const burera_bathymetry = {
"type": "FeatureCollection",
"name": "burera",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "fid": 1, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.383676896551724 ], [ 29.78132088607595, -1.383946379310345 ], [ 29.782398860759493, -1.383946379310345 ], [ 29.782398860759493, -1.383676896551724 ], [ 29.78132088607595, -1.383676896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.383676896551724 ], [ 29.792909113924051, -1.384215862068966 ], [ 29.793717594936709, -1.384215862068966 ], [ 29.793717594936709, -1.383946379310345 ], [ 29.793178607594935, -1.383946379310345 ], [ 29.793178607594935, -1.383676896551724 ], [ 29.792909113924051, -1.383676896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.383946379310345 ], [ 29.781051392405065, -1.384215862068966 ], [ 29.78132088607595, -1.384215862068966 ], [ 29.78132088607595, -1.383946379310345 ], [ 29.781051392405065, -1.383946379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.383946379310345 ], [ 29.78132088607595, -1.384215862068966 ], [ 29.781590379746834, -1.384215862068966 ], [ 29.781590379746834, -1.383946379310345 ], [ 29.78132088607595, -1.383946379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.383946379310345 ], [ 29.781590379746834, -1.384215862068966 ], [ 29.782129367088608, -1.384215862068966 ], [ 29.782129367088608, -1.383946379310345 ], [ 29.781590379746834, -1.383946379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.383946379310345 ], [ 29.782129367088608, -1.384215862068966 ], [ 29.782398860759493, -1.384215862068966 ], [ 29.782398860759493, -1.383946379310345 ], [ 29.782129367088608, -1.383946379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.383946379310345 ], [ 29.782398860759493, -1.384215862068966 ], [ 29.782668354430381, -1.384215862068966 ], [ 29.782668354430381, -1.383946379310345 ], [ 29.782398860759493, -1.383946379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.384215862068966 ], [ 29.780781898734176, -1.385024310344828 ], [ 29.781051392405065, -1.385024310344828 ], [ 29.781051392405065, -1.384215862068966 ], [ 29.780781898734176, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.384215862068966 ], [ 29.781051392405065, -1.384485344827586 ], [ 29.78132088607595, -1.384485344827586 ], [ 29.78132088607595, -1.384215862068966 ], [ 29.781051392405065, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.384215862068966 ], [ 29.78132088607595, -1.384485344827586 ], [ 29.781590379746834, -1.384485344827586 ], [ 29.781590379746834, -1.384215862068966 ], [ 29.78132088607595, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.384215862068966 ], [ 29.781590379746834, -1.384485344827586 ], [ 29.782129367088608, -1.384485344827586 ], [ 29.782129367088608, -1.384215862068966 ], [ 29.781590379746834, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.384215862068966 ], [ 29.782129367088608, -1.384485344827586 ], [ 29.782398860759493, -1.384485344827586 ], [ 29.782398860759493, -1.384215862068966 ], [ 29.782129367088608, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.384215862068966 ], [ 29.782398860759493, -1.384485344827586 ], [ 29.782668354430381, -1.384485344827586 ], [ 29.782668354430381, -1.384215862068966 ], [ 29.782398860759493, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.384215862068966 ], [ 29.782668354430381, -1.384485344827586 ], [ 29.782937848101266, -1.384485344827586 ], [ 29.782937848101266, -1.384215862068966 ], [ 29.782668354430381, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.384215862068966 ], [ 29.792370126582277, -1.385293793103448 ], [ 29.792909113924051, -1.385293793103448 ], [ 29.792909113924051, -1.384215862068966 ], [ 29.792370126582277, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.384215862068966 ], [ 29.792909113924051, -1.384485344827586 ], [ 29.793178607594935, -1.384485344827586 ], [ 29.793178607594935, -1.384215862068966 ], [ 29.792909113924051, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.384215862068966 ], [ 29.793178607594935, -1.384485344827586 ], [ 29.793448101265824, -1.384485344827586 ], [ 29.793448101265824, -1.384215862068966 ], [ 29.793178607594935, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.384215862068966 ], [ 29.793448101265824, -1.384485344827586 ], [ 29.793717594936709, -1.384485344827586 ], [ 29.793717594936709, -1.384215862068966 ], [ 29.793448101265824, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.384215862068966 ], [ 29.793717594936709, -1.384485344827586 ], [ 29.793987088607594, -1.384485344827586 ], [ 29.793987088607594, -1.384215862068966 ], [ 29.793717594936709, -1.384215862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.384485344827586 ], [ 29.781051392405065, -1.385024310344828 ], [ 29.78132088607595, -1.385024310344828 ], [ 29.78132088607595, -1.384485344827586 ], [ 29.781051392405065, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.384485344827586 ], [ 29.78132088607595, -1.385024310344828 ], [ 29.781590379746834, -1.385024310344828 ], [ 29.781590379746834, -1.384485344827586 ], [ 29.78132088607595, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.384485344827586 ], [ 29.781590379746834, -1.384754827586207 ], [ 29.782129367088608, -1.384754827586207 ], [ 29.782129367088608, -1.384485344827586 ], [ 29.781590379746834, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.384485344827586 ], [ 29.782129367088608, -1.384754827586207 ], [ 29.782398860759493, -1.384754827586207 ], [ 29.782398860759493, -1.384485344827586 ], [ 29.782129367088608, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.384485344827586 ], [ 29.782398860759493, -1.384754827586207 ], [ 29.782668354430381, -1.384754827586207 ], [ 29.782668354430381, -1.384485344827586 ], [ 29.782398860759493, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.384485344827586 ], [ 29.782668354430381, -1.384754827586207 ], [ 29.782937848101266, -1.384754827586207 ], [ 29.782937848101266, -1.384485344827586 ], [ 29.782668354430381, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.384485344827586 ], [ 29.782937848101266, -1.385024310344828 ], [ 29.783207341772151, -1.385024310344828 ], [ 29.783207341772151, -1.384485344827586 ], [ 29.782937848101266, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.384485344827586 ], [ 29.792909113924051, -1.385024310344828 ], [ 29.793178607594935, -1.385024310344828 ], [ 29.793178607594935, -1.384485344827586 ], [ 29.792909113924051, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.384485344827586 ], [ 29.793178607594935, -1.385024310344828 ], [ 29.793717594936709, -1.385024310344828 ], [ 29.793717594936709, -1.384754827586207 ], [ 29.793448101265824, -1.384754827586207 ], [ 29.793448101265824, -1.384485344827586 ], [ 29.793178607594935, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.384485344827586 ], [ 29.793448101265824, -1.384754827586207 ], [ 29.793717594936709, -1.384754827586207 ], [ 29.793717594936709, -1.384485344827586 ], [ 29.793448101265824, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.384485344827586 ], [ 29.793717594936709, -1.384754827586207 ], [ 29.793987088607594, -1.384754827586207 ], [ 29.793987088607594, -1.384485344827586 ], [ 29.793717594936709, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.384485344827586 ], [ 29.793987088607594, -1.384754827586207 ], [ 29.794256582278482, -1.384754827586207 ], [ 29.794256582278482, -1.384485344827586 ], [ 29.793987088607594, -1.384485344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.384754827586207 ], [ 29.781590379746834, -1.385293793103448 ], [ 29.781859873417723, -1.385293793103448 ], [ 29.781859873417723, -1.385024310344828 ], [ 29.782129367088608, -1.385024310344828 ], [ 29.782129367088608, -1.384754827586207 ], [ 29.781590379746834, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.384754827586207 ], [ 29.782129367088608, -1.385024310344828 ], [ 29.782398860759493, -1.385024310344828 ], [ 29.782398860759493, -1.384754827586207 ], [ 29.782129367088608, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.384754827586207 ], [ 29.782398860759493, -1.385024310344828 ], [ 29.782668354430381, -1.385024310344828 ], [ 29.782668354430381, -1.384754827586207 ], [ 29.782398860759493, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 35, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.384754827586207 ], [ 29.782668354430381, -1.385024310344828 ], [ 29.782937848101266, -1.385024310344828 ], [ 29.782937848101266, -1.384754827586207 ], [ 29.782668354430381, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 36, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.384754827586207 ], [ 29.793717594936709, -1.385024310344828 ], [ 29.793987088607594, -1.385024310344828 ], [ 29.793987088607594, -1.384754827586207 ], [ 29.793717594936709, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 37, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.384754827586207 ], [ 29.793987088607594, -1.385024310344828 ], [ 29.794256582278482, -1.385024310344828 ], [ 29.794256582278482, -1.384754827586207 ], [ 29.793987088607594, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 38, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.384754827586207 ], [ 29.794256582278482, -1.385024310344828 ], [ 29.794795569620252, -1.385024310344828 ], [ 29.794795569620252, -1.384754827586207 ], [ 29.794256582278482, -1.384754827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 39, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.385024310344828 ], [ 29.780781898734176, -1.385293793103448 ], [ 29.781051392405065, -1.385293793103448 ], [ 29.781051392405065, -1.385024310344828 ], [ 29.780781898734176, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 40, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.385024310344828 ], [ 29.781051392405065, -1.385293793103448 ], [ 29.78132088607595, -1.385293793103448 ], [ 29.78132088607595, -1.385024310344828 ], [ 29.781051392405065, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 41, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.385024310344828 ], [ 29.78132088607595, -1.385293793103448 ], [ 29.781590379746834, -1.385293793103448 ], [ 29.781590379746834, -1.385024310344828 ], [ 29.78132088607595, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 42, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.385024310344828 ], [ 29.781859873417723, -1.385293793103448 ], [ 29.782129367088608, -1.385293793103448 ], [ 29.782129367088608, -1.385024310344828 ], [ 29.781859873417723, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 43, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.385024310344828 ], [ 29.782129367088608, -1.385293793103448 ], [ 29.782398860759493, -1.385293793103448 ], [ 29.782398860759493, -1.385024310344828 ], [ 29.782129367088608, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 44, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.385024310344828 ], [ 29.782398860759493, -1.385293793103448 ], [ 29.782668354430381, -1.385293793103448 ], [ 29.782668354430381, -1.385024310344828 ], [ 29.782398860759493, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 45, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.385024310344828 ], [ 29.782668354430381, -1.385293793103448 ], [ 29.782937848101266, -1.385293793103448 ], [ 29.782937848101266, -1.385024310344828 ], [ 29.782668354430381, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 46, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.385024310344828 ], [ 29.782937848101266, -1.385293793103448 ], [ 29.783207341772151, -1.385293793103448 ], [ 29.783207341772151, -1.385024310344828 ], [ 29.782937848101266, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 47, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.385024310344828 ], [ 29.783207341772151, -1.385563275862069 ], [ 29.783476835443039, -1.385563275862069 ], [ 29.783476835443039, -1.385024310344828 ], [ 29.783207341772151, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 48, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.385024310344828 ], [ 29.792909113924051, -1.385293793103448 ], [ 29.793178607594935, -1.385293793103448 ], [ 29.793178607594935, -1.385024310344828 ], [ 29.792909113924051, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 49, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.385024310344828 ], [ 29.793178607594935, -1.385293793103448 ], [ 29.793448101265824, -1.385293793103448 ], [ 29.793448101265824, -1.385024310344828 ], [ 29.793178607594935, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 50, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.385024310344828 ], [ 29.793448101265824, -1.385293793103448 ], [ 29.793717594936709, -1.385293793103448 ], [ 29.793717594936709, -1.385024310344828 ], [ 29.793448101265824, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 51, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.385024310344828 ], [ 29.793717594936709, -1.385293793103448 ], [ 29.793987088607594, -1.385293793103448 ], [ 29.793987088607594, -1.385024310344828 ], [ 29.793717594936709, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 52, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.385024310344828 ], [ 29.793987088607594, -1.385293793103448 ], [ 29.794256582278482, -1.385293793103448 ], [ 29.794256582278482, -1.385024310344828 ], [ 29.793987088607594, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 53, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.385024310344828 ], [ 29.794256582278482, -1.385293793103448 ], [ 29.794526075949367, -1.385293793103448 ], [ 29.794526075949367, -1.385024310344828 ], [ 29.794256582278482, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 54, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.385024310344828 ], [ 29.794526075949367, -1.385293793103448 ], [ 29.794795569620252, -1.385293793103448 ], [ 29.794795569620252, -1.385024310344828 ], [ 29.794526075949367, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 55, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.385024310344828 ], [ 29.794795569620252, -1.385293793103448 ], [ 29.79506506329114, -1.385293793103448 ], [ 29.79506506329114, -1.385024310344828 ], [ 29.794795569620252, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 56, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.385293793103448 ], [ 29.78132088607595, -1.385563275862069 ], [ 29.781590379746834, -1.385563275862069 ], [ 29.781590379746834, -1.385293793103448 ], [ 29.78132088607595, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 57, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.385293793103448 ], [ 29.781590379746834, -1.385563275862069 ], [ 29.781859873417723, -1.385563275862069 ], [ 29.781859873417723, -1.385293793103448 ], [ 29.781590379746834, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 58, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.385293793103448 ], [ 29.781859873417723, -1.385563275862069 ], [ 29.782129367088608, -1.385563275862069 ], [ 29.782129367088608, -1.385293793103448 ], [ 29.781859873417723, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 59, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.385293793103448 ], [ 29.782129367088608, -1.385563275862069 ], [ 29.782398860759493, -1.385563275862069 ], [ 29.782398860759493, -1.385293793103448 ], [ 29.782129367088608, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 60, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.385293793103448 ], [ 29.782398860759493, -1.385563275862069 ], [ 29.782668354430381, -1.385563275862069 ], [ 29.782668354430381, -1.385293793103448 ], [ 29.782398860759493, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 61, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.385293793103448 ], [ 29.782668354430381, -1.385563275862069 ], [ 29.782937848101266, -1.385563275862069 ], [ 29.782937848101266, -1.385293793103448 ], [ 29.782668354430381, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 62, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.385293793103448 ], [ 29.782937848101266, -1.385563275862069 ], [ 29.783207341772151, -1.385563275862069 ], [ 29.783207341772151, -1.385293793103448 ], [ 29.782937848101266, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 63, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.385293793103448 ], [ 29.793717594936709, -1.385563275862069 ], [ 29.793987088607594, -1.385563275862069 ], [ 29.793987088607594, -1.385293793103448 ], [ 29.793717594936709, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 64, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.385293793103448 ], [ 29.793987088607594, -1.385563275862069 ], [ 29.794256582278482, -1.385563275862069 ], [ 29.794256582278482, -1.385293793103448 ], [ 29.793987088607594, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 65, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.385293793103448 ], [ 29.794256582278482, -1.385563275862069 ], [ 29.794526075949367, -1.385563275862069 ], [ 29.794526075949367, -1.385293793103448 ], [ 29.794256582278482, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 66, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.385293793103448 ], [ 29.794526075949367, -1.385563275862069 ], [ 29.794795569620252, -1.385563275862069 ], [ 29.794795569620252, -1.385293793103448 ], [ 29.794526075949367, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 67, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.385293793103448 ], [ 29.794795569620252, -1.385563275862069 ], [ 29.79506506329114, -1.385563275862069 ], [ 29.79506506329114, -1.385293793103448 ], [ 29.794795569620252, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 68, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.385293793103448 ], [ 29.79506506329114, -1.385563275862069 ], [ 29.79560405063291, -1.385563275862069 ], [ 29.79560405063291, -1.385293793103448 ], [ 29.79506506329114, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 69, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.385024310344828 ], [ 29.780512405063291, -1.386371724137931 ], [ 29.780781898734176, -1.386371724137931 ], [ 29.780781898734176, -1.385024310344828 ], [ 29.780512405063291, -1.385024310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 70, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.385293793103448 ], [ 29.780781898734176, -1.38610224137931 ], [ 29.781051392405065, -1.38610224137931 ], [ 29.781051392405065, -1.385293793103448 ], [ 29.780781898734176, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 71, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.385293793103448 ], [ 29.781051392405065, -1.38610224137931 ], [ 29.78132088607595, -1.38610224137931 ], [ 29.78132088607595, -1.385293793103448 ], [ 29.781051392405065, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 72, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.385293793103448 ], [ 29.792909113924051, -1.387180172413793 ], [ 29.793178607594935, -1.387180172413793 ], [ 29.793178607594935, -1.385293793103448 ], [ 29.792909113924051, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 73, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.385293793103448 ], [ 29.793178607594935, -1.387180172413793 ], [ 29.793448101265824, -1.387180172413793 ], [ 29.793448101265824, -1.385293793103448 ], [ 29.793178607594935, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 74, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.385293793103448 ], [ 29.793448101265824, -1.387180172413793 ], [ 29.793717594936709, -1.387180172413793 ], [ 29.793717594936709, -1.385293793103448 ], [ 29.793448101265824, -1.385293793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 75, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.385563275862069 ], [ 29.78132088607595, -1.38583275862069 ], [ 29.781590379746834, -1.38583275862069 ], [ 29.781590379746834, -1.385563275862069 ], [ 29.78132088607595, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 76, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.385563275862069 ], [ 29.781590379746834, -1.38583275862069 ], [ 29.781859873417723, -1.38583275862069 ], [ 29.781859873417723, -1.385563275862069 ], [ 29.781590379746834, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 77, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.385563275862069 ], [ 29.781859873417723, -1.38583275862069 ], [ 29.782129367088608, -1.38583275862069 ], [ 29.782129367088608, -1.385563275862069 ], [ 29.781859873417723, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 78, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.385563275862069 ], [ 29.782129367088608, -1.38583275862069 ], [ 29.782398860759493, -1.38583275862069 ], [ 29.782398860759493, -1.385563275862069 ], [ 29.782129367088608, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 79, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.385563275862069 ], [ 29.782398860759493, -1.38583275862069 ], [ 29.782668354430381, -1.38583275862069 ], [ 29.782668354430381, -1.385563275862069 ], [ 29.782398860759493, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 80, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.385563275862069 ], [ 29.782668354430381, -1.38583275862069 ], [ 29.782937848101266, -1.38583275862069 ], [ 29.782937848101266, -1.385563275862069 ], [ 29.782668354430381, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 81, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.385563275862069 ], [ 29.782937848101266, -1.38583275862069 ], [ 29.783207341772151, -1.38583275862069 ], [ 29.783207341772151, -1.385563275862069 ], [ 29.782937848101266, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 82, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.385563275862069 ], [ 29.783207341772151, -1.38583275862069 ], [ 29.783476835443039, -1.38583275862069 ], [ 29.783476835443039, -1.385563275862069 ], [ 29.783207341772151, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 83, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.385563275862069 ], [ 29.783476835443039, -1.38583275862069 ], [ 29.784015822784809, -1.38583275862069 ], [ 29.784015822784809, -1.385563275862069 ], [ 29.783476835443039, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 84, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.385563275862069 ], [ 29.793717594936709, -1.387449655172414 ], [ 29.793987088607594, -1.387449655172414 ], [ 29.793987088607594, -1.38583275862069 ], [ 29.794256582278482, -1.38583275862069 ], [ 29.794256582278482, -1.385563275862069 ], [ 29.793717594936709, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 85, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.385563275862069 ], [ 29.794256582278482, -1.38583275862069 ], [ 29.794526075949367, -1.38583275862069 ], [ 29.794526075949367, -1.385563275862069 ], [ 29.794256582278482, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 86, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.385563275862069 ], [ 29.794526075949367, -1.38583275862069 ], [ 29.794795569620252, -1.38583275862069 ], [ 29.794795569620252, -1.385563275862069 ], [ 29.794526075949367, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 87, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.385563275862069 ], [ 29.794795569620252, -1.38583275862069 ], [ 29.79506506329114, -1.38583275862069 ], [ 29.79506506329114, -1.385563275862069 ], [ 29.794795569620252, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 88, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.385563275862069 ], [ 29.79506506329114, -1.38583275862069 ], [ 29.795334556962025, -1.38583275862069 ], [ 29.795334556962025, -1.385563275862069 ], [ 29.79506506329114, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 89, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.385563275862069 ], [ 29.795334556962025, -1.38583275862069 ], [ 29.79560405063291, -1.38583275862069 ], [ 29.79560405063291, -1.385563275862069 ], [ 29.795334556962025, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 90, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.385563275862069 ], [ 29.79560405063291, -1.38583275862069 ], [ 29.795873544303799, -1.38583275862069 ], [ 29.795873544303799, -1.385563275862069 ], [ 29.79560405063291, -1.385563275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 91, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.38583275862069 ], [ 29.78132088607595, -1.38610224137931 ], [ 29.781590379746834, -1.38610224137931 ], [ 29.781590379746834, -1.38583275862069 ], [ 29.78132088607595, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 92, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.38583275862069 ], [ 29.781590379746834, -1.38610224137931 ], [ 29.781859873417723, -1.38610224137931 ], [ 29.781859873417723, -1.38583275862069 ], [ 29.781590379746834, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 93, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.38583275862069 ], [ 29.781859873417723, -1.38610224137931 ], [ 29.782398860759493, -1.38610224137931 ], [ 29.782398860759493, -1.38583275862069 ], [ 29.781859873417723, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 94, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.38583275862069 ], [ 29.782398860759493, -1.38610224137931 ], [ 29.782668354430381, -1.38610224137931 ], [ 29.782668354430381, -1.38583275862069 ], [ 29.782398860759493, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 95, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.38583275862069 ], [ 29.782668354430381, -1.38610224137931 ], [ 29.782937848101266, -1.38610224137931 ], [ 29.782937848101266, -1.38583275862069 ], [ 29.782668354430381, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 96, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.38583275862069 ], [ 29.782937848101266, -1.38610224137931 ], [ 29.783207341772151, -1.38610224137931 ], [ 29.783207341772151, -1.38583275862069 ], [ 29.782937848101266, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 97, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.38583275862069 ], [ 29.783207341772151, -1.38610224137931 ], [ 29.783476835443039, -1.38610224137931 ], [ 29.783476835443039, -1.38583275862069 ], [ 29.783207341772151, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 98, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.38583275862069 ], [ 29.783476835443039, -1.38610224137931 ], [ 29.784015822784809, -1.38610224137931 ], [ 29.784015822784809, -1.38583275862069 ], [ 29.783476835443039, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 99, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.38583275862069 ], [ 29.784015822784809, -1.386371724137931 ], [ 29.784285316455698, -1.386371724137931 ], [ 29.784285316455698, -1.38583275862069 ], [ 29.784015822784809, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 100, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.38583275862069 ], [ 29.793987088607594, -1.387449655172414 ], [ 29.794256582278482, -1.387449655172414 ], [ 29.794256582278482, -1.386371724137931 ], [ 29.794526075949367, -1.386371724137931 ], [ 29.794526075949367, -1.38610224137931 ], [ 29.794256582278482, -1.38610224137931 ], [ 29.794256582278482, -1.38583275862069 ], [ 29.793987088607594, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 101, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.38583275862069 ], [ 29.794256582278482, -1.38610224137931 ], [ 29.794526075949367, -1.38610224137931 ], [ 29.794526075949367, -1.38583275862069 ], [ 29.794256582278482, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 102, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.38583275862069 ], [ 29.794526075949367, -1.38610224137931 ], [ 29.794795569620252, -1.38610224137931 ], [ 29.794795569620252, -1.38583275862069 ], [ 29.794526075949367, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 103, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.38583275862069 ], [ 29.794795569620252, -1.38610224137931 ], [ 29.79506506329114, -1.38610224137931 ], [ 29.79506506329114, -1.38583275862069 ], [ 29.794795569620252, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 104, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.38583275862069 ], [ 29.79506506329114, -1.38610224137931 ], [ 29.795334556962025, -1.38610224137931 ], [ 29.795334556962025, -1.38583275862069 ], [ 29.79506506329114, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 105, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.38583275862069 ], [ 29.795334556962025, -1.38610224137931 ], [ 29.79560405063291, -1.38610224137931 ], [ 29.79560405063291, -1.38583275862069 ], [ 29.795334556962025, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 106, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.38583275862069 ], [ 29.79560405063291, -1.38610224137931 ], [ 29.795873544303799, -1.38610224137931 ], [ 29.795873544303799, -1.38583275862069 ], [ 29.79560405063291, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 107, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.38583275862069 ], [ 29.795873544303799, -1.387180172413793 ], [ 29.796143037974684, -1.387180172413793 ], [ 29.796143037974684, -1.38583275862069 ], [ 29.795873544303799, -1.38583275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 108, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.38610224137931 ], [ 29.780781898734176, -1.386371724137931 ], [ 29.781051392405065, -1.386371724137931 ], [ 29.781051392405065, -1.38610224137931 ], [ 29.780781898734176, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 109, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.38610224137931 ], [ 29.781051392405065, -1.386371724137931 ], [ 29.78132088607595, -1.386371724137931 ], [ 29.78132088607595, -1.38610224137931 ], [ 29.781051392405065, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 110, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.38610224137931 ], [ 29.78132088607595, -1.386371724137931 ], [ 29.781590379746834, -1.386371724137931 ], [ 29.781590379746834, -1.38610224137931 ], [ 29.78132088607595, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 111, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.38610224137931 ], [ 29.781590379746834, -1.386641206896552 ], [ 29.781859873417723, -1.386641206896552 ], [ 29.781859873417723, -1.38610224137931 ], [ 29.781590379746834, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 112, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.38610224137931 ], [ 29.781859873417723, -1.386641206896552 ], [ 29.782129367088608, -1.386641206896552 ], [ 29.782129367088608, -1.38610224137931 ], [ 29.781859873417723, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 113, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.38610224137931 ], [ 29.782129367088608, -1.386371724137931 ], [ 29.782398860759493, -1.386371724137931 ], [ 29.782398860759493, -1.38610224137931 ], [ 29.782129367088608, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 114, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.38610224137931 ], [ 29.782398860759493, -1.386371724137931 ], [ 29.782668354430381, -1.386371724137931 ], [ 29.782668354430381, -1.38610224137931 ], [ 29.782398860759493, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 115, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.38610224137931 ], [ 29.782668354430381, -1.386371724137931 ], [ 29.782937848101266, -1.386371724137931 ], [ 29.782937848101266, -1.38610224137931 ], [ 29.782668354430381, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 116, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.38610224137931 ], [ 29.782937848101266, -1.386371724137931 ], [ 29.783207341772151, -1.386371724137931 ], [ 29.783207341772151, -1.38610224137931 ], [ 29.782937848101266, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 117, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.38610224137931 ], [ 29.783207341772151, -1.386371724137931 ], [ 29.783476835443039, -1.386371724137931 ], [ 29.783476835443039, -1.38610224137931 ], [ 29.783207341772151, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 118, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.38610224137931 ], [ 29.783476835443039, -1.386371724137931 ], [ 29.784015822784809, -1.386371724137931 ], [ 29.784015822784809, -1.38610224137931 ], [ 29.783476835443039, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 119, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.38610224137931 ], [ 29.794526075949367, -1.386371724137931 ], [ 29.794795569620252, -1.386371724137931 ], [ 29.794795569620252, -1.38610224137931 ], [ 29.794526075949367, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 120, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.38610224137931 ], [ 29.794795569620252, -1.386371724137931 ], [ 29.79506506329114, -1.386371724137931 ], [ 29.79506506329114, -1.38610224137931 ], [ 29.794795569620252, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 121, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.38610224137931 ], [ 29.79506506329114, -1.386371724137931 ], [ 29.795334556962025, -1.386371724137931 ], [ 29.795334556962025, -1.38610224137931 ], [ 29.79506506329114, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 122, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.38610224137931 ], [ 29.795334556962025, -1.387180172413793 ], [ 29.79560405063291, -1.387180172413793 ], [ 29.79560405063291, -1.38610224137931 ], [ 29.795334556962025, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 123, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.38610224137931 ], [ 29.79560405063291, -1.387180172413793 ], [ 29.795873544303799, -1.387180172413793 ], [ 29.795873544303799, -1.38610224137931 ], [ 29.79560405063291, -1.38610224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 124, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.386371724137931 ], [ 29.780781898734176, -1.387180172413793 ], [ 29.781051392405065, -1.387180172413793 ], [ 29.781051392405065, -1.386371724137931 ], [ 29.780781898734176, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 125, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.386371724137931 ], [ 29.781051392405065, -1.386910689655172 ], [ 29.78132088607595, -1.386910689655172 ], [ 29.78132088607595, -1.386371724137931 ], [ 29.781051392405065, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 126, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.386371724137931 ], [ 29.78132088607595, -1.386910689655172 ], [ 29.781590379746834, -1.386910689655172 ], [ 29.781590379746834, -1.386371724137931 ], [ 29.78132088607595, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 127, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.386371724137931 ], [ 29.782129367088608, -1.386641206896552 ], [ 29.782398860759493, -1.386641206896552 ], [ 29.782398860759493, -1.386910689655172 ], [ 29.782668354430381, -1.386910689655172 ], [ 29.782668354430381, -1.386371724137931 ], [ 29.782129367088608, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 128, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.386371724137931 ], [ 29.782668354430381, -1.386641206896552 ], [ 29.782937848101266, -1.386641206896552 ], [ 29.782937848101266, -1.386371724137931 ], [ 29.782668354430381, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 129, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.386371724137931 ], [ 29.782937848101266, -1.386641206896552 ], [ 29.783207341772151, -1.386641206896552 ], [ 29.783207341772151, -1.386371724137931 ], [ 29.782937848101266, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 130, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.386371724137931 ], [ 29.783207341772151, -1.386641206896552 ], [ 29.783476835443039, -1.386641206896552 ], [ 29.783476835443039, -1.386371724137931 ], [ 29.783207341772151, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 131, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.386371724137931 ], [ 29.783476835443039, -1.386641206896552 ], [ 29.784015822784809, -1.386641206896552 ], [ 29.784015822784809, -1.386371724137931 ], [ 29.783476835443039, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 132, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.386371724137931 ], [ 29.784015822784809, -1.386641206896552 ], [ 29.784285316455698, -1.386641206896552 ], [ 29.784285316455698, -1.386371724137931 ], [ 29.784015822784809, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 133, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.386371724137931 ], [ 29.784285316455698, -1.386641206896552 ], [ 29.784554810126583, -1.386641206896552 ], [ 29.784554810126583, -1.386371724137931 ], [ 29.784285316455698, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 134, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.386371724137931 ], [ 29.789675189873417, -1.387449655172414 ], [ 29.789944683544302, -1.387449655172414 ], [ 29.789944683544302, -1.386641206896552 ], [ 29.790214177215191, -1.386641206896552 ], [ 29.790214177215191, -1.386371724137931 ], [ 29.789675189873417, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 135, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.386371724137931 ], [ 29.794256582278482, -1.387180172413793 ], [ 29.794795569620252, -1.387180172413793 ], [ 29.794795569620252, -1.386641206896552 ], [ 29.794526075949367, -1.386641206896552 ], [ 29.794526075949367, -1.386371724137931 ], [ 29.794256582278482, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 136, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.386371724137931 ], [ 29.794526075949367, -1.386641206896552 ], [ 29.794795569620252, -1.386641206896552 ], [ 29.794795569620252, -1.386371724137931 ], [ 29.794526075949367, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 137, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.386371724137931 ], [ 29.794795569620252, -1.387449655172414 ], [ 29.79506506329114, -1.387449655172414 ], [ 29.79506506329114, -1.386371724137931 ], [ 29.794795569620252, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 138, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.386371724137931 ], [ 29.79506506329114, -1.387449655172414 ], [ 29.795334556962025, -1.387449655172414 ], [ 29.795334556962025, -1.386371724137931 ], [ 29.79506506329114, -1.386371724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 139, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.386641206896552 ], [ 29.781590379746834, -1.386910689655172 ], [ 29.781859873417723, -1.386910689655172 ], [ 29.781859873417723, -1.386641206896552 ], [ 29.781590379746834, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 140, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.386641206896552 ], [ 29.781859873417723, -1.386910689655172 ], [ 29.782129367088608, -1.386910689655172 ], [ 29.782129367088608, -1.386641206896552 ], [ 29.781859873417723, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 141, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.386641206896552 ], [ 29.782129367088608, -1.386910689655172 ], [ 29.782398860759493, -1.386910689655172 ], [ 29.782398860759493, -1.386641206896552 ], [ 29.782129367088608, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 142, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.386641206896552 ], [ 29.782668354430381, -1.386910689655172 ], [ 29.782937848101266, -1.386910689655172 ], [ 29.782937848101266, -1.386641206896552 ], [ 29.782668354430381, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 143, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.386641206896552 ], [ 29.782937848101266, -1.386910689655172 ], [ 29.783207341772151, -1.386910689655172 ], [ 29.783207341772151, -1.386641206896552 ], [ 29.782937848101266, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 144, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.386641206896552 ], [ 29.783207341772151, -1.386910689655172 ], [ 29.783476835443039, -1.386910689655172 ], [ 29.783476835443039, -1.386641206896552 ], [ 29.783207341772151, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 145, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.386641206896552 ], [ 29.783476835443039, -1.386910689655172 ], [ 29.784015822784809, -1.386910689655172 ], [ 29.784015822784809, -1.386641206896552 ], [ 29.783476835443039, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 146, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.386641206896552 ], [ 29.784015822784809, -1.386910689655172 ], [ 29.784285316455698, -1.386910689655172 ], [ 29.784285316455698, -1.386641206896552 ], [ 29.784015822784809, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 147, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.386641206896552 ], [ 29.784285316455698, -1.386910689655172 ], [ 29.784554810126583, -1.386910689655172 ], [ 29.784554810126583, -1.386641206896552 ], [ 29.784285316455698, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 148, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.386641206896552 ], [ 29.784554810126583, -1.387449655172414 ], [ 29.784824303797468, -1.387449655172414 ], [ 29.784824303797468, -1.386641206896552 ], [ 29.784554810126583, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 149, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.386641206896552 ], [ 29.789944683544302, -1.386910689655172 ], [ 29.790214177215191, -1.386910689655172 ], [ 29.790214177215191, -1.386641206896552 ], [ 29.789944683544302, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 150, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.386641206896552 ], [ 29.790214177215191, -1.386910689655172 ], [ 29.790483670886076, -1.386910689655172 ], [ 29.790483670886076, -1.386641206896552 ], [ 29.790214177215191, -1.386641206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.386910689655172 ], [ 29.781051392405065, -1.387180172413793 ], [ 29.78132088607595, -1.387180172413793 ], [ 29.78132088607595, -1.386910689655172 ], [ 29.781051392405065, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 152, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.386910689655172 ], [ 29.78132088607595, -1.387180172413793 ], [ 29.781590379746834, -1.387180172413793 ], [ 29.781590379746834, -1.386910689655172 ], [ 29.78132088607595, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 153, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.386910689655172 ], [ 29.781590379746834, -1.387180172413793 ], [ 29.781859873417723, -1.387180172413793 ], [ 29.781859873417723, -1.386910689655172 ], [ 29.781590379746834, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 154, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.386910689655172 ], [ 29.781859873417723, -1.387180172413793 ], [ 29.782129367088608, -1.387180172413793 ], [ 29.782129367088608, -1.386910689655172 ], [ 29.781859873417723, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 155, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.386910689655172 ], [ 29.782129367088608, -1.387180172413793 ], [ 29.782398860759493, -1.387180172413793 ], [ 29.782398860759493, -1.386910689655172 ], [ 29.782129367088608, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 156, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.386910689655172 ], [ 29.782398860759493, -1.387180172413793 ], [ 29.782668354430381, -1.387180172413793 ], [ 29.782668354430381, -1.386910689655172 ], [ 29.782398860759493, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 157, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.386910689655172 ], [ 29.782668354430381, -1.387180172413793 ], [ 29.782937848101266, -1.387180172413793 ], [ 29.782937848101266, -1.386910689655172 ], [ 29.782668354430381, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 158, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.386910689655172 ], [ 29.782937848101266, -1.387180172413793 ], [ 29.783207341772151, -1.387180172413793 ], [ 29.783207341772151, -1.386910689655172 ], [ 29.782937848101266, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 159, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.386910689655172 ], [ 29.783207341772151, -1.387180172413793 ], [ 29.783476835443039, -1.387180172413793 ], [ 29.783476835443039, -1.386910689655172 ], [ 29.783207341772151, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 160, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.386910689655172 ], [ 29.783476835443039, -1.387180172413793 ], [ 29.784015822784809, -1.387180172413793 ], [ 29.784015822784809, -1.386910689655172 ], [ 29.783476835443039, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 161, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.386910689655172 ], [ 29.784015822784809, -1.387449655172414 ], [ 29.784285316455698, -1.387449655172414 ], [ 29.784285316455698, -1.386910689655172 ], [ 29.784015822784809, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 162, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.386910689655172 ], [ 29.784285316455698, -1.387449655172414 ], [ 29.784554810126583, -1.387449655172414 ], [ 29.784554810126583, -1.386910689655172 ], [ 29.784285316455698, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 163, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.386910689655172 ], [ 29.789944683544302, -1.387449655172414 ], [ 29.790214177215191, -1.387449655172414 ], [ 29.790214177215191, -1.386910689655172 ], [ 29.789944683544302, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 164, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.386910689655172 ], [ 29.790214177215191, -1.387180172413793 ], [ 29.790483670886076, -1.387180172413793 ], [ 29.790483670886076, -1.386910689655172 ], [ 29.790214177215191, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 165, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.386910689655172 ], [ 29.790483670886076, -1.387180172413793 ], [ 29.790753164556961, -1.387180172413793 ], [ 29.790753164556961, -1.386910689655172 ], [ 29.790483670886076, -1.386910689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 166, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.387180172413793 ], [ 29.781051392405065, -1.387449655172414 ], [ 29.78132088607595, -1.387449655172414 ], [ 29.78132088607595, -1.387180172413793 ], [ 29.781051392405065, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 167, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.387180172413793 ], [ 29.78132088607595, -1.387449655172414 ], [ 29.781590379746834, -1.387449655172414 ], [ 29.781590379746834, -1.387180172413793 ], [ 29.78132088607595, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 168, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.387180172413793 ], [ 29.781590379746834, -1.387449655172414 ], [ 29.781859873417723, -1.387449655172414 ], [ 29.781859873417723, -1.387180172413793 ], [ 29.781590379746834, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 169, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.387180172413793 ], [ 29.781859873417723, -1.387449655172414 ], [ 29.782129367088608, -1.387449655172414 ], [ 29.782129367088608, -1.387180172413793 ], [ 29.781859873417723, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 170, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.387180172413793 ], [ 29.782129367088608, -1.387449655172414 ], [ 29.782398860759493, -1.387449655172414 ], [ 29.782398860759493, -1.387180172413793 ], [ 29.782129367088608, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 171, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.387180172413793 ], [ 29.782398860759493, -1.387449655172414 ], [ 29.782668354430381, -1.387449655172414 ], [ 29.782668354430381, -1.387180172413793 ], [ 29.782398860759493, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 172, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.387180172413793 ], [ 29.782668354430381, -1.387449655172414 ], [ 29.782937848101266, -1.387449655172414 ], [ 29.782937848101266, -1.387180172413793 ], [ 29.782668354430381, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 173, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.387180172413793 ], [ 29.782937848101266, -1.387449655172414 ], [ 29.783207341772151, -1.387449655172414 ], [ 29.783207341772151, -1.387180172413793 ], [ 29.782937848101266, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 174, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.387180172413793 ], [ 29.783207341772151, -1.387719137931035 ], [ 29.783476835443039, -1.387719137931035 ], [ 29.783476835443039, -1.387180172413793 ], [ 29.783207341772151, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 175, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.387180172413793 ], [ 29.783476835443039, -1.387719137931035 ], [ 29.784015822784809, -1.387719137931035 ], [ 29.784015822784809, -1.387180172413793 ], [ 29.783476835443039, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 176, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.387180172413793 ], [ 29.790214177215191, -1.387449655172414 ], [ 29.790483670886076, -1.387449655172414 ], [ 29.790483670886076, -1.387180172413793 ], [ 29.790214177215191, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 177, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.387180172413793 ], [ 29.790483670886076, -1.387449655172414 ], [ 29.790753164556961, -1.387449655172414 ], [ 29.790753164556961, -1.387180172413793 ], [ 29.790483670886076, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 178, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.387180172413793 ], [ 29.790753164556961, -1.387449655172414 ], [ 29.791022658227849, -1.387449655172414 ], [ 29.791022658227849, -1.387180172413793 ], [ 29.790753164556961, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 179, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.387180172413793 ], [ 29.792370126582277, -1.387449655172414 ], [ 29.792909113924051, -1.387449655172414 ], [ 29.792909113924051, -1.387180172413793 ], [ 29.792370126582277, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 180, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.387180172413793 ], [ 29.792909113924051, -1.387449655172414 ], [ 29.793178607594935, -1.387449655172414 ], [ 29.793178607594935, -1.387180172413793 ], [ 29.792909113924051, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 181, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.387180172413793 ], [ 29.793178607594935, -1.387449655172414 ], [ 29.793448101265824, -1.387449655172414 ], [ 29.793448101265824, -1.387180172413793 ], [ 29.793178607594935, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 182, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.387180172413793 ], [ 29.793448101265824, -1.387449655172414 ], [ 29.793717594936709, -1.387449655172414 ], [ 29.793717594936709, -1.387180172413793 ], [ 29.793448101265824, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 183, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.387180172413793 ], [ 29.794256582278482, -1.387719137931035 ], [ 29.794795569620252, -1.387719137931035 ], [ 29.794795569620252, -1.387180172413793 ], [ 29.794256582278482, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 184, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.387180172413793 ], [ 29.795334556962025, -1.387449655172414 ], [ 29.79560405063291, -1.387449655172414 ], [ 29.79560405063291, -1.387180172413793 ], [ 29.795334556962025, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 185, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.387180172413793 ], [ 29.79560405063291, -1.387449655172414 ], [ 29.795873544303799, -1.387449655172414 ], [ 29.795873544303799, -1.387180172413793 ], [ 29.79560405063291, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 186, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.387180172413793 ], [ 29.795873544303799, -1.387449655172414 ], [ 29.796143037974684, -1.387449655172414 ], [ 29.796143037974684, -1.387180172413793 ], [ 29.795873544303799, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 187, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.387449655172414 ], [ 29.78132088607595, -1.387719137931035 ], [ 29.781590379746834, -1.387719137931035 ], [ 29.781590379746834, -1.387449655172414 ], [ 29.78132088607595, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 188, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.387449655172414 ], [ 29.781590379746834, -1.387719137931035 ], [ 29.781859873417723, -1.387719137931035 ], [ 29.781859873417723, -1.387449655172414 ], [ 29.781590379746834, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 189, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.387449655172414 ], [ 29.781859873417723, -1.387719137931035 ], [ 29.782129367088608, -1.387719137931035 ], [ 29.782129367088608, -1.387449655172414 ], [ 29.781859873417723, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 190, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.387449655172414 ], [ 29.782129367088608, -1.387719137931035 ], [ 29.782398860759493, -1.387719137931035 ], [ 29.782398860759493, -1.387449655172414 ], [ 29.782129367088608, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 191, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.387449655172414 ], [ 29.782937848101266, -1.387719137931035 ], [ 29.783207341772151, -1.387719137931035 ], [ 29.783207341772151, -1.387449655172414 ], [ 29.782937848101266, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 192, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.387449655172414 ], [ 29.784015822784809, -1.387719137931035 ], [ 29.784285316455698, -1.387719137931035 ], [ 29.784285316455698, -1.387449655172414 ], [ 29.784015822784809, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 193, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.387449655172414 ], [ 29.784285316455698, -1.387719137931035 ], [ 29.784554810126583, -1.387719137931035 ], [ 29.784554810126583, -1.387449655172414 ], [ 29.784285316455698, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 194, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.387449655172414 ], [ 29.784554810126583, -1.387719137931035 ], [ 29.784824303797468, -1.387719137931035 ], [ 29.784824303797468, -1.387449655172414 ], [ 29.784554810126583, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 195, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.387449655172414 ], [ 29.790214177215191, -1.387719137931035 ], [ 29.790483670886076, -1.387719137931035 ], [ 29.790483670886076, -1.387449655172414 ], [ 29.790214177215191, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 196, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.387449655172414 ], [ 29.790483670886076, -1.387719137931035 ], [ 29.790753164556961, -1.387719137931035 ], [ 29.790753164556961, -1.387449655172414 ], [ 29.790483670886076, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 197, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.387449655172414 ], [ 29.790753164556961, -1.387719137931035 ], [ 29.791022658227849, -1.387719137931035 ], [ 29.791022658227849, -1.387449655172414 ], [ 29.790753164556961, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 198, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.387449655172414 ], [ 29.791022658227849, -1.387719137931035 ], [ 29.792370126582277, -1.387719137931035 ], [ 29.792370126582277, -1.387449655172414 ], [ 29.791022658227849, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 199, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.387449655172414 ], [ 29.792370126582277, -1.387719137931035 ], [ 29.792909113924051, -1.387719137931035 ], [ 29.792909113924051, -1.387449655172414 ], [ 29.792370126582277, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 200, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.387449655172414 ], [ 29.792909113924051, -1.387719137931035 ], [ 29.793178607594935, -1.387719137931035 ], [ 29.793178607594935, -1.387449655172414 ], [ 29.792909113924051, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 201, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.387449655172414 ], [ 29.793178607594935, -1.387719137931035 ], [ 29.793448101265824, -1.387719137931035 ], [ 29.793448101265824, -1.387449655172414 ], [ 29.793178607594935, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 202, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.387449655172414 ], [ 29.793448101265824, -1.387719137931035 ], [ 29.793717594936709, -1.387719137931035 ], [ 29.793717594936709, -1.387449655172414 ], [ 29.793448101265824, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 203, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.387449655172414 ], [ 29.793717594936709, -1.387719137931035 ], [ 29.793987088607594, -1.387719137931035 ], [ 29.793987088607594, -1.387449655172414 ], [ 29.793717594936709, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 204, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.387449655172414 ], [ 29.793987088607594, -1.387719137931035 ], [ 29.794256582278482, -1.387719137931035 ], [ 29.794256582278482, -1.387449655172414 ], [ 29.793987088607594, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 205, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.387449655172414 ], [ 29.794795569620252, -1.387719137931035 ], [ 29.79506506329114, -1.387719137931035 ], [ 29.79506506329114, -1.387449655172414 ], [ 29.794795569620252, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 206, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.387449655172414 ], [ 29.79506506329114, -1.387719137931035 ], [ 29.795334556962025, -1.387719137931035 ], [ 29.795334556962025, -1.387449655172414 ], [ 29.79506506329114, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 207, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.387449655172414 ], [ 29.795334556962025, -1.387719137931035 ], [ 29.79560405063291, -1.387719137931035 ], [ 29.79560405063291, -1.387449655172414 ], [ 29.795334556962025, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 208, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.387180172413793 ], [ 29.796143037974684, -1.388527586206897 ], [ 29.796412531645569, -1.388527586206897 ], [ 29.796412531645569, -1.387180172413793 ], [ 29.796143037974684, -1.387180172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 209, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.387449655172414 ], [ 29.782398860759493, -1.387988620689655 ], [ 29.782668354430381, -1.387988620689655 ], [ 29.782668354430381, -1.387449655172414 ], [ 29.782398860759493, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 210, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.387449655172414 ], [ 29.782668354430381, -1.387988620689655 ], [ 29.782937848101266, -1.387988620689655 ], [ 29.782937848101266, -1.387449655172414 ], [ 29.782668354430381, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 211, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.387449655172414 ], [ 29.784824303797468, -1.388527586206897 ], [ 29.785093797468356, -1.388527586206897 ], [ 29.785093797468356, -1.387449655172414 ], [ 29.784824303797468, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 212, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.387449655172414 ], [ 29.789405696202532, -1.387988620689655 ], [ 29.789675189873417, -1.387988620689655 ], [ 29.789675189873417, -1.387449655172414 ], [ 29.789405696202532, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 213, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.387449655172414 ], [ 29.789675189873417, -1.387988620689655 ], [ 29.789944683544302, -1.387988620689655 ], [ 29.789944683544302, -1.387449655172414 ], [ 29.789675189873417, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 214, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.387449655172414 ], [ 29.789944683544302, -1.387988620689655 ], [ 29.790214177215191, -1.387988620689655 ], [ 29.790214177215191, -1.387449655172414 ], [ 29.789944683544302, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 215, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.387449655172414 ], [ 29.79560405063291, -1.388258103448276 ], [ 29.795873544303799, -1.388258103448276 ], [ 29.795873544303799, -1.387449655172414 ], [ 29.79560405063291, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 216, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.387449655172414 ], [ 29.795873544303799, -1.388258103448276 ], [ 29.796143037974684, -1.388258103448276 ], [ 29.796143037974684, -1.387449655172414 ], [ 29.795873544303799, -1.387449655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 217, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.387719137931035 ], [ 29.777547974683543, -1.387988620689655 ], [ 29.778086962025316, -1.387988620689655 ], [ 29.778086962025316, -1.387719137931035 ], [ 29.777547974683543, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 218, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.387719137931035 ], [ 29.781590379746834, -1.388527586206897 ], [ 29.781859873417723, -1.388527586206897 ], [ 29.781859873417723, -1.387719137931035 ], [ 29.781590379746834, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 219, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.387719137931035 ], [ 29.781859873417723, -1.388258103448276 ], [ 29.782129367088608, -1.388258103448276 ], [ 29.782129367088608, -1.387719137931035 ], [ 29.781859873417723, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 220, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.387719137931035 ], [ 29.782129367088608, -1.388258103448276 ], [ 29.782398860759493, -1.388258103448276 ], [ 29.782398860759493, -1.387719137931035 ], [ 29.782129367088608, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 221, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.387719137931035 ], [ 29.782937848101266, -1.387988620689655 ], [ 29.783207341772151, -1.387988620689655 ], [ 29.783207341772151, -1.387719137931035 ], [ 29.782937848101266, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 222, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.387719137931035 ], [ 29.783207341772151, -1.387988620689655 ], [ 29.783476835443039, -1.387988620689655 ], [ 29.783476835443039, -1.387719137931035 ], [ 29.783207341772151, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 223, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.387719137931035 ], [ 29.783476835443039, -1.387988620689655 ], [ 29.784015822784809, -1.387988620689655 ], [ 29.784015822784809, -1.387719137931035 ], [ 29.783476835443039, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 224, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.387719137931035 ], [ 29.784015822784809, -1.387988620689655 ], [ 29.784285316455698, -1.387988620689655 ], [ 29.784285316455698, -1.387719137931035 ], [ 29.784015822784809, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 225, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.387719137931035 ], [ 29.784285316455698, -1.388527586206897 ], [ 29.784554810126583, -1.388527586206897 ], [ 29.784554810126583, -1.387719137931035 ], [ 29.784285316455698, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 226, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.387719137931035 ], [ 29.784554810126583, -1.388527586206897 ], [ 29.784824303797468, -1.388527586206897 ], [ 29.784824303797468, -1.387719137931035 ], [ 29.784554810126583, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 227, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.387719137931035 ], [ 29.790214177215191, -1.387988620689655 ], [ 29.790483670886076, -1.387988620689655 ], [ 29.790483670886076, -1.387719137931035 ], [ 29.790214177215191, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 228, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.387719137931035 ], [ 29.790483670886076, -1.387988620689655 ], [ 29.790753164556961, -1.387988620689655 ], [ 29.790753164556961, -1.387719137931035 ], [ 29.790483670886076, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 229, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.387719137931035 ], [ 29.790753164556961, -1.387988620689655 ], [ 29.791022658227849, -1.387988620689655 ], [ 29.791022658227849, -1.387719137931035 ], [ 29.790753164556961, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 230, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.387719137931035 ], [ 29.791022658227849, -1.387988620689655 ], [ 29.792370126582277, -1.387988620689655 ], [ 29.792370126582277, -1.387719137931035 ], [ 29.791022658227849, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 231, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.387719137931035 ], [ 29.792370126582277, -1.387988620689655 ], [ 29.792909113924051, -1.387988620689655 ], [ 29.792909113924051, -1.387719137931035 ], [ 29.792370126582277, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 232, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.387719137931035 ], [ 29.792909113924051, -1.387988620689655 ], [ 29.793178607594935, -1.387988620689655 ], [ 29.793178607594935, -1.387719137931035 ], [ 29.792909113924051, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 233, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.387719137931035 ], [ 29.793178607594935, -1.387988620689655 ], [ 29.793448101265824, -1.387988620689655 ], [ 29.793448101265824, -1.387719137931035 ], [ 29.793178607594935, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 234, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.387719137931035 ], [ 29.793448101265824, -1.387988620689655 ], [ 29.793717594936709, -1.387988620689655 ], [ 29.793717594936709, -1.387719137931035 ], [ 29.793448101265824, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 235, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.387719137931035 ], [ 29.793717594936709, -1.387988620689655 ], [ 29.793987088607594, -1.387988620689655 ], [ 29.793987088607594, -1.387719137931035 ], [ 29.793717594936709, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 236, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.387719137931035 ], [ 29.793987088607594, -1.387988620689655 ], [ 29.794256582278482, -1.387988620689655 ], [ 29.794256582278482, -1.387719137931035 ], [ 29.793987088607594, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 237, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.387719137931035 ], [ 29.794256582278482, -1.387988620689655 ], [ 29.794795569620252, -1.387988620689655 ], [ 29.794795569620252, -1.387719137931035 ], [ 29.794256582278482, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 238, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.387719137931035 ], [ 29.794795569620252, -1.387988620689655 ], [ 29.79506506329114, -1.387988620689655 ], [ 29.79506506329114, -1.387719137931035 ], [ 29.794795569620252, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 239, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.387719137931035 ], [ 29.79506506329114, -1.387988620689655 ], [ 29.795334556962025, -1.387988620689655 ], [ 29.795334556962025, -1.387719137931035 ], [ 29.79506506329114, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 240, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.387719137931035 ], [ 29.795334556962025, -1.387988620689655 ], [ 29.79560405063291, -1.387988620689655 ], [ 29.79560405063291, -1.387719137931035 ], [ 29.795334556962025, -1.387719137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 241, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.387988620689655 ], [ 29.777278481012658, -1.388527586206897 ], [ 29.777547974683543, -1.388527586206897 ], [ 29.777547974683543, -1.387988620689655 ], [ 29.777278481012658, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 242, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.387988620689655 ], [ 29.777547974683543, -1.388258103448276 ], [ 29.778086962025316, -1.388258103448276 ], [ 29.778086962025316, -1.387988620689655 ], [ 29.777547974683543, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 243, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.387988620689655 ], [ 29.778086962025316, -1.388258103448276 ], [ 29.778356455696201, -1.388258103448276 ], [ 29.778356455696201, -1.387988620689655 ], [ 29.778086962025316, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 244, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.387988620689655 ], [ 29.782398860759493, -1.388258103448276 ], [ 29.782668354430381, -1.388258103448276 ], [ 29.782668354430381, -1.387988620689655 ], [ 29.782398860759493, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 245, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.387988620689655 ], [ 29.782668354430381, -1.388258103448276 ], [ 29.782937848101266, -1.388258103448276 ], [ 29.782937848101266, -1.387988620689655 ], [ 29.782668354430381, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 246, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.387988620689655 ], [ 29.782937848101266, -1.388258103448276 ], [ 29.783207341772151, -1.388258103448276 ], [ 29.783207341772151, -1.387988620689655 ], [ 29.782937848101266, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 247, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.387988620689655 ], [ 29.783207341772151, -1.388797068965517 ], [ 29.783476835443039, -1.388797068965517 ], [ 29.783476835443039, -1.387988620689655 ], [ 29.783207341772151, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 248, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.387988620689655 ], [ 29.783476835443039, -1.388797068965517 ], [ 29.784015822784809, -1.388797068965517 ], [ 29.784015822784809, -1.387988620689655 ], [ 29.783476835443039, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 249, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.387988620689655 ], [ 29.784015822784809, -1.388797068965517 ], [ 29.784285316455698, -1.388797068965517 ], [ 29.784285316455698, -1.387988620689655 ], [ 29.784015822784809, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 250, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.387988620689655 ], [ 29.789675189873417, -1.388527586206897 ], [ 29.789944683544302, -1.388527586206897 ], [ 29.789944683544302, -1.387988620689655 ], [ 29.789675189873417, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 251, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.387988620689655 ], [ 29.789944683544302, -1.388258103448276 ], [ 29.790214177215191, -1.388258103448276 ], [ 29.790214177215191, -1.387988620689655 ], [ 29.789944683544302, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 252, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.387988620689655 ], [ 29.790214177215191, -1.388258103448276 ], [ 29.790483670886076, -1.388258103448276 ], [ 29.790483670886076, -1.387988620689655 ], [ 29.790214177215191, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 253, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.387988620689655 ], [ 29.790483670886076, -1.388258103448276 ], [ 29.790753164556961, -1.388258103448276 ], [ 29.790753164556961, -1.387988620689655 ], [ 29.790483670886076, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 254, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.387988620689655 ], [ 29.790753164556961, -1.388258103448276 ], [ 29.791022658227849, -1.388258103448276 ], [ 29.791022658227849, -1.387988620689655 ], [ 29.790753164556961, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 255, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.387988620689655 ], [ 29.791022658227849, -1.388258103448276 ], [ 29.792370126582277, -1.388258103448276 ], [ 29.792370126582277, -1.387988620689655 ], [ 29.791022658227849, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 256, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.387988620689655 ], [ 29.792370126582277, -1.388258103448276 ], [ 29.792909113924051, -1.388258103448276 ], [ 29.792909113924051, -1.387988620689655 ], [ 29.792370126582277, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 257, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.387988620689655 ], [ 29.792909113924051, -1.388258103448276 ], [ 29.793178607594935, -1.388258103448276 ], [ 29.793178607594935, -1.387988620689655 ], [ 29.792909113924051, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 258, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.387988620689655 ], [ 29.793178607594935, -1.388258103448276 ], [ 29.793448101265824, -1.388258103448276 ], [ 29.793448101265824, -1.387988620689655 ], [ 29.793178607594935, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 259, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.387988620689655 ], [ 29.793448101265824, -1.388258103448276 ], [ 29.793717594936709, -1.388258103448276 ], [ 29.793717594936709, -1.387988620689655 ], [ 29.793448101265824, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 260, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.387988620689655 ], [ 29.793717594936709, -1.388258103448276 ], [ 29.793987088607594, -1.388258103448276 ], [ 29.793987088607594, -1.387988620689655 ], [ 29.793717594936709, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 261, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.387988620689655 ], [ 29.793987088607594, -1.388258103448276 ], [ 29.794256582278482, -1.388258103448276 ], [ 29.794256582278482, -1.387988620689655 ], [ 29.793987088607594, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 262, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.387988620689655 ], [ 29.794256582278482, -1.388258103448276 ], [ 29.794526075949367, -1.388258103448276 ], [ 29.794526075949367, -1.387988620689655 ], [ 29.794256582278482, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 263, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.387988620689655 ], [ 29.794526075949367, -1.388258103448276 ], [ 29.794795569620252, -1.388258103448276 ], [ 29.794795569620252, -1.387988620689655 ], [ 29.794526075949367, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 264, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.387988620689655 ], [ 29.794795569620252, -1.388258103448276 ], [ 29.79506506329114, -1.388258103448276 ], [ 29.79506506329114, -1.387988620689655 ], [ 29.794795569620252, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 265, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.387988620689655 ], [ 29.79506506329114, -1.388258103448276 ], [ 29.795334556962025, -1.388258103448276 ], [ 29.795334556962025, -1.387988620689655 ], [ 29.79506506329114, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 266, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.387988620689655 ], [ 29.795334556962025, -1.388258103448276 ], [ 29.79560405063291, -1.388258103448276 ], [ 29.79560405063291, -1.387988620689655 ], [ 29.795334556962025, -1.387988620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 267, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.388258103448276 ], [ 29.777547974683543, -1.388527586206897 ], [ 29.777817468354431, -1.388527586206897 ], [ 29.777817468354431, -1.388258103448276 ], [ 29.777547974683543, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.388258103448276 ], [ 29.777817468354431, -1.388527586206897 ], [ 29.778086962025316, -1.388527586206897 ], [ 29.778086962025316, -1.388258103448276 ], [ 29.777817468354431, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 269, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.388258103448276 ], [ 29.778086962025316, -1.388527586206897 ], [ 29.778356455696201, -1.388527586206897 ], [ 29.778356455696201, -1.388258103448276 ], [ 29.778086962025316, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 270, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.388258103448276 ], [ 29.778356455696201, -1.388527586206897 ], [ 29.77862594936709, -1.388527586206897 ], [ 29.77862594936709, -1.388258103448276 ], [ 29.778356455696201, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 271, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.388258103448276 ], [ 29.781859873417723, -1.388527586206897 ], [ 29.782129367088608, -1.388527586206897 ], [ 29.782129367088608, -1.388258103448276 ], [ 29.781859873417723, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 272, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.388258103448276 ], [ 29.782129367088608, -1.388527586206897 ], [ 29.782398860759493, -1.388527586206897 ], [ 29.782398860759493, -1.388258103448276 ], [ 29.782129367088608, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 273, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.388258103448276 ], [ 29.782398860759493, -1.388527586206897 ], [ 29.782668354430381, -1.388527586206897 ], [ 29.782668354430381, -1.388258103448276 ], [ 29.782398860759493, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 274, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.388258103448276 ], [ 29.782668354430381, -1.388797068965517 ], [ 29.782937848101266, -1.388797068965517 ], [ 29.782937848101266, -1.388258103448276 ], [ 29.782668354430381, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 275, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.388258103448276 ], [ 29.782937848101266, -1.388797068965517 ], [ 29.783207341772151, -1.388797068965517 ], [ 29.783207341772151, -1.388258103448276 ], [ 29.782937848101266, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 276, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.388258103448276 ], [ 29.789944683544302, -1.388527586206897 ], [ 29.790214177215191, -1.388527586206897 ], [ 29.790214177215191, -1.388258103448276 ], [ 29.789944683544302, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 277, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.388258103448276 ], [ 29.790214177215191, -1.388527586206897 ], [ 29.790483670886076, -1.388527586206897 ], [ 29.790483670886076, -1.388258103448276 ], [ 29.790214177215191, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 278, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.388258103448276 ], [ 29.790483670886076, -1.388527586206897 ], [ 29.790753164556961, -1.388527586206897 ], [ 29.790753164556961, -1.388258103448276 ], [ 29.790483670886076, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 279, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.388258103448276 ], [ 29.790753164556961, -1.388527586206897 ], [ 29.792909113924051, -1.388527586206897 ], [ 29.792909113924051, -1.388258103448276 ], [ 29.790753164556961, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 280, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.388258103448276 ], [ 29.792909113924051, -1.388527586206897 ], [ 29.793178607594935, -1.388527586206897 ], [ 29.793178607594935, -1.388258103448276 ], [ 29.792909113924051, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 281, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.388258103448276 ], [ 29.793178607594935, -1.388527586206897 ], [ 29.793448101265824, -1.388527586206897 ], [ 29.793448101265824, -1.388258103448276 ], [ 29.793178607594935, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 282, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.388258103448276 ], [ 29.793448101265824, -1.388527586206897 ], [ 29.793717594936709, -1.388527586206897 ], [ 29.793717594936709, -1.388258103448276 ], [ 29.793448101265824, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 283, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.388258103448276 ], [ 29.793717594936709, -1.388527586206897 ], [ 29.793987088607594, -1.388527586206897 ], [ 29.793987088607594, -1.388258103448276 ], [ 29.793717594936709, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 284, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.388258103448276 ], [ 29.793987088607594, -1.388527586206897 ], [ 29.794256582278482, -1.388527586206897 ], [ 29.794256582278482, -1.388258103448276 ], [ 29.793987088607594, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 285, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.388258103448276 ], [ 29.794256582278482, -1.388527586206897 ], [ 29.794526075949367, -1.388527586206897 ], [ 29.794526075949367, -1.388258103448276 ], [ 29.794256582278482, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 286, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.388258103448276 ], [ 29.794526075949367, -1.388527586206897 ], [ 29.794795569620252, -1.388527586206897 ], [ 29.794795569620252, -1.388258103448276 ], [ 29.794526075949367, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 287, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.388258103448276 ], [ 29.794795569620252, -1.388527586206897 ], [ 29.79506506329114, -1.388527586206897 ], [ 29.79506506329114, -1.388258103448276 ], [ 29.794795569620252, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 288, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.388258103448276 ], [ 29.79506506329114, -1.388527586206897 ], [ 29.795334556962025, -1.388527586206897 ], [ 29.795334556962025, -1.388258103448276 ], [ 29.79506506329114, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 289, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.388258103448276 ], [ 29.795334556962025, -1.388527586206897 ], [ 29.79560405063291, -1.388527586206897 ], [ 29.79560405063291, -1.388258103448276 ], [ 29.795334556962025, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 290, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.388258103448276 ], [ 29.79560405063291, -1.388527586206897 ], [ 29.795873544303799, -1.388527586206897 ], [ 29.795873544303799, -1.388258103448276 ], [ 29.79560405063291, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 291, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.388258103448276 ], [ 29.795873544303799, -1.388527586206897 ], [ 29.796143037974684, -1.388527586206897 ], [ 29.796143037974684, -1.388258103448276 ], [ 29.795873544303799, -1.388258103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 292, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.388527586206897 ], [ 29.777008987341773, -1.389066551724138 ], [ 29.777278481012658, -1.389066551724138 ], [ 29.777278481012658, -1.388527586206897 ], [ 29.777008987341773, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 293, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.388527586206897 ], [ 29.777278481012658, -1.389066551724138 ], [ 29.777547974683543, -1.389066551724138 ], [ 29.777547974683543, -1.388527586206897 ], [ 29.777278481012658, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 294, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.388527586206897 ], [ 29.777547974683543, -1.389066551724138 ], [ 29.777817468354431, -1.389066551724138 ], [ 29.777817468354431, -1.388527586206897 ], [ 29.777547974683543, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.388527586206897 ], [ 29.777817468354431, -1.388797068965517 ], [ 29.778086962025316, -1.388797068965517 ], [ 29.778086962025316, -1.388527586206897 ], [ 29.777817468354431, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 296, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.388527586206897 ], [ 29.778086962025316, -1.388797068965517 ], [ 29.778356455696201, -1.388797068965517 ], [ 29.778356455696201, -1.388527586206897 ], [ 29.778086962025316, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 297, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.388527586206897 ], [ 29.778356455696201, -1.388797068965517 ], [ 29.77862594936709, -1.388797068965517 ], [ 29.77862594936709, -1.388527586206897 ], [ 29.778356455696201, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 298, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.388527586206897 ], [ 29.781859873417723, -1.389336034482759 ], [ 29.782129367088608, -1.389336034482759 ], [ 29.782129367088608, -1.388527586206897 ], [ 29.781859873417723, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 299, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.388527586206897 ], [ 29.782129367088608, -1.389066551724138 ], [ 29.782398860759493, -1.389066551724138 ], [ 29.782398860759493, -1.388527586206897 ], [ 29.782129367088608, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 300, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.388527586206897 ], [ 29.782398860759493, -1.389066551724138 ], [ 29.782668354430381, -1.389066551724138 ], [ 29.782668354430381, -1.388527586206897 ], [ 29.782398860759493, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 301, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.388527586206897 ], [ 29.784285316455698, -1.388797068965517 ], [ 29.784554810126583, -1.388797068965517 ], [ 29.784554810126583, -1.388527586206897 ], [ 29.784285316455698, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 302, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.388527586206897 ], [ 29.784554810126583, -1.388797068965517 ], [ 29.784824303797468, -1.388797068965517 ], [ 29.784824303797468, -1.388527586206897 ], [ 29.784554810126583, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 303, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.388527586206897 ], [ 29.784824303797468, -1.388797068965517 ], [ 29.785093797468356, -1.388797068965517 ], [ 29.785093797468356, -1.388527586206897 ], [ 29.784824303797468, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 304, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.388527586206897 ], [ 29.785093797468356, -1.389336034482759 ], [ 29.785363291139241, -1.389336034482759 ], [ 29.785363291139241, -1.388527586206897 ], [ 29.785093797468356, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 305, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.388527586206897 ], [ 29.789944683544302, -1.389066551724138 ], [ 29.790214177215191, -1.389066551724138 ], [ 29.790214177215191, -1.388527586206897 ], [ 29.789944683544302, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 306, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.388527586206897 ], [ 29.790214177215191, -1.388797068965517 ], [ 29.790483670886076, -1.388797068965517 ], [ 29.790483670886076, -1.388527586206897 ], [ 29.790214177215191, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 307, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.388527586206897 ], [ 29.790483670886076, -1.388797068965517 ], [ 29.790753164556961, -1.388797068965517 ], [ 29.790753164556961, -1.388527586206897 ], [ 29.790483670886076, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 308, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.388527586206897 ], [ 29.790753164556961, -1.388797068965517 ], [ 29.791022658227849, -1.388797068965517 ], [ 29.791022658227849, -1.388527586206897 ], [ 29.790753164556961, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 309, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.388527586206897 ], [ 29.791022658227849, -1.388797068965517 ], [ 29.791292151898734, -1.388797068965517 ], [ 29.791292151898734, -1.388527586206897 ], [ 29.791022658227849, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 310, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.388527586206897 ], [ 29.792909113924051, -1.388797068965517 ], [ 29.793178607594935, -1.388797068965517 ], [ 29.793178607594935, -1.388527586206897 ], [ 29.792909113924051, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 311, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.388527586206897 ], [ 29.793178607594935, -1.388797068965517 ], [ 29.793448101265824, -1.388797068965517 ], [ 29.793448101265824, -1.388527586206897 ], [ 29.793178607594935, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 312, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.388527586206897 ], [ 29.793448101265824, -1.388797068965517 ], [ 29.793717594936709, -1.388797068965517 ], [ 29.793717594936709, -1.388527586206897 ], [ 29.793448101265824, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 313, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.388527586206897 ], [ 29.793717594936709, -1.388797068965517 ], [ 29.793987088607594, -1.388797068965517 ], [ 29.793987088607594, -1.388527586206897 ], [ 29.793717594936709, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 314, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.388527586206897 ], [ 29.793987088607594, -1.388797068965517 ], [ 29.794256582278482, -1.388797068965517 ], [ 29.794256582278482, -1.388527586206897 ], [ 29.793987088607594, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 315, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.388527586206897 ], [ 29.794256582278482, -1.389066551724138 ], [ 29.794526075949367, -1.389066551724138 ], [ 29.794526075949367, -1.388527586206897 ], [ 29.794256582278482, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 316, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.388527586206897 ], [ 29.794526075949367, -1.388797068965517 ], [ 29.794795569620252, -1.388797068965517 ], [ 29.794795569620252, -1.388527586206897 ], [ 29.794526075949367, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 317, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.388527586206897 ], [ 29.794795569620252, -1.388797068965517 ], [ 29.79506506329114, -1.388797068965517 ], [ 29.79506506329114, -1.388527586206897 ], [ 29.794795569620252, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 318, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.388527586206897 ], [ 29.79506506329114, -1.388797068965517 ], [ 29.795334556962025, -1.388797068965517 ], [ 29.795334556962025, -1.388527586206897 ], [ 29.79506506329114, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 319, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.388527586206897 ], [ 29.795334556962025, -1.388797068965517 ], [ 29.79560405063291, -1.388797068965517 ], [ 29.79560405063291, -1.388527586206897 ], [ 29.795334556962025, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 320, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.388527586206897 ], [ 29.79560405063291, -1.388797068965517 ], [ 29.795873544303799, -1.388797068965517 ], [ 29.795873544303799, -1.388527586206897 ], [ 29.79560405063291, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 321, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.388527586206897 ], [ 29.795873544303799, -1.389066551724138 ], [ 29.796143037974684, -1.389066551724138 ], [ 29.796143037974684, -1.388527586206897 ], [ 29.795873544303799, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 322, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.388797068965517 ], [ 29.777817468354431, -1.389066551724138 ], [ 29.778086962025316, -1.389066551724138 ], [ 29.778086962025316, -1.388797068965517 ], [ 29.777817468354431, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 323, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.388797068965517 ], [ 29.782668354430381, -1.389066551724138 ], [ 29.782937848101266, -1.389066551724138 ], [ 29.782937848101266, -1.388797068965517 ], [ 29.782668354430381, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 324, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.388797068965517 ], [ 29.782937848101266, -1.389066551724138 ], [ 29.783207341772151, -1.389066551724138 ], [ 29.783207341772151, -1.388797068965517 ], [ 29.782937848101266, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 325, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.388797068965517 ], [ 29.783207341772151, -1.389066551724138 ], [ 29.784015822784809, -1.389066551724138 ], [ 29.784015822784809, -1.388797068965517 ], [ 29.783207341772151, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 326, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.388797068965517 ], [ 29.784015822784809, -1.389066551724138 ], [ 29.784285316455698, -1.389066551724138 ], [ 29.784285316455698, -1.388797068965517 ], [ 29.784015822784809, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 327, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.388797068965517 ], [ 29.784285316455698, -1.389066551724138 ], [ 29.784554810126583, -1.389066551724138 ], [ 29.784554810126583, -1.388797068965517 ], [ 29.784285316455698, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 328, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.388797068965517 ], [ 29.784554810126583, -1.389336034482759 ], [ 29.784824303797468, -1.389336034482759 ], [ 29.784824303797468, -1.388797068965517 ], [ 29.784554810126583, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 329, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.388797068965517 ], [ 29.784824303797468, -1.389336034482759 ], [ 29.785093797468356, -1.389336034482759 ], [ 29.785093797468356, -1.388797068965517 ], [ 29.784824303797468, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 330, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.388797068965517 ], [ 29.790214177215191, -1.389066551724138 ], [ 29.790483670886076, -1.389066551724138 ], [ 29.790483670886076, -1.388797068965517 ], [ 29.790214177215191, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 331, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.388797068965517 ], [ 29.790483670886076, -1.389066551724138 ], [ 29.790753164556961, -1.389066551724138 ], [ 29.790753164556961, -1.388797068965517 ], [ 29.790483670886076, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 332, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.388797068965517 ], [ 29.790753164556961, -1.389066551724138 ], [ 29.791022658227849, -1.389066551724138 ], [ 29.791022658227849, -1.388797068965517 ], [ 29.790753164556961, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 333, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.388797068965517 ], [ 29.792370126582277, -1.389066551724138 ], [ 29.793178607594935, -1.389066551724138 ], [ 29.793178607594935, -1.388797068965517 ], [ 29.792370126582277, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 334, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.388797068965517 ], [ 29.793178607594935, -1.389066551724138 ], [ 29.793448101265824, -1.389066551724138 ], [ 29.793448101265824, -1.388797068965517 ], [ 29.793178607594935, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 335, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.388797068965517 ], [ 29.793448101265824, -1.389066551724138 ], [ 29.793717594936709, -1.389066551724138 ], [ 29.793717594936709, -1.388797068965517 ], [ 29.793448101265824, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 336, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.388797068965517 ], [ 29.793717594936709, -1.389066551724138 ], [ 29.793987088607594, -1.389066551724138 ], [ 29.793987088607594, -1.388797068965517 ], [ 29.793717594936709, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 337, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.388797068965517 ], [ 29.793987088607594, -1.389066551724138 ], [ 29.794256582278482, -1.389066551724138 ], [ 29.794256582278482, -1.388797068965517 ], [ 29.793987088607594, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 338, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.388797068965517 ], [ 29.794526075949367, -1.389336034482759 ], [ 29.794795569620252, -1.389336034482759 ], [ 29.794795569620252, -1.388797068965517 ], [ 29.794526075949367, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 339, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.388797068965517 ], [ 29.794795569620252, -1.389336034482759 ], [ 29.79506506329114, -1.389336034482759 ], [ 29.79506506329114, -1.388797068965517 ], [ 29.794795569620252, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 340, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.388797068965517 ], [ 29.79506506329114, -1.389066551724138 ], [ 29.795334556962025, -1.389066551724138 ], [ 29.795334556962025, -1.388797068965517 ], [ 29.79506506329114, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 341, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.388797068965517 ], [ 29.795334556962025, -1.389066551724138 ], [ 29.79560405063291, -1.389066551724138 ], [ 29.79560405063291, -1.388797068965517 ], [ 29.795334556962025, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 342, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.388797068965517 ], [ 29.79560405063291, -1.389066551724138 ], [ 29.795873544303799, -1.389066551724138 ], [ 29.795873544303799, -1.388797068965517 ], [ 29.79560405063291, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 343, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.389066551724138 ], [ 29.777278481012658, -1.389605517241379 ], [ 29.777547974683543, -1.389605517241379 ], [ 29.777547974683543, -1.389066551724138 ], [ 29.777278481012658, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 344, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.389066551724138 ], [ 29.777547974683543, -1.389336034482759 ], [ 29.777817468354431, -1.389336034482759 ], [ 29.777817468354431, -1.389066551724138 ], [ 29.777547974683543, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 345, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.388797068965517 ], [ 29.778356455696201, -1.388797068965517 ], [ 29.778356455696201, -1.389875 ], [ 29.778086962025316, -1.389875 ], [ 29.778086962025316, -1.389336034482759 ], [ 29.777817468354431, -1.389336034482759 ], [ 29.777817468354431, -1.389066551724138 ], [ 29.778086962025316, -1.389066551724138 ], [ 29.778086962025316, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 346, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.389066551724138 ], [ 29.782129367088608, -1.389336034482759 ], [ 29.782398860759493, -1.389336034482759 ], [ 29.782398860759493, -1.389066551724138 ], [ 29.782129367088608, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 347, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.389066551724138 ], [ 29.782398860759493, -1.389336034482759 ], [ 29.782668354430381, -1.389336034482759 ], [ 29.782668354430381, -1.389066551724138 ], [ 29.782398860759493, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 348, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.389066551724138 ], [ 29.782668354430381, -1.389336034482759 ], [ 29.782937848101266, -1.389336034482759 ], [ 29.782937848101266, -1.389066551724138 ], [ 29.782668354430381, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 349, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.389066551724138 ], [ 29.782937848101266, -1.389605517241379 ], [ 29.783207341772151, -1.389605517241379 ], [ 29.783207341772151, -1.389066551724138 ], [ 29.782937848101266, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 350, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.389066551724138 ], [ 29.783207341772151, -1.389605517241379 ], [ 29.783476835443039, -1.389605517241379 ], [ 29.783476835443039, -1.389066551724138 ], [ 29.783207341772151, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 351, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.389066551724138 ], [ 29.783476835443039, -1.389605517241379 ], [ 29.784015822784809, -1.389605517241379 ], [ 29.784015822784809, -1.389066551724138 ], [ 29.783476835443039, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 352, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.389066551724138 ], [ 29.784015822784809, -1.389605517241379 ], [ 29.784285316455698, -1.389605517241379 ], [ 29.784285316455698, -1.389066551724138 ], [ 29.784015822784809, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 353, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.389066551724138 ], [ 29.784285316455698, -1.389605517241379 ], [ 29.784554810126583, -1.389605517241379 ], [ 29.784554810126583, -1.389066551724138 ], [ 29.784285316455698, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 354, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.389066551724138 ], [ 29.791831139240507, -1.389875 ], [ 29.792100632911392, -1.389875 ], [ 29.792100632911392, -1.389336034482759 ], [ 29.792909113924051, -1.389336034482759 ], [ 29.792909113924051, -1.389066551724138 ], [ 29.791831139240507, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 355, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.389066551724138 ], [ 29.792909113924051, -1.389336034482759 ], [ 29.793178607594935, -1.389336034482759 ], [ 29.793178607594935, -1.389066551724138 ], [ 29.792909113924051, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 356, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.389066551724138 ], [ 29.793178607594935, -1.389336034482759 ], [ 29.793448101265824, -1.389336034482759 ], [ 29.793448101265824, -1.389066551724138 ], [ 29.793178607594935, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 357, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.389066551724138 ], [ 29.793448101265824, -1.389336034482759 ], [ 29.793717594936709, -1.389336034482759 ], [ 29.793717594936709, -1.389066551724138 ], [ 29.793448101265824, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 358, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.389066551724138 ], [ 29.793717594936709, -1.389336034482759 ], [ 29.793987088607594, -1.389336034482759 ], [ 29.793987088607594, -1.389066551724138 ], [ 29.793717594936709, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 359, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.389066551724138 ], [ 29.793987088607594, -1.389336034482759 ], [ 29.794256582278482, -1.389336034482759 ], [ 29.794256582278482, -1.389066551724138 ], [ 29.793987088607594, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 360, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.389066551724138 ], [ 29.794256582278482, -1.389336034482759 ], [ 29.794526075949367, -1.389336034482759 ], [ 29.794526075949367, -1.389066551724138 ], [ 29.794256582278482, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 361, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.389066551724138 ], [ 29.79506506329114, -1.389605517241379 ], [ 29.795334556962025, -1.389605517241379 ], [ 29.795334556962025, -1.389066551724138 ], [ 29.79506506329114, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 362, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.389066551724138 ], [ 29.795334556962025, -1.389605517241379 ], [ 29.79560405063291, -1.389605517241379 ], [ 29.79560405063291, -1.389066551724138 ], [ 29.795334556962025, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 363, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.389066551724138 ], [ 29.79560405063291, -1.389875 ], [ 29.795873544303799, -1.389875 ], [ 29.795873544303799, -1.389066551724138 ], [ 29.79560405063291, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 364, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.389336034482759 ], [ 29.777547974683543, -1.389605517241379 ], [ 29.777817468354431, -1.389605517241379 ], [ 29.777817468354431, -1.389336034482759 ], [ 29.777547974683543, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 365, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.389336034482759 ], [ 29.777817468354431, -1.389605517241379 ], [ 29.778086962025316, -1.389605517241379 ], [ 29.778086962025316, -1.389336034482759 ], [ 29.777817468354431, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 366, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.389336034482759 ], [ 29.782398860759493, -1.389875 ], [ 29.782668354430381, -1.389875 ], [ 29.782668354430381, -1.389336034482759 ], [ 29.782398860759493, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 367, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.389336034482759 ], [ 29.782668354430381, -1.389875 ], [ 29.782937848101266, -1.389875 ], [ 29.782937848101266, -1.389336034482759 ], [ 29.782668354430381, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 368, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.389336034482759 ], [ 29.784554810126583, -1.389605517241379 ], [ 29.784824303797468, -1.389605517241379 ], [ 29.784824303797468, -1.389336034482759 ], [ 29.784554810126583, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 369, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.389336034482759 ], [ 29.784824303797468, -1.389605517241379 ], [ 29.785093797468356, -1.389605517241379 ], [ 29.785093797468356, -1.389336034482759 ], [ 29.784824303797468, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 370, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.389336034482759 ], [ 29.785093797468356, -1.389605517241379 ], [ 29.785363291139241, -1.389605517241379 ], [ 29.785363291139241, -1.389336034482759 ], [ 29.785093797468356, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 371, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.389336034482759 ], [ 29.785363291139241, -1.389875 ], [ 29.785632784810126, -1.389875 ], [ 29.785632784810126, -1.389336034482759 ], [ 29.785363291139241, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 372, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.389336034482759 ], [ 29.792100632911392, -1.389605517241379 ], [ 29.792370126582277, -1.389605517241379 ], [ 29.792370126582277, -1.389336034482759 ], [ 29.792100632911392, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 373, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.389336034482759 ], [ 29.792370126582277, -1.389605517241379 ], [ 29.792909113924051, -1.389605517241379 ], [ 29.792909113924051, -1.389336034482759 ], [ 29.792370126582277, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 374, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.389336034482759 ], [ 29.792909113924051, -1.389605517241379 ], [ 29.793987088607594, -1.389605517241379 ], [ 29.793987088607594, -1.389336034482759 ], [ 29.792909113924051, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 375, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.389336034482759 ], [ 29.793987088607594, -1.389605517241379 ], [ 29.794256582278482, -1.389605517241379 ], [ 29.794256582278482, -1.389336034482759 ], [ 29.793987088607594, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 376, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.389336034482759 ], [ 29.794256582278482, -1.389605517241379 ], [ 29.794526075949367, -1.389605517241379 ], [ 29.794526075949367, -1.389336034482759 ], [ 29.794256582278482, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 377, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.389336034482759 ], [ 29.794526075949367, -1.389605517241379 ], [ 29.794795569620252, -1.389605517241379 ], [ 29.794795569620252, -1.389336034482759 ], [ 29.794526075949367, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 378, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.389336034482759 ], [ 29.794795569620252, -1.389605517241379 ], [ 29.79506506329114, -1.389605517241379 ], [ 29.79506506329114, -1.389336034482759 ], [ 29.794795569620252, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 379, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.389605517241379 ], [ 29.777817468354431, -1.389875 ], [ 29.778086962025316, -1.389875 ], [ 29.778086962025316, -1.389605517241379 ], [ 29.777817468354431, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 380, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.389605517241379 ], [ 29.782937848101266, -1.389875 ], [ 29.783207341772151, -1.389875 ], [ 29.783207341772151, -1.389605517241379 ], [ 29.782937848101266, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 381, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.389605517241379 ], [ 29.783207341772151, -1.389875 ], [ 29.783476835443039, -1.389875 ], [ 29.783476835443039, -1.389605517241379 ], [ 29.783207341772151, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 382, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.389605517241379 ], [ 29.783476835443039, -1.389875 ], [ 29.784285316455698, -1.389875 ], [ 29.784285316455698, -1.389605517241379 ], [ 29.783476835443039, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 383, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.389605517241379 ], [ 29.784285316455698, -1.389875 ], [ 29.784554810126583, -1.389875 ], [ 29.784554810126583, -1.389605517241379 ], [ 29.784285316455698, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 384, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.389605517241379 ], [ 29.784554810126583, -1.389875 ], [ 29.784824303797468, -1.389875 ], [ 29.784824303797468, -1.389605517241379 ], [ 29.784554810126583, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 385, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.389605517241379 ], [ 29.784824303797468, -1.389875 ], [ 29.785093797468356, -1.389875 ], [ 29.785093797468356, -1.389605517241379 ], [ 29.784824303797468, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 386, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.389605517241379 ], [ 29.785093797468356, -1.389875 ], [ 29.785363291139241, -1.389875 ], [ 29.785363291139241, -1.389605517241379 ], [ 29.785093797468356, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 387, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.389605517241379 ], [ 29.792100632911392, -1.389875 ], [ 29.792370126582277, -1.389875 ], [ 29.792370126582277, -1.389605517241379 ], [ 29.792100632911392, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 388, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.389605517241379 ], [ 29.792370126582277, -1.389875 ], [ 29.793178607594935, -1.389875 ], [ 29.793178607594935, -1.389605517241379 ], [ 29.792370126582277, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 389, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.389605517241379 ], [ 29.793178607594935, -1.389875 ], [ 29.794256582278482, -1.389875 ], [ 29.794256582278482, -1.389605517241379 ], [ 29.793178607594935, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 390, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.389605517241379 ], [ 29.794795569620252, -1.389875 ], [ 29.79506506329114, -1.389875 ], [ 29.79506506329114, -1.389605517241379 ], [ 29.794795569620252, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 391, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.389605517241379 ], [ 29.79506506329114, -1.389875 ], [ 29.795334556962025, -1.389875 ], [ 29.795334556962025, -1.389605517241379 ], [ 29.79506506329114, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 392, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.389605517241379 ], [ 29.795334556962025, -1.389875 ], [ 29.79560405063291, -1.389875 ], [ 29.79560405063291, -1.389605517241379 ], [ 29.795334556962025, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.388527586206897 ], [ 29.77862594936709, -1.390413965517241 ], [ 29.778895443037975, -1.390413965517241 ], [ 29.778895443037975, -1.388527586206897 ], [ 29.77862594936709, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 394, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.388527586206897 ], [ 29.791292151898734, -1.390952931034483 ], [ 29.791561645569619, -1.390952931034483 ], [ 29.791561645569619, -1.388797068965517 ], [ 29.792909113924051, -1.388797068965517 ], [ 29.792909113924051, -1.388527586206897 ], [ 29.791292151898734, -1.388527586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 395, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.388797068965517 ], [ 29.778356455696201, -1.390413965517241 ], [ 29.77862594936709, -1.390413965517241 ], [ 29.77862594936709, -1.388797068965517 ], [ 29.778356455696201, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 396, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.388797068965517 ], [ 29.791022658227849, -1.390952931034483 ], [ 29.791292151898734, -1.390952931034483 ], [ 29.791292151898734, -1.388797068965517 ], [ 29.791022658227849, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 397, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.388797068965517 ], [ 29.791561645569619, -1.390683448275862 ], [ 29.791831139240507, -1.390683448275862 ], [ 29.791831139240507, -1.389066551724138 ], [ 29.792370126582277, -1.389066551724138 ], [ 29.792370126582277, -1.388797068965517 ], [ 29.791561645569619, -1.388797068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 398, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.389066551724138 ], [ 29.790214177215191, -1.390683448275862 ], [ 29.790483670886076, -1.390683448275862 ], [ 29.790483670886076, -1.389066551724138 ], [ 29.790214177215191, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 399, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.389066551724138 ], [ 29.790483670886076, -1.390683448275862 ], [ 29.790753164556961, -1.390683448275862 ], [ 29.790753164556961, -1.389066551724138 ], [ 29.790483670886076, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 400, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.389066551724138 ], [ 29.790753164556961, -1.390683448275862 ], [ 29.791022658227849, -1.390683448275862 ], [ 29.791022658227849, -1.389066551724138 ], [ 29.790753164556961, -1.389066551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 401, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.389336034482759 ], [ 29.782129367088608, -1.390413965517241 ], [ 29.782398860759493, -1.390413965517241 ], [ 29.782398860759493, -1.389336034482759 ], [ 29.782129367088608, -1.389336034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 402, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.389605517241379 ], [ 29.777547974683543, -1.390413965517241 ], [ 29.777817468354431, -1.390413965517241 ], [ 29.777817468354431, -1.389605517241379 ], [ 29.777547974683543, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 403, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.389875 ], [ 29.777817468354431, -1.390413965517241 ], [ 29.778086962025316, -1.390413965517241 ], [ 29.778086962025316, -1.389875 ], [ 29.777817468354431, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 404, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.389875 ], [ 29.778086962025316, -1.390413965517241 ], [ 29.778356455696201, -1.390413965517241 ], [ 29.778356455696201, -1.389875 ], [ 29.778086962025316, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 405, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.389875 ], [ 29.782398860759493, -1.390413965517241 ], [ 29.782668354430381, -1.390413965517241 ], [ 29.782668354430381, -1.389875 ], [ 29.782398860759493, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 406, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.389875 ], [ 29.782668354430381, -1.390413965517241 ], [ 29.782937848101266, -1.390413965517241 ], [ 29.782937848101266, -1.389875 ], [ 29.782668354430381, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 407, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.389875 ], [ 29.782937848101266, -1.390413965517241 ], [ 29.783207341772151, -1.390413965517241 ], [ 29.783207341772151, -1.389875 ], [ 29.782937848101266, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 408, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.389875 ], [ 29.783207341772151, -1.390683448275862 ], [ 29.783476835443039, -1.390683448275862 ], [ 29.783476835443039, -1.389875 ], [ 29.783207341772151, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 409, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.389875 ], [ 29.783476835443039, -1.390683448275862 ], [ 29.784015822784809, -1.390683448275862 ], [ 29.784015822784809, -1.389875 ], [ 29.783476835443039, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 410, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.389875 ], [ 29.784015822784809, -1.390683448275862 ], [ 29.784285316455698, -1.390683448275862 ], [ 29.784285316455698, -1.389875 ], [ 29.784015822784809, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 411, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.389875 ], [ 29.784285316455698, -1.390413965517241 ], [ 29.784554810126583, -1.390413965517241 ], [ 29.784554810126583, -1.389875 ], [ 29.784285316455698, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 412, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.389875 ], [ 29.784554810126583, -1.390413965517241 ], [ 29.784824303797468, -1.390413965517241 ], [ 29.784824303797468, -1.389875 ], [ 29.784554810126583, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 413, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.389875 ], [ 29.784824303797468, -1.390413965517241 ], [ 29.785093797468356, -1.390413965517241 ], [ 29.785093797468356, -1.389875 ], [ 29.784824303797468, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 414, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.389875 ], [ 29.785093797468356, -1.390413965517241 ], [ 29.785363291139241, -1.390413965517241 ], [ 29.785363291139241, -1.389875 ], [ 29.785093797468356, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 415, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.389875 ], [ 29.785363291139241, -1.390413965517241 ], [ 29.785632784810126, -1.390413965517241 ], [ 29.785632784810126, -1.389875 ], [ 29.785363291139241, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 416, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.389875 ], [ 29.785632784810126, -1.390413965517241 ], [ 29.785902278481014, -1.390413965517241 ], [ 29.785902278481014, -1.390683448275862 ], [ 29.786171772151899, -1.390683448275862 ], [ 29.786171772151899, -1.389875 ], [ 29.785632784810126, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 417, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.389875 ], [ 29.791831139240507, -1.390413965517241 ], [ 29.792100632911392, -1.390413965517241 ], [ 29.792100632911392, -1.389875 ], [ 29.791831139240507, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 418, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.389875 ], [ 29.792100632911392, -1.390413965517241 ], [ 29.792370126582277, -1.390413965517241 ], [ 29.792370126582277, -1.389875 ], [ 29.792100632911392, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 419, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.389875 ], [ 29.792370126582277, -1.390413965517241 ], [ 29.792909113924051, -1.390413965517241 ], [ 29.792909113924051, -1.389875 ], [ 29.792370126582277, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 420, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.389605517241379 ], [ 29.794526075949367, -1.389605517241379 ], [ 29.794526075949367, -1.390413965517241 ], [ 29.792909113924051, -1.390413965517241 ], [ 29.792909113924051, -1.389875 ], [ 29.794256582278482, -1.389875 ], [ 29.794256582278482, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 421, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.389875 ], [ 29.79506506329114, -1.390952931034483 ], [ 29.795334556962025, -1.390952931034483 ], [ 29.795334556962025, -1.389875 ], [ 29.79506506329114, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 422, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.389875 ], [ 29.795334556962025, -1.391222413793104 ], [ 29.79560405063291, -1.391222413793104 ], [ 29.79560405063291, -1.389875 ], [ 29.795334556962025, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 423, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.390413965517241 ], [ 29.771349620253165, -1.390683448275862 ], [ 29.773505569620252, -1.390683448275862 ], [ 29.773505569620252, -1.390413965517241 ], [ 29.771349620253165, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 424, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.390413965517241 ], [ 29.777817468354431, -1.391761379310345 ], [ 29.778086962025316, -1.391761379310345 ], [ 29.778086962025316, -1.390413965517241 ], [ 29.777817468354431, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 425, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.390413965517241 ], [ 29.778086962025316, -1.391491896551724 ], [ 29.778356455696201, -1.391491896551724 ], [ 29.778356455696201, -1.390413965517241 ], [ 29.778086962025316, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 426, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.390413965517241 ], [ 29.778356455696201, -1.390683448275862 ], [ 29.77862594936709, -1.390683448275862 ], [ 29.77862594936709, -1.390413965517241 ], [ 29.778356455696201, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 427, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.390413965517241 ], [ 29.77862594936709, -1.390683448275862 ], [ 29.778895443037975, -1.390683448275862 ], [ 29.778895443037975, -1.390413965517241 ], [ 29.77862594936709, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 428, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.390413965517241 ], [ 29.778895443037975, -1.391491896551724 ], [ 29.77916493670886, -1.391491896551724 ], [ 29.77916493670886, -1.390413965517241 ], [ 29.778895443037975, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 429, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.390413965517241 ], [ 29.782398860759493, -1.391222413793104 ], [ 29.782668354430381, -1.391222413793104 ], [ 29.782668354430381, -1.390413965517241 ], [ 29.782398860759493, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 430, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.390413965517241 ], [ 29.782668354430381, -1.390952931034483 ], [ 29.782937848101266, -1.390952931034483 ], [ 29.782937848101266, -1.390413965517241 ], [ 29.782668354430381, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 431, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.390413965517241 ], [ 29.782937848101266, -1.390952931034483 ], [ 29.783207341772151, -1.390952931034483 ], [ 29.783207341772151, -1.390413965517241 ], [ 29.782937848101266, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 432, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.390413965517241 ], [ 29.784285316455698, -1.390683448275862 ], [ 29.784554810126583, -1.390683448275862 ], [ 29.784554810126583, -1.390413965517241 ], [ 29.784285316455698, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 433, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.390413965517241 ], [ 29.784554810126583, -1.390683448275862 ], [ 29.784824303797468, -1.390683448275862 ], [ 29.784824303797468, -1.390413965517241 ], [ 29.784554810126583, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 434, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.390413965517241 ], [ 29.784824303797468, -1.390683448275862 ], [ 29.785093797468356, -1.390683448275862 ], [ 29.785093797468356, -1.390413965517241 ], [ 29.784824303797468, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 435, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.390413965517241 ], [ 29.785093797468356, -1.390683448275862 ], [ 29.785363291139241, -1.390683448275862 ], [ 29.785363291139241, -1.390413965517241 ], [ 29.785093797468356, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 436, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.390413965517241 ], [ 29.785363291139241, -1.390683448275862 ], [ 29.785632784810126, -1.390683448275862 ], [ 29.785632784810126, -1.390413965517241 ], [ 29.785363291139241, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 437, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.390413965517241 ], [ 29.785632784810126, -1.390683448275862 ], [ 29.785902278481014, -1.390683448275862 ], [ 29.785902278481014, -1.390413965517241 ], [ 29.785632784810126, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 438, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.390413965517241 ], [ 29.791831139240507, -1.390683448275862 ], [ 29.792100632911392, -1.390683448275862 ], [ 29.792100632911392, -1.390413965517241 ], [ 29.791831139240507, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 439, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.390413965517241 ], [ 29.792100632911392, -1.390683448275862 ], [ 29.792370126582277, -1.390683448275862 ], [ 29.792370126582277, -1.390413965517241 ], [ 29.792100632911392, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 440, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.390413965517241 ], [ 29.792370126582277, -1.390683448275862 ], [ 29.792909113924051, -1.390683448275862 ], [ 29.792909113924051, -1.390413965517241 ], [ 29.792370126582277, -1.390413965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 441, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.389605517241379 ], [ 29.794795569620252, -1.389605517241379 ], [ 29.794795569620252, -1.390683448275862 ], [ 29.792909113924051, -1.390683448275862 ], [ 29.792909113924051, -1.390413965517241 ], [ 29.794526075949367, -1.390413965517241 ], [ 29.794526075949367, -1.389605517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 442, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.390683448275862 ], [ 29.771080126582277, -1.390952931034483 ], [ 29.771349620253165, -1.390952931034483 ], [ 29.771349620253165, -1.390683448275862 ], [ 29.771080126582277, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 443, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.390683448275862 ], [ 29.771349620253165, -1.390952931034483 ], [ 29.77161911392405, -1.390952931034483 ], [ 29.77161911392405, -1.390683448275862 ], [ 29.771349620253165, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 444, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.390683448275862 ], [ 29.77161911392405, -1.390952931034483 ], [ 29.773236075949367, -1.390952931034483 ], [ 29.773236075949367, -1.390683448275862 ], [ 29.77161911392405, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 445, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.390683448275862 ], [ 29.773236075949367, -1.390952931034483 ], [ 29.773505569620252, -1.390952931034483 ], [ 29.773505569620252, -1.390683448275862 ], [ 29.773236075949367, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 446, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.390683448275862 ], [ 29.773505569620252, -1.390952931034483 ], [ 29.77377506329114, -1.390952931034483 ], [ 29.77377506329114, -1.390683448275862 ], [ 29.773505569620252, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 447, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.390683448275862 ], [ 29.778356455696201, -1.391491896551724 ], [ 29.77862594936709, -1.391491896551724 ], [ 29.77862594936709, -1.390683448275862 ], [ 29.778356455696201, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 448, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.390683448275862 ], [ 29.77862594936709, -1.391491896551724 ], [ 29.778895443037975, -1.391491896551724 ], [ 29.778895443037975, -1.390683448275862 ], [ 29.77862594936709, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 449, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.390683448275862 ], [ 29.783207341772151, -1.390952931034483 ], [ 29.783476835443039, -1.390952931034483 ], [ 29.783476835443039, -1.390683448275862 ], [ 29.783207341772151, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 450, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.390683448275862 ], [ 29.783476835443039, -1.390952931034483 ], [ 29.784015822784809, -1.390952931034483 ], [ 29.784015822784809, -1.390683448275862 ], [ 29.783476835443039, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 451, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.390683448275862 ], [ 29.784015822784809, -1.390952931034483 ], [ 29.784285316455698, -1.390952931034483 ], [ 29.784285316455698, -1.390683448275862 ], [ 29.784015822784809, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 452, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.390683448275862 ], [ 29.784285316455698, -1.390952931034483 ], [ 29.784554810126583, -1.390952931034483 ], [ 29.784554810126583, -1.390683448275862 ], [ 29.784285316455698, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 453, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.390683448275862 ], [ 29.784554810126583, -1.390952931034483 ], [ 29.784824303797468, -1.390952931034483 ], [ 29.784824303797468, -1.390683448275862 ], [ 29.784554810126583, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 454, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.390683448275862 ], [ 29.784824303797468, -1.390952931034483 ], [ 29.785093797468356, -1.390952931034483 ], [ 29.785093797468356, -1.390683448275862 ], [ 29.784824303797468, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 455, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.390683448275862 ], [ 29.785093797468356, -1.390952931034483 ], [ 29.785363291139241, -1.390952931034483 ], [ 29.785363291139241, -1.390683448275862 ], [ 29.785093797468356, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 456, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.390683448275862 ], [ 29.785363291139241, -1.390952931034483 ], [ 29.785632784810126, -1.390952931034483 ], [ 29.785632784810126, -1.390683448275862 ], [ 29.785363291139241, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 457, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.390683448275862 ], [ 29.785632784810126, -1.390952931034483 ], [ 29.785902278481014, -1.390952931034483 ], [ 29.785902278481014, -1.390683448275862 ], [ 29.785632784810126, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 458, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.390683448275862 ], [ 29.785902278481014, -1.390952931034483 ], [ 29.786171772151899, -1.390952931034483 ], [ 29.786171772151899, -1.390683448275862 ], [ 29.785902278481014, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 459, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.390683448275862 ], [ 29.786171772151899, -1.391222413793104 ], [ 29.786441265822784, -1.391222413793104 ], [ 29.786441265822784, -1.390683448275862 ], [ 29.786171772151899, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 460, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.390683448275862 ], [ 29.789944683544302, -1.390952931034483 ], [ 29.790214177215191, -1.390952931034483 ], [ 29.790214177215191, -1.390683448275862 ], [ 29.789944683544302, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 461, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.390683448275862 ], [ 29.790214177215191, -1.390952931034483 ], [ 29.790483670886076, -1.390952931034483 ], [ 29.790483670886076, -1.390683448275862 ], [ 29.790214177215191, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 462, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.390683448275862 ], [ 29.790483670886076, -1.390952931034483 ], [ 29.790753164556961, -1.390952931034483 ], [ 29.790753164556961, -1.390683448275862 ], [ 29.790483670886076, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 463, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.390683448275862 ], [ 29.790753164556961, -1.390952931034483 ], [ 29.791022658227849, -1.390952931034483 ], [ 29.791022658227849, -1.390683448275862 ], [ 29.790753164556961, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 464, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.390683448275862 ], [ 29.791561645569619, -1.390952931034483 ], [ 29.791831139240507, -1.390952931034483 ], [ 29.791831139240507, -1.390683448275862 ], [ 29.791561645569619, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 465, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.390683448275862 ], [ 29.791831139240507, -1.390952931034483 ], [ 29.792100632911392, -1.390952931034483 ], [ 29.792100632911392, -1.390683448275862 ], [ 29.791831139240507, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 466, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.390683448275862 ], [ 29.792100632911392, -1.390952931034483 ], [ 29.792370126582277, -1.390952931034483 ], [ 29.792370126582277, -1.390683448275862 ], [ 29.792100632911392, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 467, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.390683448275862 ], [ 29.792370126582277, -1.390952931034483 ], [ 29.792909113924051, -1.390952931034483 ], [ 29.792909113924051, -1.390683448275862 ], [ 29.792370126582277, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 468, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.390683448275862 ], [ 29.792909113924051, -1.390952931034483 ], [ 29.793178607594935, -1.390952931034483 ], [ 29.793178607594935, -1.390683448275862 ], [ 29.792909113924051, -1.390683448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 469, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.389875 ], [ 29.79506506329114, -1.389875 ], [ 29.79506506329114, -1.390952931034483 ], [ 29.793178607594935, -1.390952931034483 ], [ 29.793178607594935, -1.390683448275862 ], [ 29.794795569620252, -1.390683448275862 ], [ 29.794795569620252, -1.389875 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 470, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.390952931034483 ], [ 29.770810632911392, -1.391222413793104 ], [ 29.771080126582277, -1.391222413793104 ], [ 29.771080126582277, -1.390952931034483 ], [ 29.770810632911392, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 471, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.390952931034483 ], [ 29.771080126582277, -1.391222413793104 ], [ 29.771349620253165, -1.391222413793104 ], [ 29.771349620253165, -1.390952931034483 ], [ 29.771080126582277, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 472, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.390952931034483 ], [ 29.771349620253165, -1.391222413793104 ], [ 29.77161911392405, -1.391222413793104 ], [ 29.77161911392405, -1.390952931034483 ], [ 29.771349620253165, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 473, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.390952931034483 ], [ 29.77161911392405, -1.391222413793104 ], [ 29.773236075949367, -1.391222413793104 ], [ 29.773236075949367, -1.390952931034483 ], [ 29.77161911392405, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 474, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.390952931034483 ], [ 29.773236075949367, -1.391222413793104 ], [ 29.773505569620252, -1.391222413793104 ], [ 29.773505569620252, -1.390952931034483 ], [ 29.773236075949367, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 475, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.390952931034483 ], [ 29.773505569620252, -1.391222413793104 ], [ 29.77377506329114, -1.391222413793104 ], [ 29.77377506329114, -1.390952931034483 ], [ 29.773505569620252, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 476, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.390952931034483 ], [ 29.77377506329114, -1.391222413793104 ], [ 29.77431405063291, -1.391222413793104 ], [ 29.77431405063291, -1.390952931034483 ], [ 29.77377506329114, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 477, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.390952931034483 ], [ 29.782668354430381, -1.391222413793104 ], [ 29.782937848101266, -1.391222413793104 ], [ 29.782937848101266, -1.390952931034483 ], [ 29.782668354430381, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 478, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.390952931034483 ], [ 29.782937848101266, -1.391222413793104 ], [ 29.783207341772151, -1.391222413793104 ], [ 29.783207341772151, -1.390952931034483 ], [ 29.782937848101266, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 479, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.390952931034483 ], [ 29.783207341772151, -1.391222413793104 ], [ 29.783476835443039, -1.391222413793104 ], [ 29.783476835443039, -1.390952931034483 ], [ 29.783207341772151, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 480, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.390952931034483 ], [ 29.784285316455698, -1.391222413793104 ], [ 29.784554810126583, -1.391222413793104 ], [ 29.784554810126583, -1.390952931034483 ], [ 29.784285316455698, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 481, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.390952931034483 ], [ 29.784554810126583, -1.391222413793104 ], [ 29.784824303797468, -1.391222413793104 ], [ 29.784824303797468, -1.390952931034483 ], [ 29.784554810126583, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 482, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.390952931034483 ], [ 29.784824303797468, -1.391222413793104 ], [ 29.785093797468356, -1.391222413793104 ], [ 29.785093797468356, -1.390952931034483 ], [ 29.784824303797468, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 483, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.390952931034483 ], [ 29.785093797468356, -1.391222413793104 ], [ 29.785363291139241, -1.391222413793104 ], [ 29.785363291139241, -1.390952931034483 ], [ 29.785093797468356, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 484, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.390952931034483 ], [ 29.785363291139241, -1.391222413793104 ], [ 29.785632784810126, -1.391222413793104 ], [ 29.785632784810126, -1.390952931034483 ], [ 29.785363291139241, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 485, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.390952931034483 ], [ 29.785632784810126, -1.391222413793104 ], [ 29.785902278481014, -1.391222413793104 ], [ 29.785902278481014, -1.390952931034483 ], [ 29.785632784810126, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 486, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.390952931034483 ], [ 29.785902278481014, -1.391222413793104 ], [ 29.786171772151899, -1.391222413793104 ], [ 29.786171772151899, -1.390952931034483 ], [ 29.785902278481014, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 487, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.390952931034483 ], [ 29.789136202531644, -1.391222413793104 ], [ 29.789944683544302, -1.391222413793104 ], [ 29.789944683544302, -1.390952931034483 ], [ 29.789136202531644, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 488, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.390952931034483 ], [ 29.789944683544302, -1.391222413793104 ], [ 29.790214177215191, -1.391222413793104 ], [ 29.790214177215191, -1.390952931034483 ], [ 29.789944683544302, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 489, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.390952931034483 ], [ 29.790214177215191, -1.391222413793104 ], [ 29.790483670886076, -1.391222413793104 ], [ 29.790483670886076, -1.390952931034483 ], [ 29.790214177215191, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 490, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.390952931034483 ], [ 29.790483670886076, -1.391222413793104 ], [ 29.790753164556961, -1.391222413793104 ], [ 29.790753164556961, -1.390952931034483 ], [ 29.790483670886076, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 491, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.390952931034483 ], [ 29.790753164556961, -1.391222413793104 ], [ 29.791022658227849, -1.391222413793104 ], [ 29.791022658227849, -1.390952931034483 ], [ 29.790753164556961, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 492, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.390952931034483 ], [ 29.791022658227849, -1.391222413793104 ], [ 29.791292151898734, -1.391222413793104 ], [ 29.791292151898734, -1.390952931034483 ], [ 29.791022658227849, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 493, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.390952931034483 ], [ 29.791292151898734, -1.391222413793104 ], [ 29.791561645569619, -1.391222413793104 ], [ 29.791561645569619, -1.390952931034483 ], [ 29.791292151898734, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 494, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.390952931034483 ], [ 29.791561645569619, -1.391222413793104 ], [ 29.791831139240507, -1.391222413793104 ], [ 29.791831139240507, -1.390952931034483 ], [ 29.791561645569619, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 495, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.390952931034483 ], [ 29.791831139240507, -1.391222413793104 ], [ 29.792100632911392, -1.391222413793104 ], [ 29.792100632911392, -1.390952931034483 ], [ 29.791831139240507, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 496, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.390952931034483 ], [ 29.792100632911392, -1.391222413793104 ], [ 29.792370126582277, -1.391222413793104 ], [ 29.792370126582277, -1.390952931034483 ], [ 29.792100632911392, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 497, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.390952931034483 ], [ 29.792370126582277, -1.391222413793104 ], [ 29.792909113924051, -1.391222413793104 ], [ 29.792909113924051, -1.390952931034483 ], [ 29.792370126582277, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 498, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.390952931034483 ], [ 29.792909113924051, -1.391222413793104 ], [ 29.793178607594935, -1.391222413793104 ], [ 29.793178607594935, -1.390952931034483 ], [ 29.792909113924051, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 499, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.390952931034483 ], [ 29.793178607594935, -1.391222413793104 ], [ 29.79506506329114, -1.391222413793104 ], [ 29.79506506329114, -1.390952931034483 ], [ 29.793178607594935, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 500, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.390952931034483 ], [ 29.79506506329114, -1.391222413793104 ], [ 29.795334556962025, -1.391222413793104 ], [ 29.795334556962025, -1.390952931034483 ], [ 29.79506506329114, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 501, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.391222413793104 ], [ 29.770541139240507, -1.391491896551724 ], [ 29.770810632911392, -1.391491896551724 ], [ 29.770810632911392, -1.391222413793104 ], [ 29.770541139240507, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 502, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.391222413793104 ], [ 29.770810632911392, -1.391491896551724 ], [ 29.771080126582277, -1.391491896551724 ], [ 29.771080126582277, -1.391222413793104 ], [ 29.770810632911392, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 503, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.391222413793104 ], [ 29.771080126582277, -1.391491896551724 ], [ 29.771349620253165, -1.391491896551724 ], [ 29.771349620253165, -1.391222413793104 ], [ 29.771080126582277, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 504, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.391222413793104 ], [ 29.771349620253165, -1.391491896551724 ], [ 29.77161911392405, -1.391491896551724 ], [ 29.77161911392405, -1.391222413793104 ], [ 29.771349620253165, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 505, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.391222413793104 ], [ 29.77161911392405, -1.391491896551724 ], [ 29.771888607594935, -1.391491896551724 ], [ 29.771888607594935, -1.391222413793104 ], [ 29.77161911392405, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 506, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.391222413793104 ], [ 29.771888607594935, -1.391491896551724 ], [ 29.772966582278482, -1.391491896551724 ], [ 29.772966582278482, -1.391222413793104 ], [ 29.771888607594935, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 507, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.391222413793104 ], [ 29.772966582278482, -1.391491896551724 ], [ 29.773236075949367, -1.391491896551724 ], [ 29.773236075949367, -1.391222413793104 ], [ 29.772966582278482, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 508, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.391222413793104 ], [ 29.773236075949367, -1.391491896551724 ], [ 29.773505569620252, -1.391491896551724 ], [ 29.773505569620252, -1.391222413793104 ], [ 29.773236075949367, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 509, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.391222413793104 ], [ 29.773505569620252, -1.391491896551724 ], [ 29.77377506329114, -1.391491896551724 ], [ 29.77377506329114, -1.391222413793104 ], [ 29.773505569620252, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 510, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.391222413793104 ], [ 29.77377506329114, -1.391491896551724 ], [ 29.774044556962025, -1.391491896551724 ], [ 29.774044556962025, -1.391222413793104 ], [ 29.77377506329114, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 511, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.391222413793104 ], [ 29.774044556962025, -1.391491896551724 ], [ 29.77431405063291, -1.391491896551724 ], [ 29.77431405063291, -1.391222413793104 ], [ 29.774044556962025, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 512, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.391222413793104 ], [ 29.77431405063291, -1.391491896551724 ], [ 29.775392025316457, -1.391491896551724 ], [ 29.775392025316457, -1.391222413793104 ], [ 29.77431405063291, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 513, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.391222413793104 ], [ 29.784554810126583, -1.391491896551724 ], [ 29.784824303797468, -1.391491896551724 ], [ 29.784824303797468, -1.391222413793104 ], [ 29.784554810126583, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 514, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.391222413793104 ], [ 29.784824303797468, -1.391491896551724 ], [ 29.785093797468356, -1.391491896551724 ], [ 29.785093797468356, -1.391222413793104 ], [ 29.784824303797468, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 515, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.391222413793104 ], [ 29.785093797468356, -1.391491896551724 ], [ 29.785363291139241, -1.391491896551724 ], [ 29.785363291139241, -1.391222413793104 ], [ 29.785093797468356, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 516, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.391222413793104 ], [ 29.785363291139241, -1.391491896551724 ], [ 29.785632784810126, -1.391491896551724 ], [ 29.785632784810126, -1.391222413793104 ], [ 29.785363291139241, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 517, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.391222413793104 ], [ 29.785632784810126, -1.391491896551724 ], [ 29.785902278481014, -1.391491896551724 ], [ 29.785902278481014, -1.391222413793104 ], [ 29.785632784810126, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 518, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.391222413793104 ], [ 29.785902278481014, -1.391491896551724 ], [ 29.786171772151899, -1.391491896551724 ], [ 29.786171772151899, -1.391222413793104 ], [ 29.785902278481014, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 519, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.391222413793104 ], [ 29.786171772151899, -1.391491896551724 ], [ 29.786441265822784, -1.391491896551724 ], [ 29.786441265822784, -1.391222413793104 ], [ 29.786171772151899, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 520, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.391222413793104 ], [ 29.786441265822784, -1.391761379310345 ], [ 29.786710759493673, -1.391761379310345 ], [ 29.786710759493673, -1.391222413793104 ], [ 29.786441265822784, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 521, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.391222413793104 ], [ 29.788058227848101, -1.391761379310345 ], [ 29.788327721518986, -1.391761379310345 ], [ 29.788327721518986, -1.391491896551724 ], [ 29.789136202531644, -1.391491896551724 ], [ 29.789136202531644, -1.391222413793104 ], [ 29.788058227848101, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 522, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.391222413793104 ], [ 29.789136202531644, -1.391491896551724 ], [ 29.789405696202532, -1.391491896551724 ], [ 29.789405696202532, -1.391222413793104 ], [ 29.789136202531644, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 523, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.391222413793104 ], [ 29.789405696202532, -1.391491896551724 ], [ 29.789944683544302, -1.391491896551724 ], [ 29.789944683544302, -1.391222413793104 ], [ 29.789405696202532, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 524, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.391222413793104 ], [ 29.789944683544302, -1.391491896551724 ], [ 29.790214177215191, -1.391491896551724 ], [ 29.790214177215191, -1.391222413793104 ], [ 29.789944683544302, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 525, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.391222413793104 ], [ 29.790214177215191, -1.391491896551724 ], [ 29.790483670886076, -1.391491896551724 ], [ 29.790483670886076, -1.391222413793104 ], [ 29.790214177215191, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 526, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.391222413793104 ], [ 29.790483670886076, -1.391491896551724 ], [ 29.790753164556961, -1.391491896551724 ], [ 29.790753164556961, -1.391222413793104 ], [ 29.790483670886076, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 527, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.391222413793104 ], [ 29.790753164556961, -1.391491896551724 ], [ 29.791022658227849, -1.391491896551724 ], [ 29.791022658227849, -1.391222413793104 ], [ 29.790753164556961, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 528, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.391222413793104 ], [ 29.791022658227849, -1.391491896551724 ], [ 29.791561645569619, -1.391491896551724 ], [ 29.791561645569619, -1.391222413793104 ], [ 29.791022658227849, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 529, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.391222413793104 ], [ 29.791561645569619, -1.391491896551724 ], [ 29.791831139240507, -1.391491896551724 ], [ 29.791831139240507, -1.391222413793104 ], [ 29.791561645569619, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 530, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.391222413793104 ], [ 29.791831139240507, -1.391491896551724 ], [ 29.792100632911392, -1.391491896551724 ], [ 29.792100632911392, -1.391222413793104 ], [ 29.791831139240507, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 531, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.391222413793104 ], [ 29.792100632911392, -1.391491896551724 ], [ 29.792370126582277, -1.391491896551724 ], [ 29.792370126582277, -1.391222413793104 ], [ 29.792100632911392, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 532, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.391222413793104 ], [ 29.792370126582277, -1.391491896551724 ], [ 29.792909113924051, -1.391491896551724 ], [ 29.792909113924051, -1.391222413793104 ], [ 29.792370126582277, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 533, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.391222413793104 ], [ 29.792909113924051, -1.391491896551724 ], [ 29.793178607594935, -1.391491896551724 ], [ 29.793178607594935, -1.391222413793104 ], [ 29.792909113924051, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 534, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.391222413793104 ], [ 29.793178607594935, -1.391491896551724 ], [ 29.795334556962025, -1.391491896551724 ], [ 29.795334556962025, -1.391222413793104 ], [ 29.793178607594935, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 535, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.391491896551724 ], [ 29.770271645569618, -1.392030862068966 ], [ 29.770541139240507, -1.392030862068966 ], [ 29.770541139240507, -1.391491896551724 ], [ 29.770271645569618, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 536, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.391491896551724 ], [ 29.770541139240507, -1.391761379310345 ], [ 29.770810632911392, -1.391761379310345 ], [ 29.770810632911392, -1.391491896551724 ], [ 29.770541139240507, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 537, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.391491896551724 ], [ 29.770810632911392, -1.391761379310345 ], [ 29.771080126582277, -1.391761379310345 ], [ 29.771080126582277, -1.391491896551724 ], [ 29.770810632911392, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 538, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.391491896551724 ], [ 29.771080126582277, -1.391761379310345 ], [ 29.771349620253165, -1.391761379310345 ], [ 29.771349620253165, -1.391491896551724 ], [ 29.771080126582277, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 539, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.391491896551724 ], [ 29.771349620253165, -1.391761379310345 ], [ 29.77161911392405, -1.391761379310345 ], [ 29.77161911392405, -1.391491896551724 ], [ 29.771349620253165, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 540, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.391491896551724 ], [ 29.77161911392405, -1.391761379310345 ], [ 29.771888607594935, -1.391761379310345 ], [ 29.771888607594935, -1.391491896551724 ], [ 29.77161911392405, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 541, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.391491896551724 ], [ 29.771888607594935, -1.391761379310345 ], [ 29.772966582278482, -1.391761379310345 ], [ 29.772966582278482, -1.391491896551724 ], [ 29.771888607594935, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 542, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.391491896551724 ], [ 29.772966582278482, -1.391761379310345 ], [ 29.773236075949367, -1.391761379310345 ], [ 29.773236075949367, -1.391491896551724 ], [ 29.772966582278482, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 543, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.391491896551724 ], [ 29.773236075949367, -1.391761379310345 ], [ 29.773505569620252, -1.391761379310345 ], [ 29.773505569620252, -1.391491896551724 ], [ 29.773236075949367, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 544, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.391491896551724 ], [ 29.773505569620252, -1.391761379310345 ], [ 29.77377506329114, -1.391761379310345 ], [ 29.77377506329114, -1.391491896551724 ], [ 29.773505569620252, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 545, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.391491896551724 ], [ 29.77377506329114, -1.391761379310345 ], [ 29.774044556962025, -1.391761379310345 ], [ 29.774044556962025, -1.391491896551724 ], [ 29.77377506329114, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 546, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.391491896551724 ], [ 29.774044556962025, -1.391761379310345 ], [ 29.77431405063291, -1.391761379310345 ], [ 29.77431405063291, -1.391491896551724 ], [ 29.774044556962025, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 547, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.391491896551724 ], [ 29.77431405063291, -1.391761379310345 ], [ 29.775122531645568, -1.391761379310345 ], [ 29.775122531645568, -1.391491896551724 ], [ 29.77431405063291, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 548, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.391491896551724 ], [ 29.775122531645568, -1.391761379310345 ], [ 29.775392025316457, -1.391761379310345 ], [ 29.775392025316457, -1.391491896551724 ], [ 29.775122531645568, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 549, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.391491896551724 ], [ 29.775392025316457, -1.391761379310345 ], [ 29.775661518987341, -1.391761379310345 ], [ 29.775661518987341, -1.391491896551724 ], [ 29.775392025316457, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 550, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.391491896551724 ], [ 29.778086962025316, -1.391761379310345 ], [ 29.778356455696201, -1.391761379310345 ], [ 29.778356455696201, -1.391491896551724 ], [ 29.778086962025316, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 551, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.391491896551724 ], [ 29.778356455696201, -1.391761379310345 ], [ 29.778895443037975, -1.391761379310345 ], [ 29.778895443037975, -1.391491896551724 ], [ 29.778356455696201, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 552, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.391491896551724 ], [ 29.778895443037975, -1.391761379310345 ], [ 29.77916493670886, -1.391761379310345 ], [ 29.77916493670886, -1.391491896551724 ], [ 29.778895443037975, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 553, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.391491896551724 ], [ 29.77916493670886, -1.391761379310345 ], [ 29.779434430379748, -1.391761379310345 ], [ 29.779434430379748, -1.391491896551724 ], [ 29.77916493670886, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 554, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.391491896551724 ], [ 29.784824303797468, -1.391761379310345 ], [ 29.785093797468356, -1.391761379310345 ], [ 29.785093797468356, -1.391491896551724 ], [ 29.784824303797468, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 555, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.391491896551724 ], [ 29.785093797468356, -1.391761379310345 ], [ 29.785363291139241, -1.391761379310345 ], [ 29.785363291139241, -1.391491896551724 ], [ 29.785093797468356, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 556, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.391491896551724 ], [ 29.785363291139241, -1.391761379310345 ], [ 29.785632784810126, -1.391761379310345 ], [ 29.785632784810126, -1.391491896551724 ], [ 29.785363291139241, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 557, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.391491896551724 ], [ 29.785632784810126, -1.391761379310345 ], [ 29.785902278481014, -1.391761379310345 ], [ 29.785902278481014, -1.391491896551724 ], [ 29.785632784810126, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 558, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.391491896551724 ], [ 29.785902278481014, -1.391761379310345 ], [ 29.786171772151899, -1.391761379310345 ], [ 29.786171772151899, -1.391491896551724 ], [ 29.785902278481014, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 559, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.391491896551724 ], [ 29.786171772151899, -1.391761379310345 ], [ 29.786441265822784, -1.391761379310345 ], [ 29.786441265822784, -1.391491896551724 ], [ 29.786171772151899, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 560, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.391491896551724 ], [ 29.788327721518986, -1.391761379310345 ], [ 29.789136202531644, -1.391761379310345 ], [ 29.789136202531644, -1.391491896551724 ], [ 29.788327721518986, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 561, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.391491896551724 ], [ 29.789136202531644, -1.391761379310345 ], [ 29.789405696202532, -1.391761379310345 ], [ 29.789405696202532, -1.391491896551724 ], [ 29.789136202531644, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 562, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.391491896551724 ], [ 29.789405696202532, -1.391761379310345 ], [ 29.789944683544302, -1.391761379310345 ], [ 29.789944683544302, -1.391491896551724 ], [ 29.789405696202532, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 563, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.391491896551724 ], [ 29.789944683544302, -1.391761379310345 ], [ 29.790214177215191, -1.391761379310345 ], [ 29.790214177215191, -1.391491896551724 ], [ 29.789944683544302, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 564, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.391491896551724 ], [ 29.790214177215191, -1.391761379310345 ], [ 29.790483670886076, -1.391761379310345 ], [ 29.790483670886076, -1.391491896551724 ], [ 29.790214177215191, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 565, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.391491896551724 ], [ 29.790483670886076, -1.391761379310345 ], [ 29.790753164556961, -1.391761379310345 ], [ 29.790753164556961, -1.391491896551724 ], [ 29.790483670886076, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 566, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.391491896551724 ], [ 29.790753164556961, -1.391761379310345 ], [ 29.791022658227849, -1.391761379310345 ], [ 29.791022658227849, -1.391491896551724 ], [ 29.790753164556961, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 567, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.391491896551724 ], [ 29.791022658227849, -1.391761379310345 ], [ 29.791292151898734, -1.391761379310345 ], [ 29.791292151898734, -1.391491896551724 ], [ 29.791022658227849, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 568, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.391491896551724 ], [ 29.791292151898734, -1.391761379310345 ], [ 29.791561645569619, -1.391761379310345 ], [ 29.791561645569619, -1.391491896551724 ], [ 29.791292151898734, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 569, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.391491896551724 ], [ 29.791561645569619, -1.391761379310345 ], [ 29.791831139240507, -1.391761379310345 ], [ 29.791831139240507, -1.391491896551724 ], [ 29.791561645569619, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 570, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.391491896551724 ], [ 29.791831139240507, -1.391761379310345 ], [ 29.792100632911392, -1.391761379310345 ], [ 29.792100632911392, -1.391491896551724 ], [ 29.791831139240507, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 571, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.391491896551724 ], [ 29.792100632911392, -1.391761379310345 ], [ 29.792370126582277, -1.391761379310345 ], [ 29.792370126582277, -1.391491896551724 ], [ 29.792100632911392, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 572, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.391491896551724 ], [ 29.792370126582277, -1.391761379310345 ], [ 29.792909113924051, -1.391761379310345 ], [ 29.792909113924051, -1.391491896551724 ], [ 29.792370126582277, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 573, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.391491896551724 ], [ 29.792909113924051, -1.391761379310345 ], [ 29.793178607594935, -1.391761379310345 ], [ 29.793178607594935, -1.391491896551724 ], [ 29.792909113924051, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 574, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.391761379310345 ], [ 29.770541139240507, -1.392030862068966 ], [ 29.770810632911392, -1.392030862068966 ], [ 29.770810632911392, -1.391761379310345 ], [ 29.770541139240507, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 575, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.391761379310345 ], [ 29.770810632911392, -1.392030862068966 ], [ 29.771080126582277, -1.392030862068966 ], [ 29.771080126582277, -1.391761379310345 ], [ 29.770810632911392, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 576, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.391761379310345 ], [ 29.771080126582277, -1.392030862068966 ], [ 29.771349620253165, -1.392030862068966 ], [ 29.771349620253165, -1.391761379310345 ], [ 29.771080126582277, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 577, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.391761379310345 ], [ 29.771349620253165, -1.392030862068966 ], [ 29.77161911392405, -1.392030862068966 ], [ 29.77161911392405, -1.391761379310345 ], [ 29.771349620253165, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 578, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.391761379310345 ], [ 29.77161911392405, -1.392030862068966 ], [ 29.771888607594935, -1.392030862068966 ], [ 29.771888607594935, -1.391761379310345 ], [ 29.77161911392405, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 579, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.391761379310345 ], [ 29.771888607594935, -1.392030862068966 ], [ 29.772158101265823, -1.392030862068966 ], [ 29.772158101265823, -1.391761379310345 ], [ 29.771888607594935, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 580, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.391761379310345 ], [ 29.772158101265823, -1.392030862068966 ], [ 29.772697088607593, -1.392030862068966 ], [ 29.772697088607593, -1.391761379310345 ], [ 29.772158101265823, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 581, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.391761379310345 ], [ 29.772697088607593, -1.392030862068966 ], [ 29.772966582278482, -1.392030862068966 ], [ 29.772966582278482, -1.391761379310345 ], [ 29.772697088607593, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 582, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.391761379310345 ], [ 29.772966582278482, -1.392030862068966 ], [ 29.773236075949367, -1.392030862068966 ], [ 29.773236075949367, -1.391761379310345 ], [ 29.772966582278482, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 583, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.391761379310345 ], [ 29.773236075949367, -1.392030862068966 ], [ 29.773505569620252, -1.392030862068966 ], [ 29.773505569620252, -1.391761379310345 ], [ 29.773236075949367, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 584, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.391761379310345 ], [ 29.773505569620252, -1.392030862068966 ], [ 29.77377506329114, -1.392030862068966 ], [ 29.77377506329114, -1.391761379310345 ], [ 29.773505569620252, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 585, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.391761379310345 ], [ 29.77377506329114, -1.392030862068966 ], [ 29.774044556962025, -1.392030862068966 ], [ 29.774044556962025, -1.391761379310345 ], [ 29.77377506329114, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 586, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.391761379310345 ], [ 29.774044556962025, -1.392030862068966 ], [ 29.77431405063291, -1.392030862068966 ], [ 29.77431405063291, -1.391761379310345 ], [ 29.774044556962025, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 587, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.391761379310345 ], [ 29.77431405063291, -1.392030862068966 ], [ 29.775122531645568, -1.392030862068966 ], [ 29.775122531645568, -1.391761379310345 ], [ 29.77431405063291, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 588, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.391761379310345 ], [ 29.775122531645568, -1.392030862068966 ], [ 29.775392025316457, -1.392030862068966 ], [ 29.775392025316457, -1.391761379310345 ], [ 29.775122531645568, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 589, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.391761379310345 ], [ 29.775392025316457, -1.392030862068966 ], [ 29.775661518987341, -1.392030862068966 ], [ 29.775661518987341, -1.391761379310345 ], [ 29.775392025316457, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 590, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.391761379310345 ], [ 29.775661518987341, -1.392030862068966 ], [ 29.775931012658226, -1.392030862068966 ], [ 29.775931012658226, -1.391761379310345 ], [ 29.775661518987341, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 591, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.391761379310345 ], [ 29.778895443037975, -1.392030862068966 ], [ 29.77916493670886, -1.392030862068966 ], [ 29.77916493670886, -1.391761379310345 ], [ 29.778895443037975, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 592, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.391761379310345 ], [ 29.77916493670886, -1.392030862068966 ], [ 29.779434430379748, -1.392030862068966 ], [ 29.779434430379748, -1.391761379310345 ], [ 29.77916493670886, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 593, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.391761379310345 ], [ 29.779434430379748, -1.392030862068966 ], [ 29.779703924050633, -1.392030862068966 ], [ 29.779703924050633, -1.391761379310345 ], [ 29.779434430379748, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 594, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.391761379310345 ], [ 29.784824303797468, -1.392030862068966 ], [ 29.785093797468356, -1.392030862068966 ], [ 29.785093797468356, -1.391761379310345 ], [ 29.784824303797468, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 595, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.391761379310345 ], [ 29.785093797468356, -1.392030862068966 ], [ 29.785363291139241, -1.392030862068966 ], [ 29.785363291139241, -1.391761379310345 ], [ 29.785093797468356, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 596, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.391761379310345 ], [ 29.785363291139241, -1.392030862068966 ], [ 29.785632784810126, -1.392030862068966 ], [ 29.785632784810126, -1.391761379310345 ], [ 29.785363291139241, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 597, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.391761379310345 ], [ 29.785632784810126, -1.392030862068966 ], [ 29.785902278481014, -1.392030862068966 ], [ 29.785902278481014, -1.391761379310345 ], [ 29.785632784810126, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 598, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.391761379310345 ], [ 29.785902278481014, -1.392030862068966 ], [ 29.786171772151899, -1.392030862068966 ], [ 29.786171772151899, -1.391761379310345 ], [ 29.785902278481014, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 599, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.391761379310345 ], [ 29.786171772151899, -1.392030862068966 ], [ 29.786441265822784, -1.392030862068966 ], [ 29.786441265822784, -1.391761379310345 ], [ 29.786171772151899, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 600, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.391761379310345 ], [ 29.786441265822784, -1.392030862068966 ], [ 29.786710759493673, -1.392030862068966 ], [ 29.786710759493673, -1.391761379310345 ], [ 29.786441265822784, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 601, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.391761379310345 ], [ 29.787788734177216, -1.392030862068966 ], [ 29.788058227848101, -1.392030862068966 ], [ 29.788058227848101, -1.391761379310345 ], [ 29.787788734177216, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 602, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.391761379310345 ], [ 29.788058227848101, -1.392030862068966 ], [ 29.788327721518986, -1.392030862068966 ], [ 29.788327721518986, -1.391761379310345 ], [ 29.788058227848101, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 603, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.391761379310345 ], [ 29.788327721518986, -1.392030862068966 ], [ 29.788597215189874, -1.392030862068966 ], [ 29.788597215189874, -1.391761379310345 ], [ 29.788327721518986, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 604, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.391761379310345 ], [ 29.788597215189874, -1.392030862068966 ], [ 29.789136202531644, -1.392030862068966 ], [ 29.789136202531644, -1.391761379310345 ], [ 29.788597215189874, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 605, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.391761379310345 ], [ 29.789136202531644, -1.392030862068966 ], [ 29.789405696202532, -1.392030862068966 ], [ 29.789405696202532, -1.391761379310345 ], [ 29.789136202531644, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 606, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.391761379310345 ], [ 29.789405696202532, -1.392030862068966 ], [ 29.789675189873417, -1.392030862068966 ], [ 29.789675189873417, -1.391761379310345 ], [ 29.789405696202532, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 607, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.391761379310345 ], [ 29.789675189873417, -1.392030862068966 ], [ 29.790214177215191, -1.392030862068966 ], [ 29.790214177215191, -1.391761379310345 ], [ 29.789675189873417, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 608, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.391761379310345 ], [ 29.790214177215191, -1.392030862068966 ], [ 29.790483670886076, -1.392030862068966 ], [ 29.790483670886076, -1.391761379310345 ], [ 29.790214177215191, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 609, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.391761379310345 ], [ 29.790483670886076, -1.392030862068966 ], [ 29.790753164556961, -1.392030862068966 ], [ 29.790753164556961, -1.391761379310345 ], [ 29.790483670886076, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 610, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.391761379310345 ], [ 29.790753164556961, -1.392030862068966 ], [ 29.791022658227849, -1.392030862068966 ], [ 29.791022658227849, -1.391761379310345 ], [ 29.790753164556961, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 611, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.391761379310345 ], [ 29.791022658227849, -1.392030862068966 ], [ 29.791292151898734, -1.392030862068966 ], [ 29.791292151898734, -1.391761379310345 ], [ 29.791022658227849, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 612, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.391761379310345 ], [ 29.791292151898734, -1.392030862068966 ], [ 29.791561645569619, -1.392030862068966 ], [ 29.791561645569619, -1.391761379310345 ], [ 29.791292151898734, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 613, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.391761379310345 ], [ 29.791561645569619, -1.392030862068966 ], [ 29.791831139240507, -1.392030862068966 ], [ 29.791831139240507, -1.391761379310345 ], [ 29.791561645569619, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 614, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.391761379310345 ], [ 29.791831139240507, -1.392030862068966 ], [ 29.792100632911392, -1.392030862068966 ], [ 29.792100632911392, -1.391761379310345 ], [ 29.791831139240507, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 615, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.391761379310345 ], [ 29.792100632911392, -1.392030862068966 ], [ 29.792370126582277, -1.392030862068966 ], [ 29.792370126582277, -1.391761379310345 ], [ 29.792100632911392, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 616, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.391761379310345 ], [ 29.792370126582277, -1.392030862068966 ], [ 29.792909113924051, -1.392030862068966 ], [ 29.792909113924051, -1.391761379310345 ], [ 29.792370126582277, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 617, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.390952931034483 ], [ 29.783476835443039, -1.39391724137931 ], [ 29.784015822784809, -1.39391724137931 ], [ 29.784015822784809, -1.390952931034483 ], [ 29.783476835443039, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 618, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.390952931034483 ], [ 29.784015822784809, -1.39391724137931 ], [ 29.784285316455698, -1.39391724137931 ], [ 29.784285316455698, -1.390952931034483 ], [ 29.784015822784809, -1.390952931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 619, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.391222413793104 ], [ 29.782668354430381, -1.39364775862069 ], [ 29.782937848101266, -1.39364775862069 ], [ 29.782937848101266, -1.391222413793104 ], [ 29.782668354430381, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 620, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.391222413793104 ], [ 29.782937848101266, -1.39364775862069 ], [ 29.783207341772151, -1.39364775862069 ], [ 29.783207341772151, -1.391222413793104 ], [ 29.782937848101266, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 621, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.391222413793104 ], [ 29.783207341772151, -1.39364775862069 ], [ 29.783476835443039, -1.39364775862069 ], [ 29.783476835443039, -1.391222413793104 ], [ 29.783207341772151, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 622, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.391222413793104 ], [ 29.784285316455698, -1.39364775862069 ], [ 29.784554810126583, -1.39364775862069 ], [ 29.784554810126583, -1.391222413793104 ], [ 29.784285316455698, -1.391222413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 623, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.391491896551724 ], [ 29.784554810126583, -1.39391724137931 ], [ 29.784824303797468, -1.39391724137931 ], [ 29.784824303797468, -1.391491896551724 ], [ 29.784554810126583, -1.391491896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 624, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.391761379310345 ], [ 29.778086962025316, -1.393108793103448 ], [ 29.778356455696201, -1.393108793103448 ], [ 29.778356455696201, -1.391761379310345 ], [ 29.778086962025316, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 625, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.391761379310345 ], [ 29.778356455696201, -1.393108793103448 ], [ 29.77862594936709, -1.393108793103448 ], [ 29.77862594936709, -1.391761379310345 ], [ 29.778356455696201, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 626, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.391761379310345 ], [ 29.77862594936709, -1.393108793103448 ], [ 29.778895443037975, -1.393108793103448 ], [ 29.778895443037975, -1.392300344827586 ], [ 29.77916493670886, -1.392300344827586 ], [ 29.77916493670886, -1.392030862068966 ], [ 29.778895443037975, -1.392030862068966 ], [ 29.778895443037975, -1.391761379310345 ], [ 29.77862594936709, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 627, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.391761379310345 ], [ 29.786710759493673, -1.392300344827586 ], [ 29.786980253164558, -1.392300344827586 ], [ 29.786980253164558, -1.391761379310345 ], [ 29.786710759493673, -1.391761379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 628, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.392030862068966 ], [ 29.770002151898733, -1.392569827586207 ], [ 29.770271645569618, -1.392569827586207 ], [ 29.770271645569618, -1.392030862068966 ], [ 29.770002151898733, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 629, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.392030862068966 ], [ 29.770271645569618, -1.392300344827586 ], [ 29.770541139240507, -1.392300344827586 ], [ 29.770541139240507, -1.392030862068966 ], [ 29.770271645569618, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 630, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.392030862068966 ], [ 29.770541139240507, -1.392300344827586 ], [ 29.770810632911392, -1.392300344827586 ], [ 29.770810632911392, -1.392030862068966 ], [ 29.770541139240507, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 631, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.392030862068966 ], [ 29.770810632911392, -1.392300344827586 ], [ 29.771080126582277, -1.392300344827586 ], [ 29.771080126582277, -1.392030862068966 ], [ 29.770810632911392, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 632, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.392030862068966 ], [ 29.771080126582277, -1.392300344827586 ], [ 29.771349620253165, -1.392300344827586 ], [ 29.771349620253165, -1.392030862068966 ], [ 29.771080126582277, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 633, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.392030862068966 ], [ 29.771349620253165, -1.392300344827586 ], [ 29.77161911392405, -1.392300344827586 ], [ 29.77161911392405, -1.392030862068966 ], [ 29.771349620253165, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 634, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.392030862068966 ], [ 29.77161911392405, -1.392300344827586 ], [ 29.771888607594935, -1.392300344827586 ], [ 29.771888607594935, -1.392030862068966 ], [ 29.77161911392405, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 635, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.392030862068966 ], [ 29.771888607594935, -1.392300344827586 ], [ 29.772158101265823, -1.392300344827586 ], [ 29.772158101265823, -1.392030862068966 ], [ 29.771888607594935, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 636, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.392030862068966 ], [ 29.772158101265823, -1.392300344827586 ], [ 29.772697088607593, -1.392300344827586 ], [ 29.772697088607593, -1.392030862068966 ], [ 29.772158101265823, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 637, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.392030862068966 ], [ 29.772697088607593, -1.392300344827586 ], [ 29.772966582278482, -1.392300344827586 ], [ 29.772966582278482, -1.392030862068966 ], [ 29.772697088607593, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 638, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.392030862068966 ], [ 29.772966582278482, -1.392300344827586 ], [ 29.773236075949367, -1.392300344827586 ], [ 29.773236075949367, -1.392030862068966 ], [ 29.772966582278482, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 639, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.392030862068966 ], [ 29.773236075949367, -1.392300344827586 ], [ 29.773505569620252, -1.392300344827586 ], [ 29.773505569620252, -1.392030862068966 ], [ 29.773236075949367, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 640, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.392030862068966 ], [ 29.773505569620252, -1.392300344827586 ], [ 29.77377506329114, -1.392300344827586 ], [ 29.77377506329114, -1.392030862068966 ], [ 29.773505569620252, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 641, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.392030862068966 ], [ 29.77377506329114, -1.392300344827586 ], [ 29.774044556962025, -1.392300344827586 ], [ 29.774044556962025, -1.392030862068966 ], [ 29.77377506329114, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 642, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.392030862068966 ], [ 29.774044556962025, -1.392300344827586 ], [ 29.774853037974683, -1.392300344827586 ], [ 29.774853037974683, -1.392030862068966 ], [ 29.774044556962025, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 643, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.392030862068966 ], [ 29.774853037974683, -1.392300344827586 ], [ 29.775122531645568, -1.392300344827586 ], [ 29.775122531645568, -1.392030862068966 ], [ 29.774853037974683, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 644, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.392030862068966 ], [ 29.775122531645568, -1.392300344827586 ], [ 29.775392025316457, -1.392300344827586 ], [ 29.775392025316457, -1.392030862068966 ], [ 29.775122531645568, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 645, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.392030862068966 ], [ 29.775392025316457, -1.392300344827586 ], [ 29.775661518987341, -1.392300344827586 ], [ 29.775661518987341, -1.392030862068966 ], [ 29.775392025316457, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 646, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.392030862068966 ], [ 29.775661518987341, -1.392300344827586 ], [ 29.775931012658226, -1.392300344827586 ], [ 29.775931012658226, -1.392030862068966 ], [ 29.775661518987341, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 647, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.392030862068966 ], [ 29.775931012658226, -1.392569827586207 ], [ 29.776200506329115, -1.392569827586207 ], [ 29.776200506329115, -1.392030862068966 ], [ 29.775931012658226, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 648, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.392030862068966 ], [ 29.77916493670886, -1.392300344827586 ], [ 29.779434430379748, -1.392300344827586 ], [ 29.779434430379748, -1.392030862068966 ], [ 29.77916493670886, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 649, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.392030862068966 ], [ 29.779434430379748, -1.392300344827586 ], [ 29.779703924050633, -1.392300344827586 ], [ 29.779703924050633, -1.392030862068966 ], [ 29.779434430379748, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 650, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.392030862068966 ], [ 29.779703924050633, -1.392569827586207 ], [ 29.779973417721518, -1.392569827586207 ], [ 29.779973417721518, -1.392030862068966 ], [ 29.779703924050633, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 651, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.392030862068966 ], [ 29.784824303797468, -1.392300344827586 ], [ 29.785093797468356, -1.392300344827586 ], [ 29.785093797468356, -1.392030862068966 ], [ 29.784824303797468, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 652, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.392030862068966 ], [ 29.785093797468356, -1.392300344827586 ], [ 29.785363291139241, -1.392300344827586 ], [ 29.785363291139241, -1.392030862068966 ], [ 29.785093797468356, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 653, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.392030862068966 ], [ 29.785363291139241, -1.392300344827586 ], [ 29.785632784810126, -1.392300344827586 ], [ 29.785632784810126, -1.392030862068966 ], [ 29.785363291139241, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 654, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.392030862068966 ], [ 29.785632784810126, -1.392300344827586 ], [ 29.785902278481014, -1.392300344827586 ], [ 29.785902278481014, -1.392030862068966 ], [ 29.785632784810126, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 655, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.392030862068966 ], [ 29.785902278481014, -1.392300344827586 ], [ 29.786171772151899, -1.392300344827586 ], [ 29.786171772151899, -1.392030862068966 ], [ 29.785902278481014, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 656, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.392030862068966 ], [ 29.786171772151899, -1.392300344827586 ], [ 29.786441265822784, -1.392300344827586 ], [ 29.786441265822784, -1.392030862068966 ], [ 29.786171772151899, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 657, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.392030862068966 ], [ 29.786441265822784, -1.392300344827586 ], [ 29.786710759493673, -1.392300344827586 ], [ 29.786710759493673, -1.392030862068966 ], [ 29.786441265822784, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 658, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.392030862068966 ], [ 29.787519240506327, -1.392300344827586 ], [ 29.787788734177216, -1.392300344827586 ], [ 29.787788734177216, -1.392030862068966 ], [ 29.787519240506327, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 659, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.392030862068966 ], [ 29.787788734177216, -1.392300344827586 ], [ 29.788058227848101, -1.392300344827586 ], [ 29.788058227848101, -1.392030862068966 ], [ 29.787788734177216, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 660, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.392030862068966 ], [ 29.788058227848101, -1.392300344827586 ], [ 29.788327721518986, -1.392300344827586 ], [ 29.788327721518986, -1.392030862068966 ], [ 29.788058227848101, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 661, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.392030862068966 ], [ 29.788327721518986, -1.392300344827586 ], [ 29.788597215189874, -1.392300344827586 ], [ 29.788597215189874, -1.392030862068966 ], [ 29.788327721518986, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 662, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.392030862068966 ], [ 29.788597215189874, -1.392300344827586 ], [ 29.788866708860759, -1.392300344827586 ], [ 29.788866708860759, -1.392030862068966 ], [ 29.788597215189874, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 663, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.392030862068966 ], [ 29.788866708860759, -1.392300344827586 ], [ 29.789405696202532, -1.392300344827586 ], [ 29.789405696202532, -1.392030862068966 ], [ 29.788866708860759, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 664, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.392030862068966 ], [ 29.789405696202532, -1.392300344827586 ], [ 29.789675189873417, -1.392300344827586 ], [ 29.789675189873417, -1.392030862068966 ], [ 29.789405696202532, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 665, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.392030862068966 ], [ 29.789675189873417, -1.392300344827586 ], [ 29.790214177215191, -1.392300344827586 ], [ 29.790214177215191, -1.392030862068966 ], [ 29.789675189873417, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 666, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.392030862068966 ], [ 29.790214177215191, -1.392300344827586 ], [ 29.790483670886076, -1.392300344827586 ], [ 29.790483670886076, -1.392030862068966 ], [ 29.790214177215191, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 667, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.392030862068966 ], [ 29.790483670886076, -1.392300344827586 ], [ 29.790753164556961, -1.392300344827586 ], [ 29.790753164556961, -1.392030862068966 ], [ 29.790483670886076, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 668, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.392030862068966 ], [ 29.790753164556961, -1.392300344827586 ], [ 29.791022658227849, -1.392300344827586 ], [ 29.791022658227849, -1.392030862068966 ], [ 29.790753164556961, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 669, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.392030862068966 ], [ 29.791022658227849, -1.392300344827586 ], [ 29.791292151898734, -1.392300344827586 ], [ 29.791292151898734, -1.392030862068966 ], [ 29.791022658227849, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 670, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.392030862068966 ], [ 29.791292151898734, -1.392300344827586 ], [ 29.791561645569619, -1.392300344827586 ], [ 29.791561645569619, -1.392030862068966 ], [ 29.791292151898734, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 671, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.392030862068966 ], [ 29.791561645569619, -1.392300344827586 ], [ 29.791831139240507, -1.392300344827586 ], [ 29.791831139240507, -1.392030862068966 ], [ 29.791561645569619, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 672, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.392030862068966 ], [ 29.791831139240507, -1.392300344827586 ], [ 29.792100632911392, -1.392300344827586 ], [ 29.792100632911392, -1.392030862068966 ], [ 29.791831139240507, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 673, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.392030862068966 ], [ 29.792100632911392, -1.392569827586207 ], [ 29.792370126582277, -1.392569827586207 ], [ 29.792370126582277, -1.392030862068966 ], [ 29.792100632911392, -1.392030862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 674, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.392300344827586 ], [ 29.770271645569618, -1.392569827586207 ], [ 29.770541139240507, -1.392569827586207 ], [ 29.770541139240507, -1.392300344827586 ], [ 29.770271645569618, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 675, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.392300344827586 ], [ 29.770541139240507, -1.392569827586207 ], [ 29.770810632911392, -1.392569827586207 ], [ 29.770810632911392, -1.392300344827586 ], [ 29.770541139240507, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 676, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.392300344827586 ], [ 29.770810632911392, -1.392569827586207 ], [ 29.771080126582277, -1.392569827586207 ], [ 29.771080126582277, -1.392300344827586 ], [ 29.770810632911392, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 677, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.392300344827586 ], [ 29.771080126582277, -1.392569827586207 ], [ 29.771349620253165, -1.392569827586207 ], [ 29.771349620253165, -1.392300344827586 ], [ 29.771080126582277, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 678, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.392300344827586 ], [ 29.771349620253165, -1.392569827586207 ], [ 29.77161911392405, -1.392569827586207 ], [ 29.77161911392405, -1.392300344827586 ], [ 29.771349620253165, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 679, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.392300344827586 ], [ 29.77161911392405, -1.392569827586207 ], [ 29.771888607594935, -1.392569827586207 ], [ 29.771888607594935, -1.392300344827586 ], [ 29.77161911392405, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 680, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.392300344827586 ], [ 29.771888607594935, -1.392569827586207 ], [ 29.772158101265823, -1.392569827586207 ], [ 29.772158101265823, -1.392300344827586 ], [ 29.771888607594935, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 681, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.392300344827586 ], [ 29.772158101265823, -1.392569827586207 ], [ 29.772697088607593, -1.392569827586207 ], [ 29.772697088607593, -1.392300344827586 ], [ 29.772158101265823, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 682, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.392300344827586 ], [ 29.772697088607593, -1.392569827586207 ], [ 29.772966582278482, -1.392569827586207 ], [ 29.772966582278482, -1.392300344827586 ], [ 29.772697088607593, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 683, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.392300344827586 ], [ 29.772966582278482, -1.392569827586207 ], [ 29.773236075949367, -1.392569827586207 ], [ 29.773236075949367, -1.392300344827586 ], [ 29.772966582278482, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 684, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.392300344827586 ], [ 29.773236075949367, -1.392569827586207 ], [ 29.773505569620252, -1.392569827586207 ], [ 29.773505569620252, -1.392300344827586 ], [ 29.773236075949367, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 685, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.392300344827586 ], [ 29.773505569620252, -1.392569827586207 ], [ 29.77377506329114, -1.392569827586207 ], [ 29.77377506329114, -1.392300344827586 ], [ 29.773505569620252, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 686, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.392300344827586 ], [ 29.77377506329114, -1.392569827586207 ], [ 29.774044556962025, -1.392569827586207 ], [ 29.774044556962025, -1.392300344827586 ], [ 29.77377506329114, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 687, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.392300344827586 ], [ 29.774044556962025, -1.392569827586207 ], [ 29.774853037974683, -1.392569827586207 ], [ 29.774853037974683, -1.392300344827586 ], [ 29.774044556962025, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 688, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.392300344827586 ], [ 29.774853037974683, -1.392569827586207 ], [ 29.775122531645568, -1.392569827586207 ], [ 29.775122531645568, -1.392300344827586 ], [ 29.774853037974683, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 689, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.392300344827586 ], [ 29.775122531645568, -1.392569827586207 ], [ 29.775392025316457, -1.392569827586207 ], [ 29.775392025316457, -1.392300344827586 ], [ 29.775122531645568, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 690, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.392300344827586 ], [ 29.775392025316457, -1.392569827586207 ], [ 29.775661518987341, -1.392569827586207 ], [ 29.775661518987341, -1.392300344827586 ], [ 29.775392025316457, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 691, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.392300344827586 ], [ 29.775661518987341, -1.392569827586207 ], [ 29.775931012658226, -1.392569827586207 ], [ 29.775931012658226, -1.392300344827586 ], [ 29.775661518987341, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 692, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.392300344827586 ], [ 29.778895443037975, -1.392569827586207 ], [ 29.77916493670886, -1.392569827586207 ], [ 29.77916493670886, -1.392300344827586 ], [ 29.778895443037975, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 693, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.392300344827586 ], [ 29.77916493670886, -1.392569827586207 ], [ 29.779434430379748, -1.392569827586207 ], [ 29.779434430379748, -1.392300344827586 ], [ 29.77916493670886, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 694, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.392300344827586 ], [ 29.779434430379748, -1.392569827586207 ], [ 29.779703924050633, -1.392569827586207 ], [ 29.779703924050633, -1.392300344827586 ], [ 29.779434430379748, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 695, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.392300344827586 ], [ 29.784824303797468, -1.392569827586207 ], [ 29.785093797468356, -1.392569827586207 ], [ 29.785093797468356, -1.392300344827586 ], [ 29.784824303797468, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 696, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.392300344827586 ], [ 29.785093797468356, -1.392569827586207 ], [ 29.785363291139241, -1.392569827586207 ], [ 29.785363291139241, -1.392300344827586 ], [ 29.785093797468356, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 697, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.392300344827586 ], [ 29.785363291139241, -1.392569827586207 ], [ 29.785632784810126, -1.392569827586207 ], [ 29.785632784810126, -1.392300344827586 ], [ 29.785363291139241, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 698, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.392300344827586 ], [ 29.785632784810126, -1.392569827586207 ], [ 29.785902278481014, -1.392569827586207 ], [ 29.785902278481014, -1.392300344827586 ], [ 29.785632784810126, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 699, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.392300344827586 ], [ 29.785902278481014, -1.392569827586207 ], [ 29.786171772151899, -1.392569827586207 ], [ 29.786171772151899, -1.392300344827586 ], [ 29.785902278481014, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 700, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.392300344827586 ], [ 29.786171772151899, -1.392569827586207 ], [ 29.786441265822784, -1.392569827586207 ], [ 29.786441265822784, -1.392300344827586 ], [ 29.786171772151899, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 701, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.392300344827586 ], [ 29.786441265822784, -1.392569827586207 ], [ 29.786710759493673, -1.392569827586207 ], [ 29.786710759493673, -1.392300344827586 ], [ 29.786441265822784, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 702, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.392300344827586 ], [ 29.786710759493673, -1.392569827586207 ], [ 29.786980253164558, -1.392569827586207 ], [ 29.786980253164558, -1.392300344827586 ], [ 29.786710759493673, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 703, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.392300344827586 ], [ 29.786980253164558, -1.392569827586207 ], [ 29.787519240506327, -1.392569827586207 ], [ 29.787519240506327, -1.392300344827586 ], [ 29.786980253164558, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 704, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.392300344827586 ], [ 29.787519240506327, -1.392569827586207 ], [ 29.787788734177216, -1.392569827586207 ], [ 29.787788734177216, -1.392300344827586 ], [ 29.787519240506327, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 705, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.392300344827586 ], [ 29.787788734177216, -1.392569827586207 ], [ 29.788058227848101, -1.392569827586207 ], [ 29.788058227848101, -1.392300344827586 ], [ 29.787788734177216, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 706, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.392300344827586 ], [ 29.788058227848101, -1.392569827586207 ], [ 29.788327721518986, -1.392569827586207 ], [ 29.788327721518986, -1.392300344827586 ], [ 29.788058227848101, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.392300344827586 ], [ 29.788327721518986, -1.392569827586207 ], [ 29.788597215189874, -1.392569827586207 ], [ 29.788597215189874, -1.392300344827586 ], [ 29.788327721518986, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 708, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.392300344827586 ], [ 29.788597215189874, -1.392569827586207 ], [ 29.788866708860759, -1.392569827586207 ], [ 29.788866708860759, -1.392300344827586 ], [ 29.788597215189874, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 709, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.392300344827586 ], [ 29.788866708860759, -1.392569827586207 ], [ 29.789405696202532, -1.392569827586207 ], [ 29.789405696202532, -1.392300344827586 ], [ 29.788866708860759, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 710, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.392300344827586 ], [ 29.789405696202532, -1.392569827586207 ], [ 29.789675189873417, -1.392569827586207 ], [ 29.789675189873417, -1.392300344827586 ], [ 29.789405696202532, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 711, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.392300344827586 ], [ 29.789675189873417, -1.392569827586207 ], [ 29.789944683544302, -1.392569827586207 ], [ 29.789944683544302, -1.392300344827586 ], [ 29.789675189873417, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 712, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.392300344827586 ], [ 29.789944683544302, -1.392569827586207 ], [ 29.790753164556961, -1.392569827586207 ], [ 29.790753164556961, -1.392300344827586 ], [ 29.789944683544302, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 713, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.392300344827586 ], [ 29.790753164556961, -1.393108793103448 ], [ 29.791022658227849, -1.393108793103448 ], [ 29.791022658227849, -1.392300344827586 ], [ 29.790753164556961, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 714, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.392300344827586 ], [ 29.791022658227849, -1.393108793103448 ], [ 29.791292151898734, -1.393108793103448 ], [ 29.791292151898734, -1.392300344827586 ], [ 29.791022658227849, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 715, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.392300344827586 ], [ 29.791292151898734, -1.392569827586207 ], [ 29.791561645569619, -1.392569827586207 ], [ 29.791561645569619, -1.392300344827586 ], [ 29.791292151898734, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 716, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.392300344827586 ], [ 29.791561645569619, -1.392569827586207 ], [ 29.791831139240507, -1.392569827586207 ], [ 29.791831139240507, -1.392300344827586 ], [ 29.791561645569619, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 717, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.392300344827586 ], [ 29.791831139240507, -1.392569827586207 ], [ 29.792100632911392, -1.392569827586207 ], [ 29.792100632911392, -1.392300344827586 ], [ 29.791831139240507, -1.392300344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 718, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.392569827586207 ], [ 29.769732658227849, -1.392839310344828 ], [ 29.770002151898733, -1.392839310344828 ], [ 29.770002151898733, -1.392569827586207 ], [ 29.769732658227849, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 719, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.392569827586207 ], [ 29.770002151898733, -1.392839310344828 ], [ 29.770271645569618, -1.392839310344828 ], [ 29.770271645569618, -1.392569827586207 ], [ 29.770002151898733, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 720, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.392569827586207 ], [ 29.770271645569618, -1.392839310344828 ], [ 29.770541139240507, -1.392839310344828 ], [ 29.770541139240507, -1.392569827586207 ], [ 29.770271645569618, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 721, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.392569827586207 ], [ 29.770541139240507, -1.392839310344828 ], [ 29.770810632911392, -1.392839310344828 ], [ 29.770810632911392, -1.392569827586207 ], [ 29.770541139240507, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 722, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.392569827586207 ], [ 29.770810632911392, -1.392839310344828 ], [ 29.771080126582277, -1.392839310344828 ], [ 29.771080126582277, -1.392569827586207 ], [ 29.770810632911392, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 723, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.392569827586207 ], [ 29.771080126582277, -1.392839310344828 ], [ 29.771349620253165, -1.392839310344828 ], [ 29.771349620253165, -1.392569827586207 ], [ 29.771080126582277, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 724, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.392569827586207 ], [ 29.771349620253165, -1.392839310344828 ], [ 29.77161911392405, -1.392839310344828 ], [ 29.77161911392405, -1.392569827586207 ], [ 29.771349620253165, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 725, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.392569827586207 ], [ 29.77161911392405, -1.392839310344828 ], [ 29.771888607594935, -1.392839310344828 ], [ 29.771888607594935, -1.392569827586207 ], [ 29.77161911392405, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 726, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.392569827586207 ], [ 29.771888607594935, -1.392839310344828 ], [ 29.772158101265823, -1.392839310344828 ], [ 29.772158101265823, -1.392569827586207 ], [ 29.771888607594935, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 727, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.392569827586207 ], [ 29.772158101265823, -1.392839310344828 ], [ 29.772697088607593, -1.392839310344828 ], [ 29.772697088607593, -1.392569827586207 ], [ 29.772158101265823, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 728, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.392569827586207 ], [ 29.772697088607593, -1.392839310344828 ], [ 29.772966582278482, -1.392839310344828 ], [ 29.772966582278482, -1.392569827586207 ], [ 29.772697088607593, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 729, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.392569827586207 ], [ 29.772966582278482, -1.392839310344828 ], [ 29.773236075949367, -1.392839310344828 ], [ 29.773236075949367, -1.392569827586207 ], [ 29.772966582278482, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 730, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.392569827586207 ], [ 29.773236075949367, -1.392839310344828 ], [ 29.773505569620252, -1.392839310344828 ], [ 29.773505569620252, -1.392569827586207 ], [ 29.773236075949367, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 731, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.392569827586207 ], [ 29.773505569620252, -1.392839310344828 ], [ 29.77377506329114, -1.392839310344828 ], [ 29.77377506329114, -1.392569827586207 ], [ 29.773505569620252, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 732, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.392569827586207 ], [ 29.77377506329114, -1.392839310344828 ], [ 29.77431405063291, -1.392839310344828 ], [ 29.77431405063291, -1.392569827586207 ], [ 29.77377506329114, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 733, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.392569827586207 ], [ 29.77431405063291, -1.392839310344828 ], [ 29.774853037974683, -1.392839310344828 ], [ 29.774853037974683, -1.392569827586207 ], [ 29.77431405063291, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 734, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.392569827586207 ], [ 29.774853037974683, -1.392839310344828 ], [ 29.775122531645568, -1.392839310344828 ], [ 29.775122531645568, -1.392569827586207 ], [ 29.774853037974683, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 735, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.392569827586207 ], [ 29.775122531645568, -1.392839310344828 ], [ 29.775392025316457, -1.392839310344828 ], [ 29.775392025316457, -1.392569827586207 ], [ 29.775122531645568, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 736, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.392569827586207 ], [ 29.775392025316457, -1.392839310344828 ], [ 29.775661518987341, -1.392839310344828 ], [ 29.775661518987341, -1.392569827586207 ], [ 29.775392025316457, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 737, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.392569827586207 ], [ 29.775661518987341, -1.392839310344828 ], [ 29.775931012658226, -1.392839310344828 ], [ 29.775931012658226, -1.392569827586207 ], [ 29.775661518987341, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 738, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.392569827586207 ], [ 29.775931012658226, -1.392839310344828 ], [ 29.776200506329115, -1.392839310344828 ], [ 29.776200506329115, -1.392569827586207 ], [ 29.775931012658226, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 739, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.392569827586207 ], [ 29.776200506329115, -1.392839310344828 ], [ 29.77647, -1.392839310344828 ], [ 29.77647, -1.392569827586207 ], [ 29.776200506329115, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 740, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.392569827586207 ], [ 29.778895443037975, -1.393378275862069 ], [ 29.779434430379748, -1.393378275862069 ], [ 29.779434430379748, -1.393108793103448 ], [ 29.77916493670886, -1.393108793103448 ], [ 29.77916493670886, -1.392569827586207 ], [ 29.778895443037975, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 741, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.392569827586207 ], [ 29.77916493670886, -1.392839310344828 ], [ 29.779434430379748, -1.392839310344828 ], [ 29.779434430379748, -1.392569827586207 ], [ 29.77916493670886, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 742, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.392569827586207 ], [ 29.779434430379748, -1.392839310344828 ], [ 29.779703924050633, -1.392839310344828 ], [ 29.779703924050633, -1.392569827586207 ], [ 29.779434430379748, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 743, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.392569827586207 ], [ 29.779703924050633, -1.392839310344828 ], [ 29.779973417721518, -1.392839310344828 ], [ 29.779973417721518, -1.392569827586207 ], [ 29.779703924050633, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 744, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.392569827586207 ], [ 29.779973417721518, -1.392839310344828 ], [ 29.780242911392406, -1.392839310344828 ], [ 29.780242911392406, -1.392569827586207 ], [ 29.779973417721518, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 745, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.392569827586207 ], [ 29.784824303797468, -1.39391724137931 ], [ 29.785093797468356, -1.39391724137931 ], [ 29.785093797468356, -1.393378275862069 ], [ 29.785363291139241, -1.393378275862069 ], [ 29.785363291139241, -1.393108793103448 ], [ 29.785093797468356, -1.393108793103448 ], [ 29.785093797468356, -1.392569827586207 ], [ 29.784824303797468, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 746, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.392569827586207 ], [ 29.785093797468356, -1.392839310344828 ], [ 29.785363291139241, -1.392839310344828 ], [ 29.785363291139241, -1.392569827586207 ], [ 29.785093797468356, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 747, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.392569827586207 ], [ 29.785363291139241, -1.392839310344828 ], [ 29.785632784810126, -1.392839310344828 ], [ 29.785632784810126, -1.392569827586207 ], [ 29.785363291139241, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 748, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.392569827586207 ], [ 29.785632784810126, -1.392839310344828 ], [ 29.785902278481014, -1.392839310344828 ], [ 29.785902278481014, -1.392569827586207 ], [ 29.785632784810126, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 749, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.392569827586207 ], [ 29.785902278481014, -1.392839310344828 ], [ 29.786171772151899, -1.392839310344828 ], [ 29.786171772151899, -1.392569827586207 ], [ 29.785902278481014, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 750, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.392569827586207 ], [ 29.786171772151899, -1.392839310344828 ], [ 29.786441265822784, -1.392839310344828 ], [ 29.786441265822784, -1.392569827586207 ], [ 29.786171772151899, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 751, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.392569827586207 ], [ 29.786441265822784, -1.392839310344828 ], [ 29.786710759493673, -1.392839310344828 ], [ 29.786710759493673, -1.392569827586207 ], [ 29.786441265822784, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 752, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.392569827586207 ], [ 29.786710759493673, -1.392839310344828 ], [ 29.786980253164558, -1.392839310344828 ], [ 29.786980253164558, -1.392569827586207 ], [ 29.786710759493673, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 753, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.392569827586207 ], [ 29.786980253164558, -1.392839310344828 ], [ 29.787519240506327, -1.392839310344828 ], [ 29.787519240506327, -1.392569827586207 ], [ 29.786980253164558, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 754, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.392569827586207 ], [ 29.787519240506327, -1.392839310344828 ], [ 29.787788734177216, -1.392839310344828 ], [ 29.787788734177216, -1.392569827586207 ], [ 29.787519240506327, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 755, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.392569827586207 ], [ 29.787788734177216, -1.392839310344828 ], [ 29.788058227848101, -1.392839310344828 ], [ 29.788058227848101, -1.392569827586207 ], [ 29.787788734177216, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 756, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.392569827586207 ], [ 29.788058227848101, -1.392839310344828 ], [ 29.788327721518986, -1.392839310344828 ], [ 29.788327721518986, -1.392569827586207 ], [ 29.788058227848101, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 757, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.392569827586207 ], [ 29.788327721518986, -1.392839310344828 ], [ 29.788597215189874, -1.392839310344828 ], [ 29.788597215189874, -1.392569827586207 ], [ 29.788327721518986, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 758, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.392569827586207 ], [ 29.788597215189874, -1.392839310344828 ], [ 29.788866708860759, -1.392839310344828 ], [ 29.788866708860759, -1.392569827586207 ], [ 29.788597215189874, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 759, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.392569827586207 ], [ 29.788866708860759, -1.392839310344828 ], [ 29.789136202531644, -1.392839310344828 ], [ 29.789136202531644, -1.392569827586207 ], [ 29.788866708860759, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 760, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.392569827586207 ], [ 29.789136202531644, -1.392839310344828 ], [ 29.789675189873417, -1.392839310344828 ], [ 29.789675189873417, -1.392569827586207 ], [ 29.789136202531644, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 761, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.392569827586207 ], [ 29.789675189873417, -1.392839310344828 ], [ 29.789944683544302, -1.392839310344828 ], [ 29.789944683544302, -1.392569827586207 ], [ 29.789675189873417, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 762, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.392569827586207 ], [ 29.789944683544302, -1.392839310344828 ], [ 29.790214177215191, -1.392839310344828 ], [ 29.790214177215191, -1.393108793103448 ], [ 29.790483670886076, -1.393108793103448 ], [ 29.790483670886076, -1.392569827586207 ], [ 29.789944683544302, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 763, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.392569827586207 ], [ 29.790483670886076, -1.392839310344828 ], [ 29.790753164556961, -1.392839310344828 ], [ 29.790753164556961, -1.392569827586207 ], [ 29.790483670886076, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 764, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.392569827586207 ], [ 29.791292151898734, -1.392839310344828 ], [ 29.791561645569619, -1.392839310344828 ], [ 29.791561645569619, -1.392569827586207 ], [ 29.791292151898734, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 765, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.392569827586207 ], [ 29.791561645569619, -1.392839310344828 ], [ 29.791831139240507, -1.392839310344828 ], [ 29.791831139240507, -1.392569827586207 ], [ 29.791561645569619, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 766, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.392569827586207 ], [ 29.791831139240507, -1.392839310344828 ], [ 29.792100632911392, -1.392839310344828 ], [ 29.792100632911392, -1.392569827586207 ], [ 29.791831139240507, -1.392569827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 767, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.392839310344828 ], [ 29.76946316455696, -1.393378275862069 ], [ 29.769732658227849, -1.393378275862069 ], [ 29.769732658227849, -1.392839310344828 ], [ 29.76946316455696, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 768, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.392839310344828 ], [ 29.769732658227849, -1.393108793103448 ], [ 29.770002151898733, -1.393108793103448 ], [ 29.770002151898733, -1.392839310344828 ], [ 29.769732658227849, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 769, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.392839310344828 ], [ 29.770002151898733, -1.393108793103448 ], [ 29.770271645569618, -1.393108793103448 ], [ 29.770271645569618, -1.392839310344828 ], [ 29.770002151898733, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 770, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.392839310344828 ], [ 29.770271645569618, -1.393108793103448 ], [ 29.770541139240507, -1.393108793103448 ], [ 29.770541139240507, -1.392839310344828 ], [ 29.770271645569618, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 771, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.392839310344828 ], [ 29.770541139240507, -1.393108793103448 ], [ 29.770810632911392, -1.393108793103448 ], [ 29.770810632911392, -1.392839310344828 ], [ 29.770541139240507, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 772, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.392839310344828 ], [ 29.770810632911392, -1.393108793103448 ], [ 29.771080126582277, -1.393108793103448 ], [ 29.771080126582277, -1.392839310344828 ], [ 29.770810632911392, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 773, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.392839310344828 ], [ 29.771080126582277, -1.393108793103448 ], [ 29.771349620253165, -1.393108793103448 ], [ 29.771349620253165, -1.392839310344828 ], [ 29.771080126582277, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 774, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.392839310344828 ], [ 29.771349620253165, -1.393108793103448 ], [ 29.77161911392405, -1.393108793103448 ], [ 29.77161911392405, -1.392839310344828 ], [ 29.771349620253165, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 775, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.392839310344828 ], [ 29.77161911392405, -1.393108793103448 ], [ 29.771888607594935, -1.393108793103448 ], [ 29.771888607594935, -1.392839310344828 ], [ 29.77161911392405, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 776, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.392839310344828 ], [ 29.771888607594935, -1.393108793103448 ], [ 29.772158101265823, -1.393108793103448 ], [ 29.772158101265823, -1.392839310344828 ], [ 29.771888607594935, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 777, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.392839310344828 ], [ 29.772158101265823, -1.393108793103448 ], [ 29.772427594936708, -1.393108793103448 ], [ 29.772427594936708, -1.392839310344828 ], [ 29.772158101265823, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 778, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.392839310344828 ], [ 29.772427594936708, -1.393108793103448 ], [ 29.772697088607593, -1.393108793103448 ], [ 29.772697088607593, -1.392839310344828 ], [ 29.772427594936708, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 779, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.392839310344828 ], [ 29.772697088607593, -1.393108793103448 ], [ 29.772966582278482, -1.393108793103448 ], [ 29.772966582278482, -1.392839310344828 ], [ 29.772697088607593, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 780, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.392839310344828 ], [ 29.772966582278482, -1.393108793103448 ], [ 29.773236075949367, -1.393108793103448 ], [ 29.773236075949367, -1.392839310344828 ], [ 29.772966582278482, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 781, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.392839310344828 ], [ 29.773236075949367, -1.393108793103448 ], [ 29.773505569620252, -1.393108793103448 ], [ 29.773505569620252, -1.392839310344828 ], [ 29.773236075949367, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 782, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.392839310344828 ], [ 29.773505569620252, -1.393108793103448 ], [ 29.77377506329114, -1.393108793103448 ], [ 29.77377506329114, -1.392839310344828 ], [ 29.773505569620252, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 783, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.392839310344828 ], [ 29.77377506329114, -1.393108793103448 ], [ 29.774044556962025, -1.393108793103448 ], [ 29.774044556962025, -1.392839310344828 ], [ 29.77377506329114, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 784, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.392839310344828 ], [ 29.774044556962025, -1.393108793103448 ], [ 29.77431405063291, -1.393108793103448 ], [ 29.77431405063291, -1.392839310344828 ], [ 29.774044556962025, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 785, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.392839310344828 ], [ 29.77431405063291, -1.393108793103448 ], [ 29.774853037974683, -1.393108793103448 ], [ 29.774853037974683, -1.392839310344828 ], [ 29.77431405063291, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 786, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.392839310344828 ], [ 29.774853037974683, -1.393108793103448 ], [ 29.775122531645568, -1.393108793103448 ], [ 29.775122531645568, -1.392839310344828 ], [ 29.774853037974683, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 787, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.392839310344828 ], [ 29.775122531645568, -1.393108793103448 ], [ 29.775392025316457, -1.393108793103448 ], [ 29.775392025316457, -1.392839310344828 ], [ 29.775122531645568, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 788, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.392839310344828 ], [ 29.775392025316457, -1.393108793103448 ], [ 29.775661518987341, -1.393108793103448 ], [ 29.775661518987341, -1.392839310344828 ], [ 29.775392025316457, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 789, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.392839310344828 ], [ 29.775661518987341, -1.393108793103448 ], [ 29.775931012658226, -1.393108793103448 ], [ 29.775931012658226, -1.392839310344828 ], [ 29.775661518987341, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 790, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.392839310344828 ], [ 29.775931012658226, -1.393108793103448 ], [ 29.776200506329115, -1.393108793103448 ], [ 29.776200506329115, -1.392839310344828 ], [ 29.775931012658226, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 791, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.392839310344828 ], [ 29.776200506329115, -1.393108793103448 ], [ 29.77647, -1.393108793103448 ], [ 29.77647, -1.392839310344828 ], [ 29.776200506329115, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 792, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.392839310344828 ], [ 29.77647, -1.393108793103448 ], [ 29.776739493670885, -1.393108793103448 ], [ 29.776739493670885, -1.392839310344828 ], [ 29.77647, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 793, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.392839310344828 ], [ 29.77916493670886, -1.393108793103448 ], [ 29.779434430379748, -1.393108793103448 ], [ 29.779434430379748, -1.392839310344828 ], [ 29.77916493670886, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 794, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.392839310344828 ], [ 29.779434430379748, -1.393108793103448 ], [ 29.779703924050633, -1.393108793103448 ], [ 29.779703924050633, -1.392839310344828 ], [ 29.779434430379748, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 795, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.392839310344828 ], [ 29.779703924050633, -1.393108793103448 ], [ 29.779973417721518, -1.393108793103448 ], [ 29.779973417721518, -1.392839310344828 ], [ 29.779703924050633, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 796, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.392839310344828 ], [ 29.779973417721518, -1.393108793103448 ], [ 29.780242911392406, -1.393108793103448 ], [ 29.780242911392406, -1.392839310344828 ], [ 29.779973417721518, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 797, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.392839310344828 ], [ 29.780242911392406, -1.393108793103448 ], [ 29.780512405063291, -1.393108793103448 ], [ 29.780512405063291, -1.392839310344828 ], [ 29.780242911392406, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 798, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.392839310344828 ], [ 29.785093797468356, -1.393108793103448 ], [ 29.785363291139241, -1.393108793103448 ], [ 29.785363291139241, -1.392839310344828 ], [ 29.785093797468356, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 799, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.392839310344828 ], [ 29.785363291139241, -1.393108793103448 ], [ 29.785632784810126, -1.393108793103448 ], [ 29.785632784810126, -1.392839310344828 ], [ 29.785363291139241, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 800, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.392839310344828 ], [ 29.785632784810126, -1.393108793103448 ], [ 29.785902278481014, -1.393108793103448 ], [ 29.785902278481014, -1.392839310344828 ], [ 29.785632784810126, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 801, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.392839310344828 ], [ 29.785902278481014, -1.393108793103448 ], [ 29.786171772151899, -1.393108793103448 ], [ 29.786171772151899, -1.392839310344828 ], [ 29.785902278481014, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 802, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.392839310344828 ], [ 29.786171772151899, -1.393108793103448 ], [ 29.786441265822784, -1.393108793103448 ], [ 29.786441265822784, -1.392839310344828 ], [ 29.786171772151899, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 803, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.392839310344828 ], [ 29.786441265822784, -1.393108793103448 ], [ 29.786710759493673, -1.393108793103448 ], [ 29.786710759493673, -1.392839310344828 ], [ 29.786441265822784, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 804, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.392839310344828 ], [ 29.786710759493673, -1.393108793103448 ], [ 29.786980253164558, -1.393108793103448 ], [ 29.786980253164558, -1.392839310344828 ], [ 29.786710759493673, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 805, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.392839310344828 ], [ 29.786980253164558, -1.393108793103448 ], [ 29.787519240506327, -1.393108793103448 ], [ 29.787519240506327, -1.392839310344828 ], [ 29.786980253164558, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 806, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.392839310344828 ], [ 29.787519240506327, -1.393108793103448 ], [ 29.787788734177216, -1.393108793103448 ], [ 29.787788734177216, -1.392839310344828 ], [ 29.787519240506327, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 807, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.392839310344828 ], [ 29.787788734177216, -1.393108793103448 ], [ 29.788058227848101, -1.393108793103448 ], [ 29.788058227848101, -1.392839310344828 ], [ 29.787788734177216, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 808, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.392839310344828 ], [ 29.788058227848101, -1.393108793103448 ], [ 29.788327721518986, -1.393108793103448 ], [ 29.788327721518986, -1.392839310344828 ], [ 29.788058227848101, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 809, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.392839310344828 ], [ 29.788327721518986, -1.393108793103448 ], [ 29.788597215189874, -1.393108793103448 ], [ 29.788597215189874, -1.392839310344828 ], [ 29.788327721518986, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 810, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.392839310344828 ], [ 29.788597215189874, -1.393108793103448 ], [ 29.788866708860759, -1.393108793103448 ], [ 29.788866708860759, -1.392839310344828 ], [ 29.788597215189874, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 811, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.392839310344828 ], [ 29.788866708860759, -1.393108793103448 ], [ 29.789136202531644, -1.393108793103448 ], [ 29.789136202531644, -1.392839310344828 ], [ 29.788866708860759, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 812, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.392839310344828 ], [ 29.789136202531644, -1.393108793103448 ], [ 29.789405696202532, -1.393108793103448 ], [ 29.789405696202532, -1.392839310344828 ], [ 29.789136202531644, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 813, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.392839310344828 ], [ 29.789405696202532, -1.393108793103448 ], [ 29.789675189873417, -1.393108793103448 ], [ 29.789675189873417, -1.392839310344828 ], [ 29.789405696202532, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 814, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.392839310344828 ], [ 29.789675189873417, -1.393108793103448 ], [ 29.789944683544302, -1.393108793103448 ], [ 29.789944683544302, -1.392839310344828 ], [ 29.789675189873417, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 815, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.392839310344828 ], [ 29.789944683544302, -1.393108793103448 ], [ 29.790214177215191, -1.393108793103448 ], [ 29.790214177215191, -1.392839310344828 ], [ 29.789944683544302, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 816, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.392839310344828 ], [ 29.790483670886076, -1.393378275862069 ], [ 29.790753164556961, -1.393378275862069 ], [ 29.790753164556961, -1.392839310344828 ], [ 29.790483670886076, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 817, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.392839310344828 ], [ 29.791292151898734, -1.393108793103448 ], [ 29.791561645569619, -1.393108793103448 ], [ 29.791561645569619, -1.392839310344828 ], [ 29.791292151898734, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 818, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.392839310344828 ], [ 29.791561645569619, -1.393108793103448 ], [ 29.791831139240507, -1.393108793103448 ], [ 29.791831139240507, -1.392839310344828 ], [ 29.791561645569619, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 819, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.392839310344828 ], [ 29.791831139240507, -1.393108793103448 ], [ 29.792100632911392, -1.393108793103448 ], [ 29.792100632911392, -1.392839310344828 ], [ 29.791831139240507, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 820, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.392839310344828 ], [ 29.792100632911392, -1.393108793103448 ], [ 29.792370126582277, -1.393108793103448 ], [ 29.792370126582277, -1.392839310344828 ], [ 29.792100632911392, -1.392839310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.393108793103448 ], [ 29.769732658227849, -1.393378275862069 ], [ 29.770002151898733, -1.393378275862069 ], [ 29.770002151898733, -1.393108793103448 ], [ 29.769732658227849, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 822, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.393108793103448 ], [ 29.770002151898733, -1.393378275862069 ], [ 29.770271645569618, -1.393378275862069 ], [ 29.770271645569618, -1.393108793103448 ], [ 29.770002151898733, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 823, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.393108793103448 ], [ 29.770271645569618, -1.393378275862069 ], [ 29.770541139240507, -1.393378275862069 ], [ 29.770541139240507, -1.393108793103448 ], [ 29.770271645569618, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 824, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.393108793103448 ], [ 29.770541139240507, -1.393378275862069 ], [ 29.770810632911392, -1.393378275862069 ], [ 29.770810632911392, -1.393108793103448 ], [ 29.770541139240507, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 825, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.393108793103448 ], [ 29.770810632911392, -1.393378275862069 ], [ 29.771080126582277, -1.393378275862069 ], [ 29.771080126582277, -1.393108793103448 ], [ 29.770810632911392, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 826, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.393108793103448 ], [ 29.771080126582277, -1.393378275862069 ], [ 29.771349620253165, -1.393378275862069 ], [ 29.771349620253165, -1.393108793103448 ], [ 29.771080126582277, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 827, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.393108793103448 ], [ 29.771349620253165, -1.393378275862069 ], [ 29.77161911392405, -1.393378275862069 ], [ 29.77161911392405, -1.393108793103448 ], [ 29.771349620253165, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 828, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.393108793103448 ], [ 29.77161911392405, -1.393378275862069 ], [ 29.771888607594935, -1.393378275862069 ], [ 29.771888607594935, -1.393108793103448 ], [ 29.77161911392405, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 829, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.393108793103448 ], [ 29.771888607594935, -1.393378275862069 ], [ 29.772158101265823, -1.393378275862069 ], [ 29.772158101265823, -1.393108793103448 ], [ 29.771888607594935, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 830, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.393108793103448 ], [ 29.772158101265823, -1.393378275862069 ], [ 29.772427594936708, -1.393378275862069 ], [ 29.772427594936708, -1.393108793103448 ], [ 29.772158101265823, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 831, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.393108793103448 ], [ 29.772427594936708, -1.393378275862069 ], [ 29.772697088607593, -1.393378275862069 ], [ 29.772697088607593, -1.393108793103448 ], [ 29.772427594936708, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 832, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.393108793103448 ], [ 29.772697088607593, -1.393378275862069 ], [ 29.772966582278482, -1.393378275862069 ], [ 29.772966582278482, -1.393108793103448 ], [ 29.772697088607593, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 833, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.393108793103448 ], [ 29.772966582278482, -1.393378275862069 ], [ 29.773236075949367, -1.393378275862069 ], [ 29.773236075949367, -1.393108793103448 ], [ 29.772966582278482, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 834, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.393108793103448 ], [ 29.773236075949367, -1.393378275862069 ], [ 29.773505569620252, -1.393378275862069 ], [ 29.773505569620252, -1.393108793103448 ], [ 29.773236075949367, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 835, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.393108793103448 ], [ 29.773505569620252, -1.393378275862069 ], [ 29.77377506329114, -1.393378275862069 ], [ 29.77377506329114, -1.393108793103448 ], [ 29.773505569620252, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 836, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.393108793103448 ], [ 29.77377506329114, -1.393378275862069 ], [ 29.774044556962025, -1.393378275862069 ], [ 29.774044556962025, -1.393108793103448 ], [ 29.77377506329114, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 837, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.393108793103448 ], [ 29.774044556962025, -1.393378275862069 ], [ 29.77431405063291, -1.393378275862069 ], [ 29.77431405063291, -1.393108793103448 ], [ 29.774044556962025, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 838, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.393108793103448 ], [ 29.77431405063291, -1.393378275862069 ], [ 29.774853037974683, -1.393378275862069 ], [ 29.774853037974683, -1.393108793103448 ], [ 29.77431405063291, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 839, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.393108793103448 ], [ 29.774853037974683, -1.393378275862069 ], [ 29.775122531645568, -1.393378275862069 ], [ 29.775122531645568, -1.393108793103448 ], [ 29.774853037974683, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 840, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.393108793103448 ], [ 29.775122531645568, -1.393378275862069 ], [ 29.775392025316457, -1.393378275862069 ], [ 29.775392025316457, -1.393108793103448 ], [ 29.775122531645568, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 841, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.393108793103448 ], [ 29.775392025316457, -1.393378275862069 ], [ 29.775661518987341, -1.393378275862069 ], [ 29.775661518987341, -1.393108793103448 ], [ 29.775392025316457, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 842, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.393108793103448 ], [ 29.775661518987341, -1.393378275862069 ], [ 29.775931012658226, -1.393378275862069 ], [ 29.775931012658226, -1.393108793103448 ], [ 29.775661518987341, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 843, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.393108793103448 ], [ 29.775931012658226, -1.393378275862069 ], [ 29.776200506329115, -1.393378275862069 ], [ 29.776200506329115, -1.393108793103448 ], [ 29.775931012658226, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 844, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.393108793103448 ], [ 29.776200506329115, -1.393378275862069 ], [ 29.77647, -1.393378275862069 ], [ 29.77647, -1.393108793103448 ], [ 29.776200506329115, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 845, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.393108793103448 ], [ 29.77647, -1.393378275862069 ], [ 29.776739493670885, -1.393378275862069 ], [ 29.776739493670885, -1.393108793103448 ], [ 29.77647, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 846, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.393108793103448 ], [ 29.776739493670885, -1.393378275862069 ], [ 29.777008987341773, -1.393378275862069 ], [ 29.777008987341773, -1.393108793103448 ], [ 29.776739493670885, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 847, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.393108793103448 ], [ 29.777547974683543, -1.393378275862069 ], [ 29.778086962025316, -1.393378275862069 ], [ 29.778086962025316, -1.393108793103448 ], [ 29.777547974683543, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 848, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.393108793103448 ], [ 29.778086962025316, -1.393378275862069 ], [ 29.778356455696201, -1.393378275862069 ], [ 29.778356455696201, -1.393108793103448 ], [ 29.778086962025316, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 849, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.393108793103448 ], [ 29.778356455696201, -1.393378275862069 ], [ 29.77862594936709, -1.393378275862069 ], [ 29.77862594936709, -1.393108793103448 ], [ 29.778356455696201, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 850, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.393108793103448 ], [ 29.77862594936709, -1.393378275862069 ], [ 29.778895443037975, -1.393378275862069 ], [ 29.778895443037975, -1.393108793103448 ], [ 29.77862594936709, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.393108793103448 ], [ 29.779434430379748, -1.393378275862069 ], [ 29.779703924050633, -1.393378275862069 ], [ 29.779703924050633, -1.393108793103448 ], [ 29.779434430379748, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 852, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.393108793103448 ], [ 29.779703924050633, -1.393378275862069 ], [ 29.779973417721518, -1.393378275862069 ], [ 29.779973417721518, -1.393108793103448 ], [ 29.779703924050633, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 853, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.393108793103448 ], [ 29.779973417721518, -1.393378275862069 ], [ 29.780242911392406, -1.393378275862069 ], [ 29.780242911392406, -1.393108793103448 ], [ 29.779973417721518, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 854, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.393108793103448 ], [ 29.780242911392406, -1.393378275862069 ], [ 29.780512405063291, -1.393378275862069 ], [ 29.780512405063291, -1.393108793103448 ], [ 29.780242911392406, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 855, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.393108793103448 ], [ 29.780512405063291, -1.393378275862069 ], [ 29.780781898734176, -1.393378275862069 ], [ 29.780781898734176, -1.393108793103448 ], [ 29.780512405063291, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 856, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.393108793103448 ], [ 29.785363291139241, -1.393378275862069 ], [ 29.785632784810126, -1.393378275862069 ], [ 29.785632784810126, -1.393108793103448 ], [ 29.785363291139241, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 857, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.393108793103448 ], [ 29.785632784810126, -1.393378275862069 ], [ 29.785902278481014, -1.393378275862069 ], [ 29.785902278481014, -1.393108793103448 ], [ 29.785632784810126, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 858, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.393108793103448 ], [ 29.785902278481014, -1.393378275862069 ], [ 29.786171772151899, -1.393378275862069 ], [ 29.786171772151899, -1.393108793103448 ], [ 29.785902278481014, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 859, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.393108793103448 ], [ 29.786171772151899, -1.393378275862069 ], [ 29.786441265822784, -1.393378275862069 ], [ 29.786441265822784, -1.393108793103448 ], [ 29.786171772151899, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 860, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.393108793103448 ], [ 29.786441265822784, -1.393378275862069 ], [ 29.786710759493673, -1.393378275862069 ], [ 29.786710759493673, -1.393108793103448 ], [ 29.786441265822784, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 861, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.393108793103448 ], [ 29.786710759493673, -1.393378275862069 ], [ 29.787788734177216, -1.393378275862069 ], [ 29.787788734177216, -1.393108793103448 ], [ 29.786710759493673, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 862, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.393108793103448 ], [ 29.787788734177216, -1.393378275862069 ], [ 29.788058227848101, -1.393378275862069 ], [ 29.788058227848101, -1.393108793103448 ], [ 29.787788734177216, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 863, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.393108793103448 ], [ 29.788058227848101, -1.393378275862069 ], [ 29.788327721518986, -1.393378275862069 ], [ 29.788327721518986, -1.393108793103448 ], [ 29.788058227848101, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 864, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.393108793103448 ], [ 29.788327721518986, -1.393378275862069 ], [ 29.788597215189874, -1.393378275862069 ], [ 29.788597215189874, -1.393108793103448 ], [ 29.788327721518986, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 865, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.393108793103448 ], [ 29.788597215189874, -1.393378275862069 ], [ 29.788866708860759, -1.393378275862069 ], [ 29.788866708860759, -1.393108793103448 ], [ 29.788597215189874, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 866, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.393108793103448 ], [ 29.788866708860759, -1.393378275862069 ], [ 29.789136202531644, -1.393378275862069 ], [ 29.789136202531644, -1.393108793103448 ], [ 29.788866708860759, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 867, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.393108793103448 ], [ 29.789405696202532, -1.393378275862069 ], [ 29.789675189873417, -1.393378275862069 ], [ 29.789675189873417, -1.393108793103448 ], [ 29.789405696202532, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 868, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.393108793103448 ], [ 29.789675189873417, -1.393378275862069 ], [ 29.789944683544302, -1.393378275862069 ], [ 29.789944683544302, -1.393108793103448 ], [ 29.789675189873417, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 869, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.393108793103448 ], [ 29.789944683544302, -1.393378275862069 ], [ 29.790214177215191, -1.393378275862069 ], [ 29.790214177215191, -1.393108793103448 ], [ 29.789944683544302, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 870, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.393108793103448 ], [ 29.790214177215191, -1.393378275862069 ], [ 29.790483670886076, -1.393378275862069 ], [ 29.790483670886076, -1.393108793103448 ], [ 29.790214177215191, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 871, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.393108793103448 ], [ 29.790753164556961, -1.393378275862069 ], [ 29.791022658227849, -1.393378275862069 ], [ 29.791022658227849, -1.393108793103448 ], [ 29.790753164556961, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 872, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.393108793103448 ], [ 29.791022658227849, -1.393378275862069 ], [ 29.791292151898734, -1.393378275862069 ], [ 29.791292151898734, -1.393108793103448 ], [ 29.791022658227849, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 873, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.393108793103448 ], [ 29.791292151898734, -1.393378275862069 ], [ 29.791561645569619, -1.393378275862069 ], [ 29.791561645569619, -1.393108793103448 ], [ 29.791292151898734, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 874, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.393108793103448 ], [ 29.791561645569619, -1.393378275862069 ], [ 29.791831139240507, -1.393378275862069 ], [ 29.791831139240507, -1.393108793103448 ], [ 29.791561645569619, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 875, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.393108793103448 ], [ 29.791831139240507, -1.393378275862069 ], [ 29.792100632911392, -1.393378275862069 ], [ 29.792100632911392, -1.393108793103448 ], [ 29.791831139240507, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 876, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.393108793103448 ], [ 29.792100632911392, -1.393378275862069 ], [ 29.792370126582277, -1.393378275862069 ], [ 29.792370126582277, -1.393108793103448 ], [ 29.792100632911392, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 877, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.393108793103448 ], [ 29.792370126582277, -1.393378275862069 ], [ 29.793178607594935, -1.393378275862069 ], [ 29.793178607594935, -1.393108793103448 ], [ 29.792370126582277, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 878, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.393378275862069 ], [ 29.769193670886075, -1.39391724137931 ], [ 29.76946316455696, -1.39391724137931 ], [ 29.76946316455696, -1.393378275862069 ], [ 29.769193670886075, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 879, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.393378275862069 ], [ 29.76946316455696, -1.39364775862069 ], [ 29.769732658227849, -1.39364775862069 ], [ 29.769732658227849, -1.393378275862069 ], [ 29.76946316455696, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 880, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.393378275862069 ], [ 29.769732658227849, -1.39364775862069 ], [ 29.770002151898733, -1.39364775862069 ], [ 29.770002151898733, -1.393378275862069 ], [ 29.769732658227849, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 881, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.393378275862069 ], [ 29.770002151898733, -1.39364775862069 ], [ 29.770271645569618, -1.39364775862069 ], [ 29.770271645569618, -1.393378275862069 ], [ 29.770002151898733, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 882, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.393378275862069 ], [ 29.770271645569618, -1.39364775862069 ], [ 29.770541139240507, -1.39364775862069 ], [ 29.770541139240507, -1.393378275862069 ], [ 29.770271645569618, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 883, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.393378275862069 ], [ 29.770541139240507, -1.39364775862069 ], [ 29.770810632911392, -1.39364775862069 ], [ 29.770810632911392, -1.393378275862069 ], [ 29.770541139240507, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 884, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.393378275862069 ], [ 29.770810632911392, -1.39364775862069 ], [ 29.771080126582277, -1.39364775862069 ], [ 29.771080126582277, -1.393378275862069 ], [ 29.770810632911392, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 885, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.393378275862069 ], [ 29.771080126582277, -1.39364775862069 ], [ 29.771349620253165, -1.39364775862069 ], [ 29.771349620253165, -1.393378275862069 ], [ 29.771080126582277, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 886, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.393378275862069 ], [ 29.771349620253165, -1.39364775862069 ], [ 29.77161911392405, -1.39364775862069 ], [ 29.77161911392405, -1.393378275862069 ], [ 29.771349620253165, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 887, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.393378275862069 ], [ 29.77161911392405, -1.39364775862069 ], [ 29.771888607594935, -1.39364775862069 ], [ 29.771888607594935, -1.393378275862069 ], [ 29.77161911392405, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 888, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.393378275862069 ], [ 29.771888607594935, -1.39364775862069 ], [ 29.772158101265823, -1.39364775862069 ], [ 29.772158101265823, -1.393378275862069 ], [ 29.771888607594935, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 889, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.393378275862069 ], [ 29.772158101265823, -1.39364775862069 ], [ 29.772427594936708, -1.39364775862069 ], [ 29.772427594936708, -1.393378275862069 ], [ 29.772158101265823, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 890, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.393378275862069 ], [ 29.772427594936708, -1.39364775862069 ], [ 29.772966582278482, -1.39364775862069 ], [ 29.772966582278482, -1.393378275862069 ], [ 29.772427594936708, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 891, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.393378275862069 ], [ 29.772966582278482, -1.39364775862069 ], [ 29.773236075949367, -1.39364775862069 ], [ 29.773236075949367, -1.393378275862069 ], [ 29.772966582278482, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 892, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.393378275862069 ], [ 29.773236075949367, -1.39364775862069 ], [ 29.773505569620252, -1.39364775862069 ], [ 29.773505569620252, -1.393378275862069 ], [ 29.773236075949367, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 893, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.393378275862069 ], [ 29.773505569620252, -1.39364775862069 ], [ 29.77377506329114, -1.39364775862069 ], [ 29.77377506329114, -1.393378275862069 ], [ 29.773505569620252, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 894, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.393378275862069 ], [ 29.77377506329114, -1.39364775862069 ], [ 29.774044556962025, -1.39364775862069 ], [ 29.774044556962025, -1.393378275862069 ], [ 29.77377506329114, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 895, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.393378275862069 ], [ 29.774044556962025, -1.39364775862069 ], [ 29.77431405063291, -1.39364775862069 ], [ 29.77431405063291, -1.393378275862069 ], [ 29.774044556962025, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 896, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.393378275862069 ], [ 29.77431405063291, -1.39364775862069 ], [ 29.774853037974683, -1.39364775862069 ], [ 29.774853037974683, -1.393378275862069 ], [ 29.77431405063291, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 897, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.393378275862069 ], [ 29.774853037974683, -1.39364775862069 ], [ 29.775122531645568, -1.39364775862069 ], [ 29.775122531645568, -1.393378275862069 ], [ 29.774853037974683, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 898, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.393378275862069 ], [ 29.775122531645568, -1.39364775862069 ], [ 29.775392025316457, -1.39364775862069 ], [ 29.775392025316457, -1.393378275862069 ], [ 29.775122531645568, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 899, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.393378275862069 ], [ 29.775392025316457, -1.39364775862069 ], [ 29.775661518987341, -1.39364775862069 ], [ 29.775661518987341, -1.393378275862069 ], [ 29.775392025316457, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 900, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.393378275862069 ], [ 29.775661518987341, -1.39364775862069 ], [ 29.775931012658226, -1.39364775862069 ], [ 29.775931012658226, -1.393378275862069 ], [ 29.775661518987341, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 901, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.393378275862069 ], [ 29.775931012658226, -1.39364775862069 ], [ 29.776200506329115, -1.39364775862069 ], [ 29.776200506329115, -1.393378275862069 ], [ 29.775931012658226, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 902, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.393378275862069 ], [ 29.776200506329115, -1.39364775862069 ], [ 29.77647, -1.39364775862069 ], [ 29.77647, -1.393378275862069 ], [ 29.776200506329115, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 903, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.393378275862069 ], [ 29.77647, -1.39364775862069 ], [ 29.776739493670885, -1.39364775862069 ], [ 29.776739493670885, -1.393378275862069 ], [ 29.77647, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 904, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.393378275862069 ], [ 29.776739493670885, -1.39364775862069 ], [ 29.777008987341773, -1.39364775862069 ], [ 29.777008987341773, -1.393378275862069 ], [ 29.776739493670885, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 905, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.393378275862069 ], [ 29.777008987341773, -1.39364775862069 ], [ 29.777547974683543, -1.39364775862069 ], [ 29.777547974683543, -1.393378275862069 ], [ 29.777008987341773, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 906, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.393378275862069 ], [ 29.777547974683543, -1.39364775862069 ], [ 29.777817468354431, -1.39364775862069 ], [ 29.777817468354431, -1.393378275862069 ], [ 29.777547974683543, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 907, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.393378275862069 ], [ 29.777817468354431, -1.39364775862069 ], [ 29.778086962025316, -1.39364775862069 ], [ 29.778086962025316, -1.393378275862069 ], [ 29.777817468354431, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 908, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.393378275862069 ], [ 29.778086962025316, -1.39364775862069 ], [ 29.778356455696201, -1.39364775862069 ], [ 29.778356455696201, -1.393378275862069 ], [ 29.778086962025316, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 909, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.393378275862069 ], [ 29.778356455696201, -1.39364775862069 ], [ 29.77862594936709, -1.39364775862069 ], [ 29.77862594936709, -1.393378275862069 ], [ 29.778356455696201, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 910, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.393378275862069 ], [ 29.77862594936709, -1.39364775862069 ], [ 29.778895443037975, -1.39364775862069 ], [ 29.778895443037975, -1.393378275862069 ], [ 29.77862594936709, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 911, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.393378275862069 ], [ 29.778895443037975, -1.39364775862069 ], [ 29.77916493670886, -1.39364775862069 ], [ 29.77916493670886, -1.393378275862069 ], [ 29.778895443037975, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 912, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.393378275862069 ], [ 29.77916493670886, -1.39364775862069 ], [ 29.779434430379748, -1.39364775862069 ], [ 29.779434430379748, -1.393378275862069 ], [ 29.77916493670886, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 913, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.393378275862069 ], [ 29.779434430379748, -1.39364775862069 ], [ 29.779703924050633, -1.39364775862069 ], [ 29.779703924050633, -1.393378275862069 ], [ 29.779434430379748, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 914, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.393378275862069 ], [ 29.779703924050633, -1.39364775862069 ], [ 29.779973417721518, -1.39364775862069 ], [ 29.779973417721518, -1.393378275862069 ], [ 29.779703924050633, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 915, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.393378275862069 ], [ 29.779973417721518, -1.39364775862069 ], [ 29.780242911392406, -1.39364775862069 ], [ 29.780242911392406, -1.393378275862069 ], [ 29.779973417721518, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 916, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.393378275862069 ], [ 29.780242911392406, -1.39364775862069 ], [ 29.780512405063291, -1.39364775862069 ], [ 29.780512405063291, -1.393378275862069 ], [ 29.780242911392406, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 917, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.393378275862069 ], [ 29.780512405063291, -1.39364775862069 ], [ 29.780781898734176, -1.39364775862069 ], [ 29.780781898734176, -1.393378275862069 ], [ 29.780512405063291, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 918, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.393378275862069 ], [ 29.780781898734176, -1.39364775862069 ], [ 29.781051392405065, -1.39364775862069 ], [ 29.781051392405065, -1.393378275862069 ], [ 29.780781898734176, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 919, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.393378275862069 ], [ 29.785093797468356, -1.39364775862069 ], [ 29.785363291139241, -1.39364775862069 ], [ 29.785363291139241, -1.393378275862069 ], [ 29.785093797468356, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 920, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.393378275862069 ], [ 29.785363291139241, -1.39364775862069 ], [ 29.785632784810126, -1.39364775862069 ], [ 29.785632784810126, -1.393378275862069 ], [ 29.785363291139241, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 921, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.393378275862069 ], [ 29.785632784810126, -1.39364775862069 ], [ 29.785902278481014, -1.39364775862069 ], [ 29.785902278481014, -1.393378275862069 ], [ 29.785632784810126, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 922, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.393378275862069 ], [ 29.785902278481014, -1.39364775862069 ], [ 29.786171772151899, -1.39364775862069 ], [ 29.786171772151899, -1.393378275862069 ], [ 29.785902278481014, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 923, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.393378275862069 ], [ 29.786171772151899, -1.39364775862069 ], [ 29.786441265822784, -1.39364775862069 ], [ 29.786441265822784, -1.393378275862069 ], [ 29.786171772151899, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 924, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.393378275862069 ], [ 29.786441265822784, -1.39364775862069 ], [ 29.786710759493673, -1.39364775862069 ], [ 29.786710759493673, -1.393378275862069 ], [ 29.786441265822784, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 925, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.393378275862069 ], [ 29.786710759493673, -1.39364775862069 ], [ 29.787788734177216, -1.39364775862069 ], [ 29.787788734177216, -1.393378275862069 ], [ 29.786710759493673, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 926, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.393378275862069 ], [ 29.787788734177216, -1.39364775862069 ], [ 29.788058227848101, -1.39364775862069 ], [ 29.788058227848101, -1.393378275862069 ], [ 29.787788734177216, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 927, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.393378275862069 ], [ 29.788058227848101, -1.39364775862069 ], [ 29.788327721518986, -1.39364775862069 ], [ 29.788327721518986, -1.393378275862069 ], [ 29.788058227848101, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 928, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.393378275862069 ], [ 29.788327721518986, -1.39364775862069 ], [ 29.788597215189874, -1.39364775862069 ], [ 29.788597215189874, -1.393378275862069 ], [ 29.788327721518986, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 929, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.393378275862069 ], [ 29.788597215189874, -1.39391724137931 ], [ 29.789675189873417, -1.39391724137931 ], [ 29.789675189873417, -1.39364775862069 ], [ 29.788866708860759, -1.39364775862069 ], [ 29.788866708860759, -1.393378275862069 ], [ 29.788597215189874, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 930, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.393108793103448 ], [ 29.789405696202532, -1.393108793103448 ], [ 29.789405696202532, -1.393378275862069 ], [ 29.789675189873417, -1.393378275862069 ], [ 29.789675189873417, -1.39364775862069 ], [ 29.788866708860759, -1.39364775862069 ], [ 29.788866708860759, -1.393378275862069 ], [ 29.789136202531644, -1.393378275862069 ], [ 29.789136202531644, -1.393108793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 931, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.393378275862069 ], [ 29.789675189873417, -1.39364775862069 ], [ 29.789944683544302, -1.39364775862069 ], [ 29.789944683544302, -1.393378275862069 ], [ 29.789675189873417, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 932, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.393378275862069 ], [ 29.789944683544302, -1.39364775862069 ], [ 29.790214177215191, -1.39364775862069 ], [ 29.790214177215191, -1.393378275862069 ], [ 29.789944683544302, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 933, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.393378275862069 ], [ 29.790214177215191, -1.39364775862069 ], [ 29.790483670886076, -1.39364775862069 ], [ 29.790483670886076, -1.393378275862069 ], [ 29.790214177215191, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 934, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.393378275862069 ], [ 29.790483670886076, -1.39364775862069 ], [ 29.790753164556961, -1.39364775862069 ], [ 29.790753164556961, -1.393378275862069 ], [ 29.790483670886076, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 935, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.393378275862069 ], [ 29.790753164556961, -1.39364775862069 ], [ 29.791022658227849, -1.39364775862069 ], [ 29.791022658227849, -1.393378275862069 ], [ 29.790753164556961, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 936, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.393378275862069 ], [ 29.791022658227849, -1.39364775862069 ], [ 29.791292151898734, -1.39364775862069 ], [ 29.791292151898734, -1.393378275862069 ], [ 29.791022658227849, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 937, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.393378275862069 ], [ 29.791292151898734, -1.39364775862069 ], [ 29.791561645569619, -1.39364775862069 ], [ 29.791561645569619, -1.393378275862069 ], [ 29.791292151898734, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 938, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.393378275862069 ], [ 29.791561645569619, -1.39364775862069 ], [ 29.791831139240507, -1.39364775862069 ], [ 29.791831139240507, -1.393378275862069 ], [ 29.791561645569619, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 939, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.393378275862069 ], [ 29.791831139240507, -1.39364775862069 ], [ 29.792100632911392, -1.39364775862069 ], [ 29.792100632911392, -1.393378275862069 ], [ 29.791831139240507, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 940, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.393378275862069 ], [ 29.792100632911392, -1.39364775862069 ], [ 29.792370126582277, -1.39364775862069 ], [ 29.792370126582277, -1.393378275862069 ], [ 29.792100632911392, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 941, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.393378275862069 ], [ 29.792370126582277, -1.39364775862069 ], [ 29.792909113924051, -1.39364775862069 ], [ 29.792909113924051, -1.393378275862069 ], [ 29.792370126582277, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 942, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.393378275862069 ], [ 29.792909113924051, -1.39364775862069 ], [ 29.793178607594935, -1.39364775862069 ], [ 29.793178607594935, -1.393378275862069 ], [ 29.792909113924051, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 943, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.393378275862069 ], [ 29.793178607594935, -1.39364775862069 ], [ 29.793448101265824, -1.39364775862069 ], [ 29.793448101265824, -1.393378275862069 ], [ 29.793178607594935, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 944, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.393378275862069 ], [ 29.795873544303799, -1.39391724137931 ], [ 29.796143037974684, -1.39391724137931 ], [ 29.796143037974684, -1.39364775862069 ], [ 29.796412531645569, -1.39364775862069 ], [ 29.796412531645569, -1.393378275862069 ], [ 29.795873544303799, -1.393378275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 945, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.39364775862069 ], [ 29.76946316455696, -1.39391724137931 ], [ 29.769732658227849, -1.39391724137931 ], [ 29.769732658227849, -1.39364775862069 ], [ 29.76946316455696, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.39364775862069 ], [ 29.769732658227849, -1.39391724137931 ], [ 29.770002151898733, -1.39391724137931 ], [ 29.770002151898733, -1.39364775862069 ], [ 29.769732658227849, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 947, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.39364775862069 ], [ 29.770002151898733, -1.39391724137931 ], [ 29.770271645569618, -1.39391724137931 ], [ 29.770271645569618, -1.39364775862069 ], [ 29.770002151898733, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 948, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.39364775862069 ], [ 29.770271645569618, -1.39391724137931 ], [ 29.770541139240507, -1.39391724137931 ], [ 29.770541139240507, -1.39364775862069 ], [ 29.770271645569618, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 949, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.39364775862069 ], [ 29.770541139240507, -1.39391724137931 ], [ 29.770810632911392, -1.39391724137931 ], [ 29.770810632911392, -1.39364775862069 ], [ 29.770541139240507, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 950, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.39364775862069 ], [ 29.770810632911392, -1.39391724137931 ], [ 29.771080126582277, -1.39391724137931 ], [ 29.771080126582277, -1.39364775862069 ], [ 29.770810632911392, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 951, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.39364775862069 ], [ 29.771080126582277, -1.39391724137931 ], [ 29.771349620253165, -1.39391724137931 ], [ 29.771349620253165, -1.39364775862069 ], [ 29.771080126582277, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 952, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.39364775862069 ], [ 29.771349620253165, -1.39391724137931 ], [ 29.77161911392405, -1.39391724137931 ], [ 29.77161911392405, -1.39364775862069 ], [ 29.771349620253165, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 953, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.39364775862069 ], [ 29.77161911392405, -1.39391724137931 ], [ 29.771888607594935, -1.39391724137931 ], [ 29.771888607594935, -1.39364775862069 ], [ 29.77161911392405, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 954, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.39364775862069 ], [ 29.771888607594935, -1.39391724137931 ], [ 29.772158101265823, -1.39391724137931 ], [ 29.772158101265823, -1.39364775862069 ], [ 29.771888607594935, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 955, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.39364775862069 ], [ 29.772158101265823, -1.39391724137931 ], [ 29.772427594936708, -1.39391724137931 ], [ 29.772427594936708, -1.39364775862069 ], [ 29.772158101265823, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 956, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.39364775862069 ], [ 29.772427594936708, -1.39391724137931 ], [ 29.772697088607593, -1.39391724137931 ], [ 29.772697088607593, -1.39364775862069 ], [ 29.772427594936708, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 957, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.39364775862069 ], [ 29.772697088607593, -1.39391724137931 ], [ 29.772966582278482, -1.39391724137931 ], [ 29.772966582278482, -1.39364775862069 ], [ 29.772697088607593, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 958, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.39364775862069 ], [ 29.772966582278482, -1.39391724137931 ], [ 29.773236075949367, -1.39391724137931 ], [ 29.773236075949367, -1.39364775862069 ], [ 29.772966582278482, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 959, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.39364775862069 ], [ 29.773236075949367, -1.39391724137931 ], [ 29.773505569620252, -1.39391724137931 ], [ 29.773505569620252, -1.39364775862069 ], [ 29.773236075949367, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 960, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.39364775862069 ], [ 29.773505569620252, -1.39391724137931 ], [ 29.77377506329114, -1.39391724137931 ], [ 29.77377506329114, -1.39364775862069 ], [ 29.773505569620252, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 961, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.39364775862069 ], [ 29.77377506329114, -1.39391724137931 ], [ 29.774044556962025, -1.39391724137931 ], [ 29.774044556962025, -1.39364775862069 ], [ 29.77377506329114, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 962, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.39364775862069 ], [ 29.774044556962025, -1.39391724137931 ], [ 29.77431405063291, -1.39391724137931 ], [ 29.77431405063291, -1.39364775862069 ], [ 29.774044556962025, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 963, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.39364775862069 ], [ 29.77431405063291, -1.39391724137931 ], [ 29.774853037974683, -1.39391724137931 ], [ 29.774853037974683, -1.39364775862069 ], [ 29.77431405063291, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 964, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.39364775862069 ], [ 29.774853037974683, -1.39391724137931 ], [ 29.775122531645568, -1.39391724137931 ], [ 29.775122531645568, -1.39364775862069 ], [ 29.774853037974683, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 965, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.39364775862069 ], [ 29.775122531645568, -1.39391724137931 ], [ 29.775392025316457, -1.39391724137931 ], [ 29.775392025316457, -1.39364775862069 ], [ 29.775122531645568, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 966, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.39364775862069 ], [ 29.775392025316457, -1.39391724137931 ], [ 29.775661518987341, -1.39391724137931 ], [ 29.775661518987341, -1.39364775862069 ], [ 29.775392025316457, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 967, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.39364775862069 ], [ 29.775661518987341, -1.39391724137931 ], [ 29.775931012658226, -1.39391724137931 ], [ 29.775931012658226, -1.39364775862069 ], [ 29.775661518987341, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 968, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.39364775862069 ], [ 29.775931012658226, -1.39391724137931 ], [ 29.776200506329115, -1.39391724137931 ], [ 29.776200506329115, -1.39364775862069 ], [ 29.775931012658226, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 969, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.39364775862069 ], [ 29.776200506329115, -1.39391724137931 ], [ 29.77647, -1.39391724137931 ], [ 29.77647, -1.39364775862069 ], [ 29.776200506329115, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 970, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.39364775862069 ], [ 29.77647, -1.39391724137931 ], [ 29.776739493670885, -1.39391724137931 ], [ 29.776739493670885, -1.39364775862069 ], [ 29.77647, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 971, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.39364775862069 ], [ 29.776739493670885, -1.39391724137931 ], [ 29.777008987341773, -1.39391724137931 ], [ 29.777008987341773, -1.39364775862069 ], [ 29.776739493670885, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 972, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.39364775862069 ], [ 29.777008987341773, -1.39391724137931 ], [ 29.777547974683543, -1.39391724137931 ], [ 29.777547974683543, -1.39364775862069 ], [ 29.777008987341773, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 973, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.39364775862069 ], [ 29.777547974683543, -1.39391724137931 ], [ 29.777817468354431, -1.39391724137931 ], [ 29.777817468354431, -1.39364775862069 ], [ 29.777547974683543, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 974, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.39364775862069 ], [ 29.777817468354431, -1.39391724137931 ], [ 29.778086962025316, -1.39391724137931 ], [ 29.778086962025316, -1.39364775862069 ], [ 29.777817468354431, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 975, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.39364775862069 ], [ 29.778086962025316, -1.39391724137931 ], [ 29.778356455696201, -1.39391724137931 ], [ 29.778356455696201, -1.39364775862069 ], [ 29.778086962025316, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 976, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.39364775862069 ], [ 29.778356455696201, -1.39391724137931 ], [ 29.77862594936709, -1.39391724137931 ], [ 29.77862594936709, -1.39364775862069 ], [ 29.778356455696201, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 977, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.39364775862069 ], [ 29.77862594936709, -1.39391724137931 ], [ 29.778895443037975, -1.39391724137931 ], [ 29.778895443037975, -1.39364775862069 ], [ 29.77862594936709, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 978, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.39364775862069 ], [ 29.778895443037975, -1.39391724137931 ], [ 29.77916493670886, -1.39391724137931 ], [ 29.77916493670886, -1.39364775862069 ], [ 29.778895443037975, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 979, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.39364775862069 ], [ 29.77916493670886, -1.39391724137931 ], [ 29.779434430379748, -1.39391724137931 ], [ 29.779434430379748, -1.39364775862069 ], [ 29.77916493670886, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 980, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.39364775862069 ], [ 29.779434430379748, -1.39391724137931 ], [ 29.779703924050633, -1.39391724137931 ], [ 29.779703924050633, -1.39364775862069 ], [ 29.779434430379748, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 981, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.39364775862069 ], [ 29.779703924050633, -1.39391724137931 ], [ 29.779973417721518, -1.39391724137931 ], [ 29.779973417721518, -1.39364775862069 ], [ 29.779703924050633, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 982, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.39364775862069 ], [ 29.779973417721518, -1.39391724137931 ], [ 29.780242911392406, -1.39391724137931 ], [ 29.780242911392406, -1.39364775862069 ], [ 29.779973417721518, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 983, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.39364775862069 ], [ 29.780242911392406, -1.39391724137931 ], [ 29.780512405063291, -1.39391724137931 ], [ 29.780512405063291, -1.39364775862069 ], [ 29.780242911392406, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 984, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.39364775862069 ], [ 29.780512405063291, -1.39391724137931 ], [ 29.780781898734176, -1.39391724137931 ], [ 29.780781898734176, -1.39364775862069 ], [ 29.780512405063291, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 985, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.39364775862069 ], [ 29.780781898734176, -1.39391724137931 ], [ 29.781051392405065, -1.39391724137931 ], [ 29.781051392405065, -1.39364775862069 ], [ 29.780781898734176, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 986, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.39364775862069 ], [ 29.781051392405065, -1.39391724137931 ], [ 29.78132088607595, -1.39391724137931 ], [ 29.78132088607595, -1.39364775862069 ], [ 29.781051392405065, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 987, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.39364775862069 ], [ 29.782398860759493, -1.39391724137931 ], [ 29.782668354430381, -1.39391724137931 ], [ 29.782668354430381, -1.39364775862069 ], [ 29.782398860759493, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 988, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.39364775862069 ], [ 29.782668354430381, -1.39391724137931 ], [ 29.782937848101266, -1.39391724137931 ], [ 29.782937848101266, -1.39364775862069 ], [ 29.782668354430381, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 989, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.39364775862069 ], [ 29.782937848101266, -1.39391724137931 ], [ 29.783207341772151, -1.39391724137931 ], [ 29.783207341772151, -1.39364775862069 ], [ 29.782937848101266, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 990, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.39364775862069 ], [ 29.783207341772151, -1.39391724137931 ], [ 29.783476835443039, -1.39391724137931 ], [ 29.783476835443039, -1.39364775862069 ], [ 29.783207341772151, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 991, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.39364775862069 ], [ 29.784285316455698, -1.394186724137931 ], [ 29.784554810126583, -1.394186724137931 ], [ 29.784554810126583, -1.39364775862069 ], [ 29.784285316455698, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 992, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.39364775862069 ], [ 29.785093797468356, -1.394186724137931 ], [ 29.785632784810126, -1.394186724137931 ], [ 29.785632784810126, -1.39391724137931 ], [ 29.785363291139241, -1.39391724137931 ], [ 29.785363291139241, -1.39364775862069 ], [ 29.785093797468356, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 993, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.39364775862069 ], [ 29.785363291139241, -1.39391724137931 ], [ 29.785632784810126, -1.39391724137931 ], [ 29.785632784810126, -1.39364775862069 ], [ 29.785363291139241, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 994, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.39364775862069 ], [ 29.785632784810126, -1.39391724137931 ], [ 29.785902278481014, -1.39391724137931 ], [ 29.785902278481014, -1.39364775862069 ], [ 29.785632784810126, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 995, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.39364775862069 ], [ 29.785902278481014, -1.39391724137931 ], [ 29.786171772151899, -1.39391724137931 ], [ 29.786171772151899, -1.39364775862069 ], [ 29.785902278481014, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 996, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.39364775862069 ], [ 29.786171772151899, -1.39391724137931 ], [ 29.786441265822784, -1.39391724137931 ], [ 29.786441265822784, -1.39364775862069 ], [ 29.786171772151899, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 997, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.39364775862069 ], [ 29.786441265822784, -1.39391724137931 ], [ 29.786980253164558, -1.39391724137931 ], [ 29.786980253164558, -1.39364775862069 ], [ 29.786441265822784, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 998, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.39364775862069 ], [ 29.786980253164558, -1.39391724137931 ], [ 29.787519240506327, -1.39391724137931 ], [ 29.787519240506327, -1.39364775862069 ], [ 29.786980253164558, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 999, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.39364775862069 ], [ 29.787519240506327, -1.39391724137931 ], [ 29.788058227848101, -1.39391724137931 ], [ 29.788058227848101, -1.39364775862069 ], [ 29.787519240506327, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1000, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.39364775862069 ], [ 29.788058227848101, -1.394186724137931 ], [ 29.788327721518986, -1.394186724137931 ], [ 29.788327721518986, -1.39364775862069 ], [ 29.788058227848101, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1001, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.39364775862069 ], [ 29.788327721518986, -1.39391724137931 ], [ 29.788597215189874, -1.39391724137931 ], [ 29.788597215189874, -1.39364775862069 ], [ 29.788327721518986, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1002, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.39364775862069 ], [ 29.789675189873417, -1.39391724137931 ], [ 29.789944683544302, -1.39391724137931 ], [ 29.789944683544302, -1.39364775862069 ], [ 29.789675189873417, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1003, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.39364775862069 ], [ 29.789944683544302, -1.39391724137931 ], [ 29.790214177215191, -1.39391724137931 ], [ 29.790214177215191, -1.39364775862069 ], [ 29.789944683544302, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1004, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.39364775862069 ], [ 29.790214177215191, -1.39391724137931 ], [ 29.790483670886076, -1.39391724137931 ], [ 29.790483670886076, -1.39364775862069 ], [ 29.790214177215191, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1005, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.39364775862069 ], [ 29.790483670886076, -1.39391724137931 ], [ 29.790753164556961, -1.39391724137931 ], [ 29.790753164556961, -1.39364775862069 ], [ 29.790483670886076, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1006, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.39364775862069 ], [ 29.790753164556961, -1.39391724137931 ], [ 29.791022658227849, -1.39391724137931 ], [ 29.791022658227849, -1.39364775862069 ], [ 29.790753164556961, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1007, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.39364775862069 ], [ 29.791022658227849, -1.39391724137931 ], [ 29.791292151898734, -1.39391724137931 ], [ 29.791292151898734, -1.39364775862069 ], [ 29.791022658227849, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1008, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.39364775862069 ], [ 29.791292151898734, -1.39391724137931 ], [ 29.791561645569619, -1.39391724137931 ], [ 29.791561645569619, -1.39364775862069 ], [ 29.791292151898734, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1009, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.39364775862069 ], [ 29.791561645569619, -1.39391724137931 ], [ 29.791831139240507, -1.39391724137931 ], [ 29.791831139240507, -1.39364775862069 ], [ 29.791561645569619, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1010, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.39364775862069 ], [ 29.791831139240507, -1.39391724137931 ], [ 29.792100632911392, -1.39391724137931 ], [ 29.792100632911392, -1.39364775862069 ], [ 29.791831139240507, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1011, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.39364775862069 ], [ 29.792100632911392, -1.39391724137931 ], [ 29.792370126582277, -1.39391724137931 ], [ 29.792370126582277, -1.39364775862069 ], [ 29.792100632911392, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1012, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.39364775862069 ], [ 29.792370126582277, -1.39391724137931 ], [ 29.792909113924051, -1.39391724137931 ], [ 29.792909113924051, -1.39364775862069 ], [ 29.792370126582277, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1013, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.39364775862069 ], [ 29.792909113924051, -1.39391724137931 ], [ 29.793178607594935, -1.39391724137931 ], [ 29.793178607594935, -1.39364775862069 ], [ 29.792909113924051, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1014, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.39364775862069 ], [ 29.793178607594935, -1.39391724137931 ], [ 29.793448101265824, -1.39391724137931 ], [ 29.793448101265824, -1.39364775862069 ], [ 29.793178607594935, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1015, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.39364775862069 ], [ 29.793448101265824, -1.39391724137931 ], [ 29.793987088607594, -1.39391724137931 ], [ 29.793987088607594, -1.39364775862069 ], [ 29.793448101265824, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1016, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.39364775862069 ], [ 29.796143037974684, -1.39391724137931 ], [ 29.796412531645569, -1.39391724137931 ], [ 29.796412531645569, -1.39364775862069 ], [ 29.796143037974684, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1017, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.39364775862069 ], [ 29.796412531645569, -1.394186724137931 ], [ 29.796682025316457, -1.394186724137931 ], [ 29.796682025316457, -1.39364775862069 ], [ 29.796412531645569, -1.39364775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1018, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.39391724137931 ], [ 29.769193670886075, -1.394186724137931 ], [ 29.76946316455696, -1.394186724137931 ], [ 29.76946316455696, -1.39391724137931 ], [ 29.769193670886075, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1019, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.39391724137931 ], [ 29.76946316455696, -1.394186724137931 ], [ 29.769732658227849, -1.394186724137931 ], [ 29.769732658227849, -1.39391724137931 ], [ 29.76946316455696, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1020, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.39391724137931 ], [ 29.769732658227849, -1.394186724137931 ], [ 29.770002151898733, -1.394186724137931 ], [ 29.770002151898733, -1.39391724137931 ], [ 29.769732658227849, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1021, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.39391724137931 ], [ 29.770002151898733, -1.394186724137931 ], [ 29.770271645569618, -1.394186724137931 ], [ 29.770271645569618, -1.39391724137931 ], [ 29.770002151898733, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1022, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.39391724137931 ], [ 29.770271645569618, -1.394186724137931 ], [ 29.770541139240507, -1.394186724137931 ], [ 29.770541139240507, -1.39391724137931 ], [ 29.770271645569618, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1023, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.39391724137931 ], [ 29.770541139240507, -1.394186724137931 ], [ 29.770810632911392, -1.394186724137931 ], [ 29.770810632911392, -1.39391724137931 ], [ 29.770541139240507, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1024, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.39391724137931 ], [ 29.770810632911392, -1.394186724137931 ], [ 29.771080126582277, -1.394186724137931 ], [ 29.771080126582277, -1.39391724137931 ], [ 29.770810632911392, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1025, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.39391724137931 ], [ 29.771080126582277, -1.394186724137931 ], [ 29.771349620253165, -1.394186724137931 ], [ 29.771349620253165, -1.39391724137931 ], [ 29.771080126582277, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1026, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.39391724137931 ], [ 29.771349620253165, -1.394186724137931 ], [ 29.77161911392405, -1.394186724137931 ], [ 29.77161911392405, -1.39391724137931 ], [ 29.771349620253165, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1027, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.39391724137931 ], [ 29.77161911392405, -1.394186724137931 ], [ 29.771888607594935, -1.394186724137931 ], [ 29.771888607594935, -1.39391724137931 ], [ 29.77161911392405, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1028, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.39391724137931 ], [ 29.771888607594935, -1.394186724137931 ], [ 29.772158101265823, -1.394186724137931 ], [ 29.772158101265823, -1.39391724137931 ], [ 29.771888607594935, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1029, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.39391724137931 ], [ 29.772158101265823, -1.394186724137931 ], [ 29.772427594936708, -1.394186724137931 ], [ 29.772427594936708, -1.39391724137931 ], [ 29.772158101265823, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1030, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.39391724137931 ], [ 29.772427594936708, -1.394186724137931 ], [ 29.772697088607593, -1.394186724137931 ], [ 29.772697088607593, -1.39391724137931 ], [ 29.772427594936708, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1031, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.39391724137931 ], [ 29.772697088607593, -1.394186724137931 ], [ 29.772966582278482, -1.394186724137931 ], [ 29.772966582278482, -1.39391724137931 ], [ 29.772697088607593, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1032, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.39391724137931 ], [ 29.772966582278482, -1.394186724137931 ], [ 29.773236075949367, -1.394186724137931 ], [ 29.773236075949367, -1.39391724137931 ], [ 29.772966582278482, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1033, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.39391724137931 ], [ 29.773236075949367, -1.394186724137931 ], [ 29.773505569620252, -1.394186724137931 ], [ 29.773505569620252, -1.39391724137931 ], [ 29.773236075949367, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1034, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.39391724137931 ], [ 29.773505569620252, -1.394186724137931 ], [ 29.77377506329114, -1.394186724137931 ], [ 29.77377506329114, -1.39391724137931 ], [ 29.773505569620252, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1035, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.39391724137931 ], [ 29.77377506329114, -1.394186724137931 ], [ 29.774044556962025, -1.394186724137931 ], [ 29.774044556962025, -1.39391724137931 ], [ 29.77377506329114, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1036, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.39391724137931 ], [ 29.774044556962025, -1.394186724137931 ], [ 29.77431405063291, -1.394186724137931 ], [ 29.77431405063291, -1.39391724137931 ], [ 29.774044556962025, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1037, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.39391724137931 ], [ 29.77431405063291, -1.394186724137931 ], [ 29.774853037974683, -1.394186724137931 ], [ 29.774853037974683, -1.39391724137931 ], [ 29.77431405063291, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1038, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.39391724137931 ], [ 29.774853037974683, -1.394186724137931 ], [ 29.775122531645568, -1.394186724137931 ], [ 29.775122531645568, -1.39391724137931 ], [ 29.774853037974683, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1039, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.39391724137931 ], [ 29.775122531645568, -1.394186724137931 ], [ 29.775392025316457, -1.394186724137931 ], [ 29.775392025316457, -1.39391724137931 ], [ 29.775122531645568, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1040, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.39391724137931 ], [ 29.775392025316457, -1.394186724137931 ], [ 29.775661518987341, -1.394186724137931 ], [ 29.775661518987341, -1.39391724137931 ], [ 29.775392025316457, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1041, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.39391724137931 ], [ 29.775661518987341, -1.394186724137931 ], [ 29.775931012658226, -1.394186724137931 ], [ 29.775931012658226, -1.39391724137931 ], [ 29.775661518987341, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1042, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.39391724137931 ], [ 29.775931012658226, -1.394186724137931 ], [ 29.776200506329115, -1.394186724137931 ], [ 29.776200506329115, -1.39391724137931 ], [ 29.775931012658226, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1043, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.39391724137931 ], [ 29.776200506329115, -1.394186724137931 ], [ 29.77647, -1.394186724137931 ], [ 29.77647, -1.39391724137931 ], [ 29.776200506329115, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1044, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.39391724137931 ], [ 29.77647, -1.394186724137931 ], [ 29.776739493670885, -1.394186724137931 ], [ 29.776739493670885, -1.39391724137931 ], [ 29.77647, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1045, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.39391724137931 ], [ 29.776739493670885, -1.394186724137931 ], [ 29.777008987341773, -1.394186724137931 ], [ 29.777008987341773, -1.39391724137931 ], [ 29.776739493670885, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1046, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.39391724137931 ], [ 29.777008987341773, -1.394186724137931 ], [ 29.777547974683543, -1.394186724137931 ], [ 29.777547974683543, -1.39391724137931 ], [ 29.777008987341773, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1047, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.39391724137931 ], [ 29.777547974683543, -1.394186724137931 ], [ 29.777817468354431, -1.394186724137931 ], [ 29.777817468354431, -1.39391724137931 ], [ 29.777547974683543, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1048, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.39391724137931 ], [ 29.777817468354431, -1.394186724137931 ], [ 29.778086962025316, -1.394186724137931 ], [ 29.778086962025316, -1.39391724137931 ], [ 29.777817468354431, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1049, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.39391724137931 ], [ 29.778086962025316, -1.394186724137931 ], [ 29.778356455696201, -1.394186724137931 ], [ 29.778356455696201, -1.39391724137931 ], [ 29.778086962025316, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1050, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.39391724137931 ], [ 29.778356455696201, -1.394186724137931 ], [ 29.77862594936709, -1.394186724137931 ], [ 29.77862594936709, -1.39391724137931 ], [ 29.778356455696201, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1051, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.39391724137931 ], [ 29.77862594936709, -1.394186724137931 ], [ 29.778895443037975, -1.394186724137931 ], [ 29.778895443037975, -1.39391724137931 ], [ 29.77862594936709, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1052, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.39391724137931 ], [ 29.778895443037975, -1.394186724137931 ], [ 29.77916493670886, -1.394186724137931 ], [ 29.77916493670886, -1.39391724137931 ], [ 29.778895443037975, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1053, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.39391724137931 ], [ 29.77916493670886, -1.394186724137931 ], [ 29.779434430379748, -1.394186724137931 ], [ 29.779434430379748, -1.39391724137931 ], [ 29.77916493670886, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1054, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.39391724137931 ], [ 29.779434430379748, -1.394186724137931 ], [ 29.779703924050633, -1.394186724137931 ], [ 29.779703924050633, -1.39391724137931 ], [ 29.779434430379748, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1055, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.39391724137931 ], [ 29.779703924050633, -1.394186724137931 ], [ 29.779973417721518, -1.394186724137931 ], [ 29.779973417721518, -1.39391724137931 ], [ 29.779703924050633, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1056, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.39391724137931 ], [ 29.779973417721518, -1.394186724137931 ], [ 29.780242911392406, -1.394186724137931 ], [ 29.780242911392406, -1.39391724137931 ], [ 29.779973417721518, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1057, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.39391724137931 ], [ 29.780242911392406, -1.394186724137931 ], [ 29.780512405063291, -1.394186724137931 ], [ 29.780512405063291, -1.39391724137931 ], [ 29.780242911392406, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1058, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.39391724137931 ], [ 29.780512405063291, -1.394186724137931 ], [ 29.780781898734176, -1.394186724137931 ], [ 29.780781898734176, -1.39391724137931 ], [ 29.780512405063291, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1059, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.39391724137931 ], [ 29.780781898734176, -1.394186724137931 ], [ 29.781051392405065, -1.394186724137931 ], [ 29.781051392405065, -1.39391724137931 ], [ 29.780781898734176, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1060, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.39391724137931 ], [ 29.781051392405065, -1.394186724137931 ], [ 29.78132088607595, -1.394186724137931 ], [ 29.78132088607595, -1.39391724137931 ], [ 29.781051392405065, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1061, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.39391724137931 ], [ 29.78132088607595, -1.394186724137931 ], [ 29.781590379746834, -1.394186724137931 ], [ 29.781590379746834, -1.39391724137931 ], [ 29.78132088607595, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1062, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.39391724137931 ], [ 29.781859873417723, -1.394186724137931 ], [ 29.782398860759493, -1.394186724137931 ], [ 29.782398860759493, -1.39391724137931 ], [ 29.781859873417723, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1063, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.39391724137931 ], [ 29.782398860759493, -1.394186724137931 ], [ 29.782668354430381, -1.394186724137931 ], [ 29.782668354430381, -1.39391724137931 ], [ 29.782398860759493, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1064, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.39391724137931 ], [ 29.782668354430381, -1.394186724137931 ], [ 29.782937848101266, -1.394186724137931 ], [ 29.782937848101266, -1.39391724137931 ], [ 29.782668354430381, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1065, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.39391724137931 ], [ 29.782937848101266, -1.394186724137931 ], [ 29.783207341772151, -1.394186724137931 ], [ 29.783207341772151, -1.39391724137931 ], [ 29.782937848101266, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1066, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.39391724137931 ], [ 29.783207341772151, -1.394186724137931 ], [ 29.783476835443039, -1.394186724137931 ], [ 29.783476835443039, -1.39391724137931 ], [ 29.783207341772151, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1067, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.39391724137931 ], [ 29.783476835443039, -1.394186724137931 ], [ 29.784015822784809, -1.394186724137931 ], [ 29.784015822784809, -1.39391724137931 ], [ 29.783476835443039, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1068, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.39391724137931 ], [ 29.784015822784809, -1.394186724137931 ], [ 29.784285316455698, -1.394186724137931 ], [ 29.784285316455698, -1.39391724137931 ], [ 29.784015822784809, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1069, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.39391724137931 ], [ 29.784554810126583, -1.394186724137931 ], [ 29.784824303797468, -1.394186724137931 ], [ 29.784824303797468, -1.39391724137931 ], [ 29.784554810126583, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1070, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.39391724137931 ], [ 29.784824303797468, -1.394186724137931 ], [ 29.785093797468356, -1.394186724137931 ], [ 29.785093797468356, -1.39391724137931 ], [ 29.784824303797468, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1071, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.39391724137931 ], [ 29.785632784810126, -1.394186724137931 ], [ 29.785902278481014, -1.394186724137931 ], [ 29.785902278481014, -1.39391724137931 ], [ 29.785632784810126, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1072, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.39391724137931 ], [ 29.785902278481014, -1.394186724137931 ], [ 29.786171772151899, -1.394186724137931 ], [ 29.786171772151899, -1.39391724137931 ], [ 29.785902278481014, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1073, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.39391724137931 ], [ 29.786171772151899, -1.394186724137931 ], [ 29.786441265822784, -1.394186724137931 ], [ 29.786441265822784, -1.39391724137931 ], [ 29.786171772151899, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1074, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.39391724137931 ], [ 29.786441265822784, -1.394186724137931 ], [ 29.786710759493673, -1.394186724137931 ], [ 29.786710759493673, -1.39391724137931 ], [ 29.786441265822784, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1075, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.39391724137931 ], [ 29.786710759493673, -1.394186724137931 ], [ 29.787788734177216, -1.394186724137931 ], [ 29.787788734177216, -1.39391724137931 ], [ 29.786710759493673, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1076, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.39391724137931 ], [ 29.787788734177216, -1.394186724137931 ], [ 29.788058227848101, -1.394186724137931 ], [ 29.788058227848101, -1.39391724137931 ], [ 29.787788734177216, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1077, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.39391724137931 ], [ 29.788327721518986, -1.394186724137931 ], [ 29.788866708860759, -1.394186724137931 ], [ 29.788866708860759, -1.39391724137931 ], [ 29.788327721518986, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1078, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.39391724137931 ], [ 29.788866708860759, -1.394186724137931 ], [ 29.789405696202532, -1.394186724137931 ], [ 29.789405696202532, -1.39391724137931 ], [ 29.788866708860759, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1079, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.39391724137931 ], [ 29.789405696202532, -1.394186724137931 ], [ 29.789944683544302, -1.394186724137931 ], [ 29.789944683544302, -1.39391724137931 ], [ 29.789405696202532, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1080, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.39391724137931 ], [ 29.789944683544302, -1.394186724137931 ], [ 29.790214177215191, -1.394186724137931 ], [ 29.790214177215191, -1.39391724137931 ], [ 29.789944683544302, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1081, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.39391724137931 ], [ 29.790214177215191, -1.394186724137931 ], [ 29.790483670886076, -1.394186724137931 ], [ 29.790483670886076, -1.39391724137931 ], [ 29.790214177215191, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1082, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.39391724137931 ], [ 29.790483670886076, -1.394186724137931 ], [ 29.790753164556961, -1.394186724137931 ], [ 29.790753164556961, -1.39391724137931 ], [ 29.790483670886076, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1083, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.39391724137931 ], [ 29.791022658227849, -1.394186724137931 ], [ 29.791292151898734, -1.394186724137931 ], [ 29.791292151898734, -1.39391724137931 ], [ 29.791022658227849, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1084, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.39391724137931 ], [ 29.791292151898734, -1.394186724137931 ], [ 29.791561645569619, -1.394186724137931 ], [ 29.791561645569619, -1.39391724137931 ], [ 29.791292151898734, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1085, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.39391724137931 ], [ 29.791561645569619, -1.394186724137931 ], [ 29.791831139240507, -1.394186724137931 ], [ 29.791831139240507, -1.39391724137931 ], [ 29.791561645569619, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1086, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.39391724137931 ], [ 29.791831139240507, -1.394186724137931 ], [ 29.792100632911392, -1.394186724137931 ], [ 29.792100632911392, -1.39391724137931 ], [ 29.791831139240507, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1087, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.39391724137931 ], [ 29.792100632911392, -1.394186724137931 ], [ 29.792370126582277, -1.394186724137931 ], [ 29.792370126582277, -1.39391724137931 ], [ 29.792100632911392, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1088, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.39391724137931 ], [ 29.792370126582277, -1.394186724137931 ], [ 29.792909113924051, -1.394186724137931 ], [ 29.792909113924051, -1.39391724137931 ], [ 29.792370126582277, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1089, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.39391724137931 ], [ 29.792909113924051, -1.394186724137931 ], [ 29.793178607594935, -1.394186724137931 ], [ 29.793178607594935, -1.39391724137931 ], [ 29.792909113924051, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1090, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.39391724137931 ], [ 29.793178607594935, -1.394186724137931 ], [ 29.793448101265824, -1.394186724137931 ], [ 29.793448101265824, -1.39391724137931 ], [ 29.793178607594935, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1091, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.39391724137931 ], [ 29.793448101265824, -1.394186724137931 ], [ 29.793717594936709, -1.394186724137931 ], [ 29.793717594936709, -1.39391724137931 ], [ 29.793448101265824, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1092, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.39391724137931 ], [ 29.793717594936709, -1.394186724137931 ], [ 29.793987088607594, -1.394186724137931 ], [ 29.793987088607594, -1.39391724137931 ], [ 29.793717594936709, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1093, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.39391724137931 ], [ 29.793987088607594, -1.394186724137931 ], [ 29.794526075949367, -1.394186724137931 ], [ 29.794526075949367, -1.39391724137931 ], [ 29.793987088607594, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1094, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.39391724137931 ], [ 29.79560405063291, -1.394186724137931 ], [ 29.795873544303799, -1.394186724137931 ], [ 29.795873544303799, -1.39391724137931 ], [ 29.79560405063291, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1095, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.39391724137931 ], [ 29.795873544303799, -1.394186724137931 ], [ 29.796143037974684, -1.394186724137931 ], [ 29.796143037974684, -1.39391724137931 ], [ 29.795873544303799, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1096, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.39391724137931 ], [ 29.796143037974684, -1.394186724137931 ], [ 29.796412531645569, -1.394186724137931 ], [ 29.796412531645569, -1.39391724137931 ], [ 29.796143037974684, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1097, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.39391724137931 ], [ 29.76892417721519, -1.394456206896552 ], [ 29.769193670886075, -1.394456206896552 ], [ 29.769193670886075, -1.39391724137931 ], [ 29.76892417721519, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1098, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.39391724137931 ], [ 29.790753164556961, -1.394456206896552 ], [ 29.791292151898734, -1.394456206896552 ], [ 29.791292151898734, -1.394186724137931 ], [ 29.791022658227849, -1.394186724137931 ], [ 29.791022658227849, -1.39391724137931 ], [ 29.790753164556961, -1.39391724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1099, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.394186724137931 ], [ 29.769193670886075, -1.394456206896552 ], [ 29.76946316455696, -1.394456206896552 ], [ 29.76946316455696, -1.394186724137931 ], [ 29.769193670886075, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1100, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.394186724137931 ], [ 29.76946316455696, -1.394456206896552 ], [ 29.769732658227849, -1.394456206896552 ], [ 29.769732658227849, -1.394186724137931 ], [ 29.76946316455696, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1101, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.394186724137931 ], [ 29.769732658227849, -1.394456206896552 ], [ 29.770002151898733, -1.394456206896552 ], [ 29.770002151898733, -1.394186724137931 ], [ 29.769732658227849, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1102, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.394186724137931 ], [ 29.770002151898733, -1.394456206896552 ], [ 29.770271645569618, -1.394456206896552 ], [ 29.770271645569618, -1.394186724137931 ], [ 29.770002151898733, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1103, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.394186724137931 ], [ 29.770271645569618, -1.394456206896552 ], [ 29.770541139240507, -1.394456206896552 ], [ 29.770541139240507, -1.394186724137931 ], [ 29.770271645569618, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1104, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.394186724137931 ], [ 29.770541139240507, -1.394456206896552 ], [ 29.770810632911392, -1.394456206896552 ], [ 29.770810632911392, -1.394186724137931 ], [ 29.770541139240507, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1105, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.394186724137931 ], [ 29.770810632911392, -1.394456206896552 ], [ 29.771080126582277, -1.394456206896552 ], [ 29.771080126582277, -1.394186724137931 ], [ 29.770810632911392, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1106, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.394186724137931 ], [ 29.771080126582277, -1.394456206896552 ], [ 29.771349620253165, -1.394456206896552 ], [ 29.771349620253165, -1.394186724137931 ], [ 29.771080126582277, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1107, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.394186724137931 ], [ 29.771349620253165, -1.394456206896552 ], [ 29.77161911392405, -1.394456206896552 ], [ 29.77161911392405, -1.394186724137931 ], [ 29.771349620253165, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1108, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.394186724137931 ], [ 29.77161911392405, -1.394456206896552 ], [ 29.771888607594935, -1.394456206896552 ], [ 29.771888607594935, -1.394186724137931 ], [ 29.77161911392405, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1109, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.394186724137931 ], [ 29.771888607594935, -1.394456206896552 ], [ 29.772158101265823, -1.394456206896552 ], [ 29.772158101265823, -1.394186724137931 ], [ 29.771888607594935, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1110, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.394186724137931 ], [ 29.772158101265823, -1.394456206896552 ], [ 29.772427594936708, -1.394456206896552 ], [ 29.772427594936708, -1.394186724137931 ], [ 29.772158101265823, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1111, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.394186724137931 ], [ 29.772427594936708, -1.394456206896552 ], [ 29.772697088607593, -1.394456206896552 ], [ 29.772697088607593, -1.394186724137931 ], [ 29.772427594936708, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1112, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.394186724137931 ], [ 29.772697088607593, -1.394456206896552 ], [ 29.773236075949367, -1.394456206896552 ], [ 29.773236075949367, -1.394186724137931 ], [ 29.772697088607593, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1113, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.394186724137931 ], [ 29.773236075949367, -1.394456206896552 ], [ 29.773505569620252, -1.394456206896552 ], [ 29.773505569620252, -1.394186724137931 ], [ 29.773236075949367, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1114, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.394186724137931 ], [ 29.773505569620252, -1.394456206896552 ], [ 29.77377506329114, -1.394456206896552 ], [ 29.77377506329114, -1.394186724137931 ], [ 29.773505569620252, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1115, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.394186724137931 ], [ 29.77377506329114, -1.394456206896552 ], [ 29.774044556962025, -1.394456206896552 ], [ 29.774044556962025, -1.394186724137931 ], [ 29.77377506329114, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1116, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.394186724137931 ], [ 29.774044556962025, -1.394456206896552 ], [ 29.77431405063291, -1.394456206896552 ], [ 29.77431405063291, -1.394186724137931 ], [ 29.774044556962025, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1117, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.394186724137931 ], [ 29.77431405063291, -1.394456206896552 ], [ 29.774853037974683, -1.394456206896552 ], [ 29.774853037974683, -1.394186724137931 ], [ 29.77431405063291, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1118, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.394186724137931 ], [ 29.774853037974683, -1.394456206896552 ], [ 29.775122531645568, -1.394456206896552 ], [ 29.775122531645568, -1.394186724137931 ], [ 29.774853037974683, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1119, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.394186724137931 ], [ 29.775122531645568, -1.394456206896552 ], [ 29.775392025316457, -1.394456206896552 ], [ 29.775392025316457, -1.394186724137931 ], [ 29.775122531645568, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1120, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.394186724137931 ], [ 29.775392025316457, -1.394456206896552 ], [ 29.775661518987341, -1.394456206896552 ], [ 29.775661518987341, -1.394186724137931 ], [ 29.775392025316457, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1121, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.394186724137931 ], [ 29.775661518987341, -1.394456206896552 ], [ 29.775931012658226, -1.394456206896552 ], [ 29.775931012658226, -1.394186724137931 ], [ 29.775661518987341, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1122, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.394186724137931 ], [ 29.775931012658226, -1.394456206896552 ], [ 29.776200506329115, -1.394456206896552 ], [ 29.776200506329115, -1.394186724137931 ], [ 29.775931012658226, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1123, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.394186724137931 ], [ 29.776200506329115, -1.394456206896552 ], [ 29.77647, -1.394456206896552 ], [ 29.77647, -1.394186724137931 ], [ 29.776200506329115, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1124, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.394186724137931 ], [ 29.77647, -1.394456206896552 ], [ 29.776739493670885, -1.394456206896552 ], [ 29.776739493670885, -1.394186724137931 ], [ 29.77647, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1125, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.394186724137931 ], [ 29.776739493670885, -1.394456206896552 ], [ 29.777817468354431, -1.394456206896552 ], [ 29.777817468354431, -1.394186724137931 ], [ 29.776739493670885, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1126, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.394186724137931 ], [ 29.777817468354431, -1.394456206896552 ], [ 29.778086962025316, -1.394456206896552 ], [ 29.778086962025316, -1.394186724137931 ], [ 29.777817468354431, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1127, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.394186724137931 ], [ 29.778086962025316, -1.394456206896552 ], [ 29.778356455696201, -1.394456206896552 ], [ 29.778356455696201, -1.394186724137931 ], [ 29.778086962025316, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1128, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.394186724137931 ], [ 29.778356455696201, -1.394456206896552 ], [ 29.77862594936709, -1.394456206896552 ], [ 29.77862594936709, -1.394186724137931 ], [ 29.778356455696201, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1129, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.394186724137931 ], [ 29.77862594936709, -1.394456206896552 ], [ 29.778895443037975, -1.394456206896552 ], [ 29.778895443037975, -1.394186724137931 ], [ 29.77862594936709, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1130, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.394186724137931 ], [ 29.778895443037975, -1.394456206896552 ], [ 29.77916493670886, -1.394456206896552 ], [ 29.77916493670886, -1.394186724137931 ], [ 29.778895443037975, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1131, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.394186724137931 ], [ 29.77916493670886, -1.394456206896552 ], [ 29.779434430379748, -1.394456206896552 ], [ 29.779434430379748, -1.394186724137931 ], [ 29.77916493670886, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1132, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.394186724137931 ], [ 29.779434430379748, -1.394456206896552 ], [ 29.779703924050633, -1.394456206896552 ], [ 29.779703924050633, -1.394186724137931 ], [ 29.779434430379748, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1133, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.394186724137931 ], [ 29.779703924050633, -1.394456206896552 ], [ 29.779973417721518, -1.394456206896552 ], [ 29.779973417721518, -1.394186724137931 ], [ 29.779703924050633, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1134, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.394186724137931 ], [ 29.779973417721518, -1.394456206896552 ], [ 29.780242911392406, -1.394456206896552 ], [ 29.780242911392406, -1.394186724137931 ], [ 29.779973417721518, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1135, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.394186724137931 ], [ 29.780242911392406, -1.394456206896552 ], [ 29.780512405063291, -1.394456206896552 ], [ 29.780512405063291, -1.394186724137931 ], [ 29.780242911392406, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1136, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.394186724137931 ], [ 29.780512405063291, -1.394456206896552 ], [ 29.780781898734176, -1.394456206896552 ], [ 29.780781898734176, -1.394186724137931 ], [ 29.780512405063291, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1137, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.394186724137931 ], [ 29.780781898734176, -1.394456206896552 ], [ 29.781051392405065, -1.394456206896552 ], [ 29.781051392405065, -1.394186724137931 ], [ 29.780781898734176, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1138, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.394186724137931 ], [ 29.781051392405065, -1.394456206896552 ], [ 29.78132088607595, -1.394456206896552 ], [ 29.78132088607595, -1.394186724137931 ], [ 29.781051392405065, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1139, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.394186724137931 ], [ 29.78132088607595, -1.394456206896552 ], [ 29.781590379746834, -1.394456206896552 ], [ 29.781590379746834, -1.394186724137931 ], [ 29.78132088607595, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1140, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.394186724137931 ], [ 29.781590379746834, -1.394456206896552 ], [ 29.781859873417723, -1.394456206896552 ], [ 29.781859873417723, -1.394186724137931 ], [ 29.781590379746834, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1141, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.394186724137931 ], [ 29.781859873417723, -1.394456206896552 ], [ 29.782129367088608, -1.394456206896552 ], [ 29.782129367088608, -1.394186724137931 ], [ 29.781859873417723, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1142, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.394186724137931 ], [ 29.782129367088608, -1.394456206896552 ], [ 29.782398860759493, -1.394456206896552 ], [ 29.782398860759493, -1.394186724137931 ], [ 29.782129367088608, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1143, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.394186724137931 ], [ 29.782398860759493, -1.394456206896552 ], [ 29.782668354430381, -1.394456206896552 ], [ 29.782668354430381, -1.394186724137931 ], [ 29.782398860759493, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1144, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.394186724137931 ], [ 29.782668354430381, -1.394456206896552 ], [ 29.782937848101266, -1.394456206896552 ], [ 29.782937848101266, -1.394186724137931 ], [ 29.782668354430381, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1145, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.394186724137931 ], [ 29.782937848101266, -1.394456206896552 ], [ 29.783207341772151, -1.394456206896552 ], [ 29.783207341772151, -1.394186724137931 ], [ 29.782937848101266, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1146, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.394186724137931 ], [ 29.783207341772151, -1.394456206896552 ], [ 29.783476835443039, -1.394456206896552 ], [ 29.783476835443039, -1.394186724137931 ], [ 29.783207341772151, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1147, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.394186724137931 ], [ 29.783476835443039, -1.394456206896552 ], [ 29.784015822784809, -1.394456206896552 ], [ 29.784015822784809, -1.394186724137931 ], [ 29.783476835443039, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1148, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.394186724137931 ], [ 29.784015822784809, -1.394456206896552 ], [ 29.784285316455698, -1.394456206896552 ], [ 29.784285316455698, -1.394186724137931 ], [ 29.784015822784809, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1149, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.394186724137931 ], [ 29.784285316455698, -1.394456206896552 ], [ 29.784554810126583, -1.394456206896552 ], [ 29.784554810126583, -1.394186724137931 ], [ 29.784285316455698, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1150, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.394186724137931 ], [ 29.784554810126583, -1.394456206896552 ], [ 29.784824303797468, -1.394456206896552 ], [ 29.784824303797468, -1.394186724137931 ], [ 29.784554810126583, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1151, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.394186724137931 ], [ 29.784824303797468, -1.394456206896552 ], [ 29.785093797468356, -1.394456206896552 ], [ 29.785093797468356, -1.394186724137931 ], [ 29.784824303797468, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1152, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.394186724137931 ], [ 29.785093797468356, -1.394456206896552 ], [ 29.785363291139241, -1.394456206896552 ], [ 29.785363291139241, -1.394186724137931 ], [ 29.785093797468356, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1153, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.394186724137931 ], [ 29.785363291139241, -1.394456206896552 ], [ 29.785632784810126, -1.394456206896552 ], [ 29.785632784810126, -1.394186724137931 ], [ 29.785363291139241, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1154, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.394186724137931 ], [ 29.785632784810126, -1.394456206896552 ], [ 29.785902278481014, -1.394456206896552 ], [ 29.785902278481014, -1.394186724137931 ], [ 29.785632784810126, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1155, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.394186724137931 ], [ 29.785902278481014, -1.394456206896552 ], [ 29.786171772151899, -1.394456206896552 ], [ 29.786171772151899, -1.394186724137931 ], [ 29.785902278481014, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1156, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.394186724137931 ], [ 29.786171772151899, -1.394456206896552 ], [ 29.786441265822784, -1.394456206896552 ], [ 29.786441265822784, -1.394186724137931 ], [ 29.786171772151899, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1157, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.394186724137931 ], [ 29.786441265822784, -1.394456206896552 ], [ 29.786710759493673, -1.394456206896552 ], [ 29.786710759493673, -1.394186724137931 ], [ 29.786441265822784, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1158, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.394186724137931 ], [ 29.786710759493673, -1.394725689655173 ], [ 29.786980253164558, -1.394725689655173 ], [ 29.786980253164558, -1.394456206896552 ], [ 29.787249746835442, -1.394456206896552 ], [ 29.787249746835442, -1.394186724137931 ], [ 29.786710759493673, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1159, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.394186724137931 ], [ 29.787249746835442, -1.394456206896552 ], [ 29.787519240506327, -1.394456206896552 ], [ 29.787519240506327, -1.394186724137931 ], [ 29.787249746835442, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1160, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.394186724137931 ], [ 29.787519240506327, -1.394456206896552 ], [ 29.787788734177216, -1.394456206896552 ], [ 29.787788734177216, -1.394186724137931 ], [ 29.787519240506327, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1161, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.394186724137931 ], [ 29.787788734177216, -1.394456206896552 ], [ 29.788058227848101, -1.394456206896552 ], [ 29.788058227848101, -1.394186724137931 ], [ 29.787788734177216, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1162, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.394186724137931 ], [ 29.788058227848101, -1.394456206896552 ], [ 29.788327721518986, -1.394456206896552 ], [ 29.788327721518986, -1.394186724137931 ], [ 29.788058227848101, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1163, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.394186724137931 ], [ 29.788327721518986, -1.394456206896552 ], [ 29.788597215189874, -1.394456206896552 ], [ 29.788597215189874, -1.394186724137931 ], [ 29.788327721518986, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1164, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.394186724137931 ], [ 29.788597215189874, -1.394456206896552 ], [ 29.789675189873417, -1.394456206896552 ], [ 29.789675189873417, -1.394186724137931 ], [ 29.788597215189874, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1165, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.394186724137931 ], [ 29.789675189873417, -1.394456206896552 ], [ 29.789944683544302, -1.394456206896552 ], [ 29.789944683544302, -1.394186724137931 ], [ 29.789675189873417, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1166, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.394186724137931 ], [ 29.789944683544302, -1.394456206896552 ], [ 29.790214177215191, -1.394456206896552 ], [ 29.790214177215191, -1.394186724137931 ], [ 29.789944683544302, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1167, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.394186724137931 ], [ 29.790214177215191, -1.394456206896552 ], [ 29.790483670886076, -1.394456206896552 ], [ 29.790483670886076, -1.394186724137931 ], [ 29.790214177215191, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1168, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.394186724137931 ], [ 29.790483670886076, -1.394456206896552 ], [ 29.790753164556961, -1.394456206896552 ], [ 29.790753164556961, -1.394186724137931 ], [ 29.790483670886076, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1169, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.394186724137931 ], [ 29.791292151898734, -1.394456206896552 ], [ 29.791561645569619, -1.394456206896552 ], [ 29.791561645569619, -1.394186724137931 ], [ 29.791292151898734, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1170, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.394186724137931 ], [ 29.791561645569619, -1.394456206896552 ], [ 29.791831139240507, -1.394456206896552 ], [ 29.791831139240507, -1.394186724137931 ], [ 29.791561645569619, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1171, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.394186724137931 ], [ 29.791831139240507, -1.394456206896552 ], [ 29.792100632911392, -1.394456206896552 ], [ 29.792100632911392, -1.394186724137931 ], [ 29.791831139240507, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1172, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.394186724137931 ], [ 29.792100632911392, -1.394456206896552 ], [ 29.792370126582277, -1.394456206896552 ], [ 29.792370126582277, -1.394186724137931 ], [ 29.792100632911392, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1173, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.394186724137931 ], [ 29.792370126582277, -1.394456206896552 ], [ 29.792909113924051, -1.394456206896552 ], [ 29.792909113924051, -1.394186724137931 ], [ 29.792370126582277, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1174, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.394186724137931 ], [ 29.792909113924051, -1.394456206896552 ], [ 29.793178607594935, -1.394456206896552 ], [ 29.793178607594935, -1.394186724137931 ], [ 29.792909113924051, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1175, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.394186724137931 ], [ 29.793178607594935, -1.394456206896552 ], [ 29.793448101265824, -1.394456206896552 ], [ 29.793448101265824, -1.394186724137931 ], [ 29.793178607594935, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1176, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.394186724137931 ], [ 29.793448101265824, -1.394456206896552 ], [ 29.793717594936709, -1.394456206896552 ], [ 29.793717594936709, -1.394186724137931 ], [ 29.793448101265824, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1177, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.394186724137931 ], [ 29.793717594936709, -1.394456206896552 ], [ 29.793987088607594, -1.394456206896552 ], [ 29.793987088607594, -1.394186724137931 ], [ 29.793717594936709, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1178, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.394186724137931 ], [ 29.793987088607594, -1.394456206896552 ], [ 29.794256582278482, -1.394456206896552 ], [ 29.794256582278482, -1.394186724137931 ], [ 29.793987088607594, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1179, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.394186724137931 ], [ 29.794256582278482, -1.394456206896552 ], [ 29.794526075949367, -1.394456206896552 ], [ 29.794526075949367, -1.394186724137931 ], [ 29.794256582278482, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1180, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.394186724137931 ], [ 29.794526075949367, -1.394456206896552 ], [ 29.79506506329114, -1.394456206896552 ], [ 29.79506506329114, -1.394186724137931 ], [ 29.794526075949367, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1181, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.394186724137931 ], [ 29.795334556962025, -1.394456206896552 ], [ 29.79560405063291, -1.394456206896552 ], [ 29.79560405063291, -1.394186724137931 ], [ 29.795334556962025, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1182, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.394186724137931 ], [ 29.79560405063291, -1.394456206896552 ], [ 29.795873544303799, -1.394456206896552 ], [ 29.795873544303799, -1.394186724137931 ], [ 29.79560405063291, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1183, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.394186724137931 ], [ 29.795873544303799, -1.394456206896552 ], [ 29.796412531645569, -1.394456206896552 ], [ 29.796412531645569, -1.394186724137931 ], [ 29.795873544303799, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1184, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.394186724137931 ], [ 29.796412531645569, -1.394456206896552 ], [ 29.796682025316457, -1.394456206896552 ], [ 29.796682025316457, -1.394186724137931 ], [ 29.796412531645569, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1185, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.394186724137931 ], [ 29.796682025316457, -1.394456206896552 ], [ 29.796951518987342, -1.394456206896552 ], [ 29.796951518987342, -1.394186724137931 ], [ 29.796682025316457, -1.394186724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1186, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.394456206896552 ], [ 29.768654683544302, -1.394995172413793 ], [ 29.76892417721519, -1.394995172413793 ], [ 29.76892417721519, -1.394456206896552 ], [ 29.768654683544302, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1187, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.394456206896552 ], [ 29.76892417721519, -1.394725689655173 ], [ 29.769193670886075, -1.394725689655173 ], [ 29.769193670886075, -1.394456206896552 ], [ 29.76892417721519, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1188, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.394456206896552 ], [ 29.769193670886075, -1.394725689655173 ], [ 29.76946316455696, -1.394725689655173 ], [ 29.76946316455696, -1.394456206896552 ], [ 29.769193670886075, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1189, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.394456206896552 ], [ 29.76946316455696, -1.394725689655173 ], [ 29.769732658227849, -1.394725689655173 ], [ 29.769732658227849, -1.394456206896552 ], [ 29.76946316455696, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.394456206896552 ], [ 29.769732658227849, -1.394725689655173 ], [ 29.770002151898733, -1.394725689655173 ], [ 29.770002151898733, -1.394456206896552 ], [ 29.769732658227849, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1191, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.394456206896552 ], [ 29.770002151898733, -1.394725689655173 ], [ 29.770271645569618, -1.394725689655173 ], [ 29.770271645569618, -1.394456206896552 ], [ 29.770002151898733, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1192, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.394456206896552 ], [ 29.770271645569618, -1.394725689655173 ], [ 29.770541139240507, -1.394725689655173 ], [ 29.770541139240507, -1.394456206896552 ], [ 29.770271645569618, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1193, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.394456206896552 ], [ 29.770541139240507, -1.394725689655173 ], [ 29.770810632911392, -1.394725689655173 ], [ 29.770810632911392, -1.394456206896552 ], [ 29.770541139240507, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1194, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.394456206896552 ], [ 29.770810632911392, -1.394725689655173 ], [ 29.771080126582277, -1.394725689655173 ], [ 29.771080126582277, -1.394456206896552 ], [ 29.770810632911392, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1195, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.394456206896552 ], [ 29.771080126582277, -1.394725689655173 ], [ 29.771349620253165, -1.394725689655173 ], [ 29.771349620253165, -1.394456206896552 ], [ 29.771080126582277, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1196, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.394456206896552 ], [ 29.771349620253165, -1.394725689655173 ], [ 29.77161911392405, -1.394725689655173 ], [ 29.77161911392405, -1.394456206896552 ], [ 29.771349620253165, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1197, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.394456206896552 ], [ 29.77161911392405, -1.394725689655173 ], [ 29.771888607594935, -1.394725689655173 ], [ 29.771888607594935, -1.394456206896552 ], [ 29.77161911392405, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1198, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.394456206896552 ], [ 29.771888607594935, -1.394725689655173 ], [ 29.772158101265823, -1.394725689655173 ], [ 29.772158101265823, -1.394456206896552 ], [ 29.771888607594935, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1199, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.394456206896552 ], [ 29.772158101265823, -1.394725689655173 ], [ 29.772427594936708, -1.394725689655173 ], [ 29.772427594936708, -1.394456206896552 ], [ 29.772158101265823, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1200, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.394456206896552 ], [ 29.772427594936708, -1.394725689655173 ], [ 29.772697088607593, -1.394725689655173 ], [ 29.772697088607593, -1.394456206896552 ], [ 29.772427594936708, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1201, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.394456206896552 ], [ 29.772697088607593, -1.394725689655173 ], [ 29.773236075949367, -1.394725689655173 ], [ 29.773236075949367, -1.394456206896552 ], [ 29.772697088607593, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1202, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.394456206896552 ], [ 29.773236075949367, -1.394725689655173 ], [ 29.773505569620252, -1.394725689655173 ], [ 29.773505569620252, -1.394456206896552 ], [ 29.773236075949367, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1203, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.394456206896552 ], [ 29.773505569620252, -1.394725689655173 ], [ 29.77377506329114, -1.394725689655173 ], [ 29.77377506329114, -1.394456206896552 ], [ 29.773505569620252, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1204, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.394456206896552 ], [ 29.77377506329114, -1.394725689655173 ], [ 29.774044556962025, -1.394725689655173 ], [ 29.774044556962025, -1.394456206896552 ], [ 29.77377506329114, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1205, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.394456206896552 ], [ 29.774044556962025, -1.394725689655173 ], [ 29.77431405063291, -1.394725689655173 ], [ 29.77431405063291, -1.394456206896552 ], [ 29.774044556962025, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1206, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.394456206896552 ], [ 29.77431405063291, -1.394725689655173 ], [ 29.774853037974683, -1.394725689655173 ], [ 29.774853037974683, -1.394456206896552 ], [ 29.77431405063291, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1207, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.394456206896552 ], [ 29.774853037974683, -1.394725689655173 ], [ 29.775122531645568, -1.394725689655173 ], [ 29.775122531645568, -1.394456206896552 ], [ 29.774853037974683, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1208, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.394456206896552 ], [ 29.775122531645568, -1.394725689655173 ], [ 29.775392025316457, -1.394725689655173 ], [ 29.775392025316457, -1.394456206896552 ], [ 29.775122531645568, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1209, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.394456206896552 ], [ 29.775392025316457, -1.394725689655173 ], [ 29.775661518987341, -1.394725689655173 ], [ 29.775661518987341, -1.394456206896552 ], [ 29.775392025316457, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1210, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.394456206896552 ], [ 29.775661518987341, -1.394725689655173 ], [ 29.775931012658226, -1.394725689655173 ], [ 29.775931012658226, -1.394456206896552 ], [ 29.775661518987341, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1211, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.394456206896552 ], [ 29.775931012658226, -1.394725689655173 ], [ 29.776200506329115, -1.394725689655173 ], [ 29.776200506329115, -1.394456206896552 ], [ 29.775931012658226, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1212, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.394456206896552 ], [ 29.776200506329115, -1.394725689655173 ], [ 29.77647, -1.394725689655173 ], [ 29.77647, -1.394456206896552 ], [ 29.776200506329115, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1213, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.394456206896552 ], [ 29.77647, -1.394725689655173 ], [ 29.776739493670885, -1.394725689655173 ], [ 29.776739493670885, -1.394456206896552 ], [ 29.77647, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1214, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.394456206896552 ], [ 29.776739493670885, -1.394725689655173 ], [ 29.777817468354431, -1.394725689655173 ], [ 29.777817468354431, -1.394456206896552 ], [ 29.776739493670885, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1215, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.394456206896552 ], [ 29.777817468354431, -1.394725689655173 ], [ 29.778086962025316, -1.394725689655173 ], [ 29.778086962025316, -1.394456206896552 ], [ 29.777817468354431, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1216, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.394456206896552 ], [ 29.778086962025316, -1.394725689655173 ], [ 29.778356455696201, -1.394725689655173 ], [ 29.778356455696201, -1.394456206896552 ], [ 29.778086962025316, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1217, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.394456206896552 ], [ 29.778356455696201, -1.394725689655173 ], [ 29.77862594936709, -1.394725689655173 ], [ 29.77862594936709, -1.394456206896552 ], [ 29.778356455696201, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1218, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.394456206896552 ], [ 29.77862594936709, -1.394725689655173 ], [ 29.778895443037975, -1.394725689655173 ], [ 29.778895443037975, -1.394456206896552 ], [ 29.77862594936709, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1219, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.394456206896552 ], [ 29.778895443037975, -1.394725689655173 ], [ 29.77916493670886, -1.394725689655173 ], [ 29.77916493670886, -1.394456206896552 ], [ 29.778895443037975, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1220, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.394456206896552 ], [ 29.77916493670886, -1.394725689655173 ], [ 29.779434430379748, -1.394725689655173 ], [ 29.779434430379748, -1.394456206896552 ], [ 29.77916493670886, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1221, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.394456206896552 ], [ 29.779434430379748, -1.394725689655173 ], [ 29.779703924050633, -1.394725689655173 ], [ 29.779703924050633, -1.394456206896552 ], [ 29.779434430379748, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1222, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.394456206896552 ], [ 29.779703924050633, -1.394725689655173 ], [ 29.779973417721518, -1.394725689655173 ], [ 29.779973417721518, -1.394456206896552 ], [ 29.779703924050633, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1223, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.394456206896552 ], [ 29.779973417721518, -1.394725689655173 ], [ 29.780242911392406, -1.394725689655173 ], [ 29.780242911392406, -1.394456206896552 ], [ 29.779973417721518, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1224, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.394456206896552 ], [ 29.780242911392406, -1.394725689655173 ], [ 29.780512405063291, -1.394725689655173 ], [ 29.780512405063291, -1.394456206896552 ], [ 29.780242911392406, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1225, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.394456206896552 ], [ 29.780512405063291, -1.394725689655173 ], [ 29.780781898734176, -1.394725689655173 ], [ 29.780781898734176, -1.394456206896552 ], [ 29.780512405063291, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1226, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.394456206896552 ], [ 29.780781898734176, -1.394725689655173 ], [ 29.781051392405065, -1.394725689655173 ], [ 29.781051392405065, -1.394456206896552 ], [ 29.780781898734176, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1227, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.394456206896552 ], [ 29.781051392405065, -1.394725689655173 ], [ 29.78132088607595, -1.394725689655173 ], [ 29.78132088607595, -1.394456206896552 ], [ 29.781051392405065, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.394456206896552 ], [ 29.78132088607595, -1.394725689655173 ], [ 29.781590379746834, -1.394725689655173 ], [ 29.781590379746834, -1.394456206896552 ], [ 29.78132088607595, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1229, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.394456206896552 ], [ 29.781590379746834, -1.394725689655173 ], [ 29.781859873417723, -1.394725689655173 ], [ 29.781859873417723, -1.394456206896552 ], [ 29.781590379746834, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1230, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.394456206896552 ], [ 29.781859873417723, -1.394725689655173 ], [ 29.782129367088608, -1.394725689655173 ], [ 29.782129367088608, -1.394456206896552 ], [ 29.781859873417723, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1231, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.394456206896552 ], [ 29.782129367088608, -1.394725689655173 ], [ 29.782398860759493, -1.394725689655173 ], [ 29.782398860759493, -1.394456206896552 ], [ 29.782129367088608, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1232, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.394456206896552 ], [ 29.782398860759493, -1.394725689655173 ], [ 29.782668354430381, -1.394725689655173 ], [ 29.782668354430381, -1.394456206896552 ], [ 29.782398860759493, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1233, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.394456206896552 ], [ 29.782668354430381, -1.394725689655173 ], [ 29.782937848101266, -1.394725689655173 ], [ 29.782937848101266, -1.394456206896552 ], [ 29.782668354430381, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1234, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.394456206896552 ], [ 29.782937848101266, -1.394725689655173 ], [ 29.783207341772151, -1.394725689655173 ], [ 29.783207341772151, -1.394456206896552 ], [ 29.782937848101266, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1235, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.394456206896552 ], [ 29.783207341772151, -1.394725689655173 ], [ 29.783476835443039, -1.394725689655173 ], [ 29.783476835443039, -1.394456206896552 ], [ 29.783207341772151, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1236, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.394456206896552 ], [ 29.783476835443039, -1.394725689655173 ], [ 29.784015822784809, -1.394725689655173 ], [ 29.784015822784809, -1.394456206896552 ], [ 29.783476835443039, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1237, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.394456206896552 ], [ 29.784015822784809, -1.394725689655173 ], [ 29.784285316455698, -1.394725689655173 ], [ 29.784285316455698, -1.394456206896552 ], [ 29.784015822784809, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1238, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.394456206896552 ], [ 29.784285316455698, -1.394725689655173 ], [ 29.784554810126583, -1.394725689655173 ], [ 29.784554810126583, -1.394456206896552 ], [ 29.784285316455698, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1239, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.394456206896552 ], [ 29.784554810126583, -1.394725689655173 ], [ 29.784824303797468, -1.394725689655173 ], [ 29.784824303797468, -1.394456206896552 ], [ 29.784554810126583, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1240, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.394456206896552 ], [ 29.784824303797468, -1.394725689655173 ], [ 29.785093797468356, -1.394725689655173 ], [ 29.785093797468356, -1.394456206896552 ], [ 29.784824303797468, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1241, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.394456206896552 ], [ 29.785093797468356, -1.394725689655173 ], [ 29.785363291139241, -1.394725689655173 ], [ 29.785363291139241, -1.394456206896552 ], [ 29.785093797468356, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1242, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.394456206896552 ], [ 29.785363291139241, -1.394995172413793 ], [ 29.785632784810126, -1.394995172413793 ], [ 29.785632784810126, -1.394456206896552 ], [ 29.785363291139241, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1243, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.394456206896552 ], [ 29.785632784810126, -1.394725689655173 ], [ 29.785902278481014, -1.394725689655173 ], [ 29.785902278481014, -1.394456206896552 ], [ 29.785632784810126, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1244, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.394456206896552 ], [ 29.785902278481014, -1.394725689655173 ], [ 29.786171772151899, -1.394725689655173 ], [ 29.786171772151899, -1.394456206896552 ], [ 29.785902278481014, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1245, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.394456206896552 ], [ 29.786171772151899, -1.394725689655173 ], [ 29.786441265822784, -1.394725689655173 ], [ 29.786441265822784, -1.394456206896552 ], [ 29.786171772151899, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1246, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.394456206896552 ], [ 29.786441265822784, -1.394725689655173 ], [ 29.786710759493673, -1.394725689655173 ], [ 29.786710759493673, -1.394456206896552 ], [ 29.786441265822784, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1247, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.394456206896552 ], [ 29.786980253164558, -1.394725689655173 ], [ 29.787249746835442, -1.394725689655173 ], [ 29.787249746835442, -1.394456206896552 ], [ 29.786980253164558, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1248, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.394456206896552 ], [ 29.787249746835442, -1.394725689655173 ], [ 29.787519240506327, -1.394725689655173 ], [ 29.787519240506327, -1.394456206896552 ], [ 29.787249746835442, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1249, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.394456206896552 ], [ 29.787519240506327, -1.394725689655173 ], [ 29.787788734177216, -1.394725689655173 ], [ 29.787788734177216, -1.394456206896552 ], [ 29.787519240506327, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1250, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.394456206896552 ], [ 29.787788734177216, -1.394725689655173 ], [ 29.788058227848101, -1.394725689655173 ], [ 29.788058227848101, -1.394456206896552 ], [ 29.787788734177216, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1251, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.394456206896552 ], [ 29.788058227848101, -1.394725689655173 ], [ 29.788327721518986, -1.394725689655173 ], [ 29.788327721518986, -1.394456206896552 ], [ 29.788058227848101, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1252, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.394456206896552 ], [ 29.788327721518986, -1.394725689655173 ], [ 29.788597215189874, -1.394725689655173 ], [ 29.788597215189874, -1.394456206896552 ], [ 29.788327721518986, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1253, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.394456206896552 ], [ 29.788597215189874, -1.394725689655173 ], [ 29.789675189873417, -1.394725689655173 ], [ 29.789675189873417, -1.394456206896552 ], [ 29.788597215189874, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1254, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.394456206896552 ], [ 29.789675189873417, -1.394725689655173 ], [ 29.789944683544302, -1.394725689655173 ], [ 29.789944683544302, -1.394456206896552 ], [ 29.789675189873417, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1255, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.394456206896552 ], [ 29.789944683544302, -1.394725689655173 ], [ 29.790214177215191, -1.394725689655173 ], [ 29.790214177215191, -1.394456206896552 ], [ 29.789944683544302, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1256, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.394456206896552 ], [ 29.790214177215191, -1.394725689655173 ], [ 29.790483670886076, -1.394725689655173 ], [ 29.790483670886076, -1.394456206896552 ], [ 29.790214177215191, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1257, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.394456206896552 ], [ 29.790483670886076, -1.394725689655173 ], [ 29.791022658227849, -1.394725689655173 ], [ 29.791022658227849, -1.394456206896552 ], [ 29.790483670886076, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1258, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.394456206896552 ], [ 29.791022658227849, -1.394725689655173 ], [ 29.791561645569619, -1.394725689655173 ], [ 29.791561645569619, -1.394456206896552 ], [ 29.791022658227849, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1259, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.394456206896552 ], [ 29.791561645569619, -1.394725689655173 ], [ 29.791831139240507, -1.394725689655173 ], [ 29.791831139240507, -1.394456206896552 ], [ 29.791561645569619, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1260, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.394456206896552 ], [ 29.791831139240507, -1.394725689655173 ], [ 29.792100632911392, -1.394725689655173 ], [ 29.792100632911392, -1.394456206896552 ], [ 29.791831139240507, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1261, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.394456206896552 ], [ 29.792100632911392, -1.394725689655173 ], [ 29.792370126582277, -1.394725689655173 ], [ 29.792370126582277, -1.394456206896552 ], [ 29.792100632911392, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1262, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.394456206896552 ], [ 29.792370126582277, -1.394725689655173 ], [ 29.792909113924051, -1.394725689655173 ], [ 29.792909113924051, -1.394456206896552 ], [ 29.792370126582277, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1263, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.394456206896552 ], [ 29.792909113924051, -1.394725689655173 ], [ 29.793178607594935, -1.394725689655173 ], [ 29.793178607594935, -1.394456206896552 ], [ 29.792909113924051, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1264, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.394456206896552 ], [ 29.793178607594935, -1.394725689655173 ], [ 29.793448101265824, -1.394725689655173 ], [ 29.793448101265824, -1.394456206896552 ], [ 29.793178607594935, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1265, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.394456206896552 ], [ 29.793448101265824, -1.394725689655173 ], [ 29.793717594936709, -1.394725689655173 ], [ 29.793717594936709, -1.394456206896552 ], [ 29.793448101265824, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1266, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.394456206896552 ], [ 29.793717594936709, -1.394725689655173 ], [ 29.793987088607594, -1.394725689655173 ], [ 29.793987088607594, -1.394456206896552 ], [ 29.793717594936709, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1267, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.394456206896552 ], [ 29.793987088607594, -1.394725689655173 ], [ 29.794256582278482, -1.394725689655173 ], [ 29.794256582278482, -1.394456206896552 ], [ 29.793987088607594, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.394456206896552 ], [ 29.794256582278482, -1.394725689655173 ], [ 29.794526075949367, -1.394725689655173 ], [ 29.794526075949367, -1.394456206896552 ], [ 29.794256582278482, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1269, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.394456206896552 ], [ 29.794526075949367, -1.394725689655173 ], [ 29.794795569620252, -1.394725689655173 ], [ 29.794795569620252, -1.394456206896552 ], [ 29.794526075949367, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1270, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.394456206896552 ], [ 29.794795569620252, -1.394725689655173 ], [ 29.79506506329114, -1.394725689655173 ], [ 29.79506506329114, -1.394456206896552 ], [ 29.794795569620252, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1271, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.394456206896552 ], [ 29.79506506329114, -1.394725689655173 ], [ 29.795334556962025, -1.394725689655173 ], [ 29.795334556962025, -1.394456206896552 ], [ 29.79506506329114, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1272, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.394456206896552 ], [ 29.795334556962025, -1.394725689655173 ], [ 29.79560405063291, -1.394725689655173 ], [ 29.79560405063291, -1.394456206896552 ], [ 29.795334556962025, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1273, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.394456206896552 ], [ 29.79560405063291, -1.394725689655173 ], [ 29.795873544303799, -1.394725689655173 ], [ 29.795873544303799, -1.394456206896552 ], [ 29.79560405063291, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1274, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.394456206896552 ], [ 29.795873544303799, -1.394725689655173 ], [ 29.796412531645569, -1.394725689655173 ], [ 29.796412531645569, -1.394456206896552 ], [ 29.795873544303799, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1275, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.394456206896552 ], [ 29.796412531645569, -1.394725689655173 ], [ 29.796682025316457, -1.394725689655173 ], [ 29.796682025316457, -1.394456206896552 ], [ 29.796412531645569, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1276, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.394456206896552 ], [ 29.796682025316457, -1.394725689655173 ], [ 29.796951518987342, -1.394725689655173 ], [ 29.796951518987342, -1.394456206896552 ], [ 29.796682025316457, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1277, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.394456206896552 ], [ 29.796951518987342, -1.394725689655173 ], [ 29.797221012658227, -1.394725689655173 ], [ 29.797221012658227, -1.394456206896552 ], [ 29.796951518987342, -1.394456206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1278, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.394725689655173 ], [ 29.76892417721519, -1.394995172413793 ], [ 29.769193670886075, -1.394995172413793 ], [ 29.769193670886075, -1.394725689655173 ], [ 29.76892417721519, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1279, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.394725689655173 ], [ 29.769193670886075, -1.394995172413793 ], [ 29.76946316455696, -1.394995172413793 ], [ 29.76946316455696, -1.394725689655173 ], [ 29.769193670886075, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1280, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.394725689655173 ], [ 29.76946316455696, -1.394995172413793 ], [ 29.769732658227849, -1.394995172413793 ], [ 29.769732658227849, -1.394725689655173 ], [ 29.76946316455696, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1281, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.394725689655173 ], [ 29.769732658227849, -1.394995172413793 ], [ 29.770002151898733, -1.394995172413793 ], [ 29.770002151898733, -1.394725689655173 ], [ 29.769732658227849, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1282, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.394725689655173 ], [ 29.770002151898733, -1.394995172413793 ], [ 29.770271645569618, -1.394995172413793 ], [ 29.770271645569618, -1.394725689655173 ], [ 29.770002151898733, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1283, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.394725689655173 ], [ 29.770271645569618, -1.394995172413793 ], [ 29.770541139240507, -1.394995172413793 ], [ 29.770541139240507, -1.394725689655173 ], [ 29.770271645569618, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1284, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.394725689655173 ], [ 29.770541139240507, -1.394995172413793 ], [ 29.770810632911392, -1.394995172413793 ], [ 29.770810632911392, -1.394725689655173 ], [ 29.770541139240507, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1285, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.394725689655173 ], [ 29.770810632911392, -1.394995172413793 ], [ 29.771080126582277, -1.394995172413793 ], [ 29.771080126582277, -1.394725689655173 ], [ 29.770810632911392, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1286, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.394725689655173 ], [ 29.771080126582277, -1.394995172413793 ], [ 29.771349620253165, -1.394995172413793 ], [ 29.771349620253165, -1.394725689655173 ], [ 29.771080126582277, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1287, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.394725689655173 ], [ 29.771349620253165, -1.394995172413793 ], [ 29.77161911392405, -1.394995172413793 ], [ 29.77161911392405, -1.394725689655173 ], [ 29.771349620253165, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1288, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.394725689655173 ], [ 29.77161911392405, -1.394995172413793 ], [ 29.771888607594935, -1.394995172413793 ], [ 29.771888607594935, -1.394725689655173 ], [ 29.77161911392405, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1289, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.394725689655173 ], [ 29.771888607594935, -1.394995172413793 ], [ 29.772158101265823, -1.394995172413793 ], [ 29.772158101265823, -1.394725689655173 ], [ 29.771888607594935, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1290, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.394725689655173 ], [ 29.772158101265823, -1.394995172413793 ], [ 29.772427594936708, -1.394995172413793 ], [ 29.772427594936708, -1.394725689655173 ], [ 29.772158101265823, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1291, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.394725689655173 ], [ 29.772427594936708, -1.394995172413793 ], [ 29.772697088607593, -1.394995172413793 ], [ 29.772697088607593, -1.394725689655173 ], [ 29.772427594936708, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1292, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.394725689655173 ], [ 29.772697088607593, -1.394995172413793 ], [ 29.773236075949367, -1.394995172413793 ], [ 29.773236075949367, -1.394725689655173 ], [ 29.772697088607593, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1293, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.394725689655173 ], [ 29.773236075949367, -1.394995172413793 ], [ 29.773505569620252, -1.394995172413793 ], [ 29.773505569620252, -1.394725689655173 ], [ 29.773236075949367, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1294, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.394725689655173 ], [ 29.773505569620252, -1.394995172413793 ], [ 29.77377506329114, -1.394995172413793 ], [ 29.77377506329114, -1.394725689655173 ], [ 29.773505569620252, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1295, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.394725689655173 ], [ 29.77377506329114, -1.394995172413793 ], [ 29.774044556962025, -1.394995172413793 ], [ 29.774044556962025, -1.394725689655173 ], [ 29.77377506329114, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1296, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.394725689655173 ], [ 29.774044556962025, -1.394995172413793 ], [ 29.77431405063291, -1.394995172413793 ], [ 29.77431405063291, -1.394725689655173 ], [ 29.774044556962025, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1297, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.394725689655173 ], [ 29.77431405063291, -1.394995172413793 ], [ 29.774853037974683, -1.394995172413793 ], [ 29.774853037974683, -1.394725689655173 ], [ 29.77431405063291, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1298, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.394725689655173 ], [ 29.774853037974683, -1.394995172413793 ], [ 29.775122531645568, -1.394995172413793 ], [ 29.775122531645568, -1.394725689655173 ], [ 29.774853037974683, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1299, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.394725689655173 ], [ 29.775122531645568, -1.394995172413793 ], [ 29.775392025316457, -1.394995172413793 ], [ 29.775392025316457, -1.394725689655173 ], [ 29.775122531645568, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1300, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.394725689655173 ], [ 29.775392025316457, -1.394995172413793 ], [ 29.775661518987341, -1.394995172413793 ], [ 29.775661518987341, -1.394725689655173 ], [ 29.775392025316457, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1301, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.394725689655173 ], [ 29.775661518987341, -1.394995172413793 ], [ 29.775931012658226, -1.394995172413793 ], [ 29.775931012658226, -1.394725689655173 ], [ 29.775661518987341, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1302, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.394725689655173 ], [ 29.775931012658226, -1.394995172413793 ], [ 29.776200506329115, -1.394995172413793 ], [ 29.776200506329115, -1.394725689655173 ], [ 29.775931012658226, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1303, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.394725689655173 ], [ 29.776200506329115, -1.394995172413793 ], [ 29.77647, -1.394995172413793 ], [ 29.77647, -1.394725689655173 ], [ 29.776200506329115, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1304, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.394725689655173 ], [ 29.77647, -1.394995172413793 ], [ 29.777008987341773, -1.394995172413793 ], [ 29.777008987341773, -1.394725689655173 ], [ 29.77647, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1305, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.394725689655173 ], [ 29.777008987341773, -1.394995172413793 ], [ 29.777547974683543, -1.394995172413793 ], [ 29.777547974683543, -1.394725689655173 ], [ 29.777008987341773, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1306, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.394725689655173 ], [ 29.777547974683543, -1.394995172413793 ], [ 29.778086962025316, -1.394995172413793 ], [ 29.778086962025316, -1.394725689655173 ], [ 29.777547974683543, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1307, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.394725689655173 ], [ 29.778086962025316, -1.394995172413793 ], [ 29.778356455696201, -1.394995172413793 ], [ 29.778356455696201, -1.394725689655173 ], [ 29.778086962025316, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1308, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.394725689655173 ], [ 29.778356455696201, -1.394995172413793 ], [ 29.77862594936709, -1.394995172413793 ], [ 29.77862594936709, -1.394725689655173 ], [ 29.778356455696201, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1309, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.394725689655173 ], [ 29.77862594936709, -1.394995172413793 ], [ 29.778895443037975, -1.394995172413793 ], [ 29.778895443037975, -1.394725689655173 ], [ 29.77862594936709, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1310, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.394725689655173 ], [ 29.778895443037975, -1.394995172413793 ], [ 29.77916493670886, -1.394995172413793 ], [ 29.77916493670886, -1.394725689655173 ], [ 29.778895443037975, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1311, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.394725689655173 ], [ 29.77916493670886, -1.394995172413793 ], [ 29.779434430379748, -1.394995172413793 ], [ 29.779434430379748, -1.394725689655173 ], [ 29.77916493670886, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1312, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.394725689655173 ], [ 29.779434430379748, -1.394995172413793 ], [ 29.779703924050633, -1.394995172413793 ], [ 29.779703924050633, -1.394725689655173 ], [ 29.779434430379748, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1313, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.394725689655173 ], [ 29.779703924050633, -1.394995172413793 ], [ 29.779973417721518, -1.394995172413793 ], [ 29.779973417721518, -1.394725689655173 ], [ 29.779703924050633, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1314, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.394725689655173 ], [ 29.779973417721518, -1.394995172413793 ], [ 29.780242911392406, -1.394995172413793 ], [ 29.780242911392406, -1.394725689655173 ], [ 29.779973417721518, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1315, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.394725689655173 ], [ 29.780242911392406, -1.394995172413793 ], [ 29.780512405063291, -1.394995172413793 ], [ 29.780512405063291, -1.394725689655173 ], [ 29.780242911392406, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1316, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.394725689655173 ], [ 29.780512405063291, -1.394995172413793 ], [ 29.780781898734176, -1.394995172413793 ], [ 29.780781898734176, -1.394725689655173 ], [ 29.780512405063291, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1317, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.394725689655173 ], [ 29.780781898734176, -1.394995172413793 ], [ 29.781051392405065, -1.394995172413793 ], [ 29.781051392405065, -1.394725689655173 ], [ 29.780781898734176, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1318, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.394725689655173 ], [ 29.781051392405065, -1.394995172413793 ], [ 29.78132088607595, -1.394995172413793 ], [ 29.78132088607595, -1.394725689655173 ], [ 29.781051392405065, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1319, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.394725689655173 ], [ 29.78132088607595, -1.394995172413793 ], [ 29.781590379746834, -1.394995172413793 ], [ 29.781590379746834, -1.394725689655173 ], [ 29.78132088607595, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1320, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.394725689655173 ], [ 29.781590379746834, -1.394995172413793 ], [ 29.781859873417723, -1.394995172413793 ], [ 29.781859873417723, -1.394725689655173 ], [ 29.781590379746834, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1321, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.394725689655173 ], [ 29.781859873417723, -1.394995172413793 ], [ 29.782129367088608, -1.394995172413793 ], [ 29.782129367088608, -1.394725689655173 ], [ 29.781859873417723, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1322, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.394725689655173 ], [ 29.782129367088608, -1.394995172413793 ], [ 29.782398860759493, -1.394995172413793 ], [ 29.782398860759493, -1.394725689655173 ], [ 29.782129367088608, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1323, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.394725689655173 ], [ 29.782398860759493, -1.394995172413793 ], [ 29.782668354430381, -1.394995172413793 ], [ 29.782668354430381, -1.394725689655173 ], [ 29.782398860759493, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1324, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.394725689655173 ], [ 29.782668354430381, -1.394995172413793 ], [ 29.782937848101266, -1.394995172413793 ], [ 29.782937848101266, -1.394725689655173 ], [ 29.782668354430381, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.394725689655173 ], [ 29.782937848101266, -1.394995172413793 ], [ 29.783207341772151, -1.394995172413793 ], [ 29.783207341772151, -1.394725689655173 ], [ 29.782937848101266, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1326, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.394725689655173 ], [ 29.783207341772151, -1.394995172413793 ], [ 29.783476835443039, -1.394995172413793 ], [ 29.783476835443039, -1.394725689655173 ], [ 29.783207341772151, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1327, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.394725689655173 ], [ 29.783476835443039, -1.394995172413793 ], [ 29.784015822784809, -1.394995172413793 ], [ 29.784015822784809, -1.394725689655173 ], [ 29.783476835443039, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1328, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.394725689655173 ], [ 29.784015822784809, -1.394995172413793 ], [ 29.784285316455698, -1.394995172413793 ], [ 29.784285316455698, -1.394725689655173 ], [ 29.784015822784809, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1329, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.394725689655173 ], [ 29.784285316455698, -1.394995172413793 ], [ 29.784554810126583, -1.394995172413793 ], [ 29.784554810126583, -1.394725689655173 ], [ 29.784285316455698, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1330, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.394725689655173 ], [ 29.784554810126583, -1.394995172413793 ], [ 29.784824303797468, -1.394995172413793 ], [ 29.784824303797468, -1.394725689655173 ], [ 29.784554810126583, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1331, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.394725689655173 ], [ 29.784824303797468, -1.394995172413793 ], [ 29.785093797468356, -1.394995172413793 ], [ 29.785093797468356, -1.394725689655173 ], [ 29.784824303797468, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1332, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.394725689655173 ], [ 29.785093797468356, -1.394995172413793 ], [ 29.785363291139241, -1.394995172413793 ], [ 29.785363291139241, -1.394725689655173 ], [ 29.785093797468356, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1333, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.394725689655173 ], [ 29.785632784810126, -1.394995172413793 ], [ 29.785902278481014, -1.394995172413793 ], [ 29.785902278481014, -1.394725689655173 ], [ 29.785632784810126, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1334, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.394725689655173 ], [ 29.785902278481014, -1.394995172413793 ], [ 29.786171772151899, -1.394995172413793 ], [ 29.786171772151899, -1.394725689655173 ], [ 29.785902278481014, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1335, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.394725689655173 ], [ 29.786171772151899, -1.394995172413793 ], [ 29.786441265822784, -1.394995172413793 ], [ 29.786441265822784, -1.394725689655173 ], [ 29.786171772151899, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1336, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.394725689655173 ], [ 29.786441265822784, -1.394995172413793 ], [ 29.786710759493673, -1.394995172413793 ], [ 29.786710759493673, -1.394725689655173 ], [ 29.786441265822784, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1337, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.394725689655173 ], [ 29.786710759493673, -1.394995172413793 ], [ 29.786980253164558, -1.394995172413793 ], [ 29.786980253164558, -1.394725689655173 ], [ 29.786710759493673, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1338, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.394725689655173 ], [ 29.786980253164558, -1.394995172413793 ], [ 29.787249746835442, -1.394995172413793 ], [ 29.787249746835442, -1.394725689655173 ], [ 29.786980253164558, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1339, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.394725689655173 ], [ 29.787249746835442, -1.394995172413793 ], [ 29.787519240506327, -1.394995172413793 ], [ 29.787519240506327, -1.394725689655173 ], [ 29.787249746835442, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1340, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.394725689655173 ], [ 29.787519240506327, -1.394995172413793 ], [ 29.787788734177216, -1.394995172413793 ], [ 29.787788734177216, -1.394725689655173 ], [ 29.787519240506327, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1341, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.394725689655173 ], [ 29.787788734177216, -1.394995172413793 ], [ 29.788058227848101, -1.394995172413793 ], [ 29.788058227848101, -1.394725689655173 ], [ 29.787788734177216, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1342, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.394725689655173 ], [ 29.788058227848101, -1.394995172413793 ], [ 29.788327721518986, -1.394995172413793 ], [ 29.788327721518986, -1.394725689655173 ], [ 29.788058227848101, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1343, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.394725689655173 ], [ 29.788327721518986, -1.394995172413793 ], [ 29.788597215189874, -1.394995172413793 ], [ 29.788597215189874, -1.394725689655173 ], [ 29.788327721518986, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1344, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.394725689655173 ], [ 29.788597215189874, -1.394995172413793 ], [ 29.788866708860759, -1.394995172413793 ], [ 29.788866708860759, -1.394725689655173 ], [ 29.788597215189874, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1345, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.394725689655173 ], [ 29.788866708860759, -1.394995172413793 ], [ 29.789405696202532, -1.394995172413793 ], [ 29.789405696202532, -1.394725689655173 ], [ 29.788866708860759, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1346, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.394725689655173 ], [ 29.789405696202532, -1.394995172413793 ], [ 29.789675189873417, -1.394995172413793 ], [ 29.789675189873417, -1.394725689655173 ], [ 29.789405696202532, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1347, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.394725689655173 ], [ 29.789675189873417, -1.394995172413793 ], [ 29.789944683544302, -1.394995172413793 ], [ 29.789944683544302, -1.394725689655173 ], [ 29.789675189873417, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1348, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.394725689655173 ], [ 29.789944683544302, -1.394995172413793 ], [ 29.790214177215191, -1.394995172413793 ], [ 29.790214177215191, -1.394725689655173 ], [ 29.789944683544302, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1349, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.394725689655173 ], [ 29.790214177215191, -1.394995172413793 ], [ 29.790483670886076, -1.394995172413793 ], [ 29.790483670886076, -1.394725689655173 ], [ 29.790214177215191, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1350, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.394725689655173 ], [ 29.790483670886076, -1.394995172413793 ], [ 29.791292151898734, -1.394995172413793 ], [ 29.791292151898734, -1.394725689655173 ], [ 29.790483670886076, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1351, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.394725689655173 ], [ 29.791292151898734, -1.394995172413793 ], [ 29.791561645569619, -1.394995172413793 ], [ 29.791561645569619, -1.394725689655173 ], [ 29.791292151898734, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1352, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.394725689655173 ], [ 29.791561645569619, -1.394995172413793 ], [ 29.792100632911392, -1.394995172413793 ], [ 29.792100632911392, -1.394725689655173 ], [ 29.791561645569619, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1353, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.394725689655173 ], [ 29.792100632911392, -1.394995172413793 ], [ 29.792370126582277, -1.394995172413793 ], [ 29.792370126582277, -1.394725689655173 ], [ 29.792100632911392, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1354, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.394725689655173 ], [ 29.792370126582277, -1.394995172413793 ], [ 29.792909113924051, -1.394995172413793 ], [ 29.792909113924051, -1.394725689655173 ], [ 29.792370126582277, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1355, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.394725689655173 ], [ 29.792909113924051, -1.394995172413793 ], [ 29.793178607594935, -1.394995172413793 ], [ 29.793178607594935, -1.394725689655173 ], [ 29.792909113924051, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1356, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.394725689655173 ], [ 29.793178607594935, -1.394995172413793 ], [ 29.793448101265824, -1.394995172413793 ], [ 29.793448101265824, -1.394725689655173 ], [ 29.793178607594935, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1357, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.394725689655173 ], [ 29.793448101265824, -1.394995172413793 ], [ 29.793717594936709, -1.394995172413793 ], [ 29.793717594936709, -1.394725689655173 ], [ 29.793448101265824, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1358, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.394725689655173 ], [ 29.793717594936709, -1.394995172413793 ], [ 29.793987088607594, -1.394995172413793 ], [ 29.793987088607594, -1.394725689655173 ], [ 29.793717594936709, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1359, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.394725689655173 ], [ 29.793987088607594, -1.394995172413793 ], [ 29.794256582278482, -1.394995172413793 ], [ 29.794256582278482, -1.394725689655173 ], [ 29.793987088607594, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1360, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.394725689655173 ], [ 29.794256582278482, -1.394995172413793 ], [ 29.794526075949367, -1.394995172413793 ], [ 29.794526075949367, -1.394725689655173 ], [ 29.794256582278482, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1361, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.394725689655173 ], [ 29.794526075949367, -1.394995172413793 ], [ 29.794795569620252, -1.394995172413793 ], [ 29.794795569620252, -1.394725689655173 ], [ 29.794526075949367, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1362, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.394725689655173 ], [ 29.794795569620252, -1.394995172413793 ], [ 29.79506506329114, -1.394995172413793 ], [ 29.79506506329114, -1.394725689655173 ], [ 29.794795569620252, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1363, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.394725689655173 ], [ 29.79506506329114, -1.394995172413793 ], [ 29.795334556962025, -1.394995172413793 ], [ 29.795334556962025, -1.394725689655173 ], [ 29.79506506329114, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1364, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.394725689655173 ], [ 29.795334556962025, -1.394995172413793 ], [ 29.79560405063291, -1.394995172413793 ], [ 29.79560405063291, -1.394725689655173 ], [ 29.795334556962025, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1365, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.394725689655173 ], [ 29.79560405063291, -1.394995172413793 ], [ 29.795873544303799, -1.394995172413793 ], [ 29.795873544303799, -1.394725689655173 ], [ 29.79560405063291, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1366, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.394725689655173 ], [ 29.795873544303799, -1.394995172413793 ], [ 29.796412531645569, -1.394995172413793 ], [ 29.796412531645569, -1.394725689655173 ], [ 29.795873544303799, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1367, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.394725689655173 ], [ 29.796412531645569, -1.394995172413793 ], [ 29.796682025316457, -1.394995172413793 ], [ 29.796682025316457, -1.394725689655173 ], [ 29.796412531645569, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1368, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.394725689655173 ], [ 29.796682025316457, -1.394995172413793 ], [ 29.796951518987342, -1.394995172413793 ], [ 29.796951518987342, -1.394725689655173 ], [ 29.796682025316457, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1369, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.394725689655173 ], [ 29.796951518987342, -1.394995172413793 ], [ 29.797221012658227, -1.394995172413793 ], [ 29.797221012658227, -1.394725689655173 ], [ 29.796951518987342, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1370, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.394725689655173 ], [ 29.797221012658227, -1.394995172413793 ], [ 29.797490506329115, -1.394995172413793 ], [ 29.797490506329115, -1.394725689655173 ], [ 29.797221012658227, -1.394725689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1371, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.394995172413793 ], [ 29.768385189873417, -1.395264655172414 ], [ 29.768654683544302, -1.395264655172414 ], [ 29.768654683544302, -1.394995172413793 ], [ 29.768385189873417, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1372, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.394995172413793 ], [ 29.768654683544302, -1.395264655172414 ], [ 29.76892417721519, -1.395264655172414 ], [ 29.76892417721519, -1.394995172413793 ], [ 29.768654683544302, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1373, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.394995172413793 ], [ 29.76892417721519, -1.395264655172414 ], [ 29.769193670886075, -1.395264655172414 ], [ 29.769193670886075, -1.394995172413793 ], [ 29.76892417721519, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1374, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.394995172413793 ], [ 29.769193670886075, -1.395264655172414 ], [ 29.76946316455696, -1.395264655172414 ], [ 29.76946316455696, -1.394995172413793 ], [ 29.769193670886075, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1375, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.394995172413793 ], [ 29.76946316455696, -1.395264655172414 ], [ 29.769732658227849, -1.395264655172414 ], [ 29.769732658227849, -1.394995172413793 ], [ 29.76946316455696, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1376, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.394995172413793 ], [ 29.769732658227849, -1.395264655172414 ], [ 29.770002151898733, -1.395264655172414 ], [ 29.770002151898733, -1.394995172413793 ], [ 29.769732658227849, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1377, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.394995172413793 ], [ 29.770002151898733, -1.395264655172414 ], [ 29.770271645569618, -1.395264655172414 ], [ 29.770271645569618, -1.394995172413793 ], [ 29.770002151898733, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1378, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.394995172413793 ], [ 29.770271645569618, -1.395264655172414 ], [ 29.770541139240507, -1.395264655172414 ], [ 29.770541139240507, -1.394995172413793 ], [ 29.770271645569618, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1379, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.394995172413793 ], [ 29.770541139240507, -1.395264655172414 ], [ 29.770810632911392, -1.395264655172414 ], [ 29.770810632911392, -1.394995172413793 ], [ 29.770541139240507, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1380, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.394995172413793 ], [ 29.770810632911392, -1.395264655172414 ], [ 29.771080126582277, -1.395264655172414 ], [ 29.771080126582277, -1.394995172413793 ], [ 29.770810632911392, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1381, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.394995172413793 ], [ 29.771080126582277, -1.395264655172414 ], [ 29.771349620253165, -1.395264655172414 ], [ 29.771349620253165, -1.394995172413793 ], [ 29.771080126582277, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1382, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.394995172413793 ], [ 29.771349620253165, -1.395264655172414 ], [ 29.77161911392405, -1.395264655172414 ], [ 29.77161911392405, -1.394995172413793 ], [ 29.771349620253165, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1383, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.394995172413793 ], [ 29.77161911392405, -1.395264655172414 ], [ 29.771888607594935, -1.395264655172414 ], [ 29.771888607594935, -1.394995172413793 ], [ 29.77161911392405, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1384, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.394995172413793 ], [ 29.771888607594935, -1.395264655172414 ], [ 29.772158101265823, -1.395264655172414 ], [ 29.772158101265823, -1.394995172413793 ], [ 29.771888607594935, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1385, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.394995172413793 ], [ 29.772158101265823, -1.395264655172414 ], [ 29.772427594936708, -1.395264655172414 ], [ 29.772427594936708, -1.394995172413793 ], [ 29.772158101265823, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1386, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.394995172413793 ], [ 29.772427594936708, -1.395264655172414 ], [ 29.772697088607593, -1.395264655172414 ], [ 29.772697088607593, -1.394995172413793 ], [ 29.772427594936708, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1387, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.394995172413793 ], [ 29.772697088607593, -1.395264655172414 ], [ 29.772966582278482, -1.395264655172414 ], [ 29.772966582278482, -1.394995172413793 ], [ 29.772697088607593, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1388, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.394995172413793 ], [ 29.772966582278482, -1.395264655172414 ], [ 29.773236075949367, -1.395264655172414 ], [ 29.773236075949367, -1.394995172413793 ], [ 29.772966582278482, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1389, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.394995172413793 ], [ 29.773236075949367, -1.395264655172414 ], [ 29.773505569620252, -1.395264655172414 ], [ 29.773505569620252, -1.394995172413793 ], [ 29.773236075949367, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1390, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.394995172413793 ], [ 29.773505569620252, -1.395264655172414 ], [ 29.77377506329114, -1.395264655172414 ], [ 29.77377506329114, -1.394995172413793 ], [ 29.773505569620252, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1391, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.394995172413793 ], [ 29.77377506329114, -1.395264655172414 ], [ 29.774044556962025, -1.395264655172414 ], [ 29.774044556962025, -1.394995172413793 ], [ 29.77377506329114, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1392, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.394995172413793 ], [ 29.774044556962025, -1.395264655172414 ], [ 29.77431405063291, -1.395264655172414 ], [ 29.77431405063291, -1.394995172413793 ], [ 29.774044556962025, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1393, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.394995172413793 ], [ 29.77431405063291, -1.395264655172414 ], [ 29.774853037974683, -1.395264655172414 ], [ 29.774853037974683, -1.394995172413793 ], [ 29.77431405063291, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1394, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.394995172413793 ], [ 29.774853037974683, -1.395264655172414 ], [ 29.775122531645568, -1.395264655172414 ], [ 29.775122531645568, -1.394995172413793 ], [ 29.774853037974683, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1395, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.394995172413793 ], [ 29.775122531645568, -1.395264655172414 ], [ 29.775392025316457, -1.395264655172414 ], [ 29.775392025316457, -1.394995172413793 ], [ 29.775122531645568, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1396, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.394995172413793 ], [ 29.775392025316457, -1.395264655172414 ], [ 29.775661518987341, -1.395264655172414 ], [ 29.775661518987341, -1.394995172413793 ], [ 29.775392025316457, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1397, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.394995172413793 ], [ 29.775661518987341, -1.395264655172414 ], [ 29.775931012658226, -1.395264655172414 ], [ 29.775931012658226, -1.394995172413793 ], [ 29.775661518987341, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1398, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.394995172413793 ], [ 29.775931012658226, -1.395264655172414 ], [ 29.776200506329115, -1.395264655172414 ], [ 29.776200506329115, -1.394995172413793 ], [ 29.775931012658226, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1399, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.394995172413793 ], [ 29.776200506329115, -1.395264655172414 ], [ 29.77647, -1.395264655172414 ], [ 29.77647, -1.394995172413793 ], [ 29.776200506329115, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1400, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.394995172413793 ], [ 29.77647, -1.395264655172414 ], [ 29.776739493670885, -1.395264655172414 ], [ 29.776739493670885, -1.394995172413793 ], [ 29.77647, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1401, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.394995172413793 ], [ 29.776739493670885, -1.395264655172414 ], [ 29.777817468354431, -1.395264655172414 ], [ 29.777817468354431, -1.394995172413793 ], [ 29.776739493670885, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1402, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.394995172413793 ], [ 29.777817468354431, -1.395264655172414 ], [ 29.778086962025316, -1.395264655172414 ], [ 29.778086962025316, -1.394995172413793 ], [ 29.777817468354431, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1403, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.394995172413793 ], [ 29.778086962025316, -1.395264655172414 ], [ 29.778356455696201, -1.395264655172414 ], [ 29.778356455696201, -1.394995172413793 ], [ 29.778086962025316, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1404, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.394995172413793 ], [ 29.778356455696201, -1.395264655172414 ], [ 29.77862594936709, -1.395264655172414 ], [ 29.77862594936709, -1.394995172413793 ], [ 29.778356455696201, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1405, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.394995172413793 ], [ 29.77862594936709, -1.395264655172414 ], [ 29.778895443037975, -1.395264655172414 ], [ 29.778895443037975, -1.394995172413793 ], [ 29.77862594936709, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1406, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.394995172413793 ], [ 29.778895443037975, -1.395264655172414 ], [ 29.77916493670886, -1.395264655172414 ], [ 29.77916493670886, -1.394995172413793 ], [ 29.778895443037975, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1407, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.394995172413793 ], [ 29.77916493670886, -1.395264655172414 ], [ 29.779434430379748, -1.395264655172414 ], [ 29.779434430379748, -1.394995172413793 ], [ 29.77916493670886, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1408, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.394995172413793 ], [ 29.779434430379748, -1.395264655172414 ], [ 29.779703924050633, -1.395264655172414 ], [ 29.779703924050633, -1.394995172413793 ], [ 29.779434430379748, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1409, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.394995172413793 ], [ 29.779703924050633, -1.395264655172414 ], [ 29.779973417721518, -1.395264655172414 ], [ 29.779973417721518, -1.394995172413793 ], [ 29.779703924050633, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1410, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.394995172413793 ], [ 29.779973417721518, -1.395264655172414 ], [ 29.780242911392406, -1.395264655172414 ], [ 29.780242911392406, -1.394995172413793 ], [ 29.779973417721518, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1411, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.394995172413793 ], [ 29.780242911392406, -1.395264655172414 ], [ 29.780512405063291, -1.395264655172414 ], [ 29.780512405063291, -1.394995172413793 ], [ 29.780242911392406, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1412, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.394995172413793 ], [ 29.780512405063291, -1.395264655172414 ], [ 29.780781898734176, -1.395264655172414 ], [ 29.780781898734176, -1.394995172413793 ], [ 29.780512405063291, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1413, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.394995172413793 ], [ 29.780781898734176, -1.395264655172414 ], [ 29.781051392405065, -1.395264655172414 ], [ 29.781051392405065, -1.394995172413793 ], [ 29.780781898734176, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1414, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.394995172413793 ], [ 29.781051392405065, -1.395264655172414 ], [ 29.78132088607595, -1.395264655172414 ], [ 29.78132088607595, -1.394995172413793 ], [ 29.781051392405065, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1415, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.394995172413793 ], [ 29.78132088607595, -1.395264655172414 ], [ 29.782129367088608, -1.395264655172414 ], [ 29.782129367088608, -1.394995172413793 ], [ 29.78132088607595, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1416, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.394995172413793 ], [ 29.782129367088608, -1.395264655172414 ], [ 29.782398860759493, -1.395264655172414 ], [ 29.782398860759493, -1.394995172413793 ], [ 29.782129367088608, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1417, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.394995172413793 ], [ 29.782398860759493, -1.395264655172414 ], [ 29.782668354430381, -1.395264655172414 ], [ 29.782668354430381, -1.394995172413793 ], [ 29.782398860759493, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1418, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.394995172413793 ], [ 29.782668354430381, -1.395264655172414 ], [ 29.782937848101266, -1.395264655172414 ], [ 29.782937848101266, -1.394995172413793 ], [ 29.782668354430381, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1419, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.394995172413793 ], [ 29.782937848101266, -1.395264655172414 ], [ 29.783207341772151, -1.395264655172414 ], [ 29.783207341772151, -1.394995172413793 ], [ 29.782937848101266, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1420, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.394995172413793 ], [ 29.783207341772151, -1.395264655172414 ], [ 29.783476835443039, -1.395264655172414 ], [ 29.783476835443039, -1.394995172413793 ], [ 29.783207341772151, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1421, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.394995172413793 ], [ 29.783476835443039, -1.395264655172414 ], [ 29.784015822784809, -1.395264655172414 ], [ 29.784015822784809, -1.394995172413793 ], [ 29.783476835443039, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1422, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.394995172413793 ], [ 29.784015822784809, -1.395264655172414 ], [ 29.784285316455698, -1.395264655172414 ], [ 29.784285316455698, -1.394995172413793 ], [ 29.784015822784809, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1423, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.394995172413793 ], [ 29.784285316455698, -1.395264655172414 ], [ 29.784554810126583, -1.395264655172414 ], [ 29.784554810126583, -1.394995172413793 ], [ 29.784285316455698, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1424, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.394995172413793 ], [ 29.784824303797468, -1.395264655172414 ], [ 29.785093797468356, -1.395264655172414 ], [ 29.785093797468356, -1.394995172413793 ], [ 29.784824303797468, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1425, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.394995172413793 ], [ 29.785093797468356, -1.395264655172414 ], [ 29.785363291139241, -1.395264655172414 ], [ 29.785363291139241, -1.394995172413793 ], [ 29.785093797468356, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1426, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.394995172413793 ], [ 29.785363291139241, -1.395264655172414 ], [ 29.785632784810126, -1.395264655172414 ], [ 29.785632784810126, -1.394995172413793 ], [ 29.785363291139241, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1427, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.394995172413793 ], [ 29.785632784810126, -1.395264655172414 ], [ 29.785902278481014, -1.395264655172414 ], [ 29.785902278481014, -1.394995172413793 ], [ 29.785632784810126, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1428, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.394995172413793 ], [ 29.785902278481014, -1.395264655172414 ], [ 29.786171772151899, -1.395264655172414 ], [ 29.786171772151899, -1.394995172413793 ], [ 29.785902278481014, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1429, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.394995172413793 ], [ 29.786171772151899, -1.395264655172414 ], [ 29.786441265822784, -1.395264655172414 ], [ 29.786441265822784, -1.394995172413793 ], [ 29.786171772151899, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1430, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.394995172413793 ], [ 29.786441265822784, -1.395264655172414 ], [ 29.786710759493673, -1.395264655172414 ], [ 29.786710759493673, -1.394995172413793 ], [ 29.786441265822784, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1431, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.394995172413793 ], [ 29.786710759493673, -1.395264655172414 ], [ 29.786980253164558, -1.395264655172414 ], [ 29.786980253164558, -1.394995172413793 ], [ 29.786710759493673, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1432, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.394995172413793 ], [ 29.786980253164558, -1.395264655172414 ], [ 29.787249746835442, -1.395264655172414 ], [ 29.787249746835442, -1.394995172413793 ], [ 29.786980253164558, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1433, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.394995172413793 ], [ 29.787249746835442, -1.395264655172414 ], [ 29.787519240506327, -1.395264655172414 ], [ 29.787519240506327, -1.394995172413793 ], [ 29.787249746835442, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1434, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.394995172413793 ], [ 29.787519240506327, -1.395264655172414 ], [ 29.787788734177216, -1.395264655172414 ], [ 29.787788734177216, -1.394995172413793 ], [ 29.787519240506327, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1435, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.394995172413793 ], [ 29.787788734177216, -1.395264655172414 ], [ 29.788058227848101, -1.395264655172414 ], [ 29.788058227848101, -1.394995172413793 ], [ 29.787788734177216, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1436, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.394995172413793 ], [ 29.788058227848101, -1.395264655172414 ], [ 29.788327721518986, -1.395264655172414 ], [ 29.788327721518986, -1.394995172413793 ], [ 29.788058227848101, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1437, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.394995172413793 ], [ 29.788327721518986, -1.395264655172414 ], [ 29.788597215189874, -1.395264655172414 ], [ 29.788597215189874, -1.394995172413793 ], [ 29.788327721518986, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1438, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.394995172413793 ], [ 29.788597215189874, -1.395264655172414 ], [ 29.788866708860759, -1.395264655172414 ], [ 29.788866708860759, -1.394995172413793 ], [ 29.788597215189874, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1439, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.394995172413793 ], [ 29.788866708860759, -1.395264655172414 ], [ 29.789405696202532, -1.395264655172414 ], [ 29.789405696202532, -1.394995172413793 ], [ 29.788866708860759, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1440, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.394995172413793 ], [ 29.789405696202532, -1.395264655172414 ], [ 29.789675189873417, -1.395264655172414 ], [ 29.789675189873417, -1.394995172413793 ], [ 29.789405696202532, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1441, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.394995172413793 ], [ 29.789675189873417, -1.395264655172414 ], [ 29.789944683544302, -1.395264655172414 ], [ 29.789944683544302, -1.394995172413793 ], [ 29.789675189873417, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1442, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.394995172413793 ], [ 29.789944683544302, -1.395264655172414 ], [ 29.790214177215191, -1.395264655172414 ], [ 29.790214177215191, -1.394995172413793 ], [ 29.789944683544302, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1443, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.394995172413793 ], [ 29.790214177215191, -1.395264655172414 ], [ 29.790483670886076, -1.395264655172414 ], [ 29.790483670886076, -1.394995172413793 ], [ 29.790214177215191, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1444, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.394995172413793 ], [ 29.790483670886076, -1.395264655172414 ], [ 29.791292151898734, -1.395264655172414 ], [ 29.791292151898734, -1.394995172413793 ], [ 29.790483670886076, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1445, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.394995172413793 ], [ 29.791292151898734, -1.395264655172414 ], [ 29.791561645569619, -1.395264655172414 ], [ 29.791561645569619, -1.394995172413793 ], [ 29.791292151898734, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1446, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.394995172413793 ], [ 29.791561645569619, -1.395264655172414 ], [ 29.792370126582277, -1.395264655172414 ], [ 29.792370126582277, -1.394995172413793 ], [ 29.791561645569619, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1447, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.394995172413793 ], [ 29.792370126582277, -1.395264655172414 ], [ 29.792909113924051, -1.395264655172414 ], [ 29.792909113924051, -1.394995172413793 ], [ 29.792370126582277, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1448, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.394995172413793 ], [ 29.792909113924051, -1.395264655172414 ], [ 29.793448101265824, -1.395264655172414 ], [ 29.793448101265824, -1.394995172413793 ], [ 29.792909113924051, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1449, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.394995172413793 ], [ 29.793448101265824, -1.395264655172414 ], [ 29.793717594936709, -1.395264655172414 ], [ 29.793717594936709, -1.394995172413793 ], [ 29.793448101265824, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1450, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.394995172413793 ], [ 29.793717594936709, -1.395264655172414 ], [ 29.793987088607594, -1.395264655172414 ], [ 29.793987088607594, -1.394995172413793 ], [ 29.793717594936709, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1451, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.394995172413793 ], [ 29.793987088607594, -1.395264655172414 ], [ 29.794256582278482, -1.395264655172414 ], [ 29.794256582278482, -1.394995172413793 ], [ 29.793987088607594, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1452, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.394995172413793 ], [ 29.794256582278482, -1.395264655172414 ], [ 29.794526075949367, -1.395264655172414 ], [ 29.794526075949367, -1.394995172413793 ], [ 29.794256582278482, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1453, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.394995172413793 ], [ 29.794526075949367, -1.395264655172414 ], [ 29.794795569620252, -1.395264655172414 ], [ 29.794795569620252, -1.394995172413793 ], [ 29.794526075949367, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1454, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.394995172413793 ], [ 29.794795569620252, -1.395264655172414 ], [ 29.79506506329114, -1.395264655172414 ], [ 29.79506506329114, -1.394995172413793 ], [ 29.794795569620252, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1455, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.394995172413793 ], [ 29.79506506329114, -1.395264655172414 ], [ 29.795334556962025, -1.395264655172414 ], [ 29.795334556962025, -1.394995172413793 ], [ 29.79506506329114, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1456, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.394995172413793 ], [ 29.795334556962025, -1.395264655172414 ], [ 29.79560405063291, -1.395264655172414 ], [ 29.79560405063291, -1.394995172413793 ], [ 29.795334556962025, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1457, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.394995172413793 ], [ 29.79560405063291, -1.395264655172414 ], [ 29.795873544303799, -1.395264655172414 ], [ 29.795873544303799, -1.394995172413793 ], [ 29.79560405063291, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1458, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.394995172413793 ], [ 29.795873544303799, -1.395264655172414 ], [ 29.796412531645569, -1.395264655172414 ], [ 29.796412531645569, -1.394995172413793 ], [ 29.795873544303799, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1459, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.394995172413793 ], [ 29.796412531645569, -1.395264655172414 ], [ 29.796682025316457, -1.395264655172414 ], [ 29.796682025316457, -1.394995172413793 ], [ 29.796412531645569, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1460, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.394995172413793 ], [ 29.796682025316457, -1.395264655172414 ], [ 29.796951518987342, -1.395264655172414 ], [ 29.796951518987342, -1.394995172413793 ], [ 29.796682025316457, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1461, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.394995172413793 ], [ 29.796951518987342, -1.395264655172414 ], [ 29.797221012658227, -1.395264655172414 ], [ 29.797221012658227, -1.394995172413793 ], [ 29.796951518987342, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1462, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.394995172413793 ], [ 29.797221012658227, -1.395264655172414 ], [ 29.797490506329115, -1.395264655172414 ], [ 29.797490506329115, -1.394995172413793 ], [ 29.797221012658227, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1463, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.394995172413793 ], [ 29.797490506329115, -1.395264655172414 ], [ 29.79776, -1.395264655172414 ], [ 29.79776, -1.394995172413793 ], [ 29.797490506329115, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1464, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.395264655172414 ], [ 29.768115696202532, -1.395803620689655 ], [ 29.768385189873417, -1.395803620689655 ], [ 29.768385189873417, -1.395264655172414 ], [ 29.768115696202532, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1465, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.395264655172414 ], [ 29.768385189873417, -1.395534137931035 ], [ 29.768654683544302, -1.395534137931035 ], [ 29.768654683544302, -1.395264655172414 ], [ 29.768385189873417, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1466, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.395264655172414 ], [ 29.768654683544302, -1.395534137931035 ], [ 29.76892417721519, -1.395534137931035 ], [ 29.76892417721519, -1.395264655172414 ], [ 29.768654683544302, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1467, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.395264655172414 ], [ 29.76892417721519, -1.395534137931035 ], [ 29.769193670886075, -1.395534137931035 ], [ 29.769193670886075, -1.395264655172414 ], [ 29.76892417721519, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1468, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.395264655172414 ], [ 29.769193670886075, -1.395534137931035 ], [ 29.76946316455696, -1.395534137931035 ], [ 29.76946316455696, -1.395264655172414 ], [ 29.769193670886075, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1469, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.395264655172414 ], [ 29.76946316455696, -1.395534137931035 ], [ 29.769732658227849, -1.395534137931035 ], [ 29.769732658227849, -1.395264655172414 ], [ 29.76946316455696, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1470, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.395264655172414 ], [ 29.769732658227849, -1.395534137931035 ], [ 29.770002151898733, -1.395534137931035 ], [ 29.770002151898733, -1.395264655172414 ], [ 29.769732658227849, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1471, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.395264655172414 ], [ 29.770002151898733, -1.395534137931035 ], [ 29.770271645569618, -1.395534137931035 ], [ 29.770271645569618, -1.395264655172414 ], [ 29.770002151898733, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1472, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.395264655172414 ], [ 29.770271645569618, -1.395534137931035 ], [ 29.770541139240507, -1.395534137931035 ], [ 29.770541139240507, -1.395264655172414 ], [ 29.770271645569618, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1473, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.395264655172414 ], [ 29.770541139240507, -1.395534137931035 ], [ 29.770810632911392, -1.395534137931035 ], [ 29.770810632911392, -1.395264655172414 ], [ 29.770541139240507, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1474, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.395264655172414 ], [ 29.770810632911392, -1.395534137931035 ], [ 29.771080126582277, -1.395534137931035 ], [ 29.771080126582277, -1.395264655172414 ], [ 29.770810632911392, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1475, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.395264655172414 ], [ 29.771080126582277, -1.395534137931035 ], [ 29.771349620253165, -1.395534137931035 ], [ 29.771349620253165, -1.395264655172414 ], [ 29.771080126582277, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1476, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.395264655172414 ], [ 29.771349620253165, -1.395534137931035 ], [ 29.77161911392405, -1.395534137931035 ], [ 29.77161911392405, -1.395264655172414 ], [ 29.771349620253165, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1477, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.395264655172414 ], [ 29.77161911392405, -1.395534137931035 ], [ 29.771888607594935, -1.395534137931035 ], [ 29.771888607594935, -1.395264655172414 ], [ 29.77161911392405, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1478, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.395264655172414 ], [ 29.771888607594935, -1.395534137931035 ], [ 29.772158101265823, -1.395534137931035 ], [ 29.772158101265823, -1.395264655172414 ], [ 29.771888607594935, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1479, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.395264655172414 ], [ 29.772158101265823, -1.395534137931035 ], [ 29.772427594936708, -1.395534137931035 ], [ 29.772427594936708, -1.395264655172414 ], [ 29.772158101265823, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1480, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.395264655172414 ], [ 29.772427594936708, -1.395534137931035 ], [ 29.772697088607593, -1.395534137931035 ], [ 29.772697088607593, -1.395264655172414 ], [ 29.772427594936708, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1481, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.395264655172414 ], [ 29.772697088607593, -1.395534137931035 ], [ 29.772966582278482, -1.395534137931035 ], [ 29.772966582278482, -1.395264655172414 ], [ 29.772697088607593, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1482, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.395264655172414 ], [ 29.772966582278482, -1.395534137931035 ], [ 29.773236075949367, -1.395534137931035 ], [ 29.773236075949367, -1.395264655172414 ], [ 29.772966582278482, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1483, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.395264655172414 ], [ 29.773236075949367, -1.395534137931035 ], [ 29.773505569620252, -1.395534137931035 ], [ 29.773505569620252, -1.395264655172414 ], [ 29.773236075949367, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1484, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.395264655172414 ], [ 29.773505569620252, -1.395534137931035 ], [ 29.77377506329114, -1.395534137931035 ], [ 29.77377506329114, -1.395264655172414 ], [ 29.773505569620252, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1485, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.395264655172414 ], [ 29.77377506329114, -1.395534137931035 ], [ 29.774044556962025, -1.395534137931035 ], [ 29.774044556962025, -1.395264655172414 ], [ 29.77377506329114, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1486, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.395264655172414 ], [ 29.774044556962025, -1.395534137931035 ], [ 29.77431405063291, -1.395534137931035 ], [ 29.77431405063291, -1.395264655172414 ], [ 29.774044556962025, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1487, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.395264655172414 ], [ 29.77431405063291, -1.395534137931035 ], [ 29.774853037974683, -1.395534137931035 ], [ 29.774853037974683, -1.395264655172414 ], [ 29.77431405063291, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1488, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.395264655172414 ], [ 29.774853037974683, -1.395534137931035 ], [ 29.775122531645568, -1.395534137931035 ], [ 29.775122531645568, -1.395264655172414 ], [ 29.774853037974683, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1489, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.395264655172414 ], [ 29.775122531645568, -1.395534137931035 ], [ 29.775392025316457, -1.395534137931035 ], [ 29.775392025316457, -1.395264655172414 ], [ 29.775122531645568, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1490, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.395264655172414 ], [ 29.775392025316457, -1.395534137931035 ], [ 29.775661518987341, -1.395534137931035 ], [ 29.775661518987341, -1.395264655172414 ], [ 29.775392025316457, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1491, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.395264655172414 ], [ 29.775661518987341, -1.395534137931035 ], [ 29.775931012658226, -1.395534137931035 ], [ 29.775931012658226, -1.395264655172414 ], [ 29.775661518987341, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1492, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.395264655172414 ], [ 29.775931012658226, -1.395534137931035 ], [ 29.776200506329115, -1.395534137931035 ], [ 29.776200506329115, -1.395264655172414 ], [ 29.775931012658226, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1493, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.395264655172414 ], [ 29.776200506329115, -1.395534137931035 ], [ 29.77647, -1.395534137931035 ], [ 29.77647, -1.395264655172414 ], [ 29.776200506329115, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1494, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.395264655172414 ], [ 29.77647, -1.395534137931035 ], [ 29.776739493670885, -1.395534137931035 ], [ 29.776739493670885, -1.395264655172414 ], [ 29.77647, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1495, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.395264655172414 ], [ 29.776739493670885, -1.395534137931035 ], [ 29.777817468354431, -1.395534137931035 ], [ 29.777817468354431, -1.395264655172414 ], [ 29.776739493670885, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1496, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.395264655172414 ], [ 29.777817468354431, -1.395534137931035 ], [ 29.778086962025316, -1.395534137931035 ], [ 29.778086962025316, -1.395264655172414 ], [ 29.777817468354431, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1497, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.395264655172414 ], [ 29.778086962025316, -1.395534137931035 ], [ 29.778356455696201, -1.395534137931035 ], [ 29.778356455696201, -1.395264655172414 ], [ 29.778086962025316, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1498, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.395264655172414 ], [ 29.778356455696201, -1.395534137931035 ], [ 29.77862594936709, -1.395534137931035 ], [ 29.77862594936709, -1.395264655172414 ], [ 29.778356455696201, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1499, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.395264655172414 ], [ 29.77862594936709, -1.395534137931035 ], [ 29.778895443037975, -1.395534137931035 ], [ 29.778895443037975, -1.395264655172414 ], [ 29.77862594936709, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1500, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.395264655172414 ], [ 29.778895443037975, -1.395534137931035 ], [ 29.77916493670886, -1.395534137931035 ], [ 29.77916493670886, -1.395264655172414 ], [ 29.778895443037975, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1501, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.395264655172414 ], [ 29.77916493670886, -1.395534137931035 ], [ 29.779434430379748, -1.395534137931035 ], [ 29.779434430379748, -1.395264655172414 ], [ 29.77916493670886, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1502, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.395264655172414 ], [ 29.779434430379748, -1.395534137931035 ], [ 29.779703924050633, -1.395534137931035 ], [ 29.779703924050633, -1.395264655172414 ], [ 29.779434430379748, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1503, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.395264655172414 ], [ 29.779703924050633, -1.395534137931035 ], [ 29.779973417721518, -1.395534137931035 ], [ 29.779973417721518, -1.395264655172414 ], [ 29.779703924050633, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1504, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.395264655172414 ], [ 29.779973417721518, -1.395534137931035 ], [ 29.780242911392406, -1.395534137931035 ], [ 29.780242911392406, -1.395264655172414 ], [ 29.779973417721518, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1505, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.395264655172414 ], [ 29.780242911392406, -1.395534137931035 ], [ 29.780512405063291, -1.395534137931035 ], [ 29.780512405063291, -1.395264655172414 ], [ 29.780242911392406, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1506, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.395264655172414 ], [ 29.780512405063291, -1.395534137931035 ], [ 29.780781898734176, -1.395534137931035 ], [ 29.780781898734176, -1.395264655172414 ], [ 29.780512405063291, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1507, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.395264655172414 ], [ 29.780781898734176, -1.395534137931035 ], [ 29.781051392405065, -1.395534137931035 ], [ 29.781051392405065, -1.395264655172414 ], [ 29.780781898734176, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1508, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.395264655172414 ], [ 29.781051392405065, -1.395534137931035 ], [ 29.78132088607595, -1.395534137931035 ], [ 29.78132088607595, -1.395264655172414 ], [ 29.781051392405065, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1509, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.395264655172414 ], [ 29.78132088607595, -1.395534137931035 ], [ 29.782129367088608, -1.395534137931035 ], [ 29.782129367088608, -1.395264655172414 ], [ 29.78132088607595, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1510, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.395264655172414 ], [ 29.782129367088608, -1.395534137931035 ], [ 29.782398860759493, -1.395534137931035 ], [ 29.782398860759493, -1.395264655172414 ], [ 29.782129367088608, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1511, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.395264655172414 ], [ 29.782398860759493, -1.395534137931035 ], [ 29.782668354430381, -1.395534137931035 ], [ 29.782668354430381, -1.395264655172414 ], [ 29.782398860759493, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1512, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.395264655172414 ], [ 29.782668354430381, -1.395534137931035 ], [ 29.782937848101266, -1.395534137931035 ], [ 29.782937848101266, -1.395264655172414 ], [ 29.782668354430381, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1513, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.395264655172414 ], [ 29.782937848101266, -1.395534137931035 ], [ 29.783207341772151, -1.395534137931035 ], [ 29.783207341772151, -1.395264655172414 ], [ 29.782937848101266, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1514, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.395264655172414 ], [ 29.783207341772151, -1.395534137931035 ], [ 29.783476835443039, -1.395534137931035 ], [ 29.783476835443039, -1.395264655172414 ], [ 29.783207341772151, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1515, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.395264655172414 ], [ 29.784015822784809, -1.395534137931035 ], [ 29.784285316455698, -1.395534137931035 ], [ 29.784285316455698, -1.395264655172414 ], [ 29.784015822784809, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1516, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.394995172413793 ], [ 29.784824303797468, -1.394995172413793 ], [ 29.784824303797468, -1.395534137931035 ], [ 29.784285316455698, -1.395534137931035 ], [ 29.784285316455698, -1.395264655172414 ], [ 29.784554810126583, -1.395264655172414 ], [ 29.784554810126583, -1.394995172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1517, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.395264655172414 ], [ 29.784824303797468, -1.395534137931035 ], [ 29.785093797468356, -1.395534137931035 ], [ 29.785093797468356, -1.395264655172414 ], [ 29.784824303797468, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1518, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.395264655172414 ], [ 29.785093797468356, -1.395534137931035 ], [ 29.785632784810126, -1.395534137931035 ], [ 29.785632784810126, -1.395264655172414 ], [ 29.785093797468356, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1519, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.395264655172414 ], [ 29.785632784810126, -1.395534137931035 ], [ 29.785902278481014, -1.395534137931035 ], [ 29.785902278481014, -1.395264655172414 ], [ 29.785632784810126, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1520, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.395264655172414 ], [ 29.785902278481014, -1.395534137931035 ], [ 29.786171772151899, -1.395534137931035 ], [ 29.786171772151899, -1.395264655172414 ], [ 29.785902278481014, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1521, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.395264655172414 ], [ 29.786171772151899, -1.395534137931035 ], [ 29.786441265822784, -1.395534137931035 ], [ 29.786441265822784, -1.395264655172414 ], [ 29.786171772151899, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1522, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.395264655172414 ], [ 29.786441265822784, -1.395534137931035 ], [ 29.786710759493673, -1.395534137931035 ], [ 29.786710759493673, -1.395264655172414 ], [ 29.786441265822784, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1523, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.395264655172414 ], [ 29.786710759493673, -1.395534137931035 ], [ 29.786980253164558, -1.395534137931035 ], [ 29.786980253164558, -1.395264655172414 ], [ 29.786710759493673, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1524, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.395264655172414 ], [ 29.786980253164558, -1.395534137931035 ], [ 29.787249746835442, -1.395534137931035 ], [ 29.787249746835442, -1.395264655172414 ], [ 29.786980253164558, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1525, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.395264655172414 ], [ 29.787249746835442, -1.395534137931035 ], [ 29.787519240506327, -1.395534137931035 ], [ 29.787519240506327, -1.395264655172414 ], [ 29.787249746835442, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1526, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.395264655172414 ], [ 29.787519240506327, -1.395534137931035 ], [ 29.787788734177216, -1.395534137931035 ], [ 29.787788734177216, -1.395264655172414 ], [ 29.787519240506327, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1527, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.395264655172414 ], [ 29.787788734177216, -1.395534137931035 ], [ 29.788058227848101, -1.395534137931035 ], [ 29.788058227848101, -1.395264655172414 ], [ 29.787788734177216, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1528, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.395264655172414 ], [ 29.788058227848101, -1.395534137931035 ], [ 29.788327721518986, -1.395534137931035 ], [ 29.788327721518986, -1.395264655172414 ], [ 29.788058227848101, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1529, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.395264655172414 ], [ 29.788327721518986, -1.395534137931035 ], [ 29.788597215189874, -1.395534137931035 ], [ 29.788597215189874, -1.395264655172414 ], [ 29.788327721518986, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1530, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.395264655172414 ], [ 29.788597215189874, -1.395534137931035 ], [ 29.788866708860759, -1.395534137931035 ], [ 29.788866708860759, -1.395264655172414 ], [ 29.788597215189874, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1531, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.395264655172414 ], [ 29.788866708860759, -1.395534137931035 ], [ 29.789405696202532, -1.395534137931035 ], [ 29.789405696202532, -1.395264655172414 ], [ 29.788866708860759, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1532, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.395264655172414 ], [ 29.789405696202532, -1.395534137931035 ], [ 29.789675189873417, -1.395534137931035 ], [ 29.789675189873417, -1.395264655172414 ], [ 29.789405696202532, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1533, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.395264655172414 ], [ 29.789675189873417, -1.395534137931035 ], [ 29.789944683544302, -1.395534137931035 ], [ 29.789944683544302, -1.395264655172414 ], [ 29.789675189873417, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1534, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.395264655172414 ], [ 29.789944683544302, -1.395534137931035 ], [ 29.790214177215191, -1.395534137931035 ], [ 29.790214177215191, -1.395264655172414 ], [ 29.789944683544302, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1535, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.395264655172414 ], [ 29.790214177215191, -1.395534137931035 ], [ 29.791022658227849, -1.395534137931035 ], [ 29.791022658227849, -1.395264655172414 ], [ 29.790214177215191, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1536, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.395264655172414 ], [ 29.791022658227849, -1.395534137931035 ], [ 29.791292151898734, -1.395534137931035 ], [ 29.791292151898734, -1.395264655172414 ], [ 29.791022658227849, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1537, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.395264655172414 ], [ 29.791292151898734, -1.395534137931035 ], [ 29.791561645569619, -1.395534137931035 ], [ 29.791561645569619, -1.395264655172414 ], [ 29.791292151898734, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1538, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.395264655172414 ], [ 29.791561645569619, -1.395534137931035 ], [ 29.792370126582277, -1.395534137931035 ], [ 29.792370126582277, -1.395264655172414 ], [ 29.791561645569619, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1539, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.395264655172414 ], [ 29.792370126582277, -1.395534137931035 ], [ 29.792909113924051, -1.395534137931035 ], [ 29.792909113924051, -1.395264655172414 ], [ 29.792370126582277, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1540, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.395264655172414 ], [ 29.792909113924051, -1.395534137931035 ], [ 29.793178607594935, -1.395534137931035 ], [ 29.793178607594935, -1.395264655172414 ], [ 29.792909113924051, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1541, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.395264655172414 ], [ 29.793178607594935, -1.395534137931035 ], [ 29.793448101265824, -1.395534137931035 ], [ 29.793448101265824, -1.395264655172414 ], [ 29.793178607594935, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1542, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.395264655172414 ], [ 29.793448101265824, -1.395534137931035 ], [ 29.793987088607594, -1.395534137931035 ], [ 29.793987088607594, -1.395264655172414 ], [ 29.793448101265824, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1543, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.395264655172414 ], [ 29.793987088607594, -1.395534137931035 ], [ 29.794256582278482, -1.395534137931035 ], [ 29.794256582278482, -1.395264655172414 ], [ 29.793987088607594, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1544, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.395264655172414 ], [ 29.794256582278482, -1.395803620689655 ], [ 29.794526075949367, -1.395803620689655 ], [ 29.794526075949367, -1.395264655172414 ], [ 29.794256582278482, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1545, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.395264655172414 ], [ 29.794526075949367, -1.395803620689655 ], [ 29.794795569620252, -1.395803620689655 ], [ 29.794795569620252, -1.395264655172414 ], [ 29.794526075949367, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1546, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.395264655172414 ], [ 29.794795569620252, -1.395803620689655 ], [ 29.796412531645569, -1.395803620689655 ], [ 29.796412531645569, -1.395534137931035 ], [ 29.79560405063291, -1.395534137931035 ], [ 29.79560405063291, -1.395264655172414 ], [ 29.794795569620252, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1547, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.395264655172414 ], [ 29.79560405063291, -1.395534137931035 ], [ 29.795873544303799, -1.395534137931035 ], [ 29.795873544303799, -1.395264655172414 ], [ 29.79560405063291, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1548, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.395264655172414 ], [ 29.795873544303799, -1.395534137931035 ], [ 29.796412531645569, -1.395534137931035 ], [ 29.796412531645569, -1.395264655172414 ], [ 29.795873544303799, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1549, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.395264655172414 ], [ 29.796412531645569, -1.395534137931035 ], [ 29.796682025316457, -1.395534137931035 ], [ 29.796682025316457, -1.395264655172414 ], [ 29.796412531645569, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1550, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.395264655172414 ], [ 29.796682025316457, -1.395534137931035 ], [ 29.796951518987342, -1.395534137931035 ], [ 29.796951518987342, -1.395264655172414 ], [ 29.796682025316457, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1551, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.395264655172414 ], [ 29.796951518987342, -1.395534137931035 ], [ 29.797221012658227, -1.395534137931035 ], [ 29.797221012658227, -1.395264655172414 ], [ 29.796951518987342, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1552, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.395264655172414 ], [ 29.797221012658227, -1.395534137931035 ], [ 29.797490506329115, -1.395534137931035 ], [ 29.797490506329115, -1.395264655172414 ], [ 29.797221012658227, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1553, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.395264655172414 ], [ 29.797490506329115, -1.395534137931035 ], [ 29.79776, -1.395534137931035 ], [ 29.79776, -1.395264655172414 ], [ 29.797490506329115, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1554, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.395264655172414 ], [ 29.79776, -1.395803620689655 ], [ 29.798029493670885, -1.395803620689655 ], [ 29.798029493670885, -1.395264655172414 ], [ 29.79776, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1555, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.395534137931035 ], [ 29.768385189873417, -1.395803620689655 ], [ 29.768654683544302, -1.395803620689655 ], [ 29.768654683544302, -1.395534137931035 ], [ 29.768385189873417, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1556, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.395534137931035 ], [ 29.768654683544302, -1.395803620689655 ], [ 29.76892417721519, -1.395803620689655 ], [ 29.76892417721519, -1.395534137931035 ], [ 29.768654683544302, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1557, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.395534137931035 ], [ 29.76892417721519, -1.395803620689655 ], [ 29.769193670886075, -1.395803620689655 ], [ 29.769193670886075, -1.395534137931035 ], [ 29.76892417721519, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1558, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.395534137931035 ], [ 29.769193670886075, -1.395803620689655 ], [ 29.76946316455696, -1.395803620689655 ], [ 29.76946316455696, -1.395534137931035 ], [ 29.769193670886075, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1559, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.395534137931035 ], [ 29.76946316455696, -1.395803620689655 ], [ 29.769732658227849, -1.395803620689655 ], [ 29.769732658227849, -1.395534137931035 ], [ 29.76946316455696, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1560, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.395534137931035 ], [ 29.769732658227849, -1.395803620689655 ], [ 29.770002151898733, -1.395803620689655 ], [ 29.770002151898733, -1.395534137931035 ], [ 29.769732658227849, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1561, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.395534137931035 ], [ 29.770002151898733, -1.395803620689655 ], [ 29.770271645569618, -1.395803620689655 ], [ 29.770271645569618, -1.395534137931035 ], [ 29.770002151898733, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1562, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.395534137931035 ], [ 29.770271645569618, -1.395803620689655 ], [ 29.770541139240507, -1.395803620689655 ], [ 29.770541139240507, -1.395534137931035 ], [ 29.770271645569618, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1563, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.395534137931035 ], [ 29.770541139240507, -1.395803620689655 ], [ 29.770810632911392, -1.395803620689655 ], [ 29.770810632911392, -1.395534137931035 ], [ 29.770541139240507, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1564, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.395534137931035 ], [ 29.770810632911392, -1.395803620689655 ], [ 29.771080126582277, -1.395803620689655 ], [ 29.771080126582277, -1.395534137931035 ], [ 29.770810632911392, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1565, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.395534137931035 ], [ 29.771080126582277, -1.395803620689655 ], [ 29.771349620253165, -1.395803620689655 ], [ 29.771349620253165, -1.395534137931035 ], [ 29.771080126582277, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1566, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.395534137931035 ], [ 29.771349620253165, -1.395803620689655 ], [ 29.77161911392405, -1.395803620689655 ], [ 29.77161911392405, -1.395534137931035 ], [ 29.771349620253165, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1567, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.395534137931035 ], [ 29.77161911392405, -1.395803620689655 ], [ 29.771888607594935, -1.395803620689655 ], [ 29.771888607594935, -1.395534137931035 ], [ 29.77161911392405, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1568, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.395534137931035 ], [ 29.771888607594935, -1.395803620689655 ], [ 29.772158101265823, -1.395803620689655 ], [ 29.772158101265823, -1.395534137931035 ], [ 29.771888607594935, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1569, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.395534137931035 ], [ 29.772158101265823, -1.395803620689655 ], [ 29.772427594936708, -1.395803620689655 ], [ 29.772427594936708, -1.395534137931035 ], [ 29.772158101265823, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1570, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.395534137931035 ], [ 29.772427594936708, -1.395803620689655 ], [ 29.772697088607593, -1.395803620689655 ], [ 29.772697088607593, -1.395534137931035 ], [ 29.772427594936708, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1571, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.395534137931035 ], [ 29.772697088607593, -1.395803620689655 ], [ 29.772966582278482, -1.395803620689655 ], [ 29.772966582278482, -1.395534137931035 ], [ 29.772697088607593, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1572, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.395534137931035 ], [ 29.772966582278482, -1.395803620689655 ], [ 29.773236075949367, -1.395803620689655 ], [ 29.773236075949367, -1.395534137931035 ], [ 29.772966582278482, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1573, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.395534137931035 ], [ 29.773236075949367, -1.395803620689655 ], [ 29.773505569620252, -1.395803620689655 ], [ 29.773505569620252, -1.395534137931035 ], [ 29.773236075949367, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1574, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.395534137931035 ], [ 29.773505569620252, -1.395803620689655 ], [ 29.77377506329114, -1.395803620689655 ], [ 29.77377506329114, -1.395534137931035 ], [ 29.773505569620252, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1575, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.395534137931035 ], [ 29.77377506329114, -1.395803620689655 ], [ 29.774044556962025, -1.395803620689655 ], [ 29.774044556962025, -1.395534137931035 ], [ 29.77377506329114, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1576, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.395534137931035 ], [ 29.774044556962025, -1.395803620689655 ], [ 29.77431405063291, -1.395803620689655 ], [ 29.77431405063291, -1.395534137931035 ], [ 29.774044556962025, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1577, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.395534137931035 ], [ 29.77431405063291, -1.395803620689655 ], [ 29.774853037974683, -1.395803620689655 ], [ 29.774853037974683, -1.395534137931035 ], [ 29.77431405063291, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1578, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.395534137931035 ], [ 29.774853037974683, -1.395803620689655 ], [ 29.775122531645568, -1.395803620689655 ], [ 29.775122531645568, -1.395534137931035 ], [ 29.774853037974683, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1579, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.395534137931035 ], [ 29.775122531645568, -1.395803620689655 ], [ 29.775392025316457, -1.395803620689655 ], [ 29.775392025316457, -1.395534137931035 ], [ 29.775122531645568, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1580, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.395534137931035 ], [ 29.775392025316457, -1.395803620689655 ], [ 29.775661518987341, -1.395803620689655 ], [ 29.775661518987341, -1.395534137931035 ], [ 29.775392025316457, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1581, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.395534137931035 ], [ 29.775661518987341, -1.395803620689655 ], [ 29.775931012658226, -1.395803620689655 ], [ 29.775931012658226, -1.395534137931035 ], [ 29.775661518987341, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1582, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.395534137931035 ], [ 29.775931012658226, -1.395803620689655 ], [ 29.776200506329115, -1.395803620689655 ], [ 29.776200506329115, -1.395534137931035 ], [ 29.775931012658226, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1583, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.395534137931035 ], [ 29.776200506329115, -1.395803620689655 ], [ 29.77647, -1.395803620689655 ], [ 29.77647, -1.395534137931035 ], [ 29.776200506329115, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1584, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.395534137931035 ], [ 29.77647, -1.395803620689655 ], [ 29.778086962025316, -1.395803620689655 ], [ 29.778086962025316, -1.395534137931035 ], [ 29.77647, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1585, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.395534137931035 ], [ 29.778086962025316, -1.395803620689655 ], [ 29.778356455696201, -1.395803620689655 ], [ 29.778356455696201, -1.395534137931035 ], [ 29.778086962025316, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1586, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.395534137931035 ], [ 29.778356455696201, -1.395803620689655 ], [ 29.77862594936709, -1.395803620689655 ], [ 29.77862594936709, -1.395534137931035 ], [ 29.778356455696201, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1587, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.395534137931035 ], [ 29.77862594936709, -1.395803620689655 ], [ 29.778895443037975, -1.395803620689655 ], [ 29.778895443037975, -1.395534137931035 ], [ 29.77862594936709, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1588, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.395534137931035 ], [ 29.778895443037975, -1.395803620689655 ], [ 29.77916493670886, -1.395803620689655 ], [ 29.77916493670886, -1.395534137931035 ], [ 29.778895443037975, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1589, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.395534137931035 ], [ 29.77916493670886, -1.395803620689655 ], [ 29.779434430379748, -1.395803620689655 ], [ 29.779434430379748, -1.395534137931035 ], [ 29.77916493670886, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1590, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.395534137931035 ], [ 29.779434430379748, -1.395803620689655 ], [ 29.779703924050633, -1.395803620689655 ], [ 29.779703924050633, -1.395534137931035 ], [ 29.779434430379748, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1591, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.395534137931035 ], [ 29.779703924050633, -1.395803620689655 ], [ 29.779973417721518, -1.395803620689655 ], [ 29.779973417721518, -1.395534137931035 ], [ 29.779703924050633, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1592, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.395534137931035 ], [ 29.779973417721518, -1.395803620689655 ], [ 29.780242911392406, -1.395803620689655 ], [ 29.780242911392406, -1.395534137931035 ], [ 29.779973417721518, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1593, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.395534137931035 ], [ 29.780242911392406, -1.395803620689655 ], [ 29.780512405063291, -1.395803620689655 ], [ 29.780512405063291, -1.395534137931035 ], [ 29.780242911392406, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1594, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.395534137931035 ], [ 29.780512405063291, -1.395803620689655 ], [ 29.780781898734176, -1.395803620689655 ], [ 29.780781898734176, -1.395534137931035 ], [ 29.780512405063291, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1595, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.395534137931035 ], [ 29.780781898734176, -1.395803620689655 ], [ 29.781051392405065, -1.395803620689655 ], [ 29.781051392405065, -1.395534137931035 ], [ 29.780781898734176, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1596, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.395534137931035 ], [ 29.781051392405065, -1.395803620689655 ], [ 29.781590379746834, -1.395803620689655 ], [ 29.781590379746834, -1.395534137931035 ], [ 29.781051392405065, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1597, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.395534137931035 ], [ 29.781590379746834, -1.395803620689655 ], [ 29.781859873417723, -1.395803620689655 ], [ 29.781859873417723, -1.395534137931035 ], [ 29.781590379746834, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1598, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.395534137931035 ], [ 29.781859873417723, -1.395803620689655 ], [ 29.782398860759493, -1.395803620689655 ], [ 29.782398860759493, -1.395534137931035 ], [ 29.781859873417723, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1599, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.395534137931035 ], [ 29.782398860759493, -1.395803620689655 ], [ 29.782668354430381, -1.395803620689655 ], [ 29.782668354430381, -1.395534137931035 ], [ 29.782398860759493, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1600, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.395534137931035 ], [ 29.782668354430381, -1.395803620689655 ], [ 29.782937848101266, -1.395803620689655 ], [ 29.782937848101266, -1.395534137931035 ], [ 29.782668354430381, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1601, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.395534137931035 ], [ 29.782937848101266, -1.395803620689655 ], [ 29.783207341772151, -1.395803620689655 ], [ 29.783207341772151, -1.395534137931035 ], [ 29.782937848101266, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1602, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.395264655172414 ], [ 29.784015822784809, -1.395264655172414 ], [ 29.784015822784809, -1.395534137931035 ], [ 29.784554810126583, -1.395534137931035 ], [ 29.784554810126583, -1.395803620689655 ], [ 29.783207341772151, -1.395803620689655 ], [ 29.783207341772151, -1.395534137931035 ], [ 29.783476835443039, -1.395534137931035 ], [ 29.783476835443039, -1.395264655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1603, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.395534137931035 ], [ 29.784554810126583, -1.395803620689655 ], [ 29.784824303797468, -1.395803620689655 ], [ 29.784824303797468, -1.395534137931035 ], [ 29.784554810126583, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1604, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.395534137931035 ], [ 29.784824303797468, -1.395803620689655 ], [ 29.785093797468356, -1.395803620689655 ], [ 29.785093797468356, -1.395534137931035 ], [ 29.784824303797468, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1605, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.395534137931035 ], [ 29.785093797468356, -1.395803620689655 ], [ 29.785363291139241, -1.395803620689655 ], [ 29.785363291139241, -1.395534137931035 ], [ 29.785093797468356, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1606, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.395534137931035 ], [ 29.785363291139241, -1.395803620689655 ], [ 29.785632784810126, -1.395803620689655 ], [ 29.785632784810126, -1.395534137931035 ], [ 29.785363291139241, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1607, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.395534137931035 ], [ 29.785632784810126, -1.395803620689655 ], [ 29.785902278481014, -1.395803620689655 ], [ 29.785902278481014, -1.395534137931035 ], [ 29.785632784810126, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1608, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.395534137931035 ], [ 29.785902278481014, -1.395803620689655 ], [ 29.786171772151899, -1.395803620689655 ], [ 29.786171772151899, -1.395534137931035 ], [ 29.785902278481014, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1609, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.395534137931035 ], [ 29.786171772151899, -1.395803620689655 ], [ 29.786441265822784, -1.395803620689655 ], [ 29.786441265822784, -1.395534137931035 ], [ 29.786171772151899, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1610, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.395534137931035 ], [ 29.786441265822784, -1.395803620689655 ], [ 29.786710759493673, -1.395803620689655 ], [ 29.786710759493673, -1.395534137931035 ], [ 29.786441265822784, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1611, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.395534137931035 ], [ 29.786710759493673, -1.395803620689655 ], [ 29.786980253164558, -1.395803620689655 ], [ 29.786980253164558, -1.395534137931035 ], [ 29.786710759493673, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1612, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.395534137931035 ], [ 29.786980253164558, -1.395803620689655 ], [ 29.787249746835442, -1.395803620689655 ], [ 29.787249746835442, -1.395534137931035 ], [ 29.786980253164558, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1613, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.395534137931035 ], [ 29.787249746835442, -1.395803620689655 ], [ 29.787519240506327, -1.395803620689655 ], [ 29.787519240506327, -1.395534137931035 ], [ 29.787249746835442, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1614, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.395534137931035 ], [ 29.787519240506327, -1.395803620689655 ], [ 29.787788734177216, -1.395803620689655 ], [ 29.787788734177216, -1.395534137931035 ], [ 29.787519240506327, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1615, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.395534137931035 ], [ 29.787788734177216, -1.395803620689655 ], [ 29.788058227848101, -1.395803620689655 ], [ 29.788058227848101, -1.395534137931035 ], [ 29.787788734177216, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1616, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.395534137931035 ], [ 29.788058227848101, -1.395803620689655 ], [ 29.788327721518986, -1.395803620689655 ], [ 29.788327721518986, -1.395534137931035 ], [ 29.788058227848101, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1617, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.395534137931035 ], [ 29.788327721518986, -1.395803620689655 ], [ 29.788866708860759, -1.395803620689655 ], [ 29.788866708860759, -1.395534137931035 ], [ 29.788327721518986, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1618, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.395534137931035 ], [ 29.789405696202532, -1.395803620689655 ], [ 29.789944683544302, -1.395803620689655 ], [ 29.789944683544302, -1.395534137931035 ], [ 29.789405696202532, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1619, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.395534137931035 ], [ 29.789944683544302, -1.395803620689655 ], [ 29.790214177215191, -1.395803620689655 ], [ 29.790214177215191, -1.395534137931035 ], [ 29.789944683544302, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1620, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.395534137931035 ], [ 29.790214177215191, -1.395803620689655 ], [ 29.791022658227849, -1.395803620689655 ], [ 29.791022658227849, -1.395534137931035 ], [ 29.790214177215191, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1621, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.395534137931035 ], [ 29.791022658227849, -1.395803620689655 ], [ 29.791292151898734, -1.395803620689655 ], [ 29.791292151898734, -1.395534137931035 ], [ 29.791022658227849, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1622, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.395534137931035 ], [ 29.791292151898734, -1.395803620689655 ], [ 29.792100632911392, -1.395803620689655 ], [ 29.792100632911392, -1.395534137931035 ], [ 29.791292151898734, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1623, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.395534137931035 ], [ 29.792100632911392, -1.395803620689655 ], [ 29.792370126582277, -1.395803620689655 ], [ 29.792370126582277, -1.395534137931035 ], [ 29.792100632911392, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1624, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.395534137931035 ], [ 29.792370126582277, -1.395803620689655 ], [ 29.792909113924051, -1.395803620689655 ], [ 29.792909113924051, -1.395534137931035 ], [ 29.792370126582277, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1625, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.395534137931035 ], [ 29.792909113924051, -1.395803620689655 ], [ 29.793178607594935, -1.395803620689655 ], [ 29.793178607594935, -1.395534137931035 ], [ 29.792909113924051, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1626, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.395534137931035 ], [ 29.793178607594935, -1.395803620689655 ], [ 29.793448101265824, -1.395803620689655 ], [ 29.793448101265824, -1.395534137931035 ], [ 29.793178607594935, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1627, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.395534137931035 ], [ 29.793448101265824, -1.395803620689655 ], [ 29.793717594936709, -1.395803620689655 ], [ 29.793717594936709, -1.395534137931035 ], [ 29.793448101265824, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1628, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.395534137931035 ], [ 29.793717594936709, -1.395803620689655 ], [ 29.794256582278482, -1.395803620689655 ], [ 29.794256582278482, -1.395534137931035 ], [ 29.793717594936709, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1629, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.395534137931035 ], [ 29.796412531645569, -1.395803620689655 ], [ 29.796682025316457, -1.395803620689655 ], [ 29.796682025316457, -1.395534137931035 ], [ 29.796412531645569, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1630, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.395534137931035 ], [ 29.796682025316457, -1.396073103448276 ], [ 29.797221012658227, -1.396073103448276 ], [ 29.797221012658227, -1.395803620689655 ], [ 29.796951518987342, -1.395803620689655 ], [ 29.796951518987342, -1.395534137931035 ], [ 29.796682025316457, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1631, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.395534137931035 ], [ 29.796951518987342, -1.395803620689655 ], [ 29.797221012658227, -1.395803620689655 ], [ 29.797221012658227, -1.395534137931035 ], [ 29.796951518987342, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1632, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.395534137931035 ], [ 29.797221012658227, -1.395803620689655 ], [ 29.797490506329115, -1.395803620689655 ], [ 29.797490506329115, -1.395534137931035 ], [ 29.797221012658227, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1633, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.395534137931035 ], [ 29.797490506329115, -1.395803620689655 ], [ 29.79776, -1.395803620689655 ], [ 29.79776, -1.395534137931035 ], [ 29.797490506329115, -1.395534137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1634, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.395803620689655 ], [ 29.767846202531643, -1.396073103448276 ], [ 29.768115696202532, -1.396073103448276 ], [ 29.768115696202532, -1.395803620689655 ], [ 29.767846202531643, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1635, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.395803620689655 ], [ 29.768115696202532, -1.396073103448276 ], [ 29.768385189873417, -1.396073103448276 ], [ 29.768385189873417, -1.395803620689655 ], [ 29.768115696202532, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1636, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.395803620689655 ], [ 29.768385189873417, -1.396073103448276 ], [ 29.768654683544302, -1.396073103448276 ], [ 29.768654683544302, -1.395803620689655 ], [ 29.768385189873417, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1637, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.395803620689655 ], [ 29.768654683544302, -1.396073103448276 ], [ 29.76892417721519, -1.396073103448276 ], [ 29.76892417721519, -1.395803620689655 ], [ 29.768654683544302, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1638, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.395803620689655 ], [ 29.76892417721519, -1.396073103448276 ], [ 29.769193670886075, -1.396073103448276 ], [ 29.769193670886075, -1.395803620689655 ], [ 29.76892417721519, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1639, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.395803620689655 ], [ 29.769193670886075, -1.396073103448276 ], [ 29.76946316455696, -1.396073103448276 ], [ 29.76946316455696, -1.395803620689655 ], [ 29.769193670886075, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1640, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.395803620689655 ], [ 29.76946316455696, -1.396073103448276 ], [ 29.769732658227849, -1.396073103448276 ], [ 29.769732658227849, -1.395803620689655 ], [ 29.76946316455696, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1641, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.395803620689655 ], [ 29.769732658227849, -1.396073103448276 ], [ 29.770002151898733, -1.396073103448276 ], [ 29.770002151898733, -1.395803620689655 ], [ 29.769732658227849, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1642, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.395803620689655 ], [ 29.770002151898733, -1.396073103448276 ], [ 29.770271645569618, -1.396073103448276 ], [ 29.770271645569618, -1.395803620689655 ], [ 29.770002151898733, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1643, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.395803620689655 ], [ 29.770271645569618, -1.396073103448276 ], [ 29.770541139240507, -1.396073103448276 ], [ 29.770541139240507, -1.395803620689655 ], [ 29.770271645569618, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1644, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.395803620689655 ], [ 29.770541139240507, -1.396073103448276 ], [ 29.770810632911392, -1.396073103448276 ], [ 29.770810632911392, -1.395803620689655 ], [ 29.770541139240507, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1645, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.395803620689655 ], [ 29.770810632911392, -1.396073103448276 ], [ 29.771080126582277, -1.396073103448276 ], [ 29.771080126582277, -1.395803620689655 ], [ 29.770810632911392, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1646, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.395803620689655 ], [ 29.771080126582277, -1.396073103448276 ], [ 29.771349620253165, -1.396073103448276 ], [ 29.771349620253165, -1.395803620689655 ], [ 29.771080126582277, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1647, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.395803620689655 ], [ 29.771349620253165, -1.396073103448276 ], [ 29.77161911392405, -1.396073103448276 ], [ 29.77161911392405, -1.395803620689655 ], [ 29.771349620253165, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1648, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.395803620689655 ], [ 29.77161911392405, -1.396073103448276 ], [ 29.771888607594935, -1.396073103448276 ], [ 29.771888607594935, -1.395803620689655 ], [ 29.77161911392405, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1649, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.395803620689655 ], [ 29.771888607594935, -1.396073103448276 ], [ 29.772158101265823, -1.396073103448276 ], [ 29.772158101265823, -1.395803620689655 ], [ 29.771888607594935, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1650, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.395803620689655 ], [ 29.772158101265823, -1.396073103448276 ], [ 29.772427594936708, -1.396073103448276 ], [ 29.772427594936708, -1.395803620689655 ], [ 29.772158101265823, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1651, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.395803620689655 ], [ 29.772427594936708, -1.396073103448276 ], [ 29.772697088607593, -1.396073103448276 ], [ 29.772697088607593, -1.395803620689655 ], [ 29.772427594936708, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1652, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.395803620689655 ], [ 29.772697088607593, -1.396073103448276 ], [ 29.772966582278482, -1.396073103448276 ], [ 29.772966582278482, -1.395803620689655 ], [ 29.772697088607593, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1653, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.395803620689655 ], [ 29.772966582278482, -1.396073103448276 ], [ 29.773236075949367, -1.396073103448276 ], [ 29.773236075949367, -1.395803620689655 ], [ 29.772966582278482, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1654, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.395803620689655 ], [ 29.773236075949367, -1.396073103448276 ], [ 29.773505569620252, -1.396073103448276 ], [ 29.773505569620252, -1.395803620689655 ], [ 29.773236075949367, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1655, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.395803620689655 ], [ 29.773505569620252, -1.396073103448276 ], [ 29.77377506329114, -1.396073103448276 ], [ 29.77377506329114, -1.395803620689655 ], [ 29.773505569620252, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1656, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.395803620689655 ], [ 29.77377506329114, -1.396073103448276 ], [ 29.774044556962025, -1.396073103448276 ], [ 29.774044556962025, -1.395803620689655 ], [ 29.77377506329114, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1657, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.395803620689655 ], [ 29.774044556962025, -1.396073103448276 ], [ 29.77431405063291, -1.396073103448276 ], [ 29.77431405063291, -1.395803620689655 ], [ 29.774044556962025, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1658, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.395803620689655 ], [ 29.77431405063291, -1.396073103448276 ], [ 29.774853037974683, -1.396073103448276 ], [ 29.774853037974683, -1.395803620689655 ], [ 29.77431405063291, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1659, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.395803620689655 ], [ 29.774853037974683, -1.396073103448276 ], [ 29.775122531645568, -1.396073103448276 ], [ 29.775122531645568, -1.395803620689655 ], [ 29.774853037974683, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1660, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.395803620689655 ], [ 29.775122531645568, -1.396073103448276 ], [ 29.775392025316457, -1.396073103448276 ], [ 29.775392025316457, -1.395803620689655 ], [ 29.775122531645568, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1661, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.395803620689655 ], [ 29.775392025316457, -1.396073103448276 ], [ 29.775661518987341, -1.396073103448276 ], [ 29.775661518987341, -1.395803620689655 ], [ 29.775392025316457, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1662, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.395803620689655 ], [ 29.775661518987341, -1.396073103448276 ], [ 29.775931012658226, -1.396073103448276 ], [ 29.775931012658226, -1.395803620689655 ], [ 29.775661518987341, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1663, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.395803620689655 ], [ 29.775931012658226, -1.396073103448276 ], [ 29.776200506329115, -1.396073103448276 ], [ 29.776200506329115, -1.395803620689655 ], [ 29.775931012658226, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1664, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.395803620689655 ], [ 29.776200506329115, -1.396073103448276 ], [ 29.776739493670885, -1.396073103448276 ], [ 29.776739493670885, -1.395803620689655 ], [ 29.776200506329115, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1665, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.395803620689655 ], [ 29.776739493670885, -1.396073103448276 ], [ 29.777817468354431, -1.396073103448276 ], [ 29.777817468354431, -1.395803620689655 ], [ 29.776739493670885, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1666, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.395803620689655 ], [ 29.777817468354431, -1.396073103448276 ], [ 29.778356455696201, -1.396073103448276 ], [ 29.778356455696201, -1.395803620689655 ], [ 29.777817468354431, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1667, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.395803620689655 ], [ 29.778356455696201, -1.396073103448276 ], [ 29.77862594936709, -1.396073103448276 ], [ 29.77862594936709, -1.395803620689655 ], [ 29.778356455696201, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1668, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.395803620689655 ], [ 29.77862594936709, -1.396073103448276 ], [ 29.778895443037975, -1.396073103448276 ], [ 29.778895443037975, -1.395803620689655 ], [ 29.77862594936709, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1669, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.395803620689655 ], [ 29.778895443037975, -1.396073103448276 ], [ 29.77916493670886, -1.396073103448276 ], [ 29.77916493670886, -1.395803620689655 ], [ 29.778895443037975, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1670, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.395803620689655 ], [ 29.77916493670886, -1.396073103448276 ], [ 29.779434430379748, -1.396073103448276 ], [ 29.779434430379748, -1.395803620689655 ], [ 29.77916493670886, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1671, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.395803620689655 ], [ 29.779434430379748, -1.396073103448276 ], [ 29.779703924050633, -1.396073103448276 ], [ 29.779703924050633, -1.395803620689655 ], [ 29.779434430379748, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1672, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.395803620689655 ], [ 29.779703924050633, -1.396073103448276 ], [ 29.779973417721518, -1.396073103448276 ], [ 29.779973417721518, -1.395803620689655 ], [ 29.779703924050633, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1673, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.395803620689655 ], [ 29.779973417721518, -1.396073103448276 ], [ 29.780242911392406, -1.396073103448276 ], [ 29.780242911392406, -1.395803620689655 ], [ 29.779973417721518, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1674, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.395803620689655 ], [ 29.780242911392406, -1.396073103448276 ], [ 29.780512405063291, -1.396073103448276 ], [ 29.780512405063291, -1.395803620689655 ], [ 29.780242911392406, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1675, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.395803620689655 ], [ 29.780512405063291, -1.396073103448276 ], [ 29.780781898734176, -1.396073103448276 ], [ 29.780781898734176, -1.395803620689655 ], [ 29.780512405063291, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1676, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.395803620689655 ], [ 29.780781898734176, -1.396073103448276 ], [ 29.781051392405065, -1.396073103448276 ], [ 29.781051392405065, -1.395803620689655 ], [ 29.780781898734176, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1677, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.395803620689655 ], [ 29.781051392405065, -1.396073103448276 ], [ 29.78132088607595, -1.396073103448276 ], [ 29.78132088607595, -1.395803620689655 ], [ 29.781051392405065, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1678, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.395803620689655 ], [ 29.78132088607595, -1.396073103448276 ], [ 29.784554810126583, -1.396073103448276 ], [ 29.784554810126583, -1.395803620689655 ], [ 29.78132088607595, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1679, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.395803620689655 ], [ 29.784554810126583, -1.396073103448276 ], [ 29.784824303797468, -1.396073103448276 ], [ 29.784824303797468, -1.395803620689655 ], [ 29.784554810126583, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1680, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.395803620689655 ], [ 29.784824303797468, -1.396073103448276 ], [ 29.785093797468356, -1.396073103448276 ], [ 29.785093797468356, -1.395803620689655 ], [ 29.784824303797468, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1681, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.395803620689655 ], [ 29.785093797468356, -1.396073103448276 ], [ 29.785363291139241, -1.396073103448276 ], [ 29.785363291139241, -1.395803620689655 ], [ 29.785093797468356, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1682, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.395803620689655 ], [ 29.785363291139241, -1.396073103448276 ], [ 29.785632784810126, -1.396073103448276 ], [ 29.785632784810126, -1.395803620689655 ], [ 29.785363291139241, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1683, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.395803620689655 ], [ 29.785632784810126, -1.396073103448276 ], [ 29.785902278481014, -1.396073103448276 ], [ 29.785902278481014, -1.395803620689655 ], [ 29.785632784810126, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1684, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.395803620689655 ], [ 29.785902278481014, -1.396073103448276 ], [ 29.786171772151899, -1.396073103448276 ], [ 29.786171772151899, -1.395803620689655 ], [ 29.785902278481014, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1685, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.395803620689655 ], [ 29.786171772151899, -1.396342586206897 ], [ 29.786441265822784, -1.396342586206897 ], [ 29.786441265822784, -1.395803620689655 ], [ 29.786171772151899, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1686, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.395803620689655 ], [ 29.786441265822784, -1.396073103448276 ], [ 29.786710759493673, -1.396073103448276 ], [ 29.786710759493673, -1.395803620689655 ], [ 29.786441265822784, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1687, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.395803620689655 ], [ 29.786710759493673, -1.396073103448276 ], [ 29.786980253164558, -1.396073103448276 ], [ 29.786980253164558, -1.395803620689655 ], [ 29.786710759493673, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1688, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.395803620689655 ], [ 29.786980253164558, -1.396073103448276 ], [ 29.787249746835442, -1.396073103448276 ], [ 29.787249746835442, -1.395803620689655 ], [ 29.786980253164558, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1689, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.395803620689655 ], [ 29.787249746835442, -1.396073103448276 ], [ 29.787519240506327, -1.396073103448276 ], [ 29.787519240506327, -1.395803620689655 ], [ 29.787249746835442, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1690, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.395803620689655 ], [ 29.787519240506327, -1.396073103448276 ], [ 29.787788734177216, -1.396073103448276 ], [ 29.787788734177216, -1.395803620689655 ], [ 29.787519240506327, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1691, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.395803620689655 ], [ 29.787788734177216, -1.396073103448276 ], [ 29.788058227848101, -1.396073103448276 ], [ 29.788058227848101, -1.395803620689655 ], [ 29.787788734177216, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1692, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.395803620689655 ], [ 29.788058227848101, -1.396073103448276 ], [ 29.788327721518986, -1.396073103448276 ], [ 29.788327721518986, -1.395803620689655 ], [ 29.788058227848101, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1693, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.395803620689655 ], [ 29.789944683544302, -1.396073103448276 ], [ 29.791022658227849, -1.396073103448276 ], [ 29.791022658227849, -1.395803620689655 ], [ 29.789944683544302, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1694, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.395803620689655 ], [ 29.791022658227849, -1.396073103448276 ], [ 29.791292151898734, -1.396073103448276 ], [ 29.791292151898734, -1.395803620689655 ], [ 29.791022658227849, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1695, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.395803620689655 ], [ 29.791292151898734, -1.396073103448276 ], [ 29.792100632911392, -1.396073103448276 ], [ 29.792100632911392, -1.395803620689655 ], [ 29.791292151898734, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1696, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.395803620689655 ], [ 29.792100632911392, -1.396073103448276 ], [ 29.792370126582277, -1.396073103448276 ], [ 29.792370126582277, -1.395803620689655 ], [ 29.792100632911392, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1697, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.395803620689655 ], [ 29.792370126582277, -1.396073103448276 ], [ 29.792909113924051, -1.396073103448276 ], [ 29.792909113924051, -1.395803620689655 ], [ 29.792370126582277, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1698, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.395803620689655 ], [ 29.792909113924051, -1.396073103448276 ], [ 29.793178607594935, -1.396073103448276 ], [ 29.793178607594935, -1.395803620689655 ], [ 29.792909113924051, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1699, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.395803620689655 ], [ 29.793178607594935, -1.396073103448276 ], [ 29.793448101265824, -1.396073103448276 ], [ 29.793448101265824, -1.395803620689655 ], [ 29.793178607594935, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1700, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.395803620689655 ], [ 29.793448101265824, -1.396073103448276 ], [ 29.793717594936709, -1.396073103448276 ], [ 29.793717594936709, -1.395803620689655 ], [ 29.793448101265824, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1701, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.395803620689655 ], [ 29.793717594936709, -1.396073103448276 ], [ 29.794256582278482, -1.396073103448276 ], [ 29.794256582278482, -1.395803620689655 ], [ 29.793717594936709, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1702, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.395803620689655 ], [ 29.794256582278482, -1.396073103448276 ], [ 29.794526075949367, -1.396073103448276 ], [ 29.794526075949367, -1.395803620689655 ], [ 29.794256582278482, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1703, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.395803620689655 ], [ 29.794526075949367, -1.396073103448276 ], [ 29.794795569620252, -1.396073103448276 ], [ 29.794795569620252, -1.395803620689655 ], [ 29.794526075949367, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1704, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.395803620689655 ], [ 29.794795569620252, -1.396073103448276 ], [ 29.79506506329114, -1.396073103448276 ], [ 29.79506506329114, -1.395803620689655 ], [ 29.794795569620252, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1705, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.395803620689655 ], [ 29.79506506329114, -1.396073103448276 ], [ 29.796143037974684, -1.396073103448276 ], [ 29.796143037974684, -1.395803620689655 ], [ 29.79506506329114, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1706, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.395803620689655 ], [ 29.796143037974684, -1.396073103448276 ], [ 29.796412531645569, -1.396073103448276 ], [ 29.796412531645569, -1.395803620689655 ], [ 29.796143037974684, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.395803620689655 ], [ 29.796412531645569, -1.396073103448276 ], [ 29.796682025316457, -1.396073103448276 ], [ 29.796682025316457, -1.395803620689655 ], [ 29.796412531645569, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1708, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.395803620689655 ], [ 29.797221012658227, -1.396073103448276 ], [ 29.797490506329115, -1.396073103448276 ], [ 29.797490506329115, -1.395803620689655 ], [ 29.797221012658227, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1709, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.395803620689655 ], [ 29.797490506329115, -1.396073103448276 ], [ 29.79776, -1.396073103448276 ], [ 29.79776, -1.395803620689655 ], [ 29.797490506329115, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1710, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.395803620689655 ], [ 29.79776, -1.396073103448276 ], [ 29.798029493670885, -1.396073103448276 ], [ 29.798029493670885, -1.395803620689655 ], [ 29.79776, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1711, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.395803620689655 ], [ 29.798029493670885, -1.396342586206897 ], [ 29.798298987341774, -1.396342586206897 ], [ 29.798298987341774, -1.395803620689655 ], [ 29.798029493670885, -1.395803620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1712, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.396073103448276 ], [ 29.767576708860759, -1.396342586206897 ], [ 29.767846202531643, -1.396342586206897 ], [ 29.767846202531643, -1.396073103448276 ], [ 29.767576708860759, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1713, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.396073103448276 ], [ 29.767846202531643, -1.396342586206897 ], [ 29.768115696202532, -1.396342586206897 ], [ 29.768115696202532, -1.396073103448276 ], [ 29.767846202531643, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.396073103448276 ], [ 29.768115696202532, -1.396342586206897 ], [ 29.768385189873417, -1.396342586206897 ], [ 29.768385189873417, -1.396073103448276 ], [ 29.768115696202532, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1715, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.396073103448276 ], [ 29.768385189873417, -1.396342586206897 ], [ 29.768654683544302, -1.396342586206897 ], [ 29.768654683544302, -1.396073103448276 ], [ 29.768385189873417, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1716, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.396073103448276 ], [ 29.768654683544302, -1.396342586206897 ], [ 29.76892417721519, -1.396342586206897 ], [ 29.76892417721519, -1.396073103448276 ], [ 29.768654683544302, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1717, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.396073103448276 ], [ 29.76892417721519, -1.396342586206897 ], [ 29.769193670886075, -1.396342586206897 ], [ 29.769193670886075, -1.396073103448276 ], [ 29.76892417721519, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1718, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.396073103448276 ], [ 29.769193670886075, -1.396342586206897 ], [ 29.76946316455696, -1.396342586206897 ], [ 29.76946316455696, -1.396073103448276 ], [ 29.769193670886075, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1719, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.396073103448276 ], [ 29.76946316455696, -1.396342586206897 ], [ 29.769732658227849, -1.396342586206897 ], [ 29.769732658227849, -1.396073103448276 ], [ 29.76946316455696, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1720, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.396073103448276 ], [ 29.769732658227849, -1.396342586206897 ], [ 29.770002151898733, -1.396342586206897 ], [ 29.770002151898733, -1.396073103448276 ], [ 29.769732658227849, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1721, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.396073103448276 ], [ 29.770002151898733, -1.396342586206897 ], [ 29.770271645569618, -1.396342586206897 ], [ 29.770271645569618, -1.396073103448276 ], [ 29.770002151898733, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1722, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.396073103448276 ], [ 29.770271645569618, -1.396342586206897 ], [ 29.770541139240507, -1.396342586206897 ], [ 29.770541139240507, -1.396073103448276 ], [ 29.770271645569618, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1723, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.396073103448276 ], [ 29.770541139240507, -1.396342586206897 ], [ 29.770810632911392, -1.396342586206897 ], [ 29.770810632911392, -1.396073103448276 ], [ 29.770541139240507, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1724, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.396073103448276 ], [ 29.770810632911392, -1.396342586206897 ], [ 29.771080126582277, -1.396342586206897 ], [ 29.771080126582277, -1.396073103448276 ], [ 29.770810632911392, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1725, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.396073103448276 ], [ 29.771080126582277, -1.396342586206897 ], [ 29.771349620253165, -1.396342586206897 ], [ 29.771349620253165, -1.396073103448276 ], [ 29.771080126582277, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1726, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.396073103448276 ], [ 29.771349620253165, -1.396342586206897 ], [ 29.77161911392405, -1.396342586206897 ], [ 29.77161911392405, -1.396073103448276 ], [ 29.771349620253165, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1727, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.396073103448276 ], [ 29.77161911392405, -1.396342586206897 ], [ 29.771888607594935, -1.396342586206897 ], [ 29.771888607594935, -1.396073103448276 ], [ 29.77161911392405, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1728, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.396073103448276 ], [ 29.771888607594935, -1.396342586206897 ], [ 29.772158101265823, -1.396342586206897 ], [ 29.772158101265823, -1.396073103448276 ], [ 29.771888607594935, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1729, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.396073103448276 ], [ 29.772158101265823, -1.396342586206897 ], [ 29.772427594936708, -1.396342586206897 ], [ 29.772427594936708, -1.396073103448276 ], [ 29.772158101265823, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1730, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.396073103448276 ], [ 29.772427594936708, -1.396342586206897 ], [ 29.772697088607593, -1.396342586206897 ], [ 29.772697088607593, -1.396073103448276 ], [ 29.772427594936708, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1731, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.396073103448276 ], [ 29.772697088607593, -1.396342586206897 ], [ 29.772966582278482, -1.396342586206897 ], [ 29.772966582278482, -1.396073103448276 ], [ 29.772697088607593, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1732, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.396073103448276 ], [ 29.772966582278482, -1.396342586206897 ], [ 29.773236075949367, -1.396342586206897 ], [ 29.773236075949367, -1.396073103448276 ], [ 29.772966582278482, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1733, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.396073103448276 ], [ 29.773236075949367, -1.396342586206897 ], [ 29.773505569620252, -1.396342586206897 ], [ 29.773505569620252, -1.396073103448276 ], [ 29.773236075949367, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1734, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.396073103448276 ], [ 29.773505569620252, -1.396342586206897 ], [ 29.77377506329114, -1.396342586206897 ], [ 29.77377506329114, -1.396073103448276 ], [ 29.773505569620252, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1735, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.396073103448276 ], [ 29.77377506329114, -1.396342586206897 ], [ 29.774044556962025, -1.396342586206897 ], [ 29.774044556962025, -1.396073103448276 ], [ 29.77377506329114, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1736, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.396073103448276 ], [ 29.774044556962025, -1.396342586206897 ], [ 29.77431405063291, -1.396342586206897 ], [ 29.77431405063291, -1.396073103448276 ], [ 29.774044556962025, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1737, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.396073103448276 ], [ 29.77431405063291, -1.396342586206897 ], [ 29.774853037974683, -1.396342586206897 ], [ 29.774853037974683, -1.396073103448276 ], [ 29.77431405063291, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1738, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.396073103448276 ], [ 29.774853037974683, -1.396342586206897 ], [ 29.775122531645568, -1.396342586206897 ], [ 29.775122531645568, -1.396073103448276 ], [ 29.774853037974683, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1739, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.396073103448276 ], [ 29.775122531645568, -1.396342586206897 ], [ 29.775392025316457, -1.396342586206897 ], [ 29.775392025316457, -1.396073103448276 ], [ 29.775122531645568, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1740, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.396073103448276 ], [ 29.775392025316457, -1.396342586206897 ], [ 29.775661518987341, -1.396342586206897 ], [ 29.775661518987341, -1.396073103448276 ], [ 29.775392025316457, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1741, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.396073103448276 ], [ 29.775661518987341, -1.396342586206897 ], [ 29.775931012658226, -1.396342586206897 ], [ 29.775931012658226, -1.396073103448276 ], [ 29.775661518987341, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1742, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.396073103448276 ], [ 29.775931012658226, -1.396342586206897 ], [ 29.776200506329115, -1.396342586206897 ], [ 29.776200506329115, -1.396073103448276 ], [ 29.775931012658226, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1743, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.396073103448276 ], [ 29.776200506329115, -1.396342586206897 ], [ 29.77647, -1.396342586206897 ], [ 29.77647, -1.396073103448276 ], [ 29.776200506329115, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1744, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.396073103448276 ], [ 29.77647, -1.396342586206897 ], [ 29.777008987341773, -1.396342586206897 ], [ 29.777008987341773, -1.396073103448276 ], [ 29.77647, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1745, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.396073103448276 ], [ 29.777008987341773, -1.396342586206897 ], [ 29.777547974683543, -1.396342586206897 ], [ 29.777547974683543, -1.396073103448276 ], [ 29.777008987341773, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1746, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.396073103448276 ], [ 29.777547974683543, -1.396342586206897 ], [ 29.778086962025316, -1.396342586206897 ], [ 29.778086962025316, -1.396073103448276 ], [ 29.777547974683543, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1747, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.396073103448276 ], [ 29.778086962025316, -1.396342586206897 ], [ 29.778356455696201, -1.396342586206897 ], [ 29.778356455696201, -1.396073103448276 ], [ 29.778086962025316, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1748, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.396073103448276 ], [ 29.778356455696201, -1.396342586206897 ], [ 29.77862594936709, -1.396342586206897 ], [ 29.77862594936709, -1.396073103448276 ], [ 29.778356455696201, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1749, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.396073103448276 ], [ 29.77862594936709, -1.396342586206897 ], [ 29.778895443037975, -1.396342586206897 ], [ 29.778895443037975, -1.396073103448276 ], [ 29.77862594936709, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1750, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.396073103448276 ], [ 29.778895443037975, -1.396342586206897 ], [ 29.77916493670886, -1.396342586206897 ], [ 29.77916493670886, -1.396073103448276 ], [ 29.778895443037975, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1751, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.396073103448276 ], [ 29.77916493670886, -1.396342586206897 ], [ 29.779434430379748, -1.396342586206897 ], [ 29.779434430379748, -1.396073103448276 ], [ 29.77916493670886, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1752, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.396073103448276 ], [ 29.779434430379748, -1.396342586206897 ], [ 29.779703924050633, -1.396342586206897 ], [ 29.779703924050633, -1.396073103448276 ], [ 29.779434430379748, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1753, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.396073103448276 ], [ 29.779703924050633, -1.396342586206897 ], [ 29.779973417721518, -1.396342586206897 ], [ 29.779973417721518, -1.396073103448276 ], [ 29.779703924050633, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1754, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.396073103448276 ], [ 29.780242911392406, -1.396342586206897 ], [ 29.780512405063291, -1.396342586206897 ], [ 29.780512405063291, -1.396073103448276 ], [ 29.780242911392406, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1755, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.396073103448276 ], [ 29.780512405063291, -1.396342586206897 ], [ 29.780781898734176, -1.396342586206897 ], [ 29.780781898734176, -1.396073103448276 ], [ 29.780512405063291, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1756, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.396073103448276 ], [ 29.780781898734176, -1.396342586206897 ], [ 29.781051392405065, -1.396342586206897 ], [ 29.781051392405065, -1.396073103448276 ], [ 29.780781898734176, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1757, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.396073103448276 ], [ 29.781051392405065, -1.396342586206897 ], [ 29.78132088607595, -1.396342586206897 ], [ 29.78132088607595, -1.396073103448276 ], [ 29.781051392405065, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1758, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.396073103448276 ], [ 29.78132088607595, -1.396342586206897 ], [ 29.781590379746834, -1.396342586206897 ], [ 29.781590379746834, -1.396073103448276 ], [ 29.78132088607595, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1759, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.396073103448276 ], [ 29.781590379746834, -1.396342586206897 ], [ 29.781859873417723, -1.396342586206897 ], [ 29.781859873417723, -1.396073103448276 ], [ 29.781590379746834, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1760, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.396073103448276 ], [ 29.781859873417723, -1.396342586206897 ], [ 29.782398860759493, -1.396342586206897 ], [ 29.782398860759493, -1.396073103448276 ], [ 29.781859873417723, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1761, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.396073103448276 ], [ 29.782398860759493, -1.396342586206897 ], [ 29.784285316455698, -1.396342586206897 ], [ 29.784285316455698, -1.396073103448276 ], [ 29.782398860759493, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1762, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.396073103448276 ], [ 29.784285316455698, -1.396342586206897 ], [ 29.784824303797468, -1.396342586206897 ], [ 29.784824303797468, -1.396073103448276 ], [ 29.784285316455698, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1763, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.396073103448276 ], [ 29.784824303797468, -1.396342586206897 ], [ 29.785093797468356, -1.396342586206897 ], [ 29.785093797468356, -1.396073103448276 ], [ 29.784824303797468, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1764, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.396073103448276 ], [ 29.785093797468356, -1.396342586206897 ], [ 29.785363291139241, -1.396342586206897 ], [ 29.785363291139241, -1.396073103448276 ], [ 29.785093797468356, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1765, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.396073103448276 ], [ 29.786980253164558, -1.396342586206897 ], [ 29.787249746835442, -1.396342586206897 ], [ 29.787249746835442, -1.396073103448276 ], [ 29.786980253164558, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1766, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.396073103448276 ], [ 29.787249746835442, -1.396342586206897 ], [ 29.787519240506327, -1.396342586206897 ], [ 29.787519240506327, -1.396073103448276 ], [ 29.787249746835442, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1767, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.396073103448276 ], [ 29.787519240506327, -1.396342586206897 ], [ 29.787788734177216, -1.396342586206897 ], [ 29.787788734177216, -1.396073103448276 ], [ 29.787519240506327, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1768, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.396073103448276 ], [ 29.787788734177216, -1.396342586206897 ], [ 29.788058227848101, -1.396342586206897 ], [ 29.788058227848101, -1.396073103448276 ], [ 29.787788734177216, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1769, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.396073103448276 ], [ 29.791022658227849, -1.396342586206897 ], [ 29.792100632911392, -1.396342586206897 ], [ 29.792100632911392, -1.396073103448276 ], [ 29.791022658227849, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1770, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.396073103448276 ], [ 29.792100632911392, -1.396342586206897 ], [ 29.792370126582277, -1.396342586206897 ], [ 29.792370126582277, -1.396073103448276 ], [ 29.792100632911392, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1771, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.396073103448276 ], [ 29.792370126582277, -1.396342586206897 ], [ 29.792909113924051, -1.396342586206897 ], [ 29.792909113924051, -1.396073103448276 ], [ 29.792370126582277, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1772, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.396073103448276 ], [ 29.792909113924051, -1.396342586206897 ], [ 29.793178607594935, -1.396342586206897 ], [ 29.793178607594935, -1.396073103448276 ], [ 29.792909113924051, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1773, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.396073103448276 ], [ 29.793178607594935, -1.396342586206897 ], [ 29.793448101265824, -1.396342586206897 ], [ 29.793448101265824, -1.396073103448276 ], [ 29.793178607594935, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1774, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.396073103448276 ], [ 29.793448101265824, -1.396342586206897 ], [ 29.793717594936709, -1.396342586206897 ], [ 29.793717594936709, -1.396073103448276 ], [ 29.793448101265824, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1775, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.396073103448276 ], [ 29.793717594936709, -1.396342586206897 ], [ 29.794256582278482, -1.396342586206897 ], [ 29.794256582278482, -1.396073103448276 ], [ 29.793717594936709, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1776, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.396073103448276 ], [ 29.794256582278482, -1.396342586206897 ], [ 29.794526075949367, -1.396342586206897 ], [ 29.794526075949367, -1.396073103448276 ], [ 29.794256582278482, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1777, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.396073103448276 ], [ 29.794526075949367, -1.396342586206897 ], [ 29.794795569620252, -1.396342586206897 ], [ 29.794795569620252, -1.396073103448276 ], [ 29.794526075949367, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1778, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.396073103448276 ], [ 29.794795569620252, -1.396342586206897 ], [ 29.79506506329114, -1.396342586206897 ], [ 29.79506506329114, -1.396073103448276 ], [ 29.794795569620252, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1779, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.396073103448276 ], [ 29.79506506329114, -1.396342586206897 ], [ 29.796143037974684, -1.396342586206897 ], [ 29.796143037974684, -1.396073103448276 ], [ 29.79506506329114, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1780, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.396073103448276 ], [ 29.796143037974684, -1.396342586206897 ], [ 29.796412531645569, -1.396342586206897 ], [ 29.796412531645569, -1.396073103448276 ], [ 29.796143037974684, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1781, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.396073103448276 ], [ 29.796412531645569, -1.396342586206897 ], [ 29.796682025316457, -1.396342586206897 ], [ 29.796682025316457, -1.396073103448276 ], [ 29.796412531645569, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1782, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.396073103448276 ], [ 29.796682025316457, -1.396342586206897 ], [ 29.796951518987342, -1.396342586206897 ], [ 29.796951518987342, -1.396073103448276 ], [ 29.796682025316457, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1783, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.396073103448276 ], [ 29.796951518987342, -1.396342586206897 ], [ 29.797221012658227, -1.396342586206897 ], [ 29.797221012658227, -1.396073103448276 ], [ 29.796951518987342, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1784, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.396073103448276 ], [ 29.797221012658227, -1.396342586206897 ], [ 29.797490506329115, -1.396342586206897 ], [ 29.797490506329115, -1.396073103448276 ], [ 29.797221012658227, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1785, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.396073103448276 ], [ 29.797490506329115, -1.396342586206897 ], [ 29.79776, -1.396342586206897 ], [ 29.79776, -1.396073103448276 ], [ 29.797490506329115, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1786, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.396073103448276 ], [ 29.79776, -1.396342586206897 ], [ 29.798029493670885, -1.396342586206897 ], [ 29.798029493670885, -1.396073103448276 ], [ 29.79776, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1787, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.396073103448276 ], [ 29.779973417721518, -1.396612068965517 ], [ 29.780242911392406, -1.396612068965517 ], [ 29.780242911392406, -1.396073103448276 ], [ 29.779973417721518, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1788, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.396073103448276 ], [ 29.785363291139241, -1.396342586206897 ], [ 29.785632784810126, -1.396342586206897 ], [ 29.785632784810126, -1.396612068965517 ], [ 29.785902278481014, -1.396612068965517 ], [ 29.785902278481014, -1.396073103448276 ], [ 29.785363291139241, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1789, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.396073103448276 ], [ 29.786441265822784, -1.398498448275862 ], [ 29.786710759493673, -1.398498448275862 ], [ 29.786710759493673, -1.396073103448276 ], [ 29.786441265822784, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1790, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.396073103448276 ], [ 29.786710759493673, -1.398498448275862 ], [ 29.786980253164558, -1.398498448275862 ], [ 29.786980253164558, -1.396073103448276 ], [ 29.786710759493673, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1791, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.396342586206897 ], [ 29.767037721518985, -1.396612068965517 ], [ 29.767576708860759, -1.396612068965517 ], [ 29.767576708860759, -1.396342586206897 ], [ 29.767037721518985, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1792, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.396342586206897 ], [ 29.767576708860759, -1.396612068965517 ], [ 29.767846202531643, -1.396612068965517 ], [ 29.767846202531643, -1.396342586206897 ], [ 29.767576708860759, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1793, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.396342586206897 ], [ 29.767846202531643, -1.396612068965517 ], [ 29.768115696202532, -1.396612068965517 ], [ 29.768115696202532, -1.396342586206897 ], [ 29.767846202531643, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1794, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.396342586206897 ], [ 29.768115696202532, -1.396612068965517 ], [ 29.768385189873417, -1.396612068965517 ], [ 29.768385189873417, -1.396342586206897 ], [ 29.768115696202532, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1795, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.396342586206897 ], [ 29.768385189873417, -1.396612068965517 ], [ 29.768654683544302, -1.396612068965517 ], [ 29.768654683544302, -1.396342586206897 ], [ 29.768385189873417, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1796, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.396342586206897 ], [ 29.768654683544302, -1.396612068965517 ], [ 29.76892417721519, -1.396612068965517 ], [ 29.76892417721519, -1.396342586206897 ], [ 29.768654683544302, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1797, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.396342586206897 ], [ 29.76892417721519, -1.396612068965517 ], [ 29.769193670886075, -1.396612068965517 ], [ 29.769193670886075, -1.396342586206897 ], [ 29.76892417721519, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1798, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.396342586206897 ], [ 29.769193670886075, -1.396612068965517 ], [ 29.76946316455696, -1.396612068965517 ], [ 29.76946316455696, -1.396342586206897 ], [ 29.769193670886075, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1799, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.396342586206897 ], [ 29.76946316455696, -1.396612068965517 ], [ 29.769732658227849, -1.396612068965517 ], [ 29.769732658227849, -1.396342586206897 ], [ 29.76946316455696, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1800, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.396342586206897 ], [ 29.769732658227849, -1.396612068965517 ], [ 29.770002151898733, -1.396612068965517 ], [ 29.770002151898733, -1.396342586206897 ], [ 29.769732658227849, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1801, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.396342586206897 ], [ 29.770002151898733, -1.396612068965517 ], [ 29.770271645569618, -1.396612068965517 ], [ 29.770271645569618, -1.396342586206897 ], [ 29.770002151898733, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1802, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.396342586206897 ], [ 29.770271645569618, -1.396612068965517 ], [ 29.770541139240507, -1.396612068965517 ], [ 29.770541139240507, -1.396342586206897 ], [ 29.770271645569618, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1803, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.396342586206897 ], [ 29.770541139240507, -1.396612068965517 ], [ 29.770810632911392, -1.396612068965517 ], [ 29.770810632911392, -1.396342586206897 ], [ 29.770541139240507, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1804, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.396342586206897 ], [ 29.770810632911392, -1.396612068965517 ], [ 29.771080126582277, -1.396612068965517 ], [ 29.771080126582277, -1.396342586206897 ], [ 29.770810632911392, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1805, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.396342586206897 ], [ 29.771080126582277, -1.396612068965517 ], [ 29.771349620253165, -1.396612068965517 ], [ 29.771349620253165, -1.396342586206897 ], [ 29.771080126582277, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1806, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.396342586206897 ], [ 29.771349620253165, -1.396612068965517 ], [ 29.77161911392405, -1.396612068965517 ], [ 29.77161911392405, -1.396342586206897 ], [ 29.771349620253165, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1807, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.396342586206897 ], [ 29.77161911392405, -1.396612068965517 ], [ 29.771888607594935, -1.396612068965517 ], [ 29.771888607594935, -1.396342586206897 ], [ 29.77161911392405, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1808, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.396342586206897 ], [ 29.771888607594935, -1.396612068965517 ], [ 29.772158101265823, -1.396612068965517 ], [ 29.772158101265823, -1.396342586206897 ], [ 29.771888607594935, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1809, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.396342586206897 ], [ 29.772158101265823, -1.396612068965517 ], [ 29.772427594936708, -1.396612068965517 ], [ 29.772427594936708, -1.396342586206897 ], [ 29.772158101265823, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1810, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.396342586206897 ], [ 29.772427594936708, -1.396612068965517 ], [ 29.772697088607593, -1.396612068965517 ], [ 29.772697088607593, -1.396342586206897 ], [ 29.772427594936708, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1811, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.396342586206897 ], [ 29.772697088607593, -1.396612068965517 ], [ 29.772966582278482, -1.396612068965517 ], [ 29.772966582278482, -1.396342586206897 ], [ 29.772697088607593, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1812, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.396342586206897 ], [ 29.772966582278482, -1.396612068965517 ], [ 29.773236075949367, -1.396612068965517 ], [ 29.773236075949367, -1.396342586206897 ], [ 29.772966582278482, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1813, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.396342586206897 ], [ 29.773236075949367, -1.396612068965517 ], [ 29.773505569620252, -1.396612068965517 ], [ 29.773505569620252, -1.396342586206897 ], [ 29.773236075949367, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1814, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.396342586206897 ], [ 29.773505569620252, -1.396612068965517 ], [ 29.77377506329114, -1.396612068965517 ], [ 29.77377506329114, -1.396342586206897 ], [ 29.773505569620252, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1815, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.396342586206897 ], [ 29.77377506329114, -1.396612068965517 ], [ 29.774044556962025, -1.396612068965517 ], [ 29.774044556962025, -1.396342586206897 ], [ 29.77377506329114, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1816, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.396342586206897 ], [ 29.774044556962025, -1.396612068965517 ], [ 29.77431405063291, -1.396612068965517 ], [ 29.77431405063291, -1.396342586206897 ], [ 29.774044556962025, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1817, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.396342586206897 ], [ 29.77431405063291, -1.396612068965517 ], [ 29.774853037974683, -1.396612068965517 ], [ 29.774853037974683, -1.396342586206897 ], [ 29.77431405063291, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1818, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.396342586206897 ], [ 29.774853037974683, -1.396612068965517 ], [ 29.775122531645568, -1.396612068965517 ], [ 29.775122531645568, -1.396342586206897 ], [ 29.774853037974683, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1819, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.396342586206897 ], [ 29.775122531645568, -1.396612068965517 ], [ 29.775392025316457, -1.396612068965517 ], [ 29.775392025316457, -1.396342586206897 ], [ 29.775122531645568, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1820, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.396342586206897 ], [ 29.775392025316457, -1.396612068965517 ], [ 29.775661518987341, -1.396612068965517 ], [ 29.775661518987341, -1.396342586206897 ], [ 29.775392025316457, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1821, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.396342586206897 ], [ 29.775661518987341, -1.396612068965517 ], [ 29.775931012658226, -1.396612068965517 ], [ 29.775931012658226, -1.396342586206897 ], [ 29.775661518987341, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1822, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.396342586206897 ], [ 29.775931012658226, -1.396612068965517 ], [ 29.776200506329115, -1.396612068965517 ], [ 29.776200506329115, -1.396342586206897 ], [ 29.775931012658226, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1823, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.396342586206897 ], [ 29.776200506329115, -1.396612068965517 ], [ 29.77647, -1.396612068965517 ], [ 29.77647, -1.396342586206897 ], [ 29.776200506329115, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1824, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.396342586206897 ], [ 29.77647, -1.396612068965517 ], [ 29.778086962025316, -1.396612068965517 ], [ 29.778086962025316, -1.396342586206897 ], [ 29.77647, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1825, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.396342586206897 ], [ 29.778086962025316, -1.396612068965517 ], [ 29.778356455696201, -1.396612068965517 ], [ 29.778356455696201, -1.396342586206897 ], [ 29.778086962025316, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1826, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.396342586206897 ], [ 29.778356455696201, -1.396612068965517 ], [ 29.77862594936709, -1.396612068965517 ], [ 29.77862594936709, -1.396342586206897 ], [ 29.778356455696201, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1827, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.396342586206897 ], [ 29.77862594936709, -1.396612068965517 ], [ 29.778895443037975, -1.396612068965517 ], [ 29.778895443037975, -1.396342586206897 ], [ 29.77862594936709, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1828, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.396342586206897 ], [ 29.778895443037975, -1.396612068965517 ], [ 29.77916493670886, -1.396612068965517 ], [ 29.77916493670886, -1.396342586206897 ], [ 29.778895443037975, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1829, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.396342586206897 ], [ 29.77916493670886, -1.396612068965517 ], [ 29.779434430379748, -1.396612068965517 ], [ 29.779434430379748, -1.396342586206897 ], [ 29.77916493670886, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1830, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.396342586206897 ], [ 29.779434430379748, -1.396612068965517 ], [ 29.779703924050633, -1.396612068965517 ], [ 29.779703924050633, -1.396342586206897 ], [ 29.779434430379748, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1831, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.396342586206897 ], [ 29.779703924050633, -1.396612068965517 ], [ 29.779973417721518, -1.396612068965517 ], [ 29.779973417721518, -1.396342586206897 ], [ 29.779703924050633, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1832, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.396342586206897 ], [ 29.780242911392406, -1.396612068965517 ], [ 29.780512405063291, -1.396612068965517 ], [ 29.780512405063291, -1.396342586206897 ], [ 29.780242911392406, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1833, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.396342586206897 ], [ 29.780512405063291, -1.396612068965517 ], [ 29.780781898734176, -1.396612068965517 ], [ 29.780781898734176, -1.396342586206897 ], [ 29.780512405063291, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1834, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.396342586206897 ], [ 29.780781898734176, -1.396612068965517 ], [ 29.781051392405065, -1.396612068965517 ], [ 29.781051392405065, -1.396342586206897 ], [ 29.780781898734176, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1835, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.396342586206897 ], [ 29.781051392405065, -1.396612068965517 ], [ 29.78132088607595, -1.396612068965517 ], [ 29.78132088607595, -1.396342586206897 ], [ 29.781051392405065, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1836, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.396342586206897 ], [ 29.78132088607595, -1.396612068965517 ], [ 29.781590379746834, -1.396612068965517 ], [ 29.781590379746834, -1.396342586206897 ], [ 29.78132088607595, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1837, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.396342586206897 ], [ 29.781590379746834, -1.396612068965517 ], [ 29.781859873417723, -1.396612068965517 ], [ 29.781859873417723, -1.396342586206897 ], [ 29.781590379746834, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1838, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.396342586206897 ], [ 29.781859873417723, -1.396612068965517 ], [ 29.782129367088608, -1.396612068965517 ], [ 29.782129367088608, -1.396342586206897 ], [ 29.781859873417723, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1839, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.396342586206897 ], [ 29.782129367088608, -1.396612068965517 ], [ 29.784554810126583, -1.396612068965517 ], [ 29.784554810126583, -1.396342586206897 ], [ 29.782129367088608, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1840, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.396342586206897 ], [ 29.784554810126583, -1.396612068965517 ], [ 29.784824303797468, -1.396612068965517 ], [ 29.784824303797468, -1.396342586206897 ], [ 29.784554810126583, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1841, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.396342586206897 ], [ 29.784824303797468, -1.396612068965517 ], [ 29.785093797468356, -1.396612068965517 ], [ 29.785093797468356, -1.396342586206897 ], [ 29.784824303797468, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1842, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.396342586206897 ], [ 29.785093797468356, -1.396612068965517 ], [ 29.785363291139241, -1.396612068965517 ], [ 29.785363291139241, -1.396342586206897 ], [ 29.785093797468356, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1843, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.396342586206897 ], [ 29.785363291139241, -1.396612068965517 ], [ 29.785632784810126, -1.396612068965517 ], [ 29.785632784810126, -1.396342586206897 ], [ 29.785363291139241, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1844, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.396342586206897 ], [ 29.792100632911392, -1.396612068965517 ], [ 29.792909113924051, -1.396612068965517 ], [ 29.792909113924051, -1.396342586206897 ], [ 29.792100632911392, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1845, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.396342586206897 ], [ 29.792909113924051, -1.396612068965517 ], [ 29.793178607594935, -1.396612068965517 ], [ 29.793178607594935, -1.396342586206897 ], [ 29.792909113924051, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1846, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.396342586206897 ], [ 29.793178607594935, -1.396612068965517 ], [ 29.793448101265824, -1.396612068965517 ], [ 29.793448101265824, -1.396342586206897 ], [ 29.793178607594935, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1847, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.396342586206897 ], [ 29.793448101265824, -1.396612068965517 ], [ 29.794526075949367, -1.396612068965517 ], [ 29.794526075949367, -1.396342586206897 ], [ 29.793448101265824, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1848, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.396342586206897 ], [ 29.794526075949367, -1.396612068965517 ], [ 29.794795569620252, -1.396612068965517 ], [ 29.794795569620252, -1.396342586206897 ], [ 29.794526075949367, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1849, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.396342586206897 ], [ 29.794795569620252, -1.396612068965517 ], [ 29.79506506329114, -1.396612068965517 ], [ 29.79506506329114, -1.396342586206897 ], [ 29.794795569620252, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1850, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.396342586206897 ], [ 29.79506506329114, -1.396612068965517 ], [ 29.796143037974684, -1.396612068965517 ], [ 29.796143037974684, -1.396342586206897 ], [ 29.79506506329114, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1851, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.396342586206897 ], [ 29.796143037974684, -1.396612068965517 ], [ 29.796412531645569, -1.396612068965517 ], [ 29.796412531645569, -1.396342586206897 ], [ 29.796143037974684, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.396342586206897 ], [ 29.796412531645569, -1.396612068965517 ], [ 29.796682025316457, -1.396612068965517 ], [ 29.796682025316457, -1.396342586206897 ], [ 29.796412531645569, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1853, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.396342586206897 ], [ 29.796682025316457, -1.396612068965517 ], [ 29.796951518987342, -1.396612068965517 ], [ 29.796951518987342, -1.396342586206897 ], [ 29.796682025316457, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1854, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.396342586206897 ], [ 29.796951518987342, -1.396612068965517 ], [ 29.797221012658227, -1.396612068965517 ], [ 29.797221012658227, -1.396342586206897 ], [ 29.796951518987342, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1855, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.396342586206897 ], [ 29.797221012658227, -1.396612068965517 ], [ 29.797490506329115, -1.396612068965517 ], [ 29.797490506329115, -1.396342586206897 ], [ 29.797221012658227, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1856, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.396342586206897 ], [ 29.797490506329115, -1.396612068965517 ], [ 29.79776, -1.396612068965517 ], [ 29.79776, -1.396342586206897 ], [ 29.797490506329115, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1857, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.396342586206897 ], [ 29.79776, -1.396612068965517 ], [ 29.798029493670885, -1.396612068965517 ], [ 29.798029493670885, -1.396342586206897 ], [ 29.79776, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1858, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.396342586206897 ], [ 29.798029493670885, -1.396612068965517 ], [ 29.798298987341774, -1.396612068965517 ], [ 29.798298987341774, -1.396342586206897 ], [ 29.798029493670885, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1859, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.396342586206897 ], [ 29.798298987341774, -1.397420517241379 ], [ 29.798568481012659, -1.397420517241379 ], [ 29.798568481012659, -1.396342586206897 ], [ 29.798298987341774, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1860, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.396612068965517 ], [ 29.766498734177215, -1.396881551724138 ], [ 29.767037721518985, -1.396881551724138 ], [ 29.767037721518985, -1.396612068965517 ], [ 29.766498734177215, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1861, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.396612068965517 ], [ 29.767037721518985, -1.396881551724138 ], [ 29.767307215189874, -1.396881551724138 ], [ 29.767307215189874, -1.396612068965517 ], [ 29.767037721518985, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1862, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.396612068965517 ], [ 29.767307215189874, -1.396881551724138 ], [ 29.767576708860759, -1.396881551724138 ], [ 29.767576708860759, -1.396612068965517 ], [ 29.767307215189874, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1863, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.396612068965517 ], [ 29.767576708860759, -1.396881551724138 ], [ 29.767846202531643, -1.396881551724138 ], [ 29.767846202531643, -1.396612068965517 ], [ 29.767576708860759, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1864, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.396612068965517 ], [ 29.767846202531643, -1.396881551724138 ], [ 29.768115696202532, -1.396881551724138 ], [ 29.768115696202532, -1.396612068965517 ], [ 29.767846202531643, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1865, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.396612068965517 ], [ 29.768115696202532, -1.396881551724138 ], [ 29.768385189873417, -1.396881551724138 ], [ 29.768385189873417, -1.396612068965517 ], [ 29.768115696202532, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1866, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.396612068965517 ], [ 29.768385189873417, -1.396881551724138 ], [ 29.768654683544302, -1.396881551724138 ], [ 29.768654683544302, -1.396612068965517 ], [ 29.768385189873417, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1867, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.396612068965517 ], [ 29.768654683544302, -1.396881551724138 ], [ 29.76892417721519, -1.396881551724138 ], [ 29.76892417721519, -1.396612068965517 ], [ 29.768654683544302, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1868, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.396612068965517 ], [ 29.76892417721519, -1.396881551724138 ], [ 29.769193670886075, -1.396881551724138 ], [ 29.769193670886075, -1.396612068965517 ], [ 29.76892417721519, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1869, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.396612068965517 ], [ 29.769193670886075, -1.396881551724138 ], [ 29.76946316455696, -1.396881551724138 ], [ 29.76946316455696, -1.396612068965517 ], [ 29.769193670886075, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1870, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.396612068965517 ], [ 29.76946316455696, -1.396881551724138 ], [ 29.769732658227849, -1.396881551724138 ], [ 29.769732658227849, -1.396612068965517 ], [ 29.76946316455696, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1871, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.396612068965517 ], [ 29.769732658227849, -1.396881551724138 ], [ 29.770002151898733, -1.396881551724138 ], [ 29.770002151898733, -1.396612068965517 ], [ 29.769732658227849, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1872, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.396612068965517 ], [ 29.770002151898733, -1.396881551724138 ], [ 29.770271645569618, -1.396881551724138 ], [ 29.770271645569618, -1.396612068965517 ], [ 29.770002151898733, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1873, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.396612068965517 ], [ 29.770271645569618, -1.396881551724138 ], [ 29.770541139240507, -1.396881551724138 ], [ 29.770541139240507, -1.396612068965517 ], [ 29.770271645569618, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1874, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.396612068965517 ], [ 29.770541139240507, -1.396881551724138 ], [ 29.770810632911392, -1.396881551724138 ], [ 29.770810632911392, -1.396612068965517 ], [ 29.770541139240507, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1875, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.396612068965517 ], [ 29.770810632911392, -1.396881551724138 ], [ 29.771080126582277, -1.396881551724138 ], [ 29.771080126582277, -1.396612068965517 ], [ 29.770810632911392, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1876, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.396612068965517 ], [ 29.771080126582277, -1.396881551724138 ], [ 29.771349620253165, -1.396881551724138 ], [ 29.771349620253165, -1.396612068965517 ], [ 29.771080126582277, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1877, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.396612068965517 ], [ 29.771349620253165, -1.396881551724138 ], [ 29.77161911392405, -1.396881551724138 ], [ 29.77161911392405, -1.396612068965517 ], [ 29.771349620253165, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1878, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.396612068965517 ], [ 29.77161911392405, -1.396881551724138 ], [ 29.771888607594935, -1.396881551724138 ], [ 29.771888607594935, -1.396612068965517 ], [ 29.77161911392405, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1879, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.396612068965517 ], [ 29.771888607594935, -1.396881551724138 ], [ 29.772158101265823, -1.396881551724138 ], [ 29.772158101265823, -1.396612068965517 ], [ 29.771888607594935, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1880, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.396612068965517 ], [ 29.772158101265823, -1.396881551724138 ], [ 29.772427594936708, -1.396881551724138 ], [ 29.772427594936708, -1.396612068965517 ], [ 29.772158101265823, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1881, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.396612068965517 ], [ 29.772427594936708, -1.396881551724138 ], [ 29.772697088607593, -1.396881551724138 ], [ 29.772697088607593, -1.396612068965517 ], [ 29.772427594936708, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1882, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.396612068965517 ], [ 29.772697088607593, -1.396881551724138 ], [ 29.772966582278482, -1.396881551724138 ], [ 29.772966582278482, -1.396612068965517 ], [ 29.772697088607593, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1883, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.396612068965517 ], [ 29.772966582278482, -1.396881551724138 ], [ 29.773505569620252, -1.396881551724138 ], [ 29.773505569620252, -1.396612068965517 ], [ 29.772966582278482, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1884, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.396612068965517 ], [ 29.773505569620252, -1.396881551724138 ], [ 29.77377506329114, -1.396881551724138 ], [ 29.77377506329114, -1.396612068965517 ], [ 29.773505569620252, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1885, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.396612068965517 ], [ 29.77377506329114, -1.396881551724138 ], [ 29.774044556962025, -1.396881551724138 ], [ 29.774044556962025, -1.396612068965517 ], [ 29.77377506329114, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1886, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.396612068965517 ], [ 29.774044556962025, -1.396881551724138 ], [ 29.77431405063291, -1.396881551724138 ], [ 29.77431405063291, -1.396612068965517 ], [ 29.774044556962025, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1887, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.396612068965517 ], [ 29.77431405063291, -1.396881551724138 ], [ 29.774853037974683, -1.396881551724138 ], [ 29.774853037974683, -1.396612068965517 ], [ 29.77431405063291, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1888, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.396612068965517 ], [ 29.774853037974683, -1.396881551724138 ], [ 29.775122531645568, -1.396881551724138 ], [ 29.775122531645568, -1.396612068965517 ], [ 29.774853037974683, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1889, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.396612068965517 ], [ 29.775122531645568, -1.396881551724138 ], [ 29.775392025316457, -1.396881551724138 ], [ 29.775392025316457, -1.396612068965517 ], [ 29.775122531645568, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1890, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.396612068965517 ], [ 29.775392025316457, -1.396881551724138 ], [ 29.775661518987341, -1.396881551724138 ], [ 29.775661518987341, -1.396612068965517 ], [ 29.775392025316457, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1891, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.396612068965517 ], [ 29.775661518987341, -1.396881551724138 ], [ 29.775931012658226, -1.396881551724138 ], [ 29.775931012658226, -1.396612068965517 ], [ 29.775661518987341, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1892, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.396612068965517 ], [ 29.775931012658226, -1.396881551724138 ], [ 29.776200506329115, -1.396881551724138 ], [ 29.776200506329115, -1.396612068965517 ], [ 29.775931012658226, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1893, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.396612068965517 ], [ 29.776200506329115, -1.396881551724138 ], [ 29.776739493670885, -1.396881551724138 ], [ 29.776739493670885, -1.396612068965517 ], [ 29.776200506329115, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1894, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.396612068965517 ], [ 29.776739493670885, -1.396881551724138 ], [ 29.777817468354431, -1.396881551724138 ], [ 29.777817468354431, -1.396612068965517 ], [ 29.776739493670885, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1895, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.396612068965517 ], [ 29.777817468354431, -1.396881551724138 ], [ 29.778356455696201, -1.396881551724138 ], [ 29.778356455696201, -1.396612068965517 ], [ 29.777817468354431, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1896, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.396612068965517 ], [ 29.778356455696201, -1.396881551724138 ], [ 29.77862594936709, -1.396881551724138 ], [ 29.77862594936709, -1.396612068965517 ], [ 29.778356455696201, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1897, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.396612068965517 ], [ 29.77862594936709, -1.396881551724138 ], [ 29.778895443037975, -1.396881551724138 ], [ 29.778895443037975, -1.396612068965517 ], [ 29.77862594936709, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1898, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.396612068965517 ], [ 29.778895443037975, -1.396881551724138 ], [ 29.77916493670886, -1.396881551724138 ], [ 29.77916493670886, -1.396612068965517 ], [ 29.778895443037975, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1899, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.396612068965517 ], [ 29.77916493670886, -1.396881551724138 ], [ 29.779434430379748, -1.396881551724138 ], [ 29.779434430379748, -1.396612068965517 ], [ 29.77916493670886, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1900, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.396612068965517 ], [ 29.779434430379748, -1.396881551724138 ], [ 29.779703924050633, -1.396881551724138 ], [ 29.779703924050633, -1.396612068965517 ], [ 29.779434430379748, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1901, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.396612068965517 ], [ 29.779703924050633, -1.396881551724138 ], [ 29.779973417721518, -1.396881551724138 ], [ 29.779973417721518, -1.396612068965517 ], [ 29.779703924050633, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1902, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.396612068965517 ], [ 29.779973417721518, -1.396881551724138 ], [ 29.780242911392406, -1.396881551724138 ], [ 29.780242911392406, -1.396612068965517 ], [ 29.779973417721518, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1903, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.396612068965517 ], [ 29.780242911392406, -1.396881551724138 ], [ 29.780512405063291, -1.396881551724138 ], [ 29.780512405063291, -1.396612068965517 ], [ 29.780242911392406, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1904, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.396612068965517 ], [ 29.780512405063291, -1.396881551724138 ], [ 29.780781898734176, -1.396881551724138 ], [ 29.780781898734176, -1.396612068965517 ], [ 29.780512405063291, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1905, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.396612068965517 ], [ 29.780781898734176, -1.396881551724138 ], [ 29.781051392405065, -1.396881551724138 ], [ 29.781051392405065, -1.396612068965517 ], [ 29.780781898734176, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1906, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.396612068965517 ], [ 29.781051392405065, -1.396881551724138 ], [ 29.78132088607595, -1.396881551724138 ], [ 29.78132088607595, -1.396612068965517 ], [ 29.781051392405065, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1907, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.396612068965517 ], [ 29.78132088607595, -1.396881551724138 ], [ 29.781590379746834, -1.396881551724138 ], [ 29.781590379746834, -1.396612068965517 ], [ 29.78132088607595, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1908, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.396612068965517 ], [ 29.781590379746834, -1.396881551724138 ], [ 29.781859873417723, -1.396881551724138 ], [ 29.781859873417723, -1.396612068965517 ], [ 29.781590379746834, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1909, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.396612068965517 ], [ 29.781859873417723, -1.396881551724138 ], [ 29.782129367088608, -1.396881551724138 ], [ 29.782129367088608, -1.396612068965517 ], [ 29.781859873417723, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1910, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.396612068965517 ], [ 29.782129367088608, -1.396881551724138 ], [ 29.784554810126583, -1.396881551724138 ], [ 29.784554810126583, -1.396612068965517 ], [ 29.782129367088608, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1911, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.396612068965517 ], [ 29.784554810126583, -1.396881551724138 ], [ 29.784824303797468, -1.396881551724138 ], [ 29.784824303797468, -1.396612068965517 ], [ 29.784554810126583, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1912, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.396612068965517 ], [ 29.784824303797468, -1.396881551724138 ], [ 29.785093797468356, -1.396881551724138 ], [ 29.785093797468356, -1.396612068965517 ], [ 29.784824303797468, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1913, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.396612068965517 ], [ 29.785093797468356, -1.396881551724138 ], [ 29.785363291139241, -1.396881551724138 ], [ 29.785363291139241, -1.396612068965517 ], [ 29.785093797468356, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1914, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.396612068965517 ], [ 29.785363291139241, -1.396881551724138 ], [ 29.785632784810126, -1.396881551724138 ], [ 29.785632784810126, -1.396612068965517 ], [ 29.785363291139241, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1915, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.396073103448276 ], [ 29.786171772151899, -1.396073103448276 ], [ 29.786171772151899, -1.397151034482759 ], [ 29.785902278481014, -1.397151034482759 ], [ 29.785902278481014, -1.396881551724138 ], [ 29.785632784810126, -1.396881551724138 ], [ 29.785632784810126, -1.396612068965517 ], [ 29.785902278481014, -1.396612068965517 ], [ 29.785902278481014, -1.396073103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1916, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.396612068965517 ], [ 29.792909113924051, -1.396881551724138 ], [ 29.793178607594935, -1.396881551724138 ], [ 29.793178607594935, -1.396612068965517 ], [ 29.792909113924051, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1917, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.396612068965517 ], [ 29.793178607594935, -1.396881551724138 ], [ 29.793448101265824, -1.396881551724138 ], [ 29.793448101265824, -1.396612068965517 ], [ 29.793178607594935, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1918, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.396612068965517 ], [ 29.793448101265824, -1.396881551724138 ], [ 29.794526075949367, -1.396881551724138 ], [ 29.794526075949367, -1.396612068965517 ], [ 29.793448101265824, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1919, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.396612068965517 ], [ 29.794526075949367, -1.396881551724138 ], [ 29.794795569620252, -1.396881551724138 ], [ 29.794795569620252, -1.396612068965517 ], [ 29.794526075949367, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1920, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.396612068965517 ], [ 29.794795569620252, -1.396881551724138 ], [ 29.79506506329114, -1.396881551724138 ], [ 29.79506506329114, -1.396612068965517 ], [ 29.794795569620252, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.396612068965517 ], [ 29.796143037974684, -1.397151034482759 ], [ 29.796682025316457, -1.397151034482759 ], [ 29.796682025316457, -1.396881551724138 ], [ 29.796412531645569, -1.396881551724138 ], [ 29.796412531645569, -1.396612068965517 ], [ 29.796143037974684, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1922, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.396612068965517 ], [ 29.796412531645569, -1.396881551724138 ], [ 29.796682025316457, -1.396881551724138 ], [ 29.796682025316457, -1.396612068965517 ], [ 29.796412531645569, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1923, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.396612068965517 ], [ 29.796682025316457, -1.396881551724138 ], [ 29.796951518987342, -1.396881551724138 ], [ 29.796951518987342, -1.396612068965517 ], [ 29.796682025316457, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1924, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.396612068965517 ], [ 29.796951518987342, -1.396881551724138 ], [ 29.797221012658227, -1.396881551724138 ], [ 29.797221012658227, -1.396612068965517 ], [ 29.796951518987342, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1925, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.396612068965517 ], [ 29.797221012658227, -1.396881551724138 ], [ 29.797490506329115, -1.396881551724138 ], [ 29.797490506329115, -1.396612068965517 ], [ 29.797221012658227, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1926, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.396612068965517 ], [ 29.797490506329115, -1.396881551724138 ], [ 29.79776, -1.396881551724138 ], [ 29.79776, -1.396612068965517 ], [ 29.797490506329115, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1927, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.396612068965517 ], [ 29.798029493670885, -1.397151034482759 ], [ 29.798298987341774, -1.397151034482759 ], [ 29.798298987341774, -1.396612068965517 ], [ 29.798029493670885, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1928, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.396881551724138 ], [ 29.766229240506327, -1.397151034482759 ], [ 29.766498734177215, -1.397151034482759 ], [ 29.766498734177215, -1.396881551724138 ], [ 29.766229240506327, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1929, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.396881551724138 ], [ 29.766498734177215, -1.397151034482759 ], [ 29.7667682278481, -1.397151034482759 ], [ 29.7667682278481, -1.396881551724138 ], [ 29.766498734177215, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1930, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.396881551724138 ], [ 29.7667682278481, -1.397151034482759 ], [ 29.767037721518985, -1.397151034482759 ], [ 29.767037721518985, -1.396881551724138 ], [ 29.7667682278481, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1931, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.396881551724138 ], [ 29.767037721518985, -1.397151034482759 ], [ 29.767307215189874, -1.397151034482759 ], [ 29.767307215189874, -1.396881551724138 ], [ 29.767037721518985, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1932, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.396881551724138 ], [ 29.767307215189874, -1.397151034482759 ], [ 29.767576708860759, -1.397151034482759 ], [ 29.767576708860759, -1.396881551724138 ], [ 29.767307215189874, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1933, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.396881551724138 ], [ 29.767576708860759, -1.397151034482759 ], [ 29.767846202531643, -1.397151034482759 ], [ 29.767846202531643, -1.396881551724138 ], [ 29.767576708860759, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1934, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.396881551724138 ], [ 29.767846202531643, -1.397151034482759 ], [ 29.768115696202532, -1.397151034482759 ], [ 29.768115696202532, -1.396881551724138 ], [ 29.767846202531643, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1935, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.396881551724138 ], [ 29.768115696202532, -1.397151034482759 ], [ 29.768385189873417, -1.397151034482759 ], [ 29.768385189873417, -1.396881551724138 ], [ 29.768115696202532, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1936, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.396881551724138 ], [ 29.768385189873417, -1.397151034482759 ], [ 29.768654683544302, -1.397151034482759 ], [ 29.768654683544302, -1.396881551724138 ], [ 29.768385189873417, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1937, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.396881551724138 ], [ 29.768654683544302, -1.397151034482759 ], [ 29.76892417721519, -1.397151034482759 ], [ 29.76892417721519, -1.396881551724138 ], [ 29.768654683544302, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1938, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.396881551724138 ], [ 29.76892417721519, -1.397151034482759 ], [ 29.769193670886075, -1.397151034482759 ], [ 29.769193670886075, -1.396881551724138 ], [ 29.76892417721519, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1939, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.396881551724138 ], [ 29.769193670886075, -1.397151034482759 ], [ 29.76946316455696, -1.397151034482759 ], [ 29.76946316455696, -1.396881551724138 ], [ 29.769193670886075, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1940, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.396881551724138 ], [ 29.76946316455696, -1.397151034482759 ], [ 29.769732658227849, -1.397151034482759 ], [ 29.769732658227849, -1.396881551724138 ], [ 29.76946316455696, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1941, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.396881551724138 ], [ 29.769732658227849, -1.397151034482759 ], [ 29.770002151898733, -1.397151034482759 ], [ 29.770002151898733, -1.396881551724138 ], [ 29.769732658227849, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1942, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.396881551724138 ], [ 29.770002151898733, -1.397151034482759 ], [ 29.770271645569618, -1.397151034482759 ], [ 29.770271645569618, -1.396881551724138 ], [ 29.770002151898733, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1943, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.396881551724138 ], [ 29.770271645569618, -1.397151034482759 ], [ 29.770541139240507, -1.397151034482759 ], [ 29.770541139240507, -1.396881551724138 ], [ 29.770271645569618, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1944, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.396881551724138 ], [ 29.770541139240507, -1.397151034482759 ], [ 29.770810632911392, -1.397151034482759 ], [ 29.770810632911392, -1.396881551724138 ], [ 29.770541139240507, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1945, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.396881551724138 ], [ 29.770810632911392, -1.397151034482759 ], [ 29.771080126582277, -1.397151034482759 ], [ 29.771080126582277, -1.396881551724138 ], [ 29.770810632911392, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1946, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.396881551724138 ], [ 29.771080126582277, -1.397151034482759 ], [ 29.771349620253165, -1.397151034482759 ], [ 29.771349620253165, -1.396881551724138 ], [ 29.771080126582277, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1947, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.396881551724138 ], [ 29.771349620253165, -1.397151034482759 ], [ 29.77161911392405, -1.397151034482759 ], [ 29.77161911392405, -1.396881551724138 ], [ 29.771349620253165, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1948, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.396881551724138 ], [ 29.77161911392405, -1.397151034482759 ], [ 29.771888607594935, -1.397151034482759 ], [ 29.771888607594935, -1.396881551724138 ], [ 29.77161911392405, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1949, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.396881551724138 ], [ 29.771888607594935, -1.397151034482759 ], [ 29.772158101265823, -1.397151034482759 ], [ 29.772158101265823, -1.396881551724138 ], [ 29.771888607594935, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1950, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.396881551724138 ], [ 29.772158101265823, -1.397151034482759 ], [ 29.772427594936708, -1.397151034482759 ], [ 29.772427594936708, -1.396881551724138 ], [ 29.772158101265823, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1951, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.396881551724138 ], [ 29.772427594936708, -1.397151034482759 ], [ 29.772697088607593, -1.397151034482759 ], [ 29.772697088607593, -1.396881551724138 ], [ 29.772427594936708, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1952, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.396881551724138 ], [ 29.772697088607593, -1.397151034482759 ], [ 29.772966582278482, -1.397151034482759 ], [ 29.772966582278482, -1.396881551724138 ], [ 29.772697088607593, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1953, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.396881551724138 ], [ 29.772966582278482, -1.397151034482759 ], [ 29.773236075949367, -1.397151034482759 ], [ 29.773236075949367, -1.396881551724138 ], [ 29.772966582278482, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1954, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.396881551724138 ], [ 29.773236075949367, -1.397151034482759 ], [ 29.773505569620252, -1.397151034482759 ], [ 29.773505569620252, -1.396881551724138 ], [ 29.773236075949367, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1955, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.396881551724138 ], [ 29.773505569620252, -1.397151034482759 ], [ 29.77377506329114, -1.397151034482759 ], [ 29.77377506329114, -1.396881551724138 ], [ 29.773505569620252, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1956, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.396881551724138 ], [ 29.77377506329114, -1.397151034482759 ], [ 29.774044556962025, -1.397151034482759 ], [ 29.774044556962025, -1.396881551724138 ], [ 29.77377506329114, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1957, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.396881551724138 ], [ 29.774044556962025, -1.397151034482759 ], [ 29.77431405063291, -1.397151034482759 ], [ 29.77431405063291, -1.396881551724138 ], [ 29.774044556962025, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1958, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.396881551724138 ], [ 29.77431405063291, -1.397151034482759 ], [ 29.774853037974683, -1.397151034482759 ], [ 29.774853037974683, -1.396881551724138 ], [ 29.77431405063291, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1959, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.396881551724138 ], [ 29.774853037974683, -1.397151034482759 ], [ 29.775122531645568, -1.397151034482759 ], [ 29.775122531645568, -1.396881551724138 ], [ 29.774853037974683, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1960, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.396881551724138 ], [ 29.775122531645568, -1.397151034482759 ], [ 29.775392025316457, -1.397151034482759 ], [ 29.775392025316457, -1.396881551724138 ], [ 29.775122531645568, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1961, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.396881551724138 ], [ 29.775392025316457, -1.397151034482759 ], [ 29.775661518987341, -1.397151034482759 ], [ 29.775661518987341, -1.396881551724138 ], [ 29.775392025316457, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1962, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.396881551724138 ], [ 29.775661518987341, -1.397151034482759 ], [ 29.775931012658226, -1.397151034482759 ], [ 29.775931012658226, -1.396881551724138 ], [ 29.775661518987341, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1963, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.396881551724138 ], [ 29.775931012658226, -1.397151034482759 ], [ 29.77647, -1.397151034482759 ], [ 29.77647, -1.396881551724138 ], [ 29.775931012658226, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1964, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.396881551724138 ], [ 29.77647, -1.397151034482759 ], [ 29.778086962025316, -1.397151034482759 ], [ 29.778086962025316, -1.396881551724138 ], [ 29.77647, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1965, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.396881551724138 ], [ 29.778086962025316, -1.397151034482759 ], [ 29.778356455696201, -1.397151034482759 ], [ 29.778356455696201, -1.397420517241379 ], [ 29.77862594936709, -1.397420517241379 ], [ 29.77862594936709, -1.396881551724138 ], [ 29.778086962025316, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1966, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.396881551724138 ], [ 29.77862594936709, -1.397151034482759 ], [ 29.778895443037975, -1.397151034482759 ], [ 29.778895443037975, -1.396881551724138 ], [ 29.77862594936709, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1967, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.396881551724138 ], [ 29.778895443037975, -1.397151034482759 ], [ 29.77916493670886, -1.397151034482759 ], [ 29.77916493670886, -1.396881551724138 ], [ 29.778895443037975, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1968, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.396881551724138 ], [ 29.77916493670886, -1.397151034482759 ], [ 29.779434430379748, -1.397151034482759 ], [ 29.779434430379748, -1.396881551724138 ], [ 29.77916493670886, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1969, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.396881551724138 ], [ 29.779434430379748, -1.397151034482759 ], [ 29.779703924050633, -1.397151034482759 ], [ 29.779703924050633, -1.396881551724138 ], [ 29.779434430379748, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1970, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.396881551724138 ], [ 29.779703924050633, -1.397151034482759 ], [ 29.779973417721518, -1.397151034482759 ], [ 29.779973417721518, -1.396881551724138 ], [ 29.779703924050633, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1971, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.396881551724138 ], [ 29.779973417721518, -1.397151034482759 ], [ 29.780242911392406, -1.397151034482759 ], [ 29.780242911392406, -1.396881551724138 ], [ 29.779973417721518, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1972, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.396881551724138 ], [ 29.780242911392406, -1.397151034482759 ], [ 29.780512405063291, -1.397151034482759 ], [ 29.780512405063291, -1.396881551724138 ], [ 29.780242911392406, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1973, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.396881551724138 ], [ 29.780512405063291, -1.397151034482759 ], [ 29.780781898734176, -1.397151034482759 ], [ 29.780781898734176, -1.396881551724138 ], [ 29.780512405063291, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1974, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.396881551724138 ], [ 29.780781898734176, -1.397151034482759 ], [ 29.781051392405065, -1.397151034482759 ], [ 29.781051392405065, -1.396881551724138 ], [ 29.780781898734176, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1975, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.396881551724138 ], [ 29.781051392405065, -1.397151034482759 ], [ 29.78132088607595, -1.397151034482759 ], [ 29.78132088607595, -1.396881551724138 ], [ 29.781051392405065, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1976, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.396881551724138 ], [ 29.78132088607595, -1.397151034482759 ], [ 29.781590379746834, -1.397151034482759 ], [ 29.781590379746834, -1.396881551724138 ], [ 29.78132088607595, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1977, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.396881551724138 ], [ 29.781590379746834, -1.397151034482759 ], [ 29.781859873417723, -1.397151034482759 ], [ 29.781859873417723, -1.396881551724138 ], [ 29.781590379746834, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1978, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.396881551724138 ], [ 29.781859873417723, -1.397151034482759 ], [ 29.782129367088608, -1.397151034482759 ], [ 29.782129367088608, -1.396881551724138 ], [ 29.781859873417723, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1979, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.396881551724138 ], [ 29.782129367088608, -1.397151034482759 ], [ 29.782398860759493, -1.397151034482759 ], [ 29.782398860759493, -1.396881551724138 ], [ 29.782129367088608, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1980, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.396881551724138 ], [ 29.782398860759493, -1.397151034482759 ], [ 29.784285316455698, -1.397151034482759 ], [ 29.784285316455698, -1.396881551724138 ], [ 29.782398860759493, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1981, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.396881551724138 ], [ 29.784285316455698, -1.397151034482759 ], [ 29.784554810126583, -1.397151034482759 ], [ 29.784554810126583, -1.396881551724138 ], [ 29.784285316455698, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1982, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.396881551724138 ], [ 29.784554810126583, -1.397151034482759 ], [ 29.784824303797468, -1.397151034482759 ], [ 29.784824303797468, -1.396881551724138 ], [ 29.784554810126583, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1983, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.396881551724138 ], [ 29.784824303797468, -1.397151034482759 ], [ 29.785093797468356, -1.397151034482759 ], [ 29.785093797468356, -1.396881551724138 ], [ 29.784824303797468, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1984, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.396881551724138 ], [ 29.785093797468356, -1.397151034482759 ], [ 29.785363291139241, -1.397151034482759 ], [ 29.785363291139241, -1.396881551724138 ], [ 29.785093797468356, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1985, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.396881551724138 ], [ 29.785363291139241, -1.397151034482759 ], [ 29.785632784810126, -1.397151034482759 ], [ 29.785632784810126, -1.396881551724138 ], [ 29.785363291139241, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1986, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.396881551724138 ], [ 29.785632784810126, -1.397151034482759 ], [ 29.785902278481014, -1.397151034482759 ], [ 29.785902278481014, -1.396881551724138 ], [ 29.785632784810126, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1987, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.396881551724138 ], [ 29.793178607594935, -1.397151034482759 ], [ 29.794795569620252, -1.397151034482759 ], [ 29.794795569620252, -1.396881551724138 ], [ 29.793178607594935, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1988, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.396881551724138 ], [ 29.796682025316457, -1.397151034482759 ], [ 29.796951518987342, -1.397151034482759 ], [ 29.796951518987342, -1.396881551724138 ], [ 29.796682025316457, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1989, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.396881551724138 ], [ 29.796951518987342, -1.397151034482759 ], [ 29.797221012658227, -1.397151034482759 ], [ 29.797221012658227, -1.396881551724138 ], [ 29.796951518987342, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1990, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.396881551724138 ], [ 29.797221012658227, -1.397151034482759 ], [ 29.797490506329115, -1.397151034482759 ], [ 29.797490506329115, -1.396881551724138 ], [ 29.797221012658227, -1.396881551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1991, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.396612068965517 ], [ 29.798029493670885, -1.396612068965517 ], [ 29.798029493670885, -1.397151034482759 ], [ 29.797490506329115, -1.397151034482759 ], [ 29.797490506329115, -1.396881551724138 ], [ 29.79776, -1.396881551724138 ], [ 29.79776, -1.396612068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1992, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.397151034482759 ], [ 29.765959746835442, -1.397420517241379 ], [ 29.766229240506327, -1.397420517241379 ], [ 29.766229240506327, -1.397151034482759 ], [ 29.765959746835442, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1993, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.397151034482759 ], [ 29.766229240506327, -1.397420517241379 ], [ 29.766498734177215, -1.397420517241379 ], [ 29.766498734177215, -1.397151034482759 ], [ 29.766229240506327, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1994, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.397151034482759 ], [ 29.766498734177215, -1.397420517241379 ], [ 29.7667682278481, -1.397420517241379 ], [ 29.7667682278481, -1.397151034482759 ], [ 29.766498734177215, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1995, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.397151034482759 ], [ 29.7667682278481, -1.397420517241379 ], [ 29.767037721518985, -1.397420517241379 ], [ 29.767037721518985, -1.397151034482759 ], [ 29.7667682278481, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1996, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.397151034482759 ], [ 29.767037721518985, -1.397420517241379 ], [ 29.767307215189874, -1.397420517241379 ], [ 29.767307215189874, -1.397151034482759 ], [ 29.767037721518985, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1997, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.397151034482759 ], [ 29.767307215189874, -1.397420517241379 ], [ 29.767576708860759, -1.397420517241379 ], [ 29.767576708860759, -1.397151034482759 ], [ 29.767307215189874, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1998, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.397151034482759 ], [ 29.767576708860759, -1.397420517241379 ], [ 29.767846202531643, -1.397420517241379 ], [ 29.767846202531643, -1.397151034482759 ], [ 29.767576708860759, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 1999, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.397151034482759 ], [ 29.767846202531643, -1.397420517241379 ], [ 29.768115696202532, -1.397420517241379 ], [ 29.768115696202532, -1.397151034482759 ], [ 29.767846202531643, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2000, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.397151034482759 ], [ 29.768115696202532, -1.397420517241379 ], [ 29.768385189873417, -1.397420517241379 ], [ 29.768385189873417, -1.397151034482759 ], [ 29.768115696202532, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2001, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.397151034482759 ], [ 29.768385189873417, -1.397420517241379 ], [ 29.768654683544302, -1.397420517241379 ], [ 29.768654683544302, -1.397151034482759 ], [ 29.768385189873417, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2002, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.397151034482759 ], [ 29.768654683544302, -1.397420517241379 ], [ 29.76892417721519, -1.397420517241379 ], [ 29.76892417721519, -1.397151034482759 ], [ 29.768654683544302, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2003, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.397151034482759 ], [ 29.76892417721519, -1.397420517241379 ], [ 29.769193670886075, -1.397420517241379 ], [ 29.769193670886075, -1.397151034482759 ], [ 29.76892417721519, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2004, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.397151034482759 ], [ 29.769193670886075, -1.397420517241379 ], [ 29.76946316455696, -1.397420517241379 ], [ 29.76946316455696, -1.397151034482759 ], [ 29.769193670886075, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2005, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.397151034482759 ], [ 29.76946316455696, -1.397420517241379 ], [ 29.769732658227849, -1.397420517241379 ], [ 29.769732658227849, -1.397151034482759 ], [ 29.76946316455696, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2006, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.397151034482759 ], [ 29.769732658227849, -1.397420517241379 ], [ 29.770002151898733, -1.397420517241379 ], [ 29.770002151898733, -1.397151034482759 ], [ 29.769732658227849, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2007, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.397151034482759 ], [ 29.770002151898733, -1.397420517241379 ], [ 29.770271645569618, -1.397420517241379 ], [ 29.770271645569618, -1.397151034482759 ], [ 29.770002151898733, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2008, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.397151034482759 ], [ 29.770271645569618, -1.397420517241379 ], [ 29.770541139240507, -1.397420517241379 ], [ 29.770541139240507, -1.397151034482759 ], [ 29.770271645569618, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2009, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.397151034482759 ], [ 29.770541139240507, -1.397420517241379 ], [ 29.770810632911392, -1.397420517241379 ], [ 29.770810632911392, -1.397151034482759 ], [ 29.770541139240507, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2010, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.397151034482759 ], [ 29.770810632911392, -1.397420517241379 ], [ 29.771080126582277, -1.397420517241379 ], [ 29.771080126582277, -1.397151034482759 ], [ 29.770810632911392, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2011, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.397151034482759 ], [ 29.771080126582277, -1.397420517241379 ], [ 29.771349620253165, -1.397420517241379 ], [ 29.771349620253165, -1.397151034482759 ], [ 29.771080126582277, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2012, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.397151034482759 ], [ 29.771349620253165, -1.397420517241379 ], [ 29.77161911392405, -1.397420517241379 ], [ 29.77161911392405, -1.397151034482759 ], [ 29.771349620253165, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2013, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.397151034482759 ], [ 29.77161911392405, -1.397420517241379 ], [ 29.771888607594935, -1.397420517241379 ], [ 29.771888607594935, -1.397151034482759 ], [ 29.77161911392405, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2014, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.397151034482759 ], [ 29.771888607594935, -1.397420517241379 ], [ 29.772158101265823, -1.397420517241379 ], [ 29.772158101265823, -1.397151034482759 ], [ 29.771888607594935, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2015, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.397151034482759 ], [ 29.772158101265823, -1.397420517241379 ], [ 29.772427594936708, -1.397420517241379 ], [ 29.772427594936708, -1.397151034482759 ], [ 29.772158101265823, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2016, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.397151034482759 ], [ 29.772427594936708, -1.397420517241379 ], [ 29.772697088607593, -1.397420517241379 ], [ 29.772697088607593, -1.397151034482759 ], [ 29.772427594936708, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2017, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.397151034482759 ], [ 29.772697088607593, -1.397420517241379 ], [ 29.772966582278482, -1.397420517241379 ], [ 29.772966582278482, -1.397151034482759 ], [ 29.772697088607593, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2018, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.397151034482759 ], [ 29.772966582278482, -1.397420517241379 ], [ 29.773236075949367, -1.397420517241379 ], [ 29.773236075949367, -1.397151034482759 ], [ 29.772966582278482, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2019, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.397151034482759 ], [ 29.773236075949367, -1.397420517241379 ], [ 29.773505569620252, -1.397420517241379 ], [ 29.773505569620252, -1.397151034482759 ], [ 29.773236075949367, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2020, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.397151034482759 ], [ 29.773505569620252, -1.397420517241379 ], [ 29.77377506329114, -1.397420517241379 ], [ 29.77377506329114, -1.397151034482759 ], [ 29.773505569620252, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2021, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.397151034482759 ], [ 29.77377506329114, -1.397420517241379 ], [ 29.774044556962025, -1.397420517241379 ], [ 29.774044556962025, -1.397151034482759 ], [ 29.77377506329114, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2022, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.397151034482759 ], [ 29.774044556962025, -1.397420517241379 ], [ 29.77431405063291, -1.397420517241379 ], [ 29.77431405063291, -1.397151034482759 ], [ 29.774044556962025, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2023, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.397151034482759 ], [ 29.77431405063291, -1.397420517241379 ], [ 29.774853037974683, -1.397420517241379 ], [ 29.774853037974683, -1.397151034482759 ], [ 29.77431405063291, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2024, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.397151034482759 ], [ 29.774853037974683, -1.397420517241379 ], [ 29.775122531645568, -1.397420517241379 ], [ 29.775122531645568, -1.397151034482759 ], [ 29.774853037974683, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2025, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.397151034482759 ], [ 29.775122531645568, -1.397420517241379 ], [ 29.775392025316457, -1.397420517241379 ], [ 29.775392025316457, -1.397151034482759 ], [ 29.775122531645568, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2026, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.397151034482759 ], [ 29.775392025316457, -1.397420517241379 ], [ 29.775661518987341, -1.397420517241379 ], [ 29.775661518987341, -1.397151034482759 ], [ 29.775392025316457, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2027, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.397151034482759 ], [ 29.775661518987341, -1.397420517241379 ], [ 29.775931012658226, -1.397420517241379 ], [ 29.775931012658226, -1.397151034482759 ], [ 29.775661518987341, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2028, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.397151034482759 ], [ 29.775931012658226, -1.397420517241379 ], [ 29.776200506329115, -1.397420517241379 ], [ 29.776200506329115, -1.397151034482759 ], [ 29.775931012658226, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2029, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.397151034482759 ], [ 29.776200506329115, -1.397420517241379 ], [ 29.77647, -1.397420517241379 ], [ 29.77647, -1.397151034482759 ], [ 29.776200506329115, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2030, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.397151034482759 ], [ 29.77647, -1.397420517241379 ], [ 29.778086962025316, -1.397420517241379 ], [ 29.778086962025316, -1.397151034482759 ], [ 29.77647, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2031, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.397151034482759 ], [ 29.778086962025316, -1.397420517241379 ], [ 29.778356455696201, -1.397420517241379 ], [ 29.778356455696201, -1.397151034482759 ], [ 29.778086962025316, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2032, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.397151034482759 ], [ 29.77862594936709, -1.39769 ], [ 29.778895443037975, -1.39769 ], [ 29.778895443037975, -1.397151034482759 ], [ 29.77862594936709, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2033, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.397151034482759 ], [ 29.778895443037975, -1.397420517241379 ], [ 29.77916493670886, -1.397420517241379 ], [ 29.77916493670886, -1.397151034482759 ], [ 29.778895443037975, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2034, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.397151034482759 ], [ 29.77916493670886, -1.397420517241379 ], [ 29.779434430379748, -1.397420517241379 ], [ 29.779434430379748, -1.397151034482759 ], [ 29.77916493670886, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2035, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.397151034482759 ], [ 29.779434430379748, -1.39769 ], [ 29.779703924050633, -1.39769 ], [ 29.779703924050633, -1.397151034482759 ], [ 29.779434430379748, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2036, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.397151034482759 ], [ 29.779703924050633, -1.397420517241379 ], [ 29.779973417721518, -1.397420517241379 ], [ 29.779973417721518, -1.397151034482759 ], [ 29.779703924050633, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2037, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.397151034482759 ], [ 29.779973417721518, -1.397420517241379 ], [ 29.780242911392406, -1.397420517241379 ], [ 29.780242911392406, -1.397151034482759 ], [ 29.779973417721518, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2038, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.397151034482759 ], [ 29.780242911392406, -1.397420517241379 ], [ 29.780512405063291, -1.397420517241379 ], [ 29.780512405063291, -1.397151034482759 ], [ 29.780242911392406, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2039, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.397151034482759 ], [ 29.780512405063291, -1.397420517241379 ], [ 29.780781898734176, -1.397420517241379 ], [ 29.780781898734176, -1.397151034482759 ], [ 29.780512405063291, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2040, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.397151034482759 ], [ 29.780781898734176, -1.397420517241379 ], [ 29.781051392405065, -1.397420517241379 ], [ 29.781051392405065, -1.397151034482759 ], [ 29.780781898734176, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2041, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.397151034482759 ], [ 29.781051392405065, -1.397420517241379 ], [ 29.78132088607595, -1.397420517241379 ], [ 29.78132088607595, -1.397151034482759 ], [ 29.781051392405065, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2042, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.397151034482759 ], [ 29.78132088607595, -1.397420517241379 ], [ 29.781590379746834, -1.397420517241379 ], [ 29.781590379746834, -1.397151034482759 ], [ 29.78132088607595, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2043, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.397151034482759 ], [ 29.781590379746834, -1.397420517241379 ], [ 29.781859873417723, -1.397420517241379 ], [ 29.781859873417723, -1.397151034482759 ], [ 29.781590379746834, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2044, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.397151034482759 ], [ 29.781859873417723, -1.397420517241379 ], [ 29.782129367088608, -1.397420517241379 ], [ 29.782129367088608, -1.397151034482759 ], [ 29.781859873417723, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2045, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.397151034482759 ], [ 29.782129367088608, -1.397420517241379 ], [ 29.782398860759493, -1.397420517241379 ], [ 29.782398860759493, -1.397151034482759 ], [ 29.782129367088608, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2046, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.397151034482759 ], [ 29.782398860759493, -1.397420517241379 ], [ 29.784285316455698, -1.397420517241379 ], [ 29.784285316455698, -1.397151034482759 ], [ 29.782398860759493, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2047, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.397151034482759 ], [ 29.784285316455698, -1.397420517241379 ], [ 29.784554810126583, -1.397420517241379 ], [ 29.784554810126583, -1.397151034482759 ], [ 29.784285316455698, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2048, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.397151034482759 ], [ 29.784554810126583, -1.397420517241379 ], [ 29.784824303797468, -1.397420517241379 ], [ 29.784824303797468, -1.397151034482759 ], [ 29.784554810126583, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2049, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.397151034482759 ], [ 29.784824303797468, -1.397420517241379 ], [ 29.785093797468356, -1.397420517241379 ], [ 29.785093797468356, -1.397151034482759 ], [ 29.784824303797468, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2050, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.397151034482759 ], [ 29.785093797468356, -1.397420517241379 ], [ 29.785363291139241, -1.397420517241379 ], [ 29.785363291139241, -1.397151034482759 ], [ 29.785093797468356, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2051, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.397151034482759 ], [ 29.785363291139241, -1.397420517241379 ], [ 29.785632784810126, -1.397420517241379 ], [ 29.785632784810126, -1.397151034482759 ], [ 29.785363291139241, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2052, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.397151034482759 ], [ 29.785632784810126, -1.397420517241379 ], [ 29.785902278481014, -1.397420517241379 ], [ 29.785902278481014, -1.397151034482759 ], [ 29.785632784810126, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2053, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.397151034482759 ], [ 29.785902278481014, -1.397420517241379 ], [ 29.786171772151899, -1.397420517241379 ], [ 29.786171772151899, -1.397151034482759 ], [ 29.785902278481014, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2054, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.397151034482759 ], [ 29.796682025316457, -1.397420517241379 ], [ 29.797221012658227, -1.397420517241379 ], [ 29.797221012658227, -1.397151034482759 ], [ 29.796682025316457, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2055, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.397151034482759 ], [ 29.797221012658227, -1.397420517241379 ], [ 29.797490506329115, -1.397420517241379 ], [ 29.797490506329115, -1.397151034482759 ], [ 29.797221012658227, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2056, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.397151034482759 ], [ 29.797490506329115, -1.397420517241379 ], [ 29.79776, -1.397420517241379 ], [ 29.79776, -1.397151034482759 ], [ 29.797490506329115, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2057, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.397151034482759 ], [ 29.79776, -1.397420517241379 ], [ 29.798029493670885, -1.397420517241379 ], [ 29.798029493670885, -1.397151034482759 ], [ 29.79776, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2058, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.397151034482759 ], [ 29.798029493670885, -1.397420517241379 ], [ 29.798298987341774, -1.397420517241379 ], [ 29.798298987341774, -1.397151034482759 ], [ 29.798029493670885, -1.397151034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2059, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.397420517241379 ], [ 29.765420759493672, -1.398228965517242 ], [ 29.765959746835442, -1.398228965517242 ], [ 29.765959746835442, -1.397420517241379 ], [ 29.765420759493672, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2060, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.397420517241379 ], [ 29.765959746835442, -1.39769 ], [ 29.766229240506327, -1.39769 ], [ 29.766229240506327, -1.397420517241379 ], [ 29.765959746835442, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2061, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.397420517241379 ], [ 29.766229240506327, -1.39769 ], [ 29.766498734177215, -1.39769 ], [ 29.766498734177215, -1.397420517241379 ], [ 29.766229240506327, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2062, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.397420517241379 ], [ 29.766498734177215, -1.39769 ], [ 29.7667682278481, -1.39769 ], [ 29.7667682278481, -1.397420517241379 ], [ 29.766498734177215, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2063, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.397420517241379 ], [ 29.7667682278481, -1.39769 ], [ 29.767037721518985, -1.39769 ], [ 29.767037721518985, -1.397420517241379 ], [ 29.7667682278481, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2064, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.397420517241379 ], [ 29.767037721518985, -1.39769 ], [ 29.767307215189874, -1.39769 ], [ 29.767307215189874, -1.397420517241379 ], [ 29.767037721518985, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2065, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.397420517241379 ], [ 29.767307215189874, -1.39769 ], [ 29.767576708860759, -1.39769 ], [ 29.767576708860759, -1.397420517241379 ], [ 29.767307215189874, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2066, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.397420517241379 ], [ 29.767576708860759, -1.39769 ], [ 29.767846202531643, -1.39769 ], [ 29.767846202531643, -1.397420517241379 ], [ 29.767576708860759, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2067, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.397420517241379 ], [ 29.767846202531643, -1.39769 ], [ 29.768115696202532, -1.39769 ], [ 29.768115696202532, -1.397420517241379 ], [ 29.767846202531643, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2068, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.397420517241379 ], [ 29.768115696202532, -1.39769 ], [ 29.768385189873417, -1.39769 ], [ 29.768385189873417, -1.397420517241379 ], [ 29.768115696202532, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2069, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.397420517241379 ], [ 29.768385189873417, -1.39769 ], [ 29.768654683544302, -1.39769 ], [ 29.768654683544302, -1.397420517241379 ], [ 29.768385189873417, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2070, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.397420517241379 ], [ 29.768654683544302, -1.39769 ], [ 29.76892417721519, -1.39769 ], [ 29.76892417721519, -1.397420517241379 ], [ 29.768654683544302, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2071, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.397420517241379 ], [ 29.76892417721519, -1.39769 ], [ 29.769193670886075, -1.39769 ], [ 29.769193670886075, -1.397420517241379 ], [ 29.76892417721519, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2072, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.397420517241379 ], [ 29.769193670886075, -1.39769 ], [ 29.76946316455696, -1.39769 ], [ 29.76946316455696, -1.397420517241379 ], [ 29.769193670886075, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2073, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.397420517241379 ], [ 29.76946316455696, -1.39769 ], [ 29.769732658227849, -1.39769 ], [ 29.769732658227849, -1.397420517241379 ], [ 29.76946316455696, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2074, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.397420517241379 ], [ 29.769732658227849, -1.39769 ], [ 29.770002151898733, -1.39769 ], [ 29.770002151898733, -1.397420517241379 ], [ 29.769732658227849, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2075, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.397420517241379 ], [ 29.770002151898733, -1.39769 ], [ 29.770271645569618, -1.39769 ], [ 29.770271645569618, -1.397420517241379 ], [ 29.770002151898733, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2076, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.397420517241379 ], [ 29.770271645569618, -1.39769 ], [ 29.770541139240507, -1.39769 ], [ 29.770541139240507, -1.397420517241379 ], [ 29.770271645569618, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2077, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.397420517241379 ], [ 29.770541139240507, -1.39769 ], [ 29.770810632911392, -1.39769 ], [ 29.770810632911392, -1.397420517241379 ], [ 29.770541139240507, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2078, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.397420517241379 ], [ 29.770810632911392, -1.39769 ], [ 29.771080126582277, -1.39769 ], [ 29.771080126582277, -1.397420517241379 ], [ 29.770810632911392, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2079, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.397420517241379 ], [ 29.771080126582277, -1.39769 ], [ 29.771349620253165, -1.39769 ], [ 29.771349620253165, -1.397420517241379 ], [ 29.771080126582277, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2080, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.397420517241379 ], [ 29.771349620253165, -1.39769 ], [ 29.77161911392405, -1.39769 ], [ 29.77161911392405, -1.397420517241379 ], [ 29.771349620253165, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2081, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.397420517241379 ], [ 29.77161911392405, -1.39769 ], [ 29.771888607594935, -1.39769 ], [ 29.771888607594935, -1.397420517241379 ], [ 29.77161911392405, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2082, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.397420517241379 ], [ 29.771888607594935, -1.39769 ], [ 29.772158101265823, -1.39769 ], [ 29.772158101265823, -1.397420517241379 ], [ 29.771888607594935, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2083, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.397420517241379 ], [ 29.772158101265823, -1.39769 ], [ 29.772427594936708, -1.39769 ], [ 29.772427594936708, -1.397420517241379 ], [ 29.772158101265823, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2084, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.397420517241379 ], [ 29.772427594936708, -1.39769 ], [ 29.772697088607593, -1.39769 ], [ 29.772697088607593, -1.397420517241379 ], [ 29.772427594936708, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2085, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.397420517241379 ], [ 29.772697088607593, -1.39769 ], [ 29.772966582278482, -1.39769 ], [ 29.772966582278482, -1.397420517241379 ], [ 29.772697088607593, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2086, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.397420517241379 ], [ 29.772966582278482, -1.39769 ], [ 29.773236075949367, -1.39769 ], [ 29.773236075949367, -1.397420517241379 ], [ 29.772966582278482, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2087, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.397420517241379 ], [ 29.773236075949367, -1.39769 ], [ 29.773505569620252, -1.39769 ], [ 29.773505569620252, -1.397420517241379 ], [ 29.773236075949367, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2088, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.397420517241379 ], [ 29.773505569620252, -1.39769 ], [ 29.77377506329114, -1.39769 ], [ 29.77377506329114, -1.397420517241379 ], [ 29.773505569620252, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2089, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.397420517241379 ], [ 29.77377506329114, -1.39769 ], [ 29.774044556962025, -1.39769 ], [ 29.774044556962025, -1.397420517241379 ], [ 29.77377506329114, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2090, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.397420517241379 ], [ 29.774044556962025, -1.39769 ], [ 29.77431405063291, -1.39769 ], [ 29.77431405063291, -1.397420517241379 ], [ 29.774044556962025, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2091, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.397420517241379 ], [ 29.77431405063291, -1.39769 ], [ 29.774853037974683, -1.39769 ], [ 29.774853037974683, -1.397420517241379 ], [ 29.77431405063291, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2092, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.397420517241379 ], [ 29.774853037974683, -1.39769 ], [ 29.775122531645568, -1.39769 ], [ 29.775122531645568, -1.397420517241379 ], [ 29.774853037974683, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2093, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.397420517241379 ], [ 29.775122531645568, -1.39769 ], [ 29.775392025316457, -1.39769 ], [ 29.775392025316457, -1.397420517241379 ], [ 29.775122531645568, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2094, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.397420517241379 ], [ 29.775392025316457, -1.39769 ], [ 29.775661518987341, -1.39769 ], [ 29.775661518987341, -1.397420517241379 ], [ 29.775392025316457, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2095, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.397420517241379 ], [ 29.775661518987341, -1.39769 ], [ 29.775931012658226, -1.39769 ], [ 29.775931012658226, -1.397420517241379 ], [ 29.775661518987341, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2096, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.397420517241379 ], [ 29.775931012658226, -1.39769 ], [ 29.776200506329115, -1.39769 ], [ 29.776200506329115, -1.397420517241379 ], [ 29.775931012658226, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2097, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.397420517241379 ], [ 29.776200506329115, -1.39769 ], [ 29.776739493670885, -1.39769 ], [ 29.776739493670885, -1.397420517241379 ], [ 29.776200506329115, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2098, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.397420517241379 ], [ 29.776739493670885, -1.39769 ], [ 29.777817468354431, -1.39769 ], [ 29.777817468354431, -1.397420517241379 ], [ 29.776739493670885, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2099, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.397420517241379 ], [ 29.777817468354431, -1.39769 ], [ 29.778086962025316, -1.39769 ], [ 29.778086962025316, -1.397420517241379 ], [ 29.777817468354431, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2100, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.397420517241379 ], [ 29.77916493670886, -1.397959482758621 ], [ 29.779434430379748, -1.397959482758621 ], [ 29.779434430379748, -1.397420517241379 ], [ 29.77916493670886, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2101, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.397420517241379 ], [ 29.779973417721518, -1.39769 ], [ 29.780242911392406, -1.39769 ], [ 29.780242911392406, -1.397420517241379 ], [ 29.779973417721518, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2102, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.397420517241379 ], [ 29.780242911392406, -1.39769 ], [ 29.780512405063291, -1.39769 ], [ 29.780512405063291, -1.397420517241379 ], [ 29.780242911392406, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2103, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.397420517241379 ], [ 29.780512405063291, -1.397959482758621 ], [ 29.780781898734176, -1.397959482758621 ], [ 29.780781898734176, -1.397420517241379 ], [ 29.780512405063291, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2104, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.397420517241379 ], [ 29.780781898734176, -1.39769 ], [ 29.781051392405065, -1.39769 ], [ 29.781051392405065, -1.397420517241379 ], [ 29.780781898734176, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2105, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.397420517241379 ], [ 29.781051392405065, -1.39769 ], [ 29.78132088607595, -1.39769 ], [ 29.78132088607595, -1.397420517241379 ], [ 29.781051392405065, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2106, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.397420517241379 ], [ 29.78132088607595, -1.39769 ], [ 29.781590379746834, -1.39769 ], [ 29.781590379746834, -1.397420517241379 ], [ 29.78132088607595, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2107, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.397420517241379 ], [ 29.781590379746834, -1.39769 ], [ 29.781859873417723, -1.39769 ], [ 29.781859873417723, -1.397420517241379 ], [ 29.781590379746834, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2108, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.397420517241379 ], [ 29.781859873417723, -1.39769 ], [ 29.782129367088608, -1.39769 ], [ 29.782129367088608, -1.397420517241379 ], [ 29.781859873417723, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2109, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.397420517241379 ], [ 29.782129367088608, -1.39769 ], [ 29.782398860759493, -1.39769 ], [ 29.782398860759493, -1.397420517241379 ], [ 29.782129367088608, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2110, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.397420517241379 ], [ 29.782398860759493, -1.39769 ], [ 29.784285316455698, -1.39769 ], [ 29.784285316455698, -1.397420517241379 ], [ 29.782398860759493, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2111, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.397420517241379 ], [ 29.784285316455698, -1.39769 ], [ 29.784554810126583, -1.39769 ], [ 29.784554810126583, -1.397420517241379 ], [ 29.784285316455698, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2112, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.397420517241379 ], [ 29.784554810126583, -1.39769 ], [ 29.784824303797468, -1.39769 ], [ 29.784824303797468, -1.397420517241379 ], [ 29.784554810126583, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2113, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.397420517241379 ], [ 29.784824303797468, -1.39769 ], [ 29.785093797468356, -1.39769 ], [ 29.785093797468356, -1.397420517241379 ], [ 29.784824303797468, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2114, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.397420517241379 ], [ 29.785093797468356, -1.39769 ], [ 29.785363291139241, -1.39769 ], [ 29.785363291139241, -1.397420517241379 ], [ 29.785093797468356, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2115, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.397420517241379 ], [ 29.785363291139241, -1.39769 ], [ 29.785632784810126, -1.39769 ], [ 29.785632784810126, -1.397420517241379 ], [ 29.785363291139241, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2116, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.397420517241379 ], [ 29.785632784810126, -1.39769 ], [ 29.785902278481014, -1.39769 ], [ 29.785902278481014, -1.397420517241379 ], [ 29.785632784810126, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2117, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.396342586206897 ], [ 29.786441265822784, -1.396342586206897 ], [ 29.786441265822784, -1.398228965517242 ], [ 29.786171772151899, -1.398228965517242 ], [ 29.786171772151899, -1.39769 ], [ 29.785902278481014, -1.39769 ], [ 29.785902278481014, -1.397420517241379 ], [ 29.786171772151899, -1.397420517241379 ], [ 29.786171772151899, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2118, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.397420517241379 ], [ 29.797221012658227, -1.39769 ], [ 29.79776, -1.39769 ], [ 29.79776, -1.397420517241379 ], [ 29.797221012658227, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2119, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.397420517241379 ], [ 29.79776, -1.39769 ], [ 29.798029493670885, -1.39769 ], [ 29.798029493670885, -1.397420517241379 ], [ 29.79776, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2120, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.397420517241379 ], [ 29.798029493670885, -1.39769 ], [ 29.798298987341774, -1.39769 ], [ 29.798298987341774, -1.397420517241379 ], [ 29.798029493670885, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2121, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.39769 ], [ 29.765959746835442, -1.397959482758621 ], [ 29.766229240506327, -1.397959482758621 ], [ 29.766229240506327, -1.39769 ], [ 29.765959746835442, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2122, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.39769 ], [ 29.766229240506327, -1.397959482758621 ], [ 29.766498734177215, -1.397959482758621 ], [ 29.766498734177215, -1.39769 ], [ 29.766229240506327, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2123, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.39769 ], [ 29.766498734177215, -1.397959482758621 ], [ 29.7667682278481, -1.397959482758621 ], [ 29.7667682278481, -1.39769 ], [ 29.766498734177215, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2124, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.39769 ], [ 29.767307215189874, -1.397959482758621 ], [ 29.767576708860759, -1.397959482758621 ], [ 29.767576708860759, -1.39769 ], [ 29.767307215189874, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2125, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.39769 ], [ 29.767576708860759, -1.397959482758621 ], [ 29.767846202531643, -1.397959482758621 ], [ 29.767846202531643, -1.39769 ], [ 29.767576708860759, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2126, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.39769 ], [ 29.767846202531643, -1.397959482758621 ], [ 29.768115696202532, -1.397959482758621 ], [ 29.768115696202532, -1.39769 ], [ 29.767846202531643, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2127, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.39769 ], [ 29.768115696202532, -1.397959482758621 ], [ 29.768385189873417, -1.397959482758621 ], [ 29.768385189873417, -1.39769 ], [ 29.768115696202532, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2128, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.39769 ], [ 29.768385189873417, -1.397959482758621 ], [ 29.768654683544302, -1.397959482758621 ], [ 29.768654683544302, -1.39769 ], [ 29.768385189873417, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2129, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.39769 ], [ 29.768654683544302, -1.397959482758621 ], [ 29.76892417721519, -1.397959482758621 ], [ 29.76892417721519, -1.39769 ], [ 29.768654683544302, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2130, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.39769 ], [ 29.76892417721519, -1.397959482758621 ], [ 29.769193670886075, -1.397959482758621 ], [ 29.769193670886075, -1.39769 ], [ 29.76892417721519, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2131, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.39769 ], [ 29.769193670886075, -1.397959482758621 ], [ 29.76946316455696, -1.397959482758621 ], [ 29.76946316455696, -1.39769 ], [ 29.769193670886075, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2132, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.39769 ], [ 29.76946316455696, -1.397959482758621 ], [ 29.769732658227849, -1.397959482758621 ], [ 29.769732658227849, -1.39769 ], [ 29.76946316455696, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2133, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.39769 ], [ 29.769732658227849, -1.397959482758621 ], [ 29.770002151898733, -1.397959482758621 ], [ 29.770002151898733, -1.39769 ], [ 29.769732658227849, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2134, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.39769 ], [ 29.770002151898733, -1.397959482758621 ], [ 29.770271645569618, -1.397959482758621 ], [ 29.770271645569618, -1.39769 ], [ 29.770002151898733, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2135, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.39769 ], [ 29.770271645569618, -1.397959482758621 ], [ 29.770541139240507, -1.397959482758621 ], [ 29.770541139240507, -1.39769 ], [ 29.770271645569618, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2136, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.39769 ], [ 29.770541139240507, -1.397959482758621 ], [ 29.770810632911392, -1.397959482758621 ], [ 29.770810632911392, -1.39769 ], [ 29.770541139240507, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2137, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.39769 ], [ 29.770810632911392, -1.397959482758621 ], [ 29.771080126582277, -1.397959482758621 ], [ 29.771080126582277, -1.39769 ], [ 29.770810632911392, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2138, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.39769 ], [ 29.771080126582277, -1.397959482758621 ], [ 29.771349620253165, -1.397959482758621 ], [ 29.771349620253165, -1.39769 ], [ 29.771080126582277, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2139, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.39769 ], [ 29.771349620253165, -1.397959482758621 ], [ 29.77161911392405, -1.397959482758621 ], [ 29.77161911392405, -1.39769 ], [ 29.771349620253165, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2140, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.39769 ], [ 29.77161911392405, -1.397959482758621 ], [ 29.771888607594935, -1.397959482758621 ], [ 29.771888607594935, -1.39769 ], [ 29.77161911392405, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2141, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.39769 ], [ 29.771888607594935, -1.397959482758621 ], [ 29.772158101265823, -1.397959482758621 ], [ 29.772158101265823, -1.39769 ], [ 29.771888607594935, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2142, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.39769 ], [ 29.772158101265823, -1.397959482758621 ], [ 29.772427594936708, -1.397959482758621 ], [ 29.772427594936708, -1.39769 ], [ 29.772158101265823, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2143, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.39769 ], [ 29.772427594936708, -1.397959482758621 ], [ 29.772697088607593, -1.397959482758621 ], [ 29.772697088607593, -1.39769 ], [ 29.772427594936708, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2144, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.39769 ], [ 29.772697088607593, -1.397959482758621 ], [ 29.772966582278482, -1.397959482758621 ], [ 29.772966582278482, -1.39769 ], [ 29.772697088607593, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2145, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.39769 ], [ 29.772966582278482, -1.397959482758621 ], [ 29.773236075949367, -1.397959482758621 ], [ 29.773236075949367, -1.39769 ], [ 29.772966582278482, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2146, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.39769 ], [ 29.773236075949367, -1.397959482758621 ], [ 29.773505569620252, -1.397959482758621 ], [ 29.773505569620252, -1.39769 ], [ 29.773236075949367, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2147, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.39769 ], [ 29.773505569620252, -1.397959482758621 ], [ 29.77377506329114, -1.397959482758621 ], [ 29.77377506329114, -1.39769 ], [ 29.773505569620252, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2148, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.39769 ], [ 29.77377506329114, -1.397959482758621 ], [ 29.774044556962025, -1.397959482758621 ], [ 29.774044556962025, -1.39769 ], [ 29.77377506329114, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2149, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.39769 ], [ 29.774044556962025, -1.397959482758621 ], [ 29.77431405063291, -1.397959482758621 ], [ 29.77431405063291, -1.39769 ], [ 29.774044556962025, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2150, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.39769 ], [ 29.77431405063291, -1.397959482758621 ], [ 29.774853037974683, -1.397959482758621 ], [ 29.774853037974683, -1.39769 ], [ 29.77431405063291, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2151, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.39769 ], [ 29.774853037974683, -1.397959482758621 ], [ 29.775122531645568, -1.397959482758621 ], [ 29.775122531645568, -1.39769 ], [ 29.774853037974683, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2152, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.39769 ], [ 29.775122531645568, -1.397959482758621 ], [ 29.775392025316457, -1.397959482758621 ], [ 29.775392025316457, -1.39769 ], [ 29.775122531645568, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2153, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.39769 ], [ 29.775392025316457, -1.397959482758621 ], [ 29.775661518987341, -1.397959482758621 ], [ 29.775661518987341, -1.39769 ], [ 29.775392025316457, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2154, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.39769 ], [ 29.775661518987341, -1.397959482758621 ], [ 29.775931012658226, -1.397959482758621 ], [ 29.775931012658226, -1.39769 ], [ 29.775661518987341, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2155, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.39769 ], [ 29.775931012658226, -1.397959482758621 ], [ 29.77647, -1.397959482758621 ], [ 29.77647, -1.39769 ], [ 29.775931012658226, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2156, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.39769 ], [ 29.78132088607595, -1.397959482758621 ], [ 29.781590379746834, -1.397959482758621 ], [ 29.781590379746834, -1.39769 ], [ 29.78132088607595, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2157, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.39769 ], [ 29.781590379746834, -1.397959482758621 ], [ 29.781859873417723, -1.397959482758621 ], [ 29.781859873417723, -1.39769 ], [ 29.781590379746834, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2158, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.39769 ], [ 29.781859873417723, -1.397959482758621 ], [ 29.782129367088608, -1.397959482758621 ], [ 29.782129367088608, -1.39769 ], [ 29.781859873417723, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2159, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.39769 ], [ 29.782129367088608, -1.397959482758621 ], [ 29.782398860759493, -1.397959482758621 ], [ 29.782398860759493, -1.39769 ], [ 29.782129367088608, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2160, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.39769 ], [ 29.784285316455698, -1.397959482758621 ], [ 29.784554810126583, -1.397959482758621 ], [ 29.784554810126583, -1.39769 ], [ 29.784285316455698, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2161, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.39769 ], [ 29.784554810126583, -1.397959482758621 ], [ 29.784824303797468, -1.397959482758621 ], [ 29.784824303797468, -1.39769 ], [ 29.784554810126583, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2162, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.39769 ], [ 29.784824303797468, -1.397959482758621 ], [ 29.785093797468356, -1.397959482758621 ], [ 29.785093797468356, -1.39769 ], [ 29.784824303797468, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2163, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.39769 ], [ 29.785093797468356, -1.397959482758621 ], [ 29.785363291139241, -1.397959482758621 ], [ 29.785363291139241, -1.39769 ], [ 29.785093797468356, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2164, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.39769 ], [ 29.785363291139241, -1.397959482758621 ], [ 29.785632784810126, -1.397959482758621 ], [ 29.785632784810126, -1.39769 ], [ 29.785363291139241, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2165, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.39769 ], [ 29.785632784810126, -1.397959482758621 ], [ 29.785902278481014, -1.397959482758621 ], [ 29.785902278481014, -1.39769 ], [ 29.785632784810126, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2166, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.39769 ], [ 29.785902278481014, -1.397959482758621 ], [ 29.786171772151899, -1.397959482758621 ], [ 29.786171772151899, -1.39769 ], [ 29.785902278481014, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2167, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.39769 ], [ 29.79776, -1.397959482758621 ], [ 29.798029493670885, -1.397959482758621 ], [ 29.798029493670885, -1.39769 ], [ 29.79776, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2168, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.397959482758621 ], [ 29.765959746835442, -1.398228965517242 ], [ 29.766229240506327, -1.398228965517242 ], [ 29.766229240506327, -1.397959482758621 ], [ 29.765959746835442, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2169, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.397959482758621 ], [ 29.766229240506327, -1.398228965517242 ], [ 29.766498734177215, -1.398228965517242 ], [ 29.766498734177215, -1.397959482758621 ], [ 29.766229240506327, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2170, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.397959482758621 ], [ 29.766498734177215, -1.398228965517242 ], [ 29.7667682278481, -1.398228965517242 ], [ 29.7667682278481, -1.397959482758621 ], [ 29.766498734177215, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2171, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.397959482758621 ], [ 29.767307215189874, -1.398228965517242 ], [ 29.767576708860759, -1.398228965517242 ], [ 29.767576708860759, -1.397959482758621 ], [ 29.767307215189874, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2172, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.397959482758621 ], [ 29.767576708860759, -1.398228965517242 ], [ 29.767846202531643, -1.398228965517242 ], [ 29.767846202531643, -1.397959482758621 ], [ 29.767576708860759, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2173, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.397959482758621 ], [ 29.767846202531643, -1.398228965517242 ], [ 29.768115696202532, -1.398228965517242 ], [ 29.768115696202532, -1.397959482758621 ], [ 29.767846202531643, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2174, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.397959482758621 ], [ 29.768115696202532, -1.398228965517242 ], [ 29.768385189873417, -1.398228965517242 ], [ 29.768385189873417, -1.397959482758621 ], [ 29.768115696202532, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2175, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.397959482758621 ], [ 29.768385189873417, -1.398228965517242 ], [ 29.768654683544302, -1.398228965517242 ], [ 29.768654683544302, -1.397959482758621 ], [ 29.768385189873417, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2176, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.397959482758621 ], [ 29.768654683544302, -1.398228965517242 ], [ 29.76892417721519, -1.398228965517242 ], [ 29.76892417721519, -1.397959482758621 ], [ 29.768654683544302, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2177, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.397959482758621 ], [ 29.76892417721519, -1.398228965517242 ], [ 29.769193670886075, -1.398228965517242 ], [ 29.769193670886075, -1.397959482758621 ], [ 29.76892417721519, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2178, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.397959482758621 ], [ 29.769193670886075, -1.398228965517242 ], [ 29.76946316455696, -1.398228965517242 ], [ 29.76946316455696, -1.397959482758621 ], [ 29.769193670886075, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2179, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.397959482758621 ], [ 29.76946316455696, -1.398228965517242 ], [ 29.769732658227849, -1.398228965517242 ], [ 29.769732658227849, -1.397959482758621 ], [ 29.76946316455696, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2180, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.397959482758621 ], [ 29.769732658227849, -1.398228965517242 ], [ 29.770002151898733, -1.398228965517242 ], [ 29.770002151898733, -1.397959482758621 ], [ 29.769732658227849, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2181, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.397959482758621 ], [ 29.770002151898733, -1.398228965517242 ], [ 29.770271645569618, -1.398228965517242 ], [ 29.770271645569618, -1.397959482758621 ], [ 29.770002151898733, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2182, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.397959482758621 ], [ 29.770271645569618, -1.398228965517242 ], [ 29.770541139240507, -1.398228965517242 ], [ 29.770541139240507, -1.397959482758621 ], [ 29.770271645569618, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2183, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.397959482758621 ], [ 29.770541139240507, -1.398228965517242 ], [ 29.770810632911392, -1.398228965517242 ], [ 29.770810632911392, -1.397959482758621 ], [ 29.770541139240507, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2184, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.397959482758621 ], [ 29.770810632911392, -1.398228965517242 ], [ 29.771080126582277, -1.398228965517242 ], [ 29.771080126582277, -1.397959482758621 ], [ 29.770810632911392, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2185, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.397959482758621 ], [ 29.771080126582277, -1.398228965517242 ], [ 29.771349620253165, -1.398228965517242 ], [ 29.771349620253165, -1.397959482758621 ], [ 29.771080126582277, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2186, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.397959482758621 ], [ 29.771349620253165, -1.398228965517242 ], [ 29.77161911392405, -1.398228965517242 ], [ 29.77161911392405, -1.397959482758621 ], [ 29.771349620253165, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2187, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.397959482758621 ], [ 29.77161911392405, -1.398228965517242 ], [ 29.771888607594935, -1.398228965517242 ], [ 29.771888607594935, -1.397959482758621 ], [ 29.77161911392405, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2188, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.397959482758621 ], [ 29.771888607594935, -1.398228965517242 ], [ 29.772158101265823, -1.398228965517242 ], [ 29.772158101265823, -1.397959482758621 ], [ 29.771888607594935, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2189, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.397959482758621 ], [ 29.772158101265823, -1.398228965517242 ], [ 29.772427594936708, -1.398228965517242 ], [ 29.772427594936708, -1.397959482758621 ], [ 29.772158101265823, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2190, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.397959482758621 ], [ 29.772427594936708, -1.398228965517242 ], [ 29.772697088607593, -1.398228965517242 ], [ 29.772697088607593, -1.397959482758621 ], [ 29.772427594936708, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2191, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.397959482758621 ], [ 29.772697088607593, -1.398228965517242 ], [ 29.772966582278482, -1.398228965517242 ], [ 29.772966582278482, -1.397959482758621 ], [ 29.772697088607593, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2192, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.397959482758621 ], [ 29.772966582278482, -1.398228965517242 ], [ 29.773236075949367, -1.398228965517242 ], [ 29.773236075949367, -1.397959482758621 ], [ 29.772966582278482, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2193, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.397959482758621 ], [ 29.773236075949367, -1.398228965517242 ], [ 29.77377506329114, -1.398228965517242 ], [ 29.77377506329114, -1.397959482758621 ], [ 29.773236075949367, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2194, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.397959482758621 ], [ 29.77377506329114, -1.398228965517242 ], [ 29.774044556962025, -1.398228965517242 ], [ 29.774044556962025, -1.397959482758621 ], [ 29.77377506329114, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2195, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.397959482758621 ], [ 29.774044556962025, -1.398228965517242 ], [ 29.77431405063291, -1.398228965517242 ], [ 29.77431405063291, -1.397959482758621 ], [ 29.774044556962025, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2196, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.397959482758621 ], [ 29.77431405063291, -1.398228965517242 ], [ 29.774853037974683, -1.398228965517242 ], [ 29.774853037974683, -1.397959482758621 ], [ 29.77431405063291, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2197, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.397959482758621 ], [ 29.774853037974683, -1.398228965517242 ], [ 29.775122531645568, -1.398228965517242 ], [ 29.775122531645568, -1.397959482758621 ], [ 29.774853037974683, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2198, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.397959482758621 ], [ 29.775122531645568, -1.398228965517242 ], [ 29.775392025316457, -1.398228965517242 ], [ 29.775392025316457, -1.397959482758621 ], [ 29.775122531645568, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2199, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.397959482758621 ], [ 29.775392025316457, -1.398228965517242 ], [ 29.775931012658226, -1.398228965517242 ], [ 29.775931012658226, -1.397959482758621 ], [ 29.775392025316457, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2200, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.397959482758621 ], [ 29.775931012658226, -1.398228965517242 ], [ 29.776200506329115, -1.398228965517242 ], [ 29.776200506329115, -1.397959482758621 ], [ 29.775931012658226, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2201, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.397959482758621 ], [ 29.776200506329115, -1.398228965517242 ], [ 29.776739493670885, -1.398228965517242 ], [ 29.776739493670885, -1.397959482758621 ], [ 29.776200506329115, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2202, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.397959482758621 ], [ 29.784554810126583, -1.398228965517242 ], [ 29.784824303797468, -1.398228965517242 ], [ 29.784824303797468, -1.397959482758621 ], [ 29.784554810126583, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2203, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.397959482758621 ], [ 29.784824303797468, -1.398228965517242 ], [ 29.785093797468356, -1.398228965517242 ], [ 29.785093797468356, -1.397959482758621 ], [ 29.784824303797468, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2204, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.397959482758621 ], [ 29.785093797468356, -1.398228965517242 ], [ 29.785363291139241, -1.398228965517242 ], [ 29.785363291139241, -1.397959482758621 ], [ 29.785093797468356, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2205, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.397959482758621 ], [ 29.785363291139241, -1.398228965517242 ], [ 29.785632784810126, -1.398228965517242 ], [ 29.785632784810126, -1.397959482758621 ], [ 29.785363291139241, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2206, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.397959482758621 ], [ 29.785632784810126, -1.398228965517242 ], [ 29.785902278481014, -1.398228965517242 ], [ 29.785902278481014, -1.397959482758621 ], [ 29.785632784810126, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2207, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.397959482758621 ], [ 29.785902278481014, -1.398228965517242 ], [ 29.786171772151899, -1.398228965517242 ], [ 29.786171772151899, -1.397959482758621 ], [ 29.785902278481014, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2208, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.398228965517242 ], [ 29.768115696202532, -1.398498448275862 ], [ 29.768385189873417, -1.398498448275862 ], [ 29.768385189873417, -1.398228965517242 ], [ 29.768115696202532, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2209, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.398228965517242 ], [ 29.768385189873417, -1.398498448275862 ], [ 29.768654683544302, -1.398498448275862 ], [ 29.768654683544302, -1.398228965517242 ], [ 29.768385189873417, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2210, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.398228965517242 ], [ 29.768654683544302, -1.398498448275862 ], [ 29.76892417721519, -1.398498448275862 ], [ 29.76892417721519, -1.398228965517242 ], [ 29.768654683544302, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2211, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.398228965517242 ], [ 29.76892417721519, -1.398498448275862 ], [ 29.769193670886075, -1.398498448275862 ], [ 29.769193670886075, -1.398228965517242 ], [ 29.76892417721519, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2212, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.398228965517242 ], [ 29.769193670886075, -1.398498448275862 ], [ 29.76946316455696, -1.398498448275862 ], [ 29.76946316455696, -1.398228965517242 ], [ 29.769193670886075, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2213, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.398228965517242 ], [ 29.76946316455696, -1.398498448275862 ], [ 29.769732658227849, -1.398498448275862 ], [ 29.769732658227849, -1.398228965517242 ], [ 29.76946316455696, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2214, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.398228965517242 ], [ 29.769732658227849, -1.398498448275862 ], [ 29.770002151898733, -1.398498448275862 ], [ 29.770002151898733, -1.398228965517242 ], [ 29.769732658227849, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2215, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.398228965517242 ], [ 29.770002151898733, -1.398498448275862 ], [ 29.770271645569618, -1.398498448275862 ], [ 29.770271645569618, -1.398228965517242 ], [ 29.770002151898733, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2216, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.398228965517242 ], [ 29.770271645569618, -1.398498448275862 ], [ 29.770541139240507, -1.398498448275862 ], [ 29.770541139240507, -1.398228965517242 ], [ 29.770271645569618, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2217, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.398228965517242 ], [ 29.770541139240507, -1.398498448275862 ], [ 29.770810632911392, -1.398498448275862 ], [ 29.770810632911392, -1.398228965517242 ], [ 29.770541139240507, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2218, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.398228965517242 ], [ 29.770810632911392, -1.398498448275862 ], [ 29.771080126582277, -1.398498448275862 ], [ 29.771080126582277, -1.398228965517242 ], [ 29.770810632911392, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2219, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.398228965517242 ], [ 29.771080126582277, -1.398498448275862 ], [ 29.771349620253165, -1.398498448275862 ], [ 29.771349620253165, -1.398228965517242 ], [ 29.771080126582277, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2220, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.398228965517242 ], [ 29.771349620253165, -1.398498448275862 ], [ 29.77161911392405, -1.398498448275862 ], [ 29.77161911392405, -1.398228965517242 ], [ 29.771349620253165, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2221, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.398228965517242 ], [ 29.77161911392405, -1.398498448275862 ], [ 29.771888607594935, -1.398498448275862 ], [ 29.771888607594935, -1.398228965517242 ], [ 29.77161911392405, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2222, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.398228965517242 ], [ 29.771888607594935, -1.398498448275862 ], [ 29.772158101265823, -1.398498448275862 ], [ 29.772158101265823, -1.398228965517242 ], [ 29.771888607594935, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2223, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.398228965517242 ], [ 29.772158101265823, -1.398498448275862 ], [ 29.772427594936708, -1.398498448275862 ], [ 29.772427594936708, -1.398228965517242 ], [ 29.772158101265823, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2224, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.398228965517242 ], [ 29.772427594936708, -1.398498448275862 ], [ 29.772697088607593, -1.398498448275862 ], [ 29.772697088607593, -1.398228965517242 ], [ 29.772427594936708, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2225, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.398228965517242 ], [ 29.772697088607593, -1.398498448275862 ], [ 29.772966582278482, -1.398498448275862 ], [ 29.772966582278482, -1.398228965517242 ], [ 29.772697088607593, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2226, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.398228965517242 ], [ 29.772966582278482, -1.398498448275862 ], [ 29.773236075949367, -1.398498448275862 ], [ 29.773236075949367, -1.398228965517242 ], [ 29.772966582278482, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2227, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.398228965517242 ], [ 29.773236075949367, -1.398498448275862 ], [ 29.773505569620252, -1.398498448275862 ], [ 29.773505569620252, -1.398228965517242 ], [ 29.773236075949367, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2228, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.398228965517242 ], [ 29.773505569620252, -1.398498448275862 ], [ 29.77377506329114, -1.398498448275862 ], [ 29.77377506329114, -1.398228965517242 ], [ 29.773505569620252, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2229, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.398228965517242 ], [ 29.77377506329114, -1.398498448275862 ], [ 29.774044556962025, -1.398498448275862 ], [ 29.774044556962025, -1.398228965517242 ], [ 29.77377506329114, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2230, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.398228965517242 ], [ 29.774044556962025, -1.398498448275862 ], [ 29.77431405063291, -1.398498448275862 ], [ 29.77431405063291, -1.398228965517242 ], [ 29.774044556962025, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2231, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.398228965517242 ], [ 29.77431405063291, -1.398498448275862 ], [ 29.774853037974683, -1.398498448275862 ], [ 29.774853037974683, -1.398228965517242 ], [ 29.77431405063291, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2232, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.398228965517242 ], [ 29.774853037974683, -1.398498448275862 ], [ 29.775122531645568, -1.398498448275862 ], [ 29.775122531645568, -1.398228965517242 ], [ 29.774853037974683, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2233, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.398228965517242 ], [ 29.775122531645568, -1.398498448275862 ], [ 29.775392025316457, -1.398498448275862 ], [ 29.775392025316457, -1.398228965517242 ], [ 29.775122531645568, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2234, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.398228965517242 ], [ 29.775392025316457, -1.398498448275862 ], [ 29.775661518987341, -1.398498448275862 ], [ 29.775661518987341, -1.398228965517242 ], [ 29.775392025316457, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2235, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.398228965517242 ], [ 29.775661518987341, -1.398498448275862 ], [ 29.775931012658226, -1.398498448275862 ], [ 29.775931012658226, -1.398228965517242 ], [ 29.775661518987341, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2236, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.398228965517242 ], [ 29.775931012658226, -1.398498448275862 ], [ 29.776200506329115, -1.398498448275862 ], [ 29.776200506329115, -1.398228965517242 ], [ 29.775931012658226, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2237, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.398228965517242 ], [ 29.785093797468356, -1.398498448275862 ], [ 29.785363291139241, -1.398498448275862 ], [ 29.785363291139241, -1.398228965517242 ], [ 29.785093797468356, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2238, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.398228965517242 ], [ 29.785363291139241, -1.398498448275862 ], [ 29.785632784810126, -1.398498448275862 ], [ 29.785632784810126, -1.398228965517242 ], [ 29.785363291139241, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2239, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.398228965517242 ], [ 29.785632784810126, -1.398498448275862 ], [ 29.785902278481014, -1.398498448275862 ], [ 29.785902278481014, -1.398228965517242 ], [ 29.785632784810126, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2240, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.398228965517242 ], [ 29.785902278481014, -1.398498448275862 ], [ 29.786171772151899, -1.398498448275862 ], [ 29.786171772151899, -1.398228965517242 ], [ 29.785902278481014, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2241, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.398228965517242 ], [ 29.786171772151899, -1.398498448275862 ], [ 29.786441265822784, -1.398498448275862 ], [ 29.786441265822784, -1.398228965517242 ], [ 29.786171772151899, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2242, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.396342586206897 ], [ 29.786980253164558, -1.398767931034483 ], [ 29.787249746835442, -1.398767931034483 ], [ 29.787249746835442, -1.396342586206897 ], [ 29.786980253164558, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2243, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.396342586206897 ], [ 29.787249746835442, -1.398767931034483 ], [ 29.787519240506327, -1.398767931034483 ], [ 29.787519240506327, -1.396342586206897 ], [ 29.787249746835442, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2244, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.396342586206897 ], [ 29.787519240506327, -1.399037413793103 ], [ 29.787788734177216, -1.399037413793103 ], [ 29.787788734177216, -1.396342586206897 ], [ 29.787519240506327, -1.396342586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2245, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.397420517241379 ], [ 29.778086962025316, -1.400384827586207 ], [ 29.778356455696201, -1.400384827586207 ], [ 29.778356455696201, -1.397420517241379 ], [ 29.778086962025316, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2246, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.397420517241379 ], [ 29.778356455696201, -1.400384827586207 ], [ 29.77862594936709, -1.400384827586207 ], [ 29.77862594936709, -1.397420517241379 ], [ 29.778356455696201, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2247, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.397420517241379 ], [ 29.778895443037975, -1.400654310344828 ], [ 29.77916493670886, -1.400654310344828 ], [ 29.77916493670886, -1.397420517241379 ], [ 29.778895443037975, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2248, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.39769 ], [ 29.7667682278481, -1.399037413793103 ], [ 29.767037721518985, -1.399037413793103 ], [ 29.767037721518985, -1.39769 ], [ 29.7667682278481, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2249, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.39769 ], [ 29.767037721518985, -1.399037413793103 ], [ 29.767307215189874, -1.399037413793103 ], [ 29.767307215189874, -1.39769 ], [ 29.767037721518985, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2250, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.39769 ], [ 29.77647, -1.397959482758621 ], [ 29.777547974683543, -1.397959482758621 ], [ 29.777547974683543, -1.400115344827586 ], [ 29.777817468354431, -1.400115344827586 ], [ 29.777817468354431, -1.39769 ], [ 29.77647, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2251, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.39769 ], [ 29.777817468354431, -1.400115344827586 ], [ 29.778086962025316, -1.400115344827586 ], [ 29.778086962025316, -1.39769 ], [ 29.777817468354431, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2252, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.39769 ], [ 29.77862594936709, -1.400654310344828 ], [ 29.778895443037975, -1.400654310344828 ], [ 29.778895443037975, -1.39769 ], [ 29.77862594936709, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2253, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.397959482758621 ], [ 29.776739493670885, -1.398228965517242 ], [ 29.777278481012658, -1.398228965517242 ], [ 29.777278481012658, -1.399845862068966 ], [ 29.777547974683543, -1.399845862068966 ], [ 29.777547974683543, -1.397959482758621 ], [ 29.776739493670885, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2254, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.397959482758621 ], [ 29.77916493670886, -1.400654310344828 ], [ 29.779434430379748, -1.400654310344828 ], [ 29.779434430379748, -1.397959482758621 ], [ 29.77916493670886, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.398228965517242 ], [ 29.765959746835442, -1.399845862068966 ], [ 29.766229240506327, -1.399845862068966 ], [ 29.766229240506327, -1.398228965517242 ], [ 29.765959746835442, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2256, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.398228965517242 ], [ 29.766229240506327, -1.399576379310345 ], [ 29.766498734177215, -1.399576379310345 ], [ 29.766498734177215, -1.398228965517242 ], [ 29.766229240506327, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2257, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.398228965517242 ], [ 29.766498734177215, -1.399576379310345 ], [ 29.7667682278481, -1.399576379310345 ], [ 29.7667682278481, -1.398228965517242 ], [ 29.766498734177215, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2258, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.398228965517242 ], [ 29.767307215189874, -1.399037413793103 ], [ 29.767576708860759, -1.399037413793103 ], [ 29.767576708860759, -1.398228965517242 ], [ 29.767307215189874, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2259, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.398228965517242 ], [ 29.767576708860759, -1.398767931034483 ], [ 29.767846202531643, -1.398767931034483 ], [ 29.767846202531643, -1.398228965517242 ], [ 29.767576708860759, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2260, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.398228965517242 ], [ 29.767846202531643, -1.398767931034483 ], [ 29.768115696202532, -1.398767931034483 ], [ 29.768115696202532, -1.398228965517242 ], [ 29.767846202531643, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2261, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.398228965517242 ], [ 29.776200506329115, -1.398498448275862 ], [ 29.777008987341773, -1.398498448275862 ], [ 29.777008987341773, -1.399845862068966 ], [ 29.777278481012658, -1.399845862068966 ], [ 29.777278481012658, -1.398228965517242 ], [ 29.776200506329115, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2262, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.398228965517242 ], [ 29.784824303797468, -1.398767931034483 ], [ 29.785093797468356, -1.398767931034483 ], [ 29.785093797468356, -1.398228965517242 ], [ 29.784824303797468, -1.398228965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2263, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.398498448275862 ], [ 29.768115696202532, -1.398767931034483 ], [ 29.768385189873417, -1.398767931034483 ], [ 29.768385189873417, -1.398498448275862 ], [ 29.768115696202532, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2264, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.398498448275862 ], [ 29.768385189873417, -1.398767931034483 ], [ 29.768654683544302, -1.398767931034483 ], [ 29.768654683544302, -1.398498448275862 ], [ 29.768385189873417, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2265, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.398498448275862 ], [ 29.768654683544302, -1.399576379310345 ], [ 29.76892417721519, -1.399576379310345 ], [ 29.76892417721519, -1.398498448275862 ], [ 29.768654683544302, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2266, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.398498448275862 ], [ 29.76892417721519, -1.398767931034483 ], [ 29.769193670886075, -1.398767931034483 ], [ 29.769193670886075, -1.398498448275862 ], [ 29.76892417721519, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2267, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.398498448275862 ], [ 29.769193670886075, -1.398767931034483 ], [ 29.76946316455696, -1.398767931034483 ], [ 29.76946316455696, -1.398498448275862 ], [ 29.769193670886075, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2268, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.398498448275862 ], [ 29.76946316455696, -1.398767931034483 ], [ 29.769732658227849, -1.398767931034483 ], [ 29.769732658227849, -1.398498448275862 ], [ 29.76946316455696, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2269, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.398498448275862 ], [ 29.769732658227849, -1.398767931034483 ], [ 29.770002151898733, -1.398767931034483 ], [ 29.770002151898733, -1.398498448275862 ], [ 29.769732658227849, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2270, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.398498448275862 ], [ 29.770002151898733, -1.398767931034483 ], [ 29.770271645569618, -1.398767931034483 ], [ 29.770271645569618, -1.398498448275862 ], [ 29.770002151898733, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2271, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.398498448275862 ], [ 29.770271645569618, -1.398767931034483 ], [ 29.770541139240507, -1.398767931034483 ], [ 29.770541139240507, -1.398498448275862 ], [ 29.770271645569618, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2272, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.398498448275862 ], [ 29.770541139240507, -1.398767931034483 ], [ 29.770810632911392, -1.398767931034483 ], [ 29.770810632911392, -1.398498448275862 ], [ 29.770541139240507, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2273, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.398498448275862 ], [ 29.770810632911392, -1.398767931034483 ], [ 29.771080126582277, -1.398767931034483 ], [ 29.771080126582277, -1.398498448275862 ], [ 29.770810632911392, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2274, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.398498448275862 ], [ 29.771080126582277, -1.398767931034483 ], [ 29.771349620253165, -1.398767931034483 ], [ 29.771349620253165, -1.398498448275862 ], [ 29.771080126582277, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2275, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.398498448275862 ], [ 29.771349620253165, -1.398767931034483 ], [ 29.77161911392405, -1.398767931034483 ], [ 29.77161911392405, -1.398498448275862 ], [ 29.771349620253165, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2276, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.398498448275862 ], [ 29.77161911392405, -1.398767931034483 ], [ 29.771888607594935, -1.398767931034483 ], [ 29.771888607594935, -1.398498448275862 ], [ 29.77161911392405, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2277, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.398498448275862 ], [ 29.771888607594935, -1.398767931034483 ], [ 29.772158101265823, -1.398767931034483 ], [ 29.772158101265823, -1.398498448275862 ], [ 29.771888607594935, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2278, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.398498448275862 ], [ 29.772158101265823, -1.398767931034483 ], [ 29.772427594936708, -1.398767931034483 ], [ 29.772427594936708, -1.398498448275862 ], [ 29.772158101265823, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2279, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.398498448275862 ], [ 29.772427594936708, -1.398767931034483 ], [ 29.772697088607593, -1.398767931034483 ], [ 29.772697088607593, -1.398498448275862 ], [ 29.772427594936708, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2280, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.398498448275862 ], [ 29.772697088607593, -1.398767931034483 ], [ 29.772966582278482, -1.398767931034483 ], [ 29.772966582278482, -1.398498448275862 ], [ 29.772697088607593, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2281, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.398498448275862 ], [ 29.772966582278482, -1.398767931034483 ], [ 29.773236075949367, -1.398767931034483 ], [ 29.773236075949367, -1.398498448275862 ], [ 29.772966582278482, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2282, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.398498448275862 ], [ 29.773236075949367, -1.398767931034483 ], [ 29.773505569620252, -1.398767931034483 ], [ 29.773505569620252, -1.398498448275862 ], [ 29.773236075949367, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2283, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.398498448275862 ], [ 29.773505569620252, -1.398767931034483 ], [ 29.77377506329114, -1.398767931034483 ], [ 29.77377506329114, -1.398498448275862 ], [ 29.773505569620252, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2284, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.398498448275862 ], [ 29.77377506329114, -1.398767931034483 ], [ 29.774044556962025, -1.398767931034483 ], [ 29.774044556962025, -1.398498448275862 ], [ 29.77377506329114, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2285, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.398498448275862 ], [ 29.774044556962025, -1.398767931034483 ], [ 29.77431405063291, -1.398767931034483 ], [ 29.77431405063291, -1.398498448275862 ], [ 29.774044556962025, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2286, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.398498448275862 ], [ 29.77431405063291, -1.398767931034483 ], [ 29.774853037974683, -1.398767931034483 ], [ 29.774853037974683, -1.398498448275862 ], [ 29.77431405063291, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2287, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.398498448275862 ], [ 29.774853037974683, -1.398767931034483 ], [ 29.775122531645568, -1.398767931034483 ], [ 29.775122531645568, -1.398498448275862 ], [ 29.774853037974683, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2288, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.398498448275862 ], [ 29.775122531645568, -1.398767931034483 ], [ 29.775392025316457, -1.398767931034483 ], [ 29.775392025316457, -1.398498448275862 ], [ 29.775122531645568, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2289, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.398498448275862 ], [ 29.775392025316457, -1.398767931034483 ], [ 29.775661518987341, -1.398767931034483 ], [ 29.775661518987341, -1.398498448275862 ], [ 29.775392025316457, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2290, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.398498448275862 ], [ 29.775661518987341, -1.398767931034483 ], [ 29.775931012658226, -1.398767931034483 ], [ 29.775931012658226, -1.398498448275862 ], [ 29.775661518987341, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2291, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.398498448275862 ], [ 29.775931012658226, -1.398767931034483 ], [ 29.77647, -1.398767931034483 ], [ 29.77647, -1.398498448275862 ], [ 29.775931012658226, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2292, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.398498448275862 ], [ 29.77647, -1.398767931034483 ], [ 29.776739493670885, -1.398767931034483 ], [ 29.776739493670885, -1.399576379310345 ], [ 29.777008987341773, -1.399576379310345 ], [ 29.777008987341773, -1.398498448275862 ], [ 29.77647, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2293, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.398498448275862 ], [ 29.785093797468356, -1.398767931034483 ], [ 29.785363291139241, -1.398767931034483 ], [ 29.785363291139241, -1.398498448275862 ], [ 29.785093797468356, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2294, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.398498448275862 ], [ 29.785363291139241, -1.398767931034483 ], [ 29.785632784810126, -1.398767931034483 ], [ 29.785632784810126, -1.398498448275862 ], [ 29.785363291139241, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2295, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.398498448275862 ], [ 29.785632784810126, -1.398767931034483 ], [ 29.785902278481014, -1.398767931034483 ], [ 29.785902278481014, -1.398498448275862 ], [ 29.785632784810126, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2296, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.398498448275862 ], [ 29.785902278481014, -1.398767931034483 ], [ 29.786171772151899, -1.398767931034483 ], [ 29.786171772151899, -1.398498448275862 ], [ 29.785902278481014, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2297, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.398498448275862 ], [ 29.786171772151899, -1.398767931034483 ], [ 29.786441265822784, -1.398767931034483 ], [ 29.786441265822784, -1.398498448275862 ], [ 29.786171772151899, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2298, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.398498448275862 ], [ 29.786441265822784, -1.398767931034483 ], [ 29.786710759493673, -1.398767931034483 ], [ 29.786710759493673, -1.398498448275862 ], [ 29.786441265822784, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2299, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.398498448275862 ], [ 29.786710759493673, -1.398767931034483 ], [ 29.786980253164558, -1.398767931034483 ], [ 29.786980253164558, -1.398498448275862 ], [ 29.786710759493673, -1.398498448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2300, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.398767931034483 ], [ 29.767576708860759, -1.399037413793103 ], [ 29.767846202531643, -1.399037413793103 ], [ 29.767846202531643, -1.398767931034483 ], [ 29.767576708860759, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2301, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.398767931034483 ], [ 29.767846202531643, -1.399037413793103 ], [ 29.768115696202532, -1.399037413793103 ], [ 29.768115696202532, -1.398767931034483 ], [ 29.767846202531643, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2302, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.398767931034483 ], [ 29.768115696202532, -1.399037413793103 ], [ 29.768385189873417, -1.399037413793103 ], [ 29.768385189873417, -1.398767931034483 ], [ 29.768115696202532, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2303, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.398767931034483 ], [ 29.768385189873417, -1.399037413793103 ], [ 29.768654683544302, -1.399037413793103 ], [ 29.768654683544302, -1.398767931034483 ], [ 29.768385189873417, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2304, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.398767931034483 ], [ 29.76892417721519, -1.399037413793103 ], [ 29.769193670886075, -1.399037413793103 ], [ 29.769193670886075, -1.398767931034483 ], [ 29.76892417721519, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2305, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.398767931034483 ], [ 29.769193670886075, -1.399845862068966 ], [ 29.76946316455696, -1.399845862068966 ], [ 29.76946316455696, -1.398767931034483 ], [ 29.769193670886075, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2306, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.398767931034483 ], [ 29.76946316455696, -1.399037413793103 ], [ 29.769732658227849, -1.399037413793103 ], [ 29.769732658227849, -1.398767931034483 ], [ 29.76946316455696, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2307, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.398767931034483 ], [ 29.769732658227849, -1.399037413793103 ], [ 29.770002151898733, -1.399037413793103 ], [ 29.770002151898733, -1.398767931034483 ], [ 29.769732658227849, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2308, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.398767931034483 ], [ 29.770002151898733, -1.399037413793103 ], [ 29.770271645569618, -1.399037413793103 ], [ 29.770271645569618, -1.398767931034483 ], [ 29.770002151898733, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2309, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.398767931034483 ], [ 29.770271645569618, -1.399037413793103 ], [ 29.770541139240507, -1.399037413793103 ], [ 29.770541139240507, -1.398767931034483 ], [ 29.770271645569618, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2310, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.398767931034483 ], [ 29.770541139240507, -1.399037413793103 ], [ 29.770810632911392, -1.399037413793103 ], [ 29.770810632911392, -1.398767931034483 ], [ 29.770541139240507, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2311, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.398767931034483 ], [ 29.770810632911392, -1.399037413793103 ], [ 29.771080126582277, -1.399037413793103 ], [ 29.771080126582277, -1.398767931034483 ], [ 29.770810632911392, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2312, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.398767931034483 ], [ 29.771080126582277, -1.399037413793103 ], [ 29.771349620253165, -1.399037413793103 ], [ 29.771349620253165, -1.398767931034483 ], [ 29.771080126582277, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2313, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.398767931034483 ], [ 29.771349620253165, -1.399037413793103 ], [ 29.77161911392405, -1.399037413793103 ], [ 29.77161911392405, -1.398767931034483 ], [ 29.771349620253165, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2314, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.398767931034483 ], [ 29.77161911392405, -1.399037413793103 ], [ 29.771888607594935, -1.399037413793103 ], [ 29.771888607594935, -1.398767931034483 ], [ 29.77161911392405, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2315, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.398767931034483 ], [ 29.771888607594935, -1.399037413793103 ], [ 29.772158101265823, -1.399037413793103 ], [ 29.772158101265823, -1.398767931034483 ], [ 29.771888607594935, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2316, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.398767931034483 ], [ 29.772158101265823, -1.399037413793103 ], [ 29.772427594936708, -1.399037413793103 ], [ 29.772427594936708, -1.398767931034483 ], [ 29.772158101265823, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2317, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.398767931034483 ], [ 29.772427594936708, -1.399037413793103 ], [ 29.772697088607593, -1.399037413793103 ], [ 29.772697088607593, -1.398767931034483 ], [ 29.772427594936708, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2318, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.398767931034483 ], [ 29.772697088607593, -1.399037413793103 ], [ 29.772966582278482, -1.399037413793103 ], [ 29.772966582278482, -1.398767931034483 ], [ 29.772697088607593, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2319, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.398767931034483 ], [ 29.772966582278482, -1.399037413793103 ], [ 29.773236075949367, -1.399037413793103 ], [ 29.773236075949367, -1.398767931034483 ], [ 29.772966582278482, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2320, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.398767931034483 ], [ 29.773236075949367, -1.399037413793103 ], [ 29.773505569620252, -1.399037413793103 ], [ 29.773505569620252, -1.398767931034483 ], [ 29.773236075949367, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2321, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.398767931034483 ], [ 29.773505569620252, -1.399037413793103 ], [ 29.77377506329114, -1.399037413793103 ], [ 29.77377506329114, -1.398767931034483 ], [ 29.773505569620252, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2322, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.398767931034483 ], [ 29.77377506329114, -1.399037413793103 ], [ 29.774044556962025, -1.399037413793103 ], [ 29.774044556962025, -1.398767931034483 ], [ 29.77377506329114, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2323, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.398767931034483 ], [ 29.774044556962025, -1.399037413793103 ], [ 29.77431405063291, -1.399037413793103 ], [ 29.77431405063291, -1.398767931034483 ], [ 29.774044556962025, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2324, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.398767931034483 ], [ 29.77431405063291, -1.399037413793103 ], [ 29.774853037974683, -1.399037413793103 ], [ 29.774853037974683, -1.398767931034483 ], [ 29.77431405063291, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2325, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.398767931034483 ], [ 29.774853037974683, -1.399037413793103 ], [ 29.775122531645568, -1.399037413793103 ], [ 29.775122531645568, -1.398767931034483 ], [ 29.774853037974683, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2326, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.398767931034483 ], [ 29.775122531645568, -1.399037413793103 ], [ 29.775392025316457, -1.399037413793103 ], [ 29.775392025316457, -1.398767931034483 ], [ 29.775122531645568, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2327, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.398767931034483 ], [ 29.775392025316457, -1.399037413793103 ], [ 29.775661518987341, -1.399037413793103 ], [ 29.775661518987341, -1.398767931034483 ], [ 29.775392025316457, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2328, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.398767931034483 ], [ 29.775661518987341, -1.399037413793103 ], [ 29.776200506329115, -1.399037413793103 ], [ 29.776200506329115, -1.398767931034483 ], [ 29.775661518987341, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2329, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.398767931034483 ], [ 29.776200506329115, -1.399037413793103 ], [ 29.77647, -1.399037413793103 ], [ 29.77647, -1.398767931034483 ], [ 29.776200506329115, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2330, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.398767931034483 ], [ 29.77647, -1.399576379310345 ], [ 29.776739493670885, -1.399576379310345 ], [ 29.776739493670885, -1.398767931034483 ], [ 29.77647, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2331, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.398767931034483 ], [ 29.785093797468356, -1.399576379310345 ], [ 29.785363291139241, -1.399576379310345 ], [ 29.785363291139241, -1.398767931034483 ], [ 29.785093797468356, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2332, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.398767931034483 ], [ 29.785363291139241, -1.399037413793103 ], [ 29.785632784810126, -1.399037413793103 ], [ 29.785632784810126, -1.398767931034483 ], [ 29.785363291139241, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2333, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.398767931034483 ], [ 29.785632784810126, -1.399037413793103 ], [ 29.785902278481014, -1.399037413793103 ], [ 29.785902278481014, -1.398767931034483 ], [ 29.785632784810126, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2334, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.398767931034483 ], [ 29.785902278481014, -1.399037413793103 ], [ 29.786171772151899, -1.399037413793103 ], [ 29.786171772151899, -1.398767931034483 ], [ 29.785902278481014, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2335, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.398767931034483 ], [ 29.786171772151899, -1.399037413793103 ], [ 29.786441265822784, -1.399037413793103 ], [ 29.786441265822784, -1.398767931034483 ], [ 29.786171772151899, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2336, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.398767931034483 ], [ 29.786441265822784, -1.399037413793103 ], [ 29.786710759493673, -1.399037413793103 ], [ 29.786710759493673, -1.398767931034483 ], [ 29.786441265822784, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2337, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.398767931034483 ], [ 29.786710759493673, -1.399037413793103 ], [ 29.786980253164558, -1.399037413793103 ], [ 29.786980253164558, -1.398767931034483 ], [ 29.786710759493673, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2338, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.398767931034483 ], [ 29.786980253164558, -1.399037413793103 ], [ 29.787249746835442, -1.399037413793103 ], [ 29.787249746835442, -1.398767931034483 ], [ 29.786980253164558, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2339, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.398767931034483 ], [ 29.787249746835442, -1.399037413793103 ], [ 29.787519240506327, -1.399037413793103 ], [ 29.787519240506327, -1.398767931034483 ], [ 29.787249746835442, -1.398767931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2340, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.399037413793103 ], [ 29.7667682278481, -1.399576379310345 ], [ 29.767037721518985, -1.399576379310345 ], [ 29.767037721518985, -1.399037413793103 ], [ 29.7667682278481, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2341, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.399037413793103 ], [ 29.767037721518985, -1.399576379310345 ], [ 29.767307215189874, -1.399576379310345 ], [ 29.767307215189874, -1.399037413793103 ], [ 29.767037721518985, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2342, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.399037413793103 ], [ 29.767307215189874, -1.399576379310345 ], [ 29.767576708860759, -1.399576379310345 ], [ 29.767576708860759, -1.399037413793103 ], [ 29.767307215189874, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2343, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.399037413793103 ], [ 29.767576708860759, -1.399845862068966 ], [ 29.767846202531643, -1.399845862068966 ], [ 29.767846202531643, -1.399037413793103 ], [ 29.767576708860759, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2344, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.399037413793103 ], [ 29.767846202531643, -1.399576379310345 ], [ 29.768115696202532, -1.399576379310345 ], [ 29.768115696202532, -1.399037413793103 ], [ 29.767846202531643, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2345, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.399037413793103 ], [ 29.768115696202532, -1.399576379310345 ], [ 29.768385189873417, -1.399576379310345 ], [ 29.768385189873417, -1.399037413793103 ], [ 29.768115696202532, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2346, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.399037413793103 ], [ 29.768385189873417, -1.399845862068966 ], [ 29.768654683544302, -1.399845862068966 ], [ 29.768654683544302, -1.399037413793103 ], [ 29.768385189873417, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2347, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.399037413793103 ], [ 29.76892417721519, -1.399845862068966 ], [ 29.769193670886075, -1.399845862068966 ], [ 29.769193670886075, -1.399037413793103 ], [ 29.76892417721519, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2348, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.399037413793103 ], [ 29.76946316455696, -1.399576379310345 ], [ 29.769732658227849, -1.399576379310345 ], [ 29.769732658227849, -1.399037413793103 ], [ 29.76946316455696, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2349, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.399037413793103 ], [ 29.769732658227849, -1.399576379310345 ], [ 29.770002151898733, -1.399576379310345 ], [ 29.770002151898733, -1.399037413793103 ], [ 29.769732658227849, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2350, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.399037413793103 ], [ 29.770002151898733, -1.399845862068966 ], [ 29.770271645569618, -1.399845862068966 ], [ 29.770271645569618, -1.399037413793103 ], [ 29.770002151898733, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2351, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.399037413793103 ], [ 29.770271645569618, -1.399576379310345 ], [ 29.770541139240507, -1.399576379310345 ], [ 29.770541139240507, -1.399037413793103 ], [ 29.770271645569618, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2352, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.399037413793103 ], [ 29.770541139240507, -1.399576379310345 ], [ 29.770810632911392, -1.399576379310345 ], [ 29.770810632911392, -1.399037413793103 ], [ 29.770541139240507, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2353, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.399037413793103 ], [ 29.770810632911392, -1.399576379310345 ], [ 29.771080126582277, -1.399576379310345 ], [ 29.771080126582277, -1.399037413793103 ], [ 29.770810632911392, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2354, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.399037413793103 ], [ 29.771080126582277, -1.399576379310345 ], [ 29.771349620253165, -1.399576379310345 ], [ 29.771349620253165, -1.399037413793103 ], [ 29.771080126582277, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2355, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.399037413793103 ], [ 29.771349620253165, -1.399576379310345 ], [ 29.77161911392405, -1.399576379310345 ], [ 29.77161911392405, -1.399037413793103 ], [ 29.771349620253165, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2356, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.399037413793103 ], [ 29.77161911392405, -1.399576379310345 ], [ 29.771888607594935, -1.399576379310345 ], [ 29.771888607594935, -1.399037413793103 ], [ 29.77161911392405, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2357, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.399037413793103 ], [ 29.771888607594935, -1.399576379310345 ], [ 29.772158101265823, -1.399576379310345 ], [ 29.772158101265823, -1.399037413793103 ], [ 29.771888607594935, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2358, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.399037413793103 ], [ 29.772158101265823, -1.399576379310345 ], [ 29.772427594936708, -1.399576379310345 ], [ 29.772427594936708, -1.399037413793103 ], [ 29.772158101265823, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2359, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.399037413793103 ], [ 29.772427594936708, -1.399576379310345 ], [ 29.772697088607593, -1.399576379310345 ], [ 29.772697088607593, -1.399037413793103 ], [ 29.772427594936708, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2360, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.399037413793103 ], [ 29.772697088607593, -1.399576379310345 ], [ 29.772966582278482, -1.399576379310345 ], [ 29.772966582278482, -1.399037413793103 ], [ 29.772697088607593, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2361, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.399037413793103 ], [ 29.772966582278482, -1.399576379310345 ], [ 29.773236075949367, -1.399576379310345 ], [ 29.773236075949367, -1.399037413793103 ], [ 29.772966582278482, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2362, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.399037413793103 ], [ 29.773236075949367, -1.399576379310345 ], [ 29.773505569620252, -1.399576379310345 ], [ 29.773505569620252, -1.399037413793103 ], [ 29.773236075949367, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2363, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.399037413793103 ], [ 29.773505569620252, -1.399576379310345 ], [ 29.774044556962025, -1.399576379310345 ], [ 29.774044556962025, -1.399037413793103 ], [ 29.773505569620252, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2364, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.399037413793103 ], [ 29.774044556962025, -1.399576379310345 ], [ 29.77431405063291, -1.399576379310345 ], [ 29.77431405063291, -1.399037413793103 ], [ 29.774044556962025, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2365, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.399037413793103 ], [ 29.77431405063291, -1.399576379310345 ], [ 29.774853037974683, -1.399576379310345 ], [ 29.774853037974683, -1.399037413793103 ], [ 29.77431405063291, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2366, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.399037413793103 ], [ 29.774853037974683, -1.399576379310345 ], [ 29.775122531645568, -1.399576379310345 ], [ 29.775122531645568, -1.399037413793103 ], [ 29.774853037974683, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2367, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.399037413793103 ], [ 29.775122531645568, -1.399576379310345 ], [ 29.775661518987341, -1.399576379310345 ], [ 29.775661518987341, -1.399037413793103 ], [ 29.775122531645568, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2368, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.399037413793103 ], [ 29.775661518987341, -1.399576379310345 ], [ 29.775931012658226, -1.399576379310345 ], [ 29.775931012658226, -1.399037413793103 ], [ 29.775661518987341, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2369, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.399037413793103 ], [ 29.775931012658226, -1.399576379310345 ], [ 29.776200506329115, -1.399576379310345 ], [ 29.776200506329115, -1.399037413793103 ], [ 29.775931012658226, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2370, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.399037413793103 ], [ 29.776200506329115, -1.399576379310345 ], [ 29.77647, -1.399576379310345 ], [ 29.77647, -1.399037413793103 ], [ 29.776200506329115, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2371, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.399037413793103 ], [ 29.785363291139241, -1.399576379310345 ], [ 29.785632784810126, -1.399576379310345 ], [ 29.785632784810126, -1.399037413793103 ], [ 29.785363291139241, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2372, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.399037413793103 ], [ 29.785632784810126, -1.399576379310345 ], [ 29.785902278481014, -1.399576379310345 ], [ 29.785902278481014, -1.399037413793103 ], [ 29.785632784810126, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2373, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.399037413793103 ], [ 29.785902278481014, -1.399576379310345 ], [ 29.786171772151899, -1.399576379310345 ], [ 29.786171772151899, -1.399037413793103 ], [ 29.785902278481014, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2374, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.399037413793103 ], [ 29.786171772151899, -1.399576379310345 ], [ 29.786441265822784, -1.399576379310345 ], [ 29.786441265822784, -1.399037413793103 ], [ 29.786171772151899, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2375, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.399037413793103 ], [ 29.786441265822784, -1.399576379310345 ], [ 29.786710759493673, -1.399576379310345 ], [ 29.786710759493673, -1.399037413793103 ], [ 29.786441265822784, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2376, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.399037413793103 ], [ 29.786710759493673, -1.399576379310345 ], [ 29.786980253164558, -1.399576379310345 ], [ 29.786980253164558, -1.399037413793103 ], [ 29.786710759493673, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2377, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.399037413793103 ], [ 29.786980253164558, -1.399576379310345 ], [ 29.787249746835442, -1.399576379310345 ], [ 29.787249746835442, -1.399037413793103 ], [ 29.786980253164558, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2378, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.399037413793103 ], [ 29.787249746835442, -1.399576379310345 ], [ 29.787519240506327, -1.399576379310345 ], [ 29.787519240506327, -1.399037413793103 ], [ 29.787249746835442, -1.399037413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2379, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.399576379310345 ], [ 29.766229240506327, -1.399845862068966 ], [ 29.766498734177215, -1.399845862068966 ], [ 29.766498734177215, -1.399576379310345 ], [ 29.766229240506327, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2380, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.399576379310345 ], [ 29.766498734177215, -1.399845862068966 ], [ 29.7667682278481, -1.399845862068966 ], [ 29.7667682278481, -1.399576379310345 ], [ 29.766498734177215, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2381, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.399576379310345 ], [ 29.7667682278481, -1.399845862068966 ], [ 29.767037721518985, -1.399845862068966 ], [ 29.767037721518985, -1.399576379310345 ], [ 29.7667682278481, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2382, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.399576379310345 ], [ 29.767037721518985, -1.400384827586207 ], [ 29.767307215189874, -1.400384827586207 ], [ 29.767307215189874, -1.399576379310345 ], [ 29.767037721518985, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2383, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.399576379310345 ], [ 29.767307215189874, -1.400384827586207 ], [ 29.767576708860759, -1.400384827586207 ], [ 29.767576708860759, -1.399576379310345 ], [ 29.767307215189874, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2384, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.399576379310345 ], [ 29.767846202531643, -1.400115344827586 ], [ 29.768115696202532, -1.400115344827586 ], [ 29.768115696202532, -1.399576379310345 ], [ 29.767846202531643, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2385, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.399576379310345 ], [ 29.768115696202532, -1.400115344827586 ], [ 29.768385189873417, -1.400115344827586 ], [ 29.768385189873417, -1.399576379310345 ], [ 29.768115696202532, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2386, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.399576379310345 ], [ 29.768654683544302, -1.399845862068966 ], [ 29.76892417721519, -1.399845862068966 ], [ 29.76892417721519, -1.399576379310345 ], [ 29.768654683544302, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2387, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.399576379310345 ], [ 29.76946316455696, -1.399845862068966 ], [ 29.769732658227849, -1.399845862068966 ], [ 29.769732658227849, -1.399576379310345 ], [ 29.76946316455696, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2388, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.399576379310345 ], [ 29.769732658227849, -1.399845862068966 ], [ 29.770002151898733, -1.399845862068966 ], [ 29.770002151898733, -1.399576379310345 ], [ 29.769732658227849, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2389, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.399576379310345 ], [ 29.770271645569618, -1.399845862068966 ], [ 29.770541139240507, -1.399845862068966 ], [ 29.770541139240507, -1.399576379310345 ], [ 29.770271645569618, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2390, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.399576379310345 ], [ 29.770541139240507, -1.399845862068966 ], [ 29.770810632911392, -1.399845862068966 ], [ 29.770810632911392, -1.399576379310345 ], [ 29.770541139240507, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2391, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.399576379310345 ], [ 29.770810632911392, -1.399845862068966 ], [ 29.771080126582277, -1.399845862068966 ], [ 29.771080126582277, -1.399576379310345 ], [ 29.770810632911392, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2392, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.399576379310345 ], [ 29.771080126582277, -1.399845862068966 ], [ 29.771349620253165, -1.399845862068966 ], [ 29.771349620253165, -1.399576379310345 ], [ 29.771080126582277, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2393, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.399576379310345 ], [ 29.771349620253165, -1.399845862068966 ], [ 29.77161911392405, -1.399845862068966 ], [ 29.77161911392405, -1.399576379310345 ], [ 29.771349620253165, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2394, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.399576379310345 ], [ 29.77161911392405, -1.399845862068966 ], [ 29.771888607594935, -1.399845862068966 ], [ 29.771888607594935, -1.399576379310345 ], [ 29.77161911392405, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2395, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.399576379310345 ], [ 29.771888607594935, -1.399845862068966 ], [ 29.772158101265823, -1.399845862068966 ], [ 29.772158101265823, -1.399576379310345 ], [ 29.771888607594935, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2396, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.399576379310345 ], [ 29.772158101265823, -1.399845862068966 ], [ 29.772427594936708, -1.399845862068966 ], [ 29.772427594936708, -1.399576379310345 ], [ 29.772158101265823, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2397, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.399576379310345 ], [ 29.772427594936708, -1.399845862068966 ], [ 29.772697088607593, -1.399845862068966 ], [ 29.772697088607593, -1.399576379310345 ], [ 29.772427594936708, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2398, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.399576379310345 ], [ 29.772697088607593, -1.399845862068966 ], [ 29.772966582278482, -1.399845862068966 ], [ 29.772966582278482, -1.399576379310345 ], [ 29.772697088607593, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2399, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.399576379310345 ], [ 29.772966582278482, -1.399845862068966 ], [ 29.773236075949367, -1.399845862068966 ], [ 29.773236075949367, -1.399576379310345 ], [ 29.772966582278482, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2400, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.399576379310345 ], [ 29.773236075949367, -1.399845862068966 ], [ 29.773505569620252, -1.399845862068966 ], [ 29.773505569620252, -1.399576379310345 ], [ 29.773236075949367, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2401, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.399576379310345 ], [ 29.773505569620252, -1.399845862068966 ], [ 29.77377506329114, -1.399845862068966 ], [ 29.77377506329114, -1.399576379310345 ], [ 29.773505569620252, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2402, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.399576379310345 ], [ 29.77377506329114, -1.399845862068966 ], [ 29.774044556962025, -1.399845862068966 ], [ 29.774044556962025, -1.399576379310345 ], [ 29.77377506329114, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2403, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.399576379310345 ], [ 29.774044556962025, -1.399845862068966 ], [ 29.77431405063291, -1.399845862068966 ], [ 29.77431405063291, -1.399576379310345 ], [ 29.774044556962025, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2404, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.399576379310345 ], [ 29.77431405063291, -1.399845862068966 ], [ 29.774853037974683, -1.399845862068966 ], [ 29.774853037974683, -1.399576379310345 ], [ 29.77431405063291, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2405, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.399576379310345 ], [ 29.774853037974683, -1.399845862068966 ], [ 29.775122531645568, -1.399845862068966 ], [ 29.775122531645568, -1.399576379310345 ], [ 29.774853037974683, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2406, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.399576379310345 ], [ 29.775122531645568, -1.400115344827586 ], [ 29.775392025316457, -1.400115344827586 ], [ 29.775392025316457, -1.399576379310345 ], [ 29.775122531645568, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2407, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.399576379310345 ], [ 29.775392025316457, -1.399845862068966 ], [ 29.775661518987341, -1.399845862068966 ], [ 29.775661518987341, -1.399576379310345 ], [ 29.775392025316457, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2408, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.399576379310345 ], [ 29.775661518987341, -1.399845862068966 ], [ 29.775931012658226, -1.399845862068966 ], [ 29.775931012658226, -1.399576379310345 ], [ 29.775661518987341, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2409, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.399576379310345 ], [ 29.775931012658226, -1.399845862068966 ], [ 29.776200506329115, -1.399845862068966 ], [ 29.776200506329115, -1.399576379310345 ], [ 29.775931012658226, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2410, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.399576379310345 ], [ 29.776200506329115, -1.399845862068966 ], [ 29.77647, -1.399845862068966 ], [ 29.77647, -1.399576379310345 ], [ 29.776200506329115, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2411, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.399576379310345 ], [ 29.77647, -1.399845862068966 ], [ 29.776739493670885, -1.399845862068966 ], [ 29.776739493670885, -1.399576379310345 ], [ 29.77647, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2412, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.399576379310345 ], [ 29.776739493670885, -1.399845862068966 ], [ 29.777008987341773, -1.399845862068966 ], [ 29.777008987341773, -1.399576379310345 ], [ 29.776739493670885, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2413, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.399576379310345 ], [ 29.785363291139241, -1.400115344827586 ], [ 29.785632784810126, -1.400115344827586 ], [ 29.785632784810126, -1.399576379310345 ], [ 29.785363291139241, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2414, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.399576379310345 ], [ 29.785632784810126, -1.399845862068966 ], [ 29.785902278481014, -1.399845862068966 ], [ 29.785902278481014, -1.399576379310345 ], [ 29.785632784810126, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2415, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.399576379310345 ], [ 29.785902278481014, -1.399845862068966 ], [ 29.786441265822784, -1.399845862068966 ], [ 29.786441265822784, -1.399576379310345 ], [ 29.785902278481014, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2416, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.399576379310345 ], [ 29.786441265822784, -1.399845862068966 ], [ 29.786710759493673, -1.399845862068966 ], [ 29.786710759493673, -1.399576379310345 ], [ 29.786441265822784, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2417, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.399576379310345 ], [ 29.786710759493673, -1.399845862068966 ], [ 29.786980253164558, -1.399845862068966 ], [ 29.786980253164558, -1.399576379310345 ], [ 29.786710759493673, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2418, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.399576379310345 ], [ 29.786980253164558, -1.399845862068966 ], [ 29.787249746835442, -1.399845862068966 ], [ 29.787249746835442, -1.399576379310345 ], [ 29.786980253164558, -1.399576379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2419, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.399845862068966 ], [ 29.766498734177215, -1.400654310344828 ], [ 29.7667682278481, -1.400654310344828 ], [ 29.7667682278481, -1.399845862068966 ], [ 29.766498734177215, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2420, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.399845862068966 ], [ 29.7667682278481, -1.400654310344828 ], [ 29.767037721518985, -1.400654310344828 ], [ 29.767037721518985, -1.399845862068966 ], [ 29.7667682278481, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2421, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.399845862068966 ], [ 29.767576708860759, -1.400384827586207 ], [ 29.767846202531643, -1.400384827586207 ], [ 29.767846202531643, -1.399845862068966 ], [ 29.767576708860759, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2422, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.399845862068966 ], [ 29.768385189873417, -1.400115344827586 ], [ 29.768654683544302, -1.400115344827586 ], [ 29.768654683544302, -1.399845862068966 ], [ 29.768385189873417, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2423, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.399845862068966 ], [ 29.768654683544302, -1.400115344827586 ], [ 29.76892417721519, -1.400115344827586 ], [ 29.76892417721519, -1.399845862068966 ], [ 29.768654683544302, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2424, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.399845862068966 ], [ 29.76892417721519, -1.400115344827586 ], [ 29.769193670886075, -1.400115344827586 ], [ 29.769193670886075, -1.399845862068966 ], [ 29.76892417721519, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2425, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.399845862068966 ], [ 29.769193670886075, -1.400115344827586 ], [ 29.76946316455696, -1.400115344827586 ], [ 29.76946316455696, -1.399845862068966 ], [ 29.769193670886075, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2426, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.399845862068966 ], [ 29.76946316455696, -1.400115344827586 ], [ 29.769732658227849, -1.400115344827586 ], [ 29.769732658227849, -1.399845862068966 ], [ 29.76946316455696, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2427, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.399845862068966 ], [ 29.769732658227849, -1.400115344827586 ], [ 29.770002151898733, -1.400115344827586 ], [ 29.770002151898733, -1.399845862068966 ], [ 29.769732658227849, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2428, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.399845862068966 ], [ 29.770002151898733, -1.400384827586207 ], [ 29.770271645569618, -1.400384827586207 ], [ 29.770271645569618, -1.399845862068966 ], [ 29.770002151898733, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2429, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.399845862068966 ], [ 29.770271645569618, -1.400115344827586 ], [ 29.770541139240507, -1.400115344827586 ], [ 29.770541139240507, -1.399845862068966 ], [ 29.770271645569618, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2430, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.399845862068966 ], [ 29.770541139240507, -1.400115344827586 ], [ 29.770810632911392, -1.400115344827586 ], [ 29.770810632911392, -1.399845862068966 ], [ 29.770541139240507, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2431, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.399845862068966 ], [ 29.770810632911392, -1.400384827586207 ], [ 29.771080126582277, -1.400384827586207 ], [ 29.771080126582277, -1.399845862068966 ], [ 29.770810632911392, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2432, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.399845862068966 ], [ 29.771080126582277, -1.400115344827586 ], [ 29.771349620253165, -1.400115344827586 ], [ 29.771349620253165, -1.399845862068966 ], [ 29.771080126582277, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2433, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.399845862068966 ], [ 29.771349620253165, -1.400115344827586 ], [ 29.77161911392405, -1.400115344827586 ], [ 29.77161911392405, -1.399845862068966 ], [ 29.771349620253165, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2434, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.399845862068966 ], [ 29.77161911392405, -1.400384827586207 ], [ 29.771888607594935, -1.400384827586207 ], [ 29.771888607594935, -1.399845862068966 ], [ 29.77161911392405, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2435, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.399845862068966 ], [ 29.771888607594935, -1.400654310344828 ], [ 29.772158101265823, -1.400654310344828 ], [ 29.772158101265823, -1.399845862068966 ], [ 29.771888607594935, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2436, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.399845862068966 ], [ 29.772427594936708, -1.400115344827586 ], [ 29.772697088607593, -1.400115344827586 ], [ 29.772697088607593, -1.399845862068966 ], [ 29.772427594936708, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2437, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.399845862068966 ], [ 29.772697088607593, -1.400115344827586 ], [ 29.772966582278482, -1.400115344827586 ], [ 29.772966582278482, -1.399845862068966 ], [ 29.772697088607593, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2438, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.399845862068966 ], [ 29.772966582278482, -1.400115344827586 ], [ 29.773236075949367, -1.400115344827586 ], [ 29.773236075949367, -1.399845862068966 ], [ 29.772966582278482, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2439, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.399845862068966 ], [ 29.773236075949367, -1.400115344827586 ], [ 29.773505569620252, -1.400115344827586 ], [ 29.773505569620252, -1.399845862068966 ], [ 29.773236075949367, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2440, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.399845862068966 ], [ 29.773505569620252, -1.400115344827586 ], [ 29.77377506329114, -1.400115344827586 ], [ 29.77377506329114, -1.399845862068966 ], [ 29.773505569620252, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2441, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.399845862068966 ], [ 29.77377506329114, -1.400115344827586 ], [ 29.774044556962025, -1.400115344827586 ], [ 29.774044556962025, -1.399845862068966 ], [ 29.77377506329114, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2442, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.399845862068966 ], [ 29.774044556962025, -1.400115344827586 ], [ 29.77431405063291, -1.400115344827586 ], [ 29.77431405063291, -1.399845862068966 ], [ 29.774044556962025, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2443, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.399845862068966 ], [ 29.77431405063291, -1.400384827586207 ], [ 29.774853037974683, -1.400384827586207 ], [ 29.774853037974683, -1.399845862068966 ], [ 29.77431405063291, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2444, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.399845862068966 ], [ 29.774853037974683, -1.400115344827586 ], [ 29.775122531645568, -1.400115344827586 ], [ 29.775122531645568, -1.399845862068966 ], [ 29.774853037974683, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2445, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.399845862068966 ], [ 29.775392025316457, -1.400115344827586 ], [ 29.775661518987341, -1.400115344827586 ], [ 29.775661518987341, -1.399845862068966 ], [ 29.775392025316457, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2446, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.399845862068966 ], [ 29.775661518987341, -1.400115344827586 ], [ 29.775931012658226, -1.400115344827586 ], [ 29.775931012658226, -1.399845862068966 ], [ 29.775661518987341, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2447, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.399845862068966 ], [ 29.775931012658226, -1.400115344827586 ], [ 29.776200506329115, -1.400115344827586 ], [ 29.776200506329115, -1.399845862068966 ], [ 29.775931012658226, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2448, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.399845862068966 ], [ 29.776200506329115, -1.400115344827586 ], [ 29.77647, -1.400115344827586 ], [ 29.77647, -1.399845862068966 ], [ 29.776200506329115, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2449, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.399845862068966 ], [ 29.77647, -1.400115344827586 ], [ 29.776739493670885, -1.400115344827586 ], [ 29.776739493670885, -1.399845862068966 ], [ 29.77647, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2450, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.399845862068966 ], [ 29.776739493670885, -1.400115344827586 ], [ 29.777008987341773, -1.400115344827586 ], [ 29.777008987341773, -1.399845862068966 ], [ 29.776739493670885, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2451, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.399845862068966 ], [ 29.777008987341773, -1.400115344827586 ], [ 29.777278481012658, -1.400115344827586 ], [ 29.777278481012658, -1.399845862068966 ], [ 29.777008987341773, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2452, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.399845862068966 ], [ 29.777278481012658, -1.400115344827586 ], [ 29.777547974683543, -1.400115344827586 ], [ 29.777547974683543, -1.399845862068966 ], [ 29.777278481012658, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2453, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.399845862068966 ], [ 29.785632784810126, -1.400115344827586 ], [ 29.785902278481014, -1.400115344827586 ], [ 29.785902278481014, -1.399845862068966 ], [ 29.785632784810126, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2454, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.399845862068966 ], [ 29.785902278481014, -1.400115344827586 ], [ 29.786441265822784, -1.400115344827586 ], [ 29.786441265822784, -1.399845862068966 ], [ 29.785902278481014, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2455, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.399845862068966 ], [ 29.786441265822784, -1.400115344827586 ], [ 29.786710759493673, -1.400115344827586 ], [ 29.786710759493673, -1.399845862068966 ], [ 29.786441265822784, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2456, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.399845862068966 ], [ 29.786710759493673, -1.400115344827586 ], [ 29.786980253164558, -1.400115344827586 ], [ 29.786980253164558, -1.399845862068966 ], [ 29.786710759493673, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2457, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.400115344827586 ], [ 29.767846202531643, -1.400384827586207 ], [ 29.768115696202532, -1.400384827586207 ], [ 29.768115696202532, -1.400115344827586 ], [ 29.767846202531643, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2458, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.400115344827586 ], [ 29.768115696202532, -1.400384827586207 ], [ 29.768385189873417, -1.400384827586207 ], [ 29.768385189873417, -1.400115344827586 ], [ 29.768115696202532, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2459, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.400115344827586 ], [ 29.768385189873417, -1.400384827586207 ], [ 29.768654683544302, -1.400384827586207 ], [ 29.768654683544302, -1.400115344827586 ], [ 29.768385189873417, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2460, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.400115344827586 ], [ 29.768654683544302, -1.400384827586207 ], [ 29.76892417721519, -1.400384827586207 ], [ 29.76892417721519, -1.400115344827586 ], [ 29.768654683544302, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2461, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.400115344827586 ], [ 29.76892417721519, -1.400654310344828 ], [ 29.769193670886075, -1.400654310344828 ], [ 29.769193670886075, -1.400115344827586 ], [ 29.76892417721519, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2462, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.400115344827586 ], [ 29.769193670886075, -1.400384827586207 ], [ 29.76946316455696, -1.400384827586207 ], [ 29.76946316455696, -1.400115344827586 ], [ 29.769193670886075, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2463, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.400115344827586 ], [ 29.76946316455696, -1.400384827586207 ], [ 29.769732658227849, -1.400384827586207 ], [ 29.769732658227849, -1.400115344827586 ], [ 29.76946316455696, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2464, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.400115344827586 ], [ 29.769732658227849, -1.400654310344828 ], [ 29.770002151898733, -1.400654310344828 ], [ 29.770002151898733, -1.400115344827586 ], [ 29.769732658227849, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2465, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.400115344827586 ], [ 29.770271645569618, -1.400384827586207 ], [ 29.770541139240507, -1.400384827586207 ], [ 29.770541139240507, -1.400115344827586 ], [ 29.770271645569618, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2466, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.400115344827586 ], [ 29.770541139240507, -1.400654310344828 ], [ 29.770810632911392, -1.400654310344828 ], [ 29.770810632911392, -1.400115344827586 ], [ 29.770541139240507, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2467, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.400115344827586 ], [ 29.771080126582277, -1.400654310344828 ], [ 29.771349620253165, -1.400654310344828 ], [ 29.771349620253165, -1.400115344827586 ], [ 29.771080126582277, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2468, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.400115344827586 ], [ 29.772427594936708, -1.400384827586207 ], [ 29.772697088607593, -1.400384827586207 ], [ 29.772697088607593, -1.400115344827586 ], [ 29.772427594936708, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2469, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.400115344827586 ], [ 29.772966582278482, -1.400384827586207 ], [ 29.773236075949367, -1.400384827586207 ], [ 29.773236075949367, -1.400115344827586 ], [ 29.772966582278482, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2470, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.400115344827586 ], [ 29.773236075949367, -1.400384827586207 ], [ 29.773505569620252, -1.400384827586207 ], [ 29.773505569620252, -1.400115344827586 ], [ 29.773236075949367, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2471, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.400115344827586 ], [ 29.773505569620252, -1.400384827586207 ], [ 29.77377506329114, -1.400384827586207 ], [ 29.77377506329114, -1.400115344827586 ], [ 29.773505569620252, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2472, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.400115344827586 ], [ 29.774044556962025, -1.400384827586207 ], [ 29.77431405063291, -1.400384827586207 ], [ 29.77431405063291, -1.400115344827586 ], [ 29.774044556962025, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2473, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.400115344827586 ], [ 29.774853037974683, -1.400384827586207 ], [ 29.775122531645568, -1.400384827586207 ], [ 29.775122531645568, -1.400115344827586 ], [ 29.774853037974683, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2474, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.400115344827586 ], [ 29.775122531645568, -1.400384827586207 ], [ 29.775392025316457, -1.400384827586207 ], [ 29.775392025316457, -1.400115344827586 ], [ 29.775122531645568, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2475, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.400115344827586 ], [ 29.775392025316457, -1.400384827586207 ], [ 29.775661518987341, -1.400384827586207 ], [ 29.775661518987341, -1.400115344827586 ], [ 29.775392025316457, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2476, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.400115344827586 ], [ 29.775661518987341, -1.400384827586207 ], [ 29.775931012658226, -1.400384827586207 ], [ 29.775931012658226, -1.400115344827586 ], [ 29.775661518987341, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2477, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.400115344827586 ], [ 29.775931012658226, -1.400384827586207 ], [ 29.776200506329115, -1.400384827586207 ], [ 29.776200506329115, -1.400115344827586 ], [ 29.775931012658226, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2478, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.400115344827586 ], [ 29.776200506329115, -1.400384827586207 ], [ 29.77647, -1.400384827586207 ], [ 29.77647, -1.400115344827586 ], [ 29.776200506329115, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2479, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.400115344827586 ], [ 29.77647, -1.400384827586207 ], [ 29.776739493670885, -1.400384827586207 ], [ 29.776739493670885, -1.400115344827586 ], [ 29.77647, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2480, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.400115344827586 ], [ 29.776739493670885, -1.400384827586207 ], [ 29.777008987341773, -1.400384827586207 ], [ 29.777008987341773, -1.400115344827586 ], [ 29.776739493670885, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2481, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.400115344827586 ], [ 29.777008987341773, -1.400384827586207 ], [ 29.777278481012658, -1.400384827586207 ], [ 29.777278481012658, -1.400115344827586 ], [ 29.777008987341773, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2482, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.400115344827586 ], [ 29.777278481012658, -1.400384827586207 ], [ 29.777547974683543, -1.400384827586207 ], [ 29.777547974683543, -1.400115344827586 ], [ 29.777278481012658, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2483, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.400115344827586 ], [ 29.777547974683543, -1.400384827586207 ], [ 29.777817468354431, -1.400384827586207 ], [ 29.777817468354431, -1.400115344827586 ], [ 29.777547974683543, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2484, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.400115344827586 ], [ 29.777817468354431, -1.400384827586207 ], [ 29.778086962025316, -1.400384827586207 ], [ 29.778086962025316, -1.400115344827586 ], [ 29.777817468354431, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2485, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.400115344827586 ], [ 29.785632784810126, -1.400384827586207 ], [ 29.786710759493673, -1.400384827586207 ], [ 29.786710759493673, -1.400115344827586 ], [ 29.785632784810126, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2486, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.400384827586207 ], [ 29.767037721518985, -1.400654310344828 ], [ 29.767307215189874, -1.400654310344828 ], [ 29.767307215189874, -1.400384827586207 ], [ 29.767037721518985, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2487, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.400384827586207 ], [ 29.767307215189874, -1.400654310344828 ], [ 29.767576708860759, -1.400654310344828 ], [ 29.767576708860759, -1.400384827586207 ], [ 29.767307215189874, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2488, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.400384827586207 ], [ 29.767576708860759, -1.400654310344828 ], [ 29.767846202531643, -1.400654310344828 ], [ 29.767846202531643, -1.400384827586207 ], [ 29.767576708860759, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2489, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.400384827586207 ], [ 29.768115696202532, -1.400654310344828 ], [ 29.768385189873417, -1.400654310344828 ], [ 29.768385189873417, -1.400384827586207 ], [ 29.768115696202532, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2490, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.400384827586207 ], [ 29.768385189873417, -1.400654310344828 ], [ 29.768654683544302, -1.400654310344828 ], [ 29.768654683544302, -1.400384827586207 ], [ 29.768385189873417, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2491, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.400384827586207 ], [ 29.773236075949367, -1.400654310344828 ], [ 29.773505569620252, -1.400654310344828 ], [ 29.773505569620252, -1.400384827586207 ], [ 29.773236075949367, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2492, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.400384827586207 ], [ 29.774044556962025, -1.400654310344828 ], [ 29.77431405063291, -1.400654310344828 ], [ 29.77431405063291, -1.400384827586207 ], [ 29.774044556962025, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2493, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.400384827586207 ], [ 29.77431405063291, -1.400654310344828 ], [ 29.774853037974683, -1.400654310344828 ], [ 29.774853037974683, -1.400384827586207 ], [ 29.77431405063291, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2494, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.400384827586207 ], [ 29.774853037974683, -1.400654310344828 ], [ 29.775122531645568, -1.400654310344828 ], [ 29.775122531645568, -1.400384827586207 ], [ 29.774853037974683, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2495, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.400384827586207 ], [ 29.775122531645568, -1.400654310344828 ], [ 29.775392025316457, -1.400654310344828 ], [ 29.775392025316457, -1.400384827586207 ], [ 29.775122531645568, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2496, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.400384827586207 ], [ 29.775392025316457, -1.400654310344828 ], [ 29.775661518987341, -1.400654310344828 ], [ 29.775661518987341, -1.400384827586207 ], [ 29.775392025316457, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2497, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.400384827586207 ], [ 29.775661518987341, -1.400654310344828 ], [ 29.775931012658226, -1.400654310344828 ], [ 29.775931012658226, -1.400384827586207 ], [ 29.775661518987341, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2498, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.400384827586207 ], [ 29.775931012658226, -1.400654310344828 ], [ 29.776200506329115, -1.400654310344828 ], [ 29.776200506329115, -1.400384827586207 ], [ 29.775931012658226, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2499, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.400384827586207 ], [ 29.776200506329115, -1.400654310344828 ], [ 29.77647, -1.400654310344828 ], [ 29.77647, -1.400384827586207 ], [ 29.776200506329115, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2500, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.400384827586207 ], [ 29.77647, -1.400654310344828 ], [ 29.776739493670885, -1.400654310344828 ], [ 29.776739493670885, -1.400384827586207 ], [ 29.77647, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2501, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.400384827586207 ], [ 29.776739493670885, -1.400654310344828 ], [ 29.777008987341773, -1.400654310344828 ], [ 29.777008987341773, -1.400384827586207 ], [ 29.776739493670885, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2502, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.400384827586207 ], [ 29.777008987341773, -1.400654310344828 ], [ 29.777278481012658, -1.400654310344828 ], [ 29.777278481012658, -1.400384827586207 ], [ 29.777008987341773, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2503, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.400384827586207 ], [ 29.777278481012658, -1.400654310344828 ], [ 29.777547974683543, -1.400654310344828 ], [ 29.777547974683543, -1.400384827586207 ], [ 29.777278481012658, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2504, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.400384827586207 ], [ 29.777547974683543, -1.400654310344828 ], [ 29.777817468354431, -1.400654310344828 ], [ 29.777817468354431, -1.400384827586207 ], [ 29.777547974683543, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2505, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.400384827586207 ], [ 29.777817468354431, -1.400654310344828 ], [ 29.778086962025316, -1.400654310344828 ], [ 29.778086962025316, -1.400384827586207 ], [ 29.777817468354431, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2506, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.400384827586207 ], [ 29.778086962025316, -1.400654310344828 ], [ 29.778356455696201, -1.400654310344828 ], [ 29.778356455696201, -1.400384827586207 ], [ 29.778086962025316, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2507, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.400384827586207 ], [ 29.778356455696201, -1.400654310344828 ], [ 29.77862594936709, -1.400654310344828 ], [ 29.77862594936709, -1.400384827586207 ], [ 29.778356455696201, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2508, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.397420517241379 ], [ 29.779703924050633, -1.400923793103448 ], [ 29.779973417721518, -1.400923793103448 ], [ 29.779973417721518, -1.397420517241379 ], [ 29.779703924050633, -1.397420517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2509, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.39769 ], [ 29.779434430379748, -1.400923793103448 ], [ 29.779703924050633, -1.400923793103448 ], [ 29.779703924050633, -1.39769 ], [ 29.779434430379748, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2510, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.39769 ], [ 29.779973417721518, -1.401193275862069 ], [ 29.780242911392406, -1.401193275862069 ], [ 29.780242911392406, -1.39769 ], [ 29.779973417721518, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2511, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.39769 ], [ 29.780242911392406, -1.401193275862069 ], [ 29.780512405063291, -1.401193275862069 ], [ 29.780512405063291, -1.39769 ], [ 29.780242911392406, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2512, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.39769 ], [ 29.780781898734176, -1.40146275862069 ], [ 29.781051392405065, -1.40146275862069 ], [ 29.781051392405065, -1.39769 ], [ 29.780781898734176, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2513, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.39769 ], [ 29.781051392405065, -1.40146275862069 ], [ 29.78132088607595, -1.40146275862069 ], [ 29.78132088607595, -1.39769 ], [ 29.781051392405065, -1.39769 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2514, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.397959482758621 ], [ 29.780512405063291, -1.401193275862069 ], [ 29.780781898734176, -1.401193275862069 ], [ 29.780781898734176, -1.397959482758621 ], [ 29.780512405063291, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2515, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.397959482758621 ], [ 29.78132088607595, -1.401732241379311 ], [ 29.781590379746834, -1.401732241379311 ], [ 29.781590379746834, -1.397959482758621 ], [ 29.78132088607595, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2516, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.397959482758621 ], [ 29.781590379746834, -1.401732241379311 ], [ 29.781859873417723, -1.401732241379311 ], [ 29.781859873417723, -1.397959482758621 ], [ 29.781590379746834, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2517, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.397959482758621 ], [ 29.781859873417723, -1.402001724137931 ], [ 29.782129367088608, -1.402001724137931 ], [ 29.782129367088608, -1.397959482758621 ], [ 29.781859873417723, -1.397959482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2518, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.399845862068966 ], [ 29.766229240506327, -1.400923793103448 ], [ 29.766498734177215, -1.400923793103448 ], [ 29.766498734177215, -1.399845862068966 ], [ 29.766229240506327, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2519, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.399845862068966 ], [ 29.772158101265823, -1.402271206896552 ], [ 29.772427594936708, -1.402271206896552 ], [ 29.772427594936708, -1.399845862068966 ], [ 29.772158101265823, -1.399845862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2520, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.400115344827586 ], [ 29.771349620253165, -1.402271206896552 ], [ 29.77161911392405, -1.402271206896552 ], [ 29.77161911392405, -1.400115344827586 ], [ 29.771349620253165, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2521, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.400115344827586 ], [ 29.772697088607593, -1.402271206896552 ], [ 29.772966582278482, -1.402271206896552 ], [ 29.772966582278482, -1.400115344827586 ], [ 29.772697088607593, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2522, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.400384827586207 ], [ 29.767846202531643, -1.400923793103448 ], [ 29.768115696202532, -1.400923793103448 ], [ 29.768115696202532, -1.400384827586207 ], [ 29.767846202531643, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2523, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.400384827586207 ], [ 29.768654683544302, -1.402001724137931 ], [ 29.76892417721519, -1.402001724137931 ], [ 29.76892417721519, -1.400384827586207 ], [ 29.768654683544302, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2524, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.400384827586207 ], [ 29.769193670886075, -1.400923793103448 ], [ 29.76946316455696, -1.400923793103448 ], [ 29.76946316455696, -1.400384827586207 ], [ 29.769193670886075, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2525, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.400384827586207 ], [ 29.76946316455696, -1.402001724137931 ], [ 29.769732658227849, -1.402001724137931 ], [ 29.769732658227849, -1.400384827586207 ], [ 29.76946316455696, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2526, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.400384827586207 ], [ 29.770002151898733, -1.402001724137931 ], [ 29.770271645569618, -1.402001724137931 ], [ 29.770271645569618, -1.400384827586207 ], [ 29.770002151898733, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2527, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.400384827586207 ], [ 29.770271645569618, -1.402001724137931 ], [ 29.770541139240507, -1.402001724137931 ], [ 29.770541139240507, -1.400384827586207 ], [ 29.770271645569618, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2528, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.400384827586207 ], [ 29.770810632911392, -1.402001724137931 ], [ 29.771080126582277, -1.402001724137931 ], [ 29.771080126582277, -1.400384827586207 ], [ 29.770810632911392, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2529, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.400384827586207 ], [ 29.77161911392405, -1.402001724137931 ], [ 29.771888607594935, -1.402001724137931 ], [ 29.771888607594935, -1.400384827586207 ], [ 29.77161911392405, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2530, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.400384827586207 ], [ 29.772427594936708, -1.402001724137931 ], [ 29.772697088607593, -1.402001724137931 ], [ 29.772697088607593, -1.400384827586207 ], [ 29.772427594936708, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2531, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.400384827586207 ], [ 29.772966582278482, -1.402001724137931 ], [ 29.773236075949367, -1.402001724137931 ], [ 29.773236075949367, -1.400384827586207 ], [ 29.772966582278482, -1.400384827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2532, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.400115344827586 ], [ 29.774044556962025, -1.400115344827586 ], [ 29.774044556962025, -1.400654310344828 ], [ 29.77377506329114, -1.400654310344828 ], [ 29.77377506329114, -1.40146275862069 ], [ 29.773505569620252, -1.40146275862069 ], [ 29.773505569620252, -1.400384827586207 ], [ 29.77377506329114, -1.400384827586207 ], [ 29.77377506329114, -1.400115344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2533, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.400654310344828 ], [ 29.766498734177215, -1.400923793103448 ], [ 29.7667682278481, -1.400923793103448 ], [ 29.7667682278481, -1.400654310344828 ], [ 29.766498734177215, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2534, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.400654310344828 ], [ 29.7667682278481, -1.400923793103448 ], [ 29.767037721518985, -1.400923793103448 ], [ 29.767037721518985, -1.400654310344828 ], [ 29.7667682278481, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2535, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.400654310344828 ], [ 29.767037721518985, -1.400923793103448 ], [ 29.767307215189874, -1.400923793103448 ], [ 29.767307215189874, -1.400654310344828 ], [ 29.767037721518985, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2536, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.400654310344828 ], [ 29.767307215189874, -1.401732241379311 ], [ 29.767576708860759, -1.401732241379311 ], [ 29.767576708860759, -1.400654310344828 ], [ 29.767307215189874, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2537, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.400654310344828 ], [ 29.767576708860759, -1.401732241379311 ], [ 29.767846202531643, -1.401732241379311 ], [ 29.767846202531643, -1.400654310344828 ], [ 29.767576708860759, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2538, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.400654310344828 ], [ 29.768115696202532, -1.401732241379311 ], [ 29.768385189873417, -1.401732241379311 ], [ 29.768385189873417, -1.400654310344828 ], [ 29.768115696202532, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2539, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.400654310344828 ], [ 29.768385189873417, -1.401732241379311 ], [ 29.768654683544302, -1.401732241379311 ], [ 29.768654683544302, -1.400654310344828 ], [ 29.768385189873417, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2540, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.400654310344828 ], [ 29.76892417721519, -1.401732241379311 ], [ 29.769193670886075, -1.401732241379311 ], [ 29.769193670886075, -1.400654310344828 ], [ 29.76892417721519, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2541, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.400654310344828 ], [ 29.769732658227849, -1.401732241379311 ], [ 29.770002151898733, -1.401732241379311 ], [ 29.770002151898733, -1.400654310344828 ], [ 29.769732658227849, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2542, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.400654310344828 ], [ 29.770541139240507, -1.401732241379311 ], [ 29.770810632911392, -1.401732241379311 ], [ 29.770810632911392, -1.400654310344828 ], [ 29.770541139240507, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2543, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.400654310344828 ], [ 29.771080126582277, -1.401732241379311 ], [ 29.771349620253165, -1.401732241379311 ], [ 29.771349620253165, -1.400654310344828 ], [ 29.771080126582277, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2544, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.400654310344828 ], [ 29.771888607594935, -1.401732241379311 ], [ 29.772158101265823, -1.401732241379311 ], [ 29.772158101265823, -1.400654310344828 ], [ 29.771888607594935, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2545, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.400654310344828 ], [ 29.773236075949367, -1.401732241379311 ], [ 29.773505569620252, -1.401732241379311 ], [ 29.773505569620252, -1.400654310344828 ], [ 29.773236075949367, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2546, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.400654310344828 ], [ 29.77377506329114, -1.400923793103448 ], [ 29.774044556962025, -1.400923793103448 ], [ 29.774044556962025, -1.400654310344828 ], [ 29.77377506329114, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2547, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.400654310344828 ], [ 29.774044556962025, -1.400923793103448 ], [ 29.77431405063291, -1.400923793103448 ], [ 29.77431405063291, -1.400654310344828 ], [ 29.774044556962025, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2548, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.400654310344828 ], [ 29.77431405063291, -1.400923793103448 ], [ 29.774853037974683, -1.400923793103448 ], [ 29.774853037974683, -1.400654310344828 ], [ 29.77431405063291, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2549, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.400654310344828 ], [ 29.774853037974683, -1.400923793103448 ], [ 29.775122531645568, -1.400923793103448 ], [ 29.775122531645568, -1.400654310344828 ], [ 29.774853037974683, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2550, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.400654310344828 ], [ 29.775122531645568, -1.400923793103448 ], [ 29.775392025316457, -1.400923793103448 ], [ 29.775392025316457, -1.400654310344828 ], [ 29.775122531645568, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2551, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.400654310344828 ], [ 29.775392025316457, -1.400923793103448 ], [ 29.775661518987341, -1.400923793103448 ], [ 29.775661518987341, -1.400654310344828 ], [ 29.775392025316457, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2552, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.400654310344828 ], [ 29.775661518987341, -1.400923793103448 ], [ 29.775931012658226, -1.400923793103448 ], [ 29.775931012658226, -1.400654310344828 ], [ 29.775661518987341, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2553, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.400654310344828 ], [ 29.775931012658226, -1.400923793103448 ], [ 29.776200506329115, -1.400923793103448 ], [ 29.776200506329115, -1.400654310344828 ], [ 29.775931012658226, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2554, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.400654310344828 ], [ 29.776200506329115, -1.400923793103448 ], [ 29.77647, -1.400923793103448 ], [ 29.77647, -1.400654310344828 ], [ 29.776200506329115, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2555, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.400654310344828 ], [ 29.77647, -1.400923793103448 ], [ 29.776739493670885, -1.400923793103448 ], [ 29.776739493670885, -1.400654310344828 ], [ 29.77647, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2556, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.400654310344828 ], [ 29.776739493670885, -1.400923793103448 ], [ 29.777008987341773, -1.400923793103448 ], [ 29.777008987341773, -1.400654310344828 ], [ 29.776739493670885, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2557, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.400654310344828 ], [ 29.777008987341773, -1.400923793103448 ], [ 29.777278481012658, -1.400923793103448 ], [ 29.777278481012658, -1.400654310344828 ], [ 29.777008987341773, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2558, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.400654310344828 ], [ 29.777278481012658, -1.400923793103448 ], [ 29.777547974683543, -1.400923793103448 ], [ 29.777547974683543, -1.400654310344828 ], [ 29.777278481012658, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2559, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.400654310344828 ], [ 29.777547974683543, -1.400923793103448 ], [ 29.777817468354431, -1.400923793103448 ], [ 29.777817468354431, -1.400654310344828 ], [ 29.777547974683543, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2560, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.400654310344828 ], [ 29.777817468354431, -1.400923793103448 ], [ 29.778086962025316, -1.400923793103448 ], [ 29.778086962025316, -1.400654310344828 ], [ 29.777817468354431, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2561, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.400654310344828 ], [ 29.778086962025316, -1.400923793103448 ], [ 29.778356455696201, -1.400923793103448 ], [ 29.778356455696201, -1.400654310344828 ], [ 29.778086962025316, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2562, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.400654310344828 ], [ 29.778356455696201, -1.400923793103448 ], [ 29.77862594936709, -1.400923793103448 ], [ 29.77862594936709, -1.400654310344828 ], [ 29.778356455696201, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2563, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.400654310344828 ], [ 29.77862594936709, -1.400923793103448 ], [ 29.778895443037975, -1.400923793103448 ], [ 29.778895443037975, -1.400654310344828 ], [ 29.77862594936709, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2564, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.400654310344828 ], [ 29.778895443037975, -1.400923793103448 ], [ 29.77916493670886, -1.400923793103448 ], [ 29.77916493670886, -1.400654310344828 ], [ 29.778895443037975, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2565, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.400654310344828 ], [ 29.77916493670886, -1.400923793103448 ], [ 29.779434430379748, -1.400923793103448 ], [ 29.779434430379748, -1.400654310344828 ], [ 29.77916493670886, -1.400654310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2566, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.400923793103448 ], [ 29.766498734177215, -1.40146275862069 ], [ 29.7667682278481, -1.40146275862069 ], [ 29.7667682278481, -1.400923793103448 ], [ 29.766498734177215, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2567, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.400923793103448 ], [ 29.7667682278481, -1.40146275862069 ], [ 29.767037721518985, -1.40146275862069 ], [ 29.767037721518985, -1.400923793103448 ], [ 29.7667682278481, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2568, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.400923793103448 ], [ 29.767037721518985, -1.40146275862069 ], [ 29.767307215189874, -1.40146275862069 ], [ 29.767307215189874, -1.400923793103448 ], [ 29.767037721518985, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2569, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.400923793103448 ], [ 29.767846202531643, -1.40146275862069 ], [ 29.768115696202532, -1.40146275862069 ], [ 29.768115696202532, -1.400923793103448 ], [ 29.767846202531643, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2570, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.400923793103448 ], [ 29.769193670886075, -1.40146275862069 ], [ 29.76946316455696, -1.40146275862069 ], [ 29.76946316455696, -1.400923793103448 ], [ 29.769193670886075, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2571, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.400923793103448 ], [ 29.77377506329114, -1.401193275862069 ], [ 29.774044556962025, -1.401193275862069 ], [ 29.774044556962025, -1.400923793103448 ], [ 29.77377506329114, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2572, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.400923793103448 ], [ 29.774044556962025, -1.401193275862069 ], [ 29.77431405063291, -1.401193275862069 ], [ 29.77431405063291, -1.400923793103448 ], [ 29.774044556962025, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2573, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.400923793103448 ], [ 29.77431405063291, -1.401193275862069 ], [ 29.774853037974683, -1.401193275862069 ], [ 29.774853037974683, -1.400923793103448 ], [ 29.77431405063291, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2574, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.400923793103448 ], [ 29.774853037974683, -1.401193275862069 ], [ 29.775122531645568, -1.401193275862069 ], [ 29.775122531645568, -1.400923793103448 ], [ 29.774853037974683, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2575, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.400923793103448 ], [ 29.775122531645568, -1.401193275862069 ], [ 29.775392025316457, -1.401193275862069 ], [ 29.775392025316457, -1.400923793103448 ], [ 29.775122531645568, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2576, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.400923793103448 ], [ 29.775392025316457, -1.401193275862069 ], [ 29.775661518987341, -1.401193275862069 ], [ 29.775661518987341, -1.400923793103448 ], [ 29.775392025316457, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2577, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.400923793103448 ], [ 29.775661518987341, -1.401193275862069 ], [ 29.775931012658226, -1.401193275862069 ], [ 29.775931012658226, -1.400923793103448 ], [ 29.775661518987341, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2578, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.400923793103448 ], [ 29.775931012658226, -1.401193275862069 ], [ 29.776200506329115, -1.401193275862069 ], [ 29.776200506329115, -1.400923793103448 ], [ 29.775931012658226, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2579, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.400923793103448 ], [ 29.776200506329115, -1.401193275862069 ], [ 29.77647, -1.401193275862069 ], [ 29.77647, -1.400923793103448 ], [ 29.776200506329115, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2580, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.400923793103448 ], [ 29.77647, -1.401193275862069 ], [ 29.776739493670885, -1.401193275862069 ], [ 29.776739493670885, -1.400923793103448 ], [ 29.77647, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2581, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.400923793103448 ], [ 29.776739493670885, -1.401193275862069 ], [ 29.777008987341773, -1.401193275862069 ], [ 29.777008987341773, -1.400923793103448 ], [ 29.776739493670885, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2582, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.400923793103448 ], [ 29.777008987341773, -1.401193275862069 ], [ 29.777278481012658, -1.401193275862069 ], [ 29.777278481012658, -1.400923793103448 ], [ 29.777008987341773, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2583, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.400923793103448 ], [ 29.777278481012658, -1.401193275862069 ], [ 29.777547974683543, -1.401193275862069 ], [ 29.777547974683543, -1.400923793103448 ], [ 29.777278481012658, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2584, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.400923793103448 ], [ 29.777547974683543, -1.401193275862069 ], [ 29.777817468354431, -1.401193275862069 ], [ 29.777817468354431, -1.400923793103448 ], [ 29.777547974683543, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2585, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.400923793103448 ], [ 29.777817468354431, -1.401193275862069 ], [ 29.778086962025316, -1.401193275862069 ], [ 29.778086962025316, -1.400923793103448 ], [ 29.777817468354431, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2586, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.400923793103448 ], [ 29.778086962025316, -1.401193275862069 ], [ 29.778356455696201, -1.401193275862069 ], [ 29.778356455696201, -1.400923793103448 ], [ 29.778086962025316, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2587, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.400923793103448 ], [ 29.778356455696201, -1.401193275862069 ], [ 29.77862594936709, -1.401193275862069 ], [ 29.77862594936709, -1.400923793103448 ], [ 29.778356455696201, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2588, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.400923793103448 ], [ 29.77862594936709, -1.401193275862069 ], [ 29.778895443037975, -1.401193275862069 ], [ 29.778895443037975, -1.400923793103448 ], [ 29.77862594936709, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2589, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.400923793103448 ], [ 29.778895443037975, -1.401193275862069 ], [ 29.77916493670886, -1.401193275862069 ], [ 29.77916493670886, -1.400923793103448 ], [ 29.778895443037975, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2590, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.400923793103448 ], [ 29.77916493670886, -1.401193275862069 ], [ 29.779434430379748, -1.401193275862069 ], [ 29.779434430379748, -1.400923793103448 ], [ 29.77916493670886, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2591, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.400923793103448 ], [ 29.779434430379748, -1.401193275862069 ], [ 29.779703924050633, -1.401193275862069 ], [ 29.779703924050633, -1.400923793103448 ], [ 29.779434430379748, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2592, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.400923793103448 ], [ 29.779703924050633, -1.401193275862069 ], [ 29.779973417721518, -1.401193275862069 ], [ 29.779973417721518, -1.400923793103448 ], [ 29.779703924050633, -1.400923793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2593, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.401193275862069 ], [ 29.77377506329114, -1.40146275862069 ], [ 29.774044556962025, -1.40146275862069 ], [ 29.774044556962025, -1.401193275862069 ], [ 29.77377506329114, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2594, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.401193275862069 ], [ 29.774044556962025, -1.40146275862069 ], [ 29.77431405063291, -1.40146275862069 ], [ 29.77431405063291, -1.401193275862069 ], [ 29.774044556962025, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2595, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.401193275862069 ], [ 29.77431405063291, -1.40146275862069 ], [ 29.774853037974683, -1.40146275862069 ], [ 29.774853037974683, -1.401193275862069 ], [ 29.77431405063291, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2596, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.401193275862069 ], [ 29.774853037974683, -1.401732241379311 ], [ 29.775122531645568, -1.401732241379311 ], [ 29.775122531645568, -1.401193275862069 ], [ 29.774853037974683, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2597, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.401193275862069 ], [ 29.775122531645568, -1.40146275862069 ], [ 29.775392025316457, -1.40146275862069 ], [ 29.775392025316457, -1.401193275862069 ], [ 29.775122531645568, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2598, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.401193275862069 ], [ 29.775392025316457, -1.40146275862069 ], [ 29.775661518987341, -1.40146275862069 ], [ 29.775661518987341, -1.401193275862069 ], [ 29.775392025316457, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2599, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.401193275862069 ], [ 29.775661518987341, -1.40146275862069 ], [ 29.775931012658226, -1.40146275862069 ], [ 29.775931012658226, -1.401193275862069 ], [ 29.775661518987341, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2600, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.401193275862069 ], [ 29.775931012658226, -1.40146275862069 ], [ 29.776200506329115, -1.40146275862069 ], [ 29.776200506329115, -1.401193275862069 ], [ 29.775931012658226, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2601, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.401193275862069 ], [ 29.776200506329115, -1.40146275862069 ], [ 29.77647, -1.40146275862069 ], [ 29.77647, -1.401193275862069 ], [ 29.776200506329115, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2602, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.401193275862069 ], [ 29.77647, -1.40146275862069 ], [ 29.776739493670885, -1.40146275862069 ], [ 29.776739493670885, -1.401193275862069 ], [ 29.77647, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2603, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.401193275862069 ], [ 29.776739493670885, -1.40146275862069 ], [ 29.777008987341773, -1.40146275862069 ], [ 29.777008987341773, -1.401193275862069 ], [ 29.776739493670885, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2604, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.401193275862069 ], [ 29.777008987341773, -1.40146275862069 ], [ 29.777278481012658, -1.40146275862069 ], [ 29.777278481012658, -1.401193275862069 ], [ 29.777008987341773, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2605, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.401193275862069 ], [ 29.777278481012658, -1.40146275862069 ], [ 29.777547974683543, -1.40146275862069 ], [ 29.777547974683543, -1.401193275862069 ], [ 29.777278481012658, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2606, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.401193275862069 ], [ 29.777547974683543, -1.40146275862069 ], [ 29.777817468354431, -1.40146275862069 ], [ 29.777817468354431, -1.401193275862069 ], [ 29.777547974683543, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2607, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.401193275862069 ], [ 29.777817468354431, -1.40146275862069 ], [ 29.778086962025316, -1.40146275862069 ], [ 29.778086962025316, -1.401193275862069 ], [ 29.777817468354431, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2608, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.401193275862069 ], [ 29.778086962025316, -1.40146275862069 ], [ 29.778356455696201, -1.40146275862069 ], [ 29.778356455696201, -1.401193275862069 ], [ 29.778086962025316, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2609, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.401193275862069 ], [ 29.778356455696201, -1.40146275862069 ], [ 29.77862594936709, -1.40146275862069 ], [ 29.77862594936709, -1.401193275862069 ], [ 29.778356455696201, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2610, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.401193275862069 ], [ 29.77862594936709, -1.40146275862069 ], [ 29.778895443037975, -1.40146275862069 ], [ 29.778895443037975, -1.401193275862069 ], [ 29.77862594936709, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2611, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.401193275862069 ], [ 29.778895443037975, -1.40146275862069 ], [ 29.77916493670886, -1.40146275862069 ], [ 29.77916493670886, -1.401193275862069 ], [ 29.778895443037975, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2612, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.401193275862069 ], [ 29.77916493670886, -1.40146275862069 ], [ 29.779434430379748, -1.40146275862069 ], [ 29.779434430379748, -1.401193275862069 ], [ 29.77916493670886, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2613, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.401193275862069 ], [ 29.779434430379748, -1.40146275862069 ], [ 29.779703924050633, -1.40146275862069 ], [ 29.779703924050633, -1.401193275862069 ], [ 29.779434430379748, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2614, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.401193275862069 ], [ 29.779703924050633, -1.40146275862069 ], [ 29.779973417721518, -1.40146275862069 ], [ 29.779973417721518, -1.401193275862069 ], [ 29.779703924050633, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2615, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.401193275862069 ], [ 29.779973417721518, -1.40146275862069 ], [ 29.780242911392406, -1.40146275862069 ], [ 29.780242911392406, -1.401193275862069 ], [ 29.779973417721518, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2616, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.401193275862069 ], [ 29.780242911392406, -1.40146275862069 ], [ 29.780512405063291, -1.40146275862069 ], [ 29.780512405063291, -1.401193275862069 ], [ 29.780242911392406, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2617, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.401193275862069 ], [ 29.780512405063291, -1.40146275862069 ], [ 29.780781898734176, -1.40146275862069 ], [ 29.780781898734176, -1.401193275862069 ], [ 29.780512405063291, -1.401193275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2618, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.40146275862069 ], [ 29.766229240506327, -1.402271206896552 ], [ 29.766498734177215, -1.402271206896552 ], [ 29.766498734177215, -1.40146275862069 ], [ 29.766229240506327, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2619, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.40146275862069 ], [ 29.766498734177215, -1.401732241379311 ], [ 29.7667682278481, -1.401732241379311 ], [ 29.7667682278481, -1.40146275862069 ], [ 29.766498734177215, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2620, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.40146275862069 ], [ 29.7667682278481, -1.401732241379311 ], [ 29.767037721518985, -1.401732241379311 ], [ 29.767037721518985, -1.40146275862069 ], [ 29.7667682278481, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2621, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.40146275862069 ], [ 29.767037721518985, -1.401732241379311 ], [ 29.767307215189874, -1.401732241379311 ], [ 29.767307215189874, -1.40146275862069 ], [ 29.767037721518985, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2622, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.40146275862069 ], [ 29.767846202531643, -1.402001724137931 ], [ 29.768115696202532, -1.402001724137931 ], [ 29.768115696202532, -1.40146275862069 ], [ 29.767846202531643, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2623, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.40146275862069 ], [ 29.769193670886075, -1.402001724137931 ], [ 29.76946316455696, -1.402001724137931 ], [ 29.76946316455696, -1.40146275862069 ], [ 29.769193670886075, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2624, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.40146275862069 ], [ 29.773505569620252, -1.401732241379311 ], [ 29.77377506329114, -1.401732241379311 ], [ 29.77377506329114, -1.40146275862069 ], [ 29.773505569620252, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2625, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.40146275862069 ], [ 29.77377506329114, -1.402001724137931 ], [ 29.774044556962025, -1.402001724137931 ], [ 29.774044556962025, -1.40146275862069 ], [ 29.77377506329114, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2626, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.40146275862069 ], [ 29.774044556962025, -1.401732241379311 ], [ 29.77431405063291, -1.401732241379311 ], [ 29.77431405063291, -1.40146275862069 ], [ 29.774044556962025, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2627, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.40146275862069 ], [ 29.77431405063291, -1.401732241379311 ], [ 29.774853037974683, -1.401732241379311 ], [ 29.774853037974683, -1.40146275862069 ], [ 29.77431405063291, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2628, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.40146275862069 ], [ 29.775122531645568, -1.401732241379311 ], [ 29.775392025316457, -1.401732241379311 ], [ 29.775392025316457, -1.40146275862069 ], [ 29.775122531645568, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2629, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.40146275862069 ], [ 29.775392025316457, -1.401732241379311 ], [ 29.775661518987341, -1.401732241379311 ], [ 29.775661518987341, -1.40146275862069 ], [ 29.775392025316457, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2630, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.40146275862069 ], [ 29.775661518987341, -1.401732241379311 ], [ 29.775931012658226, -1.401732241379311 ], [ 29.775931012658226, -1.40146275862069 ], [ 29.775661518987341, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2631, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.40146275862069 ], [ 29.775931012658226, -1.402001724137931 ], [ 29.776200506329115, -1.402001724137931 ], [ 29.776200506329115, -1.40146275862069 ], [ 29.775931012658226, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2632, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.40146275862069 ], [ 29.776200506329115, -1.401732241379311 ], [ 29.77647, -1.401732241379311 ], [ 29.77647, -1.40146275862069 ], [ 29.776200506329115, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2633, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.40146275862069 ], [ 29.77647, -1.401732241379311 ], [ 29.776739493670885, -1.401732241379311 ], [ 29.776739493670885, -1.40146275862069 ], [ 29.77647, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2634, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.40146275862069 ], [ 29.776739493670885, -1.401732241379311 ], [ 29.777008987341773, -1.401732241379311 ], [ 29.777008987341773, -1.40146275862069 ], [ 29.776739493670885, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2635, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.40146275862069 ], [ 29.777008987341773, -1.401732241379311 ], [ 29.777278481012658, -1.401732241379311 ], [ 29.777278481012658, -1.40146275862069 ], [ 29.777008987341773, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2636, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.40146275862069 ], [ 29.777278481012658, -1.401732241379311 ], [ 29.777547974683543, -1.401732241379311 ], [ 29.777547974683543, -1.40146275862069 ], [ 29.777278481012658, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2637, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.40146275862069 ], [ 29.777547974683543, -1.401732241379311 ], [ 29.777817468354431, -1.401732241379311 ], [ 29.777817468354431, -1.40146275862069 ], [ 29.777547974683543, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2638, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.40146275862069 ], [ 29.777817468354431, -1.401732241379311 ], [ 29.778086962025316, -1.401732241379311 ], [ 29.778086962025316, -1.40146275862069 ], [ 29.777817468354431, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2639, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.40146275862069 ], [ 29.778086962025316, -1.401732241379311 ], [ 29.778356455696201, -1.401732241379311 ], [ 29.778356455696201, -1.40146275862069 ], [ 29.778086962025316, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2640, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.40146275862069 ], [ 29.778356455696201, -1.401732241379311 ], [ 29.77862594936709, -1.401732241379311 ], [ 29.77862594936709, -1.40146275862069 ], [ 29.778356455696201, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2641, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.40146275862069 ], [ 29.77862594936709, -1.401732241379311 ], [ 29.778895443037975, -1.401732241379311 ], [ 29.778895443037975, -1.40146275862069 ], [ 29.77862594936709, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2642, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.40146275862069 ], [ 29.778895443037975, -1.401732241379311 ], [ 29.77916493670886, -1.401732241379311 ], [ 29.77916493670886, -1.40146275862069 ], [ 29.778895443037975, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2643, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.40146275862069 ], [ 29.77916493670886, -1.401732241379311 ], [ 29.779434430379748, -1.401732241379311 ], [ 29.779434430379748, -1.40146275862069 ], [ 29.77916493670886, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2644, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.40146275862069 ], [ 29.779434430379748, -1.401732241379311 ], [ 29.779703924050633, -1.401732241379311 ], [ 29.779703924050633, -1.40146275862069 ], [ 29.779434430379748, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2645, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.40146275862069 ], [ 29.779703924050633, -1.401732241379311 ], [ 29.779973417721518, -1.401732241379311 ], [ 29.779973417721518, -1.40146275862069 ], [ 29.779703924050633, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2646, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.40146275862069 ], [ 29.779973417721518, -1.401732241379311 ], [ 29.780242911392406, -1.401732241379311 ], [ 29.780242911392406, -1.40146275862069 ], [ 29.779973417721518, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2647, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.40146275862069 ], [ 29.780242911392406, -1.401732241379311 ], [ 29.780512405063291, -1.401732241379311 ], [ 29.780512405063291, -1.40146275862069 ], [ 29.780242911392406, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2648, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.40146275862069 ], [ 29.780512405063291, -1.401732241379311 ], [ 29.780781898734176, -1.401732241379311 ], [ 29.780781898734176, -1.40146275862069 ], [ 29.780512405063291, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2649, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.40146275862069 ], [ 29.780781898734176, -1.401732241379311 ], [ 29.781051392405065, -1.401732241379311 ], [ 29.781051392405065, -1.40146275862069 ], [ 29.780781898734176, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2650, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.40146275862069 ], [ 29.781051392405065, -1.401732241379311 ], [ 29.78132088607595, -1.401732241379311 ], [ 29.78132088607595, -1.40146275862069 ], [ 29.781051392405065, -1.40146275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2651, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.401732241379311 ], [ 29.766498734177215, -1.402271206896552 ], [ 29.7667682278481, -1.402271206896552 ], [ 29.7667682278481, -1.401732241379311 ], [ 29.766498734177215, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2652, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.401732241379311 ], [ 29.7667682278481, -1.402271206896552 ], [ 29.767037721518985, -1.402271206896552 ], [ 29.767037721518985, -1.401732241379311 ], [ 29.7667682278481, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2653, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.401732241379311 ], [ 29.767037721518985, -1.402001724137931 ], [ 29.767307215189874, -1.402001724137931 ], [ 29.767307215189874, -1.401732241379311 ], [ 29.767037721518985, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2654, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.401732241379311 ], [ 29.767307215189874, -1.402001724137931 ], [ 29.767576708860759, -1.402001724137931 ], [ 29.767576708860759, -1.401732241379311 ], [ 29.767307215189874, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2655, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.401732241379311 ], [ 29.767576708860759, -1.402001724137931 ], [ 29.767846202531643, -1.402001724137931 ], [ 29.767846202531643, -1.401732241379311 ], [ 29.767576708860759, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2656, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.401732241379311 ], [ 29.768115696202532, -1.402001724137931 ], [ 29.768385189873417, -1.402001724137931 ], [ 29.768385189873417, -1.401732241379311 ], [ 29.768115696202532, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2657, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.401732241379311 ], [ 29.768385189873417, -1.402001724137931 ], [ 29.768654683544302, -1.402001724137931 ], [ 29.768654683544302, -1.401732241379311 ], [ 29.768385189873417, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2658, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.401732241379311 ], [ 29.76892417721519, -1.402271206896552 ], [ 29.769193670886075, -1.402271206896552 ], [ 29.769193670886075, -1.401732241379311 ], [ 29.76892417721519, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2659, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.401732241379311 ], [ 29.769732658227849, -1.402271206896552 ], [ 29.770002151898733, -1.402271206896552 ], [ 29.770002151898733, -1.401732241379311 ], [ 29.769732658227849, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2660, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.401732241379311 ], [ 29.770541139240507, -1.402271206896552 ], [ 29.770810632911392, -1.402271206896552 ], [ 29.770810632911392, -1.401732241379311 ], [ 29.770541139240507, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2661, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.401732241379311 ], [ 29.771080126582277, -1.402271206896552 ], [ 29.771349620253165, -1.402271206896552 ], [ 29.771349620253165, -1.401732241379311 ], [ 29.771080126582277, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2662, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.401732241379311 ], [ 29.771888607594935, -1.402271206896552 ], [ 29.772158101265823, -1.402271206896552 ], [ 29.772158101265823, -1.401732241379311 ], [ 29.771888607594935, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2663, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.401732241379311 ], [ 29.773236075949367, -1.402540689655172 ], [ 29.773505569620252, -1.402540689655172 ], [ 29.773505569620252, -1.402810172413793 ], [ 29.77377506329114, -1.402810172413793 ], [ 29.77377506329114, -1.402001724137931 ], [ 29.773505569620252, -1.402001724137931 ], [ 29.773505569620252, -1.401732241379311 ], [ 29.773236075949367, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2664, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.401732241379311 ], [ 29.773505569620252, -1.402001724137931 ], [ 29.77377506329114, -1.402001724137931 ], [ 29.77377506329114, -1.401732241379311 ], [ 29.773505569620252, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2665, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.401732241379311 ], [ 29.774044556962025, -1.402271206896552 ], [ 29.77431405063291, -1.402271206896552 ], [ 29.77431405063291, -1.401732241379311 ], [ 29.774044556962025, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2666, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.401732241379311 ], [ 29.77431405063291, -1.402001724137931 ], [ 29.774853037974683, -1.402001724137931 ], [ 29.774853037974683, -1.401732241379311 ], [ 29.77431405063291, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2667, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.401732241379311 ], [ 29.774853037974683, -1.402001724137931 ], [ 29.775122531645568, -1.402001724137931 ], [ 29.775122531645568, -1.401732241379311 ], [ 29.774853037974683, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2668, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.401732241379311 ], [ 29.775122531645568, -1.402271206896552 ], [ 29.775392025316457, -1.402271206896552 ], [ 29.775392025316457, -1.401732241379311 ], [ 29.775122531645568, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2669, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.401732241379311 ], [ 29.775392025316457, -1.402001724137931 ], [ 29.775661518987341, -1.402001724137931 ], [ 29.775661518987341, -1.401732241379311 ], [ 29.775392025316457, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2670, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.401732241379311 ], [ 29.775661518987341, -1.402001724137931 ], [ 29.775931012658226, -1.402001724137931 ], [ 29.775931012658226, -1.401732241379311 ], [ 29.775661518987341, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2671, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.401732241379311 ], [ 29.776200506329115, -1.402001724137931 ], [ 29.77647, -1.402001724137931 ], [ 29.77647, -1.401732241379311 ], [ 29.776200506329115, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2672, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.401732241379311 ], [ 29.77647, -1.402001724137931 ], [ 29.776739493670885, -1.402001724137931 ], [ 29.776739493670885, -1.401732241379311 ], [ 29.77647, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2673, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.401732241379311 ], [ 29.776739493670885, -1.402001724137931 ], [ 29.777008987341773, -1.402001724137931 ], [ 29.777008987341773, -1.401732241379311 ], [ 29.776739493670885, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2674, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.401732241379311 ], [ 29.777008987341773, -1.402001724137931 ], [ 29.777278481012658, -1.402001724137931 ], [ 29.777278481012658, -1.401732241379311 ], [ 29.777008987341773, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2675, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.401732241379311 ], [ 29.777278481012658, -1.402001724137931 ], [ 29.777547974683543, -1.402001724137931 ], [ 29.777547974683543, -1.401732241379311 ], [ 29.777278481012658, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2676, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.401732241379311 ], [ 29.777547974683543, -1.402001724137931 ], [ 29.777817468354431, -1.402001724137931 ], [ 29.777817468354431, -1.401732241379311 ], [ 29.777547974683543, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2677, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.401732241379311 ], [ 29.777817468354431, -1.402001724137931 ], [ 29.778086962025316, -1.402001724137931 ], [ 29.778086962025316, -1.401732241379311 ], [ 29.777817468354431, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2678, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.401732241379311 ], [ 29.778086962025316, -1.402001724137931 ], [ 29.778356455696201, -1.402001724137931 ], [ 29.778356455696201, -1.401732241379311 ], [ 29.778086962025316, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2679, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.401732241379311 ], [ 29.778356455696201, -1.402001724137931 ], [ 29.77862594936709, -1.402001724137931 ], [ 29.77862594936709, -1.401732241379311 ], [ 29.778356455696201, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2680, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.401732241379311 ], [ 29.77862594936709, -1.402001724137931 ], [ 29.778895443037975, -1.402001724137931 ], [ 29.778895443037975, -1.401732241379311 ], [ 29.77862594936709, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2681, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.401732241379311 ], [ 29.778895443037975, -1.402001724137931 ], [ 29.77916493670886, -1.402001724137931 ], [ 29.77916493670886, -1.401732241379311 ], [ 29.778895443037975, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2682, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.401732241379311 ], [ 29.77916493670886, -1.402001724137931 ], [ 29.779434430379748, -1.402001724137931 ], [ 29.779434430379748, -1.401732241379311 ], [ 29.77916493670886, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2683, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.401732241379311 ], [ 29.779434430379748, -1.402001724137931 ], [ 29.779703924050633, -1.402001724137931 ], [ 29.779703924050633, -1.401732241379311 ], [ 29.779434430379748, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2684, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.401732241379311 ], [ 29.779703924050633, -1.402001724137931 ], [ 29.779973417721518, -1.402001724137931 ], [ 29.779973417721518, -1.401732241379311 ], [ 29.779703924050633, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2685, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.401732241379311 ], [ 29.779973417721518, -1.402001724137931 ], [ 29.780242911392406, -1.402001724137931 ], [ 29.780242911392406, -1.401732241379311 ], [ 29.779973417721518, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2686, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.401732241379311 ], [ 29.780242911392406, -1.402001724137931 ], [ 29.780512405063291, -1.402001724137931 ], [ 29.780512405063291, -1.401732241379311 ], [ 29.780242911392406, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2687, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.401732241379311 ], [ 29.780512405063291, -1.402001724137931 ], [ 29.780781898734176, -1.402001724137931 ], [ 29.780781898734176, -1.401732241379311 ], [ 29.780512405063291, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2688, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.401732241379311 ], [ 29.780781898734176, -1.402001724137931 ], [ 29.781051392405065, -1.402001724137931 ], [ 29.781051392405065, -1.401732241379311 ], [ 29.780781898734176, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2689, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.401732241379311 ], [ 29.781051392405065, -1.402001724137931 ], [ 29.78132088607595, -1.402001724137931 ], [ 29.78132088607595, -1.401732241379311 ], [ 29.781051392405065, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2690, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.401732241379311 ], [ 29.78132088607595, -1.402001724137931 ], [ 29.781590379746834, -1.402001724137931 ], [ 29.781590379746834, -1.401732241379311 ], [ 29.78132088607595, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2691, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.401732241379311 ], [ 29.781590379746834, -1.402001724137931 ], [ 29.781859873417723, -1.402001724137931 ], [ 29.781859873417723, -1.401732241379311 ], [ 29.781590379746834, -1.401732241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2692, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.402001724137931 ], [ 29.767037721518985, -1.402540689655172 ], [ 29.767307215189874, -1.402540689655172 ], [ 29.767307215189874, -1.402001724137931 ], [ 29.767037721518985, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2693, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.402001724137931 ], [ 29.767307215189874, -1.402540689655172 ], [ 29.767576708860759, -1.402540689655172 ], [ 29.767576708860759, -1.402001724137931 ], [ 29.767307215189874, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2694, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.402001724137931 ], [ 29.767576708860759, -1.402271206896552 ], [ 29.767846202531643, -1.402271206896552 ], [ 29.767846202531643, -1.402001724137931 ], [ 29.767576708860759, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2695, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.402001724137931 ], [ 29.767846202531643, -1.402271206896552 ], [ 29.768115696202532, -1.402271206896552 ], [ 29.768115696202532, -1.402001724137931 ], [ 29.767846202531643, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2696, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.402001724137931 ], [ 29.768115696202532, -1.402271206896552 ], [ 29.768385189873417, -1.402271206896552 ], [ 29.768385189873417, -1.402001724137931 ], [ 29.768115696202532, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2697, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.402001724137931 ], [ 29.768385189873417, -1.402271206896552 ], [ 29.768654683544302, -1.402271206896552 ], [ 29.768654683544302, -1.402001724137931 ], [ 29.768385189873417, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2698, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.402001724137931 ], [ 29.768654683544302, -1.402271206896552 ], [ 29.76892417721519, -1.402271206896552 ], [ 29.76892417721519, -1.402001724137931 ], [ 29.768654683544302, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2699, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.402001724137931 ], [ 29.769193670886075, -1.402271206896552 ], [ 29.76946316455696, -1.402271206896552 ], [ 29.76946316455696, -1.402001724137931 ], [ 29.769193670886075, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2700, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.402001724137931 ], [ 29.76946316455696, -1.402271206896552 ], [ 29.769732658227849, -1.402271206896552 ], [ 29.769732658227849, -1.402001724137931 ], [ 29.76946316455696, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2701, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.402001724137931 ], [ 29.770002151898733, -1.402540689655172 ], [ 29.770271645569618, -1.402540689655172 ], [ 29.770271645569618, -1.402001724137931 ], [ 29.770002151898733, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2702, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.402001724137931 ], [ 29.770271645569618, -1.402271206896552 ], [ 29.770541139240507, -1.402271206896552 ], [ 29.770541139240507, -1.402001724137931 ], [ 29.770271645569618, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2703, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.402001724137931 ], [ 29.770810632911392, -1.402540689655172 ], [ 29.771080126582277, -1.402540689655172 ], [ 29.771080126582277, -1.402001724137931 ], [ 29.770810632911392, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2704, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.402001724137931 ], [ 29.77161911392405, -1.402540689655172 ], [ 29.771888607594935, -1.402540689655172 ], [ 29.771888607594935, -1.402001724137931 ], [ 29.77161911392405, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2705, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.402001724137931 ], [ 29.772427594936708, -1.402540689655172 ], [ 29.772697088607593, -1.402540689655172 ], [ 29.772697088607593, -1.402001724137931 ], [ 29.772427594936708, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2706, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.402001724137931 ], [ 29.772966582278482, -1.402540689655172 ], [ 29.773236075949367, -1.402540689655172 ], [ 29.773236075949367, -1.402001724137931 ], [ 29.772966582278482, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2707, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.402001724137931 ], [ 29.77377506329114, -1.402540689655172 ], [ 29.774044556962025, -1.402540689655172 ], [ 29.774044556962025, -1.402001724137931 ], [ 29.77377506329114, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2708, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.402001724137931 ], [ 29.77431405063291, -1.402540689655172 ], [ 29.774853037974683, -1.402540689655172 ], [ 29.774853037974683, -1.402001724137931 ], [ 29.77431405063291, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2709, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.402001724137931 ], [ 29.774853037974683, -1.402271206896552 ], [ 29.775122531645568, -1.402271206896552 ], [ 29.775122531645568, -1.402001724137931 ], [ 29.774853037974683, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2710, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.402001724137931 ], [ 29.775392025316457, -1.402540689655172 ], [ 29.775661518987341, -1.402540689655172 ], [ 29.775661518987341, -1.402001724137931 ], [ 29.775392025316457, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2711, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.402001724137931 ], [ 29.775661518987341, -1.402271206896552 ], [ 29.775931012658226, -1.402271206896552 ], [ 29.775931012658226, -1.402001724137931 ], [ 29.775661518987341, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2712, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.402001724137931 ], [ 29.775931012658226, -1.402271206896552 ], [ 29.776200506329115, -1.402271206896552 ], [ 29.776200506329115, -1.402001724137931 ], [ 29.775931012658226, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2713, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.402001724137931 ], [ 29.776200506329115, -1.402540689655172 ], [ 29.77647, -1.402540689655172 ], [ 29.77647, -1.402001724137931 ], [ 29.776200506329115, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2714, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.402001724137931 ], [ 29.77647, -1.402271206896552 ], [ 29.776739493670885, -1.402271206896552 ], [ 29.776739493670885, -1.402001724137931 ], [ 29.77647, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2715, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.402001724137931 ], [ 29.776739493670885, -1.402271206896552 ], [ 29.777008987341773, -1.402271206896552 ], [ 29.777008987341773, -1.402001724137931 ], [ 29.776739493670885, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2716, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.402001724137931 ], [ 29.777008987341773, -1.402540689655172 ], [ 29.777278481012658, -1.402540689655172 ], [ 29.777278481012658, -1.402001724137931 ], [ 29.777008987341773, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2717, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.402001724137931 ], [ 29.777278481012658, -1.402271206896552 ], [ 29.777547974683543, -1.402271206896552 ], [ 29.777547974683543, -1.402001724137931 ], [ 29.777278481012658, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2718, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.402001724137931 ], [ 29.777547974683543, -1.402271206896552 ], [ 29.777817468354431, -1.402271206896552 ], [ 29.777817468354431, -1.402001724137931 ], [ 29.777547974683543, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2719, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.402001724137931 ], [ 29.777817468354431, -1.402271206896552 ], [ 29.778086962025316, -1.402271206896552 ], [ 29.778086962025316, -1.402001724137931 ], [ 29.777817468354431, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2720, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.402001724137931 ], [ 29.778086962025316, -1.402271206896552 ], [ 29.778356455696201, -1.402271206896552 ], [ 29.778356455696201, -1.402001724137931 ], [ 29.778086962025316, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2721, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.402001724137931 ], [ 29.778356455696201, -1.402271206896552 ], [ 29.77862594936709, -1.402271206896552 ], [ 29.77862594936709, -1.402001724137931 ], [ 29.778356455696201, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2722, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.402001724137931 ], [ 29.77862594936709, -1.402271206896552 ], [ 29.778895443037975, -1.402271206896552 ], [ 29.778895443037975, -1.402001724137931 ], [ 29.77862594936709, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2723, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.402001724137931 ], [ 29.778895443037975, -1.402271206896552 ], [ 29.77916493670886, -1.402271206896552 ], [ 29.77916493670886, -1.402001724137931 ], [ 29.778895443037975, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2724, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.402001724137931 ], [ 29.77916493670886, -1.402271206896552 ], [ 29.779434430379748, -1.402271206896552 ], [ 29.779434430379748, -1.402001724137931 ], [ 29.77916493670886, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2725, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.402001724137931 ], [ 29.779434430379748, -1.402271206896552 ], [ 29.779703924050633, -1.402271206896552 ], [ 29.779703924050633, -1.402001724137931 ], [ 29.779434430379748, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2726, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.402001724137931 ], [ 29.779703924050633, -1.402271206896552 ], [ 29.779973417721518, -1.402271206896552 ], [ 29.779973417721518, -1.402001724137931 ], [ 29.779703924050633, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2727, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.402001724137931 ], [ 29.779973417721518, -1.402271206896552 ], [ 29.780242911392406, -1.402271206896552 ], [ 29.780242911392406, -1.402001724137931 ], [ 29.779973417721518, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2728, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.402001724137931 ], [ 29.780242911392406, -1.402271206896552 ], [ 29.780512405063291, -1.402271206896552 ], [ 29.780512405063291, -1.402001724137931 ], [ 29.780242911392406, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2729, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.402001724137931 ], [ 29.780512405063291, -1.402271206896552 ], [ 29.780781898734176, -1.402271206896552 ], [ 29.780781898734176, -1.402001724137931 ], [ 29.780512405063291, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2730, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.402001724137931 ], [ 29.780781898734176, -1.402271206896552 ], [ 29.781051392405065, -1.402271206896552 ], [ 29.781051392405065, -1.402001724137931 ], [ 29.780781898734176, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2731, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.402001724137931 ], [ 29.781051392405065, -1.402271206896552 ], [ 29.78132088607595, -1.402271206896552 ], [ 29.78132088607595, -1.402001724137931 ], [ 29.781051392405065, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2732, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.402001724137931 ], [ 29.78132088607595, -1.402271206896552 ], [ 29.781590379746834, -1.402271206896552 ], [ 29.781590379746834, -1.402001724137931 ], [ 29.78132088607595, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2733, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.402001724137931 ], [ 29.781590379746834, -1.402271206896552 ], [ 29.781859873417723, -1.402271206896552 ], [ 29.781859873417723, -1.402001724137931 ], [ 29.781590379746834, -1.402001724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2734, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.402271206896552 ], [ 29.765959746835442, -1.402810172413793 ], [ 29.766229240506327, -1.402810172413793 ], [ 29.766229240506327, -1.402271206896552 ], [ 29.765959746835442, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2735, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.402271206896552 ], [ 29.766229240506327, -1.402540689655172 ], [ 29.766498734177215, -1.402540689655172 ], [ 29.766498734177215, -1.402271206896552 ], [ 29.766229240506327, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2736, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.402271206896552 ], [ 29.766498734177215, -1.402540689655172 ], [ 29.7667682278481, -1.402540689655172 ], [ 29.7667682278481, -1.402271206896552 ], [ 29.766498734177215, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2737, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.402271206896552 ], [ 29.7667682278481, -1.402540689655172 ], [ 29.767037721518985, -1.402540689655172 ], [ 29.767037721518985, -1.402271206896552 ], [ 29.7667682278481, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2738, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.402271206896552 ], [ 29.767576708860759, -1.402810172413793 ], [ 29.767846202531643, -1.402810172413793 ], [ 29.767846202531643, -1.402271206896552 ], [ 29.767576708860759, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2739, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.402271206896552 ], [ 29.767846202531643, -1.402540689655172 ], [ 29.768115696202532, -1.402540689655172 ], [ 29.768115696202532, -1.402271206896552 ], [ 29.767846202531643, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2740, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.402271206896552 ], [ 29.768115696202532, -1.402540689655172 ], [ 29.768385189873417, -1.402540689655172 ], [ 29.768385189873417, -1.402271206896552 ], [ 29.768115696202532, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2741, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.402271206896552 ], [ 29.768385189873417, -1.402540689655172 ], [ 29.768654683544302, -1.402540689655172 ], [ 29.768654683544302, -1.402271206896552 ], [ 29.768385189873417, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2742, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.402271206896552 ], [ 29.768654683544302, -1.402540689655172 ], [ 29.76892417721519, -1.402540689655172 ], [ 29.76892417721519, -1.402271206896552 ], [ 29.768654683544302, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2743, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.402271206896552 ], [ 29.76892417721519, -1.402540689655172 ], [ 29.769193670886075, -1.402540689655172 ], [ 29.769193670886075, -1.402271206896552 ], [ 29.76892417721519, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2744, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.402271206896552 ], [ 29.769193670886075, -1.402540689655172 ], [ 29.76946316455696, -1.402540689655172 ], [ 29.76946316455696, -1.402271206896552 ], [ 29.769193670886075, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2745, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.402271206896552 ], [ 29.76946316455696, -1.402540689655172 ], [ 29.769732658227849, -1.402540689655172 ], [ 29.769732658227849, -1.402271206896552 ], [ 29.76946316455696, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2746, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.402271206896552 ], [ 29.769732658227849, -1.402540689655172 ], [ 29.770002151898733, -1.402540689655172 ], [ 29.770002151898733, -1.402271206896552 ], [ 29.769732658227849, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2747, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.402271206896552 ], [ 29.770271645569618, -1.402540689655172 ], [ 29.770541139240507, -1.402540689655172 ], [ 29.770541139240507, -1.402271206896552 ], [ 29.770271645569618, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2748, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.402271206896552 ], [ 29.770541139240507, -1.402540689655172 ], [ 29.770810632911392, -1.402540689655172 ], [ 29.770810632911392, -1.402271206896552 ], [ 29.770541139240507, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2749, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.402271206896552 ], [ 29.771080126582277, -1.402540689655172 ], [ 29.771349620253165, -1.402540689655172 ], [ 29.771349620253165, -1.402271206896552 ], [ 29.771080126582277, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2750, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.402271206896552 ], [ 29.771349620253165, -1.402540689655172 ], [ 29.77161911392405, -1.402540689655172 ], [ 29.77161911392405, -1.402271206896552 ], [ 29.771349620253165, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2751, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.402271206896552 ], [ 29.771888607594935, -1.402810172413793 ], [ 29.772158101265823, -1.402810172413793 ], [ 29.772158101265823, -1.402271206896552 ], [ 29.771888607594935, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2752, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.402271206896552 ], [ 29.772158101265823, -1.402540689655172 ], [ 29.772427594936708, -1.402540689655172 ], [ 29.772427594936708, -1.402271206896552 ], [ 29.772158101265823, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2753, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.402271206896552 ], [ 29.772697088607593, -1.402810172413793 ], [ 29.772966582278482, -1.402810172413793 ], [ 29.772966582278482, -1.402271206896552 ], [ 29.772697088607593, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2754, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.402271206896552 ], [ 29.775122531645568, -1.402810172413793 ], [ 29.775392025316457, -1.402810172413793 ], [ 29.775392025316457, -1.402271206896552 ], [ 29.775122531645568, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2755, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.402271206896552 ], [ 29.775931012658226, -1.402810172413793 ], [ 29.776200506329115, -1.402810172413793 ], [ 29.776200506329115, -1.402271206896552 ], [ 29.775931012658226, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2756, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.402271206896552 ], [ 29.77647, -1.402810172413793 ], [ 29.776739493670885, -1.402810172413793 ], [ 29.776739493670885, -1.402271206896552 ], [ 29.77647, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2757, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.402271206896552 ], [ 29.776739493670885, -1.402540689655172 ], [ 29.777008987341773, -1.402540689655172 ], [ 29.777008987341773, -1.402271206896552 ], [ 29.776739493670885, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2758, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.402271206896552 ], [ 29.777278481012658, -1.402810172413793 ], [ 29.777547974683543, -1.402810172413793 ], [ 29.777547974683543, -1.402271206896552 ], [ 29.777278481012658, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2759, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.402271206896552 ], [ 29.777547974683543, -1.402540689655172 ], [ 29.777817468354431, -1.402540689655172 ], [ 29.777817468354431, -1.402271206896552 ], [ 29.777547974683543, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2760, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.402271206896552 ], [ 29.777817468354431, -1.402540689655172 ], [ 29.778086962025316, -1.402540689655172 ], [ 29.778086962025316, -1.402271206896552 ], [ 29.777817468354431, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2761, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.402271206896552 ], [ 29.778086962025316, -1.402810172413793 ], [ 29.778356455696201, -1.402810172413793 ], [ 29.778356455696201, -1.402271206896552 ], [ 29.778086962025316, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2762, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.402271206896552 ], [ 29.778356455696201, -1.402540689655172 ], [ 29.77862594936709, -1.402540689655172 ], [ 29.77862594936709, -1.402271206896552 ], [ 29.778356455696201, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2763, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.402271206896552 ], [ 29.77862594936709, -1.402540689655172 ], [ 29.778895443037975, -1.402540689655172 ], [ 29.778895443037975, -1.402271206896552 ], [ 29.77862594936709, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2764, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.402271206896552 ], [ 29.778895443037975, -1.402540689655172 ], [ 29.77916493670886, -1.402540689655172 ], [ 29.77916493670886, -1.402271206896552 ], [ 29.778895443037975, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2765, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.402271206896552 ], [ 29.77916493670886, -1.402540689655172 ], [ 29.779434430379748, -1.402540689655172 ], [ 29.779434430379748, -1.402271206896552 ], [ 29.77916493670886, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2766, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.402271206896552 ], [ 29.779434430379748, -1.402540689655172 ], [ 29.779703924050633, -1.402540689655172 ], [ 29.779703924050633, -1.402271206896552 ], [ 29.779434430379748, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2767, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.402271206896552 ], [ 29.779703924050633, -1.402540689655172 ], [ 29.779973417721518, -1.402540689655172 ], [ 29.779973417721518, -1.402271206896552 ], [ 29.779703924050633, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2768, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.402271206896552 ], [ 29.779973417721518, -1.402540689655172 ], [ 29.780242911392406, -1.402540689655172 ], [ 29.780242911392406, -1.402271206896552 ], [ 29.779973417721518, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2769, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.402271206896552 ], [ 29.780242911392406, -1.402540689655172 ], [ 29.780512405063291, -1.402540689655172 ], [ 29.780512405063291, -1.402271206896552 ], [ 29.780242911392406, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2770, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.402271206896552 ], [ 29.780512405063291, -1.402540689655172 ], [ 29.780781898734176, -1.402540689655172 ], [ 29.780781898734176, -1.402271206896552 ], [ 29.780512405063291, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2771, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.402271206896552 ], [ 29.780781898734176, -1.402540689655172 ], [ 29.781051392405065, -1.402540689655172 ], [ 29.781051392405065, -1.402271206896552 ], [ 29.780781898734176, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2772, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.402271206896552 ], [ 29.781051392405065, -1.402540689655172 ], [ 29.78132088607595, -1.402540689655172 ], [ 29.78132088607595, -1.402271206896552 ], [ 29.781051392405065, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2773, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.402271206896552 ], [ 29.78132088607595, -1.402540689655172 ], [ 29.781590379746834, -1.402540689655172 ], [ 29.781590379746834, -1.402271206896552 ], [ 29.78132088607595, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2774, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.402540689655172 ], [ 29.766229240506327, -1.402810172413793 ], [ 29.766498734177215, -1.402810172413793 ], [ 29.766498734177215, -1.402540689655172 ], [ 29.766229240506327, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2775, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.402540689655172 ], [ 29.766498734177215, -1.402810172413793 ], [ 29.7667682278481, -1.402810172413793 ], [ 29.7667682278481, -1.402540689655172 ], [ 29.766498734177215, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2776, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.402540689655172 ], [ 29.7667682278481, -1.402810172413793 ], [ 29.767037721518985, -1.402810172413793 ], [ 29.767037721518985, -1.402540689655172 ], [ 29.7667682278481, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2777, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.402540689655172 ], [ 29.767037721518985, -1.402810172413793 ], [ 29.767307215189874, -1.402810172413793 ], [ 29.767307215189874, -1.402540689655172 ], [ 29.767037721518985, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2778, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.402540689655172 ], [ 29.767307215189874, -1.402810172413793 ], [ 29.767576708860759, -1.402810172413793 ], [ 29.767576708860759, -1.402540689655172 ], [ 29.767307215189874, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2779, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.402540689655172 ], [ 29.767846202531643, -1.402810172413793 ], [ 29.768115696202532, -1.402810172413793 ], [ 29.768115696202532, -1.402540689655172 ], [ 29.767846202531643, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2780, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.402540689655172 ], [ 29.768115696202532, -1.402810172413793 ], [ 29.768385189873417, -1.402810172413793 ], [ 29.768385189873417, -1.402540689655172 ], [ 29.768115696202532, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2781, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.402540689655172 ], [ 29.768385189873417, -1.402810172413793 ], [ 29.768654683544302, -1.402810172413793 ], [ 29.768654683544302, -1.402540689655172 ], [ 29.768385189873417, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2782, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.402540689655172 ], [ 29.76892417721519, -1.402810172413793 ], [ 29.769193670886075, -1.402810172413793 ], [ 29.769193670886075, -1.402540689655172 ], [ 29.76892417721519, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2783, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.402540689655172 ], [ 29.769193670886075, -1.402810172413793 ], [ 29.76946316455696, -1.402810172413793 ], [ 29.76946316455696, -1.402540689655172 ], [ 29.769193670886075, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2784, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.402540689655172 ], [ 29.76946316455696, -1.402810172413793 ], [ 29.769732658227849, -1.402810172413793 ], [ 29.769732658227849, -1.402540689655172 ], [ 29.76946316455696, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2785, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.402540689655172 ], [ 29.769732658227849, -1.402810172413793 ], [ 29.770002151898733, -1.402810172413793 ], [ 29.770002151898733, -1.402540689655172 ], [ 29.769732658227849, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2786, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.402540689655172 ], [ 29.770002151898733, -1.402810172413793 ], [ 29.770271645569618, -1.402810172413793 ], [ 29.770271645569618, -1.402540689655172 ], [ 29.770002151898733, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2787, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.402540689655172 ], [ 29.770271645569618, -1.402810172413793 ], [ 29.770541139240507, -1.402810172413793 ], [ 29.770541139240507, -1.402540689655172 ], [ 29.770271645569618, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2788, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.402540689655172 ], [ 29.770541139240507, -1.402810172413793 ], [ 29.770810632911392, -1.402810172413793 ], [ 29.770810632911392, -1.402540689655172 ], [ 29.770541139240507, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2789, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.402540689655172 ], [ 29.770810632911392, -1.402810172413793 ], [ 29.771080126582277, -1.402810172413793 ], [ 29.771080126582277, -1.402540689655172 ], [ 29.770810632911392, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2790, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.402540689655172 ], [ 29.771349620253165, -1.402810172413793 ], [ 29.77161911392405, -1.402810172413793 ], [ 29.77161911392405, -1.402540689655172 ], [ 29.771349620253165, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2791, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.402540689655172 ], [ 29.77161911392405, -1.402810172413793 ], [ 29.771888607594935, -1.402810172413793 ], [ 29.771888607594935, -1.402540689655172 ], [ 29.77161911392405, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2792, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.402540689655172 ], [ 29.772158101265823, -1.402810172413793 ], [ 29.772427594936708, -1.402810172413793 ], [ 29.772427594936708, -1.402540689655172 ], [ 29.772158101265823, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2793, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.402540689655172 ], [ 29.772427594936708, -1.402810172413793 ], [ 29.772697088607593, -1.402810172413793 ], [ 29.772697088607593, -1.402540689655172 ], [ 29.772427594936708, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2794, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.402540689655172 ], [ 29.773236075949367, -1.402810172413793 ], [ 29.773505569620252, -1.402810172413793 ], [ 29.773505569620252, -1.402540689655172 ], [ 29.773236075949367, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2795, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.402540689655172 ], [ 29.77862594936709, -1.402810172413793 ], [ 29.778895443037975, -1.402810172413793 ], [ 29.778895443037975, -1.402540689655172 ], [ 29.77862594936709, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2796, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.402540689655172 ], [ 29.778895443037975, -1.402810172413793 ], [ 29.77916493670886, -1.402810172413793 ], [ 29.77916493670886, -1.402540689655172 ], [ 29.778895443037975, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2797, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.402540689655172 ], [ 29.779434430379748, -1.402810172413793 ], [ 29.779703924050633, -1.402810172413793 ], [ 29.779703924050633, -1.402540689655172 ], [ 29.779434430379748, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2798, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.402540689655172 ], [ 29.779703924050633, -1.402810172413793 ], [ 29.779973417721518, -1.402810172413793 ], [ 29.779973417721518, -1.402540689655172 ], [ 29.779703924050633, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2799, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.402540689655172 ], [ 29.779973417721518, -1.402810172413793 ], [ 29.780242911392406, -1.402810172413793 ], [ 29.780242911392406, -1.402540689655172 ], [ 29.779973417721518, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2800, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.402540689655172 ], [ 29.780242911392406, -1.402810172413793 ], [ 29.780512405063291, -1.402810172413793 ], [ 29.780512405063291, -1.402540689655172 ], [ 29.780242911392406, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2801, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.402540689655172 ], [ 29.780512405063291, -1.402810172413793 ], [ 29.780781898734176, -1.402810172413793 ], [ 29.780781898734176, -1.402540689655172 ], [ 29.780512405063291, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2802, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.402540689655172 ], [ 29.780781898734176, -1.402810172413793 ], [ 29.781051392405065, -1.402810172413793 ], [ 29.781051392405065, -1.402540689655172 ], [ 29.780781898734176, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2803, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.402540689655172 ], [ 29.781051392405065, -1.402810172413793 ], [ 29.78132088607595, -1.402810172413793 ], [ 29.78132088607595, -1.402540689655172 ], [ 29.781051392405065, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2804, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.402271206896552 ], [ 29.774044556962025, -1.404157586206897 ], [ 29.77431405063291, -1.404157586206897 ], [ 29.77431405063291, -1.402271206896552 ], [ 29.774044556962025, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2805, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.402271206896552 ], [ 29.774853037974683, -1.404157586206897 ], [ 29.775122531645568, -1.404157586206897 ], [ 29.775122531645568, -1.402271206896552 ], [ 29.774853037974683, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2806, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.402271206896552 ], [ 29.775661518987341, -1.404157586206897 ], [ 29.775931012658226, -1.404157586206897 ], [ 29.775931012658226, -1.402271206896552 ], [ 29.775661518987341, -1.402271206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2807, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.402540689655172 ], [ 29.768654683544302, -1.403079655172414 ], [ 29.76892417721519, -1.403079655172414 ], [ 29.76892417721519, -1.402540689655172 ], [ 29.768654683544302, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2808, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.402540689655172 ], [ 29.771080126582277, -1.403079655172414 ], [ 29.771349620253165, -1.403079655172414 ], [ 29.771349620253165, -1.402540689655172 ], [ 29.771080126582277, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2809, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.402540689655172 ], [ 29.772966582278482, -1.403079655172414 ], [ 29.773236075949367, -1.403079655172414 ], [ 29.773236075949367, -1.402540689655172 ], [ 29.772966582278482, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2810, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.402540689655172 ], [ 29.77377506329114, -1.403888103448276 ], [ 29.774044556962025, -1.403888103448276 ], [ 29.774044556962025, -1.402540689655172 ], [ 29.77377506329114, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2811, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.402540689655172 ], [ 29.77431405063291, -1.403888103448276 ], [ 29.774853037974683, -1.403888103448276 ], [ 29.774853037974683, -1.402540689655172 ], [ 29.77431405063291, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2812, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.402540689655172 ], [ 29.775392025316457, -1.403888103448276 ], [ 29.775661518987341, -1.403888103448276 ], [ 29.775661518987341, -1.402540689655172 ], [ 29.775392025316457, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2813, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.402540689655172 ], [ 29.776200506329115, -1.403888103448276 ], [ 29.77647, -1.403888103448276 ], [ 29.77647, -1.402540689655172 ], [ 29.776200506329115, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2814, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.402540689655172 ], [ 29.776739493670885, -1.403888103448276 ], [ 29.777008987341773, -1.403888103448276 ], [ 29.777008987341773, -1.402540689655172 ], [ 29.776739493670885, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2815, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.402540689655172 ], [ 29.777008987341773, -1.403888103448276 ], [ 29.777278481012658, -1.403888103448276 ], [ 29.777278481012658, -1.402540689655172 ], [ 29.777008987341773, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2816, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.402540689655172 ], [ 29.777547974683543, -1.403888103448276 ], [ 29.777817468354431, -1.403888103448276 ], [ 29.777817468354431, -1.402540689655172 ], [ 29.777547974683543, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2817, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.402540689655172 ], [ 29.777817468354431, -1.403079655172414 ], [ 29.778086962025316, -1.403079655172414 ], [ 29.778086962025316, -1.402540689655172 ], [ 29.777817468354431, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2818, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.402540689655172 ], [ 29.778356455696201, -1.403888103448276 ], [ 29.77862594936709, -1.403888103448276 ], [ 29.77862594936709, -1.402540689655172 ], [ 29.778356455696201, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2819, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.402540689655172 ], [ 29.77916493670886, -1.403079655172414 ], [ 29.779434430379748, -1.403079655172414 ], [ 29.779434430379748, -1.402540689655172 ], [ 29.77916493670886, -1.402540689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2820, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.402810172413793 ], [ 29.765420759493672, -1.403079655172414 ], [ 29.765959746835442, -1.403079655172414 ], [ 29.765959746835442, -1.402810172413793 ], [ 29.765420759493672, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2821, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.402810172413793 ], [ 29.765959746835442, -1.403079655172414 ], [ 29.766229240506327, -1.403079655172414 ], [ 29.766229240506327, -1.402810172413793 ], [ 29.765959746835442, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2822, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.402810172413793 ], [ 29.766229240506327, -1.403079655172414 ], [ 29.766498734177215, -1.403079655172414 ], [ 29.766498734177215, -1.402810172413793 ], [ 29.766229240506327, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2823, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.402810172413793 ], [ 29.766498734177215, -1.403079655172414 ], [ 29.7667682278481, -1.403079655172414 ], [ 29.7667682278481, -1.402810172413793 ], [ 29.766498734177215, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2824, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.402810172413793 ], [ 29.7667682278481, -1.403079655172414 ], [ 29.767037721518985, -1.403079655172414 ], [ 29.767037721518985, -1.402810172413793 ], [ 29.7667682278481, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2825, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.402810172413793 ], [ 29.767037721518985, -1.403079655172414 ], [ 29.767307215189874, -1.403079655172414 ], [ 29.767307215189874, -1.402810172413793 ], [ 29.767037721518985, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2826, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.402810172413793 ], [ 29.767307215189874, -1.403079655172414 ], [ 29.767576708860759, -1.403079655172414 ], [ 29.767576708860759, -1.402810172413793 ], [ 29.767307215189874, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2827, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.402810172413793 ], [ 29.767576708860759, -1.403079655172414 ], [ 29.767846202531643, -1.403079655172414 ], [ 29.767846202531643, -1.402810172413793 ], [ 29.767576708860759, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2828, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.402810172413793 ], [ 29.767846202531643, -1.403079655172414 ], [ 29.768115696202532, -1.403079655172414 ], [ 29.768115696202532, -1.402810172413793 ], [ 29.767846202531643, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2829, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.402810172413793 ], [ 29.768115696202532, -1.403079655172414 ], [ 29.768385189873417, -1.403079655172414 ], [ 29.768385189873417, -1.402810172413793 ], [ 29.768115696202532, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2830, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.402810172413793 ], [ 29.768385189873417, -1.403079655172414 ], [ 29.768654683544302, -1.403079655172414 ], [ 29.768654683544302, -1.402810172413793 ], [ 29.768385189873417, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2831, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.402810172413793 ], [ 29.76892417721519, -1.403079655172414 ], [ 29.769193670886075, -1.403079655172414 ], [ 29.769193670886075, -1.402810172413793 ], [ 29.76892417721519, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2832, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.402810172413793 ], [ 29.769193670886075, -1.403079655172414 ], [ 29.76946316455696, -1.403079655172414 ], [ 29.76946316455696, -1.402810172413793 ], [ 29.769193670886075, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2833, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.402810172413793 ], [ 29.76946316455696, -1.403079655172414 ], [ 29.769732658227849, -1.403079655172414 ], [ 29.769732658227849, -1.402810172413793 ], [ 29.76946316455696, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2834, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.402810172413793 ], [ 29.769732658227849, -1.403349137931035 ], [ 29.770002151898733, -1.403349137931035 ], [ 29.770002151898733, -1.402810172413793 ], [ 29.769732658227849, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2835, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.402810172413793 ], [ 29.770002151898733, -1.403079655172414 ], [ 29.770271645569618, -1.403079655172414 ], [ 29.770271645569618, -1.402810172413793 ], [ 29.770002151898733, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2836, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.402810172413793 ], [ 29.770271645569618, -1.403079655172414 ], [ 29.770541139240507, -1.403079655172414 ], [ 29.770541139240507, -1.402810172413793 ], [ 29.770271645569618, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2837, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.402810172413793 ], [ 29.770541139240507, -1.403079655172414 ], [ 29.770810632911392, -1.403079655172414 ], [ 29.770810632911392, -1.402810172413793 ], [ 29.770541139240507, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2838, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.402810172413793 ], [ 29.770810632911392, -1.403079655172414 ], [ 29.771080126582277, -1.403079655172414 ], [ 29.771080126582277, -1.402810172413793 ], [ 29.770810632911392, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2839, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.402810172413793 ], [ 29.771349620253165, -1.403079655172414 ], [ 29.77161911392405, -1.403079655172414 ], [ 29.77161911392405, -1.402810172413793 ], [ 29.771349620253165, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2840, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.402810172413793 ], [ 29.77161911392405, -1.403079655172414 ], [ 29.771888607594935, -1.403079655172414 ], [ 29.771888607594935, -1.402810172413793 ], [ 29.77161911392405, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2841, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.402810172413793 ], [ 29.771888607594935, -1.403079655172414 ], [ 29.772158101265823, -1.403079655172414 ], [ 29.772158101265823, -1.402810172413793 ], [ 29.771888607594935, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2842, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.402810172413793 ], [ 29.772158101265823, -1.403349137931035 ], [ 29.772427594936708, -1.403349137931035 ], [ 29.772427594936708, -1.402810172413793 ], [ 29.772158101265823, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2843, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.402810172413793 ], [ 29.772427594936708, -1.403079655172414 ], [ 29.772697088607593, -1.403079655172414 ], [ 29.772697088607593, -1.402810172413793 ], [ 29.772427594936708, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2844, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.402810172413793 ], [ 29.772697088607593, -1.403079655172414 ], [ 29.772966582278482, -1.403079655172414 ], [ 29.772966582278482, -1.402810172413793 ], [ 29.772697088607593, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2845, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.402810172413793 ], [ 29.773236075949367, -1.403079655172414 ], [ 29.773505569620252, -1.403079655172414 ], [ 29.773505569620252, -1.402810172413793 ], [ 29.773236075949367, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2846, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.402810172413793 ], [ 29.773505569620252, -1.403618620689655 ], [ 29.77377506329114, -1.403618620689655 ], [ 29.77377506329114, -1.402810172413793 ], [ 29.773505569620252, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2847, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.402810172413793 ], [ 29.775122531645568, -1.403618620689655 ], [ 29.775392025316457, -1.403618620689655 ], [ 29.775392025316457, -1.402810172413793 ], [ 29.775122531645568, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2848, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.402810172413793 ], [ 29.775931012658226, -1.403618620689655 ], [ 29.776200506329115, -1.403618620689655 ], [ 29.776200506329115, -1.402810172413793 ], [ 29.775931012658226, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2849, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.402810172413793 ], [ 29.77647, -1.403618620689655 ], [ 29.776739493670885, -1.403618620689655 ], [ 29.776739493670885, -1.402810172413793 ], [ 29.77647, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2850, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.402810172413793 ], [ 29.777278481012658, -1.403618620689655 ], [ 29.777547974683543, -1.403618620689655 ], [ 29.777547974683543, -1.402810172413793 ], [ 29.777278481012658, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2851, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.402810172413793 ], [ 29.778086962025316, -1.403618620689655 ], [ 29.778356455696201, -1.403618620689655 ], [ 29.778356455696201, -1.402810172413793 ], [ 29.778086962025316, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2852, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.402810172413793 ], [ 29.77862594936709, -1.403618620689655 ], [ 29.778895443037975, -1.403618620689655 ], [ 29.778895443037975, -1.402810172413793 ], [ 29.77862594936709, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2853, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.402810172413793 ], [ 29.778895443037975, -1.403618620689655 ], [ 29.77916493670886, -1.403618620689655 ], [ 29.77916493670886, -1.402810172413793 ], [ 29.778895443037975, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2854, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.402810172413793 ], [ 29.779434430379748, -1.403618620689655 ], [ 29.779703924050633, -1.403618620689655 ], [ 29.779703924050633, -1.402810172413793 ], [ 29.779434430379748, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2855, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.402810172413793 ], [ 29.779703924050633, -1.403618620689655 ], [ 29.779973417721518, -1.403618620689655 ], [ 29.779973417721518, -1.402810172413793 ], [ 29.779703924050633, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2856, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.402810172413793 ], [ 29.779973417721518, -1.403079655172414 ], [ 29.780242911392406, -1.403079655172414 ], [ 29.780242911392406, -1.402810172413793 ], [ 29.779973417721518, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2857, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.402810172413793 ], [ 29.780242911392406, -1.403079655172414 ], [ 29.780512405063291, -1.403079655172414 ], [ 29.780512405063291, -1.402810172413793 ], [ 29.780242911392406, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2858, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.402810172413793 ], [ 29.780512405063291, -1.403079655172414 ], [ 29.780781898734176, -1.403079655172414 ], [ 29.780781898734176, -1.402810172413793 ], [ 29.780512405063291, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2859, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.402810172413793 ], [ 29.780781898734176, -1.403079655172414 ], [ 29.781051392405065, -1.403079655172414 ], [ 29.781051392405065, -1.402810172413793 ], [ 29.780781898734176, -1.402810172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2860, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.403079655172414 ], [ 29.765151265822784, -1.403349137931035 ], [ 29.765420759493672, -1.403349137931035 ], [ 29.765420759493672, -1.403079655172414 ], [ 29.765151265822784, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2861, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.403079655172414 ], [ 29.765420759493672, -1.403349137931035 ], [ 29.765959746835442, -1.403349137931035 ], [ 29.765959746835442, -1.403079655172414 ], [ 29.765420759493672, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2862, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.403079655172414 ], [ 29.765959746835442, -1.403349137931035 ], [ 29.766229240506327, -1.403349137931035 ], [ 29.766229240506327, -1.403079655172414 ], [ 29.765959746835442, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2863, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.403079655172414 ], [ 29.766229240506327, -1.403349137931035 ], [ 29.766498734177215, -1.403349137931035 ], [ 29.766498734177215, -1.403079655172414 ], [ 29.766229240506327, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2864, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.403079655172414 ], [ 29.766498734177215, -1.403349137931035 ], [ 29.7667682278481, -1.403349137931035 ], [ 29.7667682278481, -1.403079655172414 ], [ 29.766498734177215, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2865, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.403079655172414 ], [ 29.7667682278481, -1.403349137931035 ], [ 29.767037721518985, -1.403349137931035 ], [ 29.767037721518985, -1.403079655172414 ], [ 29.7667682278481, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2866, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.403079655172414 ], [ 29.767037721518985, -1.403349137931035 ], [ 29.767307215189874, -1.403349137931035 ], [ 29.767307215189874, -1.403079655172414 ], [ 29.767037721518985, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2867, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.403079655172414 ], [ 29.767307215189874, -1.403349137931035 ], [ 29.767576708860759, -1.403349137931035 ], [ 29.767576708860759, -1.403079655172414 ], [ 29.767307215189874, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2868, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.403079655172414 ], [ 29.767576708860759, -1.403349137931035 ], [ 29.767846202531643, -1.403349137931035 ], [ 29.767846202531643, -1.403079655172414 ], [ 29.767576708860759, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2869, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.403079655172414 ], [ 29.767846202531643, -1.403349137931035 ], [ 29.768115696202532, -1.403349137931035 ], [ 29.768115696202532, -1.403079655172414 ], [ 29.767846202531643, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2870, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.403079655172414 ], [ 29.768115696202532, -1.403349137931035 ], [ 29.768385189873417, -1.403349137931035 ], [ 29.768385189873417, -1.403079655172414 ], [ 29.768115696202532, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2871, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.403079655172414 ], [ 29.768385189873417, -1.403349137931035 ], [ 29.768654683544302, -1.403349137931035 ], [ 29.768654683544302, -1.403079655172414 ], [ 29.768385189873417, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2872, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.403079655172414 ], [ 29.768654683544302, -1.403349137931035 ], [ 29.76892417721519, -1.403349137931035 ], [ 29.76892417721519, -1.403079655172414 ], [ 29.768654683544302, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2873, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.403079655172414 ], [ 29.76892417721519, -1.403349137931035 ], [ 29.769193670886075, -1.403349137931035 ], [ 29.769193670886075, -1.403079655172414 ], [ 29.76892417721519, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2874, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.403079655172414 ], [ 29.769193670886075, -1.403349137931035 ], [ 29.76946316455696, -1.403349137931035 ], [ 29.76946316455696, -1.403079655172414 ], [ 29.769193670886075, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2875, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.403079655172414 ], [ 29.76946316455696, -1.403349137931035 ], [ 29.769732658227849, -1.403349137931035 ], [ 29.769732658227849, -1.403079655172414 ], [ 29.76946316455696, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2876, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.403079655172414 ], [ 29.770002151898733, -1.403349137931035 ], [ 29.770271645569618, -1.403349137931035 ], [ 29.770271645569618, -1.403079655172414 ], [ 29.770002151898733, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2877, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.403079655172414 ], [ 29.770271645569618, -1.403349137931035 ], [ 29.770541139240507, -1.403349137931035 ], [ 29.770541139240507, -1.403079655172414 ], [ 29.770271645569618, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2878, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.403079655172414 ], [ 29.770541139240507, -1.403349137931035 ], [ 29.770810632911392, -1.403349137931035 ], [ 29.770810632911392, -1.403079655172414 ], [ 29.770541139240507, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2879, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.403079655172414 ], [ 29.770810632911392, -1.403349137931035 ], [ 29.771080126582277, -1.403349137931035 ], [ 29.771080126582277, -1.403079655172414 ], [ 29.770810632911392, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2880, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.403079655172414 ], [ 29.771080126582277, -1.403349137931035 ], [ 29.771349620253165, -1.403349137931035 ], [ 29.771349620253165, -1.403079655172414 ], [ 29.771080126582277, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2881, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.403079655172414 ], [ 29.771349620253165, -1.403349137931035 ], [ 29.77161911392405, -1.403349137931035 ], [ 29.77161911392405, -1.403079655172414 ], [ 29.771349620253165, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2882, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.403079655172414 ], [ 29.77161911392405, -1.403349137931035 ], [ 29.771888607594935, -1.403349137931035 ], [ 29.771888607594935, -1.403079655172414 ], [ 29.77161911392405, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2883, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.403079655172414 ], [ 29.771888607594935, -1.403349137931035 ], [ 29.772158101265823, -1.403349137931035 ], [ 29.772158101265823, -1.403079655172414 ], [ 29.771888607594935, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2884, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.403079655172414 ], [ 29.772427594936708, -1.403349137931035 ], [ 29.772697088607593, -1.403349137931035 ], [ 29.772697088607593, -1.403079655172414 ], [ 29.772427594936708, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2885, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.403079655172414 ], [ 29.772697088607593, -1.403349137931035 ], [ 29.772966582278482, -1.403349137931035 ], [ 29.772966582278482, -1.403079655172414 ], [ 29.772697088607593, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2886, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.403079655172414 ], [ 29.772966582278482, -1.403349137931035 ], [ 29.773236075949367, -1.403349137931035 ], [ 29.773236075949367, -1.403079655172414 ], [ 29.772966582278482, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2887, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.403079655172414 ], [ 29.777817468354431, -1.403349137931035 ], [ 29.778086962025316, -1.403349137931035 ], [ 29.778086962025316, -1.403079655172414 ], [ 29.777817468354431, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2888, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.403079655172414 ], [ 29.77916493670886, -1.403349137931035 ], [ 29.779434430379748, -1.403349137931035 ], [ 29.779434430379748, -1.403079655172414 ], [ 29.77916493670886, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2889, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.403079655172414 ], [ 29.779973417721518, -1.403349137931035 ], [ 29.780242911392406, -1.403349137931035 ], [ 29.780242911392406, -1.403079655172414 ], [ 29.779973417721518, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2890, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.403079655172414 ], [ 29.780242911392406, -1.403349137931035 ], [ 29.780512405063291, -1.403349137931035 ], [ 29.780512405063291, -1.403079655172414 ], [ 29.780242911392406, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2891, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.403079655172414 ], [ 29.780512405063291, -1.403349137931035 ], [ 29.780781898734176, -1.403349137931035 ], [ 29.780781898734176, -1.403079655172414 ], [ 29.780512405063291, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2892, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.403349137931035 ], [ 29.764881772151899, -1.403888103448276 ], [ 29.765151265822784, -1.403888103448276 ], [ 29.765151265822784, -1.403349137931035 ], [ 29.764881772151899, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2893, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.403349137931035 ], [ 29.765151265822784, -1.403618620689655 ], [ 29.765420759493672, -1.403618620689655 ], [ 29.765420759493672, -1.403349137931035 ], [ 29.765151265822784, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2894, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.403349137931035 ], [ 29.765420759493672, -1.403618620689655 ], [ 29.765959746835442, -1.403618620689655 ], [ 29.765959746835442, -1.403349137931035 ], [ 29.765420759493672, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2895, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.403349137931035 ], [ 29.765959746835442, -1.403618620689655 ], [ 29.766229240506327, -1.403618620689655 ], [ 29.766229240506327, -1.403349137931035 ], [ 29.765959746835442, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2896, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.403349137931035 ], [ 29.766229240506327, -1.403618620689655 ], [ 29.766498734177215, -1.403618620689655 ], [ 29.766498734177215, -1.403349137931035 ], [ 29.766229240506327, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2897, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.403349137931035 ], [ 29.766498734177215, -1.403618620689655 ], [ 29.7667682278481, -1.403618620689655 ], [ 29.7667682278481, -1.403349137931035 ], [ 29.766498734177215, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2898, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.403349137931035 ], [ 29.7667682278481, -1.403618620689655 ], [ 29.767037721518985, -1.403618620689655 ], [ 29.767037721518985, -1.403349137931035 ], [ 29.7667682278481, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2899, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.403349137931035 ], [ 29.767037721518985, -1.403618620689655 ], [ 29.767307215189874, -1.403618620689655 ], [ 29.767307215189874, -1.403349137931035 ], [ 29.767037721518985, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2900, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.403349137931035 ], [ 29.767307215189874, -1.403618620689655 ], [ 29.767576708860759, -1.403618620689655 ], [ 29.767576708860759, -1.403349137931035 ], [ 29.767307215189874, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2901, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.403349137931035 ], [ 29.767576708860759, -1.403618620689655 ], [ 29.767846202531643, -1.403618620689655 ], [ 29.767846202531643, -1.403349137931035 ], [ 29.767576708860759, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2902, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.403349137931035 ], [ 29.767846202531643, -1.403618620689655 ], [ 29.768115696202532, -1.403618620689655 ], [ 29.768115696202532, -1.403349137931035 ], [ 29.767846202531643, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2903, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.403349137931035 ], [ 29.768115696202532, -1.403618620689655 ], [ 29.768385189873417, -1.403618620689655 ], [ 29.768385189873417, -1.403349137931035 ], [ 29.768115696202532, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2904, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.403349137931035 ], [ 29.768385189873417, -1.403618620689655 ], [ 29.768654683544302, -1.403618620689655 ], [ 29.768654683544302, -1.403349137931035 ], [ 29.768385189873417, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2905, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.403349137931035 ], [ 29.768654683544302, -1.403618620689655 ], [ 29.76892417721519, -1.403618620689655 ], [ 29.76892417721519, -1.403349137931035 ], [ 29.768654683544302, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2906, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.403349137931035 ], [ 29.76892417721519, -1.403618620689655 ], [ 29.769193670886075, -1.403618620689655 ], [ 29.769193670886075, -1.403349137931035 ], [ 29.76892417721519, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2907, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.403349137931035 ], [ 29.769193670886075, -1.403618620689655 ], [ 29.76946316455696, -1.403618620689655 ], [ 29.76946316455696, -1.403349137931035 ], [ 29.769193670886075, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2908, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.403349137931035 ], [ 29.76946316455696, -1.403618620689655 ], [ 29.769732658227849, -1.403618620689655 ], [ 29.769732658227849, -1.403349137931035 ], [ 29.76946316455696, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2909, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.403349137931035 ], [ 29.769732658227849, -1.403618620689655 ], [ 29.770002151898733, -1.403618620689655 ], [ 29.770002151898733, -1.403349137931035 ], [ 29.769732658227849, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2910, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.403349137931035 ], [ 29.770002151898733, -1.403618620689655 ], [ 29.770271645569618, -1.403618620689655 ], [ 29.770271645569618, -1.403349137931035 ], [ 29.770002151898733, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2911, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.403349137931035 ], [ 29.770271645569618, -1.403618620689655 ], [ 29.770541139240507, -1.403618620689655 ], [ 29.770541139240507, -1.403349137931035 ], [ 29.770271645569618, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2912, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.403349137931035 ], [ 29.770541139240507, -1.403618620689655 ], [ 29.770810632911392, -1.403618620689655 ], [ 29.770810632911392, -1.403349137931035 ], [ 29.770541139240507, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2913, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.403349137931035 ], [ 29.770810632911392, -1.403888103448276 ], [ 29.771080126582277, -1.403888103448276 ], [ 29.771080126582277, -1.403349137931035 ], [ 29.770810632911392, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2914, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.403349137931035 ], [ 29.771080126582277, -1.403618620689655 ], [ 29.771349620253165, -1.403618620689655 ], [ 29.771349620253165, -1.403349137931035 ], [ 29.771080126582277, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2915, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.403349137931035 ], [ 29.771349620253165, -1.403618620689655 ], [ 29.77161911392405, -1.403618620689655 ], [ 29.77161911392405, -1.403349137931035 ], [ 29.771349620253165, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2916, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.403349137931035 ], [ 29.77161911392405, -1.403618620689655 ], [ 29.771888607594935, -1.403618620689655 ], [ 29.771888607594935, -1.403349137931035 ], [ 29.77161911392405, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2917, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.403349137931035 ], [ 29.771888607594935, -1.403618620689655 ], [ 29.772158101265823, -1.403618620689655 ], [ 29.772158101265823, -1.403349137931035 ], [ 29.771888607594935, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2918, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.403349137931035 ], [ 29.772158101265823, -1.403618620689655 ], [ 29.772427594936708, -1.403618620689655 ], [ 29.772427594936708, -1.403349137931035 ], [ 29.772158101265823, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2919, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.403349137931035 ], [ 29.772427594936708, -1.403618620689655 ], [ 29.772697088607593, -1.403618620689655 ], [ 29.772697088607593, -1.403349137931035 ], [ 29.772427594936708, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2920, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.403349137931035 ], [ 29.772697088607593, -1.403618620689655 ], [ 29.772966582278482, -1.403618620689655 ], [ 29.772966582278482, -1.403349137931035 ], [ 29.772697088607593, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2921, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.403349137931035 ], [ 29.772966582278482, -1.403618620689655 ], [ 29.773236075949367, -1.403618620689655 ], [ 29.773236075949367, -1.403349137931035 ], [ 29.772966582278482, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2922, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.403349137931035 ], [ 29.777817468354431, -1.403888103448276 ], [ 29.778086962025316, -1.403888103448276 ], [ 29.778086962025316, -1.403349137931035 ], [ 29.777817468354431, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2923, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.403349137931035 ], [ 29.77916493670886, -1.403888103448276 ], [ 29.779434430379748, -1.403888103448276 ], [ 29.779434430379748, -1.403349137931035 ], [ 29.77916493670886, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2924, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.403349137931035 ], [ 29.779973417721518, -1.403618620689655 ], [ 29.780242911392406, -1.403618620689655 ], [ 29.780242911392406, -1.403349137931035 ], [ 29.779973417721518, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2925, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.403349137931035 ], [ 29.780242911392406, -1.403618620689655 ], [ 29.780512405063291, -1.403618620689655 ], [ 29.780512405063291, -1.403349137931035 ], [ 29.780242911392406, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2926, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.403349137931035 ], [ 29.780512405063291, -1.403618620689655 ], [ 29.780781898734176, -1.403618620689655 ], [ 29.780781898734176, -1.403349137931035 ], [ 29.780512405063291, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2927, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.403349137931035 ], [ 29.780781898734176, -1.403888103448276 ], [ 29.781051392405065, -1.403888103448276 ], [ 29.781051392405065, -1.403349137931035 ], [ 29.780781898734176, -1.403349137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2928, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.403618620689655 ], [ 29.765151265822784, -1.403888103448276 ], [ 29.765420759493672, -1.403888103448276 ], [ 29.765420759493672, -1.403618620689655 ], [ 29.765151265822784, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2929, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.403618620689655 ], [ 29.765420759493672, -1.403888103448276 ], [ 29.765959746835442, -1.403888103448276 ], [ 29.765959746835442, -1.403618620689655 ], [ 29.765420759493672, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2930, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.403618620689655 ], [ 29.765959746835442, -1.403888103448276 ], [ 29.766229240506327, -1.403888103448276 ], [ 29.766229240506327, -1.403618620689655 ], [ 29.765959746835442, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2931, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.403618620689655 ], [ 29.766229240506327, -1.403888103448276 ], [ 29.766498734177215, -1.403888103448276 ], [ 29.766498734177215, -1.403618620689655 ], [ 29.766229240506327, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2932, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.403618620689655 ], [ 29.766498734177215, -1.403888103448276 ], [ 29.7667682278481, -1.403888103448276 ], [ 29.7667682278481, -1.403618620689655 ], [ 29.766498734177215, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2933, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.403618620689655 ], [ 29.7667682278481, -1.403888103448276 ], [ 29.767037721518985, -1.403888103448276 ], [ 29.767037721518985, -1.403618620689655 ], [ 29.7667682278481, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2934, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.403618620689655 ], [ 29.767037721518985, -1.403888103448276 ], [ 29.767307215189874, -1.403888103448276 ], [ 29.767307215189874, -1.403618620689655 ], [ 29.767037721518985, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2935, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.403618620689655 ], [ 29.767307215189874, -1.403888103448276 ], [ 29.767576708860759, -1.403888103448276 ], [ 29.767576708860759, -1.403618620689655 ], [ 29.767307215189874, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2936, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.403618620689655 ], [ 29.767576708860759, -1.403888103448276 ], [ 29.767846202531643, -1.403888103448276 ], [ 29.767846202531643, -1.403618620689655 ], [ 29.767576708860759, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2937, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.403618620689655 ], [ 29.767846202531643, -1.403888103448276 ], [ 29.768115696202532, -1.403888103448276 ], [ 29.768115696202532, -1.403618620689655 ], [ 29.767846202531643, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2938, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.403618620689655 ], [ 29.768115696202532, -1.403888103448276 ], [ 29.768385189873417, -1.403888103448276 ], [ 29.768385189873417, -1.403618620689655 ], [ 29.768115696202532, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2939, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.403618620689655 ], [ 29.768385189873417, -1.403888103448276 ], [ 29.768654683544302, -1.403888103448276 ], [ 29.768654683544302, -1.403618620689655 ], [ 29.768385189873417, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2940, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.403618620689655 ], [ 29.768654683544302, -1.403888103448276 ], [ 29.76892417721519, -1.403888103448276 ], [ 29.76892417721519, -1.403618620689655 ], [ 29.768654683544302, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2941, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.403618620689655 ], [ 29.76892417721519, -1.403888103448276 ], [ 29.769193670886075, -1.403888103448276 ], [ 29.769193670886075, -1.403618620689655 ], [ 29.76892417721519, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2942, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.403618620689655 ], [ 29.769193670886075, -1.403888103448276 ], [ 29.76946316455696, -1.403888103448276 ], [ 29.76946316455696, -1.403618620689655 ], [ 29.769193670886075, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2943, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.403618620689655 ], [ 29.76946316455696, -1.403888103448276 ], [ 29.769732658227849, -1.403888103448276 ], [ 29.769732658227849, -1.403618620689655 ], [ 29.76946316455696, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2944, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.403618620689655 ], [ 29.769732658227849, -1.403888103448276 ], [ 29.770002151898733, -1.403888103448276 ], [ 29.770002151898733, -1.403618620689655 ], [ 29.769732658227849, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2945, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.403618620689655 ], [ 29.770002151898733, -1.403888103448276 ], [ 29.770271645569618, -1.403888103448276 ], [ 29.770271645569618, -1.403618620689655 ], [ 29.770002151898733, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2946, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.403618620689655 ], [ 29.770271645569618, -1.403888103448276 ], [ 29.770541139240507, -1.403888103448276 ], [ 29.770541139240507, -1.403618620689655 ], [ 29.770271645569618, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2947, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.403618620689655 ], [ 29.770541139240507, -1.403888103448276 ], [ 29.770810632911392, -1.403888103448276 ], [ 29.770810632911392, -1.403618620689655 ], [ 29.770541139240507, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2948, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.403618620689655 ], [ 29.771080126582277, -1.403888103448276 ], [ 29.771349620253165, -1.403888103448276 ], [ 29.771349620253165, -1.403618620689655 ], [ 29.771080126582277, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2949, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.403618620689655 ], [ 29.771349620253165, -1.403888103448276 ], [ 29.77161911392405, -1.403888103448276 ], [ 29.77161911392405, -1.403618620689655 ], [ 29.771349620253165, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2950, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.403618620689655 ], [ 29.77161911392405, -1.403888103448276 ], [ 29.771888607594935, -1.403888103448276 ], [ 29.771888607594935, -1.403618620689655 ], [ 29.77161911392405, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2951, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.403618620689655 ], [ 29.771888607594935, -1.404157586206897 ], [ 29.772158101265823, -1.404157586206897 ], [ 29.772158101265823, -1.403618620689655 ], [ 29.771888607594935, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2952, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.403618620689655 ], [ 29.772158101265823, -1.403888103448276 ], [ 29.772427594936708, -1.403888103448276 ], [ 29.772427594936708, -1.403618620689655 ], [ 29.772158101265823, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2953, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.403618620689655 ], [ 29.772427594936708, -1.403888103448276 ], [ 29.772697088607593, -1.403888103448276 ], [ 29.772697088607593, -1.403618620689655 ], [ 29.772427594936708, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2954, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.403618620689655 ], [ 29.772697088607593, -1.403888103448276 ], [ 29.772966582278482, -1.403888103448276 ], [ 29.772966582278482, -1.403618620689655 ], [ 29.772697088607593, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2955, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.403618620689655 ], [ 29.772966582278482, -1.403888103448276 ], [ 29.773236075949367, -1.403888103448276 ], [ 29.773236075949367, -1.403618620689655 ], [ 29.772966582278482, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2956, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.403618620689655 ], [ 29.773505569620252, -1.404427068965517 ], [ 29.77377506329114, -1.404427068965517 ], [ 29.77377506329114, -1.403618620689655 ], [ 29.773505569620252, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2957, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.403618620689655 ], [ 29.775122531645568, -1.404157586206897 ], [ 29.775392025316457, -1.404157586206897 ], [ 29.775392025316457, -1.403618620689655 ], [ 29.775122531645568, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2958, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.403618620689655 ], [ 29.775931012658226, -1.404157586206897 ], [ 29.776200506329115, -1.404157586206897 ], [ 29.776200506329115, -1.403618620689655 ], [ 29.775931012658226, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2959, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.403618620689655 ], [ 29.77647, -1.404157586206897 ], [ 29.776739493670885, -1.404157586206897 ], [ 29.776739493670885, -1.403618620689655 ], [ 29.77647, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2960, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.403618620689655 ], [ 29.777278481012658, -1.404157586206897 ], [ 29.777547974683543, -1.404157586206897 ], [ 29.777547974683543, -1.403618620689655 ], [ 29.777278481012658, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2961, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.403618620689655 ], [ 29.778086962025316, -1.404157586206897 ], [ 29.778356455696201, -1.404157586206897 ], [ 29.778356455696201, -1.403618620689655 ], [ 29.778086962025316, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2962, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.403618620689655 ], [ 29.77862594936709, -1.403888103448276 ], [ 29.778895443037975, -1.403888103448276 ], [ 29.778895443037975, -1.403618620689655 ], [ 29.77862594936709, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2963, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.403618620689655 ], [ 29.778895443037975, -1.403888103448276 ], [ 29.77916493670886, -1.403888103448276 ], [ 29.77916493670886, -1.403618620689655 ], [ 29.778895443037975, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2964, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.403618620689655 ], [ 29.779434430379748, -1.403888103448276 ], [ 29.779703924050633, -1.403888103448276 ], [ 29.779703924050633, -1.403618620689655 ], [ 29.779434430379748, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2965, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.403618620689655 ], [ 29.779703924050633, -1.403888103448276 ], [ 29.779973417721518, -1.403888103448276 ], [ 29.779973417721518, -1.403618620689655 ], [ 29.779703924050633, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2966, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.403618620689655 ], [ 29.779973417721518, -1.403888103448276 ], [ 29.780242911392406, -1.403888103448276 ], [ 29.780242911392406, -1.403618620689655 ], [ 29.779973417721518, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2967, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.403618620689655 ], [ 29.780242911392406, -1.403888103448276 ], [ 29.780512405063291, -1.403888103448276 ], [ 29.780512405063291, -1.403618620689655 ], [ 29.780242911392406, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2968, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.403618620689655 ], [ 29.780512405063291, -1.403888103448276 ], [ 29.780781898734176, -1.403888103448276 ], [ 29.780781898734176, -1.403618620689655 ], [ 29.780512405063291, -1.403618620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2969, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.403888103448276 ], [ 29.764612278481014, -1.404427068965517 ], [ 29.764881772151899, -1.404427068965517 ], [ 29.764881772151899, -1.403888103448276 ], [ 29.764612278481014, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2970, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.403888103448276 ], [ 29.764881772151899, -1.404157586206897 ], [ 29.765151265822784, -1.404157586206897 ], [ 29.765151265822784, -1.403888103448276 ], [ 29.764881772151899, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2971, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.403888103448276 ], [ 29.765151265822784, -1.404157586206897 ], [ 29.765420759493672, -1.404157586206897 ], [ 29.765420759493672, -1.403888103448276 ], [ 29.765151265822784, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2972, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.403888103448276 ], [ 29.765420759493672, -1.404157586206897 ], [ 29.765959746835442, -1.404157586206897 ], [ 29.765959746835442, -1.403888103448276 ], [ 29.765420759493672, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2973, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.403888103448276 ], [ 29.765959746835442, -1.404157586206897 ], [ 29.766229240506327, -1.404157586206897 ], [ 29.766229240506327, -1.403888103448276 ], [ 29.765959746835442, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2974, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.403888103448276 ], [ 29.766229240506327, -1.404157586206897 ], [ 29.766498734177215, -1.404157586206897 ], [ 29.766498734177215, -1.403888103448276 ], [ 29.766229240506327, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2975, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.403888103448276 ], [ 29.766498734177215, -1.404157586206897 ], [ 29.7667682278481, -1.404157586206897 ], [ 29.7667682278481, -1.403888103448276 ], [ 29.766498734177215, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2976, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.403888103448276 ], [ 29.7667682278481, -1.404157586206897 ], [ 29.767037721518985, -1.404157586206897 ], [ 29.767037721518985, -1.403888103448276 ], [ 29.7667682278481, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2977, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.403888103448276 ], [ 29.767037721518985, -1.404157586206897 ], [ 29.767307215189874, -1.404157586206897 ], [ 29.767307215189874, -1.403888103448276 ], [ 29.767037721518985, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2978, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.403888103448276 ], [ 29.767307215189874, -1.404157586206897 ], [ 29.767576708860759, -1.404157586206897 ], [ 29.767576708860759, -1.403888103448276 ], [ 29.767307215189874, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2979, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.403888103448276 ], [ 29.767576708860759, -1.404157586206897 ], [ 29.767846202531643, -1.404157586206897 ], [ 29.767846202531643, -1.403888103448276 ], [ 29.767576708860759, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2980, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.403888103448276 ], [ 29.767846202531643, -1.404157586206897 ], [ 29.768115696202532, -1.404157586206897 ], [ 29.768115696202532, -1.403888103448276 ], [ 29.767846202531643, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2981, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.403888103448276 ], [ 29.768115696202532, -1.404157586206897 ], [ 29.768385189873417, -1.404157586206897 ], [ 29.768385189873417, -1.403888103448276 ], [ 29.768115696202532, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2982, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.403888103448276 ], [ 29.768385189873417, -1.404157586206897 ], [ 29.768654683544302, -1.404157586206897 ], [ 29.768654683544302, -1.403888103448276 ], [ 29.768385189873417, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2983, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.403888103448276 ], [ 29.768654683544302, -1.404157586206897 ], [ 29.76892417721519, -1.404157586206897 ], [ 29.76892417721519, -1.403888103448276 ], [ 29.768654683544302, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2984, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.403888103448276 ], [ 29.76892417721519, -1.404157586206897 ], [ 29.769193670886075, -1.404157586206897 ], [ 29.769193670886075, -1.403888103448276 ], [ 29.76892417721519, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2985, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.403888103448276 ], [ 29.769193670886075, -1.404157586206897 ], [ 29.76946316455696, -1.404157586206897 ], [ 29.76946316455696, -1.403888103448276 ], [ 29.769193670886075, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2986, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.403888103448276 ], [ 29.76946316455696, -1.404157586206897 ], [ 29.769732658227849, -1.404157586206897 ], [ 29.769732658227849, -1.403888103448276 ], [ 29.76946316455696, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2987, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.403888103448276 ], [ 29.769732658227849, -1.404157586206897 ], [ 29.770002151898733, -1.404157586206897 ], [ 29.770002151898733, -1.403888103448276 ], [ 29.769732658227849, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2988, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.403888103448276 ], [ 29.770002151898733, -1.404157586206897 ], [ 29.770271645569618, -1.404157586206897 ], [ 29.770271645569618, -1.403888103448276 ], [ 29.770002151898733, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2989, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.403888103448276 ], [ 29.770271645569618, -1.404157586206897 ], [ 29.770541139240507, -1.404157586206897 ], [ 29.770541139240507, -1.403888103448276 ], [ 29.770271645569618, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2990, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.403888103448276 ], [ 29.770541139240507, -1.404157586206897 ], [ 29.770810632911392, -1.404157586206897 ], [ 29.770810632911392, -1.403888103448276 ], [ 29.770541139240507, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2991, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.403888103448276 ], [ 29.770810632911392, -1.404157586206897 ], [ 29.771080126582277, -1.404157586206897 ], [ 29.771080126582277, -1.403888103448276 ], [ 29.770810632911392, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2992, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.403888103448276 ], [ 29.771080126582277, -1.404157586206897 ], [ 29.771349620253165, -1.404157586206897 ], [ 29.771349620253165, -1.403888103448276 ], [ 29.771080126582277, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2993, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.403888103448276 ], [ 29.771349620253165, -1.404157586206897 ], [ 29.77161911392405, -1.404157586206897 ], [ 29.77161911392405, -1.403888103448276 ], [ 29.771349620253165, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2994, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.403888103448276 ], [ 29.77161911392405, -1.404157586206897 ], [ 29.771888607594935, -1.404157586206897 ], [ 29.771888607594935, -1.403888103448276 ], [ 29.77161911392405, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2995, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.403888103448276 ], [ 29.772158101265823, -1.404157586206897 ], [ 29.772427594936708, -1.404157586206897 ], [ 29.772427594936708, -1.403888103448276 ], [ 29.772158101265823, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2996, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.403888103448276 ], [ 29.772427594936708, -1.404157586206897 ], [ 29.772697088607593, -1.404157586206897 ], [ 29.772697088607593, -1.403888103448276 ], [ 29.772427594936708, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2997, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.403888103448276 ], [ 29.772697088607593, -1.404157586206897 ], [ 29.772966582278482, -1.404157586206897 ], [ 29.772966582278482, -1.403888103448276 ], [ 29.772697088607593, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2998, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.403079655172414 ], [ 29.773505569620252, -1.403079655172414 ], [ 29.773505569620252, -1.404157586206897 ], [ 29.773236075949367, -1.404157586206897 ], [ 29.773236075949367, -1.404427068965517 ], [ 29.772966582278482, -1.404427068965517 ], [ 29.772966582278482, -1.403888103448276 ], [ 29.773236075949367, -1.403888103448276 ], [ 29.773236075949367, -1.403079655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 2999, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.403888103448276 ], [ 29.77377506329114, -1.404427068965517 ], [ 29.774044556962025, -1.404427068965517 ], [ 29.774044556962025, -1.403888103448276 ], [ 29.77377506329114, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3000, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.403888103448276 ], [ 29.77431405063291, -1.404427068965517 ], [ 29.774853037974683, -1.404427068965517 ], [ 29.774853037974683, -1.403888103448276 ], [ 29.77431405063291, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3001, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.403888103448276 ], [ 29.775392025316457, -1.404427068965517 ], [ 29.775661518987341, -1.404427068965517 ], [ 29.775661518987341, -1.403888103448276 ], [ 29.775392025316457, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3002, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.403888103448276 ], [ 29.776200506329115, -1.404427068965517 ], [ 29.77647, -1.404427068965517 ], [ 29.77647, -1.403888103448276 ], [ 29.776200506329115, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3003, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.403888103448276 ], [ 29.776739493670885, -1.404157586206897 ], [ 29.777008987341773, -1.404157586206897 ], [ 29.777008987341773, -1.403888103448276 ], [ 29.776739493670885, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3004, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.403888103448276 ], [ 29.777008987341773, -1.404427068965517 ], [ 29.777278481012658, -1.404427068965517 ], [ 29.777278481012658, -1.403888103448276 ], [ 29.777008987341773, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3005, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.403888103448276 ], [ 29.777547974683543, -1.404157586206897 ], [ 29.777817468354431, -1.404157586206897 ], [ 29.777817468354431, -1.403888103448276 ], [ 29.777547974683543, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3006, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.403888103448276 ], [ 29.777817468354431, -1.404157586206897 ], [ 29.778086962025316, -1.404157586206897 ], [ 29.778086962025316, -1.403888103448276 ], [ 29.777817468354431, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3007, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.403888103448276 ], [ 29.778356455696201, -1.404157586206897 ], [ 29.77862594936709, -1.404157586206897 ], [ 29.77862594936709, -1.403888103448276 ], [ 29.778356455696201, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3008, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.403888103448276 ], [ 29.77862594936709, -1.404157586206897 ], [ 29.778895443037975, -1.404157586206897 ], [ 29.778895443037975, -1.403888103448276 ], [ 29.77862594936709, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3009, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.403888103448276 ], [ 29.778895443037975, -1.404157586206897 ], [ 29.77916493670886, -1.404157586206897 ], [ 29.77916493670886, -1.403888103448276 ], [ 29.778895443037975, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3010, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.403888103448276 ], [ 29.77916493670886, -1.404157586206897 ], [ 29.779434430379748, -1.404157586206897 ], [ 29.779434430379748, -1.403888103448276 ], [ 29.77916493670886, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3011, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.403888103448276 ], [ 29.779434430379748, -1.404157586206897 ], [ 29.779703924050633, -1.404157586206897 ], [ 29.779703924050633, -1.403888103448276 ], [ 29.779434430379748, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3012, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.403888103448276 ], [ 29.779703924050633, -1.404157586206897 ], [ 29.779973417721518, -1.404157586206897 ], [ 29.779973417721518, -1.403888103448276 ], [ 29.779703924050633, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3013, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.403888103448276 ], [ 29.779973417721518, -1.404157586206897 ], [ 29.780242911392406, -1.404157586206897 ], [ 29.780242911392406, -1.403888103448276 ], [ 29.779973417721518, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3014, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.403888103448276 ], [ 29.780242911392406, -1.404157586206897 ], [ 29.780512405063291, -1.404157586206897 ], [ 29.780512405063291, -1.403888103448276 ], [ 29.780242911392406, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3015, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.403888103448276 ], [ 29.780512405063291, -1.404157586206897 ], [ 29.780781898734176, -1.404157586206897 ], [ 29.780781898734176, -1.403888103448276 ], [ 29.780512405063291, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3016, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.403888103448276 ], [ 29.780781898734176, -1.404157586206897 ], [ 29.781051392405065, -1.404157586206897 ], [ 29.781051392405065, -1.403888103448276 ], [ 29.780781898734176, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3017, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.403888103448276 ], [ 29.781051392405065, -1.404427068965517 ], [ 29.78132088607595, -1.404427068965517 ], [ 29.78132088607595, -1.403888103448276 ], [ 29.781051392405065, -1.403888103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3018, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.404157586206897 ], [ 29.764881772151899, -1.404427068965517 ], [ 29.765151265822784, -1.404427068965517 ], [ 29.765151265822784, -1.404157586206897 ], [ 29.764881772151899, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3019, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.404157586206897 ], [ 29.765151265822784, -1.404427068965517 ], [ 29.765420759493672, -1.404427068965517 ], [ 29.765420759493672, -1.404157586206897 ], [ 29.765151265822784, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3020, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.404157586206897 ], [ 29.765420759493672, -1.404427068965517 ], [ 29.765959746835442, -1.404427068965517 ], [ 29.765959746835442, -1.404157586206897 ], [ 29.765420759493672, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3021, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.404157586206897 ], [ 29.765959746835442, -1.404427068965517 ], [ 29.766229240506327, -1.404427068965517 ], [ 29.766229240506327, -1.404157586206897 ], [ 29.765959746835442, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3022, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.404157586206897 ], [ 29.766229240506327, -1.404427068965517 ], [ 29.766498734177215, -1.404427068965517 ], [ 29.766498734177215, -1.404157586206897 ], [ 29.766229240506327, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3023, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.404157586206897 ], [ 29.766498734177215, -1.404427068965517 ], [ 29.7667682278481, -1.404427068965517 ], [ 29.7667682278481, -1.404157586206897 ], [ 29.766498734177215, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.404157586206897 ], [ 29.7667682278481, -1.404427068965517 ], [ 29.767037721518985, -1.404427068965517 ], [ 29.767037721518985, -1.404157586206897 ], [ 29.7667682278481, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3025, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.404157586206897 ], [ 29.767037721518985, -1.404427068965517 ], [ 29.767307215189874, -1.404427068965517 ], [ 29.767307215189874, -1.404157586206897 ], [ 29.767037721518985, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3026, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.404157586206897 ], [ 29.767307215189874, -1.404427068965517 ], [ 29.767576708860759, -1.404427068965517 ], [ 29.767576708860759, -1.404157586206897 ], [ 29.767307215189874, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3027, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.404157586206897 ], [ 29.767576708860759, -1.404427068965517 ], [ 29.767846202531643, -1.404427068965517 ], [ 29.767846202531643, -1.404157586206897 ], [ 29.767576708860759, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3028, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.404157586206897 ], [ 29.767846202531643, -1.404427068965517 ], [ 29.768115696202532, -1.404427068965517 ], [ 29.768115696202532, -1.404157586206897 ], [ 29.767846202531643, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3029, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.404157586206897 ], [ 29.768115696202532, -1.404427068965517 ], [ 29.768385189873417, -1.404427068965517 ], [ 29.768385189873417, -1.404157586206897 ], [ 29.768115696202532, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3030, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.404157586206897 ], [ 29.768385189873417, -1.404427068965517 ], [ 29.768654683544302, -1.404427068965517 ], [ 29.768654683544302, -1.404157586206897 ], [ 29.768385189873417, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3031, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.404157586206897 ], [ 29.768654683544302, -1.404427068965517 ], [ 29.76892417721519, -1.404427068965517 ], [ 29.76892417721519, -1.404157586206897 ], [ 29.768654683544302, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3032, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.404157586206897 ], [ 29.76892417721519, -1.404427068965517 ], [ 29.769193670886075, -1.404427068965517 ], [ 29.769193670886075, -1.404157586206897 ], [ 29.76892417721519, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3033, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.404157586206897 ], [ 29.769193670886075, -1.404427068965517 ], [ 29.76946316455696, -1.404427068965517 ], [ 29.76946316455696, -1.404157586206897 ], [ 29.769193670886075, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3034, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.404157586206897 ], [ 29.76946316455696, -1.404427068965517 ], [ 29.769732658227849, -1.404427068965517 ], [ 29.769732658227849, -1.404157586206897 ], [ 29.76946316455696, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3035, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.404157586206897 ], [ 29.769732658227849, -1.404427068965517 ], [ 29.770002151898733, -1.404427068965517 ], [ 29.770002151898733, -1.404157586206897 ], [ 29.769732658227849, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3036, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.404157586206897 ], [ 29.770002151898733, -1.404427068965517 ], [ 29.770271645569618, -1.404427068965517 ], [ 29.770271645569618, -1.404157586206897 ], [ 29.770002151898733, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3037, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.404157586206897 ], [ 29.770271645569618, -1.404427068965517 ], [ 29.770541139240507, -1.404427068965517 ], [ 29.770541139240507, -1.404157586206897 ], [ 29.770271645569618, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3038, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.404157586206897 ], [ 29.770541139240507, -1.404427068965517 ], [ 29.770810632911392, -1.404427068965517 ], [ 29.770810632911392, -1.404157586206897 ], [ 29.770541139240507, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3039, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.404157586206897 ], [ 29.770810632911392, -1.404427068965517 ], [ 29.771080126582277, -1.404427068965517 ], [ 29.771080126582277, -1.404157586206897 ], [ 29.770810632911392, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3040, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.404157586206897 ], [ 29.771080126582277, -1.404427068965517 ], [ 29.771349620253165, -1.404427068965517 ], [ 29.771349620253165, -1.404157586206897 ], [ 29.771080126582277, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3041, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.404157586206897 ], [ 29.771349620253165, -1.404427068965517 ], [ 29.77161911392405, -1.404427068965517 ], [ 29.77161911392405, -1.404157586206897 ], [ 29.771349620253165, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3042, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.404157586206897 ], [ 29.77161911392405, -1.404427068965517 ], [ 29.771888607594935, -1.404427068965517 ], [ 29.771888607594935, -1.404157586206897 ], [ 29.77161911392405, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3043, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.404157586206897 ], [ 29.771888607594935, -1.404427068965517 ], [ 29.772158101265823, -1.404427068965517 ], [ 29.772158101265823, -1.404157586206897 ], [ 29.771888607594935, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3044, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.404157586206897 ], [ 29.772158101265823, -1.404427068965517 ], [ 29.772427594936708, -1.404427068965517 ], [ 29.772427594936708, -1.404157586206897 ], [ 29.772158101265823, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3045, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.404157586206897 ], [ 29.772427594936708, -1.404427068965517 ], [ 29.772697088607593, -1.404427068965517 ], [ 29.772697088607593, -1.404157586206897 ], [ 29.772427594936708, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3046, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.404157586206897 ], [ 29.772697088607593, -1.404427068965517 ], [ 29.772966582278482, -1.404427068965517 ], [ 29.772966582278482, -1.404157586206897 ], [ 29.772697088607593, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3047, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.404157586206897 ], [ 29.773236075949367, -1.404696551724138 ], [ 29.773505569620252, -1.404696551724138 ], [ 29.773505569620252, -1.404157586206897 ], [ 29.773236075949367, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3048, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.404157586206897 ], [ 29.774044556962025, -1.404696551724138 ], [ 29.77431405063291, -1.404696551724138 ], [ 29.77431405063291, -1.404157586206897 ], [ 29.774044556962025, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3049, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.404157586206897 ], [ 29.774853037974683, -1.404427068965517 ], [ 29.775122531645568, -1.404427068965517 ], [ 29.775122531645568, -1.404157586206897 ], [ 29.774853037974683, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3050, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.404157586206897 ], [ 29.775122531645568, -1.404696551724138 ], [ 29.775392025316457, -1.404696551724138 ], [ 29.775392025316457, -1.404157586206897 ], [ 29.775122531645568, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3051, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.404157586206897 ], [ 29.775661518987341, -1.404427068965517 ], [ 29.775931012658226, -1.404427068965517 ], [ 29.775931012658226, -1.404157586206897 ], [ 29.775661518987341, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3052, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.404157586206897 ], [ 29.775931012658226, -1.404427068965517 ], [ 29.776200506329115, -1.404427068965517 ], [ 29.776200506329115, -1.404157586206897 ], [ 29.775931012658226, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3053, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.404157586206897 ], [ 29.77647, -1.404427068965517 ], [ 29.776739493670885, -1.404427068965517 ], [ 29.776739493670885, -1.404157586206897 ], [ 29.77647, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3054, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.404157586206897 ], [ 29.776739493670885, -1.404427068965517 ], [ 29.777008987341773, -1.404427068965517 ], [ 29.777008987341773, -1.404157586206897 ], [ 29.776739493670885, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3055, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.404157586206897 ], [ 29.777278481012658, -1.404427068965517 ], [ 29.777547974683543, -1.404427068965517 ], [ 29.777547974683543, -1.404157586206897 ], [ 29.777278481012658, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3056, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.404157586206897 ], [ 29.777547974683543, -1.404427068965517 ], [ 29.777817468354431, -1.404427068965517 ], [ 29.777817468354431, -1.404157586206897 ], [ 29.777547974683543, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3057, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.404157586206897 ], [ 29.777817468354431, -1.404427068965517 ], [ 29.778086962025316, -1.404427068965517 ], [ 29.778086962025316, -1.404157586206897 ], [ 29.777817468354431, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3058, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.404157586206897 ], [ 29.778086962025316, -1.404427068965517 ], [ 29.778356455696201, -1.404427068965517 ], [ 29.778356455696201, -1.404157586206897 ], [ 29.778086962025316, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3059, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.404157586206897 ], [ 29.778356455696201, -1.404427068965517 ], [ 29.77862594936709, -1.404427068965517 ], [ 29.77862594936709, -1.404157586206897 ], [ 29.778356455696201, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3060, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.404157586206897 ], [ 29.77862594936709, -1.404427068965517 ], [ 29.778895443037975, -1.404427068965517 ], [ 29.778895443037975, -1.404157586206897 ], [ 29.77862594936709, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3061, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.404157586206897 ], [ 29.778895443037975, -1.404427068965517 ], [ 29.77916493670886, -1.404427068965517 ], [ 29.77916493670886, -1.404157586206897 ], [ 29.778895443037975, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3062, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.404157586206897 ], [ 29.77916493670886, -1.404427068965517 ], [ 29.779434430379748, -1.404427068965517 ], [ 29.779434430379748, -1.404157586206897 ], [ 29.77916493670886, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3063, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.404157586206897 ], [ 29.779434430379748, -1.404427068965517 ], [ 29.779703924050633, -1.404427068965517 ], [ 29.779703924050633, -1.404157586206897 ], [ 29.779434430379748, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3064, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.404157586206897 ], [ 29.779703924050633, -1.404427068965517 ], [ 29.779973417721518, -1.404427068965517 ], [ 29.779973417721518, -1.404157586206897 ], [ 29.779703924050633, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3065, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.404157586206897 ], [ 29.779973417721518, -1.404427068965517 ], [ 29.780242911392406, -1.404427068965517 ], [ 29.780242911392406, -1.404157586206897 ], [ 29.779973417721518, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3066, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.404157586206897 ], [ 29.780242911392406, -1.404427068965517 ], [ 29.780512405063291, -1.404427068965517 ], [ 29.780512405063291, -1.404157586206897 ], [ 29.780242911392406, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3067, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.404157586206897 ], [ 29.780512405063291, -1.404427068965517 ], [ 29.780781898734176, -1.404427068965517 ], [ 29.780781898734176, -1.404157586206897 ], [ 29.780512405063291, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3068, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.404157586206897 ], [ 29.780781898734176, -1.404427068965517 ], [ 29.781051392405065, -1.404427068965517 ], [ 29.781051392405065, -1.404157586206897 ], [ 29.780781898734176, -1.404157586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3069, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.404427068965517 ], [ 29.764342784810125, -1.404966034482759 ], [ 29.764612278481014, -1.404966034482759 ], [ 29.764612278481014, -1.404427068965517 ], [ 29.764342784810125, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3070, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.404427068965517 ], [ 29.764612278481014, -1.404696551724138 ], [ 29.764881772151899, -1.404696551724138 ], [ 29.764881772151899, -1.404427068965517 ], [ 29.764612278481014, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3071, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.404427068965517 ], [ 29.764881772151899, -1.404696551724138 ], [ 29.765151265822784, -1.404696551724138 ], [ 29.765151265822784, -1.404427068965517 ], [ 29.764881772151899, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3072, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.404427068965517 ], [ 29.765151265822784, -1.404696551724138 ], [ 29.765420759493672, -1.404696551724138 ], [ 29.765420759493672, -1.404427068965517 ], [ 29.765151265822784, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3073, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.404427068965517 ], [ 29.765420759493672, -1.404696551724138 ], [ 29.765959746835442, -1.404696551724138 ], [ 29.765959746835442, -1.404427068965517 ], [ 29.765420759493672, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3074, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.404427068965517 ], [ 29.765959746835442, -1.404696551724138 ], [ 29.766229240506327, -1.404696551724138 ], [ 29.766229240506327, -1.404427068965517 ], [ 29.765959746835442, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3075, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.404427068965517 ], [ 29.766229240506327, -1.404696551724138 ], [ 29.766498734177215, -1.404696551724138 ], [ 29.766498734177215, -1.404427068965517 ], [ 29.766229240506327, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3076, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.404427068965517 ], [ 29.766498734177215, -1.404696551724138 ], [ 29.7667682278481, -1.404696551724138 ], [ 29.7667682278481, -1.404427068965517 ], [ 29.766498734177215, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3077, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.404427068965517 ], [ 29.7667682278481, -1.404696551724138 ], [ 29.767037721518985, -1.404696551724138 ], [ 29.767037721518985, -1.404427068965517 ], [ 29.7667682278481, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3078, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.404427068965517 ], [ 29.767037721518985, -1.404696551724138 ], [ 29.767307215189874, -1.404696551724138 ], [ 29.767307215189874, -1.404427068965517 ], [ 29.767037721518985, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3079, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.404427068965517 ], [ 29.767307215189874, -1.404696551724138 ], [ 29.767576708860759, -1.404696551724138 ], [ 29.767576708860759, -1.404427068965517 ], [ 29.767307215189874, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3080, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.404427068965517 ], [ 29.767576708860759, -1.404696551724138 ], [ 29.767846202531643, -1.404696551724138 ], [ 29.767846202531643, -1.404427068965517 ], [ 29.767576708860759, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3081, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.404427068965517 ], [ 29.767846202531643, -1.404696551724138 ], [ 29.768115696202532, -1.404696551724138 ], [ 29.768115696202532, -1.404427068965517 ], [ 29.767846202531643, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3082, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.404427068965517 ], [ 29.768115696202532, -1.404696551724138 ], [ 29.768385189873417, -1.404696551724138 ], [ 29.768385189873417, -1.404427068965517 ], [ 29.768115696202532, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3083, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.404427068965517 ], [ 29.768385189873417, -1.404696551724138 ], [ 29.768654683544302, -1.404696551724138 ], [ 29.768654683544302, -1.404427068965517 ], [ 29.768385189873417, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3084, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.404427068965517 ], [ 29.768654683544302, -1.404696551724138 ], [ 29.76892417721519, -1.404696551724138 ], [ 29.76892417721519, -1.404427068965517 ], [ 29.768654683544302, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3085, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.404427068965517 ], [ 29.76892417721519, -1.404696551724138 ], [ 29.769193670886075, -1.404696551724138 ], [ 29.769193670886075, -1.404427068965517 ], [ 29.76892417721519, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3086, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.404427068965517 ], [ 29.769193670886075, -1.404696551724138 ], [ 29.76946316455696, -1.404696551724138 ], [ 29.76946316455696, -1.404427068965517 ], [ 29.769193670886075, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3087, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.404427068965517 ], [ 29.76946316455696, -1.404696551724138 ], [ 29.769732658227849, -1.404696551724138 ], [ 29.769732658227849, -1.404427068965517 ], [ 29.76946316455696, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3088, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.404427068965517 ], [ 29.769732658227849, -1.404696551724138 ], [ 29.770002151898733, -1.404696551724138 ], [ 29.770002151898733, -1.404427068965517 ], [ 29.769732658227849, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3089, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.404427068965517 ], [ 29.770002151898733, -1.404696551724138 ], [ 29.770271645569618, -1.404696551724138 ], [ 29.770271645569618, -1.404427068965517 ], [ 29.770002151898733, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3090, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.404427068965517 ], [ 29.770271645569618, -1.404696551724138 ], [ 29.770541139240507, -1.404696551724138 ], [ 29.770541139240507, -1.404427068965517 ], [ 29.770271645569618, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3091, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.404427068965517 ], [ 29.770541139240507, -1.404696551724138 ], [ 29.770810632911392, -1.404696551724138 ], [ 29.770810632911392, -1.404427068965517 ], [ 29.770541139240507, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3092, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.404427068965517 ], [ 29.770810632911392, -1.404696551724138 ], [ 29.771080126582277, -1.404696551724138 ], [ 29.771080126582277, -1.404427068965517 ], [ 29.770810632911392, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3093, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.404427068965517 ], [ 29.771080126582277, -1.404696551724138 ], [ 29.771349620253165, -1.404696551724138 ], [ 29.771349620253165, -1.404427068965517 ], [ 29.771080126582277, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3094, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.404427068965517 ], [ 29.771349620253165, -1.404696551724138 ], [ 29.77161911392405, -1.404696551724138 ], [ 29.77161911392405, -1.404427068965517 ], [ 29.771349620253165, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3095, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.404427068965517 ], [ 29.77161911392405, -1.404696551724138 ], [ 29.771888607594935, -1.404696551724138 ], [ 29.771888607594935, -1.404427068965517 ], [ 29.77161911392405, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3096, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.404427068965517 ], [ 29.771888607594935, -1.404696551724138 ], [ 29.772158101265823, -1.404696551724138 ], [ 29.772158101265823, -1.404427068965517 ], [ 29.771888607594935, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3097, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.404427068965517 ], [ 29.772158101265823, -1.404696551724138 ], [ 29.772427594936708, -1.404696551724138 ], [ 29.772427594936708, -1.404427068965517 ], [ 29.772158101265823, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3098, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.404427068965517 ], [ 29.772427594936708, -1.404696551724138 ], [ 29.772697088607593, -1.404696551724138 ], [ 29.772697088607593, -1.404427068965517 ], [ 29.772427594936708, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3099, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.404427068965517 ], [ 29.772697088607593, -1.404696551724138 ], [ 29.772966582278482, -1.404696551724138 ], [ 29.772966582278482, -1.404427068965517 ], [ 29.772697088607593, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3100, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.404427068965517 ], [ 29.772966582278482, -1.404696551724138 ], [ 29.773236075949367, -1.404696551724138 ], [ 29.773236075949367, -1.404427068965517 ], [ 29.772966582278482, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3101, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.404427068965517 ], [ 29.773505569620252, -1.404696551724138 ], [ 29.77377506329114, -1.404696551724138 ], [ 29.77377506329114, -1.404427068965517 ], [ 29.773505569620252, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3102, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.404427068965517 ], [ 29.77377506329114, -1.404966034482759 ], [ 29.774044556962025, -1.404966034482759 ], [ 29.774044556962025, -1.404427068965517 ], [ 29.77377506329114, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3103, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.404427068965517 ], [ 29.77431405063291, -1.404696551724138 ], [ 29.774853037974683, -1.404696551724138 ], [ 29.774853037974683, -1.404427068965517 ], [ 29.77431405063291, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3104, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.404427068965517 ], [ 29.774853037974683, -1.404696551724138 ], [ 29.775122531645568, -1.404696551724138 ], [ 29.775122531645568, -1.404427068965517 ], [ 29.774853037974683, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3105, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.404427068965517 ], [ 29.775392025316457, -1.404696551724138 ], [ 29.775661518987341, -1.404696551724138 ], [ 29.775661518987341, -1.404427068965517 ], [ 29.775392025316457, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3106, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.404427068965517 ], [ 29.775661518987341, -1.404696551724138 ], [ 29.775931012658226, -1.404696551724138 ], [ 29.775931012658226, -1.404427068965517 ], [ 29.775661518987341, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3107, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.404427068965517 ], [ 29.775931012658226, -1.404966034482759 ], [ 29.776200506329115, -1.404966034482759 ], [ 29.776200506329115, -1.404427068965517 ], [ 29.775931012658226, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3108, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.404427068965517 ], [ 29.776200506329115, -1.404696551724138 ], [ 29.77647, -1.404696551724138 ], [ 29.77647, -1.404427068965517 ], [ 29.776200506329115, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3109, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.404427068965517 ], [ 29.77647, -1.404696551724138 ], [ 29.776739493670885, -1.404696551724138 ], [ 29.776739493670885, -1.404427068965517 ], [ 29.77647, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3110, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.404427068965517 ], [ 29.776739493670885, -1.404696551724138 ], [ 29.777008987341773, -1.404696551724138 ], [ 29.777008987341773, -1.404427068965517 ], [ 29.776739493670885, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3111, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.404427068965517 ], [ 29.777008987341773, -1.404696551724138 ], [ 29.777278481012658, -1.404696551724138 ], [ 29.777278481012658, -1.404427068965517 ], [ 29.777008987341773, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3112, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.404427068965517 ], [ 29.777278481012658, -1.404696551724138 ], [ 29.777547974683543, -1.404696551724138 ], [ 29.777547974683543, -1.404427068965517 ], [ 29.777278481012658, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3113, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.404427068965517 ], [ 29.777547974683543, -1.404696551724138 ], [ 29.777817468354431, -1.404696551724138 ], [ 29.777817468354431, -1.404427068965517 ], [ 29.777547974683543, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3114, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.404427068965517 ], [ 29.777817468354431, -1.404696551724138 ], [ 29.778086962025316, -1.404696551724138 ], [ 29.778086962025316, -1.404427068965517 ], [ 29.777817468354431, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3115, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.404427068965517 ], [ 29.778086962025316, -1.404696551724138 ], [ 29.778356455696201, -1.404696551724138 ], [ 29.778356455696201, -1.404427068965517 ], [ 29.778086962025316, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3116, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.404427068965517 ], [ 29.778356455696201, -1.404696551724138 ], [ 29.77862594936709, -1.404696551724138 ], [ 29.77862594936709, -1.404427068965517 ], [ 29.778356455696201, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3117, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.404427068965517 ], [ 29.77862594936709, -1.404696551724138 ], [ 29.778895443037975, -1.404696551724138 ], [ 29.778895443037975, -1.404427068965517 ], [ 29.77862594936709, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3118, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.404427068965517 ], [ 29.778895443037975, -1.404696551724138 ], [ 29.77916493670886, -1.404696551724138 ], [ 29.77916493670886, -1.404427068965517 ], [ 29.778895443037975, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3119, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.404427068965517 ], [ 29.77916493670886, -1.404696551724138 ], [ 29.779434430379748, -1.404696551724138 ], [ 29.779434430379748, -1.404427068965517 ], [ 29.77916493670886, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3120, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.404427068965517 ], [ 29.779434430379748, -1.404696551724138 ], [ 29.779703924050633, -1.404696551724138 ], [ 29.779703924050633, -1.404427068965517 ], [ 29.779434430379748, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3121, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.404427068965517 ], [ 29.779703924050633, -1.404696551724138 ], [ 29.779973417721518, -1.404696551724138 ], [ 29.779973417721518, -1.404427068965517 ], [ 29.779703924050633, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3122, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.404427068965517 ], [ 29.779973417721518, -1.404696551724138 ], [ 29.780242911392406, -1.404696551724138 ], [ 29.780242911392406, -1.404427068965517 ], [ 29.779973417721518, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3123, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.404427068965517 ], [ 29.780242911392406, -1.404696551724138 ], [ 29.780512405063291, -1.404696551724138 ], [ 29.780512405063291, -1.404427068965517 ], [ 29.780242911392406, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3124, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.404427068965517 ], [ 29.780512405063291, -1.404696551724138 ], [ 29.780781898734176, -1.404696551724138 ], [ 29.780781898734176, -1.404427068965517 ], [ 29.780512405063291, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3125, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.404427068965517 ], [ 29.780781898734176, -1.404696551724138 ], [ 29.781051392405065, -1.404696551724138 ], [ 29.781051392405065, -1.404427068965517 ], [ 29.780781898734176, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3126, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.404427068965517 ], [ 29.781051392405065, -1.404696551724138 ], [ 29.78132088607595, -1.404696551724138 ], [ 29.78132088607595, -1.404427068965517 ], [ 29.781051392405065, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3127, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.404427068965517 ], [ 29.78132088607595, -1.404696551724138 ], [ 29.781590379746834, -1.404696551724138 ], [ 29.781590379746834, -1.404427068965517 ], [ 29.78132088607595, -1.404427068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3128, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.404696551724138 ], [ 29.764612278481014, -1.404966034482759 ], [ 29.764881772151899, -1.404966034482759 ], [ 29.764881772151899, -1.404696551724138 ], [ 29.764612278481014, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3129, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.404696551724138 ], [ 29.764881772151899, -1.404966034482759 ], [ 29.765151265822784, -1.404966034482759 ], [ 29.765151265822784, -1.404696551724138 ], [ 29.764881772151899, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3130, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.404696551724138 ], [ 29.765151265822784, -1.404966034482759 ], [ 29.765420759493672, -1.404966034482759 ], [ 29.765420759493672, -1.404696551724138 ], [ 29.765151265822784, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3131, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.404696551724138 ], [ 29.765420759493672, -1.404966034482759 ], [ 29.765959746835442, -1.404966034482759 ], [ 29.765959746835442, -1.404696551724138 ], [ 29.765420759493672, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3132, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.404696551724138 ], [ 29.765959746835442, -1.404966034482759 ], [ 29.766229240506327, -1.404966034482759 ], [ 29.766229240506327, -1.404696551724138 ], [ 29.765959746835442, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3133, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.404696551724138 ], [ 29.766229240506327, -1.404966034482759 ], [ 29.766498734177215, -1.404966034482759 ], [ 29.766498734177215, -1.404696551724138 ], [ 29.766229240506327, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3134, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.404696551724138 ], [ 29.766498734177215, -1.404966034482759 ], [ 29.7667682278481, -1.404966034482759 ], [ 29.7667682278481, -1.404696551724138 ], [ 29.766498734177215, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3135, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.404696551724138 ], [ 29.7667682278481, -1.404966034482759 ], [ 29.767037721518985, -1.404966034482759 ], [ 29.767037721518985, -1.404696551724138 ], [ 29.7667682278481, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3136, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.404696551724138 ], [ 29.767037721518985, -1.404966034482759 ], [ 29.767307215189874, -1.404966034482759 ], [ 29.767307215189874, -1.404696551724138 ], [ 29.767037721518985, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3137, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.404696551724138 ], [ 29.767307215189874, -1.404966034482759 ], [ 29.767576708860759, -1.404966034482759 ], [ 29.767576708860759, -1.404696551724138 ], [ 29.767307215189874, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3138, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.404696551724138 ], [ 29.767576708860759, -1.404966034482759 ], [ 29.767846202531643, -1.404966034482759 ], [ 29.767846202531643, -1.404696551724138 ], [ 29.767576708860759, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3139, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.404696551724138 ], [ 29.767846202531643, -1.404966034482759 ], [ 29.768115696202532, -1.404966034482759 ], [ 29.768115696202532, -1.404696551724138 ], [ 29.767846202531643, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3140, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.404696551724138 ], [ 29.768115696202532, -1.404966034482759 ], [ 29.768385189873417, -1.404966034482759 ], [ 29.768385189873417, -1.404696551724138 ], [ 29.768115696202532, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3141, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.404696551724138 ], [ 29.768385189873417, -1.404966034482759 ], [ 29.768654683544302, -1.404966034482759 ], [ 29.768654683544302, -1.404696551724138 ], [ 29.768385189873417, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3142, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.404696551724138 ], [ 29.768654683544302, -1.404966034482759 ], [ 29.76892417721519, -1.404966034482759 ], [ 29.76892417721519, -1.404696551724138 ], [ 29.768654683544302, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3143, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.404696551724138 ], [ 29.76892417721519, -1.404966034482759 ], [ 29.769193670886075, -1.404966034482759 ], [ 29.769193670886075, -1.404696551724138 ], [ 29.76892417721519, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3144, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.404696551724138 ], [ 29.769193670886075, -1.404966034482759 ], [ 29.76946316455696, -1.404966034482759 ], [ 29.76946316455696, -1.404696551724138 ], [ 29.769193670886075, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3145, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.404696551724138 ], [ 29.76946316455696, -1.404966034482759 ], [ 29.769732658227849, -1.404966034482759 ], [ 29.769732658227849, -1.404696551724138 ], [ 29.76946316455696, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3146, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.404696551724138 ], [ 29.769732658227849, -1.404966034482759 ], [ 29.770002151898733, -1.404966034482759 ], [ 29.770002151898733, -1.404696551724138 ], [ 29.769732658227849, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3147, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.404696551724138 ], [ 29.770002151898733, -1.404966034482759 ], [ 29.770271645569618, -1.404966034482759 ], [ 29.770271645569618, -1.404696551724138 ], [ 29.770002151898733, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3148, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.404696551724138 ], [ 29.770271645569618, -1.404966034482759 ], [ 29.770541139240507, -1.404966034482759 ], [ 29.770541139240507, -1.404696551724138 ], [ 29.770271645569618, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3149, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.404696551724138 ], [ 29.770541139240507, -1.404966034482759 ], [ 29.770810632911392, -1.404966034482759 ], [ 29.770810632911392, -1.404696551724138 ], [ 29.770541139240507, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3150, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.404696551724138 ], [ 29.770810632911392, -1.404966034482759 ], [ 29.771080126582277, -1.404966034482759 ], [ 29.771080126582277, -1.404696551724138 ], [ 29.770810632911392, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3151, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.404696551724138 ], [ 29.771080126582277, -1.404966034482759 ], [ 29.771349620253165, -1.404966034482759 ], [ 29.771349620253165, -1.404696551724138 ], [ 29.771080126582277, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3152, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.404696551724138 ], [ 29.771349620253165, -1.404966034482759 ], [ 29.77161911392405, -1.404966034482759 ], [ 29.77161911392405, -1.404696551724138 ], [ 29.771349620253165, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3153, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.404696551724138 ], [ 29.77161911392405, -1.404966034482759 ], [ 29.771888607594935, -1.404966034482759 ], [ 29.771888607594935, -1.404696551724138 ], [ 29.77161911392405, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3154, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.404696551724138 ], [ 29.771888607594935, -1.404966034482759 ], [ 29.772158101265823, -1.404966034482759 ], [ 29.772158101265823, -1.404696551724138 ], [ 29.771888607594935, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3155, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.404696551724138 ], [ 29.772158101265823, -1.404966034482759 ], [ 29.772427594936708, -1.404966034482759 ], [ 29.772427594936708, -1.404696551724138 ], [ 29.772158101265823, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3156, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.404696551724138 ], [ 29.772427594936708, -1.404966034482759 ], [ 29.772697088607593, -1.404966034482759 ], [ 29.772697088607593, -1.404696551724138 ], [ 29.772427594936708, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3157, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.404696551724138 ], [ 29.772697088607593, -1.404966034482759 ], [ 29.772966582278482, -1.404966034482759 ], [ 29.772966582278482, -1.404696551724138 ], [ 29.772697088607593, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3158, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.404696551724138 ], [ 29.772966582278482, -1.404966034482759 ], [ 29.773236075949367, -1.404966034482759 ], [ 29.773236075949367, -1.404696551724138 ], [ 29.772966582278482, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3159, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.404696551724138 ], [ 29.773236075949367, -1.404966034482759 ], [ 29.773505569620252, -1.404966034482759 ], [ 29.773505569620252, -1.404696551724138 ], [ 29.773236075949367, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3160, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.404696551724138 ], [ 29.773505569620252, -1.404966034482759 ], [ 29.77377506329114, -1.404966034482759 ], [ 29.77377506329114, -1.404696551724138 ], [ 29.773505569620252, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3161, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.404696551724138 ], [ 29.774044556962025, -1.404966034482759 ], [ 29.77431405063291, -1.404966034482759 ], [ 29.77431405063291, -1.404696551724138 ], [ 29.774044556962025, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3162, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.404696551724138 ], [ 29.77431405063291, -1.404966034482759 ], [ 29.774853037974683, -1.404966034482759 ], [ 29.774853037974683, -1.404696551724138 ], [ 29.77431405063291, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3163, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.404696551724138 ], [ 29.775122531645568, -1.404966034482759 ], [ 29.775392025316457, -1.404966034482759 ], [ 29.775392025316457, -1.404696551724138 ], [ 29.775122531645568, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3164, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.404696551724138 ], [ 29.775392025316457, -1.404966034482759 ], [ 29.775661518987341, -1.404966034482759 ], [ 29.775661518987341, -1.404696551724138 ], [ 29.775392025316457, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3165, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.404696551724138 ], [ 29.775661518987341, -1.404966034482759 ], [ 29.775931012658226, -1.404966034482759 ], [ 29.775931012658226, -1.404696551724138 ], [ 29.775661518987341, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3166, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.404696551724138 ], [ 29.776200506329115, -1.404966034482759 ], [ 29.77647, -1.404966034482759 ], [ 29.77647, -1.404696551724138 ], [ 29.776200506329115, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3167, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.404696551724138 ], [ 29.77647, -1.404966034482759 ], [ 29.776739493670885, -1.404966034482759 ], [ 29.776739493670885, -1.404696551724138 ], [ 29.77647, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3168, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.404696551724138 ], [ 29.776739493670885, -1.404966034482759 ], [ 29.777008987341773, -1.404966034482759 ], [ 29.777008987341773, -1.404696551724138 ], [ 29.776739493670885, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3169, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.404696551724138 ], [ 29.777008987341773, -1.404966034482759 ], [ 29.777278481012658, -1.404966034482759 ], [ 29.777278481012658, -1.404696551724138 ], [ 29.777008987341773, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3170, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.404696551724138 ], [ 29.777278481012658, -1.404966034482759 ], [ 29.777547974683543, -1.404966034482759 ], [ 29.777547974683543, -1.404696551724138 ], [ 29.777278481012658, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3171, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.404696551724138 ], [ 29.777547974683543, -1.404966034482759 ], [ 29.777817468354431, -1.404966034482759 ], [ 29.777817468354431, -1.404696551724138 ], [ 29.777547974683543, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3172, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.404696551724138 ], [ 29.777817468354431, -1.404966034482759 ], [ 29.778086962025316, -1.404966034482759 ], [ 29.778086962025316, -1.404696551724138 ], [ 29.777817468354431, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3173, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.404696551724138 ], [ 29.778086962025316, -1.404966034482759 ], [ 29.778356455696201, -1.404966034482759 ], [ 29.778356455696201, -1.404696551724138 ], [ 29.778086962025316, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3174, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.404696551724138 ], [ 29.778356455696201, -1.404966034482759 ], [ 29.77862594936709, -1.404966034482759 ], [ 29.77862594936709, -1.404696551724138 ], [ 29.778356455696201, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3175, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.404696551724138 ], [ 29.77862594936709, -1.404966034482759 ], [ 29.778895443037975, -1.404966034482759 ], [ 29.778895443037975, -1.404696551724138 ], [ 29.77862594936709, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3176, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.404696551724138 ], [ 29.778895443037975, -1.404966034482759 ], [ 29.77916493670886, -1.404966034482759 ], [ 29.77916493670886, -1.404696551724138 ], [ 29.778895443037975, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3177, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.404696551724138 ], [ 29.77916493670886, -1.404966034482759 ], [ 29.779434430379748, -1.404966034482759 ], [ 29.779434430379748, -1.404696551724138 ], [ 29.77916493670886, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3178, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.404696551724138 ], [ 29.779434430379748, -1.404966034482759 ], [ 29.779703924050633, -1.404966034482759 ], [ 29.779703924050633, -1.404696551724138 ], [ 29.779434430379748, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3179, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.404696551724138 ], [ 29.779703924050633, -1.404966034482759 ], [ 29.779973417721518, -1.404966034482759 ], [ 29.779973417721518, -1.404696551724138 ], [ 29.779703924050633, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3180, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.404696551724138 ], [ 29.779973417721518, -1.404966034482759 ], [ 29.780242911392406, -1.404966034482759 ], [ 29.780242911392406, -1.404696551724138 ], [ 29.779973417721518, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3181, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.404696551724138 ], [ 29.780242911392406, -1.404966034482759 ], [ 29.780512405063291, -1.404966034482759 ], [ 29.780512405063291, -1.404696551724138 ], [ 29.780242911392406, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3182, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.404696551724138 ], [ 29.780512405063291, -1.404966034482759 ], [ 29.780781898734176, -1.404966034482759 ], [ 29.780781898734176, -1.404696551724138 ], [ 29.780512405063291, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3183, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.404696551724138 ], [ 29.780781898734176, -1.404966034482759 ], [ 29.781051392405065, -1.404966034482759 ], [ 29.781051392405065, -1.404696551724138 ], [ 29.780781898734176, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3184, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.404696551724138 ], [ 29.781051392405065, -1.404966034482759 ], [ 29.78132088607595, -1.404966034482759 ], [ 29.78132088607595, -1.404696551724138 ], [ 29.781051392405065, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3185, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.404696551724138 ], [ 29.78132088607595, -1.404966034482759 ], [ 29.781590379746834, -1.404966034482759 ], [ 29.781590379746834, -1.404696551724138 ], [ 29.78132088607595, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3186, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.404696551724138 ], [ 29.781590379746834, -1.404966034482759 ], [ 29.781859873417723, -1.404966034482759 ], [ 29.781859873417723, -1.404696551724138 ], [ 29.781590379746834, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3187, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.404696551724138 ], [ 29.774853037974683, -1.405235517241379 ], [ 29.775122531645568, -1.405235517241379 ], [ 29.775122531645568, -1.404696551724138 ], [ 29.774853037974683, -1.404696551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3188, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.404966034482759 ], [ 29.76407329113924, -1.405505 ], [ 29.764342784810125, -1.405505 ], [ 29.764342784810125, -1.404966034482759 ], [ 29.76407329113924, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3189, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.404966034482759 ], [ 29.764342784810125, -1.405235517241379 ], [ 29.764612278481014, -1.405235517241379 ], [ 29.764612278481014, -1.404966034482759 ], [ 29.764342784810125, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3190, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.404966034482759 ], [ 29.764612278481014, -1.405235517241379 ], [ 29.764881772151899, -1.405235517241379 ], [ 29.764881772151899, -1.404966034482759 ], [ 29.764612278481014, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3191, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.404966034482759 ], [ 29.764881772151899, -1.405235517241379 ], [ 29.765151265822784, -1.405235517241379 ], [ 29.765151265822784, -1.404966034482759 ], [ 29.764881772151899, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3192, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.404966034482759 ], [ 29.765151265822784, -1.405235517241379 ], [ 29.765420759493672, -1.405235517241379 ], [ 29.765420759493672, -1.404966034482759 ], [ 29.765151265822784, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3193, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.404966034482759 ], [ 29.765420759493672, -1.405235517241379 ], [ 29.765959746835442, -1.405235517241379 ], [ 29.765959746835442, -1.404966034482759 ], [ 29.765420759493672, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3194, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.404966034482759 ], [ 29.765959746835442, -1.405235517241379 ], [ 29.766229240506327, -1.405235517241379 ], [ 29.766229240506327, -1.404966034482759 ], [ 29.765959746835442, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3195, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.404966034482759 ], [ 29.766229240506327, -1.405235517241379 ], [ 29.766498734177215, -1.405235517241379 ], [ 29.766498734177215, -1.404966034482759 ], [ 29.766229240506327, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3196, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.404966034482759 ], [ 29.766498734177215, -1.405235517241379 ], [ 29.7667682278481, -1.405235517241379 ], [ 29.7667682278481, -1.404966034482759 ], [ 29.766498734177215, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3197, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.404966034482759 ], [ 29.7667682278481, -1.405235517241379 ], [ 29.767037721518985, -1.405235517241379 ], [ 29.767037721518985, -1.404966034482759 ], [ 29.7667682278481, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3198, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.404966034482759 ], [ 29.767037721518985, -1.405235517241379 ], [ 29.767307215189874, -1.405235517241379 ], [ 29.767307215189874, -1.404966034482759 ], [ 29.767037721518985, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3199, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.404966034482759 ], [ 29.767307215189874, -1.405235517241379 ], [ 29.767576708860759, -1.405235517241379 ], [ 29.767576708860759, -1.404966034482759 ], [ 29.767307215189874, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3200, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.404966034482759 ], [ 29.767576708860759, -1.405235517241379 ], [ 29.767846202531643, -1.405235517241379 ], [ 29.767846202531643, -1.404966034482759 ], [ 29.767576708860759, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3201, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.404966034482759 ], [ 29.767846202531643, -1.405235517241379 ], [ 29.768115696202532, -1.405235517241379 ], [ 29.768115696202532, -1.404966034482759 ], [ 29.767846202531643, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3202, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.404966034482759 ], [ 29.768115696202532, -1.405235517241379 ], [ 29.768385189873417, -1.405235517241379 ], [ 29.768385189873417, -1.404966034482759 ], [ 29.768115696202532, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3203, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.404966034482759 ], [ 29.768385189873417, -1.405235517241379 ], [ 29.768654683544302, -1.405235517241379 ], [ 29.768654683544302, -1.404966034482759 ], [ 29.768385189873417, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3204, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.404966034482759 ], [ 29.768654683544302, -1.405235517241379 ], [ 29.76892417721519, -1.405235517241379 ], [ 29.76892417721519, -1.404966034482759 ], [ 29.768654683544302, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3205, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.404966034482759 ], [ 29.76892417721519, -1.405235517241379 ], [ 29.769193670886075, -1.405235517241379 ], [ 29.769193670886075, -1.404966034482759 ], [ 29.76892417721519, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3206, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.404966034482759 ], [ 29.769193670886075, -1.405235517241379 ], [ 29.76946316455696, -1.405235517241379 ], [ 29.76946316455696, -1.404966034482759 ], [ 29.769193670886075, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3207, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.404966034482759 ], [ 29.76946316455696, -1.405235517241379 ], [ 29.769732658227849, -1.405235517241379 ], [ 29.769732658227849, -1.404966034482759 ], [ 29.76946316455696, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3208, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.404966034482759 ], [ 29.769732658227849, -1.405235517241379 ], [ 29.770002151898733, -1.405235517241379 ], [ 29.770002151898733, -1.404966034482759 ], [ 29.769732658227849, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3209, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.404966034482759 ], [ 29.770002151898733, -1.405235517241379 ], [ 29.770271645569618, -1.405235517241379 ], [ 29.770271645569618, -1.404966034482759 ], [ 29.770002151898733, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3210, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.404966034482759 ], [ 29.770271645569618, -1.405235517241379 ], [ 29.770541139240507, -1.405235517241379 ], [ 29.770541139240507, -1.404966034482759 ], [ 29.770271645569618, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3211, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.404966034482759 ], [ 29.770541139240507, -1.405235517241379 ], [ 29.770810632911392, -1.405235517241379 ], [ 29.770810632911392, -1.404966034482759 ], [ 29.770541139240507, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3212, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.404966034482759 ], [ 29.770810632911392, -1.405235517241379 ], [ 29.771080126582277, -1.405235517241379 ], [ 29.771080126582277, -1.404966034482759 ], [ 29.770810632911392, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3213, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.404966034482759 ], [ 29.771080126582277, -1.405235517241379 ], [ 29.771349620253165, -1.405235517241379 ], [ 29.771349620253165, -1.404966034482759 ], [ 29.771080126582277, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3214, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.404966034482759 ], [ 29.771349620253165, -1.405235517241379 ], [ 29.77161911392405, -1.405235517241379 ], [ 29.77161911392405, -1.404966034482759 ], [ 29.771349620253165, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3215, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.404966034482759 ], [ 29.77161911392405, -1.405235517241379 ], [ 29.771888607594935, -1.405235517241379 ], [ 29.771888607594935, -1.404966034482759 ], [ 29.77161911392405, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3216, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.404966034482759 ], [ 29.771888607594935, -1.405235517241379 ], [ 29.772158101265823, -1.405235517241379 ], [ 29.772158101265823, -1.404966034482759 ], [ 29.771888607594935, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3217, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.404966034482759 ], [ 29.772158101265823, -1.405235517241379 ], [ 29.772427594936708, -1.405235517241379 ], [ 29.772427594936708, -1.404966034482759 ], [ 29.772158101265823, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3218, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.404966034482759 ], [ 29.772427594936708, -1.405235517241379 ], [ 29.772697088607593, -1.405235517241379 ], [ 29.772697088607593, -1.404966034482759 ], [ 29.772427594936708, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3219, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.404966034482759 ], [ 29.772697088607593, -1.405235517241379 ], [ 29.772966582278482, -1.405235517241379 ], [ 29.772966582278482, -1.404966034482759 ], [ 29.772697088607593, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3220, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.404966034482759 ], [ 29.772966582278482, -1.405235517241379 ], [ 29.773236075949367, -1.405235517241379 ], [ 29.773236075949367, -1.404966034482759 ], [ 29.772966582278482, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3221, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.404966034482759 ], [ 29.773236075949367, -1.405235517241379 ], [ 29.773505569620252, -1.405235517241379 ], [ 29.773505569620252, -1.404966034482759 ], [ 29.773236075949367, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3222, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.404966034482759 ], [ 29.773505569620252, -1.405505 ], [ 29.77377506329114, -1.405505 ], [ 29.77377506329114, -1.404966034482759 ], [ 29.773505569620252, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3223, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.404966034482759 ], [ 29.77377506329114, -1.405235517241379 ], [ 29.774044556962025, -1.405235517241379 ], [ 29.774044556962025, -1.404966034482759 ], [ 29.77377506329114, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3224, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.404966034482759 ], [ 29.774044556962025, -1.405235517241379 ], [ 29.77431405063291, -1.405235517241379 ], [ 29.77431405063291, -1.404966034482759 ], [ 29.774044556962025, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3225, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.404966034482759 ], [ 29.77431405063291, -1.405235517241379 ], [ 29.774853037974683, -1.405235517241379 ], [ 29.774853037974683, -1.404966034482759 ], [ 29.77431405063291, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3226, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.404966034482759 ], [ 29.775122531645568, -1.405235517241379 ], [ 29.775392025316457, -1.405235517241379 ], [ 29.775392025316457, -1.404966034482759 ], [ 29.775122531645568, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3227, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.404966034482759 ], [ 29.775392025316457, -1.405235517241379 ], [ 29.775661518987341, -1.405235517241379 ], [ 29.775661518987341, -1.404966034482759 ], [ 29.775392025316457, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3228, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.404966034482759 ], [ 29.775661518987341, -1.405235517241379 ], [ 29.775931012658226, -1.405235517241379 ], [ 29.775931012658226, -1.404966034482759 ], [ 29.775661518987341, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3229, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.404966034482759 ], [ 29.775931012658226, -1.405235517241379 ], [ 29.776200506329115, -1.405235517241379 ], [ 29.776200506329115, -1.404966034482759 ], [ 29.775931012658226, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3230, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.404966034482759 ], [ 29.776200506329115, -1.405235517241379 ], [ 29.77647, -1.405235517241379 ], [ 29.77647, -1.404966034482759 ], [ 29.776200506329115, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3231, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.404966034482759 ], [ 29.77647, -1.405235517241379 ], [ 29.776739493670885, -1.405235517241379 ], [ 29.776739493670885, -1.404966034482759 ], [ 29.77647, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3232, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.404966034482759 ], [ 29.776739493670885, -1.405235517241379 ], [ 29.777008987341773, -1.405235517241379 ], [ 29.777008987341773, -1.404966034482759 ], [ 29.776739493670885, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3233, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.404966034482759 ], [ 29.777008987341773, -1.405235517241379 ], [ 29.777278481012658, -1.405235517241379 ], [ 29.777278481012658, -1.404966034482759 ], [ 29.777008987341773, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3234, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.404966034482759 ], [ 29.777278481012658, -1.405235517241379 ], [ 29.777547974683543, -1.405235517241379 ], [ 29.777547974683543, -1.404966034482759 ], [ 29.777278481012658, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3235, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.404966034482759 ], [ 29.777547974683543, -1.405235517241379 ], [ 29.777817468354431, -1.405235517241379 ], [ 29.777817468354431, -1.404966034482759 ], [ 29.777547974683543, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3236, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.404966034482759 ], [ 29.777817468354431, -1.405235517241379 ], [ 29.778086962025316, -1.405235517241379 ], [ 29.778086962025316, -1.404966034482759 ], [ 29.777817468354431, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3237, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.404966034482759 ], [ 29.778086962025316, -1.405235517241379 ], [ 29.778356455696201, -1.405235517241379 ], [ 29.778356455696201, -1.404966034482759 ], [ 29.778086962025316, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3238, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.404966034482759 ], [ 29.778356455696201, -1.405235517241379 ], [ 29.77862594936709, -1.405235517241379 ], [ 29.77862594936709, -1.404966034482759 ], [ 29.778356455696201, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3239, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.404966034482759 ], [ 29.77862594936709, -1.405235517241379 ], [ 29.778895443037975, -1.405235517241379 ], [ 29.778895443037975, -1.404966034482759 ], [ 29.77862594936709, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3240, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.404966034482759 ], [ 29.778895443037975, -1.405235517241379 ], [ 29.77916493670886, -1.405235517241379 ], [ 29.77916493670886, -1.404966034482759 ], [ 29.778895443037975, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3241, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.404966034482759 ], [ 29.77916493670886, -1.405235517241379 ], [ 29.779434430379748, -1.405235517241379 ], [ 29.779434430379748, -1.404966034482759 ], [ 29.77916493670886, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3242, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.404966034482759 ], [ 29.779434430379748, -1.405235517241379 ], [ 29.779703924050633, -1.405235517241379 ], [ 29.779703924050633, -1.404966034482759 ], [ 29.779434430379748, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3243, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.404966034482759 ], [ 29.779703924050633, -1.405235517241379 ], [ 29.779973417721518, -1.405235517241379 ], [ 29.779973417721518, -1.404966034482759 ], [ 29.779703924050633, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3244, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.404966034482759 ], [ 29.779973417721518, -1.405235517241379 ], [ 29.780242911392406, -1.405235517241379 ], [ 29.780242911392406, -1.404966034482759 ], [ 29.779973417721518, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3245, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.404966034482759 ], [ 29.780242911392406, -1.405235517241379 ], [ 29.780512405063291, -1.405235517241379 ], [ 29.780512405063291, -1.404966034482759 ], [ 29.780242911392406, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3246, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.404966034482759 ], [ 29.780512405063291, -1.405235517241379 ], [ 29.780781898734176, -1.405235517241379 ], [ 29.780781898734176, -1.404966034482759 ], [ 29.780512405063291, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3247, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.404966034482759 ], [ 29.780781898734176, -1.405235517241379 ], [ 29.781051392405065, -1.405235517241379 ], [ 29.781051392405065, -1.404966034482759 ], [ 29.780781898734176, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3248, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.404966034482759 ], [ 29.781051392405065, -1.405235517241379 ], [ 29.78132088607595, -1.405235517241379 ], [ 29.78132088607595, -1.404966034482759 ], [ 29.781051392405065, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3249, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.404966034482759 ], [ 29.78132088607595, -1.405505 ], [ 29.781590379746834, -1.405505 ], [ 29.781590379746834, -1.404966034482759 ], [ 29.78132088607595, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3250, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.404966034482759 ], [ 29.781590379746834, -1.405505 ], [ 29.781859873417723, -1.405505 ], [ 29.781859873417723, -1.404966034482759 ], [ 29.781590379746834, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3251, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.404966034482759 ], [ 29.781859873417723, -1.405505 ], [ 29.782129367088608, -1.405505 ], [ 29.782129367088608, -1.404966034482759 ], [ 29.781859873417723, -1.404966034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3252, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.405235517241379 ], [ 29.764342784810125, -1.405505 ], [ 29.764612278481014, -1.405505 ], [ 29.764612278481014, -1.405235517241379 ], [ 29.764342784810125, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3253, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.405235517241379 ], [ 29.764612278481014, -1.405505 ], [ 29.764881772151899, -1.405505 ], [ 29.764881772151899, -1.405235517241379 ], [ 29.764612278481014, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3254, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.405235517241379 ], [ 29.764881772151899, -1.405505 ], [ 29.765151265822784, -1.405505 ], [ 29.765151265822784, -1.405235517241379 ], [ 29.764881772151899, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3255, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.405235517241379 ], [ 29.765151265822784, -1.405505 ], [ 29.765420759493672, -1.405505 ], [ 29.765420759493672, -1.405235517241379 ], [ 29.765151265822784, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3256, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.405235517241379 ], [ 29.765420759493672, -1.405505 ], [ 29.765959746835442, -1.405505 ], [ 29.765959746835442, -1.405235517241379 ], [ 29.765420759493672, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3257, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.405235517241379 ], [ 29.765959746835442, -1.405505 ], [ 29.766229240506327, -1.405505 ], [ 29.766229240506327, -1.405235517241379 ], [ 29.765959746835442, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3258, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.405235517241379 ], [ 29.766229240506327, -1.405505 ], [ 29.766498734177215, -1.405505 ], [ 29.766498734177215, -1.405235517241379 ], [ 29.766229240506327, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3259, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.405235517241379 ], [ 29.766498734177215, -1.405505 ], [ 29.7667682278481, -1.405505 ], [ 29.7667682278481, -1.405235517241379 ], [ 29.766498734177215, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3260, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.405235517241379 ], [ 29.7667682278481, -1.405505 ], [ 29.767037721518985, -1.405505 ], [ 29.767037721518985, -1.405235517241379 ], [ 29.7667682278481, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3261, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.405235517241379 ], [ 29.767037721518985, -1.405505 ], [ 29.767307215189874, -1.405505 ], [ 29.767307215189874, -1.405235517241379 ], [ 29.767037721518985, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3262, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.405235517241379 ], [ 29.767307215189874, -1.405505 ], [ 29.767576708860759, -1.405505 ], [ 29.767576708860759, -1.405235517241379 ], [ 29.767307215189874, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3263, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.405235517241379 ], [ 29.767576708860759, -1.405505 ], [ 29.767846202531643, -1.405505 ], [ 29.767846202531643, -1.405235517241379 ], [ 29.767576708860759, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3264, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.405235517241379 ], [ 29.767846202531643, -1.405505 ], [ 29.768115696202532, -1.405505 ], [ 29.768115696202532, -1.405235517241379 ], [ 29.767846202531643, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3265, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.405235517241379 ], [ 29.768115696202532, -1.405505 ], [ 29.768385189873417, -1.405505 ], [ 29.768385189873417, -1.405235517241379 ], [ 29.768115696202532, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3266, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.405235517241379 ], [ 29.768385189873417, -1.405505 ], [ 29.768654683544302, -1.405505 ], [ 29.768654683544302, -1.405235517241379 ], [ 29.768385189873417, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3267, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.405235517241379 ], [ 29.768654683544302, -1.405505 ], [ 29.76892417721519, -1.405505 ], [ 29.76892417721519, -1.405235517241379 ], [ 29.768654683544302, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3268, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.405235517241379 ], [ 29.76892417721519, -1.405505 ], [ 29.769193670886075, -1.405505 ], [ 29.769193670886075, -1.405235517241379 ], [ 29.76892417721519, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3269, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.405235517241379 ], [ 29.769193670886075, -1.405505 ], [ 29.76946316455696, -1.405505 ], [ 29.76946316455696, -1.405235517241379 ], [ 29.769193670886075, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3270, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.405235517241379 ], [ 29.76946316455696, -1.405505 ], [ 29.769732658227849, -1.405505 ], [ 29.769732658227849, -1.405235517241379 ], [ 29.76946316455696, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3271, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.405235517241379 ], [ 29.769732658227849, -1.405505 ], [ 29.770002151898733, -1.405505 ], [ 29.770002151898733, -1.405235517241379 ], [ 29.769732658227849, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3272, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.405235517241379 ], [ 29.770002151898733, -1.405505 ], [ 29.770271645569618, -1.405505 ], [ 29.770271645569618, -1.405235517241379 ], [ 29.770002151898733, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3273, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.405235517241379 ], [ 29.770271645569618, -1.405505 ], [ 29.770541139240507, -1.405505 ], [ 29.770541139240507, -1.405235517241379 ], [ 29.770271645569618, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3274, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.405235517241379 ], [ 29.770541139240507, -1.405505 ], [ 29.770810632911392, -1.405505 ], [ 29.770810632911392, -1.405235517241379 ], [ 29.770541139240507, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3275, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.405235517241379 ], [ 29.770810632911392, -1.405505 ], [ 29.771080126582277, -1.405505 ], [ 29.771080126582277, -1.405235517241379 ], [ 29.770810632911392, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3276, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.405235517241379 ], [ 29.771080126582277, -1.405505 ], [ 29.771349620253165, -1.405505 ], [ 29.771349620253165, -1.405235517241379 ], [ 29.771080126582277, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3277, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.405235517241379 ], [ 29.771349620253165, -1.405505 ], [ 29.77161911392405, -1.405505 ], [ 29.77161911392405, -1.405235517241379 ], [ 29.771349620253165, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3278, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.405235517241379 ], [ 29.77161911392405, -1.405505 ], [ 29.771888607594935, -1.405505 ], [ 29.771888607594935, -1.405235517241379 ], [ 29.77161911392405, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3279, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.405235517241379 ], [ 29.771888607594935, -1.405505 ], [ 29.772158101265823, -1.405505 ], [ 29.772158101265823, -1.405235517241379 ], [ 29.771888607594935, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3280, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.405235517241379 ], [ 29.772158101265823, -1.405505 ], [ 29.772427594936708, -1.405505 ], [ 29.772427594936708, -1.405235517241379 ], [ 29.772158101265823, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3281, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.405235517241379 ], [ 29.772427594936708, -1.405505 ], [ 29.772697088607593, -1.405505 ], [ 29.772697088607593, -1.405235517241379 ], [ 29.772427594936708, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3282, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.405235517241379 ], [ 29.772697088607593, -1.405505 ], [ 29.772966582278482, -1.405505 ], [ 29.772966582278482, -1.405235517241379 ], [ 29.772697088607593, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3283, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.405235517241379 ], [ 29.772966582278482, -1.405505 ], [ 29.773236075949367, -1.405505 ], [ 29.773236075949367, -1.405235517241379 ], [ 29.772966582278482, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3284, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.405235517241379 ], [ 29.773236075949367, -1.405505 ], [ 29.773505569620252, -1.405505 ], [ 29.773505569620252, -1.405235517241379 ], [ 29.773236075949367, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3285, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.405235517241379 ], [ 29.77377506329114, -1.405505 ], [ 29.774044556962025, -1.405505 ], [ 29.774044556962025, -1.405235517241379 ], [ 29.77377506329114, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3286, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.405235517241379 ], [ 29.774044556962025, -1.405505 ], [ 29.77431405063291, -1.405505 ], [ 29.77431405063291, -1.405235517241379 ], [ 29.774044556962025, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3287, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.405235517241379 ], [ 29.77431405063291, -1.405505 ], [ 29.774853037974683, -1.405505 ], [ 29.774853037974683, -1.405235517241379 ], [ 29.77431405063291, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3288, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.405235517241379 ], [ 29.774853037974683, -1.405505 ], [ 29.775122531645568, -1.405505 ], [ 29.775122531645568, -1.405235517241379 ], [ 29.774853037974683, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3289, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.405235517241379 ], [ 29.775122531645568, -1.405505 ], [ 29.775392025316457, -1.405505 ], [ 29.775392025316457, -1.405235517241379 ], [ 29.775122531645568, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3290, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.405235517241379 ], [ 29.775392025316457, -1.405505 ], [ 29.775661518987341, -1.405505 ], [ 29.775661518987341, -1.405235517241379 ], [ 29.775392025316457, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3291, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.405235517241379 ], [ 29.775661518987341, -1.405505 ], [ 29.775931012658226, -1.405505 ], [ 29.775931012658226, -1.405235517241379 ], [ 29.775661518987341, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3292, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.405235517241379 ], [ 29.775931012658226, -1.405505 ], [ 29.776200506329115, -1.405505 ], [ 29.776200506329115, -1.405235517241379 ], [ 29.775931012658226, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3293, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.405235517241379 ], [ 29.776200506329115, -1.405505 ], [ 29.77647, -1.405505 ], [ 29.77647, -1.405235517241379 ], [ 29.776200506329115, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3294, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.405235517241379 ], [ 29.77647, -1.405505 ], [ 29.776739493670885, -1.405505 ], [ 29.776739493670885, -1.405235517241379 ], [ 29.77647, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3295, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.405235517241379 ], [ 29.776739493670885, -1.405505 ], [ 29.777008987341773, -1.405505 ], [ 29.777008987341773, -1.405235517241379 ], [ 29.776739493670885, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3296, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.405235517241379 ], [ 29.777008987341773, -1.405505 ], [ 29.777278481012658, -1.405505 ], [ 29.777278481012658, -1.405235517241379 ], [ 29.777008987341773, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3297, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.405235517241379 ], [ 29.777278481012658, -1.405505 ], [ 29.777547974683543, -1.405505 ], [ 29.777547974683543, -1.405235517241379 ], [ 29.777278481012658, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3298, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.405235517241379 ], [ 29.777547974683543, -1.405505 ], [ 29.777817468354431, -1.405505 ], [ 29.777817468354431, -1.405235517241379 ], [ 29.777547974683543, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3299, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.405235517241379 ], [ 29.777817468354431, -1.405505 ], [ 29.778086962025316, -1.405505 ], [ 29.778086962025316, -1.405235517241379 ], [ 29.777817468354431, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3300, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.405235517241379 ], [ 29.778086962025316, -1.405505 ], [ 29.778356455696201, -1.405505 ], [ 29.778356455696201, -1.405235517241379 ], [ 29.778086962025316, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3301, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.405235517241379 ], [ 29.778356455696201, -1.405505 ], [ 29.77862594936709, -1.405505 ], [ 29.77862594936709, -1.405235517241379 ], [ 29.778356455696201, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3302, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.405235517241379 ], [ 29.77862594936709, -1.405505 ], [ 29.778895443037975, -1.405505 ], [ 29.778895443037975, -1.405235517241379 ], [ 29.77862594936709, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3303, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.405235517241379 ], [ 29.778895443037975, -1.405505 ], [ 29.77916493670886, -1.405505 ], [ 29.77916493670886, -1.405235517241379 ], [ 29.778895443037975, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3304, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.405235517241379 ], [ 29.77916493670886, -1.405505 ], [ 29.779434430379748, -1.405505 ], [ 29.779434430379748, -1.405235517241379 ], [ 29.77916493670886, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3305, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.405235517241379 ], [ 29.779434430379748, -1.405505 ], [ 29.779703924050633, -1.405505 ], [ 29.779703924050633, -1.405235517241379 ], [ 29.779434430379748, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3306, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.405235517241379 ], [ 29.779703924050633, -1.405774482758621 ], [ 29.779973417721518, -1.405774482758621 ], [ 29.779973417721518, -1.405235517241379 ], [ 29.779703924050633, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3307, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.405235517241379 ], [ 29.779973417721518, -1.405505 ], [ 29.780242911392406, -1.405505 ], [ 29.780242911392406, -1.405235517241379 ], [ 29.779973417721518, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3308, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.405235517241379 ], [ 29.780242911392406, -1.405505 ], [ 29.780512405063291, -1.405505 ], [ 29.780512405063291, -1.405235517241379 ], [ 29.780242911392406, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3309, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.405235517241379 ], [ 29.780512405063291, -1.405505 ], [ 29.780781898734176, -1.405505 ], [ 29.780781898734176, -1.405235517241379 ], [ 29.780512405063291, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3310, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.405235517241379 ], [ 29.780781898734176, -1.405505 ], [ 29.781051392405065, -1.405505 ], [ 29.781051392405065, -1.405235517241379 ], [ 29.780781898734176, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3311, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.405235517241379 ], [ 29.781051392405065, -1.405505 ], [ 29.78132088607595, -1.405505 ], [ 29.78132088607595, -1.405235517241379 ], [ 29.781051392405065, -1.405235517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3312, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.405505 ], [ 29.763803797468356, -1.405774482758621 ], [ 29.76407329113924, -1.405774482758621 ], [ 29.76407329113924, -1.405505 ], [ 29.763803797468356, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3313, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.405505 ], [ 29.76407329113924, -1.405774482758621 ], [ 29.764342784810125, -1.405774482758621 ], [ 29.764342784810125, -1.405505 ], [ 29.76407329113924, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3314, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.405505 ], [ 29.764342784810125, -1.405774482758621 ], [ 29.764612278481014, -1.405774482758621 ], [ 29.764612278481014, -1.405505 ], [ 29.764342784810125, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3315, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.405505 ], [ 29.764612278481014, -1.405774482758621 ], [ 29.764881772151899, -1.405774482758621 ], [ 29.764881772151899, -1.405505 ], [ 29.764612278481014, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3316, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.405505 ], [ 29.764881772151899, -1.405774482758621 ], [ 29.765151265822784, -1.405774482758621 ], [ 29.765151265822784, -1.405505 ], [ 29.764881772151899, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3317, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.405505 ], [ 29.765151265822784, -1.405774482758621 ], [ 29.765420759493672, -1.405774482758621 ], [ 29.765420759493672, -1.405505 ], [ 29.765151265822784, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3318, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.405505 ], [ 29.765420759493672, -1.405774482758621 ], [ 29.765959746835442, -1.405774482758621 ], [ 29.765959746835442, -1.405505 ], [ 29.765420759493672, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3319, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.405505 ], [ 29.765959746835442, -1.405774482758621 ], [ 29.766229240506327, -1.405774482758621 ], [ 29.766229240506327, -1.405505 ], [ 29.765959746835442, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3320, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.405505 ], [ 29.766229240506327, -1.405774482758621 ], [ 29.766498734177215, -1.405774482758621 ], [ 29.766498734177215, -1.405505 ], [ 29.766229240506327, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3321, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.405505 ], [ 29.766498734177215, -1.405774482758621 ], [ 29.7667682278481, -1.405774482758621 ], [ 29.7667682278481, -1.405505 ], [ 29.766498734177215, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3322, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.405505 ], [ 29.7667682278481, -1.405774482758621 ], [ 29.767037721518985, -1.405774482758621 ], [ 29.767037721518985, -1.405505 ], [ 29.7667682278481, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3323, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.405505 ], [ 29.767037721518985, -1.405774482758621 ], [ 29.767307215189874, -1.405774482758621 ], [ 29.767307215189874, -1.405505 ], [ 29.767037721518985, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3324, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.405505 ], [ 29.767307215189874, -1.405774482758621 ], [ 29.767576708860759, -1.405774482758621 ], [ 29.767576708860759, -1.405505 ], [ 29.767307215189874, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3325, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.405505 ], [ 29.767576708860759, -1.405774482758621 ], [ 29.767846202531643, -1.405774482758621 ], [ 29.767846202531643, -1.405505 ], [ 29.767576708860759, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3326, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.405505 ], [ 29.767846202531643, -1.405774482758621 ], [ 29.768115696202532, -1.405774482758621 ], [ 29.768115696202532, -1.405505 ], [ 29.767846202531643, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3327, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.405505 ], [ 29.768115696202532, -1.405774482758621 ], [ 29.768385189873417, -1.405774482758621 ], [ 29.768385189873417, -1.405505 ], [ 29.768115696202532, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3328, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.405505 ], [ 29.768385189873417, -1.405774482758621 ], [ 29.768654683544302, -1.405774482758621 ], [ 29.768654683544302, -1.405505 ], [ 29.768385189873417, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3329, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.405505 ], [ 29.768654683544302, -1.405774482758621 ], [ 29.76892417721519, -1.405774482758621 ], [ 29.76892417721519, -1.405505 ], [ 29.768654683544302, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3330, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.405505 ], [ 29.76892417721519, -1.405774482758621 ], [ 29.769193670886075, -1.405774482758621 ], [ 29.769193670886075, -1.405505 ], [ 29.76892417721519, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3331, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.405505 ], [ 29.769193670886075, -1.405774482758621 ], [ 29.76946316455696, -1.405774482758621 ], [ 29.76946316455696, -1.405505 ], [ 29.769193670886075, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3332, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.405505 ], [ 29.76946316455696, -1.405774482758621 ], [ 29.769732658227849, -1.405774482758621 ], [ 29.769732658227849, -1.405505 ], [ 29.76946316455696, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3333, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.405505 ], [ 29.769732658227849, -1.405774482758621 ], [ 29.770002151898733, -1.405774482758621 ], [ 29.770002151898733, -1.405505 ], [ 29.769732658227849, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3334, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.405505 ], [ 29.770002151898733, -1.405774482758621 ], [ 29.770271645569618, -1.405774482758621 ], [ 29.770271645569618, -1.405505 ], [ 29.770002151898733, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3335, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.405505 ], [ 29.770271645569618, -1.405774482758621 ], [ 29.770541139240507, -1.405774482758621 ], [ 29.770541139240507, -1.405505 ], [ 29.770271645569618, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3336, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.405505 ], [ 29.770541139240507, -1.405774482758621 ], [ 29.770810632911392, -1.405774482758621 ], [ 29.770810632911392, -1.405505 ], [ 29.770541139240507, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3337, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.405505 ], [ 29.770810632911392, -1.405774482758621 ], [ 29.771080126582277, -1.405774482758621 ], [ 29.771080126582277, -1.405505 ], [ 29.770810632911392, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3338, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.405505 ], [ 29.771080126582277, -1.405774482758621 ], [ 29.771349620253165, -1.405774482758621 ], [ 29.771349620253165, -1.405505 ], [ 29.771080126582277, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3339, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.405505 ], [ 29.771349620253165, -1.405774482758621 ], [ 29.77161911392405, -1.405774482758621 ], [ 29.77161911392405, -1.405505 ], [ 29.771349620253165, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3340, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.405505 ], [ 29.77161911392405, -1.405774482758621 ], [ 29.771888607594935, -1.405774482758621 ], [ 29.771888607594935, -1.405505 ], [ 29.77161911392405, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3341, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.405505 ], [ 29.771888607594935, -1.405774482758621 ], [ 29.772158101265823, -1.405774482758621 ], [ 29.772158101265823, -1.405505 ], [ 29.771888607594935, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3342, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.405505 ], [ 29.772158101265823, -1.405774482758621 ], [ 29.772427594936708, -1.405774482758621 ], [ 29.772427594936708, -1.405505 ], [ 29.772158101265823, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3343, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.405505 ], [ 29.772427594936708, -1.405774482758621 ], [ 29.772697088607593, -1.405774482758621 ], [ 29.772697088607593, -1.405505 ], [ 29.772427594936708, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3344, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.405505 ], [ 29.772697088607593, -1.405774482758621 ], [ 29.772966582278482, -1.405774482758621 ], [ 29.772966582278482, -1.405505 ], [ 29.772697088607593, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3345, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.405505 ], [ 29.772966582278482, -1.405774482758621 ], [ 29.773236075949367, -1.405774482758621 ], [ 29.773236075949367, -1.405505 ], [ 29.772966582278482, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3346, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.405505 ], [ 29.773236075949367, -1.405774482758621 ], [ 29.773505569620252, -1.405774482758621 ], [ 29.773505569620252, -1.405505 ], [ 29.773236075949367, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3347, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.405505 ], [ 29.773505569620252, -1.405774482758621 ], [ 29.77377506329114, -1.405774482758621 ], [ 29.77377506329114, -1.405505 ], [ 29.773505569620252, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3348, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.405505 ], [ 29.77377506329114, -1.405774482758621 ], [ 29.774044556962025, -1.405774482758621 ], [ 29.774044556962025, -1.405505 ], [ 29.77377506329114, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3349, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.405505 ], [ 29.774044556962025, -1.405774482758621 ], [ 29.77431405063291, -1.405774482758621 ], [ 29.77431405063291, -1.405505 ], [ 29.774044556962025, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3350, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.405505 ], [ 29.77431405063291, -1.405774482758621 ], [ 29.774853037974683, -1.405774482758621 ], [ 29.774853037974683, -1.405505 ], [ 29.77431405063291, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3351, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.405505 ], [ 29.774853037974683, -1.405774482758621 ], [ 29.775122531645568, -1.405774482758621 ], [ 29.775122531645568, -1.405505 ], [ 29.774853037974683, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3352, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.405505 ], [ 29.775122531645568, -1.405774482758621 ], [ 29.775392025316457, -1.405774482758621 ], [ 29.775392025316457, -1.405505 ], [ 29.775122531645568, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3353, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.405505 ], [ 29.775392025316457, -1.405774482758621 ], [ 29.775661518987341, -1.405774482758621 ], [ 29.775661518987341, -1.405505 ], [ 29.775392025316457, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3354, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.405505 ], [ 29.775661518987341, -1.405774482758621 ], [ 29.775931012658226, -1.405774482758621 ], [ 29.775931012658226, -1.405505 ], [ 29.775661518987341, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3355, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.405505 ], [ 29.775931012658226, -1.405774482758621 ], [ 29.776200506329115, -1.405774482758621 ], [ 29.776200506329115, -1.405505 ], [ 29.775931012658226, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3356, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.405505 ], [ 29.776200506329115, -1.405774482758621 ], [ 29.77647, -1.405774482758621 ], [ 29.77647, -1.405505 ], [ 29.776200506329115, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3357, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.405505 ], [ 29.77647, -1.405774482758621 ], [ 29.776739493670885, -1.405774482758621 ], [ 29.776739493670885, -1.405505 ], [ 29.77647, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3358, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.405505 ], [ 29.776739493670885, -1.405774482758621 ], [ 29.777008987341773, -1.405774482758621 ], [ 29.777008987341773, -1.405505 ], [ 29.776739493670885, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3359, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.405505 ], [ 29.777008987341773, -1.405774482758621 ], [ 29.777278481012658, -1.405774482758621 ], [ 29.777278481012658, -1.405505 ], [ 29.777008987341773, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3360, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.405505 ], [ 29.777278481012658, -1.405774482758621 ], [ 29.777547974683543, -1.405774482758621 ], [ 29.777547974683543, -1.405505 ], [ 29.777278481012658, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3361, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.405505 ], [ 29.777547974683543, -1.405774482758621 ], [ 29.777817468354431, -1.405774482758621 ], [ 29.777817468354431, -1.405505 ], [ 29.777547974683543, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3362, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.405505 ], [ 29.777817468354431, -1.405774482758621 ], [ 29.778086962025316, -1.405774482758621 ], [ 29.778086962025316, -1.405505 ], [ 29.777817468354431, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3363, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.405505 ], [ 29.77862594936709, -1.406043965517241 ], [ 29.778895443037975, -1.406043965517241 ], [ 29.778895443037975, -1.405505 ], [ 29.77862594936709, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3364, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.405505 ], [ 29.778895443037975, -1.405774482758621 ], [ 29.77916493670886, -1.405774482758621 ], [ 29.77916493670886, -1.405505 ], [ 29.778895443037975, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3365, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.405505 ], [ 29.779434430379748, -1.405774482758621 ], [ 29.779703924050633, -1.405774482758621 ], [ 29.779703924050633, -1.405505 ], [ 29.779434430379748, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3366, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.405505 ], [ 29.779973417721518, -1.406043965517241 ], [ 29.780242911392406, -1.406043965517241 ], [ 29.780242911392406, -1.405505 ], [ 29.779973417721518, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3367, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.405505 ], [ 29.780242911392406, -1.405774482758621 ], [ 29.780512405063291, -1.405774482758621 ], [ 29.780512405063291, -1.405505 ], [ 29.780242911392406, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3368, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.405505 ], [ 29.780512405063291, -1.405774482758621 ], [ 29.780781898734176, -1.405774482758621 ], [ 29.780781898734176, -1.405505 ], [ 29.780512405063291, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3369, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.405505 ], [ 29.780781898734176, -1.405774482758621 ], [ 29.781051392405065, -1.405774482758621 ], [ 29.781051392405065, -1.405505 ], [ 29.780781898734176, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3370, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.405505 ], [ 29.781051392405065, -1.405774482758621 ], [ 29.78132088607595, -1.405774482758621 ], [ 29.78132088607595, -1.405505 ], [ 29.781051392405065, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3371, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.405505 ], [ 29.78132088607595, -1.405774482758621 ], [ 29.781590379746834, -1.405774482758621 ], [ 29.781590379746834, -1.405505 ], [ 29.78132088607595, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3372, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.405505 ], [ 29.781590379746834, -1.406043965517241 ], [ 29.781859873417723, -1.406043965517241 ], [ 29.781859873417723, -1.405505 ], [ 29.781590379746834, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3373, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.405774482758621 ], [ 29.763534303797467, -1.406043965517241 ], [ 29.763803797468356, -1.406043965517241 ], [ 29.763803797468356, -1.405774482758621 ], [ 29.763534303797467, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3374, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.405774482758621 ], [ 29.763803797468356, -1.406043965517241 ], [ 29.76407329113924, -1.406043965517241 ], [ 29.76407329113924, -1.405774482758621 ], [ 29.763803797468356, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3375, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.405774482758621 ], [ 29.76407329113924, -1.406043965517241 ], [ 29.764342784810125, -1.406043965517241 ], [ 29.764342784810125, -1.405774482758621 ], [ 29.76407329113924, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3376, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.405774482758621 ], [ 29.764342784810125, -1.406043965517241 ], [ 29.764612278481014, -1.406043965517241 ], [ 29.764612278481014, -1.405774482758621 ], [ 29.764342784810125, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3377, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.405774482758621 ], [ 29.764612278481014, -1.406043965517241 ], [ 29.764881772151899, -1.406043965517241 ], [ 29.764881772151899, -1.405774482758621 ], [ 29.764612278481014, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3378, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.405774482758621 ], [ 29.764881772151899, -1.406043965517241 ], [ 29.765151265822784, -1.406043965517241 ], [ 29.765151265822784, -1.405774482758621 ], [ 29.764881772151899, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3379, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.405774482758621 ], [ 29.765151265822784, -1.406043965517241 ], [ 29.765420759493672, -1.406043965517241 ], [ 29.765420759493672, -1.405774482758621 ], [ 29.765151265822784, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3380, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.405774482758621 ], [ 29.765420759493672, -1.406043965517241 ], [ 29.765959746835442, -1.406043965517241 ], [ 29.765959746835442, -1.405774482758621 ], [ 29.765420759493672, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3381, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.405774482758621 ], [ 29.765959746835442, -1.406043965517241 ], [ 29.766229240506327, -1.406043965517241 ], [ 29.766229240506327, -1.405774482758621 ], [ 29.765959746835442, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3382, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.405774482758621 ], [ 29.766229240506327, -1.406043965517241 ], [ 29.766498734177215, -1.406043965517241 ], [ 29.766498734177215, -1.405774482758621 ], [ 29.766229240506327, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3383, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.405774482758621 ], [ 29.766498734177215, -1.406043965517241 ], [ 29.7667682278481, -1.406043965517241 ], [ 29.7667682278481, -1.405774482758621 ], [ 29.766498734177215, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3384, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.405774482758621 ], [ 29.7667682278481, -1.406043965517241 ], [ 29.767037721518985, -1.406043965517241 ], [ 29.767037721518985, -1.405774482758621 ], [ 29.7667682278481, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3385, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.405774482758621 ], [ 29.767037721518985, -1.406043965517241 ], [ 29.767307215189874, -1.406043965517241 ], [ 29.767307215189874, -1.405774482758621 ], [ 29.767037721518985, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3386, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.405774482758621 ], [ 29.767307215189874, -1.406043965517241 ], [ 29.767576708860759, -1.406043965517241 ], [ 29.767576708860759, -1.405774482758621 ], [ 29.767307215189874, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3387, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.405774482758621 ], [ 29.767576708860759, -1.406043965517241 ], [ 29.767846202531643, -1.406043965517241 ], [ 29.767846202531643, -1.405774482758621 ], [ 29.767576708860759, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3388, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.405774482758621 ], [ 29.767846202531643, -1.406043965517241 ], [ 29.768115696202532, -1.406043965517241 ], [ 29.768115696202532, -1.405774482758621 ], [ 29.767846202531643, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3389, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.405774482758621 ], [ 29.768115696202532, -1.406043965517241 ], [ 29.768385189873417, -1.406043965517241 ], [ 29.768385189873417, -1.405774482758621 ], [ 29.768115696202532, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3390, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.405774482758621 ], [ 29.768385189873417, -1.406043965517241 ], [ 29.768654683544302, -1.406043965517241 ], [ 29.768654683544302, -1.405774482758621 ], [ 29.768385189873417, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3391, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.405774482758621 ], [ 29.768654683544302, -1.406043965517241 ], [ 29.76892417721519, -1.406043965517241 ], [ 29.76892417721519, -1.405774482758621 ], [ 29.768654683544302, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3392, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.405774482758621 ], [ 29.76892417721519, -1.406043965517241 ], [ 29.769193670886075, -1.406043965517241 ], [ 29.769193670886075, -1.405774482758621 ], [ 29.76892417721519, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3393, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.405774482758621 ], [ 29.769193670886075, -1.406043965517241 ], [ 29.76946316455696, -1.406043965517241 ], [ 29.76946316455696, -1.405774482758621 ], [ 29.769193670886075, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3394, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.405774482758621 ], [ 29.76946316455696, -1.406043965517241 ], [ 29.769732658227849, -1.406043965517241 ], [ 29.769732658227849, -1.405774482758621 ], [ 29.76946316455696, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3395, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.405774482758621 ], [ 29.769732658227849, -1.406043965517241 ], [ 29.770002151898733, -1.406043965517241 ], [ 29.770002151898733, -1.405774482758621 ], [ 29.769732658227849, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3396, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.405774482758621 ], [ 29.770002151898733, -1.406043965517241 ], [ 29.770271645569618, -1.406043965517241 ], [ 29.770271645569618, -1.405774482758621 ], [ 29.770002151898733, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3397, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.405774482758621 ], [ 29.770271645569618, -1.406043965517241 ], [ 29.770541139240507, -1.406043965517241 ], [ 29.770541139240507, -1.405774482758621 ], [ 29.770271645569618, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3398, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.405774482758621 ], [ 29.770541139240507, -1.406043965517241 ], [ 29.770810632911392, -1.406043965517241 ], [ 29.770810632911392, -1.405774482758621 ], [ 29.770541139240507, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3399, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.405774482758621 ], [ 29.770810632911392, -1.406043965517241 ], [ 29.771080126582277, -1.406043965517241 ], [ 29.771080126582277, -1.405774482758621 ], [ 29.770810632911392, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3400, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.405774482758621 ], [ 29.771080126582277, -1.406043965517241 ], [ 29.771349620253165, -1.406043965517241 ], [ 29.771349620253165, -1.405774482758621 ], [ 29.771080126582277, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3401, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.405774482758621 ], [ 29.771349620253165, -1.406043965517241 ], [ 29.77161911392405, -1.406043965517241 ], [ 29.77161911392405, -1.405774482758621 ], [ 29.771349620253165, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3402, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.405774482758621 ], [ 29.77161911392405, -1.406043965517241 ], [ 29.771888607594935, -1.406043965517241 ], [ 29.771888607594935, -1.405774482758621 ], [ 29.77161911392405, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3403, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.405774482758621 ], [ 29.771888607594935, -1.406043965517241 ], [ 29.772158101265823, -1.406043965517241 ], [ 29.772158101265823, -1.405774482758621 ], [ 29.771888607594935, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3404, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.405774482758621 ], [ 29.772158101265823, -1.406043965517241 ], [ 29.772427594936708, -1.406043965517241 ], [ 29.772427594936708, -1.405774482758621 ], [ 29.772158101265823, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3405, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.405774482758621 ], [ 29.772427594936708, -1.406043965517241 ], [ 29.772697088607593, -1.406043965517241 ], [ 29.772697088607593, -1.405774482758621 ], [ 29.772427594936708, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3406, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.405774482758621 ], [ 29.772697088607593, -1.406043965517241 ], [ 29.772966582278482, -1.406043965517241 ], [ 29.772966582278482, -1.405774482758621 ], [ 29.772697088607593, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3407, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.405774482758621 ], [ 29.772966582278482, -1.406043965517241 ], [ 29.773236075949367, -1.406043965517241 ], [ 29.773236075949367, -1.405774482758621 ], [ 29.772966582278482, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3408, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.405774482758621 ], [ 29.773236075949367, -1.406043965517241 ], [ 29.773505569620252, -1.406043965517241 ], [ 29.773505569620252, -1.405774482758621 ], [ 29.773236075949367, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3409, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.405774482758621 ], [ 29.773505569620252, -1.406043965517241 ], [ 29.77377506329114, -1.406043965517241 ], [ 29.77377506329114, -1.405774482758621 ], [ 29.773505569620252, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3410, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.405774482758621 ], [ 29.77377506329114, -1.406043965517241 ], [ 29.774044556962025, -1.406043965517241 ], [ 29.774044556962025, -1.405774482758621 ], [ 29.77377506329114, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3411, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.405774482758621 ], [ 29.774044556962025, -1.406043965517241 ], [ 29.77431405063291, -1.406043965517241 ], [ 29.77431405063291, -1.405774482758621 ], [ 29.774044556962025, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3412, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.405774482758621 ], [ 29.77431405063291, -1.406043965517241 ], [ 29.774853037974683, -1.406043965517241 ], [ 29.774853037974683, -1.405774482758621 ], [ 29.77431405063291, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3413, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.405774482758621 ], [ 29.774853037974683, -1.406043965517241 ], [ 29.775122531645568, -1.406043965517241 ], [ 29.775122531645568, -1.405774482758621 ], [ 29.774853037974683, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3414, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.405774482758621 ], [ 29.775122531645568, -1.406043965517241 ], [ 29.775392025316457, -1.406043965517241 ], [ 29.775392025316457, -1.405774482758621 ], [ 29.775122531645568, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3415, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.405774482758621 ], [ 29.775392025316457, -1.406043965517241 ], [ 29.775661518987341, -1.406043965517241 ], [ 29.775661518987341, -1.405774482758621 ], [ 29.775392025316457, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3416, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.405774482758621 ], [ 29.775661518987341, -1.406043965517241 ], [ 29.775931012658226, -1.406043965517241 ], [ 29.775931012658226, -1.405774482758621 ], [ 29.775661518987341, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3417, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.405774482758621 ], [ 29.775931012658226, -1.406043965517241 ], [ 29.776200506329115, -1.406043965517241 ], [ 29.776200506329115, -1.405774482758621 ], [ 29.775931012658226, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3418, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.405774482758621 ], [ 29.776200506329115, -1.406043965517241 ], [ 29.77647, -1.406043965517241 ], [ 29.77647, -1.405774482758621 ], [ 29.776200506329115, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3419, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.405774482758621 ], [ 29.77647, -1.406043965517241 ], [ 29.776739493670885, -1.406043965517241 ], [ 29.776739493670885, -1.405774482758621 ], [ 29.77647, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3420, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.405774482758621 ], [ 29.780781898734176, -1.406043965517241 ], [ 29.781051392405065, -1.406043965517241 ], [ 29.781051392405065, -1.405774482758621 ], [ 29.780781898734176, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3421, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.405774482758621 ], [ 29.781051392405065, -1.406043965517241 ], [ 29.78132088607595, -1.406043965517241 ], [ 29.78132088607595, -1.405774482758621 ], [ 29.781051392405065, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3422, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.405774482758621 ], [ 29.78132088607595, -1.406043965517241 ], [ 29.781590379746834, -1.406043965517241 ], [ 29.781590379746834, -1.405774482758621 ], [ 29.78132088607595, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3423, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.406043965517241 ], [ 29.762995316455697, -1.406313448275862 ], [ 29.763534303797467, -1.406313448275862 ], [ 29.763534303797467, -1.406043965517241 ], [ 29.762995316455697, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3424, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.406043965517241 ], [ 29.763534303797467, -1.406313448275862 ], [ 29.763803797468356, -1.406313448275862 ], [ 29.763803797468356, -1.406043965517241 ], [ 29.763534303797467, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3425, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.406043965517241 ], [ 29.763803797468356, -1.406313448275862 ], [ 29.76407329113924, -1.406313448275862 ], [ 29.76407329113924, -1.406043965517241 ], [ 29.763803797468356, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3426, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.406043965517241 ], [ 29.76407329113924, -1.406313448275862 ], [ 29.764342784810125, -1.406313448275862 ], [ 29.764342784810125, -1.406043965517241 ], [ 29.76407329113924, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3427, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.406043965517241 ], [ 29.764342784810125, -1.406313448275862 ], [ 29.764612278481014, -1.406313448275862 ], [ 29.764612278481014, -1.406043965517241 ], [ 29.764342784810125, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3428, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.406043965517241 ], [ 29.764612278481014, -1.406313448275862 ], [ 29.764881772151899, -1.406313448275862 ], [ 29.764881772151899, -1.406043965517241 ], [ 29.764612278481014, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3429, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.406043965517241 ], [ 29.764881772151899, -1.406313448275862 ], [ 29.765151265822784, -1.406313448275862 ], [ 29.765151265822784, -1.406043965517241 ], [ 29.764881772151899, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3430, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.406043965517241 ], [ 29.765151265822784, -1.406313448275862 ], [ 29.765420759493672, -1.406313448275862 ], [ 29.765420759493672, -1.406043965517241 ], [ 29.765151265822784, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3431, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.406043965517241 ], [ 29.765420759493672, -1.406313448275862 ], [ 29.765959746835442, -1.406313448275862 ], [ 29.765959746835442, -1.406043965517241 ], [ 29.765420759493672, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3432, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.406043965517241 ], [ 29.765959746835442, -1.406313448275862 ], [ 29.766229240506327, -1.406313448275862 ], [ 29.766229240506327, -1.406043965517241 ], [ 29.765959746835442, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3433, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.406043965517241 ], [ 29.766229240506327, -1.406313448275862 ], [ 29.766498734177215, -1.406313448275862 ], [ 29.766498734177215, -1.406043965517241 ], [ 29.766229240506327, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3434, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.406043965517241 ], [ 29.766498734177215, -1.406313448275862 ], [ 29.7667682278481, -1.406313448275862 ], [ 29.7667682278481, -1.406043965517241 ], [ 29.766498734177215, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3435, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.406043965517241 ], [ 29.7667682278481, -1.406313448275862 ], [ 29.767037721518985, -1.406313448275862 ], [ 29.767037721518985, -1.406043965517241 ], [ 29.7667682278481, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3436, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.406043965517241 ], [ 29.767037721518985, -1.406313448275862 ], [ 29.767307215189874, -1.406313448275862 ], [ 29.767307215189874, -1.406043965517241 ], [ 29.767037721518985, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3437, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.406043965517241 ], [ 29.767307215189874, -1.406313448275862 ], [ 29.767576708860759, -1.406313448275862 ], [ 29.767576708860759, -1.406043965517241 ], [ 29.767307215189874, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3438, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.406043965517241 ], [ 29.767576708860759, -1.406313448275862 ], [ 29.767846202531643, -1.406313448275862 ], [ 29.767846202531643, -1.406043965517241 ], [ 29.767576708860759, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3439, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.406043965517241 ], [ 29.767846202531643, -1.406313448275862 ], [ 29.768115696202532, -1.406313448275862 ], [ 29.768115696202532, -1.406043965517241 ], [ 29.767846202531643, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3440, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.406043965517241 ], [ 29.768115696202532, -1.406313448275862 ], [ 29.768385189873417, -1.406313448275862 ], [ 29.768385189873417, -1.406043965517241 ], [ 29.768115696202532, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3441, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.406043965517241 ], [ 29.768385189873417, -1.406313448275862 ], [ 29.768654683544302, -1.406313448275862 ], [ 29.768654683544302, -1.406043965517241 ], [ 29.768385189873417, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3442, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.406043965517241 ], [ 29.768654683544302, -1.406313448275862 ], [ 29.76892417721519, -1.406313448275862 ], [ 29.76892417721519, -1.406043965517241 ], [ 29.768654683544302, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3443, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.406043965517241 ], [ 29.76892417721519, -1.406313448275862 ], [ 29.769193670886075, -1.406313448275862 ], [ 29.769193670886075, -1.406043965517241 ], [ 29.76892417721519, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3444, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.406043965517241 ], [ 29.769193670886075, -1.406313448275862 ], [ 29.76946316455696, -1.406313448275862 ], [ 29.76946316455696, -1.406043965517241 ], [ 29.769193670886075, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3445, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.406043965517241 ], [ 29.76946316455696, -1.406313448275862 ], [ 29.769732658227849, -1.406313448275862 ], [ 29.769732658227849, -1.406043965517241 ], [ 29.76946316455696, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3446, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.406043965517241 ], [ 29.769732658227849, -1.406313448275862 ], [ 29.770002151898733, -1.406313448275862 ], [ 29.770002151898733, -1.406043965517241 ], [ 29.769732658227849, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3447, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.406043965517241 ], [ 29.770002151898733, -1.406313448275862 ], [ 29.770271645569618, -1.406313448275862 ], [ 29.770271645569618, -1.406043965517241 ], [ 29.770002151898733, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3448, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.406043965517241 ], [ 29.770271645569618, -1.406313448275862 ], [ 29.770541139240507, -1.406313448275862 ], [ 29.770541139240507, -1.406043965517241 ], [ 29.770271645569618, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3449, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.406043965517241 ], [ 29.770541139240507, -1.406313448275862 ], [ 29.770810632911392, -1.406313448275862 ], [ 29.770810632911392, -1.406043965517241 ], [ 29.770541139240507, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3450, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.406043965517241 ], [ 29.770810632911392, -1.406313448275862 ], [ 29.771080126582277, -1.406313448275862 ], [ 29.771080126582277, -1.406043965517241 ], [ 29.770810632911392, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3451, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.406043965517241 ], [ 29.771080126582277, -1.406313448275862 ], [ 29.771349620253165, -1.406313448275862 ], [ 29.771349620253165, -1.406043965517241 ], [ 29.771080126582277, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3452, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.406043965517241 ], [ 29.771349620253165, -1.406313448275862 ], [ 29.77161911392405, -1.406313448275862 ], [ 29.77161911392405, -1.406043965517241 ], [ 29.771349620253165, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3453, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.406043965517241 ], [ 29.77161911392405, -1.406313448275862 ], [ 29.771888607594935, -1.406313448275862 ], [ 29.771888607594935, -1.406043965517241 ], [ 29.77161911392405, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3454, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.406043965517241 ], [ 29.771888607594935, -1.406313448275862 ], [ 29.772158101265823, -1.406313448275862 ], [ 29.772158101265823, -1.406043965517241 ], [ 29.771888607594935, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3455, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.406043965517241 ], [ 29.772158101265823, -1.406313448275862 ], [ 29.772427594936708, -1.406313448275862 ], [ 29.772427594936708, -1.406043965517241 ], [ 29.772158101265823, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3456, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.406043965517241 ], [ 29.772427594936708, -1.406313448275862 ], [ 29.772697088607593, -1.406313448275862 ], [ 29.772697088607593, -1.406043965517241 ], [ 29.772427594936708, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3457, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.406043965517241 ], [ 29.772697088607593, -1.406313448275862 ], [ 29.772966582278482, -1.406313448275862 ], [ 29.772966582278482, -1.406043965517241 ], [ 29.772697088607593, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3458, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.406043965517241 ], [ 29.772966582278482, -1.406313448275862 ], [ 29.773236075949367, -1.406313448275862 ], [ 29.773236075949367, -1.406043965517241 ], [ 29.772966582278482, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3459, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.406043965517241 ], [ 29.773236075949367, -1.406313448275862 ], [ 29.773505569620252, -1.406313448275862 ], [ 29.773505569620252, -1.406043965517241 ], [ 29.773236075949367, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3460, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.406043965517241 ], [ 29.773505569620252, -1.406313448275862 ], [ 29.77377506329114, -1.406313448275862 ], [ 29.77377506329114, -1.406043965517241 ], [ 29.773505569620252, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3461, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.406043965517241 ], [ 29.77377506329114, -1.406313448275862 ], [ 29.774044556962025, -1.406313448275862 ], [ 29.774044556962025, -1.406043965517241 ], [ 29.77377506329114, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3462, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.406043965517241 ], [ 29.774044556962025, -1.406313448275862 ], [ 29.77431405063291, -1.406313448275862 ], [ 29.77431405063291, -1.406043965517241 ], [ 29.774044556962025, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3463, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.406043965517241 ], [ 29.77431405063291, -1.406313448275862 ], [ 29.774853037974683, -1.406313448275862 ], [ 29.774853037974683, -1.406043965517241 ], [ 29.77431405063291, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3464, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.406043965517241 ], [ 29.774853037974683, -1.406313448275862 ], [ 29.775122531645568, -1.406313448275862 ], [ 29.775122531645568, -1.406043965517241 ], [ 29.774853037974683, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3465, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.406043965517241 ], [ 29.775122531645568, -1.406313448275862 ], [ 29.775392025316457, -1.406313448275862 ], [ 29.775392025316457, -1.406043965517241 ], [ 29.775122531645568, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3466, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.406043965517241 ], [ 29.77862594936709, -1.407121896551724 ], [ 29.778895443037975, -1.407121896551724 ], [ 29.778895443037975, -1.406043965517241 ], [ 29.77862594936709, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3467, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.406043965517241 ], [ 29.779973417721518, -1.407121896551724 ], [ 29.780242911392406, -1.407121896551724 ], [ 29.780242911392406, -1.406043965517241 ], [ 29.779973417721518, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3468, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.406043965517241 ], [ 29.780781898734176, -1.407121896551724 ], [ 29.781051392405065, -1.407121896551724 ], [ 29.781051392405065, -1.406043965517241 ], [ 29.780781898734176, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3469, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.406043965517241 ], [ 29.781051392405065, -1.407121896551724 ], [ 29.78132088607595, -1.407121896551724 ], [ 29.78132088607595, -1.406043965517241 ], [ 29.781051392405065, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3470, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.406043965517241 ], [ 29.78132088607595, -1.407121896551724 ], [ 29.781590379746834, -1.407121896551724 ], [ 29.781590379746834, -1.406043965517241 ], [ 29.78132088607595, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3471, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.406313448275862 ], [ 29.762725822784809, -1.406582931034483 ], [ 29.762995316455697, -1.406582931034483 ], [ 29.762995316455697, -1.406313448275862 ], [ 29.762725822784809, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.406313448275862 ], [ 29.762995316455697, -1.406582931034483 ], [ 29.763264810126582, -1.406582931034483 ], [ 29.763264810126582, -1.406313448275862 ], [ 29.762995316455697, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3473, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.406313448275862 ], [ 29.763264810126582, -1.406582931034483 ], [ 29.763534303797467, -1.406582931034483 ], [ 29.763534303797467, -1.406313448275862 ], [ 29.763264810126582, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3474, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.406313448275862 ], [ 29.763534303797467, -1.406582931034483 ], [ 29.763803797468356, -1.406582931034483 ], [ 29.763803797468356, -1.406313448275862 ], [ 29.763534303797467, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3475, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.406313448275862 ], [ 29.763803797468356, -1.406582931034483 ], [ 29.76407329113924, -1.406582931034483 ], [ 29.76407329113924, -1.406313448275862 ], [ 29.763803797468356, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3476, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.406313448275862 ], [ 29.76407329113924, -1.406582931034483 ], [ 29.764342784810125, -1.406582931034483 ], [ 29.764342784810125, -1.406313448275862 ], [ 29.76407329113924, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3477, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.406313448275862 ], [ 29.764342784810125, -1.406582931034483 ], [ 29.764612278481014, -1.406582931034483 ], [ 29.764612278481014, -1.406313448275862 ], [ 29.764342784810125, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3478, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.406313448275862 ], [ 29.764612278481014, -1.406582931034483 ], [ 29.764881772151899, -1.406582931034483 ], [ 29.764881772151899, -1.406313448275862 ], [ 29.764612278481014, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3479, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.406313448275862 ], [ 29.764881772151899, -1.406582931034483 ], [ 29.765151265822784, -1.406582931034483 ], [ 29.765151265822784, -1.406313448275862 ], [ 29.764881772151899, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3480, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.406313448275862 ], [ 29.765151265822784, -1.406582931034483 ], [ 29.765420759493672, -1.406582931034483 ], [ 29.765420759493672, -1.406313448275862 ], [ 29.765151265822784, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3481, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.406313448275862 ], [ 29.765420759493672, -1.406582931034483 ], [ 29.765959746835442, -1.406582931034483 ], [ 29.765959746835442, -1.406313448275862 ], [ 29.765420759493672, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3482, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.406313448275862 ], [ 29.765959746835442, -1.406582931034483 ], [ 29.766229240506327, -1.406582931034483 ], [ 29.766229240506327, -1.406313448275862 ], [ 29.765959746835442, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3483, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.406313448275862 ], [ 29.766229240506327, -1.406582931034483 ], [ 29.766498734177215, -1.406582931034483 ], [ 29.766498734177215, -1.406313448275862 ], [ 29.766229240506327, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3484, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.406313448275862 ], [ 29.766498734177215, -1.406582931034483 ], [ 29.7667682278481, -1.406582931034483 ], [ 29.7667682278481, -1.406313448275862 ], [ 29.766498734177215, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3485, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.406313448275862 ], [ 29.7667682278481, -1.406582931034483 ], [ 29.767037721518985, -1.406582931034483 ], [ 29.767037721518985, -1.406313448275862 ], [ 29.7667682278481, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3486, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.406313448275862 ], [ 29.767037721518985, -1.406582931034483 ], [ 29.767307215189874, -1.406582931034483 ], [ 29.767307215189874, -1.406313448275862 ], [ 29.767037721518985, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3487, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.406313448275862 ], [ 29.767307215189874, -1.406582931034483 ], [ 29.767576708860759, -1.406582931034483 ], [ 29.767576708860759, -1.406313448275862 ], [ 29.767307215189874, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3488, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.406313448275862 ], [ 29.767576708860759, -1.406582931034483 ], [ 29.767846202531643, -1.406582931034483 ], [ 29.767846202531643, -1.406313448275862 ], [ 29.767576708860759, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3489, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.406313448275862 ], [ 29.767846202531643, -1.406582931034483 ], [ 29.768115696202532, -1.406582931034483 ], [ 29.768115696202532, -1.406313448275862 ], [ 29.767846202531643, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3490, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.406313448275862 ], [ 29.768115696202532, -1.406582931034483 ], [ 29.768385189873417, -1.406582931034483 ], [ 29.768385189873417, -1.406313448275862 ], [ 29.768115696202532, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3491, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.406313448275862 ], [ 29.768385189873417, -1.406582931034483 ], [ 29.768654683544302, -1.406582931034483 ], [ 29.768654683544302, -1.406313448275862 ], [ 29.768385189873417, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3492, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.406313448275862 ], [ 29.768654683544302, -1.406582931034483 ], [ 29.76892417721519, -1.406582931034483 ], [ 29.76892417721519, -1.406313448275862 ], [ 29.768654683544302, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3493, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.406313448275862 ], [ 29.76892417721519, -1.406582931034483 ], [ 29.769193670886075, -1.406582931034483 ], [ 29.769193670886075, -1.406313448275862 ], [ 29.76892417721519, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3494, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.406313448275862 ], [ 29.769193670886075, -1.406582931034483 ], [ 29.76946316455696, -1.406582931034483 ], [ 29.76946316455696, -1.406313448275862 ], [ 29.769193670886075, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3495, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.406313448275862 ], [ 29.76946316455696, -1.406582931034483 ], [ 29.769732658227849, -1.406582931034483 ], [ 29.769732658227849, -1.406313448275862 ], [ 29.76946316455696, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3496, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.406313448275862 ], [ 29.769732658227849, -1.406582931034483 ], [ 29.770002151898733, -1.406582931034483 ], [ 29.770002151898733, -1.406313448275862 ], [ 29.769732658227849, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3497, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.406313448275862 ], [ 29.770002151898733, -1.406582931034483 ], [ 29.770271645569618, -1.406582931034483 ], [ 29.770271645569618, -1.406313448275862 ], [ 29.770002151898733, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3498, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.406313448275862 ], [ 29.770271645569618, -1.406582931034483 ], [ 29.770541139240507, -1.406582931034483 ], [ 29.770541139240507, -1.406313448275862 ], [ 29.770271645569618, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3499, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.406313448275862 ], [ 29.770541139240507, -1.406582931034483 ], [ 29.770810632911392, -1.406582931034483 ], [ 29.770810632911392, -1.406313448275862 ], [ 29.770541139240507, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3500, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.406313448275862 ], [ 29.770810632911392, -1.406582931034483 ], [ 29.771080126582277, -1.406582931034483 ], [ 29.771080126582277, -1.406313448275862 ], [ 29.770810632911392, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3501, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.406313448275862 ], [ 29.771080126582277, -1.406582931034483 ], [ 29.771349620253165, -1.406582931034483 ], [ 29.771349620253165, -1.406313448275862 ], [ 29.771080126582277, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3502, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.406313448275862 ], [ 29.771349620253165, -1.406582931034483 ], [ 29.77161911392405, -1.406582931034483 ], [ 29.77161911392405, -1.406313448275862 ], [ 29.771349620253165, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3503, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.406313448275862 ], [ 29.77161911392405, -1.406582931034483 ], [ 29.771888607594935, -1.406582931034483 ], [ 29.771888607594935, -1.406313448275862 ], [ 29.77161911392405, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3504, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.406313448275862 ], [ 29.771888607594935, -1.406582931034483 ], [ 29.772158101265823, -1.406582931034483 ], [ 29.772158101265823, -1.406313448275862 ], [ 29.771888607594935, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3505, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.406313448275862 ], [ 29.772158101265823, -1.406582931034483 ], [ 29.772427594936708, -1.406582931034483 ], [ 29.772427594936708, -1.406313448275862 ], [ 29.772158101265823, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3506, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.406313448275862 ], [ 29.772427594936708, -1.406582931034483 ], [ 29.772697088607593, -1.406582931034483 ], [ 29.772697088607593, -1.406313448275862 ], [ 29.772427594936708, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3507, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.406313448275862 ], [ 29.772697088607593, -1.406582931034483 ], [ 29.772966582278482, -1.406582931034483 ], [ 29.772966582278482, -1.406313448275862 ], [ 29.772697088607593, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3508, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.406313448275862 ], [ 29.772966582278482, -1.406582931034483 ], [ 29.773236075949367, -1.406582931034483 ], [ 29.773236075949367, -1.406313448275862 ], [ 29.772966582278482, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3509, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.406313448275862 ], [ 29.773236075949367, -1.406582931034483 ], [ 29.773505569620252, -1.406582931034483 ], [ 29.773505569620252, -1.406313448275862 ], [ 29.773236075949367, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3510, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.406313448275862 ], [ 29.773505569620252, -1.406582931034483 ], [ 29.77377506329114, -1.406582931034483 ], [ 29.77377506329114, -1.406313448275862 ], [ 29.773505569620252, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3511, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.406313448275862 ], [ 29.77377506329114, -1.406582931034483 ], [ 29.774044556962025, -1.406582931034483 ], [ 29.774044556962025, -1.406313448275862 ], [ 29.77377506329114, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3512, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.406313448275862 ], [ 29.774044556962025, -1.406582931034483 ], [ 29.77431405063291, -1.406582931034483 ], [ 29.77431405063291, -1.406313448275862 ], [ 29.774044556962025, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3513, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.406582931034483 ], [ 29.762456329113924, -1.406852413793104 ], [ 29.762725822784809, -1.406852413793104 ], [ 29.762725822784809, -1.406582931034483 ], [ 29.762456329113924, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3514, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.406582931034483 ], [ 29.762725822784809, -1.406852413793104 ], [ 29.762995316455697, -1.406852413793104 ], [ 29.762995316455697, -1.406582931034483 ], [ 29.762725822784809, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3515, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.406582931034483 ], [ 29.762995316455697, -1.406852413793104 ], [ 29.763264810126582, -1.406852413793104 ], [ 29.763264810126582, -1.406582931034483 ], [ 29.762995316455697, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3516, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.406582931034483 ], [ 29.763264810126582, -1.406852413793104 ], [ 29.763534303797467, -1.406852413793104 ], [ 29.763534303797467, -1.406582931034483 ], [ 29.763264810126582, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3517, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.406582931034483 ], [ 29.763534303797467, -1.406852413793104 ], [ 29.763803797468356, -1.406852413793104 ], [ 29.763803797468356, -1.406582931034483 ], [ 29.763534303797467, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3518, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.406582931034483 ], [ 29.763803797468356, -1.406852413793104 ], [ 29.76407329113924, -1.406852413793104 ], [ 29.76407329113924, -1.406582931034483 ], [ 29.763803797468356, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3519, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.406582931034483 ], [ 29.76407329113924, -1.406852413793104 ], [ 29.764342784810125, -1.406852413793104 ], [ 29.764342784810125, -1.406582931034483 ], [ 29.76407329113924, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3520, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.406582931034483 ], [ 29.764342784810125, -1.406852413793104 ], [ 29.764612278481014, -1.406852413793104 ], [ 29.764612278481014, -1.406582931034483 ], [ 29.764342784810125, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3521, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.406582931034483 ], [ 29.764612278481014, -1.406852413793104 ], [ 29.764881772151899, -1.406852413793104 ], [ 29.764881772151899, -1.406582931034483 ], [ 29.764612278481014, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3522, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.406582931034483 ], [ 29.764881772151899, -1.406852413793104 ], [ 29.765151265822784, -1.406852413793104 ], [ 29.765151265822784, -1.406582931034483 ], [ 29.764881772151899, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3523, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.406582931034483 ], [ 29.765151265822784, -1.406852413793104 ], [ 29.765420759493672, -1.406852413793104 ], [ 29.765420759493672, -1.406582931034483 ], [ 29.765151265822784, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3524, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.406582931034483 ], [ 29.765420759493672, -1.406852413793104 ], [ 29.765959746835442, -1.406852413793104 ], [ 29.765959746835442, -1.406582931034483 ], [ 29.765420759493672, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3525, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.406582931034483 ], [ 29.765959746835442, -1.406852413793104 ], [ 29.766229240506327, -1.406852413793104 ], [ 29.766229240506327, -1.406582931034483 ], [ 29.765959746835442, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3526, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.406582931034483 ], [ 29.766229240506327, -1.406852413793104 ], [ 29.766498734177215, -1.406852413793104 ], [ 29.766498734177215, -1.406582931034483 ], [ 29.766229240506327, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3527, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.406582931034483 ], [ 29.766498734177215, -1.406852413793104 ], [ 29.7667682278481, -1.406852413793104 ], [ 29.7667682278481, -1.406582931034483 ], [ 29.766498734177215, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3528, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.406582931034483 ], [ 29.7667682278481, -1.406852413793104 ], [ 29.767037721518985, -1.406852413793104 ], [ 29.767037721518985, -1.406582931034483 ], [ 29.7667682278481, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3529, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.406582931034483 ], [ 29.767037721518985, -1.406852413793104 ], [ 29.767307215189874, -1.406852413793104 ], [ 29.767307215189874, -1.406582931034483 ], [ 29.767037721518985, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3530, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.406582931034483 ], [ 29.767307215189874, -1.406852413793104 ], [ 29.767576708860759, -1.406852413793104 ], [ 29.767576708860759, -1.406582931034483 ], [ 29.767307215189874, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3531, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.406582931034483 ], [ 29.767576708860759, -1.406852413793104 ], [ 29.767846202531643, -1.406852413793104 ], [ 29.767846202531643, -1.406582931034483 ], [ 29.767576708860759, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3532, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.406582931034483 ], [ 29.767846202531643, -1.406852413793104 ], [ 29.768115696202532, -1.406852413793104 ], [ 29.768115696202532, -1.406582931034483 ], [ 29.767846202531643, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3533, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.406582931034483 ], [ 29.768115696202532, -1.406852413793104 ], [ 29.768385189873417, -1.406852413793104 ], [ 29.768385189873417, -1.406582931034483 ], [ 29.768115696202532, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3534, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.406582931034483 ], [ 29.768385189873417, -1.406852413793104 ], [ 29.768654683544302, -1.406852413793104 ], [ 29.768654683544302, -1.406582931034483 ], [ 29.768385189873417, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3535, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.406582931034483 ], [ 29.768654683544302, -1.406852413793104 ], [ 29.76892417721519, -1.406852413793104 ], [ 29.76892417721519, -1.406582931034483 ], [ 29.768654683544302, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3536, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.406582931034483 ], [ 29.76892417721519, -1.406852413793104 ], [ 29.769193670886075, -1.406852413793104 ], [ 29.769193670886075, -1.406582931034483 ], [ 29.76892417721519, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3537, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.406582931034483 ], [ 29.769193670886075, -1.406852413793104 ], [ 29.76946316455696, -1.406852413793104 ], [ 29.76946316455696, -1.406582931034483 ], [ 29.769193670886075, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3538, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.406582931034483 ], [ 29.76946316455696, -1.406852413793104 ], [ 29.769732658227849, -1.406852413793104 ], [ 29.769732658227849, -1.406582931034483 ], [ 29.76946316455696, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3539, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.406582931034483 ], [ 29.769732658227849, -1.406852413793104 ], [ 29.770002151898733, -1.406852413793104 ], [ 29.770002151898733, -1.406582931034483 ], [ 29.769732658227849, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3540, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.406582931034483 ], [ 29.770002151898733, -1.406852413793104 ], [ 29.770271645569618, -1.406852413793104 ], [ 29.770271645569618, -1.406582931034483 ], [ 29.770002151898733, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3541, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.406582931034483 ], [ 29.770271645569618, -1.406852413793104 ], [ 29.770541139240507, -1.406852413793104 ], [ 29.770541139240507, -1.406582931034483 ], [ 29.770271645569618, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3542, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.406582931034483 ], [ 29.770541139240507, -1.406852413793104 ], [ 29.770810632911392, -1.406852413793104 ], [ 29.770810632911392, -1.406582931034483 ], [ 29.770541139240507, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3543, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.406582931034483 ], [ 29.770810632911392, -1.406852413793104 ], [ 29.771080126582277, -1.406852413793104 ], [ 29.771080126582277, -1.406582931034483 ], [ 29.770810632911392, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3544, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.406582931034483 ], [ 29.771080126582277, -1.406852413793104 ], [ 29.771349620253165, -1.406852413793104 ], [ 29.771349620253165, -1.406582931034483 ], [ 29.771080126582277, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3545, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.406582931034483 ], [ 29.771349620253165, -1.406852413793104 ], [ 29.77161911392405, -1.406852413793104 ], [ 29.77161911392405, -1.406582931034483 ], [ 29.771349620253165, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3546, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.406582931034483 ], [ 29.77161911392405, -1.406852413793104 ], [ 29.771888607594935, -1.406852413793104 ], [ 29.771888607594935, -1.406582931034483 ], [ 29.77161911392405, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3547, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.406582931034483 ], [ 29.771888607594935, -1.406852413793104 ], [ 29.772158101265823, -1.406852413793104 ], [ 29.772158101265823, -1.406582931034483 ], [ 29.771888607594935, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3548, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.406582931034483 ], [ 29.772158101265823, -1.406852413793104 ], [ 29.772427594936708, -1.406852413793104 ], [ 29.772427594936708, -1.406582931034483 ], [ 29.772158101265823, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3549, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.406582931034483 ], [ 29.772427594936708, -1.406852413793104 ], [ 29.772697088607593, -1.406852413793104 ], [ 29.772697088607593, -1.406582931034483 ], [ 29.772427594936708, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3550, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.406582931034483 ], [ 29.772697088607593, -1.406852413793104 ], [ 29.772966582278482, -1.406852413793104 ], [ 29.772966582278482, -1.406582931034483 ], [ 29.772697088607593, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3551, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.406852413793104 ], [ 29.762186835443039, -1.407121896551724 ], [ 29.762456329113924, -1.407121896551724 ], [ 29.762456329113924, -1.406852413793104 ], [ 29.762186835443039, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3552, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.406852413793104 ], [ 29.762456329113924, -1.407121896551724 ], [ 29.762725822784809, -1.407121896551724 ], [ 29.762725822784809, -1.406852413793104 ], [ 29.762456329113924, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3553, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.406852413793104 ], [ 29.762725822784809, -1.407121896551724 ], [ 29.762995316455697, -1.407121896551724 ], [ 29.762995316455697, -1.406852413793104 ], [ 29.762725822784809, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3554, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.406852413793104 ], [ 29.762995316455697, -1.407121896551724 ], [ 29.763264810126582, -1.407121896551724 ], [ 29.763264810126582, -1.406852413793104 ], [ 29.762995316455697, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3555, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.406852413793104 ], [ 29.763264810126582, -1.407121896551724 ], [ 29.763534303797467, -1.407121896551724 ], [ 29.763534303797467, -1.406852413793104 ], [ 29.763264810126582, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3556, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.406852413793104 ], [ 29.763534303797467, -1.407121896551724 ], [ 29.763803797468356, -1.407121896551724 ], [ 29.763803797468356, -1.406852413793104 ], [ 29.763534303797467, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3557, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.406852413793104 ], [ 29.763803797468356, -1.407121896551724 ], [ 29.76407329113924, -1.407121896551724 ], [ 29.76407329113924, -1.406852413793104 ], [ 29.763803797468356, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3558, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.406852413793104 ], [ 29.76407329113924, -1.407121896551724 ], [ 29.764342784810125, -1.407121896551724 ], [ 29.764342784810125, -1.406852413793104 ], [ 29.76407329113924, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3559, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.406852413793104 ], [ 29.764342784810125, -1.407121896551724 ], [ 29.764612278481014, -1.407121896551724 ], [ 29.764612278481014, -1.406852413793104 ], [ 29.764342784810125, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3560, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.406852413793104 ], [ 29.764612278481014, -1.407121896551724 ], [ 29.764881772151899, -1.407121896551724 ], [ 29.764881772151899, -1.406852413793104 ], [ 29.764612278481014, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3561, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.406852413793104 ], [ 29.764881772151899, -1.407121896551724 ], [ 29.765151265822784, -1.407121896551724 ], [ 29.765151265822784, -1.406852413793104 ], [ 29.764881772151899, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3562, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.406852413793104 ], [ 29.765151265822784, -1.407121896551724 ], [ 29.765420759493672, -1.407121896551724 ], [ 29.765420759493672, -1.406852413793104 ], [ 29.765151265822784, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3563, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.406852413793104 ], [ 29.765420759493672, -1.407121896551724 ], [ 29.765959746835442, -1.407121896551724 ], [ 29.765959746835442, -1.406852413793104 ], [ 29.765420759493672, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3564, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.406852413793104 ], [ 29.765959746835442, -1.407121896551724 ], [ 29.766229240506327, -1.407121896551724 ], [ 29.766229240506327, -1.406852413793104 ], [ 29.765959746835442, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3565, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.406852413793104 ], [ 29.766229240506327, -1.407121896551724 ], [ 29.766498734177215, -1.407121896551724 ], [ 29.766498734177215, -1.406852413793104 ], [ 29.766229240506327, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3566, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.406852413793104 ], [ 29.766498734177215, -1.407121896551724 ], [ 29.7667682278481, -1.407121896551724 ], [ 29.7667682278481, -1.406852413793104 ], [ 29.766498734177215, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3567, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.406852413793104 ], [ 29.7667682278481, -1.407121896551724 ], [ 29.767037721518985, -1.407121896551724 ], [ 29.767037721518985, -1.406852413793104 ], [ 29.7667682278481, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3568, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.406852413793104 ], [ 29.767037721518985, -1.407121896551724 ], [ 29.767307215189874, -1.407121896551724 ], [ 29.767307215189874, -1.406852413793104 ], [ 29.767037721518985, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3569, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.406852413793104 ], [ 29.767307215189874, -1.407121896551724 ], [ 29.767576708860759, -1.407121896551724 ], [ 29.767576708860759, -1.406852413793104 ], [ 29.767307215189874, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3570, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.406852413793104 ], [ 29.767576708860759, -1.407121896551724 ], [ 29.767846202531643, -1.407121896551724 ], [ 29.767846202531643, -1.406852413793104 ], [ 29.767576708860759, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3571, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.406852413793104 ], [ 29.767846202531643, -1.407121896551724 ], [ 29.768115696202532, -1.407121896551724 ], [ 29.768115696202532, -1.406852413793104 ], [ 29.767846202531643, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3572, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.406852413793104 ], [ 29.768115696202532, -1.407121896551724 ], [ 29.768385189873417, -1.407121896551724 ], [ 29.768385189873417, -1.406852413793104 ], [ 29.768115696202532, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3573, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.406852413793104 ], [ 29.768385189873417, -1.407121896551724 ], [ 29.768654683544302, -1.407121896551724 ], [ 29.768654683544302, -1.406852413793104 ], [ 29.768385189873417, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3574, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.406852413793104 ], [ 29.768654683544302, -1.407121896551724 ], [ 29.76892417721519, -1.407121896551724 ], [ 29.76892417721519, -1.406852413793104 ], [ 29.768654683544302, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3575, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.406852413793104 ], [ 29.76892417721519, -1.407121896551724 ], [ 29.769193670886075, -1.407121896551724 ], [ 29.769193670886075, -1.406852413793104 ], [ 29.76892417721519, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3576, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.406852413793104 ], [ 29.769193670886075, -1.407121896551724 ], [ 29.76946316455696, -1.407121896551724 ], [ 29.76946316455696, -1.406852413793104 ], [ 29.769193670886075, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3577, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.406852413793104 ], [ 29.76946316455696, -1.407121896551724 ], [ 29.769732658227849, -1.407121896551724 ], [ 29.769732658227849, -1.406852413793104 ], [ 29.76946316455696, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3578, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.406852413793104 ], [ 29.769732658227849, -1.407121896551724 ], [ 29.770002151898733, -1.407121896551724 ], [ 29.770002151898733, -1.406852413793104 ], [ 29.769732658227849, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3579, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.406852413793104 ], [ 29.770002151898733, -1.407121896551724 ], [ 29.770271645569618, -1.407121896551724 ], [ 29.770271645569618, -1.406852413793104 ], [ 29.770002151898733, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3580, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.406852413793104 ], [ 29.770271645569618, -1.407121896551724 ], [ 29.770541139240507, -1.407121896551724 ], [ 29.770541139240507, -1.406852413793104 ], [ 29.770271645569618, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3581, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.406852413793104 ], [ 29.770541139240507, -1.407121896551724 ], [ 29.770810632911392, -1.407121896551724 ], [ 29.770810632911392, -1.406852413793104 ], [ 29.770541139240507, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3582, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.406852413793104 ], [ 29.770810632911392, -1.407121896551724 ], [ 29.771080126582277, -1.407121896551724 ], [ 29.771080126582277, -1.406852413793104 ], [ 29.770810632911392, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3583, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.406852413793104 ], [ 29.771080126582277, -1.407121896551724 ], [ 29.771349620253165, -1.407121896551724 ], [ 29.771349620253165, -1.406852413793104 ], [ 29.771080126582277, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3584, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.406852413793104 ], [ 29.771349620253165, -1.407121896551724 ], [ 29.77161911392405, -1.407121896551724 ], [ 29.77161911392405, -1.406852413793104 ], [ 29.771349620253165, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3585, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.406852413793104 ], [ 29.77161911392405, -1.407121896551724 ], [ 29.771888607594935, -1.407121896551724 ], [ 29.771888607594935, -1.406852413793104 ], [ 29.77161911392405, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3586, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.406852413793104 ], [ 29.771888607594935, -1.407121896551724 ], [ 29.772158101265823, -1.407121896551724 ], [ 29.772158101265823, -1.406852413793104 ], [ 29.771888607594935, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3587, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.406852413793104 ], [ 29.772158101265823, -1.407121896551724 ], [ 29.772427594936708, -1.407121896551724 ], [ 29.772427594936708, -1.406852413793104 ], [ 29.772158101265823, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3588, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.406852413793104 ], [ 29.772427594936708, -1.407121896551724 ], [ 29.772697088607593, -1.407121896551724 ], [ 29.772697088607593, -1.406852413793104 ], [ 29.772427594936708, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3589, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.406852413793104 ], [ 29.772697088607593, -1.407121896551724 ], [ 29.772966582278482, -1.407121896551724 ], [ 29.772966582278482, -1.406852413793104 ], [ 29.772697088607593, -1.406852413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3590, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.405505 ], [ 29.778086962025316, -1.407391379310345 ], [ 29.778356455696201, -1.407391379310345 ], [ 29.778356455696201, -1.405505 ], [ 29.778086962025316, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3591, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.405505 ], [ 29.778356455696201, -1.407660862068966 ], [ 29.77862594936709, -1.407660862068966 ], [ 29.77862594936709, -1.405505 ], [ 29.778356455696201, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3592, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.405505 ], [ 29.77916493670886, -1.407660862068966 ], [ 29.779434430379748, -1.407660862068966 ], [ 29.779434430379748, -1.405505 ], [ 29.77916493670886, -1.405505 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3593, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.405774482758621 ], [ 29.776739493670885, -1.407391379310345 ], [ 29.777008987341773, -1.407391379310345 ], [ 29.777008987341773, -1.405774482758621 ], [ 29.776739493670885, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3594, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.405774482758621 ], [ 29.777008987341773, -1.407660862068966 ], [ 29.777278481012658, -1.407660862068966 ], [ 29.777278481012658, -1.405774482758621 ], [ 29.777008987341773, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3595, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.405774482758621 ], [ 29.777278481012658, -1.407391379310345 ], [ 29.777547974683543, -1.407391379310345 ], [ 29.777547974683543, -1.405774482758621 ], [ 29.777278481012658, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3596, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.405774482758621 ], [ 29.777547974683543, -1.407660862068966 ], [ 29.777817468354431, -1.407660862068966 ], [ 29.777817468354431, -1.405774482758621 ], [ 29.777547974683543, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3597, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.405774482758621 ], [ 29.777817468354431, -1.407660862068966 ], [ 29.778086962025316, -1.407660862068966 ], [ 29.778086962025316, -1.405774482758621 ], [ 29.777817468354431, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3598, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.405774482758621 ], [ 29.778895443037975, -1.407391379310345 ], [ 29.77916493670886, -1.407391379310345 ], [ 29.77916493670886, -1.405774482758621 ], [ 29.778895443037975, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3599, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.405774482758621 ], [ 29.779434430379748, -1.407391379310345 ], [ 29.779703924050633, -1.407391379310345 ], [ 29.779703924050633, -1.405774482758621 ], [ 29.779434430379748, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3600, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.405774482758621 ], [ 29.779703924050633, -1.407391379310345 ], [ 29.779973417721518, -1.407391379310345 ], [ 29.779973417721518, -1.405774482758621 ], [ 29.779703924050633, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3601, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.405774482758621 ], [ 29.780242911392406, -1.407391379310345 ], [ 29.780512405063291, -1.407391379310345 ], [ 29.780512405063291, -1.405774482758621 ], [ 29.780242911392406, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3602, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.405774482758621 ], [ 29.780512405063291, -1.407391379310345 ], [ 29.780781898734176, -1.407391379310345 ], [ 29.780781898734176, -1.405774482758621 ], [ 29.780512405063291, -1.405774482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3603, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.406043965517241 ], [ 29.775392025316457, -1.407660862068966 ], [ 29.775661518987341, -1.407660862068966 ], [ 29.775661518987341, -1.406043965517241 ], [ 29.775392025316457, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3604, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.406043965517241 ], [ 29.775661518987341, -1.407930344827586 ], [ 29.775931012658226, -1.407930344827586 ], [ 29.775931012658226, -1.406043965517241 ], [ 29.775661518987341, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3605, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.406043965517241 ], [ 29.775931012658226, -1.407391379310345 ], [ 29.776200506329115, -1.407391379310345 ], [ 29.776200506329115, -1.406043965517241 ], [ 29.775931012658226, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3606, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.406043965517241 ], [ 29.776200506329115, -1.407660862068966 ], [ 29.77647, -1.407660862068966 ], [ 29.77647, -1.406043965517241 ], [ 29.776200506329115, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3607, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.406043965517241 ], [ 29.77647, -1.407930344827586 ], [ 29.776739493670885, -1.407930344827586 ], [ 29.776739493670885, -1.406043965517241 ], [ 29.77647, -1.406043965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3608, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.406313448275862 ], [ 29.77431405063291, -1.407391379310345 ], [ 29.774853037974683, -1.407391379310345 ], [ 29.774853037974683, -1.406313448275862 ], [ 29.77431405063291, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3609, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.406313448275862 ], [ 29.774853037974683, -1.407660862068966 ], [ 29.775122531645568, -1.407660862068966 ], [ 29.775122531645568, -1.406313448275862 ], [ 29.774853037974683, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3610, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.406313448275862 ], [ 29.775122531645568, -1.407930344827586 ], [ 29.775392025316457, -1.407930344827586 ], [ 29.775392025316457, -1.406313448275862 ], [ 29.775122531645568, -1.406313448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3611, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.406582931034483 ], [ 29.772966582278482, -1.407391379310345 ], [ 29.773236075949367, -1.407391379310345 ], [ 29.773236075949367, -1.406582931034483 ], [ 29.772966582278482, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3612, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.406582931034483 ], [ 29.773236075949367, -1.407930344827586 ], [ 29.773505569620252, -1.407930344827586 ], [ 29.773505569620252, -1.406582931034483 ], [ 29.773236075949367, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3613, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.406582931034483 ], [ 29.773505569620252, -1.407930344827586 ], [ 29.77377506329114, -1.407930344827586 ], [ 29.77377506329114, -1.406582931034483 ], [ 29.773505569620252, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3614, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.406582931034483 ], [ 29.77377506329114, -1.407660862068966 ], [ 29.774044556962025, -1.407660862068966 ], [ 29.774044556962025, -1.406582931034483 ], [ 29.77377506329114, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3615, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.406582931034483 ], [ 29.774044556962025, -1.407930344827586 ], [ 29.77431405063291, -1.407930344827586 ], [ 29.77431405063291, -1.406582931034483 ], [ 29.774044556962025, -1.406582931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3616, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.407121896551724 ], [ 29.761917341772151, -1.407660862068966 ], [ 29.762186835443039, -1.407660862068966 ], [ 29.762186835443039, -1.407121896551724 ], [ 29.761917341772151, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3617, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.407121896551724 ], [ 29.762186835443039, -1.407391379310345 ], [ 29.762456329113924, -1.407391379310345 ], [ 29.762456329113924, -1.407121896551724 ], [ 29.762186835443039, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3618, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.407121896551724 ], [ 29.762456329113924, -1.407391379310345 ], [ 29.762725822784809, -1.407391379310345 ], [ 29.762725822784809, -1.407121896551724 ], [ 29.762456329113924, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3619, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.407121896551724 ], [ 29.762725822784809, -1.407391379310345 ], [ 29.762995316455697, -1.407391379310345 ], [ 29.762995316455697, -1.407121896551724 ], [ 29.762725822784809, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3620, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.407121896551724 ], [ 29.762995316455697, -1.407391379310345 ], [ 29.763264810126582, -1.407391379310345 ], [ 29.763264810126582, -1.407121896551724 ], [ 29.762995316455697, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3621, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.407121896551724 ], [ 29.763264810126582, -1.407391379310345 ], [ 29.763534303797467, -1.407391379310345 ], [ 29.763534303797467, -1.407121896551724 ], [ 29.763264810126582, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3622, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.407121896551724 ], [ 29.763534303797467, -1.407391379310345 ], [ 29.763803797468356, -1.407391379310345 ], [ 29.763803797468356, -1.407121896551724 ], [ 29.763534303797467, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3623, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.407121896551724 ], [ 29.763803797468356, -1.407391379310345 ], [ 29.76407329113924, -1.407391379310345 ], [ 29.76407329113924, -1.407121896551724 ], [ 29.763803797468356, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3624, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.407121896551724 ], [ 29.76407329113924, -1.407391379310345 ], [ 29.764342784810125, -1.407391379310345 ], [ 29.764342784810125, -1.407121896551724 ], [ 29.76407329113924, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3625, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.407121896551724 ], [ 29.764342784810125, -1.407391379310345 ], [ 29.764612278481014, -1.407391379310345 ], [ 29.764612278481014, -1.407121896551724 ], [ 29.764342784810125, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3626, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.407121896551724 ], [ 29.764612278481014, -1.407391379310345 ], [ 29.764881772151899, -1.407391379310345 ], [ 29.764881772151899, -1.407121896551724 ], [ 29.764612278481014, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3627, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.407121896551724 ], [ 29.764881772151899, -1.407391379310345 ], [ 29.765151265822784, -1.407391379310345 ], [ 29.765151265822784, -1.407121896551724 ], [ 29.764881772151899, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3628, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.407121896551724 ], [ 29.765151265822784, -1.407391379310345 ], [ 29.765420759493672, -1.407391379310345 ], [ 29.765420759493672, -1.407121896551724 ], [ 29.765151265822784, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3629, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.407121896551724 ], [ 29.765420759493672, -1.407391379310345 ], [ 29.765959746835442, -1.407391379310345 ], [ 29.765959746835442, -1.407121896551724 ], [ 29.765420759493672, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3630, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.407121896551724 ], [ 29.765959746835442, -1.407391379310345 ], [ 29.766229240506327, -1.407391379310345 ], [ 29.766229240506327, -1.407121896551724 ], [ 29.765959746835442, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3631, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.407121896551724 ], [ 29.766229240506327, -1.407391379310345 ], [ 29.766498734177215, -1.407391379310345 ], [ 29.766498734177215, -1.407121896551724 ], [ 29.766229240506327, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3632, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.407121896551724 ], [ 29.766498734177215, -1.407391379310345 ], [ 29.7667682278481, -1.407391379310345 ], [ 29.7667682278481, -1.407121896551724 ], [ 29.766498734177215, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3633, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.407121896551724 ], [ 29.7667682278481, -1.407391379310345 ], [ 29.767037721518985, -1.407391379310345 ], [ 29.767037721518985, -1.407121896551724 ], [ 29.7667682278481, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3634, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.407121896551724 ], [ 29.767037721518985, -1.407391379310345 ], [ 29.767307215189874, -1.407391379310345 ], [ 29.767307215189874, -1.407121896551724 ], [ 29.767037721518985, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3635, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.407121896551724 ], [ 29.767307215189874, -1.407391379310345 ], [ 29.767576708860759, -1.407391379310345 ], [ 29.767576708860759, -1.407121896551724 ], [ 29.767307215189874, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3636, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.407121896551724 ], [ 29.767576708860759, -1.407391379310345 ], [ 29.767846202531643, -1.407391379310345 ], [ 29.767846202531643, -1.407121896551724 ], [ 29.767576708860759, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3637, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.407121896551724 ], [ 29.767846202531643, -1.407391379310345 ], [ 29.768115696202532, -1.407391379310345 ], [ 29.768115696202532, -1.407121896551724 ], [ 29.767846202531643, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3638, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.407121896551724 ], [ 29.768115696202532, -1.407391379310345 ], [ 29.768385189873417, -1.407391379310345 ], [ 29.768385189873417, -1.407121896551724 ], [ 29.768115696202532, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3639, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.407121896551724 ], [ 29.768385189873417, -1.407391379310345 ], [ 29.768654683544302, -1.407391379310345 ], [ 29.768654683544302, -1.407121896551724 ], [ 29.768385189873417, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3640, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.407121896551724 ], [ 29.768654683544302, -1.407391379310345 ], [ 29.76892417721519, -1.407391379310345 ], [ 29.76892417721519, -1.407121896551724 ], [ 29.768654683544302, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3641, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.407121896551724 ], [ 29.76892417721519, -1.407391379310345 ], [ 29.769193670886075, -1.407391379310345 ], [ 29.769193670886075, -1.407121896551724 ], [ 29.76892417721519, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3642, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.407121896551724 ], [ 29.769193670886075, -1.407391379310345 ], [ 29.76946316455696, -1.407391379310345 ], [ 29.76946316455696, -1.407121896551724 ], [ 29.769193670886075, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3643, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.407121896551724 ], [ 29.76946316455696, -1.407391379310345 ], [ 29.769732658227849, -1.407391379310345 ], [ 29.769732658227849, -1.407121896551724 ], [ 29.76946316455696, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3644, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.407121896551724 ], [ 29.769732658227849, -1.407391379310345 ], [ 29.770002151898733, -1.407391379310345 ], [ 29.770002151898733, -1.407121896551724 ], [ 29.769732658227849, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3645, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.407121896551724 ], [ 29.770002151898733, -1.407391379310345 ], [ 29.770271645569618, -1.407391379310345 ], [ 29.770271645569618, -1.407121896551724 ], [ 29.770002151898733, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3646, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.407121896551724 ], [ 29.770271645569618, -1.407391379310345 ], [ 29.770541139240507, -1.407391379310345 ], [ 29.770541139240507, -1.407121896551724 ], [ 29.770271645569618, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3647, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.407121896551724 ], [ 29.770541139240507, -1.407391379310345 ], [ 29.770810632911392, -1.407391379310345 ], [ 29.770810632911392, -1.407121896551724 ], [ 29.770541139240507, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3648, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.407121896551724 ], [ 29.770810632911392, -1.407391379310345 ], [ 29.771080126582277, -1.407391379310345 ], [ 29.771080126582277, -1.407121896551724 ], [ 29.770810632911392, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3649, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.407121896551724 ], [ 29.771080126582277, -1.407391379310345 ], [ 29.771349620253165, -1.407391379310345 ], [ 29.771349620253165, -1.407121896551724 ], [ 29.771080126582277, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3650, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.407121896551724 ], [ 29.771349620253165, -1.407391379310345 ], [ 29.77161911392405, -1.407391379310345 ], [ 29.77161911392405, -1.407121896551724 ], [ 29.771349620253165, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3651, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.407121896551724 ], [ 29.77161911392405, -1.407391379310345 ], [ 29.771888607594935, -1.407391379310345 ], [ 29.771888607594935, -1.407121896551724 ], [ 29.77161911392405, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3652, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.407121896551724 ], [ 29.771888607594935, -1.407391379310345 ], [ 29.772158101265823, -1.407391379310345 ], [ 29.772158101265823, -1.407121896551724 ], [ 29.771888607594935, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3653, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.407121896551724 ], [ 29.772158101265823, -1.407391379310345 ], [ 29.772427594936708, -1.407391379310345 ], [ 29.772427594936708, -1.407121896551724 ], [ 29.772158101265823, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3654, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.407121896551724 ], [ 29.772427594936708, -1.407391379310345 ], [ 29.772697088607593, -1.407391379310345 ], [ 29.772697088607593, -1.407121896551724 ], [ 29.772427594936708, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3655, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.407121896551724 ], [ 29.772697088607593, -1.407391379310345 ], [ 29.772966582278482, -1.407391379310345 ], [ 29.772966582278482, -1.407121896551724 ], [ 29.772697088607593, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3656, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.407121896551724 ], [ 29.77862594936709, -1.407660862068966 ], [ 29.778895443037975, -1.407660862068966 ], [ 29.778895443037975, -1.407121896551724 ], [ 29.77862594936709, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3657, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.407121896551724 ], [ 29.779973417721518, -1.407660862068966 ], [ 29.780242911392406, -1.407660862068966 ], [ 29.780242911392406, -1.407121896551724 ], [ 29.779973417721518, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3658, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.407121896551724 ], [ 29.780781898734176, -1.407391379310345 ], [ 29.781051392405065, -1.407391379310345 ], [ 29.781051392405065, -1.407121896551724 ], [ 29.780781898734176, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3659, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.407121896551724 ], [ 29.781051392405065, -1.407391379310345 ], [ 29.78132088607595, -1.407391379310345 ], [ 29.78132088607595, -1.407121896551724 ], [ 29.781051392405065, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3660, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.407121896551724 ], [ 29.78132088607595, -1.407391379310345 ], [ 29.781590379746834, -1.407391379310345 ], [ 29.781590379746834, -1.407121896551724 ], [ 29.78132088607595, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3661, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.407121896551724 ], [ 29.781590379746834, -1.407391379310345 ], [ 29.781859873417723, -1.407391379310345 ], [ 29.781859873417723, -1.407121896551724 ], [ 29.781590379746834, -1.407121896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3662, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.407391379310345 ], [ 29.762186835443039, -1.407660862068966 ], [ 29.762456329113924, -1.407660862068966 ], [ 29.762456329113924, -1.407391379310345 ], [ 29.762186835443039, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3663, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.407391379310345 ], [ 29.762456329113924, -1.407660862068966 ], [ 29.762725822784809, -1.407660862068966 ], [ 29.762725822784809, -1.407391379310345 ], [ 29.762456329113924, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3664, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.407391379310345 ], [ 29.762725822784809, -1.407660862068966 ], [ 29.762995316455697, -1.407660862068966 ], [ 29.762995316455697, -1.407391379310345 ], [ 29.762725822784809, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3665, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.407391379310345 ], [ 29.762995316455697, -1.407660862068966 ], [ 29.763264810126582, -1.407660862068966 ], [ 29.763264810126582, -1.407391379310345 ], [ 29.762995316455697, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3666, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.407391379310345 ], [ 29.763264810126582, -1.407660862068966 ], [ 29.763534303797467, -1.407660862068966 ], [ 29.763534303797467, -1.407391379310345 ], [ 29.763264810126582, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3667, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.407391379310345 ], [ 29.763534303797467, -1.407660862068966 ], [ 29.763803797468356, -1.407660862068966 ], [ 29.763803797468356, -1.407391379310345 ], [ 29.763534303797467, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3668, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.407391379310345 ], [ 29.763803797468356, -1.407660862068966 ], [ 29.76407329113924, -1.407660862068966 ], [ 29.76407329113924, -1.407391379310345 ], [ 29.763803797468356, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3669, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.407391379310345 ], [ 29.76407329113924, -1.407660862068966 ], [ 29.764342784810125, -1.407660862068966 ], [ 29.764342784810125, -1.407391379310345 ], [ 29.76407329113924, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3670, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.407391379310345 ], [ 29.764342784810125, -1.407660862068966 ], [ 29.764612278481014, -1.407660862068966 ], [ 29.764612278481014, -1.407391379310345 ], [ 29.764342784810125, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3671, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.407391379310345 ], [ 29.764612278481014, -1.407660862068966 ], [ 29.764881772151899, -1.407660862068966 ], [ 29.764881772151899, -1.407391379310345 ], [ 29.764612278481014, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3672, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.407391379310345 ], [ 29.764881772151899, -1.407660862068966 ], [ 29.765151265822784, -1.407660862068966 ], [ 29.765151265822784, -1.407391379310345 ], [ 29.764881772151899, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3673, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.407391379310345 ], [ 29.765151265822784, -1.407660862068966 ], [ 29.765420759493672, -1.407660862068966 ], [ 29.765420759493672, -1.407391379310345 ], [ 29.765151265822784, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3674, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.407391379310345 ], [ 29.765420759493672, -1.407660862068966 ], [ 29.765959746835442, -1.407660862068966 ], [ 29.765959746835442, -1.407391379310345 ], [ 29.765420759493672, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3675, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.407391379310345 ], [ 29.765959746835442, -1.407660862068966 ], [ 29.766229240506327, -1.407660862068966 ], [ 29.766229240506327, -1.407391379310345 ], [ 29.765959746835442, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3676, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.407391379310345 ], [ 29.766229240506327, -1.407660862068966 ], [ 29.766498734177215, -1.407660862068966 ], [ 29.766498734177215, -1.407391379310345 ], [ 29.766229240506327, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3677, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.407391379310345 ], [ 29.766498734177215, -1.407660862068966 ], [ 29.7667682278481, -1.407660862068966 ], [ 29.7667682278481, -1.407391379310345 ], [ 29.766498734177215, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3678, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.407391379310345 ], [ 29.7667682278481, -1.407660862068966 ], [ 29.767037721518985, -1.407660862068966 ], [ 29.767037721518985, -1.407391379310345 ], [ 29.7667682278481, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3679, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.407391379310345 ], [ 29.767037721518985, -1.407660862068966 ], [ 29.767307215189874, -1.407660862068966 ], [ 29.767307215189874, -1.407391379310345 ], [ 29.767037721518985, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3680, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.407391379310345 ], [ 29.767307215189874, -1.407660862068966 ], [ 29.767576708860759, -1.407660862068966 ], [ 29.767576708860759, -1.407391379310345 ], [ 29.767307215189874, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3681, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.407391379310345 ], [ 29.767576708860759, -1.407660862068966 ], [ 29.767846202531643, -1.407660862068966 ], [ 29.767846202531643, -1.407391379310345 ], [ 29.767576708860759, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3682, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.407391379310345 ], [ 29.767846202531643, -1.407660862068966 ], [ 29.768115696202532, -1.407660862068966 ], [ 29.768115696202532, -1.407391379310345 ], [ 29.767846202531643, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3683, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.407391379310345 ], [ 29.768115696202532, -1.407660862068966 ], [ 29.768385189873417, -1.407660862068966 ], [ 29.768385189873417, -1.407391379310345 ], [ 29.768115696202532, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3684, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.407391379310345 ], [ 29.768385189873417, -1.407660862068966 ], [ 29.768654683544302, -1.407660862068966 ], [ 29.768654683544302, -1.407391379310345 ], [ 29.768385189873417, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3685, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.407391379310345 ], [ 29.768654683544302, -1.407660862068966 ], [ 29.76892417721519, -1.407660862068966 ], [ 29.76892417721519, -1.407391379310345 ], [ 29.768654683544302, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3686, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.407391379310345 ], [ 29.76892417721519, -1.407660862068966 ], [ 29.769193670886075, -1.407660862068966 ], [ 29.769193670886075, -1.407391379310345 ], [ 29.76892417721519, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3687, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.407391379310345 ], [ 29.769193670886075, -1.407660862068966 ], [ 29.76946316455696, -1.407660862068966 ], [ 29.76946316455696, -1.407391379310345 ], [ 29.769193670886075, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3688, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.407391379310345 ], [ 29.76946316455696, -1.407660862068966 ], [ 29.769732658227849, -1.407660862068966 ], [ 29.769732658227849, -1.407391379310345 ], [ 29.76946316455696, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3689, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.407391379310345 ], [ 29.769732658227849, -1.407660862068966 ], [ 29.770002151898733, -1.407660862068966 ], [ 29.770002151898733, -1.407391379310345 ], [ 29.769732658227849, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3690, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.407391379310345 ], [ 29.770002151898733, -1.407660862068966 ], [ 29.770271645569618, -1.407660862068966 ], [ 29.770271645569618, -1.407391379310345 ], [ 29.770002151898733, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3691, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.407391379310345 ], [ 29.770271645569618, -1.407660862068966 ], [ 29.770541139240507, -1.407660862068966 ], [ 29.770541139240507, -1.407391379310345 ], [ 29.770271645569618, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3692, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.407391379310345 ], [ 29.770541139240507, -1.407660862068966 ], [ 29.770810632911392, -1.407660862068966 ], [ 29.770810632911392, -1.407391379310345 ], [ 29.770541139240507, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3693, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.407391379310345 ], [ 29.770810632911392, -1.407660862068966 ], [ 29.771080126582277, -1.407660862068966 ], [ 29.771080126582277, -1.407391379310345 ], [ 29.770810632911392, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3694, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.407391379310345 ], [ 29.771080126582277, -1.407660862068966 ], [ 29.771349620253165, -1.407660862068966 ], [ 29.771349620253165, -1.407391379310345 ], [ 29.771080126582277, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3695, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.407391379310345 ], [ 29.771349620253165, -1.407660862068966 ], [ 29.77161911392405, -1.407660862068966 ], [ 29.77161911392405, -1.407391379310345 ], [ 29.771349620253165, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3696, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.407391379310345 ], [ 29.77161911392405, -1.407660862068966 ], [ 29.771888607594935, -1.407660862068966 ], [ 29.771888607594935, -1.407391379310345 ], [ 29.77161911392405, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3697, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.407391379310345 ], [ 29.771888607594935, -1.407660862068966 ], [ 29.772158101265823, -1.407660862068966 ], [ 29.772158101265823, -1.407391379310345 ], [ 29.771888607594935, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3698, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.407391379310345 ], [ 29.772158101265823, -1.407660862068966 ], [ 29.772427594936708, -1.407660862068966 ], [ 29.772427594936708, -1.407391379310345 ], [ 29.772158101265823, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3699, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.407391379310345 ], [ 29.772427594936708, -1.407660862068966 ], [ 29.772697088607593, -1.407660862068966 ], [ 29.772697088607593, -1.407391379310345 ], [ 29.772427594936708, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3700, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.407391379310345 ], [ 29.772697088607593, -1.407660862068966 ], [ 29.772966582278482, -1.407660862068966 ], [ 29.772966582278482, -1.407391379310345 ], [ 29.772697088607593, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3701, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.407391379310345 ], [ 29.772966582278482, -1.408469310344828 ], [ 29.773236075949367, -1.408469310344828 ], [ 29.773236075949367, -1.407391379310345 ], [ 29.772966582278482, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3702, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.407391379310345 ], [ 29.77431405063291, -1.408469310344828 ], [ 29.774853037974683, -1.408469310344828 ], [ 29.774853037974683, -1.407391379310345 ], [ 29.77431405063291, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3703, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.407391379310345 ], [ 29.775931012658226, -1.407930344827586 ], [ 29.776200506329115, -1.407930344827586 ], [ 29.776200506329115, -1.407391379310345 ], [ 29.775931012658226, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3704, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.407391379310345 ], [ 29.776739493670885, -1.407930344827586 ], [ 29.777008987341773, -1.407930344827586 ], [ 29.777008987341773, -1.407391379310345 ], [ 29.776739493670885, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3705, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.407391379310345 ], [ 29.777278481012658, -1.407930344827586 ], [ 29.777547974683543, -1.407930344827586 ], [ 29.777547974683543, -1.407391379310345 ], [ 29.777278481012658, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3706, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.407391379310345 ], [ 29.778086962025316, -1.407930344827586 ], [ 29.778356455696201, -1.407930344827586 ], [ 29.778356455696201, -1.407391379310345 ], [ 29.778086962025316, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3707, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.407391379310345 ], [ 29.778895443037975, -1.407930344827586 ], [ 29.77916493670886, -1.407930344827586 ], [ 29.77916493670886, -1.407391379310345 ], [ 29.778895443037975, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3708, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.407391379310345 ], [ 29.779434430379748, -1.407660862068966 ], [ 29.779703924050633, -1.407660862068966 ], [ 29.779703924050633, -1.407391379310345 ], [ 29.779434430379748, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3709, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.407391379310345 ], [ 29.779703924050633, -1.407660862068966 ], [ 29.779973417721518, -1.407660862068966 ], [ 29.779973417721518, -1.407391379310345 ], [ 29.779703924050633, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3710, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.407391379310345 ], [ 29.780242911392406, -1.407660862068966 ], [ 29.780512405063291, -1.407660862068966 ], [ 29.780512405063291, -1.407391379310345 ], [ 29.780242911392406, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3711, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.407391379310345 ], [ 29.780512405063291, -1.407660862068966 ], [ 29.780781898734176, -1.407660862068966 ], [ 29.780781898734176, -1.407391379310345 ], [ 29.780512405063291, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3712, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.407391379310345 ], [ 29.780781898734176, -1.407660862068966 ], [ 29.781051392405065, -1.407660862068966 ], [ 29.781051392405065, -1.407391379310345 ], [ 29.780781898734176, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3713, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.407391379310345 ], [ 29.781051392405065, -1.407660862068966 ], [ 29.78132088607595, -1.407660862068966 ], [ 29.78132088607595, -1.407391379310345 ], [ 29.781051392405065, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.407391379310345 ], [ 29.78132088607595, -1.407660862068966 ], [ 29.781590379746834, -1.407660862068966 ], [ 29.781590379746834, -1.407391379310345 ], [ 29.78132088607595, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3715, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.407391379310345 ], [ 29.781590379746834, -1.407660862068966 ], [ 29.781859873417723, -1.407660862068966 ], [ 29.781859873417723, -1.407391379310345 ], [ 29.781590379746834, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3716, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.407391379310345 ], [ 29.781859873417723, -1.407930344827586 ], [ 29.782129367088608, -1.407930344827586 ], [ 29.782129367088608, -1.407391379310345 ], [ 29.781859873417723, -1.407391379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3717, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.407660862068966 ], [ 29.761917341772151, -1.407930344827586 ], [ 29.762186835443039, -1.407930344827586 ], [ 29.762186835443039, -1.407660862068966 ], [ 29.761917341772151, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3718, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.407660862068966 ], [ 29.762186835443039, -1.407930344827586 ], [ 29.762456329113924, -1.407930344827586 ], [ 29.762456329113924, -1.407660862068966 ], [ 29.762186835443039, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3719, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.407660862068966 ], [ 29.762456329113924, -1.407930344827586 ], [ 29.762725822784809, -1.407930344827586 ], [ 29.762725822784809, -1.407660862068966 ], [ 29.762456329113924, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3720, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.407660862068966 ], [ 29.762725822784809, -1.407930344827586 ], [ 29.762995316455697, -1.407930344827586 ], [ 29.762995316455697, -1.407660862068966 ], [ 29.762725822784809, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3721, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.407660862068966 ], [ 29.762995316455697, -1.407930344827586 ], [ 29.763264810126582, -1.407930344827586 ], [ 29.763264810126582, -1.407660862068966 ], [ 29.762995316455697, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3722, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.407660862068966 ], [ 29.763264810126582, -1.407930344827586 ], [ 29.763534303797467, -1.407930344827586 ], [ 29.763534303797467, -1.407660862068966 ], [ 29.763264810126582, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3723, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.407660862068966 ], [ 29.763534303797467, -1.407930344827586 ], [ 29.763803797468356, -1.407930344827586 ], [ 29.763803797468356, -1.407660862068966 ], [ 29.763534303797467, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3724, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.407660862068966 ], [ 29.763803797468356, -1.407930344827586 ], [ 29.76407329113924, -1.407930344827586 ], [ 29.76407329113924, -1.407660862068966 ], [ 29.763803797468356, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3725, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.407660862068966 ], [ 29.76407329113924, -1.407930344827586 ], [ 29.764342784810125, -1.407930344827586 ], [ 29.764342784810125, -1.407660862068966 ], [ 29.76407329113924, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3726, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.407660862068966 ], [ 29.764342784810125, -1.407930344827586 ], [ 29.764612278481014, -1.407930344827586 ], [ 29.764612278481014, -1.407660862068966 ], [ 29.764342784810125, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3727, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.407660862068966 ], [ 29.764612278481014, -1.407930344827586 ], [ 29.764881772151899, -1.407930344827586 ], [ 29.764881772151899, -1.407660862068966 ], [ 29.764612278481014, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3728, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.407660862068966 ], [ 29.764881772151899, -1.407930344827586 ], [ 29.765151265822784, -1.407930344827586 ], [ 29.765151265822784, -1.407660862068966 ], [ 29.764881772151899, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3729, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.407660862068966 ], [ 29.765151265822784, -1.407930344827586 ], [ 29.765420759493672, -1.407930344827586 ], [ 29.765420759493672, -1.407660862068966 ], [ 29.765151265822784, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3730, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.407660862068966 ], [ 29.765420759493672, -1.407930344827586 ], [ 29.765959746835442, -1.407930344827586 ], [ 29.765959746835442, -1.407660862068966 ], [ 29.765420759493672, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3731, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.407660862068966 ], [ 29.765959746835442, -1.407930344827586 ], [ 29.766229240506327, -1.407930344827586 ], [ 29.766229240506327, -1.407660862068966 ], [ 29.765959746835442, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3732, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.407660862068966 ], [ 29.766229240506327, -1.407930344827586 ], [ 29.766498734177215, -1.407930344827586 ], [ 29.766498734177215, -1.407660862068966 ], [ 29.766229240506327, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3733, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.407660862068966 ], [ 29.766498734177215, -1.407930344827586 ], [ 29.7667682278481, -1.407930344827586 ], [ 29.7667682278481, -1.407660862068966 ], [ 29.766498734177215, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3734, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.407660862068966 ], [ 29.7667682278481, -1.407930344827586 ], [ 29.767037721518985, -1.407930344827586 ], [ 29.767037721518985, -1.407660862068966 ], [ 29.7667682278481, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3735, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.407660862068966 ], [ 29.767037721518985, -1.407930344827586 ], [ 29.767307215189874, -1.407930344827586 ], [ 29.767307215189874, -1.407660862068966 ], [ 29.767037721518985, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3736, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.407660862068966 ], [ 29.767307215189874, -1.407930344827586 ], [ 29.767576708860759, -1.407930344827586 ], [ 29.767576708860759, -1.407660862068966 ], [ 29.767307215189874, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3737, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.407660862068966 ], [ 29.767576708860759, -1.407930344827586 ], [ 29.767846202531643, -1.407930344827586 ], [ 29.767846202531643, -1.407660862068966 ], [ 29.767576708860759, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3738, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.407660862068966 ], [ 29.767846202531643, -1.407930344827586 ], [ 29.768115696202532, -1.407930344827586 ], [ 29.768115696202532, -1.407660862068966 ], [ 29.767846202531643, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3739, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.407660862068966 ], [ 29.768115696202532, -1.407930344827586 ], [ 29.768385189873417, -1.407930344827586 ], [ 29.768385189873417, -1.407660862068966 ], [ 29.768115696202532, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3740, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.407660862068966 ], [ 29.768385189873417, -1.407930344827586 ], [ 29.768654683544302, -1.407930344827586 ], [ 29.768654683544302, -1.407660862068966 ], [ 29.768385189873417, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3741, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.407660862068966 ], [ 29.768654683544302, -1.407930344827586 ], [ 29.76892417721519, -1.407930344827586 ], [ 29.76892417721519, -1.407660862068966 ], [ 29.768654683544302, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3742, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.407660862068966 ], [ 29.76892417721519, -1.407930344827586 ], [ 29.769193670886075, -1.407930344827586 ], [ 29.769193670886075, -1.407660862068966 ], [ 29.76892417721519, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3743, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.407660862068966 ], [ 29.769193670886075, -1.407930344827586 ], [ 29.76946316455696, -1.407930344827586 ], [ 29.76946316455696, -1.407660862068966 ], [ 29.769193670886075, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3744, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.407660862068966 ], [ 29.76946316455696, -1.407930344827586 ], [ 29.769732658227849, -1.407930344827586 ], [ 29.769732658227849, -1.407660862068966 ], [ 29.76946316455696, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3745, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.407660862068966 ], [ 29.769732658227849, -1.407930344827586 ], [ 29.770002151898733, -1.407930344827586 ], [ 29.770002151898733, -1.407660862068966 ], [ 29.769732658227849, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3746, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.407660862068966 ], [ 29.770002151898733, -1.407930344827586 ], [ 29.770271645569618, -1.407930344827586 ], [ 29.770271645569618, -1.407660862068966 ], [ 29.770002151898733, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3747, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.407660862068966 ], [ 29.770271645569618, -1.407930344827586 ], [ 29.770541139240507, -1.407930344827586 ], [ 29.770541139240507, -1.407660862068966 ], [ 29.770271645569618, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3748, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.407660862068966 ], [ 29.770541139240507, -1.407930344827586 ], [ 29.770810632911392, -1.407930344827586 ], [ 29.770810632911392, -1.407660862068966 ], [ 29.770541139240507, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3749, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.407660862068966 ], [ 29.770810632911392, -1.407930344827586 ], [ 29.771080126582277, -1.407930344827586 ], [ 29.771080126582277, -1.407660862068966 ], [ 29.770810632911392, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3750, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.407660862068966 ], [ 29.771080126582277, -1.407930344827586 ], [ 29.771349620253165, -1.407930344827586 ], [ 29.771349620253165, -1.407660862068966 ], [ 29.771080126582277, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3751, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.407660862068966 ], [ 29.771349620253165, -1.407930344827586 ], [ 29.77161911392405, -1.407930344827586 ], [ 29.77161911392405, -1.407660862068966 ], [ 29.771349620253165, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3752, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.407660862068966 ], [ 29.77161911392405, -1.407930344827586 ], [ 29.771888607594935, -1.407930344827586 ], [ 29.771888607594935, -1.407660862068966 ], [ 29.77161911392405, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3753, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.407660862068966 ], [ 29.771888607594935, -1.407930344827586 ], [ 29.772158101265823, -1.407930344827586 ], [ 29.772158101265823, -1.407660862068966 ], [ 29.771888607594935, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3754, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.407660862068966 ], [ 29.772158101265823, -1.407930344827586 ], [ 29.772427594936708, -1.407930344827586 ], [ 29.772427594936708, -1.407660862068966 ], [ 29.772158101265823, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3755, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.407660862068966 ], [ 29.772427594936708, -1.407930344827586 ], [ 29.772697088607593, -1.407930344827586 ], [ 29.772697088607593, -1.407660862068966 ], [ 29.772427594936708, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3756, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.407660862068966 ], [ 29.772697088607593, -1.407930344827586 ], [ 29.772966582278482, -1.407930344827586 ], [ 29.772966582278482, -1.407660862068966 ], [ 29.772697088607593, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3757, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.407660862068966 ], [ 29.77377506329114, -1.408469310344828 ], [ 29.774044556962025, -1.408469310344828 ], [ 29.774044556962025, -1.407660862068966 ], [ 29.77377506329114, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3758, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.407660862068966 ], [ 29.774853037974683, -1.408469310344828 ], [ 29.775122531645568, -1.408469310344828 ], [ 29.775122531645568, -1.407660862068966 ], [ 29.774853037974683, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3759, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.407660862068966 ], [ 29.775392025316457, -1.408469310344828 ], [ 29.775661518987341, -1.408469310344828 ], [ 29.775661518987341, -1.407660862068966 ], [ 29.775392025316457, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3760, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.407660862068966 ], [ 29.776200506329115, -1.408469310344828 ], [ 29.77647, -1.408469310344828 ], [ 29.77647, -1.407660862068966 ], [ 29.776200506329115, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3761, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.407660862068966 ], [ 29.777008987341773, -1.408469310344828 ], [ 29.777278481012658, -1.408469310344828 ], [ 29.777278481012658, -1.407660862068966 ], [ 29.777008987341773, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3762, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.407660862068966 ], [ 29.777547974683543, -1.407930344827586 ], [ 29.777817468354431, -1.407930344827586 ], [ 29.777817468354431, -1.407660862068966 ], [ 29.777547974683543, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3763, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.407660862068966 ], [ 29.777817468354431, -1.408469310344828 ], [ 29.778086962025316, -1.408469310344828 ], [ 29.778086962025316, -1.407660862068966 ], [ 29.777817468354431, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3764, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.407660862068966 ], [ 29.778356455696201, -1.407930344827586 ], [ 29.77862594936709, -1.407930344827586 ], [ 29.77862594936709, -1.407660862068966 ], [ 29.778356455696201, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3765, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.407660862068966 ], [ 29.77862594936709, -1.407930344827586 ], [ 29.778895443037975, -1.407930344827586 ], [ 29.778895443037975, -1.407660862068966 ], [ 29.77862594936709, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3766, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.407660862068966 ], [ 29.77916493670886, -1.407930344827586 ], [ 29.779434430379748, -1.407930344827586 ], [ 29.779434430379748, -1.407660862068966 ], [ 29.77916493670886, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3767, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.407660862068966 ], [ 29.779434430379748, -1.407930344827586 ], [ 29.779703924050633, -1.407930344827586 ], [ 29.779703924050633, -1.407660862068966 ], [ 29.779434430379748, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3768, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.407660862068966 ], [ 29.779703924050633, -1.407930344827586 ], [ 29.779973417721518, -1.407930344827586 ], [ 29.779973417721518, -1.407660862068966 ], [ 29.779703924050633, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3769, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.407660862068966 ], [ 29.779973417721518, -1.407930344827586 ], [ 29.780242911392406, -1.407930344827586 ], [ 29.780242911392406, -1.407660862068966 ], [ 29.779973417721518, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3770, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.407660862068966 ], [ 29.780242911392406, -1.407930344827586 ], [ 29.780512405063291, -1.407930344827586 ], [ 29.780512405063291, -1.407660862068966 ], [ 29.780242911392406, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3771, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.407660862068966 ], [ 29.780512405063291, -1.407930344827586 ], [ 29.780781898734176, -1.407930344827586 ], [ 29.780781898734176, -1.407660862068966 ], [ 29.780512405063291, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3772, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.407660862068966 ], [ 29.780781898734176, -1.407930344827586 ], [ 29.781051392405065, -1.407930344827586 ], [ 29.781051392405065, -1.407660862068966 ], [ 29.780781898734176, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3773, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.407660862068966 ], [ 29.781051392405065, -1.407930344827586 ], [ 29.78132088607595, -1.407930344827586 ], [ 29.78132088607595, -1.407660862068966 ], [ 29.781051392405065, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3774, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.407660862068966 ], [ 29.78132088607595, -1.407930344827586 ], [ 29.781590379746834, -1.407930344827586 ], [ 29.781590379746834, -1.407660862068966 ], [ 29.78132088607595, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3775, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.407660862068966 ], [ 29.781590379746834, -1.407930344827586 ], [ 29.781859873417723, -1.407930344827586 ], [ 29.781859873417723, -1.407660862068966 ], [ 29.781590379746834, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3776, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.407930344827586 ], [ 29.762456329113924, -1.408469310344828 ], [ 29.762725822784809, -1.408469310344828 ], [ 29.762725822784809, -1.407930344827586 ], [ 29.762456329113924, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3777, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.407930344827586 ], [ 29.762725822784809, -1.408469310344828 ], [ 29.762995316455697, -1.408469310344828 ], [ 29.762995316455697, -1.407930344827586 ], [ 29.762725822784809, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3778, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.407930344827586 ], [ 29.762995316455697, -1.408469310344828 ], [ 29.763264810126582, -1.408469310344828 ], [ 29.763264810126582, -1.407930344827586 ], [ 29.762995316455697, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3779, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.407930344827586 ], [ 29.763264810126582, -1.408469310344828 ], [ 29.763534303797467, -1.408469310344828 ], [ 29.763534303797467, -1.407930344827586 ], [ 29.763264810126582, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3780, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.407930344827586 ], [ 29.763534303797467, -1.408469310344828 ], [ 29.763803797468356, -1.408469310344828 ], [ 29.763803797468356, -1.407930344827586 ], [ 29.763534303797467, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3781, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.407930344827586 ], [ 29.763803797468356, -1.408469310344828 ], [ 29.76407329113924, -1.408469310344828 ], [ 29.76407329113924, -1.407930344827586 ], [ 29.763803797468356, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3782, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.407930344827586 ], [ 29.76407329113924, -1.408469310344828 ], [ 29.764342784810125, -1.408469310344828 ], [ 29.764342784810125, -1.407930344827586 ], [ 29.76407329113924, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3783, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.407930344827586 ], [ 29.764342784810125, -1.408469310344828 ], [ 29.764612278481014, -1.408469310344828 ], [ 29.764612278481014, -1.407930344827586 ], [ 29.764342784810125, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3784, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.407930344827586 ], [ 29.764612278481014, -1.408469310344828 ], [ 29.764881772151899, -1.408469310344828 ], [ 29.764881772151899, -1.407930344827586 ], [ 29.764612278481014, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3785, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.407930344827586 ], [ 29.764881772151899, -1.408469310344828 ], [ 29.765151265822784, -1.408469310344828 ], [ 29.765151265822784, -1.407930344827586 ], [ 29.764881772151899, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3786, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.407930344827586 ], [ 29.765151265822784, -1.408469310344828 ], [ 29.765420759493672, -1.408469310344828 ], [ 29.765420759493672, -1.407930344827586 ], [ 29.765151265822784, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3787, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.407930344827586 ], [ 29.765420759493672, -1.408469310344828 ], [ 29.765959746835442, -1.408469310344828 ], [ 29.765959746835442, -1.407930344827586 ], [ 29.765420759493672, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3788, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.407930344827586 ], [ 29.765959746835442, -1.408469310344828 ], [ 29.766229240506327, -1.408469310344828 ], [ 29.766229240506327, -1.407930344827586 ], [ 29.765959746835442, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3789, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.407930344827586 ], [ 29.766229240506327, -1.408469310344828 ], [ 29.766498734177215, -1.408469310344828 ], [ 29.766498734177215, -1.407930344827586 ], [ 29.766229240506327, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3790, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.407930344827586 ], [ 29.766498734177215, -1.408469310344828 ], [ 29.7667682278481, -1.408469310344828 ], [ 29.7667682278481, -1.407930344827586 ], [ 29.766498734177215, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3791, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.407930344827586 ], [ 29.7667682278481, -1.408469310344828 ], [ 29.767037721518985, -1.408469310344828 ], [ 29.767037721518985, -1.407930344827586 ], [ 29.7667682278481, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3792, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.407930344827586 ], [ 29.767037721518985, -1.408469310344828 ], [ 29.767307215189874, -1.408469310344828 ], [ 29.767307215189874, -1.407930344827586 ], [ 29.767037721518985, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3793, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.407930344827586 ], [ 29.767307215189874, -1.408469310344828 ], [ 29.767576708860759, -1.408469310344828 ], [ 29.767576708860759, -1.407930344827586 ], [ 29.767307215189874, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3794, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.407930344827586 ], [ 29.767576708860759, -1.408469310344828 ], [ 29.767846202531643, -1.408469310344828 ], [ 29.767846202531643, -1.407930344827586 ], [ 29.767576708860759, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3795, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.407930344827586 ], [ 29.767846202531643, -1.408469310344828 ], [ 29.768115696202532, -1.408469310344828 ], [ 29.768115696202532, -1.407930344827586 ], [ 29.767846202531643, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3796, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.407930344827586 ], [ 29.768115696202532, -1.408469310344828 ], [ 29.768385189873417, -1.408469310344828 ], [ 29.768385189873417, -1.407930344827586 ], [ 29.768115696202532, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3797, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.407930344827586 ], [ 29.768385189873417, -1.408469310344828 ], [ 29.768654683544302, -1.408469310344828 ], [ 29.768654683544302, -1.407930344827586 ], [ 29.768385189873417, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3798, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.407930344827586 ], [ 29.768654683544302, -1.408469310344828 ], [ 29.76892417721519, -1.408469310344828 ], [ 29.76892417721519, -1.407930344827586 ], [ 29.768654683544302, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3799, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.407930344827586 ], [ 29.76892417721519, -1.408469310344828 ], [ 29.769193670886075, -1.408469310344828 ], [ 29.769193670886075, -1.407930344827586 ], [ 29.76892417721519, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3800, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.407930344827586 ], [ 29.769193670886075, -1.408469310344828 ], [ 29.76946316455696, -1.408469310344828 ], [ 29.76946316455696, -1.407930344827586 ], [ 29.769193670886075, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3801, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.407930344827586 ], [ 29.76946316455696, -1.408469310344828 ], [ 29.769732658227849, -1.408469310344828 ], [ 29.769732658227849, -1.407930344827586 ], [ 29.76946316455696, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3802, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.407930344827586 ], [ 29.769732658227849, -1.408469310344828 ], [ 29.770002151898733, -1.408469310344828 ], [ 29.770002151898733, -1.407930344827586 ], [ 29.769732658227849, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3803, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.407930344827586 ], [ 29.770002151898733, -1.408469310344828 ], [ 29.770271645569618, -1.408469310344828 ], [ 29.770271645569618, -1.407930344827586 ], [ 29.770002151898733, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3804, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.407930344827586 ], [ 29.770271645569618, -1.408469310344828 ], [ 29.770541139240507, -1.408469310344828 ], [ 29.770541139240507, -1.407930344827586 ], [ 29.770271645569618, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3805, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.407930344827586 ], [ 29.770541139240507, -1.408469310344828 ], [ 29.770810632911392, -1.408469310344828 ], [ 29.770810632911392, -1.407930344827586 ], [ 29.770541139240507, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3806, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.407930344827586 ], [ 29.770810632911392, -1.408469310344828 ], [ 29.771080126582277, -1.408469310344828 ], [ 29.771080126582277, -1.407930344827586 ], [ 29.770810632911392, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3807, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.407930344827586 ], [ 29.771080126582277, -1.408469310344828 ], [ 29.771349620253165, -1.408469310344828 ], [ 29.771349620253165, -1.407930344827586 ], [ 29.771080126582277, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3808, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.407930344827586 ], [ 29.771349620253165, -1.408469310344828 ], [ 29.77161911392405, -1.408469310344828 ], [ 29.77161911392405, -1.407930344827586 ], [ 29.771349620253165, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3809, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.407930344827586 ], [ 29.77161911392405, -1.408469310344828 ], [ 29.771888607594935, -1.408469310344828 ], [ 29.771888607594935, -1.407930344827586 ], [ 29.77161911392405, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3810, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.407930344827586 ], [ 29.771888607594935, -1.408469310344828 ], [ 29.772158101265823, -1.408469310344828 ], [ 29.772158101265823, -1.407930344827586 ], [ 29.771888607594935, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3811, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.407930344827586 ], [ 29.772158101265823, -1.408469310344828 ], [ 29.772427594936708, -1.408469310344828 ], [ 29.772427594936708, -1.407930344827586 ], [ 29.772158101265823, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3812, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.407930344827586 ], [ 29.772427594936708, -1.408469310344828 ], [ 29.772697088607593, -1.408469310344828 ], [ 29.772697088607593, -1.407930344827586 ], [ 29.772427594936708, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3813, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.407930344827586 ], [ 29.772697088607593, -1.408738793103448 ], [ 29.772966582278482, -1.408738793103448 ], [ 29.772966582278482, -1.407930344827586 ], [ 29.772697088607593, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3814, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.407930344827586 ], [ 29.773236075949367, -1.408738793103448 ], [ 29.773505569620252, -1.408738793103448 ], [ 29.773505569620252, -1.407930344827586 ], [ 29.773236075949367, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3815, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.407930344827586 ], [ 29.773505569620252, -1.408738793103448 ], [ 29.77377506329114, -1.408738793103448 ], [ 29.77377506329114, -1.407930344827586 ], [ 29.773505569620252, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3816, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.407930344827586 ], [ 29.774044556962025, -1.408738793103448 ], [ 29.77431405063291, -1.408738793103448 ], [ 29.77431405063291, -1.407930344827586 ], [ 29.774044556962025, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3817, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.407930344827586 ], [ 29.775122531645568, -1.408738793103448 ], [ 29.775392025316457, -1.408738793103448 ], [ 29.775392025316457, -1.407930344827586 ], [ 29.775122531645568, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3818, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.407930344827586 ], [ 29.775661518987341, -1.408469310344828 ], [ 29.775931012658226, -1.408469310344828 ], [ 29.775931012658226, -1.407930344827586 ], [ 29.775661518987341, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3819, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.407930344827586 ], [ 29.775931012658226, -1.408738793103448 ], [ 29.776200506329115, -1.408738793103448 ], [ 29.776200506329115, -1.407930344827586 ], [ 29.775931012658226, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3820, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.407930344827586 ], [ 29.77647, -1.408469310344828 ], [ 29.776739493670885, -1.408469310344828 ], [ 29.776739493670885, -1.407930344827586 ], [ 29.77647, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3821, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.407930344827586 ], [ 29.776739493670885, -1.408469310344828 ], [ 29.777008987341773, -1.408469310344828 ], [ 29.777008987341773, -1.407930344827586 ], [ 29.776739493670885, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3822, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.407930344827586 ], [ 29.777278481012658, -1.408469310344828 ], [ 29.777547974683543, -1.408469310344828 ], [ 29.777547974683543, -1.407930344827586 ], [ 29.777278481012658, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3823, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.407930344827586 ], [ 29.777547974683543, -1.408469310344828 ], [ 29.777817468354431, -1.408469310344828 ], [ 29.777817468354431, -1.407930344827586 ], [ 29.777547974683543, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3824, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.407930344827586 ], [ 29.778086962025316, -1.408469310344828 ], [ 29.778356455696201, -1.408469310344828 ], [ 29.778356455696201, -1.407930344827586 ], [ 29.778086962025316, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3825, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.407930344827586 ], [ 29.778356455696201, -1.408469310344828 ], [ 29.77862594936709, -1.408469310344828 ], [ 29.77862594936709, -1.407930344827586 ], [ 29.778356455696201, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3826, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.407930344827586 ], [ 29.77862594936709, -1.408469310344828 ], [ 29.778895443037975, -1.408469310344828 ], [ 29.778895443037975, -1.407930344827586 ], [ 29.77862594936709, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3827, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.407930344827586 ], [ 29.778895443037975, -1.408469310344828 ], [ 29.77916493670886, -1.408469310344828 ], [ 29.77916493670886, -1.407930344827586 ], [ 29.778895443037975, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3828, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.407930344827586 ], [ 29.77916493670886, -1.408469310344828 ], [ 29.779434430379748, -1.408469310344828 ], [ 29.779434430379748, -1.407930344827586 ], [ 29.77916493670886, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3829, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.407930344827586 ], [ 29.779434430379748, -1.408469310344828 ], [ 29.779703924050633, -1.408469310344828 ], [ 29.779703924050633, -1.407930344827586 ], [ 29.779434430379748, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3830, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.407930344827586 ], [ 29.779703924050633, -1.408469310344828 ], [ 29.779973417721518, -1.408469310344828 ], [ 29.779973417721518, -1.407930344827586 ], [ 29.779703924050633, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3831, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.407930344827586 ], [ 29.779973417721518, -1.408469310344828 ], [ 29.780242911392406, -1.408469310344828 ], [ 29.780242911392406, -1.407930344827586 ], [ 29.779973417721518, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3832, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.407930344827586 ], [ 29.780242911392406, -1.408469310344828 ], [ 29.780512405063291, -1.408469310344828 ], [ 29.780512405063291, -1.407930344827586 ], [ 29.780242911392406, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3833, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.407930344827586 ], [ 29.780512405063291, -1.408469310344828 ], [ 29.780781898734176, -1.408469310344828 ], [ 29.780781898734176, -1.407930344827586 ], [ 29.780512405063291, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3834, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.407930344827586 ], [ 29.780781898734176, -1.408469310344828 ], [ 29.781051392405065, -1.408469310344828 ], [ 29.781051392405065, -1.407930344827586 ], [ 29.780781898734176, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3835, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.407930344827586 ], [ 29.781051392405065, -1.408469310344828 ], [ 29.78132088607595, -1.408469310344828 ], [ 29.78132088607595, -1.407930344827586 ], [ 29.781051392405065, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3836, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.407930344827586 ], [ 29.78132088607595, -1.408469310344828 ], [ 29.781590379746834, -1.408469310344828 ], [ 29.781590379746834, -1.407930344827586 ], [ 29.78132088607595, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3837, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.407930344827586 ], [ 29.781590379746834, -1.408469310344828 ], [ 29.781859873417723, -1.408469310344828 ], [ 29.781859873417723, -1.407930344827586 ], [ 29.781590379746834, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3838, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.407930344827586 ], [ 29.781859873417723, -1.408469310344828 ], [ 29.782129367088608, -1.408469310344828 ], [ 29.782129367088608, -1.407930344827586 ], [ 29.781859873417723, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3839, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.407930344827586 ], [ 29.782129367088608, -1.409008275862069 ], [ 29.782398860759493, -1.409008275862069 ], [ 29.782398860759493, -1.407930344827586 ], [ 29.782129367088608, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3840, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.408469310344828 ], [ 29.763264810126582, -1.408738793103448 ], [ 29.763534303797467, -1.408738793103448 ], [ 29.763534303797467, -1.408469310344828 ], [ 29.763264810126582, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3841, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.408469310344828 ], [ 29.763534303797467, -1.408738793103448 ], [ 29.763803797468356, -1.408738793103448 ], [ 29.763803797468356, -1.408469310344828 ], [ 29.763534303797467, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3842, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.408469310344828 ], [ 29.763803797468356, -1.408738793103448 ], [ 29.76407329113924, -1.408738793103448 ], [ 29.76407329113924, -1.408469310344828 ], [ 29.763803797468356, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3843, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.408469310344828 ], [ 29.76407329113924, -1.408738793103448 ], [ 29.764342784810125, -1.408738793103448 ], [ 29.764342784810125, -1.408469310344828 ], [ 29.76407329113924, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3844, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.408469310344828 ], [ 29.764342784810125, -1.408738793103448 ], [ 29.764612278481014, -1.408738793103448 ], [ 29.764612278481014, -1.408469310344828 ], [ 29.764342784810125, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3845, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.408469310344828 ], [ 29.764612278481014, -1.408738793103448 ], [ 29.764881772151899, -1.408738793103448 ], [ 29.764881772151899, -1.408469310344828 ], [ 29.764612278481014, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3846, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.408469310344828 ], [ 29.764881772151899, -1.408738793103448 ], [ 29.765151265822784, -1.408738793103448 ], [ 29.765151265822784, -1.408469310344828 ], [ 29.764881772151899, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3847, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.408469310344828 ], [ 29.765151265822784, -1.408738793103448 ], [ 29.765420759493672, -1.408738793103448 ], [ 29.765420759493672, -1.408469310344828 ], [ 29.765151265822784, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3848, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.408469310344828 ], [ 29.765420759493672, -1.408738793103448 ], [ 29.765959746835442, -1.408738793103448 ], [ 29.765959746835442, -1.408469310344828 ], [ 29.765420759493672, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3849, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.408469310344828 ], [ 29.765959746835442, -1.408738793103448 ], [ 29.766229240506327, -1.408738793103448 ], [ 29.766229240506327, -1.408469310344828 ], [ 29.765959746835442, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3850, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.408469310344828 ], [ 29.766229240506327, -1.408738793103448 ], [ 29.766498734177215, -1.408738793103448 ], [ 29.766498734177215, -1.408469310344828 ], [ 29.766229240506327, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3851, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.408469310344828 ], [ 29.766498734177215, -1.408738793103448 ], [ 29.7667682278481, -1.408738793103448 ], [ 29.7667682278481, -1.408469310344828 ], [ 29.766498734177215, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3852, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.408469310344828 ], [ 29.7667682278481, -1.408738793103448 ], [ 29.767037721518985, -1.408738793103448 ], [ 29.767037721518985, -1.408469310344828 ], [ 29.7667682278481, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3853, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.408469310344828 ], [ 29.767037721518985, -1.408738793103448 ], [ 29.767307215189874, -1.408738793103448 ], [ 29.767307215189874, -1.408469310344828 ], [ 29.767037721518985, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3854, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.408469310344828 ], [ 29.767307215189874, -1.408738793103448 ], [ 29.767576708860759, -1.408738793103448 ], [ 29.767576708860759, -1.408469310344828 ], [ 29.767307215189874, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3855, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.408469310344828 ], [ 29.767576708860759, -1.408738793103448 ], [ 29.767846202531643, -1.408738793103448 ], [ 29.767846202531643, -1.408469310344828 ], [ 29.767576708860759, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3856, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.408469310344828 ], [ 29.767846202531643, -1.408738793103448 ], [ 29.768115696202532, -1.408738793103448 ], [ 29.768115696202532, -1.408469310344828 ], [ 29.767846202531643, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3857, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.408469310344828 ], [ 29.768115696202532, -1.408738793103448 ], [ 29.768385189873417, -1.408738793103448 ], [ 29.768385189873417, -1.408469310344828 ], [ 29.768115696202532, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3858, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.408469310344828 ], [ 29.768385189873417, -1.408738793103448 ], [ 29.768654683544302, -1.408738793103448 ], [ 29.768654683544302, -1.408469310344828 ], [ 29.768385189873417, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3859, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.408469310344828 ], [ 29.768654683544302, -1.408738793103448 ], [ 29.76892417721519, -1.408738793103448 ], [ 29.76892417721519, -1.408469310344828 ], [ 29.768654683544302, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3860, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.408469310344828 ], [ 29.76892417721519, -1.408738793103448 ], [ 29.769193670886075, -1.408738793103448 ], [ 29.769193670886075, -1.408469310344828 ], [ 29.76892417721519, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3861, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.408469310344828 ], [ 29.769193670886075, -1.408738793103448 ], [ 29.76946316455696, -1.408738793103448 ], [ 29.76946316455696, -1.408469310344828 ], [ 29.769193670886075, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3862, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.408469310344828 ], [ 29.76946316455696, -1.408738793103448 ], [ 29.769732658227849, -1.408738793103448 ], [ 29.769732658227849, -1.408469310344828 ], [ 29.76946316455696, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3863, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.408469310344828 ], [ 29.769732658227849, -1.408738793103448 ], [ 29.770002151898733, -1.408738793103448 ], [ 29.770002151898733, -1.408469310344828 ], [ 29.769732658227849, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3864, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.408469310344828 ], [ 29.770002151898733, -1.408738793103448 ], [ 29.770271645569618, -1.408738793103448 ], [ 29.770271645569618, -1.408469310344828 ], [ 29.770002151898733, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3865, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.408469310344828 ], [ 29.770271645569618, -1.408738793103448 ], [ 29.770541139240507, -1.408738793103448 ], [ 29.770541139240507, -1.408469310344828 ], [ 29.770271645569618, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3866, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.408469310344828 ], [ 29.770541139240507, -1.408738793103448 ], [ 29.770810632911392, -1.408738793103448 ], [ 29.770810632911392, -1.408469310344828 ], [ 29.770541139240507, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3867, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.408469310344828 ], [ 29.770810632911392, -1.408738793103448 ], [ 29.771080126582277, -1.408738793103448 ], [ 29.771080126582277, -1.408469310344828 ], [ 29.770810632911392, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3868, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.408469310344828 ], [ 29.771080126582277, -1.408738793103448 ], [ 29.771349620253165, -1.408738793103448 ], [ 29.771349620253165, -1.408469310344828 ], [ 29.771080126582277, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3869, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.408469310344828 ], [ 29.771349620253165, -1.408738793103448 ], [ 29.77161911392405, -1.408738793103448 ], [ 29.77161911392405, -1.408469310344828 ], [ 29.771349620253165, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3870, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.408469310344828 ], [ 29.77161911392405, -1.408738793103448 ], [ 29.771888607594935, -1.408738793103448 ], [ 29.771888607594935, -1.408469310344828 ], [ 29.77161911392405, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3871, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.408469310344828 ], [ 29.771888607594935, -1.408738793103448 ], [ 29.772158101265823, -1.408738793103448 ], [ 29.772158101265823, -1.408469310344828 ], [ 29.771888607594935, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3872, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.408469310344828 ], [ 29.772158101265823, -1.408738793103448 ], [ 29.772427594936708, -1.408738793103448 ], [ 29.772427594936708, -1.408469310344828 ], [ 29.772158101265823, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3873, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.408469310344828 ], [ 29.772427594936708, -1.408738793103448 ], [ 29.772697088607593, -1.408738793103448 ], [ 29.772697088607593, -1.408469310344828 ], [ 29.772427594936708, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3874, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.408469310344828 ], [ 29.772966582278482, -1.409008275862069 ], [ 29.773236075949367, -1.409008275862069 ], [ 29.773236075949367, -1.408469310344828 ], [ 29.772966582278482, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3875, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.408469310344828 ], [ 29.77377506329114, -1.409008275862069 ], [ 29.774044556962025, -1.409008275862069 ], [ 29.774044556962025, -1.408469310344828 ], [ 29.77377506329114, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3876, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.408469310344828 ], [ 29.77431405063291, -1.408738793103448 ], [ 29.774853037974683, -1.408738793103448 ], [ 29.774853037974683, -1.408469310344828 ], [ 29.77431405063291, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3877, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.408469310344828 ], [ 29.774853037974683, -1.409008275862069 ], [ 29.775122531645568, -1.409008275862069 ], [ 29.775122531645568, -1.408469310344828 ], [ 29.774853037974683, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3878, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.408469310344828 ], [ 29.775392025316457, -1.408738793103448 ], [ 29.775661518987341, -1.408738793103448 ], [ 29.775661518987341, -1.408469310344828 ], [ 29.775392025316457, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3879, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.408469310344828 ], [ 29.775661518987341, -1.408738793103448 ], [ 29.775931012658226, -1.408738793103448 ], [ 29.775931012658226, -1.408469310344828 ], [ 29.775661518987341, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3880, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.408469310344828 ], [ 29.776200506329115, -1.408738793103448 ], [ 29.77647, -1.408738793103448 ], [ 29.77647, -1.408469310344828 ], [ 29.776200506329115, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3881, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.408469310344828 ], [ 29.77647, -1.408738793103448 ], [ 29.776739493670885, -1.408738793103448 ], [ 29.776739493670885, -1.408469310344828 ], [ 29.77647, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3882, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.408469310344828 ], [ 29.776739493670885, -1.409008275862069 ], [ 29.777008987341773, -1.409008275862069 ], [ 29.777008987341773, -1.408469310344828 ], [ 29.776739493670885, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3883, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.408469310344828 ], [ 29.777008987341773, -1.408738793103448 ], [ 29.777278481012658, -1.408738793103448 ], [ 29.777278481012658, -1.408469310344828 ], [ 29.777008987341773, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3884, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.408469310344828 ], [ 29.777278481012658, -1.408738793103448 ], [ 29.777547974683543, -1.408738793103448 ], [ 29.777547974683543, -1.408469310344828 ], [ 29.777278481012658, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3885, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.408469310344828 ], [ 29.777547974683543, -1.408738793103448 ], [ 29.777817468354431, -1.408738793103448 ], [ 29.777817468354431, -1.408469310344828 ], [ 29.777547974683543, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3886, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.408469310344828 ], [ 29.777817468354431, -1.408738793103448 ], [ 29.778086962025316, -1.408738793103448 ], [ 29.778086962025316, -1.408469310344828 ], [ 29.777817468354431, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3887, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.408469310344828 ], [ 29.778086962025316, -1.408738793103448 ], [ 29.778356455696201, -1.408738793103448 ], [ 29.778356455696201, -1.408469310344828 ], [ 29.778086962025316, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3888, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.408469310344828 ], [ 29.778356455696201, -1.408738793103448 ], [ 29.77862594936709, -1.408738793103448 ], [ 29.77862594936709, -1.408469310344828 ], [ 29.778356455696201, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3889, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.408469310344828 ], [ 29.77862594936709, -1.408738793103448 ], [ 29.778895443037975, -1.408738793103448 ], [ 29.778895443037975, -1.408469310344828 ], [ 29.77862594936709, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3890, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.408469310344828 ], [ 29.778895443037975, -1.408738793103448 ], [ 29.77916493670886, -1.408738793103448 ], [ 29.77916493670886, -1.408469310344828 ], [ 29.778895443037975, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3891, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.408469310344828 ], [ 29.77916493670886, -1.408738793103448 ], [ 29.779434430379748, -1.408738793103448 ], [ 29.779434430379748, -1.408469310344828 ], [ 29.77916493670886, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3892, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.408469310344828 ], [ 29.779434430379748, -1.408738793103448 ], [ 29.779703924050633, -1.408738793103448 ], [ 29.779703924050633, -1.408469310344828 ], [ 29.779434430379748, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3893, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.408469310344828 ], [ 29.779703924050633, -1.408738793103448 ], [ 29.779973417721518, -1.408738793103448 ], [ 29.779973417721518, -1.408469310344828 ], [ 29.779703924050633, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3894, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.408469310344828 ], [ 29.779973417721518, -1.408738793103448 ], [ 29.780242911392406, -1.408738793103448 ], [ 29.780242911392406, -1.408469310344828 ], [ 29.779973417721518, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3895, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.408469310344828 ], [ 29.780242911392406, -1.408738793103448 ], [ 29.780512405063291, -1.408738793103448 ], [ 29.780512405063291, -1.408469310344828 ], [ 29.780242911392406, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3896, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.408469310344828 ], [ 29.780512405063291, -1.408738793103448 ], [ 29.780781898734176, -1.408738793103448 ], [ 29.780781898734176, -1.408469310344828 ], [ 29.780512405063291, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3897, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.408469310344828 ], [ 29.780781898734176, -1.408738793103448 ], [ 29.781051392405065, -1.408738793103448 ], [ 29.781051392405065, -1.408469310344828 ], [ 29.780781898734176, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3898, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.408469310344828 ], [ 29.781051392405065, -1.408738793103448 ], [ 29.78132088607595, -1.408738793103448 ], [ 29.78132088607595, -1.408469310344828 ], [ 29.781051392405065, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3899, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.408469310344828 ], [ 29.78132088607595, -1.408738793103448 ], [ 29.781590379746834, -1.408738793103448 ], [ 29.781590379746834, -1.408469310344828 ], [ 29.78132088607595, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3900, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.408469310344828 ], [ 29.781590379746834, -1.409008275862069 ], [ 29.781859873417723, -1.409008275862069 ], [ 29.781859873417723, -1.408469310344828 ], [ 29.781590379746834, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3901, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.408469310344828 ], [ 29.781859873417723, -1.409008275862069 ], [ 29.782129367088608, -1.409008275862069 ], [ 29.782129367088608, -1.408469310344828 ], [ 29.781859873417723, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3902, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.408738793103448 ], [ 29.763264810126582, -1.40927775862069 ], [ 29.763534303797467, -1.40927775862069 ], [ 29.763534303797467, -1.408738793103448 ], [ 29.763264810126582, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3903, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.408738793103448 ], [ 29.763534303797467, -1.40927775862069 ], [ 29.763803797468356, -1.40927775862069 ], [ 29.763803797468356, -1.408738793103448 ], [ 29.763534303797467, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3904, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.408738793103448 ], [ 29.763803797468356, -1.40927775862069 ], [ 29.76407329113924, -1.40927775862069 ], [ 29.76407329113924, -1.408738793103448 ], [ 29.763803797468356, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3905, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.408738793103448 ], [ 29.76407329113924, -1.40927775862069 ], [ 29.764342784810125, -1.40927775862069 ], [ 29.764342784810125, -1.408738793103448 ], [ 29.76407329113924, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3906, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.408738793103448 ], [ 29.764342784810125, -1.40927775862069 ], [ 29.764612278481014, -1.40927775862069 ], [ 29.764612278481014, -1.408738793103448 ], [ 29.764342784810125, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3907, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.408738793103448 ], [ 29.764612278481014, -1.409008275862069 ], [ 29.764881772151899, -1.409008275862069 ], [ 29.764881772151899, -1.408738793103448 ], [ 29.764612278481014, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3908, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.408738793103448 ], [ 29.764881772151899, -1.409008275862069 ], [ 29.765151265822784, -1.409008275862069 ], [ 29.765151265822784, -1.408738793103448 ], [ 29.764881772151899, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3909, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.408738793103448 ], [ 29.765151265822784, -1.409008275862069 ], [ 29.765420759493672, -1.409008275862069 ], [ 29.765420759493672, -1.408738793103448 ], [ 29.765151265822784, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3910, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.408738793103448 ], [ 29.765420759493672, -1.409008275862069 ], [ 29.765959746835442, -1.409008275862069 ], [ 29.765959746835442, -1.408738793103448 ], [ 29.765420759493672, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3911, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.408738793103448 ], [ 29.765959746835442, -1.409008275862069 ], [ 29.766229240506327, -1.409008275862069 ], [ 29.766229240506327, -1.408738793103448 ], [ 29.765959746835442, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3912, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.408738793103448 ], [ 29.766229240506327, -1.409008275862069 ], [ 29.766498734177215, -1.409008275862069 ], [ 29.766498734177215, -1.408738793103448 ], [ 29.766229240506327, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3913, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.408738793103448 ], [ 29.766498734177215, -1.409008275862069 ], [ 29.7667682278481, -1.409008275862069 ], [ 29.7667682278481, -1.408738793103448 ], [ 29.766498734177215, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3914, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.408738793103448 ], [ 29.7667682278481, -1.409008275862069 ], [ 29.767037721518985, -1.409008275862069 ], [ 29.767037721518985, -1.408738793103448 ], [ 29.7667682278481, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3915, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.408738793103448 ], [ 29.767037721518985, -1.409008275862069 ], [ 29.767307215189874, -1.409008275862069 ], [ 29.767307215189874, -1.408738793103448 ], [ 29.767037721518985, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3916, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.408738793103448 ], [ 29.767307215189874, -1.409008275862069 ], [ 29.767576708860759, -1.409008275862069 ], [ 29.767576708860759, -1.408738793103448 ], [ 29.767307215189874, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3917, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.408738793103448 ], [ 29.767576708860759, -1.409008275862069 ], [ 29.767846202531643, -1.409008275862069 ], [ 29.767846202531643, -1.408738793103448 ], [ 29.767576708860759, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3918, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.408738793103448 ], [ 29.767846202531643, -1.409008275862069 ], [ 29.768115696202532, -1.409008275862069 ], [ 29.768115696202532, -1.408738793103448 ], [ 29.767846202531643, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3919, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.408738793103448 ], [ 29.768115696202532, -1.409008275862069 ], [ 29.768385189873417, -1.409008275862069 ], [ 29.768385189873417, -1.408738793103448 ], [ 29.768115696202532, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3920, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.408738793103448 ], [ 29.768385189873417, -1.409008275862069 ], [ 29.768654683544302, -1.409008275862069 ], [ 29.768654683544302, -1.408738793103448 ], [ 29.768385189873417, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3921, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.408738793103448 ], [ 29.768654683544302, -1.409008275862069 ], [ 29.76892417721519, -1.409008275862069 ], [ 29.76892417721519, -1.408738793103448 ], [ 29.768654683544302, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3922, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.408738793103448 ], [ 29.76892417721519, -1.409008275862069 ], [ 29.769193670886075, -1.409008275862069 ], [ 29.769193670886075, -1.408738793103448 ], [ 29.76892417721519, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3923, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.408738793103448 ], [ 29.769193670886075, -1.409008275862069 ], [ 29.76946316455696, -1.409008275862069 ], [ 29.76946316455696, -1.408738793103448 ], [ 29.769193670886075, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3924, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.408738793103448 ], [ 29.76946316455696, -1.409008275862069 ], [ 29.769732658227849, -1.409008275862069 ], [ 29.769732658227849, -1.408738793103448 ], [ 29.76946316455696, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3925, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.408738793103448 ], [ 29.769732658227849, -1.409008275862069 ], [ 29.770002151898733, -1.409008275862069 ], [ 29.770002151898733, -1.408738793103448 ], [ 29.769732658227849, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3926, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.408738793103448 ], [ 29.770002151898733, -1.409008275862069 ], [ 29.770271645569618, -1.409008275862069 ], [ 29.770271645569618, -1.408738793103448 ], [ 29.770002151898733, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3927, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.408738793103448 ], [ 29.770271645569618, -1.409008275862069 ], [ 29.770541139240507, -1.409008275862069 ], [ 29.770541139240507, -1.408738793103448 ], [ 29.770271645569618, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3928, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.408738793103448 ], [ 29.770541139240507, -1.409008275862069 ], [ 29.770810632911392, -1.409008275862069 ], [ 29.770810632911392, -1.408738793103448 ], [ 29.770541139240507, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3929, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.408738793103448 ], [ 29.770810632911392, -1.409008275862069 ], [ 29.771080126582277, -1.409008275862069 ], [ 29.771080126582277, -1.408738793103448 ], [ 29.770810632911392, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3930, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.408738793103448 ], [ 29.771080126582277, -1.409008275862069 ], [ 29.771349620253165, -1.409008275862069 ], [ 29.771349620253165, -1.408738793103448 ], [ 29.771080126582277, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3931, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.408738793103448 ], [ 29.771349620253165, -1.409008275862069 ], [ 29.77161911392405, -1.409008275862069 ], [ 29.77161911392405, -1.408738793103448 ], [ 29.771349620253165, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3932, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.408738793103448 ], [ 29.77161911392405, -1.409008275862069 ], [ 29.771888607594935, -1.409008275862069 ], [ 29.771888607594935, -1.408738793103448 ], [ 29.77161911392405, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3933, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.408738793103448 ], [ 29.771888607594935, -1.409008275862069 ], [ 29.772158101265823, -1.409008275862069 ], [ 29.772158101265823, -1.408738793103448 ], [ 29.771888607594935, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3934, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.408738793103448 ], [ 29.772158101265823, -1.409008275862069 ], [ 29.772427594936708, -1.409008275862069 ], [ 29.772427594936708, -1.408738793103448 ], [ 29.772158101265823, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3935, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.408738793103448 ], [ 29.772427594936708, -1.409008275862069 ], [ 29.772697088607593, -1.409008275862069 ], [ 29.772697088607593, -1.408738793103448 ], [ 29.772427594936708, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3936, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.408738793103448 ], [ 29.772697088607593, -1.40927775862069 ], [ 29.772966582278482, -1.40927775862069 ], [ 29.772966582278482, -1.408738793103448 ], [ 29.772697088607593, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3937, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.408738793103448 ], [ 29.773236075949367, -1.409008275862069 ], [ 29.773505569620252, -1.409008275862069 ], [ 29.773505569620252, -1.408738793103448 ], [ 29.773236075949367, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3938, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.408738793103448 ], [ 29.773505569620252, -1.40927775862069 ], [ 29.77377506329114, -1.40927775862069 ], [ 29.77377506329114, -1.408738793103448 ], [ 29.773505569620252, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3939, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.408738793103448 ], [ 29.774044556962025, -1.409008275862069 ], [ 29.77431405063291, -1.409008275862069 ], [ 29.77431405063291, -1.408738793103448 ], [ 29.774044556962025, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3940, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.408738793103448 ], [ 29.77431405063291, -1.409008275862069 ], [ 29.774853037974683, -1.409008275862069 ], [ 29.774853037974683, -1.408738793103448 ], [ 29.77431405063291, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3941, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.408738793103448 ], [ 29.775122531645568, -1.409008275862069 ], [ 29.775392025316457, -1.409008275862069 ], [ 29.775392025316457, -1.408738793103448 ], [ 29.775122531645568, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3942, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.408738793103448 ], [ 29.775392025316457, -1.409008275862069 ], [ 29.775661518987341, -1.409008275862069 ], [ 29.775661518987341, -1.408738793103448 ], [ 29.775392025316457, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3943, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.408738793103448 ], [ 29.775661518987341, -1.40927775862069 ], [ 29.775931012658226, -1.40927775862069 ], [ 29.775931012658226, -1.408738793103448 ], [ 29.775661518987341, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3944, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.408738793103448 ], [ 29.775931012658226, -1.409008275862069 ], [ 29.776200506329115, -1.409008275862069 ], [ 29.776200506329115, -1.408738793103448 ], [ 29.775931012658226, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3945, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.408738793103448 ], [ 29.776200506329115, -1.409008275862069 ], [ 29.77647, -1.409008275862069 ], [ 29.77647, -1.408738793103448 ], [ 29.776200506329115, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3946, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.408738793103448 ], [ 29.77647, -1.409008275862069 ], [ 29.776739493670885, -1.409008275862069 ], [ 29.776739493670885, -1.408738793103448 ], [ 29.77647, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3947, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.408738793103448 ], [ 29.777008987341773, -1.409008275862069 ], [ 29.777278481012658, -1.409008275862069 ], [ 29.777278481012658, -1.408738793103448 ], [ 29.777008987341773, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3948, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.408738793103448 ], [ 29.777278481012658, -1.409008275862069 ], [ 29.777547974683543, -1.409008275862069 ], [ 29.777547974683543, -1.408738793103448 ], [ 29.777278481012658, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3949, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.408738793103448 ], [ 29.777547974683543, -1.409008275862069 ], [ 29.777817468354431, -1.409008275862069 ], [ 29.777817468354431, -1.408738793103448 ], [ 29.777547974683543, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3950, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.408738793103448 ], [ 29.777817468354431, -1.409008275862069 ], [ 29.778086962025316, -1.409008275862069 ], [ 29.778086962025316, -1.408738793103448 ], [ 29.777817468354431, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3951, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.408738793103448 ], [ 29.778086962025316, -1.409008275862069 ], [ 29.778356455696201, -1.409008275862069 ], [ 29.778356455696201, -1.408738793103448 ], [ 29.778086962025316, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3952, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.408738793103448 ], [ 29.778356455696201, -1.409008275862069 ], [ 29.77862594936709, -1.409008275862069 ], [ 29.77862594936709, -1.408738793103448 ], [ 29.778356455696201, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3953, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.408738793103448 ], [ 29.77862594936709, -1.409008275862069 ], [ 29.778895443037975, -1.409008275862069 ], [ 29.778895443037975, -1.408738793103448 ], [ 29.77862594936709, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3954, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.408738793103448 ], [ 29.778895443037975, -1.409008275862069 ], [ 29.77916493670886, -1.409008275862069 ], [ 29.77916493670886, -1.408738793103448 ], [ 29.778895443037975, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3955, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.408738793103448 ], [ 29.77916493670886, -1.409008275862069 ], [ 29.779434430379748, -1.409008275862069 ], [ 29.779434430379748, -1.408738793103448 ], [ 29.77916493670886, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3956, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.408738793103448 ], [ 29.779434430379748, -1.409008275862069 ], [ 29.779703924050633, -1.409008275862069 ], [ 29.779703924050633, -1.408738793103448 ], [ 29.779434430379748, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3957, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.408738793103448 ], [ 29.779703924050633, -1.409008275862069 ], [ 29.779973417721518, -1.409008275862069 ], [ 29.779973417721518, -1.408738793103448 ], [ 29.779703924050633, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3958, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.408738793103448 ], [ 29.779973417721518, -1.409008275862069 ], [ 29.780242911392406, -1.409008275862069 ], [ 29.780242911392406, -1.408738793103448 ], [ 29.779973417721518, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3959, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.408738793103448 ], [ 29.780242911392406, -1.409008275862069 ], [ 29.780512405063291, -1.409008275862069 ], [ 29.780512405063291, -1.408738793103448 ], [ 29.780242911392406, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3960, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.408738793103448 ], [ 29.780512405063291, -1.409008275862069 ], [ 29.780781898734176, -1.409008275862069 ], [ 29.780781898734176, -1.408738793103448 ], [ 29.780512405063291, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.408738793103448 ], [ 29.780781898734176, -1.409008275862069 ], [ 29.781051392405065, -1.409008275862069 ], [ 29.781051392405065, -1.408738793103448 ], [ 29.780781898734176, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3962, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.408738793103448 ], [ 29.781051392405065, -1.40927775862069 ], [ 29.78132088607595, -1.40927775862069 ], [ 29.78132088607595, -1.408738793103448 ], [ 29.781051392405065, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3963, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.408738793103448 ], [ 29.78132088607595, -1.40927775862069 ], [ 29.781590379746834, -1.40927775862069 ], [ 29.781590379746834, -1.408738793103448 ], [ 29.78132088607595, -1.408738793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3964, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.409008275862069 ], [ 29.764612278481014, -1.40927775862069 ], [ 29.764881772151899, -1.40927775862069 ], [ 29.764881772151899, -1.409008275862069 ], [ 29.764612278481014, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3965, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.409008275862069 ], [ 29.764881772151899, -1.40927775862069 ], [ 29.765151265822784, -1.40927775862069 ], [ 29.765151265822784, -1.409008275862069 ], [ 29.764881772151899, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3966, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.409008275862069 ], [ 29.765151265822784, -1.40927775862069 ], [ 29.765420759493672, -1.40927775862069 ], [ 29.765420759493672, -1.409008275862069 ], [ 29.765151265822784, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3967, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.409008275862069 ], [ 29.765420759493672, -1.40927775862069 ], [ 29.765959746835442, -1.40927775862069 ], [ 29.765959746835442, -1.409008275862069 ], [ 29.765420759493672, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3968, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.409008275862069 ], [ 29.765959746835442, -1.40927775862069 ], [ 29.766229240506327, -1.40927775862069 ], [ 29.766229240506327, -1.409008275862069 ], [ 29.765959746835442, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3969, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.409008275862069 ], [ 29.766229240506327, -1.40927775862069 ], [ 29.766498734177215, -1.40927775862069 ], [ 29.766498734177215, -1.409008275862069 ], [ 29.766229240506327, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3970, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.409008275862069 ], [ 29.766498734177215, -1.40927775862069 ], [ 29.7667682278481, -1.40927775862069 ], [ 29.7667682278481, -1.409008275862069 ], [ 29.766498734177215, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3971, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.409008275862069 ], [ 29.7667682278481, -1.40927775862069 ], [ 29.767037721518985, -1.40927775862069 ], [ 29.767037721518985, -1.409008275862069 ], [ 29.7667682278481, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3972, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.409008275862069 ], [ 29.767037721518985, -1.40927775862069 ], [ 29.767307215189874, -1.40927775862069 ], [ 29.767307215189874, -1.409008275862069 ], [ 29.767037721518985, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3973, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.409008275862069 ], [ 29.767307215189874, -1.40927775862069 ], [ 29.767576708860759, -1.40927775862069 ], [ 29.767576708860759, -1.409008275862069 ], [ 29.767307215189874, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3974, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.409008275862069 ], [ 29.767576708860759, -1.40927775862069 ], [ 29.767846202531643, -1.40927775862069 ], [ 29.767846202531643, -1.409008275862069 ], [ 29.767576708860759, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3975, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.409008275862069 ], [ 29.767846202531643, -1.40927775862069 ], [ 29.768115696202532, -1.40927775862069 ], [ 29.768115696202532, -1.409008275862069 ], [ 29.767846202531643, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3976, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.409008275862069 ], [ 29.768115696202532, -1.40927775862069 ], [ 29.768385189873417, -1.40927775862069 ], [ 29.768385189873417, -1.409008275862069 ], [ 29.768115696202532, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3977, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.409008275862069 ], [ 29.768385189873417, -1.40927775862069 ], [ 29.768654683544302, -1.40927775862069 ], [ 29.768654683544302, -1.409008275862069 ], [ 29.768385189873417, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3978, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.409008275862069 ], [ 29.768654683544302, -1.40927775862069 ], [ 29.76892417721519, -1.40927775862069 ], [ 29.76892417721519, -1.409008275862069 ], [ 29.768654683544302, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3979, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.409008275862069 ], [ 29.76892417721519, -1.40927775862069 ], [ 29.769193670886075, -1.40927775862069 ], [ 29.769193670886075, -1.409008275862069 ], [ 29.76892417721519, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3980, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.409008275862069 ], [ 29.769193670886075, -1.40927775862069 ], [ 29.76946316455696, -1.40927775862069 ], [ 29.76946316455696, -1.409008275862069 ], [ 29.769193670886075, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3981, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.409008275862069 ], [ 29.76946316455696, -1.40927775862069 ], [ 29.769732658227849, -1.40927775862069 ], [ 29.769732658227849, -1.409008275862069 ], [ 29.76946316455696, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3982, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.409008275862069 ], [ 29.769732658227849, -1.40927775862069 ], [ 29.770002151898733, -1.40927775862069 ], [ 29.770002151898733, -1.409008275862069 ], [ 29.769732658227849, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3983, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.409008275862069 ], [ 29.770002151898733, -1.40927775862069 ], [ 29.770271645569618, -1.40927775862069 ], [ 29.770271645569618, -1.409008275862069 ], [ 29.770002151898733, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3984, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.409008275862069 ], [ 29.770271645569618, -1.40927775862069 ], [ 29.770541139240507, -1.40927775862069 ], [ 29.770541139240507, -1.409008275862069 ], [ 29.770271645569618, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3985, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.409008275862069 ], [ 29.770541139240507, -1.40927775862069 ], [ 29.770810632911392, -1.40927775862069 ], [ 29.770810632911392, -1.409008275862069 ], [ 29.770541139240507, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3986, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.409008275862069 ], [ 29.770810632911392, -1.40927775862069 ], [ 29.771080126582277, -1.40927775862069 ], [ 29.771080126582277, -1.409008275862069 ], [ 29.770810632911392, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3987, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.409008275862069 ], [ 29.771080126582277, -1.40927775862069 ], [ 29.771349620253165, -1.40927775862069 ], [ 29.771349620253165, -1.409008275862069 ], [ 29.771080126582277, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3988, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.409008275862069 ], [ 29.771349620253165, -1.40927775862069 ], [ 29.77161911392405, -1.40927775862069 ], [ 29.77161911392405, -1.409008275862069 ], [ 29.771349620253165, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3989, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.409008275862069 ], [ 29.77161911392405, -1.40927775862069 ], [ 29.771888607594935, -1.40927775862069 ], [ 29.771888607594935, -1.409008275862069 ], [ 29.77161911392405, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3990, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.409008275862069 ], [ 29.771888607594935, -1.40927775862069 ], [ 29.772158101265823, -1.40927775862069 ], [ 29.772158101265823, -1.409008275862069 ], [ 29.771888607594935, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3991, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.409008275862069 ], [ 29.772158101265823, -1.40927775862069 ], [ 29.772427594936708, -1.40927775862069 ], [ 29.772427594936708, -1.409008275862069 ], [ 29.772158101265823, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3992, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.409008275862069 ], [ 29.772427594936708, -1.40927775862069 ], [ 29.772697088607593, -1.40927775862069 ], [ 29.772697088607593, -1.409008275862069 ], [ 29.772427594936708, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3993, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.409008275862069 ], [ 29.772966582278482, -1.40927775862069 ], [ 29.773236075949367, -1.40927775862069 ], [ 29.773236075949367, -1.409008275862069 ], [ 29.772966582278482, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3994, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.409008275862069 ], [ 29.773236075949367, -1.40927775862069 ], [ 29.773505569620252, -1.40927775862069 ], [ 29.773505569620252, -1.409008275862069 ], [ 29.773236075949367, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3995, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.409008275862069 ], [ 29.77377506329114, -1.40927775862069 ], [ 29.774044556962025, -1.40927775862069 ], [ 29.774044556962025, -1.409008275862069 ], [ 29.77377506329114, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3996, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.409008275862069 ], [ 29.774044556962025, -1.40927775862069 ], [ 29.77431405063291, -1.40927775862069 ], [ 29.77431405063291, -1.409008275862069 ], [ 29.774044556962025, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3997, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.409008275862069 ], [ 29.774853037974683, -1.40927775862069 ], [ 29.775122531645568, -1.40927775862069 ], [ 29.775122531645568, -1.409008275862069 ], [ 29.774853037974683, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3998, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.409008275862069 ], [ 29.775122531645568, -1.40927775862069 ], [ 29.775392025316457, -1.40927775862069 ], [ 29.775392025316457, -1.409008275862069 ], [ 29.775122531645568, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 3999, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.409008275862069 ], [ 29.775392025316457, -1.40927775862069 ], [ 29.775661518987341, -1.40927775862069 ], [ 29.775661518987341, -1.409008275862069 ], [ 29.775392025316457, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4000, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.409008275862069 ], [ 29.775931012658226, -1.40927775862069 ], [ 29.776200506329115, -1.40927775862069 ], [ 29.776200506329115, -1.409008275862069 ], [ 29.775931012658226, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4001, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.409008275862069 ], [ 29.776200506329115, -1.40927775862069 ], [ 29.77647, -1.40927775862069 ], [ 29.77647, -1.409008275862069 ], [ 29.776200506329115, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4002, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.409008275862069 ], [ 29.77647, -1.40927775862069 ], [ 29.776739493670885, -1.40927775862069 ], [ 29.776739493670885, -1.409008275862069 ], [ 29.77647, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4003, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.409008275862069 ], [ 29.776739493670885, -1.40927775862069 ], [ 29.777008987341773, -1.40927775862069 ], [ 29.777008987341773, -1.409008275862069 ], [ 29.776739493670885, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4004, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.409008275862069 ], [ 29.777008987341773, -1.40927775862069 ], [ 29.777278481012658, -1.40927775862069 ], [ 29.777278481012658, -1.409008275862069 ], [ 29.777008987341773, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4005, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.409008275862069 ], [ 29.777278481012658, -1.40927775862069 ], [ 29.777547974683543, -1.40927775862069 ], [ 29.777547974683543, -1.409008275862069 ], [ 29.777278481012658, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4006, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.409008275862069 ], [ 29.777547974683543, -1.40927775862069 ], [ 29.777817468354431, -1.40927775862069 ], [ 29.777817468354431, -1.409008275862069 ], [ 29.777547974683543, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4007, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.409008275862069 ], [ 29.777817468354431, -1.40927775862069 ], [ 29.778086962025316, -1.40927775862069 ], [ 29.778086962025316, -1.409008275862069 ], [ 29.777817468354431, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4008, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.409008275862069 ], [ 29.778086962025316, -1.40927775862069 ], [ 29.778356455696201, -1.40927775862069 ], [ 29.778356455696201, -1.409008275862069 ], [ 29.778086962025316, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4009, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.409008275862069 ], [ 29.778356455696201, -1.40927775862069 ], [ 29.77862594936709, -1.40927775862069 ], [ 29.77862594936709, -1.409008275862069 ], [ 29.778356455696201, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4010, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.409008275862069 ], [ 29.77862594936709, -1.40927775862069 ], [ 29.778895443037975, -1.40927775862069 ], [ 29.778895443037975, -1.409008275862069 ], [ 29.77862594936709, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4011, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.409008275862069 ], [ 29.778895443037975, -1.40927775862069 ], [ 29.77916493670886, -1.40927775862069 ], [ 29.77916493670886, -1.409008275862069 ], [ 29.778895443037975, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4012, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.409008275862069 ], [ 29.77916493670886, -1.40927775862069 ], [ 29.779434430379748, -1.40927775862069 ], [ 29.779434430379748, -1.409008275862069 ], [ 29.77916493670886, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4013, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.409008275862069 ], [ 29.779434430379748, -1.40927775862069 ], [ 29.779703924050633, -1.40927775862069 ], [ 29.779703924050633, -1.409008275862069 ], [ 29.779434430379748, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4014, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.409008275862069 ], [ 29.779703924050633, -1.40927775862069 ], [ 29.779973417721518, -1.40927775862069 ], [ 29.779973417721518, -1.409008275862069 ], [ 29.779703924050633, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4015, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.409008275862069 ], [ 29.779973417721518, -1.40927775862069 ], [ 29.780242911392406, -1.40927775862069 ], [ 29.780242911392406, -1.409008275862069 ], [ 29.779973417721518, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4016, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.409008275862069 ], [ 29.780242911392406, -1.40927775862069 ], [ 29.780512405063291, -1.40927775862069 ], [ 29.780512405063291, -1.409008275862069 ], [ 29.780242911392406, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4017, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.409008275862069 ], [ 29.780512405063291, -1.40927775862069 ], [ 29.780781898734176, -1.40927775862069 ], [ 29.780781898734176, -1.409008275862069 ], [ 29.780512405063291, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4018, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.409008275862069 ], [ 29.781590379746834, -1.40927775862069 ], [ 29.781859873417723, -1.40927775862069 ], [ 29.781859873417723, -1.409008275862069 ], [ 29.781590379746834, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4019, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.409008275862069 ], [ 29.781859873417723, -1.40927775862069 ], [ 29.782129367088608, -1.40927775862069 ], [ 29.782129367088608, -1.409008275862069 ], [ 29.781859873417723, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4020, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.409008275862069 ], [ 29.782129367088608, -1.40927775862069 ], [ 29.782398860759493, -1.40927775862069 ], [ 29.782398860759493, -1.409008275862069 ], [ 29.782129367088608, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4021, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.407660862068966 ], [ 29.761647848101266, -1.410086206896552 ], [ 29.761917341772151, -1.410086206896552 ], [ 29.761917341772151, -1.407660862068966 ], [ 29.761647848101266, -1.407660862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4022, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.407930344827586 ], [ 29.761917341772151, -1.409816724137931 ], [ 29.762186835443039, -1.409816724137931 ], [ 29.762186835443039, -1.407930344827586 ], [ 29.761917341772151, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4023, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.407930344827586 ], [ 29.762186835443039, -1.409816724137931 ], [ 29.762456329113924, -1.409816724137931 ], [ 29.762456329113924, -1.407930344827586 ], [ 29.762186835443039, -1.407930344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4024, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.408469310344828 ], [ 29.762456329113924, -1.40954724137931 ], [ 29.762725822784809, -1.40954724137931 ], [ 29.762725822784809, -1.408469310344828 ], [ 29.762456329113924, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4025, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.408469310344828 ], [ 29.762725822784809, -1.40954724137931 ], [ 29.762995316455697, -1.40954724137931 ], [ 29.762995316455697, -1.408469310344828 ], [ 29.762725822784809, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4026, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.408469310344828 ], [ 29.762995316455697, -1.40954724137931 ], [ 29.763264810126582, -1.40954724137931 ], [ 29.763264810126582, -1.408469310344828 ], [ 29.762995316455697, -1.408469310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4027, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.409008275862069 ], [ 29.77431405063291, -1.40954724137931 ], [ 29.774853037974683, -1.40954724137931 ], [ 29.774853037974683, -1.409008275862069 ], [ 29.77431405063291, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4028, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.409008275862069 ], [ 29.780781898734176, -1.40954724137931 ], [ 29.781051392405065, -1.40954724137931 ], [ 29.781051392405065, -1.409008275862069 ], [ 29.780781898734176, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4029, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.409008275862069 ], [ 29.782398860759493, -1.410894655172414 ], [ 29.782668354430381, -1.410894655172414 ], [ 29.782668354430381, -1.409008275862069 ], [ 29.782398860759493, -1.409008275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4030, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.40927775862069 ], [ 29.763264810126582, -1.40954724137931 ], [ 29.763534303797467, -1.40954724137931 ], [ 29.763534303797467, -1.40927775862069 ], [ 29.763264810126582, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4031, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.40927775862069 ], [ 29.763534303797467, -1.40954724137931 ], [ 29.763803797468356, -1.40954724137931 ], [ 29.763803797468356, -1.40927775862069 ], [ 29.763534303797467, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4032, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.40927775862069 ], [ 29.763803797468356, -1.40954724137931 ], [ 29.76407329113924, -1.40954724137931 ], [ 29.76407329113924, -1.40927775862069 ], [ 29.763803797468356, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4033, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.40927775862069 ], [ 29.76407329113924, -1.40954724137931 ], [ 29.764342784810125, -1.40954724137931 ], [ 29.764342784810125, -1.40927775862069 ], [ 29.76407329113924, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4034, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.40927775862069 ], [ 29.764342784810125, -1.409816724137931 ], [ 29.764612278481014, -1.409816724137931 ], [ 29.764612278481014, -1.40927775862069 ], [ 29.764342784810125, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4035, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.40927775862069 ], [ 29.764612278481014, -1.40954724137931 ], [ 29.764881772151899, -1.40954724137931 ], [ 29.764881772151899, -1.40927775862069 ], [ 29.764612278481014, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4036, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.40927775862069 ], [ 29.764881772151899, -1.40954724137931 ], [ 29.765151265822784, -1.40954724137931 ], [ 29.765151265822784, -1.40927775862069 ], [ 29.764881772151899, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4037, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.40927775862069 ], [ 29.765151265822784, -1.409816724137931 ], [ 29.765420759493672, -1.409816724137931 ], [ 29.765420759493672, -1.40927775862069 ], [ 29.765151265822784, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4038, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.40927775862069 ], [ 29.765420759493672, -1.40954724137931 ], [ 29.765959746835442, -1.40954724137931 ], [ 29.765959746835442, -1.40927775862069 ], [ 29.765420759493672, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4039, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.40927775862069 ], [ 29.766229240506327, -1.40954724137931 ], [ 29.766498734177215, -1.40954724137931 ], [ 29.766498734177215, -1.40927775862069 ], [ 29.766229240506327, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4040, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.40927775862069 ], [ 29.766498734177215, -1.40954724137931 ], [ 29.7667682278481, -1.40954724137931 ], [ 29.7667682278481, -1.40927775862069 ], [ 29.766498734177215, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4041, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.40927775862069 ], [ 29.7667682278481, -1.40954724137931 ], [ 29.767037721518985, -1.40954724137931 ], [ 29.767037721518985, -1.40927775862069 ], [ 29.7667682278481, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4042, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.40927775862069 ], [ 29.767037721518985, -1.40954724137931 ], [ 29.767307215189874, -1.40954724137931 ], [ 29.767307215189874, -1.40927775862069 ], [ 29.767037721518985, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4043, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.40927775862069 ], [ 29.767307215189874, -1.40954724137931 ], [ 29.767576708860759, -1.40954724137931 ], [ 29.767576708860759, -1.40927775862069 ], [ 29.767307215189874, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4044, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.40927775862069 ], [ 29.767576708860759, -1.40954724137931 ], [ 29.767846202531643, -1.40954724137931 ], [ 29.767846202531643, -1.40927775862069 ], [ 29.767576708860759, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4045, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.40927775862069 ], [ 29.767846202531643, -1.40954724137931 ], [ 29.768115696202532, -1.40954724137931 ], [ 29.768115696202532, -1.40927775862069 ], [ 29.767846202531643, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4046, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.40927775862069 ], [ 29.768115696202532, -1.40954724137931 ], [ 29.768385189873417, -1.40954724137931 ], [ 29.768385189873417, -1.40927775862069 ], [ 29.768115696202532, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4047, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.40927775862069 ], [ 29.768385189873417, -1.40954724137931 ], [ 29.768654683544302, -1.40954724137931 ], [ 29.768654683544302, -1.40927775862069 ], [ 29.768385189873417, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4048, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.40927775862069 ], [ 29.768654683544302, -1.40954724137931 ], [ 29.76892417721519, -1.40954724137931 ], [ 29.76892417721519, -1.40927775862069 ], [ 29.768654683544302, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4049, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.40927775862069 ], [ 29.76892417721519, -1.40954724137931 ], [ 29.769193670886075, -1.40954724137931 ], [ 29.769193670886075, -1.40927775862069 ], [ 29.76892417721519, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4050, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.40927775862069 ], [ 29.769193670886075, -1.40954724137931 ], [ 29.76946316455696, -1.40954724137931 ], [ 29.76946316455696, -1.40927775862069 ], [ 29.769193670886075, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4051, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.40927775862069 ], [ 29.76946316455696, -1.40954724137931 ], [ 29.769732658227849, -1.40954724137931 ], [ 29.769732658227849, -1.40927775862069 ], [ 29.76946316455696, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4052, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.40927775862069 ], [ 29.769732658227849, -1.40954724137931 ], [ 29.770002151898733, -1.40954724137931 ], [ 29.770002151898733, -1.40927775862069 ], [ 29.769732658227849, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4053, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.40927775862069 ], [ 29.770002151898733, -1.40954724137931 ], [ 29.770271645569618, -1.40954724137931 ], [ 29.770271645569618, -1.40927775862069 ], [ 29.770002151898733, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4054, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.40927775862069 ], [ 29.770271645569618, -1.40954724137931 ], [ 29.770541139240507, -1.40954724137931 ], [ 29.770541139240507, -1.40927775862069 ], [ 29.770271645569618, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4055, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.40927775862069 ], [ 29.770541139240507, -1.40954724137931 ], [ 29.770810632911392, -1.40954724137931 ], [ 29.770810632911392, -1.40927775862069 ], [ 29.770541139240507, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4056, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.40927775862069 ], [ 29.770810632911392, -1.40954724137931 ], [ 29.771080126582277, -1.40954724137931 ], [ 29.771080126582277, -1.40927775862069 ], [ 29.770810632911392, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4057, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.40927775862069 ], [ 29.771080126582277, -1.40954724137931 ], [ 29.771349620253165, -1.40954724137931 ], [ 29.771349620253165, -1.40927775862069 ], [ 29.771080126582277, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4058, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.40927775862069 ], [ 29.771349620253165, -1.40954724137931 ], [ 29.77161911392405, -1.40954724137931 ], [ 29.77161911392405, -1.40927775862069 ], [ 29.771349620253165, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4059, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.40927775862069 ], [ 29.77161911392405, -1.40954724137931 ], [ 29.771888607594935, -1.40954724137931 ], [ 29.771888607594935, -1.40927775862069 ], [ 29.77161911392405, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4060, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.40927775862069 ], [ 29.771888607594935, -1.40954724137931 ], [ 29.772158101265823, -1.40954724137931 ], [ 29.772158101265823, -1.40927775862069 ], [ 29.771888607594935, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4061, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.40927775862069 ], [ 29.772158101265823, -1.40954724137931 ], [ 29.772427594936708, -1.40954724137931 ], [ 29.772427594936708, -1.40927775862069 ], [ 29.772158101265823, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4062, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.40927775862069 ], [ 29.772427594936708, -1.40954724137931 ], [ 29.772697088607593, -1.40954724137931 ], [ 29.772697088607593, -1.40927775862069 ], [ 29.772427594936708, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4063, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.40927775862069 ], [ 29.772697088607593, -1.40954724137931 ], [ 29.772966582278482, -1.40954724137931 ], [ 29.772966582278482, -1.40927775862069 ], [ 29.772697088607593, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4064, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.40927775862069 ], [ 29.772966582278482, -1.40954724137931 ], [ 29.773236075949367, -1.40954724137931 ], [ 29.773236075949367, -1.40927775862069 ], [ 29.772966582278482, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4065, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.40927775862069 ], [ 29.773236075949367, -1.409816724137931 ], [ 29.773505569620252, -1.409816724137931 ], [ 29.773505569620252, -1.40927775862069 ], [ 29.773236075949367, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4066, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.40927775862069 ], [ 29.773505569620252, -1.40954724137931 ], [ 29.77377506329114, -1.40954724137931 ], [ 29.77377506329114, -1.40927775862069 ], [ 29.773505569620252, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4067, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.40927775862069 ], [ 29.77377506329114, -1.40954724137931 ], [ 29.774044556962025, -1.40954724137931 ], [ 29.774044556962025, -1.40927775862069 ], [ 29.77377506329114, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4068, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.40927775862069 ], [ 29.774044556962025, -1.40954724137931 ], [ 29.77431405063291, -1.40954724137931 ], [ 29.77431405063291, -1.40927775862069 ], [ 29.774044556962025, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4069, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.40927775862069 ], [ 29.774853037974683, -1.40954724137931 ], [ 29.775122531645568, -1.40954724137931 ], [ 29.775122531645568, -1.40927775862069 ], [ 29.774853037974683, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4070, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.40927775862069 ], [ 29.775122531645568, -1.40954724137931 ], [ 29.775392025316457, -1.40954724137931 ], [ 29.775392025316457, -1.40927775862069 ], [ 29.775122531645568, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4071, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.40927775862069 ], [ 29.775392025316457, -1.40954724137931 ], [ 29.775661518987341, -1.40954724137931 ], [ 29.775661518987341, -1.40927775862069 ], [ 29.775392025316457, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4072, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.40927775862069 ], [ 29.775661518987341, -1.40954724137931 ], [ 29.775931012658226, -1.40954724137931 ], [ 29.775931012658226, -1.40927775862069 ], [ 29.775661518987341, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4073, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.40927775862069 ], [ 29.775931012658226, -1.40954724137931 ], [ 29.776200506329115, -1.40954724137931 ], [ 29.776200506329115, -1.40927775862069 ], [ 29.775931012658226, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4074, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.40927775862069 ], [ 29.776200506329115, -1.40954724137931 ], [ 29.77647, -1.40954724137931 ], [ 29.77647, -1.40927775862069 ], [ 29.776200506329115, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4075, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.40927775862069 ], [ 29.77647, -1.40954724137931 ], [ 29.776739493670885, -1.40954724137931 ], [ 29.776739493670885, -1.40927775862069 ], [ 29.77647, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4076, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.40927775862069 ], [ 29.776739493670885, -1.40954724137931 ], [ 29.777008987341773, -1.40954724137931 ], [ 29.777008987341773, -1.40927775862069 ], [ 29.776739493670885, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4077, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.40927775862069 ], [ 29.777008987341773, -1.40954724137931 ], [ 29.777278481012658, -1.40954724137931 ], [ 29.777278481012658, -1.40927775862069 ], [ 29.777008987341773, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4078, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.40927775862069 ], [ 29.777278481012658, -1.40954724137931 ], [ 29.777547974683543, -1.40954724137931 ], [ 29.777547974683543, -1.40927775862069 ], [ 29.777278481012658, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4079, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.40927775862069 ], [ 29.777547974683543, -1.40954724137931 ], [ 29.777817468354431, -1.40954724137931 ], [ 29.777817468354431, -1.40927775862069 ], [ 29.777547974683543, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4080, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.40927775862069 ], [ 29.777817468354431, -1.40954724137931 ], [ 29.778086962025316, -1.40954724137931 ], [ 29.778086962025316, -1.40927775862069 ], [ 29.777817468354431, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4081, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.40927775862069 ], [ 29.778086962025316, -1.40954724137931 ], [ 29.778356455696201, -1.40954724137931 ], [ 29.778356455696201, -1.40927775862069 ], [ 29.778086962025316, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4082, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.40927775862069 ], [ 29.778356455696201, -1.40954724137931 ], [ 29.77862594936709, -1.40954724137931 ], [ 29.77862594936709, -1.40927775862069 ], [ 29.778356455696201, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4083, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.40927775862069 ], [ 29.77862594936709, -1.40954724137931 ], [ 29.778895443037975, -1.40954724137931 ], [ 29.778895443037975, -1.40927775862069 ], [ 29.77862594936709, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4084, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.40927775862069 ], [ 29.778895443037975, -1.40954724137931 ], [ 29.77916493670886, -1.40954724137931 ], [ 29.77916493670886, -1.40927775862069 ], [ 29.778895443037975, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4085, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.40927775862069 ], [ 29.77916493670886, -1.40954724137931 ], [ 29.779434430379748, -1.40954724137931 ], [ 29.779434430379748, -1.40927775862069 ], [ 29.77916493670886, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4086, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.40927775862069 ], [ 29.779434430379748, -1.40954724137931 ], [ 29.779703924050633, -1.40954724137931 ], [ 29.779703924050633, -1.40927775862069 ], [ 29.779434430379748, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4087, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.40927775862069 ], [ 29.779703924050633, -1.409816724137931 ], [ 29.779973417721518, -1.409816724137931 ], [ 29.779973417721518, -1.40927775862069 ], [ 29.779703924050633, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4088, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.40927775862069 ], [ 29.779973417721518, -1.40954724137931 ], [ 29.780242911392406, -1.40954724137931 ], [ 29.780242911392406, -1.40927775862069 ], [ 29.779973417721518, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4089, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.40927775862069 ], [ 29.780242911392406, -1.40954724137931 ], [ 29.780512405063291, -1.40954724137931 ], [ 29.780512405063291, -1.40927775862069 ], [ 29.780242911392406, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4090, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.40927775862069 ], [ 29.780512405063291, -1.40954724137931 ], [ 29.780781898734176, -1.40954724137931 ], [ 29.780781898734176, -1.40927775862069 ], [ 29.780512405063291, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4091, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.40927775862069 ], [ 29.781051392405065, -1.40954724137931 ], [ 29.78132088607595, -1.40954724137931 ], [ 29.78132088607595, -1.40927775862069 ], [ 29.781051392405065, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4092, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.40927775862069 ], [ 29.78132088607595, -1.40954724137931 ], [ 29.781590379746834, -1.40954724137931 ], [ 29.781590379746834, -1.40927775862069 ], [ 29.78132088607595, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4093, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.40927775862069 ], [ 29.781590379746834, -1.40954724137931 ], [ 29.781859873417723, -1.40954724137931 ], [ 29.781859873417723, -1.40927775862069 ], [ 29.781590379746834, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4094, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.40927775862069 ], [ 29.781859873417723, -1.410894655172414 ], [ 29.782129367088608, -1.410894655172414 ], [ 29.782129367088608, -1.40927775862069 ], [ 29.781859873417723, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4095, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.40927775862069 ], [ 29.782129367088608, -1.410894655172414 ], [ 29.782398860759493, -1.410894655172414 ], [ 29.782398860759493, -1.40927775862069 ], [ 29.782129367088608, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4096, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.40954724137931 ], [ 29.762456329113924, -1.409816724137931 ], [ 29.762725822784809, -1.409816724137931 ], [ 29.762725822784809, -1.40954724137931 ], [ 29.762456329113924, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4097, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.40954724137931 ], [ 29.762725822784809, -1.409816724137931 ], [ 29.762995316455697, -1.409816724137931 ], [ 29.762995316455697, -1.40954724137931 ], [ 29.762725822784809, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4098, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.40954724137931 ], [ 29.762995316455697, -1.409816724137931 ], [ 29.763264810126582, -1.409816724137931 ], [ 29.763264810126582, -1.40954724137931 ], [ 29.762995316455697, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4099, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.40954724137931 ], [ 29.763264810126582, -1.410086206896552 ], [ 29.763534303797467, -1.410086206896552 ], [ 29.763534303797467, -1.40954724137931 ], [ 29.763264810126582, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4100, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.40954724137931 ], [ 29.763534303797467, -1.409816724137931 ], [ 29.763803797468356, -1.409816724137931 ], [ 29.763803797468356, -1.40954724137931 ], [ 29.763534303797467, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4101, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.40954724137931 ], [ 29.763803797468356, -1.409816724137931 ], [ 29.76407329113924, -1.409816724137931 ], [ 29.76407329113924, -1.40954724137931 ], [ 29.763803797468356, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4102, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.40954724137931 ], [ 29.764612278481014, -1.410086206896552 ], [ 29.764881772151899, -1.410086206896552 ], [ 29.764881772151899, -1.40954724137931 ], [ 29.764612278481014, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4103, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.40954724137931 ], [ 29.766229240506327, -1.409816724137931 ], [ 29.766498734177215, -1.409816724137931 ], [ 29.766498734177215, -1.40954724137931 ], [ 29.766229240506327, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4104, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.40954724137931 ], [ 29.7667682278481, -1.409816724137931 ], [ 29.767037721518985, -1.409816724137931 ], [ 29.767037721518985, -1.40954724137931 ], [ 29.7667682278481, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4105, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.40954724137931 ], [ 29.767037721518985, -1.409816724137931 ], [ 29.767307215189874, -1.409816724137931 ], [ 29.767307215189874, -1.40954724137931 ], [ 29.767037721518985, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4106, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.40954724137931 ], [ 29.767307215189874, -1.409816724137931 ], [ 29.767576708860759, -1.409816724137931 ], [ 29.767576708860759, -1.40954724137931 ], [ 29.767307215189874, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4107, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.40954724137931 ], [ 29.767576708860759, -1.409816724137931 ], [ 29.767846202531643, -1.409816724137931 ], [ 29.767846202531643, -1.40954724137931 ], [ 29.767576708860759, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4108, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.40954724137931 ], [ 29.767846202531643, -1.409816724137931 ], [ 29.768115696202532, -1.409816724137931 ], [ 29.768115696202532, -1.40954724137931 ], [ 29.767846202531643, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4109, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.40954724137931 ], [ 29.768115696202532, -1.409816724137931 ], [ 29.768385189873417, -1.409816724137931 ], [ 29.768385189873417, -1.40954724137931 ], [ 29.768115696202532, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4110, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.40954724137931 ], [ 29.768385189873417, -1.409816724137931 ], [ 29.768654683544302, -1.409816724137931 ], [ 29.768654683544302, -1.40954724137931 ], [ 29.768385189873417, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4111, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.40954724137931 ], [ 29.768654683544302, -1.409816724137931 ], [ 29.76892417721519, -1.409816724137931 ], [ 29.76892417721519, -1.40954724137931 ], [ 29.768654683544302, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4112, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.40954724137931 ], [ 29.76892417721519, -1.409816724137931 ], [ 29.769193670886075, -1.409816724137931 ], [ 29.769193670886075, -1.40954724137931 ], [ 29.76892417721519, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4113, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.40954724137931 ], [ 29.769193670886075, -1.409816724137931 ], [ 29.76946316455696, -1.409816724137931 ], [ 29.76946316455696, -1.40954724137931 ], [ 29.769193670886075, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4114, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.40954724137931 ], [ 29.76946316455696, -1.409816724137931 ], [ 29.769732658227849, -1.409816724137931 ], [ 29.769732658227849, -1.40954724137931 ], [ 29.76946316455696, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4115, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.40954724137931 ], [ 29.769732658227849, -1.409816724137931 ], [ 29.770002151898733, -1.409816724137931 ], [ 29.770002151898733, -1.40954724137931 ], [ 29.769732658227849, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4116, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.40954724137931 ], [ 29.770002151898733, -1.409816724137931 ], [ 29.770271645569618, -1.409816724137931 ], [ 29.770271645569618, -1.40954724137931 ], [ 29.770002151898733, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4117, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.40954724137931 ], [ 29.770271645569618, -1.409816724137931 ], [ 29.770541139240507, -1.409816724137931 ], [ 29.770541139240507, -1.40954724137931 ], [ 29.770271645569618, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4118, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.40954724137931 ], [ 29.770541139240507, -1.409816724137931 ], [ 29.770810632911392, -1.409816724137931 ], [ 29.770810632911392, -1.40954724137931 ], [ 29.770541139240507, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4119, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.40954724137931 ], [ 29.770810632911392, -1.409816724137931 ], [ 29.771080126582277, -1.409816724137931 ], [ 29.771080126582277, -1.40954724137931 ], [ 29.770810632911392, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4120, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.40954724137931 ], [ 29.771080126582277, -1.409816724137931 ], [ 29.771349620253165, -1.409816724137931 ], [ 29.771349620253165, -1.40954724137931 ], [ 29.771080126582277, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4121, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.40954724137931 ], [ 29.771349620253165, -1.409816724137931 ], [ 29.77161911392405, -1.409816724137931 ], [ 29.77161911392405, -1.40954724137931 ], [ 29.771349620253165, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4122, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.40954724137931 ], [ 29.77161911392405, -1.409816724137931 ], [ 29.771888607594935, -1.409816724137931 ], [ 29.771888607594935, -1.40954724137931 ], [ 29.77161911392405, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4123, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.40954724137931 ], [ 29.771888607594935, -1.409816724137931 ], [ 29.772158101265823, -1.409816724137931 ], [ 29.772158101265823, -1.40954724137931 ], [ 29.771888607594935, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4124, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.40954724137931 ], [ 29.772158101265823, -1.409816724137931 ], [ 29.772427594936708, -1.409816724137931 ], [ 29.772427594936708, -1.40954724137931 ], [ 29.772158101265823, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4125, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.40954724137931 ], [ 29.772427594936708, -1.409816724137931 ], [ 29.772697088607593, -1.409816724137931 ], [ 29.772697088607593, -1.40954724137931 ], [ 29.772427594936708, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4126, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.40954724137931 ], [ 29.772697088607593, -1.409816724137931 ], [ 29.772966582278482, -1.409816724137931 ], [ 29.772966582278482, -1.40954724137931 ], [ 29.772697088607593, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4127, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.40954724137931 ], [ 29.772966582278482, -1.409816724137931 ], [ 29.773236075949367, -1.409816724137931 ], [ 29.773236075949367, -1.40954724137931 ], [ 29.772966582278482, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4128, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.40954724137931 ], [ 29.773505569620252, -1.409816724137931 ], [ 29.77377506329114, -1.409816724137931 ], [ 29.77377506329114, -1.40954724137931 ], [ 29.773505569620252, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4129, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.40954724137931 ], [ 29.77377506329114, -1.409816724137931 ], [ 29.774044556962025, -1.409816724137931 ], [ 29.774044556962025, -1.40954724137931 ], [ 29.77377506329114, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4130, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.40954724137931 ], [ 29.774044556962025, -1.409816724137931 ], [ 29.77431405063291, -1.409816724137931 ], [ 29.77431405063291, -1.40954724137931 ], [ 29.774044556962025, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4131, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.40954724137931 ], [ 29.77431405063291, -1.409816724137931 ], [ 29.774853037974683, -1.409816724137931 ], [ 29.774853037974683, -1.40954724137931 ], [ 29.77431405063291, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4132, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.40954724137931 ], [ 29.774853037974683, -1.409816724137931 ], [ 29.775122531645568, -1.409816724137931 ], [ 29.775122531645568, -1.40954724137931 ], [ 29.774853037974683, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4133, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.40954724137931 ], [ 29.775122531645568, -1.409816724137931 ], [ 29.775392025316457, -1.409816724137931 ], [ 29.775392025316457, -1.40954724137931 ], [ 29.775122531645568, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4134, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.40954724137931 ], [ 29.775392025316457, -1.409816724137931 ], [ 29.775661518987341, -1.409816724137931 ], [ 29.775661518987341, -1.40954724137931 ], [ 29.775392025316457, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4135, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.40954724137931 ], [ 29.775661518987341, -1.409816724137931 ], [ 29.775931012658226, -1.409816724137931 ], [ 29.775931012658226, -1.40954724137931 ], [ 29.775661518987341, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4136, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.40954724137931 ], [ 29.775931012658226, -1.409816724137931 ], [ 29.776200506329115, -1.409816724137931 ], [ 29.776200506329115, -1.40954724137931 ], [ 29.775931012658226, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4137, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.40954724137931 ], [ 29.776200506329115, -1.409816724137931 ], [ 29.77647, -1.409816724137931 ], [ 29.77647, -1.40954724137931 ], [ 29.776200506329115, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4138, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.40954724137931 ], [ 29.77647, -1.409816724137931 ], [ 29.776739493670885, -1.409816724137931 ], [ 29.776739493670885, -1.40954724137931 ], [ 29.77647, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4139, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.40954724137931 ], [ 29.776739493670885, -1.409816724137931 ], [ 29.777008987341773, -1.409816724137931 ], [ 29.777008987341773, -1.40954724137931 ], [ 29.776739493670885, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4140, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.40954724137931 ], [ 29.777008987341773, -1.409816724137931 ], [ 29.777278481012658, -1.409816724137931 ], [ 29.777278481012658, -1.40954724137931 ], [ 29.777008987341773, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4141, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.40954724137931 ], [ 29.777278481012658, -1.409816724137931 ], [ 29.777547974683543, -1.409816724137931 ], [ 29.777547974683543, -1.40954724137931 ], [ 29.777278481012658, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4142, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.40954724137931 ], [ 29.777547974683543, -1.409816724137931 ], [ 29.777817468354431, -1.409816724137931 ], [ 29.777817468354431, -1.40954724137931 ], [ 29.777547974683543, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4143, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.40954724137931 ], [ 29.777817468354431, -1.409816724137931 ], [ 29.778086962025316, -1.409816724137931 ], [ 29.778086962025316, -1.40954724137931 ], [ 29.777817468354431, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4144, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.40954724137931 ], [ 29.778086962025316, -1.409816724137931 ], [ 29.778356455696201, -1.409816724137931 ], [ 29.778356455696201, -1.40954724137931 ], [ 29.778086962025316, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4145, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.40954724137931 ], [ 29.778356455696201, -1.409816724137931 ], [ 29.77862594936709, -1.409816724137931 ], [ 29.77862594936709, -1.40954724137931 ], [ 29.778356455696201, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4146, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.40954724137931 ], [ 29.77862594936709, -1.409816724137931 ], [ 29.778895443037975, -1.409816724137931 ], [ 29.778895443037975, -1.40954724137931 ], [ 29.77862594936709, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4147, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.40954724137931 ], [ 29.778895443037975, -1.409816724137931 ], [ 29.77916493670886, -1.409816724137931 ], [ 29.77916493670886, -1.40954724137931 ], [ 29.778895443037975, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4148, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.40954724137931 ], [ 29.77916493670886, -1.409816724137931 ], [ 29.779434430379748, -1.409816724137931 ], [ 29.779434430379748, -1.40954724137931 ], [ 29.77916493670886, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4149, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.40954724137931 ], [ 29.779434430379748, -1.409816724137931 ], [ 29.779703924050633, -1.409816724137931 ], [ 29.779703924050633, -1.40954724137931 ], [ 29.779434430379748, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4150, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.40954724137931 ], [ 29.779973417721518, -1.409816724137931 ], [ 29.780242911392406, -1.409816724137931 ], [ 29.780242911392406, -1.40954724137931 ], [ 29.779973417721518, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4151, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.40954724137931 ], [ 29.780242911392406, -1.409816724137931 ], [ 29.780512405063291, -1.409816724137931 ], [ 29.780512405063291, -1.40954724137931 ], [ 29.780242911392406, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4152, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.40954724137931 ], [ 29.780512405063291, -1.409816724137931 ], [ 29.780781898734176, -1.409816724137931 ], [ 29.780781898734176, -1.40954724137931 ], [ 29.780512405063291, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4153, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.40954724137931 ], [ 29.780781898734176, -1.409816724137931 ], [ 29.781051392405065, -1.409816724137931 ], [ 29.781051392405065, -1.40954724137931 ], [ 29.780781898734176, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4154, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.40954724137931 ], [ 29.781051392405065, -1.410894655172414 ], [ 29.78132088607595, -1.410894655172414 ], [ 29.78132088607595, -1.40954724137931 ], [ 29.781051392405065, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4155, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.40954724137931 ], [ 29.78132088607595, -1.411164137931034 ], [ 29.781590379746834, -1.411164137931034 ], [ 29.781590379746834, -1.40954724137931 ], [ 29.78132088607595, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4156, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.40954724137931 ], [ 29.781590379746834, -1.411164137931034 ], [ 29.781859873417723, -1.411164137931034 ], [ 29.781859873417723, -1.40954724137931 ], [ 29.781590379746834, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4157, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.409816724137931 ], [ 29.761917341772151, -1.410086206896552 ], [ 29.762186835443039, -1.410086206896552 ], [ 29.762186835443039, -1.409816724137931 ], [ 29.761917341772151, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4158, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.409816724137931 ], [ 29.762186835443039, -1.410086206896552 ], [ 29.762456329113924, -1.410086206896552 ], [ 29.762456329113924, -1.409816724137931 ], [ 29.762186835443039, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4159, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.409816724137931 ], [ 29.762456329113924, -1.410086206896552 ], [ 29.762725822784809, -1.410086206896552 ], [ 29.762725822784809, -1.409816724137931 ], [ 29.762456329113924, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4160, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.409816724137931 ], [ 29.767037721518985, -1.411433620689655 ], [ 29.767307215189874, -1.411433620689655 ], [ 29.767307215189874, -1.409816724137931 ], [ 29.767037721518985, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4161, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.409816724137931 ], [ 29.767307215189874, -1.410086206896552 ], [ 29.767576708860759, -1.410086206896552 ], [ 29.767576708860759, -1.409816724137931 ], [ 29.767307215189874, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4162, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.409816724137931 ], [ 29.767576708860759, -1.410086206896552 ], [ 29.767846202531643, -1.410086206896552 ], [ 29.767846202531643, -1.409816724137931 ], [ 29.767576708860759, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4163, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.409816724137931 ], [ 29.767846202531643, -1.410086206896552 ], [ 29.768115696202532, -1.410086206896552 ], [ 29.768115696202532, -1.409816724137931 ], [ 29.767846202531643, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4164, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.409816724137931 ], [ 29.768115696202532, -1.410086206896552 ], [ 29.768385189873417, -1.410086206896552 ], [ 29.768385189873417, -1.409816724137931 ], [ 29.768115696202532, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4165, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.409816724137931 ], [ 29.768385189873417, -1.410086206896552 ], [ 29.768654683544302, -1.410086206896552 ], [ 29.768654683544302, -1.409816724137931 ], [ 29.768385189873417, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4166, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.409816724137931 ], [ 29.768654683544302, -1.410086206896552 ], [ 29.76892417721519, -1.410086206896552 ], [ 29.76892417721519, -1.409816724137931 ], [ 29.768654683544302, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4167, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.409816724137931 ], [ 29.76892417721519, -1.410086206896552 ], [ 29.769193670886075, -1.410086206896552 ], [ 29.769193670886075, -1.409816724137931 ], [ 29.76892417721519, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4168, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.409816724137931 ], [ 29.769193670886075, -1.410086206896552 ], [ 29.76946316455696, -1.410086206896552 ], [ 29.76946316455696, -1.409816724137931 ], [ 29.769193670886075, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4169, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.409816724137931 ], [ 29.76946316455696, -1.410086206896552 ], [ 29.769732658227849, -1.410086206896552 ], [ 29.769732658227849, -1.409816724137931 ], [ 29.76946316455696, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4170, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.409816724137931 ], [ 29.769732658227849, -1.410086206896552 ], [ 29.770002151898733, -1.410086206896552 ], [ 29.770002151898733, -1.409816724137931 ], [ 29.769732658227849, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4171, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.409816724137931 ], [ 29.770002151898733, -1.410086206896552 ], [ 29.770271645569618, -1.410086206896552 ], [ 29.770271645569618, -1.409816724137931 ], [ 29.770002151898733, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4172, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.409816724137931 ], [ 29.770271645569618, -1.410086206896552 ], [ 29.770541139240507, -1.410086206896552 ], [ 29.770541139240507, -1.409816724137931 ], [ 29.770271645569618, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4173, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.409816724137931 ], [ 29.770541139240507, -1.410086206896552 ], [ 29.770810632911392, -1.410086206896552 ], [ 29.770810632911392, -1.409816724137931 ], [ 29.770541139240507, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4174, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.409816724137931 ], [ 29.770810632911392, -1.410086206896552 ], [ 29.771080126582277, -1.410086206896552 ], [ 29.771080126582277, -1.409816724137931 ], [ 29.770810632911392, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4175, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.409816724137931 ], [ 29.771080126582277, -1.410086206896552 ], [ 29.771349620253165, -1.410086206896552 ], [ 29.771349620253165, -1.409816724137931 ], [ 29.771080126582277, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4176, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.409816724137931 ], [ 29.771349620253165, -1.410086206896552 ], [ 29.77161911392405, -1.410086206896552 ], [ 29.77161911392405, -1.409816724137931 ], [ 29.771349620253165, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4177, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.409816724137931 ], [ 29.77161911392405, -1.410086206896552 ], [ 29.771888607594935, -1.410086206896552 ], [ 29.771888607594935, -1.409816724137931 ], [ 29.77161911392405, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4178, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.409816724137931 ], [ 29.771888607594935, -1.410086206896552 ], [ 29.772158101265823, -1.410086206896552 ], [ 29.772158101265823, -1.409816724137931 ], [ 29.771888607594935, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4179, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.409816724137931 ], [ 29.772158101265823, -1.410086206896552 ], [ 29.772427594936708, -1.410086206896552 ], [ 29.772427594936708, -1.409816724137931 ], [ 29.772158101265823, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4180, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.409816724137931 ], [ 29.772427594936708, -1.410086206896552 ], [ 29.772697088607593, -1.410086206896552 ], [ 29.772697088607593, -1.409816724137931 ], [ 29.772427594936708, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4181, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.409816724137931 ], [ 29.772697088607593, -1.410086206896552 ], [ 29.772966582278482, -1.410086206896552 ], [ 29.772966582278482, -1.409816724137931 ], [ 29.772697088607593, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4182, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.409816724137931 ], [ 29.772966582278482, -1.410086206896552 ], [ 29.773236075949367, -1.410086206896552 ], [ 29.773236075949367, -1.409816724137931 ], [ 29.772966582278482, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4183, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.409816724137931 ], [ 29.773236075949367, -1.410086206896552 ], [ 29.773505569620252, -1.410086206896552 ], [ 29.773505569620252, -1.409816724137931 ], [ 29.773236075949367, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4184, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.409816724137931 ], [ 29.773505569620252, -1.410086206896552 ], [ 29.77377506329114, -1.410086206896552 ], [ 29.77377506329114, -1.409816724137931 ], [ 29.773505569620252, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4185, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.409816724137931 ], [ 29.77377506329114, -1.410086206896552 ], [ 29.774044556962025, -1.410086206896552 ], [ 29.774044556962025, -1.409816724137931 ], [ 29.77377506329114, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4186, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.409816724137931 ], [ 29.774044556962025, -1.410086206896552 ], [ 29.77431405063291, -1.410086206896552 ], [ 29.77431405063291, -1.409816724137931 ], [ 29.774044556962025, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4187, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.409816724137931 ], [ 29.77431405063291, -1.410086206896552 ], [ 29.774853037974683, -1.410086206896552 ], [ 29.774853037974683, -1.409816724137931 ], [ 29.77431405063291, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4188, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.409816724137931 ], [ 29.774853037974683, -1.410086206896552 ], [ 29.775122531645568, -1.410086206896552 ], [ 29.775122531645568, -1.409816724137931 ], [ 29.774853037974683, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4189, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.409816724137931 ], [ 29.775122531645568, -1.410086206896552 ], [ 29.775392025316457, -1.410086206896552 ], [ 29.775392025316457, -1.409816724137931 ], [ 29.775122531645568, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4190, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.409816724137931 ], [ 29.775392025316457, -1.410086206896552 ], [ 29.775661518987341, -1.410086206896552 ], [ 29.775661518987341, -1.409816724137931 ], [ 29.775392025316457, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4191, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.409816724137931 ], [ 29.775661518987341, -1.410086206896552 ], [ 29.775931012658226, -1.410086206896552 ], [ 29.775931012658226, -1.409816724137931 ], [ 29.775661518987341, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4192, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.409816724137931 ], [ 29.775931012658226, -1.410086206896552 ], [ 29.776200506329115, -1.410086206896552 ], [ 29.776200506329115, -1.409816724137931 ], [ 29.775931012658226, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4193, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.409816724137931 ], [ 29.776200506329115, -1.410086206896552 ], [ 29.77647, -1.410086206896552 ], [ 29.77647, -1.409816724137931 ], [ 29.776200506329115, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4194, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.409816724137931 ], [ 29.77647, -1.410086206896552 ], [ 29.776739493670885, -1.410086206896552 ], [ 29.776739493670885, -1.409816724137931 ], [ 29.77647, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4195, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.409816724137931 ], [ 29.776739493670885, -1.410086206896552 ], [ 29.777008987341773, -1.410086206896552 ], [ 29.777008987341773, -1.409816724137931 ], [ 29.776739493670885, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4196, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.409816724137931 ], [ 29.777008987341773, -1.410086206896552 ], [ 29.777278481012658, -1.410086206896552 ], [ 29.777278481012658, -1.409816724137931 ], [ 29.777008987341773, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4197, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.409816724137931 ], [ 29.777278481012658, -1.410086206896552 ], [ 29.777547974683543, -1.410086206896552 ], [ 29.777547974683543, -1.409816724137931 ], [ 29.777278481012658, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4198, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.409816724137931 ], [ 29.777547974683543, -1.410086206896552 ], [ 29.777817468354431, -1.410086206896552 ], [ 29.777817468354431, -1.409816724137931 ], [ 29.777547974683543, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4199, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.409816724137931 ], [ 29.777817468354431, -1.410086206896552 ], [ 29.778086962025316, -1.410086206896552 ], [ 29.778086962025316, -1.409816724137931 ], [ 29.777817468354431, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4200, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.409816724137931 ], [ 29.778086962025316, -1.410086206896552 ], [ 29.778356455696201, -1.410086206896552 ], [ 29.778356455696201, -1.409816724137931 ], [ 29.778086962025316, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4201, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.409816724137931 ], [ 29.778356455696201, -1.410086206896552 ], [ 29.77862594936709, -1.410086206896552 ], [ 29.77862594936709, -1.409816724137931 ], [ 29.778356455696201, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4202, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.409816724137931 ], [ 29.77862594936709, -1.410086206896552 ], [ 29.778895443037975, -1.410086206896552 ], [ 29.778895443037975, -1.409816724137931 ], [ 29.77862594936709, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4203, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.409816724137931 ], [ 29.778895443037975, -1.410086206896552 ], [ 29.77916493670886, -1.410086206896552 ], [ 29.77916493670886, -1.409816724137931 ], [ 29.778895443037975, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4204, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.409816724137931 ], [ 29.77916493670886, -1.410086206896552 ], [ 29.779434430379748, -1.410086206896552 ], [ 29.779434430379748, -1.409816724137931 ], [ 29.77916493670886, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4205, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.409816724137931 ], [ 29.779434430379748, -1.410086206896552 ], [ 29.779703924050633, -1.410086206896552 ], [ 29.779703924050633, -1.409816724137931 ], [ 29.779434430379748, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4206, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.409816724137931 ], [ 29.779703924050633, -1.410894655172414 ], [ 29.779973417721518, -1.410894655172414 ], [ 29.779973417721518, -1.409816724137931 ], [ 29.779703924050633, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4207, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.409816724137931 ], [ 29.779973417721518, -1.410086206896552 ], [ 29.780242911392406, -1.410086206896552 ], [ 29.780242911392406, -1.409816724137931 ], [ 29.779973417721518, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4208, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.409816724137931 ], [ 29.780242911392406, -1.410086206896552 ], [ 29.780512405063291, -1.410086206896552 ], [ 29.780512405063291, -1.409816724137931 ], [ 29.780242911392406, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4209, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.409816724137931 ], [ 29.780512405063291, -1.411164137931034 ], [ 29.780781898734176, -1.411164137931034 ], [ 29.780781898734176, -1.409816724137931 ], [ 29.780512405063291, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4210, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.409816724137931 ], [ 29.780781898734176, -1.411164137931034 ], [ 29.781051392405065, -1.411164137931034 ], [ 29.781051392405065, -1.409816724137931 ], [ 29.780781898734176, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4211, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.410086206896552 ], [ 29.767307215189874, -1.411433620689655 ], [ 29.767576708860759, -1.411433620689655 ], [ 29.767576708860759, -1.410086206896552 ], [ 29.767307215189874, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4212, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.410086206896552 ], [ 29.767576708860759, -1.411433620689655 ], [ 29.767846202531643, -1.411433620689655 ], [ 29.767846202531643, -1.410086206896552 ], [ 29.767576708860759, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4213, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.410086206896552 ], [ 29.767846202531643, -1.411433620689655 ], [ 29.768115696202532, -1.411433620689655 ], [ 29.768115696202532, -1.410086206896552 ], [ 29.767846202531643, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4214, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.410086206896552 ], [ 29.768115696202532, -1.410355689655173 ], [ 29.768385189873417, -1.410355689655173 ], [ 29.768385189873417, -1.410086206896552 ], [ 29.768115696202532, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4215, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.410086206896552 ], [ 29.768385189873417, -1.410355689655173 ], [ 29.768654683544302, -1.410355689655173 ], [ 29.768654683544302, -1.410086206896552 ], [ 29.768385189873417, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4216, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.410086206896552 ], [ 29.768654683544302, -1.410355689655173 ], [ 29.76892417721519, -1.410355689655173 ], [ 29.76892417721519, -1.410086206896552 ], [ 29.768654683544302, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4217, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.410086206896552 ], [ 29.76892417721519, -1.410355689655173 ], [ 29.769193670886075, -1.410355689655173 ], [ 29.769193670886075, -1.410086206896552 ], [ 29.76892417721519, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4218, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.410086206896552 ], [ 29.769193670886075, -1.410355689655173 ], [ 29.76946316455696, -1.410355689655173 ], [ 29.76946316455696, -1.410086206896552 ], [ 29.769193670886075, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4219, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.410086206896552 ], [ 29.76946316455696, -1.410355689655173 ], [ 29.769732658227849, -1.410355689655173 ], [ 29.769732658227849, -1.410086206896552 ], [ 29.76946316455696, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4220, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.410086206896552 ], [ 29.769732658227849, -1.410355689655173 ], [ 29.770002151898733, -1.410355689655173 ], [ 29.770002151898733, -1.410086206896552 ], [ 29.769732658227849, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4221, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.410086206896552 ], [ 29.770002151898733, -1.410355689655173 ], [ 29.770271645569618, -1.410355689655173 ], [ 29.770271645569618, -1.410086206896552 ], [ 29.770002151898733, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4222, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.410086206896552 ], [ 29.770271645569618, -1.410355689655173 ], [ 29.770541139240507, -1.410355689655173 ], [ 29.770541139240507, -1.410086206896552 ], [ 29.770271645569618, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4223, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.410086206896552 ], [ 29.770541139240507, -1.410355689655173 ], [ 29.770810632911392, -1.410355689655173 ], [ 29.770810632911392, -1.410086206896552 ], [ 29.770541139240507, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4224, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.410086206896552 ], [ 29.770810632911392, -1.410355689655173 ], [ 29.771080126582277, -1.410355689655173 ], [ 29.771080126582277, -1.410086206896552 ], [ 29.770810632911392, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4225, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.410086206896552 ], [ 29.771080126582277, -1.410355689655173 ], [ 29.771349620253165, -1.410355689655173 ], [ 29.771349620253165, -1.410086206896552 ], [ 29.771080126582277, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4226, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.410086206896552 ], [ 29.771349620253165, -1.410355689655173 ], [ 29.77161911392405, -1.410355689655173 ], [ 29.77161911392405, -1.410086206896552 ], [ 29.771349620253165, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4227, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.410086206896552 ], [ 29.77161911392405, -1.410355689655173 ], [ 29.771888607594935, -1.410355689655173 ], [ 29.771888607594935, -1.410086206896552 ], [ 29.77161911392405, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4228, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.410086206896552 ], [ 29.771888607594935, -1.410355689655173 ], [ 29.772158101265823, -1.410355689655173 ], [ 29.772158101265823, -1.410086206896552 ], [ 29.771888607594935, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4229, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.410086206896552 ], [ 29.772158101265823, -1.410355689655173 ], [ 29.772427594936708, -1.410355689655173 ], [ 29.772427594936708, -1.410086206896552 ], [ 29.772158101265823, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4230, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.410086206896552 ], [ 29.772427594936708, -1.410355689655173 ], [ 29.772697088607593, -1.410355689655173 ], [ 29.772697088607593, -1.410086206896552 ], [ 29.772427594936708, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4231, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.410086206896552 ], [ 29.772697088607593, -1.410355689655173 ], [ 29.772966582278482, -1.410355689655173 ], [ 29.772966582278482, -1.410086206896552 ], [ 29.772697088607593, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4232, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.410086206896552 ], [ 29.772966582278482, -1.410355689655173 ], [ 29.773236075949367, -1.410355689655173 ], [ 29.773236075949367, -1.410086206896552 ], [ 29.772966582278482, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4233, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.410086206896552 ], [ 29.773236075949367, -1.410355689655173 ], [ 29.773505569620252, -1.410355689655173 ], [ 29.773505569620252, -1.410086206896552 ], [ 29.773236075949367, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4234, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.410086206896552 ], [ 29.773505569620252, -1.410355689655173 ], [ 29.77377506329114, -1.410355689655173 ], [ 29.77377506329114, -1.410086206896552 ], [ 29.773505569620252, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4235, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.410086206896552 ], [ 29.77377506329114, -1.410355689655173 ], [ 29.774044556962025, -1.410355689655173 ], [ 29.774044556962025, -1.410086206896552 ], [ 29.77377506329114, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4236, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.410086206896552 ], [ 29.774044556962025, -1.410355689655173 ], [ 29.77431405063291, -1.410355689655173 ], [ 29.77431405063291, -1.410086206896552 ], [ 29.774044556962025, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4237, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.410086206896552 ], [ 29.77431405063291, -1.410355689655173 ], [ 29.774853037974683, -1.410355689655173 ], [ 29.774853037974683, -1.410086206896552 ], [ 29.77431405063291, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4238, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.410086206896552 ], [ 29.774853037974683, -1.410355689655173 ], [ 29.775122531645568, -1.410355689655173 ], [ 29.775122531645568, -1.410086206896552 ], [ 29.774853037974683, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4239, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.410086206896552 ], [ 29.775122531645568, -1.410355689655173 ], [ 29.775392025316457, -1.410355689655173 ], [ 29.775392025316457, -1.410086206896552 ], [ 29.775122531645568, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4240, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.410086206896552 ], [ 29.775392025316457, -1.410355689655173 ], [ 29.775661518987341, -1.410355689655173 ], [ 29.775661518987341, -1.410086206896552 ], [ 29.775392025316457, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4241, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.410086206896552 ], [ 29.775661518987341, -1.410355689655173 ], [ 29.775931012658226, -1.410355689655173 ], [ 29.775931012658226, -1.410086206896552 ], [ 29.775661518987341, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4242, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.410086206896552 ], [ 29.775931012658226, -1.410355689655173 ], [ 29.776200506329115, -1.410355689655173 ], [ 29.776200506329115, -1.410086206896552 ], [ 29.775931012658226, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4243, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.410086206896552 ], [ 29.776200506329115, -1.410355689655173 ], [ 29.77647, -1.410355689655173 ], [ 29.77647, -1.410086206896552 ], [ 29.776200506329115, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4244, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.410086206896552 ], [ 29.77647, -1.410355689655173 ], [ 29.776739493670885, -1.410355689655173 ], [ 29.776739493670885, -1.410086206896552 ], [ 29.77647, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4245, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.410086206896552 ], [ 29.776739493670885, -1.410355689655173 ], [ 29.777008987341773, -1.410355689655173 ], [ 29.777008987341773, -1.410086206896552 ], [ 29.776739493670885, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4246, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.410086206896552 ], [ 29.777008987341773, -1.410355689655173 ], [ 29.777278481012658, -1.410355689655173 ], [ 29.777278481012658, -1.410086206896552 ], [ 29.777008987341773, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4247, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.410086206896552 ], [ 29.777278481012658, -1.410355689655173 ], [ 29.777547974683543, -1.410355689655173 ], [ 29.777547974683543, -1.410086206896552 ], [ 29.777278481012658, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4248, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.410086206896552 ], [ 29.777547974683543, -1.410355689655173 ], [ 29.777817468354431, -1.410355689655173 ], [ 29.777817468354431, -1.410086206896552 ], [ 29.777547974683543, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4249, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.410086206896552 ], [ 29.777817468354431, -1.410355689655173 ], [ 29.778086962025316, -1.410355689655173 ], [ 29.778086962025316, -1.410086206896552 ], [ 29.777817468354431, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4250, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.410086206896552 ], [ 29.778086962025316, -1.410355689655173 ], [ 29.778356455696201, -1.410355689655173 ], [ 29.778356455696201, -1.410086206896552 ], [ 29.778086962025316, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4251, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.410086206896552 ], [ 29.778356455696201, -1.411164137931034 ], [ 29.77862594936709, -1.411164137931034 ], [ 29.77862594936709, -1.410086206896552 ], [ 29.778356455696201, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4252, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.410086206896552 ], [ 29.77862594936709, -1.411433620689655 ], [ 29.778895443037975, -1.411433620689655 ], [ 29.778895443037975, -1.410086206896552 ], [ 29.77862594936709, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4253, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.410086206896552 ], [ 29.778895443037975, -1.410355689655173 ], [ 29.77916493670886, -1.410355689655173 ], [ 29.77916493670886, -1.410086206896552 ], [ 29.778895443037975, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4254, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.410086206896552 ], [ 29.77916493670886, -1.411164137931034 ], [ 29.779434430379748, -1.411164137931034 ], [ 29.779434430379748, -1.410086206896552 ], [ 29.77916493670886, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4255, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.410086206896552 ], [ 29.779434430379748, -1.411433620689655 ], [ 29.779703924050633, -1.411433620689655 ], [ 29.779703924050633, -1.410086206896552 ], [ 29.779434430379748, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4256, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.410086206896552 ], [ 29.779973417721518, -1.411164137931034 ], [ 29.780242911392406, -1.411164137931034 ], [ 29.780242911392406, -1.410086206896552 ], [ 29.779973417721518, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4257, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.410086206896552 ], [ 29.780242911392406, -1.411433620689655 ], [ 29.780512405063291, -1.411433620689655 ], [ 29.780512405063291, -1.410086206896552 ], [ 29.780242911392406, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4258, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.410355689655173 ], [ 29.768115696202532, -1.411433620689655 ], [ 29.768385189873417, -1.411433620689655 ], [ 29.768385189873417, -1.410355689655173 ], [ 29.768115696202532, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4259, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.410355689655173 ], [ 29.768385189873417, -1.411164137931034 ], [ 29.768654683544302, -1.411164137931034 ], [ 29.768654683544302, -1.410355689655173 ], [ 29.768385189873417, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4260, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.410355689655173 ], [ 29.768654683544302, -1.410625172413793 ], [ 29.76892417721519, -1.410625172413793 ], [ 29.76892417721519, -1.410355689655173 ], [ 29.768654683544302, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4261, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.410355689655173 ], [ 29.76892417721519, -1.410625172413793 ], [ 29.769193670886075, -1.410625172413793 ], [ 29.769193670886075, -1.410355689655173 ], [ 29.76892417721519, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4262, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.410355689655173 ], [ 29.769193670886075, -1.410625172413793 ], [ 29.76946316455696, -1.410625172413793 ], [ 29.76946316455696, -1.410355689655173 ], [ 29.769193670886075, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4263, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.410355689655173 ], [ 29.76946316455696, -1.410625172413793 ], [ 29.769732658227849, -1.410625172413793 ], [ 29.769732658227849, -1.410355689655173 ], [ 29.76946316455696, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4264, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.410355689655173 ], [ 29.769732658227849, -1.410625172413793 ], [ 29.770002151898733, -1.410625172413793 ], [ 29.770002151898733, -1.410355689655173 ], [ 29.769732658227849, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4265, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.410355689655173 ], [ 29.770002151898733, -1.410625172413793 ], [ 29.770271645569618, -1.410625172413793 ], [ 29.770271645569618, -1.410355689655173 ], [ 29.770002151898733, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4266, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.410355689655173 ], [ 29.770271645569618, -1.410625172413793 ], [ 29.770541139240507, -1.410625172413793 ], [ 29.770541139240507, -1.410355689655173 ], [ 29.770271645569618, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4267, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.410355689655173 ], [ 29.770541139240507, -1.410625172413793 ], [ 29.770810632911392, -1.410625172413793 ], [ 29.770810632911392, -1.410355689655173 ], [ 29.770541139240507, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4268, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.410355689655173 ], [ 29.770810632911392, -1.410625172413793 ], [ 29.771080126582277, -1.410625172413793 ], [ 29.771080126582277, -1.410355689655173 ], [ 29.770810632911392, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4269, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.410355689655173 ], [ 29.771080126582277, -1.410625172413793 ], [ 29.771349620253165, -1.410625172413793 ], [ 29.771349620253165, -1.410355689655173 ], [ 29.771080126582277, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4270, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.410355689655173 ], [ 29.771349620253165, -1.410625172413793 ], [ 29.77161911392405, -1.410625172413793 ], [ 29.77161911392405, -1.410355689655173 ], [ 29.771349620253165, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4271, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.410355689655173 ], [ 29.77161911392405, -1.410625172413793 ], [ 29.771888607594935, -1.410625172413793 ], [ 29.771888607594935, -1.410355689655173 ], [ 29.77161911392405, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4272, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.410355689655173 ], [ 29.771888607594935, -1.410625172413793 ], [ 29.772158101265823, -1.410625172413793 ], [ 29.772158101265823, -1.410355689655173 ], [ 29.771888607594935, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4273, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.410355689655173 ], [ 29.772158101265823, -1.410625172413793 ], [ 29.772697088607593, -1.410625172413793 ], [ 29.772697088607593, -1.410355689655173 ], [ 29.772158101265823, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4274, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.410355689655173 ], [ 29.772697088607593, -1.410625172413793 ], [ 29.772966582278482, -1.410625172413793 ], [ 29.772966582278482, -1.410355689655173 ], [ 29.772697088607593, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4275, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.410355689655173 ], [ 29.772966582278482, -1.410625172413793 ], [ 29.773236075949367, -1.410625172413793 ], [ 29.773236075949367, -1.410355689655173 ], [ 29.772966582278482, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4276, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.410355689655173 ], [ 29.773236075949367, -1.410625172413793 ], [ 29.773505569620252, -1.410625172413793 ], [ 29.773505569620252, -1.410355689655173 ], [ 29.773236075949367, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4277, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.410355689655173 ], [ 29.773505569620252, -1.410625172413793 ], [ 29.77377506329114, -1.410625172413793 ], [ 29.77377506329114, -1.410355689655173 ], [ 29.773505569620252, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4278, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.410355689655173 ], [ 29.77377506329114, -1.410625172413793 ], [ 29.774044556962025, -1.410625172413793 ], [ 29.774044556962025, -1.410355689655173 ], [ 29.77377506329114, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4279, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.410355689655173 ], [ 29.774044556962025, -1.410625172413793 ], [ 29.77431405063291, -1.410625172413793 ], [ 29.77431405063291, -1.410355689655173 ], [ 29.774044556962025, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4280, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.410355689655173 ], [ 29.77431405063291, -1.410625172413793 ], [ 29.774853037974683, -1.410625172413793 ], [ 29.774853037974683, -1.410355689655173 ], [ 29.77431405063291, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4281, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.410355689655173 ], [ 29.774853037974683, -1.410625172413793 ], [ 29.775122531645568, -1.410625172413793 ], [ 29.775122531645568, -1.410355689655173 ], [ 29.774853037974683, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4282, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.410355689655173 ], [ 29.775122531645568, -1.410625172413793 ], [ 29.775392025316457, -1.410625172413793 ], [ 29.775392025316457, -1.410355689655173 ], [ 29.775122531645568, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4283, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.410355689655173 ], [ 29.775392025316457, -1.410625172413793 ], [ 29.775661518987341, -1.410625172413793 ], [ 29.775661518987341, -1.410355689655173 ], [ 29.775392025316457, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4284, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.410355689655173 ], [ 29.775661518987341, -1.410625172413793 ], [ 29.775931012658226, -1.410625172413793 ], [ 29.775931012658226, -1.410355689655173 ], [ 29.775661518987341, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4285, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.410355689655173 ], [ 29.775931012658226, -1.410625172413793 ], [ 29.776200506329115, -1.410625172413793 ], [ 29.776200506329115, -1.410355689655173 ], [ 29.775931012658226, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4286, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.410355689655173 ], [ 29.776200506329115, -1.410625172413793 ], [ 29.77647, -1.410625172413793 ], [ 29.77647, -1.410355689655173 ], [ 29.776200506329115, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4287, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.410355689655173 ], [ 29.77647, -1.410625172413793 ], [ 29.776739493670885, -1.410625172413793 ], [ 29.776739493670885, -1.410355689655173 ], [ 29.77647, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4288, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.410355689655173 ], [ 29.776739493670885, -1.410625172413793 ], [ 29.777008987341773, -1.410625172413793 ], [ 29.777008987341773, -1.410355689655173 ], [ 29.776739493670885, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4289, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.410355689655173 ], [ 29.777008987341773, -1.411164137931034 ], [ 29.777278481012658, -1.411164137931034 ], [ 29.777278481012658, -1.410355689655173 ], [ 29.777008987341773, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4290, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.410355689655173 ], [ 29.777278481012658, -1.411433620689655 ], [ 29.777547974683543, -1.411433620689655 ], [ 29.777547974683543, -1.410355689655173 ], [ 29.777278481012658, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4291, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.410355689655173 ], [ 29.777547974683543, -1.410625172413793 ], [ 29.777817468354431, -1.410625172413793 ], [ 29.777817468354431, -1.410355689655173 ], [ 29.777547974683543, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4292, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.410355689655173 ], [ 29.777817468354431, -1.411164137931034 ], [ 29.778086962025316, -1.411164137931034 ], [ 29.778086962025316, -1.410355689655173 ], [ 29.777817468354431, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4293, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.410355689655173 ], [ 29.778086962025316, -1.411433620689655 ], [ 29.778356455696201, -1.411433620689655 ], [ 29.778356455696201, -1.410355689655173 ], [ 29.778086962025316, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4294, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.410355689655173 ], [ 29.778895443037975, -1.411433620689655 ], [ 29.77916493670886, -1.411433620689655 ], [ 29.77916493670886, -1.410355689655173 ], [ 29.778895443037975, -1.410355689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4295, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.410625172413793 ], [ 29.768654683544302, -1.411164137931034 ], [ 29.76892417721519, -1.411164137931034 ], [ 29.76892417721519, -1.410625172413793 ], [ 29.768654683544302, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4296, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.410625172413793 ], [ 29.76892417721519, -1.411164137931034 ], [ 29.769193670886075, -1.411164137931034 ], [ 29.769193670886075, -1.410625172413793 ], [ 29.76892417721519, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4297, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.410625172413793 ], [ 29.769193670886075, -1.410894655172414 ], [ 29.76946316455696, -1.410894655172414 ], [ 29.76946316455696, -1.410625172413793 ], [ 29.769193670886075, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4298, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.410625172413793 ], [ 29.76946316455696, -1.410894655172414 ], [ 29.769732658227849, -1.410894655172414 ], [ 29.769732658227849, -1.410625172413793 ], [ 29.76946316455696, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4299, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.410625172413793 ], [ 29.769732658227849, -1.410894655172414 ], [ 29.770002151898733, -1.410894655172414 ], [ 29.770002151898733, -1.410625172413793 ], [ 29.769732658227849, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4300, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.410625172413793 ], [ 29.770002151898733, -1.410894655172414 ], [ 29.770271645569618, -1.410894655172414 ], [ 29.770271645569618, -1.410625172413793 ], [ 29.770002151898733, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4301, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.410625172413793 ], [ 29.770271645569618, -1.410894655172414 ], [ 29.770541139240507, -1.410894655172414 ], [ 29.770541139240507, -1.410625172413793 ], [ 29.770271645569618, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4302, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.410625172413793 ], [ 29.770541139240507, -1.410894655172414 ], [ 29.770810632911392, -1.410894655172414 ], [ 29.770810632911392, -1.410625172413793 ], [ 29.770541139240507, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4303, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.410625172413793 ], [ 29.770810632911392, -1.410894655172414 ], [ 29.771080126582277, -1.410894655172414 ], [ 29.771080126582277, -1.410625172413793 ], [ 29.770810632911392, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4304, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.410625172413793 ], [ 29.771080126582277, -1.410894655172414 ], [ 29.771349620253165, -1.410894655172414 ], [ 29.771349620253165, -1.410625172413793 ], [ 29.771080126582277, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4305, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.410625172413793 ], [ 29.771349620253165, -1.410894655172414 ], [ 29.77161911392405, -1.410894655172414 ], [ 29.77161911392405, -1.410625172413793 ], [ 29.771349620253165, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4306, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.410625172413793 ], [ 29.77161911392405, -1.410894655172414 ], [ 29.771888607594935, -1.410894655172414 ], [ 29.771888607594935, -1.410625172413793 ], [ 29.77161911392405, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4307, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.410625172413793 ], [ 29.771888607594935, -1.410894655172414 ], [ 29.772158101265823, -1.410894655172414 ], [ 29.772158101265823, -1.410625172413793 ], [ 29.771888607594935, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4308, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.410625172413793 ], [ 29.772158101265823, -1.410894655172414 ], [ 29.772697088607593, -1.410894655172414 ], [ 29.772697088607593, -1.410625172413793 ], [ 29.772158101265823, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4309, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.410625172413793 ], [ 29.772697088607593, -1.410894655172414 ], [ 29.772966582278482, -1.410894655172414 ], [ 29.772966582278482, -1.410625172413793 ], [ 29.772697088607593, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4310, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.410625172413793 ], [ 29.772966582278482, -1.410894655172414 ], [ 29.773236075949367, -1.410894655172414 ], [ 29.773236075949367, -1.410625172413793 ], [ 29.772966582278482, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4311, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.410625172413793 ], [ 29.773236075949367, -1.410894655172414 ], [ 29.773505569620252, -1.410894655172414 ], [ 29.773505569620252, -1.410625172413793 ], [ 29.773236075949367, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4312, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.410625172413793 ], [ 29.773505569620252, -1.410894655172414 ], [ 29.77377506329114, -1.410894655172414 ], [ 29.77377506329114, -1.410625172413793 ], [ 29.773505569620252, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4313, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.410625172413793 ], [ 29.77377506329114, -1.410894655172414 ], [ 29.774044556962025, -1.410894655172414 ], [ 29.774044556962025, -1.410625172413793 ], [ 29.77377506329114, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4314, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.410625172413793 ], [ 29.774044556962025, -1.410894655172414 ], [ 29.77431405063291, -1.410894655172414 ], [ 29.77431405063291, -1.410625172413793 ], [ 29.774044556962025, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4315, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.410625172413793 ], [ 29.77431405063291, -1.410894655172414 ], [ 29.774853037974683, -1.410894655172414 ], [ 29.774853037974683, -1.410625172413793 ], [ 29.77431405063291, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4316, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.410625172413793 ], [ 29.774853037974683, -1.410894655172414 ], [ 29.775122531645568, -1.410894655172414 ], [ 29.775122531645568, -1.410625172413793 ], [ 29.774853037974683, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4317, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.410625172413793 ], [ 29.775122531645568, -1.410894655172414 ], [ 29.775392025316457, -1.410894655172414 ], [ 29.775392025316457, -1.410625172413793 ], [ 29.775122531645568, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4318, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.410625172413793 ], [ 29.775392025316457, -1.410894655172414 ], [ 29.775661518987341, -1.410894655172414 ], [ 29.775661518987341, -1.410625172413793 ], [ 29.775392025316457, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4319, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.410625172413793 ], [ 29.775661518987341, -1.410894655172414 ], [ 29.775931012658226, -1.410894655172414 ], [ 29.775931012658226, -1.410625172413793 ], [ 29.775661518987341, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4320, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.410625172413793 ], [ 29.775931012658226, -1.410894655172414 ], [ 29.776200506329115, -1.410894655172414 ], [ 29.776200506329115, -1.410625172413793 ], [ 29.775931012658226, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4321, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.410625172413793 ], [ 29.776200506329115, -1.410894655172414 ], [ 29.77647, -1.410894655172414 ], [ 29.77647, -1.410625172413793 ], [ 29.776200506329115, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4322, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.410625172413793 ], [ 29.77647, -1.411433620689655 ], [ 29.776739493670885, -1.411433620689655 ], [ 29.776739493670885, -1.410625172413793 ], [ 29.77647, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4323, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.410894655172414 ], [ 29.769193670886075, -1.411164137931034 ], [ 29.76946316455696, -1.411164137931034 ], [ 29.76946316455696, -1.410894655172414 ], [ 29.769193670886075, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4324, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.410894655172414 ], [ 29.76946316455696, -1.411164137931034 ], [ 29.769732658227849, -1.411164137931034 ], [ 29.769732658227849, -1.410894655172414 ], [ 29.76946316455696, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4325, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.410894655172414 ], [ 29.769732658227849, -1.411164137931034 ], [ 29.770002151898733, -1.411164137931034 ], [ 29.770002151898733, -1.410894655172414 ], [ 29.769732658227849, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4326, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.410894655172414 ], [ 29.770002151898733, -1.411164137931034 ], [ 29.770271645569618, -1.411164137931034 ], [ 29.770271645569618, -1.410894655172414 ], [ 29.770002151898733, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4327, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.410894655172414 ], [ 29.770271645569618, -1.411164137931034 ], [ 29.770541139240507, -1.411164137931034 ], [ 29.770541139240507, -1.410894655172414 ], [ 29.770271645569618, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4328, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.410894655172414 ], [ 29.770810632911392, -1.411164137931034 ], [ 29.771080126582277, -1.411164137931034 ], [ 29.771080126582277, -1.410894655172414 ], [ 29.770810632911392, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4329, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.410894655172414 ], [ 29.771080126582277, -1.411164137931034 ], [ 29.771349620253165, -1.411164137931034 ], [ 29.771349620253165, -1.410894655172414 ], [ 29.771080126582277, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4330, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.410894655172414 ], [ 29.771349620253165, -1.411164137931034 ], [ 29.77161911392405, -1.411164137931034 ], [ 29.77161911392405, -1.410894655172414 ], [ 29.771349620253165, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4331, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.410894655172414 ], [ 29.77161911392405, -1.411164137931034 ], [ 29.771888607594935, -1.411164137931034 ], [ 29.771888607594935, -1.410894655172414 ], [ 29.77161911392405, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4332, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.410894655172414 ], [ 29.771888607594935, -1.411164137931034 ], [ 29.772158101265823, -1.411164137931034 ], [ 29.772158101265823, -1.410894655172414 ], [ 29.771888607594935, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4333, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.410894655172414 ], [ 29.772158101265823, -1.411164137931034 ], [ 29.772427594936708, -1.411164137931034 ], [ 29.772427594936708, -1.410894655172414 ], [ 29.772158101265823, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4334, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.410894655172414 ], [ 29.772427594936708, -1.411164137931034 ], [ 29.772697088607593, -1.411164137931034 ], [ 29.772697088607593, -1.410894655172414 ], [ 29.772427594936708, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4335, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.410894655172414 ], [ 29.772697088607593, -1.411164137931034 ], [ 29.772966582278482, -1.411164137931034 ], [ 29.772966582278482, -1.410894655172414 ], [ 29.772697088607593, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4336, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.410894655172414 ], [ 29.772966582278482, -1.411164137931034 ], [ 29.773236075949367, -1.411164137931034 ], [ 29.773236075949367, -1.410894655172414 ], [ 29.772966582278482, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4337, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.410894655172414 ], [ 29.773236075949367, -1.411164137931034 ], [ 29.773505569620252, -1.411164137931034 ], [ 29.773505569620252, -1.410894655172414 ], [ 29.773236075949367, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4338, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.410894655172414 ], [ 29.773505569620252, -1.411164137931034 ], [ 29.77377506329114, -1.411164137931034 ], [ 29.77377506329114, -1.410894655172414 ], [ 29.773505569620252, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4339, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.410894655172414 ], [ 29.77377506329114, -1.411164137931034 ], [ 29.774044556962025, -1.411164137931034 ], [ 29.774044556962025, -1.410894655172414 ], [ 29.77377506329114, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4340, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.410894655172414 ], [ 29.774044556962025, -1.411164137931034 ], [ 29.77431405063291, -1.411164137931034 ], [ 29.77431405063291, -1.410894655172414 ], [ 29.774044556962025, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4341, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.410894655172414 ], [ 29.77431405063291, -1.411164137931034 ], [ 29.774853037974683, -1.411164137931034 ], [ 29.774853037974683, -1.410894655172414 ], [ 29.77431405063291, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4342, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.410894655172414 ], [ 29.774853037974683, -1.411164137931034 ], [ 29.775122531645568, -1.411164137931034 ], [ 29.775122531645568, -1.410894655172414 ], [ 29.774853037974683, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4343, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.410894655172414 ], [ 29.775122531645568, -1.411164137931034 ], [ 29.775392025316457, -1.411164137931034 ], [ 29.775392025316457, -1.410894655172414 ], [ 29.775122531645568, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4344, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.410894655172414 ], [ 29.775661518987341, -1.411164137931034 ], [ 29.775931012658226, -1.411164137931034 ], [ 29.775931012658226, -1.410894655172414 ], [ 29.775661518987341, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4345, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.410894655172414 ], [ 29.775931012658226, -1.411433620689655 ], [ 29.776200506329115, -1.411433620689655 ], [ 29.776200506329115, -1.410894655172414 ], [ 29.775931012658226, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4346, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.410894655172414 ], [ 29.779703924050633, -1.411433620689655 ], [ 29.779973417721518, -1.411433620689655 ], [ 29.779973417721518, -1.410894655172414 ], [ 29.779703924050633, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4347, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.410894655172414 ], [ 29.781051392405065, -1.411433620689655 ], [ 29.78132088607595, -1.411433620689655 ], [ 29.78132088607595, -1.410894655172414 ], [ 29.781051392405065, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4348, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.410894655172414 ], [ 29.781859873417723, -1.411164137931034 ], [ 29.782129367088608, -1.411164137931034 ], [ 29.782129367088608, -1.410894655172414 ], [ 29.781859873417723, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4349, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.410894655172414 ], [ 29.782129367088608, -1.411164137931034 ], [ 29.782398860759493, -1.411164137931034 ], [ 29.782398860759493, -1.410894655172414 ], [ 29.782129367088608, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4350, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.410894655172414 ], [ 29.782398860759493, -1.411164137931034 ], [ 29.782668354430381, -1.411164137931034 ], [ 29.782668354430381, -1.410894655172414 ], [ 29.782398860759493, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4351, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.411164137931034 ], [ 29.768385189873417, -1.411433620689655 ], [ 29.768654683544302, -1.411433620689655 ], [ 29.768654683544302, -1.411164137931034 ], [ 29.768385189873417, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4352, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.411164137931034 ], [ 29.768654683544302, -1.411433620689655 ], [ 29.76892417721519, -1.411433620689655 ], [ 29.76892417721519, -1.411164137931034 ], [ 29.768654683544302, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4353, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.411164137931034 ], [ 29.76892417721519, -1.411433620689655 ], [ 29.769193670886075, -1.411433620689655 ], [ 29.769193670886075, -1.411164137931034 ], [ 29.76892417721519, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4354, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.411164137931034 ], [ 29.769193670886075, -1.411433620689655 ], [ 29.76946316455696, -1.411433620689655 ], [ 29.76946316455696, -1.411164137931034 ], [ 29.769193670886075, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4355, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.411164137931034 ], [ 29.76946316455696, -1.411433620689655 ], [ 29.769732658227849, -1.411433620689655 ], [ 29.769732658227849, -1.411164137931034 ], [ 29.76946316455696, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4356, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.411164137931034 ], [ 29.769732658227849, -1.411433620689655 ], [ 29.770002151898733, -1.411433620689655 ], [ 29.770002151898733, -1.411164137931034 ], [ 29.769732658227849, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4357, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.411164137931034 ], [ 29.770002151898733, -1.411433620689655 ], [ 29.770271645569618, -1.411433620689655 ], [ 29.770271645569618, -1.411164137931034 ], [ 29.770002151898733, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4358, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.411164137931034 ], [ 29.770271645569618, -1.411433620689655 ], [ 29.770541139240507, -1.411433620689655 ], [ 29.770541139240507, -1.411164137931034 ], [ 29.770271645569618, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4359, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.411164137931034 ], [ 29.770810632911392, -1.411433620689655 ], [ 29.771080126582277, -1.411433620689655 ], [ 29.771080126582277, -1.411164137931034 ], [ 29.770810632911392, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4360, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.411164137931034 ], [ 29.771080126582277, -1.411433620689655 ], [ 29.771349620253165, -1.411433620689655 ], [ 29.771349620253165, -1.411164137931034 ], [ 29.771080126582277, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4361, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.411164137931034 ], [ 29.771349620253165, -1.411433620689655 ], [ 29.77161911392405, -1.411433620689655 ], [ 29.77161911392405, -1.411164137931034 ], [ 29.771349620253165, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4362, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.411164137931034 ], [ 29.772158101265823, -1.411433620689655 ], [ 29.772427594936708, -1.411433620689655 ], [ 29.772427594936708, -1.411164137931034 ], [ 29.772158101265823, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4363, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.411164137931034 ], [ 29.772427594936708, -1.411433620689655 ], [ 29.772697088607593, -1.411433620689655 ], [ 29.772697088607593, -1.411164137931034 ], [ 29.772427594936708, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4364, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.411164137931034 ], [ 29.772697088607593, -1.411433620689655 ], [ 29.772966582278482, -1.411433620689655 ], [ 29.772966582278482, -1.411164137931034 ], [ 29.772697088607593, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4365, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.411164137931034 ], [ 29.772966582278482, -1.411433620689655 ], [ 29.773236075949367, -1.411433620689655 ], [ 29.773236075949367, -1.411164137931034 ], [ 29.772966582278482, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4366, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.411164137931034 ], [ 29.773236075949367, -1.411433620689655 ], [ 29.773505569620252, -1.411433620689655 ], [ 29.773505569620252, -1.411164137931034 ], [ 29.773236075949367, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4367, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.411164137931034 ], [ 29.773505569620252, -1.411433620689655 ], [ 29.77377506329114, -1.411433620689655 ], [ 29.77377506329114, -1.411164137931034 ], [ 29.773505569620252, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4368, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.411164137931034 ], [ 29.77377506329114, -1.411433620689655 ], [ 29.774044556962025, -1.411433620689655 ], [ 29.774044556962025, -1.411164137931034 ], [ 29.77377506329114, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4369, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.411164137931034 ], [ 29.774044556962025, -1.411433620689655 ], [ 29.77431405063291, -1.411433620689655 ], [ 29.77431405063291, -1.411164137931034 ], [ 29.774044556962025, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4370, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.411164137931034 ], [ 29.775122531645568, -1.411433620689655 ], [ 29.775392025316457, -1.411433620689655 ], [ 29.775392025316457, -1.411164137931034 ], [ 29.775122531645568, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4371, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.411164137931034 ], [ 29.780512405063291, -1.411433620689655 ], [ 29.780781898734176, -1.411433620689655 ], [ 29.780781898734176, -1.411164137931034 ], [ 29.780512405063291, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4372, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.411164137931034 ], [ 29.780781898734176, -1.411433620689655 ], [ 29.781051392405065, -1.411433620689655 ], [ 29.781051392405065, -1.411164137931034 ], [ 29.780781898734176, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4373, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.411164137931034 ], [ 29.78132088607595, -1.411433620689655 ], [ 29.781590379746834, -1.411433620689655 ], [ 29.781590379746834, -1.411164137931034 ], [ 29.78132088607595, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4374, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.411164137931034 ], [ 29.781590379746834, -1.411433620689655 ], [ 29.781859873417723, -1.411433620689655 ], [ 29.781859873417723, -1.411164137931034 ], [ 29.781590379746834, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4375, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.411164137931034 ], [ 29.781859873417723, -1.411433620689655 ], [ 29.782129367088608, -1.411433620689655 ], [ 29.782129367088608, -1.411164137931034 ], [ 29.781859873417723, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4376, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.40927775862069 ], [ 29.765959746835442, -1.411703103448276 ], [ 29.766229240506327, -1.411703103448276 ], [ 29.766229240506327, -1.40927775862069 ], [ 29.765959746835442, -1.40927775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4377, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.40954724137931 ], [ 29.76407329113924, -1.411972586206897 ], [ 29.764342784810125, -1.411972586206897 ], [ 29.764342784810125, -1.40954724137931 ], [ 29.76407329113924, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4378, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.40954724137931 ], [ 29.764881772151899, -1.411972586206897 ], [ 29.765151265822784, -1.411972586206897 ], [ 29.765151265822784, -1.40954724137931 ], [ 29.764881772151899, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4379, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.40954724137931 ], [ 29.765420759493672, -1.411703103448276 ], [ 29.765959746835442, -1.411703103448276 ], [ 29.765959746835442, -1.40954724137931 ], [ 29.765420759493672, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4380, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.40954724137931 ], [ 29.766498734177215, -1.411703103448276 ], [ 29.7667682278481, -1.411703103448276 ], [ 29.7667682278481, -1.40954724137931 ], [ 29.766498734177215, -1.40954724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4381, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.409816724137931 ], [ 29.762725822784809, -1.412511551724138 ], [ 29.762995316455697, -1.412511551724138 ], [ 29.762995316455697, -1.409816724137931 ], [ 29.762725822784809, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4382, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.409816724137931 ], [ 29.762995316455697, -1.412511551724138 ], [ 29.763264810126582, -1.412511551724138 ], [ 29.763264810126582, -1.409816724137931 ], [ 29.762995316455697, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4383, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.409816724137931 ], [ 29.763534303797467, -1.412242068965517 ], [ 29.763803797468356, -1.412242068965517 ], [ 29.763803797468356, -1.409816724137931 ], [ 29.763534303797467, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4384, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.409816724137931 ], [ 29.763803797468356, -1.412242068965517 ], [ 29.76407329113924, -1.412242068965517 ], [ 29.76407329113924, -1.409816724137931 ], [ 29.763803797468356, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4385, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.409816724137931 ], [ 29.764342784810125, -1.411972586206897 ], [ 29.764612278481014, -1.411972586206897 ], [ 29.764612278481014, -1.409816724137931 ], [ 29.764342784810125, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4386, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.409816724137931 ], [ 29.765151265822784, -1.411972586206897 ], [ 29.765420759493672, -1.411972586206897 ], [ 29.765420759493672, -1.409816724137931 ], [ 29.765151265822784, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4387, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.409816724137931 ], [ 29.766229240506327, -1.411703103448276 ], [ 29.766498734177215, -1.411703103448276 ], [ 29.766498734177215, -1.409816724137931 ], [ 29.766229240506327, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4388, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.409816724137931 ], [ 29.7667682278481, -1.411703103448276 ], [ 29.767037721518985, -1.411703103448276 ], [ 29.767037721518985, -1.409816724137931 ], [ 29.7667682278481, -1.409816724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4389, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.410086206896552 ], [ 29.761917341772151, -1.413050517241379 ], [ 29.762186835443039, -1.413050517241379 ], [ 29.762186835443039, -1.410086206896552 ], [ 29.761917341772151, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4390, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.410086206896552 ], [ 29.762186835443039, -1.412781034482759 ], [ 29.762456329113924, -1.412781034482759 ], [ 29.762456329113924, -1.410086206896552 ], [ 29.762186835443039, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4391, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.410086206896552 ], [ 29.762456329113924, -1.412781034482759 ], [ 29.762725822784809, -1.412781034482759 ], [ 29.762725822784809, -1.410086206896552 ], [ 29.762456329113924, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4392, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.410086206896552 ], [ 29.763264810126582, -1.412511551724138 ], [ 29.763534303797467, -1.412511551724138 ], [ 29.763534303797467, -1.410086206896552 ], [ 29.763264810126582, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4393, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.410086206896552 ], [ 29.764612278481014, -1.412242068965517 ], [ 29.764881772151899, -1.412242068965517 ], [ 29.764881772151899, -1.410086206896552 ], [ 29.764612278481014, -1.410086206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4394, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.410625172413793 ], [ 29.776739493670885, -1.411703103448276 ], [ 29.777008987341773, -1.411703103448276 ], [ 29.777008987341773, -1.410625172413793 ], [ 29.776739493670885, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4395, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.410625172413793 ], [ 29.777547974683543, -1.411703103448276 ], [ 29.777817468354431, -1.411703103448276 ], [ 29.777817468354431, -1.410625172413793 ], [ 29.777547974683543, -1.410625172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4396, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.410894655172414 ], [ 29.770541139240507, -1.411703103448276 ], [ 29.770810632911392, -1.411703103448276 ], [ 29.770810632911392, -1.410894655172414 ], [ 29.770541139240507, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4397, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.410894655172414 ], [ 29.775392025316457, -1.411703103448276 ], [ 29.775661518987341, -1.411703103448276 ], [ 29.775661518987341, -1.410894655172414 ], [ 29.775392025316457, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4398, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.410894655172414 ], [ 29.776200506329115, -1.411703103448276 ], [ 29.77647, -1.411703103448276 ], [ 29.77647, -1.410894655172414 ], [ 29.776200506329115, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4399, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.410894655172414 ], [ 29.782668354430381, -1.411703103448276 ], [ 29.782937848101266, -1.411703103448276 ], [ 29.782937848101266, -1.410894655172414 ], [ 29.782668354430381, -1.410894655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4400, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.411164137931034 ], [ 29.77161911392405, -1.411703103448276 ], [ 29.771888607594935, -1.411703103448276 ], [ 29.771888607594935, -1.411164137931034 ], [ 29.77161911392405, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4401, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.411164137931034 ], [ 29.771888607594935, -1.411703103448276 ], [ 29.772158101265823, -1.411703103448276 ], [ 29.772158101265823, -1.411164137931034 ], [ 29.771888607594935, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4402, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.411164137931034 ], [ 29.77431405063291, -1.411703103448276 ], [ 29.774853037974683, -1.411703103448276 ], [ 29.774853037974683, -1.411164137931034 ], [ 29.77431405063291, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4403, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.411164137931034 ], [ 29.774853037974683, -1.411703103448276 ], [ 29.775122531645568, -1.411703103448276 ], [ 29.775122531645568, -1.411164137931034 ], [ 29.774853037974683, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4404, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.411164137931034 ], [ 29.775661518987341, -1.411972586206897 ], [ 29.775931012658226, -1.411972586206897 ], [ 29.775931012658226, -1.411164137931034 ], [ 29.775661518987341, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4405, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.411164137931034 ], [ 29.777008987341773, -1.411703103448276 ], [ 29.777278481012658, -1.411703103448276 ], [ 29.777278481012658, -1.411164137931034 ], [ 29.777008987341773, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4406, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.411164137931034 ], [ 29.777817468354431, -1.411703103448276 ], [ 29.778086962025316, -1.411703103448276 ], [ 29.778086962025316, -1.411164137931034 ], [ 29.777817468354431, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4407, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.411164137931034 ], [ 29.778356455696201, -1.411703103448276 ], [ 29.77862594936709, -1.411703103448276 ], [ 29.77862594936709, -1.411164137931034 ], [ 29.778356455696201, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4408, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.411164137931034 ], [ 29.77916493670886, -1.411703103448276 ], [ 29.779434430379748, -1.411703103448276 ], [ 29.779434430379748, -1.411164137931034 ], [ 29.77916493670886, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4409, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.411164137931034 ], [ 29.779973417721518, -1.411703103448276 ], [ 29.780242911392406, -1.411703103448276 ], [ 29.780242911392406, -1.411164137931034 ], [ 29.779973417721518, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4410, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.411164137931034 ], [ 29.782129367088608, -1.411703103448276 ], [ 29.782398860759493, -1.411703103448276 ], [ 29.782398860759493, -1.411164137931034 ], [ 29.782129367088608, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4411, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.411164137931034 ], [ 29.782398860759493, -1.411703103448276 ], [ 29.782668354430381, -1.411703103448276 ], [ 29.782668354430381, -1.411164137931034 ], [ 29.782398860759493, -1.411164137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4412, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.411433620689655 ], [ 29.757066455696201, -1.411703103448276 ], [ 29.757335949367089, -1.411703103448276 ], [ 29.757335949367089, -1.411433620689655 ], [ 29.757066455696201, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4413, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.411433620689655 ], [ 29.767037721518985, -1.411703103448276 ], [ 29.767307215189874, -1.411703103448276 ], [ 29.767307215189874, -1.411433620689655 ], [ 29.767037721518985, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4414, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.411433620689655 ], [ 29.767307215189874, -1.411703103448276 ], [ 29.767576708860759, -1.411703103448276 ], [ 29.767576708860759, -1.411433620689655 ], [ 29.767307215189874, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4415, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.411433620689655 ], [ 29.767576708860759, -1.411703103448276 ], [ 29.767846202531643, -1.411703103448276 ], [ 29.767846202531643, -1.411433620689655 ], [ 29.767576708860759, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4416, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.411433620689655 ], [ 29.767846202531643, -1.411703103448276 ], [ 29.768115696202532, -1.411703103448276 ], [ 29.768115696202532, -1.411433620689655 ], [ 29.767846202531643, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4417, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.411433620689655 ], [ 29.768115696202532, -1.411703103448276 ], [ 29.768385189873417, -1.411703103448276 ], [ 29.768385189873417, -1.411433620689655 ], [ 29.768115696202532, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4418, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.411433620689655 ], [ 29.768385189873417, -1.411703103448276 ], [ 29.768654683544302, -1.411703103448276 ], [ 29.768654683544302, -1.411433620689655 ], [ 29.768385189873417, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4419, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.411433620689655 ], [ 29.768654683544302, -1.411703103448276 ], [ 29.76892417721519, -1.411703103448276 ], [ 29.76892417721519, -1.411433620689655 ], [ 29.768654683544302, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4420, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.411433620689655 ], [ 29.76892417721519, -1.411703103448276 ], [ 29.769193670886075, -1.411703103448276 ], [ 29.769193670886075, -1.411433620689655 ], [ 29.76892417721519, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4421, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.411433620689655 ], [ 29.769193670886075, -1.411703103448276 ], [ 29.76946316455696, -1.411703103448276 ], [ 29.76946316455696, -1.411433620689655 ], [ 29.769193670886075, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4422, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.411433620689655 ], [ 29.76946316455696, -1.411972586206897 ], [ 29.769732658227849, -1.411972586206897 ], [ 29.769732658227849, -1.411433620689655 ], [ 29.76946316455696, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4423, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.411433620689655 ], [ 29.769732658227849, -1.411703103448276 ], [ 29.770002151898733, -1.411703103448276 ], [ 29.770002151898733, -1.411433620689655 ], [ 29.769732658227849, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4424, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.411433620689655 ], [ 29.770002151898733, -1.411703103448276 ], [ 29.770271645569618, -1.411703103448276 ], [ 29.770271645569618, -1.411433620689655 ], [ 29.770002151898733, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4425, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.411433620689655 ], [ 29.770271645569618, -1.411703103448276 ], [ 29.770541139240507, -1.411703103448276 ], [ 29.770541139240507, -1.411433620689655 ], [ 29.770271645569618, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4426, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.411433620689655 ], [ 29.770810632911392, -1.411703103448276 ], [ 29.771080126582277, -1.411703103448276 ], [ 29.771080126582277, -1.411433620689655 ], [ 29.770810632911392, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4427, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.411433620689655 ], [ 29.771080126582277, -1.411703103448276 ], [ 29.771349620253165, -1.411703103448276 ], [ 29.771349620253165, -1.411433620689655 ], [ 29.771080126582277, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4428, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.411433620689655 ], [ 29.771349620253165, -1.411972586206897 ], [ 29.77161911392405, -1.411972586206897 ], [ 29.77161911392405, -1.411433620689655 ], [ 29.771349620253165, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4429, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.411433620689655 ], [ 29.772158101265823, -1.411972586206897 ], [ 29.772427594936708, -1.411972586206897 ], [ 29.772427594936708, -1.411703103448276 ], [ 29.772697088607593, -1.411703103448276 ], [ 29.772697088607593, -1.411433620689655 ], [ 29.772158101265823, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4430, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.411433620689655 ], [ 29.772697088607593, -1.411703103448276 ], [ 29.772966582278482, -1.411703103448276 ], [ 29.772966582278482, -1.411433620689655 ], [ 29.772697088607593, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4431, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.411433620689655 ], [ 29.772966582278482, -1.411703103448276 ], [ 29.773236075949367, -1.411703103448276 ], [ 29.773236075949367, -1.411433620689655 ], [ 29.772966582278482, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4432, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.411433620689655 ], [ 29.773236075949367, -1.411972586206897 ], [ 29.773505569620252, -1.411972586206897 ], [ 29.773505569620252, -1.411433620689655 ], [ 29.773236075949367, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4433, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.411433620689655 ], [ 29.773505569620252, -1.412242068965517 ], [ 29.77377506329114, -1.412242068965517 ], [ 29.77377506329114, -1.411433620689655 ], [ 29.773505569620252, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4434, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.411433620689655 ], [ 29.77377506329114, -1.411703103448276 ], [ 29.774044556962025, -1.411703103448276 ], [ 29.774044556962025, -1.411433620689655 ], [ 29.77377506329114, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4435, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.411433620689655 ], [ 29.774044556962025, -1.411972586206897 ], [ 29.77431405063291, -1.411972586206897 ], [ 29.77431405063291, -1.411433620689655 ], [ 29.774044556962025, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4436, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.411433620689655 ], [ 29.775122531645568, -1.411972586206897 ], [ 29.775392025316457, -1.411972586206897 ], [ 29.775392025316457, -1.411433620689655 ], [ 29.775122531645568, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4437, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.411433620689655 ], [ 29.775931012658226, -1.411972586206897 ], [ 29.776200506329115, -1.411972586206897 ], [ 29.776200506329115, -1.411433620689655 ], [ 29.775931012658226, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4438, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.411433620689655 ], [ 29.77647, -1.411972586206897 ], [ 29.776739493670885, -1.411972586206897 ], [ 29.776739493670885, -1.411433620689655 ], [ 29.77647, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4439, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.411433620689655 ], [ 29.777278481012658, -1.411972586206897 ], [ 29.777547974683543, -1.411972586206897 ], [ 29.777547974683543, -1.411433620689655 ], [ 29.777278481012658, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4440, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.411433620689655 ], [ 29.778086962025316, -1.411972586206897 ], [ 29.778356455696201, -1.411972586206897 ], [ 29.778356455696201, -1.411433620689655 ], [ 29.778086962025316, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4441, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.411433620689655 ], [ 29.77862594936709, -1.411703103448276 ], [ 29.778895443037975, -1.411703103448276 ], [ 29.778895443037975, -1.411433620689655 ], [ 29.77862594936709, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4442, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.411433620689655 ], [ 29.778895443037975, -1.411972586206897 ], [ 29.77916493670886, -1.411972586206897 ], [ 29.77916493670886, -1.411433620689655 ], [ 29.778895443037975, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4443, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.411433620689655 ], [ 29.779434430379748, -1.411703103448276 ], [ 29.779703924050633, -1.411703103448276 ], [ 29.779703924050633, -1.411433620689655 ], [ 29.779434430379748, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4444, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.411433620689655 ], [ 29.779703924050633, -1.411703103448276 ], [ 29.779973417721518, -1.411703103448276 ], [ 29.779973417721518, -1.411433620689655 ], [ 29.779703924050633, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4445, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.411433620689655 ], [ 29.780242911392406, -1.411703103448276 ], [ 29.780512405063291, -1.411703103448276 ], [ 29.780512405063291, -1.411433620689655 ], [ 29.780242911392406, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4446, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.411433620689655 ], [ 29.780512405063291, -1.411703103448276 ], [ 29.780781898734176, -1.411703103448276 ], [ 29.780781898734176, -1.411433620689655 ], [ 29.780512405063291, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4447, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.411433620689655 ], [ 29.780781898734176, -1.411703103448276 ], [ 29.781051392405065, -1.411703103448276 ], [ 29.781051392405065, -1.411433620689655 ], [ 29.780781898734176, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4448, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.411433620689655 ], [ 29.781051392405065, -1.411703103448276 ], [ 29.78132088607595, -1.411703103448276 ], [ 29.78132088607595, -1.411433620689655 ], [ 29.781051392405065, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4449, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.411433620689655 ], [ 29.78132088607595, -1.411703103448276 ], [ 29.781590379746834, -1.411703103448276 ], [ 29.781590379746834, -1.411433620689655 ], [ 29.78132088607595, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4450, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.411433620689655 ], [ 29.781590379746834, -1.411972586206897 ], [ 29.781859873417723, -1.411972586206897 ], [ 29.781859873417723, -1.411433620689655 ], [ 29.781590379746834, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4451, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.411433620689655 ], [ 29.781859873417723, -1.411972586206897 ], [ 29.782129367088608, -1.411972586206897 ], [ 29.782129367088608, -1.411433620689655 ], [ 29.781859873417723, -1.411433620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4452, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.411703103448276 ], [ 29.756796962025316, -1.411972586206897 ], [ 29.757066455696201, -1.411972586206897 ], [ 29.757066455696201, -1.411703103448276 ], [ 29.756796962025316, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4453, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.411703103448276 ], [ 29.757066455696201, -1.411972586206897 ], [ 29.757335949367089, -1.411972586206897 ], [ 29.757335949367089, -1.411703103448276 ], [ 29.757066455696201, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4454, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.411703103448276 ], [ 29.757335949367089, -1.411972586206897 ], [ 29.757605443037974, -1.411972586206897 ], [ 29.757605443037974, -1.411703103448276 ], [ 29.757335949367089, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4455, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.411703103448276 ], [ 29.765420759493672, -1.411972586206897 ], [ 29.765959746835442, -1.411972586206897 ], [ 29.765959746835442, -1.411703103448276 ], [ 29.765420759493672, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4456, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.411703103448276 ], [ 29.765959746835442, -1.411972586206897 ], [ 29.766229240506327, -1.411972586206897 ], [ 29.766229240506327, -1.411703103448276 ], [ 29.765959746835442, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4457, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.411703103448276 ], [ 29.766229240506327, -1.411972586206897 ], [ 29.766498734177215, -1.411972586206897 ], [ 29.766498734177215, -1.411703103448276 ], [ 29.766229240506327, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4458, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.411703103448276 ], [ 29.766498734177215, -1.411972586206897 ], [ 29.7667682278481, -1.411972586206897 ], [ 29.7667682278481, -1.411703103448276 ], [ 29.766498734177215, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4459, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.411703103448276 ], [ 29.7667682278481, -1.411972586206897 ], [ 29.767037721518985, -1.411972586206897 ], [ 29.767037721518985, -1.411703103448276 ], [ 29.7667682278481, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4460, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.411703103448276 ], [ 29.767037721518985, -1.411972586206897 ], [ 29.767307215189874, -1.411972586206897 ], [ 29.767307215189874, -1.411703103448276 ], [ 29.767037721518985, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4461, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.411703103448276 ], [ 29.767307215189874, -1.411972586206897 ], [ 29.767576708860759, -1.411972586206897 ], [ 29.767576708860759, -1.411703103448276 ], [ 29.767307215189874, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4462, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.411703103448276 ], [ 29.767576708860759, -1.411972586206897 ], [ 29.767846202531643, -1.411972586206897 ], [ 29.767846202531643, -1.411703103448276 ], [ 29.767576708860759, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4463, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.411703103448276 ], [ 29.767846202531643, -1.411972586206897 ], [ 29.768115696202532, -1.411972586206897 ], [ 29.768115696202532, -1.411703103448276 ], [ 29.767846202531643, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4464, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.411703103448276 ], [ 29.768115696202532, -1.411972586206897 ], [ 29.768385189873417, -1.411972586206897 ], [ 29.768385189873417, -1.411703103448276 ], [ 29.768115696202532, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4465, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.411703103448276 ], [ 29.768385189873417, -1.412242068965517 ], [ 29.768654683544302, -1.412242068965517 ], [ 29.768654683544302, -1.411703103448276 ], [ 29.768385189873417, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4466, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.411703103448276 ], [ 29.768654683544302, -1.411972586206897 ], [ 29.76892417721519, -1.411972586206897 ], [ 29.76892417721519, -1.411703103448276 ], [ 29.768654683544302, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4467, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.411703103448276 ], [ 29.76892417721519, -1.411972586206897 ], [ 29.769193670886075, -1.411972586206897 ], [ 29.769193670886075, -1.411703103448276 ], [ 29.76892417721519, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4468, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.411703103448276 ], [ 29.769193670886075, -1.411972586206897 ], [ 29.76946316455696, -1.411972586206897 ], [ 29.76946316455696, -1.411703103448276 ], [ 29.769193670886075, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4469, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.411703103448276 ], [ 29.769732658227849, -1.411972586206897 ], [ 29.770002151898733, -1.411972586206897 ], [ 29.770002151898733, -1.411703103448276 ], [ 29.769732658227849, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4470, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.411703103448276 ], [ 29.770002151898733, -1.411972586206897 ], [ 29.770271645569618, -1.411972586206897 ], [ 29.770271645569618, -1.411703103448276 ], [ 29.770002151898733, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4471, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.411703103448276 ], [ 29.770271645569618, -1.412242068965517 ], [ 29.770541139240507, -1.412242068965517 ], [ 29.770541139240507, -1.411703103448276 ], [ 29.770271645569618, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4472, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.411703103448276 ], [ 29.770541139240507, -1.411972586206897 ], [ 29.770810632911392, -1.411972586206897 ], [ 29.770810632911392, -1.411703103448276 ], [ 29.770541139240507, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4473, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.411703103448276 ], [ 29.770810632911392, -1.411972586206897 ], [ 29.771080126582277, -1.411972586206897 ], [ 29.771080126582277, -1.411703103448276 ], [ 29.770810632911392, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4474, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.411703103448276 ], [ 29.771080126582277, -1.412242068965517 ], [ 29.771349620253165, -1.412242068965517 ], [ 29.771349620253165, -1.411703103448276 ], [ 29.771080126582277, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4475, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.411703103448276 ], [ 29.77161911392405, -1.411972586206897 ], [ 29.771888607594935, -1.411972586206897 ], [ 29.771888607594935, -1.411703103448276 ], [ 29.77161911392405, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4476, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.411703103448276 ], [ 29.771888607594935, -1.412242068965517 ], [ 29.772158101265823, -1.412242068965517 ], [ 29.772158101265823, -1.411703103448276 ], [ 29.771888607594935, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4477, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.411703103448276 ], [ 29.772427594936708, -1.411972586206897 ], [ 29.772697088607593, -1.411972586206897 ], [ 29.772697088607593, -1.411703103448276 ], [ 29.772427594936708, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4478, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.411703103448276 ], [ 29.772697088607593, -1.411972586206897 ], [ 29.772966582278482, -1.411972586206897 ], [ 29.772966582278482, -1.411703103448276 ], [ 29.772697088607593, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4479, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.411703103448276 ], [ 29.772966582278482, -1.412242068965517 ], [ 29.773236075949367, -1.412242068965517 ], [ 29.773236075949367, -1.411703103448276 ], [ 29.772966582278482, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4480, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.411703103448276 ], [ 29.77377506329114, -1.412242068965517 ], [ 29.774044556962025, -1.412242068965517 ], [ 29.774044556962025, -1.411703103448276 ], [ 29.77377506329114, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4481, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.411703103448276 ], [ 29.77431405063291, -1.412242068965517 ], [ 29.774853037974683, -1.412242068965517 ], [ 29.774853037974683, -1.411703103448276 ], [ 29.77431405063291, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4482, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.411703103448276 ], [ 29.774853037974683, -1.412242068965517 ], [ 29.775122531645568, -1.412242068965517 ], [ 29.775122531645568, -1.411703103448276 ], [ 29.774853037974683, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4483, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.411703103448276 ], [ 29.775392025316457, -1.412242068965517 ], [ 29.775661518987341, -1.412242068965517 ], [ 29.775661518987341, -1.411703103448276 ], [ 29.775392025316457, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4484, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.411703103448276 ], [ 29.776200506329115, -1.412242068965517 ], [ 29.77647, -1.412242068965517 ], [ 29.77647, -1.411703103448276 ], [ 29.776200506329115, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4485, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.411703103448276 ], [ 29.776739493670885, -1.411972586206897 ], [ 29.777008987341773, -1.411972586206897 ], [ 29.777008987341773, -1.411703103448276 ], [ 29.776739493670885, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4486, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.411703103448276 ], [ 29.777008987341773, -1.412242068965517 ], [ 29.777278481012658, -1.412242068965517 ], [ 29.777278481012658, -1.411703103448276 ], [ 29.777008987341773, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4487, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.411703103448276 ], [ 29.777547974683543, -1.411972586206897 ], [ 29.777817468354431, -1.411972586206897 ], [ 29.777817468354431, -1.411703103448276 ], [ 29.777547974683543, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4488, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.411703103448276 ], [ 29.777817468354431, -1.411972586206897 ], [ 29.778086962025316, -1.411972586206897 ], [ 29.778086962025316, -1.411703103448276 ], [ 29.777817468354431, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4489, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.411703103448276 ], [ 29.778356455696201, -1.411972586206897 ], [ 29.77862594936709, -1.411972586206897 ], [ 29.77862594936709, -1.411703103448276 ], [ 29.778356455696201, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4490, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.411703103448276 ], [ 29.77862594936709, -1.411972586206897 ], [ 29.778895443037975, -1.411972586206897 ], [ 29.778895443037975, -1.411703103448276 ], [ 29.77862594936709, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4491, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.411703103448276 ], [ 29.77916493670886, -1.411972586206897 ], [ 29.779434430379748, -1.411972586206897 ], [ 29.779434430379748, -1.411703103448276 ], [ 29.77916493670886, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4492, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.411703103448276 ], [ 29.779434430379748, -1.411972586206897 ], [ 29.779703924050633, -1.411972586206897 ], [ 29.779703924050633, -1.411703103448276 ], [ 29.779434430379748, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4493, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.411703103448276 ], [ 29.779703924050633, -1.411972586206897 ], [ 29.779973417721518, -1.411972586206897 ], [ 29.779973417721518, -1.411703103448276 ], [ 29.779703924050633, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4494, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.411703103448276 ], [ 29.779973417721518, -1.411972586206897 ], [ 29.780242911392406, -1.411972586206897 ], [ 29.780242911392406, -1.411703103448276 ], [ 29.779973417721518, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4495, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.411703103448276 ], [ 29.780242911392406, -1.411972586206897 ], [ 29.780512405063291, -1.411972586206897 ], [ 29.780512405063291, -1.411703103448276 ], [ 29.780242911392406, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4496, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.411703103448276 ], [ 29.780512405063291, -1.411972586206897 ], [ 29.780781898734176, -1.411972586206897 ], [ 29.780781898734176, -1.411703103448276 ], [ 29.780512405063291, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4497, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.411703103448276 ], [ 29.780781898734176, -1.411972586206897 ], [ 29.781051392405065, -1.411972586206897 ], [ 29.781051392405065, -1.411703103448276 ], [ 29.780781898734176, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4498, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.411703103448276 ], [ 29.781051392405065, -1.411972586206897 ], [ 29.78132088607595, -1.411972586206897 ], [ 29.78132088607595, -1.411703103448276 ], [ 29.781051392405065, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4499, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.411703103448276 ], [ 29.78132088607595, -1.412242068965517 ], [ 29.781590379746834, -1.412242068965517 ], [ 29.781590379746834, -1.411703103448276 ], [ 29.78132088607595, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4500, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.411703103448276 ], [ 29.782129367088608, -1.411972586206897 ], [ 29.782398860759493, -1.411972586206897 ], [ 29.782398860759493, -1.411703103448276 ], [ 29.782129367088608, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4501, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.411703103448276 ], [ 29.782398860759493, -1.411972586206897 ], [ 29.782668354430381, -1.411972586206897 ], [ 29.782668354430381, -1.411703103448276 ], [ 29.782398860759493, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4502, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.411703103448276 ], [ 29.782668354430381, -1.411972586206897 ], [ 29.782937848101266, -1.411972586206897 ], [ 29.782937848101266, -1.411703103448276 ], [ 29.782668354430381, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4503, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.411703103448276 ], [ 29.782937848101266, -1.411972586206897 ], [ 29.783207341772151, -1.411972586206897 ], [ 29.783207341772151, -1.411703103448276 ], [ 29.782937848101266, -1.411703103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4504, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.411972586206897 ], [ 29.756257974683542, -1.412511551724138 ], [ 29.756796962025316, -1.412511551724138 ], [ 29.756796962025316, -1.411972586206897 ], [ 29.756257974683542, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4505, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.411972586206897 ], [ 29.756796962025316, -1.412242068965517 ], [ 29.757066455696201, -1.412242068965517 ], [ 29.757066455696201, -1.411972586206897 ], [ 29.756796962025316, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4506, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.411972586206897 ], [ 29.757066455696201, -1.412242068965517 ], [ 29.757335949367089, -1.412242068965517 ], [ 29.757335949367089, -1.411972586206897 ], [ 29.757066455696201, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4507, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.411972586206897 ], [ 29.757335949367089, -1.412242068965517 ], [ 29.757605443037974, -1.412242068965517 ], [ 29.757605443037974, -1.411972586206897 ], [ 29.757335949367089, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4508, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.411972586206897 ], [ 29.757605443037974, -1.412242068965517 ], [ 29.758413924050632, -1.412242068965517 ], [ 29.758413924050632, -1.411972586206897 ], [ 29.757605443037974, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4509, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.411972586206897 ], [ 29.76407329113924, -1.412242068965517 ], [ 29.764342784810125, -1.412242068965517 ], [ 29.764342784810125, -1.411972586206897 ], [ 29.76407329113924, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4510, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.411972586206897 ], [ 29.764342784810125, -1.412242068965517 ], [ 29.764612278481014, -1.412242068965517 ], [ 29.764612278481014, -1.411972586206897 ], [ 29.764342784810125, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4511, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.411972586206897 ], [ 29.764881772151899, -1.412242068965517 ], [ 29.765151265822784, -1.412242068965517 ], [ 29.765151265822784, -1.411972586206897 ], [ 29.764881772151899, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4512, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.411972586206897 ], [ 29.765151265822784, -1.412242068965517 ], [ 29.765420759493672, -1.412242068965517 ], [ 29.765420759493672, -1.411972586206897 ], [ 29.765151265822784, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4513, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.411972586206897 ], [ 29.765420759493672, -1.412242068965517 ], [ 29.765959746835442, -1.412242068965517 ], [ 29.765959746835442, -1.411972586206897 ], [ 29.765420759493672, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4514, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.411972586206897 ], [ 29.765959746835442, -1.412242068965517 ], [ 29.766229240506327, -1.412242068965517 ], [ 29.766229240506327, -1.411972586206897 ], [ 29.765959746835442, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4515, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.411972586206897 ], [ 29.766229240506327, -1.412242068965517 ], [ 29.766498734177215, -1.412242068965517 ], [ 29.766498734177215, -1.411972586206897 ], [ 29.766229240506327, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4516, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.411972586206897 ], [ 29.766498734177215, -1.412242068965517 ], [ 29.7667682278481, -1.412242068965517 ], [ 29.7667682278481, -1.411972586206897 ], [ 29.766498734177215, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4517, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.411972586206897 ], [ 29.7667682278481, -1.412242068965517 ], [ 29.767037721518985, -1.412242068965517 ], [ 29.767037721518985, -1.411972586206897 ], [ 29.7667682278481, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4518, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.411972586206897 ], [ 29.767037721518985, -1.412242068965517 ], [ 29.767307215189874, -1.412242068965517 ], [ 29.767307215189874, -1.411972586206897 ], [ 29.767037721518985, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4519, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.411972586206897 ], [ 29.767307215189874, -1.412511551724138 ], [ 29.767576708860759, -1.412511551724138 ], [ 29.767576708860759, -1.411972586206897 ], [ 29.767307215189874, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4520, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.411972586206897 ], [ 29.767576708860759, -1.412242068965517 ], [ 29.767846202531643, -1.412242068965517 ], [ 29.767846202531643, -1.411972586206897 ], [ 29.767576708860759, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4521, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.411972586206897 ], [ 29.767846202531643, -1.412242068965517 ], [ 29.768115696202532, -1.412242068965517 ], [ 29.768115696202532, -1.411972586206897 ], [ 29.767846202531643, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4522, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.411972586206897 ], [ 29.768115696202532, -1.412242068965517 ], [ 29.768385189873417, -1.412242068965517 ], [ 29.768385189873417, -1.411972586206897 ], [ 29.768115696202532, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4523, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.411972586206897 ], [ 29.768654683544302, -1.412242068965517 ], [ 29.76892417721519, -1.412242068965517 ], [ 29.76892417721519, -1.411972586206897 ], [ 29.768654683544302, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4524, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.411972586206897 ], [ 29.76892417721519, -1.412242068965517 ], [ 29.769193670886075, -1.412242068965517 ], [ 29.769193670886075, -1.411972586206897 ], [ 29.76892417721519, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4525, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.411972586206897 ], [ 29.769193670886075, -1.412511551724138 ], [ 29.76946316455696, -1.412511551724138 ], [ 29.76946316455696, -1.411972586206897 ], [ 29.769193670886075, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4526, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.411972586206897 ], [ 29.76946316455696, -1.412242068965517 ], [ 29.769732658227849, -1.412242068965517 ], [ 29.769732658227849, -1.411972586206897 ], [ 29.76946316455696, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4527, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.411972586206897 ], [ 29.769732658227849, -1.412242068965517 ], [ 29.770002151898733, -1.412242068965517 ], [ 29.770002151898733, -1.411972586206897 ], [ 29.769732658227849, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4528, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.411972586206897 ], [ 29.770002151898733, -1.412511551724138 ], [ 29.770271645569618, -1.412511551724138 ], [ 29.770271645569618, -1.411972586206897 ], [ 29.770002151898733, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4529, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.411972586206897 ], [ 29.770541139240507, -1.412242068965517 ], [ 29.770810632911392, -1.412242068965517 ], [ 29.770810632911392, -1.411972586206897 ], [ 29.770541139240507, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4530, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.411972586206897 ], [ 29.770810632911392, -1.412511551724138 ], [ 29.771080126582277, -1.412511551724138 ], [ 29.771080126582277, -1.411972586206897 ], [ 29.770810632911392, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4531, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.411972586206897 ], [ 29.771349620253165, -1.412242068965517 ], [ 29.77161911392405, -1.412242068965517 ], [ 29.77161911392405, -1.411972586206897 ], [ 29.771349620253165, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4532, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.411972586206897 ], [ 29.77161911392405, -1.412511551724138 ], [ 29.771888607594935, -1.412511551724138 ], [ 29.771888607594935, -1.411972586206897 ], [ 29.77161911392405, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4533, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.411972586206897 ], [ 29.772158101265823, -1.412511551724138 ], [ 29.772427594936708, -1.412511551724138 ], [ 29.772427594936708, -1.411972586206897 ], [ 29.772158101265823, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4534, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.411972586206897 ], [ 29.772427594936708, -1.412781034482759 ], [ 29.772697088607593, -1.412781034482759 ], [ 29.772697088607593, -1.411972586206897 ], [ 29.772427594936708, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4535, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.411972586206897 ], [ 29.772697088607593, -1.412511551724138 ], [ 29.772966582278482, -1.412511551724138 ], [ 29.772966582278482, -1.411972586206897 ], [ 29.772697088607593, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4536, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.411972586206897 ], [ 29.773236075949367, -1.412511551724138 ], [ 29.773505569620252, -1.412511551724138 ], [ 29.773505569620252, -1.411972586206897 ], [ 29.773236075949367, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4537, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.411972586206897 ], [ 29.774044556962025, -1.412511551724138 ], [ 29.77431405063291, -1.412511551724138 ], [ 29.77431405063291, -1.411972586206897 ], [ 29.774044556962025, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4538, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.411972586206897 ], [ 29.775122531645568, -1.412511551724138 ], [ 29.775392025316457, -1.412511551724138 ], [ 29.775392025316457, -1.411972586206897 ], [ 29.775122531645568, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4539, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.411972586206897 ], [ 29.775661518987341, -1.412242068965517 ], [ 29.775931012658226, -1.412242068965517 ], [ 29.775931012658226, -1.411972586206897 ], [ 29.775661518987341, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4540, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.411972586206897 ], [ 29.775931012658226, -1.412511551724138 ], [ 29.776200506329115, -1.412511551724138 ], [ 29.776200506329115, -1.411972586206897 ], [ 29.775931012658226, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4541, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.411972586206897 ], [ 29.77647, -1.412242068965517 ], [ 29.776739493670885, -1.412242068965517 ], [ 29.776739493670885, -1.411972586206897 ], [ 29.77647, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4542, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.411972586206897 ], [ 29.776739493670885, -1.412242068965517 ], [ 29.777008987341773, -1.412242068965517 ], [ 29.777008987341773, -1.411972586206897 ], [ 29.776739493670885, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4543, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.411972586206897 ], [ 29.777278481012658, -1.412242068965517 ], [ 29.777547974683543, -1.412242068965517 ], [ 29.777547974683543, -1.411972586206897 ], [ 29.777278481012658, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4544, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.411972586206897 ], [ 29.777547974683543, -1.412242068965517 ], [ 29.777817468354431, -1.412242068965517 ], [ 29.777817468354431, -1.411972586206897 ], [ 29.777547974683543, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4545, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.411972586206897 ], [ 29.777817468354431, -1.412511551724138 ], [ 29.778086962025316, -1.412511551724138 ], [ 29.778086962025316, -1.411972586206897 ], [ 29.777817468354431, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4546, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.411972586206897 ], [ 29.778086962025316, -1.412242068965517 ], [ 29.778356455696201, -1.412242068965517 ], [ 29.778356455696201, -1.411972586206897 ], [ 29.778086962025316, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4547, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.411972586206897 ], [ 29.778356455696201, -1.412242068965517 ], [ 29.77862594936709, -1.412242068965517 ], [ 29.77862594936709, -1.411972586206897 ], [ 29.778356455696201, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4548, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.411972586206897 ], [ 29.77862594936709, -1.412242068965517 ], [ 29.778895443037975, -1.412242068965517 ], [ 29.778895443037975, -1.411972586206897 ], [ 29.77862594936709, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4549, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.411972586206897 ], [ 29.778895443037975, -1.412242068965517 ], [ 29.77916493670886, -1.412242068965517 ], [ 29.77916493670886, -1.411972586206897 ], [ 29.778895443037975, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4550, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.411972586206897 ], [ 29.77916493670886, -1.412242068965517 ], [ 29.779434430379748, -1.412242068965517 ], [ 29.779434430379748, -1.411972586206897 ], [ 29.77916493670886, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4551, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.411972586206897 ], [ 29.779434430379748, -1.412242068965517 ], [ 29.779703924050633, -1.412242068965517 ], [ 29.779703924050633, -1.411972586206897 ], [ 29.779434430379748, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4552, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.411972586206897 ], [ 29.779703924050633, -1.412242068965517 ], [ 29.779973417721518, -1.412242068965517 ], [ 29.779973417721518, -1.411972586206897 ], [ 29.779703924050633, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4553, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.411972586206897 ], [ 29.779973417721518, -1.412242068965517 ], [ 29.780242911392406, -1.412242068965517 ], [ 29.780242911392406, -1.411972586206897 ], [ 29.779973417721518, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4554, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.411972586206897 ], [ 29.780242911392406, -1.412511551724138 ], [ 29.780512405063291, -1.412511551724138 ], [ 29.780512405063291, -1.411972586206897 ], [ 29.780242911392406, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4555, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.411972586206897 ], [ 29.780512405063291, -1.412242068965517 ], [ 29.780781898734176, -1.412242068965517 ], [ 29.780781898734176, -1.411972586206897 ], [ 29.780512405063291, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4556, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.411972586206897 ], [ 29.780781898734176, -1.412242068965517 ], [ 29.781051392405065, -1.412242068965517 ], [ 29.781051392405065, -1.411972586206897 ], [ 29.780781898734176, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4557, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.411972586206897 ], [ 29.781051392405065, -1.412242068965517 ], [ 29.78132088607595, -1.412242068965517 ], [ 29.78132088607595, -1.411972586206897 ], [ 29.781051392405065, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4558, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.411972586206897 ], [ 29.781590379746834, -1.412242068965517 ], [ 29.781859873417723, -1.412242068965517 ], [ 29.781859873417723, -1.411972586206897 ], [ 29.781590379746834, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4559, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.411972586206897 ], [ 29.781859873417723, -1.412242068965517 ], [ 29.782129367088608, -1.412242068965517 ], [ 29.782129367088608, -1.411972586206897 ], [ 29.781859873417723, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4560, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.411972586206897 ], [ 29.782129367088608, -1.412242068965517 ], [ 29.782398860759493, -1.412242068965517 ], [ 29.782398860759493, -1.411972586206897 ], [ 29.782129367088608, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4561, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.411972586206897 ], [ 29.782398860759493, -1.412242068965517 ], [ 29.782668354430381, -1.412242068965517 ], [ 29.782668354430381, -1.411972586206897 ], [ 29.782398860759493, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4562, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.411972586206897 ], [ 29.782668354430381, -1.412242068965517 ], [ 29.782937848101266, -1.412242068965517 ], [ 29.782937848101266, -1.411972586206897 ], [ 29.782668354430381, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4563, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.411972586206897 ], [ 29.782937848101266, -1.412242068965517 ], [ 29.783207341772151, -1.412242068965517 ], [ 29.783207341772151, -1.411972586206897 ], [ 29.782937848101266, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4564, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.411972586206897 ], [ 29.783207341772151, -1.412242068965517 ], [ 29.783476835443039, -1.412242068965517 ], [ 29.783476835443039, -1.411972586206897 ], [ 29.783207341772151, -1.411972586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4565, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.412242068965517 ], [ 29.756796962025316, -1.412511551724138 ], [ 29.757066455696201, -1.412511551724138 ], [ 29.757066455696201, -1.412242068965517 ], [ 29.756796962025316, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4566, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.412242068965517 ], [ 29.757066455696201, -1.412511551724138 ], [ 29.757335949367089, -1.412511551724138 ], [ 29.757335949367089, -1.412242068965517 ], [ 29.757066455696201, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4567, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.412242068965517 ], [ 29.757335949367089, -1.412511551724138 ], [ 29.757605443037974, -1.412511551724138 ], [ 29.757605443037974, -1.412242068965517 ], [ 29.757335949367089, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4568, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.412242068965517 ], [ 29.757605443037974, -1.412511551724138 ], [ 29.758144430379748, -1.412511551724138 ], [ 29.758144430379748, -1.412242068965517 ], [ 29.757605443037974, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4569, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.412242068965517 ], [ 29.758144430379748, -1.412511551724138 ], [ 29.758413924050632, -1.412511551724138 ], [ 29.758413924050632, -1.412242068965517 ], [ 29.758144430379748, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4570, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.412242068965517 ], [ 29.758413924050632, -1.412511551724138 ], [ 29.759491898734176, -1.412511551724138 ], [ 29.759491898734176, -1.412242068965517 ], [ 29.758413924050632, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4571, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.412242068965517 ], [ 29.763534303797467, -1.412511551724138 ], [ 29.763803797468356, -1.412511551724138 ], [ 29.763803797468356, -1.412242068965517 ], [ 29.763534303797467, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4572, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.412242068965517 ], [ 29.763803797468356, -1.412511551724138 ], [ 29.76407329113924, -1.412511551724138 ], [ 29.76407329113924, -1.412242068965517 ], [ 29.763803797468356, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4573, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.412242068965517 ], [ 29.76407329113924, -1.412511551724138 ], [ 29.764342784810125, -1.412511551724138 ], [ 29.764342784810125, -1.412242068965517 ], [ 29.76407329113924, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4574, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.412242068965517 ], [ 29.764342784810125, -1.412511551724138 ], [ 29.764612278481014, -1.412511551724138 ], [ 29.764612278481014, -1.412242068965517 ], [ 29.764342784810125, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4575, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.412242068965517 ], [ 29.764612278481014, -1.412511551724138 ], [ 29.764881772151899, -1.412511551724138 ], [ 29.764881772151899, -1.412242068965517 ], [ 29.764612278481014, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4576, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.412242068965517 ], [ 29.764881772151899, -1.412511551724138 ], [ 29.765151265822784, -1.412511551724138 ], [ 29.765151265822784, -1.412242068965517 ], [ 29.764881772151899, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4577, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.412242068965517 ], [ 29.765151265822784, -1.412511551724138 ], [ 29.765420759493672, -1.412511551724138 ], [ 29.765420759493672, -1.412242068965517 ], [ 29.765151265822784, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4578, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.412242068965517 ], [ 29.765420759493672, -1.412511551724138 ], [ 29.765959746835442, -1.412511551724138 ], [ 29.765959746835442, -1.412242068965517 ], [ 29.765420759493672, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4579, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.412242068965517 ], [ 29.765959746835442, -1.412511551724138 ], [ 29.766229240506327, -1.412511551724138 ], [ 29.766229240506327, -1.412242068965517 ], [ 29.765959746835442, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4580, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.412242068965517 ], [ 29.766229240506327, -1.412511551724138 ], [ 29.766498734177215, -1.412511551724138 ], [ 29.766498734177215, -1.412242068965517 ], [ 29.766229240506327, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4581, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.412242068965517 ], [ 29.766498734177215, -1.412511551724138 ], [ 29.7667682278481, -1.412511551724138 ], [ 29.7667682278481, -1.412242068965517 ], [ 29.766498734177215, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4582, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.412242068965517 ], [ 29.7667682278481, -1.412511551724138 ], [ 29.767037721518985, -1.412511551724138 ], [ 29.767037721518985, -1.412242068965517 ], [ 29.7667682278481, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4583, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.412242068965517 ], [ 29.767037721518985, -1.412511551724138 ], [ 29.767307215189874, -1.412511551724138 ], [ 29.767307215189874, -1.412242068965517 ], [ 29.767037721518985, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4584, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.412242068965517 ], [ 29.767576708860759, -1.412511551724138 ], [ 29.767846202531643, -1.412511551724138 ], [ 29.767846202531643, -1.412242068965517 ], [ 29.767576708860759, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4585, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.412242068965517 ], [ 29.767846202531643, -1.412511551724138 ], [ 29.768115696202532, -1.412511551724138 ], [ 29.768115696202532, -1.412242068965517 ], [ 29.767846202531643, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4586, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.412242068965517 ], [ 29.768115696202532, -1.412781034482759 ], [ 29.768385189873417, -1.412781034482759 ], [ 29.768385189873417, -1.412242068965517 ], [ 29.768115696202532, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4587, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.412242068965517 ], [ 29.768385189873417, -1.412511551724138 ], [ 29.768654683544302, -1.412511551724138 ], [ 29.768654683544302, -1.412242068965517 ], [ 29.768385189873417, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4588, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.412242068965517 ], [ 29.768654683544302, -1.412511551724138 ], [ 29.76892417721519, -1.412511551724138 ], [ 29.76892417721519, -1.412242068965517 ], [ 29.768654683544302, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4589, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.412242068965517 ], [ 29.76892417721519, -1.412781034482759 ], [ 29.769193670886075, -1.412781034482759 ], [ 29.769193670886075, -1.412242068965517 ], [ 29.76892417721519, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4590, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.412242068965517 ], [ 29.76946316455696, -1.412511551724138 ], [ 29.769732658227849, -1.412511551724138 ], [ 29.769732658227849, -1.412242068965517 ], [ 29.76946316455696, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4591, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.412242068965517 ], [ 29.769732658227849, -1.412781034482759 ], [ 29.770002151898733, -1.412781034482759 ], [ 29.770002151898733, -1.412242068965517 ], [ 29.769732658227849, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4592, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.412242068965517 ], [ 29.770271645569618, -1.412781034482759 ], [ 29.770541139240507, -1.412781034482759 ], [ 29.770541139240507, -1.412242068965517 ], [ 29.770271645569618, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4593, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.412242068965517 ], [ 29.770541139240507, -1.412781034482759 ], [ 29.770810632911392, -1.412781034482759 ], [ 29.770810632911392, -1.412242068965517 ], [ 29.770541139240507, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4594, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.412242068965517 ], [ 29.771080126582277, -1.412781034482759 ], [ 29.771349620253165, -1.412781034482759 ], [ 29.771349620253165, -1.412242068965517 ], [ 29.771080126582277, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4595, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.412242068965517 ], [ 29.771349620253165, -1.413050517241379 ], [ 29.77161911392405, -1.413050517241379 ], [ 29.77161911392405, -1.412242068965517 ], [ 29.771349620253165, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4596, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.412242068965517 ], [ 29.771888607594935, -1.412781034482759 ], [ 29.772158101265823, -1.412781034482759 ], [ 29.772158101265823, -1.412242068965517 ], [ 29.771888607594935, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4597, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.412242068965517 ], [ 29.772966582278482, -1.412781034482759 ], [ 29.773236075949367, -1.412781034482759 ], [ 29.773236075949367, -1.412242068965517 ], [ 29.772966582278482, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4598, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.412242068965517 ], [ 29.773505569620252, -1.412511551724138 ], [ 29.77377506329114, -1.412511551724138 ], [ 29.77377506329114, -1.412242068965517 ], [ 29.773505569620252, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4599, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.412242068965517 ], [ 29.77377506329114, -1.412781034482759 ], [ 29.774044556962025, -1.412781034482759 ], [ 29.774044556962025, -1.412242068965517 ], [ 29.77377506329114, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4600, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.412242068965517 ], [ 29.77431405063291, -1.412511551724138 ], [ 29.774853037974683, -1.412511551724138 ], [ 29.774853037974683, -1.412242068965517 ], [ 29.77431405063291, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4601, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.412242068965517 ], [ 29.774853037974683, -1.412781034482759 ], [ 29.775122531645568, -1.412781034482759 ], [ 29.775122531645568, -1.412242068965517 ], [ 29.774853037974683, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4602, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.412242068965517 ], [ 29.775392025316457, -1.412511551724138 ], [ 29.775661518987341, -1.412511551724138 ], [ 29.775661518987341, -1.412242068965517 ], [ 29.775392025316457, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4603, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.412242068965517 ], [ 29.775661518987341, -1.412511551724138 ], [ 29.775931012658226, -1.412511551724138 ], [ 29.775931012658226, -1.412242068965517 ], [ 29.775661518987341, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4604, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.412242068965517 ], [ 29.776200506329115, -1.412511551724138 ], [ 29.77647, -1.412511551724138 ], [ 29.77647, -1.412242068965517 ], [ 29.776200506329115, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4605, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.412242068965517 ], [ 29.77647, -1.412511551724138 ], [ 29.776739493670885, -1.412511551724138 ], [ 29.776739493670885, -1.412242068965517 ], [ 29.77647, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4606, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.412242068965517 ], [ 29.776739493670885, -1.412781034482759 ], [ 29.777008987341773, -1.412781034482759 ], [ 29.777008987341773, -1.412242068965517 ], [ 29.776739493670885, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4607, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.412242068965517 ], [ 29.777008987341773, -1.412511551724138 ], [ 29.777278481012658, -1.412511551724138 ], [ 29.777278481012658, -1.412242068965517 ], [ 29.777008987341773, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4608, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.412242068965517 ], [ 29.777278481012658, -1.412511551724138 ], [ 29.777547974683543, -1.412511551724138 ], [ 29.777547974683543, -1.412242068965517 ], [ 29.777278481012658, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4609, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.412242068965517 ], [ 29.777547974683543, -1.412511551724138 ], [ 29.777817468354431, -1.412511551724138 ], [ 29.777817468354431, -1.412242068965517 ], [ 29.777547974683543, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4610, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.412242068965517 ], [ 29.778086962025316, -1.412511551724138 ], [ 29.778356455696201, -1.412511551724138 ], [ 29.778356455696201, -1.412242068965517 ], [ 29.778086962025316, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4611, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.412242068965517 ], [ 29.778356455696201, -1.412511551724138 ], [ 29.77862594936709, -1.412511551724138 ], [ 29.77862594936709, -1.412242068965517 ], [ 29.778356455696201, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4612, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.412242068965517 ], [ 29.77862594936709, -1.412511551724138 ], [ 29.778895443037975, -1.412511551724138 ], [ 29.778895443037975, -1.412242068965517 ], [ 29.77862594936709, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4613, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.412242068965517 ], [ 29.778895443037975, -1.412511551724138 ], [ 29.77916493670886, -1.412511551724138 ], [ 29.77916493670886, -1.412242068965517 ], [ 29.778895443037975, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4614, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.412242068965517 ], [ 29.77916493670886, -1.412781034482759 ], [ 29.779434430379748, -1.412781034482759 ], [ 29.779434430379748, -1.412242068965517 ], [ 29.77916493670886, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4615, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.412242068965517 ], [ 29.779434430379748, -1.412511551724138 ], [ 29.779703924050633, -1.412511551724138 ], [ 29.779703924050633, -1.412242068965517 ], [ 29.779434430379748, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4616, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.412242068965517 ], [ 29.779703924050633, -1.412511551724138 ], [ 29.779973417721518, -1.412511551724138 ], [ 29.779973417721518, -1.412242068965517 ], [ 29.779703924050633, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4617, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.412242068965517 ], [ 29.779973417721518, -1.412511551724138 ], [ 29.780242911392406, -1.412511551724138 ], [ 29.780242911392406, -1.412242068965517 ], [ 29.779973417721518, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4618, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.412242068965517 ], [ 29.780512405063291, -1.412511551724138 ], [ 29.780781898734176, -1.412511551724138 ], [ 29.780781898734176, -1.412242068965517 ], [ 29.780512405063291, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4619, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.412242068965517 ], [ 29.780781898734176, -1.412511551724138 ], [ 29.781051392405065, -1.412511551724138 ], [ 29.781051392405065, -1.412242068965517 ], [ 29.780781898734176, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4620, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.412242068965517 ], [ 29.781051392405065, -1.412511551724138 ], [ 29.78132088607595, -1.412511551724138 ], [ 29.78132088607595, -1.412242068965517 ], [ 29.781051392405065, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4621, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.412242068965517 ], [ 29.78132088607595, -1.412511551724138 ], [ 29.781590379746834, -1.412511551724138 ], [ 29.781590379746834, -1.412242068965517 ], [ 29.78132088607595, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4622, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.412242068965517 ], [ 29.781590379746834, -1.412511551724138 ], [ 29.781859873417723, -1.412511551724138 ], [ 29.781859873417723, -1.412242068965517 ], [ 29.781590379746834, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4623, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.412242068965517 ], [ 29.781859873417723, -1.412511551724138 ], [ 29.782129367088608, -1.412511551724138 ], [ 29.782129367088608, -1.412242068965517 ], [ 29.781859873417723, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4624, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.412242068965517 ], [ 29.782129367088608, -1.412511551724138 ], [ 29.782398860759493, -1.412511551724138 ], [ 29.782398860759493, -1.412242068965517 ], [ 29.782129367088608, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4625, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.412242068965517 ], [ 29.782398860759493, -1.412511551724138 ], [ 29.782668354430381, -1.412511551724138 ], [ 29.782668354430381, -1.412242068965517 ], [ 29.782398860759493, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4626, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.412242068965517 ], [ 29.782668354430381, -1.412511551724138 ], [ 29.782937848101266, -1.412511551724138 ], [ 29.782937848101266, -1.412242068965517 ], [ 29.782668354430381, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4627, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.412242068965517 ], [ 29.782937848101266, -1.412511551724138 ], [ 29.783207341772151, -1.412511551724138 ], [ 29.783207341772151, -1.412242068965517 ], [ 29.782937848101266, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4628, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.412242068965517 ], [ 29.783207341772151, -1.412511551724138 ], [ 29.783476835443039, -1.412511551724138 ], [ 29.783476835443039, -1.412242068965517 ], [ 29.783207341772151, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4629, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.412242068965517 ], [ 29.783476835443039, -1.412511551724138 ], [ 29.784015822784809, -1.412511551724138 ], [ 29.784015822784809, -1.412242068965517 ], [ 29.783476835443039, -1.412242068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4630, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.412511551724138 ], [ 29.756257974683542, -1.412781034482759 ], [ 29.756796962025316, -1.412781034482759 ], [ 29.756796962025316, -1.412511551724138 ], [ 29.756257974683542, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4631, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.412511551724138 ], [ 29.756796962025316, -1.412781034482759 ], [ 29.757066455696201, -1.412781034482759 ], [ 29.757066455696201, -1.412511551724138 ], [ 29.756796962025316, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4632, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.412511551724138 ], [ 29.757066455696201, -1.412781034482759 ], [ 29.757605443037974, -1.412781034482759 ], [ 29.757605443037974, -1.412511551724138 ], [ 29.757066455696201, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4633, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.412511551724138 ], [ 29.757605443037974, -1.412781034482759 ], [ 29.758144430379748, -1.412781034482759 ], [ 29.758144430379748, -1.412511551724138 ], [ 29.757605443037974, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4634, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.412511551724138 ], [ 29.758144430379748, -1.412781034482759 ], [ 29.758413924050632, -1.412781034482759 ], [ 29.758413924050632, -1.412511551724138 ], [ 29.758144430379748, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4635, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.412511551724138 ], [ 29.758413924050632, -1.412781034482759 ], [ 29.759222405063291, -1.412781034482759 ], [ 29.759222405063291, -1.412511551724138 ], [ 29.758413924050632, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4636, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.412511551724138 ], [ 29.759222405063291, -1.412781034482759 ], [ 29.759491898734176, -1.412781034482759 ], [ 29.759491898734176, -1.412511551724138 ], [ 29.759222405063291, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4637, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.412511551724138 ], [ 29.759491898734176, -1.412781034482759 ], [ 29.760030886075949, -1.412781034482759 ], [ 29.760030886075949, -1.412511551724138 ], [ 29.759491898734176, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4638, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.412511551724138 ], [ 29.762725822784809, -1.412781034482759 ], [ 29.762995316455697, -1.412781034482759 ], [ 29.762995316455697, -1.412511551724138 ], [ 29.762725822784809, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4639, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.412511551724138 ], [ 29.762995316455697, -1.412781034482759 ], [ 29.763264810126582, -1.412781034482759 ], [ 29.763264810126582, -1.412511551724138 ], [ 29.762995316455697, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4640, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.412511551724138 ], [ 29.763264810126582, -1.412781034482759 ], [ 29.763534303797467, -1.412781034482759 ], [ 29.763534303797467, -1.412511551724138 ], [ 29.763264810126582, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4641, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.412511551724138 ], [ 29.763534303797467, -1.412781034482759 ], [ 29.763803797468356, -1.412781034482759 ], [ 29.763803797468356, -1.412511551724138 ], [ 29.763534303797467, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4642, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.412511551724138 ], [ 29.763803797468356, -1.412781034482759 ], [ 29.76407329113924, -1.412781034482759 ], [ 29.76407329113924, -1.412511551724138 ], [ 29.763803797468356, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4643, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.412511551724138 ], [ 29.76407329113924, -1.412781034482759 ], [ 29.764342784810125, -1.412781034482759 ], [ 29.764342784810125, -1.412511551724138 ], [ 29.76407329113924, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4644, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.412511551724138 ], [ 29.764342784810125, -1.412781034482759 ], [ 29.764612278481014, -1.412781034482759 ], [ 29.764612278481014, -1.412511551724138 ], [ 29.764342784810125, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4645, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.412511551724138 ], [ 29.764612278481014, -1.412781034482759 ], [ 29.764881772151899, -1.412781034482759 ], [ 29.764881772151899, -1.412511551724138 ], [ 29.764612278481014, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4646, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.412511551724138 ], [ 29.764881772151899, -1.412781034482759 ], [ 29.765151265822784, -1.412781034482759 ], [ 29.765151265822784, -1.412511551724138 ], [ 29.764881772151899, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4647, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.412511551724138 ], [ 29.765151265822784, -1.412781034482759 ], [ 29.765420759493672, -1.412781034482759 ], [ 29.765420759493672, -1.412511551724138 ], [ 29.765151265822784, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4648, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.412511551724138 ], [ 29.765420759493672, -1.412781034482759 ], [ 29.765959746835442, -1.412781034482759 ], [ 29.765959746835442, -1.412511551724138 ], [ 29.765420759493672, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4649, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.412511551724138 ], [ 29.765959746835442, -1.412781034482759 ], [ 29.766229240506327, -1.412781034482759 ], [ 29.766229240506327, -1.412511551724138 ], [ 29.765959746835442, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4650, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.412511551724138 ], [ 29.766229240506327, -1.413050517241379 ], [ 29.766498734177215, -1.413050517241379 ], [ 29.766498734177215, -1.412511551724138 ], [ 29.766229240506327, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4651, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.412511551724138 ], [ 29.766498734177215, -1.412781034482759 ], [ 29.7667682278481, -1.412781034482759 ], [ 29.7667682278481, -1.412511551724138 ], [ 29.766498734177215, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4652, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.412511551724138 ], [ 29.7667682278481, -1.412781034482759 ], [ 29.767037721518985, -1.412781034482759 ], [ 29.767037721518985, -1.412511551724138 ], [ 29.7667682278481, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4653, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.412511551724138 ], [ 29.767037721518985, -1.413050517241379 ], [ 29.767307215189874, -1.413050517241379 ], [ 29.767307215189874, -1.412511551724138 ], [ 29.767037721518985, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4654, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.412511551724138 ], [ 29.767307215189874, -1.412781034482759 ], [ 29.767576708860759, -1.412781034482759 ], [ 29.767576708860759, -1.412511551724138 ], [ 29.767307215189874, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4655, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.412511551724138 ], [ 29.767576708860759, -1.412781034482759 ], [ 29.767846202531643, -1.412781034482759 ], [ 29.767846202531643, -1.412511551724138 ], [ 29.767576708860759, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4656, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.412511551724138 ], [ 29.767846202531643, -1.413050517241379 ], [ 29.768115696202532, -1.413050517241379 ], [ 29.768115696202532, -1.412511551724138 ], [ 29.767846202531643, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4657, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.412511551724138 ], [ 29.768385189873417, -1.412781034482759 ], [ 29.768654683544302, -1.412781034482759 ], [ 29.768654683544302, -1.412511551724138 ], [ 29.768385189873417, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4658, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.412511551724138 ], [ 29.768654683544302, -1.413050517241379 ], [ 29.76892417721519, -1.413050517241379 ], [ 29.76892417721519, -1.412511551724138 ], [ 29.768654683544302, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4659, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.412511551724138 ], [ 29.769193670886075, -1.413050517241379 ], [ 29.76946316455696, -1.413050517241379 ], [ 29.76946316455696, -1.412511551724138 ], [ 29.769193670886075, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4660, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.412511551724138 ], [ 29.76946316455696, -1.41332 ], [ 29.769732658227849, -1.41332 ], [ 29.769732658227849, -1.412511551724138 ], [ 29.76946316455696, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4661, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.412511551724138 ], [ 29.770002151898733, -1.413050517241379 ], [ 29.770271645569618, -1.413050517241379 ], [ 29.770271645569618, -1.412511551724138 ], [ 29.770002151898733, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4662, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.412511551724138 ], [ 29.770810632911392, -1.41332 ], [ 29.771080126582277, -1.41332 ], [ 29.771080126582277, -1.412511551724138 ], [ 29.770810632911392, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4663, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.412511551724138 ], [ 29.772158101265823, -1.41332 ], [ 29.772427594936708, -1.41332 ], [ 29.772427594936708, -1.412511551724138 ], [ 29.772158101265823, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4664, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.412511551724138 ], [ 29.772697088607593, -1.413050517241379 ], [ 29.772966582278482, -1.413050517241379 ], [ 29.772966582278482, -1.412511551724138 ], [ 29.772697088607593, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4665, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.412511551724138 ], [ 29.773236075949367, -1.412781034482759 ], [ 29.773505569620252, -1.412781034482759 ], [ 29.773505569620252, -1.412511551724138 ], [ 29.773236075949367, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4666, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.412511551724138 ], [ 29.773505569620252, -1.413050517241379 ], [ 29.77377506329114, -1.413050517241379 ], [ 29.77377506329114, -1.412511551724138 ], [ 29.773505569620252, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4667, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.412511551724138 ], [ 29.774044556962025, -1.412781034482759 ], [ 29.77431405063291, -1.412781034482759 ], [ 29.77431405063291, -1.412511551724138 ], [ 29.774044556962025, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4668, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.412511551724138 ], [ 29.77431405063291, -1.412781034482759 ], [ 29.774853037974683, -1.412781034482759 ], [ 29.774853037974683, -1.412511551724138 ], [ 29.77431405063291, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4669, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.412511551724138 ], [ 29.775122531645568, -1.412781034482759 ], [ 29.775392025316457, -1.412781034482759 ], [ 29.775392025316457, -1.412511551724138 ], [ 29.775122531645568, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4670, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.412511551724138 ], [ 29.775392025316457, -1.412781034482759 ], [ 29.775661518987341, -1.412781034482759 ], [ 29.775661518987341, -1.412511551724138 ], [ 29.775392025316457, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4671, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.412511551724138 ], [ 29.775661518987341, -1.413050517241379 ], [ 29.775931012658226, -1.413050517241379 ], [ 29.775931012658226, -1.412511551724138 ], [ 29.775661518987341, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4672, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.412511551724138 ], [ 29.775931012658226, -1.412781034482759 ], [ 29.776200506329115, -1.412781034482759 ], [ 29.776200506329115, -1.412511551724138 ], [ 29.775931012658226, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4673, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.412511551724138 ], [ 29.776200506329115, -1.412781034482759 ], [ 29.77647, -1.412781034482759 ], [ 29.77647, -1.412511551724138 ], [ 29.776200506329115, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4674, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.412511551724138 ], [ 29.77647, -1.412781034482759 ], [ 29.776739493670885, -1.412781034482759 ], [ 29.776739493670885, -1.412511551724138 ], [ 29.77647, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4675, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.412511551724138 ], [ 29.777008987341773, -1.412781034482759 ], [ 29.777278481012658, -1.412781034482759 ], [ 29.777278481012658, -1.412511551724138 ], [ 29.777008987341773, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4676, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.412511551724138 ], [ 29.777278481012658, -1.412781034482759 ], [ 29.777547974683543, -1.412781034482759 ], [ 29.777547974683543, -1.412511551724138 ], [ 29.777278481012658, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4677, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.412511551724138 ], [ 29.777547974683543, -1.412781034482759 ], [ 29.777817468354431, -1.412781034482759 ], [ 29.777817468354431, -1.412511551724138 ], [ 29.777547974683543, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4678, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.412511551724138 ], [ 29.777817468354431, -1.412781034482759 ], [ 29.778086962025316, -1.412781034482759 ], [ 29.778086962025316, -1.412511551724138 ], [ 29.777817468354431, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4679, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.412511551724138 ], [ 29.778086962025316, -1.412781034482759 ], [ 29.778356455696201, -1.412781034482759 ], [ 29.778356455696201, -1.412511551724138 ], [ 29.778086962025316, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4680, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.412511551724138 ], [ 29.778356455696201, -1.412781034482759 ], [ 29.77862594936709, -1.412781034482759 ], [ 29.77862594936709, -1.412511551724138 ], [ 29.778356455696201, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4681, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.412511551724138 ], [ 29.77862594936709, -1.412781034482759 ], [ 29.778895443037975, -1.412781034482759 ], [ 29.778895443037975, -1.412511551724138 ], [ 29.77862594936709, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4682, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.412511551724138 ], [ 29.778895443037975, -1.412781034482759 ], [ 29.77916493670886, -1.412781034482759 ], [ 29.77916493670886, -1.412511551724138 ], [ 29.778895443037975, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4683, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.412511551724138 ], [ 29.779434430379748, -1.412781034482759 ], [ 29.779703924050633, -1.412781034482759 ], [ 29.779703924050633, -1.412511551724138 ], [ 29.779434430379748, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4684, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.412511551724138 ], [ 29.779703924050633, -1.412781034482759 ], [ 29.779973417721518, -1.412781034482759 ], [ 29.779973417721518, -1.412511551724138 ], [ 29.779703924050633, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4685, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.412511551724138 ], [ 29.779973417721518, -1.412781034482759 ], [ 29.780242911392406, -1.412781034482759 ], [ 29.780242911392406, -1.412511551724138 ], [ 29.779973417721518, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4686, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.412511551724138 ], [ 29.780242911392406, -1.412781034482759 ], [ 29.780512405063291, -1.412781034482759 ], [ 29.780512405063291, -1.412511551724138 ], [ 29.780242911392406, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4687, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.412511551724138 ], [ 29.780512405063291, -1.412781034482759 ], [ 29.780781898734176, -1.412781034482759 ], [ 29.780781898734176, -1.412511551724138 ], [ 29.780512405063291, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4688, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.412511551724138 ], [ 29.780781898734176, -1.412781034482759 ], [ 29.781051392405065, -1.412781034482759 ], [ 29.781051392405065, -1.412511551724138 ], [ 29.780781898734176, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4689, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.412511551724138 ], [ 29.781051392405065, -1.412781034482759 ], [ 29.78132088607595, -1.412781034482759 ], [ 29.78132088607595, -1.412511551724138 ], [ 29.781051392405065, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4690, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.412511551724138 ], [ 29.78132088607595, -1.412781034482759 ], [ 29.781590379746834, -1.412781034482759 ], [ 29.781590379746834, -1.412511551724138 ], [ 29.78132088607595, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4691, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.412511551724138 ], [ 29.781590379746834, -1.412781034482759 ], [ 29.781859873417723, -1.412781034482759 ], [ 29.781859873417723, -1.412511551724138 ], [ 29.781590379746834, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4692, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.412511551724138 ], [ 29.781859873417723, -1.412781034482759 ], [ 29.782129367088608, -1.412781034482759 ], [ 29.782129367088608, -1.412511551724138 ], [ 29.781859873417723, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4693, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.412511551724138 ], [ 29.782129367088608, -1.412781034482759 ], [ 29.782398860759493, -1.412781034482759 ], [ 29.782398860759493, -1.412511551724138 ], [ 29.782129367088608, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4694, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.412511551724138 ], [ 29.782398860759493, -1.412781034482759 ], [ 29.782668354430381, -1.412781034482759 ], [ 29.782668354430381, -1.412511551724138 ], [ 29.782398860759493, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4695, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.412511551724138 ], [ 29.782668354430381, -1.412781034482759 ], [ 29.782937848101266, -1.412781034482759 ], [ 29.782937848101266, -1.412511551724138 ], [ 29.782668354430381, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4696, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.412511551724138 ], [ 29.782937848101266, -1.412781034482759 ], [ 29.783207341772151, -1.412781034482759 ], [ 29.783207341772151, -1.412511551724138 ], [ 29.782937848101266, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4697, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.412511551724138 ], [ 29.783207341772151, -1.412781034482759 ], [ 29.783476835443039, -1.412781034482759 ], [ 29.783476835443039, -1.412511551724138 ], [ 29.783207341772151, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4698, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.412511551724138 ], [ 29.783476835443039, -1.412781034482759 ], [ 29.784015822784809, -1.412781034482759 ], [ 29.784015822784809, -1.412511551724138 ], [ 29.783476835443039, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4699, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.412511551724138 ], [ 29.784015822784809, -1.412781034482759 ], [ 29.784285316455698, -1.412781034482759 ], [ 29.784285316455698, -1.412511551724138 ], [ 29.784015822784809, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4700, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.412781034482759 ], [ 29.757066455696201, -1.413050517241379 ], [ 29.757335949367089, -1.413050517241379 ], [ 29.757335949367089, -1.412781034482759 ], [ 29.757066455696201, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4701, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.412781034482759 ], [ 29.757335949367089, -1.413050517241379 ], [ 29.757874936708859, -1.413050517241379 ], [ 29.757874936708859, -1.412781034482759 ], [ 29.757335949367089, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4702, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.412781034482759 ], [ 29.757874936708859, -1.413050517241379 ], [ 29.758144430379748, -1.413050517241379 ], [ 29.758144430379748, -1.412781034482759 ], [ 29.757874936708859, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4703, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.412781034482759 ], [ 29.758144430379748, -1.413050517241379 ], [ 29.758413924050632, -1.413050517241379 ], [ 29.758413924050632, -1.412781034482759 ], [ 29.758144430379748, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4704, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.412781034482759 ], [ 29.758413924050632, -1.413050517241379 ], [ 29.759222405063291, -1.413050517241379 ], [ 29.759222405063291, -1.412781034482759 ], [ 29.758413924050632, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4705, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.412781034482759 ], [ 29.759222405063291, -1.413050517241379 ], [ 29.759491898734176, -1.413050517241379 ], [ 29.759491898734176, -1.412781034482759 ], [ 29.759222405063291, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4706, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.412781034482759 ], [ 29.759491898734176, -1.413050517241379 ], [ 29.759761392405064, -1.413050517241379 ], [ 29.759761392405064, -1.412781034482759 ], [ 29.759491898734176, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4707, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.412781034482759 ], [ 29.759761392405064, -1.413050517241379 ], [ 29.760030886075949, -1.413050517241379 ], [ 29.760030886075949, -1.412781034482759 ], [ 29.759761392405064, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4708, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.412781034482759 ], [ 29.760030886075949, -1.413050517241379 ], [ 29.760300379746834, -1.413050517241379 ], [ 29.760300379746834, -1.412781034482759 ], [ 29.760030886075949, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4709, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.412781034482759 ], [ 29.762186835443039, -1.413050517241379 ], [ 29.762456329113924, -1.413050517241379 ], [ 29.762456329113924, -1.412781034482759 ], [ 29.762186835443039, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4710, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.412781034482759 ], [ 29.762456329113924, -1.413050517241379 ], [ 29.762725822784809, -1.413050517241379 ], [ 29.762725822784809, -1.412781034482759 ], [ 29.762456329113924, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4711, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.412781034482759 ], [ 29.762725822784809, -1.413050517241379 ], [ 29.762995316455697, -1.413050517241379 ], [ 29.762995316455697, -1.412781034482759 ], [ 29.762725822784809, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4712, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.412781034482759 ], [ 29.762995316455697, -1.413050517241379 ], [ 29.763264810126582, -1.413050517241379 ], [ 29.763264810126582, -1.412781034482759 ], [ 29.762995316455697, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4713, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.412781034482759 ], [ 29.763264810126582, -1.413050517241379 ], [ 29.763534303797467, -1.413050517241379 ], [ 29.763534303797467, -1.412781034482759 ], [ 29.763264810126582, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4714, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.412781034482759 ], [ 29.763534303797467, -1.413050517241379 ], [ 29.763803797468356, -1.413050517241379 ], [ 29.763803797468356, -1.412781034482759 ], [ 29.763534303797467, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4715, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.412781034482759 ], [ 29.763803797468356, -1.413050517241379 ], [ 29.76407329113924, -1.413050517241379 ], [ 29.76407329113924, -1.412781034482759 ], [ 29.763803797468356, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4716, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.412781034482759 ], [ 29.76407329113924, -1.413050517241379 ], [ 29.764342784810125, -1.413050517241379 ], [ 29.764342784810125, -1.412781034482759 ], [ 29.76407329113924, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4717, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.412781034482759 ], [ 29.764342784810125, -1.413050517241379 ], [ 29.764612278481014, -1.413050517241379 ], [ 29.764612278481014, -1.412781034482759 ], [ 29.764342784810125, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4718, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.412781034482759 ], [ 29.764612278481014, -1.413050517241379 ], [ 29.764881772151899, -1.413050517241379 ], [ 29.764881772151899, -1.412781034482759 ], [ 29.764612278481014, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4719, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.412781034482759 ], [ 29.764881772151899, -1.41332 ], [ 29.765151265822784, -1.41332 ], [ 29.765151265822784, -1.412781034482759 ], [ 29.764881772151899, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4720, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.412781034482759 ], [ 29.765151265822784, -1.413050517241379 ], [ 29.765420759493672, -1.413050517241379 ], [ 29.765420759493672, -1.412781034482759 ], [ 29.765151265822784, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4721, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.412781034482759 ], [ 29.765420759493672, -1.413050517241379 ], [ 29.765959746835442, -1.413050517241379 ], [ 29.765959746835442, -1.412781034482759 ], [ 29.765420759493672, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4722, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.412781034482759 ], [ 29.765959746835442, -1.41332 ], [ 29.766229240506327, -1.41332 ], [ 29.766229240506327, -1.412781034482759 ], [ 29.765959746835442, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4723, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.412781034482759 ], [ 29.766498734177215, -1.413050517241379 ], [ 29.7667682278481, -1.413050517241379 ], [ 29.7667682278481, -1.412781034482759 ], [ 29.766498734177215, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4724, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.412781034482759 ], [ 29.7667682278481, -1.41332 ], [ 29.767037721518985, -1.41332 ], [ 29.767037721518985, -1.412781034482759 ], [ 29.7667682278481, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4725, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.412781034482759 ], [ 29.767307215189874, -1.41332 ], [ 29.767576708860759, -1.41332 ], [ 29.767576708860759, -1.412781034482759 ], [ 29.767307215189874, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4726, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.412781034482759 ], [ 29.768115696202532, -1.41332 ], [ 29.768385189873417, -1.41332 ], [ 29.768385189873417, -1.412781034482759 ], [ 29.768115696202532, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4727, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.412781034482759 ], [ 29.772966582278482, -1.413050517241379 ], [ 29.773236075949367, -1.413050517241379 ], [ 29.773236075949367, -1.412781034482759 ], [ 29.772966582278482, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4728, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.412781034482759 ], [ 29.773236075949367, -1.413050517241379 ], [ 29.773505569620252, -1.413050517241379 ], [ 29.773505569620252, -1.412781034482759 ], [ 29.773236075949367, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4729, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.412781034482759 ], [ 29.77377506329114, -1.413050517241379 ], [ 29.774044556962025, -1.413050517241379 ], [ 29.774044556962025, -1.412781034482759 ], [ 29.77377506329114, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4730, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.412781034482759 ], [ 29.774044556962025, -1.413050517241379 ], [ 29.77431405063291, -1.413050517241379 ], [ 29.77431405063291, -1.412781034482759 ], [ 29.774044556962025, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4731, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.412781034482759 ], [ 29.77431405063291, -1.41332 ], [ 29.774853037974683, -1.41332 ], [ 29.774853037974683, -1.412781034482759 ], [ 29.77431405063291, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4732, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.412781034482759 ], [ 29.774853037974683, -1.413050517241379 ], [ 29.775122531645568, -1.413050517241379 ], [ 29.775122531645568, -1.412781034482759 ], [ 29.774853037974683, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4733, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.412781034482759 ], [ 29.775122531645568, -1.413050517241379 ], [ 29.775392025316457, -1.413050517241379 ], [ 29.775392025316457, -1.412781034482759 ], [ 29.775122531645568, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4734, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.412781034482759 ], [ 29.775392025316457, -1.413050517241379 ], [ 29.775661518987341, -1.413050517241379 ], [ 29.775661518987341, -1.412781034482759 ], [ 29.775392025316457, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4735, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.412781034482759 ], [ 29.775931012658226, -1.413050517241379 ], [ 29.776200506329115, -1.413050517241379 ], [ 29.776200506329115, -1.412781034482759 ], [ 29.775931012658226, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4736, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.412781034482759 ], [ 29.776200506329115, -1.413050517241379 ], [ 29.77647, -1.413050517241379 ], [ 29.77647, -1.412781034482759 ], [ 29.776200506329115, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4737, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.412781034482759 ], [ 29.77647, -1.413050517241379 ], [ 29.776739493670885, -1.413050517241379 ], [ 29.776739493670885, -1.412781034482759 ], [ 29.77647, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4738, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.412781034482759 ], [ 29.776739493670885, -1.413050517241379 ], [ 29.777008987341773, -1.413050517241379 ], [ 29.777008987341773, -1.412781034482759 ], [ 29.776739493670885, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4739, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.412781034482759 ], [ 29.777008987341773, -1.413050517241379 ], [ 29.777278481012658, -1.413050517241379 ], [ 29.777278481012658, -1.412781034482759 ], [ 29.777008987341773, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4740, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.412781034482759 ], [ 29.777278481012658, -1.413050517241379 ], [ 29.777547974683543, -1.413050517241379 ], [ 29.777547974683543, -1.412781034482759 ], [ 29.777278481012658, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4741, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.412781034482759 ], [ 29.777547974683543, -1.413050517241379 ], [ 29.777817468354431, -1.413050517241379 ], [ 29.777817468354431, -1.412781034482759 ], [ 29.777547974683543, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4742, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.412781034482759 ], [ 29.777817468354431, -1.413050517241379 ], [ 29.778086962025316, -1.413050517241379 ], [ 29.778086962025316, -1.412781034482759 ], [ 29.777817468354431, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4743, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.412781034482759 ], [ 29.778086962025316, -1.41332 ], [ 29.778356455696201, -1.41332 ], [ 29.778356455696201, -1.412781034482759 ], [ 29.778086962025316, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4744, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.412781034482759 ], [ 29.778356455696201, -1.413050517241379 ], [ 29.77862594936709, -1.413050517241379 ], [ 29.77862594936709, -1.412781034482759 ], [ 29.778356455696201, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4745, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.412781034482759 ], [ 29.77862594936709, -1.413050517241379 ], [ 29.778895443037975, -1.413050517241379 ], [ 29.778895443037975, -1.412781034482759 ], [ 29.77862594936709, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4746, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.412781034482759 ], [ 29.778895443037975, -1.413050517241379 ], [ 29.77916493670886, -1.413050517241379 ], [ 29.77916493670886, -1.412781034482759 ], [ 29.778895443037975, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4747, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.412781034482759 ], [ 29.77916493670886, -1.413050517241379 ], [ 29.779434430379748, -1.413050517241379 ], [ 29.779434430379748, -1.412781034482759 ], [ 29.77916493670886, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4748, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.412781034482759 ], [ 29.779434430379748, -1.413050517241379 ], [ 29.779703924050633, -1.413050517241379 ], [ 29.779703924050633, -1.412781034482759 ], [ 29.779434430379748, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4749, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.412781034482759 ], [ 29.779703924050633, -1.413050517241379 ], [ 29.779973417721518, -1.413050517241379 ], [ 29.779973417721518, -1.412781034482759 ], [ 29.779703924050633, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4750, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.412781034482759 ], [ 29.779973417721518, -1.413050517241379 ], [ 29.780242911392406, -1.413050517241379 ], [ 29.780242911392406, -1.412781034482759 ], [ 29.779973417721518, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4751, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.412781034482759 ], [ 29.780242911392406, -1.413050517241379 ], [ 29.780512405063291, -1.413050517241379 ], [ 29.780512405063291, -1.412781034482759 ], [ 29.780242911392406, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4752, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.412781034482759 ], [ 29.780512405063291, -1.413050517241379 ], [ 29.780781898734176, -1.413050517241379 ], [ 29.780781898734176, -1.412781034482759 ], [ 29.780512405063291, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4753, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.412781034482759 ], [ 29.780781898734176, -1.413050517241379 ], [ 29.781051392405065, -1.413050517241379 ], [ 29.781051392405065, -1.412781034482759 ], [ 29.780781898734176, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4754, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.412781034482759 ], [ 29.781051392405065, -1.413050517241379 ], [ 29.78132088607595, -1.413050517241379 ], [ 29.78132088607595, -1.412781034482759 ], [ 29.781051392405065, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4755, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.412781034482759 ], [ 29.78132088607595, -1.413050517241379 ], [ 29.781590379746834, -1.413050517241379 ], [ 29.781590379746834, -1.412781034482759 ], [ 29.78132088607595, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4756, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.412781034482759 ], [ 29.781590379746834, -1.413050517241379 ], [ 29.781859873417723, -1.413050517241379 ], [ 29.781859873417723, -1.412781034482759 ], [ 29.781590379746834, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4757, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.412781034482759 ], [ 29.781859873417723, -1.413050517241379 ], [ 29.782129367088608, -1.413050517241379 ], [ 29.782129367088608, -1.412781034482759 ], [ 29.781859873417723, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4758, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.412781034482759 ], [ 29.782129367088608, -1.413050517241379 ], [ 29.782398860759493, -1.413050517241379 ], [ 29.782398860759493, -1.412781034482759 ], [ 29.782129367088608, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4759, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.412781034482759 ], [ 29.782398860759493, -1.41332 ], [ 29.782668354430381, -1.41332 ], [ 29.782668354430381, -1.412781034482759 ], [ 29.782398860759493, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4760, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.412781034482759 ], [ 29.782668354430381, -1.41332 ], [ 29.782937848101266, -1.41332 ], [ 29.782937848101266, -1.412781034482759 ], [ 29.782668354430381, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4761, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.412781034482759 ], [ 29.782937848101266, -1.41332 ], [ 29.783207341772151, -1.41332 ], [ 29.783207341772151, -1.412781034482759 ], [ 29.782937848101266, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4762, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.412781034482759 ], [ 29.783207341772151, -1.41332 ], [ 29.783476835443039, -1.41332 ], [ 29.783476835443039, -1.412781034482759 ], [ 29.783207341772151, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4763, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.412781034482759 ], [ 29.783476835443039, -1.41332 ], [ 29.784015822784809, -1.41332 ], [ 29.784015822784809, -1.412781034482759 ], [ 29.783476835443039, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4764, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.412781034482759 ], [ 29.784015822784809, -1.41332 ], [ 29.784285316455698, -1.41332 ], [ 29.784285316455698, -1.412781034482759 ], [ 29.784015822784809, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4765, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.412781034482759 ], [ 29.784285316455698, -1.41332 ], [ 29.784554810126583, -1.41332 ], [ 29.784554810126583, -1.412781034482759 ], [ 29.784285316455698, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4766, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.413050517241379 ], [ 29.757874936708859, -1.41332 ], [ 29.758144430379748, -1.41332 ], [ 29.758144430379748, -1.413050517241379 ], [ 29.757874936708859, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4767, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.413050517241379 ], [ 29.758144430379748, -1.41332 ], [ 29.758952911392406, -1.41332 ], [ 29.758952911392406, -1.413050517241379 ], [ 29.758144430379748, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4768, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.413050517241379 ], [ 29.758952911392406, -1.41332 ], [ 29.759222405063291, -1.41332 ], [ 29.759222405063291, -1.413050517241379 ], [ 29.758952911392406, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4769, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.413050517241379 ], [ 29.759222405063291, -1.41332 ], [ 29.759491898734176, -1.41332 ], [ 29.759491898734176, -1.413050517241379 ], [ 29.759222405063291, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4770, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.413050517241379 ], [ 29.759491898734176, -1.41332 ], [ 29.759761392405064, -1.41332 ], [ 29.759761392405064, -1.413050517241379 ], [ 29.759491898734176, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4771, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.413050517241379 ], [ 29.759761392405064, -1.41332 ], [ 29.760030886075949, -1.41332 ], [ 29.760030886075949, -1.413050517241379 ], [ 29.759761392405064, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4772, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.413050517241379 ], [ 29.760030886075949, -1.41332 ], [ 29.760300379746834, -1.41332 ], [ 29.760300379746834, -1.413050517241379 ], [ 29.760030886075949, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4773, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.413050517241379 ], [ 29.760300379746834, -1.41332 ], [ 29.760839367088607, -1.41332 ], [ 29.760839367088607, -1.413050517241379 ], [ 29.760300379746834, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4774, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.413050517241379 ], [ 29.762186835443039, -1.413589482758621 ], [ 29.762456329113924, -1.413589482758621 ], [ 29.762456329113924, -1.413050517241379 ], [ 29.762186835443039, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4775, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.413050517241379 ], [ 29.762456329113924, -1.41332 ], [ 29.762725822784809, -1.41332 ], [ 29.762725822784809, -1.413050517241379 ], [ 29.762456329113924, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4776, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.413050517241379 ], [ 29.762725822784809, -1.41332 ], [ 29.762995316455697, -1.41332 ], [ 29.762995316455697, -1.413050517241379 ], [ 29.762725822784809, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4777, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.413050517241379 ], [ 29.762995316455697, -1.41332 ], [ 29.763264810126582, -1.41332 ], [ 29.763264810126582, -1.413050517241379 ], [ 29.762995316455697, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4778, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.413050517241379 ], [ 29.763264810126582, -1.41332 ], [ 29.763534303797467, -1.41332 ], [ 29.763534303797467, -1.413050517241379 ], [ 29.763264810126582, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4779, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.413050517241379 ], [ 29.763534303797467, -1.41332 ], [ 29.763803797468356, -1.41332 ], [ 29.763803797468356, -1.413050517241379 ], [ 29.763534303797467, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4780, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.413050517241379 ], [ 29.763803797468356, -1.413589482758621 ], [ 29.76407329113924, -1.413589482758621 ], [ 29.76407329113924, -1.413050517241379 ], [ 29.763803797468356, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4781, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.413050517241379 ], [ 29.76407329113924, -1.41332 ], [ 29.764342784810125, -1.41332 ], [ 29.764342784810125, -1.413050517241379 ], [ 29.76407329113924, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4782, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.413050517241379 ], [ 29.764342784810125, -1.41332 ], [ 29.764612278481014, -1.41332 ], [ 29.764612278481014, -1.413050517241379 ], [ 29.764342784810125, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4783, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.413050517241379 ], [ 29.765151265822784, -1.413589482758621 ], [ 29.765420759493672, -1.413589482758621 ], [ 29.765420759493672, -1.413050517241379 ], [ 29.765151265822784, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4784, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.413050517241379 ], [ 29.772966582278482, -1.41332 ], [ 29.773236075949367, -1.41332 ], [ 29.773236075949367, -1.413050517241379 ], [ 29.772966582278482, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4785, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.413050517241379 ], [ 29.773236075949367, -1.413589482758621 ], [ 29.773505569620252, -1.413589482758621 ], [ 29.773505569620252, -1.413050517241379 ], [ 29.773236075949367, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4786, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.413050517241379 ], [ 29.773505569620252, -1.41332 ], [ 29.77377506329114, -1.41332 ], [ 29.77377506329114, -1.413050517241379 ], [ 29.773505569620252, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4787, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.413050517241379 ], [ 29.77377506329114, -1.41332 ], [ 29.774044556962025, -1.41332 ], [ 29.774044556962025, -1.413050517241379 ], [ 29.77377506329114, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4788, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.413050517241379 ], [ 29.774044556962025, -1.41332 ], [ 29.77431405063291, -1.41332 ], [ 29.77431405063291, -1.413050517241379 ], [ 29.774044556962025, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4789, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.413050517241379 ], [ 29.774853037974683, -1.41332 ], [ 29.775122531645568, -1.41332 ], [ 29.775122531645568, -1.413050517241379 ], [ 29.774853037974683, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4790, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.413050517241379 ], [ 29.775122531645568, -1.41332 ], [ 29.775392025316457, -1.41332 ], [ 29.775392025316457, -1.413050517241379 ], [ 29.775122531645568, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4791, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.413050517241379 ], [ 29.775392025316457, -1.41332 ], [ 29.775661518987341, -1.41332 ], [ 29.775661518987341, -1.413050517241379 ], [ 29.775392025316457, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4792, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.413050517241379 ], [ 29.775661518987341, -1.41332 ], [ 29.775931012658226, -1.41332 ], [ 29.775931012658226, -1.413050517241379 ], [ 29.775661518987341, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4793, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.413050517241379 ], [ 29.775931012658226, -1.41332 ], [ 29.776200506329115, -1.41332 ], [ 29.776200506329115, -1.413050517241379 ], [ 29.775931012658226, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4794, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.413050517241379 ], [ 29.776200506329115, -1.41332 ], [ 29.77647, -1.41332 ], [ 29.77647, -1.413050517241379 ], [ 29.776200506329115, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4795, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.413050517241379 ], [ 29.77647, -1.41332 ], [ 29.776739493670885, -1.41332 ], [ 29.776739493670885, -1.413050517241379 ], [ 29.77647, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4796, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.413050517241379 ], [ 29.776739493670885, -1.41332 ], [ 29.777008987341773, -1.41332 ], [ 29.777008987341773, -1.413050517241379 ], [ 29.776739493670885, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4797, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.413050517241379 ], [ 29.777008987341773, -1.413589482758621 ], [ 29.777278481012658, -1.413589482758621 ], [ 29.777278481012658, -1.413050517241379 ], [ 29.777008987341773, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4798, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.413050517241379 ], [ 29.777278481012658, -1.41332 ], [ 29.777547974683543, -1.41332 ], [ 29.777547974683543, -1.413050517241379 ], [ 29.777278481012658, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4799, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.413050517241379 ], [ 29.777547974683543, -1.41332 ], [ 29.777817468354431, -1.41332 ], [ 29.777817468354431, -1.413050517241379 ], [ 29.777547974683543, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4800, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.413050517241379 ], [ 29.777817468354431, -1.41332 ], [ 29.778086962025316, -1.41332 ], [ 29.778086962025316, -1.413050517241379 ], [ 29.777817468354431, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4801, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.413050517241379 ], [ 29.778356455696201, -1.41332 ], [ 29.77862594936709, -1.41332 ], [ 29.77862594936709, -1.413050517241379 ], [ 29.778356455696201, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4802, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.413050517241379 ], [ 29.77862594936709, -1.41332 ], [ 29.778895443037975, -1.41332 ], [ 29.778895443037975, -1.413050517241379 ], [ 29.77862594936709, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4803, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.413050517241379 ], [ 29.778895443037975, -1.41332 ], [ 29.77916493670886, -1.41332 ], [ 29.77916493670886, -1.413050517241379 ], [ 29.778895443037975, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4804, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.413050517241379 ], [ 29.77916493670886, -1.41332 ], [ 29.779434430379748, -1.41332 ], [ 29.779434430379748, -1.413050517241379 ], [ 29.77916493670886, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4805, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.413050517241379 ], [ 29.779434430379748, -1.41332 ], [ 29.779703924050633, -1.41332 ], [ 29.779703924050633, -1.413050517241379 ], [ 29.779434430379748, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4806, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.413050517241379 ], [ 29.779703924050633, -1.41332 ], [ 29.779973417721518, -1.41332 ], [ 29.779973417721518, -1.413050517241379 ], [ 29.779703924050633, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4807, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.413050517241379 ], [ 29.779973417721518, -1.41332 ], [ 29.780242911392406, -1.41332 ], [ 29.780242911392406, -1.413050517241379 ], [ 29.779973417721518, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4808, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.413050517241379 ], [ 29.780242911392406, -1.41332 ], [ 29.780512405063291, -1.41332 ], [ 29.780512405063291, -1.413050517241379 ], [ 29.780242911392406, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4809, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.413050517241379 ], [ 29.780512405063291, -1.41332 ], [ 29.780781898734176, -1.41332 ], [ 29.780781898734176, -1.413050517241379 ], [ 29.780512405063291, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4810, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.413050517241379 ], [ 29.780781898734176, -1.41332 ], [ 29.781051392405065, -1.41332 ], [ 29.781051392405065, -1.413050517241379 ], [ 29.780781898734176, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4811, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.413050517241379 ], [ 29.78132088607595, -1.41332 ], [ 29.781590379746834, -1.41332 ], [ 29.781590379746834, -1.413050517241379 ], [ 29.78132088607595, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4812, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.413050517241379 ], [ 29.781590379746834, -1.41332 ], [ 29.781859873417723, -1.41332 ], [ 29.781859873417723, -1.413050517241379 ], [ 29.781590379746834, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4813, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.413050517241379 ], [ 29.781859873417723, -1.41332 ], [ 29.782129367088608, -1.41332 ], [ 29.782129367088608, -1.413050517241379 ], [ 29.781859873417723, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4814, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.413050517241379 ], [ 29.782129367088608, -1.41332 ], [ 29.782398860759493, -1.41332 ], [ 29.782398860759493, -1.413050517241379 ], [ 29.782129367088608, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4815, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.41332 ], [ 29.757874936708859, -1.413589482758621 ], [ 29.758144430379748, -1.413589482758621 ], [ 29.758144430379748, -1.41332 ], [ 29.757874936708859, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4816, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.41332 ], [ 29.758144430379748, -1.413589482758621 ], [ 29.758952911392406, -1.413589482758621 ], [ 29.758952911392406, -1.41332 ], [ 29.758144430379748, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4817, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.41332 ], [ 29.758952911392406, -1.413589482758621 ], [ 29.759222405063291, -1.413589482758621 ], [ 29.759222405063291, -1.41332 ], [ 29.758952911392406, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4818, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.41332 ], [ 29.759222405063291, -1.413589482758621 ], [ 29.759491898734176, -1.413589482758621 ], [ 29.759491898734176, -1.41332 ], [ 29.759222405063291, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4819, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.41332 ], [ 29.759491898734176, -1.413589482758621 ], [ 29.759761392405064, -1.413589482758621 ], [ 29.759761392405064, -1.41332 ], [ 29.759491898734176, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4820, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.41332 ], [ 29.759761392405064, -1.413589482758621 ], [ 29.760030886075949, -1.413589482758621 ], [ 29.760030886075949, -1.41332 ], [ 29.759761392405064, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4821, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.41332 ], [ 29.760030886075949, -1.413589482758621 ], [ 29.760300379746834, -1.413589482758621 ], [ 29.760300379746834, -1.41332 ], [ 29.760030886075949, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4822, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.41332 ], [ 29.760300379746834, -1.413589482758621 ], [ 29.760569873417722, -1.413589482758621 ], [ 29.760569873417722, -1.41332 ], [ 29.760300379746834, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4823, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.41332 ], [ 29.760569873417722, -1.413589482758621 ], [ 29.760839367088607, -1.413589482758621 ], [ 29.760839367088607, -1.41332 ], [ 29.760569873417722, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4824, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.41332 ], [ 29.760839367088607, -1.413589482758621 ], [ 29.761108860759492, -1.413589482758621 ], [ 29.761108860759492, -1.41332 ], [ 29.760839367088607, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4825, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.41332 ], [ 29.762456329113924, -1.413589482758621 ], [ 29.762725822784809, -1.413589482758621 ], [ 29.762725822784809, -1.41332 ], [ 29.762456329113924, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4826, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.41332 ], [ 29.762725822784809, -1.413589482758621 ], [ 29.762995316455697, -1.413589482758621 ], [ 29.762995316455697, -1.41332 ], [ 29.762725822784809, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4827, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.41332 ], [ 29.762995316455697, -1.413589482758621 ], [ 29.763264810126582, -1.413589482758621 ], [ 29.763264810126582, -1.41332 ], [ 29.762995316455697, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4828, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.41332 ], [ 29.772697088607593, -1.413589482758621 ], [ 29.772966582278482, -1.413589482758621 ], [ 29.772966582278482, -1.41332 ], [ 29.772697088607593, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4829, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.41332 ], [ 29.772966582278482, -1.413589482758621 ], [ 29.773236075949367, -1.413589482758621 ], [ 29.773236075949367, -1.41332 ], [ 29.772966582278482, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4830, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.41332 ], [ 29.773505569620252, -1.413589482758621 ], [ 29.77377506329114, -1.413589482758621 ], [ 29.77377506329114, -1.41332 ], [ 29.773505569620252, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4831, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.41332 ], [ 29.77377506329114, -1.413589482758621 ], [ 29.774044556962025, -1.413589482758621 ], [ 29.774044556962025, -1.41332 ], [ 29.77377506329114, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4832, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.41332 ], [ 29.774044556962025, -1.413589482758621 ], [ 29.77431405063291, -1.413589482758621 ], [ 29.77431405063291, -1.41332 ], [ 29.774044556962025, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4833, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.41332 ], [ 29.77431405063291, -1.413589482758621 ], [ 29.774853037974683, -1.413589482758621 ], [ 29.774853037974683, -1.41332 ], [ 29.77431405063291, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4834, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.41332 ], [ 29.774853037974683, -1.413589482758621 ], [ 29.775122531645568, -1.413589482758621 ], [ 29.775122531645568, -1.41332 ], [ 29.774853037974683, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4835, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.41332 ], [ 29.775122531645568, -1.413589482758621 ], [ 29.775392025316457, -1.413589482758621 ], [ 29.775392025316457, -1.41332 ], [ 29.775122531645568, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4836, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.41332 ], [ 29.775392025316457, -1.413589482758621 ], [ 29.775661518987341, -1.413589482758621 ], [ 29.775661518987341, -1.41332 ], [ 29.775392025316457, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4837, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.41332 ], [ 29.775661518987341, -1.413589482758621 ], [ 29.775931012658226, -1.413589482758621 ], [ 29.775931012658226, -1.41332 ], [ 29.775661518987341, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4838, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.41332 ], [ 29.776200506329115, -1.413589482758621 ], [ 29.77647, -1.413589482758621 ], [ 29.77647, -1.41332 ], [ 29.776200506329115, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4839, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.41332 ], [ 29.77647, -1.413589482758621 ], [ 29.776739493670885, -1.413589482758621 ], [ 29.776739493670885, -1.41332 ], [ 29.77647, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4840, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.41332 ], [ 29.776739493670885, -1.413589482758621 ], [ 29.777008987341773, -1.413589482758621 ], [ 29.777008987341773, -1.41332 ], [ 29.776739493670885, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4841, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.41332 ], [ 29.777278481012658, -1.413589482758621 ], [ 29.777547974683543, -1.413589482758621 ], [ 29.777547974683543, -1.41332 ], [ 29.777278481012658, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4842, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.41332 ], [ 29.777547974683543, -1.413589482758621 ], [ 29.777817468354431, -1.413589482758621 ], [ 29.777817468354431, -1.41332 ], [ 29.777547974683543, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4843, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.41332 ], [ 29.777817468354431, -1.413589482758621 ], [ 29.778086962025316, -1.413589482758621 ], [ 29.778086962025316, -1.41332 ], [ 29.777817468354431, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4844, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.41332 ], [ 29.778086962025316, -1.413589482758621 ], [ 29.778356455696201, -1.413589482758621 ], [ 29.778356455696201, -1.41332 ], [ 29.778086962025316, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4845, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.41332 ], [ 29.778356455696201, -1.413589482758621 ], [ 29.77862594936709, -1.413589482758621 ], [ 29.77862594936709, -1.41332 ], [ 29.778356455696201, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4846, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.41332 ], [ 29.77862594936709, -1.413589482758621 ], [ 29.778895443037975, -1.413589482758621 ], [ 29.778895443037975, -1.41332 ], [ 29.77862594936709, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4847, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.41332 ], [ 29.778895443037975, -1.413589482758621 ], [ 29.77916493670886, -1.413589482758621 ], [ 29.77916493670886, -1.41332 ], [ 29.778895443037975, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4848, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.41332 ], [ 29.77916493670886, -1.413589482758621 ], [ 29.779434430379748, -1.413589482758621 ], [ 29.779434430379748, -1.41332 ], [ 29.77916493670886, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4849, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.41332 ], [ 29.779434430379748, -1.413589482758621 ], [ 29.779703924050633, -1.413589482758621 ], [ 29.779703924050633, -1.41332 ], [ 29.779434430379748, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4850, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.41332 ], [ 29.779703924050633, -1.413589482758621 ], [ 29.779973417721518, -1.413589482758621 ], [ 29.779973417721518, -1.41332 ], [ 29.779703924050633, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4851, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.41332 ], [ 29.780781898734176, -1.413589482758621 ], [ 29.781051392405065, -1.413589482758621 ], [ 29.781051392405065, -1.41332 ], [ 29.780781898734176, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4852, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.41332 ], [ 29.78132088607595, -1.413589482758621 ], [ 29.781590379746834, -1.413589482758621 ], [ 29.781590379746834, -1.41332 ], [ 29.78132088607595, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4853, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.41332 ], [ 29.781590379746834, -1.413589482758621 ], [ 29.781859873417723, -1.413589482758621 ], [ 29.781859873417723, -1.41332 ], [ 29.781590379746834, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4854, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.41332 ], [ 29.782129367088608, -1.413589482758621 ], [ 29.782398860759493, -1.413589482758621 ], [ 29.782398860759493, -1.41332 ], [ 29.782129367088608, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4855, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.41332 ], [ 29.782398860759493, -1.413589482758621 ], [ 29.782668354430381, -1.413589482758621 ], [ 29.782668354430381, -1.41332 ], [ 29.782398860759493, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4856, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.41332 ], [ 29.782668354430381, -1.413589482758621 ], [ 29.782937848101266, -1.413589482758621 ], [ 29.782937848101266, -1.41332 ], [ 29.782668354430381, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4857, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.41332 ], [ 29.782937848101266, -1.413589482758621 ], [ 29.783207341772151, -1.413589482758621 ], [ 29.783207341772151, -1.41332 ], [ 29.782937848101266, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4858, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.41332 ], [ 29.783207341772151, -1.413589482758621 ], [ 29.783476835443039, -1.413589482758621 ], [ 29.783476835443039, -1.41332 ], [ 29.783207341772151, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4859, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.41332 ], [ 29.783476835443039, -1.413589482758621 ], [ 29.784015822784809, -1.413589482758621 ], [ 29.784015822784809, -1.41332 ], [ 29.783476835443039, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4860, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.41332 ], [ 29.784015822784809, -1.413589482758621 ], [ 29.784285316455698, -1.413589482758621 ], [ 29.784285316455698, -1.41332 ], [ 29.784015822784809, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4861, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.412511551724138 ], [ 29.755988481012658, -1.414667413793103 ], [ 29.756257974683542, -1.414667413793103 ], [ 29.756257974683542, -1.412511551724138 ], [ 29.755988481012658, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4862, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.412511551724138 ], [ 29.77161911392405, -1.414936896551724 ], [ 29.771888607594935, -1.414936896551724 ], [ 29.771888607594935, -1.412511551724138 ], [ 29.77161911392405, -1.412511551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4863, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.412781034482759 ], [ 29.756257974683542, -1.414397931034483 ], [ 29.756796962025316, -1.414397931034483 ], [ 29.756796962025316, -1.412781034482759 ], [ 29.756257974683542, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4864, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.412781034482759 ], [ 29.756796962025316, -1.414397931034483 ], [ 29.757066455696201, -1.414397931034483 ], [ 29.757066455696201, -1.412781034482759 ], [ 29.756796962025316, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4865, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.412781034482759 ], [ 29.767576708860759, -1.414667413793103 ], [ 29.767846202531643, -1.414667413793103 ], [ 29.767846202531643, -1.412781034482759 ], [ 29.767576708860759, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4866, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.412781034482759 ], [ 29.768385189873417, -1.414667413793103 ], [ 29.768654683544302, -1.414667413793103 ], [ 29.768654683544302, -1.412781034482759 ], [ 29.768385189873417, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4867, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.412781034482759 ], [ 29.76892417721519, -1.414667413793103 ], [ 29.769193670886075, -1.414667413793103 ], [ 29.769193670886075, -1.412781034482759 ], [ 29.76892417721519, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4868, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.412781034482759 ], [ 29.769732658227849, -1.414667413793103 ], [ 29.770002151898733, -1.414667413793103 ], [ 29.770002151898733, -1.412781034482759 ], [ 29.769732658227849, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4869, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.412781034482759 ], [ 29.770271645569618, -1.414667413793103 ], [ 29.770541139240507, -1.414667413793103 ], [ 29.770541139240507, -1.412781034482759 ], [ 29.770271645569618, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4870, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.412781034482759 ], [ 29.770541139240507, -1.414667413793103 ], [ 29.770810632911392, -1.414667413793103 ], [ 29.770810632911392, -1.412781034482759 ], [ 29.770541139240507, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4871, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.412781034482759 ], [ 29.771080126582277, -1.414667413793103 ], [ 29.771349620253165, -1.414667413793103 ], [ 29.771349620253165, -1.412781034482759 ], [ 29.771080126582277, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4872, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.412781034482759 ], [ 29.771888607594935, -1.414667413793103 ], [ 29.772158101265823, -1.414667413793103 ], [ 29.772158101265823, -1.412781034482759 ], [ 29.771888607594935, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4873, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.412781034482759 ], [ 29.772427594936708, -1.414667413793103 ], [ 29.772697088607593, -1.414667413793103 ], [ 29.772697088607593, -1.41332 ], [ 29.772966582278482, -1.41332 ], [ 29.772966582278482, -1.413050517241379 ], [ 29.772697088607593, -1.413050517241379 ], [ 29.772697088607593, -1.412781034482759 ], [ 29.772427594936708, -1.412781034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4874, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.413050517241379 ], [ 29.757066455696201, -1.414128448275862 ], [ 29.757335949367089, -1.414128448275862 ], [ 29.757335949367089, -1.413050517241379 ], [ 29.757066455696201, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4875, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.413050517241379 ], [ 29.757335949367089, -1.414128448275862 ], [ 29.757605443037974, -1.414128448275862 ], [ 29.757605443037974, -1.41332 ], [ 29.757874936708859, -1.41332 ], [ 29.757874936708859, -1.413050517241379 ], [ 29.757335949367089, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.413050517241379 ], [ 29.764612278481014, -1.414397931034483 ], [ 29.764881772151899, -1.414397931034483 ], [ 29.764881772151899, -1.413050517241379 ], [ 29.764612278481014, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4877, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.413050517241379 ], [ 29.765420759493672, -1.414397931034483 ], [ 29.765959746835442, -1.414397931034483 ], [ 29.765959746835442, -1.413050517241379 ], [ 29.765420759493672, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4878, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.413050517241379 ], [ 29.766229240506327, -1.414397931034483 ], [ 29.766498734177215, -1.414397931034483 ], [ 29.766498734177215, -1.413050517241379 ], [ 29.766229240506327, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4879, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.413050517241379 ], [ 29.766498734177215, -1.414397931034483 ], [ 29.7667682278481, -1.414397931034483 ], [ 29.7667682278481, -1.413050517241379 ], [ 29.766498734177215, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4880, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.413050517241379 ], [ 29.767037721518985, -1.414397931034483 ], [ 29.767307215189874, -1.414397931034483 ], [ 29.767307215189874, -1.413050517241379 ], [ 29.767037721518985, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4881, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.413050517241379 ], [ 29.767846202531643, -1.414397931034483 ], [ 29.768115696202532, -1.414397931034483 ], [ 29.768115696202532, -1.413050517241379 ], [ 29.767846202531643, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4882, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.413050517241379 ], [ 29.768654683544302, -1.414397931034483 ], [ 29.76892417721519, -1.414397931034483 ], [ 29.76892417721519, -1.413050517241379 ], [ 29.768654683544302, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4883, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.413050517241379 ], [ 29.769193670886075, -1.414397931034483 ], [ 29.76946316455696, -1.414397931034483 ], [ 29.76946316455696, -1.413050517241379 ], [ 29.769193670886075, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4884, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.413050517241379 ], [ 29.770002151898733, -1.414397931034483 ], [ 29.770271645569618, -1.414397931034483 ], [ 29.770271645569618, -1.413050517241379 ], [ 29.770002151898733, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4885, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.413050517241379 ], [ 29.771349620253165, -1.414397931034483 ], [ 29.77161911392405, -1.414397931034483 ], [ 29.77161911392405, -1.413050517241379 ], [ 29.771349620253165, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4886, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.413050517241379 ], [ 29.781051392405065, -1.414936896551724 ], [ 29.78132088607595, -1.414936896551724 ], [ 29.78132088607595, -1.413050517241379 ], [ 29.781051392405065, -1.413050517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4887, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.41332 ], [ 29.757605443037974, -1.413858965517242 ], [ 29.757874936708859, -1.413858965517242 ], [ 29.757874936708859, -1.41332 ], [ 29.757605443037974, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4888, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.41332 ], [ 29.763264810126582, -1.414128448275862 ], [ 29.763534303797467, -1.414128448275862 ], [ 29.763534303797467, -1.41332 ], [ 29.763264810126582, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4889, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.41332 ], [ 29.763534303797467, -1.414128448275862 ], [ 29.763803797468356, -1.414128448275862 ], [ 29.763803797468356, -1.41332 ], [ 29.763534303797467, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4890, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.41332 ], [ 29.76407329113924, -1.414128448275862 ], [ 29.764342784810125, -1.414128448275862 ], [ 29.764342784810125, -1.41332 ], [ 29.76407329113924, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4891, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.41332 ], [ 29.764342784810125, -1.414128448275862 ], [ 29.764612278481014, -1.414128448275862 ], [ 29.764612278481014, -1.41332 ], [ 29.764342784810125, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4892, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.41332 ], [ 29.764881772151899, -1.414128448275862 ], [ 29.765151265822784, -1.414128448275862 ], [ 29.765151265822784, -1.41332 ], [ 29.764881772151899, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4893, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.41332 ], [ 29.765959746835442, -1.414128448275862 ], [ 29.766229240506327, -1.414128448275862 ], [ 29.766229240506327, -1.41332 ], [ 29.765959746835442, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4894, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.41332 ], [ 29.7667682278481, -1.414128448275862 ], [ 29.767037721518985, -1.414128448275862 ], [ 29.767037721518985, -1.41332 ], [ 29.7667682278481, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4895, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.41332 ], [ 29.767307215189874, -1.414128448275862 ], [ 29.767576708860759, -1.414128448275862 ], [ 29.767576708860759, -1.41332 ], [ 29.767307215189874, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4896, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.41332 ], [ 29.768115696202532, -1.414128448275862 ], [ 29.768385189873417, -1.414128448275862 ], [ 29.768385189873417, -1.41332 ], [ 29.768115696202532, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4897, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.41332 ], [ 29.76946316455696, -1.414128448275862 ], [ 29.769732658227849, -1.414128448275862 ], [ 29.769732658227849, -1.41332 ], [ 29.76946316455696, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4898, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.41332 ], [ 29.770810632911392, -1.414128448275862 ], [ 29.771080126582277, -1.414128448275862 ], [ 29.771080126582277, -1.41332 ], [ 29.770810632911392, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4899, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.41332 ], [ 29.772158101265823, -1.414128448275862 ], [ 29.772427594936708, -1.414128448275862 ], [ 29.772427594936708, -1.41332 ], [ 29.772158101265823, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4900, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.41332 ], [ 29.775931012658226, -1.413858965517242 ], [ 29.776200506329115, -1.413858965517242 ], [ 29.776200506329115, -1.41332 ], [ 29.775931012658226, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4901, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.41332 ], [ 29.779973417721518, -1.414667413793103 ], [ 29.780242911392406, -1.414667413793103 ], [ 29.780242911392406, -1.41332 ], [ 29.779973417721518, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4902, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.41332 ], [ 29.780242911392406, -1.414936896551724 ], [ 29.780512405063291, -1.414936896551724 ], [ 29.780512405063291, -1.41332 ], [ 29.780242911392406, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4903, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.41332 ], [ 29.780512405063291, -1.413858965517242 ], [ 29.780781898734176, -1.413858965517242 ], [ 29.780781898734176, -1.41332 ], [ 29.780512405063291, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4904, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.41332 ], [ 29.781859873417723, -1.413858965517242 ], [ 29.782129367088608, -1.413858965517242 ], [ 29.782129367088608, -1.41332 ], [ 29.781859873417723, -1.41332 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4905, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.413589482758621 ], [ 29.757874936708859, -1.413858965517242 ], [ 29.758413924050632, -1.413858965517242 ], [ 29.758413924050632, -1.413589482758621 ], [ 29.757874936708859, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4906, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.413589482758621 ], [ 29.758413924050632, -1.413858965517242 ], [ 29.758952911392406, -1.413858965517242 ], [ 29.758952911392406, -1.413589482758621 ], [ 29.758413924050632, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4907, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.413589482758621 ], [ 29.758952911392406, -1.413858965517242 ], [ 29.759222405063291, -1.413858965517242 ], [ 29.759222405063291, -1.413589482758621 ], [ 29.758952911392406, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4908, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.413589482758621 ], [ 29.759222405063291, -1.413858965517242 ], [ 29.759491898734176, -1.413858965517242 ], [ 29.759491898734176, -1.413589482758621 ], [ 29.759222405063291, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4909, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.413589482758621 ], [ 29.759491898734176, -1.413858965517242 ], [ 29.759761392405064, -1.413858965517242 ], [ 29.759761392405064, -1.413589482758621 ], [ 29.759491898734176, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4910, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.413589482758621 ], [ 29.759761392405064, -1.413858965517242 ], [ 29.760030886075949, -1.413858965517242 ], [ 29.760030886075949, -1.413589482758621 ], [ 29.759761392405064, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4911, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.413589482758621 ], [ 29.760030886075949, -1.413858965517242 ], [ 29.760300379746834, -1.413858965517242 ], [ 29.760300379746834, -1.413589482758621 ], [ 29.760030886075949, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4912, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.413589482758621 ], [ 29.760300379746834, -1.413858965517242 ], [ 29.760569873417722, -1.413858965517242 ], [ 29.760569873417722, -1.413589482758621 ], [ 29.760300379746834, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4913, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.413589482758621 ], [ 29.760569873417722, -1.413858965517242 ], [ 29.760839367088607, -1.413858965517242 ], [ 29.760839367088607, -1.413589482758621 ], [ 29.760569873417722, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4914, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.413589482758621 ], [ 29.760839367088607, -1.413858965517242 ], [ 29.761108860759492, -1.413858965517242 ], [ 29.761108860759492, -1.413589482758621 ], [ 29.760839367088607, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4915, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.413589482758621 ], [ 29.761108860759492, -1.413858965517242 ], [ 29.761917341772151, -1.413858965517242 ], [ 29.761917341772151, -1.413589482758621 ], [ 29.761108860759492, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4916, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.413589482758621 ], [ 29.762456329113924, -1.413858965517242 ], [ 29.762725822784809, -1.413858965517242 ], [ 29.762725822784809, -1.413589482758621 ], [ 29.762456329113924, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4917, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.413589482758621 ], [ 29.762725822784809, -1.413858965517242 ], [ 29.762995316455697, -1.413858965517242 ], [ 29.762995316455697, -1.413589482758621 ], [ 29.762725822784809, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4918, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.413589482758621 ], [ 29.762995316455697, -1.413858965517242 ], [ 29.763264810126582, -1.413858965517242 ], [ 29.763264810126582, -1.413589482758621 ], [ 29.762995316455697, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4919, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.413589482758621 ], [ 29.763803797468356, -1.413858965517242 ], [ 29.76407329113924, -1.413858965517242 ], [ 29.76407329113924, -1.413589482758621 ], [ 29.763803797468356, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4920, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.413589482758621 ], [ 29.765151265822784, -1.413858965517242 ], [ 29.765420759493672, -1.413858965517242 ], [ 29.765420759493672, -1.413589482758621 ], [ 29.765151265822784, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4921, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.413589482758621 ], [ 29.772697088607593, -1.413858965517242 ], [ 29.772966582278482, -1.413858965517242 ], [ 29.772966582278482, -1.413589482758621 ], [ 29.772697088607593, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4922, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.413589482758621 ], [ 29.772966582278482, -1.413858965517242 ], [ 29.773236075949367, -1.413858965517242 ], [ 29.773236075949367, -1.413589482758621 ], [ 29.772966582278482, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4923, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.413589482758621 ], [ 29.773236075949367, -1.413858965517242 ], [ 29.773505569620252, -1.413858965517242 ], [ 29.773505569620252, -1.413589482758621 ], [ 29.773236075949367, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4924, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.413589482758621 ], [ 29.773505569620252, -1.413858965517242 ], [ 29.77377506329114, -1.413858965517242 ], [ 29.77377506329114, -1.413589482758621 ], [ 29.773505569620252, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4925, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.413589482758621 ], [ 29.77377506329114, -1.413858965517242 ], [ 29.774044556962025, -1.413858965517242 ], [ 29.774044556962025, -1.413589482758621 ], [ 29.77377506329114, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4926, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.413589482758621 ], [ 29.774044556962025, -1.413858965517242 ], [ 29.77431405063291, -1.413858965517242 ], [ 29.77431405063291, -1.413589482758621 ], [ 29.774044556962025, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4927, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.413589482758621 ], [ 29.77431405063291, -1.413858965517242 ], [ 29.774853037974683, -1.413858965517242 ], [ 29.774853037974683, -1.413589482758621 ], [ 29.77431405063291, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4928, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.413589482758621 ], [ 29.774853037974683, -1.413858965517242 ], [ 29.775122531645568, -1.413858965517242 ], [ 29.775122531645568, -1.413589482758621 ], [ 29.774853037974683, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4929, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.413589482758621 ], [ 29.775122531645568, -1.413858965517242 ], [ 29.775392025316457, -1.413858965517242 ], [ 29.775392025316457, -1.413589482758621 ], [ 29.775122531645568, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4930, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.413589482758621 ], [ 29.775392025316457, -1.413858965517242 ], [ 29.775661518987341, -1.413858965517242 ], [ 29.775661518987341, -1.413589482758621 ], [ 29.775392025316457, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4931, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.413589482758621 ], [ 29.775661518987341, -1.413858965517242 ], [ 29.775931012658226, -1.413858965517242 ], [ 29.775931012658226, -1.413589482758621 ], [ 29.775661518987341, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4932, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.413589482758621 ], [ 29.776200506329115, -1.413858965517242 ], [ 29.77647, -1.413858965517242 ], [ 29.77647, -1.413589482758621 ], [ 29.776200506329115, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4933, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.413589482758621 ], [ 29.77647, -1.413858965517242 ], [ 29.776739493670885, -1.413858965517242 ], [ 29.776739493670885, -1.413589482758621 ], [ 29.77647, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4934, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.413589482758621 ], [ 29.776739493670885, -1.413858965517242 ], [ 29.777008987341773, -1.413858965517242 ], [ 29.777008987341773, -1.413589482758621 ], [ 29.776739493670885, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4935, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.413589482758621 ], [ 29.777008987341773, -1.413858965517242 ], [ 29.777278481012658, -1.413858965517242 ], [ 29.777278481012658, -1.413589482758621 ], [ 29.777008987341773, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4936, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.413589482758621 ], [ 29.777278481012658, -1.413858965517242 ], [ 29.777547974683543, -1.413858965517242 ], [ 29.777547974683543, -1.413589482758621 ], [ 29.777278481012658, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4937, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.413589482758621 ], [ 29.777547974683543, -1.413858965517242 ], [ 29.777817468354431, -1.413858965517242 ], [ 29.777817468354431, -1.413589482758621 ], [ 29.777547974683543, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4938, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.413589482758621 ], [ 29.777817468354431, -1.413858965517242 ], [ 29.778086962025316, -1.413858965517242 ], [ 29.778086962025316, -1.413589482758621 ], [ 29.777817468354431, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4939, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.413589482758621 ], [ 29.778086962025316, -1.413858965517242 ], [ 29.778356455696201, -1.413858965517242 ], [ 29.778356455696201, -1.413589482758621 ], [ 29.778086962025316, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4940, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.413589482758621 ], [ 29.778356455696201, -1.413858965517242 ], [ 29.77862594936709, -1.413858965517242 ], [ 29.77862594936709, -1.413589482758621 ], [ 29.778356455696201, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4941, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.413589482758621 ], [ 29.77862594936709, -1.414667413793103 ], [ 29.778895443037975, -1.414667413793103 ], [ 29.778895443037975, -1.413589482758621 ], [ 29.77862594936709, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4942, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.413589482758621 ], [ 29.778895443037975, -1.414936896551724 ], [ 29.77916493670886, -1.414936896551724 ], [ 29.77916493670886, -1.413589482758621 ], [ 29.778895443037975, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4943, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.413589482758621 ], [ 29.77916493670886, -1.414667413793103 ], [ 29.779434430379748, -1.414667413793103 ], [ 29.779434430379748, -1.413589482758621 ], [ 29.77916493670886, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4944, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.413589482758621 ], [ 29.779434430379748, -1.414936896551724 ], [ 29.779703924050633, -1.414936896551724 ], [ 29.779703924050633, -1.413589482758621 ], [ 29.779434430379748, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4945, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.413589482758621 ], [ 29.779703924050633, -1.414936896551724 ], [ 29.779973417721518, -1.414936896551724 ], [ 29.779973417721518, -1.413589482758621 ], [ 29.779703924050633, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4946, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.413589482758621 ], [ 29.780781898734176, -1.414667413793103 ], [ 29.781051392405065, -1.414667413793103 ], [ 29.781051392405065, -1.413589482758621 ], [ 29.780781898734176, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4947, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.413589482758621 ], [ 29.78132088607595, -1.414667413793103 ], [ 29.781590379746834, -1.414667413793103 ], [ 29.781590379746834, -1.413589482758621 ], [ 29.78132088607595, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4948, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.413589482758621 ], [ 29.781590379746834, -1.414667413793103 ], [ 29.781859873417723, -1.414667413793103 ], [ 29.781859873417723, -1.413589482758621 ], [ 29.781590379746834, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4949, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.413589482758621 ], [ 29.782129367088608, -1.414667413793103 ], [ 29.782398860759493, -1.414667413793103 ], [ 29.782398860759493, -1.413589482758621 ], [ 29.782129367088608, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4950, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.413589482758621 ], [ 29.782398860759493, -1.414667413793103 ], [ 29.782668354430381, -1.414667413793103 ], [ 29.782668354430381, -1.413589482758621 ], [ 29.782398860759493, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4951, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.413589482758621 ], [ 29.782668354430381, -1.413858965517242 ], [ 29.782937848101266, -1.413858965517242 ], [ 29.782937848101266, -1.413589482758621 ], [ 29.782668354430381, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4952, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.413589482758621 ], [ 29.782937848101266, -1.413858965517242 ], [ 29.783207341772151, -1.413858965517242 ], [ 29.783207341772151, -1.413589482758621 ], [ 29.782937848101266, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4953, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.413589482758621 ], [ 29.783207341772151, -1.413858965517242 ], [ 29.783476835443039, -1.413858965517242 ], [ 29.783476835443039, -1.413589482758621 ], [ 29.783207341772151, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4954, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.413589482758621 ], [ 29.783476835443039, -1.413858965517242 ], [ 29.784015822784809, -1.413858965517242 ], [ 29.784015822784809, -1.413589482758621 ], [ 29.783476835443039, -1.413589482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4955, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.413858965517242 ], [ 29.757605443037974, -1.414128448275862 ], [ 29.757874936708859, -1.414128448275862 ], [ 29.757874936708859, -1.413858965517242 ], [ 29.757605443037974, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4956, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.413858965517242 ], [ 29.757874936708859, -1.414128448275862 ], [ 29.758144430379748, -1.414128448275862 ], [ 29.758144430379748, -1.413858965517242 ], [ 29.757874936708859, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4957, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.413858965517242 ], [ 29.758144430379748, -1.414128448275862 ], [ 29.758683417721517, -1.414128448275862 ], [ 29.758683417721517, -1.413858965517242 ], [ 29.758144430379748, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4958, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.413858965517242 ], [ 29.758683417721517, -1.414128448275862 ], [ 29.758952911392406, -1.414128448275862 ], [ 29.758952911392406, -1.413858965517242 ], [ 29.758683417721517, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4959, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.413858965517242 ], [ 29.758952911392406, -1.414128448275862 ], [ 29.759222405063291, -1.414128448275862 ], [ 29.759222405063291, -1.413858965517242 ], [ 29.758952911392406, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4960, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.413858965517242 ], [ 29.759222405063291, -1.414128448275862 ], [ 29.759491898734176, -1.414128448275862 ], [ 29.759491898734176, -1.413858965517242 ], [ 29.759222405063291, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4961, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.413858965517242 ], [ 29.759491898734176, -1.414128448275862 ], [ 29.759761392405064, -1.414128448275862 ], [ 29.759761392405064, -1.413858965517242 ], [ 29.759491898734176, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4962, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.413858965517242 ], [ 29.759761392405064, -1.414128448275862 ], [ 29.760030886075949, -1.414128448275862 ], [ 29.760030886075949, -1.413858965517242 ], [ 29.759761392405064, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4963, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.413858965517242 ], [ 29.760030886075949, -1.414128448275862 ], [ 29.760300379746834, -1.414128448275862 ], [ 29.760300379746834, -1.413858965517242 ], [ 29.760030886075949, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4964, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.413858965517242 ], [ 29.760300379746834, -1.414128448275862 ], [ 29.760569873417722, -1.414128448275862 ], [ 29.760569873417722, -1.413858965517242 ], [ 29.760300379746834, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4965, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.413858965517242 ], [ 29.760569873417722, -1.414128448275862 ], [ 29.760839367088607, -1.414128448275862 ], [ 29.760839367088607, -1.413858965517242 ], [ 29.760569873417722, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4966, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.413858965517242 ], [ 29.760839367088607, -1.414128448275862 ], [ 29.761108860759492, -1.414128448275862 ], [ 29.761108860759492, -1.413858965517242 ], [ 29.760839367088607, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4967, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.413858965517242 ], [ 29.761108860759492, -1.414128448275862 ], [ 29.761647848101266, -1.414128448275862 ], [ 29.761647848101266, -1.413858965517242 ], [ 29.761108860759492, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4968, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.413858965517242 ], [ 29.761647848101266, -1.414128448275862 ], [ 29.761917341772151, -1.414128448275862 ], [ 29.761917341772151, -1.413858965517242 ], [ 29.761647848101266, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4969, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.413858965517242 ], [ 29.761917341772151, -1.414128448275862 ], [ 29.762456329113924, -1.414128448275862 ], [ 29.762456329113924, -1.413858965517242 ], [ 29.761917341772151, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4970, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.413858965517242 ], [ 29.762456329113924, -1.414128448275862 ], [ 29.762725822784809, -1.414128448275862 ], [ 29.762725822784809, -1.413858965517242 ], [ 29.762456329113924, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4971, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.413858965517242 ], [ 29.762725822784809, -1.414128448275862 ], [ 29.762995316455697, -1.414128448275862 ], [ 29.762995316455697, -1.413858965517242 ], [ 29.762725822784809, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4972, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.413858965517242 ], [ 29.762995316455697, -1.414128448275862 ], [ 29.763264810126582, -1.414128448275862 ], [ 29.763264810126582, -1.413858965517242 ], [ 29.762995316455697, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4973, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.413858965517242 ], [ 29.763803797468356, -1.414397931034483 ], [ 29.76407329113924, -1.414397931034483 ], [ 29.76407329113924, -1.413858965517242 ], [ 29.763803797468356, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4974, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.413858965517242 ], [ 29.765151265822784, -1.414397931034483 ], [ 29.765420759493672, -1.414397931034483 ], [ 29.765420759493672, -1.413858965517242 ], [ 29.765151265822784, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4975, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.413858965517242 ], [ 29.772697088607593, -1.414397931034483 ], [ 29.772966582278482, -1.414397931034483 ], [ 29.772966582278482, -1.413858965517242 ], [ 29.772697088607593, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4976, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.413858965517242 ], [ 29.772966582278482, -1.414128448275862 ], [ 29.773236075949367, -1.414128448275862 ], [ 29.773236075949367, -1.413858965517242 ], [ 29.772966582278482, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4977, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.413858965517242 ], [ 29.773236075949367, -1.414128448275862 ], [ 29.773505569620252, -1.414128448275862 ], [ 29.773505569620252, -1.413858965517242 ], [ 29.773236075949367, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4978, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.413858965517242 ], [ 29.773505569620252, -1.414128448275862 ], [ 29.77377506329114, -1.414128448275862 ], [ 29.77377506329114, -1.413858965517242 ], [ 29.773505569620252, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4979, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.413858965517242 ], [ 29.77377506329114, -1.414128448275862 ], [ 29.774044556962025, -1.414128448275862 ], [ 29.774044556962025, -1.413858965517242 ], [ 29.77377506329114, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4980, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.413858965517242 ], [ 29.774044556962025, -1.414128448275862 ], [ 29.77431405063291, -1.414128448275862 ], [ 29.77431405063291, -1.413858965517242 ], [ 29.774044556962025, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4981, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.413858965517242 ], [ 29.77431405063291, -1.414128448275862 ], [ 29.774853037974683, -1.414128448275862 ], [ 29.774853037974683, -1.413858965517242 ], [ 29.77431405063291, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4982, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.413858965517242 ], [ 29.774853037974683, -1.414128448275862 ], [ 29.775122531645568, -1.414128448275862 ], [ 29.775122531645568, -1.413858965517242 ], [ 29.774853037974683, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4983, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.413858965517242 ], [ 29.775122531645568, -1.414128448275862 ], [ 29.775392025316457, -1.414128448275862 ], [ 29.775392025316457, -1.413858965517242 ], [ 29.775122531645568, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4984, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.413858965517242 ], [ 29.775392025316457, -1.414128448275862 ], [ 29.775661518987341, -1.414128448275862 ], [ 29.775661518987341, -1.413858965517242 ], [ 29.775392025316457, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4985, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.413858965517242 ], [ 29.775661518987341, -1.414128448275862 ], [ 29.775931012658226, -1.414128448275862 ], [ 29.775931012658226, -1.413858965517242 ], [ 29.775661518987341, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4986, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.413858965517242 ], [ 29.775931012658226, -1.414128448275862 ], [ 29.776200506329115, -1.414128448275862 ], [ 29.776200506329115, -1.413858965517242 ], [ 29.775931012658226, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4987, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.413858965517242 ], [ 29.776200506329115, -1.414128448275862 ], [ 29.77647, -1.414128448275862 ], [ 29.77647, -1.413858965517242 ], [ 29.776200506329115, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4988, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.413858965517242 ], [ 29.77647, -1.414128448275862 ], [ 29.776739493670885, -1.414128448275862 ], [ 29.776739493670885, -1.413858965517242 ], [ 29.77647, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4989, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.413858965517242 ], [ 29.776739493670885, -1.414128448275862 ], [ 29.777008987341773, -1.414128448275862 ], [ 29.777008987341773, -1.413858965517242 ], [ 29.776739493670885, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4990, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.413858965517242 ], [ 29.777008987341773, -1.414128448275862 ], [ 29.777278481012658, -1.414128448275862 ], [ 29.777278481012658, -1.413858965517242 ], [ 29.777008987341773, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4991, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.413858965517242 ], [ 29.777278481012658, -1.414936896551724 ], [ 29.777547974683543, -1.414936896551724 ], [ 29.777547974683543, -1.413858965517242 ], [ 29.777278481012658, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4992, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.413858965517242 ], [ 29.777547974683543, -1.415206379310345 ], [ 29.777817468354431, -1.415206379310345 ], [ 29.777817468354431, -1.413858965517242 ], [ 29.777547974683543, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4993, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.413858965517242 ], [ 29.777817468354431, -1.414667413793103 ], [ 29.778086962025316, -1.414667413793103 ], [ 29.778086962025316, -1.413858965517242 ], [ 29.777817468354431, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4994, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.413858965517242 ], [ 29.778086962025316, -1.414936896551724 ], [ 29.778356455696201, -1.414936896551724 ], [ 29.778356455696201, -1.413858965517242 ], [ 29.778086962025316, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4995, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.413858965517242 ], [ 29.778356455696201, -1.415206379310345 ], [ 29.77862594936709, -1.415206379310345 ], [ 29.77862594936709, -1.413858965517242 ], [ 29.778356455696201, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4996, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.413858965517242 ], [ 29.780512405063291, -1.414397931034483 ], [ 29.780781898734176, -1.414397931034483 ], [ 29.780781898734176, -1.413858965517242 ], [ 29.780512405063291, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4997, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.413858965517242 ], [ 29.781859873417723, -1.414397931034483 ], [ 29.782129367088608, -1.414397931034483 ], [ 29.782129367088608, -1.413858965517242 ], [ 29.781859873417723, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4998, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.413858965517242 ], [ 29.782668354430381, -1.414397931034483 ], [ 29.782937848101266, -1.414397931034483 ], [ 29.782937848101266, -1.413858965517242 ], [ 29.782668354430381, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 4999, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.413858965517242 ], [ 29.782937848101266, -1.414397931034483 ], [ 29.783207341772151, -1.414397931034483 ], [ 29.783207341772151, -1.413858965517242 ], [ 29.782937848101266, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5000, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.413858965517242 ], [ 29.783207341772151, -1.414397931034483 ], [ 29.783476835443039, -1.414397931034483 ], [ 29.783476835443039, -1.413858965517242 ], [ 29.783207341772151, -1.413858965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5001, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.414128448275862 ], [ 29.757066455696201, -1.414397931034483 ], [ 29.757335949367089, -1.414397931034483 ], [ 29.757335949367089, -1.414128448275862 ], [ 29.757066455696201, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5002, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.414128448275862 ], [ 29.757335949367089, -1.414397931034483 ], [ 29.757605443037974, -1.414397931034483 ], [ 29.757605443037974, -1.414128448275862 ], [ 29.757335949367089, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5003, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.414128448275862 ], [ 29.757605443037974, -1.414397931034483 ], [ 29.757874936708859, -1.414397931034483 ], [ 29.757874936708859, -1.414128448275862 ], [ 29.757605443037974, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5004, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.414128448275862 ], [ 29.757874936708859, -1.414397931034483 ], [ 29.758144430379748, -1.414397931034483 ], [ 29.758144430379748, -1.414128448275862 ], [ 29.757874936708859, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5005, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.414128448275862 ], [ 29.758144430379748, -1.414397931034483 ], [ 29.758413924050632, -1.414397931034483 ], [ 29.758413924050632, -1.414128448275862 ], [ 29.758144430379748, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5006, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.414128448275862 ], [ 29.758413924050632, -1.414397931034483 ], [ 29.758683417721517, -1.414397931034483 ], [ 29.758683417721517, -1.414128448275862 ], [ 29.758413924050632, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5007, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.414128448275862 ], [ 29.758683417721517, -1.414667413793103 ], [ 29.758952911392406, -1.414667413793103 ], [ 29.758952911392406, -1.414128448275862 ], [ 29.758683417721517, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5008, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.414128448275862 ], [ 29.758952911392406, -1.414397931034483 ], [ 29.759222405063291, -1.414397931034483 ], [ 29.759222405063291, -1.414128448275862 ], [ 29.758952911392406, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5009, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.414128448275862 ], [ 29.759222405063291, -1.414397931034483 ], [ 29.759491898734176, -1.414397931034483 ], [ 29.759491898734176, -1.414128448275862 ], [ 29.759222405063291, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5010, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.414128448275862 ], [ 29.759491898734176, -1.414397931034483 ], [ 29.759761392405064, -1.414397931034483 ], [ 29.759761392405064, -1.414128448275862 ], [ 29.759491898734176, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5011, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.414128448275862 ], [ 29.759761392405064, -1.414397931034483 ], [ 29.760030886075949, -1.414397931034483 ], [ 29.760030886075949, -1.414128448275862 ], [ 29.759761392405064, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5012, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.414128448275862 ], [ 29.760030886075949, -1.414397931034483 ], [ 29.760300379746834, -1.414397931034483 ], [ 29.760300379746834, -1.414128448275862 ], [ 29.760030886075949, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5013, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.414128448275862 ], [ 29.760300379746834, -1.414397931034483 ], [ 29.760569873417722, -1.414397931034483 ], [ 29.760569873417722, -1.414128448275862 ], [ 29.760300379746834, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5014, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.414128448275862 ], [ 29.760569873417722, -1.414397931034483 ], [ 29.760839367088607, -1.414397931034483 ], [ 29.760839367088607, -1.414128448275862 ], [ 29.760569873417722, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5015, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.414128448275862 ], [ 29.760839367088607, -1.414397931034483 ], [ 29.761108860759492, -1.414397931034483 ], [ 29.761108860759492, -1.414128448275862 ], [ 29.760839367088607, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5016, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.414128448275862 ], [ 29.761108860759492, -1.414397931034483 ], [ 29.761647848101266, -1.414397931034483 ], [ 29.761647848101266, -1.414128448275862 ], [ 29.761108860759492, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5017, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.414128448275862 ], [ 29.761647848101266, -1.414397931034483 ], [ 29.761917341772151, -1.414397931034483 ], [ 29.761917341772151, -1.414128448275862 ], [ 29.761647848101266, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5018, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.414128448275862 ], [ 29.761917341772151, -1.414397931034483 ], [ 29.762456329113924, -1.414397931034483 ], [ 29.762456329113924, -1.414128448275862 ], [ 29.761917341772151, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5019, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.414128448275862 ], [ 29.762456329113924, -1.414397931034483 ], [ 29.762725822784809, -1.414397931034483 ], [ 29.762725822784809, -1.414128448275862 ], [ 29.762456329113924, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5020, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.414128448275862 ], [ 29.762725822784809, -1.414397931034483 ], [ 29.762995316455697, -1.414397931034483 ], [ 29.762995316455697, -1.414128448275862 ], [ 29.762725822784809, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5021, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.414128448275862 ], [ 29.762995316455697, -1.414397931034483 ], [ 29.763264810126582, -1.414397931034483 ], [ 29.763264810126582, -1.414128448275862 ], [ 29.762995316455697, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5022, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.414128448275862 ], [ 29.763264810126582, -1.414397931034483 ], [ 29.763534303797467, -1.414397931034483 ], [ 29.763534303797467, -1.414128448275862 ], [ 29.763264810126582, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5023, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.414128448275862 ], [ 29.763534303797467, -1.414397931034483 ], [ 29.763803797468356, -1.414397931034483 ], [ 29.763803797468356, -1.414128448275862 ], [ 29.763534303797467, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.414128448275862 ], [ 29.76407329113924, -1.414397931034483 ], [ 29.764342784810125, -1.414397931034483 ], [ 29.764342784810125, -1.414128448275862 ], [ 29.76407329113924, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5025, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.414128448275862 ], [ 29.764342784810125, -1.414397931034483 ], [ 29.764612278481014, -1.414397931034483 ], [ 29.764612278481014, -1.414128448275862 ], [ 29.764342784810125, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5026, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.414128448275862 ], [ 29.764881772151899, -1.414667413793103 ], [ 29.765151265822784, -1.414667413793103 ], [ 29.765151265822784, -1.414128448275862 ], [ 29.764881772151899, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5027, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.414128448275862 ], [ 29.765959746835442, -1.414667413793103 ], [ 29.766229240506327, -1.414667413793103 ], [ 29.766229240506327, -1.414128448275862 ], [ 29.765959746835442, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5028, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.414128448275862 ], [ 29.7667682278481, -1.414667413793103 ], [ 29.767037721518985, -1.414667413793103 ], [ 29.767037721518985, -1.414128448275862 ], [ 29.7667682278481, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5029, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.414128448275862 ], [ 29.767307215189874, -1.414667413793103 ], [ 29.767576708860759, -1.414667413793103 ], [ 29.767576708860759, -1.414128448275862 ], [ 29.767307215189874, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5030, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.414128448275862 ], [ 29.768115696202532, -1.414667413793103 ], [ 29.768385189873417, -1.414667413793103 ], [ 29.768385189873417, -1.414128448275862 ], [ 29.768115696202532, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5031, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.414128448275862 ], [ 29.76946316455696, -1.414936896551724 ], [ 29.769732658227849, -1.414936896551724 ], [ 29.769732658227849, -1.414128448275862 ], [ 29.76946316455696, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5032, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.414128448275862 ], [ 29.770810632911392, -1.414936896551724 ], [ 29.771080126582277, -1.414936896551724 ], [ 29.771080126582277, -1.414128448275862 ], [ 29.770810632911392, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5033, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.414128448275862 ], [ 29.772158101265823, -1.414936896551724 ], [ 29.772427594936708, -1.414936896551724 ], [ 29.772427594936708, -1.414128448275862 ], [ 29.772158101265823, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5034, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.414128448275862 ], [ 29.772966582278482, -1.414397931034483 ], [ 29.773236075949367, -1.414397931034483 ], [ 29.773236075949367, -1.414128448275862 ], [ 29.772966582278482, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5035, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.414128448275862 ], [ 29.773236075949367, -1.414397931034483 ], [ 29.773505569620252, -1.414397931034483 ], [ 29.773505569620252, -1.414128448275862 ], [ 29.773236075949367, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5036, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.414128448275862 ], [ 29.773505569620252, -1.414397931034483 ], [ 29.77377506329114, -1.414397931034483 ], [ 29.77377506329114, -1.414128448275862 ], [ 29.773505569620252, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5037, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.414128448275862 ], [ 29.77377506329114, -1.414397931034483 ], [ 29.774044556962025, -1.414397931034483 ], [ 29.774044556962025, -1.414128448275862 ], [ 29.77377506329114, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5038, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.414128448275862 ], [ 29.774044556962025, -1.414397931034483 ], [ 29.77431405063291, -1.414397931034483 ], [ 29.77431405063291, -1.414128448275862 ], [ 29.774044556962025, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5039, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.414128448275862 ], [ 29.77431405063291, -1.414397931034483 ], [ 29.774853037974683, -1.414397931034483 ], [ 29.774853037974683, -1.414128448275862 ], [ 29.77431405063291, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5040, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.414128448275862 ], [ 29.774853037974683, -1.414397931034483 ], [ 29.775122531645568, -1.414397931034483 ], [ 29.775122531645568, -1.414128448275862 ], [ 29.774853037974683, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5041, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.414128448275862 ], [ 29.775122531645568, -1.414397931034483 ], [ 29.775392025316457, -1.414397931034483 ], [ 29.775392025316457, -1.414128448275862 ], [ 29.775122531645568, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5042, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.414128448275862 ], [ 29.775392025316457, -1.414397931034483 ], [ 29.775661518987341, -1.414397931034483 ], [ 29.775661518987341, -1.414128448275862 ], [ 29.775392025316457, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5043, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.414128448275862 ], [ 29.775661518987341, -1.414397931034483 ], [ 29.775931012658226, -1.414397931034483 ], [ 29.775931012658226, -1.414128448275862 ], [ 29.775661518987341, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5044, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.414128448275862 ], [ 29.775931012658226, -1.414936896551724 ], [ 29.776200506329115, -1.414936896551724 ], [ 29.776200506329115, -1.414128448275862 ], [ 29.775931012658226, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5045, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.414128448275862 ], [ 29.776200506329115, -1.415206379310345 ], [ 29.77647, -1.415206379310345 ], [ 29.77647, -1.414128448275862 ], [ 29.776200506329115, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5046, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.414128448275862 ], [ 29.77647, -1.414667413793103 ], [ 29.776739493670885, -1.414667413793103 ], [ 29.776739493670885, -1.414128448275862 ], [ 29.77647, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5047, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.414128448275862 ], [ 29.776739493670885, -1.414936896551724 ], [ 29.777008987341773, -1.414936896551724 ], [ 29.777008987341773, -1.414128448275862 ], [ 29.776739493670885, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5048, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.414128448275862 ], [ 29.777008987341773, -1.415206379310345 ], [ 29.777278481012658, -1.415206379310345 ], [ 29.777278481012658, -1.414128448275862 ], [ 29.777008987341773, -1.414128448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5049, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.414397931034483 ], [ 29.756257974683542, -1.414667413793103 ], [ 29.756796962025316, -1.414667413793103 ], [ 29.756796962025316, -1.414397931034483 ], [ 29.756257974683542, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5050, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.414397931034483 ], [ 29.756796962025316, -1.414667413793103 ], [ 29.757066455696201, -1.414667413793103 ], [ 29.757066455696201, -1.414397931034483 ], [ 29.756796962025316, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5051, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.414397931034483 ], [ 29.757066455696201, -1.414667413793103 ], [ 29.757335949367089, -1.414667413793103 ], [ 29.757335949367089, -1.414397931034483 ], [ 29.757066455696201, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5052, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.414397931034483 ], [ 29.757335949367089, -1.414667413793103 ], [ 29.757605443037974, -1.414667413793103 ], [ 29.757605443037974, -1.414397931034483 ], [ 29.757335949367089, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5053, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.414397931034483 ], [ 29.757605443037974, -1.414667413793103 ], [ 29.757874936708859, -1.414667413793103 ], [ 29.757874936708859, -1.414397931034483 ], [ 29.757605443037974, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5054, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.414397931034483 ], [ 29.757874936708859, -1.414667413793103 ], [ 29.758144430379748, -1.414667413793103 ], [ 29.758144430379748, -1.414397931034483 ], [ 29.757874936708859, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5055, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.414397931034483 ], [ 29.758144430379748, -1.414667413793103 ], [ 29.758413924050632, -1.414667413793103 ], [ 29.758413924050632, -1.414397931034483 ], [ 29.758144430379748, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5056, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.414397931034483 ], [ 29.758413924050632, -1.414667413793103 ], [ 29.758683417721517, -1.414667413793103 ], [ 29.758683417721517, -1.414397931034483 ], [ 29.758413924050632, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5057, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.414397931034483 ], [ 29.758952911392406, -1.414936896551724 ], [ 29.759222405063291, -1.414936896551724 ], [ 29.759222405063291, -1.415206379310345 ], [ 29.759491898734176, -1.415206379310345 ], [ 29.759491898734176, -1.414667413793103 ], [ 29.759222405063291, -1.414667413793103 ], [ 29.759222405063291, -1.414397931034483 ], [ 29.758952911392406, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5058, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.414397931034483 ], [ 29.759222405063291, -1.414667413793103 ], [ 29.759491898734176, -1.414667413793103 ], [ 29.759491898734176, -1.414397931034483 ], [ 29.759222405063291, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5059, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.414397931034483 ], [ 29.759491898734176, -1.414667413793103 ], [ 29.759761392405064, -1.414667413793103 ], [ 29.759761392405064, -1.414397931034483 ], [ 29.759491898734176, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5060, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.414397931034483 ], [ 29.759761392405064, -1.414667413793103 ], [ 29.760030886075949, -1.414667413793103 ], [ 29.760030886075949, -1.414397931034483 ], [ 29.759761392405064, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5061, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.414397931034483 ], [ 29.760030886075949, -1.414667413793103 ], [ 29.760300379746834, -1.414667413793103 ], [ 29.760300379746834, -1.414397931034483 ], [ 29.760030886075949, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5062, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.414397931034483 ], [ 29.760300379746834, -1.414667413793103 ], [ 29.760569873417722, -1.414667413793103 ], [ 29.760569873417722, -1.414397931034483 ], [ 29.760300379746834, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5063, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.414397931034483 ], [ 29.760569873417722, -1.414667413793103 ], [ 29.760839367088607, -1.414667413793103 ], [ 29.760839367088607, -1.414397931034483 ], [ 29.760569873417722, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5064, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.414397931034483 ], [ 29.760839367088607, -1.414667413793103 ], [ 29.761378354430381, -1.414667413793103 ], [ 29.761378354430381, -1.414397931034483 ], [ 29.760839367088607, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5065, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.414397931034483 ], [ 29.761378354430381, -1.414667413793103 ], [ 29.761647848101266, -1.414667413793103 ], [ 29.761647848101266, -1.414397931034483 ], [ 29.761378354430381, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5066, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.414397931034483 ], [ 29.761647848101266, -1.414667413793103 ], [ 29.761917341772151, -1.414667413793103 ], [ 29.761917341772151, -1.414397931034483 ], [ 29.761647848101266, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5067, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.414397931034483 ], [ 29.761917341772151, -1.414667413793103 ], [ 29.762456329113924, -1.414667413793103 ], [ 29.762456329113924, -1.414397931034483 ], [ 29.761917341772151, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5068, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.414397931034483 ], [ 29.762456329113924, -1.414667413793103 ], [ 29.762725822784809, -1.414667413793103 ], [ 29.762725822784809, -1.414397931034483 ], [ 29.762456329113924, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5069, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.414397931034483 ], [ 29.762725822784809, -1.414667413793103 ], [ 29.762995316455697, -1.414667413793103 ], [ 29.762995316455697, -1.414397931034483 ], [ 29.762725822784809, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5070, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.414397931034483 ], [ 29.762995316455697, -1.414667413793103 ], [ 29.763264810126582, -1.414667413793103 ], [ 29.763264810126582, -1.414397931034483 ], [ 29.762995316455697, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5071, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.414397931034483 ], [ 29.763264810126582, -1.414667413793103 ], [ 29.763534303797467, -1.414667413793103 ], [ 29.763534303797467, -1.414397931034483 ], [ 29.763264810126582, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5072, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.414397931034483 ], [ 29.763534303797467, -1.414667413793103 ], [ 29.763803797468356, -1.414667413793103 ], [ 29.763803797468356, -1.414397931034483 ], [ 29.763534303797467, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5073, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.414397931034483 ], [ 29.763803797468356, -1.414667413793103 ], [ 29.76407329113924, -1.414667413793103 ], [ 29.76407329113924, -1.414397931034483 ], [ 29.763803797468356, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5074, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.414397931034483 ], [ 29.76407329113924, -1.414667413793103 ], [ 29.764342784810125, -1.414667413793103 ], [ 29.764342784810125, -1.414397931034483 ], [ 29.76407329113924, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5075, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.414397931034483 ], [ 29.764342784810125, -1.414667413793103 ], [ 29.764612278481014, -1.414667413793103 ], [ 29.764612278481014, -1.414397931034483 ], [ 29.764342784810125, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5076, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.414397931034483 ], [ 29.764612278481014, -1.414667413793103 ], [ 29.764881772151899, -1.414667413793103 ], [ 29.764881772151899, -1.414397931034483 ], [ 29.764612278481014, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5077, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.414397931034483 ], [ 29.765151265822784, -1.414667413793103 ], [ 29.765420759493672, -1.414667413793103 ], [ 29.765420759493672, -1.414397931034483 ], [ 29.765151265822784, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5078, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.414397931034483 ], [ 29.765420759493672, -1.414667413793103 ], [ 29.765959746835442, -1.414667413793103 ], [ 29.765959746835442, -1.414397931034483 ], [ 29.765420759493672, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5079, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.414397931034483 ], [ 29.766229240506327, -1.414936896551724 ], [ 29.766498734177215, -1.414936896551724 ], [ 29.766498734177215, -1.414397931034483 ], [ 29.766229240506327, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5080, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.414397931034483 ], [ 29.766498734177215, -1.414667413793103 ], [ 29.7667682278481, -1.414667413793103 ], [ 29.7667682278481, -1.414397931034483 ], [ 29.766498734177215, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5081, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.414397931034483 ], [ 29.767037721518985, -1.414936896551724 ], [ 29.767307215189874, -1.414936896551724 ], [ 29.767307215189874, -1.414397931034483 ], [ 29.767037721518985, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5082, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.414397931034483 ], [ 29.767846202531643, -1.414936896551724 ], [ 29.768115696202532, -1.414936896551724 ], [ 29.768115696202532, -1.414397931034483 ], [ 29.767846202531643, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5083, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.414397931034483 ], [ 29.768654683544302, -1.414936896551724 ], [ 29.76892417721519, -1.414936896551724 ], [ 29.76892417721519, -1.414397931034483 ], [ 29.768654683544302, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5084, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.414397931034483 ], [ 29.769193670886075, -1.414936896551724 ], [ 29.76946316455696, -1.414936896551724 ], [ 29.76946316455696, -1.414397931034483 ], [ 29.769193670886075, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5085, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.414397931034483 ], [ 29.770002151898733, -1.414936896551724 ], [ 29.770271645569618, -1.414936896551724 ], [ 29.770271645569618, -1.414397931034483 ], [ 29.770002151898733, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5086, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.414397931034483 ], [ 29.771349620253165, -1.415206379310345 ], [ 29.77161911392405, -1.415206379310345 ], [ 29.77161911392405, -1.414397931034483 ], [ 29.771349620253165, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5087, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.414397931034483 ], [ 29.772697088607593, -1.415206379310345 ], [ 29.772966582278482, -1.415206379310345 ], [ 29.772966582278482, -1.415745344827586 ], [ 29.773236075949367, -1.415745344827586 ], [ 29.773236075949367, -1.414936896551724 ], [ 29.772966582278482, -1.414936896551724 ], [ 29.772966582278482, -1.414397931034483 ], [ 29.772697088607593, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5088, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.414397931034483 ], [ 29.772966582278482, -1.414667413793103 ], [ 29.773236075949367, -1.414667413793103 ], [ 29.773236075949367, -1.414397931034483 ], [ 29.772966582278482, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5089, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.414397931034483 ], [ 29.773236075949367, -1.414667413793103 ], [ 29.773505569620252, -1.414667413793103 ], [ 29.773505569620252, -1.414397931034483 ], [ 29.773236075949367, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5090, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.414397931034483 ], [ 29.773505569620252, -1.414667413793103 ], [ 29.77377506329114, -1.414667413793103 ], [ 29.77377506329114, -1.414397931034483 ], [ 29.773505569620252, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5091, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.414397931034483 ], [ 29.77377506329114, -1.414667413793103 ], [ 29.774044556962025, -1.414667413793103 ], [ 29.774044556962025, -1.414397931034483 ], [ 29.77377506329114, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5092, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.414397931034483 ], [ 29.774044556962025, -1.414667413793103 ], [ 29.77431405063291, -1.414667413793103 ], [ 29.77431405063291, -1.414397931034483 ], [ 29.774044556962025, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5093, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.414397931034483 ], [ 29.77431405063291, -1.414936896551724 ], [ 29.774853037974683, -1.414936896551724 ], [ 29.774853037974683, -1.414397931034483 ], [ 29.77431405063291, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5094, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.414397931034483 ], [ 29.774853037974683, -1.415206379310345 ], [ 29.775122531645568, -1.415206379310345 ], [ 29.775122531645568, -1.414397931034483 ], [ 29.774853037974683, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5095, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.414397931034483 ], [ 29.775122531645568, -1.414667413793103 ], [ 29.775392025316457, -1.414667413793103 ], [ 29.775392025316457, -1.414397931034483 ], [ 29.775122531645568, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5096, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.414397931034483 ], [ 29.775392025316457, -1.415206379310345 ], [ 29.775661518987341, -1.415206379310345 ], [ 29.775661518987341, -1.414397931034483 ], [ 29.775392025316457, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5097, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.414397931034483 ], [ 29.775661518987341, -1.415206379310345 ], [ 29.775931012658226, -1.415206379310345 ], [ 29.775931012658226, -1.414397931034483 ], [ 29.775661518987341, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5098, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.414397931034483 ], [ 29.780512405063291, -1.414936896551724 ], [ 29.780781898734176, -1.414936896551724 ], [ 29.780781898734176, -1.414397931034483 ], [ 29.780512405063291, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5099, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.414397931034483 ], [ 29.781859873417723, -1.414936896551724 ], [ 29.782129367088608, -1.414936896551724 ], [ 29.782129367088608, -1.414397931034483 ], [ 29.781859873417723, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5100, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.414397931034483 ], [ 29.782668354430381, -1.414667413793103 ], [ 29.782937848101266, -1.414667413793103 ], [ 29.782937848101266, -1.414397931034483 ], [ 29.782668354430381, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5101, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.414397931034483 ], [ 29.782937848101266, -1.414667413793103 ], [ 29.783207341772151, -1.414667413793103 ], [ 29.783207341772151, -1.414397931034483 ], [ 29.782937848101266, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5102, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.414397931034483 ], [ 29.783207341772151, -1.414667413793103 ], [ 29.783476835443039, -1.414667413793103 ], [ 29.783476835443039, -1.414397931034483 ], [ 29.783207341772151, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5103, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.414397931034483 ], [ 29.783476835443039, -1.415206379310345 ], [ 29.784015822784809, -1.415206379310345 ], [ 29.784015822784809, -1.414397931034483 ], [ 29.783476835443039, -1.414397931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5104, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.414667413793103 ], [ 29.756257974683542, -1.414936896551724 ], [ 29.756796962025316, -1.414936896551724 ], [ 29.756796962025316, -1.414667413793103 ], [ 29.756257974683542, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5105, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.414667413793103 ], [ 29.756796962025316, -1.414936896551724 ], [ 29.757066455696201, -1.414936896551724 ], [ 29.757066455696201, -1.414667413793103 ], [ 29.756796962025316, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5106, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.414667413793103 ], [ 29.757066455696201, -1.414936896551724 ], [ 29.757335949367089, -1.414936896551724 ], [ 29.757335949367089, -1.414667413793103 ], [ 29.757066455696201, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5107, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.414667413793103 ], [ 29.757335949367089, -1.414936896551724 ], [ 29.757605443037974, -1.414936896551724 ], [ 29.757605443037974, -1.414667413793103 ], [ 29.757335949367089, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5108, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.414667413793103 ], [ 29.757605443037974, -1.414936896551724 ], [ 29.757874936708859, -1.414936896551724 ], [ 29.757874936708859, -1.414667413793103 ], [ 29.757605443037974, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5109, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.414667413793103 ], [ 29.757874936708859, -1.414936896551724 ], [ 29.758144430379748, -1.414936896551724 ], [ 29.758144430379748, -1.414667413793103 ], [ 29.757874936708859, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5110, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.414667413793103 ], [ 29.758144430379748, -1.414936896551724 ], [ 29.758413924050632, -1.414936896551724 ], [ 29.758413924050632, -1.414667413793103 ], [ 29.758144430379748, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5111, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.414667413793103 ], [ 29.758413924050632, -1.414936896551724 ], [ 29.758683417721517, -1.414936896551724 ], [ 29.758683417721517, -1.414667413793103 ], [ 29.758413924050632, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5112, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.414667413793103 ], [ 29.758683417721517, -1.414936896551724 ], [ 29.758952911392406, -1.414936896551724 ], [ 29.758952911392406, -1.414667413793103 ], [ 29.758683417721517, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5113, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.414667413793103 ], [ 29.759491898734176, -1.414936896551724 ], [ 29.759761392405064, -1.414936896551724 ], [ 29.759761392405064, -1.414667413793103 ], [ 29.759491898734176, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5114, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.414667413793103 ], [ 29.759761392405064, -1.414936896551724 ], [ 29.760030886075949, -1.414936896551724 ], [ 29.760030886075949, -1.414667413793103 ], [ 29.759761392405064, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5115, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.414667413793103 ], [ 29.760030886075949, -1.414936896551724 ], [ 29.760300379746834, -1.414936896551724 ], [ 29.760300379746834, -1.414667413793103 ], [ 29.760030886075949, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5116, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.414667413793103 ], [ 29.760300379746834, -1.414936896551724 ], [ 29.760569873417722, -1.414936896551724 ], [ 29.760569873417722, -1.414667413793103 ], [ 29.760300379746834, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5117, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.414667413793103 ], [ 29.760569873417722, -1.414936896551724 ], [ 29.760839367088607, -1.414936896551724 ], [ 29.760839367088607, -1.414667413793103 ], [ 29.760569873417722, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5118, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.414667413793103 ], [ 29.760839367088607, -1.414936896551724 ], [ 29.761378354430381, -1.414936896551724 ], [ 29.761378354430381, -1.414667413793103 ], [ 29.760839367088607, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5119, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.414667413793103 ], [ 29.761378354430381, -1.414936896551724 ], [ 29.761647848101266, -1.414936896551724 ], [ 29.761647848101266, -1.414667413793103 ], [ 29.761378354430381, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5120, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.414667413793103 ], [ 29.761647848101266, -1.414936896551724 ], [ 29.762725822784809, -1.414936896551724 ], [ 29.762725822784809, -1.414667413793103 ], [ 29.761647848101266, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5121, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.414667413793103 ], [ 29.762725822784809, -1.414936896551724 ], [ 29.762995316455697, -1.414936896551724 ], [ 29.762995316455697, -1.414667413793103 ], [ 29.762725822784809, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5122, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.414667413793103 ], [ 29.762995316455697, -1.414936896551724 ], [ 29.763264810126582, -1.414936896551724 ], [ 29.763264810126582, -1.414667413793103 ], [ 29.762995316455697, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5123, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.414667413793103 ], [ 29.763264810126582, -1.414936896551724 ], [ 29.763534303797467, -1.414936896551724 ], [ 29.763534303797467, -1.414667413793103 ], [ 29.763264810126582, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5124, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.414667413793103 ], [ 29.763534303797467, -1.414936896551724 ], [ 29.763803797468356, -1.414936896551724 ], [ 29.763803797468356, -1.414667413793103 ], [ 29.763534303797467, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5125, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.414667413793103 ], [ 29.763803797468356, -1.414936896551724 ], [ 29.76407329113924, -1.414936896551724 ], [ 29.76407329113924, -1.414667413793103 ], [ 29.763803797468356, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5126, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.414667413793103 ], [ 29.76407329113924, -1.414936896551724 ], [ 29.764342784810125, -1.414936896551724 ], [ 29.764342784810125, -1.414667413793103 ], [ 29.76407329113924, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5127, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.414667413793103 ], [ 29.764342784810125, -1.414936896551724 ], [ 29.764612278481014, -1.414936896551724 ], [ 29.764612278481014, -1.414667413793103 ], [ 29.764342784810125, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5128, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.414667413793103 ], [ 29.764612278481014, -1.414936896551724 ], [ 29.764881772151899, -1.414936896551724 ], [ 29.764881772151899, -1.414667413793103 ], [ 29.764612278481014, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5129, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.414667413793103 ], [ 29.764881772151899, -1.414936896551724 ], [ 29.765151265822784, -1.414936896551724 ], [ 29.765151265822784, -1.414667413793103 ], [ 29.764881772151899, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5130, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.414667413793103 ], [ 29.765151265822784, -1.414936896551724 ], [ 29.765420759493672, -1.414936896551724 ], [ 29.765420759493672, -1.414667413793103 ], [ 29.765151265822784, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5131, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.414667413793103 ], [ 29.765420759493672, -1.414936896551724 ], [ 29.765959746835442, -1.414936896551724 ], [ 29.765959746835442, -1.414667413793103 ], [ 29.765420759493672, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5132, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.414667413793103 ], [ 29.765959746835442, -1.414936896551724 ], [ 29.766229240506327, -1.414936896551724 ], [ 29.766229240506327, -1.414667413793103 ], [ 29.765959746835442, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5133, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.414667413793103 ], [ 29.766498734177215, -1.414936896551724 ], [ 29.7667682278481, -1.414936896551724 ], [ 29.7667682278481, -1.414667413793103 ], [ 29.766498734177215, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5134, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.414667413793103 ], [ 29.7667682278481, -1.414936896551724 ], [ 29.767037721518985, -1.414936896551724 ], [ 29.767037721518985, -1.414667413793103 ], [ 29.7667682278481, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5135, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.414667413793103 ], [ 29.767307215189874, -1.414936896551724 ], [ 29.767576708860759, -1.414936896551724 ], [ 29.767576708860759, -1.414667413793103 ], [ 29.767307215189874, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5136, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.414667413793103 ], [ 29.767576708860759, -1.414936896551724 ], [ 29.767846202531643, -1.414936896551724 ], [ 29.767846202531643, -1.414667413793103 ], [ 29.767576708860759, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5137, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.414667413793103 ], [ 29.768115696202532, -1.415206379310345 ], [ 29.768385189873417, -1.415206379310345 ], [ 29.768385189873417, -1.414667413793103 ], [ 29.768115696202532, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5138, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.414667413793103 ], [ 29.768385189873417, -1.414936896551724 ], [ 29.768654683544302, -1.414936896551724 ], [ 29.768654683544302, -1.414667413793103 ], [ 29.768385189873417, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5139, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.414667413793103 ], [ 29.76892417721519, -1.415206379310345 ], [ 29.769193670886075, -1.415206379310345 ], [ 29.769193670886075, -1.414667413793103 ], [ 29.76892417721519, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5140, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.414667413793103 ], [ 29.769732658227849, -1.415206379310345 ], [ 29.770002151898733, -1.415206379310345 ], [ 29.770002151898733, -1.414667413793103 ], [ 29.769732658227849, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5141, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.414667413793103 ], [ 29.770271645569618, -1.415206379310345 ], [ 29.770541139240507, -1.415206379310345 ], [ 29.770541139240507, -1.414667413793103 ], [ 29.770271645569618, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5142, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.414667413793103 ], [ 29.770541139240507, -1.415206379310345 ], [ 29.770810632911392, -1.415206379310345 ], [ 29.770810632911392, -1.414667413793103 ], [ 29.770541139240507, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5143, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.414667413793103 ], [ 29.771080126582277, -1.415206379310345 ], [ 29.771349620253165, -1.415206379310345 ], [ 29.771349620253165, -1.414667413793103 ], [ 29.771080126582277, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5144, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.414667413793103 ], [ 29.771888607594935, -1.415206379310345 ], [ 29.772158101265823, -1.415206379310345 ], [ 29.772158101265823, -1.414667413793103 ], [ 29.771888607594935, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5145, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.414667413793103 ], [ 29.772427594936708, -1.415475862068966 ], [ 29.772697088607593, -1.415475862068966 ], [ 29.772697088607593, -1.414667413793103 ], [ 29.772427594936708, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5146, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.414667413793103 ], [ 29.772966582278482, -1.414936896551724 ], [ 29.773236075949367, -1.414936896551724 ], [ 29.773236075949367, -1.414667413793103 ], [ 29.772966582278482, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5147, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.414667413793103 ], [ 29.773236075949367, -1.415206379310345 ], [ 29.773505569620252, -1.415206379310345 ], [ 29.773505569620252, -1.414667413793103 ], [ 29.773236075949367, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5148, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.414667413793103 ], [ 29.773505569620252, -1.415475862068966 ], [ 29.77377506329114, -1.415475862068966 ], [ 29.77377506329114, -1.414667413793103 ], [ 29.773505569620252, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5149, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.414667413793103 ], [ 29.77377506329114, -1.415206379310345 ], [ 29.774044556962025, -1.415206379310345 ], [ 29.774044556962025, -1.414667413793103 ], [ 29.77377506329114, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5150, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.414667413793103 ], [ 29.774044556962025, -1.415475862068966 ], [ 29.77431405063291, -1.415475862068966 ], [ 29.77431405063291, -1.414667413793103 ], [ 29.774044556962025, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5151, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.414667413793103 ], [ 29.775122531645568, -1.415475862068966 ], [ 29.775392025316457, -1.415475862068966 ], [ 29.775392025316457, -1.414667413793103 ], [ 29.775122531645568, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5152, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.414667413793103 ], [ 29.77647, -1.415475862068966 ], [ 29.776739493670885, -1.415475862068966 ], [ 29.776739493670885, -1.414667413793103 ], [ 29.77647, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5153, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.414667413793103 ], [ 29.777817468354431, -1.415206379310345 ], [ 29.778086962025316, -1.415206379310345 ], [ 29.778086962025316, -1.414667413793103 ], [ 29.777817468354431, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5154, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.414667413793103 ], [ 29.77862594936709, -1.415206379310345 ], [ 29.778895443037975, -1.415206379310345 ], [ 29.778895443037975, -1.414667413793103 ], [ 29.77862594936709, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5155, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.414667413793103 ], [ 29.77916493670886, -1.415206379310345 ], [ 29.779434430379748, -1.415206379310345 ], [ 29.779434430379748, -1.414667413793103 ], [ 29.77916493670886, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5156, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.414667413793103 ], [ 29.779973417721518, -1.415206379310345 ], [ 29.780242911392406, -1.415206379310345 ], [ 29.780242911392406, -1.414667413793103 ], [ 29.779973417721518, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5157, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.414667413793103 ], [ 29.780781898734176, -1.415206379310345 ], [ 29.781051392405065, -1.415206379310345 ], [ 29.781051392405065, -1.414667413793103 ], [ 29.780781898734176, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5158, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.414667413793103 ], [ 29.78132088607595, -1.414936896551724 ], [ 29.781590379746834, -1.414936896551724 ], [ 29.781590379746834, -1.414667413793103 ], [ 29.78132088607595, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5159, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.414667413793103 ], [ 29.781590379746834, -1.414936896551724 ], [ 29.781859873417723, -1.414936896551724 ], [ 29.781859873417723, -1.414667413793103 ], [ 29.781590379746834, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5160, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.414667413793103 ], [ 29.782129367088608, -1.414936896551724 ], [ 29.782398860759493, -1.414936896551724 ], [ 29.782398860759493, -1.414667413793103 ], [ 29.782129367088608, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5161, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.414667413793103 ], [ 29.782398860759493, -1.414936896551724 ], [ 29.782668354430381, -1.414936896551724 ], [ 29.782668354430381, -1.414667413793103 ], [ 29.782398860759493, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5162, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.414667413793103 ], [ 29.782668354430381, -1.414936896551724 ], [ 29.782937848101266, -1.414936896551724 ], [ 29.782937848101266, -1.414667413793103 ], [ 29.782668354430381, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5163, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.414667413793103 ], [ 29.782937848101266, -1.415206379310345 ], [ 29.783207341772151, -1.415206379310345 ], [ 29.783207341772151, -1.414667413793103 ], [ 29.782937848101266, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5164, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.414667413793103 ], [ 29.783207341772151, -1.415206379310345 ], [ 29.783476835443039, -1.415206379310345 ], [ 29.783476835443039, -1.414667413793103 ], [ 29.783207341772151, -1.414667413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5165, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.414936896551724 ], [ 29.756796962025316, -1.415206379310345 ], [ 29.757066455696201, -1.415206379310345 ], [ 29.757066455696201, -1.414936896551724 ], [ 29.756796962025316, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5166, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.414936896551724 ], [ 29.757066455696201, -1.415206379310345 ], [ 29.757335949367089, -1.415206379310345 ], [ 29.757335949367089, -1.414936896551724 ], [ 29.757066455696201, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5167, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.414936896551724 ], [ 29.757335949367089, -1.415206379310345 ], [ 29.757605443037974, -1.415206379310345 ], [ 29.757605443037974, -1.414936896551724 ], [ 29.757335949367089, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5168, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.414936896551724 ], [ 29.757605443037974, -1.415206379310345 ], [ 29.757874936708859, -1.415206379310345 ], [ 29.757874936708859, -1.414936896551724 ], [ 29.757605443037974, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5169, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.414936896551724 ], [ 29.757874936708859, -1.415206379310345 ], [ 29.758144430379748, -1.415206379310345 ], [ 29.758144430379748, -1.414936896551724 ], [ 29.757874936708859, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5170, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.414936896551724 ], [ 29.758144430379748, -1.415206379310345 ], [ 29.758413924050632, -1.415206379310345 ], [ 29.758413924050632, -1.414936896551724 ], [ 29.758144430379748, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5171, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.414936896551724 ], [ 29.758413924050632, -1.415206379310345 ], [ 29.758683417721517, -1.415206379310345 ], [ 29.758683417721517, -1.414936896551724 ], [ 29.758413924050632, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5172, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.414936896551724 ], [ 29.758683417721517, -1.415206379310345 ], [ 29.758952911392406, -1.415206379310345 ], [ 29.758952911392406, -1.414936896551724 ], [ 29.758683417721517, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5173, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.414936896551724 ], [ 29.758952911392406, -1.415206379310345 ], [ 29.759222405063291, -1.415206379310345 ], [ 29.759222405063291, -1.414936896551724 ], [ 29.758952911392406, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5174, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.414936896551724 ], [ 29.759491898734176, -1.415206379310345 ], [ 29.759761392405064, -1.415206379310345 ], [ 29.759761392405064, -1.414936896551724 ], [ 29.759491898734176, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5175, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.414936896551724 ], [ 29.759761392405064, -1.415206379310345 ], [ 29.760030886075949, -1.415206379310345 ], [ 29.760030886075949, -1.414936896551724 ], [ 29.759761392405064, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5176, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.414936896551724 ], [ 29.760030886075949, -1.415206379310345 ], [ 29.760300379746834, -1.415206379310345 ], [ 29.760300379746834, -1.414936896551724 ], [ 29.760030886075949, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5177, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.414936896551724 ], [ 29.760300379746834, -1.415206379310345 ], [ 29.760569873417722, -1.415206379310345 ], [ 29.760569873417722, -1.414936896551724 ], [ 29.760300379746834, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5178, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.414936896551724 ], [ 29.760569873417722, -1.415206379310345 ], [ 29.761108860759492, -1.415206379310345 ], [ 29.761108860759492, -1.414936896551724 ], [ 29.760569873417722, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5179, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.414936896551724 ], [ 29.761108860759492, -1.415206379310345 ], [ 29.761378354430381, -1.415206379310345 ], [ 29.761378354430381, -1.414936896551724 ], [ 29.761108860759492, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5180, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.414936896551724 ], [ 29.761378354430381, -1.415206379310345 ], [ 29.761647848101266, -1.415206379310345 ], [ 29.761647848101266, -1.414936896551724 ], [ 29.761378354430381, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5181, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.414936896551724 ], [ 29.761647848101266, -1.415206379310345 ], [ 29.762725822784809, -1.415206379310345 ], [ 29.762725822784809, -1.414936896551724 ], [ 29.761647848101266, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5182, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.414936896551724 ], [ 29.762725822784809, -1.415206379310345 ], [ 29.762995316455697, -1.415206379310345 ], [ 29.762995316455697, -1.414936896551724 ], [ 29.762725822784809, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5183, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.414936896551724 ], [ 29.762995316455697, -1.415206379310345 ], [ 29.763264810126582, -1.415206379310345 ], [ 29.763264810126582, -1.414936896551724 ], [ 29.762995316455697, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5184, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.414936896551724 ], [ 29.763264810126582, -1.415206379310345 ], [ 29.763534303797467, -1.415206379310345 ], [ 29.763534303797467, -1.414936896551724 ], [ 29.763264810126582, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5185, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.414936896551724 ], [ 29.763534303797467, -1.415206379310345 ], [ 29.763803797468356, -1.415206379310345 ], [ 29.763803797468356, -1.414936896551724 ], [ 29.763534303797467, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5186, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.414936896551724 ], [ 29.763803797468356, -1.415206379310345 ], [ 29.76407329113924, -1.415206379310345 ], [ 29.76407329113924, -1.414936896551724 ], [ 29.763803797468356, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5187, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.414936896551724 ], [ 29.76407329113924, -1.415206379310345 ], [ 29.764342784810125, -1.415206379310345 ], [ 29.764342784810125, -1.414936896551724 ], [ 29.76407329113924, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5188, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.414936896551724 ], [ 29.764342784810125, -1.415206379310345 ], [ 29.764612278481014, -1.415206379310345 ], [ 29.764612278481014, -1.414936896551724 ], [ 29.764342784810125, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5189, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.414936896551724 ], [ 29.764612278481014, -1.415206379310345 ], [ 29.764881772151899, -1.415206379310345 ], [ 29.764881772151899, -1.414936896551724 ], [ 29.764612278481014, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5190, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.414936896551724 ], [ 29.764881772151899, -1.415206379310345 ], [ 29.765151265822784, -1.415206379310345 ], [ 29.765151265822784, -1.414936896551724 ], [ 29.764881772151899, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5191, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.414936896551724 ], [ 29.765151265822784, -1.415206379310345 ], [ 29.765420759493672, -1.415206379310345 ], [ 29.765420759493672, -1.414936896551724 ], [ 29.765151265822784, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5192, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.414936896551724 ], [ 29.765420759493672, -1.415206379310345 ], [ 29.765959746835442, -1.415206379310345 ], [ 29.765959746835442, -1.414936896551724 ], [ 29.765420759493672, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5193, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.414936896551724 ], [ 29.765959746835442, -1.415206379310345 ], [ 29.766229240506327, -1.415206379310345 ], [ 29.766229240506327, -1.414936896551724 ], [ 29.765959746835442, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5194, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.414936896551724 ], [ 29.766229240506327, -1.415206379310345 ], [ 29.766498734177215, -1.415206379310345 ], [ 29.766498734177215, -1.414936896551724 ], [ 29.766229240506327, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5195, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.414936896551724 ], [ 29.766498734177215, -1.415206379310345 ], [ 29.7667682278481, -1.415206379310345 ], [ 29.7667682278481, -1.414936896551724 ], [ 29.766498734177215, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5196, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.414936896551724 ], [ 29.7667682278481, -1.415206379310345 ], [ 29.767037721518985, -1.415206379310345 ], [ 29.767037721518985, -1.414936896551724 ], [ 29.7667682278481, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5197, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.414936896551724 ], [ 29.767037721518985, -1.415206379310345 ], [ 29.767307215189874, -1.415206379310345 ], [ 29.767307215189874, -1.414936896551724 ], [ 29.767037721518985, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5198, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.414936896551724 ], [ 29.767307215189874, -1.415475862068966 ], [ 29.767576708860759, -1.415475862068966 ], [ 29.767576708860759, -1.414936896551724 ], [ 29.767307215189874, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5199, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.414936896551724 ], [ 29.767576708860759, -1.415206379310345 ], [ 29.767846202531643, -1.415206379310345 ], [ 29.767846202531643, -1.414936896551724 ], [ 29.767576708860759, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5200, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.414936896551724 ], [ 29.767846202531643, -1.415206379310345 ], [ 29.768115696202532, -1.415206379310345 ], [ 29.768115696202532, -1.414936896551724 ], [ 29.767846202531643, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5201, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.414936896551724 ], [ 29.768385189873417, -1.415206379310345 ], [ 29.768654683544302, -1.415206379310345 ], [ 29.768654683544302, -1.414936896551724 ], [ 29.768385189873417, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5202, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.414936896551724 ], [ 29.768654683544302, -1.415206379310345 ], [ 29.76892417721519, -1.415206379310345 ], [ 29.76892417721519, -1.414936896551724 ], [ 29.768654683544302, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5203, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.414936896551724 ], [ 29.769193670886075, -1.415475862068966 ], [ 29.76946316455696, -1.415475862068966 ], [ 29.76946316455696, -1.414936896551724 ], [ 29.769193670886075, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5204, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.414936896551724 ], [ 29.76946316455696, -1.415206379310345 ], [ 29.769732658227849, -1.415206379310345 ], [ 29.769732658227849, -1.414936896551724 ], [ 29.76946316455696, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5205, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.414936896551724 ], [ 29.770002151898733, -1.415475862068966 ], [ 29.770271645569618, -1.415475862068966 ], [ 29.770271645569618, -1.414936896551724 ], [ 29.770002151898733, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5206, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.414936896551724 ], [ 29.770810632911392, -1.415475862068966 ], [ 29.771080126582277, -1.415475862068966 ], [ 29.771080126582277, -1.414936896551724 ], [ 29.770810632911392, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5207, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.414936896551724 ], [ 29.77161911392405, -1.415475862068966 ], [ 29.771888607594935, -1.415475862068966 ], [ 29.771888607594935, -1.414936896551724 ], [ 29.77161911392405, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5208, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.414936896551724 ], [ 29.772158101265823, -1.415475862068966 ], [ 29.772427594936708, -1.415475862068966 ], [ 29.772427594936708, -1.414936896551724 ], [ 29.772158101265823, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5209, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.414936896551724 ], [ 29.77431405063291, -1.415745344827586 ], [ 29.774853037974683, -1.415745344827586 ], [ 29.774853037974683, -1.414936896551724 ], [ 29.77431405063291, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5210, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.414936896551724 ], [ 29.775931012658226, -1.415475862068966 ], [ 29.776200506329115, -1.415475862068966 ], [ 29.776200506329115, -1.414936896551724 ], [ 29.775931012658226, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5211, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.414936896551724 ], [ 29.776739493670885, -1.415475862068966 ], [ 29.777008987341773, -1.415475862068966 ], [ 29.777008987341773, -1.414936896551724 ], [ 29.776739493670885, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5212, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.414936896551724 ], [ 29.777278481012658, -1.415475862068966 ], [ 29.777547974683543, -1.415475862068966 ], [ 29.777547974683543, -1.414936896551724 ], [ 29.777278481012658, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5213, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.414936896551724 ], [ 29.778086962025316, -1.415475862068966 ], [ 29.778356455696201, -1.415475862068966 ], [ 29.778356455696201, -1.414936896551724 ], [ 29.778086962025316, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5214, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.414936896551724 ], [ 29.778895443037975, -1.415475862068966 ], [ 29.77916493670886, -1.415475862068966 ], [ 29.77916493670886, -1.414936896551724 ], [ 29.778895443037975, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5215, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.414936896551724 ], [ 29.779434430379748, -1.415206379310345 ], [ 29.779703924050633, -1.415206379310345 ], [ 29.779703924050633, -1.414936896551724 ], [ 29.779434430379748, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5216, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.414936896551724 ], [ 29.779703924050633, -1.415475862068966 ], [ 29.779973417721518, -1.415475862068966 ], [ 29.779973417721518, -1.414936896551724 ], [ 29.779703924050633, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5217, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.414936896551724 ], [ 29.780242911392406, -1.415206379310345 ], [ 29.780512405063291, -1.415206379310345 ], [ 29.780512405063291, -1.414936896551724 ], [ 29.780242911392406, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5218, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.414936896551724 ], [ 29.780512405063291, -1.415206379310345 ], [ 29.780781898734176, -1.415206379310345 ], [ 29.780781898734176, -1.414936896551724 ], [ 29.780512405063291, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5219, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.414936896551724 ], [ 29.781051392405065, -1.415206379310345 ], [ 29.78132088607595, -1.415206379310345 ], [ 29.78132088607595, -1.414936896551724 ], [ 29.781051392405065, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5220, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.414936896551724 ], [ 29.78132088607595, -1.415206379310345 ], [ 29.781590379746834, -1.415206379310345 ], [ 29.781590379746834, -1.414936896551724 ], [ 29.78132088607595, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5221, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.414936896551724 ], [ 29.781590379746834, -1.415206379310345 ], [ 29.781859873417723, -1.415206379310345 ], [ 29.781859873417723, -1.414936896551724 ], [ 29.781590379746834, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5222, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.414936896551724 ], [ 29.781859873417723, -1.415206379310345 ], [ 29.782129367088608, -1.415206379310345 ], [ 29.782129367088608, -1.414936896551724 ], [ 29.781859873417723, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5223, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.414936896551724 ], [ 29.782129367088608, -1.415206379310345 ], [ 29.782398860759493, -1.415206379310345 ], [ 29.782398860759493, -1.414936896551724 ], [ 29.782129367088608, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5224, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.414936896551724 ], [ 29.782398860759493, -1.415475862068966 ], [ 29.782668354430381, -1.415475862068966 ], [ 29.782668354430381, -1.414936896551724 ], [ 29.782398860759493, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5225, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.414936896551724 ], [ 29.782668354430381, -1.415475862068966 ], [ 29.782937848101266, -1.415475862068966 ], [ 29.782937848101266, -1.414936896551724 ], [ 29.782668354430381, -1.414936896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5226, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.415206379310345 ], [ 29.757066455696201, -1.415745344827586 ], [ 29.757335949367089, -1.415745344827586 ], [ 29.757335949367089, -1.415206379310345 ], [ 29.757066455696201, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5227, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.415206379310345 ], [ 29.757335949367089, -1.415475862068966 ], [ 29.757605443037974, -1.415475862068966 ], [ 29.757605443037974, -1.415206379310345 ], [ 29.757335949367089, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5228, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.415206379310345 ], [ 29.757605443037974, -1.415475862068966 ], [ 29.757874936708859, -1.415475862068966 ], [ 29.757874936708859, -1.415206379310345 ], [ 29.757605443037974, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5229, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.415206379310345 ], [ 29.757874936708859, -1.415475862068966 ], [ 29.758144430379748, -1.415475862068966 ], [ 29.758144430379748, -1.415206379310345 ], [ 29.757874936708859, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5230, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.415206379310345 ], [ 29.758144430379748, -1.415475862068966 ], [ 29.758413924050632, -1.415475862068966 ], [ 29.758413924050632, -1.415206379310345 ], [ 29.758144430379748, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5231, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.415206379310345 ], [ 29.758413924050632, -1.415475862068966 ], [ 29.758683417721517, -1.415475862068966 ], [ 29.758683417721517, -1.415206379310345 ], [ 29.758413924050632, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5232, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.415206379310345 ], [ 29.758683417721517, -1.415475862068966 ], [ 29.758952911392406, -1.415475862068966 ], [ 29.758952911392406, -1.415206379310345 ], [ 29.758683417721517, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5233, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.415206379310345 ], [ 29.758952911392406, -1.415475862068966 ], [ 29.759222405063291, -1.415475862068966 ], [ 29.759222405063291, -1.415206379310345 ], [ 29.758952911392406, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5234, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.415206379310345 ], [ 29.759222405063291, -1.415475862068966 ], [ 29.759491898734176, -1.415475862068966 ], [ 29.759491898734176, -1.415206379310345 ], [ 29.759222405063291, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5235, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.415206379310345 ], [ 29.759491898734176, -1.415475862068966 ], [ 29.759761392405064, -1.415475862068966 ], [ 29.759761392405064, -1.415206379310345 ], [ 29.759491898734176, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5236, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.415206379310345 ], [ 29.759761392405064, -1.415745344827586 ], [ 29.760030886075949, -1.415745344827586 ], [ 29.760030886075949, -1.415206379310345 ], [ 29.759761392405064, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5237, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.415206379310345 ], [ 29.760030886075949, -1.415475862068966 ], [ 29.760300379746834, -1.415475862068966 ], [ 29.760300379746834, -1.415206379310345 ], [ 29.760030886075949, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5238, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.415206379310345 ], [ 29.760300379746834, -1.415475862068966 ], [ 29.760569873417722, -1.415475862068966 ], [ 29.760569873417722, -1.415206379310345 ], [ 29.760300379746834, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5239, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.415206379310345 ], [ 29.760569873417722, -1.415475862068966 ], [ 29.760839367088607, -1.415475862068966 ], [ 29.760839367088607, -1.415206379310345 ], [ 29.760569873417722, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5240, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.415206379310345 ], [ 29.760839367088607, -1.415475862068966 ], [ 29.761108860759492, -1.415475862068966 ], [ 29.761108860759492, -1.415206379310345 ], [ 29.760839367088607, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5241, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.415206379310345 ], [ 29.761108860759492, -1.415475862068966 ], [ 29.761378354430381, -1.415475862068966 ], [ 29.761378354430381, -1.415206379310345 ], [ 29.761108860759492, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5242, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.415206379310345 ], [ 29.761378354430381, -1.415475862068966 ], [ 29.761917341772151, -1.415475862068966 ], [ 29.761917341772151, -1.415206379310345 ], [ 29.761378354430381, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5243, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.415206379310345 ], [ 29.761917341772151, -1.415475862068966 ], [ 29.762456329113924, -1.415475862068966 ], [ 29.762456329113924, -1.415206379310345 ], [ 29.761917341772151, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5244, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.415206379310345 ], [ 29.762456329113924, -1.415475862068966 ], [ 29.762995316455697, -1.415475862068966 ], [ 29.762995316455697, -1.415206379310345 ], [ 29.762456329113924, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5245, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.415206379310345 ], [ 29.762995316455697, -1.415475862068966 ], [ 29.763264810126582, -1.415475862068966 ], [ 29.763264810126582, -1.415206379310345 ], [ 29.762995316455697, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5246, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.415206379310345 ], [ 29.763264810126582, -1.415475862068966 ], [ 29.763534303797467, -1.415475862068966 ], [ 29.763534303797467, -1.415206379310345 ], [ 29.763264810126582, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5247, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.415206379310345 ], [ 29.763534303797467, -1.415475862068966 ], [ 29.763803797468356, -1.415475862068966 ], [ 29.763803797468356, -1.415206379310345 ], [ 29.763534303797467, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5248, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.415206379310345 ], [ 29.763803797468356, -1.415475862068966 ], [ 29.76407329113924, -1.415475862068966 ], [ 29.76407329113924, -1.415206379310345 ], [ 29.763803797468356, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5249, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.415206379310345 ], [ 29.76407329113924, -1.415475862068966 ], [ 29.764342784810125, -1.415475862068966 ], [ 29.764342784810125, -1.415206379310345 ], [ 29.76407329113924, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5250, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.415206379310345 ], [ 29.764342784810125, -1.415475862068966 ], [ 29.764612278481014, -1.415475862068966 ], [ 29.764612278481014, -1.415206379310345 ], [ 29.764342784810125, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5251, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.415206379310345 ], [ 29.764612278481014, -1.415475862068966 ], [ 29.764881772151899, -1.415475862068966 ], [ 29.764881772151899, -1.415206379310345 ], [ 29.764612278481014, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5252, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.415206379310345 ], [ 29.764881772151899, -1.415475862068966 ], [ 29.765151265822784, -1.415475862068966 ], [ 29.765151265822784, -1.415206379310345 ], [ 29.764881772151899, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5253, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.415206379310345 ], [ 29.765151265822784, -1.415475862068966 ], [ 29.765420759493672, -1.415475862068966 ], [ 29.765420759493672, -1.415206379310345 ], [ 29.765151265822784, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5254, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.415206379310345 ], [ 29.765420759493672, -1.415475862068966 ], [ 29.765959746835442, -1.415475862068966 ], [ 29.765959746835442, -1.415206379310345 ], [ 29.765420759493672, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5255, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.415206379310345 ], [ 29.765959746835442, -1.415475862068966 ], [ 29.766229240506327, -1.415475862068966 ], [ 29.766229240506327, -1.415206379310345 ], [ 29.765959746835442, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5256, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.415206379310345 ], [ 29.766229240506327, -1.415475862068966 ], [ 29.766498734177215, -1.415475862068966 ], [ 29.766498734177215, -1.415206379310345 ], [ 29.766229240506327, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5257, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.415206379310345 ], [ 29.766498734177215, -1.415475862068966 ], [ 29.7667682278481, -1.415475862068966 ], [ 29.7667682278481, -1.415206379310345 ], [ 29.766498734177215, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5258, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.415206379310345 ], [ 29.7667682278481, -1.415475862068966 ], [ 29.767037721518985, -1.415475862068966 ], [ 29.767037721518985, -1.415206379310345 ], [ 29.7667682278481, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5259, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.415206379310345 ], [ 29.767037721518985, -1.415475862068966 ], [ 29.767307215189874, -1.415475862068966 ], [ 29.767307215189874, -1.415206379310345 ], [ 29.767037721518985, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5260, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.415206379310345 ], [ 29.767576708860759, -1.415475862068966 ], [ 29.767846202531643, -1.415475862068966 ], [ 29.767846202531643, -1.415206379310345 ], [ 29.767576708860759, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5261, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.415206379310345 ], [ 29.767846202531643, -1.415475862068966 ], [ 29.768115696202532, -1.415475862068966 ], [ 29.768115696202532, -1.415206379310345 ], [ 29.767846202531643, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5262, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.415206379310345 ], [ 29.768115696202532, -1.415475862068966 ], [ 29.768385189873417, -1.415475862068966 ], [ 29.768385189873417, -1.415206379310345 ], [ 29.768115696202532, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5263, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.415206379310345 ], [ 29.768385189873417, -1.415745344827586 ], [ 29.768654683544302, -1.415745344827586 ], [ 29.768654683544302, -1.415206379310345 ], [ 29.768385189873417, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5264, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.415206379310345 ], [ 29.768654683544302, -1.415475862068966 ], [ 29.76892417721519, -1.415475862068966 ], [ 29.76892417721519, -1.415206379310345 ], [ 29.768654683544302, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5265, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.415206379310345 ], [ 29.76892417721519, -1.415475862068966 ], [ 29.769193670886075, -1.415475862068966 ], [ 29.769193670886075, -1.415206379310345 ], [ 29.76892417721519, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5266, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.415206379310345 ], [ 29.76946316455696, -1.415475862068966 ], [ 29.769732658227849, -1.415475862068966 ], [ 29.769732658227849, -1.415206379310345 ], [ 29.76946316455696, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5267, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.415206379310345 ], [ 29.769732658227849, -1.415475862068966 ], [ 29.770002151898733, -1.415475862068966 ], [ 29.770002151898733, -1.415206379310345 ], [ 29.769732658227849, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5268, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.415206379310345 ], [ 29.770271645569618, -1.415745344827586 ], [ 29.770541139240507, -1.415745344827586 ], [ 29.770541139240507, -1.415206379310345 ], [ 29.770271645569618, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5269, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.415206379310345 ], [ 29.770541139240507, -1.415475862068966 ], [ 29.770810632911392, -1.415475862068966 ], [ 29.770810632911392, -1.415206379310345 ], [ 29.770541139240507, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5270, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.415206379310345 ], [ 29.771080126582277, -1.415745344827586 ], [ 29.771349620253165, -1.415745344827586 ], [ 29.771349620253165, -1.415206379310345 ], [ 29.771080126582277, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5271, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.415206379310345 ], [ 29.771349620253165, -1.415475862068966 ], [ 29.77161911392405, -1.415475862068966 ], [ 29.77161911392405, -1.415206379310345 ], [ 29.771349620253165, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5272, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.415206379310345 ], [ 29.771888607594935, -1.415745344827586 ], [ 29.772158101265823, -1.415745344827586 ], [ 29.772158101265823, -1.415206379310345 ], [ 29.771888607594935, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5273, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.415206379310345 ], [ 29.772697088607593, -1.415745344827586 ], [ 29.772966582278482, -1.415745344827586 ], [ 29.772966582278482, -1.415206379310345 ], [ 29.772697088607593, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5274, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.415206379310345 ], [ 29.77377506329114, -1.415745344827586 ], [ 29.774044556962025, -1.415745344827586 ], [ 29.774044556962025, -1.415206379310345 ], [ 29.77377506329114, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5275, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.415206379310345 ], [ 29.774853037974683, -1.415745344827586 ], [ 29.775122531645568, -1.415745344827586 ], [ 29.775122531645568, -1.415206379310345 ], [ 29.774853037974683, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5276, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.415206379310345 ], [ 29.775392025316457, -1.415745344827586 ], [ 29.775661518987341, -1.415745344827586 ], [ 29.775661518987341, -1.415206379310345 ], [ 29.775392025316457, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5277, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.415206379310345 ], [ 29.775661518987341, -1.415745344827586 ], [ 29.775931012658226, -1.415745344827586 ], [ 29.775931012658226, -1.415206379310345 ], [ 29.775661518987341, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5278, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.415206379310345 ], [ 29.776200506329115, -1.415745344827586 ], [ 29.77647, -1.415745344827586 ], [ 29.77647, -1.415206379310345 ], [ 29.776200506329115, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5279, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.415206379310345 ], [ 29.777008987341773, -1.415745344827586 ], [ 29.777278481012658, -1.415745344827586 ], [ 29.777278481012658, -1.415206379310345 ], [ 29.777008987341773, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5280, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.415206379310345 ], [ 29.777547974683543, -1.415475862068966 ], [ 29.777817468354431, -1.415475862068966 ], [ 29.777817468354431, -1.415206379310345 ], [ 29.777547974683543, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5281, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.415206379310345 ], [ 29.777817468354431, -1.415745344827586 ], [ 29.778086962025316, -1.415745344827586 ], [ 29.778086962025316, -1.415206379310345 ], [ 29.777817468354431, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5282, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.415206379310345 ], [ 29.778356455696201, -1.415475862068966 ], [ 29.77862594936709, -1.415475862068966 ], [ 29.77862594936709, -1.415206379310345 ], [ 29.778356455696201, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5283, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.415206379310345 ], [ 29.77862594936709, -1.415475862068966 ], [ 29.778895443037975, -1.415475862068966 ], [ 29.778895443037975, -1.415206379310345 ], [ 29.77862594936709, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5284, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.415206379310345 ], [ 29.77916493670886, -1.415475862068966 ], [ 29.779434430379748, -1.415475862068966 ], [ 29.779434430379748, -1.415206379310345 ], [ 29.77916493670886, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5285, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.415206379310345 ], [ 29.779434430379748, -1.415475862068966 ], [ 29.779703924050633, -1.415475862068966 ], [ 29.779703924050633, -1.415206379310345 ], [ 29.779434430379748, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5286, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.415206379310345 ], [ 29.779973417721518, -1.415475862068966 ], [ 29.780242911392406, -1.415475862068966 ], [ 29.780242911392406, -1.415206379310345 ], [ 29.779973417721518, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5287, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.415206379310345 ], [ 29.780242911392406, -1.415475862068966 ], [ 29.780512405063291, -1.415475862068966 ], [ 29.780512405063291, -1.415206379310345 ], [ 29.780242911392406, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5288, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.415206379310345 ], [ 29.780512405063291, -1.415475862068966 ], [ 29.780781898734176, -1.415475862068966 ], [ 29.780781898734176, -1.415206379310345 ], [ 29.780512405063291, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5289, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.415206379310345 ], [ 29.780781898734176, -1.415475862068966 ], [ 29.781051392405065, -1.415475862068966 ], [ 29.781051392405065, -1.415206379310345 ], [ 29.780781898734176, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5290, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.415206379310345 ], [ 29.781051392405065, -1.415475862068966 ], [ 29.78132088607595, -1.415475862068966 ], [ 29.78132088607595, -1.415206379310345 ], [ 29.781051392405065, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5291, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.415206379310345 ], [ 29.78132088607595, -1.415475862068966 ], [ 29.781590379746834, -1.415475862068966 ], [ 29.781590379746834, -1.415206379310345 ], [ 29.78132088607595, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5292, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.415206379310345 ], [ 29.781590379746834, -1.415475862068966 ], [ 29.781859873417723, -1.415475862068966 ], [ 29.781859873417723, -1.415206379310345 ], [ 29.781590379746834, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5293, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.415206379310345 ], [ 29.781859873417723, -1.415475862068966 ], [ 29.782129367088608, -1.415475862068966 ], [ 29.782129367088608, -1.415206379310345 ], [ 29.781859873417723, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5294, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.415206379310345 ], [ 29.782129367088608, -1.415745344827586 ], [ 29.782398860759493, -1.415745344827586 ], [ 29.782398860759493, -1.415206379310345 ], [ 29.782129367088608, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5295, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.415206379310345 ], [ 29.782937848101266, -1.415475862068966 ], [ 29.783207341772151, -1.415475862068966 ], [ 29.783207341772151, -1.415206379310345 ], [ 29.782937848101266, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5296, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.415206379310345 ], [ 29.783207341772151, -1.415475862068966 ], [ 29.783476835443039, -1.415475862068966 ], [ 29.783476835443039, -1.415206379310345 ], [ 29.783207341772151, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5297, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.415206379310345 ], [ 29.783476835443039, -1.415475862068966 ], [ 29.784015822784809, -1.415475862068966 ], [ 29.784015822784809, -1.415206379310345 ], [ 29.783476835443039, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5298, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.415475862068966 ], [ 29.757335949367089, -1.415745344827586 ], [ 29.757605443037974, -1.415745344827586 ], [ 29.757605443037974, -1.415475862068966 ], [ 29.757335949367089, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5299, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.415475862068966 ], [ 29.757605443037974, -1.415745344827586 ], [ 29.757874936708859, -1.415745344827586 ], [ 29.757874936708859, -1.415475862068966 ], [ 29.757605443037974, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5300, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.415475862068966 ], [ 29.757874936708859, -1.415745344827586 ], [ 29.758144430379748, -1.415745344827586 ], [ 29.758144430379748, -1.415475862068966 ], [ 29.757874936708859, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5301, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.415475862068966 ], [ 29.758144430379748, -1.415745344827586 ], [ 29.758413924050632, -1.415745344827586 ], [ 29.758413924050632, -1.415475862068966 ], [ 29.758144430379748, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5302, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.415475862068966 ], [ 29.758413924050632, -1.415745344827586 ], [ 29.758683417721517, -1.415745344827586 ], [ 29.758683417721517, -1.415475862068966 ], [ 29.758413924050632, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5303, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.415475862068966 ], [ 29.758683417721517, -1.415745344827586 ], [ 29.758952911392406, -1.415745344827586 ], [ 29.758952911392406, -1.415475862068966 ], [ 29.758683417721517, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5304, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.415475862068966 ], [ 29.758952911392406, -1.415745344827586 ], [ 29.759222405063291, -1.415745344827586 ], [ 29.759222405063291, -1.415475862068966 ], [ 29.758952911392406, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5305, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.415475862068966 ], [ 29.759222405063291, -1.415745344827586 ], [ 29.759491898734176, -1.415745344827586 ], [ 29.759491898734176, -1.415475862068966 ], [ 29.759222405063291, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5306, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.415475862068966 ], [ 29.759491898734176, -1.415745344827586 ], [ 29.759761392405064, -1.415745344827586 ], [ 29.759761392405064, -1.415475862068966 ], [ 29.759491898734176, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5307, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.415475862068966 ], [ 29.760030886075949, -1.415745344827586 ], [ 29.760300379746834, -1.415745344827586 ], [ 29.760300379746834, -1.415475862068966 ], [ 29.760030886075949, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5308, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.415475862068966 ], [ 29.760300379746834, -1.415745344827586 ], [ 29.760569873417722, -1.415745344827586 ], [ 29.760569873417722, -1.415475862068966 ], [ 29.760300379746834, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5309, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.415475862068966 ], [ 29.760569873417722, -1.415745344827586 ], [ 29.760839367088607, -1.415745344827586 ], [ 29.760839367088607, -1.415475862068966 ], [ 29.760569873417722, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5310, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.415475862068966 ], [ 29.760839367088607, -1.415745344827586 ], [ 29.761108860759492, -1.415745344827586 ], [ 29.761108860759492, -1.415475862068966 ], [ 29.760839367088607, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5311, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.415475862068966 ], [ 29.761108860759492, -1.415745344827586 ], [ 29.761378354430381, -1.415745344827586 ], [ 29.761378354430381, -1.415475862068966 ], [ 29.761108860759492, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5312, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.415475862068966 ], [ 29.761378354430381, -1.415745344827586 ], [ 29.761647848101266, -1.415745344827586 ], [ 29.761647848101266, -1.415475862068966 ], [ 29.761378354430381, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5313, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.415475862068966 ], [ 29.761647848101266, -1.415745344827586 ], [ 29.762725822784809, -1.415745344827586 ], [ 29.762725822784809, -1.415475862068966 ], [ 29.761647848101266, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5314, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.415475862068966 ], [ 29.762725822784809, -1.415745344827586 ], [ 29.762995316455697, -1.415745344827586 ], [ 29.762995316455697, -1.415475862068966 ], [ 29.762725822784809, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5315, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.415475862068966 ], [ 29.762995316455697, -1.415745344827586 ], [ 29.763264810126582, -1.415745344827586 ], [ 29.763264810126582, -1.415475862068966 ], [ 29.762995316455697, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5316, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.415475862068966 ], [ 29.763264810126582, -1.415745344827586 ], [ 29.763534303797467, -1.415745344827586 ], [ 29.763534303797467, -1.415475862068966 ], [ 29.763264810126582, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5317, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.415475862068966 ], [ 29.763534303797467, -1.415745344827586 ], [ 29.763803797468356, -1.415745344827586 ], [ 29.763803797468356, -1.415475862068966 ], [ 29.763534303797467, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5318, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.415475862068966 ], [ 29.763803797468356, -1.415745344827586 ], [ 29.76407329113924, -1.415745344827586 ], [ 29.76407329113924, -1.415475862068966 ], [ 29.763803797468356, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5319, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.415475862068966 ], [ 29.76407329113924, -1.415745344827586 ], [ 29.764342784810125, -1.415745344827586 ], [ 29.764342784810125, -1.415475862068966 ], [ 29.76407329113924, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5320, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.415475862068966 ], [ 29.764342784810125, -1.415745344827586 ], [ 29.764612278481014, -1.415745344827586 ], [ 29.764612278481014, -1.415475862068966 ], [ 29.764342784810125, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5321, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.415475862068966 ], [ 29.764612278481014, -1.415745344827586 ], [ 29.764881772151899, -1.415745344827586 ], [ 29.764881772151899, -1.415475862068966 ], [ 29.764612278481014, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5322, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.415475862068966 ], [ 29.764881772151899, -1.415745344827586 ], [ 29.765151265822784, -1.415745344827586 ], [ 29.765151265822784, -1.415475862068966 ], [ 29.764881772151899, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5323, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.415475862068966 ], [ 29.765151265822784, -1.415745344827586 ], [ 29.765420759493672, -1.415745344827586 ], [ 29.765420759493672, -1.415475862068966 ], [ 29.765151265822784, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5324, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.415475862068966 ], [ 29.765420759493672, -1.415745344827586 ], [ 29.765959746835442, -1.415745344827586 ], [ 29.765959746835442, -1.415475862068966 ], [ 29.765420759493672, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5325, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.415475862068966 ], [ 29.765959746835442, -1.415745344827586 ], [ 29.766229240506327, -1.415745344827586 ], [ 29.766229240506327, -1.415475862068966 ], [ 29.765959746835442, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5326, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.415475862068966 ], [ 29.766229240506327, -1.415745344827586 ], [ 29.766498734177215, -1.415745344827586 ], [ 29.766498734177215, -1.415475862068966 ], [ 29.766229240506327, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5327, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.415475862068966 ], [ 29.766498734177215, -1.415745344827586 ], [ 29.7667682278481, -1.415745344827586 ], [ 29.7667682278481, -1.415475862068966 ], [ 29.766498734177215, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5328, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.415475862068966 ], [ 29.7667682278481, -1.415745344827586 ], [ 29.767037721518985, -1.415745344827586 ], [ 29.767037721518985, -1.415475862068966 ], [ 29.7667682278481, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5329, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.415475862068966 ], [ 29.767037721518985, -1.415745344827586 ], [ 29.767307215189874, -1.415745344827586 ], [ 29.767307215189874, -1.415475862068966 ], [ 29.767037721518985, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5330, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.415475862068966 ], [ 29.767307215189874, -1.415745344827586 ], [ 29.767576708860759, -1.415745344827586 ], [ 29.767576708860759, -1.415475862068966 ], [ 29.767307215189874, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5331, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.415475862068966 ], [ 29.767576708860759, -1.415745344827586 ], [ 29.767846202531643, -1.415745344827586 ], [ 29.767846202531643, -1.415475862068966 ], [ 29.767576708860759, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5332, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.415475862068966 ], [ 29.767846202531643, -1.415745344827586 ], [ 29.768115696202532, -1.415745344827586 ], [ 29.768115696202532, -1.415475862068966 ], [ 29.767846202531643, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5333, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.415475862068966 ], [ 29.768115696202532, -1.415745344827586 ], [ 29.768385189873417, -1.415745344827586 ], [ 29.768385189873417, -1.415475862068966 ], [ 29.768115696202532, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5334, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.415475862068966 ], [ 29.768654683544302, -1.415745344827586 ], [ 29.76892417721519, -1.415745344827586 ], [ 29.76892417721519, -1.415475862068966 ], [ 29.768654683544302, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5335, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.415475862068966 ], [ 29.76892417721519, -1.415745344827586 ], [ 29.769193670886075, -1.415745344827586 ], [ 29.769193670886075, -1.415475862068966 ], [ 29.76892417721519, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5336, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.415475862068966 ], [ 29.769193670886075, -1.415745344827586 ], [ 29.76946316455696, -1.415745344827586 ], [ 29.76946316455696, -1.415475862068966 ], [ 29.769193670886075, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5337, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.415475862068966 ], [ 29.769732658227849, -1.415745344827586 ], [ 29.770002151898733, -1.415745344827586 ], [ 29.770002151898733, -1.415475862068966 ], [ 29.769732658227849, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5338, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.415475862068966 ], [ 29.770002151898733, -1.415745344827586 ], [ 29.770271645569618, -1.415745344827586 ], [ 29.770271645569618, -1.415475862068966 ], [ 29.770002151898733, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5339, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.415475862068966 ], [ 29.770541139240507, -1.415745344827586 ], [ 29.770810632911392, -1.415745344827586 ], [ 29.770810632911392, -1.415475862068966 ], [ 29.770541139240507, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5340, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.415475862068966 ], [ 29.770810632911392, -1.415745344827586 ], [ 29.771080126582277, -1.415745344827586 ], [ 29.771080126582277, -1.415475862068966 ], [ 29.770810632911392, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5341, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.415475862068966 ], [ 29.77161911392405, -1.415745344827586 ], [ 29.771888607594935, -1.415745344827586 ], [ 29.771888607594935, -1.415475862068966 ], [ 29.77161911392405, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5342, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.415475862068966 ], [ 29.772427594936708, -1.415745344827586 ], [ 29.772697088607593, -1.415745344827586 ], [ 29.772697088607593, -1.415475862068966 ], [ 29.772427594936708, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5343, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.415475862068966 ], [ 29.77647, -1.415745344827586 ], [ 29.776739493670885, -1.415745344827586 ], [ 29.776739493670885, -1.415475862068966 ], [ 29.77647, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5344, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.415475862068966 ], [ 29.777278481012658, -1.415745344827586 ], [ 29.777547974683543, -1.415745344827586 ], [ 29.777547974683543, -1.415475862068966 ], [ 29.777278481012658, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5345, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.415475862068966 ], [ 29.777547974683543, -1.415745344827586 ], [ 29.777817468354431, -1.415745344827586 ], [ 29.777817468354431, -1.415475862068966 ], [ 29.777547974683543, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5346, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.415475862068966 ], [ 29.778086962025316, -1.415745344827586 ], [ 29.778356455696201, -1.415745344827586 ], [ 29.778356455696201, -1.415475862068966 ], [ 29.778086962025316, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5347, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.415475862068966 ], [ 29.778356455696201, -1.415745344827586 ], [ 29.77862594936709, -1.415745344827586 ], [ 29.77862594936709, -1.415475862068966 ], [ 29.778356455696201, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5348, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.415475862068966 ], [ 29.778895443037975, -1.415745344827586 ], [ 29.77916493670886, -1.415745344827586 ], [ 29.77916493670886, -1.415475862068966 ], [ 29.778895443037975, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5349, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.415475862068966 ], [ 29.77916493670886, -1.415745344827586 ], [ 29.779434430379748, -1.415745344827586 ], [ 29.779434430379748, -1.415475862068966 ], [ 29.77916493670886, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5350, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.415475862068966 ], [ 29.779434430379748, -1.415745344827586 ], [ 29.779703924050633, -1.415745344827586 ], [ 29.779703924050633, -1.415475862068966 ], [ 29.779434430379748, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5351, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.415475862068966 ], [ 29.779703924050633, -1.415745344827586 ], [ 29.779973417721518, -1.415745344827586 ], [ 29.779973417721518, -1.415475862068966 ], [ 29.779703924050633, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5352, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.415475862068966 ], [ 29.779973417721518, -1.415745344827586 ], [ 29.780242911392406, -1.415745344827586 ], [ 29.780242911392406, -1.415475862068966 ], [ 29.779973417721518, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5353, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.415475862068966 ], [ 29.780242911392406, -1.415745344827586 ], [ 29.780512405063291, -1.415745344827586 ], [ 29.780512405063291, -1.415475862068966 ], [ 29.780242911392406, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5354, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.415475862068966 ], [ 29.780512405063291, -1.415745344827586 ], [ 29.780781898734176, -1.415745344827586 ], [ 29.780781898734176, -1.415475862068966 ], [ 29.780512405063291, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5355, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.415475862068966 ], [ 29.780781898734176, -1.415745344827586 ], [ 29.781051392405065, -1.415745344827586 ], [ 29.781051392405065, -1.415475862068966 ], [ 29.780781898734176, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5356, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.415475862068966 ], [ 29.78132088607595, -1.415745344827586 ], [ 29.781590379746834, -1.415745344827586 ], [ 29.781590379746834, -1.415475862068966 ], [ 29.78132088607595, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5357, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.415475862068966 ], [ 29.781590379746834, -1.415745344827586 ], [ 29.781859873417723, -1.415745344827586 ], [ 29.781859873417723, -1.415475862068966 ], [ 29.781590379746834, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5358, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.415475862068966 ], [ 29.781859873417723, -1.415745344827586 ], [ 29.782129367088608, -1.415745344827586 ], [ 29.782129367088608, -1.415475862068966 ], [ 29.781859873417723, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5359, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.415475862068966 ], [ 29.782398860759493, -1.415745344827586 ], [ 29.782668354430381, -1.415745344827586 ], [ 29.782668354430381, -1.415475862068966 ], [ 29.782398860759493, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5360, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.415475862068966 ], [ 29.782668354430381, -1.415745344827586 ], [ 29.782937848101266, -1.415745344827586 ], [ 29.782937848101266, -1.415475862068966 ], [ 29.782668354430381, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5361, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.415475862068966 ], [ 29.782937848101266, -1.415745344827586 ], [ 29.783207341772151, -1.415745344827586 ], [ 29.783207341772151, -1.415475862068966 ], [ 29.782937848101266, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5362, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.415475862068966 ], [ 29.799646455696202, -1.415745344827586 ], [ 29.79991594936709, -1.415745344827586 ], [ 29.79991594936709, -1.415475862068966 ], [ 29.799646455696202, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5363, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.415206379310345 ], [ 29.773236075949367, -1.416014827586207 ], [ 29.773505569620252, -1.416014827586207 ], [ 29.773505569620252, -1.415206379310345 ], [ 29.773236075949367, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5364, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.415206379310345 ], [ 29.784015822784809, -1.416553793103448 ], [ 29.784285316455698, -1.416553793103448 ], [ 29.784285316455698, -1.415206379310345 ], [ 29.784015822784809, -1.415206379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5365, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.415475862068966 ], [ 29.76946316455696, -1.416014827586207 ], [ 29.769732658227849, -1.416014827586207 ], [ 29.769732658227849, -1.415475862068966 ], [ 29.76946316455696, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5366, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.415475862068966 ], [ 29.771349620253165, -1.416014827586207 ], [ 29.77161911392405, -1.416014827586207 ], [ 29.77161911392405, -1.415475862068966 ], [ 29.771349620253165, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5367, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.415475862068966 ], [ 29.772158101265823, -1.416014827586207 ], [ 29.772427594936708, -1.416014827586207 ], [ 29.772427594936708, -1.415475862068966 ], [ 29.772158101265823, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5368, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.415475862068966 ], [ 29.773505569620252, -1.416014827586207 ], [ 29.77377506329114, -1.416014827586207 ], [ 29.77377506329114, -1.415475862068966 ], [ 29.773505569620252, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5369, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.415475862068966 ], [ 29.774044556962025, -1.416014827586207 ], [ 29.77431405063291, -1.416014827586207 ], [ 29.77431405063291, -1.415475862068966 ], [ 29.774044556962025, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5370, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.415475862068966 ], [ 29.775122531645568, -1.416014827586207 ], [ 29.775392025316457, -1.416014827586207 ], [ 29.775392025316457, -1.415475862068966 ], [ 29.775122531645568, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5371, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.415475862068966 ], [ 29.775931012658226, -1.416014827586207 ], [ 29.776200506329115, -1.416014827586207 ], [ 29.776200506329115, -1.415475862068966 ], [ 29.775931012658226, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5372, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.415475862068966 ], [ 29.776739493670885, -1.416014827586207 ], [ 29.777008987341773, -1.416014827586207 ], [ 29.777008987341773, -1.415475862068966 ], [ 29.776739493670885, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5373, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.415475862068966 ], [ 29.77862594936709, -1.416014827586207 ], [ 29.778895443037975, -1.416014827586207 ], [ 29.778895443037975, -1.415475862068966 ], [ 29.77862594936709, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5374, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.415475862068966 ], [ 29.781051392405065, -1.416014827586207 ], [ 29.78132088607595, -1.416014827586207 ], [ 29.78132088607595, -1.415475862068966 ], [ 29.781051392405065, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5375, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.415475862068966 ], [ 29.783207341772151, -1.416553793103448 ], [ 29.783476835443039, -1.416553793103448 ], [ 29.783476835443039, -1.415475862068966 ], [ 29.783207341772151, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5376, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.415475862068966 ], [ 29.783476835443039, -1.416553793103448 ], [ 29.784015822784809, -1.416553793103448 ], [ 29.784015822784809, -1.415475862068966 ], [ 29.783476835443039, -1.415475862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5377, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.415745344827586 ], [ 29.757335949367089, -1.416014827586207 ], [ 29.757605443037974, -1.416014827586207 ], [ 29.757605443037974, -1.415745344827586 ], [ 29.757335949367089, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5378, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.415745344827586 ], [ 29.757605443037974, -1.416014827586207 ], [ 29.757874936708859, -1.416014827586207 ], [ 29.757874936708859, -1.415745344827586 ], [ 29.757605443037974, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5379, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.415745344827586 ], [ 29.757874936708859, -1.416014827586207 ], [ 29.758144430379748, -1.416014827586207 ], [ 29.758144430379748, -1.415745344827586 ], [ 29.757874936708859, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5380, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.415745344827586 ], [ 29.758144430379748, -1.416014827586207 ], [ 29.758413924050632, -1.416014827586207 ], [ 29.758413924050632, -1.415745344827586 ], [ 29.758144430379748, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5381, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.415745344827586 ], [ 29.758413924050632, -1.416014827586207 ], [ 29.758683417721517, -1.416014827586207 ], [ 29.758683417721517, -1.415745344827586 ], [ 29.758413924050632, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5382, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.415745344827586 ], [ 29.758683417721517, -1.416014827586207 ], [ 29.758952911392406, -1.416014827586207 ], [ 29.758952911392406, -1.415745344827586 ], [ 29.758683417721517, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5383, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.415745344827586 ], [ 29.758952911392406, -1.416014827586207 ], [ 29.759222405063291, -1.416014827586207 ], [ 29.759222405063291, -1.415745344827586 ], [ 29.758952911392406, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5384, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.415745344827586 ], [ 29.759222405063291, -1.416284310344828 ], [ 29.759491898734176, -1.416284310344828 ], [ 29.759491898734176, -1.415745344827586 ], [ 29.759222405063291, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5385, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.415745344827586 ], [ 29.759491898734176, -1.416014827586207 ], [ 29.759761392405064, -1.416014827586207 ], [ 29.759761392405064, -1.415745344827586 ], [ 29.759491898734176, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5386, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.415745344827586 ], [ 29.759761392405064, -1.416014827586207 ], [ 29.760030886075949, -1.416014827586207 ], [ 29.760030886075949, -1.415745344827586 ], [ 29.759761392405064, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5387, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.415745344827586 ], [ 29.760030886075949, -1.416553793103448 ], [ 29.760300379746834, -1.416553793103448 ], [ 29.760300379746834, -1.415745344827586 ], [ 29.760030886075949, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5388, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.415745344827586 ], [ 29.760300379746834, -1.416014827586207 ], [ 29.760569873417722, -1.416014827586207 ], [ 29.760569873417722, -1.415745344827586 ], [ 29.760300379746834, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5389, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.415745344827586 ], [ 29.760569873417722, -1.416014827586207 ], [ 29.760839367088607, -1.416014827586207 ], [ 29.760839367088607, -1.415745344827586 ], [ 29.760569873417722, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5390, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.415745344827586 ], [ 29.760839367088607, -1.416014827586207 ], [ 29.761108860759492, -1.416014827586207 ], [ 29.761108860759492, -1.415745344827586 ], [ 29.760839367088607, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5391, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.415745344827586 ], [ 29.761108860759492, -1.416014827586207 ], [ 29.761378354430381, -1.416014827586207 ], [ 29.761378354430381, -1.415745344827586 ], [ 29.761108860759492, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5392, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.415745344827586 ], [ 29.761378354430381, -1.416014827586207 ], [ 29.761647848101266, -1.416014827586207 ], [ 29.761647848101266, -1.415745344827586 ], [ 29.761378354430381, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5393, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.415745344827586 ], [ 29.761647848101266, -1.416014827586207 ], [ 29.762725822784809, -1.416014827586207 ], [ 29.762725822784809, -1.415745344827586 ], [ 29.761647848101266, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5394, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.415745344827586 ], [ 29.762725822784809, -1.416014827586207 ], [ 29.762995316455697, -1.416014827586207 ], [ 29.762995316455697, -1.415745344827586 ], [ 29.762725822784809, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5395, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.415745344827586 ], [ 29.762995316455697, -1.416014827586207 ], [ 29.763264810126582, -1.416014827586207 ], [ 29.763264810126582, -1.415745344827586 ], [ 29.762995316455697, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5396, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.415745344827586 ], [ 29.763264810126582, -1.416014827586207 ], [ 29.763534303797467, -1.416014827586207 ], [ 29.763534303797467, -1.415745344827586 ], [ 29.763264810126582, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5397, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.415745344827586 ], [ 29.763534303797467, -1.416014827586207 ], [ 29.763803797468356, -1.416014827586207 ], [ 29.763803797468356, -1.415745344827586 ], [ 29.763534303797467, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5398, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.415745344827586 ], [ 29.763803797468356, -1.416014827586207 ], [ 29.76407329113924, -1.416014827586207 ], [ 29.76407329113924, -1.415745344827586 ], [ 29.763803797468356, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5399, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.415745344827586 ], [ 29.76407329113924, -1.416014827586207 ], [ 29.764342784810125, -1.416014827586207 ], [ 29.764342784810125, -1.415745344827586 ], [ 29.76407329113924, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5400, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.415745344827586 ], [ 29.764342784810125, -1.416014827586207 ], [ 29.764612278481014, -1.416014827586207 ], [ 29.764612278481014, -1.415745344827586 ], [ 29.764342784810125, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5401, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.415745344827586 ], [ 29.764612278481014, -1.416014827586207 ], [ 29.764881772151899, -1.416014827586207 ], [ 29.764881772151899, -1.415745344827586 ], [ 29.764612278481014, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5402, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.415745344827586 ], [ 29.764881772151899, -1.416014827586207 ], [ 29.765151265822784, -1.416014827586207 ], [ 29.765151265822784, -1.415745344827586 ], [ 29.764881772151899, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5403, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.415745344827586 ], [ 29.765151265822784, -1.416014827586207 ], [ 29.765420759493672, -1.416014827586207 ], [ 29.765420759493672, -1.415745344827586 ], [ 29.765151265822784, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5404, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.415745344827586 ], [ 29.765420759493672, -1.416014827586207 ], [ 29.765959746835442, -1.416014827586207 ], [ 29.765959746835442, -1.415745344827586 ], [ 29.765420759493672, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5405, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.415745344827586 ], [ 29.765959746835442, -1.416014827586207 ], [ 29.766229240506327, -1.416014827586207 ], [ 29.766229240506327, -1.415745344827586 ], [ 29.765959746835442, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5406, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.415745344827586 ], [ 29.766229240506327, -1.416014827586207 ], [ 29.766498734177215, -1.416014827586207 ], [ 29.766498734177215, -1.415745344827586 ], [ 29.766229240506327, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5407, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.415745344827586 ], [ 29.766498734177215, -1.416014827586207 ], [ 29.7667682278481, -1.416014827586207 ], [ 29.7667682278481, -1.415745344827586 ], [ 29.766498734177215, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5408, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.415745344827586 ], [ 29.7667682278481, -1.416014827586207 ], [ 29.767037721518985, -1.416014827586207 ], [ 29.767037721518985, -1.415745344827586 ], [ 29.7667682278481, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5409, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.415745344827586 ], [ 29.767037721518985, -1.416014827586207 ], [ 29.767307215189874, -1.416014827586207 ], [ 29.767307215189874, -1.415745344827586 ], [ 29.767037721518985, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5410, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.415745344827586 ], [ 29.767307215189874, -1.416014827586207 ], [ 29.767576708860759, -1.416014827586207 ], [ 29.767576708860759, -1.415745344827586 ], [ 29.767307215189874, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5411, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.415745344827586 ], [ 29.767576708860759, -1.416014827586207 ], [ 29.767846202531643, -1.416014827586207 ], [ 29.767846202531643, -1.415745344827586 ], [ 29.767576708860759, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5412, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.415745344827586 ], [ 29.767846202531643, -1.416014827586207 ], [ 29.768115696202532, -1.416014827586207 ], [ 29.768115696202532, -1.415745344827586 ], [ 29.767846202531643, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5413, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.415745344827586 ], [ 29.768115696202532, -1.416014827586207 ], [ 29.768385189873417, -1.416014827586207 ], [ 29.768385189873417, -1.415745344827586 ], [ 29.768115696202532, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5414, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.415745344827586 ], [ 29.768385189873417, -1.416014827586207 ], [ 29.768654683544302, -1.416014827586207 ], [ 29.768654683544302, -1.415745344827586 ], [ 29.768385189873417, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5415, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.415745344827586 ], [ 29.768654683544302, -1.416014827586207 ], [ 29.76892417721519, -1.416014827586207 ], [ 29.76892417721519, -1.415745344827586 ], [ 29.768654683544302, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5416, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.415745344827586 ], [ 29.76892417721519, -1.416014827586207 ], [ 29.769193670886075, -1.416014827586207 ], [ 29.769193670886075, -1.415745344827586 ], [ 29.76892417721519, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5417, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.415745344827586 ], [ 29.769193670886075, -1.416014827586207 ], [ 29.76946316455696, -1.416014827586207 ], [ 29.76946316455696, -1.415745344827586 ], [ 29.769193670886075, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5418, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.415745344827586 ], [ 29.769732658227849, -1.416014827586207 ], [ 29.770002151898733, -1.416014827586207 ], [ 29.770002151898733, -1.415745344827586 ], [ 29.769732658227849, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5419, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.415745344827586 ], [ 29.770002151898733, -1.416014827586207 ], [ 29.770271645569618, -1.416014827586207 ], [ 29.770271645569618, -1.415745344827586 ], [ 29.770002151898733, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5420, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.415745344827586 ], [ 29.770271645569618, -1.416014827586207 ], [ 29.770541139240507, -1.416014827586207 ], [ 29.770541139240507, -1.415745344827586 ], [ 29.770271645569618, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5421, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.415745344827586 ], [ 29.770541139240507, -1.416284310344828 ], [ 29.770810632911392, -1.416284310344828 ], [ 29.770810632911392, -1.415745344827586 ], [ 29.770541139240507, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5422, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.415745344827586 ], [ 29.770810632911392, -1.416014827586207 ], [ 29.771080126582277, -1.416014827586207 ], [ 29.771080126582277, -1.415745344827586 ], [ 29.770810632911392, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5423, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.415745344827586 ], [ 29.771080126582277, -1.416014827586207 ], [ 29.771349620253165, -1.416014827586207 ], [ 29.771349620253165, -1.415745344827586 ], [ 29.771080126582277, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5424, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.415745344827586 ], [ 29.77161911392405, -1.416014827586207 ], [ 29.771888607594935, -1.416014827586207 ], [ 29.771888607594935, -1.415745344827586 ], [ 29.77161911392405, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5425, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.415745344827586 ], [ 29.771888607594935, -1.416014827586207 ], [ 29.772158101265823, -1.416014827586207 ], [ 29.772158101265823, -1.415745344827586 ], [ 29.771888607594935, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5426, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.415745344827586 ], [ 29.772427594936708, -1.416284310344828 ], [ 29.772697088607593, -1.416284310344828 ], [ 29.772697088607593, -1.415745344827586 ], [ 29.772427594936708, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5427, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.415745344827586 ], [ 29.772697088607593, -1.416014827586207 ], [ 29.772966582278482, -1.416014827586207 ], [ 29.772966582278482, -1.415745344827586 ], [ 29.772697088607593, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5428, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.415745344827586 ], [ 29.772966582278482, -1.416284310344828 ], [ 29.773236075949367, -1.416284310344828 ], [ 29.773236075949367, -1.415745344827586 ], [ 29.772966582278482, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5429, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.415745344827586 ], [ 29.77377506329114, -1.416284310344828 ], [ 29.774044556962025, -1.416284310344828 ], [ 29.774044556962025, -1.415745344827586 ], [ 29.77377506329114, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5430, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.415745344827586 ], [ 29.77431405063291, -1.416014827586207 ], [ 29.774853037974683, -1.416014827586207 ], [ 29.774853037974683, -1.415745344827586 ], [ 29.77431405063291, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5431, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.415745344827586 ], [ 29.774853037974683, -1.416284310344828 ], [ 29.775122531645568, -1.416284310344828 ], [ 29.775122531645568, -1.415745344827586 ], [ 29.774853037974683, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5432, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.415745344827586 ], [ 29.775392025316457, -1.416014827586207 ], [ 29.775661518987341, -1.416014827586207 ], [ 29.775661518987341, -1.415745344827586 ], [ 29.775392025316457, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5433, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.415745344827586 ], [ 29.775661518987341, -1.416284310344828 ], [ 29.775931012658226, -1.416284310344828 ], [ 29.775931012658226, -1.415745344827586 ], [ 29.775661518987341, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5434, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.415745344827586 ], [ 29.776200506329115, -1.416014827586207 ], [ 29.77647, -1.416014827586207 ], [ 29.77647, -1.415745344827586 ], [ 29.776200506329115, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5435, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.415745344827586 ], [ 29.77647, -1.416014827586207 ], [ 29.776739493670885, -1.416014827586207 ], [ 29.776739493670885, -1.415745344827586 ], [ 29.77647, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5436, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.415745344827586 ], [ 29.777008987341773, -1.416014827586207 ], [ 29.777278481012658, -1.416014827586207 ], [ 29.777278481012658, -1.415745344827586 ], [ 29.777008987341773, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5437, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.415745344827586 ], [ 29.777278481012658, -1.416014827586207 ], [ 29.777547974683543, -1.416014827586207 ], [ 29.777547974683543, -1.415745344827586 ], [ 29.777278481012658, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5438, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.415745344827586 ], [ 29.777547974683543, -1.416284310344828 ], [ 29.777817468354431, -1.416284310344828 ], [ 29.777817468354431, -1.415745344827586 ], [ 29.777547974683543, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5439, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.415745344827586 ], [ 29.777817468354431, -1.416014827586207 ], [ 29.778086962025316, -1.416014827586207 ], [ 29.778086962025316, -1.415745344827586 ], [ 29.777817468354431, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5440, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.415745344827586 ], [ 29.778086962025316, -1.416014827586207 ], [ 29.778356455696201, -1.416014827586207 ], [ 29.778356455696201, -1.415745344827586 ], [ 29.778086962025316, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5441, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.415745344827586 ], [ 29.778356455696201, -1.416014827586207 ], [ 29.77862594936709, -1.416014827586207 ], [ 29.77862594936709, -1.415745344827586 ], [ 29.778356455696201, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5442, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.415745344827586 ], [ 29.778895443037975, -1.416014827586207 ], [ 29.77916493670886, -1.416014827586207 ], [ 29.77916493670886, -1.415745344827586 ], [ 29.778895443037975, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5443, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.415745344827586 ], [ 29.77916493670886, -1.416014827586207 ], [ 29.779434430379748, -1.416014827586207 ], [ 29.779434430379748, -1.415745344827586 ], [ 29.77916493670886, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5444, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.415745344827586 ], [ 29.779434430379748, -1.416014827586207 ], [ 29.779703924050633, -1.416014827586207 ], [ 29.779703924050633, -1.415745344827586 ], [ 29.779434430379748, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5445, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.415745344827586 ], [ 29.779703924050633, -1.416014827586207 ], [ 29.779973417721518, -1.416014827586207 ], [ 29.779973417721518, -1.415745344827586 ], [ 29.779703924050633, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5446, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.415745344827586 ], [ 29.779973417721518, -1.416284310344828 ], [ 29.780242911392406, -1.416284310344828 ], [ 29.780242911392406, -1.415745344827586 ], [ 29.779973417721518, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5447, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.415745344827586 ], [ 29.780242911392406, -1.416014827586207 ], [ 29.780512405063291, -1.416014827586207 ], [ 29.780512405063291, -1.415745344827586 ], [ 29.780242911392406, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5448, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.415745344827586 ], [ 29.780512405063291, -1.416014827586207 ], [ 29.780781898734176, -1.416014827586207 ], [ 29.780781898734176, -1.415745344827586 ], [ 29.780512405063291, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5449, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.415745344827586 ], [ 29.780781898734176, -1.416014827586207 ], [ 29.781051392405065, -1.416014827586207 ], [ 29.781051392405065, -1.415745344827586 ], [ 29.780781898734176, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5450, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.415745344827586 ], [ 29.78132088607595, -1.416014827586207 ], [ 29.781590379746834, -1.416014827586207 ], [ 29.781590379746834, -1.415745344827586 ], [ 29.78132088607595, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5451, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.415745344827586 ], [ 29.781590379746834, -1.416014827586207 ], [ 29.781859873417723, -1.416014827586207 ], [ 29.781859873417723, -1.415745344827586 ], [ 29.781590379746834, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5452, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.415745344827586 ], [ 29.781859873417723, -1.416014827586207 ], [ 29.782129367088608, -1.416014827586207 ], [ 29.782129367088608, -1.415745344827586 ], [ 29.781859873417723, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5453, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.415745344827586 ], [ 29.782129367088608, -1.416014827586207 ], [ 29.782398860759493, -1.416014827586207 ], [ 29.782398860759493, -1.415745344827586 ], [ 29.782129367088608, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5454, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.415745344827586 ], [ 29.782398860759493, -1.416553793103448 ], [ 29.782668354430381, -1.416553793103448 ], [ 29.782668354430381, -1.415745344827586 ], [ 29.782398860759493, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5455, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.415745344827586 ], [ 29.782668354430381, -1.416823275862069 ], [ 29.782937848101266, -1.416823275862069 ], [ 29.782937848101266, -1.415745344827586 ], [ 29.782668354430381, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5456, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.415745344827586 ], [ 29.782937848101266, -1.416823275862069 ], [ 29.783207341772151, -1.416823275862069 ], [ 29.783207341772151, -1.415745344827586 ], [ 29.782937848101266, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5457, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.415745344827586 ], [ 29.799376962025317, -1.416014827586207 ], [ 29.799646455696202, -1.416014827586207 ], [ 29.799646455696202, -1.415745344827586 ], [ 29.799376962025317, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5458, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.415745344827586 ], [ 29.799646455696202, -1.416014827586207 ], [ 29.79991594936709, -1.416014827586207 ], [ 29.79991594936709, -1.415745344827586 ], [ 29.799646455696202, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5459, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.415745344827586 ], [ 29.79991594936709, -1.416014827586207 ], [ 29.800185443037975, -1.416014827586207 ], [ 29.800185443037975, -1.415745344827586 ], [ 29.79991594936709, -1.415745344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5460, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.416014827586207 ], [ 29.757605443037974, -1.416284310344828 ], [ 29.757874936708859, -1.416284310344828 ], [ 29.757874936708859, -1.416014827586207 ], [ 29.757605443037974, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5461, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.416014827586207 ], [ 29.757874936708859, -1.416284310344828 ], [ 29.758144430379748, -1.416284310344828 ], [ 29.758144430379748, -1.416014827586207 ], [ 29.757874936708859, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5462, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.416014827586207 ], [ 29.758144430379748, -1.416284310344828 ], [ 29.758413924050632, -1.416284310344828 ], [ 29.758413924050632, -1.416014827586207 ], [ 29.758144430379748, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5463, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.416014827586207 ], [ 29.758413924050632, -1.416284310344828 ], [ 29.758683417721517, -1.416284310344828 ], [ 29.758683417721517, -1.416014827586207 ], [ 29.758413924050632, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5464, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.416014827586207 ], [ 29.758683417721517, -1.41709275862069 ], [ 29.758952911392406, -1.41709275862069 ], [ 29.758952911392406, -1.416014827586207 ], [ 29.758683417721517, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5465, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.416014827586207 ], [ 29.758952911392406, -1.41709275862069 ], [ 29.759222405063291, -1.41709275862069 ], [ 29.759222405063291, -1.416014827586207 ], [ 29.758952911392406, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5466, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.416014827586207 ], [ 29.759491898734176, -1.416823275862069 ], [ 29.759761392405064, -1.416823275862069 ], [ 29.759761392405064, -1.416014827586207 ], [ 29.759491898734176, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5467, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.416014827586207 ], [ 29.759761392405064, -1.416823275862069 ], [ 29.760030886075949, -1.416823275862069 ], [ 29.760030886075949, -1.416014827586207 ], [ 29.759761392405064, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5468, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.416014827586207 ], [ 29.760300379746834, -1.416553793103448 ], [ 29.760569873417722, -1.416553793103448 ], [ 29.760569873417722, -1.416014827586207 ], [ 29.760300379746834, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5469, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.416014827586207 ], [ 29.760569873417722, -1.416284310344828 ], [ 29.760839367088607, -1.416284310344828 ], [ 29.760839367088607, -1.416014827586207 ], [ 29.760569873417722, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5470, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.416014827586207 ], [ 29.760839367088607, -1.416284310344828 ], [ 29.761108860759492, -1.416284310344828 ], [ 29.761108860759492, -1.416014827586207 ], [ 29.760839367088607, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5471, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.416014827586207 ], [ 29.761108860759492, -1.416284310344828 ], [ 29.761378354430381, -1.416284310344828 ], [ 29.761378354430381, -1.416014827586207 ], [ 29.761108860759492, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5472, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.416014827586207 ], [ 29.761378354430381, -1.416284310344828 ], [ 29.762995316455697, -1.416284310344828 ], [ 29.762995316455697, -1.416014827586207 ], [ 29.761378354430381, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5473, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.416014827586207 ], [ 29.762995316455697, -1.416284310344828 ], [ 29.763264810126582, -1.416284310344828 ], [ 29.763264810126582, -1.416014827586207 ], [ 29.762995316455697, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5474, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.416014827586207 ], [ 29.763264810126582, -1.416284310344828 ], [ 29.763534303797467, -1.416284310344828 ], [ 29.763534303797467, -1.416014827586207 ], [ 29.763264810126582, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5475, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.416014827586207 ], [ 29.763534303797467, -1.416284310344828 ], [ 29.763803797468356, -1.416284310344828 ], [ 29.763803797468356, -1.416014827586207 ], [ 29.763534303797467, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5476, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.416014827586207 ], [ 29.763803797468356, -1.416284310344828 ], [ 29.76407329113924, -1.416284310344828 ], [ 29.76407329113924, -1.416014827586207 ], [ 29.763803797468356, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5477, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.416014827586207 ], [ 29.76407329113924, -1.416284310344828 ], [ 29.764342784810125, -1.416284310344828 ], [ 29.764342784810125, -1.416014827586207 ], [ 29.76407329113924, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5478, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.416014827586207 ], [ 29.764342784810125, -1.416284310344828 ], [ 29.764612278481014, -1.416284310344828 ], [ 29.764612278481014, -1.416014827586207 ], [ 29.764342784810125, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5479, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.416014827586207 ], [ 29.764612278481014, -1.416284310344828 ], [ 29.764881772151899, -1.416284310344828 ], [ 29.764881772151899, -1.416014827586207 ], [ 29.764612278481014, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5480, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.416014827586207 ], [ 29.764881772151899, -1.416284310344828 ], [ 29.765151265822784, -1.416284310344828 ], [ 29.765151265822784, -1.416014827586207 ], [ 29.764881772151899, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5481, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.416014827586207 ], [ 29.765151265822784, -1.416284310344828 ], [ 29.765420759493672, -1.416284310344828 ], [ 29.765420759493672, -1.416014827586207 ], [ 29.765151265822784, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5482, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.416014827586207 ], [ 29.765420759493672, -1.416284310344828 ], [ 29.765959746835442, -1.416284310344828 ], [ 29.765959746835442, -1.416014827586207 ], [ 29.765420759493672, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5483, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.416014827586207 ], [ 29.765959746835442, -1.416284310344828 ], [ 29.766229240506327, -1.416284310344828 ], [ 29.766229240506327, -1.416014827586207 ], [ 29.765959746835442, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5484, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.416014827586207 ], [ 29.766229240506327, -1.416284310344828 ], [ 29.766498734177215, -1.416284310344828 ], [ 29.766498734177215, -1.416014827586207 ], [ 29.766229240506327, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5485, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.416014827586207 ], [ 29.766498734177215, -1.416284310344828 ], [ 29.7667682278481, -1.416284310344828 ], [ 29.7667682278481, -1.416014827586207 ], [ 29.766498734177215, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5486, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.416014827586207 ], [ 29.7667682278481, -1.416284310344828 ], [ 29.767037721518985, -1.416284310344828 ], [ 29.767037721518985, -1.416014827586207 ], [ 29.7667682278481, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5487, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.416014827586207 ], [ 29.767037721518985, -1.416284310344828 ], [ 29.767307215189874, -1.416284310344828 ], [ 29.767307215189874, -1.416014827586207 ], [ 29.767037721518985, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5488, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.416014827586207 ], [ 29.767307215189874, -1.416284310344828 ], [ 29.767576708860759, -1.416284310344828 ], [ 29.767576708860759, -1.416014827586207 ], [ 29.767307215189874, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5489, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.416014827586207 ], [ 29.767576708860759, -1.416284310344828 ], [ 29.767846202531643, -1.416284310344828 ], [ 29.767846202531643, -1.416014827586207 ], [ 29.767576708860759, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5490, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.416014827586207 ], [ 29.767846202531643, -1.416284310344828 ], [ 29.768115696202532, -1.416284310344828 ], [ 29.768115696202532, -1.416014827586207 ], [ 29.767846202531643, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5491, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.416014827586207 ], [ 29.768115696202532, -1.416284310344828 ], [ 29.768385189873417, -1.416284310344828 ], [ 29.768385189873417, -1.416014827586207 ], [ 29.768115696202532, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5492, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.416014827586207 ], [ 29.768385189873417, -1.416284310344828 ], [ 29.768654683544302, -1.416284310344828 ], [ 29.768654683544302, -1.416014827586207 ], [ 29.768385189873417, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5493, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.416014827586207 ], [ 29.768654683544302, -1.416284310344828 ], [ 29.76892417721519, -1.416284310344828 ], [ 29.76892417721519, -1.416014827586207 ], [ 29.768654683544302, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5494, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.416014827586207 ], [ 29.76892417721519, -1.416284310344828 ], [ 29.769193670886075, -1.416284310344828 ], [ 29.769193670886075, -1.416014827586207 ], [ 29.76892417721519, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5495, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.416014827586207 ], [ 29.769193670886075, -1.416284310344828 ], [ 29.76946316455696, -1.416284310344828 ], [ 29.76946316455696, -1.416014827586207 ], [ 29.769193670886075, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5496, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.416014827586207 ], [ 29.76946316455696, -1.416284310344828 ], [ 29.769732658227849, -1.416284310344828 ], [ 29.769732658227849, -1.416014827586207 ], [ 29.76946316455696, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5497, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.416014827586207 ], [ 29.769732658227849, -1.416284310344828 ], [ 29.770002151898733, -1.416284310344828 ], [ 29.770002151898733, -1.416014827586207 ], [ 29.769732658227849, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5498, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.416014827586207 ], [ 29.770002151898733, -1.416284310344828 ], [ 29.770271645569618, -1.416284310344828 ], [ 29.770271645569618, -1.416014827586207 ], [ 29.770002151898733, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5499, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.416014827586207 ], [ 29.770271645569618, -1.416284310344828 ], [ 29.770541139240507, -1.416284310344828 ], [ 29.770541139240507, -1.416014827586207 ], [ 29.770271645569618, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5500, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.416014827586207 ], [ 29.770810632911392, -1.416284310344828 ], [ 29.771080126582277, -1.416284310344828 ], [ 29.771080126582277, -1.416014827586207 ], [ 29.770810632911392, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5501, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.416014827586207 ], [ 29.771080126582277, -1.416284310344828 ], [ 29.771349620253165, -1.416284310344828 ], [ 29.771349620253165, -1.416014827586207 ], [ 29.771080126582277, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5502, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.416014827586207 ], [ 29.771349620253165, -1.416284310344828 ], [ 29.77161911392405, -1.416284310344828 ], [ 29.77161911392405, -1.416014827586207 ], [ 29.771349620253165, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5503, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.416014827586207 ], [ 29.77161911392405, -1.416553793103448 ], [ 29.771888607594935, -1.416553793103448 ], [ 29.771888607594935, -1.416014827586207 ], [ 29.77161911392405, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5504, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.416014827586207 ], [ 29.771888607594935, -1.416284310344828 ], [ 29.772158101265823, -1.416284310344828 ], [ 29.772158101265823, -1.416014827586207 ], [ 29.771888607594935, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5505, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.416014827586207 ], [ 29.772158101265823, -1.416284310344828 ], [ 29.772427594936708, -1.416284310344828 ], [ 29.772427594936708, -1.416014827586207 ], [ 29.772158101265823, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5506, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.416014827586207 ], [ 29.772697088607593, -1.416284310344828 ], [ 29.772966582278482, -1.416284310344828 ], [ 29.772966582278482, -1.416014827586207 ], [ 29.772697088607593, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5507, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.416014827586207 ], [ 29.773236075949367, -1.416284310344828 ], [ 29.773505569620252, -1.416284310344828 ], [ 29.773505569620252, -1.416014827586207 ], [ 29.773236075949367, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5508, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.416014827586207 ], [ 29.773505569620252, -1.416553793103448 ], [ 29.77377506329114, -1.416553793103448 ], [ 29.77377506329114, -1.416014827586207 ], [ 29.773505569620252, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5509, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.416014827586207 ], [ 29.774044556962025, -1.416284310344828 ], [ 29.77431405063291, -1.416284310344828 ], [ 29.77431405063291, -1.416014827586207 ], [ 29.774044556962025, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5510, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.416014827586207 ], [ 29.77431405063291, -1.416553793103448 ], [ 29.774853037974683, -1.416553793103448 ], [ 29.774853037974683, -1.416014827586207 ], [ 29.77431405063291, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5511, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.416014827586207 ], [ 29.775122531645568, -1.416284310344828 ], [ 29.775392025316457, -1.416284310344828 ], [ 29.775392025316457, -1.416014827586207 ], [ 29.775122531645568, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5512, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.416014827586207 ], [ 29.775392025316457, -1.416284310344828 ], [ 29.775661518987341, -1.416284310344828 ], [ 29.775661518987341, -1.416014827586207 ], [ 29.775392025316457, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5513, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.416014827586207 ], [ 29.775931012658226, -1.416284310344828 ], [ 29.776200506329115, -1.416284310344828 ], [ 29.776200506329115, -1.416014827586207 ], [ 29.775931012658226, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5514, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.416014827586207 ], [ 29.776200506329115, -1.416284310344828 ], [ 29.77647, -1.416284310344828 ], [ 29.77647, -1.416014827586207 ], [ 29.776200506329115, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5515, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.416014827586207 ], [ 29.77647, -1.416553793103448 ], [ 29.776739493670885, -1.416553793103448 ], [ 29.776739493670885, -1.416014827586207 ], [ 29.77647, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5516, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.416014827586207 ], [ 29.776739493670885, -1.416284310344828 ], [ 29.777008987341773, -1.416284310344828 ], [ 29.777008987341773, -1.416014827586207 ], [ 29.776739493670885, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5517, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.416014827586207 ], [ 29.777008987341773, -1.416284310344828 ], [ 29.777278481012658, -1.416284310344828 ], [ 29.777278481012658, -1.416014827586207 ], [ 29.777008987341773, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5518, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.416014827586207 ], [ 29.777278481012658, -1.416284310344828 ], [ 29.777547974683543, -1.416284310344828 ], [ 29.777547974683543, -1.416014827586207 ], [ 29.777278481012658, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5519, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.416014827586207 ], [ 29.777817468354431, -1.416284310344828 ], [ 29.778086962025316, -1.416284310344828 ], [ 29.778086962025316, -1.416014827586207 ], [ 29.777817468354431, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5520, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.416014827586207 ], [ 29.778086962025316, -1.416284310344828 ], [ 29.778356455696201, -1.416284310344828 ], [ 29.778356455696201, -1.416014827586207 ], [ 29.778086962025316, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5521, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.416014827586207 ], [ 29.778356455696201, -1.416284310344828 ], [ 29.77862594936709, -1.416284310344828 ], [ 29.77862594936709, -1.416014827586207 ], [ 29.778356455696201, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5522, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.416014827586207 ], [ 29.77862594936709, -1.416284310344828 ], [ 29.778895443037975, -1.416284310344828 ], [ 29.778895443037975, -1.416014827586207 ], [ 29.77862594936709, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5523, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.416014827586207 ], [ 29.778895443037975, -1.416284310344828 ], [ 29.77916493670886, -1.416284310344828 ], [ 29.77916493670886, -1.416014827586207 ], [ 29.778895443037975, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5524, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.416014827586207 ], [ 29.77916493670886, -1.416284310344828 ], [ 29.779434430379748, -1.416284310344828 ], [ 29.779434430379748, -1.416014827586207 ], [ 29.77916493670886, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5525, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.416014827586207 ], [ 29.779434430379748, -1.416284310344828 ], [ 29.779703924050633, -1.416284310344828 ], [ 29.779703924050633, -1.416014827586207 ], [ 29.779434430379748, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5526, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.416014827586207 ], [ 29.779703924050633, -1.416284310344828 ], [ 29.779973417721518, -1.416284310344828 ], [ 29.779973417721518, -1.416014827586207 ], [ 29.779703924050633, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5527, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.416014827586207 ], [ 29.780242911392406, -1.416284310344828 ], [ 29.780512405063291, -1.416284310344828 ], [ 29.780512405063291, -1.416014827586207 ], [ 29.780242911392406, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5528, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.416014827586207 ], [ 29.780512405063291, -1.416284310344828 ], [ 29.780781898734176, -1.416284310344828 ], [ 29.780781898734176, -1.416014827586207 ], [ 29.780512405063291, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5529, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.416014827586207 ], [ 29.780781898734176, -1.416284310344828 ], [ 29.781051392405065, -1.416284310344828 ], [ 29.781051392405065, -1.416014827586207 ], [ 29.780781898734176, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5530, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.416014827586207 ], [ 29.781051392405065, -1.416553793103448 ], [ 29.78132088607595, -1.416553793103448 ], [ 29.78132088607595, -1.416014827586207 ], [ 29.781051392405065, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5531, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.416014827586207 ], [ 29.78132088607595, -1.416284310344828 ], [ 29.781590379746834, -1.416284310344828 ], [ 29.781590379746834, -1.416014827586207 ], [ 29.78132088607595, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5532, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.416014827586207 ], [ 29.781590379746834, -1.416284310344828 ], [ 29.781859873417723, -1.416284310344828 ], [ 29.781859873417723, -1.416014827586207 ], [ 29.781590379746834, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5533, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.416014827586207 ], [ 29.781859873417723, -1.416823275862069 ], [ 29.782129367088608, -1.416823275862069 ], [ 29.782129367088608, -1.416014827586207 ], [ 29.781859873417723, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5534, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.416014827586207 ], [ 29.782129367088608, -1.416823275862069 ], [ 29.782398860759493, -1.416823275862069 ], [ 29.782398860759493, -1.416014827586207 ], [ 29.782129367088608, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5535, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.416014827586207 ], [ 29.799107468354432, -1.41709275862069 ], [ 29.799376962025317, -1.41709275862069 ], [ 29.799376962025317, -1.416014827586207 ], [ 29.799107468354432, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5536, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.416014827586207 ], [ 29.799376962025317, -1.416284310344828 ], [ 29.799646455696202, -1.416284310344828 ], [ 29.799646455696202, -1.416014827586207 ], [ 29.799376962025317, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5537, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.416014827586207 ], [ 29.799646455696202, -1.416284310344828 ], [ 29.79991594936709, -1.416284310344828 ], [ 29.79991594936709, -1.416014827586207 ], [ 29.799646455696202, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5538, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.416014827586207 ], [ 29.79991594936709, -1.416284310344828 ], [ 29.800185443037975, -1.416284310344828 ], [ 29.800185443037975, -1.416014827586207 ], [ 29.79991594936709, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5539, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.416014827586207 ], [ 29.800185443037975, -1.416284310344828 ], [ 29.800724430379748, -1.416284310344828 ], [ 29.800724430379748, -1.416014827586207 ], [ 29.800185443037975, -1.416014827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5540, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.416284310344828 ], [ 29.757874936708859, -1.417901206896552 ], [ 29.758144430379748, -1.417901206896552 ], [ 29.758144430379748, -1.416284310344828 ], [ 29.757874936708859, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5541, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.416284310344828 ], [ 29.758144430379748, -1.417631724137931 ], [ 29.758413924050632, -1.417631724137931 ], [ 29.758413924050632, -1.416284310344828 ], [ 29.758144430379748, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5542, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.416284310344828 ], [ 29.758413924050632, -1.417631724137931 ], [ 29.758683417721517, -1.417631724137931 ], [ 29.758683417721517, -1.416284310344828 ], [ 29.758413924050632, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5543, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.416284310344828 ], [ 29.759222405063291, -1.41709275862069 ], [ 29.759491898734176, -1.41709275862069 ], [ 29.759491898734176, -1.416284310344828 ], [ 29.759222405063291, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5544, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.416284310344828 ], [ 29.760569873417722, -1.416823275862069 ], [ 29.760839367088607, -1.416823275862069 ], [ 29.760839367088607, -1.416553793103448 ], [ 29.761108860759492, -1.416553793103448 ], [ 29.761108860759492, -1.416284310344828 ], [ 29.760569873417722, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5545, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.416284310344828 ], [ 29.761108860759492, -1.416553793103448 ], [ 29.761647848101266, -1.416553793103448 ], [ 29.761647848101266, -1.416284310344828 ], [ 29.761108860759492, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5546, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.416284310344828 ], [ 29.761647848101266, -1.416553793103448 ], [ 29.762725822784809, -1.416553793103448 ], [ 29.762725822784809, -1.416284310344828 ], [ 29.761647848101266, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5547, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.416284310344828 ], [ 29.762725822784809, -1.416553793103448 ], [ 29.763264810126582, -1.416553793103448 ], [ 29.763264810126582, -1.416284310344828 ], [ 29.762725822784809, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5548, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.416284310344828 ], [ 29.763264810126582, -1.416553793103448 ], [ 29.763534303797467, -1.416553793103448 ], [ 29.763534303797467, -1.416284310344828 ], [ 29.763264810126582, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5549, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.416284310344828 ], [ 29.763534303797467, -1.416553793103448 ], [ 29.763803797468356, -1.416553793103448 ], [ 29.763803797468356, -1.416284310344828 ], [ 29.763534303797467, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5550, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.416284310344828 ], [ 29.763803797468356, -1.416553793103448 ], [ 29.76407329113924, -1.416553793103448 ], [ 29.76407329113924, -1.416284310344828 ], [ 29.763803797468356, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5551, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.416284310344828 ], [ 29.76407329113924, -1.416553793103448 ], [ 29.764342784810125, -1.416553793103448 ], [ 29.764342784810125, -1.416284310344828 ], [ 29.76407329113924, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5552, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.416284310344828 ], [ 29.764342784810125, -1.416553793103448 ], [ 29.764612278481014, -1.416553793103448 ], [ 29.764612278481014, -1.416284310344828 ], [ 29.764342784810125, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5553, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.416284310344828 ], [ 29.764612278481014, -1.416553793103448 ], [ 29.764881772151899, -1.416553793103448 ], [ 29.764881772151899, -1.416284310344828 ], [ 29.764612278481014, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5554, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.416284310344828 ], [ 29.764881772151899, -1.416553793103448 ], [ 29.765151265822784, -1.416553793103448 ], [ 29.765151265822784, -1.416284310344828 ], [ 29.764881772151899, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5555, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.416284310344828 ], [ 29.765151265822784, -1.416553793103448 ], [ 29.765420759493672, -1.416553793103448 ], [ 29.765420759493672, -1.416284310344828 ], [ 29.765151265822784, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5556, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.416284310344828 ], [ 29.765420759493672, -1.416553793103448 ], [ 29.765959746835442, -1.416553793103448 ], [ 29.765959746835442, -1.416284310344828 ], [ 29.765420759493672, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5557, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.416284310344828 ], [ 29.765959746835442, -1.416553793103448 ], [ 29.766229240506327, -1.416553793103448 ], [ 29.766229240506327, -1.416284310344828 ], [ 29.765959746835442, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5558, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.416284310344828 ], [ 29.766229240506327, -1.416553793103448 ], [ 29.766498734177215, -1.416553793103448 ], [ 29.766498734177215, -1.416284310344828 ], [ 29.766229240506327, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5559, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.416284310344828 ], [ 29.766498734177215, -1.416553793103448 ], [ 29.7667682278481, -1.416553793103448 ], [ 29.7667682278481, -1.416284310344828 ], [ 29.766498734177215, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5560, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.416284310344828 ], [ 29.7667682278481, -1.416553793103448 ], [ 29.767037721518985, -1.416553793103448 ], [ 29.767037721518985, -1.416284310344828 ], [ 29.7667682278481, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5561, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.416284310344828 ], [ 29.767037721518985, -1.416553793103448 ], [ 29.767307215189874, -1.416553793103448 ], [ 29.767307215189874, -1.416284310344828 ], [ 29.767037721518985, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5562, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.416284310344828 ], [ 29.767307215189874, -1.416553793103448 ], [ 29.767576708860759, -1.416553793103448 ], [ 29.767576708860759, -1.416284310344828 ], [ 29.767307215189874, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5563, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.416284310344828 ], [ 29.767576708860759, -1.416553793103448 ], [ 29.767846202531643, -1.416553793103448 ], [ 29.767846202531643, -1.416284310344828 ], [ 29.767576708860759, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5564, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.416284310344828 ], [ 29.767846202531643, -1.416553793103448 ], [ 29.768115696202532, -1.416553793103448 ], [ 29.768115696202532, -1.416284310344828 ], [ 29.767846202531643, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5565, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.416284310344828 ], [ 29.768115696202532, -1.416553793103448 ], [ 29.768385189873417, -1.416553793103448 ], [ 29.768385189873417, -1.416284310344828 ], [ 29.768115696202532, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5566, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.416284310344828 ], [ 29.768385189873417, -1.416553793103448 ], [ 29.768654683544302, -1.416553793103448 ], [ 29.768654683544302, -1.416284310344828 ], [ 29.768385189873417, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5567, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.416284310344828 ], [ 29.768654683544302, -1.416553793103448 ], [ 29.76892417721519, -1.416553793103448 ], [ 29.76892417721519, -1.416284310344828 ], [ 29.768654683544302, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5568, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.416284310344828 ], [ 29.76892417721519, -1.416553793103448 ], [ 29.769193670886075, -1.416553793103448 ], [ 29.769193670886075, -1.416284310344828 ], [ 29.76892417721519, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5569, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.416284310344828 ], [ 29.769193670886075, -1.416553793103448 ], [ 29.76946316455696, -1.416553793103448 ], [ 29.76946316455696, -1.416284310344828 ], [ 29.769193670886075, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5570, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.416284310344828 ], [ 29.76946316455696, -1.416553793103448 ], [ 29.769732658227849, -1.416553793103448 ], [ 29.769732658227849, -1.416284310344828 ], [ 29.76946316455696, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5571, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.416284310344828 ], [ 29.769732658227849, -1.416553793103448 ], [ 29.770002151898733, -1.416553793103448 ], [ 29.770002151898733, -1.416284310344828 ], [ 29.769732658227849, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5572, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.416284310344828 ], [ 29.770002151898733, -1.416553793103448 ], [ 29.770271645569618, -1.416553793103448 ], [ 29.770271645569618, -1.416284310344828 ], [ 29.770002151898733, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5573, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.416284310344828 ], [ 29.770271645569618, -1.416553793103448 ], [ 29.770541139240507, -1.416553793103448 ], [ 29.770541139240507, -1.416284310344828 ], [ 29.770271645569618, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5574, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.416284310344828 ], [ 29.770541139240507, -1.416553793103448 ], [ 29.770810632911392, -1.416553793103448 ], [ 29.770810632911392, -1.416284310344828 ], [ 29.770541139240507, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5575, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.416284310344828 ], [ 29.770810632911392, -1.416553793103448 ], [ 29.771080126582277, -1.416553793103448 ], [ 29.771080126582277, -1.416284310344828 ], [ 29.770810632911392, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5576, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.416284310344828 ], [ 29.771080126582277, -1.416553793103448 ], [ 29.771349620253165, -1.416553793103448 ], [ 29.771349620253165, -1.416284310344828 ], [ 29.771080126582277, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5577, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.416284310344828 ], [ 29.771349620253165, -1.416553793103448 ], [ 29.77161911392405, -1.416553793103448 ], [ 29.77161911392405, -1.416284310344828 ], [ 29.771349620253165, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5578, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.416284310344828 ], [ 29.771888607594935, -1.416553793103448 ], [ 29.772158101265823, -1.416553793103448 ], [ 29.772158101265823, -1.416284310344828 ], [ 29.771888607594935, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5579, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.416284310344828 ], [ 29.772158101265823, -1.416553793103448 ], [ 29.772427594936708, -1.416553793103448 ], [ 29.772427594936708, -1.416284310344828 ], [ 29.772158101265823, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5580, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.416284310344828 ], [ 29.772427594936708, -1.416553793103448 ], [ 29.772697088607593, -1.416553793103448 ], [ 29.772697088607593, -1.416284310344828 ], [ 29.772427594936708, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5581, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.416284310344828 ], [ 29.772697088607593, -1.416823275862069 ], [ 29.772966582278482, -1.416823275862069 ], [ 29.772966582278482, -1.416284310344828 ], [ 29.772697088607593, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5582, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.416284310344828 ], [ 29.772966582278482, -1.416553793103448 ], [ 29.773236075949367, -1.416553793103448 ], [ 29.773236075949367, -1.416284310344828 ], [ 29.772966582278482, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5583, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.416284310344828 ], [ 29.773236075949367, -1.416823275862069 ], [ 29.773505569620252, -1.416823275862069 ], [ 29.773505569620252, -1.416284310344828 ], [ 29.773236075949367, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5584, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.416284310344828 ], [ 29.77377506329114, -1.416553793103448 ], [ 29.774044556962025, -1.416553793103448 ], [ 29.774044556962025, -1.416284310344828 ], [ 29.77377506329114, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5585, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.416284310344828 ], [ 29.774044556962025, -1.416553793103448 ], [ 29.77431405063291, -1.416553793103448 ], [ 29.77431405063291, -1.416284310344828 ], [ 29.774044556962025, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5586, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.416284310344828 ], [ 29.774853037974683, -1.416553793103448 ], [ 29.775122531645568, -1.416553793103448 ], [ 29.775122531645568, -1.416284310344828 ], [ 29.774853037974683, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5587, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.416284310344828 ], [ 29.775122531645568, -1.416553793103448 ], [ 29.775392025316457, -1.416553793103448 ], [ 29.775392025316457, -1.416284310344828 ], [ 29.775122531645568, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5588, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.416284310344828 ], [ 29.775392025316457, -1.416823275862069 ], [ 29.775661518987341, -1.416823275862069 ], [ 29.775661518987341, -1.416284310344828 ], [ 29.775392025316457, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5589, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.416284310344828 ], [ 29.775661518987341, -1.416553793103448 ], [ 29.775931012658226, -1.416553793103448 ], [ 29.775931012658226, -1.416284310344828 ], [ 29.775661518987341, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5590, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.416284310344828 ], [ 29.775931012658226, -1.416553793103448 ], [ 29.776200506329115, -1.416553793103448 ], [ 29.776200506329115, -1.416284310344828 ], [ 29.775931012658226, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5591, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.416284310344828 ], [ 29.776200506329115, -1.416553793103448 ], [ 29.77647, -1.416553793103448 ], [ 29.77647, -1.416284310344828 ], [ 29.776200506329115, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5592, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.416284310344828 ], [ 29.776739493670885, -1.416553793103448 ], [ 29.777008987341773, -1.416553793103448 ], [ 29.777008987341773, -1.416284310344828 ], [ 29.776739493670885, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5593, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.416284310344828 ], [ 29.777008987341773, -1.416553793103448 ], [ 29.777278481012658, -1.416553793103448 ], [ 29.777278481012658, -1.416284310344828 ], [ 29.777008987341773, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5594, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.416284310344828 ], [ 29.777278481012658, -1.416553793103448 ], [ 29.777547974683543, -1.416553793103448 ], [ 29.777547974683543, -1.416284310344828 ], [ 29.777278481012658, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5595, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.416284310344828 ], [ 29.777547974683543, -1.416553793103448 ], [ 29.777817468354431, -1.416553793103448 ], [ 29.777817468354431, -1.416284310344828 ], [ 29.777547974683543, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5596, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.416284310344828 ], [ 29.777817468354431, -1.416553793103448 ], [ 29.778086962025316, -1.416553793103448 ], [ 29.778086962025316, -1.416284310344828 ], [ 29.777817468354431, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5597, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.416284310344828 ], [ 29.778086962025316, -1.416553793103448 ], [ 29.778356455696201, -1.416553793103448 ], [ 29.778356455696201, -1.416284310344828 ], [ 29.778086962025316, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5598, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.416284310344828 ], [ 29.778356455696201, -1.416553793103448 ], [ 29.77862594936709, -1.416553793103448 ], [ 29.77862594936709, -1.416284310344828 ], [ 29.778356455696201, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5599, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.416284310344828 ], [ 29.77862594936709, -1.416553793103448 ], [ 29.778895443037975, -1.416553793103448 ], [ 29.778895443037975, -1.416284310344828 ], [ 29.77862594936709, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5600, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.416284310344828 ], [ 29.778895443037975, -1.416823275862069 ], [ 29.77916493670886, -1.416823275862069 ], [ 29.77916493670886, -1.416284310344828 ], [ 29.778895443037975, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5601, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.416284310344828 ], [ 29.77916493670886, -1.416553793103448 ], [ 29.779434430379748, -1.416553793103448 ], [ 29.779434430379748, -1.416284310344828 ], [ 29.77916493670886, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5602, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.416284310344828 ], [ 29.779434430379748, -1.416553793103448 ], [ 29.779703924050633, -1.416553793103448 ], [ 29.779703924050633, -1.416284310344828 ], [ 29.779434430379748, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5603, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.416284310344828 ], [ 29.779703924050633, -1.416823275862069 ], [ 29.779973417721518, -1.416823275862069 ], [ 29.779973417721518, -1.416284310344828 ], [ 29.779703924050633, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5604, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.416284310344828 ], [ 29.779973417721518, -1.41709275862069 ], [ 29.780242911392406, -1.41709275862069 ], [ 29.780242911392406, -1.416284310344828 ], [ 29.779973417721518, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5605, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.416284310344828 ], [ 29.780242911392406, -1.416553793103448 ], [ 29.780512405063291, -1.416553793103448 ], [ 29.780512405063291, -1.416284310344828 ], [ 29.780242911392406, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5606, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.416284310344828 ], [ 29.780512405063291, -1.416823275862069 ], [ 29.780781898734176, -1.416823275862069 ], [ 29.780781898734176, -1.416284310344828 ], [ 29.780512405063291, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5607, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.416284310344828 ], [ 29.780781898734176, -1.41709275862069 ], [ 29.781051392405065, -1.41709275862069 ], [ 29.781051392405065, -1.416284310344828 ], [ 29.780781898734176, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5608, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.416284310344828 ], [ 29.78132088607595, -1.416823275862069 ], [ 29.781590379746834, -1.416823275862069 ], [ 29.781590379746834, -1.416284310344828 ], [ 29.78132088607595, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5609, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.416284310344828 ], [ 29.781590379746834, -1.41709275862069 ], [ 29.781859873417723, -1.41709275862069 ], [ 29.781859873417723, -1.416284310344828 ], [ 29.781590379746834, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5610, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.416284310344828 ], [ 29.799376962025317, -1.41709275862069 ], [ 29.799646455696202, -1.41709275862069 ], [ 29.799646455696202, -1.416284310344828 ], [ 29.799376962025317, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5611, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.416284310344828 ], [ 29.799646455696202, -1.41709275862069 ], [ 29.79991594936709, -1.41709275862069 ], [ 29.79991594936709, -1.416284310344828 ], [ 29.799646455696202, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5612, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.416284310344828 ], [ 29.79991594936709, -1.416553793103448 ], [ 29.800185443037975, -1.416553793103448 ], [ 29.800185443037975, -1.416284310344828 ], [ 29.79991594936709, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5613, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.416284310344828 ], [ 29.800185443037975, -1.416553793103448 ], [ 29.80045493670886, -1.416553793103448 ], [ 29.80045493670886, -1.416284310344828 ], [ 29.800185443037975, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5614, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.416284310344828 ], [ 29.80045493670886, -1.416553793103448 ], [ 29.800724430379748, -1.416553793103448 ], [ 29.800724430379748, -1.416284310344828 ], [ 29.80045493670886, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5615, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.416284310344828 ], [ 29.800724430379748, -1.416553793103448 ], [ 29.801263417721518, -1.416553793103448 ], [ 29.801263417721518, -1.416284310344828 ], [ 29.800724430379748, -1.416284310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5616, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.416553793103448 ], [ 29.760030886075949, -1.416823275862069 ], [ 29.760300379746834, -1.416823275862069 ], [ 29.760300379746834, -1.416553793103448 ], [ 29.760030886075949, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5617, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.416553793103448 ], [ 29.760300379746834, -1.416823275862069 ], [ 29.760569873417722, -1.416823275862069 ], [ 29.760569873417722, -1.416553793103448 ], [ 29.760300379746834, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5618, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.416553793103448 ], [ 29.760839367088607, -1.416823275862069 ], [ 29.761108860759492, -1.416823275862069 ], [ 29.761108860759492, -1.416553793103448 ], [ 29.760839367088607, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5619, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.416553793103448 ], [ 29.761108860759492, -1.416823275862069 ], [ 29.761378354430381, -1.416823275862069 ], [ 29.761378354430381, -1.416553793103448 ], [ 29.761108860759492, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5620, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.416553793103448 ], [ 29.761378354430381, -1.416823275862069 ], [ 29.761917341772151, -1.416823275862069 ], [ 29.761917341772151, -1.416553793103448 ], [ 29.761378354430381, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5621, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.416553793103448 ], [ 29.761917341772151, -1.416823275862069 ], [ 29.762456329113924, -1.416823275862069 ], [ 29.762456329113924, -1.416553793103448 ], [ 29.761917341772151, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5622, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.416553793103448 ], [ 29.762456329113924, -1.416823275862069 ], [ 29.762995316455697, -1.416823275862069 ], [ 29.762995316455697, -1.416553793103448 ], [ 29.762456329113924, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5623, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.416553793103448 ], [ 29.762995316455697, -1.416823275862069 ], [ 29.763264810126582, -1.416823275862069 ], [ 29.763264810126582, -1.416553793103448 ], [ 29.762995316455697, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5624, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.416553793103448 ], [ 29.763264810126582, -1.416823275862069 ], [ 29.763534303797467, -1.416823275862069 ], [ 29.763534303797467, -1.416553793103448 ], [ 29.763264810126582, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5625, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.416553793103448 ], [ 29.763534303797467, -1.416823275862069 ], [ 29.763803797468356, -1.416823275862069 ], [ 29.763803797468356, -1.416553793103448 ], [ 29.763534303797467, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5626, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.416553793103448 ], [ 29.763803797468356, -1.416823275862069 ], [ 29.76407329113924, -1.416823275862069 ], [ 29.76407329113924, -1.416553793103448 ], [ 29.763803797468356, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5627, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.416553793103448 ], [ 29.76407329113924, -1.416823275862069 ], [ 29.764342784810125, -1.416823275862069 ], [ 29.764342784810125, -1.416553793103448 ], [ 29.76407329113924, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5628, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.416553793103448 ], [ 29.764342784810125, -1.416823275862069 ], [ 29.764612278481014, -1.416823275862069 ], [ 29.764612278481014, -1.416553793103448 ], [ 29.764342784810125, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5629, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.416553793103448 ], [ 29.764612278481014, -1.416823275862069 ], [ 29.764881772151899, -1.416823275862069 ], [ 29.764881772151899, -1.416553793103448 ], [ 29.764612278481014, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5630, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.416553793103448 ], [ 29.764881772151899, -1.416823275862069 ], [ 29.765151265822784, -1.416823275862069 ], [ 29.765151265822784, -1.416553793103448 ], [ 29.764881772151899, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5631, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.416553793103448 ], [ 29.765151265822784, -1.416823275862069 ], [ 29.765420759493672, -1.416823275862069 ], [ 29.765420759493672, -1.416553793103448 ], [ 29.765151265822784, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5632, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.416553793103448 ], [ 29.765420759493672, -1.416823275862069 ], [ 29.765959746835442, -1.416823275862069 ], [ 29.765959746835442, -1.416553793103448 ], [ 29.765420759493672, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5633, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.416553793103448 ], [ 29.765959746835442, -1.416823275862069 ], [ 29.766229240506327, -1.416823275862069 ], [ 29.766229240506327, -1.416553793103448 ], [ 29.765959746835442, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5634, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.416553793103448 ], [ 29.766229240506327, -1.416823275862069 ], [ 29.766498734177215, -1.416823275862069 ], [ 29.766498734177215, -1.416553793103448 ], [ 29.766229240506327, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5635, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.416553793103448 ], [ 29.766498734177215, -1.416823275862069 ], [ 29.7667682278481, -1.416823275862069 ], [ 29.7667682278481, -1.416553793103448 ], [ 29.766498734177215, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5636, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.416553793103448 ], [ 29.7667682278481, -1.416823275862069 ], [ 29.767037721518985, -1.416823275862069 ], [ 29.767037721518985, -1.416553793103448 ], [ 29.7667682278481, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5637, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.416553793103448 ], [ 29.767037721518985, -1.416823275862069 ], [ 29.767307215189874, -1.416823275862069 ], [ 29.767307215189874, -1.416553793103448 ], [ 29.767037721518985, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5638, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.416553793103448 ], [ 29.767307215189874, -1.416823275862069 ], [ 29.767576708860759, -1.416823275862069 ], [ 29.767576708860759, -1.416553793103448 ], [ 29.767307215189874, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5639, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.416553793103448 ], [ 29.767576708860759, -1.416823275862069 ], [ 29.767846202531643, -1.416823275862069 ], [ 29.767846202531643, -1.416553793103448 ], [ 29.767576708860759, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5640, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.416553793103448 ], [ 29.767846202531643, -1.416823275862069 ], [ 29.768115696202532, -1.416823275862069 ], [ 29.768115696202532, -1.416553793103448 ], [ 29.767846202531643, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5641, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.416553793103448 ], [ 29.768115696202532, -1.416823275862069 ], [ 29.768385189873417, -1.416823275862069 ], [ 29.768385189873417, -1.416553793103448 ], [ 29.768115696202532, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5642, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.416553793103448 ], [ 29.768385189873417, -1.416823275862069 ], [ 29.768654683544302, -1.416823275862069 ], [ 29.768654683544302, -1.416553793103448 ], [ 29.768385189873417, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5643, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.416553793103448 ], [ 29.768654683544302, -1.416823275862069 ], [ 29.76892417721519, -1.416823275862069 ], [ 29.76892417721519, -1.416553793103448 ], [ 29.768654683544302, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5644, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.416553793103448 ], [ 29.76892417721519, -1.416823275862069 ], [ 29.769193670886075, -1.416823275862069 ], [ 29.769193670886075, -1.416553793103448 ], [ 29.76892417721519, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5645, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.416553793103448 ], [ 29.769193670886075, -1.416823275862069 ], [ 29.76946316455696, -1.416823275862069 ], [ 29.76946316455696, -1.416553793103448 ], [ 29.769193670886075, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5646, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.416553793103448 ], [ 29.76946316455696, -1.416823275862069 ], [ 29.769732658227849, -1.416823275862069 ], [ 29.769732658227849, -1.416553793103448 ], [ 29.76946316455696, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5647, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.416553793103448 ], [ 29.769732658227849, -1.416823275862069 ], [ 29.770002151898733, -1.416823275862069 ], [ 29.770002151898733, -1.416553793103448 ], [ 29.769732658227849, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5648, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.416553793103448 ], [ 29.770002151898733, -1.416823275862069 ], [ 29.770271645569618, -1.416823275862069 ], [ 29.770271645569618, -1.416553793103448 ], [ 29.770002151898733, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5649, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.416553793103448 ], [ 29.770271645569618, -1.416823275862069 ], [ 29.770541139240507, -1.416823275862069 ], [ 29.770541139240507, -1.416553793103448 ], [ 29.770271645569618, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5650, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.416553793103448 ], [ 29.770541139240507, -1.416823275862069 ], [ 29.770810632911392, -1.416823275862069 ], [ 29.770810632911392, -1.416553793103448 ], [ 29.770541139240507, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5651, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.416553793103448 ], [ 29.770810632911392, -1.416823275862069 ], [ 29.771080126582277, -1.416823275862069 ], [ 29.771080126582277, -1.416553793103448 ], [ 29.770810632911392, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5652, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.416553793103448 ], [ 29.771080126582277, -1.416823275862069 ], [ 29.771349620253165, -1.416823275862069 ], [ 29.771349620253165, -1.416553793103448 ], [ 29.771080126582277, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5653, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.416553793103448 ], [ 29.771349620253165, -1.416823275862069 ], [ 29.77161911392405, -1.416823275862069 ], [ 29.77161911392405, -1.416553793103448 ], [ 29.771349620253165, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5654, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.416553793103448 ], [ 29.77161911392405, -1.416823275862069 ], [ 29.771888607594935, -1.416823275862069 ], [ 29.771888607594935, -1.416553793103448 ], [ 29.77161911392405, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5655, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.416553793103448 ], [ 29.771888607594935, -1.416823275862069 ], [ 29.772158101265823, -1.416823275862069 ], [ 29.772158101265823, -1.416553793103448 ], [ 29.771888607594935, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5656, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.416553793103448 ], [ 29.772158101265823, -1.416823275862069 ], [ 29.772427594936708, -1.416823275862069 ], [ 29.772427594936708, -1.416553793103448 ], [ 29.772158101265823, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5657, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.416553793103448 ], [ 29.772427594936708, -1.416823275862069 ], [ 29.772697088607593, -1.416823275862069 ], [ 29.772697088607593, -1.416553793103448 ], [ 29.772427594936708, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5658, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.416553793103448 ], [ 29.772966582278482, -1.416823275862069 ], [ 29.773236075949367, -1.416823275862069 ], [ 29.773236075949367, -1.416553793103448 ], [ 29.772966582278482, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5659, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.416553793103448 ], [ 29.773505569620252, -1.416823275862069 ], [ 29.77377506329114, -1.416823275862069 ], [ 29.77377506329114, -1.416553793103448 ], [ 29.773505569620252, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5660, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.416553793103448 ], [ 29.77377506329114, -1.416823275862069 ], [ 29.774044556962025, -1.416823275862069 ], [ 29.774044556962025, -1.416553793103448 ], [ 29.77377506329114, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5661, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.416553793103448 ], [ 29.774044556962025, -1.41709275862069 ], [ 29.77431405063291, -1.41709275862069 ], [ 29.77431405063291, -1.416553793103448 ], [ 29.774044556962025, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5662, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.416553793103448 ], [ 29.77431405063291, -1.416823275862069 ], [ 29.774853037974683, -1.416823275862069 ], [ 29.774853037974683, -1.416553793103448 ], [ 29.77431405063291, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5663, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.416553793103448 ], [ 29.774853037974683, -1.416823275862069 ], [ 29.775122531645568, -1.416823275862069 ], [ 29.775122531645568, -1.416553793103448 ], [ 29.774853037974683, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5664, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.416553793103448 ], [ 29.775122531645568, -1.416823275862069 ], [ 29.775392025316457, -1.416823275862069 ], [ 29.775392025316457, -1.416553793103448 ], [ 29.775122531645568, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5665, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.416553793103448 ], [ 29.775661518987341, -1.416823275862069 ], [ 29.775931012658226, -1.416823275862069 ], [ 29.775931012658226, -1.416553793103448 ], [ 29.775661518987341, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5666, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.416553793103448 ], [ 29.775931012658226, -1.416823275862069 ], [ 29.776200506329115, -1.416823275862069 ], [ 29.776200506329115, -1.416553793103448 ], [ 29.775931012658226, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5667, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.416553793103448 ], [ 29.776200506329115, -1.416823275862069 ], [ 29.77647, -1.416823275862069 ], [ 29.77647, -1.416553793103448 ], [ 29.776200506329115, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5668, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.416553793103448 ], [ 29.77647, -1.416823275862069 ], [ 29.776739493670885, -1.416823275862069 ], [ 29.776739493670885, -1.416553793103448 ], [ 29.77647, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5669, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.416553793103448 ], [ 29.776739493670885, -1.416823275862069 ], [ 29.777008987341773, -1.416823275862069 ], [ 29.777008987341773, -1.416553793103448 ], [ 29.776739493670885, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5670, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.416553793103448 ], [ 29.777008987341773, -1.416823275862069 ], [ 29.777278481012658, -1.416823275862069 ], [ 29.777278481012658, -1.416553793103448 ], [ 29.777008987341773, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5671, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.416553793103448 ], [ 29.777278481012658, -1.416823275862069 ], [ 29.777547974683543, -1.416823275862069 ], [ 29.777547974683543, -1.416553793103448 ], [ 29.777278481012658, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5672, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.416553793103448 ], [ 29.777547974683543, -1.416823275862069 ], [ 29.777817468354431, -1.416823275862069 ], [ 29.777817468354431, -1.416553793103448 ], [ 29.777547974683543, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5673, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.416553793103448 ], [ 29.777817468354431, -1.41709275862069 ], [ 29.778086962025316, -1.41709275862069 ], [ 29.778086962025316, -1.416553793103448 ], [ 29.777817468354431, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5674, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.416553793103448 ], [ 29.778086962025316, -1.416823275862069 ], [ 29.778356455696201, -1.416823275862069 ], [ 29.778356455696201, -1.416553793103448 ], [ 29.778086962025316, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5675, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.416553793103448 ], [ 29.778356455696201, -1.416823275862069 ], [ 29.77862594936709, -1.416823275862069 ], [ 29.77862594936709, -1.416553793103448 ], [ 29.778356455696201, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5676, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.416553793103448 ], [ 29.77862594936709, -1.41709275862069 ], [ 29.778895443037975, -1.41709275862069 ], [ 29.778895443037975, -1.416553793103448 ], [ 29.77862594936709, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5677, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.416553793103448 ], [ 29.77916493670886, -1.416823275862069 ], [ 29.779434430379748, -1.416823275862069 ], [ 29.779434430379748, -1.416553793103448 ], [ 29.77916493670886, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5678, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.416553793103448 ], [ 29.779434430379748, -1.41709275862069 ], [ 29.779703924050633, -1.41709275862069 ], [ 29.779703924050633, -1.416553793103448 ], [ 29.779434430379748, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5679, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.416553793103448 ], [ 29.780242911392406, -1.41709275862069 ], [ 29.780512405063291, -1.41709275862069 ], [ 29.780512405063291, -1.416553793103448 ], [ 29.780242911392406, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5680, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.416553793103448 ], [ 29.781051392405065, -1.41709275862069 ], [ 29.78132088607595, -1.41709275862069 ], [ 29.78132088607595, -1.416553793103448 ], [ 29.781051392405065, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5681, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.416553793103448 ], [ 29.782398860759493, -1.41709275862069 ], [ 29.782668354430381, -1.41709275862069 ], [ 29.782668354430381, -1.416553793103448 ], [ 29.782398860759493, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5682, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.416553793103448 ], [ 29.783207341772151, -1.416823275862069 ], [ 29.783476835443039, -1.416823275862069 ], [ 29.783476835443039, -1.416553793103448 ], [ 29.783207341772151, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5683, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.416553793103448 ], [ 29.783476835443039, -1.416823275862069 ], [ 29.784015822784809, -1.416823275862069 ], [ 29.784015822784809, -1.416553793103448 ], [ 29.783476835443039, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5684, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.416553793103448 ], [ 29.784015822784809, -1.416823275862069 ], [ 29.784285316455698, -1.416823275862069 ], [ 29.784285316455698, -1.416553793103448 ], [ 29.784015822784809, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5685, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.416553793103448 ], [ 29.79991594936709, -1.416823275862069 ], [ 29.800185443037975, -1.416823275862069 ], [ 29.800185443037975, -1.416553793103448 ], [ 29.79991594936709, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5686, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.416553793103448 ], [ 29.800185443037975, -1.416823275862069 ], [ 29.80045493670886, -1.416823275862069 ], [ 29.80045493670886, -1.416553793103448 ], [ 29.800185443037975, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5687, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.416553793103448 ], [ 29.80045493670886, -1.416823275862069 ], [ 29.800724430379748, -1.416823275862069 ], [ 29.800724430379748, -1.416553793103448 ], [ 29.80045493670886, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5688, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.416553793103448 ], [ 29.800724430379748, -1.416823275862069 ], [ 29.800993924050633, -1.416823275862069 ], [ 29.800993924050633, -1.416553793103448 ], [ 29.800724430379748, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5689, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.416553793103448 ], [ 29.800993924050633, -1.416823275862069 ], [ 29.801263417721518, -1.416823275862069 ], [ 29.801263417721518, -1.416553793103448 ], [ 29.800993924050633, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5690, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.416553793103448 ], [ 29.801263417721518, -1.416823275862069 ], [ 29.801532911392407, -1.416823275862069 ], [ 29.801532911392407, -1.416553793103448 ], [ 29.801263417721518, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5691, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.416823275862069 ], [ 29.759491898734176, -1.41709275862069 ], [ 29.759761392405064, -1.41709275862069 ], [ 29.759761392405064, -1.416823275862069 ], [ 29.759491898734176, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5692, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.416823275862069 ], [ 29.759761392405064, -1.41709275862069 ], [ 29.760030886075949, -1.41709275862069 ], [ 29.760030886075949, -1.416823275862069 ], [ 29.759761392405064, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5693, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.416823275862069 ], [ 29.760030886075949, -1.41709275862069 ], [ 29.760300379746834, -1.41709275862069 ], [ 29.760300379746834, -1.416823275862069 ], [ 29.760030886075949, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5694, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.416823275862069 ], [ 29.760300379746834, -1.41709275862069 ], [ 29.760569873417722, -1.41709275862069 ], [ 29.760569873417722, -1.416823275862069 ], [ 29.760300379746834, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5695, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.416823275862069 ], [ 29.760569873417722, -1.417631724137931 ], [ 29.760839367088607, -1.417631724137931 ], [ 29.760839367088607, -1.416823275862069 ], [ 29.760569873417722, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5696, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.416823275862069 ], [ 29.760839367088607, -1.41709275862069 ], [ 29.761108860759492, -1.41709275862069 ], [ 29.761108860759492, -1.416823275862069 ], [ 29.760839367088607, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5697, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.416823275862069 ], [ 29.761108860759492, -1.41709275862069 ], [ 29.761378354430381, -1.41709275862069 ], [ 29.761378354430381, -1.416823275862069 ], [ 29.761108860759492, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5698, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.416823275862069 ], [ 29.761378354430381, -1.41709275862069 ], [ 29.762995316455697, -1.41709275862069 ], [ 29.762995316455697, -1.416823275862069 ], [ 29.761378354430381, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5699, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.416823275862069 ], [ 29.762995316455697, -1.41709275862069 ], [ 29.763264810126582, -1.41709275862069 ], [ 29.763264810126582, -1.416823275862069 ], [ 29.762995316455697, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5700, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.416823275862069 ], [ 29.763264810126582, -1.41709275862069 ], [ 29.763534303797467, -1.41709275862069 ], [ 29.763534303797467, -1.416823275862069 ], [ 29.763264810126582, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5701, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.416823275862069 ], [ 29.763534303797467, -1.41709275862069 ], [ 29.763803797468356, -1.41709275862069 ], [ 29.763803797468356, -1.416823275862069 ], [ 29.763534303797467, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5702, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.416823275862069 ], [ 29.763803797468356, -1.41709275862069 ], [ 29.76407329113924, -1.41709275862069 ], [ 29.76407329113924, -1.416823275862069 ], [ 29.763803797468356, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5703, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.416823275862069 ], [ 29.76407329113924, -1.41709275862069 ], [ 29.764342784810125, -1.41709275862069 ], [ 29.764342784810125, -1.416823275862069 ], [ 29.76407329113924, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5704, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.416823275862069 ], [ 29.764342784810125, -1.41709275862069 ], [ 29.764612278481014, -1.41709275862069 ], [ 29.764612278481014, -1.416823275862069 ], [ 29.764342784810125, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5705, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.416823275862069 ], [ 29.764612278481014, -1.41709275862069 ], [ 29.764881772151899, -1.41709275862069 ], [ 29.764881772151899, -1.416823275862069 ], [ 29.764612278481014, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5706, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.416823275862069 ], [ 29.764881772151899, -1.41709275862069 ], [ 29.765151265822784, -1.41709275862069 ], [ 29.765151265822784, -1.416823275862069 ], [ 29.764881772151899, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5707, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.416823275862069 ], [ 29.765151265822784, -1.41709275862069 ], [ 29.765420759493672, -1.41709275862069 ], [ 29.765420759493672, -1.416823275862069 ], [ 29.765151265822784, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5708, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.416823275862069 ], [ 29.765420759493672, -1.41709275862069 ], [ 29.765959746835442, -1.41709275862069 ], [ 29.765959746835442, -1.416823275862069 ], [ 29.765420759493672, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5709, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.416823275862069 ], [ 29.765959746835442, -1.41709275862069 ], [ 29.766229240506327, -1.41709275862069 ], [ 29.766229240506327, -1.416823275862069 ], [ 29.765959746835442, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5710, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.416823275862069 ], [ 29.766229240506327, -1.41709275862069 ], [ 29.766498734177215, -1.41709275862069 ], [ 29.766498734177215, -1.416823275862069 ], [ 29.766229240506327, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5711, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.416823275862069 ], [ 29.766498734177215, -1.41709275862069 ], [ 29.7667682278481, -1.41709275862069 ], [ 29.7667682278481, -1.416823275862069 ], [ 29.766498734177215, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5712, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.416823275862069 ], [ 29.7667682278481, -1.41709275862069 ], [ 29.767037721518985, -1.41709275862069 ], [ 29.767037721518985, -1.416823275862069 ], [ 29.7667682278481, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5713, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.416823275862069 ], [ 29.767037721518985, -1.41709275862069 ], [ 29.767307215189874, -1.41709275862069 ], [ 29.767307215189874, -1.416823275862069 ], [ 29.767037721518985, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5714, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.416823275862069 ], [ 29.767307215189874, -1.41709275862069 ], [ 29.767576708860759, -1.41709275862069 ], [ 29.767576708860759, -1.416823275862069 ], [ 29.767307215189874, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5715, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.416823275862069 ], [ 29.767576708860759, -1.41709275862069 ], [ 29.767846202531643, -1.41709275862069 ], [ 29.767846202531643, -1.416823275862069 ], [ 29.767576708860759, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5716, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.416823275862069 ], [ 29.767846202531643, -1.41709275862069 ], [ 29.768115696202532, -1.41709275862069 ], [ 29.768115696202532, -1.416823275862069 ], [ 29.767846202531643, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5717, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.416823275862069 ], [ 29.768115696202532, -1.41709275862069 ], [ 29.768385189873417, -1.41709275862069 ], [ 29.768385189873417, -1.416823275862069 ], [ 29.768115696202532, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5718, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.416823275862069 ], [ 29.768385189873417, -1.41709275862069 ], [ 29.768654683544302, -1.41709275862069 ], [ 29.768654683544302, -1.416823275862069 ], [ 29.768385189873417, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5719, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.416823275862069 ], [ 29.768654683544302, -1.41709275862069 ], [ 29.76892417721519, -1.41709275862069 ], [ 29.76892417721519, -1.416823275862069 ], [ 29.768654683544302, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5720, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.416823275862069 ], [ 29.76892417721519, -1.41709275862069 ], [ 29.769193670886075, -1.41709275862069 ], [ 29.769193670886075, -1.416823275862069 ], [ 29.76892417721519, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5721, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.416823275862069 ], [ 29.769193670886075, -1.41709275862069 ], [ 29.76946316455696, -1.41709275862069 ], [ 29.76946316455696, -1.416823275862069 ], [ 29.769193670886075, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5722, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.416823275862069 ], [ 29.76946316455696, -1.41709275862069 ], [ 29.769732658227849, -1.41709275862069 ], [ 29.769732658227849, -1.416823275862069 ], [ 29.76946316455696, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5723, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.416823275862069 ], [ 29.769732658227849, -1.41709275862069 ], [ 29.770002151898733, -1.41709275862069 ], [ 29.770002151898733, -1.416823275862069 ], [ 29.769732658227849, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5724, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.416823275862069 ], [ 29.770002151898733, -1.41709275862069 ], [ 29.770271645569618, -1.41709275862069 ], [ 29.770271645569618, -1.416823275862069 ], [ 29.770002151898733, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5725, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.416823275862069 ], [ 29.770271645569618, -1.41709275862069 ], [ 29.770541139240507, -1.41709275862069 ], [ 29.770541139240507, -1.416823275862069 ], [ 29.770271645569618, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5726, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.416823275862069 ], [ 29.770541139240507, -1.41709275862069 ], [ 29.770810632911392, -1.41709275862069 ], [ 29.770810632911392, -1.416823275862069 ], [ 29.770541139240507, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5727, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.416823275862069 ], [ 29.770810632911392, -1.41709275862069 ], [ 29.771080126582277, -1.41709275862069 ], [ 29.771080126582277, -1.416823275862069 ], [ 29.770810632911392, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5728, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.416823275862069 ], [ 29.771080126582277, -1.41709275862069 ], [ 29.771349620253165, -1.41709275862069 ], [ 29.771349620253165, -1.416823275862069 ], [ 29.771080126582277, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5729, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.416823275862069 ], [ 29.771349620253165, -1.41709275862069 ], [ 29.77161911392405, -1.41709275862069 ], [ 29.77161911392405, -1.416823275862069 ], [ 29.771349620253165, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5730, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.416823275862069 ], [ 29.77161911392405, -1.41709275862069 ], [ 29.771888607594935, -1.41709275862069 ], [ 29.771888607594935, -1.416823275862069 ], [ 29.77161911392405, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5731, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.416823275862069 ], [ 29.771888607594935, -1.41709275862069 ], [ 29.772158101265823, -1.41709275862069 ], [ 29.772158101265823, -1.416823275862069 ], [ 29.771888607594935, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5732, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.416823275862069 ], [ 29.772158101265823, -1.41709275862069 ], [ 29.772427594936708, -1.41709275862069 ], [ 29.772427594936708, -1.416823275862069 ], [ 29.772158101265823, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5733, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.416823275862069 ], [ 29.772427594936708, -1.41709275862069 ], [ 29.772697088607593, -1.41709275862069 ], [ 29.772697088607593, -1.416823275862069 ], [ 29.772427594936708, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5734, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.416823275862069 ], [ 29.772697088607593, -1.41709275862069 ], [ 29.772966582278482, -1.41709275862069 ], [ 29.772966582278482, -1.416823275862069 ], [ 29.772697088607593, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5735, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.416823275862069 ], [ 29.772966582278482, -1.417631724137931 ], [ 29.773236075949367, -1.417631724137931 ], [ 29.773236075949367, -1.416823275862069 ], [ 29.772966582278482, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5736, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.416823275862069 ], [ 29.773236075949367, -1.41709275862069 ], [ 29.773505569620252, -1.41709275862069 ], [ 29.773505569620252, -1.416823275862069 ], [ 29.773236075949367, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5737, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.416823275862069 ], [ 29.773505569620252, -1.41709275862069 ], [ 29.77377506329114, -1.41709275862069 ], [ 29.77377506329114, -1.416823275862069 ], [ 29.773505569620252, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5738, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.416823275862069 ], [ 29.77377506329114, -1.41709275862069 ], [ 29.774044556962025, -1.41709275862069 ], [ 29.774044556962025, -1.416823275862069 ], [ 29.77377506329114, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5739, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.416823275862069 ], [ 29.77431405063291, -1.41709275862069 ], [ 29.774853037974683, -1.41709275862069 ], [ 29.774853037974683, -1.416823275862069 ], [ 29.77431405063291, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5740, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.416823275862069 ], [ 29.774853037974683, -1.41709275862069 ], [ 29.775122531645568, -1.41709275862069 ], [ 29.775122531645568, -1.416823275862069 ], [ 29.774853037974683, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5741, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.416823275862069 ], [ 29.775122531645568, -1.41709275862069 ], [ 29.775392025316457, -1.41709275862069 ], [ 29.775392025316457, -1.416823275862069 ], [ 29.775122531645568, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5742, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.416823275862069 ], [ 29.775392025316457, -1.41709275862069 ], [ 29.775661518987341, -1.41709275862069 ], [ 29.775661518987341, -1.416823275862069 ], [ 29.775392025316457, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5743, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.416823275862069 ], [ 29.775661518987341, -1.41709275862069 ], [ 29.775931012658226, -1.41709275862069 ], [ 29.775931012658226, -1.416823275862069 ], [ 29.775661518987341, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5744, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.416823275862069 ], [ 29.775931012658226, -1.41709275862069 ], [ 29.776200506329115, -1.41709275862069 ], [ 29.776200506329115, -1.416823275862069 ], [ 29.775931012658226, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5745, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.416823275862069 ], [ 29.776200506329115, -1.41709275862069 ], [ 29.77647, -1.41709275862069 ], [ 29.77647, -1.416823275862069 ], [ 29.776200506329115, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5746, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.416823275862069 ], [ 29.77647, -1.41709275862069 ], [ 29.776739493670885, -1.41709275862069 ], [ 29.776739493670885, -1.416823275862069 ], [ 29.77647, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5747, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.416823275862069 ], [ 29.776739493670885, -1.417631724137931 ], [ 29.777008987341773, -1.417631724137931 ], [ 29.777008987341773, -1.416823275862069 ], [ 29.776739493670885, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5748, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.416823275862069 ], [ 29.777008987341773, -1.41709275862069 ], [ 29.777278481012658, -1.41709275862069 ], [ 29.777278481012658, -1.416823275862069 ], [ 29.777008987341773, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5749, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.416823275862069 ], [ 29.777278481012658, -1.41709275862069 ], [ 29.777547974683543, -1.41709275862069 ], [ 29.777547974683543, -1.416823275862069 ], [ 29.777278481012658, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5750, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.416823275862069 ], [ 29.777547974683543, -1.417631724137931 ], [ 29.777817468354431, -1.417631724137931 ], [ 29.777817468354431, -1.416823275862069 ], [ 29.777547974683543, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5751, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.416823275862069 ], [ 29.778086962025316, -1.417631724137931 ], [ 29.778356455696201, -1.417631724137931 ], [ 29.778356455696201, -1.416823275862069 ], [ 29.778086962025316, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5752, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.416823275862069 ], [ 29.778356455696201, -1.417631724137931 ], [ 29.77862594936709, -1.417631724137931 ], [ 29.77862594936709, -1.416823275862069 ], [ 29.778356455696201, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5753, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.416823275862069 ], [ 29.778895443037975, -1.417631724137931 ], [ 29.77916493670886, -1.417631724137931 ], [ 29.77916493670886, -1.416823275862069 ], [ 29.778895443037975, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5754, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.416823275862069 ], [ 29.77916493670886, -1.417631724137931 ], [ 29.779434430379748, -1.417631724137931 ], [ 29.779434430379748, -1.416823275862069 ], [ 29.77916493670886, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5755, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.416823275862069 ], [ 29.779703924050633, -1.417631724137931 ], [ 29.779973417721518, -1.417631724137931 ], [ 29.779973417721518, -1.416823275862069 ], [ 29.779703924050633, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5756, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.416823275862069 ], [ 29.780512405063291, -1.417631724137931 ], [ 29.780781898734176, -1.417631724137931 ], [ 29.780781898734176, -1.416823275862069 ], [ 29.780512405063291, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5757, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.416823275862069 ], [ 29.78132088607595, -1.417631724137931 ], [ 29.781590379746834, -1.417631724137931 ], [ 29.781590379746834, -1.416823275862069 ], [ 29.78132088607595, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5758, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.416823275862069 ], [ 29.781859873417723, -1.41709275862069 ], [ 29.782129367088608, -1.41709275862069 ], [ 29.782129367088608, -1.416823275862069 ], [ 29.781859873417723, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5759, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.416823275862069 ], [ 29.782129367088608, -1.41709275862069 ], [ 29.782398860759493, -1.41709275862069 ], [ 29.782398860759493, -1.416823275862069 ], [ 29.782129367088608, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5760, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.416823275862069 ], [ 29.782668354430381, -1.41709275862069 ], [ 29.782937848101266, -1.41709275862069 ], [ 29.782937848101266, -1.416823275862069 ], [ 29.782668354430381, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5761, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.416823275862069 ], [ 29.782937848101266, -1.41709275862069 ], [ 29.783207341772151, -1.41709275862069 ], [ 29.783207341772151, -1.416823275862069 ], [ 29.782937848101266, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5762, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.416823275862069 ], [ 29.783207341772151, -1.41709275862069 ], [ 29.783476835443039, -1.41709275862069 ], [ 29.783476835443039, -1.416823275862069 ], [ 29.783207341772151, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5763, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.416823275862069 ], [ 29.79991594936709, -1.417631724137931 ], [ 29.800185443037975, -1.417631724137931 ], [ 29.800185443037975, -1.416823275862069 ], [ 29.79991594936709, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5764, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.416823275862069 ], [ 29.800185443037975, -1.41709275862069 ], [ 29.80045493670886, -1.41709275862069 ], [ 29.80045493670886, -1.416823275862069 ], [ 29.800185443037975, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5765, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.416823275862069 ], [ 29.80045493670886, -1.41709275862069 ], [ 29.800724430379748, -1.41709275862069 ], [ 29.800724430379748, -1.416823275862069 ], [ 29.80045493670886, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5766, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.416823275862069 ], [ 29.800724430379748, -1.41709275862069 ], [ 29.800993924050633, -1.41709275862069 ], [ 29.800993924050633, -1.416823275862069 ], [ 29.800724430379748, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5767, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.416823275862069 ], [ 29.800993924050633, -1.41709275862069 ], [ 29.801263417721518, -1.41709275862069 ], [ 29.801263417721518, -1.416823275862069 ], [ 29.800993924050633, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5768, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.416823275862069 ], [ 29.801263417721518, -1.41709275862069 ], [ 29.801532911392407, -1.41709275862069 ], [ 29.801532911392407, -1.416823275862069 ], [ 29.801263417721518, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5769, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.416823275862069 ], [ 29.801532911392407, -1.41709275862069 ], [ 29.802071898734177, -1.41709275862069 ], [ 29.802071898734177, -1.416823275862069 ], [ 29.801532911392407, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5770, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.41709275862069 ], [ 29.758683417721517, -1.417631724137931 ], [ 29.758952911392406, -1.417631724137931 ], [ 29.758952911392406, -1.41709275862069 ], [ 29.758683417721517, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5771, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.41709275862069 ], [ 29.758952911392406, -1.417631724137931 ], [ 29.759222405063291, -1.417631724137931 ], [ 29.759222405063291, -1.41709275862069 ], [ 29.758952911392406, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5772, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.41709275862069 ], [ 29.759222405063291, -1.417631724137931 ], [ 29.759491898734176, -1.417631724137931 ], [ 29.759491898734176, -1.41709275862069 ], [ 29.759222405063291, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5773, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.41709275862069 ], [ 29.759491898734176, -1.417901206896552 ], [ 29.759761392405064, -1.417901206896552 ], [ 29.759761392405064, -1.41709275862069 ], [ 29.759491898734176, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5774, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.41709275862069 ], [ 29.759761392405064, -1.417631724137931 ], [ 29.760030886075949, -1.417631724137931 ], [ 29.760030886075949, -1.41709275862069 ], [ 29.759761392405064, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5775, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.41709275862069 ], [ 29.760030886075949, -1.417631724137931 ], [ 29.760300379746834, -1.417631724137931 ], [ 29.760300379746834, -1.41709275862069 ], [ 29.760030886075949, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5776, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.41709275862069 ], [ 29.760839367088607, -1.417901206896552 ], [ 29.761108860759492, -1.417901206896552 ], [ 29.761108860759492, -1.41709275862069 ], [ 29.760839367088607, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5777, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.41709275862069 ], [ 29.761378354430381, -1.417631724137931 ], [ 29.761647848101266, -1.417631724137931 ], [ 29.761647848101266, -1.41709275862069 ], [ 29.761378354430381, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5778, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.41709275862069 ], [ 29.761647848101266, -1.417631724137931 ], [ 29.762725822784809, -1.417631724137931 ], [ 29.762725822784809, -1.41709275862069 ], [ 29.761647848101266, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5779, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.41709275862069 ], [ 29.762725822784809, -1.417631724137931 ], [ 29.763264810126582, -1.417631724137931 ], [ 29.763264810126582, -1.41709275862069 ], [ 29.762725822784809, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5780, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.41709275862069 ], [ 29.763264810126582, -1.417631724137931 ], [ 29.763534303797467, -1.417631724137931 ], [ 29.763534303797467, -1.41709275862069 ], [ 29.763264810126582, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5781, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.41709275862069 ], [ 29.763534303797467, -1.417631724137931 ], [ 29.763803797468356, -1.417631724137931 ], [ 29.763803797468356, -1.41709275862069 ], [ 29.763534303797467, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5782, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.41709275862069 ], [ 29.763803797468356, -1.417631724137931 ], [ 29.76407329113924, -1.417631724137931 ], [ 29.76407329113924, -1.41709275862069 ], [ 29.763803797468356, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5783, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.41709275862069 ], [ 29.76407329113924, -1.417631724137931 ], [ 29.764342784810125, -1.417631724137931 ], [ 29.764342784810125, -1.41709275862069 ], [ 29.76407329113924, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5784, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.41709275862069 ], [ 29.764342784810125, -1.417631724137931 ], [ 29.764612278481014, -1.417631724137931 ], [ 29.764612278481014, -1.41709275862069 ], [ 29.764342784810125, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5785, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.41709275862069 ], [ 29.764612278481014, -1.417631724137931 ], [ 29.764881772151899, -1.417631724137931 ], [ 29.764881772151899, -1.41709275862069 ], [ 29.764612278481014, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5786, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.41709275862069 ], [ 29.764881772151899, -1.417631724137931 ], [ 29.765151265822784, -1.417631724137931 ], [ 29.765151265822784, -1.41709275862069 ], [ 29.764881772151899, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5787, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.41709275862069 ], [ 29.765151265822784, -1.417631724137931 ], [ 29.765420759493672, -1.417631724137931 ], [ 29.765420759493672, -1.41709275862069 ], [ 29.765151265822784, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5788, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.41709275862069 ], [ 29.765420759493672, -1.417631724137931 ], [ 29.765959746835442, -1.417631724137931 ], [ 29.765959746835442, -1.41709275862069 ], [ 29.765420759493672, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5789, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.41709275862069 ], [ 29.765959746835442, -1.417631724137931 ], [ 29.766229240506327, -1.417631724137931 ], [ 29.766229240506327, -1.41709275862069 ], [ 29.765959746835442, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5790, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.41709275862069 ], [ 29.766229240506327, -1.417631724137931 ], [ 29.766498734177215, -1.417631724137931 ], [ 29.766498734177215, -1.41709275862069 ], [ 29.766229240506327, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5791, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.41709275862069 ], [ 29.766498734177215, -1.417631724137931 ], [ 29.7667682278481, -1.417631724137931 ], [ 29.7667682278481, -1.41709275862069 ], [ 29.766498734177215, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5792, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.41709275862069 ], [ 29.7667682278481, -1.417631724137931 ], [ 29.767037721518985, -1.417631724137931 ], [ 29.767037721518985, -1.41709275862069 ], [ 29.7667682278481, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5793, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.41709275862069 ], [ 29.767037721518985, -1.417631724137931 ], [ 29.767307215189874, -1.417631724137931 ], [ 29.767307215189874, -1.41709275862069 ], [ 29.767037721518985, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5794, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.41709275862069 ], [ 29.767307215189874, -1.417631724137931 ], [ 29.767576708860759, -1.417631724137931 ], [ 29.767576708860759, -1.41709275862069 ], [ 29.767307215189874, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5795, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.41709275862069 ], [ 29.767576708860759, -1.417631724137931 ], [ 29.767846202531643, -1.417631724137931 ], [ 29.767846202531643, -1.41709275862069 ], [ 29.767576708860759, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5796, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.41709275862069 ], [ 29.767846202531643, -1.417631724137931 ], [ 29.768115696202532, -1.417631724137931 ], [ 29.768115696202532, -1.41709275862069 ], [ 29.767846202531643, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5797, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.41709275862069 ], [ 29.768115696202532, -1.417631724137931 ], [ 29.768385189873417, -1.417631724137931 ], [ 29.768385189873417, -1.41709275862069 ], [ 29.768115696202532, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5798, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.41709275862069 ], [ 29.768385189873417, -1.417631724137931 ], [ 29.768654683544302, -1.417631724137931 ], [ 29.768654683544302, -1.41709275862069 ], [ 29.768385189873417, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5799, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.41709275862069 ], [ 29.768654683544302, -1.417631724137931 ], [ 29.76892417721519, -1.417631724137931 ], [ 29.76892417721519, -1.41709275862069 ], [ 29.768654683544302, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5800, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.41709275862069 ], [ 29.76892417721519, -1.417631724137931 ], [ 29.769193670886075, -1.417631724137931 ], [ 29.769193670886075, -1.41709275862069 ], [ 29.76892417721519, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5801, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.41709275862069 ], [ 29.769193670886075, -1.417631724137931 ], [ 29.76946316455696, -1.417631724137931 ], [ 29.76946316455696, -1.41709275862069 ], [ 29.769193670886075, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5802, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.41709275862069 ], [ 29.76946316455696, -1.417631724137931 ], [ 29.769732658227849, -1.417631724137931 ], [ 29.769732658227849, -1.41709275862069 ], [ 29.76946316455696, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5803, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.41709275862069 ], [ 29.769732658227849, -1.417631724137931 ], [ 29.770002151898733, -1.417631724137931 ], [ 29.770002151898733, -1.41709275862069 ], [ 29.769732658227849, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5804, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.41709275862069 ], [ 29.770002151898733, -1.417631724137931 ], [ 29.770271645569618, -1.417631724137931 ], [ 29.770271645569618, -1.41709275862069 ], [ 29.770002151898733, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5805, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.41709275862069 ], [ 29.770271645569618, -1.417631724137931 ], [ 29.770541139240507, -1.417631724137931 ], [ 29.770541139240507, -1.41709275862069 ], [ 29.770271645569618, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5806, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.41709275862069 ], [ 29.770541139240507, -1.417631724137931 ], [ 29.770810632911392, -1.417631724137931 ], [ 29.770810632911392, -1.41709275862069 ], [ 29.770541139240507, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5807, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.41709275862069 ], [ 29.770810632911392, -1.417631724137931 ], [ 29.771080126582277, -1.417631724137931 ], [ 29.771080126582277, -1.41709275862069 ], [ 29.770810632911392, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5808, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.41709275862069 ], [ 29.771080126582277, -1.417631724137931 ], [ 29.771349620253165, -1.417631724137931 ], [ 29.771349620253165, -1.41709275862069 ], [ 29.771080126582277, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5809, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.41709275862069 ], [ 29.771349620253165, -1.417631724137931 ], [ 29.77161911392405, -1.417631724137931 ], [ 29.77161911392405, -1.41709275862069 ], [ 29.771349620253165, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5810, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.41709275862069 ], [ 29.77161911392405, -1.417631724137931 ], [ 29.771888607594935, -1.417631724137931 ], [ 29.771888607594935, -1.41709275862069 ], [ 29.77161911392405, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5811, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.41709275862069 ], [ 29.771888607594935, -1.417631724137931 ], [ 29.772158101265823, -1.417631724137931 ], [ 29.772158101265823, -1.41709275862069 ], [ 29.771888607594935, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5812, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.41709275862069 ], [ 29.772158101265823, -1.417631724137931 ], [ 29.772427594936708, -1.417631724137931 ], [ 29.772427594936708, -1.41709275862069 ], [ 29.772158101265823, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5813, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.41709275862069 ], [ 29.772427594936708, -1.417631724137931 ], [ 29.772697088607593, -1.417631724137931 ], [ 29.772697088607593, -1.41709275862069 ], [ 29.772427594936708, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5814, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.41709275862069 ], [ 29.772697088607593, -1.417631724137931 ], [ 29.772966582278482, -1.417631724137931 ], [ 29.772966582278482, -1.41709275862069 ], [ 29.772697088607593, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5815, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.41709275862069 ], [ 29.773236075949367, -1.417631724137931 ], [ 29.773505569620252, -1.417631724137931 ], [ 29.773505569620252, -1.41709275862069 ], [ 29.773236075949367, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5816, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.41709275862069 ], [ 29.773505569620252, -1.417631724137931 ], [ 29.77377506329114, -1.417631724137931 ], [ 29.77377506329114, -1.41709275862069 ], [ 29.773505569620252, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5817, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.41709275862069 ], [ 29.77377506329114, -1.417631724137931 ], [ 29.774044556962025, -1.417631724137931 ], [ 29.774044556962025, -1.41709275862069 ], [ 29.77377506329114, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5818, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.41709275862069 ], [ 29.774044556962025, -1.417631724137931 ], [ 29.77431405063291, -1.417631724137931 ], [ 29.77431405063291, -1.41709275862069 ], [ 29.774044556962025, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5819, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.41709275862069 ], [ 29.77431405063291, -1.417631724137931 ], [ 29.774853037974683, -1.417631724137931 ], [ 29.774853037974683, -1.41709275862069 ], [ 29.77431405063291, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5820, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.41709275862069 ], [ 29.774853037974683, -1.417631724137931 ], [ 29.775122531645568, -1.417631724137931 ], [ 29.775122531645568, -1.41709275862069 ], [ 29.774853037974683, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5821, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.41709275862069 ], [ 29.775122531645568, -1.417631724137931 ], [ 29.775392025316457, -1.417631724137931 ], [ 29.775392025316457, -1.41709275862069 ], [ 29.775122531645568, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5822, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.41709275862069 ], [ 29.775392025316457, -1.417631724137931 ], [ 29.775661518987341, -1.417631724137931 ], [ 29.775661518987341, -1.41709275862069 ], [ 29.775392025316457, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5823, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.41709275862069 ], [ 29.775661518987341, -1.417631724137931 ], [ 29.775931012658226, -1.417631724137931 ], [ 29.775931012658226, -1.41709275862069 ], [ 29.775661518987341, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5824, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.41709275862069 ], [ 29.775931012658226, -1.417631724137931 ], [ 29.776200506329115, -1.417631724137931 ], [ 29.776200506329115, -1.41709275862069 ], [ 29.775931012658226, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5825, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.41709275862069 ], [ 29.776200506329115, -1.417631724137931 ], [ 29.77647, -1.417631724137931 ], [ 29.77647, -1.41709275862069 ], [ 29.776200506329115, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5826, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.41709275862069 ], [ 29.77647, -1.417901206896552 ], [ 29.776739493670885, -1.417901206896552 ], [ 29.776739493670885, -1.41709275862069 ], [ 29.77647, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5827, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.41709275862069 ], [ 29.777008987341773, -1.417901206896552 ], [ 29.777278481012658, -1.417901206896552 ], [ 29.777278481012658, -1.41709275862069 ], [ 29.777008987341773, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5828, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.41709275862069 ], [ 29.777278481012658, -1.417901206896552 ], [ 29.777547974683543, -1.417901206896552 ], [ 29.777547974683543, -1.41709275862069 ], [ 29.777278481012658, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5829, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.41709275862069 ], [ 29.777817468354431, -1.417901206896552 ], [ 29.778086962025316, -1.417901206896552 ], [ 29.778086962025316, -1.41709275862069 ], [ 29.777817468354431, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5830, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.41709275862069 ], [ 29.77862594936709, -1.417901206896552 ], [ 29.778895443037975, -1.417901206896552 ], [ 29.778895443037975, -1.41709275862069 ], [ 29.77862594936709, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5831, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.41709275862069 ], [ 29.779434430379748, -1.417901206896552 ], [ 29.779703924050633, -1.417901206896552 ], [ 29.779703924050633, -1.41709275862069 ], [ 29.779434430379748, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5832, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.41709275862069 ], [ 29.779973417721518, -1.417631724137931 ], [ 29.780242911392406, -1.417631724137931 ], [ 29.780242911392406, -1.41709275862069 ], [ 29.779973417721518, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5833, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.41709275862069 ], [ 29.780242911392406, -1.417901206896552 ], [ 29.780512405063291, -1.417901206896552 ], [ 29.780512405063291, -1.41709275862069 ], [ 29.780242911392406, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5834, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.41709275862069 ], [ 29.780781898734176, -1.417631724137931 ], [ 29.781051392405065, -1.417631724137931 ], [ 29.781051392405065, -1.41709275862069 ], [ 29.780781898734176, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5835, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.41709275862069 ], [ 29.781051392405065, -1.417631724137931 ], [ 29.78132088607595, -1.417631724137931 ], [ 29.78132088607595, -1.41709275862069 ], [ 29.781051392405065, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5836, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.41709275862069 ], [ 29.781590379746834, -1.417631724137931 ], [ 29.781859873417723, -1.417631724137931 ], [ 29.781859873417723, -1.41709275862069 ], [ 29.781590379746834, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5837, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.41709275862069 ], [ 29.781859873417723, -1.417631724137931 ], [ 29.782129367088608, -1.417631724137931 ], [ 29.782129367088608, -1.41709275862069 ], [ 29.781859873417723, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5838, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.41709275862069 ], [ 29.782129367088608, -1.417631724137931 ], [ 29.782398860759493, -1.417631724137931 ], [ 29.782398860759493, -1.41709275862069 ], [ 29.782129367088608, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5839, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.41709275862069 ], [ 29.782398860759493, -1.417631724137931 ], [ 29.782668354430381, -1.417631724137931 ], [ 29.782668354430381, -1.41709275862069 ], [ 29.782398860759493, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5840, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.41709275862069 ], [ 29.798837974683543, -1.417631724137931 ], [ 29.799107468354432, -1.417631724137931 ], [ 29.799107468354432, -1.41709275862069 ], [ 29.798837974683543, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5841, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.41709275862069 ], [ 29.799107468354432, -1.417631724137931 ], [ 29.799376962025317, -1.417631724137931 ], [ 29.799376962025317, -1.41709275862069 ], [ 29.799107468354432, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5842, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.41709275862069 ], [ 29.799376962025317, -1.417631724137931 ], [ 29.799646455696202, -1.417631724137931 ], [ 29.799646455696202, -1.41709275862069 ], [ 29.799376962025317, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5843, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.41709275862069 ], [ 29.799646455696202, -1.417631724137931 ], [ 29.79991594936709, -1.417631724137931 ], [ 29.79991594936709, -1.41709275862069 ], [ 29.799646455696202, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5844, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.41709275862069 ], [ 29.800185443037975, -1.417631724137931 ], [ 29.80045493670886, -1.417631724137931 ], [ 29.80045493670886, -1.41709275862069 ], [ 29.800185443037975, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5845, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.41709275862069 ], [ 29.80045493670886, -1.417631724137931 ], [ 29.800724430379748, -1.417631724137931 ], [ 29.800724430379748, -1.41709275862069 ], [ 29.80045493670886, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5846, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.41709275862069 ], [ 29.800724430379748, -1.417631724137931 ], [ 29.800993924050633, -1.417631724137931 ], [ 29.800993924050633, -1.41709275862069 ], [ 29.800724430379748, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5847, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.41709275862069 ], [ 29.800993924050633, -1.417631724137931 ], [ 29.801263417721518, -1.417631724137931 ], [ 29.801263417721518, -1.41709275862069 ], [ 29.800993924050633, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5848, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.41709275862069 ], [ 29.801263417721518, -1.417631724137931 ], [ 29.801532911392407, -1.417631724137931 ], [ 29.801532911392407, -1.41709275862069 ], [ 29.801263417721518, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5849, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.41709275862069 ], [ 29.801532911392407, -1.417631724137931 ], [ 29.802071898734177, -1.417631724137931 ], [ 29.802071898734177, -1.41709275862069 ], [ 29.801532911392407, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5850, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.41709275862069 ], [ 29.802071898734177, -1.417631724137931 ], [ 29.802341392405065, -1.417631724137931 ], [ 29.802341392405065, -1.41709275862069 ], [ 29.802071898734177, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5851, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.417631724137931 ], [ 29.758144430379748, -1.417901206896552 ], [ 29.758413924050632, -1.417901206896552 ], [ 29.758413924050632, -1.417631724137931 ], [ 29.758144430379748, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.417631724137931 ], [ 29.758413924050632, -1.417901206896552 ], [ 29.758683417721517, -1.417901206896552 ], [ 29.758683417721517, -1.417631724137931 ], [ 29.758413924050632, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5853, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.417631724137931 ], [ 29.758683417721517, -1.417901206896552 ], [ 29.758952911392406, -1.417901206896552 ], [ 29.758952911392406, -1.417631724137931 ], [ 29.758683417721517, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5854, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.417631724137931 ], [ 29.762995316455697, -1.417901206896552 ], [ 29.763534303797467, -1.417901206896552 ], [ 29.763534303797467, -1.417631724137931 ], [ 29.762995316455697, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5855, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.417631724137931 ], [ 29.763534303797467, -1.417901206896552 ], [ 29.763803797468356, -1.417901206896552 ], [ 29.763803797468356, -1.417631724137931 ], [ 29.763534303797467, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5856, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.417631724137931 ], [ 29.763803797468356, -1.417901206896552 ], [ 29.76407329113924, -1.417901206896552 ], [ 29.76407329113924, -1.417631724137931 ], [ 29.763803797468356, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5857, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.417631724137931 ], [ 29.76407329113924, -1.417901206896552 ], [ 29.764342784810125, -1.417901206896552 ], [ 29.764342784810125, -1.417631724137931 ], [ 29.76407329113924, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5858, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.417631724137931 ], [ 29.764342784810125, -1.417901206896552 ], [ 29.764612278481014, -1.417901206896552 ], [ 29.764612278481014, -1.417631724137931 ], [ 29.764342784810125, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5859, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.417631724137931 ], [ 29.764612278481014, -1.417901206896552 ], [ 29.764881772151899, -1.417901206896552 ], [ 29.764881772151899, -1.417631724137931 ], [ 29.764612278481014, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5860, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.417631724137931 ], [ 29.764881772151899, -1.417901206896552 ], [ 29.765151265822784, -1.417901206896552 ], [ 29.765151265822784, -1.417631724137931 ], [ 29.764881772151899, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5861, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.417631724137931 ], [ 29.765151265822784, -1.417901206896552 ], [ 29.765420759493672, -1.417901206896552 ], [ 29.765420759493672, -1.417631724137931 ], [ 29.765151265822784, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5862, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.417631724137931 ], [ 29.765420759493672, -1.417901206896552 ], [ 29.765959746835442, -1.417901206896552 ], [ 29.765959746835442, -1.417631724137931 ], [ 29.765420759493672, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5863, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.417631724137931 ], [ 29.765959746835442, -1.417901206896552 ], [ 29.766229240506327, -1.417901206896552 ], [ 29.766229240506327, -1.417631724137931 ], [ 29.765959746835442, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5864, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.417631724137931 ], [ 29.766229240506327, -1.417901206896552 ], [ 29.766498734177215, -1.417901206896552 ], [ 29.766498734177215, -1.417631724137931 ], [ 29.766229240506327, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5865, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.417631724137931 ], [ 29.766498734177215, -1.417901206896552 ], [ 29.7667682278481, -1.417901206896552 ], [ 29.7667682278481, -1.417631724137931 ], [ 29.766498734177215, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5866, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.417631724137931 ], [ 29.7667682278481, -1.417901206896552 ], [ 29.767037721518985, -1.417901206896552 ], [ 29.767037721518985, -1.417631724137931 ], [ 29.7667682278481, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5867, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.417631724137931 ], [ 29.767037721518985, -1.417901206896552 ], [ 29.767307215189874, -1.417901206896552 ], [ 29.767307215189874, -1.417631724137931 ], [ 29.767037721518985, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5868, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.417631724137931 ], [ 29.767307215189874, -1.417901206896552 ], [ 29.767576708860759, -1.417901206896552 ], [ 29.767576708860759, -1.417631724137931 ], [ 29.767307215189874, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5869, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.417631724137931 ], [ 29.767576708860759, -1.417901206896552 ], [ 29.767846202531643, -1.417901206896552 ], [ 29.767846202531643, -1.417631724137931 ], [ 29.767576708860759, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5870, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.417631724137931 ], [ 29.767846202531643, -1.417901206896552 ], [ 29.768115696202532, -1.417901206896552 ], [ 29.768115696202532, -1.417631724137931 ], [ 29.767846202531643, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5871, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.417631724137931 ], [ 29.768115696202532, -1.417901206896552 ], [ 29.768385189873417, -1.417901206896552 ], [ 29.768385189873417, -1.417631724137931 ], [ 29.768115696202532, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5872, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.417631724137931 ], [ 29.768385189873417, -1.417901206896552 ], [ 29.768654683544302, -1.417901206896552 ], [ 29.768654683544302, -1.417631724137931 ], [ 29.768385189873417, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5873, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.417631724137931 ], [ 29.768654683544302, -1.417901206896552 ], [ 29.76892417721519, -1.417901206896552 ], [ 29.76892417721519, -1.417631724137931 ], [ 29.768654683544302, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5874, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.417631724137931 ], [ 29.76892417721519, -1.417901206896552 ], [ 29.769193670886075, -1.417901206896552 ], [ 29.769193670886075, -1.417631724137931 ], [ 29.76892417721519, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5875, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.417631724137931 ], [ 29.769193670886075, -1.417901206896552 ], [ 29.76946316455696, -1.417901206896552 ], [ 29.76946316455696, -1.417631724137931 ], [ 29.769193670886075, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5876, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.417631724137931 ], [ 29.76946316455696, -1.417901206896552 ], [ 29.769732658227849, -1.417901206896552 ], [ 29.769732658227849, -1.417631724137931 ], [ 29.76946316455696, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5877, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.417631724137931 ], [ 29.769732658227849, -1.417901206896552 ], [ 29.770002151898733, -1.417901206896552 ], [ 29.770002151898733, -1.417631724137931 ], [ 29.769732658227849, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5878, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.417631724137931 ], [ 29.770002151898733, -1.417901206896552 ], [ 29.770271645569618, -1.417901206896552 ], [ 29.770271645569618, -1.417631724137931 ], [ 29.770002151898733, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5879, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.417631724137931 ], [ 29.770271645569618, -1.417901206896552 ], [ 29.770541139240507, -1.417901206896552 ], [ 29.770541139240507, -1.417631724137931 ], [ 29.770271645569618, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5880, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.417631724137931 ], [ 29.770541139240507, -1.417901206896552 ], [ 29.770810632911392, -1.417901206896552 ], [ 29.770810632911392, -1.417631724137931 ], [ 29.770541139240507, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5881, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.417631724137931 ], [ 29.770810632911392, -1.417901206896552 ], [ 29.771080126582277, -1.417901206896552 ], [ 29.771080126582277, -1.417631724137931 ], [ 29.770810632911392, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5882, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.417631724137931 ], [ 29.771080126582277, -1.417901206896552 ], [ 29.771349620253165, -1.417901206896552 ], [ 29.771349620253165, -1.417631724137931 ], [ 29.771080126582277, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5883, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.417631724137931 ], [ 29.771349620253165, -1.417901206896552 ], [ 29.77161911392405, -1.417901206896552 ], [ 29.77161911392405, -1.417631724137931 ], [ 29.771349620253165, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5884, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.417631724137931 ], [ 29.77161911392405, -1.417901206896552 ], [ 29.771888607594935, -1.417901206896552 ], [ 29.771888607594935, -1.417631724137931 ], [ 29.77161911392405, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5885, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.417631724137931 ], [ 29.771888607594935, -1.417901206896552 ], [ 29.772158101265823, -1.417901206896552 ], [ 29.772158101265823, -1.417631724137931 ], [ 29.771888607594935, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5886, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.417631724137931 ], [ 29.772158101265823, -1.417901206896552 ], [ 29.772427594936708, -1.417901206896552 ], [ 29.772427594936708, -1.417631724137931 ], [ 29.772158101265823, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5887, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.417631724137931 ], [ 29.772427594936708, -1.417901206896552 ], [ 29.772697088607593, -1.417901206896552 ], [ 29.772697088607593, -1.417631724137931 ], [ 29.772427594936708, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5888, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.417631724137931 ], [ 29.772697088607593, -1.417901206896552 ], [ 29.772966582278482, -1.417901206896552 ], [ 29.772966582278482, -1.417631724137931 ], [ 29.772697088607593, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5889, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.417631724137931 ], [ 29.772966582278482, -1.417901206896552 ], [ 29.773236075949367, -1.417901206896552 ], [ 29.773236075949367, -1.417631724137931 ], [ 29.772966582278482, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5890, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.417631724137931 ], [ 29.773236075949367, -1.417901206896552 ], [ 29.773505569620252, -1.417901206896552 ], [ 29.773505569620252, -1.417631724137931 ], [ 29.773236075949367, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5891, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.417631724137931 ], [ 29.773505569620252, -1.417901206896552 ], [ 29.77377506329114, -1.417901206896552 ], [ 29.77377506329114, -1.417631724137931 ], [ 29.773505569620252, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5892, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.417631724137931 ], [ 29.77377506329114, -1.417901206896552 ], [ 29.774044556962025, -1.417901206896552 ], [ 29.774044556962025, -1.417631724137931 ], [ 29.77377506329114, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5893, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.417631724137931 ], [ 29.774044556962025, -1.417901206896552 ], [ 29.77431405063291, -1.417901206896552 ], [ 29.77431405063291, -1.417631724137931 ], [ 29.774044556962025, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5894, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.417631724137931 ], [ 29.77431405063291, -1.417901206896552 ], [ 29.774853037974683, -1.417901206896552 ], [ 29.774853037974683, -1.417631724137931 ], [ 29.77431405063291, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5895, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.417631724137931 ], [ 29.774853037974683, -1.417901206896552 ], [ 29.775122531645568, -1.417901206896552 ], [ 29.775122531645568, -1.417631724137931 ], [ 29.774853037974683, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5896, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.417631724137931 ], [ 29.775661518987341, -1.417901206896552 ], [ 29.775931012658226, -1.417901206896552 ], [ 29.775931012658226, -1.417631724137931 ], [ 29.775661518987341, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5897, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.417631724137931 ], [ 29.778086962025316, -1.417901206896552 ], [ 29.778356455696201, -1.417901206896552 ], [ 29.778356455696201, -1.417631724137931 ], [ 29.778086962025316, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5898, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.417631724137931 ], [ 29.778895443037975, -1.417901206896552 ], [ 29.77916493670886, -1.417901206896552 ], [ 29.77916493670886, -1.417631724137931 ], [ 29.778895443037975, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5899, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.417631724137931 ], [ 29.77916493670886, -1.417901206896552 ], [ 29.779434430379748, -1.417901206896552 ], [ 29.779434430379748, -1.417631724137931 ], [ 29.77916493670886, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5900, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.417631724137931 ], [ 29.779703924050633, -1.417901206896552 ], [ 29.779973417721518, -1.417901206896552 ], [ 29.779973417721518, -1.417631724137931 ], [ 29.779703924050633, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5901, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.417631724137931 ], [ 29.779973417721518, -1.417901206896552 ], [ 29.780242911392406, -1.417901206896552 ], [ 29.780242911392406, -1.417631724137931 ], [ 29.779973417721518, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5902, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.417631724137931 ], [ 29.780512405063291, -1.417901206896552 ], [ 29.780781898734176, -1.417901206896552 ], [ 29.780781898734176, -1.417631724137931 ], [ 29.780512405063291, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5903, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.417631724137931 ], [ 29.780781898734176, -1.417901206896552 ], [ 29.781051392405065, -1.417901206896552 ], [ 29.781051392405065, -1.417631724137931 ], [ 29.780781898734176, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5904, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.417631724137931 ], [ 29.781051392405065, -1.417901206896552 ], [ 29.78132088607595, -1.417901206896552 ], [ 29.78132088607595, -1.417631724137931 ], [ 29.781051392405065, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5905, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.417631724137931 ], [ 29.781590379746834, -1.417901206896552 ], [ 29.781859873417723, -1.417901206896552 ], [ 29.781859873417723, -1.417631724137931 ], [ 29.781590379746834, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5906, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.417631724137931 ], [ 29.781859873417723, -1.417901206896552 ], [ 29.782129367088608, -1.417901206896552 ], [ 29.782129367088608, -1.417631724137931 ], [ 29.781859873417723, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5907, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.417631724137931 ], [ 29.798298987341774, -1.417901206896552 ], [ 29.798837974683543, -1.417901206896552 ], [ 29.798837974683543, -1.417631724137931 ], [ 29.798298987341774, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5908, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.417631724137931 ], [ 29.798837974683543, -1.417901206896552 ], [ 29.799107468354432, -1.417901206896552 ], [ 29.799107468354432, -1.417631724137931 ], [ 29.798837974683543, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5909, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.417631724137931 ], [ 29.799107468354432, -1.417901206896552 ], [ 29.799376962025317, -1.417901206896552 ], [ 29.799376962025317, -1.417631724137931 ], [ 29.799107468354432, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5910, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.417631724137931 ], [ 29.799376962025317, -1.417901206896552 ], [ 29.799646455696202, -1.417901206896552 ], [ 29.799646455696202, -1.417631724137931 ], [ 29.799376962025317, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5911, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.417631724137931 ], [ 29.799646455696202, -1.417901206896552 ], [ 29.79991594936709, -1.417901206896552 ], [ 29.79991594936709, -1.417631724137931 ], [ 29.799646455696202, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5912, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.417631724137931 ], [ 29.79991594936709, -1.417901206896552 ], [ 29.800185443037975, -1.417901206896552 ], [ 29.800185443037975, -1.417631724137931 ], [ 29.79991594936709, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5913, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.417631724137931 ], [ 29.800185443037975, -1.417901206896552 ], [ 29.80045493670886, -1.417901206896552 ], [ 29.80045493670886, -1.417631724137931 ], [ 29.800185443037975, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5914, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.417631724137931 ], [ 29.80045493670886, -1.417901206896552 ], [ 29.800724430379748, -1.417901206896552 ], [ 29.800724430379748, -1.417631724137931 ], [ 29.80045493670886, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5915, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.417631724137931 ], [ 29.800724430379748, -1.417901206896552 ], [ 29.800993924050633, -1.417901206896552 ], [ 29.800993924050633, -1.417631724137931 ], [ 29.800724430379748, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5916, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.417631724137931 ], [ 29.800993924050633, -1.417901206896552 ], [ 29.801263417721518, -1.417901206896552 ], [ 29.801263417721518, -1.417631724137931 ], [ 29.800993924050633, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5917, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.417631724137931 ], [ 29.801263417721518, -1.417901206896552 ], [ 29.801532911392407, -1.417901206896552 ], [ 29.801532911392407, -1.417631724137931 ], [ 29.801263417721518, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5918, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.417631724137931 ], [ 29.801532911392407, -1.417901206896552 ], [ 29.802071898734177, -1.417901206896552 ], [ 29.802071898734177, -1.417631724137931 ], [ 29.801532911392407, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5919, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.417631724137931 ], [ 29.802071898734177, -1.417901206896552 ], [ 29.802341392405065, -1.417901206896552 ], [ 29.802341392405065, -1.417631724137931 ], [ 29.802071898734177, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5920, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.417631724137931 ], [ 29.802341392405065, -1.417901206896552 ], [ 29.80261088607595, -1.417901206896552 ], [ 29.80261088607595, -1.417631724137931 ], [ 29.802341392405065, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.416553793103448 ], [ 29.784285316455698, -1.419248620689655 ], [ 29.784554810126583, -1.419248620689655 ], [ 29.784554810126583, -1.416553793103448 ], [ 29.784285316455698, -1.416553793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5922, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.416823275862069 ], [ 29.783476835443039, -1.419248620689655 ], [ 29.784015822784809, -1.419248620689655 ], [ 29.784015822784809, -1.416823275862069 ], [ 29.783476835443039, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5923, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.416823275862069 ], [ 29.784015822784809, -1.419248620689655 ], [ 29.784285316455698, -1.419248620689655 ], [ 29.784285316455698, -1.416823275862069 ], [ 29.784015822784809, -1.416823275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5924, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.41709275862069 ], [ 29.782668354430381, -1.419248620689655 ], [ 29.782937848101266, -1.419248620689655 ], [ 29.782937848101266, -1.41709275862069 ], [ 29.782668354430381, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5925, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.41709275862069 ], [ 29.782937848101266, -1.419518103448276 ], [ 29.783207341772151, -1.419518103448276 ], [ 29.783207341772151, -1.41709275862069 ], [ 29.782937848101266, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5926, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.41709275862069 ], [ 29.783207341772151, -1.419518103448276 ], [ 29.783476835443039, -1.419518103448276 ], [ 29.783476835443039, -1.41709275862069 ], [ 29.783207341772151, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5927, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.417631724137931 ], [ 29.775122531645568, -1.418170689655172 ], [ 29.775392025316457, -1.418170689655172 ], [ 29.775392025316457, -1.417631724137931 ], [ 29.775122531645568, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5928, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.417631724137931 ], [ 29.775392025316457, -1.418170689655172 ], [ 29.775661518987341, -1.418170689655172 ], [ 29.775661518987341, -1.417631724137931 ], [ 29.775392025316457, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5929, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.417631724137931 ], [ 29.775931012658226, -1.418170689655172 ], [ 29.776200506329115, -1.418170689655172 ], [ 29.776200506329115, -1.417631724137931 ], [ 29.775931012658226, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5930, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.417631724137931 ], [ 29.776200506329115, -1.418170689655172 ], [ 29.77647, -1.418170689655172 ], [ 29.77647, -1.417631724137931 ], [ 29.776200506329115, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5931, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.417631724137931 ], [ 29.776739493670885, -1.418170689655172 ], [ 29.777008987341773, -1.418170689655172 ], [ 29.777008987341773, -1.417631724137931 ], [ 29.776739493670885, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5932, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.417631724137931 ], [ 29.777547974683543, -1.418170689655172 ], [ 29.777817468354431, -1.418170689655172 ], [ 29.777817468354431, -1.417631724137931 ], [ 29.777547974683543, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5933, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.417631724137931 ], [ 29.778356455696201, -1.418170689655172 ], [ 29.77862594936709, -1.418170689655172 ], [ 29.77862594936709, -1.417631724137931 ], [ 29.778356455696201, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5934, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.417631724137931 ], [ 29.78132088607595, -1.419248620689655 ], [ 29.781590379746834, -1.419248620689655 ], [ 29.781590379746834, -1.417631724137931 ], [ 29.78132088607595, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5935, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.417631724137931 ], [ 29.782129367088608, -1.419518103448276 ], [ 29.782398860759493, -1.419518103448276 ], [ 29.782398860759493, -1.417631724137931 ], [ 29.782129367088608, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5936, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.417631724137931 ], [ 29.782398860759493, -1.419518103448276 ], [ 29.782668354430381, -1.419518103448276 ], [ 29.782668354430381, -1.417631724137931 ], [ 29.782398860759493, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5937, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.417901206896552 ], [ 29.762995316455697, -1.418170689655172 ], [ 29.763264810126582, -1.418170689655172 ], [ 29.763264810126582, -1.417901206896552 ], [ 29.762995316455697, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5938, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.417901206896552 ], [ 29.763264810126582, -1.418170689655172 ], [ 29.763534303797467, -1.418170689655172 ], [ 29.763534303797467, -1.417901206896552 ], [ 29.763264810126582, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5939, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.417901206896552 ], [ 29.763534303797467, -1.418170689655172 ], [ 29.763803797468356, -1.418170689655172 ], [ 29.763803797468356, -1.417901206896552 ], [ 29.763534303797467, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5940, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.417901206896552 ], [ 29.763803797468356, -1.418170689655172 ], [ 29.76407329113924, -1.418170689655172 ], [ 29.76407329113924, -1.417901206896552 ], [ 29.763803797468356, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5941, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.417901206896552 ], [ 29.76407329113924, -1.418170689655172 ], [ 29.764342784810125, -1.418170689655172 ], [ 29.764342784810125, -1.417901206896552 ], [ 29.76407329113924, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5942, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.417901206896552 ], [ 29.764342784810125, -1.418170689655172 ], [ 29.764612278481014, -1.418170689655172 ], [ 29.764612278481014, -1.417901206896552 ], [ 29.764342784810125, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5943, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.417901206896552 ], [ 29.764612278481014, -1.418170689655172 ], [ 29.764881772151899, -1.418170689655172 ], [ 29.764881772151899, -1.417901206896552 ], [ 29.764612278481014, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5944, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.417901206896552 ], [ 29.764881772151899, -1.418170689655172 ], [ 29.765151265822784, -1.418170689655172 ], [ 29.765151265822784, -1.417901206896552 ], [ 29.764881772151899, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5945, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.417901206896552 ], [ 29.765151265822784, -1.418170689655172 ], [ 29.765420759493672, -1.418170689655172 ], [ 29.765420759493672, -1.417901206896552 ], [ 29.765151265822784, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5946, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.417901206896552 ], [ 29.765420759493672, -1.418170689655172 ], [ 29.765959746835442, -1.418170689655172 ], [ 29.765959746835442, -1.417901206896552 ], [ 29.765420759493672, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5947, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.417901206896552 ], [ 29.765959746835442, -1.418170689655172 ], [ 29.766229240506327, -1.418170689655172 ], [ 29.766229240506327, -1.417901206896552 ], [ 29.765959746835442, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5948, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.417901206896552 ], [ 29.766229240506327, -1.418170689655172 ], [ 29.766498734177215, -1.418170689655172 ], [ 29.766498734177215, -1.417901206896552 ], [ 29.766229240506327, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5949, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.417901206896552 ], [ 29.766498734177215, -1.418170689655172 ], [ 29.7667682278481, -1.418170689655172 ], [ 29.7667682278481, -1.417901206896552 ], [ 29.766498734177215, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5950, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.417901206896552 ], [ 29.7667682278481, -1.418170689655172 ], [ 29.767037721518985, -1.418170689655172 ], [ 29.767037721518985, -1.417901206896552 ], [ 29.7667682278481, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5951, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.417901206896552 ], [ 29.767037721518985, -1.418170689655172 ], [ 29.767307215189874, -1.418170689655172 ], [ 29.767307215189874, -1.417901206896552 ], [ 29.767037721518985, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5952, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.417901206896552 ], [ 29.767307215189874, -1.418170689655172 ], [ 29.767576708860759, -1.418170689655172 ], [ 29.767576708860759, -1.417901206896552 ], [ 29.767307215189874, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5953, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.417901206896552 ], [ 29.767576708860759, -1.418170689655172 ], [ 29.767846202531643, -1.418170689655172 ], [ 29.767846202531643, -1.417901206896552 ], [ 29.767576708860759, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5954, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.417901206896552 ], [ 29.767846202531643, -1.418170689655172 ], [ 29.768115696202532, -1.418170689655172 ], [ 29.768115696202532, -1.417901206896552 ], [ 29.767846202531643, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5955, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.417901206896552 ], [ 29.768115696202532, -1.418170689655172 ], [ 29.768385189873417, -1.418170689655172 ], [ 29.768385189873417, -1.417901206896552 ], [ 29.768115696202532, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5956, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.417901206896552 ], [ 29.768385189873417, -1.418170689655172 ], [ 29.768654683544302, -1.418170689655172 ], [ 29.768654683544302, -1.417901206896552 ], [ 29.768385189873417, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5957, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.417901206896552 ], [ 29.768654683544302, -1.418170689655172 ], [ 29.76892417721519, -1.418170689655172 ], [ 29.76892417721519, -1.417901206896552 ], [ 29.768654683544302, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5958, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.417901206896552 ], [ 29.76892417721519, -1.418170689655172 ], [ 29.769193670886075, -1.418170689655172 ], [ 29.769193670886075, -1.417901206896552 ], [ 29.76892417721519, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5959, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.417901206896552 ], [ 29.769193670886075, -1.418170689655172 ], [ 29.76946316455696, -1.418170689655172 ], [ 29.76946316455696, -1.417901206896552 ], [ 29.769193670886075, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5960, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.417901206896552 ], [ 29.76946316455696, -1.418170689655172 ], [ 29.769732658227849, -1.418170689655172 ], [ 29.769732658227849, -1.417901206896552 ], [ 29.76946316455696, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5961, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.417901206896552 ], [ 29.769732658227849, -1.418170689655172 ], [ 29.770002151898733, -1.418170689655172 ], [ 29.770002151898733, -1.417901206896552 ], [ 29.769732658227849, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5962, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.417901206896552 ], [ 29.770002151898733, -1.418170689655172 ], [ 29.770271645569618, -1.418170689655172 ], [ 29.770271645569618, -1.417901206896552 ], [ 29.770002151898733, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5963, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.417901206896552 ], [ 29.770271645569618, -1.418170689655172 ], [ 29.770541139240507, -1.418170689655172 ], [ 29.770541139240507, -1.417901206896552 ], [ 29.770271645569618, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5964, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.417901206896552 ], [ 29.770541139240507, -1.418170689655172 ], [ 29.770810632911392, -1.418170689655172 ], [ 29.770810632911392, -1.417901206896552 ], [ 29.770541139240507, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5965, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.417901206896552 ], [ 29.770810632911392, -1.418170689655172 ], [ 29.771080126582277, -1.418170689655172 ], [ 29.771080126582277, -1.417901206896552 ], [ 29.770810632911392, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5966, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.417901206896552 ], [ 29.771080126582277, -1.418170689655172 ], [ 29.771349620253165, -1.418170689655172 ], [ 29.771349620253165, -1.417901206896552 ], [ 29.771080126582277, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5967, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.417901206896552 ], [ 29.771349620253165, -1.418170689655172 ], [ 29.77161911392405, -1.418170689655172 ], [ 29.77161911392405, -1.417901206896552 ], [ 29.771349620253165, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5968, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.417901206896552 ], [ 29.77161911392405, -1.418170689655172 ], [ 29.771888607594935, -1.418170689655172 ], [ 29.771888607594935, -1.417901206896552 ], [ 29.77161911392405, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5969, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.417901206896552 ], [ 29.771888607594935, -1.418170689655172 ], [ 29.772158101265823, -1.418170689655172 ], [ 29.772158101265823, -1.417901206896552 ], [ 29.771888607594935, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5970, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.417901206896552 ], [ 29.772158101265823, -1.418170689655172 ], [ 29.772427594936708, -1.418170689655172 ], [ 29.772427594936708, -1.417901206896552 ], [ 29.772158101265823, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5971, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.417901206896552 ], [ 29.772427594936708, -1.418170689655172 ], [ 29.772697088607593, -1.418170689655172 ], [ 29.772697088607593, -1.417901206896552 ], [ 29.772427594936708, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5972, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.417901206896552 ], [ 29.772697088607593, -1.418170689655172 ], [ 29.772966582278482, -1.418170689655172 ], [ 29.772966582278482, -1.417901206896552 ], [ 29.772697088607593, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5973, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.417901206896552 ], [ 29.772966582278482, -1.418440172413793 ], [ 29.773236075949367, -1.418440172413793 ], [ 29.773236075949367, -1.417901206896552 ], [ 29.772966582278482, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5974, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.417901206896552 ], [ 29.773236075949367, -1.418170689655172 ], [ 29.773505569620252, -1.418170689655172 ], [ 29.773505569620252, -1.417901206896552 ], [ 29.773236075949367, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5975, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.417901206896552 ], [ 29.773505569620252, -1.418170689655172 ], [ 29.77377506329114, -1.418170689655172 ], [ 29.77377506329114, -1.417901206896552 ], [ 29.773505569620252, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5976, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.417901206896552 ], [ 29.77377506329114, -1.418440172413793 ], [ 29.774044556962025, -1.418440172413793 ], [ 29.774044556962025, -1.417901206896552 ], [ 29.77377506329114, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5977, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.417901206896552 ], [ 29.774044556962025, -1.418440172413793 ], [ 29.77431405063291, -1.418440172413793 ], [ 29.77431405063291, -1.417901206896552 ], [ 29.774044556962025, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5978, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.417901206896552 ], [ 29.77431405063291, -1.418170689655172 ], [ 29.774853037974683, -1.418170689655172 ], [ 29.774853037974683, -1.417901206896552 ], [ 29.77431405063291, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5979, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.417901206896552 ], [ 29.774853037974683, -1.418440172413793 ], [ 29.775122531645568, -1.418440172413793 ], [ 29.775122531645568, -1.417901206896552 ], [ 29.774853037974683, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5980, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.417901206896552 ], [ 29.775661518987341, -1.418440172413793 ], [ 29.775931012658226, -1.418440172413793 ], [ 29.775931012658226, -1.417901206896552 ], [ 29.775661518987341, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5981, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.417901206896552 ], [ 29.77647, -1.418440172413793 ], [ 29.776739493670885, -1.418440172413793 ], [ 29.776739493670885, -1.417901206896552 ], [ 29.77647, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5982, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.417901206896552 ], [ 29.777008987341773, -1.418170689655172 ], [ 29.777278481012658, -1.418170689655172 ], [ 29.777278481012658, -1.417901206896552 ], [ 29.777008987341773, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5983, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.417901206896552 ], [ 29.777278481012658, -1.418440172413793 ], [ 29.777547974683543, -1.418440172413793 ], [ 29.777547974683543, -1.417901206896552 ], [ 29.777278481012658, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5984, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.417901206896552 ], [ 29.777817468354431, -1.418170689655172 ], [ 29.778086962025316, -1.418170689655172 ], [ 29.778086962025316, -1.417901206896552 ], [ 29.777817468354431, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5985, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.417901206896552 ], [ 29.778086962025316, -1.418170689655172 ], [ 29.778356455696201, -1.418170689655172 ], [ 29.778356455696201, -1.417901206896552 ], [ 29.778086962025316, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5986, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.417901206896552 ], [ 29.77862594936709, -1.418170689655172 ], [ 29.778895443037975, -1.418170689655172 ], [ 29.778895443037975, -1.417901206896552 ], [ 29.77862594936709, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5987, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.417901206896552 ], [ 29.778895443037975, -1.418170689655172 ], [ 29.77916493670886, -1.418170689655172 ], [ 29.77916493670886, -1.417901206896552 ], [ 29.778895443037975, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5988, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.417901206896552 ], [ 29.77916493670886, -1.418440172413793 ], [ 29.779434430379748, -1.418440172413793 ], [ 29.779434430379748, -1.417901206896552 ], [ 29.77916493670886, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5989, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.417901206896552 ], [ 29.779434430379748, -1.418170689655172 ], [ 29.779703924050633, -1.418170689655172 ], [ 29.779703924050633, -1.417901206896552 ], [ 29.779434430379748, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5990, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.417901206896552 ], [ 29.779703924050633, -1.418170689655172 ], [ 29.779973417721518, -1.418170689655172 ], [ 29.779973417721518, -1.417901206896552 ], [ 29.779703924050633, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5991, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.417901206896552 ], [ 29.779973417721518, -1.419518103448276 ], [ 29.780242911392406, -1.419518103448276 ], [ 29.780242911392406, -1.417901206896552 ], [ 29.779973417721518, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5992, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.417901206896552 ], [ 29.780242911392406, -1.419787586206897 ], [ 29.780512405063291, -1.419787586206897 ], [ 29.780512405063291, -1.417901206896552 ], [ 29.780242911392406, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5993, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.417901206896552 ], [ 29.780512405063291, -1.418170689655172 ], [ 29.780781898734176, -1.418170689655172 ], [ 29.780781898734176, -1.417901206896552 ], [ 29.780512405063291, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5994, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.417901206896552 ], [ 29.780781898734176, -1.419518103448276 ], [ 29.781051392405065, -1.419518103448276 ], [ 29.781051392405065, -1.417901206896552 ], [ 29.780781898734176, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5995, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.417901206896552 ], [ 29.781051392405065, -1.419787586206897 ], [ 29.78132088607595, -1.419787586206897 ], [ 29.78132088607595, -1.417901206896552 ], [ 29.781051392405065, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5996, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.417901206896552 ], [ 29.781590379746834, -1.419518103448276 ], [ 29.781859873417723, -1.419518103448276 ], [ 29.781859873417723, -1.417901206896552 ], [ 29.781590379746834, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5997, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.417901206896552 ], [ 29.781859873417723, -1.419787586206897 ], [ 29.782129367088608, -1.419787586206897 ], [ 29.782129367088608, -1.417901206896552 ], [ 29.781859873417723, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5998, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.417901206896552 ], [ 29.798029493670885, -1.418170689655172 ], [ 29.798298987341774, -1.418170689655172 ], [ 29.798298987341774, -1.417901206896552 ], [ 29.798029493670885, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 5999, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.417901206896552 ], [ 29.798298987341774, -1.418170689655172 ], [ 29.798568481012659, -1.418170689655172 ], [ 29.798568481012659, -1.417901206896552 ], [ 29.798298987341774, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6000, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.417901206896552 ], [ 29.798568481012659, -1.418170689655172 ], [ 29.798837974683543, -1.418170689655172 ], [ 29.798837974683543, -1.417901206896552 ], [ 29.798568481012659, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6001, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.417901206896552 ], [ 29.798837974683543, -1.418170689655172 ], [ 29.799107468354432, -1.418170689655172 ], [ 29.799107468354432, -1.417901206896552 ], [ 29.798837974683543, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6002, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.417901206896552 ], [ 29.799107468354432, -1.418170689655172 ], [ 29.799376962025317, -1.418170689655172 ], [ 29.799376962025317, -1.417901206896552 ], [ 29.799107468354432, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6003, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.417901206896552 ], [ 29.799376962025317, -1.418170689655172 ], [ 29.799646455696202, -1.418170689655172 ], [ 29.799646455696202, -1.417901206896552 ], [ 29.799376962025317, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6004, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.417901206896552 ], [ 29.799646455696202, -1.418170689655172 ], [ 29.79991594936709, -1.418170689655172 ], [ 29.79991594936709, -1.417901206896552 ], [ 29.799646455696202, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6005, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.417901206896552 ], [ 29.79991594936709, -1.418170689655172 ], [ 29.800185443037975, -1.418170689655172 ], [ 29.800185443037975, -1.417901206896552 ], [ 29.79991594936709, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6006, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.417901206896552 ], [ 29.800185443037975, -1.418170689655172 ], [ 29.80045493670886, -1.418170689655172 ], [ 29.80045493670886, -1.417901206896552 ], [ 29.800185443037975, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6007, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.417901206896552 ], [ 29.80045493670886, -1.418170689655172 ], [ 29.800724430379748, -1.418170689655172 ], [ 29.800724430379748, -1.417901206896552 ], [ 29.80045493670886, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6008, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.417901206896552 ], [ 29.800724430379748, -1.418170689655172 ], [ 29.800993924050633, -1.418170689655172 ], [ 29.800993924050633, -1.417901206896552 ], [ 29.800724430379748, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6009, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.417901206896552 ], [ 29.800993924050633, -1.418170689655172 ], [ 29.801263417721518, -1.418170689655172 ], [ 29.801263417721518, -1.417901206896552 ], [ 29.800993924050633, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6010, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.417901206896552 ], [ 29.801263417721518, -1.418170689655172 ], [ 29.801532911392407, -1.418170689655172 ], [ 29.801532911392407, -1.417901206896552 ], [ 29.801263417721518, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6011, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.417901206896552 ], [ 29.801532911392407, -1.418170689655172 ], [ 29.802071898734177, -1.418170689655172 ], [ 29.802071898734177, -1.417901206896552 ], [ 29.801532911392407, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6012, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.417901206896552 ], [ 29.802071898734177, -1.418170689655172 ], [ 29.802341392405065, -1.418170689655172 ], [ 29.802341392405065, -1.417901206896552 ], [ 29.802071898734177, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6013, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.417901206896552 ], [ 29.802341392405065, -1.418170689655172 ], [ 29.80261088607595, -1.418170689655172 ], [ 29.80261088607595, -1.417901206896552 ], [ 29.802341392405065, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6014, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.417901206896552 ], [ 29.80261088607595, -1.418170689655172 ], [ 29.802880379746835, -1.418170689655172 ], [ 29.802880379746835, -1.417901206896552 ], [ 29.80261088607595, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6015, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.418170689655172 ], [ 29.762725822784809, -1.418440172413793 ], [ 29.763264810126582, -1.418440172413793 ], [ 29.763264810126582, -1.418170689655172 ], [ 29.762725822784809, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6016, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.418170689655172 ], [ 29.763264810126582, -1.418440172413793 ], [ 29.763534303797467, -1.418440172413793 ], [ 29.763534303797467, -1.418170689655172 ], [ 29.763264810126582, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6017, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.418170689655172 ], [ 29.763534303797467, -1.418440172413793 ], [ 29.763803797468356, -1.418440172413793 ], [ 29.763803797468356, -1.418170689655172 ], [ 29.763534303797467, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6018, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.418170689655172 ], [ 29.763803797468356, -1.418440172413793 ], [ 29.76407329113924, -1.418440172413793 ], [ 29.76407329113924, -1.418170689655172 ], [ 29.763803797468356, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6019, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.418170689655172 ], [ 29.76407329113924, -1.418440172413793 ], [ 29.764342784810125, -1.418440172413793 ], [ 29.764342784810125, -1.418170689655172 ], [ 29.76407329113924, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6020, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.418170689655172 ], [ 29.764342784810125, -1.418440172413793 ], [ 29.764612278481014, -1.418440172413793 ], [ 29.764612278481014, -1.418170689655172 ], [ 29.764342784810125, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6021, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.418170689655172 ], [ 29.764612278481014, -1.418440172413793 ], [ 29.764881772151899, -1.418440172413793 ], [ 29.764881772151899, -1.418170689655172 ], [ 29.764612278481014, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6022, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.418170689655172 ], [ 29.764881772151899, -1.418440172413793 ], [ 29.765151265822784, -1.418440172413793 ], [ 29.765151265822784, -1.418170689655172 ], [ 29.764881772151899, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6023, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.418170689655172 ], [ 29.765151265822784, -1.418440172413793 ], [ 29.765420759493672, -1.418440172413793 ], [ 29.765420759493672, -1.418170689655172 ], [ 29.765151265822784, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6024, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.418170689655172 ], [ 29.765420759493672, -1.418440172413793 ], [ 29.765959746835442, -1.418440172413793 ], [ 29.765959746835442, -1.418170689655172 ], [ 29.765420759493672, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6025, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.418170689655172 ], [ 29.765959746835442, -1.418440172413793 ], [ 29.766229240506327, -1.418440172413793 ], [ 29.766229240506327, -1.418170689655172 ], [ 29.765959746835442, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6026, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.418170689655172 ], [ 29.766229240506327, -1.418440172413793 ], [ 29.766498734177215, -1.418440172413793 ], [ 29.766498734177215, -1.418170689655172 ], [ 29.766229240506327, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6027, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.418170689655172 ], [ 29.766498734177215, -1.418440172413793 ], [ 29.7667682278481, -1.418440172413793 ], [ 29.7667682278481, -1.418170689655172 ], [ 29.766498734177215, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6028, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.418170689655172 ], [ 29.7667682278481, -1.418440172413793 ], [ 29.767037721518985, -1.418440172413793 ], [ 29.767037721518985, -1.418170689655172 ], [ 29.7667682278481, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6029, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.418170689655172 ], [ 29.767037721518985, -1.418440172413793 ], [ 29.767307215189874, -1.418440172413793 ], [ 29.767307215189874, -1.418170689655172 ], [ 29.767037721518985, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6030, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.418170689655172 ], [ 29.767307215189874, -1.418440172413793 ], [ 29.767576708860759, -1.418440172413793 ], [ 29.767576708860759, -1.418170689655172 ], [ 29.767307215189874, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6031, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.418170689655172 ], [ 29.767576708860759, -1.418440172413793 ], [ 29.767846202531643, -1.418440172413793 ], [ 29.767846202531643, -1.418170689655172 ], [ 29.767576708860759, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6032, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.418170689655172 ], [ 29.767846202531643, -1.418440172413793 ], [ 29.768115696202532, -1.418440172413793 ], [ 29.768115696202532, -1.418170689655172 ], [ 29.767846202531643, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6033, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.418170689655172 ], [ 29.768115696202532, -1.418440172413793 ], [ 29.768385189873417, -1.418440172413793 ], [ 29.768385189873417, -1.418170689655172 ], [ 29.768115696202532, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6034, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.418170689655172 ], [ 29.768385189873417, -1.418440172413793 ], [ 29.768654683544302, -1.418440172413793 ], [ 29.768654683544302, -1.418170689655172 ], [ 29.768385189873417, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6035, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.418170689655172 ], [ 29.768654683544302, -1.418440172413793 ], [ 29.76892417721519, -1.418440172413793 ], [ 29.76892417721519, -1.418170689655172 ], [ 29.768654683544302, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6036, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.418170689655172 ], [ 29.76892417721519, -1.418440172413793 ], [ 29.769193670886075, -1.418440172413793 ], [ 29.769193670886075, -1.418170689655172 ], [ 29.76892417721519, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6037, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.418170689655172 ], [ 29.769193670886075, -1.418440172413793 ], [ 29.76946316455696, -1.418440172413793 ], [ 29.76946316455696, -1.418170689655172 ], [ 29.769193670886075, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6038, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.418170689655172 ], [ 29.76946316455696, -1.418440172413793 ], [ 29.769732658227849, -1.418440172413793 ], [ 29.769732658227849, -1.418170689655172 ], [ 29.76946316455696, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6039, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.418170689655172 ], [ 29.769732658227849, -1.418440172413793 ], [ 29.770002151898733, -1.418440172413793 ], [ 29.770002151898733, -1.418170689655172 ], [ 29.769732658227849, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6040, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.418170689655172 ], [ 29.770002151898733, -1.418440172413793 ], [ 29.770271645569618, -1.418440172413793 ], [ 29.770271645569618, -1.418170689655172 ], [ 29.770002151898733, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6041, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.418170689655172 ], [ 29.770271645569618, -1.418440172413793 ], [ 29.770541139240507, -1.418440172413793 ], [ 29.770541139240507, -1.418170689655172 ], [ 29.770271645569618, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6042, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.418170689655172 ], [ 29.770541139240507, -1.418440172413793 ], [ 29.770810632911392, -1.418440172413793 ], [ 29.770810632911392, -1.418170689655172 ], [ 29.770541139240507, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6043, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.418170689655172 ], [ 29.770810632911392, -1.418440172413793 ], [ 29.771080126582277, -1.418440172413793 ], [ 29.771080126582277, -1.418170689655172 ], [ 29.770810632911392, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6044, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.418170689655172 ], [ 29.771080126582277, -1.418440172413793 ], [ 29.771349620253165, -1.418440172413793 ], [ 29.771349620253165, -1.418170689655172 ], [ 29.771080126582277, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6045, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.418170689655172 ], [ 29.771349620253165, -1.418440172413793 ], [ 29.77161911392405, -1.418440172413793 ], [ 29.77161911392405, -1.418170689655172 ], [ 29.771349620253165, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6046, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.418170689655172 ], [ 29.77161911392405, -1.418440172413793 ], [ 29.771888607594935, -1.418440172413793 ], [ 29.771888607594935, -1.418170689655172 ], [ 29.77161911392405, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6047, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.418170689655172 ], [ 29.771888607594935, -1.418440172413793 ], [ 29.772158101265823, -1.418440172413793 ], [ 29.772158101265823, -1.418170689655172 ], [ 29.771888607594935, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6048, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.418170689655172 ], [ 29.772158101265823, -1.418440172413793 ], [ 29.772427594936708, -1.418440172413793 ], [ 29.772427594936708, -1.418170689655172 ], [ 29.772158101265823, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6049, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.418170689655172 ], [ 29.772427594936708, -1.418440172413793 ], [ 29.772697088607593, -1.418440172413793 ], [ 29.772697088607593, -1.418170689655172 ], [ 29.772427594936708, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6050, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.418170689655172 ], [ 29.772697088607593, -1.418440172413793 ], [ 29.772966582278482, -1.418440172413793 ], [ 29.772966582278482, -1.418170689655172 ], [ 29.772697088607593, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6051, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.418170689655172 ], [ 29.773236075949367, -1.418440172413793 ], [ 29.773505569620252, -1.418440172413793 ], [ 29.773505569620252, -1.418170689655172 ], [ 29.773236075949367, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6052, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.418170689655172 ], [ 29.773505569620252, -1.418709655172414 ], [ 29.77377506329114, -1.418709655172414 ], [ 29.77377506329114, -1.418170689655172 ], [ 29.773505569620252, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6053, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.418170689655172 ], [ 29.77431405063291, -1.418709655172414 ], [ 29.774853037974683, -1.418709655172414 ], [ 29.774853037974683, -1.418170689655172 ], [ 29.77431405063291, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6054, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.418170689655172 ], [ 29.775122531645568, -1.418440172413793 ], [ 29.775392025316457, -1.418440172413793 ], [ 29.775392025316457, -1.418170689655172 ], [ 29.775122531645568, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6055, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.418170689655172 ], [ 29.775392025316457, -1.418709655172414 ], [ 29.775661518987341, -1.418709655172414 ], [ 29.775661518987341, -1.418170689655172 ], [ 29.775392025316457, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6056, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.418170689655172 ], [ 29.775931012658226, -1.418440172413793 ], [ 29.776200506329115, -1.418440172413793 ], [ 29.776200506329115, -1.418170689655172 ], [ 29.775931012658226, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6057, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.418170689655172 ], [ 29.776200506329115, -1.418709655172414 ], [ 29.77647, -1.418709655172414 ], [ 29.77647, -1.418170689655172 ], [ 29.776200506329115, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6058, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.418170689655172 ], [ 29.776739493670885, -1.418440172413793 ], [ 29.777008987341773, -1.418440172413793 ], [ 29.777008987341773, -1.418170689655172 ], [ 29.776739493670885, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6059, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.418170689655172 ], [ 29.777008987341773, -1.418440172413793 ], [ 29.777278481012658, -1.418440172413793 ], [ 29.777278481012658, -1.418170689655172 ], [ 29.777008987341773, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6060, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.418170689655172 ], [ 29.777547974683543, -1.418440172413793 ], [ 29.777817468354431, -1.418440172413793 ], [ 29.777817468354431, -1.418170689655172 ], [ 29.777547974683543, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6061, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.418170689655172 ], [ 29.777817468354431, -1.418440172413793 ], [ 29.778086962025316, -1.418440172413793 ], [ 29.778086962025316, -1.418170689655172 ], [ 29.777817468354431, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6062, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.418170689655172 ], [ 29.778086962025316, -1.419787586206897 ], [ 29.778356455696201, -1.419787586206897 ], [ 29.778356455696201, -1.418170689655172 ], [ 29.778086962025316, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6063, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.418170689655172 ], [ 29.778356455696201, -1.418440172413793 ], [ 29.77862594936709, -1.418440172413793 ], [ 29.77862594936709, -1.418170689655172 ], [ 29.778356455696201, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6064, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.418170689655172 ], [ 29.77862594936709, -1.419518103448276 ], [ 29.778895443037975, -1.419518103448276 ], [ 29.778895443037975, -1.418170689655172 ], [ 29.77862594936709, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6065, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.418170689655172 ], [ 29.778895443037975, -1.419787586206897 ], [ 29.77916493670886, -1.419787586206897 ], [ 29.77916493670886, -1.418170689655172 ], [ 29.778895443037975, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6066, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.418170689655172 ], [ 29.779434430379748, -1.419518103448276 ], [ 29.779703924050633, -1.419518103448276 ], [ 29.779703924050633, -1.418170689655172 ], [ 29.779434430379748, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6067, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.418170689655172 ], [ 29.779703924050633, -1.419787586206897 ], [ 29.779973417721518, -1.419787586206897 ], [ 29.779973417721518, -1.418170689655172 ], [ 29.779703924050633, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6068, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.418170689655172 ], [ 29.780512405063291, -1.419787586206897 ], [ 29.780781898734176, -1.419787586206897 ], [ 29.780781898734176, -1.418170689655172 ], [ 29.780512405063291, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6069, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.418170689655172 ], [ 29.79776, -1.419518103448276 ], [ 29.798029493670885, -1.419518103448276 ], [ 29.798029493670885, -1.418170689655172 ], [ 29.79776, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6070, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.418170689655172 ], [ 29.798029493670885, -1.418440172413793 ], [ 29.798298987341774, -1.418440172413793 ], [ 29.798298987341774, -1.418170689655172 ], [ 29.798029493670885, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6071, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.418170689655172 ], [ 29.798298987341774, -1.418440172413793 ], [ 29.798568481012659, -1.418440172413793 ], [ 29.798568481012659, -1.418170689655172 ], [ 29.798298987341774, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6072, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.418170689655172 ], [ 29.798568481012659, -1.418440172413793 ], [ 29.798837974683543, -1.418440172413793 ], [ 29.798837974683543, -1.418170689655172 ], [ 29.798568481012659, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6073, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.418170689655172 ], [ 29.798837974683543, -1.418440172413793 ], [ 29.799107468354432, -1.418440172413793 ], [ 29.799107468354432, -1.418170689655172 ], [ 29.798837974683543, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6074, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.418170689655172 ], [ 29.799107468354432, -1.418440172413793 ], [ 29.799376962025317, -1.418440172413793 ], [ 29.799376962025317, -1.418170689655172 ], [ 29.799107468354432, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6075, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.418170689655172 ], [ 29.799376962025317, -1.418440172413793 ], [ 29.799646455696202, -1.418440172413793 ], [ 29.799646455696202, -1.418170689655172 ], [ 29.799376962025317, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6076, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.418170689655172 ], [ 29.799646455696202, -1.418440172413793 ], [ 29.79991594936709, -1.418440172413793 ], [ 29.79991594936709, -1.418170689655172 ], [ 29.799646455696202, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6077, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.418170689655172 ], [ 29.79991594936709, -1.418440172413793 ], [ 29.800185443037975, -1.418440172413793 ], [ 29.800185443037975, -1.418170689655172 ], [ 29.79991594936709, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6078, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.418170689655172 ], [ 29.800185443037975, -1.418440172413793 ], [ 29.800724430379748, -1.418440172413793 ], [ 29.800724430379748, -1.418170689655172 ], [ 29.800185443037975, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6079, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.418170689655172 ], [ 29.800724430379748, -1.418440172413793 ], [ 29.800993924050633, -1.418440172413793 ], [ 29.800993924050633, -1.418170689655172 ], [ 29.800724430379748, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6080, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.418170689655172 ], [ 29.800993924050633, -1.418440172413793 ], [ 29.801263417721518, -1.418440172413793 ], [ 29.801263417721518, -1.418170689655172 ], [ 29.800993924050633, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6081, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.418170689655172 ], [ 29.801263417721518, -1.418440172413793 ], [ 29.801532911392407, -1.418440172413793 ], [ 29.801532911392407, -1.418170689655172 ], [ 29.801263417721518, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6082, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.418170689655172 ], [ 29.801532911392407, -1.418440172413793 ], [ 29.802071898734177, -1.418440172413793 ], [ 29.802071898734177, -1.418170689655172 ], [ 29.801532911392407, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6083, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.418170689655172 ], [ 29.802071898734177, -1.418440172413793 ], [ 29.802341392405065, -1.418440172413793 ], [ 29.802341392405065, -1.418170689655172 ], [ 29.802071898734177, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6084, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.418170689655172 ], [ 29.802341392405065, -1.418440172413793 ], [ 29.80261088607595, -1.418440172413793 ], [ 29.80261088607595, -1.418170689655172 ], [ 29.802341392405065, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6085, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.418170689655172 ], [ 29.80261088607595, -1.418440172413793 ], [ 29.802880379746835, -1.418440172413793 ], [ 29.802880379746835, -1.418170689655172 ], [ 29.80261088607595, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6086, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.418170689655172 ], [ 29.802880379746835, -1.418440172413793 ], [ 29.803419367088608, -1.418440172413793 ], [ 29.803419367088608, -1.418170689655172 ], [ 29.802880379746835, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6087, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.418440172413793 ], [ 29.762995316455697, -1.418709655172414 ], [ 29.763534303797467, -1.418709655172414 ], [ 29.763534303797467, -1.418440172413793 ], [ 29.762995316455697, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6088, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.418440172413793 ], [ 29.763534303797467, -1.418709655172414 ], [ 29.763803797468356, -1.418709655172414 ], [ 29.763803797468356, -1.418440172413793 ], [ 29.763534303797467, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6089, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.418440172413793 ], [ 29.763803797468356, -1.418709655172414 ], [ 29.76407329113924, -1.418709655172414 ], [ 29.76407329113924, -1.418440172413793 ], [ 29.763803797468356, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6090, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.418440172413793 ], [ 29.76407329113924, -1.418709655172414 ], [ 29.764342784810125, -1.418709655172414 ], [ 29.764342784810125, -1.418440172413793 ], [ 29.76407329113924, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6091, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.418440172413793 ], [ 29.764342784810125, -1.418709655172414 ], [ 29.764612278481014, -1.418709655172414 ], [ 29.764612278481014, -1.418440172413793 ], [ 29.764342784810125, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6092, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.418440172413793 ], [ 29.764612278481014, -1.418709655172414 ], [ 29.764881772151899, -1.418709655172414 ], [ 29.764881772151899, -1.418440172413793 ], [ 29.764612278481014, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6093, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.418440172413793 ], [ 29.764881772151899, -1.418709655172414 ], [ 29.765151265822784, -1.418709655172414 ], [ 29.765151265822784, -1.418440172413793 ], [ 29.764881772151899, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6094, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.418440172413793 ], [ 29.765151265822784, -1.418709655172414 ], [ 29.765420759493672, -1.418709655172414 ], [ 29.765420759493672, -1.418440172413793 ], [ 29.765151265822784, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6095, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.418440172413793 ], [ 29.765420759493672, -1.418709655172414 ], [ 29.765959746835442, -1.418709655172414 ], [ 29.765959746835442, -1.418440172413793 ], [ 29.765420759493672, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6096, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.418440172413793 ], [ 29.765959746835442, -1.418709655172414 ], [ 29.766229240506327, -1.418709655172414 ], [ 29.766229240506327, -1.418440172413793 ], [ 29.765959746835442, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6097, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.418440172413793 ], [ 29.766229240506327, -1.418709655172414 ], [ 29.766498734177215, -1.418709655172414 ], [ 29.766498734177215, -1.418440172413793 ], [ 29.766229240506327, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6098, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.418440172413793 ], [ 29.766498734177215, -1.418709655172414 ], [ 29.7667682278481, -1.418709655172414 ], [ 29.7667682278481, -1.418440172413793 ], [ 29.766498734177215, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6099, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.418440172413793 ], [ 29.7667682278481, -1.418709655172414 ], [ 29.767037721518985, -1.418709655172414 ], [ 29.767037721518985, -1.418440172413793 ], [ 29.7667682278481, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6100, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.418440172413793 ], [ 29.767037721518985, -1.418709655172414 ], [ 29.767307215189874, -1.418709655172414 ], [ 29.767307215189874, -1.418440172413793 ], [ 29.767037721518985, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6101, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.418440172413793 ], [ 29.767307215189874, -1.418709655172414 ], [ 29.767576708860759, -1.418709655172414 ], [ 29.767576708860759, -1.418440172413793 ], [ 29.767307215189874, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6102, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.418440172413793 ], [ 29.767576708860759, -1.418709655172414 ], [ 29.767846202531643, -1.418709655172414 ], [ 29.767846202531643, -1.418440172413793 ], [ 29.767576708860759, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6103, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.418440172413793 ], [ 29.767846202531643, -1.418709655172414 ], [ 29.768115696202532, -1.418709655172414 ], [ 29.768115696202532, -1.418440172413793 ], [ 29.767846202531643, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6104, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.418440172413793 ], [ 29.768115696202532, -1.418709655172414 ], [ 29.768385189873417, -1.418709655172414 ], [ 29.768385189873417, -1.418440172413793 ], [ 29.768115696202532, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6105, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.418440172413793 ], [ 29.768385189873417, -1.418709655172414 ], [ 29.768654683544302, -1.418709655172414 ], [ 29.768654683544302, -1.418440172413793 ], [ 29.768385189873417, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6106, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.418440172413793 ], [ 29.768654683544302, -1.418709655172414 ], [ 29.76892417721519, -1.418709655172414 ], [ 29.76892417721519, -1.418440172413793 ], [ 29.768654683544302, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6107, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.418440172413793 ], [ 29.76892417721519, -1.418709655172414 ], [ 29.769193670886075, -1.418709655172414 ], [ 29.769193670886075, -1.418440172413793 ], [ 29.76892417721519, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6108, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.418440172413793 ], [ 29.769193670886075, -1.418709655172414 ], [ 29.76946316455696, -1.418709655172414 ], [ 29.76946316455696, -1.418440172413793 ], [ 29.769193670886075, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6109, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.418440172413793 ], [ 29.76946316455696, -1.418709655172414 ], [ 29.769732658227849, -1.418709655172414 ], [ 29.769732658227849, -1.418440172413793 ], [ 29.76946316455696, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6110, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.418440172413793 ], [ 29.769732658227849, -1.418709655172414 ], [ 29.770002151898733, -1.418709655172414 ], [ 29.770002151898733, -1.418440172413793 ], [ 29.769732658227849, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6111, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.418440172413793 ], [ 29.770002151898733, -1.418709655172414 ], [ 29.770271645569618, -1.418709655172414 ], [ 29.770271645569618, -1.418440172413793 ], [ 29.770002151898733, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6112, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.418440172413793 ], [ 29.770271645569618, -1.418709655172414 ], [ 29.770541139240507, -1.418709655172414 ], [ 29.770541139240507, -1.418440172413793 ], [ 29.770271645569618, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6113, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.418440172413793 ], [ 29.770541139240507, -1.418709655172414 ], [ 29.770810632911392, -1.418709655172414 ], [ 29.770810632911392, -1.418440172413793 ], [ 29.770541139240507, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6114, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.418440172413793 ], [ 29.770810632911392, -1.418709655172414 ], [ 29.771080126582277, -1.418709655172414 ], [ 29.771080126582277, -1.418440172413793 ], [ 29.770810632911392, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6115, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.418440172413793 ], [ 29.771080126582277, -1.418709655172414 ], [ 29.771349620253165, -1.418709655172414 ], [ 29.771349620253165, -1.418440172413793 ], [ 29.771080126582277, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6116, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.418440172413793 ], [ 29.771349620253165, -1.418709655172414 ], [ 29.77161911392405, -1.418709655172414 ], [ 29.77161911392405, -1.418440172413793 ], [ 29.771349620253165, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6117, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.418440172413793 ], [ 29.77161911392405, -1.418709655172414 ], [ 29.771888607594935, -1.418709655172414 ], [ 29.771888607594935, -1.418440172413793 ], [ 29.77161911392405, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6118, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.418440172413793 ], [ 29.771888607594935, -1.418709655172414 ], [ 29.772158101265823, -1.418709655172414 ], [ 29.772158101265823, -1.418440172413793 ], [ 29.771888607594935, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6119, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.418440172413793 ], [ 29.772158101265823, -1.418709655172414 ], [ 29.772427594936708, -1.418709655172414 ], [ 29.772427594936708, -1.418440172413793 ], [ 29.772158101265823, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6120, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.418440172413793 ], [ 29.772427594936708, -1.418709655172414 ], [ 29.772697088607593, -1.418709655172414 ], [ 29.772697088607593, -1.418440172413793 ], [ 29.772427594936708, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6121, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.418440172413793 ], [ 29.772697088607593, -1.418709655172414 ], [ 29.772966582278482, -1.418709655172414 ], [ 29.772966582278482, -1.418440172413793 ], [ 29.772697088607593, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6122, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.418440172413793 ], [ 29.772966582278482, -1.418709655172414 ], [ 29.773236075949367, -1.418709655172414 ], [ 29.773236075949367, -1.418440172413793 ], [ 29.772966582278482, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6123, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.418440172413793 ], [ 29.773236075949367, -1.418979137931035 ], [ 29.773505569620252, -1.418979137931035 ], [ 29.773505569620252, -1.418440172413793 ], [ 29.773236075949367, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6124, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.418440172413793 ], [ 29.77377506329114, -1.418709655172414 ], [ 29.774044556962025, -1.418709655172414 ], [ 29.774044556962025, -1.418440172413793 ], [ 29.77377506329114, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6125, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.418440172413793 ], [ 29.774044556962025, -1.418979137931035 ], [ 29.77431405063291, -1.418979137931035 ], [ 29.77431405063291, -1.418440172413793 ], [ 29.774044556962025, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6126, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.418440172413793 ], [ 29.774853037974683, -1.418709655172414 ], [ 29.775122531645568, -1.418709655172414 ], [ 29.775122531645568, -1.418440172413793 ], [ 29.774853037974683, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6127, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.418440172413793 ], [ 29.775122531645568, -1.418979137931035 ], [ 29.775392025316457, -1.418979137931035 ], [ 29.775392025316457, -1.418440172413793 ], [ 29.775122531645568, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6128, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.418440172413793 ], [ 29.775661518987341, -1.418709655172414 ], [ 29.775931012658226, -1.418709655172414 ], [ 29.775931012658226, -1.418440172413793 ], [ 29.775661518987341, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6129, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.418440172413793 ], [ 29.775931012658226, -1.419518103448276 ], [ 29.776200506329115, -1.419518103448276 ], [ 29.776200506329115, -1.418440172413793 ], [ 29.775931012658226, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6130, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.418440172413793 ], [ 29.77647, -1.418709655172414 ], [ 29.776739493670885, -1.418709655172414 ], [ 29.776739493670885, -1.418440172413793 ], [ 29.77647, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6131, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.418440172413793 ], [ 29.776739493670885, -1.419787586206897 ], [ 29.777008987341773, -1.419787586206897 ], [ 29.777008987341773, -1.418440172413793 ], [ 29.776739493670885, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6132, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.418440172413793 ], [ 29.777008987341773, -1.420057068965517 ], [ 29.777278481012658, -1.420057068965517 ], [ 29.777278481012658, -1.418440172413793 ], [ 29.777008987341773, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6133, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.418440172413793 ], [ 29.777278481012658, -1.419518103448276 ], [ 29.777547974683543, -1.419518103448276 ], [ 29.777547974683543, -1.418440172413793 ], [ 29.777278481012658, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6134, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.418440172413793 ], [ 29.777547974683543, -1.419787586206897 ], [ 29.777817468354431, -1.419787586206897 ], [ 29.777817468354431, -1.418440172413793 ], [ 29.777547974683543, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6135, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.418440172413793 ], [ 29.777817468354431, -1.420057068965517 ], [ 29.778086962025316, -1.420057068965517 ], [ 29.778086962025316, -1.418440172413793 ], [ 29.777817468354431, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6136, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.418440172413793 ], [ 29.778356455696201, -1.420057068965517 ], [ 29.77862594936709, -1.420057068965517 ], [ 29.77862594936709, -1.418440172413793 ], [ 29.778356455696201, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6137, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.418440172413793 ], [ 29.77916493670886, -1.420057068965517 ], [ 29.779434430379748, -1.420057068965517 ], [ 29.779434430379748, -1.418440172413793 ], [ 29.77916493670886, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6138, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.418440172413793 ], [ 29.798029493670885, -1.419248620689655 ], [ 29.798298987341774, -1.419248620689655 ], [ 29.798298987341774, -1.418440172413793 ], [ 29.798029493670885, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6139, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.418440172413793 ], [ 29.798298987341774, -1.419248620689655 ], [ 29.798568481012659, -1.419248620689655 ], [ 29.798568481012659, -1.418440172413793 ], [ 29.798298987341774, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6140, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.418440172413793 ], [ 29.798568481012659, -1.418709655172414 ], [ 29.798837974683543, -1.418709655172414 ], [ 29.798837974683543, -1.418440172413793 ], [ 29.798568481012659, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6141, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.418440172413793 ], [ 29.798837974683543, -1.418709655172414 ], [ 29.799107468354432, -1.418709655172414 ], [ 29.799107468354432, -1.418440172413793 ], [ 29.798837974683543, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6142, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.418440172413793 ], [ 29.799107468354432, -1.418709655172414 ], [ 29.799376962025317, -1.418709655172414 ], [ 29.799376962025317, -1.418440172413793 ], [ 29.799107468354432, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6143, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.418440172413793 ], [ 29.799376962025317, -1.418709655172414 ], [ 29.799646455696202, -1.418709655172414 ], [ 29.799646455696202, -1.418440172413793 ], [ 29.799376962025317, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6144, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.418440172413793 ], [ 29.799646455696202, -1.418709655172414 ], [ 29.79991594936709, -1.418709655172414 ], [ 29.79991594936709, -1.418440172413793 ], [ 29.799646455696202, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6145, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.418440172413793 ], [ 29.79991594936709, -1.418709655172414 ], [ 29.800185443037975, -1.418709655172414 ], [ 29.800185443037975, -1.418440172413793 ], [ 29.79991594936709, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6146, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.418440172413793 ], [ 29.800185443037975, -1.418709655172414 ], [ 29.800724430379748, -1.418709655172414 ], [ 29.800724430379748, -1.418440172413793 ], [ 29.800185443037975, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6147, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.418440172413793 ], [ 29.800724430379748, -1.418709655172414 ], [ 29.800993924050633, -1.418709655172414 ], [ 29.800993924050633, -1.418440172413793 ], [ 29.800724430379748, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6148, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.418440172413793 ], [ 29.800993924050633, -1.418709655172414 ], [ 29.801263417721518, -1.418709655172414 ], [ 29.801263417721518, -1.418440172413793 ], [ 29.800993924050633, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6149, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.418440172413793 ], [ 29.801263417721518, -1.418709655172414 ], [ 29.801532911392407, -1.418709655172414 ], [ 29.801532911392407, -1.418440172413793 ], [ 29.801263417721518, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6150, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.418440172413793 ], [ 29.801532911392407, -1.418709655172414 ], [ 29.802071898734177, -1.418709655172414 ], [ 29.802071898734177, -1.418440172413793 ], [ 29.801532911392407, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6151, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.418440172413793 ], [ 29.802071898734177, -1.418709655172414 ], [ 29.802341392405065, -1.418709655172414 ], [ 29.802341392405065, -1.418440172413793 ], [ 29.802071898734177, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6152, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.418440172413793 ], [ 29.802341392405065, -1.418709655172414 ], [ 29.80261088607595, -1.418709655172414 ], [ 29.80261088607595, -1.418440172413793 ], [ 29.802341392405065, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6153, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.418440172413793 ], [ 29.80261088607595, -1.418709655172414 ], [ 29.802880379746835, -1.418709655172414 ], [ 29.802880379746835, -1.418440172413793 ], [ 29.80261088607595, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6154, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.418440172413793 ], [ 29.802880379746835, -1.418709655172414 ], [ 29.803149873417723, -1.418709655172414 ], [ 29.803149873417723, -1.418440172413793 ], [ 29.802880379746835, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6155, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.418440172413793 ], [ 29.803149873417723, -1.418709655172414 ], [ 29.803419367088608, -1.418709655172414 ], [ 29.803419367088608, -1.418440172413793 ], [ 29.803149873417723, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6156, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.418440172413793 ], [ 29.803419367088608, -1.418979137931035 ], [ 29.803688860759493, -1.418979137931035 ], [ 29.803688860759493, -1.418440172413793 ], [ 29.803419367088608, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6157, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.418709655172414 ], [ 29.762995316455697, -1.418979137931035 ], [ 29.763264810126582, -1.418979137931035 ], [ 29.763264810126582, -1.418709655172414 ], [ 29.762995316455697, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6158, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.418709655172414 ], [ 29.763264810126582, -1.418979137931035 ], [ 29.763534303797467, -1.418979137931035 ], [ 29.763534303797467, -1.418709655172414 ], [ 29.763264810126582, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6159, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.418709655172414 ], [ 29.763534303797467, -1.418979137931035 ], [ 29.76407329113924, -1.418979137931035 ], [ 29.76407329113924, -1.418709655172414 ], [ 29.763534303797467, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6160, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.418709655172414 ], [ 29.76407329113924, -1.418979137931035 ], [ 29.764342784810125, -1.418979137931035 ], [ 29.764342784810125, -1.418709655172414 ], [ 29.76407329113924, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6161, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.418709655172414 ], [ 29.764342784810125, -1.418979137931035 ], [ 29.764612278481014, -1.418979137931035 ], [ 29.764612278481014, -1.418709655172414 ], [ 29.764342784810125, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6162, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.418709655172414 ], [ 29.764612278481014, -1.418979137931035 ], [ 29.764881772151899, -1.418979137931035 ], [ 29.764881772151899, -1.418709655172414 ], [ 29.764612278481014, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6163, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.418709655172414 ], [ 29.764881772151899, -1.418979137931035 ], [ 29.765151265822784, -1.418979137931035 ], [ 29.765151265822784, -1.418709655172414 ], [ 29.764881772151899, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6164, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.418709655172414 ], [ 29.765151265822784, -1.418979137931035 ], [ 29.765420759493672, -1.418979137931035 ], [ 29.765420759493672, -1.418709655172414 ], [ 29.765151265822784, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6165, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.418709655172414 ], [ 29.765420759493672, -1.418979137931035 ], [ 29.765959746835442, -1.418979137931035 ], [ 29.765959746835442, -1.418709655172414 ], [ 29.765420759493672, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6166, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.418709655172414 ], [ 29.765959746835442, -1.418979137931035 ], [ 29.766229240506327, -1.418979137931035 ], [ 29.766229240506327, -1.418709655172414 ], [ 29.765959746835442, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6167, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.418709655172414 ], [ 29.766229240506327, -1.418979137931035 ], [ 29.766498734177215, -1.418979137931035 ], [ 29.766498734177215, -1.418709655172414 ], [ 29.766229240506327, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6168, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.418709655172414 ], [ 29.766498734177215, -1.418979137931035 ], [ 29.7667682278481, -1.418979137931035 ], [ 29.7667682278481, -1.418709655172414 ], [ 29.766498734177215, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6169, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.418709655172414 ], [ 29.7667682278481, -1.418979137931035 ], [ 29.767037721518985, -1.418979137931035 ], [ 29.767037721518985, -1.418709655172414 ], [ 29.7667682278481, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6170, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.418709655172414 ], [ 29.767037721518985, -1.418979137931035 ], [ 29.767307215189874, -1.418979137931035 ], [ 29.767307215189874, -1.418709655172414 ], [ 29.767037721518985, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6171, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.418709655172414 ], [ 29.767307215189874, -1.418979137931035 ], [ 29.767576708860759, -1.418979137931035 ], [ 29.767576708860759, -1.418709655172414 ], [ 29.767307215189874, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6172, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.418709655172414 ], [ 29.767576708860759, -1.418979137931035 ], [ 29.767846202531643, -1.418979137931035 ], [ 29.767846202531643, -1.418709655172414 ], [ 29.767576708860759, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6173, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.418709655172414 ], [ 29.767846202531643, -1.418979137931035 ], [ 29.768115696202532, -1.418979137931035 ], [ 29.768115696202532, -1.418709655172414 ], [ 29.767846202531643, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6174, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.418709655172414 ], [ 29.768115696202532, -1.418979137931035 ], [ 29.768385189873417, -1.418979137931035 ], [ 29.768385189873417, -1.418709655172414 ], [ 29.768115696202532, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6175, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.418709655172414 ], [ 29.768385189873417, -1.418979137931035 ], [ 29.768654683544302, -1.418979137931035 ], [ 29.768654683544302, -1.418709655172414 ], [ 29.768385189873417, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6176, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.418709655172414 ], [ 29.768654683544302, -1.418979137931035 ], [ 29.76892417721519, -1.418979137931035 ], [ 29.76892417721519, -1.418709655172414 ], [ 29.768654683544302, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6177, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.418709655172414 ], [ 29.76892417721519, -1.418979137931035 ], [ 29.769193670886075, -1.418979137931035 ], [ 29.769193670886075, -1.418709655172414 ], [ 29.76892417721519, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6178, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.418709655172414 ], [ 29.769193670886075, -1.418979137931035 ], [ 29.76946316455696, -1.418979137931035 ], [ 29.76946316455696, -1.418709655172414 ], [ 29.769193670886075, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6179, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.418709655172414 ], [ 29.76946316455696, -1.418979137931035 ], [ 29.769732658227849, -1.418979137931035 ], [ 29.769732658227849, -1.418709655172414 ], [ 29.76946316455696, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6180, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.418709655172414 ], [ 29.769732658227849, -1.418979137931035 ], [ 29.770002151898733, -1.418979137931035 ], [ 29.770002151898733, -1.418709655172414 ], [ 29.769732658227849, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6181, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.418709655172414 ], [ 29.770002151898733, -1.418979137931035 ], [ 29.770271645569618, -1.418979137931035 ], [ 29.770271645569618, -1.418709655172414 ], [ 29.770002151898733, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6182, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.418709655172414 ], [ 29.770271645569618, -1.418979137931035 ], [ 29.770541139240507, -1.418979137931035 ], [ 29.770541139240507, -1.418709655172414 ], [ 29.770271645569618, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6183, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.418709655172414 ], [ 29.770541139240507, -1.418979137931035 ], [ 29.770810632911392, -1.418979137931035 ], [ 29.770810632911392, -1.418709655172414 ], [ 29.770541139240507, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6184, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.418709655172414 ], [ 29.770810632911392, -1.418979137931035 ], [ 29.771080126582277, -1.418979137931035 ], [ 29.771080126582277, -1.418709655172414 ], [ 29.770810632911392, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6185, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.418709655172414 ], [ 29.771080126582277, -1.418979137931035 ], [ 29.771349620253165, -1.418979137931035 ], [ 29.771349620253165, -1.418709655172414 ], [ 29.771080126582277, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6186, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.418709655172414 ], [ 29.771349620253165, -1.418979137931035 ], [ 29.77161911392405, -1.418979137931035 ], [ 29.77161911392405, -1.418709655172414 ], [ 29.771349620253165, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6187, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.418709655172414 ], [ 29.77161911392405, -1.418979137931035 ], [ 29.771888607594935, -1.418979137931035 ], [ 29.771888607594935, -1.418709655172414 ], [ 29.77161911392405, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6188, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.418709655172414 ], [ 29.771888607594935, -1.418979137931035 ], [ 29.772158101265823, -1.418979137931035 ], [ 29.772158101265823, -1.418709655172414 ], [ 29.771888607594935, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6189, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.418709655172414 ], [ 29.772158101265823, -1.418979137931035 ], [ 29.772427594936708, -1.418979137931035 ], [ 29.772427594936708, -1.418709655172414 ], [ 29.772158101265823, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6190, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.418709655172414 ], [ 29.772427594936708, -1.418979137931035 ], [ 29.772697088607593, -1.418979137931035 ], [ 29.772697088607593, -1.418709655172414 ], [ 29.772427594936708, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6191, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.418709655172414 ], [ 29.772697088607593, -1.418979137931035 ], [ 29.772966582278482, -1.418979137931035 ], [ 29.772966582278482, -1.418709655172414 ], [ 29.772697088607593, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6192, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.418709655172414 ], [ 29.772966582278482, -1.419518103448276 ], [ 29.773236075949367, -1.419518103448276 ], [ 29.773236075949367, -1.418709655172414 ], [ 29.772966582278482, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6193, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.418709655172414 ], [ 29.773505569620252, -1.418979137931035 ], [ 29.77377506329114, -1.418979137931035 ], [ 29.77377506329114, -1.418709655172414 ], [ 29.773505569620252, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6194, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.418709655172414 ], [ 29.77377506329114, -1.419787586206897 ], [ 29.774044556962025, -1.419787586206897 ], [ 29.774044556962025, -1.418709655172414 ], [ 29.77377506329114, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6195, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.418709655172414 ], [ 29.77431405063291, -1.419518103448276 ], [ 29.774853037974683, -1.419518103448276 ], [ 29.774853037974683, -1.418709655172414 ], [ 29.77431405063291, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6196, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.418709655172414 ], [ 29.774853037974683, -1.420057068965517 ], [ 29.775122531645568, -1.420057068965517 ], [ 29.775122531645568, -1.418709655172414 ], [ 29.774853037974683, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6197, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.418709655172414 ], [ 29.775392025316457, -1.419787586206897 ], [ 29.775661518987341, -1.419787586206897 ], [ 29.775661518987341, -1.418709655172414 ], [ 29.775392025316457, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6198, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.418709655172414 ], [ 29.775661518987341, -1.420057068965517 ], [ 29.775931012658226, -1.420057068965517 ], [ 29.775931012658226, -1.418709655172414 ], [ 29.775661518987341, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6199, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.418709655172414 ], [ 29.776200506329115, -1.420057068965517 ], [ 29.77647, -1.420057068965517 ], [ 29.77647, -1.418709655172414 ], [ 29.776200506329115, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6200, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.418709655172414 ], [ 29.77647, -1.420057068965517 ], [ 29.776739493670885, -1.420057068965517 ], [ 29.776739493670885, -1.418709655172414 ], [ 29.77647, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6201, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.418709655172414 ], [ 29.798568481012659, -1.418979137931035 ], [ 29.798837974683543, -1.418979137931035 ], [ 29.798837974683543, -1.418709655172414 ], [ 29.798568481012659, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6202, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.418709655172414 ], [ 29.798837974683543, -1.418979137931035 ], [ 29.799107468354432, -1.418979137931035 ], [ 29.799107468354432, -1.418709655172414 ], [ 29.798837974683543, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6203, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.418709655172414 ], [ 29.799107468354432, -1.418979137931035 ], [ 29.799376962025317, -1.418979137931035 ], [ 29.799376962025317, -1.418709655172414 ], [ 29.799107468354432, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6204, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.418709655172414 ], [ 29.799376962025317, -1.418979137931035 ], [ 29.799646455696202, -1.418979137931035 ], [ 29.799646455696202, -1.418709655172414 ], [ 29.799376962025317, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6205, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.418709655172414 ], [ 29.799646455696202, -1.418979137931035 ], [ 29.79991594936709, -1.418979137931035 ], [ 29.79991594936709, -1.418709655172414 ], [ 29.799646455696202, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6206, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.418709655172414 ], [ 29.79991594936709, -1.418979137931035 ], [ 29.800185443037975, -1.418979137931035 ], [ 29.800185443037975, -1.418709655172414 ], [ 29.79991594936709, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6207, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.418709655172414 ], [ 29.800185443037975, -1.418979137931035 ], [ 29.80045493670886, -1.418979137931035 ], [ 29.80045493670886, -1.418709655172414 ], [ 29.800185443037975, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6208, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.418709655172414 ], [ 29.80045493670886, -1.418979137931035 ], [ 29.800724430379748, -1.418979137931035 ], [ 29.800724430379748, -1.418709655172414 ], [ 29.80045493670886, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6209, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.418709655172414 ], [ 29.800724430379748, -1.419248620689655 ], [ 29.801263417721518, -1.419248620689655 ], [ 29.801263417721518, -1.418979137931035 ], [ 29.800993924050633, -1.418979137931035 ], [ 29.800993924050633, -1.418709655172414 ], [ 29.800724430379748, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6210, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.418709655172414 ], [ 29.800993924050633, -1.418979137931035 ], [ 29.801263417721518, -1.418979137931035 ], [ 29.801263417721518, -1.418709655172414 ], [ 29.800993924050633, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6211, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.418709655172414 ], [ 29.801263417721518, -1.418979137931035 ], [ 29.801532911392407, -1.418979137931035 ], [ 29.801532911392407, -1.418709655172414 ], [ 29.801263417721518, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6212, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.418709655172414 ], [ 29.801532911392407, -1.418979137931035 ], [ 29.802071898734177, -1.418979137931035 ], [ 29.802071898734177, -1.418709655172414 ], [ 29.801532911392407, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6213, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.418709655172414 ], [ 29.802071898734177, -1.418979137931035 ], [ 29.802341392405065, -1.418979137931035 ], [ 29.802341392405065, -1.418709655172414 ], [ 29.802071898734177, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6214, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.418709655172414 ], [ 29.802341392405065, -1.418979137931035 ], [ 29.80261088607595, -1.418979137931035 ], [ 29.80261088607595, -1.418709655172414 ], [ 29.802341392405065, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6215, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.418709655172414 ], [ 29.80261088607595, -1.418979137931035 ], [ 29.802880379746835, -1.418979137931035 ], [ 29.802880379746835, -1.418709655172414 ], [ 29.80261088607595, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6216, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.418709655172414 ], [ 29.802880379746835, -1.418979137931035 ], [ 29.803149873417723, -1.418979137931035 ], [ 29.803149873417723, -1.418709655172414 ], [ 29.802880379746835, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6217, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.418709655172414 ], [ 29.803149873417723, -1.418979137931035 ], [ 29.803419367088608, -1.418979137931035 ], [ 29.803419367088608, -1.418709655172414 ], [ 29.803149873417723, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6218, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.418979137931035 ], [ 29.763264810126582, -1.419248620689655 ], [ 29.763534303797467, -1.419248620689655 ], [ 29.763534303797467, -1.418979137931035 ], [ 29.763264810126582, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6219, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.418979137931035 ], [ 29.763534303797467, -1.419248620689655 ], [ 29.763803797468356, -1.419248620689655 ], [ 29.763803797468356, -1.418979137931035 ], [ 29.763534303797467, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6220, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.418979137931035 ], [ 29.763803797468356, -1.419248620689655 ], [ 29.76407329113924, -1.419248620689655 ], [ 29.76407329113924, -1.418979137931035 ], [ 29.763803797468356, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6221, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.418979137931035 ], [ 29.76407329113924, -1.419248620689655 ], [ 29.764342784810125, -1.419248620689655 ], [ 29.764342784810125, -1.418979137931035 ], [ 29.76407329113924, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6222, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.418979137931035 ], [ 29.764342784810125, -1.419248620689655 ], [ 29.764612278481014, -1.419248620689655 ], [ 29.764612278481014, -1.418979137931035 ], [ 29.764342784810125, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6223, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.418979137931035 ], [ 29.764612278481014, -1.419248620689655 ], [ 29.764881772151899, -1.419248620689655 ], [ 29.764881772151899, -1.418979137931035 ], [ 29.764612278481014, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6224, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.418979137931035 ], [ 29.764881772151899, -1.419248620689655 ], [ 29.765151265822784, -1.419248620689655 ], [ 29.765151265822784, -1.418979137931035 ], [ 29.764881772151899, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6225, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.418979137931035 ], [ 29.765151265822784, -1.419248620689655 ], [ 29.765420759493672, -1.419248620689655 ], [ 29.765420759493672, -1.418979137931035 ], [ 29.765151265822784, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6226, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.418979137931035 ], [ 29.765420759493672, -1.419248620689655 ], [ 29.765959746835442, -1.419248620689655 ], [ 29.765959746835442, -1.418979137931035 ], [ 29.765420759493672, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6227, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.418979137931035 ], [ 29.765959746835442, -1.419248620689655 ], [ 29.766229240506327, -1.419248620689655 ], [ 29.766229240506327, -1.418979137931035 ], [ 29.765959746835442, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6228, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.418979137931035 ], [ 29.766229240506327, -1.419248620689655 ], [ 29.766498734177215, -1.419248620689655 ], [ 29.766498734177215, -1.418979137931035 ], [ 29.766229240506327, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6229, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.418979137931035 ], [ 29.766498734177215, -1.419248620689655 ], [ 29.7667682278481, -1.419248620689655 ], [ 29.7667682278481, -1.418979137931035 ], [ 29.766498734177215, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6230, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.418979137931035 ], [ 29.7667682278481, -1.419248620689655 ], [ 29.767037721518985, -1.419248620689655 ], [ 29.767037721518985, -1.418979137931035 ], [ 29.7667682278481, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6231, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.418979137931035 ], [ 29.767037721518985, -1.419248620689655 ], [ 29.767307215189874, -1.419248620689655 ], [ 29.767307215189874, -1.418979137931035 ], [ 29.767037721518985, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6232, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.418979137931035 ], [ 29.767307215189874, -1.419248620689655 ], [ 29.767576708860759, -1.419248620689655 ], [ 29.767576708860759, -1.418979137931035 ], [ 29.767307215189874, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6233, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.418979137931035 ], [ 29.767576708860759, -1.419248620689655 ], [ 29.767846202531643, -1.419248620689655 ], [ 29.767846202531643, -1.418979137931035 ], [ 29.767576708860759, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6234, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.418979137931035 ], [ 29.767846202531643, -1.419248620689655 ], [ 29.768115696202532, -1.419248620689655 ], [ 29.768115696202532, -1.418979137931035 ], [ 29.767846202531643, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6235, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.418979137931035 ], [ 29.768115696202532, -1.419248620689655 ], [ 29.768385189873417, -1.419248620689655 ], [ 29.768385189873417, -1.418979137931035 ], [ 29.768115696202532, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6236, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.418979137931035 ], [ 29.768385189873417, -1.419248620689655 ], [ 29.768654683544302, -1.419248620689655 ], [ 29.768654683544302, -1.418979137931035 ], [ 29.768385189873417, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6237, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.418979137931035 ], [ 29.768654683544302, -1.419248620689655 ], [ 29.76892417721519, -1.419248620689655 ], [ 29.76892417721519, -1.418979137931035 ], [ 29.768654683544302, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6238, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.418979137931035 ], [ 29.76892417721519, -1.419248620689655 ], [ 29.769193670886075, -1.419248620689655 ], [ 29.769193670886075, -1.418979137931035 ], [ 29.76892417721519, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6239, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.418979137931035 ], [ 29.769193670886075, -1.419248620689655 ], [ 29.76946316455696, -1.419248620689655 ], [ 29.76946316455696, -1.418979137931035 ], [ 29.769193670886075, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6240, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.418979137931035 ], [ 29.76946316455696, -1.419248620689655 ], [ 29.769732658227849, -1.419248620689655 ], [ 29.769732658227849, -1.418979137931035 ], [ 29.76946316455696, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6241, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.418979137931035 ], [ 29.769732658227849, -1.419248620689655 ], [ 29.770002151898733, -1.419248620689655 ], [ 29.770002151898733, -1.418979137931035 ], [ 29.769732658227849, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6242, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.418979137931035 ], [ 29.770002151898733, -1.419248620689655 ], [ 29.770271645569618, -1.419248620689655 ], [ 29.770271645569618, -1.418979137931035 ], [ 29.770002151898733, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6243, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.418979137931035 ], [ 29.770271645569618, -1.419248620689655 ], [ 29.770541139240507, -1.419248620689655 ], [ 29.770541139240507, -1.418979137931035 ], [ 29.770271645569618, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6244, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.418979137931035 ], [ 29.770541139240507, -1.419248620689655 ], [ 29.770810632911392, -1.419248620689655 ], [ 29.770810632911392, -1.418979137931035 ], [ 29.770541139240507, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6245, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.418979137931035 ], [ 29.770810632911392, -1.419248620689655 ], [ 29.771080126582277, -1.419248620689655 ], [ 29.771080126582277, -1.418979137931035 ], [ 29.770810632911392, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6246, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.418979137931035 ], [ 29.771080126582277, -1.419248620689655 ], [ 29.771349620253165, -1.419248620689655 ], [ 29.771349620253165, -1.418979137931035 ], [ 29.771080126582277, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6247, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.418979137931035 ], [ 29.771349620253165, -1.419248620689655 ], [ 29.77161911392405, -1.419248620689655 ], [ 29.77161911392405, -1.418979137931035 ], [ 29.771349620253165, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6248, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.418979137931035 ], [ 29.77161911392405, -1.419248620689655 ], [ 29.771888607594935, -1.419248620689655 ], [ 29.771888607594935, -1.418979137931035 ], [ 29.77161911392405, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6249, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.418979137931035 ], [ 29.771888607594935, -1.419248620689655 ], [ 29.772158101265823, -1.419248620689655 ], [ 29.772158101265823, -1.418979137931035 ], [ 29.771888607594935, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6250, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.418979137931035 ], [ 29.772158101265823, -1.419248620689655 ], [ 29.772427594936708, -1.419248620689655 ], [ 29.772427594936708, -1.418979137931035 ], [ 29.772158101265823, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6251, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.418979137931035 ], [ 29.772427594936708, -1.419248620689655 ], [ 29.772697088607593, -1.419248620689655 ], [ 29.772697088607593, -1.418979137931035 ], [ 29.772427594936708, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6252, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.418979137931035 ], [ 29.773236075949367, -1.420057068965517 ], [ 29.773505569620252, -1.420057068965517 ], [ 29.773505569620252, -1.418979137931035 ], [ 29.773236075949367, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6253, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.418979137931035 ], [ 29.774044556962025, -1.420057068965517 ], [ 29.77431405063291, -1.420057068965517 ], [ 29.77431405063291, -1.418979137931035 ], [ 29.774044556962025, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6254, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.418979137931035 ], [ 29.798568481012659, -1.419248620689655 ], [ 29.798837974683543, -1.419248620689655 ], [ 29.798837974683543, -1.418979137931035 ], [ 29.798568481012659, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6255, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.418979137931035 ], [ 29.798837974683543, -1.419248620689655 ], [ 29.799107468354432, -1.419248620689655 ], [ 29.799107468354432, -1.418979137931035 ], [ 29.798837974683543, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6256, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.418979137931035 ], [ 29.799107468354432, -1.419248620689655 ], [ 29.799376962025317, -1.419248620689655 ], [ 29.799376962025317, -1.418979137931035 ], [ 29.799107468354432, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6257, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.418979137931035 ], [ 29.799376962025317, -1.419248620689655 ], [ 29.799646455696202, -1.419248620689655 ], [ 29.799646455696202, -1.418979137931035 ], [ 29.799376962025317, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6258, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.418979137931035 ], [ 29.799646455696202, -1.419248620689655 ], [ 29.79991594936709, -1.419248620689655 ], [ 29.79991594936709, -1.418979137931035 ], [ 29.799646455696202, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6259, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.418979137931035 ], [ 29.79991594936709, -1.419248620689655 ], [ 29.800185443037975, -1.419248620689655 ], [ 29.800185443037975, -1.418979137931035 ], [ 29.79991594936709, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6260, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.418979137931035 ], [ 29.800185443037975, -1.419248620689655 ], [ 29.80045493670886, -1.419248620689655 ], [ 29.80045493670886, -1.418979137931035 ], [ 29.800185443037975, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6261, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.418979137931035 ], [ 29.80045493670886, -1.419248620689655 ], [ 29.800724430379748, -1.419248620689655 ], [ 29.800724430379748, -1.418979137931035 ], [ 29.80045493670886, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6262, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.418979137931035 ], [ 29.801263417721518, -1.419248620689655 ], [ 29.801532911392407, -1.419248620689655 ], [ 29.801532911392407, -1.418979137931035 ], [ 29.801263417721518, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6263, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.418979137931035 ], [ 29.801532911392407, -1.419248620689655 ], [ 29.802071898734177, -1.419248620689655 ], [ 29.802071898734177, -1.418979137931035 ], [ 29.801532911392407, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6264, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.418979137931035 ], [ 29.802071898734177, -1.419248620689655 ], [ 29.802341392405065, -1.419248620689655 ], [ 29.802341392405065, -1.418979137931035 ], [ 29.802071898734177, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6265, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.418979137931035 ], [ 29.802341392405065, -1.419248620689655 ], [ 29.80261088607595, -1.419248620689655 ], [ 29.80261088607595, -1.418979137931035 ], [ 29.802341392405065, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6266, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.418979137931035 ], [ 29.80261088607595, -1.419248620689655 ], [ 29.802880379746835, -1.419248620689655 ], [ 29.802880379746835, -1.418979137931035 ], [ 29.80261088607595, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6267, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.418979137931035 ], [ 29.802880379746835, -1.419248620689655 ], [ 29.803149873417723, -1.419248620689655 ], [ 29.803149873417723, -1.418979137931035 ], [ 29.802880379746835, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.418979137931035 ], [ 29.803149873417723, -1.419248620689655 ], [ 29.803419367088608, -1.419248620689655 ], [ 29.803419367088608, -1.418979137931035 ], [ 29.803149873417723, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6269, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.418979137931035 ], [ 29.803419367088608, -1.419248620689655 ], [ 29.803688860759493, -1.419248620689655 ], [ 29.803688860759493, -1.418979137931035 ], [ 29.803419367088608, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6270, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.418979137931035 ], [ 29.803688860759493, -1.419518103448276 ], [ 29.803958354430382, -1.419518103448276 ], [ 29.803958354430382, -1.418979137931035 ], [ 29.803688860759493, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6271, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.419248620689655 ], [ 29.763534303797467, -1.419518103448276 ], [ 29.763803797468356, -1.419518103448276 ], [ 29.763803797468356, -1.419248620689655 ], [ 29.763534303797467, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6272, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.419248620689655 ], [ 29.763803797468356, -1.419518103448276 ], [ 29.76407329113924, -1.419518103448276 ], [ 29.76407329113924, -1.419248620689655 ], [ 29.763803797468356, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6273, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.419248620689655 ], [ 29.76407329113924, -1.419518103448276 ], [ 29.764342784810125, -1.419518103448276 ], [ 29.764342784810125, -1.419248620689655 ], [ 29.76407329113924, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6274, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.419248620689655 ], [ 29.764342784810125, -1.419518103448276 ], [ 29.764612278481014, -1.419518103448276 ], [ 29.764612278481014, -1.419248620689655 ], [ 29.764342784810125, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6275, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.419248620689655 ], [ 29.764612278481014, -1.419518103448276 ], [ 29.764881772151899, -1.419518103448276 ], [ 29.764881772151899, -1.419248620689655 ], [ 29.764612278481014, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6276, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.419248620689655 ], [ 29.764881772151899, -1.419518103448276 ], [ 29.765151265822784, -1.419518103448276 ], [ 29.765151265822784, -1.419248620689655 ], [ 29.764881772151899, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6277, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.419248620689655 ], [ 29.765151265822784, -1.419518103448276 ], [ 29.765420759493672, -1.419518103448276 ], [ 29.765420759493672, -1.419248620689655 ], [ 29.765151265822784, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6278, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.419248620689655 ], [ 29.765420759493672, -1.419518103448276 ], [ 29.765959746835442, -1.419518103448276 ], [ 29.765959746835442, -1.419248620689655 ], [ 29.765420759493672, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6279, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.419248620689655 ], [ 29.765959746835442, -1.419518103448276 ], [ 29.766229240506327, -1.419518103448276 ], [ 29.766229240506327, -1.419248620689655 ], [ 29.765959746835442, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6280, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.419248620689655 ], [ 29.766229240506327, -1.419518103448276 ], [ 29.766498734177215, -1.419518103448276 ], [ 29.766498734177215, -1.419248620689655 ], [ 29.766229240506327, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6281, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.419248620689655 ], [ 29.766498734177215, -1.419518103448276 ], [ 29.7667682278481, -1.419518103448276 ], [ 29.7667682278481, -1.419248620689655 ], [ 29.766498734177215, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6282, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.419248620689655 ], [ 29.7667682278481, -1.419518103448276 ], [ 29.767037721518985, -1.419518103448276 ], [ 29.767037721518985, -1.419248620689655 ], [ 29.7667682278481, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6283, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.419248620689655 ], [ 29.767037721518985, -1.419518103448276 ], [ 29.767307215189874, -1.419518103448276 ], [ 29.767307215189874, -1.419248620689655 ], [ 29.767037721518985, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6284, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.419248620689655 ], [ 29.767307215189874, -1.419518103448276 ], [ 29.767576708860759, -1.419518103448276 ], [ 29.767576708860759, -1.419248620689655 ], [ 29.767307215189874, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6285, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.419248620689655 ], [ 29.767576708860759, -1.419518103448276 ], [ 29.767846202531643, -1.419518103448276 ], [ 29.767846202531643, -1.419248620689655 ], [ 29.767576708860759, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6286, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.419248620689655 ], [ 29.767846202531643, -1.419518103448276 ], [ 29.768115696202532, -1.419518103448276 ], [ 29.768115696202532, -1.419248620689655 ], [ 29.767846202531643, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6287, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.419248620689655 ], [ 29.768115696202532, -1.419518103448276 ], [ 29.768385189873417, -1.419518103448276 ], [ 29.768385189873417, -1.419248620689655 ], [ 29.768115696202532, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6288, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.419248620689655 ], [ 29.768385189873417, -1.419518103448276 ], [ 29.768654683544302, -1.419518103448276 ], [ 29.768654683544302, -1.419248620689655 ], [ 29.768385189873417, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6289, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.419248620689655 ], [ 29.768654683544302, -1.419518103448276 ], [ 29.76892417721519, -1.419518103448276 ], [ 29.76892417721519, -1.419248620689655 ], [ 29.768654683544302, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6290, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.419248620689655 ], [ 29.76892417721519, -1.419518103448276 ], [ 29.769193670886075, -1.419518103448276 ], [ 29.769193670886075, -1.419248620689655 ], [ 29.76892417721519, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6291, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.419248620689655 ], [ 29.769193670886075, -1.419518103448276 ], [ 29.76946316455696, -1.419518103448276 ], [ 29.76946316455696, -1.419248620689655 ], [ 29.769193670886075, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6292, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.419248620689655 ], [ 29.76946316455696, -1.419518103448276 ], [ 29.769732658227849, -1.419518103448276 ], [ 29.769732658227849, -1.419248620689655 ], [ 29.76946316455696, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6293, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.419248620689655 ], [ 29.769732658227849, -1.419518103448276 ], [ 29.770002151898733, -1.419518103448276 ], [ 29.770002151898733, -1.419248620689655 ], [ 29.769732658227849, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6294, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.419248620689655 ], [ 29.770002151898733, -1.419518103448276 ], [ 29.770271645569618, -1.419518103448276 ], [ 29.770271645569618, -1.419248620689655 ], [ 29.770002151898733, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6295, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.419248620689655 ], [ 29.770271645569618, -1.419518103448276 ], [ 29.770541139240507, -1.419518103448276 ], [ 29.770541139240507, -1.419248620689655 ], [ 29.770271645569618, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6296, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.419248620689655 ], [ 29.770541139240507, -1.419518103448276 ], [ 29.770810632911392, -1.419518103448276 ], [ 29.770810632911392, -1.419248620689655 ], [ 29.770541139240507, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6297, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.419248620689655 ], [ 29.770810632911392, -1.419518103448276 ], [ 29.771080126582277, -1.419518103448276 ], [ 29.771080126582277, -1.419248620689655 ], [ 29.770810632911392, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6298, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.419248620689655 ], [ 29.771080126582277, -1.419518103448276 ], [ 29.771349620253165, -1.419518103448276 ], [ 29.771349620253165, -1.419248620689655 ], [ 29.771080126582277, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6299, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.419248620689655 ], [ 29.771349620253165, -1.419518103448276 ], [ 29.77161911392405, -1.419518103448276 ], [ 29.77161911392405, -1.419248620689655 ], [ 29.771349620253165, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6300, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.419248620689655 ], [ 29.77161911392405, -1.419518103448276 ], [ 29.771888607594935, -1.419518103448276 ], [ 29.771888607594935, -1.419248620689655 ], [ 29.77161911392405, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6301, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.419248620689655 ], [ 29.771888607594935, -1.419518103448276 ], [ 29.772158101265823, -1.419518103448276 ], [ 29.772158101265823, -1.419248620689655 ], [ 29.771888607594935, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6302, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.419248620689655 ], [ 29.772158101265823, -1.419518103448276 ], [ 29.772427594936708, -1.419518103448276 ], [ 29.772427594936708, -1.419248620689655 ], [ 29.772158101265823, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6303, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.419248620689655 ], [ 29.78132088607595, -1.419787586206897 ], [ 29.781590379746834, -1.419787586206897 ], [ 29.781590379746834, -1.419248620689655 ], [ 29.78132088607595, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6304, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.419248620689655 ], [ 29.782668354430381, -1.419787586206897 ], [ 29.782937848101266, -1.419787586206897 ], [ 29.782937848101266, -1.419248620689655 ], [ 29.782668354430381, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6305, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.419248620689655 ], [ 29.783476835443039, -1.419518103448276 ], [ 29.784015822784809, -1.419518103448276 ], [ 29.784015822784809, -1.419248620689655 ], [ 29.783476835443039, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6306, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.419248620689655 ], [ 29.784015822784809, -1.419518103448276 ], [ 29.784285316455698, -1.419518103448276 ], [ 29.784285316455698, -1.419248620689655 ], [ 29.784015822784809, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6307, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.419248620689655 ], [ 29.784285316455698, -1.419518103448276 ], [ 29.784554810126583, -1.419518103448276 ], [ 29.784554810126583, -1.419248620689655 ], [ 29.784285316455698, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6308, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.419248620689655 ], [ 29.798029493670885, -1.419518103448276 ], [ 29.798298987341774, -1.419518103448276 ], [ 29.798298987341774, -1.419248620689655 ], [ 29.798029493670885, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6309, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.419248620689655 ], [ 29.798298987341774, -1.419518103448276 ], [ 29.798568481012659, -1.419518103448276 ], [ 29.798568481012659, -1.419248620689655 ], [ 29.798298987341774, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6310, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.419248620689655 ], [ 29.798568481012659, -1.419518103448276 ], [ 29.798837974683543, -1.419518103448276 ], [ 29.798837974683543, -1.419248620689655 ], [ 29.798568481012659, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6311, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.419248620689655 ], [ 29.798837974683543, -1.419518103448276 ], [ 29.799107468354432, -1.419518103448276 ], [ 29.799107468354432, -1.419248620689655 ], [ 29.798837974683543, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6312, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.419248620689655 ], [ 29.799107468354432, -1.419518103448276 ], [ 29.799376962025317, -1.419518103448276 ], [ 29.799376962025317, -1.419248620689655 ], [ 29.799107468354432, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6313, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.419248620689655 ], [ 29.799376962025317, -1.419518103448276 ], [ 29.799646455696202, -1.419518103448276 ], [ 29.799646455696202, -1.419248620689655 ], [ 29.799376962025317, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6314, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.419248620689655 ], [ 29.799646455696202, -1.419518103448276 ], [ 29.79991594936709, -1.419518103448276 ], [ 29.79991594936709, -1.419248620689655 ], [ 29.799646455696202, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6315, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.419248620689655 ], [ 29.79991594936709, -1.419518103448276 ], [ 29.800185443037975, -1.419518103448276 ], [ 29.800185443037975, -1.419248620689655 ], [ 29.79991594936709, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6316, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.419248620689655 ], [ 29.800185443037975, -1.419518103448276 ], [ 29.80045493670886, -1.419518103448276 ], [ 29.80045493670886, -1.419248620689655 ], [ 29.800185443037975, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6317, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.419248620689655 ], [ 29.80045493670886, -1.419518103448276 ], [ 29.800724430379748, -1.419518103448276 ], [ 29.800724430379748, -1.419248620689655 ], [ 29.80045493670886, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6318, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.419248620689655 ], [ 29.800724430379748, -1.419518103448276 ], [ 29.800993924050633, -1.419518103448276 ], [ 29.800993924050633, -1.419248620689655 ], [ 29.800724430379748, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6319, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.419248620689655 ], [ 29.800993924050633, -1.419518103448276 ], [ 29.801263417721518, -1.419518103448276 ], [ 29.801263417721518, -1.419248620689655 ], [ 29.800993924050633, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6320, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.419248620689655 ], [ 29.801263417721518, -1.419518103448276 ], [ 29.801532911392407, -1.419518103448276 ], [ 29.801532911392407, -1.419248620689655 ], [ 29.801263417721518, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6321, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.419248620689655 ], [ 29.801532911392407, -1.419518103448276 ], [ 29.802071898734177, -1.419518103448276 ], [ 29.802071898734177, -1.419248620689655 ], [ 29.801532911392407, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6322, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.419248620689655 ], [ 29.802071898734177, -1.419518103448276 ], [ 29.802341392405065, -1.419518103448276 ], [ 29.802341392405065, -1.419248620689655 ], [ 29.802071898734177, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6323, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.419248620689655 ], [ 29.802341392405065, -1.419518103448276 ], [ 29.80261088607595, -1.419518103448276 ], [ 29.80261088607595, -1.419248620689655 ], [ 29.802341392405065, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6324, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.419248620689655 ], [ 29.80261088607595, -1.419518103448276 ], [ 29.802880379746835, -1.419518103448276 ], [ 29.802880379746835, -1.419248620689655 ], [ 29.80261088607595, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6325, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.419248620689655 ], [ 29.802880379746835, -1.419518103448276 ], [ 29.803149873417723, -1.419518103448276 ], [ 29.803149873417723, -1.419248620689655 ], [ 29.802880379746835, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6326, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.419248620689655 ], [ 29.803149873417723, -1.419518103448276 ], [ 29.803419367088608, -1.419518103448276 ], [ 29.803419367088608, -1.419248620689655 ], [ 29.803149873417723, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6327, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.419248620689655 ], [ 29.803419367088608, -1.419518103448276 ], [ 29.803688860759493, -1.419518103448276 ], [ 29.803688860759493, -1.419248620689655 ], [ 29.803419367088608, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6328, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.419518103448276 ], [ 29.763803797468356, -1.419787586206897 ], [ 29.76407329113924, -1.419787586206897 ], [ 29.76407329113924, -1.419518103448276 ], [ 29.763803797468356, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6329, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.419518103448276 ], [ 29.76407329113924, -1.419787586206897 ], [ 29.764342784810125, -1.419787586206897 ], [ 29.764342784810125, -1.419518103448276 ], [ 29.76407329113924, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6330, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.419518103448276 ], [ 29.764342784810125, -1.419787586206897 ], [ 29.764612278481014, -1.419787586206897 ], [ 29.764612278481014, -1.419518103448276 ], [ 29.764342784810125, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6331, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.419518103448276 ], [ 29.764612278481014, -1.419787586206897 ], [ 29.764881772151899, -1.419787586206897 ], [ 29.764881772151899, -1.419518103448276 ], [ 29.764612278481014, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6332, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.419518103448276 ], [ 29.764881772151899, -1.419787586206897 ], [ 29.765151265822784, -1.419787586206897 ], [ 29.765151265822784, -1.419518103448276 ], [ 29.764881772151899, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6333, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.419518103448276 ], [ 29.765151265822784, -1.419787586206897 ], [ 29.765420759493672, -1.419787586206897 ], [ 29.765420759493672, -1.419518103448276 ], [ 29.765151265822784, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6334, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.419518103448276 ], [ 29.765420759493672, -1.419787586206897 ], [ 29.765959746835442, -1.419787586206897 ], [ 29.765959746835442, -1.419518103448276 ], [ 29.765420759493672, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6335, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.419518103448276 ], [ 29.765959746835442, -1.419787586206897 ], [ 29.766229240506327, -1.419787586206897 ], [ 29.766229240506327, -1.419518103448276 ], [ 29.765959746835442, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6336, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.419518103448276 ], [ 29.766229240506327, -1.419787586206897 ], [ 29.766498734177215, -1.419787586206897 ], [ 29.766498734177215, -1.419518103448276 ], [ 29.766229240506327, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6337, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.419518103448276 ], [ 29.766498734177215, -1.419787586206897 ], [ 29.7667682278481, -1.419787586206897 ], [ 29.7667682278481, -1.419518103448276 ], [ 29.766498734177215, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6338, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.419518103448276 ], [ 29.7667682278481, -1.419787586206897 ], [ 29.767037721518985, -1.419787586206897 ], [ 29.767037721518985, -1.419518103448276 ], [ 29.7667682278481, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6339, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.419518103448276 ], [ 29.767037721518985, -1.419787586206897 ], [ 29.767307215189874, -1.419787586206897 ], [ 29.767307215189874, -1.419518103448276 ], [ 29.767037721518985, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6340, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.419518103448276 ], [ 29.767307215189874, -1.419787586206897 ], [ 29.767576708860759, -1.419787586206897 ], [ 29.767576708860759, -1.419518103448276 ], [ 29.767307215189874, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6341, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.419518103448276 ], [ 29.767576708860759, -1.419787586206897 ], [ 29.767846202531643, -1.419787586206897 ], [ 29.767846202531643, -1.419518103448276 ], [ 29.767576708860759, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6342, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.419518103448276 ], [ 29.767846202531643, -1.419787586206897 ], [ 29.768115696202532, -1.419787586206897 ], [ 29.768115696202532, -1.419518103448276 ], [ 29.767846202531643, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6343, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.419518103448276 ], [ 29.768115696202532, -1.419787586206897 ], [ 29.768385189873417, -1.419787586206897 ], [ 29.768385189873417, -1.419518103448276 ], [ 29.768115696202532, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6344, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.419518103448276 ], [ 29.768385189873417, -1.419787586206897 ], [ 29.768654683544302, -1.419787586206897 ], [ 29.768654683544302, -1.419518103448276 ], [ 29.768385189873417, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6345, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.419518103448276 ], [ 29.768654683544302, -1.419787586206897 ], [ 29.76892417721519, -1.419787586206897 ], [ 29.76892417721519, -1.419518103448276 ], [ 29.768654683544302, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6346, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.419518103448276 ], [ 29.76892417721519, -1.419787586206897 ], [ 29.769193670886075, -1.419787586206897 ], [ 29.769193670886075, -1.419518103448276 ], [ 29.76892417721519, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6347, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.419518103448276 ], [ 29.769193670886075, -1.419787586206897 ], [ 29.76946316455696, -1.419787586206897 ], [ 29.76946316455696, -1.419518103448276 ], [ 29.769193670886075, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6348, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.419518103448276 ], [ 29.76946316455696, -1.419787586206897 ], [ 29.769732658227849, -1.419787586206897 ], [ 29.769732658227849, -1.419518103448276 ], [ 29.76946316455696, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6349, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.419518103448276 ], [ 29.769732658227849, -1.419787586206897 ], [ 29.770002151898733, -1.419787586206897 ], [ 29.770002151898733, -1.419518103448276 ], [ 29.769732658227849, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6350, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.419518103448276 ], [ 29.770002151898733, -1.419787586206897 ], [ 29.770271645569618, -1.419787586206897 ], [ 29.770271645569618, -1.419518103448276 ], [ 29.770002151898733, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6351, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.419518103448276 ], [ 29.770271645569618, -1.419787586206897 ], [ 29.770541139240507, -1.419787586206897 ], [ 29.770541139240507, -1.419518103448276 ], [ 29.770271645569618, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6352, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.419518103448276 ], [ 29.770541139240507, -1.419787586206897 ], [ 29.770810632911392, -1.419787586206897 ], [ 29.770810632911392, -1.419518103448276 ], [ 29.770541139240507, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6353, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.419518103448276 ], [ 29.770810632911392, -1.419787586206897 ], [ 29.771080126582277, -1.419787586206897 ], [ 29.771080126582277, -1.419518103448276 ], [ 29.770810632911392, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6354, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.419518103448276 ], [ 29.771080126582277, -1.419787586206897 ], [ 29.771349620253165, -1.419787586206897 ], [ 29.771349620253165, -1.419518103448276 ], [ 29.771080126582277, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6355, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.419518103448276 ], [ 29.771349620253165, -1.419787586206897 ], [ 29.77161911392405, -1.419787586206897 ], [ 29.77161911392405, -1.419518103448276 ], [ 29.771349620253165, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6356, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.419518103448276 ], [ 29.77161911392405, -1.419787586206897 ], [ 29.771888607594935, -1.419787586206897 ], [ 29.771888607594935, -1.419518103448276 ], [ 29.77161911392405, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6357, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.419518103448276 ], [ 29.771888607594935, -1.419787586206897 ], [ 29.772158101265823, -1.419787586206897 ], [ 29.772158101265823, -1.419518103448276 ], [ 29.771888607594935, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6358, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.419518103448276 ], [ 29.772158101265823, -1.419787586206897 ], [ 29.772427594936708, -1.419787586206897 ], [ 29.772427594936708, -1.419518103448276 ], [ 29.772158101265823, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6359, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.419518103448276 ], [ 29.772427594936708, -1.419787586206897 ], [ 29.772697088607593, -1.419787586206897 ], [ 29.772697088607593, -1.419518103448276 ], [ 29.772427594936708, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6360, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.419518103448276 ], [ 29.77862594936709, -1.420057068965517 ], [ 29.778895443037975, -1.420057068965517 ], [ 29.778895443037975, -1.419518103448276 ], [ 29.77862594936709, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6361, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.419518103448276 ], [ 29.779434430379748, -1.420057068965517 ], [ 29.779703924050633, -1.420057068965517 ], [ 29.779703924050633, -1.419518103448276 ], [ 29.779434430379748, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6362, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.419518103448276 ], [ 29.779973417721518, -1.420057068965517 ], [ 29.780242911392406, -1.420057068965517 ], [ 29.780242911392406, -1.419518103448276 ], [ 29.779973417721518, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6363, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.419518103448276 ], [ 29.780781898734176, -1.420057068965517 ], [ 29.781051392405065, -1.420057068965517 ], [ 29.781051392405065, -1.419518103448276 ], [ 29.780781898734176, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6364, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.419518103448276 ], [ 29.781590379746834, -1.420057068965517 ], [ 29.781859873417723, -1.420057068965517 ], [ 29.781859873417723, -1.419518103448276 ], [ 29.781590379746834, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6365, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.419518103448276 ], [ 29.782129367088608, -1.419787586206897 ], [ 29.782398860759493, -1.419787586206897 ], [ 29.782398860759493, -1.419518103448276 ], [ 29.782129367088608, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6366, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.419518103448276 ], [ 29.782398860759493, -1.419787586206897 ], [ 29.782668354430381, -1.419787586206897 ], [ 29.782668354430381, -1.419518103448276 ], [ 29.782398860759493, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6367, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.419518103448276 ], [ 29.782937848101266, -1.419787586206897 ], [ 29.783207341772151, -1.419787586206897 ], [ 29.783207341772151, -1.419518103448276 ], [ 29.782937848101266, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6368, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.419518103448276 ], [ 29.783207341772151, -1.419787586206897 ], [ 29.783476835443039, -1.419787586206897 ], [ 29.783476835443039, -1.419518103448276 ], [ 29.783207341772151, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6369, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.419518103448276 ], [ 29.783476835443039, -1.419787586206897 ], [ 29.784015822784809, -1.419787586206897 ], [ 29.784015822784809, -1.419518103448276 ], [ 29.783476835443039, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6370, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.419518103448276 ], [ 29.798029493670885, -1.419787586206897 ], [ 29.798568481012659, -1.419787586206897 ], [ 29.798568481012659, -1.419518103448276 ], [ 29.798029493670885, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6371, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.419518103448276 ], [ 29.798568481012659, -1.419787586206897 ], [ 29.798837974683543, -1.419787586206897 ], [ 29.798837974683543, -1.419518103448276 ], [ 29.798568481012659, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6372, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.419518103448276 ], [ 29.798837974683543, -1.419787586206897 ], [ 29.799107468354432, -1.419787586206897 ], [ 29.799107468354432, -1.419518103448276 ], [ 29.798837974683543, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6373, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.419518103448276 ], [ 29.799107468354432, -1.419787586206897 ], [ 29.799376962025317, -1.419787586206897 ], [ 29.799376962025317, -1.419518103448276 ], [ 29.799107468354432, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6374, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.419518103448276 ], [ 29.799376962025317, -1.419787586206897 ], [ 29.799646455696202, -1.419787586206897 ], [ 29.799646455696202, -1.419518103448276 ], [ 29.799376962025317, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6375, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.419518103448276 ], [ 29.799646455696202, -1.419787586206897 ], [ 29.79991594936709, -1.419787586206897 ], [ 29.79991594936709, -1.419518103448276 ], [ 29.799646455696202, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6376, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.419518103448276 ], [ 29.79991594936709, -1.419787586206897 ], [ 29.800185443037975, -1.419787586206897 ], [ 29.800185443037975, -1.419518103448276 ], [ 29.79991594936709, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6377, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.419518103448276 ], [ 29.800185443037975, -1.419787586206897 ], [ 29.80045493670886, -1.419787586206897 ], [ 29.80045493670886, -1.419518103448276 ], [ 29.800185443037975, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6378, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.419518103448276 ], [ 29.80045493670886, -1.419787586206897 ], [ 29.800724430379748, -1.419787586206897 ], [ 29.800724430379748, -1.419518103448276 ], [ 29.80045493670886, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6379, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.419518103448276 ], [ 29.800724430379748, -1.419787586206897 ], [ 29.800993924050633, -1.419787586206897 ], [ 29.800993924050633, -1.419518103448276 ], [ 29.800724430379748, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6380, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.419518103448276 ], [ 29.800993924050633, -1.419787586206897 ], [ 29.801263417721518, -1.419787586206897 ], [ 29.801263417721518, -1.419518103448276 ], [ 29.800993924050633, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6381, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.419518103448276 ], [ 29.801263417721518, -1.419787586206897 ], [ 29.801532911392407, -1.419787586206897 ], [ 29.801532911392407, -1.419518103448276 ], [ 29.801263417721518, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6382, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.419518103448276 ], [ 29.801532911392407, -1.419787586206897 ], [ 29.802071898734177, -1.419787586206897 ], [ 29.802071898734177, -1.419518103448276 ], [ 29.801532911392407, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6383, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.419518103448276 ], [ 29.802071898734177, -1.419787586206897 ], [ 29.802341392405065, -1.419787586206897 ], [ 29.802341392405065, -1.419518103448276 ], [ 29.802071898734177, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6384, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.419518103448276 ], [ 29.802341392405065, -1.419787586206897 ], [ 29.80261088607595, -1.419787586206897 ], [ 29.80261088607595, -1.419518103448276 ], [ 29.802341392405065, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6385, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.419518103448276 ], [ 29.80261088607595, -1.419787586206897 ], [ 29.802880379746835, -1.419787586206897 ], [ 29.802880379746835, -1.419518103448276 ], [ 29.80261088607595, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6386, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.419518103448276 ], [ 29.802880379746835, -1.419787586206897 ], [ 29.803149873417723, -1.419787586206897 ], [ 29.803149873417723, -1.419518103448276 ], [ 29.802880379746835, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6387, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.419518103448276 ], [ 29.803149873417723, -1.419787586206897 ], [ 29.803419367088608, -1.419787586206897 ], [ 29.803419367088608, -1.419518103448276 ], [ 29.803149873417723, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6388, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.419518103448276 ], [ 29.803419367088608, -1.419787586206897 ], [ 29.803688860759493, -1.419787586206897 ], [ 29.803688860759493, -1.419518103448276 ], [ 29.803419367088608, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6389, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.419518103448276 ], [ 29.803688860759493, -1.419787586206897 ], [ 29.803958354430382, -1.419787586206897 ], [ 29.803958354430382, -1.419518103448276 ], [ 29.803688860759493, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6390, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.419518103448276 ], [ 29.803958354430382, -1.420057068965517 ], [ 29.804227848101267, -1.420057068965517 ], [ 29.804227848101267, -1.419518103448276 ], [ 29.803958354430382, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6391, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.419787586206897 ], [ 29.764342784810125, -1.420057068965517 ], [ 29.764612278481014, -1.420057068965517 ], [ 29.764612278481014, -1.419787586206897 ], [ 29.764342784810125, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6392, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.419787586206897 ], [ 29.764612278481014, -1.420057068965517 ], [ 29.764881772151899, -1.420057068965517 ], [ 29.764881772151899, -1.419787586206897 ], [ 29.764612278481014, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6393, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.419787586206897 ], [ 29.764881772151899, -1.420057068965517 ], [ 29.765151265822784, -1.420057068965517 ], [ 29.765151265822784, -1.419787586206897 ], [ 29.764881772151899, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6394, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.419787586206897 ], [ 29.765151265822784, -1.420057068965517 ], [ 29.765420759493672, -1.420057068965517 ], [ 29.765420759493672, -1.419787586206897 ], [ 29.765151265822784, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6395, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.419787586206897 ], [ 29.765420759493672, -1.420057068965517 ], [ 29.765959746835442, -1.420057068965517 ], [ 29.765959746835442, -1.419787586206897 ], [ 29.765420759493672, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6396, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.419787586206897 ], [ 29.765959746835442, -1.420057068965517 ], [ 29.766229240506327, -1.420057068965517 ], [ 29.766229240506327, -1.419787586206897 ], [ 29.765959746835442, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6397, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.419787586206897 ], [ 29.766229240506327, -1.420057068965517 ], [ 29.766498734177215, -1.420057068965517 ], [ 29.766498734177215, -1.419787586206897 ], [ 29.766229240506327, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6398, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.419787586206897 ], [ 29.766498734177215, -1.420057068965517 ], [ 29.7667682278481, -1.420057068965517 ], [ 29.7667682278481, -1.419787586206897 ], [ 29.766498734177215, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6399, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.419787586206897 ], [ 29.7667682278481, -1.420057068965517 ], [ 29.767037721518985, -1.420057068965517 ], [ 29.767037721518985, -1.419787586206897 ], [ 29.7667682278481, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6400, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.419787586206897 ], [ 29.767037721518985, -1.420057068965517 ], [ 29.767307215189874, -1.420057068965517 ], [ 29.767307215189874, -1.419787586206897 ], [ 29.767037721518985, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6401, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.419787586206897 ], [ 29.767307215189874, -1.420057068965517 ], [ 29.767576708860759, -1.420057068965517 ], [ 29.767576708860759, -1.419787586206897 ], [ 29.767307215189874, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6402, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.419787586206897 ], [ 29.767576708860759, -1.420057068965517 ], [ 29.767846202531643, -1.420057068965517 ], [ 29.767846202531643, -1.419787586206897 ], [ 29.767576708860759, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6403, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.419787586206897 ], [ 29.767846202531643, -1.420057068965517 ], [ 29.768115696202532, -1.420057068965517 ], [ 29.768115696202532, -1.419787586206897 ], [ 29.767846202531643, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6404, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.419787586206897 ], [ 29.768115696202532, -1.420057068965517 ], [ 29.768385189873417, -1.420057068965517 ], [ 29.768385189873417, -1.419787586206897 ], [ 29.768115696202532, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6405, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.419787586206897 ], [ 29.768385189873417, -1.420057068965517 ], [ 29.768654683544302, -1.420057068965517 ], [ 29.768654683544302, -1.419787586206897 ], [ 29.768385189873417, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6406, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.419787586206897 ], [ 29.768654683544302, -1.420057068965517 ], [ 29.76892417721519, -1.420057068965517 ], [ 29.76892417721519, -1.419787586206897 ], [ 29.768654683544302, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6407, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.419787586206897 ], [ 29.76892417721519, -1.420057068965517 ], [ 29.769193670886075, -1.420057068965517 ], [ 29.769193670886075, -1.419787586206897 ], [ 29.76892417721519, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6408, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.419787586206897 ], [ 29.769193670886075, -1.420057068965517 ], [ 29.76946316455696, -1.420057068965517 ], [ 29.76946316455696, -1.419787586206897 ], [ 29.769193670886075, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6409, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.419787586206897 ], [ 29.76946316455696, -1.420057068965517 ], [ 29.769732658227849, -1.420057068965517 ], [ 29.769732658227849, -1.419787586206897 ], [ 29.76946316455696, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6410, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.419787586206897 ], [ 29.769732658227849, -1.420057068965517 ], [ 29.770002151898733, -1.420057068965517 ], [ 29.770002151898733, -1.419787586206897 ], [ 29.769732658227849, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6411, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.419787586206897 ], [ 29.770002151898733, -1.420057068965517 ], [ 29.770271645569618, -1.420057068965517 ], [ 29.770271645569618, -1.419787586206897 ], [ 29.770002151898733, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6412, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.419787586206897 ], [ 29.770271645569618, -1.420057068965517 ], [ 29.770541139240507, -1.420057068965517 ], [ 29.770541139240507, -1.419787586206897 ], [ 29.770271645569618, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6413, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.419787586206897 ], [ 29.770541139240507, -1.420057068965517 ], [ 29.770810632911392, -1.420057068965517 ], [ 29.770810632911392, -1.419787586206897 ], [ 29.770541139240507, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6414, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.419787586206897 ], [ 29.770810632911392, -1.420057068965517 ], [ 29.771080126582277, -1.420057068965517 ], [ 29.771080126582277, -1.419787586206897 ], [ 29.770810632911392, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6415, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.419787586206897 ], [ 29.771080126582277, -1.420057068965517 ], [ 29.771349620253165, -1.420057068965517 ], [ 29.771349620253165, -1.419787586206897 ], [ 29.771080126582277, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6416, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.419787586206897 ], [ 29.771349620253165, -1.420057068965517 ], [ 29.77161911392405, -1.420057068965517 ], [ 29.77161911392405, -1.419787586206897 ], [ 29.771349620253165, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6417, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.419787586206897 ], [ 29.77161911392405, -1.420057068965517 ], [ 29.771888607594935, -1.420057068965517 ], [ 29.771888607594935, -1.419787586206897 ], [ 29.77161911392405, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6418, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.419787586206897 ], [ 29.771888607594935, -1.420057068965517 ], [ 29.772158101265823, -1.420057068965517 ], [ 29.772158101265823, -1.419787586206897 ], [ 29.771888607594935, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6419, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.419787586206897 ], [ 29.772158101265823, -1.420057068965517 ], [ 29.772427594936708, -1.420057068965517 ], [ 29.772427594936708, -1.419787586206897 ], [ 29.772158101265823, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6420, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.419787586206897 ], [ 29.772427594936708, -1.420057068965517 ], [ 29.772697088607593, -1.420057068965517 ], [ 29.772697088607593, -1.419787586206897 ], [ 29.772427594936708, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6421, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.419787586206897 ], [ 29.780242911392406, -1.420057068965517 ], [ 29.780512405063291, -1.420057068965517 ], [ 29.780512405063291, -1.419787586206897 ], [ 29.780242911392406, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6422, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.419787586206897 ], [ 29.781051392405065, -1.420057068965517 ], [ 29.78132088607595, -1.420057068965517 ], [ 29.78132088607595, -1.419787586206897 ], [ 29.781051392405065, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6423, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.419787586206897 ], [ 29.78132088607595, -1.420057068965517 ], [ 29.781590379746834, -1.420057068965517 ], [ 29.781590379746834, -1.419787586206897 ], [ 29.78132088607595, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6424, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.419787586206897 ], [ 29.781859873417723, -1.420057068965517 ], [ 29.782129367088608, -1.420057068965517 ], [ 29.782129367088608, -1.419787586206897 ], [ 29.781859873417723, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6425, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.419787586206897 ], [ 29.782129367088608, -1.420057068965517 ], [ 29.782398860759493, -1.420057068965517 ], [ 29.782398860759493, -1.419787586206897 ], [ 29.782129367088608, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6426, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.419787586206897 ], [ 29.782398860759493, -1.420057068965517 ], [ 29.782668354430381, -1.420057068965517 ], [ 29.782668354430381, -1.419787586206897 ], [ 29.782398860759493, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6427, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.419787586206897 ], [ 29.782668354430381, -1.420057068965517 ], [ 29.782937848101266, -1.420057068965517 ], [ 29.782937848101266, -1.419787586206897 ], [ 29.782668354430381, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6428, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.419787586206897 ], [ 29.798568481012659, -1.420057068965517 ], [ 29.799107468354432, -1.420057068965517 ], [ 29.799107468354432, -1.419787586206897 ], [ 29.798568481012659, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6429, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.419787586206897 ], [ 29.799107468354432, -1.420057068965517 ], [ 29.799376962025317, -1.420057068965517 ], [ 29.799376962025317, -1.419787586206897 ], [ 29.799107468354432, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6430, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.419787586206897 ], [ 29.799376962025317, -1.420057068965517 ], [ 29.799646455696202, -1.420057068965517 ], [ 29.799646455696202, -1.419787586206897 ], [ 29.799376962025317, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6431, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.419787586206897 ], [ 29.799646455696202, -1.420057068965517 ], [ 29.79991594936709, -1.420057068965517 ], [ 29.79991594936709, -1.419787586206897 ], [ 29.799646455696202, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6432, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.419787586206897 ], [ 29.79991594936709, -1.420057068965517 ], [ 29.800185443037975, -1.420057068965517 ], [ 29.800185443037975, -1.419787586206897 ], [ 29.79991594936709, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6433, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.419787586206897 ], [ 29.800185443037975, -1.420057068965517 ], [ 29.80045493670886, -1.420057068965517 ], [ 29.80045493670886, -1.419787586206897 ], [ 29.800185443037975, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6434, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.419787586206897 ], [ 29.80045493670886, -1.420057068965517 ], [ 29.800724430379748, -1.420057068965517 ], [ 29.800724430379748, -1.419787586206897 ], [ 29.80045493670886, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6435, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.419787586206897 ], [ 29.800724430379748, -1.420057068965517 ], [ 29.800993924050633, -1.420057068965517 ], [ 29.800993924050633, -1.419787586206897 ], [ 29.800724430379748, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6436, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.419787586206897 ], [ 29.801263417721518, -1.420057068965517 ], [ 29.801532911392407, -1.420057068965517 ], [ 29.801532911392407, -1.419787586206897 ], [ 29.801263417721518, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6437, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.419787586206897 ], [ 29.802071898734177, -1.420057068965517 ], [ 29.802341392405065, -1.420057068965517 ], [ 29.802341392405065, -1.419787586206897 ], [ 29.802071898734177, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6438, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.419787586206897 ], [ 29.802341392405065, -1.420057068965517 ], [ 29.80261088607595, -1.420057068965517 ], [ 29.80261088607595, -1.419787586206897 ], [ 29.802341392405065, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6439, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.419787586206897 ], [ 29.80261088607595, -1.420057068965517 ], [ 29.802880379746835, -1.420057068965517 ], [ 29.802880379746835, -1.419787586206897 ], [ 29.80261088607595, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6440, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.419787586206897 ], [ 29.802880379746835, -1.420057068965517 ], [ 29.803149873417723, -1.420057068965517 ], [ 29.803149873417723, -1.419787586206897 ], [ 29.802880379746835, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6441, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.419787586206897 ], [ 29.803149873417723, -1.420057068965517 ], [ 29.803419367088608, -1.420057068965517 ], [ 29.803419367088608, -1.419787586206897 ], [ 29.803149873417723, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6442, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.419787586206897 ], [ 29.803419367088608, -1.420057068965517 ], [ 29.803688860759493, -1.420057068965517 ], [ 29.803688860759493, -1.419787586206897 ], [ 29.803419367088608, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6443, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.419787586206897 ], [ 29.803688860759493, -1.420057068965517 ], [ 29.803958354430382, -1.420057068965517 ], [ 29.803958354430382, -1.419787586206897 ], [ 29.803688860759493, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6444, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.41709275862069 ], [ 29.760300379746834, -1.421135 ], [ 29.760569873417722, -1.421135 ], [ 29.760569873417722, -1.41709275862069 ], [ 29.760300379746834, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6445, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.41709275862069 ], [ 29.761108860759492, -1.421135 ], [ 29.761378354430381, -1.421135 ], [ 29.761378354430381, -1.41709275862069 ], [ 29.761108860759492, -1.41709275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6446, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.417631724137931 ], [ 29.758952911392406, -1.420865517241379 ], [ 29.759222405063291, -1.420865517241379 ], [ 29.759222405063291, -1.417631724137931 ], [ 29.758952911392406, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6447, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.417631724137931 ], [ 29.759222405063291, -1.420865517241379 ], [ 29.759491898734176, -1.420865517241379 ], [ 29.759491898734176, -1.417631724137931 ], [ 29.759222405063291, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6448, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.417631724137931 ], [ 29.759761392405064, -1.420865517241379 ], [ 29.760030886075949, -1.420865517241379 ], [ 29.760030886075949, -1.417631724137931 ], [ 29.759761392405064, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6449, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.417631724137931 ], [ 29.760030886075949, -1.420865517241379 ], [ 29.760300379746834, -1.420865517241379 ], [ 29.760300379746834, -1.417631724137931 ], [ 29.760030886075949, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6450, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.417631724137931 ], [ 29.760569873417722, -1.420865517241379 ], [ 29.760839367088607, -1.420865517241379 ], [ 29.760839367088607, -1.417631724137931 ], [ 29.760569873417722, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6451, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.417631724137931 ], [ 29.761378354430381, -1.420865517241379 ], [ 29.761647848101266, -1.420865517241379 ], [ 29.761647848101266, -1.417631724137931 ], [ 29.761378354430381, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6452, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.417631724137931 ], [ 29.761647848101266, -1.421135 ], [ 29.761917341772151, -1.421135 ], [ 29.761917341772151, -1.417901206896552 ], [ 29.762995316455697, -1.417901206896552 ], [ 29.762995316455697, -1.417631724137931 ], [ 29.761647848101266, -1.417631724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6453, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.417901206896552 ], [ 29.758144430379748, -1.420596034482759 ], [ 29.758413924050632, -1.420596034482759 ], [ 29.758413924050632, -1.417901206896552 ], [ 29.758144430379748, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6454, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.417901206896552 ], [ 29.758413924050632, -1.420596034482759 ], [ 29.758683417721517, -1.420596034482759 ], [ 29.758683417721517, -1.417901206896552 ], [ 29.758413924050632, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6455, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.417901206896552 ], [ 29.758683417721517, -1.420596034482759 ], [ 29.758952911392406, -1.420596034482759 ], [ 29.758952911392406, -1.417901206896552 ], [ 29.758683417721517, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6456, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.417901206896552 ], [ 29.759491898734176, -1.420596034482759 ], [ 29.759761392405064, -1.420596034482759 ], [ 29.759761392405064, -1.417901206896552 ], [ 29.759491898734176, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6457, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.417901206896552 ], [ 29.760839367088607, -1.420596034482759 ], [ 29.761108860759492, -1.420596034482759 ], [ 29.761108860759492, -1.417901206896552 ], [ 29.760839367088607, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6458, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.417901206896552 ], [ 29.761917341772151, -1.421135 ], [ 29.762186835443039, -1.421135 ], [ 29.762186835443039, -1.418170689655172 ], [ 29.762995316455697, -1.418170689655172 ], [ 29.762995316455697, -1.417901206896552 ], [ 29.761917341772151, -1.417901206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6459, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.418170689655172 ], [ 29.762186835443039, -1.420865517241379 ], [ 29.762456329113924, -1.420865517241379 ], [ 29.762456329113924, -1.418440172413793 ], [ 29.762725822784809, -1.418440172413793 ], [ 29.762725822784809, -1.418170689655172 ], [ 29.762186835443039, -1.418170689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6460, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.418440172413793 ], [ 29.762456329113924, -1.421135 ], [ 29.762725822784809, -1.421135 ], [ 29.762725822784809, -1.418709655172414 ], [ 29.762995316455697, -1.418709655172414 ], [ 29.762995316455697, -1.418440172413793 ], [ 29.762456329113924, -1.418440172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6461, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.418709655172414 ], [ 29.762725822784809, -1.420865517241379 ], [ 29.762995316455697, -1.420865517241379 ], [ 29.762995316455697, -1.418709655172414 ], [ 29.762725822784809, -1.418709655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6462, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.418979137931035 ], [ 29.762995316455697, -1.421404482758621 ], [ 29.763264810126582, -1.421404482758621 ], [ 29.763264810126582, -1.418979137931035 ], [ 29.762995316455697, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6463, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.418979137931035 ], [ 29.773505569620252, -1.420326551724138 ], [ 29.77377506329114, -1.420326551724138 ], [ 29.77377506329114, -1.418979137931035 ], [ 29.773505569620252, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6464, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.418979137931035 ], [ 29.775122531645568, -1.420326551724138 ], [ 29.775392025316457, -1.420326551724138 ], [ 29.775392025316457, -1.418979137931035 ], [ 29.775122531645568, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6465, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.419248620689655 ], [ 29.763264810126582, -1.421135 ], [ 29.763534303797467, -1.421135 ], [ 29.763534303797467, -1.419248620689655 ], [ 29.763264810126582, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6466, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.418979137931035 ], [ 29.772966582278482, -1.418979137931035 ], [ 29.772966582278482, -1.420326551724138 ], [ 29.772697088607593, -1.420326551724138 ], [ 29.772697088607593, -1.419518103448276 ], [ 29.772427594936708, -1.419518103448276 ], [ 29.772427594936708, -1.419248620689655 ], [ 29.772697088607593, -1.419248620689655 ], [ 29.772697088607593, -1.418979137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6467, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.419248620689655 ], [ 29.784554810126583, -1.420865517241379 ], [ 29.784824303797468, -1.420865517241379 ], [ 29.784824303797468, -1.419248620689655 ], [ 29.784554810126583, -1.419248620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6468, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.419518103448276 ], [ 29.763534303797467, -1.420865517241379 ], [ 29.763803797468356, -1.420865517241379 ], [ 29.763803797468356, -1.419518103448276 ], [ 29.763534303797467, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6469, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.419518103448276 ], [ 29.772966582278482, -1.420596034482759 ], [ 29.773236075949367, -1.420596034482759 ], [ 29.773236075949367, -1.419518103448276 ], [ 29.772966582278482, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6470, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.419518103448276 ], [ 29.77431405063291, -1.420326551724138 ], [ 29.774853037974683, -1.420326551724138 ], [ 29.774853037974683, -1.419518103448276 ], [ 29.77431405063291, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6471, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.419518103448276 ], [ 29.775931012658226, -1.420326551724138 ], [ 29.776200506329115, -1.420326551724138 ], [ 29.776200506329115, -1.419518103448276 ], [ 29.775931012658226, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6472, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.419518103448276 ], [ 29.777278481012658, -1.420326551724138 ], [ 29.777547974683543, -1.420326551724138 ], [ 29.777547974683543, -1.419518103448276 ], [ 29.777278481012658, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6473, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.419518103448276 ], [ 29.784015822784809, -1.420865517241379 ], [ 29.784285316455698, -1.420865517241379 ], [ 29.784285316455698, -1.419518103448276 ], [ 29.784015822784809, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6474, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.419518103448276 ], [ 29.784285316455698, -1.420865517241379 ], [ 29.784554810126583, -1.420865517241379 ], [ 29.784554810126583, -1.419518103448276 ], [ 29.784285316455698, -1.419518103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6475, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.419787586206897 ], [ 29.763803797468356, -1.421404482758621 ], [ 29.76407329113924, -1.421404482758621 ], [ 29.76407329113924, -1.419787586206897 ], [ 29.763803797468356, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6476, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.419787586206897 ], [ 29.76407329113924, -1.421135 ], [ 29.764342784810125, -1.421135 ], [ 29.764342784810125, -1.419787586206897 ], [ 29.76407329113924, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6477, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.419787586206897 ], [ 29.77377506329114, -1.420596034482759 ], [ 29.774044556962025, -1.420596034482759 ], [ 29.774044556962025, -1.419787586206897 ], [ 29.77377506329114, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6478, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.419787586206897 ], [ 29.775392025316457, -1.420596034482759 ], [ 29.775661518987341, -1.420596034482759 ], [ 29.775661518987341, -1.419787586206897 ], [ 29.775392025316457, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6479, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.419787586206897 ], [ 29.776739493670885, -1.420326551724138 ], [ 29.777008987341773, -1.420326551724138 ], [ 29.777008987341773, -1.419787586206897 ], [ 29.776739493670885, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6480, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.419787586206897 ], [ 29.777547974683543, -1.420326551724138 ], [ 29.777817468354431, -1.420326551724138 ], [ 29.777817468354431, -1.419787586206897 ], [ 29.777547974683543, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6481, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.419787586206897 ], [ 29.778086962025316, -1.420326551724138 ], [ 29.778356455696201, -1.420326551724138 ], [ 29.778356455696201, -1.419787586206897 ], [ 29.778086962025316, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6482, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.419787586206897 ], [ 29.778895443037975, -1.420326551724138 ], [ 29.77916493670886, -1.420326551724138 ], [ 29.77916493670886, -1.419787586206897 ], [ 29.778895443037975, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6483, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.419787586206897 ], [ 29.779703924050633, -1.420326551724138 ], [ 29.779973417721518, -1.420326551724138 ], [ 29.779973417721518, -1.419787586206897 ], [ 29.779703924050633, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6484, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.419787586206897 ], [ 29.780512405063291, -1.420326551724138 ], [ 29.780781898734176, -1.420326551724138 ], [ 29.780781898734176, -1.419787586206897 ], [ 29.780512405063291, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6485, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.419787586206897 ], [ 29.782937848101266, -1.420865517241379 ], [ 29.783207341772151, -1.420865517241379 ], [ 29.783207341772151, -1.419787586206897 ], [ 29.782937848101266, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6486, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.419787586206897 ], [ 29.783207341772151, -1.421135 ], [ 29.783476835443039, -1.421135 ], [ 29.783476835443039, -1.419787586206897 ], [ 29.783207341772151, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6487, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.419787586206897 ], [ 29.783476835443039, -1.421135 ], [ 29.784015822784809, -1.421135 ], [ 29.784015822784809, -1.419787586206897 ], [ 29.783476835443039, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6488, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.419787586206897 ], [ 29.800993924050633, -1.420326551724138 ], [ 29.801263417721518, -1.420326551724138 ], [ 29.801263417721518, -1.419787586206897 ], [ 29.800993924050633, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6489, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.419787586206897 ], [ 29.801532911392407, -1.420326551724138 ], [ 29.802071898734177, -1.420326551724138 ], [ 29.802071898734177, -1.419787586206897 ], [ 29.801532911392407, -1.419787586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6490, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.420057068965517 ], [ 29.764342784810125, -1.421404482758621 ], [ 29.764612278481014, -1.421404482758621 ], [ 29.764612278481014, -1.420057068965517 ], [ 29.764342784810125, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6491, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.420057068965517 ], [ 29.764612278481014, -1.420326551724138 ], [ 29.764881772151899, -1.420326551724138 ], [ 29.764881772151899, -1.420057068965517 ], [ 29.764612278481014, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6492, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.420057068965517 ], [ 29.764881772151899, -1.420326551724138 ], [ 29.765151265822784, -1.420326551724138 ], [ 29.765151265822784, -1.420057068965517 ], [ 29.764881772151899, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6493, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.420057068965517 ], [ 29.765151265822784, -1.420326551724138 ], [ 29.765420759493672, -1.420326551724138 ], [ 29.765420759493672, -1.420057068965517 ], [ 29.765151265822784, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6494, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.420057068965517 ], [ 29.765420759493672, -1.420326551724138 ], [ 29.765959746835442, -1.420326551724138 ], [ 29.765959746835442, -1.420057068965517 ], [ 29.765420759493672, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6495, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.420057068965517 ], [ 29.765959746835442, -1.420326551724138 ], [ 29.766229240506327, -1.420326551724138 ], [ 29.766229240506327, -1.420057068965517 ], [ 29.765959746835442, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6496, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.420057068965517 ], [ 29.766229240506327, -1.420326551724138 ], [ 29.766498734177215, -1.420326551724138 ], [ 29.766498734177215, -1.420057068965517 ], [ 29.766229240506327, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6497, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.420057068965517 ], [ 29.766498734177215, -1.420326551724138 ], [ 29.7667682278481, -1.420326551724138 ], [ 29.7667682278481, -1.420057068965517 ], [ 29.766498734177215, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6498, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.420057068965517 ], [ 29.7667682278481, -1.420326551724138 ], [ 29.767037721518985, -1.420326551724138 ], [ 29.767037721518985, -1.420057068965517 ], [ 29.7667682278481, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6499, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.420057068965517 ], [ 29.767037721518985, -1.420326551724138 ], [ 29.767307215189874, -1.420326551724138 ], [ 29.767307215189874, -1.420057068965517 ], [ 29.767037721518985, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6500, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.420057068965517 ], [ 29.767307215189874, -1.420326551724138 ], [ 29.767576708860759, -1.420326551724138 ], [ 29.767576708860759, -1.420057068965517 ], [ 29.767307215189874, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6501, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.420057068965517 ], [ 29.767576708860759, -1.420326551724138 ], [ 29.767846202531643, -1.420326551724138 ], [ 29.767846202531643, -1.420057068965517 ], [ 29.767576708860759, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6502, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.420057068965517 ], [ 29.767846202531643, -1.420326551724138 ], [ 29.768115696202532, -1.420326551724138 ], [ 29.768115696202532, -1.420057068965517 ], [ 29.767846202531643, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6503, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.420057068965517 ], [ 29.768115696202532, -1.420326551724138 ], [ 29.768385189873417, -1.420326551724138 ], [ 29.768385189873417, -1.420057068965517 ], [ 29.768115696202532, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6504, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.420057068965517 ], [ 29.768385189873417, -1.420326551724138 ], [ 29.768654683544302, -1.420326551724138 ], [ 29.768654683544302, -1.420057068965517 ], [ 29.768385189873417, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6505, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.420057068965517 ], [ 29.768654683544302, -1.420326551724138 ], [ 29.76892417721519, -1.420326551724138 ], [ 29.76892417721519, -1.420057068965517 ], [ 29.768654683544302, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6506, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.420057068965517 ], [ 29.76892417721519, -1.420326551724138 ], [ 29.769193670886075, -1.420326551724138 ], [ 29.769193670886075, -1.420057068965517 ], [ 29.76892417721519, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6507, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.420057068965517 ], [ 29.769193670886075, -1.420326551724138 ], [ 29.76946316455696, -1.420326551724138 ], [ 29.76946316455696, -1.420057068965517 ], [ 29.769193670886075, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6508, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.420057068965517 ], [ 29.76946316455696, -1.420326551724138 ], [ 29.769732658227849, -1.420326551724138 ], [ 29.769732658227849, -1.420057068965517 ], [ 29.76946316455696, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6509, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.420057068965517 ], [ 29.769732658227849, -1.420326551724138 ], [ 29.770002151898733, -1.420326551724138 ], [ 29.770002151898733, -1.420057068965517 ], [ 29.769732658227849, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6510, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.420057068965517 ], [ 29.770002151898733, -1.420326551724138 ], [ 29.770271645569618, -1.420326551724138 ], [ 29.770271645569618, -1.420057068965517 ], [ 29.770002151898733, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6511, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.420057068965517 ], [ 29.770271645569618, -1.420326551724138 ], [ 29.770541139240507, -1.420326551724138 ], [ 29.770541139240507, -1.420057068965517 ], [ 29.770271645569618, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6512, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.420057068965517 ], [ 29.770541139240507, -1.420326551724138 ], [ 29.770810632911392, -1.420326551724138 ], [ 29.770810632911392, -1.420057068965517 ], [ 29.770541139240507, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6513, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.420057068965517 ], [ 29.770810632911392, -1.420326551724138 ], [ 29.771080126582277, -1.420326551724138 ], [ 29.771080126582277, -1.420057068965517 ], [ 29.770810632911392, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6514, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.420057068965517 ], [ 29.771080126582277, -1.420326551724138 ], [ 29.771349620253165, -1.420326551724138 ], [ 29.771349620253165, -1.420057068965517 ], [ 29.771080126582277, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6515, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.420057068965517 ], [ 29.771349620253165, -1.420326551724138 ], [ 29.77161911392405, -1.420326551724138 ], [ 29.77161911392405, -1.420057068965517 ], [ 29.771349620253165, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6516, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.420057068965517 ], [ 29.77161911392405, -1.420326551724138 ], [ 29.771888607594935, -1.420326551724138 ], [ 29.771888607594935, -1.420057068965517 ], [ 29.77161911392405, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6517, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.420057068965517 ], [ 29.771888607594935, -1.420326551724138 ], [ 29.772158101265823, -1.420326551724138 ], [ 29.772158101265823, -1.420057068965517 ], [ 29.771888607594935, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6518, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.420057068965517 ], [ 29.772158101265823, -1.420326551724138 ], [ 29.772427594936708, -1.420326551724138 ], [ 29.772427594936708, -1.420057068965517 ], [ 29.772158101265823, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6519, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.420057068965517 ], [ 29.773236075949367, -1.420865517241379 ], [ 29.773505569620252, -1.420865517241379 ], [ 29.773505569620252, -1.420057068965517 ], [ 29.773236075949367, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6520, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.420057068965517 ], [ 29.774044556962025, -1.420865517241379 ], [ 29.77431405063291, -1.420865517241379 ], [ 29.77431405063291, -1.420057068965517 ], [ 29.774044556962025, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6521, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.420057068965517 ], [ 29.774853037974683, -1.420596034482759 ], [ 29.775122531645568, -1.420596034482759 ], [ 29.775122531645568, -1.420057068965517 ], [ 29.774853037974683, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6522, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.420057068965517 ], [ 29.775661518987341, -1.420596034482759 ], [ 29.775931012658226, -1.420596034482759 ], [ 29.775931012658226, -1.420057068965517 ], [ 29.775661518987341, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6523, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.420057068965517 ], [ 29.776200506329115, -1.420596034482759 ], [ 29.77647, -1.420596034482759 ], [ 29.77647, -1.420057068965517 ], [ 29.776200506329115, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6524, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.420057068965517 ], [ 29.77647, -1.420596034482759 ], [ 29.776739493670885, -1.420596034482759 ], [ 29.776739493670885, -1.420057068965517 ], [ 29.77647, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6525, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.420057068965517 ], [ 29.777008987341773, -1.420596034482759 ], [ 29.777278481012658, -1.420596034482759 ], [ 29.777278481012658, -1.420057068965517 ], [ 29.777008987341773, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6526, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.420057068965517 ], [ 29.777817468354431, -1.420596034482759 ], [ 29.778086962025316, -1.420596034482759 ], [ 29.778086962025316, -1.420057068965517 ], [ 29.777817468354431, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6527, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.420057068965517 ], [ 29.778356455696201, -1.420326551724138 ], [ 29.77862594936709, -1.420326551724138 ], [ 29.77862594936709, -1.420057068965517 ], [ 29.778356455696201, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6528, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.420057068965517 ], [ 29.77862594936709, -1.420596034482759 ], [ 29.778895443037975, -1.420596034482759 ], [ 29.778895443037975, -1.420057068965517 ], [ 29.77862594936709, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6529, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.420057068965517 ], [ 29.77916493670886, -1.420326551724138 ], [ 29.779434430379748, -1.420326551724138 ], [ 29.779434430379748, -1.420057068965517 ], [ 29.77916493670886, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6530, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.420057068965517 ], [ 29.779434430379748, -1.420326551724138 ], [ 29.779703924050633, -1.420326551724138 ], [ 29.779703924050633, -1.420057068965517 ], [ 29.779434430379748, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6531, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.420057068965517 ], [ 29.779973417721518, -1.420326551724138 ], [ 29.780242911392406, -1.420326551724138 ], [ 29.780242911392406, -1.420057068965517 ], [ 29.779973417721518, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6532, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.420057068965517 ], [ 29.780242911392406, -1.420326551724138 ], [ 29.780512405063291, -1.420326551724138 ], [ 29.780512405063291, -1.420057068965517 ], [ 29.780242911392406, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6533, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.420057068965517 ], [ 29.780781898734176, -1.420326551724138 ], [ 29.781051392405065, -1.420326551724138 ], [ 29.781051392405065, -1.420057068965517 ], [ 29.780781898734176, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6534, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.420057068965517 ], [ 29.781051392405065, -1.420326551724138 ], [ 29.78132088607595, -1.420326551724138 ], [ 29.78132088607595, -1.420057068965517 ], [ 29.781051392405065, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6535, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.420057068965517 ], [ 29.78132088607595, -1.420326551724138 ], [ 29.781590379746834, -1.420326551724138 ], [ 29.781590379746834, -1.420057068965517 ], [ 29.78132088607595, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6536, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.420057068965517 ], [ 29.781590379746834, -1.420865517241379 ], [ 29.781859873417723, -1.420865517241379 ], [ 29.781859873417723, -1.420057068965517 ], [ 29.781590379746834, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6537, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.420057068965517 ], [ 29.781859873417723, -1.420326551724138 ], [ 29.782129367088608, -1.420326551724138 ], [ 29.782129367088608, -1.420057068965517 ], [ 29.781859873417723, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6538, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.420057068965517 ], [ 29.782129367088608, -1.420326551724138 ], [ 29.782398860759493, -1.420326551724138 ], [ 29.782398860759493, -1.420057068965517 ], [ 29.782129367088608, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6539, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.420057068965517 ], [ 29.782398860759493, -1.421135 ], [ 29.782668354430381, -1.421135 ], [ 29.782668354430381, -1.420057068965517 ], [ 29.782398860759493, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6540, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.420057068965517 ], [ 29.782668354430381, -1.421135 ], [ 29.782937848101266, -1.421135 ], [ 29.782937848101266, -1.420057068965517 ], [ 29.782668354430381, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6541, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.420057068965517 ], [ 29.799107468354432, -1.420326551724138 ], [ 29.799646455696202, -1.420326551724138 ], [ 29.799646455696202, -1.420057068965517 ], [ 29.799107468354432, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6542, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.420057068965517 ], [ 29.799646455696202, -1.420326551724138 ], [ 29.79991594936709, -1.420326551724138 ], [ 29.79991594936709, -1.420057068965517 ], [ 29.799646455696202, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6543, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.420057068965517 ], [ 29.79991594936709, -1.420326551724138 ], [ 29.800185443037975, -1.420326551724138 ], [ 29.800185443037975, -1.420057068965517 ], [ 29.79991594936709, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6544, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.420057068965517 ], [ 29.800185443037975, -1.420326551724138 ], [ 29.80045493670886, -1.420326551724138 ], [ 29.80045493670886, -1.420057068965517 ], [ 29.800185443037975, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6545, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.420057068965517 ], [ 29.80045493670886, -1.420596034482759 ], [ 29.800724430379748, -1.420596034482759 ], [ 29.800724430379748, -1.420057068965517 ], [ 29.80045493670886, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6546, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.420057068965517 ], [ 29.800724430379748, -1.420596034482759 ], [ 29.800993924050633, -1.420596034482759 ], [ 29.800993924050633, -1.420057068965517 ], [ 29.800724430379748, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6547, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.420057068965517 ], [ 29.801263417721518, -1.420326551724138 ], [ 29.801532911392407, -1.420326551724138 ], [ 29.801532911392407, -1.420057068965517 ], [ 29.801263417721518, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6548, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.420057068965517 ], [ 29.802071898734177, -1.420326551724138 ], [ 29.802341392405065, -1.420326551724138 ], [ 29.802341392405065, -1.420057068965517 ], [ 29.802071898734177, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6549, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.420057068965517 ], [ 29.802341392405065, -1.420326551724138 ], [ 29.80261088607595, -1.420326551724138 ], [ 29.80261088607595, -1.420057068965517 ], [ 29.802341392405065, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6550, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.420057068965517 ], [ 29.80261088607595, -1.420326551724138 ], [ 29.802880379746835, -1.420326551724138 ], [ 29.802880379746835, -1.420057068965517 ], [ 29.80261088607595, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6551, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.420057068965517 ], [ 29.802880379746835, -1.420326551724138 ], [ 29.803149873417723, -1.420326551724138 ], [ 29.803149873417723, -1.420057068965517 ], [ 29.802880379746835, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6552, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.420057068965517 ], [ 29.803149873417723, -1.420326551724138 ], [ 29.803419367088608, -1.420326551724138 ], [ 29.803419367088608, -1.420057068965517 ], [ 29.803149873417723, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6553, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.420057068965517 ], [ 29.803419367088608, -1.420326551724138 ], [ 29.803688860759493, -1.420326551724138 ], [ 29.803688860759493, -1.420057068965517 ], [ 29.803419367088608, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6554, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.420057068965517 ], [ 29.803688860759493, -1.420326551724138 ], [ 29.803958354430382, -1.420326551724138 ], [ 29.803958354430382, -1.420057068965517 ], [ 29.803688860759493, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6555, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.420057068965517 ], [ 29.803958354430382, -1.420326551724138 ], [ 29.804227848101267, -1.420326551724138 ], [ 29.804227848101267, -1.420057068965517 ], [ 29.803958354430382, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6556, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.420057068965517 ], [ 29.804227848101267, -1.420596034482759 ], [ 29.804497341772151, -1.420596034482759 ], [ 29.804497341772151, -1.420057068965517 ], [ 29.804227848101267, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6557, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.420326551724138 ], [ 29.764612278481014, -1.421135 ], [ 29.764881772151899, -1.421135 ], [ 29.764881772151899, -1.420326551724138 ], [ 29.764612278481014, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6558, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.420326551724138 ], [ 29.764881772151899, -1.420865517241379 ], [ 29.765151265822784, -1.420865517241379 ], [ 29.765151265822784, -1.420326551724138 ], [ 29.764881772151899, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6559, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.420326551724138 ], [ 29.765151265822784, -1.420596034482759 ], [ 29.765420759493672, -1.420596034482759 ], [ 29.765420759493672, -1.420326551724138 ], [ 29.765151265822784, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6560, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.420326551724138 ], [ 29.765420759493672, -1.420596034482759 ], [ 29.765959746835442, -1.420596034482759 ], [ 29.765959746835442, -1.420326551724138 ], [ 29.765420759493672, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6561, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.420326551724138 ], [ 29.765959746835442, -1.420596034482759 ], [ 29.766229240506327, -1.420596034482759 ], [ 29.766229240506327, -1.420326551724138 ], [ 29.765959746835442, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6562, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.420326551724138 ], [ 29.766229240506327, -1.420596034482759 ], [ 29.766498734177215, -1.420596034482759 ], [ 29.766498734177215, -1.420326551724138 ], [ 29.766229240506327, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6563, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.420326551724138 ], [ 29.766498734177215, -1.420596034482759 ], [ 29.7667682278481, -1.420596034482759 ], [ 29.7667682278481, -1.420326551724138 ], [ 29.766498734177215, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6564, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.420326551724138 ], [ 29.7667682278481, -1.420596034482759 ], [ 29.767037721518985, -1.420596034482759 ], [ 29.767037721518985, -1.420326551724138 ], [ 29.7667682278481, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6565, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.420326551724138 ], [ 29.767037721518985, -1.420596034482759 ], [ 29.767307215189874, -1.420596034482759 ], [ 29.767307215189874, -1.420326551724138 ], [ 29.767037721518985, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6566, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.420326551724138 ], [ 29.767307215189874, -1.420596034482759 ], [ 29.767576708860759, -1.420596034482759 ], [ 29.767576708860759, -1.420326551724138 ], [ 29.767307215189874, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6567, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.420326551724138 ], [ 29.767576708860759, -1.420596034482759 ], [ 29.767846202531643, -1.420596034482759 ], [ 29.767846202531643, -1.420326551724138 ], [ 29.767576708860759, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6568, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.420326551724138 ], [ 29.767846202531643, -1.420596034482759 ], [ 29.768115696202532, -1.420596034482759 ], [ 29.768115696202532, -1.420326551724138 ], [ 29.767846202531643, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6569, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.420326551724138 ], [ 29.768115696202532, -1.420596034482759 ], [ 29.768385189873417, -1.420596034482759 ], [ 29.768385189873417, -1.420326551724138 ], [ 29.768115696202532, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6570, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.420326551724138 ], [ 29.768385189873417, -1.420596034482759 ], [ 29.768654683544302, -1.420596034482759 ], [ 29.768654683544302, -1.420326551724138 ], [ 29.768385189873417, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6571, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.420326551724138 ], [ 29.768654683544302, -1.420596034482759 ], [ 29.76892417721519, -1.420596034482759 ], [ 29.76892417721519, -1.420326551724138 ], [ 29.768654683544302, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6572, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.420326551724138 ], [ 29.76892417721519, -1.420596034482759 ], [ 29.769193670886075, -1.420596034482759 ], [ 29.769193670886075, -1.420326551724138 ], [ 29.76892417721519, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6573, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.420326551724138 ], [ 29.769193670886075, -1.420596034482759 ], [ 29.76946316455696, -1.420596034482759 ], [ 29.76946316455696, -1.420326551724138 ], [ 29.769193670886075, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6574, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.420326551724138 ], [ 29.76946316455696, -1.420596034482759 ], [ 29.769732658227849, -1.420596034482759 ], [ 29.769732658227849, -1.420326551724138 ], [ 29.76946316455696, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6575, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.420326551724138 ], [ 29.769732658227849, -1.420596034482759 ], [ 29.770002151898733, -1.420596034482759 ], [ 29.770002151898733, -1.420326551724138 ], [ 29.769732658227849, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6576, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.420326551724138 ], [ 29.770002151898733, -1.420596034482759 ], [ 29.770271645569618, -1.420596034482759 ], [ 29.770271645569618, -1.420326551724138 ], [ 29.770002151898733, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6577, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.420326551724138 ], [ 29.770271645569618, -1.420596034482759 ], [ 29.770541139240507, -1.420596034482759 ], [ 29.770541139240507, -1.420326551724138 ], [ 29.770271645569618, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6578, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.420326551724138 ], [ 29.770541139240507, -1.420596034482759 ], [ 29.770810632911392, -1.420596034482759 ], [ 29.770810632911392, -1.420326551724138 ], [ 29.770541139240507, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6579, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.420326551724138 ], [ 29.770810632911392, -1.420596034482759 ], [ 29.771080126582277, -1.420596034482759 ], [ 29.771080126582277, -1.420326551724138 ], [ 29.770810632911392, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6580, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.420326551724138 ], [ 29.771080126582277, -1.420596034482759 ], [ 29.771349620253165, -1.420596034482759 ], [ 29.771349620253165, -1.420326551724138 ], [ 29.771080126582277, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6581, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.420326551724138 ], [ 29.771349620253165, -1.420596034482759 ], [ 29.77161911392405, -1.420596034482759 ], [ 29.77161911392405, -1.420326551724138 ], [ 29.771349620253165, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6582, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.420326551724138 ], [ 29.77161911392405, -1.420596034482759 ], [ 29.771888607594935, -1.420596034482759 ], [ 29.771888607594935, -1.420326551724138 ], [ 29.77161911392405, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6583, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.420326551724138 ], [ 29.771888607594935, -1.420596034482759 ], [ 29.772158101265823, -1.420596034482759 ], [ 29.772158101265823, -1.420326551724138 ], [ 29.771888607594935, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6584, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.420057068965517 ], [ 29.772697088607593, -1.420057068965517 ], [ 29.772697088607593, -1.420596034482759 ], [ 29.772158101265823, -1.420596034482759 ], [ 29.772158101265823, -1.420326551724138 ], [ 29.772427594936708, -1.420326551724138 ], [ 29.772427594936708, -1.420057068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6585, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.420326551724138 ], [ 29.772697088607593, -1.420865517241379 ], [ 29.772966582278482, -1.420865517241379 ], [ 29.772966582278482, -1.420326551724138 ], [ 29.772697088607593, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6586, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.420326551724138 ], [ 29.773505569620252, -1.420865517241379 ], [ 29.77377506329114, -1.420865517241379 ], [ 29.77377506329114, -1.420326551724138 ], [ 29.773505569620252, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6587, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.420326551724138 ], [ 29.77431405063291, -1.420865517241379 ], [ 29.774853037974683, -1.420865517241379 ], [ 29.774853037974683, -1.420326551724138 ], [ 29.77431405063291, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6588, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.420326551724138 ], [ 29.775122531645568, -1.420865517241379 ], [ 29.775392025316457, -1.420865517241379 ], [ 29.775392025316457, -1.420326551724138 ], [ 29.775122531645568, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6589, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.420326551724138 ], [ 29.775931012658226, -1.420865517241379 ], [ 29.776200506329115, -1.420865517241379 ], [ 29.776200506329115, -1.420326551724138 ], [ 29.775931012658226, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6590, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.420326551724138 ], [ 29.776739493670885, -1.420865517241379 ], [ 29.777008987341773, -1.420865517241379 ], [ 29.777008987341773, -1.420326551724138 ], [ 29.776739493670885, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6591, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.420326551724138 ], [ 29.777278481012658, -1.420596034482759 ], [ 29.777547974683543, -1.420596034482759 ], [ 29.777547974683543, -1.420326551724138 ], [ 29.777278481012658, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6592, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.420326551724138 ], [ 29.777547974683543, -1.420865517241379 ], [ 29.777817468354431, -1.420865517241379 ], [ 29.777817468354431, -1.420326551724138 ], [ 29.777547974683543, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6593, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.420326551724138 ], [ 29.778086962025316, -1.420596034482759 ], [ 29.778356455696201, -1.420596034482759 ], [ 29.778356455696201, -1.420326551724138 ], [ 29.778086962025316, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6594, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.420326551724138 ], [ 29.778356455696201, -1.420596034482759 ], [ 29.77862594936709, -1.420596034482759 ], [ 29.77862594936709, -1.420326551724138 ], [ 29.778356455696201, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6595, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.420326551724138 ], [ 29.778895443037975, -1.420596034482759 ], [ 29.77916493670886, -1.420596034482759 ], [ 29.77916493670886, -1.420326551724138 ], [ 29.778895443037975, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6596, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.420326551724138 ], [ 29.77916493670886, -1.420596034482759 ], [ 29.779434430379748, -1.420596034482759 ], [ 29.779434430379748, -1.420326551724138 ], [ 29.77916493670886, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6597, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.420326551724138 ], [ 29.779434430379748, -1.420865517241379 ], [ 29.779703924050633, -1.420865517241379 ], [ 29.779703924050633, -1.420326551724138 ], [ 29.779434430379748, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6598, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.420326551724138 ], [ 29.779703924050633, -1.420596034482759 ], [ 29.779973417721518, -1.420596034482759 ], [ 29.779973417721518, -1.420326551724138 ], [ 29.779703924050633, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6599, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.420326551724138 ], [ 29.779973417721518, -1.420596034482759 ], [ 29.780242911392406, -1.420596034482759 ], [ 29.780242911392406, -1.420326551724138 ], [ 29.779973417721518, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6600, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.420326551724138 ], [ 29.780242911392406, -1.421135 ], [ 29.780512405063291, -1.421135 ], [ 29.780512405063291, -1.420326551724138 ], [ 29.780242911392406, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6601, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.420326551724138 ], [ 29.780512405063291, -1.421404482758621 ], [ 29.780781898734176, -1.421404482758621 ], [ 29.780781898734176, -1.420326551724138 ], [ 29.780512405063291, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6602, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.420326551724138 ], [ 29.780781898734176, -1.420596034482759 ], [ 29.781051392405065, -1.420596034482759 ], [ 29.781051392405065, -1.420326551724138 ], [ 29.780781898734176, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6603, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.420326551724138 ], [ 29.781051392405065, -1.421135 ], [ 29.78132088607595, -1.421135 ], [ 29.78132088607595, -1.420326551724138 ], [ 29.781051392405065, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6604, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.420326551724138 ], [ 29.78132088607595, -1.421404482758621 ], [ 29.781590379746834, -1.421404482758621 ], [ 29.781590379746834, -1.420326551724138 ], [ 29.78132088607595, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6605, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.420326551724138 ], [ 29.781859873417723, -1.421135 ], [ 29.782129367088608, -1.421135 ], [ 29.782129367088608, -1.420326551724138 ], [ 29.781859873417723, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6606, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.420326551724138 ], [ 29.782129367088608, -1.421404482758621 ], [ 29.782398860759493, -1.421404482758621 ], [ 29.782398860759493, -1.420326551724138 ], [ 29.782129367088608, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6607, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.420326551724138 ], [ 29.799646455696202, -1.421135 ], [ 29.79991594936709, -1.421135 ], [ 29.79991594936709, -1.420326551724138 ], [ 29.799646455696202, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6608, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.420326551724138 ], [ 29.79991594936709, -1.420865517241379 ], [ 29.800185443037975, -1.420865517241379 ], [ 29.800185443037975, -1.420326551724138 ], [ 29.79991594936709, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6609, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.420326551724138 ], [ 29.800185443037975, -1.420865517241379 ], [ 29.80045493670886, -1.420865517241379 ], [ 29.80045493670886, -1.420326551724138 ], [ 29.800185443037975, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6610, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.420326551724138 ], [ 29.800993924050633, -1.420596034482759 ], [ 29.801263417721518, -1.420596034482759 ], [ 29.801263417721518, -1.420326551724138 ], [ 29.800993924050633, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6611, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.420326551724138 ], [ 29.801263417721518, -1.420596034482759 ], [ 29.801532911392407, -1.420596034482759 ], [ 29.801532911392407, -1.420326551724138 ], [ 29.801263417721518, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6612, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.420326551724138 ], [ 29.801532911392407, -1.420596034482759 ], [ 29.802071898734177, -1.420596034482759 ], [ 29.802071898734177, -1.420326551724138 ], [ 29.801532911392407, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6613, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.420326551724138 ], [ 29.802071898734177, -1.420596034482759 ], [ 29.802341392405065, -1.420596034482759 ], [ 29.802341392405065, -1.420326551724138 ], [ 29.802071898734177, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6614, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.420326551724138 ], [ 29.802341392405065, -1.420596034482759 ], [ 29.80261088607595, -1.420596034482759 ], [ 29.80261088607595, -1.420326551724138 ], [ 29.802341392405065, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6615, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.420326551724138 ], [ 29.80261088607595, -1.420596034482759 ], [ 29.802880379746835, -1.420596034482759 ], [ 29.802880379746835, -1.420326551724138 ], [ 29.80261088607595, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6616, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.420326551724138 ], [ 29.802880379746835, -1.420596034482759 ], [ 29.803149873417723, -1.420596034482759 ], [ 29.803149873417723, -1.420326551724138 ], [ 29.802880379746835, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6617, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.420326551724138 ], [ 29.803149873417723, -1.420596034482759 ], [ 29.803419367088608, -1.420596034482759 ], [ 29.803419367088608, -1.420326551724138 ], [ 29.803149873417723, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6618, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.420326551724138 ], [ 29.803419367088608, -1.420596034482759 ], [ 29.803688860759493, -1.420596034482759 ], [ 29.803688860759493, -1.420326551724138 ], [ 29.803419367088608, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6619, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.420326551724138 ], [ 29.803688860759493, -1.420596034482759 ], [ 29.803958354430382, -1.420596034482759 ], [ 29.803958354430382, -1.420326551724138 ], [ 29.803688860759493, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6620, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.420326551724138 ], [ 29.803958354430382, -1.420596034482759 ], [ 29.804227848101267, -1.420596034482759 ], [ 29.804227848101267, -1.420326551724138 ], [ 29.803958354430382, -1.420326551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6621, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.420596034482759 ], [ 29.757874936708859, -1.421943448275862 ], [ 29.758144430379748, -1.421943448275862 ], [ 29.758144430379748, -1.420596034482759 ], [ 29.757874936708859, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6622, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.420596034482759 ], [ 29.758144430379748, -1.420865517241379 ], [ 29.758413924050632, -1.420865517241379 ], [ 29.758413924050632, -1.420596034482759 ], [ 29.758144430379748, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6623, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.420596034482759 ], [ 29.758413924050632, -1.420865517241379 ], [ 29.758683417721517, -1.420865517241379 ], [ 29.758683417721517, -1.420596034482759 ], [ 29.758413924050632, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6624, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.420596034482759 ], [ 29.758683417721517, -1.420865517241379 ], [ 29.758952911392406, -1.420865517241379 ], [ 29.758952911392406, -1.420596034482759 ], [ 29.758683417721517, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6625, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.420596034482759 ], [ 29.759491898734176, -1.421135 ], [ 29.759761392405064, -1.421135 ], [ 29.759761392405064, -1.420596034482759 ], [ 29.759491898734176, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6626, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.420596034482759 ], [ 29.760839367088607, -1.421135 ], [ 29.761108860759492, -1.421135 ], [ 29.761108860759492, -1.420596034482759 ], [ 29.760839367088607, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6627, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.420596034482759 ], [ 29.765151265822784, -1.421404482758621 ], [ 29.765420759493672, -1.421404482758621 ], [ 29.765420759493672, -1.420596034482759 ], [ 29.765151265822784, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6628, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.420596034482759 ], [ 29.765420759493672, -1.420865517241379 ], [ 29.765959746835442, -1.420865517241379 ], [ 29.765959746835442, -1.420596034482759 ], [ 29.765420759493672, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6629, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.420596034482759 ], [ 29.765959746835442, -1.420865517241379 ], [ 29.766229240506327, -1.420865517241379 ], [ 29.766229240506327, -1.420596034482759 ], [ 29.765959746835442, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6630, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.420596034482759 ], [ 29.766229240506327, -1.420865517241379 ], [ 29.766498734177215, -1.420865517241379 ], [ 29.766498734177215, -1.420596034482759 ], [ 29.766229240506327, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6631, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.420596034482759 ], [ 29.766498734177215, -1.420865517241379 ], [ 29.7667682278481, -1.420865517241379 ], [ 29.7667682278481, -1.420596034482759 ], [ 29.766498734177215, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6632, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.420596034482759 ], [ 29.7667682278481, -1.420865517241379 ], [ 29.767037721518985, -1.420865517241379 ], [ 29.767037721518985, -1.420596034482759 ], [ 29.7667682278481, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6633, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.420596034482759 ], [ 29.767037721518985, -1.420865517241379 ], [ 29.767307215189874, -1.420865517241379 ], [ 29.767307215189874, -1.420596034482759 ], [ 29.767037721518985, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6634, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.420596034482759 ], [ 29.767307215189874, -1.420865517241379 ], [ 29.767576708860759, -1.420865517241379 ], [ 29.767576708860759, -1.420596034482759 ], [ 29.767307215189874, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6635, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.420596034482759 ], [ 29.767576708860759, -1.420865517241379 ], [ 29.767846202531643, -1.420865517241379 ], [ 29.767846202531643, -1.420596034482759 ], [ 29.767576708860759, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6636, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.420596034482759 ], [ 29.767846202531643, -1.420865517241379 ], [ 29.768115696202532, -1.420865517241379 ], [ 29.768115696202532, -1.420596034482759 ], [ 29.767846202531643, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6637, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.420596034482759 ], [ 29.768115696202532, -1.420865517241379 ], [ 29.768385189873417, -1.420865517241379 ], [ 29.768385189873417, -1.420596034482759 ], [ 29.768115696202532, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6638, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.420596034482759 ], [ 29.768385189873417, -1.420865517241379 ], [ 29.768654683544302, -1.420865517241379 ], [ 29.768654683544302, -1.420596034482759 ], [ 29.768385189873417, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6639, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.420596034482759 ], [ 29.768654683544302, -1.420865517241379 ], [ 29.76892417721519, -1.420865517241379 ], [ 29.76892417721519, -1.420596034482759 ], [ 29.768654683544302, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6640, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.420596034482759 ], [ 29.76892417721519, -1.420865517241379 ], [ 29.769193670886075, -1.420865517241379 ], [ 29.769193670886075, -1.420596034482759 ], [ 29.76892417721519, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6641, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.420596034482759 ], [ 29.769193670886075, -1.420865517241379 ], [ 29.76946316455696, -1.420865517241379 ], [ 29.76946316455696, -1.420596034482759 ], [ 29.769193670886075, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6642, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.420596034482759 ], [ 29.76946316455696, -1.420865517241379 ], [ 29.769732658227849, -1.420865517241379 ], [ 29.769732658227849, -1.420596034482759 ], [ 29.76946316455696, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6643, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.420596034482759 ], [ 29.769732658227849, -1.420865517241379 ], [ 29.770002151898733, -1.420865517241379 ], [ 29.770002151898733, -1.420596034482759 ], [ 29.769732658227849, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6644, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.420596034482759 ], [ 29.770002151898733, -1.420865517241379 ], [ 29.770271645569618, -1.420865517241379 ], [ 29.770271645569618, -1.420596034482759 ], [ 29.770002151898733, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6645, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.420596034482759 ], [ 29.770271645569618, -1.420865517241379 ], [ 29.770541139240507, -1.420865517241379 ], [ 29.770541139240507, -1.420596034482759 ], [ 29.770271645569618, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6646, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.420596034482759 ], [ 29.770541139240507, -1.420865517241379 ], [ 29.770810632911392, -1.420865517241379 ], [ 29.770810632911392, -1.420596034482759 ], [ 29.770541139240507, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6647, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.420596034482759 ], [ 29.770810632911392, -1.420865517241379 ], [ 29.771080126582277, -1.420865517241379 ], [ 29.771080126582277, -1.420596034482759 ], [ 29.770810632911392, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6648, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.420596034482759 ], [ 29.771080126582277, -1.420865517241379 ], [ 29.771349620253165, -1.420865517241379 ], [ 29.771349620253165, -1.420596034482759 ], [ 29.771080126582277, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6649, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.420596034482759 ], [ 29.771349620253165, -1.420865517241379 ], [ 29.77161911392405, -1.420865517241379 ], [ 29.77161911392405, -1.420596034482759 ], [ 29.771349620253165, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6650, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.420596034482759 ], [ 29.77161911392405, -1.420865517241379 ], [ 29.771888607594935, -1.420865517241379 ], [ 29.771888607594935, -1.420596034482759 ], [ 29.77161911392405, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6651, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.420596034482759 ], [ 29.771888607594935, -1.420865517241379 ], [ 29.772158101265823, -1.420865517241379 ], [ 29.772158101265823, -1.420596034482759 ], [ 29.771888607594935, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6652, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.420596034482759 ], [ 29.772158101265823, -1.420865517241379 ], [ 29.772427594936708, -1.420865517241379 ], [ 29.772427594936708, -1.420596034482759 ], [ 29.772158101265823, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6653, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.420596034482759 ], [ 29.772427594936708, -1.421135 ], [ 29.772697088607593, -1.421135 ], [ 29.772697088607593, -1.420596034482759 ], [ 29.772427594936708, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6654, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.420596034482759 ], [ 29.772966582278482, -1.421135 ], [ 29.773236075949367, -1.421135 ], [ 29.773236075949367, -1.420596034482759 ], [ 29.772966582278482, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6655, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.420596034482759 ], [ 29.77377506329114, -1.421135 ], [ 29.774044556962025, -1.421135 ], [ 29.774044556962025, -1.420596034482759 ], [ 29.77377506329114, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6656, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.420596034482759 ], [ 29.774853037974683, -1.421135 ], [ 29.775122531645568, -1.421135 ], [ 29.775122531645568, -1.420596034482759 ], [ 29.774853037974683, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6657, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.420596034482759 ], [ 29.775392025316457, -1.420865517241379 ], [ 29.775661518987341, -1.420865517241379 ], [ 29.775661518987341, -1.420596034482759 ], [ 29.775392025316457, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6658, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.420596034482759 ], [ 29.775661518987341, -1.421135 ], [ 29.775931012658226, -1.421135 ], [ 29.775931012658226, -1.420596034482759 ], [ 29.775661518987341, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6659, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.420596034482759 ], [ 29.776200506329115, -1.420865517241379 ], [ 29.77647, -1.420865517241379 ], [ 29.77647, -1.420596034482759 ], [ 29.776200506329115, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6660, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.420596034482759 ], [ 29.77647, -1.421135 ], [ 29.776739493670885, -1.421135 ], [ 29.776739493670885, -1.420596034482759 ], [ 29.77647, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6661, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.420596034482759 ], [ 29.777008987341773, -1.420865517241379 ], [ 29.777278481012658, -1.420865517241379 ], [ 29.777278481012658, -1.420596034482759 ], [ 29.777008987341773, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6662, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.420596034482759 ], [ 29.777278481012658, -1.420865517241379 ], [ 29.777547974683543, -1.420865517241379 ], [ 29.777547974683543, -1.420596034482759 ], [ 29.777278481012658, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6663, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.420596034482759 ], [ 29.777817468354431, -1.420865517241379 ], [ 29.778086962025316, -1.420865517241379 ], [ 29.778086962025316, -1.420596034482759 ], [ 29.777817468354431, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6664, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.420596034482759 ], [ 29.778086962025316, -1.420865517241379 ], [ 29.778356455696201, -1.420865517241379 ], [ 29.778356455696201, -1.420596034482759 ], [ 29.778086962025316, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6665, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.420596034482759 ], [ 29.778356455696201, -1.421404482758621 ], [ 29.77862594936709, -1.421404482758621 ], [ 29.77862594936709, -1.420596034482759 ], [ 29.778356455696201, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6666, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.420596034482759 ], [ 29.77862594936709, -1.420865517241379 ], [ 29.778895443037975, -1.420865517241379 ], [ 29.778895443037975, -1.420596034482759 ], [ 29.77862594936709, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6667, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.420596034482759 ], [ 29.778895443037975, -1.421135 ], [ 29.77916493670886, -1.421135 ], [ 29.77916493670886, -1.420596034482759 ], [ 29.778895443037975, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6668, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.420596034482759 ], [ 29.77916493670886, -1.421404482758621 ], [ 29.779434430379748, -1.421404482758621 ], [ 29.779434430379748, -1.420596034482759 ], [ 29.77916493670886, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6669, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.420596034482759 ], [ 29.779703924050633, -1.421135 ], [ 29.779973417721518, -1.421135 ], [ 29.779973417721518, -1.420596034482759 ], [ 29.779703924050633, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6670, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.420596034482759 ], [ 29.779973417721518, -1.421404482758621 ], [ 29.780242911392406, -1.421404482758621 ], [ 29.780242911392406, -1.420596034482759 ], [ 29.779973417721518, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6671, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.420596034482759 ], [ 29.780781898734176, -1.421404482758621 ], [ 29.781051392405065, -1.421404482758621 ], [ 29.781051392405065, -1.420596034482759 ], [ 29.780781898734176, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6672, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.420596034482759 ], [ 29.80045493670886, -1.420865517241379 ], [ 29.800724430379748, -1.420865517241379 ], [ 29.800724430379748, -1.420596034482759 ], [ 29.80045493670886, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6673, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.420596034482759 ], [ 29.800724430379748, -1.420865517241379 ], [ 29.800993924050633, -1.420865517241379 ], [ 29.800993924050633, -1.420596034482759 ], [ 29.800724430379748, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6674, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.420596034482759 ], [ 29.800993924050633, -1.420865517241379 ], [ 29.801263417721518, -1.420865517241379 ], [ 29.801263417721518, -1.420596034482759 ], [ 29.800993924050633, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6675, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.420596034482759 ], [ 29.801263417721518, -1.421135 ], [ 29.801532911392407, -1.421135 ], [ 29.801532911392407, -1.420596034482759 ], [ 29.801263417721518, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6676, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.420596034482759 ], [ 29.801532911392407, -1.420865517241379 ], [ 29.802071898734177, -1.420865517241379 ], [ 29.802071898734177, -1.420596034482759 ], [ 29.801532911392407, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6677, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.420596034482759 ], [ 29.802071898734177, -1.420865517241379 ], [ 29.802341392405065, -1.420865517241379 ], [ 29.802341392405065, -1.420596034482759 ], [ 29.802071898734177, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6678, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.420596034482759 ], [ 29.802341392405065, -1.420865517241379 ], [ 29.80261088607595, -1.420865517241379 ], [ 29.80261088607595, -1.420596034482759 ], [ 29.802341392405065, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6679, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.420596034482759 ], [ 29.80261088607595, -1.420865517241379 ], [ 29.802880379746835, -1.420865517241379 ], [ 29.802880379746835, -1.420596034482759 ], [ 29.80261088607595, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6680, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.420596034482759 ], [ 29.802880379746835, -1.420865517241379 ], [ 29.803149873417723, -1.420865517241379 ], [ 29.803149873417723, -1.420596034482759 ], [ 29.802880379746835, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6681, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.420596034482759 ], [ 29.803149873417723, -1.420865517241379 ], [ 29.803419367088608, -1.420865517241379 ], [ 29.803419367088608, -1.420596034482759 ], [ 29.803149873417723, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6682, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.420596034482759 ], [ 29.803419367088608, -1.420865517241379 ], [ 29.803688860759493, -1.420865517241379 ], [ 29.803688860759493, -1.420596034482759 ], [ 29.803419367088608, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6683, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.420596034482759 ], [ 29.803688860759493, -1.420865517241379 ], [ 29.803958354430382, -1.420865517241379 ], [ 29.803958354430382, -1.420596034482759 ], [ 29.803688860759493, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6684, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.420596034482759 ], [ 29.803958354430382, -1.420865517241379 ], [ 29.804227848101267, -1.420865517241379 ], [ 29.804227848101267, -1.420596034482759 ], [ 29.803958354430382, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6685, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.420596034482759 ], [ 29.804227848101267, -1.420865517241379 ], [ 29.804497341772151, -1.420865517241379 ], [ 29.804497341772151, -1.420596034482759 ], [ 29.804227848101267, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6686, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.420596034482759 ], [ 29.804497341772151, -1.421135 ], [ 29.80476683544304, -1.421135 ], [ 29.80476683544304, -1.420596034482759 ], [ 29.804497341772151, -1.420596034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6687, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.420865517241379 ], [ 29.758144430379748, -1.421943448275862 ], [ 29.758413924050632, -1.421943448275862 ], [ 29.758413924050632, -1.420865517241379 ], [ 29.758144430379748, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6688, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.420865517241379 ], [ 29.758413924050632, -1.421943448275862 ], [ 29.758683417721517, -1.421943448275862 ], [ 29.758683417721517, -1.420865517241379 ], [ 29.758413924050632, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6689, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.420865517241379 ], [ 29.758683417721517, -1.421135 ], [ 29.758952911392406, -1.421135 ], [ 29.758952911392406, -1.420865517241379 ], [ 29.758683417721517, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6690, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.420865517241379 ], [ 29.758952911392406, -1.421135 ], [ 29.759222405063291, -1.421135 ], [ 29.759222405063291, -1.420865517241379 ], [ 29.758952911392406, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6691, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.420865517241379 ], [ 29.759222405063291, -1.421135 ], [ 29.759491898734176, -1.421135 ], [ 29.759491898734176, -1.420865517241379 ], [ 29.759222405063291, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6692, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.420865517241379 ], [ 29.759761392405064, -1.421135 ], [ 29.760030886075949, -1.421135 ], [ 29.760030886075949, -1.420865517241379 ], [ 29.759761392405064, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6693, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.420865517241379 ], [ 29.760030886075949, -1.421135 ], [ 29.760300379746834, -1.421135 ], [ 29.760300379746834, -1.420865517241379 ], [ 29.760030886075949, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6694, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.420865517241379 ], [ 29.760569873417722, -1.421404482758621 ], [ 29.760839367088607, -1.421404482758621 ], [ 29.760839367088607, -1.420865517241379 ], [ 29.760569873417722, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6695, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.420865517241379 ], [ 29.761378354430381, -1.421404482758621 ], [ 29.761647848101266, -1.421404482758621 ], [ 29.761647848101266, -1.420865517241379 ], [ 29.761378354430381, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6696, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.420865517241379 ], [ 29.762186835443039, -1.421404482758621 ], [ 29.762456329113924, -1.421404482758621 ], [ 29.762456329113924, -1.420865517241379 ], [ 29.762186835443039, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6697, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.420865517241379 ], [ 29.762725822784809, -1.421404482758621 ], [ 29.762995316455697, -1.421404482758621 ], [ 29.762995316455697, -1.420865517241379 ], [ 29.762725822784809, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6698, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.420865517241379 ], [ 29.763534303797467, -1.421404482758621 ], [ 29.763803797468356, -1.421404482758621 ], [ 29.763803797468356, -1.420865517241379 ], [ 29.763534303797467, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6699, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.420865517241379 ], [ 29.764881772151899, -1.421673965517241 ], [ 29.765151265822784, -1.421673965517241 ], [ 29.765151265822784, -1.420865517241379 ], [ 29.764881772151899, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6700, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.420865517241379 ], [ 29.765420759493672, -1.421135 ], [ 29.765959746835442, -1.421135 ], [ 29.765959746835442, -1.420865517241379 ], [ 29.765420759493672, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6701, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.420865517241379 ], [ 29.765959746835442, -1.421404482758621 ], [ 29.766229240506327, -1.421404482758621 ], [ 29.766229240506327, -1.420865517241379 ], [ 29.765959746835442, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6702, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.420865517241379 ], [ 29.766229240506327, -1.421135 ], [ 29.766498734177215, -1.421135 ], [ 29.766498734177215, -1.420865517241379 ], [ 29.766229240506327, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6703, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.420865517241379 ], [ 29.766498734177215, -1.421135 ], [ 29.7667682278481, -1.421135 ], [ 29.7667682278481, -1.420865517241379 ], [ 29.766498734177215, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6704, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.420865517241379 ], [ 29.7667682278481, -1.421135 ], [ 29.767037721518985, -1.421135 ], [ 29.767037721518985, -1.420865517241379 ], [ 29.7667682278481, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6705, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.420865517241379 ], [ 29.767037721518985, -1.421135 ], [ 29.767307215189874, -1.421135 ], [ 29.767307215189874, -1.420865517241379 ], [ 29.767037721518985, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6706, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.420865517241379 ], [ 29.767307215189874, -1.421135 ], [ 29.767576708860759, -1.421135 ], [ 29.767576708860759, -1.420865517241379 ], [ 29.767307215189874, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6707, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.420865517241379 ], [ 29.767576708860759, -1.421135 ], [ 29.767846202531643, -1.421135 ], [ 29.767846202531643, -1.420865517241379 ], [ 29.767576708860759, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6708, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.420865517241379 ], [ 29.767846202531643, -1.421135 ], [ 29.768115696202532, -1.421135 ], [ 29.768115696202532, -1.420865517241379 ], [ 29.767846202531643, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6709, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.420865517241379 ], [ 29.768115696202532, -1.421135 ], [ 29.768385189873417, -1.421135 ], [ 29.768385189873417, -1.420865517241379 ], [ 29.768115696202532, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6710, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.420865517241379 ], [ 29.768385189873417, -1.421135 ], [ 29.768654683544302, -1.421135 ], [ 29.768654683544302, -1.420865517241379 ], [ 29.768385189873417, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6711, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.420865517241379 ], [ 29.768654683544302, -1.421135 ], [ 29.76892417721519, -1.421135 ], [ 29.76892417721519, -1.420865517241379 ], [ 29.768654683544302, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6712, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.420865517241379 ], [ 29.76892417721519, -1.421135 ], [ 29.769193670886075, -1.421135 ], [ 29.769193670886075, -1.420865517241379 ], [ 29.76892417721519, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6713, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.420865517241379 ], [ 29.769193670886075, -1.421135 ], [ 29.76946316455696, -1.421135 ], [ 29.76946316455696, -1.420865517241379 ], [ 29.769193670886075, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6714, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.420865517241379 ], [ 29.76946316455696, -1.421135 ], [ 29.769732658227849, -1.421135 ], [ 29.769732658227849, -1.420865517241379 ], [ 29.76946316455696, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6715, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.420865517241379 ], [ 29.769732658227849, -1.421135 ], [ 29.770002151898733, -1.421135 ], [ 29.770002151898733, -1.420865517241379 ], [ 29.769732658227849, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6716, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.420865517241379 ], [ 29.770002151898733, -1.421135 ], [ 29.770271645569618, -1.421135 ], [ 29.770271645569618, -1.420865517241379 ], [ 29.770002151898733, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6717, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.420865517241379 ], [ 29.770271645569618, -1.421135 ], [ 29.770541139240507, -1.421135 ], [ 29.770541139240507, -1.420865517241379 ], [ 29.770271645569618, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6718, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.420865517241379 ], [ 29.770541139240507, -1.421135 ], [ 29.770810632911392, -1.421135 ], [ 29.770810632911392, -1.420865517241379 ], [ 29.770541139240507, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6719, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.420865517241379 ], [ 29.770810632911392, -1.421135 ], [ 29.771080126582277, -1.421135 ], [ 29.771080126582277, -1.420865517241379 ], [ 29.770810632911392, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6720, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.420865517241379 ], [ 29.771080126582277, -1.421135 ], [ 29.771349620253165, -1.421135 ], [ 29.771349620253165, -1.420865517241379 ], [ 29.771080126582277, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6721, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.420865517241379 ], [ 29.771349620253165, -1.421135 ], [ 29.77161911392405, -1.421135 ], [ 29.77161911392405, -1.420865517241379 ], [ 29.771349620253165, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6722, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.420865517241379 ], [ 29.77161911392405, -1.421135 ], [ 29.771888607594935, -1.421135 ], [ 29.771888607594935, -1.420865517241379 ], [ 29.77161911392405, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6723, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.420865517241379 ], [ 29.771888607594935, -1.421135 ], [ 29.772158101265823, -1.421135 ], [ 29.772158101265823, -1.420865517241379 ], [ 29.771888607594935, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6724, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.420865517241379 ], [ 29.772158101265823, -1.421135 ], [ 29.772427594936708, -1.421135 ], [ 29.772427594936708, -1.420865517241379 ], [ 29.772158101265823, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6725, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.420865517241379 ], [ 29.772697088607593, -1.421404482758621 ], [ 29.772966582278482, -1.421404482758621 ], [ 29.772966582278482, -1.420865517241379 ], [ 29.772697088607593, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6726, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.420865517241379 ], [ 29.773236075949367, -1.421135 ], [ 29.773505569620252, -1.421135 ], [ 29.773505569620252, -1.420865517241379 ], [ 29.773236075949367, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6727, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.420865517241379 ], [ 29.773505569620252, -1.421404482758621 ], [ 29.77377506329114, -1.421404482758621 ], [ 29.77377506329114, -1.420865517241379 ], [ 29.773505569620252, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6728, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.420865517241379 ], [ 29.774044556962025, -1.421135 ], [ 29.77431405063291, -1.421135 ], [ 29.77431405063291, -1.420865517241379 ], [ 29.774044556962025, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6729, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.420865517241379 ], [ 29.77431405063291, -1.421404482758621 ], [ 29.774853037974683, -1.421404482758621 ], [ 29.774853037974683, -1.420865517241379 ], [ 29.77431405063291, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6730, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.420865517241379 ], [ 29.775122531645568, -1.421135 ], [ 29.775392025316457, -1.421135 ], [ 29.775392025316457, -1.420865517241379 ], [ 29.775122531645568, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6731, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.420865517241379 ], [ 29.775392025316457, -1.421404482758621 ], [ 29.775661518987341, -1.421404482758621 ], [ 29.775661518987341, -1.420865517241379 ], [ 29.775392025316457, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6732, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.420865517241379 ], [ 29.775931012658226, -1.421135 ], [ 29.776200506329115, -1.421135 ], [ 29.776200506329115, -1.420865517241379 ], [ 29.775931012658226, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6733, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.420865517241379 ], [ 29.776200506329115, -1.421135 ], [ 29.77647, -1.421135 ], [ 29.77647, -1.420865517241379 ], [ 29.776200506329115, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6734, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.420865517241379 ], [ 29.776739493670885, -1.421135 ], [ 29.777008987341773, -1.421135 ], [ 29.777008987341773, -1.420865517241379 ], [ 29.776739493670885, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6735, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.420865517241379 ], [ 29.777008987341773, -1.421404482758621 ], [ 29.777278481012658, -1.421404482758621 ], [ 29.777278481012658, -1.420865517241379 ], [ 29.777008987341773, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6736, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.420865517241379 ], [ 29.777278481012658, -1.421673965517241 ], [ 29.777547974683543, -1.421673965517241 ], [ 29.777547974683543, -1.420865517241379 ], [ 29.777278481012658, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6737, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.420865517241379 ], [ 29.777547974683543, -1.421135 ], [ 29.777817468354431, -1.421135 ], [ 29.777817468354431, -1.420865517241379 ], [ 29.777547974683543, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6738, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.420865517241379 ], [ 29.777817468354431, -1.421404482758621 ], [ 29.778086962025316, -1.421404482758621 ], [ 29.778086962025316, -1.420865517241379 ], [ 29.777817468354431, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6739, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.420865517241379 ], [ 29.778086962025316, -1.421673965517241 ], [ 29.778356455696201, -1.421673965517241 ], [ 29.778356455696201, -1.420865517241379 ], [ 29.778086962025316, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6740, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.420865517241379 ], [ 29.77862594936709, -1.421673965517241 ], [ 29.778895443037975, -1.421673965517241 ], [ 29.778895443037975, -1.420865517241379 ], [ 29.77862594936709, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6741, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.420865517241379 ], [ 29.779434430379748, -1.421673965517241 ], [ 29.779703924050633, -1.421673965517241 ], [ 29.779703924050633, -1.420865517241379 ], [ 29.779434430379748, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6742, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.420865517241379 ], [ 29.781590379746834, -1.421404482758621 ], [ 29.781859873417723, -1.421404482758621 ], [ 29.781859873417723, -1.420865517241379 ], [ 29.781590379746834, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6743, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.420865517241379 ], [ 29.782937848101266, -1.421404482758621 ], [ 29.783207341772151, -1.421404482758621 ], [ 29.783207341772151, -1.420865517241379 ], [ 29.782937848101266, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6744, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.420865517241379 ], [ 29.784015822784809, -1.421135 ], [ 29.784285316455698, -1.421135 ], [ 29.784285316455698, -1.420865517241379 ], [ 29.784015822784809, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6745, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.420865517241379 ], [ 29.784285316455698, -1.421135 ], [ 29.784554810126583, -1.421135 ], [ 29.784554810126583, -1.420865517241379 ], [ 29.784285316455698, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6746, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.420865517241379 ], [ 29.784554810126583, -1.421135 ], [ 29.784824303797468, -1.421135 ], [ 29.784824303797468, -1.420865517241379 ], [ 29.784554810126583, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6747, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.420865517241379 ], [ 29.784824303797468, -1.421135 ], [ 29.785093797468356, -1.421135 ], [ 29.785093797468356, -1.420865517241379 ], [ 29.784824303797468, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6748, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.420865517241379 ], [ 29.79991594936709, -1.421135 ], [ 29.800185443037975, -1.421135 ], [ 29.800185443037975, -1.420865517241379 ], [ 29.79991594936709, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6749, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.420865517241379 ], [ 29.800185443037975, -1.421135 ], [ 29.80045493670886, -1.421135 ], [ 29.80045493670886, -1.420865517241379 ], [ 29.800185443037975, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6750, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.420865517241379 ], [ 29.80045493670886, -1.421135 ], [ 29.800724430379748, -1.421135 ], [ 29.800724430379748, -1.420865517241379 ], [ 29.80045493670886, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6751, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.420865517241379 ], [ 29.800724430379748, -1.421673965517241 ], [ 29.800993924050633, -1.421673965517241 ], [ 29.800993924050633, -1.420865517241379 ], [ 29.800724430379748, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6752, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.420865517241379 ], [ 29.800993924050633, -1.421673965517241 ], [ 29.801263417721518, -1.421673965517241 ], [ 29.801263417721518, -1.420865517241379 ], [ 29.800993924050633, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6753, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.420865517241379 ], [ 29.801532911392407, -1.421404482758621 ], [ 29.802071898734177, -1.421404482758621 ], [ 29.802071898734177, -1.420865517241379 ], [ 29.801532911392407, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6754, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.420865517241379 ], [ 29.802071898734177, -1.421404482758621 ], [ 29.802341392405065, -1.421404482758621 ], [ 29.802341392405065, -1.421135 ], [ 29.80261088607595, -1.421135 ], [ 29.80261088607595, -1.420865517241379 ], [ 29.802071898734177, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6755, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.420865517241379 ], [ 29.80261088607595, -1.421135 ], [ 29.802880379746835, -1.421135 ], [ 29.802880379746835, -1.420865517241379 ], [ 29.80261088607595, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6756, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.420865517241379 ], [ 29.802880379746835, -1.421135 ], [ 29.803149873417723, -1.421135 ], [ 29.803149873417723, -1.420865517241379 ], [ 29.802880379746835, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6757, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.420865517241379 ], [ 29.803149873417723, -1.421135 ], [ 29.803419367088608, -1.421135 ], [ 29.803419367088608, -1.420865517241379 ], [ 29.803149873417723, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6758, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.420865517241379 ], [ 29.803419367088608, -1.421135 ], [ 29.803688860759493, -1.421135 ], [ 29.803688860759493, -1.420865517241379 ], [ 29.803419367088608, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6759, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.420865517241379 ], [ 29.803688860759493, -1.421135 ], [ 29.803958354430382, -1.421135 ], [ 29.803958354430382, -1.420865517241379 ], [ 29.803688860759493, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6760, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.420865517241379 ], [ 29.803958354430382, -1.421135 ], [ 29.804227848101267, -1.421135 ], [ 29.804227848101267, -1.420865517241379 ], [ 29.803958354430382, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6761, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.420865517241379 ], [ 29.804227848101267, -1.421135 ], [ 29.804497341772151, -1.421135 ], [ 29.804497341772151, -1.420865517241379 ], [ 29.804227848101267, -1.420865517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6762, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.421135 ], [ 29.758683417721517, -1.422212931034483 ], [ 29.758952911392406, -1.422212931034483 ], [ 29.758952911392406, -1.421135 ], [ 29.758683417721517, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6763, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.421135 ], [ 29.758952911392406, -1.422212931034483 ], [ 29.759222405063291, -1.422212931034483 ], [ 29.759222405063291, -1.421135 ], [ 29.758952911392406, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6764, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.421135 ], [ 29.759222405063291, -1.421943448275862 ], [ 29.759491898734176, -1.421943448275862 ], [ 29.759491898734176, -1.421135 ], [ 29.759222405063291, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6765, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.421135 ], [ 29.759491898734176, -1.421404482758621 ], [ 29.759761392405064, -1.421404482758621 ], [ 29.759761392405064, -1.421135 ], [ 29.759491898734176, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6766, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.421135 ], [ 29.759761392405064, -1.421404482758621 ], [ 29.760030886075949, -1.421404482758621 ], [ 29.760030886075949, -1.421135 ], [ 29.759761392405064, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6767, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.421135 ], [ 29.760030886075949, -1.421404482758621 ], [ 29.760300379746834, -1.421404482758621 ], [ 29.760300379746834, -1.421135 ], [ 29.760030886075949, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6768, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.421135 ], [ 29.760300379746834, -1.421404482758621 ], [ 29.760569873417722, -1.421404482758621 ], [ 29.760569873417722, -1.421135 ], [ 29.760300379746834, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6769, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.421135 ], [ 29.760839367088607, -1.421404482758621 ], [ 29.761108860759492, -1.421404482758621 ], [ 29.761108860759492, -1.421135 ], [ 29.760839367088607, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6770, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.421135 ], [ 29.761108860759492, -1.421404482758621 ], [ 29.761378354430381, -1.421404482758621 ], [ 29.761378354430381, -1.421135 ], [ 29.761108860759492, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6771, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.421135 ], [ 29.761647848101266, -1.421673965517241 ], [ 29.761917341772151, -1.421673965517241 ], [ 29.761917341772151, -1.421135 ], [ 29.761647848101266, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6772, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.421135 ], [ 29.761917341772151, -1.421404482758621 ], [ 29.762186835443039, -1.421404482758621 ], [ 29.762186835443039, -1.421135 ], [ 29.761917341772151, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6773, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.421135 ], [ 29.762456329113924, -1.421673965517241 ], [ 29.762725822784809, -1.421673965517241 ], [ 29.762725822784809, -1.421135 ], [ 29.762456329113924, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6774, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.421135 ], [ 29.763264810126582, -1.421673965517241 ], [ 29.763534303797467, -1.421673965517241 ], [ 29.763534303797467, -1.421135 ], [ 29.763264810126582, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6775, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.421135 ], [ 29.76407329113924, -1.421673965517241 ], [ 29.764342784810125, -1.421673965517241 ], [ 29.764342784810125, -1.421135 ], [ 29.76407329113924, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6776, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.421135 ], [ 29.764612278481014, -1.421673965517241 ], [ 29.764881772151899, -1.421673965517241 ], [ 29.764881772151899, -1.421135 ], [ 29.764612278481014, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6777, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.421135 ], [ 29.765420759493672, -1.421673965517241 ], [ 29.765959746835442, -1.421673965517241 ], [ 29.765959746835442, -1.421135 ], [ 29.765420759493672, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6778, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.421135 ], [ 29.766229240506327, -1.421404482758621 ], [ 29.766498734177215, -1.421404482758621 ], [ 29.766498734177215, -1.421135 ], [ 29.766229240506327, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6779, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.421135 ], [ 29.766498734177215, -1.421404482758621 ], [ 29.7667682278481, -1.421404482758621 ], [ 29.7667682278481, -1.421135 ], [ 29.766498734177215, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6780, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.421135 ], [ 29.7667682278481, -1.421404482758621 ], [ 29.767037721518985, -1.421404482758621 ], [ 29.767037721518985, -1.421135 ], [ 29.7667682278481, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6781, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.421135 ], [ 29.767037721518985, -1.421404482758621 ], [ 29.767307215189874, -1.421404482758621 ], [ 29.767307215189874, -1.421135 ], [ 29.767037721518985, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6782, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.421135 ], [ 29.767307215189874, -1.421404482758621 ], [ 29.767576708860759, -1.421404482758621 ], [ 29.767576708860759, -1.421135 ], [ 29.767307215189874, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6783, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.421135 ], [ 29.767576708860759, -1.421404482758621 ], [ 29.767846202531643, -1.421404482758621 ], [ 29.767846202531643, -1.421135 ], [ 29.767576708860759, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6784, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.421135 ], [ 29.767846202531643, -1.421404482758621 ], [ 29.768115696202532, -1.421404482758621 ], [ 29.768115696202532, -1.421135 ], [ 29.767846202531643, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6785, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.421135 ], [ 29.768115696202532, -1.421404482758621 ], [ 29.768385189873417, -1.421404482758621 ], [ 29.768385189873417, -1.421135 ], [ 29.768115696202532, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6786, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.421135 ], [ 29.768385189873417, -1.421404482758621 ], [ 29.768654683544302, -1.421404482758621 ], [ 29.768654683544302, -1.421135 ], [ 29.768385189873417, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6787, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.421135 ], [ 29.768654683544302, -1.421404482758621 ], [ 29.76892417721519, -1.421404482758621 ], [ 29.76892417721519, -1.421135 ], [ 29.768654683544302, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6788, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.421135 ], [ 29.76892417721519, -1.421404482758621 ], [ 29.769193670886075, -1.421404482758621 ], [ 29.769193670886075, -1.421135 ], [ 29.76892417721519, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6789, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.421135 ], [ 29.769193670886075, -1.421404482758621 ], [ 29.76946316455696, -1.421404482758621 ], [ 29.76946316455696, -1.421135 ], [ 29.769193670886075, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6790, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.421135 ], [ 29.76946316455696, -1.421404482758621 ], [ 29.769732658227849, -1.421404482758621 ], [ 29.769732658227849, -1.421135 ], [ 29.76946316455696, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6791, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.421135 ], [ 29.769732658227849, -1.421404482758621 ], [ 29.770002151898733, -1.421404482758621 ], [ 29.770002151898733, -1.421135 ], [ 29.769732658227849, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6792, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.421135 ], [ 29.770002151898733, -1.421404482758621 ], [ 29.770271645569618, -1.421404482758621 ], [ 29.770271645569618, -1.421135 ], [ 29.770002151898733, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6793, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.421135 ], [ 29.770271645569618, -1.421404482758621 ], [ 29.770541139240507, -1.421404482758621 ], [ 29.770541139240507, -1.421135 ], [ 29.770271645569618, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6794, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.421135 ], [ 29.770541139240507, -1.421404482758621 ], [ 29.770810632911392, -1.421404482758621 ], [ 29.770810632911392, -1.421135 ], [ 29.770541139240507, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6795, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.421135 ], [ 29.770810632911392, -1.421404482758621 ], [ 29.771080126582277, -1.421404482758621 ], [ 29.771080126582277, -1.421135 ], [ 29.770810632911392, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6796, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.421135 ], [ 29.771080126582277, -1.421404482758621 ], [ 29.771349620253165, -1.421404482758621 ], [ 29.771349620253165, -1.421135 ], [ 29.771080126582277, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6797, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.421135 ], [ 29.771349620253165, -1.421404482758621 ], [ 29.77161911392405, -1.421404482758621 ], [ 29.77161911392405, -1.421135 ], [ 29.771349620253165, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6798, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.421135 ], [ 29.77161911392405, -1.421404482758621 ], [ 29.771888607594935, -1.421404482758621 ], [ 29.771888607594935, -1.421135 ], [ 29.77161911392405, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6799, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.421135 ], [ 29.771888607594935, -1.421404482758621 ], [ 29.772158101265823, -1.421404482758621 ], [ 29.772158101265823, -1.421135 ], [ 29.771888607594935, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6800, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.421135 ], [ 29.772158101265823, -1.421404482758621 ], [ 29.772427594936708, -1.421404482758621 ], [ 29.772427594936708, -1.421135 ], [ 29.772158101265823, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6801, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.421135 ], [ 29.772427594936708, -1.421673965517241 ], [ 29.772697088607593, -1.421673965517241 ], [ 29.772697088607593, -1.421135 ], [ 29.772427594936708, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6802, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.421135 ], [ 29.772966582278482, -1.421404482758621 ], [ 29.773236075949367, -1.421404482758621 ], [ 29.773236075949367, -1.421135 ], [ 29.772966582278482, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6803, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.421135 ], [ 29.773236075949367, -1.421673965517241 ], [ 29.773505569620252, -1.421673965517241 ], [ 29.773505569620252, -1.421135 ], [ 29.773236075949367, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6804, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.421135 ], [ 29.77377506329114, -1.421404482758621 ], [ 29.774044556962025, -1.421404482758621 ], [ 29.774044556962025, -1.421135 ], [ 29.77377506329114, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6805, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.421135 ], [ 29.774044556962025, -1.421673965517241 ], [ 29.77431405063291, -1.421673965517241 ], [ 29.77431405063291, -1.421135 ], [ 29.774044556962025, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6806, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.421135 ], [ 29.774853037974683, -1.421404482758621 ], [ 29.775122531645568, -1.421404482758621 ], [ 29.775122531645568, -1.421135 ], [ 29.774853037974683, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6807, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.421135 ], [ 29.775122531645568, -1.421673965517241 ], [ 29.775392025316457, -1.421673965517241 ], [ 29.775392025316457, -1.421135 ], [ 29.775122531645568, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6808, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.421135 ], [ 29.775661518987341, -1.421404482758621 ], [ 29.775931012658226, -1.421404482758621 ], [ 29.775931012658226, -1.421135 ], [ 29.775661518987341, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6809, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.421135 ], [ 29.775931012658226, -1.421673965517241 ], [ 29.776200506329115, -1.421673965517241 ], [ 29.776200506329115, -1.421135 ], [ 29.775931012658226, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6810, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.421135 ], [ 29.776200506329115, -1.421943448275862 ], [ 29.77647, -1.421943448275862 ], [ 29.77647, -1.421135 ], [ 29.776200506329115, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6811, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.421135 ], [ 29.77647, -1.421673965517241 ], [ 29.776739493670885, -1.421673965517241 ], [ 29.776739493670885, -1.421135 ], [ 29.77647, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6812, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.421135 ], [ 29.776739493670885, -1.421673965517241 ], [ 29.777008987341773, -1.421673965517241 ], [ 29.777008987341773, -1.421135 ], [ 29.776739493670885, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6813, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.421135 ], [ 29.777547974683543, -1.421943448275862 ], [ 29.777817468354431, -1.421943448275862 ], [ 29.777817468354431, -1.421135 ], [ 29.777547974683543, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6814, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.421135 ], [ 29.778895443037975, -1.421673965517241 ], [ 29.77916493670886, -1.421673965517241 ], [ 29.77916493670886, -1.421135 ], [ 29.778895443037975, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6815, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.421135 ], [ 29.779703924050633, -1.421673965517241 ], [ 29.779973417721518, -1.421673965517241 ], [ 29.779973417721518, -1.421135 ], [ 29.779703924050633, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6816, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.421135 ], [ 29.780242911392406, -1.421673965517241 ], [ 29.780512405063291, -1.421673965517241 ], [ 29.780512405063291, -1.421135 ], [ 29.780242911392406, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6817, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.421135 ], [ 29.781051392405065, -1.421673965517241 ], [ 29.78132088607595, -1.421673965517241 ], [ 29.78132088607595, -1.421135 ], [ 29.781051392405065, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6818, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.421135 ], [ 29.781859873417723, -1.421673965517241 ], [ 29.782129367088608, -1.421673965517241 ], [ 29.782129367088608, -1.421135 ], [ 29.781859873417723, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6819, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.421135 ], [ 29.782398860759493, -1.421404482758621 ], [ 29.782668354430381, -1.421404482758621 ], [ 29.782668354430381, -1.421135 ], [ 29.782398860759493, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6820, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.421135 ], [ 29.782668354430381, -1.421404482758621 ], [ 29.782937848101266, -1.421404482758621 ], [ 29.782937848101266, -1.421135 ], [ 29.782668354430381, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6821, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.421135 ], [ 29.783207341772151, -1.421404482758621 ], [ 29.783476835443039, -1.421404482758621 ], [ 29.783476835443039, -1.421135 ], [ 29.783207341772151, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6822, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.421135 ], [ 29.783476835443039, -1.421404482758621 ], [ 29.784015822784809, -1.421404482758621 ], [ 29.784015822784809, -1.421135 ], [ 29.783476835443039, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6823, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.421135 ], [ 29.784015822784809, -1.421404482758621 ], [ 29.784285316455698, -1.421404482758621 ], [ 29.784285316455698, -1.421135 ], [ 29.784015822784809, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6824, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.421135 ], [ 29.784285316455698, -1.421404482758621 ], [ 29.784554810126583, -1.421404482758621 ], [ 29.784554810126583, -1.421135 ], [ 29.784285316455698, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6825, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.421135 ], [ 29.784554810126583, -1.421404482758621 ], [ 29.784824303797468, -1.421404482758621 ], [ 29.784824303797468, -1.421135 ], [ 29.784554810126583, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6826, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.421135 ], [ 29.784824303797468, -1.421404482758621 ], [ 29.785093797468356, -1.421404482758621 ], [ 29.785093797468356, -1.421135 ], [ 29.784824303797468, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6827, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.421135 ], [ 29.785093797468356, -1.421673965517241 ], [ 29.785363291139241, -1.421673965517241 ], [ 29.785363291139241, -1.421135 ], [ 29.785093797468356, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6828, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.421135 ], [ 29.79991594936709, -1.422212931034483 ], [ 29.800185443037975, -1.422212931034483 ], [ 29.800185443037975, -1.421135 ], [ 29.79991594936709, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6829, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.421135 ], [ 29.800185443037975, -1.421943448275862 ], [ 29.80045493670886, -1.421943448275862 ], [ 29.80045493670886, -1.421135 ], [ 29.800185443037975, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6830, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.421135 ], [ 29.80045493670886, -1.421943448275862 ], [ 29.800724430379748, -1.421943448275862 ], [ 29.800724430379748, -1.421135 ], [ 29.80045493670886, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6831, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.421135 ], [ 29.801263417721518, -1.421673965517241 ], [ 29.801532911392407, -1.421673965517241 ], [ 29.801532911392407, -1.421135 ], [ 29.801263417721518, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6832, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.421135 ], [ 29.802341392405065, -1.421673965517241 ], [ 29.80261088607595, -1.421673965517241 ], [ 29.80261088607595, -1.421135 ], [ 29.802341392405065, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6833, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.421135 ], [ 29.80261088607595, -1.421404482758621 ], [ 29.802880379746835, -1.421404482758621 ], [ 29.802880379746835, -1.421135 ], [ 29.80261088607595, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6834, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.421135 ], [ 29.802880379746835, -1.421404482758621 ], [ 29.803149873417723, -1.421404482758621 ], [ 29.803149873417723, -1.421135 ], [ 29.802880379746835, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6835, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.421135 ], [ 29.803149873417723, -1.421404482758621 ], [ 29.803419367088608, -1.421404482758621 ], [ 29.803419367088608, -1.421135 ], [ 29.803149873417723, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6836, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.421135 ], [ 29.803419367088608, -1.421404482758621 ], [ 29.803688860759493, -1.421404482758621 ], [ 29.803688860759493, -1.421135 ], [ 29.803419367088608, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6837, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.421135 ], [ 29.803688860759493, -1.421404482758621 ], [ 29.803958354430382, -1.421404482758621 ], [ 29.803958354430382, -1.421135 ], [ 29.803688860759493, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6838, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.421135 ], [ 29.803958354430382, -1.421404482758621 ], [ 29.804227848101267, -1.421404482758621 ], [ 29.804227848101267, -1.421135 ], [ 29.803958354430382, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6839, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.421135 ], [ 29.804227848101267, -1.421404482758621 ], [ 29.804497341772151, -1.421404482758621 ], [ 29.804497341772151, -1.421135 ], [ 29.804227848101267, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6840, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.421135 ], [ 29.804497341772151, -1.421404482758621 ], [ 29.80476683544304, -1.421404482758621 ], [ 29.80476683544304, -1.421135 ], [ 29.804497341772151, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6841, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.421135 ], [ 29.80476683544304, -1.421673965517241 ], [ 29.805036329113925, -1.421673965517241 ], [ 29.805036329113925, -1.421135 ], [ 29.80476683544304, -1.421135 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6842, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.421404482758621 ], [ 29.759491898734176, -1.422212931034483 ], [ 29.759761392405064, -1.422212931034483 ], [ 29.759761392405064, -1.421404482758621 ], [ 29.759491898734176, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6843, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.421404482758621 ], [ 29.759761392405064, -1.422212931034483 ], [ 29.760030886075949, -1.422212931034483 ], [ 29.760030886075949, -1.421404482758621 ], [ 29.759761392405064, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6844, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.421404482758621 ], [ 29.760030886075949, -1.421673965517241 ], [ 29.760300379746834, -1.421673965517241 ], [ 29.760300379746834, -1.421404482758621 ], [ 29.760030886075949, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6845, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.421404482758621 ], [ 29.760300379746834, -1.421673965517241 ], [ 29.760569873417722, -1.421673965517241 ], [ 29.760569873417722, -1.421404482758621 ], [ 29.760300379746834, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6846, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.421404482758621 ], [ 29.760569873417722, -1.421943448275862 ], [ 29.760839367088607, -1.421943448275862 ], [ 29.760839367088607, -1.421404482758621 ], [ 29.760569873417722, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6847, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.421404482758621 ], [ 29.760839367088607, -1.421673965517241 ], [ 29.761108860759492, -1.421673965517241 ], [ 29.761108860759492, -1.421404482758621 ], [ 29.760839367088607, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6848, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.421404482758621 ], [ 29.761108860759492, -1.421673965517241 ], [ 29.761378354430381, -1.421673965517241 ], [ 29.761378354430381, -1.421404482758621 ], [ 29.761108860759492, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6849, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.421404482758621 ], [ 29.761378354430381, -1.421673965517241 ], [ 29.761647848101266, -1.421673965517241 ], [ 29.761647848101266, -1.421404482758621 ], [ 29.761378354430381, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6850, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.421404482758621 ], [ 29.761917341772151, -1.421673965517241 ], [ 29.762186835443039, -1.421673965517241 ], [ 29.762186835443039, -1.421404482758621 ], [ 29.761917341772151, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6851, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.421404482758621 ], [ 29.762186835443039, -1.421673965517241 ], [ 29.762456329113924, -1.421673965517241 ], [ 29.762456329113924, -1.421404482758621 ], [ 29.762186835443039, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6852, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.421404482758621 ], [ 29.762725822784809, -1.421673965517241 ], [ 29.762995316455697, -1.421673965517241 ], [ 29.762995316455697, -1.421404482758621 ], [ 29.762725822784809, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6853, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.421404482758621 ], [ 29.762995316455697, -1.421673965517241 ], [ 29.763264810126582, -1.421673965517241 ], [ 29.763264810126582, -1.421404482758621 ], [ 29.762995316455697, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6854, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.421404482758621 ], [ 29.763534303797467, -1.421943448275862 ], [ 29.763803797468356, -1.421943448275862 ], [ 29.763803797468356, -1.421404482758621 ], [ 29.763534303797467, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6855, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.421404482758621 ], [ 29.763803797468356, -1.421673965517241 ], [ 29.76407329113924, -1.421673965517241 ], [ 29.76407329113924, -1.421404482758621 ], [ 29.763803797468356, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6856, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.421404482758621 ], [ 29.764342784810125, -1.421943448275862 ], [ 29.764612278481014, -1.421943448275862 ], [ 29.764612278481014, -1.421404482758621 ], [ 29.764342784810125, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6857, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.421404482758621 ], [ 29.765151265822784, -1.421943448275862 ], [ 29.765420759493672, -1.421943448275862 ], [ 29.765420759493672, -1.421404482758621 ], [ 29.765151265822784, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6858, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.421404482758621 ], [ 29.765959746835442, -1.421943448275862 ], [ 29.766229240506327, -1.421943448275862 ], [ 29.766229240506327, -1.421404482758621 ], [ 29.765959746835442, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6859, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.421404482758621 ], [ 29.766229240506327, -1.421943448275862 ], [ 29.766498734177215, -1.421943448275862 ], [ 29.766498734177215, -1.421404482758621 ], [ 29.766229240506327, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6860, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.421404482758621 ], [ 29.766498734177215, -1.421673965517241 ], [ 29.7667682278481, -1.421673965517241 ], [ 29.7667682278481, -1.421404482758621 ], [ 29.766498734177215, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6861, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.421404482758621 ], [ 29.7667682278481, -1.421943448275862 ], [ 29.767037721518985, -1.421943448275862 ], [ 29.767037721518985, -1.421404482758621 ], [ 29.7667682278481, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6862, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.421404482758621 ], [ 29.767037721518985, -1.421673965517241 ], [ 29.767307215189874, -1.421673965517241 ], [ 29.767307215189874, -1.421404482758621 ], [ 29.767037721518985, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6863, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.421404482758621 ], [ 29.767307215189874, -1.421673965517241 ], [ 29.767576708860759, -1.421673965517241 ], [ 29.767576708860759, -1.421404482758621 ], [ 29.767307215189874, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6864, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.421404482758621 ], [ 29.767576708860759, -1.421673965517241 ], [ 29.767846202531643, -1.421673965517241 ], [ 29.767846202531643, -1.421404482758621 ], [ 29.767576708860759, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6865, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.421404482758621 ], [ 29.767846202531643, -1.421673965517241 ], [ 29.768115696202532, -1.421673965517241 ], [ 29.768115696202532, -1.421404482758621 ], [ 29.767846202531643, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6866, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.421404482758621 ], [ 29.768115696202532, -1.421673965517241 ], [ 29.768385189873417, -1.421673965517241 ], [ 29.768385189873417, -1.421404482758621 ], [ 29.768115696202532, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6867, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.421404482758621 ], [ 29.768385189873417, -1.421673965517241 ], [ 29.768654683544302, -1.421673965517241 ], [ 29.768654683544302, -1.421404482758621 ], [ 29.768385189873417, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6868, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.421404482758621 ], [ 29.768654683544302, -1.421673965517241 ], [ 29.76892417721519, -1.421673965517241 ], [ 29.76892417721519, -1.421404482758621 ], [ 29.768654683544302, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6869, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.421404482758621 ], [ 29.76892417721519, -1.421673965517241 ], [ 29.769193670886075, -1.421673965517241 ], [ 29.769193670886075, -1.421404482758621 ], [ 29.76892417721519, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6870, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.421404482758621 ], [ 29.769193670886075, -1.421673965517241 ], [ 29.76946316455696, -1.421673965517241 ], [ 29.76946316455696, -1.421404482758621 ], [ 29.769193670886075, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6871, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.421404482758621 ], [ 29.76946316455696, -1.421673965517241 ], [ 29.769732658227849, -1.421673965517241 ], [ 29.769732658227849, -1.421404482758621 ], [ 29.76946316455696, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6872, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.421404482758621 ], [ 29.769732658227849, -1.421673965517241 ], [ 29.770002151898733, -1.421673965517241 ], [ 29.770002151898733, -1.421404482758621 ], [ 29.769732658227849, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6873, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.421404482758621 ], [ 29.770002151898733, -1.421673965517241 ], [ 29.770271645569618, -1.421673965517241 ], [ 29.770271645569618, -1.421404482758621 ], [ 29.770002151898733, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6874, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.421404482758621 ], [ 29.770271645569618, -1.421673965517241 ], [ 29.770541139240507, -1.421673965517241 ], [ 29.770541139240507, -1.421404482758621 ], [ 29.770271645569618, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6875, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.421404482758621 ], [ 29.770541139240507, -1.421673965517241 ], [ 29.770810632911392, -1.421673965517241 ], [ 29.770810632911392, -1.421404482758621 ], [ 29.770541139240507, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6876, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.421404482758621 ], [ 29.770810632911392, -1.421673965517241 ], [ 29.771080126582277, -1.421673965517241 ], [ 29.771080126582277, -1.421404482758621 ], [ 29.770810632911392, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6877, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.421404482758621 ], [ 29.771080126582277, -1.421673965517241 ], [ 29.771349620253165, -1.421673965517241 ], [ 29.771349620253165, -1.421404482758621 ], [ 29.771080126582277, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6878, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.421404482758621 ], [ 29.771349620253165, -1.421673965517241 ], [ 29.77161911392405, -1.421673965517241 ], [ 29.77161911392405, -1.421404482758621 ], [ 29.771349620253165, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6879, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.421404482758621 ], [ 29.77161911392405, -1.421673965517241 ], [ 29.771888607594935, -1.421673965517241 ], [ 29.771888607594935, -1.421404482758621 ], [ 29.77161911392405, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6880, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.421404482758621 ], [ 29.771888607594935, -1.421673965517241 ], [ 29.772158101265823, -1.421673965517241 ], [ 29.772158101265823, -1.421404482758621 ], [ 29.771888607594935, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6881, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.421404482758621 ], [ 29.772158101265823, -1.421943448275862 ], [ 29.772427594936708, -1.421943448275862 ], [ 29.772427594936708, -1.421404482758621 ], [ 29.772158101265823, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6882, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.421404482758621 ], [ 29.772697088607593, -1.421673965517241 ], [ 29.772966582278482, -1.421673965517241 ], [ 29.772966582278482, -1.421404482758621 ], [ 29.772697088607593, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6883, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.421404482758621 ], [ 29.772966582278482, -1.421943448275862 ], [ 29.773236075949367, -1.421943448275862 ], [ 29.773236075949367, -1.421404482758621 ], [ 29.772966582278482, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6884, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.421404482758621 ], [ 29.773505569620252, -1.421673965517241 ], [ 29.77377506329114, -1.421673965517241 ], [ 29.77377506329114, -1.421404482758621 ], [ 29.773505569620252, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6885, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.421404482758621 ], [ 29.77377506329114, -1.421943448275862 ], [ 29.774044556962025, -1.421943448275862 ], [ 29.774044556962025, -1.421404482758621 ], [ 29.77377506329114, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6886, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.421404482758621 ], [ 29.77431405063291, -1.421673965517241 ], [ 29.774853037974683, -1.421673965517241 ], [ 29.774853037974683, -1.421404482758621 ], [ 29.77431405063291, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6887, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.421404482758621 ], [ 29.774853037974683, -1.421943448275862 ], [ 29.775122531645568, -1.421943448275862 ], [ 29.775122531645568, -1.421404482758621 ], [ 29.774853037974683, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6888, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.421404482758621 ], [ 29.775392025316457, -1.421943448275862 ], [ 29.775661518987341, -1.421943448275862 ], [ 29.775661518987341, -1.421404482758621 ], [ 29.775392025316457, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6889, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.421404482758621 ], [ 29.775661518987341, -1.422212931034483 ], [ 29.775931012658226, -1.422212931034483 ], [ 29.775931012658226, -1.421404482758621 ], [ 29.775661518987341, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6890, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.421404482758621 ], [ 29.777008987341773, -1.421943448275862 ], [ 29.777278481012658, -1.421943448275862 ], [ 29.777278481012658, -1.421404482758621 ], [ 29.777008987341773, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6891, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.421404482758621 ], [ 29.777817468354431, -1.421943448275862 ], [ 29.778086962025316, -1.421943448275862 ], [ 29.778086962025316, -1.421404482758621 ], [ 29.777817468354431, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6892, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.421404482758621 ], [ 29.778356455696201, -1.421943448275862 ], [ 29.77862594936709, -1.421943448275862 ], [ 29.77862594936709, -1.421404482758621 ], [ 29.778356455696201, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6893, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.421404482758621 ], [ 29.77916493670886, -1.421943448275862 ], [ 29.779434430379748, -1.421943448275862 ], [ 29.779434430379748, -1.421404482758621 ], [ 29.77916493670886, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6894, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.421404482758621 ], [ 29.779973417721518, -1.421943448275862 ], [ 29.780242911392406, -1.421943448275862 ], [ 29.780242911392406, -1.421404482758621 ], [ 29.779973417721518, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6895, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.421404482758621 ], [ 29.780512405063291, -1.421673965517241 ], [ 29.780781898734176, -1.421673965517241 ], [ 29.780781898734176, -1.421404482758621 ], [ 29.780512405063291, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6896, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.421404482758621 ], [ 29.780781898734176, -1.421943448275862 ], [ 29.781051392405065, -1.421943448275862 ], [ 29.781051392405065, -1.421404482758621 ], [ 29.780781898734176, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6897, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.421404482758621 ], [ 29.78132088607595, -1.421673965517241 ], [ 29.781590379746834, -1.421673965517241 ], [ 29.781590379746834, -1.421404482758621 ], [ 29.78132088607595, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6898, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.421404482758621 ], [ 29.781590379746834, -1.421673965517241 ], [ 29.781859873417723, -1.421673965517241 ], [ 29.781859873417723, -1.421404482758621 ], [ 29.781590379746834, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6899, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.421404482758621 ], [ 29.782129367088608, -1.421673965517241 ], [ 29.782398860759493, -1.421673965517241 ], [ 29.782398860759493, -1.421404482758621 ], [ 29.782129367088608, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6900, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.421404482758621 ], [ 29.782398860759493, -1.421673965517241 ], [ 29.782668354430381, -1.421673965517241 ], [ 29.782668354430381, -1.421404482758621 ], [ 29.782398860759493, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6901, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.421404482758621 ], [ 29.782668354430381, -1.421673965517241 ], [ 29.782937848101266, -1.421673965517241 ], [ 29.782937848101266, -1.421404482758621 ], [ 29.782668354430381, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6902, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.421404482758621 ], [ 29.782937848101266, -1.421673965517241 ], [ 29.783207341772151, -1.421673965517241 ], [ 29.783207341772151, -1.421404482758621 ], [ 29.782937848101266, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6903, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.421404482758621 ], [ 29.783207341772151, -1.421673965517241 ], [ 29.783476835443039, -1.421673965517241 ], [ 29.783476835443039, -1.421404482758621 ], [ 29.783207341772151, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6904, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.421404482758621 ], [ 29.783476835443039, -1.421673965517241 ], [ 29.784015822784809, -1.421673965517241 ], [ 29.784015822784809, -1.421404482758621 ], [ 29.783476835443039, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6905, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.421404482758621 ], [ 29.784015822784809, -1.421673965517241 ], [ 29.784285316455698, -1.421673965517241 ], [ 29.784285316455698, -1.421404482758621 ], [ 29.784015822784809, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6906, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.421404482758621 ], [ 29.784285316455698, -1.421673965517241 ], [ 29.784554810126583, -1.421673965517241 ], [ 29.784554810126583, -1.421404482758621 ], [ 29.784285316455698, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6907, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.421404482758621 ], [ 29.784554810126583, -1.421673965517241 ], [ 29.784824303797468, -1.421673965517241 ], [ 29.784824303797468, -1.421404482758621 ], [ 29.784554810126583, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6908, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.421404482758621 ], [ 29.784824303797468, -1.421673965517241 ], [ 29.785093797468356, -1.421673965517241 ], [ 29.785093797468356, -1.421404482758621 ], [ 29.784824303797468, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6909, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.421404482758621 ], [ 29.801532911392407, -1.421673965517241 ], [ 29.802071898734177, -1.421673965517241 ], [ 29.802071898734177, -1.421404482758621 ], [ 29.801532911392407, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6910, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.421404482758621 ], [ 29.802071898734177, -1.421673965517241 ], [ 29.802341392405065, -1.421673965517241 ], [ 29.802341392405065, -1.421404482758621 ], [ 29.802071898734177, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6911, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.421404482758621 ], [ 29.80261088607595, -1.421673965517241 ], [ 29.802880379746835, -1.421673965517241 ], [ 29.802880379746835, -1.421404482758621 ], [ 29.80261088607595, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6912, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.421404482758621 ], [ 29.802880379746835, -1.421673965517241 ], [ 29.803149873417723, -1.421673965517241 ], [ 29.803149873417723, -1.421404482758621 ], [ 29.802880379746835, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6913, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.421404482758621 ], [ 29.803149873417723, -1.421673965517241 ], [ 29.803419367088608, -1.421673965517241 ], [ 29.803419367088608, -1.421404482758621 ], [ 29.803149873417723, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6914, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.421404482758621 ], [ 29.803419367088608, -1.421673965517241 ], [ 29.803688860759493, -1.421673965517241 ], [ 29.803688860759493, -1.421404482758621 ], [ 29.803419367088608, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6915, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.421404482758621 ], [ 29.803688860759493, -1.421673965517241 ], [ 29.803958354430382, -1.421673965517241 ], [ 29.803958354430382, -1.421404482758621 ], [ 29.803688860759493, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6916, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.421404482758621 ], [ 29.803958354430382, -1.421673965517241 ], [ 29.804227848101267, -1.421673965517241 ], [ 29.804227848101267, -1.421404482758621 ], [ 29.803958354430382, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6917, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.421404482758621 ], [ 29.804227848101267, -1.421673965517241 ], [ 29.804497341772151, -1.421673965517241 ], [ 29.804497341772151, -1.421404482758621 ], [ 29.804227848101267, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6918, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.421404482758621 ], [ 29.804497341772151, -1.421673965517241 ], [ 29.80476683544304, -1.421673965517241 ], [ 29.80476683544304, -1.421404482758621 ], [ 29.804497341772151, -1.421404482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6919, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.421673965517241 ], [ 29.760300379746834, -1.422212931034483 ], [ 29.760569873417722, -1.422212931034483 ], [ 29.760569873417722, -1.421673965517241 ], [ 29.760300379746834, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6920, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.421673965517241 ], [ 29.761108860759492, -1.422212931034483 ], [ 29.761378354430381, -1.422212931034483 ], [ 29.761378354430381, -1.421673965517241 ], [ 29.761108860759492, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6921, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.421673965517241 ], [ 29.761378354430381, -1.421943448275862 ], [ 29.761647848101266, -1.421943448275862 ], [ 29.761647848101266, -1.421673965517241 ], [ 29.761378354430381, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6922, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.421673965517241 ], [ 29.761917341772151, -1.422212931034483 ], [ 29.762186835443039, -1.422212931034483 ], [ 29.762186835443039, -1.421673965517241 ], [ 29.761917341772151, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6923, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.421673965517241 ], [ 29.762186835443039, -1.421943448275862 ], [ 29.762456329113924, -1.421943448275862 ], [ 29.762456329113924, -1.421673965517241 ], [ 29.762186835443039, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6924, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.421673965517241 ], [ 29.762456329113924, -1.421943448275862 ], [ 29.762725822784809, -1.421943448275862 ], [ 29.762725822784809, -1.421673965517241 ], [ 29.762456329113924, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6925, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.421673965517241 ], [ 29.762725822784809, -1.422212931034483 ], [ 29.762995316455697, -1.422212931034483 ], [ 29.762995316455697, -1.421673965517241 ], [ 29.762725822784809, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6926, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.421673965517241 ], [ 29.762995316455697, -1.421943448275862 ], [ 29.763264810126582, -1.421943448275862 ], [ 29.763264810126582, -1.421673965517241 ], [ 29.762995316455697, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6927, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.421673965517241 ], [ 29.763264810126582, -1.421943448275862 ], [ 29.763534303797467, -1.421943448275862 ], [ 29.763534303797467, -1.421673965517241 ], [ 29.763264810126582, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6928, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.421673965517241 ], [ 29.763803797468356, -1.421943448275862 ], [ 29.76407329113924, -1.421943448275862 ], [ 29.76407329113924, -1.421673965517241 ], [ 29.763803797468356, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6929, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.421673965517241 ], [ 29.76407329113924, -1.421943448275862 ], [ 29.764342784810125, -1.421943448275862 ], [ 29.764342784810125, -1.421673965517241 ], [ 29.76407329113924, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6930, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.421673965517241 ], [ 29.764612278481014, -1.422212931034483 ], [ 29.764881772151899, -1.422212931034483 ], [ 29.764881772151899, -1.421673965517241 ], [ 29.764612278481014, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6931, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.421673965517241 ], [ 29.764881772151899, -1.421943448275862 ], [ 29.765151265822784, -1.421943448275862 ], [ 29.765151265822784, -1.421673965517241 ], [ 29.764881772151899, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6932, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.421673965517241 ], [ 29.765420759493672, -1.422212931034483 ], [ 29.765959746835442, -1.422212931034483 ], [ 29.765959746835442, -1.421673965517241 ], [ 29.765420759493672, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6933, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.421673965517241 ], [ 29.766498734177215, -1.422212931034483 ], [ 29.7667682278481, -1.422212931034483 ], [ 29.7667682278481, -1.421673965517241 ], [ 29.766498734177215, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6934, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.421673965517241 ], [ 29.767037721518985, -1.421943448275862 ], [ 29.767307215189874, -1.421943448275862 ], [ 29.767307215189874, -1.421673965517241 ], [ 29.767037721518985, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6935, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.421673965517241 ], [ 29.767307215189874, -1.421943448275862 ], [ 29.767576708860759, -1.421943448275862 ], [ 29.767576708860759, -1.421673965517241 ], [ 29.767307215189874, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6936, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.421673965517241 ], [ 29.767576708860759, -1.421943448275862 ], [ 29.767846202531643, -1.421943448275862 ], [ 29.767846202531643, -1.421673965517241 ], [ 29.767576708860759, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6937, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.421673965517241 ], [ 29.767846202531643, -1.421943448275862 ], [ 29.768115696202532, -1.421943448275862 ], [ 29.768115696202532, -1.421673965517241 ], [ 29.767846202531643, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6938, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.421673965517241 ], [ 29.768115696202532, -1.421943448275862 ], [ 29.768385189873417, -1.421943448275862 ], [ 29.768385189873417, -1.421673965517241 ], [ 29.768115696202532, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6939, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.421673965517241 ], [ 29.768385189873417, -1.421943448275862 ], [ 29.768654683544302, -1.421943448275862 ], [ 29.768654683544302, -1.421673965517241 ], [ 29.768385189873417, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6940, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.421673965517241 ], [ 29.768654683544302, -1.421943448275862 ], [ 29.76892417721519, -1.421943448275862 ], [ 29.76892417721519, -1.421673965517241 ], [ 29.768654683544302, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6941, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.421673965517241 ], [ 29.76892417721519, -1.421943448275862 ], [ 29.769193670886075, -1.421943448275862 ], [ 29.769193670886075, -1.421673965517241 ], [ 29.76892417721519, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6942, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.421673965517241 ], [ 29.769193670886075, -1.421943448275862 ], [ 29.76946316455696, -1.421943448275862 ], [ 29.76946316455696, -1.421673965517241 ], [ 29.769193670886075, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6943, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.421673965517241 ], [ 29.76946316455696, -1.421943448275862 ], [ 29.769732658227849, -1.421943448275862 ], [ 29.769732658227849, -1.421673965517241 ], [ 29.76946316455696, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6944, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.421673965517241 ], [ 29.769732658227849, -1.421943448275862 ], [ 29.770002151898733, -1.421943448275862 ], [ 29.770002151898733, -1.421673965517241 ], [ 29.769732658227849, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6945, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.421673965517241 ], [ 29.770002151898733, -1.421943448275862 ], [ 29.770271645569618, -1.421943448275862 ], [ 29.770271645569618, -1.421673965517241 ], [ 29.770002151898733, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6946, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.421673965517241 ], [ 29.770271645569618, -1.421943448275862 ], [ 29.770541139240507, -1.421943448275862 ], [ 29.770541139240507, -1.421673965517241 ], [ 29.770271645569618, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6947, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.421673965517241 ], [ 29.770541139240507, -1.421943448275862 ], [ 29.770810632911392, -1.421943448275862 ], [ 29.770810632911392, -1.421673965517241 ], [ 29.770541139240507, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6948, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.421673965517241 ], [ 29.770810632911392, -1.421943448275862 ], [ 29.771080126582277, -1.421943448275862 ], [ 29.771080126582277, -1.421673965517241 ], [ 29.770810632911392, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6949, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.421673965517241 ], [ 29.771080126582277, -1.421943448275862 ], [ 29.771349620253165, -1.421943448275862 ], [ 29.771349620253165, -1.421673965517241 ], [ 29.771080126582277, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6950, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.421673965517241 ], [ 29.771349620253165, -1.421943448275862 ], [ 29.77161911392405, -1.421943448275862 ], [ 29.77161911392405, -1.421673965517241 ], [ 29.771349620253165, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6951, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.421673965517241 ], [ 29.77161911392405, -1.421943448275862 ], [ 29.771888607594935, -1.421943448275862 ], [ 29.771888607594935, -1.421673965517241 ], [ 29.77161911392405, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6952, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.421673965517241 ], [ 29.771888607594935, -1.421943448275862 ], [ 29.772158101265823, -1.421943448275862 ], [ 29.772158101265823, -1.421673965517241 ], [ 29.771888607594935, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6953, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.421673965517241 ], [ 29.772427594936708, -1.421943448275862 ], [ 29.772697088607593, -1.421943448275862 ], [ 29.772697088607593, -1.421673965517241 ], [ 29.772427594936708, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6954, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.421673965517241 ], [ 29.772697088607593, -1.422212931034483 ], [ 29.772966582278482, -1.422212931034483 ], [ 29.772966582278482, -1.421673965517241 ], [ 29.772697088607593, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6955, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.421673965517241 ], [ 29.773236075949367, -1.422212931034483 ], [ 29.773505569620252, -1.422212931034483 ], [ 29.773505569620252, -1.421673965517241 ], [ 29.773236075949367, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6956, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.421673965517241 ], [ 29.774044556962025, -1.422212931034483 ], [ 29.77431405063291, -1.422212931034483 ], [ 29.77431405063291, -1.421673965517241 ], [ 29.774044556962025, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6957, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.421673965517241 ], [ 29.775122531645568, -1.422212931034483 ], [ 29.775392025316457, -1.422212931034483 ], [ 29.775392025316457, -1.421673965517241 ], [ 29.775122531645568, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6958, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.421673965517241 ], [ 29.775931012658226, -1.422212931034483 ], [ 29.776200506329115, -1.422212931034483 ], [ 29.776200506329115, -1.421673965517241 ], [ 29.775931012658226, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6959, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.421673965517241 ], [ 29.77647, -1.422212931034483 ], [ 29.776739493670885, -1.422212931034483 ], [ 29.776739493670885, -1.421673965517241 ], [ 29.77647, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6960, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.421673965517241 ], [ 29.776739493670885, -1.422212931034483 ], [ 29.777008987341773, -1.422212931034483 ], [ 29.777008987341773, -1.421673965517241 ], [ 29.776739493670885, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6961, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.421673965517241 ], [ 29.777278481012658, -1.422212931034483 ], [ 29.777547974683543, -1.422212931034483 ], [ 29.777547974683543, -1.421673965517241 ], [ 29.777278481012658, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6962, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.421673965517241 ], [ 29.778086962025316, -1.422212931034483 ], [ 29.778356455696201, -1.422212931034483 ], [ 29.778356455696201, -1.421673965517241 ], [ 29.778086962025316, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6963, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.421673965517241 ], [ 29.77862594936709, -1.421943448275862 ], [ 29.778895443037975, -1.421943448275862 ], [ 29.778895443037975, -1.421673965517241 ], [ 29.77862594936709, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6964, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.421673965517241 ], [ 29.778895443037975, -1.422212931034483 ], [ 29.77916493670886, -1.422212931034483 ], [ 29.77916493670886, -1.421673965517241 ], [ 29.778895443037975, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6965, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.421673965517241 ], [ 29.779434430379748, -1.421943448275862 ], [ 29.779703924050633, -1.421943448275862 ], [ 29.779703924050633, -1.421673965517241 ], [ 29.779434430379748, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6966, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.421673965517241 ], [ 29.779703924050633, -1.421943448275862 ], [ 29.779973417721518, -1.421943448275862 ], [ 29.779973417721518, -1.421673965517241 ], [ 29.779703924050633, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6967, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.421673965517241 ], [ 29.780242911392406, -1.421943448275862 ], [ 29.780512405063291, -1.421943448275862 ], [ 29.780512405063291, -1.421673965517241 ], [ 29.780242911392406, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6968, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.421673965517241 ], [ 29.780512405063291, -1.421943448275862 ], [ 29.780781898734176, -1.421943448275862 ], [ 29.780781898734176, -1.421673965517241 ], [ 29.780512405063291, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6969, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.421673965517241 ], [ 29.781051392405065, -1.421943448275862 ], [ 29.78132088607595, -1.421943448275862 ], [ 29.78132088607595, -1.421673965517241 ], [ 29.781051392405065, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6970, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.421673965517241 ], [ 29.78132088607595, -1.421943448275862 ], [ 29.781590379746834, -1.421943448275862 ], [ 29.781590379746834, -1.421673965517241 ], [ 29.78132088607595, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6971, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.421673965517241 ], [ 29.781590379746834, -1.421943448275862 ], [ 29.781859873417723, -1.421943448275862 ], [ 29.781859873417723, -1.421673965517241 ], [ 29.781590379746834, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6972, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.421673965517241 ], [ 29.781859873417723, -1.421943448275862 ], [ 29.782129367088608, -1.421943448275862 ], [ 29.782129367088608, -1.421673965517241 ], [ 29.781859873417723, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6973, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.421673965517241 ], [ 29.782129367088608, -1.421943448275862 ], [ 29.782398860759493, -1.421943448275862 ], [ 29.782398860759493, -1.421673965517241 ], [ 29.782129367088608, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6974, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.421673965517241 ], [ 29.782398860759493, -1.421943448275862 ], [ 29.782668354430381, -1.421943448275862 ], [ 29.782668354430381, -1.421673965517241 ], [ 29.782398860759493, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6975, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.421673965517241 ], [ 29.782668354430381, -1.421943448275862 ], [ 29.782937848101266, -1.421943448275862 ], [ 29.782937848101266, -1.421673965517241 ], [ 29.782668354430381, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6976, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.421673965517241 ], [ 29.782937848101266, -1.421943448275862 ], [ 29.783207341772151, -1.421943448275862 ], [ 29.783207341772151, -1.421673965517241 ], [ 29.782937848101266, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6977, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.421673965517241 ], [ 29.783207341772151, -1.421943448275862 ], [ 29.783476835443039, -1.421943448275862 ], [ 29.783476835443039, -1.421673965517241 ], [ 29.783207341772151, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6978, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.421673965517241 ], [ 29.783476835443039, -1.421943448275862 ], [ 29.784015822784809, -1.421943448275862 ], [ 29.784015822784809, -1.421673965517241 ], [ 29.783476835443039, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6979, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.421673965517241 ], [ 29.784015822784809, -1.421943448275862 ], [ 29.784285316455698, -1.421943448275862 ], [ 29.784285316455698, -1.421673965517241 ], [ 29.784015822784809, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.421673965517241 ], [ 29.784285316455698, -1.421943448275862 ], [ 29.784554810126583, -1.421943448275862 ], [ 29.784554810126583, -1.421673965517241 ], [ 29.784285316455698, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6981, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.421673965517241 ], [ 29.784554810126583, -1.421943448275862 ], [ 29.784824303797468, -1.421943448275862 ], [ 29.784824303797468, -1.421673965517241 ], [ 29.784554810126583, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6982, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.421673965517241 ], [ 29.784824303797468, -1.421943448275862 ], [ 29.785093797468356, -1.421943448275862 ], [ 29.785093797468356, -1.421673965517241 ], [ 29.784824303797468, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6983, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.421673965517241 ], [ 29.785093797468356, -1.421943448275862 ], [ 29.785363291139241, -1.421943448275862 ], [ 29.785363291139241, -1.421673965517241 ], [ 29.785093797468356, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6984, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.421673965517241 ], [ 29.800724430379748, -1.421943448275862 ], [ 29.800993924050633, -1.421943448275862 ], [ 29.800993924050633, -1.421673965517241 ], [ 29.800724430379748, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6985, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.421673965517241 ], [ 29.800993924050633, -1.421943448275862 ], [ 29.801263417721518, -1.421943448275862 ], [ 29.801263417721518, -1.421673965517241 ], [ 29.800993924050633, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6986, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.421673965517241 ], [ 29.801263417721518, -1.421943448275862 ], [ 29.801532911392407, -1.421943448275862 ], [ 29.801532911392407, -1.421673965517241 ], [ 29.801263417721518, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6987, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.421673965517241 ], [ 29.801532911392407, -1.422212931034483 ], [ 29.802071898734177, -1.422212931034483 ], [ 29.802071898734177, -1.421673965517241 ], [ 29.801532911392407, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6988, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.421673965517241 ], [ 29.802071898734177, -1.421943448275862 ], [ 29.802341392405065, -1.421943448275862 ], [ 29.802341392405065, -1.421673965517241 ], [ 29.802071898734177, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6989, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.421673965517241 ], [ 29.802341392405065, -1.421943448275862 ], [ 29.80261088607595, -1.421943448275862 ], [ 29.80261088607595, -1.421673965517241 ], [ 29.802341392405065, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6990, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.421673965517241 ], [ 29.80261088607595, -1.421943448275862 ], [ 29.802880379746835, -1.421943448275862 ], [ 29.802880379746835, -1.421673965517241 ], [ 29.80261088607595, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6991, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.421673965517241 ], [ 29.802880379746835, -1.421943448275862 ], [ 29.803149873417723, -1.421943448275862 ], [ 29.803149873417723, -1.421673965517241 ], [ 29.802880379746835, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6992, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.421673965517241 ], [ 29.803149873417723, -1.421943448275862 ], [ 29.803419367088608, -1.421943448275862 ], [ 29.803419367088608, -1.421673965517241 ], [ 29.803149873417723, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6993, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.421673965517241 ], [ 29.803419367088608, -1.421943448275862 ], [ 29.803688860759493, -1.421943448275862 ], [ 29.803688860759493, -1.421673965517241 ], [ 29.803419367088608, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6994, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.421673965517241 ], [ 29.803688860759493, -1.421943448275862 ], [ 29.803958354430382, -1.421943448275862 ], [ 29.803958354430382, -1.421673965517241 ], [ 29.803688860759493, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6995, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.421673965517241 ], [ 29.803958354430382, -1.421943448275862 ], [ 29.804227848101267, -1.421943448275862 ], [ 29.804227848101267, -1.421673965517241 ], [ 29.803958354430382, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6996, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.421673965517241 ], [ 29.804227848101267, -1.421943448275862 ], [ 29.804497341772151, -1.421943448275862 ], [ 29.804497341772151, -1.421673965517241 ], [ 29.804227848101267, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6997, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.421673965517241 ], [ 29.804497341772151, -1.421943448275862 ], [ 29.80476683544304, -1.421943448275862 ], [ 29.80476683544304, -1.421673965517241 ], [ 29.804497341772151, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6998, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.421673965517241 ], [ 29.80476683544304, -1.421943448275862 ], [ 29.805036329113925, -1.421943448275862 ], [ 29.805036329113925, -1.421673965517241 ], [ 29.80476683544304, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 6999, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.421673965517241 ], [ 29.805036329113925, -1.421943448275862 ], [ 29.80530582278481, -1.421943448275862 ], [ 29.80530582278481, -1.421673965517241 ], [ 29.805036329113925, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7000, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.421943448275862 ], [ 29.757874936708859, -1.422212931034483 ], [ 29.758144430379748, -1.422212931034483 ], [ 29.758144430379748, -1.421943448275862 ], [ 29.757874936708859, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7001, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.421943448275862 ], [ 29.758144430379748, -1.422212931034483 ], [ 29.758413924050632, -1.422212931034483 ], [ 29.758413924050632, -1.421943448275862 ], [ 29.758144430379748, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7002, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.421943448275862 ], [ 29.758413924050632, -1.422212931034483 ], [ 29.758683417721517, -1.422212931034483 ], [ 29.758683417721517, -1.421943448275862 ], [ 29.758413924050632, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7003, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.421943448275862 ], [ 29.762456329113924, -1.422212931034483 ], [ 29.762725822784809, -1.422212931034483 ], [ 29.762725822784809, -1.421943448275862 ], [ 29.762456329113924, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7004, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.421943448275862 ], [ 29.763264810126582, -1.422212931034483 ], [ 29.763534303797467, -1.422212931034483 ], [ 29.763534303797467, -1.421943448275862 ], [ 29.763264810126582, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7005, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.421943448275862 ], [ 29.763534303797467, -1.422212931034483 ], [ 29.763803797468356, -1.422212931034483 ], [ 29.763803797468356, -1.421943448275862 ], [ 29.763534303797467, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7006, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.421943448275862 ], [ 29.76407329113924, -1.422212931034483 ], [ 29.764342784810125, -1.422212931034483 ], [ 29.764342784810125, -1.421943448275862 ], [ 29.76407329113924, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7007, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.421943448275862 ], [ 29.764342784810125, -1.422212931034483 ], [ 29.764612278481014, -1.422212931034483 ], [ 29.764612278481014, -1.421943448275862 ], [ 29.764342784810125, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7008, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.421943448275862 ], [ 29.764881772151899, -1.422212931034483 ], [ 29.765151265822784, -1.422212931034483 ], [ 29.765151265822784, -1.421943448275862 ], [ 29.764881772151899, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7009, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.421943448275862 ], [ 29.765151265822784, -1.422212931034483 ], [ 29.765420759493672, -1.422212931034483 ], [ 29.765420759493672, -1.421943448275862 ], [ 29.765151265822784, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7010, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.421943448275862 ], [ 29.766229240506327, -1.422212931034483 ], [ 29.766498734177215, -1.422212931034483 ], [ 29.766498734177215, -1.421943448275862 ], [ 29.766229240506327, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7011, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.421943448275862 ], [ 29.767037721518985, -1.422212931034483 ], [ 29.767307215189874, -1.422212931034483 ], [ 29.767307215189874, -1.421943448275862 ], [ 29.767037721518985, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7012, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.421943448275862 ], [ 29.767307215189874, -1.422212931034483 ], [ 29.767576708860759, -1.422212931034483 ], [ 29.767576708860759, -1.421943448275862 ], [ 29.767307215189874, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7013, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.421943448275862 ], [ 29.767846202531643, -1.422212931034483 ], [ 29.768115696202532, -1.422212931034483 ], [ 29.768115696202532, -1.421943448275862 ], [ 29.767846202531643, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7014, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.421943448275862 ], [ 29.768115696202532, -1.422212931034483 ], [ 29.768385189873417, -1.422212931034483 ], [ 29.768385189873417, -1.421943448275862 ], [ 29.768115696202532, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7015, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.421943448275862 ], [ 29.768385189873417, -1.422212931034483 ], [ 29.768654683544302, -1.422212931034483 ], [ 29.768654683544302, -1.421943448275862 ], [ 29.768385189873417, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7016, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.421943448275862 ], [ 29.768654683544302, -1.422212931034483 ], [ 29.76892417721519, -1.422212931034483 ], [ 29.76892417721519, -1.421943448275862 ], [ 29.768654683544302, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7017, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.421943448275862 ], [ 29.76892417721519, -1.422212931034483 ], [ 29.769193670886075, -1.422212931034483 ], [ 29.769193670886075, -1.421943448275862 ], [ 29.76892417721519, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7018, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.421943448275862 ], [ 29.769193670886075, -1.422212931034483 ], [ 29.76946316455696, -1.422212931034483 ], [ 29.76946316455696, -1.421943448275862 ], [ 29.769193670886075, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7019, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.421943448275862 ], [ 29.76946316455696, -1.422212931034483 ], [ 29.769732658227849, -1.422212931034483 ], [ 29.769732658227849, -1.421943448275862 ], [ 29.76946316455696, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7020, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.421943448275862 ], [ 29.769732658227849, -1.422212931034483 ], [ 29.770002151898733, -1.422212931034483 ], [ 29.770002151898733, -1.421943448275862 ], [ 29.769732658227849, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7021, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.421943448275862 ], [ 29.770002151898733, -1.422212931034483 ], [ 29.770271645569618, -1.422212931034483 ], [ 29.770271645569618, -1.421943448275862 ], [ 29.770002151898733, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7022, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.421943448275862 ], [ 29.770271645569618, -1.422212931034483 ], [ 29.770541139240507, -1.422212931034483 ], [ 29.770541139240507, -1.421943448275862 ], [ 29.770271645569618, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7023, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.421943448275862 ], [ 29.770541139240507, -1.422212931034483 ], [ 29.770810632911392, -1.422212931034483 ], [ 29.770810632911392, -1.421943448275862 ], [ 29.770541139240507, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7024, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.421943448275862 ], [ 29.770810632911392, -1.422212931034483 ], [ 29.771080126582277, -1.422212931034483 ], [ 29.771080126582277, -1.421943448275862 ], [ 29.770810632911392, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7025, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.421943448275862 ], [ 29.771080126582277, -1.422212931034483 ], [ 29.771349620253165, -1.422212931034483 ], [ 29.771349620253165, -1.421943448275862 ], [ 29.771080126582277, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7026, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.421943448275862 ], [ 29.771349620253165, -1.422212931034483 ], [ 29.77161911392405, -1.422212931034483 ], [ 29.77161911392405, -1.421943448275862 ], [ 29.771349620253165, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7027, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.421943448275862 ], [ 29.77161911392405, -1.422212931034483 ], [ 29.771888607594935, -1.422212931034483 ], [ 29.771888607594935, -1.421943448275862 ], [ 29.77161911392405, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7028, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.421943448275862 ], [ 29.771888607594935, -1.422212931034483 ], [ 29.772158101265823, -1.422212931034483 ], [ 29.772158101265823, -1.421943448275862 ], [ 29.771888607594935, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7029, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.421943448275862 ], [ 29.777547974683543, -1.422212931034483 ], [ 29.777817468354431, -1.422212931034483 ], [ 29.777817468354431, -1.421943448275862 ], [ 29.777547974683543, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7030, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.421943448275862 ], [ 29.778356455696201, -1.422212931034483 ], [ 29.77862594936709, -1.422212931034483 ], [ 29.77862594936709, -1.421943448275862 ], [ 29.778356455696201, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7031, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.421943448275862 ], [ 29.77862594936709, -1.422212931034483 ], [ 29.778895443037975, -1.422212931034483 ], [ 29.778895443037975, -1.421943448275862 ], [ 29.77862594936709, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7032, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.421943448275862 ], [ 29.77916493670886, -1.422212931034483 ], [ 29.779434430379748, -1.422212931034483 ], [ 29.779434430379748, -1.421943448275862 ], [ 29.77916493670886, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7033, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.421943448275862 ], [ 29.779434430379748, -1.422212931034483 ], [ 29.779703924050633, -1.422212931034483 ], [ 29.779703924050633, -1.421943448275862 ], [ 29.779434430379748, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7034, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.421943448275862 ], [ 29.779973417721518, -1.422212931034483 ], [ 29.780242911392406, -1.422212931034483 ], [ 29.780242911392406, -1.421943448275862 ], [ 29.779973417721518, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7035, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.421943448275862 ], [ 29.780242911392406, -1.422212931034483 ], [ 29.780512405063291, -1.422212931034483 ], [ 29.780512405063291, -1.421943448275862 ], [ 29.780242911392406, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7036, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.421943448275862 ], [ 29.780512405063291, -1.422212931034483 ], [ 29.780781898734176, -1.422212931034483 ], [ 29.780781898734176, -1.421943448275862 ], [ 29.780512405063291, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7037, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.421943448275862 ], [ 29.780781898734176, -1.422212931034483 ], [ 29.781051392405065, -1.422212931034483 ], [ 29.781051392405065, -1.421943448275862 ], [ 29.780781898734176, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7038, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.421943448275862 ], [ 29.781051392405065, -1.422212931034483 ], [ 29.78132088607595, -1.422212931034483 ], [ 29.78132088607595, -1.421943448275862 ], [ 29.781051392405065, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7039, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.421943448275862 ], [ 29.78132088607595, -1.422212931034483 ], [ 29.781590379746834, -1.422212931034483 ], [ 29.781590379746834, -1.421943448275862 ], [ 29.78132088607595, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7040, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.421943448275862 ], [ 29.781590379746834, -1.422212931034483 ], [ 29.781859873417723, -1.422212931034483 ], [ 29.781859873417723, -1.421943448275862 ], [ 29.781590379746834, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7041, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.421943448275862 ], [ 29.781859873417723, -1.422212931034483 ], [ 29.782129367088608, -1.422212931034483 ], [ 29.782129367088608, -1.421943448275862 ], [ 29.781859873417723, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7042, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.421943448275862 ], [ 29.782129367088608, -1.422212931034483 ], [ 29.782398860759493, -1.422212931034483 ], [ 29.782398860759493, -1.421943448275862 ], [ 29.782129367088608, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7043, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.421943448275862 ], [ 29.782398860759493, -1.422212931034483 ], [ 29.782668354430381, -1.422212931034483 ], [ 29.782668354430381, -1.421943448275862 ], [ 29.782398860759493, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7044, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.421943448275862 ], [ 29.782668354430381, -1.422212931034483 ], [ 29.782937848101266, -1.422212931034483 ], [ 29.782937848101266, -1.421943448275862 ], [ 29.782668354430381, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7045, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.421943448275862 ], [ 29.782937848101266, -1.422212931034483 ], [ 29.783207341772151, -1.422212931034483 ], [ 29.783207341772151, -1.421943448275862 ], [ 29.782937848101266, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7046, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.421943448275862 ], [ 29.783207341772151, -1.422212931034483 ], [ 29.783476835443039, -1.422212931034483 ], [ 29.783476835443039, -1.421943448275862 ], [ 29.783207341772151, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7047, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.421943448275862 ], [ 29.783476835443039, -1.422212931034483 ], [ 29.784015822784809, -1.422212931034483 ], [ 29.784015822784809, -1.421943448275862 ], [ 29.783476835443039, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7048, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.421943448275862 ], [ 29.784015822784809, -1.422212931034483 ], [ 29.784285316455698, -1.422212931034483 ], [ 29.784285316455698, -1.421943448275862 ], [ 29.784015822784809, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7049, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.421943448275862 ], [ 29.784285316455698, -1.422212931034483 ], [ 29.784554810126583, -1.422212931034483 ], [ 29.784554810126583, -1.421943448275862 ], [ 29.784285316455698, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7050, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.421943448275862 ], [ 29.784554810126583, -1.422212931034483 ], [ 29.784824303797468, -1.422212931034483 ], [ 29.784824303797468, -1.421943448275862 ], [ 29.784554810126583, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7051, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.421943448275862 ], [ 29.800185443037975, -1.422212931034483 ], [ 29.80045493670886, -1.422212931034483 ], [ 29.80045493670886, -1.421943448275862 ], [ 29.800185443037975, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7052, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.421943448275862 ], [ 29.80045493670886, -1.422212931034483 ], [ 29.800724430379748, -1.422212931034483 ], [ 29.800724430379748, -1.421943448275862 ], [ 29.80045493670886, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7053, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.421943448275862 ], [ 29.800724430379748, -1.422212931034483 ], [ 29.800993924050633, -1.422212931034483 ], [ 29.800993924050633, -1.421943448275862 ], [ 29.800724430379748, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7054, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.421943448275862 ], [ 29.802880379746835, -1.422212931034483 ], [ 29.803149873417723, -1.422212931034483 ], [ 29.803149873417723, -1.421943448275862 ], [ 29.802880379746835, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7055, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.421943448275862 ], [ 29.803149873417723, -1.422212931034483 ], [ 29.803419367088608, -1.422212931034483 ], [ 29.803419367088608, -1.421943448275862 ], [ 29.803149873417723, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7056, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.421943448275862 ], [ 29.803419367088608, -1.422212931034483 ], [ 29.803688860759493, -1.422212931034483 ], [ 29.803688860759493, -1.421943448275862 ], [ 29.803419367088608, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7057, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.421943448275862 ], [ 29.803688860759493, -1.422212931034483 ], [ 29.803958354430382, -1.422212931034483 ], [ 29.803958354430382, -1.421943448275862 ], [ 29.803688860759493, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7058, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.421943448275862 ], [ 29.803958354430382, -1.422212931034483 ], [ 29.804227848101267, -1.422212931034483 ], [ 29.804227848101267, -1.421943448275862 ], [ 29.803958354430382, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7059, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.421943448275862 ], [ 29.804227848101267, -1.422212931034483 ], [ 29.804497341772151, -1.422212931034483 ], [ 29.804497341772151, -1.421943448275862 ], [ 29.804227848101267, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7060, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.421943448275862 ], [ 29.804497341772151, -1.422212931034483 ], [ 29.80476683544304, -1.422212931034483 ], [ 29.80476683544304, -1.421943448275862 ], [ 29.804497341772151, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7061, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.421943448275862 ], [ 29.80476683544304, -1.422212931034483 ], [ 29.805036329113925, -1.422212931034483 ], [ 29.805036329113925, -1.421943448275862 ], [ 29.80476683544304, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7062, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.421943448275862 ], [ 29.805036329113925, -1.422212931034483 ], [ 29.80530582278481, -1.422212931034483 ], [ 29.80530582278481, -1.421943448275862 ], [ 29.805036329113925, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7063, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.421943448275862 ], [ 29.80530582278481, -1.422212931034483 ], [ 29.805575316455698, -1.422212931034483 ], [ 29.805575316455698, -1.421943448275862 ], [ 29.80530582278481, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7064, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.421673965517241 ], [ 29.760030886075949, -1.422482413793104 ], [ 29.760300379746834, -1.422482413793104 ], [ 29.760300379746834, -1.421673965517241 ], [ 29.760030886075949, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7065, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.421673965517241 ], [ 29.760839367088607, -1.422482413793104 ], [ 29.761108860759492, -1.422482413793104 ], [ 29.761108860759492, -1.421673965517241 ], [ 29.760839367088607, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7066, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.421673965517241 ], [ 29.761647848101266, -1.422482413793104 ], [ 29.761917341772151, -1.422482413793104 ], [ 29.761917341772151, -1.421673965517241 ], [ 29.761647848101266, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7067, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.421673965517241 ], [ 29.773505569620252, -1.422482413793104 ], [ 29.77377506329114, -1.422482413793104 ], [ 29.77377506329114, -1.421673965517241 ], [ 29.773505569620252, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7068, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.421673965517241 ], [ 29.77431405063291, -1.422482413793104 ], [ 29.774853037974683, -1.422482413793104 ], [ 29.774853037974683, -1.421673965517241 ], [ 29.77431405063291, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7069, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.421673965517241 ], [ 29.785363291139241, -1.423560344827586 ], [ 29.785632784810126, -1.423560344827586 ], [ 29.785632784810126, -1.421673965517241 ], [ 29.785363291139241, -1.421673965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7070, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.421943448275862 ], [ 29.757605443037974, -1.422482413793104 ], [ 29.757874936708859, -1.422482413793104 ], [ 29.757874936708859, -1.421943448275862 ], [ 29.757605443037974, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7071, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.421943448275862 ], [ 29.759222405063291, -1.422482413793104 ], [ 29.759491898734176, -1.422482413793104 ], [ 29.759491898734176, -1.421943448275862 ], [ 29.759222405063291, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7072, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.421943448275862 ], [ 29.760569873417722, -1.422482413793104 ], [ 29.760839367088607, -1.422482413793104 ], [ 29.760839367088607, -1.421943448275862 ], [ 29.760569873417722, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7073, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.421943448275862 ], [ 29.761378354430381, -1.422482413793104 ], [ 29.761647848101266, -1.422482413793104 ], [ 29.761647848101266, -1.421943448275862 ], [ 29.761378354430381, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7074, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.421943448275862 ], [ 29.762186835443039, -1.422482413793104 ], [ 29.762456329113924, -1.422482413793104 ], [ 29.762456329113924, -1.421943448275862 ], [ 29.762186835443039, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7075, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.421943448275862 ], [ 29.762995316455697, -1.422482413793104 ], [ 29.763264810126582, -1.422482413793104 ], [ 29.763264810126582, -1.421943448275862 ], [ 29.762995316455697, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7076, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.421943448275862 ], [ 29.763803797468356, -1.422482413793104 ], [ 29.76407329113924, -1.422482413793104 ], [ 29.76407329113924, -1.421943448275862 ], [ 29.763803797468356, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7077, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.421943448275862 ], [ 29.765959746835442, -1.422482413793104 ], [ 29.766229240506327, -1.422482413793104 ], [ 29.766229240506327, -1.421943448275862 ], [ 29.765959746835442, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7078, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.421943448275862 ], [ 29.7667682278481, -1.422482413793104 ], [ 29.767037721518985, -1.422482413793104 ], [ 29.767037721518985, -1.421943448275862 ], [ 29.7667682278481, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7079, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.421943448275862 ], [ 29.767576708860759, -1.422482413793104 ], [ 29.767846202531643, -1.422482413793104 ], [ 29.767846202531643, -1.421943448275862 ], [ 29.767576708860759, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7080, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.421943448275862 ], [ 29.772158101265823, -1.422482413793104 ], [ 29.772427594936708, -1.422482413793104 ], [ 29.772427594936708, -1.421943448275862 ], [ 29.772158101265823, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7081, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.421943448275862 ], [ 29.772427594936708, -1.422751896551724 ], [ 29.772697088607593, -1.422751896551724 ], [ 29.772697088607593, -1.421943448275862 ], [ 29.772427594936708, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7082, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.421943448275862 ], [ 29.772966582278482, -1.422482413793104 ], [ 29.773236075949367, -1.422482413793104 ], [ 29.773236075949367, -1.421943448275862 ], [ 29.772966582278482, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7083, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.421943448275862 ], [ 29.77377506329114, -1.422482413793104 ], [ 29.774044556962025, -1.422482413793104 ], [ 29.774044556962025, -1.421943448275862 ], [ 29.77377506329114, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7084, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.421943448275862 ], [ 29.774853037974683, -1.422482413793104 ], [ 29.775122531645568, -1.422482413793104 ], [ 29.775122531645568, -1.421943448275862 ], [ 29.774853037974683, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7085, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.421943448275862 ], [ 29.775392025316457, -1.422482413793104 ], [ 29.775661518987341, -1.422482413793104 ], [ 29.775661518987341, -1.421943448275862 ], [ 29.775392025316457, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7086, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.421943448275862 ], [ 29.776200506329115, -1.422482413793104 ], [ 29.77647, -1.422482413793104 ], [ 29.77647, -1.421943448275862 ], [ 29.776200506329115, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7087, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.421943448275862 ], [ 29.777008987341773, -1.422482413793104 ], [ 29.777278481012658, -1.422482413793104 ], [ 29.777278481012658, -1.421943448275862 ], [ 29.777008987341773, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7088, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.421943448275862 ], [ 29.777817468354431, -1.422482413793104 ], [ 29.778086962025316, -1.422482413793104 ], [ 29.778086962025316, -1.421943448275862 ], [ 29.777817468354431, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7089, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.421943448275862 ], [ 29.779703924050633, -1.422482413793104 ], [ 29.779973417721518, -1.422482413793104 ], [ 29.779973417721518, -1.421943448275862 ], [ 29.779703924050633, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7090, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.421943448275862 ], [ 29.784824303797468, -1.423560344827586 ], [ 29.785093797468356, -1.423560344827586 ], [ 29.785093797468356, -1.421943448275862 ], [ 29.784824303797468, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7091, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.421943448275862 ], [ 29.785093797468356, -1.423560344827586 ], [ 29.785363291139241, -1.423560344827586 ], [ 29.785363291139241, -1.421943448275862 ], [ 29.785093797468356, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7092, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.421943448275862 ], [ 29.789405696202532, -1.422482413793104 ], [ 29.789675189873417, -1.422482413793104 ], [ 29.789675189873417, -1.422212931034483 ], [ 29.791022658227849, -1.422212931034483 ], [ 29.791022658227849, -1.421943448275862 ], [ 29.789405696202532, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7093, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.421943448275862 ], [ 29.800993924050633, -1.422751896551724 ], [ 29.801263417721518, -1.422751896551724 ], [ 29.801263417721518, -1.421943448275862 ], [ 29.800993924050633, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7094, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.421943448275862 ], [ 29.801263417721518, -1.422751896551724 ], [ 29.801532911392407, -1.422751896551724 ], [ 29.801532911392407, -1.421943448275862 ], [ 29.801263417721518, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7095, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.421943448275862 ], [ 29.802071898734177, -1.422482413793104 ], [ 29.802341392405065, -1.422482413793104 ], [ 29.802341392405065, -1.421943448275862 ], [ 29.802071898734177, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7096, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.421943448275862 ], [ 29.802341392405065, -1.422482413793104 ], [ 29.80261088607595, -1.422482413793104 ], [ 29.80261088607595, -1.421943448275862 ], [ 29.802341392405065, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7097, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.421943448275862 ], [ 29.80261088607595, -1.422482413793104 ], [ 29.802880379746835, -1.422482413793104 ], [ 29.802880379746835, -1.421943448275862 ], [ 29.80261088607595, -1.421943448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7098, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.422212931034483 ], [ 29.757874936708859, -1.422482413793104 ], [ 29.758144430379748, -1.422482413793104 ], [ 29.758144430379748, -1.422212931034483 ], [ 29.757874936708859, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7099, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.422212931034483 ], [ 29.758144430379748, -1.422482413793104 ], [ 29.758413924050632, -1.422482413793104 ], [ 29.758413924050632, -1.422212931034483 ], [ 29.758144430379748, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7100, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.422212931034483 ], [ 29.758413924050632, -1.422482413793104 ], [ 29.758683417721517, -1.422482413793104 ], [ 29.758683417721517, -1.422212931034483 ], [ 29.758413924050632, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7101, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.422212931034483 ], [ 29.758683417721517, -1.422482413793104 ], [ 29.758952911392406, -1.422482413793104 ], [ 29.758952911392406, -1.422212931034483 ], [ 29.758683417721517, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7102, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.422212931034483 ], [ 29.758952911392406, -1.422482413793104 ], [ 29.759222405063291, -1.422482413793104 ], [ 29.759222405063291, -1.422212931034483 ], [ 29.758952911392406, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7103, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.422212931034483 ], [ 29.759491898734176, -1.422482413793104 ], [ 29.759761392405064, -1.422482413793104 ], [ 29.759761392405064, -1.422212931034483 ], [ 29.759491898734176, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7104, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.422212931034483 ], [ 29.759761392405064, -1.422482413793104 ], [ 29.760030886075949, -1.422482413793104 ], [ 29.760030886075949, -1.422212931034483 ], [ 29.759761392405064, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7105, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.422212931034483 ], [ 29.760300379746834, -1.422751896551724 ], [ 29.760569873417722, -1.422751896551724 ], [ 29.760569873417722, -1.422212931034483 ], [ 29.760300379746834, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7106, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.422212931034483 ], [ 29.761108860759492, -1.422751896551724 ], [ 29.761378354430381, -1.422751896551724 ], [ 29.761378354430381, -1.422212931034483 ], [ 29.761108860759492, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7107, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.422212931034483 ], [ 29.761917341772151, -1.422751896551724 ], [ 29.762186835443039, -1.422751896551724 ], [ 29.762186835443039, -1.422212931034483 ], [ 29.761917341772151, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7108, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.422212931034483 ], [ 29.762456329113924, -1.422751896551724 ], [ 29.762725822784809, -1.422751896551724 ], [ 29.762725822784809, -1.422212931034483 ], [ 29.762456329113924, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7109, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.422212931034483 ], [ 29.762725822784809, -1.422751896551724 ], [ 29.762995316455697, -1.422751896551724 ], [ 29.762995316455697, -1.422212931034483 ], [ 29.762725822784809, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7110, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.422212931034483 ], [ 29.763264810126582, -1.422751896551724 ], [ 29.763534303797467, -1.422751896551724 ], [ 29.763534303797467, -1.422212931034483 ], [ 29.763264810126582, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7111, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.422212931034483 ], [ 29.763534303797467, -1.422751896551724 ], [ 29.763803797468356, -1.422751896551724 ], [ 29.763803797468356, -1.422212931034483 ], [ 29.763534303797467, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7112, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.422212931034483 ], [ 29.76407329113924, -1.422751896551724 ], [ 29.764342784810125, -1.422751896551724 ], [ 29.764342784810125, -1.422212931034483 ], [ 29.76407329113924, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7113, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.422212931034483 ], [ 29.764342784810125, -1.422482413793104 ], [ 29.764612278481014, -1.422482413793104 ], [ 29.764612278481014, -1.422212931034483 ], [ 29.764342784810125, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7114, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.422212931034483 ], [ 29.764612278481014, -1.422482413793104 ], [ 29.764881772151899, -1.422482413793104 ], [ 29.764881772151899, -1.422212931034483 ], [ 29.764612278481014, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7115, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.422212931034483 ], [ 29.764881772151899, -1.422751896551724 ], [ 29.765151265822784, -1.422751896551724 ], [ 29.765151265822784, -1.422212931034483 ], [ 29.764881772151899, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7116, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.422212931034483 ], [ 29.765151265822784, -1.422482413793104 ], [ 29.765420759493672, -1.422482413793104 ], [ 29.765420759493672, -1.422212931034483 ], [ 29.765151265822784, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7117, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.422212931034483 ], [ 29.765420759493672, -1.422482413793104 ], [ 29.765959746835442, -1.422482413793104 ], [ 29.765959746835442, -1.422212931034483 ], [ 29.765420759493672, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7118, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.422212931034483 ], [ 29.766229240506327, -1.422482413793104 ], [ 29.766498734177215, -1.422482413793104 ], [ 29.766498734177215, -1.422212931034483 ], [ 29.766229240506327, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7119, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.422212931034483 ], [ 29.766498734177215, -1.422482413793104 ], [ 29.7667682278481, -1.422482413793104 ], [ 29.7667682278481, -1.422212931034483 ], [ 29.766498734177215, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7120, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.422212931034483 ], [ 29.767037721518985, -1.422751896551724 ], [ 29.767307215189874, -1.422751896551724 ], [ 29.767307215189874, -1.422212931034483 ], [ 29.767037721518985, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7121, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.422212931034483 ], [ 29.767307215189874, -1.422482413793104 ], [ 29.767576708860759, -1.422482413793104 ], [ 29.767576708860759, -1.422212931034483 ], [ 29.767307215189874, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7122, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.422212931034483 ], [ 29.767846202531643, -1.422751896551724 ], [ 29.768115696202532, -1.422751896551724 ], [ 29.768115696202532, -1.422212931034483 ], [ 29.767846202531643, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7123, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.422212931034483 ], [ 29.768115696202532, -1.422482413793104 ], [ 29.768385189873417, -1.422482413793104 ], [ 29.768385189873417, -1.422212931034483 ], [ 29.768115696202532, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7124, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.422212931034483 ], [ 29.768385189873417, -1.422482413793104 ], [ 29.768654683544302, -1.422482413793104 ], [ 29.768654683544302, -1.422212931034483 ], [ 29.768385189873417, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7125, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.422212931034483 ], [ 29.768654683544302, -1.422482413793104 ], [ 29.76892417721519, -1.422482413793104 ], [ 29.76892417721519, -1.422212931034483 ], [ 29.768654683544302, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7126, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.422212931034483 ], [ 29.76892417721519, -1.422482413793104 ], [ 29.769193670886075, -1.422482413793104 ], [ 29.769193670886075, -1.422212931034483 ], [ 29.76892417721519, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7127, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.422212931034483 ], [ 29.769193670886075, -1.422482413793104 ], [ 29.76946316455696, -1.422482413793104 ], [ 29.76946316455696, -1.422212931034483 ], [ 29.769193670886075, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7128, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.422212931034483 ], [ 29.76946316455696, -1.422482413793104 ], [ 29.769732658227849, -1.422482413793104 ], [ 29.769732658227849, -1.422212931034483 ], [ 29.76946316455696, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7129, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.422212931034483 ], [ 29.769732658227849, -1.422482413793104 ], [ 29.770002151898733, -1.422482413793104 ], [ 29.770002151898733, -1.422212931034483 ], [ 29.769732658227849, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7130, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.422212931034483 ], [ 29.770002151898733, -1.422482413793104 ], [ 29.770271645569618, -1.422482413793104 ], [ 29.770271645569618, -1.422212931034483 ], [ 29.770002151898733, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7131, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.422212931034483 ], [ 29.770271645569618, -1.422482413793104 ], [ 29.770541139240507, -1.422482413793104 ], [ 29.770541139240507, -1.422212931034483 ], [ 29.770271645569618, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7132, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.422212931034483 ], [ 29.770541139240507, -1.422482413793104 ], [ 29.770810632911392, -1.422482413793104 ], [ 29.770810632911392, -1.422212931034483 ], [ 29.770541139240507, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7133, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.422212931034483 ], [ 29.770810632911392, -1.422482413793104 ], [ 29.771080126582277, -1.422482413793104 ], [ 29.771080126582277, -1.422212931034483 ], [ 29.770810632911392, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7134, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.422212931034483 ], [ 29.771080126582277, -1.422482413793104 ], [ 29.771349620253165, -1.422482413793104 ], [ 29.771349620253165, -1.422212931034483 ], [ 29.771080126582277, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7135, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.422212931034483 ], [ 29.771349620253165, -1.422482413793104 ], [ 29.77161911392405, -1.422482413793104 ], [ 29.77161911392405, -1.422212931034483 ], [ 29.771349620253165, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7136, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.422212931034483 ], [ 29.77161911392405, -1.422482413793104 ], [ 29.771888607594935, -1.422482413793104 ], [ 29.771888607594935, -1.422212931034483 ], [ 29.77161911392405, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7137, "El": 158 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.422212931034483 ], [ 29.771888607594935, -1.422751896551724 ], [ 29.772158101265823, -1.422751896551724 ], [ 29.772158101265823, -1.422212931034483 ], [ 29.771888607594935, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7138, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.422212931034483 ], [ 29.772697088607593, -1.422751896551724 ], [ 29.772966582278482, -1.422751896551724 ], [ 29.772966582278482, -1.422212931034483 ], [ 29.772697088607593, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7139, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.422212931034483 ], [ 29.773236075949367, -1.422751896551724 ], [ 29.773505569620252, -1.422751896551724 ], [ 29.773505569620252, -1.422212931034483 ], [ 29.773236075949367, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7140, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.422212931034483 ], [ 29.774044556962025, -1.422751896551724 ], [ 29.77431405063291, -1.422751896551724 ], [ 29.77431405063291, -1.422212931034483 ], [ 29.774044556962025, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7141, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.422212931034483 ], [ 29.775122531645568, -1.422751896551724 ], [ 29.775392025316457, -1.422751896551724 ], [ 29.775392025316457, -1.422212931034483 ], [ 29.775122531645568, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7142, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.422212931034483 ], [ 29.775661518987341, -1.422482413793104 ], [ 29.775931012658226, -1.422482413793104 ], [ 29.775931012658226, -1.422212931034483 ], [ 29.775661518987341, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7143, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.422212931034483 ], [ 29.775931012658226, -1.422751896551724 ], [ 29.776200506329115, -1.422751896551724 ], [ 29.776200506329115, -1.422212931034483 ], [ 29.775931012658226, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7144, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.422212931034483 ], [ 29.77647, -1.422482413793104 ], [ 29.776739493670885, -1.422482413793104 ], [ 29.776739493670885, -1.422212931034483 ], [ 29.77647, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7145, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.422212931034483 ], [ 29.776739493670885, -1.422751896551724 ], [ 29.777008987341773, -1.422751896551724 ], [ 29.777008987341773, -1.422212931034483 ], [ 29.776739493670885, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7146, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.422212931034483 ], [ 29.777278481012658, -1.422482413793104 ], [ 29.777547974683543, -1.422482413793104 ], [ 29.777547974683543, -1.422212931034483 ], [ 29.777278481012658, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7147, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.422212931034483 ], [ 29.777547974683543, -1.422482413793104 ], [ 29.777817468354431, -1.422482413793104 ], [ 29.777817468354431, -1.422212931034483 ], [ 29.777547974683543, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7148, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.422212931034483 ], [ 29.778086962025316, -1.422482413793104 ], [ 29.778356455696201, -1.422482413793104 ], [ 29.778356455696201, -1.422212931034483 ], [ 29.778086962025316, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7149, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.422212931034483 ], [ 29.778356455696201, -1.422482413793104 ], [ 29.77862594936709, -1.422482413793104 ], [ 29.77862594936709, -1.422212931034483 ], [ 29.778356455696201, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7150, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.422212931034483 ], [ 29.77862594936709, -1.422751896551724 ], [ 29.778895443037975, -1.422751896551724 ], [ 29.778895443037975, -1.422212931034483 ], [ 29.77862594936709, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7151, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.422212931034483 ], [ 29.778895443037975, -1.422482413793104 ], [ 29.77916493670886, -1.422482413793104 ], [ 29.77916493670886, -1.422212931034483 ], [ 29.778895443037975, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7152, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.422212931034483 ], [ 29.77916493670886, -1.422482413793104 ], [ 29.779434430379748, -1.422482413793104 ], [ 29.779434430379748, -1.422212931034483 ], [ 29.77916493670886, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7153, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.422212931034483 ], [ 29.779434430379748, -1.422482413793104 ], [ 29.779703924050633, -1.422482413793104 ], [ 29.779703924050633, -1.422212931034483 ], [ 29.779434430379748, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7154, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.422212931034483 ], [ 29.779973417721518, -1.422482413793104 ], [ 29.780242911392406, -1.422482413793104 ], [ 29.780242911392406, -1.422212931034483 ], [ 29.779973417721518, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7155, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.422212931034483 ], [ 29.780242911392406, -1.422482413793104 ], [ 29.780512405063291, -1.422482413793104 ], [ 29.780512405063291, -1.422212931034483 ], [ 29.780242911392406, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7156, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.422212931034483 ], [ 29.780512405063291, -1.422482413793104 ], [ 29.780781898734176, -1.422482413793104 ], [ 29.780781898734176, -1.422212931034483 ], [ 29.780512405063291, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7157, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.422212931034483 ], [ 29.780781898734176, -1.422482413793104 ], [ 29.781051392405065, -1.422482413793104 ], [ 29.781051392405065, -1.422212931034483 ], [ 29.780781898734176, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7158, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.422212931034483 ], [ 29.781051392405065, -1.422482413793104 ], [ 29.78132088607595, -1.422482413793104 ], [ 29.78132088607595, -1.422212931034483 ], [ 29.781051392405065, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7159, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.422212931034483 ], [ 29.78132088607595, -1.422482413793104 ], [ 29.781590379746834, -1.422482413793104 ], [ 29.781590379746834, -1.422212931034483 ], [ 29.78132088607595, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7160, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.422212931034483 ], [ 29.781590379746834, -1.422482413793104 ], [ 29.781859873417723, -1.422482413793104 ], [ 29.781859873417723, -1.422212931034483 ], [ 29.781590379746834, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7161, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.422212931034483 ], [ 29.781859873417723, -1.422482413793104 ], [ 29.782129367088608, -1.422482413793104 ], [ 29.782129367088608, -1.422212931034483 ], [ 29.781859873417723, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7162, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.422212931034483 ], [ 29.782129367088608, -1.422482413793104 ], [ 29.782398860759493, -1.422482413793104 ], [ 29.782398860759493, -1.422212931034483 ], [ 29.782129367088608, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7163, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.422212931034483 ], [ 29.782398860759493, -1.422482413793104 ], [ 29.782668354430381, -1.422482413793104 ], [ 29.782668354430381, -1.422212931034483 ], [ 29.782398860759493, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7164, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.422212931034483 ], [ 29.782668354430381, -1.422482413793104 ], [ 29.782937848101266, -1.422482413793104 ], [ 29.782937848101266, -1.422212931034483 ], [ 29.782668354430381, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7165, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.422212931034483 ], [ 29.782937848101266, -1.422482413793104 ], [ 29.783207341772151, -1.422482413793104 ], [ 29.783207341772151, -1.422212931034483 ], [ 29.782937848101266, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7166, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.422212931034483 ], [ 29.783207341772151, -1.422482413793104 ], [ 29.783476835443039, -1.422482413793104 ], [ 29.783476835443039, -1.422212931034483 ], [ 29.783207341772151, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7167, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.422212931034483 ], [ 29.783476835443039, -1.422482413793104 ], [ 29.784015822784809, -1.422482413793104 ], [ 29.784015822784809, -1.422212931034483 ], [ 29.783476835443039, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7168, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.422212931034483 ], [ 29.784015822784809, -1.423560344827586 ], [ 29.784285316455698, -1.423560344827586 ], [ 29.784285316455698, -1.422212931034483 ], [ 29.784015822784809, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7169, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.422212931034483 ], [ 29.784285316455698, -1.423829827586207 ], [ 29.784554810126583, -1.423829827586207 ], [ 29.784554810126583, -1.422212931034483 ], [ 29.784285316455698, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7170, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.422212931034483 ], [ 29.784554810126583, -1.423829827586207 ], [ 29.784824303797468, -1.423829827586207 ], [ 29.784824303797468, -1.422212931034483 ], [ 29.784554810126583, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7171, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.422212931034483 ], [ 29.789675189873417, -1.422482413793104 ], [ 29.790753164556961, -1.422482413793104 ], [ 29.790753164556961, -1.422212931034483 ], [ 29.789675189873417, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7172, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.422212931034483 ], [ 29.790753164556961, -1.422482413793104 ], [ 29.791022658227849, -1.422482413793104 ], [ 29.791022658227849, -1.422212931034483 ], [ 29.790753164556961, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7173, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.422212931034483 ], [ 29.791022658227849, -1.422482413793104 ], [ 29.791292151898734, -1.422482413793104 ], [ 29.791292151898734, -1.422212931034483 ], [ 29.791022658227849, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7174, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.422212931034483 ], [ 29.800185443037975, -1.423290862068965 ], [ 29.80045493670886, -1.423290862068965 ], [ 29.80045493670886, -1.422212931034483 ], [ 29.800185443037975, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7175, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.422212931034483 ], [ 29.80045493670886, -1.423021379310345 ], [ 29.800724430379748, -1.423021379310345 ], [ 29.800724430379748, -1.422212931034483 ], [ 29.80045493670886, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7176, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.422212931034483 ], [ 29.800724430379748, -1.423021379310345 ], [ 29.800993924050633, -1.423021379310345 ], [ 29.800993924050633, -1.422212931034483 ], [ 29.800724430379748, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7177, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.422212931034483 ], [ 29.801532911392407, -1.422751896551724 ], [ 29.802071898734177, -1.422751896551724 ], [ 29.802071898734177, -1.422212931034483 ], [ 29.801532911392407, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7178, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.422212931034483 ], [ 29.802880379746835, -1.422482413793104 ], [ 29.803149873417723, -1.422482413793104 ], [ 29.803149873417723, -1.422212931034483 ], [ 29.802880379746835, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7179, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.422212931034483 ], [ 29.803149873417723, -1.422482413793104 ], [ 29.803419367088608, -1.422482413793104 ], [ 29.803419367088608, -1.422212931034483 ], [ 29.803149873417723, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7180, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.422212931034483 ], [ 29.803419367088608, -1.422482413793104 ], [ 29.803688860759493, -1.422482413793104 ], [ 29.803688860759493, -1.422212931034483 ], [ 29.803419367088608, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7181, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.422212931034483 ], [ 29.803688860759493, -1.422482413793104 ], [ 29.803958354430382, -1.422482413793104 ], [ 29.803958354430382, -1.422212931034483 ], [ 29.803688860759493, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7182, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.422212931034483 ], [ 29.803958354430382, -1.422482413793104 ], [ 29.804227848101267, -1.422482413793104 ], [ 29.804227848101267, -1.422212931034483 ], [ 29.803958354430382, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7183, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.422212931034483 ], [ 29.804227848101267, -1.422482413793104 ], [ 29.804497341772151, -1.422482413793104 ], [ 29.804497341772151, -1.422212931034483 ], [ 29.804227848101267, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7184, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.422212931034483 ], [ 29.804497341772151, -1.422482413793104 ], [ 29.80476683544304, -1.422482413793104 ], [ 29.80476683544304, -1.422212931034483 ], [ 29.804497341772151, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7185, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.422212931034483 ], [ 29.80476683544304, -1.422482413793104 ], [ 29.805036329113925, -1.422482413793104 ], [ 29.805036329113925, -1.422212931034483 ], [ 29.80476683544304, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7186, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.422212931034483 ], [ 29.805036329113925, -1.422482413793104 ], [ 29.80530582278481, -1.422482413793104 ], [ 29.80530582278481, -1.422212931034483 ], [ 29.805036329113925, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7187, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.422212931034483 ], [ 29.80530582278481, -1.422482413793104 ], [ 29.805575316455698, -1.422482413793104 ], [ 29.805575316455698, -1.422212931034483 ], [ 29.80530582278481, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7188, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.422212931034483 ], [ 29.805575316455698, -1.422482413793104 ], [ 29.805844810126583, -1.422482413793104 ], [ 29.805844810126583, -1.422212931034483 ], [ 29.805575316455698, -1.422212931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7189, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.422482413793104 ], [ 29.757335949367089, -1.423021379310345 ], [ 29.757605443037974, -1.423021379310345 ], [ 29.757605443037974, -1.422482413793104 ], [ 29.757335949367089, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7190, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.422482413793104 ], [ 29.757605443037974, -1.422751896551724 ], [ 29.757874936708859, -1.422751896551724 ], [ 29.757874936708859, -1.422482413793104 ], [ 29.757605443037974, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7191, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.422482413793104 ], [ 29.757874936708859, -1.422751896551724 ], [ 29.758144430379748, -1.422751896551724 ], [ 29.758144430379748, -1.422482413793104 ], [ 29.757874936708859, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7192, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.422482413793104 ], [ 29.758144430379748, -1.422751896551724 ], [ 29.758413924050632, -1.422751896551724 ], [ 29.758413924050632, -1.422482413793104 ], [ 29.758144430379748, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7193, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.422482413793104 ], [ 29.758413924050632, -1.422751896551724 ], [ 29.758683417721517, -1.422751896551724 ], [ 29.758683417721517, -1.422482413793104 ], [ 29.758413924050632, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7194, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.422482413793104 ], [ 29.758683417721517, -1.422751896551724 ], [ 29.758952911392406, -1.422751896551724 ], [ 29.758952911392406, -1.422482413793104 ], [ 29.758683417721517, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7195, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.422482413793104 ], [ 29.758952911392406, -1.422751896551724 ], [ 29.759222405063291, -1.422751896551724 ], [ 29.759222405063291, -1.422482413793104 ], [ 29.758952911392406, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7196, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.422482413793104 ], [ 29.759222405063291, -1.422751896551724 ], [ 29.759491898734176, -1.422751896551724 ], [ 29.759491898734176, -1.422482413793104 ], [ 29.759222405063291, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7197, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.422482413793104 ], [ 29.759491898734176, -1.422751896551724 ], [ 29.759761392405064, -1.422751896551724 ], [ 29.759761392405064, -1.422482413793104 ], [ 29.759491898734176, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7198, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.422482413793104 ], [ 29.759761392405064, -1.422751896551724 ], [ 29.760030886075949, -1.422751896551724 ], [ 29.760030886075949, -1.422482413793104 ], [ 29.759761392405064, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7199, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.422482413793104 ], [ 29.760030886075949, -1.422751896551724 ], [ 29.760300379746834, -1.422751896551724 ], [ 29.760300379746834, -1.422482413793104 ], [ 29.760030886075949, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7200, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.422482413793104 ], [ 29.760569873417722, -1.422751896551724 ], [ 29.760839367088607, -1.422751896551724 ], [ 29.760839367088607, -1.422482413793104 ], [ 29.760569873417722, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7201, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.422482413793104 ], [ 29.760839367088607, -1.422751896551724 ], [ 29.761108860759492, -1.422751896551724 ], [ 29.761108860759492, -1.422482413793104 ], [ 29.760839367088607, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7202, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.422482413793104 ], [ 29.761378354430381, -1.423021379310345 ], [ 29.761647848101266, -1.423021379310345 ], [ 29.761647848101266, -1.422482413793104 ], [ 29.761378354430381, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7203, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.422482413793104 ], [ 29.761647848101266, -1.422751896551724 ], [ 29.761917341772151, -1.422751896551724 ], [ 29.761917341772151, -1.422482413793104 ], [ 29.761647848101266, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7204, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.422482413793104 ], [ 29.762186835443039, -1.423021379310345 ], [ 29.762456329113924, -1.423021379310345 ], [ 29.762456329113924, -1.422482413793104 ], [ 29.762186835443039, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7205, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.422482413793104 ], [ 29.762995316455697, -1.423021379310345 ], [ 29.763264810126582, -1.423021379310345 ], [ 29.763264810126582, -1.422482413793104 ], [ 29.762995316455697, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7206, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.422482413793104 ], [ 29.763803797468356, -1.423021379310345 ], [ 29.76407329113924, -1.423021379310345 ], [ 29.76407329113924, -1.422482413793104 ], [ 29.763803797468356, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7207, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.422482413793104 ], [ 29.764342784810125, -1.423021379310345 ], [ 29.764612278481014, -1.423021379310345 ], [ 29.764612278481014, -1.422482413793104 ], [ 29.764342784810125, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7208, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.422482413793104 ], [ 29.764612278481014, -1.423021379310345 ], [ 29.764881772151899, -1.423021379310345 ], [ 29.764881772151899, -1.422482413793104 ], [ 29.764612278481014, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7209, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.422482413793104 ], [ 29.765151265822784, -1.423021379310345 ], [ 29.765420759493672, -1.423021379310345 ], [ 29.765420759493672, -1.422482413793104 ], [ 29.765151265822784, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7210, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.422482413793104 ], [ 29.765420759493672, -1.422751896551724 ], [ 29.765959746835442, -1.422751896551724 ], [ 29.765959746835442, -1.422482413793104 ], [ 29.765420759493672, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7211, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.422482413793104 ], [ 29.765959746835442, -1.422751896551724 ], [ 29.766229240506327, -1.422751896551724 ], [ 29.766229240506327, -1.422482413793104 ], [ 29.765959746835442, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7212, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.422482413793104 ], [ 29.766229240506327, -1.423021379310345 ], [ 29.766498734177215, -1.423021379310345 ], [ 29.766498734177215, -1.422482413793104 ], [ 29.766229240506327, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7213, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.422482413793104 ], [ 29.766498734177215, -1.422751896551724 ], [ 29.7667682278481, -1.422751896551724 ], [ 29.7667682278481, -1.422482413793104 ], [ 29.766498734177215, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7214, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.422482413793104 ], [ 29.7667682278481, -1.422751896551724 ], [ 29.767037721518985, -1.422751896551724 ], [ 29.767037721518985, -1.422482413793104 ], [ 29.7667682278481, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7215, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.422482413793104 ], [ 29.767307215189874, -1.422751896551724 ], [ 29.767576708860759, -1.422751896551724 ], [ 29.767576708860759, -1.422482413793104 ], [ 29.767307215189874, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7216, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.422482413793104 ], [ 29.767576708860759, -1.422751896551724 ], [ 29.767846202531643, -1.422751896551724 ], [ 29.767846202531643, -1.422482413793104 ], [ 29.767576708860759, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7217, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.422482413793104 ], [ 29.768115696202532, -1.423021379310345 ], [ 29.768385189873417, -1.423021379310345 ], [ 29.768385189873417, -1.422482413793104 ], [ 29.768115696202532, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7218, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.422482413793104 ], [ 29.768385189873417, -1.422751896551724 ], [ 29.768654683544302, -1.422751896551724 ], [ 29.768654683544302, -1.422482413793104 ], [ 29.768385189873417, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7219, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.422482413793104 ], [ 29.768654683544302, -1.422751896551724 ], [ 29.76892417721519, -1.422751896551724 ], [ 29.76892417721519, -1.422482413793104 ], [ 29.768654683544302, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7220, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.422482413793104 ], [ 29.76892417721519, -1.422751896551724 ], [ 29.769193670886075, -1.422751896551724 ], [ 29.769193670886075, -1.422482413793104 ], [ 29.76892417721519, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7221, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.422482413793104 ], [ 29.769193670886075, -1.422751896551724 ], [ 29.76946316455696, -1.422751896551724 ], [ 29.76946316455696, -1.422482413793104 ], [ 29.769193670886075, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7222, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.422482413793104 ], [ 29.76946316455696, -1.422751896551724 ], [ 29.769732658227849, -1.422751896551724 ], [ 29.769732658227849, -1.422482413793104 ], [ 29.76946316455696, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7223, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.422482413793104 ], [ 29.769732658227849, -1.422751896551724 ], [ 29.770002151898733, -1.422751896551724 ], [ 29.770002151898733, -1.422482413793104 ], [ 29.769732658227849, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7224, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.422482413793104 ], [ 29.770002151898733, -1.422751896551724 ], [ 29.770271645569618, -1.422751896551724 ], [ 29.770271645569618, -1.422482413793104 ], [ 29.770002151898733, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7225, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.422482413793104 ], [ 29.770271645569618, -1.422751896551724 ], [ 29.770541139240507, -1.422751896551724 ], [ 29.770541139240507, -1.422482413793104 ], [ 29.770271645569618, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7226, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.422482413793104 ], [ 29.770541139240507, -1.422751896551724 ], [ 29.770810632911392, -1.422751896551724 ], [ 29.770810632911392, -1.422482413793104 ], [ 29.770541139240507, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7227, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.422482413793104 ], [ 29.770810632911392, -1.422751896551724 ], [ 29.771080126582277, -1.422751896551724 ], [ 29.771080126582277, -1.422482413793104 ], [ 29.770810632911392, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7228, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.422482413793104 ], [ 29.771080126582277, -1.422751896551724 ], [ 29.771349620253165, -1.422751896551724 ], [ 29.771349620253165, -1.422482413793104 ], [ 29.771080126582277, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7229, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.422482413793104 ], [ 29.771349620253165, -1.422751896551724 ], [ 29.77161911392405, -1.422751896551724 ], [ 29.77161911392405, -1.422482413793104 ], [ 29.771349620253165, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7230, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.422482413793104 ], [ 29.77161911392405, -1.422751896551724 ], [ 29.771888607594935, -1.422751896551724 ], [ 29.771888607594935, -1.422482413793104 ], [ 29.77161911392405, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7231, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.422482413793104 ], [ 29.772158101265823, -1.423021379310345 ], [ 29.772427594936708, -1.423021379310345 ], [ 29.772427594936708, -1.422482413793104 ], [ 29.772158101265823, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7232, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.422482413793104 ], [ 29.772966582278482, -1.423021379310345 ], [ 29.773236075949367, -1.423021379310345 ], [ 29.773236075949367, -1.422482413793104 ], [ 29.772966582278482, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7233, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.422482413793104 ], [ 29.773505569620252, -1.422751896551724 ], [ 29.77377506329114, -1.422751896551724 ], [ 29.77377506329114, -1.422482413793104 ], [ 29.773505569620252, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7234, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.422482413793104 ], [ 29.77377506329114, -1.423021379310345 ], [ 29.774044556962025, -1.423021379310345 ], [ 29.774044556962025, -1.422482413793104 ], [ 29.77377506329114, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7235, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.422482413793104 ], [ 29.77431405063291, -1.422751896551724 ], [ 29.774853037974683, -1.422751896551724 ], [ 29.774853037974683, -1.422482413793104 ], [ 29.77431405063291, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7236, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.422482413793104 ], [ 29.774853037974683, -1.423021379310345 ], [ 29.775122531645568, -1.423021379310345 ], [ 29.775122531645568, -1.422482413793104 ], [ 29.774853037974683, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7237, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.422482413793104 ], [ 29.775392025316457, -1.422751896551724 ], [ 29.775661518987341, -1.422751896551724 ], [ 29.775661518987341, -1.422482413793104 ], [ 29.775392025316457, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7238, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.422482413793104 ], [ 29.775661518987341, -1.423021379310345 ], [ 29.775931012658226, -1.423021379310345 ], [ 29.775931012658226, -1.422482413793104 ], [ 29.775661518987341, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7239, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.422482413793104 ], [ 29.776200506329115, -1.422751896551724 ], [ 29.77647, -1.422751896551724 ], [ 29.77647, -1.422482413793104 ], [ 29.776200506329115, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7240, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.422482413793104 ], [ 29.77647, -1.422751896551724 ], [ 29.776739493670885, -1.422751896551724 ], [ 29.776739493670885, -1.422482413793104 ], [ 29.77647, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7241, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.422482413793104 ], [ 29.777008987341773, -1.422751896551724 ], [ 29.777278481012658, -1.422751896551724 ], [ 29.777278481012658, -1.422482413793104 ], [ 29.777008987341773, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7242, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.422482413793104 ], [ 29.777278481012658, -1.422751896551724 ], [ 29.777547974683543, -1.422751896551724 ], [ 29.777547974683543, -1.422482413793104 ], [ 29.777278481012658, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7243, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.422482413793104 ], [ 29.777547974683543, -1.423021379310345 ], [ 29.777817468354431, -1.423021379310345 ], [ 29.777817468354431, -1.422482413793104 ], [ 29.777547974683543, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7244, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.422482413793104 ], [ 29.777817468354431, -1.422751896551724 ], [ 29.778086962025316, -1.422751896551724 ], [ 29.778086962025316, -1.422482413793104 ], [ 29.777817468354431, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7245, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.422482413793104 ], [ 29.778086962025316, -1.422751896551724 ], [ 29.778356455696201, -1.422751896551724 ], [ 29.778356455696201, -1.422482413793104 ], [ 29.778086962025316, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7246, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.422482413793104 ], [ 29.778356455696201, -1.422751896551724 ], [ 29.77862594936709, -1.422751896551724 ], [ 29.77862594936709, -1.422482413793104 ], [ 29.778356455696201, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7247, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.422482413793104 ], [ 29.778895443037975, -1.422751896551724 ], [ 29.77916493670886, -1.422751896551724 ], [ 29.77916493670886, -1.422482413793104 ], [ 29.778895443037975, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7248, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.422482413793104 ], [ 29.77916493670886, -1.422751896551724 ], [ 29.779434430379748, -1.422751896551724 ], [ 29.779434430379748, -1.422482413793104 ], [ 29.77916493670886, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7249, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.422482413793104 ], [ 29.779434430379748, -1.422751896551724 ], [ 29.779703924050633, -1.422751896551724 ], [ 29.779703924050633, -1.422482413793104 ], [ 29.779434430379748, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7250, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.422482413793104 ], [ 29.779703924050633, -1.422751896551724 ], [ 29.779973417721518, -1.422751896551724 ], [ 29.779973417721518, -1.422482413793104 ], [ 29.779703924050633, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7251, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.422482413793104 ], [ 29.779973417721518, -1.422751896551724 ], [ 29.780242911392406, -1.422751896551724 ], [ 29.780242911392406, -1.422482413793104 ], [ 29.779973417721518, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7252, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.422482413793104 ], [ 29.780242911392406, -1.422751896551724 ], [ 29.780512405063291, -1.422751896551724 ], [ 29.780512405063291, -1.422482413793104 ], [ 29.780242911392406, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7253, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.422482413793104 ], [ 29.780512405063291, -1.422751896551724 ], [ 29.780781898734176, -1.422751896551724 ], [ 29.780781898734176, -1.422482413793104 ], [ 29.780512405063291, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7254, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.422482413793104 ], [ 29.780781898734176, -1.422751896551724 ], [ 29.781051392405065, -1.422751896551724 ], [ 29.781051392405065, -1.422482413793104 ], [ 29.780781898734176, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7255, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.422482413793104 ], [ 29.781051392405065, -1.422751896551724 ], [ 29.78132088607595, -1.422751896551724 ], [ 29.78132088607595, -1.422482413793104 ], [ 29.781051392405065, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7256, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.422482413793104 ], [ 29.78132088607595, -1.422751896551724 ], [ 29.781590379746834, -1.422751896551724 ], [ 29.781590379746834, -1.422482413793104 ], [ 29.78132088607595, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7257, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.422482413793104 ], [ 29.781590379746834, -1.422751896551724 ], [ 29.781859873417723, -1.422751896551724 ], [ 29.781859873417723, -1.422482413793104 ], [ 29.781590379746834, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7258, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.422482413793104 ], [ 29.781859873417723, -1.422751896551724 ], [ 29.782129367088608, -1.422751896551724 ], [ 29.782129367088608, -1.422482413793104 ], [ 29.781859873417723, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7259, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.422482413793104 ], [ 29.782129367088608, -1.422751896551724 ], [ 29.782398860759493, -1.422751896551724 ], [ 29.782398860759493, -1.422482413793104 ], [ 29.782129367088608, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7260, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.422482413793104 ], [ 29.782398860759493, -1.422751896551724 ], [ 29.782668354430381, -1.422751896551724 ], [ 29.782668354430381, -1.422482413793104 ], [ 29.782398860759493, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7261, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.422482413793104 ], [ 29.782668354430381, -1.422751896551724 ], [ 29.782937848101266, -1.422751896551724 ], [ 29.782937848101266, -1.422482413793104 ], [ 29.782668354430381, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7262, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.422482413793104 ], [ 29.782937848101266, -1.422751896551724 ], [ 29.783207341772151, -1.422751896551724 ], [ 29.783207341772151, -1.422482413793104 ], [ 29.782937848101266, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7263, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.422482413793104 ], [ 29.783207341772151, -1.423829827586207 ], [ 29.783476835443039, -1.423829827586207 ], [ 29.783476835443039, -1.422482413793104 ], [ 29.783207341772151, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7264, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.422482413793104 ], [ 29.783476835443039, -1.423829827586207 ], [ 29.784015822784809, -1.423829827586207 ], [ 29.784015822784809, -1.422482413793104 ], [ 29.783476835443039, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7265, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.422482413793104 ], [ 29.789136202531644, -1.423021379310345 ], [ 29.789405696202532, -1.423021379310345 ], [ 29.789405696202532, -1.422482413793104 ], [ 29.789136202531644, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7266, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.422482413793104 ], [ 29.789405696202532, -1.422751896551724 ], [ 29.789675189873417, -1.422751896551724 ], [ 29.789675189873417, -1.422482413793104 ], [ 29.789405696202532, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7267, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.422482413793104 ], [ 29.789675189873417, -1.422751896551724 ], [ 29.789944683544302, -1.422751896551724 ], [ 29.789944683544302, -1.422482413793104 ], [ 29.789675189873417, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7268, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.422482413793104 ], [ 29.789944683544302, -1.422751896551724 ], [ 29.790753164556961, -1.422751896551724 ], [ 29.790753164556961, -1.422482413793104 ], [ 29.789944683544302, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7269, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.422482413793104 ], [ 29.790753164556961, -1.422751896551724 ], [ 29.791022658227849, -1.422751896551724 ], [ 29.791022658227849, -1.422482413793104 ], [ 29.790753164556961, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7270, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.422482413793104 ], [ 29.791022658227849, -1.422751896551724 ], [ 29.791292151898734, -1.422751896551724 ], [ 29.791292151898734, -1.422482413793104 ], [ 29.791022658227849, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7271, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.422482413793104 ], [ 29.791292151898734, -1.422751896551724 ], [ 29.791561645569619, -1.422751896551724 ], [ 29.791561645569619, -1.422482413793104 ], [ 29.791292151898734, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7272, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.422482413793104 ], [ 29.802071898734177, -1.422751896551724 ], [ 29.802341392405065, -1.422751896551724 ], [ 29.802341392405065, -1.422482413793104 ], [ 29.802071898734177, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7273, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.422482413793104 ], [ 29.802341392405065, -1.422751896551724 ], [ 29.80261088607595, -1.422751896551724 ], [ 29.80261088607595, -1.422482413793104 ], [ 29.802341392405065, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.422482413793104 ], [ 29.80261088607595, -1.422751896551724 ], [ 29.802880379746835, -1.422751896551724 ], [ 29.802880379746835, -1.422482413793104 ], [ 29.80261088607595, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7275, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.422482413793104 ], [ 29.802880379746835, -1.422751896551724 ], [ 29.803149873417723, -1.422751896551724 ], [ 29.803149873417723, -1.422482413793104 ], [ 29.802880379746835, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7276, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.422482413793104 ], [ 29.803149873417723, -1.422751896551724 ], [ 29.803419367088608, -1.422751896551724 ], [ 29.803419367088608, -1.422482413793104 ], [ 29.803149873417723, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7277, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.422482413793104 ], [ 29.803419367088608, -1.422751896551724 ], [ 29.803688860759493, -1.422751896551724 ], [ 29.803688860759493, -1.422482413793104 ], [ 29.803419367088608, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7278, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.422482413793104 ], [ 29.803688860759493, -1.422751896551724 ], [ 29.803958354430382, -1.422751896551724 ], [ 29.803958354430382, -1.422482413793104 ], [ 29.803688860759493, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7279, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.422482413793104 ], [ 29.803958354430382, -1.422751896551724 ], [ 29.804227848101267, -1.422751896551724 ], [ 29.804227848101267, -1.422482413793104 ], [ 29.803958354430382, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7280, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.422482413793104 ], [ 29.804227848101267, -1.422751896551724 ], [ 29.804497341772151, -1.422751896551724 ], [ 29.804497341772151, -1.422482413793104 ], [ 29.804227848101267, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7281, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.422482413793104 ], [ 29.804497341772151, -1.422751896551724 ], [ 29.80476683544304, -1.422751896551724 ], [ 29.80476683544304, -1.422482413793104 ], [ 29.804497341772151, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7282, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.422482413793104 ], [ 29.80476683544304, -1.422751896551724 ], [ 29.805036329113925, -1.422751896551724 ], [ 29.805036329113925, -1.422482413793104 ], [ 29.80476683544304, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7283, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.422482413793104 ], [ 29.805036329113925, -1.422751896551724 ], [ 29.80530582278481, -1.422751896551724 ], [ 29.80530582278481, -1.422482413793104 ], [ 29.805036329113925, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7284, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.422482413793104 ], [ 29.80530582278481, -1.422751896551724 ], [ 29.805575316455698, -1.422751896551724 ], [ 29.805575316455698, -1.422482413793104 ], [ 29.80530582278481, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7285, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.422482413793104 ], [ 29.805575316455698, -1.422751896551724 ], [ 29.805844810126583, -1.422751896551724 ], [ 29.805844810126583, -1.422482413793104 ], [ 29.805575316455698, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7286, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.422482413793104 ], [ 29.805844810126583, -1.422751896551724 ], [ 29.806114303797468, -1.422751896551724 ], [ 29.806114303797468, -1.422482413793104 ], [ 29.805844810126583, -1.422482413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7287, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.422751896551724 ], [ 29.757605443037974, -1.423021379310345 ], [ 29.757874936708859, -1.423021379310345 ], [ 29.757874936708859, -1.422751896551724 ], [ 29.757605443037974, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7288, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.422751896551724 ], [ 29.757874936708859, -1.423021379310345 ], [ 29.758144430379748, -1.423021379310345 ], [ 29.758144430379748, -1.422751896551724 ], [ 29.757874936708859, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7289, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.422751896551724 ], [ 29.758144430379748, -1.423021379310345 ], [ 29.758413924050632, -1.423021379310345 ], [ 29.758413924050632, -1.422751896551724 ], [ 29.758144430379748, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7290, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.422751896551724 ], [ 29.758413924050632, -1.423021379310345 ], [ 29.758683417721517, -1.423021379310345 ], [ 29.758683417721517, -1.422751896551724 ], [ 29.758413924050632, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7291, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.422751896551724 ], [ 29.758683417721517, -1.423021379310345 ], [ 29.758952911392406, -1.423021379310345 ], [ 29.758952911392406, -1.422751896551724 ], [ 29.758683417721517, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7292, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.422751896551724 ], [ 29.758952911392406, -1.423021379310345 ], [ 29.759222405063291, -1.423021379310345 ], [ 29.759222405063291, -1.422751896551724 ], [ 29.758952911392406, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7293, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.422751896551724 ], [ 29.759222405063291, -1.423021379310345 ], [ 29.759491898734176, -1.423021379310345 ], [ 29.759491898734176, -1.422751896551724 ], [ 29.759222405063291, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7294, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.422751896551724 ], [ 29.759491898734176, -1.423021379310345 ], [ 29.759761392405064, -1.423021379310345 ], [ 29.759761392405064, -1.422751896551724 ], [ 29.759491898734176, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7295, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.422751896551724 ], [ 29.759761392405064, -1.423021379310345 ], [ 29.760030886075949, -1.423021379310345 ], [ 29.760030886075949, -1.422751896551724 ], [ 29.759761392405064, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7296, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.422751896551724 ], [ 29.760030886075949, -1.423021379310345 ], [ 29.760300379746834, -1.423021379310345 ], [ 29.760300379746834, -1.422751896551724 ], [ 29.760030886075949, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7297, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.422751896551724 ], [ 29.760300379746834, -1.423021379310345 ], [ 29.760569873417722, -1.423021379310345 ], [ 29.760569873417722, -1.422751896551724 ], [ 29.760300379746834, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7298, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.422751896551724 ], [ 29.760569873417722, -1.423021379310345 ], [ 29.760839367088607, -1.423021379310345 ], [ 29.760839367088607, -1.422751896551724 ], [ 29.760569873417722, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7299, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.422751896551724 ], [ 29.760839367088607, -1.423021379310345 ], [ 29.761108860759492, -1.423021379310345 ], [ 29.761108860759492, -1.422751896551724 ], [ 29.760839367088607, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7300, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.422751896551724 ], [ 29.761108860759492, -1.423021379310345 ], [ 29.761378354430381, -1.423021379310345 ], [ 29.761378354430381, -1.422751896551724 ], [ 29.761108860759492, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7301, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.422751896551724 ], [ 29.761647848101266, -1.423021379310345 ], [ 29.761917341772151, -1.423021379310345 ], [ 29.761917341772151, -1.422751896551724 ], [ 29.761647848101266, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7302, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.422751896551724 ], [ 29.761917341772151, -1.423021379310345 ], [ 29.762186835443039, -1.423021379310345 ], [ 29.762186835443039, -1.422751896551724 ], [ 29.761917341772151, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7303, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.422751896551724 ], [ 29.762456329113924, -1.423021379310345 ], [ 29.762725822784809, -1.423021379310345 ], [ 29.762725822784809, -1.422751896551724 ], [ 29.762456329113924, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7304, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.422751896551724 ], [ 29.762725822784809, -1.423021379310345 ], [ 29.762995316455697, -1.423021379310345 ], [ 29.762995316455697, -1.422751896551724 ], [ 29.762725822784809, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7305, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.422751896551724 ], [ 29.763264810126582, -1.423290862068965 ], [ 29.763534303797467, -1.423290862068965 ], [ 29.763534303797467, -1.422751896551724 ], [ 29.763264810126582, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7306, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.422751896551724 ], [ 29.763534303797467, -1.423021379310345 ], [ 29.763803797468356, -1.423021379310345 ], [ 29.763803797468356, -1.422751896551724 ], [ 29.763534303797467, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7307, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.422751896551724 ], [ 29.76407329113924, -1.423290862068965 ], [ 29.764342784810125, -1.423290862068965 ], [ 29.764342784810125, -1.422751896551724 ], [ 29.76407329113924, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7308, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.422751896551724 ], [ 29.764881772151899, -1.423290862068965 ], [ 29.765151265822784, -1.423290862068965 ], [ 29.765151265822784, -1.422751896551724 ], [ 29.764881772151899, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7309, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.422751896551724 ], [ 29.765420759493672, -1.423290862068965 ], [ 29.765959746835442, -1.423290862068965 ], [ 29.765959746835442, -1.422751896551724 ], [ 29.765420759493672, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7310, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.422751896551724 ], [ 29.765959746835442, -1.423290862068965 ], [ 29.766229240506327, -1.423290862068965 ], [ 29.766229240506327, -1.422751896551724 ], [ 29.765959746835442, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7311, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.422751896551724 ], [ 29.766498734177215, -1.423290862068965 ], [ 29.7667682278481, -1.423290862068965 ], [ 29.7667682278481, -1.422751896551724 ], [ 29.766498734177215, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7312, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.422751896551724 ], [ 29.7667682278481, -1.423290862068965 ], [ 29.767037721518985, -1.423290862068965 ], [ 29.767037721518985, -1.422751896551724 ], [ 29.7667682278481, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7313, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.422751896551724 ], [ 29.767037721518985, -1.423021379310345 ], [ 29.767307215189874, -1.423021379310345 ], [ 29.767307215189874, -1.422751896551724 ], [ 29.767037721518985, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7314, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.422751896551724 ], [ 29.767307215189874, -1.423290862068965 ], [ 29.767576708860759, -1.423290862068965 ], [ 29.767576708860759, -1.422751896551724 ], [ 29.767307215189874, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7315, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.422751896551724 ], [ 29.767576708860759, -1.423021379310345 ], [ 29.767846202531643, -1.423021379310345 ], [ 29.767846202531643, -1.422751896551724 ], [ 29.767576708860759, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7316, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.422751896551724 ], [ 29.767846202531643, -1.423021379310345 ], [ 29.768115696202532, -1.423021379310345 ], [ 29.768115696202532, -1.422751896551724 ], [ 29.767846202531643, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7317, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.422751896551724 ], [ 29.768385189873417, -1.423021379310345 ], [ 29.768654683544302, -1.423021379310345 ], [ 29.768654683544302, -1.422751896551724 ], [ 29.768385189873417, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7318, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.422751896551724 ], [ 29.768654683544302, -1.423021379310345 ], [ 29.76892417721519, -1.423021379310345 ], [ 29.76892417721519, -1.422751896551724 ], [ 29.768654683544302, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7319, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.422751896551724 ], [ 29.76892417721519, -1.423021379310345 ], [ 29.769193670886075, -1.423021379310345 ], [ 29.769193670886075, -1.422751896551724 ], [ 29.76892417721519, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7320, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.422751896551724 ], [ 29.769193670886075, -1.423021379310345 ], [ 29.76946316455696, -1.423021379310345 ], [ 29.76946316455696, -1.422751896551724 ], [ 29.769193670886075, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7321, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.422751896551724 ], [ 29.76946316455696, -1.423021379310345 ], [ 29.769732658227849, -1.423021379310345 ], [ 29.769732658227849, -1.422751896551724 ], [ 29.76946316455696, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7322, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.422751896551724 ], [ 29.769732658227849, -1.423021379310345 ], [ 29.770002151898733, -1.423021379310345 ], [ 29.770002151898733, -1.422751896551724 ], [ 29.769732658227849, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7323, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.422751896551724 ], [ 29.770002151898733, -1.423021379310345 ], [ 29.770271645569618, -1.423021379310345 ], [ 29.770271645569618, -1.422751896551724 ], [ 29.770002151898733, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7324, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.422751896551724 ], [ 29.770271645569618, -1.423021379310345 ], [ 29.770541139240507, -1.423021379310345 ], [ 29.770541139240507, -1.422751896551724 ], [ 29.770271645569618, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7325, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.422751896551724 ], [ 29.770541139240507, -1.423021379310345 ], [ 29.770810632911392, -1.423021379310345 ], [ 29.770810632911392, -1.422751896551724 ], [ 29.770541139240507, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7326, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.422751896551724 ], [ 29.770810632911392, -1.423021379310345 ], [ 29.771080126582277, -1.423021379310345 ], [ 29.771080126582277, -1.422751896551724 ], [ 29.770810632911392, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7327, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.422751896551724 ], [ 29.771080126582277, -1.423021379310345 ], [ 29.771349620253165, -1.423021379310345 ], [ 29.771349620253165, -1.422751896551724 ], [ 29.771080126582277, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7328, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.422751896551724 ], [ 29.771349620253165, -1.423021379310345 ], [ 29.77161911392405, -1.423021379310345 ], [ 29.77161911392405, -1.422751896551724 ], [ 29.771349620253165, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7329, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.422751896551724 ], [ 29.77161911392405, -1.423021379310345 ], [ 29.771888607594935, -1.423021379310345 ], [ 29.771888607594935, -1.422751896551724 ], [ 29.77161911392405, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7330, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.422751896551724 ], [ 29.771888607594935, -1.423290862068965 ], [ 29.772158101265823, -1.423290862068965 ], [ 29.772158101265823, -1.422751896551724 ], [ 29.771888607594935, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7331, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.422751896551724 ], [ 29.772427594936708, -1.423021379310345 ], [ 29.772697088607593, -1.423021379310345 ], [ 29.772697088607593, -1.422751896551724 ], [ 29.772427594936708, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7332, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.422751896551724 ], [ 29.772697088607593, -1.423290862068965 ], [ 29.772966582278482, -1.423290862068965 ], [ 29.772966582278482, -1.422751896551724 ], [ 29.772697088607593, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7333, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.422751896551724 ], [ 29.773236075949367, -1.423021379310345 ], [ 29.773505569620252, -1.423021379310345 ], [ 29.773505569620252, -1.422751896551724 ], [ 29.773236075949367, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7334, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.422751896551724 ], [ 29.773505569620252, -1.423290862068965 ], [ 29.77377506329114, -1.423290862068965 ], [ 29.77377506329114, -1.422751896551724 ], [ 29.773505569620252, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7335, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.422751896551724 ], [ 29.774044556962025, -1.423021379310345 ], [ 29.77431405063291, -1.423021379310345 ], [ 29.77431405063291, -1.422751896551724 ], [ 29.774044556962025, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7336, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.422751896551724 ], [ 29.77431405063291, -1.423290862068965 ], [ 29.774853037974683, -1.423290862068965 ], [ 29.774853037974683, -1.422751896551724 ], [ 29.77431405063291, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7337, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.422751896551724 ], [ 29.775122531645568, -1.423021379310345 ], [ 29.775392025316457, -1.423021379310345 ], [ 29.775392025316457, -1.422751896551724 ], [ 29.775122531645568, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7338, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.422751896551724 ], [ 29.775392025316457, -1.423021379310345 ], [ 29.775661518987341, -1.423021379310345 ], [ 29.775661518987341, -1.422751896551724 ], [ 29.775392025316457, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7339, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.422751896551724 ], [ 29.775931012658226, -1.423021379310345 ], [ 29.776200506329115, -1.423021379310345 ], [ 29.776200506329115, -1.422751896551724 ], [ 29.775931012658226, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7340, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.422751896551724 ], [ 29.776200506329115, -1.423021379310345 ], [ 29.77647, -1.423021379310345 ], [ 29.77647, -1.422751896551724 ], [ 29.776200506329115, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7341, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.422751896551724 ], [ 29.77647, -1.423290862068965 ], [ 29.776739493670885, -1.423290862068965 ], [ 29.776739493670885, -1.422751896551724 ], [ 29.77647, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7342, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.422751896551724 ], [ 29.776739493670885, -1.423021379310345 ], [ 29.777008987341773, -1.423021379310345 ], [ 29.777008987341773, -1.422751896551724 ], [ 29.776739493670885, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7343, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.422751896551724 ], [ 29.777008987341773, -1.423021379310345 ], [ 29.777278481012658, -1.423021379310345 ], [ 29.777278481012658, -1.422751896551724 ], [ 29.777008987341773, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7344, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.422751896551724 ], [ 29.777278481012658, -1.423021379310345 ], [ 29.777547974683543, -1.423021379310345 ], [ 29.777547974683543, -1.422751896551724 ], [ 29.777278481012658, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7345, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.422751896551724 ], [ 29.777817468354431, -1.423021379310345 ], [ 29.778086962025316, -1.423021379310345 ], [ 29.778086962025316, -1.422751896551724 ], [ 29.777817468354431, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7346, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.422751896551724 ], [ 29.778086962025316, -1.423021379310345 ], [ 29.778356455696201, -1.423021379310345 ], [ 29.778356455696201, -1.422751896551724 ], [ 29.778086962025316, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7347, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.422751896551724 ], [ 29.778356455696201, -1.423021379310345 ], [ 29.77862594936709, -1.423021379310345 ], [ 29.77862594936709, -1.422751896551724 ], [ 29.778356455696201, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7348, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.422751896551724 ], [ 29.77862594936709, -1.423021379310345 ], [ 29.778895443037975, -1.423021379310345 ], [ 29.778895443037975, -1.422751896551724 ], [ 29.77862594936709, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7349, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.422751896551724 ], [ 29.778895443037975, -1.423021379310345 ], [ 29.77916493670886, -1.423021379310345 ], [ 29.77916493670886, -1.422751896551724 ], [ 29.778895443037975, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7350, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.422751896551724 ], [ 29.77916493670886, -1.423021379310345 ], [ 29.779434430379748, -1.423021379310345 ], [ 29.779434430379748, -1.422751896551724 ], [ 29.77916493670886, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7351, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.422751896551724 ], [ 29.779434430379748, -1.423021379310345 ], [ 29.779703924050633, -1.423021379310345 ], [ 29.779703924050633, -1.422751896551724 ], [ 29.779434430379748, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7352, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.422751896551724 ], [ 29.779703924050633, -1.423021379310345 ], [ 29.779973417721518, -1.423021379310345 ], [ 29.779973417721518, -1.422751896551724 ], [ 29.779703924050633, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7353, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.422751896551724 ], [ 29.779973417721518, -1.423021379310345 ], [ 29.780242911392406, -1.423021379310345 ], [ 29.780242911392406, -1.422751896551724 ], [ 29.779973417721518, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7354, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.422751896551724 ], [ 29.780242911392406, -1.423021379310345 ], [ 29.780512405063291, -1.423021379310345 ], [ 29.780512405063291, -1.422751896551724 ], [ 29.780242911392406, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7355, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.422751896551724 ], [ 29.780512405063291, -1.423021379310345 ], [ 29.780781898734176, -1.423021379310345 ], [ 29.780781898734176, -1.422751896551724 ], [ 29.780512405063291, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7356, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.422751896551724 ], [ 29.780781898734176, -1.423021379310345 ], [ 29.781051392405065, -1.423021379310345 ], [ 29.781051392405065, -1.422751896551724 ], [ 29.780781898734176, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7357, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.422751896551724 ], [ 29.781051392405065, -1.423021379310345 ], [ 29.78132088607595, -1.423021379310345 ], [ 29.78132088607595, -1.422751896551724 ], [ 29.781051392405065, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7358, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.422751896551724 ], [ 29.78132088607595, -1.423021379310345 ], [ 29.781590379746834, -1.423021379310345 ], [ 29.781590379746834, -1.422751896551724 ], [ 29.78132088607595, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7359, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.422751896551724 ], [ 29.781590379746834, -1.423021379310345 ], [ 29.781859873417723, -1.423021379310345 ], [ 29.781859873417723, -1.422751896551724 ], [ 29.781590379746834, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7360, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.422751896551724 ], [ 29.781859873417723, -1.423829827586207 ], [ 29.782129367088608, -1.423829827586207 ], [ 29.782129367088608, -1.422751896551724 ], [ 29.781859873417723, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7361, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.422751896551724 ], [ 29.782129367088608, -1.424099310344828 ], [ 29.782398860759493, -1.424099310344828 ], [ 29.782398860759493, -1.422751896551724 ], [ 29.782129367088608, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7362, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.422751896551724 ], [ 29.782398860759493, -1.423560344827586 ], [ 29.782668354430381, -1.423560344827586 ], [ 29.782668354430381, -1.422751896551724 ], [ 29.782398860759493, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7363, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.422751896551724 ], [ 29.782668354430381, -1.423829827586207 ], [ 29.782937848101266, -1.423829827586207 ], [ 29.782937848101266, -1.422751896551724 ], [ 29.782668354430381, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7364, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.422751896551724 ], [ 29.782937848101266, -1.424099310344828 ], [ 29.783207341772151, -1.424099310344828 ], [ 29.783207341772151, -1.422751896551724 ], [ 29.782937848101266, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7365, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.422751896551724 ], [ 29.789405696202532, -1.423021379310345 ], [ 29.789675189873417, -1.423021379310345 ], [ 29.789675189873417, -1.422751896551724 ], [ 29.789405696202532, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7366, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.422751896551724 ], [ 29.789675189873417, -1.423021379310345 ], [ 29.789944683544302, -1.423021379310345 ], [ 29.789944683544302, -1.422751896551724 ], [ 29.789675189873417, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7367, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.422751896551724 ], [ 29.789944683544302, -1.423021379310345 ], [ 29.790214177215191, -1.423021379310345 ], [ 29.790214177215191, -1.422751896551724 ], [ 29.789944683544302, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7368, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.422751896551724 ], [ 29.790214177215191, -1.423021379310345 ], [ 29.790483670886076, -1.423021379310345 ], [ 29.790483670886076, -1.422751896551724 ], [ 29.790214177215191, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7369, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.422751896551724 ], [ 29.790483670886076, -1.423021379310345 ], [ 29.790753164556961, -1.423021379310345 ], [ 29.790753164556961, -1.422751896551724 ], [ 29.790483670886076, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7370, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.422751896551724 ], [ 29.790753164556961, -1.423021379310345 ], [ 29.791022658227849, -1.423021379310345 ], [ 29.791022658227849, -1.422751896551724 ], [ 29.790753164556961, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7371, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.422751896551724 ], [ 29.791022658227849, -1.423021379310345 ], [ 29.791292151898734, -1.423021379310345 ], [ 29.791292151898734, -1.422751896551724 ], [ 29.791022658227849, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7372, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.422751896551724 ], [ 29.791292151898734, -1.423021379310345 ], [ 29.791561645569619, -1.423021379310345 ], [ 29.791561645569619, -1.422751896551724 ], [ 29.791292151898734, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7373, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.422751896551724 ], [ 29.791561645569619, -1.423021379310345 ], [ 29.791831139240507, -1.423021379310345 ], [ 29.791831139240507, -1.422751896551724 ], [ 29.791561645569619, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7374, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.422751896551724 ], [ 29.800993924050633, -1.423021379310345 ], [ 29.801263417721518, -1.423021379310345 ], [ 29.801263417721518, -1.422751896551724 ], [ 29.800993924050633, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7375, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.422751896551724 ], [ 29.801263417721518, -1.423021379310345 ], [ 29.801532911392407, -1.423021379310345 ], [ 29.801532911392407, -1.422751896551724 ], [ 29.801263417721518, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7376, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.422751896551724 ], [ 29.801532911392407, -1.423021379310345 ], [ 29.802071898734177, -1.423021379310345 ], [ 29.802071898734177, -1.422751896551724 ], [ 29.801532911392407, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7377, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.422751896551724 ], [ 29.802071898734177, -1.423290862068965 ], [ 29.802341392405065, -1.423290862068965 ], [ 29.802341392405065, -1.422751896551724 ], [ 29.802071898734177, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7378, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.422751896551724 ], [ 29.802341392405065, -1.423021379310345 ], [ 29.80261088607595, -1.423021379310345 ], [ 29.80261088607595, -1.422751896551724 ], [ 29.802341392405065, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7379, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.422751896551724 ], [ 29.80261088607595, -1.423021379310345 ], [ 29.802880379746835, -1.423021379310345 ], [ 29.802880379746835, -1.422751896551724 ], [ 29.80261088607595, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7380, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.422751896551724 ], [ 29.802880379746835, -1.423021379310345 ], [ 29.803149873417723, -1.423021379310345 ], [ 29.803149873417723, -1.422751896551724 ], [ 29.802880379746835, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7381, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.422751896551724 ], [ 29.803149873417723, -1.423021379310345 ], [ 29.803419367088608, -1.423021379310345 ], [ 29.803419367088608, -1.422751896551724 ], [ 29.803149873417723, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7382, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.422751896551724 ], [ 29.803419367088608, -1.423021379310345 ], [ 29.803688860759493, -1.423021379310345 ], [ 29.803688860759493, -1.422751896551724 ], [ 29.803419367088608, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7383, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.422751896551724 ], [ 29.803688860759493, -1.423021379310345 ], [ 29.803958354430382, -1.423021379310345 ], [ 29.803958354430382, -1.422751896551724 ], [ 29.803688860759493, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7384, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.422751896551724 ], [ 29.803958354430382, -1.423021379310345 ], [ 29.804227848101267, -1.423021379310345 ], [ 29.804227848101267, -1.422751896551724 ], [ 29.803958354430382, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7385, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.422751896551724 ], [ 29.804227848101267, -1.423021379310345 ], [ 29.804497341772151, -1.423021379310345 ], [ 29.804497341772151, -1.422751896551724 ], [ 29.804227848101267, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7386, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.422751896551724 ], [ 29.804497341772151, -1.423021379310345 ], [ 29.80476683544304, -1.423021379310345 ], [ 29.80476683544304, -1.422751896551724 ], [ 29.804497341772151, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7387, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.422751896551724 ], [ 29.80476683544304, -1.423021379310345 ], [ 29.805036329113925, -1.423021379310345 ], [ 29.805036329113925, -1.422751896551724 ], [ 29.80476683544304, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7388, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.422751896551724 ], [ 29.805036329113925, -1.423021379310345 ], [ 29.80530582278481, -1.423021379310345 ], [ 29.80530582278481, -1.422751896551724 ], [ 29.805036329113925, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7389, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.422751896551724 ], [ 29.80530582278481, -1.423021379310345 ], [ 29.805575316455698, -1.423021379310345 ], [ 29.805575316455698, -1.422751896551724 ], [ 29.80530582278481, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7390, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.422751896551724 ], [ 29.805575316455698, -1.423021379310345 ], [ 29.805844810126583, -1.423021379310345 ], [ 29.805844810126583, -1.422751896551724 ], [ 29.805575316455698, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7391, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.422751896551724 ], [ 29.805844810126583, -1.423021379310345 ], [ 29.806114303797468, -1.423021379310345 ], [ 29.806114303797468, -1.422751896551724 ], [ 29.805844810126583, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7392, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.422751896551724 ], [ 29.806114303797468, -1.423021379310345 ], [ 29.806383797468357, -1.423021379310345 ], [ 29.806383797468357, -1.422751896551724 ], [ 29.806114303797468, -1.422751896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.423021379310345 ], [ 29.757066455696201, -1.423290862068965 ], [ 29.757335949367089, -1.423290862068965 ], [ 29.757335949367089, -1.423021379310345 ], [ 29.757066455696201, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7394, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.423021379310345 ], [ 29.757335949367089, -1.423290862068965 ], [ 29.757605443037974, -1.423290862068965 ], [ 29.757605443037974, -1.423021379310345 ], [ 29.757335949367089, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7395, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.423021379310345 ], [ 29.757605443037974, -1.423290862068965 ], [ 29.757874936708859, -1.423290862068965 ], [ 29.757874936708859, -1.423021379310345 ], [ 29.757605443037974, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7396, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.423021379310345 ], [ 29.757874936708859, -1.423290862068965 ], [ 29.758144430379748, -1.423290862068965 ], [ 29.758144430379748, -1.423021379310345 ], [ 29.757874936708859, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7397, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.423021379310345 ], [ 29.758144430379748, -1.423290862068965 ], [ 29.758413924050632, -1.423290862068965 ], [ 29.758413924050632, -1.423021379310345 ], [ 29.758144430379748, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7398, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.423021379310345 ], [ 29.758413924050632, -1.423290862068965 ], [ 29.758683417721517, -1.423290862068965 ], [ 29.758683417721517, -1.423021379310345 ], [ 29.758413924050632, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7399, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.423021379310345 ], [ 29.758683417721517, -1.423290862068965 ], [ 29.758952911392406, -1.423290862068965 ], [ 29.758952911392406, -1.423021379310345 ], [ 29.758683417721517, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7400, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.423021379310345 ], [ 29.758952911392406, -1.423290862068965 ], [ 29.759222405063291, -1.423290862068965 ], [ 29.759222405063291, -1.423021379310345 ], [ 29.758952911392406, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7401, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.423021379310345 ], [ 29.759222405063291, -1.423290862068965 ], [ 29.759491898734176, -1.423290862068965 ], [ 29.759491898734176, -1.423021379310345 ], [ 29.759222405063291, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7402, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.423021379310345 ], [ 29.759491898734176, -1.423290862068965 ], [ 29.759761392405064, -1.423290862068965 ], [ 29.759761392405064, -1.423021379310345 ], [ 29.759491898734176, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7403, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.423021379310345 ], [ 29.759761392405064, -1.423290862068965 ], [ 29.760030886075949, -1.423290862068965 ], [ 29.760030886075949, -1.423021379310345 ], [ 29.759761392405064, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7404, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.423021379310345 ], [ 29.760030886075949, -1.423290862068965 ], [ 29.760300379746834, -1.423290862068965 ], [ 29.760300379746834, -1.423021379310345 ], [ 29.760030886075949, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7405, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.423021379310345 ], [ 29.760300379746834, -1.423290862068965 ], [ 29.760569873417722, -1.423290862068965 ], [ 29.760569873417722, -1.423021379310345 ], [ 29.760300379746834, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7406, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.423021379310345 ], [ 29.760569873417722, -1.423290862068965 ], [ 29.760839367088607, -1.423290862068965 ], [ 29.760839367088607, -1.423021379310345 ], [ 29.760569873417722, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7407, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.423021379310345 ], [ 29.760839367088607, -1.423290862068965 ], [ 29.761108860759492, -1.423290862068965 ], [ 29.761108860759492, -1.423021379310345 ], [ 29.760839367088607, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7408, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.423021379310345 ], [ 29.761108860759492, -1.423290862068965 ], [ 29.761378354430381, -1.423290862068965 ], [ 29.761378354430381, -1.423021379310345 ], [ 29.761108860759492, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7409, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.423021379310345 ], [ 29.761378354430381, -1.423290862068965 ], [ 29.761647848101266, -1.423290862068965 ], [ 29.761647848101266, -1.423021379310345 ], [ 29.761378354430381, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7410, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.423021379310345 ], [ 29.761647848101266, -1.423290862068965 ], [ 29.761917341772151, -1.423290862068965 ], [ 29.761917341772151, -1.423021379310345 ], [ 29.761647848101266, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7411, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.423021379310345 ], [ 29.761917341772151, -1.423290862068965 ], [ 29.762186835443039, -1.423290862068965 ], [ 29.762186835443039, -1.423021379310345 ], [ 29.761917341772151, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7412, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.423021379310345 ], [ 29.762186835443039, -1.423290862068965 ], [ 29.762456329113924, -1.423290862068965 ], [ 29.762456329113924, -1.423021379310345 ], [ 29.762186835443039, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7413, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.423021379310345 ], [ 29.762456329113924, -1.423560344827586 ], [ 29.762725822784809, -1.423560344827586 ], [ 29.762725822784809, -1.423021379310345 ], [ 29.762456329113924, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7414, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.423021379310345 ], [ 29.762725822784809, -1.423290862068965 ], [ 29.762995316455697, -1.423290862068965 ], [ 29.762995316455697, -1.423021379310345 ], [ 29.762725822784809, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7415, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.423021379310345 ], [ 29.762995316455697, -1.423290862068965 ], [ 29.763264810126582, -1.423290862068965 ], [ 29.763264810126582, -1.423021379310345 ], [ 29.762995316455697, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7416, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.423021379310345 ], [ 29.763534303797467, -1.423290862068965 ], [ 29.763803797468356, -1.423290862068965 ], [ 29.763803797468356, -1.423021379310345 ], [ 29.763534303797467, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7417, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.423021379310345 ], [ 29.763803797468356, -1.423290862068965 ], [ 29.76407329113924, -1.423290862068965 ], [ 29.76407329113924, -1.423021379310345 ], [ 29.763803797468356, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7418, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.423021379310345 ], [ 29.764342784810125, -1.423560344827586 ], [ 29.764612278481014, -1.423560344827586 ], [ 29.764612278481014, -1.423021379310345 ], [ 29.764342784810125, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7419, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.423021379310345 ], [ 29.764612278481014, -1.423290862068965 ], [ 29.764881772151899, -1.423290862068965 ], [ 29.764881772151899, -1.423021379310345 ], [ 29.764612278481014, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7420, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.423021379310345 ], [ 29.765151265822784, -1.423560344827586 ], [ 29.765420759493672, -1.423560344827586 ], [ 29.765420759493672, -1.423021379310345 ], [ 29.765151265822784, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7421, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.423021379310345 ], [ 29.766229240506327, -1.423560344827586 ], [ 29.766498734177215, -1.423560344827586 ], [ 29.766498734177215, -1.423021379310345 ], [ 29.766229240506327, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7422, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.423021379310345 ], [ 29.767037721518985, -1.423560344827586 ], [ 29.767307215189874, -1.423560344827586 ], [ 29.767307215189874, -1.423021379310345 ], [ 29.767037721518985, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7423, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.423021379310345 ], [ 29.767576708860759, -1.423560344827586 ], [ 29.767846202531643, -1.423560344827586 ], [ 29.767846202531643, -1.423021379310345 ], [ 29.767576708860759, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7424, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.423021379310345 ], [ 29.767846202531643, -1.423560344827586 ], [ 29.768115696202532, -1.423560344827586 ], [ 29.768115696202532, -1.423021379310345 ], [ 29.767846202531643, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7425, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.423021379310345 ], [ 29.768115696202532, -1.423290862068965 ], [ 29.768385189873417, -1.423290862068965 ], [ 29.768385189873417, -1.423021379310345 ], [ 29.768115696202532, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7426, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.423021379310345 ], [ 29.768385189873417, -1.423560344827586 ], [ 29.768654683544302, -1.423560344827586 ], [ 29.768654683544302, -1.423021379310345 ], [ 29.768385189873417, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7427, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.423021379310345 ], [ 29.768654683544302, -1.423560344827586 ], [ 29.76892417721519, -1.423560344827586 ], [ 29.76892417721519, -1.423021379310345 ], [ 29.768654683544302, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7428, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.423021379310345 ], [ 29.76892417721519, -1.423290862068965 ], [ 29.769193670886075, -1.423290862068965 ], [ 29.769193670886075, -1.423021379310345 ], [ 29.76892417721519, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7429, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.423021379310345 ], [ 29.769193670886075, -1.423290862068965 ], [ 29.76946316455696, -1.423290862068965 ], [ 29.76946316455696, -1.423021379310345 ], [ 29.769193670886075, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7430, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.423021379310345 ], [ 29.76946316455696, -1.423290862068965 ], [ 29.769732658227849, -1.423290862068965 ], [ 29.769732658227849, -1.423021379310345 ], [ 29.76946316455696, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7431, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.423021379310345 ], [ 29.769732658227849, -1.423290862068965 ], [ 29.770002151898733, -1.423290862068965 ], [ 29.770002151898733, -1.423021379310345 ], [ 29.769732658227849, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7432, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.423021379310345 ], [ 29.770002151898733, -1.423290862068965 ], [ 29.770271645569618, -1.423290862068965 ], [ 29.770271645569618, -1.423021379310345 ], [ 29.770002151898733, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7433, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.423021379310345 ], [ 29.770271645569618, -1.423290862068965 ], [ 29.770541139240507, -1.423290862068965 ], [ 29.770541139240507, -1.423021379310345 ], [ 29.770271645569618, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7434, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.423021379310345 ], [ 29.770541139240507, -1.423290862068965 ], [ 29.770810632911392, -1.423290862068965 ], [ 29.770810632911392, -1.423021379310345 ], [ 29.770541139240507, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7435, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.423021379310345 ], [ 29.770810632911392, -1.423290862068965 ], [ 29.771080126582277, -1.423290862068965 ], [ 29.771080126582277, -1.423021379310345 ], [ 29.770810632911392, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7436, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.423021379310345 ], [ 29.771080126582277, -1.423290862068965 ], [ 29.771349620253165, -1.423290862068965 ], [ 29.771349620253165, -1.423021379310345 ], [ 29.771080126582277, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7437, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.423021379310345 ], [ 29.771349620253165, -1.423290862068965 ], [ 29.77161911392405, -1.423290862068965 ], [ 29.77161911392405, -1.423021379310345 ], [ 29.771349620253165, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7438, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.423021379310345 ], [ 29.77161911392405, -1.423560344827586 ], [ 29.771888607594935, -1.423560344827586 ], [ 29.771888607594935, -1.423021379310345 ], [ 29.77161911392405, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7439, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.423021379310345 ], [ 29.772158101265823, -1.423290862068965 ], [ 29.772427594936708, -1.423290862068965 ], [ 29.772427594936708, -1.423021379310345 ], [ 29.772158101265823, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7440, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.423021379310345 ], [ 29.772427594936708, -1.423560344827586 ], [ 29.772697088607593, -1.423560344827586 ], [ 29.772697088607593, -1.423021379310345 ], [ 29.772427594936708, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7441, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.423021379310345 ], [ 29.772966582278482, -1.423290862068965 ], [ 29.773236075949367, -1.423290862068965 ], [ 29.773236075949367, -1.423021379310345 ], [ 29.772966582278482, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7442, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.423021379310345 ], [ 29.773236075949367, -1.423560344827586 ], [ 29.773505569620252, -1.423560344827586 ], [ 29.773505569620252, -1.423021379310345 ], [ 29.773236075949367, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7443, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.423021379310345 ], [ 29.77377506329114, -1.423290862068965 ], [ 29.774044556962025, -1.423290862068965 ], [ 29.774044556962025, -1.423021379310345 ], [ 29.77377506329114, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7444, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.423021379310345 ], [ 29.774044556962025, -1.423290862068965 ], [ 29.77431405063291, -1.423290862068965 ], [ 29.77431405063291, -1.423021379310345 ], [ 29.774044556962025, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7445, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.423021379310345 ], [ 29.774853037974683, -1.423290862068965 ], [ 29.775122531645568, -1.423290862068965 ], [ 29.775122531645568, -1.423021379310345 ], [ 29.774853037974683, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7446, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.423021379310345 ], [ 29.775122531645568, -1.423290862068965 ], [ 29.775392025316457, -1.423290862068965 ], [ 29.775392025316457, -1.423021379310345 ], [ 29.775122531645568, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7447, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.423021379310345 ], [ 29.775392025316457, -1.423560344827586 ], [ 29.775661518987341, -1.423560344827586 ], [ 29.775661518987341, -1.423021379310345 ], [ 29.775392025316457, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7448, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.423021379310345 ], [ 29.775661518987341, -1.423290862068965 ], [ 29.775931012658226, -1.423290862068965 ], [ 29.775931012658226, -1.423021379310345 ], [ 29.775661518987341, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7449, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.423021379310345 ], [ 29.775931012658226, -1.423290862068965 ], [ 29.776200506329115, -1.423290862068965 ], [ 29.776200506329115, -1.423021379310345 ], [ 29.775931012658226, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7450, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.423021379310345 ], [ 29.776200506329115, -1.423290862068965 ], [ 29.77647, -1.423290862068965 ], [ 29.77647, -1.423021379310345 ], [ 29.776200506329115, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7451, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.423021379310345 ], [ 29.776739493670885, -1.423290862068965 ], [ 29.777008987341773, -1.423290862068965 ], [ 29.777008987341773, -1.423021379310345 ], [ 29.776739493670885, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7452, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.423021379310345 ], [ 29.777008987341773, -1.423290862068965 ], [ 29.777278481012658, -1.423290862068965 ], [ 29.777278481012658, -1.423021379310345 ], [ 29.777008987341773, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7453, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.423021379310345 ], [ 29.777278481012658, -1.423290862068965 ], [ 29.777547974683543, -1.423290862068965 ], [ 29.777547974683543, -1.423021379310345 ], [ 29.777278481012658, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7454, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.423021379310345 ], [ 29.777547974683543, -1.423290862068965 ], [ 29.777817468354431, -1.423290862068965 ], [ 29.777817468354431, -1.423021379310345 ], [ 29.777547974683543, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7455, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.423021379310345 ], [ 29.777817468354431, -1.423290862068965 ], [ 29.778086962025316, -1.423290862068965 ], [ 29.778086962025316, -1.423021379310345 ], [ 29.777817468354431, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7456, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.423021379310345 ], [ 29.778086962025316, -1.423290862068965 ], [ 29.778356455696201, -1.423290862068965 ], [ 29.778356455696201, -1.423021379310345 ], [ 29.778086962025316, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7457, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.423021379310345 ], [ 29.778356455696201, -1.423290862068965 ], [ 29.77862594936709, -1.423290862068965 ], [ 29.77862594936709, -1.423021379310345 ], [ 29.778356455696201, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7458, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.423021379310345 ], [ 29.77862594936709, -1.423290862068965 ], [ 29.778895443037975, -1.423290862068965 ], [ 29.778895443037975, -1.423021379310345 ], [ 29.77862594936709, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7459, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.423021379310345 ], [ 29.778895443037975, -1.423290862068965 ], [ 29.77916493670886, -1.423290862068965 ], [ 29.77916493670886, -1.423021379310345 ], [ 29.778895443037975, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7460, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.423021379310345 ], [ 29.77916493670886, -1.423290862068965 ], [ 29.779434430379748, -1.423290862068965 ], [ 29.779434430379748, -1.423021379310345 ], [ 29.77916493670886, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7461, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.423021379310345 ], [ 29.779434430379748, -1.423290862068965 ], [ 29.779703924050633, -1.423290862068965 ], [ 29.779703924050633, -1.423021379310345 ], [ 29.779434430379748, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7462, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.423021379310345 ], [ 29.779703924050633, -1.423290862068965 ], [ 29.779973417721518, -1.423290862068965 ], [ 29.779973417721518, -1.423021379310345 ], [ 29.779703924050633, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7463, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.423021379310345 ], [ 29.779973417721518, -1.423290862068965 ], [ 29.780242911392406, -1.423290862068965 ], [ 29.780242911392406, -1.423021379310345 ], [ 29.779973417721518, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7464, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.423021379310345 ], [ 29.780242911392406, -1.423290862068965 ], [ 29.780512405063291, -1.423290862068965 ], [ 29.780512405063291, -1.423021379310345 ], [ 29.780242911392406, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7465, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.423021379310345 ], [ 29.780512405063291, -1.423290862068965 ], [ 29.780781898734176, -1.423290862068965 ], [ 29.780781898734176, -1.423021379310345 ], [ 29.780512405063291, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7466, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.423021379310345 ], [ 29.780781898734176, -1.423290862068965 ], [ 29.781051392405065, -1.423290862068965 ], [ 29.781051392405065, -1.423021379310345 ], [ 29.780781898734176, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7467, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.423021379310345 ], [ 29.781051392405065, -1.423829827586207 ], [ 29.78132088607595, -1.423829827586207 ], [ 29.78132088607595, -1.423021379310345 ], [ 29.781051392405065, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7468, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.423021379310345 ], [ 29.78132088607595, -1.424099310344828 ], [ 29.781590379746834, -1.424099310344828 ], [ 29.781590379746834, -1.423021379310345 ], [ 29.78132088607595, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7469, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.423021379310345 ], [ 29.781590379746834, -1.424099310344828 ], [ 29.781859873417723, -1.424099310344828 ], [ 29.781859873417723, -1.423021379310345 ], [ 29.781590379746834, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7470, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.423021379310345 ], [ 29.788866708860759, -1.423829827586207 ], [ 29.789136202531644, -1.423829827586207 ], [ 29.789136202531644, -1.423021379310345 ], [ 29.788866708860759, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7471, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.423021379310345 ], [ 29.789136202531644, -1.423290862068965 ], [ 29.789405696202532, -1.423290862068965 ], [ 29.789405696202532, -1.423021379310345 ], [ 29.789136202531644, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7472, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.423021379310345 ], [ 29.789405696202532, -1.423290862068965 ], [ 29.789675189873417, -1.423290862068965 ], [ 29.789675189873417, -1.423021379310345 ], [ 29.789405696202532, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7473, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.423021379310345 ], [ 29.789675189873417, -1.423290862068965 ], [ 29.789944683544302, -1.423290862068965 ], [ 29.789944683544302, -1.423021379310345 ], [ 29.789675189873417, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7474, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.423021379310345 ], [ 29.789944683544302, -1.423290862068965 ], [ 29.790214177215191, -1.423290862068965 ], [ 29.790214177215191, -1.423021379310345 ], [ 29.789944683544302, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7475, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.423021379310345 ], [ 29.790214177215191, -1.423290862068965 ], [ 29.790483670886076, -1.423290862068965 ], [ 29.790483670886076, -1.423021379310345 ], [ 29.790214177215191, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7476, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.423021379310345 ], [ 29.790483670886076, -1.423290862068965 ], [ 29.790753164556961, -1.423290862068965 ], [ 29.790753164556961, -1.423021379310345 ], [ 29.790483670886076, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7477, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.423021379310345 ], [ 29.790753164556961, -1.423290862068965 ], [ 29.791022658227849, -1.423290862068965 ], [ 29.791022658227849, -1.423021379310345 ], [ 29.790753164556961, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7478, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.423021379310345 ], [ 29.791022658227849, -1.423290862068965 ], [ 29.791292151898734, -1.423290862068965 ], [ 29.791292151898734, -1.423021379310345 ], [ 29.791022658227849, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7479, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.423021379310345 ], [ 29.791292151898734, -1.423290862068965 ], [ 29.791561645569619, -1.423290862068965 ], [ 29.791561645569619, -1.423021379310345 ], [ 29.791292151898734, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7480, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.423021379310345 ], [ 29.791561645569619, -1.423290862068965 ], [ 29.791831139240507, -1.423290862068965 ], [ 29.791831139240507, -1.423021379310345 ], [ 29.791561645569619, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7481, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.423021379310345 ], [ 29.791831139240507, -1.423290862068965 ], [ 29.792100632911392, -1.423290862068965 ], [ 29.792100632911392, -1.423021379310345 ], [ 29.791831139240507, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7482, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.423021379310345 ], [ 29.80045493670886, -1.423290862068965 ], [ 29.800724430379748, -1.423290862068965 ], [ 29.800724430379748, -1.423021379310345 ], [ 29.80045493670886, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7483, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.423021379310345 ], [ 29.800724430379748, -1.423290862068965 ], [ 29.800993924050633, -1.423290862068965 ], [ 29.800993924050633, -1.423021379310345 ], [ 29.800724430379748, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7484, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.423021379310345 ], [ 29.800993924050633, -1.423290862068965 ], [ 29.801263417721518, -1.423290862068965 ], [ 29.801263417721518, -1.423021379310345 ], [ 29.800993924050633, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7485, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.423021379310345 ], [ 29.801263417721518, -1.423560344827586 ], [ 29.801532911392407, -1.423560344827586 ], [ 29.801532911392407, -1.423021379310345 ], [ 29.801263417721518, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7486, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.423021379310345 ], [ 29.801532911392407, -1.423560344827586 ], [ 29.802071898734177, -1.423560344827586 ], [ 29.802071898734177, -1.423021379310345 ], [ 29.801532911392407, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7487, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.423021379310345 ], [ 29.802341392405065, -1.423290862068965 ], [ 29.80261088607595, -1.423290862068965 ], [ 29.80261088607595, -1.423021379310345 ], [ 29.802341392405065, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7488, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.423021379310345 ], [ 29.80261088607595, -1.423290862068965 ], [ 29.802880379746835, -1.423290862068965 ], [ 29.802880379746835, -1.423021379310345 ], [ 29.80261088607595, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7489, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.423021379310345 ], [ 29.802880379746835, -1.423290862068965 ], [ 29.803149873417723, -1.423290862068965 ], [ 29.803149873417723, -1.423021379310345 ], [ 29.802880379746835, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7490, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.423021379310345 ], [ 29.803149873417723, -1.423290862068965 ], [ 29.803419367088608, -1.423290862068965 ], [ 29.803419367088608, -1.423021379310345 ], [ 29.803149873417723, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7491, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.423021379310345 ], [ 29.803419367088608, -1.423290862068965 ], [ 29.803688860759493, -1.423290862068965 ], [ 29.803688860759493, -1.423021379310345 ], [ 29.803419367088608, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7492, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.423021379310345 ], [ 29.803688860759493, -1.423290862068965 ], [ 29.803958354430382, -1.423290862068965 ], [ 29.803958354430382, -1.423021379310345 ], [ 29.803688860759493, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7493, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.423021379310345 ], [ 29.803958354430382, -1.423290862068965 ], [ 29.804227848101267, -1.423290862068965 ], [ 29.804227848101267, -1.423021379310345 ], [ 29.803958354430382, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7494, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.423021379310345 ], [ 29.804227848101267, -1.423290862068965 ], [ 29.804497341772151, -1.423290862068965 ], [ 29.804497341772151, -1.423021379310345 ], [ 29.804227848101267, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7495, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.423021379310345 ], [ 29.804497341772151, -1.423290862068965 ], [ 29.80476683544304, -1.423290862068965 ], [ 29.80476683544304, -1.423021379310345 ], [ 29.804497341772151, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7496, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.423021379310345 ], [ 29.80476683544304, -1.423290862068965 ], [ 29.805036329113925, -1.423290862068965 ], [ 29.805036329113925, -1.423021379310345 ], [ 29.80476683544304, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7497, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.423021379310345 ], [ 29.805036329113925, -1.423290862068965 ], [ 29.80530582278481, -1.423290862068965 ], [ 29.80530582278481, -1.423021379310345 ], [ 29.805036329113925, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7498, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.423021379310345 ], [ 29.80530582278481, -1.423290862068965 ], [ 29.805575316455698, -1.423290862068965 ], [ 29.805575316455698, -1.423021379310345 ], [ 29.80530582278481, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7499, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.423021379310345 ], [ 29.805575316455698, -1.423290862068965 ], [ 29.805844810126583, -1.423290862068965 ], [ 29.805844810126583, -1.423021379310345 ], [ 29.805575316455698, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7500, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.423021379310345 ], [ 29.805844810126583, -1.423290862068965 ], [ 29.806114303797468, -1.423290862068965 ], [ 29.806114303797468, -1.423021379310345 ], [ 29.805844810126583, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7501, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.423021379310345 ], [ 29.806114303797468, -1.423290862068965 ], [ 29.806383797468357, -1.423290862068965 ], [ 29.806383797468357, -1.423021379310345 ], [ 29.806114303797468, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7502, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.423021379310345 ], [ 29.806383797468357, -1.423290862068965 ], [ 29.806653291139241, -1.423290862068965 ], [ 29.806653291139241, -1.423021379310345 ], [ 29.806383797468357, -1.423021379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7503, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.423290862068965 ], [ 29.756796962025316, -1.423829827586207 ], [ 29.757066455696201, -1.423829827586207 ], [ 29.757066455696201, -1.423290862068965 ], [ 29.756796962025316, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7504, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.423290862068965 ], [ 29.757066455696201, -1.423560344827586 ], [ 29.757335949367089, -1.423560344827586 ], [ 29.757335949367089, -1.423290862068965 ], [ 29.757066455696201, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7505, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.423290862068965 ], [ 29.757335949367089, -1.423560344827586 ], [ 29.757605443037974, -1.423560344827586 ], [ 29.757605443037974, -1.423290862068965 ], [ 29.757335949367089, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7506, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.423290862068965 ], [ 29.757605443037974, -1.423560344827586 ], [ 29.757874936708859, -1.423560344827586 ], [ 29.757874936708859, -1.423290862068965 ], [ 29.757605443037974, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7507, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.423290862068965 ], [ 29.757874936708859, -1.423560344827586 ], [ 29.758144430379748, -1.423560344827586 ], [ 29.758144430379748, -1.423290862068965 ], [ 29.757874936708859, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7508, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.423290862068965 ], [ 29.758144430379748, -1.423560344827586 ], [ 29.758413924050632, -1.423560344827586 ], [ 29.758413924050632, -1.423290862068965 ], [ 29.758144430379748, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7509, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.423290862068965 ], [ 29.758413924050632, -1.423560344827586 ], [ 29.758683417721517, -1.423560344827586 ], [ 29.758683417721517, -1.423290862068965 ], [ 29.758413924050632, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7510, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.423290862068965 ], [ 29.758683417721517, -1.423560344827586 ], [ 29.758952911392406, -1.423560344827586 ], [ 29.758952911392406, -1.423290862068965 ], [ 29.758683417721517, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7511, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.423290862068965 ], [ 29.758952911392406, -1.423560344827586 ], [ 29.759222405063291, -1.423560344827586 ], [ 29.759222405063291, -1.423290862068965 ], [ 29.758952911392406, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7512, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.423290862068965 ], [ 29.759222405063291, -1.423560344827586 ], [ 29.759491898734176, -1.423560344827586 ], [ 29.759491898734176, -1.423290862068965 ], [ 29.759222405063291, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7513, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.423290862068965 ], [ 29.759491898734176, -1.423560344827586 ], [ 29.759761392405064, -1.423560344827586 ], [ 29.759761392405064, -1.423290862068965 ], [ 29.759491898734176, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7514, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.423290862068965 ], [ 29.759761392405064, -1.423560344827586 ], [ 29.760030886075949, -1.423560344827586 ], [ 29.760030886075949, -1.423290862068965 ], [ 29.759761392405064, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7515, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.423290862068965 ], [ 29.760030886075949, -1.423560344827586 ], [ 29.760300379746834, -1.423560344827586 ], [ 29.760300379746834, -1.423290862068965 ], [ 29.760030886075949, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7516, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.423290862068965 ], [ 29.760300379746834, -1.423560344827586 ], [ 29.760569873417722, -1.423560344827586 ], [ 29.760569873417722, -1.423290862068965 ], [ 29.760300379746834, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7517, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.423290862068965 ], [ 29.760569873417722, -1.423560344827586 ], [ 29.760839367088607, -1.423560344827586 ], [ 29.760839367088607, -1.423290862068965 ], [ 29.760569873417722, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7518, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.423290862068965 ], [ 29.760839367088607, -1.423560344827586 ], [ 29.761108860759492, -1.423560344827586 ], [ 29.761108860759492, -1.423290862068965 ], [ 29.760839367088607, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7519, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.423290862068965 ], [ 29.761108860759492, -1.423560344827586 ], [ 29.761378354430381, -1.423560344827586 ], [ 29.761378354430381, -1.423290862068965 ], [ 29.761108860759492, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7520, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.423290862068965 ], [ 29.761378354430381, -1.423560344827586 ], [ 29.761647848101266, -1.423560344827586 ], [ 29.761647848101266, -1.423290862068965 ], [ 29.761378354430381, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7521, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.423290862068965 ], [ 29.761647848101266, -1.423560344827586 ], [ 29.761917341772151, -1.423560344827586 ], [ 29.761917341772151, -1.423290862068965 ], [ 29.761647848101266, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7522, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.423290862068965 ], [ 29.761917341772151, -1.423560344827586 ], [ 29.762186835443039, -1.423560344827586 ], [ 29.762186835443039, -1.423290862068965 ], [ 29.761917341772151, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7523, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.423290862068965 ], [ 29.762186835443039, -1.423560344827586 ], [ 29.762456329113924, -1.423560344827586 ], [ 29.762456329113924, -1.423290862068965 ], [ 29.762186835443039, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7524, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.423290862068965 ], [ 29.762725822784809, -1.423560344827586 ], [ 29.762995316455697, -1.423560344827586 ], [ 29.762995316455697, -1.423290862068965 ], [ 29.762725822784809, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7525, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.423290862068965 ], [ 29.762995316455697, -1.423560344827586 ], [ 29.763264810126582, -1.423560344827586 ], [ 29.763264810126582, -1.423290862068965 ], [ 29.762995316455697, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7526, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.423290862068965 ], [ 29.763264810126582, -1.423560344827586 ], [ 29.763534303797467, -1.423560344827586 ], [ 29.763534303797467, -1.423290862068965 ], [ 29.763264810126582, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7527, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.423290862068965 ], [ 29.763534303797467, -1.423829827586207 ], [ 29.763803797468356, -1.423829827586207 ], [ 29.763803797468356, -1.423290862068965 ], [ 29.763534303797467, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7528, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.423290862068965 ], [ 29.763803797468356, -1.423560344827586 ], [ 29.76407329113924, -1.423560344827586 ], [ 29.76407329113924, -1.423290862068965 ], [ 29.763803797468356, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7529, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.423290862068965 ], [ 29.76407329113924, -1.423560344827586 ], [ 29.764342784810125, -1.423560344827586 ], [ 29.764342784810125, -1.423290862068965 ], [ 29.76407329113924, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7530, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.423290862068965 ], [ 29.764612278481014, -1.423560344827586 ], [ 29.764881772151899, -1.423560344827586 ], [ 29.764881772151899, -1.423290862068965 ], [ 29.764612278481014, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7531, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.423290862068965 ], [ 29.764881772151899, -1.423560344827586 ], [ 29.765151265822784, -1.423560344827586 ], [ 29.765151265822784, -1.423290862068965 ], [ 29.764881772151899, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7532, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.423290862068965 ], [ 29.765420759493672, -1.423829827586207 ], [ 29.765959746835442, -1.423829827586207 ], [ 29.765959746835442, -1.423290862068965 ], [ 29.765420759493672, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7533, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.423290862068965 ], [ 29.765959746835442, -1.423560344827586 ], [ 29.766229240506327, -1.423560344827586 ], [ 29.766229240506327, -1.423290862068965 ], [ 29.765959746835442, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7534, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.423290862068965 ], [ 29.766498734177215, -1.423829827586207 ], [ 29.7667682278481, -1.423829827586207 ], [ 29.7667682278481, -1.423290862068965 ], [ 29.766498734177215, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7535, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.423290862068965 ], [ 29.7667682278481, -1.423560344827586 ], [ 29.767037721518985, -1.423560344827586 ], [ 29.767037721518985, -1.423290862068965 ], [ 29.7667682278481, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7536, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.423290862068965 ], [ 29.767307215189874, -1.423829827586207 ], [ 29.767576708860759, -1.423829827586207 ], [ 29.767576708860759, -1.423290862068965 ], [ 29.767307215189874, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7537, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.423290862068965 ], [ 29.768115696202532, -1.423829827586207 ], [ 29.768385189873417, -1.423829827586207 ], [ 29.768385189873417, -1.423290862068965 ], [ 29.768115696202532, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7538, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.423290862068965 ], [ 29.76892417721519, -1.423829827586207 ], [ 29.769193670886075, -1.423829827586207 ], [ 29.769193670886075, -1.423290862068965 ], [ 29.76892417721519, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7539, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.423290862068965 ], [ 29.769193670886075, -1.423560344827586 ], [ 29.76946316455696, -1.423560344827586 ], [ 29.76946316455696, -1.423290862068965 ], [ 29.769193670886075, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7540, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.423290862068965 ], [ 29.76946316455696, -1.423829827586207 ], [ 29.769732658227849, -1.423829827586207 ], [ 29.769732658227849, -1.423290862068965 ], [ 29.76946316455696, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7541, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.423290862068965 ], [ 29.769732658227849, -1.423560344827586 ], [ 29.770002151898733, -1.423560344827586 ], [ 29.770002151898733, -1.423290862068965 ], [ 29.769732658227849, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7542, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.423290862068965 ], [ 29.770002151898733, -1.423560344827586 ], [ 29.770271645569618, -1.423560344827586 ], [ 29.770271645569618, -1.423290862068965 ], [ 29.770002151898733, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7543, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.423290862068965 ], [ 29.770271645569618, -1.423560344827586 ], [ 29.770541139240507, -1.423560344827586 ], [ 29.770541139240507, -1.423290862068965 ], [ 29.770271645569618, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7544, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.423290862068965 ], [ 29.770541139240507, -1.423560344827586 ], [ 29.770810632911392, -1.423560344827586 ], [ 29.770810632911392, -1.423290862068965 ], [ 29.770541139240507, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7545, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.423290862068965 ], [ 29.770810632911392, -1.423560344827586 ], [ 29.771080126582277, -1.423560344827586 ], [ 29.771080126582277, -1.423290862068965 ], [ 29.770810632911392, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7546, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.423290862068965 ], [ 29.771080126582277, -1.423560344827586 ], [ 29.771349620253165, -1.423560344827586 ], [ 29.771349620253165, -1.423290862068965 ], [ 29.771080126582277, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7547, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.423290862068965 ], [ 29.771349620253165, -1.423560344827586 ], [ 29.77161911392405, -1.423560344827586 ], [ 29.77161911392405, -1.423290862068965 ], [ 29.771349620253165, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7548, "El": 160 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.423290862068965 ], [ 29.771888607594935, -1.423560344827586 ], [ 29.772158101265823, -1.423560344827586 ], [ 29.772158101265823, -1.423290862068965 ], [ 29.771888607594935, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7549, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.423290862068965 ], [ 29.772158101265823, -1.424368793103448 ], [ 29.772427594936708, -1.424368793103448 ], [ 29.772427594936708, -1.423290862068965 ], [ 29.772158101265823, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7550, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.423290862068965 ], [ 29.772697088607593, -1.423560344827586 ], [ 29.772966582278482, -1.423560344827586 ], [ 29.772966582278482, -1.423290862068965 ], [ 29.772697088607593, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7551, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.423290862068965 ], [ 29.772966582278482, -1.423560344827586 ], [ 29.773236075949367, -1.423560344827586 ], [ 29.773236075949367, -1.423290862068965 ], [ 29.772966582278482, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7552, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.423290862068965 ], [ 29.773505569620252, -1.423560344827586 ], [ 29.77377506329114, -1.423560344827586 ], [ 29.77377506329114, -1.423290862068965 ], [ 29.773505569620252, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7553, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.423290862068965 ], [ 29.77377506329114, -1.423560344827586 ], [ 29.774044556962025, -1.423560344827586 ], [ 29.774044556962025, -1.423290862068965 ], [ 29.77377506329114, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7554, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.423290862068965 ], [ 29.774044556962025, -1.424368793103448 ], [ 29.77431405063291, -1.424368793103448 ], [ 29.77431405063291, -1.423290862068965 ], [ 29.774044556962025, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7555, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.423290862068965 ], [ 29.77431405063291, -1.423560344827586 ], [ 29.774853037974683, -1.423560344827586 ], [ 29.774853037974683, -1.423290862068965 ], [ 29.77431405063291, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7556, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.423290862068965 ], [ 29.774853037974683, -1.423560344827586 ], [ 29.775122531645568, -1.423560344827586 ], [ 29.775122531645568, -1.423290862068965 ], [ 29.774853037974683, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7557, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.423290862068965 ], [ 29.775122531645568, -1.423560344827586 ], [ 29.775392025316457, -1.423560344827586 ], [ 29.775392025316457, -1.423290862068965 ], [ 29.775122531645568, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7558, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.423290862068965 ], [ 29.775661518987341, -1.423560344827586 ], [ 29.775931012658226, -1.423560344827586 ], [ 29.775931012658226, -1.423290862068965 ], [ 29.775661518987341, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7559, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.423290862068965 ], [ 29.775931012658226, -1.423560344827586 ], [ 29.776200506329115, -1.423560344827586 ], [ 29.776200506329115, -1.423290862068965 ], [ 29.775931012658226, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7560, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.423290862068965 ], [ 29.776200506329115, -1.423560344827586 ], [ 29.77647, -1.423560344827586 ], [ 29.77647, -1.423290862068965 ], [ 29.776200506329115, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7561, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.423290862068965 ], [ 29.77647, -1.423560344827586 ], [ 29.776739493670885, -1.423560344827586 ], [ 29.776739493670885, -1.423290862068965 ], [ 29.77647, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7562, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.423290862068965 ], [ 29.776739493670885, -1.423560344827586 ], [ 29.777008987341773, -1.423560344827586 ], [ 29.777008987341773, -1.423290862068965 ], [ 29.776739493670885, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7563, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.423290862068965 ], [ 29.777008987341773, -1.423560344827586 ], [ 29.777278481012658, -1.423560344827586 ], [ 29.777278481012658, -1.423290862068965 ], [ 29.777008987341773, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7564, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.423290862068965 ], [ 29.777278481012658, -1.423560344827586 ], [ 29.777547974683543, -1.423560344827586 ], [ 29.777547974683543, -1.423290862068965 ], [ 29.777278481012658, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7565, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.423290862068965 ], [ 29.777547974683543, -1.423560344827586 ], [ 29.777817468354431, -1.423560344827586 ], [ 29.777817468354431, -1.423290862068965 ], [ 29.777547974683543, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7566, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.423290862068965 ], [ 29.777817468354431, -1.423560344827586 ], [ 29.778086962025316, -1.423560344827586 ], [ 29.778086962025316, -1.423290862068965 ], [ 29.777817468354431, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7567, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.423290862068965 ], [ 29.778086962025316, -1.423560344827586 ], [ 29.778356455696201, -1.423560344827586 ], [ 29.778356455696201, -1.423290862068965 ], [ 29.778086962025316, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7568, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.423290862068965 ], [ 29.778356455696201, -1.423560344827586 ], [ 29.77862594936709, -1.423560344827586 ], [ 29.77862594936709, -1.423290862068965 ], [ 29.778356455696201, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7569, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.423290862068965 ], [ 29.77862594936709, -1.423560344827586 ], [ 29.778895443037975, -1.423560344827586 ], [ 29.778895443037975, -1.423290862068965 ], [ 29.77862594936709, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7570, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.423290862068965 ], [ 29.778895443037975, -1.423560344827586 ], [ 29.77916493670886, -1.423560344827586 ], [ 29.77916493670886, -1.423290862068965 ], [ 29.778895443037975, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7571, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.423290862068965 ], [ 29.77916493670886, -1.423560344827586 ], [ 29.779434430379748, -1.423560344827586 ], [ 29.779434430379748, -1.423290862068965 ], [ 29.77916493670886, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7572, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.423290862068965 ], [ 29.779434430379748, -1.423560344827586 ], [ 29.779703924050633, -1.423560344827586 ], [ 29.779703924050633, -1.423290862068965 ], [ 29.779434430379748, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7573, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.423290862068965 ], [ 29.779703924050633, -1.423560344827586 ], [ 29.779973417721518, -1.423560344827586 ], [ 29.779973417721518, -1.423290862068965 ], [ 29.779703924050633, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7574, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.423290862068965 ], [ 29.779973417721518, -1.423560344827586 ], [ 29.780242911392406, -1.423560344827586 ], [ 29.780242911392406, -1.423290862068965 ], [ 29.779973417721518, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7575, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.423290862068965 ], [ 29.780242911392406, -1.423560344827586 ], [ 29.780512405063291, -1.423560344827586 ], [ 29.780512405063291, -1.423290862068965 ], [ 29.780242911392406, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7576, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.423290862068965 ], [ 29.780512405063291, -1.423829827586207 ], [ 29.780781898734176, -1.423829827586207 ], [ 29.780781898734176, -1.423290862068965 ], [ 29.780512405063291, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7577, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.423290862068965 ], [ 29.780781898734176, -1.424368793103448 ], [ 29.781051392405065, -1.424368793103448 ], [ 29.781051392405065, -1.423290862068965 ], [ 29.780781898734176, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7578, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.423290862068965 ], [ 29.789136202531644, -1.423829827586207 ], [ 29.789405696202532, -1.423829827586207 ], [ 29.789405696202532, -1.423290862068965 ], [ 29.789136202531644, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7579, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.423290862068965 ], [ 29.789405696202532, -1.423829827586207 ], [ 29.789675189873417, -1.423829827586207 ], [ 29.789675189873417, -1.423290862068965 ], [ 29.789405696202532, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7580, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.423290862068965 ], [ 29.789675189873417, -1.423560344827586 ], [ 29.789944683544302, -1.423560344827586 ], [ 29.789944683544302, -1.423290862068965 ], [ 29.789675189873417, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7581, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.423290862068965 ], [ 29.789944683544302, -1.423560344827586 ], [ 29.790214177215191, -1.423560344827586 ], [ 29.790214177215191, -1.423290862068965 ], [ 29.789944683544302, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7582, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.423290862068965 ], [ 29.790214177215191, -1.423560344827586 ], [ 29.790483670886076, -1.423560344827586 ], [ 29.790483670886076, -1.423290862068965 ], [ 29.790214177215191, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7583, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.423290862068965 ], [ 29.790483670886076, -1.423560344827586 ], [ 29.790753164556961, -1.423560344827586 ], [ 29.790753164556961, -1.423290862068965 ], [ 29.790483670886076, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7584, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.423290862068965 ], [ 29.790753164556961, -1.423560344827586 ], [ 29.791022658227849, -1.423560344827586 ], [ 29.791022658227849, -1.423290862068965 ], [ 29.790753164556961, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7585, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.423290862068965 ], [ 29.791022658227849, -1.423560344827586 ], [ 29.791292151898734, -1.423560344827586 ], [ 29.791292151898734, -1.423290862068965 ], [ 29.791022658227849, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7586, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.423290862068965 ], [ 29.791292151898734, -1.423560344827586 ], [ 29.791561645569619, -1.423560344827586 ], [ 29.791561645569619, -1.423290862068965 ], [ 29.791292151898734, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7587, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.423290862068965 ], [ 29.791561645569619, -1.423560344827586 ], [ 29.791831139240507, -1.423560344827586 ], [ 29.791831139240507, -1.423290862068965 ], [ 29.791561645569619, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7588, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.423290862068965 ], [ 29.791831139240507, -1.423560344827586 ], [ 29.792100632911392, -1.423560344827586 ], [ 29.792100632911392, -1.423290862068965 ], [ 29.791831139240507, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7589, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.423290862068965 ], [ 29.792100632911392, -1.423560344827586 ], [ 29.792370126582277, -1.423560344827586 ], [ 29.792370126582277, -1.423290862068965 ], [ 29.792100632911392, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7590, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.423290862068965 ], [ 29.80045493670886, -1.424099310344828 ], [ 29.800724430379748, -1.424099310344828 ], [ 29.800724430379748, -1.423290862068965 ], [ 29.80045493670886, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7591, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.423290862068965 ], [ 29.800724430379748, -1.423829827586207 ], [ 29.800993924050633, -1.423829827586207 ], [ 29.800993924050633, -1.423290862068965 ], [ 29.800724430379748, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7592, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.423290862068965 ], [ 29.800993924050633, -1.423829827586207 ], [ 29.801263417721518, -1.423829827586207 ], [ 29.801263417721518, -1.423290862068965 ], [ 29.800993924050633, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7593, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.423290862068965 ], [ 29.802071898734177, -1.423560344827586 ], [ 29.802341392405065, -1.423560344827586 ], [ 29.802341392405065, -1.423290862068965 ], [ 29.802071898734177, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7594, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.423290862068965 ], [ 29.802341392405065, -1.423560344827586 ], [ 29.80261088607595, -1.423560344827586 ], [ 29.80261088607595, -1.423290862068965 ], [ 29.802341392405065, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7595, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.423290862068965 ], [ 29.80261088607595, -1.423560344827586 ], [ 29.802880379746835, -1.423560344827586 ], [ 29.802880379746835, -1.423290862068965 ], [ 29.80261088607595, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7596, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.423290862068965 ], [ 29.802880379746835, -1.423560344827586 ], [ 29.803149873417723, -1.423560344827586 ], [ 29.803149873417723, -1.423290862068965 ], [ 29.802880379746835, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7597, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.423290862068965 ], [ 29.803149873417723, -1.423560344827586 ], [ 29.803419367088608, -1.423560344827586 ], [ 29.803419367088608, -1.423290862068965 ], [ 29.803149873417723, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7598, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.423290862068965 ], [ 29.803419367088608, -1.423560344827586 ], [ 29.803688860759493, -1.423560344827586 ], [ 29.803688860759493, -1.423290862068965 ], [ 29.803419367088608, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7599, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.423290862068965 ], [ 29.803688860759493, -1.423560344827586 ], [ 29.803958354430382, -1.423560344827586 ], [ 29.803958354430382, -1.423290862068965 ], [ 29.803688860759493, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7600, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.423290862068965 ], [ 29.803958354430382, -1.423560344827586 ], [ 29.804227848101267, -1.423560344827586 ], [ 29.804227848101267, -1.423290862068965 ], [ 29.803958354430382, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7601, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.423290862068965 ], [ 29.804227848101267, -1.423560344827586 ], [ 29.804497341772151, -1.423560344827586 ], [ 29.804497341772151, -1.423290862068965 ], [ 29.804227848101267, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7602, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.423290862068965 ], [ 29.804497341772151, -1.423560344827586 ], [ 29.80476683544304, -1.423560344827586 ], [ 29.80476683544304, -1.423290862068965 ], [ 29.804497341772151, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7603, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.423290862068965 ], [ 29.80476683544304, -1.423560344827586 ], [ 29.805036329113925, -1.423560344827586 ], [ 29.805036329113925, -1.423290862068965 ], [ 29.80476683544304, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7604, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.423290862068965 ], [ 29.805036329113925, -1.423560344827586 ], [ 29.80530582278481, -1.423560344827586 ], [ 29.80530582278481, -1.423290862068965 ], [ 29.805036329113925, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7605, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.423290862068965 ], [ 29.80530582278481, -1.423560344827586 ], [ 29.805575316455698, -1.423560344827586 ], [ 29.805575316455698, -1.423290862068965 ], [ 29.80530582278481, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7606, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.423290862068965 ], [ 29.805575316455698, -1.423560344827586 ], [ 29.805844810126583, -1.423560344827586 ], [ 29.805844810126583, -1.423290862068965 ], [ 29.805575316455698, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7607, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.423290862068965 ], [ 29.805844810126583, -1.423560344827586 ], [ 29.806114303797468, -1.423560344827586 ], [ 29.806114303797468, -1.423290862068965 ], [ 29.805844810126583, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7608, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.423290862068965 ], [ 29.806114303797468, -1.423560344827586 ], [ 29.806383797468357, -1.423560344827586 ], [ 29.806383797468357, -1.423290862068965 ], [ 29.806114303797468, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7609, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.423290862068965 ], [ 29.806383797468357, -1.423560344827586 ], [ 29.806653291139241, -1.423560344827586 ], [ 29.806653291139241, -1.423290862068965 ], [ 29.806383797468357, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7610, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.423290862068965 ], [ 29.806653291139241, -1.423560344827586 ], [ 29.806922784810126, -1.423560344827586 ], [ 29.806922784810126, -1.423290862068965 ], [ 29.806653291139241, -1.423290862068965 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7611, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.423560344827586 ], [ 29.757066455696201, -1.423829827586207 ], [ 29.757335949367089, -1.423829827586207 ], [ 29.757335949367089, -1.423560344827586 ], [ 29.757066455696201, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7612, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.423560344827586 ], [ 29.757335949367089, -1.423829827586207 ], [ 29.757605443037974, -1.423829827586207 ], [ 29.757605443037974, -1.423560344827586 ], [ 29.757335949367089, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7613, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.423560344827586 ], [ 29.757605443037974, -1.423829827586207 ], [ 29.757874936708859, -1.423829827586207 ], [ 29.757874936708859, -1.423560344827586 ], [ 29.757605443037974, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7614, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.423560344827586 ], [ 29.757874936708859, -1.423829827586207 ], [ 29.758144430379748, -1.423829827586207 ], [ 29.758144430379748, -1.423560344827586 ], [ 29.757874936708859, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7615, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.423560344827586 ], [ 29.758144430379748, -1.423829827586207 ], [ 29.758413924050632, -1.423829827586207 ], [ 29.758413924050632, -1.423560344827586 ], [ 29.758144430379748, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7616, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.423560344827586 ], [ 29.758413924050632, -1.423829827586207 ], [ 29.758683417721517, -1.423829827586207 ], [ 29.758683417721517, -1.423560344827586 ], [ 29.758413924050632, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7617, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.423560344827586 ], [ 29.758683417721517, -1.423829827586207 ], [ 29.758952911392406, -1.423829827586207 ], [ 29.758952911392406, -1.423560344827586 ], [ 29.758683417721517, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7618, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.423560344827586 ], [ 29.758952911392406, -1.423829827586207 ], [ 29.759222405063291, -1.423829827586207 ], [ 29.759222405063291, -1.423560344827586 ], [ 29.758952911392406, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7619, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.423560344827586 ], [ 29.759222405063291, -1.423829827586207 ], [ 29.759491898734176, -1.423829827586207 ], [ 29.759491898734176, -1.423560344827586 ], [ 29.759222405063291, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7620, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.423560344827586 ], [ 29.759491898734176, -1.423829827586207 ], [ 29.759761392405064, -1.423829827586207 ], [ 29.759761392405064, -1.423560344827586 ], [ 29.759491898734176, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7621, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.423560344827586 ], [ 29.759761392405064, -1.423829827586207 ], [ 29.760030886075949, -1.423829827586207 ], [ 29.760030886075949, -1.423560344827586 ], [ 29.759761392405064, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7622, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.423560344827586 ], [ 29.760030886075949, -1.423829827586207 ], [ 29.760300379746834, -1.423829827586207 ], [ 29.760300379746834, -1.423560344827586 ], [ 29.760030886075949, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7623, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.423560344827586 ], [ 29.760300379746834, -1.423829827586207 ], [ 29.760569873417722, -1.423829827586207 ], [ 29.760569873417722, -1.423560344827586 ], [ 29.760300379746834, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7624, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.423560344827586 ], [ 29.760569873417722, -1.423829827586207 ], [ 29.760839367088607, -1.423829827586207 ], [ 29.760839367088607, -1.423560344827586 ], [ 29.760569873417722, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7625, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.423560344827586 ], [ 29.760839367088607, -1.423829827586207 ], [ 29.761108860759492, -1.423829827586207 ], [ 29.761108860759492, -1.423560344827586 ], [ 29.760839367088607, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7626, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.423560344827586 ], [ 29.761108860759492, -1.423829827586207 ], [ 29.761378354430381, -1.423829827586207 ], [ 29.761378354430381, -1.423560344827586 ], [ 29.761108860759492, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7627, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.423560344827586 ], [ 29.761378354430381, -1.423829827586207 ], [ 29.761647848101266, -1.423829827586207 ], [ 29.761647848101266, -1.423560344827586 ], [ 29.761378354430381, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7628, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.423560344827586 ], [ 29.761647848101266, -1.423829827586207 ], [ 29.761917341772151, -1.423829827586207 ], [ 29.761917341772151, -1.423560344827586 ], [ 29.761647848101266, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7629, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.423560344827586 ], [ 29.761917341772151, -1.423829827586207 ], [ 29.762186835443039, -1.423829827586207 ], [ 29.762186835443039, -1.423560344827586 ], [ 29.761917341772151, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7630, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.423560344827586 ], [ 29.762186835443039, -1.423829827586207 ], [ 29.762456329113924, -1.423829827586207 ], [ 29.762456329113924, -1.423560344827586 ], [ 29.762186835443039, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7631, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.423560344827586 ], [ 29.762456329113924, -1.423829827586207 ], [ 29.762725822784809, -1.423829827586207 ], [ 29.762725822784809, -1.423560344827586 ], [ 29.762456329113924, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7632, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.423560344827586 ], [ 29.762725822784809, -1.423829827586207 ], [ 29.762995316455697, -1.423829827586207 ], [ 29.762995316455697, -1.423560344827586 ], [ 29.762725822784809, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7633, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.423560344827586 ], [ 29.762995316455697, -1.423829827586207 ], [ 29.763264810126582, -1.423829827586207 ], [ 29.763264810126582, -1.423560344827586 ], [ 29.762995316455697, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7634, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.423560344827586 ], [ 29.763264810126582, -1.423829827586207 ], [ 29.763534303797467, -1.423829827586207 ], [ 29.763534303797467, -1.423560344827586 ], [ 29.763264810126582, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7635, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.423560344827586 ], [ 29.763803797468356, -1.423829827586207 ], [ 29.76407329113924, -1.423829827586207 ], [ 29.76407329113924, -1.423560344827586 ], [ 29.763803797468356, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7636, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.423560344827586 ], [ 29.76407329113924, -1.423829827586207 ], [ 29.764342784810125, -1.423829827586207 ], [ 29.764342784810125, -1.423560344827586 ], [ 29.76407329113924, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7637, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.423560344827586 ], [ 29.764342784810125, -1.423829827586207 ], [ 29.764612278481014, -1.423829827586207 ], [ 29.764612278481014, -1.423560344827586 ], [ 29.764342784810125, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7638, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.423560344827586 ], [ 29.764612278481014, -1.424099310344828 ], [ 29.764881772151899, -1.424099310344828 ], [ 29.764881772151899, -1.423560344827586 ], [ 29.764612278481014, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7639, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.423560344827586 ], [ 29.764881772151899, -1.423829827586207 ], [ 29.765151265822784, -1.423829827586207 ], [ 29.765151265822784, -1.423560344827586 ], [ 29.764881772151899, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7640, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.423560344827586 ], [ 29.765151265822784, -1.423829827586207 ], [ 29.765420759493672, -1.423829827586207 ], [ 29.765420759493672, -1.423560344827586 ], [ 29.765151265822784, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7641, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.423560344827586 ], [ 29.765959746835442, -1.423829827586207 ], [ 29.766229240506327, -1.423829827586207 ], [ 29.766229240506327, -1.423560344827586 ], [ 29.765959746835442, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7642, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.423560344827586 ], [ 29.766229240506327, -1.423829827586207 ], [ 29.766498734177215, -1.423829827586207 ], [ 29.766498734177215, -1.423560344827586 ], [ 29.766229240506327, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7643, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.423560344827586 ], [ 29.7667682278481, -1.424099310344828 ], [ 29.767037721518985, -1.424099310344828 ], [ 29.767037721518985, -1.423560344827586 ], [ 29.7667682278481, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7644, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.423560344827586 ], [ 29.767037721518985, -1.423829827586207 ], [ 29.767307215189874, -1.423829827586207 ], [ 29.767307215189874, -1.423560344827586 ], [ 29.767037721518985, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7645, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.423560344827586 ], [ 29.767576708860759, -1.424099310344828 ], [ 29.767846202531643, -1.424099310344828 ], [ 29.767846202531643, -1.423560344827586 ], [ 29.767576708860759, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7646, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.423560344827586 ], [ 29.767846202531643, -1.423829827586207 ], [ 29.768115696202532, -1.423829827586207 ], [ 29.768115696202532, -1.423560344827586 ], [ 29.767846202531643, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7647, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.423560344827586 ], [ 29.768385189873417, -1.424099310344828 ], [ 29.768654683544302, -1.424099310344828 ], [ 29.768654683544302, -1.423560344827586 ], [ 29.768385189873417, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7648, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.423560344827586 ], [ 29.768654683544302, -1.423829827586207 ], [ 29.76892417721519, -1.423829827586207 ], [ 29.76892417721519, -1.423560344827586 ], [ 29.768654683544302, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7649, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.423560344827586 ], [ 29.769193670886075, -1.424099310344828 ], [ 29.76946316455696, -1.424099310344828 ], [ 29.76946316455696, -1.423560344827586 ], [ 29.769193670886075, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7650, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.423560344827586 ], [ 29.769732658227849, -1.423829827586207 ], [ 29.770002151898733, -1.423829827586207 ], [ 29.770002151898733, -1.423560344827586 ], [ 29.769732658227849, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7651, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.423560344827586 ], [ 29.770002151898733, -1.424099310344828 ], [ 29.770271645569618, -1.424099310344828 ], [ 29.770271645569618, -1.423560344827586 ], [ 29.770002151898733, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7652, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.423560344827586 ], [ 29.770271645569618, -1.423829827586207 ], [ 29.770541139240507, -1.423829827586207 ], [ 29.770541139240507, -1.423560344827586 ], [ 29.770271645569618, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7653, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.423560344827586 ], [ 29.770541139240507, -1.423829827586207 ], [ 29.770810632911392, -1.423829827586207 ], [ 29.770810632911392, -1.423560344827586 ], [ 29.770541139240507, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7654, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.423560344827586 ], [ 29.770810632911392, -1.423829827586207 ], [ 29.771080126582277, -1.423829827586207 ], [ 29.771080126582277, -1.423560344827586 ], [ 29.770810632911392, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7655, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.423560344827586 ], [ 29.771080126582277, -1.423829827586207 ], [ 29.771349620253165, -1.423829827586207 ], [ 29.771349620253165, -1.423560344827586 ], [ 29.771080126582277, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7656, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.423560344827586 ], [ 29.771349620253165, -1.423829827586207 ], [ 29.77161911392405, -1.423829827586207 ], [ 29.77161911392405, -1.423560344827586 ], [ 29.771349620253165, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7657, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.423560344827586 ], [ 29.77161911392405, -1.424099310344828 ], [ 29.771888607594935, -1.424099310344828 ], [ 29.771888607594935, -1.423560344827586 ], [ 29.77161911392405, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7658, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.423560344827586 ], [ 29.771888607594935, -1.424099310344828 ], [ 29.772158101265823, -1.424099310344828 ], [ 29.772158101265823, -1.423560344827586 ], [ 29.771888607594935, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7659, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.423560344827586 ], [ 29.772427594936708, -1.424099310344828 ], [ 29.772697088607593, -1.424099310344828 ], [ 29.772697088607593, -1.423560344827586 ], [ 29.772427594936708, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7660, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.423560344827586 ], [ 29.772697088607593, -1.424099310344828 ], [ 29.772966582278482, -1.424099310344828 ], [ 29.772966582278482, -1.423560344827586 ], [ 29.772697088607593, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7661, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.423560344827586 ], [ 29.772966582278482, -1.424099310344828 ], [ 29.773236075949367, -1.424099310344828 ], [ 29.773236075949367, -1.423560344827586 ], [ 29.772966582278482, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7662, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.423560344827586 ], [ 29.773236075949367, -1.424099310344828 ], [ 29.773505569620252, -1.424099310344828 ], [ 29.773505569620252, -1.423560344827586 ], [ 29.773236075949367, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7663, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.423560344827586 ], [ 29.773505569620252, -1.424099310344828 ], [ 29.77377506329114, -1.424099310344828 ], [ 29.77377506329114, -1.423560344827586 ], [ 29.773505569620252, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7664, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.423560344827586 ], [ 29.77377506329114, -1.424099310344828 ], [ 29.774044556962025, -1.424099310344828 ], [ 29.774044556962025, -1.423560344827586 ], [ 29.77377506329114, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7665, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.423560344827586 ], [ 29.77431405063291, -1.424099310344828 ], [ 29.774853037974683, -1.424099310344828 ], [ 29.774853037974683, -1.423560344827586 ], [ 29.77431405063291, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7666, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.423560344827586 ], [ 29.774853037974683, -1.424099310344828 ], [ 29.775122531645568, -1.424099310344828 ], [ 29.775122531645568, -1.423560344827586 ], [ 29.774853037974683, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7667, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.423560344827586 ], [ 29.775122531645568, -1.424099310344828 ], [ 29.775392025316457, -1.424099310344828 ], [ 29.775392025316457, -1.423560344827586 ], [ 29.775122531645568, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7668, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.423560344827586 ], [ 29.775392025316457, -1.424099310344828 ], [ 29.775661518987341, -1.424099310344828 ], [ 29.775661518987341, -1.423560344827586 ], [ 29.775392025316457, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7669, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.423560344827586 ], [ 29.775661518987341, -1.424099310344828 ], [ 29.775931012658226, -1.424099310344828 ], [ 29.775931012658226, -1.423560344827586 ], [ 29.775661518987341, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7670, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.423560344827586 ], [ 29.775931012658226, -1.424099310344828 ], [ 29.776200506329115, -1.424099310344828 ], [ 29.776200506329115, -1.423560344827586 ], [ 29.775931012658226, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7671, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.423560344827586 ], [ 29.776200506329115, -1.424099310344828 ], [ 29.77647, -1.424099310344828 ], [ 29.77647, -1.423560344827586 ], [ 29.776200506329115, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7672, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.423560344827586 ], [ 29.77647, -1.424099310344828 ], [ 29.776739493670885, -1.424099310344828 ], [ 29.776739493670885, -1.423560344827586 ], [ 29.77647, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7673, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.423560344827586 ], [ 29.776739493670885, -1.424099310344828 ], [ 29.777008987341773, -1.424099310344828 ], [ 29.777008987341773, -1.423560344827586 ], [ 29.776739493670885, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7674, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.423560344827586 ], [ 29.777008987341773, -1.424099310344828 ], [ 29.777278481012658, -1.424099310344828 ], [ 29.777278481012658, -1.423560344827586 ], [ 29.777008987341773, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7675, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.423560344827586 ], [ 29.777278481012658, -1.424099310344828 ], [ 29.777547974683543, -1.424099310344828 ], [ 29.777547974683543, -1.423560344827586 ], [ 29.777278481012658, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7676, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.423560344827586 ], [ 29.777547974683543, -1.424099310344828 ], [ 29.777817468354431, -1.424099310344828 ], [ 29.777817468354431, -1.423560344827586 ], [ 29.777547974683543, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7677, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.423560344827586 ], [ 29.777817468354431, -1.424099310344828 ], [ 29.778086962025316, -1.424099310344828 ], [ 29.778086962025316, -1.423560344827586 ], [ 29.777817468354431, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7678, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.423560344827586 ], [ 29.778086962025316, -1.424099310344828 ], [ 29.778356455696201, -1.424099310344828 ], [ 29.778356455696201, -1.423560344827586 ], [ 29.778086962025316, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7679, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.423560344827586 ], [ 29.778356455696201, -1.424099310344828 ], [ 29.77862594936709, -1.424099310344828 ], [ 29.77862594936709, -1.423560344827586 ], [ 29.778356455696201, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7680, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.423560344827586 ], [ 29.77862594936709, -1.424099310344828 ], [ 29.778895443037975, -1.424099310344828 ], [ 29.778895443037975, -1.423560344827586 ], [ 29.77862594936709, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7681, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.423560344827586 ], [ 29.778895443037975, -1.424099310344828 ], [ 29.77916493670886, -1.424099310344828 ], [ 29.77916493670886, -1.423560344827586 ], [ 29.778895443037975, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7682, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.423560344827586 ], [ 29.77916493670886, -1.424099310344828 ], [ 29.779434430379748, -1.424099310344828 ], [ 29.779434430379748, -1.423560344827586 ], [ 29.77916493670886, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7683, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.423560344827586 ], [ 29.779434430379748, -1.424099310344828 ], [ 29.779703924050633, -1.424099310344828 ], [ 29.779703924050633, -1.423560344827586 ], [ 29.779434430379748, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7684, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.423560344827586 ], [ 29.779703924050633, -1.423829827586207 ], [ 29.779973417721518, -1.423829827586207 ], [ 29.779973417721518, -1.423560344827586 ], [ 29.779703924050633, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7685, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.423560344827586 ], [ 29.779973417721518, -1.424099310344828 ], [ 29.780242911392406, -1.424099310344828 ], [ 29.780242911392406, -1.423560344827586 ], [ 29.779973417721518, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7686, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.423560344827586 ], [ 29.780242911392406, -1.424099310344828 ], [ 29.780512405063291, -1.424099310344828 ], [ 29.780512405063291, -1.423560344827586 ], [ 29.780242911392406, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7687, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.423560344827586 ], [ 29.782398860759493, -1.424099310344828 ], [ 29.782668354430381, -1.424099310344828 ], [ 29.782668354430381, -1.423560344827586 ], [ 29.782398860759493, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7688, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.423560344827586 ], [ 29.784015822784809, -1.424099310344828 ], [ 29.784285316455698, -1.424099310344828 ], [ 29.784285316455698, -1.423560344827586 ], [ 29.784015822784809, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7689, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.423560344827586 ], [ 29.784824303797468, -1.423829827586207 ], [ 29.785093797468356, -1.423829827586207 ], [ 29.785093797468356, -1.423560344827586 ], [ 29.784824303797468, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7690, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.423560344827586 ], [ 29.785093797468356, -1.423829827586207 ], [ 29.785363291139241, -1.423829827586207 ], [ 29.785363291139241, -1.423560344827586 ], [ 29.785093797468356, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7691, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.423560344827586 ], [ 29.785363291139241, -1.423829827586207 ], [ 29.785632784810126, -1.423829827586207 ], [ 29.785632784810126, -1.423560344827586 ], [ 29.785363291139241, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7692, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.423560344827586 ], [ 29.789675189873417, -1.424099310344828 ], [ 29.789944683544302, -1.424099310344828 ], [ 29.789944683544302, -1.423560344827586 ], [ 29.789675189873417, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7693, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.423560344827586 ], [ 29.789944683544302, -1.424099310344828 ], [ 29.790214177215191, -1.424099310344828 ], [ 29.790214177215191, -1.423560344827586 ], [ 29.789944683544302, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7694, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.423560344827586 ], [ 29.790214177215191, -1.423829827586207 ], [ 29.790483670886076, -1.423829827586207 ], [ 29.790483670886076, -1.423560344827586 ], [ 29.790214177215191, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7695, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.423560344827586 ], [ 29.790483670886076, -1.423829827586207 ], [ 29.790753164556961, -1.423829827586207 ], [ 29.790753164556961, -1.423560344827586 ], [ 29.790483670886076, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7696, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.423560344827586 ], [ 29.790753164556961, -1.423829827586207 ], [ 29.791022658227849, -1.423829827586207 ], [ 29.791022658227849, -1.423560344827586 ], [ 29.790753164556961, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7697, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.423560344827586 ], [ 29.791022658227849, -1.423829827586207 ], [ 29.791292151898734, -1.423829827586207 ], [ 29.791292151898734, -1.423560344827586 ], [ 29.791022658227849, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7698, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.423560344827586 ], [ 29.791292151898734, -1.423829827586207 ], [ 29.791561645569619, -1.423829827586207 ], [ 29.791561645569619, -1.423560344827586 ], [ 29.791292151898734, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7699, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.423560344827586 ], [ 29.791561645569619, -1.423829827586207 ], [ 29.791831139240507, -1.423829827586207 ], [ 29.791831139240507, -1.423560344827586 ], [ 29.791561645569619, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7700, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.423560344827586 ], [ 29.791831139240507, -1.423829827586207 ], [ 29.792100632911392, -1.423829827586207 ], [ 29.792100632911392, -1.423560344827586 ], [ 29.791831139240507, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7701, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.423560344827586 ], [ 29.792100632911392, -1.423829827586207 ], [ 29.792370126582277, -1.423829827586207 ], [ 29.792370126582277, -1.423560344827586 ], [ 29.792100632911392, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7702, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.423560344827586 ], [ 29.792370126582277, -1.424099310344828 ], [ 29.792909113924051, -1.424099310344828 ], [ 29.792909113924051, -1.423560344827586 ], [ 29.792370126582277, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7703, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.423560344827586 ], [ 29.801263417721518, -1.423829827586207 ], [ 29.801532911392407, -1.423829827586207 ], [ 29.801532911392407, -1.423560344827586 ], [ 29.801263417721518, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7704, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.423560344827586 ], [ 29.801532911392407, -1.423829827586207 ], [ 29.802071898734177, -1.423829827586207 ], [ 29.802071898734177, -1.423560344827586 ], [ 29.801532911392407, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7705, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.423560344827586 ], [ 29.802071898734177, -1.423829827586207 ], [ 29.802341392405065, -1.423829827586207 ], [ 29.802341392405065, -1.423560344827586 ], [ 29.802071898734177, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7706, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.423560344827586 ], [ 29.802341392405065, -1.423829827586207 ], [ 29.80261088607595, -1.423829827586207 ], [ 29.80261088607595, -1.423560344827586 ], [ 29.802341392405065, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7707, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.423560344827586 ], [ 29.80261088607595, -1.423829827586207 ], [ 29.802880379746835, -1.423829827586207 ], [ 29.802880379746835, -1.423560344827586 ], [ 29.80261088607595, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7708, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.423560344827586 ], [ 29.802880379746835, -1.423829827586207 ], [ 29.803149873417723, -1.423829827586207 ], [ 29.803149873417723, -1.423560344827586 ], [ 29.802880379746835, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7709, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.423560344827586 ], [ 29.803149873417723, -1.423829827586207 ], [ 29.803419367088608, -1.423829827586207 ], [ 29.803419367088608, -1.423560344827586 ], [ 29.803149873417723, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7710, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.423560344827586 ], [ 29.803419367088608, -1.423829827586207 ], [ 29.803688860759493, -1.423829827586207 ], [ 29.803688860759493, -1.423560344827586 ], [ 29.803419367088608, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7711, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.423560344827586 ], [ 29.803688860759493, -1.423829827586207 ], [ 29.803958354430382, -1.423829827586207 ], [ 29.803958354430382, -1.423560344827586 ], [ 29.803688860759493, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7712, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.423560344827586 ], [ 29.803958354430382, -1.423829827586207 ], [ 29.804227848101267, -1.423829827586207 ], [ 29.804227848101267, -1.423560344827586 ], [ 29.803958354430382, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7713, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.423560344827586 ], [ 29.804227848101267, -1.423829827586207 ], [ 29.804497341772151, -1.423829827586207 ], [ 29.804497341772151, -1.423560344827586 ], [ 29.804227848101267, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7714, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.423560344827586 ], [ 29.804497341772151, -1.423829827586207 ], [ 29.80476683544304, -1.423829827586207 ], [ 29.80476683544304, -1.423560344827586 ], [ 29.804497341772151, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7715, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.423560344827586 ], [ 29.80476683544304, -1.423829827586207 ], [ 29.805036329113925, -1.423829827586207 ], [ 29.805036329113925, -1.423560344827586 ], [ 29.80476683544304, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7716, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.423560344827586 ], [ 29.805036329113925, -1.423829827586207 ], [ 29.80530582278481, -1.423829827586207 ], [ 29.80530582278481, -1.423560344827586 ], [ 29.805036329113925, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7717, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.423560344827586 ], [ 29.80530582278481, -1.423829827586207 ], [ 29.805575316455698, -1.423829827586207 ], [ 29.805575316455698, -1.423560344827586 ], [ 29.80530582278481, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.423560344827586 ], [ 29.805575316455698, -1.423829827586207 ], [ 29.805844810126583, -1.423829827586207 ], [ 29.805844810126583, -1.423560344827586 ], [ 29.805575316455698, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7719, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.423560344827586 ], [ 29.805844810126583, -1.423829827586207 ], [ 29.806114303797468, -1.423829827586207 ], [ 29.806114303797468, -1.423560344827586 ], [ 29.805844810126583, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7720, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.423560344827586 ], [ 29.806114303797468, -1.423829827586207 ], [ 29.806383797468357, -1.423829827586207 ], [ 29.806383797468357, -1.423560344827586 ], [ 29.806114303797468, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7721, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.423560344827586 ], [ 29.806383797468357, -1.423829827586207 ], [ 29.806653291139241, -1.423829827586207 ], [ 29.806653291139241, -1.423560344827586 ], [ 29.806383797468357, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7722, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.423560344827586 ], [ 29.806653291139241, -1.423829827586207 ], [ 29.806922784810126, -1.423829827586207 ], [ 29.806922784810126, -1.423560344827586 ], [ 29.806653291139241, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.423560344827586 ], [ 29.806922784810126, -1.423829827586207 ], [ 29.807192278481015, -1.423829827586207 ], [ 29.807192278481015, -1.423560344827586 ], [ 29.806922784810126, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7724, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.423560344827586 ], [ 29.808539746835443, -1.423829827586207 ], [ 29.809078734177216, -1.423829827586207 ], [ 29.809078734177216, -1.423560344827586 ], [ 29.808539746835443, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7725, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.423829827586207 ], [ 29.756796962025316, -1.424099310344828 ], [ 29.757066455696201, -1.424099310344828 ], [ 29.757066455696201, -1.423829827586207 ], [ 29.756796962025316, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7726, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.423829827586207 ], [ 29.757066455696201, -1.424099310344828 ], [ 29.757335949367089, -1.424099310344828 ], [ 29.757335949367089, -1.423829827586207 ], [ 29.757066455696201, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7727, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.423829827586207 ], [ 29.757335949367089, -1.424099310344828 ], [ 29.757605443037974, -1.424099310344828 ], [ 29.757605443037974, -1.423829827586207 ], [ 29.757335949367089, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7728, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.423829827586207 ], [ 29.757605443037974, -1.424099310344828 ], [ 29.757874936708859, -1.424099310344828 ], [ 29.757874936708859, -1.423829827586207 ], [ 29.757605443037974, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7729, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.423829827586207 ], [ 29.757874936708859, -1.424099310344828 ], [ 29.758144430379748, -1.424099310344828 ], [ 29.758144430379748, -1.423829827586207 ], [ 29.757874936708859, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7730, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.423829827586207 ], [ 29.758144430379748, -1.424099310344828 ], [ 29.758413924050632, -1.424099310344828 ], [ 29.758413924050632, -1.423829827586207 ], [ 29.758144430379748, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7731, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.423829827586207 ], [ 29.758413924050632, -1.424099310344828 ], [ 29.758683417721517, -1.424099310344828 ], [ 29.758683417721517, -1.423829827586207 ], [ 29.758413924050632, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7732, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.423829827586207 ], [ 29.758683417721517, -1.424099310344828 ], [ 29.758952911392406, -1.424099310344828 ], [ 29.758952911392406, -1.423829827586207 ], [ 29.758683417721517, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7733, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.423829827586207 ], [ 29.758952911392406, -1.424099310344828 ], [ 29.759222405063291, -1.424099310344828 ], [ 29.759222405063291, -1.423829827586207 ], [ 29.758952911392406, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7734, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.423829827586207 ], [ 29.759222405063291, -1.424099310344828 ], [ 29.759491898734176, -1.424099310344828 ], [ 29.759491898734176, -1.423829827586207 ], [ 29.759222405063291, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7735, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.423829827586207 ], [ 29.759491898734176, -1.424099310344828 ], [ 29.759761392405064, -1.424099310344828 ], [ 29.759761392405064, -1.423829827586207 ], [ 29.759491898734176, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7736, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.423829827586207 ], [ 29.759761392405064, -1.424099310344828 ], [ 29.760030886075949, -1.424099310344828 ], [ 29.760030886075949, -1.423829827586207 ], [ 29.759761392405064, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7737, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.423829827586207 ], [ 29.760030886075949, -1.424099310344828 ], [ 29.760300379746834, -1.424099310344828 ], [ 29.760300379746834, -1.423829827586207 ], [ 29.760030886075949, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7738, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.423829827586207 ], [ 29.760300379746834, -1.424099310344828 ], [ 29.760569873417722, -1.424099310344828 ], [ 29.760569873417722, -1.423829827586207 ], [ 29.760300379746834, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7739, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.423829827586207 ], [ 29.760569873417722, -1.424099310344828 ], [ 29.760839367088607, -1.424099310344828 ], [ 29.760839367088607, -1.423829827586207 ], [ 29.760569873417722, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7740, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.423829827586207 ], [ 29.760839367088607, -1.424099310344828 ], [ 29.761108860759492, -1.424099310344828 ], [ 29.761108860759492, -1.423829827586207 ], [ 29.760839367088607, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7741, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.423829827586207 ], [ 29.761108860759492, -1.424099310344828 ], [ 29.761378354430381, -1.424099310344828 ], [ 29.761378354430381, -1.423829827586207 ], [ 29.761108860759492, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7742, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.423829827586207 ], [ 29.761378354430381, -1.424099310344828 ], [ 29.761647848101266, -1.424099310344828 ], [ 29.761647848101266, -1.423829827586207 ], [ 29.761378354430381, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7743, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.423829827586207 ], [ 29.761647848101266, -1.424099310344828 ], [ 29.761917341772151, -1.424099310344828 ], [ 29.761917341772151, -1.423829827586207 ], [ 29.761647848101266, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7744, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.423829827586207 ], [ 29.761917341772151, -1.424099310344828 ], [ 29.762186835443039, -1.424099310344828 ], [ 29.762186835443039, -1.423829827586207 ], [ 29.761917341772151, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7745, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.423829827586207 ], [ 29.762186835443039, -1.424099310344828 ], [ 29.762456329113924, -1.424099310344828 ], [ 29.762456329113924, -1.423829827586207 ], [ 29.762186835443039, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7746, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.423829827586207 ], [ 29.762456329113924, -1.424099310344828 ], [ 29.762725822784809, -1.424099310344828 ], [ 29.762725822784809, -1.423829827586207 ], [ 29.762456329113924, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7747, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.423829827586207 ], [ 29.762725822784809, -1.424099310344828 ], [ 29.762995316455697, -1.424099310344828 ], [ 29.762995316455697, -1.423829827586207 ], [ 29.762725822784809, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7748, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.423829827586207 ], [ 29.762995316455697, -1.424099310344828 ], [ 29.763264810126582, -1.424099310344828 ], [ 29.763264810126582, -1.423829827586207 ], [ 29.762995316455697, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7749, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.423829827586207 ], [ 29.763264810126582, -1.424099310344828 ], [ 29.763534303797467, -1.424099310344828 ], [ 29.763534303797467, -1.423829827586207 ], [ 29.763264810126582, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7750, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.423829827586207 ], [ 29.763534303797467, -1.424099310344828 ], [ 29.763803797468356, -1.424099310344828 ], [ 29.763803797468356, -1.423829827586207 ], [ 29.763534303797467, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7751, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.423829827586207 ], [ 29.763803797468356, -1.424099310344828 ], [ 29.76407329113924, -1.424099310344828 ], [ 29.76407329113924, -1.423829827586207 ], [ 29.763803797468356, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7752, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.423829827586207 ], [ 29.76407329113924, -1.424099310344828 ], [ 29.764342784810125, -1.424099310344828 ], [ 29.764342784810125, -1.423829827586207 ], [ 29.76407329113924, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7753, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.423829827586207 ], [ 29.764342784810125, -1.424099310344828 ], [ 29.764612278481014, -1.424099310344828 ], [ 29.764612278481014, -1.423829827586207 ], [ 29.764342784810125, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7754, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.423829827586207 ], [ 29.764881772151899, -1.424099310344828 ], [ 29.765151265822784, -1.424099310344828 ], [ 29.765151265822784, -1.423829827586207 ], [ 29.764881772151899, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7755, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.423829827586207 ], [ 29.765151265822784, -1.424099310344828 ], [ 29.765420759493672, -1.424099310344828 ], [ 29.765420759493672, -1.423829827586207 ], [ 29.765151265822784, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7756, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.423829827586207 ], [ 29.765420759493672, -1.424099310344828 ], [ 29.765959746835442, -1.424099310344828 ], [ 29.765959746835442, -1.423829827586207 ], [ 29.765420759493672, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7757, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.423829827586207 ], [ 29.765959746835442, -1.424368793103448 ], [ 29.766229240506327, -1.424368793103448 ], [ 29.766229240506327, -1.423829827586207 ], [ 29.765959746835442, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7758, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.423829827586207 ], [ 29.766229240506327, -1.424099310344828 ], [ 29.766498734177215, -1.424099310344828 ], [ 29.766498734177215, -1.423829827586207 ], [ 29.766229240506327, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7759, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.423829827586207 ], [ 29.766498734177215, -1.424099310344828 ], [ 29.7667682278481, -1.424099310344828 ], [ 29.7667682278481, -1.423829827586207 ], [ 29.766498734177215, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7760, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.423829827586207 ], [ 29.767037721518985, -1.424099310344828 ], [ 29.767307215189874, -1.424099310344828 ], [ 29.767307215189874, -1.423829827586207 ], [ 29.767037721518985, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7761, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.423829827586207 ], [ 29.767307215189874, -1.424099310344828 ], [ 29.767576708860759, -1.424099310344828 ], [ 29.767576708860759, -1.423829827586207 ], [ 29.767307215189874, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7762, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.423829827586207 ], [ 29.767846202531643, -1.424368793103448 ], [ 29.768115696202532, -1.424368793103448 ], [ 29.768115696202532, -1.423829827586207 ], [ 29.767846202531643, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7763, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.423829827586207 ], [ 29.768115696202532, -1.424099310344828 ], [ 29.768385189873417, -1.424099310344828 ], [ 29.768385189873417, -1.423829827586207 ], [ 29.768115696202532, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7764, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.423829827586207 ], [ 29.768654683544302, -1.424368793103448 ], [ 29.76892417721519, -1.424368793103448 ], [ 29.76892417721519, -1.423829827586207 ], [ 29.768654683544302, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7765, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.423829827586207 ], [ 29.76892417721519, -1.424099310344828 ], [ 29.769193670886075, -1.424099310344828 ], [ 29.769193670886075, -1.423829827586207 ], [ 29.76892417721519, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7766, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.423829827586207 ], [ 29.76946316455696, -1.424368793103448 ], [ 29.769732658227849, -1.424368793103448 ], [ 29.769732658227849, -1.423829827586207 ], [ 29.76946316455696, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7767, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.423829827586207 ], [ 29.769732658227849, -1.424099310344828 ], [ 29.770002151898733, -1.424099310344828 ], [ 29.770002151898733, -1.423829827586207 ], [ 29.769732658227849, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7768, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.423829827586207 ], [ 29.770271645569618, -1.424368793103448 ], [ 29.770541139240507, -1.424368793103448 ], [ 29.770541139240507, -1.423829827586207 ], [ 29.770271645569618, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7769, "El": 162 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.423829827586207 ], [ 29.770541139240507, -1.424099310344828 ], [ 29.770810632911392, -1.424099310344828 ], [ 29.770810632911392, -1.423829827586207 ], [ 29.770541139240507, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7770, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.423829827586207 ], [ 29.770810632911392, -1.424099310344828 ], [ 29.771080126582277, -1.424099310344828 ], [ 29.771080126582277, -1.423829827586207 ], [ 29.770810632911392, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7771, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.423829827586207 ], [ 29.771080126582277, -1.424099310344828 ], [ 29.771349620253165, -1.424099310344828 ], [ 29.771349620253165, -1.423829827586207 ], [ 29.771080126582277, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7772, "El": 167 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.423829827586207 ], [ 29.771349620253165, -1.424368793103448 ], [ 29.77161911392405, -1.424368793103448 ], [ 29.77161911392405, -1.423829827586207 ], [ 29.771349620253165, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7773, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.423829827586207 ], [ 29.779703924050633, -1.424099310344828 ], [ 29.779973417721518, -1.424099310344828 ], [ 29.779973417721518, -1.423829827586207 ], [ 29.779703924050633, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7774, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.423829827586207 ], [ 29.780512405063291, -1.424099310344828 ], [ 29.780781898734176, -1.424099310344828 ], [ 29.780781898734176, -1.423829827586207 ], [ 29.780512405063291, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7775, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.423829827586207 ], [ 29.781051392405065, -1.424368793103448 ], [ 29.78132088607595, -1.424368793103448 ], [ 29.78132088607595, -1.423829827586207 ], [ 29.781051392405065, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7776, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.423829827586207 ], [ 29.781859873417723, -1.424368793103448 ], [ 29.782129367088608, -1.424368793103448 ], [ 29.782129367088608, -1.423829827586207 ], [ 29.781859873417723, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7777, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.423829827586207 ], [ 29.782668354430381, -1.424368793103448 ], [ 29.782937848101266, -1.424368793103448 ], [ 29.782937848101266, -1.423829827586207 ], [ 29.782668354430381, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7778, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.423829827586207 ], [ 29.783207341772151, -1.424099310344828 ], [ 29.783476835443039, -1.424099310344828 ], [ 29.783476835443039, -1.423829827586207 ], [ 29.783207341772151, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7779, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.423829827586207 ], [ 29.783476835443039, -1.424099310344828 ], [ 29.784015822784809, -1.424099310344828 ], [ 29.784015822784809, -1.423829827586207 ], [ 29.783476835443039, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7780, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.423829827586207 ], [ 29.784285316455698, -1.424099310344828 ], [ 29.784554810126583, -1.424099310344828 ], [ 29.784554810126583, -1.423829827586207 ], [ 29.784285316455698, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7781, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.423829827586207 ], [ 29.784554810126583, -1.424099310344828 ], [ 29.784824303797468, -1.424099310344828 ], [ 29.784824303797468, -1.423829827586207 ], [ 29.784554810126583, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7782, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.423829827586207 ], [ 29.784824303797468, -1.424099310344828 ], [ 29.785093797468356, -1.424099310344828 ], [ 29.785093797468356, -1.423829827586207 ], [ 29.784824303797468, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7783, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.423829827586207 ], [ 29.788597215189874, -1.424368793103448 ], [ 29.788866708860759, -1.424368793103448 ], [ 29.788866708860759, -1.423829827586207 ], [ 29.788597215189874, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7784, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.423829827586207 ], [ 29.788866708860759, -1.424099310344828 ], [ 29.789136202531644, -1.424099310344828 ], [ 29.789136202531644, -1.423829827586207 ], [ 29.788866708860759, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7785, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.423829827586207 ], [ 29.789136202531644, -1.424099310344828 ], [ 29.789405696202532, -1.424099310344828 ], [ 29.789405696202532, -1.423829827586207 ], [ 29.789136202531644, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7786, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.423829827586207 ], [ 29.789405696202532, -1.424099310344828 ], [ 29.789675189873417, -1.424099310344828 ], [ 29.789675189873417, -1.423829827586207 ], [ 29.789405696202532, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7787, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.423829827586207 ], [ 29.790214177215191, -1.424368793103448 ], [ 29.790483670886076, -1.424368793103448 ], [ 29.790483670886076, -1.423829827586207 ], [ 29.790214177215191, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7788, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.423829827586207 ], [ 29.790483670886076, -1.424099310344828 ], [ 29.790753164556961, -1.424099310344828 ], [ 29.790753164556961, -1.423829827586207 ], [ 29.790483670886076, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7789, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.423829827586207 ], [ 29.790753164556961, -1.424099310344828 ], [ 29.791022658227849, -1.424099310344828 ], [ 29.791022658227849, -1.423829827586207 ], [ 29.790753164556961, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7790, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.423829827586207 ], [ 29.791022658227849, -1.424099310344828 ], [ 29.791292151898734, -1.424099310344828 ], [ 29.791292151898734, -1.423829827586207 ], [ 29.791022658227849, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7791, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.423829827586207 ], [ 29.791292151898734, -1.424099310344828 ], [ 29.791561645569619, -1.424099310344828 ], [ 29.791561645569619, -1.423829827586207 ], [ 29.791292151898734, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7792, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.423829827586207 ], [ 29.791561645569619, -1.424099310344828 ], [ 29.791831139240507, -1.424099310344828 ], [ 29.791831139240507, -1.423829827586207 ], [ 29.791561645569619, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7793, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.423829827586207 ], [ 29.791831139240507, -1.424099310344828 ], [ 29.792100632911392, -1.424099310344828 ], [ 29.792100632911392, -1.423829827586207 ], [ 29.791831139240507, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7794, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.423829827586207 ], [ 29.792100632911392, -1.424099310344828 ], [ 29.792370126582277, -1.424099310344828 ], [ 29.792370126582277, -1.423829827586207 ], [ 29.792100632911392, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7795, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.423829827586207 ], [ 29.800724430379748, -1.424099310344828 ], [ 29.800993924050633, -1.424099310344828 ], [ 29.800993924050633, -1.423829827586207 ], [ 29.800724430379748, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7796, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.423829827586207 ], [ 29.800993924050633, -1.424099310344828 ], [ 29.801263417721518, -1.424099310344828 ], [ 29.801263417721518, -1.423829827586207 ], [ 29.800993924050633, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7797, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.423829827586207 ], [ 29.801263417721518, -1.424099310344828 ], [ 29.801532911392407, -1.424099310344828 ], [ 29.801532911392407, -1.423829827586207 ], [ 29.801263417721518, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7798, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.423829827586207 ], [ 29.801532911392407, -1.424099310344828 ], [ 29.802071898734177, -1.424099310344828 ], [ 29.802071898734177, -1.423829827586207 ], [ 29.801532911392407, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7799, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.423829827586207 ], [ 29.802071898734177, -1.424099310344828 ], [ 29.802341392405065, -1.424099310344828 ], [ 29.802341392405065, -1.423829827586207 ], [ 29.802071898734177, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7800, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.423829827586207 ], [ 29.802341392405065, -1.424099310344828 ], [ 29.80261088607595, -1.424099310344828 ], [ 29.80261088607595, -1.423829827586207 ], [ 29.802341392405065, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7801, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.423829827586207 ], [ 29.80261088607595, -1.424099310344828 ], [ 29.802880379746835, -1.424099310344828 ], [ 29.802880379746835, -1.423829827586207 ], [ 29.80261088607595, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7802, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.423829827586207 ], [ 29.802880379746835, -1.424099310344828 ], [ 29.803149873417723, -1.424099310344828 ], [ 29.803149873417723, -1.423829827586207 ], [ 29.802880379746835, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7803, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.423829827586207 ], [ 29.803149873417723, -1.424099310344828 ], [ 29.803419367088608, -1.424099310344828 ], [ 29.803419367088608, -1.423829827586207 ], [ 29.803149873417723, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7804, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.423829827586207 ], [ 29.803419367088608, -1.424099310344828 ], [ 29.803688860759493, -1.424099310344828 ], [ 29.803688860759493, -1.423829827586207 ], [ 29.803419367088608, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7805, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.423829827586207 ], [ 29.803688860759493, -1.424099310344828 ], [ 29.803958354430382, -1.424099310344828 ], [ 29.803958354430382, -1.423829827586207 ], [ 29.803688860759493, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7806, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.423829827586207 ], [ 29.803958354430382, -1.424099310344828 ], [ 29.804227848101267, -1.424099310344828 ], [ 29.804227848101267, -1.423829827586207 ], [ 29.803958354430382, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7807, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.423829827586207 ], [ 29.804227848101267, -1.424099310344828 ], [ 29.804497341772151, -1.424099310344828 ], [ 29.804497341772151, -1.423829827586207 ], [ 29.804227848101267, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7808, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.423829827586207 ], [ 29.804497341772151, -1.424099310344828 ], [ 29.80476683544304, -1.424099310344828 ], [ 29.80476683544304, -1.423829827586207 ], [ 29.804497341772151, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7809, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.423829827586207 ], [ 29.80476683544304, -1.424099310344828 ], [ 29.805036329113925, -1.424099310344828 ], [ 29.805036329113925, -1.423829827586207 ], [ 29.80476683544304, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7810, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.423829827586207 ], [ 29.805036329113925, -1.424099310344828 ], [ 29.80530582278481, -1.424099310344828 ], [ 29.80530582278481, -1.423829827586207 ], [ 29.805036329113925, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7811, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.423829827586207 ], [ 29.80530582278481, -1.424099310344828 ], [ 29.805575316455698, -1.424099310344828 ], [ 29.805575316455698, -1.423829827586207 ], [ 29.80530582278481, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7812, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.423829827586207 ], [ 29.805575316455698, -1.424099310344828 ], [ 29.805844810126583, -1.424099310344828 ], [ 29.805844810126583, -1.423829827586207 ], [ 29.805575316455698, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7813, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.423829827586207 ], [ 29.805844810126583, -1.424099310344828 ], [ 29.806114303797468, -1.424099310344828 ], [ 29.806114303797468, -1.423829827586207 ], [ 29.805844810126583, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7814, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.423829827586207 ], [ 29.806114303797468, -1.424099310344828 ], [ 29.806383797468357, -1.424099310344828 ], [ 29.806383797468357, -1.423829827586207 ], [ 29.806114303797468, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7815, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.423829827586207 ], [ 29.806383797468357, -1.424099310344828 ], [ 29.806653291139241, -1.424099310344828 ], [ 29.806653291139241, -1.423829827586207 ], [ 29.806383797468357, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7816, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.423829827586207 ], [ 29.806653291139241, -1.424099310344828 ], [ 29.806922784810126, -1.424099310344828 ], [ 29.806922784810126, -1.423829827586207 ], [ 29.806653291139241, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7817, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.423829827586207 ], [ 29.806922784810126, -1.424099310344828 ], [ 29.807192278481015, -1.424099310344828 ], [ 29.807192278481015, -1.423829827586207 ], [ 29.806922784810126, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7818, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.423829827586207 ], [ 29.807192278481015, -1.424099310344828 ], [ 29.8074617721519, -1.424099310344828 ], [ 29.8074617721519, -1.423829827586207 ], [ 29.807192278481015, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7819, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.423829827586207 ], [ 29.808000759493673, -1.424099310344828 ], [ 29.808539746835443, -1.424099310344828 ], [ 29.808539746835443, -1.423829827586207 ], [ 29.808000759493673, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7820, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.423829827586207 ], [ 29.808539746835443, -1.424099310344828 ], [ 29.809078734177216, -1.424099310344828 ], [ 29.809078734177216, -1.423829827586207 ], [ 29.808539746835443, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7821, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.423829827586207 ], [ 29.809078734177216, -1.424099310344828 ], [ 29.809617721518986, -1.424099310344828 ], [ 29.809617721518986, -1.423829827586207 ], [ 29.809078734177216, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7822, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.424099310344828 ], [ 29.757335949367089, -1.424368793103448 ], [ 29.757605443037974, -1.424368793103448 ], [ 29.757605443037974, -1.424099310344828 ], [ 29.757335949367089, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7823, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.424099310344828 ], [ 29.757605443037974, -1.424368793103448 ], [ 29.757874936708859, -1.424368793103448 ], [ 29.757874936708859, -1.424099310344828 ], [ 29.757605443037974, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7824, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.424099310344828 ], [ 29.757874936708859, -1.424368793103448 ], [ 29.758144430379748, -1.424368793103448 ], [ 29.758144430379748, -1.424099310344828 ], [ 29.757874936708859, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7825, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.424099310344828 ], [ 29.758144430379748, -1.424368793103448 ], [ 29.758413924050632, -1.424368793103448 ], [ 29.758413924050632, -1.424099310344828 ], [ 29.758144430379748, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7826, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.424099310344828 ], [ 29.758413924050632, -1.424368793103448 ], [ 29.758683417721517, -1.424368793103448 ], [ 29.758683417721517, -1.424099310344828 ], [ 29.758413924050632, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7827, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.424099310344828 ], [ 29.758683417721517, -1.424368793103448 ], [ 29.758952911392406, -1.424368793103448 ], [ 29.758952911392406, -1.424099310344828 ], [ 29.758683417721517, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7828, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.424099310344828 ], [ 29.758952911392406, -1.424368793103448 ], [ 29.759222405063291, -1.424368793103448 ], [ 29.759222405063291, -1.424099310344828 ], [ 29.758952911392406, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7829, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.424099310344828 ], [ 29.759222405063291, -1.424368793103448 ], [ 29.759491898734176, -1.424368793103448 ], [ 29.759491898734176, -1.424099310344828 ], [ 29.759222405063291, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7830, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.424099310344828 ], [ 29.759491898734176, -1.424368793103448 ], [ 29.759761392405064, -1.424368793103448 ], [ 29.759761392405064, -1.424099310344828 ], [ 29.759491898734176, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7831, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.424099310344828 ], [ 29.759761392405064, -1.424368793103448 ], [ 29.760030886075949, -1.424368793103448 ], [ 29.760030886075949, -1.424099310344828 ], [ 29.759761392405064, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7832, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.424099310344828 ], [ 29.760030886075949, -1.424368793103448 ], [ 29.760300379746834, -1.424368793103448 ], [ 29.760300379746834, -1.424099310344828 ], [ 29.760030886075949, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7833, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.424099310344828 ], [ 29.760300379746834, -1.424368793103448 ], [ 29.760569873417722, -1.424368793103448 ], [ 29.760569873417722, -1.424099310344828 ], [ 29.760300379746834, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7834, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.424099310344828 ], [ 29.760569873417722, -1.424368793103448 ], [ 29.760839367088607, -1.424368793103448 ], [ 29.760839367088607, -1.424099310344828 ], [ 29.760569873417722, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7835, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.424099310344828 ], [ 29.760839367088607, -1.424368793103448 ], [ 29.761108860759492, -1.424368793103448 ], [ 29.761108860759492, -1.424099310344828 ], [ 29.760839367088607, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7836, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.424099310344828 ], [ 29.761108860759492, -1.424368793103448 ], [ 29.761378354430381, -1.424368793103448 ], [ 29.761378354430381, -1.424099310344828 ], [ 29.761108860759492, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7837, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.424099310344828 ], [ 29.761378354430381, -1.424368793103448 ], [ 29.761647848101266, -1.424368793103448 ], [ 29.761647848101266, -1.424099310344828 ], [ 29.761378354430381, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7838, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.424099310344828 ], [ 29.761647848101266, -1.424368793103448 ], [ 29.761917341772151, -1.424368793103448 ], [ 29.761917341772151, -1.424099310344828 ], [ 29.761647848101266, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7839, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.424099310344828 ], [ 29.761917341772151, -1.424368793103448 ], [ 29.762186835443039, -1.424368793103448 ], [ 29.762186835443039, -1.424099310344828 ], [ 29.761917341772151, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7840, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.424099310344828 ], [ 29.762186835443039, -1.424368793103448 ], [ 29.762456329113924, -1.424368793103448 ], [ 29.762456329113924, -1.424099310344828 ], [ 29.762186835443039, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7841, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.424099310344828 ], [ 29.762456329113924, -1.424368793103448 ], [ 29.762725822784809, -1.424368793103448 ], [ 29.762725822784809, -1.424099310344828 ], [ 29.762456329113924, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7842, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.424099310344828 ], [ 29.762725822784809, -1.424368793103448 ], [ 29.762995316455697, -1.424368793103448 ], [ 29.762995316455697, -1.424099310344828 ], [ 29.762725822784809, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7843, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.424099310344828 ], [ 29.762995316455697, -1.424368793103448 ], [ 29.763264810126582, -1.424368793103448 ], [ 29.763264810126582, -1.424099310344828 ], [ 29.762995316455697, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7844, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.424099310344828 ], [ 29.763264810126582, -1.424368793103448 ], [ 29.763534303797467, -1.424368793103448 ], [ 29.763534303797467, -1.424099310344828 ], [ 29.763264810126582, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7845, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.424099310344828 ], [ 29.763534303797467, -1.424368793103448 ], [ 29.763803797468356, -1.424368793103448 ], [ 29.763803797468356, -1.424099310344828 ], [ 29.763534303797467, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7846, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.424099310344828 ], [ 29.763803797468356, -1.424368793103448 ], [ 29.76407329113924, -1.424368793103448 ], [ 29.76407329113924, -1.424099310344828 ], [ 29.763803797468356, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7847, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.424099310344828 ], [ 29.76407329113924, -1.424368793103448 ], [ 29.764342784810125, -1.424368793103448 ], [ 29.764342784810125, -1.424099310344828 ], [ 29.76407329113924, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7848, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.424099310344828 ], [ 29.764342784810125, -1.424368793103448 ], [ 29.764612278481014, -1.424368793103448 ], [ 29.764612278481014, -1.424099310344828 ], [ 29.764342784810125, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7849, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.424099310344828 ], [ 29.764612278481014, -1.424368793103448 ], [ 29.764881772151899, -1.424368793103448 ], [ 29.764881772151899, -1.424099310344828 ], [ 29.764612278481014, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7850, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.424099310344828 ], [ 29.764881772151899, -1.424368793103448 ], [ 29.765151265822784, -1.424368793103448 ], [ 29.765151265822784, -1.424099310344828 ], [ 29.764881772151899, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7851, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.424099310344828 ], [ 29.765151265822784, -1.424368793103448 ], [ 29.765420759493672, -1.424368793103448 ], [ 29.765420759493672, -1.424099310344828 ], [ 29.765151265822784, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7852, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.424099310344828 ], [ 29.765420759493672, -1.424368793103448 ], [ 29.765959746835442, -1.424368793103448 ], [ 29.765959746835442, -1.424099310344828 ], [ 29.765420759493672, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7853, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.424099310344828 ], [ 29.766229240506327, -1.424368793103448 ], [ 29.766498734177215, -1.424368793103448 ], [ 29.766498734177215, -1.424099310344828 ], [ 29.766229240506327, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7854, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.424099310344828 ], [ 29.766498734177215, -1.424368793103448 ], [ 29.7667682278481, -1.424368793103448 ], [ 29.7667682278481, -1.424099310344828 ], [ 29.766498734177215, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7855, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.424099310344828 ], [ 29.7667682278481, -1.424368793103448 ], [ 29.767037721518985, -1.424368793103448 ], [ 29.767037721518985, -1.424099310344828 ], [ 29.7667682278481, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7856, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.424099310344828 ], [ 29.767307215189874, -1.424368793103448 ], [ 29.767576708860759, -1.424368793103448 ], [ 29.767576708860759, -1.424099310344828 ], [ 29.767307215189874, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7857, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.424099310344828 ], [ 29.767576708860759, -1.424368793103448 ], [ 29.767846202531643, -1.424368793103448 ], [ 29.767846202531643, -1.424099310344828 ], [ 29.767576708860759, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7858, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.424099310344828 ], [ 29.768115696202532, -1.424368793103448 ], [ 29.768385189873417, -1.424368793103448 ], [ 29.768385189873417, -1.424099310344828 ], [ 29.768115696202532, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7859, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.424099310344828 ], [ 29.768385189873417, -1.424368793103448 ], [ 29.768654683544302, -1.424368793103448 ], [ 29.768654683544302, -1.424099310344828 ], [ 29.768385189873417, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7860, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.424099310344828 ], [ 29.769193670886075, -1.424368793103448 ], [ 29.76946316455696, -1.424368793103448 ], [ 29.76946316455696, -1.424099310344828 ], [ 29.769193670886075, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7861, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.424099310344828 ], [ 29.770002151898733, -1.424368793103448 ], [ 29.770271645569618, -1.424368793103448 ], [ 29.770271645569618, -1.424099310344828 ], [ 29.770002151898733, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7862, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.424099310344828 ], [ 29.770810632911392, -1.424368793103448 ], [ 29.771080126582277, -1.424368793103448 ], [ 29.771080126582277, -1.424099310344828 ], [ 29.770810632911392, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7863, "El": 160 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.424099310344828 ], [ 29.771888607594935, -1.424368793103448 ], [ 29.772158101265823, -1.424368793103448 ], [ 29.772158101265823, -1.424099310344828 ], [ 29.771888607594935, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7864, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.424099310344828 ], [ 29.772697088607593, -1.424368793103448 ], [ 29.772966582278482, -1.424368793103448 ], [ 29.772966582278482, -1.424099310344828 ], [ 29.772697088607593, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7865, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.424099310344828 ], [ 29.772966582278482, -1.424368793103448 ], [ 29.773236075949367, -1.424368793103448 ], [ 29.773236075949367, -1.424099310344828 ], [ 29.772966582278482, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7866, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.424099310344828 ], [ 29.773505569620252, -1.424368793103448 ], [ 29.77377506329114, -1.424368793103448 ], [ 29.77377506329114, -1.424099310344828 ], [ 29.773505569620252, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7867, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.424099310344828 ], [ 29.77377506329114, -1.424368793103448 ], [ 29.774044556962025, -1.424368793103448 ], [ 29.774044556962025, -1.424099310344828 ], [ 29.77377506329114, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7868, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.424099310344828 ], [ 29.77431405063291, -1.424368793103448 ], [ 29.774853037974683, -1.424368793103448 ], [ 29.774853037974683, -1.424099310344828 ], [ 29.77431405063291, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7869, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.424099310344828 ], [ 29.774853037974683, -1.424368793103448 ], [ 29.775122531645568, -1.424368793103448 ], [ 29.775122531645568, -1.424099310344828 ], [ 29.774853037974683, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7870, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.424099310344828 ], [ 29.775122531645568, -1.424368793103448 ], [ 29.775392025316457, -1.424368793103448 ], [ 29.775392025316457, -1.424099310344828 ], [ 29.775122531645568, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7871, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.424099310344828 ], [ 29.775661518987341, -1.424368793103448 ], [ 29.775931012658226, -1.424368793103448 ], [ 29.775931012658226, -1.424099310344828 ], [ 29.775661518987341, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7872, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.424099310344828 ], [ 29.775931012658226, -1.424368793103448 ], [ 29.776200506329115, -1.424368793103448 ], [ 29.776200506329115, -1.424099310344828 ], [ 29.775931012658226, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7873, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.424099310344828 ], [ 29.776200506329115, -1.424368793103448 ], [ 29.77647, -1.424368793103448 ], [ 29.77647, -1.424099310344828 ], [ 29.776200506329115, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7874, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.424099310344828 ], [ 29.77647, -1.424368793103448 ], [ 29.776739493670885, -1.424368793103448 ], [ 29.776739493670885, -1.424099310344828 ], [ 29.77647, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7875, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.424099310344828 ], [ 29.776739493670885, -1.424368793103448 ], [ 29.777008987341773, -1.424368793103448 ], [ 29.777008987341773, -1.424099310344828 ], [ 29.776739493670885, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7876, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.424099310344828 ], [ 29.777008987341773, -1.424368793103448 ], [ 29.777278481012658, -1.424368793103448 ], [ 29.777278481012658, -1.424099310344828 ], [ 29.777008987341773, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7877, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.424099310344828 ], [ 29.777278481012658, -1.424368793103448 ], [ 29.777547974683543, -1.424368793103448 ], [ 29.777547974683543, -1.424099310344828 ], [ 29.777278481012658, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7878, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.424099310344828 ], [ 29.777547974683543, -1.424368793103448 ], [ 29.777817468354431, -1.424368793103448 ], [ 29.777817468354431, -1.424099310344828 ], [ 29.777547974683543, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7879, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.424099310344828 ], [ 29.777817468354431, -1.424368793103448 ], [ 29.778086962025316, -1.424368793103448 ], [ 29.778086962025316, -1.424099310344828 ], [ 29.777817468354431, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7880, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.424099310344828 ], [ 29.778086962025316, -1.424368793103448 ], [ 29.778356455696201, -1.424368793103448 ], [ 29.778356455696201, -1.424099310344828 ], [ 29.778086962025316, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7881, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.424099310344828 ], [ 29.778356455696201, -1.424368793103448 ], [ 29.77862594936709, -1.424368793103448 ], [ 29.77862594936709, -1.424099310344828 ], [ 29.778356455696201, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7882, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.424099310344828 ], [ 29.77862594936709, -1.424368793103448 ], [ 29.778895443037975, -1.424368793103448 ], [ 29.778895443037975, -1.424099310344828 ], [ 29.77862594936709, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7883, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.424099310344828 ], [ 29.778895443037975, -1.424368793103448 ], [ 29.77916493670886, -1.424368793103448 ], [ 29.77916493670886, -1.424099310344828 ], [ 29.778895443037975, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7884, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.424099310344828 ], [ 29.77916493670886, -1.424368793103448 ], [ 29.779434430379748, -1.424368793103448 ], [ 29.779434430379748, -1.424099310344828 ], [ 29.77916493670886, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7885, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.424099310344828 ], [ 29.779434430379748, -1.424368793103448 ], [ 29.779703924050633, -1.424368793103448 ], [ 29.779703924050633, -1.424099310344828 ], [ 29.779434430379748, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7886, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.424099310344828 ], [ 29.779703924050633, -1.424368793103448 ], [ 29.779973417721518, -1.424368793103448 ], [ 29.779973417721518, -1.424099310344828 ], [ 29.779703924050633, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7887, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.424099310344828 ], [ 29.779973417721518, -1.424368793103448 ], [ 29.780242911392406, -1.424368793103448 ], [ 29.780242911392406, -1.424099310344828 ], [ 29.779973417721518, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7888, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.424099310344828 ], [ 29.780242911392406, -1.424368793103448 ], [ 29.780512405063291, -1.424368793103448 ], [ 29.780512405063291, -1.424099310344828 ], [ 29.780242911392406, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7889, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.424099310344828 ], [ 29.780512405063291, -1.424368793103448 ], [ 29.780781898734176, -1.424368793103448 ], [ 29.780781898734176, -1.424099310344828 ], [ 29.780512405063291, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7890, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.424099310344828 ], [ 29.78132088607595, -1.424368793103448 ], [ 29.781590379746834, -1.424368793103448 ], [ 29.781590379746834, -1.424099310344828 ], [ 29.78132088607595, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7891, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.424099310344828 ], [ 29.781590379746834, -1.424368793103448 ], [ 29.781859873417723, -1.424368793103448 ], [ 29.781859873417723, -1.424099310344828 ], [ 29.781590379746834, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7892, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.424099310344828 ], [ 29.782129367088608, -1.424368793103448 ], [ 29.782398860759493, -1.424368793103448 ], [ 29.782398860759493, -1.424099310344828 ], [ 29.782129367088608, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7893, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.424099310344828 ], [ 29.782398860759493, -1.424368793103448 ], [ 29.782668354430381, -1.424368793103448 ], [ 29.782668354430381, -1.424099310344828 ], [ 29.782398860759493, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7894, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.424099310344828 ], [ 29.782937848101266, -1.424368793103448 ], [ 29.783207341772151, -1.424368793103448 ], [ 29.783207341772151, -1.424099310344828 ], [ 29.782937848101266, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7895, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.424099310344828 ], [ 29.783207341772151, -1.424368793103448 ], [ 29.783476835443039, -1.424368793103448 ], [ 29.783476835443039, -1.424099310344828 ], [ 29.783207341772151, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7896, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.424099310344828 ], [ 29.783476835443039, -1.424368793103448 ], [ 29.784015822784809, -1.424368793103448 ], [ 29.784015822784809, -1.424099310344828 ], [ 29.783476835443039, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7897, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.424099310344828 ], [ 29.784015822784809, -1.424368793103448 ], [ 29.784285316455698, -1.424368793103448 ], [ 29.784285316455698, -1.424099310344828 ], [ 29.784015822784809, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7898, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.424099310344828 ], [ 29.788866708860759, -1.424368793103448 ], [ 29.789136202531644, -1.424368793103448 ], [ 29.789136202531644, -1.424099310344828 ], [ 29.788866708860759, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.424099310344828 ], [ 29.789136202531644, -1.424368793103448 ], [ 29.789405696202532, -1.424368793103448 ], [ 29.789405696202532, -1.424099310344828 ], [ 29.789136202531644, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7900, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.424099310344828 ], [ 29.789405696202532, -1.424368793103448 ], [ 29.789675189873417, -1.424368793103448 ], [ 29.789675189873417, -1.424099310344828 ], [ 29.789405696202532, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7901, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.424099310344828 ], [ 29.789675189873417, -1.424368793103448 ], [ 29.789944683544302, -1.424368793103448 ], [ 29.789944683544302, -1.424099310344828 ], [ 29.789675189873417, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7902, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.424099310344828 ], [ 29.789944683544302, -1.424368793103448 ], [ 29.790214177215191, -1.424368793103448 ], [ 29.790214177215191, -1.424099310344828 ], [ 29.789944683544302, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7903, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.424099310344828 ], [ 29.790483670886076, -1.424368793103448 ], [ 29.790753164556961, -1.424368793103448 ], [ 29.790753164556961, -1.424099310344828 ], [ 29.790483670886076, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7904, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.424099310344828 ], [ 29.790753164556961, -1.424368793103448 ], [ 29.791022658227849, -1.424368793103448 ], [ 29.791022658227849, -1.424099310344828 ], [ 29.790753164556961, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7905, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.424099310344828 ], [ 29.791022658227849, -1.424368793103448 ], [ 29.791292151898734, -1.424368793103448 ], [ 29.791292151898734, -1.424099310344828 ], [ 29.791022658227849, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7906, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.424099310344828 ], [ 29.791292151898734, -1.424368793103448 ], [ 29.791561645569619, -1.424368793103448 ], [ 29.791561645569619, -1.424099310344828 ], [ 29.791292151898734, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7907, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.424099310344828 ], [ 29.791561645569619, -1.424368793103448 ], [ 29.791831139240507, -1.424368793103448 ], [ 29.791831139240507, -1.424099310344828 ], [ 29.791561645569619, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7908, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.424099310344828 ], [ 29.791831139240507, -1.424368793103448 ], [ 29.792100632911392, -1.424368793103448 ], [ 29.792100632911392, -1.424099310344828 ], [ 29.791831139240507, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7909, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.424099310344828 ], [ 29.792100632911392, -1.424368793103448 ], [ 29.792370126582277, -1.424368793103448 ], [ 29.792370126582277, -1.424099310344828 ], [ 29.792100632911392, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7910, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.424099310344828 ], [ 29.792370126582277, -1.424368793103448 ], [ 29.792909113924051, -1.424368793103448 ], [ 29.792909113924051, -1.424099310344828 ], [ 29.792370126582277, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7911, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.424099310344828 ], [ 29.792909113924051, -1.424368793103448 ], [ 29.793178607594935, -1.424368793103448 ], [ 29.793178607594935, -1.424099310344828 ], [ 29.792909113924051, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7912, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.424099310344828 ], [ 29.801263417721518, -1.424368793103448 ], [ 29.801532911392407, -1.424368793103448 ], [ 29.801532911392407, -1.424099310344828 ], [ 29.801263417721518, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7913, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.424099310344828 ], [ 29.801532911392407, -1.424368793103448 ], [ 29.802071898734177, -1.424368793103448 ], [ 29.802071898734177, -1.424099310344828 ], [ 29.801532911392407, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7914, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.424099310344828 ], [ 29.802071898734177, -1.424368793103448 ], [ 29.802341392405065, -1.424368793103448 ], [ 29.802341392405065, -1.424099310344828 ], [ 29.802071898734177, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7915, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.424099310344828 ], [ 29.802341392405065, -1.424368793103448 ], [ 29.80261088607595, -1.424368793103448 ], [ 29.80261088607595, -1.424099310344828 ], [ 29.802341392405065, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7916, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.424099310344828 ], [ 29.80261088607595, -1.424368793103448 ], [ 29.802880379746835, -1.424368793103448 ], [ 29.802880379746835, -1.424099310344828 ], [ 29.80261088607595, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7917, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.424099310344828 ], [ 29.802880379746835, -1.424368793103448 ], [ 29.803149873417723, -1.424368793103448 ], [ 29.803149873417723, -1.424099310344828 ], [ 29.802880379746835, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7918, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.424099310344828 ], [ 29.803149873417723, -1.424368793103448 ], [ 29.803419367088608, -1.424368793103448 ], [ 29.803419367088608, -1.424099310344828 ], [ 29.803149873417723, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7919, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.424099310344828 ], [ 29.803419367088608, -1.424368793103448 ], [ 29.803688860759493, -1.424368793103448 ], [ 29.803688860759493, -1.424099310344828 ], [ 29.803419367088608, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7920, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.424099310344828 ], [ 29.803688860759493, -1.424368793103448 ], [ 29.803958354430382, -1.424368793103448 ], [ 29.803958354430382, -1.424099310344828 ], [ 29.803688860759493, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7921, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.424099310344828 ], [ 29.803958354430382, -1.424368793103448 ], [ 29.804227848101267, -1.424368793103448 ], [ 29.804227848101267, -1.424099310344828 ], [ 29.803958354430382, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7922, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.424099310344828 ], [ 29.804227848101267, -1.424368793103448 ], [ 29.804497341772151, -1.424368793103448 ], [ 29.804497341772151, -1.424099310344828 ], [ 29.804227848101267, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7923, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.424099310344828 ], [ 29.804497341772151, -1.424368793103448 ], [ 29.80476683544304, -1.424368793103448 ], [ 29.80476683544304, -1.424099310344828 ], [ 29.804497341772151, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7924, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.424099310344828 ], [ 29.80476683544304, -1.424368793103448 ], [ 29.805036329113925, -1.424368793103448 ], [ 29.805036329113925, -1.424099310344828 ], [ 29.80476683544304, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7925, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.424099310344828 ], [ 29.805036329113925, -1.424368793103448 ], [ 29.80530582278481, -1.424368793103448 ], [ 29.80530582278481, -1.424099310344828 ], [ 29.805036329113925, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7926, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.424099310344828 ], [ 29.80530582278481, -1.424368793103448 ], [ 29.805575316455698, -1.424368793103448 ], [ 29.805575316455698, -1.424099310344828 ], [ 29.80530582278481, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7927, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.424099310344828 ], [ 29.805575316455698, -1.424368793103448 ], [ 29.805844810126583, -1.424368793103448 ], [ 29.805844810126583, -1.424099310344828 ], [ 29.805575316455698, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7928, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.424099310344828 ], [ 29.805844810126583, -1.424368793103448 ], [ 29.806114303797468, -1.424368793103448 ], [ 29.806114303797468, -1.424099310344828 ], [ 29.805844810126583, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7929, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.424099310344828 ], [ 29.806114303797468, -1.424368793103448 ], [ 29.806383797468357, -1.424368793103448 ], [ 29.806383797468357, -1.424099310344828 ], [ 29.806114303797468, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7930, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.424099310344828 ], [ 29.806383797468357, -1.424368793103448 ], [ 29.806653291139241, -1.424368793103448 ], [ 29.806653291139241, -1.424099310344828 ], [ 29.806383797468357, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7931, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.424099310344828 ], [ 29.806653291139241, -1.424368793103448 ], [ 29.806922784810126, -1.424368793103448 ], [ 29.806922784810126, -1.424099310344828 ], [ 29.806653291139241, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7932, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.424099310344828 ], [ 29.806922784810126, -1.424368793103448 ], [ 29.807192278481015, -1.424368793103448 ], [ 29.807192278481015, -1.424099310344828 ], [ 29.806922784810126, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7933, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.424099310344828 ], [ 29.807192278481015, -1.424368793103448 ], [ 29.8074617721519, -1.424368793103448 ], [ 29.8074617721519, -1.424099310344828 ], [ 29.807192278481015, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7934, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.424099310344828 ], [ 29.8074617721519, -1.424368793103448 ], [ 29.808000759493673, -1.424368793103448 ], [ 29.808000759493673, -1.424099310344828 ], [ 29.8074617721519, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7935, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.424099310344828 ], [ 29.808000759493673, -1.424368793103448 ], [ 29.808270253164558, -1.424368793103448 ], [ 29.808270253164558, -1.424099310344828 ], [ 29.808000759493673, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7936, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.424099310344828 ], [ 29.808270253164558, -1.424368793103448 ], [ 29.808539746835443, -1.424368793103448 ], [ 29.808539746835443, -1.424099310344828 ], [ 29.808270253164558, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7937, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.424099310344828 ], [ 29.808539746835443, -1.424368793103448 ], [ 29.809078734177216, -1.424368793103448 ], [ 29.809078734177216, -1.424099310344828 ], [ 29.808539746835443, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7938, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.424099310344828 ], [ 29.809078734177216, -1.424368793103448 ], [ 29.809348227848101, -1.424368793103448 ], [ 29.809348227848101, -1.424099310344828 ], [ 29.809078734177216, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7939, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.424099310344828 ], [ 29.809348227848101, -1.424368793103448 ], [ 29.809617721518986, -1.424368793103448 ], [ 29.809617721518986, -1.424099310344828 ], [ 29.809348227848101, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7940, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.424099310344828 ], [ 29.809617721518986, -1.424368793103448 ], [ 29.81015670886076, -1.424368793103448 ], [ 29.81015670886076, -1.424099310344828 ], [ 29.809617721518986, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7941, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.423560344827586 ], [ 29.785632784810126, -1.425716206896552 ], [ 29.785902278481014, -1.425716206896552 ], [ 29.785902278481014, -1.423560344827586 ], [ 29.785632784810126, -1.423560344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7942, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.423829827586207 ], [ 29.756257974683542, -1.425446724137931 ], [ 29.756796962025316, -1.425446724137931 ], [ 29.756796962025316, -1.423829827586207 ], [ 29.756257974683542, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7943, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.423829827586207 ], [ 29.785093797468356, -1.425446724137931 ], [ 29.785363291139241, -1.425446724137931 ], [ 29.785363291139241, -1.423829827586207 ], [ 29.785093797468356, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7944, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.423829827586207 ], [ 29.785363291139241, -1.425446724137931 ], [ 29.785632784810126, -1.425446724137931 ], [ 29.785632784810126, -1.423829827586207 ], [ 29.785363291139241, -1.423829827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7945, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.424099310344828 ], [ 29.756796962025316, -1.425446724137931 ], [ 29.757066455696201, -1.425446724137931 ], [ 29.757066455696201, -1.424099310344828 ], [ 29.756796962025316, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.424099310344828 ], [ 29.757066455696201, -1.425446724137931 ], [ 29.757335949367089, -1.425446724137931 ], [ 29.757335949367089, -1.424099310344828 ], [ 29.757066455696201, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7947, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.424099310344828 ], [ 29.767037721518985, -1.424638275862069 ], [ 29.767307215189874, -1.424638275862069 ], [ 29.767307215189874, -1.424099310344828 ], [ 29.767037721518985, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7948, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.424099310344828 ], [ 29.76892417721519, -1.424638275862069 ], [ 29.769193670886075, -1.424638275862069 ], [ 29.769193670886075, -1.424099310344828 ], [ 29.76892417721519, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7949, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.424099310344828 ], [ 29.769732658227849, -1.424638275862069 ], [ 29.770002151898733, -1.424638275862069 ], [ 29.770002151898733, -1.424099310344828 ], [ 29.769732658227849, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7950, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.424099310344828 ], [ 29.770541139240507, -1.424638275862069 ], [ 29.770810632911392, -1.424638275862069 ], [ 29.770810632911392, -1.424099310344828 ], [ 29.770541139240507, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7951, "El": 169 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.424099310344828 ], [ 29.771080126582277, -1.42490775862069 ], [ 29.771349620253165, -1.42490775862069 ], [ 29.771349620253165, -1.424099310344828 ], [ 29.771080126582277, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7952, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.424099310344828 ], [ 29.77161911392405, -1.424638275862069 ], [ 29.771888607594935, -1.424638275862069 ], [ 29.771888607594935, -1.424099310344828 ], [ 29.77161911392405, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7953, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.424099310344828 ], [ 29.772427594936708, -1.424638275862069 ], [ 29.772697088607593, -1.424638275862069 ], [ 29.772697088607593, -1.424099310344828 ], [ 29.772427594936708, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7954, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.424099310344828 ], [ 29.773236075949367, -1.424638275862069 ], [ 29.773505569620252, -1.424638275862069 ], [ 29.773505569620252, -1.424099310344828 ], [ 29.773236075949367, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7955, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.424099310344828 ], [ 29.775392025316457, -1.424638275862069 ], [ 29.775661518987341, -1.424638275862069 ], [ 29.775661518987341, -1.424099310344828 ], [ 29.775392025316457, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7956, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.424099310344828 ], [ 29.784285316455698, -1.42517724137931 ], [ 29.784554810126583, -1.42517724137931 ], [ 29.784554810126583, -1.424099310344828 ], [ 29.784285316455698, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7957, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.424099310344828 ], [ 29.784554810126583, -1.42517724137931 ], [ 29.784824303797468, -1.42517724137931 ], [ 29.784824303797468, -1.424099310344828 ], [ 29.784554810126583, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7958, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.424099310344828 ], [ 29.784824303797468, -1.42517724137931 ], [ 29.785093797468356, -1.42517724137931 ], [ 29.785093797468356, -1.424099310344828 ], [ 29.784824303797468, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7959, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.424099310344828 ], [ 29.800724430379748, -1.42490775862069 ], [ 29.801263417721518, -1.42490775862069 ], [ 29.801263417721518, -1.424638275862069 ], [ 29.800993924050633, -1.424638275862069 ], [ 29.800993924050633, -1.424099310344828 ], [ 29.800724430379748, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7960, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.424099310344828 ], [ 29.800993924050633, -1.424638275862069 ], [ 29.801263417721518, -1.424638275862069 ], [ 29.801263417721518, -1.424099310344828 ], [ 29.800993924050633, -1.424099310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7961, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.424368793103448 ], [ 29.757335949367089, -1.425716206896552 ], [ 29.757605443037974, -1.425716206896552 ], [ 29.757605443037974, -1.424368793103448 ], [ 29.757335949367089, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7962, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.424368793103448 ], [ 29.757605443037974, -1.425716206896552 ], [ 29.757874936708859, -1.425716206896552 ], [ 29.757874936708859, -1.424368793103448 ], [ 29.757605443037974, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7963, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.424368793103448 ], [ 29.757874936708859, -1.425446724137931 ], [ 29.758144430379748, -1.425446724137931 ], [ 29.758144430379748, -1.424368793103448 ], [ 29.757874936708859, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7964, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.424368793103448 ], [ 29.758144430379748, -1.424638275862069 ], [ 29.758413924050632, -1.424638275862069 ], [ 29.758413924050632, -1.424368793103448 ], [ 29.758144430379748, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7965, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.424368793103448 ], [ 29.758413924050632, -1.424638275862069 ], [ 29.758683417721517, -1.424638275862069 ], [ 29.758683417721517, -1.424368793103448 ], [ 29.758413924050632, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7966, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.424368793103448 ], [ 29.758683417721517, -1.424638275862069 ], [ 29.758952911392406, -1.424638275862069 ], [ 29.758952911392406, -1.424368793103448 ], [ 29.758683417721517, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7967, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.424368793103448 ], [ 29.758952911392406, -1.424638275862069 ], [ 29.759222405063291, -1.424638275862069 ], [ 29.759222405063291, -1.424368793103448 ], [ 29.758952911392406, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7968, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.424368793103448 ], [ 29.759222405063291, -1.424638275862069 ], [ 29.759491898734176, -1.424638275862069 ], [ 29.759491898734176, -1.424368793103448 ], [ 29.759222405063291, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7969, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.424368793103448 ], [ 29.759491898734176, -1.424638275862069 ], [ 29.759761392405064, -1.424638275862069 ], [ 29.759761392405064, -1.424368793103448 ], [ 29.759491898734176, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7970, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.424368793103448 ], [ 29.759761392405064, -1.424638275862069 ], [ 29.760030886075949, -1.424638275862069 ], [ 29.760030886075949, -1.424368793103448 ], [ 29.759761392405064, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7971, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.424368793103448 ], [ 29.760030886075949, -1.424638275862069 ], [ 29.760300379746834, -1.424638275862069 ], [ 29.760300379746834, -1.424368793103448 ], [ 29.760030886075949, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7972, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.424368793103448 ], [ 29.760300379746834, -1.424638275862069 ], [ 29.760569873417722, -1.424638275862069 ], [ 29.760569873417722, -1.424368793103448 ], [ 29.760300379746834, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7973, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.424368793103448 ], [ 29.760569873417722, -1.424638275862069 ], [ 29.760839367088607, -1.424638275862069 ], [ 29.760839367088607, -1.424368793103448 ], [ 29.760569873417722, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7974, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.424368793103448 ], [ 29.760839367088607, -1.424638275862069 ], [ 29.761108860759492, -1.424638275862069 ], [ 29.761108860759492, -1.424368793103448 ], [ 29.760839367088607, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7975, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.424368793103448 ], [ 29.761108860759492, -1.424638275862069 ], [ 29.761378354430381, -1.424638275862069 ], [ 29.761378354430381, -1.424368793103448 ], [ 29.761108860759492, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7976, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.424368793103448 ], [ 29.761378354430381, -1.424638275862069 ], [ 29.761647848101266, -1.424638275862069 ], [ 29.761647848101266, -1.424368793103448 ], [ 29.761378354430381, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7977, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.424368793103448 ], [ 29.761647848101266, -1.424638275862069 ], [ 29.761917341772151, -1.424638275862069 ], [ 29.761917341772151, -1.424368793103448 ], [ 29.761647848101266, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7978, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.424368793103448 ], [ 29.761917341772151, -1.424638275862069 ], [ 29.762186835443039, -1.424638275862069 ], [ 29.762186835443039, -1.424368793103448 ], [ 29.761917341772151, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7979, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.424368793103448 ], [ 29.762186835443039, -1.424638275862069 ], [ 29.762456329113924, -1.424638275862069 ], [ 29.762456329113924, -1.424368793103448 ], [ 29.762186835443039, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7980, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.424368793103448 ], [ 29.762456329113924, -1.424638275862069 ], [ 29.762725822784809, -1.424638275862069 ], [ 29.762725822784809, -1.424368793103448 ], [ 29.762456329113924, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7981, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.424368793103448 ], [ 29.762725822784809, -1.424638275862069 ], [ 29.762995316455697, -1.424638275862069 ], [ 29.762995316455697, -1.424368793103448 ], [ 29.762725822784809, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7982, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.424368793103448 ], [ 29.762995316455697, -1.424638275862069 ], [ 29.763264810126582, -1.424638275862069 ], [ 29.763264810126582, -1.424368793103448 ], [ 29.762995316455697, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7983, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.424368793103448 ], [ 29.763264810126582, -1.424638275862069 ], [ 29.763534303797467, -1.424638275862069 ], [ 29.763534303797467, -1.424368793103448 ], [ 29.763264810126582, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7984, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.424368793103448 ], [ 29.763534303797467, -1.424638275862069 ], [ 29.763803797468356, -1.424638275862069 ], [ 29.763803797468356, -1.424368793103448 ], [ 29.763534303797467, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7985, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.424368793103448 ], [ 29.763803797468356, -1.424638275862069 ], [ 29.76407329113924, -1.424638275862069 ], [ 29.76407329113924, -1.424368793103448 ], [ 29.763803797468356, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7986, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.424368793103448 ], [ 29.76407329113924, -1.424638275862069 ], [ 29.764342784810125, -1.424638275862069 ], [ 29.764342784810125, -1.424368793103448 ], [ 29.76407329113924, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7987, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.424368793103448 ], [ 29.764342784810125, -1.424638275862069 ], [ 29.764612278481014, -1.424638275862069 ], [ 29.764612278481014, -1.424368793103448 ], [ 29.764342784810125, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7988, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.424368793103448 ], [ 29.764612278481014, -1.424638275862069 ], [ 29.764881772151899, -1.424638275862069 ], [ 29.764881772151899, -1.424368793103448 ], [ 29.764612278481014, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7989, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.424368793103448 ], [ 29.764881772151899, -1.424638275862069 ], [ 29.765151265822784, -1.424638275862069 ], [ 29.765151265822784, -1.424368793103448 ], [ 29.764881772151899, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7990, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.424368793103448 ], [ 29.765151265822784, -1.424638275862069 ], [ 29.765420759493672, -1.424638275862069 ], [ 29.765420759493672, -1.424368793103448 ], [ 29.765151265822784, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7991, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.424368793103448 ], [ 29.765420759493672, -1.424638275862069 ], [ 29.765959746835442, -1.424638275862069 ], [ 29.765959746835442, -1.424368793103448 ], [ 29.765420759493672, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7992, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.424368793103448 ], [ 29.765959746835442, -1.424638275862069 ], [ 29.766229240506327, -1.424638275862069 ], [ 29.766229240506327, -1.424368793103448 ], [ 29.765959746835442, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7993, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.424368793103448 ], [ 29.766229240506327, -1.424638275862069 ], [ 29.766498734177215, -1.424638275862069 ], [ 29.766498734177215, -1.424368793103448 ], [ 29.766229240506327, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7994, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.424368793103448 ], [ 29.766498734177215, -1.424638275862069 ], [ 29.7667682278481, -1.424638275862069 ], [ 29.7667682278481, -1.424368793103448 ], [ 29.766498734177215, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7995, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.424368793103448 ], [ 29.7667682278481, -1.424638275862069 ], [ 29.767037721518985, -1.424638275862069 ], [ 29.767037721518985, -1.424368793103448 ], [ 29.7667682278481, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7996, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.424368793103448 ], [ 29.767307215189874, -1.424638275862069 ], [ 29.767576708860759, -1.424638275862069 ], [ 29.767576708860759, -1.424368793103448 ], [ 29.767307215189874, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7997, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.424368793103448 ], [ 29.767576708860759, -1.424638275862069 ], [ 29.767846202531643, -1.424638275862069 ], [ 29.767846202531643, -1.424368793103448 ], [ 29.767576708860759, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7998, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.424368793103448 ], [ 29.767846202531643, -1.424638275862069 ], [ 29.768115696202532, -1.424638275862069 ], [ 29.768115696202532, -1.424368793103448 ], [ 29.767846202531643, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 7999, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.424368793103448 ], [ 29.768115696202532, -1.42490775862069 ], [ 29.768385189873417, -1.42490775862069 ], [ 29.768385189873417, -1.424368793103448 ], [ 29.768115696202532, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8000, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.424368793103448 ], [ 29.768385189873417, -1.424638275862069 ], [ 29.768654683544302, -1.424638275862069 ], [ 29.768654683544302, -1.424368793103448 ], [ 29.768385189873417, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8001, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.424368793103448 ], [ 29.768654683544302, -1.424638275862069 ], [ 29.76892417721519, -1.424638275862069 ], [ 29.76892417721519, -1.424368793103448 ], [ 29.768654683544302, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8002, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.424368793103448 ], [ 29.769193670886075, -1.424638275862069 ], [ 29.76946316455696, -1.424638275862069 ], [ 29.76946316455696, -1.424368793103448 ], [ 29.769193670886075, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8003, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.424368793103448 ], [ 29.76946316455696, -1.424638275862069 ], [ 29.769732658227849, -1.424638275862069 ], [ 29.769732658227849, -1.424368793103448 ], [ 29.76946316455696, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8004, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.424368793103448 ], [ 29.770002151898733, -1.42490775862069 ], [ 29.770271645569618, -1.42490775862069 ], [ 29.770271645569618, -1.424368793103448 ], [ 29.770002151898733, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8005, "El": 160 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.424368793103448 ], [ 29.770271645569618, -1.424638275862069 ], [ 29.770541139240507, -1.424638275862069 ], [ 29.770541139240507, -1.424368793103448 ], [ 29.770271645569618, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8006, "El": 167 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.424368793103448 ], [ 29.770810632911392, -1.42490775862069 ], [ 29.771080126582277, -1.42490775862069 ], [ 29.771080126582277, -1.424368793103448 ], [ 29.770810632911392, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8007, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.424368793103448 ], [ 29.771349620253165, -1.424638275862069 ], [ 29.77161911392405, -1.424638275862069 ], [ 29.77161911392405, -1.424368793103448 ], [ 29.771349620253165, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8008, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.424368793103448 ], [ 29.771888607594935, -1.42490775862069 ], [ 29.772158101265823, -1.42490775862069 ], [ 29.772158101265823, -1.424368793103448 ], [ 29.771888607594935, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8009, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.424368793103448 ], [ 29.772158101265823, -1.424638275862069 ], [ 29.772427594936708, -1.424638275862069 ], [ 29.772427594936708, -1.424368793103448 ], [ 29.772158101265823, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8010, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.424368793103448 ], [ 29.772697088607593, -1.42490775862069 ], [ 29.772966582278482, -1.42490775862069 ], [ 29.772966582278482, -1.424368793103448 ], [ 29.772697088607593, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8011, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.424368793103448 ], [ 29.772966582278482, -1.424638275862069 ], [ 29.773236075949367, -1.424638275862069 ], [ 29.773236075949367, -1.424368793103448 ], [ 29.772966582278482, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8012, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.424368793103448 ], [ 29.773505569620252, -1.42490775862069 ], [ 29.77377506329114, -1.42490775862069 ], [ 29.77377506329114, -1.424368793103448 ], [ 29.773505569620252, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8013, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.424368793103448 ], [ 29.77377506329114, -1.424638275862069 ], [ 29.774044556962025, -1.424638275862069 ], [ 29.774044556962025, -1.424368793103448 ], [ 29.77377506329114, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8014, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.424368793103448 ], [ 29.774044556962025, -1.424638275862069 ], [ 29.77431405063291, -1.424638275862069 ], [ 29.77431405063291, -1.424368793103448 ], [ 29.774044556962025, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8015, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.424368793103448 ], [ 29.77431405063291, -1.42490775862069 ], [ 29.774853037974683, -1.42490775862069 ], [ 29.774853037974683, -1.424368793103448 ], [ 29.77431405063291, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8016, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.424368793103448 ], [ 29.774853037974683, -1.424638275862069 ], [ 29.775122531645568, -1.424638275862069 ], [ 29.775122531645568, -1.424368793103448 ], [ 29.774853037974683, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8017, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.424368793103448 ], [ 29.775122531645568, -1.424638275862069 ], [ 29.775392025316457, -1.424638275862069 ], [ 29.775392025316457, -1.424368793103448 ], [ 29.775122531645568, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8018, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.424368793103448 ], [ 29.775661518987341, -1.424638275862069 ], [ 29.775931012658226, -1.424638275862069 ], [ 29.775931012658226, -1.424368793103448 ], [ 29.775661518987341, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8019, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.424368793103448 ], [ 29.775931012658226, -1.424638275862069 ], [ 29.776200506329115, -1.424638275862069 ], [ 29.776200506329115, -1.424368793103448 ], [ 29.775931012658226, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8020, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.424368793103448 ], [ 29.776200506329115, -1.424638275862069 ], [ 29.77647, -1.424638275862069 ], [ 29.77647, -1.424368793103448 ], [ 29.776200506329115, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8021, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.424368793103448 ], [ 29.77647, -1.42490775862069 ], [ 29.776739493670885, -1.42490775862069 ], [ 29.776739493670885, -1.424368793103448 ], [ 29.77647, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8022, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.424368793103448 ], [ 29.776739493670885, -1.424638275862069 ], [ 29.777008987341773, -1.424638275862069 ], [ 29.777008987341773, -1.424368793103448 ], [ 29.776739493670885, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8023, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.424368793103448 ], [ 29.777008987341773, -1.424638275862069 ], [ 29.777278481012658, -1.424638275862069 ], [ 29.777278481012658, -1.424368793103448 ], [ 29.777008987341773, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8024, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.424368793103448 ], [ 29.777278481012658, -1.424638275862069 ], [ 29.777547974683543, -1.424638275862069 ], [ 29.777547974683543, -1.424368793103448 ], [ 29.777278481012658, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8025, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.424368793103448 ], [ 29.777547974683543, -1.424638275862069 ], [ 29.777817468354431, -1.424638275862069 ], [ 29.777817468354431, -1.424368793103448 ], [ 29.777547974683543, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8026, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.424368793103448 ], [ 29.777817468354431, -1.424638275862069 ], [ 29.778086962025316, -1.424638275862069 ], [ 29.778086962025316, -1.424368793103448 ], [ 29.777817468354431, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8027, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.424368793103448 ], [ 29.778086962025316, -1.424638275862069 ], [ 29.778356455696201, -1.424638275862069 ], [ 29.778356455696201, -1.424368793103448 ], [ 29.778086962025316, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8028, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.424368793103448 ], [ 29.778356455696201, -1.424638275862069 ], [ 29.77862594936709, -1.424638275862069 ], [ 29.77862594936709, -1.424368793103448 ], [ 29.778356455696201, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8029, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.424368793103448 ], [ 29.77862594936709, -1.424638275862069 ], [ 29.778895443037975, -1.424638275862069 ], [ 29.778895443037975, -1.424368793103448 ], [ 29.77862594936709, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8030, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.424368793103448 ], [ 29.778895443037975, -1.424638275862069 ], [ 29.77916493670886, -1.424638275862069 ], [ 29.77916493670886, -1.424368793103448 ], [ 29.778895443037975, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8031, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.424368793103448 ], [ 29.77916493670886, -1.424638275862069 ], [ 29.779434430379748, -1.424638275862069 ], [ 29.779434430379748, -1.424368793103448 ], [ 29.77916493670886, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8032, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.424368793103448 ], [ 29.779434430379748, -1.424638275862069 ], [ 29.779703924050633, -1.424638275862069 ], [ 29.779703924050633, -1.424368793103448 ], [ 29.779434430379748, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8033, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.424368793103448 ], [ 29.779703924050633, -1.424638275862069 ], [ 29.779973417721518, -1.424638275862069 ], [ 29.779973417721518, -1.424368793103448 ], [ 29.779703924050633, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8034, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.424368793103448 ], [ 29.779973417721518, -1.424638275862069 ], [ 29.780242911392406, -1.424638275862069 ], [ 29.780242911392406, -1.424368793103448 ], [ 29.779973417721518, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8035, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.424368793103448 ], [ 29.780242911392406, -1.424638275862069 ], [ 29.780512405063291, -1.424638275862069 ], [ 29.780512405063291, -1.424368793103448 ], [ 29.780242911392406, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8036, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.424368793103448 ], [ 29.780512405063291, -1.424638275862069 ], [ 29.780781898734176, -1.424638275862069 ], [ 29.780781898734176, -1.424368793103448 ], [ 29.780512405063291, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8037, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.424368793103448 ], [ 29.780781898734176, -1.424638275862069 ], [ 29.781051392405065, -1.424638275862069 ], [ 29.781051392405065, -1.424368793103448 ], [ 29.780781898734176, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8038, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.424368793103448 ], [ 29.781051392405065, -1.424638275862069 ], [ 29.78132088607595, -1.424638275862069 ], [ 29.78132088607595, -1.424368793103448 ], [ 29.781051392405065, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8039, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.424368793103448 ], [ 29.78132088607595, -1.424638275862069 ], [ 29.781590379746834, -1.424638275862069 ], [ 29.781590379746834, -1.424368793103448 ], [ 29.78132088607595, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8040, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.424368793103448 ], [ 29.781590379746834, -1.424638275862069 ], [ 29.781859873417723, -1.424638275862069 ], [ 29.781859873417723, -1.424368793103448 ], [ 29.781590379746834, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8041, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.424368793103448 ], [ 29.781859873417723, -1.424638275862069 ], [ 29.782129367088608, -1.424638275862069 ], [ 29.782129367088608, -1.424368793103448 ], [ 29.781859873417723, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8042, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.424368793103448 ], [ 29.782129367088608, -1.424638275862069 ], [ 29.782398860759493, -1.424638275862069 ], [ 29.782398860759493, -1.424368793103448 ], [ 29.782129367088608, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8043, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.424368793103448 ], [ 29.782398860759493, -1.424638275862069 ], [ 29.782668354430381, -1.424638275862069 ], [ 29.782668354430381, -1.424368793103448 ], [ 29.782398860759493, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8044, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.424368793103448 ], [ 29.782668354430381, -1.424638275862069 ], [ 29.782937848101266, -1.424638275862069 ], [ 29.782937848101266, -1.424368793103448 ], [ 29.782668354430381, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8045, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.424368793103448 ], [ 29.782937848101266, -1.42490775862069 ], [ 29.783207341772151, -1.42490775862069 ], [ 29.783207341772151, -1.424368793103448 ], [ 29.782937848101266, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8046, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.424368793103448 ], [ 29.783207341772151, -1.42490775862069 ], [ 29.783476835443039, -1.42490775862069 ], [ 29.783476835443039, -1.424368793103448 ], [ 29.783207341772151, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8047, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.424368793103448 ], [ 29.783476835443039, -1.42490775862069 ], [ 29.784015822784809, -1.42490775862069 ], [ 29.784015822784809, -1.424368793103448 ], [ 29.783476835443039, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8048, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.424368793103448 ], [ 29.784015822784809, -1.42490775862069 ], [ 29.784285316455698, -1.42490775862069 ], [ 29.784285316455698, -1.424368793103448 ], [ 29.784015822784809, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8049, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.424368793103448 ], [ 29.788327721518986, -1.42517724137931 ], [ 29.788597215189874, -1.42517724137931 ], [ 29.788597215189874, -1.424368793103448 ], [ 29.788327721518986, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8050, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.424368793103448 ], [ 29.788597215189874, -1.424638275862069 ], [ 29.788866708860759, -1.424638275862069 ], [ 29.788866708860759, -1.424368793103448 ], [ 29.788597215189874, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8051, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.424368793103448 ], [ 29.788866708860759, -1.424638275862069 ], [ 29.789136202531644, -1.424638275862069 ], [ 29.789136202531644, -1.424368793103448 ], [ 29.788866708860759, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8052, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.424368793103448 ], [ 29.789136202531644, -1.424638275862069 ], [ 29.789405696202532, -1.424638275862069 ], [ 29.789405696202532, -1.424368793103448 ], [ 29.789136202531644, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8053, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.424368793103448 ], [ 29.789405696202532, -1.424638275862069 ], [ 29.789675189873417, -1.424638275862069 ], [ 29.789675189873417, -1.424368793103448 ], [ 29.789405696202532, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8054, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.424368793103448 ], [ 29.789675189873417, -1.424638275862069 ], [ 29.789944683544302, -1.424638275862069 ], [ 29.789944683544302, -1.424368793103448 ], [ 29.789675189873417, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8055, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.424368793103448 ], [ 29.789944683544302, -1.424638275862069 ], [ 29.790214177215191, -1.424638275862069 ], [ 29.790214177215191, -1.424368793103448 ], [ 29.789944683544302, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8056, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.424368793103448 ], [ 29.790214177215191, -1.424638275862069 ], [ 29.790483670886076, -1.424638275862069 ], [ 29.790483670886076, -1.424368793103448 ], [ 29.790214177215191, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8057, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.424368793103448 ], [ 29.790483670886076, -1.424638275862069 ], [ 29.790753164556961, -1.424638275862069 ], [ 29.790753164556961, -1.424368793103448 ], [ 29.790483670886076, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8058, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.424368793103448 ], [ 29.790753164556961, -1.424638275862069 ], [ 29.791022658227849, -1.424638275862069 ], [ 29.791022658227849, -1.424368793103448 ], [ 29.790753164556961, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8059, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.424368793103448 ], [ 29.791022658227849, -1.424638275862069 ], [ 29.791292151898734, -1.424638275862069 ], [ 29.791292151898734, -1.424368793103448 ], [ 29.791022658227849, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8060, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.424368793103448 ], [ 29.791292151898734, -1.424638275862069 ], [ 29.791561645569619, -1.424638275862069 ], [ 29.791561645569619, -1.424368793103448 ], [ 29.791292151898734, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8061, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.424368793103448 ], [ 29.791561645569619, -1.424638275862069 ], [ 29.791831139240507, -1.424638275862069 ], [ 29.791831139240507, -1.424368793103448 ], [ 29.791561645569619, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8062, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.424368793103448 ], [ 29.791831139240507, -1.424638275862069 ], [ 29.792100632911392, -1.424638275862069 ], [ 29.792100632911392, -1.424368793103448 ], [ 29.791831139240507, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8063, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.424368793103448 ], [ 29.792100632911392, -1.424638275862069 ], [ 29.792370126582277, -1.424638275862069 ], [ 29.792370126582277, -1.424368793103448 ], [ 29.792100632911392, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8064, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.424368793103448 ], [ 29.792370126582277, -1.424638275862069 ], [ 29.792909113924051, -1.424638275862069 ], [ 29.792909113924051, -1.424368793103448 ], [ 29.792370126582277, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8065, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.424368793103448 ], [ 29.792909113924051, -1.424638275862069 ], [ 29.793178607594935, -1.424638275862069 ], [ 29.793178607594935, -1.424368793103448 ], [ 29.792909113924051, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8066, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.424368793103448 ], [ 29.793178607594935, -1.42490775862069 ], [ 29.793448101265824, -1.42490775862069 ], [ 29.793448101265824, -1.424368793103448 ], [ 29.793178607594935, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8067, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.424368793103448 ], [ 29.801263417721518, -1.424638275862069 ], [ 29.801532911392407, -1.424638275862069 ], [ 29.801532911392407, -1.424368793103448 ], [ 29.801263417721518, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8068, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.424368793103448 ], [ 29.801532911392407, -1.424638275862069 ], [ 29.802071898734177, -1.424638275862069 ], [ 29.802071898734177, -1.424368793103448 ], [ 29.801532911392407, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8069, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.424368793103448 ], [ 29.802071898734177, -1.424638275862069 ], [ 29.802341392405065, -1.424638275862069 ], [ 29.802341392405065, -1.424368793103448 ], [ 29.802071898734177, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8070, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.424368793103448 ], [ 29.802341392405065, -1.424638275862069 ], [ 29.80261088607595, -1.424638275862069 ], [ 29.80261088607595, -1.424368793103448 ], [ 29.802341392405065, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8071, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.424368793103448 ], [ 29.80261088607595, -1.424638275862069 ], [ 29.802880379746835, -1.424638275862069 ], [ 29.802880379746835, -1.424368793103448 ], [ 29.80261088607595, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8072, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.424368793103448 ], [ 29.802880379746835, -1.424638275862069 ], [ 29.803149873417723, -1.424638275862069 ], [ 29.803149873417723, -1.424368793103448 ], [ 29.802880379746835, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8073, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.424368793103448 ], [ 29.803149873417723, -1.424638275862069 ], [ 29.803419367088608, -1.424638275862069 ], [ 29.803419367088608, -1.424368793103448 ], [ 29.803149873417723, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8074, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.424368793103448 ], [ 29.803419367088608, -1.424638275862069 ], [ 29.803688860759493, -1.424638275862069 ], [ 29.803688860759493, -1.424368793103448 ], [ 29.803419367088608, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8075, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.424368793103448 ], [ 29.803688860759493, -1.424638275862069 ], [ 29.803958354430382, -1.424638275862069 ], [ 29.803958354430382, -1.424368793103448 ], [ 29.803688860759493, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8076, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.424368793103448 ], [ 29.803958354430382, -1.424638275862069 ], [ 29.804227848101267, -1.424638275862069 ], [ 29.804227848101267, -1.424368793103448 ], [ 29.803958354430382, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8077, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.424368793103448 ], [ 29.804227848101267, -1.424638275862069 ], [ 29.804497341772151, -1.424638275862069 ], [ 29.804497341772151, -1.424368793103448 ], [ 29.804227848101267, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8078, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.424368793103448 ], [ 29.804497341772151, -1.424638275862069 ], [ 29.80476683544304, -1.424638275862069 ], [ 29.80476683544304, -1.424368793103448 ], [ 29.804497341772151, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8079, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.424368793103448 ], [ 29.80476683544304, -1.424638275862069 ], [ 29.805036329113925, -1.424638275862069 ], [ 29.805036329113925, -1.424368793103448 ], [ 29.80476683544304, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8080, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.424368793103448 ], [ 29.805036329113925, -1.424638275862069 ], [ 29.80530582278481, -1.424638275862069 ], [ 29.80530582278481, -1.424368793103448 ], [ 29.805036329113925, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8081, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.424368793103448 ], [ 29.80530582278481, -1.424638275862069 ], [ 29.805575316455698, -1.424638275862069 ], [ 29.805575316455698, -1.424368793103448 ], [ 29.80530582278481, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8082, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.424368793103448 ], [ 29.805575316455698, -1.424638275862069 ], [ 29.805844810126583, -1.424638275862069 ], [ 29.805844810126583, -1.424368793103448 ], [ 29.805575316455698, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8083, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.424368793103448 ], [ 29.805844810126583, -1.424638275862069 ], [ 29.806114303797468, -1.424638275862069 ], [ 29.806114303797468, -1.424368793103448 ], [ 29.805844810126583, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8084, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.424368793103448 ], [ 29.806114303797468, -1.424638275862069 ], [ 29.806383797468357, -1.424638275862069 ], [ 29.806383797468357, -1.424368793103448 ], [ 29.806114303797468, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8085, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.424368793103448 ], [ 29.806383797468357, -1.424638275862069 ], [ 29.806653291139241, -1.424638275862069 ], [ 29.806653291139241, -1.424368793103448 ], [ 29.806383797468357, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8086, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.424368793103448 ], [ 29.806653291139241, -1.424638275862069 ], [ 29.806922784810126, -1.424638275862069 ], [ 29.806922784810126, -1.424368793103448 ], [ 29.806653291139241, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8087, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.424368793103448 ], [ 29.806922784810126, -1.424638275862069 ], [ 29.807192278481015, -1.424638275862069 ], [ 29.807192278481015, -1.424368793103448 ], [ 29.806922784810126, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8088, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.424368793103448 ], [ 29.807192278481015, -1.424638275862069 ], [ 29.8074617721519, -1.424638275862069 ], [ 29.8074617721519, -1.424368793103448 ], [ 29.807192278481015, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8089, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.424368793103448 ], [ 29.8074617721519, -1.424638275862069 ], [ 29.808000759493673, -1.424638275862069 ], [ 29.808000759493673, -1.424368793103448 ], [ 29.8074617721519, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8090, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.424368793103448 ], [ 29.808000759493673, -1.424638275862069 ], [ 29.808270253164558, -1.424638275862069 ], [ 29.808270253164558, -1.424368793103448 ], [ 29.808000759493673, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8091, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.424368793103448 ], [ 29.808270253164558, -1.424638275862069 ], [ 29.808539746835443, -1.424638275862069 ], [ 29.808539746835443, -1.424368793103448 ], [ 29.808270253164558, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8092, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.424368793103448 ], [ 29.808539746835443, -1.424638275862069 ], [ 29.809078734177216, -1.424638275862069 ], [ 29.809078734177216, -1.424368793103448 ], [ 29.808539746835443, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8093, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.424368793103448 ], [ 29.809078734177216, -1.424638275862069 ], [ 29.809348227848101, -1.424638275862069 ], [ 29.809348227848101, -1.424368793103448 ], [ 29.809078734177216, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8094, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.424368793103448 ], [ 29.809348227848101, -1.424638275862069 ], [ 29.809617721518986, -1.424638275862069 ], [ 29.809617721518986, -1.424368793103448 ], [ 29.809348227848101, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8095, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.424368793103448 ], [ 29.809617721518986, -1.424638275862069 ], [ 29.809887215189875, -1.424638275862069 ], [ 29.809887215189875, -1.424368793103448 ], [ 29.809617721518986, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8096, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.424368793103448 ], [ 29.809887215189875, -1.424638275862069 ], [ 29.81015670886076, -1.424638275862069 ], [ 29.81015670886076, -1.424368793103448 ], [ 29.809887215189875, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8097, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.424368793103448 ], [ 29.81015670886076, -1.424638275862069 ], [ 29.810965189873418, -1.424638275862069 ], [ 29.810965189873418, -1.424368793103448 ], [ 29.81015670886076, -1.424368793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8098, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.424638275862069 ], [ 29.758144430379748, -1.425716206896552 ], [ 29.758413924050632, -1.425716206896552 ], [ 29.758413924050632, -1.424638275862069 ], [ 29.758144430379748, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8099, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.424638275862069 ], [ 29.758413924050632, -1.425716206896552 ], [ 29.758683417721517, -1.425716206896552 ], [ 29.758683417721517, -1.424638275862069 ], [ 29.758413924050632, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8100, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.424638275862069 ], [ 29.758683417721517, -1.425985689655173 ], [ 29.758952911392406, -1.425985689655173 ], [ 29.758952911392406, -1.424638275862069 ], [ 29.758683417721517, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8101, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.424638275862069 ], [ 29.758952911392406, -1.425716206896552 ], [ 29.759222405063291, -1.425716206896552 ], [ 29.759222405063291, -1.424638275862069 ], [ 29.758952911392406, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8102, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.424638275862069 ], [ 29.759222405063291, -1.42490775862069 ], [ 29.759491898734176, -1.42490775862069 ], [ 29.759491898734176, -1.424638275862069 ], [ 29.759222405063291, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8103, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.424638275862069 ], [ 29.759491898734176, -1.42490775862069 ], [ 29.759761392405064, -1.42490775862069 ], [ 29.759761392405064, -1.424638275862069 ], [ 29.759491898734176, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8104, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.424638275862069 ], [ 29.759761392405064, -1.42490775862069 ], [ 29.760030886075949, -1.42490775862069 ], [ 29.760030886075949, -1.424638275862069 ], [ 29.759761392405064, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8105, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.424638275862069 ], [ 29.760030886075949, -1.42490775862069 ], [ 29.760300379746834, -1.42490775862069 ], [ 29.760300379746834, -1.424638275862069 ], [ 29.760030886075949, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8106, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.424638275862069 ], [ 29.760300379746834, -1.42490775862069 ], [ 29.760569873417722, -1.42490775862069 ], [ 29.760569873417722, -1.424638275862069 ], [ 29.760300379746834, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8107, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.424638275862069 ], [ 29.760569873417722, -1.42490775862069 ], [ 29.760839367088607, -1.42490775862069 ], [ 29.760839367088607, -1.424638275862069 ], [ 29.760569873417722, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8108, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.424638275862069 ], [ 29.760839367088607, -1.42490775862069 ], [ 29.761108860759492, -1.42490775862069 ], [ 29.761108860759492, -1.424638275862069 ], [ 29.760839367088607, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8109, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.424638275862069 ], [ 29.761108860759492, -1.42490775862069 ], [ 29.761378354430381, -1.42490775862069 ], [ 29.761378354430381, -1.424638275862069 ], [ 29.761108860759492, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8110, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.424638275862069 ], [ 29.761378354430381, -1.42490775862069 ], [ 29.761647848101266, -1.42490775862069 ], [ 29.761647848101266, -1.424638275862069 ], [ 29.761378354430381, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8111, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.424638275862069 ], [ 29.761647848101266, -1.42490775862069 ], [ 29.761917341772151, -1.42490775862069 ], [ 29.761917341772151, -1.424638275862069 ], [ 29.761647848101266, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8112, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.424638275862069 ], [ 29.761917341772151, -1.42490775862069 ], [ 29.762186835443039, -1.42490775862069 ], [ 29.762186835443039, -1.424638275862069 ], [ 29.761917341772151, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8113, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.424638275862069 ], [ 29.762186835443039, -1.42490775862069 ], [ 29.762456329113924, -1.42490775862069 ], [ 29.762456329113924, -1.424638275862069 ], [ 29.762186835443039, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8114, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.424638275862069 ], [ 29.762456329113924, -1.42490775862069 ], [ 29.762725822784809, -1.42490775862069 ], [ 29.762725822784809, -1.424638275862069 ], [ 29.762456329113924, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8115, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.424638275862069 ], [ 29.762725822784809, -1.42490775862069 ], [ 29.762995316455697, -1.42490775862069 ], [ 29.762995316455697, -1.424638275862069 ], [ 29.762725822784809, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8116, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.424638275862069 ], [ 29.762995316455697, -1.42490775862069 ], [ 29.763264810126582, -1.42490775862069 ], [ 29.763264810126582, -1.424638275862069 ], [ 29.762995316455697, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8117, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.424638275862069 ], [ 29.763264810126582, -1.42490775862069 ], [ 29.763534303797467, -1.42490775862069 ], [ 29.763534303797467, -1.424638275862069 ], [ 29.763264810126582, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8118, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.424638275862069 ], [ 29.763534303797467, -1.42490775862069 ], [ 29.763803797468356, -1.42490775862069 ], [ 29.763803797468356, -1.424638275862069 ], [ 29.763534303797467, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8119, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.424638275862069 ], [ 29.763803797468356, -1.42490775862069 ], [ 29.76407329113924, -1.42490775862069 ], [ 29.76407329113924, -1.424638275862069 ], [ 29.763803797468356, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8120, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.424638275862069 ], [ 29.76407329113924, -1.42490775862069 ], [ 29.764342784810125, -1.42490775862069 ], [ 29.764342784810125, -1.424638275862069 ], [ 29.76407329113924, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8121, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.424638275862069 ], [ 29.764342784810125, -1.42490775862069 ], [ 29.764612278481014, -1.42490775862069 ], [ 29.764612278481014, -1.424638275862069 ], [ 29.764342784810125, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8122, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.424638275862069 ], [ 29.764612278481014, -1.42490775862069 ], [ 29.764881772151899, -1.42490775862069 ], [ 29.764881772151899, -1.424638275862069 ], [ 29.764612278481014, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8123, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.424638275862069 ], [ 29.764881772151899, -1.42490775862069 ], [ 29.765151265822784, -1.42490775862069 ], [ 29.765151265822784, -1.424638275862069 ], [ 29.764881772151899, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8124, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.424638275862069 ], [ 29.765151265822784, -1.42490775862069 ], [ 29.765420759493672, -1.42490775862069 ], [ 29.765420759493672, -1.424638275862069 ], [ 29.765151265822784, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8125, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.424638275862069 ], [ 29.765420759493672, -1.42490775862069 ], [ 29.765959746835442, -1.42490775862069 ], [ 29.765959746835442, -1.424638275862069 ], [ 29.765420759493672, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8126, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.424638275862069 ], [ 29.765959746835442, -1.42490775862069 ], [ 29.766229240506327, -1.42490775862069 ], [ 29.766229240506327, -1.424638275862069 ], [ 29.765959746835442, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8127, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.424638275862069 ], [ 29.766229240506327, -1.42490775862069 ], [ 29.766498734177215, -1.42490775862069 ], [ 29.766498734177215, -1.424638275862069 ], [ 29.766229240506327, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8128, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.424638275862069 ], [ 29.766498734177215, -1.42490775862069 ], [ 29.7667682278481, -1.42490775862069 ], [ 29.7667682278481, -1.424638275862069 ], [ 29.766498734177215, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8129, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.424638275862069 ], [ 29.7667682278481, -1.42490775862069 ], [ 29.767037721518985, -1.42490775862069 ], [ 29.767037721518985, -1.424638275862069 ], [ 29.7667682278481, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8130, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.424638275862069 ], [ 29.767037721518985, -1.42490775862069 ], [ 29.767307215189874, -1.42490775862069 ], [ 29.767307215189874, -1.424638275862069 ], [ 29.767037721518985, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8131, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.424638275862069 ], [ 29.767307215189874, -1.42490775862069 ], [ 29.767576708860759, -1.42490775862069 ], [ 29.767576708860759, -1.424638275862069 ], [ 29.767307215189874, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8132, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.424638275862069 ], [ 29.767576708860759, -1.42490775862069 ], [ 29.767846202531643, -1.42490775862069 ], [ 29.767846202531643, -1.424638275862069 ], [ 29.767576708860759, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8133, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.424638275862069 ], [ 29.767846202531643, -1.42490775862069 ], [ 29.768115696202532, -1.42490775862069 ], [ 29.768115696202532, -1.424638275862069 ], [ 29.767846202531643, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8134, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.424638275862069 ], [ 29.768385189873417, -1.42490775862069 ], [ 29.768654683544302, -1.42490775862069 ], [ 29.768654683544302, -1.424638275862069 ], [ 29.768385189873417, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8135, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.424638275862069 ], [ 29.768654683544302, -1.42490775862069 ], [ 29.76892417721519, -1.42490775862069 ], [ 29.76892417721519, -1.424638275862069 ], [ 29.768654683544302, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8136, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.424638275862069 ], [ 29.76892417721519, -1.42490775862069 ], [ 29.769193670886075, -1.42490775862069 ], [ 29.769193670886075, -1.424638275862069 ], [ 29.76892417721519, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8137, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.424638275862069 ], [ 29.769193670886075, -1.42517724137931 ], [ 29.76946316455696, -1.42517724137931 ], [ 29.76946316455696, -1.424638275862069 ], [ 29.769193670886075, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8138, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.424638275862069 ], [ 29.76946316455696, -1.42490775862069 ], [ 29.769732658227849, -1.42490775862069 ], [ 29.769732658227849, -1.424638275862069 ], [ 29.76946316455696, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8139, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.424638275862069 ], [ 29.769732658227849, -1.42490775862069 ], [ 29.770002151898733, -1.42490775862069 ], [ 29.770002151898733, -1.424638275862069 ], [ 29.769732658227849, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8140, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.424638275862069 ], [ 29.770271645569618, -1.42490775862069 ], [ 29.770541139240507, -1.42490775862069 ], [ 29.770541139240507, -1.424638275862069 ], [ 29.770271645569618, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8141, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.424638275862069 ], [ 29.770541139240507, -1.42490775862069 ], [ 29.770810632911392, -1.42490775862069 ], [ 29.770810632911392, -1.424638275862069 ], [ 29.770541139240507, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8142, "El": 165 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.424638275862069 ], [ 29.771349620253165, -1.42517724137931 ], [ 29.77161911392405, -1.42517724137931 ], [ 29.77161911392405, -1.424638275862069 ], [ 29.771349620253165, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8143, "El": 162 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.424638275862069 ], [ 29.77161911392405, -1.42517724137931 ], [ 29.771888607594935, -1.42517724137931 ], [ 29.771888607594935, -1.424638275862069 ], [ 29.77161911392405, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8144, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.424638275862069 ], [ 29.772158101265823, -1.42517724137931 ], [ 29.772427594936708, -1.42517724137931 ], [ 29.772427594936708, -1.424638275862069 ], [ 29.772158101265823, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8145, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.424638275862069 ], [ 29.772427594936708, -1.42490775862069 ], [ 29.772697088607593, -1.42490775862069 ], [ 29.772697088607593, -1.424638275862069 ], [ 29.772427594936708, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8146, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.424638275862069 ], [ 29.772966582278482, -1.42517724137931 ], [ 29.773236075949367, -1.42517724137931 ], [ 29.773236075949367, -1.424638275862069 ], [ 29.772966582278482, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8147, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.424638275862069 ], [ 29.773236075949367, -1.42490775862069 ], [ 29.773505569620252, -1.42490775862069 ], [ 29.773505569620252, -1.424638275862069 ], [ 29.773236075949367, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8148, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.424638275862069 ], [ 29.77377506329114, -1.42517724137931 ], [ 29.774044556962025, -1.42517724137931 ], [ 29.774044556962025, -1.424638275862069 ], [ 29.77377506329114, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8149, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.424638275862069 ], [ 29.774044556962025, -1.42490775862069 ], [ 29.77431405063291, -1.42490775862069 ], [ 29.77431405063291, -1.424638275862069 ], [ 29.774044556962025, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8150, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.424638275862069 ], [ 29.774853037974683, -1.42517724137931 ], [ 29.775122531645568, -1.42517724137931 ], [ 29.775122531645568, -1.424638275862069 ], [ 29.774853037974683, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8151, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.424638275862069 ], [ 29.775122531645568, -1.42490775862069 ], [ 29.775392025316457, -1.42490775862069 ], [ 29.775392025316457, -1.424638275862069 ], [ 29.775122531645568, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8152, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.424638275862069 ], [ 29.775392025316457, -1.42490775862069 ], [ 29.775661518987341, -1.42490775862069 ], [ 29.775661518987341, -1.424638275862069 ], [ 29.775392025316457, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8153, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.424638275862069 ], [ 29.775661518987341, -1.42517724137931 ], [ 29.775931012658226, -1.42517724137931 ], [ 29.775931012658226, -1.424638275862069 ], [ 29.775661518987341, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8154, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.424638275862069 ], [ 29.775931012658226, -1.42490775862069 ], [ 29.776200506329115, -1.42490775862069 ], [ 29.776200506329115, -1.424638275862069 ], [ 29.775931012658226, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8155, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.424638275862069 ], [ 29.776200506329115, -1.42490775862069 ], [ 29.77647, -1.42490775862069 ], [ 29.77647, -1.424638275862069 ], [ 29.776200506329115, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8156, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.424638275862069 ], [ 29.776739493670885, -1.42490775862069 ], [ 29.777008987341773, -1.42490775862069 ], [ 29.777008987341773, -1.424638275862069 ], [ 29.776739493670885, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8157, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.424638275862069 ], [ 29.777008987341773, -1.42490775862069 ], [ 29.777278481012658, -1.42490775862069 ], [ 29.777278481012658, -1.424638275862069 ], [ 29.777008987341773, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8158, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.424638275862069 ], [ 29.777278481012658, -1.42490775862069 ], [ 29.777547974683543, -1.42490775862069 ], [ 29.777547974683543, -1.424638275862069 ], [ 29.777278481012658, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8159, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.424638275862069 ], [ 29.777547974683543, -1.42517724137931 ], [ 29.777817468354431, -1.42517724137931 ], [ 29.777817468354431, -1.424638275862069 ], [ 29.777547974683543, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8160, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.424638275862069 ], [ 29.777817468354431, -1.42490775862069 ], [ 29.778086962025316, -1.42490775862069 ], [ 29.778086962025316, -1.424638275862069 ], [ 29.777817468354431, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8161, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.424638275862069 ], [ 29.778086962025316, -1.42490775862069 ], [ 29.778356455696201, -1.42490775862069 ], [ 29.778356455696201, -1.424638275862069 ], [ 29.778086962025316, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8162, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.424638275862069 ], [ 29.778356455696201, -1.42490775862069 ], [ 29.77862594936709, -1.42490775862069 ], [ 29.77862594936709, -1.424638275862069 ], [ 29.778356455696201, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8163, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.424638275862069 ], [ 29.77862594936709, -1.42490775862069 ], [ 29.778895443037975, -1.42490775862069 ], [ 29.778895443037975, -1.424638275862069 ], [ 29.77862594936709, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8164, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.424638275862069 ], [ 29.778895443037975, -1.42490775862069 ], [ 29.77916493670886, -1.42490775862069 ], [ 29.77916493670886, -1.424638275862069 ], [ 29.778895443037975, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8165, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.424638275862069 ], [ 29.77916493670886, -1.42490775862069 ], [ 29.779434430379748, -1.42490775862069 ], [ 29.779434430379748, -1.424638275862069 ], [ 29.77916493670886, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8166, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.424638275862069 ], [ 29.779434430379748, -1.42490775862069 ], [ 29.779703924050633, -1.42490775862069 ], [ 29.779703924050633, -1.424638275862069 ], [ 29.779434430379748, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8167, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.424638275862069 ], [ 29.779703924050633, -1.42490775862069 ], [ 29.779973417721518, -1.42490775862069 ], [ 29.779973417721518, -1.424638275862069 ], [ 29.779703924050633, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8168, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.424638275862069 ], [ 29.779973417721518, -1.42490775862069 ], [ 29.780242911392406, -1.42490775862069 ], [ 29.780242911392406, -1.424638275862069 ], [ 29.779973417721518, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8169, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.424638275862069 ], [ 29.780242911392406, -1.42490775862069 ], [ 29.780512405063291, -1.42490775862069 ], [ 29.780512405063291, -1.424638275862069 ], [ 29.780242911392406, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8170, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.424638275862069 ], [ 29.780512405063291, -1.42490775862069 ], [ 29.780781898734176, -1.42490775862069 ], [ 29.780781898734176, -1.424638275862069 ], [ 29.780512405063291, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8171, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.424638275862069 ], [ 29.780781898734176, -1.42490775862069 ], [ 29.781051392405065, -1.42490775862069 ], [ 29.781051392405065, -1.424638275862069 ], [ 29.780781898734176, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8172, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.424638275862069 ], [ 29.781051392405065, -1.42490775862069 ], [ 29.78132088607595, -1.42490775862069 ], [ 29.78132088607595, -1.424638275862069 ], [ 29.781051392405065, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8173, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.424638275862069 ], [ 29.78132088607595, -1.42490775862069 ], [ 29.781590379746834, -1.42490775862069 ], [ 29.781590379746834, -1.424638275862069 ], [ 29.78132088607595, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8174, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.424638275862069 ], [ 29.781590379746834, -1.42490775862069 ], [ 29.781859873417723, -1.42490775862069 ], [ 29.781859873417723, -1.424638275862069 ], [ 29.781590379746834, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8175, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.424638275862069 ], [ 29.781859873417723, -1.42490775862069 ], [ 29.782129367088608, -1.42490775862069 ], [ 29.782129367088608, -1.424638275862069 ], [ 29.781859873417723, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8176, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.424638275862069 ], [ 29.782129367088608, -1.42490775862069 ], [ 29.782398860759493, -1.42490775862069 ], [ 29.782398860759493, -1.424638275862069 ], [ 29.782129367088608, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8177, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.424638275862069 ], [ 29.782398860759493, -1.42490775862069 ], [ 29.782668354430381, -1.42490775862069 ], [ 29.782668354430381, -1.424638275862069 ], [ 29.782398860759493, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8178, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.424638275862069 ], [ 29.782668354430381, -1.42490775862069 ], [ 29.782937848101266, -1.42490775862069 ], [ 29.782937848101266, -1.424638275862069 ], [ 29.782668354430381, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8179, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.424638275862069 ], [ 29.788597215189874, -1.42517724137931 ], [ 29.788866708860759, -1.42517724137931 ], [ 29.788866708860759, -1.424638275862069 ], [ 29.788597215189874, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8180, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.424638275862069 ], [ 29.788866708860759, -1.42517724137931 ], [ 29.789136202531644, -1.42517724137931 ], [ 29.789136202531644, -1.424638275862069 ], [ 29.788866708860759, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8181, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.424638275862069 ], [ 29.789136202531644, -1.42490775862069 ], [ 29.789405696202532, -1.42490775862069 ], [ 29.789405696202532, -1.424638275862069 ], [ 29.789136202531644, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8182, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.424638275862069 ], [ 29.789405696202532, -1.42490775862069 ], [ 29.789675189873417, -1.42490775862069 ], [ 29.789675189873417, -1.424638275862069 ], [ 29.789405696202532, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8183, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.424638275862069 ], [ 29.789675189873417, -1.42490775862069 ], [ 29.789944683544302, -1.42490775862069 ], [ 29.789944683544302, -1.424638275862069 ], [ 29.789675189873417, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8184, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.424638275862069 ], [ 29.789944683544302, -1.42490775862069 ], [ 29.790214177215191, -1.42490775862069 ], [ 29.790214177215191, -1.424638275862069 ], [ 29.789944683544302, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8185, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.424638275862069 ], [ 29.790214177215191, -1.42490775862069 ], [ 29.790483670886076, -1.42490775862069 ], [ 29.790483670886076, -1.424638275862069 ], [ 29.790214177215191, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8186, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.424638275862069 ], [ 29.790483670886076, -1.42490775862069 ], [ 29.790753164556961, -1.42490775862069 ], [ 29.790753164556961, -1.424638275862069 ], [ 29.790483670886076, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8187, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.424638275862069 ], [ 29.790753164556961, -1.42490775862069 ], [ 29.791022658227849, -1.42490775862069 ], [ 29.791022658227849, -1.424638275862069 ], [ 29.790753164556961, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8188, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.424638275862069 ], [ 29.791022658227849, -1.42490775862069 ], [ 29.791292151898734, -1.42490775862069 ], [ 29.791292151898734, -1.424638275862069 ], [ 29.791022658227849, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8189, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.424638275862069 ], [ 29.791292151898734, -1.42490775862069 ], [ 29.791561645569619, -1.42490775862069 ], [ 29.791561645569619, -1.424638275862069 ], [ 29.791292151898734, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8190, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.424638275862069 ], [ 29.791561645569619, -1.42490775862069 ], [ 29.791831139240507, -1.42490775862069 ], [ 29.791831139240507, -1.424638275862069 ], [ 29.791561645569619, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8191, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.424638275862069 ], [ 29.791831139240507, -1.42490775862069 ], [ 29.792100632911392, -1.42490775862069 ], [ 29.792100632911392, -1.424638275862069 ], [ 29.791831139240507, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8192, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.424638275862069 ], [ 29.792100632911392, -1.42490775862069 ], [ 29.792370126582277, -1.42490775862069 ], [ 29.792370126582277, -1.424638275862069 ], [ 29.792100632911392, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8193, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.424638275862069 ], [ 29.792370126582277, -1.42490775862069 ], [ 29.792909113924051, -1.42490775862069 ], [ 29.792909113924051, -1.424638275862069 ], [ 29.792370126582277, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8194, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.424638275862069 ], [ 29.792909113924051, -1.42490775862069 ], [ 29.793178607594935, -1.42490775862069 ], [ 29.793178607594935, -1.424638275862069 ], [ 29.792909113924051, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8195, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.424638275862069 ], [ 29.801263417721518, -1.42490775862069 ], [ 29.801532911392407, -1.42490775862069 ], [ 29.801532911392407, -1.424638275862069 ], [ 29.801263417721518, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8196, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.424638275862069 ], [ 29.801532911392407, -1.42490775862069 ], [ 29.802071898734177, -1.42490775862069 ], [ 29.802071898734177, -1.424638275862069 ], [ 29.801532911392407, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8197, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.424638275862069 ], [ 29.802071898734177, -1.42490775862069 ], [ 29.802341392405065, -1.42490775862069 ], [ 29.802341392405065, -1.424638275862069 ], [ 29.802071898734177, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8198, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.424638275862069 ], [ 29.802341392405065, -1.42490775862069 ], [ 29.80261088607595, -1.42490775862069 ], [ 29.80261088607595, -1.424638275862069 ], [ 29.802341392405065, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8199, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.424638275862069 ], [ 29.80261088607595, -1.42490775862069 ], [ 29.802880379746835, -1.42490775862069 ], [ 29.802880379746835, -1.424638275862069 ], [ 29.80261088607595, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8200, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.424638275862069 ], [ 29.802880379746835, -1.42490775862069 ], [ 29.803149873417723, -1.42490775862069 ], [ 29.803149873417723, -1.424638275862069 ], [ 29.802880379746835, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8201, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.424638275862069 ], [ 29.803149873417723, -1.42490775862069 ], [ 29.803419367088608, -1.42490775862069 ], [ 29.803419367088608, -1.424638275862069 ], [ 29.803149873417723, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8202, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.424638275862069 ], [ 29.803419367088608, -1.42490775862069 ], [ 29.803688860759493, -1.42490775862069 ], [ 29.803688860759493, -1.424638275862069 ], [ 29.803419367088608, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8203, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.424638275862069 ], [ 29.803688860759493, -1.42490775862069 ], [ 29.803958354430382, -1.42490775862069 ], [ 29.803958354430382, -1.424638275862069 ], [ 29.803688860759493, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8204, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.424638275862069 ], [ 29.803958354430382, -1.42490775862069 ], [ 29.804227848101267, -1.42490775862069 ], [ 29.804227848101267, -1.424638275862069 ], [ 29.803958354430382, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8205, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.424638275862069 ], [ 29.804227848101267, -1.42490775862069 ], [ 29.804497341772151, -1.42490775862069 ], [ 29.804497341772151, -1.424638275862069 ], [ 29.804227848101267, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8206, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.424638275862069 ], [ 29.804497341772151, -1.42490775862069 ], [ 29.80476683544304, -1.42490775862069 ], [ 29.80476683544304, -1.424638275862069 ], [ 29.804497341772151, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8207, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.424638275862069 ], [ 29.80476683544304, -1.42490775862069 ], [ 29.805036329113925, -1.42490775862069 ], [ 29.805036329113925, -1.424638275862069 ], [ 29.80476683544304, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8208, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.424638275862069 ], [ 29.805036329113925, -1.42490775862069 ], [ 29.80530582278481, -1.42490775862069 ], [ 29.80530582278481, -1.424638275862069 ], [ 29.805036329113925, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8209, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.424638275862069 ], [ 29.80530582278481, -1.42490775862069 ], [ 29.805575316455698, -1.42490775862069 ], [ 29.805575316455698, -1.424638275862069 ], [ 29.80530582278481, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8210, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.424638275862069 ], [ 29.805575316455698, -1.42490775862069 ], [ 29.805844810126583, -1.42490775862069 ], [ 29.805844810126583, -1.424638275862069 ], [ 29.805575316455698, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8211, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.424638275862069 ], [ 29.805844810126583, -1.42490775862069 ], [ 29.806114303797468, -1.42490775862069 ], [ 29.806114303797468, -1.424638275862069 ], [ 29.805844810126583, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8212, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.424638275862069 ], [ 29.806114303797468, -1.42490775862069 ], [ 29.806383797468357, -1.42490775862069 ], [ 29.806383797468357, -1.424638275862069 ], [ 29.806114303797468, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8213, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.424638275862069 ], [ 29.806383797468357, -1.42490775862069 ], [ 29.806653291139241, -1.42490775862069 ], [ 29.806653291139241, -1.424638275862069 ], [ 29.806383797468357, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8214, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.424638275862069 ], [ 29.806653291139241, -1.42490775862069 ], [ 29.806922784810126, -1.42490775862069 ], [ 29.806922784810126, -1.424638275862069 ], [ 29.806653291139241, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8215, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.424638275862069 ], [ 29.806922784810126, -1.42490775862069 ], [ 29.807192278481015, -1.42490775862069 ], [ 29.807192278481015, -1.424638275862069 ], [ 29.806922784810126, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8216, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.424638275862069 ], [ 29.807192278481015, -1.42490775862069 ], [ 29.8074617721519, -1.42490775862069 ], [ 29.8074617721519, -1.424638275862069 ], [ 29.807192278481015, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8217, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.424638275862069 ], [ 29.8074617721519, -1.42490775862069 ], [ 29.808000759493673, -1.42490775862069 ], [ 29.808000759493673, -1.424638275862069 ], [ 29.8074617721519, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8218, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.424638275862069 ], [ 29.808000759493673, -1.42490775862069 ], [ 29.808270253164558, -1.42490775862069 ], [ 29.808270253164558, -1.424638275862069 ], [ 29.808000759493673, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8219, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.424638275862069 ], [ 29.808270253164558, -1.42490775862069 ], [ 29.808539746835443, -1.42490775862069 ], [ 29.808539746835443, -1.424638275862069 ], [ 29.808270253164558, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8220, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.424638275862069 ], [ 29.808539746835443, -1.42490775862069 ], [ 29.809078734177216, -1.42490775862069 ], [ 29.809078734177216, -1.424638275862069 ], [ 29.808539746835443, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8221, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.424638275862069 ], [ 29.809078734177216, -1.42490775862069 ], [ 29.809348227848101, -1.42490775862069 ], [ 29.809348227848101, -1.424638275862069 ], [ 29.809078734177216, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8222, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.424638275862069 ], [ 29.809348227848101, -1.42490775862069 ], [ 29.809617721518986, -1.42490775862069 ], [ 29.809617721518986, -1.424638275862069 ], [ 29.809348227848101, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8223, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.424638275862069 ], [ 29.809617721518986, -1.42490775862069 ], [ 29.809887215189875, -1.42490775862069 ], [ 29.809887215189875, -1.424638275862069 ], [ 29.809617721518986, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8224, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.424638275862069 ], [ 29.809887215189875, -1.42490775862069 ], [ 29.81015670886076, -1.42490775862069 ], [ 29.81015670886076, -1.424638275862069 ], [ 29.809887215189875, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8225, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.424638275862069 ], [ 29.81015670886076, -1.42490775862069 ], [ 29.810426202531644, -1.42490775862069 ], [ 29.810426202531644, -1.424638275862069 ], [ 29.81015670886076, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8226, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.424638275862069 ], [ 29.810426202531644, -1.42490775862069 ], [ 29.810965189873418, -1.42490775862069 ], [ 29.810965189873418, -1.424638275862069 ], [ 29.810426202531644, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8227, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.424638275862069 ], [ 29.810965189873418, -1.42490775862069 ], [ 29.811234683544303, -1.42490775862069 ], [ 29.811234683544303, -1.424638275862069 ], [ 29.810965189873418, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8228, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814738101265824, -1.424638275862069 ], [ 29.814738101265824, -1.42490775862069 ], [ 29.815007594936709, -1.42490775862069 ], [ 29.815007594936709, -1.424638275862069 ], [ 29.814738101265824, -1.424638275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8229, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.42490775862069 ], [ 29.759222405063291, -1.425446724137931 ], [ 29.759491898734176, -1.425446724137931 ], [ 29.759491898734176, -1.42490775862069 ], [ 29.759222405063291, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8230, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.42490775862069 ], [ 29.759491898734176, -1.425985689655173 ], [ 29.759761392405064, -1.425985689655173 ], [ 29.759761392405064, -1.42490775862069 ], [ 29.759491898734176, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8231, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.42490775862069 ], [ 29.759761392405064, -1.425716206896552 ], [ 29.760030886075949, -1.425716206896552 ], [ 29.760030886075949, -1.42490775862069 ], [ 29.759761392405064, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8232, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.42490775862069 ], [ 29.760030886075949, -1.42517724137931 ], [ 29.760300379746834, -1.42517724137931 ], [ 29.760300379746834, -1.42490775862069 ], [ 29.760030886075949, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8233, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.42490775862069 ], [ 29.760300379746834, -1.42517724137931 ], [ 29.760569873417722, -1.42517724137931 ], [ 29.760569873417722, -1.42490775862069 ], [ 29.760300379746834, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8234, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.42490775862069 ], [ 29.760569873417722, -1.42517724137931 ], [ 29.760839367088607, -1.42517724137931 ], [ 29.760839367088607, -1.42490775862069 ], [ 29.760569873417722, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8235, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.42490775862069 ], [ 29.760839367088607, -1.42517724137931 ], [ 29.761108860759492, -1.42517724137931 ], [ 29.761108860759492, -1.42490775862069 ], [ 29.760839367088607, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8236, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.42490775862069 ], [ 29.761108860759492, -1.42517724137931 ], [ 29.761378354430381, -1.42517724137931 ], [ 29.761378354430381, -1.42490775862069 ], [ 29.761108860759492, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8237, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.42490775862069 ], [ 29.761378354430381, -1.42517724137931 ], [ 29.761647848101266, -1.42517724137931 ], [ 29.761647848101266, -1.42490775862069 ], [ 29.761378354430381, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8238, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.42490775862069 ], [ 29.761647848101266, -1.42517724137931 ], [ 29.761917341772151, -1.42517724137931 ], [ 29.761917341772151, -1.42490775862069 ], [ 29.761647848101266, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8239, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.42490775862069 ], [ 29.761917341772151, -1.42517724137931 ], [ 29.762186835443039, -1.42517724137931 ], [ 29.762186835443039, -1.42490775862069 ], [ 29.761917341772151, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8240, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.42490775862069 ], [ 29.762186835443039, -1.42517724137931 ], [ 29.762456329113924, -1.42517724137931 ], [ 29.762456329113924, -1.42490775862069 ], [ 29.762186835443039, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8241, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.42490775862069 ], [ 29.762456329113924, -1.42517724137931 ], [ 29.762725822784809, -1.42517724137931 ], [ 29.762725822784809, -1.42490775862069 ], [ 29.762456329113924, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8242, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.42490775862069 ], [ 29.762725822784809, -1.42517724137931 ], [ 29.762995316455697, -1.42517724137931 ], [ 29.762995316455697, -1.42490775862069 ], [ 29.762725822784809, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8243, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.42490775862069 ], [ 29.762995316455697, -1.42517724137931 ], [ 29.763264810126582, -1.42517724137931 ], [ 29.763264810126582, -1.42490775862069 ], [ 29.762995316455697, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8244, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.42490775862069 ], [ 29.763264810126582, -1.42517724137931 ], [ 29.763534303797467, -1.42517724137931 ], [ 29.763534303797467, -1.42490775862069 ], [ 29.763264810126582, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8245, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.42490775862069 ], [ 29.763534303797467, -1.42517724137931 ], [ 29.763803797468356, -1.42517724137931 ], [ 29.763803797468356, -1.42490775862069 ], [ 29.763534303797467, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8246, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.42490775862069 ], [ 29.763803797468356, -1.42517724137931 ], [ 29.76407329113924, -1.42517724137931 ], [ 29.76407329113924, -1.42490775862069 ], [ 29.763803797468356, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8247, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.42490775862069 ], [ 29.76407329113924, -1.42517724137931 ], [ 29.764342784810125, -1.42517724137931 ], [ 29.764342784810125, -1.42490775862069 ], [ 29.76407329113924, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8248, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.42490775862069 ], [ 29.764342784810125, -1.42517724137931 ], [ 29.764612278481014, -1.42517724137931 ], [ 29.764612278481014, -1.42490775862069 ], [ 29.764342784810125, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8249, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.42490775862069 ], [ 29.764612278481014, -1.42517724137931 ], [ 29.764881772151899, -1.42517724137931 ], [ 29.764881772151899, -1.42490775862069 ], [ 29.764612278481014, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8250, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.42490775862069 ], [ 29.764881772151899, -1.42517724137931 ], [ 29.765151265822784, -1.42517724137931 ], [ 29.765151265822784, -1.42490775862069 ], [ 29.764881772151899, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8251, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.42490775862069 ], [ 29.765151265822784, -1.42517724137931 ], [ 29.765420759493672, -1.42517724137931 ], [ 29.765420759493672, -1.42490775862069 ], [ 29.765151265822784, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8252, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.42490775862069 ], [ 29.765420759493672, -1.42517724137931 ], [ 29.765959746835442, -1.42517724137931 ], [ 29.765959746835442, -1.42490775862069 ], [ 29.765420759493672, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8253, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.42490775862069 ], [ 29.765959746835442, -1.42517724137931 ], [ 29.766229240506327, -1.42517724137931 ], [ 29.766229240506327, -1.42490775862069 ], [ 29.765959746835442, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8254, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.42490775862069 ], [ 29.766229240506327, -1.42517724137931 ], [ 29.766498734177215, -1.42517724137931 ], [ 29.766498734177215, -1.42490775862069 ], [ 29.766229240506327, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8255, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.42490775862069 ], [ 29.766498734177215, -1.42517724137931 ], [ 29.7667682278481, -1.42517724137931 ], [ 29.7667682278481, -1.42490775862069 ], [ 29.766498734177215, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8256, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.42490775862069 ], [ 29.7667682278481, -1.42517724137931 ], [ 29.767037721518985, -1.42517724137931 ], [ 29.767037721518985, -1.42490775862069 ], [ 29.7667682278481, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8257, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.42490775862069 ], [ 29.767037721518985, -1.42517724137931 ], [ 29.767307215189874, -1.42517724137931 ], [ 29.767307215189874, -1.42490775862069 ], [ 29.767037721518985, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8258, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.42490775862069 ], [ 29.767307215189874, -1.42517724137931 ], [ 29.767576708860759, -1.42517724137931 ], [ 29.767576708860759, -1.42490775862069 ], [ 29.767307215189874, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8259, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.42490775862069 ], [ 29.767576708860759, -1.42517724137931 ], [ 29.767846202531643, -1.42517724137931 ], [ 29.767846202531643, -1.42490775862069 ], [ 29.767576708860759, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8260, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.42490775862069 ], [ 29.767846202531643, -1.42517724137931 ], [ 29.768115696202532, -1.42517724137931 ], [ 29.768115696202532, -1.42490775862069 ], [ 29.767846202531643, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8261, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.42490775862069 ], [ 29.768115696202532, -1.42517724137931 ], [ 29.768385189873417, -1.42517724137931 ], [ 29.768385189873417, -1.42490775862069 ], [ 29.768115696202532, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8262, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.42490775862069 ], [ 29.768385189873417, -1.42517724137931 ], [ 29.768654683544302, -1.42517724137931 ], [ 29.768654683544302, -1.42490775862069 ], [ 29.768385189873417, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8263, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.42490775862069 ], [ 29.768654683544302, -1.42517724137931 ], [ 29.76892417721519, -1.42517724137931 ], [ 29.76892417721519, -1.42490775862069 ], [ 29.768654683544302, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8264, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.42490775862069 ], [ 29.76892417721519, -1.42517724137931 ], [ 29.769193670886075, -1.42517724137931 ], [ 29.769193670886075, -1.42490775862069 ], [ 29.76892417721519, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8265, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.42490775862069 ], [ 29.76946316455696, -1.42517724137931 ], [ 29.769732658227849, -1.42517724137931 ], [ 29.769732658227849, -1.42490775862069 ], [ 29.76946316455696, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8266, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.42490775862069 ], [ 29.769732658227849, -1.42517724137931 ], [ 29.770002151898733, -1.42517724137931 ], [ 29.770002151898733, -1.42490775862069 ], [ 29.769732658227849, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8267, "El": 158 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.42490775862069 ], [ 29.770002151898733, -1.42517724137931 ], [ 29.770271645569618, -1.42517724137931 ], [ 29.770271645569618, -1.42490775862069 ], [ 29.770002151898733, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8268, "El": 162 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.42490775862069 ], [ 29.770271645569618, -1.425446724137931 ], [ 29.770541139240507, -1.425446724137931 ], [ 29.770541139240507, -1.42490775862069 ], [ 29.770271645569618, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8269, "El": 165 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.42490775862069 ], [ 29.770541139240507, -1.42517724137931 ], [ 29.770810632911392, -1.42517724137931 ], [ 29.770810632911392, -1.42490775862069 ], [ 29.770541139240507, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8270, "El": 168 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.42490775862069 ], [ 29.770810632911392, -1.42517724137931 ], [ 29.771080126582277, -1.42517724137931 ], [ 29.771080126582277, -1.425446724137931 ], [ 29.771349620253165, -1.425446724137931 ], [ 29.771349620253165, -1.42490775862069 ], [ 29.770810632911392, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8271, "El": 158 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.42490775862069 ], [ 29.771888607594935, -1.425446724137931 ], [ 29.772158101265823, -1.425446724137931 ], [ 29.772158101265823, -1.42490775862069 ], [ 29.771888607594935, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8272, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.42490775862069 ], [ 29.772427594936708, -1.425716206896552 ], [ 29.772697088607593, -1.425716206896552 ], [ 29.772697088607593, -1.42490775862069 ], [ 29.772427594936708, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8273, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.42490775862069 ], [ 29.772697088607593, -1.425446724137931 ], [ 29.772966582278482, -1.425446724137931 ], [ 29.772966582278482, -1.42490775862069 ], [ 29.772697088607593, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8274, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.42490775862069 ], [ 29.773236075949367, -1.425446724137931 ], [ 29.773505569620252, -1.425446724137931 ], [ 29.773505569620252, -1.42490775862069 ], [ 29.773236075949367, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8275, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.42490775862069 ], [ 29.773505569620252, -1.42517724137931 ], [ 29.77377506329114, -1.42517724137931 ], [ 29.77377506329114, -1.42490775862069 ], [ 29.773505569620252, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8276, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.42490775862069 ], [ 29.774044556962025, -1.425446724137931 ], [ 29.77431405063291, -1.425446724137931 ], [ 29.77431405063291, -1.42490775862069 ], [ 29.774044556962025, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8277, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.42490775862069 ], [ 29.77431405063291, -1.42517724137931 ], [ 29.774853037974683, -1.42517724137931 ], [ 29.774853037974683, -1.42490775862069 ], [ 29.77431405063291, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8278, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.42490775862069 ], [ 29.775122531645568, -1.425446724137931 ], [ 29.775392025316457, -1.425446724137931 ], [ 29.775392025316457, -1.42490775862069 ], [ 29.775122531645568, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8279, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.42490775862069 ], [ 29.775392025316457, -1.42517724137931 ], [ 29.775661518987341, -1.42517724137931 ], [ 29.775661518987341, -1.42490775862069 ], [ 29.775392025316457, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8280, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.42490775862069 ], [ 29.775931012658226, -1.425446724137931 ], [ 29.776200506329115, -1.425446724137931 ], [ 29.776200506329115, -1.42490775862069 ], [ 29.775931012658226, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8281, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.42490775862069 ], [ 29.776200506329115, -1.42517724137931 ], [ 29.77647, -1.42517724137931 ], [ 29.77647, -1.42490775862069 ], [ 29.776200506329115, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8282, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.42490775862069 ], [ 29.77647, -1.42517724137931 ], [ 29.776739493670885, -1.42517724137931 ], [ 29.776739493670885, -1.42490775862069 ], [ 29.77647, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8283, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.42490775862069 ], [ 29.776739493670885, -1.425446724137931 ], [ 29.777008987341773, -1.425446724137931 ], [ 29.777008987341773, -1.42490775862069 ], [ 29.776739493670885, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8284, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.42490775862069 ], [ 29.777008987341773, -1.42517724137931 ], [ 29.777278481012658, -1.42517724137931 ], [ 29.777278481012658, -1.42490775862069 ], [ 29.777008987341773, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8285, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.42490775862069 ], [ 29.777278481012658, -1.42517724137931 ], [ 29.777547974683543, -1.42517724137931 ], [ 29.777547974683543, -1.42490775862069 ], [ 29.777278481012658, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8286, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.42490775862069 ], [ 29.777817468354431, -1.42517724137931 ], [ 29.778086962025316, -1.42517724137931 ], [ 29.778086962025316, -1.42490775862069 ], [ 29.777817468354431, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8287, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.42490775862069 ], [ 29.778086962025316, -1.42517724137931 ], [ 29.778356455696201, -1.42517724137931 ], [ 29.778356455696201, -1.42490775862069 ], [ 29.778086962025316, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8288, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.42490775862069 ], [ 29.778356455696201, -1.42517724137931 ], [ 29.77862594936709, -1.42517724137931 ], [ 29.77862594936709, -1.42490775862069 ], [ 29.778356455696201, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8289, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.42490775862069 ], [ 29.77862594936709, -1.425446724137931 ], [ 29.778895443037975, -1.425446724137931 ], [ 29.778895443037975, -1.42490775862069 ], [ 29.77862594936709, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8290, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.42490775862069 ], [ 29.778895443037975, -1.42517724137931 ], [ 29.77916493670886, -1.42517724137931 ], [ 29.77916493670886, -1.42490775862069 ], [ 29.778895443037975, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8291, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.42490775862069 ], [ 29.77916493670886, -1.42517724137931 ], [ 29.779434430379748, -1.42517724137931 ], [ 29.779434430379748, -1.42490775862069 ], [ 29.77916493670886, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8292, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.42490775862069 ], [ 29.779434430379748, -1.42517724137931 ], [ 29.779703924050633, -1.42517724137931 ], [ 29.779703924050633, -1.42490775862069 ], [ 29.779434430379748, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8293, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.42490775862069 ], [ 29.779703924050633, -1.42517724137931 ], [ 29.779973417721518, -1.42517724137931 ], [ 29.779973417721518, -1.42490775862069 ], [ 29.779703924050633, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8294, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.42490775862069 ], [ 29.779973417721518, -1.42517724137931 ], [ 29.780242911392406, -1.42517724137931 ], [ 29.780242911392406, -1.42490775862069 ], [ 29.779973417721518, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8295, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.42490775862069 ], [ 29.780242911392406, -1.42517724137931 ], [ 29.780512405063291, -1.42517724137931 ], [ 29.780512405063291, -1.42490775862069 ], [ 29.780242911392406, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8296, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.42490775862069 ], [ 29.780512405063291, -1.42517724137931 ], [ 29.780781898734176, -1.42517724137931 ], [ 29.780781898734176, -1.42490775862069 ], [ 29.780512405063291, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8297, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.42490775862069 ], [ 29.780781898734176, -1.42517724137931 ], [ 29.781051392405065, -1.42517724137931 ], [ 29.781051392405065, -1.42490775862069 ], [ 29.780781898734176, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8298, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.42490775862069 ], [ 29.781051392405065, -1.42517724137931 ], [ 29.78132088607595, -1.42517724137931 ], [ 29.78132088607595, -1.42490775862069 ], [ 29.781051392405065, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8299, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.42490775862069 ], [ 29.78132088607595, -1.42517724137931 ], [ 29.781590379746834, -1.42517724137931 ], [ 29.781590379746834, -1.42490775862069 ], [ 29.78132088607595, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8300, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.42490775862069 ], [ 29.781590379746834, -1.42517724137931 ], [ 29.781859873417723, -1.42517724137931 ], [ 29.781859873417723, -1.42490775862069 ], [ 29.781590379746834, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8301, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.42490775862069 ], [ 29.781859873417723, -1.42517724137931 ], [ 29.782129367088608, -1.42517724137931 ], [ 29.782129367088608, -1.42490775862069 ], [ 29.781859873417723, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8302, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.42490775862069 ], [ 29.782129367088608, -1.42517724137931 ], [ 29.782398860759493, -1.42517724137931 ], [ 29.782398860759493, -1.42490775862069 ], [ 29.782129367088608, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8303, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.42490775862069 ], [ 29.782398860759493, -1.42517724137931 ], [ 29.782668354430381, -1.42517724137931 ], [ 29.782668354430381, -1.42490775862069 ], [ 29.782398860759493, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8304, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.42490775862069 ], [ 29.782668354430381, -1.42517724137931 ], [ 29.782937848101266, -1.42517724137931 ], [ 29.782937848101266, -1.42490775862069 ], [ 29.782668354430381, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8305, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.42490775862069 ], [ 29.782937848101266, -1.42517724137931 ], [ 29.783207341772151, -1.42517724137931 ], [ 29.783207341772151, -1.42490775862069 ], [ 29.782937848101266, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8306, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.42490775862069 ], [ 29.783207341772151, -1.42517724137931 ], [ 29.783476835443039, -1.42517724137931 ], [ 29.783476835443039, -1.42490775862069 ], [ 29.783207341772151, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8307, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.42490775862069 ], [ 29.783476835443039, -1.42517724137931 ], [ 29.784015822784809, -1.42517724137931 ], [ 29.784015822784809, -1.42490775862069 ], [ 29.783476835443039, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8308, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.42490775862069 ], [ 29.784015822784809, -1.42517724137931 ], [ 29.784285316455698, -1.42517724137931 ], [ 29.784285316455698, -1.42490775862069 ], [ 29.784015822784809, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8309, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.42490775862069 ], [ 29.789136202531644, -1.425446724137931 ], [ 29.789405696202532, -1.425446724137931 ], [ 29.789405696202532, -1.42490775862069 ], [ 29.789136202531644, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8310, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.42490775862069 ], [ 29.789405696202532, -1.425446724137931 ], [ 29.789675189873417, -1.425446724137931 ], [ 29.789675189873417, -1.42490775862069 ], [ 29.789405696202532, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8311, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.42490775862069 ], [ 29.789675189873417, -1.42517724137931 ], [ 29.789944683544302, -1.42517724137931 ], [ 29.789944683544302, -1.42490775862069 ], [ 29.789675189873417, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8312, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.42490775862069 ], [ 29.789944683544302, -1.42517724137931 ], [ 29.790214177215191, -1.42517724137931 ], [ 29.790214177215191, -1.42490775862069 ], [ 29.789944683544302, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8313, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.42490775862069 ], [ 29.790214177215191, -1.42517724137931 ], [ 29.790483670886076, -1.42517724137931 ], [ 29.790483670886076, -1.42490775862069 ], [ 29.790214177215191, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8314, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.42490775862069 ], [ 29.790483670886076, -1.42517724137931 ], [ 29.790753164556961, -1.42517724137931 ], [ 29.790753164556961, -1.42490775862069 ], [ 29.790483670886076, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8315, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.42490775862069 ], [ 29.790753164556961, -1.42517724137931 ], [ 29.791022658227849, -1.42517724137931 ], [ 29.791022658227849, -1.42490775862069 ], [ 29.790753164556961, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8316, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.42490775862069 ], [ 29.791022658227849, -1.42517724137931 ], [ 29.791292151898734, -1.42517724137931 ], [ 29.791292151898734, -1.42490775862069 ], [ 29.791022658227849, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8317, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.42490775862069 ], [ 29.791292151898734, -1.42517724137931 ], [ 29.791561645569619, -1.42517724137931 ], [ 29.791561645569619, -1.42490775862069 ], [ 29.791292151898734, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8318, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.42490775862069 ], [ 29.791561645569619, -1.42517724137931 ], [ 29.791831139240507, -1.42517724137931 ], [ 29.791831139240507, -1.42490775862069 ], [ 29.791561645569619, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8319, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.42490775862069 ], [ 29.791831139240507, -1.42517724137931 ], [ 29.792100632911392, -1.42517724137931 ], [ 29.792100632911392, -1.42490775862069 ], [ 29.791831139240507, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8320, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.42490775862069 ], [ 29.792100632911392, -1.42517724137931 ], [ 29.792370126582277, -1.42517724137931 ], [ 29.792370126582277, -1.42490775862069 ], [ 29.792100632911392, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8321, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.42490775862069 ], [ 29.792370126582277, -1.42517724137931 ], [ 29.792909113924051, -1.42517724137931 ], [ 29.792909113924051, -1.42490775862069 ], [ 29.792370126582277, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8322, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.42490775862069 ], [ 29.792909113924051, -1.42517724137931 ], [ 29.793178607594935, -1.42517724137931 ], [ 29.793178607594935, -1.42490775862069 ], [ 29.792909113924051, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8323, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.42490775862069 ], [ 29.793178607594935, -1.42517724137931 ], [ 29.793448101265824, -1.42517724137931 ], [ 29.793448101265824, -1.42490775862069 ], [ 29.793178607594935, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8324, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.42490775862069 ], [ 29.793448101265824, -1.425446724137931 ], [ 29.793717594936709, -1.425446724137931 ], [ 29.793717594936709, -1.42490775862069 ], [ 29.793448101265824, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8325, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.42490775862069 ], [ 29.801263417721518, -1.42517724137931 ], [ 29.801532911392407, -1.42517724137931 ], [ 29.801532911392407, -1.42490775862069 ], [ 29.801263417721518, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8326, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.42490775862069 ], [ 29.801532911392407, -1.42517724137931 ], [ 29.802071898734177, -1.42517724137931 ], [ 29.802071898734177, -1.42490775862069 ], [ 29.801532911392407, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8327, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.42490775862069 ], [ 29.802071898734177, -1.42517724137931 ], [ 29.802341392405065, -1.42517724137931 ], [ 29.802341392405065, -1.42490775862069 ], [ 29.802071898734177, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8328, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.42490775862069 ], [ 29.802341392405065, -1.42517724137931 ], [ 29.80261088607595, -1.42517724137931 ], [ 29.80261088607595, -1.42490775862069 ], [ 29.802341392405065, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8329, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.42490775862069 ], [ 29.80261088607595, -1.42517724137931 ], [ 29.802880379746835, -1.42517724137931 ], [ 29.802880379746835, -1.42490775862069 ], [ 29.80261088607595, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8330, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.42490775862069 ], [ 29.802880379746835, -1.42517724137931 ], [ 29.803149873417723, -1.42517724137931 ], [ 29.803149873417723, -1.42490775862069 ], [ 29.802880379746835, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8331, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.42490775862069 ], [ 29.803149873417723, -1.42517724137931 ], [ 29.803419367088608, -1.42517724137931 ], [ 29.803419367088608, -1.42490775862069 ], [ 29.803149873417723, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8332, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.42490775862069 ], [ 29.803419367088608, -1.42517724137931 ], [ 29.803688860759493, -1.42517724137931 ], [ 29.803688860759493, -1.42490775862069 ], [ 29.803419367088608, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8333, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.42490775862069 ], [ 29.803688860759493, -1.425446724137931 ], [ 29.803958354430382, -1.425446724137931 ], [ 29.803958354430382, -1.42490775862069 ], [ 29.803688860759493, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8334, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.42490775862069 ], [ 29.803958354430382, -1.42517724137931 ], [ 29.804227848101267, -1.42517724137931 ], [ 29.804227848101267, -1.42490775862069 ], [ 29.803958354430382, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8335, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.42490775862069 ], [ 29.804227848101267, -1.42517724137931 ], [ 29.804497341772151, -1.42517724137931 ], [ 29.804497341772151, -1.42490775862069 ], [ 29.804227848101267, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8336, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.42490775862069 ], [ 29.805036329113925, -1.42517724137931 ], [ 29.80530582278481, -1.42517724137931 ], [ 29.80530582278481, -1.42490775862069 ], [ 29.805036329113925, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8337, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.42490775862069 ], [ 29.80530582278481, -1.42517724137931 ], [ 29.805575316455698, -1.42517724137931 ], [ 29.805575316455698, -1.42490775862069 ], [ 29.80530582278481, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8338, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.42490775862069 ], [ 29.805575316455698, -1.42517724137931 ], [ 29.805844810126583, -1.42517724137931 ], [ 29.805844810126583, -1.42490775862069 ], [ 29.805575316455698, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8339, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.42490775862069 ], [ 29.805844810126583, -1.42517724137931 ], [ 29.806114303797468, -1.42517724137931 ], [ 29.806114303797468, -1.42490775862069 ], [ 29.805844810126583, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8340, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.42490775862069 ], [ 29.806114303797468, -1.42517724137931 ], [ 29.806383797468357, -1.42517724137931 ], [ 29.806383797468357, -1.42490775862069 ], [ 29.806114303797468, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8341, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.42490775862069 ], [ 29.806383797468357, -1.42517724137931 ], [ 29.806653291139241, -1.42517724137931 ], [ 29.806653291139241, -1.42490775862069 ], [ 29.806383797468357, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8342, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.42490775862069 ], [ 29.806653291139241, -1.42517724137931 ], [ 29.806922784810126, -1.42517724137931 ], [ 29.806922784810126, -1.42490775862069 ], [ 29.806653291139241, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8343, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.42490775862069 ], [ 29.806922784810126, -1.42517724137931 ], [ 29.807192278481015, -1.42517724137931 ], [ 29.807192278481015, -1.42490775862069 ], [ 29.806922784810126, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8344, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.42490775862069 ], [ 29.807192278481015, -1.42517724137931 ], [ 29.808270253164558, -1.42517724137931 ], [ 29.808270253164558, -1.42490775862069 ], [ 29.807192278481015, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8345, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.42490775862069 ], [ 29.808270253164558, -1.42517724137931 ], [ 29.808539746835443, -1.42517724137931 ], [ 29.808539746835443, -1.42490775862069 ], [ 29.808270253164558, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8346, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.42490775862069 ], [ 29.808539746835443, -1.42517724137931 ], [ 29.809078734177216, -1.42517724137931 ], [ 29.809078734177216, -1.42490775862069 ], [ 29.808539746835443, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8347, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.42490775862069 ], [ 29.809078734177216, -1.42517724137931 ], [ 29.809348227848101, -1.42517724137931 ], [ 29.809348227848101, -1.42490775862069 ], [ 29.809078734177216, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8348, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.42490775862069 ], [ 29.809348227848101, -1.42517724137931 ], [ 29.809617721518986, -1.42517724137931 ], [ 29.809617721518986, -1.42490775862069 ], [ 29.809348227848101, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8349, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.42490775862069 ], [ 29.809617721518986, -1.42517724137931 ], [ 29.809887215189875, -1.42517724137931 ], [ 29.809887215189875, -1.42490775862069 ], [ 29.809617721518986, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8350, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.42490775862069 ], [ 29.809887215189875, -1.42517724137931 ], [ 29.81015670886076, -1.42517724137931 ], [ 29.81015670886076, -1.42490775862069 ], [ 29.809887215189875, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8351, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.42490775862069 ], [ 29.81015670886076, -1.42517724137931 ], [ 29.810426202531644, -1.42517724137931 ], [ 29.810426202531644, -1.42490775862069 ], [ 29.81015670886076, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8352, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.42490775862069 ], [ 29.810426202531644, -1.42517724137931 ], [ 29.810965189873418, -1.42517724137931 ], [ 29.810965189873418, -1.42490775862069 ], [ 29.810426202531644, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8353, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.42490775862069 ], [ 29.810965189873418, -1.42517724137931 ], [ 29.811234683544303, -1.42517724137931 ], [ 29.811234683544303, -1.42490775862069 ], [ 29.810965189873418, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8354, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.42490775862069 ], [ 29.811234683544303, -1.42517724137931 ], [ 29.811773670886076, -1.42517724137931 ], [ 29.811773670886076, -1.42490775862069 ], [ 29.811234683544303, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8355, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.42490775862069 ], [ 29.814468607594936, -1.42517724137931 ], [ 29.814738101265824, -1.42517724137931 ], [ 29.814738101265824, -1.42490775862069 ], [ 29.814468607594936, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8356, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814738101265824, -1.42490775862069 ], [ 29.814738101265824, -1.42517724137931 ], [ 29.815007594936709, -1.42517724137931 ], [ 29.815007594936709, -1.42490775862069 ], [ 29.814738101265824, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8357, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.42517724137931 ], [ 29.760030886075949, -1.425985689655173 ], [ 29.760300379746834, -1.425985689655173 ], [ 29.760300379746834, -1.42517724137931 ], [ 29.760030886075949, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8358, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.42517724137931 ], [ 29.760300379746834, -1.425985689655173 ], [ 29.760569873417722, -1.425985689655173 ], [ 29.760569873417722, -1.42517724137931 ], [ 29.760300379746834, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8359, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.42517724137931 ], [ 29.760569873417722, -1.425716206896552 ], [ 29.760839367088607, -1.425716206896552 ], [ 29.760839367088607, -1.42517724137931 ], [ 29.760569873417722, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8360, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.42517724137931 ], [ 29.760839367088607, -1.425446724137931 ], [ 29.761108860759492, -1.425446724137931 ], [ 29.761108860759492, -1.42517724137931 ], [ 29.760839367088607, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8361, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.42517724137931 ], [ 29.761108860759492, -1.425446724137931 ], [ 29.761378354430381, -1.425446724137931 ], [ 29.761378354430381, -1.42517724137931 ], [ 29.761108860759492, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8362, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.42517724137931 ], [ 29.761378354430381, -1.425446724137931 ], [ 29.761647848101266, -1.425446724137931 ], [ 29.761647848101266, -1.42517724137931 ], [ 29.761378354430381, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8363, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.42517724137931 ], [ 29.761647848101266, -1.425446724137931 ], [ 29.761917341772151, -1.425446724137931 ], [ 29.761917341772151, -1.42517724137931 ], [ 29.761647848101266, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8364, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.42517724137931 ], [ 29.761917341772151, -1.425446724137931 ], [ 29.762186835443039, -1.425446724137931 ], [ 29.762186835443039, -1.42517724137931 ], [ 29.761917341772151, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8365, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.42517724137931 ], [ 29.762186835443039, -1.425446724137931 ], [ 29.762456329113924, -1.425446724137931 ], [ 29.762456329113924, -1.42517724137931 ], [ 29.762186835443039, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8366, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.42517724137931 ], [ 29.762456329113924, -1.425446724137931 ], [ 29.762725822784809, -1.425446724137931 ], [ 29.762725822784809, -1.42517724137931 ], [ 29.762456329113924, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8367, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.42517724137931 ], [ 29.762725822784809, -1.425446724137931 ], [ 29.762995316455697, -1.425446724137931 ], [ 29.762995316455697, -1.42517724137931 ], [ 29.762725822784809, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8368, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.42517724137931 ], [ 29.762995316455697, -1.425446724137931 ], [ 29.763264810126582, -1.425446724137931 ], [ 29.763264810126582, -1.42517724137931 ], [ 29.762995316455697, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8369, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.42517724137931 ], [ 29.763264810126582, -1.425446724137931 ], [ 29.763534303797467, -1.425446724137931 ], [ 29.763534303797467, -1.42517724137931 ], [ 29.763264810126582, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8370, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.42517724137931 ], [ 29.763534303797467, -1.425446724137931 ], [ 29.763803797468356, -1.425446724137931 ], [ 29.763803797468356, -1.42517724137931 ], [ 29.763534303797467, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8371, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.42517724137931 ], [ 29.763803797468356, -1.425446724137931 ], [ 29.76407329113924, -1.425446724137931 ], [ 29.76407329113924, -1.42517724137931 ], [ 29.763803797468356, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8372, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.42517724137931 ], [ 29.76407329113924, -1.425446724137931 ], [ 29.764342784810125, -1.425446724137931 ], [ 29.764342784810125, -1.42517724137931 ], [ 29.76407329113924, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8373, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.42517724137931 ], [ 29.764342784810125, -1.425446724137931 ], [ 29.764612278481014, -1.425446724137931 ], [ 29.764612278481014, -1.42517724137931 ], [ 29.764342784810125, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8374, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.42517724137931 ], [ 29.764612278481014, -1.425446724137931 ], [ 29.764881772151899, -1.425446724137931 ], [ 29.764881772151899, -1.42517724137931 ], [ 29.764612278481014, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8375, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.42517724137931 ], [ 29.764881772151899, -1.425446724137931 ], [ 29.765151265822784, -1.425446724137931 ], [ 29.765151265822784, -1.42517724137931 ], [ 29.764881772151899, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8376, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.42517724137931 ], [ 29.765151265822784, -1.425446724137931 ], [ 29.765420759493672, -1.425446724137931 ], [ 29.765420759493672, -1.42517724137931 ], [ 29.765151265822784, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8377, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.42517724137931 ], [ 29.765420759493672, -1.425446724137931 ], [ 29.765959746835442, -1.425446724137931 ], [ 29.765959746835442, -1.42517724137931 ], [ 29.765420759493672, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8378, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.42517724137931 ], [ 29.765959746835442, -1.425446724137931 ], [ 29.766229240506327, -1.425446724137931 ], [ 29.766229240506327, -1.42517724137931 ], [ 29.765959746835442, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8379, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.42517724137931 ], [ 29.766229240506327, -1.425446724137931 ], [ 29.766498734177215, -1.425446724137931 ], [ 29.766498734177215, -1.42517724137931 ], [ 29.766229240506327, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8380, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.42517724137931 ], [ 29.766498734177215, -1.425446724137931 ], [ 29.7667682278481, -1.425446724137931 ], [ 29.7667682278481, -1.42517724137931 ], [ 29.766498734177215, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8381, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.42517724137931 ], [ 29.7667682278481, -1.425446724137931 ], [ 29.767037721518985, -1.425446724137931 ], [ 29.767037721518985, -1.42517724137931 ], [ 29.7667682278481, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8382, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.42517724137931 ], [ 29.767037721518985, -1.425446724137931 ], [ 29.767307215189874, -1.425446724137931 ], [ 29.767307215189874, -1.42517724137931 ], [ 29.767037721518985, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8383, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.42517724137931 ], [ 29.767307215189874, -1.425446724137931 ], [ 29.767576708860759, -1.425446724137931 ], [ 29.767576708860759, -1.42517724137931 ], [ 29.767307215189874, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8384, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.42517724137931 ], [ 29.767576708860759, -1.425446724137931 ], [ 29.767846202531643, -1.425446724137931 ], [ 29.767846202531643, -1.42517724137931 ], [ 29.767576708860759, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8385, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.42517724137931 ], [ 29.767846202531643, -1.425446724137931 ], [ 29.768115696202532, -1.425446724137931 ], [ 29.768115696202532, -1.42517724137931 ], [ 29.767846202531643, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8386, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.42517724137931 ], [ 29.768115696202532, -1.425446724137931 ], [ 29.768385189873417, -1.425446724137931 ], [ 29.768385189873417, -1.42517724137931 ], [ 29.768115696202532, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8387, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.42517724137931 ], [ 29.768385189873417, -1.425446724137931 ], [ 29.768654683544302, -1.425446724137931 ], [ 29.768654683544302, -1.42517724137931 ], [ 29.768385189873417, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8388, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.42517724137931 ], [ 29.768654683544302, -1.425446724137931 ], [ 29.76892417721519, -1.425446724137931 ], [ 29.76892417721519, -1.42517724137931 ], [ 29.768654683544302, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8389, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.42517724137931 ], [ 29.76892417721519, -1.425446724137931 ], [ 29.769193670886075, -1.425446724137931 ], [ 29.769193670886075, -1.42517724137931 ], [ 29.76892417721519, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8390, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.42517724137931 ], [ 29.769193670886075, -1.425446724137931 ], [ 29.76946316455696, -1.425446724137931 ], [ 29.76946316455696, -1.42517724137931 ], [ 29.769193670886075, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8391, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.42517724137931 ], [ 29.76946316455696, -1.425446724137931 ], [ 29.769732658227849, -1.425446724137931 ], [ 29.769732658227849, -1.42517724137931 ], [ 29.76946316455696, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8392, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.42517724137931 ], [ 29.769732658227849, -1.425446724137931 ], [ 29.770002151898733, -1.425446724137931 ], [ 29.770002151898733, -1.42517724137931 ], [ 29.769732658227849, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8393, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.42517724137931 ], [ 29.770002151898733, -1.425446724137931 ], [ 29.770271645569618, -1.425446724137931 ], [ 29.770271645569618, -1.42517724137931 ], [ 29.770002151898733, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8394, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.42517724137931 ], [ 29.770541139240507, -1.425716206896552 ], [ 29.770810632911392, -1.425716206896552 ], [ 29.770810632911392, -1.42517724137931 ], [ 29.770541139240507, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8395, "El": 169 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.42517724137931 ], [ 29.770810632911392, -1.425446724137931 ], [ 29.771080126582277, -1.425446724137931 ], [ 29.771080126582277, -1.42517724137931 ], [ 29.770810632911392, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8396, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.42517724137931 ], [ 29.771349620253165, -1.425985689655173 ], [ 29.77161911392405, -1.425985689655173 ], [ 29.77161911392405, -1.42517724137931 ], [ 29.771349620253165, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8397, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.42517724137931 ], [ 29.77161911392405, -1.425716206896552 ], [ 29.771888607594935, -1.425716206896552 ], [ 29.771888607594935, -1.42517724137931 ], [ 29.77161911392405, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8398, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.42517724137931 ], [ 29.772158101265823, -1.425985689655173 ], [ 29.772427594936708, -1.425985689655173 ], [ 29.772427594936708, -1.42517724137931 ], [ 29.772158101265823, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8399, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.42517724137931 ], [ 29.772966582278482, -1.425716206896552 ], [ 29.773236075949367, -1.425716206896552 ], [ 29.773236075949367, -1.42517724137931 ], [ 29.772966582278482, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8400, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.42517724137931 ], [ 29.773505569620252, -1.425985689655173 ], [ 29.77377506329114, -1.425985689655173 ], [ 29.77377506329114, -1.42517724137931 ], [ 29.773505569620252, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8401, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.42517724137931 ], [ 29.77377506329114, -1.425716206896552 ], [ 29.774044556962025, -1.425716206896552 ], [ 29.774044556962025, -1.42517724137931 ], [ 29.77377506329114, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8402, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.42517724137931 ], [ 29.77431405063291, -1.425985689655173 ], [ 29.774853037974683, -1.425985689655173 ], [ 29.774853037974683, -1.42517724137931 ], [ 29.77431405063291, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8403, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.42517724137931 ], [ 29.774853037974683, -1.425716206896552 ], [ 29.775122531645568, -1.425716206896552 ], [ 29.775122531645568, -1.42517724137931 ], [ 29.774853037974683, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8404, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.42517724137931 ], [ 29.775392025316457, -1.425716206896552 ], [ 29.775661518987341, -1.425716206896552 ], [ 29.775661518987341, -1.42517724137931 ], [ 29.775392025316457, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8405, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.42517724137931 ], [ 29.775661518987341, -1.425446724137931 ], [ 29.775931012658226, -1.425446724137931 ], [ 29.775931012658226, -1.42517724137931 ], [ 29.775661518987341, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8406, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.42517724137931 ], [ 29.776200506329115, -1.425716206896552 ], [ 29.77647, -1.425716206896552 ], [ 29.77647, -1.42517724137931 ], [ 29.776200506329115, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8407, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.42517724137931 ], [ 29.77647, -1.425446724137931 ], [ 29.776739493670885, -1.425446724137931 ], [ 29.776739493670885, -1.42517724137931 ], [ 29.77647, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8408, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.42517724137931 ], [ 29.777008987341773, -1.425716206896552 ], [ 29.777278481012658, -1.425716206896552 ], [ 29.777278481012658, -1.42517724137931 ], [ 29.777008987341773, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8409, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.42517724137931 ], [ 29.777278481012658, -1.425446724137931 ], [ 29.777547974683543, -1.425446724137931 ], [ 29.777547974683543, -1.42517724137931 ], [ 29.777278481012658, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8410, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.42517724137931 ], [ 29.777547974683543, -1.425446724137931 ], [ 29.777817468354431, -1.425446724137931 ], [ 29.777817468354431, -1.42517724137931 ], [ 29.777547974683543, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8411, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.42517724137931 ], [ 29.777817468354431, -1.425716206896552 ], [ 29.778086962025316, -1.425716206896552 ], [ 29.778086962025316, -1.42517724137931 ], [ 29.777817468354431, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8412, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.42517724137931 ], [ 29.778086962025316, -1.425446724137931 ], [ 29.778356455696201, -1.425446724137931 ], [ 29.778356455696201, -1.42517724137931 ], [ 29.778086962025316, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8413, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.42517724137931 ], [ 29.778356455696201, -1.425446724137931 ], [ 29.77862594936709, -1.425446724137931 ], [ 29.77862594936709, -1.42517724137931 ], [ 29.778356455696201, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8414, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.42517724137931 ], [ 29.778895443037975, -1.425446724137931 ], [ 29.77916493670886, -1.425446724137931 ], [ 29.77916493670886, -1.42517724137931 ], [ 29.778895443037975, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8415, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.42517724137931 ], [ 29.77916493670886, -1.425446724137931 ], [ 29.779434430379748, -1.425446724137931 ], [ 29.779434430379748, -1.42517724137931 ], [ 29.77916493670886, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8416, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.42517724137931 ], [ 29.779434430379748, -1.425446724137931 ], [ 29.779703924050633, -1.425446724137931 ], [ 29.779703924050633, -1.42517724137931 ], [ 29.779434430379748, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8417, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.42517724137931 ], [ 29.779703924050633, -1.425716206896552 ], [ 29.779973417721518, -1.425716206896552 ], [ 29.779973417721518, -1.42517724137931 ], [ 29.779703924050633, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8418, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.42517724137931 ], [ 29.779973417721518, -1.425446724137931 ], [ 29.780242911392406, -1.425446724137931 ], [ 29.780242911392406, -1.42517724137931 ], [ 29.779973417721518, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8419, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.42517724137931 ], [ 29.780242911392406, -1.425446724137931 ], [ 29.780512405063291, -1.425446724137931 ], [ 29.780512405063291, -1.42517724137931 ], [ 29.780242911392406, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8420, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.42517724137931 ], [ 29.780512405063291, -1.425446724137931 ], [ 29.780781898734176, -1.425446724137931 ], [ 29.780781898734176, -1.42517724137931 ], [ 29.780512405063291, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8421, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.42517724137931 ], [ 29.780781898734176, -1.425446724137931 ], [ 29.781051392405065, -1.425446724137931 ], [ 29.781051392405065, -1.42517724137931 ], [ 29.780781898734176, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8422, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.42517724137931 ], [ 29.781051392405065, -1.425446724137931 ], [ 29.78132088607595, -1.425446724137931 ], [ 29.78132088607595, -1.42517724137931 ], [ 29.781051392405065, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8423, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.42517724137931 ], [ 29.78132088607595, -1.425446724137931 ], [ 29.781590379746834, -1.425446724137931 ], [ 29.781590379746834, -1.42517724137931 ], [ 29.78132088607595, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8424, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.42517724137931 ], [ 29.781590379746834, -1.425446724137931 ], [ 29.781859873417723, -1.425446724137931 ], [ 29.781859873417723, -1.42517724137931 ], [ 29.781590379746834, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8425, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.42517724137931 ], [ 29.781859873417723, -1.425446724137931 ], [ 29.782129367088608, -1.425446724137931 ], [ 29.782129367088608, -1.42517724137931 ], [ 29.781859873417723, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8426, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.42517724137931 ], [ 29.782129367088608, -1.425446724137931 ], [ 29.782398860759493, -1.425446724137931 ], [ 29.782398860759493, -1.42517724137931 ], [ 29.782129367088608, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8427, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.42517724137931 ], [ 29.782398860759493, -1.425446724137931 ], [ 29.782668354430381, -1.425446724137931 ], [ 29.782668354430381, -1.42517724137931 ], [ 29.782398860759493, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8428, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.42517724137931 ], [ 29.782668354430381, -1.425446724137931 ], [ 29.782937848101266, -1.425446724137931 ], [ 29.782937848101266, -1.42517724137931 ], [ 29.782668354430381, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8429, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.42517724137931 ], [ 29.782937848101266, -1.425446724137931 ], [ 29.783207341772151, -1.425446724137931 ], [ 29.783207341772151, -1.42517724137931 ], [ 29.782937848101266, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8430, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.42517724137931 ], [ 29.783207341772151, -1.425446724137931 ], [ 29.783476835443039, -1.425446724137931 ], [ 29.783476835443039, -1.42517724137931 ], [ 29.783207341772151, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8431, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.42517724137931 ], [ 29.783476835443039, -1.425446724137931 ], [ 29.784015822784809, -1.425446724137931 ], [ 29.784015822784809, -1.42517724137931 ], [ 29.783476835443039, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8432, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.42517724137931 ], [ 29.784015822784809, -1.425446724137931 ], [ 29.784285316455698, -1.425446724137931 ], [ 29.784285316455698, -1.42517724137931 ], [ 29.784015822784809, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8433, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.42517724137931 ], [ 29.784285316455698, -1.425446724137931 ], [ 29.784554810126583, -1.425446724137931 ], [ 29.784554810126583, -1.42517724137931 ], [ 29.784285316455698, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8434, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.42517724137931 ], [ 29.784554810126583, -1.425446724137931 ], [ 29.784824303797468, -1.425446724137931 ], [ 29.784824303797468, -1.42517724137931 ], [ 29.784554810126583, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8435, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.42517724137931 ], [ 29.784824303797468, -1.425446724137931 ], [ 29.785093797468356, -1.425446724137931 ], [ 29.785093797468356, -1.42517724137931 ], [ 29.784824303797468, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8436, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.42517724137931 ], [ 29.788327721518986, -1.425446724137931 ], [ 29.788597215189874, -1.425446724137931 ], [ 29.788597215189874, -1.42517724137931 ], [ 29.788327721518986, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8437, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.42517724137931 ], [ 29.788597215189874, -1.425446724137931 ], [ 29.788866708860759, -1.425446724137931 ], [ 29.788866708860759, -1.42517724137931 ], [ 29.788597215189874, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8438, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.42517724137931 ], [ 29.788866708860759, -1.425446724137931 ], [ 29.789136202531644, -1.425446724137931 ], [ 29.789136202531644, -1.42517724137931 ], [ 29.788866708860759, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8439, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.42517724137931 ], [ 29.789675189873417, -1.425716206896552 ], [ 29.789944683544302, -1.425716206896552 ], [ 29.789944683544302, -1.42517724137931 ], [ 29.789675189873417, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8440, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.42517724137931 ], [ 29.789944683544302, -1.425446724137931 ], [ 29.790214177215191, -1.425446724137931 ], [ 29.790214177215191, -1.42517724137931 ], [ 29.789944683544302, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8441, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.42517724137931 ], [ 29.790214177215191, -1.425446724137931 ], [ 29.790483670886076, -1.425446724137931 ], [ 29.790483670886076, -1.42517724137931 ], [ 29.790214177215191, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8442, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.42517724137931 ], [ 29.790483670886076, -1.425446724137931 ], [ 29.790753164556961, -1.425446724137931 ], [ 29.790753164556961, -1.42517724137931 ], [ 29.790483670886076, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8443, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.42517724137931 ], [ 29.790753164556961, -1.425446724137931 ], [ 29.791022658227849, -1.425446724137931 ], [ 29.791022658227849, -1.42517724137931 ], [ 29.790753164556961, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8444, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.42517724137931 ], [ 29.791022658227849, -1.425446724137931 ], [ 29.791292151898734, -1.425446724137931 ], [ 29.791292151898734, -1.42517724137931 ], [ 29.791022658227849, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8445, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.42517724137931 ], [ 29.791292151898734, -1.425446724137931 ], [ 29.791561645569619, -1.425446724137931 ], [ 29.791561645569619, -1.42517724137931 ], [ 29.791292151898734, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8446, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.42517724137931 ], [ 29.791561645569619, -1.425446724137931 ], [ 29.791831139240507, -1.425446724137931 ], [ 29.791831139240507, -1.42517724137931 ], [ 29.791561645569619, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8447, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.42517724137931 ], [ 29.791831139240507, -1.425446724137931 ], [ 29.792100632911392, -1.425446724137931 ], [ 29.792100632911392, -1.42517724137931 ], [ 29.791831139240507, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8448, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.42517724137931 ], [ 29.792100632911392, -1.425446724137931 ], [ 29.792370126582277, -1.425446724137931 ], [ 29.792370126582277, -1.42517724137931 ], [ 29.792100632911392, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8449, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.42517724137931 ], [ 29.792370126582277, -1.425446724137931 ], [ 29.792909113924051, -1.425446724137931 ], [ 29.792909113924051, -1.42517724137931 ], [ 29.792370126582277, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8450, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.42517724137931 ], [ 29.792909113924051, -1.425446724137931 ], [ 29.793178607594935, -1.425446724137931 ], [ 29.793178607594935, -1.42517724137931 ], [ 29.792909113924051, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8451, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.42517724137931 ], [ 29.793178607594935, -1.425446724137931 ], [ 29.793448101265824, -1.425446724137931 ], [ 29.793448101265824, -1.42517724137931 ], [ 29.793178607594935, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8452, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.42517724137931 ], [ 29.801532911392407, -1.425446724137931 ], [ 29.802341392405065, -1.425446724137931 ], [ 29.802341392405065, -1.42517724137931 ], [ 29.801532911392407, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8453, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.42517724137931 ], [ 29.802341392405065, -1.425446724137931 ], [ 29.80261088607595, -1.425446724137931 ], [ 29.80261088607595, -1.42517724137931 ], [ 29.802341392405065, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8454, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.42517724137931 ], [ 29.80261088607595, -1.425446724137931 ], [ 29.802880379746835, -1.425446724137931 ], [ 29.802880379746835, -1.42517724137931 ], [ 29.80261088607595, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8455, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.42517724137931 ], [ 29.802880379746835, -1.425446724137931 ], [ 29.803149873417723, -1.425446724137931 ], [ 29.803149873417723, -1.42517724137931 ], [ 29.802880379746835, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8456, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.42517724137931 ], [ 29.80530582278481, -1.425446724137931 ], [ 29.805575316455698, -1.425446724137931 ], [ 29.805575316455698, -1.42517724137931 ], [ 29.80530582278481, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8457, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.42517724137931 ], [ 29.805575316455698, -1.425446724137931 ], [ 29.805844810126583, -1.425446724137931 ], [ 29.805844810126583, -1.42517724137931 ], [ 29.805575316455698, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8458, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.42517724137931 ], [ 29.805844810126583, -1.425446724137931 ], [ 29.806114303797468, -1.425446724137931 ], [ 29.806114303797468, -1.42517724137931 ], [ 29.805844810126583, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8459, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.42517724137931 ], [ 29.806114303797468, -1.425446724137931 ], [ 29.806383797468357, -1.425446724137931 ], [ 29.806383797468357, -1.42517724137931 ], [ 29.806114303797468, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8460, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.42517724137931 ], [ 29.806383797468357, -1.425446724137931 ], [ 29.806653291139241, -1.425446724137931 ], [ 29.806653291139241, -1.42517724137931 ], [ 29.806383797468357, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8461, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.42517724137931 ], [ 29.806653291139241, -1.425446724137931 ], [ 29.806922784810126, -1.425446724137931 ], [ 29.806922784810126, -1.42517724137931 ], [ 29.806653291139241, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8462, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.42517724137931 ], [ 29.806922784810126, -1.425446724137931 ], [ 29.807192278481015, -1.425446724137931 ], [ 29.807192278481015, -1.42517724137931 ], [ 29.806922784810126, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8463, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.42517724137931 ], [ 29.807192278481015, -1.425446724137931 ], [ 29.808270253164558, -1.425446724137931 ], [ 29.808270253164558, -1.42517724137931 ], [ 29.807192278481015, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8464, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.42517724137931 ], [ 29.808270253164558, -1.425446724137931 ], [ 29.808539746835443, -1.425446724137931 ], [ 29.808539746835443, -1.42517724137931 ], [ 29.808270253164558, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8465, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.42517724137931 ], [ 29.808539746835443, -1.425716206896552 ], [ 29.808809240506328, -1.425716206896552 ], [ 29.808809240506328, -1.425446724137931 ], [ 29.809078734177216, -1.425446724137931 ], [ 29.809078734177216, -1.42517724137931 ], [ 29.808539746835443, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8466, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.42517724137931 ], [ 29.809078734177216, -1.425446724137931 ], [ 29.809348227848101, -1.425446724137931 ], [ 29.809348227848101, -1.42517724137931 ], [ 29.809078734177216, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8467, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.42517724137931 ], [ 29.809617721518986, -1.425446724137931 ], [ 29.809887215189875, -1.425446724137931 ], [ 29.809887215189875, -1.42517724137931 ], [ 29.809617721518986, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8468, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.42517724137931 ], [ 29.809887215189875, -1.425446724137931 ], [ 29.81015670886076, -1.425446724137931 ], [ 29.81015670886076, -1.42517724137931 ], [ 29.809887215189875, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8469, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.42517724137931 ], [ 29.81015670886076, -1.425446724137931 ], [ 29.810426202531644, -1.425446724137931 ], [ 29.810426202531644, -1.42517724137931 ], [ 29.81015670886076, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8470, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.42517724137931 ], [ 29.810426202531644, -1.425446724137931 ], [ 29.810965189873418, -1.425446724137931 ], [ 29.810965189873418, -1.42517724137931 ], [ 29.810426202531644, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8471, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.42517724137931 ], [ 29.810965189873418, -1.425446724137931 ], [ 29.811234683544303, -1.425446724137931 ], [ 29.811234683544303, -1.42517724137931 ], [ 29.810965189873418, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8472, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.42517724137931 ], [ 29.811234683544303, -1.425446724137931 ], [ 29.811504177215191, -1.425446724137931 ], [ 29.811504177215191, -1.42517724137931 ], [ 29.811234683544303, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8473, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.42517724137931 ], [ 29.811504177215191, -1.425446724137931 ], [ 29.811773670886076, -1.425446724137931 ], [ 29.811773670886076, -1.42517724137931 ], [ 29.811504177215191, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8474, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.42517724137931 ], [ 29.811773670886076, -1.425446724137931 ], [ 29.813121139240508, -1.425446724137931 ], [ 29.813121139240508, -1.42517724137931 ], [ 29.811773670886076, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8475, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.42517724137931 ], [ 29.814199113924051, -1.425446724137931 ], [ 29.814468607594936, -1.425446724137931 ], [ 29.814468607594936, -1.42517724137931 ], [ 29.814199113924051, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8476, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.42517724137931 ], [ 29.814468607594936, -1.425446724137931 ], [ 29.814738101265824, -1.425446724137931 ], [ 29.814738101265824, -1.42517724137931 ], [ 29.814468607594936, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8477, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.425446724137931 ], [ 29.756257974683542, -1.425716206896552 ], [ 29.756796962025316, -1.425716206896552 ], [ 29.756796962025316, -1.425446724137931 ], [ 29.756257974683542, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8478, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.425446724137931 ], [ 29.756796962025316, -1.425716206896552 ], [ 29.757066455696201, -1.425716206896552 ], [ 29.757066455696201, -1.425446724137931 ], [ 29.756796962025316, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8479, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.425446724137931 ], [ 29.757066455696201, -1.425716206896552 ], [ 29.757335949367089, -1.425716206896552 ], [ 29.757335949367089, -1.425446724137931 ], [ 29.757066455696201, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8480, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.425446724137931 ], [ 29.757874936708859, -1.425985689655173 ], [ 29.758144430379748, -1.425985689655173 ], [ 29.758144430379748, -1.425446724137931 ], [ 29.757874936708859, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8481, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.425446724137931 ], [ 29.759222405063291, -1.425985689655173 ], [ 29.759491898734176, -1.425985689655173 ], [ 29.759491898734176, -1.425446724137931 ], [ 29.759222405063291, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8482, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.425446724137931 ], [ 29.760839367088607, -1.425985689655173 ], [ 29.761108860759492, -1.425985689655173 ], [ 29.761108860759492, -1.425446724137931 ], [ 29.760839367088607, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8483, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.425446724137931 ], [ 29.761108860759492, -1.425716206896552 ], [ 29.761378354430381, -1.425716206896552 ], [ 29.761378354430381, -1.425446724137931 ], [ 29.761108860759492, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8484, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.425446724137931 ], [ 29.761378354430381, -1.425716206896552 ], [ 29.761647848101266, -1.425716206896552 ], [ 29.761647848101266, -1.425446724137931 ], [ 29.761378354430381, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8485, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.425446724137931 ], [ 29.761647848101266, -1.425716206896552 ], [ 29.761917341772151, -1.425716206896552 ], [ 29.761917341772151, -1.425446724137931 ], [ 29.761647848101266, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8486, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.425446724137931 ], [ 29.761917341772151, -1.425716206896552 ], [ 29.762186835443039, -1.425716206896552 ], [ 29.762186835443039, -1.425446724137931 ], [ 29.761917341772151, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8487, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.425446724137931 ], [ 29.762186835443039, -1.425716206896552 ], [ 29.762456329113924, -1.425716206896552 ], [ 29.762456329113924, -1.425446724137931 ], [ 29.762186835443039, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8488, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.425446724137931 ], [ 29.762456329113924, -1.425716206896552 ], [ 29.762725822784809, -1.425716206896552 ], [ 29.762725822784809, -1.425446724137931 ], [ 29.762456329113924, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8489, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.425446724137931 ], [ 29.762725822784809, -1.425716206896552 ], [ 29.762995316455697, -1.425716206896552 ], [ 29.762995316455697, -1.425446724137931 ], [ 29.762725822784809, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8490, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.425446724137931 ], [ 29.762995316455697, -1.425716206896552 ], [ 29.763264810126582, -1.425716206896552 ], [ 29.763264810126582, -1.425446724137931 ], [ 29.762995316455697, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8491, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.425446724137931 ], [ 29.763264810126582, -1.425716206896552 ], [ 29.763534303797467, -1.425716206896552 ], [ 29.763534303797467, -1.425446724137931 ], [ 29.763264810126582, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8492, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.425446724137931 ], [ 29.763534303797467, -1.425716206896552 ], [ 29.763803797468356, -1.425716206896552 ], [ 29.763803797468356, -1.425446724137931 ], [ 29.763534303797467, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8493, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.425446724137931 ], [ 29.763803797468356, -1.425716206896552 ], [ 29.76407329113924, -1.425716206896552 ], [ 29.76407329113924, -1.425446724137931 ], [ 29.763803797468356, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8494, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.425446724137931 ], [ 29.76407329113924, -1.425716206896552 ], [ 29.764342784810125, -1.425716206896552 ], [ 29.764342784810125, -1.425446724137931 ], [ 29.76407329113924, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8495, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.425446724137931 ], [ 29.764342784810125, -1.425716206896552 ], [ 29.764612278481014, -1.425716206896552 ], [ 29.764612278481014, -1.425446724137931 ], [ 29.764342784810125, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8496, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.425446724137931 ], [ 29.764612278481014, -1.425716206896552 ], [ 29.764881772151899, -1.425716206896552 ], [ 29.764881772151899, -1.425446724137931 ], [ 29.764612278481014, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8497, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.425446724137931 ], [ 29.764881772151899, -1.425716206896552 ], [ 29.765151265822784, -1.425716206896552 ], [ 29.765151265822784, -1.425446724137931 ], [ 29.764881772151899, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8498, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.425446724137931 ], [ 29.765151265822784, -1.425716206896552 ], [ 29.765420759493672, -1.425716206896552 ], [ 29.765420759493672, -1.425446724137931 ], [ 29.765151265822784, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8499, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.425446724137931 ], [ 29.765420759493672, -1.425716206896552 ], [ 29.765959746835442, -1.425716206896552 ], [ 29.765959746835442, -1.425446724137931 ], [ 29.765420759493672, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8500, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.425446724137931 ], [ 29.765959746835442, -1.425716206896552 ], [ 29.766229240506327, -1.425716206896552 ], [ 29.766229240506327, -1.425446724137931 ], [ 29.765959746835442, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8501, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.425446724137931 ], [ 29.766229240506327, -1.425716206896552 ], [ 29.766498734177215, -1.425716206896552 ], [ 29.766498734177215, -1.425446724137931 ], [ 29.766229240506327, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8502, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.425446724137931 ], [ 29.766498734177215, -1.425716206896552 ], [ 29.7667682278481, -1.425716206896552 ], [ 29.7667682278481, -1.425446724137931 ], [ 29.766498734177215, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8503, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.425446724137931 ], [ 29.7667682278481, -1.425716206896552 ], [ 29.767037721518985, -1.425716206896552 ], [ 29.767037721518985, -1.425446724137931 ], [ 29.7667682278481, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8504, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.425446724137931 ], [ 29.767037721518985, -1.425716206896552 ], [ 29.767307215189874, -1.425716206896552 ], [ 29.767307215189874, -1.425446724137931 ], [ 29.767037721518985, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8505, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.425446724137931 ], [ 29.767307215189874, -1.425716206896552 ], [ 29.767576708860759, -1.425716206896552 ], [ 29.767576708860759, -1.425446724137931 ], [ 29.767307215189874, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8506, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.425446724137931 ], [ 29.767576708860759, -1.425716206896552 ], [ 29.767846202531643, -1.425716206896552 ], [ 29.767846202531643, -1.425446724137931 ], [ 29.767576708860759, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8507, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.425446724137931 ], [ 29.767846202531643, -1.425716206896552 ], [ 29.768115696202532, -1.425716206896552 ], [ 29.768115696202532, -1.425446724137931 ], [ 29.767846202531643, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8508, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.425446724137931 ], [ 29.768115696202532, -1.425716206896552 ], [ 29.768385189873417, -1.425716206896552 ], [ 29.768385189873417, -1.425446724137931 ], [ 29.768115696202532, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8509, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.425446724137931 ], [ 29.768385189873417, -1.425716206896552 ], [ 29.768654683544302, -1.425716206896552 ], [ 29.768654683544302, -1.425446724137931 ], [ 29.768385189873417, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8510, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.425446724137931 ], [ 29.768654683544302, -1.425716206896552 ], [ 29.76892417721519, -1.425716206896552 ], [ 29.76892417721519, -1.425446724137931 ], [ 29.768654683544302, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8511, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.425446724137931 ], [ 29.76892417721519, -1.425716206896552 ], [ 29.769193670886075, -1.425716206896552 ], [ 29.769193670886075, -1.425446724137931 ], [ 29.76892417721519, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8512, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.425446724137931 ], [ 29.769193670886075, -1.425716206896552 ], [ 29.76946316455696, -1.425716206896552 ], [ 29.76946316455696, -1.425446724137931 ], [ 29.769193670886075, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8513, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.425446724137931 ], [ 29.76946316455696, -1.425985689655173 ], [ 29.769732658227849, -1.425985689655173 ], [ 29.769732658227849, -1.425446724137931 ], [ 29.76946316455696, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8514, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.425446724137931 ], [ 29.769732658227849, -1.425716206896552 ], [ 29.770002151898733, -1.425716206896552 ], [ 29.770002151898733, -1.425446724137931 ], [ 29.769732658227849, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8515, "El": 160 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.425446724137931 ], [ 29.770002151898733, -1.425716206896552 ], [ 29.770271645569618, -1.425716206896552 ], [ 29.770271645569618, -1.425446724137931 ], [ 29.770002151898733, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8516, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.425446724137931 ], [ 29.770271645569618, -1.425716206896552 ], [ 29.770541139240507, -1.425716206896552 ], [ 29.770541139240507, -1.425446724137931 ], [ 29.770271645569618, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8517, "El": 168 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.425446724137931 ], [ 29.770810632911392, -1.425716206896552 ], [ 29.771080126582277, -1.425716206896552 ], [ 29.771080126582277, -1.425446724137931 ], [ 29.770810632911392, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8518, "El": 167 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.425446724137931 ], [ 29.771080126582277, -1.425985689655173 ], [ 29.771349620253165, -1.425985689655173 ], [ 29.771349620253165, -1.425446724137931 ], [ 29.771080126582277, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8519, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.425446724137931 ], [ 29.772697088607593, -1.426524655172414 ], [ 29.772966582278482, -1.426524655172414 ], [ 29.772966582278482, -1.425446724137931 ], [ 29.772697088607593, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8520, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.425446724137931 ], [ 29.774044556962025, -1.426524655172414 ], [ 29.77431405063291, -1.426524655172414 ], [ 29.77431405063291, -1.425446724137931 ], [ 29.774044556962025, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8521, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.425446724137931 ], [ 29.775122531645568, -1.425985689655173 ], [ 29.775392025316457, -1.425985689655173 ], [ 29.775392025316457, -1.425446724137931 ], [ 29.775122531645568, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8522, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.425446724137931 ], [ 29.775661518987341, -1.426524655172414 ], [ 29.775931012658226, -1.426524655172414 ], [ 29.775931012658226, -1.425446724137931 ], [ 29.775661518987341, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8523, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.425446724137931 ], [ 29.775931012658226, -1.425985689655173 ], [ 29.776200506329115, -1.425985689655173 ], [ 29.776200506329115, -1.425446724137931 ], [ 29.775931012658226, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8524, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.425446724137931 ], [ 29.77647, -1.425985689655173 ], [ 29.776739493670885, -1.425985689655173 ], [ 29.776739493670885, -1.425446724137931 ], [ 29.77647, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8525, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.425446724137931 ], [ 29.776739493670885, -1.425985689655173 ], [ 29.777008987341773, -1.425985689655173 ], [ 29.777008987341773, -1.425446724137931 ], [ 29.776739493670885, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8526, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.425446724137931 ], [ 29.777278481012658, -1.425985689655173 ], [ 29.777547974683543, -1.425985689655173 ], [ 29.777547974683543, -1.425446724137931 ], [ 29.777278481012658, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8527, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.425446724137931 ], [ 29.777547974683543, -1.425716206896552 ], [ 29.777817468354431, -1.425716206896552 ], [ 29.777817468354431, -1.425446724137931 ], [ 29.777547974683543, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8528, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.425446724137931 ], [ 29.778086962025316, -1.425985689655173 ], [ 29.778356455696201, -1.425985689655173 ], [ 29.778356455696201, -1.425446724137931 ], [ 29.778086962025316, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8529, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.425446724137931 ], [ 29.778356455696201, -1.425716206896552 ], [ 29.77862594936709, -1.425716206896552 ], [ 29.77862594936709, -1.425446724137931 ], [ 29.778356455696201, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8530, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.425446724137931 ], [ 29.77862594936709, -1.425716206896552 ], [ 29.778895443037975, -1.425716206896552 ], [ 29.778895443037975, -1.425446724137931 ], [ 29.77862594936709, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8531, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.425446724137931 ], [ 29.778895443037975, -1.425985689655173 ], [ 29.77916493670886, -1.425985689655173 ], [ 29.77916493670886, -1.425446724137931 ], [ 29.778895443037975, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8532, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.425446724137931 ], [ 29.77916493670886, -1.425716206896552 ], [ 29.779434430379748, -1.425716206896552 ], [ 29.779434430379748, -1.425446724137931 ], [ 29.77916493670886, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8533, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.425446724137931 ], [ 29.779434430379748, -1.425716206896552 ], [ 29.779703924050633, -1.425716206896552 ], [ 29.779703924050633, -1.425446724137931 ], [ 29.779434430379748, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8534, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.425446724137931 ], [ 29.779973417721518, -1.425716206896552 ], [ 29.780242911392406, -1.425716206896552 ], [ 29.780242911392406, -1.425446724137931 ], [ 29.779973417721518, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8535, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.425446724137931 ], [ 29.780242911392406, -1.425716206896552 ], [ 29.780512405063291, -1.425716206896552 ], [ 29.780512405063291, -1.425446724137931 ], [ 29.780242911392406, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8536, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.425446724137931 ], [ 29.780512405063291, -1.425716206896552 ], [ 29.780781898734176, -1.425716206896552 ], [ 29.780781898734176, -1.425446724137931 ], [ 29.780512405063291, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8537, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.425446724137931 ], [ 29.780781898734176, -1.425716206896552 ], [ 29.781051392405065, -1.425716206896552 ], [ 29.781051392405065, -1.425446724137931 ], [ 29.780781898734176, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8538, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.425446724137931 ], [ 29.781051392405065, -1.425716206896552 ], [ 29.78132088607595, -1.425716206896552 ], [ 29.78132088607595, -1.425446724137931 ], [ 29.781051392405065, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8539, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.425446724137931 ], [ 29.78132088607595, -1.425716206896552 ], [ 29.781590379746834, -1.425716206896552 ], [ 29.781590379746834, -1.425446724137931 ], [ 29.78132088607595, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8540, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.425446724137931 ], [ 29.781590379746834, -1.425716206896552 ], [ 29.781859873417723, -1.425716206896552 ], [ 29.781859873417723, -1.425446724137931 ], [ 29.781590379746834, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8541, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.425446724137931 ], [ 29.781859873417723, -1.425716206896552 ], [ 29.782129367088608, -1.425716206896552 ], [ 29.782129367088608, -1.425446724137931 ], [ 29.781859873417723, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8542, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.425446724137931 ], [ 29.782129367088608, -1.425716206896552 ], [ 29.782398860759493, -1.425716206896552 ], [ 29.782398860759493, -1.425446724137931 ], [ 29.782129367088608, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8543, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.425446724137931 ], [ 29.782398860759493, -1.425716206896552 ], [ 29.782668354430381, -1.425716206896552 ], [ 29.782668354430381, -1.425446724137931 ], [ 29.782398860759493, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8544, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.425446724137931 ], [ 29.782668354430381, -1.425716206896552 ], [ 29.782937848101266, -1.425716206896552 ], [ 29.782937848101266, -1.425446724137931 ], [ 29.782668354430381, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8545, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.425446724137931 ], [ 29.782937848101266, -1.425716206896552 ], [ 29.783207341772151, -1.425716206896552 ], [ 29.783207341772151, -1.425446724137931 ], [ 29.782937848101266, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8546, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.425446724137931 ], [ 29.783207341772151, -1.425716206896552 ], [ 29.783476835443039, -1.425716206896552 ], [ 29.783476835443039, -1.425446724137931 ], [ 29.783207341772151, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8547, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.425446724137931 ], [ 29.783476835443039, -1.425716206896552 ], [ 29.784015822784809, -1.425716206896552 ], [ 29.784015822784809, -1.425446724137931 ], [ 29.783476835443039, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8548, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.425446724137931 ], [ 29.784015822784809, -1.425716206896552 ], [ 29.784285316455698, -1.425716206896552 ], [ 29.784285316455698, -1.425446724137931 ], [ 29.784015822784809, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8549, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.425446724137931 ], [ 29.784285316455698, -1.425716206896552 ], [ 29.784554810126583, -1.425716206896552 ], [ 29.784554810126583, -1.425446724137931 ], [ 29.784285316455698, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8550, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.425446724137931 ], [ 29.784554810126583, -1.425716206896552 ], [ 29.784824303797468, -1.425716206896552 ], [ 29.784824303797468, -1.425446724137931 ], [ 29.784554810126583, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8551, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.425446724137931 ], [ 29.784824303797468, -1.425716206896552 ], [ 29.785093797468356, -1.425716206896552 ], [ 29.785093797468356, -1.425446724137931 ], [ 29.784824303797468, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8552, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.425446724137931 ], [ 29.785093797468356, -1.425716206896552 ], [ 29.785363291139241, -1.425716206896552 ], [ 29.785363291139241, -1.425446724137931 ], [ 29.785093797468356, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8553, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.425446724137931 ], [ 29.785363291139241, -1.425716206896552 ], [ 29.785632784810126, -1.425716206896552 ], [ 29.785632784810126, -1.425446724137931 ], [ 29.785363291139241, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8554, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.425446724137931 ], [ 29.788327721518986, -1.426524655172414 ], [ 29.788597215189874, -1.426524655172414 ], [ 29.788597215189874, -1.425446724137931 ], [ 29.788327721518986, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8555, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.425446724137931 ], [ 29.788597215189874, -1.426524655172414 ], [ 29.788866708860759, -1.426524655172414 ], [ 29.788866708860759, -1.425446724137931 ], [ 29.788597215189874, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8556, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.425446724137931 ], [ 29.788866708860759, -1.425716206896552 ], [ 29.789136202531644, -1.425716206896552 ], [ 29.789136202531644, -1.425446724137931 ], [ 29.788866708860759, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8557, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.425446724137931 ], [ 29.789136202531644, -1.425716206896552 ], [ 29.789405696202532, -1.425716206896552 ], [ 29.789405696202532, -1.425446724137931 ], [ 29.789136202531644, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8558, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.425446724137931 ], [ 29.789405696202532, -1.425716206896552 ], [ 29.789675189873417, -1.425716206896552 ], [ 29.789675189873417, -1.425446724137931 ], [ 29.789405696202532, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8559, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.425446724137931 ], [ 29.789944683544302, -1.425716206896552 ], [ 29.790214177215191, -1.425716206896552 ], [ 29.790214177215191, -1.425446724137931 ], [ 29.789944683544302, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8560, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.425446724137931 ], [ 29.790214177215191, -1.425716206896552 ], [ 29.790483670886076, -1.425716206896552 ], [ 29.790483670886076, -1.425446724137931 ], [ 29.790214177215191, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8561, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.425446724137931 ], [ 29.790483670886076, -1.425716206896552 ], [ 29.791022658227849, -1.425716206896552 ], [ 29.791022658227849, -1.425446724137931 ], [ 29.790483670886076, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8562, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.425446724137931 ], [ 29.791022658227849, -1.425716206896552 ], [ 29.791292151898734, -1.425716206896552 ], [ 29.791292151898734, -1.425446724137931 ], [ 29.791022658227849, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8563, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.425446724137931 ], [ 29.791292151898734, -1.425716206896552 ], [ 29.791561645569619, -1.425716206896552 ], [ 29.791561645569619, -1.425446724137931 ], [ 29.791292151898734, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8564, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.425446724137931 ], [ 29.791561645569619, -1.425716206896552 ], [ 29.791831139240507, -1.425716206896552 ], [ 29.791831139240507, -1.425446724137931 ], [ 29.791561645569619, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8565, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.425446724137931 ], [ 29.791831139240507, -1.425716206896552 ], [ 29.792100632911392, -1.425716206896552 ], [ 29.792100632911392, -1.425446724137931 ], [ 29.791831139240507, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8566, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.425446724137931 ], [ 29.792100632911392, -1.425716206896552 ], [ 29.792370126582277, -1.425716206896552 ], [ 29.792370126582277, -1.425446724137931 ], [ 29.792100632911392, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8567, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.425446724137931 ], [ 29.792370126582277, -1.425716206896552 ], [ 29.792909113924051, -1.425716206896552 ], [ 29.792909113924051, -1.425446724137931 ], [ 29.792370126582277, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8568, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.425446724137931 ], [ 29.792909113924051, -1.425716206896552 ], [ 29.793178607594935, -1.425716206896552 ], [ 29.793178607594935, -1.425446724137931 ], [ 29.792909113924051, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8569, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.425446724137931 ], [ 29.793178607594935, -1.425716206896552 ], [ 29.793448101265824, -1.425716206896552 ], [ 29.793448101265824, -1.425446724137931 ], [ 29.793178607594935, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8570, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.425446724137931 ], [ 29.793448101265824, -1.425716206896552 ], [ 29.793717594936709, -1.425716206896552 ], [ 29.793717594936709, -1.425446724137931 ], [ 29.793448101265824, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8571, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.425446724137931 ], [ 29.793717594936709, -1.425985689655173 ], [ 29.793987088607594, -1.425985689655173 ], [ 29.793987088607594, -1.425446724137931 ], [ 29.793717594936709, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8572, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.425446724137931 ], [ 29.805036329113925, -1.425716206896552 ], [ 29.80530582278481, -1.425716206896552 ], [ 29.80530582278481, -1.425446724137931 ], [ 29.805036329113925, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8573, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.425446724137931 ], [ 29.80530582278481, -1.425716206896552 ], [ 29.805575316455698, -1.425716206896552 ], [ 29.805575316455698, -1.425446724137931 ], [ 29.80530582278481, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8574, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.425446724137931 ], [ 29.805575316455698, -1.425716206896552 ], [ 29.805844810126583, -1.425716206896552 ], [ 29.805844810126583, -1.425446724137931 ], [ 29.805575316455698, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8575, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.425446724137931 ], [ 29.805844810126583, -1.425716206896552 ], [ 29.806114303797468, -1.425716206896552 ], [ 29.806114303797468, -1.425446724137931 ], [ 29.805844810126583, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8576, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.425446724137931 ], [ 29.806114303797468, -1.425716206896552 ], [ 29.806383797468357, -1.425716206896552 ], [ 29.806383797468357, -1.425446724137931 ], [ 29.806114303797468, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8577, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.425446724137931 ], [ 29.806383797468357, -1.425716206896552 ], [ 29.806653291139241, -1.425716206896552 ], [ 29.806653291139241, -1.425446724137931 ], [ 29.806383797468357, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8578, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.425446724137931 ], [ 29.806653291139241, -1.425716206896552 ], [ 29.806922784810126, -1.425716206896552 ], [ 29.806922784810126, -1.425446724137931 ], [ 29.806653291139241, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8579, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.425446724137931 ], [ 29.806922784810126, -1.425716206896552 ], [ 29.8074617721519, -1.425716206896552 ], [ 29.8074617721519, -1.425446724137931 ], [ 29.806922784810126, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8580, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.425446724137931 ], [ 29.8074617721519, -1.425716206896552 ], [ 29.808000759493673, -1.425716206896552 ], [ 29.808000759493673, -1.425446724137931 ], [ 29.8074617721519, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8581, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.425446724137931 ], [ 29.808000759493673, -1.425716206896552 ], [ 29.808539746835443, -1.425716206896552 ], [ 29.808539746835443, -1.425446724137931 ], [ 29.808000759493673, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8582, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.425446724137931 ], [ 29.808809240506328, -1.425716206896552 ], [ 29.809078734177216, -1.425716206896552 ], [ 29.809078734177216, -1.425446724137931 ], [ 29.808809240506328, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8583, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.42517724137931 ], [ 29.809617721518986, -1.42517724137931 ], [ 29.809617721518986, -1.425446724137931 ], [ 29.81015670886076, -1.425446724137931 ], [ 29.81015670886076, -1.425716206896552 ], [ 29.809078734177216, -1.425716206896552 ], [ 29.809078734177216, -1.425446724137931 ], [ 29.809348227848101, -1.425446724137931 ], [ 29.809348227848101, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8584, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.425446724137931 ], [ 29.810426202531644, -1.425716206896552 ], [ 29.810965189873418, -1.425716206896552 ], [ 29.810965189873418, -1.425446724137931 ], [ 29.810426202531644, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8585, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.425446724137931 ], [ 29.810965189873418, -1.425716206896552 ], [ 29.811234683544303, -1.425716206896552 ], [ 29.811234683544303, -1.425446724137931 ], [ 29.810965189873418, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8586, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.425446724137931 ], [ 29.811234683544303, -1.425716206896552 ], [ 29.811504177215191, -1.425716206896552 ], [ 29.811504177215191, -1.425446724137931 ], [ 29.811234683544303, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8587, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.425446724137931 ], [ 29.811504177215191, -1.425716206896552 ], [ 29.811773670886076, -1.425716206896552 ], [ 29.811773670886076, -1.425446724137931 ], [ 29.811504177215191, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8588, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.425446724137931 ], [ 29.811773670886076, -1.425716206896552 ], [ 29.812851645569619, -1.425716206896552 ], [ 29.812851645569619, -1.425446724137931 ], [ 29.811773670886076, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8589, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.425446724137931 ], [ 29.812851645569619, -1.425716206896552 ], [ 29.813121139240508, -1.425716206896552 ], [ 29.813121139240508, -1.425446724137931 ], [ 29.812851645569619, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8590, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.425446724137931 ], [ 29.813121139240508, -1.425716206896552 ], [ 29.813660126582278, -1.425716206896552 ], [ 29.813660126582278, -1.425446724137931 ], [ 29.813121139240508, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8591, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.425446724137931 ], [ 29.813929620253166, -1.425716206896552 ], [ 29.814199113924051, -1.425716206896552 ], [ 29.814199113924051, -1.425446724137931 ], [ 29.813929620253166, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8592, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.425446724137931 ], [ 29.814199113924051, -1.425716206896552 ], [ 29.814468607594936, -1.425716206896552 ], [ 29.814468607594936, -1.425446724137931 ], [ 29.814199113924051, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8593, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.425446724137931 ], [ 29.814468607594936, -1.425716206896552 ], [ 29.814738101265824, -1.425716206896552 ], [ 29.814738101265824, -1.425446724137931 ], [ 29.814468607594936, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8594, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.425716206896552 ], [ 29.757066455696201, -1.425985689655173 ], [ 29.757335949367089, -1.425985689655173 ], [ 29.757335949367089, -1.425716206896552 ], [ 29.757066455696201, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8595, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.425716206896552 ], [ 29.757335949367089, -1.425985689655173 ], [ 29.757605443037974, -1.425985689655173 ], [ 29.757605443037974, -1.425716206896552 ], [ 29.757335949367089, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8596, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.425716206896552 ], [ 29.757605443037974, -1.425985689655173 ], [ 29.757874936708859, -1.425985689655173 ], [ 29.757874936708859, -1.425716206896552 ], [ 29.757605443037974, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8597, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.425716206896552 ], [ 29.758144430379748, -1.425985689655173 ], [ 29.758413924050632, -1.425985689655173 ], [ 29.758413924050632, -1.425716206896552 ], [ 29.758144430379748, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8598, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.425716206896552 ], [ 29.758413924050632, -1.425985689655173 ], [ 29.758683417721517, -1.425985689655173 ], [ 29.758683417721517, -1.425716206896552 ], [ 29.758413924050632, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8599, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.425716206896552 ], [ 29.758952911392406, -1.426524655172414 ], [ 29.759222405063291, -1.426524655172414 ], [ 29.759222405063291, -1.425716206896552 ], [ 29.758952911392406, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8600, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.425716206896552 ], [ 29.759761392405064, -1.426524655172414 ], [ 29.760030886075949, -1.426524655172414 ], [ 29.760030886075949, -1.425716206896552 ], [ 29.759761392405064, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8601, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.425716206896552 ], [ 29.760569873417722, -1.426524655172414 ], [ 29.760839367088607, -1.426524655172414 ], [ 29.760839367088607, -1.425716206896552 ], [ 29.760569873417722, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8602, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.425716206896552 ], [ 29.761108860759492, -1.426524655172414 ], [ 29.761378354430381, -1.426524655172414 ], [ 29.761378354430381, -1.425716206896552 ], [ 29.761108860759492, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8603, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.425716206896552 ], [ 29.761378354430381, -1.426524655172414 ], [ 29.761647848101266, -1.426524655172414 ], [ 29.761647848101266, -1.425716206896552 ], [ 29.761378354430381, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8604, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.425716206896552 ], [ 29.761647848101266, -1.425985689655173 ], [ 29.761917341772151, -1.425985689655173 ], [ 29.761917341772151, -1.425716206896552 ], [ 29.761647848101266, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8605, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.425716206896552 ], [ 29.761917341772151, -1.426524655172414 ], [ 29.762186835443039, -1.426524655172414 ], [ 29.762186835443039, -1.425716206896552 ], [ 29.761917341772151, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8606, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.425716206896552 ], [ 29.762186835443039, -1.426524655172414 ], [ 29.762456329113924, -1.426524655172414 ], [ 29.762456329113924, -1.425716206896552 ], [ 29.762186835443039, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8607, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.425716206896552 ], [ 29.762456329113924, -1.425985689655173 ], [ 29.762725822784809, -1.425985689655173 ], [ 29.762725822784809, -1.425716206896552 ], [ 29.762456329113924, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8608, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.425716206896552 ], [ 29.762725822784809, -1.425985689655173 ], [ 29.762995316455697, -1.425985689655173 ], [ 29.762995316455697, -1.425716206896552 ], [ 29.762725822784809, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8609, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.425716206896552 ], [ 29.762995316455697, -1.425985689655173 ], [ 29.763264810126582, -1.425985689655173 ], [ 29.763264810126582, -1.425716206896552 ], [ 29.762995316455697, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8610, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.425716206896552 ], [ 29.763264810126582, -1.425985689655173 ], [ 29.763534303797467, -1.425985689655173 ], [ 29.763534303797467, -1.425716206896552 ], [ 29.763264810126582, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8611, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.425716206896552 ], [ 29.763534303797467, -1.425985689655173 ], [ 29.763803797468356, -1.425985689655173 ], [ 29.763803797468356, -1.425716206896552 ], [ 29.763534303797467, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8612, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.425716206896552 ], [ 29.763803797468356, -1.425985689655173 ], [ 29.76407329113924, -1.425985689655173 ], [ 29.76407329113924, -1.425716206896552 ], [ 29.763803797468356, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8613, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.425716206896552 ], [ 29.76407329113924, -1.425985689655173 ], [ 29.764342784810125, -1.425985689655173 ], [ 29.764342784810125, -1.425716206896552 ], [ 29.76407329113924, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8614, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.425716206896552 ], [ 29.764342784810125, -1.425985689655173 ], [ 29.764612278481014, -1.425985689655173 ], [ 29.764612278481014, -1.425716206896552 ], [ 29.764342784810125, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8615, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.425716206896552 ], [ 29.764612278481014, -1.425985689655173 ], [ 29.764881772151899, -1.425985689655173 ], [ 29.764881772151899, -1.425716206896552 ], [ 29.764612278481014, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8616, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.425716206896552 ], [ 29.764881772151899, -1.425985689655173 ], [ 29.765151265822784, -1.425985689655173 ], [ 29.765151265822784, -1.425716206896552 ], [ 29.764881772151899, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8617, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.425716206896552 ], [ 29.765151265822784, -1.425985689655173 ], [ 29.765420759493672, -1.425985689655173 ], [ 29.765420759493672, -1.425716206896552 ], [ 29.765151265822784, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8618, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.425716206896552 ], [ 29.765420759493672, -1.425985689655173 ], [ 29.765959746835442, -1.425985689655173 ], [ 29.765959746835442, -1.425716206896552 ], [ 29.765420759493672, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8619, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.425716206896552 ], [ 29.765959746835442, -1.425985689655173 ], [ 29.766229240506327, -1.425985689655173 ], [ 29.766229240506327, -1.425716206896552 ], [ 29.765959746835442, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8620, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.425716206896552 ], [ 29.766229240506327, -1.425985689655173 ], [ 29.766498734177215, -1.425985689655173 ], [ 29.766498734177215, -1.425716206896552 ], [ 29.766229240506327, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8621, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.425716206896552 ], [ 29.766498734177215, -1.425985689655173 ], [ 29.7667682278481, -1.425985689655173 ], [ 29.7667682278481, -1.425716206896552 ], [ 29.766498734177215, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8622, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.425716206896552 ], [ 29.7667682278481, -1.425985689655173 ], [ 29.767037721518985, -1.425985689655173 ], [ 29.767037721518985, -1.425716206896552 ], [ 29.7667682278481, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8623, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.425716206896552 ], [ 29.767037721518985, -1.425985689655173 ], [ 29.767307215189874, -1.425985689655173 ], [ 29.767307215189874, -1.425716206896552 ], [ 29.767037721518985, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8624, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.425716206896552 ], [ 29.767307215189874, -1.425985689655173 ], [ 29.767576708860759, -1.425985689655173 ], [ 29.767576708860759, -1.425716206896552 ], [ 29.767307215189874, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8625, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.425716206896552 ], [ 29.767576708860759, -1.425985689655173 ], [ 29.767846202531643, -1.425985689655173 ], [ 29.767846202531643, -1.425716206896552 ], [ 29.767576708860759, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8626, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.425716206896552 ], [ 29.767846202531643, -1.425985689655173 ], [ 29.768115696202532, -1.425985689655173 ], [ 29.768115696202532, -1.425716206896552 ], [ 29.767846202531643, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8627, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.425716206896552 ], [ 29.768115696202532, -1.425985689655173 ], [ 29.768385189873417, -1.425985689655173 ], [ 29.768385189873417, -1.425716206896552 ], [ 29.768115696202532, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8628, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.425716206896552 ], [ 29.768385189873417, -1.425985689655173 ], [ 29.768654683544302, -1.425985689655173 ], [ 29.768654683544302, -1.425716206896552 ], [ 29.768385189873417, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8629, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.425716206896552 ], [ 29.768654683544302, -1.426524655172414 ], [ 29.76892417721519, -1.426524655172414 ], [ 29.76892417721519, -1.425716206896552 ], [ 29.768654683544302, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8630, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.425716206896552 ], [ 29.76892417721519, -1.425985689655173 ], [ 29.769193670886075, -1.425985689655173 ], [ 29.769193670886075, -1.425716206896552 ], [ 29.76892417721519, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8631, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.425716206896552 ], [ 29.769193670886075, -1.425985689655173 ], [ 29.76946316455696, -1.425985689655173 ], [ 29.76946316455696, -1.425716206896552 ], [ 29.769193670886075, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8632, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.425716206896552 ], [ 29.769732658227849, -1.425985689655173 ], [ 29.770002151898733, -1.425985689655173 ], [ 29.770002151898733, -1.425716206896552 ], [ 29.769732658227849, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8633, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.425716206896552 ], [ 29.770002151898733, -1.425985689655173 ], [ 29.770271645569618, -1.425985689655173 ], [ 29.770271645569618, -1.425716206896552 ], [ 29.770002151898733, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8634, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.425716206896552 ], [ 29.770271645569618, -1.425985689655173 ], [ 29.770541139240507, -1.425985689655173 ], [ 29.770541139240507, -1.425716206896552 ], [ 29.770271645569618, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8635, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.425716206896552 ], [ 29.770541139240507, -1.425985689655173 ], [ 29.770810632911392, -1.425985689655173 ], [ 29.770810632911392, -1.425716206896552 ], [ 29.770541139240507, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8636, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.425716206896552 ], [ 29.770810632911392, -1.425985689655173 ], [ 29.771080126582277, -1.425985689655173 ], [ 29.771080126582277, -1.425716206896552 ], [ 29.770810632911392, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8637, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.425716206896552 ], [ 29.777008987341773, -1.426524655172414 ], [ 29.777278481012658, -1.426524655172414 ], [ 29.777278481012658, -1.425716206896552 ], [ 29.777008987341773, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8638, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.425716206896552 ], [ 29.777817468354431, -1.426524655172414 ], [ 29.778086962025316, -1.426524655172414 ], [ 29.778086962025316, -1.425716206896552 ], [ 29.777817468354431, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8639, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.425716206896552 ], [ 29.778356455696201, -1.426524655172414 ], [ 29.77862594936709, -1.426524655172414 ], [ 29.77862594936709, -1.425716206896552 ], [ 29.778356455696201, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8640, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.425716206896552 ], [ 29.77862594936709, -1.425985689655173 ], [ 29.778895443037975, -1.425985689655173 ], [ 29.778895443037975, -1.425716206896552 ], [ 29.77862594936709, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8641, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.425716206896552 ], [ 29.77916493670886, -1.426524655172414 ], [ 29.779434430379748, -1.426524655172414 ], [ 29.779434430379748, -1.425716206896552 ], [ 29.77916493670886, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8642, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.425716206896552 ], [ 29.779434430379748, -1.425985689655173 ], [ 29.779703924050633, -1.425985689655173 ], [ 29.779703924050633, -1.425716206896552 ], [ 29.779434430379748, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8643, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.425716206896552 ], [ 29.779703924050633, -1.425985689655173 ], [ 29.779973417721518, -1.425985689655173 ], [ 29.779973417721518, -1.425716206896552 ], [ 29.779703924050633, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8644, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.425716206896552 ], [ 29.779973417721518, -1.426524655172414 ], [ 29.780242911392406, -1.426524655172414 ], [ 29.780242911392406, -1.425716206896552 ], [ 29.779973417721518, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8645, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.425716206896552 ], [ 29.780242911392406, -1.425985689655173 ], [ 29.780512405063291, -1.425985689655173 ], [ 29.780512405063291, -1.425716206896552 ], [ 29.780242911392406, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8646, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.425716206896552 ], [ 29.780512405063291, -1.425985689655173 ], [ 29.780781898734176, -1.425985689655173 ], [ 29.780781898734176, -1.425716206896552 ], [ 29.780512405063291, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8647, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.425716206896552 ], [ 29.780781898734176, -1.426524655172414 ], [ 29.781051392405065, -1.426524655172414 ], [ 29.781051392405065, -1.425716206896552 ], [ 29.780781898734176, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8648, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.425716206896552 ], [ 29.781051392405065, -1.425985689655173 ], [ 29.78132088607595, -1.425985689655173 ], [ 29.78132088607595, -1.425716206896552 ], [ 29.781051392405065, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8649, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.425716206896552 ], [ 29.78132088607595, -1.425985689655173 ], [ 29.781590379746834, -1.425985689655173 ], [ 29.781590379746834, -1.425716206896552 ], [ 29.78132088607595, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8650, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.425716206896552 ], [ 29.781590379746834, -1.425985689655173 ], [ 29.781859873417723, -1.425985689655173 ], [ 29.781859873417723, -1.425716206896552 ], [ 29.781590379746834, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8651, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.425716206896552 ], [ 29.781859873417723, -1.425985689655173 ], [ 29.782129367088608, -1.425985689655173 ], [ 29.782129367088608, -1.425716206896552 ], [ 29.781859873417723, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8652, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.425716206896552 ], [ 29.782129367088608, -1.425985689655173 ], [ 29.782398860759493, -1.425985689655173 ], [ 29.782398860759493, -1.425716206896552 ], [ 29.782129367088608, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8653, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.425716206896552 ], [ 29.782398860759493, -1.425985689655173 ], [ 29.782668354430381, -1.425985689655173 ], [ 29.782668354430381, -1.425716206896552 ], [ 29.782398860759493, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8654, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.425716206896552 ], [ 29.782668354430381, -1.425985689655173 ], [ 29.782937848101266, -1.425985689655173 ], [ 29.782937848101266, -1.425716206896552 ], [ 29.782668354430381, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8655, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.425716206896552 ], [ 29.782937848101266, -1.425985689655173 ], [ 29.783207341772151, -1.425985689655173 ], [ 29.783207341772151, -1.425716206896552 ], [ 29.782937848101266, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8656, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.425716206896552 ], [ 29.783207341772151, -1.425985689655173 ], [ 29.783476835443039, -1.425985689655173 ], [ 29.783476835443039, -1.425716206896552 ], [ 29.783207341772151, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8657, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.425716206896552 ], [ 29.783476835443039, -1.425985689655173 ], [ 29.784015822784809, -1.425985689655173 ], [ 29.784015822784809, -1.425716206896552 ], [ 29.783476835443039, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8658, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.425716206896552 ], [ 29.784015822784809, -1.425985689655173 ], [ 29.784285316455698, -1.425985689655173 ], [ 29.784285316455698, -1.425716206896552 ], [ 29.784015822784809, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8659, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.425716206896552 ], [ 29.784285316455698, -1.425985689655173 ], [ 29.784554810126583, -1.425985689655173 ], [ 29.784554810126583, -1.425716206896552 ], [ 29.784285316455698, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8660, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.425716206896552 ], [ 29.784554810126583, -1.425985689655173 ], [ 29.784824303797468, -1.425985689655173 ], [ 29.784824303797468, -1.425716206896552 ], [ 29.784554810126583, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8661, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.425716206896552 ], [ 29.784824303797468, -1.425985689655173 ], [ 29.785093797468356, -1.425985689655173 ], [ 29.785093797468356, -1.425716206896552 ], [ 29.784824303797468, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8662, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.425716206896552 ], [ 29.785093797468356, -1.426524655172414 ], [ 29.785363291139241, -1.426524655172414 ], [ 29.785363291139241, -1.425716206896552 ], [ 29.785093797468356, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8663, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.425716206896552 ], [ 29.788866708860759, -1.425985689655173 ], [ 29.789136202531644, -1.425985689655173 ], [ 29.789136202531644, -1.425716206896552 ], [ 29.788866708860759, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8664, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.425716206896552 ], [ 29.789136202531644, -1.425985689655173 ], [ 29.789405696202532, -1.425985689655173 ], [ 29.789405696202532, -1.425716206896552 ], [ 29.789136202531644, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8665, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.425716206896552 ], [ 29.789405696202532, -1.425985689655173 ], [ 29.789675189873417, -1.425985689655173 ], [ 29.789675189873417, -1.425716206896552 ], [ 29.789405696202532, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8666, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.425716206896552 ], [ 29.789675189873417, -1.425985689655173 ], [ 29.789944683544302, -1.425985689655173 ], [ 29.789944683544302, -1.425716206896552 ], [ 29.789675189873417, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8667, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.425716206896552 ], [ 29.789944683544302, -1.425985689655173 ], [ 29.790214177215191, -1.425985689655173 ], [ 29.790214177215191, -1.425716206896552 ], [ 29.789944683544302, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8668, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.425716206896552 ], [ 29.790214177215191, -1.425985689655173 ], [ 29.790483670886076, -1.425985689655173 ], [ 29.790483670886076, -1.425716206896552 ], [ 29.790214177215191, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8669, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.425716206896552 ], [ 29.790483670886076, -1.425985689655173 ], [ 29.790753164556961, -1.425985689655173 ], [ 29.790753164556961, -1.425716206896552 ], [ 29.790483670886076, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8670, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.425716206896552 ], [ 29.790753164556961, -1.425985689655173 ], [ 29.791022658227849, -1.425985689655173 ], [ 29.791022658227849, -1.425716206896552 ], [ 29.790753164556961, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8671, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.425716206896552 ], [ 29.791022658227849, -1.425985689655173 ], [ 29.791292151898734, -1.425985689655173 ], [ 29.791292151898734, -1.425716206896552 ], [ 29.791022658227849, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8672, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.425716206896552 ], [ 29.791292151898734, -1.425985689655173 ], [ 29.791561645569619, -1.425985689655173 ], [ 29.791561645569619, -1.425716206896552 ], [ 29.791292151898734, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8673, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.425716206896552 ], [ 29.791561645569619, -1.425985689655173 ], [ 29.791831139240507, -1.425985689655173 ], [ 29.791831139240507, -1.425716206896552 ], [ 29.791561645569619, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8674, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.425716206896552 ], [ 29.791831139240507, -1.425985689655173 ], [ 29.792100632911392, -1.425985689655173 ], [ 29.792100632911392, -1.425716206896552 ], [ 29.791831139240507, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8675, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.425716206896552 ], [ 29.792100632911392, -1.425985689655173 ], [ 29.792370126582277, -1.425985689655173 ], [ 29.792370126582277, -1.425716206896552 ], [ 29.792100632911392, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8676, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.425716206896552 ], [ 29.792370126582277, -1.425985689655173 ], [ 29.792909113924051, -1.425985689655173 ], [ 29.792909113924051, -1.425716206896552 ], [ 29.792370126582277, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8677, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.425716206896552 ], [ 29.792909113924051, -1.425985689655173 ], [ 29.793178607594935, -1.425985689655173 ], [ 29.793178607594935, -1.425716206896552 ], [ 29.792909113924051, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8678, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.425716206896552 ], [ 29.793178607594935, -1.425985689655173 ], [ 29.793448101265824, -1.425985689655173 ], [ 29.793448101265824, -1.425716206896552 ], [ 29.793178607594935, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8679, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.425716206896552 ], [ 29.793448101265824, -1.425985689655173 ], [ 29.793717594936709, -1.425985689655173 ], [ 29.793717594936709, -1.425716206896552 ], [ 29.793448101265824, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8680, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.425716206896552 ], [ 29.80530582278481, -1.425985689655173 ], [ 29.805575316455698, -1.425985689655173 ], [ 29.805575316455698, -1.425716206896552 ], [ 29.80530582278481, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8681, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.425716206896552 ], [ 29.805575316455698, -1.425985689655173 ], [ 29.805844810126583, -1.425985689655173 ], [ 29.805844810126583, -1.425716206896552 ], [ 29.805575316455698, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8682, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.425716206896552 ], [ 29.805844810126583, -1.425985689655173 ], [ 29.806114303797468, -1.425985689655173 ], [ 29.806114303797468, -1.425716206896552 ], [ 29.805844810126583, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8683, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.425716206896552 ], [ 29.806114303797468, -1.425985689655173 ], [ 29.806383797468357, -1.425985689655173 ], [ 29.806383797468357, -1.425716206896552 ], [ 29.806114303797468, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8684, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.425716206896552 ], [ 29.806383797468357, -1.425985689655173 ], [ 29.806653291139241, -1.425985689655173 ], [ 29.806653291139241, -1.425716206896552 ], [ 29.806383797468357, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8685, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.425716206896552 ], [ 29.806653291139241, -1.425985689655173 ], [ 29.806922784810126, -1.425985689655173 ], [ 29.806922784810126, -1.425716206896552 ], [ 29.806653291139241, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8686, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.425716206896552 ], [ 29.806922784810126, -1.425985689655173 ], [ 29.807192278481015, -1.425985689655173 ], [ 29.807192278481015, -1.425716206896552 ], [ 29.806922784810126, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8687, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.425716206896552 ], [ 29.807192278481015, -1.425985689655173 ], [ 29.8074617721519, -1.425985689655173 ], [ 29.8074617721519, -1.425716206896552 ], [ 29.807192278481015, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8688, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.425716206896552 ], [ 29.8074617721519, -1.425985689655173 ], [ 29.807731265822785, -1.425985689655173 ], [ 29.807731265822785, -1.425716206896552 ], [ 29.8074617721519, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8689, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.425716206896552 ], [ 29.807731265822785, -1.425985689655173 ], [ 29.808000759493673, -1.425985689655173 ], [ 29.808000759493673, -1.425716206896552 ], [ 29.807731265822785, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8690, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.425716206896552 ], [ 29.808000759493673, -1.425985689655173 ], [ 29.808270253164558, -1.425985689655173 ], [ 29.808270253164558, -1.425716206896552 ], [ 29.808000759493673, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8691, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.425716206896552 ], [ 29.808270253164558, -1.425985689655173 ], [ 29.808539746835443, -1.425985689655173 ], [ 29.808539746835443, -1.425716206896552 ], [ 29.808270253164558, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8692, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.425716206896552 ], [ 29.808539746835443, -1.425985689655173 ], [ 29.808809240506328, -1.425985689655173 ], [ 29.808809240506328, -1.425716206896552 ], [ 29.808539746835443, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8693, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.425716206896552 ], [ 29.808809240506328, -1.425985689655173 ], [ 29.809078734177216, -1.425985689655173 ], [ 29.809078734177216, -1.425716206896552 ], [ 29.808809240506328, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8694, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.425446724137931 ], [ 29.810426202531644, -1.425446724137931 ], [ 29.810426202531644, -1.425716206896552 ], [ 29.811234683544303, -1.425716206896552 ], [ 29.811234683544303, -1.425985689655173 ], [ 29.809078734177216, -1.425985689655173 ], [ 29.809078734177216, -1.425716206896552 ], [ 29.81015670886076, -1.425716206896552 ], [ 29.81015670886076, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8695, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.425716206896552 ], [ 29.811234683544303, -1.426524655172414 ], [ 29.811504177215191, -1.426524655172414 ], [ 29.811504177215191, -1.425716206896552 ], [ 29.811234683544303, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8696, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.425716206896552 ], [ 29.811504177215191, -1.425985689655173 ], [ 29.811773670886076, -1.425985689655173 ], [ 29.811773670886076, -1.425716206896552 ], [ 29.811504177215191, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8697, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.425716206896552 ], [ 29.811773670886076, -1.425985689655173 ], [ 29.812851645569619, -1.425985689655173 ], [ 29.812851645569619, -1.425716206896552 ], [ 29.811773670886076, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8698, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.425716206896552 ], [ 29.812851645569619, -1.425985689655173 ], [ 29.813121139240508, -1.425985689655173 ], [ 29.813121139240508, -1.425716206896552 ], [ 29.812851645569619, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8699, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.425716206896552 ], [ 29.813121139240508, -1.425985689655173 ], [ 29.813390632911393, -1.425985689655173 ], [ 29.813390632911393, -1.425716206896552 ], [ 29.813121139240508, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8700, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.425716206896552 ], [ 29.813390632911393, -1.425985689655173 ], [ 29.813660126582278, -1.425985689655173 ], [ 29.813660126582278, -1.425716206896552 ], [ 29.813390632911393, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8701, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.425716206896552 ], [ 29.813660126582278, -1.425985689655173 ], [ 29.813929620253166, -1.425985689655173 ], [ 29.813929620253166, -1.425716206896552 ], [ 29.813660126582278, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8702, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.425716206896552 ], [ 29.813929620253166, -1.425985689655173 ], [ 29.814199113924051, -1.425985689655173 ], [ 29.814199113924051, -1.425716206896552 ], [ 29.813929620253166, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8703, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.425716206896552 ], [ 29.814199113924051, -1.425985689655173 ], [ 29.814468607594936, -1.425985689655173 ], [ 29.814468607594936, -1.425716206896552 ], [ 29.814199113924051, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8704, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.425985689655173 ], [ 29.757874936708859, -1.426524655172414 ], [ 29.758144430379748, -1.426524655172414 ], [ 29.758144430379748, -1.425985689655173 ], [ 29.757874936708859, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8705, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.425985689655173 ], [ 29.758144430379748, -1.426524655172414 ], [ 29.758413924050632, -1.426524655172414 ], [ 29.758413924050632, -1.425985689655173 ], [ 29.758144430379748, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8706, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.425985689655173 ], [ 29.758413924050632, -1.426524655172414 ], [ 29.758683417721517, -1.426524655172414 ], [ 29.758683417721517, -1.425985689655173 ], [ 29.758413924050632, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8707, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.425985689655173 ], [ 29.758683417721517, -1.426524655172414 ], [ 29.758952911392406, -1.426524655172414 ], [ 29.758952911392406, -1.425985689655173 ], [ 29.758683417721517, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8708, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.425985689655173 ], [ 29.759222405063291, -1.426524655172414 ], [ 29.759491898734176, -1.426524655172414 ], [ 29.759491898734176, -1.425985689655173 ], [ 29.759222405063291, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8709, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.425985689655173 ], [ 29.759491898734176, -1.426524655172414 ], [ 29.759761392405064, -1.426524655172414 ], [ 29.759761392405064, -1.425985689655173 ], [ 29.759491898734176, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8710, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.425985689655173 ], [ 29.760300379746834, -1.426524655172414 ], [ 29.760569873417722, -1.426524655172414 ], [ 29.760569873417722, -1.425985689655173 ], [ 29.760300379746834, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8711, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.425985689655173 ], [ 29.762725822784809, -1.426524655172414 ], [ 29.762995316455697, -1.426524655172414 ], [ 29.762995316455697, -1.425985689655173 ], [ 29.762725822784809, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8712, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.425985689655173 ], [ 29.762995316455697, -1.426524655172414 ], [ 29.763264810126582, -1.426524655172414 ], [ 29.763264810126582, -1.425985689655173 ], [ 29.762995316455697, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8713, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.425985689655173 ], [ 29.763264810126582, -1.426524655172414 ], [ 29.763534303797467, -1.426524655172414 ], [ 29.763534303797467, -1.425985689655173 ], [ 29.763264810126582, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8714, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.425985689655173 ], [ 29.763534303797467, -1.426524655172414 ], [ 29.763803797468356, -1.426524655172414 ], [ 29.763803797468356, -1.425985689655173 ], [ 29.763534303797467, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8715, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.425985689655173 ], [ 29.763803797468356, -1.426524655172414 ], [ 29.76407329113924, -1.426524655172414 ], [ 29.76407329113924, -1.425985689655173 ], [ 29.763803797468356, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8716, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.425985689655173 ], [ 29.76407329113924, -1.426524655172414 ], [ 29.764342784810125, -1.426524655172414 ], [ 29.764342784810125, -1.425985689655173 ], [ 29.76407329113924, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8717, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.425985689655173 ], [ 29.764342784810125, -1.426524655172414 ], [ 29.764612278481014, -1.426524655172414 ], [ 29.764612278481014, -1.425985689655173 ], [ 29.764342784810125, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8718, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.425985689655173 ], [ 29.764612278481014, -1.426524655172414 ], [ 29.764881772151899, -1.426524655172414 ], [ 29.764881772151899, -1.425985689655173 ], [ 29.764612278481014, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8719, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.425985689655173 ], [ 29.764881772151899, -1.426524655172414 ], [ 29.765151265822784, -1.426524655172414 ], [ 29.765151265822784, -1.425985689655173 ], [ 29.764881772151899, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8720, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.425985689655173 ], [ 29.765151265822784, -1.426524655172414 ], [ 29.765420759493672, -1.426524655172414 ], [ 29.765420759493672, -1.425985689655173 ], [ 29.765151265822784, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8721, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.425985689655173 ], [ 29.765420759493672, -1.426524655172414 ], [ 29.765959746835442, -1.426524655172414 ], [ 29.765959746835442, -1.425985689655173 ], [ 29.765420759493672, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8722, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.425985689655173 ], [ 29.765959746835442, -1.426524655172414 ], [ 29.766229240506327, -1.426524655172414 ], [ 29.766229240506327, -1.425985689655173 ], [ 29.765959746835442, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8723, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.425985689655173 ], [ 29.766229240506327, -1.426524655172414 ], [ 29.766498734177215, -1.426524655172414 ], [ 29.766498734177215, -1.425985689655173 ], [ 29.766229240506327, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8724, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.425985689655173 ], [ 29.766498734177215, -1.426524655172414 ], [ 29.7667682278481, -1.426524655172414 ], [ 29.7667682278481, -1.425985689655173 ], [ 29.766498734177215, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8725, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.425985689655173 ], [ 29.7667682278481, -1.426524655172414 ], [ 29.767037721518985, -1.426524655172414 ], [ 29.767037721518985, -1.425985689655173 ], [ 29.7667682278481, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8726, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.425985689655173 ], [ 29.767037721518985, -1.426524655172414 ], [ 29.767307215189874, -1.426524655172414 ], [ 29.767307215189874, -1.425985689655173 ], [ 29.767037721518985, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8727, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.425985689655173 ], [ 29.767307215189874, -1.426524655172414 ], [ 29.767576708860759, -1.426524655172414 ], [ 29.767576708860759, -1.425985689655173 ], [ 29.767307215189874, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8728, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.425985689655173 ], [ 29.767846202531643, -1.426524655172414 ], [ 29.768115696202532, -1.426524655172414 ], [ 29.768115696202532, -1.425985689655173 ], [ 29.767846202531643, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8729, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.425985689655173 ], [ 29.768115696202532, -1.426524655172414 ], [ 29.768385189873417, -1.426524655172414 ], [ 29.768385189873417, -1.425985689655173 ], [ 29.768115696202532, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8730, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.425985689655173 ], [ 29.768385189873417, -1.426524655172414 ], [ 29.768654683544302, -1.426524655172414 ], [ 29.768654683544302, -1.425985689655173 ], [ 29.768385189873417, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8731, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.425985689655173 ], [ 29.76892417721519, -1.426524655172414 ], [ 29.769193670886075, -1.426524655172414 ], [ 29.769193670886075, -1.425985689655173 ], [ 29.76892417721519, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8732, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.425985689655173 ], [ 29.769193670886075, -1.426524655172414 ], [ 29.76946316455696, -1.426524655172414 ], [ 29.76946316455696, -1.425985689655173 ], [ 29.769193670886075, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8733, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.425985689655173 ], [ 29.76946316455696, -1.426524655172414 ], [ 29.769732658227849, -1.426524655172414 ], [ 29.769732658227849, -1.425985689655173 ], [ 29.76946316455696, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8734, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.425985689655173 ], [ 29.769732658227849, -1.426524655172414 ], [ 29.770002151898733, -1.426524655172414 ], [ 29.770002151898733, -1.425985689655173 ], [ 29.769732658227849, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8735, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.425985689655173 ], [ 29.770002151898733, -1.426524655172414 ], [ 29.770271645569618, -1.426524655172414 ], [ 29.770271645569618, -1.425985689655173 ], [ 29.770002151898733, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8736, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.425985689655173 ], [ 29.770271645569618, -1.426524655172414 ], [ 29.770541139240507, -1.426524655172414 ], [ 29.770541139240507, -1.425985689655173 ], [ 29.770271645569618, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8737, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.425985689655173 ], [ 29.770541139240507, -1.426524655172414 ], [ 29.770810632911392, -1.426524655172414 ], [ 29.770810632911392, -1.425985689655173 ], [ 29.770541139240507, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8738, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.425985689655173 ], [ 29.770810632911392, -1.426524655172414 ], [ 29.771080126582277, -1.426524655172414 ], [ 29.771080126582277, -1.425985689655173 ], [ 29.770810632911392, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8739, "El": 166 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.425985689655173 ], [ 29.771080126582277, -1.426524655172414 ], [ 29.771349620253165, -1.426524655172414 ], [ 29.771349620253165, -1.425985689655173 ], [ 29.771080126582277, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8740, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.425985689655173 ], [ 29.780512405063291, -1.426524655172414 ], [ 29.780781898734176, -1.426524655172414 ], [ 29.780781898734176, -1.425985689655173 ], [ 29.780512405063291, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8741, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.425985689655173 ], [ 29.78132088607595, -1.426524655172414 ], [ 29.781590379746834, -1.426524655172414 ], [ 29.781590379746834, -1.425985689655173 ], [ 29.78132088607595, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8742, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.425985689655173 ], [ 29.781590379746834, -1.426524655172414 ], [ 29.781859873417723, -1.426524655172414 ], [ 29.781859873417723, -1.425985689655173 ], [ 29.781590379746834, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8743, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.425985689655173 ], [ 29.782129367088608, -1.426524655172414 ], [ 29.782398860759493, -1.426524655172414 ], [ 29.782398860759493, -1.425985689655173 ], [ 29.782129367088608, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8744, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.425985689655173 ], [ 29.782398860759493, -1.426524655172414 ], [ 29.782668354430381, -1.426524655172414 ], [ 29.782668354430381, -1.425985689655173 ], [ 29.782398860759493, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8745, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.425985689655173 ], [ 29.782668354430381, -1.426524655172414 ], [ 29.782937848101266, -1.426524655172414 ], [ 29.782937848101266, -1.425985689655173 ], [ 29.782668354430381, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8746, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.425985689655173 ], [ 29.782937848101266, -1.426524655172414 ], [ 29.783207341772151, -1.426524655172414 ], [ 29.783207341772151, -1.425985689655173 ], [ 29.782937848101266, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8747, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.425985689655173 ], [ 29.783207341772151, -1.426524655172414 ], [ 29.783476835443039, -1.426524655172414 ], [ 29.783476835443039, -1.425985689655173 ], [ 29.783207341772151, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8748, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.425985689655173 ], [ 29.783476835443039, -1.426524655172414 ], [ 29.784015822784809, -1.426524655172414 ], [ 29.784015822784809, -1.425985689655173 ], [ 29.783476835443039, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8749, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.425985689655173 ], [ 29.784015822784809, -1.426524655172414 ], [ 29.784285316455698, -1.426524655172414 ], [ 29.784285316455698, -1.425985689655173 ], [ 29.784015822784809, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8750, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.425985689655173 ], [ 29.784285316455698, -1.426524655172414 ], [ 29.784554810126583, -1.426524655172414 ], [ 29.784554810126583, -1.425985689655173 ], [ 29.784285316455698, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8751, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.425985689655173 ], [ 29.784554810126583, -1.426524655172414 ], [ 29.784824303797468, -1.426524655172414 ], [ 29.784824303797468, -1.425985689655173 ], [ 29.784554810126583, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8752, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.425985689655173 ], [ 29.784824303797468, -1.426524655172414 ], [ 29.785093797468356, -1.426524655172414 ], [ 29.785093797468356, -1.425985689655173 ], [ 29.784824303797468, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8753, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.425985689655173 ], [ 29.788866708860759, -1.426524655172414 ], [ 29.789136202531644, -1.426524655172414 ], [ 29.789136202531644, -1.425985689655173 ], [ 29.788866708860759, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8754, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.425985689655173 ], [ 29.789136202531644, -1.426524655172414 ], [ 29.789405696202532, -1.426524655172414 ], [ 29.789405696202532, -1.425985689655173 ], [ 29.789136202531644, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8755, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.425985689655173 ], [ 29.789405696202532, -1.426524655172414 ], [ 29.789675189873417, -1.426524655172414 ], [ 29.789675189873417, -1.425985689655173 ], [ 29.789405696202532, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8756, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.425985689655173 ], [ 29.789944683544302, -1.426524655172414 ], [ 29.790214177215191, -1.426524655172414 ], [ 29.790214177215191, -1.425985689655173 ], [ 29.789944683544302, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8757, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.425985689655173 ], [ 29.790214177215191, -1.426524655172414 ], [ 29.790483670886076, -1.426524655172414 ], [ 29.790483670886076, -1.425985689655173 ], [ 29.790214177215191, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8758, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.425985689655173 ], [ 29.790753164556961, -1.426524655172414 ], [ 29.791022658227849, -1.426524655172414 ], [ 29.791022658227849, -1.425985689655173 ], [ 29.790753164556961, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8759, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.425985689655173 ], [ 29.791022658227849, -1.426524655172414 ], [ 29.791292151898734, -1.426524655172414 ], [ 29.791292151898734, -1.425985689655173 ], [ 29.791022658227849, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8760, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.425985689655173 ], [ 29.791292151898734, -1.426524655172414 ], [ 29.791561645569619, -1.426524655172414 ], [ 29.791561645569619, -1.425985689655173 ], [ 29.791292151898734, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8761, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.425985689655173 ], [ 29.791561645569619, -1.426524655172414 ], [ 29.791831139240507, -1.426524655172414 ], [ 29.791831139240507, -1.425985689655173 ], [ 29.791561645569619, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8762, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.425985689655173 ], [ 29.791831139240507, -1.426524655172414 ], [ 29.792100632911392, -1.426524655172414 ], [ 29.792100632911392, -1.425985689655173 ], [ 29.791831139240507, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8763, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.425985689655173 ], [ 29.792100632911392, -1.426524655172414 ], [ 29.792370126582277, -1.426524655172414 ], [ 29.792370126582277, -1.425985689655173 ], [ 29.792100632911392, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8764, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.425985689655173 ], [ 29.792370126582277, -1.426524655172414 ], [ 29.792909113924051, -1.426524655172414 ], [ 29.792909113924051, -1.425985689655173 ], [ 29.792370126582277, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8765, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.425985689655173 ], [ 29.792909113924051, -1.426524655172414 ], [ 29.793178607594935, -1.426524655172414 ], [ 29.793178607594935, -1.425985689655173 ], [ 29.792909113924051, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8766, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.425985689655173 ], [ 29.793178607594935, -1.426524655172414 ], [ 29.793448101265824, -1.426524655172414 ], [ 29.793448101265824, -1.425985689655173 ], [ 29.793178607594935, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8767, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.425985689655173 ], [ 29.793448101265824, -1.426524655172414 ], [ 29.793717594936709, -1.426524655172414 ], [ 29.793717594936709, -1.425985689655173 ], [ 29.793448101265824, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8768, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.425985689655173 ], [ 29.793717594936709, -1.426524655172414 ], [ 29.793987088607594, -1.426524655172414 ], [ 29.793987088607594, -1.425985689655173 ], [ 29.793717594936709, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8769, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.425985689655173 ], [ 29.80530582278481, -1.426524655172414 ], [ 29.805575316455698, -1.426524655172414 ], [ 29.805575316455698, -1.425985689655173 ], [ 29.80530582278481, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8770, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.425985689655173 ], [ 29.805575316455698, -1.426524655172414 ], [ 29.805844810126583, -1.426524655172414 ], [ 29.805844810126583, -1.425985689655173 ], [ 29.805575316455698, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8771, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.425985689655173 ], [ 29.805844810126583, -1.426524655172414 ], [ 29.806114303797468, -1.426524655172414 ], [ 29.806114303797468, -1.425985689655173 ], [ 29.805844810126583, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8772, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.425985689655173 ], [ 29.806114303797468, -1.426524655172414 ], [ 29.806383797468357, -1.426524655172414 ], [ 29.806383797468357, -1.425985689655173 ], [ 29.806114303797468, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8773, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.425985689655173 ], [ 29.806383797468357, -1.426524655172414 ], [ 29.806653291139241, -1.426524655172414 ], [ 29.806653291139241, -1.425985689655173 ], [ 29.806383797468357, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8774, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.425985689655173 ], [ 29.806653291139241, -1.426524655172414 ], [ 29.806922784810126, -1.426524655172414 ], [ 29.806922784810126, -1.425985689655173 ], [ 29.806653291139241, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8775, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.425985689655173 ], [ 29.806922784810126, -1.426524655172414 ], [ 29.807192278481015, -1.426524655172414 ], [ 29.807192278481015, -1.425985689655173 ], [ 29.806922784810126, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8776, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.425985689655173 ], [ 29.807192278481015, -1.426524655172414 ], [ 29.8074617721519, -1.426524655172414 ], [ 29.8074617721519, -1.425985689655173 ], [ 29.807192278481015, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8777, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.425985689655173 ], [ 29.8074617721519, -1.426524655172414 ], [ 29.807731265822785, -1.426524655172414 ], [ 29.807731265822785, -1.425985689655173 ], [ 29.8074617721519, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8778, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.425985689655173 ], [ 29.807731265822785, -1.426524655172414 ], [ 29.808000759493673, -1.426524655172414 ], [ 29.808000759493673, -1.425985689655173 ], [ 29.807731265822785, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8779, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.425985689655173 ], [ 29.808000759493673, -1.426524655172414 ], [ 29.808270253164558, -1.426524655172414 ], [ 29.808270253164558, -1.425985689655173 ], [ 29.808000759493673, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8780, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.425985689655173 ], [ 29.808270253164558, -1.426524655172414 ], [ 29.808539746835443, -1.426524655172414 ], [ 29.808539746835443, -1.425985689655173 ], [ 29.808270253164558, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8781, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.425985689655173 ], [ 29.808539746835443, -1.426524655172414 ], [ 29.808809240506328, -1.426524655172414 ], [ 29.808809240506328, -1.425985689655173 ], [ 29.808539746835443, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8782, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.425985689655173 ], [ 29.808809240506328, -1.426524655172414 ], [ 29.809078734177216, -1.426524655172414 ], [ 29.809078734177216, -1.425985689655173 ], [ 29.808809240506328, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8783, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.425985689655173 ], [ 29.809078734177216, -1.426524655172414 ], [ 29.809348227848101, -1.426524655172414 ], [ 29.809348227848101, -1.425985689655173 ], [ 29.809078734177216, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8784, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.425985689655173 ], [ 29.809348227848101, -1.426524655172414 ], [ 29.810965189873418, -1.426524655172414 ], [ 29.810965189873418, -1.425985689655173 ], [ 29.809348227848101, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8785, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.425985689655173 ], [ 29.810965189873418, -1.426524655172414 ], [ 29.811234683544303, -1.426524655172414 ], [ 29.811234683544303, -1.425985689655173 ], [ 29.810965189873418, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8786, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.425985689655173 ], [ 29.811504177215191, -1.426524655172414 ], [ 29.812582151898734, -1.426524655172414 ], [ 29.812582151898734, -1.425985689655173 ], [ 29.811504177215191, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8787, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.425985689655173 ], [ 29.812582151898734, -1.426524655172414 ], [ 29.812851645569619, -1.426524655172414 ], [ 29.812851645569619, -1.425985689655173 ], [ 29.812582151898734, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8788, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.425985689655173 ], [ 29.812851645569619, -1.426524655172414 ], [ 29.813121139240508, -1.426524655172414 ], [ 29.813121139240508, -1.425985689655173 ], [ 29.812851645569619, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8789, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.425985689655173 ], [ 29.813121139240508, -1.426524655172414 ], [ 29.813390632911393, -1.426524655172414 ], [ 29.813390632911393, -1.425985689655173 ], [ 29.813121139240508, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8790, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.425985689655173 ], [ 29.813390632911393, -1.426524655172414 ], [ 29.813660126582278, -1.426524655172414 ], [ 29.813660126582278, -1.425985689655173 ], [ 29.813390632911393, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8791, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.425985689655173 ], [ 29.813660126582278, -1.426524655172414 ], [ 29.813929620253166, -1.426524655172414 ], [ 29.813929620253166, -1.425985689655173 ], [ 29.813660126582278, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8792, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.425985689655173 ], [ 29.813929620253166, -1.426524655172414 ], [ 29.814199113924051, -1.426524655172414 ], [ 29.814199113924051, -1.425985689655173 ], [ 29.813929620253166, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8793, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.42490775862069 ], [ 29.804497341772151, -1.428411034482759 ], [ 29.80476683544304, -1.428411034482759 ], [ 29.80476683544304, -1.428141551724138 ], [ 29.805036329113925, -1.428141551724138 ], [ 29.805036329113925, -1.427872068965517 ], [ 29.80476683544304, -1.427872068965517 ], [ 29.80476683544304, -1.42490775862069 ], [ 29.804497341772151, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8794, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.42490775862069 ], [ 29.80476683544304, -1.427602586206897 ], [ 29.805036329113925, -1.427602586206897 ], [ 29.805036329113925, -1.427333103448276 ], [ 29.80530582278481, -1.427333103448276 ], [ 29.80530582278481, -1.427063620689655 ], [ 29.805036329113925, -1.427063620689655 ], [ 29.805036329113925, -1.425446724137931 ], [ 29.80530582278481, -1.425446724137931 ], [ 29.80530582278481, -1.42517724137931 ], [ 29.805036329113925, -1.42517724137931 ], [ 29.805036329113925, -1.42490775862069 ], [ 29.80476683544304, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8795, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.42517724137931 ], [ 29.788058227848101, -1.426794137931034 ], [ 29.788327721518986, -1.426794137931034 ], [ 29.788327721518986, -1.42517724137931 ], [ 29.788058227848101, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8796, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.42517724137931 ], [ 29.803149873417723, -1.428141551724138 ], [ 29.803419367088608, -1.428141551724138 ], [ 29.803419367088608, -1.42517724137931 ], [ 29.803149873417723, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8797, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.42517724137931 ], [ 29.803419367088608, -1.428141551724138 ], [ 29.803688860759493, -1.428141551724138 ], [ 29.803688860759493, -1.42517724137931 ], [ 29.803419367088608, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8798, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.42517724137931 ], [ 29.803958354430382, -1.428141551724138 ], [ 29.804227848101267, -1.428141551724138 ], [ 29.804227848101267, -1.42517724137931 ], [ 29.803958354430382, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8799, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.42517724137931 ], [ 29.804227848101267, -1.428141551724138 ], [ 29.804497341772151, -1.428141551724138 ], [ 29.804497341772151, -1.42517724137931 ], [ 29.804227848101267, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8800, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814738101265824, -1.42517724137931 ], [ 29.814738101265824, -1.427063620689655 ], [ 29.815007594936709, -1.427063620689655 ], [ 29.815007594936709, -1.42517724137931 ], [ 29.814738101265824, -1.42517724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8801, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.425446724137931 ], [ 29.755988481012658, -1.427872068965517 ], [ 29.756257974683542, -1.427872068965517 ], [ 29.756257974683542, -1.425446724137931 ], [ 29.755988481012658, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8802, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.425446724137931 ], [ 29.771888607594935, -1.426794137931034 ], [ 29.772158101265823, -1.426794137931034 ], [ 29.772158101265823, -1.425446724137931 ], [ 29.771888607594935, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8803, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.425446724137931 ], [ 29.773236075949367, -1.426794137931034 ], [ 29.773505569620252, -1.426794137931034 ], [ 29.773505569620252, -1.425446724137931 ], [ 29.773236075949367, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8804, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.425446724137931 ], [ 29.802341392405065, -1.427872068965517 ], [ 29.80261088607595, -1.427872068965517 ], [ 29.80261088607595, -1.425446724137931 ], [ 29.802341392405065, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8805, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.425446724137931 ], [ 29.80261088607595, -1.427872068965517 ], [ 29.802880379746835, -1.427872068965517 ], [ 29.802880379746835, -1.425446724137931 ], [ 29.80261088607595, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8806, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.425446724137931 ], [ 29.802880379746835, -1.427872068965517 ], [ 29.803149873417723, -1.427872068965517 ], [ 29.803149873417723, -1.425446724137931 ], [ 29.802880379746835, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8807, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.425446724137931 ], [ 29.803688860759493, -1.427872068965517 ], [ 29.803958354430382, -1.427872068965517 ], [ 29.803958354430382, -1.425446724137931 ], [ 29.803688860759493, -1.425446724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8808, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.425716206896552 ], [ 29.756257974683542, -1.427872068965517 ], [ 29.756796962025316, -1.427872068965517 ], [ 29.756796962025316, -1.425716206896552 ], [ 29.756257974683542, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8809, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.425716206896552 ], [ 29.756796962025316, -1.427872068965517 ], [ 29.757066455696201, -1.427872068965517 ], [ 29.757066455696201, -1.425716206896552 ], [ 29.756796962025316, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8810, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.425716206896552 ], [ 29.774853037974683, -1.426794137931034 ], [ 29.775122531645568, -1.426794137931034 ], [ 29.775122531645568, -1.425716206896552 ], [ 29.774853037974683, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8811, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.425716206896552 ], [ 29.776200506329115, -1.426794137931034 ], [ 29.77647, -1.426794137931034 ], [ 29.77647, -1.425716206896552 ], [ 29.776200506329115, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8812, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.425716206896552 ], [ 29.777547974683543, -1.426794137931034 ], [ 29.777817468354431, -1.426794137931034 ], [ 29.777817468354431, -1.425716206896552 ], [ 29.777547974683543, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8813, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.425716206896552 ], [ 29.805036329113925, -1.426794137931034 ], [ 29.80530582278481, -1.426794137931034 ], [ 29.80530582278481, -1.425716206896552 ], [ 29.805036329113925, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8814, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.425985689655173 ], [ 29.757066455696201, -1.428141551724138 ], [ 29.757335949367089, -1.428141551724138 ], [ 29.757335949367089, -1.425985689655173 ], [ 29.757066455696201, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8815, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.425985689655173 ], [ 29.757335949367089, -1.428141551724138 ], [ 29.757605443037974, -1.428141551724138 ], [ 29.757605443037974, -1.425985689655173 ], [ 29.757335949367089, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8816, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.425985689655173 ], [ 29.757605443037974, -1.427872068965517 ], [ 29.757874936708859, -1.427872068965517 ], [ 29.757874936708859, -1.425985689655173 ], [ 29.757605443037974, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8817, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.425985689655173 ], [ 29.760030886075949, -1.426794137931034 ], [ 29.760300379746834, -1.426794137931034 ], [ 29.760300379746834, -1.425985689655173 ], [ 29.760030886075949, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8818, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.425985689655173 ], [ 29.760839367088607, -1.426794137931034 ], [ 29.761108860759492, -1.426794137931034 ], [ 29.761108860759492, -1.425985689655173 ], [ 29.760839367088607, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8819, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.425985689655173 ], [ 29.761647848101266, -1.426794137931034 ], [ 29.761917341772151, -1.426794137931034 ], [ 29.761917341772151, -1.425985689655173 ], [ 29.761647848101266, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8820, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.425985689655173 ], [ 29.762456329113924, -1.426794137931034 ], [ 29.762725822784809, -1.426794137931034 ], [ 29.762725822784809, -1.425985689655173 ], [ 29.762456329113924, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8821, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.425985689655173 ], [ 29.767576708860759, -1.426794137931034 ], [ 29.767846202531643, -1.426794137931034 ], [ 29.767846202531643, -1.425985689655173 ], [ 29.767576708860759, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8822, "El": 163 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.425985689655173 ], [ 29.771349620253165, -1.427063620689655 ], [ 29.77161911392405, -1.427063620689655 ], [ 29.77161911392405, -1.425985689655173 ], [ 29.771349620253165, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8823, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.425985689655173 ], [ 29.772158101265823, -1.428680517241379 ], [ 29.772427594936708, -1.428680517241379 ], [ 29.772427594936708, -1.425985689655173 ], [ 29.772158101265823, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8824, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.425985689655173 ], [ 29.773505569620252, -1.428680517241379 ], [ 29.77377506329114, -1.428680517241379 ], [ 29.77377506329114, -1.425985689655173 ], [ 29.773505569620252, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8825, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.425985689655173 ], [ 29.77431405063291, -1.428680517241379 ], [ 29.774853037974683, -1.428680517241379 ], [ 29.774853037974683, -1.425985689655173 ], [ 29.77431405063291, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8826, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.425985689655173 ], [ 29.775122531645568, -1.428680517241379 ], [ 29.775392025316457, -1.428680517241379 ], [ 29.775392025316457, -1.425985689655173 ], [ 29.775122531645568, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8827, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.425985689655173 ], [ 29.775931012658226, -1.428680517241379 ], [ 29.776200506329115, -1.428680517241379 ], [ 29.776200506329115, -1.425985689655173 ], [ 29.775931012658226, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8828, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.425985689655173 ], [ 29.77647, -1.428680517241379 ], [ 29.776739493670885, -1.428680517241379 ], [ 29.776739493670885, -1.425985689655173 ], [ 29.77647, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8829, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.425985689655173 ], [ 29.776739493670885, -1.428680517241379 ], [ 29.777008987341773, -1.428680517241379 ], [ 29.777008987341773, -1.425985689655173 ], [ 29.776739493670885, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8830, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.425985689655173 ], [ 29.777278481012658, -1.428680517241379 ], [ 29.777547974683543, -1.428680517241379 ], [ 29.777547974683543, -1.425985689655173 ], [ 29.777278481012658, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8831, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.425985689655173 ], [ 29.778086962025316, -1.428680517241379 ], [ 29.778356455696201, -1.428680517241379 ], [ 29.778356455696201, -1.425985689655173 ], [ 29.778086962025316, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8832, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.425985689655173 ], [ 29.77862594936709, -1.428680517241379 ], [ 29.778895443037975, -1.428680517241379 ], [ 29.778895443037975, -1.425985689655173 ], [ 29.77862594936709, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8833, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.425985689655173 ], [ 29.778895443037975, -1.426794137931034 ], [ 29.77916493670886, -1.426794137931034 ], [ 29.77916493670886, -1.425985689655173 ], [ 29.778895443037975, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8834, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.425985689655173 ], [ 29.779434430379748, -1.428680517241379 ], [ 29.779703924050633, -1.428680517241379 ], [ 29.779703924050633, -1.425985689655173 ], [ 29.779434430379748, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8835, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.425985689655173 ], [ 29.779703924050633, -1.426794137931034 ], [ 29.779973417721518, -1.426794137931034 ], [ 29.779973417721518, -1.425985689655173 ], [ 29.779703924050633, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8836, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.425985689655173 ], [ 29.780242911392406, -1.426794137931034 ], [ 29.780512405063291, -1.426794137931034 ], [ 29.780512405063291, -1.425985689655173 ], [ 29.780242911392406, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8837, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.425985689655173 ], [ 29.781051392405065, -1.426794137931034 ], [ 29.78132088607595, -1.426794137931034 ], [ 29.78132088607595, -1.425985689655173 ], [ 29.781051392405065, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8838, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.425985689655173 ], [ 29.781859873417723, -1.426794137931034 ], [ 29.782129367088608, -1.426794137931034 ], [ 29.782129367088608, -1.425985689655173 ], [ 29.781859873417723, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8839, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.425985689655173 ], [ 29.789675189873417, -1.426794137931034 ], [ 29.789944683544302, -1.426794137931034 ], [ 29.789944683544302, -1.425985689655173 ], [ 29.789675189873417, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8840, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.425985689655173 ], [ 29.790483670886076, -1.427333103448276 ], [ 29.790753164556961, -1.427333103448276 ], [ 29.790753164556961, -1.425985689655173 ], [ 29.790483670886076, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8841, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.425985689655173 ], [ 29.793987088607594, -1.428411034482759 ], [ 29.794256582278482, -1.428411034482759 ], [ 29.794256582278482, -1.425985689655173 ], [ 29.793987088607594, -1.425985689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8842, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.425716206896552 ], [ 29.814738101265824, -1.425716206896552 ], [ 29.814738101265824, -1.426794137931034 ], [ 29.814468607594936, -1.426794137931034 ], [ 29.814468607594936, -1.426524655172414 ], [ 29.814199113924051, -1.426524655172414 ], [ 29.814199113924051, -1.425985689655173 ], [ 29.814468607594936, -1.425985689655173 ], [ 29.814468607594936, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8843, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.426524655172414 ], [ 29.757874936708859, -1.428141551724138 ], [ 29.758144430379748, -1.428141551724138 ], [ 29.758144430379748, -1.426524655172414 ], [ 29.757874936708859, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8844, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.426524655172414 ], [ 29.758144430379748, -1.428141551724138 ], [ 29.758413924050632, -1.428141551724138 ], [ 29.758413924050632, -1.426524655172414 ], [ 29.758144430379748, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8845, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.426524655172414 ], [ 29.758413924050632, -1.426794137931034 ], [ 29.758683417721517, -1.426794137931034 ], [ 29.758683417721517, -1.426524655172414 ], [ 29.758413924050632, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8846, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.426524655172414 ], [ 29.758683417721517, -1.426794137931034 ], [ 29.758952911392406, -1.426794137931034 ], [ 29.758952911392406, -1.426524655172414 ], [ 29.758683417721517, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8847, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.426524655172414 ], [ 29.758952911392406, -1.427872068965517 ], [ 29.759222405063291, -1.427872068965517 ], [ 29.759222405063291, -1.426524655172414 ], [ 29.758952911392406, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8848, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.426524655172414 ], [ 29.759222405063291, -1.426794137931034 ], [ 29.759491898734176, -1.426794137931034 ], [ 29.759491898734176, -1.426524655172414 ], [ 29.759222405063291, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8849, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.426524655172414 ], [ 29.759491898734176, -1.426794137931034 ], [ 29.759761392405064, -1.426794137931034 ], [ 29.759761392405064, -1.426524655172414 ], [ 29.759491898734176, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8850, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.426524655172414 ], [ 29.759761392405064, -1.426794137931034 ], [ 29.760030886075949, -1.426794137931034 ], [ 29.760030886075949, -1.426524655172414 ], [ 29.759761392405064, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8851, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.426524655172414 ], [ 29.760300379746834, -1.426794137931034 ], [ 29.760569873417722, -1.426794137931034 ], [ 29.760569873417722, -1.426524655172414 ], [ 29.760300379746834, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8852, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.426524655172414 ], [ 29.760569873417722, -1.426794137931034 ], [ 29.760839367088607, -1.426794137931034 ], [ 29.760839367088607, -1.426524655172414 ], [ 29.760569873417722, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8853, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.426524655172414 ], [ 29.761108860759492, -1.426794137931034 ], [ 29.761378354430381, -1.426794137931034 ], [ 29.761378354430381, -1.426524655172414 ], [ 29.761108860759492, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8854, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.426524655172414 ], [ 29.761378354430381, -1.426794137931034 ], [ 29.761647848101266, -1.426794137931034 ], [ 29.761647848101266, -1.426524655172414 ], [ 29.761378354430381, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8855, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.426524655172414 ], [ 29.761917341772151, -1.427063620689655 ], [ 29.762186835443039, -1.427063620689655 ], [ 29.762186835443039, -1.426524655172414 ], [ 29.761917341772151, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8856, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.426524655172414 ], [ 29.762186835443039, -1.426794137931034 ], [ 29.762456329113924, -1.426794137931034 ], [ 29.762456329113924, -1.426524655172414 ], [ 29.762186835443039, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8857, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.426524655172414 ], [ 29.762725822784809, -1.427063620689655 ], [ 29.762995316455697, -1.427063620689655 ], [ 29.762995316455697, -1.426524655172414 ], [ 29.762725822784809, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8858, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.426524655172414 ], [ 29.762995316455697, -1.426794137931034 ], [ 29.763264810126582, -1.426794137931034 ], [ 29.763264810126582, -1.426524655172414 ], [ 29.762995316455697, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8859, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.426524655172414 ], [ 29.763264810126582, -1.426794137931034 ], [ 29.763534303797467, -1.426794137931034 ], [ 29.763534303797467, -1.426524655172414 ], [ 29.763264810126582, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8860, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.426524655172414 ], [ 29.763534303797467, -1.427063620689655 ], [ 29.763803797468356, -1.427063620689655 ], [ 29.763803797468356, -1.426524655172414 ], [ 29.763534303797467, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8861, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.426524655172414 ], [ 29.763803797468356, -1.426794137931034 ], [ 29.76407329113924, -1.426794137931034 ], [ 29.76407329113924, -1.426524655172414 ], [ 29.763803797468356, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8862, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.426524655172414 ], [ 29.76407329113924, -1.426794137931034 ], [ 29.764342784810125, -1.426794137931034 ], [ 29.764342784810125, -1.426524655172414 ], [ 29.76407329113924, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8863, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.426524655172414 ], [ 29.764342784810125, -1.426794137931034 ], [ 29.764612278481014, -1.426794137931034 ], [ 29.764612278481014, -1.426524655172414 ], [ 29.764342784810125, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8864, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.426524655172414 ], [ 29.764612278481014, -1.426794137931034 ], [ 29.764881772151899, -1.426794137931034 ], [ 29.764881772151899, -1.426524655172414 ], [ 29.764612278481014, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8865, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.426524655172414 ], [ 29.764881772151899, -1.426794137931034 ], [ 29.765151265822784, -1.426794137931034 ], [ 29.765151265822784, -1.426524655172414 ], [ 29.764881772151899, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8866, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.426524655172414 ], [ 29.765151265822784, -1.426794137931034 ], [ 29.765420759493672, -1.426794137931034 ], [ 29.765420759493672, -1.426524655172414 ], [ 29.765151265822784, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8867, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.426524655172414 ], [ 29.765420759493672, -1.426794137931034 ], [ 29.765959746835442, -1.426794137931034 ], [ 29.765959746835442, -1.426524655172414 ], [ 29.765420759493672, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8868, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.426524655172414 ], [ 29.765959746835442, -1.426794137931034 ], [ 29.766229240506327, -1.426794137931034 ], [ 29.766229240506327, -1.426524655172414 ], [ 29.765959746835442, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8869, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.426524655172414 ], [ 29.766229240506327, -1.426794137931034 ], [ 29.766498734177215, -1.426794137931034 ], [ 29.766498734177215, -1.426524655172414 ], [ 29.766229240506327, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8870, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.426524655172414 ], [ 29.766498734177215, -1.426794137931034 ], [ 29.7667682278481, -1.426794137931034 ], [ 29.7667682278481, -1.426524655172414 ], [ 29.766498734177215, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8871, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.426524655172414 ], [ 29.7667682278481, -1.426794137931034 ], [ 29.767037721518985, -1.426794137931034 ], [ 29.767037721518985, -1.426524655172414 ], [ 29.7667682278481, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8872, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.426524655172414 ], [ 29.767037721518985, -1.426794137931034 ], [ 29.767307215189874, -1.426794137931034 ], [ 29.767307215189874, -1.426524655172414 ], [ 29.767037721518985, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8873, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.426524655172414 ], [ 29.767307215189874, -1.426794137931034 ], [ 29.767576708860759, -1.426794137931034 ], [ 29.767576708860759, -1.426524655172414 ], [ 29.767307215189874, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8874, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.426524655172414 ], [ 29.767846202531643, -1.426794137931034 ], [ 29.768115696202532, -1.426794137931034 ], [ 29.768115696202532, -1.426524655172414 ], [ 29.767846202531643, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8875, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.426524655172414 ], [ 29.768115696202532, -1.426794137931034 ], [ 29.768385189873417, -1.426794137931034 ], [ 29.768385189873417, -1.426524655172414 ], [ 29.768115696202532, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8876, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.426524655172414 ], [ 29.768385189873417, -1.426794137931034 ], [ 29.768654683544302, -1.426794137931034 ], [ 29.768654683544302, -1.426524655172414 ], [ 29.768385189873417, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8877, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.426524655172414 ], [ 29.768654683544302, -1.426794137931034 ], [ 29.76892417721519, -1.426794137931034 ], [ 29.76892417721519, -1.426524655172414 ], [ 29.768654683544302, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8878, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.426524655172414 ], [ 29.76892417721519, -1.426794137931034 ], [ 29.769193670886075, -1.426794137931034 ], [ 29.769193670886075, -1.426524655172414 ], [ 29.76892417721519, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8879, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.426524655172414 ], [ 29.769193670886075, -1.426794137931034 ], [ 29.76946316455696, -1.426794137931034 ], [ 29.76946316455696, -1.426524655172414 ], [ 29.769193670886075, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8880, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.426524655172414 ], [ 29.76946316455696, -1.426794137931034 ], [ 29.769732658227849, -1.426794137931034 ], [ 29.769732658227849, -1.426524655172414 ], [ 29.76946316455696, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8881, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.426524655172414 ], [ 29.769732658227849, -1.426794137931034 ], [ 29.770002151898733, -1.426794137931034 ], [ 29.770002151898733, -1.426524655172414 ], [ 29.769732658227849, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8882, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.426524655172414 ], [ 29.770002151898733, -1.426794137931034 ], [ 29.770271645569618, -1.426794137931034 ], [ 29.770271645569618, -1.426524655172414 ], [ 29.770002151898733, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8883, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.426524655172414 ], [ 29.770271645569618, -1.426794137931034 ], [ 29.770541139240507, -1.426794137931034 ], [ 29.770541139240507, -1.426524655172414 ], [ 29.770271645569618, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8884, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.426524655172414 ], [ 29.770541139240507, -1.426794137931034 ], [ 29.770810632911392, -1.426794137931034 ], [ 29.770810632911392, -1.426524655172414 ], [ 29.770541139240507, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8885, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.426524655172414 ], [ 29.770810632911392, -1.426794137931034 ], [ 29.771080126582277, -1.426794137931034 ], [ 29.771080126582277, -1.426524655172414 ], [ 29.770810632911392, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8886, "El": 164 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.426524655172414 ], [ 29.771080126582277, -1.426794137931034 ], [ 29.771349620253165, -1.426794137931034 ], [ 29.771349620253165, -1.426524655172414 ], [ 29.771080126582277, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8887, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.426524655172414 ], [ 29.772697088607593, -1.428411034482759 ], [ 29.772966582278482, -1.428411034482759 ], [ 29.772966582278482, -1.426524655172414 ], [ 29.772697088607593, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8888, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.426524655172414 ], [ 29.774044556962025, -1.428411034482759 ], [ 29.77431405063291, -1.428411034482759 ], [ 29.77431405063291, -1.426524655172414 ], [ 29.774044556962025, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8889, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.426524655172414 ], [ 29.775661518987341, -1.428411034482759 ], [ 29.775931012658226, -1.428411034482759 ], [ 29.775931012658226, -1.426524655172414 ], [ 29.775661518987341, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8890, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.426524655172414 ], [ 29.777008987341773, -1.428411034482759 ], [ 29.777278481012658, -1.428411034482759 ], [ 29.777278481012658, -1.426524655172414 ], [ 29.777008987341773, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8891, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.426524655172414 ], [ 29.777817468354431, -1.428411034482759 ], [ 29.778086962025316, -1.428411034482759 ], [ 29.778086962025316, -1.426524655172414 ], [ 29.777817468354431, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8892, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.426524655172414 ], [ 29.778356455696201, -1.428411034482759 ], [ 29.77862594936709, -1.428411034482759 ], [ 29.77862594936709, -1.426524655172414 ], [ 29.778356455696201, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8893, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.426524655172414 ], [ 29.77916493670886, -1.428411034482759 ], [ 29.779434430379748, -1.428411034482759 ], [ 29.779434430379748, -1.426524655172414 ], [ 29.77916493670886, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8894, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.426524655172414 ], [ 29.779973417721518, -1.428411034482759 ], [ 29.780242911392406, -1.428411034482759 ], [ 29.780242911392406, -1.426524655172414 ], [ 29.779973417721518, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8895, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.426524655172414 ], [ 29.780512405063291, -1.428411034482759 ], [ 29.780781898734176, -1.428411034482759 ], [ 29.780781898734176, -1.426524655172414 ], [ 29.780512405063291, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8896, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.426524655172414 ], [ 29.780781898734176, -1.428411034482759 ], [ 29.781051392405065, -1.428411034482759 ], [ 29.781051392405065, -1.426524655172414 ], [ 29.780781898734176, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8897, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.426524655172414 ], [ 29.78132088607595, -1.428411034482759 ], [ 29.781590379746834, -1.428411034482759 ], [ 29.781590379746834, -1.426524655172414 ], [ 29.78132088607595, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8898, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.426524655172414 ], [ 29.781590379746834, -1.427063620689655 ], [ 29.781859873417723, -1.427063620689655 ], [ 29.781859873417723, -1.426524655172414 ], [ 29.781590379746834, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8899, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.426524655172414 ], [ 29.782129367088608, -1.428411034482759 ], [ 29.782398860759493, -1.428411034482759 ], [ 29.782398860759493, -1.426524655172414 ], [ 29.782129367088608, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8900, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.426524655172414 ], [ 29.782398860759493, -1.426794137931034 ], [ 29.782668354430381, -1.426794137931034 ], [ 29.782668354430381, -1.426524655172414 ], [ 29.782398860759493, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8901, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.426524655172414 ], [ 29.782668354430381, -1.426794137931034 ], [ 29.782937848101266, -1.426794137931034 ], [ 29.782937848101266, -1.426524655172414 ], [ 29.782668354430381, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8902, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.426524655172414 ], [ 29.782937848101266, -1.427063620689655 ], [ 29.783207341772151, -1.427063620689655 ], [ 29.783207341772151, -1.426524655172414 ], [ 29.782937848101266, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8903, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.426524655172414 ], [ 29.783207341772151, -1.426794137931034 ], [ 29.783476835443039, -1.426794137931034 ], [ 29.783476835443039, -1.426524655172414 ], [ 29.783207341772151, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8904, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.426524655172414 ], [ 29.783476835443039, -1.426794137931034 ], [ 29.784015822784809, -1.426794137931034 ], [ 29.784015822784809, -1.426524655172414 ], [ 29.783476835443039, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8905, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.426524655172414 ], [ 29.784015822784809, -1.426794137931034 ], [ 29.784285316455698, -1.426794137931034 ], [ 29.784285316455698, -1.426524655172414 ], [ 29.784015822784809, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8906, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.426524655172414 ], [ 29.784285316455698, -1.426794137931034 ], [ 29.784554810126583, -1.426794137931034 ], [ 29.784554810126583, -1.426524655172414 ], [ 29.784285316455698, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8907, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.426524655172414 ], [ 29.784554810126583, -1.426794137931034 ], [ 29.784824303797468, -1.426794137931034 ], [ 29.784824303797468, -1.426524655172414 ], [ 29.784554810126583, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8908, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.426524655172414 ], [ 29.784824303797468, -1.426794137931034 ], [ 29.785093797468356, -1.426794137931034 ], [ 29.785093797468356, -1.426524655172414 ], [ 29.784824303797468, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8909, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.425716206896552 ], [ 29.785632784810126, -1.425716206896552 ], [ 29.785632784810126, -1.426794137931034 ], [ 29.785093797468356, -1.426794137931034 ], [ 29.785093797468356, -1.426524655172414 ], [ 29.785363291139241, -1.426524655172414 ], [ 29.785363291139241, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8910, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.426524655172414 ], [ 29.788327721518986, -1.426794137931034 ], [ 29.788597215189874, -1.426794137931034 ], [ 29.788597215189874, -1.426524655172414 ], [ 29.788327721518986, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8911, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.426524655172414 ], [ 29.788597215189874, -1.426794137931034 ], [ 29.788866708860759, -1.426794137931034 ], [ 29.788866708860759, -1.426524655172414 ], [ 29.788597215189874, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8912, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.426524655172414 ], [ 29.788866708860759, -1.426794137931034 ], [ 29.789136202531644, -1.426794137931034 ], [ 29.789136202531644, -1.426524655172414 ], [ 29.788866708860759, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8913, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.426524655172414 ], [ 29.789136202531644, -1.427872068965517 ], [ 29.789405696202532, -1.427872068965517 ], [ 29.789405696202532, -1.426524655172414 ], [ 29.789136202531644, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8914, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.426524655172414 ], [ 29.789405696202532, -1.427872068965517 ], [ 29.789675189873417, -1.427872068965517 ], [ 29.789675189873417, -1.426524655172414 ], [ 29.789405696202532, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8915, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.426524655172414 ], [ 29.789944683544302, -1.427602586206897 ], [ 29.790214177215191, -1.427602586206897 ], [ 29.790214177215191, -1.426524655172414 ], [ 29.789944683544302, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8916, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.426524655172414 ], [ 29.790214177215191, -1.427602586206897 ], [ 29.790483670886076, -1.427602586206897 ], [ 29.790483670886076, -1.426524655172414 ], [ 29.790214177215191, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8917, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.426524655172414 ], [ 29.790753164556961, -1.427333103448276 ], [ 29.791022658227849, -1.427333103448276 ], [ 29.791022658227849, -1.426524655172414 ], [ 29.790753164556961, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8918, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.426524655172414 ], [ 29.791022658227849, -1.426794137931034 ], [ 29.791292151898734, -1.426794137931034 ], [ 29.791292151898734, -1.426524655172414 ], [ 29.791022658227849, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8919, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.426524655172414 ], [ 29.791292151898734, -1.426794137931034 ], [ 29.791561645569619, -1.426794137931034 ], [ 29.791561645569619, -1.426524655172414 ], [ 29.791292151898734, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8920, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.426524655172414 ], [ 29.791561645569619, -1.426794137931034 ], [ 29.791831139240507, -1.426794137931034 ], [ 29.791831139240507, -1.426524655172414 ], [ 29.791561645569619, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8921, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.426524655172414 ], [ 29.791831139240507, -1.426794137931034 ], [ 29.792100632911392, -1.426794137931034 ], [ 29.792100632911392, -1.426524655172414 ], [ 29.791831139240507, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8922, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.426524655172414 ], [ 29.792100632911392, -1.426794137931034 ], [ 29.792370126582277, -1.426794137931034 ], [ 29.792370126582277, -1.426524655172414 ], [ 29.792100632911392, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8923, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.426524655172414 ], [ 29.792370126582277, -1.426794137931034 ], [ 29.792909113924051, -1.426794137931034 ], [ 29.792909113924051, -1.426524655172414 ], [ 29.792370126582277, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8924, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.426524655172414 ], [ 29.792909113924051, -1.426794137931034 ], [ 29.793178607594935, -1.426794137931034 ], [ 29.793178607594935, -1.426524655172414 ], [ 29.792909113924051, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8925, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.426524655172414 ], [ 29.793178607594935, -1.426794137931034 ], [ 29.793448101265824, -1.426794137931034 ], [ 29.793448101265824, -1.426524655172414 ], [ 29.793178607594935, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8926, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.426524655172414 ], [ 29.793448101265824, -1.428411034482759 ], [ 29.793717594936709, -1.428411034482759 ], [ 29.793717594936709, -1.426524655172414 ], [ 29.793448101265824, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8927, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.426524655172414 ], [ 29.793717594936709, -1.428411034482759 ], [ 29.793987088607594, -1.428411034482759 ], [ 29.793987088607594, -1.426524655172414 ], [ 29.793717594936709, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8928, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.426524655172414 ], [ 29.799107468354432, -1.427063620689655 ], [ 29.799376962025317, -1.427063620689655 ], [ 29.799376962025317, -1.426794137931034 ], [ 29.799646455696202, -1.426794137931034 ], [ 29.799646455696202, -1.426524655172414 ], [ 29.799107468354432, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8929, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.426524655172414 ], [ 29.80530582278481, -1.426794137931034 ], [ 29.805575316455698, -1.426794137931034 ], [ 29.805575316455698, -1.426524655172414 ], [ 29.80530582278481, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8930, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.426524655172414 ], [ 29.805575316455698, -1.426794137931034 ], [ 29.805844810126583, -1.426794137931034 ], [ 29.805844810126583, -1.426524655172414 ], [ 29.805575316455698, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8931, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.426524655172414 ], [ 29.805844810126583, -1.426794137931034 ], [ 29.806114303797468, -1.426794137931034 ], [ 29.806114303797468, -1.426524655172414 ], [ 29.805844810126583, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8932, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.426524655172414 ], [ 29.806114303797468, -1.426794137931034 ], [ 29.806383797468357, -1.426794137931034 ], [ 29.806383797468357, -1.426524655172414 ], [ 29.806114303797468, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8933, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.426524655172414 ], [ 29.806383797468357, -1.426794137931034 ], [ 29.806653291139241, -1.426794137931034 ], [ 29.806653291139241, -1.426524655172414 ], [ 29.806383797468357, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8934, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.426524655172414 ], [ 29.806653291139241, -1.426794137931034 ], [ 29.806922784810126, -1.426794137931034 ], [ 29.806922784810126, -1.426524655172414 ], [ 29.806653291139241, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8935, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.426524655172414 ], [ 29.806922784810126, -1.426794137931034 ], [ 29.807192278481015, -1.426794137931034 ], [ 29.807192278481015, -1.426524655172414 ], [ 29.806922784810126, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8936, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.426524655172414 ], [ 29.807192278481015, -1.426794137931034 ], [ 29.8074617721519, -1.426794137931034 ], [ 29.8074617721519, -1.426524655172414 ], [ 29.807192278481015, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8937, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.426524655172414 ], [ 29.8074617721519, -1.426794137931034 ], [ 29.807731265822785, -1.426794137931034 ], [ 29.807731265822785, -1.426524655172414 ], [ 29.8074617721519, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8938, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.426524655172414 ], [ 29.807731265822785, -1.426794137931034 ], [ 29.808000759493673, -1.426794137931034 ], [ 29.808000759493673, -1.426524655172414 ], [ 29.807731265822785, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8939, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.426524655172414 ], [ 29.808000759493673, -1.426794137931034 ], [ 29.808270253164558, -1.426794137931034 ], [ 29.808270253164558, -1.426524655172414 ], [ 29.808000759493673, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8940, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.426524655172414 ], [ 29.808270253164558, -1.426794137931034 ], [ 29.808539746835443, -1.426794137931034 ], [ 29.808539746835443, -1.426524655172414 ], [ 29.808270253164558, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8941, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.426524655172414 ], [ 29.808539746835443, -1.426794137931034 ], [ 29.808809240506328, -1.426794137931034 ], [ 29.808809240506328, -1.426524655172414 ], [ 29.808539746835443, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8942, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.426524655172414 ], [ 29.808809240506328, -1.426794137931034 ], [ 29.809078734177216, -1.426794137931034 ], [ 29.809078734177216, -1.426524655172414 ], [ 29.808809240506328, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8943, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.426524655172414 ], [ 29.809078734177216, -1.426794137931034 ], [ 29.809348227848101, -1.426794137931034 ], [ 29.809348227848101, -1.426524655172414 ], [ 29.809078734177216, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8944, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.426524655172414 ], [ 29.809348227848101, -1.426794137931034 ], [ 29.810965189873418, -1.426794137931034 ], [ 29.810965189873418, -1.426524655172414 ], [ 29.809348227848101, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8945, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.426524655172414 ], [ 29.810965189873418, -1.426794137931034 ], [ 29.811234683544303, -1.426794137931034 ], [ 29.811234683544303, -1.426524655172414 ], [ 29.810965189873418, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.426524655172414 ], [ 29.811234683544303, -1.426794137931034 ], [ 29.812043164556961, -1.426794137931034 ], [ 29.812043164556961, -1.426524655172414 ], [ 29.811234683544303, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8947, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.426524655172414 ], [ 29.812043164556961, -1.426794137931034 ], [ 29.812312658227849, -1.426794137931034 ], [ 29.812312658227849, -1.426524655172414 ], [ 29.812043164556961, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8948, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.426524655172414 ], [ 29.812312658227849, -1.426794137931034 ], [ 29.812582151898734, -1.426794137931034 ], [ 29.812582151898734, -1.426524655172414 ], [ 29.812312658227849, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8949, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.426524655172414 ], [ 29.812582151898734, -1.426794137931034 ], [ 29.813121139240508, -1.426794137931034 ], [ 29.813121139240508, -1.426524655172414 ], [ 29.812582151898734, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8950, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.426524655172414 ], [ 29.813121139240508, -1.426794137931034 ], [ 29.813390632911393, -1.426794137931034 ], [ 29.813390632911393, -1.426524655172414 ], [ 29.813121139240508, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8951, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.426524655172414 ], [ 29.813390632911393, -1.426794137931034 ], [ 29.813660126582278, -1.426794137931034 ], [ 29.813660126582278, -1.426524655172414 ], [ 29.813390632911393, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8952, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.426524655172414 ], [ 29.813660126582278, -1.426794137931034 ], [ 29.813929620253166, -1.426794137931034 ], [ 29.813929620253166, -1.426524655172414 ], [ 29.813660126582278, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8953, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.426524655172414 ], [ 29.813929620253166, -1.426794137931034 ], [ 29.814199113924051, -1.426794137931034 ], [ 29.814199113924051, -1.426524655172414 ], [ 29.813929620253166, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8954, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.426524655172414 ], [ 29.814199113924051, -1.426794137931034 ], [ 29.814468607594936, -1.426794137931034 ], [ 29.814468607594936, -1.426524655172414 ], [ 29.814199113924051, -1.426524655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8955, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.426794137931034 ], [ 29.758413924050632, -1.428411034482759 ], [ 29.758683417721517, -1.428411034482759 ], [ 29.758683417721517, -1.426794137931034 ], [ 29.758413924050632, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8956, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.426794137931034 ], [ 29.758683417721517, -1.428141551724138 ], [ 29.758952911392406, -1.428141551724138 ], [ 29.758952911392406, -1.426794137931034 ], [ 29.758683417721517, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8957, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.426794137931034 ], [ 29.759222405063291, -1.428411034482759 ], [ 29.759491898734176, -1.428411034482759 ], [ 29.759491898734176, -1.426794137931034 ], [ 29.759222405063291, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8958, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.426794137931034 ], [ 29.759491898734176, -1.428141551724138 ], [ 29.759761392405064, -1.428141551724138 ], [ 29.759761392405064, -1.426794137931034 ], [ 29.759491898734176, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8959, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.426794137931034 ], [ 29.759761392405064, -1.427063620689655 ], [ 29.760030886075949, -1.427063620689655 ], [ 29.760030886075949, -1.426794137931034 ], [ 29.759761392405064, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8960, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.426794137931034 ], [ 29.760030886075949, -1.428411034482759 ], [ 29.760300379746834, -1.428411034482759 ], [ 29.760300379746834, -1.426794137931034 ], [ 29.760030886075949, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8961, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.426794137931034 ], [ 29.760300379746834, -1.428141551724138 ], [ 29.760569873417722, -1.428141551724138 ], [ 29.760569873417722, -1.426794137931034 ], [ 29.760300379746834, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8962, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.426794137931034 ], [ 29.760569873417722, -1.427063620689655 ], [ 29.760839367088607, -1.427063620689655 ], [ 29.760839367088607, -1.426794137931034 ], [ 29.760569873417722, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8963, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.426794137931034 ], [ 29.760839367088607, -1.427063620689655 ], [ 29.761108860759492, -1.427063620689655 ], [ 29.761108860759492, -1.426794137931034 ], [ 29.760839367088607, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8964, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.426794137931034 ], [ 29.761108860759492, -1.427333103448276 ], [ 29.761378354430381, -1.427333103448276 ], [ 29.761378354430381, -1.426794137931034 ], [ 29.761108860759492, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8965, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.426794137931034 ], [ 29.761378354430381, -1.427063620689655 ], [ 29.761647848101266, -1.427063620689655 ], [ 29.761647848101266, -1.426794137931034 ], [ 29.761378354430381, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8966, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.426794137931034 ], [ 29.761647848101266, -1.427063620689655 ], [ 29.761917341772151, -1.427063620689655 ], [ 29.761917341772151, -1.426794137931034 ], [ 29.761647848101266, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8967, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.426794137931034 ], [ 29.762186835443039, -1.427063620689655 ], [ 29.762456329113924, -1.427063620689655 ], [ 29.762456329113924, -1.426794137931034 ], [ 29.762186835443039, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8968, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.426794137931034 ], [ 29.762456329113924, -1.427063620689655 ], [ 29.762725822784809, -1.427063620689655 ], [ 29.762725822784809, -1.426794137931034 ], [ 29.762456329113924, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8969, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.426794137931034 ], [ 29.762995316455697, -1.427333103448276 ], [ 29.763264810126582, -1.427333103448276 ], [ 29.763264810126582, -1.426794137931034 ], [ 29.762995316455697, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8970, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.426794137931034 ], [ 29.763264810126582, -1.427063620689655 ], [ 29.763534303797467, -1.427063620689655 ], [ 29.763534303797467, -1.426794137931034 ], [ 29.763264810126582, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8971, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.426794137931034 ], [ 29.763803797468356, -1.427333103448276 ], [ 29.76407329113924, -1.427333103448276 ], [ 29.76407329113924, -1.426794137931034 ], [ 29.763803797468356, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8972, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.426794137931034 ], [ 29.76407329113924, -1.427063620689655 ], [ 29.764342784810125, -1.427063620689655 ], [ 29.764342784810125, -1.426794137931034 ], [ 29.76407329113924, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8973, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.426794137931034 ], [ 29.764342784810125, -1.427063620689655 ], [ 29.764612278481014, -1.427063620689655 ], [ 29.764612278481014, -1.426794137931034 ], [ 29.764342784810125, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8974, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.426794137931034 ], [ 29.764612278481014, -1.427063620689655 ], [ 29.764881772151899, -1.427063620689655 ], [ 29.764881772151899, -1.426794137931034 ], [ 29.764612278481014, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8975, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.426794137931034 ], [ 29.764881772151899, -1.427063620689655 ], [ 29.765151265822784, -1.427063620689655 ], [ 29.765151265822784, -1.426794137931034 ], [ 29.764881772151899, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8976, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.426794137931034 ], [ 29.765151265822784, -1.427063620689655 ], [ 29.765420759493672, -1.427063620689655 ], [ 29.765420759493672, -1.426794137931034 ], [ 29.765151265822784, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8977, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.426794137931034 ], [ 29.765420759493672, -1.427333103448276 ], [ 29.765959746835442, -1.427333103448276 ], [ 29.765959746835442, -1.426794137931034 ], [ 29.765420759493672, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8978, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.426794137931034 ], [ 29.765959746835442, -1.427063620689655 ], [ 29.766229240506327, -1.427063620689655 ], [ 29.766229240506327, -1.426794137931034 ], [ 29.765959746835442, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8979, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.426794137931034 ], [ 29.766229240506327, -1.427063620689655 ], [ 29.766498734177215, -1.427063620689655 ], [ 29.766498734177215, -1.426794137931034 ], [ 29.766229240506327, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8980, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.426794137931034 ], [ 29.766498734177215, -1.427063620689655 ], [ 29.7667682278481, -1.427063620689655 ], [ 29.7667682278481, -1.426794137931034 ], [ 29.766498734177215, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8981, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.426794137931034 ], [ 29.7667682278481, -1.427063620689655 ], [ 29.767037721518985, -1.427063620689655 ], [ 29.767037721518985, -1.426794137931034 ], [ 29.7667682278481, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8982, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.426794137931034 ], [ 29.767037721518985, -1.427063620689655 ], [ 29.767307215189874, -1.427063620689655 ], [ 29.767307215189874, -1.426794137931034 ], [ 29.767037721518985, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8983, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.426794137931034 ], [ 29.767307215189874, -1.427063620689655 ], [ 29.767576708860759, -1.427063620689655 ], [ 29.767576708860759, -1.426794137931034 ], [ 29.767307215189874, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8984, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.426794137931034 ], [ 29.767576708860759, -1.427063620689655 ], [ 29.767846202531643, -1.427063620689655 ], [ 29.767846202531643, -1.426794137931034 ], [ 29.767576708860759, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8985, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.426794137931034 ], [ 29.767846202531643, -1.427063620689655 ], [ 29.768115696202532, -1.427063620689655 ], [ 29.768115696202532, -1.426794137931034 ], [ 29.767846202531643, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8986, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.426794137931034 ], [ 29.768115696202532, -1.427063620689655 ], [ 29.768385189873417, -1.427063620689655 ], [ 29.768385189873417, -1.426794137931034 ], [ 29.768115696202532, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8987, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.426794137931034 ], [ 29.768385189873417, -1.427063620689655 ], [ 29.768654683544302, -1.427063620689655 ], [ 29.768654683544302, -1.426794137931034 ], [ 29.768385189873417, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8988, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.426794137931034 ], [ 29.768654683544302, -1.427063620689655 ], [ 29.76892417721519, -1.427063620689655 ], [ 29.76892417721519, -1.426794137931034 ], [ 29.768654683544302, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8989, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.426794137931034 ], [ 29.76892417721519, -1.427063620689655 ], [ 29.769193670886075, -1.427063620689655 ], [ 29.769193670886075, -1.426794137931034 ], [ 29.76892417721519, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8990, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.426794137931034 ], [ 29.769193670886075, -1.427063620689655 ], [ 29.76946316455696, -1.427063620689655 ], [ 29.76946316455696, -1.426794137931034 ], [ 29.769193670886075, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8991, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.426794137931034 ], [ 29.76946316455696, -1.427063620689655 ], [ 29.769732658227849, -1.427063620689655 ], [ 29.769732658227849, -1.426794137931034 ], [ 29.76946316455696, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8992, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.426794137931034 ], [ 29.769732658227849, -1.427063620689655 ], [ 29.770002151898733, -1.427063620689655 ], [ 29.770002151898733, -1.426794137931034 ], [ 29.769732658227849, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8993, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.426794137931034 ], [ 29.770002151898733, -1.427063620689655 ], [ 29.770271645569618, -1.427063620689655 ], [ 29.770271645569618, -1.426794137931034 ], [ 29.770002151898733, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8994, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.426794137931034 ], [ 29.770271645569618, -1.427063620689655 ], [ 29.770541139240507, -1.427063620689655 ], [ 29.770541139240507, -1.426794137931034 ], [ 29.770271645569618, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8995, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.426794137931034 ], [ 29.770541139240507, -1.427063620689655 ], [ 29.770810632911392, -1.427063620689655 ], [ 29.770810632911392, -1.426794137931034 ], [ 29.770541139240507, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8996, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.426794137931034 ], [ 29.770810632911392, -1.427063620689655 ], [ 29.771080126582277, -1.427063620689655 ], [ 29.771080126582277, -1.426794137931034 ], [ 29.770810632911392, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8997, "El": 161 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.426794137931034 ], [ 29.771080126582277, -1.427063620689655 ], [ 29.771349620253165, -1.427063620689655 ], [ 29.771349620253165, -1.426794137931034 ], [ 29.771080126582277, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8998, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.426794137931034 ], [ 29.771888607594935, -1.428141551724138 ], [ 29.772158101265823, -1.428141551724138 ], [ 29.772158101265823, -1.426794137931034 ], [ 29.771888607594935, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 8999, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.426794137931034 ], [ 29.773236075949367, -1.428141551724138 ], [ 29.773505569620252, -1.428141551724138 ], [ 29.773505569620252, -1.426794137931034 ], [ 29.773236075949367, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9000, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.426794137931034 ], [ 29.774853037974683, -1.428141551724138 ], [ 29.775122531645568, -1.428141551724138 ], [ 29.775122531645568, -1.426794137931034 ], [ 29.774853037974683, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9001, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.426794137931034 ], [ 29.776200506329115, -1.428141551724138 ], [ 29.77647, -1.428141551724138 ], [ 29.77647, -1.426794137931034 ], [ 29.776200506329115, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9002, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.426794137931034 ], [ 29.777547974683543, -1.428141551724138 ], [ 29.777817468354431, -1.428141551724138 ], [ 29.777817468354431, -1.426794137931034 ], [ 29.777547974683543, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9003, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.426794137931034 ], [ 29.778895443037975, -1.428141551724138 ], [ 29.77916493670886, -1.428141551724138 ], [ 29.77916493670886, -1.426794137931034 ], [ 29.778895443037975, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9004, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.426794137931034 ], [ 29.779703924050633, -1.428141551724138 ], [ 29.779973417721518, -1.428141551724138 ], [ 29.779973417721518, -1.426794137931034 ], [ 29.779703924050633, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9005, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.426794137931034 ], [ 29.780242911392406, -1.428141551724138 ], [ 29.780512405063291, -1.428141551724138 ], [ 29.780512405063291, -1.426794137931034 ], [ 29.780242911392406, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9006, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.426794137931034 ], [ 29.781051392405065, -1.428141551724138 ], [ 29.78132088607595, -1.428141551724138 ], [ 29.78132088607595, -1.426794137931034 ], [ 29.781051392405065, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9007, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.426794137931034 ], [ 29.781859873417723, -1.428141551724138 ], [ 29.782129367088608, -1.428141551724138 ], [ 29.782129367088608, -1.426794137931034 ], [ 29.781859873417723, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9008, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.426794137931034 ], [ 29.782398860759493, -1.428141551724138 ], [ 29.782668354430381, -1.428141551724138 ], [ 29.782668354430381, -1.426794137931034 ], [ 29.782398860759493, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9009, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.426794137931034 ], [ 29.782668354430381, -1.428141551724138 ], [ 29.782937848101266, -1.428141551724138 ], [ 29.782937848101266, -1.426794137931034 ], [ 29.782668354430381, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9010, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.426794137931034 ], [ 29.783207341772151, -1.428141551724138 ], [ 29.783476835443039, -1.428141551724138 ], [ 29.783476835443039, -1.426794137931034 ], [ 29.783207341772151, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9011, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.426794137931034 ], [ 29.783476835443039, -1.428141551724138 ], [ 29.784015822784809, -1.428141551724138 ], [ 29.784015822784809, -1.426794137931034 ], [ 29.783476835443039, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9012, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.426794137931034 ], [ 29.784015822784809, -1.427063620689655 ], [ 29.784285316455698, -1.427063620689655 ], [ 29.784285316455698, -1.426794137931034 ], [ 29.784015822784809, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9013, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.426794137931034 ], [ 29.784285316455698, -1.427063620689655 ], [ 29.784554810126583, -1.427063620689655 ], [ 29.784554810126583, -1.426794137931034 ], [ 29.784285316455698, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9014, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.426794137931034 ], [ 29.784554810126583, -1.427063620689655 ], [ 29.784824303797468, -1.427063620689655 ], [ 29.784824303797468, -1.426794137931034 ], [ 29.784554810126583, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9015, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.426794137931034 ], [ 29.784824303797468, -1.427063620689655 ], [ 29.785093797468356, -1.427063620689655 ], [ 29.785093797468356, -1.426794137931034 ], [ 29.784824303797468, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9016, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.426794137931034 ], [ 29.788327721518986, -1.428411034482759 ], [ 29.788597215189874, -1.428411034482759 ], [ 29.788597215189874, -1.426794137931034 ], [ 29.788327721518986, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9017, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.426794137931034 ], [ 29.788597215189874, -1.428141551724138 ], [ 29.788866708860759, -1.428141551724138 ], [ 29.788866708860759, -1.426794137931034 ], [ 29.788597215189874, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9018, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.426794137931034 ], [ 29.788866708860759, -1.428141551724138 ], [ 29.789136202531644, -1.428141551724138 ], [ 29.789136202531644, -1.426794137931034 ], [ 29.788866708860759, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9019, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.426794137931034 ], [ 29.789675189873417, -1.427872068965517 ], [ 29.789944683544302, -1.427872068965517 ], [ 29.789944683544302, -1.426794137931034 ], [ 29.789675189873417, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9020, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.426794137931034 ], [ 29.791022658227849, -1.427063620689655 ], [ 29.791292151898734, -1.427063620689655 ], [ 29.791292151898734, -1.426794137931034 ], [ 29.791022658227849, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9021, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.426794137931034 ], [ 29.791292151898734, -1.427063620689655 ], [ 29.791561645569619, -1.427063620689655 ], [ 29.791561645569619, -1.426794137931034 ], [ 29.791292151898734, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9022, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.426794137931034 ], [ 29.791561645569619, -1.427063620689655 ], [ 29.791831139240507, -1.427063620689655 ], [ 29.791831139240507, -1.426794137931034 ], [ 29.791561645569619, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9023, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.426794137931034 ], [ 29.791831139240507, -1.427063620689655 ], [ 29.792100632911392, -1.427063620689655 ], [ 29.792100632911392, -1.426794137931034 ], [ 29.791831139240507, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9024, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.426794137931034 ], [ 29.792100632911392, -1.427063620689655 ], [ 29.792370126582277, -1.427063620689655 ], [ 29.792370126582277, -1.426794137931034 ], [ 29.792100632911392, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9025, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.426794137931034 ], [ 29.792370126582277, -1.428411034482759 ], [ 29.792909113924051, -1.428411034482759 ], [ 29.792909113924051, -1.426794137931034 ], [ 29.792370126582277, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9026, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.426794137931034 ], [ 29.792909113924051, -1.428680517241379 ], [ 29.793178607594935, -1.428680517241379 ], [ 29.793178607594935, -1.426794137931034 ], [ 29.792909113924051, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9027, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.426794137931034 ], [ 29.793178607594935, -1.428680517241379 ], [ 29.793448101265824, -1.428680517241379 ], [ 29.793448101265824, -1.426794137931034 ], [ 29.793178607594935, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9028, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.426794137931034 ], [ 29.799376962025317, -1.427063620689655 ], [ 29.799646455696202, -1.427063620689655 ], [ 29.799646455696202, -1.426794137931034 ], [ 29.799376962025317, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9029, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.426794137931034 ], [ 29.799646455696202, -1.427063620689655 ], [ 29.800185443037975, -1.427063620689655 ], [ 29.800185443037975, -1.426794137931034 ], [ 29.799646455696202, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9030, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.426794137931034 ], [ 29.805036329113925, -1.427063620689655 ], [ 29.80530582278481, -1.427063620689655 ], [ 29.80530582278481, -1.426794137931034 ], [ 29.805036329113925, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9031, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.426794137931034 ], [ 29.80530582278481, -1.427063620689655 ], [ 29.805575316455698, -1.427063620689655 ], [ 29.805575316455698, -1.426794137931034 ], [ 29.80530582278481, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9032, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.426794137931034 ], [ 29.805575316455698, -1.427063620689655 ], [ 29.805844810126583, -1.427063620689655 ], [ 29.805844810126583, -1.426794137931034 ], [ 29.805575316455698, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9033, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.426794137931034 ], [ 29.805844810126583, -1.427063620689655 ], [ 29.806114303797468, -1.427063620689655 ], [ 29.806114303797468, -1.426794137931034 ], [ 29.805844810126583, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9034, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.426794137931034 ], [ 29.806114303797468, -1.427063620689655 ], [ 29.806383797468357, -1.427063620689655 ], [ 29.806383797468357, -1.426794137931034 ], [ 29.806114303797468, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9035, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.426794137931034 ], [ 29.806383797468357, -1.427063620689655 ], [ 29.806653291139241, -1.427063620689655 ], [ 29.806653291139241, -1.426794137931034 ], [ 29.806383797468357, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9036, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.426794137931034 ], [ 29.806653291139241, -1.427063620689655 ], [ 29.806922784810126, -1.427063620689655 ], [ 29.806922784810126, -1.426794137931034 ], [ 29.806653291139241, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9037, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.426794137931034 ], [ 29.806922784810126, -1.427063620689655 ], [ 29.807192278481015, -1.427063620689655 ], [ 29.807192278481015, -1.426794137931034 ], [ 29.806922784810126, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9038, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.426794137931034 ], [ 29.807192278481015, -1.427063620689655 ], [ 29.8074617721519, -1.427063620689655 ], [ 29.8074617721519, -1.426794137931034 ], [ 29.807192278481015, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9039, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.426794137931034 ], [ 29.8074617721519, -1.427063620689655 ], [ 29.807731265822785, -1.427063620689655 ], [ 29.807731265822785, -1.426794137931034 ], [ 29.8074617721519, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9040, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.426794137931034 ], [ 29.807731265822785, -1.427063620689655 ], [ 29.808000759493673, -1.427063620689655 ], [ 29.808000759493673, -1.426794137931034 ], [ 29.807731265822785, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9041, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.426794137931034 ], [ 29.808000759493673, -1.427063620689655 ], [ 29.808270253164558, -1.427063620689655 ], [ 29.808270253164558, -1.426794137931034 ], [ 29.808000759493673, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9042, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.426794137931034 ], [ 29.808270253164558, -1.427063620689655 ], [ 29.808539746835443, -1.427063620689655 ], [ 29.808539746835443, -1.426794137931034 ], [ 29.808270253164558, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9043, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.426794137931034 ], [ 29.808539746835443, -1.427063620689655 ], [ 29.808809240506328, -1.427063620689655 ], [ 29.808809240506328, -1.426794137931034 ], [ 29.808539746835443, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9044, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.426794137931034 ], [ 29.808809240506328, -1.427063620689655 ], [ 29.809078734177216, -1.427063620689655 ], [ 29.809078734177216, -1.426794137931034 ], [ 29.808809240506328, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9045, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.426794137931034 ], [ 29.809078734177216, -1.427063620689655 ], [ 29.809348227848101, -1.427063620689655 ], [ 29.809348227848101, -1.426794137931034 ], [ 29.809078734177216, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9046, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.426794137931034 ], [ 29.809348227848101, -1.427063620689655 ], [ 29.810965189873418, -1.427063620689655 ], [ 29.810965189873418, -1.426794137931034 ], [ 29.809348227848101, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9047, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.426794137931034 ], [ 29.810965189873418, -1.427063620689655 ], [ 29.811234683544303, -1.427063620689655 ], [ 29.811234683544303, -1.426794137931034 ], [ 29.810965189873418, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9048, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.426794137931034 ], [ 29.811234683544303, -1.427063620689655 ], [ 29.812043164556961, -1.427063620689655 ], [ 29.812043164556961, -1.426794137931034 ], [ 29.811234683544303, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9049, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.426794137931034 ], [ 29.812043164556961, -1.427063620689655 ], [ 29.812312658227849, -1.427063620689655 ], [ 29.812312658227849, -1.426794137931034 ], [ 29.812043164556961, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9050, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.426794137931034 ], [ 29.812312658227849, -1.427063620689655 ], [ 29.812582151898734, -1.427063620689655 ], [ 29.812582151898734, -1.426794137931034 ], [ 29.812312658227849, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9051, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.426794137931034 ], [ 29.812582151898734, -1.427063620689655 ], [ 29.812851645569619, -1.427063620689655 ], [ 29.812851645569619, -1.426794137931034 ], [ 29.812582151898734, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9052, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.426794137931034 ], [ 29.812851645569619, -1.427063620689655 ], [ 29.813121139240508, -1.427063620689655 ], [ 29.813121139240508, -1.426794137931034 ], [ 29.812851645569619, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9053, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.426794137931034 ], [ 29.813121139240508, -1.427063620689655 ], [ 29.813390632911393, -1.427063620689655 ], [ 29.813390632911393, -1.426794137931034 ], [ 29.813121139240508, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9054, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.426794137931034 ], [ 29.813390632911393, -1.427063620689655 ], [ 29.813660126582278, -1.427063620689655 ], [ 29.813660126582278, -1.426794137931034 ], [ 29.813390632911393, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9055, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.426794137931034 ], [ 29.813660126582278, -1.427063620689655 ], [ 29.813929620253166, -1.427063620689655 ], [ 29.813929620253166, -1.426794137931034 ], [ 29.813660126582278, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9056, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.426794137931034 ], [ 29.813929620253166, -1.427063620689655 ], [ 29.814468607594936, -1.427063620689655 ], [ 29.814468607594936, -1.426794137931034 ], [ 29.813929620253166, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9057, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.426794137931034 ], [ 29.814468607594936, -1.427063620689655 ], [ 29.814738101265824, -1.427063620689655 ], [ 29.814738101265824, -1.426794137931034 ], [ 29.814468607594936, -1.426794137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9058, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.427063620689655 ], [ 29.759761392405064, -1.428411034482759 ], [ 29.760030886075949, -1.428411034482759 ], [ 29.760030886075949, -1.427063620689655 ], [ 29.759761392405064, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9059, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.427063620689655 ], [ 29.760569873417722, -1.428411034482759 ], [ 29.760839367088607, -1.428411034482759 ], [ 29.760839367088607, -1.427063620689655 ], [ 29.760569873417722, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9060, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.427063620689655 ], [ 29.760839367088607, -1.428141551724138 ], [ 29.761108860759492, -1.428141551724138 ], [ 29.761108860759492, -1.427063620689655 ], [ 29.760839367088607, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9061, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.427063620689655 ], [ 29.761378354430381, -1.428411034482759 ], [ 29.761647848101266, -1.428411034482759 ], [ 29.761647848101266, -1.427063620689655 ], [ 29.761378354430381, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9062, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.427063620689655 ], [ 29.761647848101266, -1.428141551724138 ], [ 29.761917341772151, -1.428141551724138 ], [ 29.761917341772151, -1.427063620689655 ], [ 29.761647848101266, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9063, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.427063620689655 ], [ 29.761917341772151, -1.427333103448276 ], [ 29.762186835443039, -1.427333103448276 ], [ 29.762186835443039, -1.427063620689655 ], [ 29.761917341772151, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9064, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.427063620689655 ], [ 29.762186835443039, -1.428411034482759 ], [ 29.762456329113924, -1.428411034482759 ], [ 29.762456329113924, -1.427063620689655 ], [ 29.762186835443039, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9065, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.427063620689655 ], [ 29.762456329113924, -1.427333103448276 ], [ 29.762725822784809, -1.427333103448276 ], [ 29.762725822784809, -1.427063620689655 ], [ 29.762456329113924, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9066, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.427063620689655 ], [ 29.762725822784809, -1.427333103448276 ], [ 29.762995316455697, -1.427333103448276 ], [ 29.762995316455697, -1.427063620689655 ], [ 29.762725822784809, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9067, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.427063620689655 ], [ 29.763264810126582, -1.427333103448276 ], [ 29.763534303797467, -1.427333103448276 ], [ 29.763534303797467, -1.427063620689655 ], [ 29.763264810126582, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9068, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.427063620689655 ], [ 29.763534303797467, -1.427333103448276 ], [ 29.763803797468356, -1.427333103448276 ], [ 29.763803797468356, -1.427063620689655 ], [ 29.763534303797467, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9069, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.427063620689655 ], [ 29.76407329113924, -1.427602586206897 ], [ 29.764342784810125, -1.427602586206897 ], [ 29.764342784810125, -1.427063620689655 ], [ 29.76407329113924, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9070, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.427063620689655 ], [ 29.764342784810125, -1.427333103448276 ], [ 29.764612278481014, -1.427333103448276 ], [ 29.764612278481014, -1.427063620689655 ], [ 29.764342784810125, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9071, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.427063620689655 ], [ 29.764612278481014, -1.427333103448276 ], [ 29.764881772151899, -1.427333103448276 ], [ 29.764881772151899, -1.427063620689655 ], [ 29.764612278481014, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9072, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.427063620689655 ], [ 29.764881772151899, -1.427333103448276 ], [ 29.765151265822784, -1.427333103448276 ], [ 29.765151265822784, -1.427063620689655 ], [ 29.764881772151899, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9073, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.427063620689655 ], [ 29.765151265822784, -1.427333103448276 ], [ 29.765420759493672, -1.427333103448276 ], [ 29.765420759493672, -1.427063620689655 ], [ 29.765151265822784, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9074, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.427063620689655 ], [ 29.765959746835442, -1.427333103448276 ], [ 29.766229240506327, -1.427333103448276 ], [ 29.766229240506327, -1.427063620689655 ], [ 29.765959746835442, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9075, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.427063620689655 ], [ 29.766229240506327, -1.427333103448276 ], [ 29.766498734177215, -1.427333103448276 ], [ 29.766498734177215, -1.427063620689655 ], [ 29.766229240506327, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9076, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.427063620689655 ], [ 29.766498734177215, -1.427333103448276 ], [ 29.7667682278481, -1.427333103448276 ], [ 29.7667682278481, -1.427063620689655 ], [ 29.766498734177215, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9077, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.427063620689655 ], [ 29.7667682278481, -1.427333103448276 ], [ 29.767037721518985, -1.427333103448276 ], [ 29.767037721518985, -1.427063620689655 ], [ 29.7667682278481, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9078, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.427063620689655 ], [ 29.767037721518985, -1.427333103448276 ], [ 29.767307215189874, -1.427333103448276 ], [ 29.767307215189874, -1.427063620689655 ], [ 29.767037721518985, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9079, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.427063620689655 ], [ 29.767307215189874, -1.427333103448276 ], [ 29.767576708860759, -1.427333103448276 ], [ 29.767576708860759, -1.427063620689655 ], [ 29.767307215189874, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9080, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.427063620689655 ], [ 29.767576708860759, -1.427333103448276 ], [ 29.767846202531643, -1.427333103448276 ], [ 29.767846202531643, -1.427063620689655 ], [ 29.767576708860759, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9081, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.427063620689655 ], [ 29.767846202531643, -1.427333103448276 ], [ 29.768115696202532, -1.427333103448276 ], [ 29.768115696202532, -1.427063620689655 ], [ 29.767846202531643, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9082, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.427063620689655 ], [ 29.768115696202532, -1.427333103448276 ], [ 29.768385189873417, -1.427333103448276 ], [ 29.768385189873417, -1.427063620689655 ], [ 29.768115696202532, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9083, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.427063620689655 ], [ 29.768385189873417, -1.427333103448276 ], [ 29.768654683544302, -1.427333103448276 ], [ 29.768654683544302, -1.427063620689655 ], [ 29.768385189873417, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9084, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.427063620689655 ], [ 29.768654683544302, -1.427333103448276 ], [ 29.76892417721519, -1.427333103448276 ], [ 29.76892417721519, -1.427063620689655 ], [ 29.768654683544302, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9085, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.427063620689655 ], [ 29.76892417721519, -1.427333103448276 ], [ 29.769193670886075, -1.427333103448276 ], [ 29.769193670886075, -1.427063620689655 ], [ 29.76892417721519, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9086, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.427063620689655 ], [ 29.769193670886075, -1.427333103448276 ], [ 29.76946316455696, -1.427333103448276 ], [ 29.76946316455696, -1.427063620689655 ], [ 29.769193670886075, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9087, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.427063620689655 ], [ 29.76946316455696, -1.427333103448276 ], [ 29.769732658227849, -1.427333103448276 ], [ 29.769732658227849, -1.427063620689655 ], [ 29.76946316455696, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9088, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.427063620689655 ], [ 29.769732658227849, -1.427333103448276 ], [ 29.770002151898733, -1.427333103448276 ], [ 29.770002151898733, -1.427063620689655 ], [ 29.769732658227849, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9089, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.427063620689655 ], [ 29.770002151898733, -1.427333103448276 ], [ 29.770271645569618, -1.427333103448276 ], [ 29.770271645569618, -1.427063620689655 ], [ 29.770002151898733, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9090, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.427063620689655 ], [ 29.770271645569618, -1.427333103448276 ], [ 29.770541139240507, -1.427333103448276 ], [ 29.770541139240507, -1.427063620689655 ], [ 29.770271645569618, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9091, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.427063620689655 ], [ 29.770541139240507, -1.427333103448276 ], [ 29.770810632911392, -1.427333103448276 ], [ 29.770810632911392, -1.427063620689655 ], [ 29.770541139240507, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9092, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.427063620689655 ], [ 29.770810632911392, -1.427333103448276 ], [ 29.771080126582277, -1.427333103448276 ], [ 29.771080126582277, -1.427063620689655 ], [ 29.770810632911392, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9093, "El": 159 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.427063620689655 ], [ 29.771080126582277, -1.427333103448276 ], [ 29.771349620253165, -1.427333103448276 ], [ 29.771349620253165, -1.427063620689655 ], [ 29.771080126582277, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9094, "El": 162 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.427063620689655 ], [ 29.771349620253165, -1.427333103448276 ], [ 29.77161911392405, -1.427333103448276 ], [ 29.77161911392405, -1.427063620689655 ], [ 29.771349620253165, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9095, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.427063620689655 ], [ 29.781590379746834, -1.427872068965517 ], [ 29.781859873417723, -1.427872068965517 ], [ 29.781859873417723, -1.427063620689655 ], [ 29.781590379746834, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9096, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.427063620689655 ], [ 29.782937848101266, -1.427872068965517 ], [ 29.783207341772151, -1.427872068965517 ], [ 29.783207341772151, -1.427063620689655 ], [ 29.782937848101266, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9097, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.427063620689655 ], [ 29.784015822784809, -1.427872068965517 ], [ 29.784285316455698, -1.427872068965517 ], [ 29.784285316455698, -1.427063620689655 ], [ 29.784015822784809, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9098, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.427063620689655 ], [ 29.784285316455698, -1.427872068965517 ], [ 29.784554810126583, -1.427872068965517 ], [ 29.784554810126583, -1.427063620689655 ], [ 29.784285316455698, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9099, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.427063620689655 ], [ 29.784554810126583, -1.427872068965517 ], [ 29.784824303797468, -1.427872068965517 ], [ 29.784824303797468, -1.427063620689655 ], [ 29.784554810126583, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9100, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.427063620689655 ], [ 29.791022658227849, -1.427602586206897 ], [ 29.791292151898734, -1.427602586206897 ], [ 29.791292151898734, -1.427063620689655 ], [ 29.791022658227849, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9101, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.427063620689655 ], [ 29.791292151898734, -1.427333103448276 ], [ 29.791561645569619, -1.427333103448276 ], [ 29.791561645569619, -1.427063620689655 ], [ 29.791292151898734, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9102, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.427063620689655 ], [ 29.791561645569619, -1.427333103448276 ], [ 29.791831139240507, -1.427333103448276 ], [ 29.791831139240507, -1.427063620689655 ], [ 29.791561645569619, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9103, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.427063620689655 ], [ 29.791831139240507, -1.428680517241379 ], [ 29.792100632911392, -1.428680517241379 ], [ 29.792100632911392, -1.427063620689655 ], [ 29.791831139240507, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9104, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.427063620689655 ], [ 29.792100632911392, -1.428680517241379 ], [ 29.792370126582277, -1.428680517241379 ], [ 29.792370126582277, -1.427063620689655 ], [ 29.792100632911392, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9105, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.427063620689655 ], [ 29.798837974683543, -1.427333103448276 ], [ 29.799107468354432, -1.427333103448276 ], [ 29.799107468354432, -1.427063620689655 ], [ 29.798837974683543, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9106, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.427063620689655 ], [ 29.799107468354432, -1.427333103448276 ], [ 29.799376962025317, -1.427333103448276 ], [ 29.799376962025317, -1.427063620689655 ], [ 29.799107468354432, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9107, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.427063620689655 ], [ 29.799376962025317, -1.427333103448276 ], [ 29.799646455696202, -1.427333103448276 ], [ 29.799646455696202, -1.427063620689655 ], [ 29.799376962025317, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9108, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.427063620689655 ], [ 29.799646455696202, -1.427333103448276 ], [ 29.79991594936709, -1.427333103448276 ], [ 29.79991594936709, -1.427063620689655 ], [ 29.799646455696202, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9109, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.427063620689655 ], [ 29.79991594936709, -1.427333103448276 ], [ 29.800185443037975, -1.427333103448276 ], [ 29.800185443037975, -1.427063620689655 ], [ 29.79991594936709, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9110, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.427063620689655 ], [ 29.800185443037975, -1.427333103448276 ], [ 29.800724430379748, -1.427333103448276 ], [ 29.800724430379748, -1.427063620689655 ], [ 29.800185443037975, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9111, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.427063620689655 ], [ 29.80530582278481, -1.427333103448276 ], [ 29.805575316455698, -1.427333103448276 ], [ 29.805575316455698, -1.427063620689655 ], [ 29.80530582278481, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9112, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.427063620689655 ], [ 29.805575316455698, -1.427333103448276 ], [ 29.805844810126583, -1.427333103448276 ], [ 29.805844810126583, -1.427063620689655 ], [ 29.805575316455698, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9113, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.427063620689655 ], [ 29.805844810126583, -1.427333103448276 ], [ 29.806114303797468, -1.427333103448276 ], [ 29.806114303797468, -1.427063620689655 ], [ 29.805844810126583, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9114, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.427063620689655 ], [ 29.806114303797468, -1.427333103448276 ], [ 29.806383797468357, -1.427333103448276 ], [ 29.806383797468357, -1.427063620689655 ], [ 29.806114303797468, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9115, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.427063620689655 ], [ 29.806383797468357, -1.427333103448276 ], [ 29.806653291139241, -1.427333103448276 ], [ 29.806653291139241, -1.427063620689655 ], [ 29.806383797468357, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9116, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.427063620689655 ], [ 29.806653291139241, -1.427333103448276 ], [ 29.806922784810126, -1.427333103448276 ], [ 29.806922784810126, -1.427063620689655 ], [ 29.806653291139241, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9117, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.427063620689655 ], [ 29.806922784810126, -1.427333103448276 ], [ 29.807192278481015, -1.427333103448276 ], [ 29.807192278481015, -1.427063620689655 ], [ 29.806922784810126, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9118, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.427063620689655 ], [ 29.807192278481015, -1.427333103448276 ], [ 29.8074617721519, -1.427333103448276 ], [ 29.8074617721519, -1.427063620689655 ], [ 29.807192278481015, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9119, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.427063620689655 ], [ 29.8074617721519, -1.427333103448276 ], [ 29.807731265822785, -1.427333103448276 ], [ 29.807731265822785, -1.427063620689655 ], [ 29.8074617721519, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.427063620689655 ], [ 29.807731265822785, -1.427333103448276 ], [ 29.808000759493673, -1.427333103448276 ], [ 29.808000759493673, -1.427063620689655 ], [ 29.807731265822785, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.427063620689655 ], [ 29.808000759493673, -1.427333103448276 ], [ 29.808270253164558, -1.427333103448276 ], [ 29.808270253164558, -1.427063620689655 ], [ 29.808000759493673, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9122, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.427063620689655 ], [ 29.808270253164558, -1.427333103448276 ], [ 29.808539746835443, -1.427333103448276 ], [ 29.808539746835443, -1.427063620689655 ], [ 29.808270253164558, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9123, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.427063620689655 ], [ 29.808539746835443, -1.427333103448276 ], [ 29.808809240506328, -1.427333103448276 ], [ 29.808809240506328, -1.427063620689655 ], [ 29.808539746835443, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9124, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.427063620689655 ], [ 29.808809240506328, -1.427333103448276 ], [ 29.809348227848101, -1.427333103448276 ], [ 29.809348227848101, -1.427063620689655 ], [ 29.808809240506328, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9125, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.427063620689655 ], [ 29.810965189873418, -1.427333103448276 ], [ 29.812043164556961, -1.427333103448276 ], [ 29.812043164556961, -1.427063620689655 ], [ 29.810965189873418, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9126, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.427063620689655 ], [ 29.812043164556961, -1.427333103448276 ], [ 29.812312658227849, -1.427333103448276 ], [ 29.812312658227849, -1.427063620689655 ], [ 29.812043164556961, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9127, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.427063620689655 ], [ 29.812312658227849, -1.427333103448276 ], [ 29.812582151898734, -1.427333103448276 ], [ 29.812582151898734, -1.427063620689655 ], [ 29.812312658227849, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9128, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.427063620689655 ], [ 29.812582151898734, -1.427333103448276 ], [ 29.812851645569619, -1.427333103448276 ], [ 29.812851645569619, -1.427063620689655 ], [ 29.812582151898734, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9129, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.427063620689655 ], [ 29.812851645569619, -1.427333103448276 ], [ 29.813121139240508, -1.427333103448276 ], [ 29.813121139240508, -1.427063620689655 ], [ 29.812851645569619, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9130, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.427063620689655 ], [ 29.813121139240508, -1.427333103448276 ], [ 29.813390632911393, -1.427333103448276 ], [ 29.813390632911393, -1.427063620689655 ], [ 29.813121139240508, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9131, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.427063620689655 ], [ 29.813390632911393, -1.427333103448276 ], [ 29.813660126582278, -1.427333103448276 ], [ 29.813660126582278, -1.427063620689655 ], [ 29.813390632911393, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9132, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.427063620689655 ], [ 29.813660126582278, -1.427333103448276 ], [ 29.813929620253166, -1.427333103448276 ], [ 29.813929620253166, -1.427063620689655 ], [ 29.813660126582278, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9133, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.427063620689655 ], [ 29.813929620253166, -1.427333103448276 ], [ 29.814468607594936, -1.427333103448276 ], [ 29.814468607594936, -1.427063620689655 ], [ 29.813929620253166, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9134, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.427063620689655 ], [ 29.814468607594936, -1.427333103448276 ], [ 29.814738101265824, -1.427333103448276 ], [ 29.814738101265824, -1.427063620689655 ], [ 29.814468607594936, -1.427063620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9135, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.815007594936709, -1.42490775862069 ], [ 29.815277088607594, -1.42490775862069 ], [ 29.815277088607594, -1.427333103448276 ], [ 29.814738101265824, -1.427333103448276 ], [ 29.814738101265824, -1.427063620689655 ], [ 29.815007594936709, -1.427063620689655 ], [ 29.815007594936709, -1.42490775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9136, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.427333103448276 ], [ 29.761108860759492, -1.428680517241379 ], [ 29.761378354430381, -1.428680517241379 ], [ 29.761378354430381, -1.427333103448276 ], [ 29.761108860759492, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9137, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.427333103448276 ], [ 29.761917341772151, -1.428680517241379 ], [ 29.762186835443039, -1.428680517241379 ], [ 29.762186835443039, -1.427333103448276 ], [ 29.761917341772151, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9138, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.427333103448276 ], [ 29.762456329113924, -1.428680517241379 ], [ 29.762725822784809, -1.428680517241379 ], [ 29.762725822784809, -1.427333103448276 ], [ 29.762456329113924, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9139, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.427333103448276 ], [ 29.762725822784809, -1.428411034482759 ], [ 29.762995316455697, -1.428411034482759 ], [ 29.762995316455697, -1.427333103448276 ], [ 29.762725822784809, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9140, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.427333103448276 ], [ 29.762995316455697, -1.428141551724138 ], [ 29.763264810126582, -1.428141551724138 ], [ 29.763264810126582, -1.427333103448276 ], [ 29.762995316455697, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9141, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.427333103448276 ], [ 29.763264810126582, -1.428411034482759 ], [ 29.763534303797467, -1.428411034482759 ], [ 29.763534303797467, -1.427333103448276 ], [ 29.763264810126582, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9142, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.427333103448276 ], [ 29.763534303797467, -1.428141551724138 ], [ 29.763803797468356, -1.428141551724138 ], [ 29.763803797468356, -1.427333103448276 ], [ 29.763534303797467, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9143, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.427333103448276 ], [ 29.763803797468356, -1.427602586206897 ], [ 29.76407329113924, -1.427602586206897 ], [ 29.76407329113924, -1.427333103448276 ], [ 29.763803797468356, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9144, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.427333103448276 ], [ 29.764342784810125, -1.427602586206897 ], [ 29.764612278481014, -1.427602586206897 ], [ 29.764612278481014, -1.427333103448276 ], [ 29.764342784810125, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9145, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.427333103448276 ], [ 29.764612278481014, -1.427602586206897 ], [ 29.764881772151899, -1.427602586206897 ], [ 29.764881772151899, -1.427333103448276 ], [ 29.764612278481014, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9146, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.427333103448276 ], [ 29.764881772151899, -1.427602586206897 ], [ 29.765151265822784, -1.427602586206897 ], [ 29.765151265822784, -1.427333103448276 ], [ 29.764881772151899, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9147, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.427333103448276 ], [ 29.765151265822784, -1.427602586206897 ], [ 29.765420759493672, -1.427602586206897 ], [ 29.765420759493672, -1.427333103448276 ], [ 29.765151265822784, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9148, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.427333103448276 ], [ 29.765420759493672, -1.427602586206897 ], [ 29.765959746835442, -1.427602586206897 ], [ 29.765959746835442, -1.427333103448276 ], [ 29.765420759493672, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9149, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.427333103448276 ], [ 29.765959746835442, -1.427602586206897 ], [ 29.766229240506327, -1.427602586206897 ], [ 29.766229240506327, -1.427333103448276 ], [ 29.765959746835442, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9150, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.427333103448276 ], [ 29.766229240506327, -1.427602586206897 ], [ 29.766498734177215, -1.427602586206897 ], [ 29.766498734177215, -1.427333103448276 ], [ 29.766229240506327, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9151, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.427333103448276 ], [ 29.766498734177215, -1.427602586206897 ], [ 29.7667682278481, -1.427602586206897 ], [ 29.7667682278481, -1.427333103448276 ], [ 29.766498734177215, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9152, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.427333103448276 ], [ 29.7667682278481, -1.427602586206897 ], [ 29.767037721518985, -1.427602586206897 ], [ 29.767037721518985, -1.427333103448276 ], [ 29.7667682278481, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9153, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.427333103448276 ], [ 29.767037721518985, -1.427602586206897 ], [ 29.767307215189874, -1.427602586206897 ], [ 29.767307215189874, -1.427333103448276 ], [ 29.767037721518985, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9154, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.427333103448276 ], [ 29.767307215189874, -1.427602586206897 ], [ 29.767576708860759, -1.427602586206897 ], [ 29.767576708860759, -1.427333103448276 ], [ 29.767307215189874, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9155, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.427333103448276 ], [ 29.767576708860759, -1.427602586206897 ], [ 29.767846202531643, -1.427602586206897 ], [ 29.767846202531643, -1.427333103448276 ], [ 29.767576708860759, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9156, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.427333103448276 ], [ 29.767846202531643, -1.427602586206897 ], [ 29.768115696202532, -1.427602586206897 ], [ 29.768115696202532, -1.427333103448276 ], [ 29.767846202531643, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9157, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.427333103448276 ], [ 29.768115696202532, -1.427602586206897 ], [ 29.768385189873417, -1.427602586206897 ], [ 29.768385189873417, -1.427333103448276 ], [ 29.768115696202532, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9158, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.427333103448276 ], [ 29.768385189873417, -1.427602586206897 ], [ 29.768654683544302, -1.427602586206897 ], [ 29.768654683544302, -1.427333103448276 ], [ 29.768385189873417, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9159, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.427333103448276 ], [ 29.768654683544302, -1.427602586206897 ], [ 29.76892417721519, -1.427602586206897 ], [ 29.76892417721519, -1.427333103448276 ], [ 29.768654683544302, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9160, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.427333103448276 ], [ 29.76892417721519, -1.427602586206897 ], [ 29.769193670886075, -1.427602586206897 ], [ 29.769193670886075, -1.427333103448276 ], [ 29.76892417721519, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9161, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.427333103448276 ], [ 29.769193670886075, -1.427602586206897 ], [ 29.76946316455696, -1.427602586206897 ], [ 29.76946316455696, -1.427333103448276 ], [ 29.769193670886075, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9162, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.427333103448276 ], [ 29.76946316455696, -1.427602586206897 ], [ 29.769732658227849, -1.427602586206897 ], [ 29.769732658227849, -1.427333103448276 ], [ 29.76946316455696, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9163, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.427333103448276 ], [ 29.769732658227849, -1.427602586206897 ], [ 29.770002151898733, -1.427602586206897 ], [ 29.770002151898733, -1.427333103448276 ], [ 29.769732658227849, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9164, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.427333103448276 ], [ 29.770002151898733, -1.427602586206897 ], [ 29.770271645569618, -1.427602586206897 ], [ 29.770271645569618, -1.427333103448276 ], [ 29.770002151898733, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9165, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.427333103448276 ], [ 29.770271645569618, -1.427602586206897 ], [ 29.770541139240507, -1.427602586206897 ], [ 29.770541139240507, -1.427333103448276 ], [ 29.770271645569618, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9166, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.427333103448276 ], [ 29.770541139240507, -1.427602586206897 ], [ 29.770810632911392, -1.427602586206897 ], [ 29.770810632911392, -1.427333103448276 ], [ 29.770541139240507, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9167, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.427333103448276 ], [ 29.770810632911392, -1.427602586206897 ], [ 29.771080126582277, -1.427602586206897 ], [ 29.771080126582277, -1.427333103448276 ], [ 29.770810632911392, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9168, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.427333103448276 ], [ 29.771080126582277, -1.427602586206897 ], [ 29.771349620253165, -1.427602586206897 ], [ 29.771349620253165, -1.427333103448276 ], [ 29.771080126582277, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9169, "El": 160 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.425716206896552 ], [ 29.771888607594935, -1.425716206896552 ], [ 29.771888607594935, -1.427872068965517 ], [ 29.77161911392405, -1.427872068965517 ], [ 29.77161911392405, -1.427602586206897 ], [ 29.771349620253165, -1.427602586206897 ], [ 29.771349620253165, -1.427333103448276 ], [ 29.77161911392405, -1.427333103448276 ], [ 29.77161911392405, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9170, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.427333103448276 ], [ 29.790483670886076, -1.427602586206897 ], [ 29.790753164556961, -1.427602586206897 ], [ 29.790753164556961, -1.427333103448276 ], [ 29.790483670886076, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9171, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.427333103448276 ], [ 29.790753164556961, -1.427602586206897 ], [ 29.791022658227849, -1.427602586206897 ], [ 29.791022658227849, -1.427333103448276 ], [ 29.790753164556961, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9172, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.427333103448276 ], [ 29.798568481012659, -1.427872068965517 ], [ 29.798837974683543, -1.427872068965517 ], [ 29.798837974683543, -1.427333103448276 ], [ 29.798568481012659, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9173, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.427333103448276 ], [ 29.798837974683543, -1.427602586206897 ], [ 29.799107468354432, -1.427602586206897 ], [ 29.799107468354432, -1.427333103448276 ], [ 29.798837974683543, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9174, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.427333103448276 ], [ 29.799107468354432, -1.427602586206897 ], [ 29.799376962025317, -1.427602586206897 ], [ 29.799376962025317, -1.427333103448276 ], [ 29.799107468354432, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9175, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.427333103448276 ], [ 29.799376962025317, -1.427602586206897 ], [ 29.79991594936709, -1.427602586206897 ], [ 29.79991594936709, -1.427333103448276 ], [ 29.799376962025317, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9176, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.427333103448276 ], [ 29.79991594936709, -1.427602586206897 ], [ 29.800185443037975, -1.427602586206897 ], [ 29.800185443037975, -1.427333103448276 ], [ 29.79991594936709, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9177, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.427333103448276 ], [ 29.800185443037975, -1.427602586206897 ], [ 29.80045493670886, -1.427602586206897 ], [ 29.80045493670886, -1.427333103448276 ], [ 29.800185443037975, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9178, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.427333103448276 ], [ 29.80045493670886, -1.427602586206897 ], [ 29.800724430379748, -1.427602586206897 ], [ 29.800724430379748, -1.427333103448276 ], [ 29.80045493670886, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9179, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.427333103448276 ], [ 29.800724430379748, -1.427602586206897 ], [ 29.801263417721518, -1.427602586206897 ], [ 29.801263417721518, -1.427333103448276 ], [ 29.800724430379748, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9180, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.427333103448276 ], [ 29.805036329113925, -1.427602586206897 ], [ 29.80530582278481, -1.427602586206897 ], [ 29.80530582278481, -1.427333103448276 ], [ 29.805036329113925, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9181, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.427333103448276 ], [ 29.80530582278481, -1.427602586206897 ], [ 29.805575316455698, -1.427602586206897 ], [ 29.805575316455698, -1.427333103448276 ], [ 29.80530582278481, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9182, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.427333103448276 ], [ 29.805575316455698, -1.427602586206897 ], [ 29.805844810126583, -1.427602586206897 ], [ 29.805844810126583, -1.427333103448276 ], [ 29.805575316455698, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9183, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.427333103448276 ], [ 29.805844810126583, -1.427602586206897 ], [ 29.806114303797468, -1.427602586206897 ], [ 29.806114303797468, -1.427333103448276 ], [ 29.805844810126583, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9184, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.427333103448276 ], [ 29.806114303797468, -1.427602586206897 ], [ 29.806383797468357, -1.427602586206897 ], [ 29.806383797468357, -1.427333103448276 ], [ 29.806114303797468, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9185, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.427333103448276 ], [ 29.806383797468357, -1.427602586206897 ], [ 29.806653291139241, -1.427602586206897 ], [ 29.806653291139241, -1.427333103448276 ], [ 29.806383797468357, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9186, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.427333103448276 ], [ 29.806653291139241, -1.427602586206897 ], [ 29.806922784810126, -1.427602586206897 ], [ 29.806922784810126, -1.427333103448276 ], [ 29.806653291139241, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9187, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.427333103448276 ], [ 29.806922784810126, -1.427602586206897 ], [ 29.807192278481015, -1.427602586206897 ], [ 29.807192278481015, -1.427333103448276 ], [ 29.806922784810126, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9188, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.427333103448276 ], [ 29.807192278481015, -1.427602586206897 ], [ 29.8074617721519, -1.427602586206897 ], [ 29.8074617721519, -1.427333103448276 ], [ 29.807192278481015, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9189, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.427333103448276 ], [ 29.8074617721519, -1.427602586206897 ], [ 29.807731265822785, -1.427602586206897 ], [ 29.807731265822785, -1.427333103448276 ], [ 29.8074617721519, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9190, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.427333103448276 ], [ 29.807731265822785, -1.427602586206897 ], [ 29.808000759493673, -1.427602586206897 ], [ 29.808000759493673, -1.427333103448276 ], [ 29.807731265822785, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9191, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.427333103448276 ], [ 29.808000759493673, -1.427602586206897 ], [ 29.808270253164558, -1.427602586206897 ], [ 29.808270253164558, -1.427333103448276 ], [ 29.808000759493673, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9192, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.427333103448276 ], [ 29.808270253164558, -1.427602586206897 ], [ 29.808809240506328, -1.427602586206897 ], [ 29.808809240506328, -1.427333103448276 ], [ 29.808270253164558, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9193, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.427333103448276 ], [ 29.812043164556961, -1.427602586206897 ], [ 29.812582151898734, -1.427602586206897 ], [ 29.812582151898734, -1.427333103448276 ], [ 29.812043164556961, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9194, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.427333103448276 ], [ 29.812582151898734, -1.427602586206897 ], [ 29.812851645569619, -1.427602586206897 ], [ 29.812851645569619, -1.427333103448276 ], [ 29.812582151898734, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9195, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.427333103448276 ], [ 29.812851645569619, -1.427602586206897 ], [ 29.813121139240508, -1.427602586206897 ], [ 29.813121139240508, -1.427333103448276 ], [ 29.812851645569619, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9196, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.427333103448276 ], [ 29.813121139240508, -1.427602586206897 ], [ 29.813390632911393, -1.427602586206897 ], [ 29.813390632911393, -1.427333103448276 ], [ 29.813121139240508, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9197, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.427333103448276 ], [ 29.813390632911393, -1.427602586206897 ], [ 29.813660126582278, -1.427602586206897 ], [ 29.813660126582278, -1.427333103448276 ], [ 29.813390632911393, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9198, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.427333103448276 ], [ 29.813660126582278, -1.427602586206897 ], [ 29.813929620253166, -1.427602586206897 ], [ 29.813929620253166, -1.427333103448276 ], [ 29.813660126582278, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9199, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.427333103448276 ], [ 29.813929620253166, -1.427602586206897 ], [ 29.814738101265824, -1.427602586206897 ], [ 29.814738101265824, -1.427333103448276 ], [ 29.813929620253166, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9200, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.427602586206897 ], [ 29.763803797468356, -1.427872068965517 ], [ 29.76407329113924, -1.427872068965517 ], [ 29.76407329113924, -1.427602586206897 ], [ 29.763803797468356, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9201, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.427602586206897 ], [ 29.76407329113924, -1.427872068965517 ], [ 29.764342784810125, -1.427872068965517 ], [ 29.764342784810125, -1.427602586206897 ], [ 29.76407329113924, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9202, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.427602586206897 ], [ 29.764342784810125, -1.427872068965517 ], [ 29.764612278481014, -1.427872068965517 ], [ 29.764612278481014, -1.427602586206897 ], [ 29.764342784810125, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9203, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.427602586206897 ], [ 29.764612278481014, -1.427872068965517 ], [ 29.764881772151899, -1.427872068965517 ], [ 29.764881772151899, -1.427602586206897 ], [ 29.764612278481014, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9204, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.427602586206897 ], [ 29.764881772151899, -1.427872068965517 ], [ 29.765151265822784, -1.427872068965517 ], [ 29.765151265822784, -1.427602586206897 ], [ 29.764881772151899, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9205, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.427602586206897 ], [ 29.765151265822784, -1.427872068965517 ], [ 29.765420759493672, -1.427872068965517 ], [ 29.765420759493672, -1.427602586206897 ], [ 29.765151265822784, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9206, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.427602586206897 ], [ 29.765420759493672, -1.427872068965517 ], [ 29.765959746835442, -1.427872068965517 ], [ 29.765959746835442, -1.427602586206897 ], [ 29.765420759493672, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9207, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.427602586206897 ], [ 29.765959746835442, -1.427872068965517 ], [ 29.766229240506327, -1.427872068965517 ], [ 29.766229240506327, -1.427602586206897 ], [ 29.765959746835442, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9208, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.427602586206897 ], [ 29.766229240506327, -1.427872068965517 ], [ 29.766498734177215, -1.427872068965517 ], [ 29.766498734177215, -1.427602586206897 ], [ 29.766229240506327, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9209, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.427602586206897 ], [ 29.766498734177215, -1.427872068965517 ], [ 29.7667682278481, -1.427872068965517 ], [ 29.7667682278481, -1.427602586206897 ], [ 29.766498734177215, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9210, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.427602586206897 ], [ 29.7667682278481, -1.427872068965517 ], [ 29.767037721518985, -1.427872068965517 ], [ 29.767037721518985, -1.427602586206897 ], [ 29.7667682278481, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9211, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.427602586206897 ], [ 29.767037721518985, -1.427872068965517 ], [ 29.767307215189874, -1.427872068965517 ], [ 29.767307215189874, -1.427602586206897 ], [ 29.767037721518985, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9212, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.427602586206897 ], [ 29.767307215189874, -1.427872068965517 ], [ 29.767576708860759, -1.427872068965517 ], [ 29.767576708860759, -1.427602586206897 ], [ 29.767307215189874, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9213, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.427602586206897 ], [ 29.767576708860759, -1.427872068965517 ], [ 29.767846202531643, -1.427872068965517 ], [ 29.767846202531643, -1.427602586206897 ], [ 29.767576708860759, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9214, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.427602586206897 ], [ 29.767846202531643, -1.427872068965517 ], [ 29.768115696202532, -1.427872068965517 ], [ 29.768115696202532, -1.427602586206897 ], [ 29.767846202531643, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9215, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.427602586206897 ], [ 29.768115696202532, -1.427872068965517 ], [ 29.768385189873417, -1.427872068965517 ], [ 29.768385189873417, -1.427602586206897 ], [ 29.768115696202532, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9216, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.427602586206897 ], [ 29.768385189873417, -1.427872068965517 ], [ 29.768654683544302, -1.427872068965517 ], [ 29.768654683544302, -1.427602586206897 ], [ 29.768385189873417, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9217, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.427602586206897 ], [ 29.768654683544302, -1.427872068965517 ], [ 29.76892417721519, -1.427872068965517 ], [ 29.76892417721519, -1.427602586206897 ], [ 29.768654683544302, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9218, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.427602586206897 ], [ 29.76892417721519, -1.427872068965517 ], [ 29.769193670886075, -1.427872068965517 ], [ 29.769193670886075, -1.427602586206897 ], [ 29.76892417721519, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9219, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.427602586206897 ], [ 29.769193670886075, -1.427872068965517 ], [ 29.76946316455696, -1.427872068965517 ], [ 29.76946316455696, -1.427602586206897 ], [ 29.769193670886075, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9220, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.427602586206897 ], [ 29.76946316455696, -1.427872068965517 ], [ 29.769732658227849, -1.427872068965517 ], [ 29.769732658227849, -1.427602586206897 ], [ 29.76946316455696, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9221, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.427602586206897 ], [ 29.769732658227849, -1.427872068965517 ], [ 29.770002151898733, -1.427872068965517 ], [ 29.770002151898733, -1.427602586206897 ], [ 29.769732658227849, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9222, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.427602586206897 ], [ 29.770002151898733, -1.427872068965517 ], [ 29.770271645569618, -1.427872068965517 ], [ 29.770271645569618, -1.427602586206897 ], [ 29.770002151898733, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9223, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.427602586206897 ], [ 29.770271645569618, -1.427872068965517 ], [ 29.770541139240507, -1.427872068965517 ], [ 29.770541139240507, -1.427602586206897 ], [ 29.770271645569618, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9224, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.427602586206897 ], [ 29.770541139240507, -1.427872068965517 ], [ 29.770810632911392, -1.427872068965517 ], [ 29.770810632911392, -1.427602586206897 ], [ 29.770541139240507, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9225, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.427602586206897 ], [ 29.770810632911392, -1.427872068965517 ], [ 29.771080126582277, -1.427872068965517 ], [ 29.771080126582277, -1.427602586206897 ], [ 29.770810632911392, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9226, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.427602586206897 ], [ 29.771080126582277, -1.427872068965517 ], [ 29.771349620253165, -1.427872068965517 ], [ 29.771349620253165, -1.427602586206897 ], [ 29.771080126582277, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9227, "El": 158 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.427602586206897 ], [ 29.771349620253165, -1.427872068965517 ], [ 29.77161911392405, -1.427872068965517 ], [ 29.77161911392405, -1.427602586206897 ], [ 29.771349620253165, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9228, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.427602586206897 ], [ 29.789944683544302, -1.427872068965517 ], [ 29.790214177215191, -1.427872068965517 ], [ 29.790214177215191, -1.427602586206897 ], [ 29.789944683544302, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9229, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.427602586206897 ], [ 29.790214177215191, -1.427872068965517 ], [ 29.790483670886076, -1.427872068965517 ], [ 29.790483670886076, -1.427602586206897 ], [ 29.790214177215191, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9230, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.427602586206897 ], [ 29.790483670886076, -1.427872068965517 ], [ 29.790753164556961, -1.427872068965517 ], [ 29.790753164556961, -1.427602586206897 ], [ 29.790483670886076, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9231, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.427602586206897 ], [ 29.790753164556961, -1.427872068965517 ], [ 29.791022658227849, -1.427872068965517 ], [ 29.791022658227849, -1.427602586206897 ], [ 29.790753164556961, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9232, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.427602586206897 ], [ 29.791022658227849, -1.428141551724138 ], [ 29.791292151898734, -1.428141551724138 ], [ 29.791292151898734, -1.427602586206897 ], [ 29.791022658227849, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9233, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.427602586206897 ], [ 29.798837974683543, -1.427872068965517 ], [ 29.799107468354432, -1.427872068965517 ], [ 29.799107468354432, -1.427602586206897 ], [ 29.798837974683543, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9234, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.427602586206897 ], [ 29.799107468354432, -1.427872068965517 ], [ 29.799376962025317, -1.427872068965517 ], [ 29.799376962025317, -1.427602586206897 ], [ 29.799107468354432, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9235, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.427602586206897 ], [ 29.799376962025317, -1.427872068965517 ], [ 29.79991594936709, -1.427872068965517 ], [ 29.79991594936709, -1.427602586206897 ], [ 29.799376962025317, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9236, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.427602586206897 ], [ 29.79991594936709, -1.427872068965517 ], [ 29.800185443037975, -1.427872068965517 ], [ 29.800185443037975, -1.427602586206897 ], [ 29.79991594936709, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9237, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.427602586206897 ], [ 29.800185443037975, -1.427872068965517 ], [ 29.80045493670886, -1.427872068965517 ], [ 29.80045493670886, -1.427602586206897 ], [ 29.800185443037975, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9238, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.427602586206897 ], [ 29.80045493670886, -1.427872068965517 ], [ 29.800724430379748, -1.427872068965517 ], [ 29.800724430379748, -1.427602586206897 ], [ 29.80045493670886, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9239, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.427602586206897 ], [ 29.800724430379748, -1.427872068965517 ], [ 29.800993924050633, -1.427872068965517 ], [ 29.800993924050633, -1.427602586206897 ], [ 29.800724430379748, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9240, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.427602586206897 ], [ 29.800993924050633, -1.427872068965517 ], [ 29.801263417721518, -1.427872068965517 ], [ 29.801263417721518, -1.427602586206897 ], [ 29.800993924050633, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9241, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.427602586206897 ], [ 29.801263417721518, -1.427872068965517 ], [ 29.802071898734177, -1.427872068965517 ], [ 29.802071898734177, -1.427602586206897 ], [ 29.801263417721518, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9242, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.427602586206897 ], [ 29.80476683544304, -1.427872068965517 ], [ 29.805036329113925, -1.427872068965517 ], [ 29.805036329113925, -1.427602586206897 ], [ 29.80476683544304, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9243, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.427602586206897 ], [ 29.805036329113925, -1.427872068965517 ], [ 29.80530582278481, -1.427872068965517 ], [ 29.80530582278481, -1.427602586206897 ], [ 29.805036329113925, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9244, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.427602586206897 ], [ 29.80530582278481, -1.427872068965517 ], [ 29.805575316455698, -1.427872068965517 ], [ 29.805575316455698, -1.427602586206897 ], [ 29.80530582278481, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9245, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.427602586206897 ], [ 29.805575316455698, -1.427872068965517 ], [ 29.805844810126583, -1.427872068965517 ], [ 29.805844810126583, -1.427602586206897 ], [ 29.805575316455698, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9246, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.427602586206897 ], [ 29.805844810126583, -1.427872068965517 ], [ 29.806114303797468, -1.427872068965517 ], [ 29.806114303797468, -1.427602586206897 ], [ 29.805844810126583, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9247, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.427602586206897 ], [ 29.806114303797468, -1.427872068965517 ], [ 29.806383797468357, -1.427872068965517 ], [ 29.806383797468357, -1.427602586206897 ], [ 29.806114303797468, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9248, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.427602586206897 ], [ 29.806383797468357, -1.427872068965517 ], [ 29.806653291139241, -1.427872068965517 ], [ 29.806653291139241, -1.427602586206897 ], [ 29.806383797468357, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9249, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.427602586206897 ], [ 29.806653291139241, -1.427872068965517 ], [ 29.806922784810126, -1.427872068965517 ], [ 29.806922784810126, -1.427602586206897 ], [ 29.806653291139241, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9250, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.427602586206897 ], [ 29.806922784810126, -1.427872068965517 ], [ 29.807192278481015, -1.427872068965517 ], [ 29.807192278481015, -1.427602586206897 ], [ 29.806922784810126, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9251, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.427602586206897 ], [ 29.807192278481015, -1.427872068965517 ], [ 29.8074617721519, -1.427872068965517 ], [ 29.8074617721519, -1.427602586206897 ], [ 29.807192278481015, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9252, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.427602586206897 ], [ 29.8074617721519, -1.427872068965517 ], [ 29.807731265822785, -1.427872068965517 ], [ 29.807731265822785, -1.427602586206897 ], [ 29.8074617721519, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9253, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.427602586206897 ], [ 29.807731265822785, -1.427872068965517 ], [ 29.808000759493673, -1.427872068965517 ], [ 29.808000759493673, -1.427602586206897 ], [ 29.807731265822785, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9254, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.427602586206897 ], [ 29.808000759493673, -1.427872068965517 ], [ 29.808270253164558, -1.427872068965517 ], [ 29.808270253164558, -1.427602586206897 ], [ 29.808000759493673, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.427602586206897 ], [ 29.812582151898734, -1.427872068965517 ], [ 29.812851645569619, -1.427872068965517 ], [ 29.812851645569619, -1.427602586206897 ], [ 29.812582151898734, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9256, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.427602586206897 ], [ 29.812851645569619, -1.427872068965517 ], [ 29.813121139240508, -1.427872068965517 ], [ 29.813121139240508, -1.427602586206897 ], [ 29.812851645569619, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9257, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.427602586206897 ], [ 29.813121139240508, -1.427872068965517 ], [ 29.813390632911393, -1.427872068965517 ], [ 29.813390632911393, -1.427602586206897 ], [ 29.813121139240508, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9258, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.427602586206897 ], [ 29.813390632911393, -1.427872068965517 ], [ 29.813660126582278, -1.427872068965517 ], [ 29.813660126582278, -1.427602586206897 ], [ 29.813390632911393, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9259, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.427602586206897 ], [ 29.813660126582278, -1.427872068965517 ], [ 29.813929620253166, -1.427872068965517 ], [ 29.813929620253166, -1.427602586206897 ], [ 29.813660126582278, -1.427602586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9260, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.427872068965517 ], [ 29.755718987341773, -1.428411034482759 ], [ 29.755988481012658, -1.428411034482759 ], [ 29.755988481012658, -1.427872068965517 ], [ 29.755718987341773, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9261, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.427872068965517 ], [ 29.755988481012658, -1.428141551724138 ], [ 29.756257974683542, -1.428141551724138 ], [ 29.756257974683542, -1.427872068965517 ], [ 29.755988481012658, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9262, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.427872068965517 ], [ 29.756257974683542, -1.428141551724138 ], [ 29.756796962025316, -1.428141551724138 ], [ 29.756796962025316, -1.427872068965517 ], [ 29.756257974683542, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9263, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.427872068965517 ], [ 29.756796962025316, -1.428141551724138 ], [ 29.757066455696201, -1.428141551724138 ], [ 29.757066455696201, -1.427872068965517 ], [ 29.756796962025316, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9264, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.427872068965517 ], [ 29.757605443037974, -1.428411034482759 ], [ 29.757874936708859, -1.428411034482759 ], [ 29.757874936708859, -1.427872068965517 ], [ 29.757605443037974, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9265, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.427872068965517 ], [ 29.758952911392406, -1.428411034482759 ], [ 29.759222405063291, -1.428411034482759 ], [ 29.759222405063291, -1.427872068965517 ], [ 29.758952911392406, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9266, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.427872068965517 ], [ 29.763803797468356, -1.428141551724138 ], [ 29.76407329113924, -1.428141551724138 ], [ 29.76407329113924, -1.427872068965517 ], [ 29.763803797468356, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9267, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.427872068965517 ], [ 29.76407329113924, -1.428141551724138 ], [ 29.764342784810125, -1.428141551724138 ], [ 29.764342784810125, -1.427872068965517 ], [ 29.76407329113924, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9268, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.427872068965517 ], [ 29.764342784810125, -1.428141551724138 ], [ 29.764612278481014, -1.428141551724138 ], [ 29.764612278481014, -1.427872068965517 ], [ 29.764342784810125, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9269, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.427872068965517 ], [ 29.764612278481014, -1.428141551724138 ], [ 29.764881772151899, -1.428141551724138 ], [ 29.764881772151899, -1.427872068965517 ], [ 29.764612278481014, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9270, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.427872068965517 ], [ 29.764881772151899, -1.428141551724138 ], [ 29.765151265822784, -1.428141551724138 ], [ 29.765151265822784, -1.427872068965517 ], [ 29.764881772151899, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9271, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.427872068965517 ], [ 29.765151265822784, -1.428141551724138 ], [ 29.765420759493672, -1.428141551724138 ], [ 29.765420759493672, -1.427872068965517 ], [ 29.765151265822784, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9272, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.427872068965517 ], [ 29.765420759493672, -1.428141551724138 ], [ 29.765959746835442, -1.428141551724138 ], [ 29.765959746835442, -1.427872068965517 ], [ 29.765420759493672, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9273, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.427872068965517 ], [ 29.765959746835442, -1.428141551724138 ], [ 29.766229240506327, -1.428141551724138 ], [ 29.766229240506327, -1.427872068965517 ], [ 29.765959746835442, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9274, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.427872068965517 ], [ 29.766229240506327, -1.428141551724138 ], [ 29.766498734177215, -1.428141551724138 ], [ 29.766498734177215, -1.427872068965517 ], [ 29.766229240506327, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9275, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.427872068965517 ], [ 29.766498734177215, -1.428141551724138 ], [ 29.7667682278481, -1.428141551724138 ], [ 29.7667682278481, -1.427872068965517 ], [ 29.766498734177215, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9276, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.427872068965517 ], [ 29.7667682278481, -1.428141551724138 ], [ 29.767037721518985, -1.428141551724138 ], [ 29.767037721518985, -1.427872068965517 ], [ 29.7667682278481, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9277, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.427872068965517 ], [ 29.767037721518985, -1.428141551724138 ], [ 29.767307215189874, -1.428141551724138 ], [ 29.767307215189874, -1.427872068965517 ], [ 29.767037721518985, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9278, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.427872068965517 ], [ 29.767307215189874, -1.428141551724138 ], [ 29.767576708860759, -1.428141551724138 ], [ 29.767576708860759, -1.427872068965517 ], [ 29.767307215189874, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9279, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.427872068965517 ], [ 29.767576708860759, -1.428141551724138 ], [ 29.767846202531643, -1.428141551724138 ], [ 29.767846202531643, -1.427872068965517 ], [ 29.767576708860759, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9280, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.427872068965517 ], [ 29.767846202531643, -1.428141551724138 ], [ 29.768115696202532, -1.428141551724138 ], [ 29.768115696202532, -1.427872068965517 ], [ 29.767846202531643, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9281, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.427872068965517 ], [ 29.768115696202532, -1.428141551724138 ], [ 29.768385189873417, -1.428141551724138 ], [ 29.768385189873417, -1.427872068965517 ], [ 29.768115696202532, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9282, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.427872068965517 ], [ 29.768385189873417, -1.428141551724138 ], [ 29.768654683544302, -1.428141551724138 ], [ 29.768654683544302, -1.427872068965517 ], [ 29.768385189873417, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9283, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.427872068965517 ], [ 29.768654683544302, -1.428141551724138 ], [ 29.76892417721519, -1.428141551724138 ], [ 29.76892417721519, -1.427872068965517 ], [ 29.768654683544302, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9284, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.427872068965517 ], [ 29.76892417721519, -1.428141551724138 ], [ 29.769193670886075, -1.428141551724138 ], [ 29.769193670886075, -1.427872068965517 ], [ 29.76892417721519, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9285, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.427872068965517 ], [ 29.769193670886075, -1.428141551724138 ], [ 29.76946316455696, -1.428141551724138 ], [ 29.76946316455696, -1.427872068965517 ], [ 29.769193670886075, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9286, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.427872068965517 ], [ 29.76946316455696, -1.428141551724138 ], [ 29.769732658227849, -1.428141551724138 ], [ 29.769732658227849, -1.427872068965517 ], [ 29.76946316455696, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9287, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.427872068965517 ], [ 29.769732658227849, -1.428141551724138 ], [ 29.770002151898733, -1.428141551724138 ], [ 29.770002151898733, -1.427872068965517 ], [ 29.769732658227849, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9288, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.427872068965517 ], [ 29.770002151898733, -1.428141551724138 ], [ 29.770271645569618, -1.428141551724138 ], [ 29.770271645569618, -1.427872068965517 ], [ 29.770002151898733, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9289, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.427872068965517 ], [ 29.770271645569618, -1.428141551724138 ], [ 29.770541139240507, -1.428141551724138 ], [ 29.770541139240507, -1.427872068965517 ], [ 29.770271645569618, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9290, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.427872068965517 ], [ 29.770541139240507, -1.428141551724138 ], [ 29.770810632911392, -1.428141551724138 ], [ 29.770810632911392, -1.427872068965517 ], [ 29.770541139240507, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9291, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.427872068965517 ], [ 29.770810632911392, -1.428141551724138 ], [ 29.771080126582277, -1.428141551724138 ], [ 29.771080126582277, -1.427872068965517 ], [ 29.770810632911392, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9292, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.427872068965517 ], [ 29.771080126582277, -1.428141551724138 ], [ 29.771349620253165, -1.428141551724138 ], [ 29.771349620253165, -1.427872068965517 ], [ 29.771080126582277, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9293, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.427872068965517 ], [ 29.771349620253165, -1.428141551724138 ], [ 29.77161911392405, -1.428141551724138 ], [ 29.77161911392405, -1.427872068965517 ], [ 29.771349620253165, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9294, "El": 158 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.427872068965517 ], [ 29.77161911392405, -1.428141551724138 ], [ 29.771888607594935, -1.428141551724138 ], [ 29.771888607594935, -1.427872068965517 ], [ 29.77161911392405, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9295, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.427872068965517 ], [ 29.781590379746834, -1.428411034482759 ], [ 29.781859873417723, -1.428411034482759 ], [ 29.781859873417723, -1.427872068965517 ], [ 29.781590379746834, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9296, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.427872068965517 ], [ 29.782937848101266, -1.428411034482759 ], [ 29.783207341772151, -1.428411034482759 ], [ 29.783207341772151, -1.427872068965517 ], [ 29.782937848101266, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9297, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.427872068965517 ], [ 29.784015822784809, -1.428141551724138 ], [ 29.784285316455698, -1.428141551724138 ], [ 29.784285316455698, -1.427872068965517 ], [ 29.784015822784809, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9298, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.427872068965517 ], [ 29.784285316455698, -1.428141551724138 ], [ 29.784554810126583, -1.428141551724138 ], [ 29.784554810126583, -1.427872068965517 ], [ 29.784285316455698, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9299, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.427872068965517 ], [ 29.784554810126583, -1.428141551724138 ], [ 29.784824303797468, -1.428141551724138 ], [ 29.784824303797468, -1.427872068965517 ], [ 29.784554810126583, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9300, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.427872068965517 ], [ 29.784824303797468, -1.428141551724138 ], [ 29.785093797468356, -1.428141551724138 ], [ 29.785093797468356, -1.427872068965517 ], [ 29.784824303797468, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9301, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.427872068965517 ], [ 29.789136202531644, -1.428141551724138 ], [ 29.789405696202532, -1.428141551724138 ], [ 29.789405696202532, -1.427872068965517 ], [ 29.789136202531644, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9302, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.427872068965517 ], [ 29.789405696202532, -1.428141551724138 ], [ 29.789675189873417, -1.428141551724138 ], [ 29.789675189873417, -1.427872068965517 ], [ 29.789405696202532, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9303, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.427872068965517 ], [ 29.789675189873417, -1.428141551724138 ], [ 29.789944683544302, -1.428141551724138 ], [ 29.789944683544302, -1.427872068965517 ], [ 29.789675189873417, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9304, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.427872068965517 ], [ 29.789944683544302, -1.428411034482759 ], [ 29.790214177215191, -1.428411034482759 ], [ 29.790214177215191, -1.427872068965517 ], [ 29.789944683544302, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9305, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.427872068965517 ], [ 29.790214177215191, -1.428141551724138 ], [ 29.790483670886076, -1.428141551724138 ], [ 29.790483670886076, -1.427872068965517 ], [ 29.790214177215191, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9306, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.427872068965517 ], [ 29.790483670886076, -1.428141551724138 ], [ 29.790753164556961, -1.428141551724138 ], [ 29.790753164556961, -1.427872068965517 ], [ 29.790483670886076, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9307, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.427872068965517 ], [ 29.798568481012659, -1.428141551724138 ], [ 29.798837974683543, -1.428141551724138 ], [ 29.798837974683543, -1.427872068965517 ], [ 29.798568481012659, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9308, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.427872068965517 ], [ 29.798837974683543, -1.428141551724138 ], [ 29.799107468354432, -1.428141551724138 ], [ 29.799107468354432, -1.427872068965517 ], [ 29.798837974683543, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9309, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.427872068965517 ], [ 29.799107468354432, -1.428141551724138 ], [ 29.799376962025317, -1.428141551724138 ], [ 29.799376962025317, -1.427872068965517 ], [ 29.799107468354432, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9310, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.427872068965517 ], [ 29.799376962025317, -1.428141551724138 ], [ 29.799646455696202, -1.428141551724138 ], [ 29.799646455696202, -1.427872068965517 ], [ 29.799376962025317, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9311, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.427872068965517 ], [ 29.799646455696202, -1.428141551724138 ], [ 29.79991594936709, -1.428141551724138 ], [ 29.79991594936709, -1.427872068965517 ], [ 29.799646455696202, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9312, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.427872068965517 ], [ 29.79991594936709, -1.428141551724138 ], [ 29.800185443037975, -1.428141551724138 ], [ 29.800185443037975, -1.427872068965517 ], [ 29.79991594936709, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9313, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.427872068965517 ], [ 29.800185443037975, -1.428141551724138 ], [ 29.80045493670886, -1.428141551724138 ], [ 29.80045493670886, -1.427872068965517 ], [ 29.800185443037975, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9314, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.427872068965517 ], [ 29.80045493670886, -1.428141551724138 ], [ 29.800724430379748, -1.428141551724138 ], [ 29.800724430379748, -1.427872068965517 ], [ 29.80045493670886, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9315, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.427872068965517 ], [ 29.800724430379748, -1.428141551724138 ], [ 29.800993924050633, -1.428141551724138 ], [ 29.800993924050633, -1.427872068965517 ], [ 29.800724430379748, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9316, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.427872068965517 ], [ 29.800993924050633, -1.428141551724138 ], [ 29.801263417721518, -1.428141551724138 ], [ 29.801263417721518, -1.427872068965517 ], [ 29.800993924050633, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9317, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.427872068965517 ], [ 29.801263417721518, -1.428141551724138 ], [ 29.801532911392407, -1.428141551724138 ], [ 29.801532911392407, -1.427872068965517 ], [ 29.801263417721518, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9318, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.427872068965517 ], [ 29.801532911392407, -1.428141551724138 ], [ 29.802071898734177, -1.428141551724138 ], [ 29.802071898734177, -1.427872068965517 ], [ 29.801532911392407, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9319, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.427872068965517 ], [ 29.802071898734177, -1.428141551724138 ], [ 29.802341392405065, -1.428141551724138 ], [ 29.802341392405065, -1.427872068965517 ], [ 29.802071898734177, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9320, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.427872068965517 ], [ 29.802341392405065, -1.428141551724138 ], [ 29.80261088607595, -1.428141551724138 ], [ 29.80261088607595, -1.427872068965517 ], [ 29.802341392405065, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9321, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.427872068965517 ], [ 29.80261088607595, -1.428141551724138 ], [ 29.802880379746835, -1.428141551724138 ], [ 29.802880379746835, -1.427872068965517 ], [ 29.80261088607595, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9322, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.427872068965517 ], [ 29.802880379746835, -1.428141551724138 ], [ 29.803149873417723, -1.428141551724138 ], [ 29.803149873417723, -1.427872068965517 ], [ 29.802880379746835, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9323, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.427872068965517 ], [ 29.803688860759493, -1.428411034482759 ], [ 29.803958354430382, -1.428411034482759 ], [ 29.803958354430382, -1.427872068965517 ], [ 29.803688860759493, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9324, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.427872068965517 ], [ 29.805036329113925, -1.428141551724138 ], [ 29.80530582278481, -1.428141551724138 ], [ 29.80530582278481, -1.427872068965517 ], [ 29.805036329113925, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9325, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.427872068965517 ], [ 29.80530582278481, -1.428141551724138 ], [ 29.805575316455698, -1.428141551724138 ], [ 29.805575316455698, -1.427872068965517 ], [ 29.80530582278481, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9326, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.427872068965517 ], [ 29.805575316455698, -1.428141551724138 ], [ 29.805844810126583, -1.428141551724138 ], [ 29.805844810126583, -1.427872068965517 ], [ 29.805575316455698, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9327, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.427872068965517 ], [ 29.805844810126583, -1.428141551724138 ], [ 29.806114303797468, -1.428141551724138 ], [ 29.806114303797468, -1.427872068965517 ], [ 29.805844810126583, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9328, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.427872068965517 ], [ 29.806114303797468, -1.428141551724138 ], [ 29.806383797468357, -1.428141551724138 ], [ 29.806383797468357, -1.427872068965517 ], [ 29.806114303797468, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9329, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.427872068965517 ], [ 29.806383797468357, -1.428141551724138 ], [ 29.806653291139241, -1.428141551724138 ], [ 29.806653291139241, -1.427872068965517 ], [ 29.806383797468357, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9330, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.427872068965517 ], [ 29.806653291139241, -1.428141551724138 ], [ 29.806922784810126, -1.428141551724138 ], [ 29.806922784810126, -1.427872068965517 ], [ 29.806653291139241, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9331, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.427872068965517 ], [ 29.806922784810126, -1.428141551724138 ], [ 29.807192278481015, -1.428141551724138 ], [ 29.807192278481015, -1.427872068965517 ], [ 29.806922784810126, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9332, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.427872068965517 ], [ 29.807192278481015, -1.428141551724138 ], [ 29.8074617721519, -1.428141551724138 ], [ 29.8074617721519, -1.427872068965517 ], [ 29.807192278481015, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9333, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.427872068965517 ], [ 29.8074617721519, -1.428141551724138 ], [ 29.807731265822785, -1.428141551724138 ], [ 29.807731265822785, -1.427872068965517 ], [ 29.8074617721519, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9334, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.427872068965517 ], [ 29.807731265822785, -1.428141551724138 ], [ 29.808000759493673, -1.428141551724138 ], [ 29.808000759493673, -1.427872068965517 ], [ 29.807731265822785, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9335, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.427872068965517 ], [ 29.812851645569619, -1.428141551724138 ], [ 29.813390632911393, -1.428141551724138 ], [ 29.813390632911393, -1.428411034482759 ], [ 29.813660126582278, -1.428411034482759 ], [ 29.813660126582278, -1.427872068965517 ], [ 29.812851645569619, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9336, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.428141551724138 ], [ 29.755988481012658, -1.428411034482759 ], [ 29.756257974683542, -1.428411034482759 ], [ 29.756257974683542, -1.428141551724138 ], [ 29.755988481012658, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9337, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.428141551724138 ], [ 29.756257974683542, -1.428411034482759 ], [ 29.756796962025316, -1.428411034482759 ], [ 29.756796962025316, -1.428141551724138 ], [ 29.756257974683542, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9338, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.428141551724138 ], [ 29.756796962025316, -1.428411034482759 ], [ 29.757066455696201, -1.428411034482759 ], [ 29.757066455696201, -1.428141551724138 ], [ 29.756796962025316, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9339, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.428141551724138 ], [ 29.757066455696201, -1.428411034482759 ], [ 29.757335949367089, -1.428411034482759 ], [ 29.757335949367089, -1.428141551724138 ], [ 29.757066455696201, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9340, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.428141551724138 ], [ 29.757335949367089, -1.428411034482759 ], [ 29.757605443037974, -1.428411034482759 ], [ 29.757605443037974, -1.428141551724138 ], [ 29.757335949367089, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9341, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.428141551724138 ], [ 29.757874936708859, -1.428411034482759 ], [ 29.758144430379748, -1.428411034482759 ], [ 29.758144430379748, -1.428141551724138 ], [ 29.757874936708859, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9342, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.428141551724138 ], [ 29.758144430379748, -1.428411034482759 ], [ 29.758413924050632, -1.428411034482759 ], [ 29.758413924050632, -1.428141551724138 ], [ 29.758144430379748, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9343, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.428141551724138 ], [ 29.758683417721517, -1.428680517241379 ], [ 29.758952911392406, -1.428680517241379 ], [ 29.758952911392406, -1.428141551724138 ], [ 29.758683417721517, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9344, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.428141551724138 ], [ 29.759491898734176, -1.428680517241379 ], [ 29.759761392405064, -1.428680517241379 ], [ 29.759761392405064, -1.428141551724138 ], [ 29.759491898734176, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9345, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.428141551724138 ], [ 29.760300379746834, -1.428680517241379 ], [ 29.760569873417722, -1.428680517241379 ], [ 29.760569873417722, -1.428141551724138 ], [ 29.760300379746834, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9346, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.428141551724138 ], [ 29.760839367088607, -1.428680517241379 ], [ 29.761108860759492, -1.428680517241379 ], [ 29.761108860759492, -1.428141551724138 ], [ 29.760839367088607, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9347, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.428141551724138 ], [ 29.761647848101266, -1.428680517241379 ], [ 29.761917341772151, -1.428680517241379 ], [ 29.761917341772151, -1.428141551724138 ], [ 29.761647848101266, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9348, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.428141551724138 ], [ 29.762995316455697, -1.428411034482759 ], [ 29.763264810126582, -1.428411034482759 ], [ 29.763264810126582, -1.428141551724138 ], [ 29.762995316455697, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9349, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.428141551724138 ], [ 29.763534303797467, -1.428411034482759 ], [ 29.763803797468356, -1.428411034482759 ], [ 29.763803797468356, -1.428141551724138 ], [ 29.763534303797467, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9350, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.428141551724138 ], [ 29.763803797468356, -1.428411034482759 ], [ 29.76407329113924, -1.428411034482759 ], [ 29.76407329113924, -1.428141551724138 ], [ 29.763803797468356, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9351, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.428141551724138 ], [ 29.76407329113924, -1.428411034482759 ], [ 29.764342784810125, -1.428411034482759 ], [ 29.764342784810125, -1.428141551724138 ], [ 29.76407329113924, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9352, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.428141551724138 ], [ 29.764342784810125, -1.428411034482759 ], [ 29.764612278481014, -1.428411034482759 ], [ 29.764612278481014, -1.428141551724138 ], [ 29.764342784810125, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9353, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.428141551724138 ], [ 29.764612278481014, -1.428411034482759 ], [ 29.764881772151899, -1.428411034482759 ], [ 29.764881772151899, -1.428141551724138 ], [ 29.764612278481014, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9354, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.428141551724138 ], [ 29.764881772151899, -1.428411034482759 ], [ 29.765151265822784, -1.428411034482759 ], [ 29.765151265822784, -1.428141551724138 ], [ 29.764881772151899, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9355, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.428141551724138 ], [ 29.765151265822784, -1.428411034482759 ], [ 29.765420759493672, -1.428411034482759 ], [ 29.765420759493672, -1.428141551724138 ], [ 29.765151265822784, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9356, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.428141551724138 ], [ 29.765420759493672, -1.428411034482759 ], [ 29.765959746835442, -1.428411034482759 ], [ 29.765959746835442, -1.428141551724138 ], [ 29.765420759493672, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9357, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.428141551724138 ], [ 29.765959746835442, -1.428411034482759 ], [ 29.766229240506327, -1.428411034482759 ], [ 29.766229240506327, -1.428141551724138 ], [ 29.765959746835442, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9358, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.428141551724138 ], [ 29.766229240506327, -1.428411034482759 ], [ 29.766498734177215, -1.428411034482759 ], [ 29.766498734177215, -1.428141551724138 ], [ 29.766229240506327, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9359, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.428141551724138 ], [ 29.766498734177215, -1.428411034482759 ], [ 29.7667682278481, -1.428411034482759 ], [ 29.7667682278481, -1.428141551724138 ], [ 29.766498734177215, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9360, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.428141551724138 ], [ 29.7667682278481, -1.428411034482759 ], [ 29.767037721518985, -1.428411034482759 ], [ 29.767037721518985, -1.428141551724138 ], [ 29.7667682278481, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9361, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.428141551724138 ], [ 29.767037721518985, -1.428411034482759 ], [ 29.767307215189874, -1.428411034482759 ], [ 29.767307215189874, -1.428141551724138 ], [ 29.767037721518985, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9362, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.428141551724138 ], [ 29.767307215189874, -1.428411034482759 ], [ 29.767576708860759, -1.428411034482759 ], [ 29.767576708860759, -1.428141551724138 ], [ 29.767307215189874, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9363, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.428141551724138 ], [ 29.767576708860759, -1.428411034482759 ], [ 29.767846202531643, -1.428411034482759 ], [ 29.767846202531643, -1.428141551724138 ], [ 29.767576708860759, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9364, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.428141551724138 ], [ 29.767846202531643, -1.428411034482759 ], [ 29.768115696202532, -1.428411034482759 ], [ 29.768115696202532, -1.428141551724138 ], [ 29.767846202531643, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9365, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.428141551724138 ], [ 29.768115696202532, -1.428411034482759 ], [ 29.768385189873417, -1.428411034482759 ], [ 29.768385189873417, -1.428141551724138 ], [ 29.768115696202532, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9366, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.428141551724138 ], [ 29.768385189873417, -1.428411034482759 ], [ 29.768654683544302, -1.428411034482759 ], [ 29.768654683544302, -1.428141551724138 ], [ 29.768385189873417, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9367, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.428141551724138 ], [ 29.768654683544302, -1.428411034482759 ], [ 29.76892417721519, -1.428411034482759 ], [ 29.76892417721519, -1.428141551724138 ], [ 29.768654683544302, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9368, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.428141551724138 ], [ 29.76892417721519, -1.428411034482759 ], [ 29.769193670886075, -1.428411034482759 ], [ 29.769193670886075, -1.428141551724138 ], [ 29.76892417721519, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9369, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.428141551724138 ], [ 29.769193670886075, -1.428411034482759 ], [ 29.76946316455696, -1.428411034482759 ], [ 29.76946316455696, -1.428141551724138 ], [ 29.769193670886075, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9370, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.428141551724138 ], [ 29.76946316455696, -1.428411034482759 ], [ 29.769732658227849, -1.428411034482759 ], [ 29.769732658227849, -1.428141551724138 ], [ 29.76946316455696, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9371, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.428141551724138 ], [ 29.769732658227849, -1.428411034482759 ], [ 29.770002151898733, -1.428411034482759 ], [ 29.770002151898733, -1.428141551724138 ], [ 29.769732658227849, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9372, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.428141551724138 ], [ 29.770002151898733, -1.428411034482759 ], [ 29.770271645569618, -1.428411034482759 ], [ 29.770271645569618, -1.428141551724138 ], [ 29.770002151898733, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9373, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.428141551724138 ], [ 29.770271645569618, -1.428411034482759 ], [ 29.770541139240507, -1.428411034482759 ], [ 29.770541139240507, -1.428141551724138 ], [ 29.770271645569618, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9374, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.428141551724138 ], [ 29.770541139240507, -1.428411034482759 ], [ 29.770810632911392, -1.428411034482759 ], [ 29.770810632911392, -1.428141551724138 ], [ 29.770541139240507, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9375, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.428141551724138 ], [ 29.770810632911392, -1.428411034482759 ], [ 29.771080126582277, -1.428411034482759 ], [ 29.771080126582277, -1.428141551724138 ], [ 29.770810632911392, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9376, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.428141551724138 ], [ 29.771080126582277, -1.428411034482759 ], [ 29.771349620253165, -1.428411034482759 ], [ 29.771349620253165, -1.428141551724138 ], [ 29.771080126582277, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9377, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.428141551724138 ], [ 29.771349620253165, -1.428411034482759 ], [ 29.77161911392405, -1.428411034482759 ], [ 29.77161911392405, -1.428141551724138 ], [ 29.771349620253165, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9378, "El": 157 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.428141551724138 ], [ 29.77161911392405, -1.428411034482759 ], [ 29.771888607594935, -1.428411034482759 ], [ 29.771888607594935, -1.428680517241379 ], [ 29.772158101265823, -1.428680517241379 ], [ 29.772158101265823, -1.428141551724138 ], [ 29.77161911392405, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9379, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.428141551724138 ], [ 29.778895443037975, -1.428680517241379 ], [ 29.77916493670886, -1.428680517241379 ], [ 29.77916493670886, -1.428141551724138 ], [ 29.778895443037975, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9380, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.428141551724138 ], [ 29.779703924050633, -1.428680517241379 ], [ 29.779973417721518, -1.428680517241379 ], [ 29.779973417721518, -1.428141551724138 ], [ 29.779703924050633, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9381, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.428141551724138 ], [ 29.780242911392406, -1.428680517241379 ], [ 29.780512405063291, -1.428680517241379 ], [ 29.780512405063291, -1.428141551724138 ], [ 29.780242911392406, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9382, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.428141551724138 ], [ 29.781051392405065, -1.428680517241379 ], [ 29.78132088607595, -1.428680517241379 ], [ 29.78132088607595, -1.428141551724138 ], [ 29.781051392405065, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9383, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.428141551724138 ], [ 29.781859873417723, -1.428680517241379 ], [ 29.782129367088608, -1.428680517241379 ], [ 29.782129367088608, -1.428141551724138 ], [ 29.781859873417723, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9384, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.428141551724138 ], [ 29.782398860759493, -1.428411034482759 ], [ 29.782668354430381, -1.428411034482759 ], [ 29.782668354430381, -1.428141551724138 ], [ 29.782398860759493, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9385, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.428141551724138 ], [ 29.782668354430381, -1.428411034482759 ], [ 29.782937848101266, -1.428411034482759 ], [ 29.782937848101266, -1.428141551724138 ], [ 29.782668354430381, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9386, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.428141551724138 ], [ 29.783207341772151, -1.428411034482759 ], [ 29.783476835443039, -1.428411034482759 ], [ 29.783476835443039, -1.428141551724138 ], [ 29.783207341772151, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9387, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.428141551724138 ], [ 29.783476835443039, -1.428411034482759 ], [ 29.784015822784809, -1.428411034482759 ], [ 29.784015822784809, -1.428141551724138 ], [ 29.783476835443039, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9388, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.428141551724138 ], [ 29.784015822784809, -1.428411034482759 ], [ 29.784285316455698, -1.428411034482759 ], [ 29.784285316455698, -1.428141551724138 ], [ 29.784015822784809, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9389, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.428141551724138 ], [ 29.784285316455698, -1.428411034482759 ], [ 29.784554810126583, -1.428411034482759 ], [ 29.784554810126583, -1.428141551724138 ], [ 29.784285316455698, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9390, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.428141551724138 ], [ 29.784554810126583, -1.428411034482759 ], [ 29.784824303797468, -1.428411034482759 ], [ 29.784824303797468, -1.428141551724138 ], [ 29.784554810126583, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9391, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.428141551724138 ], [ 29.784824303797468, -1.428411034482759 ], [ 29.785093797468356, -1.428411034482759 ], [ 29.785093797468356, -1.428141551724138 ], [ 29.784824303797468, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9392, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.428141551724138 ], [ 29.785093797468356, -1.428680517241379 ], [ 29.785363291139241, -1.428680517241379 ], [ 29.785363291139241, -1.428141551724138 ], [ 29.785093797468356, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9393, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.428141551724138 ], [ 29.788597215189874, -1.428411034482759 ], [ 29.788866708860759, -1.428411034482759 ], [ 29.788866708860759, -1.428141551724138 ], [ 29.788597215189874, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9394, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.428141551724138 ], [ 29.788866708860759, -1.428411034482759 ], [ 29.789136202531644, -1.428411034482759 ], [ 29.789136202531644, -1.428141551724138 ], [ 29.788866708860759, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9395, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.428141551724138 ], [ 29.789136202531644, -1.428411034482759 ], [ 29.789405696202532, -1.428411034482759 ], [ 29.789405696202532, -1.428141551724138 ], [ 29.789136202531644, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9396, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.428141551724138 ], [ 29.799107468354432, -1.428411034482759 ], [ 29.799376962025317, -1.428411034482759 ], [ 29.799376962025317, -1.428141551724138 ], [ 29.799107468354432, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9397, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.428141551724138 ], [ 29.799376962025317, -1.428411034482759 ], [ 29.799646455696202, -1.428411034482759 ], [ 29.799646455696202, -1.428141551724138 ], [ 29.799376962025317, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9398, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.428141551724138 ], [ 29.799646455696202, -1.428411034482759 ], [ 29.79991594936709, -1.428411034482759 ], [ 29.79991594936709, -1.428141551724138 ], [ 29.799646455696202, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9399, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.428141551724138 ], [ 29.79991594936709, -1.428411034482759 ], [ 29.800185443037975, -1.428411034482759 ], [ 29.800185443037975, -1.428141551724138 ], [ 29.79991594936709, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9400, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.428141551724138 ], [ 29.800185443037975, -1.428411034482759 ], [ 29.80045493670886, -1.428411034482759 ], [ 29.80045493670886, -1.428141551724138 ], [ 29.800185443037975, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9401, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.428141551724138 ], [ 29.80045493670886, -1.428411034482759 ], [ 29.800724430379748, -1.428411034482759 ], [ 29.800724430379748, -1.428141551724138 ], [ 29.80045493670886, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.428141551724138 ], [ 29.800724430379748, -1.428411034482759 ], [ 29.800993924050633, -1.428411034482759 ], [ 29.800993924050633, -1.428141551724138 ], [ 29.800724430379748, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9403, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.428141551724138 ], [ 29.800993924050633, -1.428411034482759 ], [ 29.801263417721518, -1.428411034482759 ], [ 29.801263417721518, -1.428141551724138 ], [ 29.800993924050633, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9404, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.428141551724138 ], [ 29.801263417721518, -1.428411034482759 ], [ 29.801532911392407, -1.428411034482759 ], [ 29.801532911392407, -1.428141551724138 ], [ 29.801263417721518, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9405, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.428141551724138 ], [ 29.801532911392407, -1.428411034482759 ], [ 29.802071898734177, -1.428411034482759 ], [ 29.802071898734177, -1.428141551724138 ], [ 29.801532911392407, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9406, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.428141551724138 ], [ 29.802071898734177, -1.428411034482759 ], [ 29.802341392405065, -1.428411034482759 ], [ 29.802341392405065, -1.428141551724138 ], [ 29.802071898734177, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9407, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.428141551724138 ], [ 29.802341392405065, -1.428411034482759 ], [ 29.80261088607595, -1.428411034482759 ], [ 29.80261088607595, -1.428141551724138 ], [ 29.802341392405065, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9408, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.428141551724138 ], [ 29.80261088607595, -1.428411034482759 ], [ 29.802880379746835, -1.428411034482759 ], [ 29.802880379746835, -1.428141551724138 ], [ 29.80261088607595, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9409, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.428141551724138 ], [ 29.802880379746835, -1.428411034482759 ], [ 29.803149873417723, -1.428411034482759 ], [ 29.803149873417723, -1.428141551724138 ], [ 29.802880379746835, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9410, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.428141551724138 ], [ 29.803149873417723, -1.428411034482759 ], [ 29.803419367088608, -1.428411034482759 ], [ 29.803419367088608, -1.428141551724138 ], [ 29.803149873417723, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9411, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.428141551724138 ], [ 29.803419367088608, -1.428411034482759 ], [ 29.803688860759493, -1.428411034482759 ], [ 29.803688860759493, -1.428141551724138 ], [ 29.803419367088608, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9412, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.428141551724138 ], [ 29.803958354430382, -1.428411034482759 ], [ 29.804227848101267, -1.428411034482759 ], [ 29.804227848101267, -1.428141551724138 ], [ 29.803958354430382, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9413, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.428141551724138 ], [ 29.804227848101267, -1.428411034482759 ], [ 29.804497341772151, -1.428411034482759 ], [ 29.804497341772151, -1.428141551724138 ], [ 29.804227848101267, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9414, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.428141551724138 ], [ 29.80476683544304, -1.428411034482759 ], [ 29.805036329113925, -1.428411034482759 ], [ 29.805036329113925, -1.428141551724138 ], [ 29.80476683544304, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9415, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.428141551724138 ], [ 29.805036329113925, -1.428411034482759 ], [ 29.80530582278481, -1.428411034482759 ], [ 29.80530582278481, -1.428141551724138 ], [ 29.805036329113925, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9416, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.428141551724138 ], [ 29.80530582278481, -1.428411034482759 ], [ 29.805575316455698, -1.428411034482759 ], [ 29.805575316455698, -1.428141551724138 ], [ 29.80530582278481, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9417, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.428141551724138 ], [ 29.805575316455698, -1.428411034482759 ], [ 29.805844810126583, -1.428411034482759 ], [ 29.805844810126583, -1.428141551724138 ], [ 29.805575316455698, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9418, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.428141551724138 ], [ 29.805844810126583, -1.428411034482759 ], [ 29.806114303797468, -1.428411034482759 ], [ 29.806114303797468, -1.428141551724138 ], [ 29.805844810126583, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9419, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.428141551724138 ], [ 29.806114303797468, -1.428411034482759 ], [ 29.806383797468357, -1.428411034482759 ], [ 29.806383797468357, -1.428141551724138 ], [ 29.806114303797468, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9420, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.428141551724138 ], [ 29.806383797468357, -1.428411034482759 ], [ 29.806653291139241, -1.428411034482759 ], [ 29.806653291139241, -1.428141551724138 ], [ 29.806383797468357, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9421, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.428141551724138 ], [ 29.806653291139241, -1.428411034482759 ], [ 29.806922784810126, -1.428411034482759 ], [ 29.806922784810126, -1.428141551724138 ], [ 29.806653291139241, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9422, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.428141551724138 ], [ 29.806922784810126, -1.428411034482759 ], [ 29.807192278481015, -1.428411034482759 ], [ 29.807192278481015, -1.428141551724138 ], [ 29.806922784810126, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9423, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.428141551724138 ], [ 29.807192278481015, -1.428411034482759 ], [ 29.8074617721519, -1.428411034482759 ], [ 29.8074617721519, -1.428141551724138 ], [ 29.807192278481015, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9424, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.428141551724138 ], [ 29.8074617721519, -1.428411034482759 ], [ 29.807731265822785, -1.428411034482759 ], [ 29.807731265822785, -1.428141551724138 ], [ 29.8074617721519, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9425, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.428411034482759 ], [ 29.755718987341773, -1.428680517241379 ], [ 29.755988481012658, -1.428680517241379 ], [ 29.755988481012658, -1.428411034482759 ], [ 29.755718987341773, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9426, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.428411034482759 ], [ 29.755988481012658, -1.428680517241379 ], [ 29.756257974683542, -1.428680517241379 ], [ 29.756257974683542, -1.428411034482759 ], [ 29.755988481012658, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9427, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.428411034482759 ], [ 29.756257974683542, -1.428680517241379 ], [ 29.756796962025316, -1.428680517241379 ], [ 29.756796962025316, -1.428411034482759 ], [ 29.756257974683542, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9428, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.428411034482759 ], [ 29.756796962025316, -1.428680517241379 ], [ 29.757066455696201, -1.428680517241379 ], [ 29.757066455696201, -1.428411034482759 ], [ 29.756796962025316, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9429, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.428411034482759 ], [ 29.757066455696201, -1.428680517241379 ], [ 29.757335949367089, -1.428680517241379 ], [ 29.757335949367089, -1.428411034482759 ], [ 29.757066455696201, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9430, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.428411034482759 ], [ 29.757335949367089, -1.428680517241379 ], [ 29.757605443037974, -1.428680517241379 ], [ 29.757605443037974, -1.428411034482759 ], [ 29.757335949367089, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9431, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.428411034482759 ], [ 29.757605443037974, -1.428680517241379 ], [ 29.757874936708859, -1.428680517241379 ], [ 29.757874936708859, -1.428411034482759 ], [ 29.757605443037974, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9432, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.428411034482759 ], [ 29.757874936708859, -1.428680517241379 ], [ 29.758144430379748, -1.428680517241379 ], [ 29.758144430379748, -1.428411034482759 ], [ 29.757874936708859, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9433, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.428411034482759 ], [ 29.758144430379748, -1.428680517241379 ], [ 29.758413924050632, -1.428680517241379 ], [ 29.758413924050632, -1.428411034482759 ], [ 29.758144430379748, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9434, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.428411034482759 ], [ 29.758413924050632, -1.428680517241379 ], [ 29.758683417721517, -1.428680517241379 ], [ 29.758683417721517, -1.428411034482759 ], [ 29.758413924050632, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9435, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.428411034482759 ], [ 29.758952911392406, -1.428680517241379 ], [ 29.759222405063291, -1.428680517241379 ], [ 29.759222405063291, -1.428411034482759 ], [ 29.758952911392406, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9436, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.428411034482759 ], [ 29.759222405063291, -1.428680517241379 ], [ 29.759491898734176, -1.428680517241379 ], [ 29.759491898734176, -1.428411034482759 ], [ 29.759222405063291, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9437, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.428411034482759 ], [ 29.760030886075949, -1.428680517241379 ], [ 29.760300379746834, -1.428680517241379 ], [ 29.760300379746834, -1.428411034482759 ], [ 29.760030886075949, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9438, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.428411034482759 ], [ 29.762725822784809, -1.428680517241379 ], [ 29.762995316455697, -1.428680517241379 ], [ 29.762995316455697, -1.428411034482759 ], [ 29.762725822784809, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9439, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.428411034482759 ], [ 29.762995316455697, -1.428680517241379 ], [ 29.763264810126582, -1.428680517241379 ], [ 29.763264810126582, -1.428411034482759 ], [ 29.762995316455697, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9440, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.428411034482759 ], [ 29.763264810126582, -1.428680517241379 ], [ 29.763534303797467, -1.428680517241379 ], [ 29.763534303797467, -1.428411034482759 ], [ 29.763264810126582, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9441, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.428411034482759 ], [ 29.763534303797467, -1.428680517241379 ], [ 29.763803797468356, -1.428680517241379 ], [ 29.763803797468356, -1.428411034482759 ], [ 29.763534303797467, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9442, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.428411034482759 ], [ 29.763803797468356, -1.428680517241379 ], [ 29.76407329113924, -1.428680517241379 ], [ 29.76407329113924, -1.428411034482759 ], [ 29.763803797468356, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9443, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.428411034482759 ], [ 29.76407329113924, -1.428680517241379 ], [ 29.764342784810125, -1.428680517241379 ], [ 29.764342784810125, -1.428411034482759 ], [ 29.76407329113924, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9444, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.428411034482759 ], [ 29.764342784810125, -1.428680517241379 ], [ 29.764612278481014, -1.428680517241379 ], [ 29.764612278481014, -1.428411034482759 ], [ 29.764342784810125, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9445, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.428411034482759 ], [ 29.764612278481014, -1.428680517241379 ], [ 29.764881772151899, -1.428680517241379 ], [ 29.764881772151899, -1.428411034482759 ], [ 29.764612278481014, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9446, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.428411034482759 ], [ 29.764881772151899, -1.428680517241379 ], [ 29.765151265822784, -1.428680517241379 ], [ 29.765151265822784, -1.428411034482759 ], [ 29.764881772151899, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9447, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.428411034482759 ], [ 29.765151265822784, -1.428680517241379 ], [ 29.765420759493672, -1.428680517241379 ], [ 29.765420759493672, -1.428411034482759 ], [ 29.765151265822784, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9448, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.428411034482759 ], [ 29.765420759493672, -1.428680517241379 ], [ 29.765959746835442, -1.428680517241379 ], [ 29.765959746835442, -1.428411034482759 ], [ 29.765420759493672, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9449, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.428411034482759 ], [ 29.765959746835442, -1.428680517241379 ], [ 29.766229240506327, -1.428680517241379 ], [ 29.766229240506327, -1.428411034482759 ], [ 29.765959746835442, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9450, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.428411034482759 ], [ 29.766229240506327, -1.428680517241379 ], [ 29.766498734177215, -1.428680517241379 ], [ 29.766498734177215, -1.428411034482759 ], [ 29.766229240506327, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9451, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.428411034482759 ], [ 29.766498734177215, -1.428680517241379 ], [ 29.7667682278481, -1.428680517241379 ], [ 29.7667682278481, -1.428411034482759 ], [ 29.766498734177215, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9452, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.428411034482759 ], [ 29.7667682278481, -1.428680517241379 ], [ 29.767037721518985, -1.428680517241379 ], [ 29.767037721518985, -1.428411034482759 ], [ 29.7667682278481, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9453, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.428411034482759 ], [ 29.767037721518985, -1.428680517241379 ], [ 29.767307215189874, -1.428680517241379 ], [ 29.767307215189874, -1.428411034482759 ], [ 29.767037721518985, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9454, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.428411034482759 ], [ 29.767307215189874, -1.428680517241379 ], [ 29.767576708860759, -1.428680517241379 ], [ 29.767576708860759, -1.428411034482759 ], [ 29.767307215189874, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9455, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.428411034482759 ], [ 29.767576708860759, -1.428680517241379 ], [ 29.767846202531643, -1.428680517241379 ], [ 29.767846202531643, -1.428411034482759 ], [ 29.767576708860759, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9456, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.428411034482759 ], [ 29.767846202531643, -1.428680517241379 ], [ 29.768115696202532, -1.428680517241379 ], [ 29.768115696202532, -1.428411034482759 ], [ 29.767846202531643, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9457, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.428411034482759 ], [ 29.768115696202532, -1.428680517241379 ], [ 29.768385189873417, -1.428680517241379 ], [ 29.768385189873417, -1.428411034482759 ], [ 29.768115696202532, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9458, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.428411034482759 ], [ 29.768385189873417, -1.428680517241379 ], [ 29.768654683544302, -1.428680517241379 ], [ 29.768654683544302, -1.428411034482759 ], [ 29.768385189873417, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9459, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.428411034482759 ], [ 29.768654683544302, -1.428680517241379 ], [ 29.76892417721519, -1.428680517241379 ], [ 29.76892417721519, -1.428411034482759 ], [ 29.768654683544302, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9460, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.428411034482759 ], [ 29.76892417721519, -1.428680517241379 ], [ 29.769193670886075, -1.428680517241379 ], [ 29.769193670886075, -1.428411034482759 ], [ 29.76892417721519, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9461, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.428411034482759 ], [ 29.769193670886075, -1.428680517241379 ], [ 29.76946316455696, -1.428680517241379 ], [ 29.76946316455696, -1.428411034482759 ], [ 29.769193670886075, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9462, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.428411034482759 ], [ 29.76946316455696, -1.428680517241379 ], [ 29.769732658227849, -1.428680517241379 ], [ 29.769732658227849, -1.428411034482759 ], [ 29.76946316455696, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9463, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.428411034482759 ], [ 29.769732658227849, -1.428680517241379 ], [ 29.770002151898733, -1.428680517241379 ], [ 29.770002151898733, -1.428411034482759 ], [ 29.769732658227849, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9464, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.428411034482759 ], [ 29.770002151898733, -1.428680517241379 ], [ 29.770271645569618, -1.428680517241379 ], [ 29.770271645569618, -1.428411034482759 ], [ 29.770002151898733, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9465, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.428411034482759 ], [ 29.770271645569618, -1.428680517241379 ], [ 29.770541139240507, -1.428680517241379 ], [ 29.770541139240507, -1.428411034482759 ], [ 29.770271645569618, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9466, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.428411034482759 ], [ 29.770541139240507, -1.428680517241379 ], [ 29.770810632911392, -1.428680517241379 ], [ 29.770810632911392, -1.428411034482759 ], [ 29.770541139240507, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9467, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.428411034482759 ], [ 29.770810632911392, -1.428680517241379 ], [ 29.771080126582277, -1.428680517241379 ], [ 29.771080126582277, -1.428411034482759 ], [ 29.770810632911392, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9468, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.428411034482759 ], [ 29.771080126582277, -1.428680517241379 ], [ 29.771349620253165, -1.428680517241379 ], [ 29.771349620253165, -1.428411034482759 ], [ 29.771080126582277, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9469, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.428411034482759 ], [ 29.771349620253165, -1.428680517241379 ], [ 29.77161911392405, -1.428680517241379 ], [ 29.77161911392405, -1.428411034482759 ], [ 29.771349620253165, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9470, "El": 155 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.428411034482759 ], [ 29.77161911392405, -1.428680517241379 ], [ 29.771888607594935, -1.428680517241379 ], [ 29.771888607594935, -1.428411034482759 ], [ 29.77161911392405, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9471, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.428411034482759 ], [ 29.780512405063291, -1.428680517241379 ], [ 29.780781898734176, -1.428680517241379 ], [ 29.780781898734176, -1.428411034482759 ], [ 29.780512405063291, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9472, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.428411034482759 ], [ 29.78132088607595, -1.428680517241379 ], [ 29.781590379746834, -1.428680517241379 ], [ 29.781590379746834, -1.428411034482759 ], [ 29.78132088607595, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9473, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.428411034482759 ], [ 29.781590379746834, -1.428680517241379 ], [ 29.781859873417723, -1.428680517241379 ], [ 29.781859873417723, -1.428411034482759 ], [ 29.781590379746834, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9474, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.428411034482759 ], [ 29.782129367088608, -1.428680517241379 ], [ 29.782398860759493, -1.428680517241379 ], [ 29.782398860759493, -1.428411034482759 ], [ 29.782129367088608, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9475, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.428411034482759 ], [ 29.782398860759493, -1.428680517241379 ], [ 29.782668354430381, -1.428680517241379 ], [ 29.782668354430381, -1.428411034482759 ], [ 29.782398860759493, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9476, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.428411034482759 ], [ 29.782668354430381, -1.428680517241379 ], [ 29.782937848101266, -1.428680517241379 ], [ 29.782937848101266, -1.428411034482759 ], [ 29.782668354430381, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9477, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.428411034482759 ], [ 29.782937848101266, -1.428680517241379 ], [ 29.783207341772151, -1.428680517241379 ], [ 29.783207341772151, -1.428411034482759 ], [ 29.782937848101266, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9478, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.428411034482759 ], [ 29.783207341772151, -1.428680517241379 ], [ 29.783476835443039, -1.428680517241379 ], [ 29.783476835443039, -1.428411034482759 ], [ 29.783207341772151, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9479, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.428411034482759 ], [ 29.783476835443039, -1.428680517241379 ], [ 29.784015822784809, -1.428680517241379 ], [ 29.784015822784809, -1.428411034482759 ], [ 29.783476835443039, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9480, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.428411034482759 ], [ 29.784015822784809, -1.428680517241379 ], [ 29.784285316455698, -1.428680517241379 ], [ 29.784285316455698, -1.428411034482759 ], [ 29.784015822784809, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9481, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.428411034482759 ], [ 29.784285316455698, -1.428680517241379 ], [ 29.784554810126583, -1.428680517241379 ], [ 29.784554810126583, -1.428411034482759 ], [ 29.784285316455698, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9482, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.428411034482759 ], [ 29.784554810126583, -1.428680517241379 ], [ 29.784824303797468, -1.428680517241379 ], [ 29.784824303797468, -1.428411034482759 ], [ 29.784554810126583, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9483, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.428411034482759 ], [ 29.784824303797468, -1.428680517241379 ], [ 29.785093797468356, -1.428680517241379 ], [ 29.785093797468356, -1.428411034482759 ], [ 29.784824303797468, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9484, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.428411034482759 ], [ 29.793448101265824, -1.428680517241379 ], [ 29.793717594936709, -1.428680517241379 ], [ 29.793717594936709, -1.428411034482759 ], [ 29.793448101265824, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9485, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.428411034482759 ], [ 29.793717594936709, -1.428680517241379 ], [ 29.793987088607594, -1.428680517241379 ], [ 29.793987088607594, -1.428411034482759 ], [ 29.793717594936709, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9486, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.428411034482759 ], [ 29.793987088607594, -1.428680517241379 ], [ 29.794256582278482, -1.428680517241379 ], [ 29.794256582278482, -1.428411034482759 ], [ 29.793987088607594, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9487, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.428411034482759 ], [ 29.799107468354432, -1.428680517241379 ], [ 29.799376962025317, -1.428680517241379 ], [ 29.799376962025317, -1.428411034482759 ], [ 29.799107468354432, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9488, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.428411034482759 ], [ 29.799376962025317, -1.428680517241379 ], [ 29.799646455696202, -1.428680517241379 ], [ 29.799646455696202, -1.428411034482759 ], [ 29.799376962025317, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9489, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.428411034482759 ], [ 29.799646455696202, -1.428680517241379 ], [ 29.800185443037975, -1.428680517241379 ], [ 29.800185443037975, -1.428411034482759 ], [ 29.799646455696202, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9490, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.428411034482759 ], [ 29.800185443037975, -1.428680517241379 ], [ 29.80045493670886, -1.428680517241379 ], [ 29.80045493670886, -1.428411034482759 ], [ 29.800185443037975, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9491, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.428411034482759 ], [ 29.80045493670886, -1.428680517241379 ], [ 29.800724430379748, -1.428680517241379 ], [ 29.800724430379748, -1.428411034482759 ], [ 29.80045493670886, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9492, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.428411034482759 ], [ 29.800724430379748, -1.428680517241379 ], [ 29.800993924050633, -1.428680517241379 ], [ 29.800993924050633, -1.428411034482759 ], [ 29.800724430379748, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9493, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.428411034482759 ], [ 29.800993924050633, -1.428680517241379 ], [ 29.801263417721518, -1.428680517241379 ], [ 29.801263417721518, -1.428411034482759 ], [ 29.800993924050633, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9494, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.428411034482759 ], [ 29.801263417721518, -1.428680517241379 ], [ 29.801532911392407, -1.428680517241379 ], [ 29.801532911392407, -1.428411034482759 ], [ 29.801263417721518, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9495, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.428411034482759 ], [ 29.801532911392407, -1.428680517241379 ], [ 29.802071898734177, -1.428680517241379 ], [ 29.802071898734177, -1.428411034482759 ], [ 29.801532911392407, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9496, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.428411034482759 ], [ 29.802071898734177, -1.428680517241379 ], [ 29.802341392405065, -1.428680517241379 ], [ 29.802341392405065, -1.428411034482759 ], [ 29.802071898734177, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9497, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.428411034482759 ], [ 29.802341392405065, -1.428680517241379 ], [ 29.80261088607595, -1.428680517241379 ], [ 29.80261088607595, -1.428411034482759 ], [ 29.802341392405065, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9498, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.428411034482759 ], [ 29.80261088607595, -1.428680517241379 ], [ 29.802880379746835, -1.428680517241379 ], [ 29.802880379746835, -1.428411034482759 ], [ 29.80261088607595, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9499, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.428411034482759 ], [ 29.802880379746835, -1.428680517241379 ], [ 29.803149873417723, -1.428680517241379 ], [ 29.803149873417723, -1.428411034482759 ], [ 29.802880379746835, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9500, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.428411034482759 ], [ 29.803149873417723, -1.428680517241379 ], [ 29.803419367088608, -1.428680517241379 ], [ 29.803419367088608, -1.428411034482759 ], [ 29.803149873417723, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9501, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.428411034482759 ], [ 29.803419367088608, -1.428680517241379 ], [ 29.803688860759493, -1.428680517241379 ], [ 29.803688860759493, -1.428411034482759 ], [ 29.803419367088608, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9502, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.428411034482759 ], [ 29.803688860759493, -1.428680517241379 ], [ 29.803958354430382, -1.428680517241379 ], [ 29.803958354430382, -1.428411034482759 ], [ 29.803688860759493, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9503, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.428411034482759 ], [ 29.803958354430382, -1.428680517241379 ], [ 29.804227848101267, -1.428680517241379 ], [ 29.804227848101267, -1.428411034482759 ], [ 29.803958354430382, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9504, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.428411034482759 ], [ 29.804227848101267, -1.428680517241379 ], [ 29.804497341772151, -1.428680517241379 ], [ 29.804497341772151, -1.428411034482759 ], [ 29.804227848101267, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9505, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.428411034482759 ], [ 29.804497341772151, -1.428680517241379 ], [ 29.80476683544304, -1.428680517241379 ], [ 29.80476683544304, -1.428411034482759 ], [ 29.804497341772151, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9506, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.428411034482759 ], [ 29.80476683544304, -1.428680517241379 ], [ 29.805036329113925, -1.428680517241379 ], [ 29.805036329113925, -1.428411034482759 ], [ 29.80476683544304, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9507, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.428411034482759 ], [ 29.805036329113925, -1.428680517241379 ], [ 29.80530582278481, -1.428680517241379 ], [ 29.80530582278481, -1.428411034482759 ], [ 29.805036329113925, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9508, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.428411034482759 ], [ 29.80530582278481, -1.428680517241379 ], [ 29.805575316455698, -1.428680517241379 ], [ 29.805575316455698, -1.428411034482759 ], [ 29.80530582278481, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9509, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.428411034482759 ], [ 29.805575316455698, -1.428680517241379 ], [ 29.805844810126583, -1.428680517241379 ], [ 29.805844810126583, -1.428411034482759 ], [ 29.805575316455698, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9510, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.428411034482759 ], [ 29.805844810126583, -1.428680517241379 ], [ 29.806114303797468, -1.428680517241379 ], [ 29.806114303797468, -1.428411034482759 ], [ 29.805844810126583, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9511, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.428411034482759 ], [ 29.806114303797468, -1.428680517241379 ], [ 29.806383797468357, -1.428680517241379 ], [ 29.806383797468357, -1.428411034482759 ], [ 29.806114303797468, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9512, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.428411034482759 ], [ 29.806383797468357, -1.428680517241379 ], [ 29.806653291139241, -1.428680517241379 ], [ 29.806653291139241, -1.428411034482759 ], [ 29.806383797468357, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9513, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.428411034482759 ], [ 29.806653291139241, -1.428680517241379 ], [ 29.806922784810126, -1.428680517241379 ], [ 29.806922784810126, -1.428411034482759 ], [ 29.806653291139241, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9514, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.428411034482759 ], [ 29.806922784810126, -1.428680517241379 ], [ 29.807192278481015, -1.428680517241379 ], [ 29.807192278481015, -1.428411034482759 ], [ 29.806922784810126, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9515, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.428411034482759 ], [ 29.807192278481015, -1.428680517241379 ], [ 29.8074617721519, -1.428680517241379 ], [ 29.8074617721519, -1.428411034482759 ], [ 29.807192278481015, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9516, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.425716206896552 ], [ 29.772427594936708, -1.42895 ], [ 29.772697088607593, -1.42895 ], [ 29.772697088607593, -1.425716206896552 ], [ 29.772427594936708, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9517, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.425716206896552 ], [ 29.772966582278482, -1.42895 ], [ 29.773236075949367, -1.42895 ], [ 29.773236075949367, -1.425716206896552 ], [ 29.772966582278482, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9518, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.425716206896552 ], [ 29.77377506329114, -1.42895 ], [ 29.774044556962025, -1.42895 ], [ 29.774044556962025, -1.425716206896552 ], [ 29.77377506329114, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9519, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.425716206896552 ], [ 29.775392025316457, -1.42895 ], [ 29.775661518987341, -1.42895 ], [ 29.775661518987341, -1.425716206896552 ], [ 29.775392025316457, -1.425716206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9520, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.427333103448276 ], [ 29.791561645569619, -1.42895 ], [ 29.791831139240507, -1.42895 ], [ 29.791831139240507, -1.427333103448276 ], [ 29.791561645569619, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9521, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.427872068965517 ], [ 29.798298987341774, -1.429219482758621 ], [ 29.798568481012659, -1.429219482758621 ], [ 29.798568481012659, -1.427872068965517 ], [ 29.798298987341774, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9522, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.428141551724138 ], [ 29.773236075949367, -1.429219482758621 ], [ 29.773505569620252, -1.429219482758621 ], [ 29.773505569620252, -1.428141551724138 ], [ 29.773236075949367, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9523, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.428141551724138 ], [ 29.774853037974683, -1.42895 ], [ 29.775122531645568, -1.42895 ], [ 29.775122531645568, -1.428141551724138 ], [ 29.774853037974683, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9524, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.428141551724138 ], [ 29.776200506329115, -1.42895 ], [ 29.77647, -1.42895 ], [ 29.77647, -1.428141551724138 ], [ 29.776200506329115, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9525, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.428141551724138 ], [ 29.777547974683543, -1.42895 ], [ 29.777817468354431, -1.42895 ], [ 29.777817468354431, -1.428141551724138 ], [ 29.777547974683543, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9526, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.428141551724138 ], [ 29.798568481012659, -1.42895 ], [ 29.798837974683543, -1.42895 ], [ 29.798837974683543, -1.428141551724138 ], [ 29.798568481012659, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9527, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.428141551724138 ], [ 29.798837974683543, -1.42895 ], [ 29.799107468354432, -1.42895 ], [ 29.799107468354432, -1.428141551724138 ], [ 29.798837974683543, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9528, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.428411034482759 ], [ 29.755449493670884, -1.42895 ], [ 29.755718987341773, -1.42895 ], [ 29.755718987341773, -1.428411034482759 ], [ 29.755449493670884, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9529, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.428411034482759 ], [ 29.759761392405064, -1.42895 ], [ 29.760030886075949, -1.42895 ], [ 29.760030886075949, -1.428411034482759 ], [ 29.759761392405064, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9530, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.428411034482759 ], [ 29.760569873417722, -1.42895 ], [ 29.760839367088607, -1.42895 ], [ 29.760839367088607, -1.428411034482759 ], [ 29.760569873417722, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9531, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.428411034482759 ], [ 29.761378354430381, -1.42895 ], [ 29.761647848101266, -1.42895 ], [ 29.761647848101266, -1.428411034482759 ], [ 29.761378354430381, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9532, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.428411034482759 ], [ 29.762186835443039, -1.429219482758621 ], [ 29.762456329113924, -1.429219482758621 ], [ 29.762456329113924, -1.428411034482759 ], [ 29.762186835443039, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9533, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.428411034482759 ], [ 29.772697088607593, -1.429219482758621 ], [ 29.772966582278482, -1.429219482758621 ], [ 29.772966582278482, -1.428411034482759 ], [ 29.772697088607593, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9534, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.428411034482759 ], [ 29.774044556962025, -1.429219482758621 ], [ 29.77431405063291, -1.429219482758621 ], [ 29.77431405063291, -1.428411034482759 ], [ 29.774044556962025, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9535, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.428411034482759 ], [ 29.775661518987341, -1.429219482758621 ], [ 29.775931012658226, -1.429219482758621 ], [ 29.775931012658226, -1.428411034482759 ], [ 29.775661518987341, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9536, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.428411034482759 ], [ 29.777008987341773, -1.42895 ], [ 29.777278481012658, -1.42895 ], [ 29.777278481012658, -1.428411034482759 ], [ 29.777008987341773, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9537, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.428411034482759 ], [ 29.777817468354431, -1.42895 ], [ 29.778086962025316, -1.42895 ], [ 29.778086962025316, -1.428411034482759 ], [ 29.777817468354431, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9538, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.428411034482759 ], [ 29.778356455696201, -1.42895 ], [ 29.77862594936709, -1.42895 ], [ 29.77862594936709, -1.428411034482759 ], [ 29.778356455696201, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9539, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.428411034482759 ], [ 29.77916493670886, -1.42895 ], [ 29.779434430379748, -1.42895 ], [ 29.779434430379748, -1.428411034482759 ], [ 29.77916493670886, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9540, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.428411034482759 ], [ 29.779973417721518, -1.42895 ], [ 29.780242911392406, -1.42895 ], [ 29.780242911392406, -1.428411034482759 ], [ 29.779973417721518, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9541, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.428411034482759 ], [ 29.780781898734176, -1.42895 ], [ 29.781051392405065, -1.42895 ], [ 29.781051392405065, -1.428411034482759 ], [ 29.780781898734176, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9542, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.428411034482759 ], [ 29.792370126582277, -1.42895 ], [ 29.792909113924051, -1.42895 ], [ 29.792909113924051, -1.428411034482759 ], [ 29.792370126582277, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9543, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.428411034482759 ], [ 29.794256582278482, -1.42895 ], [ 29.794526075949367, -1.42895 ], [ 29.794526075949367, -1.428411034482759 ], [ 29.794256582278482, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9544, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.428680517241379 ], [ 29.755718987341773, -1.42895 ], [ 29.755988481012658, -1.42895 ], [ 29.755988481012658, -1.428680517241379 ], [ 29.755718987341773, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9545, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.428680517241379 ], [ 29.755988481012658, -1.42895 ], [ 29.756257974683542, -1.42895 ], [ 29.756257974683542, -1.428680517241379 ], [ 29.755988481012658, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9546, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.428680517241379 ], [ 29.756257974683542, -1.42895 ], [ 29.756796962025316, -1.42895 ], [ 29.756796962025316, -1.428680517241379 ], [ 29.756257974683542, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9547, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.428680517241379 ], [ 29.756796962025316, -1.42895 ], [ 29.757066455696201, -1.42895 ], [ 29.757066455696201, -1.428680517241379 ], [ 29.756796962025316, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9548, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.428680517241379 ], [ 29.757066455696201, -1.42895 ], [ 29.757335949367089, -1.42895 ], [ 29.757335949367089, -1.428680517241379 ], [ 29.757066455696201, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9549, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.428680517241379 ], [ 29.757335949367089, -1.42895 ], [ 29.757605443037974, -1.42895 ], [ 29.757605443037974, -1.428680517241379 ], [ 29.757335949367089, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9550, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.428680517241379 ], [ 29.757605443037974, -1.42895 ], [ 29.757874936708859, -1.42895 ], [ 29.757874936708859, -1.428680517241379 ], [ 29.757605443037974, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9551, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.428680517241379 ], [ 29.757874936708859, -1.42895 ], [ 29.758144430379748, -1.42895 ], [ 29.758144430379748, -1.428680517241379 ], [ 29.757874936708859, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9552, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.428680517241379 ], [ 29.758144430379748, -1.42895 ], [ 29.758413924050632, -1.42895 ], [ 29.758413924050632, -1.428680517241379 ], [ 29.758144430379748, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9553, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.428680517241379 ], [ 29.758413924050632, -1.42895 ], [ 29.758683417721517, -1.42895 ], [ 29.758683417721517, -1.428680517241379 ], [ 29.758413924050632, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9554, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.428680517241379 ], [ 29.758683417721517, -1.42895 ], [ 29.758952911392406, -1.42895 ], [ 29.758952911392406, -1.428680517241379 ], [ 29.758683417721517, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9555, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.428680517241379 ], [ 29.758952911392406, -1.42895 ], [ 29.759222405063291, -1.42895 ], [ 29.759222405063291, -1.428680517241379 ], [ 29.758952911392406, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9556, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.428680517241379 ], [ 29.759222405063291, -1.42895 ], [ 29.759491898734176, -1.42895 ], [ 29.759491898734176, -1.428680517241379 ], [ 29.759222405063291, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9557, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.428680517241379 ], [ 29.759491898734176, -1.42895 ], [ 29.759761392405064, -1.42895 ], [ 29.759761392405064, -1.428680517241379 ], [ 29.759491898734176, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9558, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.428680517241379 ], [ 29.760030886075949, -1.42895 ], [ 29.760300379746834, -1.42895 ], [ 29.760300379746834, -1.428680517241379 ], [ 29.760030886075949, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9559, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.428680517241379 ], [ 29.760300379746834, -1.42895 ], [ 29.760569873417722, -1.42895 ], [ 29.760569873417722, -1.428680517241379 ], [ 29.760300379746834, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9560, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.428680517241379 ], [ 29.760839367088607, -1.42895 ], [ 29.761108860759492, -1.42895 ], [ 29.761108860759492, -1.428680517241379 ], [ 29.760839367088607, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9561, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.428680517241379 ], [ 29.761108860759492, -1.42895 ], [ 29.761378354430381, -1.42895 ], [ 29.761378354430381, -1.428680517241379 ], [ 29.761108860759492, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9562, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.428680517241379 ], [ 29.761647848101266, -1.429219482758621 ], [ 29.761917341772151, -1.429219482758621 ], [ 29.761917341772151, -1.428680517241379 ], [ 29.761647848101266, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9563, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.428680517241379 ], [ 29.761917341772151, -1.42895 ], [ 29.762186835443039, -1.42895 ], [ 29.762186835443039, -1.428680517241379 ], [ 29.761917341772151, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9564, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.428680517241379 ], [ 29.762456329113924, -1.42895 ], [ 29.762725822784809, -1.42895 ], [ 29.762725822784809, -1.428680517241379 ], [ 29.762456329113924, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9565, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.428680517241379 ], [ 29.762725822784809, -1.42895 ], [ 29.762995316455697, -1.42895 ], [ 29.762995316455697, -1.428680517241379 ], [ 29.762725822784809, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9566, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.428680517241379 ], [ 29.762995316455697, -1.42895 ], [ 29.763264810126582, -1.42895 ], [ 29.763264810126582, -1.428680517241379 ], [ 29.762995316455697, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9567, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.428680517241379 ], [ 29.763264810126582, -1.42895 ], [ 29.763534303797467, -1.42895 ], [ 29.763534303797467, -1.428680517241379 ], [ 29.763264810126582, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9568, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.428680517241379 ], [ 29.763534303797467, -1.42895 ], [ 29.763803797468356, -1.42895 ], [ 29.763803797468356, -1.428680517241379 ], [ 29.763534303797467, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9569, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.428680517241379 ], [ 29.763803797468356, -1.42895 ], [ 29.76407329113924, -1.42895 ], [ 29.76407329113924, -1.428680517241379 ], [ 29.763803797468356, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9570, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.428680517241379 ], [ 29.76407329113924, -1.42895 ], [ 29.764342784810125, -1.42895 ], [ 29.764342784810125, -1.428680517241379 ], [ 29.76407329113924, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9571, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.428680517241379 ], [ 29.764342784810125, -1.42895 ], [ 29.764612278481014, -1.42895 ], [ 29.764612278481014, -1.428680517241379 ], [ 29.764342784810125, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9572, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.428680517241379 ], [ 29.764612278481014, -1.42895 ], [ 29.764881772151899, -1.42895 ], [ 29.764881772151899, -1.428680517241379 ], [ 29.764612278481014, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9573, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.428680517241379 ], [ 29.764881772151899, -1.42895 ], [ 29.765151265822784, -1.42895 ], [ 29.765151265822784, -1.428680517241379 ], [ 29.764881772151899, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9574, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.428680517241379 ], [ 29.765151265822784, -1.42895 ], [ 29.765420759493672, -1.42895 ], [ 29.765420759493672, -1.428680517241379 ], [ 29.765151265822784, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9575, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.428680517241379 ], [ 29.765420759493672, -1.42895 ], [ 29.765959746835442, -1.42895 ], [ 29.765959746835442, -1.428680517241379 ], [ 29.765420759493672, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9576, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.428680517241379 ], [ 29.765959746835442, -1.42895 ], [ 29.766229240506327, -1.42895 ], [ 29.766229240506327, -1.428680517241379 ], [ 29.765959746835442, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9577, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.428680517241379 ], [ 29.766229240506327, -1.42895 ], [ 29.766498734177215, -1.42895 ], [ 29.766498734177215, -1.428680517241379 ], [ 29.766229240506327, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9578, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.428680517241379 ], [ 29.766498734177215, -1.42895 ], [ 29.7667682278481, -1.42895 ], [ 29.7667682278481, -1.428680517241379 ], [ 29.766498734177215, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9579, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.428680517241379 ], [ 29.7667682278481, -1.42895 ], [ 29.767037721518985, -1.42895 ], [ 29.767037721518985, -1.428680517241379 ], [ 29.7667682278481, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9580, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.428680517241379 ], [ 29.767037721518985, -1.42895 ], [ 29.767307215189874, -1.42895 ], [ 29.767307215189874, -1.428680517241379 ], [ 29.767037721518985, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9581, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.428680517241379 ], [ 29.767307215189874, -1.42895 ], [ 29.767576708860759, -1.42895 ], [ 29.767576708860759, -1.428680517241379 ], [ 29.767307215189874, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9582, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.428680517241379 ], [ 29.767576708860759, -1.42895 ], [ 29.767846202531643, -1.42895 ], [ 29.767846202531643, -1.428680517241379 ], [ 29.767576708860759, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9583, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.428680517241379 ], [ 29.767846202531643, -1.42895 ], [ 29.768115696202532, -1.42895 ], [ 29.768115696202532, -1.428680517241379 ], [ 29.767846202531643, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9584, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.428680517241379 ], [ 29.768115696202532, -1.42895 ], [ 29.768385189873417, -1.42895 ], [ 29.768385189873417, -1.428680517241379 ], [ 29.768115696202532, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9585, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.428680517241379 ], [ 29.768385189873417, -1.42895 ], [ 29.768654683544302, -1.42895 ], [ 29.768654683544302, -1.428680517241379 ], [ 29.768385189873417, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9586, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.428680517241379 ], [ 29.768654683544302, -1.42895 ], [ 29.76892417721519, -1.42895 ], [ 29.76892417721519, -1.428680517241379 ], [ 29.768654683544302, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9587, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.428680517241379 ], [ 29.76892417721519, -1.42895 ], [ 29.769193670886075, -1.42895 ], [ 29.769193670886075, -1.428680517241379 ], [ 29.76892417721519, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9588, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.428680517241379 ], [ 29.769193670886075, -1.42895 ], [ 29.76946316455696, -1.42895 ], [ 29.76946316455696, -1.428680517241379 ], [ 29.769193670886075, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9589, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.428680517241379 ], [ 29.76946316455696, -1.42895 ], [ 29.769732658227849, -1.42895 ], [ 29.769732658227849, -1.428680517241379 ], [ 29.76946316455696, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9590, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.428680517241379 ], [ 29.769732658227849, -1.42895 ], [ 29.770002151898733, -1.42895 ], [ 29.770002151898733, -1.428680517241379 ], [ 29.769732658227849, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9591, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.428680517241379 ], [ 29.770002151898733, -1.42895 ], [ 29.770271645569618, -1.42895 ], [ 29.770271645569618, -1.428680517241379 ], [ 29.770002151898733, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9592, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.428680517241379 ], [ 29.770271645569618, -1.42895 ], [ 29.770541139240507, -1.42895 ], [ 29.770541139240507, -1.428680517241379 ], [ 29.770271645569618, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9593, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.428680517241379 ], [ 29.770541139240507, -1.42895 ], [ 29.770810632911392, -1.42895 ], [ 29.770810632911392, -1.428680517241379 ], [ 29.770541139240507, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9594, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.428680517241379 ], [ 29.770810632911392, -1.42895 ], [ 29.771080126582277, -1.42895 ], [ 29.771080126582277, -1.428680517241379 ], [ 29.770810632911392, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9595, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.428680517241379 ], [ 29.771080126582277, -1.42895 ], [ 29.771349620253165, -1.42895 ], [ 29.771349620253165, -1.428680517241379 ], [ 29.771080126582277, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9596, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.428680517241379 ], [ 29.771349620253165, -1.42895 ], [ 29.77161911392405, -1.42895 ], [ 29.77161911392405, -1.428680517241379 ], [ 29.771349620253165, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9597, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.428680517241379 ], [ 29.77161911392405, -1.42895 ], [ 29.771888607594935, -1.42895 ], [ 29.771888607594935, -1.428680517241379 ], [ 29.77161911392405, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9598, "El": 156 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.428680517241379 ], [ 29.771888607594935, -1.42895 ], [ 29.772158101265823, -1.42895 ], [ 29.772158101265823, -1.428680517241379 ], [ 29.771888607594935, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9599, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.428680517241379 ], [ 29.773505569620252, -1.429488965517241 ], [ 29.77377506329114, -1.429488965517241 ], [ 29.77377506329114, -1.428680517241379 ], [ 29.773505569620252, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9600, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.428680517241379 ], [ 29.77431405063291, -1.429488965517241 ], [ 29.774853037974683, -1.429488965517241 ], [ 29.774853037974683, -1.428680517241379 ], [ 29.77431405063291, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9601, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.428680517241379 ], [ 29.775122531645568, -1.429219482758621 ], [ 29.775392025316457, -1.429219482758621 ], [ 29.775392025316457, -1.428680517241379 ], [ 29.775122531645568, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9602, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.428680517241379 ], [ 29.775931012658226, -1.429219482758621 ], [ 29.776200506329115, -1.429219482758621 ], [ 29.776200506329115, -1.428680517241379 ], [ 29.775931012658226, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9603, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.428680517241379 ], [ 29.77647, -1.429219482758621 ], [ 29.776739493670885, -1.429219482758621 ], [ 29.776739493670885, -1.428680517241379 ], [ 29.77647, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9604, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.428680517241379 ], [ 29.776739493670885, -1.429219482758621 ], [ 29.777008987341773, -1.429219482758621 ], [ 29.777008987341773, -1.428680517241379 ], [ 29.776739493670885, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9605, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.428680517241379 ], [ 29.777278481012658, -1.429219482758621 ], [ 29.777547974683543, -1.429219482758621 ], [ 29.777547974683543, -1.428680517241379 ], [ 29.777278481012658, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9606, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.428680517241379 ], [ 29.778086962025316, -1.429219482758621 ], [ 29.778356455696201, -1.429219482758621 ], [ 29.778356455696201, -1.428680517241379 ], [ 29.778086962025316, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9607, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.428680517241379 ], [ 29.77862594936709, -1.42895 ], [ 29.778895443037975, -1.42895 ], [ 29.778895443037975, -1.428680517241379 ], [ 29.77862594936709, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9608, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.428680517241379 ], [ 29.778895443037975, -1.429219482758621 ], [ 29.77916493670886, -1.429219482758621 ], [ 29.77916493670886, -1.428680517241379 ], [ 29.778895443037975, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9609, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.428680517241379 ], [ 29.779434430379748, -1.42895 ], [ 29.779703924050633, -1.42895 ], [ 29.779703924050633, -1.428680517241379 ], [ 29.779434430379748, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9610, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.428680517241379 ], [ 29.779703924050633, -1.42895 ], [ 29.779973417721518, -1.42895 ], [ 29.779973417721518, -1.428680517241379 ], [ 29.779703924050633, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9611, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.428680517241379 ], [ 29.780242911392406, -1.42895 ], [ 29.780512405063291, -1.42895 ], [ 29.780512405063291, -1.428680517241379 ], [ 29.780242911392406, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9612, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.428680517241379 ], [ 29.780512405063291, -1.42895 ], [ 29.780781898734176, -1.42895 ], [ 29.780781898734176, -1.428680517241379 ], [ 29.780512405063291, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9613, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.428680517241379 ], [ 29.781051392405065, -1.42895 ], [ 29.78132088607595, -1.42895 ], [ 29.78132088607595, -1.428680517241379 ], [ 29.781051392405065, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9614, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.428680517241379 ], [ 29.78132088607595, -1.42895 ], [ 29.781590379746834, -1.42895 ], [ 29.781590379746834, -1.428680517241379 ], [ 29.78132088607595, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9615, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.428680517241379 ], [ 29.781590379746834, -1.42895 ], [ 29.781859873417723, -1.42895 ], [ 29.781859873417723, -1.428680517241379 ], [ 29.781590379746834, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9616, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.428680517241379 ], [ 29.781859873417723, -1.42895 ], [ 29.782129367088608, -1.42895 ], [ 29.782129367088608, -1.428680517241379 ], [ 29.781859873417723, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9617, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.428680517241379 ], [ 29.782129367088608, -1.42895 ], [ 29.782398860759493, -1.42895 ], [ 29.782398860759493, -1.428680517241379 ], [ 29.782129367088608, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9618, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.428680517241379 ], [ 29.782398860759493, -1.42895 ], [ 29.782668354430381, -1.42895 ], [ 29.782668354430381, -1.428680517241379 ], [ 29.782398860759493, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9619, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.428680517241379 ], [ 29.782668354430381, -1.42895 ], [ 29.782937848101266, -1.42895 ], [ 29.782937848101266, -1.428680517241379 ], [ 29.782668354430381, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9620, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.428680517241379 ], [ 29.782937848101266, -1.42895 ], [ 29.783207341772151, -1.42895 ], [ 29.783207341772151, -1.428680517241379 ], [ 29.782937848101266, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9621, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.428680517241379 ], [ 29.783207341772151, -1.42895 ], [ 29.783476835443039, -1.42895 ], [ 29.783476835443039, -1.428680517241379 ], [ 29.783207341772151, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9622, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.428680517241379 ], [ 29.783476835443039, -1.42895 ], [ 29.784015822784809, -1.42895 ], [ 29.784015822784809, -1.428680517241379 ], [ 29.783476835443039, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9623, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.428680517241379 ], [ 29.784015822784809, -1.42895 ], [ 29.784285316455698, -1.42895 ], [ 29.784285316455698, -1.428680517241379 ], [ 29.784015822784809, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9624, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.428680517241379 ], [ 29.784285316455698, -1.42895 ], [ 29.784554810126583, -1.42895 ], [ 29.784554810126583, -1.428680517241379 ], [ 29.784285316455698, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9625, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.428680517241379 ], [ 29.784554810126583, -1.42895 ], [ 29.784824303797468, -1.42895 ], [ 29.784824303797468, -1.428680517241379 ], [ 29.784554810126583, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9626, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.428680517241379 ], [ 29.784824303797468, -1.42895 ], [ 29.785093797468356, -1.42895 ], [ 29.785093797468356, -1.428680517241379 ], [ 29.784824303797468, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9627, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.428680517241379 ], [ 29.785093797468356, -1.42895 ], [ 29.785363291139241, -1.42895 ], [ 29.785363291139241, -1.428680517241379 ], [ 29.785093797468356, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9628, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.428680517241379 ], [ 29.785363291139241, -1.429758448275862 ], [ 29.785632784810126, -1.429758448275862 ], [ 29.785632784810126, -1.428680517241379 ], [ 29.785363291139241, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9629, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.428680517241379 ], [ 29.791292151898734, -1.429219482758621 ], [ 29.791561645569619, -1.429219482758621 ], [ 29.791561645569619, -1.428680517241379 ], [ 29.791292151898734, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9630, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.428680517241379 ], [ 29.791831139240507, -1.42895 ], [ 29.792100632911392, -1.42895 ], [ 29.792100632911392, -1.428680517241379 ], [ 29.791831139240507, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9631, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.428680517241379 ], [ 29.792100632911392, -1.42895 ], [ 29.792370126582277, -1.42895 ], [ 29.792370126582277, -1.428680517241379 ], [ 29.792100632911392, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9632, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.428680517241379 ], [ 29.792909113924051, -1.42895 ], [ 29.793178607594935, -1.42895 ], [ 29.793178607594935, -1.428680517241379 ], [ 29.792909113924051, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9633, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.428680517241379 ], [ 29.793178607594935, -1.42895 ], [ 29.793448101265824, -1.42895 ], [ 29.793448101265824, -1.428680517241379 ], [ 29.793178607594935, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9634, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.428680517241379 ], [ 29.793448101265824, -1.42895 ], [ 29.793717594936709, -1.42895 ], [ 29.793717594936709, -1.428680517241379 ], [ 29.793448101265824, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9635, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.428680517241379 ], [ 29.793717594936709, -1.42895 ], [ 29.793987088607594, -1.42895 ], [ 29.793987088607594, -1.428680517241379 ], [ 29.793717594936709, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9636, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.428680517241379 ], [ 29.793987088607594, -1.42895 ], [ 29.794256582278482, -1.42895 ], [ 29.794256582278482, -1.428680517241379 ], [ 29.793987088607594, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9637, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.428680517241379 ], [ 29.799107468354432, -1.42895 ], [ 29.799376962025317, -1.42895 ], [ 29.799376962025317, -1.428680517241379 ], [ 29.799107468354432, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9638, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.428680517241379 ], [ 29.799376962025317, -1.42895 ], [ 29.799646455696202, -1.42895 ], [ 29.799646455696202, -1.428680517241379 ], [ 29.799376962025317, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9639, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.428680517241379 ], [ 29.799646455696202, -1.42895 ], [ 29.79991594936709, -1.42895 ], [ 29.79991594936709, -1.428680517241379 ], [ 29.799646455696202, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9640, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.428680517241379 ], [ 29.79991594936709, -1.429219482758621 ], [ 29.800185443037975, -1.429219482758621 ], [ 29.800185443037975, -1.42895 ], [ 29.80045493670886, -1.42895 ], [ 29.80045493670886, -1.428680517241379 ], [ 29.79991594936709, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9641, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.428680517241379 ], [ 29.80045493670886, -1.42895 ], [ 29.800724430379748, -1.42895 ], [ 29.800724430379748, -1.428680517241379 ], [ 29.80045493670886, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9642, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.428680517241379 ], [ 29.800724430379748, -1.42895 ], [ 29.800993924050633, -1.42895 ], [ 29.800993924050633, -1.428680517241379 ], [ 29.800724430379748, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9643, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.428680517241379 ], [ 29.800993924050633, -1.42895 ], [ 29.801263417721518, -1.42895 ], [ 29.801263417721518, -1.428680517241379 ], [ 29.800993924050633, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9644, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.428680517241379 ], [ 29.801263417721518, -1.42895 ], [ 29.801532911392407, -1.42895 ], [ 29.801532911392407, -1.428680517241379 ], [ 29.801263417721518, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9645, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.428680517241379 ], [ 29.801532911392407, -1.42895 ], [ 29.80261088607595, -1.42895 ], [ 29.80261088607595, -1.428680517241379 ], [ 29.801532911392407, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9646, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.428680517241379 ], [ 29.80261088607595, -1.42895 ], [ 29.802880379746835, -1.42895 ], [ 29.802880379746835, -1.428680517241379 ], [ 29.80261088607595, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9647, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.428680517241379 ], [ 29.802880379746835, -1.42895 ], [ 29.803149873417723, -1.42895 ], [ 29.803149873417723, -1.428680517241379 ], [ 29.802880379746835, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9648, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.428680517241379 ], [ 29.803149873417723, -1.42895 ], [ 29.803419367088608, -1.42895 ], [ 29.803419367088608, -1.428680517241379 ], [ 29.803149873417723, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9649, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.428680517241379 ], [ 29.803419367088608, -1.42895 ], [ 29.803688860759493, -1.42895 ], [ 29.803688860759493, -1.428680517241379 ], [ 29.803419367088608, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9650, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.428680517241379 ], [ 29.803688860759493, -1.42895 ], [ 29.803958354430382, -1.42895 ], [ 29.803958354430382, -1.428680517241379 ], [ 29.803688860759493, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9651, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.428680517241379 ], [ 29.803958354430382, -1.42895 ], [ 29.804227848101267, -1.42895 ], [ 29.804227848101267, -1.428680517241379 ], [ 29.803958354430382, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9652, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.428680517241379 ], [ 29.804227848101267, -1.42895 ], [ 29.804497341772151, -1.42895 ], [ 29.804497341772151, -1.428680517241379 ], [ 29.804227848101267, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9653, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.428680517241379 ], [ 29.804497341772151, -1.42895 ], [ 29.80476683544304, -1.42895 ], [ 29.80476683544304, -1.428680517241379 ], [ 29.804497341772151, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9654, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.428680517241379 ], [ 29.80476683544304, -1.42895 ], [ 29.805036329113925, -1.42895 ], [ 29.805036329113925, -1.428680517241379 ], [ 29.80476683544304, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9655, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.428680517241379 ], [ 29.805036329113925, -1.42895 ], [ 29.80530582278481, -1.42895 ], [ 29.80530582278481, -1.428680517241379 ], [ 29.805036329113925, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9656, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.428680517241379 ], [ 29.80530582278481, -1.42895 ], [ 29.805575316455698, -1.42895 ], [ 29.805575316455698, -1.428680517241379 ], [ 29.80530582278481, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9657, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.428680517241379 ], [ 29.805575316455698, -1.42895 ], [ 29.805844810126583, -1.42895 ], [ 29.805844810126583, -1.428680517241379 ], [ 29.805575316455698, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9658, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.428680517241379 ], [ 29.805844810126583, -1.42895 ], [ 29.806114303797468, -1.42895 ], [ 29.806114303797468, -1.428680517241379 ], [ 29.805844810126583, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9659, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.428680517241379 ], [ 29.806114303797468, -1.42895 ], [ 29.806383797468357, -1.42895 ], [ 29.806383797468357, -1.428680517241379 ], [ 29.806114303797468, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9660, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.428680517241379 ], [ 29.806383797468357, -1.42895 ], [ 29.806653291139241, -1.42895 ], [ 29.806653291139241, -1.428680517241379 ], [ 29.806383797468357, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9661, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.428680517241379 ], [ 29.806653291139241, -1.42895 ], [ 29.806922784810126, -1.42895 ], [ 29.806922784810126, -1.428680517241379 ], [ 29.806653291139241, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9662, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.428680517241379 ], [ 29.806922784810126, -1.429219482758621 ], [ 29.807192278481015, -1.429219482758621 ], [ 29.807192278481015, -1.428680517241379 ], [ 29.806922784810126, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9663, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.42895 ], [ 29.75518, -1.429488965517241 ], [ 29.755449493670884, -1.429488965517241 ], [ 29.755449493670884, -1.42895 ], [ 29.75518, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9664, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.42895 ], [ 29.755449493670884, -1.429219482758621 ], [ 29.755718987341773, -1.429219482758621 ], [ 29.755718987341773, -1.42895 ], [ 29.755449493670884, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9665, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.42895 ], [ 29.755718987341773, -1.429219482758621 ], [ 29.755988481012658, -1.429219482758621 ], [ 29.755988481012658, -1.42895 ], [ 29.755718987341773, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9666, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.42895 ], [ 29.755988481012658, -1.429219482758621 ], [ 29.756257974683542, -1.429219482758621 ], [ 29.756257974683542, -1.42895 ], [ 29.755988481012658, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9667, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.42895 ], [ 29.756257974683542, -1.429219482758621 ], [ 29.756796962025316, -1.429219482758621 ], [ 29.756796962025316, -1.42895 ], [ 29.756257974683542, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9668, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.42895 ], [ 29.756796962025316, -1.429219482758621 ], [ 29.757066455696201, -1.429219482758621 ], [ 29.757066455696201, -1.42895 ], [ 29.756796962025316, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9669, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.42895 ], [ 29.757066455696201, -1.429219482758621 ], [ 29.757335949367089, -1.429219482758621 ], [ 29.757335949367089, -1.42895 ], [ 29.757066455696201, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9670, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.42895 ], [ 29.757335949367089, -1.429219482758621 ], [ 29.757605443037974, -1.429219482758621 ], [ 29.757605443037974, -1.42895 ], [ 29.757335949367089, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9671, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.42895 ], [ 29.757605443037974, -1.429219482758621 ], [ 29.757874936708859, -1.429219482758621 ], [ 29.757874936708859, -1.42895 ], [ 29.757605443037974, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9672, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.42895 ], [ 29.757874936708859, -1.429219482758621 ], [ 29.758144430379748, -1.429219482758621 ], [ 29.758144430379748, -1.42895 ], [ 29.757874936708859, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9673, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.42895 ], [ 29.758144430379748, -1.429219482758621 ], [ 29.758413924050632, -1.429219482758621 ], [ 29.758413924050632, -1.42895 ], [ 29.758144430379748, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9674, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.42895 ], [ 29.758413924050632, -1.429219482758621 ], [ 29.758683417721517, -1.429219482758621 ], [ 29.758683417721517, -1.42895 ], [ 29.758413924050632, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9675, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.42895 ], [ 29.758683417721517, -1.429219482758621 ], [ 29.758952911392406, -1.429219482758621 ], [ 29.758952911392406, -1.42895 ], [ 29.758683417721517, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9676, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.42895 ], [ 29.758952911392406, -1.429219482758621 ], [ 29.759222405063291, -1.429219482758621 ], [ 29.759222405063291, -1.42895 ], [ 29.758952911392406, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9677, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.42895 ], [ 29.759222405063291, -1.429219482758621 ], [ 29.759491898734176, -1.429219482758621 ], [ 29.759491898734176, -1.42895 ], [ 29.759222405063291, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9678, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.42895 ], [ 29.759491898734176, -1.429219482758621 ], [ 29.759761392405064, -1.429219482758621 ], [ 29.759761392405064, -1.42895 ], [ 29.759491898734176, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9679, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.42895 ], [ 29.759761392405064, -1.429219482758621 ], [ 29.760030886075949, -1.429219482758621 ], [ 29.760030886075949, -1.42895 ], [ 29.759761392405064, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9680, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.42895 ], [ 29.760030886075949, -1.429219482758621 ], [ 29.760300379746834, -1.429219482758621 ], [ 29.760300379746834, -1.42895 ], [ 29.760030886075949, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9681, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.42895 ], [ 29.760300379746834, -1.429219482758621 ], [ 29.760569873417722, -1.429219482758621 ], [ 29.760569873417722, -1.42895 ], [ 29.760300379746834, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9682, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.42895 ], [ 29.760569873417722, -1.429219482758621 ], [ 29.760839367088607, -1.429219482758621 ], [ 29.760839367088607, -1.42895 ], [ 29.760569873417722, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9683, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.42895 ], [ 29.760839367088607, -1.429488965517241 ], [ 29.761108860759492, -1.429488965517241 ], [ 29.761108860759492, -1.42895 ], [ 29.760839367088607, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9684, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.42895 ], [ 29.761108860759492, -1.429219482758621 ], [ 29.761378354430381, -1.429219482758621 ], [ 29.761378354430381, -1.42895 ], [ 29.761108860759492, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9685, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.42895 ], [ 29.761378354430381, -1.429219482758621 ], [ 29.761647848101266, -1.429219482758621 ], [ 29.761647848101266, -1.42895 ], [ 29.761378354430381, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9686, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.42895 ], [ 29.761917341772151, -1.429219482758621 ], [ 29.762186835443039, -1.429219482758621 ], [ 29.762186835443039, -1.42895 ], [ 29.761917341772151, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9687, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.42895 ], [ 29.762456329113924, -1.429219482758621 ], [ 29.762725822784809, -1.429219482758621 ], [ 29.762725822784809, -1.42895 ], [ 29.762456329113924, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9688, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.42895 ], [ 29.762725822784809, -1.429219482758621 ], [ 29.762995316455697, -1.429219482758621 ], [ 29.762995316455697, -1.42895 ], [ 29.762725822784809, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9689, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.42895 ], [ 29.762995316455697, -1.429219482758621 ], [ 29.763264810126582, -1.429219482758621 ], [ 29.763264810126582, -1.42895 ], [ 29.762995316455697, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9690, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.42895 ], [ 29.763264810126582, -1.429219482758621 ], [ 29.763534303797467, -1.429219482758621 ], [ 29.763534303797467, -1.42895 ], [ 29.763264810126582, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9691, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.42895 ], [ 29.763534303797467, -1.429219482758621 ], [ 29.763803797468356, -1.429219482758621 ], [ 29.763803797468356, -1.42895 ], [ 29.763534303797467, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9692, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.42895 ], [ 29.763803797468356, -1.429219482758621 ], [ 29.76407329113924, -1.429219482758621 ], [ 29.76407329113924, -1.42895 ], [ 29.763803797468356, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9693, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.42895 ], [ 29.76407329113924, -1.429219482758621 ], [ 29.764342784810125, -1.429219482758621 ], [ 29.764342784810125, -1.42895 ], [ 29.76407329113924, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9694, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.42895 ], [ 29.764342784810125, -1.429219482758621 ], [ 29.764612278481014, -1.429219482758621 ], [ 29.764612278481014, -1.42895 ], [ 29.764342784810125, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9695, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.42895 ], [ 29.764612278481014, -1.429219482758621 ], [ 29.764881772151899, -1.429219482758621 ], [ 29.764881772151899, -1.42895 ], [ 29.764612278481014, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9696, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.42895 ], [ 29.764881772151899, -1.429219482758621 ], [ 29.765151265822784, -1.429219482758621 ], [ 29.765151265822784, -1.42895 ], [ 29.764881772151899, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9697, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.42895 ], [ 29.765151265822784, -1.429219482758621 ], [ 29.765420759493672, -1.429219482758621 ], [ 29.765420759493672, -1.42895 ], [ 29.765151265822784, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9698, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.42895 ], [ 29.765420759493672, -1.429219482758621 ], [ 29.765959746835442, -1.429219482758621 ], [ 29.765959746835442, -1.42895 ], [ 29.765420759493672, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9699, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.42895 ], [ 29.765959746835442, -1.429219482758621 ], [ 29.766229240506327, -1.429219482758621 ], [ 29.766229240506327, -1.42895 ], [ 29.765959746835442, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9700, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.42895 ], [ 29.766229240506327, -1.429219482758621 ], [ 29.766498734177215, -1.429219482758621 ], [ 29.766498734177215, -1.42895 ], [ 29.766229240506327, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9701, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.42895 ], [ 29.766498734177215, -1.429219482758621 ], [ 29.7667682278481, -1.429219482758621 ], [ 29.7667682278481, -1.42895 ], [ 29.766498734177215, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9702, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.42895 ], [ 29.7667682278481, -1.429219482758621 ], [ 29.767037721518985, -1.429219482758621 ], [ 29.767037721518985, -1.42895 ], [ 29.7667682278481, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9703, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.42895 ], [ 29.767037721518985, -1.429219482758621 ], [ 29.767307215189874, -1.429219482758621 ], [ 29.767307215189874, -1.42895 ], [ 29.767037721518985, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9704, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.42895 ], [ 29.767307215189874, -1.429219482758621 ], [ 29.767576708860759, -1.429219482758621 ], [ 29.767576708860759, -1.42895 ], [ 29.767307215189874, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9705, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.42895 ], [ 29.767576708860759, -1.429219482758621 ], [ 29.767846202531643, -1.429219482758621 ], [ 29.767846202531643, -1.42895 ], [ 29.767576708860759, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9706, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.42895 ], [ 29.767846202531643, -1.429219482758621 ], [ 29.768115696202532, -1.429219482758621 ], [ 29.768115696202532, -1.42895 ], [ 29.767846202531643, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9707, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.42895 ], [ 29.768115696202532, -1.429219482758621 ], [ 29.768385189873417, -1.429219482758621 ], [ 29.768385189873417, -1.42895 ], [ 29.768115696202532, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9708, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.42895 ], [ 29.768385189873417, -1.429219482758621 ], [ 29.768654683544302, -1.429219482758621 ], [ 29.768654683544302, -1.42895 ], [ 29.768385189873417, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9709, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.42895 ], [ 29.768654683544302, -1.429219482758621 ], [ 29.76892417721519, -1.429219482758621 ], [ 29.76892417721519, -1.42895 ], [ 29.768654683544302, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9710, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.42895 ], [ 29.76892417721519, -1.429219482758621 ], [ 29.769193670886075, -1.429219482758621 ], [ 29.769193670886075, -1.42895 ], [ 29.76892417721519, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9711, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.42895 ], [ 29.769193670886075, -1.429219482758621 ], [ 29.76946316455696, -1.429219482758621 ], [ 29.76946316455696, -1.42895 ], [ 29.769193670886075, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9712, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.42895 ], [ 29.76946316455696, -1.429219482758621 ], [ 29.769732658227849, -1.429219482758621 ], [ 29.769732658227849, -1.42895 ], [ 29.76946316455696, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9713, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.42895 ], [ 29.769732658227849, -1.429219482758621 ], [ 29.770002151898733, -1.429219482758621 ], [ 29.770002151898733, -1.42895 ], [ 29.769732658227849, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9714, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.42895 ], [ 29.770002151898733, -1.429219482758621 ], [ 29.770271645569618, -1.429219482758621 ], [ 29.770271645569618, -1.42895 ], [ 29.770002151898733, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9715, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.42895 ], [ 29.770271645569618, -1.429219482758621 ], [ 29.770541139240507, -1.429219482758621 ], [ 29.770541139240507, -1.42895 ], [ 29.770271645569618, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9716, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.42895 ], [ 29.770541139240507, -1.429219482758621 ], [ 29.770810632911392, -1.429219482758621 ], [ 29.770810632911392, -1.42895 ], [ 29.770541139240507, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9717, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.42895 ], [ 29.770810632911392, -1.429219482758621 ], [ 29.771080126582277, -1.429219482758621 ], [ 29.771080126582277, -1.42895 ], [ 29.770810632911392, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9718, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.42895 ], [ 29.771080126582277, -1.429219482758621 ], [ 29.771349620253165, -1.429219482758621 ], [ 29.771349620253165, -1.42895 ], [ 29.771080126582277, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9719, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.42895 ], [ 29.771349620253165, -1.429219482758621 ], [ 29.77161911392405, -1.429219482758621 ], [ 29.77161911392405, -1.42895 ], [ 29.771349620253165, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9720, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.42895 ], [ 29.77161911392405, -1.429219482758621 ], [ 29.771888607594935, -1.429219482758621 ], [ 29.771888607594935, -1.42895 ], [ 29.77161911392405, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9721, "El": 154 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.428680517241379 ], [ 29.772427594936708, -1.428680517241379 ], [ 29.772427594936708, -1.429758448275862 ], [ 29.772158101265823, -1.429758448275862 ], [ 29.772158101265823, -1.429219482758621 ], [ 29.771888607594935, -1.429219482758621 ], [ 29.771888607594935, -1.42895 ], [ 29.772158101265823, -1.42895 ], [ 29.772158101265823, -1.428680517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9722, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.42895 ], [ 29.772427594936708, -1.429758448275862 ], [ 29.772697088607593, -1.429758448275862 ], [ 29.772697088607593, -1.42895 ], [ 29.772427594936708, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9723, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.42895 ], [ 29.772966582278482, -1.429488965517241 ], [ 29.773236075949367, -1.429488965517241 ], [ 29.773236075949367, -1.42895 ], [ 29.772966582278482, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9724, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.42895 ], [ 29.77377506329114, -1.429488965517241 ], [ 29.774044556962025, -1.429488965517241 ], [ 29.774044556962025, -1.42895 ], [ 29.77377506329114, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9725, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.42895 ], [ 29.774853037974683, -1.429488965517241 ], [ 29.775122531645568, -1.429488965517241 ], [ 29.775122531645568, -1.42895 ], [ 29.774853037974683, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9726, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.42895 ], [ 29.775392025316457, -1.429488965517241 ], [ 29.775661518987341, -1.429488965517241 ], [ 29.775661518987341, -1.42895 ], [ 29.775392025316457, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9727, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.42895 ], [ 29.776200506329115, -1.429488965517241 ], [ 29.77647, -1.429488965517241 ], [ 29.77647, -1.42895 ], [ 29.776200506329115, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9728, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.42895 ], [ 29.777008987341773, -1.429488965517241 ], [ 29.777278481012658, -1.429488965517241 ], [ 29.777278481012658, -1.42895 ], [ 29.777008987341773, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9729, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.42895 ], [ 29.777547974683543, -1.429219482758621 ], [ 29.777817468354431, -1.429219482758621 ], [ 29.777817468354431, -1.42895 ], [ 29.777547974683543, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9730, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.42895 ], [ 29.777817468354431, -1.429488965517241 ], [ 29.778086962025316, -1.429488965517241 ], [ 29.778086962025316, -1.42895 ], [ 29.777817468354431, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9731, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.42895 ], [ 29.778356455696201, -1.429219482758621 ], [ 29.77862594936709, -1.429219482758621 ], [ 29.77862594936709, -1.42895 ], [ 29.778356455696201, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9732, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.42895 ], [ 29.77862594936709, -1.429219482758621 ], [ 29.778895443037975, -1.429219482758621 ], [ 29.778895443037975, -1.42895 ], [ 29.77862594936709, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9733, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.42895 ], [ 29.77916493670886, -1.429219482758621 ], [ 29.779434430379748, -1.429219482758621 ], [ 29.779434430379748, -1.42895 ], [ 29.77916493670886, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9734, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.42895 ], [ 29.779434430379748, -1.429219482758621 ], [ 29.779703924050633, -1.429219482758621 ], [ 29.779703924050633, -1.42895 ], [ 29.779434430379748, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9735, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.42895 ], [ 29.779703924050633, -1.429488965517241 ], [ 29.779973417721518, -1.429488965517241 ], [ 29.779973417721518, -1.42895 ], [ 29.779703924050633, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9736, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.42895 ], [ 29.779973417721518, -1.429219482758621 ], [ 29.780242911392406, -1.429219482758621 ], [ 29.780242911392406, -1.42895 ], [ 29.779973417721518, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9737, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.42895 ], [ 29.780242911392406, -1.429219482758621 ], [ 29.780512405063291, -1.429219482758621 ], [ 29.780512405063291, -1.42895 ], [ 29.780242911392406, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9738, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.42895 ], [ 29.780512405063291, -1.429219482758621 ], [ 29.780781898734176, -1.429219482758621 ], [ 29.780781898734176, -1.42895 ], [ 29.780512405063291, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9739, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.42895 ], [ 29.780781898734176, -1.429219482758621 ], [ 29.781051392405065, -1.429219482758621 ], [ 29.781051392405065, -1.42895 ], [ 29.780781898734176, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9740, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.42895 ], [ 29.781051392405065, -1.429219482758621 ], [ 29.78132088607595, -1.429219482758621 ], [ 29.78132088607595, -1.42895 ], [ 29.781051392405065, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9741, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.42895 ], [ 29.78132088607595, -1.429219482758621 ], [ 29.781590379746834, -1.429219482758621 ], [ 29.781590379746834, -1.42895 ], [ 29.78132088607595, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9742, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.42895 ], [ 29.781590379746834, -1.429219482758621 ], [ 29.781859873417723, -1.429219482758621 ], [ 29.781859873417723, -1.42895 ], [ 29.781590379746834, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9743, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.42895 ], [ 29.781859873417723, -1.429219482758621 ], [ 29.782129367088608, -1.429219482758621 ], [ 29.782129367088608, -1.42895 ], [ 29.781859873417723, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9744, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.42895 ], [ 29.782129367088608, -1.429219482758621 ], [ 29.782398860759493, -1.429219482758621 ], [ 29.782398860759493, -1.42895 ], [ 29.782129367088608, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9745, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.42895 ], [ 29.782398860759493, -1.429219482758621 ], [ 29.782668354430381, -1.429219482758621 ], [ 29.782668354430381, -1.42895 ], [ 29.782398860759493, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9746, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.42895 ], [ 29.782668354430381, -1.429219482758621 ], [ 29.782937848101266, -1.429219482758621 ], [ 29.782937848101266, -1.42895 ], [ 29.782668354430381, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9747, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.42895 ], [ 29.782937848101266, -1.429219482758621 ], [ 29.783207341772151, -1.429219482758621 ], [ 29.783207341772151, -1.42895 ], [ 29.782937848101266, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9748, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.42895 ], [ 29.783207341772151, -1.429219482758621 ], [ 29.783476835443039, -1.429219482758621 ], [ 29.783476835443039, -1.42895 ], [ 29.783207341772151, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9749, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.42895 ], [ 29.783476835443039, -1.429219482758621 ], [ 29.784015822784809, -1.429219482758621 ], [ 29.784015822784809, -1.42895 ], [ 29.783476835443039, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9750, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.42895 ], [ 29.784015822784809, -1.429219482758621 ], [ 29.784285316455698, -1.429219482758621 ], [ 29.784285316455698, -1.42895 ], [ 29.784015822784809, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9751, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.42895 ], [ 29.784285316455698, -1.429219482758621 ], [ 29.784554810126583, -1.429219482758621 ], [ 29.784554810126583, -1.42895 ], [ 29.784285316455698, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9752, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.42895 ], [ 29.784554810126583, -1.429219482758621 ], [ 29.784824303797468, -1.429219482758621 ], [ 29.784824303797468, -1.42895 ], [ 29.784554810126583, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9753, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.42895 ], [ 29.784824303797468, -1.429758448275862 ], [ 29.785093797468356, -1.429758448275862 ], [ 29.785093797468356, -1.42895 ], [ 29.784824303797468, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9754, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.42895 ], [ 29.785093797468356, -1.429758448275862 ], [ 29.785363291139241, -1.429758448275862 ], [ 29.785363291139241, -1.42895 ], [ 29.785093797468356, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9755, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.42895 ], [ 29.791561645569619, -1.429219482758621 ], [ 29.791831139240507, -1.429219482758621 ], [ 29.791831139240507, -1.42895 ], [ 29.791561645569619, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9756, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.42895 ], [ 29.791831139240507, -1.429219482758621 ], [ 29.792100632911392, -1.429219482758621 ], [ 29.792100632911392, -1.42895 ], [ 29.791831139240507, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9757, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.42895 ], [ 29.792100632911392, -1.429219482758621 ], [ 29.792370126582277, -1.429219482758621 ], [ 29.792370126582277, -1.42895 ], [ 29.792100632911392, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9758, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.42895 ], [ 29.792370126582277, -1.429219482758621 ], [ 29.792909113924051, -1.429219482758621 ], [ 29.792909113924051, -1.42895 ], [ 29.792370126582277, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9759, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.42895 ], [ 29.792909113924051, -1.429219482758621 ], [ 29.793178607594935, -1.429219482758621 ], [ 29.793178607594935, -1.42895 ], [ 29.792909113924051, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9760, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.42895 ], [ 29.793178607594935, -1.429219482758621 ], [ 29.793448101265824, -1.429219482758621 ], [ 29.793448101265824, -1.42895 ], [ 29.793178607594935, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9761, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.42895 ], [ 29.793448101265824, -1.429219482758621 ], [ 29.793717594936709, -1.429219482758621 ], [ 29.793717594936709, -1.42895 ], [ 29.793448101265824, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9762, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.42895 ], [ 29.793717594936709, -1.429219482758621 ], [ 29.793987088607594, -1.429219482758621 ], [ 29.793987088607594, -1.42895 ], [ 29.793717594936709, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9763, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.42895 ], [ 29.793987088607594, -1.429219482758621 ], [ 29.794256582278482, -1.429219482758621 ], [ 29.794256582278482, -1.42895 ], [ 29.793987088607594, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9764, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.42895 ], [ 29.794256582278482, -1.429219482758621 ], [ 29.794526075949367, -1.429219482758621 ], [ 29.794526075949367, -1.42895 ], [ 29.794256582278482, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9765, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.42895 ], [ 29.794526075949367, -1.429488965517241 ], [ 29.794795569620252, -1.429488965517241 ], [ 29.794795569620252, -1.42895 ], [ 29.794526075949367, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9766, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.42895 ], [ 29.798568481012659, -1.429219482758621 ], [ 29.798837974683543, -1.429219482758621 ], [ 29.798837974683543, -1.42895 ], [ 29.798568481012659, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9767, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.42895 ], [ 29.798837974683543, -1.429219482758621 ], [ 29.799107468354432, -1.429219482758621 ], [ 29.799107468354432, -1.42895 ], [ 29.798837974683543, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9768, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.42895 ], [ 29.799107468354432, -1.429219482758621 ], [ 29.799376962025317, -1.429219482758621 ], [ 29.799376962025317, -1.42895 ], [ 29.799107468354432, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9769, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.42895 ], [ 29.799376962025317, -1.430566896551724 ], [ 29.799646455696202, -1.430566896551724 ], [ 29.799646455696202, -1.42895 ], [ 29.799376962025317, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9770, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.42895 ], [ 29.799646455696202, -1.430566896551724 ], [ 29.79991594936709, -1.430566896551724 ], [ 29.79991594936709, -1.42895 ], [ 29.799646455696202, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9771, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.42895 ], [ 29.800185443037975, -1.430566896551724 ], [ 29.80045493670886, -1.430566896551724 ], [ 29.80045493670886, -1.42895 ], [ 29.800185443037975, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9772, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.42895 ], [ 29.80045493670886, -1.429219482758621 ], [ 29.800724430379748, -1.429219482758621 ], [ 29.800724430379748, -1.42895 ], [ 29.80045493670886, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9773, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.42895 ], [ 29.800724430379748, -1.429219482758621 ], [ 29.800993924050633, -1.429219482758621 ], [ 29.800993924050633, -1.42895 ], [ 29.800724430379748, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9774, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.42895 ], [ 29.800993924050633, -1.429219482758621 ], [ 29.801263417721518, -1.429219482758621 ], [ 29.801263417721518, -1.42895 ], [ 29.800993924050633, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9775, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.42895 ], [ 29.801263417721518, -1.429219482758621 ], [ 29.801532911392407, -1.429219482758621 ], [ 29.801532911392407, -1.42895 ], [ 29.801263417721518, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9776, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.42895 ], [ 29.801532911392407, -1.429219482758621 ], [ 29.80261088607595, -1.429219482758621 ], [ 29.80261088607595, -1.42895 ], [ 29.801532911392407, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9777, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.42895 ], [ 29.80261088607595, -1.429219482758621 ], [ 29.802880379746835, -1.429219482758621 ], [ 29.802880379746835, -1.42895 ], [ 29.80261088607595, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9778, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.42895 ], [ 29.802880379746835, -1.429219482758621 ], [ 29.803149873417723, -1.429219482758621 ], [ 29.803149873417723, -1.42895 ], [ 29.802880379746835, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9779, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.42895 ], [ 29.803149873417723, -1.429219482758621 ], [ 29.803419367088608, -1.429219482758621 ], [ 29.803419367088608, -1.42895 ], [ 29.803149873417723, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9780, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.42895 ], [ 29.803419367088608, -1.429219482758621 ], [ 29.803688860759493, -1.429219482758621 ], [ 29.803688860759493, -1.42895 ], [ 29.803419367088608, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9781, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.42895 ], [ 29.803688860759493, -1.429219482758621 ], [ 29.803958354430382, -1.429219482758621 ], [ 29.803958354430382, -1.42895 ], [ 29.803688860759493, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9782, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.42895 ], [ 29.803958354430382, -1.429219482758621 ], [ 29.804227848101267, -1.429219482758621 ], [ 29.804227848101267, -1.42895 ], [ 29.803958354430382, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9783, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.42895 ], [ 29.804497341772151, -1.429219482758621 ], [ 29.80476683544304, -1.429219482758621 ], [ 29.80476683544304, -1.42895 ], [ 29.804497341772151, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9784, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.42895 ], [ 29.80476683544304, -1.429219482758621 ], [ 29.805036329113925, -1.429219482758621 ], [ 29.805036329113925, -1.42895 ], [ 29.80476683544304, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9785, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.42895 ], [ 29.805036329113925, -1.429488965517241 ], [ 29.80530582278481, -1.429488965517241 ], [ 29.80530582278481, -1.42895 ], [ 29.805036329113925, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9786, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.42895 ], [ 29.80530582278481, -1.429219482758621 ], [ 29.805575316455698, -1.429219482758621 ], [ 29.805575316455698, -1.42895 ], [ 29.80530582278481, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9787, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.42895 ], [ 29.805575316455698, -1.429219482758621 ], [ 29.805844810126583, -1.429219482758621 ], [ 29.805844810126583, -1.42895 ], [ 29.805575316455698, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9788, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.42895 ], [ 29.805844810126583, -1.429219482758621 ], [ 29.806114303797468, -1.429219482758621 ], [ 29.806114303797468, -1.42895 ], [ 29.805844810126583, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9789, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.42895 ], [ 29.806114303797468, -1.429219482758621 ], [ 29.806383797468357, -1.429219482758621 ], [ 29.806383797468357, -1.42895 ], [ 29.806114303797468, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9790, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.42895 ], [ 29.806383797468357, -1.429219482758621 ], [ 29.806653291139241, -1.429219482758621 ], [ 29.806653291139241, -1.42895 ], [ 29.806383797468357, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9791, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.42895 ], [ 29.806653291139241, -1.429219482758621 ], [ 29.806922784810126, -1.429219482758621 ], [ 29.806922784810126, -1.42895 ], [ 29.806653291139241, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9792, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.429219482758621 ], [ 29.755449493670884, -1.429488965517241 ], [ 29.755718987341773, -1.429488965517241 ], [ 29.755718987341773, -1.429219482758621 ], [ 29.755449493670884, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9793, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.429219482758621 ], [ 29.755718987341773, -1.429488965517241 ], [ 29.755988481012658, -1.429488965517241 ], [ 29.755988481012658, -1.429219482758621 ], [ 29.755718987341773, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9794, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.429219482758621 ], [ 29.755988481012658, -1.429488965517241 ], [ 29.756257974683542, -1.429488965517241 ], [ 29.756257974683542, -1.429219482758621 ], [ 29.755988481012658, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9795, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.429219482758621 ], [ 29.756257974683542, -1.429488965517241 ], [ 29.756796962025316, -1.429488965517241 ], [ 29.756796962025316, -1.429219482758621 ], [ 29.756257974683542, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9796, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.429219482758621 ], [ 29.756796962025316, -1.429488965517241 ], [ 29.757066455696201, -1.429488965517241 ], [ 29.757066455696201, -1.429219482758621 ], [ 29.756796962025316, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9797, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.429219482758621 ], [ 29.757066455696201, -1.429488965517241 ], [ 29.757335949367089, -1.429488965517241 ], [ 29.757335949367089, -1.429219482758621 ], [ 29.757066455696201, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9798, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.429219482758621 ], [ 29.757335949367089, -1.429488965517241 ], [ 29.757605443037974, -1.429488965517241 ], [ 29.757605443037974, -1.429219482758621 ], [ 29.757335949367089, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9799, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.429219482758621 ], [ 29.757605443037974, -1.429488965517241 ], [ 29.757874936708859, -1.429488965517241 ], [ 29.757874936708859, -1.429219482758621 ], [ 29.757605443037974, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9800, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.429219482758621 ], [ 29.757874936708859, -1.429488965517241 ], [ 29.758144430379748, -1.429488965517241 ], [ 29.758144430379748, -1.429219482758621 ], [ 29.757874936708859, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9801, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.429219482758621 ], [ 29.758144430379748, -1.429488965517241 ], [ 29.758413924050632, -1.429488965517241 ], [ 29.758413924050632, -1.429219482758621 ], [ 29.758144430379748, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9802, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.429219482758621 ], [ 29.758413924050632, -1.429488965517241 ], [ 29.758683417721517, -1.429488965517241 ], [ 29.758683417721517, -1.429219482758621 ], [ 29.758413924050632, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9803, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.429219482758621 ], [ 29.758683417721517, -1.429488965517241 ], [ 29.758952911392406, -1.429488965517241 ], [ 29.758952911392406, -1.429219482758621 ], [ 29.758683417721517, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9804, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.429219482758621 ], [ 29.758952911392406, -1.429488965517241 ], [ 29.759222405063291, -1.429488965517241 ], [ 29.759222405063291, -1.429219482758621 ], [ 29.758952911392406, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9805, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.429219482758621 ], [ 29.759222405063291, -1.429488965517241 ], [ 29.759491898734176, -1.429488965517241 ], [ 29.759491898734176, -1.429219482758621 ], [ 29.759222405063291, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9806, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.429219482758621 ], [ 29.759491898734176, -1.429488965517241 ], [ 29.759761392405064, -1.429488965517241 ], [ 29.759761392405064, -1.429219482758621 ], [ 29.759491898734176, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9807, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.429219482758621 ], [ 29.759761392405064, -1.429488965517241 ], [ 29.760030886075949, -1.429488965517241 ], [ 29.760030886075949, -1.429219482758621 ], [ 29.759761392405064, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9808, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.429219482758621 ], [ 29.760030886075949, -1.429488965517241 ], [ 29.760300379746834, -1.429488965517241 ], [ 29.760300379746834, -1.429219482758621 ], [ 29.760030886075949, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9809, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.429219482758621 ], [ 29.760300379746834, -1.429488965517241 ], [ 29.760569873417722, -1.429488965517241 ], [ 29.760569873417722, -1.429219482758621 ], [ 29.760300379746834, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9810, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.429219482758621 ], [ 29.760569873417722, -1.429488965517241 ], [ 29.760839367088607, -1.429488965517241 ], [ 29.760839367088607, -1.429219482758621 ], [ 29.760569873417722, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9811, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.429219482758621 ], [ 29.761108860759492, -1.429488965517241 ], [ 29.761378354430381, -1.429488965517241 ], [ 29.761378354430381, -1.429219482758621 ], [ 29.761108860759492, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9812, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.429219482758621 ], [ 29.761378354430381, -1.429758448275862 ], [ 29.761647848101266, -1.429758448275862 ], [ 29.761647848101266, -1.429219482758621 ], [ 29.761378354430381, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9813, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.429219482758621 ], [ 29.761647848101266, -1.429488965517241 ], [ 29.761917341772151, -1.429488965517241 ], [ 29.761917341772151, -1.429219482758621 ], [ 29.761647848101266, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9814, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.429219482758621 ], [ 29.761917341772151, -1.429488965517241 ], [ 29.762186835443039, -1.429488965517241 ], [ 29.762186835443039, -1.429219482758621 ], [ 29.761917341772151, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9815, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.429219482758621 ], [ 29.762186835443039, -1.429488965517241 ], [ 29.762456329113924, -1.429488965517241 ], [ 29.762456329113924, -1.429219482758621 ], [ 29.762186835443039, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9816, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.429219482758621 ], [ 29.762456329113924, -1.429488965517241 ], [ 29.762725822784809, -1.429488965517241 ], [ 29.762725822784809, -1.429219482758621 ], [ 29.762456329113924, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9817, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.429219482758621 ], [ 29.762725822784809, -1.429488965517241 ], [ 29.763264810126582, -1.429488965517241 ], [ 29.763264810126582, -1.429219482758621 ], [ 29.762725822784809, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9818, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.429219482758621 ], [ 29.763264810126582, -1.429488965517241 ], [ 29.763534303797467, -1.429488965517241 ], [ 29.763534303797467, -1.429219482758621 ], [ 29.763264810126582, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9819, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.429219482758621 ], [ 29.763534303797467, -1.429488965517241 ], [ 29.763803797468356, -1.429488965517241 ], [ 29.763803797468356, -1.429219482758621 ], [ 29.763534303797467, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9820, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.429219482758621 ], [ 29.763803797468356, -1.429488965517241 ], [ 29.76407329113924, -1.429488965517241 ], [ 29.76407329113924, -1.429219482758621 ], [ 29.763803797468356, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9821, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.429219482758621 ], [ 29.76407329113924, -1.429488965517241 ], [ 29.764342784810125, -1.429488965517241 ], [ 29.764342784810125, -1.429219482758621 ], [ 29.76407329113924, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9822, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.429219482758621 ], [ 29.764342784810125, -1.429488965517241 ], [ 29.764612278481014, -1.429488965517241 ], [ 29.764612278481014, -1.429219482758621 ], [ 29.764342784810125, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9823, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.429219482758621 ], [ 29.764612278481014, -1.429488965517241 ], [ 29.764881772151899, -1.429488965517241 ], [ 29.764881772151899, -1.429219482758621 ], [ 29.764612278481014, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9824, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.429219482758621 ], [ 29.764881772151899, -1.429488965517241 ], [ 29.765151265822784, -1.429488965517241 ], [ 29.765151265822784, -1.429219482758621 ], [ 29.764881772151899, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9825, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.429219482758621 ], [ 29.765151265822784, -1.429488965517241 ], [ 29.765420759493672, -1.429488965517241 ], [ 29.765420759493672, -1.429219482758621 ], [ 29.765151265822784, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9826, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.429219482758621 ], [ 29.765420759493672, -1.429488965517241 ], [ 29.765959746835442, -1.429488965517241 ], [ 29.765959746835442, -1.429219482758621 ], [ 29.765420759493672, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9827, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.429219482758621 ], [ 29.765959746835442, -1.429488965517241 ], [ 29.766229240506327, -1.429488965517241 ], [ 29.766229240506327, -1.429219482758621 ], [ 29.765959746835442, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9828, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.429219482758621 ], [ 29.766229240506327, -1.429488965517241 ], [ 29.766498734177215, -1.429488965517241 ], [ 29.766498734177215, -1.429219482758621 ], [ 29.766229240506327, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9829, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.429219482758621 ], [ 29.766498734177215, -1.429488965517241 ], [ 29.7667682278481, -1.429488965517241 ], [ 29.7667682278481, -1.429219482758621 ], [ 29.766498734177215, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9830, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.429219482758621 ], [ 29.7667682278481, -1.429488965517241 ], [ 29.767037721518985, -1.429488965517241 ], [ 29.767037721518985, -1.429219482758621 ], [ 29.7667682278481, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9831, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.429219482758621 ], [ 29.767037721518985, -1.429488965517241 ], [ 29.767307215189874, -1.429488965517241 ], [ 29.767307215189874, -1.429219482758621 ], [ 29.767037721518985, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9832, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.429219482758621 ], [ 29.767307215189874, -1.429488965517241 ], [ 29.767576708860759, -1.429488965517241 ], [ 29.767576708860759, -1.429219482758621 ], [ 29.767307215189874, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9833, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.429219482758621 ], [ 29.767576708860759, -1.429488965517241 ], [ 29.767846202531643, -1.429488965517241 ], [ 29.767846202531643, -1.429219482758621 ], [ 29.767576708860759, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9834, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.429219482758621 ], [ 29.767846202531643, -1.429488965517241 ], [ 29.768115696202532, -1.429488965517241 ], [ 29.768115696202532, -1.429219482758621 ], [ 29.767846202531643, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9835, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.429219482758621 ], [ 29.768115696202532, -1.429488965517241 ], [ 29.768385189873417, -1.429488965517241 ], [ 29.768385189873417, -1.429219482758621 ], [ 29.768115696202532, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9836, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.429219482758621 ], [ 29.768385189873417, -1.429488965517241 ], [ 29.768654683544302, -1.429488965517241 ], [ 29.768654683544302, -1.429219482758621 ], [ 29.768385189873417, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9837, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.429219482758621 ], [ 29.768654683544302, -1.429488965517241 ], [ 29.76892417721519, -1.429488965517241 ], [ 29.76892417721519, -1.429219482758621 ], [ 29.768654683544302, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9838, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.429219482758621 ], [ 29.76892417721519, -1.429488965517241 ], [ 29.769193670886075, -1.429488965517241 ], [ 29.769193670886075, -1.429219482758621 ], [ 29.76892417721519, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9839, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.429219482758621 ], [ 29.769193670886075, -1.429488965517241 ], [ 29.76946316455696, -1.429488965517241 ], [ 29.76946316455696, -1.429219482758621 ], [ 29.769193670886075, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9840, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.429219482758621 ], [ 29.76946316455696, -1.429488965517241 ], [ 29.769732658227849, -1.429488965517241 ], [ 29.769732658227849, -1.429219482758621 ], [ 29.76946316455696, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9841, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.429219482758621 ], [ 29.769732658227849, -1.429488965517241 ], [ 29.770002151898733, -1.429488965517241 ], [ 29.770002151898733, -1.429219482758621 ], [ 29.769732658227849, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9842, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.429219482758621 ], [ 29.770002151898733, -1.429488965517241 ], [ 29.770271645569618, -1.429488965517241 ], [ 29.770271645569618, -1.429219482758621 ], [ 29.770002151898733, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9843, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.429219482758621 ], [ 29.770271645569618, -1.429488965517241 ], [ 29.770541139240507, -1.429488965517241 ], [ 29.770541139240507, -1.429219482758621 ], [ 29.770271645569618, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9844, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.429219482758621 ], [ 29.770541139240507, -1.429488965517241 ], [ 29.770810632911392, -1.429488965517241 ], [ 29.770810632911392, -1.429219482758621 ], [ 29.770541139240507, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9845, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.429219482758621 ], [ 29.770810632911392, -1.429488965517241 ], [ 29.771080126582277, -1.429488965517241 ], [ 29.771080126582277, -1.429219482758621 ], [ 29.770810632911392, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9846, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.429219482758621 ], [ 29.771080126582277, -1.429488965517241 ], [ 29.771349620253165, -1.429488965517241 ], [ 29.771349620253165, -1.429219482758621 ], [ 29.771080126582277, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9847, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.429219482758621 ], [ 29.771349620253165, -1.429488965517241 ], [ 29.77161911392405, -1.429488965517241 ], [ 29.77161911392405, -1.429219482758621 ], [ 29.771349620253165, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9848, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.429219482758621 ], [ 29.77161911392405, -1.429488965517241 ], [ 29.771888607594935, -1.429488965517241 ], [ 29.771888607594935, -1.429219482758621 ], [ 29.77161911392405, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9849, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.429219482758621 ], [ 29.771888607594935, -1.429488965517241 ], [ 29.772158101265823, -1.429488965517241 ], [ 29.772158101265823, -1.429219482758621 ], [ 29.771888607594935, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9850, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.429219482758621 ], [ 29.772697088607593, -1.429758448275862 ], [ 29.772966582278482, -1.429758448275862 ], [ 29.772966582278482, -1.429219482758621 ], [ 29.772697088607593, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9851, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.429219482758621 ], [ 29.773236075949367, -1.429758448275862 ], [ 29.773505569620252, -1.429758448275862 ], [ 29.773505569620252, -1.429219482758621 ], [ 29.773236075949367, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9852, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.429219482758621 ], [ 29.774044556962025, -1.429758448275862 ], [ 29.77431405063291, -1.429758448275862 ], [ 29.77431405063291, -1.429219482758621 ], [ 29.774044556962025, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9853, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.429219482758621 ], [ 29.775122531645568, -1.429758448275862 ], [ 29.775392025316457, -1.429758448275862 ], [ 29.775392025316457, -1.429219482758621 ], [ 29.775122531645568, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9854, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.429219482758621 ], [ 29.775661518987341, -1.429488965517241 ], [ 29.775931012658226, -1.429488965517241 ], [ 29.775931012658226, -1.429219482758621 ], [ 29.775661518987341, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9855, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.429219482758621 ], [ 29.775931012658226, -1.429758448275862 ], [ 29.776200506329115, -1.429758448275862 ], [ 29.776200506329115, -1.429219482758621 ], [ 29.775931012658226, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9856, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.429219482758621 ], [ 29.77647, -1.429488965517241 ], [ 29.776739493670885, -1.429488965517241 ], [ 29.776739493670885, -1.429219482758621 ], [ 29.77647, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9857, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.429219482758621 ], [ 29.776739493670885, -1.429758448275862 ], [ 29.777008987341773, -1.429758448275862 ], [ 29.777008987341773, -1.429219482758621 ], [ 29.776739493670885, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9858, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.429219482758621 ], [ 29.777278481012658, -1.429488965517241 ], [ 29.777547974683543, -1.429488965517241 ], [ 29.777547974683543, -1.429219482758621 ], [ 29.777278481012658, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9859, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.429219482758621 ], [ 29.777547974683543, -1.429488965517241 ], [ 29.777817468354431, -1.429488965517241 ], [ 29.777817468354431, -1.429219482758621 ], [ 29.777547974683543, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9860, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.429219482758621 ], [ 29.778086962025316, -1.429488965517241 ], [ 29.778356455696201, -1.429488965517241 ], [ 29.778356455696201, -1.429219482758621 ], [ 29.778086962025316, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9861, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.429219482758621 ], [ 29.778356455696201, -1.429488965517241 ], [ 29.77862594936709, -1.429488965517241 ], [ 29.77862594936709, -1.429219482758621 ], [ 29.778356455696201, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9862, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.429219482758621 ], [ 29.77862594936709, -1.429758448275862 ], [ 29.778895443037975, -1.429758448275862 ], [ 29.778895443037975, -1.429219482758621 ], [ 29.77862594936709, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9863, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.429219482758621 ], [ 29.778895443037975, -1.429488965517241 ], [ 29.77916493670886, -1.429488965517241 ], [ 29.77916493670886, -1.429219482758621 ], [ 29.778895443037975, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9864, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.429219482758621 ], [ 29.77916493670886, -1.429488965517241 ], [ 29.779434430379748, -1.429488965517241 ], [ 29.779434430379748, -1.429219482758621 ], [ 29.77916493670886, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9865, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.429219482758621 ], [ 29.779434430379748, -1.429488965517241 ], [ 29.779703924050633, -1.429488965517241 ], [ 29.779703924050633, -1.429219482758621 ], [ 29.779434430379748, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9866, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.429219482758621 ], [ 29.779973417721518, -1.429488965517241 ], [ 29.780242911392406, -1.429488965517241 ], [ 29.780242911392406, -1.429219482758621 ], [ 29.779973417721518, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9867, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.429219482758621 ], [ 29.780242911392406, -1.429488965517241 ], [ 29.780512405063291, -1.429488965517241 ], [ 29.780512405063291, -1.429219482758621 ], [ 29.780242911392406, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9868, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.429219482758621 ], [ 29.780512405063291, -1.429488965517241 ], [ 29.780781898734176, -1.429488965517241 ], [ 29.780781898734176, -1.429219482758621 ], [ 29.780512405063291, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9869, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.429219482758621 ], [ 29.780781898734176, -1.429488965517241 ], [ 29.781051392405065, -1.429488965517241 ], [ 29.781051392405065, -1.429219482758621 ], [ 29.780781898734176, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9870, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.429219482758621 ], [ 29.781051392405065, -1.429488965517241 ], [ 29.78132088607595, -1.429488965517241 ], [ 29.78132088607595, -1.429219482758621 ], [ 29.781051392405065, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9871, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.429219482758621 ], [ 29.78132088607595, -1.429488965517241 ], [ 29.781590379746834, -1.429488965517241 ], [ 29.781590379746834, -1.429219482758621 ], [ 29.78132088607595, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9872, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.429219482758621 ], [ 29.781590379746834, -1.429488965517241 ], [ 29.781859873417723, -1.429488965517241 ], [ 29.781859873417723, -1.429219482758621 ], [ 29.781590379746834, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9873, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.429219482758621 ], [ 29.781859873417723, -1.429488965517241 ], [ 29.782129367088608, -1.429488965517241 ], [ 29.782129367088608, -1.429219482758621 ], [ 29.781859873417723, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9874, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.429219482758621 ], [ 29.782129367088608, -1.429488965517241 ], [ 29.782398860759493, -1.429488965517241 ], [ 29.782398860759493, -1.429219482758621 ], [ 29.782129367088608, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9875, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.429219482758621 ], [ 29.782398860759493, -1.429488965517241 ], [ 29.782668354430381, -1.429488965517241 ], [ 29.782668354430381, -1.429219482758621 ], [ 29.782398860759493, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.429219482758621 ], [ 29.782668354430381, -1.429488965517241 ], [ 29.782937848101266, -1.429488965517241 ], [ 29.782937848101266, -1.429219482758621 ], [ 29.782668354430381, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9877, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.429219482758621 ], [ 29.782937848101266, -1.429488965517241 ], [ 29.783207341772151, -1.429488965517241 ], [ 29.783207341772151, -1.429219482758621 ], [ 29.782937848101266, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9878, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.429219482758621 ], [ 29.783207341772151, -1.429488965517241 ], [ 29.783476835443039, -1.429488965517241 ], [ 29.783476835443039, -1.429219482758621 ], [ 29.783207341772151, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9879, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.429219482758621 ], [ 29.783476835443039, -1.429488965517241 ], [ 29.784015822784809, -1.429488965517241 ], [ 29.784015822784809, -1.429219482758621 ], [ 29.783476835443039, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9880, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.429219482758621 ], [ 29.784015822784809, -1.429758448275862 ], [ 29.784285316455698, -1.429758448275862 ], [ 29.784285316455698, -1.429219482758621 ], [ 29.784015822784809, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9881, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.429219482758621 ], [ 29.784285316455698, -1.430027931034483 ], [ 29.784554810126583, -1.430027931034483 ], [ 29.784554810126583, -1.429219482758621 ], [ 29.784285316455698, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9882, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.429219482758621 ], [ 29.784554810126583, -1.430027931034483 ], [ 29.784824303797468, -1.430027931034483 ], [ 29.784824303797468, -1.429219482758621 ], [ 29.784554810126583, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9883, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.429219482758621 ], [ 29.791561645569619, -1.429488965517241 ], [ 29.791831139240507, -1.429488965517241 ], [ 29.791831139240507, -1.429219482758621 ], [ 29.791561645569619, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9884, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.429219482758621 ], [ 29.791831139240507, -1.429488965517241 ], [ 29.792100632911392, -1.429488965517241 ], [ 29.792100632911392, -1.429219482758621 ], [ 29.791831139240507, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9885, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.429219482758621 ], [ 29.792100632911392, -1.429488965517241 ], [ 29.792370126582277, -1.429488965517241 ], [ 29.792370126582277, -1.429219482758621 ], [ 29.792100632911392, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9886, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.429219482758621 ], [ 29.792370126582277, -1.429488965517241 ], [ 29.792909113924051, -1.429488965517241 ], [ 29.792909113924051, -1.429219482758621 ], [ 29.792370126582277, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9887, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.429219482758621 ], [ 29.792909113924051, -1.429488965517241 ], [ 29.793178607594935, -1.429488965517241 ], [ 29.793178607594935, -1.429219482758621 ], [ 29.792909113924051, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9888, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.429219482758621 ], [ 29.793178607594935, -1.429488965517241 ], [ 29.793448101265824, -1.429488965517241 ], [ 29.793448101265824, -1.429219482758621 ], [ 29.793178607594935, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9889, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.429219482758621 ], [ 29.793448101265824, -1.429488965517241 ], [ 29.793717594936709, -1.429488965517241 ], [ 29.793717594936709, -1.429219482758621 ], [ 29.793448101265824, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9890, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.429219482758621 ], [ 29.793717594936709, -1.429488965517241 ], [ 29.793987088607594, -1.429488965517241 ], [ 29.793987088607594, -1.429219482758621 ], [ 29.793717594936709, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9891, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.429219482758621 ], [ 29.793987088607594, -1.429488965517241 ], [ 29.794256582278482, -1.429488965517241 ], [ 29.794256582278482, -1.429219482758621 ], [ 29.793987088607594, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9892, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.429219482758621 ], [ 29.794256582278482, -1.429488965517241 ], [ 29.794526075949367, -1.429488965517241 ], [ 29.794526075949367, -1.429219482758621 ], [ 29.794256582278482, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9893, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.429219482758621 ], [ 29.798568481012659, -1.430297413793103 ], [ 29.798837974683543, -1.430297413793103 ], [ 29.798837974683543, -1.429219482758621 ], [ 29.798568481012659, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9894, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.429219482758621 ], [ 29.798837974683543, -1.430297413793103 ], [ 29.799107468354432, -1.430297413793103 ], [ 29.799107468354432, -1.429219482758621 ], [ 29.798837974683543, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9895, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.429219482758621 ], [ 29.799107468354432, -1.430297413793103 ], [ 29.799376962025317, -1.430297413793103 ], [ 29.799376962025317, -1.429219482758621 ], [ 29.799107468354432, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9896, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.429219482758621 ], [ 29.79991594936709, -1.430297413793103 ], [ 29.800185443037975, -1.430297413793103 ], [ 29.800185443037975, -1.429219482758621 ], [ 29.79991594936709, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9897, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.429219482758621 ], [ 29.80045493670886, -1.429488965517241 ], [ 29.800724430379748, -1.429488965517241 ], [ 29.800724430379748, -1.429219482758621 ], [ 29.80045493670886, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9898, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.429219482758621 ], [ 29.800724430379748, -1.429488965517241 ], [ 29.800993924050633, -1.429488965517241 ], [ 29.800993924050633, -1.429219482758621 ], [ 29.800724430379748, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9899, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.429219482758621 ], [ 29.800993924050633, -1.429488965517241 ], [ 29.801263417721518, -1.429488965517241 ], [ 29.801263417721518, -1.429219482758621 ], [ 29.800993924050633, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9900, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.429219482758621 ], [ 29.801263417721518, -1.429488965517241 ], [ 29.802071898734177, -1.429488965517241 ], [ 29.802071898734177, -1.429219482758621 ], [ 29.801263417721518, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9901, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.429219482758621 ], [ 29.802071898734177, -1.429488965517241 ], [ 29.802341392405065, -1.429488965517241 ], [ 29.802341392405065, -1.429219482758621 ], [ 29.802071898734177, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9902, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.429219482758621 ], [ 29.802341392405065, -1.429488965517241 ], [ 29.802880379746835, -1.429488965517241 ], [ 29.802880379746835, -1.429219482758621 ], [ 29.802341392405065, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9903, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.429219482758621 ], [ 29.802880379746835, -1.429488965517241 ], [ 29.803149873417723, -1.429488965517241 ], [ 29.803149873417723, -1.429219482758621 ], [ 29.802880379746835, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9904, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.429219482758621 ], [ 29.803149873417723, -1.429488965517241 ], [ 29.803419367088608, -1.429488965517241 ], [ 29.803419367088608, -1.429219482758621 ], [ 29.803149873417723, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9905, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.429219482758621 ], [ 29.803419367088608, -1.429488965517241 ], [ 29.803688860759493, -1.429488965517241 ], [ 29.803688860759493, -1.429219482758621 ], [ 29.803419367088608, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9906, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.429219482758621 ], [ 29.803688860759493, -1.429488965517241 ], [ 29.803958354430382, -1.429488965517241 ], [ 29.803958354430382, -1.429219482758621 ], [ 29.803688860759493, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9907, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.42895 ], [ 29.804497341772151, -1.42895 ], [ 29.804497341772151, -1.430297413793103 ], [ 29.804227848101267, -1.430297413793103 ], [ 29.804227848101267, -1.429488965517241 ], [ 29.803958354430382, -1.429488965517241 ], [ 29.803958354430382, -1.429219482758621 ], [ 29.804227848101267, -1.429219482758621 ], [ 29.804227848101267, -1.42895 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9908, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.429219482758621 ], [ 29.804497341772151, -1.430566896551724 ], [ 29.80476683544304, -1.430566896551724 ], [ 29.80476683544304, -1.429219482758621 ], [ 29.804497341772151, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9909, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.429219482758621 ], [ 29.80476683544304, -1.430566896551724 ], [ 29.805036329113925, -1.430566896551724 ], [ 29.805036329113925, -1.429219482758621 ], [ 29.80476683544304, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9910, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.429219482758621 ], [ 29.80530582278481, -1.430836379310345 ], [ 29.805575316455698, -1.430836379310345 ], [ 29.805575316455698, -1.429219482758621 ], [ 29.80530582278481, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9911, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.429219482758621 ], [ 29.805575316455698, -1.430836379310345 ], [ 29.805844810126583, -1.430836379310345 ], [ 29.805844810126583, -1.429219482758621 ], [ 29.805575316455698, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9912, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.429219482758621 ], [ 29.805844810126583, -1.429488965517241 ], [ 29.806114303797468, -1.429488965517241 ], [ 29.806114303797468, -1.429219482758621 ], [ 29.805844810126583, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9913, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.429219482758621 ], [ 29.806114303797468, -1.429488965517241 ], [ 29.806383797468357, -1.429488965517241 ], [ 29.806383797468357, -1.429219482758621 ], [ 29.806114303797468, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9914, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.429219482758621 ], [ 29.806383797468357, -1.429488965517241 ], [ 29.806653291139241, -1.429488965517241 ], [ 29.806653291139241, -1.429219482758621 ], [ 29.806383797468357, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9915, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.429219482758621 ], [ 29.806653291139241, -1.429488965517241 ], [ 29.806922784810126, -1.429488965517241 ], [ 29.806922784810126, -1.429219482758621 ], [ 29.806653291139241, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9916, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.429488965517241 ], [ 29.754910506329114, -1.430027931034483 ], [ 29.75518, -1.430027931034483 ], [ 29.75518, -1.429488965517241 ], [ 29.754910506329114, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9917, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.429488965517241 ], [ 29.75518, -1.429758448275862 ], [ 29.755449493670884, -1.429758448275862 ], [ 29.755449493670884, -1.429488965517241 ], [ 29.75518, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9918, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.429488965517241 ], [ 29.755449493670884, -1.429758448275862 ], [ 29.755718987341773, -1.429758448275862 ], [ 29.755718987341773, -1.429488965517241 ], [ 29.755449493670884, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9919, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.429488965517241 ], [ 29.755718987341773, -1.429758448275862 ], [ 29.755988481012658, -1.429758448275862 ], [ 29.755988481012658, -1.429488965517241 ], [ 29.755718987341773, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9920, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.429488965517241 ], [ 29.755988481012658, -1.429758448275862 ], [ 29.756257974683542, -1.429758448275862 ], [ 29.756257974683542, -1.429488965517241 ], [ 29.755988481012658, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9921, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.429488965517241 ], [ 29.756257974683542, -1.429758448275862 ], [ 29.756796962025316, -1.429758448275862 ], [ 29.756796962025316, -1.429488965517241 ], [ 29.756257974683542, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9922, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.429488965517241 ], [ 29.756796962025316, -1.429758448275862 ], [ 29.757066455696201, -1.429758448275862 ], [ 29.757066455696201, -1.429488965517241 ], [ 29.756796962025316, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9923, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.429488965517241 ], [ 29.757066455696201, -1.429758448275862 ], [ 29.757335949367089, -1.429758448275862 ], [ 29.757335949367089, -1.429488965517241 ], [ 29.757066455696201, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9924, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.429488965517241 ], [ 29.757335949367089, -1.429758448275862 ], [ 29.757605443037974, -1.429758448275862 ], [ 29.757605443037974, -1.429488965517241 ], [ 29.757335949367089, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9925, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.429488965517241 ], [ 29.757605443037974, -1.429758448275862 ], [ 29.757874936708859, -1.429758448275862 ], [ 29.757874936708859, -1.429488965517241 ], [ 29.757605443037974, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9926, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.429488965517241 ], [ 29.757874936708859, -1.429758448275862 ], [ 29.758144430379748, -1.429758448275862 ], [ 29.758144430379748, -1.429488965517241 ], [ 29.757874936708859, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9927, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.429488965517241 ], [ 29.758144430379748, -1.429758448275862 ], [ 29.758413924050632, -1.429758448275862 ], [ 29.758413924050632, -1.429488965517241 ], [ 29.758144430379748, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9928, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.429488965517241 ], [ 29.758413924050632, -1.429758448275862 ], [ 29.758683417721517, -1.429758448275862 ], [ 29.758683417721517, -1.429488965517241 ], [ 29.758413924050632, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9929, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.429488965517241 ], [ 29.758683417721517, -1.429758448275862 ], [ 29.758952911392406, -1.429758448275862 ], [ 29.758952911392406, -1.429488965517241 ], [ 29.758683417721517, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9930, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.429488965517241 ], [ 29.758952911392406, -1.429758448275862 ], [ 29.759222405063291, -1.429758448275862 ], [ 29.759222405063291, -1.429488965517241 ], [ 29.758952911392406, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9931, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.429488965517241 ], [ 29.759222405063291, -1.429758448275862 ], [ 29.759491898734176, -1.429758448275862 ], [ 29.759491898734176, -1.429488965517241 ], [ 29.759222405063291, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9932, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.429488965517241 ], [ 29.759491898734176, -1.429758448275862 ], [ 29.759761392405064, -1.429758448275862 ], [ 29.759761392405064, -1.429488965517241 ], [ 29.759491898734176, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9933, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.429488965517241 ], [ 29.759761392405064, -1.429758448275862 ], [ 29.760030886075949, -1.429758448275862 ], [ 29.760030886075949, -1.429488965517241 ], [ 29.759761392405064, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9934, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.429488965517241 ], [ 29.760030886075949, -1.429758448275862 ], [ 29.760300379746834, -1.429758448275862 ], [ 29.760300379746834, -1.429488965517241 ], [ 29.760030886075949, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9935, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.429488965517241 ], [ 29.760300379746834, -1.429758448275862 ], [ 29.760569873417722, -1.429758448275862 ], [ 29.760569873417722, -1.429488965517241 ], [ 29.760300379746834, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9936, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.429488965517241 ], [ 29.760569873417722, -1.429758448275862 ], [ 29.760839367088607, -1.429758448275862 ], [ 29.760839367088607, -1.429488965517241 ], [ 29.760569873417722, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9937, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.429488965517241 ], [ 29.760839367088607, -1.429758448275862 ], [ 29.761108860759492, -1.429758448275862 ], [ 29.761108860759492, -1.429488965517241 ], [ 29.760839367088607, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9938, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.429488965517241 ], [ 29.761108860759492, -1.429758448275862 ], [ 29.761378354430381, -1.429758448275862 ], [ 29.761378354430381, -1.429488965517241 ], [ 29.761108860759492, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9939, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.429488965517241 ], [ 29.761647848101266, -1.429758448275862 ], [ 29.762186835443039, -1.429758448275862 ], [ 29.762186835443039, -1.429488965517241 ], [ 29.761647848101266, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9940, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.429488965517241 ], [ 29.762186835443039, -1.429758448275862 ], [ 29.762725822784809, -1.429758448275862 ], [ 29.762725822784809, -1.429488965517241 ], [ 29.762186835443039, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9941, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.429488965517241 ], [ 29.762725822784809, -1.429758448275862 ], [ 29.762995316455697, -1.429758448275862 ], [ 29.762995316455697, -1.429488965517241 ], [ 29.762725822784809, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9942, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.429488965517241 ], [ 29.762995316455697, -1.429758448275862 ], [ 29.763264810126582, -1.429758448275862 ], [ 29.763264810126582, -1.429488965517241 ], [ 29.762995316455697, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9943, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.429488965517241 ], [ 29.763264810126582, -1.429758448275862 ], [ 29.763534303797467, -1.429758448275862 ], [ 29.763534303797467, -1.429488965517241 ], [ 29.763264810126582, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9944, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.429488965517241 ], [ 29.763534303797467, -1.429758448275862 ], [ 29.763803797468356, -1.429758448275862 ], [ 29.763803797468356, -1.429488965517241 ], [ 29.763534303797467, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9945, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.429488965517241 ], [ 29.763803797468356, -1.429758448275862 ], [ 29.76407329113924, -1.429758448275862 ], [ 29.76407329113924, -1.429488965517241 ], [ 29.763803797468356, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9946, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.429488965517241 ], [ 29.76407329113924, -1.429758448275862 ], [ 29.764342784810125, -1.429758448275862 ], [ 29.764342784810125, -1.429488965517241 ], [ 29.76407329113924, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9947, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.429488965517241 ], [ 29.764342784810125, -1.429758448275862 ], [ 29.764612278481014, -1.429758448275862 ], [ 29.764612278481014, -1.429488965517241 ], [ 29.764342784810125, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9948, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.429488965517241 ], [ 29.764612278481014, -1.429758448275862 ], [ 29.764881772151899, -1.429758448275862 ], [ 29.764881772151899, -1.429488965517241 ], [ 29.764612278481014, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9949, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.429488965517241 ], [ 29.764881772151899, -1.429758448275862 ], [ 29.765151265822784, -1.429758448275862 ], [ 29.765151265822784, -1.429488965517241 ], [ 29.764881772151899, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9950, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.429488965517241 ], [ 29.765151265822784, -1.429758448275862 ], [ 29.765420759493672, -1.429758448275862 ], [ 29.765420759493672, -1.429488965517241 ], [ 29.765151265822784, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9951, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.429488965517241 ], [ 29.765420759493672, -1.429758448275862 ], [ 29.765959746835442, -1.429758448275862 ], [ 29.765959746835442, -1.429488965517241 ], [ 29.765420759493672, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9952, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.429488965517241 ], [ 29.765959746835442, -1.429758448275862 ], [ 29.766229240506327, -1.429758448275862 ], [ 29.766229240506327, -1.429488965517241 ], [ 29.765959746835442, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9953, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.429488965517241 ], [ 29.766229240506327, -1.429758448275862 ], [ 29.766498734177215, -1.429758448275862 ], [ 29.766498734177215, -1.429488965517241 ], [ 29.766229240506327, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9954, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.429488965517241 ], [ 29.766498734177215, -1.429758448275862 ], [ 29.7667682278481, -1.429758448275862 ], [ 29.7667682278481, -1.429488965517241 ], [ 29.766498734177215, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9955, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.429488965517241 ], [ 29.7667682278481, -1.429758448275862 ], [ 29.767037721518985, -1.429758448275862 ], [ 29.767037721518985, -1.429488965517241 ], [ 29.7667682278481, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9956, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.429488965517241 ], [ 29.767037721518985, -1.429758448275862 ], [ 29.767307215189874, -1.429758448275862 ], [ 29.767307215189874, -1.429488965517241 ], [ 29.767037721518985, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9957, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.429488965517241 ], [ 29.767307215189874, -1.429758448275862 ], [ 29.767576708860759, -1.429758448275862 ], [ 29.767576708860759, -1.429488965517241 ], [ 29.767307215189874, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9958, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.429488965517241 ], [ 29.767576708860759, -1.429758448275862 ], [ 29.767846202531643, -1.429758448275862 ], [ 29.767846202531643, -1.429488965517241 ], [ 29.767576708860759, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9959, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.429488965517241 ], [ 29.767846202531643, -1.429758448275862 ], [ 29.768115696202532, -1.429758448275862 ], [ 29.768115696202532, -1.429488965517241 ], [ 29.767846202531643, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9960, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.429488965517241 ], [ 29.768115696202532, -1.429758448275862 ], [ 29.768385189873417, -1.429758448275862 ], [ 29.768385189873417, -1.429488965517241 ], [ 29.768115696202532, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9961, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.429488965517241 ], [ 29.768385189873417, -1.429758448275862 ], [ 29.768654683544302, -1.429758448275862 ], [ 29.768654683544302, -1.429488965517241 ], [ 29.768385189873417, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9962, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.429488965517241 ], [ 29.768654683544302, -1.429758448275862 ], [ 29.76892417721519, -1.429758448275862 ], [ 29.76892417721519, -1.429488965517241 ], [ 29.768654683544302, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9963, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.429488965517241 ], [ 29.76892417721519, -1.429758448275862 ], [ 29.769193670886075, -1.429758448275862 ], [ 29.769193670886075, -1.429488965517241 ], [ 29.76892417721519, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9964, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.429488965517241 ], [ 29.769193670886075, -1.429758448275862 ], [ 29.76946316455696, -1.429758448275862 ], [ 29.76946316455696, -1.429488965517241 ], [ 29.769193670886075, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9965, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.429488965517241 ], [ 29.76946316455696, -1.429758448275862 ], [ 29.769732658227849, -1.429758448275862 ], [ 29.769732658227849, -1.429488965517241 ], [ 29.76946316455696, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9966, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.429488965517241 ], [ 29.769732658227849, -1.429758448275862 ], [ 29.770002151898733, -1.429758448275862 ], [ 29.770002151898733, -1.429488965517241 ], [ 29.769732658227849, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9967, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.429488965517241 ], [ 29.770002151898733, -1.429758448275862 ], [ 29.770271645569618, -1.429758448275862 ], [ 29.770271645569618, -1.429488965517241 ], [ 29.770002151898733, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9968, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.429488965517241 ], [ 29.770271645569618, -1.429758448275862 ], [ 29.770541139240507, -1.429758448275862 ], [ 29.770541139240507, -1.429488965517241 ], [ 29.770271645569618, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9969, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.429488965517241 ], [ 29.770541139240507, -1.429758448275862 ], [ 29.770810632911392, -1.429758448275862 ], [ 29.770810632911392, -1.429488965517241 ], [ 29.770541139240507, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9970, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.429488965517241 ], [ 29.770810632911392, -1.429758448275862 ], [ 29.771080126582277, -1.429758448275862 ], [ 29.771080126582277, -1.429488965517241 ], [ 29.770810632911392, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9971, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.429488965517241 ], [ 29.771080126582277, -1.429758448275862 ], [ 29.771349620253165, -1.429758448275862 ], [ 29.771349620253165, -1.429488965517241 ], [ 29.771080126582277, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9972, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.429488965517241 ], [ 29.771349620253165, -1.429758448275862 ], [ 29.77161911392405, -1.429758448275862 ], [ 29.77161911392405, -1.429488965517241 ], [ 29.771349620253165, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9973, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.429488965517241 ], [ 29.77161911392405, -1.429758448275862 ], [ 29.771888607594935, -1.429758448275862 ], [ 29.771888607594935, -1.429488965517241 ], [ 29.77161911392405, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9974, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.429488965517241 ], [ 29.771888607594935, -1.429758448275862 ], [ 29.772158101265823, -1.429758448275862 ], [ 29.772158101265823, -1.429488965517241 ], [ 29.771888607594935, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9975, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.429488965517241 ], [ 29.772966582278482, -1.430027931034483 ], [ 29.773236075949367, -1.430027931034483 ], [ 29.773236075949367, -1.429488965517241 ], [ 29.772966582278482, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9976, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.429488965517241 ], [ 29.773505569620252, -1.429758448275862 ], [ 29.77377506329114, -1.429758448275862 ], [ 29.77377506329114, -1.429488965517241 ], [ 29.773505569620252, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9977, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.429488965517241 ], [ 29.77377506329114, -1.430027931034483 ], [ 29.774044556962025, -1.430027931034483 ], [ 29.774044556962025, -1.429488965517241 ], [ 29.77377506329114, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9978, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.429488965517241 ], [ 29.77431405063291, -1.429758448275862 ], [ 29.774853037974683, -1.429758448275862 ], [ 29.774853037974683, -1.429488965517241 ], [ 29.77431405063291, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9979, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.429488965517241 ], [ 29.774853037974683, -1.430027931034483 ], [ 29.775122531645568, -1.430027931034483 ], [ 29.775122531645568, -1.429488965517241 ], [ 29.774853037974683, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9980, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.429488965517241 ], [ 29.775392025316457, -1.429758448275862 ], [ 29.775661518987341, -1.429758448275862 ], [ 29.775661518987341, -1.429488965517241 ], [ 29.775392025316457, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9981, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.429488965517241 ], [ 29.775661518987341, -1.430027931034483 ], [ 29.775931012658226, -1.430027931034483 ], [ 29.775931012658226, -1.429488965517241 ], [ 29.775661518987341, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9982, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.429488965517241 ], [ 29.776200506329115, -1.429758448275862 ], [ 29.77647, -1.429758448275862 ], [ 29.77647, -1.429488965517241 ], [ 29.776200506329115, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9983, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.429488965517241 ], [ 29.77647, -1.429758448275862 ], [ 29.776739493670885, -1.429758448275862 ], [ 29.776739493670885, -1.429488965517241 ], [ 29.77647, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9984, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.429488965517241 ], [ 29.777008987341773, -1.429758448275862 ], [ 29.777278481012658, -1.429758448275862 ], [ 29.777278481012658, -1.429488965517241 ], [ 29.777008987341773, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9985, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.429488965517241 ], [ 29.777278481012658, -1.429758448275862 ], [ 29.777547974683543, -1.429758448275862 ], [ 29.777547974683543, -1.429488965517241 ], [ 29.777278481012658, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9986, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.429488965517241 ], [ 29.777547974683543, -1.430027931034483 ], [ 29.777817468354431, -1.430027931034483 ], [ 29.777817468354431, -1.429488965517241 ], [ 29.777547974683543, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9987, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.429488965517241 ], [ 29.777817468354431, -1.429758448275862 ], [ 29.778086962025316, -1.429758448275862 ], [ 29.778086962025316, -1.429488965517241 ], [ 29.777817468354431, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9988, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.429488965517241 ], [ 29.778086962025316, -1.429758448275862 ], [ 29.778356455696201, -1.429758448275862 ], [ 29.778356455696201, -1.429488965517241 ], [ 29.778086962025316, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9989, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.429488965517241 ], [ 29.778356455696201, -1.429758448275862 ], [ 29.77862594936709, -1.429758448275862 ], [ 29.77862594936709, -1.429488965517241 ], [ 29.778356455696201, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9990, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.429488965517241 ], [ 29.778895443037975, -1.429758448275862 ], [ 29.77916493670886, -1.429758448275862 ], [ 29.77916493670886, -1.429488965517241 ], [ 29.778895443037975, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9991, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.429488965517241 ], [ 29.77916493670886, -1.429758448275862 ], [ 29.779434430379748, -1.429758448275862 ], [ 29.779434430379748, -1.429488965517241 ], [ 29.77916493670886, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9992, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.429488965517241 ], [ 29.779434430379748, -1.429758448275862 ], [ 29.779703924050633, -1.429758448275862 ], [ 29.779703924050633, -1.429488965517241 ], [ 29.779434430379748, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9993, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.429488965517241 ], [ 29.779703924050633, -1.429758448275862 ], [ 29.779973417721518, -1.429758448275862 ], [ 29.779973417721518, -1.429488965517241 ], [ 29.779703924050633, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9994, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.429488965517241 ], [ 29.779973417721518, -1.429758448275862 ], [ 29.780242911392406, -1.429758448275862 ], [ 29.780242911392406, -1.429488965517241 ], [ 29.779973417721518, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9995, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.429488965517241 ], [ 29.780242911392406, -1.429758448275862 ], [ 29.780512405063291, -1.429758448275862 ], [ 29.780512405063291, -1.429488965517241 ], [ 29.780242911392406, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9996, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.429488965517241 ], [ 29.780512405063291, -1.429758448275862 ], [ 29.780781898734176, -1.429758448275862 ], [ 29.780781898734176, -1.429488965517241 ], [ 29.780512405063291, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9997, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.429488965517241 ], [ 29.780781898734176, -1.429758448275862 ], [ 29.781051392405065, -1.429758448275862 ], [ 29.781051392405065, -1.429488965517241 ], [ 29.780781898734176, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9998, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.429488965517241 ], [ 29.781051392405065, -1.429758448275862 ], [ 29.78132088607595, -1.429758448275862 ], [ 29.78132088607595, -1.429488965517241 ], [ 29.781051392405065, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 9999, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.429488965517241 ], [ 29.78132088607595, -1.429758448275862 ], [ 29.781590379746834, -1.429758448275862 ], [ 29.781590379746834, -1.429488965517241 ], [ 29.78132088607595, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10000, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.429488965517241 ], [ 29.781590379746834, -1.429758448275862 ], [ 29.781859873417723, -1.429758448275862 ], [ 29.781859873417723, -1.429488965517241 ], [ 29.781590379746834, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10001, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.429488965517241 ], [ 29.781859873417723, -1.429758448275862 ], [ 29.782129367088608, -1.429758448275862 ], [ 29.782129367088608, -1.429488965517241 ], [ 29.781859873417723, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10002, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.429488965517241 ], [ 29.782129367088608, -1.429758448275862 ], [ 29.782398860759493, -1.429758448275862 ], [ 29.782398860759493, -1.429488965517241 ], [ 29.782129367088608, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10003, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.429488965517241 ], [ 29.782398860759493, -1.429758448275862 ], [ 29.782668354430381, -1.429758448275862 ], [ 29.782668354430381, -1.429488965517241 ], [ 29.782398860759493, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10004, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.429488965517241 ], [ 29.782668354430381, -1.429758448275862 ], [ 29.782937848101266, -1.429758448275862 ], [ 29.782937848101266, -1.429488965517241 ], [ 29.782668354430381, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10005, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.429488965517241 ], [ 29.782937848101266, -1.429758448275862 ], [ 29.783207341772151, -1.429758448275862 ], [ 29.783207341772151, -1.429488965517241 ], [ 29.782937848101266, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10006, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.429488965517241 ], [ 29.783207341772151, -1.430027931034483 ], [ 29.783476835443039, -1.430027931034483 ], [ 29.783476835443039, -1.429488965517241 ], [ 29.783207341772151, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10007, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.429488965517241 ], [ 29.783476835443039, -1.430027931034483 ], [ 29.784015822784809, -1.430027931034483 ], [ 29.784015822784809, -1.429488965517241 ], [ 29.783476835443039, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10008, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.429488965517241 ], [ 29.791561645569619, -1.429758448275862 ], [ 29.791831139240507, -1.429758448275862 ], [ 29.791831139240507, -1.429488965517241 ], [ 29.791561645569619, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10009, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.429488965517241 ], [ 29.791831139240507, -1.429758448275862 ], [ 29.792100632911392, -1.429758448275862 ], [ 29.792100632911392, -1.429488965517241 ], [ 29.791831139240507, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10010, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.429488965517241 ], [ 29.792100632911392, -1.429758448275862 ], [ 29.792370126582277, -1.429758448275862 ], [ 29.792370126582277, -1.429488965517241 ], [ 29.792100632911392, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10011, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.429488965517241 ], [ 29.792370126582277, -1.429758448275862 ], [ 29.792909113924051, -1.429758448275862 ], [ 29.792909113924051, -1.429488965517241 ], [ 29.792370126582277, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10012, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.429488965517241 ], [ 29.792909113924051, -1.429758448275862 ], [ 29.793178607594935, -1.429758448275862 ], [ 29.793178607594935, -1.429488965517241 ], [ 29.792909113924051, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10013, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.429488965517241 ], [ 29.793178607594935, -1.429758448275862 ], [ 29.793448101265824, -1.429758448275862 ], [ 29.793448101265824, -1.429488965517241 ], [ 29.793178607594935, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10014, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.429488965517241 ], [ 29.793448101265824, -1.429758448275862 ], [ 29.793717594936709, -1.429758448275862 ], [ 29.793717594936709, -1.429488965517241 ], [ 29.793448101265824, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10015, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.429488965517241 ], [ 29.793717594936709, -1.429758448275862 ], [ 29.793987088607594, -1.429758448275862 ], [ 29.793987088607594, -1.429488965517241 ], [ 29.793717594936709, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10016, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.429488965517241 ], [ 29.793987088607594, -1.429758448275862 ], [ 29.794256582278482, -1.429758448275862 ], [ 29.794256582278482, -1.429488965517241 ], [ 29.793987088607594, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10017, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.429488965517241 ], [ 29.794256582278482, -1.429758448275862 ], [ 29.794526075949367, -1.429758448275862 ], [ 29.794526075949367, -1.429488965517241 ], [ 29.794256582278482, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10018, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.429488965517241 ], [ 29.794526075949367, -1.429758448275862 ], [ 29.794795569620252, -1.429758448275862 ], [ 29.794795569620252, -1.429488965517241 ], [ 29.794526075949367, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10019, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.429488965517241 ], [ 29.794795569620252, -1.430027931034483 ], [ 29.79506506329114, -1.430027931034483 ], [ 29.79506506329114, -1.429488965517241 ], [ 29.794795569620252, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10020, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.429488965517241 ], [ 29.80045493670886, -1.430566896551724 ], [ 29.800724430379748, -1.430566896551724 ], [ 29.800724430379748, -1.429758448275862 ], [ 29.800993924050633, -1.429758448275862 ], [ 29.800993924050633, -1.429488965517241 ], [ 29.80045493670886, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10021, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.429488965517241 ], [ 29.800993924050633, -1.429758448275862 ], [ 29.801263417721518, -1.429758448275862 ], [ 29.801263417721518, -1.429488965517241 ], [ 29.800993924050633, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10022, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.429488965517241 ], [ 29.801263417721518, -1.429758448275862 ], [ 29.801532911392407, -1.429758448275862 ], [ 29.801532911392407, -1.429488965517241 ], [ 29.801263417721518, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10023, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.429488965517241 ], [ 29.801532911392407, -1.429758448275862 ], [ 29.80261088607595, -1.429758448275862 ], [ 29.80261088607595, -1.429488965517241 ], [ 29.801532911392407, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.429488965517241 ], [ 29.80261088607595, -1.429758448275862 ], [ 29.802880379746835, -1.429758448275862 ], [ 29.802880379746835, -1.429488965517241 ], [ 29.80261088607595, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10025, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.429488965517241 ], [ 29.802880379746835, -1.429758448275862 ], [ 29.803149873417723, -1.429758448275862 ], [ 29.803149873417723, -1.429488965517241 ], [ 29.802880379746835, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10026, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.429488965517241 ], [ 29.803149873417723, -1.429758448275862 ], [ 29.803419367088608, -1.429758448275862 ], [ 29.803419367088608, -1.429488965517241 ], [ 29.803149873417723, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10027, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.429488965517241 ], [ 29.803419367088608, -1.429758448275862 ], [ 29.803688860759493, -1.429758448275862 ], [ 29.803688860759493, -1.429488965517241 ], [ 29.803419367088608, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10028, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.429488965517241 ], [ 29.803688860759493, -1.429758448275862 ], [ 29.803958354430382, -1.429758448275862 ], [ 29.803958354430382, -1.429488965517241 ], [ 29.803688860759493, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10029, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.429488965517241 ], [ 29.805036329113925, -1.430836379310345 ], [ 29.80530582278481, -1.430836379310345 ], [ 29.80530582278481, -1.429488965517241 ], [ 29.805036329113925, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10030, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.429758448275862 ], [ 29.75518, -1.430027931034483 ], [ 29.755449493670884, -1.430027931034483 ], [ 29.755449493670884, -1.429758448275862 ], [ 29.75518, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10031, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.429758448275862 ], [ 29.755449493670884, -1.430027931034483 ], [ 29.755718987341773, -1.430027931034483 ], [ 29.755718987341773, -1.429758448275862 ], [ 29.755449493670884, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10032, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.429758448275862 ], [ 29.755718987341773, -1.430027931034483 ], [ 29.755988481012658, -1.430027931034483 ], [ 29.755988481012658, -1.429758448275862 ], [ 29.755718987341773, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10033, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.429758448275862 ], [ 29.755988481012658, -1.430027931034483 ], [ 29.756257974683542, -1.430027931034483 ], [ 29.756257974683542, -1.429758448275862 ], [ 29.755988481012658, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10034, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.429758448275862 ], [ 29.756257974683542, -1.430027931034483 ], [ 29.756796962025316, -1.430027931034483 ], [ 29.756796962025316, -1.429758448275862 ], [ 29.756257974683542, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10035, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.429758448275862 ], [ 29.756796962025316, -1.430027931034483 ], [ 29.757066455696201, -1.430027931034483 ], [ 29.757066455696201, -1.429758448275862 ], [ 29.756796962025316, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10036, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.429758448275862 ], [ 29.757066455696201, -1.430027931034483 ], [ 29.757335949367089, -1.430027931034483 ], [ 29.757335949367089, -1.429758448275862 ], [ 29.757066455696201, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10037, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.429758448275862 ], [ 29.757335949367089, -1.430027931034483 ], [ 29.757605443037974, -1.430027931034483 ], [ 29.757605443037974, -1.429758448275862 ], [ 29.757335949367089, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10038, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.429758448275862 ], [ 29.757605443037974, -1.430027931034483 ], [ 29.757874936708859, -1.430027931034483 ], [ 29.757874936708859, -1.429758448275862 ], [ 29.757605443037974, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10039, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.429758448275862 ], [ 29.757874936708859, -1.430027931034483 ], [ 29.758144430379748, -1.430027931034483 ], [ 29.758144430379748, -1.429758448275862 ], [ 29.757874936708859, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10040, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.429758448275862 ], [ 29.758144430379748, -1.430027931034483 ], [ 29.758413924050632, -1.430027931034483 ], [ 29.758413924050632, -1.429758448275862 ], [ 29.758144430379748, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10041, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.429758448275862 ], [ 29.758413924050632, -1.430027931034483 ], [ 29.758683417721517, -1.430027931034483 ], [ 29.758683417721517, -1.429758448275862 ], [ 29.758413924050632, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10042, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.429758448275862 ], [ 29.758683417721517, -1.430027931034483 ], [ 29.758952911392406, -1.430027931034483 ], [ 29.758952911392406, -1.429758448275862 ], [ 29.758683417721517, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10043, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.429758448275862 ], [ 29.758952911392406, -1.430027931034483 ], [ 29.759222405063291, -1.430027931034483 ], [ 29.759222405063291, -1.429758448275862 ], [ 29.758952911392406, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10044, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.429758448275862 ], [ 29.759222405063291, -1.430027931034483 ], [ 29.759491898734176, -1.430027931034483 ], [ 29.759491898734176, -1.429758448275862 ], [ 29.759222405063291, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10045, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.429758448275862 ], [ 29.759491898734176, -1.430027931034483 ], [ 29.759761392405064, -1.430027931034483 ], [ 29.759761392405064, -1.429758448275862 ], [ 29.759491898734176, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10046, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.429758448275862 ], [ 29.759761392405064, -1.430027931034483 ], [ 29.760030886075949, -1.430027931034483 ], [ 29.760030886075949, -1.429758448275862 ], [ 29.759761392405064, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10047, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.429758448275862 ], [ 29.760030886075949, -1.430027931034483 ], [ 29.760300379746834, -1.430027931034483 ], [ 29.760300379746834, -1.429758448275862 ], [ 29.760030886075949, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10048, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.429758448275862 ], [ 29.760300379746834, -1.430027931034483 ], [ 29.760569873417722, -1.430027931034483 ], [ 29.760569873417722, -1.429758448275862 ], [ 29.760300379746834, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10049, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.429758448275862 ], [ 29.760569873417722, -1.430297413793103 ], [ 29.761108860759492, -1.430297413793103 ], [ 29.761108860759492, -1.430027931034483 ], [ 29.760839367088607, -1.430027931034483 ], [ 29.760839367088607, -1.429758448275862 ], [ 29.760569873417722, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10050, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.429758448275862 ], [ 29.760839367088607, -1.430027931034483 ], [ 29.761108860759492, -1.430027931034483 ], [ 29.761108860759492, -1.429758448275862 ], [ 29.760839367088607, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10051, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.429758448275862 ], [ 29.761108860759492, -1.430027931034483 ], [ 29.761917341772151, -1.430027931034483 ], [ 29.761917341772151, -1.429758448275862 ], [ 29.761108860759492, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10052, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.429758448275862 ], [ 29.761917341772151, -1.430027931034483 ], [ 29.762456329113924, -1.430027931034483 ], [ 29.762456329113924, -1.429758448275862 ], [ 29.761917341772151, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10053, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.429758448275862 ], [ 29.762456329113924, -1.430027931034483 ], [ 29.762725822784809, -1.430027931034483 ], [ 29.762725822784809, -1.429758448275862 ], [ 29.762456329113924, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10054, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.429758448275862 ], [ 29.762725822784809, -1.430027931034483 ], [ 29.762995316455697, -1.430027931034483 ], [ 29.762995316455697, -1.429758448275862 ], [ 29.762725822784809, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10055, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.429758448275862 ], [ 29.762995316455697, -1.430027931034483 ], [ 29.763264810126582, -1.430027931034483 ], [ 29.763264810126582, -1.429758448275862 ], [ 29.762995316455697, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10056, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.429758448275862 ], [ 29.763264810126582, -1.430027931034483 ], [ 29.763534303797467, -1.430027931034483 ], [ 29.763534303797467, -1.429758448275862 ], [ 29.763264810126582, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10057, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.429758448275862 ], [ 29.763534303797467, -1.430027931034483 ], [ 29.763803797468356, -1.430027931034483 ], [ 29.763803797468356, -1.429758448275862 ], [ 29.763534303797467, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10058, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.429758448275862 ], [ 29.763803797468356, -1.430027931034483 ], [ 29.76407329113924, -1.430027931034483 ], [ 29.76407329113924, -1.429758448275862 ], [ 29.763803797468356, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10059, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.429758448275862 ], [ 29.76407329113924, -1.430027931034483 ], [ 29.764342784810125, -1.430027931034483 ], [ 29.764342784810125, -1.429758448275862 ], [ 29.76407329113924, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10060, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.429758448275862 ], [ 29.764342784810125, -1.430027931034483 ], [ 29.764612278481014, -1.430027931034483 ], [ 29.764612278481014, -1.429758448275862 ], [ 29.764342784810125, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10061, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.429758448275862 ], [ 29.764612278481014, -1.430027931034483 ], [ 29.764881772151899, -1.430027931034483 ], [ 29.764881772151899, -1.429758448275862 ], [ 29.764612278481014, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10062, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.429758448275862 ], [ 29.764881772151899, -1.430027931034483 ], [ 29.765151265822784, -1.430027931034483 ], [ 29.765151265822784, -1.429758448275862 ], [ 29.764881772151899, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10063, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.429758448275862 ], [ 29.765151265822784, -1.430027931034483 ], [ 29.765420759493672, -1.430027931034483 ], [ 29.765420759493672, -1.429758448275862 ], [ 29.765151265822784, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10064, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.429758448275862 ], [ 29.765420759493672, -1.430027931034483 ], [ 29.765959746835442, -1.430027931034483 ], [ 29.765959746835442, -1.429758448275862 ], [ 29.765420759493672, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10065, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.429758448275862 ], [ 29.765959746835442, -1.430027931034483 ], [ 29.766229240506327, -1.430027931034483 ], [ 29.766229240506327, -1.429758448275862 ], [ 29.765959746835442, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10066, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.429758448275862 ], [ 29.766229240506327, -1.430027931034483 ], [ 29.766498734177215, -1.430027931034483 ], [ 29.766498734177215, -1.429758448275862 ], [ 29.766229240506327, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10067, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.429758448275862 ], [ 29.766498734177215, -1.430027931034483 ], [ 29.7667682278481, -1.430027931034483 ], [ 29.7667682278481, -1.429758448275862 ], [ 29.766498734177215, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10068, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.429758448275862 ], [ 29.7667682278481, -1.430027931034483 ], [ 29.767037721518985, -1.430027931034483 ], [ 29.767037721518985, -1.429758448275862 ], [ 29.7667682278481, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10069, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.429758448275862 ], [ 29.767037721518985, -1.430027931034483 ], [ 29.767307215189874, -1.430027931034483 ], [ 29.767307215189874, -1.429758448275862 ], [ 29.767037721518985, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10070, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.429758448275862 ], [ 29.767307215189874, -1.430027931034483 ], [ 29.767576708860759, -1.430027931034483 ], [ 29.767576708860759, -1.429758448275862 ], [ 29.767307215189874, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10071, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.429758448275862 ], [ 29.767576708860759, -1.430027931034483 ], [ 29.767846202531643, -1.430027931034483 ], [ 29.767846202531643, -1.429758448275862 ], [ 29.767576708860759, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10072, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.429758448275862 ], [ 29.767846202531643, -1.430027931034483 ], [ 29.768115696202532, -1.430027931034483 ], [ 29.768115696202532, -1.429758448275862 ], [ 29.767846202531643, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10073, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.429758448275862 ], [ 29.768115696202532, -1.430027931034483 ], [ 29.768385189873417, -1.430027931034483 ], [ 29.768385189873417, -1.429758448275862 ], [ 29.768115696202532, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10074, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.429758448275862 ], [ 29.768385189873417, -1.430027931034483 ], [ 29.768654683544302, -1.430027931034483 ], [ 29.768654683544302, -1.429758448275862 ], [ 29.768385189873417, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10075, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.429758448275862 ], [ 29.768654683544302, -1.430027931034483 ], [ 29.76892417721519, -1.430027931034483 ], [ 29.76892417721519, -1.429758448275862 ], [ 29.768654683544302, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10076, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.429758448275862 ], [ 29.76892417721519, -1.430027931034483 ], [ 29.769193670886075, -1.430027931034483 ], [ 29.769193670886075, -1.429758448275862 ], [ 29.76892417721519, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10077, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.429758448275862 ], [ 29.769193670886075, -1.430027931034483 ], [ 29.76946316455696, -1.430027931034483 ], [ 29.76946316455696, -1.429758448275862 ], [ 29.769193670886075, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10078, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.429758448275862 ], [ 29.76946316455696, -1.430027931034483 ], [ 29.769732658227849, -1.430027931034483 ], [ 29.769732658227849, -1.429758448275862 ], [ 29.76946316455696, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10079, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.429758448275862 ], [ 29.769732658227849, -1.430027931034483 ], [ 29.770002151898733, -1.430027931034483 ], [ 29.770002151898733, -1.429758448275862 ], [ 29.769732658227849, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10080, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.429758448275862 ], [ 29.770002151898733, -1.430027931034483 ], [ 29.770271645569618, -1.430027931034483 ], [ 29.770271645569618, -1.429758448275862 ], [ 29.770002151898733, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10081, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.429758448275862 ], [ 29.770271645569618, -1.430027931034483 ], [ 29.770541139240507, -1.430027931034483 ], [ 29.770541139240507, -1.429758448275862 ], [ 29.770271645569618, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10082, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.429758448275862 ], [ 29.770541139240507, -1.430027931034483 ], [ 29.770810632911392, -1.430027931034483 ], [ 29.770810632911392, -1.429758448275862 ], [ 29.770541139240507, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10083, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.429758448275862 ], [ 29.770810632911392, -1.430027931034483 ], [ 29.771080126582277, -1.430027931034483 ], [ 29.771080126582277, -1.429758448275862 ], [ 29.770810632911392, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10084, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.429758448275862 ], [ 29.771080126582277, -1.430027931034483 ], [ 29.771349620253165, -1.430027931034483 ], [ 29.771349620253165, -1.429758448275862 ], [ 29.771080126582277, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10085, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.429758448275862 ], [ 29.771349620253165, -1.430027931034483 ], [ 29.77161911392405, -1.430027931034483 ], [ 29.77161911392405, -1.429758448275862 ], [ 29.771349620253165, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10086, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.429758448275862 ], [ 29.77161911392405, -1.430027931034483 ], [ 29.771888607594935, -1.430027931034483 ], [ 29.771888607594935, -1.429758448275862 ], [ 29.77161911392405, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10087, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.429758448275862 ], [ 29.771888607594935, -1.430027931034483 ], [ 29.772158101265823, -1.430027931034483 ], [ 29.772158101265823, -1.429758448275862 ], [ 29.771888607594935, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10088, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.429758448275862 ], [ 29.772158101265823, -1.430027931034483 ], [ 29.772697088607593, -1.430027931034483 ], [ 29.772697088607593, -1.429758448275862 ], [ 29.772158101265823, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10089, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.429758448275862 ], [ 29.772697088607593, -1.430297413793103 ], [ 29.772966582278482, -1.430297413793103 ], [ 29.772966582278482, -1.429758448275862 ], [ 29.772697088607593, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10090, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.429758448275862 ], [ 29.773236075949367, -1.430027931034483 ], [ 29.773505569620252, -1.430027931034483 ], [ 29.773505569620252, -1.429758448275862 ], [ 29.773236075949367, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10091, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.429758448275862 ], [ 29.773505569620252, -1.430297413793103 ], [ 29.77377506329114, -1.430297413793103 ], [ 29.77377506329114, -1.429758448275862 ], [ 29.773505569620252, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10092, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.429758448275862 ], [ 29.774044556962025, -1.430027931034483 ], [ 29.77431405063291, -1.430027931034483 ], [ 29.77431405063291, -1.429758448275862 ], [ 29.774044556962025, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10093, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.429758448275862 ], [ 29.77431405063291, -1.430297413793103 ], [ 29.774853037974683, -1.430297413793103 ], [ 29.774853037974683, -1.429758448275862 ], [ 29.77431405063291, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10094, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.429758448275862 ], [ 29.775122531645568, -1.430027931034483 ], [ 29.775392025316457, -1.430027931034483 ], [ 29.775392025316457, -1.429758448275862 ], [ 29.775122531645568, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10095, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.429758448275862 ], [ 29.775392025316457, -1.430027931034483 ], [ 29.775661518987341, -1.430027931034483 ], [ 29.775661518987341, -1.429758448275862 ], [ 29.775392025316457, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10096, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.429758448275862 ], [ 29.775931012658226, -1.430027931034483 ], [ 29.776200506329115, -1.430027931034483 ], [ 29.776200506329115, -1.429758448275862 ], [ 29.775931012658226, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10097, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.429758448275862 ], [ 29.776200506329115, -1.430027931034483 ], [ 29.77647, -1.430027931034483 ], [ 29.77647, -1.429758448275862 ], [ 29.776200506329115, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10098, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.429758448275862 ], [ 29.77647, -1.430297413793103 ], [ 29.776739493670885, -1.430297413793103 ], [ 29.776739493670885, -1.429758448275862 ], [ 29.77647, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10099, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.429758448275862 ], [ 29.776739493670885, -1.430027931034483 ], [ 29.777008987341773, -1.430027931034483 ], [ 29.777008987341773, -1.429758448275862 ], [ 29.776739493670885, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10100, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.429758448275862 ], [ 29.777008987341773, -1.430027931034483 ], [ 29.777278481012658, -1.430027931034483 ], [ 29.777278481012658, -1.429758448275862 ], [ 29.777008987341773, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10101, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.429758448275862 ], [ 29.777278481012658, -1.430027931034483 ], [ 29.777547974683543, -1.430027931034483 ], [ 29.777547974683543, -1.429758448275862 ], [ 29.777278481012658, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10102, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.429758448275862 ], [ 29.777817468354431, -1.430027931034483 ], [ 29.778086962025316, -1.430027931034483 ], [ 29.778086962025316, -1.429758448275862 ], [ 29.777817468354431, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10103, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.429758448275862 ], [ 29.778086962025316, -1.430027931034483 ], [ 29.778356455696201, -1.430027931034483 ], [ 29.778356455696201, -1.429758448275862 ], [ 29.778086962025316, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10104, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.429758448275862 ], [ 29.778356455696201, -1.430027931034483 ], [ 29.77862594936709, -1.430027931034483 ], [ 29.77862594936709, -1.429758448275862 ], [ 29.778356455696201, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10105, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.429758448275862 ], [ 29.77862594936709, -1.430027931034483 ], [ 29.778895443037975, -1.430027931034483 ], [ 29.778895443037975, -1.429758448275862 ], [ 29.77862594936709, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10106, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.429758448275862 ], [ 29.778895443037975, -1.430027931034483 ], [ 29.77916493670886, -1.430027931034483 ], [ 29.77916493670886, -1.429758448275862 ], [ 29.778895443037975, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10107, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.429758448275862 ], [ 29.77916493670886, -1.430027931034483 ], [ 29.779434430379748, -1.430027931034483 ], [ 29.779434430379748, -1.429758448275862 ], [ 29.77916493670886, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10108, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.429758448275862 ], [ 29.779434430379748, -1.430027931034483 ], [ 29.779703924050633, -1.430027931034483 ], [ 29.779703924050633, -1.429758448275862 ], [ 29.779434430379748, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10109, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.429758448275862 ], [ 29.779703924050633, -1.430027931034483 ], [ 29.779973417721518, -1.430027931034483 ], [ 29.779973417721518, -1.429758448275862 ], [ 29.779703924050633, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10110, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.429758448275862 ], [ 29.779973417721518, -1.430027931034483 ], [ 29.780242911392406, -1.430027931034483 ], [ 29.780242911392406, -1.429758448275862 ], [ 29.779973417721518, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10111, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.429758448275862 ], [ 29.780242911392406, -1.430027931034483 ], [ 29.780512405063291, -1.430027931034483 ], [ 29.780512405063291, -1.429758448275862 ], [ 29.780242911392406, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10112, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.429758448275862 ], [ 29.780512405063291, -1.430027931034483 ], [ 29.780781898734176, -1.430027931034483 ], [ 29.780781898734176, -1.429758448275862 ], [ 29.780512405063291, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10113, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.429758448275862 ], [ 29.780781898734176, -1.430027931034483 ], [ 29.781051392405065, -1.430027931034483 ], [ 29.781051392405065, -1.429758448275862 ], [ 29.780781898734176, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10114, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.429758448275862 ], [ 29.781051392405065, -1.430027931034483 ], [ 29.78132088607595, -1.430027931034483 ], [ 29.78132088607595, -1.429758448275862 ], [ 29.781051392405065, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10115, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.429758448275862 ], [ 29.78132088607595, -1.430027931034483 ], [ 29.781590379746834, -1.430027931034483 ], [ 29.781590379746834, -1.429758448275862 ], [ 29.78132088607595, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10116, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.429758448275862 ], [ 29.781590379746834, -1.430027931034483 ], [ 29.781859873417723, -1.430027931034483 ], [ 29.781859873417723, -1.429758448275862 ], [ 29.781590379746834, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10117, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.429758448275862 ], [ 29.781859873417723, -1.430027931034483 ], [ 29.782129367088608, -1.430027931034483 ], [ 29.782129367088608, -1.429758448275862 ], [ 29.781859873417723, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10118, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.429758448275862 ], [ 29.782129367088608, -1.430297413793103 ], [ 29.782398860759493, -1.430297413793103 ], [ 29.782398860759493, -1.429758448275862 ], [ 29.782129367088608, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10119, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.429758448275862 ], [ 29.782398860759493, -1.430297413793103 ], [ 29.782668354430381, -1.430297413793103 ], [ 29.782668354430381, -1.429758448275862 ], [ 29.782398860759493, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10120, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.429758448275862 ], [ 29.782668354430381, -1.430027931034483 ], [ 29.782937848101266, -1.430027931034483 ], [ 29.782937848101266, -1.429758448275862 ], [ 29.782668354430381, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10121, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.429758448275862 ], [ 29.782937848101266, -1.430297413793103 ], [ 29.783207341772151, -1.430297413793103 ], [ 29.783207341772151, -1.429758448275862 ], [ 29.782937848101266, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10122, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.429758448275862 ], [ 29.784015822784809, -1.430297413793103 ], [ 29.784285316455698, -1.430297413793103 ], [ 29.784285316455698, -1.429758448275862 ], [ 29.784015822784809, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10123, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.429758448275862 ], [ 29.784824303797468, -1.430027931034483 ], [ 29.785093797468356, -1.430027931034483 ], [ 29.785093797468356, -1.429758448275862 ], [ 29.784824303797468, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10124, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.429758448275862 ], [ 29.785093797468356, -1.430027931034483 ], [ 29.785363291139241, -1.430027931034483 ], [ 29.785363291139241, -1.429758448275862 ], [ 29.785093797468356, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10125, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.429758448275862 ], [ 29.785363291139241, -1.430027931034483 ], [ 29.785632784810126, -1.430027931034483 ], [ 29.785632784810126, -1.429758448275862 ], [ 29.785363291139241, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10126, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.429758448275862 ], [ 29.791831139240507, -1.430027931034483 ], [ 29.792100632911392, -1.430027931034483 ], [ 29.792100632911392, -1.429758448275862 ], [ 29.791831139240507, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10127, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.429758448275862 ], [ 29.792100632911392, -1.430027931034483 ], [ 29.792370126582277, -1.430027931034483 ], [ 29.792370126582277, -1.429758448275862 ], [ 29.792100632911392, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10128, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.429758448275862 ], [ 29.792370126582277, -1.430027931034483 ], [ 29.792909113924051, -1.430027931034483 ], [ 29.792909113924051, -1.429758448275862 ], [ 29.792370126582277, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10129, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.429758448275862 ], [ 29.792909113924051, -1.430027931034483 ], [ 29.793178607594935, -1.430027931034483 ], [ 29.793178607594935, -1.429758448275862 ], [ 29.792909113924051, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10130, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.429758448275862 ], [ 29.793178607594935, -1.430027931034483 ], [ 29.793448101265824, -1.430027931034483 ], [ 29.793448101265824, -1.429758448275862 ], [ 29.793178607594935, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10131, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.429758448275862 ], [ 29.793448101265824, -1.430027931034483 ], [ 29.793717594936709, -1.430027931034483 ], [ 29.793717594936709, -1.429758448275862 ], [ 29.793448101265824, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10132, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.429758448275862 ], [ 29.793717594936709, -1.430027931034483 ], [ 29.793987088607594, -1.430027931034483 ], [ 29.793987088607594, -1.429758448275862 ], [ 29.793717594936709, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10133, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.429758448275862 ], [ 29.793987088607594, -1.430027931034483 ], [ 29.794256582278482, -1.430027931034483 ], [ 29.794256582278482, -1.429758448275862 ], [ 29.793987088607594, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10134, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.429758448275862 ], [ 29.794256582278482, -1.430027931034483 ], [ 29.794526075949367, -1.430027931034483 ], [ 29.794526075949367, -1.429758448275862 ], [ 29.794256582278482, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10135, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.429758448275862 ], [ 29.794526075949367, -1.430027931034483 ], [ 29.794795569620252, -1.430027931034483 ], [ 29.794795569620252, -1.429758448275862 ], [ 29.794526075949367, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10136, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.429758448275862 ], [ 29.800724430379748, -1.430027931034483 ], [ 29.800993924050633, -1.430027931034483 ], [ 29.800993924050633, -1.429758448275862 ], [ 29.800724430379748, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10137, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.429758448275862 ], [ 29.800993924050633, -1.430027931034483 ], [ 29.801263417721518, -1.430027931034483 ], [ 29.801263417721518, -1.429758448275862 ], [ 29.800993924050633, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10138, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.429758448275862 ], [ 29.801263417721518, -1.430027931034483 ], [ 29.801532911392407, -1.430027931034483 ], [ 29.801532911392407, -1.429758448275862 ], [ 29.801263417721518, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10139, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.429758448275862 ], [ 29.801532911392407, -1.430027931034483 ], [ 29.80261088607595, -1.430027931034483 ], [ 29.80261088607595, -1.429758448275862 ], [ 29.801532911392407, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10140, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.429758448275862 ], [ 29.80261088607595, -1.430027931034483 ], [ 29.802880379746835, -1.430027931034483 ], [ 29.802880379746835, -1.429758448275862 ], [ 29.80261088607595, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10141, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.429758448275862 ], [ 29.802880379746835, -1.430027931034483 ], [ 29.803149873417723, -1.430027931034483 ], [ 29.803149873417723, -1.429758448275862 ], [ 29.802880379746835, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10142, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.429758448275862 ], [ 29.803149873417723, -1.430027931034483 ], [ 29.803419367088608, -1.430027931034483 ], [ 29.803419367088608, -1.429758448275862 ], [ 29.803149873417723, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10143, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.429758448275862 ], [ 29.803419367088608, -1.430027931034483 ], [ 29.803688860759493, -1.430027931034483 ], [ 29.803688860759493, -1.429758448275862 ], [ 29.803419367088608, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10144, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.429488965517241 ], [ 29.804227848101267, -1.429488965517241 ], [ 29.804227848101267, -1.430297413793103 ], [ 29.803958354430382, -1.430297413793103 ], [ 29.803958354430382, -1.430027931034483 ], [ 29.803688860759493, -1.430027931034483 ], [ 29.803688860759493, -1.429758448275862 ], [ 29.803958354430382, -1.429758448275862 ], [ 29.803958354430382, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10145, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.430027931034483 ], [ 29.754910506329114, -1.430297413793103 ], [ 29.75518, -1.430297413793103 ], [ 29.75518, -1.430027931034483 ], [ 29.754910506329114, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10146, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.430027931034483 ], [ 29.75518, -1.430297413793103 ], [ 29.755449493670884, -1.430297413793103 ], [ 29.755449493670884, -1.430027931034483 ], [ 29.75518, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10147, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.430027931034483 ], [ 29.755449493670884, -1.430297413793103 ], [ 29.755718987341773, -1.430297413793103 ], [ 29.755718987341773, -1.430027931034483 ], [ 29.755449493670884, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10148, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.430027931034483 ], [ 29.755718987341773, -1.430297413793103 ], [ 29.755988481012658, -1.430297413793103 ], [ 29.755988481012658, -1.430027931034483 ], [ 29.755718987341773, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10149, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.430027931034483 ], [ 29.755988481012658, -1.430297413793103 ], [ 29.756257974683542, -1.430297413793103 ], [ 29.756257974683542, -1.430027931034483 ], [ 29.755988481012658, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10150, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.430027931034483 ], [ 29.756257974683542, -1.430297413793103 ], [ 29.756796962025316, -1.430297413793103 ], [ 29.756796962025316, -1.430027931034483 ], [ 29.756257974683542, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10151, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.430027931034483 ], [ 29.756796962025316, -1.430297413793103 ], [ 29.757066455696201, -1.430297413793103 ], [ 29.757066455696201, -1.430027931034483 ], [ 29.756796962025316, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10152, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.430027931034483 ], [ 29.757066455696201, -1.430297413793103 ], [ 29.757335949367089, -1.430297413793103 ], [ 29.757335949367089, -1.430027931034483 ], [ 29.757066455696201, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10153, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.430027931034483 ], [ 29.757335949367089, -1.430297413793103 ], [ 29.757605443037974, -1.430297413793103 ], [ 29.757605443037974, -1.430027931034483 ], [ 29.757335949367089, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10154, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.430027931034483 ], [ 29.757605443037974, -1.430297413793103 ], [ 29.757874936708859, -1.430297413793103 ], [ 29.757874936708859, -1.430027931034483 ], [ 29.757605443037974, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10155, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.430027931034483 ], [ 29.757874936708859, -1.430297413793103 ], [ 29.758144430379748, -1.430297413793103 ], [ 29.758144430379748, -1.430027931034483 ], [ 29.757874936708859, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10156, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.430027931034483 ], [ 29.758144430379748, -1.430297413793103 ], [ 29.758413924050632, -1.430297413793103 ], [ 29.758413924050632, -1.430027931034483 ], [ 29.758144430379748, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10157, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.430027931034483 ], [ 29.758413924050632, -1.430297413793103 ], [ 29.758683417721517, -1.430297413793103 ], [ 29.758683417721517, -1.430027931034483 ], [ 29.758413924050632, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10158, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.430027931034483 ], [ 29.758683417721517, -1.430297413793103 ], [ 29.758952911392406, -1.430297413793103 ], [ 29.758952911392406, -1.430027931034483 ], [ 29.758683417721517, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10159, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.430027931034483 ], [ 29.758952911392406, -1.430297413793103 ], [ 29.759222405063291, -1.430297413793103 ], [ 29.759222405063291, -1.430027931034483 ], [ 29.758952911392406, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10160, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.430027931034483 ], [ 29.759222405063291, -1.430297413793103 ], [ 29.759491898734176, -1.430297413793103 ], [ 29.759491898734176, -1.430027931034483 ], [ 29.759222405063291, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10161, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.430027931034483 ], [ 29.759491898734176, -1.430297413793103 ], [ 29.759761392405064, -1.430297413793103 ], [ 29.759761392405064, -1.430027931034483 ], [ 29.759491898734176, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10162, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.430027931034483 ], [ 29.759761392405064, -1.430297413793103 ], [ 29.760030886075949, -1.430297413793103 ], [ 29.760030886075949, -1.430027931034483 ], [ 29.759761392405064, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10163, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.430027931034483 ], [ 29.760030886075949, -1.430566896551724 ], [ 29.760569873417722, -1.430566896551724 ], [ 29.760569873417722, -1.430297413793103 ], [ 29.760300379746834, -1.430297413793103 ], [ 29.760300379746834, -1.430027931034483 ], [ 29.760030886075949, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10164, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.430027931034483 ], [ 29.760300379746834, -1.430297413793103 ], [ 29.760569873417722, -1.430297413793103 ], [ 29.760569873417722, -1.430027931034483 ], [ 29.760300379746834, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10165, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.430027931034483 ], [ 29.761108860759492, -1.430297413793103 ], [ 29.762186835443039, -1.430297413793103 ], [ 29.762186835443039, -1.430027931034483 ], [ 29.761108860759492, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10166, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.430027931034483 ], [ 29.762186835443039, -1.430297413793103 ], [ 29.762456329113924, -1.430297413793103 ], [ 29.762456329113924, -1.430027931034483 ], [ 29.762186835443039, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10167, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.430027931034483 ], [ 29.762456329113924, -1.430297413793103 ], [ 29.762725822784809, -1.430297413793103 ], [ 29.762725822784809, -1.430027931034483 ], [ 29.762456329113924, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10168, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.430027931034483 ], [ 29.762725822784809, -1.430297413793103 ], [ 29.762995316455697, -1.430297413793103 ], [ 29.762995316455697, -1.430027931034483 ], [ 29.762725822784809, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10169, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.430027931034483 ], [ 29.762995316455697, -1.430297413793103 ], [ 29.763264810126582, -1.430297413793103 ], [ 29.763264810126582, -1.430027931034483 ], [ 29.762995316455697, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10170, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.430027931034483 ], [ 29.763264810126582, -1.430297413793103 ], [ 29.763534303797467, -1.430297413793103 ], [ 29.763534303797467, -1.430027931034483 ], [ 29.763264810126582, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10171, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.430027931034483 ], [ 29.763534303797467, -1.430297413793103 ], [ 29.763803797468356, -1.430297413793103 ], [ 29.763803797468356, -1.430027931034483 ], [ 29.763534303797467, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10172, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.430027931034483 ], [ 29.763803797468356, -1.430297413793103 ], [ 29.76407329113924, -1.430297413793103 ], [ 29.76407329113924, -1.430027931034483 ], [ 29.763803797468356, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10173, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.430027931034483 ], [ 29.76407329113924, -1.430297413793103 ], [ 29.764342784810125, -1.430297413793103 ], [ 29.764342784810125, -1.430027931034483 ], [ 29.76407329113924, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10174, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.430027931034483 ], [ 29.764342784810125, -1.430297413793103 ], [ 29.764612278481014, -1.430297413793103 ], [ 29.764612278481014, -1.430027931034483 ], [ 29.764342784810125, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10175, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.430027931034483 ], [ 29.764612278481014, -1.430297413793103 ], [ 29.764881772151899, -1.430297413793103 ], [ 29.764881772151899, -1.430027931034483 ], [ 29.764612278481014, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10176, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.430027931034483 ], [ 29.764881772151899, -1.430297413793103 ], [ 29.765151265822784, -1.430297413793103 ], [ 29.765151265822784, -1.430027931034483 ], [ 29.764881772151899, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10177, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.430027931034483 ], [ 29.765151265822784, -1.430297413793103 ], [ 29.765420759493672, -1.430297413793103 ], [ 29.765420759493672, -1.430027931034483 ], [ 29.765151265822784, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10178, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.430027931034483 ], [ 29.765420759493672, -1.430297413793103 ], [ 29.765959746835442, -1.430297413793103 ], [ 29.765959746835442, -1.430027931034483 ], [ 29.765420759493672, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10179, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.430027931034483 ], [ 29.765959746835442, -1.430297413793103 ], [ 29.766229240506327, -1.430297413793103 ], [ 29.766229240506327, -1.430027931034483 ], [ 29.765959746835442, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10180, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.430027931034483 ], [ 29.766229240506327, -1.430297413793103 ], [ 29.766498734177215, -1.430297413793103 ], [ 29.766498734177215, -1.430027931034483 ], [ 29.766229240506327, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10181, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.430027931034483 ], [ 29.766498734177215, -1.430297413793103 ], [ 29.7667682278481, -1.430297413793103 ], [ 29.7667682278481, -1.430027931034483 ], [ 29.766498734177215, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10182, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.430027931034483 ], [ 29.7667682278481, -1.430297413793103 ], [ 29.767037721518985, -1.430297413793103 ], [ 29.767037721518985, -1.430027931034483 ], [ 29.7667682278481, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10183, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.430027931034483 ], [ 29.767037721518985, -1.430297413793103 ], [ 29.767307215189874, -1.430297413793103 ], [ 29.767307215189874, -1.430027931034483 ], [ 29.767037721518985, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10184, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.430027931034483 ], [ 29.767307215189874, -1.430297413793103 ], [ 29.767576708860759, -1.430297413793103 ], [ 29.767576708860759, -1.430027931034483 ], [ 29.767307215189874, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10185, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.430027931034483 ], [ 29.767576708860759, -1.430297413793103 ], [ 29.767846202531643, -1.430297413793103 ], [ 29.767846202531643, -1.430027931034483 ], [ 29.767576708860759, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10186, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.430027931034483 ], [ 29.767846202531643, -1.430297413793103 ], [ 29.768115696202532, -1.430297413793103 ], [ 29.768115696202532, -1.430027931034483 ], [ 29.767846202531643, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10187, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.430027931034483 ], [ 29.768115696202532, -1.430297413793103 ], [ 29.768385189873417, -1.430297413793103 ], [ 29.768385189873417, -1.430027931034483 ], [ 29.768115696202532, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10188, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.430027931034483 ], [ 29.768385189873417, -1.430297413793103 ], [ 29.768654683544302, -1.430297413793103 ], [ 29.768654683544302, -1.430027931034483 ], [ 29.768385189873417, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10189, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.430027931034483 ], [ 29.768654683544302, -1.430297413793103 ], [ 29.76892417721519, -1.430297413793103 ], [ 29.76892417721519, -1.430027931034483 ], [ 29.768654683544302, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10190, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.430027931034483 ], [ 29.76892417721519, -1.430297413793103 ], [ 29.769193670886075, -1.430297413793103 ], [ 29.769193670886075, -1.430027931034483 ], [ 29.76892417721519, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10191, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.430027931034483 ], [ 29.769193670886075, -1.430297413793103 ], [ 29.76946316455696, -1.430297413793103 ], [ 29.76946316455696, -1.430027931034483 ], [ 29.769193670886075, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10192, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.430027931034483 ], [ 29.76946316455696, -1.430297413793103 ], [ 29.769732658227849, -1.430297413793103 ], [ 29.769732658227849, -1.430027931034483 ], [ 29.76946316455696, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10193, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.430027931034483 ], [ 29.769732658227849, -1.430297413793103 ], [ 29.770002151898733, -1.430297413793103 ], [ 29.770002151898733, -1.430027931034483 ], [ 29.769732658227849, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10194, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.430027931034483 ], [ 29.770002151898733, -1.430297413793103 ], [ 29.770271645569618, -1.430297413793103 ], [ 29.770271645569618, -1.430027931034483 ], [ 29.770002151898733, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10195, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.430027931034483 ], [ 29.770271645569618, -1.430297413793103 ], [ 29.770541139240507, -1.430297413793103 ], [ 29.770541139240507, -1.430027931034483 ], [ 29.770271645569618, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10196, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.430027931034483 ], [ 29.770541139240507, -1.430297413793103 ], [ 29.770810632911392, -1.430297413793103 ], [ 29.770810632911392, -1.430027931034483 ], [ 29.770541139240507, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10197, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.430027931034483 ], [ 29.770810632911392, -1.430297413793103 ], [ 29.771080126582277, -1.430297413793103 ], [ 29.771080126582277, -1.430027931034483 ], [ 29.770810632911392, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10198, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.430027931034483 ], [ 29.771080126582277, -1.430297413793103 ], [ 29.771349620253165, -1.430297413793103 ], [ 29.771349620253165, -1.430027931034483 ], [ 29.771080126582277, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10199, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.430027931034483 ], [ 29.771349620253165, -1.430297413793103 ], [ 29.77161911392405, -1.430297413793103 ], [ 29.77161911392405, -1.430027931034483 ], [ 29.771349620253165, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10200, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.430027931034483 ], [ 29.77161911392405, -1.430297413793103 ], [ 29.771888607594935, -1.430297413793103 ], [ 29.771888607594935, -1.430027931034483 ], [ 29.77161911392405, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10201, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.430027931034483 ], [ 29.771888607594935, -1.430297413793103 ], [ 29.772158101265823, -1.430297413793103 ], [ 29.772158101265823, -1.430027931034483 ], [ 29.771888607594935, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10202, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.430027931034483 ], [ 29.772158101265823, -1.430297413793103 ], [ 29.772427594936708, -1.430297413793103 ], [ 29.772427594936708, -1.430027931034483 ], [ 29.772158101265823, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10203, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.430027931034483 ], [ 29.772427594936708, -1.430297413793103 ], [ 29.772697088607593, -1.430297413793103 ], [ 29.772697088607593, -1.430027931034483 ], [ 29.772427594936708, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10204, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.430027931034483 ], [ 29.772966582278482, -1.430297413793103 ], [ 29.773236075949367, -1.430297413793103 ], [ 29.773236075949367, -1.430027931034483 ], [ 29.772966582278482, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10205, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.430027931034483 ], [ 29.773236075949367, -1.430566896551724 ], [ 29.773505569620252, -1.430566896551724 ], [ 29.773505569620252, -1.430027931034483 ], [ 29.773236075949367, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10206, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.430027931034483 ], [ 29.77377506329114, -1.430297413793103 ], [ 29.774044556962025, -1.430297413793103 ], [ 29.774044556962025, -1.430027931034483 ], [ 29.77377506329114, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10207, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.430027931034483 ], [ 29.774044556962025, -1.430297413793103 ], [ 29.77431405063291, -1.430297413793103 ], [ 29.77431405063291, -1.430027931034483 ], [ 29.774044556962025, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10208, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.430027931034483 ], [ 29.774853037974683, -1.430297413793103 ], [ 29.775122531645568, -1.430297413793103 ], [ 29.775122531645568, -1.430027931034483 ], [ 29.774853037974683, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10209, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.430027931034483 ], [ 29.775122531645568, -1.430297413793103 ], [ 29.775392025316457, -1.430297413793103 ], [ 29.775392025316457, -1.430027931034483 ], [ 29.775122531645568, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10210, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.430027931034483 ], [ 29.775392025316457, -1.430566896551724 ], [ 29.775661518987341, -1.430566896551724 ], [ 29.775661518987341, -1.430027931034483 ], [ 29.775392025316457, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10211, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.430027931034483 ], [ 29.775661518987341, -1.430297413793103 ], [ 29.775931012658226, -1.430297413793103 ], [ 29.775931012658226, -1.430027931034483 ], [ 29.775661518987341, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10212, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.430027931034483 ], [ 29.775931012658226, -1.430297413793103 ], [ 29.776200506329115, -1.430297413793103 ], [ 29.776200506329115, -1.430027931034483 ], [ 29.775931012658226, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10213, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.430027931034483 ], [ 29.776200506329115, -1.430297413793103 ], [ 29.77647, -1.430297413793103 ], [ 29.77647, -1.430027931034483 ], [ 29.776200506329115, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10214, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.430027931034483 ], [ 29.776739493670885, -1.430297413793103 ], [ 29.777008987341773, -1.430297413793103 ], [ 29.777008987341773, -1.430027931034483 ], [ 29.776739493670885, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10215, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.430027931034483 ], [ 29.777008987341773, -1.430297413793103 ], [ 29.777278481012658, -1.430297413793103 ], [ 29.777278481012658, -1.430027931034483 ], [ 29.777008987341773, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10216, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.430027931034483 ], [ 29.777278481012658, -1.430297413793103 ], [ 29.777547974683543, -1.430297413793103 ], [ 29.777547974683543, -1.430027931034483 ], [ 29.777278481012658, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10217, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.430027931034483 ], [ 29.777547974683543, -1.430297413793103 ], [ 29.777817468354431, -1.430297413793103 ], [ 29.777817468354431, -1.430027931034483 ], [ 29.777547974683543, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10218, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.430027931034483 ], [ 29.777817468354431, -1.430297413793103 ], [ 29.778086962025316, -1.430297413793103 ], [ 29.778086962025316, -1.430027931034483 ], [ 29.777817468354431, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10219, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.430027931034483 ], [ 29.778086962025316, -1.430297413793103 ], [ 29.778356455696201, -1.430297413793103 ], [ 29.778356455696201, -1.430027931034483 ], [ 29.778086962025316, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10220, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.430027931034483 ], [ 29.778356455696201, -1.430297413793103 ], [ 29.77862594936709, -1.430297413793103 ], [ 29.77862594936709, -1.430027931034483 ], [ 29.778356455696201, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10221, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.430027931034483 ], [ 29.77862594936709, -1.430297413793103 ], [ 29.778895443037975, -1.430297413793103 ], [ 29.778895443037975, -1.430027931034483 ], [ 29.77862594936709, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10222, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.430027931034483 ], [ 29.778895443037975, -1.430297413793103 ], [ 29.77916493670886, -1.430297413793103 ], [ 29.77916493670886, -1.430027931034483 ], [ 29.778895443037975, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10223, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.430027931034483 ], [ 29.77916493670886, -1.430297413793103 ], [ 29.779434430379748, -1.430297413793103 ], [ 29.779434430379748, -1.430027931034483 ], [ 29.77916493670886, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10224, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.430027931034483 ], [ 29.779434430379748, -1.430297413793103 ], [ 29.779703924050633, -1.430297413793103 ], [ 29.779703924050633, -1.430027931034483 ], [ 29.779434430379748, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10225, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.430027931034483 ], [ 29.779703924050633, -1.430297413793103 ], [ 29.779973417721518, -1.430297413793103 ], [ 29.779973417721518, -1.430027931034483 ], [ 29.779703924050633, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10226, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.430027931034483 ], [ 29.779973417721518, -1.430297413793103 ], [ 29.780242911392406, -1.430297413793103 ], [ 29.780242911392406, -1.430027931034483 ], [ 29.779973417721518, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10227, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.430027931034483 ], [ 29.780242911392406, -1.430297413793103 ], [ 29.780512405063291, -1.430297413793103 ], [ 29.780512405063291, -1.430027931034483 ], [ 29.780242911392406, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10228, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.430027931034483 ], [ 29.780512405063291, -1.430297413793103 ], [ 29.780781898734176, -1.430297413793103 ], [ 29.780781898734176, -1.430027931034483 ], [ 29.780512405063291, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10229, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.430027931034483 ], [ 29.780781898734176, -1.430297413793103 ], [ 29.781051392405065, -1.430297413793103 ], [ 29.781051392405065, -1.430027931034483 ], [ 29.780781898734176, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10230, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.430027931034483 ], [ 29.781051392405065, -1.430297413793103 ], [ 29.78132088607595, -1.430297413793103 ], [ 29.78132088607595, -1.430027931034483 ], [ 29.781051392405065, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10231, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.430027931034483 ], [ 29.78132088607595, -1.430297413793103 ], [ 29.781590379746834, -1.430297413793103 ], [ 29.781590379746834, -1.430027931034483 ], [ 29.78132088607595, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10232, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.430027931034483 ], [ 29.781590379746834, -1.430297413793103 ], [ 29.781859873417723, -1.430297413793103 ], [ 29.781859873417723, -1.430027931034483 ], [ 29.781590379746834, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10233, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.430027931034483 ], [ 29.781859873417723, -1.430566896551724 ], [ 29.782129367088608, -1.430566896551724 ], [ 29.782129367088608, -1.430027931034483 ], [ 29.781859873417723, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10234, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.430027931034483 ], [ 29.782668354430381, -1.430566896551724 ], [ 29.782937848101266, -1.430566896551724 ], [ 29.782937848101266, -1.430027931034483 ], [ 29.782668354430381, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10235, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.430027931034483 ], [ 29.783207341772151, -1.430297413793103 ], [ 29.783476835443039, -1.430297413793103 ], [ 29.783476835443039, -1.430027931034483 ], [ 29.783207341772151, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10236, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.430027931034483 ], [ 29.783476835443039, -1.430297413793103 ], [ 29.784015822784809, -1.430297413793103 ], [ 29.784015822784809, -1.430027931034483 ], [ 29.783476835443039, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10237, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.430027931034483 ], [ 29.784285316455698, -1.430297413793103 ], [ 29.784554810126583, -1.430297413793103 ], [ 29.784554810126583, -1.430027931034483 ], [ 29.784285316455698, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10238, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.430027931034483 ], [ 29.784554810126583, -1.430297413793103 ], [ 29.784824303797468, -1.430297413793103 ], [ 29.784824303797468, -1.430027931034483 ], [ 29.784554810126583, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10239, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.430027931034483 ], [ 29.784824303797468, -1.430297413793103 ], [ 29.785093797468356, -1.430297413793103 ], [ 29.785093797468356, -1.430027931034483 ], [ 29.784824303797468, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10240, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.430027931034483 ], [ 29.791561645569619, -1.430297413793103 ], [ 29.791831139240507, -1.430297413793103 ], [ 29.791831139240507, -1.430027931034483 ], [ 29.791561645569619, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10241, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.430027931034483 ], [ 29.791831139240507, -1.430297413793103 ], [ 29.792100632911392, -1.430297413793103 ], [ 29.792100632911392, -1.430027931034483 ], [ 29.791831139240507, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10242, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.430027931034483 ], [ 29.792100632911392, -1.430297413793103 ], [ 29.792370126582277, -1.430297413793103 ], [ 29.792370126582277, -1.430027931034483 ], [ 29.792100632911392, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10243, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.430027931034483 ], [ 29.792370126582277, -1.430297413793103 ], [ 29.792909113924051, -1.430297413793103 ], [ 29.792909113924051, -1.430027931034483 ], [ 29.792370126582277, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10244, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.430027931034483 ], [ 29.792909113924051, -1.430297413793103 ], [ 29.793178607594935, -1.430297413793103 ], [ 29.793178607594935, -1.430027931034483 ], [ 29.792909113924051, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10245, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.430027931034483 ], [ 29.793178607594935, -1.430297413793103 ], [ 29.793448101265824, -1.430297413793103 ], [ 29.793448101265824, -1.430027931034483 ], [ 29.793178607594935, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10246, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.430027931034483 ], [ 29.793448101265824, -1.430297413793103 ], [ 29.793717594936709, -1.430297413793103 ], [ 29.793717594936709, -1.430027931034483 ], [ 29.793448101265824, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10247, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.430027931034483 ], [ 29.793717594936709, -1.430297413793103 ], [ 29.793987088607594, -1.430297413793103 ], [ 29.793987088607594, -1.430027931034483 ], [ 29.793717594936709, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10248, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.430027931034483 ], [ 29.793987088607594, -1.430297413793103 ], [ 29.794256582278482, -1.430297413793103 ], [ 29.794256582278482, -1.430027931034483 ], [ 29.793987088607594, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10249, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.430027931034483 ], [ 29.794256582278482, -1.430297413793103 ], [ 29.794526075949367, -1.430297413793103 ], [ 29.794526075949367, -1.430027931034483 ], [ 29.794256582278482, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10250, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.430027931034483 ], [ 29.794526075949367, -1.430297413793103 ], [ 29.794795569620252, -1.430297413793103 ], [ 29.794795569620252, -1.430027931034483 ], [ 29.794526075949367, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10251, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.430027931034483 ], [ 29.794795569620252, -1.430297413793103 ], [ 29.79506506329114, -1.430297413793103 ], [ 29.79506506329114, -1.430027931034483 ], [ 29.794795569620252, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10252, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.430027931034483 ], [ 29.800724430379748, -1.430836379310345 ], [ 29.800993924050633, -1.430836379310345 ], [ 29.800993924050633, -1.430027931034483 ], [ 29.800724430379748, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10253, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.430027931034483 ], [ 29.800993924050633, -1.430297413793103 ], [ 29.801263417721518, -1.430297413793103 ], [ 29.801263417721518, -1.430027931034483 ], [ 29.800993924050633, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10254, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.430027931034483 ], [ 29.801263417721518, -1.430297413793103 ], [ 29.802880379746835, -1.430297413793103 ], [ 29.802880379746835, -1.430027931034483 ], [ 29.801263417721518, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10255, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.430027931034483 ], [ 29.802880379746835, -1.430297413793103 ], [ 29.803149873417723, -1.430297413793103 ], [ 29.803149873417723, -1.430027931034483 ], [ 29.802880379746835, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10256, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.430027931034483 ], [ 29.803149873417723, -1.430297413793103 ], [ 29.803419367088608, -1.430297413793103 ], [ 29.803419367088608, -1.430027931034483 ], [ 29.803149873417723, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10257, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.430027931034483 ], [ 29.803419367088608, -1.430566896551724 ], [ 29.803958354430382, -1.430566896551724 ], [ 29.803958354430382, -1.430297413793103 ], [ 29.803688860759493, -1.430297413793103 ], [ 29.803688860759493, -1.430027931034483 ], [ 29.803419367088608, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10258, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.430027931034483 ], [ 29.803688860759493, -1.430297413793103 ], [ 29.803958354430382, -1.430297413793103 ], [ 29.803958354430382, -1.430027931034483 ], [ 29.803688860759493, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10259, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.430297413793103 ], [ 29.755449493670884, -1.430566896551724 ], [ 29.755718987341773, -1.430566896551724 ], [ 29.755718987341773, -1.430297413793103 ], [ 29.755449493670884, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10260, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.430297413793103 ], [ 29.755718987341773, -1.430566896551724 ], [ 29.755988481012658, -1.430566896551724 ], [ 29.755988481012658, -1.430297413793103 ], [ 29.755718987341773, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10261, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.430297413793103 ], [ 29.755988481012658, -1.430566896551724 ], [ 29.756257974683542, -1.430566896551724 ], [ 29.756257974683542, -1.430297413793103 ], [ 29.755988481012658, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10262, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.430297413793103 ], [ 29.756257974683542, -1.430566896551724 ], [ 29.756796962025316, -1.430566896551724 ], [ 29.756796962025316, -1.430297413793103 ], [ 29.756257974683542, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10263, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.430297413793103 ], [ 29.756796962025316, -1.430566896551724 ], [ 29.757066455696201, -1.430566896551724 ], [ 29.757066455696201, -1.430297413793103 ], [ 29.756796962025316, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10264, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.430297413793103 ], [ 29.757066455696201, -1.430566896551724 ], [ 29.757335949367089, -1.430566896551724 ], [ 29.757335949367089, -1.430297413793103 ], [ 29.757066455696201, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10265, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.430297413793103 ], [ 29.757335949367089, -1.430566896551724 ], [ 29.757605443037974, -1.430566896551724 ], [ 29.757605443037974, -1.430297413793103 ], [ 29.757335949367089, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10266, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.430297413793103 ], [ 29.757605443037974, -1.430566896551724 ], [ 29.757874936708859, -1.430566896551724 ], [ 29.757874936708859, -1.430297413793103 ], [ 29.757605443037974, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10267, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.430297413793103 ], [ 29.757874936708859, -1.430566896551724 ], [ 29.758144430379748, -1.430566896551724 ], [ 29.758144430379748, -1.430297413793103 ], [ 29.757874936708859, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10268, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.430297413793103 ], [ 29.758144430379748, -1.430566896551724 ], [ 29.758413924050632, -1.430566896551724 ], [ 29.758413924050632, -1.430297413793103 ], [ 29.758144430379748, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10269, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.430297413793103 ], [ 29.758413924050632, -1.430566896551724 ], [ 29.758683417721517, -1.430566896551724 ], [ 29.758683417721517, -1.430297413793103 ], [ 29.758413924050632, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10270, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.430297413793103 ], [ 29.758683417721517, -1.430566896551724 ], [ 29.758952911392406, -1.430566896551724 ], [ 29.758952911392406, -1.430297413793103 ], [ 29.758683417721517, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10271, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.430297413793103 ], [ 29.758952911392406, -1.430566896551724 ], [ 29.759222405063291, -1.430566896551724 ], [ 29.759222405063291, -1.430297413793103 ], [ 29.758952911392406, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10272, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.430297413793103 ], [ 29.759222405063291, -1.430566896551724 ], [ 29.759491898734176, -1.430566896551724 ], [ 29.759491898734176, -1.430297413793103 ], [ 29.759222405063291, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10273, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.430297413793103 ], [ 29.759491898734176, -1.430566896551724 ], [ 29.759761392405064, -1.430566896551724 ], [ 29.759761392405064, -1.430297413793103 ], [ 29.759491898734176, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10274, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.430297413793103 ], [ 29.759761392405064, -1.430566896551724 ], [ 29.760030886075949, -1.430566896551724 ], [ 29.760030886075949, -1.430297413793103 ], [ 29.759761392405064, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10275, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.430297413793103 ], [ 29.760569873417722, -1.430566896551724 ], [ 29.760839367088607, -1.430566896551724 ], [ 29.760839367088607, -1.430297413793103 ], [ 29.760569873417722, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10276, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.430297413793103 ], [ 29.760839367088607, -1.430566896551724 ], [ 29.761647848101266, -1.430566896551724 ], [ 29.761647848101266, -1.430297413793103 ], [ 29.760839367088607, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10277, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.430297413793103 ], [ 29.761647848101266, -1.430566896551724 ], [ 29.762186835443039, -1.430566896551724 ], [ 29.762186835443039, -1.430297413793103 ], [ 29.761647848101266, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10278, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.430297413793103 ], [ 29.762186835443039, -1.430566896551724 ], [ 29.762456329113924, -1.430566896551724 ], [ 29.762456329113924, -1.430297413793103 ], [ 29.762186835443039, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10279, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.430297413793103 ], [ 29.762456329113924, -1.430566896551724 ], [ 29.762995316455697, -1.430566896551724 ], [ 29.762995316455697, -1.430297413793103 ], [ 29.762456329113924, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10280, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.430297413793103 ], [ 29.762995316455697, -1.430566896551724 ], [ 29.763264810126582, -1.430566896551724 ], [ 29.763264810126582, -1.430297413793103 ], [ 29.762995316455697, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10281, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.430297413793103 ], [ 29.763264810126582, -1.430566896551724 ], [ 29.763534303797467, -1.430566896551724 ], [ 29.763534303797467, -1.430297413793103 ], [ 29.763264810126582, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10282, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.430297413793103 ], [ 29.763534303797467, -1.430566896551724 ], [ 29.763803797468356, -1.430566896551724 ], [ 29.763803797468356, -1.430297413793103 ], [ 29.763534303797467, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10283, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.430297413793103 ], [ 29.763803797468356, -1.430566896551724 ], [ 29.76407329113924, -1.430566896551724 ], [ 29.76407329113924, -1.430297413793103 ], [ 29.763803797468356, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10284, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.430297413793103 ], [ 29.76407329113924, -1.430566896551724 ], [ 29.764342784810125, -1.430566896551724 ], [ 29.764342784810125, -1.430297413793103 ], [ 29.76407329113924, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10285, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.430297413793103 ], [ 29.764342784810125, -1.430566896551724 ], [ 29.764612278481014, -1.430566896551724 ], [ 29.764612278481014, -1.430297413793103 ], [ 29.764342784810125, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10286, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.430297413793103 ], [ 29.764612278481014, -1.430566896551724 ], [ 29.764881772151899, -1.430566896551724 ], [ 29.764881772151899, -1.430297413793103 ], [ 29.764612278481014, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10287, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.430297413793103 ], [ 29.764881772151899, -1.430566896551724 ], [ 29.765151265822784, -1.430566896551724 ], [ 29.765151265822784, -1.430297413793103 ], [ 29.764881772151899, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10288, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.430297413793103 ], [ 29.765151265822784, -1.430566896551724 ], [ 29.765420759493672, -1.430566896551724 ], [ 29.765420759493672, -1.430297413793103 ], [ 29.765151265822784, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10289, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.430297413793103 ], [ 29.765420759493672, -1.430566896551724 ], [ 29.765959746835442, -1.430566896551724 ], [ 29.765959746835442, -1.430297413793103 ], [ 29.765420759493672, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10290, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.430297413793103 ], [ 29.765959746835442, -1.430566896551724 ], [ 29.766229240506327, -1.430566896551724 ], [ 29.766229240506327, -1.430297413793103 ], [ 29.765959746835442, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10291, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.430297413793103 ], [ 29.766229240506327, -1.430566896551724 ], [ 29.766498734177215, -1.430566896551724 ], [ 29.766498734177215, -1.430297413793103 ], [ 29.766229240506327, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10292, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.430297413793103 ], [ 29.766498734177215, -1.430566896551724 ], [ 29.7667682278481, -1.430566896551724 ], [ 29.7667682278481, -1.430297413793103 ], [ 29.766498734177215, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10293, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.430297413793103 ], [ 29.7667682278481, -1.430566896551724 ], [ 29.767037721518985, -1.430566896551724 ], [ 29.767037721518985, -1.430297413793103 ], [ 29.7667682278481, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10294, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.430297413793103 ], [ 29.767037721518985, -1.430566896551724 ], [ 29.767307215189874, -1.430566896551724 ], [ 29.767307215189874, -1.430297413793103 ], [ 29.767037721518985, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10295, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.430297413793103 ], [ 29.767307215189874, -1.430566896551724 ], [ 29.767576708860759, -1.430566896551724 ], [ 29.767576708860759, -1.430297413793103 ], [ 29.767307215189874, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10296, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.430297413793103 ], [ 29.767576708860759, -1.430566896551724 ], [ 29.767846202531643, -1.430566896551724 ], [ 29.767846202531643, -1.430297413793103 ], [ 29.767576708860759, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10297, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.430297413793103 ], [ 29.767846202531643, -1.430566896551724 ], [ 29.768115696202532, -1.430566896551724 ], [ 29.768115696202532, -1.430297413793103 ], [ 29.767846202531643, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10298, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.430297413793103 ], [ 29.768115696202532, -1.430566896551724 ], [ 29.768385189873417, -1.430566896551724 ], [ 29.768385189873417, -1.430297413793103 ], [ 29.768115696202532, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10299, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.430297413793103 ], [ 29.768385189873417, -1.430566896551724 ], [ 29.768654683544302, -1.430566896551724 ], [ 29.768654683544302, -1.430297413793103 ], [ 29.768385189873417, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10300, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.430297413793103 ], [ 29.768654683544302, -1.430566896551724 ], [ 29.76892417721519, -1.430566896551724 ], [ 29.76892417721519, -1.430297413793103 ], [ 29.768654683544302, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10301, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.430297413793103 ], [ 29.76892417721519, -1.430566896551724 ], [ 29.769193670886075, -1.430566896551724 ], [ 29.769193670886075, -1.430297413793103 ], [ 29.76892417721519, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10302, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.430297413793103 ], [ 29.769193670886075, -1.430566896551724 ], [ 29.76946316455696, -1.430566896551724 ], [ 29.76946316455696, -1.430297413793103 ], [ 29.769193670886075, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10303, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.430297413793103 ], [ 29.76946316455696, -1.430566896551724 ], [ 29.769732658227849, -1.430566896551724 ], [ 29.769732658227849, -1.430297413793103 ], [ 29.76946316455696, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10304, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.430297413793103 ], [ 29.769732658227849, -1.430566896551724 ], [ 29.770002151898733, -1.430566896551724 ], [ 29.770002151898733, -1.430297413793103 ], [ 29.769732658227849, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10305, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.430297413793103 ], [ 29.770002151898733, -1.430566896551724 ], [ 29.770271645569618, -1.430566896551724 ], [ 29.770271645569618, -1.430297413793103 ], [ 29.770002151898733, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10306, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.430297413793103 ], [ 29.770271645569618, -1.430566896551724 ], [ 29.770541139240507, -1.430566896551724 ], [ 29.770541139240507, -1.430297413793103 ], [ 29.770271645569618, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10307, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.430297413793103 ], [ 29.770541139240507, -1.430566896551724 ], [ 29.770810632911392, -1.430566896551724 ], [ 29.770810632911392, -1.430297413793103 ], [ 29.770541139240507, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10308, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.430297413793103 ], [ 29.770810632911392, -1.430566896551724 ], [ 29.771080126582277, -1.430566896551724 ], [ 29.771080126582277, -1.430297413793103 ], [ 29.770810632911392, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10309, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.430297413793103 ], [ 29.771080126582277, -1.430566896551724 ], [ 29.771349620253165, -1.430566896551724 ], [ 29.771349620253165, -1.430297413793103 ], [ 29.771080126582277, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10310, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.430297413793103 ], [ 29.771349620253165, -1.430566896551724 ], [ 29.77161911392405, -1.430566896551724 ], [ 29.77161911392405, -1.430297413793103 ], [ 29.771349620253165, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10311, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.430297413793103 ], [ 29.77161911392405, -1.430566896551724 ], [ 29.771888607594935, -1.430566896551724 ], [ 29.771888607594935, -1.430297413793103 ], [ 29.77161911392405, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10312, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.430297413793103 ], [ 29.771888607594935, -1.430566896551724 ], [ 29.772158101265823, -1.430566896551724 ], [ 29.772158101265823, -1.430297413793103 ], [ 29.771888607594935, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10313, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.430297413793103 ], [ 29.772158101265823, -1.430566896551724 ], [ 29.772427594936708, -1.430566896551724 ], [ 29.772427594936708, -1.430297413793103 ], [ 29.772158101265823, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10314, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.430297413793103 ], [ 29.772427594936708, -1.430566896551724 ], [ 29.772697088607593, -1.430566896551724 ], [ 29.772697088607593, -1.430297413793103 ], [ 29.772427594936708, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10315, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.430297413793103 ], [ 29.772697088607593, -1.430566896551724 ], [ 29.772966582278482, -1.430566896551724 ], [ 29.772966582278482, -1.430297413793103 ], [ 29.772697088607593, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10316, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.430297413793103 ], [ 29.772966582278482, -1.430566896551724 ], [ 29.773236075949367, -1.430566896551724 ], [ 29.773236075949367, -1.430297413793103 ], [ 29.772966582278482, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10317, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.430297413793103 ], [ 29.773505569620252, -1.430566896551724 ], [ 29.77377506329114, -1.430566896551724 ], [ 29.77377506329114, -1.430297413793103 ], [ 29.773505569620252, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10318, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.430297413793103 ], [ 29.77377506329114, -1.430566896551724 ], [ 29.774044556962025, -1.430566896551724 ], [ 29.774044556962025, -1.430297413793103 ], [ 29.77377506329114, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10319, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.430297413793103 ], [ 29.774044556962025, -1.430836379310345 ], [ 29.77431405063291, -1.430836379310345 ], [ 29.77431405063291, -1.430297413793103 ], [ 29.774044556962025, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10320, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.430297413793103 ], [ 29.77431405063291, -1.430566896551724 ], [ 29.774853037974683, -1.430566896551724 ], [ 29.774853037974683, -1.430297413793103 ], [ 29.77431405063291, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10321, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.430297413793103 ], [ 29.774853037974683, -1.430566896551724 ], [ 29.775122531645568, -1.430566896551724 ], [ 29.775122531645568, -1.430297413793103 ], [ 29.774853037974683, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10322, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.430297413793103 ], [ 29.775122531645568, -1.430566896551724 ], [ 29.775392025316457, -1.430566896551724 ], [ 29.775392025316457, -1.430297413793103 ], [ 29.775122531645568, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10323, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.430297413793103 ], [ 29.775661518987341, -1.430566896551724 ], [ 29.775931012658226, -1.430566896551724 ], [ 29.775931012658226, -1.430297413793103 ], [ 29.775661518987341, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10324, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.430297413793103 ], [ 29.775931012658226, -1.430566896551724 ], [ 29.776200506329115, -1.430566896551724 ], [ 29.776200506329115, -1.430297413793103 ], [ 29.775931012658226, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10325, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.430297413793103 ], [ 29.776200506329115, -1.430566896551724 ], [ 29.77647, -1.430566896551724 ], [ 29.77647, -1.430297413793103 ], [ 29.776200506329115, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10326, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.430297413793103 ], [ 29.77647, -1.430566896551724 ], [ 29.776739493670885, -1.430566896551724 ], [ 29.776739493670885, -1.430297413793103 ], [ 29.77647, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10327, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.430297413793103 ], [ 29.776739493670885, -1.430566896551724 ], [ 29.777008987341773, -1.430566896551724 ], [ 29.777008987341773, -1.430297413793103 ], [ 29.776739493670885, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10328, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.430297413793103 ], [ 29.777008987341773, -1.430566896551724 ], [ 29.777278481012658, -1.430566896551724 ], [ 29.777278481012658, -1.430297413793103 ], [ 29.777008987341773, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10329, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.430297413793103 ], [ 29.777278481012658, -1.430566896551724 ], [ 29.777547974683543, -1.430566896551724 ], [ 29.777547974683543, -1.430297413793103 ], [ 29.777278481012658, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10330, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.430297413793103 ], [ 29.777547974683543, -1.430566896551724 ], [ 29.777817468354431, -1.430566896551724 ], [ 29.777817468354431, -1.430297413793103 ], [ 29.777547974683543, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10331, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.430297413793103 ], [ 29.777817468354431, -1.430566896551724 ], [ 29.778086962025316, -1.430566896551724 ], [ 29.778086962025316, -1.430297413793103 ], [ 29.777817468354431, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10332, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.430297413793103 ], [ 29.778086962025316, -1.430566896551724 ], [ 29.778356455696201, -1.430566896551724 ], [ 29.778356455696201, -1.430297413793103 ], [ 29.778086962025316, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10333, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.430297413793103 ], [ 29.778356455696201, -1.430566896551724 ], [ 29.77862594936709, -1.430566896551724 ], [ 29.77862594936709, -1.430297413793103 ], [ 29.778356455696201, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10334, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.430297413793103 ], [ 29.77862594936709, -1.430566896551724 ], [ 29.778895443037975, -1.430566896551724 ], [ 29.778895443037975, -1.430297413793103 ], [ 29.77862594936709, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10335, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.430297413793103 ], [ 29.778895443037975, -1.430566896551724 ], [ 29.77916493670886, -1.430566896551724 ], [ 29.77916493670886, -1.430297413793103 ], [ 29.778895443037975, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10336, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.430297413793103 ], [ 29.77916493670886, -1.430566896551724 ], [ 29.779434430379748, -1.430566896551724 ], [ 29.779434430379748, -1.430297413793103 ], [ 29.77916493670886, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10337, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.430297413793103 ], [ 29.779434430379748, -1.430566896551724 ], [ 29.779703924050633, -1.430566896551724 ], [ 29.779703924050633, -1.430297413793103 ], [ 29.779434430379748, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10338, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.430297413793103 ], [ 29.779703924050633, -1.430566896551724 ], [ 29.779973417721518, -1.430566896551724 ], [ 29.779973417721518, -1.430297413793103 ], [ 29.779703924050633, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10339, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.430297413793103 ], [ 29.779973417721518, -1.430566896551724 ], [ 29.780242911392406, -1.430566896551724 ], [ 29.780242911392406, -1.430297413793103 ], [ 29.779973417721518, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10340, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.430297413793103 ], [ 29.780242911392406, -1.430566896551724 ], [ 29.780512405063291, -1.430566896551724 ], [ 29.780512405063291, -1.430297413793103 ], [ 29.780242911392406, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10341, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.430297413793103 ], [ 29.780512405063291, -1.430566896551724 ], [ 29.780781898734176, -1.430566896551724 ], [ 29.780781898734176, -1.430297413793103 ], [ 29.780512405063291, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10342, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.430297413793103 ], [ 29.780781898734176, -1.430566896551724 ], [ 29.781051392405065, -1.430566896551724 ], [ 29.781051392405065, -1.430297413793103 ], [ 29.780781898734176, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10343, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.430297413793103 ], [ 29.781051392405065, -1.430566896551724 ], [ 29.78132088607595, -1.430566896551724 ], [ 29.78132088607595, -1.430297413793103 ], [ 29.781051392405065, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10344, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.430297413793103 ], [ 29.78132088607595, -1.430566896551724 ], [ 29.781590379746834, -1.430566896551724 ], [ 29.781590379746834, -1.430297413793103 ], [ 29.78132088607595, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10345, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.430297413793103 ], [ 29.781590379746834, -1.430836379310345 ], [ 29.781859873417723, -1.430836379310345 ], [ 29.781859873417723, -1.430297413793103 ], [ 29.781590379746834, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10346, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.430297413793103 ], [ 29.782129367088608, -1.430566896551724 ], [ 29.782398860759493, -1.430566896551724 ], [ 29.782398860759493, -1.430297413793103 ], [ 29.782129367088608, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10347, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.430297413793103 ], [ 29.782398860759493, -1.430566896551724 ], [ 29.782668354430381, -1.430566896551724 ], [ 29.782668354430381, -1.430297413793103 ], [ 29.782398860759493, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10348, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.430297413793103 ], [ 29.782937848101266, -1.430566896551724 ], [ 29.783207341772151, -1.430566896551724 ], [ 29.783207341772151, -1.430297413793103 ], [ 29.782937848101266, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10349, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.430297413793103 ], [ 29.783207341772151, -1.430566896551724 ], [ 29.783476835443039, -1.430566896551724 ], [ 29.783476835443039, -1.430297413793103 ], [ 29.783207341772151, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10350, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.430297413793103 ], [ 29.783476835443039, -1.430566896551724 ], [ 29.784015822784809, -1.430566896551724 ], [ 29.784015822784809, -1.430297413793103 ], [ 29.783476835443039, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10351, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.430297413793103 ], [ 29.784015822784809, -1.430566896551724 ], [ 29.784285316455698, -1.430566896551724 ], [ 29.784285316455698, -1.430297413793103 ], [ 29.784015822784809, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10352, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.430297413793103 ], [ 29.791561645569619, -1.430566896551724 ], [ 29.791831139240507, -1.430566896551724 ], [ 29.791831139240507, -1.430297413793103 ], [ 29.791561645569619, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10353, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.430297413793103 ], [ 29.791831139240507, -1.430566896551724 ], [ 29.792100632911392, -1.430566896551724 ], [ 29.792100632911392, -1.430297413793103 ], [ 29.791831139240507, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10354, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.430297413793103 ], [ 29.792100632911392, -1.430566896551724 ], [ 29.792370126582277, -1.430566896551724 ], [ 29.792370126582277, -1.430297413793103 ], [ 29.792100632911392, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10355, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.430297413793103 ], [ 29.792370126582277, -1.430566896551724 ], [ 29.792909113924051, -1.430566896551724 ], [ 29.792909113924051, -1.430297413793103 ], [ 29.792370126582277, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10356, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.430297413793103 ], [ 29.792909113924051, -1.430566896551724 ], [ 29.793178607594935, -1.430566896551724 ], [ 29.793178607594935, -1.430297413793103 ], [ 29.792909113924051, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10357, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.430297413793103 ], [ 29.793178607594935, -1.430566896551724 ], [ 29.793448101265824, -1.430566896551724 ], [ 29.793448101265824, -1.430297413793103 ], [ 29.793178607594935, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10358, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.430297413793103 ], [ 29.793448101265824, -1.430566896551724 ], [ 29.793717594936709, -1.430566896551724 ], [ 29.793717594936709, -1.430297413793103 ], [ 29.793448101265824, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10359, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.430297413793103 ], [ 29.793717594936709, -1.430566896551724 ], [ 29.793987088607594, -1.430566896551724 ], [ 29.793987088607594, -1.430297413793103 ], [ 29.793717594936709, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10360, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.430297413793103 ], [ 29.793987088607594, -1.430566896551724 ], [ 29.794256582278482, -1.430566896551724 ], [ 29.794256582278482, -1.430297413793103 ], [ 29.793987088607594, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10361, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.430297413793103 ], [ 29.794256582278482, -1.430566896551724 ], [ 29.794526075949367, -1.430566896551724 ], [ 29.794526075949367, -1.430297413793103 ], [ 29.794256582278482, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10362, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.430297413793103 ], [ 29.798568481012659, -1.430566896551724 ], [ 29.798837974683543, -1.430566896551724 ], [ 29.798837974683543, -1.430297413793103 ], [ 29.798568481012659, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10363, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.430297413793103 ], [ 29.798837974683543, -1.430566896551724 ], [ 29.799107468354432, -1.430566896551724 ], [ 29.799107468354432, -1.430297413793103 ], [ 29.798837974683543, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10364, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.430297413793103 ], [ 29.799107468354432, -1.430566896551724 ], [ 29.799376962025317, -1.430566896551724 ], [ 29.799376962025317, -1.430297413793103 ], [ 29.799107468354432, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10365, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.430297413793103 ], [ 29.79991594936709, -1.430836379310345 ], [ 29.800185443037975, -1.430836379310345 ], [ 29.800185443037975, -1.430297413793103 ], [ 29.79991594936709, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10366, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.430297413793103 ], [ 29.800993924050633, -1.430566896551724 ], [ 29.801263417721518, -1.430566896551724 ], [ 29.801263417721518, -1.430297413793103 ], [ 29.800993924050633, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10367, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.430297413793103 ], [ 29.801263417721518, -1.430566896551724 ], [ 29.801532911392407, -1.430566896551724 ], [ 29.801532911392407, -1.430297413793103 ], [ 29.801263417721518, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10368, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.430297413793103 ], [ 29.801532911392407, -1.430566896551724 ], [ 29.80261088607595, -1.430566896551724 ], [ 29.80261088607595, -1.430297413793103 ], [ 29.801532911392407, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10369, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.430297413793103 ], [ 29.80261088607595, -1.430566896551724 ], [ 29.802880379746835, -1.430566896551724 ], [ 29.802880379746835, -1.430836379310345 ], [ 29.803149873417723, -1.430836379310345 ], [ 29.803149873417723, -1.430297413793103 ], [ 29.80261088607595, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10370, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.430297413793103 ], [ 29.803149873417723, -1.430566896551724 ], [ 29.803419367088608, -1.430566896551724 ], [ 29.803419367088608, -1.430297413793103 ], [ 29.803149873417723, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10371, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.430297413793103 ], [ 29.803958354430382, -1.430566896551724 ], [ 29.804227848101267, -1.430566896551724 ], [ 29.804227848101267, -1.430297413793103 ], [ 29.803958354430382, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10372, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.430297413793103 ], [ 29.804227848101267, -1.430566896551724 ], [ 29.804497341772151, -1.430566896551724 ], [ 29.804497341772151, -1.430297413793103 ], [ 29.804227848101267, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10373, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.430566896551724 ], [ 29.756257974683542, -1.430836379310345 ], [ 29.756796962025316, -1.430836379310345 ], [ 29.756796962025316, -1.430566896551724 ], [ 29.756257974683542, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10374, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.430566896551724 ], [ 29.756796962025316, -1.430836379310345 ], [ 29.757066455696201, -1.430836379310345 ], [ 29.757066455696201, -1.430566896551724 ], [ 29.756796962025316, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10375, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.430566896551724 ], [ 29.757066455696201, -1.430836379310345 ], [ 29.757335949367089, -1.430836379310345 ], [ 29.757335949367089, -1.430566896551724 ], [ 29.757066455696201, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10376, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.430566896551724 ], [ 29.757335949367089, -1.430836379310345 ], [ 29.757605443037974, -1.430836379310345 ], [ 29.757605443037974, -1.430566896551724 ], [ 29.757335949367089, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10377, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.430566896551724 ], [ 29.757605443037974, -1.430836379310345 ], [ 29.757874936708859, -1.430836379310345 ], [ 29.757874936708859, -1.430566896551724 ], [ 29.757605443037974, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10378, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.430566896551724 ], [ 29.757874936708859, -1.430836379310345 ], [ 29.758144430379748, -1.430836379310345 ], [ 29.758144430379748, -1.430566896551724 ], [ 29.757874936708859, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10379, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.430566896551724 ], [ 29.758144430379748, -1.430836379310345 ], [ 29.758413924050632, -1.430836379310345 ], [ 29.758413924050632, -1.430566896551724 ], [ 29.758144430379748, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10380, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.430566896551724 ], [ 29.758413924050632, -1.430836379310345 ], [ 29.758683417721517, -1.430836379310345 ], [ 29.758683417721517, -1.430566896551724 ], [ 29.758413924050632, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10381, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.430566896551724 ], [ 29.758683417721517, -1.430836379310345 ], [ 29.758952911392406, -1.430836379310345 ], [ 29.758952911392406, -1.430566896551724 ], [ 29.758683417721517, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10382, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.430566896551724 ], [ 29.758952911392406, -1.430836379310345 ], [ 29.759222405063291, -1.430836379310345 ], [ 29.759222405063291, -1.430566896551724 ], [ 29.758952911392406, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10383, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.430566896551724 ], [ 29.759222405063291, -1.430836379310345 ], [ 29.759491898734176, -1.430836379310345 ], [ 29.759491898734176, -1.430566896551724 ], [ 29.759222405063291, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10384, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.430566896551724 ], [ 29.759491898734176, -1.430836379310345 ], [ 29.759761392405064, -1.430836379310345 ], [ 29.759761392405064, -1.430566896551724 ], [ 29.759491898734176, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10385, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.430566896551724 ], [ 29.759761392405064, -1.430836379310345 ], [ 29.760300379746834, -1.430836379310345 ], [ 29.760300379746834, -1.430566896551724 ], [ 29.759761392405064, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10386, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.430566896551724 ], [ 29.760300379746834, -1.430836379310345 ], [ 29.760569873417722, -1.430836379310345 ], [ 29.760569873417722, -1.430566896551724 ], [ 29.760300379746834, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10387, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.430566896551724 ], [ 29.760569873417722, -1.430836379310345 ], [ 29.760839367088607, -1.430836379310345 ], [ 29.760839367088607, -1.430566896551724 ], [ 29.760569873417722, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10388, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.430566896551724 ], [ 29.760839367088607, -1.430836379310345 ], [ 29.761917341772151, -1.430836379310345 ], [ 29.761917341772151, -1.430566896551724 ], [ 29.760839367088607, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10389, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.430566896551724 ], [ 29.761917341772151, -1.430836379310345 ], [ 29.762456329113924, -1.430836379310345 ], [ 29.762456329113924, -1.430566896551724 ], [ 29.761917341772151, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10390, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.430566896551724 ], [ 29.762456329113924, -1.430836379310345 ], [ 29.762725822784809, -1.430836379310345 ], [ 29.762725822784809, -1.430566896551724 ], [ 29.762456329113924, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10391, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.430566896551724 ], [ 29.762725822784809, -1.430836379310345 ], [ 29.762995316455697, -1.430836379310345 ], [ 29.762995316455697, -1.430566896551724 ], [ 29.762725822784809, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10392, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.430566896551724 ], [ 29.762995316455697, -1.430836379310345 ], [ 29.763264810126582, -1.430836379310345 ], [ 29.763264810126582, -1.430566896551724 ], [ 29.762995316455697, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10393, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.430566896551724 ], [ 29.763264810126582, -1.430836379310345 ], [ 29.763534303797467, -1.430836379310345 ], [ 29.763534303797467, -1.430566896551724 ], [ 29.763264810126582, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10394, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.430566896551724 ], [ 29.763534303797467, -1.430836379310345 ], [ 29.763803797468356, -1.430836379310345 ], [ 29.763803797468356, -1.430566896551724 ], [ 29.763534303797467, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10395, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.430566896551724 ], [ 29.763803797468356, -1.430836379310345 ], [ 29.76407329113924, -1.430836379310345 ], [ 29.76407329113924, -1.430566896551724 ], [ 29.763803797468356, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10396, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.430566896551724 ], [ 29.76407329113924, -1.430836379310345 ], [ 29.764342784810125, -1.430836379310345 ], [ 29.764342784810125, -1.430566896551724 ], [ 29.76407329113924, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10397, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.430566896551724 ], [ 29.764342784810125, -1.430836379310345 ], [ 29.764612278481014, -1.430836379310345 ], [ 29.764612278481014, -1.430566896551724 ], [ 29.764342784810125, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10398, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.430566896551724 ], [ 29.764612278481014, -1.430836379310345 ], [ 29.764881772151899, -1.430836379310345 ], [ 29.764881772151899, -1.430566896551724 ], [ 29.764612278481014, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10399, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.430566896551724 ], [ 29.764881772151899, -1.430836379310345 ], [ 29.765151265822784, -1.430836379310345 ], [ 29.765151265822784, -1.430566896551724 ], [ 29.764881772151899, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10400, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.430566896551724 ], [ 29.765151265822784, -1.430836379310345 ], [ 29.765420759493672, -1.430836379310345 ], [ 29.765420759493672, -1.430566896551724 ], [ 29.765151265822784, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10401, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.430566896551724 ], [ 29.765420759493672, -1.430836379310345 ], [ 29.765959746835442, -1.430836379310345 ], [ 29.765959746835442, -1.430566896551724 ], [ 29.765420759493672, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10402, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.430566896551724 ], [ 29.765959746835442, -1.430836379310345 ], [ 29.766229240506327, -1.430836379310345 ], [ 29.766229240506327, -1.430566896551724 ], [ 29.765959746835442, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10403, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.430566896551724 ], [ 29.766229240506327, -1.430836379310345 ], [ 29.766498734177215, -1.430836379310345 ], [ 29.766498734177215, -1.430566896551724 ], [ 29.766229240506327, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10404, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.430566896551724 ], [ 29.766498734177215, -1.430836379310345 ], [ 29.7667682278481, -1.430836379310345 ], [ 29.7667682278481, -1.430566896551724 ], [ 29.766498734177215, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10405, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.430566896551724 ], [ 29.7667682278481, -1.430836379310345 ], [ 29.767037721518985, -1.430836379310345 ], [ 29.767037721518985, -1.430566896551724 ], [ 29.7667682278481, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10406, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.430566896551724 ], [ 29.767037721518985, -1.430836379310345 ], [ 29.767307215189874, -1.430836379310345 ], [ 29.767307215189874, -1.430566896551724 ], [ 29.767037721518985, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10407, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.430566896551724 ], [ 29.767307215189874, -1.430836379310345 ], [ 29.767576708860759, -1.430836379310345 ], [ 29.767576708860759, -1.430566896551724 ], [ 29.767307215189874, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10408, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.430566896551724 ], [ 29.767576708860759, -1.430836379310345 ], [ 29.767846202531643, -1.430836379310345 ], [ 29.767846202531643, -1.430566896551724 ], [ 29.767576708860759, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10409, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.430566896551724 ], [ 29.767846202531643, -1.430836379310345 ], [ 29.768115696202532, -1.430836379310345 ], [ 29.768115696202532, -1.430566896551724 ], [ 29.767846202531643, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10410, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.430566896551724 ], [ 29.768115696202532, -1.430836379310345 ], [ 29.768385189873417, -1.430836379310345 ], [ 29.768385189873417, -1.430566896551724 ], [ 29.768115696202532, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10411, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.430566896551724 ], [ 29.768385189873417, -1.430836379310345 ], [ 29.768654683544302, -1.430836379310345 ], [ 29.768654683544302, -1.430566896551724 ], [ 29.768385189873417, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10412, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.430566896551724 ], [ 29.768654683544302, -1.430836379310345 ], [ 29.76892417721519, -1.430836379310345 ], [ 29.76892417721519, -1.430566896551724 ], [ 29.768654683544302, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10413, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.430566896551724 ], [ 29.76892417721519, -1.430836379310345 ], [ 29.769193670886075, -1.430836379310345 ], [ 29.769193670886075, -1.430566896551724 ], [ 29.76892417721519, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10414, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.430566896551724 ], [ 29.769193670886075, -1.430836379310345 ], [ 29.76946316455696, -1.430836379310345 ], [ 29.76946316455696, -1.430566896551724 ], [ 29.769193670886075, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10415, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.430566896551724 ], [ 29.76946316455696, -1.430836379310345 ], [ 29.769732658227849, -1.430836379310345 ], [ 29.769732658227849, -1.430566896551724 ], [ 29.76946316455696, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10416, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.430566896551724 ], [ 29.769732658227849, -1.430836379310345 ], [ 29.770002151898733, -1.430836379310345 ], [ 29.770002151898733, -1.430566896551724 ], [ 29.769732658227849, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10417, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.430566896551724 ], [ 29.770002151898733, -1.430836379310345 ], [ 29.770271645569618, -1.430836379310345 ], [ 29.770271645569618, -1.430566896551724 ], [ 29.770002151898733, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10418, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.430566896551724 ], [ 29.770271645569618, -1.430836379310345 ], [ 29.770541139240507, -1.430836379310345 ], [ 29.770541139240507, -1.430566896551724 ], [ 29.770271645569618, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10419, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.430566896551724 ], [ 29.770541139240507, -1.430836379310345 ], [ 29.770810632911392, -1.430836379310345 ], [ 29.770810632911392, -1.430566896551724 ], [ 29.770541139240507, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10420, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.430566896551724 ], [ 29.770810632911392, -1.430836379310345 ], [ 29.771080126582277, -1.430836379310345 ], [ 29.771080126582277, -1.430566896551724 ], [ 29.770810632911392, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10421, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.430566896551724 ], [ 29.771080126582277, -1.430836379310345 ], [ 29.771349620253165, -1.430836379310345 ], [ 29.771349620253165, -1.430566896551724 ], [ 29.771080126582277, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10422, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.430566896551724 ], [ 29.771349620253165, -1.430836379310345 ], [ 29.77161911392405, -1.430836379310345 ], [ 29.77161911392405, -1.430566896551724 ], [ 29.771349620253165, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10423, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.430566896551724 ], [ 29.77161911392405, -1.430836379310345 ], [ 29.771888607594935, -1.430836379310345 ], [ 29.771888607594935, -1.430566896551724 ], [ 29.77161911392405, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10424, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.430566896551724 ], [ 29.771888607594935, -1.430836379310345 ], [ 29.772158101265823, -1.430836379310345 ], [ 29.772158101265823, -1.430566896551724 ], [ 29.771888607594935, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10425, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.430566896551724 ], [ 29.772158101265823, -1.430836379310345 ], [ 29.772427594936708, -1.430836379310345 ], [ 29.772427594936708, -1.430566896551724 ], [ 29.772158101265823, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10426, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.430566896551724 ], [ 29.772427594936708, -1.430836379310345 ], [ 29.772966582278482, -1.430836379310345 ], [ 29.772966582278482, -1.430566896551724 ], [ 29.772427594936708, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10427, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.430566896551724 ], [ 29.773236075949367, -1.430836379310345 ], [ 29.773505569620252, -1.430836379310345 ], [ 29.773505569620252, -1.430566896551724 ], [ 29.773236075949367, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10428, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.430566896551724 ], [ 29.773505569620252, -1.430836379310345 ], [ 29.77377506329114, -1.430836379310345 ], [ 29.77377506329114, -1.430566896551724 ], [ 29.773505569620252, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10429, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.430566896551724 ], [ 29.77377506329114, -1.430836379310345 ], [ 29.774044556962025, -1.430836379310345 ], [ 29.774044556962025, -1.430566896551724 ], [ 29.77377506329114, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10430, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.430566896551724 ], [ 29.77431405063291, -1.430836379310345 ], [ 29.774853037974683, -1.430836379310345 ], [ 29.774853037974683, -1.430566896551724 ], [ 29.77431405063291, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10431, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.430566896551724 ], [ 29.774853037974683, -1.430836379310345 ], [ 29.775122531645568, -1.430836379310345 ], [ 29.775122531645568, -1.430566896551724 ], [ 29.774853037974683, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10432, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.430566896551724 ], [ 29.775122531645568, -1.430836379310345 ], [ 29.775392025316457, -1.430836379310345 ], [ 29.775392025316457, -1.430566896551724 ], [ 29.775122531645568, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10433, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.430566896551724 ], [ 29.775392025316457, -1.430836379310345 ], [ 29.775661518987341, -1.430836379310345 ], [ 29.775661518987341, -1.430566896551724 ], [ 29.775392025316457, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10434, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.430566896551724 ], [ 29.775661518987341, -1.430836379310345 ], [ 29.775931012658226, -1.430836379310345 ], [ 29.775931012658226, -1.430566896551724 ], [ 29.775661518987341, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10435, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.430566896551724 ], [ 29.775931012658226, -1.430836379310345 ], [ 29.776200506329115, -1.430836379310345 ], [ 29.776200506329115, -1.430566896551724 ], [ 29.775931012658226, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10436, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.430566896551724 ], [ 29.776200506329115, -1.430836379310345 ], [ 29.77647, -1.430836379310345 ], [ 29.77647, -1.430566896551724 ], [ 29.776200506329115, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10437, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.430566896551724 ], [ 29.77647, -1.430836379310345 ], [ 29.776739493670885, -1.430836379310345 ], [ 29.776739493670885, -1.430566896551724 ], [ 29.77647, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10438, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.430566896551724 ], [ 29.776739493670885, -1.430836379310345 ], [ 29.777008987341773, -1.430836379310345 ], [ 29.777008987341773, -1.430566896551724 ], [ 29.776739493670885, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10439, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.430566896551724 ], [ 29.777008987341773, -1.430836379310345 ], [ 29.777278481012658, -1.430836379310345 ], [ 29.777278481012658, -1.430566896551724 ], [ 29.777008987341773, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10440, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.430566896551724 ], [ 29.777278481012658, -1.430836379310345 ], [ 29.777547974683543, -1.430836379310345 ], [ 29.777547974683543, -1.430566896551724 ], [ 29.777278481012658, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10441, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.430566896551724 ], [ 29.777547974683543, -1.430836379310345 ], [ 29.777817468354431, -1.430836379310345 ], [ 29.777817468354431, -1.430566896551724 ], [ 29.777547974683543, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10442, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.430566896551724 ], [ 29.777817468354431, -1.430836379310345 ], [ 29.778086962025316, -1.430836379310345 ], [ 29.778086962025316, -1.430566896551724 ], [ 29.777817468354431, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10443, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.430566896551724 ], [ 29.778086962025316, -1.430836379310345 ], [ 29.778356455696201, -1.430836379310345 ], [ 29.778356455696201, -1.430566896551724 ], [ 29.778086962025316, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10444, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.430566896551724 ], [ 29.778356455696201, -1.430836379310345 ], [ 29.77862594936709, -1.430836379310345 ], [ 29.77862594936709, -1.430566896551724 ], [ 29.778356455696201, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10445, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.430566896551724 ], [ 29.77862594936709, -1.430836379310345 ], [ 29.778895443037975, -1.430836379310345 ], [ 29.778895443037975, -1.430566896551724 ], [ 29.77862594936709, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10446, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.430566896551724 ], [ 29.778895443037975, -1.430836379310345 ], [ 29.77916493670886, -1.430836379310345 ], [ 29.77916493670886, -1.430566896551724 ], [ 29.778895443037975, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10447, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.430566896551724 ], [ 29.77916493670886, -1.430836379310345 ], [ 29.779434430379748, -1.430836379310345 ], [ 29.779434430379748, -1.430566896551724 ], [ 29.77916493670886, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10448, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.430566896551724 ], [ 29.779434430379748, -1.430836379310345 ], [ 29.779703924050633, -1.430836379310345 ], [ 29.779703924050633, -1.430566896551724 ], [ 29.779434430379748, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10449, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.430566896551724 ], [ 29.779703924050633, -1.430836379310345 ], [ 29.779973417721518, -1.430836379310345 ], [ 29.779973417721518, -1.430566896551724 ], [ 29.779703924050633, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10450, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.430566896551724 ], [ 29.779973417721518, -1.430836379310345 ], [ 29.780242911392406, -1.430836379310345 ], [ 29.780242911392406, -1.430566896551724 ], [ 29.779973417721518, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10451, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.430566896551724 ], [ 29.780242911392406, -1.430836379310345 ], [ 29.780512405063291, -1.430836379310345 ], [ 29.780512405063291, -1.430566896551724 ], [ 29.780242911392406, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10452, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.430566896551724 ], [ 29.780512405063291, -1.430836379310345 ], [ 29.780781898734176, -1.430836379310345 ], [ 29.780781898734176, -1.430566896551724 ], [ 29.780512405063291, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10453, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.430566896551724 ], [ 29.780781898734176, -1.430836379310345 ], [ 29.781051392405065, -1.430836379310345 ], [ 29.781051392405065, -1.430566896551724 ], [ 29.780781898734176, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10454, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.430566896551724 ], [ 29.781051392405065, -1.430836379310345 ], [ 29.78132088607595, -1.430836379310345 ], [ 29.78132088607595, -1.430566896551724 ], [ 29.781051392405065, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10455, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.430566896551724 ], [ 29.78132088607595, -1.430836379310345 ], [ 29.781590379746834, -1.430836379310345 ], [ 29.781590379746834, -1.430566896551724 ], [ 29.78132088607595, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10456, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.430566896551724 ], [ 29.781859873417723, -1.430836379310345 ], [ 29.782129367088608, -1.430836379310345 ], [ 29.782129367088608, -1.430566896551724 ], [ 29.781859873417723, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10457, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.430566896551724 ], [ 29.782129367088608, -1.430836379310345 ], [ 29.782398860759493, -1.430836379310345 ], [ 29.782398860759493, -1.430566896551724 ], [ 29.782129367088608, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10458, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.430566896551724 ], [ 29.782398860759493, -1.430836379310345 ], [ 29.782668354430381, -1.430836379310345 ], [ 29.782668354430381, -1.430566896551724 ], [ 29.782398860759493, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10459, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.430566896551724 ], [ 29.782937848101266, -1.430836379310345 ], [ 29.783207341772151, -1.430836379310345 ], [ 29.783207341772151, -1.430566896551724 ], [ 29.782937848101266, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10460, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.430566896551724 ], [ 29.783207341772151, -1.430836379310345 ], [ 29.783476835443039, -1.430836379310345 ], [ 29.783476835443039, -1.430566896551724 ], [ 29.783207341772151, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10461, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.430566896551724 ], [ 29.791831139240507, -1.430836379310345 ], [ 29.792100632911392, -1.430836379310345 ], [ 29.792100632911392, -1.430566896551724 ], [ 29.791831139240507, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10462, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.430566896551724 ], [ 29.792100632911392, -1.430836379310345 ], [ 29.792370126582277, -1.430836379310345 ], [ 29.792370126582277, -1.430566896551724 ], [ 29.792100632911392, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10463, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.430566896551724 ], [ 29.792370126582277, -1.430836379310345 ], [ 29.792909113924051, -1.430836379310345 ], [ 29.792909113924051, -1.430566896551724 ], [ 29.792370126582277, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10464, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.430566896551724 ], [ 29.792909113924051, -1.430836379310345 ], [ 29.793178607594935, -1.430836379310345 ], [ 29.793178607594935, -1.430566896551724 ], [ 29.792909113924051, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10465, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.430566896551724 ], [ 29.793178607594935, -1.430836379310345 ], [ 29.793448101265824, -1.430836379310345 ], [ 29.793448101265824, -1.430566896551724 ], [ 29.793178607594935, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10466, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.430566896551724 ], [ 29.793448101265824, -1.430836379310345 ], [ 29.793717594936709, -1.430836379310345 ], [ 29.793717594936709, -1.430566896551724 ], [ 29.793448101265824, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10467, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.430566896551724 ], [ 29.799107468354432, -1.430836379310345 ], [ 29.799376962025317, -1.430836379310345 ], [ 29.799376962025317, -1.430566896551724 ], [ 29.799107468354432, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10468, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.430566896551724 ], [ 29.799376962025317, -1.430836379310345 ], [ 29.799646455696202, -1.430836379310345 ], [ 29.799646455696202, -1.430566896551724 ], [ 29.799376962025317, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10469, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.430566896551724 ], [ 29.799646455696202, -1.430836379310345 ], [ 29.79991594936709, -1.430836379310345 ], [ 29.79991594936709, -1.430566896551724 ], [ 29.799646455696202, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10470, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.430566896551724 ], [ 29.800185443037975, -1.430836379310345 ], [ 29.80045493670886, -1.430836379310345 ], [ 29.80045493670886, -1.430566896551724 ], [ 29.800185443037975, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10471, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.430566896551724 ], [ 29.80045493670886, -1.430836379310345 ], [ 29.800724430379748, -1.430836379310345 ], [ 29.800724430379748, -1.430566896551724 ], [ 29.80045493670886, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10472, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.430566896551724 ], [ 29.801263417721518, -1.430836379310345 ], [ 29.802071898734177, -1.430836379310345 ], [ 29.802071898734177, -1.430566896551724 ], [ 29.801263417721518, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10473, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.430566896551724 ], [ 29.802341392405065, -1.430836379310345 ], [ 29.80261088607595, -1.430836379310345 ], [ 29.80261088607595, -1.430566896551724 ], [ 29.802341392405065, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10474, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.430566896551724 ], [ 29.80261088607595, -1.430836379310345 ], [ 29.802880379746835, -1.430836379310345 ], [ 29.802880379746835, -1.430566896551724 ], [ 29.80261088607595, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10475, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.430566896551724 ], [ 29.803149873417723, -1.430836379310345 ], [ 29.803419367088608, -1.430836379310345 ], [ 29.803419367088608, -1.430566896551724 ], [ 29.803149873417723, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10476, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.430566896551724 ], [ 29.803419367088608, -1.430836379310345 ], [ 29.803688860759493, -1.430836379310345 ], [ 29.803688860759493, -1.430566896551724 ], [ 29.803419367088608, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10477, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.430566896551724 ], [ 29.803688860759493, -1.430836379310345 ], [ 29.804227848101267, -1.430836379310345 ], [ 29.804227848101267, -1.430566896551724 ], [ 29.803688860759493, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10478, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.430566896551724 ], [ 29.804227848101267, -1.430836379310345 ], [ 29.804497341772151, -1.430836379310345 ], [ 29.804497341772151, -1.430566896551724 ], [ 29.804227848101267, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10479, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.430566896551724 ], [ 29.804497341772151, -1.430836379310345 ], [ 29.80476683544304, -1.430836379310345 ], [ 29.80476683544304, -1.430566896551724 ], [ 29.804497341772151, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10480, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.430566896551724 ], [ 29.80476683544304, -1.430836379310345 ], [ 29.805036329113925, -1.430836379310345 ], [ 29.805036329113925, -1.430566896551724 ], [ 29.80476683544304, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10481, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.427872068965517 ], [ 29.790753164556961, -1.431914310344828 ], [ 29.791022658227849, -1.431914310344828 ], [ 29.791022658227849, -1.427872068965517 ], [ 29.790753164556961, -1.427872068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10482, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.428141551724138 ], [ 29.789405696202532, -1.431644827586207 ], [ 29.789675189873417, -1.431644827586207 ], [ 29.789675189873417, -1.428141551724138 ], [ 29.789405696202532, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10483, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.428141551724138 ], [ 29.789675189873417, -1.431644827586207 ], [ 29.789944683544302, -1.431644827586207 ], [ 29.789944683544302, -1.428141551724138 ], [ 29.789675189873417, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10484, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.428141551724138 ], [ 29.790214177215191, -1.431644827586207 ], [ 29.790483670886076, -1.431644827586207 ], [ 29.790483670886076, -1.428141551724138 ], [ 29.790214177215191, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10485, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.428141551724138 ], [ 29.790483670886076, -1.431644827586207 ], [ 29.790753164556961, -1.431644827586207 ], [ 29.790753164556961, -1.428141551724138 ], [ 29.790483670886076, -1.428141551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10486, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.427333103448276 ], [ 29.791561645569619, -1.427333103448276 ], [ 29.791561645569619, -1.428680517241379 ], [ 29.791292151898734, -1.428680517241379 ], [ 29.791292151898734, -1.431644827586207 ], [ 29.791022658227849, -1.431644827586207 ], [ 29.791022658227849, -1.428141551724138 ], [ 29.791292151898734, -1.428141551724138 ], [ 29.791292151898734, -1.427333103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10487, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.428411034482759 ], [ 29.788597215189874, -1.431375344827586 ], [ 29.788866708860759, -1.431375344827586 ], [ 29.788866708860759, -1.428411034482759 ], [ 29.788597215189874, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10488, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.428411034482759 ], [ 29.788866708860759, -1.431375344827586 ], [ 29.789136202531644, -1.431375344827586 ], [ 29.789136202531644, -1.428411034482759 ], [ 29.788866708860759, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10489, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.428411034482759 ], [ 29.789136202531644, -1.431375344827586 ], [ 29.789405696202532, -1.431375344827586 ], [ 29.789405696202532, -1.428411034482759 ], [ 29.789136202531644, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10490, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.428411034482759 ], [ 29.789944683544302, -1.431375344827586 ], [ 29.790214177215191, -1.431375344827586 ], [ 29.790214177215191, -1.428411034482759 ], [ 29.789944683544302, -1.428411034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10491, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.429219482758621 ], [ 29.791292151898734, -1.431375344827586 ], [ 29.791561645569619, -1.431375344827586 ], [ 29.791561645569619, -1.430027931034483 ], [ 29.791831139240507, -1.430027931034483 ], [ 29.791831139240507, -1.429758448275862 ], [ 29.791561645569619, -1.429758448275862 ], [ 29.791561645569619, -1.429219482758621 ], [ 29.791292151898734, -1.429219482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10492, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.429488965517241 ], [ 29.805844810126583, -1.431105862068966 ], [ 29.806114303797468, -1.431105862068966 ], [ 29.806114303797468, -1.429488965517241 ], [ 29.805844810126583, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10493, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.429488965517241 ], [ 29.806114303797468, -1.431105862068966 ], [ 29.806383797468357, -1.431105862068966 ], [ 29.806383797468357, -1.429488965517241 ], [ 29.806114303797468, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10494, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.429488965517241 ], [ 29.806383797468357, -1.431375344827586 ], [ 29.806653291139241, -1.431375344827586 ], [ 29.806653291139241, -1.429488965517241 ], [ 29.806383797468357, -1.429488965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10495, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.429758448275862 ], [ 29.785632784810126, -1.431105862068966 ], [ 29.785902278481014, -1.431105862068966 ], [ 29.785902278481014, -1.429758448275862 ], [ 29.785632784810126, -1.429758448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10496, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.430027931034483 ], [ 29.754641012658226, -1.431914310344828 ], [ 29.754910506329114, -1.431914310344828 ], [ 29.754910506329114, -1.430027931034483 ], [ 29.754641012658226, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10497, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.430027931034483 ], [ 29.785093797468356, -1.431105862068966 ], [ 29.785363291139241, -1.431105862068966 ], [ 29.785363291139241, -1.430027931034483 ], [ 29.785093797468356, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10498, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.430027931034483 ], [ 29.785363291139241, -1.431105862068966 ], [ 29.785632784810126, -1.431105862068966 ], [ 29.785632784810126, -1.430027931034483 ], [ 29.785363291139241, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10499, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.430027931034483 ], [ 29.79506506329114, -1.431375344827586 ], [ 29.795334556962025, -1.431375344827586 ], [ 29.795334556962025, -1.430027931034483 ], [ 29.79506506329114, -1.430027931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10500, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.430297413793103 ], [ 29.754910506329114, -1.431914310344828 ], [ 29.75518, -1.431914310344828 ], [ 29.75518, -1.430297413793103 ], [ 29.754910506329114, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10501, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.430297413793103 ], [ 29.75518, -1.431914310344828 ], [ 29.755449493670884, -1.431914310344828 ], [ 29.755449493670884, -1.430297413793103 ], [ 29.75518, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10502, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.430297413793103 ], [ 29.784285316455698, -1.431105862068966 ], [ 29.784554810126583, -1.431105862068966 ], [ 29.784554810126583, -1.430297413793103 ], [ 29.784285316455698, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10503, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.430297413793103 ], [ 29.784554810126583, -1.431375344827586 ], [ 29.784824303797468, -1.431375344827586 ], [ 29.784824303797468, -1.430297413793103 ], [ 29.784554810126583, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10504, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.430297413793103 ], [ 29.784824303797468, -1.431375344827586 ], [ 29.785093797468356, -1.431375344827586 ], [ 29.785093797468356, -1.430297413793103 ], [ 29.784824303797468, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10505, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.430297413793103 ], [ 29.794526075949367, -1.431375344827586 ], [ 29.794795569620252, -1.431375344827586 ], [ 29.794795569620252, -1.430297413793103 ], [ 29.794526075949367, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10506, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.430297413793103 ], [ 29.794795569620252, -1.431375344827586 ], [ 29.79506506329114, -1.431375344827586 ], [ 29.79506506329114, -1.430297413793103 ], [ 29.794795569620252, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10507, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.430297413793103 ], [ 29.798298987341774, -1.431105862068966 ], [ 29.798568481012659, -1.431105862068966 ], [ 29.798568481012659, -1.430297413793103 ], [ 29.798298987341774, -1.430297413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10508, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.430566896551724 ], [ 29.755449493670884, -1.432183793103448 ], [ 29.755718987341773, -1.432183793103448 ], [ 29.755718987341773, -1.430566896551724 ], [ 29.755449493670884, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10509, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.430566896551724 ], [ 29.755718987341773, -1.432183793103448 ], [ 29.755988481012658, -1.432183793103448 ], [ 29.755988481012658, -1.430566896551724 ], [ 29.755718987341773, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10510, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.430566896551724 ], [ 29.755988481012658, -1.431914310344828 ], [ 29.756257974683542, -1.431914310344828 ], [ 29.756257974683542, -1.430566896551724 ], [ 29.755988481012658, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10511, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.430566896551724 ], [ 29.772966582278482, -1.431105862068966 ], [ 29.773236075949367, -1.431105862068966 ], [ 29.773236075949367, -1.430566896551724 ], [ 29.772966582278482, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10512, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.430566896551724 ], [ 29.782668354430381, -1.431105862068966 ], [ 29.782937848101266, -1.431105862068966 ], [ 29.782937848101266, -1.430566896551724 ], [ 29.782668354430381, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10513, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.430566896551724 ], [ 29.783476835443039, -1.431375344827586 ], [ 29.784015822784809, -1.431375344827586 ], [ 29.784015822784809, -1.430566896551724 ], [ 29.783476835443039, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10514, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.430566896551724 ], [ 29.784015822784809, -1.431375344827586 ], [ 29.784285316455698, -1.431375344827586 ], [ 29.784285316455698, -1.430566896551724 ], [ 29.784015822784809, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10515, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.430566896551724 ], [ 29.791561645569619, -1.431914310344828 ], [ 29.792100632911392, -1.431914310344828 ], [ 29.792100632911392, -1.431105862068966 ], [ 29.791831139240507, -1.431105862068966 ], [ 29.791831139240507, -1.430566896551724 ], [ 29.791561645569619, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10516, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.430566896551724 ], [ 29.793717594936709, -1.431375344827586 ], [ 29.793987088607594, -1.431375344827586 ], [ 29.793987088607594, -1.430566896551724 ], [ 29.793717594936709, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10517, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.430566896551724 ], [ 29.793987088607594, -1.431644827586207 ], [ 29.794256582278482, -1.431644827586207 ], [ 29.794256582278482, -1.430566896551724 ], [ 29.793987088607594, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10518, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.430566896551724 ], [ 29.794256582278482, -1.431644827586207 ], [ 29.794526075949367, -1.431644827586207 ], [ 29.794526075949367, -1.430566896551724 ], [ 29.794256582278482, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10519, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.430566896551724 ], [ 29.798568481012659, -1.431105862068966 ], [ 29.798837974683543, -1.431105862068966 ], [ 29.798837974683543, -1.430566896551724 ], [ 29.798568481012659, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10520, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.430566896551724 ], [ 29.798837974683543, -1.431105862068966 ], [ 29.799107468354432, -1.431105862068966 ], [ 29.799107468354432, -1.430566896551724 ], [ 29.798837974683543, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10521, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.430566896551724 ], [ 29.800993924050633, -1.431105862068966 ], [ 29.801263417721518, -1.431105862068966 ], [ 29.801263417721518, -1.430566896551724 ], [ 29.800993924050633, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10522, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.430566896551724 ], [ 29.802071898734177, -1.431105862068966 ], [ 29.802341392405065, -1.431105862068966 ], [ 29.802341392405065, -1.430566896551724 ], [ 29.802071898734177, -1.430566896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10523, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.430836379310345 ], [ 29.756257974683542, -1.432183793103448 ], [ 29.756796962025316, -1.432183793103448 ], [ 29.756796962025316, -1.430836379310345 ], [ 29.756257974683542, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10524, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.430836379310345 ], [ 29.756796962025316, -1.432183793103448 ], [ 29.757066455696201, -1.432183793103448 ], [ 29.757066455696201, -1.430836379310345 ], [ 29.756796962025316, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10525, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.430836379310345 ], [ 29.757066455696201, -1.431105862068966 ], [ 29.757335949367089, -1.431105862068966 ], [ 29.757335949367089, -1.430836379310345 ], [ 29.757066455696201, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10526, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.430836379310345 ], [ 29.757335949367089, -1.431105862068966 ], [ 29.757605443037974, -1.431105862068966 ], [ 29.757605443037974, -1.430836379310345 ], [ 29.757335949367089, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10527, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.430836379310345 ], [ 29.757605443037974, -1.431914310344828 ], [ 29.757874936708859, -1.431914310344828 ], [ 29.757874936708859, -1.430836379310345 ], [ 29.757605443037974, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10528, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.430836379310345 ], [ 29.757874936708859, -1.431105862068966 ], [ 29.758144430379748, -1.431105862068966 ], [ 29.758144430379748, -1.430836379310345 ], [ 29.757874936708859, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10529, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.430836379310345 ], [ 29.758144430379748, -1.431105862068966 ], [ 29.758413924050632, -1.431105862068966 ], [ 29.758413924050632, -1.430836379310345 ], [ 29.758144430379748, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10530, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.430836379310345 ], [ 29.758413924050632, -1.431105862068966 ], [ 29.758683417721517, -1.431105862068966 ], [ 29.758683417721517, -1.430836379310345 ], [ 29.758413924050632, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10531, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.430836379310345 ], [ 29.758683417721517, -1.431105862068966 ], [ 29.758952911392406, -1.431105862068966 ], [ 29.758952911392406, -1.430836379310345 ], [ 29.758683417721517, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10532, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.430836379310345 ], [ 29.758952911392406, -1.431105862068966 ], [ 29.760030886075949, -1.431105862068966 ], [ 29.760030886075949, -1.430836379310345 ], [ 29.758952911392406, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10533, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.430836379310345 ], [ 29.760030886075949, -1.431105862068966 ], [ 29.760300379746834, -1.431105862068966 ], [ 29.760300379746834, -1.430836379310345 ], [ 29.760030886075949, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10534, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.430836379310345 ], [ 29.760300379746834, -1.431105862068966 ], [ 29.760569873417722, -1.431105862068966 ], [ 29.760569873417722, -1.430836379310345 ], [ 29.760300379746834, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10535, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.430836379310345 ], [ 29.760569873417722, -1.431105862068966 ], [ 29.761647848101266, -1.431105862068966 ], [ 29.761647848101266, -1.430836379310345 ], [ 29.760569873417722, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10536, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.430836379310345 ], [ 29.761647848101266, -1.431105862068966 ], [ 29.762186835443039, -1.431105862068966 ], [ 29.762186835443039, -1.430836379310345 ], [ 29.761647848101266, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10537, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.430836379310345 ], [ 29.762186835443039, -1.431105862068966 ], [ 29.762456329113924, -1.431105862068966 ], [ 29.762456329113924, -1.430836379310345 ], [ 29.762186835443039, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10538, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.430836379310345 ], [ 29.762456329113924, -1.431105862068966 ], [ 29.762725822784809, -1.431105862068966 ], [ 29.762725822784809, -1.430836379310345 ], [ 29.762456329113924, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10539, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.430836379310345 ], [ 29.762725822784809, -1.431105862068966 ], [ 29.762995316455697, -1.431105862068966 ], [ 29.762995316455697, -1.430836379310345 ], [ 29.762725822784809, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10540, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.430836379310345 ], [ 29.762995316455697, -1.431105862068966 ], [ 29.763264810126582, -1.431105862068966 ], [ 29.763264810126582, -1.430836379310345 ], [ 29.762995316455697, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10541, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.430836379310345 ], [ 29.763264810126582, -1.431105862068966 ], [ 29.763534303797467, -1.431105862068966 ], [ 29.763534303797467, -1.430836379310345 ], [ 29.763264810126582, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10542, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.430836379310345 ], [ 29.763534303797467, -1.431105862068966 ], [ 29.763803797468356, -1.431105862068966 ], [ 29.763803797468356, -1.430836379310345 ], [ 29.763534303797467, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10543, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.430836379310345 ], [ 29.763803797468356, -1.431105862068966 ], [ 29.76407329113924, -1.431105862068966 ], [ 29.76407329113924, -1.430836379310345 ], [ 29.763803797468356, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10544, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.430836379310345 ], [ 29.76407329113924, -1.431105862068966 ], [ 29.764342784810125, -1.431105862068966 ], [ 29.764342784810125, -1.430836379310345 ], [ 29.76407329113924, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10545, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.430836379310345 ], [ 29.764342784810125, -1.431105862068966 ], [ 29.764612278481014, -1.431105862068966 ], [ 29.764612278481014, -1.430836379310345 ], [ 29.764342784810125, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10546, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.430836379310345 ], [ 29.764612278481014, -1.431105862068966 ], [ 29.764881772151899, -1.431105862068966 ], [ 29.764881772151899, -1.430836379310345 ], [ 29.764612278481014, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10547, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.430836379310345 ], [ 29.764881772151899, -1.431105862068966 ], [ 29.765151265822784, -1.431105862068966 ], [ 29.765151265822784, -1.430836379310345 ], [ 29.764881772151899, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10548, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.430836379310345 ], [ 29.765151265822784, -1.431105862068966 ], [ 29.765420759493672, -1.431105862068966 ], [ 29.765420759493672, -1.430836379310345 ], [ 29.765151265822784, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10549, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.430836379310345 ], [ 29.765420759493672, -1.431105862068966 ], [ 29.765959746835442, -1.431105862068966 ], [ 29.765959746835442, -1.430836379310345 ], [ 29.765420759493672, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10550, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.430836379310345 ], [ 29.765959746835442, -1.431105862068966 ], [ 29.766229240506327, -1.431105862068966 ], [ 29.766229240506327, -1.430836379310345 ], [ 29.765959746835442, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10551, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.430836379310345 ], [ 29.766229240506327, -1.431105862068966 ], [ 29.766498734177215, -1.431105862068966 ], [ 29.766498734177215, -1.430836379310345 ], [ 29.766229240506327, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10552, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.430836379310345 ], [ 29.766498734177215, -1.431105862068966 ], [ 29.7667682278481, -1.431105862068966 ], [ 29.7667682278481, -1.430836379310345 ], [ 29.766498734177215, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10553, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.430836379310345 ], [ 29.7667682278481, -1.431105862068966 ], [ 29.767037721518985, -1.431105862068966 ], [ 29.767037721518985, -1.430836379310345 ], [ 29.7667682278481, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10554, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.430836379310345 ], [ 29.767037721518985, -1.431105862068966 ], [ 29.767307215189874, -1.431105862068966 ], [ 29.767307215189874, -1.430836379310345 ], [ 29.767037721518985, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10555, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.430836379310345 ], [ 29.767307215189874, -1.431105862068966 ], [ 29.767576708860759, -1.431105862068966 ], [ 29.767576708860759, -1.430836379310345 ], [ 29.767307215189874, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10556, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.430836379310345 ], [ 29.767576708860759, -1.431105862068966 ], [ 29.767846202531643, -1.431105862068966 ], [ 29.767846202531643, -1.430836379310345 ], [ 29.767576708860759, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10557, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.430836379310345 ], [ 29.767846202531643, -1.431105862068966 ], [ 29.768115696202532, -1.431105862068966 ], [ 29.768115696202532, -1.430836379310345 ], [ 29.767846202531643, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10558, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.430836379310345 ], [ 29.768115696202532, -1.431105862068966 ], [ 29.768385189873417, -1.431105862068966 ], [ 29.768385189873417, -1.430836379310345 ], [ 29.768115696202532, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10559, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.430836379310345 ], [ 29.768385189873417, -1.431105862068966 ], [ 29.768654683544302, -1.431105862068966 ], [ 29.768654683544302, -1.430836379310345 ], [ 29.768385189873417, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10560, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.430836379310345 ], [ 29.768654683544302, -1.431105862068966 ], [ 29.76892417721519, -1.431105862068966 ], [ 29.76892417721519, -1.430836379310345 ], [ 29.768654683544302, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10561, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.430836379310345 ], [ 29.76892417721519, -1.431105862068966 ], [ 29.769193670886075, -1.431105862068966 ], [ 29.769193670886075, -1.430836379310345 ], [ 29.76892417721519, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10562, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.430836379310345 ], [ 29.769193670886075, -1.431105862068966 ], [ 29.76946316455696, -1.431105862068966 ], [ 29.76946316455696, -1.430836379310345 ], [ 29.769193670886075, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10563, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.430836379310345 ], [ 29.76946316455696, -1.431105862068966 ], [ 29.769732658227849, -1.431105862068966 ], [ 29.769732658227849, -1.430836379310345 ], [ 29.76946316455696, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10564, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.430836379310345 ], [ 29.769732658227849, -1.431105862068966 ], [ 29.770002151898733, -1.431105862068966 ], [ 29.770002151898733, -1.430836379310345 ], [ 29.769732658227849, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10565, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.430836379310345 ], [ 29.770002151898733, -1.431105862068966 ], [ 29.770271645569618, -1.431105862068966 ], [ 29.770271645569618, -1.430836379310345 ], [ 29.770002151898733, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10566, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.430836379310345 ], [ 29.770271645569618, -1.431105862068966 ], [ 29.770541139240507, -1.431105862068966 ], [ 29.770541139240507, -1.430836379310345 ], [ 29.770271645569618, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10567, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.430836379310345 ], [ 29.770541139240507, -1.431105862068966 ], [ 29.770810632911392, -1.431105862068966 ], [ 29.770810632911392, -1.430836379310345 ], [ 29.770541139240507, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10568, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.430836379310345 ], [ 29.770810632911392, -1.431105862068966 ], [ 29.771080126582277, -1.431105862068966 ], [ 29.771080126582277, -1.430836379310345 ], [ 29.770810632911392, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10569, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.430836379310345 ], [ 29.771080126582277, -1.431105862068966 ], [ 29.771349620253165, -1.431105862068966 ], [ 29.771349620253165, -1.430836379310345 ], [ 29.771080126582277, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10570, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.430836379310345 ], [ 29.771349620253165, -1.431105862068966 ], [ 29.77161911392405, -1.431105862068966 ], [ 29.77161911392405, -1.430836379310345 ], [ 29.771349620253165, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10571, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.430836379310345 ], [ 29.77161911392405, -1.431105862068966 ], [ 29.771888607594935, -1.431105862068966 ], [ 29.771888607594935, -1.430836379310345 ], [ 29.77161911392405, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10572, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.430836379310345 ], [ 29.771888607594935, -1.431105862068966 ], [ 29.772158101265823, -1.431105862068966 ], [ 29.772158101265823, -1.430836379310345 ], [ 29.771888607594935, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10573, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.430836379310345 ], [ 29.772158101265823, -1.431105862068966 ], [ 29.772427594936708, -1.431105862068966 ], [ 29.772427594936708, -1.430836379310345 ], [ 29.772158101265823, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10574, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.430836379310345 ], [ 29.772427594936708, -1.431105862068966 ], [ 29.772697088607593, -1.431105862068966 ], [ 29.772697088607593, -1.430836379310345 ], [ 29.772427594936708, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10575, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.430836379310345 ], [ 29.772697088607593, -1.431375344827586 ], [ 29.772966582278482, -1.431375344827586 ], [ 29.772966582278482, -1.430836379310345 ], [ 29.772697088607593, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10576, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.430836379310345 ], [ 29.773236075949367, -1.431105862068966 ], [ 29.773505569620252, -1.431105862068966 ], [ 29.773505569620252, -1.430836379310345 ], [ 29.773236075949367, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10577, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.430836379310345 ], [ 29.773505569620252, -1.431105862068966 ], [ 29.77377506329114, -1.431105862068966 ], [ 29.77377506329114, -1.430836379310345 ], [ 29.773505569620252, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10578, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.430836379310345 ], [ 29.77377506329114, -1.431105862068966 ], [ 29.774044556962025, -1.431105862068966 ], [ 29.774044556962025, -1.430836379310345 ], [ 29.77377506329114, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10579, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.430836379310345 ], [ 29.774044556962025, -1.431105862068966 ], [ 29.77431405063291, -1.431105862068966 ], [ 29.77431405063291, -1.430836379310345 ], [ 29.774044556962025, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10580, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.430836379310345 ], [ 29.77431405063291, -1.431105862068966 ], [ 29.774853037974683, -1.431105862068966 ], [ 29.774853037974683, -1.430836379310345 ], [ 29.77431405063291, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10581, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.430836379310345 ], [ 29.774853037974683, -1.431105862068966 ], [ 29.775122531645568, -1.431105862068966 ], [ 29.775122531645568, -1.430836379310345 ], [ 29.774853037974683, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10582, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.430836379310345 ], [ 29.775122531645568, -1.431105862068966 ], [ 29.775392025316457, -1.431105862068966 ], [ 29.775392025316457, -1.430836379310345 ], [ 29.775122531645568, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10583, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.430836379310345 ], [ 29.775392025316457, -1.431105862068966 ], [ 29.775661518987341, -1.431105862068966 ], [ 29.775661518987341, -1.430836379310345 ], [ 29.775392025316457, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10584, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.430836379310345 ], [ 29.775661518987341, -1.431105862068966 ], [ 29.775931012658226, -1.431105862068966 ], [ 29.775931012658226, -1.430836379310345 ], [ 29.775661518987341, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10585, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.430836379310345 ], [ 29.775931012658226, -1.431105862068966 ], [ 29.776200506329115, -1.431105862068966 ], [ 29.776200506329115, -1.430836379310345 ], [ 29.775931012658226, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10586, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.430836379310345 ], [ 29.776200506329115, -1.431105862068966 ], [ 29.77647, -1.431105862068966 ], [ 29.77647, -1.430836379310345 ], [ 29.776200506329115, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10587, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.430836379310345 ], [ 29.77647, -1.431105862068966 ], [ 29.776739493670885, -1.431105862068966 ], [ 29.776739493670885, -1.430836379310345 ], [ 29.77647, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10588, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.430836379310345 ], [ 29.776739493670885, -1.431105862068966 ], [ 29.777008987341773, -1.431105862068966 ], [ 29.777008987341773, -1.430836379310345 ], [ 29.776739493670885, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10589, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.430836379310345 ], [ 29.777008987341773, -1.431105862068966 ], [ 29.777278481012658, -1.431105862068966 ], [ 29.777278481012658, -1.430836379310345 ], [ 29.777008987341773, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10590, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.430836379310345 ], [ 29.777278481012658, -1.431105862068966 ], [ 29.777547974683543, -1.431105862068966 ], [ 29.777547974683543, -1.430836379310345 ], [ 29.777278481012658, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10591, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.430836379310345 ], [ 29.777547974683543, -1.431105862068966 ], [ 29.777817468354431, -1.431105862068966 ], [ 29.777817468354431, -1.430836379310345 ], [ 29.777547974683543, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10592, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.430836379310345 ], [ 29.777817468354431, -1.431105862068966 ], [ 29.778086962025316, -1.431105862068966 ], [ 29.778086962025316, -1.430836379310345 ], [ 29.777817468354431, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10593, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.430836379310345 ], [ 29.778086962025316, -1.431105862068966 ], [ 29.778356455696201, -1.431105862068966 ], [ 29.778356455696201, -1.430836379310345 ], [ 29.778086962025316, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10594, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.430836379310345 ], [ 29.778356455696201, -1.431105862068966 ], [ 29.77862594936709, -1.431105862068966 ], [ 29.77862594936709, -1.430836379310345 ], [ 29.778356455696201, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10595, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.430836379310345 ], [ 29.77862594936709, -1.431105862068966 ], [ 29.778895443037975, -1.431105862068966 ], [ 29.778895443037975, -1.430836379310345 ], [ 29.77862594936709, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10596, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.430836379310345 ], [ 29.778895443037975, -1.431105862068966 ], [ 29.77916493670886, -1.431105862068966 ], [ 29.77916493670886, -1.430836379310345 ], [ 29.778895443037975, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10597, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.430836379310345 ], [ 29.77916493670886, -1.431105862068966 ], [ 29.779434430379748, -1.431105862068966 ], [ 29.779434430379748, -1.430836379310345 ], [ 29.77916493670886, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10598, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.430836379310345 ], [ 29.779434430379748, -1.431105862068966 ], [ 29.779703924050633, -1.431105862068966 ], [ 29.779703924050633, -1.430836379310345 ], [ 29.779434430379748, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10599, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.430836379310345 ], [ 29.779703924050633, -1.431105862068966 ], [ 29.779973417721518, -1.431105862068966 ], [ 29.779973417721518, -1.430836379310345 ], [ 29.779703924050633, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10600, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.430836379310345 ], [ 29.779973417721518, -1.431105862068966 ], [ 29.780242911392406, -1.431105862068966 ], [ 29.780242911392406, -1.430836379310345 ], [ 29.779973417721518, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10601, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.430836379310345 ], [ 29.780242911392406, -1.431105862068966 ], [ 29.780512405063291, -1.431105862068966 ], [ 29.780512405063291, -1.430836379310345 ], [ 29.780242911392406, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10602, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.430836379310345 ], [ 29.780512405063291, -1.431375344827586 ], [ 29.780781898734176, -1.431375344827586 ], [ 29.780781898734176, -1.430836379310345 ], [ 29.780512405063291, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10603, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.430836379310345 ], [ 29.780781898734176, -1.431105862068966 ], [ 29.781051392405065, -1.431105862068966 ], [ 29.781051392405065, -1.430836379310345 ], [ 29.780781898734176, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10604, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.430836379310345 ], [ 29.781051392405065, -1.431105862068966 ], [ 29.78132088607595, -1.431105862068966 ], [ 29.78132088607595, -1.430836379310345 ], [ 29.781051392405065, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10605, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.430836379310345 ], [ 29.78132088607595, -1.431375344827586 ], [ 29.781590379746834, -1.431375344827586 ], [ 29.781590379746834, -1.430836379310345 ], [ 29.78132088607595, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10606, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.430836379310345 ], [ 29.781590379746834, -1.431644827586207 ], [ 29.781859873417723, -1.431644827586207 ], [ 29.781859873417723, -1.430836379310345 ], [ 29.781590379746834, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10607, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.430836379310345 ], [ 29.781859873417723, -1.431105862068966 ], [ 29.782129367088608, -1.431105862068966 ], [ 29.782129367088608, -1.430836379310345 ], [ 29.781859873417723, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10608, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.430836379310345 ], [ 29.782129367088608, -1.431375344827586 ], [ 29.782398860759493, -1.431375344827586 ], [ 29.782398860759493, -1.430836379310345 ], [ 29.782129367088608, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10609, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.430836379310345 ], [ 29.782398860759493, -1.431644827586207 ], [ 29.782668354430381, -1.431644827586207 ], [ 29.782668354430381, -1.430836379310345 ], [ 29.782398860759493, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10610, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.430836379310345 ], [ 29.782937848101266, -1.431375344827586 ], [ 29.783207341772151, -1.431375344827586 ], [ 29.783207341772151, -1.430836379310345 ], [ 29.782937848101266, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10611, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.430836379310345 ], [ 29.783207341772151, -1.431644827586207 ], [ 29.783476835443039, -1.431644827586207 ], [ 29.783476835443039, -1.430836379310345 ], [ 29.783207341772151, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10612, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.430836379310345 ], [ 29.791831139240507, -1.431105862068966 ], [ 29.792100632911392, -1.431105862068966 ], [ 29.792100632911392, -1.430836379310345 ], [ 29.791831139240507, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10613, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.430836379310345 ], [ 29.792100632911392, -1.431375344827586 ], [ 29.792370126582277, -1.431375344827586 ], [ 29.792370126582277, -1.430836379310345 ], [ 29.792100632911392, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10614, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.430836379310345 ], [ 29.792370126582277, -1.431105862068966 ], [ 29.792909113924051, -1.431105862068966 ], [ 29.792909113924051, -1.430836379310345 ], [ 29.792370126582277, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10615, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.430836379310345 ], [ 29.792909113924051, -1.431105862068966 ], [ 29.793178607594935, -1.431105862068966 ], [ 29.793178607594935, -1.430836379310345 ], [ 29.792909113924051, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10616, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.430836379310345 ], [ 29.793178607594935, -1.431644827586207 ], [ 29.793448101265824, -1.431644827586207 ], [ 29.793448101265824, -1.430836379310345 ], [ 29.793178607594935, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10617, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.430836379310345 ], [ 29.793448101265824, -1.431644827586207 ], [ 29.793717594936709, -1.431644827586207 ], [ 29.793717594936709, -1.430836379310345 ], [ 29.793448101265824, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10618, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.430836379310345 ], [ 29.799107468354432, -1.431375344827586 ], [ 29.799376962025317, -1.431375344827586 ], [ 29.799376962025317, -1.430836379310345 ], [ 29.799107468354432, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10619, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.430836379310345 ], [ 29.799376962025317, -1.431375344827586 ], [ 29.799646455696202, -1.431375344827586 ], [ 29.799646455696202, -1.430836379310345 ], [ 29.799376962025317, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10620, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.430836379310345 ], [ 29.799646455696202, -1.431105862068966 ], [ 29.79991594936709, -1.431105862068966 ], [ 29.79991594936709, -1.430836379310345 ], [ 29.799646455696202, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10621, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.430836379310345 ], [ 29.79991594936709, -1.431105862068966 ], [ 29.800185443037975, -1.431105862068966 ], [ 29.800185443037975, -1.430836379310345 ], [ 29.79991594936709, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10622, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.430836379310345 ], [ 29.800185443037975, -1.431105862068966 ], [ 29.80045493670886, -1.431105862068966 ], [ 29.80045493670886, -1.430836379310345 ], [ 29.800185443037975, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10623, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.430836379310345 ], [ 29.80045493670886, -1.431105862068966 ], [ 29.800724430379748, -1.431105862068966 ], [ 29.800724430379748, -1.430836379310345 ], [ 29.80045493670886, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10624, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.430836379310345 ], [ 29.800724430379748, -1.431105862068966 ], [ 29.800993924050633, -1.431105862068966 ], [ 29.800993924050633, -1.430836379310345 ], [ 29.800724430379748, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10625, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.430836379310345 ], [ 29.801263417721518, -1.431105862068966 ], [ 29.802071898734177, -1.431105862068966 ], [ 29.802071898734177, -1.430836379310345 ], [ 29.801263417721518, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10626, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.430836379310345 ], [ 29.802341392405065, -1.431105862068966 ], [ 29.80261088607595, -1.431105862068966 ], [ 29.80261088607595, -1.430836379310345 ], [ 29.802341392405065, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10627, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.430836379310345 ], [ 29.80261088607595, -1.431105862068966 ], [ 29.802880379746835, -1.431105862068966 ], [ 29.802880379746835, -1.430836379310345 ], [ 29.80261088607595, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10628, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.430836379310345 ], [ 29.802880379746835, -1.431105862068966 ], [ 29.803149873417723, -1.431105862068966 ], [ 29.803149873417723, -1.430836379310345 ], [ 29.802880379746835, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10629, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.430836379310345 ], [ 29.803149873417723, -1.431105862068966 ], [ 29.803419367088608, -1.431105862068966 ], [ 29.803419367088608, -1.430836379310345 ], [ 29.803149873417723, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10630, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.430836379310345 ], [ 29.803419367088608, -1.431105862068966 ], [ 29.803688860759493, -1.431105862068966 ], [ 29.803688860759493, -1.430836379310345 ], [ 29.803419367088608, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10631, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.430836379310345 ], [ 29.803688860759493, -1.431105862068966 ], [ 29.803958354430382, -1.431105862068966 ], [ 29.803958354430382, -1.430836379310345 ], [ 29.803688860759493, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10632, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.430836379310345 ], [ 29.803958354430382, -1.431105862068966 ], [ 29.804497341772151, -1.431105862068966 ], [ 29.804497341772151, -1.430836379310345 ], [ 29.803958354430382, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10633, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.430836379310345 ], [ 29.804497341772151, -1.431105862068966 ], [ 29.80476683544304, -1.431105862068966 ], [ 29.80476683544304, -1.430836379310345 ], [ 29.804497341772151, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10634, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.430836379310345 ], [ 29.80476683544304, -1.431375344827586 ], [ 29.805036329113925, -1.431375344827586 ], [ 29.805036329113925, -1.430836379310345 ], [ 29.80476683544304, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10635, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.430836379310345 ], [ 29.805036329113925, -1.431105862068966 ], [ 29.80530582278481, -1.431105862068966 ], [ 29.80530582278481, -1.430836379310345 ], [ 29.805036329113925, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10636, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.430836379310345 ], [ 29.80530582278481, -1.431105862068966 ], [ 29.805575316455698, -1.431105862068966 ], [ 29.805575316455698, -1.430836379310345 ], [ 29.80530582278481, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10637, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.430836379310345 ], [ 29.805575316455698, -1.431105862068966 ], [ 29.805844810126583, -1.431105862068966 ], [ 29.805844810126583, -1.430836379310345 ], [ 29.805575316455698, -1.430836379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10638, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.431105862068966 ], [ 29.757066455696201, -1.432453275862069 ], [ 29.757335949367089, -1.432453275862069 ], [ 29.757335949367089, -1.431105862068966 ], [ 29.757066455696201, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10639, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.431105862068966 ], [ 29.757335949367089, -1.432183793103448 ], [ 29.757605443037974, -1.432183793103448 ], [ 29.757605443037974, -1.431105862068966 ], [ 29.757335949367089, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10640, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.431105862068966 ], [ 29.757874936708859, -1.431914310344828 ], [ 29.758144430379748, -1.431914310344828 ], [ 29.758144430379748, -1.431105862068966 ], [ 29.757874936708859, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10641, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.431105862068966 ], [ 29.758144430379748, -1.431644827586207 ], [ 29.758413924050632, -1.431644827586207 ], [ 29.758413924050632, -1.431105862068966 ], [ 29.758144430379748, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10642, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.431105862068966 ], [ 29.758413924050632, -1.431375344827586 ], [ 29.758683417721517, -1.431375344827586 ], [ 29.758683417721517, -1.431105862068966 ], [ 29.758413924050632, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10643, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.431105862068966 ], [ 29.758683417721517, -1.431375344827586 ], [ 29.760030886075949, -1.431375344827586 ], [ 29.760030886075949, -1.431105862068966 ], [ 29.758683417721517, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10644, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.431105862068966 ], [ 29.760030886075949, -1.431375344827586 ], [ 29.760569873417722, -1.431375344827586 ], [ 29.760569873417722, -1.431105862068966 ], [ 29.760030886075949, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10645, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.431105862068966 ], [ 29.760569873417722, -1.431375344827586 ], [ 29.761917341772151, -1.431375344827586 ], [ 29.761917341772151, -1.431105862068966 ], [ 29.760569873417722, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10646, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.431105862068966 ], [ 29.761917341772151, -1.431375344827586 ], [ 29.762186835443039, -1.431375344827586 ], [ 29.762186835443039, -1.431105862068966 ], [ 29.761917341772151, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10647, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.431105862068966 ], [ 29.762186835443039, -1.431375344827586 ], [ 29.762456329113924, -1.431375344827586 ], [ 29.762456329113924, -1.431105862068966 ], [ 29.762186835443039, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10648, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.431105862068966 ], [ 29.762456329113924, -1.431375344827586 ], [ 29.762725822784809, -1.431375344827586 ], [ 29.762725822784809, -1.431105862068966 ], [ 29.762456329113924, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10649, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.431105862068966 ], [ 29.762725822784809, -1.431375344827586 ], [ 29.762995316455697, -1.431375344827586 ], [ 29.762995316455697, -1.431105862068966 ], [ 29.762725822784809, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10650, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.431105862068966 ], [ 29.762995316455697, -1.431375344827586 ], [ 29.763264810126582, -1.431375344827586 ], [ 29.763264810126582, -1.431105862068966 ], [ 29.762995316455697, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10651, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.431105862068966 ], [ 29.763264810126582, -1.431375344827586 ], [ 29.763534303797467, -1.431375344827586 ], [ 29.763534303797467, -1.431105862068966 ], [ 29.763264810126582, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10652, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.431105862068966 ], [ 29.763534303797467, -1.431375344827586 ], [ 29.763803797468356, -1.431375344827586 ], [ 29.763803797468356, -1.431105862068966 ], [ 29.763534303797467, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10653, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.431105862068966 ], [ 29.763803797468356, -1.431375344827586 ], [ 29.76407329113924, -1.431375344827586 ], [ 29.76407329113924, -1.431105862068966 ], [ 29.763803797468356, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10654, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.431105862068966 ], [ 29.76407329113924, -1.431375344827586 ], [ 29.764342784810125, -1.431375344827586 ], [ 29.764342784810125, -1.431105862068966 ], [ 29.76407329113924, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10655, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.431105862068966 ], [ 29.764342784810125, -1.431375344827586 ], [ 29.764612278481014, -1.431375344827586 ], [ 29.764612278481014, -1.431105862068966 ], [ 29.764342784810125, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10656, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.431105862068966 ], [ 29.764612278481014, -1.431375344827586 ], [ 29.764881772151899, -1.431375344827586 ], [ 29.764881772151899, -1.431105862068966 ], [ 29.764612278481014, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10657, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.431105862068966 ], [ 29.764881772151899, -1.431375344827586 ], [ 29.765151265822784, -1.431375344827586 ], [ 29.765151265822784, -1.431105862068966 ], [ 29.764881772151899, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10658, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.431105862068966 ], [ 29.765151265822784, -1.431375344827586 ], [ 29.765420759493672, -1.431375344827586 ], [ 29.765420759493672, -1.431105862068966 ], [ 29.765151265822784, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10659, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.431105862068966 ], [ 29.765420759493672, -1.431375344827586 ], [ 29.765959746835442, -1.431375344827586 ], [ 29.765959746835442, -1.431105862068966 ], [ 29.765420759493672, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10660, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.431105862068966 ], [ 29.765959746835442, -1.431375344827586 ], [ 29.766229240506327, -1.431375344827586 ], [ 29.766229240506327, -1.431105862068966 ], [ 29.765959746835442, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10661, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.431105862068966 ], [ 29.766229240506327, -1.431375344827586 ], [ 29.766498734177215, -1.431375344827586 ], [ 29.766498734177215, -1.431105862068966 ], [ 29.766229240506327, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10662, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.431105862068966 ], [ 29.766498734177215, -1.431375344827586 ], [ 29.7667682278481, -1.431375344827586 ], [ 29.7667682278481, -1.431105862068966 ], [ 29.766498734177215, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10663, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.431105862068966 ], [ 29.7667682278481, -1.431375344827586 ], [ 29.767037721518985, -1.431375344827586 ], [ 29.767037721518985, -1.431105862068966 ], [ 29.7667682278481, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10664, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.431105862068966 ], [ 29.767037721518985, -1.431375344827586 ], [ 29.767307215189874, -1.431375344827586 ], [ 29.767307215189874, -1.431105862068966 ], [ 29.767037721518985, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10665, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.431105862068966 ], [ 29.767307215189874, -1.431375344827586 ], [ 29.767576708860759, -1.431375344827586 ], [ 29.767576708860759, -1.431105862068966 ], [ 29.767307215189874, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10666, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.431105862068966 ], [ 29.767576708860759, -1.431375344827586 ], [ 29.767846202531643, -1.431375344827586 ], [ 29.767846202531643, -1.431105862068966 ], [ 29.767576708860759, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10667, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.431105862068966 ], [ 29.767846202531643, -1.431375344827586 ], [ 29.768115696202532, -1.431375344827586 ], [ 29.768115696202532, -1.431105862068966 ], [ 29.767846202531643, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10668, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.431105862068966 ], [ 29.768115696202532, -1.431375344827586 ], [ 29.768385189873417, -1.431375344827586 ], [ 29.768385189873417, -1.431105862068966 ], [ 29.768115696202532, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10669, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.431105862068966 ], [ 29.768385189873417, -1.431375344827586 ], [ 29.768654683544302, -1.431375344827586 ], [ 29.768654683544302, -1.431105862068966 ], [ 29.768385189873417, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10670, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.431105862068966 ], [ 29.768654683544302, -1.431375344827586 ], [ 29.76892417721519, -1.431375344827586 ], [ 29.76892417721519, -1.431105862068966 ], [ 29.768654683544302, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10671, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.431105862068966 ], [ 29.76892417721519, -1.431375344827586 ], [ 29.769193670886075, -1.431375344827586 ], [ 29.769193670886075, -1.431105862068966 ], [ 29.76892417721519, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10672, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.431105862068966 ], [ 29.769193670886075, -1.431375344827586 ], [ 29.76946316455696, -1.431375344827586 ], [ 29.76946316455696, -1.431105862068966 ], [ 29.769193670886075, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10673, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.431105862068966 ], [ 29.76946316455696, -1.431375344827586 ], [ 29.769732658227849, -1.431375344827586 ], [ 29.769732658227849, -1.431105862068966 ], [ 29.76946316455696, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10674, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.431105862068966 ], [ 29.769732658227849, -1.431375344827586 ], [ 29.770002151898733, -1.431375344827586 ], [ 29.770002151898733, -1.431105862068966 ], [ 29.769732658227849, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10675, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.431105862068966 ], [ 29.770002151898733, -1.431375344827586 ], [ 29.770271645569618, -1.431375344827586 ], [ 29.770271645569618, -1.431105862068966 ], [ 29.770002151898733, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10676, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.431105862068966 ], [ 29.770271645569618, -1.431375344827586 ], [ 29.770541139240507, -1.431375344827586 ], [ 29.770541139240507, -1.431105862068966 ], [ 29.770271645569618, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10677, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.431105862068966 ], [ 29.770541139240507, -1.431375344827586 ], [ 29.770810632911392, -1.431375344827586 ], [ 29.770810632911392, -1.431105862068966 ], [ 29.770541139240507, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10678, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.431105862068966 ], [ 29.770810632911392, -1.431375344827586 ], [ 29.771080126582277, -1.431375344827586 ], [ 29.771080126582277, -1.431105862068966 ], [ 29.770810632911392, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10679, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.431105862068966 ], [ 29.771080126582277, -1.431375344827586 ], [ 29.771349620253165, -1.431375344827586 ], [ 29.771349620253165, -1.431105862068966 ], [ 29.771080126582277, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10680, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.431105862068966 ], [ 29.771349620253165, -1.431375344827586 ], [ 29.77161911392405, -1.431375344827586 ], [ 29.77161911392405, -1.431105862068966 ], [ 29.771349620253165, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10681, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.431105862068966 ], [ 29.77161911392405, -1.431375344827586 ], [ 29.771888607594935, -1.431375344827586 ], [ 29.771888607594935, -1.431105862068966 ], [ 29.77161911392405, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10682, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.431105862068966 ], [ 29.771888607594935, -1.431375344827586 ], [ 29.772158101265823, -1.431375344827586 ], [ 29.772158101265823, -1.431105862068966 ], [ 29.771888607594935, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10683, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.431105862068966 ], [ 29.772158101265823, -1.431375344827586 ], [ 29.772427594936708, -1.431375344827586 ], [ 29.772427594936708, -1.431105862068966 ], [ 29.772158101265823, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10684, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.431105862068966 ], [ 29.772427594936708, -1.431375344827586 ], [ 29.772697088607593, -1.431375344827586 ], [ 29.772697088607593, -1.431105862068966 ], [ 29.772427594936708, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10685, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.431105862068966 ], [ 29.772966582278482, -1.431375344827586 ], [ 29.773236075949367, -1.431375344827586 ], [ 29.773236075949367, -1.431105862068966 ], [ 29.772966582278482, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10686, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.431105862068966 ], [ 29.773236075949367, -1.431375344827586 ], [ 29.773505569620252, -1.431375344827586 ], [ 29.773505569620252, -1.431105862068966 ], [ 29.773236075949367, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10687, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.431105862068966 ], [ 29.773505569620252, -1.431375344827586 ], [ 29.77377506329114, -1.431375344827586 ], [ 29.77377506329114, -1.431105862068966 ], [ 29.773505569620252, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10688, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.431105862068966 ], [ 29.77377506329114, -1.431375344827586 ], [ 29.774044556962025, -1.431375344827586 ], [ 29.774044556962025, -1.431105862068966 ], [ 29.77377506329114, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10689, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.431105862068966 ], [ 29.774044556962025, -1.431375344827586 ], [ 29.77431405063291, -1.431375344827586 ], [ 29.77431405063291, -1.431105862068966 ], [ 29.774044556962025, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10690, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.431105862068966 ], [ 29.77431405063291, -1.431375344827586 ], [ 29.774853037974683, -1.431375344827586 ], [ 29.774853037974683, -1.431105862068966 ], [ 29.77431405063291, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10691, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.431105862068966 ], [ 29.774853037974683, -1.431375344827586 ], [ 29.775122531645568, -1.431375344827586 ], [ 29.775122531645568, -1.431105862068966 ], [ 29.774853037974683, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10692, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.431105862068966 ], [ 29.775122531645568, -1.431375344827586 ], [ 29.775392025316457, -1.431375344827586 ], [ 29.775392025316457, -1.431105862068966 ], [ 29.775122531645568, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10693, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.431105862068966 ], [ 29.775392025316457, -1.431375344827586 ], [ 29.775661518987341, -1.431375344827586 ], [ 29.775661518987341, -1.431105862068966 ], [ 29.775392025316457, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10694, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.431105862068966 ], [ 29.775661518987341, -1.431375344827586 ], [ 29.775931012658226, -1.431375344827586 ], [ 29.775931012658226, -1.431105862068966 ], [ 29.775661518987341, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10695, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.431105862068966 ], [ 29.775931012658226, -1.431375344827586 ], [ 29.776200506329115, -1.431375344827586 ], [ 29.776200506329115, -1.431105862068966 ], [ 29.775931012658226, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10696, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.431105862068966 ], [ 29.776200506329115, -1.431375344827586 ], [ 29.77647, -1.431375344827586 ], [ 29.77647, -1.431105862068966 ], [ 29.776200506329115, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10697, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.431105862068966 ], [ 29.77647, -1.431375344827586 ], [ 29.776739493670885, -1.431375344827586 ], [ 29.776739493670885, -1.431105862068966 ], [ 29.77647, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10698, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.431105862068966 ], [ 29.776739493670885, -1.431375344827586 ], [ 29.777008987341773, -1.431375344827586 ], [ 29.777008987341773, -1.431105862068966 ], [ 29.776739493670885, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10699, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.431105862068966 ], [ 29.777008987341773, -1.431375344827586 ], [ 29.777278481012658, -1.431375344827586 ], [ 29.777278481012658, -1.431105862068966 ], [ 29.777008987341773, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10700, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.431105862068966 ], [ 29.777278481012658, -1.431375344827586 ], [ 29.777547974683543, -1.431375344827586 ], [ 29.777547974683543, -1.431105862068966 ], [ 29.777278481012658, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10701, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.431105862068966 ], [ 29.777547974683543, -1.431375344827586 ], [ 29.777817468354431, -1.431375344827586 ], [ 29.777817468354431, -1.431105862068966 ], [ 29.777547974683543, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10702, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.431105862068966 ], [ 29.777817468354431, -1.431375344827586 ], [ 29.778086962025316, -1.431375344827586 ], [ 29.778086962025316, -1.431105862068966 ], [ 29.777817468354431, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10703, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.431105862068966 ], [ 29.778086962025316, -1.431375344827586 ], [ 29.778356455696201, -1.431375344827586 ], [ 29.778356455696201, -1.431105862068966 ], [ 29.778086962025316, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10704, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.431105862068966 ], [ 29.778356455696201, -1.431375344827586 ], [ 29.77862594936709, -1.431375344827586 ], [ 29.77862594936709, -1.431105862068966 ], [ 29.778356455696201, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10705, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.431105862068966 ], [ 29.77862594936709, -1.431375344827586 ], [ 29.778895443037975, -1.431375344827586 ], [ 29.778895443037975, -1.431105862068966 ], [ 29.77862594936709, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10706, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.431105862068966 ], [ 29.778895443037975, -1.431375344827586 ], [ 29.77916493670886, -1.431375344827586 ], [ 29.77916493670886, -1.431105862068966 ], [ 29.778895443037975, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10707, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.431105862068966 ], [ 29.77916493670886, -1.431375344827586 ], [ 29.779434430379748, -1.431375344827586 ], [ 29.779434430379748, -1.431105862068966 ], [ 29.77916493670886, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10708, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.431105862068966 ], [ 29.779434430379748, -1.431644827586207 ], [ 29.779703924050633, -1.431644827586207 ], [ 29.779703924050633, -1.431105862068966 ], [ 29.779434430379748, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10709, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.431105862068966 ], [ 29.779703924050633, -1.431375344827586 ], [ 29.779973417721518, -1.431375344827586 ], [ 29.779973417721518, -1.431105862068966 ], [ 29.779703924050633, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10710, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.431105862068966 ], [ 29.779973417721518, -1.431375344827586 ], [ 29.780242911392406, -1.431375344827586 ], [ 29.780242911392406, -1.431105862068966 ], [ 29.779973417721518, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10711, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.431105862068966 ], [ 29.780242911392406, -1.431644827586207 ], [ 29.780512405063291, -1.431644827586207 ], [ 29.780512405063291, -1.431105862068966 ], [ 29.780242911392406, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10712, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.431105862068966 ], [ 29.780781898734176, -1.431375344827586 ], [ 29.781051392405065, -1.431375344827586 ], [ 29.781051392405065, -1.431105862068966 ], [ 29.780781898734176, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10713, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.431105862068966 ], [ 29.781051392405065, -1.431644827586207 ], [ 29.78132088607595, -1.431644827586207 ], [ 29.78132088607595, -1.431105862068966 ], [ 29.781051392405065, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10714, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.431105862068966 ], [ 29.781859873417723, -1.431644827586207 ], [ 29.782129367088608, -1.431644827586207 ], [ 29.782129367088608, -1.431105862068966 ], [ 29.781859873417723, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10715, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.431105862068966 ], [ 29.782668354430381, -1.431644827586207 ], [ 29.782937848101266, -1.431644827586207 ], [ 29.782937848101266, -1.431105862068966 ], [ 29.782668354430381, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10716, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.431105862068966 ], [ 29.784285316455698, -1.431644827586207 ], [ 29.784554810126583, -1.431644827586207 ], [ 29.784554810126583, -1.431105862068966 ], [ 29.784285316455698, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10717, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.431105862068966 ], [ 29.785093797468356, -1.431375344827586 ], [ 29.785363291139241, -1.431375344827586 ], [ 29.785363291139241, -1.431105862068966 ], [ 29.785093797468356, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10718, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.431105862068966 ], [ 29.785363291139241, -1.431375344827586 ], [ 29.785632784810126, -1.431375344827586 ], [ 29.785632784810126, -1.431105862068966 ], [ 29.785363291139241, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10719, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.431105862068966 ], [ 29.785632784810126, -1.431375344827586 ], [ 29.785902278481014, -1.431375344827586 ], [ 29.785902278481014, -1.431105862068966 ], [ 29.785632784810126, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10720, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.431105862068966 ], [ 29.785902278481014, -1.432183793103448 ], [ 29.786171772151899, -1.432183793103448 ], [ 29.786171772151899, -1.431105862068966 ], [ 29.785902278481014, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10721, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.431105862068966 ], [ 29.792370126582277, -1.431644827586207 ], [ 29.792909113924051, -1.431644827586207 ], [ 29.792909113924051, -1.431105862068966 ], [ 29.792370126582277, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10722, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.431105862068966 ], [ 29.792909113924051, -1.431914310344828 ], [ 29.793178607594935, -1.431914310344828 ], [ 29.793178607594935, -1.431105862068966 ], [ 29.792909113924051, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10723, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.431105862068966 ], [ 29.798298987341774, -1.431375344827586 ], [ 29.798568481012659, -1.431375344827586 ], [ 29.798568481012659, -1.431105862068966 ], [ 29.798298987341774, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10724, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.431105862068966 ], [ 29.798568481012659, -1.431375344827586 ], [ 29.798837974683543, -1.431375344827586 ], [ 29.798837974683543, -1.431105862068966 ], [ 29.798568481012659, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10725, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.431105862068966 ], [ 29.798837974683543, -1.431375344827586 ], [ 29.799107468354432, -1.431375344827586 ], [ 29.799107468354432, -1.431105862068966 ], [ 29.798837974683543, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10726, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.431105862068966 ], [ 29.799646455696202, -1.431644827586207 ], [ 29.79991594936709, -1.431644827586207 ], [ 29.79991594936709, -1.431105862068966 ], [ 29.799646455696202, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10727, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.431105862068966 ], [ 29.79991594936709, -1.431375344827586 ], [ 29.800185443037975, -1.431375344827586 ], [ 29.800185443037975, -1.431105862068966 ], [ 29.79991594936709, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10728, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.431105862068966 ], [ 29.800185443037975, -1.431375344827586 ], [ 29.80045493670886, -1.431375344827586 ], [ 29.80045493670886, -1.431105862068966 ], [ 29.800185443037975, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10729, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.431105862068966 ], [ 29.80045493670886, -1.431375344827586 ], [ 29.800724430379748, -1.431375344827586 ], [ 29.800724430379748, -1.431105862068966 ], [ 29.80045493670886, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10730, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.431105862068966 ], [ 29.800724430379748, -1.431375344827586 ], [ 29.800993924050633, -1.431375344827586 ], [ 29.800993924050633, -1.431105862068966 ], [ 29.800724430379748, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10731, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.431105862068966 ], [ 29.800993924050633, -1.431375344827586 ], [ 29.801263417721518, -1.431375344827586 ], [ 29.801263417721518, -1.431105862068966 ], [ 29.800993924050633, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10732, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.431105862068966 ], [ 29.801263417721518, -1.431375344827586 ], [ 29.801532911392407, -1.431375344827586 ], [ 29.801532911392407, -1.431105862068966 ], [ 29.801263417721518, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10733, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.431105862068966 ], [ 29.801532911392407, -1.431375344827586 ], [ 29.802071898734177, -1.431375344827586 ], [ 29.802071898734177, -1.431105862068966 ], [ 29.801532911392407, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10734, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.431105862068966 ], [ 29.802071898734177, -1.431375344827586 ], [ 29.802341392405065, -1.431375344827586 ], [ 29.802341392405065, -1.431105862068966 ], [ 29.802071898734177, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10735, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.431105862068966 ], [ 29.802341392405065, -1.431375344827586 ], [ 29.80261088607595, -1.431375344827586 ], [ 29.80261088607595, -1.431105862068966 ], [ 29.802341392405065, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10736, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.431105862068966 ], [ 29.80261088607595, -1.431375344827586 ], [ 29.802880379746835, -1.431375344827586 ], [ 29.802880379746835, -1.431105862068966 ], [ 29.80261088607595, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10737, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.431105862068966 ], [ 29.802880379746835, -1.431375344827586 ], [ 29.803149873417723, -1.431375344827586 ], [ 29.803149873417723, -1.431105862068966 ], [ 29.802880379746835, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10738, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.431105862068966 ], [ 29.803149873417723, -1.431375344827586 ], [ 29.803419367088608, -1.431375344827586 ], [ 29.803419367088608, -1.431105862068966 ], [ 29.803149873417723, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10739, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.431105862068966 ], [ 29.803419367088608, -1.431375344827586 ], [ 29.803688860759493, -1.431375344827586 ], [ 29.803688860759493, -1.431105862068966 ], [ 29.803419367088608, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10740, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.431105862068966 ], [ 29.803688860759493, -1.431375344827586 ], [ 29.803958354430382, -1.431375344827586 ], [ 29.803958354430382, -1.431105862068966 ], [ 29.803688860759493, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10741, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.431105862068966 ], [ 29.803958354430382, -1.431375344827586 ], [ 29.80476683544304, -1.431375344827586 ], [ 29.80476683544304, -1.431105862068966 ], [ 29.803958354430382, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10742, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.431105862068966 ], [ 29.805575316455698, -1.431375344827586 ], [ 29.805844810126583, -1.431375344827586 ], [ 29.805844810126583, -1.431105862068966 ], [ 29.805575316455698, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10743, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.431105862068966 ], [ 29.805844810126583, -1.431375344827586 ], [ 29.806114303797468, -1.431375344827586 ], [ 29.806114303797468, -1.431105862068966 ], [ 29.805844810126583, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10744, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.431105862068966 ], [ 29.806114303797468, -1.431375344827586 ], [ 29.806383797468357, -1.431375344827586 ], [ 29.806383797468357, -1.431105862068966 ], [ 29.806114303797468, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10745, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.431375344827586 ], [ 29.758413924050632, -1.431644827586207 ], [ 29.758683417721517, -1.431644827586207 ], [ 29.758683417721517, -1.431375344827586 ], [ 29.758413924050632, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10746, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.431375344827586 ], [ 29.758683417721517, -1.431644827586207 ], [ 29.759761392405064, -1.431644827586207 ], [ 29.759761392405064, -1.431375344827586 ], [ 29.758683417721517, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10747, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.431375344827586 ], [ 29.759761392405064, -1.431644827586207 ], [ 29.760300379746834, -1.431644827586207 ], [ 29.760300379746834, -1.431375344827586 ], [ 29.759761392405064, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10748, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.431375344827586 ], [ 29.760300379746834, -1.431644827586207 ], [ 29.760569873417722, -1.431644827586207 ], [ 29.760569873417722, -1.431375344827586 ], [ 29.760300379746834, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10749, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.431375344827586 ], [ 29.760569873417722, -1.431644827586207 ], [ 29.760839367088607, -1.431644827586207 ], [ 29.760839367088607, -1.431375344827586 ], [ 29.760569873417722, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10750, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.431375344827586 ], [ 29.760839367088607, -1.431644827586207 ], [ 29.761917341772151, -1.431644827586207 ], [ 29.761917341772151, -1.431375344827586 ], [ 29.760839367088607, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10751, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.431375344827586 ], [ 29.761917341772151, -1.431644827586207 ], [ 29.762456329113924, -1.431644827586207 ], [ 29.762456329113924, -1.431375344827586 ], [ 29.761917341772151, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10752, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.431375344827586 ], [ 29.762456329113924, -1.431644827586207 ], [ 29.762725822784809, -1.431644827586207 ], [ 29.762725822784809, -1.431375344827586 ], [ 29.762456329113924, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10753, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.431375344827586 ], [ 29.762725822784809, -1.431644827586207 ], [ 29.762995316455697, -1.431644827586207 ], [ 29.762995316455697, -1.431375344827586 ], [ 29.762725822784809, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10754, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.431375344827586 ], [ 29.762995316455697, -1.431644827586207 ], [ 29.763264810126582, -1.431644827586207 ], [ 29.763264810126582, -1.431375344827586 ], [ 29.762995316455697, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10755, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.431375344827586 ], [ 29.763264810126582, -1.431644827586207 ], [ 29.763534303797467, -1.431644827586207 ], [ 29.763534303797467, -1.431375344827586 ], [ 29.763264810126582, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10756, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.431375344827586 ], [ 29.763534303797467, -1.431644827586207 ], [ 29.763803797468356, -1.431644827586207 ], [ 29.763803797468356, -1.431375344827586 ], [ 29.763534303797467, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10757, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.431375344827586 ], [ 29.763803797468356, -1.431644827586207 ], [ 29.76407329113924, -1.431644827586207 ], [ 29.76407329113924, -1.431375344827586 ], [ 29.763803797468356, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10758, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.431375344827586 ], [ 29.76407329113924, -1.431644827586207 ], [ 29.764342784810125, -1.431644827586207 ], [ 29.764342784810125, -1.431375344827586 ], [ 29.76407329113924, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10759, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.431375344827586 ], [ 29.764342784810125, -1.431644827586207 ], [ 29.764612278481014, -1.431644827586207 ], [ 29.764612278481014, -1.431375344827586 ], [ 29.764342784810125, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10760, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.431375344827586 ], [ 29.764612278481014, -1.431644827586207 ], [ 29.764881772151899, -1.431644827586207 ], [ 29.764881772151899, -1.431375344827586 ], [ 29.764612278481014, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10761, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.431375344827586 ], [ 29.764881772151899, -1.431644827586207 ], [ 29.765151265822784, -1.431644827586207 ], [ 29.765151265822784, -1.431375344827586 ], [ 29.764881772151899, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10762, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.431375344827586 ], [ 29.765151265822784, -1.431644827586207 ], [ 29.765420759493672, -1.431644827586207 ], [ 29.765420759493672, -1.431375344827586 ], [ 29.765151265822784, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10763, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.431375344827586 ], [ 29.765420759493672, -1.431644827586207 ], [ 29.765959746835442, -1.431644827586207 ], [ 29.765959746835442, -1.431375344827586 ], [ 29.765420759493672, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10764, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.431375344827586 ], [ 29.765959746835442, -1.431644827586207 ], [ 29.766229240506327, -1.431644827586207 ], [ 29.766229240506327, -1.431375344827586 ], [ 29.765959746835442, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10765, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.431375344827586 ], [ 29.766229240506327, -1.431644827586207 ], [ 29.766498734177215, -1.431644827586207 ], [ 29.766498734177215, -1.431375344827586 ], [ 29.766229240506327, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10766, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.431375344827586 ], [ 29.766498734177215, -1.431644827586207 ], [ 29.7667682278481, -1.431644827586207 ], [ 29.7667682278481, -1.431375344827586 ], [ 29.766498734177215, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10767, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.431375344827586 ], [ 29.7667682278481, -1.431644827586207 ], [ 29.767037721518985, -1.431644827586207 ], [ 29.767037721518985, -1.431375344827586 ], [ 29.7667682278481, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10768, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.431375344827586 ], [ 29.767037721518985, -1.431644827586207 ], [ 29.767307215189874, -1.431644827586207 ], [ 29.767307215189874, -1.431375344827586 ], [ 29.767037721518985, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10769, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.431375344827586 ], [ 29.767307215189874, -1.431644827586207 ], [ 29.767576708860759, -1.431644827586207 ], [ 29.767576708860759, -1.431375344827586 ], [ 29.767307215189874, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10770, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.431375344827586 ], [ 29.767576708860759, -1.431644827586207 ], [ 29.767846202531643, -1.431644827586207 ], [ 29.767846202531643, -1.431375344827586 ], [ 29.767576708860759, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10771, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.431375344827586 ], [ 29.767846202531643, -1.431644827586207 ], [ 29.768115696202532, -1.431644827586207 ], [ 29.768115696202532, -1.431375344827586 ], [ 29.767846202531643, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10772, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.431375344827586 ], [ 29.768115696202532, -1.431644827586207 ], [ 29.768385189873417, -1.431644827586207 ], [ 29.768385189873417, -1.431375344827586 ], [ 29.768115696202532, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10773, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.431375344827586 ], [ 29.768385189873417, -1.431644827586207 ], [ 29.768654683544302, -1.431644827586207 ], [ 29.768654683544302, -1.431375344827586 ], [ 29.768385189873417, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10774, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.431375344827586 ], [ 29.768654683544302, -1.431644827586207 ], [ 29.76892417721519, -1.431644827586207 ], [ 29.76892417721519, -1.431375344827586 ], [ 29.768654683544302, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10775, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.431375344827586 ], [ 29.76892417721519, -1.431644827586207 ], [ 29.769193670886075, -1.431644827586207 ], [ 29.769193670886075, -1.431375344827586 ], [ 29.76892417721519, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10776, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.431375344827586 ], [ 29.769193670886075, -1.431644827586207 ], [ 29.76946316455696, -1.431644827586207 ], [ 29.76946316455696, -1.431375344827586 ], [ 29.769193670886075, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10777, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.431375344827586 ], [ 29.76946316455696, -1.431644827586207 ], [ 29.769732658227849, -1.431644827586207 ], [ 29.769732658227849, -1.431375344827586 ], [ 29.76946316455696, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10778, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.431375344827586 ], [ 29.769732658227849, -1.431644827586207 ], [ 29.770002151898733, -1.431644827586207 ], [ 29.770002151898733, -1.431375344827586 ], [ 29.769732658227849, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10779, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.431375344827586 ], [ 29.770002151898733, -1.431644827586207 ], [ 29.770271645569618, -1.431644827586207 ], [ 29.770271645569618, -1.431375344827586 ], [ 29.770002151898733, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10780, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.431375344827586 ], [ 29.770271645569618, -1.431644827586207 ], [ 29.770541139240507, -1.431644827586207 ], [ 29.770541139240507, -1.431375344827586 ], [ 29.770271645569618, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10781, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.431375344827586 ], [ 29.770541139240507, -1.431644827586207 ], [ 29.770810632911392, -1.431644827586207 ], [ 29.770810632911392, -1.431375344827586 ], [ 29.770541139240507, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10782, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.431375344827586 ], [ 29.770810632911392, -1.431644827586207 ], [ 29.771080126582277, -1.431644827586207 ], [ 29.771080126582277, -1.431375344827586 ], [ 29.770810632911392, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10783, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.431375344827586 ], [ 29.771080126582277, -1.431644827586207 ], [ 29.771349620253165, -1.431644827586207 ], [ 29.771349620253165, -1.431375344827586 ], [ 29.771080126582277, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10784, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.431375344827586 ], [ 29.771349620253165, -1.431644827586207 ], [ 29.77161911392405, -1.431644827586207 ], [ 29.77161911392405, -1.431375344827586 ], [ 29.771349620253165, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10785, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.431375344827586 ], [ 29.77161911392405, -1.431644827586207 ], [ 29.771888607594935, -1.431644827586207 ], [ 29.771888607594935, -1.431375344827586 ], [ 29.77161911392405, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10786, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.431375344827586 ], [ 29.771888607594935, -1.431644827586207 ], [ 29.772158101265823, -1.431644827586207 ], [ 29.772158101265823, -1.431375344827586 ], [ 29.771888607594935, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10787, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.431375344827586 ], [ 29.772158101265823, -1.431644827586207 ], [ 29.772427594936708, -1.431644827586207 ], [ 29.772427594936708, -1.431375344827586 ], [ 29.772158101265823, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10788, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.431375344827586 ], [ 29.772427594936708, -1.431644827586207 ], [ 29.772697088607593, -1.431644827586207 ], [ 29.772697088607593, -1.431375344827586 ], [ 29.772427594936708, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10789, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.431375344827586 ], [ 29.772697088607593, -1.431644827586207 ], [ 29.773236075949367, -1.431644827586207 ], [ 29.773236075949367, -1.431375344827586 ], [ 29.772697088607593, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10790, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.431375344827586 ], [ 29.773236075949367, -1.431644827586207 ], [ 29.773505569620252, -1.431644827586207 ], [ 29.773505569620252, -1.431375344827586 ], [ 29.773236075949367, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10791, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.431375344827586 ], [ 29.773505569620252, -1.431644827586207 ], [ 29.77377506329114, -1.431644827586207 ], [ 29.77377506329114, -1.431375344827586 ], [ 29.773505569620252, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10792, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.431375344827586 ], [ 29.77377506329114, -1.431644827586207 ], [ 29.774044556962025, -1.431644827586207 ], [ 29.774044556962025, -1.431375344827586 ], [ 29.77377506329114, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10793, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.431375344827586 ], [ 29.774044556962025, -1.431644827586207 ], [ 29.77431405063291, -1.431644827586207 ], [ 29.77431405063291, -1.431375344827586 ], [ 29.774044556962025, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10794, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.431375344827586 ], [ 29.77431405063291, -1.431644827586207 ], [ 29.774853037974683, -1.431644827586207 ], [ 29.774853037974683, -1.431375344827586 ], [ 29.77431405063291, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10795, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.431375344827586 ], [ 29.774853037974683, -1.431644827586207 ], [ 29.775122531645568, -1.431644827586207 ], [ 29.775122531645568, -1.431375344827586 ], [ 29.774853037974683, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10796, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.431375344827586 ], [ 29.775122531645568, -1.431644827586207 ], [ 29.775392025316457, -1.431644827586207 ], [ 29.775392025316457, -1.431375344827586 ], [ 29.775122531645568, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10797, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.431375344827586 ], [ 29.775392025316457, -1.431644827586207 ], [ 29.775661518987341, -1.431644827586207 ], [ 29.775661518987341, -1.431375344827586 ], [ 29.775392025316457, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10798, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.431375344827586 ], [ 29.775661518987341, -1.431644827586207 ], [ 29.775931012658226, -1.431644827586207 ], [ 29.775931012658226, -1.431375344827586 ], [ 29.775661518987341, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10799, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.431375344827586 ], [ 29.775931012658226, -1.431644827586207 ], [ 29.776200506329115, -1.431644827586207 ], [ 29.776200506329115, -1.431375344827586 ], [ 29.775931012658226, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10800, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.431375344827586 ], [ 29.776200506329115, -1.431644827586207 ], [ 29.77647, -1.431644827586207 ], [ 29.77647, -1.431375344827586 ], [ 29.776200506329115, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10801, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.431375344827586 ], [ 29.77647, -1.431644827586207 ], [ 29.776739493670885, -1.431644827586207 ], [ 29.776739493670885, -1.431375344827586 ], [ 29.77647, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10802, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.431375344827586 ], [ 29.776739493670885, -1.431644827586207 ], [ 29.777008987341773, -1.431644827586207 ], [ 29.777008987341773, -1.431375344827586 ], [ 29.776739493670885, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10803, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.431375344827586 ], [ 29.777008987341773, -1.431644827586207 ], [ 29.777278481012658, -1.431644827586207 ], [ 29.777278481012658, -1.431375344827586 ], [ 29.777008987341773, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10804, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.431375344827586 ], [ 29.777278481012658, -1.431644827586207 ], [ 29.777547974683543, -1.431644827586207 ], [ 29.777547974683543, -1.431375344827586 ], [ 29.777278481012658, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10805, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.431375344827586 ], [ 29.777547974683543, -1.431644827586207 ], [ 29.777817468354431, -1.431644827586207 ], [ 29.777817468354431, -1.431375344827586 ], [ 29.777547974683543, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10806, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.431375344827586 ], [ 29.777817468354431, -1.431644827586207 ], [ 29.778086962025316, -1.431644827586207 ], [ 29.778086962025316, -1.431375344827586 ], [ 29.777817468354431, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10807, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.431375344827586 ], [ 29.778086962025316, -1.431644827586207 ], [ 29.778356455696201, -1.431644827586207 ], [ 29.778356455696201, -1.431375344827586 ], [ 29.778086962025316, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10808, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.431375344827586 ], [ 29.778356455696201, -1.431914310344828 ], [ 29.77862594936709, -1.431914310344828 ], [ 29.77862594936709, -1.431375344827586 ], [ 29.778356455696201, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10809, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.431375344827586 ], [ 29.77862594936709, -1.431644827586207 ], [ 29.778895443037975, -1.431644827586207 ], [ 29.778895443037975, -1.431375344827586 ], [ 29.77862594936709, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10810, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.431375344827586 ], [ 29.778895443037975, -1.431644827586207 ], [ 29.77916493670886, -1.431644827586207 ], [ 29.77916493670886, -1.431375344827586 ], [ 29.778895443037975, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10811, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.431375344827586 ], [ 29.77916493670886, -1.431914310344828 ], [ 29.779434430379748, -1.431914310344828 ], [ 29.779434430379748, -1.431375344827586 ], [ 29.77916493670886, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10812, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.431375344827586 ], [ 29.779703924050633, -1.431914310344828 ], [ 29.779973417721518, -1.431914310344828 ], [ 29.779973417721518, -1.431375344827586 ], [ 29.779703924050633, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10813, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.431375344827586 ], [ 29.779973417721518, -1.431914310344828 ], [ 29.780242911392406, -1.431914310344828 ], [ 29.780242911392406, -1.431375344827586 ], [ 29.779973417721518, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10814, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.431375344827586 ], [ 29.780512405063291, -1.431914310344828 ], [ 29.780781898734176, -1.431914310344828 ], [ 29.780781898734176, -1.431375344827586 ], [ 29.780512405063291, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10815, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.431375344827586 ], [ 29.780781898734176, -1.431914310344828 ], [ 29.781051392405065, -1.431914310344828 ], [ 29.781051392405065, -1.431375344827586 ], [ 29.780781898734176, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10816, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.431375344827586 ], [ 29.78132088607595, -1.431914310344828 ], [ 29.781590379746834, -1.431914310344828 ], [ 29.781590379746834, -1.431375344827586 ], [ 29.78132088607595, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10817, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.431375344827586 ], [ 29.782129367088608, -1.431914310344828 ], [ 29.782398860759493, -1.431914310344828 ], [ 29.782398860759493, -1.431375344827586 ], [ 29.782129367088608, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10818, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.431375344827586 ], [ 29.782937848101266, -1.431914310344828 ], [ 29.783207341772151, -1.431914310344828 ], [ 29.783207341772151, -1.431375344827586 ], [ 29.782937848101266, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10819, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.431375344827586 ], [ 29.783476835443039, -1.431644827586207 ], [ 29.784015822784809, -1.431644827586207 ], [ 29.784015822784809, -1.431375344827586 ], [ 29.783476835443039, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10820, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.431375344827586 ], [ 29.784015822784809, -1.431644827586207 ], [ 29.784285316455698, -1.431644827586207 ], [ 29.784285316455698, -1.431375344827586 ], [ 29.784015822784809, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10821, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.431375344827586 ], [ 29.784554810126583, -1.431644827586207 ], [ 29.784824303797468, -1.431644827586207 ], [ 29.784824303797468, -1.431375344827586 ], [ 29.784554810126583, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10822, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.431375344827586 ], [ 29.784824303797468, -1.431644827586207 ], [ 29.785093797468356, -1.431644827586207 ], [ 29.785093797468356, -1.431375344827586 ], [ 29.784824303797468, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10823, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.431375344827586 ], [ 29.785093797468356, -1.431644827586207 ], [ 29.785363291139241, -1.431644827586207 ], [ 29.785363291139241, -1.431375344827586 ], [ 29.785093797468356, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10824, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.431375344827586 ], [ 29.785363291139241, -1.432183793103448 ], [ 29.785632784810126, -1.432183793103448 ], [ 29.785632784810126, -1.431375344827586 ], [ 29.785363291139241, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10825, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.431375344827586 ], [ 29.785632784810126, -1.432183793103448 ], [ 29.785902278481014, -1.432183793103448 ], [ 29.785902278481014, -1.431375344827586 ], [ 29.785632784810126, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10826, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.431375344827586 ], [ 29.788327721518986, -1.432183793103448 ], [ 29.788597215189874, -1.432183793103448 ], [ 29.788597215189874, -1.431375344827586 ], [ 29.788327721518986, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10827, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.431375344827586 ], [ 29.788597215189874, -1.431644827586207 ], [ 29.788866708860759, -1.431644827586207 ], [ 29.788866708860759, -1.431375344827586 ], [ 29.788597215189874, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10828, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.431375344827586 ], [ 29.788866708860759, -1.431644827586207 ], [ 29.789136202531644, -1.431644827586207 ], [ 29.789136202531644, -1.431375344827586 ], [ 29.788866708860759, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10829, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.431375344827586 ], [ 29.789136202531644, -1.431644827586207 ], [ 29.789405696202532, -1.431644827586207 ], [ 29.789405696202532, -1.431375344827586 ], [ 29.789136202531644, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10830, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.431375344827586 ], [ 29.789944683544302, -1.431914310344828 ], [ 29.790214177215191, -1.431914310344828 ], [ 29.790214177215191, -1.431375344827586 ], [ 29.789944683544302, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10831, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.431375344827586 ], [ 29.791292151898734, -1.431914310344828 ], [ 29.791561645569619, -1.431914310344828 ], [ 29.791561645569619, -1.431375344827586 ], [ 29.791292151898734, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10832, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.431375344827586 ], [ 29.792100632911392, -1.431914310344828 ], [ 29.792370126582277, -1.431914310344828 ], [ 29.792370126582277, -1.431375344827586 ], [ 29.792100632911392, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10833, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.431375344827586 ], [ 29.793717594936709, -1.431914310344828 ], [ 29.793987088607594, -1.431914310344828 ], [ 29.793987088607594, -1.431375344827586 ], [ 29.793717594936709, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10834, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.431375344827586 ], [ 29.794526075949367, -1.431644827586207 ], [ 29.794795569620252, -1.431644827586207 ], [ 29.794795569620252, -1.431375344827586 ], [ 29.794526075949367, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10835, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.431375344827586 ], [ 29.794795569620252, -1.431644827586207 ], [ 29.79506506329114, -1.431644827586207 ], [ 29.79506506329114, -1.431375344827586 ], [ 29.794795569620252, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10836, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.431375344827586 ], [ 29.79506506329114, -1.431644827586207 ], [ 29.795334556962025, -1.431644827586207 ], [ 29.795334556962025, -1.431375344827586 ], [ 29.79506506329114, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10837, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.431375344827586 ], [ 29.795334556962025, -1.431914310344828 ], [ 29.79560405063291, -1.431914310344828 ], [ 29.79560405063291, -1.431375344827586 ], [ 29.795334556962025, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10838, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.431375344827586 ], [ 29.798837974683543, -1.431644827586207 ], [ 29.799107468354432, -1.431644827586207 ], [ 29.799107468354432, -1.431375344827586 ], [ 29.798837974683543, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10839, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.431375344827586 ], [ 29.799107468354432, -1.431644827586207 ], [ 29.799376962025317, -1.431644827586207 ], [ 29.799376962025317, -1.431375344827586 ], [ 29.799107468354432, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10840, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.431375344827586 ], [ 29.799376962025317, -1.431644827586207 ], [ 29.799646455696202, -1.431644827586207 ], [ 29.799646455696202, -1.431375344827586 ], [ 29.799376962025317, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10841, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.431375344827586 ], [ 29.79991594936709, -1.431644827586207 ], [ 29.800185443037975, -1.431644827586207 ], [ 29.800185443037975, -1.431375344827586 ], [ 29.79991594936709, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10842, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.431375344827586 ], [ 29.800185443037975, -1.431644827586207 ], [ 29.80045493670886, -1.431644827586207 ], [ 29.80045493670886, -1.431375344827586 ], [ 29.800185443037975, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10843, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.431375344827586 ], [ 29.80045493670886, -1.431644827586207 ], [ 29.800724430379748, -1.431644827586207 ], [ 29.800724430379748, -1.431375344827586 ], [ 29.80045493670886, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10844, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.431375344827586 ], [ 29.800724430379748, -1.431914310344828 ], [ 29.800993924050633, -1.431914310344828 ], [ 29.800993924050633, -1.431375344827586 ], [ 29.800724430379748, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10845, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.431375344827586 ], [ 29.800993924050633, -1.431644827586207 ], [ 29.801263417721518, -1.431644827586207 ], [ 29.801263417721518, -1.431375344827586 ], [ 29.800993924050633, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10846, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.431375344827586 ], [ 29.801263417721518, -1.431644827586207 ], [ 29.801532911392407, -1.431644827586207 ], [ 29.801532911392407, -1.431375344827586 ], [ 29.801263417721518, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10847, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.431375344827586 ], [ 29.801532911392407, -1.431644827586207 ], [ 29.802071898734177, -1.431644827586207 ], [ 29.802071898734177, -1.431375344827586 ], [ 29.801532911392407, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10848, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.431375344827586 ], [ 29.802071898734177, -1.431644827586207 ], [ 29.802341392405065, -1.431644827586207 ], [ 29.802341392405065, -1.431375344827586 ], [ 29.802071898734177, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10849, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.431375344827586 ], [ 29.802341392405065, -1.431644827586207 ], [ 29.80261088607595, -1.431644827586207 ], [ 29.80261088607595, -1.431375344827586 ], [ 29.802341392405065, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10850, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.431375344827586 ], [ 29.80261088607595, -1.431644827586207 ], [ 29.802880379746835, -1.431644827586207 ], [ 29.802880379746835, -1.431375344827586 ], [ 29.80261088607595, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10851, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.431375344827586 ], [ 29.802880379746835, -1.431644827586207 ], [ 29.803149873417723, -1.431644827586207 ], [ 29.803149873417723, -1.431375344827586 ], [ 29.802880379746835, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10852, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.431375344827586 ], [ 29.803149873417723, -1.431644827586207 ], [ 29.803419367088608, -1.431644827586207 ], [ 29.803419367088608, -1.431375344827586 ], [ 29.803149873417723, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10853, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.431375344827586 ], [ 29.803419367088608, -1.431644827586207 ], [ 29.803688860759493, -1.431644827586207 ], [ 29.803688860759493, -1.431375344827586 ], [ 29.803419367088608, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10854, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.431375344827586 ], [ 29.803688860759493, -1.431644827586207 ], [ 29.804227848101267, -1.431644827586207 ], [ 29.804227848101267, -1.431375344827586 ], [ 29.803688860759493, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10855, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.431375344827586 ], [ 29.804227848101267, -1.431644827586207 ], [ 29.805036329113925, -1.431644827586207 ], [ 29.805036329113925, -1.431375344827586 ], [ 29.804227848101267, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10856, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.431644827586207 ], [ 29.758144430379748, -1.431914310344828 ], [ 29.758413924050632, -1.431914310344828 ], [ 29.758413924050632, -1.431644827586207 ], [ 29.758144430379748, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10857, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.431644827586207 ], [ 29.758413924050632, -1.431914310344828 ], [ 29.760030886075949, -1.431914310344828 ], [ 29.760030886075949, -1.431644827586207 ], [ 29.758413924050632, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10858, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.431644827586207 ], [ 29.760030886075949, -1.431914310344828 ], [ 29.760300379746834, -1.431914310344828 ], [ 29.760300379746834, -1.431644827586207 ], [ 29.760030886075949, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10859, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.431644827586207 ], [ 29.760300379746834, -1.431914310344828 ], [ 29.760569873417722, -1.431914310344828 ], [ 29.760569873417722, -1.431644827586207 ], [ 29.760300379746834, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10860, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.431644827586207 ], [ 29.760569873417722, -1.431914310344828 ], [ 29.761647848101266, -1.431914310344828 ], [ 29.761647848101266, -1.431644827586207 ], [ 29.760569873417722, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10861, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.431644827586207 ], [ 29.761647848101266, -1.431914310344828 ], [ 29.762186835443039, -1.431914310344828 ], [ 29.762186835443039, -1.431644827586207 ], [ 29.761647848101266, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10862, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.431644827586207 ], [ 29.762186835443039, -1.431914310344828 ], [ 29.762456329113924, -1.431914310344828 ], [ 29.762456329113924, -1.431644827586207 ], [ 29.762186835443039, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10863, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.431644827586207 ], [ 29.762456329113924, -1.431914310344828 ], [ 29.762725822784809, -1.431914310344828 ], [ 29.762725822784809, -1.431644827586207 ], [ 29.762456329113924, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10864, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.431644827586207 ], [ 29.762725822784809, -1.431914310344828 ], [ 29.762995316455697, -1.431914310344828 ], [ 29.762995316455697, -1.431644827586207 ], [ 29.762725822784809, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10865, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.431644827586207 ], [ 29.762995316455697, -1.431914310344828 ], [ 29.763264810126582, -1.431914310344828 ], [ 29.763264810126582, -1.431644827586207 ], [ 29.762995316455697, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10866, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.431644827586207 ], [ 29.763264810126582, -1.431914310344828 ], [ 29.763534303797467, -1.431914310344828 ], [ 29.763534303797467, -1.431644827586207 ], [ 29.763264810126582, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10867, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.431644827586207 ], [ 29.763534303797467, -1.431914310344828 ], [ 29.763803797468356, -1.431914310344828 ], [ 29.763803797468356, -1.431644827586207 ], [ 29.763534303797467, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10868, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.431644827586207 ], [ 29.763803797468356, -1.431914310344828 ], [ 29.76407329113924, -1.431914310344828 ], [ 29.76407329113924, -1.431644827586207 ], [ 29.763803797468356, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10869, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.431644827586207 ], [ 29.76407329113924, -1.431914310344828 ], [ 29.764342784810125, -1.431914310344828 ], [ 29.764342784810125, -1.431644827586207 ], [ 29.76407329113924, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10870, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.431644827586207 ], [ 29.764342784810125, -1.431914310344828 ], [ 29.764612278481014, -1.431914310344828 ], [ 29.764612278481014, -1.431644827586207 ], [ 29.764342784810125, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10871, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.431644827586207 ], [ 29.764612278481014, -1.431914310344828 ], [ 29.764881772151899, -1.431914310344828 ], [ 29.764881772151899, -1.431644827586207 ], [ 29.764612278481014, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10872, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.431644827586207 ], [ 29.764881772151899, -1.431914310344828 ], [ 29.765151265822784, -1.431914310344828 ], [ 29.765151265822784, -1.431644827586207 ], [ 29.764881772151899, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10873, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.431644827586207 ], [ 29.765151265822784, -1.431914310344828 ], [ 29.765420759493672, -1.431914310344828 ], [ 29.765420759493672, -1.431644827586207 ], [ 29.765151265822784, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10874, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.431644827586207 ], [ 29.765420759493672, -1.431914310344828 ], [ 29.765959746835442, -1.431914310344828 ], [ 29.765959746835442, -1.431644827586207 ], [ 29.765420759493672, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10875, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.431644827586207 ], [ 29.765959746835442, -1.431914310344828 ], [ 29.766229240506327, -1.431914310344828 ], [ 29.766229240506327, -1.431644827586207 ], [ 29.765959746835442, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10876, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.431644827586207 ], [ 29.766229240506327, -1.431914310344828 ], [ 29.766498734177215, -1.431914310344828 ], [ 29.766498734177215, -1.431644827586207 ], [ 29.766229240506327, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10877, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.431644827586207 ], [ 29.766498734177215, -1.431914310344828 ], [ 29.7667682278481, -1.431914310344828 ], [ 29.7667682278481, -1.431644827586207 ], [ 29.766498734177215, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10878, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.431644827586207 ], [ 29.7667682278481, -1.431914310344828 ], [ 29.767037721518985, -1.431914310344828 ], [ 29.767037721518985, -1.431644827586207 ], [ 29.7667682278481, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10879, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.431644827586207 ], [ 29.767037721518985, -1.431914310344828 ], [ 29.767307215189874, -1.431914310344828 ], [ 29.767307215189874, -1.431644827586207 ], [ 29.767037721518985, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10880, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.431644827586207 ], [ 29.767307215189874, -1.431914310344828 ], [ 29.767576708860759, -1.431914310344828 ], [ 29.767576708860759, -1.431644827586207 ], [ 29.767307215189874, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10881, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.431644827586207 ], [ 29.767576708860759, -1.431914310344828 ], [ 29.767846202531643, -1.431914310344828 ], [ 29.767846202531643, -1.431644827586207 ], [ 29.767576708860759, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10882, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.431644827586207 ], [ 29.767846202531643, -1.431914310344828 ], [ 29.768115696202532, -1.431914310344828 ], [ 29.768115696202532, -1.431644827586207 ], [ 29.767846202531643, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10883, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.431644827586207 ], [ 29.768115696202532, -1.431914310344828 ], [ 29.768385189873417, -1.431914310344828 ], [ 29.768385189873417, -1.431644827586207 ], [ 29.768115696202532, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10884, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.431644827586207 ], [ 29.768385189873417, -1.431914310344828 ], [ 29.768654683544302, -1.431914310344828 ], [ 29.768654683544302, -1.431644827586207 ], [ 29.768385189873417, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10885, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.431644827586207 ], [ 29.768654683544302, -1.431914310344828 ], [ 29.76892417721519, -1.431914310344828 ], [ 29.76892417721519, -1.431644827586207 ], [ 29.768654683544302, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10886, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.431644827586207 ], [ 29.76892417721519, -1.431914310344828 ], [ 29.769193670886075, -1.431914310344828 ], [ 29.769193670886075, -1.431644827586207 ], [ 29.76892417721519, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10887, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.431644827586207 ], [ 29.769193670886075, -1.431914310344828 ], [ 29.76946316455696, -1.431914310344828 ], [ 29.76946316455696, -1.431644827586207 ], [ 29.769193670886075, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10888, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.431644827586207 ], [ 29.76946316455696, -1.431914310344828 ], [ 29.769732658227849, -1.431914310344828 ], [ 29.769732658227849, -1.431644827586207 ], [ 29.76946316455696, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10889, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.431644827586207 ], [ 29.769732658227849, -1.431914310344828 ], [ 29.770002151898733, -1.431914310344828 ], [ 29.770002151898733, -1.431644827586207 ], [ 29.769732658227849, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10890, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.431644827586207 ], [ 29.770002151898733, -1.431914310344828 ], [ 29.770271645569618, -1.431914310344828 ], [ 29.770271645569618, -1.431644827586207 ], [ 29.770002151898733, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10891, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.431644827586207 ], [ 29.770271645569618, -1.431914310344828 ], [ 29.770541139240507, -1.431914310344828 ], [ 29.770541139240507, -1.431644827586207 ], [ 29.770271645569618, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10892, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.431644827586207 ], [ 29.770541139240507, -1.431914310344828 ], [ 29.770810632911392, -1.431914310344828 ], [ 29.770810632911392, -1.431644827586207 ], [ 29.770541139240507, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10893, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.431644827586207 ], [ 29.770810632911392, -1.431914310344828 ], [ 29.771080126582277, -1.431914310344828 ], [ 29.771080126582277, -1.431644827586207 ], [ 29.770810632911392, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10894, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.431644827586207 ], [ 29.771080126582277, -1.431914310344828 ], [ 29.771349620253165, -1.431914310344828 ], [ 29.771349620253165, -1.431644827586207 ], [ 29.771080126582277, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10895, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.431644827586207 ], [ 29.771349620253165, -1.431914310344828 ], [ 29.77161911392405, -1.431914310344828 ], [ 29.77161911392405, -1.431644827586207 ], [ 29.771349620253165, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10896, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.431644827586207 ], [ 29.77161911392405, -1.431914310344828 ], [ 29.771888607594935, -1.431914310344828 ], [ 29.771888607594935, -1.431644827586207 ], [ 29.77161911392405, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10897, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.431644827586207 ], [ 29.771888607594935, -1.431914310344828 ], [ 29.772158101265823, -1.431914310344828 ], [ 29.772158101265823, -1.431644827586207 ], [ 29.771888607594935, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10898, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.431644827586207 ], [ 29.772158101265823, -1.431914310344828 ], [ 29.772427594936708, -1.431914310344828 ], [ 29.772427594936708, -1.431644827586207 ], [ 29.772158101265823, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10899, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.431644827586207 ], [ 29.772427594936708, -1.431914310344828 ], [ 29.772697088607593, -1.431914310344828 ], [ 29.772697088607593, -1.431644827586207 ], [ 29.772427594936708, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10900, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.431644827586207 ], [ 29.772697088607593, -1.431914310344828 ], [ 29.772966582278482, -1.431914310344828 ], [ 29.772966582278482, -1.431644827586207 ], [ 29.772697088607593, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10901, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.431644827586207 ], [ 29.772966582278482, -1.432183793103448 ], [ 29.773236075949367, -1.432183793103448 ], [ 29.773236075949367, -1.431644827586207 ], [ 29.772966582278482, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10902, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.431644827586207 ], [ 29.773236075949367, -1.431914310344828 ], [ 29.773505569620252, -1.431914310344828 ], [ 29.773505569620252, -1.431644827586207 ], [ 29.773236075949367, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10903, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.431644827586207 ], [ 29.773505569620252, -1.431914310344828 ], [ 29.77377506329114, -1.431914310344828 ], [ 29.77377506329114, -1.431644827586207 ], [ 29.773505569620252, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10904, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.431644827586207 ], [ 29.77377506329114, -1.431914310344828 ], [ 29.774044556962025, -1.431914310344828 ], [ 29.774044556962025, -1.431644827586207 ], [ 29.77377506329114, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10905, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.431644827586207 ], [ 29.774044556962025, -1.431914310344828 ], [ 29.77431405063291, -1.431914310344828 ], [ 29.77431405063291, -1.431644827586207 ], [ 29.774044556962025, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10906, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.431644827586207 ], [ 29.77431405063291, -1.431914310344828 ], [ 29.774853037974683, -1.431914310344828 ], [ 29.774853037974683, -1.431644827586207 ], [ 29.77431405063291, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10907, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.431644827586207 ], [ 29.774853037974683, -1.431914310344828 ], [ 29.775122531645568, -1.431914310344828 ], [ 29.775122531645568, -1.431644827586207 ], [ 29.774853037974683, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10908, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.431644827586207 ], [ 29.775122531645568, -1.431914310344828 ], [ 29.775392025316457, -1.431914310344828 ], [ 29.775392025316457, -1.431644827586207 ], [ 29.775122531645568, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10909, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.431644827586207 ], [ 29.775392025316457, -1.431914310344828 ], [ 29.775661518987341, -1.431914310344828 ], [ 29.775661518987341, -1.431644827586207 ], [ 29.775392025316457, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10910, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.431644827586207 ], [ 29.775661518987341, -1.431914310344828 ], [ 29.775931012658226, -1.431914310344828 ], [ 29.775931012658226, -1.431644827586207 ], [ 29.775661518987341, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10911, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.431644827586207 ], [ 29.775931012658226, -1.431914310344828 ], [ 29.776200506329115, -1.431914310344828 ], [ 29.776200506329115, -1.431644827586207 ], [ 29.775931012658226, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10912, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.431644827586207 ], [ 29.776200506329115, -1.431914310344828 ], [ 29.77647, -1.431914310344828 ], [ 29.77647, -1.431644827586207 ], [ 29.776200506329115, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10913, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.431644827586207 ], [ 29.77647, -1.431914310344828 ], [ 29.776739493670885, -1.431914310344828 ], [ 29.776739493670885, -1.431644827586207 ], [ 29.77647, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10914, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.431644827586207 ], [ 29.776739493670885, -1.431914310344828 ], [ 29.777008987341773, -1.431914310344828 ], [ 29.777008987341773, -1.431644827586207 ], [ 29.776739493670885, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10915, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.431644827586207 ], [ 29.777008987341773, -1.431914310344828 ], [ 29.777278481012658, -1.431914310344828 ], [ 29.777278481012658, -1.431644827586207 ], [ 29.777008987341773, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10916, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.431644827586207 ], [ 29.777278481012658, -1.431914310344828 ], [ 29.777547974683543, -1.431914310344828 ], [ 29.777547974683543, -1.431644827586207 ], [ 29.777278481012658, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10917, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.431644827586207 ], [ 29.777547974683543, -1.431914310344828 ], [ 29.777817468354431, -1.431914310344828 ], [ 29.777817468354431, -1.431644827586207 ], [ 29.777547974683543, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10918, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.431644827586207 ], [ 29.777817468354431, -1.431914310344828 ], [ 29.778086962025316, -1.431914310344828 ], [ 29.778086962025316, -1.431644827586207 ], [ 29.777817468354431, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10919, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.431644827586207 ], [ 29.778086962025316, -1.432183793103448 ], [ 29.778356455696201, -1.432183793103448 ], [ 29.778356455696201, -1.431644827586207 ], [ 29.778086962025316, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10920, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.431644827586207 ], [ 29.77862594936709, -1.432183793103448 ], [ 29.778895443037975, -1.432183793103448 ], [ 29.778895443037975, -1.431644827586207 ], [ 29.77862594936709, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10921, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.431644827586207 ], [ 29.778895443037975, -1.432183793103448 ], [ 29.77916493670886, -1.432183793103448 ], [ 29.77916493670886, -1.431644827586207 ], [ 29.778895443037975, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10922, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.431644827586207 ], [ 29.779434430379748, -1.432183793103448 ], [ 29.779703924050633, -1.432183793103448 ], [ 29.779703924050633, -1.431644827586207 ], [ 29.779434430379748, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10923, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.431644827586207 ], [ 29.780242911392406, -1.432183793103448 ], [ 29.780512405063291, -1.432183793103448 ], [ 29.780512405063291, -1.431644827586207 ], [ 29.780242911392406, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10924, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.431644827586207 ], [ 29.781051392405065, -1.432183793103448 ], [ 29.78132088607595, -1.432183793103448 ], [ 29.78132088607595, -1.431644827586207 ], [ 29.781051392405065, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10925, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.431644827586207 ], [ 29.781590379746834, -1.431914310344828 ], [ 29.781859873417723, -1.431914310344828 ], [ 29.781859873417723, -1.431644827586207 ], [ 29.781590379746834, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10926, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.431644827586207 ], [ 29.781859873417723, -1.432183793103448 ], [ 29.782129367088608, -1.432183793103448 ], [ 29.782129367088608, -1.431644827586207 ], [ 29.781859873417723, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10927, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.431644827586207 ], [ 29.782398860759493, -1.431914310344828 ], [ 29.782668354430381, -1.431914310344828 ], [ 29.782668354430381, -1.431644827586207 ], [ 29.782398860759493, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10928, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.431644827586207 ], [ 29.782668354430381, -1.431914310344828 ], [ 29.782937848101266, -1.431914310344828 ], [ 29.782937848101266, -1.431644827586207 ], [ 29.782668354430381, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10929, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.431644827586207 ], [ 29.783207341772151, -1.431914310344828 ], [ 29.783476835443039, -1.431914310344828 ], [ 29.783476835443039, -1.431644827586207 ], [ 29.783207341772151, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10930, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.431644827586207 ], [ 29.783476835443039, -1.431914310344828 ], [ 29.784015822784809, -1.431914310344828 ], [ 29.784015822784809, -1.431644827586207 ], [ 29.783476835443039, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10931, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.431644827586207 ], [ 29.784015822784809, -1.431914310344828 ], [ 29.784285316455698, -1.431914310344828 ], [ 29.784285316455698, -1.431644827586207 ], [ 29.784015822784809, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10932, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.431644827586207 ], [ 29.784285316455698, -1.431914310344828 ], [ 29.784554810126583, -1.431914310344828 ], [ 29.784554810126583, -1.431644827586207 ], [ 29.784285316455698, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10933, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.431644827586207 ], [ 29.784554810126583, -1.432183793103448 ], [ 29.784824303797468, -1.432183793103448 ], [ 29.784824303797468, -1.431644827586207 ], [ 29.784554810126583, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10934, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.431644827586207 ], [ 29.784824303797468, -1.432453275862069 ], [ 29.785093797468356, -1.432453275862069 ], [ 29.785093797468356, -1.431644827586207 ], [ 29.784824303797468, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10935, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.431644827586207 ], [ 29.785093797468356, -1.432453275862069 ], [ 29.785363291139241, -1.432453275862069 ], [ 29.785363291139241, -1.431644827586207 ], [ 29.785093797468356, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10936, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.431644827586207 ], [ 29.788597215189874, -1.432183793103448 ], [ 29.788866708860759, -1.432183793103448 ], [ 29.788866708860759, -1.431644827586207 ], [ 29.788597215189874, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10937, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.431644827586207 ], [ 29.788866708860759, -1.432183793103448 ], [ 29.789136202531644, -1.432183793103448 ], [ 29.789136202531644, -1.431644827586207 ], [ 29.788866708860759, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10938, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.431644827586207 ], [ 29.789136202531644, -1.431914310344828 ], [ 29.789405696202532, -1.431914310344828 ], [ 29.789405696202532, -1.431644827586207 ], [ 29.789136202531644, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10939, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.431644827586207 ], [ 29.789405696202532, -1.431914310344828 ], [ 29.789675189873417, -1.431914310344828 ], [ 29.789675189873417, -1.431644827586207 ], [ 29.789405696202532, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10940, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.431644827586207 ], [ 29.789675189873417, -1.431914310344828 ], [ 29.789944683544302, -1.431914310344828 ], [ 29.789944683544302, -1.431644827586207 ], [ 29.789675189873417, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10941, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.431644827586207 ], [ 29.790214177215191, -1.431914310344828 ], [ 29.790483670886076, -1.431914310344828 ], [ 29.790483670886076, -1.431644827586207 ], [ 29.790214177215191, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10942, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.431644827586207 ], [ 29.790483670886076, -1.431914310344828 ], [ 29.790753164556961, -1.431914310344828 ], [ 29.790753164556961, -1.431644827586207 ], [ 29.790483670886076, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10943, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.431644827586207 ], [ 29.791022658227849, -1.432183793103448 ], [ 29.791292151898734, -1.432183793103448 ], [ 29.791292151898734, -1.431644827586207 ], [ 29.791022658227849, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10944, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.431644827586207 ], [ 29.792370126582277, -1.432183793103448 ], [ 29.792909113924051, -1.432183793103448 ], [ 29.792909113924051, -1.431644827586207 ], [ 29.792370126582277, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10945, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.431644827586207 ], [ 29.793178607594935, -1.431914310344828 ], [ 29.793448101265824, -1.431914310344828 ], [ 29.793448101265824, -1.431644827586207 ], [ 29.793178607594935, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10946, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.431644827586207 ], [ 29.793448101265824, -1.431914310344828 ], [ 29.793717594936709, -1.431914310344828 ], [ 29.793717594936709, -1.431644827586207 ], [ 29.793448101265824, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10947, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.431644827586207 ], [ 29.793987088607594, -1.431914310344828 ], [ 29.794256582278482, -1.431914310344828 ], [ 29.794256582278482, -1.431644827586207 ], [ 29.793987088607594, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10948, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.431644827586207 ], [ 29.794256582278482, -1.431914310344828 ], [ 29.794526075949367, -1.431914310344828 ], [ 29.794526075949367, -1.431644827586207 ], [ 29.794256582278482, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10949, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.431644827586207 ], [ 29.794526075949367, -1.431914310344828 ], [ 29.794795569620252, -1.431914310344828 ], [ 29.794795569620252, -1.431644827586207 ], [ 29.794526075949367, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10950, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.431644827586207 ], [ 29.794795569620252, -1.431914310344828 ], [ 29.79506506329114, -1.431914310344828 ], [ 29.79506506329114, -1.431644827586207 ], [ 29.794795569620252, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10951, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.431644827586207 ], [ 29.79506506329114, -1.431914310344828 ], [ 29.795334556962025, -1.431914310344828 ], [ 29.795334556962025, -1.431644827586207 ], [ 29.79506506329114, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10952, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.431644827586207 ], [ 29.799646455696202, -1.431914310344828 ], [ 29.79991594936709, -1.431914310344828 ], [ 29.79991594936709, -1.431644827586207 ], [ 29.799646455696202, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10953, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.431644827586207 ], [ 29.79991594936709, -1.431914310344828 ], [ 29.800185443037975, -1.431914310344828 ], [ 29.800185443037975, -1.431644827586207 ], [ 29.79991594936709, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10954, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.431644827586207 ], [ 29.800185443037975, -1.431914310344828 ], [ 29.80045493670886, -1.431914310344828 ], [ 29.80045493670886, -1.431644827586207 ], [ 29.800185443037975, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10955, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.431644827586207 ], [ 29.80045493670886, -1.431914310344828 ], [ 29.800724430379748, -1.431914310344828 ], [ 29.800724430379748, -1.431644827586207 ], [ 29.80045493670886, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10956, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.431644827586207 ], [ 29.800993924050633, -1.431914310344828 ], [ 29.801263417721518, -1.431914310344828 ], [ 29.801263417721518, -1.431644827586207 ], [ 29.800993924050633, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10957, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.431644827586207 ], [ 29.801263417721518, -1.431914310344828 ], [ 29.801532911392407, -1.431914310344828 ], [ 29.801532911392407, -1.431644827586207 ], [ 29.801263417721518, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10958, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.431644827586207 ], [ 29.801532911392407, -1.431914310344828 ], [ 29.802071898734177, -1.431914310344828 ], [ 29.802071898734177, -1.431644827586207 ], [ 29.801532911392407, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10959, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.431644827586207 ], [ 29.802071898734177, -1.431914310344828 ], [ 29.802341392405065, -1.431914310344828 ], [ 29.802341392405065, -1.431644827586207 ], [ 29.802071898734177, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10960, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.431644827586207 ], [ 29.802341392405065, -1.431914310344828 ], [ 29.80261088607595, -1.431914310344828 ], [ 29.80261088607595, -1.431644827586207 ], [ 29.802341392405065, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10961, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.431644827586207 ], [ 29.80261088607595, -1.431914310344828 ], [ 29.802880379746835, -1.431914310344828 ], [ 29.802880379746835, -1.431644827586207 ], [ 29.80261088607595, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10962, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.431644827586207 ], [ 29.802880379746835, -1.431914310344828 ], [ 29.803149873417723, -1.431914310344828 ], [ 29.803149873417723, -1.431644827586207 ], [ 29.802880379746835, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10963, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.431644827586207 ], [ 29.803149873417723, -1.431914310344828 ], [ 29.803419367088608, -1.431914310344828 ], [ 29.803419367088608, -1.431644827586207 ], [ 29.803149873417723, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10964, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.431644827586207 ], [ 29.803419367088608, -1.431914310344828 ], [ 29.803688860759493, -1.431914310344828 ], [ 29.803688860759493, -1.431644827586207 ], [ 29.803419367088608, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10965, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.431644827586207 ], [ 29.803688860759493, -1.431914310344828 ], [ 29.803958354430382, -1.431914310344828 ], [ 29.803958354430382, -1.431644827586207 ], [ 29.803688860759493, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10966, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.431105862068966 ], [ 29.80530582278481, -1.431105862068966 ], [ 29.80530582278481, -1.431914310344828 ], [ 29.803958354430382, -1.431914310344828 ], [ 29.803958354430382, -1.431644827586207 ], [ 29.805036329113925, -1.431644827586207 ], [ 29.805036329113925, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10967, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.431914310344828 ], [ 29.754641012658226, -1.432183793103448 ], [ 29.754910506329114, -1.432183793103448 ], [ 29.754910506329114, -1.431914310344828 ], [ 29.754641012658226, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10968, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.431914310344828 ], [ 29.754910506329114, -1.432183793103448 ], [ 29.75518, -1.432183793103448 ], [ 29.75518, -1.431914310344828 ], [ 29.754910506329114, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10969, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.431914310344828 ], [ 29.75518, -1.432183793103448 ], [ 29.755449493670884, -1.432183793103448 ], [ 29.755449493670884, -1.431914310344828 ], [ 29.75518, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10970, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.431914310344828 ], [ 29.755988481012658, -1.432453275862069 ], [ 29.756257974683542, -1.432453275862069 ], [ 29.756257974683542, -1.431914310344828 ], [ 29.755988481012658, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10971, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.431914310344828 ], [ 29.757605443037974, -1.432453275862069 ], [ 29.757874936708859, -1.432453275862069 ], [ 29.757874936708859, -1.431914310344828 ], [ 29.757605443037974, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10972, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.431914310344828 ], [ 29.757874936708859, -1.432183793103448 ], [ 29.758144430379748, -1.432183793103448 ], [ 29.758144430379748, -1.431914310344828 ], [ 29.757874936708859, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10973, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.431914310344828 ], [ 29.758144430379748, -1.432183793103448 ], [ 29.758413924050632, -1.432183793103448 ], [ 29.758413924050632, -1.431914310344828 ], [ 29.758144430379748, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10974, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.431914310344828 ], [ 29.758413924050632, -1.432183793103448 ], [ 29.758952911392406, -1.432183793103448 ], [ 29.758952911392406, -1.431914310344828 ], [ 29.758413924050632, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10975, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.431914310344828 ], [ 29.758952911392406, -1.432183793103448 ], [ 29.759491898734176, -1.432183793103448 ], [ 29.759491898734176, -1.431914310344828 ], [ 29.758952911392406, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10976, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.431914310344828 ], [ 29.759491898734176, -1.432183793103448 ], [ 29.760030886075949, -1.432183793103448 ], [ 29.760030886075949, -1.431914310344828 ], [ 29.759491898734176, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10977, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.431914310344828 ], [ 29.760030886075949, -1.432183793103448 ], [ 29.760300379746834, -1.432183793103448 ], [ 29.760300379746834, -1.431914310344828 ], [ 29.760030886075949, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10978, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.431914310344828 ], [ 29.760300379746834, -1.432183793103448 ], [ 29.760569873417722, -1.432183793103448 ], [ 29.760569873417722, -1.431914310344828 ], [ 29.760300379746834, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10979, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.431914310344828 ], [ 29.760569873417722, -1.432183793103448 ], [ 29.761917341772151, -1.432183793103448 ], [ 29.761917341772151, -1.431914310344828 ], [ 29.760569873417722, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10980, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.431914310344828 ], [ 29.761917341772151, -1.432183793103448 ], [ 29.762186835443039, -1.432183793103448 ], [ 29.762186835443039, -1.431914310344828 ], [ 29.761917341772151, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10981, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.431914310344828 ], [ 29.762186835443039, -1.432183793103448 ], [ 29.762456329113924, -1.432183793103448 ], [ 29.762456329113924, -1.431914310344828 ], [ 29.762186835443039, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10982, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.431914310344828 ], [ 29.762456329113924, -1.432183793103448 ], [ 29.762725822784809, -1.432183793103448 ], [ 29.762725822784809, -1.431914310344828 ], [ 29.762456329113924, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10983, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.431914310344828 ], [ 29.762725822784809, -1.432183793103448 ], [ 29.762995316455697, -1.432183793103448 ], [ 29.762995316455697, -1.431914310344828 ], [ 29.762725822784809, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10984, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.431914310344828 ], [ 29.762995316455697, -1.432183793103448 ], [ 29.763264810126582, -1.432183793103448 ], [ 29.763264810126582, -1.431914310344828 ], [ 29.762995316455697, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10985, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.431914310344828 ], [ 29.763264810126582, -1.432183793103448 ], [ 29.763534303797467, -1.432183793103448 ], [ 29.763534303797467, -1.431914310344828 ], [ 29.763264810126582, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10986, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.431914310344828 ], [ 29.763534303797467, -1.432183793103448 ], [ 29.763803797468356, -1.432183793103448 ], [ 29.763803797468356, -1.431914310344828 ], [ 29.763534303797467, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10987, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.431914310344828 ], [ 29.763803797468356, -1.432183793103448 ], [ 29.76407329113924, -1.432183793103448 ], [ 29.76407329113924, -1.431914310344828 ], [ 29.763803797468356, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10988, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.431914310344828 ], [ 29.76407329113924, -1.432183793103448 ], [ 29.764342784810125, -1.432183793103448 ], [ 29.764342784810125, -1.431914310344828 ], [ 29.76407329113924, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10989, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.431914310344828 ], [ 29.764342784810125, -1.432183793103448 ], [ 29.764612278481014, -1.432183793103448 ], [ 29.764612278481014, -1.431914310344828 ], [ 29.764342784810125, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10990, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.431914310344828 ], [ 29.764612278481014, -1.432183793103448 ], [ 29.764881772151899, -1.432183793103448 ], [ 29.764881772151899, -1.431914310344828 ], [ 29.764612278481014, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10991, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.431914310344828 ], [ 29.764881772151899, -1.432183793103448 ], [ 29.765151265822784, -1.432183793103448 ], [ 29.765151265822784, -1.431914310344828 ], [ 29.764881772151899, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10992, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.431914310344828 ], [ 29.765151265822784, -1.432183793103448 ], [ 29.765420759493672, -1.432183793103448 ], [ 29.765420759493672, -1.431914310344828 ], [ 29.765151265822784, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10993, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.431914310344828 ], [ 29.765420759493672, -1.432183793103448 ], [ 29.765959746835442, -1.432183793103448 ], [ 29.765959746835442, -1.431914310344828 ], [ 29.765420759493672, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10994, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.431914310344828 ], [ 29.765959746835442, -1.432183793103448 ], [ 29.766229240506327, -1.432183793103448 ], [ 29.766229240506327, -1.431914310344828 ], [ 29.765959746835442, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10995, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.431914310344828 ], [ 29.766229240506327, -1.432183793103448 ], [ 29.766498734177215, -1.432183793103448 ], [ 29.766498734177215, -1.431914310344828 ], [ 29.766229240506327, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10996, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.431914310344828 ], [ 29.766498734177215, -1.432183793103448 ], [ 29.7667682278481, -1.432183793103448 ], [ 29.7667682278481, -1.431914310344828 ], [ 29.766498734177215, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10997, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.431914310344828 ], [ 29.7667682278481, -1.432183793103448 ], [ 29.767037721518985, -1.432183793103448 ], [ 29.767037721518985, -1.431914310344828 ], [ 29.7667682278481, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10998, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.431914310344828 ], [ 29.767037721518985, -1.432183793103448 ], [ 29.767307215189874, -1.432183793103448 ], [ 29.767307215189874, -1.431914310344828 ], [ 29.767037721518985, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 10999, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.431914310344828 ], [ 29.767307215189874, -1.432183793103448 ], [ 29.767576708860759, -1.432183793103448 ], [ 29.767576708860759, -1.431914310344828 ], [ 29.767307215189874, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11000, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.431914310344828 ], [ 29.767576708860759, -1.432183793103448 ], [ 29.767846202531643, -1.432183793103448 ], [ 29.767846202531643, -1.431914310344828 ], [ 29.767576708860759, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11001, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.431914310344828 ], [ 29.767846202531643, -1.432183793103448 ], [ 29.768115696202532, -1.432183793103448 ], [ 29.768115696202532, -1.431914310344828 ], [ 29.767846202531643, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11002, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.431914310344828 ], [ 29.768115696202532, -1.432183793103448 ], [ 29.768385189873417, -1.432183793103448 ], [ 29.768385189873417, -1.431914310344828 ], [ 29.768115696202532, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11003, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.431914310344828 ], [ 29.768385189873417, -1.432183793103448 ], [ 29.768654683544302, -1.432183793103448 ], [ 29.768654683544302, -1.431914310344828 ], [ 29.768385189873417, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11004, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.431914310344828 ], [ 29.768654683544302, -1.432183793103448 ], [ 29.76892417721519, -1.432183793103448 ], [ 29.76892417721519, -1.431914310344828 ], [ 29.768654683544302, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11005, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.431914310344828 ], [ 29.76892417721519, -1.432183793103448 ], [ 29.769193670886075, -1.432183793103448 ], [ 29.769193670886075, -1.431914310344828 ], [ 29.76892417721519, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11006, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.431914310344828 ], [ 29.769193670886075, -1.432183793103448 ], [ 29.76946316455696, -1.432183793103448 ], [ 29.76946316455696, -1.431914310344828 ], [ 29.769193670886075, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11007, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.431914310344828 ], [ 29.76946316455696, -1.432183793103448 ], [ 29.769732658227849, -1.432183793103448 ], [ 29.769732658227849, -1.431914310344828 ], [ 29.76946316455696, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11008, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.431914310344828 ], [ 29.769732658227849, -1.432183793103448 ], [ 29.770002151898733, -1.432183793103448 ], [ 29.770002151898733, -1.431914310344828 ], [ 29.769732658227849, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11009, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.431914310344828 ], [ 29.770002151898733, -1.432183793103448 ], [ 29.770271645569618, -1.432183793103448 ], [ 29.770271645569618, -1.431914310344828 ], [ 29.770002151898733, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11010, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.431914310344828 ], [ 29.770271645569618, -1.432183793103448 ], [ 29.770541139240507, -1.432183793103448 ], [ 29.770541139240507, -1.431914310344828 ], [ 29.770271645569618, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11011, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.431914310344828 ], [ 29.770541139240507, -1.432183793103448 ], [ 29.770810632911392, -1.432183793103448 ], [ 29.770810632911392, -1.431914310344828 ], [ 29.770541139240507, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11012, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.431914310344828 ], [ 29.770810632911392, -1.432183793103448 ], [ 29.771080126582277, -1.432183793103448 ], [ 29.771080126582277, -1.431914310344828 ], [ 29.770810632911392, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11013, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.431914310344828 ], [ 29.771080126582277, -1.432183793103448 ], [ 29.771349620253165, -1.432183793103448 ], [ 29.771349620253165, -1.431914310344828 ], [ 29.771080126582277, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11014, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.431914310344828 ], [ 29.771349620253165, -1.432183793103448 ], [ 29.77161911392405, -1.432183793103448 ], [ 29.77161911392405, -1.431914310344828 ], [ 29.771349620253165, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11015, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.431914310344828 ], [ 29.77161911392405, -1.432183793103448 ], [ 29.771888607594935, -1.432183793103448 ], [ 29.771888607594935, -1.431914310344828 ], [ 29.77161911392405, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11016, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.431914310344828 ], [ 29.771888607594935, -1.432183793103448 ], [ 29.772158101265823, -1.432183793103448 ], [ 29.772158101265823, -1.431914310344828 ], [ 29.771888607594935, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11017, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.431914310344828 ], [ 29.772158101265823, -1.432183793103448 ], [ 29.772427594936708, -1.432183793103448 ], [ 29.772427594936708, -1.431914310344828 ], [ 29.772158101265823, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11018, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.431914310344828 ], [ 29.772427594936708, -1.432183793103448 ], [ 29.772697088607593, -1.432183793103448 ], [ 29.772697088607593, -1.431914310344828 ], [ 29.772427594936708, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11019, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.431914310344828 ], [ 29.772697088607593, -1.432453275862069 ], [ 29.772966582278482, -1.432453275862069 ], [ 29.772966582278482, -1.431914310344828 ], [ 29.772697088607593, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11020, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.431914310344828 ], [ 29.773236075949367, -1.432183793103448 ], [ 29.773505569620252, -1.432183793103448 ], [ 29.773505569620252, -1.431914310344828 ], [ 29.773236075949367, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11021, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.431914310344828 ], [ 29.773505569620252, -1.432183793103448 ], [ 29.77377506329114, -1.432183793103448 ], [ 29.77377506329114, -1.431914310344828 ], [ 29.773505569620252, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11022, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.431914310344828 ], [ 29.77377506329114, -1.432183793103448 ], [ 29.774044556962025, -1.432183793103448 ], [ 29.774044556962025, -1.431914310344828 ], [ 29.77377506329114, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11023, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.431914310344828 ], [ 29.774044556962025, -1.432183793103448 ], [ 29.77431405063291, -1.432183793103448 ], [ 29.77431405063291, -1.431914310344828 ], [ 29.774044556962025, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11024, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.431914310344828 ], [ 29.77431405063291, -1.432183793103448 ], [ 29.774853037974683, -1.432183793103448 ], [ 29.774853037974683, -1.431914310344828 ], [ 29.77431405063291, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11025, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.431914310344828 ], [ 29.774853037974683, -1.432183793103448 ], [ 29.775122531645568, -1.432183793103448 ], [ 29.775122531645568, -1.431914310344828 ], [ 29.774853037974683, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11026, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.431914310344828 ], [ 29.775122531645568, -1.432183793103448 ], [ 29.775392025316457, -1.432183793103448 ], [ 29.775392025316457, -1.431914310344828 ], [ 29.775122531645568, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11027, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.431914310344828 ], [ 29.775392025316457, -1.432183793103448 ], [ 29.775661518987341, -1.432183793103448 ], [ 29.775661518987341, -1.431914310344828 ], [ 29.775392025316457, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11028, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.431914310344828 ], [ 29.775661518987341, -1.432183793103448 ], [ 29.775931012658226, -1.432183793103448 ], [ 29.775931012658226, -1.431914310344828 ], [ 29.775661518987341, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11029, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.431914310344828 ], [ 29.775931012658226, -1.432183793103448 ], [ 29.776200506329115, -1.432183793103448 ], [ 29.776200506329115, -1.431914310344828 ], [ 29.775931012658226, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11030, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.431914310344828 ], [ 29.776200506329115, -1.432183793103448 ], [ 29.77647, -1.432183793103448 ], [ 29.77647, -1.431914310344828 ], [ 29.776200506329115, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11031, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.431914310344828 ], [ 29.77647, -1.432183793103448 ], [ 29.776739493670885, -1.432183793103448 ], [ 29.776739493670885, -1.431914310344828 ], [ 29.77647, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11032, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.431914310344828 ], [ 29.776739493670885, -1.432453275862069 ], [ 29.777008987341773, -1.432453275862069 ], [ 29.777008987341773, -1.431914310344828 ], [ 29.776739493670885, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11033, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.431914310344828 ], [ 29.777008987341773, -1.432453275862069 ], [ 29.777278481012658, -1.432453275862069 ], [ 29.777278481012658, -1.431914310344828 ], [ 29.777008987341773, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11034, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.431914310344828 ], [ 29.777278481012658, -1.432183793103448 ], [ 29.777547974683543, -1.432183793103448 ], [ 29.777547974683543, -1.431914310344828 ], [ 29.777278481012658, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11035, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.431914310344828 ], [ 29.777547974683543, -1.432453275862069 ], [ 29.777817468354431, -1.432453275862069 ], [ 29.777817468354431, -1.431914310344828 ], [ 29.777547974683543, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11036, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.431914310344828 ], [ 29.777817468354431, -1.432453275862069 ], [ 29.778086962025316, -1.432453275862069 ], [ 29.778086962025316, -1.431914310344828 ], [ 29.777817468354431, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11037, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.431914310344828 ], [ 29.778356455696201, -1.432453275862069 ], [ 29.77862594936709, -1.432453275862069 ], [ 29.77862594936709, -1.431914310344828 ], [ 29.778356455696201, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11038, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.431914310344828 ], [ 29.77916493670886, -1.432453275862069 ], [ 29.779434430379748, -1.432453275862069 ], [ 29.779434430379748, -1.431914310344828 ], [ 29.77916493670886, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11039, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.431914310344828 ], [ 29.779703924050633, -1.432183793103448 ], [ 29.779973417721518, -1.432183793103448 ], [ 29.779973417721518, -1.431914310344828 ], [ 29.779703924050633, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11040, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.431914310344828 ], [ 29.779973417721518, -1.432453275862069 ], [ 29.780242911392406, -1.432453275862069 ], [ 29.780242911392406, -1.431914310344828 ], [ 29.779973417721518, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11041, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.431914310344828 ], [ 29.780512405063291, -1.432183793103448 ], [ 29.780781898734176, -1.432183793103448 ], [ 29.780781898734176, -1.431914310344828 ], [ 29.780512405063291, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11042, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.431914310344828 ], [ 29.780781898734176, -1.432183793103448 ], [ 29.781051392405065, -1.432183793103448 ], [ 29.781051392405065, -1.431914310344828 ], [ 29.780781898734176, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11043, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.431914310344828 ], [ 29.78132088607595, -1.432183793103448 ], [ 29.781590379746834, -1.432183793103448 ], [ 29.781590379746834, -1.431914310344828 ], [ 29.78132088607595, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11044, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.431914310344828 ], [ 29.781590379746834, -1.432183793103448 ], [ 29.781859873417723, -1.432183793103448 ], [ 29.781859873417723, -1.431914310344828 ], [ 29.781590379746834, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11045, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.431914310344828 ], [ 29.782129367088608, -1.432183793103448 ], [ 29.782398860759493, -1.432183793103448 ], [ 29.782398860759493, -1.431914310344828 ], [ 29.782129367088608, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11046, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.431914310344828 ], [ 29.782398860759493, -1.432183793103448 ], [ 29.782668354430381, -1.432183793103448 ], [ 29.782668354430381, -1.431914310344828 ], [ 29.782398860759493, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11047, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.431914310344828 ], [ 29.782668354430381, -1.432183793103448 ], [ 29.782937848101266, -1.432183793103448 ], [ 29.782937848101266, -1.431914310344828 ], [ 29.782668354430381, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11048, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.431914310344828 ], [ 29.782937848101266, -1.432183793103448 ], [ 29.783207341772151, -1.432183793103448 ], [ 29.783207341772151, -1.431914310344828 ], [ 29.782937848101266, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11049, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.431914310344828 ], [ 29.783207341772151, -1.432183793103448 ], [ 29.783476835443039, -1.432183793103448 ], [ 29.783476835443039, -1.431914310344828 ], [ 29.783207341772151, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11050, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.431914310344828 ], [ 29.783476835443039, -1.432183793103448 ], [ 29.784015822784809, -1.432183793103448 ], [ 29.784015822784809, -1.431914310344828 ], [ 29.783476835443039, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11051, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.431914310344828 ], [ 29.784015822784809, -1.432453275862069 ], [ 29.784285316455698, -1.432453275862069 ], [ 29.784285316455698, -1.431914310344828 ], [ 29.784015822784809, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11052, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.431914310344828 ], [ 29.784285316455698, -1.432453275862069 ], [ 29.784554810126583, -1.432453275862069 ], [ 29.784554810126583, -1.431914310344828 ], [ 29.784285316455698, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11053, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.431914310344828 ], [ 29.789136202531644, -1.432453275862069 ], [ 29.789405696202532, -1.432453275862069 ], [ 29.789405696202532, -1.431914310344828 ], [ 29.789136202531644, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11054, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.431914310344828 ], [ 29.789405696202532, -1.432453275862069 ], [ 29.789675189873417, -1.432453275862069 ], [ 29.789675189873417, -1.431914310344828 ], [ 29.789405696202532, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11055, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.431914310344828 ], [ 29.789675189873417, -1.432183793103448 ], [ 29.789944683544302, -1.432183793103448 ], [ 29.789944683544302, -1.431914310344828 ], [ 29.789675189873417, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11056, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.431914310344828 ], [ 29.789944683544302, -1.432183793103448 ], [ 29.790214177215191, -1.432183793103448 ], [ 29.790214177215191, -1.431914310344828 ], [ 29.789944683544302, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11057, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.431914310344828 ], [ 29.790214177215191, -1.432183793103448 ], [ 29.790483670886076, -1.432183793103448 ], [ 29.790483670886076, -1.431914310344828 ], [ 29.790214177215191, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11058, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.431914310344828 ], [ 29.790483670886076, -1.432183793103448 ], [ 29.790753164556961, -1.432183793103448 ], [ 29.790753164556961, -1.431914310344828 ], [ 29.790483670886076, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11059, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.431914310344828 ], [ 29.790753164556961, -1.432183793103448 ], [ 29.791022658227849, -1.432183793103448 ], [ 29.791022658227849, -1.431914310344828 ], [ 29.790753164556961, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11060, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.431914310344828 ], [ 29.791292151898734, -1.432183793103448 ], [ 29.791561645569619, -1.432183793103448 ], [ 29.791561645569619, -1.431914310344828 ], [ 29.791292151898734, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11061, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.431914310344828 ], [ 29.791561645569619, -1.432183793103448 ], [ 29.792100632911392, -1.432183793103448 ], [ 29.792100632911392, -1.431914310344828 ], [ 29.791561645569619, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11062, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.431914310344828 ], [ 29.792100632911392, -1.432183793103448 ], [ 29.792370126582277, -1.432183793103448 ], [ 29.792370126582277, -1.431914310344828 ], [ 29.792100632911392, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11063, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.431914310344828 ], [ 29.792909113924051, -1.432183793103448 ], [ 29.793178607594935, -1.432183793103448 ], [ 29.793178607594935, -1.431914310344828 ], [ 29.792909113924051, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11064, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.431914310344828 ], [ 29.793178607594935, -1.432183793103448 ], [ 29.793448101265824, -1.432183793103448 ], [ 29.793448101265824, -1.431914310344828 ], [ 29.793178607594935, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11065, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.431914310344828 ], [ 29.793448101265824, -1.432183793103448 ], [ 29.793717594936709, -1.432183793103448 ], [ 29.793717594936709, -1.431914310344828 ], [ 29.793448101265824, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11066, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.431914310344828 ], [ 29.793717594936709, -1.432183793103448 ], [ 29.793987088607594, -1.432183793103448 ], [ 29.793987088607594, -1.431914310344828 ], [ 29.793717594936709, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11067, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.431914310344828 ], [ 29.793987088607594, -1.432183793103448 ], [ 29.794256582278482, -1.432183793103448 ], [ 29.794256582278482, -1.431914310344828 ], [ 29.793987088607594, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11068, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.431914310344828 ], [ 29.794256582278482, -1.432183793103448 ], [ 29.794526075949367, -1.432183793103448 ], [ 29.794526075949367, -1.431914310344828 ], [ 29.794256582278482, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11069, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.431914310344828 ], [ 29.794526075949367, -1.432183793103448 ], [ 29.794795569620252, -1.432183793103448 ], [ 29.794795569620252, -1.431914310344828 ], [ 29.794526075949367, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11070, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.431914310344828 ], [ 29.794795569620252, -1.432183793103448 ], [ 29.79506506329114, -1.432183793103448 ], [ 29.79506506329114, -1.431914310344828 ], [ 29.794795569620252, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11071, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.431914310344828 ], [ 29.79506506329114, -1.432183793103448 ], [ 29.795334556962025, -1.432183793103448 ], [ 29.795334556962025, -1.431914310344828 ], [ 29.79506506329114, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11072, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.431914310344828 ], [ 29.795334556962025, -1.432183793103448 ], [ 29.79560405063291, -1.432183793103448 ], [ 29.79560405063291, -1.431914310344828 ], [ 29.795334556962025, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11073, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.431914310344828 ], [ 29.79560405063291, -1.432453275862069 ], [ 29.795873544303799, -1.432453275862069 ], [ 29.795873544303799, -1.431914310344828 ], [ 29.79560405063291, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11074, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.431914310344828 ], [ 29.800185443037975, -1.432183793103448 ], [ 29.80045493670886, -1.432183793103448 ], [ 29.80045493670886, -1.431914310344828 ], [ 29.800185443037975, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11075, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.431914310344828 ], [ 29.80045493670886, -1.432183793103448 ], [ 29.800724430379748, -1.432183793103448 ], [ 29.800724430379748, -1.431914310344828 ], [ 29.80045493670886, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11076, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.431914310344828 ], [ 29.800724430379748, -1.432453275862069 ], [ 29.800993924050633, -1.432453275862069 ], [ 29.800993924050633, -1.431914310344828 ], [ 29.800724430379748, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11077, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.431914310344828 ], [ 29.800993924050633, -1.432183793103448 ], [ 29.801263417721518, -1.432183793103448 ], [ 29.801263417721518, -1.431914310344828 ], [ 29.800993924050633, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11078, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.431914310344828 ], [ 29.801263417721518, -1.432183793103448 ], [ 29.801532911392407, -1.432183793103448 ], [ 29.801532911392407, -1.431914310344828 ], [ 29.801263417721518, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11079, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.431914310344828 ], [ 29.801532911392407, -1.432183793103448 ], [ 29.802071898734177, -1.432183793103448 ], [ 29.802071898734177, -1.431914310344828 ], [ 29.801532911392407, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11080, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.431914310344828 ], [ 29.802071898734177, -1.432183793103448 ], [ 29.802341392405065, -1.432183793103448 ], [ 29.802341392405065, -1.431914310344828 ], [ 29.802071898734177, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11081, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.431914310344828 ], [ 29.802341392405065, -1.432183793103448 ], [ 29.80261088607595, -1.432183793103448 ], [ 29.80261088607595, -1.431914310344828 ], [ 29.802341392405065, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11082, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.431914310344828 ], [ 29.80261088607595, -1.432183793103448 ], [ 29.802880379746835, -1.432183793103448 ], [ 29.802880379746835, -1.431914310344828 ], [ 29.80261088607595, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11083, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.431914310344828 ], [ 29.802880379746835, -1.432183793103448 ], [ 29.803149873417723, -1.432183793103448 ], [ 29.803149873417723, -1.431914310344828 ], [ 29.802880379746835, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11084, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.431914310344828 ], [ 29.803149873417723, -1.432183793103448 ], [ 29.803419367088608, -1.432183793103448 ], [ 29.803419367088608, -1.431914310344828 ], [ 29.803149873417723, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11085, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.431914310344828 ], [ 29.803419367088608, -1.432183793103448 ], [ 29.803688860759493, -1.432183793103448 ], [ 29.803688860759493, -1.431914310344828 ], [ 29.803419367088608, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11086, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.431914310344828 ], [ 29.803688860759493, -1.432183793103448 ], [ 29.803958354430382, -1.432183793103448 ], [ 29.803958354430382, -1.431914310344828 ], [ 29.803688860759493, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11087, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.431105862068966 ], [ 29.805575316455698, -1.431105862068966 ], [ 29.805575316455698, -1.432183793103448 ], [ 29.803958354430382, -1.432183793103448 ], [ 29.803958354430382, -1.431914310344828 ], [ 29.80530582278481, -1.431914310344828 ], [ 29.80530582278481, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11088, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.432183793103448 ], [ 29.75518, -1.432453275862069 ], [ 29.755449493670884, -1.432453275862069 ], [ 29.755449493670884, -1.432183793103448 ], [ 29.75518, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11089, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.432183793103448 ], [ 29.755449493670884, -1.432453275862069 ], [ 29.755718987341773, -1.432453275862069 ], [ 29.755718987341773, -1.432183793103448 ], [ 29.755449493670884, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11090, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.432183793103448 ], [ 29.755718987341773, -1.432453275862069 ], [ 29.755988481012658, -1.432453275862069 ], [ 29.755988481012658, -1.432183793103448 ], [ 29.755718987341773, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11091, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.432183793103448 ], [ 29.756257974683542, -1.432453275862069 ], [ 29.756796962025316, -1.432453275862069 ], [ 29.756796962025316, -1.432183793103448 ], [ 29.756257974683542, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11092, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.432183793103448 ], [ 29.756796962025316, -1.432453275862069 ], [ 29.757066455696201, -1.432453275862069 ], [ 29.757066455696201, -1.432183793103448 ], [ 29.756796962025316, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11093, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.432183793103448 ], [ 29.757335949367089, -1.43272275862069 ], [ 29.757874936708859, -1.43272275862069 ], [ 29.757874936708859, -1.432453275862069 ], [ 29.757605443037974, -1.432453275862069 ], [ 29.757605443037974, -1.432183793103448 ], [ 29.757335949367089, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11094, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.432183793103448 ], [ 29.757874936708859, -1.432453275862069 ], [ 29.758144430379748, -1.432453275862069 ], [ 29.758144430379748, -1.432183793103448 ], [ 29.757874936708859, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11095, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.432183793103448 ], [ 29.758144430379748, -1.432453275862069 ], [ 29.758683417721517, -1.432453275862069 ], [ 29.758683417721517, -1.432183793103448 ], [ 29.758144430379748, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11096, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.432183793103448 ], [ 29.758683417721517, -1.432453275862069 ], [ 29.759761392405064, -1.432453275862069 ], [ 29.759761392405064, -1.432183793103448 ], [ 29.758683417721517, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11097, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.432183793103448 ], [ 29.759761392405064, -1.432453275862069 ], [ 29.760300379746834, -1.432453275862069 ], [ 29.760300379746834, -1.432183793103448 ], [ 29.759761392405064, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11098, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.432183793103448 ], [ 29.760300379746834, -1.432453275862069 ], [ 29.761378354430381, -1.432453275862069 ], [ 29.761378354430381, -1.432183793103448 ], [ 29.760300379746834, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11099, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.432183793103448 ], [ 29.761378354430381, -1.432453275862069 ], [ 29.761917341772151, -1.432453275862069 ], [ 29.761917341772151, -1.432183793103448 ], [ 29.761378354430381, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11100, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.432183793103448 ], [ 29.761917341772151, -1.432453275862069 ], [ 29.762186835443039, -1.432453275862069 ], [ 29.762186835443039, -1.432183793103448 ], [ 29.761917341772151, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11101, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.432183793103448 ], [ 29.762186835443039, -1.432453275862069 ], [ 29.762456329113924, -1.432453275862069 ], [ 29.762456329113924, -1.432183793103448 ], [ 29.762186835443039, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11102, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.432183793103448 ], [ 29.762456329113924, -1.432453275862069 ], [ 29.762725822784809, -1.432453275862069 ], [ 29.762725822784809, -1.432183793103448 ], [ 29.762456329113924, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11103, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.432183793103448 ], [ 29.762725822784809, -1.432453275862069 ], [ 29.762995316455697, -1.432453275862069 ], [ 29.762995316455697, -1.432183793103448 ], [ 29.762725822784809, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11104, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.432183793103448 ], [ 29.762995316455697, -1.432453275862069 ], [ 29.763264810126582, -1.432453275862069 ], [ 29.763264810126582, -1.432183793103448 ], [ 29.762995316455697, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11105, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.432183793103448 ], [ 29.763264810126582, -1.432453275862069 ], [ 29.763534303797467, -1.432453275862069 ], [ 29.763534303797467, -1.432183793103448 ], [ 29.763264810126582, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11106, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.432183793103448 ], [ 29.763534303797467, -1.432453275862069 ], [ 29.763803797468356, -1.432453275862069 ], [ 29.763803797468356, -1.432183793103448 ], [ 29.763534303797467, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11107, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.432183793103448 ], [ 29.763803797468356, -1.432453275862069 ], [ 29.76407329113924, -1.432453275862069 ], [ 29.76407329113924, -1.432183793103448 ], [ 29.763803797468356, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11108, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.432183793103448 ], [ 29.76407329113924, -1.432453275862069 ], [ 29.764342784810125, -1.432453275862069 ], [ 29.764342784810125, -1.432183793103448 ], [ 29.76407329113924, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11109, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.432183793103448 ], [ 29.764342784810125, -1.432453275862069 ], [ 29.764612278481014, -1.432453275862069 ], [ 29.764612278481014, -1.432183793103448 ], [ 29.764342784810125, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11110, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.432183793103448 ], [ 29.764612278481014, -1.432453275862069 ], [ 29.764881772151899, -1.432453275862069 ], [ 29.764881772151899, -1.432183793103448 ], [ 29.764612278481014, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11111, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.432183793103448 ], [ 29.764881772151899, -1.432453275862069 ], [ 29.765151265822784, -1.432453275862069 ], [ 29.765151265822784, -1.432183793103448 ], [ 29.764881772151899, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11112, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.432183793103448 ], [ 29.765151265822784, -1.432453275862069 ], [ 29.765420759493672, -1.432453275862069 ], [ 29.765420759493672, -1.432183793103448 ], [ 29.765151265822784, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11113, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.432183793103448 ], [ 29.765420759493672, -1.432453275862069 ], [ 29.765959746835442, -1.432453275862069 ], [ 29.765959746835442, -1.432183793103448 ], [ 29.765420759493672, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11114, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.432183793103448 ], [ 29.765959746835442, -1.432453275862069 ], [ 29.766229240506327, -1.432453275862069 ], [ 29.766229240506327, -1.432183793103448 ], [ 29.765959746835442, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11115, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.432183793103448 ], [ 29.766229240506327, -1.432453275862069 ], [ 29.766498734177215, -1.432453275862069 ], [ 29.766498734177215, -1.432183793103448 ], [ 29.766229240506327, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11116, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.432183793103448 ], [ 29.766498734177215, -1.432453275862069 ], [ 29.7667682278481, -1.432453275862069 ], [ 29.7667682278481, -1.432183793103448 ], [ 29.766498734177215, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11117, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.432183793103448 ], [ 29.7667682278481, -1.432453275862069 ], [ 29.767037721518985, -1.432453275862069 ], [ 29.767037721518985, -1.432183793103448 ], [ 29.7667682278481, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11118, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.432183793103448 ], [ 29.767037721518985, -1.432453275862069 ], [ 29.767307215189874, -1.432453275862069 ], [ 29.767307215189874, -1.432183793103448 ], [ 29.767037721518985, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11119, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.432183793103448 ], [ 29.767307215189874, -1.432453275862069 ], [ 29.767576708860759, -1.432453275862069 ], [ 29.767576708860759, -1.432183793103448 ], [ 29.767307215189874, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11120, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.432183793103448 ], [ 29.767576708860759, -1.432453275862069 ], [ 29.767846202531643, -1.432453275862069 ], [ 29.767846202531643, -1.432183793103448 ], [ 29.767576708860759, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11121, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.432183793103448 ], [ 29.767846202531643, -1.432453275862069 ], [ 29.768115696202532, -1.432453275862069 ], [ 29.768115696202532, -1.432183793103448 ], [ 29.767846202531643, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11122, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.432183793103448 ], [ 29.768115696202532, -1.432453275862069 ], [ 29.768385189873417, -1.432453275862069 ], [ 29.768385189873417, -1.432183793103448 ], [ 29.768115696202532, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11123, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.432183793103448 ], [ 29.768385189873417, -1.432453275862069 ], [ 29.768654683544302, -1.432453275862069 ], [ 29.768654683544302, -1.432183793103448 ], [ 29.768385189873417, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11124, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.432183793103448 ], [ 29.768654683544302, -1.432453275862069 ], [ 29.76892417721519, -1.432453275862069 ], [ 29.76892417721519, -1.432183793103448 ], [ 29.768654683544302, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11125, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.432183793103448 ], [ 29.76892417721519, -1.432453275862069 ], [ 29.769193670886075, -1.432453275862069 ], [ 29.769193670886075, -1.432183793103448 ], [ 29.76892417721519, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11126, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.432183793103448 ], [ 29.769193670886075, -1.432453275862069 ], [ 29.76946316455696, -1.432453275862069 ], [ 29.76946316455696, -1.432183793103448 ], [ 29.769193670886075, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11127, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.432183793103448 ], [ 29.76946316455696, -1.432453275862069 ], [ 29.769732658227849, -1.432453275862069 ], [ 29.769732658227849, -1.432183793103448 ], [ 29.76946316455696, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11128, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.432183793103448 ], [ 29.769732658227849, -1.432453275862069 ], [ 29.770002151898733, -1.432453275862069 ], [ 29.770002151898733, -1.432183793103448 ], [ 29.769732658227849, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11129, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.432183793103448 ], [ 29.770002151898733, -1.432453275862069 ], [ 29.770271645569618, -1.432453275862069 ], [ 29.770271645569618, -1.432183793103448 ], [ 29.770002151898733, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11130, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.432183793103448 ], [ 29.770271645569618, -1.432453275862069 ], [ 29.770541139240507, -1.432453275862069 ], [ 29.770541139240507, -1.432183793103448 ], [ 29.770271645569618, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11131, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.432183793103448 ], [ 29.770541139240507, -1.432453275862069 ], [ 29.770810632911392, -1.432453275862069 ], [ 29.770810632911392, -1.432183793103448 ], [ 29.770541139240507, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11132, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.432183793103448 ], [ 29.770810632911392, -1.432453275862069 ], [ 29.771080126582277, -1.432453275862069 ], [ 29.771080126582277, -1.432183793103448 ], [ 29.770810632911392, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11133, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.432183793103448 ], [ 29.771080126582277, -1.432453275862069 ], [ 29.771349620253165, -1.432453275862069 ], [ 29.771349620253165, -1.432183793103448 ], [ 29.771080126582277, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11134, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.432183793103448 ], [ 29.771349620253165, -1.432453275862069 ], [ 29.77161911392405, -1.432453275862069 ], [ 29.77161911392405, -1.432183793103448 ], [ 29.771349620253165, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11135, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.432183793103448 ], [ 29.77161911392405, -1.43272275862069 ], [ 29.771888607594935, -1.43272275862069 ], [ 29.771888607594935, -1.432183793103448 ], [ 29.77161911392405, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11136, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.432183793103448 ], [ 29.771888607594935, -1.432453275862069 ], [ 29.772158101265823, -1.432453275862069 ], [ 29.772158101265823, -1.432183793103448 ], [ 29.771888607594935, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11137, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.432183793103448 ], [ 29.772158101265823, -1.432453275862069 ], [ 29.772427594936708, -1.432453275862069 ], [ 29.772427594936708, -1.432183793103448 ], [ 29.772158101265823, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11138, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.432183793103448 ], [ 29.772427594936708, -1.432453275862069 ], [ 29.772697088607593, -1.432453275862069 ], [ 29.772697088607593, -1.432183793103448 ], [ 29.772427594936708, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11139, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.432183793103448 ], [ 29.772966582278482, -1.432453275862069 ], [ 29.773505569620252, -1.432453275862069 ], [ 29.773505569620252, -1.432183793103448 ], [ 29.772966582278482, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11140, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.432183793103448 ], [ 29.773505569620252, -1.432453275862069 ], [ 29.77377506329114, -1.432453275862069 ], [ 29.77377506329114, -1.432183793103448 ], [ 29.773505569620252, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11141, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.432183793103448 ], [ 29.77377506329114, -1.432453275862069 ], [ 29.774044556962025, -1.432453275862069 ], [ 29.774044556962025, -1.432183793103448 ], [ 29.77377506329114, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11142, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.432183793103448 ], [ 29.774044556962025, -1.432453275862069 ], [ 29.77431405063291, -1.432453275862069 ], [ 29.77431405063291, -1.432183793103448 ], [ 29.774044556962025, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11143, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.432183793103448 ], [ 29.77431405063291, -1.432453275862069 ], [ 29.774853037974683, -1.432453275862069 ], [ 29.774853037974683, -1.432183793103448 ], [ 29.77431405063291, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11144, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.432183793103448 ], [ 29.774853037974683, -1.432453275862069 ], [ 29.775122531645568, -1.432453275862069 ], [ 29.775122531645568, -1.432183793103448 ], [ 29.774853037974683, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11145, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.432183793103448 ], [ 29.775122531645568, -1.432453275862069 ], [ 29.775392025316457, -1.432453275862069 ], [ 29.775392025316457, -1.432183793103448 ], [ 29.775122531645568, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11146, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.432183793103448 ], [ 29.775392025316457, -1.432453275862069 ], [ 29.775661518987341, -1.432453275862069 ], [ 29.775661518987341, -1.432183793103448 ], [ 29.775392025316457, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11147, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.432183793103448 ], [ 29.775661518987341, -1.432453275862069 ], [ 29.775931012658226, -1.432453275862069 ], [ 29.775931012658226, -1.432183793103448 ], [ 29.775661518987341, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11148, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.432183793103448 ], [ 29.775931012658226, -1.432453275862069 ], [ 29.776200506329115, -1.432453275862069 ], [ 29.776200506329115, -1.432183793103448 ], [ 29.775931012658226, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11149, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.432183793103448 ], [ 29.776200506329115, -1.432453275862069 ], [ 29.77647, -1.432453275862069 ], [ 29.77647, -1.432183793103448 ], [ 29.776200506329115, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11150, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.432183793103448 ], [ 29.77647, -1.43272275862069 ], [ 29.776739493670885, -1.43272275862069 ], [ 29.776739493670885, -1.432183793103448 ], [ 29.77647, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11151, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.432183793103448 ], [ 29.777278481012658, -1.43272275862069 ], [ 29.777547974683543, -1.43272275862069 ], [ 29.777547974683543, -1.432183793103448 ], [ 29.777278481012658, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11152, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.432183793103448 ], [ 29.778086962025316, -1.43272275862069 ], [ 29.778356455696201, -1.43272275862069 ], [ 29.778356455696201, -1.432183793103448 ], [ 29.778086962025316, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11153, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.432183793103448 ], [ 29.77862594936709, -1.432453275862069 ], [ 29.778895443037975, -1.432453275862069 ], [ 29.778895443037975, -1.432183793103448 ], [ 29.77862594936709, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11154, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.432183793103448 ], [ 29.778895443037975, -1.43272275862069 ], [ 29.77916493670886, -1.43272275862069 ], [ 29.77916493670886, -1.432183793103448 ], [ 29.778895443037975, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11155, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.432183793103448 ], [ 29.779434430379748, -1.432453275862069 ], [ 29.779703924050633, -1.432453275862069 ], [ 29.779703924050633, -1.432183793103448 ], [ 29.779434430379748, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11156, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.432183793103448 ], [ 29.779703924050633, -1.432453275862069 ], [ 29.779973417721518, -1.432453275862069 ], [ 29.779973417721518, -1.432183793103448 ], [ 29.779703924050633, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11157, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.432183793103448 ], [ 29.780242911392406, -1.432453275862069 ], [ 29.780512405063291, -1.432453275862069 ], [ 29.780512405063291, -1.432183793103448 ], [ 29.780242911392406, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11158, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.432183793103448 ], [ 29.780512405063291, -1.432453275862069 ], [ 29.780781898734176, -1.432453275862069 ], [ 29.780781898734176, -1.432183793103448 ], [ 29.780512405063291, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11159, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.432183793103448 ], [ 29.780781898734176, -1.43272275862069 ], [ 29.781051392405065, -1.43272275862069 ], [ 29.781051392405065, -1.432183793103448 ], [ 29.780781898734176, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11160, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.432183793103448 ], [ 29.781051392405065, -1.432453275862069 ], [ 29.78132088607595, -1.432453275862069 ], [ 29.78132088607595, -1.432183793103448 ], [ 29.781051392405065, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11161, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.432183793103448 ], [ 29.78132088607595, -1.432453275862069 ], [ 29.781590379746834, -1.432453275862069 ], [ 29.781590379746834, -1.432183793103448 ], [ 29.78132088607595, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11162, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.432183793103448 ], [ 29.781590379746834, -1.432453275862069 ], [ 29.781859873417723, -1.432453275862069 ], [ 29.781859873417723, -1.432183793103448 ], [ 29.781590379746834, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11163, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.432183793103448 ], [ 29.781859873417723, -1.43272275862069 ], [ 29.782129367088608, -1.43272275862069 ], [ 29.782129367088608, -1.432183793103448 ], [ 29.781859873417723, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11164, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.432183793103448 ], [ 29.782129367088608, -1.432453275862069 ], [ 29.782398860759493, -1.432453275862069 ], [ 29.782398860759493, -1.432183793103448 ], [ 29.782129367088608, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11165, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.432183793103448 ], [ 29.782398860759493, -1.432453275862069 ], [ 29.782668354430381, -1.432453275862069 ], [ 29.782668354430381, -1.432183793103448 ], [ 29.782398860759493, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11166, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.432183793103448 ], [ 29.782668354430381, -1.43272275862069 ], [ 29.782937848101266, -1.43272275862069 ], [ 29.782937848101266, -1.432183793103448 ], [ 29.782668354430381, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11167, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.432183793103448 ], [ 29.782937848101266, -1.43272275862069 ], [ 29.783207341772151, -1.43272275862069 ], [ 29.783207341772151, -1.432183793103448 ], [ 29.782937848101266, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11168, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.432183793103448 ], [ 29.783207341772151, -1.432453275862069 ], [ 29.783476835443039, -1.432453275862069 ], [ 29.783476835443039, -1.432183793103448 ], [ 29.783207341772151, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11169, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.432183793103448 ], [ 29.783476835443039, -1.43272275862069 ], [ 29.784015822784809, -1.43272275862069 ], [ 29.784015822784809, -1.432183793103448 ], [ 29.783476835443039, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11170, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.432183793103448 ], [ 29.784554810126583, -1.43272275862069 ], [ 29.784824303797468, -1.43272275862069 ], [ 29.784824303797468, -1.432183793103448 ], [ 29.784554810126583, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11171, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.432183793103448 ], [ 29.785363291139241, -1.432453275862069 ], [ 29.785632784810126, -1.432453275862069 ], [ 29.785632784810126, -1.432183793103448 ], [ 29.785363291139241, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11172, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.432183793103448 ], [ 29.785632784810126, -1.432453275862069 ], [ 29.785902278481014, -1.432453275862069 ], [ 29.785902278481014, -1.432183793103448 ], [ 29.785632784810126, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11173, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.432183793103448 ], [ 29.785902278481014, -1.432453275862069 ], [ 29.786171772151899, -1.432453275862069 ], [ 29.786171772151899, -1.432183793103448 ], [ 29.785902278481014, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11174, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.432183793103448 ], [ 29.786171772151899, -1.432453275862069 ], [ 29.786710759493673, -1.432453275862069 ], [ 29.786710759493673, -1.432183793103448 ], [ 29.786171772151899, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11175, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.432183793103448 ], [ 29.788058227848101, -1.43272275862069 ], [ 29.788327721518986, -1.43272275862069 ], [ 29.788327721518986, -1.432183793103448 ], [ 29.788058227848101, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11176, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.432183793103448 ], [ 29.788327721518986, -1.432453275862069 ], [ 29.788597215189874, -1.432453275862069 ], [ 29.788597215189874, -1.432183793103448 ], [ 29.788327721518986, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11177, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.432183793103448 ], [ 29.788597215189874, -1.432453275862069 ], [ 29.788866708860759, -1.432453275862069 ], [ 29.788866708860759, -1.432183793103448 ], [ 29.788597215189874, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11178, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.432183793103448 ], [ 29.788866708860759, -1.432453275862069 ], [ 29.789136202531644, -1.432453275862069 ], [ 29.789136202531644, -1.432183793103448 ], [ 29.788866708860759, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11179, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.432183793103448 ], [ 29.789675189873417, -1.43272275862069 ], [ 29.789944683544302, -1.43272275862069 ], [ 29.789944683544302, -1.432183793103448 ], [ 29.789675189873417, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11180, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.432183793103448 ], [ 29.789944683544302, -1.432453275862069 ], [ 29.790214177215191, -1.432453275862069 ], [ 29.790214177215191, -1.432183793103448 ], [ 29.789944683544302, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11181, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.432183793103448 ], [ 29.790214177215191, -1.432453275862069 ], [ 29.790483670886076, -1.432453275862069 ], [ 29.790483670886076, -1.432183793103448 ], [ 29.790214177215191, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11182, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.432183793103448 ], [ 29.790483670886076, -1.432453275862069 ], [ 29.790753164556961, -1.432453275862069 ], [ 29.790753164556961, -1.432183793103448 ], [ 29.790483670886076, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11183, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.432183793103448 ], [ 29.790753164556961, -1.432453275862069 ], [ 29.791022658227849, -1.432453275862069 ], [ 29.791022658227849, -1.432183793103448 ], [ 29.790753164556961, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11184, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.432183793103448 ], [ 29.791022658227849, -1.432453275862069 ], [ 29.791292151898734, -1.432453275862069 ], [ 29.791292151898734, -1.432183793103448 ], [ 29.791022658227849, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11185, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.432183793103448 ], [ 29.791292151898734, -1.432453275862069 ], [ 29.791561645569619, -1.432453275862069 ], [ 29.791561645569619, -1.432183793103448 ], [ 29.791292151898734, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11186, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.432183793103448 ], [ 29.791561645569619, -1.432453275862069 ], [ 29.792100632911392, -1.432453275862069 ], [ 29.792100632911392, -1.432183793103448 ], [ 29.791561645569619, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11187, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.432183793103448 ], [ 29.792100632911392, -1.432453275862069 ], [ 29.792370126582277, -1.432453275862069 ], [ 29.792370126582277, -1.432183793103448 ], [ 29.792100632911392, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11188, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.432183793103448 ], [ 29.792370126582277, -1.432453275862069 ], [ 29.792909113924051, -1.432453275862069 ], [ 29.792909113924051, -1.432183793103448 ], [ 29.792370126582277, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11189, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.432183793103448 ], [ 29.792909113924051, -1.432453275862069 ], [ 29.793178607594935, -1.432453275862069 ], [ 29.793178607594935, -1.432183793103448 ], [ 29.792909113924051, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11190, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.432183793103448 ], [ 29.793178607594935, -1.432453275862069 ], [ 29.793448101265824, -1.432453275862069 ], [ 29.793448101265824, -1.432183793103448 ], [ 29.793178607594935, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11191, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.432183793103448 ], [ 29.793448101265824, -1.432453275862069 ], [ 29.793717594936709, -1.432453275862069 ], [ 29.793717594936709, -1.432183793103448 ], [ 29.793448101265824, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11192, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.432183793103448 ], [ 29.793717594936709, -1.432453275862069 ], [ 29.793987088607594, -1.432453275862069 ], [ 29.793987088607594, -1.432183793103448 ], [ 29.793717594936709, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11193, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.432183793103448 ], [ 29.793987088607594, -1.432453275862069 ], [ 29.794256582278482, -1.432453275862069 ], [ 29.794256582278482, -1.432183793103448 ], [ 29.793987088607594, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11194, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.432183793103448 ], [ 29.794256582278482, -1.432453275862069 ], [ 29.794526075949367, -1.432453275862069 ], [ 29.794526075949367, -1.432183793103448 ], [ 29.794256582278482, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11195, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.432183793103448 ], [ 29.794526075949367, -1.432453275862069 ], [ 29.794795569620252, -1.432453275862069 ], [ 29.794795569620252, -1.432183793103448 ], [ 29.794526075949367, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11196, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.432183793103448 ], [ 29.794795569620252, -1.432453275862069 ], [ 29.79506506329114, -1.432453275862069 ], [ 29.79506506329114, -1.432183793103448 ], [ 29.794795569620252, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11197, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.432183793103448 ], [ 29.79506506329114, -1.432453275862069 ], [ 29.795334556962025, -1.432453275862069 ], [ 29.795334556962025, -1.432183793103448 ], [ 29.79506506329114, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11198, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.432183793103448 ], [ 29.795334556962025, -1.432453275862069 ], [ 29.79560405063291, -1.432453275862069 ], [ 29.79560405063291, -1.432183793103448 ], [ 29.795334556962025, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11199, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.432183793103448 ], [ 29.800993924050633, -1.432453275862069 ], [ 29.801263417721518, -1.432453275862069 ], [ 29.801263417721518, -1.432183793103448 ], [ 29.800993924050633, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11200, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.432183793103448 ], [ 29.801263417721518, -1.432453275862069 ], [ 29.801532911392407, -1.432453275862069 ], [ 29.801532911392407, -1.432183793103448 ], [ 29.801263417721518, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11201, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.432183793103448 ], [ 29.801532911392407, -1.432453275862069 ], [ 29.802071898734177, -1.432453275862069 ], [ 29.802071898734177, -1.432183793103448 ], [ 29.801532911392407, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11202, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.432183793103448 ], [ 29.802071898734177, -1.432453275862069 ], [ 29.802341392405065, -1.432453275862069 ], [ 29.802341392405065, -1.432183793103448 ], [ 29.802071898734177, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11203, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.432183793103448 ], [ 29.802341392405065, -1.432453275862069 ], [ 29.80261088607595, -1.432453275862069 ], [ 29.80261088607595, -1.432183793103448 ], [ 29.802341392405065, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11204, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.432183793103448 ], [ 29.80261088607595, -1.432453275862069 ], [ 29.802880379746835, -1.432453275862069 ], [ 29.802880379746835, -1.432183793103448 ], [ 29.80261088607595, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11205, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.432183793103448 ], [ 29.802880379746835, -1.432453275862069 ], [ 29.803149873417723, -1.432453275862069 ], [ 29.803149873417723, -1.432183793103448 ], [ 29.802880379746835, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11206, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.432183793103448 ], [ 29.803149873417723, -1.432453275862069 ], [ 29.803419367088608, -1.432453275862069 ], [ 29.803419367088608, -1.432183793103448 ], [ 29.803149873417723, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11207, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.432183793103448 ], [ 29.803419367088608, -1.432453275862069 ], [ 29.803688860759493, -1.432453275862069 ], [ 29.803688860759493, -1.432183793103448 ], [ 29.803419367088608, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11208, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.432183793103448 ], [ 29.803688860759493, -1.432453275862069 ], [ 29.803958354430382, -1.432453275862069 ], [ 29.803958354430382, -1.432183793103448 ], [ 29.803688860759493, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11209, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.432183793103448 ], [ 29.803958354430382, -1.432453275862069 ], [ 29.804227848101267, -1.432453275862069 ], [ 29.804227848101267, -1.432183793103448 ], [ 29.803958354430382, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11210, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.431375344827586 ], [ 29.805844810126583, -1.431375344827586 ], [ 29.805844810126583, -1.432453275862069 ], [ 29.804227848101267, -1.432453275862069 ], [ 29.804227848101267, -1.432183793103448 ], [ 29.805575316455698, -1.432183793103448 ], [ 29.805575316455698, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11211, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.432453275862069 ], [ 29.755988481012658, -1.43272275862069 ], [ 29.756257974683542, -1.43272275862069 ], [ 29.756257974683542, -1.432453275862069 ], [ 29.755988481012658, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11212, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.432453275862069 ], [ 29.756257974683542, -1.43272275862069 ], [ 29.756796962025316, -1.43272275862069 ], [ 29.756796962025316, -1.432453275862069 ], [ 29.756257974683542, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11213, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.432453275862069 ], [ 29.756796962025316, -1.43272275862069 ], [ 29.757066455696201, -1.43272275862069 ], [ 29.757066455696201, -1.432453275862069 ], [ 29.756796962025316, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11214, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.432453275862069 ], [ 29.757066455696201, -1.43272275862069 ], [ 29.757335949367089, -1.43272275862069 ], [ 29.757335949367089, -1.432453275862069 ], [ 29.757066455696201, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11215, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.432453275862069 ], [ 29.757874936708859, -1.43272275862069 ], [ 29.758144430379748, -1.43272275862069 ], [ 29.758144430379748, -1.432453275862069 ], [ 29.757874936708859, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11216, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.432453275862069 ], [ 29.758144430379748, -1.43272275862069 ], [ 29.758413924050632, -1.43272275862069 ], [ 29.758413924050632, -1.432453275862069 ], [ 29.758144430379748, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11217, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.432453275862069 ], [ 29.758413924050632, -1.43272275862069 ], [ 29.760030886075949, -1.43272275862069 ], [ 29.760030886075949, -1.432453275862069 ], [ 29.758413924050632, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11218, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.432453275862069 ], [ 29.760030886075949, -1.43272275862069 ], [ 29.760300379746834, -1.43272275862069 ], [ 29.760300379746834, -1.432453275862069 ], [ 29.760030886075949, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11219, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.432453275862069 ], [ 29.760300379746834, -1.43272275862069 ], [ 29.760569873417722, -1.43272275862069 ], [ 29.760569873417722, -1.432453275862069 ], [ 29.760300379746834, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11220, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.432453275862069 ], [ 29.760569873417722, -1.43272275862069 ], [ 29.761647848101266, -1.43272275862069 ], [ 29.761647848101266, -1.432453275862069 ], [ 29.760569873417722, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11221, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.432453275862069 ], [ 29.761647848101266, -1.43272275862069 ], [ 29.762186835443039, -1.43272275862069 ], [ 29.762186835443039, -1.432453275862069 ], [ 29.761647848101266, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11222, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.432453275862069 ], [ 29.762186835443039, -1.43272275862069 ], [ 29.762456329113924, -1.43272275862069 ], [ 29.762456329113924, -1.432453275862069 ], [ 29.762186835443039, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11223, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.432453275862069 ], [ 29.762456329113924, -1.43272275862069 ], [ 29.762725822784809, -1.43272275862069 ], [ 29.762725822784809, -1.432453275862069 ], [ 29.762456329113924, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11224, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.432453275862069 ], [ 29.762725822784809, -1.43272275862069 ], [ 29.762995316455697, -1.43272275862069 ], [ 29.762995316455697, -1.432453275862069 ], [ 29.762725822784809, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11225, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.432453275862069 ], [ 29.762995316455697, -1.43272275862069 ], [ 29.763264810126582, -1.43272275862069 ], [ 29.763264810126582, -1.432453275862069 ], [ 29.762995316455697, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11226, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.432453275862069 ], [ 29.763264810126582, -1.43272275862069 ], [ 29.763534303797467, -1.43272275862069 ], [ 29.763534303797467, -1.432453275862069 ], [ 29.763264810126582, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11227, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.432453275862069 ], [ 29.763534303797467, -1.43272275862069 ], [ 29.763803797468356, -1.43272275862069 ], [ 29.763803797468356, -1.432453275862069 ], [ 29.763534303797467, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11228, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.432453275862069 ], [ 29.763803797468356, -1.43272275862069 ], [ 29.76407329113924, -1.43272275862069 ], [ 29.76407329113924, -1.432453275862069 ], [ 29.763803797468356, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11229, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.432453275862069 ], [ 29.76407329113924, -1.43272275862069 ], [ 29.764342784810125, -1.43272275862069 ], [ 29.764342784810125, -1.432453275862069 ], [ 29.76407329113924, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11230, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.432453275862069 ], [ 29.764342784810125, -1.43272275862069 ], [ 29.764612278481014, -1.43272275862069 ], [ 29.764612278481014, -1.432453275862069 ], [ 29.764342784810125, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11231, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.432453275862069 ], [ 29.764612278481014, -1.43272275862069 ], [ 29.764881772151899, -1.43272275862069 ], [ 29.764881772151899, -1.432453275862069 ], [ 29.764612278481014, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11232, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.432453275862069 ], [ 29.764881772151899, -1.43272275862069 ], [ 29.765151265822784, -1.43272275862069 ], [ 29.765151265822784, -1.432453275862069 ], [ 29.764881772151899, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11233, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.432453275862069 ], [ 29.765151265822784, -1.43272275862069 ], [ 29.765420759493672, -1.43272275862069 ], [ 29.765420759493672, -1.432453275862069 ], [ 29.765151265822784, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11234, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.432453275862069 ], [ 29.765420759493672, -1.43272275862069 ], [ 29.765959746835442, -1.43272275862069 ], [ 29.765959746835442, -1.432453275862069 ], [ 29.765420759493672, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11235, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.432453275862069 ], [ 29.765959746835442, -1.43272275862069 ], [ 29.766229240506327, -1.43272275862069 ], [ 29.766229240506327, -1.432453275862069 ], [ 29.765959746835442, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11236, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.432453275862069 ], [ 29.766229240506327, -1.43272275862069 ], [ 29.766498734177215, -1.43272275862069 ], [ 29.766498734177215, -1.432453275862069 ], [ 29.766229240506327, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11237, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.432453275862069 ], [ 29.766498734177215, -1.43272275862069 ], [ 29.7667682278481, -1.43272275862069 ], [ 29.7667682278481, -1.432453275862069 ], [ 29.766498734177215, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11238, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.432453275862069 ], [ 29.7667682278481, -1.43272275862069 ], [ 29.767037721518985, -1.43272275862069 ], [ 29.767037721518985, -1.432453275862069 ], [ 29.7667682278481, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11239, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.432453275862069 ], [ 29.767037721518985, -1.43272275862069 ], [ 29.767307215189874, -1.43272275862069 ], [ 29.767307215189874, -1.432453275862069 ], [ 29.767037721518985, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11240, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.432453275862069 ], [ 29.767307215189874, -1.43272275862069 ], [ 29.767576708860759, -1.43272275862069 ], [ 29.767576708860759, -1.432453275862069 ], [ 29.767307215189874, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11241, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.432453275862069 ], [ 29.767576708860759, -1.43272275862069 ], [ 29.767846202531643, -1.43272275862069 ], [ 29.767846202531643, -1.432453275862069 ], [ 29.767576708860759, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11242, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.432453275862069 ], [ 29.767846202531643, -1.43272275862069 ], [ 29.768115696202532, -1.43272275862069 ], [ 29.768115696202532, -1.432453275862069 ], [ 29.767846202531643, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11243, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.432453275862069 ], [ 29.768115696202532, -1.43272275862069 ], [ 29.768385189873417, -1.43272275862069 ], [ 29.768385189873417, -1.432453275862069 ], [ 29.768115696202532, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11244, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.432453275862069 ], [ 29.768385189873417, -1.43272275862069 ], [ 29.768654683544302, -1.43272275862069 ], [ 29.768654683544302, -1.432453275862069 ], [ 29.768385189873417, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11245, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.432453275862069 ], [ 29.768654683544302, -1.43272275862069 ], [ 29.76892417721519, -1.43272275862069 ], [ 29.76892417721519, -1.432453275862069 ], [ 29.768654683544302, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11246, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.432453275862069 ], [ 29.76892417721519, -1.43272275862069 ], [ 29.769193670886075, -1.43272275862069 ], [ 29.769193670886075, -1.432453275862069 ], [ 29.76892417721519, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11247, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.432453275862069 ], [ 29.769193670886075, -1.43272275862069 ], [ 29.76946316455696, -1.43272275862069 ], [ 29.76946316455696, -1.432453275862069 ], [ 29.769193670886075, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11248, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.432453275862069 ], [ 29.76946316455696, -1.43272275862069 ], [ 29.769732658227849, -1.43272275862069 ], [ 29.769732658227849, -1.432453275862069 ], [ 29.76946316455696, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11249, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.432453275862069 ], [ 29.769732658227849, -1.43272275862069 ], [ 29.770002151898733, -1.43272275862069 ], [ 29.770002151898733, -1.432453275862069 ], [ 29.769732658227849, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11250, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.432453275862069 ], [ 29.770002151898733, -1.43272275862069 ], [ 29.770271645569618, -1.43272275862069 ], [ 29.770271645569618, -1.432453275862069 ], [ 29.770002151898733, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11251, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.432453275862069 ], [ 29.770271645569618, -1.43272275862069 ], [ 29.770541139240507, -1.43272275862069 ], [ 29.770541139240507, -1.432453275862069 ], [ 29.770271645569618, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11252, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.432453275862069 ], [ 29.770541139240507, -1.43299224137931 ], [ 29.770810632911392, -1.43299224137931 ], [ 29.770810632911392, -1.432453275862069 ], [ 29.770541139240507, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11253, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.432453275862069 ], [ 29.770810632911392, -1.43272275862069 ], [ 29.771080126582277, -1.43272275862069 ], [ 29.771080126582277, -1.432453275862069 ], [ 29.770810632911392, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11254, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.432453275862069 ], [ 29.771080126582277, -1.43272275862069 ], [ 29.771349620253165, -1.43272275862069 ], [ 29.771349620253165, -1.432453275862069 ], [ 29.771080126582277, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11255, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.432453275862069 ], [ 29.771349620253165, -1.43272275862069 ], [ 29.77161911392405, -1.43272275862069 ], [ 29.77161911392405, -1.432453275862069 ], [ 29.771349620253165, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11256, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.432453275862069 ], [ 29.771888607594935, -1.43272275862069 ], [ 29.772158101265823, -1.43272275862069 ], [ 29.772158101265823, -1.432453275862069 ], [ 29.771888607594935, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11257, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.432453275862069 ], [ 29.772158101265823, -1.43272275862069 ], [ 29.772427594936708, -1.43272275862069 ], [ 29.772427594936708, -1.432453275862069 ], [ 29.772158101265823, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11258, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.432453275862069 ], [ 29.772427594936708, -1.43299224137931 ], [ 29.772697088607593, -1.43299224137931 ], [ 29.772697088607593, -1.432453275862069 ], [ 29.772427594936708, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11259, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.432453275862069 ], [ 29.772697088607593, -1.43272275862069 ], [ 29.772966582278482, -1.43272275862069 ], [ 29.772966582278482, -1.432453275862069 ], [ 29.772697088607593, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11260, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.432453275862069 ], [ 29.772966582278482, -1.43272275862069 ], [ 29.773236075949367, -1.43272275862069 ], [ 29.773236075949367, -1.432453275862069 ], [ 29.772966582278482, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11261, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.432453275862069 ], [ 29.773236075949367, -1.43272275862069 ], [ 29.773505569620252, -1.43272275862069 ], [ 29.773505569620252, -1.432453275862069 ], [ 29.773236075949367, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11262, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.432453275862069 ], [ 29.773505569620252, -1.43272275862069 ], [ 29.77377506329114, -1.43272275862069 ], [ 29.77377506329114, -1.432453275862069 ], [ 29.773505569620252, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11263, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.432453275862069 ], [ 29.77377506329114, -1.43272275862069 ], [ 29.774044556962025, -1.43272275862069 ], [ 29.774044556962025, -1.432453275862069 ], [ 29.77377506329114, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11264, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.432453275862069 ], [ 29.774044556962025, -1.43272275862069 ], [ 29.77431405063291, -1.43272275862069 ], [ 29.77431405063291, -1.432453275862069 ], [ 29.774044556962025, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11265, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.432453275862069 ], [ 29.77431405063291, -1.43272275862069 ], [ 29.774853037974683, -1.43272275862069 ], [ 29.774853037974683, -1.432453275862069 ], [ 29.77431405063291, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11266, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.432453275862069 ], [ 29.774853037974683, -1.43272275862069 ], [ 29.775122531645568, -1.43272275862069 ], [ 29.775122531645568, -1.432453275862069 ], [ 29.774853037974683, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11267, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.432453275862069 ], [ 29.775122531645568, -1.43272275862069 ], [ 29.775392025316457, -1.43272275862069 ], [ 29.775392025316457, -1.432453275862069 ], [ 29.775122531645568, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11268, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.432453275862069 ], [ 29.775392025316457, -1.43299224137931 ], [ 29.775661518987341, -1.43299224137931 ], [ 29.775661518987341, -1.432453275862069 ], [ 29.775392025316457, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11269, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.432453275862069 ], [ 29.775661518987341, -1.43272275862069 ], [ 29.775931012658226, -1.43272275862069 ], [ 29.775931012658226, -1.432453275862069 ], [ 29.775661518987341, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11270, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.432453275862069 ], [ 29.775931012658226, -1.43272275862069 ], [ 29.776200506329115, -1.43272275862069 ], [ 29.776200506329115, -1.432453275862069 ], [ 29.775931012658226, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11271, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.432453275862069 ], [ 29.776200506329115, -1.43299224137931 ], [ 29.77647, -1.43299224137931 ], [ 29.77647, -1.432453275862069 ], [ 29.776200506329115, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11272, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.432453275862069 ], [ 29.776739493670885, -1.43272275862069 ], [ 29.777008987341773, -1.43272275862069 ], [ 29.777008987341773, -1.432453275862069 ], [ 29.776739493670885, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11273, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.432453275862069 ], [ 29.777008987341773, -1.43299224137931 ], [ 29.777278481012658, -1.43299224137931 ], [ 29.777278481012658, -1.432453275862069 ], [ 29.777008987341773, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11274, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.432453275862069 ], [ 29.777547974683543, -1.43272275862069 ], [ 29.777817468354431, -1.43272275862069 ], [ 29.777817468354431, -1.432453275862069 ], [ 29.777547974683543, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11275, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.432453275862069 ], [ 29.777817468354431, -1.43299224137931 ], [ 29.778086962025316, -1.43299224137931 ], [ 29.778086962025316, -1.432453275862069 ], [ 29.777817468354431, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11276, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.432453275862069 ], [ 29.778356455696201, -1.43272275862069 ], [ 29.77862594936709, -1.43272275862069 ], [ 29.77862594936709, -1.432453275862069 ], [ 29.778356455696201, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11277, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.432453275862069 ], [ 29.77862594936709, -1.43272275862069 ], [ 29.778895443037975, -1.43272275862069 ], [ 29.778895443037975, -1.432453275862069 ], [ 29.77862594936709, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11278, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.432453275862069 ], [ 29.77916493670886, -1.43272275862069 ], [ 29.779434430379748, -1.43272275862069 ], [ 29.779434430379748, -1.432453275862069 ], [ 29.77916493670886, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11279, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.432453275862069 ], [ 29.779434430379748, -1.43272275862069 ], [ 29.779703924050633, -1.43272275862069 ], [ 29.779703924050633, -1.432453275862069 ], [ 29.779434430379748, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11280, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.432453275862069 ], [ 29.779703924050633, -1.43299224137931 ], [ 29.779973417721518, -1.43299224137931 ], [ 29.779973417721518, -1.432453275862069 ], [ 29.779703924050633, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11281, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.432453275862069 ], [ 29.779973417721518, -1.43272275862069 ], [ 29.780242911392406, -1.43272275862069 ], [ 29.780242911392406, -1.432453275862069 ], [ 29.779973417721518, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11282, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.432453275862069 ], [ 29.780242911392406, -1.43272275862069 ], [ 29.780512405063291, -1.43272275862069 ], [ 29.780512405063291, -1.432453275862069 ], [ 29.780242911392406, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11283, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.432453275862069 ], [ 29.780512405063291, -1.43272275862069 ], [ 29.780781898734176, -1.43272275862069 ], [ 29.780781898734176, -1.432453275862069 ], [ 29.780512405063291, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11284, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.432453275862069 ], [ 29.781051392405065, -1.43272275862069 ], [ 29.78132088607595, -1.43272275862069 ], [ 29.78132088607595, -1.432453275862069 ], [ 29.781051392405065, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11285, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.432453275862069 ], [ 29.78132088607595, -1.43272275862069 ], [ 29.781590379746834, -1.43272275862069 ], [ 29.781590379746834, -1.432453275862069 ], [ 29.78132088607595, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11286, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.432453275862069 ], [ 29.781590379746834, -1.43299224137931 ], [ 29.781859873417723, -1.43299224137931 ], [ 29.781859873417723, -1.432453275862069 ], [ 29.781590379746834, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11287, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.432453275862069 ], [ 29.782129367088608, -1.43272275862069 ], [ 29.782398860759493, -1.43272275862069 ], [ 29.782398860759493, -1.432453275862069 ], [ 29.782129367088608, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11288, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.432453275862069 ], [ 29.782398860759493, -1.43299224137931 ], [ 29.782668354430381, -1.43299224137931 ], [ 29.782668354430381, -1.432453275862069 ], [ 29.782398860759493, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11289, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.432453275862069 ], [ 29.783207341772151, -1.43299224137931 ], [ 29.783476835443039, -1.43299224137931 ], [ 29.783476835443039, -1.432453275862069 ], [ 29.783207341772151, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11290, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.432453275862069 ], [ 29.784015822784809, -1.43272275862069 ], [ 29.784285316455698, -1.43272275862069 ], [ 29.784285316455698, -1.432453275862069 ], [ 29.784015822784809, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11291, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.432453275862069 ], [ 29.784285316455698, -1.43272275862069 ], [ 29.784554810126583, -1.43272275862069 ], [ 29.784554810126583, -1.432453275862069 ], [ 29.784285316455698, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11292, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.432453275862069 ], [ 29.784824303797468, -1.43272275862069 ], [ 29.785093797468356, -1.43272275862069 ], [ 29.785093797468356, -1.432453275862069 ], [ 29.784824303797468, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11293, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.432453275862069 ], [ 29.785093797468356, -1.43272275862069 ], [ 29.785363291139241, -1.43272275862069 ], [ 29.785363291139241, -1.432453275862069 ], [ 29.785093797468356, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11294, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.432453275862069 ], [ 29.785363291139241, -1.43272275862069 ], [ 29.785632784810126, -1.43272275862069 ], [ 29.785632784810126, -1.432453275862069 ], [ 29.785363291139241, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.432453275862069 ], [ 29.785632784810126, -1.43272275862069 ], [ 29.785902278481014, -1.43272275862069 ], [ 29.785902278481014, -1.432453275862069 ], [ 29.785632784810126, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11296, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.432453275862069 ], [ 29.785902278481014, -1.43272275862069 ], [ 29.786171772151899, -1.43272275862069 ], [ 29.786171772151899, -1.432453275862069 ], [ 29.785902278481014, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11297, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.432453275862069 ], [ 29.786171772151899, -1.43272275862069 ], [ 29.786441265822784, -1.43272275862069 ], [ 29.786441265822784, -1.432453275862069 ], [ 29.786171772151899, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11298, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.432453275862069 ], [ 29.786441265822784, -1.43272275862069 ], [ 29.786710759493673, -1.43272275862069 ], [ 29.786710759493673, -1.432453275862069 ], [ 29.786441265822784, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11299, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.432453275862069 ], [ 29.786710759493673, -1.43272275862069 ], [ 29.787249746835442, -1.43272275862069 ], [ 29.787249746835442, -1.432453275862069 ], [ 29.786710759493673, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11300, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.432453275862069 ], [ 29.788327721518986, -1.43272275862069 ], [ 29.788597215189874, -1.43272275862069 ], [ 29.788597215189874, -1.432453275862069 ], [ 29.788327721518986, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11301, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.432453275862069 ], [ 29.788597215189874, -1.43272275862069 ], [ 29.788866708860759, -1.43272275862069 ], [ 29.788866708860759, -1.432453275862069 ], [ 29.788597215189874, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11302, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.432453275862069 ], [ 29.788866708860759, -1.43272275862069 ], [ 29.789136202531644, -1.43272275862069 ], [ 29.789136202531644, -1.432453275862069 ], [ 29.788866708860759, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11303, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.432453275862069 ], [ 29.789136202531644, -1.43272275862069 ], [ 29.789405696202532, -1.43272275862069 ], [ 29.789405696202532, -1.432453275862069 ], [ 29.789136202531644, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11304, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.432453275862069 ], [ 29.789405696202532, -1.43272275862069 ], [ 29.789675189873417, -1.43272275862069 ], [ 29.789675189873417, -1.432453275862069 ], [ 29.789405696202532, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11305, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.432453275862069 ], [ 29.789944683544302, -1.43272275862069 ], [ 29.790214177215191, -1.43272275862069 ], [ 29.790214177215191, -1.432453275862069 ], [ 29.789944683544302, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11306, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.432453275862069 ], [ 29.790214177215191, -1.43272275862069 ], [ 29.790483670886076, -1.43272275862069 ], [ 29.790483670886076, -1.432453275862069 ], [ 29.790214177215191, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11307, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.432453275862069 ], [ 29.790483670886076, -1.43272275862069 ], [ 29.790753164556961, -1.43272275862069 ], [ 29.790753164556961, -1.432453275862069 ], [ 29.790483670886076, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11308, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.432453275862069 ], [ 29.790753164556961, -1.43299224137931 ], [ 29.791022658227849, -1.43299224137931 ], [ 29.791022658227849, -1.432453275862069 ], [ 29.790753164556961, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11309, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.432453275862069 ], [ 29.791022658227849, -1.43272275862069 ], [ 29.791292151898734, -1.43272275862069 ], [ 29.791292151898734, -1.432453275862069 ], [ 29.791022658227849, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11310, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.432453275862069 ], [ 29.791292151898734, -1.43272275862069 ], [ 29.791561645569619, -1.43272275862069 ], [ 29.791561645569619, -1.432453275862069 ], [ 29.791292151898734, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11311, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.432453275862069 ], [ 29.791561645569619, -1.43272275862069 ], [ 29.792100632911392, -1.43272275862069 ], [ 29.792100632911392, -1.432453275862069 ], [ 29.791561645569619, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11312, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.432453275862069 ], [ 29.792100632911392, -1.43272275862069 ], [ 29.792370126582277, -1.43272275862069 ], [ 29.792370126582277, -1.432453275862069 ], [ 29.792100632911392, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11313, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.432453275862069 ], [ 29.792370126582277, -1.43272275862069 ], [ 29.792909113924051, -1.43272275862069 ], [ 29.792909113924051, -1.432453275862069 ], [ 29.792370126582277, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11314, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.432453275862069 ], [ 29.792909113924051, -1.43272275862069 ], [ 29.793178607594935, -1.43272275862069 ], [ 29.793178607594935, -1.432453275862069 ], [ 29.792909113924051, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11315, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.432453275862069 ], [ 29.793178607594935, -1.43272275862069 ], [ 29.793448101265824, -1.43272275862069 ], [ 29.793448101265824, -1.432453275862069 ], [ 29.793178607594935, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11316, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.432453275862069 ], [ 29.793448101265824, -1.43272275862069 ], [ 29.793717594936709, -1.43272275862069 ], [ 29.793717594936709, -1.432453275862069 ], [ 29.793448101265824, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11317, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.432453275862069 ], [ 29.793717594936709, -1.43272275862069 ], [ 29.793987088607594, -1.43272275862069 ], [ 29.793987088607594, -1.432453275862069 ], [ 29.793717594936709, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11318, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.432453275862069 ], [ 29.793987088607594, -1.43272275862069 ], [ 29.794256582278482, -1.43272275862069 ], [ 29.794256582278482, -1.432453275862069 ], [ 29.793987088607594, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11319, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.432453275862069 ], [ 29.794256582278482, -1.43272275862069 ], [ 29.794526075949367, -1.43272275862069 ], [ 29.794526075949367, -1.432453275862069 ], [ 29.794256582278482, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11320, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.432453275862069 ], [ 29.794526075949367, -1.43272275862069 ], [ 29.794795569620252, -1.43272275862069 ], [ 29.794795569620252, -1.432453275862069 ], [ 29.794526075949367, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11321, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.432453275862069 ], [ 29.794795569620252, -1.43272275862069 ], [ 29.79506506329114, -1.43272275862069 ], [ 29.79506506329114, -1.432453275862069 ], [ 29.794795569620252, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11322, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.432453275862069 ], [ 29.79506506329114, -1.43272275862069 ], [ 29.795334556962025, -1.43272275862069 ], [ 29.795334556962025, -1.432453275862069 ], [ 29.79506506329114, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11323, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.432453275862069 ], [ 29.795334556962025, -1.43272275862069 ], [ 29.79560405063291, -1.43272275862069 ], [ 29.79560405063291, -1.432453275862069 ], [ 29.795334556962025, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11324, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.432453275862069 ], [ 29.79560405063291, -1.43272275862069 ], [ 29.795873544303799, -1.43272275862069 ], [ 29.795873544303799, -1.432453275862069 ], [ 29.79560405063291, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11325, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.432453275862069 ], [ 29.795873544303799, -1.43272275862069 ], [ 29.796143037974684, -1.43272275862069 ], [ 29.796143037974684, -1.432453275862069 ], [ 29.795873544303799, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11326, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.432453275862069 ], [ 29.800724430379748, -1.43299224137931 ], [ 29.800993924050633, -1.43299224137931 ], [ 29.800993924050633, -1.432453275862069 ], [ 29.800724430379748, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11327, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.432453275862069 ], [ 29.800993924050633, -1.43272275862069 ], [ 29.801263417721518, -1.43272275862069 ], [ 29.801263417721518, -1.432453275862069 ], [ 29.800993924050633, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11328, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.432453275862069 ], [ 29.801263417721518, -1.43272275862069 ], [ 29.801532911392407, -1.43272275862069 ], [ 29.801532911392407, -1.432453275862069 ], [ 29.801263417721518, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11329, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.432453275862069 ], [ 29.801532911392407, -1.43272275862069 ], [ 29.802071898734177, -1.43272275862069 ], [ 29.802071898734177, -1.432453275862069 ], [ 29.801532911392407, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11330, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.432453275862069 ], [ 29.802071898734177, -1.43272275862069 ], [ 29.802341392405065, -1.43272275862069 ], [ 29.802341392405065, -1.432453275862069 ], [ 29.802071898734177, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11331, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.432453275862069 ], [ 29.802341392405065, -1.43272275862069 ], [ 29.80261088607595, -1.43272275862069 ], [ 29.80261088607595, -1.432453275862069 ], [ 29.802341392405065, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11332, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.432453275862069 ], [ 29.80261088607595, -1.43272275862069 ], [ 29.802880379746835, -1.43272275862069 ], [ 29.802880379746835, -1.432453275862069 ], [ 29.80261088607595, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11333, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.432453275862069 ], [ 29.802880379746835, -1.43272275862069 ], [ 29.803149873417723, -1.43272275862069 ], [ 29.803149873417723, -1.432453275862069 ], [ 29.802880379746835, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11334, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.432453275862069 ], [ 29.803149873417723, -1.43272275862069 ], [ 29.803419367088608, -1.43272275862069 ], [ 29.803419367088608, -1.432453275862069 ], [ 29.803149873417723, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11335, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.432453275862069 ], [ 29.803419367088608, -1.43272275862069 ], [ 29.803688860759493, -1.43272275862069 ], [ 29.803688860759493, -1.432453275862069 ], [ 29.803419367088608, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11336, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.432453275862069 ], [ 29.803688860759493, -1.43272275862069 ], [ 29.803958354430382, -1.43272275862069 ], [ 29.803958354430382, -1.432453275862069 ], [ 29.803688860759493, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11337, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.432453275862069 ], [ 29.803958354430382, -1.43272275862069 ], [ 29.804227848101267, -1.43272275862069 ], [ 29.804227848101267, -1.432453275862069 ], [ 29.803958354430382, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11338, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.431375344827586 ], [ 29.806114303797468, -1.431375344827586 ], [ 29.806114303797468, -1.43272275862069 ], [ 29.804227848101267, -1.43272275862069 ], [ 29.804227848101267, -1.432453275862069 ], [ 29.805844810126583, -1.432453275862069 ], [ 29.805844810126583, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11339, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.43272275862069 ], [ 29.756796962025316, -1.43299224137931 ], [ 29.757066455696201, -1.43299224137931 ], [ 29.757066455696201, -1.43272275862069 ], [ 29.756796962025316, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11340, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.43272275862069 ], [ 29.757066455696201, -1.43299224137931 ], [ 29.757335949367089, -1.43299224137931 ], [ 29.757335949367089, -1.43272275862069 ], [ 29.757066455696201, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11341, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.43272275862069 ], [ 29.757335949367089, -1.43299224137931 ], [ 29.757605443037974, -1.43299224137931 ], [ 29.757605443037974, -1.43272275862069 ], [ 29.757335949367089, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11342, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.43272275862069 ], [ 29.757605443037974, -1.43299224137931 ], [ 29.757874936708859, -1.43299224137931 ], [ 29.757874936708859, -1.43272275862069 ], [ 29.757605443037974, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11343, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.43272275862069 ], [ 29.757874936708859, -1.43299224137931 ], [ 29.758144430379748, -1.43299224137931 ], [ 29.758144430379748, -1.43272275862069 ], [ 29.757874936708859, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11344, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.43272275862069 ], [ 29.758144430379748, -1.43299224137931 ], [ 29.758413924050632, -1.43299224137931 ], [ 29.758413924050632, -1.43272275862069 ], [ 29.758144430379748, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11345, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.43272275862069 ], [ 29.758413924050632, -1.43299224137931 ], [ 29.758683417721517, -1.43299224137931 ], [ 29.758683417721517, -1.43272275862069 ], [ 29.758413924050632, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11346, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.43272275862069 ], [ 29.758683417721517, -1.43299224137931 ], [ 29.759761392405064, -1.43299224137931 ], [ 29.759761392405064, -1.43272275862069 ], [ 29.758683417721517, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11347, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.43272275862069 ], [ 29.759761392405064, -1.43299224137931 ], [ 29.760030886075949, -1.43299224137931 ], [ 29.760030886075949, -1.43272275862069 ], [ 29.759761392405064, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11348, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.43272275862069 ], [ 29.760030886075949, -1.43299224137931 ], [ 29.760300379746834, -1.43299224137931 ], [ 29.760300379746834, -1.43272275862069 ], [ 29.760030886075949, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11349, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.43272275862069 ], [ 29.760300379746834, -1.43299224137931 ], [ 29.760569873417722, -1.43299224137931 ], [ 29.760569873417722, -1.43272275862069 ], [ 29.760300379746834, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11350, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.43272275862069 ], [ 29.760569873417722, -1.43299224137931 ], [ 29.761917341772151, -1.43299224137931 ], [ 29.761917341772151, -1.43272275862069 ], [ 29.760569873417722, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11351, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.43272275862069 ], [ 29.761917341772151, -1.43299224137931 ], [ 29.762186835443039, -1.43299224137931 ], [ 29.762186835443039, -1.43272275862069 ], [ 29.761917341772151, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11352, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.43272275862069 ], [ 29.762186835443039, -1.43299224137931 ], [ 29.762456329113924, -1.43299224137931 ], [ 29.762456329113924, -1.43272275862069 ], [ 29.762186835443039, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11353, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.43272275862069 ], [ 29.762456329113924, -1.43299224137931 ], [ 29.762725822784809, -1.43299224137931 ], [ 29.762725822784809, -1.43272275862069 ], [ 29.762456329113924, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11354, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.43272275862069 ], [ 29.762725822784809, -1.43299224137931 ], [ 29.762995316455697, -1.43299224137931 ], [ 29.762995316455697, -1.43272275862069 ], [ 29.762725822784809, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11355, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.43272275862069 ], [ 29.762995316455697, -1.43299224137931 ], [ 29.763264810126582, -1.43299224137931 ], [ 29.763264810126582, -1.43272275862069 ], [ 29.762995316455697, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11356, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.43272275862069 ], [ 29.763264810126582, -1.43299224137931 ], [ 29.763534303797467, -1.43299224137931 ], [ 29.763534303797467, -1.43272275862069 ], [ 29.763264810126582, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11357, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.43272275862069 ], [ 29.763534303797467, -1.43299224137931 ], [ 29.763803797468356, -1.43299224137931 ], [ 29.763803797468356, -1.43272275862069 ], [ 29.763534303797467, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11358, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.43272275862069 ], [ 29.763803797468356, -1.43299224137931 ], [ 29.76407329113924, -1.43299224137931 ], [ 29.76407329113924, -1.43272275862069 ], [ 29.763803797468356, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11359, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.43272275862069 ], [ 29.76407329113924, -1.43299224137931 ], [ 29.764342784810125, -1.43299224137931 ], [ 29.764342784810125, -1.43272275862069 ], [ 29.76407329113924, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11360, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.43272275862069 ], [ 29.764342784810125, -1.43299224137931 ], [ 29.764612278481014, -1.43299224137931 ], [ 29.764612278481014, -1.43272275862069 ], [ 29.764342784810125, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11361, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.43272275862069 ], [ 29.764612278481014, -1.43299224137931 ], [ 29.764881772151899, -1.43299224137931 ], [ 29.764881772151899, -1.43272275862069 ], [ 29.764612278481014, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11362, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.43272275862069 ], [ 29.764881772151899, -1.43299224137931 ], [ 29.765151265822784, -1.43299224137931 ], [ 29.765151265822784, -1.43272275862069 ], [ 29.764881772151899, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11363, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.43272275862069 ], [ 29.765151265822784, -1.43299224137931 ], [ 29.765420759493672, -1.43299224137931 ], [ 29.765420759493672, -1.43272275862069 ], [ 29.765151265822784, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11364, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.43272275862069 ], [ 29.765420759493672, -1.43299224137931 ], [ 29.765959746835442, -1.43299224137931 ], [ 29.765959746835442, -1.43272275862069 ], [ 29.765420759493672, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11365, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.43272275862069 ], [ 29.765959746835442, -1.43299224137931 ], [ 29.766229240506327, -1.43299224137931 ], [ 29.766229240506327, -1.43272275862069 ], [ 29.765959746835442, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11366, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.43272275862069 ], [ 29.766229240506327, -1.43299224137931 ], [ 29.766498734177215, -1.43299224137931 ], [ 29.766498734177215, -1.43272275862069 ], [ 29.766229240506327, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11367, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.43272275862069 ], [ 29.766498734177215, -1.43299224137931 ], [ 29.7667682278481, -1.43299224137931 ], [ 29.7667682278481, -1.43272275862069 ], [ 29.766498734177215, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11368, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.43272275862069 ], [ 29.7667682278481, -1.43299224137931 ], [ 29.767037721518985, -1.43299224137931 ], [ 29.767037721518985, -1.43272275862069 ], [ 29.7667682278481, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11369, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.43272275862069 ], [ 29.767037721518985, -1.43299224137931 ], [ 29.767307215189874, -1.43299224137931 ], [ 29.767307215189874, -1.43272275862069 ], [ 29.767037721518985, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11370, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.43272275862069 ], [ 29.767307215189874, -1.43299224137931 ], [ 29.767576708860759, -1.43299224137931 ], [ 29.767576708860759, -1.43272275862069 ], [ 29.767307215189874, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11371, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.43272275862069 ], [ 29.767576708860759, -1.43299224137931 ], [ 29.767846202531643, -1.43299224137931 ], [ 29.767846202531643, -1.43272275862069 ], [ 29.767576708860759, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11372, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.43272275862069 ], [ 29.767846202531643, -1.43299224137931 ], [ 29.768115696202532, -1.43299224137931 ], [ 29.768115696202532, -1.43272275862069 ], [ 29.767846202531643, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11373, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.43272275862069 ], [ 29.768115696202532, -1.43299224137931 ], [ 29.768385189873417, -1.43299224137931 ], [ 29.768385189873417, -1.43272275862069 ], [ 29.768115696202532, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11374, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.43272275862069 ], [ 29.768385189873417, -1.43299224137931 ], [ 29.768654683544302, -1.43299224137931 ], [ 29.768654683544302, -1.43272275862069 ], [ 29.768385189873417, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11375, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.43272275862069 ], [ 29.768654683544302, -1.43299224137931 ], [ 29.76892417721519, -1.43299224137931 ], [ 29.76892417721519, -1.43272275862069 ], [ 29.768654683544302, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11376, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.43272275862069 ], [ 29.76892417721519, -1.43299224137931 ], [ 29.769193670886075, -1.43299224137931 ], [ 29.769193670886075, -1.43272275862069 ], [ 29.76892417721519, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11377, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.43272275862069 ], [ 29.769193670886075, -1.43299224137931 ], [ 29.76946316455696, -1.43299224137931 ], [ 29.76946316455696, -1.43272275862069 ], [ 29.769193670886075, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11378, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.43272275862069 ], [ 29.769732658227849, -1.43299224137931 ], [ 29.770002151898733, -1.43299224137931 ], [ 29.770002151898733, -1.43272275862069 ], [ 29.769732658227849, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11379, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.43272275862069 ], [ 29.770002151898733, -1.43299224137931 ], [ 29.770271645569618, -1.43299224137931 ], [ 29.770271645569618, -1.43272275862069 ], [ 29.770002151898733, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11380, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.43272275862069 ], [ 29.770271645569618, -1.43299224137931 ], [ 29.770541139240507, -1.43299224137931 ], [ 29.770541139240507, -1.43272275862069 ], [ 29.770271645569618, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11381, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.43272275862069 ], [ 29.770810632911392, -1.43299224137931 ], [ 29.771080126582277, -1.43299224137931 ], [ 29.771080126582277, -1.43272275862069 ], [ 29.770810632911392, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11382, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.43272275862069 ], [ 29.771080126582277, -1.43299224137931 ], [ 29.771349620253165, -1.43299224137931 ], [ 29.771349620253165, -1.43272275862069 ], [ 29.771080126582277, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11383, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.43272275862069 ], [ 29.77161911392405, -1.43299224137931 ], [ 29.771888607594935, -1.43299224137931 ], [ 29.771888607594935, -1.43272275862069 ], [ 29.77161911392405, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11384, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.43272275862069 ], [ 29.771888607594935, -1.43299224137931 ], [ 29.772158101265823, -1.43299224137931 ], [ 29.772158101265823, -1.43272275862069 ], [ 29.771888607594935, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11385, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.43272275862069 ], [ 29.772697088607593, -1.43299224137931 ], [ 29.772966582278482, -1.43299224137931 ], [ 29.772966582278482, -1.43272275862069 ], [ 29.772697088607593, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11386, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.43272275862069 ], [ 29.772966582278482, -1.43299224137931 ], [ 29.773236075949367, -1.43299224137931 ], [ 29.773236075949367, -1.43272275862069 ], [ 29.772966582278482, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11387, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.43272275862069 ], [ 29.773236075949367, -1.43299224137931 ], [ 29.773505569620252, -1.43299224137931 ], [ 29.773505569620252, -1.43272275862069 ], [ 29.773236075949367, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11388, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.43272275862069 ], [ 29.773505569620252, -1.43299224137931 ], [ 29.77377506329114, -1.43299224137931 ], [ 29.77377506329114, -1.43272275862069 ], [ 29.773505569620252, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11389, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.43272275862069 ], [ 29.77377506329114, -1.43299224137931 ], [ 29.774044556962025, -1.43299224137931 ], [ 29.774044556962025, -1.43272275862069 ], [ 29.77377506329114, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11390, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.43272275862069 ], [ 29.774044556962025, -1.43299224137931 ], [ 29.77431405063291, -1.43299224137931 ], [ 29.77431405063291, -1.43272275862069 ], [ 29.774044556962025, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11391, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.43272275862069 ], [ 29.77431405063291, -1.43299224137931 ], [ 29.774853037974683, -1.43299224137931 ], [ 29.774853037974683, -1.43272275862069 ], [ 29.77431405063291, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11392, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.43272275862069 ], [ 29.774853037974683, -1.43299224137931 ], [ 29.775122531645568, -1.43299224137931 ], [ 29.775122531645568, -1.43272275862069 ], [ 29.774853037974683, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11393, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.43272275862069 ], [ 29.775661518987341, -1.43299224137931 ], [ 29.775931012658226, -1.43299224137931 ], [ 29.775931012658226, -1.43272275862069 ], [ 29.775661518987341, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11394, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.43272275862069 ], [ 29.77647, -1.43299224137931 ], [ 29.776739493670885, -1.43299224137931 ], [ 29.776739493670885, -1.43272275862069 ], [ 29.77647, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11395, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.43272275862069 ], [ 29.777278481012658, -1.43299224137931 ], [ 29.777547974683543, -1.43299224137931 ], [ 29.777547974683543, -1.43272275862069 ], [ 29.777278481012658, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11396, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.43272275862069 ], [ 29.777547974683543, -1.43299224137931 ], [ 29.777817468354431, -1.43299224137931 ], [ 29.777817468354431, -1.43272275862069 ], [ 29.777547974683543, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11397, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.43272275862069 ], [ 29.778086962025316, -1.43299224137931 ], [ 29.778356455696201, -1.43299224137931 ], [ 29.778356455696201, -1.43272275862069 ], [ 29.778086962025316, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11398, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.43272275862069 ], [ 29.778356455696201, -1.43299224137931 ], [ 29.77862594936709, -1.43299224137931 ], [ 29.77862594936709, -1.43272275862069 ], [ 29.778356455696201, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11399, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.43272275862069 ], [ 29.778895443037975, -1.43299224137931 ], [ 29.77916493670886, -1.43299224137931 ], [ 29.77916493670886, -1.43272275862069 ], [ 29.778895443037975, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11400, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.43272275862069 ], [ 29.77916493670886, -1.43299224137931 ], [ 29.779434430379748, -1.43299224137931 ], [ 29.779434430379748, -1.43272275862069 ], [ 29.77916493670886, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11401, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.43272275862069 ], [ 29.779434430379748, -1.43299224137931 ], [ 29.779703924050633, -1.43299224137931 ], [ 29.779703924050633, -1.43272275862069 ], [ 29.779434430379748, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11402, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.43272275862069 ], [ 29.779973417721518, -1.43299224137931 ], [ 29.780242911392406, -1.43299224137931 ], [ 29.780242911392406, -1.43272275862069 ], [ 29.779973417721518, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11403, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.43272275862069 ], [ 29.780242911392406, -1.43299224137931 ], [ 29.780512405063291, -1.43299224137931 ], [ 29.780512405063291, -1.43272275862069 ], [ 29.780242911392406, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11404, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.43272275862069 ], [ 29.780781898734176, -1.43299224137931 ], [ 29.781051392405065, -1.43299224137931 ], [ 29.781051392405065, -1.43272275862069 ], [ 29.780781898734176, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11405, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.43272275862069 ], [ 29.781051392405065, -1.43299224137931 ], [ 29.78132088607595, -1.43299224137931 ], [ 29.78132088607595, -1.43272275862069 ], [ 29.781051392405065, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11406, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.43272275862069 ], [ 29.781859873417723, -1.43299224137931 ], [ 29.782129367088608, -1.43299224137931 ], [ 29.782129367088608, -1.43272275862069 ], [ 29.781859873417723, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11407, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.43272275862069 ], [ 29.782668354430381, -1.43299224137931 ], [ 29.782937848101266, -1.43299224137931 ], [ 29.782937848101266, -1.43272275862069 ], [ 29.782668354430381, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11408, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.43272275862069 ], [ 29.782937848101266, -1.43299224137931 ], [ 29.783207341772151, -1.43299224137931 ], [ 29.783207341772151, -1.43272275862069 ], [ 29.782937848101266, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11409, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.43272275862069 ], [ 29.783476835443039, -1.43299224137931 ], [ 29.784015822784809, -1.43299224137931 ], [ 29.784015822784809, -1.43272275862069 ], [ 29.783476835443039, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11410, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.43272275862069 ], [ 29.784015822784809, -1.43299224137931 ], [ 29.784285316455698, -1.43299224137931 ], [ 29.784285316455698, -1.43272275862069 ], [ 29.784015822784809, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11411, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.43272275862069 ], [ 29.784285316455698, -1.43299224137931 ], [ 29.784554810126583, -1.43299224137931 ], [ 29.784554810126583, -1.43272275862069 ], [ 29.784285316455698, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11412, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.43272275862069 ], [ 29.784554810126583, -1.43299224137931 ], [ 29.784824303797468, -1.43299224137931 ], [ 29.784824303797468, -1.43272275862069 ], [ 29.784554810126583, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11413, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.43272275862069 ], [ 29.784824303797468, -1.43299224137931 ], [ 29.785093797468356, -1.43299224137931 ], [ 29.785093797468356, -1.43272275862069 ], [ 29.784824303797468, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11414, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.43272275862069 ], [ 29.785093797468356, -1.43299224137931 ], [ 29.785363291139241, -1.43299224137931 ], [ 29.785363291139241, -1.43272275862069 ], [ 29.785093797468356, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11415, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.43272275862069 ], [ 29.785363291139241, -1.43299224137931 ], [ 29.785632784810126, -1.43299224137931 ], [ 29.785632784810126, -1.43272275862069 ], [ 29.785363291139241, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11416, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.43272275862069 ], [ 29.785632784810126, -1.43299224137931 ], [ 29.785902278481014, -1.43299224137931 ], [ 29.785902278481014, -1.43272275862069 ], [ 29.785632784810126, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11417, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.43272275862069 ], [ 29.785902278481014, -1.43299224137931 ], [ 29.786171772151899, -1.43299224137931 ], [ 29.786171772151899, -1.43272275862069 ], [ 29.785902278481014, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11418, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.43272275862069 ], [ 29.786171772151899, -1.43299224137931 ], [ 29.786441265822784, -1.43299224137931 ], [ 29.786441265822784, -1.43272275862069 ], [ 29.786171772151899, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11419, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.43272275862069 ], [ 29.786441265822784, -1.43299224137931 ], [ 29.786710759493673, -1.43299224137931 ], [ 29.786710759493673, -1.43272275862069 ], [ 29.786441265822784, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11420, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.43272275862069 ], [ 29.786710759493673, -1.43299224137931 ], [ 29.786980253164558, -1.43299224137931 ], [ 29.786980253164558, -1.43272275862069 ], [ 29.786710759493673, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11421, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.43272275862069 ], [ 29.786980253164558, -1.43299224137931 ], [ 29.787249746835442, -1.43299224137931 ], [ 29.787249746835442, -1.43272275862069 ], [ 29.786980253164558, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11422, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.43272275862069 ], [ 29.787249746835442, -1.43299224137931 ], [ 29.788058227848101, -1.43299224137931 ], [ 29.788058227848101, -1.43272275862069 ], [ 29.787249746835442, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11423, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.43272275862069 ], [ 29.788058227848101, -1.43299224137931 ], [ 29.788327721518986, -1.43299224137931 ], [ 29.788327721518986, -1.43272275862069 ], [ 29.788058227848101, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11424, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.43272275862069 ], [ 29.788327721518986, -1.43299224137931 ], [ 29.788597215189874, -1.43299224137931 ], [ 29.788597215189874, -1.43272275862069 ], [ 29.788327721518986, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11425, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.43272275862069 ], [ 29.788597215189874, -1.43299224137931 ], [ 29.788866708860759, -1.43299224137931 ], [ 29.788866708860759, -1.43272275862069 ], [ 29.788597215189874, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11426, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.43272275862069 ], [ 29.788866708860759, -1.43299224137931 ], [ 29.789136202531644, -1.43299224137931 ], [ 29.789136202531644, -1.43272275862069 ], [ 29.788866708860759, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11427, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.43272275862069 ], [ 29.789136202531644, -1.43299224137931 ], [ 29.789405696202532, -1.43299224137931 ], [ 29.789405696202532, -1.43272275862069 ], [ 29.789136202531644, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11428, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.43272275862069 ], [ 29.789405696202532, -1.43299224137931 ], [ 29.789675189873417, -1.43299224137931 ], [ 29.789675189873417, -1.43272275862069 ], [ 29.789405696202532, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11429, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.43272275862069 ], [ 29.789675189873417, -1.43299224137931 ], [ 29.789944683544302, -1.43299224137931 ], [ 29.789944683544302, -1.43272275862069 ], [ 29.789675189873417, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11430, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.43272275862069 ], [ 29.789944683544302, -1.43299224137931 ], [ 29.790214177215191, -1.43299224137931 ], [ 29.790214177215191, -1.43272275862069 ], [ 29.789944683544302, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11431, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.43272275862069 ], [ 29.790214177215191, -1.43299224137931 ], [ 29.790483670886076, -1.43299224137931 ], [ 29.790483670886076, -1.43272275862069 ], [ 29.790214177215191, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11432, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.43272275862069 ], [ 29.790483670886076, -1.43299224137931 ], [ 29.790753164556961, -1.43299224137931 ], [ 29.790753164556961, -1.43272275862069 ], [ 29.790483670886076, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11433, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.43272275862069 ], [ 29.791022658227849, -1.43299224137931 ], [ 29.791292151898734, -1.43299224137931 ], [ 29.791292151898734, -1.43272275862069 ], [ 29.791022658227849, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11434, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.43272275862069 ], [ 29.791292151898734, -1.43299224137931 ], [ 29.791561645569619, -1.43299224137931 ], [ 29.791561645569619, -1.43272275862069 ], [ 29.791292151898734, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11435, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.43272275862069 ], [ 29.791561645569619, -1.43299224137931 ], [ 29.791831139240507, -1.43299224137931 ], [ 29.791831139240507, -1.43272275862069 ], [ 29.791561645569619, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11436, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.43272275862069 ], [ 29.791831139240507, -1.43299224137931 ], [ 29.792100632911392, -1.43299224137931 ], [ 29.792100632911392, -1.43272275862069 ], [ 29.791831139240507, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11437, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.43272275862069 ], [ 29.792100632911392, -1.43299224137931 ], [ 29.792370126582277, -1.43299224137931 ], [ 29.792370126582277, -1.43272275862069 ], [ 29.792100632911392, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11438, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.43272275862069 ], [ 29.792370126582277, -1.43299224137931 ], [ 29.792909113924051, -1.43299224137931 ], [ 29.792909113924051, -1.43272275862069 ], [ 29.792370126582277, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11439, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.43272275862069 ], [ 29.792909113924051, -1.43299224137931 ], [ 29.793178607594935, -1.43299224137931 ], [ 29.793178607594935, -1.43272275862069 ], [ 29.792909113924051, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11440, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.43272275862069 ], [ 29.793178607594935, -1.43299224137931 ], [ 29.793448101265824, -1.43299224137931 ], [ 29.793448101265824, -1.43272275862069 ], [ 29.793178607594935, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11441, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.43272275862069 ], [ 29.793448101265824, -1.43299224137931 ], [ 29.793717594936709, -1.43299224137931 ], [ 29.793717594936709, -1.43272275862069 ], [ 29.793448101265824, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11442, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.43272275862069 ], [ 29.793717594936709, -1.43299224137931 ], [ 29.793987088607594, -1.43299224137931 ], [ 29.793987088607594, -1.43272275862069 ], [ 29.793717594936709, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11443, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.43272275862069 ], [ 29.793987088607594, -1.43299224137931 ], [ 29.794256582278482, -1.43299224137931 ], [ 29.794256582278482, -1.43272275862069 ], [ 29.793987088607594, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11444, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.43272275862069 ], [ 29.794256582278482, -1.43299224137931 ], [ 29.794526075949367, -1.43299224137931 ], [ 29.794526075949367, -1.43272275862069 ], [ 29.794256582278482, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11445, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.43272275862069 ], [ 29.794526075949367, -1.43299224137931 ], [ 29.794795569620252, -1.43299224137931 ], [ 29.794795569620252, -1.43272275862069 ], [ 29.794526075949367, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11446, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.43272275862069 ], [ 29.794795569620252, -1.43299224137931 ], [ 29.79506506329114, -1.43299224137931 ], [ 29.79506506329114, -1.43272275862069 ], [ 29.794795569620252, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11447, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.43272275862069 ], [ 29.79506506329114, -1.43299224137931 ], [ 29.795334556962025, -1.43299224137931 ], [ 29.795334556962025, -1.43272275862069 ], [ 29.79506506329114, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11448, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.43272275862069 ], [ 29.795334556962025, -1.43299224137931 ], [ 29.79560405063291, -1.43299224137931 ], [ 29.79560405063291, -1.43272275862069 ], [ 29.795334556962025, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11449, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.43272275862069 ], [ 29.79560405063291, -1.43299224137931 ], [ 29.795873544303799, -1.43299224137931 ], [ 29.795873544303799, -1.43272275862069 ], [ 29.79560405063291, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11450, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.43272275862069 ], [ 29.795873544303799, -1.43299224137931 ], [ 29.796143037974684, -1.43299224137931 ], [ 29.796143037974684, -1.43272275862069 ], [ 29.795873544303799, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11451, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.43272275862069 ], [ 29.796143037974684, -1.43299224137931 ], [ 29.796412531645569, -1.43299224137931 ], [ 29.796412531645569, -1.43272275862069 ], [ 29.796143037974684, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11452, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.43272275862069 ], [ 29.801263417721518, -1.43299224137931 ], [ 29.801532911392407, -1.43299224137931 ], [ 29.801532911392407, -1.43272275862069 ], [ 29.801263417721518, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11453, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.43272275862069 ], [ 29.801532911392407, -1.43299224137931 ], [ 29.802071898734177, -1.43299224137931 ], [ 29.802071898734177, -1.43272275862069 ], [ 29.801532911392407, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11454, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.43272275862069 ], [ 29.802341392405065, -1.43299224137931 ], [ 29.80261088607595, -1.43299224137931 ], [ 29.80261088607595, -1.43272275862069 ], [ 29.802341392405065, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11455, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.43272275862069 ], [ 29.80261088607595, -1.43299224137931 ], [ 29.802880379746835, -1.43299224137931 ], [ 29.802880379746835, -1.43272275862069 ], [ 29.80261088607595, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11456, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.43272275862069 ], [ 29.802880379746835, -1.43299224137931 ], [ 29.803149873417723, -1.43299224137931 ], [ 29.803149873417723, -1.43272275862069 ], [ 29.802880379746835, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11457, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.43272275862069 ], [ 29.803149873417723, -1.43299224137931 ], [ 29.803419367088608, -1.43299224137931 ], [ 29.803419367088608, -1.43272275862069 ], [ 29.803149873417723, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11458, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.43272275862069 ], [ 29.803419367088608, -1.43299224137931 ], [ 29.803688860759493, -1.43299224137931 ], [ 29.803688860759493, -1.43272275862069 ], [ 29.803419367088608, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11459, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.43272275862069 ], [ 29.803688860759493, -1.43299224137931 ], [ 29.803958354430382, -1.43299224137931 ], [ 29.803958354430382, -1.43272275862069 ], [ 29.803688860759493, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11460, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.43272275862069 ], [ 29.803958354430382, -1.43299224137931 ], [ 29.804227848101267, -1.43299224137931 ], [ 29.804227848101267, -1.43272275862069 ], [ 29.803958354430382, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11461, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.431375344827586 ], [ 29.806383797468357, -1.431375344827586 ], [ 29.806383797468357, -1.43299224137931 ], [ 29.804227848101267, -1.43299224137931 ], [ 29.804227848101267, -1.43272275862069 ], [ 29.806114303797468, -1.43272275862069 ], [ 29.806114303797468, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11462, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.431105862068966 ], [ 29.798029493670885, -1.433531206896552 ], [ 29.798298987341774, -1.433531206896552 ], [ 29.798298987341774, -1.431105862068966 ], [ 29.798029493670885, -1.431105862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11463, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.431375344827586 ], [ 29.798298987341774, -1.433531206896552 ], [ 29.798568481012659, -1.433531206896552 ], [ 29.798568481012659, -1.431375344827586 ], [ 29.798298987341774, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11464, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.431375344827586 ], [ 29.798568481012659, -1.433531206896552 ], [ 29.798837974683543, -1.433531206896552 ], [ 29.798837974683543, -1.431375344827586 ], [ 29.798568481012659, -1.431375344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11465, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.431644827586207 ], [ 29.798837974683543, -1.433800689655172 ], [ 29.799107468354432, -1.433800689655172 ], [ 29.799107468354432, -1.431644827586207 ], [ 29.798837974683543, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11466, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.431644827586207 ], [ 29.799107468354432, -1.433800689655172 ], [ 29.799376962025317, -1.433800689655172 ], [ 29.799376962025317, -1.431644827586207 ], [ 29.799107468354432, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11467, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.431644827586207 ], [ 29.799376962025317, -1.433531206896552 ], [ 29.799646455696202, -1.433531206896552 ], [ 29.799646455696202, -1.431644827586207 ], [ 29.799376962025317, -1.431644827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11468, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.431914310344828 ], [ 29.754371518987341, -1.433531206896552 ], [ 29.754641012658226, -1.433531206896552 ], [ 29.754641012658226, -1.431914310344828 ], [ 29.754371518987341, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11469, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.431914310344828 ], [ 29.799646455696202, -1.433800689655172 ], [ 29.79991594936709, -1.433800689655172 ], [ 29.79991594936709, -1.431914310344828 ], [ 29.799646455696202, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11470, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.431914310344828 ], [ 29.79991594936709, -1.433800689655172 ], [ 29.800185443037975, -1.433800689655172 ], [ 29.800185443037975, -1.431914310344828 ], [ 29.79991594936709, -1.431914310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11471, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.432183793103448 ], [ 29.754641012658226, -1.433531206896552 ], [ 29.754910506329114, -1.433531206896552 ], [ 29.754910506329114, -1.432183793103448 ], [ 29.754641012658226, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11472, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.432183793103448 ], [ 29.754910506329114, -1.433531206896552 ], [ 29.75518, -1.433531206896552 ], [ 29.75518, -1.432183793103448 ], [ 29.754910506329114, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11473, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.432183793103448 ], [ 29.800185443037975, -1.434070172413793 ], [ 29.80045493670886, -1.434070172413793 ], [ 29.80045493670886, -1.432183793103448 ], [ 29.800185443037975, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11474, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.432183793103448 ], [ 29.80045493670886, -1.433800689655172 ], [ 29.800724430379748, -1.433800689655172 ], [ 29.800724430379748, -1.434878620689655 ], [ 29.800993924050633, -1.434878620689655 ], [ 29.800993924050633, -1.43299224137931 ], [ 29.800724430379748, -1.43299224137931 ], [ 29.800724430379748, -1.432183793103448 ], [ 29.80045493670886, -1.432183793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11475, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.432453275862069 ], [ 29.75518, -1.433800689655172 ], [ 29.755449493670884, -1.433800689655172 ], [ 29.755449493670884, -1.432453275862069 ], [ 29.75518, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11476, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.432453275862069 ], [ 29.755449493670884, -1.433800689655172 ], [ 29.755718987341773, -1.433800689655172 ], [ 29.755718987341773, -1.432453275862069 ], [ 29.755449493670884, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11477, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.432453275862069 ], [ 29.755718987341773, -1.433531206896552 ], [ 29.755988481012658, -1.433531206896552 ], [ 29.755988481012658, -1.432453275862069 ], [ 29.755718987341773, -1.432453275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11478, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.43272275862069 ], [ 29.755988481012658, -1.433800689655172 ], [ 29.756257974683542, -1.433800689655172 ], [ 29.756257974683542, -1.43272275862069 ], [ 29.755988481012658, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11479, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.43272275862069 ], [ 29.756257974683542, -1.433800689655172 ], [ 29.756796962025316, -1.433800689655172 ], [ 29.756796962025316, -1.43272275862069 ], [ 29.756257974683542, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11480, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.43272275862069 ], [ 29.76946316455696, -1.433261724137931 ], [ 29.769732658227849, -1.433261724137931 ], [ 29.769732658227849, -1.43272275862069 ], [ 29.76946316455696, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11481, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.43272275862069 ], [ 29.771349620253165, -1.433261724137931 ], [ 29.77161911392405, -1.433261724137931 ], [ 29.77161911392405, -1.43272275862069 ], [ 29.771349620253165, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11482, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.43272275862069 ], [ 29.772158101265823, -1.433261724137931 ], [ 29.772427594936708, -1.433261724137931 ], [ 29.772427594936708, -1.43272275862069 ], [ 29.772158101265823, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11483, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.43272275862069 ], [ 29.775122531645568, -1.433261724137931 ], [ 29.775392025316457, -1.433261724137931 ], [ 29.775392025316457, -1.43272275862069 ], [ 29.775122531645568, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11484, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.43272275862069 ], [ 29.775931012658226, -1.433261724137931 ], [ 29.776200506329115, -1.433261724137931 ], [ 29.776200506329115, -1.43272275862069 ], [ 29.775931012658226, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11485, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.43272275862069 ], [ 29.776739493670885, -1.433261724137931 ], [ 29.777008987341773, -1.433261724137931 ], [ 29.777008987341773, -1.43272275862069 ], [ 29.776739493670885, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11486, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.43272275862069 ], [ 29.77862594936709, -1.433261724137931 ], [ 29.778895443037975, -1.433261724137931 ], [ 29.778895443037975, -1.43272275862069 ], [ 29.77862594936709, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11487, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.43272275862069 ], [ 29.780512405063291, -1.433261724137931 ], [ 29.780781898734176, -1.433261724137931 ], [ 29.780781898734176, -1.43272275862069 ], [ 29.780512405063291, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11488, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.43272275862069 ], [ 29.78132088607595, -1.433261724137931 ], [ 29.781590379746834, -1.433261724137931 ], [ 29.781590379746834, -1.43272275862069 ], [ 29.78132088607595, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11489, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.43272275862069 ], [ 29.782129367088608, -1.433261724137931 ], [ 29.782398860759493, -1.433261724137931 ], [ 29.782398860759493, -1.43272275862069 ], [ 29.782129367088608, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11490, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.43272275862069 ], [ 29.800993924050633, -1.435148103448276 ], [ 29.801263417721518, -1.435148103448276 ], [ 29.801263417721518, -1.43272275862069 ], [ 29.800993924050633, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11491, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.43272275862069 ], [ 29.802071898734177, -1.433261724137931 ], [ 29.802341392405065, -1.433261724137931 ], [ 29.802341392405065, -1.43272275862069 ], [ 29.802071898734177, -1.43272275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11492, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.43299224137931 ], [ 29.747095189873416, -1.433261724137931 ], [ 29.748981645569618, -1.433261724137931 ], [ 29.748981645569618, -1.43299224137931 ], [ 29.747095189873416, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11493, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.43299224137931 ], [ 29.756796962025316, -1.433800689655172 ], [ 29.757066455696201, -1.433800689655172 ], [ 29.757066455696201, -1.433531206896552 ], [ 29.757335949367089, -1.433531206896552 ], [ 29.757335949367089, -1.433261724137931 ], [ 29.757066455696201, -1.433261724137931 ], [ 29.757066455696201, -1.43299224137931 ], [ 29.756796962025316, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11494, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.43299224137931 ], [ 29.757066455696201, -1.433261724137931 ], [ 29.757335949367089, -1.433261724137931 ], [ 29.757335949367089, -1.43299224137931 ], [ 29.757066455696201, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11495, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.43299224137931 ], [ 29.757335949367089, -1.433261724137931 ], [ 29.757605443037974, -1.433261724137931 ], [ 29.757605443037974, -1.43299224137931 ], [ 29.757335949367089, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11496, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.43299224137931 ], [ 29.757605443037974, -1.433261724137931 ], [ 29.757874936708859, -1.433261724137931 ], [ 29.757874936708859, -1.43299224137931 ], [ 29.757605443037974, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11497, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.43299224137931 ], [ 29.757874936708859, -1.433261724137931 ], [ 29.758144430379748, -1.433261724137931 ], [ 29.758144430379748, -1.43299224137931 ], [ 29.757874936708859, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11498, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.43299224137931 ], [ 29.758144430379748, -1.433261724137931 ], [ 29.758413924050632, -1.433261724137931 ], [ 29.758413924050632, -1.43299224137931 ], [ 29.758144430379748, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11499, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.43299224137931 ], [ 29.758413924050632, -1.433261724137931 ], [ 29.758683417721517, -1.433261724137931 ], [ 29.758683417721517, -1.43299224137931 ], [ 29.758413924050632, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11500, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.43299224137931 ], [ 29.758683417721517, -1.433261724137931 ], [ 29.759761392405064, -1.433261724137931 ], [ 29.759761392405064, -1.43299224137931 ], [ 29.758683417721517, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11501, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.43299224137931 ], [ 29.759761392405064, -1.433261724137931 ], [ 29.760030886075949, -1.433261724137931 ], [ 29.760030886075949, -1.43299224137931 ], [ 29.759761392405064, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11502, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.43299224137931 ], [ 29.760030886075949, -1.433261724137931 ], [ 29.760300379746834, -1.433261724137931 ], [ 29.760300379746834, -1.43299224137931 ], [ 29.760030886075949, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11503, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.43299224137931 ], [ 29.760300379746834, -1.433261724137931 ], [ 29.761647848101266, -1.433261724137931 ], [ 29.761647848101266, -1.43299224137931 ], [ 29.760300379746834, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11504, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.43299224137931 ], [ 29.761647848101266, -1.433261724137931 ], [ 29.761917341772151, -1.433261724137931 ], [ 29.761917341772151, -1.43299224137931 ], [ 29.761647848101266, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11505, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.43299224137931 ], [ 29.761917341772151, -1.433261724137931 ], [ 29.762186835443039, -1.433261724137931 ], [ 29.762186835443039, -1.43299224137931 ], [ 29.761917341772151, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11506, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.43299224137931 ], [ 29.762186835443039, -1.433261724137931 ], [ 29.762456329113924, -1.433261724137931 ], [ 29.762456329113924, -1.43299224137931 ], [ 29.762186835443039, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11507, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.43299224137931 ], [ 29.762456329113924, -1.433261724137931 ], [ 29.762725822784809, -1.433261724137931 ], [ 29.762725822784809, -1.43299224137931 ], [ 29.762456329113924, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11508, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.43299224137931 ], [ 29.762725822784809, -1.433261724137931 ], [ 29.762995316455697, -1.433261724137931 ], [ 29.762995316455697, -1.43299224137931 ], [ 29.762725822784809, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11509, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.43299224137931 ], [ 29.762995316455697, -1.433261724137931 ], [ 29.763264810126582, -1.433261724137931 ], [ 29.763264810126582, -1.43299224137931 ], [ 29.762995316455697, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11510, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.43299224137931 ], [ 29.763264810126582, -1.433261724137931 ], [ 29.763534303797467, -1.433261724137931 ], [ 29.763534303797467, -1.43299224137931 ], [ 29.763264810126582, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11511, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.43299224137931 ], [ 29.763534303797467, -1.433261724137931 ], [ 29.763803797468356, -1.433261724137931 ], [ 29.763803797468356, -1.43299224137931 ], [ 29.763534303797467, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11512, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.43299224137931 ], [ 29.763803797468356, -1.433261724137931 ], [ 29.76407329113924, -1.433261724137931 ], [ 29.76407329113924, -1.43299224137931 ], [ 29.763803797468356, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11513, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.43299224137931 ], [ 29.76407329113924, -1.433261724137931 ], [ 29.764342784810125, -1.433261724137931 ], [ 29.764342784810125, -1.43299224137931 ], [ 29.76407329113924, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11514, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.43299224137931 ], [ 29.764342784810125, -1.433261724137931 ], [ 29.764612278481014, -1.433261724137931 ], [ 29.764612278481014, -1.43299224137931 ], [ 29.764342784810125, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11515, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.43299224137931 ], [ 29.764612278481014, -1.433261724137931 ], [ 29.764881772151899, -1.433261724137931 ], [ 29.764881772151899, -1.43299224137931 ], [ 29.764612278481014, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11516, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.43299224137931 ], [ 29.764881772151899, -1.433261724137931 ], [ 29.765151265822784, -1.433261724137931 ], [ 29.765151265822784, -1.43299224137931 ], [ 29.764881772151899, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11517, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.43299224137931 ], [ 29.765151265822784, -1.433261724137931 ], [ 29.765420759493672, -1.433261724137931 ], [ 29.765420759493672, -1.43299224137931 ], [ 29.765151265822784, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11518, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.43299224137931 ], [ 29.765420759493672, -1.433261724137931 ], [ 29.765959746835442, -1.433261724137931 ], [ 29.765959746835442, -1.43299224137931 ], [ 29.765420759493672, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11519, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.43299224137931 ], [ 29.765959746835442, -1.433261724137931 ], [ 29.766229240506327, -1.433261724137931 ], [ 29.766229240506327, -1.43299224137931 ], [ 29.765959746835442, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11520, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.43299224137931 ], [ 29.766229240506327, -1.433261724137931 ], [ 29.766498734177215, -1.433261724137931 ], [ 29.766498734177215, -1.43299224137931 ], [ 29.766229240506327, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11521, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.43299224137931 ], [ 29.766498734177215, -1.433261724137931 ], [ 29.7667682278481, -1.433261724137931 ], [ 29.7667682278481, -1.43299224137931 ], [ 29.766498734177215, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11522, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.43299224137931 ], [ 29.7667682278481, -1.433261724137931 ], [ 29.767037721518985, -1.433261724137931 ], [ 29.767037721518985, -1.43299224137931 ], [ 29.7667682278481, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11523, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.43299224137931 ], [ 29.767037721518985, -1.433261724137931 ], [ 29.767307215189874, -1.433261724137931 ], [ 29.767307215189874, -1.43299224137931 ], [ 29.767037721518985, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11524, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.43299224137931 ], [ 29.767307215189874, -1.433261724137931 ], [ 29.767576708860759, -1.433261724137931 ], [ 29.767576708860759, -1.43299224137931 ], [ 29.767307215189874, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11525, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.43299224137931 ], [ 29.767576708860759, -1.433261724137931 ], [ 29.767846202531643, -1.433261724137931 ], [ 29.767846202531643, -1.43299224137931 ], [ 29.767576708860759, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11526, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.43299224137931 ], [ 29.767846202531643, -1.433261724137931 ], [ 29.768115696202532, -1.433261724137931 ], [ 29.768115696202532, -1.43299224137931 ], [ 29.767846202531643, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11527, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.43299224137931 ], [ 29.768115696202532, -1.433261724137931 ], [ 29.768385189873417, -1.433261724137931 ], [ 29.768385189873417, -1.43299224137931 ], [ 29.768115696202532, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11528, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.43299224137931 ], [ 29.768385189873417, -1.433531206896552 ], [ 29.768654683544302, -1.433531206896552 ], [ 29.768654683544302, -1.43299224137931 ], [ 29.768385189873417, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11529, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.43299224137931 ], [ 29.768654683544302, -1.433261724137931 ], [ 29.76892417721519, -1.433261724137931 ], [ 29.76892417721519, -1.43299224137931 ], [ 29.768654683544302, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11530, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.43299224137931 ], [ 29.76892417721519, -1.433261724137931 ], [ 29.769193670886075, -1.433261724137931 ], [ 29.769193670886075, -1.43299224137931 ], [ 29.76892417721519, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11531, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.43299224137931 ], [ 29.769193670886075, -1.433261724137931 ], [ 29.76946316455696, -1.433261724137931 ], [ 29.76946316455696, -1.43299224137931 ], [ 29.769193670886075, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11532, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.43299224137931 ], [ 29.769732658227849, -1.433261724137931 ], [ 29.770002151898733, -1.433261724137931 ], [ 29.770002151898733, -1.43299224137931 ], [ 29.769732658227849, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11533, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.43299224137931 ], [ 29.770002151898733, -1.433261724137931 ], [ 29.770271645569618, -1.433261724137931 ], [ 29.770271645569618, -1.43299224137931 ], [ 29.770002151898733, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11534, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.43299224137931 ], [ 29.770271645569618, -1.433531206896552 ], [ 29.770541139240507, -1.433531206896552 ], [ 29.770541139240507, -1.43299224137931 ], [ 29.770271645569618, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11535, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.43299224137931 ], [ 29.770541139240507, -1.433261724137931 ], [ 29.770810632911392, -1.433261724137931 ], [ 29.770810632911392, -1.43299224137931 ], [ 29.770541139240507, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11536, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.43299224137931 ], [ 29.770810632911392, -1.433261724137931 ], [ 29.771080126582277, -1.433261724137931 ], [ 29.771080126582277, -1.43299224137931 ], [ 29.770810632911392, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11537, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.43299224137931 ], [ 29.771080126582277, -1.433531206896552 ], [ 29.771349620253165, -1.433531206896552 ], [ 29.771349620253165, -1.43299224137931 ], [ 29.771080126582277, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11538, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.43299224137931 ], [ 29.77161911392405, -1.433261724137931 ], [ 29.771888607594935, -1.433261724137931 ], [ 29.771888607594935, -1.43299224137931 ], [ 29.77161911392405, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11539, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.43299224137931 ], [ 29.771888607594935, -1.433261724137931 ], [ 29.772158101265823, -1.433261724137931 ], [ 29.772158101265823, -1.43299224137931 ], [ 29.771888607594935, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11540, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.43299224137931 ], [ 29.772427594936708, -1.433261724137931 ], [ 29.772697088607593, -1.433261724137931 ], [ 29.772697088607593, -1.43299224137931 ], [ 29.772427594936708, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11541, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.43299224137931 ], [ 29.772697088607593, -1.433261724137931 ], [ 29.772966582278482, -1.433261724137931 ], [ 29.772966582278482, -1.43299224137931 ], [ 29.772697088607593, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11542, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.43299224137931 ], [ 29.772966582278482, -1.433531206896552 ], [ 29.773236075949367, -1.433531206896552 ], [ 29.773236075949367, -1.43299224137931 ], [ 29.772966582278482, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11543, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.43299224137931 ], [ 29.773236075949367, -1.433261724137931 ], [ 29.77377506329114, -1.433261724137931 ], [ 29.77377506329114, -1.43299224137931 ], [ 29.773236075949367, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11544, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.43299224137931 ], [ 29.77377506329114, -1.433531206896552 ], [ 29.774044556962025, -1.433531206896552 ], [ 29.774044556962025, -1.43299224137931 ], [ 29.77377506329114, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11545, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.43299224137931 ], [ 29.774044556962025, -1.433261724137931 ], [ 29.77431405063291, -1.433261724137931 ], [ 29.77431405063291, -1.43299224137931 ], [ 29.774044556962025, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11546, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.43299224137931 ], [ 29.77431405063291, -1.433261724137931 ], [ 29.774853037974683, -1.433261724137931 ], [ 29.774853037974683, -1.43299224137931 ], [ 29.77431405063291, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11547, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.43299224137931 ], [ 29.774853037974683, -1.433531206896552 ], [ 29.775122531645568, -1.433531206896552 ], [ 29.775122531645568, -1.43299224137931 ], [ 29.774853037974683, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11548, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.43299224137931 ], [ 29.775392025316457, -1.433261724137931 ], [ 29.775661518987341, -1.433261724137931 ], [ 29.775661518987341, -1.43299224137931 ], [ 29.775392025316457, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11549, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.43299224137931 ], [ 29.775661518987341, -1.433531206896552 ], [ 29.775931012658226, -1.433531206896552 ], [ 29.775931012658226, -1.43299224137931 ], [ 29.775661518987341, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11550, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.43299224137931 ], [ 29.776200506329115, -1.433261724137931 ], [ 29.77647, -1.433261724137931 ], [ 29.77647, -1.43299224137931 ], [ 29.776200506329115, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11551, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.43299224137931 ], [ 29.77647, -1.433261724137931 ], [ 29.776739493670885, -1.433261724137931 ], [ 29.776739493670885, -1.43299224137931 ], [ 29.77647, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11552, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.43299224137931 ], [ 29.777008987341773, -1.433261724137931 ], [ 29.777278481012658, -1.433261724137931 ], [ 29.777278481012658, -1.43299224137931 ], [ 29.777008987341773, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11553, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.43299224137931 ], [ 29.777278481012658, -1.433261724137931 ], [ 29.777547974683543, -1.433261724137931 ], [ 29.777547974683543, -1.43299224137931 ], [ 29.777278481012658, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11554, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.43299224137931 ], [ 29.777547974683543, -1.433531206896552 ], [ 29.777817468354431, -1.433531206896552 ], [ 29.777817468354431, -1.43299224137931 ], [ 29.777547974683543, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11555, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.43299224137931 ], [ 29.777817468354431, -1.433261724137931 ], [ 29.778086962025316, -1.433261724137931 ], [ 29.778086962025316, -1.43299224137931 ], [ 29.777817468354431, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11556, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.43299224137931 ], [ 29.778086962025316, -1.433261724137931 ], [ 29.778356455696201, -1.433261724137931 ], [ 29.778356455696201, -1.43299224137931 ], [ 29.778086962025316, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11557, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.43299224137931 ], [ 29.778356455696201, -1.433261724137931 ], [ 29.77862594936709, -1.433261724137931 ], [ 29.77862594936709, -1.43299224137931 ], [ 29.778356455696201, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11558, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.43299224137931 ], [ 29.778895443037975, -1.433261724137931 ], [ 29.77916493670886, -1.433261724137931 ], [ 29.77916493670886, -1.43299224137931 ], [ 29.778895443037975, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11559, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.43299224137931 ], [ 29.77916493670886, -1.433261724137931 ], [ 29.779434430379748, -1.433261724137931 ], [ 29.779434430379748, -1.43299224137931 ], [ 29.77916493670886, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11560, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.43299224137931 ], [ 29.779434430379748, -1.433531206896552 ], [ 29.779703924050633, -1.433531206896552 ], [ 29.779703924050633, -1.43299224137931 ], [ 29.779434430379748, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11561, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.43299224137931 ], [ 29.779703924050633, -1.433261724137931 ], [ 29.779973417721518, -1.433261724137931 ], [ 29.779973417721518, -1.43299224137931 ], [ 29.779703924050633, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11562, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.43299224137931 ], [ 29.779973417721518, -1.433261724137931 ], [ 29.780242911392406, -1.433261724137931 ], [ 29.780242911392406, -1.43299224137931 ], [ 29.779973417721518, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11563, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.43299224137931 ], [ 29.780242911392406, -1.433531206896552 ], [ 29.780512405063291, -1.433531206896552 ], [ 29.780512405063291, -1.43299224137931 ], [ 29.780242911392406, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11564, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.43299224137931 ], [ 29.780781898734176, -1.433261724137931 ], [ 29.781051392405065, -1.433261724137931 ], [ 29.781051392405065, -1.43299224137931 ], [ 29.780781898734176, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11565, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.43299224137931 ], [ 29.781051392405065, -1.433261724137931 ], [ 29.78132088607595, -1.433261724137931 ], [ 29.78132088607595, -1.43299224137931 ], [ 29.781051392405065, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11566, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.43299224137931 ], [ 29.781590379746834, -1.433261724137931 ], [ 29.781859873417723, -1.433261724137931 ], [ 29.781859873417723, -1.43299224137931 ], [ 29.781590379746834, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11567, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.43299224137931 ], [ 29.781859873417723, -1.433261724137931 ], [ 29.782129367088608, -1.433261724137931 ], [ 29.782129367088608, -1.43299224137931 ], [ 29.781859873417723, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11568, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.43299224137931 ], [ 29.782398860759493, -1.433261724137931 ], [ 29.782668354430381, -1.433261724137931 ], [ 29.782668354430381, -1.43299224137931 ], [ 29.782398860759493, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11569, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.43299224137931 ], [ 29.782668354430381, -1.433261724137931 ], [ 29.782937848101266, -1.433261724137931 ], [ 29.782937848101266, -1.43299224137931 ], [ 29.782668354430381, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11570, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.43299224137931 ], [ 29.782937848101266, -1.433261724137931 ], [ 29.783207341772151, -1.433261724137931 ], [ 29.783207341772151, -1.43299224137931 ], [ 29.782937848101266, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11571, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.43299224137931 ], [ 29.783207341772151, -1.433261724137931 ], [ 29.783476835443039, -1.433261724137931 ], [ 29.783476835443039, -1.43299224137931 ], [ 29.783207341772151, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11572, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.43299224137931 ], [ 29.783476835443039, -1.433261724137931 ], [ 29.784015822784809, -1.433261724137931 ], [ 29.784015822784809, -1.43299224137931 ], [ 29.783476835443039, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11573, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.43299224137931 ], [ 29.784015822784809, -1.433261724137931 ], [ 29.784285316455698, -1.433261724137931 ], [ 29.784285316455698, -1.43299224137931 ], [ 29.784015822784809, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11574, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.43299224137931 ], [ 29.784285316455698, -1.433261724137931 ], [ 29.784554810126583, -1.433261724137931 ], [ 29.784554810126583, -1.43299224137931 ], [ 29.784285316455698, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11575, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.43299224137931 ], [ 29.784554810126583, -1.433261724137931 ], [ 29.784824303797468, -1.433261724137931 ], [ 29.784824303797468, -1.43299224137931 ], [ 29.784554810126583, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11576, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.43299224137931 ], [ 29.784824303797468, -1.433261724137931 ], [ 29.785093797468356, -1.433261724137931 ], [ 29.785093797468356, -1.43299224137931 ], [ 29.784824303797468, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11577, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.43299224137931 ], [ 29.785093797468356, -1.433261724137931 ], [ 29.785363291139241, -1.433261724137931 ], [ 29.785363291139241, -1.43299224137931 ], [ 29.785093797468356, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11578, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.43299224137931 ], [ 29.785363291139241, -1.433261724137931 ], [ 29.785632784810126, -1.433261724137931 ], [ 29.785632784810126, -1.43299224137931 ], [ 29.785363291139241, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11579, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.43299224137931 ], [ 29.785632784810126, -1.433261724137931 ], [ 29.785902278481014, -1.433261724137931 ], [ 29.785902278481014, -1.43299224137931 ], [ 29.785632784810126, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11580, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.43299224137931 ], [ 29.785902278481014, -1.433261724137931 ], [ 29.786171772151899, -1.433261724137931 ], [ 29.786171772151899, -1.43299224137931 ], [ 29.785902278481014, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11581, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.43299224137931 ], [ 29.786171772151899, -1.433261724137931 ], [ 29.786441265822784, -1.433261724137931 ], [ 29.786441265822784, -1.43299224137931 ], [ 29.786171772151899, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11582, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.43299224137931 ], [ 29.786441265822784, -1.433261724137931 ], [ 29.786710759493673, -1.433261724137931 ], [ 29.786710759493673, -1.43299224137931 ], [ 29.786441265822784, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11583, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.43299224137931 ], [ 29.786710759493673, -1.433261724137931 ], [ 29.786980253164558, -1.433261724137931 ], [ 29.786980253164558, -1.43299224137931 ], [ 29.786710759493673, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11584, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.43299224137931 ], [ 29.786980253164558, -1.433261724137931 ], [ 29.787249746835442, -1.433261724137931 ], [ 29.787249746835442, -1.43299224137931 ], [ 29.786980253164558, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11585, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.43299224137931 ], [ 29.787249746835442, -1.433261724137931 ], [ 29.788058227848101, -1.433261724137931 ], [ 29.788058227848101, -1.43299224137931 ], [ 29.787249746835442, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11586, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.43299224137931 ], [ 29.788058227848101, -1.433261724137931 ], [ 29.788327721518986, -1.433261724137931 ], [ 29.788327721518986, -1.43299224137931 ], [ 29.788058227848101, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11587, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.43299224137931 ], [ 29.788327721518986, -1.433261724137931 ], [ 29.788597215189874, -1.433261724137931 ], [ 29.788597215189874, -1.43299224137931 ], [ 29.788327721518986, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11588, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.43299224137931 ], [ 29.788597215189874, -1.433261724137931 ], [ 29.788866708860759, -1.433261724137931 ], [ 29.788866708860759, -1.43299224137931 ], [ 29.788597215189874, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11589, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.43299224137931 ], [ 29.788866708860759, -1.433261724137931 ], [ 29.789136202531644, -1.433261724137931 ], [ 29.789136202531644, -1.43299224137931 ], [ 29.788866708860759, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11590, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.43299224137931 ], [ 29.789136202531644, -1.433261724137931 ], [ 29.789405696202532, -1.433261724137931 ], [ 29.789405696202532, -1.43299224137931 ], [ 29.789136202531644, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11591, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.43299224137931 ], [ 29.789405696202532, -1.433261724137931 ], [ 29.789675189873417, -1.433261724137931 ], [ 29.789675189873417, -1.43299224137931 ], [ 29.789405696202532, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11592, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.43299224137931 ], [ 29.789675189873417, -1.433261724137931 ], [ 29.789944683544302, -1.433261724137931 ], [ 29.789944683544302, -1.43299224137931 ], [ 29.789675189873417, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11593, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.43299224137931 ], [ 29.789944683544302, -1.433261724137931 ], [ 29.790214177215191, -1.433261724137931 ], [ 29.790214177215191, -1.43299224137931 ], [ 29.789944683544302, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11594, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.43299224137931 ], [ 29.790214177215191, -1.433261724137931 ], [ 29.790483670886076, -1.433261724137931 ], [ 29.790483670886076, -1.43299224137931 ], [ 29.790214177215191, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11595, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.43299224137931 ], [ 29.790483670886076, -1.433261724137931 ], [ 29.790753164556961, -1.433261724137931 ], [ 29.790753164556961, -1.43299224137931 ], [ 29.790483670886076, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11596, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.43299224137931 ], [ 29.790753164556961, -1.433261724137931 ], [ 29.791022658227849, -1.433261724137931 ], [ 29.791022658227849, -1.43299224137931 ], [ 29.790753164556961, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11597, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.43299224137931 ], [ 29.791022658227849, -1.433261724137931 ], [ 29.791292151898734, -1.433261724137931 ], [ 29.791292151898734, -1.43299224137931 ], [ 29.791022658227849, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11598, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.43299224137931 ], [ 29.791292151898734, -1.433261724137931 ], [ 29.791561645569619, -1.433261724137931 ], [ 29.791561645569619, -1.43299224137931 ], [ 29.791292151898734, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11599, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.43299224137931 ], [ 29.791561645569619, -1.433261724137931 ], [ 29.791831139240507, -1.433261724137931 ], [ 29.791831139240507, -1.43299224137931 ], [ 29.791561645569619, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11600, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.43299224137931 ], [ 29.791831139240507, -1.433261724137931 ], [ 29.792100632911392, -1.433261724137931 ], [ 29.792100632911392, -1.43299224137931 ], [ 29.791831139240507, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11601, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.43299224137931 ], [ 29.792100632911392, -1.433261724137931 ], [ 29.792370126582277, -1.433261724137931 ], [ 29.792370126582277, -1.43299224137931 ], [ 29.792100632911392, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11602, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.43299224137931 ], [ 29.792370126582277, -1.433261724137931 ], [ 29.792909113924051, -1.433261724137931 ], [ 29.792909113924051, -1.43299224137931 ], [ 29.792370126582277, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11603, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.43299224137931 ], [ 29.792909113924051, -1.433261724137931 ], [ 29.793178607594935, -1.433261724137931 ], [ 29.793178607594935, -1.43299224137931 ], [ 29.792909113924051, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11604, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.43299224137931 ], [ 29.793178607594935, -1.433261724137931 ], [ 29.793448101265824, -1.433261724137931 ], [ 29.793448101265824, -1.43299224137931 ], [ 29.793178607594935, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11605, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.43299224137931 ], [ 29.793448101265824, -1.433261724137931 ], [ 29.793717594936709, -1.433261724137931 ], [ 29.793717594936709, -1.43299224137931 ], [ 29.793448101265824, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11606, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.43299224137931 ], [ 29.793717594936709, -1.433261724137931 ], [ 29.793987088607594, -1.433261724137931 ], [ 29.793987088607594, -1.43299224137931 ], [ 29.793717594936709, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11607, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.43299224137931 ], [ 29.793987088607594, -1.433261724137931 ], [ 29.794256582278482, -1.433261724137931 ], [ 29.794256582278482, -1.43299224137931 ], [ 29.793987088607594, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11608, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.43299224137931 ], [ 29.794256582278482, -1.433261724137931 ], [ 29.794526075949367, -1.433261724137931 ], [ 29.794526075949367, -1.43299224137931 ], [ 29.794256582278482, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11609, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.43299224137931 ], [ 29.794526075949367, -1.433261724137931 ], [ 29.794795569620252, -1.433261724137931 ], [ 29.794795569620252, -1.43299224137931 ], [ 29.794526075949367, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11610, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.43299224137931 ], [ 29.794795569620252, -1.433261724137931 ], [ 29.79506506329114, -1.433261724137931 ], [ 29.79506506329114, -1.43299224137931 ], [ 29.794795569620252, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11611, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.43299224137931 ], [ 29.79506506329114, -1.433261724137931 ], [ 29.795334556962025, -1.433261724137931 ], [ 29.795334556962025, -1.43299224137931 ], [ 29.79506506329114, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11612, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.43299224137931 ], [ 29.795334556962025, -1.433261724137931 ], [ 29.79560405063291, -1.433261724137931 ], [ 29.79560405063291, -1.43299224137931 ], [ 29.795334556962025, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11613, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.43299224137931 ], [ 29.79560405063291, -1.433261724137931 ], [ 29.795873544303799, -1.433261724137931 ], [ 29.795873544303799, -1.43299224137931 ], [ 29.79560405063291, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11614, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.43299224137931 ], [ 29.795873544303799, -1.433261724137931 ], [ 29.796143037974684, -1.433261724137931 ], [ 29.796143037974684, -1.43299224137931 ], [ 29.795873544303799, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11615, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.43299224137931 ], [ 29.796143037974684, -1.433261724137931 ], [ 29.796412531645569, -1.433261724137931 ], [ 29.796412531645569, -1.43299224137931 ], [ 29.796143037974684, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11616, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.43299224137931 ], [ 29.796412531645569, -1.433261724137931 ], [ 29.796682025316457, -1.433261724137931 ], [ 29.796682025316457, -1.43299224137931 ], [ 29.796412531645569, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11617, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.43299224137931 ], [ 29.801263417721518, -1.434878620689655 ], [ 29.801532911392407, -1.434878620689655 ], [ 29.801532911392407, -1.43299224137931 ], [ 29.801263417721518, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11618, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.43299224137931 ], [ 29.801532911392407, -1.434878620689655 ], [ 29.802071898734177, -1.434878620689655 ], [ 29.802071898734177, -1.43299224137931 ], [ 29.801532911392407, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11619, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.43299224137931 ], [ 29.802341392405065, -1.434878620689655 ], [ 29.80261088607595, -1.434878620689655 ], [ 29.80261088607595, -1.43299224137931 ], [ 29.802341392405065, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11620, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.43299224137931 ], [ 29.80261088607595, -1.434878620689655 ], [ 29.802880379746835, -1.434878620689655 ], [ 29.802880379746835, -1.43299224137931 ], [ 29.80261088607595, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11621, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.43299224137931 ], [ 29.802880379746835, -1.433261724137931 ], [ 29.803149873417723, -1.433261724137931 ], [ 29.803149873417723, -1.43299224137931 ], [ 29.802880379746835, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11622, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.43299224137931 ], [ 29.803149873417723, -1.433261724137931 ], [ 29.803419367088608, -1.433261724137931 ], [ 29.803419367088608, -1.43299224137931 ], [ 29.803149873417723, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11623, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.43299224137931 ], [ 29.803419367088608, -1.433261724137931 ], [ 29.803688860759493, -1.433261724137931 ], [ 29.803688860759493, -1.43299224137931 ], [ 29.803419367088608, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11624, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.43299224137931 ], [ 29.803688860759493, -1.433261724137931 ], [ 29.804227848101267, -1.433261724137931 ], [ 29.804227848101267, -1.43299224137931 ], [ 29.803688860759493, -1.43299224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11625, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.433261724137931 ], [ 29.745747721518985, -1.433531206896552 ], [ 29.747095189873416, -1.433531206896552 ], [ 29.747095189873416, -1.433261724137931 ], [ 29.745747721518985, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11626, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.433261724137931 ], [ 29.747095189873416, -1.433531206896552 ], [ 29.747364683544301, -1.433531206896552 ], [ 29.747364683544301, -1.433261724137931 ], [ 29.747095189873416, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11627, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.433261724137931 ], [ 29.747364683544301, -1.433531206896552 ], [ 29.748712151898733, -1.433531206896552 ], [ 29.748712151898733, -1.433261724137931 ], [ 29.747364683544301, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11628, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.433261724137931 ], [ 29.748712151898733, -1.433531206896552 ], [ 29.748981645569618, -1.433531206896552 ], [ 29.748981645569618, -1.433261724137931 ], [ 29.748712151898733, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11629, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.433261724137931 ], [ 29.748981645569618, -1.433531206896552 ], [ 29.749520632911391, -1.433531206896552 ], [ 29.749520632911391, -1.433261724137931 ], [ 29.748981645569618, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11630, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.433261724137931 ], [ 29.757335949367089, -1.433531206896552 ], [ 29.757605443037974, -1.433531206896552 ], [ 29.757605443037974, -1.433261724137931 ], [ 29.757335949367089, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11631, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.433261724137931 ], [ 29.757605443037974, -1.433531206896552 ], [ 29.757874936708859, -1.433531206896552 ], [ 29.757874936708859, -1.433261724137931 ], [ 29.757605443037974, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11632, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.433261724137931 ], [ 29.757874936708859, -1.433531206896552 ], [ 29.758144430379748, -1.433531206896552 ], [ 29.758144430379748, -1.433261724137931 ], [ 29.757874936708859, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11633, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.433261724137931 ], [ 29.758144430379748, -1.433531206896552 ], [ 29.758413924050632, -1.433531206896552 ], [ 29.758413924050632, -1.433261724137931 ], [ 29.758144430379748, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11634, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.433261724137931 ], [ 29.758413924050632, -1.433531206896552 ], [ 29.758952911392406, -1.433531206896552 ], [ 29.758952911392406, -1.433261724137931 ], [ 29.758413924050632, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11635, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.433261724137931 ], [ 29.758952911392406, -1.433531206896552 ], [ 29.759491898734176, -1.433531206896552 ], [ 29.759491898734176, -1.433261724137931 ], [ 29.758952911392406, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11636, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.433261724137931 ], [ 29.759491898734176, -1.433531206896552 ], [ 29.760030886075949, -1.433531206896552 ], [ 29.760030886075949, -1.433261724137931 ], [ 29.759491898734176, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11637, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.433261724137931 ], [ 29.760030886075949, -1.433531206896552 ], [ 29.760300379746834, -1.433531206896552 ], [ 29.760300379746834, -1.433261724137931 ], [ 29.760030886075949, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11638, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.433261724137931 ], [ 29.760300379746834, -1.433531206896552 ], [ 29.761647848101266, -1.433531206896552 ], [ 29.761647848101266, -1.433261724137931 ], [ 29.760300379746834, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11639, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.433261724137931 ], [ 29.761647848101266, -1.433531206896552 ], [ 29.761917341772151, -1.433531206896552 ], [ 29.761917341772151, -1.433261724137931 ], [ 29.761647848101266, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11640, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.433261724137931 ], [ 29.761917341772151, -1.433531206896552 ], [ 29.762186835443039, -1.433531206896552 ], [ 29.762186835443039, -1.433261724137931 ], [ 29.761917341772151, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11641, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.433261724137931 ], [ 29.762186835443039, -1.433531206896552 ], [ 29.762456329113924, -1.433531206896552 ], [ 29.762456329113924, -1.433261724137931 ], [ 29.762186835443039, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11642, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.433261724137931 ], [ 29.762456329113924, -1.433531206896552 ], [ 29.762725822784809, -1.433531206896552 ], [ 29.762725822784809, -1.433261724137931 ], [ 29.762456329113924, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11643, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.433261724137931 ], [ 29.762725822784809, -1.433531206896552 ], [ 29.762995316455697, -1.433531206896552 ], [ 29.762995316455697, -1.433261724137931 ], [ 29.762725822784809, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11644, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.433261724137931 ], [ 29.762995316455697, -1.433531206896552 ], [ 29.763264810126582, -1.433531206896552 ], [ 29.763264810126582, -1.433261724137931 ], [ 29.762995316455697, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11645, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.433261724137931 ], [ 29.763264810126582, -1.433531206896552 ], [ 29.763534303797467, -1.433531206896552 ], [ 29.763534303797467, -1.433261724137931 ], [ 29.763264810126582, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11646, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.433261724137931 ], [ 29.763534303797467, -1.433531206896552 ], [ 29.763803797468356, -1.433531206896552 ], [ 29.763803797468356, -1.433261724137931 ], [ 29.763534303797467, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11647, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.433261724137931 ], [ 29.763803797468356, -1.433531206896552 ], [ 29.76407329113924, -1.433531206896552 ], [ 29.76407329113924, -1.433261724137931 ], [ 29.763803797468356, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11648, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.433261724137931 ], [ 29.76407329113924, -1.433531206896552 ], [ 29.764342784810125, -1.433531206896552 ], [ 29.764342784810125, -1.433261724137931 ], [ 29.76407329113924, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11649, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.433261724137931 ], [ 29.764342784810125, -1.433531206896552 ], [ 29.764612278481014, -1.433531206896552 ], [ 29.764612278481014, -1.433261724137931 ], [ 29.764342784810125, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11650, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.433261724137931 ], [ 29.764612278481014, -1.433531206896552 ], [ 29.764881772151899, -1.433531206896552 ], [ 29.764881772151899, -1.433261724137931 ], [ 29.764612278481014, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11651, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.433261724137931 ], [ 29.764881772151899, -1.433531206896552 ], [ 29.765151265822784, -1.433531206896552 ], [ 29.765151265822784, -1.433261724137931 ], [ 29.764881772151899, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11652, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.433261724137931 ], [ 29.765151265822784, -1.433531206896552 ], [ 29.765420759493672, -1.433531206896552 ], [ 29.765420759493672, -1.433261724137931 ], [ 29.765151265822784, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11653, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.433261724137931 ], [ 29.765420759493672, -1.433531206896552 ], [ 29.765959746835442, -1.433531206896552 ], [ 29.765959746835442, -1.433261724137931 ], [ 29.765420759493672, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11654, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.433261724137931 ], [ 29.765959746835442, -1.433531206896552 ], [ 29.766229240506327, -1.433531206896552 ], [ 29.766229240506327, -1.433261724137931 ], [ 29.765959746835442, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11655, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.433261724137931 ], [ 29.766229240506327, -1.433531206896552 ], [ 29.766498734177215, -1.433531206896552 ], [ 29.766498734177215, -1.433261724137931 ], [ 29.766229240506327, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11656, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.433261724137931 ], [ 29.766498734177215, -1.433531206896552 ], [ 29.7667682278481, -1.433531206896552 ], [ 29.7667682278481, -1.433261724137931 ], [ 29.766498734177215, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11657, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.433261724137931 ], [ 29.7667682278481, -1.433531206896552 ], [ 29.767037721518985, -1.433531206896552 ], [ 29.767037721518985, -1.433261724137931 ], [ 29.7667682278481, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11658, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.433261724137931 ], [ 29.767037721518985, -1.433531206896552 ], [ 29.767307215189874, -1.433531206896552 ], [ 29.767307215189874, -1.433261724137931 ], [ 29.767037721518985, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11659, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.433261724137931 ], [ 29.767307215189874, -1.433800689655172 ], [ 29.767576708860759, -1.433800689655172 ], [ 29.767576708860759, -1.433261724137931 ], [ 29.767307215189874, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11660, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.433261724137931 ], [ 29.767576708860759, -1.433531206896552 ], [ 29.767846202531643, -1.433531206896552 ], [ 29.767846202531643, -1.433261724137931 ], [ 29.767576708860759, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11661, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.433261724137931 ], [ 29.767846202531643, -1.433531206896552 ], [ 29.768115696202532, -1.433531206896552 ], [ 29.768115696202532, -1.433261724137931 ], [ 29.767846202531643, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11662, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.433261724137931 ], [ 29.768115696202532, -1.433531206896552 ], [ 29.768385189873417, -1.433531206896552 ], [ 29.768385189873417, -1.433261724137931 ], [ 29.768115696202532, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11663, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.433261724137931 ], [ 29.768654683544302, -1.433531206896552 ], [ 29.76892417721519, -1.433531206896552 ], [ 29.76892417721519, -1.433261724137931 ], [ 29.768654683544302, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11664, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.433261724137931 ], [ 29.76892417721519, -1.433531206896552 ], [ 29.769193670886075, -1.433531206896552 ], [ 29.769193670886075, -1.433261724137931 ], [ 29.76892417721519, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11665, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.433261724137931 ], [ 29.769193670886075, -1.433800689655172 ], [ 29.76946316455696, -1.433800689655172 ], [ 29.76946316455696, -1.433261724137931 ], [ 29.769193670886075, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11666, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.433261724137931 ], [ 29.76946316455696, -1.433531206896552 ], [ 29.769732658227849, -1.433531206896552 ], [ 29.769732658227849, -1.433261724137931 ], [ 29.76946316455696, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11667, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.433261724137931 ], [ 29.769732658227849, -1.433531206896552 ], [ 29.770002151898733, -1.433531206896552 ], [ 29.770002151898733, -1.433261724137931 ], [ 29.769732658227849, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11668, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.433261724137931 ], [ 29.770002151898733, -1.433800689655172 ], [ 29.770271645569618, -1.433800689655172 ], [ 29.770271645569618, -1.433261724137931 ], [ 29.770002151898733, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11669, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.433261724137931 ], [ 29.770541139240507, -1.433531206896552 ], [ 29.770810632911392, -1.433531206896552 ], [ 29.770810632911392, -1.433261724137931 ], [ 29.770541139240507, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11670, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.433261724137931 ], [ 29.770810632911392, -1.433531206896552 ], [ 29.771080126582277, -1.433531206896552 ], [ 29.771080126582277, -1.433261724137931 ], [ 29.770810632911392, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11671, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.433261724137931 ], [ 29.771349620253165, -1.433531206896552 ], [ 29.77161911392405, -1.433531206896552 ], [ 29.77161911392405, -1.433261724137931 ], [ 29.771349620253165, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11672, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.433261724137931 ], [ 29.77161911392405, -1.433531206896552 ], [ 29.771888607594935, -1.433531206896552 ], [ 29.771888607594935, -1.433261724137931 ], [ 29.77161911392405, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11673, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.433261724137931 ], [ 29.771888607594935, -1.433800689655172 ], [ 29.772158101265823, -1.433800689655172 ], [ 29.772158101265823, -1.433261724137931 ], [ 29.771888607594935, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11674, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.433261724137931 ], [ 29.772158101265823, -1.433531206896552 ], [ 29.772427594936708, -1.433531206896552 ], [ 29.772427594936708, -1.433261724137931 ], [ 29.772158101265823, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11675, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.433261724137931 ], [ 29.772427594936708, -1.433531206896552 ], [ 29.772697088607593, -1.433531206896552 ], [ 29.772697088607593, -1.433261724137931 ], [ 29.772427594936708, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11676, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.433261724137931 ], [ 29.772697088607593, -1.433531206896552 ], [ 29.772966582278482, -1.433531206896552 ], [ 29.772966582278482, -1.433261724137931 ], [ 29.772697088607593, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11677, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.433261724137931 ], [ 29.773236075949367, -1.433531206896552 ], [ 29.773505569620252, -1.433531206896552 ], [ 29.773505569620252, -1.433261724137931 ], [ 29.773236075949367, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11678, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.433261724137931 ], [ 29.773505569620252, -1.434070172413793 ], [ 29.77377506329114, -1.434070172413793 ], [ 29.77377506329114, -1.433261724137931 ], [ 29.773505569620252, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11679, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.433261724137931 ], [ 29.774044556962025, -1.433531206896552 ], [ 29.77431405063291, -1.433531206896552 ], [ 29.77431405063291, -1.433261724137931 ], [ 29.774044556962025, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11680, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.433261724137931 ], [ 29.77431405063291, -1.433800689655172 ], [ 29.774853037974683, -1.433800689655172 ], [ 29.774853037974683, -1.433261724137931 ], [ 29.77431405063291, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11681, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.433261724137931 ], [ 29.775122531645568, -1.433531206896552 ], [ 29.775392025316457, -1.433531206896552 ], [ 29.775392025316457, -1.433261724137931 ], [ 29.775122531645568, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11682, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.433261724137931 ], [ 29.775392025316457, -1.433531206896552 ], [ 29.775661518987341, -1.433531206896552 ], [ 29.775661518987341, -1.433261724137931 ], [ 29.775392025316457, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11683, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.433261724137931 ], [ 29.775931012658226, -1.433531206896552 ], [ 29.776200506329115, -1.433531206896552 ], [ 29.776200506329115, -1.433261724137931 ], [ 29.775931012658226, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11684, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.433261724137931 ], [ 29.776200506329115, -1.433531206896552 ], [ 29.77647, -1.433531206896552 ], [ 29.77647, -1.433261724137931 ], [ 29.776200506329115, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11685, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.433261724137931 ], [ 29.77647, -1.433800689655172 ], [ 29.776739493670885, -1.433800689655172 ], [ 29.776739493670885, -1.433261724137931 ], [ 29.77647, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11686, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.433261724137931 ], [ 29.776739493670885, -1.433531206896552 ], [ 29.777008987341773, -1.433531206896552 ], [ 29.777008987341773, -1.433261724137931 ], [ 29.776739493670885, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11687, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.433261724137931 ], [ 29.777008987341773, -1.433531206896552 ], [ 29.777278481012658, -1.433531206896552 ], [ 29.777278481012658, -1.433261724137931 ], [ 29.777008987341773, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11688, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.433261724137931 ], [ 29.777278481012658, -1.433531206896552 ], [ 29.777547974683543, -1.433531206896552 ], [ 29.777547974683543, -1.433261724137931 ], [ 29.777278481012658, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11689, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.433261724137931 ], [ 29.777817468354431, -1.433531206896552 ], [ 29.778086962025316, -1.433531206896552 ], [ 29.778086962025316, -1.433261724137931 ], [ 29.777817468354431, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11690, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.433261724137931 ], [ 29.778086962025316, -1.433531206896552 ], [ 29.778356455696201, -1.433531206896552 ], [ 29.778356455696201, -1.433261724137931 ], [ 29.778086962025316, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11691, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.433261724137931 ], [ 29.778356455696201, -1.433800689655172 ], [ 29.77862594936709, -1.433800689655172 ], [ 29.77862594936709, -1.433261724137931 ], [ 29.778356455696201, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11692, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.433261724137931 ], [ 29.77862594936709, -1.433531206896552 ], [ 29.778895443037975, -1.433531206896552 ], [ 29.778895443037975, -1.433261724137931 ], [ 29.77862594936709, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11693, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.433261724137931 ], [ 29.778895443037975, -1.433531206896552 ], [ 29.77916493670886, -1.433531206896552 ], [ 29.77916493670886, -1.433261724137931 ], [ 29.778895443037975, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11694, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.433261724137931 ], [ 29.77916493670886, -1.433800689655172 ], [ 29.779434430379748, -1.433800689655172 ], [ 29.779434430379748, -1.433261724137931 ], [ 29.77916493670886, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11695, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.433261724137931 ], [ 29.779703924050633, -1.433531206896552 ], [ 29.779973417721518, -1.433531206896552 ], [ 29.779973417721518, -1.433261724137931 ], [ 29.779703924050633, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11696, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.433261724137931 ], [ 29.779973417721518, -1.433531206896552 ], [ 29.780242911392406, -1.433531206896552 ], [ 29.780242911392406, -1.433261724137931 ], [ 29.779973417721518, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11697, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.433261724137931 ], [ 29.780512405063291, -1.433531206896552 ], [ 29.780781898734176, -1.433531206896552 ], [ 29.780781898734176, -1.433261724137931 ], [ 29.780512405063291, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11698, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.433261724137931 ], [ 29.780781898734176, -1.433531206896552 ], [ 29.781051392405065, -1.433531206896552 ], [ 29.781051392405065, -1.433261724137931 ], [ 29.780781898734176, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11699, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.433261724137931 ], [ 29.781051392405065, -1.433800689655172 ], [ 29.78132088607595, -1.433800689655172 ], [ 29.78132088607595, -1.433261724137931 ], [ 29.781051392405065, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11700, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.433261724137931 ], [ 29.78132088607595, -1.433531206896552 ], [ 29.781590379746834, -1.433531206896552 ], [ 29.781590379746834, -1.433261724137931 ], [ 29.78132088607595, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11701, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.433261724137931 ], [ 29.781590379746834, -1.433531206896552 ], [ 29.781859873417723, -1.433531206896552 ], [ 29.781859873417723, -1.433261724137931 ], [ 29.781590379746834, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11702, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.433261724137931 ], [ 29.781859873417723, -1.433531206896552 ], [ 29.782129367088608, -1.433531206896552 ], [ 29.782129367088608, -1.433261724137931 ], [ 29.781859873417723, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11703, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.433261724137931 ], [ 29.782129367088608, -1.433531206896552 ], [ 29.782398860759493, -1.433531206896552 ], [ 29.782398860759493, -1.433261724137931 ], [ 29.782129367088608, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11704, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.433261724137931 ], [ 29.782398860759493, -1.433531206896552 ], [ 29.782668354430381, -1.433531206896552 ], [ 29.782668354430381, -1.433261724137931 ], [ 29.782398860759493, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11705, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.433261724137931 ], [ 29.782668354430381, -1.433531206896552 ], [ 29.782937848101266, -1.433531206896552 ], [ 29.782937848101266, -1.433261724137931 ], [ 29.782668354430381, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11706, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.433261724137931 ], [ 29.782937848101266, -1.433531206896552 ], [ 29.783207341772151, -1.433531206896552 ], [ 29.783207341772151, -1.433261724137931 ], [ 29.782937848101266, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11707, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.433261724137931 ], [ 29.783207341772151, -1.433531206896552 ], [ 29.783476835443039, -1.433531206896552 ], [ 29.783476835443039, -1.433261724137931 ], [ 29.783207341772151, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11708, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.433261724137931 ], [ 29.783476835443039, -1.433531206896552 ], [ 29.784015822784809, -1.433531206896552 ], [ 29.784015822784809, -1.433261724137931 ], [ 29.783476835443039, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11709, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.433261724137931 ], [ 29.784015822784809, -1.433531206896552 ], [ 29.784285316455698, -1.433531206896552 ], [ 29.784285316455698, -1.433261724137931 ], [ 29.784015822784809, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11710, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.433261724137931 ], [ 29.784285316455698, -1.433531206896552 ], [ 29.784554810126583, -1.433531206896552 ], [ 29.784554810126583, -1.433261724137931 ], [ 29.784285316455698, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11711, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.433261724137931 ], [ 29.784554810126583, -1.433531206896552 ], [ 29.784824303797468, -1.433531206896552 ], [ 29.784824303797468, -1.433261724137931 ], [ 29.784554810126583, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11712, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.433261724137931 ], [ 29.784824303797468, -1.433531206896552 ], [ 29.785093797468356, -1.433531206896552 ], [ 29.785093797468356, -1.433261724137931 ], [ 29.784824303797468, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11713, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.433261724137931 ], [ 29.785093797468356, -1.433531206896552 ], [ 29.785363291139241, -1.433531206896552 ], [ 29.785363291139241, -1.433261724137931 ], [ 29.785093797468356, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11714, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.433261724137931 ], [ 29.785363291139241, -1.433531206896552 ], [ 29.785632784810126, -1.433531206896552 ], [ 29.785632784810126, -1.433261724137931 ], [ 29.785363291139241, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11715, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.433261724137931 ], [ 29.785632784810126, -1.433531206896552 ], [ 29.785902278481014, -1.433531206896552 ], [ 29.785902278481014, -1.433261724137931 ], [ 29.785632784810126, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11716, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.433261724137931 ], [ 29.785902278481014, -1.433531206896552 ], [ 29.786171772151899, -1.433531206896552 ], [ 29.786171772151899, -1.433261724137931 ], [ 29.785902278481014, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11717, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.433261724137931 ], [ 29.786171772151899, -1.433531206896552 ], [ 29.786441265822784, -1.433531206896552 ], [ 29.786441265822784, -1.433261724137931 ], [ 29.786171772151899, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11718, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.433261724137931 ], [ 29.786441265822784, -1.433531206896552 ], [ 29.786710759493673, -1.433531206896552 ], [ 29.786710759493673, -1.433261724137931 ], [ 29.786441265822784, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11719, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.433261724137931 ], [ 29.786710759493673, -1.433531206896552 ], [ 29.786980253164558, -1.433531206896552 ], [ 29.786980253164558, -1.433261724137931 ], [ 29.786710759493673, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11720, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.433261724137931 ], [ 29.786980253164558, -1.433531206896552 ], [ 29.787249746835442, -1.433531206896552 ], [ 29.787249746835442, -1.433261724137931 ], [ 29.786980253164558, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11721, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.433261724137931 ], [ 29.787249746835442, -1.433531206896552 ], [ 29.788058227848101, -1.433531206896552 ], [ 29.788058227848101, -1.433261724137931 ], [ 29.787249746835442, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11722, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.433261724137931 ], [ 29.788058227848101, -1.433531206896552 ], [ 29.788327721518986, -1.433531206896552 ], [ 29.788327721518986, -1.433261724137931 ], [ 29.788058227848101, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11723, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.433261724137931 ], [ 29.788327721518986, -1.433531206896552 ], [ 29.788597215189874, -1.433531206896552 ], [ 29.788597215189874, -1.433261724137931 ], [ 29.788327721518986, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11724, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.433261724137931 ], [ 29.788597215189874, -1.433531206896552 ], [ 29.788866708860759, -1.433531206896552 ], [ 29.788866708860759, -1.433261724137931 ], [ 29.788597215189874, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11725, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.433261724137931 ], [ 29.788866708860759, -1.433531206896552 ], [ 29.789136202531644, -1.433531206896552 ], [ 29.789136202531644, -1.433261724137931 ], [ 29.788866708860759, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11726, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.433261724137931 ], [ 29.789136202531644, -1.433531206896552 ], [ 29.789405696202532, -1.433531206896552 ], [ 29.789405696202532, -1.433261724137931 ], [ 29.789136202531644, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11727, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.433261724137931 ], [ 29.789405696202532, -1.433531206896552 ], [ 29.789675189873417, -1.433531206896552 ], [ 29.789675189873417, -1.433261724137931 ], [ 29.789405696202532, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11728, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.433261724137931 ], [ 29.789675189873417, -1.433531206896552 ], [ 29.789944683544302, -1.433531206896552 ], [ 29.789944683544302, -1.433261724137931 ], [ 29.789675189873417, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11729, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.433261724137931 ], [ 29.789944683544302, -1.433531206896552 ], [ 29.790214177215191, -1.433531206896552 ], [ 29.790214177215191, -1.433261724137931 ], [ 29.789944683544302, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11730, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.433261724137931 ], [ 29.790214177215191, -1.433531206896552 ], [ 29.790483670886076, -1.433531206896552 ], [ 29.790483670886076, -1.433261724137931 ], [ 29.790214177215191, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11731, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.433261724137931 ], [ 29.790483670886076, -1.433531206896552 ], [ 29.790753164556961, -1.433531206896552 ], [ 29.790753164556961, -1.433261724137931 ], [ 29.790483670886076, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11732, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.433261724137931 ], [ 29.790753164556961, -1.433531206896552 ], [ 29.791022658227849, -1.433531206896552 ], [ 29.791022658227849, -1.433261724137931 ], [ 29.790753164556961, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11733, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.433261724137931 ], [ 29.791022658227849, -1.433531206896552 ], [ 29.791292151898734, -1.433531206896552 ], [ 29.791292151898734, -1.433261724137931 ], [ 29.791022658227849, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11734, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.433261724137931 ], [ 29.791292151898734, -1.433531206896552 ], [ 29.791561645569619, -1.433531206896552 ], [ 29.791561645569619, -1.433261724137931 ], [ 29.791292151898734, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11735, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.433261724137931 ], [ 29.791561645569619, -1.433531206896552 ], [ 29.791831139240507, -1.433531206896552 ], [ 29.791831139240507, -1.433261724137931 ], [ 29.791561645569619, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11736, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.433261724137931 ], [ 29.791831139240507, -1.433531206896552 ], [ 29.792100632911392, -1.433531206896552 ], [ 29.792100632911392, -1.433261724137931 ], [ 29.791831139240507, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11737, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.433261724137931 ], [ 29.792100632911392, -1.433531206896552 ], [ 29.792370126582277, -1.433531206896552 ], [ 29.792370126582277, -1.433261724137931 ], [ 29.792100632911392, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11738, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.433261724137931 ], [ 29.792370126582277, -1.433531206896552 ], [ 29.792909113924051, -1.433531206896552 ], [ 29.792909113924051, -1.433261724137931 ], [ 29.792370126582277, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11739, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.433261724137931 ], [ 29.792909113924051, -1.433531206896552 ], [ 29.793178607594935, -1.433531206896552 ], [ 29.793178607594935, -1.433261724137931 ], [ 29.792909113924051, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11740, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.433261724137931 ], [ 29.793178607594935, -1.433531206896552 ], [ 29.793448101265824, -1.433531206896552 ], [ 29.793448101265824, -1.433261724137931 ], [ 29.793178607594935, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11741, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.433261724137931 ], [ 29.793448101265824, -1.433531206896552 ], [ 29.793717594936709, -1.433531206896552 ], [ 29.793717594936709, -1.433261724137931 ], [ 29.793448101265824, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11742, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.433261724137931 ], [ 29.793717594936709, -1.433531206896552 ], [ 29.793987088607594, -1.433531206896552 ], [ 29.793987088607594, -1.433261724137931 ], [ 29.793717594936709, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11743, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.433261724137931 ], [ 29.793987088607594, -1.433531206896552 ], [ 29.794256582278482, -1.433531206896552 ], [ 29.794256582278482, -1.433261724137931 ], [ 29.793987088607594, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11744, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.433261724137931 ], [ 29.794256582278482, -1.433531206896552 ], [ 29.794526075949367, -1.433531206896552 ], [ 29.794526075949367, -1.433261724137931 ], [ 29.794256582278482, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11745, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.433261724137931 ], [ 29.794526075949367, -1.433531206896552 ], [ 29.794795569620252, -1.433531206896552 ], [ 29.794795569620252, -1.433261724137931 ], [ 29.794526075949367, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11746, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.433261724137931 ], [ 29.794795569620252, -1.433531206896552 ], [ 29.79506506329114, -1.433531206896552 ], [ 29.79506506329114, -1.433261724137931 ], [ 29.794795569620252, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11747, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.433261724137931 ], [ 29.79506506329114, -1.433531206896552 ], [ 29.795334556962025, -1.433531206896552 ], [ 29.795334556962025, -1.433261724137931 ], [ 29.79506506329114, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11748, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.433261724137931 ], [ 29.795334556962025, -1.433531206896552 ], [ 29.79560405063291, -1.433531206896552 ], [ 29.79560405063291, -1.433261724137931 ], [ 29.795334556962025, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11749, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.433261724137931 ], [ 29.79560405063291, -1.433531206896552 ], [ 29.795873544303799, -1.433531206896552 ], [ 29.795873544303799, -1.433261724137931 ], [ 29.79560405063291, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11750, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.433261724137931 ], [ 29.795873544303799, -1.433531206896552 ], [ 29.796143037974684, -1.433531206896552 ], [ 29.796143037974684, -1.433261724137931 ], [ 29.795873544303799, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11751, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.433261724137931 ], [ 29.796143037974684, -1.433531206896552 ], [ 29.796412531645569, -1.433531206896552 ], [ 29.796412531645569, -1.433261724137931 ], [ 29.796143037974684, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11752, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.433261724137931 ], [ 29.796412531645569, -1.433531206896552 ], [ 29.796682025316457, -1.433531206896552 ], [ 29.796682025316457, -1.433261724137931 ], [ 29.796412531645569, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11753, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.433261724137931 ], [ 29.796682025316457, -1.433800689655172 ], [ 29.796951518987342, -1.433800689655172 ], [ 29.796951518987342, -1.433261724137931 ], [ 29.796682025316457, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11754, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.433261724137931 ], [ 29.802071898734177, -1.434609137931035 ], [ 29.802341392405065, -1.434609137931035 ], [ 29.802341392405065, -1.433261724137931 ], [ 29.802071898734177, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11755, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.433261724137931 ], [ 29.802880379746835, -1.434609137931035 ], [ 29.803149873417723, -1.434609137931035 ], [ 29.803149873417723, -1.433261724137931 ], [ 29.802880379746835, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11756, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.433261724137931 ], [ 29.803149873417723, -1.434609137931035 ], [ 29.803419367088608, -1.434609137931035 ], [ 29.803419367088608, -1.433261724137931 ], [ 29.803149873417723, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11757, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.433261724137931 ], [ 29.803419367088608, -1.434609137931035 ], [ 29.803688860759493, -1.434609137931035 ], [ 29.803688860759493, -1.433261724137931 ], [ 29.803419367088608, -1.433261724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11758, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.433531206896552 ], [ 29.7454782278481, -1.434070172413793 ], [ 29.745747721518985, -1.434070172413793 ], [ 29.745747721518985, -1.433531206896552 ], [ 29.7454782278481, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11759, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.433531206896552 ], [ 29.745747721518985, -1.433800689655172 ], [ 29.746017215189873, -1.433800689655172 ], [ 29.746017215189873, -1.433531206896552 ], [ 29.745747721518985, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11760, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.433531206896552 ], [ 29.746017215189873, -1.433800689655172 ], [ 29.747095189873416, -1.433800689655172 ], [ 29.747095189873416, -1.433531206896552 ], [ 29.746017215189873, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11761, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.433531206896552 ], [ 29.747095189873416, -1.433800689655172 ], [ 29.747364683544301, -1.433800689655172 ], [ 29.747364683544301, -1.433531206896552 ], [ 29.747095189873416, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11762, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.433531206896552 ], [ 29.747364683544301, -1.433800689655172 ], [ 29.748712151898733, -1.433800689655172 ], [ 29.748712151898733, -1.433531206896552 ], [ 29.747364683544301, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11763, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.433531206896552 ], [ 29.748712151898733, -1.433800689655172 ], [ 29.748981645569618, -1.433800689655172 ], [ 29.748981645569618, -1.433531206896552 ], [ 29.748712151898733, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11764, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.433531206896552 ], [ 29.748981645569618, -1.433800689655172 ], [ 29.749251139240506, -1.433800689655172 ], [ 29.749251139240506, -1.433531206896552 ], [ 29.748981645569618, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11765, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.433531206896552 ], [ 29.749251139240506, -1.433800689655172 ], [ 29.749520632911391, -1.433800689655172 ], [ 29.749520632911391, -1.433531206896552 ], [ 29.749251139240506, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11766, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.433531206896552 ], [ 29.749520632911391, -1.433800689655172 ], [ 29.750059620253165, -1.433800689655172 ], [ 29.750059620253165, -1.433531206896552 ], [ 29.749520632911391, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11767, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.433531206896552 ], [ 29.754102025316456, -1.434339655172414 ], [ 29.754371518987341, -1.434339655172414 ], [ 29.754371518987341, -1.433531206896552 ], [ 29.754102025316456, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11768, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.433531206896552 ], [ 29.754371518987341, -1.433800689655172 ], [ 29.754641012658226, -1.433800689655172 ], [ 29.754641012658226, -1.433531206896552 ], [ 29.754371518987341, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11769, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.433531206896552 ], [ 29.754641012658226, -1.433800689655172 ], [ 29.754910506329114, -1.433800689655172 ], [ 29.754910506329114, -1.433531206896552 ], [ 29.754641012658226, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11770, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.433531206896552 ], [ 29.754910506329114, -1.433800689655172 ], [ 29.75518, -1.433800689655172 ], [ 29.75518, -1.433531206896552 ], [ 29.754910506329114, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11771, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.433531206896552 ], [ 29.755718987341773, -1.434070172413793 ], [ 29.755988481012658, -1.434070172413793 ], [ 29.755988481012658, -1.433531206896552 ], [ 29.755718987341773, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11772, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.433531206896552 ], [ 29.757066455696201, -1.433800689655172 ], [ 29.757335949367089, -1.433800689655172 ], [ 29.757335949367089, -1.433531206896552 ], [ 29.757066455696201, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11773, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.433531206896552 ], [ 29.757335949367089, -1.433800689655172 ], [ 29.757605443037974, -1.433800689655172 ], [ 29.757605443037974, -1.433531206896552 ], [ 29.757335949367089, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11774, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.433531206896552 ], [ 29.757605443037974, -1.433800689655172 ], [ 29.757874936708859, -1.433800689655172 ], [ 29.757874936708859, -1.433531206896552 ], [ 29.757605443037974, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11775, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.433531206896552 ], [ 29.757874936708859, -1.433800689655172 ], [ 29.758144430379748, -1.433800689655172 ], [ 29.758144430379748, -1.433531206896552 ], [ 29.757874936708859, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11776, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.433531206896552 ], [ 29.758144430379748, -1.433800689655172 ], [ 29.758413924050632, -1.433800689655172 ], [ 29.758413924050632, -1.433531206896552 ], [ 29.758144430379748, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11777, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.433531206896552 ], [ 29.758413924050632, -1.433800689655172 ], [ 29.758683417721517, -1.433800689655172 ], [ 29.758683417721517, -1.433531206896552 ], [ 29.758413924050632, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11778, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.433531206896552 ], [ 29.758683417721517, -1.433800689655172 ], [ 29.759761392405064, -1.433800689655172 ], [ 29.759761392405064, -1.433531206896552 ], [ 29.758683417721517, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11779, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.433531206896552 ], [ 29.759761392405064, -1.433800689655172 ], [ 29.760030886075949, -1.433800689655172 ], [ 29.760030886075949, -1.433531206896552 ], [ 29.759761392405064, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11780, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.433531206896552 ], [ 29.760030886075949, -1.433800689655172 ], [ 29.761378354430381, -1.433800689655172 ], [ 29.761378354430381, -1.433531206896552 ], [ 29.760030886075949, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11781, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.433531206896552 ], [ 29.761378354430381, -1.433800689655172 ], [ 29.761917341772151, -1.433800689655172 ], [ 29.761917341772151, -1.433531206896552 ], [ 29.761378354430381, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11782, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.433531206896552 ], [ 29.761917341772151, -1.433800689655172 ], [ 29.762186835443039, -1.433800689655172 ], [ 29.762186835443039, -1.433531206896552 ], [ 29.761917341772151, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11783, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.433531206896552 ], [ 29.762186835443039, -1.433800689655172 ], [ 29.762456329113924, -1.433800689655172 ], [ 29.762456329113924, -1.433531206896552 ], [ 29.762186835443039, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11784, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.433531206896552 ], [ 29.762456329113924, -1.433800689655172 ], [ 29.762725822784809, -1.433800689655172 ], [ 29.762725822784809, -1.433531206896552 ], [ 29.762456329113924, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11785, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.433531206896552 ], [ 29.762725822784809, -1.433800689655172 ], [ 29.762995316455697, -1.433800689655172 ], [ 29.762995316455697, -1.433531206896552 ], [ 29.762725822784809, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11786, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.433531206896552 ], [ 29.762995316455697, -1.433800689655172 ], [ 29.763264810126582, -1.433800689655172 ], [ 29.763264810126582, -1.433531206896552 ], [ 29.762995316455697, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11787, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.433531206896552 ], [ 29.763264810126582, -1.433800689655172 ], [ 29.763534303797467, -1.433800689655172 ], [ 29.763534303797467, -1.433531206896552 ], [ 29.763264810126582, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11788, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.433531206896552 ], [ 29.763534303797467, -1.433800689655172 ], [ 29.763803797468356, -1.433800689655172 ], [ 29.763803797468356, -1.433531206896552 ], [ 29.763534303797467, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11789, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.433531206896552 ], [ 29.763803797468356, -1.433800689655172 ], [ 29.76407329113924, -1.433800689655172 ], [ 29.76407329113924, -1.433531206896552 ], [ 29.763803797468356, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11790, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.433531206896552 ], [ 29.76407329113924, -1.433800689655172 ], [ 29.764342784810125, -1.433800689655172 ], [ 29.764342784810125, -1.433531206896552 ], [ 29.76407329113924, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11791, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.433531206896552 ], [ 29.764342784810125, -1.433800689655172 ], [ 29.764612278481014, -1.433800689655172 ], [ 29.764612278481014, -1.433531206896552 ], [ 29.764342784810125, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11792, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.433531206896552 ], [ 29.764612278481014, -1.433800689655172 ], [ 29.764881772151899, -1.433800689655172 ], [ 29.764881772151899, -1.433531206896552 ], [ 29.764612278481014, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11793, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.433531206896552 ], [ 29.764881772151899, -1.433800689655172 ], [ 29.765151265822784, -1.433800689655172 ], [ 29.765151265822784, -1.433531206896552 ], [ 29.764881772151899, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11794, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.433531206896552 ], [ 29.765151265822784, -1.433800689655172 ], [ 29.765420759493672, -1.433800689655172 ], [ 29.765420759493672, -1.433531206896552 ], [ 29.765151265822784, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11795, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.433531206896552 ], [ 29.765420759493672, -1.433800689655172 ], [ 29.765959746835442, -1.433800689655172 ], [ 29.765959746835442, -1.433531206896552 ], [ 29.765420759493672, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11796, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.433531206896552 ], [ 29.765959746835442, -1.433800689655172 ], [ 29.766229240506327, -1.433800689655172 ], [ 29.766229240506327, -1.433531206896552 ], [ 29.765959746835442, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11797, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.433531206896552 ], [ 29.766229240506327, -1.434070172413793 ], [ 29.766498734177215, -1.434070172413793 ], [ 29.766498734177215, -1.433531206896552 ], [ 29.766229240506327, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11798, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.433531206896552 ], [ 29.766498734177215, -1.433800689655172 ], [ 29.7667682278481, -1.433800689655172 ], [ 29.7667682278481, -1.433531206896552 ], [ 29.766498734177215, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11799, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.433531206896552 ], [ 29.7667682278481, -1.433800689655172 ], [ 29.767037721518985, -1.433800689655172 ], [ 29.767037721518985, -1.433531206896552 ], [ 29.7667682278481, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11800, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.433531206896552 ], [ 29.767037721518985, -1.433800689655172 ], [ 29.767307215189874, -1.433800689655172 ], [ 29.767307215189874, -1.433531206896552 ], [ 29.767037721518985, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11801, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.433531206896552 ], [ 29.767576708860759, -1.433800689655172 ], [ 29.767846202531643, -1.433800689655172 ], [ 29.767846202531643, -1.433531206896552 ], [ 29.767576708860759, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11802, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.433531206896552 ], [ 29.767846202531643, -1.433800689655172 ], [ 29.768115696202532, -1.433800689655172 ], [ 29.768115696202532, -1.433531206896552 ], [ 29.767846202531643, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11803, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.433531206896552 ], [ 29.768115696202532, -1.434070172413793 ], [ 29.768385189873417, -1.434070172413793 ], [ 29.768385189873417, -1.433531206896552 ], [ 29.768115696202532, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11804, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.433531206896552 ], [ 29.768385189873417, -1.433800689655172 ], [ 29.768654683544302, -1.433800689655172 ], [ 29.768654683544302, -1.433531206896552 ], [ 29.768385189873417, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11805, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.433531206896552 ], [ 29.768654683544302, -1.433800689655172 ], [ 29.76892417721519, -1.433800689655172 ], [ 29.76892417721519, -1.433531206896552 ], [ 29.768654683544302, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11806, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.433531206896552 ], [ 29.76892417721519, -1.434070172413793 ], [ 29.769193670886075, -1.434070172413793 ], [ 29.769193670886075, -1.433531206896552 ], [ 29.76892417721519, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11807, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.433531206896552 ], [ 29.76946316455696, -1.433800689655172 ], [ 29.769732658227849, -1.433800689655172 ], [ 29.769732658227849, -1.433531206896552 ], [ 29.76946316455696, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11808, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.433531206896552 ], [ 29.769732658227849, -1.433800689655172 ], [ 29.770002151898733, -1.433800689655172 ], [ 29.770002151898733, -1.433531206896552 ], [ 29.769732658227849, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11809, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.433531206896552 ], [ 29.770271645569618, -1.433800689655172 ], [ 29.770541139240507, -1.433800689655172 ], [ 29.770541139240507, -1.433531206896552 ], [ 29.770271645569618, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11810, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.433531206896552 ], [ 29.770541139240507, -1.433800689655172 ], [ 29.770810632911392, -1.433800689655172 ], [ 29.770810632911392, -1.433531206896552 ], [ 29.770541139240507, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11811, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.433531206896552 ], [ 29.770810632911392, -1.434070172413793 ], [ 29.771080126582277, -1.434070172413793 ], [ 29.771080126582277, -1.433531206896552 ], [ 29.770810632911392, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11812, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.433531206896552 ], [ 29.771080126582277, -1.433800689655172 ], [ 29.771349620253165, -1.433800689655172 ], [ 29.771349620253165, -1.433531206896552 ], [ 29.771080126582277, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11813, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.433531206896552 ], [ 29.771349620253165, -1.433800689655172 ], [ 29.77161911392405, -1.433800689655172 ], [ 29.77161911392405, -1.433531206896552 ], [ 29.771349620253165, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11814, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.433531206896552 ], [ 29.77161911392405, -1.433800689655172 ], [ 29.771888607594935, -1.433800689655172 ], [ 29.771888607594935, -1.433531206896552 ], [ 29.77161911392405, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11815, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.433531206896552 ], [ 29.772158101265823, -1.433800689655172 ], [ 29.772427594936708, -1.433800689655172 ], [ 29.772427594936708, -1.433531206896552 ], [ 29.772158101265823, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11816, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.433531206896552 ], [ 29.772427594936708, -1.433800689655172 ], [ 29.772697088607593, -1.433800689655172 ], [ 29.772697088607593, -1.433531206896552 ], [ 29.772427594936708, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11817, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.433531206896552 ], [ 29.772697088607593, -1.434070172413793 ], [ 29.772966582278482, -1.434070172413793 ], [ 29.772966582278482, -1.433531206896552 ], [ 29.772697088607593, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11818, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.433531206896552 ], [ 29.772966582278482, -1.433800689655172 ], [ 29.773236075949367, -1.433800689655172 ], [ 29.773236075949367, -1.433531206896552 ], [ 29.772966582278482, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11819, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.433531206896552 ], [ 29.773236075949367, -1.433800689655172 ], [ 29.773505569620252, -1.433800689655172 ], [ 29.773505569620252, -1.433531206896552 ], [ 29.773236075949367, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11820, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.433531206896552 ], [ 29.77377506329114, -1.433800689655172 ], [ 29.774044556962025, -1.433800689655172 ], [ 29.774044556962025, -1.433531206896552 ], [ 29.77377506329114, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11821, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.433531206896552 ], [ 29.774044556962025, -1.433800689655172 ], [ 29.77431405063291, -1.433800689655172 ], [ 29.77431405063291, -1.433531206896552 ], [ 29.774044556962025, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11822, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.433531206896552 ], [ 29.774853037974683, -1.433800689655172 ], [ 29.775122531645568, -1.433800689655172 ], [ 29.775122531645568, -1.433531206896552 ], [ 29.774853037974683, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11823, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.433531206896552 ], [ 29.775122531645568, -1.433800689655172 ], [ 29.775392025316457, -1.433800689655172 ], [ 29.775392025316457, -1.433531206896552 ], [ 29.775122531645568, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11824, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.433531206896552 ], [ 29.775392025316457, -1.434070172413793 ], [ 29.775661518987341, -1.434070172413793 ], [ 29.775661518987341, -1.433531206896552 ], [ 29.775392025316457, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11825, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.433531206896552 ], [ 29.775661518987341, -1.433800689655172 ], [ 29.775931012658226, -1.433800689655172 ], [ 29.775931012658226, -1.433531206896552 ], [ 29.775661518987341, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11826, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.433531206896552 ], [ 29.775931012658226, -1.433800689655172 ], [ 29.776200506329115, -1.433800689655172 ], [ 29.776200506329115, -1.433531206896552 ], [ 29.775931012658226, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11827, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.433531206896552 ], [ 29.776200506329115, -1.433800689655172 ], [ 29.77647, -1.433800689655172 ], [ 29.77647, -1.433531206896552 ], [ 29.776200506329115, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11828, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.433531206896552 ], [ 29.776739493670885, -1.433800689655172 ], [ 29.777008987341773, -1.433800689655172 ], [ 29.777008987341773, -1.433531206896552 ], [ 29.776739493670885, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11829, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.433531206896552 ], [ 29.777008987341773, -1.433800689655172 ], [ 29.777278481012658, -1.433800689655172 ], [ 29.777278481012658, -1.433531206896552 ], [ 29.777008987341773, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11830, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.433531206896552 ], [ 29.777278481012658, -1.434070172413793 ], [ 29.777547974683543, -1.434070172413793 ], [ 29.777547974683543, -1.433531206896552 ], [ 29.777278481012658, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11831, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.433531206896552 ], [ 29.777547974683543, -1.433800689655172 ], [ 29.777817468354431, -1.433800689655172 ], [ 29.777817468354431, -1.433531206896552 ], [ 29.777547974683543, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11832, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.433531206896552 ], [ 29.777817468354431, -1.433800689655172 ], [ 29.778086962025316, -1.433800689655172 ], [ 29.778086962025316, -1.433531206896552 ], [ 29.777817468354431, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11833, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.433531206896552 ], [ 29.778086962025316, -1.434070172413793 ], [ 29.778356455696201, -1.434070172413793 ], [ 29.778356455696201, -1.433531206896552 ], [ 29.778086962025316, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11834, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.433531206896552 ], [ 29.77862594936709, -1.433800689655172 ], [ 29.778895443037975, -1.433800689655172 ], [ 29.778895443037975, -1.433531206896552 ], [ 29.77862594936709, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11835, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.433531206896552 ], [ 29.778895443037975, -1.433800689655172 ], [ 29.77916493670886, -1.433800689655172 ], [ 29.77916493670886, -1.433531206896552 ], [ 29.778895443037975, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11836, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.433531206896552 ], [ 29.779434430379748, -1.433800689655172 ], [ 29.779703924050633, -1.433800689655172 ], [ 29.779703924050633, -1.433531206896552 ], [ 29.779434430379748, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11837, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.433531206896552 ], [ 29.779703924050633, -1.433800689655172 ], [ 29.779973417721518, -1.433800689655172 ], [ 29.779973417721518, -1.433531206896552 ], [ 29.779703924050633, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11838, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.433531206896552 ], [ 29.779973417721518, -1.434070172413793 ], [ 29.780242911392406, -1.434070172413793 ], [ 29.780242911392406, -1.433531206896552 ], [ 29.779973417721518, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11839, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.433531206896552 ], [ 29.780242911392406, -1.433800689655172 ], [ 29.780512405063291, -1.433800689655172 ], [ 29.780512405063291, -1.433531206896552 ], [ 29.780242911392406, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11840, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.433531206896552 ], [ 29.780512405063291, -1.433800689655172 ], [ 29.780781898734176, -1.433800689655172 ], [ 29.780781898734176, -1.433531206896552 ], [ 29.780512405063291, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11841, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.433531206896552 ], [ 29.780781898734176, -1.433800689655172 ], [ 29.781051392405065, -1.433800689655172 ], [ 29.781051392405065, -1.433531206896552 ], [ 29.780781898734176, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11842, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.433531206896552 ], [ 29.78132088607595, -1.433800689655172 ], [ 29.781590379746834, -1.433800689655172 ], [ 29.781590379746834, -1.433531206896552 ], [ 29.78132088607595, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11843, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.433531206896552 ], [ 29.781590379746834, -1.433800689655172 ], [ 29.781859873417723, -1.433800689655172 ], [ 29.781859873417723, -1.433531206896552 ], [ 29.781590379746834, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11844, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.433531206896552 ], [ 29.781859873417723, -1.433800689655172 ], [ 29.782129367088608, -1.433800689655172 ], [ 29.782129367088608, -1.433531206896552 ], [ 29.781859873417723, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11845, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.433531206896552 ], [ 29.782129367088608, -1.433800689655172 ], [ 29.782398860759493, -1.433800689655172 ], [ 29.782398860759493, -1.433531206896552 ], [ 29.782129367088608, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11846, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.433531206896552 ], [ 29.782398860759493, -1.433800689655172 ], [ 29.782668354430381, -1.433800689655172 ], [ 29.782668354430381, -1.433531206896552 ], [ 29.782398860759493, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11847, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.433531206896552 ], [ 29.782668354430381, -1.433800689655172 ], [ 29.782937848101266, -1.433800689655172 ], [ 29.782937848101266, -1.433531206896552 ], [ 29.782668354430381, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11848, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.433531206896552 ], [ 29.782937848101266, -1.433800689655172 ], [ 29.783207341772151, -1.433800689655172 ], [ 29.783207341772151, -1.433531206896552 ], [ 29.782937848101266, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11849, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.433531206896552 ], [ 29.783207341772151, -1.433800689655172 ], [ 29.783476835443039, -1.433800689655172 ], [ 29.783476835443039, -1.433531206896552 ], [ 29.783207341772151, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11850, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.433531206896552 ], [ 29.783476835443039, -1.433800689655172 ], [ 29.784015822784809, -1.433800689655172 ], [ 29.784015822784809, -1.433531206896552 ], [ 29.783476835443039, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11851, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.433531206896552 ], [ 29.784015822784809, -1.433800689655172 ], [ 29.784285316455698, -1.433800689655172 ], [ 29.784285316455698, -1.433531206896552 ], [ 29.784015822784809, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11852, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.433531206896552 ], [ 29.784285316455698, -1.433800689655172 ], [ 29.784554810126583, -1.433800689655172 ], [ 29.784554810126583, -1.433531206896552 ], [ 29.784285316455698, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11853, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.433531206896552 ], [ 29.784554810126583, -1.433800689655172 ], [ 29.784824303797468, -1.433800689655172 ], [ 29.784824303797468, -1.433531206896552 ], [ 29.784554810126583, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11854, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.433531206896552 ], [ 29.784824303797468, -1.433800689655172 ], [ 29.785093797468356, -1.433800689655172 ], [ 29.785093797468356, -1.433531206896552 ], [ 29.784824303797468, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11855, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.433531206896552 ], [ 29.785093797468356, -1.433800689655172 ], [ 29.785363291139241, -1.433800689655172 ], [ 29.785363291139241, -1.433531206896552 ], [ 29.785093797468356, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11856, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.433531206896552 ], [ 29.785363291139241, -1.433800689655172 ], [ 29.785632784810126, -1.433800689655172 ], [ 29.785632784810126, -1.433531206896552 ], [ 29.785363291139241, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11857, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.433531206896552 ], [ 29.785632784810126, -1.433800689655172 ], [ 29.785902278481014, -1.433800689655172 ], [ 29.785902278481014, -1.433531206896552 ], [ 29.785632784810126, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11858, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.433531206896552 ], [ 29.785902278481014, -1.433800689655172 ], [ 29.786171772151899, -1.433800689655172 ], [ 29.786171772151899, -1.433531206896552 ], [ 29.785902278481014, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11859, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.433531206896552 ], [ 29.786171772151899, -1.433800689655172 ], [ 29.786441265822784, -1.433800689655172 ], [ 29.786441265822784, -1.433531206896552 ], [ 29.786171772151899, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11860, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.433531206896552 ], [ 29.786441265822784, -1.433800689655172 ], [ 29.786710759493673, -1.433800689655172 ], [ 29.786710759493673, -1.433531206896552 ], [ 29.786441265822784, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11861, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.433531206896552 ], [ 29.786710759493673, -1.433800689655172 ], [ 29.786980253164558, -1.433800689655172 ], [ 29.786980253164558, -1.433531206896552 ], [ 29.786710759493673, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11862, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.433531206896552 ], [ 29.786980253164558, -1.433800689655172 ], [ 29.788327721518986, -1.433800689655172 ], [ 29.788327721518986, -1.433531206896552 ], [ 29.786980253164558, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11863, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.433531206896552 ], [ 29.788327721518986, -1.433800689655172 ], [ 29.788597215189874, -1.433800689655172 ], [ 29.788597215189874, -1.433531206896552 ], [ 29.788327721518986, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11864, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.433531206896552 ], [ 29.788597215189874, -1.433800689655172 ], [ 29.788866708860759, -1.433800689655172 ], [ 29.788866708860759, -1.433531206896552 ], [ 29.788597215189874, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11865, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.433531206896552 ], [ 29.788866708860759, -1.433800689655172 ], [ 29.789136202531644, -1.433800689655172 ], [ 29.789136202531644, -1.433531206896552 ], [ 29.788866708860759, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11866, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.433531206896552 ], [ 29.789136202531644, -1.433800689655172 ], [ 29.789405696202532, -1.433800689655172 ], [ 29.789405696202532, -1.433531206896552 ], [ 29.789136202531644, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11867, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.433531206896552 ], [ 29.789405696202532, -1.433800689655172 ], [ 29.789675189873417, -1.433800689655172 ], [ 29.789675189873417, -1.433531206896552 ], [ 29.789405696202532, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11868, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.433531206896552 ], [ 29.789675189873417, -1.433800689655172 ], [ 29.789944683544302, -1.433800689655172 ], [ 29.789944683544302, -1.433531206896552 ], [ 29.789675189873417, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11869, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.433531206896552 ], [ 29.789944683544302, -1.433800689655172 ], [ 29.790214177215191, -1.433800689655172 ], [ 29.790214177215191, -1.433531206896552 ], [ 29.789944683544302, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11870, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.433531206896552 ], [ 29.790214177215191, -1.433800689655172 ], [ 29.790483670886076, -1.433800689655172 ], [ 29.790483670886076, -1.433531206896552 ], [ 29.790214177215191, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11871, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.433531206896552 ], [ 29.790483670886076, -1.433800689655172 ], [ 29.790753164556961, -1.433800689655172 ], [ 29.790753164556961, -1.433531206896552 ], [ 29.790483670886076, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11872, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.433531206896552 ], [ 29.790753164556961, -1.433800689655172 ], [ 29.791022658227849, -1.433800689655172 ], [ 29.791022658227849, -1.433531206896552 ], [ 29.790753164556961, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11873, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.433531206896552 ], [ 29.791022658227849, -1.433800689655172 ], [ 29.791292151898734, -1.433800689655172 ], [ 29.791292151898734, -1.433531206896552 ], [ 29.791022658227849, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11874, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.433531206896552 ], [ 29.791292151898734, -1.433800689655172 ], [ 29.791561645569619, -1.433800689655172 ], [ 29.791561645569619, -1.433531206896552 ], [ 29.791292151898734, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11875, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.433531206896552 ], [ 29.791561645569619, -1.433800689655172 ], [ 29.791831139240507, -1.433800689655172 ], [ 29.791831139240507, -1.433531206896552 ], [ 29.791561645569619, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11876, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.433531206896552 ], [ 29.791831139240507, -1.433800689655172 ], [ 29.792100632911392, -1.433800689655172 ], [ 29.792100632911392, -1.433531206896552 ], [ 29.791831139240507, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11877, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.433531206896552 ], [ 29.792100632911392, -1.433800689655172 ], [ 29.792370126582277, -1.433800689655172 ], [ 29.792370126582277, -1.433531206896552 ], [ 29.792100632911392, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11878, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.433531206896552 ], [ 29.792370126582277, -1.433800689655172 ], [ 29.792909113924051, -1.433800689655172 ], [ 29.792909113924051, -1.433531206896552 ], [ 29.792370126582277, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11879, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.433531206896552 ], [ 29.792909113924051, -1.433800689655172 ], [ 29.793178607594935, -1.433800689655172 ], [ 29.793178607594935, -1.433531206896552 ], [ 29.792909113924051, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11880, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.433531206896552 ], [ 29.793178607594935, -1.433800689655172 ], [ 29.793448101265824, -1.433800689655172 ], [ 29.793448101265824, -1.433531206896552 ], [ 29.793178607594935, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11881, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.433531206896552 ], [ 29.793448101265824, -1.433800689655172 ], [ 29.793717594936709, -1.433800689655172 ], [ 29.793717594936709, -1.433531206896552 ], [ 29.793448101265824, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11882, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.433531206896552 ], [ 29.793717594936709, -1.433800689655172 ], [ 29.793987088607594, -1.433800689655172 ], [ 29.793987088607594, -1.433531206896552 ], [ 29.793717594936709, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11883, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.433531206896552 ], [ 29.793987088607594, -1.433800689655172 ], [ 29.794256582278482, -1.433800689655172 ], [ 29.794256582278482, -1.433531206896552 ], [ 29.793987088607594, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11884, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.433531206896552 ], [ 29.794256582278482, -1.433800689655172 ], [ 29.794526075949367, -1.433800689655172 ], [ 29.794526075949367, -1.433531206896552 ], [ 29.794256582278482, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11885, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.433531206896552 ], [ 29.794526075949367, -1.433800689655172 ], [ 29.794795569620252, -1.433800689655172 ], [ 29.794795569620252, -1.433531206896552 ], [ 29.794526075949367, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11886, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.433531206896552 ], [ 29.794795569620252, -1.433800689655172 ], [ 29.79506506329114, -1.433800689655172 ], [ 29.79506506329114, -1.433531206896552 ], [ 29.794795569620252, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11887, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.433531206896552 ], [ 29.79506506329114, -1.433800689655172 ], [ 29.795334556962025, -1.433800689655172 ], [ 29.795334556962025, -1.433531206896552 ], [ 29.79506506329114, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11888, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.433531206896552 ], [ 29.795334556962025, -1.433800689655172 ], [ 29.79560405063291, -1.433800689655172 ], [ 29.79560405063291, -1.433531206896552 ], [ 29.795334556962025, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11889, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.433531206896552 ], [ 29.79560405063291, -1.433800689655172 ], [ 29.795873544303799, -1.433800689655172 ], [ 29.795873544303799, -1.433531206896552 ], [ 29.79560405063291, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11890, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.433531206896552 ], [ 29.795873544303799, -1.433800689655172 ], [ 29.796143037974684, -1.433800689655172 ], [ 29.796143037974684, -1.433531206896552 ], [ 29.795873544303799, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11891, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.433531206896552 ], [ 29.796143037974684, -1.433800689655172 ], [ 29.796412531645569, -1.433800689655172 ], [ 29.796412531645569, -1.433531206896552 ], [ 29.796143037974684, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11892, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.433531206896552 ], [ 29.796412531645569, -1.433800689655172 ], [ 29.796682025316457, -1.433800689655172 ], [ 29.796682025316457, -1.433531206896552 ], [ 29.796412531645569, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11893, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.433531206896552 ], [ 29.797490506329115, -1.433800689655172 ], [ 29.798029493670885, -1.433800689655172 ], [ 29.798029493670885, -1.433531206896552 ], [ 29.797490506329115, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11894, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.433531206896552 ], [ 29.798029493670885, -1.433800689655172 ], [ 29.798298987341774, -1.433800689655172 ], [ 29.798298987341774, -1.433531206896552 ], [ 29.798029493670885, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11895, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.433531206896552 ], [ 29.798298987341774, -1.433800689655172 ], [ 29.798568481012659, -1.433800689655172 ], [ 29.798568481012659, -1.433531206896552 ], [ 29.798298987341774, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11896, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.433531206896552 ], [ 29.798568481012659, -1.433800689655172 ], [ 29.798837974683543, -1.433800689655172 ], [ 29.798837974683543, -1.433531206896552 ], [ 29.798568481012659, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11897, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.433531206896552 ], [ 29.799376962025317, -1.434070172413793 ], [ 29.799646455696202, -1.434070172413793 ], [ 29.799646455696202, -1.433531206896552 ], [ 29.799376962025317, -1.433531206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11898, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.433800689655172 ], [ 29.745747721518985, -1.434070172413793 ], [ 29.746017215189873, -1.434070172413793 ], [ 29.746017215189873, -1.433800689655172 ], [ 29.745747721518985, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.433800689655172 ], [ 29.746017215189873, -1.434070172413793 ], [ 29.747095189873416, -1.434070172413793 ], [ 29.747095189873416, -1.433800689655172 ], [ 29.746017215189873, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11900, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.433800689655172 ], [ 29.747095189873416, -1.434070172413793 ], [ 29.747364683544301, -1.434070172413793 ], [ 29.747364683544301, -1.433800689655172 ], [ 29.747095189873416, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11901, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.433800689655172 ], [ 29.747364683544301, -1.434070172413793 ], [ 29.747903670886075, -1.434070172413793 ], [ 29.747903670886075, -1.433800689655172 ], [ 29.747364683544301, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11902, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.433800689655172 ], [ 29.747903670886075, -1.434070172413793 ], [ 29.748442658227848, -1.434070172413793 ], [ 29.748442658227848, -1.433800689655172 ], [ 29.747903670886075, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11903, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.433800689655172 ], [ 29.748442658227848, -1.434070172413793 ], [ 29.748712151898733, -1.434070172413793 ], [ 29.748712151898733, -1.433800689655172 ], [ 29.748442658227848, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11904, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.433800689655172 ], [ 29.748712151898733, -1.434070172413793 ], [ 29.748981645569618, -1.434070172413793 ], [ 29.748981645569618, -1.433800689655172 ], [ 29.748712151898733, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11905, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.433800689655172 ], [ 29.748981645569618, -1.434070172413793 ], [ 29.749251139240506, -1.434070172413793 ], [ 29.749251139240506, -1.433800689655172 ], [ 29.748981645569618, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11906, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.433800689655172 ], [ 29.749251139240506, -1.434070172413793 ], [ 29.749520632911391, -1.434070172413793 ], [ 29.749520632911391, -1.433800689655172 ], [ 29.749251139240506, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11907, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.433800689655172 ], [ 29.749520632911391, -1.434070172413793 ], [ 29.749790126582276, -1.434070172413793 ], [ 29.749790126582276, -1.433800689655172 ], [ 29.749520632911391, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11908, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.433800689655172 ], [ 29.749790126582276, -1.434070172413793 ], [ 29.750059620253165, -1.434070172413793 ], [ 29.750059620253165, -1.433800689655172 ], [ 29.749790126582276, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11909, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.433800689655172 ], [ 29.750059620253165, -1.434070172413793 ], [ 29.750598607594934, -1.434070172413793 ], [ 29.750598607594934, -1.433800689655172 ], [ 29.750059620253165, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11910, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.433800689655172 ], [ 29.754371518987341, -1.434339655172414 ], [ 29.754641012658226, -1.434339655172414 ], [ 29.754641012658226, -1.433800689655172 ], [ 29.754371518987341, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11911, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.433800689655172 ], [ 29.754641012658226, -1.434339655172414 ], [ 29.754910506329114, -1.434339655172414 ], [ 29.754910506329114, -1.433800689655172 ], [ 29.754641012658226, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11912, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.433800689655172 ], [ 29.754910506329114, -1.434070172413793 ], [ 29.75518, -1.434070172413793 ], [ 29.75518, -1.433800689655172 ], [ 29.754910506329114, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11913, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.433800689655172 ], [ 29.75518, -1.434070172413793 ], [ 29.755449493670884, -1.434070172413793 ], [ 29.755449493670884, -1.433800689655172 ], [ 29.75518, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11914, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.433800689655172 ], [ 29.755449493670884, -1.434070172413793 ], [ 29.755718987341773, -1.434070172413793 ], [ 29.755718987341773, -1.433800689655172 ], [ 29.755449493670884, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11915, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.433800689655172 ], [ 29.755988481012658, -1.434070172413793 ], [ 29.756257974683542, -1.434070172413793 ], [ 29.756257974683542, -1.433800689655172 ], [ 29.755988481012658, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11916, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.433800689655172 ], [ 29.756257974683542, -1.434070172413793 ], [ 29.756796962025316, -1.434070172413793 ], [ 29.756796962025316, -1.433800689655172 ], [ 29.756257974683542, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11917, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.433800689655172 ], [ 29.756796962025316, -1.434070172413793 ], [ 29.757066455696201, -1.434070172413793 ], [ 29.757066455696201, -1.433800689655172 ], [ 29.756796962025316, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11918, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.433800689655172 ], [ 29.757066455696201, -1.434070172413793 ], [ 29.757335949367089, -1.434070172413793 ], [ 29.757335949367089, -1.433800689655172 ], [ 29.757066455696201, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11919, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.433800689655172 ], [ 29.757335949367089, -1.434070172413793 ], [ 29.757605443037974, -1.434070172413793 ], [ 29.757605443037974, -1.433800689655172 ], [ 29.757335949367089, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11920, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.433800689655172 ], [ 29.757605443037974, -1.434070172413793 ], [ 29.757874936708859, -1.434070172413793 ], [ 29.757874936708859, -1.433800689655172 ], [ 29.757605443037974, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11921, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.433800689655172 ], [ 29.757874936708859, -1.434070172413793 ], [ 29.758144430379748, -1.434070172413793 ], [ 29.758144430379748, -1.433800689655172 ], [ 29.757874936708859, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11922, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.433800689655172 ], [ 29.758144430379748, -1.434070172413793 ], [ 29.758413924050632, -1.434070172413793 ], [ 29.758413924050632, -1.433800689655172 ], [ 29.758144430379748, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11923, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.433800689655172 ], [ 29.758413924050632, -1.434070172413793 ], [ 29.758683417721517, -1.434070172413793 ], [ 29.758683417721517, -1.433800689655172 ], [ 29.758413924050632, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11924, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.433800689655172 ], [ 29.758683417721517, -1.434070172413793 ], [ 29.759761392405064, -1.434070172413793 ], [ 29.759761392405064, -1.433800689655172 ], [ 29.758683417721517, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11925, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.433800689655172 ], [ 29.759761392405064, -1.434070172413793 ], [ 29.760030886075949, -1.434070172413793 ], [ 29.760030886075949, -1.433800689655172 ], [ 29.759761392405064, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11926, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.433800689655172 ], [ 29.760030886075949, -1.434070172413793 ], [ 29.761647848101266, -1.434070172413793 ], [ 29.761647848101266, -1.433800689655172 ], [ 29.760030886075949, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11927, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.433800689655172 ], [ 29.761647848101266, -1.434070172413793 ], [ 29.761917341772151, -1.434070172413793 ], [ 29.761917341772151, -1.433800689655172 ], [ 29.761647848101266, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11928, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.433800689655172 ], [ 29.761917341772151, -1.434070172413793 ], [ 29.762186835443039, -1.434070172413793 ], [ 29.762186835443039, -1.433800689655172 ], [ 29.761917341772151, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11929, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.433800689655172 ], [ 29.762186835443039, -1.434070172413793 ], [ 29.762456329113924, -1.434070172413793 ], [ 29.762456329113924, -1.433800689655172 ], [ 29.762186835443039, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11930, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.433800689655172 ], [ 29.762456329113924, -1.434070172413793 ], [ 29.762725822784809, -1.434070172413793 ], [ 29.762725822784809, -1.433800689655172 ], [ 29.762456329113924, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11931, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.433800689655172 ], [ 29.762725822784809, -1.434070172413793 ], [ 29.762995316455697, -1.434070172413793 ], [ 29.762995316455697, -1.433800689655172 ], [ 29.762725822784809, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11932, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.433800689655172 ], [ 29.762995316455697, -1.434070172413793 ], [ 29.763264810126582, -1.434070172413793 ], [ 29.763264810126582, -1.433800689655172 ], [ 29.762995316455697, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11933, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.433800689655172 ], [ 29.763264810126582, -1.434070172413793 ], [ 29.763534303797467, -1.434070172413793 ], [ 29.763534303797467, -1.433800689655172 ], [ 29.763264810126582, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11934, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.433800689655172 ], [ 29.763534303797467, -1.434070172413793 ], [ 29.763803797468356, -1.434070172413793 ], [ 29.763803797468356, -1.433800689655172 ], [ 29.763534303797467, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11935, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.433800689655172 ], [ 29.763803797468356, -1.434070172413793 ], [ 29.76407329113924, -1.434070172413793 ], [ 29.76407329113924, -1.433800689655172 ], [ 29.763803797468356, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11936, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.433800689655172 ], [ 29.76407329113924, -1.434070172413793 ], [ 29.764342784810125, -1.434070172413793 ], [ 29.764342784810125, -1.433800689655172 ], [ 29.76407329113924, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11937, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.433800689655172 ], [ 29.764342784810125, -1.434070172413793 ], [ 29.764612278481014, -1.434070172413793 ], [ 29.764612278481014, -1.433800689655172 ], [ 29.764342784810125, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11938, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.433800689655172 ], [ 29.764612278481014, -1.434070172413793 ], [ 29.764881772151899, -1.434070172413793 ], [ 29.764881772151899, -1.433800689655172 ], [ 29.764612278481014, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11939, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.433800689655172 ], [ 29.764881772151899, -1.434070172413793 ], [ 29.765151265822784, -1.434070172413793 ], [ 29.765151265822784, -1.433800689655172 ], [ 29.764881772151899, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11940, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.433800689655172 ], [ 29.765151265822784, -1.434070172413793 ], [ 29.765420759493672, -1.434070172413793 ], [ 29.765420759493672, -1.433800689655172 ], [ 29.765151265822784, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11941, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.433800689655172 ], [ 29.765420759493672, -1.434070172413793 ], [ 29.765959746835442, -1.434070172413793 ], [ 29.765959746835442, -1.433800689655172 ], [ 29.765420759493672, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11942, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.433800689655172 ], [ 29.765959746835442, -1.434070172413793 ], [ 29.766229240506327, -1.434070172413793 ], [ 29.766229240506327, -1.433800689655172 ], [ 29.765959746835442, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11943, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.433800689655172 ], [ 29.766498734177215, -1.434070172413793 ], [ 29.7667682278481, -1.434070172413793 ], [ 29.7667682278481, -1.433800689655172 ], [ 29.766498734177215, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11944, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.433800689655172 ], [ 29.7667682278481, -1.434070172413793 ], [ 29.767037721518985, -1.434070172413793 ], [ 29.767037721518985, -1.433800689655172 ], [ 29.7667682278481, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11945, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.433800689655172 ], [ 29.767037721518985, -1.434339655172414 ], [ 29.767307215189874, -1.434339655172414 ], [ 29.767307215189874, -1.433800689655172 ], [ 29.767037721518985, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11946, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.433800689655172 ], [ 29.767307215189874, -1.434070172413793 ], [ 29.767576708860759, -1.434070172413793 ], [ 29.767576708860759, -1.433800689655172 ], [ 29.767307215189874, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11947, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.433800689655172 ], [ 29.767576708860759, -1.434070172413793 ], [ 29.767846202531643, -1.434070172413793 ], [ 29.767846202531643, -1.433800689655172 ], [ 29.767576708860759, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11948, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.433800689655172 ], [ 29.767846202531643, -1.434339655172414 ], [ 29.768115696202532, -1.434339655172414 ], [ 29.768115696202532, -1.433800689655172 ], [ 29.767846202531643, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11949, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.433800689655172 ], [ 29.768385189873417, -1.434070172413793 ], [ 29.768654683544302, -1.434070172413793 ], [ 29.768654683544302, -1.433800689655172 ], [ 29.768385189873417, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11950, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.433800689655172 ], [ 29.768654683544302, -1.434070172413793 ], [ 29.76892417721519, -1.434070172413793 ], [ 29.76892417721519, -1.433800689655172 ], [ 29.768654683544302, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11951, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.433800689655172 ], [ 29.769193670886075, -1.434070172413793 ], [ 29.76946316455696, -1.434070172413793 ], [ 29.76946316455696, -1.433800689655172 ], [ 29.769193670886075, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11952, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.433800689655172 ], [ 29.76946316455696, -1.434070172413793 ], [ 29.769732658227849, -1.434070172413793 ], [ 29.769732658227849, -1.433800689655172 ], [ 29.76946316455696, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11953, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.433800689655172 ], [ 29.769732658227849, -1.434339655172414 ], [ 29.770002151898733, -1.434339655172414 ], [ 29.770002151898733, -1.433800689655172 ], [ 29.769732658227849, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11954, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.433800689655172 ], [ 29.770002151898733, -1.434070172413793 ], [ 29.770271645569618, -1.434070172413793 ], [ 29.770271645569618, -1.433800689655172 ], [ 29.770002151898733, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11955, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.433800689655172 ], [ 29.770271645569618, -1.434070172413793 ], [ 29.770541139240507, -1.434070172413793 ], [ 29.770541139240507, -1.433800689655172 ], [ 29.770271645569618, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11956, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.433800689655172 ], [ 29.770541139240507, -1.434070172413793 ], [ 29.770810632911392, -1.434070172413793 ], [ 29.770810632911392, -1.433800689655172 ], [ 29.770541139240507, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11957, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.433800689655172 ], [ 29.771080126582277, -1.434070172413793 ], [ 29.771349620253165, -1.434070172413793 ], [ 29.771349620253165, -1.433800689655172 ], [ 29.771080126582277, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11958, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.433800689655172 ], [ 29.771349620253165, -1.434070172413793 ], [ 29.77161911392405, -1.434070172413793 ], [ 29.77161911392405, -1.433800689655172 ], [ 29.771349620253165, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11959, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.433800689655172 ], [ 29.77161911392405, -1.434339655172414 ], [ 29.771888607594935, -1.434339655172414 ], [ 29.771888607594935, -1.433800689655172 ], [ 29.77161911392405, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11960, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.433800689655172 ], [ 29.771888607594935, -1.434070172413793 ], [ 29.772158101265823, -1.434070172413793 ], [ 29.772158101265823, -1.433800689655172 ], [ 29.771888607594935, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11961, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.433800689655172 ], [ 29.772158101265823, -1.434070172413793 ], [ 29.772427594936708, -1.434070172413793 ], [ 29.772427594936708, -1.433800689655172 ], [ 29.772158101265823, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11962, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.433800689655172 ], [ 29.772427594936708, -1.434339655172414 ], [ 29.772697088607593, -1.434339655172414 ], [ 29.772697088607593, -1.433800689655172 ], [ 29.772427594936708, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11963, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.433800689655172 ], [ 29.772966582278482, -1.434070172413793 ], [ 29.773236075949367, -1.434070172413793 ], [ 29.773236075949367, -1.433800689655172 ], [ 29.772966582278482, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11964, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.433800689655172 ], [ 29.773236075949367, -1.434070172413793 ], [ 29.773505569620252, -1.434070172413793 ], [ 29.773505569620252, -1.433800689655172 ], [ 29.773236075949367, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11965, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.433800689655172 ], [ 29.77377506329114, -1.434070172413793 ], [ 29.774044556962025, -1.434070172413793 ], [ 29.774044556962025, -1.433800689655172 ], [ 29.77377506329114, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11966, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.433800689655172 ], [ 29.774044556962025, -1.434339655172414 ], [ 29.77431405063291, -1.434339655172414 ], [ 29.77431405063291, -1.433800689655172 ], [ 29.774044556962025, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11967, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.433800689655172 ], [ 29.77431405063291, -1.434070172413793 ], [ 29.774853037974683, -1.434070172413793 ], [ 29.774853037974683, -1.433800689655172 ], [ 29.77431405063291, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11968, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.433800689655172 ], [ 29.774853037974683, -1.434070172413793 ], [ 29.775122531645568, -1.434070172413793 ], [ 29.775122531645568, -1.433800689655172 ], [ 29.774853037974683, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11969, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.433800689655172 ], [ 29.775122531645568, -1.434070172413793 ], [ 29.775392025316457, -1.434070172413793 ], [ 29.775392025316457, -1.433800689655172 ], [ 29.775122531645568, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11970, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.433800689655172 ], [ 29.775661518987341, -1.434070172413793 ], [ 29.775931012658226, -1.434070172413793 ], [ 29.775931012658226, -1.433800689655172 ], [ 29.775661518987341, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11971, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.433800689655172 ], [ 29.775931012658226, -1.434070172413793 ], [ 29.776200506329115, -1.434070172413793 ], [ 29.776200506329115, -1.433800689655172 ], [ 29.775931012658226, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11972, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.433800689655172 ], [ 29.776200506329115, -1.434339655172414 ], [ 29.77647, -1.434339655172414 ], [ 29.77647, -1.433800689655172 ], [ 29.776200506329115, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11973, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.433800689655172 ], [ 29.77647, -1.434070172413793 ], [ 29.776739493670885, -1.434070172413793 ], [ 29.776739493670885, -1.433800689655172 ], [ 29.77647, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11974, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.433800689655172 ], [ 29.776739493670885, -1.434070172413793 ], [ 29.777008987341773, -1.434070172413793 ], [ 29.777008987341773, -1.433800689655172 ], [ 29.776739493670885, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11975, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.433800689655172 ], [ 29.777008987341773, -1.434339655172414 ], [ 29.777278481012658, -1.434339655172414 ], [ 29.777278481012658, -1.433800689655172 ], [ 29.777008987341773, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11976, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.433800689655172 ], [ 29.777547974683543, -1.434070172413793 ], [ 29.777817468354431, -1.434070172413793 ], [ 29.777817468354431, -1.433800689655172 ], [ 29.777547974683543, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11977, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.433800689655172 ], [ 29.777817468354431, -1.434070172413793 ], [ 29.778086962025316, -1.434070172413793 ], [ 29.778086962025316, -1.433800689655172 ], [ 29.777817468354431, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11978, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.433800689655172 ], [ 29.778356455696201, -1.434070172413793 ], [ 29.77862594936709, -1.434070172413793 ], [ 29.77862594936709, -1.433800689655172 ], [ 29.778356455696201, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11979, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.433800689655172 ], [ 29.77862594936709, -1.434070172413793 ], [ 29.778895443037975, -1.434070172413793 ], [ 29.778895443037975, -1.433800689655172 ], [ 29.77862594936709, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11980, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.433800689655172 ], [ 29.778895443037975, -1.434339655172414 ], [ 29.77916493670886, -1.434339655172414 ], [ 29.77916493670886, -1.433800689655172 ], [ 29.778895443037975, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11981, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.433800689655172 ], [ 29.77916493670886, -1.434070172413793 ], [ 29.779434430379748, -1.434070172413793 ], [ 29.779434430379748, -1.433800689655172 ], [ 29.77916493670886, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11982, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.433800689655172 ], [ 29.779434430379748, -1.434070172413793 ], [ 29.779703924050633, -1.434070172413793 ], [ 29.779703924050633, -1.433800689655172 ], [ 29.779434430379748, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11983, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.433800689655172 ], [ 29.779703924050633, -1.434070172413793 ], [ 29.779973417721518, -1.434070172413793 ], [ 29.779973417721518, -1.433800689655172 ], [ 29.779703924050633, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11984, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.433800689655172 ], [ 29.780242911392406, -1.434070172413793 ], [ 29.780512405063291, -1.434070172413793 ], [ 29.780512405063291, -1.433800689655172 ], [ 29.780242911392406, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11985, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.433800689655172 ], [ 29.780512405063291, -1.434070172413793 ], [ 29.780781898734176, -1.434070172413793 ], [ 29.780781898734176, -1.433800689655172 ], [ 29.780512405063291, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11986, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.433800689655172 ], [ 29.780781898734176, -1.434070172413793 ], [ 29.781051392405065, -1.434070172413793 ], [ 29.781051392405065, -1.433800689655172 ], [ 29.780781898734176, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11987, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.433800689655172 ], [ 29.781051392405065, -1.434070172413793 ], [ 29.78132088607595, -1.434070172413793 ], [ 29.78132088607595, -1.433800689655172 ], [ 29.781051392405065, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11988, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.433800689655172 ], [ 29.78132088607595, -1.434070172413793 ], [ 29.781590379746834, -1.434070172413793 ], [ 29.781590379746834, -1.433800689655172 ], [ 29.78132088607595, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11989, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.433800689655172 ], [ 29.781590379746834, -1.434070172413793 ], [ 29.781859873417723, -1.434070172413793 ], [ 29.781859873417723, -1.433800689655172 ], [ 29.781590379746834, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11990, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.433800689655172 ], [ 29.781859873417723, -1.434070172413793 ], [ 29.782129367088608, -1.434070172413793 ], [ 29.782129367088608, -1.433800689655172 ], [ 29.781859873417723, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11991, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.433800689655172 ], [ 29.782129367088608, -1.434070172413793 ], [ 29.782398860759493, -1.434070172413793 ], [ 29.782398860759493, -1.433800689655172 ], [ 29.782129367088608, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11992, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.433800689655172 ], [ 29.782398860759493, -1.434070172413793 ], [ 29.782668354430381, -1.434070172413793 ], [ 29.782668354430381, -1.433800689655172 ], [ 29.782398860759493, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11993, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.433800689655172 ], [ 29.782668354430381, -1.434070172413793 ], [ 29.782937848101266, -1.434070172413793 ], [ 29.782937848101266, -1.433800689655172 ], [ 29.782668354430381, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11994, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.433800689655172 ], [ 29.782937848101266, -1.434070172413793 ], [ 29.783207341772151, -1.434070172413793 ], [ 29.783207341772151, -1.433800689655172 ], [ 29.782937848101266, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11995, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.433800689655172 ], [ 29.783207341772151, -1.434070172413793 ], [ 29.783476835443039, -1.434070172413793 ], [ 29.783476835443039, -1.433800689655172 ], [ 29.783207341772151, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11996, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.433800689655172 ], [ 29.783476835443039, -1.434070172413793 ], [ 29.784015822784809, -1.434070172413793 ], [ 29.784015822784809, -1.433800689655172 ], [ 29.783476835443039, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11997, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.433800689655172 ], [ 29.784015822784809, -1.434070172413793 ], [ 29.784285316455698, -1.434070172413793 ], [ 29.784285316455698, -1.433800689655172 ], [ 29.784015822784809, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11998, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.433800689655172 ], [ 29.784285316455698, -1.434070172413793 ], [ 29.784554810126583, -1.434070172413793 ], [ 29.784554810126583, -1.433800689655172 ], [ 29.784285316455698, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 11999, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.433800689655172 ], [ 29.784554810126583, -1.434070172413793 ], [ 29.784824303797468, -1.434070172413793 ], [ 29.784824303797468, -1.433800689655172 ], [ 29.784554810126583, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12000, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.433800689655172 ], [ 29.784824303797468, -1.434070172413793 ], [ 29.785093797468356, -1.434070172413793 ], [ 29.785093797468356, -1.433800689655172 ], [ 29.784824303797468, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12001, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.433800689655172 ], [ 29.785093797468356, -1.434070172413793 ], [ 29.785363291139241, -1.434070172413793 ], [ 29.785363291139241, -1.433800689655172 ], [ 29.785093797468356, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12002, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.433800689655172 ], [ 29.785363291139241, -1.434070172413793 ], [ 29.785632784810126, -1.434070172413793 ], [ 29.785632784810126, -1.433800689655172 ], [ 29.785363291139241, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12003, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.433800689655172 ], [ 29.785632784810126, -1.434070172413793 ], [ 29.785902278481014, -1.434070172413793 ], [ 29.785902278481014, -1.433800689655172 ], [ 29.785632784810126, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12004, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.433800689655172 ], [ 29.785902278481014, -1.434070172413793 ], [ 29.786171772151899, -1.434070172413793 ], [ 29.786171772151899, -1.433800689655172 ], [ 29.785902278481014, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12005, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.433800689655172 ], [ 29.786171772151899, -1.434070172413793 ], [ 29.786441265822784, -1.434070172413793 ], [ 29.786441265822784, -1.433800689655172 ], [ 29.786171772151899, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12006, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.433800689655172 ], [ 29.786441265822784, -1.434070172413793 ], [ 29.786710759493673, -1.434070172413793 ], [ 29.786710759493673, -1.433800689655172 ], [ 29.786441265822784, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12007, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.433800689655172 ], [ 29.786710759493673, -1.434070172413793 ], [ 29.786980253164558, -1.434070172413793 ], [ 29.786980253164558, -1.433800689655172 ], [ 29.786710759493673, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12008, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.433800689655172 ], [ 29.786980253164558, -1.434070172413793 ], [ 29.788327721518986, -1.434070172413793 ], [ 29.788327721518986, -1.433800689655172 ], [ 29.786980253164558, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12009, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.433800689655172 ], [ 29.788327721518986, -1.434070172413793 ], [ 29.788597215189874, -1.434070172413793 ], [ 29.788597215189874, -1.433800689655172 ], [ 29.788327721518986, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12010, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.433800689655172 ], [ 29.788597215189874, -1.434070172413793 ], [ 29.788866708860759, -1.434070172413793 ], [ 29.788866708860759, -1.433800689655172 ], [ 29.788597215189874, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12011, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.433800689655172 ], [ 29.788866708860759, -1.434070172413793 ], [ 29.789136202531644, -1.434070172413793 ], [ 29.789136202531644, -1.433800689655172 ], [ 29.788866708860759, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12012, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.433800689655172 ], [ 29.789136202531644, -1.434070172413793 ], [ 29.789405696202532, -1.434070172413793 ], [ 29.789405696202532, -1.433800689655172 ], [ 29.789136202531644, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12013, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.433800689655172 ], [ 29.789405696202532, -1.434070172413793 ], [ 29.789675189873417, -1.434070172413793 ], [ 29.789675189873417, -1.433800689655172 ], [ 29.789405696202532, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12014, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.433800689655172 ], [ 29.789675189873417, -1.434070172413793 ], [ 29.789944683544302, -1.434070172413793 ], [ 29.789944683544302, -1.433800689655172 ], [ 29.789675189873417, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12015, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.433800689655172 ], [ 29.789944683544302, -1.434070172413793 ], [ 29.790214177215191, -1.434070172413793 ], [ 29.790214177215191, -1.433800689655172 ], [ 29.789944683544302, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12016, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.433800689655172 ], [ 29.790214177215191, -1.434070172413793 ], [ 29.790483670886076, -1.434070172413793 ], [ 29.790483670886076, -1.433800689655172 ], [ 29.790214177215191, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12017, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.433800689655172 ], [ 29.790483670886076, -1.434070172413793 ], [ 29.790753164556961, -1.434070172413793 ], [ 29.790753164556961, -1.433800689655172 ], [ 29.790483670886076, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12018, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.433800689655172 ], [ 29.790753164556961, -1.434070172413793 ], [ 29.791022658227849, -1.434070172413793 ], [ 29.791022658227849, -1.433800689655172 ], [ 29.790753164556961, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12019, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.433800689655172 ], [ 29.791022658227849, -1.434070172413793 ], [ 29.791292151898734, -1.434070172413793 ], [ 29.791292151898734, -1.433800689655172 ], [ 29.791022658227849, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12020, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.433800689655172 ], [ 29.791292151898734, -1.434070172413793 ], [ 29.791561645569619, -1.434070172413793 ], [ 29.791561645569619, -1.433800689655172 ], [ 29.791292151898734, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12021, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.433800689655172 ], [ 29.791561645569619, -1.434070172413793 ], [ 29.791831139240507, -1.434070172413793 ], [ 29.791831139240507, -1.433800689655172 ], [ 29.791561645569619, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12022, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.433800689655172 ], [ 29.791831139240507, -1.434070172413793 ], [ 29.792100632911392, -1.434070172413793 ], [ 29.792100632911392, -1.433800689655172 ], [ 29.791831139240507, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12023, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.433800689655172 ], [ 29.792100632911392, -1.434070172413793 ], [ 29.792370126582277, -1.434070172413793 ], [ 29.792370126582277, -1.433800689655172 ], [ 29.792100632911392, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12024, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.433800689655172 ], [ 29.792370126582277, -1.434070172413793 ], [ 29.792909113924051, -1.434070172413793 ], [ 29.792909113924051, -1.433800689655172 ], [ 29.792370126582277, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12025, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.433800689655172 ], [ 29.792909113924051, -1.434070172413793 ], [ 29.793178607594935, -1.434070172413793 ], [ 29.793178607594935, -1.433800689655172 ], [ 29.792909113924051, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12026, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.433800689655172 ], [ 29.793178607594935, -1.434070172413793 ], [ 29.793448101265824, -1.434070172413793 ], [ 29.793448101265824, -1.433800689655172 ], [ 29.793178607594935, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12027, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.433800689655172 ], [ 29.793448101265824, -1.434070172413793 ], [ 29.793717594936709, -1.434070172413793 ], [ 29.793717594936709, -1.433800689655172 ], [ 29.793448101265824, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12028, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.433800689655172 ], [ 29.793717594936709, -1.434070172413793 ], [ 29.793987088607594, -1.434070172413793 ], [ 29.793987088607594, -1.433800689655172 ], [ 29.793717594936709, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12029, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.433800689655172 ], [ 29.793987088607594, -1.434070172413793 ], [ 29.794256582278482, -1.434070172413793 ], [ 29.794256582278482, -1.433800689655172 ], [ 29.793987088607594, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12030, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.433800689655172 ], [ 29.794256582278482, -1.434070172413793 ], [ 29.794526075949367, -1.434070172413793 ], [ 29.794526075949367, -1.433800689655172 ], [ 29.794256582278482, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12031, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.433800689655172 ], [ 29.794526075949367, -1.434070172413793 ], [ 29.794795569620252, -1.434070172413793 ], [ 29.794795569620252, -1.433800689655172 ], [ 29.794526075949367, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12032, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.433800689655172 ], [ 29.794795569620252, -1.434070172413793 ], [ 29.79506506329114, -1.434070172413793 ], [ 29.79506506329114, -1.433800689655172 ], [ 29.794795569620252, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12033, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.433800689655172 ], [ 29.79506506329114, -1.434070172413793 ], [ 29.795334556962025, -1.434070172413793 ], [ 29.795334556962025, -1.433800689655172 ], [ 29.79506506329114, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12034, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.433800689655172 ], [ 29.795334556962025, -1.434070172413793 ], [ 29.79560405063291, -1.434070172413793 ], [ 29.79560405063291, -1.433800689655172 ], [ 29.795334556962025, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12035, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.433800689655172 ], [ 29.79560405063291, -1.434070172413793 ], [ 29.795873544303799, -1.434070172413793 ], [ 29.795873544303799, -1.433800689655172 ], [ 29.79560405063291, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12036, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.433800689655172 ], [ 29.795873544303799, -1.434070172413793 ], [ 29.796143037974684, -1.434070172413793 ], [ 29.796143037974684, -1.433800689655172 ], [ 29.795873544303799, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12037, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.433800689655172 ], [ 29.796143037974684, -1.434070172413793 ], [ 29.796412531645569, -1.434070172413793 ], [ 29.796412531645569, -1.433800689655172 ], [ 29.796143037974684, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12038, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.433800689655172 ], [ 29.796412531645569, -1.434070172413793 ], [ 29.796682025316457, -1.434070172413793 ], [ 29.796682025316457, -1.433800689655172 ], [ 29.796412531645569, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12039, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.433800689655172 ], [ 29.796682025316457, -1.434070172413793 ], [ 29.796951518987342, -1.434070172413793 ], [ 29.796951518987342, -1.433800689655172 ], [ 29.796682025316457, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12040, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.433800689655172 ], [ 29.796951518987342, -1.434070172413793 ], [ 29.797490506329115, -1.434070172413793 ], [ 29.797490506329115, -1.433800689655172 ], [ 29.796951518987342, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12041, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.433800689655172 ], [ 29.797490506329115, -1.434070172413793 ], [ 29.79776, -1.434070172413793 ], [ 29.79776, -1.433800689655172 ], [ 29.797490506329115, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12042, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.433800689655172 ], [ 29.79776, -1.434070172413793 ], [ 29.798029493670885, -1.434070172413793 ], [ 29.798029493670885, -1.433800689655172 ], [ 29.79776, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12043, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.433800689655172 ], [ 29.798029493670885, -1.434070172413793 ], [ 29.798298987341774, -1.434070172413793 ], [ 29.798298987341774, -1.433800689655172 ], [ 29.798029493670885, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12044, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.433800689655172 ], [ 29.798298987341774, -1.434070172413793 ], [ 29.798568481012659, -1.434070172413793 ], [ 29.798568481012659, -1.433800689655172 ], [ 29.798298987341774, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12045, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.433800689655172 ], [ 29.798568481012659, -1.434070172413793 ], [ 29.798837974683543, -1.434070172413793 ], [ 29.798837974683543, -1.433800689655172 ], [ 29.798568481012659, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12046, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.433800689655172 ], [ 29.798837974683543, -1.434070172413793 ], [ 29.799107468354432, -1.434070172413793 ], [ 29.799107468354432, -1.433800689655172 ], [ 29.798837974683543, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12047, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.433800689655172 ], [ 29.799107468354432, -1.434070172413793 ], [ 29.799376962025317, -1.434070172413793 ], [ 29.799376962025317, -1.433800689655172 ], [ 29.799107468354432, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12048, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.433800689655172 ], [ 29.799646455696202, -1.434070172413793 ], [ 29.79991594936709, -1.434070172413793 ], [ 29.79991594936709, -1.433800689655172 ], [ 29.799646455696202, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12049, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.433800689655172 ], [ 29.79991594936709, -1.434070172413793 ], [ 29.800185443037975, -1.434070172413793 ], [ 29.800185443037975, -1.433800689655172 ], [ 29.79991594936709, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12050, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.433800689655172 ], [ 29.80045493670886, -1.434339655172414 ], [ 29.800724430379748, -1.434339655172414 ], [ 29.800724430379748, -1.433800689655172 ], [ 29.80045493670886, -1.433800689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12051, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.434070172413793 ], [ 29.745208734177215, -1.434609137931035 ], [ 29.7454782278481, -1.434609137931035 ], [ 29.7454782278481, -1.434070172413793 ], [ 29.745208734177215, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12052, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.434070172413793 ], [ 29.7454782278481, -1.434339655172414 ], [ 29.745747721518985, -1.434339655172414 ], [ 29.745747721518985, -1.434070172413793 ], [ 29.7454782278481, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12053, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.434070172413793 ], [ 29.745747721518985, -1.434339655172414 ], [ 29.746017215189873, -1.434339655172414 ], [ 29.746017215189873, -1.434070172413793 ], [ 29.745747721518985, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12054, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.434070172413793 ], [ 29.746017215189873, -1.434339655172414 ], [ 29.746286708860758, -1.434339655172414 ], [ 29.746286708860758, -1.434070172413793 ], [ 29.746017215189873, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12055, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.434070172413793 ], [ 29.746286708860758, -1.434339655172414 ], [ 29.747364683544301, -1.434339655172414 ], [ 29.747364683544301, -1.434070172413793 ], [ 29.746286708860758, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12056, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.434070172413793 ], [ 29.747364683544301, -1.434339655172414 ], [ 29.747903670886075, -1.434339655172414 ], [ 29.747903670886075, -1.434070172413793 ], [ 29.747364683544301, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12057, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.434070172413793 ], [ 29.747903670886075, -1.434339655172414 ], [ 29.748442658227848, -1.434339655172414 ], [ 29.748442658227848, -1.434070172413793 ], [ 29.747903670886075, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12058, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.434070172413793 ], [ 29.748442658227848, -1.434339655172414 ], [ 29.748712151898733, -1.434339655172414 ], [ 29.748712151898733, -1.434070172413793 ], [ 29.748442658227848, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12059, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.434070172413793 ], [ 29.748712151898733, -1.434339655172414 ], [ 29.748981645569618, -1.434339655172414 ], [ 29.748981645569618, -1.434070172413793 ], [ 29.748712151898733, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12060, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.434070172413793 ], [ 29.748981645569618, -1.434339655172414 ], [ 29.749251139240506, -1.434339655172414 ], [ 29.749251139240506, -1.434070172413793 ], [ 29.748981645569618, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12061, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.434070172413793 ], [ 29.749251139240506, -1.434339655172414 ], [ 29.749520632911391, -1.434339655172414 ], [ 29.749520632911391, -1.434070172413793 ], [ 29.749251139240506, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12062, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.434070172413793 ], [ 29.749520632911391, -1.434339655172414 ], [ 29.749790126582276, -1.434339655172414 ], [ 29.749790126582276, -1.434070172413793 ], [ 29.749520632911391, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12063, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.434070172413793 ], [ 29.749790126582276, -1.434339655172414 ], [ 29.750059620253165, -1.434339655172414 ], [ 29.750059620253165, -1.434070172413793 ], [ 29.749790126582276, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12064, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.434070172413793 ], [ 29.750059620253165, -1.434339655172414 ], [ 29.75032911392405, -1.434339655172414 ], [ 29.75032911392405, -1.434070172413793 ], [ 29.750059620253165, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12065, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.434070172413793 ], [ 29.75032911392405, -1.434339655172414 ], [ 29.750598607594934, -1.434339655172414 ], [ 29.750598607594934, -1.434070172413793 ], [ 29.75032911392405, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12066, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.434070172413793 ], [ 29.750598607594934, -1.434339655172414 ], [ 29.751137594936708, -1.434339655172414 ], [ 29.751137594936708, -1.434070172413793 ], [ 29.750598607594934, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12067, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.434070172413793 ], [ 29.754910506329114, -1.434609137931035 ], [ 29.75518, -1.434609137931035 ], [ 29.75518, -1.434070172413793 ], [ 29.754910506329114, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12068, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.434070172413793 ], [ 29.75518, -1.434609137931035 ], [ 29.755449493670884, -1.434609137931035 ], [ 29.755449493670884, -1.434070172413793 ], [ 29.75518, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12069, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.434070172413793 ], [ 29.755449493670884, -1.434339655172414 ], [ 29.755718987341773, -1.434339655172414 ], [ 29.755718987341773, -1.434070172413793 ], [ 29.755449493670884, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12070, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.434070172413793 ], [ 29.755718987341773, -1.434339655172414 ], [ 29.755988481012658, -1.434339655172414 ], [ 29.755988481012658, -1.434070172413793 ], [ 29.755718987341773, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12071, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.434070172413793 ], [ 29.755988481012658, -1.434339655172414 ], [ 29.756257974683542, -1.434339655172414 ], [ 29.756257974683542, -1.434070172413793 ], [ 29.755988481012658, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12072, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.434070172413793 ], [ 29.756257974683542, -1.434339655172414 ], [ 29.757066455696201, -1.434339655172414 ], [ 29.757066455696201, -1.434070172413793 ], [ 29.756257974683542, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12073, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.434070172413793 ], [ 29.757066455696201, -1.434339655172414 ], [ 29.757335949367089, -1.434339655172414 ], [ 29.757335949367089, -1.434070172413793 ], [ 29.757066455696201, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12074, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.434070172413793 ], [ 29.757335949367089, -1.434339655172414 ], [ 29.757605443037974, -1.434339655172414 ], [ 29.757605443037974, -1.434070172413793 ], [ 29.757335949367089, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12075, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.434070172413793 ], [ 29.757605443037974, -1.434339655172414 ], [ 29.757874936708859, -1.434339655172414 ], [ 29.757874936708859, -1.434070172413793 ], [ 29.757605443037974, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12076, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.434070172413793 ], [ 29.757874936708859, -1.434339655172414 ], [ 29.758144430379748, -1.434339655172414 ], [ 29.758144430379748, -1.434070172413793 ], [ 29.757874936708859, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12077, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.434070172413793 ], [ 29.758144430379748, -1.434339655172414 ], [ 29.758413924050632, -1.434339655172414 ], [ 29.758413924050632, -1.434070172413793 ], [ 29.758144430379748, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12078, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.434070172413793 ], [ 29.758413924050632, -1.434339655172414 ], [ 29.758683417721517, -1.434339655172414 ], [ 29.758683417721517, -1.434070172413793 ], [ 29.758413924050632, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12079, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.434070172413793 ], [ 29.758683417721517, -1.434339655172414 ], [ 29.758952911392406, -1.434339655172414 ], [ 29.758952911392406, -1.434070172413793 ], [ 29.758683417721517, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12080, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.434070172413793 ], [ 29.758952911392406, -1.434339655172414 ], [ 29.759491898734176, -1.434339655172414 ], [ 29.759491898734176, -1.434070172413793 ], [ 29.758952911392406, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12081, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.434070172413793 ], [ 29.759491898734176, -1.434339655172414 ], [ 29.759761392405064, -1.434339655172414 ], [ 29.759761392405064, -1.434070172413793 ], [ 29.759491898734176, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12082, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.434070172413793 ], [ 29.759761392405064, -1.434339655172414 ], [ 29.760030886075949, -1.434339655172414 ], [ 29.760030886075949, -1.434070172413793 ], [ 29.759761392405064, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12083, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.434070172413793 ], [ 29.760030886075949, -1.434339655172414 ], [ 29.761647848101266, -1.434339655172414 ], [ 29.761647848101266, -1.434070172413793 ], [ 29.760030886075949, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12084, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.434070172413793 ], [ 29.761647848101266, -1.434339655172414 ], [ 29.761917341772151, -1.434339655172414 ], [ 29.761917341772151, -1.434070172413793 ], [ 29.761647848101266, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12085, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.434070172413793 ], [ 29.761917341772151, -1.434339655172414 ], [ 29.762186835443039, -1.434339655172414 ], [ 29.762186835443039, -1.434070172413793 ], [ 29.761917341772151, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12086, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.434070172413793 ], [ 29.762186835443039, -1.434339655172414 ], [ 29.762456329113924, -1.434339655172414 ], [ 29.762456329113924, -1.434070172413793 ], [ 29.762186835443039, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12087, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.434070172413793 ], [ 29.762456329113924, -1.434339655172414 ], [ 29.762725822784809, -1.434339655172414 ], [ 29.762725822784809, -1.434070172413793 ], [ 29.762456329113924, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12088, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.434070172413793 ], [ 29.762725822784809, -1.434339655172414 ], [ 29.762995316455697, -1.434339655172414 ], [ 29.762995316455697, -1.434070172413793 ], [ 29.762725822784809, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12089, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.434070172413793 ], [ 29.762995316455697, -1.434339655172414 ], [ 29.763264810126582, -1.434339655172414 ], [ 29.763264810126582, -1.434070172413793 ], [ 29.762995316455697, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12090, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.434070172413793 ], [ 29.763264810126582, -1.434339655172414 ], [ 29.763534303797467, -1.434339655172414 ], [ 29.763534303797467, -1.434070172413793 ], [ 29.763264810126582, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12091, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.434070172413793 ], [ 29.763534303797467, -1.434339655172414 ], [ 29.763803797468356, -1.434339655172414 ], [ 29.763803797468356, -1.434070172413793 ], [ 29.763534303797467, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12092, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.434070172413793 ], [ 29.763803797468356, -1.434339655172414 ], [ 29.76407329113924, -1.434339655172414 ], [ 29.76407329113924, -1.434070172413793 ], [ 29.763803797468356, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12093, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.434070172413793 ], [ 29.76407329113924, -1.434339655172414 ], [ 29.764342784810125, -1.434339655172414 ], [ 29.764342784810125, -1.434070172413793 ], [ 29.76407329113924, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12094, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.434070172413793 ], [ 29.764342784810125, -1.434339655172414 ], [ 29.764612278481014, -1.434339655172414 ], [ 29.764612278481014, -1.434070172413793 ], [ 29.764342784810125, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12095, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.434070172413793 ], [ 29.764612278481014, -1.434339655172414 ], [ 29.764881772151899, -1.434339655172414 ], [ 29.764881772151899, -1.434070172413793 ], [ 29.764612278481014, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12096, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.434070172413793 ], [ 29.764881772151899, -1.434609137931035 ], [ 29.765151265822784, -1.434609137931035 ], [ 29.765151265822784, -1.434070172413793 ], [ 29.764881772151899, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12097, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.434070172413793 ], [ 29.765151265822784, -1.434339655172414 ], [ 29.765420759493672, -1.434339655172414 ], [ 29.765420759493672, -1.434070172413793 ], [ 29.765151265822784, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12098, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.434070172413793 ], [ 29.765420759493672, -1.434339655172414 ], [ 29.765959746835442, -1.434339655172414 ], [ 29.765959746835442, -1.434070172413793 ], [ 29.765420759493672, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12099, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.434070172413793 ], [ 29.765959746835442, -1.434609137931035 ], [ 29.766229240506327, -1.434609137931035 ], [ 29.766229240506327, -1.434070172413793 ], [ 29.765959746835442, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12100, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.434070172413793 ], [ 29.766229240506327, -1.434339655172414 ], [ 29.766498734177215, -1.434339655172414 ], [ 29.766498734177215, -1.434070172413793 ], [ 29.766229240506327, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12101, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.434070172413793 ], [ 29.766498734177215, -1.434339655172414 ], [ 29.7667682278481, -1.434339655172414 ], [ 29.7667682278481, -1.434070172413793 ], [ 29.766498734177215, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12102, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.434070172413793 ], [ 29.7667682278481, -1.434609137931035 ], [ 29.767037721518985, -1.434609137931035 ], [ 29.767037721518985, -1.434070172413793 ], [ 29.7667682278481, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12103, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.434070172413793 ], [ 29.767307215189874, -1.434339655172414 ], [ 29.767576708860759, -1.434339655172414 ], [ 29.767576708860759, -1.434070172413793 ], [ 29.767307215189874, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12104, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.434070172413793 ], [ 29.767576708860759, -1.434339655172414 ], [ 29.767846202531643, -1.434339655172414 ], [ 29.767846202531643, -1.434070172413793 ], [ 29.767576708860759, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12105, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.434070172413793 ], [ 29.768115696202532, -1.434339655172414 ], [ 29.768385189873417, -1.434339655172414 ], [ 29.768385189873417, -1.434070172413793 ], [ 29.768115696202532, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12106, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.434070172413793 ], [ 29.768385189873417, -1.434339655172414 ], [ 29.768654683544302, -1.434339655172414 ], [ 29.768654683544302, -1.434070172413793 ], [ 29.768385189873417, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12107, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.434070172413793 ], [ 29.768654683544302, -1.434609137931035 ], [ 29.76892417721519, -1.434609137931035 ], [ 29.76892417721519, -1.434070172413793 ], [ 29.768654683544302, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12108, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.434070172413793 ], [ 29.76892417721519, -1.434339655172414 ], [ 29.769193670886075, -1.434339655172414 ], [ 29.769193670886075, -1.434070172413793 ], [ 29.76892417721519, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12109, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.434070172413793 ], [ 29.769193670886075, -1.434339655172414 ], [ 29.76946316455696, -1.434339655172414 ], [ 29.76946316455696, -1.434070172413793 ], [ 29.769193670886075, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12110, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.434070172413793 ], [ 29.76946316455696, -1.434339655172414 ], [ 29.769732658227849, -1.434339655172414 ], [ 29.769732658227849, -1.434070172413793 ], [ 29.76946316455696, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12111, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.434070172413793 ], [ 29.770002151898733, -1.434339655172414 ], [ 29.770271645569618, -1.434339655172414 ], [ 29.770271645569618, -1.434070172413793 ], [ 29.770002151898733, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12112, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.434070172413793 ], [ 29.770271645569618, -1.434339655172414 ], [ 29.770541139240507, -1.434339655172414 ], [ 29.770541139240507, -1.434070172413793 ], [ 29.770271645569618, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12113, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.434070172413793 ], [ 29.770541139240507, -1.434609137931035 ], [ 29.770810632911392, -1.434609137931035 ], [ 29.770810632911392, -1.434070172413793 ], [ 29.770541139240507, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12114, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.434070172413793 ], [ 29.770810632911392, -1.434339655172414 ], [ 29.771080126582277, -1.434339655172414 ], [ 29.771080126582277, -1.434070172413793 ], [ 29.770810632911392, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12115, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.434070172413793 ], [ 29.771080126582277, -1.434339655172414 ], [ 29.771349620253165, -1.434339655172414 ], [ 29.771349620253165, -1.434070172413793 ], [ 29.771080126582277, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12116, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.434070172413793 ], [ 29.771349620253165, -1.434609137931035 ], [ 29.77161911392405, -1.434609137931035 ], [ 29.77161911392405, -1.434070172413793 ], [ 29.771349620253165, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12117, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.434070172413793 ], [ 29.771888607594935, -1.434339655172414 ], [ 29.772158101265823, -1.434339655172414 ], [ 29.772158101265823, -1.434070172413793 ], [ 29.771888607594935, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12118, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.434070172413793 ], [ 29.772158101265823, -1.434339655172414 ], [ 29.772427594936708, -1.434339655172414 ], [ 29.772427594936708, -1.434070172413793 ], [ 29.772158101265823, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12119, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.434070172413793 ], [ 29.772697088607593, -1.434339655172414 ], [ 29.772966582278482, -1.434339655172414 ], [ 29.772966582278482, -1.434070172413793 ], [ 29.772697088607593, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12120, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.434070172413793 ], [ 29.772966582278482, -1.434339655172414 ], [ 29.773236075949367, -1.434339655172414 ], [ 29.773236075949367, -1.434070172413793 ], [ 29.772966582278482, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12121, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.434070172413793 ], [ 29.773236075949367, -1.434609137931035 ], [ 29.773505569620252, -1.434609137931035 ], [ 29.773505569620252, -1.434070172413793 ], [ 29.773236075949367, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12122, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.434070172413793 ], [ 29.773505569620252, -1.434339655172414 ], [ 29.774044556962025, -1.434339655172414 ], [ 29.774044556962025, -1.434070172413793 ], [ 29.773505569620252, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12123, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.434070172413793 ], [ 29.77431405063291, -1.434339655172414 ], [ 29.774853037974683, -1.434339655172414 ], [ 29.774853037974683, -1.434070172413793 ], [ 29.77431405063291, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12124, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.434070172413793 ], [ 29.774853037974683, -1.434339655172414 ], [ 29.775122531645568, -1.434339655172414 ], [ 29.775122531645568, -1.434070172413793 ], [ 29.774853037974683, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12125, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.434070172413793 ], [ 29.775122531645568, -1.434878620689655 ], [ 29.775392025316457, -1.434878620689655 ], [ 29.775392025316457, -1.434070172413793 ], [ 29.775122531645568, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12126, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.434070172413793 ], [ 29.775392025316457, -1.434339655172414 ], [ 29.775661518987341, -1.434339655172414 ], [ 29.775661518987341, -1.434070172413793 ], [ 29.775392025316457, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12127, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.434070172413793 ], [ 29.775661518987341, -1.434339655172414 ], [ 29.775931012658226, -1.434339655172414 ], [ 29.775931012658226, -1.434070172413793 ], [ 29.775661518987341, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12128, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.434070172413793 ], [ 29.775931012658226, -1.434878620689655 ], [ 29.776200506329115, -1.434878620689655 ], [ 29.776200506329115, -1.434070172413793 ], [ 29.775931012658226, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12129, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.434070172413793 ], [ 29.77647, -1.434339655172414 ], [ 29.776739493670885, -1.434339655172414 ], [ 29.776739493670885, -1.434070172413793 ], [ 29.77647, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12130, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.434070172413793 ], [ 29.776739493670885, -1.434339655172414 ], [ 29.777008987341773, -1.434339655172414 ], [ 29.777008987341773, -1.434070172413793 ], [ 29.776739493670885, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12131, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.434070172413793 ], [ 29.777278481012658, -1.434339655172414 ], [ 29.777547974683543, -1.434339655172414 ], [ 29.777547974683543, -1.434070172413793 ], [ 29.777278481012658, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12132, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.434070172413793 ], [ 29.777547974683543, -1.434339655172414 ], [ 29.777817468354431, -1.434339655172414 ], [ 29.777817468354431, -1.434070172413793 ], [ 29.777547974683543, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12133, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.434070172413793 ], [ 29.777817468354431, -1.434609137931035 ], [ 29.778086962025316, -1.434609137931035 ], [ 29.778086962025316, -1.434070172413793 ], [ 29.777817468354431, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12134, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.434070172413793 ], [ 29.778086962025316, -1.434339655172414 ], [ 29.778356455696201, -1.434339655172414 ], [ 29.778356455696201, -1.434070172413793 ], [ 29.778086962025316, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12135, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.434070172413793 ], [ 29.778356455696201, -1.434339655172414 ], [ 29.77862594936709, -1.434339655172414 ], [ 29.77862594936709, -1.434070172413793 ], [ 29.778356455696201, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12136, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.434070172413793 ], [ 29.77862594936709, -1.434339655172414 ], [ 29.778895443037975, -1.434339655172414 ], [ 29.778895443037975, -1.434070172413793 ], [ 29.77862594936709, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12137, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.434070172413793 ], [ 29.77916493670886, -1.434339655172414 ], [ 29.779434430379748, -1.434339655172414 ], [ 29.779434430379748, -1.434070172413793 ], [ 29.77916493670886, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12138, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.434070172413793 ], [ 29.779434430379748, -1.434339655172414 ], [ 29.779703924050633, -1.434339655172414 ], [ 29.779703924050633, -1.434070172413793 ], [ 29.779434430379748, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12139, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.434070172413793 ], [ 29.779703924050633, -1.434339655172414 ], [ 29.779973417721518, -1.434339655172414 ], [ 29.779973417721518, -1.434070172413793 ], [ 29.779703924050633, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12140, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.434070172413793 ], [ 29.779973417721518, -1.434339655172414 ], [ 29.780242911392406, -1.434339655172414 ], [ 29.780242911392406, -1.434070172413793 ], [ 29.779973417721518, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12141, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.434070172413793 ], [ 29.780242911392406, -1.434339655172414 ], [ 29.780512405063291, -1.434339655172414 ], [ 29.780512405063291, -1.434070172413793 ], [ 29.780242911392406, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12142, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.434070172413793 ], [ 29.780512405063291, -1.434339655172414 ], [ 29.780781898734176, -1.434339655172414 ], [ 29.780781898734176, -1.434070172413793 ], [ 29.780512405063291, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12143, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.434070172413793 ], [ 29.780781898734176, -1.434339655172414 ], [ 29.781051392405065, -1.434339655172414 ], [ 29.781051392405065, -1.434070172413793 ], [ 29.780781898734176, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12144, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.434070172413793 ], [ 29.781051392405065, -1.434339655172414 ], [ 29.78132088607595, -1.434339655172414 ], [ 29.78132088607595, -1.434070172413793 ], [ 29.781051392405065, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12145, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.434070172413793 ], [ 29.78132088607595, -1.434339655172414 ], [ 29.781590379746834, -1.434339655172414 ], [ 29.781590379746834, -1.434070172413793 ], [ 29.78132088607595, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12146, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.434070172413793 ], [ 29.781590379746834, -1.434339655172414 ], [ 29.781859873417723, -1.434339655172414 ], [ 29.781859873417723, -1.434070172413793 ], [ 29.781590379746834, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12147, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.434070172413793 ], [ 29.781859873417723, -1.434339655172414 ], [ 29.782129367088608, -1.434339655172414 ], [ 29.782129367088608, -1.434070172413793 ], [ 29.781859873417723, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12148, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.434070172413793 ], [ 29.782129367088608, -1.434339655172414 ], [ 29.782398860759493, -1.434339655172414 ], [ 29.782398860759493, -1.434070172413793 ], [ 29.782129367088608, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12149, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.434070172413793 ], [ 29.782398860759493, -1.434339655172414 ], [ 29.782668354430381, -1.434339655172414 ], [ 29.782668354430381, -1.434070172413793 ], [ 29.782398860759493, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12150, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.434070172413793 ], [ 29.782668354430381, -1.434339655172414 ], [ 29.782937848101266, -1.434339655172414 ], [ 29.782937848101266, -1.434070172413793 ], [ 29.782668354430381, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12151, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.434070172413793 ], [ 29.782937848101266, -1.434339655172414 ], [ 29.783207341772151, -1.434339655172414 ], [ 29.783207341772151, -1.434070172413793 ], [ 29.782937848101266, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12152, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.434070172413793 ], [ 29.783207341772151, -1.434339655172414 ], [ 29.783476835443039, -1.434339655172414 ], [ 29.783476835443039, -1.434070172413793 ], [ 29.783207341772151, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12153, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.434070172413793 ], [ 29.783476835443039, -1.434339655172414 ], [ 29.784015822784809, -1.434339655172414 ], [ 29.784015822784809, -1.434070172413793 ], [ 29.783476835443039, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12154, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.434070172413793 ], [ 29.784015822784809, -1.434339655172414 ], [ 29.784285316455698, -1.434339655172414 ], [ 29.784285316455698, -1.434070172413793 ], [ 29.784015822784809, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12155, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.434070172413793 ], [ 29.784285316455698, -1.434339655172414 ], [ 29.784554810126583, -1.434339655172414 ], [ 29.784554810126583, -1.434070172413793 ], [ 29.784285316455698, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12156, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.434070172413793 ], [ 29.784554810126583, -1.434339655172414 ], [ 29.784824303797468, -1.434339655172414 ], [ 29.784824303797468, -1.434070172413793 ], [ 29.784554810126583, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12157, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.434070172413793 ], [ 29.784824303797468, -1.434339655172414 ], [ 29.785093797468356, -1.434339655172414 ], [ 29.785093797468356, -1.434070172413793 ], [ 29.784824303797468, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12158, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.434070172413793 ], [ 29.785093797468356, -1.434339655172414 ], [ 29.785363291139241, -1.434339655172414 ], [ 29.785363291139241, -1.434070172413793 ], [ 29.785093797468356, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12159, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.434070172413793 ], [ 29.785363291139241, -1.434339655172414 ], [ 29.785632784810126, -1.434339655172414 ], [ 29.785632784810126, -1.434070172413793 ], [ 29.785363291139241, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12160, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.434070172413793 ], [ 29.785632784810126, -1.434339655172414 ], [ 29.785902278481014, -1.434339655172414 ], [ 29.785902278481014, -1.434070172413793 ], [ 29.785632784810126, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12161, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.434070172413793 ], [ 29.785902278481014, -1.434339655172414 ], [ 29.786171772151899, -1.434339655172414 ], [ 29.786171772151899, -1.434070172413793 ], [ 29.785902278481014, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12162, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.434070172413793 ], [ 29.786171772151899, -1.434339655172414 ], [ 29.786441265822784, -1.434339655172414 ], [ 29.786441265822784, -1.434070172413793 ], [ 29.786171772151899, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12163, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.434070172413793 ], [ 29.786441265822784, -1.434339655172414 ], [ 29.786710759493673, -1.434339655172414 ], [ 29.786710759493673, -1.434070172413793 ], [ 29.786441265822784, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12164, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.434070172413793 ], [ 29.786710759493673, -1.434339655172414 ], [ 29.787249746835442, -1.434339655172414 ], [ 29.787249746835442, -1.434070172413793 ], [ 29.786710759493673, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12165, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.434070172413793 ], [ 29.787249746835442, -1.434339655172414 ], [ 29.788058227848101, -1.434339655172414 ], [ 29.788058227848101, -1.434070172413793 ], [ 29.787249746835442, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12166, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.434070172413793 ], [ 29.788058227848101, -1.434339655172414 ], [ 29.788597215189874, -1.434339655172414 ], [ 29.788597215189874, -1.434070172413793 ], [ 29.788058227848101, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12167, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.434070172413793 ], [ 29.788597215189874, -1.434339655172414 ], [ 29.788866708860759, -1.434339655172414 ], [ 29.788866708860759, -1.434070172413793 ], [ 29.788597215189874, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12168, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.434070172413793 ], [ 29.788866708860759, -1.434339655172414 ], [ 29.789136202531644, -1.434339655172414 ], [ 29.789136202531644, -1.434070172413793 ], [ 29.788866708860759, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12169, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.434070172413793 ], [ 29.789136202531644, -1.434339655172414 ], [ 29.789405696202532, -1.434339655172414 ], [ 29.789405696202532, -1.434070172413793 ], [ 29.789136202531644, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12170, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.434070172413793 ], [ 29.789405696202532, -1.434339655172414 ], [ 29.789675189873417, -1.434339655172414 ], [ 29.789675189873417, -1.434070172413793 ], [ 29.789405696202532, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12171, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.434070172413793 ], [ 29.789675189873417, -1.434339655172414 ], [ 29.789944683544302, -1.434339655172414 ], [ 29.789944683544302, -1.434070172413793 ], [ 29.789675189873417, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12172, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.434070172413793 ], [ 29.789944683544302, -1.434339655172414 ], [ 29.790214177215191, -1.434339655172414 ], [ 29.790214177215191, -1.434070172413793 ], [ 29.789944683544302, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12173, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.434070172413793 ], [ 29.790214177215191, -1.434339655172414 ], [ 29.790483670886076, -1.434339655172414 ], [ 29.790483670886076, -1.434070172413793 ], [ 29.790214177215191, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12174, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.434070172413793 ], [ 29.790483670886076, -1.434339655172414 ], [ 29.790753164556961, -1.434339655172414 ], [ 29.790753164556961, -1.434070172413793 ], [ 29.790483670886076, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12175, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.434070172413793 ], [ 29.790753164556961, -1.434339655172414 ], [ 29.791022658227849, -1.434339655172414 ], [ 29.791022658227849, -1.434070172413793 ], [ 29.790753164556961, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12176, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.434070172413793 ], [ 29.791022658227849, -1.434339655172414 ], [ 29.791292151898734, -1.434339655172414 ], [ 29.791292151898734, -1.434070172413793 ], [ 29.791022658227849, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12177, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.434070172413793 ], [ 29.791292151898734, -1.434339655172414 ], [ 29.791561645569619, -1.434339655172414 ], [ 29.791561645569619, -1.434070172413793 ], [ 29.791292151898734, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12178, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.434070172413793 ], [ 29.791561645569619, -1.434609137931035 ], [ 29.791831139240507, -1.434609137931035 ], [ 29.791831139240507, -1.434070172413793 ], [ 29.791561645569619, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12179, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.434070172413793 ], [ 29.791831139240507, -1.434339655172414 ], [ 29.792100632911392, -1.434339655172414 ], [ 29.792100632911392, -1.434070172413793 ], [ 29.791831139240507, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12180, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.434070172413793 ], [ 29.792100632911392, -1.434339655172414 ], [ 29.792370126582277, -1.434339655172414 ], [ 29.792370126582277, -1.434070172413793 ], [ 29.792100632911392, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12181, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.434070172413793 ], [ 29.792370126582277, -1.434339655172414 ], [ 29.792909113924051, -1.434339655172414 ], [ 29.792909113924051, -1.434070172413793 ], [ 29.792370126582277, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12182, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.434070172413793 ], [ 29.792909113924051, -1.434339655172414 ], [ 29.793178607594935, -1.434339655172414 ], [ 29.793178607594935, -1.434070172413793 ], [ 29.792909113924051, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12183, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.434070172413793 ], [ 29.793178607594935, -1.434339655172414 ], [ 29.793448101265824, -1.434339655172414 ], [ 29.793448101265824, -1.434070172413793 ], [ 29.793178607594935, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12184, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.434070172413793 ], [ 29.793448101265824, -1.434339655172414 ], [ 29.793717594936709, -1.434339655172414 ], [ 29.793717594936709, -1.434070172413793 ], [ 29.793448101265824, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12185, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.434070172413793 ], [ 29.793717594936709, -1.434339655172414 ], [ 29.793987088607594, -1.434339655172414 ], [ 29.793987088607594, -1.434070172413793 ], [ 29.793717594936709, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12186, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.434070172413793 ], [ 29.793987088607594, -1.434339655172414 ], [ 29.794256582278482, -1.434339655172414 ], [ 29.794256582278482, -1.434070172413793 ], [ 29.793987088607594, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12187, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.434070172413793 ], [ 29.794256582278482, -1.434339655172414 ], [ 29.794526075949367, -1.434339655172414 ], [ 29.794526075949367, -1.434070172413793 ], [ 29.794256582278482, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12188, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.434070172413793 ], [ 29.794526075949367, -1.434339655172414 ], [ 29.794795569620252, -1.434339655172414 ], [ 29.794795569620252, -1.434070172413793 ], [ 29.794526075949367, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12189, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.434070172413793 ], [ 29.794795569620252, -1.434339655172414 ], [ 29.79506506329114, -1.434339655172414 ], [ 29.79506506329114, -1.434070172413793 ], [ 29.794795569620252, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12190, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.434070172413793 ], [ 29.79506506329114, -1.434339655172414 ], [ 29.795334556962025, -1.434339655172414 ], [ 29.795334556962025, -1.434070172413793 ], [ 29.79506506329114, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12191, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.434070172413793 ], [ 29.795334556962025, -1.434339655172414 ], [ 29.79560405063291, -1.434339655172414 ], [ 29.79560405063291, -1.434070172413793 ], [ 29.795334556962025, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12192, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.434070172413793 ], [ 29.79560405063291, -1.434339655172414 ], [ 29.795873544303799, -1.434339655172414 ], [ 29.795873544303799, -1.434070172413793 ], [ 29.79560405063291, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12193, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.434070172413793 ], [ 29.795873544303799, -1.434339655172414 ], [ 29.796143037974684, -1.434339655172414 ], [ 29.796143037974684, -1.434070172413793 ], [ 29.795873544303799, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12194, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.434070172413793 ], [ 29.796143037974684, -1.434339655172414 ], [ 29.796412531645569, -1.434339655172414 ], [ 29.796412531645569, -1.434070172413793 ], [ 29.796143037974684, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12195, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.434070172413793 ], [ 29.796412531645569, -1.434339655172414 ], [ 29.796682025316457, -1.434339655172414 ], [ 29.796682025316457, -1.434070172413793 ], [ 29.796412531645569, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12196, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.434070172413793 ], [ 29.796682025316457, -1.434339655172414 ], [ 29.796951518987342, -1.434339655172414 ], [ 29.796951518987342, -1.434070172413793 ], [ 29.796682025316457, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12197, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.434070172413793 ], [ 29.796951518987342, -1.434339655172414 ], [ 29.797490506329115, -1.434339655172414 ], [ 29.797490506329115, -1.434070172413793 ], [ 29.796951518987342, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12198, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.434070172413793 ], [ 29.797490506329115, -1.434339655172414 ], [ 29.79776, -1.434339655172414 ], [ 29.79776, -1.434070172413793 ], [ 29.797490506329115, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12199, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.434070172413793 ], [ 29.79776, -1.434339655172414 ], [ 29.798029493670885, -1.434339655172414 ], [ 29.798029493670885, -1.434070172413793 ], [ 29.79776, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12200, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.434070172413793 ], [ 29.798029493670885, -1.434339655172414 ], [ 29.798298987341774, -1.434339655172414 ], [ 29.798298987341774, -1.434070172413793 ], [ 29.798029493670885, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12201, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.434070172413793 ], [ 29.798298987341774, -1.434339655172414 ], [ 29.798568481012659, -1.434339655172414 ], [ 29.798568481012659, -1.434070172413793 ], [ 29.798298987341774, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12202, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.434070172413793 ], [ 29.798568481012659, -1.434339655172414 ], [ 29.798837974683543, -1.434339655172414 ], [ 29.798837974683543, -1.434070172413793 ], [ 29.798568481012659, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12203, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.434070172413793 ], [ 29.798837974683543, -1.434339655172414 ], [ 29.799107468354432, -1.434339655172414 ], [ 29.799107468354432, -1.434070172413793 ], [ 29.798837974683543, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12204, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.434070172413793 ], [ 29.799107468354432, -1.434339655172414 ], [ 29.799376962025317, -1.434339655172414 ], [ 29.799376962025317, -1.434070172413793 ], [ 29.799107468354432, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12205, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.434070172413793 ], [ 29.799376962025317, -1.434339655172414 ], [ 29.799646455696202, -1.434339655172414 ], [ 29.799646455696202, -1.434070172413793 ], [ 29.799376962025317, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12206, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.434070172413793 ], [ 29.799646455696202, -1.434339655172414 ], [ 29.79991594936709, -1.434339655172414 ], [ 29.79991594936709, -1.434070172413793 ], [ 29.799646455696202, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12207, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.434070172413793 ], [ 29.79991594936709, -1.434339655172414 ], [ 29.800185443037975, -1.434339655172414 ], [ 29.800185443037975, -1.434070172413793 ], [ 29.79991594936709, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12208, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.434070172413793 ], [ 29.800185443037975, -1.434339655172414 ], [ 29.80045493670886, -1.434339655172414 ], [ 29.80045493670886, -1.434070172413793 ], [ 29.800185443037975, -1.434070172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12209, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.434339655172414 ], [ 29.7454782278481, -1.434609137931035 ], [ 29.745747721518985, -1.434609137931035 ], [ 29.745747721518985, -1.434339655172414 ], [ 29.7454782278481, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12210, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.434339655172414 ], [ 29.745747721518985, -1.434609137931035 ], [ 29.746017215189873, -1.434609137931035 ], [ 29.746017215189873, -1.434339655172414 ], [ 29.745747721518985, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12211, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.434339655172414 ], [ 29.746017215189873, -1.434609137931035 ], [ 29.746286708860758, -1.434609137931035 ], [ 29.746286708860758, -1.434339655172414 ], [ 29.746017215189873, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12212, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.434339655172414 ], [ 29.746286708860758, -1.434609137931035 ], [ 29.746556202531643, -1.434609137931035 ], [ 29.746556202531643, -1.434339655172414 ], [ 29.746286708860758, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12213, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.434339655172414 ], [ 29.746556202531643, -1.434609137931035 ], [ 29.747364683544301, -1.434609137931035 ], [ 29.747364683544301, -1.434339655172414 ], [ 29.746556202531643, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12214, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.434339655172414 ], [ 29.747364683544301, -1.434609137931035 ], [ 29.747903670886075, -1.434609137931035 ], [ 29.747903670886075, -1.434339655172414 ], [ 29.747364683544301, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12215, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.434339655172414 ], [ 29.747903670886075, -1.434609137931035 ], [ 29.748442658227848, -1.434609137931035 ], [ 29.748442658227848, -1.434339655172414 ], [ 29.747903670886075, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12216, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.434339655172414 ], [ 29.748442658227848, -1.434609137931035 ], [ 29.748712151898733, -1.434609137931035 ], [ 29.748712151898733, -1.434339655172414 ], [ 29.748442658227848, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12217, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.434339655172414 ], [ 29.748712151898733, -1.434609137931035 ], [ 29.748981645569618, -1.434609137931035 ], [ 29.748981645569618, -1.434339655172414 ], [ 29.748712151898733, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12218, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.434339655172414 ], [ 29.748981645569618, -1.434609137931035 ], [ 29.749251139240506, -1.434609137931035 ], [ 29.749251139240506, -1.434339655172414 ], [ 29.748981645569618, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12219, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.434339655172414 ], [ 29.749251139240506, -1.434609137931035 ], [ 29.749520632911391, -1.434609137931035 ], [ 29.749520632911391, -1.434339655172414 ], [ 29.749251139240506, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12220, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.434339655172414 ], [ 29.749520632911391, -1.434609137931035 ], [ 29.749790126582276, -1.434609137931035 ], [ 29.749790126582276, -1.434339655172414 ], [ 29.749520632911391, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12221, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.434339655172414 ], [ 29.749790126582276, -1.434609137931035 ], [ 29.750059620253165, -1.434609137931035 ], [ 29.750059620253165, -1.434339655172414 ], [ 29.749790126582276, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12222, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.434339655172414 ], [ 29.750059620253165, -1.434609137931035 ], [ 29.75032911392405, -1.434609137931035 ], [ 29.75032911392405, -1.434339655172414 ], [ 29.750059620253165, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12223, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.434339655172414 ], [ 29.75032911392405, -1.434609137931035 ], [ 29.750598607594934, -1.434609137931035 ], [ 29.750598607594934, -1.434339655172414 ], [ 29.75032911392405, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12224, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.434339655172414 ], [ 29.750598607594934, -1.434609137931035 ], [ 29.750868101265823, -1.434609137931035 ], [ 29.750868101265823, -1.434339655172414 ], [ 29.750598607594934, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12225, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.434339655172414 ], [ 29.750868101265823, -1.434609137931035 ], [ 29.751137594936708, -1.434609137931035 ], [ 29.751137594936708, -1.434339655172414 ], [ 29.750868101265823, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12226, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.434339655172414 ], [ 29.751137594936708, -1.434609137931035 ], [ 29.754102025316456, -1.434609137931035 ], [ 29.754102025316456, -1.434339655172414 ], [ 29.751137594936708, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12227, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.434339655172414 ], [ 29.754102025316456, -1.434609137931035 ], [ 29.754371518987341, -1.434609137931035 ], [ 29.754371518987341, -1.434339655172414 ], [ 29.754102025316456, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.434339655172414 ], [ 29.754371518987341, -1.434609137931035 ], [ 29.754641012658226, -1.434609137931035 ], [ 29.754641012658226, -1.434339655172414 ], [ 29.754371518987341, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12229, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.434339655172414 ], [ 29.754641012658226, -1.434609137931035 ], [ 29.754910506329114, -1.434609137931035 ], [ 29.754910506329114, -1.434339655172414 ], [ 29.754641012658226, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12230, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.434339655172414 ], [ 29.755449493670884, -1.434878620689655 ], [ 29.755718987341773, -1.434878620689655 ], [ 29.755718987341773, -1.434339655172414 ], [ 29.755449493670884, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12231, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.434339655172414 ], [ 29.755718987341773, -1.434609137931035 ], [ 29.755988481012658, -1.434609137931035 ], [ 29.755988481012658, -1.434339655172414 ], [ 29.755718987341773, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12232, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.434339655172414 ], [ 29.755988481012658, -1.434609137931035 ], [ 29.756257974683542, -1.434609137931035 ], [ 29.756257974683542, -1.434339655172414 ], [ 29.755988481012658, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12233, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.434339655172414 ], [ 29.756257974683542, -1.434609137931035 ], [ 29.756796962025316, -1.434609137931035 ], [ 29.756796962025316, -1.434339655172414 ], [ 29.756257974683542, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12234, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.434339655172414 ], [ 29.756796962025316, -1.434609137931035 ], [ 29.757066455696201, -1.434609137931035 ], [ 29.757066455696201, -1.434339655172414 ], [ 29.756796962025316, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12235, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.434339655172414 ], [ 29.757066455696201, -1.434609137931035 ], [ 29.757335949367089, -1.434609137931035 ], [ 29.757335949367089, -1.434339655172414 ], [ 29.757066455696201, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12236, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.434339655172414 ], [ 29.757335949367089, -1.434878620689655 ], [ 29.757605443037974, -1.434878620689655 ], [ 29.757605443037974, -1.434339655172414 ], [ 29.757335949367089, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12237, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.434339655172414 ], [ 29.757605443037974, -1.434609137931035 ], [ 29.757874936708859, -1.434609137931035 ], [ 29.757874936708859, -1.434339655172414 ], [ 29.757605443037974, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12238, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.434339655172414 ], [ 29.757874936708859, -1.434609137931035 ], [ 29.758144430379748, -1.434609137931035 ], [ 29.758144430379748, -1.434339655172414 ], [ 29.757874936708859, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12239, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.434339655172414 ], [ 29.758144430379748, -1.434609137931035 ], [ 29.758413924050632, -1.434609137931035 ], [ 29.758413924050632, -1.434339655172414 ], [ 29.758144430379748, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12240, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.434339655172414 ], [ 29.758413924050632, -1.434609137931035 ], [ 29.758683417721517, -1.434609137931035 ], [ 29.758683417721517, -1.434339655172414 ], [ 29.758413924050632, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12241, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.434339655172414 ], [ 29.758683417721517, -1.434609137931035 ], [ 29.758952911392406, -1.434609137931035 ], [ 29.758952911392406, -1.434339655172414 ], [ 29.758683417721517, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12242, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.434339655172414 ], [ 29.758952911392406, -1.434609137931035 ], [ 29.759491898734176, -1.434609137931035 ], [ 29.759491898734176, -1.434339655172414 ], [ 29.758952911392406, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12243, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.434339655172414 ], [ 29.759491898734176, -1.434609137931035 ], [ 29.759761392405064, -1.434609137931035 ], [ 29.759761392405064, -1.434339655172414 ], [ 29.759491898734176, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12244, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.434339655172414 ], [ 29.759761392405064, -1.434609137931035 ], [ 29.761378354430381, -1.434609137931035 ], [ 29.761378354430381, -1.434339655172414 ], [ 29.759761392405064, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12245, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.434339655172414 ], [ 29.761378354430381, -1.434609137931035 ], [ 29.761647848101266, -1.434609137931035 ], [ 29.761647848101266, -1.434339655172414 ], [ 29.761378354430381, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12246, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.434339655172414 ], [ 29.761647848101266, -1.434609137931035 ], [ 29.761917341772151, -1.434609137931035 ], [ 29.761917341772151, -1.434339655172414 ], [ 29.761647848101266, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12247, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.434339655172414 ], [ 29.761917341772151, -1.434609137931035 ], [ 29.762186835443039, -1.434609137931035 ], [ 29.762186835443039, -1.434339655172414 ], [ 29.761917341772151, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12248, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.434339655172414 ], [ 29.762186835443039, -1.434609137931035 ], [ 29.762456329113924, -1.434609137931035 ], [ 29.762456329113924, -1.434339655172414 ], [ 29.762186835443039, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12249, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.434339655172414 ], [ 29.762456329113924, -1.434609137931035 ], [ 29.762725822784809, -1.434609137931035 ], [ 29.762725822784809, -1.434339655172414 ], [ 29.762456329113924, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12250, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.434339655172414 ], [ 29.762725822784809, -1.434609137931035 ], [ 29.762995316455697, -1.434609137931035 ], [ 29.762995316455697, -1.434339655172414 ], [ 29.762725822784809, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12251, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.434339655172414 ], [ 29.762995316455697, -1.434609137931035 ], [ 29.763264810126582, -1.434609137931035 ], [ 29.763264810126582, -1.434339655172414 ], [ 29.762995316455697, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12252, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.434339655172414 ], [ 29.763264810126582, -1.434609137931035 ], [ 29.763534303797467, -1.434609137931035 ], [ 29.763534303797467, -1.434339655172414 ], [ 29.763264810126582, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12253, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.434339655172414 ], [ 29.763534303797467, -1.434609137931035 ], [ 29.763803797468356, -1.434609137931035 ], [ 29.763803797468356, -1.434339655172414 ], [ 29.763534303797467, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12254, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.434339655172414 ], [ 29.763803797468356, -1.434878620689655 ], [ 29.76407329113924, -1.434878620689655 ], [ 29.76407329113924, -1.434339655172414 ], [ 29.763803797468356, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12255, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.434339655172414 ], [ 29.76407329113924, -1.434609137931035 ], [ 29.764342784810125, -1.434609137931035 ], [ 29.764342784810125, -1.434339655172414 ], [ 29.76407329113924, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12256, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.434339655172414 ], [ 29.764342784810125, -1.434609137931035 ], [ 29.764612278481014, -1.434609137931035 ], [ 29.764612278481014, -1.434339655172414 ], [ 29.764342784810125, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12257, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.434339655172414 ], [ 29.764612278481014, -1.434878620689655 ], [ 29.764881772151899, -1.434878620689655 ], [ 29.764881772151899, -1.434339655172414 ], [ 29.764612278481014, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12258, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.434339655172414 ], [ 29.765151265822784, -1.434609137931035 ], [ 29.765420759493672, -1.434609137931035 ], [ 29.765420759493672, -1.434339655172414 ], [ 29.765151265822784, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12259, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.434339655172414 ], [ 29.765420759493672, -1.434878620689655 ], [ 29.765959746835442, -1.434878620689655 ], [ 29.765959746835442, -1.434339655172414 ], [ 29.765420759493672, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12260, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.434339655172414 ], [ 29.766229240506327, -1.434609137931035 ], [ 29.766498734177215, -1.434609137931035 ], [ 29.766498734177215, -1.434339655172414 ], [ 29.766229240506327, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12261, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.434339655172414 ], [ 29.766498734177215, -1.434609137931035 ], [ 29.7667682278481, -1.434609137931035 ], [ 29.7667682278481, -1.434339655172414 ], [ 29.766498734177215, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12262, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.434339655172414 ], [ 29.767037721518985, -1.434609137931035 ], [ 29.767307215189874, -1.434609137931035 ], [ 29.767307215189874, -1.434339655172414 ], [ 29.767037721518985, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12263, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.434339655172414 ], [ 29.767307215189874, -1.434609137931035 ], [ 29.767576708860759, -1.434609137931035 ], [ 29.767576708860759, -1.434339655172414 ], [ 29.767307215189874, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12264, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.434339655172414 ], [ 29.767576708860759, -1.434878620689655 ], [ 29.767846202531643, -1.434878620689655 ], [ 29.767846202531643, -1.434339655172414 ], [ 29.767576708860759, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12265, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.434339655172414 ], [ 29.767846202531643, -1.434609137931035 ], [ 29.768115696202532, -1.434609137931035 ], [ 29.768115696202532, -1.434339655172414 ], [ 29.767846202531643, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12266, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.434339655172414 ], [ 29.768115696202532, -1.434609137931035 ], [ 29.768385189873417, -1.434609137931035 ], [ 29.768385189873417, -1.434339655172414 ], [ 29.768115696202532, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12267, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.434339655172414 ], [ 29.768385189873417, -1.434609137931035 ], [ 29.768654683544302, -1.434609137931035 ], [ 29.768654683544302, -1.434339655172414 ], [ 29.768385189873417, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12268, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.434339655172414 ], [ 29.76892417721519, -1.434609137931035 ], [ 29.769193670886075, -1.434609137931035 ], [ 29.769193670886075, -1.434339655172414 ], [ 29.76892417721519, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12269, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.434339655172414 ], [ 29.769193670886075, -1.434609137931035 ], [ 29.76946316455696, -1.434609137931035 ], [ 29.76946316455696, -1.434339655172414 ], [ 29.769193670886075, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12270, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.434339655172414 ], [ 29.76946316455696, -1.434878620689655 ], [ 29.769732658227849, -1.434878620689655 ], [ 29.769732658227849, -1.434339655172414 ], [ 29.76946316455696, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12271, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.434339655172414 ], [ 29.769732658227849, -1.434609137931035 ], [ 29.770002151898733, -1.434609137931035 ], [ 29.770002151898733, -1.434339655172414 ], [ 29.769732658227849, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12272, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.434339655172414 ], [ 29.770002151898733, -1.434609137931035 ], [ 29.770271645569618, -1.434609137931035 ], [ 29.770271645569618, -1.434339655172414 ], [ 29.770002151898733, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12273, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.434339655172414 ], [ 29.770271645569618, -1.434878620689655 ], [ 29.770541139240507, -1.434878620689655 ], [ 29.770541139240507, -1.434339655172414 ], [ 29.770271645569618, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12274, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.434339655172414 ], [ 29.770810632911392, -1.434609137931035 ], [ 29.771080126582277, -1.434609137931035 ], [ 29.771080126582277, -1.434339655172414 ], [ 29.770810632911392, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12275, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.434339655172414 ], [ 29.771080126582277, -1.434609137931035 ], [ 29.771349620253165, -1.434609137931035 ], [ 29.771349620253165, -1.434339655172414 ], [ 29.771080126582277, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12276, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.434339655172414 ], [ 29.77161911392405, -1.434609137931035 ], [ 29.771888607594935, -1.434609137931035 ], [ 29.771888607594935, -1.434339655172414 ], [ 29.77161911392405, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12277, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.434339655172414 ], [ 29.771888607594935, -1.434609137931035 ], [ 29.772158101265823, -1.434609137931035 ], [ 29.772158101265823, -1.434339655172414 ], [ 29.771888607594935, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12278, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.434339655172414 ], [ 29.772158101265823, -1.434878620689655 ], [ 29.772427594936708, -1.434878620689655 ], [ 29.772427594936708, -1.434339655172414 ], [ 29.772158101265823, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12279, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.434339655172414 ], [ 29.772427594936708, -1.434609137931035 ], [ 29.772697088607593, -1.434609137931035 ], [ 29.772697088607593, -1.434339655172414 ], [ 29.772427594936708, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12280, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.434339655172414 ], [ 29.772697088607593, -1.434609137931035 ], [ 29.772966582278482, -1.434609137931035 ], [ 29.772966582278482, -1.434339655172414 ], [ 29.772697088607593, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12281, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.434339655172414 ], [ 29.772966582278482, -1.434609137931035 ], [ 29.773236075949367, -1.434609137931035 ], [ 29.773236075949367, -1.434339655172414 ], [ 29.772966582278482, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12282, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.434339655172414 ], [ 29.773505569620252, -1.434609137931035 ], [ 29.77377506329114, -1.434609137931035 ], [ 29.77377506329114, -1.434339655172414 ], [ 29.773505569620252, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12283, "El": 153 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.434339655172414 ], [ 29.77377506329114, -1.434609137931035 ], [ 29.774044556962025, -1.434609137931035 ], [ 29.774044556962025, -1.434339655172414 ], [ 29.77377506329114, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12284, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.434339655172414 ], [ 29.774044556962025, -1.434609137931035 ], [ 29.77431405063291, -1.434609137931035 ], [ 29.77431405063291, -1.434339655172414 ], [ 29.774044556962025, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12285, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.434339655172414 ], [ 29.77431405063291, -1.434609137931035 ], [ 29.774853037974683, -1.434609137931035 ], [ 29.774853037974683, -1.434339655172414 ], [ 29.77431405063291, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12286, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.434339655172414 ], [ 29.774853037974683, -1.434609137931035 ], [ 29.775122531645568, -1.434609137931035 ], [ 29.775122531645568, -1.434339655172414 ], [ 29.774853037974683, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12287, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.434339655172414 ], [ 29.775392025316457, -1.434609137931035 ], [ 29.775661518987341, -1.434609137931035 ], [ 29.775661518987341, -1.434339655172414 ], [ 29.775392025316457, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12288, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.434339655172414 ], [ 29.775661518987341, -1.434609137931035 ], [ 29.775931012658226, -1.434609137931035 ], [ 29.775931012658226, -1.434339655172414 ], [ 29.775661518987341, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12289, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.434339655172414 ], [ 29.776200506329115, -1.434878620689655 ], [ 29.77647, -1.434878620689655 ], [ 29.77647, -1.434339655172414 ], [ 29.776200506329115, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12290, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.434339655172414 ], [ 29.77647, -1.434878620689655 ], [ 29.776739493670885, -1.434878620689655 ], [ 29.776739493670885, -1.434339655172414 ], [ 29.77647, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12291, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.434339655172414 ], [ 29.776739493670885, -1.434609137931035 ], [ 29.777008987341773, -1.434609137931035 ], [ 29.777008987341773, -1.434339655172414 ], [ 29.776739493670885, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12292, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.434339655172414 ], [ 29.777008987341773, -1.434878620689655 ], [ 29.777278481012658, -1.434878620689655 ], [ 29.777278481012658, -1.434339655172414 ], [ 29.777008987341773, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12293, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.434339655172414 ], [ 29.777278481012658, -1.434878620689655 ], [ 29.777547974683543, -1.434878620689655 ], [ 29.777547974683543, -1.434339655172414 ], [ 29.777278481012658, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12294, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.434339655172414 ], [ 29.777547974683543, -1.434878620689655 ], [ 29.777817468354431, -1.434878620689655 ], [ 29.777817468354431, -1.434339655172414 ], [ 29.777547974683543, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12295, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.434339655172414 ], [ 29.778086962025316, -1.434609137931035 ], [ 29.778356455696201, -1.434609137931035 ], [ 29.778356455696201, -1.434339655172414 ], [ 29.778086962025316, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12296, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.434339655172414 ], [ 29.778356455696201, -1.434878620689655 ], [ 29.77862594936709, -1.434878620689655 ], [ 29.77862594936709, -1.434339655172414 ], [ 29.778356455696201, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12297, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.434339655172414 ], [ 29.77862594936709, -1.434878620689655 ], [ 29.778895443037975, -1.434878620689655 ], [ 29.778895443037975, -1.434339655172414 ], [ 29.77862594936709, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12298, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.434339655172414 ], [ 29.778895443037975, -1.434878620689655 ], [ 29.77916493670886, -1.434878620689655 ], [ 29.77916493670886, -1.434339655172414 ], [ 29.778895443037975, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12299, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.434339655172414 ], [ 29.77916493670886, -1.434609137931035 ], [ 29.779434430379748, -1.434609137931035 ], [ 29.779434430379748, -1.434339655172414 ], [ 29.77916493670886, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12300, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.434339655172414 ], [ 29.779434430379748, -1.434609137931035 ], [ 29.779703924050633, -1.434609137931035 ], [ 29.779703924050633, -1.434339655172414 ], [ 29.779434430379748, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12301, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.434339655172414 ], [ 29.779703924050633, -1.434609137931035 ], [ 29.779973417721518, -1.434609137931035 ], [ 29.779973417721518, -1.434339655172414 ], [ 29.779703924050633, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12302, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.434339655172414 ], [ 29.779973417721518, -1.434609137931035 ], [ 29.780242911392406, -1.434609137931035 ], [ 29.780242911392406, -1.434339655172414 ], [ 29.779973417721518, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12303, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.434339655172414 ], [ 29.780242911392406, -1.434609137931035 ], [ 29.780512405063291, -1.434609137931035 ], [ 29.780512405063291, -1.434339655172414 ], [ 29.780242911392406, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12304, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.434339655172414 ], [ 29.780512405063291, -1.434609137931035 ], [ 29.780781898734176, -1.434609137931035 ], [ 29.780781898734176, -1.434339655172414 ], [ 29.780512405063291, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12305, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.434339655172414 ], [ 29.780781898734176, -1.434609137931035 ], [ 29.781051392405065, -1.434609137931035 ], [ 29.781051392405065, -1.434339655172414 ], [ 29.780781898734176, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12306, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.434339655172414 ], [ 29.781051392405065, -1.434609137931035 ], [ 29.78132088607595, -1.434609137931035 ], [ 29.78132088607595, -1.434339655172414 ], [ 29.781051392405065, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12307, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.434339655172414 ], [ 29.78132088607595, -1.434609137931035 ], [ 29.781590379746834, -1.434609137931035 ], [ 29.781590379746834, -1.434339655172414 ], [ 29.78132088607595, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12308, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.434339655172414 ], [ 29.781590379746834, -1.434609137931035 ], [ 29.781859873417723, -1.434609137931035 ], [ 29.781859873417723, -1.434339655172414 ], [ 29.781590379746834, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12309, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.434339655172414 ], [ 29.781859873417723, -1.434609137931035 ], [ 29.782129367088608, -1.434609137931035 ], [ 29.782129367088608, -1.434339655172414 ], [ 29.781859873417723, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12310, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.434339655172414 ], [ 29.782129367088608, -1.434609137931035 ], [ 29.782398860759493, -1.434609137931035 ], [ 29.782398860759493, -1.434339655172414 ], [ 29.782129367088608, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12311, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.434339655172414 ], [ 29.782398860759493, -1.434609137931035 ], [ 29.782668354430381, -1.434609137931035 ], [ 29.782668354430381, -1.434339655172414 ], [ 29.782398860759493, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12312, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.434339655172414 ], [ 29.782668354430381, -1.434609137931035 ], [ 29.782937848101266, -1.434609137931035 ], [ 29.782937848101266, -1.434339655172414 ], [ 29.782668354430381, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12313, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.434339655172414 ], [ 29.782937848101266, -1.434609137931035 ], [ 29.783207341772151, -1.434609137931035 ], [ 29.783207341772151, -1.434339655172414 ], [ 29.782937848101266, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12314, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.434339655172414 ], [ 29.783207341772151, -1.434609137931035 ], [ 29.783476835443039, -1.434609137931035 ], [ 29.783476835443039, -1.434339655172414 ], [ 29.783207341772151, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12315, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.434339655172414 ], [ 29.783476835443039, -1.434609137931035 ], [ 29.784015822784809, -1.434609137931035 ], [ 29.784015822784809, -1.434339655172414 ], [ 29.783476835443039, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12316, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.434339655172414 ], [ 29.784015822784809, -1.434609137931035 ], [ 29.784285316455698, -1.434609137931035 ], [ 29.784285316455698, -1.434339655172414 ], [ 29.784015822784809, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12317, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.434339655172414 ], [ 29.784285316455698, -1.434609137931035 ], [ 29.784554810126583, -1.434609137931035 ], [ 29.784554810126583, -1.434339655172414 ], [ 29.784285316455698, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12318, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.434339655172414 ], [ 29.784554810126583, -1.434609137931035 ], [ 29.784824303797468, -1.434609137931035 ], [ 29.784824303797468, -1.434339655172414 ], [ 29.784554810126583, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12319, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.434339655172414 ], [ 29.784824303797468, -1.434609137931035 ], [ 29.785093797468356, -1.434609137931035 ], [ 29.785093797468356, -1.434339655172414 ], [ 29.784824303797468, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12320, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.434339655172414 ], [ 29.785093797468356, -1.434609137931035 ], [ 29.785363291139241, -1.434609137931035 ], [ 29.785363291139241, -1.434339655172414 ], [ 29.785093797468356, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12321, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.434339655172414 ], [ 29.785363291139241, -1.434609137931035 ], [ 29.785632784810126, -1.434609137931035 ], [ 29.785632784810126, -1.434339655172414 ], [ 29.785363291139241, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12322, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.434339655172414 ], [ 29.785632784810126, -1.434609137931035 ], [ 29.785902278481014, -1.434609137931035 ], [ 29.785902278481014, -1.434339655172414 ], [ 29.785632784810126, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12323, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.434339655172414 ], [ 29.785902278481014, -1.434609137931035 ], [ 29.786171772151899, -1.434609137931035 ], [ 29.786171772151899, -1.434339655172414 ], [ 29.785902278481014, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12324, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.434339655172414 ], [ 29.786171772151899, -1.434609137931035 ], [ 29.786441265822784, -1.434609137931035 ], [ 29.786441265822784, -1.434339655172414 ], [ 29.786171772151899, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12325, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.434339655172414 ], [ 29.786441265822784, -1.434609137931035 ], [ 29.786710759493673, -1.434609137931035 ], [ 29.786710759493673, -1.434339655172414 ], [ 29.786441265822784, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12326, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.434339655172414 ], [ 29.786710759493673, -1.434609137931035 ], [ 29.786980253164558, -1.434609137931035 ], [ 29.786980253164558, -1.434339655172414 ], [ 29.786710759493673, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12327, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.434339655172414 ], [ 29.786980253164558, -1.434609137931035 ], [ 29.788327721518986, -1.434609137931035 ], [ 29.788327721518986, -1.434339655172414 ], [ 29.786980253164558, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12328, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.434339655172414 ], [ 29.788327721518986, -1.434609137931035 ], [ 29.788597215189874, -1.434609137931035 ], [ 29.788597215189874, -1.434339655172414 ], [ 29.788327721518986, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12329, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.434339655172414 ], [ 29.788597215189874, -1.434609137931035 ], [ 29.788866708860759, -1.434609137931035 ], [ 29.788866708860759, -1.434339655172414 ], [ 29.788597215189874, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12330, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.434339655172414 ], [ 29.788866708860759, -1.434609137931035 ], [ 29.789136202531644, -1.434609137931035 ], [ 29.789136202531644, -1.434339655172414 ], [ 29.788866708860759, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12331, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.434339655172414 ], [ 29.789136202531644, -1.434609137931035 ], [ 29.789405696202532, -1.434609137931035 ], [ 29.789405696202532, -1.434339655172414 ], [ 29.789136202531644, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12332, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.434339655172414 ], [ 29.789405696202532, -1.434609137931035 ], [ 29.789675189873417, -1.434609137931035 ], [ 29.789675189873417, -1.434339655172414 ], [ 29.789405696202532, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12333, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.434339655172414 ], [ 29.789675189873417, -1.434609137931035 ], [ 29.789944683544302, -1.434609137931035 ], [ 29.789944683544302, -1.434339655172414 ], [ 29.789675189873417, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12334, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.434339655172414 ], [ 29.789944683544302, -1.434609137931035 ], [ 29.790214177215191, -1.434609137931035 ], [ 29.790214177215191, -1.434339655172414 ], [ 29.789944683544302, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12335, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.434339655172414 ], [ 29.790214177215191, -1.434609137931035 ], [ 29.790483670886076, -1.434609137931035 ], [ 29.790483670886076, -1.434339655172414 ], [ 29.790214177215191, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12336, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.434339655172414 ], [ 29.790483670886076, -1.434609137931035 ], [ 29.790753164556961, -1.434609137931035 ], [ 29.790753164556961, -1.434339655172414 ], [ 29.790483670886076, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12337, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.434339655172414 ], [ 29.790753164556961, -1.434878620689655 ], [ 29.791022658227849, -1.434878620689655 ], [ 29.791022658227849, -1.434339655172414 ], [ 29.790753164556961, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12338, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.434339655172414 ], [ 29.791022658227849, -1.434609137931035 ], [ 29.791292151898734, -1.434609137931035 ], [ 29.791292151898734, -1.434339655172414 ], [ 29.791022658227849, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12339, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.434339655172414 ], [ 29.791292151898734, -1.434609137931035 ], [ 29.791561645569619, -1.434609137931035 ], [ 29.791561645569619, -1.434339655172414 ], [ 29.791292151898734, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12340, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.434339655172414 ], [ 29.791831139240507, -1.434609137931035 ], [ 29.792100632911392, -1.434609137931035 ], [ 29.792100632911392, -1.434339655172414 ], [ 29.791831139240507, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12341, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.434339655172414 ], [ 29.792100632911392, -1.434609137931035 ], [ 29.792370126582277, -1.434609137931035 ], [ 29.792370126582277, -1.434339655172414 ], [ 29.792100632911392, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12342, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.434339655172414 ], [ 29.792370126582277, -1.434609137931035 ], [ 29.792909113924051, -1.434609137931035 ], [ 29.792909113924051, -1.434339655172414 ], [ 29.792370126582277, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12343, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.434339655172414 ], [ 29.792909113924051, -1.434609137931035 ], [ 29.793178607594935, -1.434609137931035 ], [ 29.793178607594935, -1.434339655172414 ], [ 29.792909113924051, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12344, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.434339655172414 ], [ 29.793178607594935, -1.434609137931035 ], [ 29.793448101265824, -1.434609137931035 ], [ 29.793448101265824, -1.434339655172414 ], [ 29.793178607594935, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12345, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.434339655172414 ], [ 29.793448101265824, -1.434609137931035 ], [ 29.793717594936709, -1.434609137931035 ], [ 29.793717594936709, -1.434339655172414 ], [ 29.793448101265824, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12346, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.434339655172414 ], [ 29.793717594936709, -1.434609137931035 ], [ 29.793987088607594, -1.434609137931035 ], [ 29.793987088607594, -1.434339655172414 ], [ 29.793717594936709, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12347, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.434339655172414 ], [ 29.793987088607594, -1.434609137931035 ], [ 29.794256582278482, -1.434609137931035 ], [ 29.794256582278482, -1.434339655172414 ], [ 29.793987088607594, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12348, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.434339655172414 ], [ 29.794256582278482, -1.434609137931035 ], [ 29.794526075949367, -1.434609137931035 ], [ 29.794526075949367, -1.434339655172414 ], [ 29.794256582278482, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12349, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.434339655172414 ], [ 29.794526075949367, -1.434609137931035 ], [ 29.794795569620252, -1.434609137931035 ], [ 29.794795569620252, -1.434339655172414 ], [ 29.794526075949367, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12350, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.434339655172414 ], [ 29.794795569620252, -1.434609137931035 ], [ 29.79506506329114, -1.434609137931035 ], [ 29.79506506329114, -1.434339655172414 ], [ 29.794795569620252, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12351, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.434339655172414 ], [ 29.79506506329114, -1.434609137931035 ], [ 29.795334556962025, -1.434609137931035 ], [ 29.795334556962025, -1.434339655172414 ], [ 29.79506506329114, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12352, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.434339655172414 ], [ 29.795334556962025, -1.434609137931035 ], [ 29.79560405063291, -1.434609137931035 ], [ 29.79560405063291, -1.434339655172414 ], [ 29.795334556962025, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12353, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.434339655172414 ], [ 29.79560405063291, -1.434609137931035 ], [ 29.795873544303799, -1.434609137931035 ], [ 29.795873544303799, -1.434339655172414 ], [ 29.79560405063291, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12354, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.434339655172414 ], [ 29.795873544303799, -1.434609137931035 ], [ 29.796143037974684, -1.434609137931035 ], [ 29.796143037974684, -1.434339655172414 ], [ 29.795873544303799, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12355, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.434339655172414 ], [ 29.796143037974684, -1.434609137931035 ], [ 29.796412531645569, -1.434609137931035 ], [ 29.796412531645569, -1.434339655172414 ], [ 29.796143037974684, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12356, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.434339655172414 ], [ 29.796412531645569, -1.434609137931035 ], [ 29.796682025316457, -1.434609137931035 ], [ 29.796682025316457, -1.434339655172414 ], [ 29.796412531645569, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12357, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.434339655172414 ], [ 29.796682025316457, -1.434609137931035 ], [ 29.796951518987342, -1.434609137931035 ], [ 29.796951518987342, -1.434339655172414 ], [ 29.796682025316457, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12358, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.434339655172414 ], [ 29.796951518987342, -1.434609137931035 ], [ 29.797490506329115, -1.434609137931035 ], [ 29.797490506329115, -1.434339655172414 ], [ 29.796951518987342, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12359, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.434339655172414 ], [ 29.797490506329115, -1.434609137931035 ], [ 29.79776, -1.434609137931035 ], [ 29.79776, -1.434339655172414 ], [ 29.797490506329115, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12360, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.434339655172414 ], [ 29.79776, -1.434609137931035 ], [ 29.798029493670885, -1.434609137931035 ], [ 29.798029493670885, -1.434339655172414 ], [ 29.79776, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12361, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.434339655172414 ], [ 29.798029493670885, -1.434609137931035 ], [ 29.798298987341774, -1.434609137931035 ], [ 29.798298987341774, -1.434339655172414 ], [ 29.798029493670885, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12362, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.434339655172414 ], [ 29.798298987341774, -1.434609137931035 ], [ 29.798568481012659, -1.434609137931035 ], [ 29.798568481012659, -1.434339655172414 ], [ 29.798298987341774, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12363, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.434339655172414 ], [ 29.798568481012659, -1.434609137931035 ], [ 29.798837974683543, -1.434609137931035 ], [ 29.798837974683543, -1.434339655172414 ], [ 29.798568481012659, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12364, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.434339655172414 ], [ 29.798837974683543, -1.434609137931035 ], [ 29.799107468354432, -1.434609137931035 ], [ 29.799107468354432, -1.434339655172414 ], [ 29.798837974683543, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12365, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.434339655172414 ], [ 29.799107468354432, -1.434609137931035 ], [ 29.799376962025317, -1.434609137931035 ], [ 29.799376962025317, -1.434339655172414 ], [ 29.799107468354432, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12366, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.434339655172414 ], [ 29.799376962025317, -1.434609137931035 ], [ 29.799646455696202, -1.434609137931035 ], [ 29.799646455696202, -1.434339655172414 ], [ 29.799376962025317, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12367, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.434339655172414 ], [ 29.799646455696202, -1.434609137931035 ], [ 29.79991594936709, -1.434609137931035 ], [ 29.79991594936709, -1.434339655172414 ], [ 29.799646455696202, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12368, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.434339655172414 ], [ 29.79991594936709, -1.434609137931035 ], [ 29.800185443037975, -1.434609137931035 ], [ 29.800185443037975, -1.434339655172414 ], [ 29.79991594936709, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12369, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.434339655172414 ], [ 29.800185443037975, -1.434609137931035 ], [ 29.80045493670886, -1.434609137931035 ], [ 29.80045493670886, -1.434339655172414 ], [ 29.800185443037975, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12370, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.434339655172414 ], [ 29.80045493670886, -1.434609137931035 ], [ 29.800724430379748, -1.434609137931035 ], [ 29.800724430379748, -1.434339655172414 ], [ 29.80045493670886, -1.434339655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12371, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.434609137931035 ], [ 29.745208734177215, -1.434878620689655 ], [ 29.7454782278481, -1.434878620689655 ], [ 29.7454782278481, -1.434609137931035 ], [ 29.745208734177215, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12372, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.434609137931035 ], [ 29.7454782278481, -1.434878620689655 ], [ 29.745747721518985, -1.434878620689655 ], [ 29.745747721518985, -1.434609137931035 ], [ 29.7454782278481, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12373, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.434609137931035 ], [ 29.745747721518985, -1.434878620689655 ], [ 29.746017215189873, -1.434878620689655 ], [ 29.746017215189873, -1.434609137931035 ], [ 29.745747721518985, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12374, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.434609137931035 ], [ 29.746017215189873, -1.434878620689655 ], [ 29.746286708860758, -1.434878620689655 ], [ 29.746286708860758, -1.434609137931035 ], [ 29.746017215189873, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12375, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.434609137931035 ], [ 29.746286708860758, -1.434878620689655 ], [ 29.746556202531643, -1.434878620689655 ], [ 29.746556202531643, -1.434609137931035 ], [ 29.746286708860758, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12376, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.434609137931035 ], [ 29.746556202531643, -1.434878620689655 ], [ 29.747095189873416, -1.434878620689655 ], [ 29.747095189873416, -1.434609137931035 ], [ 29.746556202531643, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12377, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.434609137931035 ], [ 29.747095189873416, -1.434878620689655 ], [ 29.747903670886075, -1.434878620689655 ], [ 29.747903670886075, -1.434609137931035 ], [ 29.747095189873416, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12378, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.434609137931035 ], [ 29.747903670886075, -1.434878620689655 ], [ 29.748442658227848, -1.434878620689655 ], [ 29.748442658227848, -1.434609137931035 ], [ 29.747903670886075, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12379, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.434609137931035 ], [ 29.748442658227848, -1.434878620689655 ], [ 29.748712151898733, -1.434878620689655 ], [ 29.748712151898733, -1.434609137931035 ], [ 29.748442658227848, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12380, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.434609137931035 ], [ 29.748712151898733, -1.434878620689655 ], [ 29.748981645569618, -1.434878620689655 ], [ 29.748981645569618, -1.434609137931035 ], [ 29.748712151898733, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12381, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.434609137931035 ], [ 29.748981645569618, -1.434878620689655 ], [ 29.749251139240506, -1.434878620689655 ], [ 29.749251139240506, -1.434609137931035 ], [ 29.748981645569618, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12382, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.434609137931035 ], [ 29.749251139240506, -1.434878620689655 ], [ 29.749520632911391, -1.434878620689655 ], [ 29.749520632911391, -1.434609137931035 ], [ 29.749251139240506, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12383, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.434609137931035 ], [ 29.749520632911391, -1.434878620689655 ], [ 29.749790126582276, -1.434878620689655 ], [ 29.749790126582276, -1.434609137931035 ], [ 29.749520632911391, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12384, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.434609137931035 ], [ 29.749790126582276, -1.434878620689655 ], [ 29.750059620253165, -1.434878620689655 ], [ 29.750059620253165, -1.434609137931035 ], [ 29.749790126582276, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12385, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.434609137931035 ], [ 29.750059620253165, -1.434878620689655 ], [ 29.75032911392405, -1.434878620689655 ], [ 29.75032911392405, -1.434609137931035 ], [ 29.750059620253165, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12386, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.434609137931035 ], [ 29.75032911392405, -1.434878620689655 ], [ 29.750598607594934, -1.434878620689655 ], [ 29.750598607594934, -1.434609137931035 ], [ 29.75032911392405, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12387, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.434609137931035 ], [ 29.750598607594934, -1.434878620689655 ], [ 29.750868101265823, -1.434878620689655 ], [ 29.750868101265823, -1.434609137931035 ], [ 29.750598607594934, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12388, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.434609137931035 ], [ 29.750868101265823, -1.434878620689655 ], [ 29.751137594936708, -1.434878620689655 ], [ 29.751137594936708, -1.434609137931035 ], [ 29.750868101265823, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12389, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.434609137931035 ], [ 29.751137594936708, -1.434878620689655 ], [ 29.754102025316456, -1.434878620689655 ], [ 29.754102025316456, -1.434609137931035 ], [ 29.751137594936708, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12390, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.434609137931035 ], [ 29.754102025316456, -1.434878620689655 ], [ 29.754371518987341, -1.434878620689655 ], [ 29.754371518987341, -1.434609137931035 ], [ 29.754102025316456, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12391, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.434609137931035 ], [ 29.754371518987341, -1.434878620689655 ], [ 29.754641012658226, -1.434878620689655 ], [ 29.754641012658226, -1.434609137931035 ], [ 29.754371518987341, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12392, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.434609137931035 ], [ 29.754641012658226, -1.434878620689655 ], [ 29.754910506329114, -1.434878620689655 ], [ 29.754910506329114, -1.434609137931035 ], [ 29.754641012658226, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12393, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.434609137931035 ], [ 29.754910506329114, -1.434878620689655 ], [ 29.75518, -1.434878620689655 ], [ 29.75518, -1.434609137931035 ], [ 29.754910506329114, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12394, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.434609137931035 ], [ 29.75518, -1.434878620689655 ], [ 29.755449493670884, -1.434878620689655 ], [ 29.755449493670884, -1.434609137931035 ], [ 29.75518, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12395, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.434609137931035 ], [ 29.755718987341773, -1.434878620689655 ], [ 29.755988481012658, -1.434878620689655 ], [ 29.755988481012658, -1.434609137931035 ], [ 29.755718987341773, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12396, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.434609137931035 ], [ 29.755988481012658, -1.434878620689655 ], [ 29.756257974683542, -1.434878620689655 ], [ 29.756257974683542, -1.434609137931035 ], [ 29.755988481012658, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12397, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.434609137931035 ], [ 29.758144430379748, -1.434878620689655 ], [ 29.758413924050632, -1.434878620689655 ], [ 29.758413924050632, -1.434609137931035 ], [ 29.758144430379748, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12398, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.434609137931035 ], [ 29.758413924050632, -1.434878620689655 ], [ 29.758683417721517, -1.434878620689655 ], [ 29.758683417721517, -1.434609137931035 ], [ 29.758413924050632, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12399, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.434609137931035 ], [ 29.758683417721517, -1.434878620689655 ], [ 29.758952911392406, -1.434878620689655 ], [ 29.758952911392406, -1.434609137931035 ], [ 29.758683417721517, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12400, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.434609137931035 ], [ 29.758952911392406, -1.434878620689655 ], [ 29.759491898734176, -1.434878620689655 ], [ 29.759491898734176, -1.434609137931035 ], [ 29.758952911392406, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12401, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.434609137931035 ], [ 29.759491898734176, -1.434878620689655 ], [ 29.759761392405064, -1.434878620689655 ], [ 29.759761392405064, -1.434609137931035 ], [ 29.759491898734176, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12402, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.434609137931035 ], [ 29.759761392405064, -1.434878620689655 ], [ 29.761378354430381, -1.434878620689655 ], [ 29.761378354430381, -1.434609137931035 ], [ 29.759761392405064, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12403, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.434609137931035 ], [ 29.761378354430381, -1.434878620689655 ], [ 29.761647848101266, -1.434878620689655 ], [ 29.761647848101266, -1.434609137931035 ], [ 29.761378354430381, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12404, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.434609137931035 ], [ 29.761647848101266, -1.434878620689655 ], [ 29.761917341772151, -1.434878620689655 ], [ 29.761917341772151, -1.434609137931035 ], [ 29.761647848101266, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12405, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.434609137931035 ], [ 29.761917341772151, -1.434878620689655 ], [ 29.762186835443039, -1.434878620689655 ], [ 29.762186835443039, -1.434609137931035 ], [ 29.761917341772151, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12406, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.434609137931035 ], [ 29.762186835443039, -1.434878620689655 ], [ 29.762456329113924, -1.434878620689655 ], [ 29.762456329113924, -1.434609137931035 ], [ 29.762186835443039, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12407, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.434609137931035 ], [ 29.762456329113924, -1.434878620689655 ], [ 29.762725822784809, -1.434878620689655 ], [ 29.762725822784809, -1.434609137931035 ], [ 29.762456329113924, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12408, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.434609137931035 ], [ 29.762725822784809, -1.435148103448276 ], [ 29.762995316455697, -1.435148103448276 ], [ 29.762995316455697, -1.434609137931035 ], [ 29.762725822784809, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12409, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.434609137931035 ], [ 29.762995316455697, -1.434878620689655 ], [ 29.763264810126582, -1.434878620689655 ], [ 29.763264810126582, -1.434609137931035 ], [ 29.762995316455697, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12410, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.434609137931035 ], [ 29.763264810126582, -1.434878620689655 ], [ 29.763534303797467, -1.434878620689655 ], [ 29.763534303797467, -1.434609137931035 ], [ 29.763264810126582, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12411, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.434609137931035 ], [ 29.763534303797467, -1.435148103448276 ], [ 29.763803797468356, -1.435148103448276 ], [ 29.763803797468356, -1.434609137931035 ], [ 29.763534303797467, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12412, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.434609137931035 ], [ 29.76407329113924, -1.435148103448276 ], [ 29.764342784810125, -1.435148103448276 ], [ 29.764342784810125, -1.434609137931035 ], [ 29.76407329113924, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12413, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.434609137931035 ], [ 29.764342784810125, -1.435148103448276 ], [ 29.764612278481014, -1.435148103448276 ], [ 29.764612278481014, -1.434609137931035 ], [ 29.764342784810125, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12414, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.434609137931035 ], [ 29.764881772151899, -1.434878620689655 ], [ 29.765151265822784, -1.434878620689655 ], [ 29.765151265822784, -1.434609137931035 ], [ 29.764881772151899, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12415, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.434609137931035 ], [ 29.765151265822784, -1.435148103448276 ], [ 29.765420759493672, -1.435148103448276 ], [ 29.765420759493672, -1.434609137931035 ], [ 29.765151265822784, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12416, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.434609137931035 ], [ 29.765959746835442, -1.434878620689655 ], [ 29.766229240506327, -1.434878620689655 ], [ 29.766229240506327, -1.434609137931035 ], [ 29.765959746835442, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12417, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.434609137931035 ], [ 29.766229240506327, -1.434878620689655 ], [ 29.766498734177215, -1.434878620689655 ], [ 29.766498734177215, -1.434609137931035 ], [ 29.766229240506327, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12418, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.434609137931035 ], [ 29.766498734177215, -1.435148103448276 ], [ 29.7667682278481, -1.435148103448276 ], [ 29.7667682278481, -1.434609137931035 ], [ 29.766498734177215, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12419, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.434609137931035 ], [ 29.7667682278481, -1.434878620689655 ], [ 29.767037721518985, -1.434878620689655 ], [ 29.767037721518985, -1.434609137931035 ], [ 29.7667682278481, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12420, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.434609137931035 ], [ 29.767037721518985, -1.434878620689655 ], [ 29.767307215189874, -1.434878620689655 ], [ 29.767307215189874, -1.434609137931035 ], [ 29.767037721518985, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12421, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.434609137931035 ], [ 29.767307215189874, -1.434878620689655 ], [ 29.767576708860759, -1.434878620689655 ], [ 29.767576708860759, -1.434609137931035 ], [ 29.767307215189874, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12422, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.434609137931035 ], [ 29.767846202531643, -1.434878620689655 ], [ 29.768115696202532, -1.434878620689655 ], [ 29.768115696202532, -1.434609137931035 ], [ 29.767846202531643, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12423, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.434609137931035 ], [ 29.768115696202532, -1.434878620689655 ], [ 29.768385189873417, -1.434878620689655 ], [ 29.768385189873417, -1.434609137931035 ], [ 29.768115696202532, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12424, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.434609137931035 ], [ 29.768385189873417, -1.435148103448276 ], [ 29.768654683544302, -1.435148103448276 ], [ 29.768654683544302, -1.434609137931035 ], [ 29.768385189873417, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12425, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.434609137931035 ], [ 29.768654683544302, -1.434878620689655 ], [ 29.76892417721519, -1.434878620689655 ], [ 29.76892417721519, -1.434609137931035 ], [ 29.768654683544302, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12426, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.434609137931035 ], [ 29.76892417721519, -1.434878620689655 ], [ 29.769193670886075, -1.434878620689655 ], [ 29.769193670886075, -1.434609137931035 ], [ 29.76892417721519, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12427, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.434609137931035 ], [ 29.769193670886075, -1.435148103448276 ], [ 29.76946316455696, -1.435148103448276 ], [ 29.76946316455696, -1.434609137931035 ], [ 29.769193670886075, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12428, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.434609137931035 ], [ 29.769732658227849, -1.434878620689655 ], [ 29.770002151898733, -1.434878620689655 ], [ 29.770002151898733, -1.434609137931035 ], [ 29.769732658227849, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12429, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.434609137931035 ], [ 29.770002151898733, -1.434878620689655 ], [ 29.770271645569618, -1.434878620689655 ], [ 29.770271645569618, -1.434609137931035 ], [ 29.770002151898733, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12430, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.434609137931035 ], [ 29.770541139240507, -1.434878620689655 ], [ 29.770810632911392, -1.434878620689655 ], [ 29.770810632911392, -1.434609137931035 ], [ 29.770541139240507, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12431, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.434609137931035 ], [ 29.770810632911392, -1.434878620689655 ], [ 29.771080126582277, -1.434878620689655 ], [ 29.771080126582277, -1.434609137931035 ], [ 29.770810632911392, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12432, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.434609137931035 ], [ 29.771080126582277, -1.435148103448276 ], [ 29.771349620253165, -1.435148103448276 ], [ 29.771349620253165, -1.434609137931035 ], [ 29.771080126582277, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12433, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.434609137931035 ], [ 29.771349620253165, -1.434878620689655 ], [ 29.77161911392405, -1.434878620689655 ], [ 29.77161911392405, -1.434609137931035 ], [ 29.771349620253165, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12434, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.434609137931035 ], [ 29.77161911392405, -1.434878620689655 ], [ 29.771888607594935, -1.434878620689655 ], [ 29.771888607594935, -1.434609137931035 ], [ 29.77161911392405, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12435, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.434609137931035 ], [ 29.771888607594935, -1.434878620689655 ], [ 29.772158101265823, -1.434878620689655 ], [ 29.772158101265823, -1.434609137931035 ], [ 29.771888607594935, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12436, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.434609137931035 ], [ 29.772427594936708, -1.434878620689655 ], [ 29.772697088607593, -1.434878620689655 ], [ 29.772697088607593, -1.434609137931035 ], [ 29.772427594936708, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12437, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.434609137931035 ], [ 29.772697088607593, -1.434878620689655 ], [ 29.772966582278482, -1.434878620689655 ], [ 29.772966582278482, -1.434609137931035 ], [ 29.772697088607593, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12438, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.434609137931035 ], [ 29.772966582278482, -1.435148103448276 ], [ 29.773236075949367, -1.435148103448276 ], [ 29.773236075949367, -1.434609137931035 ], [ 29.772966582278482, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12439, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.434609137931035 ], [ 29.773236075949367, -1.434878620689655 ], [ 29.773505569620252, -1.434878620689655 ], [ 29.773505569620252, -1.434609137931035 ], [ 29.773236075949367, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12440, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.434609137931035 ], [ 29.773505569620252, -1.434878620689655 ], [ 29.77377506329114, -1.434878620689655 ], [ 29.77377506329114, -1.434609137931035 ], [ 29.773505569620252, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12441, "El": 152 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.434609137931035 ], [ 29.77377506329114, -1.434878620689655 ], [ 29.774044556962025, -1.434878620689655 ], [ 29.774044556962025, -1.434609137931035 ], [ 29.77377506329114, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12442, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.434609137931035 ], [ 29.774044556962025, -1.435148103448276 ], [ 29.77431405063291, -1.435148103448276 ], [ 29.77431405063291, -1.434609137931035 ], [ 29.774044556962025, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12443, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.434609137931035 ], [ 29.77431405063291, -1.434878620689655 ], [ 29.774853037974683, -1.434878620689655 ], [ 29.774853037974683, -1.434609137931035 ], [ 29.77431405063291, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12444, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.434609137931035 ], [ 29.774853037974683, -1.434878620689655 ], [ 29.775122531645568, -1.434878620689655 ], [ 29.775122531645568, -1.434609137931035 ], [ 29.774853037974683, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12445, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.434609137931035 ], [ 29.775392025316457, -1.434878620689655 ], [ 29.775661518987341, -1.434878620689655 ], [ 29.775661518987341, -1.434609137931035 ], [ 29.775392025316457, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12446, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.434609137931035 ], [ 29.775661518987341, -1.434878620689655 ], [ 29.775931012658226, -1.434878620689655 ], [ 29.775931012658226, -1.434609137931035 ], [ 29.775661518987341, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12447, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.434609137931035 ], [ 29.776739493670885, -1.434878620689655 ], [ 29.777008987341773, -1.434878620689655 ], [ 29.777008987341773, -1.434609137931035 ], [ 29.776739493670885, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12448, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.434609137931035 ], [ 29.777817468354431, -1.434878620689655 ], [ 29.778086962025316, -1.434878620689655 ], [ 29.778086962025316, -1.434609137931035 ], [ 29.777817468354431, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12449, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.434609137931035 ], [ 29.778086962025316, -1.434878620689655 ], [ 29.778356455696201, -1.434878620689655 ], [ 29.778356455696201, -1.434609137931035 ], [ 29.778086962025316, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12450, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.434609137931035 ], [ 29.77916493670886, -1.434878620689655 ], [ 29.779434430379748, -1.434878620689655 ], [ 29.779434430379748, -1.434609137931035 ], [ 29.77916493670886, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12451, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.434609137931035 ], [ 29.779434430379748, -1.434878620689655 ], [ 29.779703924050633, -1.434878620689655 ], [ 29.779703924050633, -1.434609137931035 ], [ 29.779434430379748, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12452, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.434609137931035 ], [ 29.779703924050633, -1.434878620689655 ], [ 29.779973417721518, -1.434878620689655 ], [ 29.779973417721518, -1.434609137931035 ], [ 29.779703924050633, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12453, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.434609137931035 ], [ 29.779973417721518, -1.434878620689655 ], [ 29.780242911392406, -1.434878620689655 ], [ 29.780242911392406, -1.434609137931035 ], [ 29.779973417721518, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12454, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.434609137931035 ], [ 29.780242911392406, -1.434878620689655 ], [ 29.780512405063291, -1.434878620689655 ], [ 29.780512405063291, -1.434609137931035 ], [ 29.780242911392406, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12455, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.434609137931035 ], [ 29.780512405063291, -1.434878620689655 ], [ 29.780781898734176, -1.434878620689655 ], [ 29.780781898734176, -1.434609137931035 ], [ 29.780512405063291, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12456, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.434609137931035 ], [ 29.780781898734176, -1.434878620689655 ], [ 29.781051392405065, -1.434878620689655 ], [ 29.781051392405065, -1.434609137931035 ], [ 29.780781898734176, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12457, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.434609137931035 ], [ 29.781051392405065, -1.434878620689655 ], [ 29.78132088607595, -1.434878620689655 ], [ 29.78132088607595, -1.434609137931035 ], [ 29.781051392405065, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12458, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.434609137931035 ], [ 29.78132088607595, -1.434878620689655 ], [ 29.781590379746834, -1.434878620689655 ], [ 29.781590379746834, -1.434609137931035 ], [ 29.78132088607595, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12459, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.434609137931035 ], [ 29.781590379746834, -1.434878620689655 ], [ 29.781859873417723, -1.434878620689655 ], [ 29.781859873417723, -1.434609137931035 ], [ 29.781590379746834, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12460, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.434609137931035 ], [ 29.781859873417723, -1.435148103448276 ], [ 29.782129367088608, -1.435148103448276 ], [ 29.782129367088608, -1.434609137931035 ], [ 29.781859873417723, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12461, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.434609137931035 ], [ 29.782129367088608, -1.435148103448276 ], [ 29.782398860759493, -1.435148103448276 ], [ 29.782398860759493, -1.434609137931035 ], [ 29.782129367088608, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12462, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.434609137931035 ], [ 29.782398860759493, -1.434878620689655 ], [ 29.782668354430381, -1.434878620689655 ], [ 29.782668354430381, -1.434609137931035 ], [ 29.782398860759493, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12463, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.434609137931035 ], [ 29.782668354430381, -1.435148103448276 ], [ 29.782937848101266, -1.435148103448276 ], [ 29.782937848101266, -1.434609137931035 ], [ 29.782668354430381, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12464, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.434609137931035 ], [ 29.782937848101266, -1.435148103448276 ], [ 29.783207341772151, -1.435148103448276 ], [ 29.783207341772151, -1.434609137931035 ], [ 29.782937848101266, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12465, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.434609137931035 ], [ 29.783207341772151, -1.435148103448276 ], [ 29.783476835443039, -1.435148103448276 ], [ 29.783476835443039, -1.434609137931035 ], [ 29.783207341772151, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12466, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.434609137931035 ], [ 29.783476835443039, -1.435148103448276 ], [ 29.784015822784809, -1.435148103448276 ], [ 29.784015822784809, -1.434609137931035 ], [ 29.783476835443039, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12467, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.434609137931035 ], [ 29.784015822784809, -1.435148103448276 ], [ 29.784285316455698, -1.435148103448276 ], [ 29.784285316455698, -1.434609137931035 ], [ 29.784015822784809, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12468, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.434609137931035 ], [ 29.784285316455698, -1.434878620689655 ], [ 29.784554810126583, -1.434878620689655 ], [ 29.784554810126583, -1.434609137931035 ], [ 29.784285316455698, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12469, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.434609137931035 ], [ 29.784554810126583, -1.434878620689655 ], [ 29.784824303797468, -1.434878620689655 ], [ 29.784824303797468, -1.434609137931035 ], [ 29.784554810126583, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12470, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.434609137931035 ], [ 29.784824303797468, -1.434878620689655 ], [ 29.785093797468356, -1.434878620689655 ], [ 29.785093797468356, -1.434609137931035 ], [ 29.784824303797468, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12471, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.434609137931035 ], [ 29.785093797468356, -1.434878620689655 ], [ 29.785363291139241, -1.434878620689655 ], [ 29.785363291139241, -1.434609137931035 ], [ 29.785093797468356, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12472, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.434609137931035 ], [ 29.785363291139241, -1.434878620689655 ], [ 29.785632784810126, -1.434878620689655 ], [ 29.785632784810126, -1.434609137931035 ], [ 29.785363291139241, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12473, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.434609137931035 ], [ 29.785632784810126, -1.434878620689655 ], [ 29.785902278481014, -1.434878620689655 ], [ 29.785902278481014, -1.434609137931035 ], [ 29.785632784810126, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12474, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.434609137931035 ], [ 29.785902278481014, -1.434878620689655 ], [ 29.786171772151899, -1.434878620689655 ], [ 29.786171772151899, -1.434609137931035 ], [ 29.785902278481014, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12475, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.434609137931035 ], [ 29.786171772151899, -1.434878620689655 ], [ 29.786441265822784, -1.434878620689655 ], [ 29.786441265822784, -1.434609137931035 ], [ 29.786171772151899, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12476, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.434609137931035 ], [ 29.786441265822784, -1.434878620689655 ], [ 29.786710759493673, -1.434878620689655 ], [ 29.786710759493673, -1.434609137931035 ], [ 29.786441265822784, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12477, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.434609137931035 ], [ 29.786710759493673, -1.434878620689655 ], [ 29.786980253164558, -1.434878620689655 ], [ 29.786980253164558, -1.434609137931035 ], [ 29.786710759493673, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12478, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.434609137931035 ], [ 29.786980253164558, -1.434878620689655 ], [ 29.788327721518986, -1.434878620689655 ], [ 29.788327721518986, -1.434609137931035 ], [ 29.786980253164558, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12479, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.434609137931035 ], [ 29.788327721518986, -1.434878620689655 ], [ 29.788597215189874, -1.434878620689655 ], [ 29.788597215189874, -1.434609137931035 ], [ 29.788327721518986, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12480, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.434609137931035 ], [ 29.788597215189874, -1.434878620689655 ], [ 29.788866708860759, -1.434878620689655 ], [ 29.788866708860759, -1.434609137931035 ], [ 29.788597215189874, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12481, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.434609137931035 ], [ 29.788866708860759, -1.434878620689655 ], [ 29.789136202531644, -1.434878620689655 ], [ 29.789136202531644, -1.434609137931035 ], [ 29.788866708860759, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12482, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.434609137931035 ], [ 29.789136202531644, -1.434878620689655 ], [ 29.789405696202532, -1.434878620689655 ], [ 29.789405696202532, -1.434609137931035 ], [ 29.789136202531644, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12483, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.434609137931035 ], [ 29.789405696202532, -1.434878620689655 ], [ 29.789675189873417, -1.434878620689655 ], [ 29.789675189873417, -1.434609137931035 ], [ 29.789405696202532, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12484, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.434609137931035 ], [ 29.789675189873417, -1.434878620689655 ], [ 29.789944683544302, -1.434878620689655 ], [ 29.789944683544302, -1.434609137931035 ], [ 29.789675189873417, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12485, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.434609137931035 ], [ 29.789944683544302, -1.434878620689655 ], [ 29.790214177215191, -1.434878620689655 ], [ 29.790214177215191, -1.434609137931035 ], [ 29.789944683544302, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12486, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.434609137931035 ], [ 29.790214177215191, -1.434878620689655 ], [ 29.790483670886076, -1.434878620689655 ], [ 29.790483670886076, -1.434609137931035 ], [ 29.790214177215191, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12487, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.434609137931035 ], [ 29.790483670886076, -1.434878620689655 ], [ 29.790753164556961, -1.434878620689655 ], [ 29.790753164556961, -1.434609137931035 ], [ 29.790483670886076, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12488, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.434609137931035 ], [ 29.791022658227849, -1.434878620689655 ], [ 29.791292151898734, -1.434878620689655 ], [ 29.791292151898734, -1.434609137931035 ], [ 29.791022658227849, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12489, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.434609137931035 ], [ 29.791292151898734, -1.434878620689655 ], [ 29.791561645569619, -1.434878620689655 ], [ 29.791561645569619, -1.434609137931035 ], [ 29.791292151898734, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12490, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.434609137931035 ], [ 29.791561645569619, -1.434878620689655 ], [ 29.791831139240507, -1.434878620689655 ], [ 29.791831139240507, -1.434609137931035 ], [ 29.791561645569619, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12491, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.434609137931035 ], [ 29.791831139240507, -1.434878620689655 ], [ 29.792100632911392, -1.434878620689655 ], [ 29.792100632911392, -1.434609137931035 ], [ 29.791831139240507, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12492, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.434609137931035 ], [ 29.792100632911392, -1.434878620689655 ], [ 29.792370126582277, -1.434878620689655 ], [ 29.792370126582277, -1.434609137931035 ], [ 29.792100632911392, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12493, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.434609137931035 ], [ 29.792370126582277, -1.434878620689655 ], [ 29.792909113924051, -1.434878620689655 ], [ 29.792909113924051, -1.434609137931035 ], [ 29.792370126582277, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12494, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.434609137931035 ], [ 29.792909113924051, -1.434878620689655 ], [ 29.793178607594935, -1.434878620689655 ], [ 29.793178607594935, -1.434609137931035 ], [ 29.792909113924051, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12495, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.434609137931035 ], [ 29.793178607594935, -1.434878620689655 ], [ 29.793448101265824, -1.434878620689655 ], [ 29.793448101265824, -1.434609137931035 ], [ 29.793178607594935, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12496, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.434609137931035 ], [ 29.793448101265824, -1.434878620689655 ], [ 29.793717594936709, -1.434878620689655 ], [ 29.793717594936709, -1.434609137931035 ], [ 29.793448101265824, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12497, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.434609137931035 ], [ 29.793717594936709, -1.434878620689655 ], [ 29.793987088607594, -1.434878620689655 ], [ 29.793987088607594, -1.434609137931035 ], [ 29.793717594936709, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12498, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.434609137931035 ], [ 29.793987088607594, -1.434878620689655 ], [ 29.794256582278482, -1.434878620689655 ], [ 29.794256582278482, -1.434609137931035 ], [ 29.793987088607594, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12499, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.434609137931035 ], [ 29.794256582278482, -1.434878620689655 ], [ 29.794526075949367, -1.434878620689655 ], [ 29.794526075949367, -1.434609137931035 ], [ 29.794256582278482, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12500, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.434609137931035 ], [ 29.794526075949367, -1.434878620689655 ], [ 29.794795569620252, -1.434878620689655 ], [ 29.794795569620252, -1.434609137931035 ], [ 29.794526075949367, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12501, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.434609137931035 ], [ 29.794795569620252, -1.434878620689655 ], [ 29.79506506329114, -1.434878620689655 ], [ 29.79506506329114, -1.434609137931035 ], [ 29.794795569620252, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12502, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.434609137931035 ], [ 29.79506506329114, -1.434878620689655 ], [ 29.795334556962025, -1.434878620689655 ], [ 29.795334556962025, -1.434609137931035 ], [ 29.79506506329114, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12503, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.434609137931035 ], [ 29.795334556962025, -1.434878620689655 ], [ 29.79560405063291, -1.434878620689655 ], [ 29.79560405063291, -1.434609137931035 ], [ 29.795334556962025, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12504, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.434609137931035 ], [ 29.79560405063291, -1.434878620689655 ], [ 29.795873544303799, -1.434878620689655 ], [ 29.795873544303799, -1.434609137931035 ], [ 29.79560405063291, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12505, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.434609137931035 ], [ 29.795873544303799, -1.434878620689655 ], [ 29.796143037974684, -1.434878620689655 ], [ 29.796143037974684, -1.434609137931035 ], [ 29.795873544303799, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12506, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.434609137931035 ], [ 29.796143037974684, -1.434878620689655 ], [ 29.796412531645569, -1.434878620689655 ], [ 29.796412531645569, -1.434609137931035 ], [ 29.796143037974684, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12507, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.434609137931035 ], [ 29.796412531645569, -1.434878620689655 ], [ 29.796682025316457, -1.434878620689655 ], [ 29.796682025316457, -1.434609137931035 ], [ 29.796412531645569, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12508, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.434609137931035 ], [ 29.796682025316457, -1.434878620689655 ], [ 29.79776, -1.434878620689655 ], [ 29.79776, -1.434609137931035 ], [ 29.796682025316457, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12509, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.434609137931035 ], [ 29.79776, -1.434878620689655 ], [ 29.798029493670885, -1.434878620689655 ], [ 29.798029493670885, -1.434609137931035 ], [ 29.79776, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12510, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.434609137931035 ], [ 29.798029493670885, -1.434878620689655 ], [ 29.798298987341774, -1.434878620689655 ], [ 29.798298987341774, -1.434609137931035 ], [ 29.798029493670885, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12511, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.434609137931035 ], [ 29.798298987341774, -1.434878620689655 ], [ 29.798568481012659, -1.434878620689655 ], [ 29.798568481012659, -1.434609137931035 ], [ 29.798298987341774, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12512, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.434609137931035 ], [ 29.798568481012659, -1.434878620689655 ], [ 29.798837974683543, -1.434878620689655 ], [ 29.798837974683543, -1.434609137931035 ], [ 29.798568481012659, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12513, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.434609137931035 ], [ 29.798837974683543, -1.434878620689655 ], [ 29.799107468354432, -1.434878620689655 ], [ 29.799107468354432, -1.434609137931035 ], [ 29.798837974683543, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12514, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.434609137931035 ], [ 29.799107468354432, -1.434878620689655 ], [ 29.799376962025317, -1.434878620689655 ], [ 29.799376962025317, -1.434609137931035 ], [ 29.799107468354432, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12515, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.434609137931035 ], [ 29.799376962025317, -1.434878620689655 ], [ 29.799646455696202, -1.434878620689655 ], [ 29.799646455696202, -1.434609137931035 ], [ 29.799376962025317, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12516, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.434609137931035 ], [ 29.799646455696202, -1.434878620689655 ], [ 29.79991594936709, -1.434878620689655 ], [ 29.79991594936709, -1.434609137931035 ], [ 29.799646455696202, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12517, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.434609137931035 ], [ 29.79991594936709, -1.434878620689655 ], [ 29.800185443037975, -1.434878620689655 ], [ 29.800185443037975, -1.434609137931035 ], [ 29.79991594936709, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12518, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.434609137931035 ], [ 29.800185443037975, -1.434878620689655 ], [ 29.80045493670886, -1.434878620689655 ], [ 29.80045493670886, -1.434609137931035 ], [ 29.800185443037975, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12519, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.434609137931035 ], [ 29.80045493670886, -1.435148103448276 ], [ 29.800724430379748, -1.435148103448276 ], [ 29.800724430379748, -1.434609137931035 ], [ 29.80045493670886, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12520, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.434609137931035 ], [ 29.802071898734177, -1.435148103448276 ], [ 29.802341392405065, -1.435148103448276 ], [ 29.802341392405065, -1.434609137931035 ], [ 29.802071898734177, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12521, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.434609137931035 ], [ 29.802880379746835, -1.434878620689655 ], [ 29.803149873417723, -1.434878620689655 ], [ 29.803149873417723, -1.434609137931035 ], [ 29.802880379746835, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12522, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.434609137931035 ], [ 29.803149873417723, -1.434878620689655 ], [ 29.803419367088608, -1.434878620689655 ], [ 29.803419367088608, -1.434609137931035 ], [ 29.803149873417723, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12523, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.434609137931035 ], [ 29.803419367088608, -1.434878620689655 ], [ 29.803688860759493, -1.434878620689655 ], [ 29.803688860759493, -1.434609137931035 ], [ 29.803419367088608, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12524, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.434878620689655 ], [ 29.745747721518985, -1.435148103448276 ], [ 29.746017215189873, -1.435148103448276 ], [ 29.746017215189873, -1.434878620689655 ], [ 29.745747721518985, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12525, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.434878620689655 ], [ 29.746017215189873, -1.435148103448276 ], [ 29.746286708860758, -1.435148103448276 ], [ 29.746286708860758, -1.434878620689655 ], [ 29.746017215189873, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12526, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.434878620689655 ], [ 29.746286708860758, -1.435148103448276 ], [ 29.746556202531643, -1.435148103448276 ], [ 29.746556202531643, -1.434878620689655 ], [ 29.746286708860758, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12527, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.434878620689655 ], [ 29.746556202531643, -1.435148103448276 ], [ 29.746825696202531, -1.435148103448276 ], [ 29.746825696202531, -1.434878620689655 ], [ 29.746556202531643, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12528, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.434878620689655 ], [ 29.746825696202531, -1.435148103448276 ], [ 29.747364683544301, -1.435148103448276 ], [ 29.747364683544301, -1.434878620689655 ], [ 29.746825696202531, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12529, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.434878620689655 ], [ 29.747364683544301, -1.435148103448276 ], [ 29.747903670886075, -1.435148103448276 ], [ 29.747903670886075, -1.434878620689655 ], [ 29.747364683544301, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12530, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.434878620689655 ], [ 29.747903670886075, -1.435148103448276 ], [ 29.748442658227848, -1.435148103448276 ], [ 29.748442658227848, -1.434878620689655 ], [ 29.747903670886075, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12531, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.434878620689655 ], [ 29.748442658227848, -1.435148103448276 ], [ 29.748712151898733, -1.435148103448276 ], [ 29.748712151898733, -1.434878620689655 ], [ 29.748442658227848, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12532, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.434878620689655 ], [ 29.748712151898733, -1.435148103448276 ], [ 29.748981645569618, -1.435148103448276 ], [ 29.748981645569618, -1.434878620689655 ], [ 29.748712151898733, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12533, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.434878620689655 ], [ 29.748981645569618, -1.435148103448276 ], [ 29.749251139240506, -1.435148103448276 ], [ 29.749251139240506, -1.434878620689655 ], [ 29.748981645569618, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12534, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.434878620689655 ], [ 29.749251139240506, -1.435148103448276 ], [ 29.749520632911391, -1.435148103448276 ], [ 29.749520632911391, -1.434878620689655 ], [ 29.749251139240506, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12535, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.434878620689655 ], [ 29.749520632911391, -1.435148103448276 ], [ 29.749790126582276, -1.435148103448276 ], [ 29.749790126582276, -1.434878620689655 ], [ 29.749520632911391, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12536, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.434878620689655 ], [ 29.749790126582276, -1.435148103448276 ], [ 29.750059620253165, -1.435148103448276 ], [ 29.750059620253165, -1.434878620689655 ], [ 29.749790126582276, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12537, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.434878620689655 ], [ 29.750059620253165, -1.435148103448276 ], [ 29.75032911392405, -1.435148103448276 ], [ 29.75032911392405, -1.434878620689655 ], [ 29.750059620253165, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12538, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.434878620689655 ], [ 29.75032911392405, -1.435148103448276 ], [ 29.750598607594934, -1.435148103448276 ], [ 29.750598607594934, -1.434878620689655 ], [ 29.75032911392405, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12539, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.434878620689655 ], [ 29.750598607594934, -1.435148103448276 ], [ 29.750868101265823, -1.435148103448276 ], [ 29.750868101265823, -1.434878620689655 ], [ 29.750598607594934, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12540, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.434878620689655 ], [ 29.750868101265823, -1.435148103448276 ], [ 29.751137594936708, -1.435148103448276 ], [ 29.751137594936708, -1.434878620689655 ], [ 29.750868101265823, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12541, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.434878620689655 ], [ 29.751137594936708, -1.435148103448276 ], [ 29.754102025316456, -1.435148103448276 ], [ 29.754102025316456, -1.434878620689655 ], [ 29.751137594936708, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12542, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.434878620689655 ], [ 29.754102025316456, -1.435148103448276 ], [ 29.754371518987341, -1.435148103448276 ], [ 29.754371518987341, -1.434878620689655 ], [ 29.754102025316456, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12543, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.434878620689655 ], [ 29.754371518987341, -1.435148103448276 ], [ 29.754641012658226, -1.435148103448276 ], [ 29.754641012658226, -1.434878620689655 ], [ 29.754371518987341, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12544, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.434878620689655 ], [ 29.754641012658226, -1.435148103448276 ], [ 29.754910506329114, -1.435148103448276 ], [ 29.754910506329114, -1.434878620689655 ], [ 29.754641012658226, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12545, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.434878620689655 ], [ 29.754910506329114, -1.435148103448276 ], [ 29.75518, -1.435148103448276 ], [ 29.75518, -1.434878620689655 ], [ 29.754910506329114, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12546, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.434878620689655 ], [ 29.75518, -1.435148103448276 ], [ 29.755449493670884, -1.435148103448276 ], [ 29.755449493670884, -1.434878620689655 ], [ 29.75518, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12547, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.434878620689655 ], [ 29.755449493670884, -1.435148103448276 ], [ 29.755718987341773, -1.435148103448276 ], [ 29.755718987341773, -1.434878620689655 ], [ 29.755449493670884, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12548, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.434878620689655 ], [ 29.755718987341773, -1.435148103448276 ], [ 29.755988481012658, -1.435148103448276 ], [ 29.755988481012658, -1.434878620689655 ], [ 29.755718987341773, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12549, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.434878620689655 ], [ 29.755988481012658, -1.435148103448276 ], [ 29.756257974683542, -1.435148103448276 ], [ 29.756257974683542, -1.434878620689655 ], [ 29.755988481012658, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12550, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.434878620689655 ], [ 29.759491898734176, -1.435148103448276 ], [ 29.761378354430381, -1.435148103448276 ], [ 29.761378354430381, -1.434878620689655 ], [ 29.759491898734176, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12551, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.434878620689655 ], [ 29.761378354430381, -1.435148103448276 ], [ 29.761647848101266, -1.435148103448276 ], [ 29.761647848101266, -1.434878620689655 ], [ 29.761378354430381, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12552, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.434878620689655 ], [ 29.761647848101266, -1.435148103448276 ], [ 29.761917341772151, -1.435148103448276 ], [ 29.761917341772151, -1.434878620689655 ], [ 29.761647848101266, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12553, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.434878620689655 ], [ 29.761917341772151, -1.435148103448276 ], [ 29.762186835443039, -1.435148103448276 ], [ 29.762186835443039, -1.434878620689655 ], [ 29.761917341772151, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12554, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.434878620689655 ], [ 29.763803797468356, -1.435148103448276 ], [ 29.76407329113924, -1.435148103448276 ], [ 29.76407329113924, -1.434878620689655 ], [ 29.763803797468356, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12555, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.434878620689655 ], [ 29.764612278481014, -1.435148103448276 ], [ 29.764881772151899, -1.435148103448276 ], [ 29.764881772151899, -1.434878620689655 ], [ 29.764612278481014, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12556, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.434878620689655 ], [ 29.764881772151899, -1.435148103448276 ], [ 29.765151265822784, -1.435148103448276 ], [ 29.765151265822784, -1.434878620689655 ], [ 29.764881772151899, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12557, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.434878620689655 ], [ 29.765420759493672, -1.435148103448276 ], [ 29.765959746835442, -1.435148103448276 ], [ 29.765959746835442, -1.434878620689655 ], [ 29.765420759493672, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12558, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.434878620689655 ], [ 29.765959746835442, -1.435148103448276 ], [ 29.766229240506327, -1.435148103448276 ], [ 29.766229240506327, -1.434878620689655 ], [ 29.765959746835442, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12559, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.434878620689655 ], [ 29.766229240506327, -1.435148103448276 ], [ 29.766498734177215, -1.435148103448276 ], [ 29.766498734177215, -1.434878620689655 ], [ 29.766229240506327, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12560, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.434878620689655 ], [ 29.7667682278481, -1.435148103448276 ], [ 29.767037721518985, -1.435148103448276 ], [ 29.767037721518985, -1.434878620689655 ], [ 29.7667682278481, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12561, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.434878620689655 ], [ 29.767037721518985, -1.435148103448276 ], [ 29.767307215189874, -1.435148103448276 ], [ 29.767307215189874, -1.434878620689655 ], [ 29.767037721518985, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12562, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.434878620689655 ], [ 29.767576708860759, -1.435148103448276 ], [ 29.767846202531643, -1.435148103448276 ], [ 29.767846202531643, -1.434878620689655 ], [ 29.767576708860759, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12563, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.434878620689655 ], [ 29.767846202531643, -1.435148103448276 ], [ 29.768115696202532, -1.435148103448276 ], [ 29.768115696202532, -1.434878620689655 ], [ 29.767846202531643, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12564, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.434878620689655 ], [ 29.768654683544302, -1.435148103448276 ], [ 29.76892417721519, -1.435148103448276 ], [ 29.76892417721519, -1.434878620689655 ], [ 29.768654683544302, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12565, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.434878620689655 ], [ 29.76892417721519, -1.435148103448276 ], [ 29.769193670886075, -1.435148103448276 ], [ 29.769193670886075, -1.434878620689655 ], [ 29.76892417721519, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12566, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.434878620689655 ], [ 29.76946316455696, -1.435148103448276 ], [ 29.769732658227849, -1.435148103448276 ], [ 29.769732658227849, -1.434878620689655 ], [ 29.76946316455696, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12567, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.434878620689655 ], [ 29.769732658227849, -1.435148103448276 ], [ 29.770002151898733, -1.435148103448276 ], [ 29.770002151898733, -1.434878620689655 ], [ 29.769732658227849, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12568, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.434878620689655 ], [ 29.770271645569618, -1.435148103448276 ], [ 29.770541139240507, -1.435148103448276 ], [ 29.770541139240507, -1.434878620689655 ], [ 29.770271645569618, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12569, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.434878620689655 ], [ 29.770541139240507, -1.435148103448276 ], [ 29.770810632911392, -1.435148103448276 ], [ 29.770810632911392, -1.434878620689655 ], [ 29.770541139240507, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12570, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.434878620689655 ], [ 29.770810632911392, -1.435148103448276 ], [ 29.771080126582277, -1.435148103448276 ], [ 29.771080126582277, -1.434878620689655 ], [ 29.770810632911392, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12571, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.434878620689655 ], [ 29.771349620253165, -1.435148103448276 ], [ 29.77161911392405, -1.435148103448276 ], [ 29.77161911392405, -1.434878620689655 ], [ 29.771349620253165, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12572, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.434878620689655 ], [ 29.77161911392405, -1.435148103448276 ], [ 29.771888607594935, -1.435148103448276 ], [ 29.771888607594935, -1.434878620689655 ], [ 29.77161911392405, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12573, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.434878620689655 ], [ 29.772158101265823, -1.435148103448276 ], [ 29.772427594936708, -1.435148103448276 ], [ 29.772427594936708, -1.434878620689655 ], [ 29.772158101265823, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12574, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.434878620689655 ], [ 29.772427594936708, -1.435148103448276 ], [ 29.772697088607593, -1.435148103448276 ], [ 29.772697088607593, -1.434878620689655 ], [ 29.772427594936708, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12575, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.434878620689655 ], [ 29.773236075949367, -1.435148103448276 ], [ 29.773505569620252, -1.435148103448276 ], [ 29.773505569620252, -1.434878620689655 ], [ 29.773236075949367, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12576, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.434878620689655 ], [ 29.773505569620252, -1.435148103448276 ], [ 29.77377506329114, -1.435148103448276 ], [ 29.77377506329114, -1.434878620689655 ], [ 29.773505569620252, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12577, "El": 151 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.434878620689655 ], [ 29.77377506329114, -1.435148103448276 ], [ 29.774044556962025, -1.435148103448276 ], [ 29.774044556962025, -1.434878620689655 ], [ 29.77377506329114, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12578, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.434878620689655 ], [ 29.77431405063291, -1.435148103448276 ], [ 29.774853037974683, -1.435148103448276 ], [ 29.774853037974683, -1.434878620689655 ], [ 29.77431405063291, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12579, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.434878620689655 ], [ 29.774853037974683, -1.435148103448276 ], [ 29.775122531645568, -1.435148103448276 ], [ 29.775122531645568, -1.434878620689655 ], [ 29.774853037974683, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12580, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.434878620689655 ], [ 29.775122531645568, -1.435148103448276 ], [ 29.775392025316457, -1.435148103448276 ], [ 29.775392025316457, -1.434878620689655 ], [ 29.775122531645568, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12581, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.434878620689655 ], [ 29.775661518987341, -1.435148103448276 ], [ 29.775931012658226, -1.435148103448276 ], [ 29.775931012658226, -1.434878620689655 ], [ 29.775661518987341, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12582, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.434878620689655 ], [ 29.775931012658226, -1.435148103448276 ], [ 29.776200506329115, -1.435148103448276 ], [ 29.776200506329115, -1.434878620689655 ], [ 29.775931012658226, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12583, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.434878620689655 ], [ 29.776200506329115, -1.435148103448276 ], [ 29.77647, -1.435148103448276 ], [ 29.77647, -1.434878620689655 ], [ 29.776200506329115, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12584, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.434878620689655 ], [ 29.77647, -1.435148103448276 ], [ 29.776739493670885, -1.435148103448276 ], [ 29.776739493670885, -1.434878620689655 ], [ 29.77647, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12585, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.434878620689655 ], [ 29.776739493670885, -1.435148103448276 ], [ 29.777008987341773, -1.435148103448276 ], [ 29.777008987341773, -1.434878620689655 ], [ 29.776739493670885, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12586, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.434878620689655 ], [ 29.777008987341773, -1.435148103448276 ], [ 29.777278481012658, -1.435148103448276 ], [ 29.777278481012658, -1.434878620689655 ], [ 29.777008987341773, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12587, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.434878620689655 ], [ 29.777278481012658, -1.435148103448276 ], [ 29.777547974683543, -1.435148103448276 ], [ 29.777547974683543, -1.434878620689655 ], [ 29.777278481012658, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12588, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.434878620689655 ], [ 29.777547974683543, -1.435148103448276 ], [ 29.777817468354431, -1.435148103448276 ], [ 29.777817468354431, -1.434878620689655 ], [ 29.777547974683543, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12589, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.434878620689655 ], [ 29.777817468354431, -1.435148103448276 ], [ 29.778086962025316, -1.435148103448276 ], [ 29.778086962025316, -1.434878620689655 ], [ 29.777817468354431, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12590, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.434878620689655 ], [ 29.778086962025316, -1.435148103448276 ], [ 29.778356455696201, -1.435148103448276 ], [ 29.778356455696201, -1.434878620689655 ], [ 29.778086962025316, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12591, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.434878620689655 ], [ 29.778356455696201, -1.435148103448276 ], [ 29.77862594936709, -1.435148103448276 ], [ 29.77862594936709, -1.434878620689655 ], [ 29.778356455696201, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12592, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.434878620689655 ], [ 29.77862594936709, -1.435148103448276 ], [ 29.778895443037975, -1.435148103448276 ], [ 29.778895443037975, -1.434878620689655 ], [ 29.77862594936709, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12593, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.434878620689655 ], [ 29.778895443037975, -1.435148103448276 ], [ 29.77916493670886, -1.435148103448276 ], [ 29.77916493670886, -1.434878620689655 ], [ 29.778895443037975, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12594, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.434878620689655 ], [ 29.77916493670886, -1.435148103448276 ], [ 29.779434430379748, -1.435148103448276 ], [ 29.779434430379748, -1.434878620689655 ], [ 29.77916493670886, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12595, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.434878620689655 ], [ 29.779434430379748, -1.435148103448276 ], [ 29.779703924050633, -1.435148103448276 ], [ 29.779703924050633, -1.434878620689655 ], [ 29.779434430379748, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12596, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.434878620689655 ], [ 29.779703924050633, -1.435148103448276 ], [ 29.779973417721518, -1.435148103448276 ], [ 29.779973417721518, -1.434878620689655 ], [ 29.779703924050633, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12597, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.434878620689655 ], [ 29.779973417721518, -1.435148103448276 ], [ 29.780242911392406, -1.435148103448276 ], [ 29.780242911392406, -1.434878620689655 ], [ 29.779973417721518, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12598, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.434878620689655 ], [ 29.780242911392406, -1.435148103448276 ], [ 29.780512405063291, -1.435148103448276 ], [ 29.780512405063291, -1.434878620689655 ], [ 29.780242911392406, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12599, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.434878620689655 ], [ 29.780512405063291, -1.435148103448276 ], [ 29.780781898734176, -1.435148103448276 ], [ 29.780781898734176, -1.434878620689655 ], [ 29.780512405063291, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12600, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.434878620689655 ], [ 29.780781898734176, -1.435148103448276 ], [ 29.781051392405065, -1.435148103448276 ], [ 29.781051392405065, -1.434878620689655 ], [ 29.780781898734176, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12601, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.434878620689655 ], [ 29.781051392405065, -1.435148103448276 ], [ 29.78132088607595, -1.435148103448276 ], [ 29.78132088607595, -1.434878620689655 ], [ 29.781051392405065, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12602, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.434878620689655 ], [ 29.78132088607595, -1.435148103448276 ], [ 29.781590379746834, -1.435148103448276 ], [ 29.781590379746834, -1.434878620689655 ], [ 29.78132088607595, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12603, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.434878620689655 ], [ 29.781590379746834, -1.435148103448276 ], [ 29.781859873417723, -1.435148103448276 ], [ 29.781859873417723, -1.434878620689655 ], [ 29.781590379746834, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12604, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.434878620689655 ], [ 29.782398860759493, -1.435148103448276 ], [ 29.782668354430381, -1.435148103448276 ], [ 29.782668354430381, -1.434878620689655 ], [ 29.782398860759493, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12605, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.434878620689655 ], [ 29.784285316455698, -1.435148103448276 ], [ 29.784554810126583, -1.435148103448276 ], [ 29.784554810126583, -1.434878620689655 ], [ 29.784285316455698, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12606, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.434878620689655 ], [ 29.784554810126583, -1.435148103448276 ], [ 29.784824303797468, -1.435148103448276 ], [ 29.784824303797468, -1.434878620689655 ], [ 29.784554810126583, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12607, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.434878620689655 ], [ 29.784824303797468, -1.435148103448276 ], [ 29.785093797468356, -1.435148103448276 ], [ 29.785093797468356, -1.434878620689655 ], [ 29.784824303797468, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12608, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.434878620689655 ], [ 29.785093797468356, -1.435148103448276 ], [ 29.785363291139241, -1.435148103448276 ], [ 29.785363291139241, -1.434878620689655 ], [ 29.785093797468356, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12609, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.434878620689655 ], [ 29.785363291139241, -1.435148103448276 ], [ 29.785632784810126, -1.435148103448276 ], [ 29.785632784810126, -1.434878620689655 ], [ 29.785363291139241, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12610, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.434878620689655 ], [ 29.785632784810126, -1.435148103448276 ], [ 29.785902278481014, -1.435148103448276 ], [ 29.785902278481014, -1.434878620689655 ], [ 29.785632784810126, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12611, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.434878620689655 ], [ 29.785902278481014, -1.435148103448276 ], [ 29.786171772151899, -1.435148103448276 ], [ 29.786171772151899, -1.434878620689655 ], [ 29.785902278481014, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12612, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.434878620689655 ], [ 29.786171772151899, -1.435148103448276 ], [ 29.786441265822784, -1.435148103448276 ], [ 29.786441265822784, -1.434878620689655 ], [ 29.786171772151899, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12613, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.434878620689655 ], [ 29.786441265822784, -1.435148103448276 ], [ 29.786710759493673, -1.435148103448276 ], [ 29.786710759493673, -1.434878620689655 ], [ 29.786441265822784, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12614, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.434878620689655 ], [ 29.788597215189874, -1.435148103448276 ], [ 29.788866708860759, -1.435148103448276 ], [ 29.788866708860759, -1.434878620689655 ], [ 29.788597215189874, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12615, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.434878620689655 ], [ 29.788866708860759, -1.435148103448276 ], [ 29.789136202531644, -1.435148103448276 ], [ 29.789136202531644, -1.434878620689655 ], [ 29.788866708860759, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12616, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.434878620689655 ], [ 29.789136202531644, -1.435148103448276 ], [ 29.789405696202532, -1.435148103448276 ], [ 29.789405696202532, -1.434878620689655 ], [ 29.789136202531644, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12617, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.434878620689655 ], [ 29.789405696202532, -1.435148103448276 ], [ 29.789944683544302, -1.435148103448276 ], [ 29.789944683544302, -1.434878620689655 ], [ 29.789405696202532, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12618, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.434878620689655 ], [ 29.789944683544302, -1.435148103448276 ], [ 29.790214177215191, -1.435148103448276 ], [ 29.790214177215191, -1.434878620689655 ], [ 29.789944683544302, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12619, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.434878620689655 ], [ 29.790214177215191, -1.435148103448276 ], [ 29.790483670886076, -1.435148103448276 ], [ 29.790483670886076, -1.434878620689655 ], [ 29.790214177215191, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12620, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.434878620689655 ], [ 29.790483670886076, -1.435148103448276 ], [ 29.790753164556961, -1.435148103448276 ], [ 29.790753164556961, -1.434878620689655 ], [ 29.790483670886076, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12621, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.434878620689655 ], [ 29.790753164556961, -1.435148103448276 ], [ 29.791022658227849, -1.435148103448276 ], [ 29.791022658227849, -1.434878620689655 ], [ 29.790753164556961, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12622, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.434878620689655 ], [ 29.791022658227849, -1.435148103448276 ], [ 29.791292151898734, -1.435148103448276 ], [ 29.791292151898734, -1.434878620689655 ], [ 29.791022658227849, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12623, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.434878620689655 ], [ 29.791292151898734, -1.435148103448276 ], [ 29.791561645569619, -1.435148103448276 ], [ 29.791561645569619, -1.434878620689655 ], [ 29.791292151898734, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12624, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.434878620689655 ], [ 29.791561645569619, -1.435148103448276 ], [ 29.791831139240507, -1.435148103448276 ], [ 29.791831139240507, -1.434878620689655 ], [ 29.791561645569619, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12625, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.434878620689655 ], [ 29.791831139240507, -1.435148103448276 ], [ 29.792100632911392, -1.435148103448276 ], [ 29.792100632911392, -1.434878620689655 ], [ 29.791831139240507, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12626, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.434878620689655 ], [ 29.792100632911392, -1.435148103448276 ], [ 29.792370126582277, -1.435148103448276 ], [ 29.792370126582277, -1.434878620689655 ], [ 29.792100632911392, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12627, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.434878620689655 ], [ 29.792370126582277, -1.435148103448276 ], [ 29.792909113924051, -1.435148103448276 ], [ 29.792909113924051, -1.434878620689655 ], [ 29.792370126582277, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12628, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.434878620689655 ], [ 29.792909113924051, -1.435148103448276 ], [ 29.793178607594935, -1.435148103448276 ], [ 29.793178607594935, -1.434878620689655 ], [ 29.792909113924051, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12629, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.434878620689655 ], [ 29.793178607594935, -1.435148103448276 ], [ 29.793448101265824, -1.435148103448276 ], [ 29.793448101265824, -1.434878620689655 ], [ 29.793178607594935, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12630, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.434878620689655 ], [ 29.793448101265824, -1.435148103448276 ], [ 29.793717594936709, -1.435148103448276 ], [ 29.793717594936709, -1.434878620689655 ], [ 29.793448101265824, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12631, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.434878620689655 ], [ 29.793717594936709, -1.435148103448276 ], [ 29.793987088607594, -1.435148103448276 ], [ 29.793987088607594, -1.434878620689655 ], [ 29.793717594936709, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12632, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.434878620689655 ], [ 29.793987088607594, -1.435148103448276 ], [ 29.794256582278482, -1.435148103448276 ], [ 29.794256582278482, -1.434878620689655 ], [ 29.793987088607594, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12633, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.434878620689655 ], [ 29.794256582278482, -1.435148103448276 ], [ 29.794526075949367, -1.435148103448276 ], [ 29.794526075949367, -1.434878620689655 ], [ 29.794256582278482, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12634, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.434878620689655 ], [ 29.794526075949367, -1.435148103448276 ], [ 29.794795569620252, -1.435148103448276 ], [ 29.794795569620252, -1.434878620689655 ], [ 29.794526075949367, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12635, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.434878620689655 ], [ 29.794795569620252, -1.435148103448276 ], [ 29.79506506329114, -1.435148103448276 ], [ 29.79506506329114, -1.434878620689655 ], [ 29.794795569620252, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12636, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.434878620689655 ], [ 29.79506506329114, -1.435148103448276 ], [ 29.795334556962025, -1.435148103448276 ], [ 29.795334556962025, -1.434878620689655 ], [ 29.79506506329114, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12637, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.434878620689655 ], [ 29.795334556962025, -1.435148103448276 ], [ 29.79560405063291, -1.435148103448276 ], [ 29.79560405063291, -1.434878620689655 ], [ 29.795334556962025, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12638, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.434878620689655 ], [ 29.79560405063291, -1.435148103448276 ], [ 29.795873544303799, -1.435148103448276 ], [ 29.795873544303799, -1.434878620689655 ], [ 29.79560405063291, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12639, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.434878620689655 ], [ 29.795873544303799, -1.435148103448276 ], [ 29.796143037974684, -1.435148103448276 ], [ 29.796143037974684, -1.434878620689655 ], [ 29.795873544303799, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12640, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.434878620689655 ], [ 29.796143037974684, -1.435148103448276 ], [ 29.796412531645569, -1.435148103448276 ], [ 29.796412531645569, -1.434878620689655 ], [ 29.796143037974684, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12641, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.434878620689655 ], [ 29.796412531645569, -1.435148103448276 ], [ 29.796682025316457, -1.435148103448276 ], [ 29.796682025316457, -1.434878620689655 ], [ 29.796412531645569, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12642, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.434878620689655 ], [ 29.796682025316457, -1.435148103448276 ], [ 29.79776, -1.435148103448276 ], [ 29.79776, -1.434878620689655 ], [ 29.796682025316457, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12643, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.434878620689655 ], [ 29.79776, -1.435148103448276 ], [ 29.798029493670885, -1.435148103448276 ], [ 29.798029493670885, -1.434878620689655 ], [ 29.79776, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12644, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.434878620689655 ], [ 29.798029493670885, -1.435148103448276 ], [ 29.798298987341774, -1.435148103448276 ], [ 29.798298987341774, -1.434878620689655 ], [ 29.798029493670885, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12645, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.434878620689655 ], [ 29.798298987341774, -1.435148103448276 ], [ 29.798568481012659, -1.435148103448276 ], [ 29.798568481012659, -1.434878620689655 ], [ 29.798298987341774, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12646, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.434878620689655 ], [ 29.798568481012659, -1.435148103448276 ], [ 29.798837974683543, -1.435148103448276 ], [ 29.798837974683543, -1.434878620689655 ], [ 29.798568481012659, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12647, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.434878620689655 ], [ 29.798837974683543, -1.435148103448276 ], [ 29.799107468354432, -1.435148103448276 ], [ 29.799107468354432, -1.434878620689655 ], [ 29.798837974683543, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12648, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.434878620689655 ], [ 29.799107468354432, -1.435148103448276 ], [ 29.799376962025317, -1.435148103448276 ], [ 29.799376962025317, -1.434878620689655 ], [ 29.799107468354432, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12649, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.434878620689655 ], [ 29.799376962025317, -1.435148103448276 ], [ 29.799646455696202, -1.435148103448276 ], [ 29.799646455696202, -1.434878620689655 ], [ 29.799376962025317, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12650, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.434878620689655 ], [ 29.799646455696202, -1.435148103448276 ], [ 29.79991594936709, -1.435148103448276 ], [ 29.79991594936709, -1.434878620689655 ], [ 29.799646455696202, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12651, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.434878620689655 ], [ 29.79991594936709, -1.435148103448276 ], [ 29.800185443037975, -1.435148103448276 ], [ 29.800185443037975, -1.434878620689655 ], [ 29.79991594936709, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12652, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.434878620689655 ], [ 29.800185443037975, -1.435148103448276 ], [ 29.80045493670886, -1.435148103448276 ], [ 29.80045493670886, -1.434878620689655 ], [ 29.800185443037975, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12653, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.434878620689655 ], [ 29.801263417721518, -1.435148103448276 ], [ 29.801532911392407, -1.435148103448276 ], [ 29.801532911392407, -1.434878620689655 ], [ 29.801263417721518, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12654, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.434878620689655 ], [ 29.801532911392407, -1.435148103448276 ], [ 29.802071898734177, -1.435148103448276 ], [ 29.802071898734177, -1.434878620689655 ], [ 29.801532911392407, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12655, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.434878620689655 ], [ 29.802341392405065, -1.435148103448276 ], [ 29.80261088607595, -1.435148103448276 ], [ 29.80261088607595, -1.434878620689655 ], [ 29.802341392405065, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12656, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.434878620689655 ], [ 29.80261088607595, -1.435148103448276 ], [ 29.802880379746835, -1.435148103448276 ], [ 29.802880379746835, -1.434878620689655 ], [ 29.80261088607595, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12657, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.434878620689655 ], [ 29.802880379746835, -1.435148103448276 ], [ 29.803149873417723, -1.435148103448276 ], [ 29.803149873417723, -1.434878620689655 ], [ 29.802880379746835, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12658, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.434609137931035 ], [ 29.744939240506326, -1.435956551724138 ], [ 29.745208734177215, -1.435956551724138 ], [ 29.745208734177215, -1.434609137931035 ], [ 29.744939240506326, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12659, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.434609137931035 ], [ 29.756796962025316, -1.437303965517241 ], [ 29.757066455696201, -1.437303965517241 ], [ 29.757066455696201, -1.434609137931035 ], [ 29.756796962025316, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12660, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.434609137931035 ], [ 29.757066455696201, -1.437303965517241 ], [ 29.757335949367089, -1.437303965517241 ], [ 29.757335949367089, -1.434609137931035 ], [ 29.757066455696201, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12661, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.434609137931035 ], [ 29.757605443037974, -1.437303965517241 ], [ 29.757874936708859, -1.437303965517241 ], [ 29.757874936708859, -1.434609137931035 ], [ 29.757605443037974, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12662, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.434609137931035 ], [ 29.757874936708859, -1.437303965517241 ], [ 29.758144430379748, -1.437303965517241 ], [ 29.758144430379748, -1.434609137931035 ], [ 29.757874936708859, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12663, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.434609137931035 ], [ 29.803688860759493, -1.436226034482759 ], [ 29.803958354430382, -1.436226034482759 ], [ 29.803958354430382, -1.434609137931035 ], [ 29.803688860759493, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12664, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.434878620689655 ], [ 29.745208734177215, -1.435956551724138 ], [ 29.7454782278481, -1.435956551724138 ], [ 29.7454782278481, -1.434878620689655 ], [ 29.745208734177215, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12665, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.434878620689655 ], [ 29.7454782278481, -1.435956551724138 ], [ 29.745747721518985, -1.435956551724138 ], [ 29.745747721518985, -1.434878620689655 ], [ 29.7454782278481, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12666, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.434878620689655 ], [ 29.757335949367089, -1.437034482758621 ], [ 29.757605443037974, -1.437034482758621 ], [ 29.757605443037974, -1.434878620689655 ], [ 29.757335949367089, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12667, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.434878620689655 ], [ 29.758144430379748, -1.437034482758621 ], [ 29.758413924050632, -1.437034482758621 ], [ 29.758413924050632, -1.434878620689655 ], [ 29.758144430379748, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12668, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.434878620689655 ], [ 29.758413924050632, -1.437034482758621 ], [ 29.758683417721517, -1.437034482758621 ], [ 29.758683417721517, -1.434878620689655 ], [ 29.758413924050632, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12669, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.434878620689655 ], [ 29.758683417721517, -1.437034482758621 ], [ 29.758952911392406, -1.437034482758621 ], [ 29.758952911392406, -1.434878620689655 ], [ 29.758683417721517, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12670, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.434878620689655 ], [ 29.762186835443039, -1.435956551724138 ], [ 29.762456329113924, -1.435956551724138 ], [ 29.762456329113924, -1.434878620689655 ], [ 29.762186835443039, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12671, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.434878620689655 ], [ 29.762456329113924, -1.435956551724138 ], [ 29.762725822784809, -1.435956551724138 ], [ 29.762725822784809, -1.434878620689655 ], [ 29.762456329113924, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12672, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.434878620689655 ], [ 29.762995316455697, -1.435687068965517 ], [ 29.763264810126582, -1.435687068965517 ], [ 29.763264810126582, -1.434878620689655 ], [ 29.762995316455697, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12673, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.434878620689655 ], [ 29.763264810126582, -1.435687068965517 ], [ 29.763534303797467, -1.435687068965517 ], [ 29.763534303797467, -1.434878620689655 ], [ 29.763264810126582, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12674, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.434878620689655 ], [ 29.767307215189874, -1.435687068965517 ], [ 29.767576708860759, -1.435687068965517 ], [ 29.767576708860759, -1.434878620689655 ], [ 29.767307215189874, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12675, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.434878620689655 ], [ 29.768115696202532, -1.435687068965517 ], [ 29.768385189873417, -1.435687068965517 ], [ 29.768385189873417, -1.434878620689655 ], [ 29.768115696202532, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12676, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.434878620689655 ], [ 29.770002151898733, -1.435687068965517 ], [ 29.770271645569618, -1.435687068965517 ], [ 29.770271645569618, -1.434878620689655 ], [ 29.770002151898733, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12677, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.434878620689655 ], [ 29.771888607594935, -1.435687068965517 ], [ 29.772158101265823, -1.435687068965517 ], [ 29.772158101265823, -1.434878620689655 ], [ 29.771888607594935, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12678, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.434878620689655 ], [ 29.772697088607593, -1.435687068965517 ], [ 29.772966582278482, -1.435687068965517 ], [ 29.772966582278482, -1.434878620689655 ], [ 29.772697088607593, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12679, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.434878620689655 ], [ 29.775392025316457, -1.435687068965517 ], [ 29.775661518987341, -1.435687068965517 ], [ 29.775661518987341, -1.434878620689655 ], [ 29.775392025316457, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12680, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.434878620689655 ], [ 29.786710759493673, -1.435148103448276 ], [ 29.786980253164558, -1.435148103448276 ], [ 29.786980253164558, -1.435687068965517 ], [ 29.789675189873417, -1.435687068965517 ], [ 29.789675189873417, -1.435148103448276 ], [ 29.788597215189874, -1.435148103448276 ], [ 29.788597215189874, -1.434878620689655 ], [ 29.786710759493673, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12681, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.434878620689655 ], [ 29.800724430379748, -1.435687068965517 ], [ 29.800993924050633, -1.435687068965517 ], [ 29.800993924050633, -1.434878620689655 ], [ 29.800724430379748, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12682, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.434878620689655 ], [ 29.803149873417723, -1.436226034482759 ], [ 29.803419367088608, -1.436226034482759 ], [ 29.803419367088608, -1.434878620689655 ], [ 29.803149873417723, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12683, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.434878620689655 ], [ 29.803419367088608, -1.436226034482759 ], [ 29.803688860759493, -1.436226034482759 ], [ 29.803688860759493, -1.434878620689655 ], [ 29.803419367088608, -1.434878620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12684, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.435148103448276 ], [ 29.745747721518985, -1.436226034482759 ], [ 29.746017215189873, -1.436226034482759 ], [ 29.746017215189873, -1.435148103448276 ], [ 29.745747721518985, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12685, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.435148103448276 ], [ 29.746017215189873, -1.436226034482759 ], [ 29.746286708860758, -1.436226034482759 ], [ 29.746286708860758, -1.435148103448276 ], [ 29.746017215189873, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12686, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.435148103448276 ], [ 29.746286708860758, -1.435956551724138 ], [ 29.746556202531643, -1.435956551724138 ], [ 29.746556202531643, -1.435148103448276 ], [ 29.746286708860758, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12687, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.435148103448276 ], [ 29.746556202531643, -1.435687068965517 ], [ 29.746825696202531, -1.435687068965517 ], [ 29.746825696202531, -1.435148103448276 ], [ 29.746556202531643, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12688, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.435148103448276 ], [ 29.746825696202531, -1.435687068965517 ], [ 29.747095189873416, -1.435687068965517 ], [ 29.747095189873416, -1.435148103448276 ], [ 29.746825696202531, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12689, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.435148103448276 ], [ 29.747095189873416, -1.435687068965517 ], [ 29.747364683544301, -1.435687068965517 ], [ 29.747364683544301, -1.435148103448276 ], [ 29.747095189873416, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12690, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.435148103448276 ], [ 29.747364683544301, -1.435687068965517 ], [ 29.747903670886075, -1.435687068965517 ], [ 29.747903670886075, -1.435148103448276 ], [ 29.747364683544301, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12691, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.435148103448276 ], [ 29.747903670886075, -1.435687068965517 ], [ 29.748442658227848, -1.435687068965517 ], [ 29.748442658227848, -1.435148103448276 ], [ 29.747903670886075, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12692, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.435148103448276 ], [ 29.748442658227848, -1.435687068965517 ], [ 29.748712151898733, -1.435687068965517 ], [ 29.748712151898733, -1.435148103448276 ], [ 29.748442658227848, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12693, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.435148103448276 ], [ 29.748712151898733, -1.435687068965517 ], [ 29.748981645569618, -1.435687068965517 ], [ 29.748981645569618, -1.435148103448276 ], [ 29.748712151898733, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12694, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.435148103448276 ], [ 29.748981645569618, -1.435687068965517 ], [ 29.749251139240506, -1.435687068965517 ], [ 29.749251139240506, -1.435148103448276 ], [ 29.748981645569618, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12695, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.435148103448276 ], [ 29.749251139240506, -1.435687068965517 ], [ 29.749520632911391, -1.435687068965517 ], [ 29.749520632911391, -1.435148103448276 ], [ 29.749251139240506, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12696, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.435148103448276 ], [ 29.749520632911391, -1.435687068965517 ], [ 29.749790126582276, -1.435687068965517 ], [ 29.749790126582276, -1.435148103448276 ], [ 29.749520632911391, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12697, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.435148103448276 ], [ 29.749790126582276, -1.435687068965517 ], [ 29.750059620253165, -1.435687068965517 ], [ 29.750059620253165, -1.435148103448276 ], [ 29.749790126582276, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12698, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.435148103448276 ], [ 29.750059620253165, -1.435687068965517 ], [ 29.75032911392405, -1.435687068965517 ], [ 29.75032911392405, -1.435148103448276 ], [ 29.750059620253165, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12699, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.435148103448276 ], [ 29.75032911392405, -1.435687068965517 ], [ 29.750598607594934, -1.435687068965517 ], [ 29.750598607594934, -1.435148103448276 ], [ 29.75032911392405, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12700, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.435148103448276 ], [ 29.750598607594934, -1.435687068965517 ], [ 29.750868101265823, -1.435687068965517 ], [ 29.750868101265823, -1.435148103448276 ], [ 29.750598607594934, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12701, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.435148103448276 ], [ 29.750868101265823, -1.435687068965517 ], [ 29.754371518987341, -1.435687068965517 ], [ 29.754371518987341, -1.435148103448276 ], [ 29.750868101265823, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12702, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.435148103448276 ], [ 29.754371518987341, -1.435687068965517 ], [ 29.754641012658226, -1.435687068965517 ], [ 29.754641012658226, -1.435148103448276 ], [ 29.754371518987341, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12703, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.435148103448276 ], [ 29.754641012658226, -1.435687068965517 ], [ 29.754910506329114, -1.435687068965517 ], [ 29.754910506329114, -1.435148103448276 ], [ 29.754641012658226, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12704, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.435148103448276 ], [ 29.754910506329114, -1.435687068965517 ], [ 29.75518, -1.435687068965517 ], [ 29.75518, -1.435148103448276 ], [ 29.754910506329114, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12705, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.435148103448276 ], [ 29.75518, -1.435687068965517 ], [ 29.755449493670884, -1.435687068965517 ], [ 29.755449493670884, -1.435148103448276 ], [ 29.75518, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12706, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.435148103448276 ], [ 29.755449493670884, -1.435687068965517 ], [ 29.755718987341773, -1.435687068965517 ], [ 29.755718987341773, -1.435148103448276 ], [ 29.755449493670884, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12707, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.435148103448276 ], [ 29.755718987341773, -1.435687068965517 ], [ 29.755988481012658, -1.435687068965517 ], [ 29.755988481012658, -1.435148103448276 ], [ 29.755718987341773, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12708, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.435148103448276 ], [ 29.755988481012658, -1.435687068965517 ], [ 29.756257974683542, -1.435687068965517 ], [ 29.756257974683542, -1.435148103448276 ], [ 29.755988481012658, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12709, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.435148103448276 ], [ 29.761378354430381, -1.436495517241379 ], [ 29.761647848101266, -1.436495517241379 ], [ 29.761647848101266, -1.435148103448276 ], [ 29.761378354430381, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12710, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.435148103448276 ], [ 29.761647848101266, -1.436226034482759 ], [ 29.761917341772151, -1.436226034482759 ], [ 29.761917341772151, -1.435148103448276 ], [ 29.761647848101266, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12711, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.435148103448276 ], [ 29.761917341772151, -1.436226034482759 ], [ 29.762186835443039, -1.436226034482759 ], [ 29.762186835443039, -1.435148103448276 ], [ 29.761917341772151, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12712, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.435148103448276 ], [ 29.762725822784809, -1.435956551724138 ], [ 29.762995316455697, -1.435956551724138 ], [ 29.762995316455697, -1.435148103448276 ], [ 29.762725822784809, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12713, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.435148103448276 ], [ 29.763534303797467, -1.435687068965517 ], [ 29.763803797468356, -1.435687068965517 ], [ 29.763803797468356, -1.435148103448276 ], [ 29.763534303797467, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12714, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.435148103448276 ], [ 29.763803797468356, -1.435687068965517 ], [ 29.76407329113924, -1.435687068965517 ], [ 29.76407329113924, -1.435148103448276 ], [ 29.763803797468356, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12715, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.435148103448276 ], [ 29.76407329113924, -1.435687068965517 ], [ 29.764342784810125, -1.435687068965517 ], [ 29.764342784810125, -1.435148103448276 ], [ 29.76407329113924, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12716, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.435148103448276 ], [ 29.764342784810125, -1.435687068965517 ], [ 29.764612278481014, -1.435687068965517 ], [ 29.764612278481014, -1.435148103448276 ], [ 29.764342784810125, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12717, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.435148103448276 ], [ 29.764612278481014, -1.435687068965517 ], [ 29.764881772151899, -1.435687068965517 ], [ 29.764881772151899, -1.435148103448276 ], [ 29.764612278481014, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12718, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.435148103448276 ], [ 29.764881772151899, -1.435687068965517 ], [ 29.765151265822784, -1.435687068965517 ], [ 29.765151265822784, -1.435148103448276 ], [ 29.764881772151899, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12719, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.435148103448276 ], [ 29.765151265822784, -1.435956551724138 ], [ 29.765420759493672, -1.435956551724138 ], [ 29.765420759493672, -1.435148103448276 ], [ 29.765151265822784, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12720, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.435148103448276 ], [ 29.765420759493672, -1.435687068965517 ], [ 29.765959746835442, -1.435687068965517 ], [ 29.765959746835442, -1.435148103448276 ], [ 29.765420759493672, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12721, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.435148103448276 ], [ 29.765959746835442, -1.435687068965517 ], [ 29.766229240506327, -1.435687068965517 ], [ 29.766229240506327, -1.435148103448276 ], [ 29.765959746835442, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12722, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.435148103448276 ], [ 29.766229240506327, -1.435956551724138 ], [ 29.766498734177215, -1.435956551724138 ], [ 29.766498734177215, -1.435148103448276 ], [ 29.766229240506327, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12723, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.435148103448276 ], [ 29.766498734177215, -1.435687068965517 ], [ 29.7667682278481, -1.435687068965517 ], [ 29.7667682278481, -1.435148103448276 ], [ 29.766498734177215, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12724, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.435148103448276 ], [ 29.7667682278481, -1.435687068965517 ], [ 29.767037721518985, -1.435687068965517 ], [ 29.767037721518985, -1.435148103448276 ], [ 29.7667682278481, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12725, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.435148103448276 ], [ 29.767037721518985, -1.435956551724138 ], [ 29.767307215189874, -1.435956551724138 ], [ 29.767307215189874, -1.435148103448276 ], [ 29.767037721518985, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12726, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.435148103448276 ], [ 29.767576708860759, -1.435687068965517 ], [ 29.767846202531643, -1.435687068965517 ], [ 29.767846202531643, -1.435148103448276 ], [ 29.767576708860759, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12727, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.435148103448276 ], [ 29.767846202531643, -1.435687068965517 ], [ 29.768115696202532, -1.435687068965517 ], [ 29.768115696202532, -1.435148103448276 ], [ 29.767846202531643, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12728, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.435148103448276 ], [ 29.768385189873417, -1.435687068965517 ], [ 29.768654683544302, -1.435687068965517 ], [ 29.768654683544302, -1.435148103448276 ], [ 29.768385189873417, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12729, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.435148103448276 ], [ 29.768654683544302, -1.435687068965517 ], [ 29.76892417721519, -1.435687068965517 ], [ 29.76892417721519, -1.435148103448276 ], [ 29.768654683544302, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12730, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.435148103448276 ], [ 29.76892417721519, -1.435956551724138 ], [ 29.769193670886075, -1.435956551724138 ], [ 29.769193670886075, -1.435148103448276 ], [ 29.76892417721519, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12731, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.435148103448276 ], [ 29.769193670886075, -1.435687068965517 ], [ 29.76946316455696, -1.435687068965517 ], [ 29.76946316455696, -1.435148103448276 ], [ 29.769193670886075, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12732, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.435148103448276 ], [ 29.76946316455696, -1.435687068965517 ], [ 29.769732658227849, -1.435687068965517 ], [ 29.769732658227849, -1.435148103448276 ], [ 29.76946316455696, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12733, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.435148103448276 ], [ 29.769732658227849, -1.435687068965517 ], [ 29.770002151898733, -1.435687068965517 ], [ 29.770002151898733, -1.435148103448276 ], [ 29.769732658227849, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12734, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.435148103448276 ], [ 29.770271645569618, -1.435687068965517 ], [ 29.770541139240507, -1.435687068965517 ], [ 29.770541139240507, -1.435148103448276 ], [ 29.770271645569618, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12735, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.435148103448276 ], [ 29.770541139240507, -1.435687068965517 ], [ 29.770810632911392, -1.435687068965517 ], [ 29.770810632911392, -1.435148103448276 ], [ 29.770541139240507, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12736, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.435148103448276 ], [ 29.770810632911392, -1.435956551724138 ], [ 29.771080126582277, -1.435956551724138 ], [ 29.771080126582277, -1.435148103448276 ], [ 29.770810632911392, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12737, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.435148103448276 ], [ 29.771080126582277, -1.435687068965517 ], [ 29.771349620253165, -1.435687068965517 ], [ 29.771349620253165, -1.435148103448276 ], [ 29.771080126582277, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12738, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.435148103448276 ], [ 29.771349620253165, -1.435687068965517 ], [ 29.77161911392405, -1.435687068965517 ], [ 29.77161911392405, -1.435148103448276 ], [ 29.771349620253165, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12739, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.435148103448276 ], [ 29.77161911392405, -1.435956551724138 ], [ 29.771888607594935, -1.435956551724138 ], [ 29.771888607594935, -1.435148103448276 ], [ 29.77161911392405, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12740, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.435148103448276 ], [ 29.772158101265823, -1.435687068965517 ], [ 29.772427594936708, -1.435687068965517 ], [ 29.772427594936708, -1.435148103448276 ], [ 29.772158101265823, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12741, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.435148103448276 ], [ 29.772427594936708, -1.435687068965517 ], [ 29.772697088607593, -1.435687068965517 ], [ 29.772697088607593, -1.435148103448276 ], [ 29.772427594936708, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12742, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.435148103448276 ], [ 29.772966582278482, -1.435687068965517 ], [ 29.773236075949367, -1.435687068965517 ], [ 29.773236075949367, -1.435148103448276 ], [ 29.772966582278482, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12743, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.435148103448276 ], [ 29.773236075949367, -1.435687068965517 ], [ 29.773505569620252, -1.435687068965517 ], [ 29.773505569620252, -1.435148103448276 ], [ 29.773236075949367, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12744, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.435148103448276 ], [ 29.773505569620252, -1.435956551724138 ], [ 29.77377506329114, -1.435956551724138 ], [ 29.77377506329114, -1.435148103448276 ], [ 29.773505569620252, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12745, "El": 150 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.435148103448276 ], [ 29.77377506329114, -1.435687068965517 ], [ 29.774044556962025, -1.435687068965517 ], [ 29.774044556962025, -1.435148103448276 ], [ 29.77377506329114, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12746, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.435148103448276 ], [ 29.774044556962025, -1.435687068965517 ], [ 29.77431405063291, -1.435687068965517 ], [ 29.77431405063291, -1.435148103448276 ], [ 29.774044556962025, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12747, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.435148103448276 ], [ 29.77431405063291, -1.435956551724138 ], [ 29.774853037974683, -1.435956551724138 ], [ 29.774853037974683, -1.435148103448276 ], [ 29.77431405063291, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12748, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.435148103448276 ], [ 29.774853037974683, -1.435687068965517 ], [ 29.775122531645568, -1.435687068965517 ], [ 29.775122531645568, -1.435148103448276 ], [ 29.774853037974683, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12749, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.435148103448276 ], [ 29.775122531645568, -1.435687068965517 ], [ 29.775392025316457, -1.435687068965517 ], [ 29.775392025316457, -1.435148103448276 ], [ 29.775122531645568, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12750, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.435148103448276 ], [ 29.775661518987341, -1.435687068965517 ], [ 29.775931012658226, -1.435687068965517 ], [ 29.775931012658226, -1.435148103448276 ], [ 29.775661518987341, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12751, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.435148103448276 ], [ 29.775931012658226, -1.435687068965517 ], [ 29.776200506329115, -1.435687068965517 ], [ 29.776200506329115, -1.435148103448276 ], [ 29.775931012658226, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12752, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.435148103448276 ], [ 29.776200506329115, -1.435687068965517 ], [ 29.77647, -1.435687068965517 ], [ 29.77647, -1.435148103448276 ], [ 29.776200506329115, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12753, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.435148103448276 ], [ 29.77647, -1.435956551724138 ], [ 29.776739493670885, -1.435956551724138 ], [ 29.776739493670885, -1.435148103448276 ], [ 29.77647, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12754, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.435148103448276 ], [ 29.776739493670885, -1.435687068965517 ], [ 29.777008987341773, -1.435687068965517 ], [ 29.777008987341773, -1.435148103448276 ], [ 29.776739493670885, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12755, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.435148103448276 ], [ 29.777008987341773, -1.435687068965517 ], [ 29.777278481012658, -1.435687068965517 ], [ 29.777278481012658, -1.435148103448276 ], [ 29.777008987341773, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12756, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.435148103448276 ], [ 29.777278481012658, -1.435687068965517 ], [ 29.777547974683543, -1.435687068965517 ], [ 29.777547974683543, -1.435148103448276 ], [ 29.777278481012658, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12757, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.435148103448276 ], [ 29.777547974683543, -1.435687068965517 ], [ 29.777817468354431, -1.435687068965517 ], [ 29.777817468354431, -1.435148103448276 ], [ 29.777547974683543, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12758, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.435148103448276 ], [ 29.777817468354431, -1.435687068965517 ], [ 29.778086962025316, -1.435687068965517 ], [ 29.778086962025316, -1.435148103448276 ], [ 29.777817468354431, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12759, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.435148103448276 ], [ 29.778086962025316, -1.435687068965517 ], [ 29.778356455696201, -1.435687068965517 ], [ 29.778356455696201, -1.435148103448276 ], [ 29.778086962025316, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12760, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.435148103448276 ], [ 29.778356455696201, -1.435687068965517 ], [ 29.77862594936709, -1.435687068965517 ], [ 29.77862594936709, -1.435148103448276 ], [ 29.778356455696201, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12761, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.435148103448276 ], [ 29.77862594936709, -1.435687068965517 ], [ 29.778895443037975, -1.435687068965517 ], [ 29.778895443037975, -1.435148103448276 ], [ 29.77862594936709, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12762, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.435148103448276 ], [ 29.778895443037975, -1.435687068965517 ], [ 29.77916493670886, -1.435687068965517 ], [ 29.77916493670886, -1.435148103448276 ], [ 29.778895443037975, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12763, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.435148103448276 ], [ 29.77916493670886, -1.435687068965517 ], [ 29.779434430379748, -1.435687068965517 ], [ 29.779434430379748, -1.435148103448276 ], [ 29.77916493670886, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12764, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.435148103448276 ], [ 29.779434430379748, -1.435687068965517 ], [ 29.779703924050633, -1.435687068965517 ], [ 29.779703924050633, -1.435148103448276 ], [ 29.779434430379748, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12765, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.435148103448276 ], [ 29.779703924050633, -1.435687068965517 ], [ 29.779973417721518, -1.435687068965517 ], [ 29.779973417721518, -1.435148103448276 ], [ 29.779703924050633, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12766, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.435148103448276 ], [ 29.779973417721518, -1.435687068965517 ], [ 29.780242911392406, -1.435687068965517 ], [ 29.780242911392406, -1.435148103448276 ], [ 29.779973417721518, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12767, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.435148103448276 ], [ 29.780242911392406, -1.435687068965517 ], [ 29.780512405063291, -1.435687068965517 ], [ 29.780512405063291, -1.435148103448276 ], [ 29.780242911392406, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12768, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.435148103448276 ], [ 29.780512405063291, -1.435687068965517 ], [ 29.780781898734176, -1.435687068965517 ], [ 29.780781898734176, -1.435148103448276 ], [ 29.780512405063291, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12769, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.435148103448276 ], [ 29.780781898734176, -1.435687068965517 ], [ 29.781051392405065, -1.435687068965517 ], [ 29.781051392405065, -1.435148103448276 ], [ 29.780781898734176, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12770, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.435148103448276 ], [ 29.781051392405065, -1.435687068965517 ], [ 29.78132088607595, -1.435687068965517 ], [ 29.78132088607595, -1.435148103448276 ], [ 29.781051392405065, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12771, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.435148103448276 ], [ 29.78132088607595, -1.435687068965517 ], [ 29.781590379746834, -1.435687068965517 ], [ 29.781590379746834, -1.435148103448276 ], [ 29.78132088607595, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12772, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.435148103448276 ], [ 29.781590379746834, -1.435687068965517 ], [ 29.781859873417723, -1.435687068965517 ], [ 29.781859873417723, -1.435148103448276 ], [ 29.781590379746834, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12773, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.435148103448276 ], [ 29.781859873417723, -1.435687068965517 ], [ 29.782129367088608, -1.435687068965517 ], [ 29.782129367088608, -1.435148103448276 ], [ 29.781859873417723, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12774, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.435148103448276 ], [ 29.782129367088608, -1.435687068965517 ], [ 29.782398860759493, -1.435687068965517 ], [ 29.782398860759493, -1.435148103448276 ], [ 29.782129367088608, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12775, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.435148103448276 ], [ 29.782398860759493, -1.435687068965517 ], [ 29.782668354430381, -1.435687068965517 ], [ 29.782668354430381, -1.435148103448276 ], [ 29.782398860759493, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12776, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.435148103448276 ], [ 29.782668354430381, -1.435687068965517 ], [ 29.782937848101266, -1.435687068965517 ], [ 29.782937848101266, -1.435148103448276 ], [ 29.782668354430381, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12777, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.435148103448276 ], [ 29.782937848101266, -1.435687068965517 ], [ 29.783207341772151, -1.435687068965517 ], [ 29.783207341772151, -1.435148103448276 ], [ 29.782937848101266, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12778, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.435148103448276 ], [ 29.783207341772151, -1.435687068965517 ], [ 29.783476835443039, -1.435687068965517 ], [ 29.783476835443039, -1.435148103448276 ], [ 29.783207341772151, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12779, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.435148103448276 ], [ 29.783476835443039, -1.435687068965517 ], [ 29.784015822784809, -1.435687068965517 ], [ 29.784015822784809, -1.435148103448276 ], [ 29.783476835443039, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12780, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.435148103448276 ], [ 29.784015822784809, -1.435687068965517 ], [ 29.784285316455698, -1.435687068965517 ], [ 29.784285316455698, -1.435148103448276 ], [ 29.784015822784809, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12781, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.435148103448276 ], [ 29.784285316455698, -1.435687068965517 ], [ 29.784554810126583, -1.435687068965517 ], [ 29.784554810126583, -1.435148103448276 ], [ 29.784285316455698, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12782, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.435148103448276 ], [ 29.784554810126583, -1.435687068965517 ], [ 29.784824303797468, -1.435687068965517 ], [ 29.784824303797468, -1.435148103448276 ], [ 29.784554810126583, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12783, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.435148103448276 ], [ 29.784824303797468, -1.435687068965517 ], [ 29.785093797468356, -1.435687068965517 ], [ 29.785093797468356, -1.435148103448276 ], [ 29.784824303797468, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12784, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.435148103448276 ], [ 29.785093797468356, -1.435687068965517 ], [ 29.785632784810126, -1.435687068965517 ], [ 29.785632784810126, -1.435148103448276 ], [ 29.785093797468356, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12785, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.435148103448276 ], [ 29.785632784810126, -1.435687068965517 ], [ 29.786441265822784, -1.435687068965517 ], [ 29.786441265822784, -1.435148103448276 ], [ 29.785632784810126, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12786, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.435148103448276 ], [ 29.786441265822784, -1.435687068965517 ], [ 29.786710759493673, -1.435687068965517 ], [ 29.786710759493673, -1.435148103448276 ], [ 29.786441265822784, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12787, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.435148103448276 ], [ 29.786710759493673, -1.435687068965517 ], [ 29.786980253164558, -1.435687068965517 ], [ 29.786980253164558, -1.435148103448276 ], [ 29.786710759493673, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12788, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.435148103448276 ], [ 29.789675189873417, -1.435687068965517 ], [ 29.789944683544302, -1.435687068965517 ], [ 29.789944683544302, -1.435148103448276 ], [ 29.789675189873417, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12789, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.435148103448276 ], [ 29.789944683544302, -1.435687068965517 ], [ 29.790214177215191, -1.435687068965517 ], [ 29.790214177215191, -1.435148103448276 ], [ 29.789944683544302, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12790, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.435148103448276 ], [ 29.790214177215191, -1.435687068965517 ], [ 29.790483670886076, -1.435687068965517 ], [ 29.790483670886076, -1.435148103448276 ], [ 29.790214177215191, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12791, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.435148103448276 ], [ 29.790483670886076, -1.435687068965517 ], [ 29.790753164556961, -1.435687068965517 ], [ 29.790753164556961, -1.435148103448276 ], [ 29.790483670886076, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12792, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.435148103448276 ], [ 29.790753164556961, -1.435687068965517 ], [ 29.791022658227849, -1.435687068965517 ], [ 29.791022658227849, -1.435148103448276 ], [ 29.790753164556961, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12793, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.435148103448276 ], [ 29.791022658227849, -1.435687068965517 ], [ 29.791292151898734, -1.435687068965517 ], [ 29.791292151898734, -1.435148103448276 ], [ 29.791022658227849, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12794, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.435148103448276 ], [ 29.791292151898734, -1.435687068965517 ], [ 29.791561645569619, -1.435687068965517 ], [ 29.791561645569619, -1.435148103448276 ], [ 29.791292151898734, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12795, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.435148103448276 ], [ 29.791561645569619, -1.435687068965517 ], [ 29.791831139240507, -1.435687068965517 ], [ 29.791831139240507, -1.435148103448276 ], [ 29.791561645569619, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12796, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.435148103448276 ], [ 29.791831139240507, -1.435687068965517 ], [ 29.792100632911392, -1.435687068965517 ], [ 29.792100632911392, -1.435148103448276 ], [ 29.791831139240507, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12797, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.435148103448276 ], [ 29.792100632911392, -1.435687068965517 ], [ 29.792370126582277, -1.435687068965517 ], [ 29.792370126582277, -1.435148103448276 ], [ 29.792100632911392, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12798, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.435148103448276 ], [ 29.792370126582277, -1.435687068965517 ], [ 29.792909113924051, -1.435687068965517 ], [ 29.792909113924051, -1.435148103448276 ], [ 29.792370126582277, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12799, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.435148103448276 ], [ 29.792909113924051, -1.435687068965517 ], [ 29.793178607594935, -1.435687068965517 ], [ 29.793178607594935, -1.435148103448276 ], [ 29.792909113924051, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12800, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.435148103448276 ], [ 29.793178607594935, -1.435687068965517 ], [ 29.793448101265824, -1.435687068965517 ], [ 29.793448101265824, -1.435148103448276 ], [ 29.793178607594935, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12801, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.435148103448276 ], [ 29.793448101265824, -1.435687068965517 ], [ 29.793717594936709, -1.435687068965517 ], [ 29.793717594936709, -1.435148103448276 ], [ 29.793448101265824, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12802, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.435148103448276 ], [ 29.793717594936709, -1.435687068965517 ], [ 29.793987088607594, -1.435687068965517 ], [ 29.793987088607594, -1.435148103448276 ], [ 29.793717594936709, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12803, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.435148103448276 ], [ 29.793987088607594, -1.435687068965517 ], [ 29.794256582278482, -1.435687068965517 ], [ 29.794256582278482, -1.435148103448276 ], [ 29.793987088607594, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12804, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.435148103448276 ], [ 29.794256582278482, -1.435687068965517 ], [ 29.794526075949367, -1.435687068965517 ], [ 29.794526075949367, -1.435148103448276 ], [ 29.794256582278482, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12805, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.435148103448276 ], [ 29.794526075949367, -1.435687068965517 ], [ 29.794795569620252, -1.435687068965517 ], [ 29.794795569620252, -1.435148103448276 ], [ 29.794526075949367, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12806, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.435148103448276 ], [ 29.794795569620252, -1.435687068965517 ], [ 29.79506506329114, -1.435687068965517 ], [ 29.79506506329114, -1.435148103448276 ], [ 29.794795569620252, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12807, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.435148103448276 ], [ 29.79506506329114, -1.435687068965517 ], [ 29.795334556962025, -1.435687068965517 ], [ 29.795334556962025, -1.435148103448276 ], [ 29.79506506329114, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12808, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.435148103448276 ], [ 29.795334556962025, -1.435687068965517 ], [ 29.79560405063291, -1.435687068965517 ], [ 29.79560405063291, -1.435148103448276 ], [ 29.795334556962025, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12809, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.435148103448276 ], [ 29.79560405063291, -1.435687068965517 ], [ 29.795873544303799, -1.435687068965517 ], [ 29.795873544303799, -1.435148103448276 ], [ 29.79560405063291, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12810, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.435148103448276 ], [ 29.795873544303799, -1.435687068965517 ], [ 29.796143037974684, -1.435687068965517 ], [ 29.796143037974684, -1.435148103448276 ], [ 29.795873544303799, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12811, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.435148103448276 ], [ 29.796143037974684, -1.435687068965517 ], [ 29.796412531645569, -1.435687068965517 ], [ 29.796412531645569, -1.435148103448276 ], [ 29.796143037974684, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12812, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.435148103448276 ], [ 29.796412531645569, -1.435687068965517 ], [ 29.796951518987342, -1.435687068965517 ], [ 29.796951518987342, -1.435148103448276 ], [ 29.796412531645569, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12813, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.435148103448276 ], [ 29.796951518987342, -1.435687068965517 ], [ 29.797490506329115, -1.435687068965517 ], [ 29.797490506329115, -1.435148103448276 ], [ 29.796951518987342, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12814, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.435148103448276 ], [ 29.797490506329115, -1.435687068965517 ], [ 29.798029493670885, -1.435687068965517 ], [ 29.798029493670885, -1.435148103448276 ], [ 29.797490506329115, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12815, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.435148103448276 ], [ 29.798029493670885, -1.435687068965517 ], [ 29.798298987341774, -1.435687068965517 ], [ 29.798298987341774, -1.435148103448276 ], [ 29.798029493670885, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12816, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.435148103448276 ], [ 29.798298987341774, -1.435687068965517 ], [ 29.798568481012659, -1.435687068965517 ], [ 29.798568481012659, -1.435148103448276 ], [ 29.798298987341774, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12817, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.435148103448276 ], [ 29.798568481012659, -1.435687068965517 ], [ 29.798837974683543, -1.435687068965517 ], [ 29.798837974683543, -1.435148103448276 ], [ 29.798568481012659, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12818, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.435148103448276 ], [ 29.798837974683543, -1.435687068965517 ], [ 29.799107468354432, -1.435687068965517 ], [ 29.799107468354432, -1.435148103448276 ], [ 29.798837974683543, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12819, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.435148103448276 ], [ 29.799107468354432, -1.435687068965517 ], [ 29.799376962025317, -1.435687068965517 ], [ 29.799376962025317, -1.435148103448276 ], [ 29.799107468354432, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12820, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.435148103448276 ], [ 29.799376962025317, -1.435687068965517 ], [ 29.799646455696202, -1.435687068965517 ], [ 29.799646455696202, -1.435148103448276 ], [ 29.799376962025317, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12821, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.435148103448276 ], [ 29.799646455696202, -1.435687068965517 ], [ 29.79991594936709, -1.435687068965517 ], [ 29.79991594936709, -1.435148103448276 ], [ 29.799646455696202, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12822, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.435148103448276 ], [ 29.79991594936709, -1.435687068965517 ], [ 29.800185443037975, -1.435687068965517 ], [ 29.800185443037975, -1.435148103448276 ], [ 29.79991594936709, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12823, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.435148103448276 ], [ 29.800185443037975, -1.435687068965517 ], [ 29.800724430379748, -1.435687068965517 ], [ 29.800724430379748, -1.435148103448276 ], [ 29.800185443037975, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12824, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.435148103448276 ], [ 29.800993924050633, -1.435687068965517 ], [ 29.801263417721518, -1.435687068965517 ], [ 29.801263417721518, -1.435148103448276 ], [ 29.800993924050633, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12825, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.435148103448276 ], [ 29.801263417721518, -1.435687068965517 ], [ 29.801532911392407, -1.435687068965517 ], [ 29.801532911392407, -1.435148103448276 ], [ 29.801263417721518, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12826, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.435148103448276 ], [ 29.801532911392407, -1.435687068965517 ], [ 29.802071898734177, -1.435687068965517 ], [ 29.802071898734177, -1.435148103448276 ], [ 29.801532911392407, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12827, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.435148103448276 ], [ 29.802071898734177, -1.435687068965517 ], [ 29.802341392405065, -1.435687068965517 ], [ 29.802341392405065, -1.435148103448276 ], [ 29.802071898734177, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12828, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.435148103448276 ], [ 29.802341392405065, -1.436226034482759 ], [ 29.80261088607595, -1.436226034482759 ], [ 29.80261088607595, -1.435148103448276 ], [ 29.802341392405065, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12829, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.435148103448276 ], [ 29.80261088607595, -1.436495517241379 ], [ 29.802880379746835, -1.436495517241379 ], [ 29.802880379746835, -1.435148103448276 ], [ 29.80261088607595, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12830, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.435148103448276 ], [ 29.802880379746835, -1.436495517241379 ], [ 29.803149873417723, -1.436495517241379 ], [ 29.803149873417723, -1.435148103448276 ], [ 29.802880379746835, -1.435148103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12831, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.435687068965517 ], [ 29.746556202531643, -1.436226034482759 ], [ 29.746825696202531, -1.436226034482759 ], [ 29.746825696202531, -1.435687068965517 ], [ 29.746556202531643, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12832, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.435687068965517 ], [ 29.746825696202531, -1.436226034482759 ], [ 29.747095189873416, -1.436226034482759 ], [ 29.747095189873416, -1.435687068965517 ], [ 29.746825696202531, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12833, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.435687068965517 ], [ 29.747095189873416, -1.436495517241379 ], [ 29.747364683544301, -1.436495517241379 ], [ 29.747364683544301, -1.435956551724138 ], [ 29.747903670886075, -1.435956551724138 ], [ 29.747903670886075, -1.435687068965517 ], [ 29.747095189873416, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12834, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.435687068965517 ], [ 29.747903670886075, -1.435956551724138 ], [ 29.748442658227848, -1.435956551724138 ], [ 29.748442658227848, -1.435687068965517 ], [ 29.747903670886075, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12835, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.435687068965517 ], [ 29.748442658227848, -1.435956551724138 ], [ 29.748712151898733, -1.435956551724138 ], [ 29.748712151898733, -1.435687068965517 ], [ 29.748442658227848, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12836, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.435687068965517 ], [ 29.748712151898733, -1.435956551724138 ], [ 29.748981645569618, -1.435956551724138 ], [ 29.748981645569618, -1.435687068965517 ], [ 29.748712151898733, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12837, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.435687068965517 ], [ 29.748981645569618, -1.435956551724138 ], [ 29.749251139240506, -1.435956551724138 ], [ 29.749251139240506, -1.435687068965517 ], [ 29.748981645569618, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12838, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.435687068965517 ], [ 29.749251139240506, -1.435956551724138 ], [ 29.749520632911391, -1.435956551724138 ], [ 29.749520632911391, -1.435687068965517 ], [ 29.749251139240506, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12839, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.435687068965517 ], [ 29.749520632911391, -1.435956551724138 ], [ 29.749790126582276, -1.435956551724138 ], [ 29.749790126582276, -1.435687068965517 ], [ 29.749520632911391, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12840, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.435687068965517 ], [ 29.749790126582276, -1.435956551724138 ], [ 29.750059620253165, -1.435956551724138 ], [ 29.750059620253165, -1.435687068965517 ], [ 29.749790126582276, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12841, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.435687068965517 ], [ 29.750059620253165, -1.435956551724138 ], [ 29.75032911392405, -1.435956551724138 ], [ 29.75032911392405, -1.435687068965517 ], [ 29.750059620253165, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12842, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.435687068965517 ], [ 29.75032911392405, -1.435956551724138 ], [ 29.750598607594934, -1.435956551724138 ], [ 29.750598607594934, -1.435687068965517 ], [ 29.75032911392405, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12843, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.435687068965517 ], [ 29.750598607594934, -1.435956551724138 ], [ 29.750868101265823, -1.435956551724138 ], [ 29.750868101265823, -1.435687068965517 ], [ 29.750598607594934, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12844, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.435687068965517 ], [ 29.750868101265823, -1.435956551724138 ], [ 29.754371518987341, -1.435956551724138 ], [ 29.754371518987341, -1.435687068965517 ], [ 29.750868101265823, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12845, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.435687068965517 ], [ 29.754371518987341, -1.435956551724138 ], [ 29.754641012658226, -1.435956551724138 ], [ 29.754641012658226, -1.435687068965517 ], [ 29.754371518987341, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12846, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.435687068965517 ], [ 29.754641012658226, -1.435956551724138 ], [ 29.754910506329114, -1.435956551724138 ], [ 29.754910506329114, -1.435687068965517 ], [ 29.754641012658226, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12847, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.435687068965517 ], [ 29.754910506329114, -1.435956551724138 ], [ 29.75518, -1.435956551724138 ], [ 29.75518, -1.435687068965517 ], [ 29.754910506329114, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12848, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.435687068965517 ], [ 29.75518, -1.435956551724138 ], [ 29.755449493670884, -1.435956551724138 ], [ 29.755449493670884, -1.435687068965517 ], [ 29.75518, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12849, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.435687068965517 ], [ 29.755449493670884, -1.435956551724138 ], [ 29.755718987341773, -1.435956551724138 ], [ 29.755718987341773, -1.435687068965517 ], [ 29.755449493670884, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12850, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.435687068965517 ], [ 29.755718987341773, -1.435956551724138 ], [ 29.755988481012658, -1.435956551724138 ], [ 29.755988481012658, -1.435687068965517 ], [ 29.755718987341773, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12851, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.435687068965517 ], [ 29.755988481012658, -1.435956551724138 ], [ 29.756257974683542, -1.435956551724138 ], [ 29.756257974683542, -1.435687068965517 ], [ 29.755988481012658, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12852, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.435687068965517 ], [ 29.762995316455697, -1.435956551724138 ], [ 29.763264810126582, -1.435956551724138 ], [ 29.763264810126582, -1.435687068965517 ], [ 29.762995316455697, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12853, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.435687068965517 ], [ 29.763264810126582, -1.435956551724138 ], [ 29.763534303797467, -1.435956551724138 ], [ 29.763534303797467, -1.435687068965517 ], [ 29.763264810126582, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12854, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.435687068965517 ], [ 29.763534303797467, -1.435956551724138 ], [ 29.763803797468356, -1.435956551724138 ], [ 29.763803797468356, -1.435687068965517 ], [ 29.763534303797467, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12855, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.435687068965517 ], [ 29.763803797468356, -1.435956551724138 ], [ 29.76407329113924, -1.435956551724138 ], [ 29.76407329113924, -1.435687068965517 ], [ 29.763803797468356, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12856, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.435687068965517 ], [ 29.76407329113924, -1.436226034482759 ], [ 29.764342784810125, -1.436226034482759 ], [ 29.764342784810125, -1.435687068965517 ], [ 29.76407329113924, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12857, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.435687068965517 ], [ 29.764342784810125, -1.435956551724138 ], [ 29.764612278481014, -1.435956551724138 ], [ 29.764612278481014, -1.435687068965517 ], [ 29.764342784810125, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12858, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.435687068965517 ], [ 29.764612278481014, -1.435956551724138 ], [ 29.764881772151899, -1.435956551724138 ], [ 29.764881772151899, -1.435687068965517 ], [ 29.764612278481014, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12859, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.435687068965517 ], [ 29.764881772151899, -1.436226034482759 ], [ 29.765151265822784, -1.436226034482759 ], [ 29.765151265822784, -1.435687068965517 ], [ 29.764881772151899, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12860, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.435687068965517 ], [ 29.765420759493672, -1.435956551724138 ], [ 29.765959746835442, -1.435956551724138 ], [ 29.765959746835442, -1.435687068965517 ], [ 29.765420759493672, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12861, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.435687068965517 ], [ 29.765959746835442, -1.436226034482759 ], [ 29.766229240506327, -1.436226034482759 ], [ 29.766229240506327, -1.435687068965517 ], [ 29.765959746835442, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12862, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.435687068965517 ], [ 29.766498734177215, -1.435956551724138 ], [ 29.7667682278481, -1.435956551724138 ], [ 29.7667682278481, -1.435687068965517 ], [ 29.766498734177215, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12863, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.435687068965517 ], [ 29.7667682278481, -1.435956551724138 ], [ 29.767037721518985, -1.435956551724138 ], [ 29.767037721518985, -1.435687068965517 ], [ 29.7667682278481, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12864, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.435687068965517 ], [ 29.767307215189874, -1.435956551724138 ], [ 29.767576708860759, -1.435956551724138 ], [ 29.767576708860759, -1.435687068965517 ], [ 29.767307215189874, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12865, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.435687068965517 ], [ 29.767576708860759, -1.435956551724138 ], [ 29.767846202531643, -1.435956551724138 ], [ 29.767846202531643, -1.435687068965517 ], [ 29.767576708860759, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12866, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.435687068965517 ], [ 29.767846202531643, -1.436226034482759 ], [ 29.768115696202532, -1.436226034482759 ], [ 29.768115696202532, -1.435687068965517 ], [ 29.767846202531643, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12867, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.435687068965517 ], [ 29.768115696202532, -1.435956551724138 ], [ 29.768385189873417, -1.435956551724138 ], [ 29.768385189873417, -1.435687068965517 ], [ 29.768115696202532, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12868, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.435687068965517 ], [ 29.768385189873417, -1.435956551724138 ], [ 29.768654683544302, -1.435956551724138 ], [ 29.768654683544302, -1.435687068965517 ], [ 29.768385189873417, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12869, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.435687068965517 ], [ 29.768654683544302, -1.435956551724138 ], [ 29.76892417721519, -1.435956551724138 ], [ 29.76892417721519, -1.435687068965517 ], [ 29.768654683544302, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12870, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.435687068965517 ], [ 29.769193670886075, -1.435956551724138 ], [ 29.76946316455696, -1.435956551724138 ], [ 29.76946316455696, -1.435687068965517 ], [ 29.769193670886075, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12871, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.435687068965517 ], [ 29.76946316455696, -1.435956551724138 ], [ 29.769732658227849, -1.435956551724138 ], [ 29.769732658227849, -1.435687068965517 ], [ 29.76946316455696, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12872, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.435687068965517 ], [ 29.769732658227849, -1.436226034482759 ], [ 29.770002151898733, -1.436226034482759 ], [ 29.770002151898733, -1.435687068965517 ], [ 29.769732658227849, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12873, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.435687068965517 ], [ 29.770002151898733, -1.435956551724138 ], [ 29.770271645569618, -1.435956551724138 ], [ 29.770271645569618, -1.435687068965517 ], [ 29.770002151898733, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12874, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.435687068965517 ], [ 29.770271645569618, -1.435956551724138 ], [ 29.770541139240507, -1.435956551724138 ], [ 29.770541139240507, -1.435687068965517 ], [ 29.770271645569618, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12875, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.435687068965517 ], [ 29.770541139240507, -1.436226034482759 ], [ 29.770810632911392, -1.436226034482759 ], [ 29.770810632911392, -1.435687068965517 ], [ 29.770541139240507, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12876, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.435687068965517 ], [ 29.771080126582277, -1.435956551724138 ], [ 29.771349620253165, -1.435956551724138 ], [ 29.771349620253165, -1.435687068965517 ], [ 29.771080126582277, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12877, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.435687068965517 ], [ 29.771349620253165, -1.435956551724138 ], [ 29.77161911392405, -1.435956551724138 ], [ 29.77161911392405, -1.435687068965517 ], [ 29.771349620253165, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12878, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.435687068965517 ], [ 29.771888607594935, -1.435956551724138 ], [ 29.772158101265823, -1.435956551724138 ], [ 29.772158101265823, -1.435687068965517 ], [ 29.771888607594935, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12879, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.435687068965517 ], [ 29.772158101265823, -1.435956551724138 ], [ 29.772427594936708, -1.435956551724138 ], [ 29.772427594936708, -1.435687068965517 ], [ 29.772158101265823, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12880, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.435687068965517 ], [ 29.772427594936708, -1.436226034482759 ], [ 29.772697088607593, -1.436226034482759 ], [ 29.772697088607593, -1.435687068965517 ], [ 29.772427594936708, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12881, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.435687068965517 ], [ 29.772697088607593, -1.435956551724138 ], [ 29.772966582278482, -1.435956551724138 ], [ 29.772966582278482, -1.435687068965517 ], [ 29.772697088607593, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12882, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.435687068965517 ], [ 29.772966582278482, -1.435956551724138 ], [ 29.773236075949367, -1.435956551724138 ], [ 29.773236075949367, -1.435687068965517 ], [ 29.772966582278482, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12883, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.435687068965517 ], [ 29.773236075949367, -1.435956551724138 ], [ 29.773505569620252, -1.435956551724138 ], [ 29.773505569620252, -1.435687068965517 ], [ 29.773236075949367, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12884, "El": 149 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.435687068965517 ], [ 29.77377506329114, -1.436226034482759 ], [ 29.774044556962025, -1.436226034482759 ], [ 29.774044556962025, -1.435687068965517 ], [ 29.77377506329114, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12885, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.435687068965517 ], [ 29.774044556962025, -1.435956551724138 ], [ 29.77431405063291, -1.435956551724138 ], [ 29.77431405063291, -1.435687068965517 ], [ 29.774044556962025, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12886, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.435687068965517 ], [ 29.774853037974683, -1.436226034482759 ], [ 29.775122531645568, -1.436226034482759 ], [ 29.775122531645568, -1.435687068965517 ], [ 29.774853037974683, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12887, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.435687068965517 ], [ 29.775122531645568, -1.435956551724138 ], [ 29.775392025316457, -1.435956551724138 ], [ 29.775392025316457, -1.435687068965517 ], [ 29.775122531645568, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12888, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.435687068965517 ], [ 29.775392025316457, -1.435956551724138 ], [ 29.775661518987341, -1.435956551724138 ], [ 29.775661518987341, -1.435687068965517 ], [ 29.775392025316457, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12889, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.435687068965517 ], [ 29.775661518987341, -1.436226034482759 ], [ 29.775931012658226, -1.436226034482759 ], [ 29.775931012658226, -1.435687068965517 ], [ 29.775661518987341, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12890, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.435687068965517 ], [ 29.775931012658226, -1.435956551724138 ], [ 29.776200506329115, -1.435956551724138 ], [ 29.776200506329115, -1.435687068965517 ], [ 29.775931012658226, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12891, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.435687068965517 ], [ 29.776200506329115, -1.435956551724138 ], [ 29.77647, -1.435956551724138 ], [ 29.77647, -1.435687068965517 ], [ 29.776200506329115, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12892, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.435687068965517 ], [ 29.776739493670885, -1.435956551724138 ], [ 29.777008987341773, -1.435956551724138 ], [ 29.777008987341773, -1.435687068965517 ], [ 29.776739493670885, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12893, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.435687068965517 ], [ 29.777008987341773, -1.435956551724138 ], [ 29.777278481012658, -1.435956551724138 ], [ 29.777278481012658, -1.435687068965517 ], [ 29.777008987341773, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12894, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.435687068965517 ], [ 29.777278481012658, -1.435956551724138 ], [ 29.777547974683543, -1.435956551724138 ], [ 29.777547974683543, -1.435687068965517 ], [ 29.777278481012658, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12895, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.435687068965517 ], [ 29.777547974683543, -1.436226034482759 ], [ 29.777817468354431, -1.436226034482759 ], [ 29.777817468354431, -1.435687068965517 ], [ 29.777547974683543, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12896, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.435687068965517 ], [ 29.777817468354431, -1.435956551724138 ], [ 29.778086962025316, -1.435956551724138 ], [ 29.778086962025316, -1.435687068965517 ], [ 29.777817468354431, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12897, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.435687068965517 ], [ 29.778086962025316, -1.435956551724138 ], [ 29.778356455696201, -1.435956551724138 ], [ 29.778356455696201, -1.435687068965517 ], [ 29.778086962025316, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12898, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.435687068965517 ], [ 29.778356455696201, -1.435956551724138 ], [ 29.77862594936709, -1.435956551724138 ], [ 29.77862594936709, -1.435687068965517 ], [ 29.778356455696201, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12899, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.435687068965517 ], [ 29.77862594936709, -1.435956551724138 ], [ 29.778895443037975, -1.435956551724138 ], [ 29.778895443037975, -1.435687068965517 ], [ 29.77862594936709, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12900, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.435687068965517 ], [ 29.778895443037975, -1.435956551724138 ], [ 29.77916493670886, -1.435956551724138 ], [ 29.77916493670886, -1.435687068965517 ], [ 29.778895443037975, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12901, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.435687068965517 ], [ 29.77916493670886, -1.435956551724138 ], [ 29.779434430379748, -1.435956551724138 ], [ 29.779434430379748, -1.435687068965517 ], [ 29.77916493670886, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12902, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.435687068965517 ], [ 29.779434430379748, -1.435956551724138 ], [ 29.779703924050633, -1.435956551724138 ], [ 29.779703924050633, -1.435687068965517 ], [ 29.779434430379748, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12903, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.435687068965517 ], [ 29.779703924050633, -1.435956551724138 ], [ 29.779973417721518, -1.435956551724138 ], [ 29.779973417721518, -1.435687068965517 ], [ 29.779703924050633, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12904, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.435687068965517 ], [ 29.779973417721518, -1.435956551724138 ], [ 29.780242911392406, -1.435956551724138 ], [ 29.780242911392406, -1.435687068965517 ], [ 29.779973417721518, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12905, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.435687068965517 ], [ 29.780242911392406, -1.435956551724138 ], [ 29.780512405063291, -1.435956551724138 ], [ 29.780512405063291, -1.435687068965517 ], [ 29.780242911392406, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12906, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.435687068965517 ], [ 29.780512405063291, -1.435956551724138 ], [ 29.780781898734176, -1.435956551724138 ], [ 29.780781898734176, -1.435687068965517 ], [ 29.780512405063291, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12907, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.435687068965517 ], [ 29.780781898734176, -1.435956551724138 ], [ 29.781051392405065, -1.435956551724138 ], [ 29.781051392405065, -1.435687068965517 ], [ 29.780781898734176, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12908, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.435687068965517 ], [ 29.781051392405065, -1.435956551724138 ], [ 29.78132088607595, -1.435956551724138 ], [ 29.78132088607595, -1.435687068965517 ], [ 29.781051392405065, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12909, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.435687068965517 ], [ 29.78132088607595, -1.435956551724138 ], [ 29.781590379746834, -1.435956551724138 ], [ 29.781590379746834, -1.435687068965517 ], [ 29.78132088607595, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12910, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.435687068965517 ], [ 29.781590379746834, -1.435956551724138 ], [ 29.781859873417723, -1.435956551724138 ], [ 29.781859873417723, -1.435687068965517 ], [ 29.781590379746834, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12911, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.435687068965517 ], [ 29.781859873417723, -1.435956551724138 ], [ 29.782129367088608, -1.435956551724138 ], [ 29.782129367088608, -1.435687068965517 ], [ 29.781859873417723, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12912, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.435687068965517 ], [ 29.782129367088608, -1.435956551724138 ], [ 29.782398860759493, -1.435956551724138 ], [ 29.782398860759493, -1.435687068965517 ], [ 29.782129367088608, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12913, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.435687068965517 ], [ 29.782398860759493, -1.435956551724138 ], [ 29.782668354430381, -1.435956551724138 ], [ 29.782668354430381, -1.435687068965517 ], [ 29.782398860759493, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12914, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.435687068965517 ], [ 29.782668354430381, -1.435956551724138 ], [ 29.782937848101266, -1.435956551724138 ], [ 29.782937848101266, -1.435687068965517 ], [ 29.782668354430381, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12915, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.435687068965517 ], [ 29.782937848101266, -1.435956551724138 ], [ 29.783207341772151, -1.435956551724138 ], [ 29.783207341772151, -1.435687068965517 ], [ 29.782937848101266, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12916, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.435687068965517 ], [ 29.783207341772151, -1.435956551724138 ], [ 29.783476835443039, -1.435956551724138 ], [ 29.783476835443039, -1.435687068965517 ], [ 29.783207341772151, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12917, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.435687068965517 ], [ 29.783476835443039, -1.435956551724138 ], [ 29.784015822784809, -1.435956551724138 ], [ 29.784015822784809, -1.435687068965517 ], [ 29.783476835443039, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12918, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.435687068965517 ], [ 29.784015822784809, -1.435956551724138 ], [ 29.784285316455698, -1.435956551724138 ], [ 29.784285316455698, -1.435687068965517 ], [ 29.784015822784809, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12919, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.435687068965517 ], [ 29.784285316455698, -1.435956551724138 ], [ 29.784554810126583, -1.435956551724138 ], [ 29.784554810126583, -1.435687068965517 ], [ 29.784285316455698, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12920, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.435687068965517 ], [ 29.784554810126583, -1.435956551724138 ], [ 29.784824303797468, -1.435956551724138 ], [ 29.784824303797468, -1.435687068965517 ], [ 29.784554810126583, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12921, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.435687068965517 ], [ 29.784824303797468, -1.435956551724138 ], [ 29.785093797468356, -1.435956551724138 ], [ 29.785093797468356, -1.435687068965517 ], [ 29.784824303797468, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12922, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.435687068965517 ], [ 29.785093797468356, -1.435956551724138 ], [ 29.785363291139241, -1.435956551724138 ], [ 29.785363291139241, -1.435687068965517 ], [ 29.785093797468356, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12923, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.435687068965517 ], [ 29.785363291139241, -1.435956551724138 ], [ 29.786441265822784, -1.435956551724138 ], [ 29.786441265822784, -1.435687068965517 ], [ 29.785363291139241, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12924, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.435687068965517 ], [ 29.786441265822784, -1.435956551724138 ], [ 29.786710759493673, -1.435956551724138 ], [ 29.786710759493673, -1.435687068965517 ], [ 29.786441265822784, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12925, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.435687068965517 ], [ 29.786710759493673, -1.435956551724138 ], [ 29.786980253164558, -1.435956551724138 ], [ 29.786980253164558, -1.435687068965517 ], [ 29.786710759493673, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12926, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.435687068965517 ], [ 29.786980253164558, -1.435956551724138 ], [ 29.787249746835442, -1.435956551724138 ], [ 29.787249746835442, -1.435687068965517 ], [ 29.786980253164558, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12927, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.435687068965517 ], [ 29.787249746835442, -1.435956551724138 ], [ 29.789405696202532, -1.435956551724138 ], [ 29.789405696202532, -1.435687068965517 ], [ 29.787249746835442, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12928, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.435687068965517 ], [ 29.789405696202532, -1.435956551724138 ], [ 29.789675189873417, -1.435956551724138 ], [ 29.789675189873417, -1.435687068965517 ], [ 29.789405696202532, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12929, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.435687068965517 ], [ 29.789675189873417, -1.435956551724138 ], [ 29.789944683544302, -1.435956551724138 ], [ 29.789944683544302, -1.435687068965517 ], [ 29.789675189873417, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12930, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.435687068965517 ], [ 29.789944683544302, -1.435956551724138 ], [ 29.790214177215191, -1.435956551724138 ], [ 29.790214177215191, -1.435687068965517 ], [ 29.789944683544302, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12931, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.435687068965517 ], [ 29.790214177215191, -1.435956551724138 ], [ 29.790483670886076, -1.435956551724138 ], [ 29.790483670886076, -1.435687068965517 ], [ 29.790214177215191, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12932, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.435687068965517 ], [ 29.790483670886076, -1.435956551724138 ], [ 29.790753164556961, -1.435956551724138 ], [ 29.790753164556961, -1.435687068965517 ], [ 29.790483670886076, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12933, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.435687068965517 ], [ 29.790753164556961, -1.435956551724138 ], [ 29.791022658227849, -1.435956551724138 ], [ 29.791022658227849, -1.435687068965517 ], [ 29.790753164556961, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12934, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.435687068965517 ], [ 29.791022658227849, -1.435956551724138 ], [ 29.791292151898734, -1.435956551724138 ], [ 29.791292151898734, -1.435687068965517 ], [ 29.791022658227849, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12935, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.435687068965517 ], [ 29.791292151898734, -1.435956551724138 ], [ 29.791561645569619, -1.435956551724138 ], [ 29.791561645569619, -1.435687068965517 ], [ 29.791292151898734, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12936, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.435687068965517 ], [ 29.791561645569619, -1.435956551724138 ], [ 29.791831139240507, -1.435956551724138 ], [ 29.791831139240507, -1.435687068965517 ], [ 29.791561645569619, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12937, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.435687068965517 ], [ 29.791831139240507, -1.435956551724138 ], [ 29.792100632911392, -1.435956551724138 ], [ 29.792100632911392, -1.435687068965517 ], [ 29.791831139240507, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12938, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.435687068965517 ], [ 29.792100632911392, -1.435956551724138 ], [ 29.792370126582277, -1.435956551724138 ], [ 29.792370126582277, -1.435687068965517 ], [ 29.792100632911392, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12939, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.435687068965517 ], [ 29.792370126582277, -1.435956551724138 ], [ 29.792909113924051, -1.435956551724138 ], [ 29.792909113924051, -1.435687068965517 ], [ 29.792370126582277, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12940, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.435687068965517 ], [ 29.792909113924051, -1.435956551724138 ], [ 29.793178607594935, -1.435956551724138 ], [ 29.793178607594935, -1.435687068965517 ], [ 29.792909113924051, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12941, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.435687068965517 ], [ 29.793178607594935, -1.435956551724138 ], [ 29.793448101265824, -1.435956551724138 ], [ 29.793448101265824, -1.435687068965517 ], [ 29.793178607594935, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12942, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.435687068965517 ], [ 29.793448101265824, -1.435956551724138 ], [ 29.793717594936709, -1.435956551724138 ], [ 29.793717594936709, -1.435687068965517 ], [ 29.793448101265824, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12943, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.435687068965517 ], [ 29.793717594936709, -1.435956551724138 ], [ 29.793987088607594, -1.435956551724138 ], [ 29.793987088607594, -1.435687068965517 ], [ 29.793717594936709, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12944, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.435687068965517 ], [ 29.793987088607594, -1.435956551724138 ], [ 29.794256582278482, -1.435956551724138 ], [ 29.794256582278482, -1.435687068965517 ], [ 29.793987088607594, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12945, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.435687068965517 ], [ 29.794256582278482, -1.435956551724138 ], [ 29.794526075949367, -1.435956551724138 ], [ 29.794526075949367, -1.435687068965517 ], [ 29.794256582278482, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12946, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.435687068965517 ], [ 29.794526075949367, -1.435956551724138 ], [ 29.794795569620252, -1.435956551724138 ], [ 29.794795569620252, -1.435687068965517 ], [ 29.794526075949367, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12947, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.435687068965517 ], [ 29.794795569620252, -1.435956551724138 ], [ 29.79506506329114, -1.435956551724138 ], [ 29.79506506329114, -1.435687068965517 ], [ 29.794795569620252, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12948, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.435687068965517 ], [ 29.79506506329114, -1.435956551724138 ], [ 29.795334556962025, -1.435956551724138 ], [ 29.795334556962025, -1.435687068965517 ], [ 29.79506506329114, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12949, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.435687068965517 ], [ 29.795334556962025, -1.435956551724138 ], [ 29.79560405063291, -1.435956551724138 ], [ 29.79560405063291, -1.435687068965517 ], [ 29.795334556962025, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12950, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.435687068965517 ], [ 29.79560405063291, -1.435956551724138 ], [ 29.795873544303799, -1.435956551724138 ], [ 29.795873544303799, -1.435687068965517 ], [ 29.79560405063291, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12951, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.435687068965517 ], [ 29.795873544303799, -1.435956551724138 ], [ 29.796143037974684, -1.435956551724138 ], [ 29.796143037974684, -1.435687068965517 ], [ 29.795873544303799, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12952, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.435687068965517 ], [ 29.796143037974684, -1.435956551724138 ], [ 29.796412531645569, -1.435956551724138 ], [ 29.796412531645569, -1.435687068965517 ], [ 29.796143037974684, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12953, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.435687068965517 ], [ 29.796412531645569, -1.435956551724138 ], [ 29.796682025316457, -1.435956551724138 ], [ 29.796682025316457, -1.435687068965517 ], [ 29.796412531645569, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12954, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.435687068965517 ], [ 29.796682025316457, -1.435956551724138 ], [ 29.79776, -1.435956551724138 ], [ 29.79776, -1.435687068965517 ], [ 29.796682025316457, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12955, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.435687068965517 ], [ 29.79776, -1.435956551724138 ], [ 29.798029493670885, -1.435956551724138 ], [ 29.798029493670885, -1.435687068965517 ], [ 29.79776, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12956, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.435687068965517 ], [ 29.798029493670885, -1.435956551724138 ], [ 29.798298987341774, -1.435956551724138 ], [ 29.798298987341774, -1.435687068965517 ], [ 29.798029493670885, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12957, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.435687068965517 ], [ 29.798298987341774, -1.435956551724138 ], [ 29.798568481012659, -1.435956551724138 ], [ 29.798568481012659, -1.435687068965517 ], [ 29.798298987341774, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12958, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.435687068965517 ], [ 29.798568481012659, -1.435956551724138 ], [ 29.798837974683543, -1.435956551724138 ], [ 29.798837974683543, -1.435687068965517 ], [ 29.798568481012659, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12959, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.435687068965517 ], [ 29.798837974683543, -1.435956551724138 ], [ 29.799107468354432, -1.435956551724138 ], [ 29.799107468354432, -1.435687068965517 ], [ 29.798837974683543, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12960, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.435687068965517 ], [ 29.799107468354432, -1.435956551724138 ], [ 29.799376962025317, -1.435956551724138 ], [ 29.799376962025317, -1.435687068965517 ], [ 29.799107468354432, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12961, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.435687068965517 ], [ 29.799376962025317, -1.435956551724138 ], [ 29.799646455696202, -1.435956551724138 ], [ 29.799646455696202, -1.435687068965517 ], [ 29.799376962025317, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12962, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.435687068965517 ], [ 29.799646455696202, -1.435956551724138 ], [ 29.79991594936709, -1.435956551724138 ], [ 29.79991594936709, -1.435687068965517 ], [ 29.799646455696202, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12963, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.435687068965517 ], [ 29.79991594936709, -1.435956551724138 ], [ 29.800185443037975, -1.435956551724138 ], [ 29.800185443037975, -1.435687068965517 ], [ 29.79991594936709, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12964, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.435687068965517 ], [ 29.800185443037975, -1.435956551724138 ], [ 29.80045493670886, -1.435956551724138 ], [ 29.80045493670886, -1.435687068965517 ], [ 29.800185443037975, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12965, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.435687068965517 ], [ 29.80045493670886, -1.435956551724138 ], [ 29.800724430379748, -1.435956551724138 ], [ 29.800724430379748, -1.435687068965517 ], [ 29.80045493670886, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12966, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.435687068965517 ], [ 29.800724430379748, -1.436226034482759 ], [ 29.800993924050633, -1.436226034482759 ], [ 29.800993924050633, -1.435687068965517 ], [ 29.800724430379748, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12967, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.435687068965517 ], [ 29.800993924050633, -1.435956551724138 ], [ 29.801263417721518, -1.435956551724138 ], [ 29.801263417721518, -1.435687068965517 ], [ 29.800993924050633, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12968, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.435687068965517 ], [ 29.801263417721518, -1.435956551724138 ], [ 29.801532911392407, -1.435956551724138 ], [ 29.801532911392407, -1.435687068965517 ], [ 29.801263417721518, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12969, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.435687068965517 ], [ 29.801532911392407, -1.436495517241379 ], [ 29.802071898734177, -1.436495517241379 ], [ 29.802071898734177, -1.435687068965517 ], [ 29.801532911392407, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12970, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.435687068965517 ], [ 29.802071898734177, -1.436495517241379 ], [ 29.802341392405065, -1.436495517241379 ], [ 29.802341392405065, -1.435687068965517 ], [ 29.802071898734177, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12971, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.435687068965517 ], [ 29.806653291139241, -1.435956551724138 ], [ 29.807731265822785, -1.435956551724138 ], [ 29.807731265822785, -1.435687068965517 ], [ 29.806653291139241, -1.435687068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12972, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.435956551724138 ], [ 29.744669746835442, -1.436226034482759 ], [ 29.744939240506326, -1.436226034482759 ], [ 29.744939240506326, -1.435956551724138 ], [ 29.744669746835442, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12973, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.435956551724138 ], [ 29.744939240506326, -1.436226034482759 ], [ 29.745208734177215, -1.436226034482759 ], [ 29.745208734177215, -1.435956551724138 ], [ 29.744939240506326, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12974, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.435956551724138 ], [ 29.745208734177215, -1.436226034482759 ], [ 29.7454782278481, -1.436226034482759 ], [ 29.7454782278481, -1.435956551724138 ], [ 29.745208734177215, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12975, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.435956551724138 ], [ 29.7454782278481, -1.436226034482759 ], [ 29.745747721518985, -1.436226034482759 ], [ 29.745747721518985, -1.435956551724138 ], [ 29.7454782278481, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12976, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.435956551724138 ], [ 29.746286708860758, -1.436495517241379 ], [ 29.746556202531643, -1.436495517241379 ], [ 29.746556202531643, -1.435956551724138 ], [ 29.746286708860758, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12977, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.435956551724138 ], [ 29.747364683544301, -1.436226034482759 ], [ 29.747903670886075, -1.436226034482759 ], [ 29.747903670886075, -1.435956551724138 ], [ 29.747364683544301, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12978, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.435956551724138 ], [ 29.747903670886075, -1.436226034482759 ], [ 29.748442658227848, -1.436226034482759 ], [ 29.748442658227848, -1.435956551724138 ], [ 29.747903670886075, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12979, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.435956551724138 ], [ 29.748442658227848, -1.436226034482759 ], [ 29.748712151898733, -1.436226034482759 ], [ 29.748712151898733, -1.435956551724138 ], [ 29.748442658227848, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12980, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.435956551724138 ], [ 29.748712151898733, -1.436226034482759 ], [ 29.748981645569618, -1.436226034482759 ], [ 29.748981645569618, -1.435956551724138 ], [ 29.748712151898733, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12981, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.435956551724138 ], [ 29.748981645569618, -1.436226034482759 ], [ 29.749251139240506, -1.436226034482759 ], [ 29.749251139240506, -1.435956551724138 ], [ 29.748981645569618, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12982, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.435956551724138 ], [ 29.749251139240506, -1.436226034482759 ], [ 29.749520632911391, -1.436226034482759 ], [ 29.749520632911391, -1.435956551724138 ], [ 29.749251139240506, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12983, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.435956551724138 ], [ 29.749520632911391, -1.436226034482759 ], [ 29.749790126582276, -1.436226034482759 ], [ 29.749790126582276, -1.435956551724138 ], [ 29.749520632911391, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12984, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.435956551724138 ], [ 29.749790126582276, -1.436226034482759 ], [ 29.750059620253165, -1.436226034482759 ], [ 29.750059620253165, -1.435956551724138 ], [ 29.749790126582276, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12985, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.435956551724138 ], [ 29.750059620253165, -1.436226034482759 ], [ 29.75032911392405, -1.436226034482759 ], [ 29.75032911392405, -1.435956551724138 ], [ 29.750059620253165, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12986, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.435956551724138 ], [ 29.75032911392405, -1.436226034482759 ], [ 29.750598607594934, -1.436226034482759 ], [ 29.750598607594934, -1.435956551724138 ], [ 29.75032911392405, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12987, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.435956551724138 ], [ 29.750598607594934, -1.436226034482759 ], [ 29.751137594936708, -1.436226034482759 ], [ 29.751137594936708, -1.435956551724138 ], [ 29.750598607594934, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12988, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.435956551724138 ], [ 29.751137594936708, -1.436226034482759 ], [ 29.754102025316456, -1.436226034482759 ], [ 29.754102025316456, -1.435956551724138 ], [ 29.751137594936708, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12989, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.435956551724138 ], [ 29.754102025316456, -1.436226034482759 ], [ 29.754641012658226, -1.436226034482759 ], [ 29.754641012658226, -1.435956551724138 ], [ 29.754102025316456, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12990, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.435956551724138 ], [ 29.754641012658226, -1.436226034482759 ], [ 29.754910506329114, -1.436226034482759 ], [ 29.754910506329114, -1.435956551724138 ], [ 29.754641012658226, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12991, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.435956551724138 ], [ 29.754910506329114, -1.436226034482759 ], [ 29.75518, -1.436226034482759 ], [ 29.75518, -1.435956551724138 ], [ 29.754910506329114, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12992, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.435956551724138 ], [ 29.75518, -1.436226034482759 ], [ 29.755449493670884, -1.436226034482759 ], [ 29.755449493670884, -1.435956551724138 ], [ 29.75518, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12993, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.435956551724138 ], [ 29.755449493670884, -1.436226034482759 ], [ 29.755718987341773, -1.436226034482759 ], [ 29.755718987341773, -1.435956551724138 ], [ 29.755449493670884, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12994, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.435956551724138 ], [ 29.755718987341773, -1.436226034482759 ], [ 29.755988481012658, -1.436226034482759 ], [ 29.755988481012658, -1.435956551724138 ], [ 29.755718987341773, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12995, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.435956551724138 ], [ 29.762186835443039, -1.436226034482759 ], [ 29.762456329113924, -1.436226034482759 ], [ 29.762456329113924, -1.435956551724138 ], [ 29.762186835443039, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12996, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.435956551724138 ], [ 29.762456329113924, -1.436226034482759 ], [ 29.762725822784809, -1.436226034482759 ], [ 29.762725822784809, -1.435956551724138 ], [ 29.762456329113924, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12997, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.435956551724138 ], [ 29.762725822784809, -1.436226034482759 ], [ 29.762995316455697, -1.436226034482759 ], [ 29.762995316455697, -1.435956551724138 ], [ 29.762725822784809, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12998, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.435956551724138 ], [ 29.762995316455697, -1.436495517241379 ], [ 29.763264810126582, -1.436495517241379 ], [ 29.763264810126582, -1.435956551724138 ], [ 29.762995316455697, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 12999, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.435956551724138 ], [ 29.763264810126582, -1.436226034482759 ], [ 29.763534303797467, -1.436226034482759 ], [ 29.763534303797467, -1.435956551724138 ], [ 29.763264810126582, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13000, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.435956551724138 ], [ 29.763534303797467, -1.436226034482759 ], [ 29.763803797468356, -1.436226034482759 ], [ 29.763803797468356, -1.435956551724138 ], [ 29.763534303797467, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13001, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.435956551724138 ], [ 29.763803797468356, -1.436495517241379 ], [ 29.76407329113924, -1.436495517241379 ], [ 29.76407329113924, -1.435956551724138 ], [ 29.763803797468356, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13002, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.435956551724138 ], [ 29.764342784810125, -1.436495517241379 ], [ 29.764612278481014, -1.436495517241379 ], [ 29.764612278481014, -1.435956551724138 ], [ 29.764342784810125, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13003, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.435956551724138 ], [ 29.764612278481014, -1.436495517241379 ], [ 29.764881772151899, -1.436495517241379 ], [ 29.764881772151899, -1.435956551724138 ], [ 29.764612278481014, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13004, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.435956551724138 ], [ 29.765151265822784, -1.436226034482759 ], [ 29.765420759493672, -1.436226034482759 ], [ 29.765420759493672, -1.435956551724138 ], [ 29.765151265822784, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13005, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.435956551724138 ], [ 29.765420759493672, -1.436495517241379 ], [ 29.765959746835442, -1.436495517241379 ], [ 29.765959746835442, -1.435956551724138 ], [ 29.765420759493672, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13006, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.435956551724138 ], [ 29.766229240506327, -1.436226034482759 ], [ 29.766498734177215, -1.436226034482759 ], [ 29.766498734177215, -1.435956551724138 ], [ 29.766229240506327, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13007, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.435956551724138 ], [ 29.766498734177215, -1.436226034482759 ], [ 29.7667682278481, -1.436226034482759 ], [ 29.7667682278481, -1.435956551724138 ], [ 29.766498734177215, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13008, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.435956551724138 ], [ 29.7667682278481, -1.436495517241379 ], [ 29.767037721518985, -1.436495517241379 ], [ 29.767037721518985, -1.435956551724138 ], [ 29.7667682278481, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13009, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.435956551724138 ], [ 29.767037721518985, -1.436226034482759 ], [ 29.767307215189874, -1.436226034482759 ], [ 29.767307215189874, -1.435956551724138 ], [ 29.767037721518985, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13010, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.435956551724138 ], [ 29.767307215189874, -1.436226034482759 ], [ 29.767576708860759, -1.436226034482759 ], [ 29.767576708860759, -1.435956551724138 ], [ 29.767307215189874, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13011, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.435956551724138 ], [ 29.767576708860759, -1.436226034482759 ], [ 29.767846202531643, -1.436226034482759 ], [ 29.767846202531643, -1.435956551724138 ], [ 29.767576708860759, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13012, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.435956551724138 ], [ 29.768115696202532, -1.436226034482759 ], [ 29.768385189873417, -1.436226034482759 ], [ 29.768385189873417, -1.435956551724138 ], [ 29.768115696202532, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13013, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.435956551724138 ], [ 29.768385189873417, -1.436226034482759 ], [ 29.768654683544302, -1.436226034482759 ], [ 29.768654683544302, -1.435956551724138 ], [ 29.768385189873417, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13014, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.435956551724138 ], [ 29.768654683544302, -1.436495517241379 ], [ 29.76892417721519, -1.436495517241379 ], [ 29.76892417721519, -1.435956551724138 ], [ 29.768654683544302, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13015, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.435956551724138 ], [ 29.76892417721519, -1.436226034482759 ], [ 29.769193670886075, -1.436226034482759 ], [ 29.769193670886075, -1.435956551724138 ], [ 29.76892417721519, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13016, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.435956551724138 ], [ 29.769193670886075, -1.436226034482759 ], [ 29.76946316455696, -1.436226034482759 ], [ 29.76946316455696, -1.435956551724138 ], [ 29.769193670886075, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13017, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.435956551724138 ], [ 29.76946316455696, -1.436495517241379 ], [ 29.769732658227849, -1.436495517241379 ], [ 29.769732658227849, -1.435956551724138 ], [ 29.76946316455696, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13018, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.435956551724138 ], [ 29.770002151898733, -1.436226034482759 ], [ 29.770271645569618, -1.436226034482759 ], [ 29.770271645569618, -1.435956551724138 ], [ 29.770002151898733, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13019, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.435956551724138 ], [ 29.770271645569618, -1.436226034482759 ], [ 29.770541139240507, -1.436226034482759 ], [ 29.770541139240507, -1.435956551724138 ], [ 29.770271645569618, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13020, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.435956551724138 ], [ 29.770810632911392, -1.436226034482759 ], [ 29.771080126582277, -1.436226034482759 ], [ 29.771080126582277, -1.435956551724138 ], [ 29.770810632911392, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13021, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.435956551724138 ], [ 29.771080126582277, -1.436226034482759 ], [ 29.771349620253165, -1.436226034482759 ], [ 29.771349620253165, -1.435956551724138 ], [ 29.771080126582277, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13022, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.435956551724138 ], [ 29.771349620253165, -1.436495517241379 ], [ 29.77161911392405, -1.436495517241379 ], [ 29.77161911392405, -1.435956551724138 ], [ 29.771349620253165, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13023, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.435956551724138 ], [ 29.77161911392405, -1.436226034482759 ], [ 29.771888607594935, -1.436226034482759 ], [ 29.771888607594935, -1.435956551724138 ], [ 29.77161911392405, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13024, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.435956551724138 ], [ 29.771888607594935, -1.436226034482759 ], [ 29.772158101265823, -1.436226034482759 ], [ 29.772158101265823, -1.435956551724138 ], [ 29.771888607594935, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13025, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.435956551724138 ], [ 29.772158101265823, -1.436226034482759 ], [ 29.772427594936708, -1.436226034482759 ], [ 29.772427594936708, -1.435956551724138 ], [ 29.772158101265823, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13026, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.435956551724138 ], [ 29.772697088607593, -1.436226034482759 ], [ 29.772966582278482, -1.436226034482759 ], [ 29.772966582278482, -1.435956551724138 ], [ 29.772697088607593, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13027, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.435956551724138 ], [ 29.772966582278482, -1.436226034482759 ], [ 29.773236075949367, -1.436226034482759 ], [ 29.773236075949367, -1.435956551724138 ], [ 29.772966582278482, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13028, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.435956551724138 ], [ 29.773236075949367, -1.436495517241379 ], [ 29.773505569620252, -1.436495517241379 ], [ 29.773505569620252, -1.435956551724138 ], [ 29.773236075949367, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13029, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.435956551724138 ], [ 29.773505569620252, -1.436226034482759 ], [ 29.77377506329114, -1.436226034482759 ], [ 29.77377506329114, -1.435956551724138 ], [ 29.773505569620252, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13030, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.435956551724138 ], [ 29.774044556962025, -1.436495517241379 ], [ 29.77431405063291, -1.436495517241379 ], [ 29.77431405063291, -1.435956551724138 ], [ 29.774044556962025, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13031, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.435956551724138 ], [ 29.77431405063291, -1.436226034482759 ], [ 29.774853037974683, -1.436226034482759 ], [ 29.774853037974683, -1.435956551724138 ], [ 29.77431405063291, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13032, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.435956551724138 ], [ 29.775122531645568, -1.436495517241379 ], [ 29.775392025316457, -1.436495517241379 ], [ 29.775392025316457, -1.435956551724138 ], [ 29.775122531645568, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13033, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.435956551724138 ], [ 29.775392025316457, -1.436226034482759 ], [ 29.775661518987341, -1.436226034482759 ], [ 29.775661518987341, -1.435956551724138 ], [ 29.775392025316457, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13034, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.435956551724138 ], [ 29.775931012658226, -1.436495517241379 ], [ 29.776200506329115, -1.436495517241379 ], [ 29.776200506329115, -1.435956551724138 ], [ 29.775931012658226, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13035, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.435956551724138 ], [ 29.776200506329115, -1.436226034482759 ], [ 29.77647, -1.436226034482759 ], [ 29.77647, -1.435956551724138 ], [ 29.776200506329115, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13036, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.435956551724138 ], [ 29.77647, -1.436226034482759 ], [ 29.776739493670885, -1.436226034482759 ], [ 29.776739493670885, -1.435956551724138 ], [ 29.77647, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13037, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.435956551724138 ], [ 29.776739493670885, -1.436495517241379 ], [ 29.777008987341773, -1.436495517241379 ], [ 29.777008987341773, -1.435956551724138 ], [ 29.776739493670885, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13038, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.435956551724138 ], [ 29.777008987341773, -1.436226034482759 ], [ 29.777278481012658, -1.436226034482759 ], [ 29.777278481012658, -1.435956551724138 ], [ 29.777008987341773, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13039, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.435956551724138 ], [ 29.777278481012658, -1.436226034482759 ], [ 29.777547974683543, -1.436226034482759 ], [ 29.777547974683543, -1.435956551724138 ], [ 29.777278481012658, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13040, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.435956551724138 ], [ 29.777817468354431, -1.436226034482759 ], [ 29.778086962025316, -1.436226034482759 ], [ 29.778086962025316, -1.435956551724138 ], [ 29.777817468354431, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13041, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.435956551724138 ], [ 29.778086962025316, -1.436226034482759 ], [ 29.778356455696201, -1.436226034482759 ], [ 29.778356455696201, -1.435956551724138 ], [ 29.778086962025316, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13042, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.435956551724138 ], [ 29.778356455696201, -1.436226034482759 ], [ 29.77862594936709, -1.436226034482759 ], [ 29.77862594936709, -1.435956551724138 ], [ 29.778356455696201, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13043, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.435956551724138 ], [ 29.77862594936709, -1.436495517241379 ], [ 29.778895443037975, -1.436495517241379 ], [ 29.778895443037975, -1.435956551724138 ], [ 29.77862594936709, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13044, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.435956551724138 ], [ 29.778895443037975, -1.436226034482759 ], [ 29.77916493670886, -1.436226034482759 ], [ 29.77916493670886, -1.435956551724138 ], [ 29.778895443037975, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13045, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.435956551724138 ], [ 29.77916493670886, -1.436226034482759 ], [ 29.779434430379748, -1.436226034482759 ], [ 29.779434430379748, -1.435956551724138 ], [ 29.77916493670886, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13046, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.435956551724138 ], [ 29.779434430379748, -1.436226034482759 ], [ 29.779703924050633, -1.436226034482759 ], [ 29.779703924050633, -1.435956551724138 ], [ 29.779434430379748, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13047, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.435956551724138 ], [ 29.779703924050633, -1.436226034482759 ], [ 29.779973417721518, -1.436226034482759 ], [ 29.779973417721518, -1.435956551724138 ], [ 29.779703924050633, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13048, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.435956551724138 ], [ 29.779973417721518, -1.436226034482759 ], [ 29.780242911392406, -1.436226034482759 ], [ 29.780242911392406, -1.435956551724138 ], [ 29.779973417721518, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13049, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.435956551724138 ], [ 29.780242911392406, -1.436226034482759 ], [ 29.780512405063291, -1.436226034482759 ], [ 29.780512405063291, -1.435956551724138 ], [ 29.780242911392406, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13050, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.435956551724138 ], [ 29.780512405063291, -1.436226034482759 ], [ 29.780781898734176, -1.436226034482759 ], [ 29.780781898734176, -1.435956551724138 ], [ 29.780512405063291, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13051, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.435956551724138 ], [ 29.780781898734176, -1.436226034482759 ], [ 29.781051392405065, -1.436226034482759 ], [ 29.781051392405065, -1.435956551724138 ], [ 29.780781898734176, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13052, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.435956551724138 ], [ 29.781051392405065, -1.436226034482759 ], [ 29.78132088607595, -1.436226034482759 ], [ 29.78132088607595, -1.435956551724138 ], [ 29.781051392405065, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13053, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.435956551724138 ], [ 29.78132088607595, -1.436226034482759 ], [ 29.781590379746834, -1.436226034482759 ], [ 29.781590379746834, -1.435956551724138 ], [ 29.78132088607595, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13054, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.435956551724138 ], [ 29.781590379746834, -1.436226034482759 ], [ 29.781859873417723, -1.436226034482759 ], [ 29.781859873417723, -1.435956551724138 ], [ 29.781590379746834, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13055, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.435956551724138 ], [ 29.781859873417723, -1.436226034482759 ], [ 29.782129367088608, -1.436226034482759 ], [ 29.782129367088608, -1.435956551724138 ], [ 29.781859873417723, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13056, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.435956551724138 ], [ 29.782129367088608, -1.436226034482759 ], [ 29.782398860759493, -1.436226034482759 ], [ 29.782398860759493, -1.435956551724138 ], [ 29.782129367088608, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13057, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.435956551724138 ], [ 29.782398860759493, -1.436226034482759 ], [ 29.782668354430381, -1.436226034482759 ], [ 29.782668354430381, -1.435956551724138 ], [ 29.782398860759493, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13058, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.435956551724138 ], [ 29.782668354430381, -1.436226034482759 ], [ 29.782937848101266, -1.436226034482759 ], [ 29.782937848101266, -1.435956551724138 ], [ 29.782668354430381, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13059, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.435956551724138 ], [ 29.782937848101266, -1.436226034482759 ], [ 29.783207341772151, -1.436226034482759 ], [ 29.783207341772151, -1.435956551724138 ], [ 29.782937848101266, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13060, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.435956551724138 ], [ 29.783207341772151, -1.436226034482759 ], [ 29.783476835443039, -1.436226034482759 ], [ 29.783476835443039, -1.435956551724138 ], [ 29.783207341772151, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13061, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.435956551724138 ], [ 29.783476835443039, -1.436226034482759 ], [ 29.784015822784809, -1.436226034482759 ], [ 29.784015822784809, -1.435956551724138 ], [ 29.783476835443039, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13062, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.435956551724138 ], [ 29.784015822784809, -1.436226034482759 ], [ 29.784285316455698, -1.436226034482759 ], [ 29.784285316455698, -1.435956551724138 ], [ 29.784015822784809, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13063, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.435956551724138 ], [ 29.784285316455698, -1.436226034482759 ], [ 29.784554810126583, -1.436226034482759 ], [ 29.784554810126583, -1.435956551724138 ], [ 29.784285316455698, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13064, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.435956551724138 ], [ 29.784554810126583, -1.436226034482759 ], [ 29.784824303797468, -1.436226034482759 ], [ 29.784824303797468, -1.435956551724138 ], [ 29.784554810126583, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13065, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.435956551724138 ], [ 29.784824303797468, -1.436226034482759 ], [ 29.785093797468356, -1.436226034482759 ], [ 29.785093797468356, -1.435956551724138 ], [ 29.784824303797468, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13066, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.435956551724138 ], [ 29.785093797468356, -1.436226034482759 ], [ 29.785363291139241, -1.436226034482759 ], [ 29.785363291139241, -1.435956551724138 ], [ 29.785093797468356, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13067, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.435956551724138 ], [ 29.785363291139241, -1.436226034482759 ], [ 29.785632784810126, -1.436226034482759 ], [ 29.785632784810126, -1.435956551724138 ], [ 29.785363291139241, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13068, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.435956551724138 ], [ 29.785632784810126, -1.436226034482759 ], [ 29.786710759493673, -1.436226034482759 ], [ 29.786710759493673, -1.435956551724138 ], [ 29.785632784810126, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13069, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.435956551724138 ], [ 29.786710759493673, -1.436226034482759 ], [ 29.786980253164558, -1.436226034482759 ], [ 29.786980253164558, -1.435956551724138 ], [ 29.786710759493673, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13070, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.435956551724138 ], [ 29.786980253164558, -1.436226034482759 ], [ 29.787249746835442, -1.436226034482759 ], [ 29.787249746835442, -1.435956551724138 ], [ 29.786980253164558, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13071, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.435956551724138 ], [ 29.787249746835442, -1.436226034482759 ], [ 29.789405696202532, -1.436226034482759 ], [ 29.789405696202532, -1.435956551724138 ], [ 29.787249746835442, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13072, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.435956551724138 ], [ 29.789405696202532, -1.436226034482759 ], [ 29.789675189873417, -1.436226034482759 ], [ 29.789675189873417, -1.435956551724138 ], [ 29.789405696202532, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13073, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.435956551724138 ], [ 29.789675189873417, -1.436226034482759 ], [ 29.789944683544302, -1.436226034482759 ], [ 29.789944683544302, -1.435956551724138 ], [ 29.789675189873417, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13074, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.435956551724138 ], [ 29.789944683544302, -1.436226034482759 ], [ 29.790214177215191, -1.436226034482759 ], [ 29.790214177215191, -1.435956551724138 ], [ 29.789944683544302, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13075, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.435956551724138 ], [ 29.790214177215191, -1.436226034482759 ], [ 29.790483670886076, -1.436226034482759 ], [ 29.790483670886076, -1.435956551724138 ], [ 29.790214177215191, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13076, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.435956551724138 ], [ 29.790483670886076, -1.436226034482759 ], [ 29.790753164556961, -1.436226034482759 ], [ 29.790753164556961, -1.435956551724138 ], [ 29.790483670886076, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13077, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.435956551724138 ], [ 29.790753164556961, -1.436226034482759 ], [ 29.791022658227849, -1.436226034482759 ], [ 29.791022658227849, -1.435956551724138 ], [ 29.790753164556961, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13078, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.435956551724138 ], [ 29.791022658227849, -1.436226034482759 ], [ 29.791292151898734, -1.436226034482759 ], [ 29.791292151898734, -1.435956551724138 ], [ 29.791022658227849, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13079, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.435956551724138 ], [ 29.791292151898734, -1.436226034482759 ], [ 29.791561645569619, -1.436226034482759 ], [ 29.791561645569619, -1.435956551724138 ], [ 29.791292151898734, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13080, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.435956551724138 ], [ 29.791561645569619, -1.436226034482759 ], [ 29.791831139240507, -1.436226034482759 ], [ 29.791831139240507, -1.435956551724138 ], [ 29.791561645569619, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13081, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.435956551724138 ], [ 29.791831139240507, -1.436226034482759 ], [ 29.792100632911392, -1.436226034482759 ], [ 29.792100632911392, -1.435956551724138 ], [ 29.791831139240507, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13082, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.435956551724138 ], [ 29.792100632911392, -1.436226034482759 ], [ 29.792370126582277, -1.436226034482759 ], [ 29.792370126582277, -1.435956551724138 ], [ 29.792100632911392, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13083, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.435956551724138 ], [ 29.792370126582277, -1.436226034482759 ], [ 29.792909113924051, -1.436226034482759 ], [ 29.792909113924051, -1.435956551724138 ], [ 29.792370126582277, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13084, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.435956551724138 ], [ 29.792909113924051, -1.436226034482759 ], [ 29.793178607594935, -1.436226034482759 ], [ 29.793178607594935, -1.435956551724138 ], [ 29.792909113924051, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13085, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.435956551724138 ], [ 29.793178607594935, -1.436226034482759 ], [ 29.793717594936709, -1.436226034482759 ], [ 29.793717594936709, -1.435956551724138 ], [ 29.793178607594935, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13086, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.435956551724138 ], [ 29.793717594936709, -1.436226034482759 ], [ 29.793987088607594, -1.436226034482759 ], [ 29.793987088607594, -1.435956551724138 ], [ 29.793717594936709, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13087, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.435956551724138 ], [ 29.793987088607594, -1.436226034482759 ], [ 29.794256582278482, -1.436226034482759 ], [ 29.794256582278482, -1.435956551724138 ], [ 29.793987088607594, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13088, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.435956551724138 ], [ 29.794256582278482, -1.436226034482759 ], [ 29.794526075949367, -1.436226034482759 ], [ 29.794526075949367, -1.435956551724138 ], [ 29.794256582278482, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13089, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.435956551724138 ], [ 29.794526075949367, -1.436226034482759 ], [ 29.794795569620252, -1.436226034482759 ], [ 29.794795569620252, -1.435956551724138 ], [ 29.794526075949367, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13090, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.435956551724138 ], [ 29.794795569620252, -1.436226034482759 ], [ 29.79506506329114, -1.436226034482759 ], [ 29.79506506329114, -1.435956551724138 ], [ 29.794795569620252, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13091, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.435956551724138 ], [ 29.79506506329114, -1.436226034482759 ], [ 29.795334556962025, -1.436226034482759 ], [ 29.795334556962025, -1.435956551724138 ], [ 29.79506506329114, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13092, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.435956551724138 ], [ 29.795334556962025, -1.436226034482759 ], [ 29.79560405063291, -1.436226034482759 ], [ 29.79560405063291, -1.435956551724138 ], [ 29.795334556962025, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13093, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.435956551724138 ], [ 29.79560405063291, -1.436226034482759 ], [ 29.795873544303799, -1.436226034482759 ], [ 29.795873544303799, -1.435956551724138 ], [ 29.79560405063291, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13094, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.435956551724138 ], [ 29.795873544303799, -1.436226034482759 ], [ 29.796143037974684, -1.436226034482759 ], [ 29.796143037974684, -1.435956551724138 ], [ 29.795873544303799, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13095, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.435956551724138 ], [ 29.796143037974684, -1.436226034482759 ], [ 29.796412531645569, -1.436226034482759 ], [ 29.796412531645569, -1.435956551724138 ], [ 29.796143037974684, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13096, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.435956551724138 ], [ 29.796412531645569, -1.436226034482759 ], [ 29.796682025316457, -1.436226034482759 ], [ 29.796682025316457, -1.435956551724138 ], [ 29.796412531645569, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13097, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.435956551724138 ], [ 29.796682025316457, -1.436226034482759 ], [ 29.79776, -1.436226034482759 ], [ 29.79776, -1.435956551724138 ], [ 29.796682025316457, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13098, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.435956551724138 ], [ 29.79776, -1.436226034482759 ], [ 29.798029493670885, -1.436226034482759 ], [ 29.798029493670885, -1.435956551724138 ], [ 29.79776, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13099, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.435956551724138 ], [ 29.798029493670885, -1.436226034482759 ], [ 29.798298987341774, -1.436226034482759 ], [ 29.798298987341774, -1.435956551724138 ], [ 29.798029493670885, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13100, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.435956551724138 ], [ 29.798298987341774, -1.436226034482759 ], [ 29.798568481012659, -1.436226034482759 ], [ 29.798568481012659, -1.435956551724138 ], [ 29.798298987341774, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13101, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.435956551724138 ], [ 29.798568481012659, -1.436226034482759 ], [ 29.798837974683543, -1.436226034482759 ], [ 29.798837974683543, -1.435956551724138 ], [ 29.798568481012659, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13102, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.435956551724138 ], [ 29.798837974683543, -1.436226034482759 ], [ 29.799107468354432, -1.436226034482759 ], [ 29.799107468354432, -1.435956551724138 ], [ 29.798837974683543, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13103, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.435956551724138 ], [ 29.799107468354432, -1.436226034482759 ], [ 29.799376962025317, -1.436226034482759 ], [ 29.799376962025317, -1.435956551724138 ], [ 29.799107468354432, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13104, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.435956551724138 ], [ 29.799376962025317, -1.436226034482759 ], [ 29.799646455696202, -1.436226034482759 ], [ 29.799646455696202, -1.435956551724138 ], [ 29.799376962025317, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13105, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.435956551724138 ], [ 29.799646455696202, -1.436226034482759 ], [ 29.79991594936709, -1.436226034482759 ], [ 29.79991594936709, -1.435956551724138 ], [ 29.799646455696202, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13106, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.435956551724138 ], [ 29.79991594936709, -1.436226034482759 ], [ 29.800185443037975, -1.436226034482759 ], [ 29.800185443037975, -1.435956551724138 ], [ 29.79991594936709, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13107, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.435956551724138 ], [ 29.800185443037975, -1.436226034482759 ], [ 29.80045493670886, -1.436226034482759 ], [ 29.80045493670886, -1.435956551724138 ], [ 29.800185443037975, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13108, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.435956551724138 ], [ 29.80045493670886, -1.436765 ], [ 29.800724430379748, -1.436765 ], [ 29.800724430379748, -1.435956551724138 ], [ 29.80045493670886, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13109, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.435956551724138 ], [ 29.800993924050633, -1.436495517241379 ], [ 29.801263417721518, -1.436495517241379 ], [ 29.801263417721518, -1.435956551724138 ], [ 29.800993924050633, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13110, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.435956551724138 ], [ 29.801263417721518, -1.436765 ], [ 29.801532911392407, -1.436765 ], [ 29.801532911392407, -1.435956551724138 ], [ 29.801263417721518, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13111, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.435956551724138 ], [ 29.806383797468357, -1.436495517241379 ], [ 29.806653291139241, -1.436495517241379 ], [ 29.806653291139241, -1.435956551724138 ], [ 29.806383797468357, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13112, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.435956551724138 ], [ 29.806653291139241, -1.436226034482759 ], [ 29.806922784810126, -1.436226034482759 ], [ 29.806922784810126, -1.435956551724138 ], [ 29.806653291139241, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13113, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.435956551724138 ], [ 29.806922784810126, -1.436226034482759 ], [ 29.8074617721519, -1.436226034482759 ], [ 29.8074617721519, -1.435956551724138 ], [ 29.806922784810126, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13114, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.435956551724138 ], [ 29.8074617721519, -1.436226034482759 ], [ 29.807731265822785, -1.436226034482759 ], [ 29.807731265822785, -1.435956551724138 ], [ 29.8074617721519, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13115, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.435956551724138 ], [ 29.807731265822785, -1.436226034482759 ], [ 29.808270253164558, -1.436226034482759 ], [ 29.808270253164558, -1.435956551724138 ], [ 29.807731265822785, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13116, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.436226034482759 ], [ 29.744400253164557, -1.436495517241379 ], [ 29.744669746835442, -1.436495517241379 ], [ 29.744669746835442, -1.436226034482759 ], [ 29.744400253164557, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13117, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.436226034482759 ], [ 29.744669746835442, -1.436495517241379 ], [ 29.744939240506326, -1.436495517241379 ], [ 29.744939240506326, -1.436226034482759 ], [ 29.744669746835442, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13118, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.436226034482759 ], [ 29.744939240506326, -1.436495517241379 ], [ 29.745208734177215, -1.436495517241379 ], [ 29.745208734177215, -1.436226034482759 ], [ 29.744939240506326, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13119, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.436226034482759 ], [ 29.745208734177215, -1.436495517241379 ], [ 29.7454782278481, -1.436495517241379 ], [ 29.7454782278481, -1.436226034482759 ], [ 29.745208734177215, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13120, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.436226034482759 ], [ 29.7454782278481, -1.436495517241379 ], [ 29.745747721518985, -1.436495517241379 ], [ 29.745747721518985, -1.436226034482759 ], [ 29.7454782278481, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13121, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.436226034482759 ], [ 29.745747721518985, -1.436495517241379 ], [ 29.746017215189873, -1.436495517241379 ], [ 29.746017215189873, -1.436226034482759 ], [ 29.745747721518985, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13122, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.436226034482759 ], [ 29.746017215189873, -1.436495517241379 ], [ 29.746286708860758, -1.436495517241379 ], [ 29.746286708860758, -1.436226034482759 ], [ 29.746017215189873, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13123, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.436226034482759 ], [ 29.746556202531643, -1.436495517241379 ], [ 29.746825696202531, -1.436495517241379 ], [ 29.746825696202531, -1.436226034482759 ], [ 29.746556202531643, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13124, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.436226034482759 ], [ 29.746825696202531, -1.436495517241379 ], [ 29.747095189873416, -1.436495517241379 ], [ 29.747095189873416, -1.436226034482759 ], [ 29.746825696202531, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13125, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.436226034482759 ], [ 29.747364683544301, -1.436765 ], [ 29.747903670886075, -1.436765 ], [ 29.747903670886075, -1.436226034482759 ], [ 29.747364683544301, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13126, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.436226034482759 ], [ 29.747903670886075, -1.436495517241379 ], [ 29.748442658227848, -1.436495517241379 ], [ 29.748442658227848, -1.436226034482759 ], [ 29.747903670886075, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13127, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.436226034482759 ], [ 29.748442658227848, -1.436495517241379 ], [ 29.748712151898733, -1.436495517241379 ], [ 29.748712151898733, -1.436226034482759 ], [ 29.748442658227848, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13128, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.436226034482759 ], [ 29.748712151898733, -1.436495517241379 ], [ 29.748981645569618, -1.436495517241379 ], [ 29.748981645569618, -1.436226034482759 ], [ 29.748712151898733, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13129, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.436226034482759 ], [ 29.748981645569618, -1.436495517241379 ], [ 29.749251139240506, -1.436495517241379 ], [ 29.749251139240506, -1.436226034482759 ], [ 29.748981645569618, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13130, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.436226034482759 ], [ 29.749251139240506, -1.436495517241379 ], [ 29.749520632911391, -1.436495517241379 ], [ 29.749520632911391, -1.436226034482759 ], [ 29.749251139240506, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13131, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.436226034482759 ], [ 29.749520632911391, -1.436495517241379 ], [ 29.749790126582276, -1.436495517241379 ], [ 29.749790126582276, -1.436226034482759 ], [ 29.749520632911391, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13132, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.436226034482759 ], [ 29.749790126582276, -1.436495517241379 ], [ 29.750059620253165, -1.436495517241379 ], [ 29.750059620253165, -1.436226034482759 ], [ 29.749790126582276, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13133, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.436226034482759 ], [ 29.750059620253165, -1.436495517241379 ], [ 29.75032911392405, -1.436495517241379 ], [ 29.75032911392405, -1.436226034482759 ], [ 29.750059620253165, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13134, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.436226034482759 ], [ 29.75032911392405, -1.436495517241379 ], [ 29.750598607594934, -1.436495517241379 ], [ 29.750598607594934, -1.436226034482759 ], [ 29.75032911392405, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13135, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.436226034482759 ], [ 29.750598607594934, -1.436495517241379 ], [ 29.750868101265823, -1.436495517241379 ], [ 29.750868101265823, -1.436226034482759 ], [ 29.750598607594934, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13136, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.436226034482759 ], [ 29.750868101265823, -1.436495517241379 ], [ 29.754371518987341, -1.436495517241379 ], [ 29.754371518987341, -1.436226034482759 ], [ 29.750868101265823, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13137, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.436226034482759 ], [ 29.754371518987341, -1.436495517241379 ], [ 29.754641012658226, -1.436495517241379 ], [ 29.754641012658226, -1.436226034482759 ], [ 29.754371518987341, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13138, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.436226034482759 ], [ 29.754641012658226, -1.436495517241379 ], [ 29.754910506329114, -1.436495517241379 ], [ 29.754910506329114, -1.436226034482759 ], [ 29.754641012658226, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13139, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.436226034482759 ], [ 29.754910506329114, -1.436495517241379 ], [ 29.75518, -1.436495517241379 ], [ 29.75518, -1.436226034482759 ], [ 29.754910506329114, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13140, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.436226034482759 ], [ 29.75518, -1.436495517241379 ], [ 29.755449493670884, -1.436495517241379 ], [ 29.755449493670884, -1.436226034482759 ], [ 29.75518, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13141, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.436226034482759 ], [ 29.755449493670884, -1.436495517241379 ], [ 29.755718987341773, -1.436495517241379 ], [ 29.755718987341773, -1.436226034482759 ], [ 29.755449493670884, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13142, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.435956551724138 ], [ 29.756257974683542, -1.435956551724138 ], [ 29.756257974683542, -1.437303965517241 ], [ 29.755988481012658, -1.437303965517241 ], [ 29.755988481012658, -1.436495517241379 ], [ 29.755718987341773, -1.436495517241379 ], [ 29.755718987341773, -1.436226034482759 ], [ 29.755988481012658, -1.436226034482759 ], [ 29.755988481012658, -1.435956551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13143, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.436226034482759 ], [ 29.761647848101266, -1.436495517241379 ], [ 29.761917341772151, -1.436495517241379 ], [ 29.761917341772151, -1.436226034482759 ], [ 29.761647848101266, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13144, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.436226034482759 ], [ 29.761917341772151, -1.436495517241379 ], [ 29.762186835443039, -1.436495517241379 ], [ 29.762186835443039, -1.436226034482759 ], [ 29.761917341772151, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13145, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.436226034482759 ], [ 29.762186835443039, -1.436495517241379 ], [ 29.762456329113924, -1.436495517241379 ], [ 29.762456329113924, -1.436226034482759 ], [ 29.762186835443039, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13146, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.436226034482759 ], [ 29.762456329113924, -1.437034482758621 ], [ 29.762725822784809, -1.437034482758621 ], [ 29.762725822784809, -1.436226034482759 ], [ 29.762456329113924, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13147, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.436226034482759 ], [ 29.762725822784809, -1.437034482758621 ], [ 29.762995316455697, -1.437034482758621 ], [ 29.762995316455697, -1.436226034482759 ], [ 29.762725822784809, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13148, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.436226034482759 ], [ 29.763264810126582, -1.436765 ], [ 29.763534303797467, -1.436765 ], [ 29.763534303797467, -1.436226034482759 ], [ 29.763264810126582, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13149, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.436226034482759 ], [ 29.763534303797467, -1.436765 ], [ 29.763803797468356, -1.436765 ], [ 29.763803797468356, -1.436226034482759 ], [ 29.763534303797467, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13150, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.436226034482759 ], [ 29.76407329113924, -1.436495517241379 ], [ 29.764342784810125, -1.436495517241379 ], [ 29.764342784810125, -1.436226034482759 ], [ 29.76407329113924, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13151, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.436226034482759 ], [ 29.764881772151899, -1.436495517241379 ], [ 29.765151265822784, -1.436495517241379 ], [ 29.765151265822784, -1.436226034482759 ], [ 29.764881772151899, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13152, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.436226034482759 ], [ 29.765151265822784, -1.436495517241379 ], [ 29.765420759493672, -1.436495517241379 ], [ 29.765420759493672, -1.436226034482759 ], [ 29.765151265822784, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13153, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.436226034482759 ], [ 29.765959746835442, -1.436495517241379 ], [ 29.766229240506327, -1.436495517241379 ], [ 29.766229240506327, -1.436226034482759 ], [ 29.765959746835442, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13154, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.436226034482759 ], [ 29.766229240506327, -1.436495517241379 ], [ 29.766498734177215, -1.436495517241379 ], [ 29.766498734177215, -1.436226034482759 ], [ 29.766229240506327, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13155, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.436226034482759 ], [ 29.766498734177215, -1.436495517241379 ], [ 29.7667682278481, -1.436495517241379 ], [ 29.7667682278481, -1.436226034482759 ], [ 29.766498734177215, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13156, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.436226034482759 ], [ 29.767037721518985, -1.436495517241379 ], [ 29.767307215189874, -1.436495517241379 ], [ 29.767307215189874, -1.436226034482759 ], [ 29.767037721518985, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13157, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.436226034482759 ], [ 29.767307215189874, -1.436495517241379 ], [ 29.767576708860759, -1.436495517241379 ], [ 29.767576708860759, -1.436226034482759 ], [ 29.767307215189874, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13158, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.436226034482759 ], [ 29.767576708860759, -1.436765 ], [ 29.767846202531643, -1.436765 ], [ 29.767846202531643, -1.436226034482759 ], [ 29.767576708860759, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13159, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.436226034482759 ], [ 29.767846202531643, -1.436495517241379 ], [ 29.768115696202532, -1.436495517241379 ], [ 29.768115696202532, -1.436226034482759 ], [ 29.767846202531643, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13160, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.436226034482759 ], [ 29.768115696202532, -1.436495517241379 ], [ 29.768385189873417, -1.436495517241379 ], [ 29.768385189873417, -1.436226034482759 ], [ 29.768115696202532, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13161, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.436226034482759 ], [ 29.768385189873417, -1.436765 ], [ 29.768654683544302, -1.436765 ], [ 29.768654683544302, -1.436226034482759 ], [ 29.768385189873417, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13162, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.436226034482759 ], [ 29.76892417721519, -1.436495517241379 ], [ 29.769193670886075, -1.436495517241379 ], [ 29.769193670886075, -1.436226034482759 ], [ 29.76892417721519, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13163, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.436226034482759 ], [ 29.769193670886075, -1.436495517241379 ], [ 29.76946316455696, -1.436495517241379 ], [ 29.76946316455696, -1.436226034482759 ], [ 29.769193670886075, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13164, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.436226034482759 ], [ 29.769732658227849, -1.436495517241379 ], [ 29.770002151898733, -1.436495517241379 ], [ 29.770002151898733, -1.436226034482759 ], [ 29.769732658227849, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13165, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.436226034482759 ], [ 29.770002151898733, -1.436495517241379 ], [ 29.770271645569618, -1.436495517241379 ], [ 29.770271645569618, -1.436226034482759 ], [ 29.770002151898733, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13166, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.436226034482759 ], [ 29.770271645569618, -1.436765 ], [ 29.770541139240507, -1.436765 ], [ 29.770541139240507, -1.436226034482759 ], [ 29.770271645569618, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13167, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.436226034482759 ], [ 29.770541139240507, -1.436495517241379 ], [ 29.770810632911392, -1.436495517241379 ], [ 29.770810632911392, -1.436226034482759 ], [ 29.770541139240507, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13168, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.436226034482759 ], [ 29.770810632911392, -1.436495517241379 ], [ 29.771080126582277, -1.436495517241379 ], [ 29.771080126582277, -1.436226034482759 ], [ 29.770810632911392, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13169, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.436226034482759 ], [ 29.771080126582277, -1.436495517241379 ], [ 29.771349620253165, -1.436495517241379 ], [ 29.771349620253165, -1.436226034482759 ], [ 29.771080126582277, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13170, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.436226034482759 ], [ 29.77161911392405, -1.436495517241379 ], [ 29.771888607594935, -1.436495517241379 ], [ 29.771888607594935, -1.436226034482759 ], [ 29.77161911392405, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13171, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.436226034482759 ], [ 29.771888607594935, -1.436495517241379 ], [ 29.772158101265823, -1.436495517241379 ], [ 29.772158101265823, -1.436226034482759 ], [ 29.771888607594935, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13172, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.436226034482759 ], [ 29.772158101265823, -1.436765 ], [ 29.772427594936708, -1.436765 ], [ 29.772427594936708, -1.436226034482759 ], [ 29.772158101265823, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13173, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.436226034482759 ], [ 29.772427594936708, -1.436495517241379 ], [ 29.772697088607593, -1.436495517241379 ], [ 29.772697088607593, -1.436226034482759 ], [ 29.772427594936708, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13174, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.436226034482759 ], [ 29.772697088607593, -1.436495517241379 ], [ 29.772966582278482, -1.436495517241379 ], [ 29.772966582278482, -1.436226034482759 ], [ 29.772697088607593, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13175, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.436226034482759 ], [ 29.772966582278482, -1.436765 ], [ 29.773236075949367, -1.436765 ], [ 29.773236075949367, -1.436226034482759 ], [ 29.772966582278482, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13176, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.436226034482759 ], [ 29.773505569620252, -1.436495517241379 ], [ 29.77377506329114, -1.436495517241379 ], [ 29.77377506329114, -1.436226034482759 ], [ 29.773505569620252, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13177, "El": 148 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.436226034482759 ], [ 29.77377506329114, -1.436765 ], [ 29.774044556962025, -1.436765 ], [ 29.774044556962025, -1.436226034482759 ], [ 29.77377506329114, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13178, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.436226034482759 ], [ 29.77431405063291, -1.436765 ], [ 29.774853037974683, -1.436765 ], [ 29.774853037974683, -1.436226034482759 ], [ 29.77431405063291, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13179, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.436226034482759 ], [ 29.774853037974683, -1.436495517241379 ], [ 29.775122531645568, -1.436495517241379 ], [ 29.775122531645568, -1.436226034482759 ], [ 29.774853037974683, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13180, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.436226034482759 ], [ 29.775392025316457, -1.436765 ], [ 29.775661518987341, -1.436765 ], [ 29.775661518987341, -1.436226034482759 ], [ 29.775392025316457, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13181, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.436226034482759 ], [ 29.775661518987341, -1.436495517241379 ], [ 29.775931012658226, -1.436495517241379 ], [ 29.775931012658226, -1.436226034482759 ], [ 29.775661518987341, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13182, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.436226034482759 ], [ 29.776200506329115, -1.436765 ], [ 29.77647, -1.436765 ], [ 29.77647, -1.436226034482759 ], [ 29.776200506329115, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13183, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.436226034482759 ], [ 29.77647, -1.436495517241379 ], [ 29.776739493670885, -1.436495517241379 ], [ 29.776739493670885, -1.436226034482759 ], [ 29.77647, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13184, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.436226034482759 ], [ 29.777008987341773, -1.436765 ], [ 29.777278481012658, -1.436765 ], [ 29.777278481012658, -1.436226034482759 ], [ 29.777008987341773, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13185, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.436226034482759 ], [ 29.777278481012658, -1.436495517241379 ], [ 29.777547974683543, -1.436495517241379 ], [ 29.777547974683543, -1.436226034482759 ], [ 29.777278481012658, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13186, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.436226034482759 ], [ 29.777547974683543, -1.436495517241379 ], [ 29.777817468354431, -1.436495517241379 ], [ 29.777817468354431, -1.436226034482759 ], [ 29.777547974683543, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13187, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.436226034482759 ], [ 29.777817468354431, -1.436765 ], [ 29.778086962025316, -1.436765 ], [ 29.778086962025316, -1.436226034482759 ], [ 29.777817468354431, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13188, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.436226034482759 ], [ 29.778086962025316, -1.436495517241379 ], [ 29.778356455696201, -1.436495517241379 ], [ 29.778356455696201, -1.436226034482759 ], [ 29.778086962025316, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13189, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.436226034482759 ], [ 29.778356455696201, -1.436495517241379 ], [ 29.77862594936709, -1.436495517241379 ], [ 29.77862594936709, -1.436226034482759 ], [ 29.778356455696201, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13190, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.436226034482759 ], [ 29.778895443037975, -1.436495517241379 ], [ 29.77916493670886, -1.436495517241379 ], [ 29.77916493670886, -1.436226034482759 ], [ 29.778895443037975, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13191, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.436226034482759 ], [ 29.77916493670886, -1.436495517241379 ], [ 29.779434430379748, -1.436495517241379 ], [ 29.779434430379748, -1.436226034482759 ], [ 29.77916493670886, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13192, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.436226034482759 ], [ 29.779434430379748, -1.436495517241379 ], [ 29.779703924050633, -1.436495517241379 ], [ 29.779703924050633, -1.436226034482759 ], [ 29.779434430379748, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13193, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.436226034482759 ], [ 29.779703924050633, -1.436765 ], [ 29.779973417721518, -1.436765 ], [ 29.779973417721518, -1.436226034482759 ], [ 29.779703924050633, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13194, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.436226034482759 ], [ 29.779973417721518, -1.436495517241379 ], [ 29.780242911392406, -1.436495517241379 ], [ 29.780242911392406, -1.436226034482759 ], [ 29.779973417721518, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13195, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.436226034482759 ], [ 29.780242911392406, -1.436495517241379 ], [ 29.780512405063291, -1.436495517241379 ], [ 29.780512405063291, -1.436226034482759 ], [ 29.780242911392406, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13196, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.436226034482759 ], [ 29.780512405063291, -1.436495517241379 ], [ 29.780781898734176, -1.436495517241379 ], [ 29.780781898734176, -1.436226034482759 ], [ 29.780512405063291, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13197, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.436226034482759 ], [ 29.780781898734176, -1.436495517241379 ], [ 29.781051392405065, -1.436495517241379 ], [ 29.781051392405065, -1.436226034482759 ], [ 29.780781898734176, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13198, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.436226034482759 ], [ 29.781051392405065, -1.436495517241379 ], [ 29.78132088607595, -1.436495517241379 ], [ 29.78132088607595, -1.436226034482759 ], [ 29.781051392405065, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13199, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.436226034482759 ], [ 29.78132088607595, -1.436495517241379 ], [ 29.781590379746834, -1.436495517241379 ], [ 29.781590379746834, -1.436226034482759 ], [ 29.78132088607595, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13200, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.436226034482759 ], [ 29.781590379746834, -1.436495517241379 ], [ 29.781859873417723, -1.436495517241379 ], [ 29.781859873417723, -1.436226034482759 ], [ 29.781590379746834, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13201, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.436226034482759 ], [ 29.781859873417723, -1.436495517241379 ], [ 29.782129367088608, -1.436495517241379 ], [ 29.782129367088608, -1.436226034482759 ], [ 29.781859873417723, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13202, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.436226034482759 ], [ 29.782129367088608, -1.436495517241379 ], [ 29.782398860759493, -1.436495517241379 ], [ 29.782398860759493, -1.436226034482759 ], [ 29.782129367088608, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13203, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.436226034482759 ], [ 29.782398860759493, -1.436495517241379 ], [ 29.782668354430381, -1.436495517241379 ], [ 29.782668354430381, -1.436226034482759 ], [ 29.782398860759493, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13204, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.436226034482759 ], [ 29.782668354430381, -1.436495517241379 ], [ 29.782937848101266, -1.436495517241379 ], [ 29.782937848101266, -1.436226034482759 ], [ 29.782668354430381, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13205, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.436226034482759 ], [ 29.782937848101266, -1.436495517241379 ], [ 29.783207341772151, -1.436495517241379 ], [ 29.783207341772151, -1.436226034482759 ], [ 29.782937848101266, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13206, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.436226034482759 ], [ 29.783207341772151, -1.436495517241379 ], [ 29.783476835443039, -1.436495517241379 ], [ 29.783476835443039, -1.436226034482759 ], [ 29.783207341772151, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13207, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.436226034482759 ], [ 29.783476835443039, -1.436495517241379 ], [ 29.784015822784809, -1.436495517241379 ], [ 29.784015822784809, -1.436226034482759 ], [ 29.783476835443039, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13208, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.436226034482759 ], [ 29.784015822784809, -1.436495517241379 ], [ 29.784285316455698, -1.436495517241379 ], [ 29.784285316455698, -1.436226034482759 ], [ 29.784015822784809, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13209, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.436226034482759 ], [ 29.784285316455698, -1.436495517241379 ], [ 29.784554810126583, -1.436495517241379 ], [ 29.784554810126583, -1.436226034482759 ], [ 29.784285316455698, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13210, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.436226034482759 ], [ 29.784554810126583, -1.436495517241379 ], [ 29.784824303797468, -1.436495517241379 ], [ 29.784824303797468, -1.436226034482759 ], [ 29.784554810126583, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13211, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.436226034482759 ], [ 29.784824303797468, -1.436495517241379 ], [ 29.785093797468356, -1.436495517241379 ], [ 29.785093797468356, -1.436226034482759 ], [ 29.784824303797468, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13212, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.436226034482759 ], [ 29.785093797468356, -1.436495517241379 ], [ 29.785363291139241, -1.436495517241379 ], [ 29.785363291139241, -1.436226034482759 ], [ 29.785093797468356, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13213, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.436226034482759 ], [ 29.785363291139241, -1.436495517241379 ], [ 29.785632784810126, -1.436495517241379 ], [ 29.785632784810126, -1.436226034482759 ], [ 29.785363291139241, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13214, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.436226034482759 ], [ 29.785632784810126, -1.436495517241379 ], [ 29.786710759493673, -1.436495517241379 ], [ 29.786710759493673, -1.436226034482759 ], [ 29.785632784810126, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13215, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.436226034482759 ], [ 29.786710759493673, -1.436495517241379 ], [ 29.786980253164558, -1.436495517241379 ], [ 29.786980253164558, -1.436226034482759 ], [ 29.786710759493673, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13216, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.436226034482759 ], [ 29.786980253164558, -1.436495517241379 ], [ 29.787519240506327, -1.436495517241379 ], [ 29.787519240506327, -1.436226034482759 ], [ 29.786980253164558, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13217, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.436226034482759 ], [ 29.787519240506327, -1.436495517241379 ], [ 29.789136202531644, -1.436495517241379 ], [ 29.789136202531644, -1.436226034482759 ], [ 29.787519240506327, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13218, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.436226034482759 ], [ 29.789136202531644, -1.436495517241379 ], [ 29.789675189873417, -1.436495517241379 ], [ 29.789675189873417, -1.436226034482759 ], [ 29.789136202531644, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13219, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.436226034482759 ], [ 29.789675189873417, -1.436495517241379 ], [ 29.789944683544302, -1.436495517241379 ], [ 29.789944683544302, -1.436226034482759 ], [ 29.789675189873417, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13220, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.436226034482759 ], [ 29.789944683544302, -1.436495517241379 ], [ 29.790214177215191, -1.436495517241379 ], [ 29.790214177215191, -1.436226034482759 ], [ 29.789944683544302, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13221, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.436226034482759 ], [ 29.790214177215191, -1.436495517241379 ], [ 29.790483670886076, -1.436495517241379 ], [ 29.790483670886076, -1.436226034482759 ], [ 29.790214177215191, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13222, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.436226034482759 ], [ 29.790483670886076, -1.436495517241379 ], [ 29.790753164556961, -1.436495517241379 ], [ 29.790753164556961, -1.436226034482759 ], [ 29.790483670886076, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13223, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.436226034482759 ], [ 29.790753164556961, -1.436495517241379 ], [ 29.791022658227849, -1.436495517241379 ], [ 29.791022658227849, -1.436226034482759 ], [ 29.790753164556961, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13224, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.436226034482759 ], [ 29.791022658227849, -1.436495517241379 ], [ 29.791292151898734, -1.436495517241379 ], [ 29.791292151898734, -1.436226034482759 ], [ 29.791022658227849, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13225, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.436226034482759 ], [ 29.791292151898734, -1.436495517241379 ], [ 29.791561645569619, -1.436495517241379 ], [ 29.791561645569619, -1.436226034482759 ], [ 29.791292151898734, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13226, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.436226034482759 ], [ 29.791561645569619, -1.436495517241379 ], [ 29.791831139240507, -1.436495517241379 ], [ 29.791831139240507, -1.436226034482759 ], [ 29.791561645569619, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13227, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.436226034482759 ], [ 29.791831139240507, -1.436495517241379 ], [ 29.792100632911392, -1.436495517241379 ], [ 29.792100632911392, -1.436226034482759 ], [ 29.791831139240507, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13228, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.436226034482759 ], [ 29.792100632911392, -1.436495517241379 ], [ 29.792370126582277, -1.436495517241379 ], [ 29.792370126582277, -1.436226034482759 ], [ 29.792100632911392, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13229, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.436226034482759 ], [ 29.792370126582277, -1.436495517241379 ], [ 29.792909113924051, -1.436495517241379 ], [ 29.792909113924051, -1.436226034482759 ], [ 29.792370126582277, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13230, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.436226034482759 ], [ 29.792909113924051, -1.436495517241379 ], [ 29.793178607594935, -1.436495517241379 ], [ 29.793178607594935, -1.436226034482759 ], [ 29.792909113924051, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13231, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.436226034482759 ], [ 29.793178607594935, -1.436495517241379 ], [ 29.793448101265824, -1.436495517241379 ], [ 29.793448101265824, -1.436226034482759 ], [ 29.793178607594935, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13232, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.436226034482759 ], [ 29.793448101265824, -1.436495517241379 ], [ 29.793717594936709, -1.436495517241379 ], [ 29.793717594936709, -1.436226034482759 ], [ 29.793448101265824, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13233, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.436226034482759 ], [ 29.793717594936709, -1.436495517241379 ], [ 29.793987088607594, -1.436495517241379 ], [ 29.793987088607594, -1.436226034482759 ], [ 29.793717594936709, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13234, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.436226034482759 ], [ 29.793987088607594, -1.436495517241379 ], [ 29.794256582278482, -1.436495517241379 ], [ 29.794256582278482, -1.436226034482759 ], [ 29.793987088607594, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13235, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.436226034482759 ], [ 29.794256582278482, -1.436495517241379 ], [ 29.794526075949367, -1.436495517241379 ], [ 29.794526075949367, -1.436226034482759 ], [ 29.794256582278482, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13236, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.436226034482759 ], [ 29.794526075949367, -1.436495517241379 ], [ 29.794795569620252, -1.436495517241379 ], [ 29.794795569620252, -1.436226034482759 ], [ 29.794526075949367, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13237, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.436226034482759 ], [ 29.794795569620252, -1.436495517241379 ], [ 29.79506506329114, -1.436495517241379 ], [ 29.79506506329114, -1.436226034482759 ], [ 29.794795569620252, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13238, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.436226034482759 ], [ 29.79506506329114, -1.436495517241379 ], [ 29.795334556962025, -1.436495517241379 ], [ 29.795334556962025, -1.436226034482759 ], [ 29.79506506329114, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13239, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.436226034482759 ], [ 29.795334556962025, -1.436495517241379 ], [ 29.79560405063291, -1.436495517241379 ], [ 29.79560405063291, -1.436226034482759 ], [ 29.795334556962025, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13240, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.436226034482759 ], [ 29.79560405063291, -1.436495517241379 ], [ 29.795873544303799, -1.436495517241379 ], [ 29.795873544303799, -1.436226034482759 ], [ 29.79560405063291, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13241, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.436226034482759 ], [ 29.795873544303799, -1.436495517241379 ], [ 29.796143037974684, -1.436495517241379 ], [ 29.796143037974684, -1.436226034482759 ], [ 29.795873544303799, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13242, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.436226034482759 ], [ 29.796143037974684, -1.436495517241379 ], [ 29.796412531645569, -1.436495517241379 ], [ 29.796412531645569, -1.436226034482759 ], [ 29.796143037974684, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13243, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.436226034482759 ], [ 29.796412531645569, -1.436495517241379 ], [ 29.798029493670885, -1.436495517241379 ], [ 29.798029493670885, -1.436226034482759 ], [ 29.796412531645569, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13244, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.436226034482759 ], [ 29.798029493670885, -1.436495517241379 ], [ 29.798298987341774, -1.436495517241379 ], [ 29.798298987341774, -1.436226034482759 ], [ 29.798029493670885, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13245, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.436226034482759 ], [ 29.798298987341774, -1.436495517241379 ], [ 29.798568481012659, -1.436495517241379 ], [ 29.798568481012659, -1.436226034482759 ], [ 29.798298987341774, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13246, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.436226034482759 ], [ 29.798568481012659, -1.436495517241379 ], [ 29.798837974683543, -1.436495517241379 ], [ 29.798837974683543, -1.436226034482759 ], [ 29.798568481012659, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13247, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.436226034482759 ], [ 29.798837974683543, -1.436495517241379 ], [ 29.799107468354432, -1.436495517241379 ], [ 29.799107468354432, -1.436226034482759 ], [ 29.798837974683543, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13248, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.436226034482759 ], [ 29.799107468354432, -1.436495517241379 ], [ 29.799376962025317, -1.436495517241379 ], [ 29.799376962025317, -1.436226034482759 ], [ 29.799107468354432, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13249, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.436226034482759 ], [ 29.799376962025317, -1.436495517241379 ], [ 29.799646455696202, -1.436495517241379 ], [ 29.799646455696202, -1.436226034482759 ], [ 29.799376962025317, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13250, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.436226034482759 ], [ 29.799646455696202, -1.436495517241379 ], [ 29.79991594936709, -1.436495517241379 ], [ 29.79991594936709, -1.436226034482759 ], [ 29.799646455696202, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13251, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.436226034482759 ], [ 29.79991594936709, -1.436495517241379 ], [ 29.80045493670886, -1.436495517241379 ], [ 29.80045493670886, -1.436226034482759 ], [ 29.79991594936709, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13252, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.436226034482759 ], [ 29.800724430379748, -1.436765 ], [ 29.800993924050633, -1.436765 ], [ 29.800993924050633, -1.436226034482759 ], [ 29.800724430379748, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13253, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.436226034482759 ], [ 29.802341392405065, -1.436765 ], [ 29.80261088607595, -1.436765 ], [ 29.80261088607595, -1.436226034482759 ], [ 29.802341392405065, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13254, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.436226034482759 ], [ 29.803149873417723, -1.436495517241379 ], [ 29.803419367088608, -1.436495517241379 ], [ 29.803419367088608, -1.436226034482759 ], [ 29.803149873417723, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13255, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.436226034482759 ], [ 29.803419367088608, -1.436495517241379 ], [ 29.803688860759493, -1.436495517241379 ], [ 29.803688860759493, -1.436226034482759 ], [ 29.803419367088608, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13256, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.436226034482759 ], [ 29.803688860759493, -1.436495517241379 ], [ 29.803958354430382, -1.436495517241379 ], [ 29.803958354430382, -1.436226034482759 ], [ 29.803688860759493, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13257, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.436226034482759 ], [ 29.803958354430382, -1.436495517241379 ], [ 29.804497341772151, -1.436495517241379 ], [ 29.804497341772151, -1.436226034482759 ], [ 29.803958354430382, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13258, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.436226034482759 ], [ 29.806653291139241, -1.436495517241379 ], [ 29.806922784810126, -1.436495517241379 ], [ 29.806922784810126, -1.436226034482759 ], [ 29.806653291139241, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13259, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.436226034482759 ], [ 29.806922784810126, -1.436495517241379 ], [ 29.8074617721519, -1.436495517241379 ], [ 29.8074617721519, -1.436226034482759 ], [ 29.806922784810126, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13260, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.436226034482759 ], [ 29.8074617721519, -1.436495517241379 ], [ 29.807731265822785, -1.436495517241379 ], [ 29.807731265822785, -1.436226034482759 ], [ 29.8074617721519, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13261, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.436226034482759 ], [ 29.807731265822785, -1.436495517241379 ], [ 29.808000759493673, -1.436495517241379 ], [ 29.808000759493673, -1.436226034482759 ], [ 29.807731265822785, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13262, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.436226034482759 ], [ 29.808000759493673, -1.436495517241379 ], [ 29.808270253164558, -1.436495517241379 ], [ 29.808270253164558, -1.436226034482759 ], [ 29.808000759493673, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13263, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.436226034482759 ], [ 29.808270253164558, -1.436495517241379 ], [ 29.808539746835443, -1.436495517241379 ], [ 29.808539746835443, -1.436226034482759 ], [ 29.808270253164558, -1.436226034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13264, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.436495517241379 ], [ 29.744130759493672, -1.436765 ], [ 29.744400253164557, -1.436765 ], [ 29.744400253164557, -1.436495517241379 ], [ 29.744130759493672, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13265, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.436495517241379 ], [ 29.744400253164557, -1.436765 ], [ 29.744669746835442, -1.436765 ], [ 29.744669746835442, -1.436495517241379 ], [ 29.744400253164557, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13266, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.436495517241379 ], [ 29.744669746835442, -1.436765 ], [ 29.744939240506326, -1.436765 ], [ 29.744939240506326, -1.436495517241379 ], [ 29.744669746835442, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13267, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.436495517241379 ], [ 29.744939240506326, -1.436765 ], [ 29.745208734177215, -1.436765 ], [ 29.745208734177215, -1.436495517241379 ], [ 29.744939240506326, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13268, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.436495517241379 ], [ 29.745208734177215, -1.436765 ], [ 29.7454782278481, -1.436765 ], [ 29.7454782278481, -1.436495517241379 ], [ 29.745208734177215, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13269, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.436495517241379 ], [ 29.7454782278481, -1.436765 ], [ 29.745747721518985, -1.436765 ], [ 29.745747721518985, -1.436495517241379 ], [ 29.7454782278481, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13270, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.436495517241379 ], [ 29.745747721518985, -1.436765 ], [ 29.746017215189873, -1.436765 ], [ 29.746017215189873, -1.436495517241379 ], [ 29.745747721518985, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13271, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.436495517241379 ], [ 29.746017215189873, -1.436765 ], [ 29.746286708860758, -1.436765 ], [ 29.746286708860758, -1.436495517241379 ], [ 29.746017215189873, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13272, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.436495517241379 ], [ 29.746286708860758, -1.436765 ], [ 29.746556202531643, -1.436765 ], [ 29.746556202531643, -1.436495517241379 ], [ 29.746286708860758, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13273, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.436495517241379 ], [ 29.746556202531643, -1.436765 ], [ 29.746825696202531, -1.436765 ], [ 29.746825696202531, -1.436495517241379 ], [ 29.746556202531643, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.436495517241379 ], [ 29.746825696202531, -1.436765 ], [ 29.747095189873416, -1.436765 ], [ 29.747095189873416, -1.436495517241379 ], [ 29.746825696202531, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13275, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.436495517241379 ], [ 29.747095189873416, -1.436765 ], [ 29.747364683544301, -1.436765 ], [ 29.747364683544301, -1.436495517241379 ], [ 29.747095189873416, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13276, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.436495517241379 ], [ 29.747903670886075, -1.436765 ], [ 29.74817316455696, -1.436765 ], [ 29.74817316455696, -1.436495517241379 ], [ 29.747903670886075, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13277, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.436495517241379 ], [ 29.74817316455696, -1.436765 ], [ 29.748442658227848, -1.436765 ], [ 29.748442658227848, -1.436495517241379 ], [ 29.74817316455696, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13278, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.436495517241379 ], [ 29.748442658227848, -1.436765 ], [ 29.748712151898733, -1.436765 ], [ 29.748712151898733, -1.436495517241379 ], [ 29.748442658227848, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13279, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.436495517241379 ], [ 29.748712151898733, -1.436765 ], [ 29.748981645569618, -1.436765 ], [ 29.748981645569618, -1.436495517241379 ], [ 29.748712151898733, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13280, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.436495517241379 ], [ 29.748981645569618, -1.436765 ], [ 29.749251139240506, -1.436765 ], [ 29.749251139240506, -1.436495517241379 ], [ 29.748981645569618, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13281, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.436495517241379 ], [ 29.749251139240506, -1.436765 ], [ 29.749520632911391, -1.436765 ], [ 29.749520632911391, -1.436495517241379 ], [ 29.749251139240506, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13282, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.436495517241379 ], [ 29.749520632911391, -1.436765 ], [ 29.749790126582276, -1.436765 ], [ 29.749790126582276, -1.436495517241379 ], [ 29.749520632911391, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13283, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.436495517241379 ], [ 29.749790126582276, -1.436765 ], [ 29.750059620253165, -1.436765 ], [ 29.750059620253165, -1.436495517241379 ], [ 29.749790126582276, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13284, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.436495517241379 ], [ 29.750059620253165, -1.436765 ], [ 29.75032911392405, -1.436765 ], [ 29.75032911392405, -1.436495517241379 ], [ 29.750059620253165, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13285, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.436495517241379 ], [ 29.75032911392405, -1.436765 ], [ 29.750598607594934, -1.436765 ], [ 29.750598607594934, -1.436495517241379 ], [ 29.75032911392405, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13286, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.436495517241379 ], [ 29.750598607594934, -1.436765 ], [ 29.750868101265823, -1.436765 ], [ 29.750868101265823, -1.436495517241379 ], [ 29.750598607594934, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13287, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.436495517241379 ], [ 29.750868101265823, -1.436765 ], [ 29.754371518987341, -1.436765 ], [ 29.754371518987341, -1.436495517241379 ], [ 29.750868101265823, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13288, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.436495517241379 ], [ 29.754371518987341, -1.436765 ], [ 29.754641012658226, -1.436765 ], [ 29.754641012658226, -1.436495517241379 ], [ 29.754371518987341, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13289, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.436495517241379 ], [ 29.754641012658226, -1.436765 ], [ 29.754910506329114, -1.436765 ], [ 29.754910506329114, -1.436495517241379 ], [ 29.754641012658226, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13290, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.436495517241379 ], [ 29.754910506329114, -1.436765 ], [ 29.75518, -1.436765 ], [ 29.75518, -1.436495517241379 ], [ 29.754910506329114, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13291, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.436495517241379 ], [ 29.75518, -1.436765 ], [ 29.755449493670884, -1.436765 ], [ 29.755449493670884, -1.436495517241379 ], [ 29.75518, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13292, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.436495517241379 ], [ 29.755449493670884, -1.436765 ], [ 29.755718987341773, -1.436765 ], [ 29.755718987341773, -1.436495517241379 ], [ 29.755449493670884, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13293, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.436495517241379 ], [ 29.755718987341773, -1.436765 ], [ 29.755988481012658, -1.436765 ], [ 29.755988481012658, -1.436495517241379 ], [ 29.755718987341773, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13294, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.436495517241379 ], [ 29.761917341772151, -1.437303965517241 ], [ 29.762186835443039, -1.437303965517241 ], [ 29.762186835443039, -1.436495517241379 ], [ 29.761917341772151, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.436495517241379 ], [ 29.762186835443039, -1.437303965517241 ], [ 29.762456329113924, -1.437303965517241 ], [ 29.762456329113924, -1.436495517241379 ], [ 29.762186835443039, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13296, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.436495517241379 ], [ 29.762995316455697, -1.437034482758621 ], [ 29.763264810126582, -1.437034482758621 ], [ 29.763264810126582, -1.436495517241379 ], [ 29.762995316455697, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13297, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.436495517241379 ], [ 29.763803797468356, -1.436765 ], [ 29.76407329113924, -1.436765 ], [ 29.76407329113924, -1.436495517241379 ], [ 29.763803797468356, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13298, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.436495517241379 ], [ 29.76407329113924, -1.436765 ], [ 29.764342784810125, -1.436765 ], [ 29.764342784810125, -1.436495517241379 ], [ 29.76407329113924, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13299, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.436495517241379 ], [ 29.764342784810125, -1.436765 ], [ 29.764612278481014, -1.436765 ], [ 29.764612278481014, -1.436495517241379 ], [ 29.764342784810125, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13300, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.436495517241379 ], [ 29.764612278481014, -1.436765 ], [ 29.764881772151899, -1.436765 ], [ 29.764881772151899, -1.436495517241379 ], [ 29.764612278481014, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13301, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.436495517241379 ], [ 29.764881772151899, -1.436765 ], [ 29.765151265822784, -1.436765 ], [ 29.765151265822784, -1.436495517241379 ], [ 29.764881772151899, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13302, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.436495517241379 ], [ 29.765151265822784, -1.436765 ], [ 29.765420759493672, -1.436765 ], [ 29.765420759493672, -1.436495517241379 ], [ 29.765151265822784, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13303, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.436495517241379 ], [ 29.765420759493672, -1.437034482758621 ], [ 29.765959746835442, -1.437034482758621 ], [ 29.765959746835442, -1.436495517241379 ], [ 29.765420759493672, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13304, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.436495517241379 ], [ 29.765959746835442, -1.436765 ], [ 29.766229240506327, -1.436765 ], [ 29.766229240506327, -1.436495517241379 ], [ 29.765959746835442, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13305, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.436495517241379 ], [ 29.766229240506327, -1.436765 ], [ 29.766498734177215, -1.436765 ], [ 29.766498734177215, -1.436495517241379 ], [ 29.766229240506327, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13306, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.436495517241379 ], [ 29.766498734177215, -1.437034482758621 ], [ 29.7667682278481, -1.437034482758621 ], [ 29.7667682278481, -1.436495517241379 ], [ 29.766498734177215, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13307, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.436495517241379 ], [ 29.7667682278481, -1.436765 ], [ 29.767037721518985, -1.436765 ], [ 29.767037721518985, -1.436495517241379 ], [ 29.7667682278481, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13308, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.436495517241379 ], [ 29.767037721518985, -1.436765 ], [ 29.767307215189874, -1.436765 ], [ 29.767307215189874, -1.436495517241379 ], [ 29.767037721518985, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13309, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.436495517241379 ], [ 29.767307215189874, -1.437034482758621 ], [ 29.767576708860759, -1.437034482758621 ], [ 29.767576708860759, -1.436495517241379 ], [ 29.767307215189874, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13310, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.436495517241379 ], [ 29.767846202531643, -1.436765 ], [ 29.768115696202532, -1.436765 ], [ 29.768115696202532, -1.436495517241379 ], [ 29.767846202531643, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13311, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.436495517241379 ], [ 29.768115696202532, -1.436765 ], [ 29.768385189873417, -1.436765 ], [ 29.768385189873417, -1.436495517241379 ], [ 29.768115696202532, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13312, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.436495517241379 ], [ 29.768654683544302, -1.436765 ], [ 29.76892417721519, -1.436765 ], [ 29.76892417721519, -1.436495517241379 ], [ 29.768654683544302, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13313, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.436495517241379 ], [ 29.76892417721519, -1.436765 ], [ 29.769193670886075, -1.436765 ], [ 29.769193670886075, -1.436495517241379 ], [ 29.76892417721519, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13314, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.436495517241379 ], [ 29.769193670886075, -1.437034482758621 ], [ 29.76946316455696, -1.437034482758621 ], [ 29.76946316455696, -1.436495517241379 ], [ 29.769193670886075, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13315, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.436495517241379 ], [ 29.76946316455696, -1.436765 ], [ 29.769732658227849, -1.436765 ], [ 29.769732658227849, -1.436495517241379 ], [ 29.76946316455696, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13316, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.436495517241379 ], [ 29.769732658227849, -1.436765 ], [ 29.770002151898733, -1.436765 ], [ 29.770002151898733, -1.436495517241379 ], [ 29.769732658227849, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13317, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.436495517241379 ], [ 29.770002151898733, -1.436765 ], [ 29.770271645569618, -1.436765 ], [ 29.770271645569618, -1.436495517241379 ], [ 29.770002151898733, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13318, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.436495517241379 ], [ 29.770541139240507, -1.436765 ], [ 29.770810632911392, -1.436765 ], [ 29.770810632911392, -1.436495517241379 ], [ 29.770541139240507, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13319, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.436495517241379 ], [ 29.770810632911392, -1.436765 ], [ 29.771080126582277, -1.436765 ], [ 29.771080126582277, -1.436495517241379 ], [ 29.770810632911392, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13320, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.436495517241379 ], [ 29.771080126582277, -1.437034482758621 ], [ 29.771349620253165, -1.437034482758621 ], [ 29.771349620253165, -1.436495517241379 ], [ 29.771080126582277, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13321, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.436495517241379 ], [ 29.771349620253165, -1.436765 ], [ 29.77161911392405, -1.436765 ], [ 29.77161911392405, -1.436495517241379 ], [ 29.771349620253165, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13322, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.436495517241379 ], [ 29.77161911392405, -1.436765 ], [ 29.771888607594935, -1.436765 ], [ 29.771888607594935, -1.436495517241379 ], [ 29.77161911392405, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13323, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.436495517241379 ], [ 29.771888607594935, -1.437034482758621 ], [ 29.772158101265823, -1.437034482758621 ], [ 29.772158101265823, -1.436495517241379 ], [ 29.771888607594935, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13324, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.436495517241379 ], [ 29.772427594936708, -1.436765 ], [ 29.772697088607593, -1.436765 ], [ 29.772697088607593, -1.436495517241379 ], [ 29.772427594936708, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13325, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.436495517241379 ], [ 29.772697088607593, -1.437034482758621 ], [ 29.772966582278482, -1.437034482758621 ], [ 29.772966582278482, -1.436495517241379 ], [ 29.772697088607593, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13326, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.436495517241379 ], [ 29.773236075949367, -1.436765 ], [ 29.773505569620252, -1.436765 ], [ 29.773505569620252, -1.436495517241379 ], [ 29.773236075949367, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13327, "El": 145 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.436495517241379 ], [ 29.773505569620252, -1.437034482758621 ], [ 29.77377506329114, -1.437034482758621 ], [ 29.77377506329114, -1.436495517241379 ], [ 29.773505569620252, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13328, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.436495517241379 ], [ 29.774044556962025, -1.437034482758621 ], [ 29.77431405063291, -1.437034482758621 ], [ 29.77431405063291, -1.436495517241379 ], [ 29.774044556962025, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13329, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.436495517241379 ], [ 29.774853037974683, -1.437303965517241 ], [ 29.775122531645568, -1.437303965517241 ], [ 29.775122531645568, -1.436495517241379 ], [ 29.774853037974683, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13330, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.436495517241379 ], [ 29.775122531645568, -1.437034482758621 ], [ 29.775392025316457, -1.437034482758621 ], [ 29.775392025316457, -1.436495517241379 ], [ 29.775122531645568, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13331, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.436495517241379 ], [ 29.775661518987341, -1.437303965517241 ], [ 29.775931012658226, -1.437303965517241 ], [ 29.775931012658226, -1.436495517241379 ], [ 29.775661518987341, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13332, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.436495517241379 ], [ 29.775931012658226, -1.437034482758621 ], [ 29.776200506329115, -1.437034482758621 ], [ 29.776200506329115, -1.436495517241379 ], [ 29.775931012658226, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13333, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.436495517241379 ], [ 29.77647, -1.437034482758621 ], [ 29.776739493670885, -1.437034482758621 ], [ 29.776739493670885, -1.436495517241379 ], [ 29.77647, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13334, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.436495517241379 ], [ 29.776739493670885, -1.436765 ], [ 29.777008987341773, -1.436765 ], [ 29.777008987341773, -1.436495517241379 ], [ 29.776739493670885, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13335, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.436495517241379 ], [ 29.777278481012658, -1.437034482758621 ], [ 29.777547974683543, -1.437034482758621 ], [ 29.777547974683543, -1.436495517241379 ], [ 29.777278481012658, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13336, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.436495517241379 ], [ 29.777547974683543, -1.436765 ], [ 29.777817468354431, -1.436765 ], [ 29.777817468354431, -1.436495517241379 ], [ 29.777547974683543, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13337, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.436495517241379 ], [ 29.778086962025316, -1.437034482758621 ], [ 29.778356455696201, -1.437034482758621 ], [ 29.778356455696201, -1.436495517241379 ], [ 29.778086962025316, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13338, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.436495517241379 ], [ 29.778356455696201, -1.436765 ], [ 29.77862594936709, -1.436765 ], [ 29.77862594936709, -1.436495517241379 ], [ 29.778356455696201, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13339, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.436495517241379 ], [ 29.77862594936709, -1.436765 ], [ 29.778895443037975, -1.436765 ], [ 29.778895443037975, -1.436495517241379 ], [ 29.77862594936709, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13340, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.436495517241379 ], [ 29.778895443037975, -1.437034482758621 ], [ 29.77916493670886, -1.437034482758621 ], [ 29.77916493670886, -1.436495517241379 ], [ 29.778895443037975, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13341, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.436495517241379 ], [ 29.77916493670886, -1.436765 ], [ 29.779434430379748, -1.436765 ], [ 29.779434430379748, -1.436495517241379 ], [ 29.77916493670886, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13342, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.436495517241379 ], [ 29.779434430379748, -1.436765 ], [ 29.779703924050633, -1.436765 ], [ 29.779703924050633, -1.436495517241379 ], [ 29.779434430379748, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13343, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.436495517241379 ], [ 29.779973417721518, -1.436765 ], [ 29.780242911392406, -1.436765 ], [ 29.780242911392406, -1.436495517241379 ], [ 29.779973417721518, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13344, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.436495517241379 ], [ 29.780242911392406, -1.436765 ], [ 29.780512405063291, -1.436765 ], [ 29.780512405063291, -1.436495517241379 ], [ 29.780242911392406, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13345, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.436495517241379 ], [ 29.780512405063291, -1.436765 ], [ 29.780781898734176, -1.436765 ], [ 29.780781898734176, -1.436495517241379 ], [ 29.780512405063291, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13346, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.436495517241379 ], [ 29.780781898734176, -1.437034482758621 ], [ 29.781051392405065, -1.437034482758621 ], [ 29.781051392405065, -1.436495517241379 ], [ 29.780781898734176, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13347, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.436495517241379 ], [ 29.781051392405065, -1.436765 ], [ 29.78132088607595, -1.436765 ], [ 29.78132088607595, -1.436495517241379 ], [ 29.781051392405065, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13348, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.436495517241379 ], [ 29.78132088607595, -1.436765 ], [ 29.781590379746834, -1.436765 ], [ 29.781590379746834, -1.436495517241379 ], [ 29.78132088607595, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13349, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.436495517241379 ], [ 29.781590379746834, -1.436765 ], [ 29.781859873417723, -1.436765 ], [ 29.781859873417723, -1.436495517241379 ], [ 29.781590379746834, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13350, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.436495517241379 ], [ 29.781859873417723, -1.436765 ], [ 29.782129367088608, -1.436765 ], [ 29.782129367088608, -1.436495517241379 ], [ 29.781859873417723, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13351, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.436495517241379 ], [ 29.782129367088608, -1.436765 ], [ 29.782398860759493, -1.436765 ], [ 29.782398860759493, -1.436495517241379 ], [ 29.782129367088608, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13352, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.436495517241379 ], [ 29.782398860759493, -1.436765 ], [ 29.782668354430381, -1.436765 ], [ 29.782668354430381, -1.436495517241379 ], [ 29.782398860759493, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13353, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.436495517241379 ], [ 29.782668354430381, -1.436765 ], [ 29.782937848101266, -1.436765 ], [ 29.782937848101266, -1.436495517241379 ], [ 29.782668354430381, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13354, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.436495517241379 ], [ 29.782937848101266, -1.436765 ], [ 29.783207341772151, -1.436765 ], [ 29.783207341772151, -1.436495517241379 ], [ 29.782937848101266, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13355, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.436495517241379 ], [ 29.783207341772151, -1.436765 ], [ 29.783476835443039, -1.436765 ], [ 29.783476835443039, -1.436495517241379 ], [ 29.783207341772151, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13356, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.436495517241379 ], [ 29.783476835443039, -1.436765 ], [ 29.784015822784809, -1.436765 ], [ 29.784015822784809, -1.436495517241379 ], [ 29.783476835443039, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13357, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.436495517241379 ], [ 29.784015822784809, -1.436765 ], [ 29.784285316455698, -1.436765 ], [ 29.784285316455698, -1.436495517241379 ], [ 29.784015822784809, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13358, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.436495517241379 ], [ 29.784285316455698, -1.436765 ], [ 29.784554810126583, -1.436765 ], [ 29.784554810126583, -1.436495517241379 ], [ 29.784285316455698, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13359, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.436495517241379 ], [ 29.784554810126583, -1.436765 ], [ 29.784824303797468, -1.436765 ], [ 29.784824303797468, -1.436495517241379 ], [ 29.784554810126583, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13360, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.436495517241379 ], [ 29.784824303797468, -1.436765 ], [ 29.785093797468356, -1.436765 ], [ 29.785093797468356, -1.436495517241379 ], [ 29.784824303797468, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13361, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.436495517241379 ], [ 29.785093797468356, -1.436765 ], [ 29.785363291139241, -1.436765 ], [ 29.785363291139241, -1.436495517241379 ], [ 29.785093797468356, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13362, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.436495517241379 ], [ 29.785363291139241, -1.436765 ], [ 29.785902278481014, -1.436765 ], [ 29.785902278481014, -1.436495517241379 ], [ 29.785363291139241, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13363, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.436495517241379 ], [ 29.785902278481014, -1.436765 ], [ 29.786980253164558, -1.436765 ], [ 29.786980253164558, -1.436495517241379 ], [ 29.785902278481014, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13364, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.436495517241379 ], [ 29.786980253164558, -1.436765 ], [ 29.787249746835442, -1.436765 ], [ 29.787249746835442, -1.436495517241379 ], [ 29.786980253164558, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13365, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.436495517241379 ], [ 29.787249746835442, -1.436765 ], [ 29.789405696202532, -1.436765 ], [ 29.789405696202532, -1.436495517241379 ], [ 29.787249746835442, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13366, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.436495517241379 ], [ 29.789405696202532, -1.436765 ], [ 29.789675189873417, -1.436765 ], [ 29.789675189873417, -1.436495517241379 ], [ 29.789405696202532, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13367, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.436495517241379 ], [ 29.789675189873417, -1.436765 ], [ 29.789944683544302, -1.436765 ], [ 29.789944683544302, -1.436495517241379 ], [ 29.789675189873417, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13368, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.436495517241379 ], [ 29.789944683544302, -1.436765 ], [ 29.790214177215191, -1.436765 ], [ 29.790214177215191, -1.436495517241379 ], [ 29.789944683544302, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13369, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.436495517241379 ], [ 29.790214177215191, -1.436765 ], [ 29.790483670886076, -1.436765 ], [ 29.790483670886076, -1.436495517241379 ], [ 29.790214177215191, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13370, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.436495517241379 ], [ 29.790483670886076, -1.436765 ], [ 29.790753164556961, -1.436765 ], [ 29.790753164556961, -1.436495517241379 ], [ 29.790483670886076, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13371, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.436495517241379 ], [ 29.790753164556961, -1.436765 ], [ 29.791022658227849, -1.436765 ], [ 29.791022658227849, -1.436495517241379 ], [ 29.790753164556961, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13372, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.436495517241379 ], [ 29.791022658227849, -1.436765 ], [ 29.791292151898734, -1.436765 ], [ 29.791292151898734, -1.436495517241379 ], [ 29.791022658227849, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13373, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.436495517241379 ], [ 29.791292151898734, -1.436765 ], [ 29.791561645569619, -1.436765 ], [ 29.791561645569619, -1.436495517241379 ], [ 29.791292151898734, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13374, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.436495517241379 ], [ 29.791561645569619, -1.436765 ], [ 29.791831139240507, -1.436765 ], [ 29.791831139240507, -1.436495517241379 ], [ 29.791561645569619, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13375, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.436495517241379 ], [ 29.791831139240507, -1.436765 ], [ 29.792100632911392, -1.436765 ], [ 29.792100632911392, -1.436495517241379 ], [ 29.791831139240507, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13376, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.436495517241379 ], [ 29.792100632911392, -1.436765 ], [ 29.792370126582277, -1.436765 ], [ 29.792370126582277, -1.436495517241379 ], [ 29.792100632911392, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13377, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.436495517241379 ], [ 29.792370126582277, -1.436765 ], [ 29.792909113924051, -1.436765 ], [ 29.792909113924051, -1.436495517241379 ], [ 29.792370126582277, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13378, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.436495517241379 ], [ 29.792909113924051, -1.436765 ], [ 29.793178607594935, -1.436765 ], [ 29.793178607594935, -1.436495517241379 ], [ 29.792909113924051, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13379, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.436495517241379 ], [ 29.793178607594935, -1.436765 ], [ 29.793448101265824, -1.436765 ], [ 29.793448101265824, -1.436495517241379 ], [ 29.793178607594935, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13380, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.436495517241379 ], [ 29.793448101265824, -1.436765 ], [ 29.793717594936709, -1.436765 ], [ 29.793717594936709, -1.436495517241379 ], [ 29.793448101265824, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13381, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.436495517241379 ], [ 29.793717594936709, -1.436765 ], [ 29.793987088607594, -1.436765 ], [ 29.793987088607594, -1.436495517241379 ], [ 29.793717594936709, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13382, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.436495517241379 ], [ 29.793987088607594, -1.436765 ], [ 29.794256582278482, -1.436765 ], [ 29.794256582278482, -1.436495517241379 ], [ 29.793987088607594, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13383, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.436495517241379 ], [ 29.794256582278482, -1.436765 ], [ 29.794526075949367, -1.436765 ], [ 29.794526075949367, -1.436495517241379 ], [ 29.794256582278482, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13384, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.436495517241379 ], [ 29.794526075949367, -1.436765 ], [ 29.794795569620252, -1.436765 ], [ 29.794795569620252, -1.436495517241379 ], [ 29.794526075949367, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13385, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.436495517241379 ], [ 29.794795569620252, -1.436765 ], [ 29.79506506329114, -1.436765 ], [ 29.79506506329114, -1.436495517241379 ], [ 29.794795569620252, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13386, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.436495517241379 ], [ 29.79506506329114, -1.436765 ], [ 29.795334556962025, -1.436765 ], [ 29.795334556962025, -1.436495517241379 ], [ 29.79506506329114, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13387, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.436495517241379 ], [ 29.795334556962025, -1.436765 ], [ 29.79560405063291, -1.436765 ], [ 29.79560405063291, -1.436495517241379 ], [ 29.795334556962025, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13388, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.436495517241379 ], [ 29.79560405063291, -1.436765 ], [ 29.795873544303799, -1.436765 ], [ 29.795873544303799, -1.436495517241379 ], [ 29.79560405063291, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13389, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.436495517241379 ], [ 29.795873544303799, -1.436765 ], [ 29.796143037974684, -1.436765 ], [ 29.796143037974684, -1.436495517241379 ], [ 29.795873544303799, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13390, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.436495517241379 ], [ 29.796143037974684, -1.436765 ], [ 29.796682025316457, -1.436765 ], [ 29.796682025316457, -1.436495517241379 ], [ 29.796143037974684, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13391, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.436495517241379 ], [ 29.796682025316457, -1.436765 ], [ 29.79776, -1.436765 ], [ 29.79776, -1.436495517241379 ], [ 29.796682025316457, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13392, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.436495517241379 ], [ 29.79776, -1.436765 ], [ 29.798298987341774, -1.436765 ], [ 29.798298987341774, -1.436495517241379 ], [ 29.79776, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13393, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.436495517241379 ], [ 29.798298987341774, -1.436765 ], [ 29.798568481012659, -1.436765 ], [ 29.798568481012659, -1.436495517241379 ], [ 29.798298987341774, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13394, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.436495517241379 ], [ 29.798568481012659, -1.436765 ], [ 29.798837974683543, -1.436765 ], [ 29.798837974683543, -1.436495517241379 ], [ 29.798568481012659, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13395, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.436495517241379 ], [ 29.798837974683543, -1.436765 ], [ 29.799107468354432, -1.436765 ], [ 29.799107468354432, -1.436495517241379 ], [ 29.798837974683543, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13396, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.436495517241379 ], [ 29.799107468354432, -1.436765 ], [ 29.799376962025317, -1.436765 ], [ 29.799376962025317, -1.436495517241379 ], [ 29.799107468354432, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13397, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.436495517241379 ], [ 29.799376962025317, -1.436765 ], [ 29.799646455696202, -1.436765 ], [ 29.799646455696202, -1.436495517241379 ], [ 29.799376962025317, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13398, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.436495517241379 ], [ 29.799646455696202, -1.436765 ], [ 29.79991594936709, -1.436765 ], [ 29.79991594936709, -1.436495517241379 ], [ 29.799646455696202, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13399, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.436495517241379 ], [ 29.79991594936709, -1.436765 ], [ 29.800185443037975, -1.436765 ], [ 29.800185443037975, -1.436495517241379 ], [ 29.79991594936709, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13400, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.436495517241379 ], [ 29.800185443037975, -1.437034482758621 ], [ 29.80045493670886, -1.437034482758621 ], [ 29.80045493670886, -1.436495517241379 ], [ 29.800185443037975, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13401, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.436495517241379 ], [ 29.800993924050633, -1.437034482758621 ], [ 29.801263417721518, -1.437034482758621 ], [ 29.801263417721518, -1.436495517241379 ], [ 29.800993924050633, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13402, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.436495517241379 ], [ 29.801532911392407, -1.436765 ], [ 29.802071898734177, -1.436765 ], [ 29.802071898734177, -1.436495517241379 ], [ 29.801532911392407, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13403, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.436495517241379 ], [ 29.802071898734177, -1.436765 ], [ 29.802341392405065, -1.436765 ], [ 29.802341392405065, -1.436495517241379 ], [ 29.802071898734177, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13404, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.436495517241379 ], [ 29.80261088607595, -1.436765 ], [ 29.802880379746835, -1.436765 ], [ 29.802880379746835, -1.436495517241379 ], [ 29.80261088607595, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13405, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.436495517241379 ], [ 29.802880379746835, -1.436765 ], [ 29.803149873417723, -1.436765 ], [ 29.803149873417723, -1.436495517241379 ], [ 29.802880379746835, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13406, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.436495517241379 ], [ 29.803149873417723, -1.436765 ], [ 29.803419367088608, -1.436765 ], [ 29.803419367088608, -1.436495517241379 ], [ 29.803149873417723, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13407, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.436495517241379 ], [ 29.803419367088608, -1.436765 ], [ 29.803688860759493, -1.436765 ], [ 29.803688860759493, -1.436495517241379 ], [ 29.803419367088608, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13408, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.436495517241379 ], [ 29.803688860759493, -1.436765 ], [ 29.803958354430382, -1.436765 ], [ 29.803958354430382, -1.436495517241379 ], [ 29.803688860759493, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13409, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.436495517241379 ], [ 29.803958354430382, -1.436765 ], [ 29.804227848101267, -1.436765 ], [ 29.804227848101267, -1.436495517241379 ], [ 29.803958354430382, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13410, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.436495517241379 ], [ 29.804227848101267, -1.436765 ], [ 29.804497341772151, -1.436765 ], [ 29.804497341772151, -1.436495517241379 ], [ 29.804227848101267, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13411, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.436495517241379 ], [ 29.804497341772151, -1.436765 ], [ 29.80530582278481, -1.436765 ], [ 29.80530582278481, -1.436495517241379 ], [ 29.804497341772151, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13412, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.436495517241379 ], [ 29.806114303797468, -1.436765 ], [ 29.806383797468357, -1.436765 ], [ 29.806383797468357, -1.436495517241379 ], [ 29.806114303797468, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13413, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.436495517241379 ], [ 29.806383797468357, -1.436765 ], [ 29.806653291139241, -1.436765 ], [ 29.806653291139241, -1.436495517241379 ], [ 29.806383797468357, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13414, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.436495517241379 ], [ 29.806653291139241, -1.436765 ], [ 29.806922784810126, -1.436765 ], [ 29.806922784810126, -1.436495517241379 ], [ 29.806653291139241, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13415, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.436495517241379 ], [ 29.806922784810126, -1.436765 ], [ 29.807192278481015, -1.436765 ], [ 29.807192278481015, -1.436495517241379 ], [ 29.806922784810126, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13416, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.436495517241379 ], [ 29.807192278481015, -1.436765 ], [ 29.8074617721519, -1.436765 ], [ 29.8074617721519, -1.436495517241379 ], [ 29.807192278481015, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13417, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.436495517241379 ], [ 29.8074617721519, -1.436765 ], [ 29.807731265822785, -1.436765 ], [ 29.807731265822785, -1.436495517241379 ], [ 29.8074617721519, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13418, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.436495517241379 ], [ 29.807731265822785, -1.436765 ], [ 29.808000759493673, -1.436765 ], [ 29.808000759493673, -1.436495517241379 ], [ 29.807731265822785, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13419, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.436495517241379 ], [ 29.808000759493673, -1.436765 ], [ 29.808270253164558, -1.436765 ], [ 29.808270253164558, -1.436495517241379 ], [ 29.808000759493673, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13420, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.436495517241379 ], [ 29.808270253164558, -1.436765 ], [ 29.808539746835443, -1.436765 ], [ 29.808539746835443, -1.436495517241379 ], [ 29.808270253164558, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13421, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.436495517241379 ], [ 29.808539746835443, -1.436765 ], [ 29.809078734177216, -1.436765 ], [ 29.809078734177216, -1.436495517241379 ], [ 29.808539746835443, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13422, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.436765 ], [ 29.743861265822783, -1.437034482758621 ], [ 29.744130759493672, -1.437034482758621 ], [ 29.744130759493672, -1.436765 ], [ 29.743861265822783, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13423, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.436765 ], [ 29.744130759493672, -1.437034482758621 ], [ 29.744400253164557, -1.437034482758621 ], [ 29.744400253164557, -1.436765 ], [ 29.744130759493672, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13424, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.436765 ], [ 29.744400253164557, -1.437034482758621 ], [ 29.744669746835442, -1.437034482758621 ], [ 29.744669746835442, -1.436765 ], [ 29.744400253164557, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13425, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.436765 ], [ 29.744669746835442, -1.437034482758621 ], [ 29.744939240506326, -1.437034482758621 ], [ 29.744939240506326, -1.436765 ], [ 29.744669746835442, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13426, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.436765 ], [ 29.744939240506326, -1.437034482758621 ], [ 29.745208734177215, -1.437034482758621 ], [ 29.745208734177215, -1.436765 ], [ 29.744939240506326, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13427, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.436765 ], [ 29.745208734177215, -1.437034482758621 ], [ 29.7454782278481, -1.437034482758621 ], [ 29.7454782278481, -1.436765 ], [ 29.745208734177215, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13428, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.436765 ], [ 29.7454782278481, -1.437034482758621 ], [ 29.745747721518985, -1.437034482758621 ], [ 29.745747721518985, -1.436765 ], [ 29.7454782278481, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13429, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.436765 ], [ 29.745747721518985, -1.437034482758621 ], [ 29.746017215189873, -1.437034482758621 ], [ 29.746017215189873, -1.436765 ], [ 29.745747721518985, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13430, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.436765 ], [ 29.746017215189873, -1.437034482758621 ], [ 29.746286708860758, -1.437034482758621 ], [ 29.746286708860758, -1.436765 ], [ 29.746017215189873, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13431, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.436765 ], [ 29.746286708860758, -1.437034482758621 ], [ 29.746556202531643, -1.437034482758621 ], [ 29.746556202531643, -1.436765 ], [ 29.746286708860758, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13432, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.436765 ], [ 29.746556202531643, -1.437034482758621 ], [ 29.746825696202531, -1.437034482758621 ], [ 29.746825696202531, -1.436765 ], [ 29.746556202531643, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13433, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.436765 ], [ 29.746825696202531, -1.437034482758621 ], [ 29.747095189873416, -1.437034482758621 ], [ 29.747095189873416, -1.436765 ], [ 29.746825696202531, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13434, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.436765 ], [ 29.747095189873416, -1.437034482758621 ], [ 29.747364683544301, -1.437034482758621 ], [ 29.747364683544301, -1.436765 ], [ 29.747095189873416, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13435, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.436765 ], [ 29.747364683544301, -1.437034482758621 ], [ 29.747903670886075, -1.437034482758621 ], [ 29.747903670886075, -1.436765 ], [ 29.747364683544301, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13436, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.436765 ], [ 29.747903670886075, -1.437034482758621 ], [ 29.74817316455696, -1.437034482758621 ], [ 29.74817316455696, -1.436765 ], [ 29.747903670886075, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13437, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.436765 ], [ 29.74817316455696, -1.437034482758621 ], [ 29.748712151898733, -1.437034482758621 ], [ 29.748712151898733, -1.436765 ], [ 29.74817316455696, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13438, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.436765 ], [ 29.748712151898733, -1.437034482758621 ], [ 29.748981645569618, -1.437034482758621 ], [ 29.748981645569618, -1.436765 ], [ 29.748712151898733, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13439, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.436765 ], [ 29.748981645569618, -1.437034482758621 ], [ 29.749251139240506, -1.437034482758621 ], [ 29.749251139240506, -1.436765 ], [ 29.748981645569618, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13440, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.436765 ], [ 29.749251139240506, -1.437034482758621 ], [ 29.749520632911391, -1.437034482758621 ], [ 29.749520632911391, -1.436765 ], [ 29.749251139240506, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13441, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.436765 ], [ 29.749520632911391, -1.437034482758621 ], [ 29.749790126582276, -1.437034482758621 ], [ 29.749790126582276, -1.436765 ], [ 29.749520632911391, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13442, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.436765 ], [ 29.749790126582276, -1.437034482758621 ], [ 29.750059620253165, -1.437034482758621 ], [ 29.750059620253165, -1.436765 ], [ 29.749790126582276, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13443, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.436765 ], [ 29.750059620253165, -1.437034482758621 ], [ 29.75032911392405, -1.437034482758621 ], [ 29.75032911392405, -1.436765 ], [ 29.750059620253165, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13444, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.436765 ], [ 29.75032911392405, -1.437034482758621 ], [ 29.750598607594934, -1.437034482758621 ], [ 29.750598607594934, -1.436765 ], [ 29.75032911392405, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13445, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.436765 ], [ 29.750598607594934, -1.437034482758621 ], [ 29.754641012658226, -1.437034482758621 ], [ 29.754641012658226, -1.436765 ], [ 29.750598607594934, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13446, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.436765 ], [ 29.754641012658226, -1.437034482758621 ], [ 29.754910506329114, -1.437034482758621 ], [ 29.754910506329114, -1.436765 ], [ 29.754641012658226, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13447, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.436765 ], [ 29.754910506329114, -1.437034482758621 ], [ 29.75518, -1.437034482758621 ], [ 29.75518, -1.436765 ], [ 29.754910506329114, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13448, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.436765 ], [ 29.75518, -1.437034482758621 ], [ 29.755449493670884, -1.437034482758621 ], [ 29.755449493670884, -1.436765 ], [ 29.75518, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13449, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.436765 ], [ 29.755449493670884, -1.437034482758621 ], [ 29.755988481012658, -1.437034482758621 ], [ 29.755988481012658, -1.436765 ], [ 29.755449493670884, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13450, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.436765 ], [ 29.763264810126582, -1.437034482758621 ], [ 29.763534303797467, -1.437034482758621 ], [ 29.763534303797467, -1.436765 ], [ 29.763264810126582, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13451, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.436765 ], [ 29.763534303797467, -1.437034482758621 ], [ 29.763803797468356, -1.437034482758621 ], [ 29.763803797468356, -1.436765 ], [ 29.763534303797467, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13452, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.436765 ], [ 29.763803797468356, -1.437034482758621 ], [ 29.76407329113924, -1.437034482758621 ], [ 29.76407329113924, -1.436765 ], [ 29.763803797468356, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13453, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.436765 ], [ 29.76407329113924, -1.437034482758621 ], [ 29.764342784810125, -1.437034482758621 ], [ 29.764342784810125, -1.436765 ], [ 29.76407329113924, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13454, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.436765 ], [ 29.764342784810125, -1.437303965517241 ], [ 29.764612278481014, -1.437303965517241 ], [ 29.764612278481014, -1.436765 ], [ 29.764342784810125, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13455, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.436765 ], [ 29.764612278481014, -1.437034482758621 ], [ 29.764881772151899, -1.437034482758621 ], [ 29.764881772151899, -1.436765 ], [ 29.764612278481014, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13456, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.436765 ], [ 29.764881772151899, -1.437034482758621 ], [ 29.765151265822784, -1.437034482758621 ], [ 29.765151265822784, -1.436765 ], [ 29.764881772151899, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13457, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.436765 ], [ 29.765151265822784, -1.437303965517241 ], [ 29.765420759493672, -1.437303965517241 ], [ 29.765420759493672, -1.436765 ], [ 29.765151265822784, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13458, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.436765 ], [ 29.765959746835442, -1.437034482758621 ], [ 29.766229240506327, -1.437034482758621 ], [ 29.766229240506327, -1.436765 ], [ 29.765959746835442, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13459, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.436765 ], [ 29.766229240506327, -1.437303965517241 ], [ 29.766498734177215, -1.437303965517241 ], [ 29.766498734177215, -1.436765 ], [ 29.766229240506327, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13460, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.436765 ], [ 29.7667682278481, -1.437034482758621 ], [ 29.767037721518985, -1.437034482758621 ], [ 29.767037721518985, -1.436765 ], [ 29.7667682278481, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13461, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.436765 ], [ 29.767037721518985, -1.437034482758621 ], [ 29.767307215189874, -1.437034482758621 ], [ 29.767307215189874, -1.436765 ], [ 29.767037721518985, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13462, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.436765 ], [ 29.767576708860759, -1.437034482758621 ], [ 29.767846202531643, -1.437034482758621 ], [ 29.767846202531643, -1.436765 ], [ 29.767576708860759, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13463, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.436765 ], [ 29.767846202531643, -1.437034482758621 ], [ 29.768115696202532, -1.437034482758621 ], [ 29.768115696202532, -1.436765 ], [ 29.767846202531643, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13464, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.436765 ], [ 29.768115696202532, -1.437303965517241 ], [ 29.768385189873417, -1.437303965517241 ], [ 29.768385189873417, -1.436765 ], [ 29.768115696202532, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13465, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.436765 ], [ 29.768385189873417, -1.437034482758621 ], [ 29.768654683544302, -1.437034482758621 ], [ 29.768654683544302, -1.436765 ], [ 29.768385189873417, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13466, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.436765 ], [ 29.768654683544302, -1.437034482758621 ], [ 29.76892417721519, -1.437034482758621 ], [ 29.76892417721519, -1.436765 ], [ 29.768654683544302, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13467, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.436765 ], [ 29.76892417721519, -1.437034482758621 ], [ 29.769193670886075, -1.437034482758621 ], [ 29.769193670886075, -1.436765 ], [ 29.76892417721519, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13468, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.436765 ], [ 29.76946316455696, -1.437034482758621 ], [ 29.769732658227849, -1.437034482758621 ], [ 29.769732658227849, -1.436765 ], [ 29.76946316455696, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13469, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.436765 ], [ 29.769732658227849, -1.437034482758621 ], [ 29.770002151898733, -1.437034482758621 ], [ 29.770002151898733, -1.436765 ], [ 29.769732658227849, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13470, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.436765 ], [ 29.770002151898733, -1.437303965517241 ], [ 29.770271645569618, -1.437303965517241 ], [ 29.770271645569618, -1.436765 ], [ 29.770002151898733, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13471, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.436765 ], [ 29.770271645569618, -1.437034482758621 ], [ 29.770541139240507, -1.437034482758621 ], [ 29.770541139240507, -1.436765 ], [ 29.770271645569618, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13472, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.436765 ], [ 29.770541139240507, -1.437034482758621 ], [ 29.770810632911392, -1.437034482758621 ], [ 29.770810632911392, -1.436765 ], [ 29.770541139240507, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13473, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.436765 ], [ 29.770810632911392, -1.437303965517241 ], [ 29.771080126582277, -1.437303965517241 ], [ 29.771080126582277, -1.436765 ], [ 29.770810632911392, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13474, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.436765 ], [ 29.771349620253165, -1.437034482758621 ], [ 29.77161911392405, -1.437034482758621 ], [ 29.77161911392405, -1.436765 ], [ 29.771349620253165, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13475, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.436765 ], [ 29.77161911392405, -1.437303965517241 ], [ 29.771888607594935, -1.437303965517241 ], [ 29.771888607594935, -1.436765 ], [ 29.77161911392405, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13476, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.436765 ], [ 29.772158101265823, -1.437034482758621 ], [ 29.772427594936708, -1.437034482758621 ], [ 29.772427594936708, -1.436765 ], [ 29.772158101265823, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13477, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.436765 ], [ 29.772427594936708, -1.437303965517241 ], [ 29.772697088607593, -1.437303965517241 ], [ 29.772697088607593, -1.436765 ], [ 29.772427594936708, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13478, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.436765 ], [ 29.772966582278482, -1.437034482758621 ], [ 29.773236075949367, -1.437034482758621 ], [ 29.773236075949367, -1.436765 ], [ 29.772966582278482, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13479, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.436765 ], [ 29.773236075949367, -1.437303965517241 ], [ 29.773505569620252, -1.437303965517241 ], [ 29.773505569620252, -1.436765 ], [ 29.773236075949367, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13480, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.436765 ], [ 29.776200506329115, -1.437303965517241 ], [ 29.77647, -1.437303965517241 ], [ 29.77647, -1.436765 ], [ 29.776200506329115, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13481, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.436765 ], [ 29.777008987341773, -1.437303965517241 ], [ 29.777278481012658, -1.437303965517241 ], [ 29.777278481012658, -1.436765 ], [ 29.777008987341773, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13482, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.436765 ], [ 29.777547974683543, -1.437303965517241 ], [ 29.777817468354431, -1.437303965517241 ], [ 29.777817468354431, -1.436765 ], [ 29.777547974683543, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13483, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.436765 ], [ 29.777817468354431, -1.437303965517241 ], [ 29.778086962025316, -1.437303965517241 ], [ 29.778086962025316, -1.436765 ], [ 29.777817468354431, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13484, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.436765 ], [ 29.778356455696201, -1.437303965517241 ], [ 29.77862594936709, -1.437303965517241 ], [ 29.77862594936709, -1.436765 ], [ 29.778356455696201, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13485, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.436765 ], [ 29.77862594936709, -1.437034482758621 ], [ 29.778895443037975, -1.437034482758621 ], [ 29.778895443037975, -1.436765 ], [ 29.77862594936709, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13486, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.436765 ], [ 29.77916493670886, -1.437303965517241 ], [ 29.779434430379748, -1.437303965517241 ], [ 29.779434430379748, -1.436765 ], [ 29.77916493670886, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13487, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.436765 ], [ 29.779434430379748, -1.437034482758621 ], [ 29.779703924050633, -1.437034482758621 ], [ 29.779703924050633, -1.436765 ], [ 29.779434430379748, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13488, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.436765 ], [ 29.779703924050633, -1.437034482758621 ], [ 29.779973417721518, -1.437034482758621 ], [ 29.779973417721518, -1.436765 ], [ 29.779703924050633, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13489, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.436765 ], [ 29.779973417721518, -1.437303965517241 ], [ 29.780242911392406, -1.437303965517241 ], [ 29.780242911392406, -1.436765 ], [ 29.779973417721518, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13490, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.436765 ], [ 29.780242911392406, -1.437034482758621 ], [ 29.780512405063291, -1.437034482758621 ], [ 29.780512405063291, -1.436765 ], [ 29.780242911392406, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13491, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.436765 ], [ 29.780512405063291, -1.437034482758621 ], [ 29.780781898734176, -1.437034482758621 ], [ 29.780781898734176, -1.436765 ], [ 29.780512405063291, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13492, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.436765 ], [ 29.781051392405065, -1.437034482758621 ], [ 29.78132088607595, -1.437034482758621 ], [ 29.78132088607595, -1.436765 ], [ 29.781051392405065, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13493, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.436765 ], [ 29.78132088607595, -1.437034482758621 ], [ 29.781590379746834, -1.437034482758621 ], [ 29.781590379746834, -1.436765 ], [ 29.78132088607595, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13494, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.436765 ], [ 29.781590379746834, -1.437034482758621 ], [ 29.781859873417723, -1.437034482758621 ], [ 29.781859873417723, -1.436765 ], [ 29.781590379746834, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13495, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.436765 ], [ 29.781859873417723, -1.437034482758621 ], [ 29.782129367088608, -1.437034482758621 ], [ 29.782129367088608, -1.436765 ], [ 29.781859873417723, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13496, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.436765 ], [ 29.782129367088608, -1.437034482758621 ], [ 29.782398860759493, -1.437034482758621 ], [ 29.782398860759493, -1.436765 ], [ 29.782129367088608, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13497, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.436765 ], [ 29.782398860759493, -1.437034482758621 ], [ 29.782668354430381, -1.437034482758621 ], [ 29.782668354430381, -1.436765 ], [ 29.782398860759493, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13498, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.436765 ], [ 29.782668354430381, -1.437034482758621 ], [ 29.782937848101266, -1.437034482758621 ], [ 29.782937848101266, -1.436765 ], [ 29.782668354430381, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13499, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.436765 ], [ 29.782937848101266, -1.437034482758621 ], [ 29.783207341772151, -1.437034482758621 ], [ 29.783207341772151, -1.436765 ], [ 29.782937848101266, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13500, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.436765 ], [ 29.783207341772151, -1.437034482758621 ], [ 29.783476835443039, -1.437034482758621 ], [ 29.783476835443039, -1.436765 ], [ 29.783207341772151, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13501, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.436765 ], [ 29.783476835443039, -1.437034482758621 ], [ 29.784015822784809, -1.437034482758621 ], [ 29.784015822784809, -1.436765 ], [ 29.783476835443039, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13502, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.436765 ], [ 29.784015822784809, -1.437034482758621 ], [ 29.784285316455698, -1.437034482758621 ], [ 29.784285316455698, -1.436765 ], [ 29.784015822784809, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13503, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.436765 ], [ 29.784285316455698, -1.437034482758621 ], [ 29.784554810126583, -1.437034482758621 ], [ 29.784554810126583, -1.436765 ], [ 29.784285316455698, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13504, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.436765 ], [ 29.784554810126583, -1.437034482758621 ], [ 29.784824303797468, -1.437034482758621 ], [ 29.784824303797468, -1.436765 ], [ 29.784554810126583, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13505, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.436765 ], [ 29.784824303797468, -1.437034482758621 ], [ 29.785093797468356, -1.437034482758621 ], [ 29.785093797468356, -1.436765 ], [ 29.784824303797468, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13506, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.436765 ], [ 29.785093797468356, -1.437034482758621 ], [ 29.785363291139241, -1.437034482758621 ], [ 29.785363291139241, -1.436765 ], [ 29.785093797468356, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13507, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.436765 ], [ 29.785363291139241, -1.437034482758621 ], [ 29.785632784810126, -1.437034482758621 ], [ 29.785632784810126, -1.436765 ], [ 29.785363291139241, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13508, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.436765 ], [ 29.785632784810126, -1.437034482758621 ], [ 29.786980253164558, -1.437034482758621 ], [ 29.786980253164558, -1.436765 ], [ 29.785632784810126, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13509, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.436765 ], [ 29.786980253164558, -1.437034482758621 ], [ 29.787249746835442, -1.437034482758621 ], [ 29.787249746835442, -1.436765 ], [ 29.786980253164558, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13510, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.436765 ], [ 29.787249746835442, -1.437034482758621 ], [ 29.789405696202532, -1.437034482758621 ], [ 29.789405696202532, -1.436765 ], [ 29.787249746835442, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13511, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.436765 ], [ 29.789405696202532, -1.437034482758621 ], [ 29.789675189873417, -1.437034482758621 ], [ 29.789675189873417, -1.436765 ], [ 29.789405696202532, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13512, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.436765 ], [ 29.789675189873417, -1.437034482758621 ], [ 29.789944683544302, -1.437034482758621 ], [ 29.789944683544302, -1.436765 ], [ 29.789675189873417, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13513, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.436765 ], [ 29.789944683544302, -1.437034482758621 ], [ 29.790214177215191, -1.437034482758621 ], [ 29.790214177215191, -1.436765 ], [ 29.789944683544302, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13514, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.436765 ], [ 29.790214177215191, -1.437034482758621 ], [ 29.790483670886076, -1.437034482758621 ], [ 29.790483670886076, -1.436765 ], [ 29.790214177215191, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13515, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.436765 ], [ 29.790483670886076, -1.437034482758621 ], [ 29.790753164556961, -1.437034482758621 ], [ 29.790753164556961, -1.436765 ], [ 29.790483670886076, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13516, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.436765 ], [ 29.790753164556961, -1.437034482758621 ], [ 29.791022658227849, -1.437034482758621 ], [ 29.791022658227849, -1.436765 ], [ 29.790753164556961, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13517, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.436765 ], [ 29.791022658227849, -1.437034482758621 ], [ 29.791292151898734, -1.437034482758621 ], [ 29.791292151898734, -1.436765 ], [ 29.791022658227849, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13518, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.436765 ], [ 29.791292151898734, -1.437034482758621 ], [ 29.791561645569619, -1.437034482758621 ], [ 29.791561645569619, -1.436765 ], [ 29.791292151898734, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13519, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.436765 ], [ 29.791561645569619, -1.437034482758621 ], [ 29.791831139240507, -1.437034482758621 ], [ 29.791831139240507, -1.436765 ], [ 29.791561645569619, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13520, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.436765 ], [ 29.791831139240507, -1.437034482758621 ], [ 29.792100632911392, -1.437034482758621 ], [ 29.792100632911392, -1.436765 ], [ 29.791831139240507, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13521, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.436765 ], [ 29.792100632911392, -1.437034482758621 ], [ 29.792370126582277, -1.437034482758621 ], [ 29.792370126582277, -1.436765 ], [ 29.792100632911392, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13522, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.436765 ], [ 29.792370126582277, -1.437034482758621 ], [ 29.792909113924051, -1.437034482758621 ], [ 29.792909113924051, -1.436765 ], [ 29.792370126582277, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13523, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.436765 ], [ 29.792909113924051, -1.437034482758621 ], [ 29.793178607594935, -1.437034482758621 ], [ 29.793178607594935, -1.436765 ], [ 29.792909113924051, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13524, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.436765 ], [ 29.793178607594935, -1.437034482758621 ], [ 29.793448101265824, -1.437034482758621 ], [ 29.793448101265824, -1.436765 ], [ 29.793178607594935, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13525, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.436765 ], [ 29.793448101265824, -1.437034482758621 ], [ 29.793717594936709, -1.437034482758621 ], [ 29.793717594936709, -1.436765 ], [ 29.793448101265824, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13526, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.436765 ], [ 29.793717594936709, -1.437034482758621 ], [ 29.793987088607594, -1.437034482758621 ], [ 29.793987088607594, -1.436765 ], [ 29.793717594936709, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13527, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.436765 ], [ 29.793987088607594, -1.437034482758621 ], [ 29.794256582278482, -1.437034482758621 ], [ 29.794256582278482, -1.436765 ], [ 29.793987088607594, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13528, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.436765 ], [ 29.794256582278482, -1.437034482758621 ], [ 29.794526075949367, -1.437034482758621 ], [ 29.794526075949367, -1.436765 ], [ 29.794256582278482, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13529, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.436765 ], [ 29.794526075949367, -1.437034482758621 ], [ 29.794795569620252, -1.437034482758621 ], [ 29.794795569620252, -1.436765 ], [ 29.794526075949367, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13530, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.436765 ], [ 29.794795569620252, -1.437034482758621 ], [ 29.79506506329114, -1.437034482758621 ], [ 29.79506506329114, -1.436765 ], [ 29.794795569620252, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13531, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.436765 ], [ 29.79506506329114, -1.437034482758621 ], [ 29.795334556962025, -1.437034482758621 ], [ 29.795334556962025, -1.436765 ], [ 29.79506506329114, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13532, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.436765 ], [ 29.795334556962025, -1.437034482758621 ], [ 29.79560405063291, -1.437034482758621 ], [ 29.79560405063291, -1.436765 ], [ 29.795334556962025, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13533, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.436765 ], [ 29.79560405063291, -1.437034482758621 ], [ 29.795873544303799, -1.437034482758621 ], [ 29.795873544303799, -1.436765 ], [ 29.79560405063291, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13534, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.436765 ], [ 29.795873544303799, -1.437034482758621 ], [ 29.796143037974684, -1.437034482758621 ], [ 29.796143037974684, -1.436765 ], [ 29.795873544303799, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13535, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.436765 ], [ 29.796143037974684, -1.437034482758621 ], [ 29.796412531645569, -1.437034482758621 ], [ 29.796412531645569, -1.436765 ], [ 29.796143037974684, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13536, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.436765 ], [ 29.796412531645569, -1.437034482758621 ], [ 29.796951518987342, -1.437034482758621 ], [ 29.796951518987342, -1.436765 ], [ 29.796412531645569, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13537, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.436765 ], [ 29.796951518987342, -1.437034482758621 ], [ 29.797490506329115, -1.437034482758621 ], [ 29.797490506329115, -1.436765 ], [ 29.796951518987342, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13538, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.436765 ], [ 29.797490506329115, -1.437034482758621 ], [ 29.798029493670885, -1.437034482758621 ], [ 29.798029493670885, -1.436765 ], [ 29.797490506329115, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13539, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.436765 ], [ 29.798029493670885, -1.437034482758621 ], [ 29.798298987341774, -1.437034482758621 ], [ 29.798298987341774, -1.436765 ], [ 29.798029493670885, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13540, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.436765 ], [ 29.798298987341774, -1.437034482758621 ], [ 29.798568481012659, -1.437034482758621 ], [ 29.798568481012659, -1.436765 ], [ 29.798298987341774, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13541, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.436765 ], [ 29.798568481012659, -1.437034482758621 ], [ 29.798837974683543, -1.437034482758621 ], [ 29.798837974683543, -1.436765 ], [ 29.798568481012659, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13542, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.436765 ], [ 29.798837974683543, -1.437034482758621 ], [ 29.799107468354432, -1.437034482758621 ], [ 29.799107468354432, -1.436765 ], [ 29.798837974683543, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13543, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.436765 ], [ 29.799107468354432, -1.437034482758621 ], [ 29.799376962025317, -1.437034482758621 ], [ 29.799376962025317, -1.436765 ], [ 29.799107468354432, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13544, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.436765 ], [ 29.799376962025317, -1.437034482758621 ], [ 29.799646455696202, -1.437034482758621 ], [ 29.799646455696202, -1.436765 ], [ 29.799376962025317, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13545, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.436765 ], [ 29.799646455696202, -1.437034482758621 ], [ 29.79991594936709, -1.437034482758621 ], [ 29.79991594936709, -1.436765 ], [ 29.799646455696202, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13546, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.436765 ], [ 29.79991594936709, -1.437303965517241 ], [ 29.800185443037975, -1.437303965517241 ], [ 29.800185443037975, -1.436765 ], [ 29.79991594936709, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13547, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.436765 ], [ 29.80045493670886, -1.437034482758621 ], [ 29.800724430379748, -1.437034482758621 ], [ 29.800724430379748, -1.436765 ], [ 29.80045493670886, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13548, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.436765 ], [ 29.800724430379748, -1.437034482758621 ], [ 29.800993924050633, -1.437034482758621 ], [ 29.800993924050633, -1.436765 ], [ 29.800724430379748, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13549, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.436765 ], [ 29.801263417721518, -1.437034482758621 ], [ 29.801532911392407, -1.437034482758621 ], [ 29.801532911392407, -1.436765 ], [ 29.801263417721518, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13550, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.436765 ], [ 29.801532911392407, -1.437034482758621 ], [ 29.802071898734177, -1.437034482758621 ], [ 29.802071898734177, -1.436765 ], [ 29.801532911392407, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13551, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.436765 ], [ 29.802071898734177, -1.437034482758621 ], [ 29.802341392405065, -1.437034482758621 ], [ 29.802341392405065, -1.436765 ], [ 29.802071898734177, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13552, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.436765 ], [ 29.802341392405065, -1.437034482758621 ], [ 29.80261088607595, -1.437034482758621 ], [ 29.80261088607595, -1.436765 ], [ 29.802341392405065, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13553, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.436765 ], [ 29.80261088607595, -1.437034482758621 ], [ 29.802880379746835, -1.437034482758621 ], [ 29.802880379746835, -1.436765 ], [ 29.80261088607595, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13554, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.436765 ], [ 29.802880379746835, -1.437034482758621 ], [ 29.803149873417723, -1.437034482758621 ], [ 29.803149873417723, -1.436765 ], [ 29.802880379746835, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13555, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.436765 ], [ 29.803149873417723, -1.437034482758621 ], [ 29.803419367088608, -1.437034482758621 ], [ 29.803419367088608, -1.436765 ], [ 29.803149873417723, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13556, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.436765 ], [ 29.803419367088608, -1.437034482758621 ], [ 29.803688860759493, -1.437034482758621 ], [ 29.803688860759493, -1.436765 ], [ 29.803419367088608, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13557, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.436765 ], [ 29.803688860759493, -1.437034482758621 ], [ 29.803958354430382, -1.437034482758621 ], [ 29.803958354430382, -1.436765 ], [ 29.803688860759493, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13558, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.436765 ], [ 29.803958354430382, -1.437034482758621 ], [ 29.804227848101267, -1.437034482758621 ], [ 29.804227848101267, -1.436765 ], [ 29.803958354430382, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13559, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.436765 ], [ 29.804227848101267, -1.437034482758621 ], [ 29.804497341772151, -1.437034482758621 ], [ 29.804497341772151, -1.436765 ], [ 29.804227848101267, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13560, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.436765 ], [ 29.804497341772151, -1.437034482758621 ], [ 29.805036329113925, -1.437034482758621 ], [ 29.805036329113925, -1.436765 ], [ 29.804497341772151, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13561, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.436765 ], [ 29.805036329113925, -1.437034482758621 ], [ 29.80530582278481, -1.437034482758621 ], [ 29.80530582278481, -1.436765 ], [ 29.805036329113925, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13562, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.436765 ], [ 29.80530582278481, -1.437034482758621 ], [ 29.806114303797468, -1.437034482758621 ], [ 29.806114303797468, -1.436765 ], [ 29.80530582278481, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13563, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.436765 ], [ 29.806114303797468, -1.437034482758621 ], [ 29.806383797468357, -1.437034482758621 ], [ 29.806383797468357, -1.436765 ], [ 29.806114303797468, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13564, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.436765 ], [ 29.806383797468357, -1.437034482758621 ], [ 29.806653291139241, -1.437034482758621 ], [ 29.806653291139241, -1.436765 ], [ 29.806383797468357, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13565, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.436765 ], [ 29.806653291139241, -1.437034482758621 ], [ 29.806922784810126, -1.437034482758621 ], [ 29.806922784810126, -1.436765 ], [ 29.806653291139241, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13566, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.436765 ], [ 29.806922784810126, -1.437034482758621 ], [ 29.807192278481015, -1.437034482758621 ], [ 29.807192278481015, -1.436765 ], [ 29.806922784810126, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13567, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.436765 ], [ 29.807192278481015, -1.437034482758621 ], [ 29.8074617721519, -1.437034482758621 ], [ 29.8074617721519, -1.436765 ], [ 29.807192278481015, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13568, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.436765 ], [ 29.8074617721519, -1.437034482758621 ], [ 29.807731265822785, -1.437034482758621 ], [ 29.807731265822785, -1.436765 ], [ 29.8074617721519, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13569, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.436765 ], [ 29.807731265822785, -1.437034482758621 ], [ 29.808000759493673, -1.437034482758621 ], [ 29.808000759493673, -1.436765 ], [ 29.807731265822785, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13570, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.436765 ], [ 29.808000759493673, -1.437034482758621 ], [ 29.808270253164558, -1.437034482758621 ], [ 29.808270253164558, -1.436765 ], [ 29.808000759493673, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13571, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.436765 ], [ 29.808270253164558, -1.437034482758621 ], [ 29.808539746835443, -1.437034482758621 ], [ 29.808539746835443, -1.436765 ], [ 29.808270253164558, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13572, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.436765 ], [ 29.808539746835443, -1.437034482758621 ], [ 29.808809240506328, -1.437034482758621 ], [ 29.808809240506328, -1.436765 ], [ 29.808539746835443, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13573, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.436765 ], [ 29.808809240506328, -1.437034482758621 ], [ 29.809078734177216, -1.437034482758621 ], [ 29.809078734177216, -1.436765 ], [ 29.808809240506328, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13574, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.436765 ], [ 29.809078734177216, -1.437034482758621 ], [ 29.809348227848101, -1.437034482758621 ], [ 29.809348227848101, -1.436765 ], [ 29.809078734177216, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13575, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.437034482758621 ], [ 29.743591772151898, -1.437303965517241 ], [ 29.743861265822783, -1.437303965517241 ], [ 29.743861265822783, -1.437034482758621 ], [ 29.743591772151898, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13576, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.437034482758621 ], [ 29.743861265822783, -1.437303965517241 ], [ 29.744130759493672, -1.437303965517241 ], [ 29.744130759493672, -1.437034482758621 ], [ 29.743861265822783, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13577, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.437034482758621 ], [ 29.744130759493672, -1.437303965517241 ], [ 29.744400253164557, -1.437303965517241 ], [ 29.744400253164557, -1.437034482758621 ], [ 29.744130759493672, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13578, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.437034482758621 ], [ 29.744400253164557, -1.437303965517241 ], [ 29.744669746835442, -1.437303965517241 ], [ 29.744669746835442, -1.437034482758621 ], [ 29.744400253164557, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13579, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.437034482758621 ], [ 29.744669746835442, -1.437303965517241 ], [ 29.744939240506326, -1.437303965517241 ], [ 29.744939240506326, -1.437034482758621 ], [ 29.744669746835442, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13580, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.437034482758621 ], [ 29.744939240506326, -1.437303965517241 ], [ 29.745208734177215, -1.437303965517241 ], [ 29.745208734177215, -1.437034482758621 ], [ 29.744939240506326, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13581, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.437034482758621 ], [ 29.745208734177215, -1.437303965517241 ], [ 29.7454782278481, -1.437303965517241 ], [ 29.7454782278481, -1.437034482758621 ], [ 29.745208734177215, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13582, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.437034482758621 ], [ 29.7454782278481, -1.437303965517241 ], [ 29.745747721518985, -1.437303965517241 ], [ 29.745747721518985, -1.437034482758621 ], [ 29.7454782278481, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13583, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.437034482758621 ], [ 29.745747721518985, -1.437303965517241 ], [ 29.746017215189873, -1.437303965517241 ], [ 29.746017215189873, -1.437034482758621 ], [ 29.745747721518985, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13584, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.437034482758621 ], [ 29.746017215189873, -1.437303965517241 ], [ 29.746286708860758, -1.437303965517241 ], [ 29.746286708860758, -1.437034482758621 ], [ 29.746017215189873, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13585, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.437034482758621 ], [ 29.746286708860758, -1.437303965517241 ], [ 29.746556202531643, -1.437303965517241 ], [ 29.746556202531643, -1.437034482758621 ], [ 29.746286708860758, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13586, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.437034482758621 ], [ 29.746556202531643, -1.437303965517241 ], [ 29.746825696202531, -1.437303965517241 ], [ 29.746825696202531, -1.437034482758621 ], [ 29.746556202531643, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13587, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.437034482758621 ], [ 29.746825696202531, -1.437303965517241 ], [ 29.747095189873416, -1.437303965517241 ], [ 29.747095189873416, -1.437034482758621 ], [ 29.746825696202531, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13588, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.437034482758621 ], [ 29.747095189873416, -1.437303965517241 ], [ 29.747364683544301, -1.437303965517241 ], [ 29.747364683544301, -1.437034482758621 ], [ 29.747095189873416, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13589, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.437034482758621 ], [ 29.747364683544301, -1.437303965517241 ], [ 29.747903670886075, -1.437303965517241 ], [ 29.747903670886075, -1.437034482758621 ], [ 29.747364683544301, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13590, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.437034482758621 ], [ 29.747903670886075, -1.437303965517241 ], [ 29.74817316455696, -1.437303965517241 ], [ 29.74817316455696, -1.437034482758621 ], [ 29.747903670886075, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13591, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.437034482758621 ], [ 29.74817316455696, -1.437303965517241 ], [ 29.748442658227848, -1.437303965517241 ], [ 29.748442658227848, -1.437034482758621 ], [ 29.74817316455696, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13592, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.437034482758621 ], [ 29.748442658227848, -1.437303965517241 ], [ 29.748712151898733, -1.437303965517241 ], [ 29.748712151898733, -1.437034482758621 ], [ 29.748442658227848, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13593, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.437034482758621 ], [ 29.748712151898733, -1.437303965517241 ], [ 29.748981645569618, -1.437303965517241 ], [ 29.748981645569618, -1.437034482758621 ], [ 29.748712151898733, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13594, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.437034482758621 ], [ 29.748981645569618, -1.437303965517241 ], [ 29.749251139240506, -1.437303965517241 ], [ 29.749251139240506, -1.437034482758621 ], [ 29.748981645569618, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13595, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.437034482758621 ], [ 29.749251139240506, -1.437303965517241 ], [ 29.749520632911391, -1.437303965517241 ], [ 29.749520632911391, -1.437034482758621 ], [ 29.749251139240506, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13596, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.437034482758621 ], [ 29.749520632911391, -1.437303965517241 ], [ 29.749790126582276, -1.437303965517241 ], [ 29.749790126582276, -1.437034482758621 ], [ 29.749520632911391, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13597, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.437034482758621 ], [ 29.749790126582276, -1.437303965517241 ], [ 29.750059620253165, -1.437303965517241 ], [ 29.750059620253165, -1.437034482758621 ], [ 29.749790126582276, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13598, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.437034482758621 ], [ 29.750059620253165, -1.437303965517241 ], [ 29.75032911392405, -1.437303965517241 ], [ 29.75032911392405, -1.437034482758621 ], [ 29.750059620253165, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13599, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.437034482758621 ], [ 29.75032911392405, -1.437303965517241 ], [ 29.750868101265823, -1.437303965517241 ], [ 29.750868101265823, -1.437034482758621 ], [ 29.75032911392405, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13600, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.437034482758621 ], [ 29.750868101265823, -1.437303965517241 ], [ 29.754371518987341, -1.437303965517241 ], [ 29.754371518987341, -1.437034482758621 ], [ 29.750868101265823, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13601, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.437034482758621 ], [ 29.754371518987341, -1.437303965517241 ], [ 29.754910506329114, -1.437303965517241 ], [ 29.754910506329114, -1.437034482758621 ], [ 29.754371518987341, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13602, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.437034482758621 ], [ 29.754910506329114, -1.437303965517241 ], [ 29.75518, -1.437303965517241 ], [ 29.75518, -1.437034482758621 ], [ 29.754910506329114, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13603, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.437034482758621 ], [ 29.75518, -1.437303965517241 ], [ 29.755449493670884, -1.437303965517241 ], [ 29.755449493670884, -1.437034482758621 ], [ 29.75518, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13604, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.437034482758621 ], [ 29.755449493670884, -1.437303965517241 ], [ 29.755718987341773, -1.437303965517241 ], [ 29.755718987341773, -1.437034482758621 ], [ 29.755449493670884, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13605, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.437034482758621 ], [ 29.758144430379748, -1.437303965517241 ], [ 29.758413924050632, -1.437303965517241 ], [ 29.758413924050632, -1.437034482758621 ], [ 29.758144430379748, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13606, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.437034482758621 ], [ 29.758413924050632, -1.437303965517241 ], [ 29.758683417721517, -1.437303965517241 ], [ 29.758683417721517, -1.437034482758621 ], [ 29.758413924050632, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13607, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.437034482758621 ], [ 29.758683417721517, -1.437303965517241 ], [ 29.758952911392406, -1.437303965517241 ], [ 29.758952911392406, -1.437034482758621 ], [ 29.758683417721517, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13608, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.437034482758621 ], [ 29.762456329113924, -1.437303965517241 ], [ 29.762725822784809, -1.437303965517241 ], [ 29.762725822784809, -1.437034482758621 ], [ 29.762456329113924, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13609, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.437034482758621 ], [ 29.762725822784809, -1.437303965517241 ], [ 29.762995316455697, -1.437303965517241 ], [ 29.762995316455697, -1.437034482758621 ], [ 29.762725822784809, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13610, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.437034482758621 ], [ 29.762995316455697, -1.437303965517241 ], [ 29.763264810126582, -1.437303965517241 ], [ 29.763264810126582, -1.437034482758621 ], [ 29.762995316455697, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13611, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.437034482758621 ], [ 29.763534303797467, -1.437303965517241 ], [ 29.763803797468356, -1.437303965517241 ], [ 29.763803797468356, -1.437034482758621 ], [ 29.763534303797467, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13612, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.437034482758621 ], [ 29.763803797468356, -1.437303965517241 ], [ 29.76407329113924, -1.437303965517241 ], [ 29.76407329113924, -1.437034482758621 ], [ 29.763803797468356, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13613, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.437034482758621 ], [ 29.765420759493672, -1.437303965517241 ], [ 29.765959746835442, -1.437303965517241 ], [ 29.765959746835442, -1.437034482758621 ], [ 29.765420759493672, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13614, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.437034482758621 ], [ 29.766498734177215, -1.437303965517241 ], [ 29.7667682278481, -1.437303965517241 ], [ 29.7667682278481, -1.437034482758621 ], [ 29.766498734177215, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13615, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.437034482758621 ], [ 29.7667682278481, -1.437303965517241 ], [ 29.767037721518985, -1.437303965517241 ], [ 29.767037721518985, -1.437034482758621 ], [ 29.7667682278481, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13616, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.437034482758621 ], [ 29.767307215189874, -1.437303965517241 ], [ 29.767576708860759, -1.437303965517241 ], [ 29.767576708860759, -1.437034482758621 ], [ 29.767307215189874, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13617, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.437034482758621 ], [ 29.767576708860759, -1.437303965517241 ], [ 29.767846202531643, -1.437303965517241 ], [ 29.767846202531643, -1.437034482758621 ], [ 29.767576708860759, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13618, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.437034482758621 ], [ 29.767846202531643, -1.437303965517241 ], [ 29.768115696202532, -1.437303965517241 ], [ 29.768115696202532, -1.437034482758621 ], [ 29.767846202531643, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13619, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.437034482758621 ], [ 29.768385189873417, -1.437303965517241 ], [ 29.768654683544302, -1.437303965517241 ], [ 29.768654683544302, -1.437034482758621 ], [ 29.768385189873417, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13620, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.437034482758621 ], [ 29.768654683544302, -1.437303965517241 ], [ 29.76892417721519, -1.437303965517241 ], [ 29.76892417721519, -1.437034482758621 ], [ 29.768654683544302, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13621, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.437034482758621 ], [ 29.769193670886075, -1.437303965517241 ], [ 29.76946316455696, -1.437303965517241 ], [ 29.76946316455696, -1.437034482758621 ], [ 29.769193670886075, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13622, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.437034482758621 ], [ 29.76946316455696, -1.437303965517241 ], [ 29.769732658227849, -1.437303965517241 ], [ 29.769732658227849, -1.437034482758621 ], [ 29.76946316455696, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13623, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.437034482758621 ], [ 29.770271645569618, -1.437303965517241 ], [ 29.770541139240507, -1.437303965517241 ], [ 29.770541139240507, -1.437034482758621 ], [ 29.770271645569618, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13624, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.437034482758621 ], [ 29.771080126582277, -1.437303965517241 ], [ 29.771349620253165, -1.437303965517241 ], [ 29.771349620253165, -1.437034482758621 ], [ 29.771080126582277, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13625, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.437034482758621 ], [ 29.779703924050633, -1.437303965517241 ], [ 29.779973417721518, -1.437303965517241 ], [ 29.779973417721518, -1.437034482758621 ], [ 29.779703924050633, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13626, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.437034482758621 ], [ 29.780512405063291, -1.437303965517241 ], [ 29.780781898734176, -1.437303965517241 ], [ 29.780781898734176, -1.437034482758621 ], [ 29.780512405063291, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13627, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.437034482758621 ], [ 29.780781898734176, -1.437303965517241 ], [ 29.781051392405065, -1.437303965517241 ], [ 29.781051392405065, -1.437034482758621 ], [ 29.780781898734176, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13628, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.437034482758621 ], [ 29.78132088607595, -1.437303965517241 ], [ 29.781590379746834, -1.437303965517241 ], [ 29.781590379746834, -1.437034482758621 ], [ 29.78132088607595, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13629, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.437034482758621 ], [ 29.781590379746834, -1.437303965517241 ], [ 29.781859873417723, -1.437303965517241 ], [ 29.781859873417723, -1.437034482758621 ], [ 29.781590379746834, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13630, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.437034482758621 ], [ 29.782129367088608, -1.437303965517241 ], [ 29.782398860759493, -1.437303965517241 ], [ 29.782398860759493, -1.437034482758621 ], [ 29.782129367088608, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13631, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.437034482758621 ], [ 29.782398860759493, -1.437303965517241 ], [ 29.782668354430381, -1.437303965517241 ], [ 29.782668354430381, -1.437034482758621 ], [ 29.782398860759493, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13632, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.437034482758621 ], [ 29.782668354430381, -1.437303965517241 ], [ 29.782937848101266, -1.437303965517241 ], [ 29.782937848101266, -1.437034482758621 ], [ 29.782668354430381, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13633, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.437034482758621 ], [ 29.782937848101266, -1.437303965517241 ], [ 29.783207341772151, -1.437303965517241 ], [ 29.783207341772151, -1.437034482758621 ], [ 29.782937848101266, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13634, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.437034482758621 ], [ 29.783207341772151, -1.437303965517241 ], [ 29.783476835443039, -1.437303965517241 ], [ 29.783476835443039, -1.437034482758621 ], [ 29.783207341772151, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13635, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.437034482758621 ], [ 29.783476835443039, -1.437303965517241 ], [ 29.784015822784809, -1.437303965517241 ], [ 29.784015822784809, -1.437034482758621 ], [ 29.783476835443039, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13636, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.437034482758621 ], [ 29.784015822784809, -1.437303965517241 ], [ 29.784285316455698, -1.437303965517241 ], [ 29.784285316455698, -1.437034482758621 ], [ 29.784015822784809, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13637, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.437034482758621 ], [ 29.784285316455698, -1.437303965517241 ], [ 29.784554810126583, -1.437303965517241 ], [ 29.784554810126583, -1.437034482758621 ], [ 29.784285316455698, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13638, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.437034482758621 ], [ 29.784554810126583, -1.437303965517241 ], [ 29.784824303797468, -1.437303965517241 ], [ 29.784824303797468, -1.437034482758621 ], [ 29.784554810126583, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13639, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.437034482758621 ], [ 29.784824303797468, -1.437303965517241 ], [ 29.785093797468356, -1.437303965517241 ], [ 29.785093797468356, -1.437034482758621 ], [ 29.784824303797468, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13640, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.437034482758621 ], [ 29.785093797468356, -1.437303965517241 ], [ 29.785363291139241, -1.437303965517241 ], [ 29.785363291139241, -1.437034482758621 ], [ 29.785093797468356, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13641, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.437034482758621 ], [ 29.785363291139241, -1.437303965517241 ], [ 29.785632784810126, -1.437303965517241 ], [ 29.785632784810126, -1.437034482758621 ], [ 29.785363291139241, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13642, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.437034482758621 ], [ 29.785632784810126, -1.437303965517241 ], [ 29.786980253164558, -1.437303965517241 ], [ 29.786980253164558, -1.437034482758621 ], [ 29.785632784810126, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13643, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.437034482758621 ], [ 29.786980253164558, -1.437303965517241 ], [ 29.787249746835442, -1.437303965517241 ], [ 29.787249746835442, -1.437034482758621 ], [ 29.786980253164558, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13644, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.437034482758621 ], [ 29.787249746835442, -1.437303965517241 ], [ 29.787519240506327, -1.437303965517241 ], [ 29.787519240506327, -1.437034482758621 ], [ 29.787249746835442, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13645, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.437034482758621 ], [ 29.787519240506327, -1.437303965517241 ], [ 29.789136202531644, -1.437303965517241 ], [ 29.789136202531644, -1.437034482758621 ], [ 29.787519240506327, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13646, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.437034482758621 ], [ 29.789136202531644, -1.437303965517241 ], [ 29.789405696202532, -1.437303965517241 ], [ 29.789405696202532, -1.437034482758621 ], [ 29.789136202531644, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13647, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.437034482758621 ], [ 29.789405696202532, -1.437303965517241 ], [ 29.789675189873417, -1.437303965517241 ], [ 29.789675189873417, -1.437034482758621 ], [ 29.789405696202532, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13648, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.437034482758621 ], [ 29.789675189873417, -1.437303965517241 ], [ 29.789944683544302, -1.437303965517241 ], [ 29.789944683544302, -1.437034482758621 ], [ 29.789675189873417, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13649, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.437034482758621 ], [ 29.789944683544302, -1.437303965517241 ], [ 29.790214177215191, -1.437303965517241 ], [ 29.790214177215191, -1.437034482758621 ], [ 29.789944683544302, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13650, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.437034482758621 ], [ 29.790214177215191, -1.437303965517241 ], [ 29.790483670886076, -1.437303965517241 ], [ 29.790483670886076, -1.437034482758621 ], [ 29.790214177215191, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13651, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.437034482758621 ], [ 29.790483670886076, -1.437303965517241 ], [ 29.790753164556961, -1.437303965517241 ], [ 29.790753164556961, -1.437034482758621 ], [ 29.790483670886076, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13652, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.437034482758621 ], [ 29.790753164556961, -1.437303965517241 ], [ 29.791022658227849, -1.437303965517241 ], [ 29.791022658227849, -1.437034482758621 ], [ 29.790753164556961, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13653, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.437034482758621 ], [ 29.791022658227849, -1.437303965517241 ], [ 29.791292151898734, -1.437303965517241 ], [ 29.791292151898734, -1.437034482758621 ], [ 29.791022658227849, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13654, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.437034482758621 ], [ 29.791292151898734, -1.437303965517241 ], [ 29.791561645569619, -1.437303965517241 ], [ 29.791561645569619, -1.437034482758621 ], [ 29.791292151898734, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13655, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.437034482758621 ], [ 29.791561645569619, -1.437303965517241 ], [ 29.791831139240507, -1.437303965517241 ], [ 29.791831139240507, -1.437034482758621 ], [ 29.791561645569619, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13656, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.437034482758621 ], [ 29.791831139240507, -1.437303965517241 ], [ 29.792100632911392, -1.437303965517241 ], [ 29.792100632911392, -1.437034482758621 ], [ 29.791831139240507, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13657, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.437034482758621 ], [ 29.792100632911392, -1.437303965517241 ], [ 29.792370126582277, -1.437303965517241 ], [ 29.792370126582277, -1.437034482758621 ], [ 29.792100632911392, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13658, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.437034482758621 ], [ 29.792370126582277, -1.437303965517241 ], [ 29.792909113924051, -1.437303965517241 ], [ 29.792909113924051, -1.437034482758621 ], [ 29.792370126582277, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13659, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.437034482758621 ], [ 29.792909113924051, -1.437303965517241 ], [ 29.793178607594935, -1.437303965517241 ], [ 29.793178607594935, -1.437034482758621 ], [ 29.792909113924051, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13660, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.437034482758621 ], [ 29.793178607594935, -1.437303965517241 ], [ 29.793448101265824, -1.437303965517241 ], [ 29.793448101265824, -1.437034482758621 ], [ 29.793178607594935, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13661, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.437034482758621 ], [ 29.793717594936709, -1.437303965517241 ], [ 29.793987088607594, -1.437303965517241 ], [ 29.793987088607594, -1.437034482758621 ], [ 29.793717594936709, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13662, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.437034482758621 ], [ 29.794256582278482, -1.437303965517241 ], [ 29.794526075949367, -1.437303965517241 ], [ 29.794526075949367, -1.437034482758621 ], [ 29.794256582278482, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13663, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.437034482758621 ], [ 29.794526075949367, -1.437303965517241 ], [ 29.794795569620252, -1.437303965517241 ], [ 29.794795569620252, -1.437034482758621 ], [ 29.794526075949367, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13664, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.437034482758621 ], [ 29.794795569620252, -1.437303965517241 ], [ 29.79506506329114, -1.437303965517241 ], [ 29.79506506329114, -1.437034482758621 ], [ 29.794795569620252, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13665, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.437034482758621 ], [ 29.79506506329114, -1.437303965517241 ], [ 29.795334556962025, -1.437303965517241 ], [ 29.795334556962025, -1.437034482758621 ], [ 29.79506506329114, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13666, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.437034482758621 ], [ 29.795334556962025, -1.437303965517241 ], [ 29.79560405063291, -1.437303965517241 ], [ 29.79560405063291, -1.437034482758621 ], [ 29.795334556962025, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13667, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.437034482758621 ], [ 29.79560405063291, -1.437303965517241 ], [ 29.795873544303799, -1.437303965517241 ], [ 29.795873544303799, -1.437034482758621 ], [ 29.79560405063291, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13668, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.437034482758621 ], [ 29.795873544303799, -1.437303965517241 ], [ 29.796143037974684, -1.437303965517241 ], [ 29.796143037974684, -1.437034482758621 ], [ 29.795873544303799, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13669, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.437034482758621 ], [ 29.796143037974684, -1.437303965517241 ], [ 29.796412531645569, -1.437303965517241 ], [ 29.796412531645569, -1.437034482758621 ], [ 29.796143037974684, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13670, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.437034482758621 ], [ 29.796412531645569, -1.437303965517241 ], [ 29.798029493670885, -1.437303965517241 ], [ 29.798029493670885, -1.437034482758621 ], [ 29.796412531645569, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13671, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.437034482758621 ], [ 29.798029493670885, -1.437303965517241 ], [ 29.798298987341774, -1.437303965517241 ], [ 29.798298987341774, -1.437034482758621 ], [ 29.798029493670885, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13672, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.437034482758621 ], [ 29.798298987341774, -1.437303965517241 ], [ 29.798568481012659, -1.437303965517241 ], [ 29.798568481012659, -1.437034482758621 ], [ 29.798298987341774, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13673, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.437034482758621 ], [ 29.798568481012659, -1.437303965517241 ], [ 29.798837974683543, -1.437303965517241 ], [ 29.798837974683543, -1.437034482758621 ], [ 29.798568481012659, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13674, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.437034482758621 ], [ 29.798837974683543, -1.437303965517241 ], [ 29.799107468354432, -1.437303965517241 ], [ 29.799107468354432, -1.437034482758621 ], [ 29.798837974683543, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13675, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.437034482758621 ], [ 29.799107468354432, -1.437303965517241 ], [ 29.799376962025317, -1.437303965517241 ], [ 29.799376962025317, -1.437034482758621 ], [ 29.799107468354432, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13676, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.437034482758621 ], [ 29.799376962025317, -1.437303965517241 ], [ 29.799646455696202, -1.437303965517241 ], [ 29.799646455696202, -1.437034482758621 ], [ 29.799376962025317, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13677, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.437034482758621 ], [ 29.799646455696202, -1.437303965517241 ], [ 29.79991594936709, -1.437303965517241 ], [ 29.79991594936709, -1.437034482758621 ], [ 29.799646455696202, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13678, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.437034482758621 ], [ 29.800185443037975, -1.437303965517241 ], [ 29.80045493670886, -1.437303965517241 ], [ 29.80045493670886, -1.437034482758621 ], [ 29.800185443037975, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13679, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.437034482758621 ], [ 29.80045493670886, -1.437303965517241 ], [ 29.800724430379748, -1.437303965517241 ], [ 29.800724430379748, -1.437034482758621 ], [ 29.80045493670886, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13680, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.437034482758621 ], [ 29.800724430379748, -1.437303965517241 ], [ 29.800993924050633, -1.437303965517241 ], [ 29.800993924050633, -1.437034482758621 ], [ 29.800724430379748, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13681, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.437034482758621 ], [ 29.800993924050633, -1.437303965517241 ], [ 29.801263417721518, -1.437303965517241 ], [ 29.801263417721518, -1.437034482758621 ], [ 29.800993924050633, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13682, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.437034482758621 ], [ 29.801263417721518, -1.437303965517241 ], [ 29.801532911392407, -1.437303965517241 ], [ 29.801532911392407, -1.437034482758621 ], [ 29.801263417721518, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13683, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.437034482758621 ], [ 29.801532911392407, -1.437303965517241 ], [ 29.802071898734177, -1.437303965517241 ], [ 29.802071898734177, -1.437034482758621 ], [ 29.801532911392407, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13684, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.437034482758621 ], [ 29.802071898734177, -1.437303965517241 ], [ 29.802341392405065, -1.437303965517241 ], [ 29.802341392405065, -1.437034482758621 ], [ 29.802071898734177, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13685, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.437034482758621 ], [ 29.802341392405065, -1.437303965517241 ], [ 29.80261088607595, -1.437303965517241 ], [ 29.80261088607595, -1.437034482758621 ], [ 29.802341392405065, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13686, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.437034482758621 ], [ 29.80261088607595, -1.437303965517241 ], [ 29.802880379746835, -1.437303965517241 ], [ 29.802880379746835, -1.437034482758621 ], [ 29.80261088607595, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13687, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.437034482758621 ], [ 29.802880379746835, -1.437303965517241 ], [ 29.803149873417723, -1.437303965517241 ], [ 29.803149873417723, -1.437034482758621 ], [ 29.802880379746835, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13688, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.437034482758621 ], [ 29.803149873417723, -1.437303965517241 ], [ 29.803419367088608, -1.437303965517241 ], [ 29.803419367088608, -1.437034482758621 ], [ 29.803149873417723, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13689, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.437034482758621 ], [ 29.803419367088608, -1.437303965517241 ], [ 29.803688860759493, -1.437303965517241 ], [ 29.803688860759493, -1.437034482758621 ], [ 29.803419367088608, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13690, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.437034482758621 ], [ 29.803688860759493, -1.437303965517241 ], [ 29.803958354430382, -1.437303965517241 ], [ 29.803958354430382, -1.437034482758621 ], [ 29.803688860759493, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13691, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.437034482758621 ], [ 29.803958354430382, -1.437303965517241 ], [ 29.804227848101267, -1.437303965517241 ], [ 29.804227848101267, -1.437034482758621 ], [ 29.803958354430382, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13692, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.437034482758621 ], [ 29.804227848101267, -1.437303965517241 ], [ 29.804497341772151, -1.437303965517241 ], [ 29.804497341772151, -1.437034482758621 ], [ 29.804227848101267, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13693, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.437034482758621 ], [ 29.804497341772151, -1.437303965517241 ], [ 29.805036329113925, -1.437303965517241 ], [ 29.805036329113925, -1.437034482758621 ], [ 29.804497341772151, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13694, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.437034482758621 ], [ 29.805036329113925, -1.437303965517241 ], [ 29.80530582278481, -1.437303965517241 ], [ 29.80530582278481, -1.437034482758621 ], [ 29.805036329113925, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13695, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.437034482758621 ], [ 29.80530582278481, -1.437303965517241 ], [ 29.806114303797468, -1.437303965517241 ], [ 29.806114303797468, -1.437034482758621 ], [ 29.80530582278481, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13696, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.437034482758621 ], [ 29.806114303797468, -1.437303965517241 ], [ 29.806383797468357, -1.437303965517241 ], [ 29.806383797468357, -1.437034482758621 ], [ 29.806114303797468, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13697, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.437034482758621 ], [ 29.806383797468357, -1.437303965517241 ], [ 29.806653291139241, -1.437303965517241 ], [ 29.806653291139241, -1.437034482758621 ], [ 29.806383797468357, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13698, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.437034482758621 ], [ 29.806653291139241, -1.437303965517241 ], [ 29.806922784810126, -1.437303965517241 ], [ 29.806922784810126, -1.437034482758621 ], [ 29.806653291139241, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13699, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.437034482758621 ], [ 29.806922784810126, -1.437303965517241 ], [ 29.807192278481015, -1.437303965517241 ], [ 29.807192278481015, -1.437034482758621 ], [ 29.806922784810126, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13700, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.437034482758621 ], [ 29.807192278481015, -1.437303965517241 ], [ 29.807731265822785, -1.437303965517241 ], [ 29.807731265822785, -1.437034482758621 ], [ 29.807192278481015, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13701, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.437034482758621 ], [ 29.807731265822785, -1.437303965517241 ], [ 29.808000759493673, -1.437303965517241 ], [ 29.808000759493673, -1.437034482758621 ], [ 29.807731265822785, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13702, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.437034482758621 ], [ 29.808000759493673, -1.437303965517241 ], [ 29.808270253164558, -1.437303965517241 ], [ 29.808270253164558, -1.437034482758621 ], [ 29.808000759493673, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13703, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.437034482758621 ], [ 29.808270253164558, -1.437303965517241 ], [ 29.808539746835443, -1.437303965517241 ], [ 29.808539746835443, -1.437034482758621 ], [ 29.808270253164558, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13704, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.437034482758621 ], [ 29.808539746835443, -1.437303965517241 ], [ 29.808809240506328, -1.437303965517241 ], [ 29.808809240506328, -1.437034482758621 ], [ 29.808539746835443, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13705, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.437034482758621 ], [ 29.808809240506328, -1.437303965517241 ], [ 29.809078734177216, -1.437303965517241 ], [ 29.809078734177216, -1.437034482758621 ], [ 29.808809240506328, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13706, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.437034482758621 ], [ 29.809078734177216, -1.437303965517241 ], [ 29.809348227848101, -1.437303965517241 ], [ 29.809348227848101, -1.437034482758621 ], [ 29.809078734177216, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13707, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.437034482758621 ], [ 29.809348227848101, -1.437303965517241 ], [ 29.809887215189875, -1.437303965517241 ], [ 29.809887215189875, -1.437034482758621 ], [ 29.809348227848101, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13708, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.434609137931035 ], [ 29.756257974683542, -1.437573448275862 ], [ 29.756796962025316, -1.437573448275862 ], [ 29.756796962025316, -1.434609137931035 ], [ 29.756257974683542, -1.434609137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13709, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.436495517241379 ], [ 29.761647848101266, -1.437573448275862 ], [ 29.761917341772151, -1.437573448275862 ], [ 29.761917341772151, -1.436495517241379 ], [ 29.761647848101266, -1.436495517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13710, "El": 147 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.436765 ], [ 29.77377506329114, -1.437573448275862 ], [ 29.774044556962025, -1.437573448275862 ], [ 29.774044556962025, -1.436765 ], [ 29.77377506329114, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13711, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.436765 ], [ 29.77431405063291, -1.437842931034483 ], [ 29.774853037974683, -1.437842931034483 ], [ 29.774853037974683, -1.436765 ], [ 29.77431405063291, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13712, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.436765 ], [ 29.775392025316457, -1.437573448275862 ], [ 29.775661518987341, -1.437573448275862 ], [ 29.775661518987341, -1.436765 ], [ 29.775392025316457, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13713, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.436765 ], [ 29.776739493670885, -1.437573448275862 ], [ 29.777008987341773, -1.437573448275862 ], [ 29.777008987341773, -1.436765 ], [ 29.776739493670885, -1.436765 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13714, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.437034482758621 ], [ 29.755718987341773, -1.437573448275862 ], [ 29.755988481012658, -1.437573448275862 ], [ 29.755988481012658, -1.437034482758621 ], [ 29.755718987341773, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13715, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.437034482758621 ], [ 29.757335949367089, -1.437573448275862 ], [ 29.757605443037974, -1.437573448275862 ], [ 29.757605443037974, -1.437034482758621 ], [ 29.757335949367089, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13716, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.437034482758621 ], [ 29.763264810126582, -1.437573448275862 ], [ 29.763534303797467, -1.437573448275862 ], [ 29.763534303797467, -1.437034482758621 ], [ 29.763264810126582, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13717, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.437034482758621 ], [ 29.76407329113924, -1.437573448275862 ], [ 29.764342784810125, -1.437573448275862 ], [ 29.764342784810125, -1.437034482758621 ], [ 29.76407329113924, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13718, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.437034482758621 ], [ 29.764612278481014, -1.437573448275862 ], [ 29.764881772151899, -1.437573448275862 ], [ 29.764881772151899, -1.437034482758621 ], [ 29.764612278481014, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13719, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.437034482758621 ], [ 29.764881772151899, -1.437573448275862 ], [ 29.765151265822784, -1.437573448275862 ], [ 29.765151265822784, -1.437034482758621 ], [ 29.764881772151899, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13720, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.437034482758621 ], [ 29.765959746835442, -1.437573448275862 ], [ 29.766229240506327, -1.437573448275862 ], [ 29.766229240506327, -1.437034482758621 ], [ 29.765959746835442, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13721, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.437034482758621 ], [ 29.767037721518985, -1.437573448275862 ], [ 29.767307215189874, -1.437573448275862 ], [ 29.767307215189874, -1.437034482758621 ], [ 29.767037721518985, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13722, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.437034482758621 ], [ 29.76892417721519, -1.437573448275862 ], [ 29.769193670886075, -1.437573448275862 ], [ 29.769193670886075, -1.437034482758621 ], [ 29.76892417721519, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13723, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.437034482758621 ], [ 29.769732658227849, -1.437573448275862 ], [ 29.770002151898733, -1.437573448275862 ], [ 29.770002151898733, -1.437034482758621 ], [ 29.769732658227849, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13724, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.437034482758621 ], [ 29.770541139240507, -1.437573448275862 ], [ 29.770810632911392, -1.437573448275862 ], [ 29.770810632911392, -1.437034482758621 ], [ 29.770541139240507, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13725, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.437034482758621 ], [ 29.771349620253165, -1.437573448275862 ], [ 29.77161911392405, -1.437573448275862 ], [ 29.77161911392405, -1.437034482758621 ], [ 29.771349620253165, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13726, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.437034482758621 ], [ 29.771888607594935, -1.437573448275862 ], [ 29.772158101265823, -1.437573448275862 ], [ 29.772158101265823, -1.437034482758621 ], [ 29.771888607594935, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13727, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.437034482758621 ], [ 29.772158101265823, -1.437842931034483 ], [ 29.772427594936708, -1.437842931034483 ], [ 29.772427594936708, -1.437034482758621 ], [ 29.772158101265823, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13728, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.437034482758621 ], [ 29.772697088607593, -1.437573448275862 ], [ 29.772966582278482, -1.437573448275862 ], [ 29.772966582278482, -1.437034482758621 ], [ 29.772697088607593, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13729, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.437034482758621 ], [ 29.772966582278482, -1.437842931034483 ], [ 29.773236075949367, -1.437842931034483 ], [ 29.773236075949367, -1.437034482758621 ], [ 29.772966582278482, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13730, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.437034482758621 ], [ 29.773505569620252, -1.437573448275862 ], [ 29.77377506329114, -1.437573448275862 ], [ 29.77377506329114, -1.437034482758621 ], [ 29.773505569620252, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13731, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.437034482758621 ], [ 29.774044556962025, -1.438920862068966 ], [ 29.77431405063291, -1.438920862068966 ], [ 29.77431405063291, -1.437034482758621 ], [ 29.774044556962025, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13732, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.437034482758621 ], [ 29.775931012658226, -1.437842931034483 ], [ 29.776200506329115, -1.437842931034483 ], [ 29.776200506329115, -1.437034482758621 ], [ 29.775931012658226, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13733, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.437034482758621 ], [ 29.777278481012658, -1.437842931034483 ], [ 29.777547974683543, -1.437842931034483 ], [ 29.777547974683543, -1.437034482758621 ], [ 29.777278481012658, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13734, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.437034482758621 ], [ 29.778086962025316, -1.437573448275862 ], [ 29.778356455696201, -1.437573448275862 ], [ 29.778356455696201, -1.437034482758621 ], [ 29.778086962025316, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13735, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.437034482758621 ], [ 29.77862594936709, -1.437842931034483 ], [ 29.778895443037975, -1.437842931034483 ], [ 29.778895443037975, -1.437034482758621 ], [ 29.77862594936709, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13736, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.437034482758621 ], [ 29.778895443037975, -1.437573448275862 ], [ 29.77916493670886, -1.437573448275862 ], [ 29.77916493670886, -1.437034482758621 ], [ 29.778895443037975, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13737, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.437034482758621 ], [ 29.779434430379748, -1.437573448275862 ], [ 29.779703924050633, -1.437573448275862 ], [ 29.779703924050633, -1.437034482758621 ], [ 29.779434430379748, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13738, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.437034482758621 ], [ 29.780242911392406, -1.437573448275862 ], [ 29.780512405063291, -1.437573448275862 ], [ 29.780512405063291, -1.437034482758621 ], [ 29.780242911392406, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13739, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.437034482758621 ], [ 29.781051392405065, -1.437573448275862 ], [ 29.78132088607595, -1.437573448275862 ], [ 29.78132088607595, -1.437034482758621 ], [ 29.781051392405065, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13740, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.437034482758621 ], [ 29.781859873417723, -1.437573448275862 ], [ 29.782129367088608, -1.437573448275862 ], [ 29.782129367088608, -1.437034482758621 ], [ 29.781859873417723, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13741, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.437034482758621 ], [ 29.793448101265824, -1.437573448275862 ], [ 29.793717594936709, -1.437573448275862 ], [ 29.793717594936709, -1.437034482758621 ], [ 29.793448101265824, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13742, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.437034482758621 ], [ 29.793987088607594, -1.437842931034483 ], [ 29.794256582278482, -1.437842931034483 ], [ 29.794256582278482, -1.437034482758621 ], [ 29.793987088607594, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13743, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743322278481013, -1.437303965517241 ], [ 29.743322278481013, -1.437573448275862 ], [ 29.743591772151898, -1.437573448275862 ], [ 29.743591772151898, -1.437303965517241 ], [ 29.743322278481013, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13744, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.437303965517241 ], [ 29.743591772151898, -1.437573448275862 ], [ 29.743861265822783, -1.437573448275862 ], [ 29.743861265822783, -1.437303965517241 ], [ 29.743591772151898, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13745, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.437303965517241 ], [ 29.743861265822783, -1.437573448275862 ], [ 29.744130759493672, -1.437573448275862 ], [ 29.744130759493672, -1.437303965517241 ], [ 29.743861265822783, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13746, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.437303965517241 ], [ 29.744130759493672, -1.437573448275862 ], [ 29.744400253164557, -1.437573448275862 ], [ 29.744400253164557, -1.437303965517241 ], [ 29.744130759493672, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13747, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.437303965517241 ], [ 29.744400253164557, -1.437573448275862 ], [ 29.744669746835442, -1.437573448275862 ], [ 29.744669746835442, -1.437303965517241 ], [ 29.744400253164557, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13748, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.437303965517241 ], [ 29.744669746835442, -1.437573448275862 ], [ 29.744939240506326, -1.437573448275862 ], [ 29.744939240506326, -1.437303965517241 ], [ 29.744669746835442, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13749, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.437303965517241 ], [ 29.744939240506326, -1.437573448275862 ], [ 29.745208734177215, -1.437573448275862 ], [ 29.745208734177215, -1.437303965517241 ], [ 29.744939240506326, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13750, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.437303965517241 ], [ 29.745208734177215, -1.437573448275862 ], [ 29.7454782278481, -1.437573448275862 ], [ 29.7454782278481, -1.437303965517241 ], [ 29.745208734177215, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13751, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.437303965517241 ], [ 29.7454782278481, -1.437573448275862 ], [ 29.745747721518985, -1.437573448275862 ], [ 29.745747721518985, -1.437303965517241 ], [ 29.7454782278481, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13752, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.437303965517241 ], [ 29.745747721518985, -1.437573448275862 ], [ 29.746017215189873, -1.437573448275862 ], [ 29.746017215189873, -1.437303965517241 ], [ 29.745747721518985, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13753, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.437303965517241 ], [ 29.746017215189873, -1.437573448275862 ], [ 29.746286708860758, -1.437573448275862 ], [ 29.746286708860758, -1.437303965517241 ], [ 29.746017215189873, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13754, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.437303965517241 ], [ 29.746286708860758, -1.437573448275862 ], [ 29.746556202531643, -1.437573448275862 ], [ 29.746556202531643, -1.437303965517241 ], [ 29.746286708860758, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13755, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.437303965517241 ], [ 29.746556202531643, -1.437573448275862 ], [ 29.746825696202531, -1.437573448275862 ], [ 29.746825696202531, -1.437303965517241 ], [ 29.746556202531643, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13756, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.437303965517241 ], [ 29.746825696202531, -1.437573448275862 ], [ 29.747095189873416, -1.437573448275862 ], [ 29.747095189873416, -1.437303965517241 ], [ 29.746825696202531, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13757, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.437303965517241 ], [ 29.747095189873416, -1.437573448275862 ], [ 29.747364683544301, -1.437573448275862 ], [ 29.747364683544301, -1.437303965517241 ], [ 29.747095189873416, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13758, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.437303965517241 ], [ 29.747364683544301, -1.437573448275862 ], [ 29.747903670886075, -1.437573448275862 ], [ 29.747903670886075, -1.437303965517241 ], [ 29.747364683544301, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13759, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.437303965517241 ], [ 29.747903670886075, -1.437573448275862 ], [ 29.74817316455696, -1.437573448275862 ], [ 29.74817316455696, -1.437303965517241 ], [ 29.747903670886075, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13760, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.437303965517241 ], [ 29.74817316455696, -1.437573448275862 ], [ 29.748442658227848, -1.437573448275862 ], [ 29.748442658227848, -1.437303965517241 ], [ 29.74817316455696, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13761, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.437303965517241 ], [ 29.748442658227848, -1.437573448275862 ], [ 29.748712151898733, -1.437573448275862 ], [ 29.748712151898733, -1.437303965517241 ], [ 29.748442658227848, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13762, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.437303965517241 ], [ 29.748712151898733, -1.437573448275862 ], [ 29.748981645569618, -1.437573448275862 ], [ 29.748981645569618, -1.437303965517241 ], [ 29.748712151898733, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13763, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.437303965517241 ], [ 29.748981645569618, -1.437573448275862 ], [ 29.749251139240506, -1.437573448275862 ], [ 29.749251139240506, -1.437303965517241 ], [ 29.748981645569618, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13764, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.437303965517241 ], [ 29.749251139240506, -1.437573448275862 ], [ 29.749520632911391, -1.437573448275862 ], [ 29.749520632911391, -1.437303965517241 ], [ 29.749251139240506, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13765, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.437303965517241 ], [ 29.749520632911391, -1.437573448275862 ], [ 29.749790126582276, -1.437573448275862 ], [ 29.749790126582276, -1.437303965517241 ], [ 29.749520632911391, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13766, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.437303965517241 ], [ 29.749790126582276, -1.437573448275862 ], [ 29.750059620253165, -1.437573448275862 ], [ 29.750059620253165, -1.437303965517241 ], [ 29.749790126582276, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13767, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.437303965517241 ], [ 29.750059620253165, -1.437573448275862 ], [ 29.75032911392405, -1.437573448275862 ], [ 29.75032911392405, -1.437303965517241 ], [ 29.750059620253165, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13768, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.437303965517241 ], [ 29.75032911392405, -1.437573448275862 ], [ 29.750598607594934, -1.437573448275862 ], [ 29.750598607594934, -1.437303965517241 ], [ 29.75032911392405, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13769, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.437303965517241 ], [ 29.750598607594934, -1.437573448275862 ], [ 29.751137594936708, -1.437573448275862 ], [ 29.751137594936708, -1.437303965517241 ], [ 29.750598607594934, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13770, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.437303965517241 ], [ 29.751137594936708, -1.437573448275862 ], [ 29.754102025316456, -1.437573448275862 ], [ 29.754102025316456, -1.437303965517241 ], [ 29.751137594936708, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13771, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.437303965517241 ], [ 29.754102025316456, -1.437573448275862 ], [ 29.754641012658226, -1.437573448275862 ], [ 29.754641012658226, -1.437303965517241 ], [ 29.754102025316456, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13772, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.437303965517241 ], [ 29.754641012658226, -1.437573448275862 ], [ 29.754910506329114, -1.437573448275862 ], [ 29.754910506329114, -1.437303965517241 ], [ 29.754641012658226, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13773, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.437303965517241 ], [ 29.754910506329114, -1.437573448275862 ], [ 29.75518, -1.437573448275862 ], [ 29.75518, -1.437303965517241 ], [ 29.754910506329114, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13774, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.437303965517241 ], [ 29.75518, -1.437573448275862 ], [ 29.755718987341773, -1.437573448275862 ], [ 29.755718987341773, -1.437303965517241 ], [ 29.75518, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13775, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.437303965517241 ], [ 29.755988481012658, -1.437842931034483 ], [ 29.756257974683542, -1.437842931034483 ], [ 29.756257974683542, -1.437303965517241 ], [ 29.755988481012658, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13776, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.437303965517241 ], [ 29.756796962025316, -1.437573448275862 ], [ 29.757066455696201, -1.437573448275862 ], [ 29.757066455696201, -1.437303965517241 ], [ 29.756796962025316, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13777, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.437303965517241 ], [ 29.757066455696201, -1.437573448275862 ], [ 29.757335949367089, -1.437573448275862 ], [ 29.757335949367089, -1.437303965517241 ], [ 29.757066455696201, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13778, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.437303965517241 ], [ 29.757605443037974, -1.437573448275862 ], [ 29.757874936708859, -1.437573448275862 ], [ 29.757874936708859, -1.437303965517241 ], [ 29.757605443037974, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13779, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.437303965517241 ], [ 29.757874936708859, -1.437573448275862 ], [ 29.758144430379748, -1.437573448275862 ], [ 29.758144430379748, -1.437303965517241 ], [ 29.757874936708859, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13780, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.437303965517241 ], [ 29.758144430379748, -1.437573448275862 ], [ 29.758413924050632, -1.437573448275862 ], [ 29.758413924050632, -1.437303965517241 ], [ 29.758144430379748, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13781, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.437303965517241 ], [ 29.761917341772151, -1.437573448275862 ], [ 29.762186835443039, -1.437573448275862 ], [ 29.762186835443039, -1.437303965517241 ], [ 29.761917341772151, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13782, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.437303965517241 ], [ 29.762186835443039, -1.437573448275862 ], [ 29.762456329113924, -1.437573448275862 ], [ 29.762456329113924, -1.437303965517241 ], [ 29.762186835443039, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13783, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.437303965517241 ], [ 29.762456329113924, -1.437573448275862 ], [ 29.762725822784809, -1.437573448275862 ], [ 29.762725822784809, -1.437303965517241 ], [ 29.762456329113924, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13784, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.437303965517241 ], [ 29.762725822784809, -1.438112413793104 ], [ 29.762995316455697, -1.438112413793104 ], [ 29.762995316455697, -1.437303965517241 ], [ 29.762725822784809, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13785, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.437303965517241 ], [ 29.762995316455697, -1.438112413793104 ], [ 29.763264810126582, -1.438112413793104 ], [ 29.763264810126582, -1.437303965517241 ], [ 29.762995316455697, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13786, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.437303965517241 ], [ 29.763534303797467, -1.437842931034483 ], [ 29.763803797468356, -1.437842931034483 ], [ 29.763803797468356, -1.437303965517241 ], [ 29.763534303797467, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13787, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.437303965517241 ], [ 29.763803797468356, -1.437842931034483 ], [ 29.76407329113924, -1.437842931034483 ], [ 29.76407329113924, -1.437303965517241 ], [ 29.763803797468356, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13788, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.437303965517241 ], [ 29.764342784810125, -1.437573448275862 ], [ 29.764612278481014, -1.437573448275862 ], [ 29.764612278481014, -1.437303965517241 ], [ 29.764342784810125, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13789, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.437303965517241 ], [ 29.765151265822784, -1.437573448275862 ], [ 29.765420759493672, -1.437573448275862 ], [ 29.765420759493672, -1.437303965517241 ], [ 29.765151265822784, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13790, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.437303965517241 ], [ 29.765420759493672, -1.437573448275862 ], [ 29.765959746835442, -1.437573448275862 ], [ 29.765959746835442, -1.437303965517241 ], [ 29.765420759493672, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13791, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.437303965517241 ], [ 29.766229240506327, -1.437573448275862 ], [ 29.766498734177215, -1.437573448275862 ], [ 29.766498734177215, -1.437303965517241 ], [ 29.766229240506327, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13792, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.437303965517241 ], [ 29.766498734177215, -1.437573448275862 ], [ 29.7667682278481, -1.437573448275862 ], [ 29.7667682278481, -1.437303965517241 ], [ 29.766498734177215, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13793, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.437303965517241 ], [ 29.7667682278481, -1.437573448275862 ], [ 29.767037721518985, -1.437573448275862 ], [ 29.767037721518985, -1.437303965517241 ], [ 29.7667682278481, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13794, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.437303965517241 ], [ 29.767307215189874, -1.437573448275862 ], [ 29.767576708860759, -1.437573448275862 ], [ 29.767576708860759, -1.437303965517241 ], [ 29.767307215189874, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13795, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.437303965517241 ], [ 29.767576708860759, -1.437573448275862 ], [ 29.767846202531643, -1.437573448275862 ], [ 29.767846202531643, -1.437303965517241 ], [ 29.767576708860759, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13796, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.437303965517241 ], [ 29.767846202531643, -1.437842931034483 ], [ 29.768115696202532, -1.437842931034483 ], [ 29.768115696202532, -1.437303965517241 ], [ 29.767846202531643, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13797, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.437303965517241 ], [ 29.768115696202532, -1.437573448275862 ], [ 29.768385189873417, -1.437573448275862 ], [ 29.768385189873417, -1.437303965517241 ], [ 29.768115696202532, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13798, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.437303965517241 ], [ 29.768385189873417, -1.437573448275862 ], [ 29.768654683544302, -1.437573448275862 ], [ 29.768654683544302, -1.437303965517241 ], [ 29.768385189873417, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13799, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.437303965517241 ], [ 29.768654683544302, -1.437842931034483 ], [ 29.76892417721519, -1.437842931034483 ], [ 29.76892417721519, -1.437303965517241 ], [ 29.768654683544302, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13800, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.437303965517241 ], [ 29.769193670886075, -1.437573448275862 ], [ 29.76946316455696, -1.437573448275862 ], [ 29.76946316455696, -1.437303965517241 ], [ 29.769193670886075, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13801, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.437303965517241 ], [ 29.76946316455696, -1.437842931034483 ], [ 29.769732658227849, -1.437842931034483 ], [ 29.769732658227849, -1.437303965517241 ], [ 29.76946316455696, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13802, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.437303965517241 ], [ 29.770002151898733, -1.437842931034483 ], [ 29.770271645569618, -1.437842931034483 ], [ 29.770271645569618, -1.437303965517241 ], [ 29.770002151898733, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13803, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.437303965517241 ], [ 29.770271645569618, -1.437842931034483 ], [ 29.770541139240507, -1.437842931034483 ], [ 29.770541139240507, -1.437303965517241 ], [ 29.770271645569618, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13804, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.437303965517241 ], [ 29.770810632911392, -1.437842931034483 ], [ 29.771080126582277, -1.437842931034483 ], [ 29.771080126582277, -1.437303965517241 ], [ 29.770810632911392, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13805, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.437303965517241 ], [ 29.771080126582277, -1.438112413793104 ], [ 29.771349620253165, -1.438112413793104 ], [ 29.771349620253165, -1.437303965517241 ], [ 29.771080126582277, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13806, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.437303965517241 ], [ 29.77161911392405, -1.437842931034483 ], [ 29.771888607594935, -1.437842931034483 ], [ 29.771888607594935, -1.437303965517241 ], [ 29.77161911392405, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13807, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.437303965517241 ], [ 29.772427594936708, -1.438112413793104 ], [ 29.772697088607593, -1.438112413793104 ], [ 29.772697088607593, -1.437303965517241 ], [ 29.772427594936708, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13808, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.437303965517241 ], [ 29.773236075949367, -1.438381896551724 ], [ 29.773505569620252, -1.438381896551724 ], [ 29.773505569620252, -1.437303965517241 ], [ 29.773236075949367, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13809, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.437303965517241 ], [ 29.779973417721518, -1.437842931034483 ], [ 29.780242911392406, -1.437842931034483 ], [ 29.780242911392406, -1.437303965517241 ], [ 29.779973417721518, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13810, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.437303965517241 ], [ 29.780781898734176, -1.437842931034483 ], [ 29.781051392405065, -1.437842931034483 ], [ 29.781051392405065, -1.437303965517241 ], [ 29.780781898734176, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13811, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.437303965517241 ], [ 29.78132088607595, -1.437842931034483 ], [ 29.781590379746834, -1.437842931034483 ], [ 29.781590379746834, -1.437303965517241 ], [ 29.78132088607595, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13812, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.437303965517241 ], [ 29.781590379746834, -1.437573448275862 ], [ 29.781859873417723, -1.437573448275862 ], [ 29.781859873417723, -1.437303965517241 ], [ 29.781590379746834, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13813, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.437303965517241 ], [ 29.782129367088608, -1.437842931034483 ], [ 29.782398860759493, -1.437842931034483 ], [ 29.782398860759493, -1.437303965517241 ], [ 29.782129367088608, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13814, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.437303965517241 ], [ 29.782398860759493, -1.437573448275862 ], [ 29.782668354430381, -1.437573448275862 ], [ 29.782668354430381, -1.437303965517241 ], [ 29.782398860759493, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13815, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.437303965517241 ], [ 29.782668354430381, -1.437573448275862 ], [ 29.782937848101266, -1.437573448275862 ], [ 29.782937848101266, -1.437303965517241 ], [ 29.782668354430381, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13816, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.437303965517241 ], [ 29.782937848101266, -1.437842931034483 ], [ 29.783207341772151, -1.437842931034483 ], [ 29.783207341772151, -1.437303965517241 ], [ 29.782937848101266, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13817, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.437303965517241 ], [ 29.783207341772151, -1.437573448275862 ], [ 29.783476835443039, -1.437573448275862 ], [ 29.783476835443039, -1.437303965517241 ], [ 29.783207341772151, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13818, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.437303965517241 ], [ 29.783476835443039, -1.437573448275862 ], [ 29.784015822784809, -1.437573448275862 ], [ 29.784015822784809, -1.437303965517241 ], [ 29.783476835443039, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13819, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.437303965517241 ], [ 29.784015822784809, -1.437573448275862 ], [ 29.784285316455698, -1.437573448275862 ], [ 29.784285316455698, -1.437303965517241 ], [ 29.784015822784809, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13820, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.437303965517241 ], [ 29.784285316455698, -1.437573448275862 ], [ 29.784554810126583, -1.437573448275862 ], [ 29.784554810126583, -1.437303965517241 ], [ 29.784285316455698, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13821, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.437303965517241 ], [ 29.784554810126583, -1.437573448275862 ], [ 29.784824303797468, -1.437573448275862 ], [ 29.784824303797468, -1.437303965517241 ], [ 29.784554810126583, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13822, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.437303965517241 ], [ 29.784824303797468, -1.437573448275862 ], [ 29.785093797468356, -1.437573448275862 ], [ 29.785093797468356, -1.437303965517241 ], [ 29.784824303797468, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13823, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.437303965517241 ], [ 29.785093797468356, -1.437573448275862 ], [ 29.785363291139241, -1.437573448275862 ], [ 29.785363291139241, -1.437303965517241 ], [ 29.785093797468356, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13824, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.437303965517241 ], [ 29.785363291139241, -1.437573448275862 ], [ 29.785632784810126, -1.437573448275862 ], [ 29.785632784810126, -1.437303965517241 ], [ 29.785363291139241, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13825, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.437303965517241 ], [ 29.785632784810126, -1.437573448275862 ], [ 29.785902278481014, -1.437573448275862 ], [ 29.785902278481014, -1.437303965517241 ], [ 29.785632784810126, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13826, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.437303965517241 ], [ 29.785902278481014, -1.437573448275862 ], [ 29.787249746835442, -1.437573448275862 ], [ 29.787249746835442, -1.437303965517241 ], [ 29.785902278481014, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13827, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.437303965517241 ], [ 29.787249746835442, -1.437573448275862 ], [ 29.787519240506327, -1.437573448275862 ], [ 29.787519240506327, -1.437303965517241 ], [ 29.787249746835442, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13828, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.437303965517241 ], [ 29.787519240506327, -1.437573448275862 ], [ 29.789136202531644, -1.437573448275862 ], [ 29.789136202531644, -1.437303965517241 ], [ 29.787519240506327, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13829, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.437303965517241 ], [ 29.789136202531644, -1.437573448275862 ], [ 29.789405696202532, -1.437573448275862 ], [ 29.789405696202532, -1.437303965517241 ], [ 29.789136202531644, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13830, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.437303965517241 ], [ 29.789405696202532, -1.437573448275862 ], [ 29.789675189873417, -1.437573448275862 ], [ 29.789675189873417, -1.437303965517241 ], [ 29.789405696202532, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13831, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.437303965517241 ], [ 29.789675189873417, -1.437573448275862 ], [ 29.789944683544302, -1.437573448275862 ], [ 29.789944683544302, -1.437303965517241 ], [ 29.789675189873417, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13832, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.437303965517241 ], [ 29.789944683544302, -1.437573448275862 ], [ 29.790214177215191, -1.437573448275862 ], [ 29.790214177215191, -1.437303965517241 ], [ 29.789944683544302, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13833, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.437303965517241 ], [ 29.790214177215191, -1.437573448275862 ], [ 29.790483670886076, -1.437573448275862 ], [ 29.790483670886076, -1.437303965517241 ], [ 29.790214177215191, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13834, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.437303965517241 ], [ 29.790483670886076, -1.437573448275862 ], [ 29.790753164556961, -1.437573448275862 ], [ 29.790753164556961, -1.437303965517241 ], [ 29.790483670886076, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13835, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.437303965517241 ], [ 29.790753164556961, -1.437573448275862 ], [ 29.791022658227849, -1.437573448275862 ], [ 29.791022658227849, -1.437303965517241 ], [ 29.790753164556961, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13836, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.437303965517241 ], [ 29.791022658227849, -1.437573448275862 ], [ 29.791292151898734, -1.437573448275862 ], [ 29.791292151898734, -1.437303965517241 ], [ 29.791022658227849, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13837, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.437303965517241 ], [ 29.791292151898734, -1.437573448275862 ], [ 29.791561645569619, -1.437573448275862 ], [ 29.791561645569619, -1.437303965517241 ], [ 29.791292151898734, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13838, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.437303965517241 ], [ 29.791561645569619, -1.437573448275862 ], [ 29.791831139240507, -1.437573448275862 ], [ 29.791831139240507, -1.437303965517241 ], [ 29.791561645569619, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13839, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.437303965517241 ], [ 29.791831139240507, -1.437573448275862 ], [ 29.792100632911392, -1.437573448275862 ], [ 29.792100632911392, -1.437303965517241 ], [ 29.791831139240507, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13840, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.437303965517241 ], [ 29.792100632911392, -1.437842931034483 ], [ 29.792370126582277, -1.437842931034483 ], [ 29.792370126582277, -1.437303965517241 ], [ 29.792100632911392, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13841, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.437303965517241 ], [ 29.792370126582277, -1.437573448275862 ], [ 29.792909113924051, -1.437573448275862 ], [ 29.792909113924051, -1.437303965517241 ], [ 29.792370126582277, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13842, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.437303965517241 ], [ 29.792909113924051, -1.437573448275862 ], [ 29.793178607594935, -1.437573448275862 ], [ 29.793178607594935, -1.437303965517241 ], [ 29.792909113924051, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13843, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.437303965517241 ], [ 29.793178607594935, -1.437842931034483 ], [ 29.793448101265824, -1.437842931034483 ], [ 29.793448101265824, -1.437303965517241 ], [ 29.793178607594935, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13844, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.437303965517241 ], [ 29.793717594936709, -1.437573448275862 ], [ 29.793987088607594, -1.437573448275862 ], [ 29.793987088607594, -1.437303965517241 ], [ 29.793717594936709, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13845, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.437303965517241 ], [ 29.794256582278482, -1.437573448275862 ], [ 29.794526075949367, -1.437573448275862 ], [ 29.794526075949367, -1.437303965517241 ], [ 29.794256582278482, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13846, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.437303965517241 ], [ 29.794526075949367, -1.437573448275862 ], [ 29.794795569620252, -1.437573448275862 ], [ 29.794795569620252, -1.437303965517241 ], [ 29.794526075949367, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13847, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.437303965517241 ], [ 29.794795569620252, -1.437573448275862 ], [ 29.79506506329114, -1.437573448275862 ], [ 29.79506506329114, -1.437303965517241 ], [ 29.794795569620252, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13848, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.437303965517241 ], [ 29.79506506329114, -1.437573448275862 ], [ 29.795334556962025, -1.437573448275862 ], [ 29.795334556962025, -1.437303965517241 ], [ 29.79506506329114, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13849, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.437303965517241 ], [ 29.795334556962025, -1.437573448275862 ], [ 29.79560405063291, -1.437573448275862 ], [ 29.79560405063291, -1.437303965517241 ], [ 29.795334556962025, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13850, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.437303965517241 ], [ 29.79560405063291, -1.437573448275862 ], [ 29.795873544303799, -1.437573448275862 ], [ 29.795873544303799, -1.437303965517241 ], [ 29.79560405063291, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13851, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.437303965517241 ], [ 29.795873544303799, -1.437573448275862 ], [ 29.796143037974684, -1.437573448275862 ], [ 29.796143037974684, -1.437303965517241 ], [ 29.795873544303799, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13852, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.437303965517241 ], [ 29.796143037974684, -1.437573448275862 ], [ 29.796682025316457, -1.437573448275862 ], [ 29.796682025316457, -1.437303965517241 ], [ 29.796143037974684, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13853, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.437303965517241 ], [ 29.796682025316457, -1.437573448275862 ], [ 29.79776, -1.437573448275862 ], [ 29.79776, -1.437303965517241 ], [ 29.796682025316457, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13854, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.437303965517241 ], [ 29.79776, -1.437573448275862 ], [ 29.798298987341774, -1.437573448275862 ], [ 29.798298987341774, -1.437303965517241 ], [ 29.79776, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13855, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.437303965517241 ], [ 29.798298987341774, -1.437573448275862 ], [ 29.798568481012659, -1.437573448275862 ], [ 29.798568481012659, -1.437303965517241 ], [ 29.798298987341774, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13856, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.437303965517241 ], [ 29.798568481012659, -1.437573448275862 ], [ 29.798837974683543, -1.437573448275862 ], [ 29.798837974683543, -1.437303965517241 ], [ 29.798568481012659, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13857, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.437303965517241 ], [ 29.798837974683543, -1.437573448275862 ], [ 29.799107468354432, -1.437573448275862 ], [ 29.799107468354432, -1.437303965517241 ], [ 29.798837974683543, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13858, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.437303965517241 ], [ 29.799107468354432, -1.437573448275862 ], [ 29.799376962025317, -1.437573448275862 ], [ 29.799376962025317, -1.437303965517241 ], [ 29.799107468354432, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13859, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.437303965517241 ], [ 29.799376962025317, -1.437573448275862 ], [ 29.799646455696202, -1.437573448275862 ], [ 29.799646455696202, -1.437303965517241 ], [ 29.799376962025317, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13860, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.437303965517241 ], [ 29.799646455696202, -1.437573448275862 ], [ 29.79991594936709, -1.437573448275862 ], [ 29.79991594936709, -1.437303965517241 ], [ 29.799646455696202, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13861, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.437303965517241 ], [ 29.79991594936709, -1.437573448275862 ], [ 29.800185443037975, -1.437573448275862 ], [ 29.800185443037975, -1.437303965517241 ], [ 29.79991594936709, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13862, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.437303965517241 ], [ 29.800185443037975, -1.437573448275862 ], [ 29.80045493670886, -1.437573448275862 ], [ 29.80045493670886, -1.437303965517241 ], [ 29.800185443037975, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13863, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.437303965517241 ], [ 29.80045493670886, -1.437573448275862 ], [ 29.800724430379748, -1.437573448275862 ], [ 29.800724430379748, -1.437303965517241 ], [ 29.80045493670886, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13864, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.437303965517241 ], [ 29.800724430379748, -1.437573448275862 ], [ 29.800993924050633, -1.437573448275862 ], [ 29.800993924050633, -1.437303965517241 ], [ 29.800724430379748, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13865, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.437303965517241 ], [ 29.800993924050633, -1.437573448275862 ], [ 29.801263417721518, -1.437573448275862 ], [ 29.801263417721518, -1.437303965517241 ], [ 29.800993924050633, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13866, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.437303965517241 ], [ 29.801263417721518, -1.437573448275862 ], [ 29.801532911392407, -1.437573448275862 ], [ 29.801532911392407, -1.437303965517241 ], [ 29.801263417721518, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13867, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.437303965517241 ], [ 29.801532911392407, -1.437573448275862 ], [ 29.802071898734177, -1.437573448275862 ], [ 29.802071898734177, -1.437303965517241 ], [ 29.801532911392407, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13868, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.437303965517241 ], [ 29.802071898734177, -1.437573448275862 ], [ 29.802341392405065, -1.437573448275862 ], [ 29.802341392405065, -1.437303965517241 ], [ 29.802071898734177, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13869, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.437303965517241 ], [ 29.802341392405065, -1.437573448275862 ], [ 29.80261088607595, -1.437573448275862 ], [ 29.80261088607595, -1.437303965517241 ], [ 29.802341392405065, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13870, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.437303965517241 ], [ 29.80261088607595, -1.437573448275862 ], [ 29.802880379746835, -1.437573448275862 ], [ 29.802880379746835, -1.437303965517241 ], [ 29.80261088607595, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13871, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.437303965517241 ], [ 29.802880379746835, -1.437573448275862 ], [ 29.803149873417723, -1.437573448275862 ], [ 29.803149873417723, -1.437303965517241 ], [ 29.802880379746835, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13872, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.437303965517241 ], [ 29.803149873417723, -1.437573448275862 ], [ 29.803419367088608, -1.437573448275862 ], [ 29.803419367088608, -1.437303965517241 ], [ 29.803149873417723, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13873, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.437303965517241 ], [ 29.803419367088608, -1.437573448275862 ], [ 29.803688860759493, -1.437573448275862 ], [ 29.803688860759493, -1.437303965517241 ], [ 29.803419367088608, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13874, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.437303965517241 ], [ 29.803688860759493, -1.437573448275862 ], [ 29.803958354430382, -1.437573448275862 ], [ 29.803958354430382, -1.437303965517241 ], [ 29.803688860759493, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13875, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.437303965517241 ], [ 29.803958354430382, -1.437573448275862 ], [ 29.804227848101267, -1.437573448275862 ], [ 29.804227848101267, -1.437303965517241 ], [ 29.803958354430382, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13876, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.437303965517241 ], [ 29.804227848101267, -1.437573448275862 ], [ 29.80476683544304, -1.437573448275862 ], [ 29.80476683544304, -1.437303965517241 ], [ 29.804227848101267, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13877, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.437303965517241 ], [ 29.80476683544304, -1.437573448275862 ], [ 29.805036329113925, -1.437573448275862 ], [ 29.805036329113925, -1.437303965517241 ], [ 29.80476683544304, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13878, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.437303965517241 ], [ 29.805036329113925, -1.437573448275862 ], [ 29.80530582278481, -1.437573448275862 ], [ 29.80530582278481, -1.437303965517241 ], [ 29.805036329113925, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13879, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.437303965517241 ], [ 29.80530582278481, -1.437573448275862 ], [ 29.806114303797468, -1.437573448275862 ], [ 29.806114303797468, -1.437303965517241 ], [ 29.80530582278481, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13880, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.437303965517241 ], [ 29.806114303797468, -1.437573448275862 ], [ 29.806383797468357, -1.437573448275862 ], [ 29.806383797468357, -1.437303965517241 ], [ 29.806114303797468, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13881, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.437303965517241 ], [ 29.806383797468357, -1.437573448275862 ], [ 29.806653291139241, -1.437573448275862 ], [ 29.806653291139241, -1.437303965517241 ], [ 29.806383797468357, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13882, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.437303965517241 ], [ 29.806653291139241, -1.437573448275862 ], [ 29.806922784810126, -1.437573448275862 ], [ 29.806922784810126, -1.437303965517241 ], [ 29.806653291139241, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13883, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.437303965517241 ], [ 29.806922784810126, -1.437573448275862 ], [ 29.807192278481015, -1.437573448275862 ], [ 29.807192278481015, -1.437303965517241 ], [ 29.806922784810126, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13884, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.437303965517241 ], [ 29.807192278481015, -1.437573448275862 ], [ 29.807731265822785, -1.437573448275862 ], [ 29.807731265822785, -1.437303965517241 ], [ 29.807192278481015, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13885, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.437303965517241 ], [ 29.807731265822785, -1.437573448275862 ], [ 29.808000759493673, -1.437573448275862 ], [ 29.808000759493673, -1.437303965517241 ], [ 29.807731265822785, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13886, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.437303965517241 ], [ 29.808000759493673, -1.437573448275862 ], [ 29.808270253164558, -1.437573448275862 ], [ 29.808270253164558, -1.437303965517241 ], [ 29.808000759493673, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13887, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.437303965517241 ], [ 29.808270253164558, -1.437573448275862 ], [ 29.808539746835443, -1.437573448275862 ], [ 29.808539746835443, -1.437303965517241 ], [ 29.808270253164558, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13888, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.437303965517241 ], [ 29.808539746835443, -1.437573448275862 ], [ 29.808809240506328, -1.437573448275862 ], [ 29.808809240506328, -1.437303965517241 ], [ 29.808539746835443, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13889, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.437303965517241 ], [ 29.808809240506328, -1.437573448275862 ], [ 29.809078734177216, -1.437573448275862 ], [ 29.809078734177216, -1.437303965517241 ], [ 29.808809240506328, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13890, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.437303965517241 ], [ 29.809078734177216, -1.437573448275862 ], [ 29.809348227848101, -1.437573448275862 ], [ 29.809348227848101, -1.437303965517241 ], [ 29.809078734177216, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13891, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.437303965517241 ], [ 29.809348227848101, -1.437573448275862 ], [ 29.809617721518986, -1.437573448275862 ], [ 29.809617721518986, -1.437303965517241 ], [ 29.809348227848101, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13892, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.437303965517241 ], [ 29.809617721518986, -1.437573448275862 ], [ 29.809887215189875, -1.437573448275862 ], [ 29.809887215189875, -1.437303965517241 ], [ 29.809617721518986, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13893, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.437303965517241 ], [ 29.809887215189875, -1.437573448275862 ], [ 29.811234683544303, -1.437573448275862 ], [ 29.811234683544303, -1.437303965517241 ], [ 29.809887215189875, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13894, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743052784810125, -1.437573448275862 ], [ 29.743052784810125, -1.437842931034483 ], [ 29.743322278481013, -1.437842931034483 ], [ 29.743322278481013, -1.437573448275862 ], [ 29.743052784810125, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13895, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743322278481013, -1.437573448275862 ], [ 29.743322278481013, -1.437842931034483 ], [ 29.743591772151898, -1.437842931034483 ], [ 29.743591772151898, -1.437573448275862 ], [ 29.743322278481013, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13896, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.437573448275862 ], [ 29.743591772151898, -1.437842931034483 ], [ 29.743861265822783, -1.437842931034483 ], [ 29.743861265822783, -1.437573448275862 ], [ 29.743591772151898, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13897, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.437573448275862 ], [ 29.743861265822783, -1.438112413793104 ], [ 29.744130759493672, -1.438112413793104 ], [ 29.744130759493672, -1.437573448275862 ], [ 29.743861265822783, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13898, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.437573448275862 ], [ 29.744130759493672, -1.438112413793104 ], [ 29.744400253164557, -1.438112413793104 ], [ 29.744400253164557, -1.437573448275862 ], [ 29.744130759493672, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13899, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.437573448275862 ], [ 29.744400253164557, -1.437842931034483 ], [ 29.744669746835442, -1.437842931034483 ], [ 29.744669746835442, -1.437573448275862 ], [ 29.744400253164557, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13900, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.437573448275862 ], [ 29.744669746835442, -1.437842931034483 ], [ 29.744939240506326, -1.437842931034483 ], [ 29.744939240506326, -1.437573448275862 ], [ 29.744669746835442, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13901, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.437573448275862 ], [ 29.744939240506326, -1.437842931034483 ], [ 29.745208734177215, -1.437842931034483 ], [ 29.745208734177215, -1.437573448275862 ], [ 29.744939240506326, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13902, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.437573448275862 ], [ 29.745208734177215, -1.437842931034483 ], [ 29.7454782278481, -1.437842931034483 ], [ 29.7454782278481, -1.437573448275862 ], [ 29.745208734177215, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13903, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.437573448275862 ], [ 29.7454782278481, -1.437842931034483 ], [ 29.745747721518985, -1.437842931034483 ], [ 29.745747721518985, -1.437573448275862 ], [ 29.7454782278481, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13904, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.437573448275862 ], [ 29.745747721518985, -1.437842931034483 ], [ 29.746017215189873, -1.437842931034483 ], [ 29.746017215189873, -1.437573448275862 ], [ 29.745747721518985, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13905, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.437573448275862 ], [ 29.746017215189873, -1.437842931034483 ], [ 29.746286708860758, -1.437842931034483 ], [ 29.746286708860758, -1.437573448275862 ], [ 29.746017215189873, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13906, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.437573448275862 ], [ 29.746286708860758, -1.437842931034483 ], [ 29.746556202531643, -1.437842931034483 ], [ 29.746556202531643, -1.437573448275862 ], [ 29.746286708860758, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13907, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.437573448275862 ], [ 29.746556202531643, -1.437842931034483 ], [ 29.746825696202531, -1.437842931034483 ], [ 29.746825696202531, -1.437573448275862 ], [ 29.746556202531643, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13908, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.437573448275862 ], [ 29.746825696202531, -1.437842931034483 ], [ 29.747095189873416, -1.437842931034483 ], [ 29.747095189873416, -1.437573448275862 ], [ 29.746825696202531, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13909, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.437573448275862 ], [ 29.747095189873416, -1.437842931034483 ], [ 29.747364683544301, -1.437842931034483 ], [ 29.747364683544301, -1.437573448275862 ], [ 29.747095189873416, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13910, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.437573448275862 ], [ 29.747364683544301, -1.437842931034483 ], [ 29.747903670886075, -1.437842931034483 ], [ 29.747903670886075, -1.437573448275862 ], [ 29.747364683544301, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13911, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.437573448275862 ], [ 29.747903670886075, -1.437842931034483 ], [ 29.74817316455696, -1.437842931034483 ], [ 29.74817316455696, -1.437573448275862 ], [ 29.747903670886075, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13912, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.437573448275862 ], [ 29.74817316455696, -1.437842931034483 ], [ 29.748442658227848, -1.437842931034483 ], [ 29.748442658227848, -1.437573448275862 ], [ 29.74817316455696, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13913, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.437573448275862 ], [ 29.748442658227848, -1.437842931034483 ], [ 29.748981645569618, -1.437842931034483 ], [ 29.748981645569618, -1.437573448275862 ], [ 29.748442658227848, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13914, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.437573448275862 ], [ 29.748981645569618, -1.437842931034483 ], [ 29.749251139240506, -1.437842931034483 ], [ 29.749251139240506, -1.437573448275862 ], [ 29.748981645569618, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13915, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.437573448275862 ], [ 29.749251139240506, -1.437842931034483 ], [ 29.749520632911391, -1.437842931034483 ], [ 29.749520632911391, -1.437573448275862 ], [ 29.749251139240506, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13916, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.437573448275862 ], [ 29.749520632911391, -1.437842931034483 ], [ 29.749790126582276, -1.437842931034483 ], [ 29.749790126582276, -1.437573448275862 ], [ 29.749520632911391, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13917, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.437573448275862 ], [ 29.749790126582276, -1.437842931034483 ], [ 29.750059620253165, -1.437842931034483 ], [ 29.750059620253165, -1.437573448275862 ], [ 29.749790126582276, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13918, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.437573448275862 ], [ 29.750059620253165, -1.437842931034483 ], [ 29.75032911392405, -1.437842931034483 ], [ 29.75032911392405, -1.437573448275862 ], [ 29.750059620253165, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13919, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.437573448275862 ], [ 29.75032911392405, -1.437842931034483 ], [ 29.750598607594934, -1.437842931034483 ], [ 29.750598607594934, -1.437573448275862 ], [ 29.75032911392405, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13920, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.437573448275862 ], [ 29.750598607594934, -1.437842931034483 ], [ 29.754641012658226, -1.437842931034483 ], [ 29.754641012658226, -1.437573448275862 ], [ 29.750598607594934, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13921, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.437573448275862 ], [ 29.754641012658226, -1.437842931034483 ], [ 29.754910506329114, -1.437842931034483 ], [ 29.754910506329114, -1.437573448275862 ], [ 29.754641012658226, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13922, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.437573448275862 ], [ 29.754910506329114, -1.437842931034483 ], [ 29.75518, -1.437842931034483 ], [ 29.75518, -1.437573448275862 ], [ 29.754910506329114, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13923, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.437573448275862 ], [ 29.75518, -1.437842931034483 ], [ 29.755449493670884, -1.437842931034483 ], [ 29.755449493670884, -1.437573448275862 ], [ 29.75518, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13924, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.437573448275862 ], [ 29.755449493670884, -1.437842931034483 ], [ 29.755718987341773, -1.437842931034483 ], [ 29.755718987341773, -1.437573448275862 ], [ 29.755449493670884, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13925, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.437573448275862 ], [ 29.755718987341773, -1.437842931034483 ], [ 29.755988481012658, -1.437842931034483 ], [ 29.755988481012658, -1.437573448275862 ], [ 29.755718987341773, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13926, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.437573448275862 ], [ 29.756257974683542, -1.437842931034483 ], [ 29.756796962025316, -1.437842931034483 ], [ 29.756796962025316, -1.437573448275862 ], [ 29.756257974683542, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13927, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.437573448275862 ], [ 29.756796962025316, -1.437842931034483 ], [ 29.757066455696201, -1.437842931034483 ], [ 29.757066455696201, -1.437573448275862 ], [ 29.756796962025316, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13928, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.437573448275862 ], [ 29.757066455696201, -1.437842931034483 ], [ 29.757335949367089, -1.437842931034483 ], [ 29.757335949367089, -1.437573448275862 ], [ 29.757066455696201, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13929, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.437573448275862 ], [ 29.757335949367089, -1.437842931034483 ], [ 29.757605443037974, -1.437842931034483 ], [ 29.757605443037974, -1.437573448275862 ], [ 29.757335949367089, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13930, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.437573448275862 ], [ 29.761917341772151, -1.438651379310345 ], [ 29.762186835443039, -1.438651379310345 ], [ 29.762186835443039, -1.437573448275862 ], [ 29.761917341772151, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13931, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.437573448275862 ], [ 29.762186835443039, -1.438381896551724 ], [ 29.762456329113924, -1.438381896551724 ], [ 29.762456329113924, -1.437573448275862 ], [ 29.762186835443039, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13932, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.437573448275862 ], [ 29.762456329113924, -1.438381896551724 ], [ 29.762725822784809, -1.438381896551724 ], [ 29.762725822784809, -1.437573448275862 ], [ 29.762456329113924, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13933, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.437573448275862 ], [ 29.763264810126582, -1.438112413793104 ], [ 29.763534303797467, -1.438112413793104 ], [ 29.763534303797467, -1.437573448275862 ], [ 29.763264810126582, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13934, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.437573448275862 ], [ 29.76407329113924, -1.437842931034483 ], [ 29.764342784810125, -1.437842931034483 ], [ 29.764342784810125, -1.437573448275862 ], [ 29.76407329113924, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13935, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.437573448275862 ], [ 29.764342784810125, -1.437842931034483 ], [ 29.764612278481014, -1.437842931034483 ], [ 29.764612278481014, -1.437573448275862 ], [ 29.764342784810125, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13936, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.437573448275862 ], [ 29.764612278481014, -1.437842931034483 ], [ 29.764881772151899, -1.437842931034483 ], [ 29.764881772151899, -1.437573448275862 ], [ 29.764612278481014, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13937, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.437573448275862 ], [ 29.764881772151899, -1.437842931034483 ], [ 29.765151265822784, -1.437842931034483 ], [ 29.765151265822784, -1.437573448275862 ], [ 29.764881772151899, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13938, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.437573448275862 ], [ 29.765151265822784, -1.437842931034483 ], [ 29.765420759493672, -1.437842931034483 ], [ 29.765420759493672, -1.437573448275862 ], [ 29.765151265822784, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13939, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.437573448275862 ], [ 29.765420759493672, -1.437842931034483 ], [ 29.765959746835442, -1.437842931034483 ], [ 29.765959746835442, -1.437573448275862 ], [ 29.765420759493672, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13940, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.437573448275862 ], [ 29.765959746835442, -1.438112413793104 ], [ 29.766229240506327, -1.438112413793104 ], [ 29.766229240506327, -1.437573448275862 ], [ 29.765959746835442, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13941, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.437573448275862 ], [ 29.766229240506327, -1.437842931034483 ], [ 29.766498734177215, -1.437842931034483 ], [ 29.766498734177215, -1.437573448275862 ], [ 29.766229240506327, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13942, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.437573448275862 ], [ 29.766498734177215, -1.437842931034483 ], [ 29.7667682278481, -1.437842931034483 ], [ 29.7667682278481, -1.437573448275862 ], [ 29.766498734177215, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13943, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.437573448275862 ], [ 29.7667682278481, -1.438112413793104 ], [ 29.767037721518985, -1.438112413793104 ], [ 29.767037721518985, -1.437573448275862 ], [ 29.7667682278481, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13944, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.437573448275862 ], [ 29.767037721518985, -1.437842931034483 ], [ 29.767307215189874, -1.437842931034483 ], [ 29.767307215189874, -1.437573448275862 ], [ 29.767037721518985, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13945, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.437573448275862 ], [ 29.767307215189874, -1.437842931034483 ], [ 29.767576708860759, -1.437842931034483 ], [ 29.767576708860759, -1.437573448275862 ], [ 29.767307215189874, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13946, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.437573448275862 ], [ 29.767576708860759, -1.438112413793104 ], [ 29.767846202531643, -1.438112413793104 ], [ 29.767846202531643, -1.437573448275862 ], [ 29.767576708860759, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13947, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.437573448275862 ], [ 29.768115696202532, -1.437842931034483 ], [ 29.768385189873417, -1.437842931034483 ], [ 29.768385189873417, -1.437573448275862 ], [ 29.768115696202532, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13948, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.437573448275862 ], [ 29.768385189873417, -1.438112413793104 ], [ 29.768654683544302, -1.438112413793104 ], [ 29.768654683544302, -1.437573448275862 ], [ 29.768385189873417, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13949, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.437573448275862 ], [ 29.76892417721519, -1.438112413793104 ], [ 29.769193670886075, -1.438112413793104 ], [ 29.769193670886075, -1.437573448275862 ], [ 29.76892417721519, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13950, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.437573448275862 ], [ 29.769193670886075, -1.438381896551724 ], [ 29.76946316455696, -1.438381896551724 ], [ 29.76946316455696, -1.437573448275862 ], [ 29.769193670886075, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13951, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.437573448275862 ], [ 29.769732658227849, -1.438112413793104 ], [ 29.770002151898733, -1.438112413793104 ], [ 29.770002151898733, -1.437573448275862 ], [ 29.769732658227849, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13952, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.437573448275862 ], [ 29.770541139240507, -1.438381896551724 ], [ 29.770810632911392, -1.438381896551724 ], [ 29.770810632911392, -1.437573448275862 ], [ 29.770541139240507, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13953, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.437573448275862 ], [ 29.771888607594935, -1.438381896551724 ], [ 29.772158101265823, -1.438381896551724 ], [ 29.772158101265823, -1.437573448275862 ], [ 29.771888607594935, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13954, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.437573448275862 ], [ 29.772697088607593, -1.438920862068966 ], [ 29.772966582278482, -1.438920862068966 ], [ 29.772966582278482, -1.437573448275862 ], [ 29.772697088607593, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13955, "El": 143 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.437573448275862 ], [ 29.773505569620252, -1.438381896551724 ], [ 29.77377506329114, -1.438381896551724 ], [ 29.77377506329114, -1.437573448275862 ], [ 29.773505569620252, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13956, "El": 146 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.437573448275862 ], [ 29.77377506329114, -1.438381896551724 ], [ 29.774044556962025, -1.438381896551724 ], [ 29.774044556962025, -1.437573448275862 ], [ 29.77377506329114, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13957, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.437573448275862 ], [ 29.782668354430381, -1.438112413793104 ], [ 29.782937848101266, -1.438112413793104 ], [ 29.782937848101266, -1.437573448275862 ], [ 29.782668354430381, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13958, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.437573448275862 ], [ 29.783476835443039, -1.437842931034483 ], [ 29.784015822784809, -1.437842931034483 ], [ 29.784015822784809, -1.437573448275862 ], [ 29.783476835443039, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13959, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.437573448275862 ], [ 29.784015822784809, -1.437842931034483 ], [ 29.784285316455698, -1.437842931034483 ], [ 29.784285316455698, -1.437573448275862 ], [ 29.784015822784809, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13960, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.437573448275862 ], [ 29.784285316455698, -1.438112413793104 ], [ 29.784554810126583, -1.438112413793104 ], [ 29.784554810126583, -1.437573448275862 ], [ 29.784285316455698, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13961, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.437573448275862 ], [ 29.784554810126583, -1.437842931034483 ], [ 29.784824303797468, -1.437842931034483 ], [ 29.784824303797468, -1.437573448275862 ], [ 29.784554810126583, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13962, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.437573448275862 ], [ 29.784824303797468, -1.437842931034483 ], [ 29.785093797468356, -1.437842931034483 ], [ 29.785093797468356, -1.437573448275862 ], [ 29.784824303797468, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13963, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.437573448275862 ], [ 29.785093797468356, -1.437842931034483 ], [ 29.785363291139241, -1.437842931034483 ], [ 29.785363291139241, -1.437573448275862 ], [ 29.785093797468356, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13964, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.437573448275862 ], [ 29.785363291139241, -1.437842931034483 ], [ 29.785632784810126, -1.437842931034483 ], [ 29.785632784810126, -1.437573448275862 ], [ 29.785363291139241, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13965, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.437573448275862 ], [ 29.785632784810126, -1.437842931034483 ], [ 29.785902278481014, -1.437842931034483 ], [ 29.785902278481014, -1.437573448275862 ], [ 29.785632784810126, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13966, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.437573448275862 ], [ 29.785902278481014, -1.437842931034483 ], [ 29.787249746835442, -1.437842931034483 ], [ 29.787249746835442, -1.437573448275862 ], [ 29.785902278481014, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13967, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.437573448275862 ], [ 29.787249746835442, -1.437842931034483 ], [ 29.787519240506327, -1.437842931034483 ], [ 29.787519240506327, -1.437573448275862 ], [ 29.787249746835442, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13968, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.437573448275862 ], [ 29.787519240506327, -1.437842931034483 ], [ 29.789136202531644, -1.437842931034483 ], [ 29.789136202531644, -1.437573448275862 ], [ 29.787519240506327, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13969, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.437573448275862 ], [ 29.789136202531644, -1.437842931034483 ], [ 29.789405696202532, -1.437842931034483 ], [ 29.789405696202532, -1.437573448275862 ], [ 29.789136202531644, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13970, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.437573448275862 ], [ 29.789405696202532, -1.437842931034483 ], [ 29.789675189873417, -1.437842931034483 ], [ 29.789675189873417, -1.437573448275862 ], [ 29.789405696202532, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13971, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.437573448275862 ], [ 29.789675189873417, -1.437842931034483 ], [ 29.789944683544302, -1.437842931034483 ], [ 29.789944683544302, -1.437573448275862 ], [ 29.789675189873417, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13972, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.437573448275862 ], [ 29.789944683544302, -1.437842931034483 ], [ 29.790214177215191, -1.437842931034483 ], [ 29.790214177215191, -1.437573448275862 ], [ 29.789944683544302, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13973, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.437573448275862 ], [ 29.790214177215191, -1.437842931034483 ], [ 29.790483670886076, -1.437842931034483 ], [ 29.790483670886076, -1.437573448275862 ], [ 29.790214177215191, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13974, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.437573448275862 ], [ 29.790483670886076, -1.437842931034483 ], [ 29.790753164556961, -1.437842931034483 ], [ 29.790753164556961, -1.437573448275862 ], [ 29.790483670886076, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13975, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.437573448275862 ], [ 29.790753164556961, -1.437842931034483 ], [ 29.791022658227849, -1.437842931034483 ], [ 29.791022658227849, -1.437573448275862 ], [ 29.790753164556961, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13976, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.437573448275862 ], [ 29.791022658227849, -1.438112413793104 ], [ 29.791292151898734, -1.438112413793104 ], [ 29.791292151898734, -1.437573448275862 ], [ 29.791022658227849, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13977, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.437573448275862 ], [ 29.791292151898734, -1.437842931034483 ], [ 29.791561645569619, -1.437842931034483 ], [ 29.791561645569619, -1.437573448275862 ], [ 29.791292151898734, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13978, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.437573448275862 ], [ 29.791561645569619, -1.437842931034483 ], [ 29.791831139240507, -1.437842931034483 ], [ 29.791831139240507, -1.437573448275862 ], [ 29.791561645569619, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13979, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.437573448275862 ], [ 29.791831139240507, -1.439190344827586 ], [ 29.792100632911392, -1.439190344827586 ], [ 29.792100632911392, -1.437573448275862 ], [ 29.791831139240507, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13980, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.437573448275862 ], [ 29.792370126582277, -1.438112413793104 ], [ 29.792909113924051, -1.438112413793104 ], [ 29.792909113924051, -1.437573448275862 ], [ 29.792370126582277, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13981, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.437573448275862 ], [ 29.792909113924051, -1.439190344827586 ], [ 29.793178607594935, -1.439190344827586 ], [ 29.793178607594935, -1.437573448275862 ], [ 29.792909113924051, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13982, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.437573448275862 ], [ 29.793448101265824, -1.439190344827586 ], [ 29.793717594936709, -1.439190344827586 ], [ 29.793717594936709, -1.437573448275862 ], [ 29.793448101265824, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13983, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.437573448275862 ], [ 29.793717594936709, -1.439190344827586 ], [ 29.793987088607594, -1.439190344827586 ], [ 29.793987088607594, -1.437573448275862 ], [ 29.793717594936709, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13984, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.437573448275862 ], [ 29.794256582278482, -1.439190344827586 ], [ 29.794526075949367, -1.439190344827586 ], [ 29.794526075949367, -1.438920862068966 ], [ 29.794795569620252, -1.438920862068966 ], [ 29.794795569620252, -1.438651379310345 ], [ 29.794526075949367, -1.438651379310345 ], [ 29.794526075949367, -1.437573448275862 ], [ 29.794256582278482, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13985, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.437573448275862 ], [ 29.794526075949367, -1.437842931034483 ], [ 29.794795569620252, -1.437842931034483 ], [ 29.794795569620252, -1.437573448275862 ], [ 29.794526075949367, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13986, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.437573448275862 ], [ 29.794795569620252, -1.437842931034483 ], [ 29.79506506329114, -1.437842931034483 ], [ 29.79506506329114, -1.437573448275862 ], [ 29.794795569620252, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13987, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.437573448275862 ], [ 29.79506506329114, -1.437842931034483 ], [ 29.795334556962025, -1.437842931034483 ], [ 29.795334556962025, -1.437573448275862 ], [ 29.79506506329114, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13988, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.437573448275862 ], [ 29.795334556962025, -1.437842931034483 ], [ 29.79560405063291, -1.437842931034483 ], [ 29.79560405063291, -1.437573448275862 ], [ 29.795334556962025, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13989, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.437573448275862 ], [ 29.79560405063291, -1.437842931034483 ], [ 29.795873544303799, -1.437842931034483 ], [ 29.795873544303799, -1.437573448275862 ], [ 29.79560405063291, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13990, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.437573448275862 ], [ 29.795873544303799, -1.437842931034483 ], [ 29.796412531645569, -1.437842931034483 ], [ 29.796412531645569, -1.437573448275862 ], [ 29.795873544303799, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13991, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.437573448275862 ], [ 29.796412531645569, -1.437842931034483 ], [ 29.797490506329115, -1.437842931034483 ], [ 29.797490506329115, -1.438112413793104 ], [ 29.799646455696202, -1.438112413793104 ], [ 29.799646455696202, -1.437842931034483 ], [ 29.798029493670885, -1.437842931034483 ], [ 29.798029493670885, -1.437573448275862 ], [ 29.796412531645569, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13992, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.437573448275862 ], [ 29.798029493670885, -1.437842931034483 ], [ 29.798568481012659, -1.437842931034483 ], [ 29.798568481012659, -1.437573448275862 ], [ 29.798029493670885, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13993, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.437573448275862 ], [ 29.798568481012659, -1.437842931034483 ], [ 29.799646455696202, -1.437842931034483 ], [ 29.799646455696202, -1.437573448275862 ], [ 29.798568481012659, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13994, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.437573448275862 ], [ 29.799646455696202, -1.437842931034483 ], [ 29.800185443037975, -1.437842931034483 ], [ 29.800185443037975, -1.437573448275862 ], [ 29.799646455696202, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13995, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.437573448275862 ], [ 29.800185443037975, -1.437842931034483 ], [ 29.80045493670886, -1.437842931034483 ], [ 29.80045493670886, -1.437573448275862 ], [ 29.800185443037975, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13996, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.437573448275862 ], [ 29.80045493670886, -1.437842931034483 ], [ 29.800724430379748, -1.437842931034483 ], [ 29.800724430379748, -1.437573448275862 ], [ 29.80045493670886, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13997, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.437573448275862 ], [ 29.800724430379748, -1.437842931034483 ], [ 29.800993924050633, -1.437842931034483 ], [ 29.800993924050633, -1.437573448275862 ], [ 29.800724430379748, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13998, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.437573448275862 ], [ 29.800993924050633, -1.437842931034483 ], [ 29.801263417721518, -1.437842931034483 ], [ 29.801263417721518, -1.437573448275862 ], [ 29.800993924050633, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 13999, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.437573448275862 ], [ 29.801263417721518, -1.437842931034483 ], [ 29.801532911392407, -1.437842931034483 ], [ 29.801532911392407, -1.437573448275862 ], [ 29.801263417721518, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14000, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.437573448275862 ], [ 29.801532911392407, -1.437842931034483 ], [ 29.802071898734177, -1.437842931034483 ], [ 29.802071898734177, -1.437573448275862 ], [ 29.801532911392407, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14001, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.437573448275862 ], [ 29.802071898734177, -1.437842931034483 ], [ 29.802341392405065, -1.437842931034483 ], [ 29.802341392405065, -1.437573448275862 ], [ 29.802071898734177, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14002, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.437573448275862 ], [ 29.802341392405065, -1.437842931034483 ], [ 29.80261088607595, -1.437842931034483 ], [ 29.80261088607595, -1.437573448275862 ], [ 29.802341392405065, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14003, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.437573448275862 ], [ 29.80261088607595, -1.437842931034483 ], [ 29.802880379746835, -1.437842931034483 ], [ 29.802880379746835, -1.437573448275862 ], [ 29.80261088607595, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14004, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.437573448275862 ], [ 29.802880379746835, -1.437842931034483 ], [ 29.803149873417723, -1.437842931034483 ], [ 29.803149873417723, -1.437573448275862 ], [ 29.802880379746835, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14005, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.437573448275862 ], [ 29.803149873417723, -1.437842931034483 ], [ 29.803419367088608, -1.437842931034483 ], [ 29.803419367088608, -1.437573448275862 ], [ 29.803149873417723, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14006, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.437573448275862 ], [ 29.803419367088608, -1.437842931034483 ], [ 29.803688860759493, -1.437842931034483 ], [ 29.803688860759493, -1.437573448275862 ], [ 29.803419367088608, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14007, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.437573448275862 ], [ 29.803688860759493, -1.437842931034483 ], [ 29.803958354430382, -1.437842931034483 ], [ 29.803958354430382, -1.437573448275862 ], [ 29.803688860759493, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14008, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.437573448275862 ], [ 29.803958354430382, -1.437842931034483 ], [ 29.804227848101267, -1.437842931034483 ], [ 29.804227848101267, -1.437573448275862 ], [ 29.803958354430382, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14009, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.437573448275862 ], [ 29.804227848101267, -1.437842931034483 ], [ 29.80476683544304, -1.437842931034483 ], [ 29.80476683544304, -1.437573448275862 ], [ 29.804227848101267, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14010, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.437573448275862 ], [ 29.80476683544304, -1.437842931034483 ], [ 29.805036329113925, -1.437842931034483 ], [ 29.805036329113925, -1.437573448275862 ], [ 29.80476683544304, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14011, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.437573448275862 ], [ 29.805036329113925, -1.437842931034483 ], [ 29.806383797468357, -1.437842931034483 ], [ 29.806383797468357, -1.437573448275862 ], [ 29.805036329113925, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14012, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.437573448275862 ], [ 29.806383797468357, -1.437842931034483 ], [ 29.806653291139241, -1.437842931034483 ], [ 29.806653291139241, -1.437573448275862 ], [ 29.806383797468357, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14013, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.437573448275862 ], [ 29.806653291139241, -1.437842931034483 ], [ 29.806922784810126, -1.437842931034483 ], [ 29.806922784810126, -1.437573448275862 ], [ 29.806653291139241, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14014, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.437573448275862 ], [ 29.806922784810126, -1.437842931034483 ], [ 29.807192278481015, -1.437842931034483 ], [ 29.807192278481015, -1.437573448275862 ], [ 29.806922784810126, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14015, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.437573448275862 ], [ 29.807192278481015, -1.437842931034483 ], [ 29.807731265822785, -1.437842931034483 ], [ 29.807731265822785, -1.437573448275862 ], [ 29.807192278481015, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14016, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.437573448275862 ], [ 29.807731265822785, -1.437842931034483 ], [ 29.808000759493673, -1.437842931034483 ], [ 29.808000759493673, -1.437573448275862 ], [ 29.807731265822785, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14017, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.437573448275862 ], [ 29.808000759493673, -1.437842931034483 ], [ 29.808270253164558, -1.437842931034483 ], [ 29.808270253164558, -1.437573448275862 ], [ 29.808000759493673, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14018, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.437573448275862 ], [ 29.808270253164558, -1.437842931034483 ], [ 29.808539746835443, -1.437842931034483 ], [ 29.808539746835443, -1.437573448275862 ], [ 29.808270253164558, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14019, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.437573448275862 ], [ 29.808539746835443, -1.437842931034483 ], [ 29.808809240506328, -1.437842931034483 ], [ 29.808809240506328, -1.437573448275862 ], [ 29.808539746835443, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14020, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.437573448275862 ], [ 29.808809240506328, -1.437842931034483 ], [ 29.809078734177216, -1.437842931034483 ], [ 29.809078734177216, -1.437573448275862 ], [ 29.808809240506328, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14021, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.437573448275862 ], [ 29.809078734177216, -1.437842931034483 ], [ 29.809348227848101, -1.437842931034483 ], [ 29.809348227848101, -1.437573448275862 ], [ 29.809078734177216, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14022, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.437573448275862 ], [ 29.809348227848101, -1.437842931034483 ], [ 29.809617721518986, -1.437842931034483 ], [ 29.809617721518986, -1.437573448275862 ], [ 29.809348227848101, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14023, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.437573448275862 ], [ 29.809617721518986, -1.437842931034483 ], [ 29.809887215189875, -1.437842931034483 ], [ 29.809887215189875, -1.437573448275862 ], [ 29.809617721518986, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14024, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.437573448275862 ], [ 29.809887215189875, -1.437842931034483 ], [ 29.810965189873418, -1.437842931034483 ], [ 29.810965189873418, -1.437573448275862 ], [ 29.809887215189875, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14025, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.437573448275862 ], [ 29.810965189873418, -1.437842931034483 ], [ 29.811234683544303, -1.437842931034483 ], [ 29.811234683544303, -1.437573448275862 ], [ 29.810965189873418, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14026, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.437573448275862 ], [ 29.811234683544303, -1.437842931034483 ], [ 29.811504177215191, -1.437842931034483 ], [ 29.811504177215191, -1.437573448275862 ], [ 29.811234683544303, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14027, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743322278481013, -1.437842931034483 ], [ 29.743322278481013, -1.438381896551724 ], [ 29.743591772151898, -1.438381896551724 ], [ 29.743591772151898, -1.437842931034483 ], [ 29.743322278481013, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14028, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.437842931034483 ], [ 29.743591772151898, -1.438112413793104 ], [ 29.743861265822783, -1.438112413793104 ], [ 29.743861265822783, -1.437842931034483 ], [ 29.743591772151898, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14029, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.437842931034483 ], [ 29.744400253164557, -1.438112413793104 ], [ 29.744669746835442, -1.438112413793104 ], [ 29.744669746835442, -1.437842931034483 ], [ 29.744400253164557, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14030, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.437842931034483 ], [ 29.744669746835442, -1.438112413793104 ], [ 29.744939240506326, -1.438112413793104 ], [ 29.744939240506326, -1.437842931034483 ], [ 29.744669746835442, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14031, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.437842931034483 ], [ 29.744939240506326, -1.438112413793104 ], [ 29.745208734177215, -1.438112413793104 ], [ 29.745208734177215, -1.437842931034483 ], [ 29.744939240506326, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14032, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.437842931034483 ], [ 29.745208734177215, -1.438112413793104 ], [ 29.7454782278481, -1.438112413793104 ], [ 29.7454782278481, -1.437842931034483 ], [ 29.745208734177215, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14033, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.437842931034483 ], [ 29.7454782278481, -1.438112413793104 ], [ 29.745747721518985, -1.438112413793104 ], [ 29.745747721518985, -1.437842931034483 ], [ 29.7454782278481, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14034, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.437842931034483 ], [ 29.745747721518985, -1.438381896551724 ], [ 29.746017215189873, -1.438381896551724 ], [ 29.746017215189873, -1.437842931034483 ], [ 29.745747721518985, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14035, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.437842931034483 ], [ 29.746017215189873, -1.438112413793104 ], [ 29.746286708860758, -1.438112413793104 ], [ 29.746286708860758, -1.437842931034483 ], [ 29.746017215189873, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14036, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.437842931034483 ], [ 29.746286708860758, -1.438112413793104 ], [ 29.746556202531643, -1.438112413793104 ], [ 29.746556202531643, -1.437842931034483 ], [ 29.746286708860758, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14037, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.437842931034483 ], [ 29.746556202531643, -1.438112413793104 ], [ 29.746825696202531, -1.438112413793104 ], [ 29.746825696202531, -1.437842931034483 ], [ 29.746556202531643, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14038, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.437842931034483 ], [ 29.746825696202531, -1.438112413793104 ], [ 29.747095189873416, -1.438112413793104 ], [ 29.747095189873416, -1.437842931034483 ], [ 29.746825696202531, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14039, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.437842931034483 ], [ 29.747095189873416, -1.438112413793104 ], [ 29.747364683544301, -1.438112413793104 ], [ 29.747364683544301, -1.437842931034483 ], [ 29.747095189873416, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14040, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.437842931034483 ], [ 29.747364683544301, -1.438112413793104 ], [ 29.747903670886075, -1.438112413793104 ], [ 29.747903670886075, -1.437842931034483 ], [ 29.747364683544301, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14041, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.437842931034483 ], [ 29.747903670886075, -1.438112413793104 ], [ 29.74817316455696, -1.438112413793104 ], [ 29.74817316455696, -1.437842931034483 ], [ 29.747903670886075, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14042, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.437842931034483 ], [ 29.74817316455696, -1.438112413793104 ], [ 29.748442658227848, -1.438112413793104 ], [ 29.748442658227848, -1.437842931034483 ], [ 29.74817316455696, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14043, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.437842931034483 ], [ 29.748442658227848, -1.438112413793104 ], [ 29.748712151898733, -1.438112413793104 ], [ 29.748712151898733, -1.437842931034483 ], [ 29.748442658227848, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14044, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.437842931034483 ], [ 29.748712151898733, -1.438112413793104 ], [ 29.748981645569618, -1.438112413793104 ], [ 29.748981645569618, -1.437842931034483 ], [ 29.748712151898733, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14045, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.437842931034483 ], [ 29.748981645569618, -1.438112413793104 ], [ 29.749251139240506, -1.438112413793104 ], [ 29.749251139240506, -1.437842931034483 ], [ 29.748981645569618, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14046, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.437842931034483 ], [ 29.749251139240506, -1.438112413793104 ], [ 29.749520632911391, -1.438112413793104 ], [ 29.749520632911391, -1.437842931034483 ], [ 29.749251139240506, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14047, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.437842931034483 ], [ 29.749520632911391, -1.438112413793104 ], [ 29.749790126582276, -1.438112413793104 ], [ 29.749790126582276, -1.437842931034483 ], [ 29.749520632911391, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14048, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.437842931034483 ], [ 29.749790126582276, -1.438112413793104 ], [ 29.750059620253165, -1.438112413793104 ], [ 29.750059620253165, -1.437842931034483 ], [ 29.749790126582276, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14049, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.437842931034483 ], [ 29.750059620253165, -1.438112413793104 ], [ 29.75032911392405, -1.438112413793104 ], [ 29.75032911392405, -1.437842931034483 ], [ 29.750059620253165, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14050, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.437842931034483 ], [ 29.75032911392405, -1.438112413793104 ], [ 29.750868101265823, -1.438112413793104 ], [ 29.750868101265823, -1.437842931034483 ], [ 29.75032911392405, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14051, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.437842931034483 ], [ 29.750868101265823, -1.438112413793104 ], [ 29.754371518987341, -1.438112413793104 ], [ 29.754371518987341, -1.437842931034483 ], [ 29.750868101265823, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14052, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.437842931034483 ], [ 29.754371518987341, -1.438112413793104 ], [ 29.754910506329114, -1.438112413793104 ], [ 29.754910506329114, -1.437842931034483 ], [ 29.754371518987341, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14053, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.437842931034483 ], [ 29.754910506329114, -1.438112413793104 ], [ 29.755449493670884, -1.438112413793104 ], [ 29.755449493670884, -1.437842931034483 ], [ 29.754910506329114, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14054, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.437842931034483 ], [ 29.755449493670884, -1.438112413793104 ], [ 29.755718987341773, -1.438112413793104 ], [ 29.755718987341773, -1.437842931034483 ], [ 29.755449493670884, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14055, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.437842931034483 ], [ 29.755718987341773, -1.438112413793104 ], [ 29.755988481012658, -1.438112413793104 ], [ 29.755988481012658, -1.437842931034483 ], [ 29.755718987341773, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14056, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.437842931034483 ], [ 29.756257974683542, -1.438112413793104 ], [ 29.756796962025316, -1.438112413793104 ], [ 29.756796962025316, -1.437842931034483 ], [ 29.756257974683542, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14057, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.437842931034483 ], [ 29.756796962025316, -1.438112413793104 ], [ 29.757066455696201, -1.438112413793104 ], [ 29.757066455696201, -1.437842931034483 ], [ 29.756796962025316, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14058, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.437842931034483 ], [ 29.763534303797467, -1.438112413793104 ], [ 29.763803797468356, -1.438112413793104 ], [ 29.763803797468356, -1.437842931034483 ], [ 29.763534303797467, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14059, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.437842931034483 ], [ 29.763803797468356, -1.438112413793104 ], [ 29.76407329113924, -1.438112413793104 ], [ 29.76407329113924, -1.437842931034483 ], [ 29.763803797468356, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14060, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.437842931034483 ], [ 29.76407329113924, -1.438112413793104 ], [ 29.764342784810125, -1.438112413793104 ], [ 29.764342784810125, -1.437842931034483 ], [ 29.76407329113924, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14061, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.437842931034483 ], [ 29.764342784810125, -1.438112413793104 ], [ 29.764612278481014, -1.438112413793104 ], [ 29.764612278481014, -1.437842931034483 ], [ 29.764342784810125, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14062, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.437842931034483 ], [ 29.764612278481014, -1.438381896551724 ], [ 29.764881772151899, -1.438381896551724 ], [ 29.764881772151899, -1.437842931034483 ], [ 29.764612278481014, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14063, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.437842931034483 ], [ 29.764881772151899, -1.438112413793104 ], [ 29.765151265822784, -1.438112413793104 ], [ 29.765151265822784, -1.437842931034483 ], [ 29.764881772151899, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14064, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.437842931034483 ], [ 29.765151265822784, -1.438112413793104 ], [ 29.765420759493672, -1.438112413793104 ], [ 29.765420759493672, -1.437842931034483 ], [ 29.765151265822784, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14065, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.437842931034483 ], [ 29.765420759493672, -1.438381896551724 ], [ 29.765959746835442, -1.438381896551724 ], [ 29.765959746835442, -1.437842931034483 ], [ 29.765420759493672, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14066, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.437842931034483 ], [ 29.766229240506327, -1.438112413793104 ], [ 29.766498734177215, -1.438112413793104 ], [ 29.766498734177215, -1.437842931034483 ], [ 29.766229240506327, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14067, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.437842931034483 ], [ 29.766498734177215, -1.438381896551724 ], [ 29.7667682278481, -1.438381896551724 ], [ 29.7667682278481, -1.437842931034483 ], [ 29.766498734177215, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14068, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.437842931034483 ], [ 29.767037721518985, -1.438381896551724 ], [ 29.767307215189874, -1.438381896551724 ], [ 29.767307215189874, -1.437842931034483 ], [ 29.767037721518985, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14069, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.437842931034483 ], [ 29.767846202531643, -1.438381896551724 ], [ 29.768115696202532, -1.438381896551724 ], [ 29.768115696202532, -1.437842931034483 ], [ 29.767846202531643, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14070, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.437842931034483 ], [ 29.772158101265823, -1.439190344827586 ], [ 29.772427594936708, -1.439190344827586 ], [ 29.772427594936708, -1.437842931034483 ], [ 29.772158101265823, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14071, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.437842931034483 ], [ 29.772966582278482, -1.438920862068966 ], [ 29.773236075949367, -1.438920862068966 ], [ 29.773236075949367, -1.437842931034483 ], [ 29.772966582278482, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14072, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.437842931034483 ], [ 29.77431405063291, -1.439459827586207 ], [ 29.774853037974683, -1.439459827586207 ], [ 29.774853037974683, -1.437842931034483 ], [ 29.77431405063291, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14073, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.437842931034483 ], [ 29.785093797468356, -1.438112413793104 ], [ 29.785363291139241, -1.438112413793104 ], [ 29.785363291139241, -1.437842931034483 ], [ 29.785093797468356, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14074, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.437842931034483 ], [ 29.785363291139241, -1.438112413793104 ], [ 29.785632784810126, -1.438112413793104 ], [ 29.785632784810126, -1.437842931034483 ], [ 29.785363291139241, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14075, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.437842931034483 ], [ 29.785632784810126, -1.438112413793104 ], [ 29.785902278481014, -1.438112413793104 ], [ 29.785902278481014, -1.437842931034483 ], [ 29.785632784810126, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14076, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.437842931034483 ], [ 29.785902278481014, -1.438112413793104 ], [ 29.787519240506327, -1.438112413793104 ], [ 29.787519240506327, -1.437842931034483 ], [ 29.785902278481014, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14077, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.437842931034483 ], [ 29.789136202531644, -1.438112413793104 ], [ 29.789675189873417, -1.438112413793104 ], [ 29.789675189873417, -1.437842931034483 ], [ 29.789136202531644, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14078, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.437842931034483 ], [ 29.789675189873417, -1.438112413793104 ], [ 29.789944683544302, -1.438112413793104 ], [ 29.789944683544302, -1.437842931034483 ], [ 29.789675189873417, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14079, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.437842931034483 ], [ 29.789944683544302, -1.438112413793104 ], [ 29.790214177215191, -1.438112413793104 ], [ 29.790214177215191, -1.437842931034483 ], [ 29.789944683544302, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14080, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.437842931034483 ], [ 29.790214177215191, -1.438112413793104 ], [ 29.790483670886076, -1.438112413793104 ], [ 29.790483670886076, -1.437842931034483 ], [ 29.790214177215191, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14081, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.437842931034483 ], [ 29.790483670886076, -1.438920862068966 ], [ 29.790753164556961, -1.438920862068966 ], [ 29.790753164556961, -1.437842931034483 ], [ 29.790483670886076, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14082, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.437842931034483 ], [ 29.790753164556961, -1.438920862068966 ], [ 29.791022658227849, -1.438920862068966 ], [ 29.791022658227849, -1.437842931034483 ], [ 29.790753164556961, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14083, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.437842931034483 ], [ 29.791292151898734, -1.438920862068966 ], [ 29.791561645569619, -1.438920862068966 ], [ 29.791561645569619, -1.437842931034483 ], [ 29.791292151898734, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14084, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.437842931034483 ], [ 29.791561645569619, -1.438920862068966 ], [ 29.791831139240507, -1.438920862068966 ], [ 29.791831139240507, -1.437842931034483 ], [ 29.791561645569619, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14085, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.437842931034483 ], [ 29.792100632911392, -1.438920862068966 ], [ 29.792370126582277, -1.438920862068966 ], [ 29.792370126582277, -1.437842931034483 ], [ 29.792100632911392, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14086, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.437842931034483 ], [ 29.793178607594935, -1.438920862068966 ], [ 29.793448101265824, -1.438920862068966 ], [ 29.793448101265824, -1.437842931034483 ], [ 29.793178607594935, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14087, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.437842931034483 ], [ 29.793987088607594, -1.438920862068966 ], [ 29.794256582278482, -1.438920862068966 ], [ 29.794256582278482, -1.437842931034483 ], [ 29.793987088607594, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14088, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.437842931034483 ], [ 29.794526075949367, -1.438112413793104 ], [ 29.794795569620252, -1.438112413793104 ], [ 29.794795569620252, -1.437842931034483 ], [ 29.794526075949367, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14089, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.437842931034483 ], [ 29.794795569620252, -1.438112413793104 ], [ 29.79506506329114, -1.438112413793104 ], [ 29.79506506329114, -1.437842931034483 ], [ 29.794795569620252, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14090, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.437842931034483 ], [ 29.79506506329114, -1.438112413793104 ], [ 29.795334556962025, -1.438112413793104 ], [ 29.795334556962025, -1.437842931034483 ], [ 29.79506506329114, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14091, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.437842931034483 ], [ 29.795334556962025, -1.438112413793104 ], [ 29.79560405063291, -1.438112413793104 ], [ 29.79560405063291, -1.437842931034483 ], [ 29.795334556962025, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14092, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.437842931034483 ], [ 29.79560405063291, -1.438381896551724 ], [ 29.795873544303799, -1.438381896551724 ], [ 29.795873544303799, -1.437842931034483 ], [ 29.79560405063291, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14093, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.437842931034483 ], [ 29.795873544303799, -1.438112413793104 ], [ 29.796143037974684, -1.438112413793104 ], [ 29.796143037974684, -1.437842931034483 ], [ 29.795873544303799, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14094, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.437842931034483 ], [ 29.796143037974684, -1.438112413793104 ], [ 29.796412531645569, -1.438112413793104 ], [ 29.796412531645569, -1.437842931034483 ], [ 29.796143037974684, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14095, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.437842931034483 ], [ 29.796412531645569, -1.438112413793104 ], [ 29.796951518987342, -1.438112413793104 ], [ 29.796951518987342, -1.437842931034483 ], [ 29.796412531645569, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14096, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.437842931034483 ], [ 29.796951518987342, -1.438112413793104 ], [ 29.797490506329115, -1.438112413793104 ], [ 29.797490506329115, -1.437842931034483 ], [ 29.796951518987342, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14097, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.437842931034483 ], [ 29.799646455696202, -1.438112413793104 ], [ 29.800185443037975, -1.438112413793104 ], [ 29.800185443037975, -1.437842931034483 ], [ 29.799646455696202, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14098, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.437842931034483 ], [ 29.800185443037975, -1.438112413793104 ], [ 29.80045493670886, -1.438112413793104 ], [ 29.80045493670886, -1.437842931034483 ], [ 29.800185443037975, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14099, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.437842931034483 ], [ 29.80045493670886, -1.438112413793104 ], [ 29.800724430379748, -1.438112413793104 ], [ 29.800724430379748, -1.437842931034483 ], [ 29.80045493670886, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14100, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.437842931034483 ], [ 29.800724430379748, -1.438112413793104 ], [ 29.800993924050633, -1.438112413793104 ], [ 29.800993924050633, -1.437842931034483 ], [ 29.800724430379748, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14101, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.437842931034483 ], [ 29.800993924050633, -1.438112413793104 ], [ 29.801263417721518, -1.438112413793104 ], [ 29.801263417721518, -1.437842931034483 ], [ 29.800993924050633, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14102, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.437842931034483 ], [ 29.801263417721518, -1.438112413793104 ], [ 29.801532911392407, -1.438112413793104 ], [ 29.801532911392407, -1.437842931034483 ], [ 29.801263417721518, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14103, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.437842931034483 ], [ 29.801532911392407, -1.438112413793104 ], [ 29.802071898734177, -1.438112413793104 ], [ 29.802071898734177, -1.437842931034483 ], [ 29.801532911392407, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14104, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.437842931034483 ], [ 29.802071898734177, -1.438112413793104 ], [ 29.802341392405065, -1.438112413793104 ], [ 29.802341392405065, -1.437842931034483 ], [ 29.802071898734177, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14105, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.437842931034483 ], [ 29.802341392405065, -1.438112413793104 ], [ 29.80261088607595, -1.438112413793104 ], [ 29.80261088607595, -1.437842931034483 ], [ 29.802341392405065, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14106, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.437842931034483 ], [ 29.80261088607595, -1.438112413793104 ], [ 29.802880379746835, -1.438112413793104 ], [ 29.802880379746835, -1.437842931034483 ], [ 29.80261088607595, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14107, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.437842931034483 ], [ 29.802880379746835, -1.438112413793104 ], [ 29.803149873417723, -1.438112413793104 ], [ 29.803149873417723, -1.437842931034483 ], [ 29.802880379746835, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14108, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.437842931034483 ], [ 29.803149873417723, -1.438112413793104 ], [ 29.803419367088608, -1.438112413793104 ], [ 29.803419367088608, -1.437842931034483 ], [ 29.803149873417723, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14109, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.437842931034483 ], [ 29.803419367088608, -1.438112413793104 ], [ 29.803688860759493, -1.438112413793104 ], [ 29.803688860759493, -1.437842931034483 ], [ 29.803419367088608, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14110, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.437842931034483 ], [ 29.803688860759493, -1.438112413793104 ], [ 29.803958354430382, -1.438112413793104 ], [ 29.803958354430382, -1.437842931034483 ], [ 29.803688860759493, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14111, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.437842931034483 ], [ 29.803958354430382, -1.438112413793104 ], [ 29.804497341772151, -1.438112413793104 ], [ 29.804497341772151, -1.437842931034483 ], [ 29.803958354430382, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14112, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.437842931034483 ], [ 29.804497341772151, -1.438112413793104 ], [ 29.80476683544304, -1.438112413793104 ], [ 29.80476683544304, -1.437842931034483 ], [ 29.804497341772151, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14113, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.437842931034483 ], [ 29.80476683544304, -1.438112413793104 ], [ 29.805036329113925, -1.438112413793104 ], [ 29.805036329113925, -1.437842931034483 ], [ 29.80476683544304, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14114, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.437842931034483 ], [ 29.805036329113925, -1.438112413793104 ], [ 29.806383797468357, -1.438112413793104 ], [ 29.806383797468357, -1.437842931034483 ], [ 29.805036329113925, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14115, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.437842931034483 ], [ 29.806383797468357, -1.438112413793104 ], [ 29.806653291139241, -1.438112413793104 ], [ 29.806653291139241, -1.437842931034483 ], [ 29.806383797468357, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14116, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.437842931034483 ], [ 29.806653291139241, -1.438112413793104 ], [ 29.806922784810126, -1.438112413793104 ], [ 29.806922784810126, -1.437842931034483 ], [ 29.806653291139241, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14117, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.437842931034483 ], [ 29.806922784810126, -1.438112413793104 ], [ 29.807192278481015, -1.438112413793104 ], [ 29.807192278481015, -1.437842931034483 ], [ 29.806922784810126, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14118, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.437842931034483 ], [ 29.807192278481015, -1.438112413793104 ], [ 29.8074617721519, -1.438112413793104 ], [ 29.8074617721519, -1.437842931034483 ], [ 29.807192278481015, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14119, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.437842931034483 ], [ 29.8074617721519, -1.438112413793104 ], [ 29.807731265822785, -1.438112413793104 ], [ 29.807731265822785, -1.437842931034483 ], [ 29.8074617721519, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14120, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.437842931034483 ], [ 29.807731265822785, -1.438112413793104 ], [ 29.808000759493673, -1.438112413793104 ], [ 29.808000759493673, -1.437842931034483 ], [ 29.807731265822785, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14121, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.437842931034483 ], [ 29.808000759493673, -1.438112413793104 ], [ 29.808270253164558, -1.438112413793104 ], [ 29.808270253164558, -1.437842931034483 ], [ 29.808000759493673, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14122, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.437842931034483 ], [ 29.808270253164558, -1.438112413793104 ], [ 29.808539746835443, -1.438112413793104 ], [ 29.808539746835443, -1.437842931034483 ], [ 29.808270253164558, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14123, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.437842931034483 ], [ 29.808539746835443, -1.438112413793104 ], [ 29.808809240506328, -1.438112413793104 ], [ 29.808809240506328, -1.437842931034483 ], [ 29.808539746835443, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14124, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.437842931034483 ], [ 29.808809240506328, -1.438112413793104 ], [ 29.809078734177216, -1.438112413793104 ], [ 29.809078734177216, -1.437842931034483 ], [ 29.808809240506328, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14125, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.437842931034483 ], [ 29.809078734177216, -1.438112413793104 ], [ 29.809348227848101, -1.438112413793104 ], [ 29.809348227848101, -1.437842931034483 ], [ 29.809078734177216, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14126, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.437842931034483 ], [ 29.809348227848101, -1.438112413793104 ], [ 29.809617721518986, -1.438112413793104 ], [ 29.809617721518986, -1.437842931034483 ], [ 29.809348227848101, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14127, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.437842931034483 ], [ 29.809617721518986, -1.438112413793104 ], [ 29.809887215189875, -1.438112413793104 ], [ 29.809887215189875, -1.437842931034483 ], [ 29.809617721518986, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14128, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.437842931034483 ], [ 29.809887215189875, -1.438112413793104 ], [ 29.810965189873418, -1.438112413793104 ], [ 29.810965189873418, -1.437842931034483 ], [ 29.809887215189875, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14129, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.437842931034483 ], [ 29.810965189873418, -1.438112413793104 ], [ 29.811234683544303, -1.438112413793104 ], [ 29.811234683544303, -1.437842931034483 ], [ 29.810965189873418, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14130, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.437842931034483 ], [ 29.811234683544303, -1.438112413793104 ], [ 29.811504177215191, -1.438112413793104 ], [ 29.811504177215191, -1.437842931034483 ], [ 29.811234683544303, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14131, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.437842931034483 ], [ 29.811504177215191, -1.438112413793104 ], [ 29.811773670886076, -1.438112413793104 ], [ 29.811773670886076, -1.437842931034483 ], [ 29.811504177215191, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14132, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.438112413793104 ], [ 29.743591772151898, -1.438381896551724 ], [ 29.743861265822783, -1.438381896551724 ], [ 29.743861265822783, -1.438112413793104 ], [ 29.743591772151898, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14133, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.438112413793104 ], [ 29.743861265822783, -1.438381896551724 ], [ 29.744130759493672, -1.438381896551724 ], [ 29.744130759493672, -1.438112413793104 ], [ 29.743861265822783, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14134, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.438112413793104 ], [ 29.744130759493672, -1.438381896551724 ], [ 29.744400253164557, -1.438381896551724 ], [ 29.744400253164557, -1.438112413793104 ], [ 29.744130759493672, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14135, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.438112413793104 ], [ 29.744400253164557, -1.438381896551724 ], [ 29.744669746835442, -1.438381896551724 ], [ 29.744669746835442, -1.438112413793104 ], [ 29.744400253164557, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14136, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.438112413793104 ], [ 29.744669746835442, -1.438381896551724 ], [ 29.744939240506326, -1.438381896551724 ], [ 29.744939240506326, -1.438112413793104 ], [ 29.744669746835442, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14137, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.438112413793104 ], [ 29.744939240506326, -1.438381896551724 ], [ 29.745208734177215, -1.438381896551724 ], [ 29.745208734177215, -1.438112413793104 ], [ 29.744939240506326, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14138, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.438112413793104 ], [ 29.745208734177215, -1.438381896551724 ], [ 29.7454782278481, -1.438381896551724 ], [ 29.7454782278481, -1.438112413793104 ], [ 29.745208734177215, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14139, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.438112413793104 ], [ 29.7454782278481, -1.438381896551724 ], [ 29.745747721518985, -1.438381896551724 ], [ 29.745747721518985, -1.438112413793104 ], [ 29.7454782278481, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14140, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.438112413793104 ], [ 29.746017215189873, -1.438381896551724 ], [ 29.746286708860758, -1.438381896551724 ], [ 29.746286708860758, -1.438112413793104 ], [ 29.746017215189873, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14141, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.438112413793104 ], [ 29.746286708860758, -1.438651379310345 ], [ 29.746556202531643, -1.438651379310345 ], [ 29.746556202531643, -1.438112413793104 ], [ 29.746286708860758, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14142, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.438112413793104 ], [ 29.746556202531643, -1.438651379310345 ], [ 29.746825696202531, -1.438651379310345 ], [ 29.746825696202531, -1.438112413793104 ], [ 29.746556202531643, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14143, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.438112413793104 ], [ 29.746825696202531, -1.438651379310345 ], [ 29.747095189873416, -1.438651379310345 ], [ 29.747095189873416, -1.438112413793104 ], [ 29.746825696202531, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14144, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.438112413793104 ], [ 29.747095189873416, -1.438651379310345 ], [ 29.747364683544301, -1.438651379310345 ], [ 29.747364683544301, -1.438112413793104 ], [ 29.747095189873416, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14145, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.438112413793104 ], [ 29.747364683544301, -1.438651379310345 ], [ 29.747903670886075, -1.438651379310345 ], [ 29.747903670886075, -1.438112413793104 ], [ 29.747364683544301, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14146, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.438112413793104 ], [ 29.747903670886075, -1.438381896551724 ], [ 29.74817316455696, -1.438381896551724 ], [ 29.74817316455696, -1.438112413793104 ], [ 29.747903670886075, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14147, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.438112413793104 ], [ 29.74817316455696, -1.438381896551724 ], [ 29.748442658227848, -1.438381896551724 ], [ 29.748442658227848, -1.438112413793104 ], [ 29.74817316455696, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14148, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.438112413793104 ], [ 29.748442658227848, -1.438381896551724 ], [ 29.748712151898733, -1.438381896551724 ], [ 29.748712151898733, -1.438112413793104 ], [ 29.748442658227848, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14149, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.438112413793104 ], [ 29.748712151898733, -1.438381896551724 ], [ 29.748981645569618, -1.438381896551724 ], [ 29.748981645569618, -1.438112413793104 ], [ 29.748712151898733, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14150, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.438112413793104 ], [ 29.748981645569618, -1.438651379310345 ], [ 29.749251139240506, -1.438651379310345 ], [ 29.749251139240506, -1.438112413793104 ], [ 29.748981645569618, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14151, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.438112413793104 ], [ 29.749251139240506, -1.438381896551724 ], [ 29.749520632911391, -1.438381896551724 ], [ 29.749520632911391, -1.438112413793104 ], [ 29.749251139240506, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14152, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.438112413793104 ], [ 29.749520632911391, -1.438381896551724 ], [ 29.749790126582276, -1.438381896551724 ], [ 29.749790126582276, -1.438112413793104 ], [ 29.749520632911391, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14153, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.438112413793104 ], [ 29.749790126582276, -1.438381896551724 ], [ 29.750059620253165, -1.438381896551724 ], [ 29.750059620253165, -1.438112413793104 ], [ 29.749790126582276, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14154, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.438112413793104 ], [ 29.750059620253165, -1.438381896551724 ], [ 29.750598607594934, -1.438381896551724 ], [ 29.750598607594934, -1.438112413793104 ], [ 29.750059620253165, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14155, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.438112413793104 ], [ 29.750598607594934, -1.438381896551724 ], [ 29.754641012658226, -1.438381896551724 ], [ 29.754641012658226, -1.438112413793104 ], [ 29.750598607594934, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14156, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.438112413793104 ], [ 29.754641012658226, -1.438381896551724 ], [ 29.75518, -1.438381896551724 ], [ 29.75518, -1.438112413793104 ], [ 29.754641012658226, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14157, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.438112413793104 ], [ 29.75518, -1.438381896551724 ], [ 29.755449493670884, -1.438381896551724 ], [ 29.755449493670884, -1.438112413793104 ], [ 29.75518, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14158, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.438112413793104 ], [ 29.762725822784809, -1.438381896551724 ], [ 29.762995316455697, -1.438381896551724 ], [ 29.762995316455697, -1.438112413793104 ], [ 29.762725822784809, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14159, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.438112413793104 ], [ 29.762995316455697, -1.438381896551724 ], [ 29.763264810126582, -1.438381896551724 ], [ 29.763264810126582, -1.438112413793104 ], [ 29.762995316455697, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14160, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.438112413793104 ], [ 29.763264810126582, -1.438381896551724 ], [ 29.763534303797467, -1.438381896551724 ], [ 29.763534303797467, -1.438112413793104 ], [ 29.763264810126582, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14161, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.438112413793104 ], [ 29.763534303797467, -1.438651379310345 ], [ 29.763803797468356, -1.438651379310345 ], [ 29.763803797468356, -1.438112413793104 ], [ 29.763534303797467, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14162, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.438112413793104 ], [ 29.763803797468356, -1.438381896551724 ], [ 29.76407329113924, -1.438381896551724 ], [ 29.76407329113924, -1.438112413793104 ], [ 29.763803797468356, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14163, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.438112413793104 ], [ 29.76407329113924, -1.438381896551724 ], [ 29.764342784810125, -1.438381896551724 ], [ 29.764342784810125, -1.438112413793104 ], [ 29.76407329113924, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14164, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.438112413793104 ], [ 29.764881772151899, -1.438651379310345 ], [ 29.765151265822784, -1.438651379310345 ], [ 29.765151265822784, -1.438112413793104 ], [ 29.764881772151899, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14165, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.438112413793104 ], [ 29.772427594936708, -1.439190344827586 ], [ 29.772697088607593, -1.439190344827586 ], [ 29.772697088607593, -1.438112413793104 ], [ 29.772427594936708, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14166, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.438112413793104 ], [ 29.782668354430381, -1.439459827586207 ], [ 29.782937848101266, -1.439459827586207 ], [ 29.782937848101266, -1.438112413793104 ], [ 29.782668354430381, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14167, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.438112413793104 ], [ 29.784285316455698, -1.439459827586207 ], [ 29.784554810126583, -1.439459827586207 ], [ 29.784554810126583, -1.438112413793104 ], [ 29.784285316455698, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14168, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.438112413793104 ], [ 29.785093797468356, -1.439459827586207 ], [ 29.785363291139241, -1.439459827586207 ], [ 29.785363291139241, -1.438112413793104 ], [ 29.785093797468356, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14169, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.438112413793104 ], [ 29.785363291139241, -1.439459827586207 ], [ 29.785632784810126, -1.439459827586207 ], [ 29.785632784810126, -1.438112413793104 ], [ 29.785363291139241, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14170, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.438112413793104 ], [ 29.785632784810126, -1.439459827586207 ], [ 29.785902278481014, -1.439459827586207 ], [ 29.785902278481014, -1.438112413793104 ], [ 29.785632784810126, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14171, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.438112413793104 ], [ 29.789675189873417, -1.438651379310345 ], [ 29.789944683544302, -1.438651379310345 ], [ 29.789944683544302, -1.438112413793104 ], [ 29.789675189873417, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14172, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.438112413793104 ], [ 29.789944683544302, -1.438651379310345 ], [ 29.790214177215191, -1.438651379310345 ], [ 29.790214177215191, -1.438112413793104 ], [ 29.789944683544302, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14173, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.438112413793104 ], [ 29.790214177215191, -1.438651379310345 ], [ 29.790483670886076, -1.438651379310345 ], [ 29.790483670886076, -1.438112413793104 ], [ 29.790214177215191, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14174, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.438112413793104 ], [ 29.791022658227849, -1.438651379310345 ], [ 29.791292151898734, -1.438651379310345 ], [ 29.791292151898734, -1.438112413793104 ], [ 29.791022658227849, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14175, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.438112413793104 ], [ 29.792370126582277, -1.438651379310345 ], [ 29.792909113924051, -1.438651379310345 ], [ 29.792909113924051, -1.438112413793104 ], [ 29.792370126582277, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14176, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.438112413793104 ], [ 29.794526075949367, -1.438381896551724 ], [ 29.794795569620252, -1.438381896551724 ], [ 29.794795569620252, -1.438112413793104 ], [ 29.794526075949367, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14177, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.438112413793104 ], [ 29.794795569620252, -1.438381896551724 ], [ 29.79506506329114, -1.438381896551724 ], [ 29.79506506329114, -1.438112413793104 ], [ 29.794795569620252, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14178, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.438112413793104 ], [ 29.79506506329114, -1.438381896551724 ], [ 29.795334556962025, -1.438381896551724 ], [ 29.795334556962025, -1.438112413793104 ], [ 29.79506506329114, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14179, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.438112413793104 ], [ 29.795334556962025, -1.438381896551724 ], [ 29.79560405063291, -1.438381896551724 ], [ 29.79560405063291, -1.438112413793104 ], [ 29.795334556962025, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14180, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.438112413793104 ], [ 29.795873544303799, -1.438381896551724 ], [ 29.796143037974684, -1.438381896551724 ], [ 29.796143037974684, -1.438112413793104 ], [ 29.795873544303799, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14181, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.438112413793104 ], [ 29.796143037974684, -1.438381896551724 ], [ 29.796412531645569, -1.438381896551724 ], [ 29.796412531645569, -1.438112413793104 ], [ 29.796143037974684, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14182, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.438112413793104 ], [ 29.796412531645569, -1.438381896551724 ], [ 29.796682025316457, -1.438381896551724 ], [ 29.796682025316457, -1.438112413793104 ], [ 29.796412531645569, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14183, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.438112413793104 ], [ 29.796682025316457, -1.438381896551724 ], [ 29.796951518987342, -1.438381896551724 ], [ 29.796951518987342, -1.438112413793104 ], [ 29.796682025316457, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14184, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.438112413793104 ], [ 29.796951518987342, -1.438381896551724 ], [ 29.797221012658227, -1.438381896551724 ], [ 29.797221012658227, -1.438112413793104 ], [ 29.796951518987342, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14185, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.438112413793104 ], [ 29.797221012658227, -1.438381896551724 ], [ 29.79776, -1.438381896551724 ], [ 29.79776, -1.438112413793104 ], [ 29.797221012658227, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14186, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.438112413793104 ], [ 29.79776, -1.438381896551724 ], [ 29.799376962025317, -1.438381896551724 ], [ 29.799376962025317, -1.438112413793104 ], [ 29.79776, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14187, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.438112413793104 ], [ 29.799376962025317, -1.438381896551724 ], [ 29.79991594936709, -1.438381896551724 ], [ 29.79991594936709, -1.438112413793104 ], [ 29.799376962025317, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14188, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.438112413793104 ], [ 29.79991594936709, -1.438381896551724 ], [ 29.800185443037975, -1.438381896551724 ], [ 29.800185443037975, -1.438112413793104 ], [ 29.79991594936709, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14189, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.438112413793104 ], [ 29.800185443037975, -1.438381896551724 ], [ 29.80045493670886, -1.438381896551724 ], [ 29.80045493670886, -1.438112413793104 ], [ 29.800185443037975, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14190, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.438112413793104 ], [ 29.80045493670886, -1.438381896551724 ], [ 29.800724430379748, -1.438381896551724 ], [ 29.800724430379748, -1.438112413793104 ], [ 29.80045493670886, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14191, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.438112413793104 ], [ 29.800724430379748, -1.438381896551724 ], [ 29.800993924050633, -1.438381896551724 ], [ 29.800993924050633, -1.438112413793104 ], [ 29.800724430379748, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14192, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.438112413793104 ], [ 29.800993924050633, -1.438381896551724 ], [ 29.801263417721518, -1.438381896551724 ], [ 29.801263417721518, -1.438112413793104 ], [ 29.800993924050633, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14193, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.438112413793104 ], [ 29.801263417721518, -1.438381896551724 ], [ 29.801532911392407, -1.438381896551724 ], [ 29.801532911392407, -1.438112413793104 ], [ 29.801263417721518, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14194, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.438112413793104 ], [ 29.801532911392407, -1.438381896551724 ], [ 29.802071898734177, -1.438381896551724 ], [ 29.802071898734177, -1.438112413793104 ], [ 29.801532911392407, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14195, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.438112413793104 ], [ 29.802071898734177, -1.438381896551724 ], [ 29.802341392405065, -1.438381896551724 ], [ 29.802341392405065, -1.438112413793104 ], [ 29.802071898734177, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14196, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.438112413793104 ], [ 29.802341392405065, -1.438381896551724 ], [ 29.80261088607595, -1.438381896551724 ], [ 29.80261088607595, -1.438112413793104 ], [ 29.802341392405065, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14197, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.438112413793104 ], [ 29.80261088607595, -1.438381896551724 ], [ 29.802880379746835, -1.438381896551724 ], [ 29.802880379746835, -1.438112413793104 ], [ 29.80261088607595, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14198, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.438112413793104 ], [ 29.802880379746835, -1.438381896551724 ], [ 29.803149873417723, -1.438381896551724 ], [ 29.803149873417723, -1.438112413793104 ], [ 29.802880379746835, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14199, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.438112413793104 ], [ 29.803149873417723, -1.438381896551724 ], [ 29.803419367088608, -1.438381896551724 ], [ 29.803419367088608, -1.438112413793104 ], [ 29.803149873417723, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14200, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.438112413793104 ], [ 29.803419367088608, -1.438381896551724 ], [ 29.803688860759493, -1.438381896551724 ], [ 29.803688860759493, -1.438112413793104 ], [ 29.803419367088608, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14201, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.438112413793104 ], [ 29.803688860759493, -1.438381896551724 ], [ 29.803958354430382, -1.438381896551724 ], [ 29.803958354430382, -1.438112413793104 ], [ 29.803688860759493, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14202, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.438112413793104 ], [ 29.803958354430382, -1.438381896551724 ], [ 29.804227848101267, -1.438381896551724 ], [ 29.804227848101267, -1.438112413793104 ], [ 29.803958354430382, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14203, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.438112413793104 ], [ 29.804227848101267, -1.438381896551724 ], [ 29.804497341772151, -1.438381896551724 ], [ 29.804497341772151, -1.438112413793104 ], [ 29.804227848101267, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14204, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.438112413793104 ], [ 29.804497341772151, -1.438381896551724 ], [ 29.80476683544304, -1.438381896551724 ], [ 29.80476683544304, -1.438112413793104 ], [ 29.804497341772151, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14205, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.438112413793104 ], [ 29.80476683544304, -1.438381896551724 ], [ 29.80530582278481, -1.438381896551724 ], [ 29.80530582278481, -1.438112413793104 ], [ 29.80476683544304, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14206, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.438112413793104 ], [ 29.80530582278481, -1.438381896551724 ], [ 29.806114303797468, -1.438381896551724 ], [ 29.806114303797468, -1.438112413793104 ], [ 29.80530582278481, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14207, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.438112413793104 ], [ 29.806114303797468, -1.438381896551724 ], [ 29.806653291139241, -1.438381896551724 ], [ 29.806653291139241, -1.438112413793104 ], [ 29.806114303797468, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14208, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.438112413793104 ], [ 29.806653291139241, -1.438381896551724 ], [ 29.806922784810126, -1.438381896551724 ], [ 29.806922784810126, -1.438112413793104 ], [ 29.806653291139241, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14209, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.438112413793104 ], [ 29.806922784810126, -1.438381896551724 ], [ 29.807192278481015, -1.438381896551724 ], [ 29.807192278481015, -1.438112413793104 ], [ 29.806922784810126, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14210, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.438112413793104 ], [ 29.807192278481015, -1.438381896551724 ], [ 29.807731265822785, -1.438381896551724 ], [ 29.807731265822785, -1.438112413793104 ], [ 29.807192278481015, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14211, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.438112413793104 ], [ 29.807731265822785, -1.438381896551724 ], [ 29.808000759493673, -1.438381896551724 ], [ 29.808000759493673, -1.438112413793104 ], [ 29.807731265822785, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14212, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.438112413793104 ], [ 29.808000759493673, -1.438381896551724 ], [ 29.808270253164558, -1.438381896551724 ], [ 29.808270253164558, -1.438112413793104 ], [ 29.808000759493673, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14213, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.438112413793104 ], [ 29.808270253164558, -1.438381896551724 ], [ 29.808539746835443, -1.438381896551724 ], [ 29.808539746835443, -1.438112413793104 ], [ 29.808270253164558, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14214, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.438112413793104 ], [ 29.808539746835443, -1.438381896551724 ], [ 29.808809240506328, -1.438381896551724 ], [ 29.808809240506328, -1.438112413793104 ], [ 29.808539746835443, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14215, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.438112413793104 ], [ 29.808809240506328, -1.438381896551724 ], [ 29.809078734177216, -1.438381896551724 ], [ 29.809078734177216, -1.438112413793104 ], [ 29.808809240506328, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14216, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.438112413793104 ], [ 29.809078734177216, -1.438381896551724 ], [ 29.809348227848101, -1.438381896551724 ], [ 29.809348227848101, -1.438112413793104 ], [ 29.809078734177216, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14217, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.438112413793104 ], [ 29.809348227848101, -1.438381896551724 ], [ 29.809617721518986, -1.438381896551724 ], [ 29.809617721518986, -1.438112413793104 ], [ 29.809348227848101, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14218, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.438112413793104 ], [ 29.809617721518986, -1.438381896551724 ], [ 29.810426202531644, -1.438381896551724 ], [ 29.810426202531644, -1.438112413793104 ], [ 29.809617721518986, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14219, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.438112413793104 ], [ 29.810426202531644, -1.438381896551724 ], [ 29.810965189873418, -1.438381896551724 ], [ 29.810965189873418, -1.438112413793104 ], [ 29.810426202531644, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14220, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.438112413793104 ], [ 29.810965189873418, -1.438381896551724 ], [ 29.811234683544303, -1.438381896551724 ], [ 29.811234683544303, -1.438112413793104 ], [ 29.810965189873418, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14221, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.438112413793104 ], [ 29.811234683544303, -1.438381896551724 ], [ 29.811504177215191, -1.438381896551724 ], [ 29.811504177215191, -1.438112413793104 ], [ 29.811234683544303, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14222, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.438112413793104 ], [ 29.811504177215191, -1.438381896551724 ], [ 29.811773670886076, -1.438381896551724 ], [ 29.811773670886076, -1.438112413793104 ], [ 29.811504177215191, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14223, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.438112413793104 ], [ 29.811773670886076, -1.438920862068966 ], [ 29.812043164556961, -1.438920862068966 ], [ 29.812043164556961, -1.438112413793104 ], [ 29.811773670886076, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14224, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743591772151898, -1.438381896551724 ], [ 29.743591772151898, -1.438920862068966 ], [ 29.743861265822783, -1.438920862068966 ], [ 29.743861265822783, -1.438381896551724 ], [ 29.743591772151898, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14225, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.438381896551724 ], [ 29.743861265822783, -1.438651379310345 ], [ 29.744130759493672, -1.438651379310345 ], [ 29.744130759493672, -1.438381896551724 ], [ 29.743861265822783, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14226, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.438381896551724 ], [ 29.744130759493672, -1.438651379310345 ], [ 29.744400253164557, -1.438651379310345 ], [ 29.744400253164557, -1.438381896551724 ], [ 29.744130759493672, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14227, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.438381896551724 ], [ 29.744400253164557, -1.438651379310345 ], [ 29.744669746835442, -1.438651379310345 ], [ 29.744669746835442, -1.438381896551724 ], [ 29.744400253164557, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14228, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.438381896551724 ], [ 29.744669746835442, -1.438651379310345 ], [ 29.744939240506326, -1.438651379310345 ], [ 29.744939240506326, -1.438381896551724 ], [ 29.744669746835442, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14229, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.438381896551724 ], [ 29.744939240506326, -1.438651379310345 ], [ 29.745208734177215, -1.438651379310345 ], [ 29.745208734177215, -1.438381896551724 ], [ 29.744939240506326, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14230, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.438381896551724 ], [ 29.745208734177215, -1.438920862068966 ], [ 29.7454782278481, -1.438920862068966 ], [ 29.7454782278481, -1.438381896551724 ], [ 29.745208734177215, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14231, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.438381896551724 ], [ 29.7454782278481, -1.438651379310345 ], [ 29.745747721518985, -1.438651379310345 ], [ 29.745747721518985, -1.438381896551724 ], [ 29.7454782278481, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14232, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.438381896551724 ], [ 29.745747721518985, -1.438651379310345 ], [ 29.746017215189873, -1.438651379310345 ], [ 29.746017215189873, -1.438381896551724 ], [ 29.745747721518985, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14233, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.438381896551724 ], [ 29.746017215189873, -1.438651379310345 ], [ 29.746286708860758, -1.438651379310345 ], [ 29.746286708860758, -1.438381896551724 ], [ 29.746017215189873, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14234, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.438381896551724 ], [ 29.747903670886075, -1.438651379310345 ], [ 29.74817316455696, -1.438651379310345 ], [ 29.74817316455696, -1.438381896551724 ], [ 29.747903670886075, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14235, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.438381896551724 ], [ 29.74817316455696, -1.438651379310345 ], [ 29.748442658227848, -1.438651379310345 ], [ 29.748442658227848, -1.438381896551724 ], [ 29.74817316455696, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14236, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.438381896551724 ], [ 29.748442658227848, -1.438651379310345 ], [ 29.748712151898733, -1.438651379310345 ], [ 29.748712151898733, -1.438381896551724 ], [ 29.748442658227848, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14237, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.438381896551724 ], [ 29.748712151898733, -1.438651379310345 ], [ 29.748981645569618, -1.438651379310345 ], [ 29.748981645569618, -1.438381896551724 ], [ 29.748712151898733, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14238, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.438381896551724 ], [ 29.749251139240506, -1.438651379310345 ], [ 29.749520632911391, -1.438651379310345 ], [ 29.749520632911391, -1.438381896551724 ], [ 29.749251139240506, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14239, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.438381896551724 ], [ 29.749520632911391, -1.438651379310345 ], [ 29.749790126582276, -1.438651379310345 ], [ 29.749790126582276, -1.438381896551724 ], [ 29.749520632911391, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14240, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.438381896551724 ], [ 29.749790126582276, -1.438651379310345 ], [ 29.750059620253165, -1.438651379310345 ], [ 29.750059620253165, -1.438381896551724 ], [ 29.749790126582276, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14241, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.438381896551724 ], [ 29.750059620253165, -1.438651379310345 ], [ 29.75032911392405, -1.438651379310345 ], [ 29.75032911392405, -1.438381896551724 ], [ 29.750059620253165, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14242, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.438381896551724 ], [ 29.75032911392405, -1.438651379310345 ], [ 29.750598607594934, -1.438651379310345 ], [ 29.750598607594934, -1.438381896551724 ], [ 29.75032911392405, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14243, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.438381896551724 ], [ 29.750598607594934, -1.438651379310345 ], [ 29.754641012658226, -1.438651379310345 ], [ 29.754641012658226, -1.438381896551724 ], [ 29.750598607594934, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14244, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.438381896551724 ], [ 29.754641012658226, -1.438651379310345 ], [ 29.754910506329114, -1.438651379310345 ], [ 29.754910506329114, -1.438381896551724 ], [ 29.754641012658226, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14245, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.438381896551724 ], [ 29.762186835443039, -1.438651379310345 ], [ 29.762456329113924, -1.438651379310345 ], [ 29.762456329113924, -1.438381896551724 ], [ 29.762186835443039, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14246, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.438381896551724 ], [ 29.762456329113924, -1.438651379310345 ], [ 29.762725822784809, -1.438651379310345 ], [ 29.762725822784809, -1.438381896551724 ], [ 29.762456329113924, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14247, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.438381896551724 ], [ 29.762725822784809, -1.438651379310345 ], [ 29.762995316455697, -1.438651379310345 ], [ 29.762995316455697, -1.438381896551724 ], [ 29.762725822784809, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14248, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.438381896551724 ], [ 29.771888607594935, -1.439459827586207 ], [ 29.772158101265823, -1.439459827586207 ], [ 29.772158101265823, -1.438381896551724 ], [ 29.771888607594935, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14249, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.438381896551724 ], [ 29.773236075949367, -1.438651379310345 ], [ 29.773505569620252, -1.438651379310345 ], [ 29.773505569620252, -1.438381896551724 ], [ 29.773236075949367, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14250, "El": 142 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.438381896551724 ], [ 29.773505569620252, -1.438651379310345 ], [ 29.77377506329114, -1.438651379310345 ], [ 29.77377506329114, -1.438381896551724 ], [ 29.773505569620252, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14251, "El": 144 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.438381896551724 ], [ 29.77377506329114, -1.438651379310345 ], [ 29.774044556962025, -1.438651379310345 ], [ 29.774044556962025, -1.438381896551724 ], [ 29.77377506329114, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14252, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.438381896551724 ], [ 29.794526075949367, -1.438651379310345 ], [ 29.794795569620252, -1.438651379310345 ], [ 29.794795569620252, -1.438381896551724 ], [ 29.794526075949367, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14253, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.438381896551724 ], [ 29.794795569620252, -1.438651379310345 ], [ 29.79506506329114, -1.438651379310345 ], [ 29.79506506329114, -1.438381896551724 ], [ 29.794795569620252, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14254, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.438381896551724 ], [ 29.79506506329114, -1.438651379310345 ], [ 29.795334556962025, -1.438651379310345 ], [ 29.795334556962025, -1.438381896551724 ], [ 29.79506506329114, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14255, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.438381896551724 ], [ 29.795334556962025, -1.438651379310345 ], [ 29.79560405063291, -1.438651379310345 ], [ 29.79560405063291, -1.438381896551724 ], [ 29.795334556962025, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14256, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.438381896551724 ], [ 29.79560405063291, -1.438651379310345 ], [ 29.795873544303799, -1.438651379310345 ], [ 29.795873544303799, -1.438381896551724 ], [ 29.79560405063291, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14257, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.438381896551724 ], [ 29.795873544303799, -1.438651379310345 ], [ 29.796143037974684, -1.438651379310345 ], [ 29.796143037974684, -1.438381896551724 ], [ 29.795873544303799, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14258, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.438381896551724 ], [ 29.796143037974684, -1.438651379310345 ], [ 29.796412531645569, -1.438651379310345 ], [ 29.796412531645569, -1.438381896551724 ], [ 29.796143037974684, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14259, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.438381896551724 ], [ 29.796412531645569, -1.438651379310345 ], [ 29.796682025316457, -1.438651379310345 ], [ 29.796682025316457, -1.438381896551724 ], [ 29.796412531645569, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14260, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.438381896551724 ], [ 29.796682025316457, -1.438651379310345 ], [ 29.796951518987342, -1.438651379310345 ], [ 29.796951518987342, -1.438381896551724 ], [ 29.796682025316457, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14261, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.438381896551724 ], [ 29.796951518987342, -1.438651379310345 ], [ 29.797221012658227, -1.438651379310345 ], [ 29.797221012658227, -1.438381896551724 ], [ 29.796951518987342, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14262, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.438381896551724 ], [ 29.797221012658227, -1.438651379310345 ], [ 29.797490506329115, -1.438651379310345 ], [ 29.797490506329115, -1.438381896551724 ], [ 29.797221012658227, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14263, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.438381896551724 ], [ 29.797490506329115, -1.438651379310345 ], [ 29.799646455696202, -1.438651379310345 ], [ 29.799646455696202, -1.438381896551724 ], [ 29.797490506329115, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14264, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.438381896551724 ], [ 29.799646455696202, -1.438651379310345 ], [ 29.79991594936709, -1.438651379310345 ], [ 29.79991594936709, -1.438381896551724 ], [ 29.799646455696202, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14265, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.438381896551724 ], [ 29.79991594936709, -1.438651379310345 ], [ 29.800185443037975, -1.438651379310345 ], [ 29.800185443037975, -1.438381896551724 ], [ 29.79991594936709, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14266, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.438381896551724 ], [ 29.800185443037975, -1.438651379310345 ], [ 29.80045493670886, -1.438651379310345 ], [ 29.80045493670886, -1.438381896551724 ], [ 29.800185443037975, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14267, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.438381896551724 ], [ 29.80045493670886, -1.438651379310345 ], [ 29.800724430379748, -1.438651379310345 ], [ 29.800724430379748, -1.438381896551724 ], [ 29.80045493670886, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14268, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.438381896551724 ], [ 29.800724430379748, -1.438651379310345 ], [ 29.800993924050633, -1.438651379310345 ], [ 29.800993924050633, -1.438381896551724 ], [ 29.800724430379748, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14269, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.438381896551724 ], [ 29.800993924050633, -1.438920862068966 ], [ 29.801263417721518, -1.438920862068966 ], [ 29.801263417721518, -1.439190344827586 ], [ 29.801532911392407, -1.439190344827586 ], [ 29.801532911392407, -1.439459827586207 ], [ 29.802071898734177, -1.439459827586207 ], [ 29.802071898734177, -1.438920862068966 ], [ 29.801532911392407, -1.438920862068966 ], [ 29.801532911392407, -1.438651379310345 ], [ 29.801263417721518, -1.438651379310345 ], [ 29.801263417721518, -1.438381896551724 ], [ 29.800993924050633, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14270, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.438381896551724 ], [ 29.801263417721518, -1.438651379310345 ], [ 29.801532911392407, -1.438651379310345 ], [ 29.801532911392407, -1.438381896551724 ], [ 29.801263417721518, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14271, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.438381896551724 ], [ 29.801532911392407, -1.438651379310345 ], [ 29.802071898734177, -1.438651379310345 ], [ 29.802071898734177, -1.438381896551724 ], [ 29.801532911392407, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14272, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.438381896551724 ], [ 29.802071898734177, -1.438651379310345 ], [ 29.802341392405065, -1.438651379310345 ], [ 29.802341392405065, -1.438381896551724 ], [ 29.802071898734177, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14273, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.438381896551724 ], [ 29.802341392405065, -1.438651379310345 ], [ 29.80261088607595, -1.438651379310345 ], [ 29.80261088607595, -1.438381896551724 ], [ 29.802341392405065, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14274, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.438381896551724 ], [ 29.80261088607595, -1.438651379310345 ], [ 29.802880379746835, -1.438651379310345 ], [ 29.802880379746835, -1.438381896551724 ], [ 29.80261088607595, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14275, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.438381896551724 ], [ 29.802880379746835, -1.438651379310345 ], [ 29.803149873417723, -1.438651379310345 ], [ 29.803149873417723, -1.438381896551724 ], [ 29.802880379746835, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14276, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.438381896551724 ], [ 29.803149873417723, -1.438651379310345 ], [ 29.803419367088608, -1.438651379310345 ], [ 29.803419367088608, -1.438381896551724 ], [ 29.803149873417723, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14277, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.438381896551724 ], [ 29.803419367088608, -1.438651379310345 ], [ 29.803688860759493, -1.438651379310345 ], [ 29.803688860759493, -1.438381896551724 ], [ 29.803419367088608, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14278, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.438381896551724 ], [ 29.803688860759493, -1.438651379310345 ], [ 29.803958354430382, -1.438651379310345 ], [ 29.803958354430382, -1.438381896551724 ], [ 29.803688860759493, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14279, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.438381896551724 ], [ 29.803958354430382, -1.438651379310345 ], [ 29.804227848101267, -1.438651379310345 ], [ 29.804227848101267, -1.438381896551724 ], [ 29.803958354430382, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14280, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.438381896551724 ], [ 29.804227848101267, -1.438651379310345 ], [ 29.804497341772151, -1.438651379310345 ], [ 29.804497341772151, -1.438381896551724 ], [ 29.804227848101267, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14281, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.438381896551724 ], [ 29.804497341772151, -1.438651379310345 ], [ 29.80476683544304, -1.438651379310345 ], [ 29.80476683544304, -1.438381896551724 ], [ 29.804497341772151, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14282, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.438381896551724 ], [ 29.80476683544304, -1.438651379310345 ], [ 29.805036329113925, -1.438651379310345 ], [ 29.805036329113925, -1.438381896551724 ], [ 29.80476683544304, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14283, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.438381896551724 ], [ 29.805036329113925, -1.438651379310345 ], [ 29.806383797468357, -1.438651379310345 ], [ 29.806383797468357, -1.438381896551724 ], [ 29.805036329113925, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14284, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.438381896551724 ], [ 29.806383797468357, -1.438651379310345 ], [ 29.806653291139241, -1.438651379310345 ], [ 29.806653291139241, -1.438381896551724 ], [ 29.806383797468357, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14285, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.438381896551724 ], [ 29.806653291139241, -1.438651379310345 ], [ 29.806922784810126, -1.438651379310345 ], [ 29.806922784810126, -1.438381896551724 ], [ 29.806653291139241, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14286, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.438381896551724 ], [ 29.806922784810126, -1.438651379310345 ], [ 29.807192278481015, -1.438651379310345 ], [ 29.807192278481015, -1.438381896551724 ], [ 29.806922784810126, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14287, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.438381896551724 ], [ 29.807192278481015, -1.438651379310345 ], [ 29.8074617721519, -1.438651379310345 ], [ 29.8074617721519, -1.438381896551724 ], [ 29.807192278481015, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14288, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.438381896551724 ], [ 29.8074617721519, -1.438651379310345 ], [ 29.807731265822785, -1.438651379310345 ], [ 29.807731265822785, -1.438381896551724 ], [ 29.8074617721519, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14289, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.438381896551724 ], [ 29.807731265822785, -1.438651379310345 ], [ 29.808000759493673, -1.438651379310345 ], [ 29.808000759493673, -1.438381896551724 ], [ 29.807731265822785, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14290, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.438381896551724 ], [ 29.808000759493673, -1.438651379310345 ], [ 29.808270253164558, -1.438651379310345 ], [ 29.808270253164558, -1.438381896551724 ], [ 29.808000759493673, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14291, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.438381896551724 ], [ 29.808270253164558, -1.438651379310345 ], [ 29.808539746835443, -1.438651379310345 ], [ 29.808539746835443, -1.438381896551724 ], [ 29.808270253164558, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14292, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.438381896551724 ], [ 29.808539746835443, -1.438651379310345 ], [ 29.808809240506328, -1.438651379310345 ], [ 29.808809240506328, -1.438381896551724 ], [ 29.808539746835443, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14293, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.438381896551724 ], [ 29.808809240506328, -1.438651379310345 ], [ 29.809078734177216, -1.438651379310345 ], [ 29.809078734177216, -1.438381896551724 ], [ 29.808809240506328, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14294, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.438381896551724 ], [ 29.809078734177216, -1.438651379310345 ], [ 29.809348227848101, -1.438651379310345 ], [ 29.809348227848101, -1.438381896551724 ], [ 29.809078734177216, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14295, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.438381896551724 ], [ 29.809348227848101, -1.438651379310345 ], [ 29.809617721518986, -1.438651379310345 ], [ 29.809617721518986, -1.438381896551724 ], [ 29.809348227848101, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14296, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.438381896551724 ], [ 29.809617721518986, -1.438651379310345 ], [ 29.81015670886076, -1.438651379310345 ], [ 29.81015670886076, -1.439190344827586 ], [ 29.810426202531644, -1.439190344827586 ], [ 29.810426202531644, -1.438381896551724 ], [ 29.809617721518986, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14297, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.438381896551724 ], [ 29.810426202531644, -1.439190344827586 ], [ 29.810965189873418, -1.439190344827586 ], [ 29.810965189873418, -1.438381896551724 ], [ 29.810426202531644, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14298, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.438381896551724 ], [ 29.810965189873418, -1.438651379310345 ], [ 29.811234683544303, -1.438651379310345 ], [ 29.811234683544303, -1.438381896551724 ], [ 29.810965189873418, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14299, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.438381896551724 ], [ 29.811234683544303, -1.438651379310345 ], [ 29.811504177215191, -1.438651379310345 ], [ 29.811504177215191, -1.438381896551724 ], [ 29.811234683544303, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14300, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.438381896551724 ], [ 29.811504177215191, -1.438651379310345 ], [ 29.811773670886076, -1.438651379310345 ], [ 29.811773670886076, -1.438381896551724 ], [ 29.811504177215191, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14301, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.438651379310345 ], [ 29.743861265822783, -1.438920862068966 ], [ 29.744130759493672, -1.438920862068966 ], [ 29.744130759493672, -1.438651379310345 ], [ 29.743861265822783, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14302, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.438651379310345 ], [ 29.744130759493672, -1.438920862068966 ], [ 29.744400253164557, -1.438920862068966 ], [ 29.744400253164557, -1.438651379310345 ], [ 29.744130759493672, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14303, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.438651379310345 ], [ 29.744400253164557, -1.438920862068966 ], [ 29.744669746835442, -1.438920862068966 ], [ 29.744669746835442, -1.438651379310345 ], [ 29.744400253164557, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14304, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.438651379310345 ], [ 29.744669746835442, -1.439190344827586 ], [ 29.744939240506326, -1.439190344827586 ], [ 29.744939240506326, -1.438651379310345 ], [ 29.744669746835442, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14305, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.438651379310345 ], [ 29.744939240506326, -1.439190344827586 ], [ 29.745208734177215, -1.439190344827586 ], [ 29.745208734177215, -1.438651379310345 ], [ 29.744939240506326, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14306, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.438651379310345 ], [ 29.7454782278481, -1.438920862068966 ], [ 29.745747721518985, -1.438920862068966 ], [ 29.745747721518985, -1.438651379310345 ], [ 29.7454782278481, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14307, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.438651379310345 ], [ 29.745747721518985, -1.438920862068966 ], [ 29.746017215189873, -1.438920862068966 ], [ 29.746017215189873, -1.438651379310345 ], [ 29.745747721518985, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14308, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.438651379310345 ], [ 29.746017215189873, -1.438920862068966 ], [ 29.746286708860758, -1.438920862068966 ], [ 29.746286708860758, -1.438651379310345 ], [ 29.746017215189873, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14309, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.438651379310345 ], [ 29.746286708860758, -1.438920862068966 ], [ 29.746556202531643, -1.438920862068966 ], [ 29.746556202531643, -1.438651379310345 ], [ 29.746286708860758, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14310, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.438651379310345 ], [ 29.746556202531643, -1.438920862068966 ], [ 29.746825696202531, -1.438920862068966 ], [ 29.746825696202531, -1.438651379310345 ], [ 29.746556202531643, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14311, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.438651379310345 ], [ 29.746825696202531, -1.438920862068966 ], [ 29.747095189873416, -1.438920862068966 ], [ 29.747095189873416, -1.438651379310345 ], [ 29.746825696202531, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14312, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.438651379310345 ], [ 29.747095189873416, -1.438920862068966 ], [ 29.747364683544301, -1.438920862068966 ], [ 29.747364683544301, -1.438651379310345 ], [ 29.747095189873416, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14313, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.438651379310345 ], [ 29.747364683544301, -1.438920862068966 ], [ 29.747903670886075, -1.438920862068966 ], [ 29.747903670886075, -1.438651379310345 ], [ 29.747364683544301, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14314, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.438651379310345 ], [ 29.747903670886075, -1.438920862068966 ], [ 29.74817316455696, -1.438920862068966 ], [ 29.74817316455696, -1.438651379310345 ], [ 29.747903670886075, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14315, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.438651379310345 ], [ 29.74817316455696, -1.438920862068966 ], [ 29.748442658227848, -1.438920862068966 ], [ 29.748442658227848, -1.438651379310345 ], [ 29.74817316455696, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14316, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.438651379310345 ], [ 29.748442658227848, -1.438920862068966 ], [ 29.748712151898733, -1.438920862068966 ], [ 29.748712151898733, -1.438651379310345 ], [ 29.748442658227848, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14317, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.438651379310345 ], [ 29.748712151898733, -1.438920862068966 ], [ 29.748981645569618, -1.438920862068966 ], [ 29.748981645569618, -1.438651379310345 ], [ 29.748712151898733, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14318, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.438651379310345 ], [ 29.748981645569618, -1.438920862068966 ], [ 29.749251139240506, -1.438920862068966 ], [ 29.749251139240506, -1.438651379310345 ], [ 29.748981645569618, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14319, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.438651379310345 ], [ 29.749251139240506, -1.438920862068966 ], [ 29.749520632911391, -1.438920862068966 ], [ 29.749520632911391, -1.438651379310345 ], [ 29.749251139240506, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14320, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.438651379310345 ], [ 29.749520632911391, -1.438920862068966 ], [ 29.749790126582276, -1.438920862068966 ], [ 29.749790126582276, -1.438651379310345 ], [ 29.749520632911391, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14321, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.438651379310345 ], [ 29.749790126582276, -1.438920862068966 ], [ 29.750059620253165, -1.438920862068966 ], [ 29.750059620253165, -1.438651379310345 ], [ 29.749790126582276, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14322, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.438651379310345 ], [ 29.750059620253165, -1.438920862068966 ], [ 29.75032911392405, -1.438920862068966 ], [ 29.75032911392405, -1.438651379310345 ], [ 29.750059620253165, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14323, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.438651379310345 ], [ 29.75032911392405, -1.438920862068966 ], [ 29.750868101265823, -1.438920862068966 ], [ 29.750868101265823, -1.438651379310345 ], [ 29.75032911392405, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14324, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.438651379310345 ], [ 29.750868101265823, -1.438920862068966 ], [ 29.754371518987341, -1.438920862068966 ], [ 29.754371518987341, -1.438651379310345 ], [ 29.750868101265823, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14325, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.438651379310345 ], [ 29.754371518987341, -1.438920862068966 ], [ 29.754641012658226, -1.438920862068966 ], [ 29.754641012658226, -1.438651379310345 ], [ 29.754371518987341, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14326, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.438651379310345 ], [ 29.762186835443039, -1.439459827586207 ], [ 29.762456329113924, -1.439459827586207 ], [ 29.762456329113924, -1.438651379310345 ], [ 29.762186835443039, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14327, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.438651379310345 ], [ 29.762456329113924, -1.439459827586207 ], [ 29.762725822784809, -1.439459827586207 ], [ 29.762725822784809, -1.438651379310345 ], [ 29.762456329113924, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14328, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.438651379310345 ], [ 29.762725822784809, -1.439459827586207 ], [ 29.762995316455697, -1.439459827586207 ], [ 29.762995316455697, -1.438651379310345 ], [ 29.762725822784809, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14329, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.438651379310345 ], [ 29.763534303797467, -1.439459827586207 ], [ 29.763803797468356, -1.439459827586207 ], [ 29.763803797468356, -1.438651379310345 ], [ 29.763534303797467, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14330, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.438651379310345 ], [ 29.764881772151899, -1.439459827586207 ], [ 29.765151265822784, -1.439459827586207 ], [ 29.765151265822784, -1.438651379310345 ], [ 29.764881772151899, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14331, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.438651379310345 ], [ 29.773236075949367, -1.438920862068966 ], [ 29.773505569620252, -1.438920862068966 ], [ 29.773505569620252, -1.438651379310345 ], [ 29.773236075949367, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14332, "El": 140 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.438651379310345 ], [ 29.773505569620252, -1.438920862068966 ], [ 29.77377506329114, -1.438920862068966 ], [ 29.77377506329114, -1.438651379310345 ], [ 29.773505569620252, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14333, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.438651379310345 ], [ 29.77377506329114, -1.438920862068966 ], [ 29.774044556962025, -1.438920862068966 ], [ 29.774044556962025, -1.438651379310345 ], [ 29.77377506329114, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14334, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.438651379310345 ], [ 29.789405696202532, -1.439459827586207 ], [ 29.789675189873417, -1.439459827586207 ], [ 29.789675189873417, -1.438651379310345 ], [ 29.789405696202532, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14335, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.438651379310345 ], [ 29.789675189873417, -1.438920862068966 ], [ 29.789944683544302, -1.438920862068966 ], [ 29.789944683544302, -1.438651379310345 ], [ 29.789675189873417, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14336, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.438651379310345 ], [ 29.789944683544302, -1.438920862068966 ], [ 29.790214177215191, -1.438920862068966 ], [ 29.790214177215191, -1.438651379310345 ], [ 29.789944683544302, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14337, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.438651379310345 ], [ 29.790214177215191, -1.438920862068966 ], [ 29.790483670886076, -1.438920862068966 ], [ 29.790483670886076, -1.438651379310345 ], [ 29.790214177215191, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14338, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.438651379310345 ], [ 29.791022658227849, -1.439190344827586 ], [ 29.791292151898734, -1.439190344827586 ], [ 29.791292151898734, -1.438651379310345 ], [ 29.791022658227849, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14339, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.438651379310345 ], [ 29.792370126582277, -1.439190344827586 ], [ 29.792909113924051, -1.439190344827586 ], [ 29.792909113924051, -1.438651379310345 ], [ 29.792370126582277, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14340, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.438651379310345 ], [ 29.794795569620252, -1.438920862068966 ], [ 29.79506506329114, -1.438920862068966 ], [ 29.79506506329114, -1.438651379310345 ], [ 29.794795569620252, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14341, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.438651379310345 ], [ 29.79506506329114, -1.438920862068966 ], [ 29.795334556962025, -1.438920862068966 ], [ 29.795334556962025, -1.438651379310345 ], [ 29.79506506329114, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14342, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.438651379310345 ], [ 29.795334556962025, -1.438920862068966 ], [ 29.79560405063291, -1.438920862068966 ], [ 29.79560405063291, -1.438651379310345 ], [ 29.795334556962025, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14343, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.438651379310345 ], [ 29.79560405063291, -1.438920862068966 ], [ 29.795873544303799, -1.438920862068966 ], [ 29.795873544303799, -1.438651379310345 ], [ 29.79560405063291, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14344, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.438651379310345 ], [ 29.795873544303799, -1.438920862068966 ], [ 29.796143037974684, -1.438920862068966 ], [ 29.796143037974684, -1.438651379310345 ], [ 29.795873544303799, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14345, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.438651379310345 ], [ 29.796143037974684, -1.438920862068966 ], [ 29.796412531645569, -1.438920862068966 ], [ 29.796412531645569, -1.438651379310345 ], [ 29.796143037974684, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14346, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.438651379310345 ], [ 29.796412531645569, -1.438920862068966 ], [ 29.796682025316457, -1.438920862068966 ], [ 29.796682025316457, -1.438651379310345 ], [ 29.796412531645569, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14347, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.438651379310345 ], [ 29.796682025316457, -1.438920862068966 ], [ 29.796951518987342, -1.438920862068966 ], [ 29.796951518987342, -1.438651379310345 ], [ 29.796682025316457, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14348, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.438651379310345 ], [ 29.796951518987342, -1.438920862068966 ], [ 29.797221012658227, -1.438920862068966 ], [ 29.797221012658227, -1.438651379310345 ], [ 29.796951518987342, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14349, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.438651379310345 ], [ 29.797221012658227, -1.438920862068966 ], [ 29.797490506329115, -1.438920862068966 ], [ 29.797490506329115, -1.438651379310345 ], [ 29.797221012658227, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14350, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.438651379310345 ], [ 29.797490506329115, -1.438920862068966 ], [ 29.798029493670885, -1.438920862068966 ], [ 29.798029493670885, -1.438651379310345 ], [ 29.797490506329115, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14351, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.438651379310345 ], [ 29.798029493670885, -1.438920862068966 ], [ 29.799107468354432, -1.438920862068966 ], [ 29.799107468354432, -1.438651379310345 ], [ 29.798029493670885, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14352, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.438651379310345 ], [ 29.799107468354432, -1.438920862068966 ], [ 29.799646455696202, -1.438920862068966 ], [ 29.799646455696202, -1.438651379310345 ], [ 29.799107468354432, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14353, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.438651379310345 ], [ 29.799646455696202, -1.438920862068966 ], [ 29.79991594936709, -1.438920862068966 ], [ 29.79991594936709, -1.438651379310345 ], [ 29.799646455696202, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14354, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.438651379310345 ], [ 29.79991594936709, -1.438920862068966 ], [ 29.800185443037975, -1.438920862068966 ], [ 29.800185443037975, -1.438651379310345 ], [ 29.79991594936709, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14355, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.438651379310345 ], [ 29.800185443037975, -1.438920862068966 ], [ 29.80045493670886, -1.438920862068966 ], [ 29.80045493670886, -1.438651379310345 ], [ 29.800185443037975, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14356, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.438651379310345 ], [ 29.80045493670886, -1.438920862068966 ], [ 29.800724430379748, -1.438920862068966 ], [ 29.800724430379748, -1.438651379310345 ], [ 29.80045493670886, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14357, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.438651379310345 ], [ 29.800724430379748, -1.438920862068966 ], [ 29.800993924050633, -1.438920862068966 ], [ 29.800993924050633, -1.438651379310345 ], [ 29.800724430379748, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14358, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.438651379310345 ], [ 29.801532911392407, -1.438920862068966 ], [ 29.802071898734177, -1.438920862068966 ], [ 29.802071898734177, -1.438651379310345 ], [ 29.801532911392407, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14359, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.438651379310345 ], [ 29.802071898734177, -1.438920862068966 ], [ 29.802341392405065, -1.438920862068966 ], [ 29.802341392405065, -1.438651379310345 ], [ 29.802071898734177, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14360, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.438651379310345 ], [ 29.802341392405065, -1.438920862068966 ], [ 29.80261088607595, -1.438920862068966 ], [ 29.80261088607595, -1.438651379310345 ], [ 29.802341392405065, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14361, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.438651379310345 ], [ 29.80261088607595, -1.438920862068966 ], [ 29.802880379746835, -1.438920862068966 ], [ 29.802880379746835, -1.438651379310345 ], [ 29.80261088607595, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14362, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.438651379310345 ], [ 29.802880379746835, -1.438920862068966 ], [ 29.803149873417723, -1.438920862068966 ], [ 29.803149873417723, -1.438651379310345 ], [ 29.802880379746835, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14363, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.438651379310345 ], [ 29.803149873417723, -1.438920862068966 ], [ 29.803419367088608, -1.438920862068966 ], [ 29.803419367088608, -1.438651379310345 ], [ 29.803149873417723, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14364, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.438651379310345 ], [ 29.803419367088608, -1.438920862068966 ], [ 29.803688860759493, -1.438920862068966 ], [ 29.803688860759493, -1.438651379310345 ], [ 29.803419367088608, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14365, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.438651379310345 ], [ 29.803688860759493, -1.438920862068966 ], [ 29.803958354430382, -1.438920862068966 ], [ 29.803958354430382, -1.438651379310345 ], [ 29.803688860759493, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14366, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.438651379310345 ], [ 29.803958354430382, -1.438920862068966 ], [ 29.804227848101267, -1.438920862068966 ], [ 29.804227848101267, -1.438651379310345 ], [ 29.803958354430382, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14367, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.438651379310345 ], [ 29.804227848101267, -1.438920862068966 ], [ 29.804497341772151, -1.438920862068966 ], [ 29.804497341772151, -1.438651379310345 ], [ 29.804227848101267, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14368, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.438651379310345 ], [ 29.804497341772151, -1.438920862068966 ], [ 29.80476683544304, -1.438920862068966 ], [ 29.80476683544304, -1.438651379310345 ], [ 29.804497341772151, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14369, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.438651379310345 ], [ 29.80476683544304, -1.438920862068966 ], [ 29.805036329113925, -1.438920862068966 ], [ 29.805036329113925, -1.438651379310345 ], [ 29.80476683544304, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14370, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.438651379310345 ], [ 29.805036329113925, -1.438920862068966 ], [ 29.806383797468357, -1.438920862068966 ], [ 29.806383797468357, -1.438651379310345 ], [ 29.805036329113925, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14371, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.438651379310345 ], [ 29.806383797468357, -1.438920862068966 ], [ 29.806653291139241, -1.438920862068966 ], [ 29.806653291139241, -1.438651379310345 ], [ 29.806383797468357, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14372, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.438651379310345 ], [ 29.806653291139241, -1.438920862068966 ], [ 29.806922784810126, -1.438920862068966 ], [ 29.806922784810126, -1.438651379310345 ], [ 29.806653291139241, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14373, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.438651379310345 ], [ 29.806922784810126, -1.438920862068966 ], [ 29.807192278481015, -1.438920862068966 ], [ 29.807192278481015, -1.438651379310345 ], [ 29.806922784810126, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14374, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.438651379310345 ], [ 29.807192278481015, -1.438920862068966 ], [ 29.8074617721519, -1.438920862068966 ], [ 29.8074617721519, -1.438651379310345 ], [ 29.807192278481015, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14375, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.438651379310345 ], [ 29.8074617721519, -1.438920862068966 ], [ 29.807731265822785, -1.438920862068966 ], [ 29.807731265822785, -1.438651379310345 ], [ 29.8074617721519, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14376, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.438651379310345 ], [ 29.807731265822785, -1.438920862068966 ], [ 29.808000759493673, -1.438920862068966 ], [ 29.808000759493673, -1.438651379310345 ], [ 29.807731265822785, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14377, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.438651379310345 ], [ 29.808000759493673, -1.438920862068966 ], [ 29.808270253164558, -1.438920862068966 ], [ 29.808270253164558, -1.438651379310345 ], [ 29.808000759493673, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14378, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.438651379310345 ], [ 29.808270253164558, -1.438920862068966 ], [ 29.808539746835443, -1.438920862068966 ], [ 29.808539746835443, -1.438651379310345 ], [ 29.808270253164558, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14379, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.438651379310345 ], [ 29.808539746835443, -1.438920862068966 ], [ 29.808809240506328, -1.438920862068966 ], [ 29.808809240506328, -1.438651379310345 ], [ 29.808539746835443, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14380, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.438651379310345 ], [ 29.808809240506328, -1.438920862068966 ], [ 29.809078734177216, -1.438920862068966 ], [ 29.809078734177216, -1.438651379310345 ], [ 29.808809240506328, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14381, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.438651379310345 ], [ 29.809078734177216, -1.438920862068966 ], [ 29.809348227848101, -1.438920862068966 ], [ 29.809348227848101, -1.438651379310345 ], [ 29.809078734177216, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14382, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.438651379310345 ], [ 29.809348227848101, -1.438920862068966 ], [ 29.809887215189875, -1.438920862068966 ], [ 29.809887215189875, -1.438651379310345 ], [ 29.809348227848101, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14383, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.438651379310345 ], [ 29.809887215189875, -1.438920862068966 ], [ 29.81015670886076, -1.438920862068966 ], [ 29.81015670886076, -1.438651379310345 ], [ 29.809887215189875, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14384, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.438651379310345 ], [ 29.810965189873418, -1.438920862068966 ], [ 29.811234683544303, -1.438920862068966 ], [ 29.811234683544303, -1.438651379310345 ], [ 29.810965189873418, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14385, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.438651379310345 ], [ 29.811234683544303, -1.438920862068966 ], [ 29.811504177215191, -1.438920862068966 ], [ 29.811504177215191, -1.438651379310345 ], [ 29.811234683544303, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14386, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.438651379310345 ], [ 29.811504177215191, -1.438920862068966 ], [ 29.811773670886076, -1.438920862068966 ], [ 29.811773670886076, -1.438651379310345 ], [ 29.811504177215191, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14387, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.438920862068966 ], [ 29.744130759493672, -1.439459827586207 ], [ 29.744400253164557, -1.439459827586207 ], [ 29.744400253164557, -1.438920862068966 ], [ 29.744130759493672, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14388, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.438920862068966 ], [ 29.744400253164557, -1.439459827586207 ], [ 29.744669746835442, -1.439459827586207 ], [ 29.744669746835442, -1.438920862068966 ], [ 29.744400253164557, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14389, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.438920862068966 ], [ 29.745208734177215, -1.439190344827586 ], [ 29.7454782278481, -1.439190344827586 ], [ 29.7454782278481, -1.438920862068966 ], [ 29.745208734177215, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14390, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.438920862068966 ], [ 29.7454782278481, -1.439190344827586 ], [ 29.745747721518985, -1.439190344827586 ], [ 29.745747721518985, -1.438920862068966 ], [ 29.7454782278481, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14391, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.438920862068966 ], [ 29.745747721518985, -1.439190344827586 ], [ 29.746017215189873, -1.439190344827586 ], [ 29.746017215189873, -1.438920862068966 ], [ 29.745747721518985, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14392, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.438920862068966 ], [ 29.746017215189873, -1.439190344827586 ], [ 29.746286708860758, -1.439190344827586 ], [ 29.746286708860758, -1.438920862068966 ], [ 29.746017215189873, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14393, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.438920862068966 ], [ 29.746286708860758, -1.439190344827586 ], [ 29.746556202531643, -1.439190344827586 ], [ 29.746556202531643, -1.438920862068966 ], [ 29.746286708860758, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14394, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.438920862068966 ], [ 29.746556202531643, -1.439190344827586 ], [ 29.746825696202531, -1.439190344827586 ], [ 29.746825696202531, -1.438920862068966 ], [ 29.746556202531643, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14395, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.438920862068966 ], [ 29.746825696202531, -1.439190344827586 ], [ 29.747095189873416, -1.439190344827586 ], [ 29.747095189873416, -1.438920862068966 ], [ 29.746825696202531, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14396, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.438920862068966 ], [ 29.747095189873416, -1.439190344827586 ], [ 29.747364683544301, -1.439190344827586 ], [ 29.747364683544301, -1.438920862068966 ], [ 29.747095189873416, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14397, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.438920862068966 ], [ 29.747364683544301, -1.439190344827586 ], [ 29.747903670886075, -1.439190344827586 ], [ 29.747903670886075, -1.438920862068966 ], [ 29.747364683544301, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14398, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.438920862068966 ], [ 29.747903670886075, -1.439190344827586 ], [ 29.74817316455696, -1.439190344827586 ], [ 29.74817316455696, -1.438920862068966 ], [ 29.747903670886075, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14399, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.438920862068966 ], [ 29.74817316455696, -1.439190344827586 ], [ 29.748442658227848, -1.439190344827586 ], [ 29.748442658227848, -1.438920862068966 ], [ 29.74817316455696, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14400, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.438920862068966 ], [ 29.748442658227848, -1.439190344827586 ], [ 29.748712151898733, -1.439190344827586 ], [ 29.748712151898733, -1.438920862068966 ], [ 29.748442658227848, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14401, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.438920862068966 ], [ 29.748712151898733, -1.439190344827586 ], [ 29.748981645569618, -1.439190344827586 ], [ 29.748981645569618, -1.438920862068966 ], [ 29.748712151898733, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14402, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.438920862068966 ], [ 29.748981645569618, -1.439190344827586 ], [ 29.749251139240506, -1.439190344827586 ], [ 29.749251139240506, -1.438920862068966 ], [ 29.748981645569618, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14403, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.438920862068966 ], [ 29.749251139240506, -1.439190344827586 ], [ 29.749520632911391, -1.439190344827586 ], [ 29.749520632911391, -1.438920862068966 ], [ 29.749251139240506, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14404, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.438920862068966 ], [ 29.749520632911391, -1.439190344827586 ], [ 29.749790126582276, -1.439190344827586 ], [ 29.749790126582276, -1.438920862068966 ], [ 29.749520632911391, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14405, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.438920862068966 ], [ 29.749790126582276, -1.439190344827586 ], [ 29.750059620253165, -1.439190344827586 ], [ 29.750059620253165, -1.438920862068966 ], [ 29.749790126582276, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14406, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.438920862068966 ], [ 29.750059620253165, -1.439190344827586 ], [ 29.750598607594934, -1.439190344827586 ], [ 29.750598607594934, -1.438920862068966 ], [ 29.750059620253165, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14407, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.438920862068966 ], [ 29.772697088607593, -1.439190344827586 ], [ 29.772966582278482, -1.439190344827586 ], [ 29.772966582278482, -1.438920862068966 ], [ 29.772697088607593, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14408, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.438920862068966 ], [ 29.772966582278482, -1.439190344827586 ], [ 29.773236075949367, -1.439190344827586 ], [ 29.773236075949367, -1.438920862068966 ], [ 29.772966582278482, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14409, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.438920862068966 ], [ 29.773236075949367, -1.439190344827586 ], [ 29.773505569620252, -1.439190344827586 ], [ 29.773505569620252, -1.438920862068966 ], [ 29.773236075949367, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14410, "El": 137 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.438920862068966 ], [ 29.773505569620252, -1.439190344827586 ], [ 29.77377506329114, -1.439190344827586 ], [ 29.77377506329114, -1.438920862068966 ], [ 29.773505569620252, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14411, "El": 139 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.438920862068966 ], [ 29.77377506329114, -1.439190344827586 ], [ 29.774044556962025, -1.439190344827586 ], [ 29.774044556962025, -1.438920862068966 ], [ 29.77377506329114, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14412, "El": 141 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.438920862068966 ], [ 29.774044556962025, -1.439190344827586 ], [ 29.77431405063291, -1.439190344827586 ], [ 29.77431405063291, -1.438920862068966 ], [ 29.774044556962025, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14413, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.438920862068966 ], [ 29.789675189873417, -1.439459827586207 ], [ 29.789944683544302, -1.439459827586207 ], [ 29.789944683544302, -1.438920862068966 ], [ 29.789675189873417, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14414, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.438920862068966 ], [ 29.789944683544302, -1.439459827586207 ], [ 29.790214177215191, -1.439459827586207 ], [ 29.790214177215191, -1.438920862068966 ], [ 29.789944683544302, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14415, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.438920862068966 ], [ 29.790214177215191, -1.439190344827586 ], [ 29.790483670886076, -1.439190344827586 ], [ 29.790483670886076, -1.438920862068966 ], [ 29.790214177215191, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14416, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.438920862068966 ], [ 29.790483670886076, -1.439190344827586 ], [ 29.790753164556961, -1.439190344827586 ], [ 29.790753164556961, -1.438920862068966 ], [ 29.790483670886076, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14417, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.438920862068966 ], [ 29.790753164556961, -1.439190344827586 ], [ 29.791022658227849, -1.439190344827586 ], [ 29.791022658227849, -1.438920862068966 ], [ 29.790753164556961, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14418, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.438920862068966 ], [ 29.791292151898734, -1.439190344827586 ], [ 29.791561645569619, -1.439190344827586 ], [ 29.791561645569619, -1.438920862068966 ], [ 29.791292151898734, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14419, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.438920862068966 ], [ 29.791561645569619, -1.439190344827586 ], [ 29.791831139240507, -1.439190344827586 ], [ 29.791831139240507, -1.438920862068966 ], [ 29.791561645569619, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14420, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.438920862068966 ], [ 29.792100632911392, -1.439459827586207 ], [ 29.792370126582277, -1.439459827586207 ], [ 29.792370126582277, -1.438920862068966 ], [ 29.792100632911392, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14421, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.438920862068966 ], [ 29.793178607594935, -1.439459827586207 ], [ 29.793448101265824, -1.439459827586207 ], [ 29.793448101265824, -1.438920862068966 ], [ 29.793178607594935, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14422, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.438920862068966 ], [ 29.793987088607594, -1.439459827586207 ], [ 29.794256582278482, -1.439459827586207 ], [ 29.794256582278482, -1.438920862068966 ], [ 29.793987088607594, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14423, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.438920862068966 ], [ 29.794526075949367, -1.439190344827586 ], [ 29.794795569620252, -1.439190344827586 ], [ 29.794795569620252, -1.438920862068966 ], [ 29.794526075949367, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14424, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.438920862068966 ], [ 29.794795569620252, -1.439190344827586 ], [ 29.79506506329114, -1.439190344827586 ], [ 29.79506506329114, -1.438920862068966 ], [ 29.794795569620252, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14425, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.438920862068966 ], [ 29.79506506329114, -1.439190344827586 ], [ 29.795334556962025, -1.439190344827586 ], [ 29.795334556962025, -1.438920862068966 ], [ 29.79506506329114, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14426, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.438920862068966 ], [ 29.795334556962025, -1.439190344827586 ], [ 29.79560405063291, -1.439190344827586 ], [ 29.79560405063291, -1.438920862068966 ], [ 29.795334556962025, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14427, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.438920862068966 ], [ 29.79560405063291, -1.439190344827586 ], [ 29.795873544303799, -1.439190344827586 ], [ 29.795873544303799, -1.438920862068966 ], [ 29.79560405063291, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14428, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.438920862068966 ], [ 29.795873544303799, -1.439190344827586 ], [ 29.796143037974684, -1.439190344827586 ], [ 29.796143037974684, -1.438920862068966 ], [ 29.795873544303799, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14429, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.438920862068966 ], [ 29.796143037974684, -1.439190344827586 ], [ 29.796412531645569, -1.439190344827586 ], [ 29.796412531645569, -1.438920862068966 ], [ 29.796143037974684, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14430, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.438920862068966 ], [ 29.796412531645569, -1.439190344827586 ], [ 29.796682025316457, -1.439190344827586 ], [ 29.796682025316457, -1.438920862068966 ], [ 29.796412531645569, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14431, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.438920862068966 ], [ 29.796682025316457, -1.439190344827586 ], [ 29.796951518987342, -1.439190344827586 ], [ 29.796951518987342, -1.438920862068966 ], [ 29.796682025316457, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14432, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.438920862068966 ], [ 29.796951518987342, -1.439190344827586 ], [ 29.797221012658227, -1.439190344827586 ], [ 29.797221012658227, -1.438920862068966 ], [ 29.796951518987342, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14433, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.438920862068966 ], [ 29.797221012658227, -1.439190344827586 ], [ 29.79776, -1.439190344827586 ], [ 29.79776, -1.438920862068966 ], [ 29.797221012658227, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14434, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.438920862068966 ], [ 29.79776, -1.439190344827586 ], [ 29.799376962025317, -1.439190344827586 ], [ 29.799376962025317, -1.438920862068966 ], [ 29.79776, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14435, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.438920862068966 ], [ 29.799376962025317, -1.439190344827586 ], [ 29.79991594936709, -1.439190344827586 ], [ 29.79991594936709, -1.438920862068966 ], [ 29.799376962025317, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14436, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.438920862068966 ], [ 29.79991594936709, -1.439190344827586 ], [ 29.800185443037975, -1.439190344827586 ], [ 29.800185443037975, -1.438920862068966 ], [ 29.79991594936709, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14437, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.438920862068966 ], [ 29.800185443037975, -1.439190344827586 ], [ 29.80045493670886, -1.439190344827586 ], [ 29.80045493670886, -1.438920862068966 ], [ 29.800185443037975, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14438, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.438920862068966 ], [ 29.80045493670886, -1.439190344827586 ], [ 29.800724430379748, -1.439190344827586 ], [ 29.800724430379748, -1.438920862068966 ], [ 29.80045493670886, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14439, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.438920862068966 ], [ 29.800724430379748, -1.439190344827586 ], [ 29.800993924050633, -1.439190344827586 ], [ 29.800993924050633, -1.438920862068966 ], [ 29.800724430379748, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14440, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.438920862068966 ], [ 29.800993924050633, -1.439190344827586 ], [ 29.801263417721518, -1.439190344827586 ], [ 29.801263417721518, -1.438920862068966 ], [ 29.800993924050633, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14441, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.438920862068966 ], [ 29.802071898734177, -1.439190344827586 ], [ 29.802341392405065, -1.439190344827586 ], [ 29.802341392405065, -1.438920862068966 ], [ 29.802071898734177, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14442, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.438920862068966 ], [ 29.802341392405065, -1.439190344827586 ], [ 29.80261088607595, -1.439190344827586 ], [ 29.80261088607595, -1.438920862068966 ], [ 29.802341392405065, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14443, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.438920862068966 ], [ 29.80261088607595, -1.439190344827586 ], [ 29.802880379746835, -1.439190344827586 ], [ 29.802880379746835, -1.438920862068966 ], [ 29.80261088607595, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14444, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.438920862068966 ], [ 29.802880379746835, -1.439190344827586 ], [ 29.803149873417723, -1.439190344827586 ], [ 29.803149873417723, -1.438920862068966 ], [ 29.802880379746835, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14445, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.438920862068966 ], [ 29.803149873417723, -1.439190344827586 ], [ 29.803419367088608, -1.439190344827586 ], [ 29.803419367088608, -1.438920862068966 ], [ 29.803149873417723, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14446, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.438920862068966 ], [ 29.803419367088608, -1.439190344827586 ], [ 29.803688860759493, -1.439190344827586 ], [ 29.803688860759493, -1.438920862068966 ], [ 29.803419367088608, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14447, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.438920862068966 ], [ 29.803688860759493, -1.439190344827586 ], [ 29.804227848101267, -1.439190344827586 ], [ 29.804227848101267, -1.438920862068966 ], [ 29.803688860759493, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14448, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.438920862068966 ], [ 29.804227848101267, -1.439190344827586 ], [ 29.804497341772151, -1.439190344827586 ], [ 29.804497341772151, -1.438920862068966 ], [ 29.804227848101267, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14449, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.438920862068966 ], [ 29.804497341772151, -1.439190344827586 ], [ 29.80476683544304, -1.439190344827586 ], [ 29.80476683544304, -1.438920862068966 ], [ 29.804497341772151, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14450, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.438920862068966 ], [ 29.80476683544304, -1.439190344827586 ], [ 29.806383797468357, -1.439190344827586 ], [ 29.806383797468357, -1.439459827586207 ], [ 29.808000759493673, -1.439459827586207 ], [ 29.808000759493673, -1.439190344827586 ], [ 29.806653291139241, -1.439190344827586 ], [ 29.806653291139241, -1.438920862068966 ], [ 29.80476683544304, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14451, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.438920862068966 ], [ 29.806653291139241, -1.439190344827586 ], [ 29.806922784810126, -1.439190344827586 ], [ 29.806922784810126, -1.438920862068966 ], [ 29.806653291139241, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14452, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.438920862068966 ], [ 29.806922784810126, -1.439190344827586 ], [ 29.807192278481015, -1.439190344827586 ], [ 29.807192278481015, -1.438920862068966 ], [ 29.806922784810126, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14453, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.438920862068966 ], [ 29.807192278481015, -1.439190344827586 ], [ 29.808000759493673, -1.439190344827586 ], [ 29.808000759493673, -1.438920862068966 ], [ 29.807192278481015, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14454, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.438920862068966 ], [ 29.808000759493673, -1.439459827586207 ], [ 29.808539746835443, -1.439459827586207 ], [ 29.808539746835443, -1.439190344827586 ], [ 29.808270253164558, -1.439190344827586 ], [ 29.808270253164558, -1.438920862068966 ], [ 29.808000759493673, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14455, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.438920862068966 ], [ 29.808270253164558, -1.439190344827586 ], [ 29.808539746835443, -1.439190344827586 ], [ 29.808539746835443, -1.438920862068966 ], [ 29.808270253164558, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14456, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.438920862068966 ], [ 29.808539746835443, -1.439459827586207 ], [ 29.808809240506328, -1.439459827586207 ], [ 29.808809240506328, -1.438920862068966 ], [ 29.808539746835443, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14457, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.438920862068966 ], [ 29.808809240506328, -1.439459827586207 ], [ 29.809078734177216, -1.439459827586207 ], [ 29.809078734177216, -1.438920862068966 ], [ 29.808809240506328, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14458, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.438920862068966 ], [ 29.809078734177216, -1.439190344827586 ], [ 29.809348227848101, -1.439190344827586 ], [ 29.809348227848101, -1.438920862068966 ], [ 29.809078734177216, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14459, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.438920862068966 ], [ 29.809348227848101, -1.439190344827586 ], [ 29.809617721518986, -1.439190344827586 ], [ 29.809617721518986, -1.438920862068966 ], [ 29.809348227848101, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14460, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.438920862068966 ], [ 29.809617721518986, -1.439190344827586 ], [ 29.809887215189875, -1.439190344827586 ], [ 29.809887215189875, -1.438920862068966 ], [ 29.809617721518986, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14461, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.438920862068966 ], [ 29.809887215189875, -1.439190344827586 ], [ 29.81015670886076, -1.439190344827586 ], [ 29.81015670886076, -1.438920862068966 ], [ 29.809887215189875, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14462, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.438920862068966 ], [ 29.810965189873418, -1.439459827586207 ], [ 29.811234683544303, -1.439459827586207 ], [ 29.811234683544303, -1.438920862068966 ], [ 29.810965189873418, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14463, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.438920862068966 ], [ 29.811234683544303, -1.439459827586207 ], [ 29.811504177215191, -1.439459827586207 ], [ 29.811504177215191, -1.438920862068966 ], [ 29.811234683544303, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14464, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.439190344827586 ], [ 29.744669746835442, -1.439459827586207 ], [ 29.744939240506326, -1.439459827586207 ], [ 29.744939240506326, -1.439190344827586 ], [ 29.744669746835442, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14465, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.439190344827586 ], [ 29.744939240506326, -1.439459827586207 ], [ 29.745208734177215, -1.439459827586207 ], [ 29.745208734177215, -1.439190344827586 ], [ 29.744939240506326, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14466, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.439190344827586 ], [ 29.745208734177215, -1.439459827586207 ], [ 29.7454782278481, -1.439459827586207 ], [ 29.7454782278481, -1.439190344827586 ], [ 29.745208734177215, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14467, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.439190344827586 ], [ 29.7454782278481, -1.439459827586207 ], [ 29.745747721518985, -1.439459827586207 ], [ 29.745747721518985, -1.439190344827586 ], [ 29.7454782278481, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14468, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.439190344827586 ], [ 29.745747721518985, -1.439459827586207 ], [ 29.746017215189873, -1.439459827586207 ], [ 29.746017215189873, -1.439190344827586 ], [ 29.745747721518985, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14469, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.439190344827586 ], [ 29.746017215189873, -1.439459827586207 ], [ 29.746286708860758, -1.439459827586207 ], [ 29.746286708860758, -1.439190344827586 ], [ 29.746017215189873, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14470, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.439190344827586 ], [ 29.746286708860758, -1.439459827586207 ], [ 29.746556202531643, -1.439459827586207 ], [ 29.746556202531643, -1.439190344827586 ], [ 29.746286708860758, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14471, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.439190344827586 ], [ 29.746556202531643, -1.439459827586207 ], [ 29.746825696202531, -1.439459827586207 ], [ 29.746825696202531, -1.439190344827586 ], [ 29.746556202531643, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14472, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.439190344827586 ], [ 29.746825696202531, -1.439459827586207 ], [ 29.747095189873416, -1.439459827586207 ], [ 29.747095189873416, -1.439190344827586 ], [ 29.746825696202531, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14473, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.439190344827586 ], [ 29.747095189873416, -1.439459827586207 ], [ 29.747364683544301, -1.439459827586207 ], [ 29.747364683544301, -1.439190344827586 ], [ 29.747095189873416, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14474, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.439190344827586 ], [ 29.747364683544301, -1.439459827586207 ], [ 29.747903670886075, -1.439459827586207 ], [ 29.747903670886075, -1.439190344827586 ], [ 29.747364683544301, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14475, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.439190344827586 ], [ 29.747903670886075, -1.439459827586207 ], [ 29.74817316455696, -1.439459827586207 ], [ 29.74817316455696, -1.439190344827586 ], [ 29.747903670886075, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14476, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.439190344827586 ], [ 29.74817316455696, -1.439459827586207 ], [ 29.748442658227848, -1.439459827586207 ], [ 29.748442658227848, -1.439190344827586 ], [ 29.74817316455696, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14477, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.439190344827586 ], [ 29.748442658227848, -1.439459827586207 ], [ 29.748712151898733, -1.439459827586207 ], [ 29.748712151898733, -1.439190344827586 ], [ 29.748442658227848, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14478, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.439190344827586 ], [ 29.748712151898733, -1.439459827586207 ], [ 29.748981645569618, -1.439459827586207 ], [ 29.748981645569618, -1.439190344827586 ], [ 29.748712151898733, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14479, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.439190344827586 ], [ 29.748981645569618, -1.439459827586207 ], [ 29.749251139240506, -1.439459827586207 ], [ 29.749251139240506, -1.439190344827586 ], [ 29.748981645569618, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14480, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.439190344827586 ], [ 29.749251139240506, -1.439459827586207 ], [ 29.749520632911391, -1.439459827586207 ], [ 29.749520632911391, -1.439190344827586 ], [ 29.749251139240506, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14481, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.439190344827586 ], [ 29.749520632911391, -1.439459827586207 ], [ 29.749790126582276, -1.439459827586207 ], [ 29.749790126582276, -1.439190344827586 ], [ 29.749520632911391, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14482, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.439190344827586 ], [ 29.749790126582276, -1.439459827586207 ], [ 29.750059620253165, -1.439459827586207 ], [ 29.750059620253165, -1.439190344827586 ], [ 29.749790126582276, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14483, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.439190344827586 ], [ 29.750059620253165, -1.439459827586207 ], [ 29.75032911392405, -1.439459827586207 ], [ 29.75032911392405, -1.439190344827586 ], [ 29.750059620253165, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14484, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.439190344827586 ], [ 29.75032911392405, -1.439459827586207 ], [ 29.750598607594934, -1.439459827586207 ], [ 29.750598607594934, -1.439190344827586 ], [ 29.75032911392405, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14485, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.439190344827586 ], [ 29.772158101265823, -1.439459827586207 ], [ 29.772427594936708, -1.439459827586207 ], [ 29.772427594936708, -1.439190344827586 ], [ 29.772158101265823, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14486, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.439190344827586 ], [ 29.772427594936708, -1.439459827586207 ], [ 29.772697088607593, -1.439459827586207 ], [ 29.772697088607593, -1.439190344827586 ], [ 29.772427594936708, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14487, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.439190344827586 ], [ 29.772697088607593, -1.439459827586207 ], [ 29.772966582278482, -1.439459827586207 ], [ 29.772966582278482, -1.439190344827586 ], [ 29.772697088607593, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14488, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.439190344827586 ], [ 29.772966582278482, -1.439459827586207 ], [ 29.773236075949367, -1.439459827586207 ], [ 29.773236075949367, -1.439190344827586 ], [ 29.772966582278482, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14489, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.439190344827586 ], [ 29.773236075949367, -1.439459827586207 ], [ 29.773505569620252, -1.439459827586207 ], [ 29.773505569620252, -1.439190344827586 ], [ 29.773236075949367, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14490, "El": 134 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.439190344827586 ], [ 29.773505569620252, -1.439459827586207 ], [ 29.77377506329114, -1.439459827586207 ], [ 29.77377506329114, -1.439190344827586 ], [ 29.773505569620252, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14491, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.439190344827586 ], [ 29.77377506329114, -1.439459827586207 ], [ 29.774044556962025, -1.439459827586207 ], [ 29.774044556962025, -1.439190344827586 ], [ 29.77377506329114, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14492, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.439190344827586 ], [ 29.774044556962025, -1.439459827586207 ], [ 29.77431405063291, -1.439459827586207 ], [ 29.77431405063291, -1.439190344827586 ], [ 29.774044556962025, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14493, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.439190344827586 ], [ 29.790753164556961, -1.439459827586207 ], [ 29.791022658227849, -1.439459827586207 ], [ 29.791022658227849, -1.439190344827586 ], [ 29.790753164556961, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14494, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.439190344827586 ], [ 29.791022658227849, -1.439459827586207 ], [ 29.791292151898734, -1.439459827586207 ], [ 29.791292151898734, -1.439190344827586 ], [ 29.791022658227849, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14495, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.439190344827586 ], [ 29.791292151898734, -1.439459827586207 ], [ 29.791561645569619, -1.439459827586207 ], [ 29.791561645569619, -1.439190344827586 ], [ 29.791292151898734, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14496, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.439190344827586 ], [ 29.791561645569619, -1.439459827586207 ], [ 29.791831139240507, -1.439459827586207 ], [ 29.791831139240507, -1.439190344827586 ], [ 29.791561645569619, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14497, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.439190344827586 ], [ 29.791831139240507, -1.439459827586207 ], [ 29.792100632911392, -1.439459827586207 ], [ 29.792100632911392, -1.439190344827586 ], [ 29.791831139240507, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14498, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.439190344827586 ], [ 29.792370126582277, -1.439459827586207 ], [ 29.792909113924051, -1.439459827586207 ], [ 29.792909113924051, -1.439190344827586 ], [ 29.792370126582277, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14499, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.439190344827586 ], [ 29.792909113924051, -1.439459827586207 ], [ 29.793178607594935, -1.439459827586207 ], [ 29.793178607594935, -1.439190344827586 ], [ 29.792909113924051, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14500, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.439190344827586 ], [ 29.793717594936709, -1.439459827586207 ], [ 29.793987088607594, -1.439459827586207 ], [ 29.793987088607594, -1.439190344827586 ], [ 29.793717594936709, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14501, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.439190344827586 ], [ 29.794256582278482, -1.439459827586207 ], [ 29.794526075949367, -1.439459827586207 ], [ 29.794526075949367, -1.439190344827586 ], [ 29.794256582278482, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14502, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.439190344827586 ], [ 29.794526075949367, -1.439459827586207 ], [ 29.794795569620252, -1.439459827586207 ], [ 29.794795569620252, -1.439190344827586 ], [ 29.794526075949367, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14503, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.439190344827586 ], [ 29.794795569620252, -1.439459827586207 ], [ 29.79506506329114, -1.439459827586207 ], [ 29.79506506329114, -1.439190344827586 ], [ 29.794795569620252, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14504, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.439190344827586 ], [ 29.79506506329114, -1.439459827586207 ], [ 29.795334556962025, -1.439459827586207 ], [ 29.795334556962025, -1.439190344827586 ], [ 29.79506506329114, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14505, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.439190344827586 ], [ 29.795334556962025, -1.439459827586207 ], [ 29.79560405063291, -1.439459827586207 ], [ 29.79560405063291, -1.439190344827586 ], [ 29.795334556962025, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14506, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.439190344827586 ], [ 29.79560405063291, -1.439459827586207 ], [ 29.795873544303799, -1.439459827586207 ], [ 29.795873544303799, -1.439190344827586 ], [ 29.79560405063291, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14507, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.439190344827586 ], [ 29.795873544303799, -1.439459827586207 ], [ 29.796143037974684, -1.439459827586207 ], [ 29.796143037974684, -1.439190344827586 ], [ 29.795873544303799, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14508, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.439190344827586 ], [ 29.796143037974684, -1.439459827586207 ], [ 29.796412531645569, -1.439459827586207 ], [ 29.796412531645569, -1.439190344827586 ], [ 29.796143037974684, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14509, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.439190344827586 ], [ 29.796412531645569, -1.439459827586207 ], [ 29.796682025316457, -1.439459827586207 ], [ 29.796682025316457, -1.439190344827586 ], [ 29.796412531645569, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14510, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.439190344827586 ], [ 29.796682025316457, -1.439459827586207 ], [ 29.796951518987342, -1.439459827586207 ], [ 29.796951518987342, -1.439190344827586 ], [ 29.796682025316457, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14511, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.439190344827586 ], [ 29.796951518987342, -1.439459827586207 ], [ 29.797221012658227, -1.439459827586207 ], [ 29.797221012658227, -1.439190344827586 ], [ 29.796951518987342, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14512, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.439190344827586 ], [ 29.797221012658227, -1.439459827586207 ], [ 29.797490506329115, -1.439459827586207 ], [ 29.797490506329115, -1.439190344827586 ], [ 29.797221012658227, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14513, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.439190344827586 ], [ 29.797490506329115, -1.439459827586207 ], [ 29.799646455696202, -1.439459827586207 ], [ 29.799646455696202, -1.439190344827586 ], [ 29.797490506329115, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14514, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.439190344827586 ], [ 29.799646455696202, -1.439459827586207 ], [ 29.79991594936709, -1.439459827586207 ], [ 29.79991594936709, -1.439190344827586 ], [ 29.799646455696202, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14515, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.439190344827586 ], [ 29.79991594936709, -1.439459827586207 ], [ 29.800185443037975, -1.439459827586207 ], [ 29.800185443037975, -1.439190344827586 ], [ 29.79991594936709, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14516, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.439190344827586 ], [ 29.800185443037975, -1.439459827586207 ], [ 29.80045493670886, -1.439459827586207 ], [ 29.80045493670886, -1.439190344827586 ], [ 29.800185443037975, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14517, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.439190344827586 ], [ 29.80045493670886, -1.439459827586207 ], [ 29.800724430379748, -1.439459827586207 ], [ 29.800724430379748, -1.439190344827586 ], [ 29.80045493670886, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14518, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.439190344827586 ], [ 29.800724430379748, -1.439459827586207 ], [ 29.800993924050633, -1.439459827586207 ], [ 29.800993924050633, -1.439190344827586 ], [ 29.800724430379748, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14519, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.439190344827586 ], [ 29.800993924050633, -1.439459827586207 ], [ 29.801263417721518, -1.439459827586207 ], [ 29.801263417721518, -1.439190344827586 ], [ 29.800993924050633, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14520, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.439190344827586 ], [ 29.801263417721518, -1.439459827586207 ], [ 29.801532911392407, -1.439459827586207 ], [ 29.801532911392407, -1.439190344827586 ], [ 29.801263417721518, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14521, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.439190344827586 ], [ 29.802071898734177, -1.439459827586207 ], [ 29.802341392405065, -1.439459827586207 ], [ 29.802341392405065, -1.439190344827586 ], [ 29.802071898734177, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14522, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.439190344827586 ], [ 29.802341392405065, -1.439459827586207 ], [ 29.80261088607595, -1.439459827586207 ], [ 29.80261088607595, -1.439190344827586 ], [ 29.802341392405065, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14523, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.439190344827586 ], [ 29.80261088607595, -1.439459827586207 ], [ 29.802880379746835, -1.439459827586207 ], [ 29.802880379746835, -1.439190344827586 ], [ 29.80261088607595, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14524, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.439190344827586 ], [ 29.802880379746835, -1.439459827586207 ], [ 29.803149873417723, -1.439459827586207 ], [ 29.803149873417723, -1.439190344827586 ], [ 29.802880379746835, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14525, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.439190344827586 ], [ 29.803149873417723, -1.439459827586207 ], [ 29.803419367088608, -1.439459827586207 ], [ 29.803419367088608, -1.439190344827586 ], [ 29.803149873417723, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14526, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.439190344827586 ], [ 29.803419367088608, -1.439459827586207 ], [ 29.803688860759493, -1.439459827586207 ], [ 29.803688860759493, -1.439190344827586 ], [ 29.803419367088608, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14527, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.439190344827586 ], [ 29.803688860759493, -1.439459827586207 ], [ 29.803958354430382, -1.439459827586207 ], [ 29.803958354430382, -1.439190344827586 ], [ 29.803688860759493, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14528, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.439190344827586 ], [ 29.803958354430382, -1.439459827586207 ], [ 29.804227848101267, -1.439459827586207 ], [ 29.804227848101267, -1.439190344827586 ], [ 29.803958354430382, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14529, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.439190344827586 ], [ 29.804227848101267, -1.439459827586207 ], [ 29.804497341772151, -1.439459827586207 ], [ 29.804497341772151, -1.439190344827586 ], [ 29.804227848101267, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14530, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.439190344827586 ], [ 29.804497341772151, -1.439459827586207 ], [ 29.805036329113925, -1.439459827586207 ], [ 29.805036329113925, -1.439190344827586 ], [ 29.804497341772151, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14531, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.439190344827586 ], [ 29.805844810126583, -1.439459827586207 ], [ 29.806114303797468, -1.439459827586207 ], [ 29.806114303797468, -1.439190344827586 ], [ 29.805844810126583, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14532, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.439190344827586 ], [ 29.806114303797468, -1.439459827586207 ], [ 29.806383797468357, -1.439459827586207 ], [ 29.806383797468357, -1.439190344827586 ], [ 29.806114303797468, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14533, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.439190344827586 ], [ 29.809078734177216, -1.439459827586207 ], [ 29.809348227848101, -1.439459827586207 ], [ 29.809348227848101, -1.439190344827586 ], [ 29.809078734177216, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14534, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.439190344827586 ], [ 29.809348227848101, -1.439459827586207 ], [ 29.809617721518986, -1.439459827586207 ], [ 29.809617721518986, -1.439190344827586 ], [ 29.809348227848101, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14535, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.439190344827586 ], [ 29.809617721518986, -1.439459827586207 ], [ 29.809887215189875, -1.439459827586207 ], [ 29.809887215189875, -1.439190344827586 ], [ 29.809617721518986, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14536, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.439190344827586 ], [ 29.809887215189875, -1.439459827586207 ], [ 29.81015670886076, -1.439459827586207 ], [ 29.81015670886076, -1.439190344827586 ], [ 29.809887215189875, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14537, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.439190344827586 ], [ 29.81015670886076, -1.439459827586207 ], [ 29.810426202531644, -1.439459827586207 ], [ 29.810426202531644, -1.439190344827586 ], [ 29.81015670886076, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14538, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.439190344827586 ], [ 29.810426202531644, -1.439459827586207 ], [ 29.810965189873418, -1.439459827586207 ], [ 29.810965189873418, -1.439190344827586 ], [ 29.810426202531644, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14540, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.437034482758621 ], [ 29.758952911392406, -1.439729310344828 ], [ 29.759222405063291, -1.439729310344828 ], [ 29.759222405063291, -1.437034482758621 ], [ 29.758952911392406, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14541, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.437034482758621 ], [ 29.775122531645568, -1.44053775862069 ], [ 29.775392025316457, -1.44053775862069 ], [ 29.775392025316457, -1.437034482758621 ], [ 29.775122531645568, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14542, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.437034482758621 ], [ 29.77647, -1.44053775862069 ], [ 29.776739493670885, -1.44053775862069 ], [ 29.776739493670885, -1.437034482758621 ], [ 29.77647, -1.437034482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14543, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.437303965517241 ], [ 29.758413924050632, -1.439729310344828 ], [ 29.758683417721517, -1.439729310344828 ], [ 29.758683417721517, -1.437303965517241 ], [ 29.758413924050632, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14544, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.437303965517241 ], [ 29.758683417721517, -1.439729310344828 ], [ 29.758952911392406, -1.439729310344828 ], [ 29.758952911392406, -1.437303965517241 ], [ 29.758683417721517, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14545, "El": 136 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.437303965517241 ], [ 29.774853037974683, -1.439998793103448 ], [ 29.775122531645568, -1.439998793103448 ], [ 29.775122531645568, -1.437303965517241 ], [ 29.774853037974683, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14546, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.437303965517241 ], [ 29.775661518987341, -1.440268275862069 ], [ 29.775931012658226, -1.440268275862069 ], [ 29.775931012658226, -1.437303965517241 ], [ 29.775661518987341, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14547, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.437303965517241 ], [ 29.776200506329115, -1.440268275862069 ], [ 29.77647, -1.440268275862069 ], [ 29.77647, -1.437303965517241 ], [ 29.776200506329115, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14548, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.437303965517241 ], [ 29.777008987341773, -1.440268275862069 ], [ 29.777278481012658, -1.440268275862069 ], [ 29.777278481012658, -1.437303965517241 ], [ 29.777008987341773, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14549, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.437303965517241 ], [ 29.777547974683543, -1.440268275862069 ], [ 29.777817468354431, -1.440268275862069 ], [ 29.777817468354431, -1.437303965517241 ], [ 29.777547974683543, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14550, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.437303965517241 ], [ 29.777817468354431, -1.440268275862069 ], [ 29.778086962025316, -1.440268275862069 ], [ 29.778086962025316, -1.437303965517241 ], [ 29.777817468354431, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14551, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.437303965517241 ], [ 29.778356455696201, -1.440268275862069 ], [ 29.77862594936709, -1.440268275862069 ], [ 29.77862594936709, -1.437303965517241 ], [ 29.778356455696201, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14552, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.437303965517241 ], [ 29.77916493670886, -1.440268275862069 ], [ 29.779434430379748, -1.440268275862069 ], [ 29.779434430379748, -1.437303965517241 ], [ 29.77916493670886, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14553, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.437303965517241 ], [ 29.779703924050633, -1.440268275862069 ], [ 29.779973417721518, -1.440268275862069 ], [ 29.779973417721518, -1.437303965517241 ], [ 29.779703924050633, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14554, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.437303965517241 ], [ 29.780512405063291, -1.440268275862069 ], [ 29.780781898734176, -1.440268275862069 ], [ 29.780781898734176, -1.437303965517241 ], [ 29.780512405063291, -1.437303965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14555, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.437573448275862 ], [ 29.757605443037974, -1.439729310344828 ], [ 29.757874936708859, -1.439729310344828 ], [ 29.757874936708859, -1.437573448275862 ], [ 29.757605443037974, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14556, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.437573448275862 ], [ 29.757874936708859, -1.439998793103448 ], [ 29.758144430379748, -1.439998793103448 ], [ 29.758144430379748, -1.437573448275862 ], [ 29.757874936708859, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14557, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.437573448275862 ], [ 29.758144430379748, -1.439998793103448 ], [ 29.758413924050632, -1.439998793103448 ], [ 29.758413924050632, -1.437573448275862 ], [ 29.758144430379748, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14558, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.437573448275862 ], [ 29.771349620253165, -1.439729310344828 ], [ 29.77161911392405, -1.439729310344828 ], [ 29.77161911392405, -1.437573448275862 ], [ 29.771349620253165, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14559, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.437573448275862 ], [ 29.775392025316457, -1.439998793103448 ], [ 29.775661518987341, -1.439998793103448 ], [ 29.775661518987341, -1.437573448275862 ], [ 29.775392025316457, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14560, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.437573448275862 ], [ 29.776739493670885, -1.439998793103448 ], [ 29.777008987341773, -1.439998793103448 ], [ 29.777008987341773, -1.437573448275862 ], [ 29.776739493670885, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14561, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.437573448275862 ], [ 29.778086962025316, -1.439998793103448 ], [ 29.778356455696201, -1.439998793103448 ], [ 29.778356455696201, -1.437573448275862 ], [ 29.778086962025316, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14562, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.437573448275862 ], [ 29.778895443037975, -1.439998793103448 ], [ 29.77916493670886, -1.439998793103448 ], [ 29.77916493670886, -1.437573448275862 ], [ 29.778895443037975, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14563, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.437573448275862 ], [ 29.779434430379748, -1.439998793103448 ], [ 29.779703924050633, -1.439998793103448 ], [ 29.779703924050633, -1.437573448275862 ], [ 29.779434430379748, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14564, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.437573448275862 ], [ 29.780242911392406, -1.439998793103448 ], [ 29.780512405063291, -1.439998793103448 ], [ 29.780512405063291, -1.437573448275862 ], [ 29.780242911392406, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14565, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.437573448275862 ], [ 29.781051392405065, -1.439998793103448 ], [ 29.78132088607595, -1.439998793103448 ], [ 29.78132088607595, -1.437573448275862 ], [ 29.781051392405065, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14566, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.437573448275862 ], [ 29.781590379746834, -1.439998793103448 ], [ 29.781859873417723, -1.439998793103448 ], [ 29.781859873417723, -1.437573448275862 ], [ 29.781590379746834, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14567, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.437573448275862 ], [ 29.781859873417723, -1.439998793103448 ], [ 29.782129367088608, -1.439998793103448 ], [ 29.782129367088608, -1.437573448275862 ], [ 29.781859873417723, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14568, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.437573448275862 ], [ 29.782398860759493, -1.439998793103448 ], [ 29.782668354430381, -1.439998793103448 ], [ 29.782668354430381, -1.437573448275862 ], [ 29.782398860759493, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14569, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.437573448275862 ], [ 29.783207341772151, -1.439998793103448 ], [ 29.783476835443039, -1.439998793103448 ], [ 29.783476835443039, -1.437573448275862 ], [ 29.783207341772151, -1.437573448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14570, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.437842931034483 ], [ 29.755988481012658, -1.439729310344828 ], [ 29.756257974683542, -1.439729310344828 ], [ 29.756257974683542, -1.437842931034483 ], [ 29.755988481012658, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14571, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.437842931034483 ], [ 29.757066455696201, -1.439998793103448 ], [ 29.757335949367089, -1.439998793103448 ], [ 29.757335949367089, -1.437842931034483 ], [ 29.757066455696201, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14572, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.437842931034483 ], [ 29.757335949367089, -1.439998793103448 ], [ 29.757605443037974, -1.439998793103448 ], [ 29.757605443037974, -1.437842931034483 ], [ 29.757335949367089, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14573, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.437842931034483 ], [ 29.767307215189874, -1.440268275862069 ], [ 29.767576708860759, -1.440268275862069 ], [ 29.767576708860759, -1.437842931034483 ], [ 29.767307215189874, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14574, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.437842931034483 ], [ 29.768115696202532, -1.440268275862069 ], [ 29.768385189873417, -1.440268275862069 ], [ 29.768385189873417, -1.437842931034483 ], [ 29.768115696202532, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14575, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.437842931034483 ], [ 29.768654683544302, -1.440268275862069 ], [ 29.76892417721519, -1.440268275862069 ], [ 29.76892417721519, -1.437842931034483 ], [ 29.768654683544302, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14576, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.437842931034483 ], [ 29.76946316455696, -1.440268275862069 ], [ 29.769732658227849, -1.440268275862069 ], [ 29.769732658227849, -1.437842931034483 ], [ 29.76946316455696, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14577, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.437842931034483 ], [ 29.770002151898733, -1.440268275862069 ], [ 29.770271645569618, -1.440268275862069 ], [ 29.770271645569618, -1.437842931034483 ], [ 29.770002151898733, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14578, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.437842931034483 ], [ 29.770271645569618, -1.44053775862069 ], [ 29.770541139240507, -1.44053775862069 ], [ 29.770541139240507, -1.437842931034483 ], [ 29.770271645569618, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14579, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.437842931034483 ], [ 29.770810632911392, -1.439998793103448 ], [ 29.771080126582277, -1.439998793103448 ], [ 29.771080126582277, -1.437842931034483 ], [ 29.770810632911392, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14580, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.437842931034483 ], [ 29.77161911392405, -1.439729310344828 ], [ 29.771888607594935, -1.439729310344828 ], [ 29.771888607594935, -1.437842931034483 ], [ 29.77161911392405, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14581, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.437842931034483 ], [ 29.775931012658226, -1.439729310344828 ], [ 29.776200506329115, -1.439729310344828 ], [ 29.776200506329115, -1.437842931034483 ], [ 29.775931012658226, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14582, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.437842931034483 ], [ 29.777278481012658, -1.439729310344828 ], [ 29.777547974683543, -1.439729310344828 ], [ 29.777547974683543, -1.437842931034483 ], [ 29.777278481012658, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14583, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.437842931034483 ], [ 29.77862594936709, -1.439729310344828 ], [ 29.778895443037975, -1.439729310344828 ], [ 29.778895443037975, -1.437842931034483 ], [ 29.77862594936709, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14584, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.437842931034483 ], [ 29.779973417721518, -1.439729310344828 ], [ 29.780242911392406, -1.439729310344828 ], [ 29.780242911392406, -1.437842931034483 ], [ 29.779973417721518, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14585, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.437842931034483 ], [ 29.780781898734176, -1.439729310344828 ], [ 29.781051392405065, -1.439729310344828 ], [ 29.781051392405065, -1.437842931034483 ], [ 29.780781898734176, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14586, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.437842931034483 ], [ 29.78132088607595, -1.439729310344828 ], [ 29.781590379746834, -1.439729310344828 ], [ 29.781590379746834, -1.437842931034483 ], [ 29.78132088607595, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14587, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.437842931034483 ], [ 29.782129367088608, -1.439729310344828 ], [ 29.782398860759493, -1.439729310344828 ], [ 29.782398860759493, -1.437842931034483 ], [ 29.782129367088608, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14588, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.437842931034483 ], [ 29.782937848101266, -1.439729310344828 ], [ 29.783207341772151, -1.439729310344828 ], [ 29.783207341772151, -1.437842931034483 ], [ 29.782937848101266, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14589, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.437842931034483 ], [ 29.783476835443039, -1.439729310344828 ], [ 29.784015822784809, -1.439729310344828 ], [ 29.784015822784809, -1.437842931034483 ], [ 29.783476835443039, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14590, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.437842931034483 ], [ 29.784015822784809, -1.439729310344828 ], [ 29.784285316455698, -1.439729310344828 ], [ 29.784285316455698, -1.437842931034483 ], [ 29.784015822784809, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14591, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.437842931034483 ], [ 29.784554810126583, -1.439729310344828 ], [ 29.784824303797468, -1.439729310344828 ], [ 29.784824303797468, -1.437842931034483 ], [ 29.784554810126583, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14592, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.437842931034483 ], [ 29.784824303797468, -1.439729310344828 ], [ 29.785093797468356, -1.439729310344828 ], [ 29.785093797468356, -1.437842931034483 ], [ 29.784824303797468, -1.437842931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14593, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.438112413793104 ], [ 29.755449493670884, -1.439998793103448 ], [ 29.755718987341773, -1.439998793103448 ], [ 29.755718987341773, -1.438112413793104 ], [ 29.755449493670884, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14594, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.438112413793104 ], [ 29.755718987341773, -1.440268275862069 ], [ 29.755988481012658, -1.440268275862069 ], [ 29.755988481012658, -1.438112413793104 ], [ 29.755718987341773, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14595, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.438112413793104 ], [ 29.756257974683542, -1.439998793103448 ], [ 29.756796962025316, -1.439998793103448 ], [ 29.756796962025316, -1.438112413793104 ], [ 29.756257974683542, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14596, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.438112413793104 ], [ 29.756796962025316, -1.440268275862069 ], [ 29.757066455696201, -1.440268275862069 ], [ 29.757066455696201, -1.438112413793104 ], [ 29.756796962025316, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14597, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.438112413793104 ], [ 29.764342784810125, -1.439998793103448 ], [ 29.764612278481014, -1.439998793103448 ], [ 29.764612278481014, -1.438112413793104 ], [ 29.764342784810125, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14598, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.438112413793104 ], [ 29.765151265822784, -1.439998793103448 ], [ 29.765420759493672, -1.439998793103448 ], [ 29.765420759493672, -1.438112413793104 ], [ 29.765151265822784, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14599, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.438112413793104 ], [ 29.765959746835442, -1.439998793103448 ], [ 29.766229240506327, -1.439998793103448 ], [ 29.766229240506327, -1.438112413793104 ], [ 29.765959746835442, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14600, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.438112413793104 ], [ 29.766229240506327, -1.439998793103448 ], [ 29.766498734177215, -1.439998793103448 ], [ 29.766498734177215, -1.438112413793104 ], [ 29.766229240506327, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14601, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.438112413793104 ], [ 29.7667682278481, -1.439998793103448 ], [ 29.767037721518985, -1.439998793103448 ], [ 29.767037721518985, -1.438112413793104 ], [ 29.7667682278481, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14602, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.438112413793104 ], [ 29.767576708860759, -1.439998793103448 ], [ 29.767846202531643, -1.439998793103448 ], [ 29.767846202531643, -1.438112413793104 ], [ 29.767576708860759, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14603, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.438112413793104 ], [ 29.768385189873417, -1.439998793103448 ], [ 29.768654683544302, -1.439998793103448 ], [ 29.768654683544302, -1.438112413793104 ], [ 29.768385189873417, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14604, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.438112413793104 ], [ 29.76892417721519, -1.439998793103448 ], [ 29.769193670886075, -1.439998793103448 ], [ 29.769193670886075, -1.438112413793104 ], [ 29.76892417721519, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14605, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.438112413793104 ], [ 29.769732658227849, -1.439998793103448 ], [ 29.770002151898733, -1.439998793103448 ], [ 29.770002151898733, -1.438112413793104 ], [ 29.769732658227849, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14606, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.438112413793104 ], [ 29.771080126582277, -1.439998793103448 ], [ 29.771349620253165, -1.439998793103448 ], [ 29.771349620253165, -1.438112413793104 ], [ 29.771080126582277, -1.438112413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14607, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.438381896551724 ], [ 29.754910506329114, -1.440268275862069 ], [ 29.75518, -1.440268275862069 ], [ 29.75518, -1.438381896551724 ], [ 29.754910506329114, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14608, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.438381896551724 ], [ 29.75518, -1.440268275862069 ], [ 29.755449493670884, -1.440268275862069 ], [ 29.755449493670884, -1.438381896551724 ], [ 29.75518, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14609, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.438381896551724 ], [ 29.762995316455697, -1.439729310344828 ], [ 29.763264810126582, -1.439729310344828 ], [ 29.763264810126582, -1.438381896551724 ], [ 29.762995316455697, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14610, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.438381896551724 ], [ 29.763264810126582, -1.439729310344828 ], [ 29.763534303797467, -1.439729310344828 ], [ 29.763534303797467, -1.438381896551724 ], [ 29.763264810126582, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14611, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.438381896551724 ], [ 29.763803797468356, -1.439729310344828 ], [ 29.76407329113924, -1.439729310344828 ], [ 29.76407329113924, -1.438381896551724 ], [ 29.763803797468356, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14612, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.438381896551724 ], [ 29.76407329113924, -1.439729310344828 ], [ 29.764342784810125, -1.439729310344828 ], [ 29.764342784810125, -1.438381896551724 ], [ 29.76407329113924, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14613, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.438381896551724 ], [ 29.764612278481014, -1.439729310344828 ], [ 29.764881772151899, -1.439729310344828 ], [ 29.764881772151899, -1.438381896551724 ], [ 29.764612278481014, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14614, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.438381896551724 ], [ 29.765420759493672, -1.439729310344828 ], [ 29.765959746835442, -1.439729310344828 ], [ 29.765959746835442, -1.438381896551724 ], [ 29.765420759493672, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14615, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.438381896551724 ], [ 29.766498734177215, -1.439729310344828 ], [ 29.7667682278481, -1.439729310344828 ], [ 29.7667682278481, -1.438381896551724 ], [ 29.766498734177215, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14616, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.438381896551724 ], [ 29.767037721518985, -1.439729310344828 ], [ 29.767307215189874, -1.439729310344828 ], [ 29.767307215189874, -1.438381896551724 ], [ 29.767037721518985, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14617, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.438381896551724 ], [ 29.767846202531643, -1.439729310344828 ], [ 29.768115696202532, -1.439729310344828 ], [ 29.768115696202532, -1.438381896551724 ], [ 29.767846202531643, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14618, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.438381896551724 ], [ 29.769193670886075, -1.439729310344828 ], [ 29.76946316455696, -1.439729310344828 ], [ 29.76946316455696, -1.438381896551724 ], [ 29.769193670886075, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14619, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.438381896551724 ], [ 29.770541139240507, -1.439729310344828 ], [ 29.770810632911392, -1.439729310344828 ], [ 29.770810632911392, -1.438381896551724 ], [ 29.770541139240507, -1.438381896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14620, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.438651379310345 ], [ 29.754641012658226, -1.439998793103448 ], [ 29.754910506329114, -1.439998793103448 ], [ 29.754910506329114, -1.438651379310345 ], [ 29.754641012658226, -1.438651379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14621, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.743861265822783, -1.438920862068966 ], [ 29.743861265822783, -1.439729310344828 ], [ 29.744130759493672, -1.439729310344828 ], [ 29.744130759493672, -1.438920862068966 ], [ 29.743861265822783, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14622, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.438920862068966 ], [ 29.750598607594934, -1.439190344827586 ], [ 29.754371518987341, -1.439190344827586 ], [ 29.754371518987341, -1.440268275862069 ], [ 29.754641012658226, -1.440268275862069 ], [ 29.754641012658226, -1.438920862068966 ], [ 29.750598607594934, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14623, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.438920862068966 ], [ 29.811504177215191, -1.439729310344828 ], [ 29.811773670886076, -1.439729310344828 ], [ 29.811773670886076, -1.438920862068966 ], [ 29.811504177215191, -1.438920862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14624, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.439190344827586 ], [ 29.750598607594934, -1.439729310344828 ], [ 29.750868101265823, -1.439729310344828 ], [ 29.750868101265823, -1.439190344827586 ], [ 29.750598607594934, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14625, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.439190344827586 ], [ 29.750868101265823, -1.439459827586207 ], [ 29.754102025316456, -1.439459827586207 ], [ 29.754102025316456, -1.439998793103448 ], [ 29.754371518987341, -1.439998793103448 ], [ 29.754371518987341, -1.439190344827586 ], [ 29.750868101265823, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14626, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.439190344827586 ], [ 29.790214177215191, -1.439729310344828 ], [ 29.790483670886076, -1.439729310344828 ], [ 29.790483670886076, -1.439190344827586 ], [ 29.790214177215191, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14627, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.439190344827586 ], [ 29.790483670886076, -1.439729310344828 ], [ 29.790753164556961, -1.439729310344828 ], [ 29.790753164556961, -1.439190344827586 ], [ 29.790483670886076, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14628, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.439190344827586 ], [ 29.793448101265824, -1.439729310344828 ], [ 29.793717594936709, -1.439729310344828 ], [ 29.793717594936709, -1.439190344827586 ], [ 29.793448101265824, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14629, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.439190344827586 ], [ 29.805036329113925, -1.439459827586207 ], [ 29.80530582278481, -1.439459827586207 ], [ 29.80530582278481, -1.439729310344828 ], [ 29.805575316455698, -1.439729310344828 ], [ 29.805575316455698, -1.439459827586207 ], [ 29.805844810126583, -1.439459827586207 ], [ 29.805844810126583, -1.439190344827586 ], [ 29.805036329113925, -1.439190344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14630, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.439459827586207 ], [ 29.744130759493672, -1.439729310344828 ], [ 29.744400253164557, -1.439729310344828 ], [ 29.744400253164557, -1.439459827586207 ], [ 29.744130759493672, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14631, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.439459827586207 ], [ 29.744400253164557, -1.439729310344828 ], [ 29.744669746835442, -1.439729310344828 ], [ 29.744669746835442, -1.439459827586207 ], [ 29.744400253164557, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14632, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.439459827586207 ], [ 29.744669746835442, -1.439729310344828 ], [ 29.744939240506326, -1.439729310344828 ], [ 29.744939240506326, -1.439459827586207 ], [ 29.744669746835442, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14633, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.439459827586207 ], [ 29.744939240506326, -1.439729310344828 ], [ 29.745208734177215, -1.439729310344828 ], [ 29.745208734177215, -1.439459827586207 ], [ 29.744939240506326, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14634, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.439459827586207 ], [ 29.745208734177215, -1.439729310344828 ], [ 29.7454782278481, -1.439729310344828 ], [ 29.7454782278481, -1.439459827586207 ], [ 29.745208734177215, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14635, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.439459827586207 ], [ 29.7454782278481, -1.439729310344828 ], [ 29.745747721518985, -1.439729310344828 ], [ 29.745747721518985, -1.439459827586207 ], [ 29.7454782278481, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14636, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.439459827586207 ], [ 29.745747721518985, -1.439729310344828 ], [ 29.746017215189873, -1.439729310344828 ], [ 29.746017215189873, -1.439459827586207 ], [ 29.745747721518985, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14637, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.439459827586207 ], [ 29.746017215189873, -1.439729310344828 ], [ 29.746286708860758, -1.439729310344828 ], [ 29.746286708860758, -1.439459827586207 ], [ 29.746017215189873, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14638, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.439459827586207 ], [ 29.746286708860758, -1.439729310344828 ], [ 29.746556202531643, -1.439729310344828 ], [ 29.746556202531643, -1.439459827586207 ], [ 29.746286708860758, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14639, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.439459827586207 ], [ 29.746556202531643, -1.439729310344828 ], [ 29.746825696202531, -1.439729310344828 ], [ 29.746825696202531, -1.439459827586207 ], [ 29.746556202531643, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14640, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.439459827586207 ], [ 29.746825696202531, -1.439729310344828 ], [ 29.747095189873416, -1.439729310344828 ], [ 29.747095189873416, -1.439459827586207 ], [ 29.746825696202531, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14641, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.439459827586207 ], [ 29.747095189873416, -1.439729310344828 ], [ 29.747364683544301, -1.439729310344828 ], [ 29.747364683544301, -1.439459827586207 ], [ 29.747095189873416, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14642, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.439459827586207 ], [ 29.747364683544301, -1.439729310344828 ], [ 29.747903670886075, -1.439729310344828 ], [ 29.747903670886075, -1.439459827586207 ], [ 29.747364683544301, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14643, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.439459827586207 ], [ 29.747903670886075, -1.439729310344828 ], [ 29.74817316455696, -1.439729310344828 ], [ 29.74817316455696, -1.439459827586207 ], [ 29.747903670886075, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14644, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.439459827586207 ], [ 29.74817316455696, -1.439729310344828 ], [ 29.748442658227848, -1.439729310344828 ], [ 29.748442658227848, -1.439459827586207 ], [ 29.74817316455696, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14645, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.439459827586207 ], [ 29.748442658227848, -1.439729310344828 ], [ 29.748712151898733, -1.439729310344828 ], [ 29.748712151898733, -1.439459827586207 ], [ 29.748442658227848, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14646, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.439459827586207 ], [ 29.748712151898733, -1.439729310344828 ], [ 29.748981645569618, -1.439729310344828 ], [ 29.748981645569618, -1.439459827586207 ], [ 29.748712151898733, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14647, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.439459827586207 ], [ 29.748981645569618, -1.439729310344828 ], [ 29.749251139240506, -1.439729310344828 ], [ 29.749251139240506, -1.439459827586207 ], [ 29.748981645569618, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14648, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.439459827586207 ], [ 29.749251139240506, -1.439729310344828 ], [ 29.749520632911391, -1.439729310344828 ], [ 29.749520632911391, -1.439459827586207 ], [ 29.749251139240506, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14649, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.439459827586207 ], [ 29.749520632911391, -1.439729310344828 ], [ 29.749790126582276, -1.439729310344828 ], [ 29.749790126582276, -1.439459827586207 ], [ 29.749520632911391, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14650, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.439459827586207 ], [ 29.749790126582276, -1.439729310344828 ], [ 29.750059620253165, -1.439729310344828 ], [ 29.750059620253165, -1.439459827586207 ], [ 29.749790126582276, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14651, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.439459827586207 ], [ 29.750059620253165, -1.439729310344828 ], [ 29.75032911392405, -1.439729310344828 ], [ 29.75032911392405, -1.439459827586207 ], [ 29.750059620253165, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14652, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.439459827586207 ], [ 29.75032911392405, -1.439729310344828 ], [ 29.750598607594934, -1.439729310344828 ], [ 29.750598607594934, -1.439459827586207 ], [ 29.75032911392405, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14653, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.439459827586207 ], [ 29.750868101265823, -1.439729310344828 ], [ 29.753832531645568, -1.439729310344828 ], [ 29.753832531645568, -1.44053775862069 ], [ 29.754102025316456, -1.44053775862069 ], [ 29.754102025316456, -1.439459827586207 ], [ 29.750868101265823, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14654, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.439459827586207 ], [ 29.761647848101266, -1.439729310344828 ], [ 29.762186835443039, -1.439729310344828 ], [ 29.762186835443039, -1.439459827586207 ], [ 29.761647848101266, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14655, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.439459827586207 ], [ 29.762186835443039, -1.439729310344828 ], [ 29.762456329113924, -1.439729310344828 ], [ 29.762456329113924, -1.439459827586207 ], [ 29.762186835443039, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14656, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.439459827586207 ], [ 29.762456329113924, -1.439729310344828 ], [ 29.762725822784809, -1.439729310344828 ], [ 29.762725822784809, -1.439459827586207 ], [ 29.762456329113924, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14657, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.439459827586207 ], [ 29.762725822784809, -1.439729310344828 ], [ 29.762995316455697, -1.439729310344828 ], [ 29.762995316455697, -1.439459827586207 ], [ 29.762725822784809, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14658, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.439459827586207 ], [ 29.763534303797467, -1.439998793103448 ], [ 29.763803797468356, -1.439998793103448 ], [ 29.763803797468356, -1.439459827586207 ], [ 29.763534303797467, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14659, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.439459827586207 ], [ 29.764881772151899, -1.439998793103448 ], [ 29.765151265822784, -1.439998793103448 ], [ 29.765151265822784, -1.439459827586207 ], [ 29.764881772151899, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14660, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.439459827586207 ], [ 29.771888607594935, -1.439729310344828 ], [ 29.772158101265823, -1.439729310344828 ], [ 29.772158101265823, -1.439459827586207 ], [ 29.771888607594935, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14661, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.439459827586207 ], [ 29.772158101265823, -1.439729310344828 ], [ 29.772427594936708, -1.439729310344828 ], [ 29.772427594936708, -1.439459827586207 ], [ 29.772158101265823, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14662, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.439459827586207 ], [ 29.772427594936708, -1.439729310344828 ], [ 29.772697088607593, -1.439729310344828 ], [ 29.772697088607593, -1.439459827586207 ], [ 29.772427594936708, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14663, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.439459827586207 ], [ 29.772697088607593, -1.439729310344828 ], [ 29.772966582278482, -1.439729310344828 ], [ 29.772966582278482, -1.439459827586207 ], [ 29.772697088607593, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14664, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.439459827586207 ], [ 29.772966582278482, -1.439729310344828 ], [ 29.773236075949367, -1.439729310344828 ], [ 29.773236075949367, -1.439459827586207 ], [ 29.772966582278482, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14665, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.439459827586207 ], [ 29.773236075949367, -1.439729310344828 ], [ 29.773505569620252, -1.439729310344828 ], [ 29.773505569620252, -1.439459827586207 ], [ 29.773236075949367, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14666, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.439459827586207 ], [ 29.773505569620252, -1.439729310344828 ], [ 29.77377506329114, -1.439729310344828 ], [ 29.77377506329114, -1.439459827586207 ], [ 29.773505569620252, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14667, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.439459827586207 ], [ 29.77377506329114, -1.439729310344828 ], [ 29.774044556962025, -1.439729310344828 ], [ 29.774044556962025, -1.439459827586207 ], [ 29.77377506329114, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14668, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.439459827586207 ], [ 29.774044556962025, -1.439729310344828 ], [ 29.77431405063291, -1.439729310344828 ], [ 29.77431405063291, -1.439459827586207 ], [ 29.774044556962025, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14669, "El": 138 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.439459827586207 ], [ 29.77431405063291, -1.439729310344828 ], [ 29.774853037974683, -1.439729310344828 ], [ 29.774853037974683, -1.439459827586207 ], [ 29.77431405063291, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14670, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.439459827586207 ], [ 29.782668354430381, -1.439998793103448 ], [ 29.782937848101266, -1.439998793103448 ], [ 29.782937848101266, -1.439459827586207 ], [ 29.782668354430381, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14671, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.439459827586207 ], [ 29.784285316455698, -1.439998793103448 ], [ 29.784554810126583, -1.439998793103448 ], [ 29.784554810126583, -1.439459827586207 ], [ 29.784285316455698, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14672, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.439459827586207 ], [ 29.785093797468356, -1.439729310344828 ], [ 29.785363291139241, -1.439729310344828 ], [ 29.785363291139241, -1.439459827586207 ], [ 29.785093797468356, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14673, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.439459827586207 ], [ 29.785363291139241, -1.439729310344828 ], [ 29.785632784810126, -1.439729310344828 ], [ 29.785632784810126, -1.439459827586207 ], [ 29.785363291139241, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14674, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.439459827586207 ], [ 29.785632784810126, -1.439729310344828 ], [ 29.785902278481014, -1.439729310344828 ], [ 29.785902278481014, -1.439459827586207 ], [ 29.785632784810126, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14675, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.439459827586207 ], [ 29.785902278481014, -1.439729310344828 ], [ 29.786171772151899, -1.439729310344828 ], [ 29.786171772151899, -1.44053775862069 ], [ 29.786441265822784, -1.44053775862069 ], [ 29.786441265822784, -1.439459827586207 ], [ 29.785902278481014, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14676, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.439459827586207 ], [ 29.789136202531644, -1.44053775862069 ], [ 29.789405696202532, -1.44053775862069 ], [ 29.789405696202532, -1.439459827586207 ], [ 29.789136202531644, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14677, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.439459827586207 ], [ 29.789405696202532, -1.439729310344828 ], [ 29.789675189873417, -1.439729310344828 ], [ 29.789675189873417, -1.439459827586207 ], [ 29.789405696202532, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14678, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.439459827586207 ], [ 29.789675189873417, -1.439729310344828 ], [ 29.789944683544302, -1.439729310344828 ], [ 29.789944683544302, -1.439459827586207 ], [ 29.789675189873417, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14679, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.439459827586207 ], [ 29.789944683544302, -1.439729310344828 ], [ 29.790214177215191, -1.439729310344828 ], [ 29.790214177215191, -1.439459827586207 ], [ 29.789944683544302, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14680, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.439459827586207 ], [ 29.790753164556961, -1.440268275862069 ], [ 29.791022658227849, -1.440268275862069 ], [ 29.791022658227849, -1.439459827586207 ], [ 29.790753164556961, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14681, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.439459827586207 ], [ 29.791022658227849, -1.439729310344828 ], [ 29.791292151898734, -1.439729310344828 ], [ 29.791292151898734, -1.439459827586207 ], [ 29.791022658227849, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14682, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.439459827586207 ], [ 29.791292151898734, -1.439729310344828 ], [ 29.791561645569619, -1.439729310344828 ], [ 29.791561645569619, -1.439459827586207 ], [ 29.791292151898734, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14683, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.439459827586207 ], [ 29.791561645569619, -1.439729310344828 ], [ 29.791831139240507, -1.439729310344828 ], [ 29.791831139240507, -1.439459827586207 ], [ 29.791561645569619, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14684, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.439459827586207 ], [ 29.791831139240507, -1.439729310344828 ], [ 29.792100632911392, -1.439729310344828 ], [ 29.792100632911392, -1.439459827586207 ], [ 29.791831139240507, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14685, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.439459827586207 ], [ 29.792100632911392, -1.439729310344828 ], [ 29.792370126582277, -1.439729310344828 ], [ 29.792370126582277, -1.439459827586207 ], [ 29.792100632911392, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14686, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.439459827586207 ], [ 29.792370126582277, -1.439729310344828 ], [ 29.792909113924051, -1.439729310344828 ], [ 29.792909113924051, -1.439459827586207 ], [ 29.792370126582277, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14687, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.439459827586207 ], [ 29.792909113924051, -1.439729310344828 ], [ 29.793178607594935, -1.439729310344828 ], [ 29.793178607594935, -1.439459827586207 ], [ 29.792909113924051, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14688, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.439459827586207 ], [ 29.793178607594935, -1.439729310344828 ], [ 29.793448101265824, -1.439729310344828 ], [ 29.793448101265824, -1.439459827586207 ], [ 29.793178607594935, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14689, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.439459827586207 ], [ 29.793717594936709, -1.439998793103448 ], [ 29.793987088607594, -1.439998793103448 ], [ 29.793987088607594, -1.439459827586207 ], [ 29.793717594936709, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14690, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.439459827586207 ], [ 29.793987088607594, -1.439998793103448 ], [ 29.794256582278482, -1.439998793103448 ], [ 29.794256582278482, -1.439459827586207 ], [ 29.793987088607594, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14691, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.439459827586207 ], [ 29.794256582278482, -1.439729310344828 ], [ 29.794526075949367, -1.439729310344828 ], [ 29.794526075949367, -1.439459827586207 ], [ 29.794256582278482, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14692, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.439459827586207 ], [ 29.794526075949367, -1.439729310344828 ], [ 29.794795569620252, -1.439729310344828 ], [ 29.794795569620252, -1.439459827586207 ], [ 29.794526075949367, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14693, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.439459827586207 ], [ 29.794795569620252, -1.439729310344828 ], [ 29.79506506329114, -1.439729310344828 ], [ 29.79506506329114, -1.439459827586207 ], [ 29.794795569620252, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14694, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.439459827586207 ], [ 29.79506506329114, -1.439729310344828 ], [ 29.795334556962025, -1.439729310344828 ], [ 29.795334556962025, -1.439459827586207 ], [ 29.79506506329114, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14695, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.439459827586207 ], [ 29.795334556962025, -1.439729310344828 ], [ 29.79560405063291, -1.439729310344828 ], [ 29.79560405063291, -1.439459827586207 ], [ 29.795334556962025, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14696, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.439459827586207 ], [ 29.79560405063291, -1.439729310344828 ], [ 29.795873544303799, -1.439729310344828 ], [ 29.795873544303799, -1.439459827586207 ], [ 29.79560405063291, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14697, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.439459827586207 ], [ 29.795873544303799, -1.439729310344828 ], [ 29.796143037974684, -1.439729310344828 ], [ 29.796143037974684, -1.439459827586207 ], [ 29.795873544303799, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14698, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.439459827586207 ], [ 29.796143037974684, -1.439729310344828 ], [ 29.796412531645569, -1.439729310344828 ], [ 29.796412531645569, -1.439459827586207 ], [ 29.796143037974684, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14699, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.439459827586207 ], [ 29.796412531645569, -1.439729310344828 ], [ 29.796682025316457, -1.439729310344828 ], [ 29.796682025316457, -1.439459827586207 ], [ 29.796412531645569, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14700, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.439459827586207 ], [ 29.796682025316457, -1.439729310344828 ], [ 29.796951518987342, -1.439729310344828 ], [ 29.796951518987342, -1.439459827586207 ], [ 29.796682025316457, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14701, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.439459827586207 ], [ 29.796951518987342, -1.439729310344828 ], [ 29.797221012658227, -1.439729310344828 ], [ 29.797221012658227, -1.439459827586207 ], [ 29.796951518987342, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14702, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.439459827586207 ], [ 29.797221012658227, -1.439729310344828 ], [ 29.797490506329115, -1.439729310344828 ], [ 29.797490506329115, -1.439459827586207 ], [ 29.797221012658227, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14703, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.439459827586207 ], [ 29.797490506329115, -1.439729310344828 ], [ 29.79776, -1.439729310344828 ], [ 29.79776, -1.439459827586207 ], [ 29.797490506329115, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14704, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.439459827586207 ], [ 29.79776, -1.439729310344828 ], [ 29.799376962025317, -1.439729310344828 ], [ 29.799376962025317, -1.439459827586207 ], [ 29.79776, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14705, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.439459827586207 ], [ 29.799376962025317, -1.439729310344828 ], [ 29.799646455696202, -1.439729310344828 ], [ 29.799646455696202, -1.439459827586207 ], [ 29.799376962025317, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14706, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.439459827586207 ], [ 29.799646455696202, -1.439729310344828 ], [ 29.79991594936709, -1.439729310344828 ], [ 29.79991594936709, -1.439459827586207 ], [ 29.799646455696202, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14707, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.439459827586207 ], [ 29.79991594936709, -1.439729310344828 ], [ 29.800185443037975, -1.439729310344828 ], [ 29.800185443037975, -1.439459827586207 ], [ 29.79991594936709, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14708, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.439459827586207 ], [ 29.800185443037975, -1.439729310344828 ], [ 29.80045493670886, -1.439729310344828 ], [ 29.80045493670886, -1.439459827586207 ], [ 29.800185443037975, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14709, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.439459827586207 ], [ 29.80045493670886, -1.439729310344828 ], [ 29.800724430379748, -1.439729310344828 ], [ 29.800724430379748, -1.439459827586207 ], [ 29.80045493670886, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14710, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.439459827586207 ], [ 29.800724430379748, -1.439729310344828 ], [ 29.800993924050633, -1.439729310344828 ], [ 29.800993924050633, -1.439459827586207 ], [ 29.800724430379748, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14711, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.439459827586207 ], [ 29.800993924050633, -1.439729310344828 ], [ 29.801263417721518, -1.439729310344828 ], [ 29.801263417721518, -1.439459827586207 ], [ 29.800993924050633, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14712, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.439459827586207 ], [ 29.801263417721518, -1.439729310344828 ], [ 29.801532911392407, -1.439729310344828 ], [ 29.801532911392407, -1.439459827586207 ], [ 29.801263417721518, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14713, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.439459827586207 ], [ 29.801532911392407, -1.439729310344828 ], [ 29.802071898734177, -1.439729310344828 ], [ 29.802071898734177, -1.439459827586207 ], [ 29.801532911392407, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14714, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.439459827586207 ], [ 29.802071898734177, -1.439729310344828 ], [ 29.802341392405065, -1.439729310344828 ], [ 29.802341392405065, -1.439459827586207 ], [ 29.802071898734177, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14715, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.439459827586207 ], [ 29.802341392405065, -1.439729310344828 ], [ 29.80261088607595, -1.439729310344828 ], [ 29.80261088607595, -1.439459827586207 ], [ 29.802341392405065, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14716, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.439459827586207 ], [ 29.80261088607595, -1.439729310344828 ], [ 29.802880379746835, -1.439729310344828 ], [ 29.802880379746835, -1.439459827586207 ], [ 29.80261088607595, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14717, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.439459827586207 ], [ 29.802880379746835, -1.439729310344828 ], [ 29.803149873417723, -1.439729310344828 ], [ 29.803149873417723, -1.439459827586207 ], [ 29.802880379746835, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14718, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.439459827586207 ], [ 29.803149873417723, -1.439729310344828 ], [ 29.803419367088608, -1.439729310344828 ], [ 29.803419367088608, -1.439459827586207 ], [ 29.803149873417723, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14719, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.439459827586207 ], [ 29.803419367088608, -1.439729310344828 ], [ 29.803688860759493, -1.439729310344828 ], [ 29.803688860759493, -1.439459827586207 ], [ 29.803419367088608, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14720, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.439459827586207 ], [ 29.803688860759493, -1.439729310344828 ], [ 29.803958354430382, -1.439729310344828 ], [ 29.803958354430382, -1.439459827586207 ], [ 29.803688860759493, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14721, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.439459827586207 ], [ 29.803958354430382, -1.439998793103448 ], [ 29.804227848101267, -1.439998793103448 ], [ 29.804227848101267, -1.439459827586207 ], [ 29.803958354430382, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14722, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.439459827586207 ], [ 29.804227848101267, -1.439729310344828 ], [ 29.804497341772151, -1.439729310344828 ], [ 29.804497341772151, -1.439459827586207 ], [ 29.804227848101267, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14723, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.439459827586207 ], [ 29.804497341772151, -1.439729310344828 ], [ 29.80476683544304, -1.439729310344828 ], [ 29.80476683544304, -1.439459827586207 ], [ 29.804497341772151, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14724, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.439459827586207 ], [ 29.80476683544304, -1.439729310344828 ], [ 29.805036329113925, -1.439729310344828 ], [ 29.805036329113925, -1.439459827586207 ], [ 29.80476683544304, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14725, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.439459827586207 ], [ 29.805036329113925, -1.439729310344828 ], [ 29.80530582278481, -1.439729310344828 ], [ 29.80530582278481, -1.439459827586207 ], [ 29.805036329113925, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14726, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.439459827586207 ], [ 29.805575316455698, -1.439729310344828 ], [ 29.805844810126583, -1.439729310344828 ], [ 29.805844810126583, -1.439459827586207 ], [ 29.805575316455698, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14727, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.439459827586207 ], [ 29.805844810126583, -1.439729310344828 ], [ 29.806114303797468, -1.439729310344828 ], [ 29.806114303797468, -1.439459827586207 ], [ 29.805844810126583, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14728, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.439459827586207 ], [ 29.806114303797468, -1.439729310344828 ], [ 29.806383797468357, -1.439729310344828 ], [ 29.806383797468357, -1.439459827586207 ], [ 29.806114303797468, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14729, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.439459827586207 ], [ 29.806383797468357, -1.439729310344828 ], [ 29.806653291139241, -1.439729310344828 ], [ 29.806653291139241, -1.439459827586207 ], [ 29.806383797468357, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14730, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.439459827586207 ], [ 29.806653291139241, -1.439729310344828 ], [ 29.807731265822785, -1.439729310344828 ], [ 29.807731265822785, -1.439459827586207 ], [ 29.806653291139241, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14731, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.439459827586207 ], [ 29.807731265822785, -1.439729310344828 ], [ 29.808000759493673, -1.439729310344828 ], [ 29.808000759493673, -1.439459827586207 ], [ 29.807731265822785, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14732, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.439459827586207 ], [ 29.808000759493673, -1.439729310344828 ], [ 29.808270253164558, -1.439729310344828 ], [ 29.808270253164558, -1.439459827586207 ], [ 29.808000759493673, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14733, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.439459827586207 ], [ 29.808270253164558, -1.439729310344828 ], [ 29.808539746835443, -1.439729310344828 ], [ 29.808539746835443, -1.439459827586207 ], [ 29.808270253164558, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14734, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.439459827586207 ], [ 29.808539746835443, -1.439729310344828 ], [ 29.808809240506328, -1.439729310344828 ], [ 29.808809240506328, -1.439459827586207 ], [ 29.808539746835443, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14735, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.439459827586207 ], [ 29.808809240506328, -1.439729310344828 ], [ 29.809078734177216, -1.439729310344828 ], [ 29.809078734177216, -1.439459827586207 ], [ 29.808809240506328, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14736, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.439459827586207 ], [ 29.809078734177216, -1.439729310344828 ], [ 29.809348227848101, -1.439729310344828 ], [ 29.809348227848101, -1.439459827586207 ], [ 29.809078734177216, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14737, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.439459827586207 ], [ 29.809348227848101, -1.439729310344828 ], [ 29.809617721518986, -1.439729310344828 ], [ 29.809617721518986, -1.439459827586207 ], [ 29.809348227848101, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14738, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.439459827586207 ], [ 29.809617721518986, -1.439729310344828 ], [ 29.809887215189875, -1.439729310344828 ], [ 29.809887215189875, -1.439459827586207 ], [ 29.809617721518986, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14739, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.439459827586207 ], [ 29.809887215189875, -1.439729310344828 ], [ 29.81015670886076, -1.439729310344828 ], [ 29.81015670886076, -1.439459827586207 ], [ 29.809887215189875, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14740, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.439459827586207 ], [ 29.81015670886076, -1.439729310344828 ], [ 29.810426202531644, -1.439729310344828 ], [ 29.810426202531644, -1.439459827586207 ], [ 29.81015670886076, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14741, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.439459827586207 ], [ 29.810426202531644, -1.439729310344828 ], [ 29.810965189873418, -1.439729310344828 ], [ 29.810965189873418, -1.439459827586207 ], [ 29.810426202531644, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14742, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.439459827586207 ], [ 29.810965189873418, -1.439729310344828 ], [ 29.811234683544303, -1.439729310344828 ], [ 29.811234683544303, -1.439459827586207 ], [ 29.810965189873418, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14743, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.439459827586207 ], [ 29.811234683544303, -1.439729310344828 ], [ 29.811504177215191, -1.439729310344828 ], [ 29.811504177215191, -1.439459827586207 ], [ 29.811234683544303, -1.439459827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14744, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744130759493672, -1.439729310344828 ], [ 29.744130759493672, -1.440268275862069 ], [ 29.744400253164557, -1.440268275862069 ], [ 29.744400253164557, -1.439729310344828 ], [ 29.744130759493672, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14745, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.439729310344828 ], [ 29.744400253164557, -1.439998793103448 ], [ 29.744669746835442, -1.439998793103448 ], [ 29.744669746835442, -1.439729310344828 ], [ 29.744400253164557, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14746, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.439729310344828 ], [ 29.744669746835442, -1.439998793103448 ], [ 29.744939240506326, -1.439998793103448 ], [ 29.744939240506326, -1.439729310344828 ], [ 29.744669746835442, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14747, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.439729310344828 ], [ 29.744939240506326, -1.439998793103448 ], [ 29.745208734177215, -1.439998793103448 ], [ 29.745208734177215, -1.439729310344828 ], [ 29.744939240506326, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14748, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.439729310344828 ], [ 29.745208734177215, -1.439998793103448 ], [ 29.7454782278481, -1.439998793103448 ], [ 29.7454782278481, -1.439729310344828 ], [ 29.745208734177215, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14749, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.439729310344828 ], [ 29.7454782278481, -1.439998793103448 ], [ 29.745747721518985, -1.439998793103448 ], [ 29.745747721518985, -1.439729310344828 ], [ 29.7454782278481, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14750, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.439729310344828 ], [ 29.745747721518985, -1.439998793103448 ], [ 29.746017215189873, -1.439998793103448 ], [ 29.746017215189873, -1.439729310344828 ], [ 29.745747721518985, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14751, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.439729310344828 ], [ 29.746017215189873, -1.439998793103448 ], [ 29.746286708860758, -1.439998793103448 ], [ 29.746286708860758, -1.439729310344828 ], [ 29.746017215189873, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14752, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.439729310344828 ], [ 29.746286708860758, -1.439998793103448 ], [ 29.746556202531643, -1.439998793103448 ], [ 29.746556202531643, -1.439729310344828 ], [ 29.746286708860758, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14753, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.439729310344828 ], [ 29.746556202531643, -1.439998793103448 ], [ 29.746825696202531, -1.439998793103448 ], [ 29.746825696202531, -1.439729310344828 ], [ 29.746556202531643, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14754, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.439729310344828 ], [ 29.746825696202531, -1.439998793103448 ], [ 29.747095189873416, -1.439998793103448 ], [ 29.747095189873416, -1.439729310344828 ], [ 29.746825696202531, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14755, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.439729310344828 ], [ 29.747095189873416, -1.439998793103448 ], [ 29.747364683544301, -1.439998793103448 ], [ 29.747364683544301, -1.439729310344828 ], [ 29.747095189873416, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14756, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.439729310344828 ], [ 29.747364683544301, -1.439998793103448 ], [ 29.747903670886075, -1.439998793103448 ], [ 29.747903670886075, -1.439729310344828 ], [ 29.747364683544301, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14757, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.439729310344828 ], [ 29.747903670886075, -1.439998793103448 ], [ 29.74817316455696, -1.439998793103448 ], [ 29.74817316455696, -1.439729310344828 ], [ 29.747903670886075, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14758, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.439729310344828 ], [ 29.74817316455696, -1.439998793103448 ], [ 29.748442658227848, -1.439998793103448 ], [ 29.748442658227848, -1.439729310344828 ], [ 29.74817316455696, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14759, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.439729310344828 ], [ 29.748442658227848, -1.439998793103448 ], [ 29.748712151898733, -1.439998793103448 ], [ 29.748712151898733, -1.439729310344828 ], [ 29.748442658227848, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14760, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.439729310344828 ], [ 29.748712151898733, -1.439998793103448 ], [ 29.748981645569618, -1.439998793103448 ], [ 29.748981645569618, -1.439729310344828 ], [ 29.748712151898733, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14761, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.439729310344828 ], [ 29.748981645569618, -1.439998793103448 ], [ 29.749251139240506, -1.439998793103448 ], [ 29.749251139240506, -1.439729310344828 ], [ 29.748981645569618, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14762, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.439729310344828 ], [ 29.749251139240506, -1.439998793103448 ], [ 29.749520632911391, -1.439998793103448 ], [ 29.749520632911391, -1.439729310344828 ], [ 29.749251139240506, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14763, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.439729310344828 ], [ 29.749520632911391, -1.439998793103448 ], [ 29.749790126582276, -1.439998793103448 ], [ 29.749790126582276, -1.439729310344828 ], [ 29.749520632911391, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14764, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.439729310344828 ], [ 29.749790126582276, -1.439998793103448 ], [ 29.750059620253165, -1.439998793103448 ], [ 29.750059620253165, -1.439729310344828 ], [ 29.749790126582276, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14765, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.439729310344828 ], [ 29.750059620253165, -1.439998793103448 ], [ 29.75032911392405, -1.439998793103448 ], [ 29.75032911392405, -1.439729310344828 ], [ 29.750059620253165, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14766, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.439729310344828 ], [ 29.75032911392405, -1.439998793103448 ], [ 29.750598607594934, -1.439998793103448 ], [ 29.750598607594934, -1.439729310344828 ], [ 29.75032911392405, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14767, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.439729310344828 ], [ 29.750598607594934, -1.439998793103448 ], [ 29.750868101265823, -1.439998793103448 ], [ 29.750868101265823, -1.439729310344828 ], [ 29.750598607594934, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14768, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.439729310344828 ], [ 29.750868101265823, -1.439998793103448 ], [ 29.751137594936708, -1.439998793103448 ], [ 29.751137594936708, -1.439729310344828 ], [ 29.750868101265823, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14769, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.439729310344828 ], [ 29.751137594936708, -1.439998793103448 ], [ 29.751407088607593, -1.439998793103448 ], [ 29.751407088607593, -1.439729310344828 ], [ 29.751137594936708, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14770, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.439729310344828 ], [ 29.751407088607593, -1.439998793103448 ], [ 29.753563037974683, -1.439998793103448 ], [ 29.753563037974683, -1.440268275862069 ], [ 29.753832531645568, -1.440268275862069 ], [ 29.753832531645568, -1.439729310344828 ], [ 29.751407088607593, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14771, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.439729310344828 ], [ 29.755988481012658, -1.440268275862069 ], [ 29.756257974683542, -1.440268275862069 ], [ 29.756257974683542, -1.439729310344828 ], [ 29.755988481012658, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14772, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.439729310344828 ], [ 29.757605443037974, -1.440268275862069 ], [ 29.757874936708859, -1.440268275862069 ], [ 29.757874936708859, -1.439729310344828 ], [ 29.757605443037974, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14773, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.439729310344828 ], [ 29.758413924050632, -1.439998793103448 ], [ 29.758683417721517, -1.439998793103448 ], [ 29.758683417721517, -1.439729310344828 ], [ 29.758413924050632, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14774, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.439729310344828 ], [ 29.758683417721517, -1.439998793103448 ], [ 29.758952911392406, -1.439998793103448 ], [ 29.758952911392406, -1.439729310344828 ], [ 29.758683417721517, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14775, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.439729310344828 ], [ 29.758952911392406, -1.439998793103448 ], [ 29.759222405063291, -1.439998793103448 ], [ 29.759222405063291, -1.439729310344828 ], [ 29.758952911392406, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14776, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.439729310344828 ], [ 29.759222405063291, -1.439998793103448 ], [ 29.761647848101266, -1.439998793103448 ], [ 29.761647848101266, -1.439729310344828 ], [ 29.759222405063291, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14777, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.439729310344828 ], [ 29.761647848101266, -1.439998793103448 ], [ 29.761917341772151, -1.439998793103448 ], [ 29.761917341772151, -1.439729310344828 ], [ 29.761647848101266, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14778, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.439729310344828 ], [ 29.761917341772151, -1.439998793103448 ], [ 29.762186835443039, -1.439998793103448 ], [ 29.762186835443039, -1.439729310344828 ], [ 29.761917341772151, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14779, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.439729310344828 ], [ 29.762186835443039, -1.439998793103448 ], [ 29.762456329113924, -1.439998793103448 ], [ 29.762456329113924, -1.439729310344828 ], [ 29.762186835443039, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14780, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.439729310344828 ], [ 29.762456329113924, -1.439998793103448 ], [ 29.762725822784809, -1.439998793103448 ], [ 29.762725822784809, -1.439729310344828 ], [ 29.762456329113924, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14781, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.439729310344828 ], [ 29.762725822784809, -1.439998793103448 ], [ 29.762995316455697, -1.439998793103448 ], [ 29.762995316455697, -1.439729310344828 ], [ 29.762725822784809, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14782, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.439729310344828 ], [ 29.762995316455697, -1.439998793103448 ], [ 29.763264810126582, -1.439998793103448 ], [ 29.763264810126582, -1.439729310344828 ], [ 29.762995316455697, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14783, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.439729310344828 ], [ 29.763264810126582, -1.439998793103448 ], [ 29.763534303797467, -1.439998793103448 ], [ 29.763534303797467, -1.439729310344828 ], [ 29.763264810126582, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14784, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.439729310344828 ], [ 29.763803797468356, -1.439998793103448 ], [ 29.76407329113924, -1.439998793103448 ], [ 29.76407329113924, -1.439729310344828 ], [ 29.763803797468356, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14785, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.439729310344828 ], [ 29.76407329113924, -1.439998793103448 ], [ 29.764342784810125, -1.439998793103448 ], [ 29.764342784810125, -1.439729310344828 ], [ 29.76407329113924, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14786, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.439729310344828 ], [ 29.764612278481014, -1.440268275862069 ], [ 29.764881772151899, -1.440268275862069 ], [ 29.764881772151899, -1.439729310344828 ], [ 29.764612278481014, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14787, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.439729310344828 ], [ 29.765420759493672, -1.440268275862069 ], [ 29.765959746835442, -1.440268275862069 ], [ 29.765959746835442, -1.439729310344828 ], [ 29.765420759493672, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14788, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.439729310344828 ], [ 29.766498734177215, -1.440268275862069 ], [ 29.7667682278481, -1.440268275862069 ], [ 29.7667682278481, -1.439729310344828 ], [ 29.766498734177215, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14789, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.439729310344828 ], [ 29.767037721518985, -1.440268275862069 ], [ 29.767307215189874, -1.440268275862069 ], [ 29.767307215189874, -1.439729310344828 ], [ 29.767037721518985, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14790, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.439729310344828 ], [ 29.767846202531643, -1.440268275862069 ], [ 29.768115696202532, -1.440268275862069 ], [ 29.768115696202532, -1.439729310344828 ], [ 29.767846202531643, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14791, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.439729310344828 ], [ 29.769193670886075, -1.44053775862069 ], [ 29.76946316455696, -1.44053775862069 ], [ 29.76946316455696, -1.439729310344828 ], [ 29.769193670886075, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14792, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.439729310344828 ], [ 29.770541139240507, -1.440268275862069 ], [ 29.770810632911392, -1.440268275862069 ], [ 29.770810632911392, -1.439729310344828 ], [ 29.770541139240507, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14793, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.439729310344828 ], [ 29.771349620253165, -1.439998793103448 ], [ 29.77161911392405, -1.439998793103448 ], [ 29.77161911392405, -1.439729310344828 ], [ 29.771349620253165, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14794, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.439729310344828 ], [ 29.77161911392405, -1.439998793103448 ], [ 29.771888607594935, -1.439998793103448 ], [ 29.771888607594935, -1.439729310344828 ], [ 29.77161911392405, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14795, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.439729310344828 ], [ 29.771888607594935, -1.439998793103448 ], [ 29.772158101265823, -1.439998793103448 ], [ 29.772158101265823, -1.439729310344828 ], [ 29.771888607594935, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14796, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.439729310344828 ], [ 29.772158101265823, -1.439998793103448 ], [ 29.772427594936708, -1.439998793103448 ], [ 29.772427594936708, -1.439729310344828 ], [ 29.772158101265823, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14797, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.439729310344828 ], [ 29.772427594936708, -1.439998793103448 ], [ 29.772697088607593, -1.439998793103448 ], [ 29.772697088607593, -1.439729310344828 ], [ 29.772427594936708, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14798, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.439729310344828 ], [ 29.772697088607593, -1.439998793103448 ], [ 29.772966582278482, -1.439998793103448 ], [ 29.772966582278482, -1.439729310344828 ], [ 29.772697088607593, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14799, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.439729310344828 ], [ 29.772966582278482, -1.439998793103448 ], [ 29.773236075949367, -1.439998793103448 ], [ 29.773236075949367, -1.439729310344828 ], [ 29.772966582278482, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14800, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.439729310344828 ], [ 29.773236075949367, -1.439998793103448 ], [ 29.773505569620252, -1.439998793103448 ], [ 29.773505569620252, -1.439729310344828 ], [ 29.773236075949367, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14801, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.439729310344828 ], [ 29.773505569620252, -1.439998793103448 ], [ 29.77377506329114, -1.439998793103448 ], [ 29.77377506329114, -1.439729310344828 ], [ 29.773505569620252, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14802, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.439729310344828 ], [ 29.77377506329114, -1.439998793103448 ], [ 29.774044556962025, -1.439998793103448 ], [ 29.774044556962025, -1.439729310344828 ], [ 29.77377506329114, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14803, "El": 133 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.439729310344828 ], [ 29.774044556962025, -1.439998793103448 ], [ 29.77431405063291, -1.439998793103448 ], [ 29.77431405063291, -1.439729310344828 ], [ 29.774044556962025, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14804, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.439729310344828 ], [ 29.77431405063291, -1.439998793103448 ], [ 29.774853037974683, -1.439998793103448 ], [ 29.774853037974683, -1.439729310344828 ], [ 29.77431405063291, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14805, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.439729310344828 ], [ 29.775931012658226, -1.44053775862069 ], [ 29.776200506329115, -1.44053775862069 ], [ 29.776200506329115, -1.439729310344828 ], [ 29.775931012658226, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14806, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.439729310344828 ], [ 29.777278481012658, -1.44053775862069 ], [ 29.777547974683543, -1.44053775862069 ], [ 29.777547974683543, -1.439729310344828 ], [ 29.777278481012658, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14807, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.439729310344828 ], [ 29.77862594936709, -1.44053775862069 ], [ 29.778895443037975, -1.44053775862069 ], [ 29.778895443037975, -1.439729310344828 ], [ 29.77862594936709, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14808, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.439729310344828 ], [ 29.779973417721518, -1.440268275862069 ], [ 29.780242911392406, -1.440268275862069 ], [ 29.780242911392406, -1.439729310344828 ], [ 29.779973417721518, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14809, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.439729310344828 ], [ 29.780781898734176, -1.440268275862069 ], [ 29.781051392405065, -1.440268275862069 ], [ 29.781051392405065, -1.439729310344828 ], [ 29.780781898734176, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14810, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.439729310344828 ], [ 29.78132088607595, -1.440268275862069 ], [ 29.781590379746834, -1.440268275862069 ], [ 29.781590379746834, -1.439729310344828 ], [ 29.78132088607595, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14811, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.439729310344828 ], [ 29.782129367088608, -1.440268275862069 ], [ 29.782398860759493, -1.440268275862069 ], [ 29.782398860759493, -1.439729310344828 ], [ 29.782129367088608, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14812, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.439729310344828 ], [ 29.782937848101266, -1.440268275862069 ], [ 29.783207341772151, -1.440268275862069 ], [ 29.783207341772151, -1.439729310344828 ], [ 29.782937848101266, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14813, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.439729310344828 ], [ 29.783476835443039, -1.439998793103448 ], [ 29.784015822784809, -1.439998793103448 ], [ 29.784015822784809, -1.439729310344828 ], [ 29.783476835443039, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14814, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.439729310344828 ], [ 29.784015822784809, -1.439998793103448 ], [ 29.784285316455698, -1.439998793103448 ], [ 29.784285316455698, -1.439729310344828 ], [ 29.784015822784809, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14815, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.439729310344828 ], [ 29.784554810126583, -1.439998793103448 ], [ 29.784824303797468, -1.439998793103448 ], [ 29.784824303797468, -1.439729310344828 ], [ 29.784554810126583, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14816, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.439729310344828 ], [ 29.784824303797468, -1.439998793103448 ], [ 29.785093797468356, -1.439998793103448 ], [ 29.785093797468356, -1.439729310344828 ], [ 29.784824303797468, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14817, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.439729310344828 ], [ 29.785093797468356, -1.439998793103448 ], [ 29.785363291139241, -1.439998793103448 ], [ 29.785363291139241, -1.439729310344828 ], [ 29.785093797468356, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14818, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.439729310344828 ], [ 29.785363291139241, -1.439998793103448 ], [ 29.785632784810126, -1.439998793103448 ], [ 29.785632784810126, -1.439729310344828 ], [ 29.785363291139241, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14819, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.439729310344828 ], [ 29.785632784810126, -1.439998793103448 ], [ 29.785902278481014, -1.439998793103448 ], [ 29.785902278481014, -1.439729310344828 ], [ 29.785632784810126, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14820, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.439729310344828 ], [ 29.785902278481014, -1.44053775862069 ], [ 29.786171772151899, -1.44053775862069 ], [ 29.786171772151899, -1.439729310344828 ], [ 29.785902278481014, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.439729310344828 ], [ 29.789405696202532, -1.440268275862069 ], [ 29.789675189873417, -1.440268275862069 ], [ 29.789675189873417, -1.439729310344828 ], [ 29.789405696202532, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14822, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.439729310344828 ], [ 29.789675189873417, -1.440268275862069 ], [ 29.789944683544302, -1.440268275862069 ], [ 29.789944683544302, -1.439729310344828 ], [ 29.789675189873417, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14823, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.439729310344828 ], [ 29.789944683544302, -1.440268275862069 ], [ 29.790214177215191, -1.440268275862069 ], [ 29.790214177215191, -1.439729310344828 ], [ 29.789944683544302, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14824, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.439729310344828 ], [ 29.790214177215191, -1.440268275862069 ], [ 29.790483670886076, -1.440268275862069 ], [ 29.790483670886076, -1.439729310344828 ], [ 29.790214177215191, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14825, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.439729310344828 ], [ 29.790483670886076, -1.440268275862069 ], [ 29.790753164556961, -1.440268275862069 ], [ 29.790753164556961, -1.439729310344828 ], [ 29.790483670886076, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14826, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.439729310344828 ], [ 29.791022658227849, -1.440268275862069 ], [ 29.791292151898734, -1.440268275862069 ], [ 29.791292151898734, -1.439729310344828 ], [ 29.791022658227849, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14827, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.439729310344828 ], [ 29.791292151898734, -1.439998793103448 ], [ 29.791561645569619, -1.439998793103448 ], [ 29.791561645569619, -1.439729310344828 ], [ 29.791292151898734, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14828, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.439729310344828 ], [ 29.791561645569619, -1.439998793103448 ], [ 29.791831139240507, -1.439998793103448 ], [ 29.791831139240507, -1.439729310344828 ], [ 29.791561645569619, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14829, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.439729310344828 ], [ 29.791831139240507, -1.439998793103448 ], [ 29.792100632911392, -1.439998793103448 ], [ 29.792100632911392, -1.439729310344828 ], [ 29.791831139240507, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14830, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.439729310344828 ], [ 29.792100632911392, -1.439998793103448 ], [ 29.792370126582277, -1.439998793103448 ], [ 29.792370126582277, -1.439729310344828 ], [ 29.792100632911392, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14831, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.439729310344828 ], [ 29.792370126582277, -1.439998793103448 ], [ 29.792909113924051, -1.439998793103448 ], [ 29.792909113924051, -1.439729310344828 ], [ 29.792370126582277, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14832, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.439729310344828 ], [ 29.792909113924051, -1.439998793103448 ], [ 29.793178607594935, -1.439998793103448 ], [ 29.793178607594935, -1.439729310344828 ], [ 29.792909113924051, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14833, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.439729310344828 ], [ 29.793178607594935, -1.439998793103448 ], [ 29.793448101265824, -1.439998793103448 ], [ 29.793448101265824, -1.439729310344828 ], [ 29.793178607594935, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14834, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.439729310344828 ], [ 29.793448101265824, -1.439998793103448 ], [ 29.793717594936709, -1.439998793103448 ], [ 29.793717594936709, -1.439729310344828 ], [ 29.793448101265824, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14835, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.439729310344828 ], [ 29.794256582278482, -1.439998793103448 ], [ 29.794526075949367, -1.439998793103448 ], [ 29.794526075949367, -1.439729310344828 ], [ 29.794256582278482, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14836, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.439729310344828 ], [ 29.794526075949367, -1.439998793103448 ], [ 29.794795569620252, -1.439998793103448 ], [ 29.794795569620252, -1.439729310344828 ], [ 29.794526075949367, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14837, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.439729310344828 ], [ 29.794795569620252, -1.439998793103448 ], [ 29.79506506329114, -1.439998793103448 ], [ 29.79506506329114, -1.439729310344828 ], [ 29.794795569620252, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14838, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.439729310344828 ], [ 29.79506506329114, -1.439998793103448 ], [ 29.795334556962025, -1.439998793103448 ], [ 29.795334556962025, -1.439729310344828 ], [ 29.79506506329114, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14839, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.439729310344828 ], [ 29.795334556962025, -1.439998793103448 ], [ 29.79560405063291, -1.439998793103448 ], [ 29.79560405063291, -1.439729310344828 ], [ 29.795334556962025, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14840, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.439729310344828 ], [ 29.79560405063291, -1.439998793103448 ], [ 29.795873544303799, -1.439998793103448 ], [ 29.795873544303799, -1.439729310344828 ], [ 29.79560405063291, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14841, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.439729310344828 ], [ 29.795873544303799, -1.439998793103448 ], [ 29.796143037974684, -1.439998793103448 ], [ 29.796143037974684, -1.439729310344828 ], [ 29.795873544303799, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14842, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.439729310344828 ], [ 29.796143037974684, -1.439998793103448 ], [ 29.796412531645569, -1.439998793103448 ], [ 29.796412531645569, -1.439729310344828 ], [ 29.796143037974684, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14843, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.439729310344828 ], [ 29.796412531645569, -1.439998793103448 ], [ 29.796682025316457, -1.439998793103448 ], [ 29.796682025316457, -1.439729310344828 ], [ 29.796412531645569, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14844, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.439729310344828 ], [ 29.796682025316457, -1.439998793103448 ], [ 29.796951518987342, -1.439998793103448 ], [ 29.796951518987342, -1.439729310344828 ], [ 29.796682025316457, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14845, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.439729310344828 ], [ 29.796951518987342, -1.439998793103448 ], [ 29.797221012658227, -1.439998793103448 ], [ 29.797221012658227, -1.439729310344828 ], [ 29.796951518987342, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14846, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.439729310344828 ], [ 29.797221012658227, -1.439998793103448 ], [ 29.797490506329115, -1.439998793103448 ], [ 29.797490506329115, -1.439729310344828 ], [ 29.797221012658227, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14847, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.439729310344828 ], [ 29.797490506329115, -1.439998793103448 ], [ 29.79776, -1.439998793103448 ], [ 29.79776, -1.439729310344828 ], [ 29.797490506329115, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14848, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.439729310344828 ], [ 29.79776, -1.439998793103448 ], [ 29.799376962025317, -1.439998793103448 ], [ 29.799376962025317, -1.439729310344828 ], [ 29.79776, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14849, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.439729310344828 ], [ 29.799376962025317, -1.439998793103448 ], [ 29.799646455696202, -1.439998793103448 ], [ 29.799646455696202, -1.439729310344828 ], [ 29.799376962025317, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14850, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.439729310344828 ], [ 29.799646455696202, -1.439998793103448 ], [ 29.79991594936709, -1.439998793103448 ], [ 29.79991594936709, -1.439729310344828 ], [ 29.799646455696202, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14851, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.439729310344828 ], [ 29.79991594936709, -1.439998793103448 ], [ 29.800185443037975, -1.439998793103448 ], [ 29.800185443037975, -1.439729310344828 ], [ 29.79991594936709, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14852, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.439729310344828 ], [ 29.800185443037975, -1.439998793103448 ], [ 29.80045493670886, -1.439998793103448 ], [ 29.80045493670886, -1.439729310344828 ], [ 29.800185443037975, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14853, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.439729310344828 ], [ 29.80045493670886, -1.439998793103448 ], [ 29.800724430379748, -1.439998793103448 ], [ 29.800724430379748, -1.439729310344828 ], [ 29.80045493670886, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14854, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.439729310344828 ], [ 29.800724430379748, -1.439998793103448 ], [ 29.800993924050633, -1.439998793103448 ], [ 29.800993924050633, -1.439729310344828 ], [ 29.800724430379748, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14855, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.439729310344828 ], [ 29.800993924050633, -1.439998793103448 ], [ 29.801263417721518, -1.439998793103448 ], [ 29.801263417721518, -1.439729310344828 ], [ 29.800993924050633, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14856, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.439729310344828 ], [ 29.801263417721518, -1.439998793103448 ], [ 29.801532911392407, -1.439998793103448 ], [ 29.801532911392407, -1.439729310344828 ], [ 29.801263417721518, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14857, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.439729310344828 ], [ 29.801532911392407, -1.439998793103448 ], [ 29.802071898734177, -1.439998793103448 ], [ 29.802071898734177, -1.439729310344828 ], [ 29.801532911392407, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14858, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.439729310344828 ], [ 29.802071898734177, -1.439998793103448 ], [ 29.802341392405065, -1.439998793103448 ], [ 29.802341392405065, -1.439729310344828 ], [ 29.802071898734177, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14859, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.439729310344828 ], [ 29.802341392405065, -1.439998793103448 ], [ 29.80261088607595, -1.439998793103448 ], [ 29.80261088607595, -1.439729310344828 ], [ 29.802341392405065, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14860, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.439729310344828 ], [ 29.80261088607595, -1.439998793103448 ], [ 29.802880379746835, -1.439998793103448 ], [ 29.802880379746835, -1.439729310344828 ], [ 29.80261088607595, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14861, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.439729310344828 ], [ 29.802880379746835, -1.439998793103448 ], [ 29.803149873417723, -1.439998793103448 ], [ 29.803149873417723, -1.439729310344828 ], [ 29.802880379746835, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14862, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.439729310344828 ], [ 29.803149873417723, -1.439998793103448 ], [ 29.803419367088608, -1.439998793103448 ], [ 29.803419367088608, -1.439729310344828 ], [ 29.803149873417723, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14863, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.439729310344828 ], [ 29.803419367088608, -1.439998793103448 ], [ 29.803688860759493, -1.439998793103448 ], [ 29.803688860759493, -1.439729310344828 ], [ 29.803419367088608, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14864, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.439729310344828 ], [ 29.803688860759493, -1.439998793103448 ], [ 29.803958354430382, -1.439998793103448 ], [ 29.803958354430382, -1.439729310344828 ], [ 29.803688860759493, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14865, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.439729310344828 ], [ 29.804227848101267, -1.439998793103448 ], [ 29.804497341772151, -1.439998793103448 ], [ 29.804497341772151, -1.439729310344828 ], [ 29.804227848101267, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14866, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.439729310344828 ], [ 29.804497341772151, -1.439998793103448 ], [ 29.80476683544304, -1.439998793103448 ], [ 29.80476683544304, -1.439729310344828 ], [ 29.804497341772151, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14867, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.439729310344828 ], [ 29.80476683544304, -1.439998793103448 ], [ 29.805036329113925, -1.439998793103448 ], [ 29.805036329113925, -1.439729310344828 ], [ 29.80476683544304, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14868, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.439729310344828 ], [ 29.805036329113925, -1.439998793103448 ], [ 29.80530582278481, -1.439998793103448 ], [ 29.80530582278481, -1.439729310344828 ], [ 29.805036329113925, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14869, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.439729310344828 ], [ 29.80530582278481, -1.439998793103448 ], [ 29.805575316455698, -1.439998793103448 ], [ 29.805575316455698, -1.439729310344828 ], [ 29.80530582278481, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14870, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.439729310344828 ], [ 29.805575316455698, -1.439998793103448 ], [ 29.805844810126583, -1.439998793103448 ], [ 29.805844810126583, -1.439729310344828 ], [ 29.805575316455698, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14871, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.439729310344828 ], [ 29.805844810126583, -1.439998793103448 ], [ 29.806114303797468, -1.439998793103448 ], [ 29.806114303797468, -1.439729310344828 ], [ 29.805844810126583, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14872, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.439729310344828 ], [ 29.806114303797468, -1.439998793103448 ], [ 29.806383797468357, -1.439998793103448 ], [ 29.806383797468357, -1.439729310344828 ], [ 29.806114303797468, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14873, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.439729310344828 ], [ 29.806383797468357, -1.439998793103448 ], [ 29.806653291139241, -1.439998793103448 ], [ 29.806653291139241, -1.439729310344828 ], [ 29.806383797468357, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14874, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.439729310344828 ], [ 29.806653291139241, -1.439998793103448 ], [ 29.807731265822785, -1.439998793103448 ], [ 29.807731265822785, -1.439729310344828 ], [ 29.806653291139241, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14875, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.439729310344828 ], [ 29.807731265822785, -1.439998793103448 ], [ 29.808000759493673, -1.439998793103448 ], [ 29.808000759493673, -1.439729310344828 ], [ 29.807731265822785, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14876, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.439729310344828 ], [ 29.808000759493673, -1.439998793103448 ], [ 29.808270253164558, -1.439998793103448 ], [ 29.808270253164558, -1.439729310344828 ], [ 29.808000759493673, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14877, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.439729310344828 ], [ 29.808270253164558, -1.439998793103448 ], [ 29.808539746835443, -1.439998793103448 ], [ 29.808539746835443, -1.439729310344828 ], [ 29.808270253164558, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14878, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.439729310344828 ], [ 29.808539746835443, -1.439998793103448 ], [ 29.808809240506328, -1.439998793103448 ], [ 29.808809240506328, -1.439729310344828 ], [ 29.808539746835443, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14879, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.439729310344828 ], [ 29.808809240506328, -1.439998793103448 ], [ 29.809078734177216, -1.439998793103448 ], [ 29.809078734177216, -1.439729310344828 ], [ 29.808809240506328, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14880, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.439729310344828 ], [ 29.809078734177216, -1.439998793103448 ], [ 29.809348227848101, -1.439998793103448 ], [ 29.809348227848101, -1.439729310344828 ], [ 29.809078734177216, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14881, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.439729310344828 ], [ 29.809348227848101, -1.439998793103448 ], [ 29.809617721518986, -1.439998793103448 ], [ 29.809617721518986, -1.439729310344828 ], [ 29.809348227848101, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14882, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.439729310344828 ], [ 29.809617721518986, -1.439998793103448 ], [ 29.809887215189875, -1.439998793103448 ], [ 29.809887215189875, -1.439729310344828 ], [ 29.809617721518986, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14883, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.439729310344828 ], [ 29.809887215189875, -1.439998793103448 ], [ 29.81015670886076, -1.439998793103448 ], [ 29.81015670886076, -1.439729310344828 ], [ 29.809887215189875, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14884, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.439729310344828 ], [ 29.81015670886076, -1.439998793103448 ], [ 29.810426202531644, -1.439998793103448 ], [ 29.810426202531644, -1.439729310344828 ], [ 29.81015670886076, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14885, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.439729310344828 ], [ 29.810426202531644, -1.439998793103448 ], [ 29.810965189873418, -1.439998793103448 ], [ 29.810965189873418, -1.439729310344828 ], [ 29.810426202531644, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14886, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.439729310344828 ], [ 29.810965189873418, -1.439998793103448 ], [ 29.811234683544303, -1.439998793103448 ], [ 29.811234683544303, -1.439729310344828 ], [ 29.810965189873418, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14887, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.439729310344828 ], [ 29.811234683544303, -1.439998793103448 ], [ 29.811504177215191, -1.439998793103448 ], [ 29.811504177215191, -1.439729310344828 ], [ 29.811234683544303, -1.439729310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14888, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.439998793103448 ], [ 29.744400253164557, -1.440268275862069 ], [ 29.744669746835442, -1.440268275862069 ], [ 29.744669746835442, -1.439998793103448 ], [ 29.744400253164557, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14889, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.439998793103448 ], [ 29.744669746835442, -1.440268275862069 ], [ 29.744939240506326, -1.440268275862069 ], [ 29.744939240506326, -1.439998793103448 ], [ 29.744669746835442, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14890, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.439998793103448 ], [ 29.744939240506326, -1.440268275862069 ], [ 29.745208734177215, -1.440268275862069 ], [ 29.745208734177215, -1.439998793103448 ], [ 29.744939240506326, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14891, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.439998793103448 ], [ 29.745208734177215, -1.440268275862069 ], [ 29.7454782278481, -1.440268275862069 ], [ 29.7454782278481, -1.439998793103448 ], [ 29.745208734177215, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14892, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.439998793103448 ], [ 29.7454782278481, -1.440268275862069 ], [ 29.745747721518985, -1.440268275862069 ], [ 29.745747721518985, -1.439998793103448 ], [ 29.7454782278481, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14893, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.439998793103448 ], [ 29.745747721518985, -1.440268275862069 ], [ 29.746017215189873, -1.440268275862069 ], [ 29.746017215189873, -1.439998793103448 ], [ 29.745747721518985, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14894, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.439998793103448 ], [ 29.746017215189873, -1.440268275862069 ], [ 29.746286708860758, -1.440268275862069 ], [ 29.746286708860758, -1.439998793103448 ], [ 29.746017215189873, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14895, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.439998793103448 ], [ 29.746286708860758, -1.440268275862069 ], [ 29.746556202531643, -1.440268275862069 ], [ 29.746556202531643, -1.439998793103448 ], [ 29.746286708860758, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14896, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.439998793103448 ], [ 29.746556202531643, -1.440268275862069 ], [ 29.746825696202531, -1.440268275862069 ], [ 29.746825696202531, -1.439998793103448 ], [ 29.746556202531643, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14897, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.439998793103448 ], [ 29.746825696202531, -1.440268275862069 ], [ 29.747095189873416, -1.440268275862069 ], [ 29.747095189873416, -1.439998793103448 ], [ 29.746825696202531, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14898, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.439998793103448 ], [ 29.747095189873416, -1.440268275862069 ], [ 29.747364683544301, -1.440268275862069 ], [ 29.747364683544301, -1.439998793103448 ], [ 29.747095189873416, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14899, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.439998793103448 ], [ 29.747364683544301, -1.440268275862069 ], [ 29.747903670886075, -1.440268275862069 ], [ 29.747903670886075, -1.439998793103448 ], [ 29.747364683544301, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14900, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.439998793103448 ], [ 29.747903670886075, -1.440268275862069 ], [ 29.74817316455696, -1.440268275862069 ], [ 29.74817316455696, -1.439998793103448 ], [ 29.747903670886075, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14901, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.439998793103448 ], [ 29.74817316455696, -1.440268275862069 ], [ 29.748442658227848, -1.440268275862069 ], [ 29.748442658227848, -1.439998793103448 ], [ 29.74817316455696, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14902, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.439998793103448 ], [ 29.748442658227848, -1.440268275862069 ], [ 29.748712151898733, -1.440268275862069 ], [ 29.748712151898733, -1.439998793103448 ], [ 29.748442658227848, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14903, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.439998793103448 ], [ 29.748712151898733, -1.440268275862069 ], [ 29.748981645569618, -1.440268275862069 ], [ 29.748981645569618, -1.439998793103448 ], [ 29.748712151898733, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14904, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.439998793103448 ], [ 29.748981645569618, -1.440268275862069 ], [ 29.749251139240506, -1.440268275862069 ], [ 29.749251139240506, -1.439998793103448 ], [ 29.748981645569618, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14905, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.439998793103448 ], [ 29.749251139240506, -1.440268275862069 ], [ 29.749520632911391, -1.440268275862069 ], [ 29.749520632911391, -1.439998793103448 ], [ 29.749251139240506, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14906, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.439998793103448 ], [ 29.749520632911391, -1.440268275862069 ], [ 29.749790126582276, -1.440268275862069 ], [ 29.749790126582276, -1.439998793103448 ], [ 29.749520632911391, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14907, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.439998793103448 ], [ 29.749790126582276, -1.440268275862069 ], [ 29.750059620253165, -1.440268275862069 ], [ 29.750059620253165, -1.439998793103448 ], [ 29.749790126582276, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14908, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.439998793103448 ], [ 29.750059620253165, -1.440268275862069 ], [ 29.75032911392405, -1.440268275862069 ], [ 29.75032911392405, -1.439998793103448 ], [ 29.750059620253165, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14909, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.439998793103448 ], [ 29.75032911392405, -1.440268275862069 ], [ 29.750598607594934, -1.440268275862069 ], [ 29.750598607594934, -1.439998793103448 ], [ 29.75032911392405, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14910, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.439998793103448 ], [ 29.750598607594934, -1.440268275862069 ], [ 29.750868101265823, -1.440268275862069 ], [ 29.750868101265823, -1.439998793103448 ], [ 29.750598607594934, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14911, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.439998793103448 ], [ 29.750868101265823, -1.440268275862069 ], [ 29.751137594936708, -1.440268275862069 ], [ 29.751137594936708, -1.439998793103448 ], [ 29.750868101265823, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14912, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.439998793103448 ], [ 29.751137594936708, -1.440268275862069 ], [ 29.751407088607593, -1.440268275862069 ], [ 29.751407088607593, -1.439998793103448 ], [ 29.751137594936708, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14913, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.439998793103448 ], [ 29.751407088607593, -1.440268275862069 ], [ 29.751676582278481, -1.440268275862069 ], [ 29.751676582278481, -1.439998793103448 ], [ 29.751407088607593, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14914, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.439998793103448 ], [ 29.751676582278481, -1.440268275862069 ], [ 29.751946075949366, -1.440268275862069 ], [ 29.751946075949366, -1.439998793103448 ], [ 29.751676582278481, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14915, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.439998793103448 ], [ 29.751946075949366, -1.44053775862069 ], [ 29.752215569620251, -1.44053775862069 ], [ 29.752215569620251, -1.440268275862069 ], [ 29.753563037974683, -1.440268275862069 ], [ 29.753563037974683, -1.439998793103448 ], [ 29.751946075949366, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14916, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.439998793103448 ], [ 29.754102025316456, -1.44053775862069 ], [ 29.754371518987341, -1.44053775862069 ], [ 29.754371518987341, -1.439998793103448 ], [ 29.754102025316456, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14917, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.439998793103448 ], [ 29.754641012658226, -1.44053775862069 ], [ 29.754910506329114, -1.44053775862069 ], [ 29.754910506329114, -1.439998793103448 ], [ 29.754641012658226, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14918, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.439998793103448 ], [ 29.755449493670884, -1.44053775862069 ], [ 29.755718987341773, -1.44053775862069 ], [ 29.755718987341773, -1.439998793103448 ], [ 29.755449493670884, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14919, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.439998793103448 ], [ 29.756257974683542, -1.44053775862069 ], [ 29.756796962025316, -1.44053775862069 ], [ 29.756796962025316, -1.439998793103448 ], [ 29.756257974683542, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14920, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.439998793103448 ], [ 29.757066455696201, -1.440268275862069 ], [ 29.757335949367089, -1.440268275862069 ], [ 29.757335949367089, -1.439998793103448 ], [ 29.757066455696201, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14921, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.439998793103448 ], [ 29.757335949367089, -1.440268275862069 ], [ 29.757605443037974, -1.440268275862069 ], [ 29.757605443037974, -1.439998793103448 ], [ 29.757335949367089, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14922, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.439998793103448 ], [ 29.757874936708859, -1.440268275862069 ], [ 29.758144430379748, -1.440268275862069 ], [ 29.758144430379748, -1.439998793103448 ], [ 29.757874936708859, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14923, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.439998793103448 ], [ 29.758144430379748, -1.440268275862069 ], [ 29.758413924050632, -1.440268275862069 ], [ 29.758413924050632, -1.439998793103448 ], [ 29.758144430379748, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14924, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.439998793103448 ], [ 29.758413924050632, -1.440268275862069 ], [ 29.758683417721517, -1.440268275862069 ], [ 29.758683417721517, -1.439998793103448 ], [ 29.758413924050632, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14925, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.439998793103448 ], [ 29.758683417721517, -1.440268275862069 ], [ 29.758952911392406, -1.440268275862069 ], [ 29.758952911392406, -1.439998793103448 ], [ 29.758683417721517, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14926, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.439998793103448 ], [ 29.758952911392406, -1.440268275862069 ], [ 29.759222405063291, -1.440268275862069 ], [ 29.759222405063291, -1.439998793103448 ], [ 29.758952911392406, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14927, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.439998793103448 ], [ 29.759222405063291, -1.440268275862069 ], [ 29.761647848101266, -1.440268275862069 ], [ 29.761647848101266, -1.439998793103448 ], [ 29.759222405063291, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14928, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.439998793103448 ], [ 29.761647848101266, -1.440268275862069 ], [ 29.761917341772151, -1.440268275862069 ], [ 29.761917341772151, -1.439998793103448 ], [ 29.761647848101266, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14929, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.439998793103448 ], [ 29.761917341772151, -1.440268275862069 ], [ 29.762186835443039, -1.440268275862069 ], [ 29.762186835443039, -1.439998793103448 ], [ 29.761917341772151, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14930, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.439998793103448 ], [ 29.762186835443039, -1.440268275862069 ], [ 29.762456329113924, -1.440268275862069 ], [ 29.762456329113924, -1.439998793103448 ], [ 29.762186835443039, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14931, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.439998793103448 ], [ 29.762456329113924, -1.440268275862069 ], [ 29.762725822784809, -1.440268275862069 ], [ 29.762725822784809, -1.439998793103448 ], [ 29.762456329113924, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14932, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.439998793103448 ], [ 29.762725822784809, -1.440268275862069 ], [ 29.762995316455697, -1.440268275862069 ], [ 29.762995316455697, -1.439998793103448 ], [ 29.762725822784809, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14933, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.439998793103448 ], [ 29.762995316455697, -1.440268275862069 ], [ 29.763264810126582, -1.440268275862069 ], [ 29.763264810126582, -1.439998793103448 ], [ 29.762995316455697, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14934, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.439998793103448 ], [ 29.763264810126582, -1.440268275862069 ], [ 29.763534303797467, -1.440268275862069 ], [ 29.763534303797467, -1.439998793103448 ], [ 29.763264810126582, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14935, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.439998793103448 ], [ 29.763534303797467, -1.440268275862069 ], [ 29.763803797468356, -1.440268275862069 ], [ 29.763803797468356, -1.439998793103448 ], [ 29.763534303797467, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14936, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.439998793103448 ], [ 29.763803797468356, -1.440268275862069 ], [ 29.76407329113924, -1.440268275862069 ], [ 29.76407329113924, -1.439998793103448 ], [ 29.763803797468356, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14937, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.439998793103448 ], [ 29.76407329113924, -1.440268275862069 ], [ 29.764342784810125, -1.440268275862069 ], [ 29.764342784810125, -1.439998793103448 ], [ 29.76407329113924, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14938, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.439998793103448 ], [ 29.764342784810125, -1.440268275862069 ], [ 29.764612278481014, -1.440268275862069 ], [ 29.764612278481014, -1.439998793103448 ], [ 29.764342784810125, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14939, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.439998793103448 ], [ 29.764881772151899, -1.440268275862069 ], [ 29.765151265822784, -1.440268275862069 ], [ 29.765151265822784, -1.439998793103448 ], [ 29.764881772151899, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14940, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.439998793103448 ], [ 29.765151265822784, -1.440268275862069 ], [ 29.765420759493672, -1.440268275862069 ], [ 29.765420759493672, -1.439998793103448 ], [ 29.765151265822784, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14941, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.439998793103448 ], [ 29.765959746835442, -1.44053775862069 ], [ 29.766229240506327, -1.44053775862069 ], [ 29.766229240506327, -1.439998793103448 ], [ 29.765959746835442, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14942, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.439998793103448 ], [ 29.766229240506327, -1.440268275862069 ], [ 29.766498734177215, -1.440268275862069 ], [ 29.766498734177215, -1.439998793103448 ], [ 29.766229240506327, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14943, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.439998793103448 ], [ 29.7667682278481, -1.44053775862069 ], [ 29.767037721518985, -1.44053775862069 ], [ 29.767037721518985, -1.439998793103448 ], [ 29.7667682278481, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14944, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.439998793103448 ], [ 29.767576708860759, -1.44053775862069 ], [ 29.767846202531643, -1.44053775862069 ], [ 29.767846202531643, -1.439998793103448 ], [ 29.767576708860759, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14945, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.439998793103448 ], [ 29.768385189873417, -1.44053775862069 ], [ 29.768654683544302, -1.44053775862069 ], [ 29.768654683544302, -1.439998793103448 ], [ 29.768385189873417, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14946, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.439998793103448 ], [ 29.76892417721519, -1.44053775862069 ], [ 29.769193670886075, -1.44053775862069 ], [ 29.769193670886075, -1.439998793103448 ], [ 29.76892417721519, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14947, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.439998793103448 ], [ 29.769732658227849, -1.44053775862069 ], [ 29.770002151898733, -1.44053775862069 ], [ 29.770002151898733, -1.439998793103448 ], [ 29.769732658227849, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14948, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.439998793103448 ], [ 29.770810632911392, -1.440268275862069 ], [ 29.771080126582277, -1.440268275862069 ], [ 29.771080126582277, -1.439998793103448 ], [ 29.770810632911392, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14949, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.439998793103448 ], [ 29.771080126582277, -1.440268275862069 ], [ 29.771349620253165, -1.440268275862069 ], [ 29.771349620253165, -1.439998793103448 ], [ 29.771080126582277, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14950, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.439998793103448 ], [ 29.771349620253165, -1.440268275862069 ], [ 29.77161911392405, -1.440268275862069 ], [ 29.77161911392405, -1.439998793103448 ], [ 29.771349620253165, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14951, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.439998793103448 ], [ 29.77161911392405, -1.440268275862069 ], [ 29.771888607594935, -1.440268275862069 ], [ 29.771888607594935, -1.439998793103448 ], [ 29.77161911392405, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14952, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.439998793103448 ], [ 29.771888607594935, -1.440268275862069 ], [ 29.772158101265823, -1.440268275862069 ], [ 29.772158101265823, -1.439998793103448 ], [ 29.771888607594935, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14953, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.439998793103448 ], [ 29.772158101265823, -1.440268275862069 ], [ 29.772427594936708, -1.440268275862069 ], [ 29.772427594936708, -1.439998793103448 ], [ 29.772158101265823, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14954, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.439998793103448 ], [ 29.772427594936708, -1.440268275862069 ], [ 29.772697088607593, -1.440268275862069 ], [ 29.772697088607593, -1.439998793103448 ], [ 29.772427594936708, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14955, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.439998793103448 ], [ 29.772697088607593, -1.440268275862069 ], [ 29.772966582278482, -1.440268275862069 ], [ 29.772966582278482, -1.439998793103448 ], [ 29.772697088607593, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14956, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.439998793103448 ], [ 29.772966582278482, -1.440268275862069 ], [ 29.773236075949367, -1.440268275862069 ], [ 29.773236075949367, -1.439998793103448 ], [ 29.772966582278482, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14957, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.439998793103448 ], [ 29.773236075949367, -1.440268275862069 ], [ 29.773505569620252, -1.440268275862069 ], [ 29.773505569620252, -1.439998793103448 ], [ 29.773236075949367, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14958, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.439998793103448 ], [ 29.773505569620252, -1.440268275862069 ], [ 29.77377506329114, -1.440268275862069 ], [ 29.77377506329114, -1.439998793103448 ], [ 29.773505569620252, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14959, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.439998793103448 ], [ 29.77377506329114, -1.440268275862069 ], [ 29.774044556962025, -1.440268275862069 ], [ 29.774044556962025, -1.439998793103448 ], [ 29.77377506329114, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14960, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.439998793103448 ], [ 29.774044556962025, -1.440268275862069 ], [ 29.77431405063291, -1.440268275862069 ], [ 29.77431405063291, -1.439998793103448 ], [ 29.774044556962025, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14961, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.439998793103448 ], [ 29.77431405063291, -1.440268275862069 ], [ 29.774853037974683, -1.440268275862069 ], [ 29.774853037974683, -1.439998793103448 ], [ 29.77431405063291, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14962, "El": 135 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.439998793103448 ], [ 29.774853037974683, -1.440268275862069 ], [ 29.775122531645568, -1.440268275862069 ], [ 29.775122531645568, -1.439998793103448 ], [ 29.774853037974683, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14963, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.439998793103448 ], [ 29.775392025316457, -1.44080724137931 ], [ 29.775661518987341, -1.44080724137931 ], [ 29.775661518987341, -1.439998793103448 ], [ 29.775392025316457, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14964, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.439998793103448 ], [ 29.776739493670885, -1.44080724137931 ], [ 29.777008987341773, -1.44080724137931 ], [ 29.777008987341773, -1.439998793103448 ], [ 29.776739493670885, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14965, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.439998793103448 ], [ 29.778086962025316, -1.44053775862069 ], [ 29.778356455696201, -1.44053775862069 ], [ 29.778356455696201, -1.439998793103448 ], [ 29.778086962025316, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14966, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.439998793103448 ], [ 29.778895443037975, -1.44053775862069 ], [ 29.77916493670886, -1.44053775862069 ], [ 29.77916493670886, -1.439998793103448 ], [ 29.778895443037975, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14967, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.439998793103448 ], [ 29.779434430379748, -1.44053775862069 ], [ 29.779703924050633, -1.44053775862069 ], [ 29.779703924050633, -1.439998793103448 ], [ 29.779434430379748, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14968, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.439998793103448 ], [ 29.780242911392406, -1.44053775862069 ], [ 29.780512405063291, -1.44053775862069 ], [ 29.780512405063291, -1.439998793103448 ], [ 29.780242911392406, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14969, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.439998793103448 ], [ 29.781051392405065, -1.44053775862069 ], [ 29.78132088607595, -1.44053775862069 ], [ 29.78132088607595, -1.439998793103448 ], [ 29.781051392405065, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14970, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.439998793103448 ], [ 29.781590379746834, -1.440268275862069 ], [ 29.781859873417723, -1.440268275862069 ], [ 29.781859873417723, -1.439998793103448 ], [ 29.781590379746834, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14971, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.439998793103448 ], [ 29.781859873417723, -1.44053775862069 ], [ 29.782129367088608, -1.44053775862069 ], [ 29.782129367088608, -1.439998793103448 ], [ 29.781859873417723, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14972, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.439998793103448 ], [ 29.782398860759493, -1.440268275862069 ], [ 29.782668354430381, -1.440268275862069 ], [ 29.782668354430381, -1.439998793103448 ], [ 29.782398860759493, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14973, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.439998793103448 ], [ 29.782668354430381, -1.440268275862069 ], [ 29.782937848101266, -1.440268275862069 ], [ 29.782937848101266, -1.439998793103448 ], [ 29.782668354430381, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14974, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.439998793103448 ], [ 29.783207341772151, -1.440268275862069 ], [ 29.783476835443039, -1.440268275862069 ], [ 29.783476835443039, -1.439998793103448 ], [ 29.783207341772151, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14975, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.439998793103448 ], [ 29.783476835443039, -1.440268275862069 ], [ 29.784015822784809, -1.440268275862069 ], [ 29.784015822784809, -1.439998793103448 ], [ 29.783476835443039, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14976, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.439998793103448 ], [ 29.784015822784809, -1.440268275862069 ], [ 29.784285316455698, -1.440268275862069 ], [ 29.784285316455698, -1.439998793103448 ], [ 29.784015822784809, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14977, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.439998793103448 ], [ 29.784285316455698, -1.440268275862069 ], [ 29.784554810126583, -1.440268275862069 ], [ 29.784554810126583, -1.439998793103448 ], [ 29.784285316455698, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14978, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.439998793103448 ], [ 29.784554810126583, -1.440268275862069 ], [ 29.784824303797468, -1.440268275862069 ], [ 29.784824303797468, -1.439998793103448 ], [ 29.784554810126583, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14979, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.439998793103448 ], [ 29.784824303797468, -1.440268275862069 ], [ 29.785093797468356, -1.440268275862069 ], [ 29.785093797468356, -1.439998793103448 ], [ 29.784824303797468, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.439998793103448 ], [ 29.785093797468356, -1.440268275862069 ], [ 29.785363291139241, -1.440268275862069 ], [ 29.785363291139241, -1.439998793103448 ], [ 29.785093797468356, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14981, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.439998793103448 ], [ 29.785363291139241, -1.440268275862069 ], [ 29.785632784810126, -1.440268275862069 ], [ 29.785632784810126, -1.439998793103448 ], [ 29.785363291139241, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14982, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.439998793103448 ], [ 29.785632784810126, -1.44053775862069 ], [ 29.785902278481014, -1.44053775862069 ], [ 29.785902278481014, -1.439998793103448 ], [ 29.785632784810126, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14983, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.439998793103448 ], [ 29.791292151898734, -1.440268275862069 ], [ 29.791561645569619, -1.440268275862069 ], [ 29.791561645569619, -1.439998793103448 ], [ 29.791292151898734, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14984, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.439998793103448 ], [ 29.791561645569619, -1.440268275862069 ], [ 29.791831139240507, -1.440268275862069 ], [ 29.791831139240507, -1.439998793103448 ], [ 29.791561645569619, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14985, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.439998793103448 ], [ 29.791831139240507, -1.440268275862069 ], [ 29.792100632911392, -1.440268275862069 ], [ 29.792100632911392, -1.439998793103448 ], [ 29.791831139240507, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14986, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.439998793103448 ], [ 29.792100632911392, -1.440268275862069 ], [ 29.792370126582277, -1.440268275862069 ], [ 29.792370126582277, -1.439998793103448 ], [ 29.792100632911392, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14987, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.439998793103448 ], [ 29.792370126582277, -1.440268275862069 ], [ 29.792909113924051, -1.440268275862069 ], [ 29.792909113924051, -1.439998793103448 ], [ 29.792370126582277, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14988, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.439998793103448 ], [ 29.792909113924051, -1.440268275862069 ], [ 29.793178607594935, -1.440268275862069 ], [ 29.793178607594935, -1.439998793103448 ], [ 29.792909113924051, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14989, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.439998793103448 ], [ 29.793178607594935, -1.440268275862069 ], [ 29.793448101265824, -1.440268275862069 ], [ 29.793448101265824, -1.439998793103448 ], [ 29.793178607594935, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14990, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.439998793103448 ], [ 29.793448101265824, -1.440268275862069 ], [ 29.793717594936709, -1.440268275862069 ], [ 29.793717594936709, -1.439998793103448 ], [ 29.793448101265824, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14991, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.439998793103448 ], [ 29.793717594936709, -1.440268275862069 ], [ 29.793987088607594, -1.440268275862069 ], [ 29.793987088607594, -1.439998793103448 ], [ 29.793717594936709, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14992, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.439998793103448 ], [ 29.793987088607594, -1.440268275862069 ], [ 29.794256582278482, -1.440268275862069 ], [ 29.794256582278482, -1.439998793103448 ], [ 29.793987088607594, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14993, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.439998793103448 ], [ 29.794256582278482, -1.440268275862069 ], [ 29.794526075949367, -1.440268275862069 ], [ 29.794526075949367, -1.439998793103448 ], [ 29.794256582278482, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14994, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.439998793103448 ], [ 29.794526075949367, -1.440268275862069 ], [ 29.794795569620252, -1.440268275862069 ], [ 29.794795569620252, -1.439998793103448 ], [ 29.794526075949367, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14995, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.439998793103448 ], [ 29.794795569620252, -1.440268275862069 ], [ 29.79506506329114, -1.440268275862069 ], [ 29.79506506329114, -1.439998793103448 ], [ 29.794795569620252, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14996, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.439998793103448 ], [ 29.79506506329114, -1.440268275862069 ], [ 29.795334556962025, -1.440268275862069 ], [ 29.795334556962025, -1.439998793103448 ], [ 29.79506506329114, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14997, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.439998793103448 ], [ 29.795334556962025, -1.440268275862069 ], [ 29.79560405063291, -1.440268275862069 ], [ 29.79560405063291, -1.439998793103448 ], [ 29.795334556962025, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14998, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.439998793103448 ], [ 29.79560405063291, -1.440268275862069 ], [ 29.795873544303799, -1.440268275862069 ], [ 29.795873544303799, -1.439998793103448 ], [ 29.79560405063291, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 14999, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.439998793103448 ], [ 29.795873544303799, -1.440268275862069 ], [ 29.796143037974684, -1.440268275862069 ], [ 29.796143037974684, -1.439998793103448 ], [ 29.795873544303799, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15000, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.439998793103448 ], [ 29.796143037974684, -1.440268275862069 ], [ 29.796412531645569, -1.440268275862069 ], [ 29.796412531645569, -1.439998793103448 ], [ 29.796143037974684, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15001, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.439998793103448 ], [ 29.796412531645569, -1.440268275862069 ], [ 29.796682025316457, -1.440268275862069 ], [ 29.796682025316457, -1.439998793103448 ], [ 29.796412531645569, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15002, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.439998793103448 ], [ 29.796682025316457, -1.440268275862069 ], [ 29.796951518987342, -1.440268275862069 ], [ 29.796951518987342, -1.439998793103448 ], [ 29.796682025316457, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15003, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.439998793103448 ], [ 29.796951518987342, -1.440268275862069 ], [ 29.797221012658227, -1.440268275862069 ], [ 29.797221012658227, -1.439998793103448 ], [ 29.796951518987342, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15004, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.439998793103448 ], [ 29.797221012658227, -1.440268275862069 ], [ 29.797490506329115, -1.440268275862069 ], [ 29.797490506329115, -1.439998793103448 ], [ 29.797221012658227, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15005, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.439998793103448 ], [ 29.797490506329115, -1.440268275862069 ], [ 29.798029493670885, -1.440268275862069 ], [ 29.798029493670885, -1.439998793103448 ], [ 29.797490506329115, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15006, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.439998793103448 ], [ 29.798029493670885, -1.440268275862069 ], [ 29.799107468354432, -1.440268275862069 ], [ 29.799107468354432, -1.439998793103448 ], [ 29.798029493670885, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15007, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.439998793103448 ], [ 29.799107468354432, -1.440268275862069 ], [ 29.799646455696202, -1.440268275862069 ], [ 29.799646455696202, -1.439998793103448 ], [ 29.799107468354432, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15008, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.439998793103448 ], [ 29.799646455696202, -1.440268275862069 ], [ 29.79991594936709, -1.440268275862069 ], [ 29.79991594936709, -1.439998793103448 ], [ 29.799646455696202, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15009, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.439998793103448 ], [ 29.79991594936709, -1.440268275862069 ], [ 29.800185443037975, -1.440268275862069 ], [ 29.800185443037975, -1.439998793103448 ], [ 29.79991594936709, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15010, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.439998793103448 ], [ 29.800185443037975, -1.440268275862069 ], [ 29.80045493670886, -1.440268275862069 ], [ 29.80045493670886, -1.439998793103448 ], [ 29.800185443037975, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15011, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.439998793103448 ], [ 29.80045493670886, -1.440268275862069 ], [ 29.800724430379748, -1.440268275862069 ], [ 29.800724430379748, -1.439998793103448 ], [ 29.80045493670886, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15012, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.439998793103448 ], [ 29.800724430379748, -1.440268275862069 ], [ 29.800993924050633, -1.440268275862069 ], [ 29.800993924050633, -1.439998793103448 ], [ 29.800724430379748, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15013, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.439998793103448 ], [ 29.800993924050633, -1.440268275862069 ], [ 29.801263417721518, -1.440268275862069 ], [ 29.801263417721518, -1.439998793103448 ], [ 29.800993924050633, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15014, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.439998793103448 ], [ 29.801263417721518, -1.440268275862069 ], [ 29.801532911392407, -1.440268275862069 ], [ 29.801532911392407, -1.439998793103448 ], [ 29.801263417721518, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15015, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.439998793103448 ], [ 29.801532911392407, -1.440268275862069 ], [ 29.802071898734177, -1.440268275862069 ], [ 29.802071898734177, -1.439998793103448 ], [ 29.801532911392407, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15016, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.439998793103448 ], [ 29.802071898734177, -1.440268275862069 ], [ 29.802341392405065, -1.440268275862069 ], [ 29.802341392405065, -1.439998793103448 ], [ 29.802071898734177, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15017, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.439998793103448 ], [ 29.802341392405065, -1.440268275862069 ], [ 29.80261088607595, -1.440268275862069 ], [ 29.80261088607595, -1.439998793103448 ], [ 29.802341392405065, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15018, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.439998793103448 ], [ 29.80261088607595, -1.440268275862069 ], [ 29.802880379746835, -1.440268275862069 ], [ 29.802880379746835, -1.439998793103448 ], [ 29.80261088607595, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15019, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.439998793103448 ], [ 29.802880379746835, -1.440268275862069 ], [ 29.803149873417723, -1.440268275862069 ], [ 29.803149873417723, -1.439998793103448 ], [ 29.802880379746835, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15020, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.439998793103448 ], [ 29.803149873417723, -1.440268275862069 ], [ 29.803419367088608, -1.440268275862069 ], [ 29.803419367088608, -1.439998793103448 ], [ 29.803149873417723, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15021, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.439998793103448 ], [ 29.803419367088608, -1.440268275862069 ], [ 29.803688860759493, -1.440268275862069 ], [ 29.803688860759493, -1.439998793103448 ], [ 29.803419367088608, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15022, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.439998793103448 ], [ 29.803688860759493, -1.440268275862069 ], [ 29.803958354430382, -1.440268275862069 ], [ 29.803958354430382, -1.439998793103448 ], [ 29.803688860759493, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15023, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.439998793103448 ], [ 29.803958354430382, -1.440268275862069 ], [ 29.804227848101267, -1.440268275862069 ], [ 29.804227848101267, -1.439998793103448 ], [ 29.803958354430382, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15024, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.439998793103448 ], [ 29.804227848101267, -1.440268275862069 ], [ 29.804497341772151, -1.440268275862069 ], [ 29.804497341772151, -1.439998793103448 ], [ 29.804227848101267, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15025, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.439998793103448 ], [ 29.804497341772151, -1.440268275862069 ], [ 29.80476683544304, -1.440268275862069 ], [ 29.80476683544304, -1.439998793103448 ], [ 29.804497341772151, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15026, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.439998793103448 ], [ 29.80476683544304, -1.440268275862069 ], [ 29.805036329113925, -1.440268275862069 ], [ 29.805036329113925, -1.439998793103448 ], [ 29.80476683544304, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15027, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.439998793103448 ], [ 29.805036329113925, -1.440268275862069 ], [ 29.80530582278481, -1.440268275862069 ], [ 29.80530582278481, -1.439998793103448 ], [ 29.805036329113925, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15028, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.439998793103448 ], [ 29.80530582278481, -1.440268275862069 ], [ 29.805575316455698, -1.440268275862069 ], [ 29.805575316455698, -1.439998793103448 ], [ 29.80530582278481, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15029, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.439998793103448 ], [ 29.805575316455698, -1.440268275862069 ], [ 29.805844810126583, -1.440268275862069 ], [ 29.805844810126583, -1.439998793103448 ], [ 29.805575316455698, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15030, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.439998793103448 ], [ 29.805844810126583, -1.440268275862069 ], [ 29.806114303797468, -1.440268275862069 ], [ 29.806114303797468, -1.439998793103448 ], [ 29.805844810126583, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15031, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.439998793103448 ], [ 29.806114303797468, -1.440268275862069 ], [ 29.806383797468357, -1.440268275862069 ], [ 29.806383797468357, -1.439998793103448 ], [ 29.806114303797468, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15032, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.439998793103448 ], [ 29.806383797468357, -1.440268275862069 ], [ 29.806922784810126, -1.440268275862069 ], [ 29.806922784810126, -1.439998793103448 ], [ 29.806383797468357, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15033, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.439998793103448 ], [ 29.806922784810126, -1.440268275862069 ], [ 29.8074617721519, -1.440268275862069 ], [ 29.8074617721519, -1.439998793103448 ], [ 29.806922784810126, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15034, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.439998793103448 ], [ 29.8074617721519, -1.440268275862069 ], [ 29.808000759493673, -1.440268275862069 ], [ 29.808000759493673, -1.439998793103448 ], [ 29.8074617721519, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15035, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.439998793103448 ], [ 29.808000759493673, -1.440268275862069 ], [ 29.808270253164558, -1.440268275862069 ], [ 29.808270253164558, -1.439998793103448 ], [ 29.808000759493673, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15036, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.439998793103448 ], [ 29.808270253164558, -1.440268275862069 ], [ 29.808809240506328, -1.440268275862069 ], [ 29.808809240506328, -1.439998793103448 ], [ 29.808270253164558, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15037, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.439998793103448 ], [ 29.808809240506328, -1.44053775862069 ], [ 29.809078734177216, -1.44053775862069 ], [ 29.809078734177216, -1.439998793103448 ], [ 29.808809240506328, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15038, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.439998793103448 ], [ 29.809078734177216, -1.440268275862069 ], [ 29.809348227848101, -1.440268275862069 ], [ 29.809348227848101, -1.439998793103448 ], [ 29.809078734177216, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15039, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.439998793103448 ], [ 29.809348227848101, -1.440268275862069 ], [ 29.809617721518986, -1.440268275862069 ], [ 29.809617721518986, -1.439998793103448 ], [ 29.809348227848101, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15040, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.439998793103448 ], [ 29.809617721518986, -1.440268275862069 ], [ 29.809887215189875, -1.440268275862069 ], [ 29.809887215189875, -1.439998793103448 ], [ 29.809617721518986, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15041, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.439998793103448 ], [ 29.809887215189875, -1.440268275862069 ], [ 29.81015670886076, -1.440268275862069 ], [ 29.81015670886076, -1.439998793103448 ], [ 29.809887215189875, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15042, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.439998793103448 ], [ 29.81015670886076, -1.440268275862069 ], [ 29.810426202531644, -1.440268275862069 ], [ 29.810426202531644, -1.439998793103448 ], [ 29.81015670886076, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15043, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.439998793103448 ], [ 29.810426202531644, -1.440268275862069 ], [ 29.810965189873418, -1.440268275862069 ], [ 29.810965189873418, -1.439998793103448 ], [ 29.810426202531644, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15044, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.439998793103448 ], [ 29.810965189873418, -1.440268275862069 ], [ 29.811234683544303, -1.440268275862069 ], [ 29.811234683544303, -1.439998793103448 ], [ 29.810965189873418, -1.439998793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15045, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744400253164557, -1.440268275862069 ], [ 29.744400253164557, -1.44053775862069 ], [ 29.744669746835442, -1.44053775862069 ], [ 29.744669746835442, -1.440268275862069 ], [ 29.744400253164557, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15046, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.440268275862069 ], [ 29.744669746835442, -1.44053775862069 ], [ 29.744939240506326, -1.44053775862069 ], [ 29.744939240506326, -1.440268275862069 ], [ 29.744669746835442, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15047, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.440268275862069 ], [ 29.744939240506326, -1.44053775862069 ], [ 29.745208734177215, -1.44053775862069 ], [ 29.745208734177215, -1.440268275862069 ], [ 29.744939240506326, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15048, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.440268275862069 ], [ 29.745208734177215, -1.44053775862069 ], [ 29.7454782278481, -1.44053775862069 ], [ 29.7454782278481, -1.440268275862069 ], [ 29.745208734177215, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15049, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.440268275862069 ], [ 29.7454782278481, -1.44053775862069 ], [ 29.745747721518985, -1.44053775862069 ], [ 29.745747721518985, -1.440268275862069 ], [ 29.7454782278481, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15050, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.440268275862069 ], [ 29.745747721518985, -1.44053775862069 ], [ 29.746017215189873, -1.44053775862069 ], [ 29.746017215189873, -1.440268275862069 ], [ 29.745747721518985, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15051, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.440268275862069 ], [ 29.746017215189873, -1.44053775862069 ], [ 29.746286708860758, -1.44053775862069 ], [ 29.746286708860758, -1.440268275862069 ], [ 29.746017215189873, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15052, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.440268275862069 ], [ 29.746286708860758, -1.44053775862069 ], [ 29.746556202531643, -1.44053775862069 ], [ 29.746556202531643, -1.440268275862069 ], [ 29.746286708860758, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15053, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.440268275862069 ], [ 29.746556202531643, -1.44053775862069 ], [ 29.746825696202531, -1.44053775862069 ], [ 29.746825696202531, -1.440268275862069 ], [ 29.746556202531643, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15054, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.440268275862069 ], [ 29.746825696202531, -1.44053775862069 ], [ 29.747095189873416, -1.44053775862069 ], [ 29.747095189873416, -1.440268275862069 ], [ 29.746825696202531, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15055, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.440268275862069 ], [ 29.747095189873416, -1.44053775862069 ], [ 29.747364683544301, -1.44053775862069 ], [ 29.747364683544301, -1.440268275862069 ], [ 29.747095189873416, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15056, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.440268275862069 ], [ 29.747364683544301, -1.44053775862069 ], [ 29.747903670886075, -1.44053775862069 ], [ 29.747903670886075, -1.440268275862069 ], [ 29.747364683544301, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15057, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.440268275862069 ], [ 29.747903670886075, -1.44053775862069 ], [ 29.74817316455696, -1.44053775862069 ], [ 29.74817316455696, -1.440268275862069 ], [ 29.747903670886075, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15058, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.440268275862069 ], [ 29.74817316455696, -1.44053775862069 ], [ 29.748442658227848, -1.44053775862069 ], [ 29.748442658227848, -1.440268275862069 ], [ 29.74817316455696, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15059, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.440268275862069 ], [ 29.748442658227848, -1.44053775862069 ], [ 29.748712151898733, -1.44053775862069 ], [ 29.748712151898733, -1.440268275862069 ], [ 29.748442658227848, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15060, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.440268275862069 ], [ 29.748712151898733, -1.44053775862069 ], [ 29.748981645569618, -1.44053775862069 ], [ 29.748981645569618, -1.440268275862069 ], [ 29.748712151898733, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15061, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.440268275862069 ], [ 29.748981645569618, -1.44053775862069 ], [ 29.749251139240506, -1.44053775862069 ], [ 29.749251139240506, -1.440268275862069 ], [ 29.748981645569618, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15062, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.440268275862069 ], [ 29.749251139240506, -1.44053775862069 ], [ 29.749520632911391, -1.44053775862069 ], [ 29.749520632911391, -1.440268275862069 ], [ 29.749251139240506, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15063, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.440268275862069 ], [ 29.749520632911391, -1.44053775862069 ], [ 29.749790126582276, -1.44053775862069 ], [ 29.749790126582276, -1.440268275862069 ], [ 29.749520632911391, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15064, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.440268275862069 ], [ 29.749790126582276, -1.44053775862069 ], [ 29.750059620253165, -1.44053775862069 ], [ 29.750059620253165, -1.440268275862069 ], [ 29.749790126582276, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15065, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.440268275862069 ], [ 29.750059620253165, -1.44053775862069 ], [ 29.75032911392405, -1.44053775862069 ], [ 29.75032911392405, -1.440268275862069 ], [ 29.750059620253165, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15066, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.440268275862069 ], [ 29.75032911392405, -1.44053775862069 ], [ 29.750598607594934, -1.44053775862069 ], [ 29.750598607594934, -1.440268275862069 ], [ 29.75032911392405, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15067, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.440268275862069 ], [ 29.750598607594934, -1.44053775862069 ], [ 29.750868101265823, -1.44053775862069 ], [ 29.750868101265823, -1.440268275862069 ], [ 29.750598607594934, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15068, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.440268275862069 ], [ 29.750868101265823, -1.44053775862069 ], [ 29.751137594936708, -1.44053775862069 ], [ 29.751137594936708, -1.440268275862069 ], [ 29.750868101265823, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15069, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.440268275862069 ], [ 29.751137594936708, -1.44053775862069 ], [ 29.751407088607593, -1.44053775862069 ], [ 29.751407088607593, -1.440268275862069 ], [ 29.751137594936708, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15070, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.440268275862069 ], [ 29.751407088607593, -1.44053775862069 ], [ 29.751676582278481, -1.44053775862069 ], [ 29.751676582278481, -1.440268275862069 ], [ 29.751407088607593, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15071, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.440268275862069 ], [ 29.751676582278481, -1.44053775862069 ], [ 29.751946075949366, -1.44053775862069 ], [ 29.751946075949366, -1.440268275862069 ], [ 29.751676582278481, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15072, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.440268275862069 ], [ 29.752215569620251, -1.44053775862069 ], [ 29.753293544303798, -1.44053775862069 ], [ 29.753293544303798, -1.440268275862069 ], [ 29.752215569620251, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15073, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.440268275862069 ], [ 29.753293544303798, -1.44053775862069 ], [ 29.753563037974683, -1.44053775862069 ], [ 29.753563037974683, -1.440268275862069 ], [ 29.753293544303798, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15074, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.440268275862069 ], [ 29.753563037974683, -1.44080724137931 ], [ 29.753832531645568, -1.44080724137931 ], [ 29.753832531645568, -1.440268275862069 ], [ 29.753563037974683, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15075, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.440268275862069 ], [ 29.754371518987341, -1.44080724137931 ], [ 29.754641012658226, -1.44080724137931 ], [ 29.754641012658226, -1.440268275862069 ], [ 29.754371518987341, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15076, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.440268275862069 ], [ 29.754910506329114, -1.44053775862069 ], [ 29.75518, -1.44053775862069 ], [ 29.75518, -1.440268275862069 ], [ 29.754910506329114, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15077, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.440268275862069 ], [ 29.75518, -1.44080724137931 ], [ 29.755449493670884, -1.44080724137931 ], [ 29.755449493670884, -1.440268275862069 ], [ 29.75518, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15078, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.440268275862069 ], [ 29.755718987341773, -1.44053775862069 ], [ 29.755988481012658, -1.44053775862069 ], [ 29.755988481012658, -1.440268275862069 ], [ 29.755718987341773, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15079, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.440268275862069 ], [ 29.755988481012658, -1.44053775862069 ], [ 29.756257974683542, -1.44053775862069 ], [ 29.756257974683542, -1.440268275862069 ], [ 29.755988481012658, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15080, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.440268275862069 ], [ 29.756796962025316, -1.44053775862069 ], [ 29.757066455696201, -1.44053775862069 ], [ 29.757066455696201, -1.440268275862069 ], [ 29.756796962025316, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15081, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.440268275862069 ], [ 29.757066455696201, -1.44053775862069 ], [ 29.757335949367089, -1.44053775862069 ], [ 29.757335949367089, -1.440268275862069 ], [ 29.757066455696201, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15082, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.440268275862069 ], [ 29.757335949367089, -1.44053775862069 ], [ 29.757605443037974, -1.44053775862069 ], [ 29.757605443037974, -1.440268275862069 ], [ 29.757335949367089, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15083, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.440268275862069 ], [ 29.757605443037974, -1.44053775862069 ], [ 29.757874936708859, -1.44053775862069 ], [ 29.757874936708859, -1.440268275862069 ], [ 29.757605443037974, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15084, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.440268275862069 ], [ 29.757874936708859, -1.44053775862069 ], [ 29.758144430379748, -1.44053775862069 ], [ 29.758144430379748, -1.440268275862069 ], [ 29.757874936708859, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15085, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.440268275862069 ], [ 29.758144430379748, -1.44053775862069 ], [ 29.758413924050632, -1.44053775862069 ], [ 29.758413924050632, -1.440268275862069 ], [ 29.758144430379748, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15086, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.440268275862069 ], [ 29.758413924050632, -1.44053775862069 ], [ 29.758683417721517, -1.44053775862069 ], [ 29.758683417721517, -1.440268275862069 ], [ 29.758413924050632, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15087, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.440268275862069 ], [ 29.758683417721517, -1.44053775862069 ], [ 29.758952911392406, -1.44053775862069 ], [ 29.758952911392406, -1.440268275862069 ], [ 29.758683417721517, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15088, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.440268275862069 ], [ 29.758952911392406, -1.44053775862069 ], [ 29.759222405063291, -1.44053775862069 ], [ 29.759222405063291, -1.440268275862069 ], [ 29.758952911392406, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15089, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.440268275862069 ], [ 29.759222405063291, -1.44053775862069 ], [ 29.761647848101266, -1.44053775862069 ], [ 29.761647848101266, -1.440268275862069 ], [ 29.759222405063291, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15090, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.440268275862069 ], [ 29.761647848101266, -1.44053775862069 ], [ 29.761917341772151, -1.44053775862069 ], [ 29.761917341772151, -1.440268275862069 ], [ 29.761647848101266, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15091, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.440268275862069 ], [ 29.761917341772151, -1.44053775862069 ], [ 29.762186835443039, -1.44053775862069 ], [ 29.762186835443039, -1.440268275862069 ], [ 29.761917341772151, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15092, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.440268275862069 ], [ 29.762186835443039, -1.44053775862069 ], [ 29.762456329113924, -1.44053775862069 ], [ 29.762456329113924, -1.440268275862069 ], [ 29.762186835443039, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15093, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.440268275862069 ], [ 29.762456329113924, -1.44053775862069 ], [ 29.762725822784809, -1.44053775862069 ], [ 29.762725822784809, -1.440268275862069 ], [ 29.762456329113924, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15094, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.440268275862069 ], [ 29.762725822784809, -1.44053775862069 ], [ 29.762995316455697, -1.44053775862069 ], [ 29.762995316455697, -1.440268275862069 ], [ 29.762725822784809, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15095, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.440268275862069 ], [ 29.762995316455697, -1.44053775862069 ], [ 29.763264810126582, -1.44053775862069 ], [ 29.763264810126582, -1.440268275862069 ], [ 29.762995316455697, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15096, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.440268275862069 ], [ 29.763264810126582, -1.44053775862069 ], [ 29.763534303797467, -1.44053775862069 ], [ 29.763534303797467, -1.440268275862069 ], [ 29.763264810126582, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15097, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.440268275862069 ], [ 29.763534303797467, -1.44053775862069 ], [ 29.763803797468356, -1.44053775862069 ], [ 29.763803797468356, -1.440268275862069 ], [ 29.763534303797467, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15098, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.440268275862069 ], [ 29.763803797468356, -1.44053775862069 ], [ 29.76407329113924, -1.44053775862069 ], [ 29.76407329113924, -1.440268275862069 ], [ 29.763803797468356, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15099, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.440268275862069 ], [ 29.76407329113924, -1.44053775862069 ], [ 29.764342784810125, -1.44053775862069 ], [ 29.764342784810125, -1.440268275862069 ], [ 29.76407329113924, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15100, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.440268275862069 ], [ 29.764342784810125, -1.44053775862069 ], [ 29.764612278481014, -1.44053775862069 ], [ 29.764612278481014, -1.440268275862069 ], [ 29.764342784810125, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15101, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.440268275862069 ], [ 29.764612278481014, -1.44053775862069 ], [ 29.764881772151899, -1.44053775862069 ], [ 29.764881772151899, -1.440268275862069 ], [ 29.764612278481014, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15102, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.440268275862069 ], [ 29.764881772151899, -1.44053775862069 ], [ 29.765151265822784, -1.44053775862069 ], [ 29.765151265822784, -1.440268275862069 ], [ 29.764881772151899, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15103, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.440268275862069 ], [ 29.765151265822784, -1.44053775862069 ], [ 29.765420759493672, -1.44053775862069 ], [ 29.765420759493672, -1.440268275862069 ], [ 29.765151265822784, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15104, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.440268275862069 ], [ 29.765420759493672, -1.44053775862069 ], [ 29.765959746835442, -1.44053775862069 ], [ 29.765959746835442, -1.440268275862069 ], [ 29.765420759493672, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15105, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.440268275862069 ], [ 29.766229240506327, -1.44053775862069 ], [ 29.766498734177215, -1.44053775862069 ], [ 29.766498734177215, -1.440268275862069 ], [ 29.766229240506327, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15106, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.440268275862069 ], [ 29.766498734177215, -1.44053775862069 ], [ 29.7667682278481, -1.44053775862069 ], [ 29.7667682278481, -1.440268275862069 ], [ 29.766498734177215, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15107, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.440268275862069 ], [ 29.767037721518985, -1.44053775862069 ], [ 29.767307215189874, -1.44053775862069 ], [ 29.767307215189874, -1.440268275862069 ], [ 29.767037721518985, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15108, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.440268275862069 ], [ 29.767307215189874, -1.44053775862069 ], [ 29.767576708860759, -1.44053775862069 ], [ 29.767576708860759, -1.440268275862069 ], [ 29.767307215189874, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15109, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.440268275862069 ], [ 29.767846202531643, -1.44080724137931 ], [ 29.768115696202532, -1.44080724137931 ], [ 29.768115696202532, -1.440268275862069 ], [ 29.767846202531643, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15110, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.440268275862069 ], [ 29.768115696202532, -1.44053775862069 ], [ 29.768385189873417, -1.44053775862069 ], [ 29.768385189873417, -1.440268275862069 ], [ 29.768115696202532, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15111, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.440268275862069 ], [ 29.768654683544302, -1.44080724137931 ], [ 29.76892417721519, -1.44080724137931 ], [ 29.76892417721519, -1.440268275862069 ], [ 29.768654683544302, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15112, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.440268275862069 ], [ 29.76946316455696, -1.44080724137931 ], [ 29.769732658227849, -1.44080724137931 ], [ 29.769732658227849, -1.440268275862069 ], [ 29.76946316455696, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15113, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.440268275862069 ], [ 29.770002151898733, -1.44053775862069 ], [ 29.770271645569618, -1.44053775862069 ], [ 29.770271645569618, -1.440268275862069 ], [ 29.770002151898733, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15114, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.440268275862069 ], [ 29.770541139240507, -1.44053775862069 ], [ 29.770810632911392, -1.44053775862069 ], [ 29.770810632911392, -1.440268275862069 ], [ 29.770541139240507, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15115, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.440268275862069 ], [ 29.770810632911392, -1.44053775862069 ], [ 29.771080126582277, -1.44053775862069 ], [ 29.771080126582277, -1.440268275862069 ], [ 29.770810632911392, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15116, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.440268275862069 ], [ 29.771080126582277, -1.44053775862069 ], [ 29.771349620253165, -1.44053775862069 ], [ 29.771349620253165, -1.440268275862069 ], [ 29.771080126582277, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15117, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.440268275862069 ], [ 29.771349620253165, -1.44053775862069 ], [ 29.77161911392405, -1.44053775862069 ], [ 29.77161911392405, -1.440268275862069 ], [ 29.771349620253165, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15118, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.440268275862069 ], [ 29.77161911392405, -1.44053775862069 ], [ 29.771888607594935, -1.44053775862069 ], [ 29.771888607594935, -1.440268275862069 ], [ 29.77161911392405, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15119, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.440268275862069 ], [ 29.771888607594935, -1.44053775862069 ], [ 29.772158101265823, -1.44053775862069 ], [ 29.772158101265823, -1.440268275862069 ], [ 29.771888607594935, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15120, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.440268275862069 ], [ 29.772158101265823, -1.44053775862069 ], [ 29.772427594936708, -1.44053775862069 ], [ 29.772427594936708, -1.440268275862069 ], [ 29.772158101265823, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15121, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.440268275862069 ], [ 29.772427594936708, -1.44053775862069 ], [ 29.772697088607593, -1.44053775862069 ], [ 29.772697088607593, -1.440268275862069 ], [ 29.772427594936708, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15122, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.440268275862069 ], [ 29.772697088607593, -1.44053775862069 ], [ 29.772966582278482, -1.44053775862069 ], [ 29.772966582278482, -1.440268275862069 ], [ 29.772697088607593, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15123, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.440268275862069 ], [ 29.772966582278482, -1.44053775862069 ], [ 29.773236075949367, -1.44053775862069 ], [ 29.773236075949367, -1.440268275862069 ], [ 29.772966582278482, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15124, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.440268275862069 ], [ 29.773236075949367, -1.44053775862069 ], [ 29.773505569620252, -1.44053775862069 ], [ 29.773505569620252, -1.440268275862069 ], [ 29.773236075949367, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15125, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.440268275862069 ], [ 29.773505569620252, -1.44053775862069 ], [ 29.77377506329114, -1.44053775862069 ], [ 29.77377506329114, -1.440268275862069 ], [ 29.773505569620252, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15126, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.440268275862069 ], [ 29.77377506329114, -1.44053775862069 ], [ 29.774044556962025, -1.44053775862069 ], [ 29.774044556962025, -1.440268275862069 ], [ 29.77377506329114, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15127, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.440268275862069 ], [ 29.774044556962025, -1.44053775862069 ], [ 29.77431405063291, -1.44053775862069 ], [ 29.77431405063291, -1.440268275862069 ], [ 29.774044556962025, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15128, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.440268275862069 ], [ 29.77431405063291, -1.44053775862069 ], [ 29.774853037974683, -1.44053775862069 ], [ 29.774853037974683, -1.440268275862069 ], [ 29.77431405063291, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15129, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.440268275862069 ], [ 29.774853037974683, -1.44053775862069 ], [ 29.775122531645568, -1.44053775862069 ], [ 29.775122531645568, -1.440268275862069 ], [ 29.774853037974683, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15130, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.440268275862069 ], [ 29.775661518987341, -1.441076724137931 ], [ 29.775931012658226, -1.441076724137931 ], [ 29.775931012658226, -1.440268275862069 ], [ 29.775661518987341, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15131, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.440268275862069 ], [ 29.776200506329115, -1.44080724137931 ], [ 29.77647, -1.44080724137931 ], [ 29.77647, -1.440268275862069 ], [ 29.776200506329115, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15132, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.440268275862069 ], [ 29.777008987341773, -1.44080724137931 ], [ 29.777278481012658, -1.44080724137931 ], [ 29.777278481012658, -1.440268275862069 ], [ 29.777008987341773, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15133, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.440268275862069 ], [ 29.777547974683543, -1.44080724137931 ], [ 29.777817468354431, -1.44080724137931 ], [ 29.777817468354431, -1.440268275862069 ], [ 29.777547974683543, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15134, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.440268275862069 ], [ 29.777817468354431, -1.44080724137931 ], [ 29.778086962025316, -1.44080724137931 ], [ 29.778086962025316, -1.440268275862069 ], [ 29.777817468354431, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15135, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.440268275862069 ], [ 29.778356455696201, -1.44080724137931 ], [ 29.77862594936709, -1.44080724137931 ], [ 29.77862594936709, -1.440268275862069 ], [ 29.778356455696201, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15136, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.440268275862069 ], [ 29.77916493670886, -1.44080724137931 ], [ 29.779434430379748, -1.44080724137931 ], [ 29.779434430379748, -1.440268275862069 ], [ 29.77916493670886, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15137, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.440268275862069 ], [ 29.779703924050633, -1.44053775862069 ], [ 29.779973417721518, -1.44053775862069 ], [ 29.779973417721518, -1.440268275862069 ], [ 29.779703924050633, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15138, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.440268275862069 ], [ 29.779973417721518, -1.44080724137931 ], [ 29.780242911392406, -1.44080724137931 ], [ 29.780242911392406, -1.440268275862069 ], [ 29.779973417721518, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15139, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.440268275862069 ], [ 29.780512405063291, -1.44053775862069 ], [ 29.780781898734176, -1.44053775862069 ], [ 29.780781898734176, -1.440268275862069 ], [ 29.780512405063291, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15140, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.440268275862069 ], [ 29.780781898734176, -1.44053775862069 ], [ 29.781051392405065, -1.44053775862069 ], [ 29.781051392405065, -1.440268275862069 ], [ 29.780781898734176, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15141, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.440268275862069 ], [ 29.78132088607595, -1.44053775862069 ], [ 29.781590379746834, -1.44053775862069 ], [ 29.781590379746834, -1.440268275862069 ], [ 29.78132088607595, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15142, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.440268275862069 ], [ 29.781590379746834, -1.44053775862069 ], [ 29.781859873417723, -1.44053775862069 ], [ 29.781859873417723, -1.440268275862069 ], [ 29.781590379746834, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15143, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.440268275862069 ], [ 29.782129367088608, -1.44053775862069 ], [ 29.782398860759493, -1.44053775862069 ], [ 29.782398860759493, -1.440268275862069 ], [ 29.782129367088608, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15144, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.440268275862069 ], [ 29.782398860759493, -1.44053775862069 ], [ 29.782668354430381, -1.44053775862069 ], [ 29.782668354430381, -1.440268275862069 ], [ 29.782398860759493, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15145, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.440268275862069 ], [ 29.782668354430381, -1.44053775862069 ], [ 29.782937848101266, -1.44053775862069 ], [ 29.782937848101266, -1.440268275862069 ], [ 29.782668354430381, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15146, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.440268275862069 ], [ 29.782937848101266, -1.44053775862069 ], [ 29.783207341772151, -1.44053775862069 ], [ 29.783207341772151, -1.440268275862069 ], [ 29.782937848101266, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15147, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.440268275862069 ], [ 29.783207341772151, -1.44053775862069 ], [ 29.783476835443039, -1.44053775862069 ], [ 29.783476835443039, -1.440268275862069 ], [ 29.783207341772151, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15148, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.440268275862069 ], [ 29.783476835443039, -1.44053775862069 ], [ 29.784015822784809, -1.44053775862069 ], [ 29.784015822784809, -1.440268275862069 ], [ 29.783476835443039, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15149, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.440268275862069 ], [ 29.784015822784809, -1.44053775862069 ], [ 29.784285316455698, -1.44053775862069 ], [ 29.784285316455698, -1.440268275862069 ], [ 29.784015822784809, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15150, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.440268275862069 ], [ 29.784285316455698, -1.44053775862069 ], [ 29.784554810126583, -1.44053775862069 ], [ 29.784554810126583, -1.440268275862069 ], [ 29.784285316455698, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15151, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.440268275862069 ], [ 29.784554810126583, -1.44053775862069 ], [ 29.784824303797468, -1.44053775862069 ], [ 29.784824303797468, -1.440268275862069 ], [ 29.784554810126583, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15152, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.440268275862069 ], [ 29.784824303797468, -1.44053775862069 ], [ 29.785093797468356, -1.44053775862069 ], [ 29.785093797468356, -1.440268275862069 ], [ 29.784824303797468, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15153, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.440268275862069 ], [ 29.785093797468356, -1.44080724137931 ], [ 29.785363291139241, -1.44080724137931 ], [ 29.785363291139241, -1.440268275862069 ], [ 29.785093797468356, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15154, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.440268275862069 ], [ 29.785363291139241, -1.44080724137931 ], [ 29.785632784810126, -1.44080724137931 ], [ 29.785632784810126, -1.440268275862069 ], [ 29.785363291139241, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15155, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.440268275862069 ], [ 29.789405696202532, -1.44053775862069 ], [ 29.789675189873417, -1.44053775862069 ], [ 29.789675189873417, -1.440268275862069 ], [ 29.789405696202532, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15156, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.440268275862069 ], [ 29.789675189873417, -1.44053775862069 ], [ 29.789944683544302, -1.44053775862069 ], [ 29.789944683544302, -1.440268275862069 ], [ 29.789675189873417, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15157, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.440268275862069 ], [ 29.789944683544302, -1.44053775862069 ], [ 29.790214177215191, -1.44053775862069 ], [ 29.790214177215191, -1.440268275862069 ], [ 29.789944683544302, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15158, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.440268275862069 ], [ 29.790214177215191, -1.44053775862069 ], [ 29.790483670886076, -1.44053775862069 ], [ 29.790483670886076, -1.440268275862069 ], [ 29.790214177215191, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15159, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.440268275862069 ], [ 29.790483670886076, -1.44053775862069 ], [ 29.790753164556961, -1.44053775862069 ], [ 29.790753164556961, -1.440268275862069 ], [ 29.790483670886076, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15160, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.440268275862069 ], [ 29.790753164556961, -1.44053775862069 ], [ 29.791022658227849, -1.44053775862069 ], [ 29.791022658227849, -1.440268275862069 ], [ 29.790753164556961, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15161, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.440268275862069 ], [ 29.791022658227849, -1.44053775862069 ], [ 29.791292151898734, -1.44053775862069 ], [ 29.791292151898734, -1.440268275862069 ], [ 29.791022658227849, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15162, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.440268275862069 ], [ 29.791292151898734, -1.44053775862069 ], [ 29.791561645569619, -1.44053775862069 ], [ 29.791561645569619, -1.440268275862069 ], [ 29.791292151898734, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15163, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.440268275862069 ], [ 29.791561645569619, -1.44053775862069 ], [ 29.791831139240507, -1.44053775862069 ], [ 29.791831139240507, -1.440268275862069 ], [ 29.791561645569619, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15164, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.440268275862069 ], [ 29.791831139240507, -1.44053775862069 ], [ 29.792100632911392, -1.44053775862069 ], [ 29.792100632911392, -1.440268275862069 ], [ 29.791831139240507, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15165, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.440268275862069 ], [ 29.792100632911392, -1.44053775862069 ], [ 29.792370126582277, -1.44053775862069 ], [ 29.792370126582277, -1.440268275862069 ], [ 29.792100632911392, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15166, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.440268275862069 ], [ 29.792370126582277, -1.44053775862069 ], [ 29.792909113924051, -1.44053775862069 ], [ 29.792909113924051, -1.440268275862069 ], [ 29.792370126582277, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15167, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.440268275862069 ], [ 29.792909113924051, -1.44053775862069 ], [ 29.793178607594935, -1.44053775862069 ], [ 29.793178607594935, -1.440268275862069 ], [ 29.792909113924051, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15168, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.440268275862069 ], [ 29.793178607594935, -1.44053775862069 ], [ 29.793448101265824, -1.44053775862069 ], [ 29.793448101265824, -1.440268275862069 ], [ 29.793178607594935, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15169, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.440268275862069 ], [ 29.793448101265824, -1.44053775862069 ], [ 29.793717594936709, -1.44053775862069 ], [ 29.793717594936709, -1.440268275862069 ], [ 29.793448101265824, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15170, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.440268275862069 ], [ 29.793717594936709, -1.44053775862069 ], [ 29.793987088607594, -1.44053775862069 ], [ 29.793987088607594, -1.440268275862069 ], [ 29.793717594936709, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15171, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.440268275862069 ], [ 29.793987088607594, -1.44053775862069 ], [ 29.794256582278482, -1.44053775862069 ], [ 29.794256582278482, -1.440268275862069 ], [ 29.793987088607594, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15172, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.440268275862069 ], [ 29.794256582278482, -1.44053775862069 ], [ 29.794526075949367, -1.44053775862069 ], [ 29.794526075949367, -1.440268275862069 ], [ 29.794256582278482, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15173, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.440268275862069 ], [ 29.794526075949367, -1.44053775862069 ], [ 29.794795569620252, -1.44053775862069 ], [ 29.794795569620252, -1.440268275862069 ], [ 29.794526075949367, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15174, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.440268275862069 ], [ 29.794795569620252, -1.44053775862069 ], [ 29.79506506329114, -1.44053775862069 ], [ 29.79506506329114, -1.440268275862069 ], [ 29.794795569620252, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15175, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.440268275862069 ], [ 29.79506506329114, -1.44053775862069 ], [ 29.795334556962025, -1.44053775862069 ], [ 29.795334556962025, -1.440268275862069 ], [ 29.79506506329114, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15176, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.440268275862069 ], [ 29.795334556962025, -1.44053775862069 ], [ 29.79560405063291, -1.44053775862069 ], [ 29.79560405063291, -1.440268275862069 ], [ 29.795334556962025, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15177, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.440268275862069 ], [ 29.79560405063291, -1.44053775862069 ], [ 29.795873544303799, -1.44053775862069 ], [ 29.795873544303799, -1.440268275862069 ], [ 29.79560405063291, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15178, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.440268275862069 ], [ 29.795873544303799, -1.44053775862069 ], [ 29.796143037974684, -1.44053775862069 ], [ 29.796143037974684, -1.440268275862069 ], [ 29.795873544303799, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15179, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.440268275862069 ], [ 29.796143037974684, -1.44053775862069 ], [ 29.796412531645569, -1.44053775862069 ], [ 29.796412531645569, -1.440268275862069 ], [ 29.796143037974684, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15180, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.440268275862069 ], [ 29.796412531645569, -1.44053775862069 ], [ 29.796682025316457, -1.44053775862069 ], [ 29.796682025316457, -1.440268275862069 ], [ 29.796412531645569, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15181, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.440268275862069 ], [ 29.796682025316457, -1.44053775862069 ], [ 29.796951518987342, -1.44053775862069 ], [ 29.796951518987342, -1.440268275862069 ], [ 29.796682025316457, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15182, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.440268275862069 ], [ 29.796951518987342, -1.44053775862069 ], [ 29.797221012658227, -1.44053775862069 ], [ 29.797221012658227, -1.440268275862069 ], [ 29.796951518987342, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15183, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.440268275862069 ], [ 29.797221012658227, -1.44053775862069 ], [ 29.797490506329115, -1.44053775862069 ], [ 29.797490506329115, -1.440268275862069 ], [ 29.797221012658227, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15184, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.440268275862069 ], [ 29.797490506329115, -1.44053775862069 ], [ 29.79776, -1.44053775862069 ], [ 29.79776, -1.440268275862069 ], [ 29.797490506329115, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15185, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.440268275862069 ], [ 29.79776, -1.44053775862069 ], [ 29.799376962025317, -1.44053775862069 ], [ 29.799376962025317, -1.440268275862069 ], [ 29.79776, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15186, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.440268275862069 ], [ 29.799376962025317, -1.44053775862069 ], [ 29.799646455696202, -1.44053775862069 ], [ 29.799646455696202, -1.440268275862069 ], [ 29.799376962025317, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15187, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.440268275862069 ], [ 29.799646455696202, -1.44053775862069 ], [ 29.79991594936709, -1.44053775862069 ], [ 29.79991594936709, -1.440268275862069 ], [ 29.799646455696202, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15188, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.440268275862069 ], [ 29.79991594936709, -1.44053775862069 ], [ 29.800185443037975, -1.44053775862069 ], [ 29.800185443037975, -1.440268275862069 ], [ 29.79991594936709, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15189, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.440268275862069 ], [ 29.800185443037975, -1.44053775862069 ], [ 29.80045493670886, -1.44053775862069 ], [ 29.80045493670886, -1.440268275862069 ], [ 29.800185443037975, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15190, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.440268275862069 ], [ 29.80045493670886, -1.44053775862069 ], [ 29.800724430379748, -1.44053775862069 ], [ 29.800724430379748, -1.440268275862069 ], [ 29.80045493670886, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15191, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.440268275862069 ], [ 29.800724430379748, -1.44053775862069 ], [ 29.800993924050633, -1.44053775862069 ], [ 29.800993924050633, -1.440268275862069 ], [ 29.800724430379748, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15192, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.440268275862069 ], [ 29.800993924050633, -1.44053775862069 ], [ 29.801263417721518, -1.44053775862069 ], [ 29.801263417721518, -1.440268275862069 ], [ 29.800993924050633, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15193, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.440268275862069 ], [ 29.801263417721518, -1.44053775862069 ], [ 29.801532911392407, -1.44053775862069 ], [ 29.801532911392407, -1.440268275862069 ], [ 29.801263417721518, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15194, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.440268275862069 ], [ 29.801532911392407, -1.44053775862069 ], [ 29.802071898734177, -1.44053775862069 ], [ 29.802071898734177, -1.440268275862069 ], [ 29.801532911392407, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15195, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.440268275862069 ], [ 29.802071898734177, -1.44053775862069 ], [ 29.802341392405065, -1.44053775862069 ], [ 29.802341392405065, -1.440268275862069 ], [ 29.802071898734177, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15196, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.440268275862069 ], [ 29.802341392405065, -1.44053775862069 ], [ 29.80261088607595, -1.44053775862069 ], [ 29.80261088607595, -1.440268275862069 ], [ 29.802341392405065, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15197, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.440268275862069 ], [ 29.80261088607595, -1.44053775862069 ], [ 29.802880379746835, -1.44053775862069 ], [ 29.802880379746835, -1.440268275862069 ], [ 29.80261088607595, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15198, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.440268275862069 ], [ 29.802880379746835, -1.44053775862069 ], [ 29.803149873417723, -1.44053775862069 ], [ 29.803149873417723, -1.440268275862069 ], [ 29.802880379746835, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15199, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.440268275862069 ], [ 29.803149873417723, -1.44053775862069 ], [ 29.803419367088608, -1.44053775862069 ], [ 29.803419367088608, -1.440268275862069 ], [ 29.803149873417723, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15200, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.440268275862069 ], [ 29.803419367088608, -1.44053775862069 ], [ 29.803688860759493, -1.44053775862069 ], [ 29.803688860759493, -1.440268275862069 ], [ 29.803419367088608, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15201, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.440268275862069 ], [ 29.803688860759493, -1.44053775862069 ], [ 29.803958354430382, -1.44053775862069 ], [ 29.803958354430382, -1.440268275862069 ], [ 29.803688860759493, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15202, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.440268275862069 ], [ 29.803958354430382, -1.44053775862069 ], [ 29.804227848101267, -1.44053775862069 ], [ 29.804227848101267, -1.440268275862069 ], [ 29.803958354430382, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15203, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.440268275862069 ], [ 29.804227848101267, -1.44053775862069 ], [ 29.804497341772151, -1.44053775862069 ], [ 29.804497341772151, -1.440268275862069 ], [ 29.804227848101267, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15204, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.440268275862069 ], [ 29.804497341772151, -1.44053775862069 ], [ 29.80476683544304, -1.44053775862069 ], [ 29.80476683544304, -1.440268275862069 ], [ 29.804497341772151, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15205, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.440268275862069 ], [ 29.80476683544304, -1.44053775862069 ], [ 29.805036329113925, -1.44053775862069 ], [ 29.805036329113925, -1.440268275862069 ], [ 29.80476683544304, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15206, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.440268275862069 ], [ 29.805036329113925, -1.44053775862069 ], [ 29.80530582278481, -1.44053775862069 ], [ 29.80530582278481, -1.440268275862069 ], [ 29.805036329113925, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15207, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.440268275862069 ], [ 29.80530582278481, -1.44053775862069 ], [ 29.805575316455698, -1.44053775862069 ], [ 29.805575316455698, -1.440268275862069 ], [ 29.80530582278481, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15208, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.440268275862069 ], [ 29.805575316455698, -1.44053775862069 ], [ 29.805844810126583, -1.44053775862069 ], [ 29.805844810126583, -1.440268275862069 ], [ 29.805575316455698, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15209, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.440268275862069 ], [ 29.805844810126583, -1.44053775862069 ], [ 29.806114303797468, -1.44053775862069 ], [ 29.806114303797468, -1.440268275862069 ], [ 29.805844810126583, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15210, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.440268275862069 ], [ 29.806114303797468, -1.44053775862069 ], [ 29.806383797468357, -1.44053775862069 ], [ 29.806383797468357, -1.440268275862069 ], [ 29.806114303797468, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15211, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.440268275862069 ], [ 29.806383797468357, -1.44053775862069 ], [ 29.806653291139241, -1.44053775862069 ], [ 29.806653291139241, -1.440268275862069 ], [ 29.806383797468357, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15212, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.440268275862069 ], [ 29.806653291139241, -1.44053775862069 ], [ 29.807731265822785, -1.44053775862069 ], [ 29.807731265822785, -1.440268275862069 ], [ 29.806653291139241, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15213, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.440268275862069 ], [ 29.807731265822785, -1.44053775862069 ], [ 29.808000759493673, -1.44053775862069 ], [ 29.808000759493673, -1.440268275862069 ], [ 29.807731265822785, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15214, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.440268275862069 ], [ 29.808000759493673, -1.44053775862069 ], [ 29.808270253164558, -1.44053775862069 ], [ 29.808270253164558, -1.440268275862069 ], [ 29.808000759493673, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15215, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.440268275862069 ], [ 29.808270253164558, -1.44053775862069 ], [ 29.808539746835443, -1.44053775862069 ], [ 29.808539746835443, -1.440268275862069 ], [ 29.808270253164558, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15216, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.440268275862069 ], [ 29.808539746835443, -1.44053775862069 ], [ 29.808809240506328, -1.44053775862069 ], [ 29.808809240506328, -1.440268275862069 ], [ 29.808539746835443, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15217, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.440268275862069 ], [ 29.809078734177216, -1.441076724137931 ], [ 29.809348227848101, -1.441076724137931 ], [ 29.809348227848101, -1.440268275862069 ], [ 29.809078734177216, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15218, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.440268275862069 ], [ 29.809348227848101, -1.441076724137931 ], [ 29.809617721518986, -1.441076724137931 ], [ 29.809617721518986, -1.440268275862069 ], [ 29.809348227848101, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15219, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.440268275862069 ], [ 29.809617721518986, -1.44053775862069 ], [ 29.809887215189875, -1.44053775862069 ], [ 29.809887215189875, -1.440268275862069 ], [ 29.809617721518986, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15220, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.440268275862069 ], [ 29.809887215189875, -1.44053775862069 ], [ 29.81015670886076, -1.44053775862069 ], [ 29.81015670886076, -1.440268275862069 ], [ 29.809887215189875, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15221, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.440268275862069 ], [ 29.81015670886076, -1.44053775862069 ], [ 29.810426202531644, -1.44053775862069 ], [ 29.810426202531644, -1.440268275862069 ], [ 29.81015670886076, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15222, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.440268275862069 ], [ 29.810426202531644, -1.44053775862069 ], [ 29.810965189873418, -1.44053775862069 ], [ 29.810965189873418, -1.440268275862069 ], [ 29.810426202531644, -1.440268275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15223, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744669746835442, -1.44053775862069 ], [ 29.744669746835442, -1.44080724137931 ], [ 29.744939240506326, -1.44080724137931 ], [ 29.744939240506326, -1.44053775862069 ], [ 29.744669746835442, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15224, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.44053775862069 ], [ 29.744939240506326, -1.44080724137931 ], [ 29.745208734177215, -1.44080724137931 ], [ 29.745208734177215, -1.44053775862069 ], [ 29.744939240506326, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15225, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.44053775862069 ], [ 29.745208734177215, -1.44080724137931 ], [ 29.7454782278481, -1.44080724137931 ], [ 29.7454782278481, -1.44053775862069 ], [ 29.745208734177215, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15226, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.44053775862069 ], [ 29.7454782278481, -1.44080724137931 ], [ 29.745747721518985, -1.44080724137931 ], [ 29.745747721518985, -1.44053775862069 ], [ 29.7454782278481, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15227, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.44053775862069 ], [ 29.745747721518985, -1.44080724137931 ], [ 29.746017215189873, -1.44080724137931 ], [ 29.746017215189873, -1.44053775862069 ], [ 29.745747721518985, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15228, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.44053775862069 ], [ 29.746017215189873, -1.44080724137931 ], [ 29.746286708860758, -1.44080724137931 ], [ 29.746286708860758, -1.44053775862069 ], [ 29.746017215189873, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15229, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.44053775862069 ], [ 29.746286708860758, -1.44080724137931 ], [ 29.746556202531643, -1.44080724137931 ], [ 29.746556202531643, -1.44053775862069 ], [ 29.746286708860758, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15230, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.44053775862069 ], [ 29.746556202531643, -1.44080724137931 ], [ 29.746825696202531, -1.44080724137931 ], [ 29.746825696202531, -1.44053775862069 ], [ 29.746556202531643, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15231, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.44053775862069 ], [ 29.746825696202531, -1.44080724137931 ], [ 29.747095189873416, -1.44080724137931 ], [ 29.747095189873416, -1.44053775862069 ], [ 29.746825696202531, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15232, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.44053775862069 ], [ 29.747095189873416, -1.44080724137931 ], [ 29.747364683544301, -1.44080724137931 ], [ 29.747364683544301, -1.44053775862069 ], [ 29.747095189873416, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15233, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.44053775862069 ], [ 29.747364683544301, -1.44080724137931 ], [ 29.747903670886075, -1.44080724137931 ], [ 29.747903670886075, -1.44053775862069 ], [ 29.747364683544301, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15234, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.44053775862069 ], [ 29.747903670886075, -1.44080724137931 ], [ 29.74817316455696, -1.44080724137931 ], [ 29.74817316455696, -1.44053775862069 ], [ 29.747903670886075, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15235, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.44053775862069 ], [ 29.74817316455696, -1.44080724137931 ], [ 29.748442658227848, -1.44080724137931 ], [ 29.748442658227848, -1.44053775862069 ], [ 29.74817316455696, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15236, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.44053775862069 ], [ 29.748442658227848, -1.44080724137931 ], [ 29.748712151898733, -1.44080724137931 ], [ 29.748712151898733, -1.44053775862069 ], [ 29.748442658227848, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15237, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.44053775862069 ], [ 29.748712151898733, -1.44080724137931 ], [ 29.748981645569618, -1.44080724137931 ], [ 29.748981645569618, -1.44053775862069 ], [ 29.748712151898733, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15238, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.44053775862069 ], [ 29.748981645569618, -1.44080724137931 ], [ 29.749251139240506, -1.44080724137931 ], [ 29.749251139240506, -1.44053775862069 ], [ 29.748981645569618, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15239, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.44053775862069 ], [ 29.749251139240506, -1.44080724137931 ], [ 29.749520632911391, -1.44080724137931 ], [ 29.749520632911391, -1.44053775862069 ], [ 29.749251139240506, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15240, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.44053775862069 ], [ 29.749520632911391, -1.44080724137931 ], [ 29.749790126582276, -1.44080724137931 ], [ 29.749790126582276, -1.44053775862069 ], [ 29.749520632911391, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15241, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.44053775862069 ], [ 29.749790126582276, -1.44080724137931 ], [ 29.750059620253165, -1.44080724137931 ], [ 29.750059620253165, -1.44053775862069 ], [ 29.749790126582276, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15242, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.44053775862069 ], [ 29.750059620253165, -1.44080724137931 ], [ 29.75032911392405, -1.44080724137931 ], [ 29.75032911392405, -1.44053775862069 ], [ 29.750059620253165, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15243, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.44053775862069 ], [ 29.75032911392405, -1.44080724137931 ], [ 29.750598607594934, -1.44080724137931 ], [ 29.750598607594934, -1.44053775862069 ], [ 29.75032911392405, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15244, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.44053775862069 ], [ 29.750598607594934, -1.44080724137931 ], [ 29.750868101265823, -1.44080724137931 ], [ 29.750868101265823, -1.44053775862069 ], [ 29.750598607594934, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15245, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.44053775862069 ], [ 29.750868101265823, -1.44080724137931 ], [ 29.751137594936708, -1.44080724137931 ], [ 29.751137594936708, -1.44053775862069 ], [ 29.750868101265823, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15246, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.44053775862069 ], [ 29.751137594936708, -1.44080724137931 ], [ 29.751407088607593, -1.44080724137931 ], [ 29.751407088607593, -1.44053775862069 ], [ 29.751137594936708, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15247, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.44053775862069 ], [ 29.751407088607593, -1.44080724137931 ], [ 29.751676582278481, -1.44080724137931 ], [ 29.751676582278481, -1.44053775862069 ], [ 29.751407088607593, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15248, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.44053775862069 ], [ 29.751676582278481, -1.44080724137931 ], [ 29.751946075949366, -1.44080724137931 ], [ 29.751946075949366, -1.44053775862069 ], [ 29.751676582278481, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15249, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.44053775862069 ], [ 29.751946075949366, -1.44080724137931 ], [ 29.752215569620251, -1.44080724137931 ], [ 29.752215569620251, -1.44053775862069 ], [ 29.751946075949366, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15250, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.44053775862069 ], [ 29.752215569620251, -1.44080724137931 ], [ 29.75248506329114, -1.44080724137931 ], [ 29.75248506329114, -1.44053775862069 ], [ 29.752215569620251, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15251, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.44053775862069 ], [ 29.75248506329114, -1.44080724137931 ], [ 29.752754556962024, -1.44080724137931 ], [ 29.752754556962024, -1.44053775862069 ], [ 29.75248506329114, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15252, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.44053775862069 ], [ 29.753024050632909, -1.44080724137931 ], [ 29.753293544303798, -1.44080724137931 ], [ 29.753293544303798, -1.44053775862069 ], [ 29.753024050632909, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15253, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.44053775862069 ], [ 29.753293544303798, -1.441076724137931 ], [ 29.753563037974683, -1.441076724137931 ], [ 29.753563037974683, -1.44053775862069 ], [ 29.753293544303798, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15254, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.44053775862069 ], [ 29.753832531645568, -1.44080724137931 ], [ 29.754102025316456, -1.44080724137931 ], [ 29.754102025316456, -1.44053775862069 ], [ 29.753832531645568, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15255, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.44053775862069 ], [ 29.754102025316456, -1.44080724137931 ], [ 29.754371518987341, -1.44080724137931 ], [ 29.754371518987341, -1.44053775862069 ], [ 29.754102025316456, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15256, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.44053775862069 ], [ 29.754641012658226, -1.44080724137931 ], [ 29.754910506329114, -1.44080724137931 ], [ 29.754910506329114, -1.44053775862069 ], [ 29.754641012658226, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15257, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.44053775862069 ], [ 29.754910506329114, -1.44080724137931 ], [ 29.75518, -1.44080724137931 ], [ 29.75518, -1.44053775862069 ], [ 29.754910506329114, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15258, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.44053775862069 ], [ 29.755449493670884, -1.44080724137931 ], [ 29.755718987341773, -1.44080724137931 ], [ 29.755718987341773, -1.44053775862069 ], [ 29.755449493670884, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15259, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.44053775862069 ], [ 29.755718987341773, -1.44080724137931 ], [ 29.755988481012658, -1.44080724137931 ], [ 29.755988481012658, -1.44053775862069 ], [ 29.755718987341773, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15260, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.44053775862069 ], [ 29.755988481012658, -1.44080724137931 ], [ 29.756257974683542, -1.44080724137931 ], [ 29.756257974683542, -1.44053775862069 ], [ 29.755988481012658, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15261, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.44053775862069 ], [ 29.756257974683542, -1.44080724137931 ], [ 29.756796962025316, -1.44080724137931 ], [ 29.756796962025316, -1.44053775862069 ], [ 29.756257974683542, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15262, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.44053775862069 ], [ 29.756796962025316, -1.44080724137931 ], [ 29.757066455696201, -1.44080724137931 ], [ 29.757066455696201, -1.44053775862069 ], [ 29.756796962025316, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15263, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.44053775862069 ], [ 29.757066455696201, -1.44080724137931 ], [ 29.757335949367089, -1.44080724137931 ], [ 29.757335949367089, -1.44053775862069 ], [ 29.757066455696201, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15264, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.44053775862069 ], [ 29.757335949367089, -1.44080724137931 ], [ 29.757605443037974, -1.44080724137931 ], [ 29.757605443037974, -1.44053775862069 ], [ 29.757335949367089, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15265, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.44053775862069 ], [ 29.757605443037974, -1.44080724137931 ], [ 29.757874936708859, -1.44080724137931 ], [ 29.757874936708859, -1.44053775862069 ], [ 29.757605443037974, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15266, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.44053775862069 ], [ 29.757874936708859, -1.44080724137931 ], [ 29.758144430379748, -1.44080724137931 ], [ 29.758144430379748, -1.44053775862069 ], [ 29.757874936708859, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15267, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.44053775862069 ], [ 29.758144430379748, -1.44080724137931 ], [ 29.758413924050632, -1.44080724137931 ], [ 29.758413924050632, -1.44053775862069 ], [ 29.758144430379748, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15268, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.44053775862069 ], [ 29.758413924050632, -1.44080724137931 ], [ 29.758683417721517, -1.44080724137931 ], [ 29.758683417721517, -1.44053775862069 ], [ 29.758413924050632, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15269, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.44053775862069 ], [ 29.758683417721517, -1.44080724137931 ], [ 29.758952911392406, -1.44080724137931 ], [ 29.758952911392406, -1.44053775862069 ], [ 29.758683417721517, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15270, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.44053775862069 ], [ 29.758952911392406, -1.44080724137931 ], [ 29.761917341772151, -1.44080724137931 ], [ 29.761917341772151, -1.44053775862069 ], [ 29.758952911392406, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15271, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.44053775862069 ], [ 29.761917341772151, -1.44080724137931 ], [ 29.762186835443039, -1.44080724137931 ], [ 29.762186835443039, -1.44053775862069 ], [ 29.761917341772151, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15272, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.44053775862069 ], [ 29.762186835443039, -1.44080724137931 ], [ 29.762456329113924, -1.44080724137931 ], [ 29.762456329113924, -1.44053775862069 ], [ 29.762186835443039, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15273, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.44053775862069 ], [ 29.762456329113924, -1.44080724137931 ], [ 29.762725822784809, -1.44080724137931 ], [ 29.762725822784809, -1.44053775862069 ], [ 29.762456329113924, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15274, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.44053775862069 ], [ 29.762725822784809, -1.44080724137931 ], [ 29.762995316455697, -1.44080724137931 ], [ 29.762995316455697, -1.44053775862069 ], [ 29.762725822784809, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15275, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.44053775862069 ], [ 29.762995316455697, -1.44080724137931 ], [ 29.763264810126582, -1.44080724137931 ], [ 29.763264810126582, -1.44053775862069 ], [ 29.762995316455697, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15276, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.44053775862069 ], [ 29.763264810126582, -1.44080724137931 ], [ 29.763534303797467, -1.44080724137931 ], [ 29.763534303797467, -1.44053775862069 ], [ 29.763264810126582, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15277, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.44053775862069 ], [ 29.763534303797467, -1.44080724137931 ], [ 29.763803797468356, -1.44080724137931 ], [ 29.763803797468356, -1.44053775862069 ], [ 29.763534303797467, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15278, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.44053775862069 ], [ 29.763803797468356, -1.44080724137931 ], [ 29.76407329113924, -1.44080724137931 ], [ 29.76407329113924, -1.44053775862069 ], [ 29.763803797468356, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15279, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.44053775862069 ], [ 29.76407329113924, -1.44080724137931 ], [ 29.764342784810125, -1.44080724137931 ], [ 29.764342784810125, -1.44053775862069 ], [ 29.76407329113924, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15280, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.44053775862069 ], [ 29.764342784810125, -1.44080724137931 ], [ 29.764612278481014, -1.44080724137931 ], [ 29.764612278481014, -1.44053775862069 ], [ 29.764342784810125, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15281, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.44053775862069 ], [ 29.764612278481014, -1.44080724137931 ], [ 29.764881772151899, -1.44080724137931 ], [ 29.764881772151899, -1.44053775862069 ], [ 29.764612278481014, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15282, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.44053775862069 ], [ 29.764881772151899, -1.44080724137931 ], [ 29.765151265822784, -1.44080724137931 ], [ 29.765151265822784, -1.44053775862069 ], [ 29.764881772151899, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15283, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.44053775862069 ], [ 29.765151265822784, -1.44080724137931 ], [ 29.765420759493672, -1.44080724137931 ], [ 29.765420759493672, -1.44053775862069 ], [ 29.765151265822784, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15284, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.44053775862069 ], [ 29.765420759493672, -1.44080724137931 ], [ 29.765959746835442, -1.44080724137931 ], [ 29.765959746835442, -1.44053775862069 ], [ 29.765420759493672, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15285, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.44053775862069 ], [ 29.765959746835442, -1.44080724137931 ], [ 29.766229240506327, -1.44080724137931 ], [ 29.766229240506327, -1.44053775862069 ], [ 29.765959746835442, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15286, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.44053775862069 ], [ 29.766229240506327, -1.44080724137931 ], [ 29.766498734177215, -1.44080724137931 ], [ 29.766498734177215, -1.44053775862069 ], [ 29.766229240506327, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15287, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.44053775862069 ], [ 29.766498734177215, -1.44080724137931 ], [ 29.7667682278481, -1.44080724137931 ], [ 29.7667682278481, -1.44053775862069 ], [ 29.766498734177215, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15288, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.44053775862069 ], [ 29.7667682278481, -1.44080724137931 ], [ 29.767037721518985, -1.44080724137931 ], [ 29.767037721518985, -1.44053775862069 ], [ 29.7667682278481, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15289, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.44053775862069 ], [ 29.767037721518985, -1.441076724137931 ], [ 29.767307215189874, -1.441076724137931 ], [ 29.767307215189874, -1.44053775862069 ], [ 29.767037721518985, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15290, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.44053775862069 ], [ 29.767307215189874, -1.44080724137931 ], [ 29.767576708860759, -1.44080724137931 ], [ 29.767576708860759, -1.44053775862069 ], [ 29.767307215189874, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15291, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.44053775862069 ], [ 29.767576708860759, -1.44080724137931 ], [ 29.767846202531643, -1.44080724137931 ], [ 29.767846202531643, -1.44053775862069 ], [ 29.767576708860759, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15292, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.44053775862069 ], [ 29.768115696202532, -1.44080724137931 ], [ 29.768385189873417, -1.44080724137931 ], [ 29.768385189873417, -1.44053775862069 ], [ 29.768115696202532, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15293, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.44053775862069 ], [ 29.768385189873417, -1.44080724137931 ], [ 29.768654683544302, -1.44080724137931 ], [ 29.768654683544302, -1.44053775862069 ], [ 29.768385189873417, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15294, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.44053775862069 ], [ 29.76892417721519, -1.441346206896552 ], [ 29.769193670886075, -1.441346206896552 ], [ 29.769193670886075, -1.44053775862069 ], [ 29.76892417721519, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15295, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.44053775862069 ], [ 29.769193670886075, -1.44080724137931 ], [ 29.76946316455696, -1.44080724137931 ], [ 29.76946316455696, -1.44053775862069 ], [ 29.769193670886075, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15296, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.44053775862069 ], [ 29.769732658227849, -1.44080724137931 ], [ 29.770002151898733, -1.44080724137931 ], [ 29.770002151898733, -1.44053775862069 ], [ 29.769732658227849, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15297, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.44053775862069 ], [ 29.770002151898733, -1.44080724137931 ], [ 29.770271645569618, -1.44080724137931 ], [ 29.770271645569618, -1.44053775862069 ], [ 29.770002151898733, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15298, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.44053775862069 ], [ 29.770271645569618, -1.44080724137931 ], [ 29.770541139240507, -1.44080724137931 ], [ 29.770541139240507, -1.44053775862069 ], [ 29.770271645569618, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15299, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.44053775862069 ], [ 29.770541139240507, -1.44080724137931 ], [ 29.770810632911392, -1.44080724137931 ], [ 29.770810632911392, -1.44053775862069 ], [ 29.770541139240507, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15300, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.44053775862069 ], [ 29.770810632911392, -1.44080724137931 ], [ 29.771080126582277, -1.44080724137931 ], [ 29.771080126582277, -1.44053775862069 ], [ 29.770810632911392, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15301, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.44053775862069 ], [ 29.771080126582277, -1.44080724137931 ], [ 29.771349620253165, -1.44080724137931 ], [ 29.771349620253165, -1.44053775862069 ], [ 29.771080126582277, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15302, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.44053775862069 ], [ 29.771349620253165, -1.44080724137931 ], [ 29.77161911392405, -1.44080724137931 ], [ 29.77161911392405, -1.44053775862069 ], [ 29.771349620253165, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15303, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.44053775862069 ], [ 29.77161911392405, -1.44080724137931 ], [ 29.771888607594935, -1.44080724137931 ], [ 29.771888607594935, -1.44053775862069 ], [ 29.77161911392405, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15304, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.44053775862069 ], [ 29.771888607594935, -1.44080724137931 ], [ 29.772158101265823, -1.44080724137931 ], [ 29.772158101265823, -1.44053775862069 ], [ 29.771888607594935, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15305, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.44053775862069 ], [ 29.772158101265823, -1.44080724137931 ], [ 29.772427594936708, -1.44080724137931 ], [ 29.772427594936708, -1.44053775862069 ], [ 29.772158101265823, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15306, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.44053775862069 ], [ 29.772427594936708, -1.44080724137931 ], [ 29.772697088607593, -1.44080724137931 ], [ 29.772697088607593, -1.44053775862069 ], [ 29.772427594936708, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15307, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.44053775862069 ], [ 29.772697088607593, -1.44080724137931 ], [ 29.772966582278482, -1.44080724137931 ], [ 29.772966582278482, -1.44053775862069 ], [ 29.772697088607593, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15308, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.44053775862069 ], [ 29.772966582278482, -1.44080724137931 ], [ 29.773236075949367, -1.44080724137931 ], [ 29.773236075949367, -1.44053775862069 ], [ 29.772966582278482, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15309, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.44053775862069 ], [ 29.773236075949367, -1.44080724137931 ], [ 29.773505569620252, -1.44080724137931 ], [ 29.773505569620252, -1.44053775862069 ], [ 29.773236075949367, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15310, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.44053775862069 ], [ 29.773505569620252, -1.44080724137931 ], [ 29.77377506329114, -1.44080724137931 ], [ 29.77377506329114, -1.44053775862069 ], [ 29.773505569620252, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15311, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.44053775862069 ], [ 29.77377506329114, -1.44080724137931 ], [ 29.774044556962025, -1.44080724137931 ], [ 29.774044556962025, -1.44053775862069 ], [ 29.77377506329114, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15312, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.44053775862069 ], [ 29.774044556962025, -1.44080724137931 ], [ 29.77431405063291, -1.44080724137931 ], [ 29.77431405063291, -1.44053775862069 ], [ 29.774044556962025, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15313, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.44053775862069 ], [ 29.77431405063291, -1.44080724137931 ], [ 29.774853037974683, -1.44080724137931 ], [ 29.774853037974683, -1.44053775862069 ], [ 29.77431405063291, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15314, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.44053775862069 ], [ 29.774853037974683, -1.44080724137931 ], [ 29.775122531645568, -1.44080724137931 ], [ 29.775122531645568, -1.44053775862069 ], [ 29.774853037974683, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15315, "El": 132 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.44053775862069 ], [ 29.775122531645568, -1.44080724137931 ], [ 29.775392025316457, -1.44080724137931 ], [ 29.775392025316457, -1.44053775862069 ], [ 29.775122531645568, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15316, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.44053775862069 ], [ 29.775931012658226, -1.441076724137931 ], [ 29.776200506329115, -1.441076724137931 ], [ 29.776200506329115, -1.44053775862069 ], [ 29.775931012658226, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15317, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.44053775862069 ], [ 29.77647, -1.441076724137931 ], [ 29.776739493670885, -1.441076724137931 ], [ 29.776739493670885, -1.44053775862069 ], [ 29.77647, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15318, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.44053775862069 ], [ 29.777278481012658, -1.441076724137931 ], [ 29.777547974683543, -1.441076724137931 ], [ 29.777547974683543, -1.44053775862069 ], [ 29.777278481012658, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15319, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.44053775862069 ], [ 29.778086962025316, -1.441076724137931 ], [ 29.778356455696201, -1.441076724137931 ], [ 29.778356455696201, -1.44053775862069 ], [ 29.778086962025316, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15320, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.44053775862069 ], [ 29.77862594936709, -1.44080724137931 ], [ 29.778895443037975, -1.44080724137931 ], [ 29.778895443037975, -1.44053775862069 ], [ 29.77862594936709, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15321, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.44053775862069 ], [ 29.778895443037975, -1.441076724137931 ], [ 29.77916493670886, -1.441076724137931 ], [ 29.77916493670886, -1.44053775862069 ], [ 29.778895443037975, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15322, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.44053775862069 ], [ 29.779434430379748, -1.44080724137931 ], [ 29.779703924050633, -1.44080724137931 ], [ 29.779703924050633, -1.44053775862069 ], [ 29.779434430379748, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15323, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.44053775862069 ], [ 29.779703924050633, -1.44080724137931 ], [ 29.779973417721518, -1.44080724137931 ], [ 29.779973417721518, -1.44053775862069 ], [ 29.779703924050633, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15324, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.44053775862069 ], [ 29.780242911392406, -1.44080724137931 ], [ 29.780512405063291, -1.44080724137931 ], [ 29.780512405063291, -1.44053775862069 ], [ 29.780242911392406, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15325, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.44053775862069 ], [ 29.780512405063291, -1.44080724137931 ], [ 29.780781898734176, -1.44080724137931 ], [ 29.780781898734176, -1.44053775862069 ], [ 29.780512405063291, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15326, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.44053775862069 ], [ 29.780781898734176, -1.441076724137931 ], [ 29.781051392405065, -1.441076724137931 ], [ 29.781051392405065, -1.44053775862069 ], [ 29.780781898734176, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15327, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.44053775862069 ], [ 29.781051392405065, -1.44080724137931 ], [ 29.78132088607595, -1.44080724137931 ], [ 29.78132088607595, -1.44053775862069 ], [ 29.781051392405065, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15328, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.44053775862069 ], [ 29.78132088607595, -1.44080724137931 ], [ 29.781590379746834, -1.44080724137931 ], [ 29.781590379746834, -1.44053775862069 ], [ 29.78132088607595, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15329, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.44053775862069 ], [ 29.781590379746834, -1.44080724137931 ], [ 29.781859873417723, -1.44080724137931 ], [ 29.781859873417723, -1.44053775862069 ], [ 29.781590379746834, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15330, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.44053775862069 ], [ 29.781859873417723, -1.44080724137931 ], [ 29.782129367088608, -1.44080724137931 ], [ 29.782129367088608, -1.44053775862069 ], [ 29.781859873417723, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15331, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.44053775862069 ], [ 29.782129367088608, -1.44080724137931 ], [ 29.782398860759493, -1.44080724137931 ], [ 29.782398860759493, -1.44053775862069 ], [ 29.782129367088608, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15332, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.44053775862069 ], [ 29.782398860759493, -1.44080724137931 ], [ 29.782668354430381, -1.44080724137931 ], [ 29.782668354430381, -1.44053775862069 ], [ 29.782398860759493, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15333, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.44053775862069 ], [ 29.782668354430381, -1.44080724137931 ], [ 29.782937848101266, -1.44080724137931 ], [ 29.782937848101266, -1.44053775862069 ], [ 29.782668354430381, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15334, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.44053775862069 ], [ 29.782937848101266, -1.44080724137931 ], [ 29.783207341772151, -1.44080724137931 ], [ 29.783207341772151, -1.44053775862069 ], [ 29.782937848101266, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15335, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.44053775862069 ], [ 29.783207341772151, -1.44080724137931 ], [ 29.783476835443039, -1.44080724137931 ], [ 29.783476835443039, -1.44053775862069 ], [ 29.783207341772151, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15336, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.44053775862069 ], [ 29.783476835443039, -1.44080724137931 ], [ 29.784015822784809, -1.44080724137931 ], [ 29.784015822784809, -1.44053775862069 ], [ 29.783476835443039, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15337, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.44053775862069 ], [ 29.784015822784809, -1.44080724137931 ], [ 29.784285316455698, -1.44080724137931 ], [ 29.784285316455698, -1.44053775862069 ], [ 29.784015822784809, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15338, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.44053775862069 ], [ 29.784285316455698, -1.44080724137931 ], [ 29.784554810126583, -1.44080724137931 ], [ 29.784554810126583, -1.44053775862069 ], [ 29.784285316455698, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15339, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.44053775862069 ], [ 29.784554810126583, -1.44080724137931 ], [ 29.784824303797468, -1.44080724137931 ], [ 29.784824303797468, -1.44053775862069 ], [ 29.784554810126583, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15340, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.44053775862069 ], [ 29.784824303797468, -1.441076724137931 ], [ 29.785093797468356, -1.441076724137931 ], [ 29.785093797468356, -1.44053775862069 ], [ 29.784824303797468, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15341, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.44053775862069 ], [ 29.785632784810126, -1.44080724137931 ], [ 29.785902278481014, -1.44080724137931 ], [ 29.785902278481014, -1.44053775862069 ], [ 29.785632784810126, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15342, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.44053775862069 ], [ 29.785902278481014, -1.44080724137931 ], [ 29.786171772151899, -1.44080724137931 ], [ 29.786171772151899, -1.44053775862069 ], [ 29.785902278481014, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15343, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.44053775862069 ], [ 29.786171772151899, -1.44080724137931 ], [ 29.786441265822784, -1.44080724137931 ], [ 29.786441265822784, -1.44053775862069 ], [ 29.786171772151899, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15344, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.44053775862069 ], [ 29.789405696202532, -1.441076724137931 ], [ 29.789675189873417, -1.441076724137931 ], [ 29.789675189873417, -1.44053775862069 ], [ 29.789405696202532, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15345, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.44053775862069 ], [ 29.789675189873417, -1.44080724137931 ], [ 29.789944683544302, -1.44080724137931 ], [ 29.789944683544302, -1.44053775862069 ], [ 29.789675189873417, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15346, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.44053775862069 ], [ 29.789944683544302, -1.44080724137931 ], [ 29.790214177215191, -1.44080724137931 ], [ 29.790214177215191, -1.44053775862069 ], [ 29.789944683544302, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15347, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.44053775862069 ], [ 29.790214177215191, -1.44080724137931 ], [ 29.790483670886076, -1.44080724137931 ], [ 29.790483670886076, -1.44053775862069 ], [ 29.790214177215191, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15348, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.44053775862069 ], [ 29.790483670886076, -1.44080724137931 ], [ 29.790753164556961, -1.44080724137931 ], [ 29.790753164556961, -1.44053775862069 ], [ 29.790483670886076, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15349, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.44053775862069 ], [ 29.790753164556961, -1.44080724137931 ], [ 29.791022658227849, -1.44080724137931 ], [ 29.791022658227849, -1.44053775862069 ], [ 29.790753164556961, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15350, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.44053775862069 ], [ 29.791022658227849, -1.44080724137931 ], [ 29.791292151898734, -1.44080724137931 ], [ 29.791292151898734, -1.44053775862069 ], [ 29.791022658227849, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15351, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.44053775862069 ], [ 29.791292151898734, -1.44080724137931 ], [ 29.791561645569619, -1.44080724137931 ], [ 29.791561645569619, -1.44053775862069 ], [ 29.791292151898734, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15352, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.44053775862069 ], [ 29.791561645569619, -1.44080724137931 ], [ 29.791831139240507, -1.44080724137931 ], [ 29.791831139240507, -1.44053775862069 ], [ 29.791561645569619, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15353, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.44053775862069 ], [ 29.791831139240507, -1.44080724137931 ], [ 29.792100632911392, -1.44080724137931 ], [ 29.792100632911392, -1.44053775862069 ], [ 29.791831139240507, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15354, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.44053775862069 ], [ 29.792100632911392, -1.44080724137931 ], [ 29.792370126582277, -1.44080724137931 ], [ 29.792370126582277, -1.44053775862069 ], [ 29.792100632911392, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15355, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.44053775862069 ], [ 29.792370126582277, -1.44080724137931 ], [ 29.792909113924051, -1.44080724137931 ], [ 29.792909113924051, -1.44053775862069 ], [ 29.792370126582277, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15356, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.44053775862069 ], [ 29.792909113924051, -1.44080724137931 ], [ 29.793178607594935, -1.44080724137931 ], [ 29.793178607594935, -1.44053775862069 ], [ 29.792909113924051, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15357, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.44053775862069 ], [ 29.793178607594935, -1.44080724137931 ], [ 29.793448101265824, -1.44080724137931 ], [ 29.793448101265824, -1.44053775862069 ], [ 29.793178607594935, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15358, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.44053775862069 ], [ 29.793448101265824, -1.44080724137931 ], [ 29.793717594936709, -1.44080724137931 ], [ 29.793717594936709, -1.44053775862069 ], [ 29.793448101265824, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15359, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.44053775862069 ], [ 29.793717594936709, -1.441076724137931 ], [ 29.793987088607594, -1.441076724137931 ], [ 29.793987088607594, -1.44053775862069 ], [ 29.793717594936709, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15360, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.44053775862069 ], [ 29.793987088607594, -1.44080724137931 ], [ 29.794256582278482, -1.44080724137931 ], [ 29.794256582278482, -1.44053775862069 ], [ 29.793987088607594, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15361, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.44053775862069 ], [ 29.794256582278482, -1.441076724137931 ], [ 29.794526075949367, -1.441076724137931 ], [ 29.794526075949367, -1.44053775862069 ], [ 29.794256582278482, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15362, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.44053775862069 ], [ 29.794526075949367, -1.44080724137931 ], [ 29.794795569620252, -1.44080724137931 ], [ 29.794795569620252, -1.44053775862069 ], [ 29.794526075949367, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15363, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.44053775862069 ], [ 29.794795569620252, -1.44080724137931 ], [ 29.79506506329114, -1.44080724137931 ], [ 29.79506506329114, -1.44053775862069 ], [ 29.794795569620252, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15364, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.44053775862069 ], [ 29.79506506329114, -1.44080724137931 ], [ 29.795334556962025, -1.44080724137931 ], [ 29.795334556962025, -1.44053775862069 ], [ 29.79506506329114, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15365, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.44053775862069 ], [ 29.795334556962025, -1.44080724137931 ], [ 29.79560405063291, -1.44080724137931 ], [ 29.79560405063291, -1.44053775862069 ], [ 29.795334556962025, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15366, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.44053775862069 ], [ 29.79560405063291, -1.44080724137931 ], [ 29.795873544303799, -1.44080724137931 ], [ 29.795873544303799, -1.44053775862069 ], [ 29.79560405063291, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15367, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.44053775862069 ], [ 29.795873544303799, -1.44080724137931 ], [ 29.796143037974684, -1.44080724137931 ], [ 29.796143037974684, -1.44053775862069 ], [ 29.795873544303799, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15368, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.44053775862069 ], [ 29.796143037974684, -1.44080724137931 ], [ 29.796412531645569, -1.44080724137931 ], [ 29.796412531645569, -1.44053775862069 ], [ 29.796143037974684, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15369, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.44053775862069 ], [ 29.796412531645569, -1.44080724137931 ], [ 29.796682025316457, -1.44080724137931 ], [ 29.796682025316457, -1.44053775862069 ], [ 29.796412531645569, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15370, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.44053775862069 ], [ 29.796682025316457, -1.44080724137931 ], [ 29.796951518987342, -1.44080724137931 ], [ 29.796951518987342, -1.44053775862069 ], [ 29.796682025316457, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15371, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.44053775862069 ], [ 29.796951518987342, -1.44080724137931 ], [ 29.797221012658227, -1.44080724137931 ], [ 29.797221012658227, -1.44053775862069 ], [ 29.796951518987342, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15372, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.44053775862069 ], [ 29.797221012658227, -1.44080724137931 ], [ 29.797490506329115, -1.44080724137931 ], [ 29.797490506329115, -1.44053775862069 ], [ 29.797221012658227, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15373, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.44053775862069 ], [ 29.797490506329115, -1.44080724137931 ], [ 29.79776, -1.44080724137931 ], [ 29.79776, -1.44053775862069 ], [ 29.797490506329115, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15374, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.44053775862069 ], [ 29.79776, -1.44080724137931 ], [ 29.799376962025317, -1.44080724137931 ], [ 29.799376962025317, -1.44053775862069 ], [ 29.79776, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15375, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.44053775862069 ], [ 29.799376962025317, -1.44080724137931 ], [ 29.799646455696202, -1.44080724137931 ], [ 29.799646455696202, -1.44053775862069 ], [ 29.799376962025317, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15376, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.44053775862069 ], [ 29.799646455696202, -1.44080724137931 ], [ 29.79991594936709, -1.44080724137931 ], [ 29.79991594936709, -1.44053775862069 ], [ 29.799646455696202, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15377, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.44053775862069 ], [ 29.79991594936709, -1.44080724137931 ], [ 29.800185443037975, -1.44080724137931 ], [ 29.800185443037975, -1.44053775862069 ], [ 29.79991594936709, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15378, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.44053775862069 ], [ 29.800185443037975, -1.44080724137931 ], [ 29.80045493670886, -1.44080724137931 ], [ 29.80045493670886, -1.44053775862069 ], [ 29.800185443037975, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15379, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.44053775862069 ], [ 29.80045493670886, -1.44080724137931 ], [ 29.800724430379748, -1.44080724137931 ], [ 29.800724430379748, -1.44053775862069 ], [ 29.80045493670886, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15380, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.44053775862069 ], [ 29.800724430379748, -1.44080724137931 ], [ 29.800993924050633, -1.44080724137931 ], [ 29.800993924050633, -1.44053775862069 ], [ 29.800724430379748, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15381, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.44053775862069 ], [ 29.800993924050633, -1.44080724137931 ], [ 29.801263417721518, -1.44080724137931 ], [ 29.801263417721518, -1.44053775862069 ], [ 29.800993924050633, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15382, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.44053775862069 ], [ 29.801263417721518, -1.44080724137931 ], [ 29.801532911392407, -1.44080724137931 ], [ 29.801532911392407, -1.44053775862069 ], [ 29.801263417721518, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15383, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.44053775862069 ], [ 29.801532911392407, -1.44080724137931 ], [ 29.802071898734177, -1.44080724137931 ], [ 29.802071898734177, -1.44053775862069 ], [ 29.801532911392407, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15384, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.44053775862069 ], [ 29.802071898734177, -1.44080724137931 ], [ 29.802341392405065, -1.44080724137931 ], [ 29.802341392405065, -1.44053775862069 ], [ 29.802071898734177, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15385, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.44053775862069 ], [ 29.802341392405065, -1.44080724137931 ], [ 29.80261088607595, -1.44080724137931 ], [ 29.80261088607595, -1.44053775862069 ], [ 29.802341392405065, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15386, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.44053775862069 ], [ 29.80261088607595, -1.44080724137931 ], [ 29.802880379746835, -1.44080724137931 ], [ 29.802880379746835, -1.44053775862069 ], [ 29.80261088607595, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15387, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.44053775862069 ], [ 29.802880379746835, -1.44080724137931 ], [ 29.803149873417723, -1.44080724137931 ], [ 29.803149873417723, -1.44053775862069 ], [ 29.802880379746835, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15388, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.44053775862069 ], [ 29.803149873417723, -1.44080724137931 ], [ 29.803419367088608, -1.44080724137931 ], [ 29.803419367088608, -1.44053775862069 ], [ 29.803149873417723, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15389, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.44053775862069 ], [ 29.803419367088608, -1.44080724137931 ], [ 29.803688860759493, -1.44080724137931 ], [ 29.803688860759493, -1.44053775862069 ], [ 29.803419367088608, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15390, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.44053775862069 ], [ 29.803688860759493, -1.44080724137931 ], [ 29.803958354430382, -1.44080724137931 ], [ 29.803958354430382, -1.44053775862069 ], [ 29.803688860759493, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15391, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.44053775862069 ], [ 29.803958354430382, -1.44080724137931 ], [ 29.804227848101267, -1.44080724137931 ], [ 29.804227848101267, -1.44053775862069 ], [ 29.803958354430382, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15392, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.44053775862069 ], [ 29.804227848101267, -1.44080724137931 ], [ 29.804497341772151, -1.44080724137931 ], [ 29.804497341772151, -1.44053775862069 ], [ 29.804227848101267, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15393, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.44053775862069 ], [ 29.804497341772151, -1.44080724137931 ], [ 29.80476683544304, -1.44080724137931 ], [ 29.80476683544304, -1.44053775862069 ], [ 29.804497341772151, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15394, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.44053775862069 ], [ 29.80476683544304, -1.44080724137931 ], [ 29.805036329113925, -1.44080724137931 ], [ 29.805036329113925, -1.44053775862069 ], [ 29.80476683544304, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15395, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.44053775862069 ], [ 29.805036329113925, -1.44080724137931 ], [ 29.80530582278481, -1.44080724137931 ], [ 29.80530582278481, -1.44053775862069 ], [ 29.805036329113925, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15396, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.44053775862069 ], [ 29.80530582278481, -1.44080724137931 ], [ 29.805575316455698, -1.44080724137931 ], [ 29.805575316455698, -1.44053775862069 ], [ 29.80530582278481, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15397, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.44053775862069 ], [ 29.805575316455698, -1.44080724137931 ], [ 29.805844810126583, -1.44080724137931 ], [ 29.805844810126583, -1.44053775862069 ], [ 29.805575316455698, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15398, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.44053775862069 ], [ 29.805844810126583, -1.44080724137931 ], [ 29.806114303797468, -1.44080724137931 ], [ 29.806114303797468, -1.44053775862069 ], [ 29.805844810126583, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15399, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.44053775862069 ], [ 29.806114303797468, -1.44080724137931 ], [ 29.806383797468357, -1.44080724137931 ], [ 29.806383797468357, -1.44053775862069 ], [ 29.806114303797468, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15400, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.44053775862069 ], [ 29.806383797468357, -1.44080724137931 ], [ 29.806653291139241, -1.44080724137931 ], [ 29.806653291139241, -1.44053775862069 ], [ 29.806383797468357, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15401, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.44053775862069 ], [ 29.806653291139241, -1.44080724137931 ], [ 29.807731265822785, -1.44080724137931 ], [ 29.807731265822785, -1.44053775862069 ], [ 29.806653291139241, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15402, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.44053775862069 ], [ 29.807731265822785, -1.44080724137931 ], [ 29.808000759493673, -1.44080724137931 ], [ 29.808000759493673, -1.44053775862069 ], [ 29.807731265822785, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15403, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.44053775862069 ], [ 29.808000759493673, -1.44080724137931 ], [ 29.808270253164558, -1.44080724137931 ], [ 29.808270253164558, -1.44053775862069 ], [ 29.808000759493673, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15404, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.44053775862069 ], [ 29.808270253164558, -1.44080724137931 ], [ 29.808539746835443, -1.44080724137931 ], [ 29.808539746835443, -1.44053775862069 ], [ 29.808270253164558, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15405, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.44053775862069 ], [ 29.808539746835443, -1.44080724137931 ], [ 29.808809240506328, -1.44080724137931 ], [ 29.808809240506328, -1.44053775862069 ], [ 29.808539746835443, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15406, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.44053775862069 ], [ 29.808809240506328, -1.441076724137931 ], [ 29.809078734177216, -1.441076724137931 ], [ 29.809078734177216, -1.44053775862069 ], [ 29.808809240506328, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15407, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.44053775862069 ], [ 29.809617721518986, -1.441346206896552 ], [ 29.809887215189875, -1.441346206896552 ], [ 29.809887215189875, -1.44053775862069 ], [ 29.809617721518986, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15408, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.44053775862069 ], [ 29.809887215189875, -1.441346206896552 ], [ 29.81015670886076, -1.441346206896552 ], [ 29.81015670886076, -1.44053775862069 ], [ 29.809887215189875, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15409, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.44053775862069 ], [ 29.81015670886076, -1.441615689655172 ], [ 29.810426202531644, -1.441615689655172 ], [ 29.810426202531644, -1.44053775862069 ], [ 29.81015670886076, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15410, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.744939240506326, -1.44080724137931 ], [ 29.744939240506326, -1.441076724137931 ], [ 29.745208734177215, -1.441076724137931 ], [ 29.745208734177215, -1.44080724137931 ], [ 29.744939240506326, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15411, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.44080724137931 ], [ 29.745208734177215, -1.441076724137931 ], [ 29.7454782278481, -1.441076724137931 ], [ 29.7454782278481, -1.44080724137931 ], [ 29.745208734177215, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15412, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.44080724137931 ], [ 29.7454782278481, -1.441076724137931 ], [ 29.745747721518985, -1.441076724137931 ], [ 29.745747721518985, -1.44080724137931 ], [ 29.7454782278481, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15413, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.44080724137931 ], [ 29.745747721518985, -1.441076724137931 ], [ 29.746017215189873, -1.441076724137931 ], [ 29.746017215189873, -1.44080724137931 ], [ 29.745747721518985, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15414, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.44080724137931 ], [ 29.746017215189873, -1.441076724137931 ], [ 29.746286708860758, -1.441076724137931 ], [ 29.746286708860758, -1.44080724137931 ], [ 29.746017215189873, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15415, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.44080724137931 ], [ 29.746286708860758, -1.441076724137931 ], [ 29.746556202531643, -1.441076724137931 ], [ 29.746556202531643, -1.44080724137931 ], [ 29.746286708860758, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15416, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.44080724137931 ], [ 29.746556202531643, -1.441076724137931 ], [ 29.746825696202531, -1.441076724137931 ], [ 29.746825696202531, -1.44080724137931 ], [ 29.746556202531643, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15417, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.44080724137931 ], [ 29.746825696202531, -1.441076724137931 ], [ 29.747095189873416, -1.441076724137931 ], [ 29.747095189873416, -1.44080724137931 ], [ 29.746825696202531, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15418, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.44080724137931 ], [ 29.747095189873416, -1.441076724137931 ], [ 29.747364683544301, -1.441076724137931 ], [ 29.747364683544301, -1.44080724137931 ], [ 29.747095189873416, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15419, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.44080724137931 ], [ 29.747364683544301, -1.441076724137931 ], [ 29.747903670886075, -1.441076724137931 ], [ 29.747903670886075, -1.44080724137931 ], [ 29.747364683544301, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15420, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.44080724137931 ], [ 29.747903670886075, -1.441076724137931 ], [ 29.74817316455696, -1.441076724137931 ], [ 29.74817316455696, -1.44080724137931 ], [ 29.747903670886075, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15421, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.44080724137931 ], [ 29.74817316455696, -1.441076724137931 ], [ 29.748442658227848, -1.441076724137931 ], [ 29.748442658227848, -1.44080724137931 ], [ 29.74817316455696, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15422, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.44080724137931 ], [ 29.748442658227848, -1.441076724137931 ], [ 29.748712151898733, -1.441076724137931 ], [ 29.748712151898733, -1.44080724137931 ], [ 29.748442658227848, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15423, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.44080724137931 ], [ 29.748712151898733, -1.441076724137931 ], [ 29.748981645569618, -1.441076724137931 ], [ 29.748981645569618, -1.44080724137931 ], [ 29.748712151898733, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15424, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.44080724137931 ], [ 29.748981645569618, -1.441076724137931 ], [ 29.749251139240506, -1.441076724137931 ], [ 29.749251139240506, -1.44080724137931 ], [ 29.748981645569618, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15425, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.44080724137931 ], [ 29.749251139240506, -1.441076724137931 ], [ 29.749520632911391, -1.441076724137931 ], [ 29.749520632911391, -1.44080724137931 ], [ 29.749251139240506, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15426, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.44080724137931 ], [ 29.749520632911391, -1.441076724137931 ], [ 29.749790126582276, -1.441076724137931 ], [ 29.749790126582276, -1.44080724137931 ], [ 29.749520632911391, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15427, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.44080724137931 ], [ 29.749790126582276, -1.441076724137931 ], [ 29.750059620253165, -1.441076724137931 ], [ 29.750059620253165, -1.44080724137931 ], [ 29.749790126582276, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15428, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.44080724137931 ], [ 29.750059620253165, -1.441076724137931 ], [ 29.75032911392405, -1.441076724137931 ], [ 29.75032911392405, -1.44080724137931 ], [ 29.750059620253165, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15429, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.44080724137931 ], [ 29.75032911392405, -1.441076724137931 ], [ 29.750598607594934, -1.441076724137931 ], [ 29.750598607594934, -1.44080724137931 ], [ 29.75032911392405, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15430, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.44080724137931 ], [ 29.750598607594934, -1.441076724137931 ], [ 29.750868101265823, -1.441076724137931 ], [ 29.750868101265823, -1.44080724137931 ], [ 29.750598607594934, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15431, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.44080724137931 ], [ 29.750868101265823, -1.441076724137931 ], [ 29.751137594936708, -1.441076724137931 ], [ 29.751137594936708, -1.44080724137931 ], [ 29.750868101265823, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15432, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.44080724137931 ], [ 29.751137594936708, -1.441076724137931 ], [ 29.751407088607593, -1.441076724137931 ], [ 29.751407088607593, -1.44080724137931 ], [ 29.751137594936708, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15433, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.44080724137931 ], [ 29.751407088607593, -1.441076724137931 ], [ 29.751676582278481, -1.441076724137931 ], [ 29.751676582278481, -1.44080724137931 ], [ 29.751407088607593, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15434, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.44080724137931 ], [ 29.751676582278481, -1.441076724137931 ], [ 29.751946075949366, -1.441076724137931 ], [ 29.751946075949366, -1.44080724137931 ], [ 29.751676582278481, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15435, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.44080724137931 ], [ 29.751946075949366, -1.441076724137931 ], [ 29.752215569620251, -1.441076724137931 ], [ 29.752215569620251, -1.44080724137931 ], [ 29.751946075949366, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15436, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.44080724137931 ], [ 29.752215569620251, -1.441076724137931 ], [ 29.75248506329114, -1.441076724137931 ], [ 29.75248506329114, -1.44080724137931 ], [ 29.752215569620251, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15437, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.44080724137931 ], [ 29.75248506329114, -1.441076724137931 ], [ 29.752754556962024, -1.441076724137931 ], [ 29.752754556962024, -1.44080724137931 ], [ 29.75248506329114, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15438, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.44080724137931 ], [ 29.753024050632909, -1.441076724137931 ], [ 29.753293544303798, -1.441076724137931 ], [ 29.753293544303798, -1.44080724137931 ], [ 29.753024050632909, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15439, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.44080724137931 ], [ 29.753563037974683, -1.441076724137931 ], [ 29.753832531645568, -1.441076724137931 ], [ 29.753832531645568, -1.44080724137931 ], [ 29.753563037974683, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15440, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.44080724137931 ], [ 29.753832531645568, -1.441076724137931 ], [ 29.754102025316456, -1.441076724137931 ], [ 29.754102025316456, -1.44080724137931 ], [ 29.753832531645568, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15441, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.44080724137931 ], [ 29.754102025316456, -1.441346206896552 ], [ 29.754371518987341, -1.441346206896552 ], [ 29.754371518987341, -1.44080724137931 ], [ 29.754102025316456, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15442, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.44080724137931 ], [ 29.754371518987341, -1.441076724137931 ], [ 29.754641012658226, -1.441076724137931 ], [ 29.754641012658226, -1.44080724137931 ], [ 29.754371518987341, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15443, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.44080724137931 ], [ 29.754641012658226, -1.441076724137931 ], [ 29.754910506329114, -1.441076724137931 ], [ 29.754910506329114, -1.44080724137931 ], [ 29.754641012658226, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15444, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.44080724137931 ], [ 29.754910506329114, -1.441076724137931 ], [ 29.75518, -1.441076724137931 ], [ 29.75518, -1.44080724137931 ], [ 29.754910506329114, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15445, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.44080724137931 ], [ 29.75518, -1.441076724137931 ], [ 29.755449493670884, -1.441076724137931 ], [ 29.755449493670884, -1.44080724137931 ], [ 29.75518, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15446, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.44080724137931 ], [ 29.755449493670884, -1.441076724137931 ], [ 29.755718987341773, -1.441076724137931 ], [ 29.755718987341773, -1.44080724137931 ], [ 29.755449493670884, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15447, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.44080724137931 ], [ 29.755718987341773, -1.441076724137931 ], [ 29.755988481012658, -1.441076724137931 ], [ 29.755988481012658, -1.44080724137931 ], [ 29.755718987341773, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15448, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.44080724137931 ], [ 29.755988481012658, -1.441076724137931 ], [ 29.756257974683542, -1.441076724137931 ], [ 29.756257974683542, -1.44080724137931 ], [ 29.755988481012658, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15449, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.44080724137931 ], [ 29.756257974683542, -1.441076724137931 ], [ 29.756796962025316, -1.441076724137931 ], [ 29.756796962025316, -1.44080724137931 ], [ 29.756257974683542, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15450, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.44080724137931 ], [ 29.756796962025316, -1.441076724137931 ], [ 29.757066455696201, -1.441076724137931 ], [ 29.757066455696201, -1.44080724137931 ], [ 29.756796962025316, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15451, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.44080724137931 ], [ 29.757066455696201, -1.441076724137931 ], [ 29.757335949367089, -1.441076724137931 ], [ 29.757335949367089, -1.44080724137931 ], [ 29.757066455696201, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15452, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.44080724137931 ], [ 29.757335949367089, -1.441076724137931 ], [ 29.757605443037974, -1.441076724137931 ], [ 29.757605443037974, -1.44080724137931 ], [ 29.757335949367089, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15453, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.44080724137931 ], [ 29.757605443037974, -1.441076724137931 ], [ 29.757874936708859, -1.441076724137931 ], [ 29.757874936708859, -1.44080724137931 ], [ 29.757605443037974, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15454, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.44080724137931 ], [ 29.757874936708859, -1.441076724137931 ], [ 29.758144430379748, -1.441076724137931 ], [ 29.758144430379748, -1.44080724137931 ], [ 29.757874936708859, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15455, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.44080724137931 ], [ 29.758144430379748, -1.441076724137931 ], [ 29.758413924050632, -1.441076724137931 ], [ 29.758413924050632, -1.44080724137931 ], [ 29.758144430379748, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15456, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.44080724137931 ], [ 29.758413924050632, -1.441076724137931 ], [ 29.758683417721517, -1.441076724137931 ], [ 29.758683417721517, -1.44080724137931 ], [ 29.758413924050632, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15457, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.44080724137931 ], [ 29.758683417721517, -1.441076724137931 ], [ 29.758952911392406, -1.441076724137931 ], [ 29.758952911392406, -1.44080724137931 ], [ 29.758683417721517, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15458, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.44080724137931 ], [ 29.758952911392406, -1.441076724137931 ], [ 29.761917341772151, -1.441076724137931 ], [ 29.761917341772151, -1.44080724137931 ], [ 29.758952911392406, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15459, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.44080724137931 ], [ 29.761917341772151, -1.441076724137931 ], [ 29.762186835443039, -1.441076724137931 ], [ 29.762186835443039, -1.44080724137931 ], [ 29.761917341772151, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15460, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.44080724137931 ], [ 29.762186835443039, -1.441076724137931 ], [ 29.762456329113924, -1.441076724137931 ], [ 29.762456329113924, -1.44080724137931 ], [ 29.762186835443039, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15461, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.44080724137931 ], [ 29.762456329113924, -1.441076724137931 ], [ 29.762725822784809, -1.441076724137931 ], [ 29.762725822784809, -1.44080724137931 ], [ 29.762456329113924, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15462, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.44080724137931 ], [ 29.762725822784809, -1.441076724137931 ], [ 29.762995316455697, -1.441076724137931 ], [ 29.762995316455697, -1.44080724137931 ], [ 29.762725822784809, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15463, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.44080724137931 ], [ 29.762995316455697, -1.441076724137931 ], [ 29.763264810126582, -1.441076724137931 ], [ 29.763264810126582, -1.44080724137931 ], [ 29.762995316455697, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15464, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.44080724137931 ], [ 29.763264810126582, -1.441076724137931 ], [ 29.763534303797467, -1.441076724137931 ], [ 29.763534303797467, -1.44080724137931 ], [ 29.763264810126582, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15465, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.44080724137931 ], [ 29.763534303797467, -1.441076724137931 ], [ 29.763803797468356, -1.441076724137931 ], [ 29.763803797468356, -1.44080724137931 ], [ 29.763534303797467, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15466, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.44080724137931 ], [ 29.763803797468356, -1.441076724137931 ], [ 29.76407329113924, -1.441076724137931 ], [ 29.76407329113924, -1.44080724137931 ], [ 29.763803797468356, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15467, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.44080724137931 ], [ 29.76407329113924, -1.441076724137931 ], [ 29.764342784810125, -1.441076724137931 ], [ 29.764342784810125, -1.44080724137931 ], [ 29.76407329113924, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15468, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.44080724137931 ], [ 29.764342784810125, -1.441076724137931 ], [ 29.764612278481014, -1.441076724137931 ], [ 29.764612278481014, -1.44080724137931 ], [ 29.764342784810125, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15469, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.44080724137931 ], [ 29.764612278481014, -1.441076724137931 ], [ 29.764881772151899, -1.441076724137931 ], [ 29.764881772151899, -1.44080724137931 ], [ 29.764612278481014, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15470, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.44080724137931 ], [ 29.764881772151899, -1.441076724137931 ], [ 29.765151265822784, -1.441076724137931 ], [ 29.765151265822784, -1.44080724137931 ], [ 29.764881772151899, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15471, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.44080724137931 ], [ 29.765151265822784, -1.441076724137931 ], [ 29.765420759493672, -1.441076724137931 ], [ 29.765420759493672, -1.44080724137931 ], [ 29.765151265822784, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15472, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.44080724137931 ], [ 29.765420759493672, -1.441076724137931 ], [ 29.765959746835442, -1.441076724137931 ], [ 29.765959746835442, -1.44080724137931 ], [ 29.765420759493672, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15473, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.44080724137931 ], [ 29.765959746835442, -1.441076724137931 ], [ 29.766229240506327, -1.441076724137931 ], [ 29.766229240506327, -1.44080724137931 ], [ 29.765959746835442, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15474, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.44080724137931 ], [ 29.766229240506327, -1.441076724137931 ], [ 29.766498734177215, -1.441076724137931 ], [ 29.766498734177215, -1.44080724137931 ], [ 29.766229240506327, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15475, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.44080724137931 ], [ 29.766498734177215, -1.441076724137931 ], [ 29.7667682278481, -1.441076724137931 ], [ 29.7667682278481, -1.44080724137931 ], [ 29.766498734177215, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15476, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.44080724137931 ], [ 29.7667682278481, -1.441076724137931 ], [ 29.767037721518985, -1.441076724137931 ], [ 29.767037721518985, -1.44080724137931 ], [ 29.7667682278481, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15477, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.44080724137931 ], [ 29.767307215189874, -1.441076724137931 ], [ 29.767576708860759, -1.441076724137931 ], [ 29.767576708860759, -1.44080724137931 ], [ 29.767307215189874, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15478, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.44080724137931 ], [ 29.767576708860759, -1.441076724137931 ], [ 29.767846202531643, -1.441076724137931 ], [ 29.767846202531643, -1.44080724137931 ], [ 29.767576708860759, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15479, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.44080724137931 ], [ 29.767846202531643, -1.441076724137931 ], [ 29.768115696202532, -1.441076724137931 ], [ 29.768115696202532, -1.44080724137931 ], [ 29.767846202531643, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15480, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.44080724137931 ], [ 29.768115696202532, -1.441346206896552 ], [ 29.768385189873417, -1.441346206896552 ], [ 29.768385189873417, -1.44080724137931 ], [ 29.768115696202532, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15481, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.44080724137931 ], [ 29.768385189873417, -1.441076724137931 ], [ 29.768654683544302, -1.441076724137931 ], [ 29.768654683544302, -1.44080724137931 ], [ 29.768385189873417, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15482, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.44080724137931 ], [ 29.768654683544302, -1.441076724137931 ], [ 29.76892417721519, -1.441076724137931 ], [ 29.76892417721519, -1.44080724137931 ], [ 29.768654683544302, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15483, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.44080724137931 ], [ 29.769193670886075, -1.441076724137931 ], [ 29.76946316455696, -1.441076724137931 ], [ 29.76946316455696, -1.44080724137931 ], [ 29.769193670886075, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15484, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.44080724137931 ], [ 29.76946316455696, -1.441076724137931 ], [ 29.769732658227849, -1.441076724137931 ], [ 29.769732658227849, -1.44080724137931 ], [ 29.76946316455696, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15485, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.44080724137931 ], [ 29.769732658227849, -1.441076724137931 ], [ 29.770002151898733, -1.441076724137931 ], [ 29.770002151898733, -1.44080724137931 ], [ 29.769732658227849, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15486, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.44080724137931 ], [ 29.770002151898733, -1.441076724137931 ], [ 29.770271645569618, -1.441076724137931 ], [ 29.770271645569618, -1.44080724137931 ], [ 29.770002151898733, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15487, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.44080724137931 ], [ 29.770271645569618, -1.441076724137931 ], [ 29.770541139240507, -1.441076724137931 ], [ 29.770541139240507, -1.44080724137931 ], [ 29.770271645569618, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15488, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.44080724137931 ], [ 29.770541139240507, -1.441076724137931 ], [ 29.770810632911392, -1.441076724137931 ], [ 29.770810632911392, -1.44080724137931 ], [ 29.770541139240507, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15489, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.44080724137931 ], [ 29.770810632911392, -1.441076724137931 ], [ 29.771080126582277, -1.441076724137931 ], [ 29.771080126582277, -1.44080724137931 ], [ 29.770810632911392, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15490, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.44080724137931 ], [ 29.771080126582277, -1.441076724137931 ], [ 29.771349620253165, -1.441076724137931 ], [ 29.771349620253165, -1.44080724137931 ], [ 29.771080126582277, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15491, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.44080724137931 ], [ 29.771349620253165, -1.441076724137931 ], [ 29.77161911392405, -1.441076724137931 ], [ 29.77161911392405, -1.44080724137931 ], [ 29.771349620253165, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15492, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.44080724137931 ], [ 29.77161911392405, -1.441076724137931 ], [ 29.771888607594935, -1.441076724137931 ], [ 29.771888607594935, -1.44080724137931 ], [ 29.77161911392405, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15493, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.44080724137931 ], [ 29.771888607594935, -1.441076724137931 ], [ 29.772158101265823, -1.441076724137931 ], [ 29.772158101265823, -1.44080724137931 ], [ 29.771888607594935, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15494, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.44080724137931 ], [ 29.772158101265823, -1.441076724137931 ], [ 29.772427594936708, -1.441076724137931 ], [ 29.772427594936708, -1.44080724137931 ], [ 29.772158101265823, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15495, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.44080724137931 ], [ 29.772427594936708, -1.441076724137931 ], [ 29.772697088607593, -1.441076724137931 ], [ 29.772697088607593, -1.44080724137931 ], [ 29.772427594936708, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15496, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.44080724137931 ], [ 29.772697088607593, -1.441076724137931 ], [ 29.772966582278482, -1.441076724137931 ], [ 29.772966582278482, -1.44080724137931 ], [ 29.772697088607593, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15497, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.44080724137931 ], [ 29.772966582278482, -1.441076724137931 ], [ 29.773236075949367, -1.441076724137931 ], [ 29.773236075949367, -1.44080724137931 ], [ 29.772966582278482, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15498, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.44080724137931 ], [ 29.773236075949367, -1.441076724137931 ], [ 29.773505569620252, -1.441076724137931 ], [ 29.773505569620252, -1.44080724137931 ], [ 29.773236075949367, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15499, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.44080724137931 ], [ 29.773505569620252, -1.441076724137931 ], [ 29.77377506329114, -1.441076724137931 ], [ 29.77377506329114, -1.44080724137931 ], [ 29.773505569620252, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15500, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.44080724137931 ], [ 29.77377506329114, -1.441076724137931 ], [ 29.774044556962025, -1.441076724137931 ], [ 29.774044556962025, -1.44080724137931 ], [ 29.77377506329114, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15501, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.44080724137931 ], [ 29.774044556962025, -1.441076724137931 ], [ 29.77431405063291, -1.441076724137931 ], [ 29.77431405063291, -1.44080724137931 ], [ 29.774044556962025, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15502, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.44080724137931 ], [ 29.77431405063291, -1.441076724137931 ], [ 29.774853037974683, -1.441076724137931 ], [ 29.774853037974683, -1.44080724137931 ], [ 29.77431405063291, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15503, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.44080724137931 ], [ 29.774853037974683, -1.441076724137931 ], [ 29.775122531645568, -1.441076724137931 ], [ 29.775122531645568, -1.44080724137931 ], [ 29.774853037974683, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15504, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.44080724137931 ], [ 29.775122531645568, -1.441076724137931 ], [ 29.775392025316457, -1.441076724137931 ], [ 29.775392025316457, -1.44080724137931 ], [ 29.775122531645568, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15505, "El": 131 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.44080724137931 ], [ 29.775392025316457, -1.441076724137931 ], [ 29.775661518987341, -1.441076724137931 ], [ 29.775661518987341, -1.44080724137931 ], [ 29.775392025316457, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15506, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.44080724137931 ], [ 29.776200506329115, -1.441346206896552 ], [ 29.77647, -1.441346206896552 ], [ 29.77647, -1.44080724137931 ], [ 29.776200506329115, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15507, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.44080724137931 ], [ 29.776739493670885, -1.441076724137931 ], [ 29.777008987341773, -1.441076724137931 ], [ 29.777008987341773, -1.44080724137931 ], [ 29.776739493670885, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15508, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.44080724137931 ], [ 29.777008987341773, -1.441346206896552 ], [ 29.777278481012658, -1.441346206896552 ], [ 29.777278481012658, -1.44080724137931 ], [ 29.777008987341773, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15509, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.44080724137931 ], [ 29.777547974683543, -1.441076724137931 ], [ 29.777817468354431, -1.441076724137931 ], [ 29.777817468354431, -1.44080724137931 ], [ 29.777547974683543, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15510, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.44080724137931 ], [ 29.777817468354431, -1.441346206896552 ], [ 29.778086962025316, -1.441346206896552 ], [ 29.778086962025316, -1.44080724137931 ], [ 29.777817468354431, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15511, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.44080724137931 ], [ 29.778356455696201, -1.441076724137931 ], [ 29.77862594936709, -1.441076724137931 ], [ 29.77862594936709, -1.44080724137931 ], [ 29.778356455696201, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15512, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.44080724137931 ], [ 29.77862594936709, -1.441076724137931 ], [ 29.778895443037975, -1.441076724137931 ], [ 29.778895443037975, -1.44080724137931 ], [ 29.77862594936709, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15513, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.44080724137931 ], [ 29.77916493670886, -1.441076724137931 ], [ 29.779434430379748, -1.441076724137931 ], [ 29.779434430379748, -1.44080724137931 ], [ 29.77916493670886, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15514, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.44080724137931 ], [ 29.779434430379748, -1.441076724137931 ], [ 29.779703924050633, -1.441076724137931 ], [ 29.779703924050633, -1.44080724137931 ], [ 29.779434430379748, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15515, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.44080724137931 ], [ 29.779703924050633, -1.441346206896552 ], [ 29.779973417721518, -1.441346206896552 ], [ 29.779973417721518, -1.44080724137931 ], [ 29.779703924050633, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15516, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.44080724137931 ], [ 29.779973417721518, -1.441076724137931 ], [ 29.780242911392406, -1.441076724137931 ], [ 29.780242911392406, -1.44080724137931 ], [ 29.779973417721518, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15517, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.44080724137931 ], [ 29.780242911392406, -1.441076724137931 ], [ 29.780512405063291, -1.441076724137931 ], [ 29.780512405063291, -1.44080724137931 ], [ 29.780242911392406, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15518, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.44080724137931 ], [ 29.780512405063291, -1.441076724137931 ], [ 29.780781898734176, -1.441076724137931 ], [ 29.780781898734176, -1.44080724137931 ], [ 29.780512405063291, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15519, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.44080724137931 ], [ 29.781051392405065, -1.441076724137931 ], [ 29.78132088607595, -1.441076724137931 ], [ 29.78132088607595, -1.44080724137931 ], [ 29.781051392405065, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15520, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.44080724137931 ], [ 29.78132088607595, -1.441076724137931 ], [ 29.781590379746834, -1.441076724137931 ], [ 29.781590379746834, -1.44080724137931 ], [ 29.78132088607595, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15521, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.44080724137931 ], [ 29.781590379746834, -1.441076724137931 ], [ 29.781859873417723, -1.441076724137931 ], [ 29.781859873417723, -1.44080724137931 ], [ 29.781590379746834, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15522, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.44080724137931 ], [ 29.781859873417723, -1.441076724137931 ], [ 29.782129367088608, -1.441076724137931 ], [ 29.782129367088608, -1.44080724137931 ], [ 29.781859873417723, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15523, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.44080724137931 ], [ 29.782129367088608, -1.441076724137931 ], [ 29.782398860759493, -1.441076724137931 ], [ 29.782398860759493, -1.44080724137931 ], [ 29.782129367088608, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15524, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.44080724137931 ], [ 29.782398860759493, -1.441076724137931 ], [ 29.782668354430381, -1.441076724137931 ], [ 29.782668354430381, -1.44080724137931 ], [ 29.782398860759493, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15525, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.44080724137931 ], [ 29.782668354430381, -1.441076724137931 ], [ 29.782937848101266, -1.441076724137931 ], [ 29.782937848101266, -1.44080724137931 ], [ 29.782668354430381, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15526, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.44080724137931 ], [ 29.782937848101266, -1.441076724137931 ], [ 29.783207341772151, -1.441076724137931 ], [ 29.783207341772151, -1.44080724137931 ], [ 29.782937848101266, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15527, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.44080724137931 ], [ 29.783207341772151, -1.441076724137931 ], [ 29.783476835443039, -1.441076724137931 ], [ 29.783476835443039, -1.44080724137931 ], [ 29.783207341772151, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15528, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.44080724137931 ], [ 29.783476835443039, -1.441076724137931 ], [ 29.784015822784809, -1.441076724137931 ], [ 29.784015822784809, -1.44080724137931 ], [ 29.783476835443039, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15529, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.44080724137931 ], [ 29.784015822784809, -1.441076724137931 ], [ 29.784285316455698, -1.441076724137931 ], [ 29.784285316455698, -1.44080724137931 ], [ 29.784015822784809, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15530, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.44080724137931 ], [ 29.784285316455698, -1.441076724137931 ], [ 29.784554810126583, -1.441076724137931 ], [ 29.784554810126583, -1.44080724137931 ], [ 29.784285316455698, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15531, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.44080724137931 ], [ 29.784554810126583, -1.441076724137931 ], [ 29.784824303797468, -1.441076724137931 ], [ 29.784824303797468, -1.44080724137931 ], [ 29.784554810126583, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15532, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.44080724137931 ], [ 29.785093797468356, -1.441076724137931 ], [ 29.785363291139241, -1.441076724137931 ], [ 29.785363291139241, -1.44080724137931 ], [ 29.785093797468356, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15533, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.44080724137931 ], [ 29.785363291139241, -1.441076724137931 ], [ 29.785632784810126, -1.441076724137931 ], [ 29.785632784810126, -1.44080724137931 ], [ 29.785363291139241, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15534, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.44080724137931 ], [ 29.785632784810126, -1.441076724137931 ], [ 29.785902278481014, -1.441076724137931 ], [ 29.785902278481014, -1.44080724137931 ], [ 29.785632784810126, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15535, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.44080724137931 ], [ 29.789675189873417, -1.441076724137931 ], [ 29.789944683544302, -1.441076724137931 ], [ 29.789944683544302, -1.44080724137931 ], [ 29.789675189873417, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15536, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.44080724137931 ], [ 29.789944683544302, -1.441076724137931 ], [ 29.790214177215191, -1.441076724137931 ], [ 29.790214177215191, -1.44080724137931 ], [ 29.789944683544302, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15537, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.44080724137931 ], [ 29.790214177215191, -1.441076724137931 ], [ 29.790483670886076, -1.441076724137931 ], [ 29.790483670886076, -1.44080724137931 ], [ 29.790214177215191, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15538, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.44080724137931 ], [ 29.790483670886076, -1.441076724137931 ], [ 29.790753164556961, -1.441076724137931 ], [ 29.790753164556961, -1.44080724137931 ], [ 29.790483670886076, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15539, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.44080724137931 ], [ 29.790753164556961, -1.441076724137931 ], [ 29.791022658227849, -1.441076724137931 ], [ 29.791022658227849, -1.44080724137931 ], [ 29.790753164556961, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15540, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.44080724137931 ], [ 29.791022658227849, -1.441076724137931 ], [ 29.791292151898734, -1.441076724137931 ], [ 29.791292151898734, -1.44080724137931 ], [ 29.791022658227849, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15541, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.44080724137931 ], [ 29.791292151898734, -1.441076724137931 ], [ 29.791561645569619, -1.441076724137931 ], [ 29.791561645569619, -1.44080724137931 ], [ 29.791292151898734, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15542, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.44080724137931 ], [ 29.791561645569619, -1.441076724137931 ], [ 29.791831139240507, -1.441076724137931 ], [ 29.791831139240507, -1.44080724137931 ], [ 29.791561645569619, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15543, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.44080724137931 ], [ 29.791831139240507, -1.441076724137931 ], [ 29.792100632911392, -1.441076724137931 ], [ 29.792100632911392, -1.44080724137931 ], [ 29.791831139240507, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15544, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.44080724137931 ], [ 29.792100632911392, -1.441076724137931 ], [ 29.792370126582277, -1.441076724137931 ], [ 29.792370126582277, -1.44080724137931 ], [ 29.792100632911392, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15545, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.44080724137931 ], [ 29.792370126582277, -1.441346206896552 ], [ 29.792909113924051, -1.441346206896552 ], [ 29.792909113924051, -1.44080724137931 ], [ 29.792370126582277, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15546, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.44080724137931 ], [ 29.792909113924051, -1.441076724137931 ], [ 29.793178607594935, -1.441076724137931 ], [ 29.793178607594935, -1.44080724137931 ], [ 29.792909113924051, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15547, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.44080724137931 ], [ 29.793178607594935, -1.441076724137931 ], [ 29.793448101265824, -1.441076724137931 ], [ 29.793448101265824, -1.44080724137931 ], [ 29.793178607594935, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15548, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.44080724137931 ], [ 29.793448101265824, -1.441346206896552 ], [ 29.793717594936709, -1.441346206896552 ], [ 29.793717594936709, -1.44080724137931 ], [ 29.793448101265824, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15549, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.44080724137931 ], [ 29.793987088607594, -1.441076724137931 ], [ 29.794256582278482, -1.441076724137931 ], [ 29.794256582278482, -1.44080724137931 ], [ 29.793987088607594, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15550, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.44080724137931 ], [ 29.794526075949367, -1.441346206896552 ], [ 29.794795569620252, -1.441346206896552 ], [ 29.794795569620252, -1.44080724137931 ], [ 29.794526075949367, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15551, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.44080724137931 ], [ 29.794795569620252, -1.441076724137931 ], [ 29.79506506329114, -1.441076724137931 ], [ 29.79506506329114, -1.44080724137931 ], [ 29.794795569620252, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15552, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.44080724137931 ], [ 29.79506506329114, -1.441076724137931 ], [ 29.795334556962025, -1.441076724137931 ], [ 29.795334556962025, -1.44080724137931 ], [ 29.79506506329114, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15553, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.44080724137931 ], [ 29.795334556962025, -1.441346206896552 ], [ 29.79560405063291, -1.441346206896552 ], [ 29.79560405063291, -1.44080724137931 ], [ 29.795334556962025, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15554, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.44080724137931 ], [ 29.79560405063291, -1.441076724137931 ], [ 29.795873544303799, -1.441076724137931 ], [ 29.795873544303799, -1.44080724137931 ], [ 29.79560405063291, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15555, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.44080724137931 ], [ 29.795873544303799, -1.441076724137931 ], [ 29.796143037974684, -1.441076724137931 ], [ 29.796143037974684, -1.44080724137931 ], [ 29.795873544303799, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15556, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.44080724137931 ], [ 29.796143037974684, -1.441076724137931 ], [ 29.796412531645569, -1.441076724137931 ], [ 29.796412531645569, -1.44080724137931 ], [ 29.796143037974684, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15557, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.44080724137931 ], [ 29.796412531645569, -1.441076724137931 ], [ 29.796682025316457, -1.441076724137931 ], [ 29.796682025316457, -1.44080724137931 ], [ 29.796412531645569, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15558, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.44080724137931 ], [ 29.796682025316457, -1.441076724137931 ], [ 29.796951518987342, -1.441076724137931 ], [ 29.796951518987342, -1.44080724137931 ], [ 29.796682025316457, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15559, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.44080724137931 ], [ 29.796951518987342, -1.441076724137931 ], [ 29.797221012658227, -1.441076724137931 ], [ 29.797221012658227, -1.44080724137931 ], [ 29.796951518987342, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15560, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.44080724137931 ], [ 29.797221012658227, -1.441076724137931 ], [ 29.797490506329115, -1.441076724137931 ], [ 29.797490506329115, -1.44080724137931 ], [ 29.797221012658227, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15561, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.44080724137931 ], [ 29.797490506329115, -1.441076724137931 ], [ 29.79776, -1.441076724137931 ], [ 29.79776, -1.44080724137931 ], [ 29.797490506329115, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15562, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.44080724137931 ], [ 29.79776, -1.441076724137931 ], [ 29.798029493670885, -1.441076724137931 ], [ 29.798029493670885, -1.44080724137931 ], [ 29.79776, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.44080724137931 ], [ 29.798029493670885, -1.441076724137931 ], [ 29.799107468354432, -1.441076724137931 ], [ 29.799107468354432, -1.44080724137931 ], [ 29.798029493670885, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15564, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.44080724137931 ], [ 29.799107468354432, -1.441076724137931 ], [ 29.799376962025317, -1.441076724137931 ], [ 29.799376962025317, -1.44080724137931 ], [ 29.799107468354432, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15565, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.44080724137931 ], [ 29.799376962025317, -1.441076724137931 ], [ 29.799646455696202, -1.441076724137931 ], [ 29.799646455696202, -1.44080724137931 ], [ 29.799376962025317, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15566, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.44080724137931 ], [ 29.799646455696202, -1.441076724137931 ], [ 29.79991594936709, -1.441076724137931 ], [ 29.79991594936709, -1.44080724137931 ], [ 29.799646455696202, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15567, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.44080724137931 ], [ 29.79991594936709, -1.441076724137931 ], [ 29.800185443037975, -1.441076724137931 ], [ 29.800185443037975, -1.44080724137931 ], [ 29.79991594936709, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15568, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.44080724137931 ], [ 29.800185443037975, -1.441076724137931 ], [ 29.80045493670886, -1.441076724137931 ], [ 29.80045493670886, -1.44080724137931 ], [ 29.800185443037975, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15569, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.44080724137931 ], [ 29.80045493670886, -1.441076724137931 ], [ 29.800724430379748, -1.441076724137931 ], [ 29.800724430379748, -1.44080724137931 ], [ 29.80045493670886, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15570, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.44080724137931 ], [ 29.800724430379748, -1.441076724137931 ], [ 29.800993924050633, -1.441076724137931 ], [ 29.800993924050633, -1.44080724137931 ], [ 29.800724430379748, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15571, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.44080724137931 ], [ 29.800993924050633, -1.441076724137931 ], [ 29.801263417721518, -1.441076724137931 ], [ 29.801263417721518, -1.44080724137931 ], [ 29.800993924050633, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15572, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.44080724137931 ], [ 29.801263417721518, -1.441076724137931 ], [ 29.801532911392407, -1.441076724137931 ], [ 29.801532911392407, -1.44080724137931 ], [ 29.801263417721518, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15573, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.44080724137931 ], [ 29.801532911392407, -1.441076724137931 ], [ 29.802071898734177, -1.441076724137931 ], [ 29.802071898734177, -1.44080724137931 ], [ 29.801532911392407, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15574, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.44080724137931 ], [ 29.802071898734177, -1.441076724137931 ], [ 29.802341392405065, -1.441076724137931 ], [ 29.802341392405065, -1.44080724137931 ], [ 29.802071898734177, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15575, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.44080724137931 ], [ 29.802341392405065, -1.441076724137931 ], [ 29.80261088607595, -1.441076724137931 ], [ 29.80261088607595, -1.44080724137931 ], [ 29.802341392405065, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15576, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.44080724137931 ], [ 29.80261088607595, -1.441076724137931 ], [ 29.802880379746835, -1.441076724137931 ], [ 29.802880379746835, -1.44080724137931 ], [ 29.80261088607595, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15577, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.44080724137931 ], [ 29.802880379746835, -1.441076724137931 ], [ 29.803149873417723, -1.441076724137931 ], [ 29.803149873417723, -1.44080724137931 ], [ 29.802880379746835, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15578, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.44080724137931 ], [ 29.803149873417723, -1.441076724137931 ], [ 29.803419367088608, -1.441076724137931 ], [ 29.803419367088608, -1.44080724137931 ], [ 29.803149873417723, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15579, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.44080724137931 ], [ 29.803419367088608, -1.441076724137931 ], [ 29.803688860759493, -1.441076724137931 ], [ 29.803688860759493, -1.44080724137931 ], [ 29.803419367088608, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15580, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.44080724137931 ], [ 29.803688860759493, -1.441076724137931 ], [ 29.803958354430382, -1.441076724137931 ], [ 29.803958354430382, -1.44080724137931 ], [ 29.803688860759493, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15581, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.44080724137931 ], [ 29.803958354430382, -1.441076724137931 ], [ 29.804227848101267, -1.441076724137931 ], [ 29.804227848101267, -1.44080724137931 ], [ 29.803958354430382, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15582, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.44080724137931 ], [ 29.804227848101267, -1.441076724137931 ], [ 29.804497341772151, -1.441076724137931 ], [ 29.804497341772151, -1.44080724137931 ], [ 29.804227848101267, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15583, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.44080724137931 ], [ 29.804497341772151, -1.441076724137931 ], [ 29.80476683544304, -1.441076724137931 ], [ 29.80476683544304, -1.44080724137931 ], [ 29.804497341772151, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15584, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.44080724137931 ], [ 29.80476683544304, -1.441076724137931 ], [ 29.805036329113925, -1.441076724137931 ], [ 29.805036329113925, -1.44080724137931 ], [ 29.80476683544304, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15585, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.44080724137931 ], [ 29.805036329113925, -1.441076724137931 ], [ 29.80530582278481, -1.441076724137931 ], [ 29.80530582278481, -1.44080724137931 ], [ 29.805036329113925, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15586, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.44080724137931 ], [ 29.80530582278481, -1.441076724137931 ], [ 29.805575316455698, -1.441076724137931 ], [ 29.805575316455698, -1.44080724137931 ], [ 29.80530582278481, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15587, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.44080724137931 ], [ 29.805575316455698, -1.441076724137931 ], [ 29.805844810126583, -1.441076724137931 ], [ 29.805844810126583, -1.44080724137931 ], [ 29.805575316455698, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15588, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.44080724137931 ], [ 29.805844810126583, -1.441076724137931 ], [ 29.806114303797468, -1.441076724137931 ], [ 29.806114303797468, -1.44080724137931 ], [ 29.805844810126583, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15589, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.44080724137931 ], [ 29.806114303797468, -1.441076724137931 ], [ 29.806383797468357, -1.441076724137931 ], [ 29.806383797468357, -1.44080724137931 ], [ 29.806114303797468, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15590, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.44080724137931 ], [ 29.806383797468357, -1.441076724137931 ], [ 29.806653291139241, -1.441076724137931 ], [ 29.806653291139241, -1.44080724137931 ], [ 29.806383797468357, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15591, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.44080724137931 ], [ 29.806653291139241, -1.441076724137931 ], [ 29.806922784810126, -1.441076724137931 ], [ 29.806922784810126, -1.44080724137931 ], [ 29.806653291139241, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15592, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.44080724137931 ], [ 29.806922784810126, -1.441076724137931 ], [ 29.8074617721519, -1.441076724137931 ], [ 29.8074617721519, -1.44080724137931 ], [ 29.806922784810126, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15593, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.44080724137931 ], [ 29.8074617721519, -1.441076724137931 ], [ 29.807731265822785, -1.441076724137931 ], [ 29.807731265822785, -1.44080724137931 ], [ 29.8074617721519, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15594, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.44080724137931 ], [ 29.807731265822785, -1.441076724137931 ], [ 29.808000759493673, -1.441076724137931 ], [ 29.808000759493673, -1.44080724137931 ], [ 29.807731265822785, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15595, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.44080724137931 ], [ 29.808000759493673, -1.441076724137931 ], [ 29.808270253164558, -1.441076724137931 ], [ 29.808270253164558, -1.44080724137931 ], [ 29.808000759493673, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15596, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.44080724137931 ], [ 29.808270253164558, -1.441076724137931 ], [ 29.808539746835443, -1.441076724137931 ], [ 29.808539746835443, -1.44080724137931 ], [ 29.808270253164558, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15597, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.44080724137931 ], [ 29.808539746835443, -1.441076724137931 ], [ 29.808809240506328, -1.441076724137931 ], [ 29.808809240506328, -1.44080724137931 ], [ 29.808539746835443, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15598, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745208734177215, -1.441076724137931 ], [ 29.745208734177215, -1.441346206896552 ], [ 29.7454782278481, -1.441346206896552 ], [ 29.7454782278481, -1.441076724137931 ], [ 29.745208734177215, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15599, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.441076724137931 ], [ 29.7454782278481, -1.441346206896552 ], [ 29.745747721518985, -1.441346206896552 ], [ 29.745747721518985, -1.441076724137931 ], [ 29.7454782278481, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15600, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.745747721518985, -1.441076724137931 ], [ 29.745747721518985, -1.441346206896552 ], [ 29.746017215189873, -1.441346206896552 ], [ 29.746017215189873, -1.441076724137931 ], [ 29.745747721518985, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15601, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.441076724137931 ], [ 29.746017215189873, -1.441346206896552 ], [ 29.746286708860758, -1.441346206896552 ], [ 29.746286708860758, -1.441076724137931 ], [ 29.746017215189873, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15602, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.441076724137931 ], [ 29.746286708860758, -1.441346206896552 ], [ 29.746556202531643, -1.441346206896552 ], [ 29.746556202531643, -1.441076724137931 ], [ 29.746286708860758, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15603, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.441076724137931 ], [ 29.746556202531643, -1.441346206896552 ], [ 29.746825696202531, -1.441346206896552 ], [ 29.746825696202531, -1.441076724137931 ], [ 29.746556202531643, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15604, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.441076724137931 ], [ 29.746825696202531, -1.441346206896552 ], [ 29.747095189873416, -1.441346206896552 ], [ 29.747095189873416, -1.441076724137931 ], [ 29.746825696202531, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15605, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.441076724137931 ], [ 29.747095189873416, -1.441346206896552 ], [ 29.747364683544301, -1.441346206896552 ], [ 29.747364683544301, -1.441076724137931 ], [ 29.747095189873416, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15606, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.441076724137931 ], [ 29.747364683544301, -1.441346206896552 ], [ 29.747903670886075, -1.441346206896552 ], [ 29.747903670886075, -1.441076724137931 ], [ 29.747364683544301, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15607, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.441076724137931 ], [ 29.747903670886075, -1.441346206896552 ], [ 29.74817316455696, -1.441346206896552 ], [ 29.74817316455696, -1.441076724137931 ], [ 29.747903670886075, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15608, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.441076724137931 ], [ 29.74817316455696, -1.441346206896552 ], [ 29.748442658227848, -1.441346206896552 ], [ 29.748442658227848, -1.441076724137931 ], [ 29.74817316455696, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15609, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.441076724137931 ], [ 29.748442658227848, -1.441346206896552 ], [ 29.748712151898733, -1.441346206896552 ], [ 29.748712151898733, -1.441076724137931 ], [ 29.748442658227848, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15610, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.441076724137931 ], [ 29.748712151898733, -1.441346206896552 ], [ 29.748981645569618, -1.441346206896552 ], [ 29.748981645569618, -1.441076724137931 ], [ 29.748712151898733, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15611, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.441076724137931 ], [ 29.748981645569618, -1.441346206896552 ], [ 29.749251139240506, -1.441346206896552 ], [ 29.749251139240506, -1.441076724137931 ], [ 29.748981645569618, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15612, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.441076724137931 ], [ 29.749251139240506, -1.441346206896552 ], [ 29.749520632911391, -1.441346206896552 ], [ 29.749520632911391, -1.441076724137931 ], [ 29.749251139240506, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15613, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.441076724137931 ], [ 29.749520632911391, -1.441346206896552 ], [ 29.749790126582276, -1.441346206896552 ], [ 29.749790126582276, -1.441076724137931 ], [ 29.749520632911391, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15614, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.441076724137931 ], [ 29.749790126582276, -1.441346206896552 ], [ 29.750059620253165, -1.441346206896552 ], [ 29.750059620253165, -1.441076724137931 ], [ 29.749790126582276, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15615, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.441076724137931 ], [ 29.750059620253165, -1.441346206896552 ], [ 29.75032911392405, -1.441346206896552 ], [ 29.75032911392405, -1.441076724137931 ], [ 29.750059620253165, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15616, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.441076724137931 ], [ 29.75032911392405, -1.441346206896552 ], [ 29.750598607594934, -1.441346206896552 ], [ 29.750598607594934, -1.441076724137931 ], [ 29.75032911392405, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15617, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.441076724137931 ], [ 29.750598607594934, -1.441346206896552 ], [ 29.750868101265823, -1.441346206896552 ], [ 29.750868101265823, -1.441076724137931 ], [ 29.750598607594934, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15618, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.441076724137931 ], [ 29.750868101265823, -1.441346206896552 ], [ 29.751137594936708, -1.441346206896552 ], [ 29.751137594936708, -1.441076724137931 ], [ 29.750868101265823, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15619, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.441076724137931 ], [ 29.751137594936708, -1.441346206896552 ], [ 29.751407088607593, -1.441346206896552 ], [ 29.751407088607593, -1.441076724137931 ], [ 29.751137594936708, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15620, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.441076724137931 ], [ 29.751407088607593, -1.441346206896552 ], [ 29.751676582278481, -1.441346206896552 ], [ 29.751676582278481, -1.441076724137931 ], [ 29.751407088607593, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15621, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.441076724137931 ], [ 29.751676582278481, -1.441615689655172 ], [ 29.751946075949366, -1.441615689655172 ], [ 29.751946075949366, -1.441076724137931 ], [ 29.751676582278481, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15622, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.441076724137931 ], [ 29.751946075949366, -1.441346206896552 ], [ 29.752215569620251, -1.441346206896552 ], [ 29.752215569620251, -1.441076724137931 ], [ 29.751946075949366, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15623, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.441076724137931 ], [ 29.752215569620251, -1.441346206896552 ], [ 29.75248506329114, -1.441346206896552 ], [ 29.75248506329114, -1.441076724137931 ], [ 29.752215569620251, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15624, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.441076724137931 ], [ 29.75248506329114, -1.441346206896552 ], [ 29.752754556962024, -1.441346206896552 ], [ 29.752754556962024, -1.441076724137931 ], [ 29.75248506329114, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15625, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.441076724137931 ], [ 29.753293544303798, -1.441346206896552 ], [ 29.753563037974683, -1.441346206896552 ], [ 29.753563037974683, -1.441076724137931 ], [ 29.753293544303798, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15626, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.441076724137931 ], [ 29.753563037974683, -1.441346206896552 ], [ 29.753832531645568, -1.441346206896552 ], [ 29.753832531645568, -1.441076724137931 ], [ 29.753563037974683, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15627, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.441076724137931 ], [ 29.753832531645568, -1.441346206896552 ], [ 29.754102025316456, -1.441346206896552 ], [ 29.754102025316456, -1.441076724137931 ], [ 29.753832531645568, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15628, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.441076724137931 ], [ 29.754371518987341, -1.441346206896552 ], [ 29.754641012658226, -1.441346206896552 ], [ 29.754641012658226, -1.441076724137931 ], [ 29.754371518987341, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15629, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.441076724137931 ], [ 29.754641012658226, -1.441346206896552 ], [ 29.754910506329114, -1.441346206896552 ], [ 29.754910506329114, -1.441076724137931 ], [ 29.754641012658226, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15630, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.441076724137931 ], [ 29.754910506329114, -1.441346206896552 ], [ 29.75518, -1.441346206896552 ], [ 29.75518, -1.441076724137931 ], [ 29.754910506329114, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15631, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.441076724137931 ], [ 29.75518, -1.441346206896552 ], [ 29.755449493670884, -1.441346206896552 ], [ 29.755449493670884, -1.441076724137931 ], [ 29.75518, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15632, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.441076724137931 ], [ 29.755449493670884, -1.441346206896552 ], [ 29.755718987341773, -1.441346206896552 ], [ 29.755718987341773, -1.441076724137931 ], [ 29.755449493670884, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15633, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.441076724137931 ], [ 29.755718987341773, -1.441346206896552 ], [ 29.755988481012658, -1.441346206896552 ], [ 29.755988481012658, -1.441076724137931 ], [ 29.755718987341773, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15634, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.441076724137931 ], [ 29.755988481012658, -1.441346206896552 ], [ 29.756257974683542, -1.441346206896552 ], [ 29.756257974683542, -1.441076724137931 ], [ 29.755988481012658, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15635, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.441076724137931 ], [ 29.756257974683542, -1.441346206896552 ], [ 29.756796962025316, -1.441346206896552 ], [ 29.756796962025316, -1.441076724137931 ], [ 29.756257974683542, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15636, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.441076724137931 ], [ 29.756796962025316, -1.441346206896552 ], [ 29.757066455696201, -1.441346206896552 ], [ 29.757066455696201, -1.441076724137931 ], [ 29.756796962025316, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15637, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.441076724137931 ], [ 29.757066455696201, -1.441346206896552 ], [ 29.757335949367089, -1.441346206896552 ], [ 29.757335949367089, -1.441076724137931 ], [ 29.757066455696201, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15638, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.441076724137931 ], [ 29.757335949367089, -1.441346206896552 ], [ 29.757605443037974, -1.441346206896552 ], [ 29.757605443037974, -1.441076724137931 ], [ 29.757335949367089, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15639, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.441076724137931 ], [ 29.757605443037974, -1.441346206896552 ], [ 29.757874936708859, -1.441346206896552 ], [ 29.757874936708859, -1.441076724137931 ], [ 29.757605443037974, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15640, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.441076724137931 ], [ 29.757874936708859, -1.441346206896552 ], [ 29.758144430379748, -1.441346206896552 ], [ 29.758144430379748, -1.441076724137931 ], [ 29.757874936708859, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15641, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.441076724137931 ], [ 29.758144430379748, -1.441346206896552 ], [ 29.758413924050632, -1.441346206896552 ], [ 29.758413924050632, -1.441076724137931 ], [ 29.758144430379748, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15642, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.441076724137931 ], [ 29.758413924050632, -1.441346206896552 ], [ 29.758683417721517, -1.441346206896552 ], [ 29.758683417721517, -1.441076724137931 ], [ 29.758413924050632, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15643, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.441076724137931 ], [ 29.758683417721517, -1.441346206896552 ], [ 29.759222405063291, -1.441346206896552 ], [ 29.759222405063291, -1.441076724137931 ], [ 29.758683417721517, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15644, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.441076724137931 ], [ 29.759222405063291, -1.441346206896552 ], [ 29.761647848101266, -1.441346206896552 ], [ 29.761647848101266, -1.441076724137931 ], [ 29.759222405063291, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15645, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.441076724137931 ], [ 29.761647848101266, -1.441346206896552 ], [ 29.762186835443039, -1.441346206896552 ], [ 29.762186835443039, -1.441076724137931 ], [ 29.761647848101266, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15646, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.441076724137931 ], [ 29.762186835443039, -1.441346206896552 ], [ 29.762456329113924, -1.441346206896552 ], [ 29.762456329113924, -1.441076724137931 ], [ 29.762186835443039, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15647, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.441076724137931 ], [ 29.762456329113924, -1.441346206896552 ], [ 29.762725822784809, -1.441346206896552 ], [ 29.762725822784809, -1.441076724137931 ], [ 29.762456329113924, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15648, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.441076724137931 ], [ 29.762725822784809, -1.441346206896552 ], [ 29.762995316455697, -1.441346206896552 ], [ 29.762995316455697, -1.441076724137931 ], [ 29.762725822784809, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15649, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.441076724137931 ], [ 29.762995316455697, -1.441346206896552 ], [ 29.763264810126582, -1.441346206896552 ], [ 29.763264810126582, -1.441076724137931 ], [ 29.762995316455697, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15650, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.441076724137931 ], [ 29.763264810126582, -1.441346206896552 ], [ 29.763534303797467, -1.441346206896552 ], [ 29.763534303797467, -1.441076724137931 ], [ 29.763264810126582, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15651, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.441076724137931 ], [ 29.763534303797467, -1.441346206896552 ], [ 29.763803797468356, -1.441346206896552 ], [ 29.763803797468356, -1.441076724137931 ], [ 29.763534303797467, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15652, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.441076724137931 ], [ 29.763803797468356, -1.441346206896552 ], [ 29.76407329113924, -1.441346206896552 ], [ 29.76407329113924, -1.441076724137931 ], [ 29.763803797468356, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15653, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.441076724137931 ], [ 29.76407329113924, -1.441346206896552 ], [ 29.764342784810125, -1.441346206896552 ], [ 29.764342784810125, -1.441076724137931 ], [ 29.76407329113924, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15654, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.441076724137931 ], [ 29.764342784810125, -1.441346206896552 ], [ 29.764612278481014, -1.441346206896552 ], [ 29.764612278481014, -1.441076724137931 ], [ 29.764342784810125, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15655, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.441076724137931 ], [ 29.764612278481014, -1.441346206896552 ], [ 29.764881772151899, -1.441346206896552 ], [ 29.764881772151899, -1.441076724137931 ], [ 29.764612278481014, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15656, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.441076724137931 ], [ 29.764881772151899, -1.441346206896552 ], [ 29.765151265822784, -1.441346206896552 ], [ 29.765151265822784, -1.441076724137931 ], [ 29.764881772151899, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15657, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.441076724137931 ], [ 29.765151265822784, -1.441346206896552 ], [ 29.765420759493672, -1.441346206896552 ], [ 29.765420759493672, -1.441076724137931 ], [ 29.765151265822784, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15658, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.441076724137931 ], [ 29.765420759493672, -1.441346206896552 ], [ 29.765959746835442, -1.441346206896552 ], [ 29.765959746835442, -1.441076724137931 ], [ 29.765420759493672, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15659, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.441076724137931 ], [ 29.765959746835442, -1.441346206896552 ], [ 29.766229240506327, -1.441346206896552 ], [ 29.766229240506327, -1.441076724137931 ], [ 29.765959746835442, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15660, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.441076724137931 ], [ 29.766229240506327, -1.441346206896552 ], [ 29.766498734177215, -1.441346206896552 ], [ 29.766498734177215, -1.441076724137931 ], [ 29.766229240506327, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15661, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.441076724137931 ], [ 29.766498734177215, -1.441346206896552 ], [ 29.7667682278481, -1.441346206896552 ], [ 29.7667682278481, -1.441076724137931 ], [ 29.766498734177215, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15662, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.441076724137931 ], [ 29.7667682278481, -1.441346206896552 ], [ 29.767037721518985, -1.441346206896552 ], [ 29.767037721518985, -1.441076724137931 ], [ 29.7667682278481, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15663, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.441076724137931 ], [ 29.767037721518985, -1.441346206896552 ], [ 29.767307215189874, -1.441346206896552 ], [ 29.767307215189874, -1.441076724137931 ], [ 29.767037721518985, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15664, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.441076724137931 ], [ 29.767307215189874, -1.441346206896552 ], [ 29.767576708860759, -1.441346206896552 ], [ 29.767576708860759, -1.441076724137931 ], [ 29.767307215189874, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15665, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.441076724137931 ], [ 29.767576708860759, -1.441346206896552 ], [ 29.767846202531643, -1.441346206896552 ], [ 29.767846202531643, -1.441076724137931 ], [ 29.767576708860759, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15666, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.441076724137931 ], [ 29.767846202531643, -1.441346206896552 ], [ 29.768115696202532, -1.441346206896552 ], [ 29.768115696202532, -1.441076724137931 ], [ 29.767846202531643, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15667, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.441076724137931 ], [ 29.768385189873417, -1.441346206896552 ], [ 29.768654683544302, -1.441346206896552 ], [ 29.768654683544302, -1.441076724137931 ], [ 29.768385189873417, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15668, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.441076724137931 ], [ 29.768654683544302, -1.441346206896552 ], [ 29.76892417721519, -1.441346206896552 ], [ 29.76892417721519, -1.441076724137931 ], [ 29.768654683544302, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15669, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.441076724137931 ], [ 29.769193670886075, -1.441346206896552 ], [ 29.76946316455696, -1.441346206896552 ], [ 29.76946316455696, -1.441076724137931 ], [ 29.769193670886075, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15670, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.441076724137931 ], [ 29.76946316455696, -1.441346206896552 ], [ 29.769732658227849, -1.441346206896552 ], [ 29.769732658227849, -1.441076724137931 ], [ 29.76946316455696, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15671, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.441076724137931 ], [ 29.769732658227849, -1.441346206896552 ], [ 29.770002151898733, -1.441346206896552 ], [ 29.770002151898733, -1.441076724137931 ], [ 29.769732658227849, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15672, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.441076724137931 ], [ 29.770002151898733, -1.441346206896552 ], [ 29.770271645569618, -1.441346206896552 ], [ 29.770271645569618, -1.441076724137931 ], [ 29.770002151898733, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15673, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.441076724137931 ], [ 29.770271645569618, -1.441346206896552 ], [ 29.770541139240507, -1.441346206896552 ], [ 29.770541139240507, -1.441076724137931 ], [ 29.770271645569618, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15674, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.441076724137931 ], [ 29.770541139240507, -1.441346206896552 ], [ 29.770810632911392, -1.441346206896552 ], [ 29.770810632911392, -1.441076724137931 ], [ 29.770541139240507, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15675, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.441076724137931 ], [ 29.770810632911392, -1.441346206896552 ], [ 29.771080126582277, -1.441346206896552 ], [ 29.771080126582277, -1.441076724137931 ], [ 29.770810632911392, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15676, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.441076724137931 ], [ 29.771080126582277, -1.441346206896552 ], [ 29.771349620253165, -1.441346206896552 ], [ 29.771349620253165, -1.441076724137931 ], [ 29.771080126582277, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15677, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.441076724137931 ], [ 29.771349620253165, -1.441346206896552 ], [ 29.77161911392405, -1.441346206896552 ], [ 29.77161911392405, -1.441076724137931 ], [ 29.771349620253165, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15678, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.441076724137931 ], [ 29.77161911392405, -1.441346206896552 ], [ 29.771888607594935, -1.441346206896552 ], [ 29.771888607594935, -1.441076724137931 ], [ 29.77161911392405, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15679, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.441076724137931 ], [ 29.771888607594935, -1.441346206896552 ], [ 29.772158101265823, -1.441346206896552 ], [ 29.772158101265823, -1.441076724137931 ], [ 29.771888607594935, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15680, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.441076724137931 ], [ 29.772158101265823, -1.441346206896552 ], [ 29.772427594936708, -1.441346206896552 ], [ 29.772427594936708, -1.441076724137931 ], [ 29.772158101265823, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15681, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.441076724137931 ], [ 29.772427594936708, -1.441346206896552 ], [ 29.772697088607593, -1.441346206896552 ], [ 29.772697088607593, -1.441076724137931 ], [ 29.772427594936708, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15682, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.441076724137931 ], [ 29.772697088607593, -1.441346206896552 ], [ 29.772966582278482, -1.441346206896552 ], [ 29.772966582278482, -1.441076724137931 ], [ 29.772697088607593, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15683, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.441076724137931 ], [ 29.772966582278482, -1.441346206896552 ], [ 29.773236075949367, -1.441346206896552 ], [ 29.773236075949367, -1.441076724137931 ], [ 29.772966582278482, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15684, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.441076724137931 ], [ 29.773236075949367, -1.441346206896552 ], [ 29.773505569620252, -1.441346206896552 ], [ 29.773505569620252, -1.441076724137931 ], [ 29.773236075949367, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15685, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.441076724137931 ], [ 29.773505569620252, -1.441346206896552 ], [ 29.77377506329114, -1.441346206896552 ], [ 29.77377506329114, -1.441076724137931 ], [ 29.773505569620252, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15686, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.441076724137931 ], [ 29.77377506329114, -1.441346206896552 ], [ 29.774044556962025, -1.441346206896552 ], [ 29.774044556962025, -1.441076724137931 ], [ 29.77377506329114, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15687, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.441076724137931 ], [ 29.774044556962025, -1.441346206896552 ], [ 29.77431405063291, -1.441346206896552 ], [ 29.77431405063291, -1.441076724137931 ], [ 29.774044556962025, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15688, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.441076724137931 ], [ 29.77431405063291, -1.441346206896552 ], [ 29.774853037974683, -1.441346206896552 ], [ 29.774853037974683, -1.441076724137931 ], [ 29.77431405063291, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15689, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.441076724137931 ], [ 29.774853037974683, -1.441346206896552 ], [ 29.775122531645568, -1.441346206896552 ], [ 29.775122531645568, -1.441076724137931 ], [ 29.774853037974683, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15690, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.441076724137931 ], [ 29.775122531645568, -1.441346206896552 ], [ 29.775392025316457, -1.441346206896552 ], [ 29.775392025316457, -1.441076724137931 ], [ 29.775122531645568, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15691, "El": 130 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.441076724137931 ], [ 29.775392025316457, -1.441346206896552 ], [ 29.775661518987341, -1.441346206896552 ], [ 29.775661518987341, -1.441076724137931 ], [ 29.775392025316457, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15692, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.441076724137931 ], [ 29.775661518987341, -1.441346206896552 ], [ 29.775931012658226, -1.441346206896552 ], [ 29.775931012658226, -1.441076724137931 ], [ 29.775661518987341, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15693, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.441076724137931 ], [ 29.775931012658226, -1.441615689655172 ], [ 29.776200506329115, -1.441615689655172 ], [ 29.776200506329115, -1.441076724137931 ], [ 29.775931012658226, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15694, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.441076724137931 ], [ 29.77647, -1.441346206896552 ], [ 29.776739493670885, -1.441346206896552 ], [ 29.776739493670885, -1.441076724137931 ], [ 29.77647, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15695, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.441076724137931 ], [ 29.776739493670885, -1.441615689655172 ], [ 29.777008987341773, -1.441615689655172 ], [ 29.777008987341773, -1.441076724137931 ], [ 29.776739493670885, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15696, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.441076724137931 ], [ 29.777278481012658, -1.441346206896552 ], [ 29.777547974683543, -1.441346206896552 ], [ 29.777547974683543, -1.441076724137931 ], [ 29.777278481012658, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15697, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.441076724137931 ], [ 29.777547974683543, -1.441346206896552 ], [ 29.777817468354431, -1.441346206896552 ], [ 29.777817468354431, -1.441076724137931 ], [ 29.777547974683543, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15698, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.441076724137931 ], [ 29.778086962025316, -1.441346206896552 ], [ 29.778356455696201, -1.441346206896552 ], [ 29.778356455696201, -1.441076724137931 ], [ 29.778086962025316, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15699, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.441076724137931 ], [ 29.778356455696201, -1.441346206896552 ], [ 29.77862594936709, -1.441346206896552 ], [ 29.77862594936709, -1.441076724137931 ], [ 29.778356455696201, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15700, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.441076724137931 ], [ 29.77862594936709, -1.441615689655172 ], [ 29.778895443037975, -1.441615689655172 ], [ 29.778895443037975, -1.441076724137931 ], [ 29.77862594936709, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15701, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.441076724137931 ], [ 29.778895443037975, -1.441346206896552 ], [ 29.77916493670886, -1.441346206896552 ], [ 29.77916493670886, -1.441076724137931 ], [ 29.778895443037975, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15702, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.441076724137931 ], [ 29.77916493670886, -1.441346206896552 ], [ 29.779434430379748, -1.441346206896552 ], [ 29.779434430379748, -1.441076724137931 ], [ 29.77916493670886, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15703, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.441076724137931 ], [ 29.779434430379748, -1.441346206896552 ], [ 29.779703924050633, -1.441346206896552 ], [ 29.779703924050633, -1.441076724137931 ], [ 29.779434430379748, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15704, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.441076724137931 ], [ 29.779973417721518, -1.441346206896552 ], [ 29.780242911392406, -1.441346206896552 ], [ 29.780242911392406, -1.441076724137931 ], [ 29.779973417721518, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15705, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.441076724137931 ], [ 29.780242911392406, -1.441346206896552 ], [ 29.780512405063291, -1.441346206896552 ], [ 29.780512405063291, -1.441076724137931 ], [ 29.780242911392406, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15706, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.441076724137931 ], [ 29.780512405063291, -1.441346206896552 ], [ 29.780781898734176, -1.441346206896552 ], [ 29.780781898734176, -1.441076724137931 ], [ 29.780512405063291, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15707, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.441076724137931 ], [ 29.780781898734176, -1.441346206896552 ], [ 29.781051392405065, -1.441346206896552 ], [ 29.781051392405065, -1.441076724137931 ], [ 29.780781898734176, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15708, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.441076724137931 ], [ 29.781051392405065, -1.441346206896552 ], [ 29.78132088607595, -1.441346206896552 ], [ 29.78132088607595, -1.441076724137931 ], [ 29.781051392405065, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15709, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.441076724137931 ], [ 29.78132088607595, -1.441346206896552 ], [ 29.781590379746834, -1.441346206896552 ], [ 29.781590379746834, -1.441076724137931 ], [ 29.78132088607595, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15710, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.441076724137931 ], [ 29.781590379746834, -1.441346206896552 ], [ 29.781859873417723, -1.441346206896552 ], [ 29.781859873417723, -1.441076724137931 ], [ 29.781590379746834, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15711, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.441076724137931 ], [ 29.781859873417723, -1.441346206896552 ], [ 29.782129367088608, -1.441346206896552 ], [ 29.782129367088608, -1.441076724137931 ], [ 29.781859873417723, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15712, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.441076724137931 ], [ 29.782129367088608, -1.441346206896552 ], [ 29.782398860759493, -1.441346206896552 ], [ 29.782398860759493, -1.441076724137931 ], [ 29.782129367088608, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15713, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.441076724137931 ], [ 29.782398860759493, -1.441346206896552 ], [ 29.782668354430381, -1.441346206896552 ], [ 29.782668354430381, -1.441076724137931 ], [ 29.782398860759493, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15714, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.441076724137931 ], [ 29.782668354430381, -1.441346206896552 ], [ 29.782937848101266, -1.441346206896552 ], [ 29.782937848101266, -1.441076724137931 ], [ 29.782668354430381, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15715, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.441076724137931 ], [ 29.782937848101266, -1.441346206896552 ], [ 29.783207341772151, -1.441346206896552 ], [ 29.783207341772151, -1.441076724137931 ], [ 29.782937848101266, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15716, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.441076724137931 ], [ 29.783207341772151, -1.441346206896552 ], [ 29.783476835443039, -1.441346206896552 ], [ 29.783476835443039, -1.441076724137931 ], [ 29.783207341772151, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15717, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.441076724137931 ], [ 29.783476835443039, -1.441346206896552 ], [ 29.784015822784809, -1.441346206896552 ], [ 29.784015822784809, -1.441076724137931 ], [ 29.783476835443039, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15718, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.441076724137931 ], [ 29.784015822784809, -1.441346206896552 ], [ 29.784285316455698, -1.441346206896552 ], [ 29.784285316455698, -1.441076724137931 ], [ 29.784015822784809, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15719, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.441076724137931 ], [ 29.784285316455698, -1.441346206896552 ], [ 29.784554810126583, -1.441346206896552 ], [ 29.784554810126583, -1.441076724137931 ], [ 29.784285316455698, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15720, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.441076724137931 ], [ 29.784554810126583, -1.441346206896552 ], [ 29.784824303797468, -1.441346206896552 ], [ 29.784824303797468, -1.441076724137931 ], [ 29.784554810126583, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15721, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.441076724137931 ], [ 29.784824303797468, -1.441346206896552 ], [ 29.785093797468356, -1.441346206896552 ], [ 29.785093797468356, -1.441076724137931 ], [ 29.784824303797468, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15722, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.441076724137931 ], [ 29.789675189873417, -1.441615689655172 ], [ 29.789944683544302, -1.441615689655172 ], [ 29.789944683544302, -1.441076724137931 ], [ 29.789675189873417, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15723, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.441076724137931 ], [ 29.789944683544302, -1.441346206896552 ], [ 29.790214177215191, -1.441346206896552 ], [ 29.790214177215191, -1.441076724137931 ], [ 29.789944683544302, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15724, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.441076724137931 ], [ 29.790214177215191, -1.441346206896552 ], [ 29.790483670886076, -1.441346206896552 ], [ 29.790483670886076, -1.441076724137931 ], [ 29.790214177215191, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15725, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.441076724137931 ], [ 29.790483670886076, -1.441346206896552 ], [ 29.790753164556961, -1.441346206896552 ], [ 29.790753164556961, -1.441076724137931 ], [ 29.790483670886076, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15726, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.441076724137931 ], [ 29.790753164556961, -1.441346206896552 ], [ 29.791022658227849, -1.441346206896552 ], [ 29.791022658227849, -1.441076724137931 ], [ 29.790753164556961, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15727, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.441076724137931 ], [ 29.791022658227849, -1.441346206896552 ], [ 29.791292151898734, -1.441346206896552 ], [ 29.791292151898734, -1.441076724137931 ], [ 29.791022658227849, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15728, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.441076724137931 ], [ 29.791292151898734, -1.441615689655172 ], [ 29.791561645569619, -1.441615689655172 ], [ 29.791561645569619, -1.441076724137931 ], [ 29.791292151898734, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15729, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.441076724137931 ], [ 29.791561645569619, -1.441346206896552 ], [ 29.791831139240507, -1.441346206896552 ], [ 29.791831139240507, -1.441076724137931 ], [ 29.791561645569619, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15730, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.441076724137931 ], [ 29.791831139240507, -1.441346206896552 ], [ 29.792100632911392, -1.441346206896552 ], [ 29.792100632911392, -1.441076724137931 ], [ 29.791831139240507, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15731, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.441076724137931 ], [ 29.792909113924051, -1.441615689655172 ], [ 29.793178607594935, -1.441615689655172 ], [ 29.793178607594935, -1.441076724137931 ], [ 29.792909113924051, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15732, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.441076724137931 ], [ 29.793987088607594, -1.441615689655172 ], [ 29.794256582278482, -1.441615689655172 ], [ 29.794256582278482, -1.441076724137931 ], [ 29.793987088607594, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15733, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.441076724137931 ], [ 29.794256582278482, -1.441346206896552 ], [ 29.794526075949367, -1.441346206896552 ], [ 29.794526075949367, -1.441076724137931 ], [ 29.794256582278482, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15734, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.441076724137931 ], [ 29.794795569620252, -1.441615689655172 ], [ 29.79506506329114, -1.441615689655172 ], [ 29.79506506329114, -1.441076724137931 ], [ 29.794795569620252, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15735, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.441076724137931 ], [ 29.79506506329114, -1.441615689655172 ], [ 29.795334556962025, -1.441615689655172 ], [ 29.795334556962025, -1.441076724137931 ], [ 29.79506506329114, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15736, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.441076724137931 ], [ 29.79560405063291, -1.441615689655172 ], [ 29.795873544303799, -1.441615689655172 ], [ 29.795873544303799, -1.441076724137931 ], [ 29.79560405063291, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15737, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.441076724137931 ], [ 29.795873544303799, -1.441346206896552 ], [ 29.796143037974684, -1.441346206896552 ], [ 29.796143037974684, -1.441076724137931 ], [ 29.795873544303799, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15738, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.441076724137931 ], [ 29.796143037974684, -1.441346206896552 ], [ 29.796412531645569, -1.441346206896552 ], [ 29.796412531645569, -1.441076724137931 ], [ 29.796143037974684, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15739, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.441076724137931 ], [ 29.796412531645569, -1.441615689655172 ], [ 29.796682025316457, -1.441615689655172 ], [ 29.796682025316457, -1.441076724137931 ], [ 29.796412531645569, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15740, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.441076724137931 ], [ 29.796682025316457, -1.441346206896552 ], [ 29.796951518987342, -1.441346206896552 ], [ 29.796951518987342, -1.441076724137931 ], [ 29.796682025316457, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15741, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.441076724137931 ], [ 29.796951518987342, -1.441346206896552 ], [ 29.797221012658227, -1.441346206896552 ], [ 29.797221012658227, -1.441076724137931 ], [ 29.796951518987342, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15742, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.441076724137931 ], [ 29.797221012658227, -1.441346206896552 ], [ 29.797490506329115, -1.441346206896552 ], [ 29.797490506329115, -1.441076724137931 ], [ 29.797221012658227, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15743, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.441076724137931 ], [ 29.797490506329115, -1.441346206896552 ], [ 29.79776, -1.441346206896552 ], [ 29.79776, -1.441076724137931 ], [ 29.797490506329115, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15744, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.441076724137931 ], [ 29.79776, -1.441346206896552 ], [ 29.798029493670885, -1.441346206896552 ], [ 29.798029493670885, -1.441076724137931 ], [ 29.79776, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15745, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.441076724137931 ], [ 29.798029493670885, -1.441346206896552 ], [ 29.799107468354432, -1.441346206896552 ], [ 29.799107468354432, -1.441076724137931 ], [ 29.798029493670885, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15746, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.441076724137931 ], [ 29.799107468354432, -1.441346206896552 ], [ 29.799376962025317, -1.441346206896552 ], [ 29.799376962025317, -1.441076724137931 ], [ 29.799107468354432, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15747, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.441076724137931 ], [ 29.799376962025317, -1.441346206896552 ], [ 29.799646455696202, -1.441346206896552 ], [ 29.799646455696202, -1.441076724137931 ], [ 29.799376962025317, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15748, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.441076724137931 ], [ 29.799646455696202, -1.441346206896552 ], [ 29.79991594936709, -1.441346206896552 ], [ 29.79991594936709, -1.441076724137931 ], [ 29.799646455696202, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15749, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.441076724137931 ], [ 29.79991594936709, -1.441346206896552 ], [ 29.800185443037975, -1.441346206896552 ], [ 29.800185443037975, -1.441076724137931 ], [ 29.79991594936709, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15750, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.441076724137931 ], [ 29.800185443037975, -1.441346206896552 ], [ 29.80045493670886, -1.441346206896552 ], [ 29.80045493670886, -1.441076724137931 ], [ 29.800185443037975, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15751, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.441076724137931 ], [ 29.80045493670886, -1.441346206896552 ], [ 29.800724430379748, -1.441346206896552 ], [ 29.800724430379748, -1.441076724137931 ], [ 29.80045493670886, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15752, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.441076724137931 ], [ 29.800724430379748, -1.441346206896552 ], [ 29.800993924050633, -1.441346206896552 ], [ 29.800993924050633, -1.441076724137931 ], [ 29.800724430379748, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15753, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.441076724137931 ], [ 29.800993924050633, -1.441346206896552 ], [ 29.801263417721518, -1.441346206896552 ], [ 29.801263417721518, -1.441076724137931 ], [ 29.800993924050633, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15754, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.441076724137931 ], [ 29.801263417721518, -1.441346206896552 ], [ 29.801532911392407, -1.441346206896552 ], [ 29.801532911392407, -1.441076724137931 ], [ 29.801263417721518, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15755, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.441076724137931 ], [ 29.801532911392407, -1.441346206896552 ], [ 29.802071898734177, -1.441346206896552 ], [ 29.802071898734177, -1.441076724137931 ], [ 29.801532911392407, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15756, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.441076724137931 ], [ 29.802071898734177, -1.441346206896552 ], [ 29.802341392405065, -1.441346206896552 ], [ 29.802341392405065, -1.441076724137931 ], [ 29.802071898734177, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15757, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.441076724137931 ], [ 29.802341392405065, -1.441346206896552 ], [ 29.80261088607595, -1.441346206896552 ], [ 29.80261088607595, -1.441076724137931 ], [ 29.802341392405065, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15758, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.441076724137931 ], [ 29.80261088607595, -1.441346206896552 ], [ 29.802880379746835, -1.441346206896552 ], [ 29.802880379746835, -1.441076724137931 ], [ 29.80261088607595, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15759, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.441076724137931 ], [ 29.802880379746835, -1.441346206896552 ], [ 29.803149873417723, -1.441346206896552 ], [ 29.803149873417723, -1.441076724137931 ], [ 29.802880379746835, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15760, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.441076724137931 ], [ 29.803149873417723, -1.441346206896552 ], [ 29.803419367088608, -1.441346206896552 ], [ 29.803419367088608, -1.441076724137931 ], [ 29.803149873417723, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15761, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.441076724137931 ], [ 29.803419367088608, -1.441346206896552 ], [ 29.803688860759493, -1.441346206896552 ], [ 29.803688860759493, -1.441076724137931 ], [ 29.803419367088608, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15762, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.441076724137931 ], [ 29.803688860759493, -1.441346206896552 ], [ 29.803958354430382, -1.441346206896552 ], [ 29.803958354430382, -1.441076724137931 ], [ 29.803688860759493, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15763, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.441076724137931 ], [ 29.803958354430382, -1.441346206896552 ], [ 29.804227848101267, -1.441346206896552 ], [ 29.804227848101267, -1.441076724137931 ], [ 29.803958354430382, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15764, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.441076724137931 ], [ 29.804227848101267, -1.441346206896552 ], [ 29.804497341772151, -1.441346206896552 ], [ 29.804497341772151, -1.441076724137931 ], [ 29.804227848101267, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15765, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.441076724137931 ], [ 29.804497341772151, -1.441346206896552 ], [ 29.80476683544304, -1.441346206896552 ], [ 29.80476683544304, -1.441076724137931 ], [ 29.804497341772151, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15766, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.441076724137931 ], [ 29.80476683544304, -1.441346206896552 ], [ 29.805036329113925, -1.441346206896552 ], [ 29.805036329113925, -1.441076724137931 ], [ 29.80476683544304, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15767, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.441076724137931 ], [ 29.805036329113925, -1.441346206896552 ], [ 29.80530582278481, -1.441346206896552 ], [ 29.80530582278481, -1.441076724137931 ], [ 29.805036329113925, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15768, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.441076724137931 ], [ 29.80530582278481, -1.441346206896552 ], [ 29.805575316455698, -1.441346206896552 ], [ 29.805575316455698, -1.441076724137931 ], [ 29.80530582278481, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15769, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.441076724137931 ], [ 29.805575316455698, -1.441346206896552 ], [ 29.805844810126583, -1.441346206896552 ], [ 29.805844810126583, -1.441076724137931 ], [ 29.805575316455698, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15770, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.441076724137931 ], [ 29.805844810126583, -1.441346206896552 ], [ 29.806114303797468, -1.441346206896552 ], [ 29.806114303797468, -1.441076724137931 ], [ 29.805844810126583, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15771, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.441076724137931 ], [ 29.806114303797468, -1.441346206896552 ], [ 29.806383797468357, -1.441346206896552 ], [ 29.806383797468357, -1.441076724137931 ], [ 29.806114303797468, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15772, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.441076724137931 ], [ 29.806383797468357, -1.441346206896552 ], [ 29.806653291139241, -1.441346206896552 ], [ 29.806653291139241, -1.441076724137931 ], [ 29.806383797468357, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15773, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.441076724137931 ], [ 29.806653291139241, -1.441346206896552 ], [ 29.806922784810126, -1.441346206896552 ], [ 29.806922784810126, -1.441076724137931 ], [ 29.806653291139241, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15774, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.441076724137931 ], [ 29.806922784810126, -1.441346206896552 ], [ 29.8074617721519, -1.441346206896552 ], [ 29.8074617721519, -1.441076724137931 ], [ 29.806922784810126, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15775, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.441076724137931 ], [ 29.8074617721519, -1.441346206896552 ], [ 29.807731265822785, -1.441346206896552 ], [ 29.807731265822785, -1.441076724137931 ], [ 29.8074617721519, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15776, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.441076724137931 ], [ 29.807731265822785, -1.441346206896552 ], [ 29.808000759493673, -1.441346206896552 ], [ 29.808000759493673, -1.441076724137931 ], [ 29.807731265822785, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15777, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.441076724137931 ], [ 29.808000759493673, -1.441346206896552 ], [ 29.808270253164558, -1.441346206896552 ], [ 29.808270253164558, -1.441076724137931 ], [ 29.808000759493673, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15778, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.441076724137931 ], [ 29.808270253164558, -1.441346206896552 ], [ 29.808539746835443, -1.441346206896552 ], [ 29.808539746835443, -1.441076724137931 ], [ 29.808270253164558, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15779, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.441076724137931 ], [ 29.808539746835443, -1.441346206896552 ], [ 29.809078734177216, -1.441346206896552 ], [ 29.809078734177216, -1.441076724137931 ], [ 29.808539746835443, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15780, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.441076724137931 ], [ 29.809078734177216, -1.441346206896552 ], [ 29.809348227848101, -1.441346206896552 ], [ 29.809348227848101, -1.441076724137931 ], [ 29.809078734177216, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15781, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.441076724137931 ], [ 29.809348227848101, -1.441346206896552 ], [ 29.809617721518986, -1.441346206896552 ], [ 29.809617721518986, -1.441076724137931 ], [ 29.809348227848101, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15782, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7454782278481, -1.441346206896552 ], [ 29.7454782278481, -1.441615689655172 ], [ 29.746017215189873, -1.441615689655172 ], [ 29.746017215189873, -1.441346206896552 ], [ 29.7454782278481, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15783, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.441346206896552 ], [ 29.746017215189873, -1.441615689655172 ], [ 29.746286708860758, -1.441615689655172 ], [ 29.746286708860758, -1.441346206896552 ], [ 29.746017215189873, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15784, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.441346206896552 ], [ 29.746286708860758, -1.441615689655172 ], [ 29.746556202531643, -1.441615689655172 ], [ 29.746556202531643, -1.441346206896552 ], [ 29.746286708860758, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15785, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.441346206896552 ], [ 29.746556202531643, -1.441615689655172 ], [ 29.746825696202531, -1.441615689655172 ], [ 29.746825696202531, -1.441346206896552 ], [ 29.746556202531643, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15786, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.441346206896552 ], [ 29.746825696202531, -1.441615689655172 ], [ 29.747095189873416, -1.441615689655172 ], [ 29.747095189873416, -1.441346206896552 ], [ 29.746825696202531, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15787, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.441346206896552 ], [ 29.747095189873416, -1.441615689655172 ], [ 29.747364683544301, -1.441615689655172 ], [ 29.747364683544301, -1.441346206896552 ], [ 29.747095189873416, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15788, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.441346206896552 ], [ 29.747364683544301, -1.441615689655172 ], [ 29.747903670886075, -1.441615689655172 ], [ 29.747903670886075, -1.441346206896552 ], [ 29.747364683544301, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15789, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.441346206896552 ], [ 29.747903670886075, -1.441615689655172 ], [ 29.74817316455696, -1.441615689655172 ], [ 29.74817316455696, -1.441346206896552 ], [ 29.747903670886075, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15790, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.441346206896552 ], [ 29.74817316455696, -1.441615689655172 ], [ 29.748442658227848, -1.441615689655172 ], [ 29.748442658227848, -1.441346206896552 ], [ 29.74817316455696, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15791, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.441346206896552 ], [ 29.748442658227848, -1.441615689655172 ], [ 29.748712151898733, -1.441615689655172 ], [ 29.748712151898733, -1.441346206896552 ], [ 29.748442658227848, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15792, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.441346206896552 ], [ 29.748712151898733, -1.441615689655172 ], [ 29.748981645569618, -1.441615689655172 ], [ 29.748981645569618, -1.441346206896552 ], [ 29.748712151898733, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15793, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.441346206896552 ], [ 29.748981645569618, -1.441615689655172 ], [ 29.749251139240506, -1.441615689655172 ], [ 29.749251139240506, -1.441346206896552 ], [ 29.748981645569618, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15794, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.441346206896552 ], [ 29.749251139240506, -1.441615689655172 ], [ 29.749520632911391, -1.441615689655172 ], [ 29.749520632911391, -1.441346206896552 ], [ 29.749251139240506, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15795, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.441346206896552 ], [ 29.749520632911391, -1.441615689655172 ], [ 29.749790126582276, -1.441615689655172 ], [ 29.749790126582276, -1.441346206896552 ], [ 29.749520632911391, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15796, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.441346206896552 ], [ 29.749790126582276, -1.441615689655172 ], [ 29.750059620253165, -1.441615689655172 ], [ 29.750059620253165, -1.441346206896552 ], [ 29.749790126582276, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15797, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.441346206896552 ], [ 29.750059620253165, -1.441615689655172 ], [ 29.75032911392405, -1.441615689655172 ], [ 29.75032911392405, -1.441346206896552 ], [ 29.750059620253165, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15798, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.441346206896552 ], [ 29.75032911392405, -1.441615689655172 ], [ 29.750598607594934, -1.441615689655172 ], [ 29.750598607594934, -1.441346206896552 ], [ 29.75032911392405, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15799, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.441346206896552 ], [ 29.750598607594934, -1.441615689655172 ], [ 29.750868101265823, -1.441615689655172 ], [ 29.750868101265823, -1.441346206896552 ], [ 29.750598607594934, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15800, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.441346206896552 ], [ 29.750868101265823, -1.441615689655172 ], [ 29.751137594936708, -1.441615689655172 ], [ 29.751137594936708, -1.441346206896552 ], [ 29.750868101265823, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15801, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.441346206896552 ], [ 29.751137594936708, -1.441615689655172 ], [ 29.751407088607593, -1.441615689655172 ], [ 29.751407088607593, -1.441346206896552 ], [ 29.751137594936708, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15802, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.441346206896552 ], [ 29.751407088607593, -1.441615689655172 ], [ 29.751676582278481, -1.441615689655172 ], [ 29.751676582278481, -1.441346206896552 ], [ 29.751407088607593, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15803, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.441346206896552 ], [ 29.751946075949366, -1.441615689655172 ], [ 29.752215569620251, -1.441615689655172 ], [ 29.752215569620251, -1.441346206896552 ], [ 29.751946075949366, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15804, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.441346206896552 ], [ 29.752215569620251, -1.441615689655172 ], [ 29.75248506329114, -1.441615689655172 ], [ 29.75248506329114, -1.441346206896552 ], [ 29.752215569620251, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15805, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.441346206896552 ], [ 29.75248506329114, -1.441615689655172 ], [ 29.752754556962024, -1.441615689655172 ], [ 29.752754556962024, -1.441346206896552 ], [ 29.75248506329114, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15806, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.441346206896552 ], [ 29.752754556962024, -1.441615689655172 ], [ 29.753024050632909, -1.441615689655172 ], [ 29.753024050632909, -1.441346206896552 ], [ 29.752754556962024, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15807, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.441346206896552 ], [ 29.753293544303798, -1.441615689655172 ], [ 29.753563037974683, -1.441615689655172 ], [ 29.753563037974683, -1.441346206896552 ], [ 29.753293544303798, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15808, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.441346206896552 ], [ 29.753563037974683, -1.441615689655172 ], [ 29.753832531645568, -1.441615689655172 ], [ 29.753832531645568, -1.441346206896552 ], [ 29.753563037974683, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15809, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.441346206896552 ], [ 29.753832531645568, -1.441615689655172 ], [ 29.754102025316456, -1.441615689655172 ], [ 29.754102025316456, -1.441346206896552 ], [ 29.753832531645568, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15810, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.441346206896552 ], [ 29.754102025316456, -1.441615689655172 ], [ 29.754371518987341, -1.441615689655172 ], [ 29.754371518987341, -1.441346206896552 ], [ 29.754102025316456, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15811, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.441346206896552 ], [ 29.754371518987341, -1.441615689655172 ], [ 29.754641012658226, -1.441615689655172 ], [ 29.754641012658226, -1.441346206896552 ], [ 29.754371518987341, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15812, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.441346206896552 ], [ 29.754641012658226, -1.441615689655172 ], [ 29.754910506329114, -1.441615689655172 ], [ 29.754910506329114, -1.441346206896552 ], [ 29.754641012658226, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15813, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.441346206896552 ], [ 29.754910506329114, -1.441615689655172 ], [ 29.75518, -1.441615689655172 ], [ 29.75518, -1.441346206896552 ], [ 29.754910506329114, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15814, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.441346206896552 ], [ 29.75518, -1.441615689655172 ], [ 29.755449493670884, -1.441615689655172 ], [ 29.755449493670884, -1.441346206896552 ], [ 29.75518, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15815, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.441346206896552 ], [ 29.755449493670884, -1.441615689655172 ], [ 29.755718987341773, -1.441615689655172 ], [ 29.755718987341773, -1.441346206896552 ], [ 29.755449493670884, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15816, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.441346206896552 ], [ 29.755718987341773, -1.441615689655172 ], [ 29.755988481012658, -1.441615689655172 ], [ 29.755988481012658, -1.441346206896552 ], [ 29.755718987341773, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15817, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.441346206896552 ], [ 29.755988481012658, -1.441615689655172 ], [ 29.756257974683542, -1.441615689655172 ], [ 29.756257974683542, -1.441346206896552 ], [ 29.755988481012658, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15818, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.441346206896552 ], [ 29.756257974683542, -1.441615689655172 ], [ 29.756796962025316, -1.441615689655172 ], [ 29.756796962025316, -1.441346206896552 ], [ 29.756257974683542, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15819, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.441346206896552 ], [ 29.756796962025316, -1.441615689655172 ], [ 29.757066455696201, -1.441615689655172 ], [ 29.757066455696201, -1.441346206896552 ], [ 29.756796962025316, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15820, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.441346206896552 ], [ 29.757066455696201, -1.441615689655172 ], [ 29.757335949367089, -1.441615689655172 ], [ 29.757335949367089, -1.441346206896552 ], [ 29.757066455696201, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15821, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.441346206896552 ], [ 29.757335949367089, -1.441615689655172 ], [ 29.757605443037974, -1.441615689655172 ], [ 29.757605443037974, -1.441346206896552 ], [ 29.757335949367089, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15822, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.441346206896552 ], [ 29.757605443037974, -1.441615689655172 ], [ 29.757874936708859, -1.441615689655172 ], [ 29.757874936708859, -1.441346206896552 ], [ 29.757605443037974, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15823, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.441346206896552 ], [ 29.757874936708859, -1.441615689655172 ], [ 29.758144430379748, -1.441615689655172 ], [ 29.758144430379748, -1.441346206896552 ], [ 29.757874936708859, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15824, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.441346206896552 ], [ 29.758144430379748, -1.441615689655172 ], [ 29.758413924050632, -1.441615689655172 ], [ 29.758413924050632, -1.441346206896552 ], [ 29.758144430379748, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15825, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.441346206896552 ], [ 29.758413924050632, -1.441615689655172 ], [ 29.758683417721517, -1.441615689655172 ], [ 29.758683417721517, -1.441346206896552 ], [ 29.758413924050632, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15826, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.441346206896552 ], [ 29.758683417721517, -1.441615689655172 ], [ 29.758952911392406, -1.441615689655172 ], [ 29.758952911392406, -1.441346206896552 ], [ 29.758683417721517, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15827, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.441346206896552 ], [ 29.758952911392406, -1.441615689655172 ], [ 29.761917341772151, -1.441615689655172 ], [ 29.761917341772151, -1.441346206896552 ], [ 29.758952911392406, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15828, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.441346206896552 ], [ 29.761917341772151, -1.441615689655172 ], [ 29.762186835443039, -1.441615689655172 ], [ 29.762186835443039, -1.441346206896552 ], [ 29.761917341772151, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15829, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.441346206896552 ], [ 29.762186835443039, -1.441615689655172 ], [ 29.762456329113924, -1.441615689655172 ], [ 29.762456329113924, -1.441346206896552 ], [ 29.762186835443039, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15830, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.441346206896552 ], [ 29.762456329113924, -1.441615689655172 ], [ 29.762725822784809, -1.441615689655172 ], [ 29.762725822784809, -1.441346206896552 ], [ 29.762456329113924, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15831, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.441346206896552 ], [ 29.762725822784809, -1.441615689655172 ], [ 29.762995316455697, -1.441615689655172 ], [ 29.762995316455697, -1.441346206896552 ], [ 29.762725822784809, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15832, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.441346206896552 ], [ 29.762995316455697, -1.441615689655172 ], [ 29.763264810126582, -1.441615689655172 ], [ 29.763264810126582, -1.441346206896552 ], [ 29.762995316455697, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15833, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.441346206896552 ], [ 29.763264810126582, -1.441615689655172 ], [ 29.763534303797467, -1.441615689655172 ], [ 29.763534303797467, -1.441346206896552 ], [ 29.763264810126582, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15834, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.441346206896552 ], [ 29.763534303797467, -1.441615689655172 ], [ 29.763803797468356, -1.441615689655172 ], [ 29.763803797468356, -1.441346206896552 ], [ 29.763534303797467, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15835, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.441346206896552 ], [ 29.763803797468356, -1.441615689655172 ], [ 29.76407329113924, -1.441615689655172 ], [ 29.76407329113924, -1.441346206896552 ], [ 29.763803797468356, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15836, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.441346206896552 ], [ 29.76407329113924, -1.441615689655172 ], [ 29.764342784810125, -1.441615689655172 ], [ 29.764342784810125, -1.441346206896552 ], [ 29.76407329113924, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15837, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.441346206896552 ], [ 29.764342784810125, -1.441615689655172 ], [ 29.764612278481014, -1.441615689655172 ], [ 29.764612278481014, -1.441346206896552 ], [ 29.764342784810125, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15838, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.441346206896552 ], [ 29.764612278481014, -1.441615689655172 ], [ 29.764881772151899, -1.441615689655172 ], [ 29.764881772151899, -1.441346206896552 ], [ 29.764612278481014, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15839, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.441346206896552 ], [ 29.764881772151899, -1.441615689655172 ], [ 29.765151265822784, -1.441615689655172 ], [ 29.765151265822784, -1.441346206896552 ], [ 29.764881772151899, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15840, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.441346206896552 ], [ 29.765151265822784, -1.441615689655172 ], [ 29.765420759493672, -1.441615689655172 ], [ 29.765420759493672, -1.441346206896552 ], [ 29.765151265822784, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15841, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.441346206896552 ], [ 29.765420759493672, -1.441615689655172 ], [ 29.765959746835442, -1.441615689655172 ], [ 29.765959746835442, -1.441346206896552 ], [ 29.765420759493672, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15842, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.441346206896552 ], [ 29.765959746835442, -1.441615689655172 ], [ 29.766229240506327, -1.441615689655172 ], [ 29.766229240506327, -1.441346206896552 ], [ 29.765959746835442, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15843, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.441346206896552 ], [ 29.766229240506327, -1.441615689655172 ], [ 29.766498734177215, -1.441615689655172 ], [ 29.766498734177215, -1.441346206896552 ], [ 29.766229240506327, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15844, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.441346206896552 ], [ 29.766498734177215, -1.441615689655172 ], [ 29.7667682278481, -1.441615689655172 ], [ 29.7667682278481, -1.441346206896552 ], [ 29.766498734177215, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15845, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.441346206896552 ], [ 29.7667682278481, -1.441615689655172 ], [ 29.767037721518985, -1.441615689655172 ], [ 29.767037721518985, -1.441346206896552 ], [ 29.7667682278481, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15846, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.441346206896552 ], [ 29.767037721518985, -1.441615689655172 ], [ 29.767307215189874, -1.441615689655172 ], [ 29.767307215189874, -1.441346206896552 ], [ 29.767037721518985, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15847, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.441346206896552 ], [ 29.767307215189874, -1.441615689655172 ], [ 29.767576708860759, -1.441615689655172 ], [ 29.767576708860759, -1.441346206896552 ], [ 29.767307215189874, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15848, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.441346206896552 ], [ 29.767576708860759, -1.441615689655172 ], [ 29.767846202531643, -1.441615689655172 ], [ 29.767846202531643, -1.441346206896552 ], [ 29.767576708860759, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15849, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.441346206896552 ], [ 29.767846202531643, -1.441615689655172 ], [ 29.768115696202532, -1.441615689655172 ], [ 29.768115696202532, -1.441346206896552 ], [ 29.767846202531643, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15850, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.441346206896552 ], [ 29.768115696202532, -1.441615689655172 ], [ 29.768385189873417, -1.441615689655172 ], [ 29.768385189873417, -1.441346206896552 ], [ 29.768115696202532, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15851, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.441346206896552 ], [ 29.768385189873417, -1.441615689655172 ], [ 29.76892417721519, -1.441615689655172 ], [ 29.76892417721519, -1.441346206896552 ], [ 29.768385189873417, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15852, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.441346206896552 ], [ 29.76892417721519, -1.441615689655172 ], [ 29.769193670886075, -1.441615689655172 ], [ 29.769193670886075, -1.441346206896552 ], [ 29.76892417721519, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15853, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.441346206896552 ], [ 29.769193670886075, -1.441615689655172 ], [ 29.76946316455696, -1.441615689655172 ], [ 29.76946316455696, -1.441346206896552 ], [ 29.769193670886075, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15854, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.441346206896552 ], [ 29.76946316455696, -1.441615689655172 ], [ 29.769732658227849, -1.441615689655172 ], [ 29.769732658227849, -1.441346206896552 ], [ 29.76946316455696, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15855, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.441346206896552 ], [ 29.769732658227849, -1.441615689655172 ], [ 29.770002151898733, -1.441615689655172 ], [ 29.770002151898733, -1.441346206896552 ], [ 29.769732658227849, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15856, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.441346206896552 ], [ 29.770002151898733, -1.441615689655172 ], [ 29.770271645569618, -1.441615689655172 ], [ 29.770271645569618, -1.441346206896552 ], [ 29.770002151898733, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15857, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.441346206896552 ], [ 29.770271645569618, -1.441615689655172 ], [ 29.770541139240507, -1.441615689655172 ], [ 29.770541139240507, -1.441346206896552 ], [ 29.770271645569618, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15858, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.441346206896552 ], [ 29.770541139240507, -1.441615689655172 ], [ 29.770810632911392, -1.441615689655172 ], [ 29.770810632911392, -1.441346206896552 ], [ 29.770541139240507, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15859, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.441346206896552 ], [ 29.770810632911392, -1.441615689655172 ], [ 29.771080126582277, -1.441615689655172 ], [ 29.771080126582277, -1.441346206896552 ], [ 29.770810632911392, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15860, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.441346206896552 ], [ 29.771080126582277, -1.441615689655172 ], [ 29.771349620253165, -1.441615689655172 ], [ 29.771349620253165, -1.441346206896552 ], [ 29.771080126582277, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15861, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.441346206896552 ], [ 29.771349620253165, -1.441615689655172 ], [ 29.77161911392405, -1.441615689655172 ], [ 29.77161911392405, -1.441346206896552 ], [ 29.771349620253165, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15862, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.441346206896552 ], [ 29.77161911392405, -1.441615689655172 ], [ 29.771888607594935, -1.441615689655172 ], [ 29.771888607594935, -1.441346206896552 ], [ 29.77161911392405, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15863, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.441346206896552 ], [ 29.771888607594935, -1.441615689655172 ], [ 29.772158101265823, -1.441615689655172 ], [ 29.772158101265823, -1.441346206896552 ], [ 29.771888607594935, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15864, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.441346206896552 ], [ 29.772158101265823, -1.441615689655172 ], [ 29.772427594936708, -1.441615689655172 ], [ 29.772427594936708, -1.441346206896552 ], [ 29.772158101265823, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15865, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.441346206896552 ], [ 29.772427594936708, -1.441615689655172 ], [ 29.772697088607593, -1.441615689655172 ], [ 29.772697088607593, -1.441346206896552 ], [ 29.772427594936708, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15866, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.441346206896552 ], [ 29.772697088607593, -1.441615689655172 ], [ 29.772966582278482, -1.441615689655172 ], [ 29.772966582278482, -1.441346206896552 ], [ 29.772697088607593, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15867, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.441346206896552 ], [ 29.772966582278482, -1.441615689655172 ], [ 29.773236075949367, -1.441615689655172 ], [ 29.773236075949367, -1.441346206896552 ], [ 29.772966582278482, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15868, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.441346206896552 ], [ 29.773236075949367, -1.441615689655172 ], [ 29.773505569620252, -1.441615689655172 ], [ 29.773505569620252, -1.441346206896552 ], [ 29.773236075949367, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15869, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.441346206896552 ], [ 29.773505569620252, -1.441615689655172 ], [ 29.77377506329114, -1.441615689655172 ], [ 29.77377506329114, -1.441346206896552 ], [ 29.773505569620252, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15870, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.441346206896552 ], [ 29.77377506329114, -1.441615689655172 ], [ 29.774044556962025, -1.441615689655172 ], [ 29.774044556962025, -1.441346206896552 ], [ 29.77377506329114, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15871, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.441346206896552 ], [ 29.774044556962025, -1.441615689655172 ], [ 29.77431405063291, -1.441615689655172 ], [ 29.77431405063291, -1.441346206896552 ], [ 29.774044556962025, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15872, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.441346206896552 ], [ 29.77431405063291, -1.441615689655172 ], [ 29.774853037974683, -1.441615689655172 ], [ 29.774853037974683, -1.441346206896552 ], [ 29.77431405063291, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15873, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.441346206896552 ], [ 29.774853037974683, -1.441615689655172 ], [ 29.775122531645568, -1.441615689655172 ], [ 29.775122531645568, -1.441346206896552 ], [ 29.774853037974683, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15874, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.441346206896552 ], [ 29.775122531645568, -1.441615689655172 ], [ 29.775392025316457, -1.441615689655172 ], [ 29.775392025316457, -1.441346206896552 ], [ 29.775122531645568, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15875, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.441346206896552 ], [ 29.775392025316457, -1.441615689655172 ], [ 29.775661518987341, -1.441615689655172 ], [ 29.775661518987341, -1.441346206896552 ], [ 29.775392025316457, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15876, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.441346206896552 ], [ 29.776200506329115, -1.441615689655172 ], [ 29.77647, -1.441615689655172 ], [ 29.77647, -1.441346206896552 ], [ 29.776200506329115, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15877, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.441346206896552 ], [ 29.77647, -1.441615689655172 ], [ 29.776739493670885, -1.441615689655172 ], [ 29.776739493670885, -1.441346206896552 ], [ 29.77647, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15878, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.441346206896552 ], [ 29.777008987341773, -1.441615689655172 ], [ 29.777278481012658, -1.441615689655172 ], [ 29.777278481012658, -1.441346206896552 ], [ 29.777008987341773, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15879, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.441346206896552 ], [ 29.777278481012658, -1.441615689655172 ], [ 29.777547974683543, -1.441615689655172 ], [ 29.777547974683543, -1.441346206896552 ], [ 29.777278481012658, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15880, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.441346206896552 ], [ 29.777817468354431, -1.441615689655172 ], [ 29.778086962025316, -1.441615689655172 ], [ 29.778086962025316, -1.441346206896552 ], [ 29.777817468354431, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15881, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.441346206896552 ], [ 29.778086962025316, -1.441615689655172 ], [ 29.778356455696201, -1.441615689655172 ], [ 29.778356455696201, -1.441346206896552 ], [ 29.778086962025316, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15882, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.441346206896552 ], [ 29.778356455696201, -1.441615689655172 ], [ 29.77862594936709, -1.441615689655172 ], [ 29.77862594936709, -1.441346206896552 ], [ 29.778356455696201, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15883, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.441346206896552 ], [ 29.778895443037975, -1.441615689655172 ], [ 29.77916493670886, -1.441615689655172 ], [ 29.77916493670886, -1.441346206896552 ], [ 29.778895443037975, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15884, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.441346206896552 ], [ 29.77916493670886, -1.441615689655172 ], [ 29.779434430379748, -1.441615689655172 ], [ 29.779434430379748, -1.441346206896552 ], [ 29.77916493670886, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15885, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.441346206896552 ], [ 29.779434430379748, -1.441615689655172 ], [ 29.779703924050633, -1.441615689655172 ], [ 29.779703924050633, -1.441346206896552 ], [ 29.779434430379748, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15886, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.441346206896552 ], [ 29.779703924050633, -1.441615689655172 ], [ 29.779973417721518, -1.441615689655172 ], [ 29.779973417721518, -1.441346206896552 ], [ 29.779703924050633, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15887, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.441346206896552 ], [ 29.779973417721518, -1.441615689655172 ], [ 29.780242911392406, -1.441615689655172 ], [ 29.780242911392406, -1.441346206896552 ], [ 29.779973417721518, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15888, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.441346206896552 ], [ 29.780242911392406, -1.441615689655172 ], [ 29.780512405063291, -1.441615689655172 ], [ 29.780512405063291, -1.441346206896552 ], [ 29.780242911392406, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15889, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.441346206896552 ], [ 29.780512405063291, -1.441615689655172 ], [ 29.780781898734176, -1.441615689655172 ], [ 29.780781898734176, -1.441346206896552 ], [ 29.780512405063291, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15890, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.441346206896552 ], [ 29.780781898734176, -1.441615689655172 ], [ 29.781051392405065, -1.441615689655172 ], [ 29.781051392405065, -1.441346206896552 ], [ 29.780781898734176, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15891, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.441346206896552 ], [ 29.781051392405065, -1.441615689655172 ], [ 29.78132088607595, -1.441615689655172 ], [ 29.78132088607595, -1.441346206896552 ], [ 29.781051392405065, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15892, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.441346206896552 ], [ 29.78132088607595, -1.441615689655172 ], [ 29.781590379746834, -1.441615689655172 ], [ 29.781590379746834, -1.441346206896552 ], [ 29.78132088607595, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15893, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.441346206896552 ], [ 29.781590379746834, -1.441615689655172 ], [ 29.781859873417723, -1.441615689655172 ], [ 29.781859873417723, -1.441346206896552 ], [ 29.781590379746834, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15894, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.441346206896552 ], [ 29.781859873417723, -1.441615689655172 ], [ 29.782129367088608, -1.441615689655172 ], [ 29.782129367088608, -1.441346206896552 ], [ 29.781859873417723, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15895, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.441346206896552 ], [ 29.782129367088608, -1.441615689655172 ], [ 29.782398860759493, -1.441615689655172 ], [ 29.782398860759493, -1.441346206896552 ], [ 29.782129367088608, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15896, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.441346206896552 ], [ 29.782398860759493, -1.441615689655172 ], [ 29.782668354430381, -1.441615689655172 ], [ 29.782668354430381, -1.441346206896552 ], [ 29.782398860759493, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15897, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.441346206896552 ], [ 29.782668354430381, -1.441615689655172 ], [ 29.782937848101266, -1.441615689655172 ], [ 29.782937848101266, -1.441346206896552 ], [ 29.782668354430381, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15898, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.441346206896552 ], [ 29.782937848101266, -1.441615689655172 ], [ 29.783207341772151, -1.441615689655172 ], [ 29.783207341772151, -1.441346206896552 ], [ 29.782937848101266, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15899, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.441346206896552 ], [ 29.783207341772151, -1.441615689655172 ], [ 29.783476835443039, -1.441615689655172 ], [ 29.783476835443039, -1.441346206896552 ], [ 29.783207341772151, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15900, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.441346206896552 ], [ 29.784015822784809, -1.441615689655172 ], [ 29.784285316455698, -1.441615689655172 ], [ 29.784285316455698, -1.441346206896552 ], [ 29.784015822784809, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15901, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.441346206896552 ], [ 29.784285316455698, -1.441615689655172 ], [ 29.784554810126583, -1.441615689655172 ], [ 29.784554810126583, -1.441346206896552 ], [ 29.784285316455698, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15902, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.441346206896552 ], [ 29.789944683544302, -1.441615689655172 ], [ 29.790214177215191, -1.441615689655172 ], [ 29.790214177215191, -1.441346206896552 ], [ 29.789944683544302, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15903, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.441346206896552 ], [ 29.790214177215191, -1.441615689655172 ], [ 29.790483670886076, -1.441615689655172 ], [ 29.790483670886076, -1.441346206896552 ], [ 29.790214177215191, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15904, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.441346206896552 ], [ 29.790483670886076, -1.441615689655172 ], [ 29.790753164556961, -1.441615689655172 ], [ 29.790753164556961, -1.441346206896552 ], [ 29.790483670886076, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15905, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.441346206896552 ], [ 29.794256582278482, -1.441615689655172 ], [ 29.794526075949367, -1.441615689655172 ], [ 29.794526075949367, -1.441346206896552 ], [ 29.794256582278482, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15906, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.441346206896552 ], [ 29.794526075949367, -1.441615689655172 ], [ 29.794795569620252, -1.441615689655172 ], [ 29.794795569620252, -1.441346206896552 ], [ 29.794526075949367, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15907, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.441346206896552 ], [ 29.795334556962025, -1.441615689655172 ], [ 29.79560405063291, -1.441615689655172 ], [ 29.79560405063291, -1.441346206896552 ], [ 29.795334556962025, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15908, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.441346206896552 ], [ 29.797221012658227, -1.441615689655172 ], [ 29.797490506329115, -1.441615689655172 ], [ 29.797490506329115, -1.441346206896552 ], [ 29.797221012658227, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15909, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.441346206896552 ], [ 29.797490506329115, -1.441615689655172 ], [ 29.79776, -1.441615689655172 ], [ 29.79776, -1.441346206896552 ], [ 29.797490506329115, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15910, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.441346206896552 ], [ 29.79776, -1.441615689655172 ], [ 29.798029493670885, -1.441615689655172 ], [ 29.798029493670885, -1.441346206896552 ], [ 29.79776, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15911, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.441346206896552 ], [ 29.798029493670885, -1.441615689655172 ], [ 29.799107468354432, -1.441615689655172 ], [ 29.799107468354432, -1.441346206896552 ], [ 29.798029493670885, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15912, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.441346206896552 ], [ 29.799107468354432, -1.441615689655172 ], [ 29.799376962025317, -1.441615689655172 ], [ 29.799376962025317, -1.441346206896552 ], [ 29.799107468354432, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15913, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.441346206896552 ], [ 29.799376962025317, -1.441615689655172 ], [ 29.799646455696202, -1.441615689655172 ], [ 29.799646455696202, -1.441346206896552 ], [ 29.799376962025317, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15914, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.441346206896552 ], [ 29.799646455696202, -1.441615689655172 ], [ 29.79991594936709, -1.441615689655172 ], [ 29.79991594936709, -1.441346206896552 ], [ 29.799646455696202, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15915, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.441346206896552 ], [ 29.79991594936709, -1.441615689655172 ], [ 29.800185443037975, -1.441615689655172 ], [ 29.800185443037975, -1.441346206896552 ], [ 29.79991594936709, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15916, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.441346206896552 ], [ 29.800185443037975, -1.441615689655172 ], [ 29.80045493670886, -1.441615689655172 ], [ 29.80045493670886, -1.441346206896552 ], [ 29.800185443037975, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15917, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.441346206896552 ], [ 29.80045493670886, -1.441615689655172 ], [ 29.800724430379748, -1.441615689655172 ], [ 29.800724430379748, -1.441346206896552 ], [ 29.80045493670886, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15918, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.441346206896552 ], [ 29.800724430379748, -1.441615689655172 ], [ 29.800993924050633, -1.441615689655172 ], [ 29.800993924050633, -1.441346206896552 ], [ 29.800724430379748, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15919, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.441346206896552 ], [ 29.800993924050633, -1.441615689655172 ], [ 29.801263417721518, -1.441615689655172 ], [ 29.801263417721518, -1.441346206896552 ], [ 29.800993924050633, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15920, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.441346206896552 ], [ 29.801263417721518, -1.441615689655172 ], [ 29.801532911392407, -1.441615689655172 ], [ 29.801532911392407, -1.441346206896552 ], [ 29.801263417721518, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15921, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.441346206896552 ], [ 29.801532911392407, -1.441615689655172 ], [ 29.802071898734177, -1.441615689655172 ], [ 29.802071898734177, -1.441346206896552 ], [ 29.801532911392407, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15922, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.441346206896552 ], [ 29.802071898734177, -1.441615689655172 ], [ 29.802341392405065, -1.441615689655172 ], [ 29.802341392405065, -1.441346206896552 ], [ 29.802071898734177, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15923, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.441346206896552 ], [ 29.802341392405065, -1.441615689655172 ], [ 29.80261088607595, -1.441615689655172 ], [ 29.80261088607595, -1.441346206896552 ], [ 29.802341392405065, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15924, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.441346206896552 ], [ 29.80261088607595, -1.441615689655172 ], [ 29.802880379746835, -1.441615689655172 ], [ 29.802880379746835, -1.441346206896552 ], [ 29.80261088607595, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15925, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.441346206896552 ], [ 29.802880379746835, -1.441615689655172 ], [ 29.803419367088608, -1.441615689655172 ], [ 29.803419367088608, -1.441346206896552 ], [ 29.802880379746835, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15926, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.441346206896552 ], [ 29.803419367088608, -1.441615689655172 ], [ 29.803688860759493, -1.441615689655172 ], [ 29.803688860759493, -1.441346206896552 ], [ 29.803419367088608, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15927, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.441346206896552 ], [ 29.803688860759493, -1.441615689655172 ], [ 29.803958354430382, -1.441615689655172 ], [ 29.803958354430382, -1.441346206896552 ], [ 29.803688860759493, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15928, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.441346206896552 ], [ 29.803958354430382, -1.441615689655172 ], [ 29.804227848101267, -1.441615689655172 ], [ 29.804227848101267, -1.441346206896552 ], [ 29.803958354430382, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15929, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.441346206896552 ], [ 29.804227848101267, -1.441615689655172 ], [ 29.804497341772151, -1.441615689655172 ], [ 29.804497341772151, -1.441346206896552 ], [ 29.804227848101267, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15930, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.441346206896552 ], [ 29.804497341772151, -1.441615689655172 ], [ 29.80476683544304, -1.441615689655172 ], [ 29.80476683544304, -1.441346206896552 ], [ 29.804497341772151, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15931, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.441346206896552 ], [ 29.80476683544304, -1.441615689655172 ], [ 29.805036329113925, -1.441615689655172 ], [ 29.805036329113925, -1.441346206896552 ], [ 29.80476683544304, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15932, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.441346206896552 ], [ 29.805036329113925, -1.441615689655172 ], [ 29.80530582278481, -1.441615689655172 ], [ 29.80530582278481, -1.441346206896552 ], [ 29.805036329113925, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15933, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.441346206896552 ], [ 29.80530582278481, -1.441615689655172 ], [ 29.805575316455698, -1.441615689655172 ], [ 29.805575316455698, -1.441346206896552 ], [ 29.80530582278481, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15934, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.441346206896552 ], [ 29.805575316455698, -1.441615689655172 ], [ 29.805844810126583, -1.441615689655172 ], [ 29.805844810126583, -1.441346206896552 ], [ 29.805575316455698, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15935, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.441346206896552 ], [ 29.805844810126583, -1.441615689655172 ], [ 29.806114303797468, -1.441615689655172 ], [ 29.806114303797468, -1.441346206896552 ], [ 29.805844810126583, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15936, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.441346206896552 ], [ 29.806114303797468, -1.441615689655172 ], [ 29.806383797468357, -1.441615689655172 ], [ 29.806383797468357, -1.441346206896552 ], [ 29.806114303797468, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15937, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.441346206896552 ], [ 29.806383797468357, -1.441615689655172 ], [ 29.806653291139241, -1.441615689655172 ], [ 29.806653291139241, -1.441346206896552 ], [ 29.806383797468357, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15938, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.441346206896552 ], [ 29.806653291139241, -1.441615689655172 ], [ 29.806922784810126, -1.441615689655172 ], [ 29.806922784810126, -1.441346206896552 ], [ 29.806653291139241, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15939, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.441346206896552 ], [ 29.806922784810126, -1.441615689655172 ], [ 29.8074617721519, -1.441615689655172 ], [ 29.8074617721519, -1.441346206896552 ], [ 29.806922784810126, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15940, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.441346206896552 ], [ 29.8074617721519, -1.441615689655172 ], [ 29.807731265822785, -1.441615689655172 ], [ 29.807731265822785, -1.441346206896552 ], [ 29.8074617721519, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15941, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.441346206896552 ], [ 29.807731265822785, -1.441615689655172 ], [ 29.808000759493673, -1.441615689655172 ], [ 29.808000759493673, -1.441346206896552 ], [ 29.807731265822785, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15942, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.441346206896552 ], [ 29.808000759493673, -1.441615689655172 ], [ 29.808270253164558, -1.441615689655172 ], [ 29.808270253164558, -1.441346206896552 ], [ 29.808000759493673, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15943, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.441346206896552 ], [ 29.808270253164558, -1.441615689655172 ], [ 29.808539746835443, -1.441615689655172 ], [ 29.808539746835443, -1.441346206896552 ], [ 29.808270253164558, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15944, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.441346206896552 ], [ 29.808539746835443, -1.441615689655172 ], [ 29.809348227848101, -1.441615689655172 ], [ 29.809348227848101, -1.441346206896552 ], [ 29.808539746835443, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15945, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.441346206896552 ], [ 29.809348227848101, -1.441615689655172 ], [ 29.809617721518986, -1.441615689655172 ], [ 29.809617721518986, -1.441346206896552 ], [ 29.809348227848101, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15946, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.441346206896552 ], [ 29.809617721518986, -1.441615689655172 ], [ 29.809887215189875, -1.441615689655172 ], [ 29.809887215189875, -1.441346206896552 ], [ 29.809617721518986, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15947, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.441346206896552 ], [ 29.809887215189875, -1.441615689655172 ], [ 29.81015670886076, -1.441615689655172 ], [ 29.81015670886076, -1.441346206896552 ], [ 29.809887215189875, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15948, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.44053775862069 ], [ 29.752754556962024, -1.441346206896552 ], [ 29.753024050632909, -1.441346206896552 ], [ 29.753024050632909, -1.442154655172414 ], [ 29.753563037974683, -1.442154655172414 ], [ 29.753563037974683, -1.441885172413793 ], [ 29.753293544303798, -1.441885172413793 ], [ 29.753293544303798, -1.441076724137931 ], [ 29.753024050632909, -1.441076724137931 ], [ 29.753024050632909, -1.44053775862069 ], [ 29.752754556962024, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15949, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.44053775862069 ], [ 29.786441265822784, -1.441885172413793 ], [ 29.786710759493673, -1.441885172413793 ], [ 29.786710759493673, -1.44053775862069 ], [ 29.786441265822784, -1.44053775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15950, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.44080724137931 ], [ 29.785902278481014, -1.441885172413793 ], [ 29.786171772151899, -1.441885172413793 ], [ 29.786171772151899, -1.44080724137931 ], [ 29.785902278481014, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15951, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.44080724137931 ], [ 29.786171772151899, -1.441885172413793 ], [ 29.786441265822784, -1.441885172413793 ], [ 29.786441265822784, -1.44080724137931 ], [ 29.786171772151899, -1.44080724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15952, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.441076724137931 ], [ 29.785093797468356, -1.441885172413793 ], [ 29.785363291139241, -1.441885172413793 ], [ 29.785363291139241, -1.441076724137931 ], [ 29.785093797468356, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15953, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.441076724137931 ], [ 29.785363291139241, -1.442424137931035 ], [ 29.785632784810126, -1.442424137931035 ], [ 29.785632784810126, -1.441076724137931 ], [ 29.785363291139241, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15954, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.441076724137931 ], [ 29.785632784810126, -1.442424137931035 ], [ 29.785902278481014, -1.442424137931035 ], [ 29.785902278481014, -1.441076724137931 ], [ 29.785632784810126, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15955, "El": 129 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.441346206896552 ], [ 29.775661518987341, -1.441885172413793 ], [ 29.775931012658226, -1.441885172413793 ], [ 29.775931012658226, -1.441346206896552 ], [ 29.775661518987341, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15956, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.441346206896552 ], [ 29.777547974683543, -1.441885172413793 ], [ 29.777817468354431, -1.441885172413793 ], [ 29.777817468354431, -1.441346206896552 ], [ 29.777547974683543, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15957, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.441346206896552 ], [ 29.783476835443039, -1.442154655172414 ], [ 29.784015822784809, -1.442154655172414 ], [ 29.784015822784809, -1.441346206896552 ], [ 29.783476835443039, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15958, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.441346206896552 ], [ 29.784554810126583, -1.442154655172414 ], [ 29.784824303797468, -1.442154655172414 ], [ 29.784824303797468, -1.441346206896552 ], [ 29.784554810126583, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15959, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.441346206896552 ], [ 29.784824303797468, -1.442154655172414 ], [ 29.785093797468356, -1.442154655172414 ], [ 29.785093797468356, -1.441346206896552 ], [ 29.784824303797468, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15960, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.441346206896552 ], [ 29.795873544303799, -1.441885172413793 ], [ 29.796143037974684, -1.441885172413793 ], [ 29.796143037974684, -1.441346206896552 ], [ 29.795873544303799, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15961, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.441346206896552 ], [ 29.796143037974684, -1.441885172413793 ], [ 29.796412531645569, -1.441885172413793 ], [ 29.796412531645569, -1.441346206896552 ], [ 29.796143037974684, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15962, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.441346206896552 ], [ 29.796682025316457, -1.442154655172414 ], [ 29.796951518987342, -1.442154655172414 ], [ 29.796951518987342, -1.441346206896552 ], [ 29.796682025316457, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15963, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.441346206896552 ], [ 29.796951518987342, -1.442154655172414 ], [ 29.797221012658227, -1.442154655172414 ], [ 29.797221012658227, -1.441346206896552 ], [ 29.796951518987342, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15964, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746017215189873, -1.441615689655172 ], [ 29.746017215189873, -1.441885172413793 ], [ 29.746286708860758, -1.441885172413793 ], [ 29.746286708860758, -1.441615689655172 ], [ 29.746017215189873, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15965, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.441615689655172 ], [ 29.746286708860758, -1.441885172413793 ], [ 29.746556202531643, -1.441885172413793 ], [ 29.746556202531643, -1.441615689655172 ], [ 29.746286708860758, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15966, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.441615689655172 ], [ 29.746556202531643, -1.441885172413793 ], [ 29.746825696202531, -1.441885172413793 ], [ 29.746825696202531, -1.441615689655172 ], [ 29.746556202531643, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15967, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.441615689655172 ], [ 29.746825696202531, -1.441885172413793 ], [ 29.747095189873416, -1.441885172413793 ], [ 29.747095189873416, -1.441615689655172 ], [ 29.746825696202531, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15968, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.441615689655172 ], [ 29.747095189873416, -1.441885172413793 ], [ 29.747364683544301, -1.441885172413793 ], [ 29.747364683544301, -1.441615689655172 ], [ 29.747095189873416, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15969, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.441615689655172 ], [ 29.747364683544301, -1.441885172413793 ], [ 29.747903670886075, -1.441885172413793 ], [ 29.747903670886075, -1.441615689655172 ], [ 29.747364683544301, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15970, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.441615689655172 ], [ 29.747903670886075, -1.441885172413793 ], [ 29.74817316455696, -1.441885172413793 ], [ 29.74817316455696, -1.441615689655172 ], [ 29.747903670886075, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15971, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.441615689655172 ], [ 29.74817316455696, -1.441885172413793 ], [ 29.748442658227848, -1.441885172413793 ], [ 29.748442658227848, -1.441615689655172 ], [ 29.74817316455696, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15972, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.441615689655172 ], [ 29.748442658227848, -1.441885172413793 ], [ 29.748712151898733, -1.441885172413793 ], [ 29.748712151898733, -1.441615689655172 ], [ 29.748442658227848, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15973, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.441615689655172 ], [ 29.748712151898733, -1.441885172413793 ], [ 29.748981645569618, -1.441885172413793 ], [ 29.748981645569618, -1.441615689655172 ], [ 29.748712151898733, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15974, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.441615689655172 ], [ 29.748981645569618, -1.441885172413793 ], [ 29.749251139240506, -1.441885172413793 ], [ 29.749251139240506, -1.441615689655172 ], [ 29.748981645569618, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15975, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.441615689655172 ], [ 29.749251139240506, -1.441885172413793 ], [ 29.749520632911391, -1.441885172413793 ], [ 29.749520632911391, -1.441615689655172 ], [ 29.749251139240506, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15976, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.441615689655172 ], [ 29.749520632911391, -1.441885172413793 ], [ 29.749790126582276, -1.441885172413793 ], [ 29.749790126582276, -1.441615689655172 ], [ 29.749520632911391, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15977, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.441615689655172 ], [ 29.749790126582276, -1.441885172413793 ], [ 29.750059620253165, -1.441885172413793 ], [ 29.750059620253165, -1.441615689655172 ], [ 29.749790126582276, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15978, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.441615689655172 ], [ 29.750059620253165, -1.441885172413793 ], [ 29.75032911392405, -1.441885172413793 ], [ 29.75032911392405, -1.441615689655172 ], [ 29.750059620253165, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15979, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.441615689655172 ], [ 29.75032911392405, -1.441885172413793 ], [ 29.750598607594934, -1.441885172413793 ], [ 29.750598607594934, -1.441615689655172 ], [ 29.75032911392405, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15980, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.441615689655172 ], [ 29.750598607594934, -1.442154655172414 ], [ 29.750868101265823, -1.442154655172414 ], [ 29.750868101265823, -1.441615689655172 ], [ 29.750598607594934, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15981, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.441615689655172 ], [ 29.750868101265823, -1.441885172413793 ], [ 29.751137594936708, -1.441885172413793 ], [ 29.751137594936708, -1.441615689655172 ], [ 29.750868101265823, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15982, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.441615689655172 ], [ 29.751137594936708, -1.441885172413793 ], [ 29.751407088607593, -1.441885172413793 ], [ 29.751407088607593, -1.441615689655172 ], [ 29.751137594936708, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15983, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.441615689655172 ], [ 29.751407088607593, -1.441885172413793 ], [ 29.751676582278481, -1.441885172413793 ], [ 29.751676582278481, -1.441615689655172 ], [ 29.751407088607593, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15984, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.441615689655172 ], [ 29.751676582278481, -1.441885172413793 ], [ 29.751946075949366, -1.441885172413793 ], [ 29.751946075949366, -1.441615689655172 ], [ 29.751676582278481, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15985, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.441615689655172 ], [ 29.751946075949366, -1.441885172413793 ], [ 29.752215569620251, -1.441885172413793 ], [ 29.752215569620251, -1.441615689655172 ], [ 29.751946075949366, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15986, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.441615689655172 ], [ 29.752215569620251, -1.441885172413793 ], [ 29.75248506329114, -1.441885172413793 ], [ 29.75248506329114, -1.441615689655172 ], [ 29.752215569620251, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15987, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.441615689655172 ], [ 29.75248506329114, -1.441885172413793 ], [ 29.752754556962024, -1.441885172413793 ], [ 29.752754556962024, -1.441615689655172 ], [ 29.75248506329114, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15988, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.441615689655172 ], [ 29.752754556962024, -1.441885172413793 ], [ 29.753024050632909, -1.441885172413793 ], [ 29.753024050632909, -1.441615689655172 ], [ 29.752754556962024, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15989, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.441615689655172 ], [ 29.753293544303798, -1.441885172413793 ], [ 29.753563037974683, -1.441885172413793 ], [ 29.753563037974683, -1.441615689655172 ], [ 29.753293544303798, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15990, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.441615689655172 ], [ 29.753563037974683, -1.441885172413793 ], [ 29.753832531645568, -1.441885172413793 ], [ 29.753832531645568, -1.441615689655172 ], [ 29.753563037974683, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15991, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.441615689655172 ], [ 29.753832531645568, -1.441885172413793 ], [ 29.754102025316456, -1.441885172413793 ], [ 29.754102025316456, -1.441615689655172 ], [ 29.753832531645568, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15992, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.441615689655172 ], [ 29.754102025316456, -1.441885172413793 ], [ 29.754371518987341, -1.441885172413793 ], [ 29.754371518987341, -1.441615689655172 ], [ 29.754102025316456, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15993, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.441615689655172 ], [ 29.754371518987341, -1.441885172413793 ], [ 29.754641012658226, -1.441885172413793 ], [ 29.754641012658226, -1.441615689655172 ], [ 29.754371518987341, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15994, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.441615689655172 ], [ 29.754641012658226, -1.441885172413793 ], [ 29.754910506329114, -1.441885172413793 ], [ 29.754910506329114, -1.441615689655172 ], [ 29.754641012658226, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15995, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.441615689655172 ], [ 29.754910506329114, -1.441885172413793 ], [ 29.75518, -1.441885172413793 ], [ 29.75518, -1.441615689655172 ], [ 29.754910506329114, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15996, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.441615689655172 ], [ 29.75518, -1.441885172413793 ], [ 29.755449493670884, -1.441885172413793 ], [ 29.755449493670884, -1.441615689655172 ], [ 29.75518, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15997, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.441615689655172 ], [ 29.755449493670884, -1.441885172413793 ], [ 29.755718987341773, -1.441885172413793 ], [ 29.755718987341773, -1.441615689655172 ], [ 29.755449493670884, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15998, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.441615689655172 ], [ 29.755718987341773, -1.441885172413793 ], [ 29.755988481012658, -1.441885172413793 ], [ 29.755988481012658, -1.441615689655172 ], [ 29.755718987341773, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 15999, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.441615689655172 ], [ 29.755988481012658, -1.441885172413793 ], [ 29.756257974683542, -1.441885172413793 ], [ 29.756257974683542, -1.441615689655172 ], [ 29.755988481012658, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16000, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.441615689655172 ], [ 29.756257974683542, -1.441885172413793 ], [ 29.756796962025316, -1.441885172413793 ], [ 29.756796962025316, -1.441615689655172 ], [ 29.756257974683542, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16001, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.441615689655172 ], [ 29.756796962025316, -1.441885172413793 ], [ 29.757066455696201, -1.441885172413793 ], [ 29.757066455696201, -1.441615689655172 ], [ 29.756796962025316, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16002, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.441615689655172 ], [ 29.757066455696201, -1.441885172413793 ], [ 29.757335949367089, -1.441885172413793 ], [ 29.757335949367089, -1.441615689655172 ], [ 29.757066455696201, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16003, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.441615689655172 ], [ 29.757335949367089, -1.441885172413793 ], [ 29.757605443037974, -1.441885172413793 ], [ 29.757605443037974, -1.441615689655172 ], [ 29.757335949367089, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16004, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.441615689655172 ], [ 29.757605443037974, -1.441885172413793 ], [ 29.757874936708859, -1.441885172413793 ], [ 29.757874936708859, -1.441615689655172 ], [ 29.757605443037974, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16005, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.441615689655172 ], [ 29.757874936708859, -1.441885172413793 ], [ 29.758144430379748, -1.441885172413793 ], [ 29.758144430379748, -1.441615689655172 ], [ 29.757874936708859, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16006, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.441615689655172 ], [ 29.758144430379748, -1.441885172413793 ], [ 29.758413924050632, -1.441885172413793 ], [ 29.758413924050632, -1.441615689655172 ], [ 29.758144430379748, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16007, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.441615689655172 ], [ 29.758413924050632, -1.441885172413793 ], [ 29.758683417721517, -1.441885172413793 ], [ 29.758683417721517, -1.441615689655172 ], [ 29.758413924050632, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16008, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.441615689655172 ], [ 29.758683417721517, -1.441885172413793 ], [ 29.758952911392406, -1.441885172413793 ], [ 29.758952911392406, -1.441615689655172 ], [ 29.758683417721517, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16009, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.441615689655172 ], [ 29.758952911392406, -1.441885172413793 ], [ 29.761917341772151, -1.441885172413793 ], [ 29.761917341772151, -1.441615689655172 ], [ 29.758952911392406, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16010, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.441615689655172 ], [ 29.761917341772151, -1.441885172413793 ], [ 29.762186835443039, -1.441885172413793 ], [ 29.762186835443039, -1.441615689655172 ], [ 29.761917341772151, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16011, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.441615689655172 ], [ 29.762186835443039, -1.441885172413793 ], [ 29.762456329113924, -1.441885172413793 ], [ 29.762456329113924, -1.441615689655172 ], [ 29.762186835443039, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16012, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.441615689655172 ], [ 29.762456329113924, -1.441885172413793 ], [ 29.762725822784809, -1.441885172413793 ], [ 29.762725822784809, -1.441615689655172 ], [ 29.762456329113924, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16013, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.441615689655172 ], [ 29.762725822784809, -1.441885172413793 ], [ 29.762995316455697, -1.441885172413793 ], [ 29.762995316455697, -1.441615689655172 ], [ 29.762725822784809, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16014, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.441615689655172 ], [ 29.762995316455697, -1.441885172413793 ], [ 29.763264810126582, -1.441885172413793 ], [ 29.763264810126582, -1.441615689655172 ], [ 29.762995316455697, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16015, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.441615689655172 ], [ 29.763264810126582, -1.441885172413793 ], [ 29.763534303797467, -1.441885172413793 ], [ 29.763534303797467, -1.441615689655172 ], [ 29.763264810126582, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16016, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.441615689655172 ], [ 29.763534303797467, -1.441885172413793 ], [ 29.763803797468356, -1.441885172413793 ], [ 29.763803797468356, -1.441615689655172 ], [ 29.763534303797467, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16017, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.441615689655172 ], [ 29.763803797468356, -1.441885172413793 ], [ 29.76407329113924, -1.441885172413793 ], [ 29.76407329113924, -1.441615689655172 ], [ 29.763803797468356, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16018, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.441615689655172 ], [ 29.76407329113924, -1.441885172413793 ], [ 29.764342784810125, -1.441885172413793 ], [ 29.764342784810125, -1.441615689655172 ], [ 29.76407329113924, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16019, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.441615689655172 ], [ 29.764342784810125, -1.441885172413793 ], [ 29.764612278481014, -1.441885172413793 ], [ 29.764612278481014, -1.441615689655172 ], [ 29.764342784810125, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16020, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.441615689655172 ], [ 29.764612278481014, -1.441885172413793 ], [ 29.764881772151899, -1.441885172413793 ], [ 29.764881772151899, -1.441615689655172 ], [ 29.764612278481014, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16021, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.441615689655172 ], [ 29.764881772151899, -1.441885172413793 ], [ 29.765151265822784, -1.441885172413793 ], [ 29.765151265822784, -1.441615689655172 ], [ 29.764881772151899, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16022, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.441615689655172 ], [ 29.765151265822784, -1.441885172413793 ], [ 29.765420759493672, -1.441885172413793 ], [ 29.765420759493672, -1.441615689655172 ], [ 29.765151265822784, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16023, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.441615689655172 ], [ 29.765420759493672, -1.441885172413793 ], [ 29.765959746835442, -1.441885172413793 ], [ 29.765959746835442, -1.441615689655172 ], [ 29.765420759493672, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16024, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.441615689655172 ], [ 29.765959746835442, -1.441885172413793 ], [ 29.766229240506327, -1.441885172413793 ], [ 29.766229240506327, -1.441615689655172 ], [ 29.765959746835442, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16025, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.441615689655172 ], [ 29.766229240506327, -1.441885172413793 ], [ 29.766498734177215, -1.441885172413793 ], [ 29.766498734177215, -1.441615689655172 ], [ 29.766229240506327, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16026, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.441615689655172 ], [ 29.766498734177215, -1.441885172413793 ], [ 29.7667682278481, -1.441885172413793 ], [ 29.7667682278481, -1.441615689655172 ], [ 29.766498734177215, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16027, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.441615689655172 ], [ 29.7667682278481, -1.441885172413793 ], [ 29.767037721518985, -1.441885172413793 ], [ 29.767037721518985, -1.441615689655172 ], [ 29.7667682278481, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16028, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.441615689655172 ], [ 29.767037721518985, -1.441885172413793 ], [ 29.767307215189874, -1.441885172413793 ], [ 29.767307215189874, -1.441615689655172 ], [ 29.767037721518985, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16029, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.441615689655172 ], [ 29.767307215189874, -1.441885172413793 ], [ 29.767576708860759, -1.441885172413793 ], [ 29.767576708860759, -1.441615689655172 ], [ 29.767307215189874, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16030, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.441615689655172 ], [ 29.767576708860759, -1.442154655172414 ], [ 29.768385189873417, -1.442154655172414 ], [ 29.768385189873417, -1.441885172413793 ], [ 29.767846202531643, -1.441885172413793 ], [ 29.767846202531643, -1.441615689655172 ], [ 29.767576708860759, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16031, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.441615689655172 ], [ 29.767846202531643, -1.441885172413793 ], [ 29.768115696202532, -1.441885172413793 ], [ 29.768115696202532, -1.441615689655172 ], [ 29.767846202531643, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16032, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.441615689655172 ], [ 29.768115696202532, -1.441885172413793 ], [ 29.768654683544302, -1.441885172413793 ], [ 29.768654683544302, -1.441615689655172 ], [ 29.768115696202532, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16033, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.441615689655172 ], [ 29.768654683544302, -1.441885172413793 ], [ 29.76892417721519, -1.441885172413793 ], [ 29.76892417721519, -1.441615689655172 ], [ 29.768654683544302, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16034, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.441615689655172 ], [ 29.76892417721519, -1.441885172413793 ], [ 29.769193670886075, -1.441885172413793 ], [ 29.769193670886075, -1.441615689655172 ], [ 29.76892417721519, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16035, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.441615689655172 ], [ 29.769193670886075, -1.441885172413793 ], [ 29.76946316455696, -1.441885172413793 ], [ 29.76946316455696, -1.441615689655172 ], [ 29.769193670886075, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16036, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.441615689655172 ], [ 29.76946316455696, -1.441885172413793 ], [ 29.769732658227849, -1.441885172413793 ], [ 29.769732658227849, -1.441615689655172 ], [ 29.76946316455696, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16037, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.441615689655172 ], [ 29.769732658227849, -1.441885172413793 ], [ 29.770002151898733, -1.441885172413793 ], [ 29.770002151898733, -1.441615689655172 ], [ 29.769732658227849, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16038, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.441615689655172 ], [ 29.770002151898733, -1.441885172413793 ], [ 29.770271645569618, -1.441885172413793 ], [ 29.770271645569618, -1.441615689655172 ], [ 29.770002151898733, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16039, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.441615689655172 ], [ 29.770271645569618, -1.441885172413793 ], [ 29.770541139240507, -1.441885172413793 ], [ 29.770541139240507, -1.441615689655172 ], [ 29.770271645569618, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16040, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.441615689655172 ], [ 29.770541139240507, -1.441885172413793 ], [ 29.770810632911392, -1.441885172413793 ], [ 29.770810632911392, -1.441615689655172 ], [ 29.770541139240507, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16041, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.441615689655172 ], [ 29.770810632911392, -1.441885172413793 ], [ 29.771080126582277, -1.441885172413793 ], [ 29.771080126582277, -1.441615689655172 ], [ 29.770810632911392, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16042, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.441615689655172 ], [ 29.771080126582277, -1.441885172413793 ], [ 29.771349620253165, -1.441885172413793 ], [ 29.771349620253165, -1.441615689655172 ], [ 29.771080126582277, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16043, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.441615689655172 ], [ 29.771349620253165, -1.441885172413793 ], [ 29.77161911392405, -1.441885172413793 ], [ 29.77161911392405, -1.441615689655172 ], [ 29.771349620253165, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16044, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.441615689655172 ], [ 29.77161911392405, -1.441885172413793 ], [ 29.771888607594935, -1.441885172413793 ], [ 29.771888607594935, -1.441615689655172 ], [ 29.77161911392405, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16045, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.441615689655172 ], [ 29.771888607594935, -1.441885172413793 ], [ 29.772158101265823, -1.441885172413793 ], [ 29.772158101265823, -1.441615689655172 ], [ 29.771888607594935, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16046, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.441615689655172 ], [ 29.772158101265823, -1.441885172413793 ], [ 29.772427594936708, -1.441885172413793 ], [ 29.772427594936708, -1.441615689655172 ], [ 29.772158101265823, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16047, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.441615689655172 ], [ 29.772427594936708, -1.441885172413793 ], [ 29.772697088607593, -1.441885172413793 ], [ 29.772697088607593, -1.441615689655172 ], [ 29.772427594936708, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16048, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.441615689655172 ], [ 29.772697088607593, -1.441885172413793 ], [ 29.772966582278482, -1.441885172413793 ], [ 29.772966582278482, -1.441615689655172 ], [ 29.772697088607593, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16049, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.441615689655172 ], [ 29.772966582278482, -1.441885172413793 ], [ 29.773236075949367, -1.441885172413793 ], [ 29.773236075949367, -1.441615689655172 ], [ 29.772966582278482, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16050, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.441615689655172 ], [ 29.773236075949367, -1.441885172413793 ], [ 29.773505569620252, -1.441885172413793 ], [ 29.773505569620252, -1.441615689655172 ], [ 29.773236075949367, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16051, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.441615689655172 ], [ 29.773505569620252, -1.441885172413793 ], [ 29.77377506329114, -1.441885172413793 ], [ 29.77377506329114, -1.441615689655172 ], [ 29.773505569620252, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16052, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.441615689655172 ], [ 29.77377506329114, -1.441885172413793 ], [ 29.774044556962025, -1.441885172413793 ], [ 29.774044556962025, -1.441615689655172 ], [ 29.77377506329114, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16053, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.441615689655172 ], [ 29.774044556962025, -1.441885172413793 ], [ 29.77431405063291, -1.441885172413793 ], [ 29.77431405063291, -1.441615689655172 ], [ 29.774044556962025, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16054, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.441615689655172 ], [ 29.77431405063291, -1.441885172413793 ], [ 29.774853037974683, -1.441885172413793 ], [ 29.774853037974683, -1.441615689655172 ], [ 29.77431405063291, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16055, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.441615689655172 ], [ 29.774853037974683, -1.441885172413793 ], [ 29.775122531645568, -1.441885172413793 ], [ 29.775122531645568, -1.441615689655172 ], [ 29.774853037974683, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16056, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.441615689655172 ], [ 29.775122531645568, -1.441885172413793 ], [ 29.775392025316457, -1.441885172413793 ], [ 29.775392025316457, -1.441615689655172 ], [ 29.775122531645568, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16057, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.441615689655172 ], [ 29.775392025316457, -1.441885172413793 ], [ 29.775661518987341, -1.441885172413793 ], [ 29.775661518987341, -1.441615689655172 ], [ 29.775392025316457, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16058, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.441615689655172 ], [ 29.775931012658226, -1.441885172413793 ], [ 29.776200506329115, -1.441885172413793 ], [ 29.776200506329115, -1.441615689655172 ], [ 29.775931012658226, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16059, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.441615689655172 ], [ 29.776200506329115, -1.441885172413793 ], [ 29.77647, -1.441885172413793 ], [ 29.77647, -1.441615689655172 ], [ 29.776200506329115, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16060, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.441615689655172 ], [ 29.77647, -1.442154655172414 ], [ 29.776739493670885, -1.442154655172414 ], [ 29.776739493670885, -1.441615689655172 ], [ 29.77647, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16061, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.441615689655172 ], [ 29.776739493670885, -1.441885172413793 ], [ 29.777008987341773, -1.441885172413793 ], [ 29.777008987341773, -1.441615689655172 ], [ 29.776739493670885, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16062, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.441615689655172 ], [ 29.777008987341773, -1.441885172413793 ], [ 29.777278481012658, -1.441885172413793 ], [ 29.777278481012658, -1.441615689655172 ], [ 29.777008987341773, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16063, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.441615689655172 ], [ 29.777278481012658, -1.441885172413793 ], [ 29.777547974683543, -1.441885172413793 ], [ 29.777547974683543, -1.441615689655172 ], [ 29.777278481012658, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16064, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.441615689655172 ], [ 29.777817468354431, -1.441885172413793 ], [ 29.778086962025316, -1.441885172413793 ], [ 29.778086962025316, -1.441615689655172 ], [ 29.777817468354431, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16065, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.441615689655172 ], [ 29.778086962025316, -1.441885172413793 ], [ 29.778356455696201, -1.441885172413793 ], [ 29.778356455696201, -1.441615689655172 ], [ 29.778086962025316, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16066, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.441615689655172 ], [ 29.778356455696201, -1.441885172413793 ], [ 29.77862594936709, -1.441885172413793 ], [ 29.77862594936709, -1.441615689655172 ], [ 29.778356455696201, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16067, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.441615689655172 ], [ 29.77862594936709, -1.441885172413793 ], [ 29.778895443037975, -1.441885172413793 ], [ 29.778895443037975, -1.441615689655172 ], [ 29.77862594936709, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16068, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.441615689655172 ], [ 29.778895443037975, -1.441885172413793 ], [ 29.77916493670886, -1.441885172413793 ], [ 29.77916493670886, -1.441615689655172 ], [ 29.778895443037975, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16069, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.441615689655172 ], [ 29.77916493670886, -1.441885172413793 ], [ 29.779434430379748, -1.441885172413793 ], [ 29.779434430379748, -1.441615689655172 ], [ 29.77916493670886, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16070, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.441615689655172 ], [ 29.779434430379748, -1.441885172413793 ], [ 29.779703924050633, -1.441885172413793 ], [ 29.779703924050633, -1.441615689655172 ], [ 29.779434430379748, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16071, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.441615689655172 ], [ 29.779703924050633, -1.441885172413793 ], [ 29.779973417721518, -1.441885172413793 ], [ 29.779973417721518, -1.441615689655172 ], [ 29.779703924050633, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16072, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.441615689655172 ], [ 29.779973417721518, -1.441885172413793 ], [ 29.780242911392406, -1.441885172413793 ], [ 29.780242911392406, -1.441615689655172 ], [ 29.779973417721518, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16073, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.441615689655172 ], [ 29.780242911392406, -1.441885172413793 ], [ 29.780512405063291, -1.441885172413793 ], [ 29.780512405063291, -1.441615689655172 ], [ 29.780242911392406, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16074, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.441615689655172 ], [ 29.780512405063291, -1.441885172413793 ], [ 29.780781898734176, -1.441885172413793 ], [ 29.780781898734176, -1.441615689655172 ], [ 29.780512405063291, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16075, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.441615689655172 ], [ 29.780781898734176, -1.441885172413793 ], [ 29.781051392405065, -1.441885172413793 ], [ 29.781051392405065, -1.441615689655172 ], [ 29.780781898734176, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16076, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.441615689655172 ], [ 29.781051392405065, -1.441885172413793 ], [ 29.78132088607595, -1.441885172413793 ], [ 29.78132088607595, -1.441615689655172 ], [ 29.781051392405065, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16077, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.441615689655172 ], [ 29.78132088607595, -1.441885172413793 ], [ 29.781590379746834, -1.441885172413793 ], [ 29.781590379746834, -1.441615689655172 ], [ 29.78132088607595, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16078, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.441615689655172 ], [ 29.781590379746834, -1.442424137931035 ], [ 29.781859873417723, -1.442424137931035 ], [ 29.781859873417723, -1.441615689655172 ], [ 29.781590379746834, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16079, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.441615689655172 ], [ 29.781859873417723, -1.441885172413793 ], [ 29.782129367088608, -1.441885172413793 ], [ 29.782129367088608, -1.441615689655172 ], [ 29.781859873417723, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16080, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.441615689655172 ], [ 29.782129367088608, -1.441885172413793 ], [ 29.782398860759493, -1.441885172413793 ], [ 29.782398860759493, -1.441615689655172 ], [ 29.782129367088608, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16081, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.441615689655172 ], [ 29.782398860759493, -1.441885172413793 ], [ 29.782668354430381, -1.441885172413793 ], [ 29.782668354430381, -1.441615689655172 ], [ 29.782398860759493, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16082, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.441615689655172 ], [ 29.782668354430381, -1.441885172413793 ], [ 29.782937848101266, -1.441885172413793 ], [ 29.782937848101266, -1.441615689655172 ], [ 29.782668354430381, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16083, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.441615689655172 ], [ 29.782937848101266, -1.441885172413793 ], [ 29.783207341772151, -1.441885172413793 ], [ 29.783207341772151, -1.441615689655172 ], [ 29.782937848101266, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16084, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.441615689655172 ], [ 29.783207341772151, -1.441885172413793 ], [ 29.783476835443039, -1.441885172413793 ], [ 29.783476835443039, -1.441615689655172 ], [ 29.783207341772151, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16085, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.441615689655172 ], [ 29.784015822784809, -1.441885172413793 ], [ 29.784285316455698, -1.441885172413793 ], [ 29.784285316455698, -1.441615689655172 ], [ 29.784015822784809, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16086, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.441615689655172 ], [ 29.784285316455698, -1.441885172413793 ], [ 29.784554810126583, -1.441885172413793 ], [ 29.784554810126583, -1.441615689655172 ], [ 29.784285316455698, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16087, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.441615689655172 ], [ 29.793987088607594, -1.442154655172414 ], [ 29.794256582278482, -1.442154655172414 ], [ 29.794256582278482, -1.441615689655172 ], [ 29.793987088607594, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16088, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.441615689655172 ], [ 29.794256582278482, -1.441885172413793 ], [ 29.794526075949367, -1.441885172413793 ], [ 29.794526075949367, -1.441615689655172 ], [ 29.794256582278482, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16089, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.441615689655172 ], [ 29.794526075949367, -1.441885172413793 ], [ 29.794795569620252, -1.441885172413793 ], [ 29.794795569620252, -1.441615689655172 ], [ 29.794526075949367, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16090, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.441615689655172 ], [ 29.794795569620252, -1.441885172413793 ], [ 29.79506506329114, -1.441885172413793 ], [ 29.79506506329114, -1.441615689655172 ], [ 29.794795569620252, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16091, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.441615689655172 ], [ 29.79506506329114, -1.441885172413793 ], [ 29.795334556962025, -1.441885172413793 ], [ 29.795334556962025, -1.441615689655172 ], [ 29.79506506329114, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16092, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.441615689655172 ], [ 29.795334556962025, -1.441885172413793 ], [ 29.79560405063291, -1.441885172413793 ], [ 29.79560405063291, -1.441615689655172 ], [ 29.795334556962025, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16093, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.441615689655172 ], [ 29.79560405063291, -1.441885172413793 ], [ 29.795873544303799, -1.441885172413793 ], [ 29.795873544303799, -1.441615689655172 ], [ 29.79560405063291, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16094, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.441615689655172 ], [ 29.796412531645569, -1.442154655172414 ], [ 29.796682025316457, -1.442154655172414 ], [ 29.796682025316457, -1.441615689655172 ], [ 29.796412531645569, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16095, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.441615689655172 ], [ 29.797221012658227, -1.442424137931035 ], [ 29.797490506329115, -1.442424137931035 ], [ 29.797490506329115, -1.441615689655172 ], [ 29.797221012658227, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16096, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.441615689655172 ], [ 29.797490506329115, -1.442424137931035 ], [ 29.79776, -1.442424137931035 ], [ 29.79776, -1.441615689655172 ], [ 29.797490506329115, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16097, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.441615689655172 ], [ 29.79776, -1.442693620689655 ], [ 29.798029493670885, -1.442693620689655 ], [ 29.798029493670885, -1.441615689655172 ], [ 29.79776, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16098, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.441615689655172 ], [ 29.799107468354432, -1.441885172413793 ], [ 29.799376962025317, -1.441885172413793 ], [ 29.799376962025317, -1.441615689655172 ], [ 29.799107468354432, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16099, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.441615689655172 ], [ 29.799376962025317, -1.441885172413793 ], [ 29.799646455696202, -1.441885172413793 ], [ 29.799646455696202, -1.441615689655172 ], [ 29.799376962025317, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16100, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.441615689655172 ], [ 29.799646455696202, -1.441885172413793 ], [ 29.79991594936709, -1.441885172413793 ], [ 29.79991594936709, -1.441615689655172 ], [ 29.799646455696202, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16101, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.441615689655172 ], [ 29.79991594936709, -1.441885172413793 ], [ 29.800185443037975, -1.441885172413793 ], [ 29.800185443037975, -1.441615689655172 ], [ 29.79991594936709, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16102, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.441615689655172 ], [ 29.800185443037975, -1.441885172413793 ], [ 29.80045493670886, -1.441885172413793 ], [ 29.80045493670886, -1.441615689655172 ], [ 29.800185443037975, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16103, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.441615689655172 ], [ 29.80045493670886, -1.441885172413793 ], [ 29.800724430379748, -1.441885172413793 ], [ 29.800724430379748, -1.441615689655172 ], [ 29.80045493670886, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16104, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.441615689655172 ], [ 29.800724430379748, -1.441885172413793 ], [ 29.800993924050633, -1.441885172413793 ], [ 29.800993924050633, -1.441615689655172 ], [ 29.800724430379748, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16105, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.441615689655172 ], [ 29.800993924050633, -1.441885172413793 ], [ 29.801263417721518, -1.441885172413793 ], [ 29.801263417721518, -1.441615689655172 ], [ 29.800993924050633, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16106, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.441615689655172 ], [ 29.801263417721518, -1.441885172413793 ], [ 29.801532911392407, -1.441885172413793 ], [ 29.801532911392407, -1.441615689655172 ], [ 29.801263417721518, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16107, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.441615689655172 ], [ 29.801532911392407, -1.441885172413793 ], [ 29.802071898734177, -1.441885172413793 ], [ 29.802071898734177, -1.441615689655172 ], [ 29.801532911392407, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16108, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.441615689655172 ], [ 29.802071898734177, -1.441885172413793 ], [ 29.802341392405065, -1.441885172413793 ], [ 29.802341392405065, -1.441615689655172 ], [ 29.802071898734177, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16109, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.441615689655172 ], [ 29.802341392405065, -1.441885172413793 ], [ 29.80261088607595, -1.441885172413793 ], [ 29.80261088607595, -1.441615689655172 ], [ 29.802341392405065, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16110, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.441615689655172 ], [ 29.80261088607595, -1.441885172413793 ], [ 29.802880379746835, -1.441885172413793 ], [ 29.802880379746835, -1.441615689655172 ], [ 29.80261088607595, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16111, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.441615689655172 ], [ 29.802880379746835, -1.441885172413793 ], [ 29.803149873417723, -1.441885172413793 ], [ 29.803149873417723, -1.442154655172414 ], [ 29.803419367088608, -1.442154655172414 ], [ 29.803419367088608, -1.441615689655172 ], [ 29.802880379746835, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16112, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.441615689655172 ], [ 29.803419367088608, -1.441885172413793 ], [ 29.803688860759493, -1.441885172413793 ], [ 29.803688860759493, -1.441615689655172 ], [ 29.803419367088608, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16113, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.441615689655172 ], [ 29.803688860759493, -1.441885172413793 ], [ 29.803958354430382, -1.441885172413793 ], [ 29.803958354430382, -1.441615689655172 ], [ 29.803688860759493, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16114, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.441615689655172 ], [ 29.803958354430382, -1.441885172413793 ], [ 29.804227848101267, -1.441885172413793 ], [ 29.804227848101267, -1.441615689655172 ], [ 29.803958354430382, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16115, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.441615689655172 ], [ 29.804227848101267, -1.441885172413793 ], [ 29.804497341772151, -1.441885172413793 ], [ 29.804497341772151, -1.441615689655172 ], [ 29.804227848101267, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16116, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.441615689655172 ], [ 29.804497341772151, -1.441885172413793 ], [ 29.80476683544304, -1.441885172413793 ], [ 29.80476683544304, -1.441615689655172 ], [ 29.804497341772151, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16117, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.441615689655172 ], [ 29.80476683544304, -1.441885172413793 ], [ 29.805036329113925, -1.441885172413793 ], [ 29.805036329113925, -1.441615689655172 ], [ 29.80476683544304, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16118, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.441615689655172 ], [ 29.805036329113925, -1.441885172413793 ], [ 29.80530582278481, -1.441885172413793 ], [ 29.80530582278481, -1.441615689655172 ], [ 29.805036329113925, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16119, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.441615689655172 ], [ 29.80530582278481, -1.441885172413793 ], [ 29.805575316455698, -1.441885172413793 ], [ 29.805575316455698, -1.441615689655172 ], [ 29.80530582278481, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.441615689655172 ], [ 29.805575316455698, -1.441885172413793 ], [ 29.805844810126583, -1.441885172413793 ], [ 29.805844810126583, -1.441615689655172 ], [ 29.805575316455698, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.441615689655172 ], [ 29.805844810126583, -1.441885172413793 ], [ 29.806114303797468, -1.441885172413793 ], [ 29.806114303797468, -1.441615689655172 ], [ 29.805844810126583, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16122, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.441615689655172 ], [ 29.806114303797468, -1.441885172413793 ], [ 29.806383797468357, -1.441885172413793 ], [ 29.806383797468357, -1.441615689655172 ], [ 29.806114303797468, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16123, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.441615689655172 ], [ 29.806383797468357, -1.441885172413793 ], [ 29.806653291139241, -1.441885172413793 ], [ 29.806653291139241, -1.441615689655172 ], [ 29.806383797468357, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16124, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.441615689655172 ], [ 29.806653291139241, -1.441885172413793 ], [ 29.806922784810126, -1.441885172413793 ], [ 29.806922784810126, -1.441615689655172 ], [ 29.806653291139241, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16125, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.441615689655172 ], [ 29.8074617721519, -1.441885172413793 ], [ 29.807731265822785, -1.441885172413793 ], [ 29.807731265822785, -1.441615689655172 ], [ 29.8074617721519, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16126, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.441615689655172 ], [ 29.807731265822785, -1.441885172413793 ], [ 29.808000759493673, -1.441885172413793 ], [ 29.808000759493673, -1.441615689655172 ], [ 29.807731265822785, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16127, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.441615689655172 ], [ 29.808000759493673, -1.441885172413793 ], [ 29.808270253164558, -1.441885172413793 ], [ 29.808270253164558, -1.441615689655172 ], [ 29.808000759493673, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16128, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.441615689655172 ], [ 29.808270253164558, -1.441885172413793 ], [ 29.809617721518986, -1.441885172413793 ], [ 29.809617721518986, -1.441615689655172 ], [ 29.808270253164558, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16129, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746286708860758, -1.441885172413793 ], [ 29.746286708860758, -1.442424137931035 ], [ 29.746556202531643, -1.442424137931035 ], [ 29.746556202531643, -1.441885172413793 ], [ 29.746286708860758, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16130, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.441885172413793 ], [ 29.746556202531643, -1.442154655172414 ], [ 29.746825696202531, -1.442154655172414 ], [ 29.746825696202531, -1.441885172413793 ], [ 29.746556202531643, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16131, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.441885172413793 ], [ 29.746825696202531, -1.442154655172414 ], [ 29.747095189873416, -1.442154655172414 ], [ 29.747095189873416, -1.441885172413793 ], [ 29.746825696202531, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16132, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.441885172413793 ], [ 29.747095189873416, -1.442154655172414 ], [ 29.747364683544301, -1.442154655172414 ], [ 29.747364683544301, -1.441885172413793 ], [ 29.747095189873416, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16133, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.441885172413793 ], [ 29.747364683544301, -1.442154655172414 ], [ 29.747903670886075, -1.442154655172414 ], [ 29.747903670886075, -1.441885172413793 ], [ 29.747364683544301, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16134, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.441885172413793 ], [ 29.747903670886075, -1.442154655172414 ], [ 29.74817316455696, -1.442154655172414 ], [ 29.74817316455696, -1.441885172413793 ], [ 29.747903670886075, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16135, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.441885172413793 ], [ 29.74817316455696, -1.442154655172414 ], [ 29.748442658227848, -1.442154655172414 ], [ 29.748442658227848, -1.441885172413793 ], [ 29.74817316455696, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16136, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.441885172413793 ], [ 29.748442658227848, -1.442154655172414 ], [ 29.748712151898733, -1.442154655172414 ], [ 29.748712151898733, -1.441885172413793 ], [ 29.748442658227848, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16137, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.441885172413793 ], [ 29.748712151898733, -1.442154655172414 ], [ 29.748981645569618, -1.442154655172414 ], [ 29.748981645569618, -1.441885172413793 ], [ 29.748712151898733, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16138, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.441885172413793 ], [ 29.748981645569618, -1.442154655172414 ], [ 29.749251139240506, -1.442154655172414 ], [ 29.749251139240506, -1.441885172413793 ], [ 29.748981645569618, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16139, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.441885172413793 ], [ 29.749251139240506, -1.442154655172414 ], [ 29.749520632911391, -1.442154655172414 ], [ 29.749520632911391, -1.441885172413793 ], [ 29.749251139240506, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16140, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.441885172413793 ], [ 29.749520632911391, -1.442424137931035 ], [ 29.749790126582276, -1.442424137931035 ], [ 29.749790126582276, -1.441885172413793 ], [ 29.749520632911391, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16141, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.441885172413793 ], [ 29.749790126582276, -1.442154655172414 ], [ 29.750059620253165, -1.442154655172414 ], [ 29.750059620253165, -1.441885172413793 ], [ 29.749790126582276, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16142, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.441885172413793 ], [ 29.750059620253165, -1.442154655172414 ], [ 29.75032911392405, -1.442154655172414 ], [ 29.75032911392405, -1.441885172413793 ], [ 29.750059620253165, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16143, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.441885172413793 ], [ 29.75032911392405, -1.442154655172414 ], [ 29.750598607594934, -1.442154655172414 ], [ 29.750598607594934, -1.441885172413793 ], [ 29.75032911392405, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16144, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.441885172413793 ], [ 29.750868101265823, -1.442154655172414 ], [ 29.751137594936708, -1.442154655172414 ], [ 29.751137594936708, -1.441885172413793 ], [ 29.750868101265823, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16145, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.441885172413793 ], [ 29.751137594936708, -1.442154655172414 ], [ 29.751407088607593, -1.442154655172414 ], [ 29.751407088607593, -1.441885172413793 ], [ 29.751137594936708, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16146, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.441885172413793 ], [ 29.751407088607593, -1.442154655172414 ], [ 29.751676582278481, -1.442154655172414 ], [ 29.751676582278481, -1.441885172413793 ], [ 29.751407088607593, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16147, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.441885172413793 ], [ 29.751676582278481, -1.442154655172414 ], [ 29.751946075949366, -1.442154655172414 ], [ 29.751946075949366, -1.441885172413793 ], [ 29.751676582278481, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16148, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.441885172413793 ], [ 29.751946075949366, -1.442424137931035 ], [ 29.752215569620251, -1.442424137931035 ], [ 29.752215569620251, -1.441885172413793 ], [ 29.751946075949366, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16149, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.441885172413793 ], [ 29.752215569620251, -1.442154655172414 ], [ 29.75248506329114, -1.442154655172414 ], [ 29.75248506329114, -1.441885172413793 ], [ 29.752215569620251, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16150, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.441885172413793 ], [ 29.75248506329114, -1.442154655172414 ], [ 29.752754556962024, -1.442154655172414 ], [ 29.752754556962024, -1.441885172413793 ], [ 29.75248506329114, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16151, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.441885172413793 ], [ 29.752754556962024, -1.442154655172414 ], [ 29.753024050632909, -1.442154655172414 ], [ 29.753024050632909, -1.441885172413793 ], [ 29.752754556962024, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16152, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.441885172413793 ], [ 29.753563037974683, -1.442154655172414 ], [ 29.753832531645568, -1.442154655172414 ], [ 29.753832531645568, -1.441885172413793 ], [ 29.753563037974683, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16153, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.441885172413793 ], [ 29.753832531645568, -1.442154655172414 ], [ 29.754102025316456, -1.442154655172414 ], [ 29.754102025316456, -1.441885172413793 ], [ 29.753832531645568, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16154, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.441885172413793 ], [ 29.754102025316456, -1.442154655172414 ], [ 29.754371518987341, -1.442154655172414 ], [ 29.754371518987341, -1.441885172413793 ], [ 29.754102025316456, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16155, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.441885172413793 ], [ 29.754371518987341, -1.442154655172414 ], [ 29.754641012658226, -1.442154655172414 ], [ 29.754641012658226, -1.441885172413793 ], [ 29.754371518987341, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16156, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.441885172413793 ], [ 29.754641012658226, -1.442154655172414 ], [ 29.754910506329114, -1.442154655172414 ], [ 29.754910506329114, -1.441885172413793 ], [ 29.754641012658226, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16157, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.441885172413793 ], [ 29.754910506329114, -1.442154655172414 ], [ 29.75518, -1.442154655172414 ], [ 29.75518, -1.441885172413793 ], [ 29.754910506329114, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16158, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.441885172413793 ], [ 29.75518, -1.442154655172414 ], [ 29.755449493670884, -1.442154655172414 ], [ 29.755449493670884, -1.441885172413793 ], [ 29.75518, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16159, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.441885172413793 ], [ 29.755449493670884, -1.442154655172414 ], [ 29.755718987341773, -1.442154655172414 ], [ 29.755718987341773, -1.441885172413793 ], [ 29.755449493670884, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16160, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.441885172413793 ], [ 29.755718987341773, -1.442154655172414 ], [ 29.755988481012658, -1.442154655172414 ], [ 29.755988481012658, -1.441885172413793 ], [ 29.755718987341773, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16161, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.441885172413793 ], [ 29.755988481012658, -1.442154655172414 ], [ 29.756257974683542, -1.442154655172414 ], [ 29.756257974683542, -1.441885172413793 ], [ 29.755988481012658, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16162, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.441885172413793 ], [ 29.756257974683542, -1.442154655172414 ], [ 29.756796962025316, -1.442154655172414 ], [ 29.756796962025316, -1.441885172413793 ], [ 29.756257974683542, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16163, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.441885172413793 ], [ 29.756796962025316, -1.442154655172414 ], [ 29.757066455696201, -1.442154655172414 ], [ 29.757066455696201, -1.441885172413793 ], [ 29.756796962025316, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16164, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.441885172413793 ], [ 29.757066455696201, -1.442154655172414 ], [ 29.757335949367089, -1.442154655172414 ], [ 29.757335949367089, -1.441885172413793 ], [ 29.757066455696201, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16165, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.441885172413793 ], [ 29.757335949367089, -1.442154655172414 ], [ 29.757605443037974, -1.442154655172414 ], [ 29.757605443037974, -1.441885172413793 ], [ 29.757335949367089, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16166, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.441885172413793 ], [ 29.757605443037974, -1.442154655172414 ], [ 29.757874936708859, -1.442154655172414 ], [ 29.757874936708859, -1.441885172413793 ], [ 29.757605443037974, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16167, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.441885172413793 ], [ 29.757874936708859, -1.442154655172414 ], [ 29.758144430379748, -1.442154655172414 ], [ 29.758144430379748, -1.441885172413793 ], [ 29.757874936708859, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16168, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.441885172413793 ], [ 29.758144430379748, -1.442154655172414 ], [ 29.758413924050632, -1.442154655172414 ], [ 29.758413924050632, -1.441885172413793 ], [ 29.758144430379748, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16169, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.441885172413793 ], [ 29.758413924050632, -1.442154655172414 ], [ 29.758683417721517, -1.442154655172414 ], [ 29.758683417721517, -1.441885172413793 ], [ 29.758413924050632, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16170, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.441885172413793 ], [ 29.758683417721517, -1.442154655172414 ], [ 29.762186835443039, -1.442154655172414 ], [ 29.762186835443039, -1.441885172413793 ], [ 29.758683417721517, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16171, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.441885172413793 ], [ 29.762186835443039, -1.442154655172414 ], [ 29.762456329113924, -1.442154655172414 ], [ 29.762456329113924, -1.441885172413793 ], [ 29.762186835443039, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16172, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.441885172413793 ], [ 29.762456329113924, -1.442154655172414 ], [ 29.762725822784809, -1.442154655172414 ], [ 29.762725822784809, -1.441885172413793 ], [ 29.762456329113924, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16173, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.441885172413793 ], [ 29.762725822784809, -1.442154655172414 ], [ 29.762995316455697, -1.442154655172414 ], [ 29.762995316455697, -1.441885172413793 ], [ 29.762725822784809, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16174, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.441885172413793 ], [ 29.762995316455697, -1.442154655172414 ], [ 29.763264810126582, -1.442154655172414 ], [ 29.763264810126582, -1.441885172413793 ], [ 29.762995316455697, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16175, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.441885172413793 ], [ 29.763264810126582, -1.442154655172414 ], [ 29.763534303797467, -1.442154655172414 ], [ 29.763534303797467, -1.441885172413793 ], [ 29.763264810126582, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16176, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.441885172413793 ], [ 29.763534303797467, -1.442154655172414 ], [ 29.763803797468356, -1.442154655172414 ], [ 29.763803797468356, -1.441885172413793 ], [ 29.763534303797467, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16177, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.441885172413793 ], [ 29.763803797468356, -1.442154655172414 ], [ 29.76407329113924, -1.442154655172414 ], [ 29.76407329113924, -1.441885172413793 ], [ 29.763803797468356, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16178, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.441885172413793 ], [ 29.76407329113924, -1.442154655172414 ], [ 29.764342784810125, -1.442154655172414 ], [ 29.764342784810125, -1.441885172413793 ], [ 29.76407329113924, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16179, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.441885172413793 ], [ 29.764342784810125, -1.442154655172414 ], [ 29.764612278481014, -1.442154655172414 ], [ 29.764612278481014, -1.441885172413793 ], [ 29.764342784810125, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16180, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.441885172413793 ], [ 29.764612278481014, -1.442154655172414 ], [ 29.764881772151899, -1.442154655172414 ], [ 29.764881772151899, -1.441885172413793 ], [ 29.764612278481014, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16181, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.441885172413793 ], [ 29.764881772151899, -1.442154655172414 ], [ 29.765151265822784, -1.442154655172414 ], [ 29.765151265822784, -1.441885172413793 ], [ 29.764881772151899, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16182, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.441885172413793 ], [ 29.765151265822784, -1.442154655172414 ], [ 29.765420759493672, -1.442154655172414 ], [ 29.765420759493672, -1.441885172413793 ], [ 29.765151265822784, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16183, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.441885172413793 ], [ 29.765420759493672, -1.442154655172414 ], [ 29.765959746835442, -1.442154655172414 ], [ 29.765959746835442, -1.441885172413793 ], [ 29.765420759493672, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16184, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.441885172413793 ], [ 29.765959746835442, -1.442154655172414 ], [ 29.766229240506327, -1.442154655172414 ], [ 29.766229240506327, -1.441885172413793 ], [ 29.765959746835442, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16185, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.441885172413793 ], [ 29.766229240506327, -1.442154655172414 ], [ 29.766498734177215, -1.442154655172414 ], [ 29.766498734177215, -1.441885172413793 ], [ 29.766229240506327, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16186, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.441885172413793 ], [ 29.766498734177215, -1.442154655172414 ], [ 29.7667682278481, -1.442154655172414 ], [ 29.7667682278481, -1.441885172413793 ], [ 29.766498734177215, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16187, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.441885172413793 ], [ 29.7667682278481, -1.442154655172414 ], [ 29.767037721518985, -1.442154655172414 ], [ 29.767037721518985, -1.441885172413793 ], [ 29.7667682278481, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16188, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.441885172413793 ], [ 29.767037721518985, -1.442154655172414 ], [ 29.767307215189874, -1.442154655172414 ], [ 29.767307215189874, -1.441885172413793 ], [ 29.767037721518985, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16189, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.441885172413793 ], [ 29.767307215189874, -1.442154655172414 ], [ 29.767576708860759, -1.442154655172414 ], [ 29.767576708860759, -1.441885172413793 ], [ 29.767307215189874, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16190, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.441885172413793 ], [ 29.768385189873417, -1.442154655172414 ], [ 29.768654683544302, -1.442154655172414 ], [ 29.768654683544302, -1.441885172413793 ], [ 29.768385189873417, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16191, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.441885172413793 ], [ 29.768654683544302, -1.442154655172414 ], [ 29.76892417721519, -1.442154655172414 ], [ 29.76892417721519, -1.441885172413793 ], [ 29.768654683544302, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16192, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.441885172413793 ], [ 29.76892417721519, -1.442154655172414 ], [ 29.76946316455696, -1.442154655172414 ], [ 29.76946316455696, -1.441885172413793 ], [ 29.76892417721519, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16193, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.441885172413793 ], [ 29.76946316455696, -1.442154655172414 ], [ 29.769732658227849, -1.442154655172414 ], [ 29.769732658227849, -1.441885172413793 ], [ 29.76946316455696, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16194, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.441885172413793 ], [ 29.769732658227849, -1.442154655172414 ], [ 29.770002151898733, -1.442154655172414 ], [ 29.770002151898733, -1.441885172413793 ], [ 29.769732658227849, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16195, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.441885172413793 ], [ 29.770002151898733, -1.442154655172414 ], [ 29.770271645569618, -1.442154655172414 ], [ 29.770271645569618, -1.441885172413793 ], [ 29.770002151898733, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16196, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.441885172413793 ], [ 29.770271645569618, -1.442154655172414 ], [ 29.770541139240507, -1.442154655172414 ], [ 29.770541139240507, -1.441885172413793 ], [ 29.770271645569618, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16197, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.441885172413793 ], [ 29.770541139240507, -1.442154655172414 ], [ 29.770810632911392, -1.442154655172414 ], [ 29.770810632911392, -1.441885172413793 ], [ 29.770541139240507, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16198, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.441885172413793 ], [ 29.770810632911392, -1.442154655172414 ], [ 29.771080126582277, -1.442154655172414 ], [ 29.771080126582277, -1.441885172413793 ], [ 29.770810632911392, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16199, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.441885172413793 ], [ 29.771080126582277, -1.442154655172414 ], [ 29.771349620253165, -1.442154655172414 ], [ 29.771349620253165, -1.441885172413793 ], [ 29.771080126582277, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16200, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.441885172413793 ], [ 29.771349620253165, -1.442154655172414 ], [ 29.77161911392405, -1.442154655172414 ], [ 29.77161911392405, -1.441885172413793 ], [ 29.771349620253165, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16201, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.441885172413793 ], [ 29.77161911392405, -1.442154655172414 ], [ 29.771888607594935, -1.442154655172414 ], [ 29.771888607594935, -1.441885172413793 ], [ 29.77161911392405, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16202, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.441885172413793 ], [ 29.771888607594935, -1.442154655172414 ], [ 29.772158101265823, -1.442154655172414 ], [ 29.772158101265823, -1.441885172413793 ], [ 29.771888607594935, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16203, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.441885172413793 ], [ 29.772158101265823, -1.442154655172414 ], [ 29.772427594936708, -1.442154655172414 ], [ 29.772427594936708, -1.441885172413793 ], [ 29.772158101265823, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16204, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.441885172413793 ], [ 29.772427594936708, -1.442154655172414 ], [ 29.772697088607593, -1.442154655172414 ], [ 29.772697088607593, -1.441885172413793 ], [ 29.772427594936708, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16205, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.441885172413793 ], [ 29.772697088607593, -1.442154655172414 ], [ 29.772966582278482, -1.442154655172414 ], [ 29.772966582278482, -1.441885172413793 ], [ 29.772697088607593, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16206, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.441885172413793 ], [ 29.772966582278482, -1.442154655172414 ], [ 29.773236075949367, -1.442154655172414 ], [ 29.773236075949367, -1.441885172413793 ], [ 29.772966582278482, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16207, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.441885172413793 ], [ 29.773236075949367, -1.442154655172414 ], [ 29.773505569620252, -1.442154655172414 ], [ 29.773505569620252, -1.441885172413793 ], [ 29.773236075949367, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16208, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.441885172413793 ], [ 29.773505569620252, -1.442154655172414 ], [ 29.77377506329114, -1.442154655172414 ], [ 29.77377506329114, -1.441885172413793 ], [ 29.773505569620252, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16209, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.441885172413793 ], [ 29.77377506329114, -1.442154655172414 ], [ 29.774044556962025, -1.442154655172414 ], [ 29.774044556962025, -1.441885172413793 ], [ 29.77377506329114, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16210, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.441885172413793 ], [ 29.774044556962025, -1.442154655172414 ], [ 29.77431405063291, -1.442154655172414 ], [ 29.77431405063291, -1.441885172413793 ], [ 29.774044556962025, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16211, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.441885172413793 ], [ 29.77431405063291, -1.442154655172414 ], [ 29.774853037974683, -1.442154655172414 ], [ 29.774853037974683, -1.441885172413793 ], [ 29.77431405063291, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16212, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.441885172413793 ], [ 29.774853037974683, -1.442154655172414 ], [ 29.775122531645568, -1.442154655172414 ], [ 29.775122531645568, -1.441885172413793 ], [ 29.774853037974683, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16213, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.441885172413793 ], [ 29.775122531645568, -1.442154655172414 ], [ 29.775392025316457, -1.442154655172414 ], [ 29.775392025316457, -1.441885172413793 ], [ 29.775122531645568, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16214, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.441885172413793 ], [ 29.775392025316457, -1.442154655172414 ], [ 29.775661518987341, -1.442154655172414 ], [ 29.775661518987341, -1.441885172413793 ], [ 29.775392025316457, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16215, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.441885172413793 ], [ 29.775661518987341, -1.442154655172414 ], [ 29.775931012658226, -1.442154655172414 ], [ 29.775931012658226, -1.441885172413793 ], [ 29.775661518987341, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16216, "El": 127 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.441885172413793 ], [ 29.775931012658226, -1.442424137931035 ], [ 29.776200506329115, -1.442424137931035 ], [ 29.776200506329115, -1.441885172413793 ], [ 29.775931012658226, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16217, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.441885172413793 ], [ 29.776200506329115, -1.442154655172414 ], [ 29.77647, -1.442154655172414 ], [ 29.77647, -1.441885172413793 ], [ 29.776200506329115, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16218, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.441885172413793 ], [ 29.776739493670885, -1.442154655172414 ], [ 29.777008987341773, -1.442154655172414 ], [ 29.777008987341773, -1.441885172413793 ], [ 29.776739493670885, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16219, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.441885172413793 ], [ 29.777008987341773, -1.442154655172414 ], [ 29.777278481012658, -1.442154655172414 ], [ 29.777278481012658, -1.441885172413793 ], [ 29.777008987341773, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16220, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.441885172413793 ], [ 29.777278481012658, -1.442154655172414 ], [ 29.777547974683543, -1.442154655172414 ], [ 29.777547974683543, -1.441885172413793 ], [ 29.777278481012658, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16221, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.441885172413793 ], [ 29.777547974683543, -1.442154655172414 ], [ 29.777817468354431, -1.442154655172414 ], [ 29.777817468354431, -1.441885172413793 ], [ 29.777547974683543, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16222, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.441885172413793 ], [ 29.777817468354431, -1.442154655172414 ], [ 29.778086962025316, -1.442154655172414 ], [ 29.778086962025316, -1.441885172413793 ], [ 29.777817468354431, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16223, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.441885172413793 ], [ 29.778086962025316, -1.442154655172414 ], [ 29.778356455696201, -1.442154655172414 ], [ 29.778356455696201, -1.441885172413793 ], [ 29.778086962025316, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16224, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.441885172413793 ], [ 29.778356455696201, -1.442154655172414 ], [ 29.77862594936709, -1.442154655172414 ], [ 29.77862594936709, -1.441885172413793 ], [ 29.778356455696201, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16225, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.441885172413793 ], [ 29.77862594936709, -1.442154655172414 ], [ 29.778895443037975, -1.442154655172414 ], [ 29.778895443037975, -1.441885172413793 ], [ 29.77862594936709, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16226, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.441885172413793 ], [ 29.77916493670886, -1.442154655172414 ], [ 29.779434430379748, -1.442154655172414 ], [ 29.779434430379748, -1.441885172413793 ], [ 29.77916493670886, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16227, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.441885172413793 ], [ 29.779434430379748, -1.442154655172414 ], [ 29.779703924050633, -1.442154655172414 ], [ 29.779703924050633, -1.441885172413793 ], [ 29.779434430379748, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16228, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.441885172413793 ], [ 29.779973417721518, -1.442424137931035 ], [ 29.780242911392406, -1.442424137931035 ], [ 29.780242911392406, -1.441885172413793 ], [ 29.779973417721518, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16229, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.441885172413793 ], [ 29.780242911392406, -1.442154655172414 ], [ 29.780512405063291, -1.442154655172414 ], [ 29.780512405063291, -1.441885172413793 ], [ 29.780242911392406, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16230, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.441885172413793 ], [ 29.780512405063291, -1.442154655172414 ], [ 29.780781898734176, -1.442154655172414 ], [ 29.780781898734176, -1.441885172413793 ], [ 29.780512405063291, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16231, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.441885172413793 ], [ 29.780781898734176, -1.442424137931035 ], [ 29.781051392405065, -1.442424137931035 ], [ 29.781051392405065, -1.441885172413793 ], [ 29.780781898734176, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16232, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.441885172413793 ], [ 29.781051392405065, -1.442154655172414 ], [ 29.78132088607595, -1.442154655172414 ], [ 29.78132088607595, -1.441885172413793 ], [ 29.781051392405065, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16233, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.441885172413793 ], [ 29.78132088607595, -1.442154655172414 ], [ 29.781590379746834, -1.442154655172414 ], [ 29.781590379746834, -1.441885172413793 ], [ 29.78132088607595, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16234, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.441885172413793 ], [ 29.781859873417723, -1.442154655172414 ], [ 29.782129367088608, -1.442154655172414 ], [ 29.782129367088608, -1.441885172413793 ], [ 29.781859873417723, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16235, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.441885172413793 ], [ 29.782129367088608, -1.442154655172414 ], [ 29.782398860759493, -1.442154655172414 ], [ 29.782398860759493, -1.441885172413793 ], [ 29.782129367088608, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16236, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.441885172413793 ], [ 29.782398860759493, -1.442424137931035 ], [ 29.782668354430381, -1.442424137931035 ], [ 29.782668354430381, -1.441885172413793 ], [ 29.782398860759493, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16237, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.441885172413793 ], [ 29.782668354430381, -1.442154655172414 ], [ 29.782937848101266, -1.442154655172414 ], [ 29.782937848101266, -1.441885172413793 ], [ 29.782668354430381, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16238, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.441885172413793 ], [ 29.782937848101266, -1.442154655172414 ], [ 29.783207341772151, -1.442154655172414 ], [ 29.783207341772151, -1.441885172413793 ], [ 29.782937848101266, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16239, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.441885172413793 ], [ 29.783207341772151, -1.442154655172414 ], [ 29.783476835443039, -1.442154655172414 ], [ 29.783476835443039, -1.441885172413793 ], [ 29.783207341772151, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16240, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.441885172413793 ], [ 29.784015822784809, -1.442154655172414 ], [ 29.784285316455698, -1.442154655172414 ], [ 29.784285316455698, -1.441885172413793 ], [ 29.784015822784809, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16241, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.441885172413793 ], [ 29.784285316455698, -1.442154655172414 ], [ 29.784554810126583, -1.442154655172414 ], [ 29.784554810126583, -1.441885172413793 ], [ 29.784285316455698, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16242, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.441885172413793 ], [ 29.785093797468356, -1.442154655172414 ], [ 29.785363291139241, -1.442154655172414 ], [ 29.785363291139241, -1.441885172413793 ], [ 29.785093797468356, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16243, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.441885172413793 ], [ 29.785902278481014, -1.442154655172414 ], [ 29.786171772151899, -1.442154655172414 ], [ 29.786171772151899, -1.441885172413793 ], [ 29.785902278481014, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16244, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.441885172413793 ], [ 29.786171772151899, -1.442154655172414 ], [ 29.786441265822784, -1.442154655172414 ], [ 29.786441265822784, -1.441885172413793 ], [ 29.786171772151899, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16245, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.441885172413793 ], [ 29.786441265822784, -1.442154655172414 ], [ 29.786710759493673, -1.442154655172414 ], [ 29.786710759493673, -1.441885172413793 ], [ 29.786441265822784, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16246, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.441885172413793 ], [ 29.786710759493673, -1.442693620689655 ], [ 29.786980253164558, -1.442693620689655 ], [ 29.786980253164558, -1.441885172413793 ], [ 29.786710759493673, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16247, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.441885172413793 ], [ 29.794256582278482, -1.442424137931035 ], [ 29.794526075949367, -1.442424137931035 ], [ 29.794526075949367, -1.441885172413793 ], [ 29.794256582278482, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16248, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.441885172413793 ], [ 29.794526075949367, -1.442154655172414 ], [ 29.794795569620252, -1.442154655172414 ], [ 29.794795569620252, -1.441885172413793 ], [ 29.794526075949367, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16249, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.441885172413793 ], [ 29.794795569620252, -1.442154655172414 ], [ 29.79506506329114, -1.442154655172414 ], [ 29.79506506329114, -1.441885172413793 ], [ 29.794795569620252, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16250, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.441885172413793 ], [ 29.79506506329114, -1.442424137931035 ], [ 29.795334556962025, -1.442424137931035 ], [ 29.795334556962025, -1.441885172413793 ], [ 29.79506506329114, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16251, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.441885172413793 ], [ 29.795334556962025, -1.442154655172414 ], [ 29.79560405063291, -1.442154655172414 ], [ 29.79560405063291, -1.441885172413793 ], [ 29.795334556962025, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16252, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.441885172413793 ], [ 29.79560405063291, -1.442154655172414 ], [ 29.795873544303799, -1.442154655172414 ], [ 29.795873544303799, -1.441885172413793 ], [ 29.79560405063291, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16253, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.441885172413793 ], [ 29.795873544303799, -1.442154655172414 ], [ 29.796143037974684, -1.442154655172414 ], [ 29.796143037974684, -1.441885172413793 ], [ 29.795873544303799, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16254, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.441885172413793 ], [ 29.796143037974684, -1.442154655172414 ], [ 29.796412531645569, -1.442154655172414 ], [ 29.796412531645569, -1.441885172413793 ], [ 29.796143037974684, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16255, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.441885172413793 ], [ 29.799376962025317, -1.442154655172414 ], [ 29.799646455696202, -1.442154655172414 ], [ 29.799646455696202, -1.441885172413793 ], [ 29.799376962025317, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16256, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.441885172413793 ], [ 29.799646455696202, -1.442154655172414 ], [ 29.79991594936709, -1.442154655172414 ], [ 29.79991594936709, -1.441885172413793 ], [ 29.799646455696202, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16257, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.441885172413793 ], [ 29.79991594936709, -1.442154655172414 ], [ 29.800185443037975, -1.442154655172414 ], [ 29.800185443037975, -1.441885172413793 ], [ 29.79991594936709, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16258, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.441885172413793 ], [ 29.800185443037975, -1.442154655172414 ], [ 29.80045493670886, -1.442154655172414 ], [ 29.80045493670886, -1.441885172413793 ], [ 29.800185443037975, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16259, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.441885172413793 ], [ 29.80045493670886, -1.442154655172414 ], [ 29.800724430379748, -1.442154655172414 ], [ 29.800724430379748, -1.441885172413793 ], [ 29.80045493670886, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16260, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.441885172413793 ], [ 29.800724430379748, -1.442154655172414 ], [ 29.800993924050633, -1.442154655172414 ], [ 29.800993924050633, -1.441885172413793 ], [ 29.800724430379748, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16261, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.441885172413793 ], [ 29.800993924050633, -1.442154655172414 ], [ 29.801263417721518, -1.442154655172414 ], [ 29.801263417721518, -1.441885172413793 ], [ 29.800993924050633, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16262, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.441885172413793 ], [ 29.801263417721518, -1.442154655172414 ], [ 29.801532911392407, -1.442154655172414 ], [ 29.801532911392407, -1.441885172413793 ], [ 29.801263417721518, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16263, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.441885172413793 ], [ 29.801532911392407, -1.442154655172414 ], [ 29.802071898734177, -1.442154655172414 ], [ 29.802071898734177, -1.441885172413793 ], [ 29.801532911392407, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16264, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.441885172413793 ], [ 29.802071898734177, -1.442154655172414 ], [ 29.802341392405065, -1.442154655172414 ], [ 29.802341392405065, -1.441885172413793 ], [ 29.802071898734177, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16265, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.441885172413793 ], [ 29.802341392405065, -1.442154655172414 ], [ 29.80261088607595, -1.442154655172414 ], [ 29.80261088607595, -1.441885172413793 ], [ 29.802341392405065, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16266, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.441885172413793 ], [ 29.80261088607595, -1.442154655172414 ], [ 29.802880379746835, -1.442154655172414 ], [ 29.802880379746835, -1.441885172413793 ], [ 29.80261088607595, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16267, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.441885172413793 ], [ 29.802880379746835, -1.442154655172414 ], [ 29.803149873417723, -1.442154655172414 ], [ 29.803149873417723, -1.441885172413793 ], [ 29.802880379746835, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16268, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.441885172413793 ], [ 29.803419367088608, -1.443232586206897 ], [ 29.803688860759493, -1.443232586206897 ], [ 29.803688860759493, -1.441885172413793 ], [ 29.803419367088608, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16269, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.441885172413793 ], [ 29.803688860759493, -1.442154655172414 ], [ 29.803958354430382, -1.442154655172414 ], [ 29.803958354430382, -1.441885172413793 ], [ 29.803688860759493, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16270, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.441885172413793 ], [ 29.803958354430382, -1.442154655172414 ], [ 29.804227848101267, -1.442154655172414 ], [ 29.804227848101267, -1.441885172413793 ], [ 29.803958354430382, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16271, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.441885172413793 ], [ 29.804227848101267, -1.442424137931035 ], [ 29.804497341772151, -1.442424137931035 ], [ 29.804497341772151, -1.441885172413793 ], [ 29.804227848101267, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16272, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.441885172413793 ], [ 29.804497341772151, -1.442154655172414 ], [ 29.80476683544304, -1.442154655172414 ], [ 29.80476683544304, -1.441885172413793 ], [ 29.804497341772151, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16273, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.441885172413793 ], [ 29.80476683544304, -1.442154655172414 ], [ 29.805036329113925, -1.442154655172414 ], [ 29.805036329113925, -1.441885172413793 ], [ 29.80476683544304, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16274, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.441885172413793 ], [ 29.805036329113925, -1.442154655172414 ], [ 29.80530582278481, -1.442154655172414 ], [ 29.80530582278481, -1.441885172413793 ], [ 29.805036329113925, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16275, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.441885172413793 ], [ 29.80530582278481, -1.442154655172414 ], [ 29.805575316455698, -1.442154655172414 ], [ 29.805575316455698, -1.441885172413793 ], [ 29.80530582278481, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16276, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.441885172413793 ], [ 29.805575316455698, -1.442154655172414 ], [ 29.805844810126583, -1.442154655172414 ], [ 29.805844810126583, -1.441885172413793 ], [ 29.805575316455698, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16277, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.441885172413793 ], [ 29.805844810126583, -1.442154655172414 ], [ 29.806114303797468, -1.442154655172414 ], [ 29.806114303797468, -1.441885172413793 ], [ 29.805844810126583, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16278, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.441885172413793 ], [ 29.806114303797468, -1.442154655172414 ], [ 29.806653291139241, -1.442154655172414 ], [ 29.806653291139241, -1.441885172413793 ], [ 29.806114303797468, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16279, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.441885172413793 ], [ 29.807731265822785, -1.442154655172414 ], [ 29.808000759493673, -1.442154655172414 ], [ 29.808000759493673, -1.441885172413793 ], [ 29.807731265822785, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16280, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.441885172413793 ], [ 29.808000759493673, -1.442154655172414 ], [ 29.808270253164558, -1.442154655172414 ], [ 29.808270253164558, -1.441885172413793 ], [ 29.808000759493673, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16281, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.441615689655172 ], [ 29.809887215189875, -1.441615689655172 ], [ 29.809887215189875, -1.442154655172414 ], [ 29.808270253164558, -1.442154655172414 ], [ 29.808270253164558, -1.441885172413793 ], [ 29.809617721518986, -1.441885172413793 ], [ 29.809617721518986, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16282, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.442154655172414 ], [ 29.746556202531643, -1.442424137931035 ], [ 29.746825696202531, -1.442424137931035 ], [ 29.746825696202531, -1.442154655172414 ], [ 29.746556202531643, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16283, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.442154655172414 ], [ 29.746825696202531, -1.442424137931035 ], [ 29.747095189873416, -1.442424137931035 ], [ 29.747095189873416, -1.442154655172414 ], [ 29.746825696202531, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16284, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.442154655172414 ], [ 29.747095189873416, -1.442424137931035 ], [ 29.747364683544301, -1.442424137931035 ], [ 29.747364683544301, -1.442154655172414 ], [ 29.747095189873416, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16285, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.442154655172414 ], [ 29.747364683544301, -1.442424137931035 ], [ 29.747903670886075, -1.442424137931035 ], [ 29.747903670886075, -1.442154655172414 ], [ 29.747364683544301, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16286, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.442154655172414 ], [ 29.747903670886075, -1.442424137931035 ], [ 29.74817316455696, -1.442424137931035 ], [ 29.74817316455696, -1.442154655172414 ], [ 29.747903670886075, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16287, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.442154655172414 ], [ 29.74817316455696, -1.442424137931035 ], [ 29.748442658227848, -1.442424137931035 ], [ 29.748442658227848, -1.442154655172414 ], [ 29.74817316455696, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16288, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.442154655172414 ], [ 29.748442658227848, -1.442693620689655 ], [ 29.748712151898733, -1.442693620689655 ], [ 29.748712151898733, -1.442154655172414 ], [ 29.748442658227848, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16289, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.442154655172414 ], [ 29.748712151898733, -1.442424137931035 ], [ 29.748981645569618, -1.442424137931035 ], [ 29.748981645569618, -1.442154655172414 ], [ 29.748712151898733, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16290, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.442154655172414 ], [ 29.748981645569618, -1.442424137931035 ], [ 29.749251139240506, -1.442424137931035 ], [ 29.749251139240506, -1.442154655172414 ], [ 29.748981645569618, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16291, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.442154655172414 ], [ 29.749251139240506, -1.442424137931035 ], [ 29.749520632911391, -1.442424137931035 ], [ 29.749520632911391, -1.442154655172414 ], [ 29.749251139240506, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16292, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.442154655172414 ], [ 29.749790126582276, -1.442424137931035 ], [ 29.750059620253165, -1.442424137931035 ], [ 29.750059620253165, -1.442154655172414 ], [ 29.749790126582276, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16293, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.442154655172414 ], [ 29.750059620253165, -1.442424137931035 ], [ 29.75032911392405, -1.442424137931035 ], [ 29.75032911392405, -1.442154655172414 ], [ 29.750059620253165, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16294, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.442154655172414 ], [ 29.75032911392405, -1.442424137931035 ], [ 29.750598607594934, -1.442424137931035 ], [ 29.750598607594934, -1.442154655172414 ], [ 29.75032911392405, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16295, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.442154655172414 ], [ 29.750598607594934, -1.442424137931035 ], [ 29.750868101265823, -1.442424137931035 ], [ 29.750868101265823, -1.442154655172414 ], [ 29.750598607594934, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16296, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.442154655172414 ], [ 29.750868101265823, -1.442424137931035 ], [ 29.751137594936708, -1.442424137931035 ], [ 29.751137594936708, -1.442154655172414 ], [ 29.750868101265823, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16297, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.442154655172414 ], [ 29.751137594936708, -1.442424137931035 ], [ 29.751407088607593, -1.442424137931035 ], [ 29.751407088607593, -1.442154655172414 ], [ 29.751137594936708, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16298, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.442154655172414 ], [ 29.751407088607593, -1.442424137931035 ], [ 29.751676582278481, -1.442424137931035 ], [ 29.751676582278481, -1.442154655172414 ], [ 29.751407088607593, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16299, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.442154655172414 ], [ 29.751676582278481, -1.442424137931035 ], [ 29.751946075949366, -1.442424137931035 ], [ 29.751946075949366, -1.442154655172414 ], [ 29.751676582278481, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16300, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.442154655172414 ], [ 29.752215569620251, -1.442424137931035 ], [ 29.75248506329114, -1.442424137931035 ], [ 29.75248506329114, -1.442154655172414 ], [ 29.752215569620251, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16301, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.442154655172414 ], [ 29.75248506329114, -1.442424137931035 ], [ 29.752754556962024, -1.442424137931035 ], [ 29.752754556962024, -1.442154655172414 ], [ 29.75248506329114, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16302, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.442154655172414 ], [ 29.752754556962024, -1.442693620689655 ], [ 29.753024050632909, -1.442693620689655 ], [ 29.753024050632909, -1.442154655172414 ], [ 29.752754556962024, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16303, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.442154655172414 ], [ 29.753024050632909, -1.442424137931035 ], [ 29.753293544303798, -1.442424137931035 ], [ 29.753293544303798, -1.442154655172414 ], [ 29.753024050632909, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16304, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.442154655172414 ], [ 29.753293544303798, -1.442963103448276 ], [ 29.753563037974683, -1.442963103448276 ], [ 29.753563037974683, -1.443232586206897 ], [ 29.753832531645568, -1.443232586206897 ], [ 29.753832531645568, -1.442693620689655 ], [ 29.753563037974683, -1.442693620689655 ], [ 29.753563037974683, -1.442154655172414 ], [ 29.753293544303798, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16305, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.442154655172414 ], [ 29.753563037974683, -1.442424137931035 ], [ 29.753832531645568, -1.442424137931035 ], [ 29.753832531645568, -1.442154655172414 ], [ 29.753563037974683, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16306, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.442154655172414 ], [ 29.753832531645568, -1.442424137931035 ], [ 29.754102025316456, -1.442424137931035 ], [ 29.754102025316456, -1.442154655172414 ], [ 29.753832531645568, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16307, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.442154655172414 ], [ 29.754102025316456, -1.442424137931035 ], [ 29.754371518987341, -1.442424137931035 ], [ 29.754371518987341, -1.442154655172414 ], [ 29.754102025316456, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16308, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.442154655172414 ], [ 29.754371518987341, -1.442424137931035 ], [ 29.754641012658226, -1.442424137931035 ], [ 29.754641012658226, -1.442154655172414 ], [ 29.754371518987341, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16309, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.442154655172414 ], [ 29.754641012658226, -1.442424137931035 ], [ 29.754910506329114, -1.442424137931035 ], [ 29.754910506329114, -1.442154655172414 ], [ 29.754641012658226, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16310, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.442154655172414 ], [ 29.754910506329114, -1.442424137931035 ], [ 29.75518, -1.442424137931035 ], [ 29.75518, -1.442154655172414 ], [ 29.754910506329114, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16311, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.442154655172414 ], [ 29.75518, -1.442424137931035 ], [ 29.755449493670884, -1.442424137931035 ], [ 29.755449493670884, -1.442154655172414 ], [ 29.75518, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16312, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.442154655172414 ], [ 29.755449493670884, -1.442424137931035 ], [ 29.755718987341773, -1.442424137931035 ], [ 29.755718987341773, -1.442154655172414 ], [ 29.755449493670884, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16313, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.442154655172414 ], [ 29.755718987341773, -1.442424137931035 ], [ 29.755988481012658, -1.442424137931035 ], [ 29.755988481012658, -1.442154655172414 ], [ 29.755718987341773, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16314, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.442154655172414 ], [ 29.755988481012658, -1.442424137931035 ], [ 29.756257974683542, -1.442424137931035 ], [ 29.756257974683542, -1.442154655172414 ], [ 29.755988481012658, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16315, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.442154655172414 ], [ 29.756257974683542, -1.442424137931035 ], [ 29.756796962025316, -1.442424137931035 ], [ 29.756796962025316, -1.442154655172414 ], [ 29.756257974683542, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16316, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.442154655172414 ], [ 29.756796962025316, -1.442424137931035 ], [ 29.757066455696201, -1.442424137931035 ], [ 29.757066455696201, -1.442154655172414 ], [ 29.756796962025316, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16317, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.442154655172414 ], [ 29.757066455696201, -1.442424137931035 ], [ 29.757335949367089, -1.442424137931035 ], [ 29.757335949367089, -1.442154655172414 ], [ 29.757066455696201, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16318, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.442154655172414 ], [ 29.757335949367089, -1.442424137931035 ], [ 29.757605443037974, -1.442424137931035 ], [ 29.757605443037974, -1.442154655172414 ], [ 29.757335949367089, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16319, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.442154655172414 ], [ 29.757605443037974, -1.442424137931035 ], [ 29.757874936708859, -1.442424137931035 ], [ 29.757874936708859, -1.442154655172414 ], [ 29.757605443037974, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16320, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.442154655172414 ], [ 29.757874936708859, -1.442424137931035 ], [ 29.758144430379748, -1.442424137931035 ], [ 29.758144430379748, -1.442154655172414 ], [ 29.757874936708859, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16321, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.442154655172414 ], [ 29.758144430379748, -1.442424137931035 ], [ 29.758413924050632, -1.442424137931035 ], [ 29.758413924050632, -1.442154655172414 ], [ 29.758144430379748, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16322, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.442154655172414 ], [ 29.758413924050632, -1.442424137931035 ], [ 29.758952911392406, -1.442424137931035 ], [ 29.758952911392406, -1.442154655172414 ], [ 29.758413924050632, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16323, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.442154655172414 ], [ 29.758952911392406, -1.442424137931035 ], [ 29.761917341772151, -1.442424137931035 ], [ 29.761917341772151, -1.442154655172414 ], [ 29.758952911392406, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16324, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.442154655172414 ], [ 29.761917341772151, -1.442424137931035 ], [ 29.762456329113924, -1.442424137931035 ], [ 29.762456329113924, -1.442154655172414 ], [ 29.761917341772151, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16325, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.442154655172414 ], [ 29.762456329113924, -1.442424137931035 ], [ 29.762725822784809, -1.442424137931035 ], [ 29.762725822784809, -1.442154655172414 ], [ 29.762456329113924, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16326, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.442154655172414 ], [ 29.762725822784809, -1.442424137931035 ], [ 29.762995316455697, -1.442424137931035 ], [ 29.762995316455697, -1.442154655172414 ], [ 29.762725822784809, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16327, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.442154655172414 ], [ 29.762995316455697, -1.442424137931035 ], [ 29.763264810126582, -1.442424137931035 ], [ 29.763264810126582, -1.442154655172414 ], [ 29.762995316455697, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16328, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.442154655172414 ], [ 29.763264810126582, -1.442424137931035 ], [ 29.763534303797467, -1.442424137931035 ], [ 29.763534303797467, -1.442154655172414 ], [ 29.763264810126582, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16329, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.442154655172414 ], [ 29.763534303797467, -1.442424137931035 ], [ 29.763803797468356, -1.442424137931035 ], [ 29.763803797468356, -1.442154655172414 ], [ 29.763534303797467, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16330, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.442154655172414 ], [ 29.763803797468356, -1.442424137931035 ], [ 29.76407329113924, -1.442424137931035 ], [ 29.76407329113924, -1.442154655172414 ], [ 29.763803797468356, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16331, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.442154655172414 ], [ 29.76407329113924, -1.442424137931035 ], [ 29.764342784810125, -1.442424137931035 ], [ 29.764342784810125, -1.442154655172414 ], [ 29.76407329113924, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16332, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.442154655172414 ], [ 29.764342784810125, -1.442424137931035 ], [ 29.764612278481014, -1.442424137931035 ], [ 29.764612278481014, -1.442154655172414 ], [ 29.764342784810125, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16333, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.442154655172414 ], [ 29.764612278481014, -1.442424137931035 ], [ 29.764881772151899, -1.442424137931035 ], [ 29.764881772151899, -1.442154655172414 ], [ 29.764612278481014, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16334, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.442154655172414 ], [ 29.764881772151899, -1.442424137931035 ], [ 29.765151265822784, -1.442424137931035 ], [ 29.765151265822784, -1.442154655172414 ], [ 29.764881772151899, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16335, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.442154655172414 ], [ 29.765151265822784, -1.442424137931035 ], [ 29.765420759493672, -1.442424137931035 ], [ 29.765420759493672, -1.442154655172414 ], [ 29.765151265822784, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16336, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.442154655172414 ], [ 29.765420759493672, -1.442424137931035 ], [ 29.765959746835442, -1.442424137931035 ], [ 29.765959746835442, -1.442154655172414 ], [ 29.765420759493672, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16337, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.442154655172414 ], [ 29.765959746835442, -1.442424137931035 ], [ 29.766229240506327, -1.442424137931035 ], [ 29.766229240506327, -1.442154655172414 ], [ 29.765959746835442, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16338, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.442154655172414 ], [ 29.766229240506327, -1.442424137931035 ], [ 29.766498734177215, -1.442424137931035 ], [ 29.766498734177215, -1.442154655172414 ], [ 29.766229240506327, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16339, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.442154655172414 ], [ 29.766498734177215, -1.442424137931035 ], [ 29.7667682278481, -1.442424137931035 ], [ 29.7667682278481, -1.442154655172414 ], [ 29.766498734177215, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16340, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.442154655172414 ], [ 29.7667682278481, -1.442424137931035 ], [ 29.767037721518985, -1.442424137931035 ], [ 29.767037721518985, -1.442154655172414 ], [ 29.7667682278481, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16341, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.442154655172414 ], [ 29.767037721518985, -1.442424137931035 ], [ 29.767846202531643, -1.442424137931035 ], [ 29.767846202531643, -1.442154655172414 ], [ 29.767037721518985, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16342, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.442154655172414 ], [ 29.767846202531643, -1.442424137931035 ], [ 29.768385189873417, -1.442424137931035 ], [ 29.768385189873417, -1.442154655172414 ], [ 29.767846202531643, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16343, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.442154655172414 ], [ 29.768385189873417, -1.442424137931035 ], [ 29.76892417721519, -1.442424137931035 ], [ 29.76892417721519, -1.442154655172414 ], [ 29.768385189873417, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16344, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.442154655172414 ], [ 29.76892417721519, -1.442424137931035 ], [ 29.769193670886075, -1.442424137931035 ], [ 29.769193670886075, -1.442154655172414 ], [ 29.76892417721519, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16345, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.442154655172414 ], [ 29.769193670886075, -1.442424137931035 ], [ 29.76946316455696, -1.442424137931035 ], [ 29.76946316455696, -1.442154655172414 ], [ 29.769193670886075, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16346, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.442154655172414 ], [ 29.76946316455696, -1.442424137931035 ], [ 29.769732658227849, -1.442424137931035 ], [ 29.769732658227849, -1.442154655172414 ], [ 29.76946316455696, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16347, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.442154655172414 ], [ 29.769732658227849, -1.442424137931035 ], [ 29.770002151898733, -1.442424137931035 ], [ 29.770002151898733, -1.442154655172414 ], [ 29.769732658227849, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16348, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.442154655172414 ], [ 29.770002151898733, -1.442424137931035 ], [ 29.770271645569618, -1.442424137931035 ], [ 29.770271645569618, -1.442154655172414 ], [ 29.770002151898733, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16349, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.442154655172414 ], [ 29.770271645569618, -1.442424137931035 ], [ 29.770541139240507, -1.442424137931035 ], [ 29.770541139240507, -1.442154655172414 ], [ 29.770271645569618, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16350, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.442154655172414 ], [ 29.770541139240507, -1.442424137931035 ], [ 29.770810632911392, -1.442424137931035 ], [ 29.770810632911392, -1.442154655172414 ], [ 29.770541139240507, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16351, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.442154655172414 ], [ 29.770810632911392, -1.442424137931035 ], [ 29.771080126582277, -1.442424137931035 ], [ 29.771080126582277, -1.442154655172414 ], [ 29.770810632911392, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16352, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.442154655172414 ], [ 29.771080126582277, -1.442424137931035 ], [ 29.771349620253165, -1.442424137931035 ], [ 29.771349620253165, -1.442154655172414 ], [ 29.771080126582277, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16353, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.442154655172414 ], [ 29.771349620253165, -1.442424137931035 ], [ 29.77161911392405, -1.442424137931035 ], [ 29.77161911392405, -1.442154655172414 ], [ 29.771349620253165, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16354, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.442154655172414 ], [ 29.77161911392405, -1.442424137931035 ], [ 29.771888607594935, -1.442424137931035 ], [ 29.771888607594935, -1.442154655172414 ], [ 29.77161911392405, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16355, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.442154655172414 ], [ 29.771888607594935, -1.442424137931035 ], [ 29.772158101265823, -1.442424137931035 ], [ 29.772158101265823, -1.442154655172414 ], [ 29.771888607594935, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16356, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.442154655172414 ], [ 29.772158101265823, -1.442424137931035 ], [ 29.772427594936708, -1.442424137931035 ], [ 29.772427594936708, -1.442154655172414 ], [ 29.772158101265823, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16357, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.442154655172414 ], [ 29.772427594936708, -1.442424137931035 ], [ 29.772697088607593, -1.442424137931035 ], [ 29.772697088607593, -1.442154655172414 ], [ 29.772427594936708, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16358, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.442154655172414 ], [ 29.772697088607593, -1.442424137931035 ], [ 29.772966582278482, -1.442424137931035 ], [ 29.772966582278482, -1.442154655172414 ], [ 29.772697088607593, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16359, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.442154655172414 ], [ 29.772966582278482, -1.442424137931035 ], [ 29.773236075949367, -1.442424137931035 ], [ 29.773236075949367, -1.442154655172414 ], [ 29.772966582278482, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16360, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.442154655172414 ], [ 29.773236075949367, -1.442424137931035 ], [ 29.773505569620252, -1.442424137931035 ], [ 29.773505569620252, -1.442154655172414 ], [ 29.773236075949367, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16361, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.442154655172414 ], [ 29.773505569620252, -1.442424137931035 ], [ 29.77377506329114, -1.442424137931035 ], [ 29.77377506329114, -1.442154655172414 ], [ 29.773505569620252, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16362, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.442154655172414 ], [ 29.77377506329114, -1.442424137931035 ], [ 29.774044556962025, -1.442424137931035 ], [ 29.774044556962025, -1.442154655172414 ], [ 29.77377506329114, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16363, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.442154655172414 ], [ 29.774044556962025, -1.442424137931035 ], [ 29.77431405063291, -1.442424137931035 ], [ 29.77431405063291, -1.442154655172414 ], [ 29.774044556962025, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16364, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.442154655172414 ], [ 29.77431405063291, -1.442424137931035 ], [ 29.774853037974683, -1.442424137931035 ], [ 29.774853037974683, -1.442154655172414 ], [ 29.77431405063291, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16365, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.442154655172414 ], [ 29.774853037974683, -1.442424137931035 ], [ 29.775122531645568, -1.442424137931035 ], [ 29.775122531645568, -1.442154655172414 ], [ 29.774853037974683, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16366, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.442154655172414 ], [ 29.775122531645568, -1.442424137931035 ], [ 29.775392025316457, -1.442424137931035 ], [ 29.775392025316457, -1.442154655172414 ], [ 29.775122531645568, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16367, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.442154655172414 ], [ 29.775392025316457, -1.442424137931035 ], [ 29.775661518987341, -1.442424137931035 ], [ 29.775661518987341, -1.442154655172414 ], [ 29.775392025316457, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16368, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.442154655172414 ], [ 29.775661518987341, -1.442424137931035 ], [ 29.775931012658226, -1.442424137931035 ], [ 29.775931012658226, -1.442154655172414 ], [ 29.775661518987341, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16369, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.442154655172414 ], [ 29.776200506329115, -1.442424137931035 ], [ 29.77647, -1.442424137931035 ], [ 29.77647, -1.442154655172414 ], [ 29.776200506329115, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16370, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.442154655172414 ], [ 29.77647, -1.442424137931035 ], [ 29.776739493670885, -1.442424137931035 ], [ 29.776739493670885, -1.442154655172414 ], [ 29.77647, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16371, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.442154655172414 ], [ 29.776739493670885, -1.442424137931035 ], [ 29.777008987341773, -1.442424137931035 ], [ 29.777008987341773, -1.442154655172414 ], [ 29.776739493670885, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16372, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.442154655172414 ], [ 29.777008987341773, -1.442424137931035 ], [ 29.777278481012658, -1.442424137931035 ], [ 29.777278481012658, -1.442154655172414 ], [ 29.777008987341773, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16373, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.442154655172414 ], [ 29.777278481012658, -1.443771551724138 ], [ 29.777547974683543, -1.443771551724138 ], [ 29.777547974683543, -1.442154655172414 ], [ 29.777278481012658, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16374, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.442154655172414 ], [ 29.777817468354431, -1.442424137931035 ], [ 29.778086962025316, -1.442424137931035 ], [ 29.778086962025316, -1.442154655172414 ], [ 29.777817468354431, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16375, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.442154655172414 ], [ 29.77862594936709, -1.442424137931035 ], [ 29.778895443037975, -1.442424137931035 ], [ 29.778895443037975, -1.442154655172414 ], [ 29.77862594936709, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16376, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.442154655172414 ], [ 29.77916493670886, -1.442693620689655 ], [ 29.779434430379748, -1.442693620689655 ], [ 29.779434430379748, -1.442154655172414 ], [ 29.77916493670886, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16377, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.442154655172414 ], [ 29.779434430379748, -1.442424137931035 ], [ 29.779703924050633, -1.442424137931035 ], [ 29.779703924050633, -1.442154655172414 ], [ 29.779434430379748, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16378, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.442154655172414 ], [ 29.780512405063291, -1.442693620689655 ], [ 29.780781898734176, -1.442693620689655 ], [ 29.780781898734176, -1.442154655172414 ], [ 29.780512405063291, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16379, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.442154655172414 ], [ 29.78132088607595, -1.442693620689655 ], [ 29.781590379746834, -1.442693620689655 ], [ 29.781590379746834, -1.442154655172414 ], [ 29.78132088607595, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16380, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.442154655172414 ], [ 29.781859873417723, -1.442693620689655 ], [ 29.782129367088608, -1.442693620689655 ], [ 29.782129367088608, -1.442154655172414 ], [ 29.781859873417723, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16381, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.442154655172414 ], [ 29.782129367088608, -1.442424137931035 ], [ 29.782398860759493, -1.442424137931035 ], [ 29.782398860759493, -1.442154655172414 ], [ 29.782129367088608, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16382, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.442154655172414 ], [ 29.782668354430381, -1.442693620689655 ], [ 29.782937848101266, -1.442693620689655 ], [ 29.782937848101266, -1.442154655172414 ], [ 29.782668354430381, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16383, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.442154655172414 ], [ 29.782937848101266, -1.442424137931035 ], [ 29.783207341772151, -1.442424137931035 ], [ 29.783207341772151, -1.442154655172414 ], [ 29.782937848101266, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16384, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.442154655172414 ], [ 29.783207341772151, -1.442424137931035 ], [ 29.783476835443039, -1.442424137931035 ], [ 29.783476835443039, -1.442154655172414 ], [ 29.783207341772151, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16385, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.442154655172414 ], [ 29.783476835443039, -1.442693620689655 ], [ 29.784015822784809, -1.442693620689655 ], [ 29.784015822784809, -1.442154655172414 ], [ 29.783476835443039, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16386, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.442154655172414 ], [ 29.784015822784809, -1.442424137931035 ], [ 29.784285316455698, -1.442424137931035 ], [ 29.784285316455698, -1.442154655172414 ], [ 29.784015822784809, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16387, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.442154655172414 ], [ 29.784285316455698, -1.442424137931035 ], [ 29.784554810126583, -1.442424137931035 ], [ 29.784554810126583, -1.442154655172414 ], [ 29.784285316455698, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16388, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.442154655172414 ], [ 29.784554810126583, -1.442424137931035 ], [ 29.784824303797468, -1.442424137931035 ], [ 29.784824303797468, -1.442154655172414 ], [ 29.784554810126583, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16389, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.442154655172414 ], [ 29.784824303797468, -1.442424137931035 ], [ 29.785093797468356, -1.442424137931035 ], [ 29.785093797468356, -1.442154655172414 ], [ 29.784824303797468, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16390, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.442154655172414 ], [ 29.785093797468356, -1.442424137931035 ], [ 29.785363291139241, -1.442424137931035 ], [ 29.785363291139241, -1.442154655172414 ], [ 29.785093797468356, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16391, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.442154655172414 ], [ 29.785902278481014, -1.442424137931035 ], [ 29.786171772151899, -1.442424137931035 ], [ 29.786171772151899, -1.442154655172414 ], [ 29.785902278481014, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16392, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.442154655172414 ], [ 29.786171772151899, -1.442424137931035 ], [ 29.786441265822784, -1.442424137931035 ], [ 29.786441265822784, -1.442154655172414 ], [ 29.786171772151899, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16393, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.442154655172414 ], [ 29.786441265822784, -1.442424137931035 ], [ 29.786710759493673, -1.442424137931035 ], [ 29.786710759493673, -1.442154655172414 ], [ 29.786441265822784, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16394, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.442154655172414 ], [ 29.794526075949367, -1.443502068965517 ], [ 29.794795569620252, -1.443502068965517 ], [ 29.794795569620252, -1.442154655172414 ], [ 29.794526075949367, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16395, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.442154655172414 ], [ 29.794795569620252, -1.442693620689655 ], [ 29.79506506329114, -1.442693620689655 ], [ 29.79506506329114, -1.442154655172414 ], [ 29.794795569620252, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16396, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.442154655172414 ], [ 29.795334556962025, -1.443502068965517 ], [ 29.79560405063291, -1.443502068965517 ], [ 29.79560405063291, -1.442154655172414 ], [ 29.795334556962025, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16397, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.442154655172414 ], [ 29.79560405063291, -1.442424137931035 ], [ 29.795873544303799, -1.442424137931035 ], [ 29.795873544303799, -1.442154655172414 ], [ 29.79560405063291, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16398, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.442154655172414 ], [ 29.795873544303799, -1.442424137931035 ], [ 29.796143037974684, -1.442424137931035 ], [ 29.796143037974684, -1.442154655172414 ], [ 29.795873544303799, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16399, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.442154655172414 ], [ 29.796143037974684, -1.442693620689655 ], [ 29.796412531645569, -1.442693620689655 ], [ 29.796412531645569, -1.442154655172414 ], [ 29.796143037974684, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16400, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.442154655172414 ], [ 29.796412531645569, -1.442424137931035 ], [ 29.796682025316457, -1.442424137931035 ], [ 29.796682025316457, -1.442154655172414 ], [ 29.796412531645569, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16401, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.442154655172414 ], [ 29.796682025316457, -1.442424137931035 ], [ 29.796951518987342, -1.442424137931035 ], [ 29.796951518987342, -1.442154655172414 ], [ 29.796682025316457, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.442154655172414 ], [ 29.796951518987342, -1.442424137931035 ], [ 29.797221012658227, -1.442424137931035 ], [ 29.797221012658227, -1.442154655172414 ], [ 29.796951518987342, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16403, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.442154655172414 ], [ 29.799646455696202, -1.442424137931035 ], [ 29.79991594936709, -1.442424137931035 ], [ 29.79991594936709, -1.442154655172414 ], [ 29.799646455696202, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16404, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.442154655172414 ], [ 29.79991594936709, -1.442424137931035 ], [ 29.800185443037975, -1.442424137931035 ], [ 29.800185443037975, -1.442154655172414 ], [ 29.79991594936709, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16405, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.442154655172414 ], [ 29.800185443037975, -1.442424137931035 ], [ 29.80045493670886, -1.442424137931035 ], [ 29.80045493670886, -1.442154655172414 ], [ 29.800185443037975, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16406, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.442154655172414 ], [ 29.80045493670886, -1.442424137931035 ], [ 29.800724430379748, -1.442424137931035 ], [ 29.800724430379748, -1.442154655172414 ], [ 29.80045493670886, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16407, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.442154655172414 ], [ 29.800724430379748, -1.442424137931035 ], [ 29.800993924050633, -1.442424137931035 ], [ 29.800993924050633, -1.442154655172414 ], [ 29.800724430379748, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16408, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.442154655172414 ], [ 29.800993924050633, -1.442424137931035 ], [ 29.801263417721518, -1.442424137931035 ], [ 29.801263417721518, -1.442154655172414 ], [ 29.800993924050633, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16409, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.442154655172414 ], [ 29.801263417721518, -1.442424137931035 ], [ 29.801532911392407, -1.442424137931035 ], [ 29.801532911392407, -1.442154655172414 ], [ 29.801263417721518, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16410, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.442154655172414 ], [ 29.801532911392407, -1.442424137931035 ], [ 29.802071898734177, -1.442424137931035 ], [ 29.802071898734177, -1.442154655172414 ], [ 29.801532911392407, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16411, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.442154655172414 ], [ 29.802071898734177, -1.442424137931035 ], [ 29.802341392405065, -1.442424137931035 ], [ 29.802341392405065, -1.442154655172414 ], [ 29.802071898734177, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16412, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.442154655172414 ], [ 29.802341392405065, -1.442424137931035 ], [ 29.80261088607595, -1.442424137931035 ], [ 29.80261088607595, -1.442154655172414 ], [ 29.802341392405065, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16413, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.442154655172414 ], [ 29.80261088607595, -1.442424137931035 ], [ 29.802880379746835, -1.442424137931035 ], [ 29.802880379746835, -1.442154655172414 ], [ 29.80261088607595, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16414, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.442154655172414 ], [ 29.802880379746835, -1.442424137931035 ], [ 29.803149873417723, -1.442424137931035 ], [ 29.803149873417723, -1.442154655172414 ], [ 29.802880379746835, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16415, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.442154655172414 ], [ 29.803149873417723, -1.442424137931035 ], [ 29.803419367088608, -1.442424137931035 ], [ 29.803419367088608, -1.442154655172414 ], [ 29.803149873417723, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16416, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.442154655172414 ], [ 29.803688860759493, -1.442963103448276 ], [ 29.803958354430382, -1.442963103448276 ], [ 29.803958354430382, -1.442154655172414 ], [ 29.803688860759493, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16417, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.442154655172414 ], [ 29.803958354430382, -1.442963103448276 ], [ 29.804227848101267, -1.442963103448276 ], [ 29.804227848101267, -1.442154655172414 ], [ 29.803958354430382, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16418, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.442154655172414 ], [ 29.804497341772151, -1.442963103448276 ], [ 29.80476683544304, -1.442963103448276 ], [ 29.80476683544304, -1.442154655172414 ], [ 29.804497341772151, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16419, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.442154655172414 ], [ 29.80476683544304, -1.442963103448276 ], [ 29.805036329113925, -1.442963103448276 ], [ 29.805036329113925, -1.442154655172414 ], [ 29.80476683544304, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16420, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.442154655172414 ], [ 29.805036329113925, -1.442424137931035 ], [ 29.80530582278481, -1.442424137931035 ], [ 29.80530582278481, -1.442154655172414 ], [ 29.805036329113925, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16421, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.442154655172414 ], [ 29.80530582278481, -1.442424137931035 ], [ 29.805575316455698, -1.442424137931035 ], [ 29.805575316455698, -1.442154655172414 ], [ 29.80530582278481, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16422, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.442154655172414 ], [ 29.805575316455698, -1.442424137931035 ], [ 29.805844810126583, -1.442424137931035 ], [ 29.805844810126583, -1.442154655172414 ], [ 29.805575316455698, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16423, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.442154655172414 ], [ 29.805844810126583, -1.442424137931035 ], [ 29.806114303797468, -1.442424137931035 ], [ 29.806114303797468, -1.442154655172414 ], [ 29.805844810126583, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16424, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.441615689655172 ], [ 29.81015670886076, -1.441615689655172 ], [ 29.81015670886076, -1.442424137931035 ], [ 29.808000759493673, -1.442424137931035 ], [ 29.808000759493673, -1.442154655172414 ], [ 29.809887215189875, -1.442154655172414 ], [ 29.809887215189875, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16425, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746556202531643, -1.442424137931035 ], [ 29.746556202531643, -1.442693620689655 ], [ 29.746825696202531, -1.442693620689655 ], [ 29.746825696202531, -1.442424137931035 ], [ 29.746556202531643, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16426, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.442424137931035 ], [ 29.746825696202531, -1.442693620689655 ], [ 29.747095189873416, -1.442693620689655 ], [ 29.747095189873416, -1.442424137931035 ], [ 29.746825696202531, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16427, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.442424137931035 ], [ 29.747095189873416, -1.442693620689655 ], [ 29.747364683544301, -1.442693620689655 ], [ 29.747364683544301, -1.442424137931035 ], [ 29.747095189873416, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16428, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.442424137931035 ], [ 29.747364683544301, -1.442693620689655 ], [ 29.747903670886075, -1.442693620689655 ], [ 29.747903670886075, -1.442424137931035 ], [ 29.747364683544301, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16429, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.442424137931035 ], [ 29.747903670886075, -1.442963103448276 ], [ 29.74817316455696, -1.442963103448276 ], [ 29.74817316455696, -1.442424137931035 ], [ 29.747903670886075, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16430, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.442424137931035 ], [ 29.74817316455696, -1.442963103448276 ], [ 29.748442658227848, -1.442963103448276 ], [ 29.748442658227848, -1.442424137931035 ], [ 29.74817316455696, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16431, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.442424137931035 ], [ 29.748712151898733, -1.442693620689655 ], [ 29.748981645569618, -1.442693620689655 ], [ 29.748981645569618, -1.442424137931035 ], [ 29.748712151898733, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16432, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.442424137931035 ], [ 29.748981645569618, -1.442693620689655 ], [ 29.749251139240506, -1.442693620689655 ], [ 29.749251139240506, -1.442424137931035 ], [ 29.748981645569618, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16433, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.442424137931035 ], [ 29.749251139240506, -1.442693620689655 ], [ 29.749520632911391, -1.442693620689655 ], [ 29.749520632911391, -1.442424137931035 ], [ 29.749251139240506, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16434, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.442424137931035 ], [ 29.749520632911391, -1.442693620689655 ], [ 29.749790126582276, -1.442693620689655 ], [ 29.749790126582276, -1.442424137931035 ], [ 29.749520632911391, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16435, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.442424137931035 ], [ 29.749790126582276, -1.442693620689655 ], [ 29.750059620253165, -1.442693620689655 ], [ 29.750059620253165, -1.442424137931035 ], [ 29.749790126582276, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16436, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.442424137931035 ], [ 29.750059620253165, -1.442693620689655 ], [ 29.75032911392405, -1.442693620689655 ], [ 29.75032911392405, -1.442424137931035 ], [ 29.750059620253165, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16437, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.442424137931035 ], [ 29.75032911392405, -1.442693620689655 ], [ 29.750598607594934, -1.442693620689655 ], [ 29.750598607594934, -1.442424137931035 ], [ 29.75032911392405, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16438, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.442424137931035 ], [ 29.750598607594934, -1.442693620689655 ], [ 29.750868101265823, -1.442693620689655 ], [ 29.750868101265823, -1.442424137931035 ], [ 29.750598607594934, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16439, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.442424137931035 ], [ 29.750868101265823, -1.442963103448276 ], [ 29.751137594936708, -1.442963103448276 ], [ 29.751137594936708, -1.442424137931035 ], [ 29.750868101265823, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16440, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.442424137931035 ], [ 29.751137594936708, -1.442693620689655 ], [ 29.751407088607593, -1.442693620689655 ], [ 29.751407088607593, -1.442424137931035 ], [ 29.751137594936708, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16441, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.442424137931035 ], [ 29.751407088607593, -1.442693620689655 ], [ 29.751676582278481, -1.442693620689655 ], [ 29.751676582278481, -1.442424137931035 ], [ 29.751407088607593, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16442, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.442424137931035 ], [ 29.751676582278481, -1.442963103448276 ], [ 29.751946075949366, -1.442963103448276 ], [ 29.751946075949366, -1.442424137931035 ], [ 29.751676582278481, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16443, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.442424137931035 ], [ 29.751946075949366, -1.442693620689655 ], [ 29.752215569620251, -1.442693620689655 ], [ 29.752215569620251, -1.442424137931035 ], [ 29.751946075949366, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16444, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.442424137931035 ], [ 29.752215569620251, -1.442693620689655 ], [ 29.75248506329114, -1.442693620689655 ], [ 29.75248506329114, -1.442424137931035 ], [ 29.752215569620251, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16445, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.442424137931035 ], [ 29.75248506329114, -1.442963103448276 ], [ 29.752754556962024, -1.442963103448276 ], [ 29.752754556962024, -1.442424137931035 ], [ 29.75248506329114, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16446, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.442424137931035 ], [ 29.753024050632909, -1.442693620689655 ], [ 29.753293544303798, -1.442693620689655 ], [ 29.753293544303798, -1.442424137931035 ], [ 29.753024050632909, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16447, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.442424137931035 ], [ 29.753563037974683, -1.442693620689655 ], [ 29.753832531645568, -1.442693620689655 ], [ 29.753832531645568, -1.442424137931035 ], [ 29.753563037974683, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16448, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.442424137931035 ], [ 29.753832531645568, -1.442693620689655 ], [ 29.754102025316456, -1.442693620689655 ], [ 29.754102025316456, -1.442424137931035 ], [ 29.753832531645568, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16449, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.442424137931035 ], [ 29.754102025316456, -1.442693620689655 ], [ 29.754371518987341, -1.442693620689655 ], [ 29.754371518987341, -1.442424137931035 ], [ 29.754102025316456, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16450, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.442424137931035 ], [ 29.754371518987341, -1.442693620689655 ], [ 29.754641012658226, -1.442693620689655 ], [ 29.754641012658226, -1.442424137931035 ], [ 29.754371518987341, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16451, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.442424137931035 ], [ 29.754641012658226, -1.442693620689655 ], [ 29.754910506329114, -1.442693620689655 ], [ 29.754910506329114, -1.442424137931035 ], [ 29.754641012658226, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16452, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.442424137931035 ], [ 29.754910506329114, -1.442693620689655 ], [ 29.75518, -1.442693620689655 ], [ 29.75518, -1.442424137931035 ], [ 29.754910506329114, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16453, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.442424137931035 ], [ 29.75518, -1.442693620689655 ], [ 29.755449493670884, -1.442693620689655 ], [ 29.755449493670884, -1.442424137931035 ], [ 29.75518, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16454, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.442424137931035 ], [ 29.755449493670884, -1.442693620689655 ], [ 29.755718987341773, -1.442693620689655 ], [ 29.755718987341773, -1.442424137931035 ], [ 29.755449493670884, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16455, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.442424137931035 ], [ 29.755718987341773, -1.442693620689655 ], [ 29.755988481012658, -1.442693620689655 ], [ 29.755988481012658, -1.442424137931035 ], [ 29.755718987341773, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16456, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.442424137931035 ], [ 29.755988481012658, -1.442693620689655 ], [ 29.756257974683542, -1.442693620689655 ], [ 29.756257974683542, -1.442424137931035 ], [ 29.755988481012658, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16457, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.442424137931035 ], [ 29.756257974683542, -1.442693620689655 ], [ 29.756796962025316, -1.442693620689655 ], [ 29.756796962025316, -1.442424137931035 ], [ 29.756257974683542, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16458, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.442424137931035 ], [ 29.756796962025316, -1.442693620689655 ], [ 29.757066455696201, -1.442693620689655 ], [ 29.757066455696201, -1.442424137931035 ], [ 29.756796962025316, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16459, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.442424137931035 ], [ 29.757066455696201, -1.442693620689655 ], [ 29.757335949367089, -1.442693620689655 ], [ 29.757335949367089, -1.442424137931035 ], [ 29.757066455696201, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16460, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.442424137931035 ], [ 29.757335949367089, -1.442693620689655 ], [ 29.757605443037974, -1.442693620689655 ], [ 29.757605443037974, -1.442424137931035 ], [ 29.757335949367089, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16461, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.442424137931035 ], [ 29.757605443037974, -1.442693620689655 ], [ 29.757874936708859, -1.442693620689655 ], [ 29.757874936708859, -1.442424137931035 ], [ 29.757605443037974, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16462, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.442424137931035 ], [ 29.757874936708859, -1.442693620689655 ], [ 29.758144430379748, -1.442693620689655 ], [ 29.758144430379748, -1.442424137931035 ], [ 29.757874936708859, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16463, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.442424137931035 ], [ 29.758144430379748, -1.442693620689655 ], [ 29.758413924050632, -1.442693620689655 ], [ 29.758413924050632, -1.442424137931035 ], [ 29.758144430379748, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16464, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.442424137931035 ], [ 29.758413924050632, -1.442693620689655 ], [ 29.758683417721517, -1.442693620689655 ], [ 29.758683417721517, -1.442424137931035 ], [ 29.758413924050632, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16465, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.442424137931035 ], [ 29.758683417721517, -1.442693620689655 ], [ 29.759222405063291, -1.442693620689655 ], [ 29.759222405063291, -1.442424137931035 ], [ 29.758683417721517, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16466, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.442424137931035 ], [ 29.759222405063291, -1.442693620689655 ], [ 29.761647848101266, -1.442693620689655 ], [ 29.761647848101266, -1.442424137931035 ], [ 29.759222405063291, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16467, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.442424137931035 ], [ 29.761647848101266, -1.442693620689655 ], [ 29.762186835443039, -1.442693620689655 ], [ 29.762186835443039, -1.442424137931035 ], [ 29.761647848101266, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16468, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.442424137931035 ], [ 29.762186835443039, -1.442693620689655 ], [ 29.762456329113924, -1.442693620689655 ], [ 29.762456329113924, -1.442424137931035 ], [ 29.762186835443039, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16469, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.442424137931035 ], [ 29.762456329113924, -1.442693620689655 ], [ 29.762725822784809, -1.442693620689655 ], [ 29.762725822784809, -1.442424137931035 ], [ 29.762456329113924, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16470, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.442424137931035 ], [ 29.762725822784809, -1.442693620689655 ], [ 29.762995316455697, -1.442693620689655 ], [ 29.762995316455697, -1.442424137931035 ], [ 29.762725822784809, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16471, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.442424137931035 ], [ 29.762995316455697, -1.442693620689655 ], [ 29.763264810126582, -1.442693620689655 ], [ 29.763264810126582, -1.442424137931035 ], [ 29.762995316455697, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16472, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.442424137931035 ], [ 29.763264810126582, -1.442693620689655 ], [ 29.763534303797467, -1.442693620689655 ], [ 29.763534303797467, -1.442424137931035 ], [ 29.763264810126582, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16473, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.442424137931035 ], [ 29.763534303797467, -1.442693620689655 ], [ 29.763803797468356, -1.442693620689655 ], [ 29.763803797468356, -1.442424137931035 ], [ 29.763534303797467, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16474, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.442424137931035 ], [ 29.763803797468356, -1.442693620689655 ], [ 29.76407329113924, -1.442693620689655 ], [ 29.76407329113924, -1.442424137931035 ], [ 29.763803797468356, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16475, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.442424137931035 ], [ 29.76407329113924, -1.442693620689655 ], [ 29.764342784810125, -1.442693620689655 ], [ 29.764342784810125, -1.442424137931035 ], [ 29.76407329113924, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16476, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.442424137931035 ], [ 29.764342784810125, -1.442693620689655 ], [ 29.764612278481014, -1.442693620689655 ], [ 29.764612278481014, -1.442424137931035 ], [ 29.764342784810125, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16477, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.442424137931035 ], [ 29.764612278481014, -1.442693620689655 ], [ 29.764881772151899, -1.442693620689655 ], [ 29.764881772151899, -1.442424137931035 ], [ 29.764612278481014, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16478, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.442424137931035 ], [ 29.764881772151899, -1.442693620689655 ], [ 29.765151265822784, -1.442693620689655 ], [ 29.765151265822784, -1.442424137931035 ], [ 29.764881772151899, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16479, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.442424137931035 ], [ 29.765151265822784, -1.442693620689655 ], [ 29.765420759493672, -1.442693620689655 ], [ 29.765420759493672, -1.442424137931035 ], [ 29.765151265822784, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16480, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.442424137931035 ], [ 29.765420759493672, -1.442693620689655 ], [ 29.765959746835442, -1.442693620689655 ], [ 29.765959746835442, -1.442424137931035 ], [ 29.765420759493672, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16481, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.442424137931035 ], [ 29.765959746835442, -1.442693620689655 ], [ 29.766229240506327, -1.442693620689655 ], [ 29.766229240506327, -1.442424137931035 ], [ 29.765959746835442, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16482, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.442424137931035 ], [ 29.766498734177215, -1.442693620689655 ], [ 29.768115696202532, -1.442693620689655 ], [ 29.768115696202532, -1.442424137931035 ], [ 29.766498734177215, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16483, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.442424137931035 ], [ 29.768115696202532, -1.442693620689655 ], [ 29.768654683544302, -1.442693620689655 ], [ 29.768654683544302, -1.442424137931035 ], [ 29.768115696202532, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16484, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.442424137931035 ], [ 29.768654683544302, -1.442693620689655 ], [ 29.76892417721519, -1.442693620689655 ], [ 29.76892417721519, -1.442424137931035 ], [ 29.768654683544302, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16485, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.442424137931035 ], [ 29.76892417721519, -1.442693620689655 ], [ 29.769193670886075, -1.442693620689655 ], [ 29.769193670886075, -1.442424137931035 ], [ 29.76892417721519, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16486, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.442424137931035 ], [ 29.769193670886075, -1.442693620689655 ], [ 29.76946316455696, -1.442693620689655 ], [ 29.76946316455696, -1.442424137931035 ], [ 29.769193670886075, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16487, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.442424137931035 ], [ 29.76946316455696, -1.442693620689655 ], [ 29.769732658227849, -1.442693620689655 ], [ 29.769732658227849, -1.442424137931035 ], [ 29.76946316455696, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16488, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.442424137931035 ], [ 29.769732658227849, -1.442693620689655 ], [ 29.770002151898733, -1.442693620689655 ], [ 29.770002151898733, -1.442424137931035 ], [ 29.769732658227849, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16489, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.442424137931035 ], [ 29.770002151898733, -1.442693620689655 ], [ 29.770271645569618, -1.442693620689655 ], [ 29.770271645569618, -1.442424137931035 ], [ 29.770002151898733, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16490, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.442424137931035 ], [ 29.770271645569618, -1.442693620689655 ], [ 29.770541139240507, -1.442693620689655 ], [ 29.770541139240507, -1.442424137931035 ], [ 29.770271645569618, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16491, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.442424137931035 ], [ 29.770541139240507, -1.442693620689655 ], [ 29.770810632911392, -1.442693620689655 ], [ 29.770810632911392, -1.442424137931035 ], [ 29.770541139240507, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16492, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.442424137931035 ], [ 29.770810632911392, -1.442693620689655 ], [ 29.771080126582277, -1.442693620689655 ], [ 29.771080126582277, -1.442424137931035 ], [ 29.770810632911392, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16493, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.442424137931035 ], [ 29.771080126582277, -1.442693620689655 ], [ 29.771349620253165, -1.442693620689655 ], [ 29.771349620253165, -1.442424137931035 ], [ 29.771080126582277, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16494, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.442424137931035 ], [ 29.771349620253165, -1.442693620689655 ], [ 29.77161911392405, -1.442693620689655 ], [ 29.77161911392405, -1.442424137931035 ], [ 29.771349620253165, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16495, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.442424137931035 ], [ 29.77161911392405, -1.442693620689655 ], [ 29.771888607594935, -1.442693620689655 ], [ 29.771888607594935, -1.442424137931035 ], [ 29.77161911392405, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16496, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.442424137931035 ], [ 29.771888607594935, -1.442693620689655 ], [ 29.772158101265823, -1.442693620689655 ], [ 29.772158101265823, -1.442424137931035 ], [ 29.771888607594935, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16497, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.442424137931035 ], [ 29.772158101265823, -1.442693620689655 ], [ 29.772427594936708, -1.442693620689655 ], [ 29.772427594936708, -1.442424137931035 ], [ 29.772158101265823, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16498, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.442424137931035 ], [ 29.772427594936708, -1.442693620689655 ], [ 29.772697088607593, -1.442693620689655 ], [ 29.772697088607593, -1.442424137931035 ], [ 29.772427594936708, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16499, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.442424137931035 ], [ 29.772697088607593, -1.442693620689655 ], [ 29.772966582278482, -1.442693620689655 ], [ 29.772966582278482, -1.442424137931035 ], [ 29.772697088607593, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16500, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.442424137931035 ], [ 29.772966582278482, -1.442693620689655 ], [ 29.773236075949367, -1.442693620689655 ], [ 29.773236075949367, -1.442424137931035 ], [ 29.772966582278482, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16501, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.442424137931035 ], [ 29.773236075949367, -1.442693620689655 ], [ 29.773505569620252, -1.442693620689655 ], [ 29.773505569620252, -1.442424137931035 ], [ 29.773236075949367, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16502, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.442424137931035 ], [ 29.773505569620252, -1.442693620689655 ], [ 29.77377506329114, -1.442693620689655 ], [ 29.77377506329114, -1.442424137931035 ], [ 29.773505569620252, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16503, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.442424137931035 ], [ 29.77377506329114, -1.442693620689655 ], [ 29.774044556962025, -1.442693620689655 ], [ 29.774044556962025, -1.442424137931035 ], [ 29.77377506329114, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16504, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.442424137931035 ], [ 29.774044556962025, -1.442693620689655 ], [ 29.77431405063291, -1.442693620689655 ], [ 29.77431405063291, -1.442424137931035 ], [ 29.774044556962025, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16505, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.442424137931035 ], [ 29.77431405063291, -1.442693620689655 ], [ 29.774853037974683, -1.442693620689655 ], [ 29.774853037974683, -1.442424137931035 ], [ 29.77431405063291, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16506, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.442424137931035 ], [ 29.774853037974683, -1.442693620689655 ], [ 29.775122531645568, -1.442693620689655 ], [ 29.775122531645568, -1.442424137931035 ], [ 29.774853037974683, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16507, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.442424137931035 ], [ 29.775122531645568, -1.442693620689655 ], [ 29.775392025316457, -1.442693620689655 ], [ 29.775392025316457, -1.442424137931035 ], [ 29.775122531645568, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16508, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.442424137931035 ], [ 29.775392025316457, -1.442693620689655 ], [ 29.775661518987341, -1.442693620689655 ], [ 29.775661518987341, -1.442424137931035 ], [ 29.775392025316457, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16509, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.442424137931035 ], [ 29.775661518987341, -1.442693620689655 ], [ 29.775931012658226, -1.442693620689655 ], [ 29.775931012658226, -1.442424137931035 ], [ 29.775661518987341, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16510, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.442424137931035 ], [ 29.775931012658226, -1.442693620689655 ], [ 29.776200506329115, -1.442693620689655 ], [ 29.776200506329115, -1.442424137931035 ], [ 29.775931012658226, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16511, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.442424137931035 ], [ 29.776200506329115, -1.442963103448276 ], [ 29.77647, -1.442963103448276 ], [ 29.77647, -1.442424137931035 ], [ 29.776200506329115, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16512, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.442424137931035 ], [ 29.77647, -1.442693620689655 ], [ 29.776739493670885, -1.442693620689655 ], [ 29.776739493670885, -1.442424137931035 ], [ 29.77647, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16513, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.442424137931035 ], [ 29.777817468354431, -1.442693620689655 ], [ 29.778086962025316, -1.442693620689655 ], [ 29.778086962025316, -1.442424137931035 ], [ 29.777817468354431, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16514, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.442424137931035 ], [ 29.77862594936709, -1.443771551724138 ], [ 29.778895443037975, -1.443771551724138 ], [ 29.778895443037975, -1.442424137931035 ], [ 29.77862594936709, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16515, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.442424137931035 ], [ 29.779434430379748, -1.443771551724138 ], [ 29.779703924050633, -1.443771551724138 ], [ 29.779703924050633, -1.442424137931035 ], [ 29.779434430379748, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16516, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.442424137931035 ], [ 29.779973417721518, -1.443771551724138 ], [ 29.780242911392406, -1.443771551724138 ], [ 29.780242911392406, -1.442424137931035 ], [ 29.779973417721518, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16517, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.442424137931035 ], [ 29.780781898734176, -1.443771551724138 ], [ 29.781051392405065, -1.443771551724138 ], [ 29.781051392405065, -1.442424137931035 ], [ 29.780781898734176, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16518, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.442424137931035 ], [ 29.781590379746834, -1.443771551724138 ], [ 29.781859873417723, -1.443771551724138 ], [ 29.781859873417723, -1.442424137931035 ], [ 29.781590379746834, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16519, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.442424137931035 ], [ 29.782129367088608, -1.443771551724138 ], [ 29.782398860759493, -1.443771551724138 ], [ 29.782398860759493, -1.442424137931035 ], [ 29.782129367088608, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16520, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.442424137931035 ], [ 29.782398860759493, -1.443771551724138 ], [ 29.782668354430381, -1.443771551724138 ], [ 29.782668354430381, -1.442424137931035 ], [ 29.782398860759493, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16521, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.442424137931035 ], [ 29.782937848101266, -1.443771551724138 ], [ 29.783207341772151, -1.443771551724138 ], [ 29.783207341772151, -1.442424137931035 ], [ 29.782937848101266, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16522, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.442424137931035 ], [ 29.783207341772151, -1.442963103448276 ], [ 29.783476835443039, -1.442963103448276 ], [ 29.783476835443039, -1.442424137931035 ], [ 29.783207341772151, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16523, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.442424137931035 ], [ 29.784015822784809, -1.443771551724138 ], [ 29.784285316455698, -1.443771551724138 ], [ 29.784285316455698, -1.442424137931035 ], [ 29.784015822784809, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16524, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.442424137931035 ], [ 29.784285316455698, -1.442693620689655 ], [ 29.784554810126583, -1.442693620689655 ], [ 29.784554810126583, -1.442424137931035 ], [ 29.784285316455698, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16525, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.442424137931035 ], [ 29.784554810126583, -1.442693620689655 ], [ 29.784824303797468, -1.442693620689655 ], [ 29.784824303797468, -1.442424137931035 ], [ 29.784554810126583, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16526, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.442424137931035 ], [ 29.784824303797468, -1.442963103448276 ], [ 29.785093797468356, -1.442963103448276 ], [ 29.785093797468356, -1.442424137931035 ], [ 29.784824303797468, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16527, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.442424137931035 ], [ 29.785093797468356, -1.442693620689655 ], [ 29.785363291139241, -1.442693620689655 ], [ 29.785363291139241, -1.442424137931035 ], [ 29.785093797468356, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16528, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.442424137931035 ], [ 29.785363291139241, -1.442693620689655 ], [ 29.785632784810126, -1.442693620689655 ], [ 29.785632784810126, -1.442424137931035 ], [ 29.785363291139241, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16529, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.442424137931035 ], [ 29.785632784810126, -1.442693620689655 ], [ 29.785902278481014, -1.442693620689655 ], [ 29.785902278481014, -1.442424137931035 ], [ 29.785632784810126, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16530, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.442424137931035 ], [ 29.785902278481014, -1.442693620689655 ], [ 29.786171772151899, -1.442693620689655 ], [ 29.786171772151899, -1.442424137931035 ], [ 29.785902278481014, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16531, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.442424137931035 ], [ 29.786171772151899, -1.442693620689655 ], [ 29.786441265822784, -1.442693620689655 ], [ 29.786441265822784, -1.442424137931035 ], [ 29.786171772151899, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16532, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.442424137931035 ], [ 29.786441265822784, -1.442693620689655 ], [ 29.786710759493673, -1.442693620689655 ], [ 29.786710759493673, -1.442424137931035 ], [ 29.786441265822784, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16533, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.442424137931035 ], [ 29.794256582278482, -1.443502068965517 ], [ 29.794526075949367, -1.443502068965517 ], [ 29.794526075949367, -1.442424137931035 ], [ 29.794256582278482, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16534, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.442424137931035 ], [ 29.79506506329114, -1.443502068965517 ], [ 29.795334556962025, -1.443502068965517 ], [ 29.795334556962025, -1.442424137931035 ], [ 29.79506506329114, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16535, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.442424137931035 ], [ 29.79560405063291, -1.443771551724138 ], [ 29.795873544303799, -1.443771551724138 ], [ 29.795873544303799, -1.442424137931035 ], [ 29.79560405063291, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16536, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.442424137931035 ], [ 29.795873544303799, -1.443771551724138 ], [ 29.796143037974684, -1.443771551724138 ], [ 29.796143037974684, -1.442424137931035 ], [ 29.795873544303799, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16537, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.442424137931035 ], [ 29.796951518987342, -1.442693620689655 ], [ 29.797221012658227, -1.442693620689655 ], [ 29.797221012658227, -1.442424137931035 ], [ 29.796951518987342, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16538, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.442424137931035 ], [ 29.797221012658227, -1.442693620689655 ], [ 29.797490506329115, -1.442693620689655 ], [ 29.797490506329115, -1.442424137931035 ], [ 29.797221012658227, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16539, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.442424137931035 ], [ 29.797490506329115, -1.442693620689655 ], [ 29.79776, -1.442693620689655 ], [ 29.79776, -1.442424137931035 ], [ 29.797490506329115, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16540, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.442424137931035 ], [ 29.79991594936709, -1.442693620689655 ], [ 29.800185443037975, -1.442693620689655 ], [ 29.800185443037975, -1.442424137931035 ], [ 29.79991594936709, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16541, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.442424137931035 ], [ 29.800185443037975, -1.442693620689655 ], [ 29.80045493670886, -1.442693620689655 ], [ 29.80045493670886, -1.442424137931035 ], [ 29.800185443037975, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16542, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.442424137931035 ], [ 29.80045493670886, -1.442693620689655 ], [ 29.800724430379748, -1.442693620689655 ], [ 29.800724430379748, -1.442424137931035 ], [ 29.80045493670886, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16543, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.442424137931035 ], [ 29.800724430379748, -1.442693620689655 ], [ 29.800993924050633, -1.442693620689655 ], [ 29.800993924050633, -1.442424137931035 ], [ 29.800724430379748, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16544, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.442424137931035 ], [ 29.800993924050633, -1.442693620689655 ], [ 29.801263417721518, -1.442693620689655 ], [ 29.801263417721518, -1.442424137931035 ], [ 29.800993924050633, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16545, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.442424137931035 ], [ 29.801263417721518, -1.442693620689655 ], [ 29.801532911392407, -1.442693620689655 ], [ 29.801532911392407, -1.442424137931035 ], [ 29.801263417721518, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16546, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.442424137931035 ], [ 29.801532911392407, -1.442693620689655 ], [ 29.802071898734177, -1.442693620689655 ], [ 29.802071898734177, -1.442424137931035 ], [ 29.801532911392407, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16547, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.442424137931035 ], [ 29.802071898734177, -1.442693620689655 ], [ 29.802341392405065, -1.442693620689655 ], [ 29.802341392405065, -1.442424137931035 ], [ 29.802071898734177, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16548, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.442424137931035 ], [ 29.802341392405065, -1.442693620689655 ], [ 29.80261088607595, -1.442693620689655 ], [ 29.80261088607595, -1.442424137931035 ], [ 29.802341392405065, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16549, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.442424137931035 ], [ 29.80261088607595, -1.442693620689655 ], [ 29.802880379746835, -1.442693620689655 ], [ 29.802880379746835, -1.442424137931035 ], [ 29.80261088607595, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16550, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.442424137931035 ], [ 29.802880379746835, -1.442693620689655 ], [ 29.803149873417723, -1.442693620689655 ], [ 29.803149873417723, -1.442424137931035 ], [ 29.802880379746835, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16551, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.442424137931035 ], [ 29.803149873417723, -1.442693620689655 ], [ 29.803419367088608, -1.442693620689655 ], [ 29.803419367088608, -1.442424137931035 ], [ 29.803149873417723, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16552, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.442424137931035 ], [ 29.804227848101267, -1.442693620689655 ], [ 29.804497341772151, -1.442693620689655 ], [ 29.804497341772151, -1.442424137931035 ], [ 29.804227848101267, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16553, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.442424137931035 ], [ 29.805036329113925, -1.442693620689655 ], [ 29.80530582278481, -1.442693620689655 ], [ 29.80530582278481, -1.442424137931035 ], [ 29.805036329113925, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16554, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.442424137931035 ], [ 29.80530582278481, -1.442693620689655 ], [ 29.805575316455698, -1.442693620689655 ], [ 29.805575316455698, -1.442424137931035 ], [ 29.80530582278481, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16555, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.442424137931035 ], [ 29.805575316455698, -1.442693620689655 ], [ 29.805844810126583, -1.442693620689655 ], [ 29.805844810126583, -1.442424137931035 ], [ 29.805575316455698, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16556, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.746825696202531, -1.442693620689655 ], [ 29.746825696202531, -1.443502068965517 ], [ 29.747095189873416, -1.443502068965517 ], [ 29.747095189873416, -1.442693620689655 ], [ 29.746825696202531, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16557, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.442693620689655 ], [ 29.747095189873416, -1.443232586206897 ], [ 29.747364683544301, -1.443232586206897 ], [ 29.747364683544301, -1.442693620689655 ], [ 29.747095189873416, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16558, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.442693620689655 ], [ 29.747364683544301, -1.443232586206897 ], [ 29.747903670886075, -1.443232586206897 ], [ 29.747903670886075, -1.442693620689655 ], [ 29.747364683544301, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16559, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.442693620689655 ], [ 29.748442658227848, -1.442963103448276 ], [ 29.748712151898733, -1.442963103448276 ], [ 29.748712151898733, -1.442693620689655 ], [ 29.748442658227848, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16560, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.442693620689655 ], [ 29.748712151898733, -1.442963103448276 ], [ 29.748981645569618, -1.442963103448276 ], [ 29.748981645569618, -1.442693620689655 ], [ 29.748712151898733, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16561, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.442693620689655 ], [ 29.748981645569618, -1.442963103448276 ], [ 29.749251139240506, -1.442963103448276 ], [ 29.749251139240506, -1.442693620689655 ], [ 29.748981645569618, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16562, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.442693620689655 ], [ 29.749251139240506, -1.442963103448276 ], [ 29.749520632911391, -1.442963103448276 ], [ 29.749520632911391, -1.442693620689655 ], [ 29.749251139240506, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16563, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.442693620689655 ], [ 29.749520632911391, -1.442963103448276 ], [ 29.749790126582276, -1.442963103448276 ], [ 29.749790126582276, -1.442693620689655 ], [ 29.749520632911391, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16564, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.442693620689655 ], [ 29.749790126582276, -1.443232586206897 ], [ 29.750059620253165, -1.443232586206897 ], [ 29.750059620253165, -1.442693620689655 ], [ 29.749790126582276, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16565, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.442693620689655 ], [ 29.750059620253165, -1.442963103448276 ], [ 29.75032911392405, -1.442963103448276 ], [ 29.75032911392405, -1.442693620689655 ], [ 29.750059620253165, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16566, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.442693620689655 ], [ 29.75032911392405, -1.442963103448276 ], [ 29.750598607594934, -1.442963103448276 ], [ 29.750598607594934, -1.442693620689655 ], [ 29.75032911392405, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16567, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.442693620689655 ], [ 29.750598607594934, -1.443232586206897 ], [ 29.750868101265823, -1.443232586206897 ], [ 29.750868101265823, -1.442693620689655 ], [ 29.750598607594934, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16568, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.442693620689655 ], [ 29.751137594936708, -1.442963103448276 ], [ 29.751407088607593, -1.442963103448276 ], [ 29.751407088607593, -1.442693620689655 ], [ 29.751137594936708, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16569, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.442693620689655 ], [ 29.751407088607593, -1.443232586206897 ], [ 29.751676582278481, -1.443232586206897 ], [ 29.751676582278481, -1.442693620689655 ], [ 29.751407088607593, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16570, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.442693620689655 ], [ 29.751946075949366, -1.443232586206897 ], [ 29.752215569620251, -1.443232586206897 ], [ 29.752215569620251, -1.442693620689655 ], [ 29.751946075949366, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16571, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.442693620689655 ], [ 29.752215569620251, -1.443502068965517 ], [ 29.75248506329114, -1.443502068965517 ], [ 29.75248506329114, -1.442693620689655 ], [ 29.752215569620251, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16572, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.442693620689655 ], [ 29.752754556962024, -1.443232586206897 ], [ 29.753024050632909, -1.443232586206897 ], [ 29.753024050632909, -1.442693620689655 ], [ 29.752754556962024, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16573, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.442693620689655 ], [ 29.753024050632909, -1.443232586206897 ], [ 29.753293544303798, -1.443232586206897 ], [ 29.753293544303798, -1.442693620689655 ], [ 29.753024050632909, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16574, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.442693620689655 ], [ 29.753832531645568, -1.442963103448276 ], [ 29.754102025316456, -1.442963103448276 ], [ 29.754102025316456, -1.442693620689655 ], [ 29.753832531645568, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16575, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.442693620689655 ], [ 29.754102025316456, -1.442963103448276 ], [ 29.754371518987341, -1.442963103448276 ], [ 29.754371518987341, -1.442693620689655 ], [ 29.754102025316456, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16576, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.442693620689655 ], [ 29.754371518987341, -1.442963103448276 ], [ 29.754641012658226, -1.442963103448276 ], [ 29.754641012658226, -1.442693620689655 ], [ 29.754371518987341, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16577, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.442693620689655 ], [ 29.754641012658226, -1.442963103448276 ], [ 29.754910506329114, -1.442963103448276 ], [ 29.754910506329114, -1.442693620689655 ], [ 29.754641012658226, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16578, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.442693620689655 ], [ 29.754910506329114, -1.442963103448276 ], [ 29.75518, -1.442963103448276 ], [ 29.75518, -1.442693620689655 ], [ 29.754910506329114, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16579, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.442693620689655 ], [ 29.75518, -1.442963103448276 ], [ 29.755449493670884, -1.442963103448276 ], [ 29.755449493670884, -1.442693620689655 ], [ 29.75518, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16580, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.442693620689655 ], [ 29.755449493670884, -1.442963103448276 ], [ 29.755718987341773, -1.442963103448276 ], [ 29.755718987341773, -1.442693620689655 ], [ 29.755449493670884, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16581, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.442693620689655 ], [ 29.755718987341773, -1.442963103448276 ], [ 29.755988481012658, -1.442963103448276 ], [ 29.755988481012658, -1.442693620689655 ], [ 29.755718987341773, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16582, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.442693620689655 ], [ 29.755988481012658, -1.442963103448276 ], [ 29.756257974683542, -1.442963103448276 ], [ 29.756257974683542, -1.442693620689655 ], [ 29.755988481012658, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16583, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.442693620689655 ], [ 29.756257974683542, -1.442963103448276 ], [ 29.756796962025316, -1.442963103448276 ], [ 29.756796962025316, -1.442693620689655 ], [ 29.756257974683542, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16584, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.442693620689655 ], [ 29.756796962025316, -1.442963103448276 ], [ 29.757066455696201, -1.442963103448276 ], [ 29.757066455696201, -1.442693620689655 ], [ 29.756796962025316, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16585, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.442693620689655 ], [ 29.757066455696201, -1.442963103448276 ], [ 29.757335949367089, -1.442963103448276 ], [ 29.757335949367089, -1.442693620689655 ], [ 29.757066455696201, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16586, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.442693620689655 ], [ 29.757335949367089, -1.442963103448276 ], [ 29.757605443037974, -1.442963103448276 ], [ 29.757605443037974, -1.442693620689655 ], [ 29.757335949367089, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16587, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.442693620689655 ], [ 29.757605443037974, -1.442963103448276 ], [ 29.757874936708859, -1.442963103448276 ], [ 29.757874936708859, -1.442693620689655 ], [ 29.757605443037974, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16588, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.442693620689655 ], [ 29.757874936708859, -1.442963103448276 ], [ 29.758144430379748, -1.442963103448276 ], [ 29.758144430379748, -1.442693620689655 ], [ 29.757874936708859, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16589, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.442693620689655 ], [ 29.758144430379748, -1.442963103448276 ], [ 29.758413924050632, -1.442963103448276 ], [ 29.758413924050632, -1.442693620689655 ], [ 29.758144430379748, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16590, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.442693620689655 ], [ 29.758413924050632, -1.442963103448276 ], [ 29.758683417721517, -1.442963103448276 ], [ 29.758683417721517, -1.442693620689655 ], [ 29.758413924050632, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16591, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.442693620689655 ], [ 29.758683417721517, -1.442963103448276 ], [ 29.762186835443039, -1.442963103448276 ], [ 29.762186835443039, -1.442693620689655 ], [ 29.758683417721517, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16592, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.442693620689655 ], [ 29.762186835443039, -1.442963103448276 ], [ 29.762456329113924, -1.442963103448276 ], [ 29.762456329113924, -1.442693620689655 ], [ 29.762186835443039, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16593, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.442693620689655 ], [ 29.762456329113924, -1.442963103448276 ], [ 29.762725822784809, -1.442963103448276 ], [ 29.762725822784809, -1.442693620689655 ], [ 29.762456329113924, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16594, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.442693620689655 ], [ 29.762725822784809, -1.442963103448276 ], [ 29.762995316455697, -1.442963103448276 ], [ 29.762995316455697, -1.442693620689655 ], [ 29.762725822784809, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16595, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.442693620689655 ], [ 29.762995316455697, -1.442963103448276 ], [ 29.763264810126582, -1.442963103448276 ], [ 29.763264810126582, -1.442693620689655 ], [ 29.762995316455697, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16596, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.442693620689655 ], [ 29.763264810126582, -1.442963103448276 ], [ 29.763534303797467, -1.442963103448276 ], [ 29.763534303797467, -1.442693620689655 ], [ 29.763264810126582, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16597, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.442693620689655 ], [ 29.763534303797467, -1.442963103448276 ], [ 29.763803797468356, -1.442963103448276 ], [ 29.763803797468356, -1.442693620689655 ], [ 29.763534303797467, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16598, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.442693620689655 ], [ 29.763803797468356, -1.442963103448276 ], [ 29.76407329113924, -1.442963103448276 ], [ 29.76407329113924, -1.442693620689655 ], [ 29.763803797468356, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16599, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.442693620689655 ], [ 29.76407329113924, -1.442963103448276 ], [ 29.764342784810125, -1.442963103448276 ], [ 29.764342784810125, -1.442693620689655 ], [ 29.76407329113924, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16600, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.442693620689655 ], [ 29.764342784810125, -1.442963103448276 ], [ 29.764612278481014, -1.442963103448276 ], [ 29.764612278481014, -1.442693620689655 ], [ 29.764342784810125, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16601, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.442693620689655 ], [ 29.764612278481014, -1.442963103448276 ], [ 29.764881772151899, -1.442963103448276 ], [ 29.764881772151899, -1.442693620689655 ], [ 29.764612278481014, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16602, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.442693620689655 ], [ 29.764881772151899, -1.442963103448276 ], [ 29.765151265822784, -1.442963103448276 ], [ 29.765151265822784, -1.442693620689655 ], [ 29.764881772151899, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16603, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.442693620689655 ], [ 29.765151265822784, -1.442963103448276 ], [ 29.765420759493672, -1.442963103448276 ], [ 29.765420759493672, -1.442693620689655 ], [ 29.765151265822784, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16604, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.442693620689655 ], [ 29.765420759493672, -1.442963103448276 ], [ 29.765959746835442, -1.442963103448276 ], [ 29.765959746835442, -1.442693620689655 ], [ 29.765420759493672, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16605, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.442424137931035 ], [ 29.766498734177215, -1.442424137931035 ], [ 29.766498734177215, -1.442693620689655 ], [ 29.768385189873417, -1.442693620689655 ], [ 29.768385189873417, -1.442963103448276 ], [ 29.765959746835442, -1.442963103448276 ], [ 29.765959746835442, -1.442693620689655 ], [ 29.766229240506327, -1.442693620689655 ], [ 29.766229240506327, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16606, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.442693620689655 ], [ 29.768385189873417, -1.442963103448276 ], [ 29.768654683544302, -1.442963103448276 ], [ 29.768654683544302, -1.442693620689655 ], [ 29.768385189873417, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16607, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.442693620689655 ], [ 29.768654683544302, -1.442963103448276 ], [ 29.76892417721519, -1.442963103448276 ], [ 29.76892417721519, -1.442693620689655 ], [ 29.768654683544302, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16608, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.442693620689655 ], [ 29.76892417721519, -1.442963103448276 ], [ 29.769193670886075, -1.442963103448276 ], [ 29.769193670886075, -1.442693620689655 ], [ 29.76892417721519, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16609, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.442693620689655 ], [ 29.769193670886075, -1.442963103448276 ], [ 29.76946316455696, -1.442963103448276 ], [ 29.76946316455696, -1.442693620689655 ], [ 29.769193670886075, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16610, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.442693620689655 ], [ 29.76946316455696, -1.442963103448276 ], [ 29.769732658227849, -1.442963103448276 ], [ 29.769732658227849, -1.442693620689655 ], [ 29.76946316455696, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16611, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.442693620689655 ], [ 29.769732658227849, -1.442963103448276 ], [ 29.770002151898733, -1.442963103448276 ], [ 29.770002151898733, -1.442693620689655 ], [ 29.769732658227849, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16612, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.442693620689655 ], [ 29.770002151898733, -1.442963103448276 ], [ 29.770271645569618, -1.442963103448276 ], [ 29.770271645569618, -1.442693620689655 ], [ 29.770002151898733, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16613, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.442693620689655 ], [ 29.770271645569618, -1.442963103448276 ], [ 29.770541139240507, -1.442963103448276 ], [ 29.770541139240507, -1.442693620689655 ], [ 29.770271645569618, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16614, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.442693620689655 ], [ 29.770541139240507, -1.442963103448276 ], [ 29.770810632911392, -1.442963103448276 ], [ 29.770810632911392, -1.442693620689655 ], [ 29.770541139240507, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16615, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.442693620689655 ], [ 29.770810632911392, -1.442963103448276 ], [ 29.771080126582277, -1.442963103448276 ], [ 29.771080126582277, -1.442693620689655 ], [ 29.770810632911392, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16616, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.442693620689655 ], [ 29.771080126582277, -1.442963103448276 ], [ 29.771349620253165, -1.442963103448276 ], [ 29.771349620253165, -1.442693620689655 ], [ 29.771080126582277, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16617, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.442693620689655 ], [ 29.771349620253165, -1.442963103448276 ], [ 29.77161911392405, -1.442963103448276 ], [ 29.77161911392405, -1.442693620689655 ], [ 29.771349620253165, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16618, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.442693620689655 ], [ 29.77161911392405, -1.442963103448276 ], [ 29.771888607594935, -1.442963103448276 ], [ 29.771888607594935, -1.442693620689655 ], [ 29.77161911392405, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16619, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.442693620689655 ], [ 29.771888607594935, -1.442963103448276 ], [ 29.772158101265823, -1.442963103448276 ], [ 29.772158101265823, -1.442693620689655 ], [ 29.771888607594935, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16620, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.442693620689655 ], [ 29.772158101265823, -1.442963103448276 ], [ 29.772427594936708, -1.442963103448276 ], [ 29.772427594936708, -1.442693620689655 ], [ 29.772158101265823, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16621, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.442693620689655 ], [ 29.772427594936708, -1.442963103448276 ], [ 29.772697088607593, -1.442963103448276 ], [ 29.772697088607593, -1.442693620689655 ], [ 29.772427594936708, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16622, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.442693620689655 ], [ 29.772697088607593, -1.442963103448276 ], [ 29.772966582278482, -1.442963103448276 ], [ 29.772966582278482, -1.442693620689655 ], [ 29.772697088607593, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16623, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.442693620689655 ], [ 29.772966582278482, -1.442963103448276 ], [ 29.773236075949367, -1.442963103448276 ], [ 29.773236075949367, -1.442693620689655 ], [ 29.772966582278482, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16624, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.442693620689655 ], [ 29.773236075949367, -1.442963103448276 ], [ 29.773505569620252, -1.442963103448276 ], [ 29.773505569620252, -1.442693620689655 ], [ 29.773236075949367, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16625, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.442693620689655 ], [ 29.773505569620252, -1.442963103448276 ], [ 29.77377506329114, -1.442963103448276 ], [ 29.77377506329114, -1.442693620689655 ], [ 29.773505569620252, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16626, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.442693620689655 ], [ 29.77377506329114, -1.442963103448276 ], [ 29.774044556962025, -1.442963103448276 ], [ 29.774044556962025, -1.442693620689655 ], [ 29.77377506329114, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16627, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.442693620689655 ], [ 29.774044556962025, -1.442963103448276 ], [ 29.77431405063291, -1.442963103448276 ], [ 29.77431405063291, -1.442693620689655 ], [ 29.774044556962025, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16628, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.442693620689655 ], [ 29.77431405063291, -1.442963103448276 ], [ 29.774853037974683, -1.442963103448276 ], [ 29.774853037974683, -1.442693620689655 ], [ 29.77431405063291, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16629, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.442693620689655 ], [ 29.774853037974683, -1.442963103448276 ], [ 29.775122531645568, -1.442963103448276 ], [ 29.775122531645568, -1.442693620689655 ], [ 29.774853037974683, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16630, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.442693620689655 ], [ 29.775122531645568, -1.442963103448276 ], [ 29.775392025316457, -1.442963103448276 ], [ 29.775392025316457, -1.442693620689655 ], [ 29.775122531645568, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16631, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.442693620689655 ], [ 29.775392025316457, -1.442963103448276 ], [ 29.775661518987341, -1.442963103448276 ], [ 29.775661518987341, -1.442693620689655 ], [ 29.775392025316457, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16632, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.442693620689655 ], [ 29.775661518987341, -1.442963103448276 ], [ 29.775931012658226, -1.442963103448276 ], [ 29.775931012658226, -1.442693620689655 ], [ 29.775661518987341, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16633, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.442693620689655 ], [ 29.775931012658226, -1.442963103448276 ], [ 29.776200506329115, -1.442963103448276 ], [ 29.776200506329115, -1.442693620689655 ], [ 29.775931012658226, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16634, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.442693620689655 ], [ 29.77647, -1.443502068965517 ], [ 29.776739493670885, -1.443502068965517 ], [ 29.776739493670885, -1.442693620689655 ], [ 29.77647, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16635, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.442693620689655 ], [ 29.777817468354431, -1.443502068965517 ], [ 29.778086962025316, -1.443502068965517 ], [ 29.778086962025316, -1.442693620689655 ], [ 29.777817468354431, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16636, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.442693620689655 ], [ 29.77916493670886, -1.443502068965517 ], [ 29.779434430379748, -1.443502068965517 ], [ 29.779434430379748, -1.442693620689655 ], [ 29.77916493670886, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16637, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.442693620689655 ], [ 29.780512405063291, -1.443502068965517 ], [ 29.780781898734176, -1.443502068965517 ], [ 29.780781898734176, -1.442693620689655 ], [ 29.780512405063291, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16638, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.442693620689655 ], [ 29.78132088607595, -1.443502068965517 ], [ 29.781590379746834, -1.443502068965517 ], [ 29.781590379746834, -1.442693620689655 ], [ 29.78132088607595, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16639, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.442693620689655 ], [ 29.781859873417723, -1.443502068965517 ], [ 29.782129367088608, -1.443502068965517 ], [ 29.782129367088608, -1.442693620689655 ], [ 29.781859873417723, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16640, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.442693620689655 ], [ 29.782668354430381, -1.443502068965517 ], [ 29.782937848101266, -1.443502068965517 ], [ 29.782937848101266, -1.442693620689655 ], [ 29.782668354430381, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16641, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.442693620689655 ], [ 29.783476835443039, -1.443502068965517 ], [ 29.784015822784809, -1.443502068965517 ], [ 29.784015822784809, -1.442693620689655 ], [ 29.783476835443039, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16642, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.442693620689655 ], [ 29.784285316455698, -1.443502068965517 ], [ 29.784554810126583, -1.443502068965517 ], [ 29.784554810126583, -1.442693620689655 ], [ 29.784285316455698, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16643, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.442693620689655 ], [ 29.784554810126583, -1.443502068965517 ], [ 29.784824303797468, -1.443502068965517 ], [ 29.784824303797468, -1.442693620689655 ], [ 29.784554810126583, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16644, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.442693620689655 ], [ 29.785093797468356, -1.443502068965517 ], [ 29.785363291139241, -1.443502068965517 ], [ 29.785363291139241, -1.442693620689655 ], [ 29.785093797468356, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16645, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.442693620689655 ], [ 29.785363291139241, -1.443502068965517 ], [ 29.785632784810126, -1.443502068965517 ], [ 29.785632784810126, -1.442693620689655 ], [ 29.785363291139241, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16646, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.442693620689655 ], [ 29.785632784810126, -1.442963103448276 ], [ 29.785902278481014, -1.442963103448276 ], [ 29.785902278481014, -1.442693620689655 ], [ 29.785632784810126, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16647, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.442693620689655 ], [ 29.785902278481014, -1.442963103448276 ], [ 29.786171772151899, -1.442963103448276 ], [ 29.786171772151899, -1.442693620689655 ], [ 29.785902278481014, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16648, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.442693620689655 ], [ 29.786171772151899, -1.442963103448276 ], [ 29.786441265822784, -1.442963103448276 ], [ 29.786441265822784, -1.442693620689655 ], [ 29.786171772151899, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16649, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.442693620689655 ], [ 29.786441265822784, -1.442963103448276 ], [ 29.786710759493673, -1.442963103448276 ], [ 29.786710759493673, -1.442693620689655 ], [ 29.786441265822784, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16650, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.442693620689655 ], [ 29.794795569620252, -1.443771551724138 ], [ 29.79506506329114, -1.443771551724138 ], [ 29.79506506329114, -1.442693620689655 ], [ 29.794795569620252, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16651, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.442693620689655 ], [ 29.800185443037975, -1.442963103448276 ], [ 29.80045493670886, -1.442963103448276 ], [ 29.80045493670886, -1.442693620689655 ], [ 29.800185443037975, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16652, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.442693620689655 ], [ 29.80045493670886, -1.442963103448276 ], [ 29.800724430379748, -1.442963103448276 ], [ 29.800724430379748, -1.442693620689655 ], [ 29.80045493670886, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16653, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.442693620689655 ], [ 29.800724430379748, -1.442963103448276 ], [ 29.800993924050633, -1.442963103448276 ], [ 29.800993924050633, -1.442693620689655 ], [ 29.800724430379748, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16654, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.442693620689655 ], [ 29.800993924050633, -1.442963103448276 ], [ 29.801263417721518, -1.442963103448276 ], [ 29.801263417721518, -1.442693620689655 ], [ 29.800993924050633, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16655, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.442693620689655 ], [ 29.801263417721518, -1.442963103448276 ], [ 29.801532911392407, -1.442963103448276 ], [ 29.801532911392407, -1.442693620689655 ], [ 29.801263417721518, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16656, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.442693620689655 ], [ 29.801532911392407, -1.442963103448276 ], [ 29.802071898734177, -1.442963103448276 ], [ 29.802071898734177, -1.442693620689655 ], [ 29.801532911392407, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16657, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.442693620689655 ], [ 29.802071898734177, -1.442963103448276 ], [ 29.802341392405065, -1.442963103448276 ], [ 29.802341392405065, -1.442693620689655 ], [ 29.802071898734177, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16658, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.442693620689655 ], [ 29.802341392405065, -1.442963103448276 ], [ 29.80261088607595, -1.442963103448276 ], [ 29.80261088607595, -1.442693620689655 ], [ 29.802341392405065, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16659, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.442693620689655 ], [ 29.80261088607595, -1.442963103448276 ], [ 29.802880379746835, -1.442963103448276 ], [ 29.802880379746835, -1.442693620689655 ], [ 29.80261088607595, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16660, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.442693620689655 ], [ 29.802880379746835, -1.442963103448276 ], [ 29.803149873417723, -1.442963103448276 ], [ 29.803149873417723, -1.442693620689655 ], [ 29.802880379746835, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16661, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.442693620689655 ], [ 29.803149873417723, -1.442963103448276 ], [ 29.803419367088608, -1.442963103448276 ], [ 29.803419367088608, -1.442693620689655 ], [ 29.803149873417723, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16662, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.442693620689655 ], [ 29.804227848101267, -1.443232586206897 ], [ 29.804497341772151, -1.443232586206897 ], [ 29.804497341772151, -1.442693620689655 ], [ 29.804227848101267, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16663, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.442693620689655 ], [ 29.805036329113925, -1.442963103448276 ], [ 29.80530582278481, -1.442963103448276 ], [ 29.80530582278481, -1.442693620689655 ], [ 29.805036329113925, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16664, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.442693620689655 ], [ 29.80530582278481, -1.442963103448276 ], [ 29.805575316455698, -1.442963103448276 ], [ 29.805575316455698, -1.442693620689655 ], [ 29.80530582278481, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16665, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.442693620689655 ], [ 29.805575316455698, -1.442963103448276 ], [ 29.805844810126583, -1.442963103448276 ], [ 29.805844810126583, -1.442693620689655 ], [ 29.805575316455698, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16666, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.442693620689655 ], [ 29.805844810126583, -1.442963103448276 ], [ 29.806114303797468, -1.442963103448276 ], [ 29.806114303797468, -1.442693620689655 ], [ 29.805844810126583, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16667, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.442963103448276 ], [ 29.747903670886075, -1.443232586206897 ], [ 29.74817316455696, -1.443232586206897 ], [ 29.74817316455696, -1.442963103448276 ], [ 29.747903670886075, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16668, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.442963103448276 ], [ 29.74817316455696, -1.443232586206897 ], [ 29.748442658227848, -1.443232586206897 ], [ 29.748442658227848, -1.442963103448276 ], [ 29.74817316455696, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16669, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.442963103448276 ], [ 29.748442658227848, -1.443232586206897 ], [ 29.748712151898733, -1.443232586206897 ], [ 29.748712151898733, -1.442963103448276 ], [ 29.748442658227848, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16670, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.442963103448276 ], [ 29.748712151898733, -1.443502068965517 ], [ 29.748981645569618, -1.443502068965517 ], [ 29.748981645569618, -1.442963103448276 ], [ 29.748712151898733, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16671, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.442963103448276 ], [ 29.748981645569618, -1.443232586206897 ], [ 29.749251139240506, -1.443232586206897 ], [ 29.749251139240506, -1.442963103448276 ], [ 29.748981645569618, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16672, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.442963103448276 ], [ 29.749251139240506, -1.443232586206897 ], [ 29.749520632911391, -1.443232586206897 ], [ 29.749520632911391, -1.442963103448276 ], [ 29.749251139240506, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16673, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.442963103448276 ], [ 29.750059620253165, -1.443502068965517 ], [ 29.75032911392405, -1.443502068965517 ], [ 29.75032911392405, -1.442963103448276 ], [ 29.750059620253165, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16674, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.442963103448276 ], [ 29.751676582278481, -1.443771551724138 ], [ 29.751946075949366, -1.443771551724138 ], [ 29.751946075949366, -1.442963103448276 ], [ 29.751676582278481, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16675, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.442963103448276 ], [ 29.75248506329114, -1.443232586206897 ], [ 29.752754556962024, -1.443232586206897 ], [ 29.752754556962024, -1.442963103448276 ], [ 29.75248506329114, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16676, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.442963103448276 ], [ 29.753293544303798, -1.443232586206897 ], [ 29.753563037974683, -1.443232586206897 ], [ 29.753563037974683, -1.442963103448276 ], [ 29.753293544303798, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16677, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.442963103448276 ], [ 29.753832531645568, -1.443232586206897 ], [ 29.754102025316456, -1.443232586206897 ], [ 29.754102025316456, -1.442963103448276 ], [ 29.753832531645568, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16678, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.442963103448276 ], [ 29.754102025316456, -1.443232586206897 ], [ 29.754371518987341, -1.443232586206897 ], [ 29.754371518987341, -1.442963103448276 ], [ 29.754102025316456, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16679, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.442963103448276 ], [ 29.754371518987341, -1.443232586206897 ], [ 29.754641012658226, -1.443232586206897 ], [ 29.754641012658226, -1.442963103448276 ], [ 29.754371518987341, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16680, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.442963103448276 ], [ 29.754641012658226, -1.443232586206897 ], [ 29.754910506329114, -1.443232586206897 ], [ 29.754910506329114, -1.442963103448276 ], [ 29.754641012658226, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16681, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.442963103448276 ], [ 29.754910506329114, -1.443232586206897 ], [ 29.75518, -1.443232586206897 ], [ 29.75518, -1.442963103448276 ], [ 29.754910506329114, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16682, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.442963103448276 ], [ 29.75518, -1.443232586206897 ], [ 29.755449493670884, -1.443232586206897 ], [ 29.755449493670884, -1.442963103448276 ], [ 29.75518, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16683, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.442963103448276 ], [ 29.755449493670884, -1.443232586206897 ], [ 29.755718987341773, -1.443232586206897 ], [ 29.755718987341773, -1.442963103448276 ], [ 29.755449493670884, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16684, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.442963103448276 ], [ 29.755718987341773, -1.443232586206897 ], [ 29.755988481012658, -1.443232586206897 ], [ 29.755988481012658, -1.442963103448276 ], [ 29.755718987341773, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16685, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.442963103448276 ], [ 29.755988481012658, -1.443232586206897 ], [ 29.756257974683542, -1.443232586206897 ], [ 29.756257974683542, -1.442963103448276 ], [ 29.755988481012658, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16686, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.442963103448276 ], [ 29.756257974683542, -1.443232586206897 ], [ 29.756796962025316, -1.443232586206897 ], [ 29.756796962025316, -1.442963103448276 ], [ 29.756257974683542, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16687, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.442963103448276 ], [ 29.756796962025316, -1.443232586206897 ], [ 29.757066455696201, -1.443232586206897 ], [ 29.757066455696201, -1.442963103448276 ], [ 29.756796962025316, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16688, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.442963103448276 ], [ 29.757066455696201, -1.443232586206897 ], [ 29.757335949367089, -1.443232586206897 ], [ 29.757335949367089, -1.442963103448276 ], [ 29.757066455696201, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16689, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.442963103448276 ], [ 29.757335949367089, -1.443232586206897 ], [ 29.757605443037974, -1.443232586206897 ], [ 29.757605443037974, -1.442963103448276 ], [ 29.757335949367089, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16690, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.442963103448276 ], [ 29.757605443037974, -1.443232586206897 ], [ 29.757874936708859, -1.443232586206897 ], [ 29.757874936708859, -1.442963103448276 ], [ 29.757605443037974, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16691, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.442963103448276 ], [ 29.757874936708859, -1.443232586206897 ], [ 29.758144430379748, -1.443232586206897 ], [ 29.758144430379748, -1.442963103448276 ], [ 29.757874936708859, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16692, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.442963103448276 ], [ 29.758144430379748, -1.443232586206897 ], [ 29.758413924050632, -1.443232586206897 ], [ 29.758413924050632, -1.442963103448276 ], [ 29.758144430379748, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16693, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.442963103448276 ], [ 29.758413924050632, -1.443232586206897 ], [ 29.758952911392406, -1.443232586206897 ], [ 29.758952911392406, -1.442963103448276 ], [ 29.758413924050632, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16694, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.442963103448276 ], [ 29.758952911392406, -1.443232586206897 ], [ 29.761917341772151, -1.443232586206897 ], [ 29.761917341772151, -1.442963103448276 ], [ 29.758952911392406, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16695, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.442963103448276 ], [ 29.761917341772151, -1.443232586206897 ], [ 29.762456329113924, -1.443232586206897 ], [ 29.762456329113924, -1.442963103448276 ], [ 29.761917341772151, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16696, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.442963103448276 ], [ 29.762456329113924, -1.443232586206897 ], [ 29.762725822784809, -1.443232586206897 ], [ 29.762725822784809, -1.442963103448276 ], [ 29.762456329113924, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16697, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.442963103448276 ], [ 29.762725822784809, -1.443232586206897 ], [ 29.762995316455697, -1.443232586206897 ], [ 29.762995316455697, -1.442963103448276 ], [ 29.762725822784809, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16698, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.442963103448276 ], [ 29.762995316455697, -1.443232586206897 ], [ 29.763264810126582, -1.443232586206897 ], [ 29.763264810126582, -1.442963103448276 ], [ 29.762995316455697, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16699, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.442963103448276 ], [ 29.763264810126582, -1.443232586206897 ], [ 29.763534303797467, -1.443232586206897 ], [ 29.763534303797467, -1.442963103448276 ], [ 29.763264810126582, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16700, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.442963103448276 ], [ 29.763534303797467, -1.443232586206897 ], [ 29.763803797468356, -1.443232586206897 ], [ 29.763803797468356, -1.442963103448276 ], [ 29.763534303797467, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16701, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.442963103448276 ], [ 29.763803797468356, -1.443232586206897 ], [ 29.76407329113924, -1.443232586206897 ], [ 29.76407329113924, -1.442963103448276 ], [ 29.763803797468356, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16702, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.442963103448276 ], [ 29.76407329113924, -1.443232586206897 ], [ 29.764342784810125, -1.443232586206897 ], [ 29.764342784810125, -1.442963103448276 ], [ 29.76407329113924, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16703, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.442963103448276 ], [ 29.764342784810125, -1.443232586206897 ], [ 29.764612278481014, -1.443232586206897 ], [ 29.764612278481014, -1.442963103448276 ], [ 29.764342784810125, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16704, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.442963103448276 ], [ 29.764612278481014, -1.443232586206897 ], [ 29.764881772151899, -1.443232586206897 ], [ 29.764881772151899, -1.442963103448276 ], [ 29.764612278481014, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16705, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.442963103448276 ], [ 29.764881772151899, -1.443232586206897 ], [ 29.765151265822784, -1.443232586206897 ], [ 29.765151265822784, -1.442963103448276 ], [ 29.764881772151899, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16706, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.442963103448276 ], [ 29.765151265822784, -1.443232586206897 ], [ 29.765420759493672, -1.443232586206897 ], [ 29.765420759493672, -1.442963103448276 ], [ 29.765151265822784, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16707, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.442963103448276 ], [ 29.765420759493672, -1.443232586206897 ], [ 29.767846202531643, -1.443232586206897 ], [ 29.767846202531643, -1.442963103448276 ], [ 29.765420759493672, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16708, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.442963103448276 ], [ 29.767846202531643, -1.443232586206897 ], [ 29.768385189873417, -1.443232586206897 ], [ 29.768385189873417, -1.442963103448276 ], [ 29.767846202531643, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16709, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.442963103448276 ], [ 29.768385189873417, -1.443232586206897 ], [ 29.768654683544302, -1.443232586206897 ], [ 29.768654683544302, -1.442963103448276 ], [ 29.768385189873417, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16710, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.442963103448276 ], [ 29.768654683544302, -1.443232586206897 ], [ 29.769193670886075, -1.443232586206897 ], [ 29.769193670886075, -1.442963103448276 ], [ 29.768654683544302, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16711, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.442963103448276 ], [ 29.769193670886075, -1.443232586206897 ], [ 29.76946316455696, -1.443232586206897 ], [ 29.76946316455696, -1.442963103448276 ], [ 29.769193670886075, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16712, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.442963103448276 ], [ 29.76946316455696, -1.443232586206897 ], [ 29.769732658227849, -1.443232586206897 ], [ 29.769732658227849, -1.442963103448276 ], [ 29.76946316455696, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16713, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.442963103448276 ], [ 29.769732658227849, -1.443232586206897 ], [ 29.770002151898733, -1.443232586206897 ], [ 29.770002151898733, -1.442963103448276 ], [ 29.769732658227849, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16714, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.442963103448276 ], [ 29.770002151898733, -1.443232586206897 ], [ 29.770271645569618, -1.443232586206897 ], [ 29.770271645569618, -1.442963103448276 ], [ 29.770002151898733, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16715, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.442963103448276 ], [ 29.770271645569618, -1.443232586206897 ], [ 29.770541139240507, -1.443232586206897 ], [ 29.770541139240507, -1.442963103448276 ], [ 29.770271645569618, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16716, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.442963103448276 ], [ 29.770541139240507, -1.443232586206897 ], [ 29.770810632911392, -1.443232586206897 ], [ 29.770810632911392, -1.442963103448276 ], [ 29.770541139240507, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16717, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.442963103448276 ], [ 29.770810632911392, -1.443232586206897 ], [ 29.771080126582277, -1.443232586206897 ], [ 29.771080126582277, -1.442963103448276 ], [ 29.770810632911392, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16718, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.442963103448276 ], [ 29.771080126582277, -1.443232586206897 ], [ 29.771349620253165, -1.443232586206897 ], [ 29.771349620253165, -1.442963103448276 ], [ 29.771080126582277, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16719, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.442963103448276 ], [ 29.771349620253165, -1.443232586206897 ], [ 29.77161911392405, -1.443232586206897 ], [ 29.77161911392405, -1.442963103448276 ], [ 29.771349620253165, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16720, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.442963103448276 ], [ 29.77161911392405, -1.443232586206897 ], [ 29.771888607594935, -1.443232586206897 ], [ 29.771888607594935, -1.442963103448276 ], [ 29.77161911392405, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16721, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.442963103448276 ], [ 29.771888607594935, -1.443232586206897 ], [ 29.772158101265823, -1.443232586206897 ], [ 29.772158101265823, -1.442963103448276 ], [ 29.771888607594935, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16722, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.442963103448276 ], [ 29.772158101265823, -1.443232586206897 ], [ 29.772427594936708, -1.443232586206897 ], [ 29.772427594936708, -1.442963103448276 ], [ 29.772158101265823, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16723, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.442963103448276 ], [ 29.772427594936708, -1.443232586206897 ], [ 29.772697088607593, -1.443232586206897 ], [ 29.772697088607593, -1.442963103448276 ], [ 29.772427594936708, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16724, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.442963103448276 ], [ 29.772697088607593, -1.443232586206897 ], [ 29.772966582278482, -1.443232586206897 ], [ 29.772966582278482, -1.442963103448276 ], [ 29.772697088607593, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16725, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.442963103448276 ], [ 29.772966582278482, -1.443232586206897 ], [ 29.773236075949367, -1.443232586206897 ], [ 29.773236075949367, -1.442963103448276 ], [ 29.772966582278482, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16726, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.442963103448276 ], [ 29.773236075949367, -1.443232586206897 ], [ 29.773505569620252, -1.443232586206897 ], [ 29.773505569620252, -1.442963103448276 ], [ 29.773236075949367, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16727, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.442963103448276 ], [ 29.773505569620252, -1.443232586206897 ], [ 29.77377506329114, -1.443232586206897 ], [ 29.77377506329114, -1.442963103448276 ], [ 29.773505569620252, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16728, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.442963103448276 ], [ 29.77377506329114, -1.443232586206897 ], [ 29.774044556962025, -1.443232586206897 ], [ 29.774044556962025, -1.442963103448276 ], [ 29.77377506329114, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16729, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.442963103448276 ], [ 29.774044556962025, -1.443232586206897 ], [ 29.77431405063291, -1.443232586206897 ], [ 29.77431405063291, -1.442963103448276 ], [ 29.774044556962025, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16730, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.442963103448276 ], [ 29.77431405063291, -1.443232586206897 ], [ 29.774853037974683, -1.443232586206897 ], [ 29.774853037974683, -1.442963103448276 ], [ 29.77431405063291, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16731, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.442963103448276 ], [ 29.774853037974683, -1.443232586206897 ], [ 29.775122531645568, -1.443232586206897 ], [ 29.775122531645568, -1.442963103448276 ], [ 29.774853037974683, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16732, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.442963103448276 ], [ 29.775122531645568, -1.443232586206897 ], [ 29.775392025316457, -1.443232586206897 ], [ 29.775392025316457, -1.442963103448276 ], [ 29.775122531645568, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16733, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.442963103448276 ], [ 29.775392025316457, -1.443232586206897 ], [ 29.775661518987341, -1.443232586206897 ], [ 29.775661518987341, -1.442963103448276 ], [ 29.775392025316457, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16734, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.442963103448276 ], [ 29.775661518987341, -1.443232586206897 ], [ 29.775931012658226, -1.443232586206897 ], [ 29.775931012658226, -1.442963103448276 ], [ 29.775661518987341, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16735, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.442963103448276 ], [ 29.775931012658226, -1.443232586206897 ], [ 29.776200506329115, -1.443232586206897 ], [ 29.776200506329115, -1.442963103448276 ], [ 29.775931012658226, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16736, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.442963103448276 ], [ 29.776200506329115, -1.443232586206897 ], [ 29.77647, -1.443232586206897 ], [ 29.77647, -1.442963103448276 ], [ 29.776200506329115, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16737, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.442963103448276 ], [ 29.783207341772151, -1.443232586206897 ], [ 29.783476835443039, -1.443232586206897 ], [ 29.783476835443039, -1.442963103448276 ], [ 29.783207341772151, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16738, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.442963103448276 ], [ 29.784824303797468, -1.443232586206897 ], [ 29.785093797468356, -1.443232586206897 ], [ 29.785093797468356, -1.442963103448276 ], [ 29.784824303797468, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16739, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.442963103448276 ], [ 29.785632784810126, -1.443232586206897 ], [ 29.785902278481014, -1.443232586206897 ], [ 29.785902278481014, -1.442963103448276 ], [ 29.785632784810126, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16740, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.442963103448276 ], [ 29.785902278481014, -1.443232586206897 ], [ 29.786171772151899, -1.443232586206897 ], [ 29.786171772151899, -1.442963103448276 ], [ 29.785902278481014, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16741, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.442963103448276 ], [ 29.786171772151899, -1.443232586206897 ], [ 29.786441265822784, -1.443232586206897 ], [ 29.786441265822784, -1.442963103448276 ], [ 29.786171772151899, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16742, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.442963103448276 ], [ 29.80045493670886, -1.443502068965517 ], [ 29.800724430379748, -1.443502068965517 ], [ 29.800724430379748, -1.442963103448276 ], [ 29.80045493670886, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16743, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.442963103448276 ], [ 29.800724430379748, -1.443232586206897 ], [ 29.800993924050633, -1.443232586206897 ], [ 29.800993924050633, -1.442963103448276 ], [ 29.800724430379748, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16744, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.442963103448276 ], [ 29.800993924050633, -1.443232586206897 ], [ 29.801263417721518, -1.443232586206897 ], [ 29.801263417721518, -1.442963103448276 ], [ 29.800993924050633, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16745, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.442963103448276 ], [ 29.801263417721518, -1.443232586206897 ], [ 29.801532911392407, -1.443232586206897 ], [ 29.801532911392407, -1.442963103448276 ], [ 29.801263417721518, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16746, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.442963103448276 ], [ 29.801532911392407, -1.443232586206897 ], [ 29.802071898734177, -1.443232586206897 ], [ 29.802071898734177, -1.442963103448276 ], [ 29.801532911392407, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16747, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.442963103448276 ], [ 29.802071898734177, -1.443232586206897 ], [ 29.802341392405065, -1.443232586206897 ], [ 29.802341392405065, -1.442963103448276 ], [ 29.802071898734177, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16748, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.442963103448276 ], [ 29.802341392405065, -1.443232586206897 ], [ 29.80261088607595, -1.443232586206897 ], [ 29.80261088607595, -1.442963103448276 ], [ 29.802341392405065, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16749, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.442963103448276 ], [ 29.80261088607595, -1.443232586206897 ], [ 29.802880379746835, -1.443232586206897 ], [ 29.802880379746835, -1.442963103448276 ], [ 29.80261088607595, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16750, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.442963103448276 ], [ 29.802880379746835, -1.443232586206897 ], [ 29.803149873417723, -1.443232586206897 ], [ 29.803149873417723, -1.442963103448276 ], [ 29.802880379746835, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16751, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.442963103448276 ], [ 29.803149873417723, -1.443232586206897 ], [ 29.803419367088608, -1.443232586206897 ], [ 29.803419367088608, -1.442963103448276 ], [ 29.803149873417723, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16752, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.442963103448276 ], [ 29.803688860759493, -1.443232586206897 ], [ 29.803958354430382, -1.443232586206897 ], [ 29.803958354430382, -1.442963103448276 ], [ 29.803688860759493, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16753, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.442963103448276 ], [ 29.803958354430382, -1.443232586206897 ], [ 29.804227848101267, -1.443232586206897 ], [ 29.804227848101267, -1.442963103448276 ], [ 29.803958354430382, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16754, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.442963103448276 ], [ 29.804497341772151, -1.443232586206897 ], [ 29.80476683544304, -1.443232586206897 ], [ 29.80476683544304, -1.442963103448276 ], [ 29.804497341772151, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16755, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.442963103448276 ], [ 29.80476683544304, -1.443232586206897 ], [ 29.805036329113925, -1.443232586206897 ], [ 29.805036329113925, -1.442963103448276 ], [ 29.80476683544304, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16756, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.442963103448276 ], [ 29.805036329113925, -1.443232586206897 ], [ 29.80530582278481, -1.443232586206897 ], [ 29.80530582278481, -1.442963103448276 ], [ 29.805036329113925, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16757, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.442963103448276 ], [ 29.80530582278481, -1.443232586206897 ], [ 29.805575316455698, -1.443232586206897 ], [ 29.805575316455698, -1.442963103448276 ], [ 29.80530582278481, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16758, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.442963103448276 ], [ 29.805575316455698, -1.443232586206897 ], [ 29.805844810126583, -1.443232586206897 ], [ 29.805844810126583, -1.442963103448276 ], [ 29.805575316455698, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16759, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.442963103448276 ], [ 29.805844810126583, -1.443232586206897 ], [ 29.806114303797468, -1.443232586206897 ], [ 29.806114303797468, -1.442963103448276 ], [ 29.805844810126583, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16760, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.442963103448276 ], [ 29.806114303797468, -1.443232586206897 ], [ 29.806383797468357, -1.443232586206897 ], [ 29.806383797468357, -1.442963103448276 ], [ 29.806114303797468, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16761, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.443232586206897 ], [ 29.747095189873416, -1.443502068965517 ], [ 29.747364683544301, -1.443502068965517 ], [ 29.747364683544301, -1.443232586206897 ], [ 29.747095189873416, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16762, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.443232586206897 ], [ 29.747364683544301, -1.443502068965517 ], [ 29.747903670886075, -1.443502068965517 ], [ 29.747903670886075, -1.443232586206897 ], [ 29.747364683544301, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16763, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.443232586206897 ], [ 29.747903670886075, -1.443502068965517 ], [ 29.74817316455696, -1.443502068965517 ], [ 29.74817316455696, -1.443232586206897 ], [ 29.747903670886075, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16764, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.443232586206897 ], [ 29.751407088607593, -1.443771551724138 ], [ 29.751676582278481, -1.443771551724138 ], [ 29.751676582278481, -1.443232586206897 ], [ 29.751407088607593, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16765, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.443232586206897 ], [ 29.751946075949366, -1.443502068965517 ], [ 29.752215569620251, -1.443502068965517 ], [ 29.752215569620251, -1.443232586206897 ], [ 29.751946075949366, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16766, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.443232586206897 ], [ 29.75248506329114, -1.443502068965517 ], [ 29.752754556962024, -1.443502068965517 ], [ 29.752754556962024, -1.443232586206897 ], [ 29.75248506329114, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16767, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.443232586206897 ], [ 29.752754556962024, -1.443502068965517 ], [ 29.753024050632909, -1.443502068965517 ], [ 29.753024050632909, -1.443232586206897 ], [ 29.752754556962024, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16768, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.443232586206897 ], [ 29.753024050632909, -1.443502068965517 ], [ 29.753293544303798, -1.443502068965517 ], [ 29.753293544303798, -1.443232586206897 ], [ 29.753024050632909, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16769, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.443232586206897 ], [ 29.753293544303798, -1.443502068965517 ], [ 29.753563037974683, -1.443502068965517 ], [ 29.753563037974683, -1.443232586206897 ], [ 29.753293544303798, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16770, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.443232586206897 ], [ 29.753563037974683, -1.443502068965517 ], [ 29.753832531645568, -1.443502068965517 ], [ 29.753832531645568, -1.443232586206897 ], [ 29.753563037974683, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16771, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.443232586206897 ], [ 29.753832531645568, -1.443502068965517 ], [ 29.754102025316456, -1.443502068965517 ], [ 29.754102025316456, -1.443232586206897 ], [ 29.753832531645568, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16772, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.443232586206897 ], [ 29.754102025316456, -1.443502068965517 ], [ 29.754371518987341, -1.443502068965517 ], [ 29.754371518987341, -1.443232586206897 ], [ 29.754102025316456, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16773, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.443232586206897 ], [ 29.754371518987341, -1.443502068965517 ], [ 29.754641012658226, -1.443502068965517 ], [ 29.754641012658226, -1.443232586206897 ], [ 29.754371518987341, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16774, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.443232586206897 ], [ 29.754641012658226, -1.443502068965517 ], [ 29.754910506329114, -1.443502068965517 ], [ 29.754910506329114, -1.443232586206897 ], [ 29.754641012658226, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16775, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.443232586206897 ], [ 29.754910506329114, -1.443502068965517 ], [ 29.75518, -1.443502068965517 ], [ 29.75518, -1.443232586206897 ], [ 29.754910506329114, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16776, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.443232586206897 ], [ 29.75518, -1.443502068965517 ], [ 29.755449493670884, -1.443502068965517 ], [ 29.755449493670884, -1.443232586206897 ], [ 29.75518, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16777, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.443232586206897 ], [ 29.755449493670884, -1.443502068965517 ], [ 29.755718987341773, -1.443502068965517 ], [ 29.755718987341773, -1.443232586206897 ], [ 29.755449493670884, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16778, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.443232586206897 ], [ 29.755718987341773, -1.443502068965517 ], [ 29.755988481012658, -1.443502068965517 ], [ 29.755988481012658, -1.443232586206897 ], [ 29.755718987341773, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16779, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.443232586206897 ], [ 29.755988481012658, -1.443502068965517 ], [ 29.756257974683542, -1.443502068965517 ], [ 29.756257974683542, -1.443232586206897 ], [ 29.755988481012658, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16780, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.443232586206897 ], [ 29.756257974683542, -1.443502068965517 ], [ 29.756796962025316, -1.443502068965517 ], [ 29.756796962025316, -1.443232586206897 ], [ 29.756257974683542, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16781, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.443232586206897 ], [ 29.756796962025316, -1.443502068965517 ], [ 29.757066455696201, -1.443502068965517 ], [ 29.757066455696201, -1.443232586206897 ], [ 29.756796962025316, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16782, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.443232586206897 ], [ 29.757066455696201, -1.443502068965517 ], [ 29.757335949367089, -1.443502068965517 ], [ 29.757335949367089, -1.443232586206897 ], [ 29.757066455696201, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16783, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.443232586206897 ], [ 29.757335949367089, -1.443502068965517 ], [ 29.757605443037974, -1.443502068965517 ], [ 29.757605443037974, -1.443232586206897 ], [ 29.757335949367089, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16784, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.443232586206897 ], [ 29.757605443037974, -1.443502068965517 ], [ 29.757874936708859, -1.443502068965517 ], [ 29.757874936708859, -1.443232586206897 ], [ 29.757605443037974, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16785, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.443232586206897 ], [ 29.757874936708859, -1.443502068965517 ], [ 29.758144430379748, -1.443502068965517 ], [ 29.758144430379748, -1.443232586206897 ], [ 29.757874936708859, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16786, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.443232586206897 ], [ 29.758144430379748, -1.443502068965517 ], [ 29.758683417721517, -1.443502068965517 ], [ 29.758683417721517, -1.443232586206897 ], [ 29.758144430379748, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16787, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.443232586206897 ], [ 29.758683417721517, -1.443502068965517 ], [ 29.762186835443039, -1.443502068965517 ], [ 29.762186835443039, -1.443232586206897 ], [ 29.758683417721517, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16788, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.443232586206897 ], [ 29.762186835443039, -1.443502068965517 ], [ 29.762725822784809, -1.443502068965517 ], [ 29.762725822784809, -1.443232586206897 ], [ 29.762186835443039, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16789, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.443232586206897 ], [ 29.762725822784809, -1.443502068965517 ], [ 29.762995316455697, -1.443502068965517 ], [ 29.762995316455697, -1.443232586206897 ], [ 29.762725822784809, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16790, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.443232586206897 ], [ 29.762995316455697, -1.443502068965517 ], [ 29.763264810126582, -1.443502068965517 ], [ 29.763264810126582, -1.443232586206897 ], [ 29.762995316455697, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16791, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.443232586206897 ], [ 29.763264810126582, -1.443502068965517 ], [ 29.763534303797467, -1.443502068965517 ], [ 29.763534303797467, -1.443232586206897 ], [ 29.763264810126582, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16792, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.443232586206897 ], [ 29.763534303797467, -1.443502068965517 ], [ 29.763803797468356, -1.443502068965517 ], [ 29.763803797468356, -1.443232586206897 ], [ 29.763534303797467, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16793, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.443232586206897 ], [ 29.763803797468356, -1.443502068965517 ], [ 29.76407329113924, -1.443502068965517 ], [ 29.76407329113924, -1.443232586206897 ], [ 29.763803797468356, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16794, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.443232586206897 ], [ 29.76407329113924, -1.443771551724138 ], [ 29.764342784810125, -1.443771551724138 ], [ 29.764342784810125, -1.443232586206897 ], [ 29.76407329113924, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16795, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.443232586206897 ], [ 29.764342784810125, -1.443502068965517 ], [ 29.765151265822784, -1.443502068965517 ], [ 29.765151265822784, -1.443232586206897 ], [ 29.764342784810125, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16796, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.443232586206897 ], [ 29.765151265822784, -1.443502068965517 ], [ 29.768115696202532, -1.443502068965517 ], [ 29.768115696202532, -1.443232586206897 ], [ 29.765151265822784, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16797, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.443232586206897 ], [ 29.768115696202532, -1.443502068965517 ], [ 29.768654683544302, -1.443502068965517 ], [ 29.768654683544302, -1.443232586206897 ], [ 29.768115696202532, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16798, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.443232586206897 ], [ 29.768654683544302, -1.443502068965517 ], [ 29.76892417721519, -1.443502068965517 ], [ 29.76892417721519, -1.443232586206897 ], [ 29.768654683544302, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16799, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.443232586206897 ], [ 29.76892417721519, -1.443502068965517 ], [ 29.769193670886075, -1.443502068965517 ], [ 29.769193670886075, -1.443232586206897 ], [ 29.76892417721519, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16800, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.443232586206897 ], [ 29.769193670886075, -1.443502068965517 ], [ 29.76946316455696, -1.443502068965517 ], [ 29.76946316455696, -1.443232586206897 ], [ 29.769193670886075, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16801, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.443232586206897 ], [ 29.76946316455696, -1.443502068965517 ], [ 29.769732658227849, -1.443502068965517 ], [ 29.769732658227849, -1.443232586206897 ], [ 29.76946316455696, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16802, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.443232586206897 ], [ 29.769732658227849, -1.443502068965517 ], [ 29.770002151898733, -1.443502068965517 ], [ 29.770002151898733, -1.443232586206897 ], [ 29.769732658227849, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16803, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.443232586206897 ], [ 29.770002151898733, -1.443502068965517 ], [ 29.770271645569618, -1.443502068965517 ], [ 29.770271645569618, -1.443232586206897 ], [ 29.770002151898733, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16804, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.443232586206897 ], [ 29.770271645569618, -1.443502068965517 ], [ 29.770541139240507, -1.443502068965517 ], [ 29.770541139240507, -1.443232586206897 ], [ 29.770271645569618, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16805, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.443232586206897 ], [ 29.770541139240507, -1.443502068965517 ], [ 29.770810632911392, -1.443502068965517 ], [ 29.770810632911392, -1.443232586206897 ], [ 29.770541139240507, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16806, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.443232586206897 ], [ 29.770810632911392, -1.443502068965517 ], [ 29.771080126582277, -1.443502068965517 ], [ 29.771080126582277, -1.443232586206897 ], [ 29.770810632911392, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16807, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.443232586206897 ], [ 29.771080126582277, -1.443502068965517 ], [ 29.771349620253165, -1.443502068965517 ], [ 29.771349620253165, -1.443232586206897 ], [ 29.771080126582277, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16808, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.443232586206897 ], [ 29.771349620253165, -1.443502068965517 ], [ 29.77161911392405, -1.443502068965517 ], [ 29.77161911392405, -1.443232586206897 ], [ 29.771349620253165, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16809, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.443232586206897 ], [ 29.77161911392405, -1.443502068965517 ], [ 29.771888607594935, -1.443502068965517 ], [ 29.771888607594935, -1.443232586206897 ], [ 29.77161911392405, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16810, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.443232586206897 ], [ 29.771888607594935, -1.443502068965517 ], [ 29.772158101265823, -1.443502068965517 ], [ 29.772158101265823, -1.443232586206897 ], [ 29.771888607594935, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16811, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.443232586206897 ], [ 29.772158101265823, -1.443502068965517 ], [ 29.772427594936708, -1.443502068965517 ], [ 29.772427594936708, -1.443232586206897 ], [ 29.772158101265823, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16812, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.443232586206897 ], [ 29.772427594936708, -1.443502068965517 ], [ 29.772697088607593, -1.443502068965517 ], [ 29.772697088607593, -1.443232586206897 ], [ 29.772427594936708, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16813, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.443232586206897 ], [ 29.772697088607593, -1.443502068965517 ], [ 29.772966582278482, -1.443502068965517 ], [ 29.772966582278482, -1.443232586206897 ], [ 29.772697088607593, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16814, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.443232586206897 ], [ 29.772966582278482, -1.443502068965517 ], [ 29.773236075949367, -1.443502068965517 ], [ 29.773236075949367, -1.443232586206897 ], [ 29.772966582278482, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16815, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.443232586206897 ], [ 29.773236075949367, -1.443502068965517 ], [ 29.773505569620252, -1.443502068965517 ], [ 29.773505569620252, -1.443232586206897 ], [ 29.773236075949367, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16816, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.443232586206897 ], [ 29.773505569620252, -1.443502068965517 ], [ 29.77377506329114, -1.443502068965517 ], [ 29.77377506329114, -1.443232586206897 ], [ 29.773505569620252, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16817, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.443232586206897 ], [ 29.77377506329114, -1.443502068965517 ], [ 29.774044556962025, -1.443502068965517 ], [ 29.774044556962025, -1.443232586206897 ], [ 29.77377506329114, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16818, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.443232586206897 ], [ 29.774044556962025, -1.443502068965517 ], [ 29.77431405063291, -1.443502068965517 ], [ 29.77431405063291, -1.443232586206897 ], [ 29.774044556962025, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16819, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.443232586206897 ], [ 29.77431405063291, -1.443502068965517 ], [ 29.774853037974683, -1.443502068965517 ], [ 29.774853037974683, -1.443232586206897 ], [ 29.77431405063291, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16820, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.443232586206897 ], [ 29.774853037974683, -1.443502068965517 ], [ 29.775122531645568, -1.443502068965517 ], [ 29.775122531645568, -1.443232586206897 ], [ 29.774853037974683, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16821, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.443232586206897 ], [ 29.775122531645568, -1.443502068965517 ], [ 29.775392025316457, -1.443502068965517 ], [ 29.775392025316457, -1.443232586206897 ], [ 29.775122531645568, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16822, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.443232586206897 ], [ 29.775392025316457, -1.443502068965517 ], [ 29.775661518987341, -1.443502068965517 ], [ 29.775661518987341, -1.443232586206897 ], [ 29.775392025316457, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16823, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.443232586206897 ], [ 29.775661518987341, -1.443502068965517 ], [ 29.775931012658226, -1.443502068965517 ], [ 29.775931012658226, -1.443232586206897 ], [ 29.775661518987341, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16824, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.443232586206897 ], [ 29.775931012658226, -1.443502068965517 ], [ 29.776200506329115, -1.443502068965517 ], [ 29.776200506329115, -1.443232586206897 ], [ 29.775931012658226, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16825, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.443232586206897 ], [ 29.776200506329115, -1.443502068965517 ], [ 29.77647, -1.443502068965517 ], [ 29.77647, -1.443232586206897 ], [ 29.776200506329115, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16826, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.443232586206897 ], [ 29.783207341772151, -1.443771551724138 ], [ 29.783476835443039, -1.443771551724138 ], [ 29.783476835443039, -1.443232586206897 ], [ 29.783207341772151, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16827, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.443232586206897 ], [ 29.784824303797468, -1.443771551724138 ], [ 29.785093797468356, -1.443771551724138 ], [ 29.785093797468356, -1.443232586206897 ], [ 29.784824303797468, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16828, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.443232586206897 ], [ 29.785632784810126, -1.443502068965517 ], [ 29.785902278481014, -1.443502068965517 ], [ 29.785902278481014, -1.443232586206897 ], [ 29.785632784810126, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16829, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.443232586206897 ], [ 29.785902278481014, -1.443502068965517 ], [ 29.786171772151899, -1.443502068965517 ], [ 29.786171772151899, -1.443232586206897 ], [ 29.785902278481014, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16830, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.443232586206897 ], [ 29.786171772151899, -1.443502068965517 ], [ 29.786441265822784, -1.443502068965517 ], [ 29.786441265822784, -1.443232586206897 ], [ 29.786171772151899, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16831, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.443232586206897 ], [ 29.786441265822784, -1.443771551724138 ], [ 29.786710759493673, -1.443771551724138 ], [ 29.786710759493673, -1.443232586206897 ], [ 29.786441265822784, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16832, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.443232586206897 ], [ 29.793987088607594, -1.443502068965517 ], [ 29.794256582278482, -1.443502068965517 ], [ 29.794256582278482, -1.443232586206897 ], [ 29.793987088607594, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16833, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.443232586206897 ], [ 29.800724430379748, -1.443502068965517 ], [ 29.800993924050633, -1.443502068965517 ], [ 29.800993924050633, -1.443232586206897 ], [ 29.800724430379748, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16834, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.443232586206897 ], [ 29.800993924050633, -1.443502068965517 ], [ 29.801263417721518, -1.443502068965517 ], [ 29.801263417721518, -1.443232586206897 ], [ 29.800993924050633, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16835, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.443232586206897 ], [ 29.801263417721518, -1.443502068965517 ], [ 29.801532911392407, -1.443502068965517 ], [ 29.801532911392407, -1.443232586206897 ], [ 29.801263417721518, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16836, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.443232586206897 ], [ 29.801532911392407, -1.443502068965517 ], [ 29.802071898734177, -1.443502068965517 ], [ 29.802071898734177, -1.443232586206897 ], [ 29.801532911392407, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16837, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.443232586206897 ], [ 29.802071898734177, -1.443502068965517 ], [ 29.802341392405065, -1.443502068965517 ], [ 29.802341392405065, -1.443232586206897 ], [ 29.802071898734177, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16838, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.443232586206897 ], [ 29.802341392405065, -1.443502068965517 ], [ 29.80261088607595, -1.443502068965517 ], [ 29.80261088607595, -1.443232586206897 ], [ 29.802341392405065, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16839, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.443232586206897 ], [ 29.80261088607595, -1.443502068965517 ], [ 29.802880379746835, -1.443502068965517 ], [ 29.802880379746835, -1.443232586206897 ], [ 29.80261088607595, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16840, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.443232586206897 ], [ 29.802880379746835, -1.443502068965517 ], [ 29.803149873417723, -1.443502068965517 ], [ 29.803149873417723, -1.443232586206897 ], [ 29.802880379746835, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16841, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.443232586206897 ], [ 29.803149873417723, -1.443502068965517 ], [ 29.803419367088608, -1.443502068965517 ], [ 29.803419367088608, -1.443232586206897 ], [ 29.803149873417723, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16842, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.443232586206897 ], [ 29.803419367088608, -1.443502068965517 ], [ 29.803688860759493, -1.443502068965517 ], [ 29.803688860759493, -1.443232586206897 ], [ 29.803419367088608, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16843, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.443232586206897 ], [ 29.803688860759493, -1.443502068965517 ], [ 29.803958354430382, -1.443502068965517 ], [ 29.803958354430382, -1.443232586206897 ], [ 29.803688860759493, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16844, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.443232586206897 ], [ 29.803958354430382, -1.443502068965517 ], [ 29.804227848101267, -1.443502068965517 ], [ 29.804227848101267, -1.443232586206897 ], [ 29.803958354430382, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16845, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.443232586206897 ], [ 29.804227848101267, -1.443502068965517 ], [ 29.804497341772151, -1.443502068965517 ], [ 29.804497341772151, -1.443232586206897 ], [ 29.804227848101267, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16846, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.443232586206897 ], [ 29.804497341772151, -1.443502068965517 ], [ 29.80476683544304, -1.443502068965517 ], [ 29.80476683544304, -1.443232586206897 ], [ 29.804497341772151, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16847, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.443232586206897 ], [ 29.80476683544304, -1.443502068965517 ], [ 29.805036329113925, -1.443502068965517 ], [ 29.805036329113925, -1.443232586206897 ], [ 29.80476683544304, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16848, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.443232586206897 ], [ 29.805036329113925, -1.443502068965517 ], [ 29.80530582278481, -1.443502068965517 ], [ 29.80530582278481, -1.443232586206897 ], [ 29.805036329113925, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16849, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.443232586206897 ], [ 29.80530582278481, -1.443502068965517 ], [ 29.805575316455698, -1.443502068965517 ], [ 29.805575316455698, -1.443232586206897 ], [ 29.80530582278481, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16850, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.443232586206897 ], [ 29.805575316455698, -1.443502068965517 ], [ 29.805844810126583, -1.443502068965517 ], [ 29.805844810126583, -1.443232586206897 ], [ 29.805575316455698, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16851, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.443232586206897 ], [ 29.805844810126583, -1.443502068965517 ], [ 29.806114303797468, -1.443502068965517 ], [ 29.806114303797468, -1.443232586206897 ], [ 29.805844810126583, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16852, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.443232586206897 ], [ 29.806114303797468, -1.443502068965517 ], [ 29.806383797468357, -1.443502068965517 ], [ 29.806383797468357, -1.443232586206897 ], [ 29.806114303797468, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16853, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.443232586206897 ], [ 29.806383797468357, -1.443502068965517 ], [ 29.806653291139241, -1.443502068965517 ], [ 29.806653291139241, -1.443232586206897 ], [ 29.806383797468357, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16854, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.443502068965517 ], [ 29.751946075949366, -1.443771551724138 ], [ 29.752215569620251, -1.443771551724138 ], [ 29.752215569620251, -1.443502068965517 ], [ 29.751946075949366, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16855, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.443502068965517 ], [ 29.752215569620251, -1.443771551724138 ], [ 29.75248506329114, -1.443771551724138 ], [ 29.75248506329114, -1.443502068965517 ], [ 29.752215569620251, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16856, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.443502068965517 ], [ 29.75248506329114, -1.443771551724138 ], [ 29.752754556962024, -1.443771551724138 ], [ 29.752754556962024, -1.443502068965517 ], [ 29.75248506329114, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16857, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.443502068965517 ], [ 29.752754556962024, -1.443771551724138 ], [ 29.753024050632909, -1.443771551724138 ], [ 29.753024050632909, -1.443502068965517 ], [ 29.752754556962024, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16858, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.443502068965517 ], [ 29.753024050632909, -1.443771551724138 ], [ 29.753293544303798, -1.443771551724138 ], [ 29.753293544303798, -1.443502068965517 ], [ 29.753024050632909, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16859, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.443502068965517 ], [ 29.753293544303798, -1.443771551724138 ], [ 29.753563037974683, -1.443771551724138 ], [ 29.753563037974683, -1.443502068965517 ], [ 29.753293544303798, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16860, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.443502068965517 ], [ 29.753563037974683, -1.443771551724138 ], [ 29.753832531645568, -1.443771551724138 ], [ 29.753832531645568, -1.443502068965517 ], [ 29.753563037974683, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16861, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.443502068965517 ], [ 29.753832531645568, -1.443771551724138 ], [ 29.754102025316456, -1.443771551724138 ], [ 29.754102025316456, -1.443502068965517 ], [ 29.753832531645568, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16862, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.443502068965517 ], [ 29.754102025316456, -1.443771551724138 ], [ 29.754371518987341, -1.443771551724138 ], [ 29.754371518987341, -1.443502068965517 ], [ 29.754102025316456, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16863, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.443502068965517 ], [ 29.754371518987341, -1.443771551724138 ], [ 29.754641012658226, -1.443771551724138 ], [ 29.754641012658226, -1.443502068965517 ], [ 29.754371518987341, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16864, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.443502068965517 ], [ 29.754641012658226, -1.443771551724138 ], [ 29.754910506329114, -1.443771551724138 ], [ 29.754910506329114, -1.443502068965517 ], [ 29.754641012658226, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16865, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.443502068965517 ], [ 29.754910506329114, -1.443771551724138 ], [ 29.75518, -1.443771551724138 ], [ 29.75518, -1.443502068965517 ], [ 29.754910506329114, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16866, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.443502068965517 ], [ 29.75518, -1.443771551724138 ], [ 29.755449493670884, -1.443771551724138 ], [ 29.755449493670884, -1.443502068965517 ], [ 29.75518, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16867, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.443502068965517 ], [ 29.755449493670884, -1.443771551724138 ], [ 29.755718987341773, -1.443771551724138 ], [ 29.755718987341773, -1.443502068965517 ], [ 29.755449493670884, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16868, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.443502068965517 ], [ 29.755718987341773, -1.443771551724138 ], [ 29.755988481012658, -1.443771551724138 ], [ 29.755988481012658, -1.443502068965517 ], [ 29.755718987341773, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16869, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.443502068965517 ], [ 29.755988481012658, -1.443771551724138 ], [ 29.756257974683542, -1.443771551724138 ], [ 29.756257974683542, -1.443502068965517 ], [ 29.755988481012658, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16870, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.443502068965517 ], [ 29.756257974683542, -1.443771551724138 ], [ 29.756796962025316, -1.443771551724138 ], [ 29.756796962025316, -1.443502068965517 ], [ 29.756257974683542, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16871, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.443502068965517 ], [ 29.756796962025316, -1.443771551724138 ], [ 29.757066455696201, -1.443771551724138 ], [ 29.757066455696201, -1.443502068965517 ], [ 29.756796962025316, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16872, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.443502068965517 ], [ 29.757066455696201, -1.443771551724138 ], [ 29.757335949367089, -1.443771551724138 ], [ 29.757335949367089, -1.443502068965517 ], [ 29.757066455696201, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16873, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.443502068965517 ], [ 29.757335949367089, -1.443771551724138 ], [ 29.757605443037974, -1.443771551724138 ], [ 29.757605443037974, -1.443502068965517 ], [ 29.757335949367089, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16874, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.443502068965517 ], [ 29.757605443037974, -1.443771551724138 ], [ 29.757874936708859, -1.443771551724138 ], [ 29.757874936708859, -1.443502068965517 ], [ 29.757605443037974, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16875, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.443502068965517 ], [ 29.757874936708859, -1.443771551724138 ], [ 29.758144430379748, -1.443771551724138 ], [ 29.758144430379748, -1.443502068965517 ], [ 29.757874936708859, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16876, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.443502068965517 ], [ 29.758144430379748, -1.443771551724138 ], [ 29.758413924050632, -1.443771551724138 ], [ 29.758413924050632, -1.443502068965517 ], [ 29.758144430379748, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16877, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.443502068965517 ], [ 29.758413924050632, -1.443771551724138 ], [ 29.758683417721517, -1.443771551724138 ], [ 29.758683417721517, -1.443502068965517 ], [ 29.758413924050632, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16878, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.443502068965517 ], [ 29.758683417721517, -1.443771551724138 ], [ 29.762186835443039, -1.443771551724138 ], [ 29.762186835443039, -1.443502068965517 ], [ 29.758683417721517, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16879, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.443502068965517 ], [ 29.762186835443039, -1.443771551724138 ], [ 29.762456329113924, -1.443771551724138 ], [ 29.762456329113924, -1.443502068965517 ], [ 29.762186835443039, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16880, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.443502068965517 ], [ 29.762456329113924, -1.443771551724138 ], [ 29.762725822784809, -1.443771551724138 ], [ 29.762725822784809, -1.443502068965517 ], [ 29.762456329113924, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16881, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.443502068965517 ], [ 29.762725822784809, -1.443771551724138 ], [ 29.762995316455697, -1.443771551724138 ], [ 29.762995316455697, -1.443502068965517 ], [ 29.762725822784809, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16882, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.443502068965517 ], [ 29.762995316455697, -1.443771551724138 ], [ 29.763264810126582, -1.443771551724138 ], [ 29.763264810126582, -1.443502068965517 ], [ 29.762995316455697, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16883, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.443502068965517 ], [ 29.763264810126582, -1.443771551724138 ], [ 29.763534303797467, -1.443771551724138 ], [ 29.763534303797467, -1.443502068965517 ], [ 29.763264810126582, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16884, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.443502068965517 ], [ 29.763803797468356, -1.443771551724138 ], [ 29.76407329113924, -1.443771551724138 ], [ 29.76407329113924, -1.443502068965517 ], [ 29.763803797468356, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16885, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.443502068965517 ], [ 29.764342784810125, -1.443771551724138 ], [ 29.764612278481014, -1.443771551724138 ], [ 29.764612278481014, -1.443502068965517 ], [ 29.764342784810125, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16886, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.443502068965517 ], [ 29.764612278481014, -1.443771551724138 ], [ 29.764881772151899, -1.443771551724138 ], [ 29.764881772151899, -1.443502068965517 ], [ 29.764612278481014, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16887, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.443502068965517 ], [ 29.764881772151899, -1.443771551724138 ], [ 29.765420759493672, -1.443771551724138 ], [ 29.765420759493672, -1.443502068965517 ], [ 29.764881772151899, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16888, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.443502068965517 ], [ 29.765420759493672, -1.443771551724138 ], [ 29.767846202531643, -1.443771551724138 ], [ 29.767846202531643, -1.443502068965517 ], [ 29.765420759493672, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16889, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.443502068965517 ], [ 29.767846202531643, -1.443771551724138 ], [ 29.768385189873417, -1.443771551724138 ], [ 29.768385189873417, -1.443502068965517 ], [ 29.767846202531643, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16890, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.443502068965517 ], [ 29.768385189873417, -1.443771551724138 ], [ 29.768654683544302, -1.443771551724138 ], [ 29.768654683544302, -1.443502068965517 ], [ 29.768385189873417, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16891, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.443502068965517 ], [ 29.768654683544302, -1.443771551724138 ], [ 29.76892417721519, -1.443771551724138 ], [ 29.76892417721519, -1.443502068965517 ], [ 29.768654683544302, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16892, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.443502068965517 ], [ 29.76892417721519, -1.443771551724138 ], [ 29.769193670886075, -1.443771551724138 ], [ 29.769193670886075, -1.443502068965517 ], [ 29.76892417721519, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16893, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.443502068965517 ], [ 29.769193670886075, -1.443771551724138 ], [ 29.76946316455696, -1.443771551724138 ], [ 29.76946316455696, -1.443502068965517 ], [ 29.769193670886075, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16894, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.443502068965517 ], [ 29.76946316455696, -1.443771551724138 ], [ 29.769732658227849, -1.443771551724138 ], [ 29.769732658227849, -1.443502068965517 ], [ 29.76946316455696, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16895, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.443502068965517 ], [ 29.769732658227849, -1.443771551724138 ], [ 29.770002151898733, -1.443771551724138 ], [ 29.770002151898733, -1.443502068965517 ], [ 29.769732658227849, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16896, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.443502068965517 ], [ 29.770002151898733, -1.443771551724138 ], [ 29.770271645569618, -1.443771551724138 ], [ 29.770271645569618, -1.443502068965517 ], [ 29.770002151898733, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16897, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.443502068965517 ], [ 29.770271645569618, -1.443771551724138 ], [ 29.770541139240507, -1.443771551724138 ], [ 29.770541139240507, -1.443502068965517 ], [ 29.770271645569618, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16898, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.443502068965517 ], [ 29.770541139240507, -1.443771551724138 ], [ 29.770810632911392, -1.443771551724138 ], [ 29.770810632911392, -1.443502068965517 ], [ 29.770541139240507, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16899, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.443502068965517 ], [ 29.770810632911392, -1.443771551724138 ], [ 29.771080126582277, -1.443771551724138 ], [ 29.771080126582277, -1.443502068965517 ], [ 29.770810632911392, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16900, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.443502068965517 ], [ 29.771080126582277, -1.443771551724138 ], [ 29.771349620253165, -1.443771551724138 ], [ 29.771349620253165, -1.443502068965517 ], [ 29.771080126582277, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16901, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.443502068965517 ], [ 29.771349620253165, -1.443771551724138 ], [ 29.77161911392405, -1.443771551724138 ], [ 29.77161911392405, -1.443502068965517 ], [ 29.771349620253165, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16902, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.443502068965517 ], [ 29.77161911392405, -1.443771551724138 ], [ 29.771888607594935, -1.443771551724138 ], [ 29.771888607594935, -1.443502068965517 ], [ 29.77161911392405, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16903, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.443502068965517 ], [ 29.771888607594935, -1.443771551724138 ], [ 29.772158101265823, -1.443771551724138 ], [ 29.772158101265823, -1.443502068965517 ], [ 29.771888607594935, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16904, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.443502068965517 ], [ 29.772158101265823, -1.443771551724138 ], [ 29.772427594936708, -1.443771551724138 ], [ 29.772427594936708, -1.443502068965517 ], [ 29.772158101265823, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16905, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.443502068965517 ], [ 29.772427594936708, -1.443771551724138 ], [ 29.772697088607593, -1.443771551724138 ], [ 29.772697088607593, -1.443502068965517 ], [ 29.772427594936708, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16906, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.443502068965517 ], [ 29.772697088607593, -1.443771551724138 ], [ 29.772966582278482, -1.443771551724138 ], [ 29.772966582278482, -1.443502068965517 ], [ 29.772697088607593, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16907, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.443502068965517 ], [ 29.772966582278482, -1.443771551724138 ], [ 29.773236075949367, -1.443771551724138 ], [ 29.773236075949367, -1.443502068965517 ], [ 29.772966582278482, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16908, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.443502068965517 ], [ 29.773236075949367, -1.443771551724138 ], [ 29.773505569620252, -1.443771551724138 ], [ 29.773505569620252, -1.443502068965517 ], [ 29.773236075949367, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16909, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.443502068965517 ], [ 29.773505569620252, -1.443771551724138 ], [ 29.77377506329114, -1.443771551724138 ], [ 29.77377506329114, -1.443502068965517 ], [ 29.773505569620252, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16910, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.443502068965517 ], [ 29.77377506329114, -1.443771551724138 ], [ 29.774044556962025, -1.443771551724138 ], [ 29.774044556962025, -1.443502068965517 ], [ 29.77377506329114, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16911, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.443502068965517 ], [ 29.774044556962025, -1.443771551724138 ], [ 29.77431405063291, -1.443771551724138 ], [ 29.77431405063291, -1.443502068965517 ], [ 29.774044556962025, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16912, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.443502068965517 ], [ 29.77431405063291, -1.443771551724138 ], [ 29.774853037974683, -1.443771551724138 ], [ 29.774853037974683, -1.443502068965517 ], [ 29.77431405063291, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16913, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.443502068965517 ], [ 29.774853037974683, -1.443771551724138 ], [ 29.775122531645568, -1.443771551724138 ], [ 29.775122531645568, -1.443502068965517 ], [ 29.774853037974683, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16914, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.443502068965517 ], [ 29.775122531645568, -1.443771551724138 ], [ 29.775392025316457, -1.443771551724138 ], [ 29.775392025316457, -1.443502068965517 ], [ 29.775122531645568, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16915, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.443502068965517 ], [ 29.775392025316457, -1.443771551724138 ], [ 29.775661518987341, -1.443771551724138 ], [ 29.775661518987341, -1.443502068965517 ], [ 29.775392025316457, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16916, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.443502068965517 ], [ 29.775661518987341, -1.443771551724138 ], [ 29.775931012658226, -1.443771551724138 ], [ 29.775931012658226, -1.443502068965517 ], [ 29.775661518987341, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16917, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.443502068965517 ], [ 29.775931012658226, -1.443771551724138 ], [ 29.776200506329115, -1.443771551724138 ], [ 29.776200506329115, -1.443502068965517 ], [ 29.775931012658226, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16918, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.443502068965517 ], [ 29.776200506329115, -1.443771551724138 ], [ 29.77647, -1.443771551724138 ], [ 29.77647, -1.443502068965517 ], [ 29.776200506329115, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16919, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.443502068965517 ], [ 29.784285316455698, -1.443771551724138 ], [ 29.784554810126583, -1.443771551724138 ], [ 29.784554810126583, -1.443502068965517 ], [ 29.784285316455698, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16920, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.443502068965517 ], [ 29.784554810126583, -1.443771551724138 ], [ 29.784824303797468, -1.443771551724138 ], [ 29.784824303797468, -1.443502068965517 ], [ 29.784554810126583, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16921, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.443502068965517 ], [ 29.785093797468356, -1.443771551724138 ], [ 29.785363291139241, -1.443771551724138 ], [ 29.785363291139241, -1.443502068965517 ], [ 29.785093797468356, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16922, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.443502068965517 ], [ 29.785363291139241, -1.443771551724138 ], [ 29.785632784810126, -1.443771551724138 ], [ 29.785632784810126, -1.443502068965517 ], [ 29.785363291139241, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16923, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.443502068965517 ], [ 29.785632784810126, -1.443771551724138 ], [ 29.785902278481014, -1.443771551724138 ], [ 29.785902278481014, -1.443502068965517 ], [ 29.785632784810126, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16924, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.443502068965517 ], [ 29.785902278481014, -1.443771551724138 ], [ 29.786171772151899, -1.443771551724138 ], [ 29.786171772151899, -1.443502068965517 ], [ 29.785902278481014, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16925, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.443502068965517 ], [ 29.786171772151899, -1.443771551724138 ], [ 29.786441265822784, -1.443771551724138 ], [ 29.786441265822784, -1.443502068965517 ], [ 29.786171772151899, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16926, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.443502068965517 ], [ 29.793987088607594, -1.443771551724138 ], [ 29.794256582278482, -1.443771551724138 ], [ 29.794256582278482, -1.443502068965517 ], [ 29.793987088607594, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16927, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.443502068965517 ], [ 29.794256582278482, -1.443771551724138 ], [ 29.794526075949367, -1.443771551724138 ], [ 29.794526075949367, -1.443502068965517 ], [ 29.794256582278482, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16928, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.443502068965517 ], [ 29.794526075949367, -1.443771551724138 ], [ 29.794795569620252, -1.443771551724138 ], [ 29.794795569620252, -1.443502068965517 ], [ 29.794526075949367, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16929, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.443502068965517 ], [ 29.79506506329114, -1.443771551724138 ], [ 29.795334556962025, -1.443771551724138 ], [ 29.795334556962025, -1.443502068965517 ], [ 29.79506506329114, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16930, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.443502068965517 ], [ 29.795334556962025, -1.443771551724138 ], [ 29.79560405063291, -1.443771551724138 ], [ 29.79560405063291, -1.443502068965517 ], [ 29.795334556962025, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16931, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.443502068965517 ], [ 29.800993924050633, -1.443771551724138 ], [ 29.801263417721518, -1.443771551724138 ], [ 29.801263417721518, -1.443502068965517 ], [ 29.800993924050633, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16932, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.443502068965517 ], [ 29.801263417721518, -1.443771551724138 ], [ 29.801532911392407, -1.443771551724138 ], [ 29.801532911392407, -1.443502068965517 ], [ 29.801263417721518, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16933, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.443502068965517 ], [ 29.801532911392407, -1.443771551724138 ], [ 29.802071898734177, -1.443771551724138 ], [ 29.802071898734177, -1.443502068965517 ], [ 29.801532911392407, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16934, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.443502068965517 ], [ 29.802071898734177, -1.443771551724138 ], [ 29.802341392405065, -1.443771551724138 ], [ 29.802341392405065, -1.443502068965517 ], [ 29.802071898734177, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16935, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.443502068965517 ], [ 29.802341392405065, -1.443771551724138 ], [ 29.80261088607595, -1.443771551724138 ], [ 29.80261088607595, -1.443502068965517 ], [ 29.802341392405065, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16936, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.443502068965517 ], [ 29.802880379746835, -1.443771551724138 ], [ 29.803149873417723, -1.443771551724138 ], [ 29.803149873417723, -1.443502068965517 ], [ 29.802880379746835, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16937, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.443502068965517 ], [ 29.803149873417723, -1.443771551724138 ], [ 29.803419367088608, -1.443771551724138 ], [ 29.803419367088608, -1.443502068965517 ], [ 29.803149873417723, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16938, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.443502068965517 ], [ 29.803688860759493, -1.443771551724138 ], [ 29.803958354430382, -1.443771551724138 ], [ 29.803958354430382, -1.443502068965517 ], [ 29.803688860759493, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16939, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.443502068965517 ], [ 29.803958354430382, -1.443771551724138 ], [ 29.804227848101267, -1.443771551724138 ], [ 29.804227848101267, -1.443502068965517 ], [ 29.803958354430382, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16940, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.443502068965517 ], [ 29.804227848101267, -1.443771551724138 ], [ 29.804497341772151, -1.443771551724138 ], [ 29.804497341772151, -1.443502068965517 ], [ 29.804227848101267, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16941, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.443502068965517 ], [ 29.804497341772151, -1.443771551724138 ], [ 29.80476683544304, -1.443771551724138 ], [ 29.80476683544304, -1.443502068965517 ], [ 29.804497341772151, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16942, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.443502068965517 ], [ 29.80476683544304, -1.443771551724138 ], [ 29.805036329113925, -1.443771551724138 ], [ 29.805036329113925, -1.443502068965517 ], [ 29.80476683544304, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16943, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.443502068965517 ], [ 29.805036329113925, -1.443771551724138 ], [ 29.80530582278481, -1.443771551724138 ], [ 29.80530582278481, -1.443502068965517 ], [ 29.805036329113925, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16944, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.443502068965517 ], [ 29.80530582278481, -1.443771551724138 ], [ 29.805575316455698, -1.443771551724138 ], [ 29.805575316455698, -1.443502068965517 ], [ 29.80530582278481, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16945, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.443502068965517 ], [ 29.805575316455698, -1.443771551724138 ], [ 29.805844810126583, -1.443771551724138 ], [ 29.805844810126583, -1.443502068965517 ], [ 29.805575316455698, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.443502068965517 ], [ 29.805844810126583, -1.443771551724138 ], [ 29.806114303797468, -1.443771551724138 ], [ 29.806114303797468, -1.443502068965517 ], [ 29.805844810126583, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16947, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.443502068965517 ], [ 29.806114303797468, -1.443771551724138 ], [ 29.806383797468357, -1.443771551724138 ], [ 29.806383797468357, -1.443502068965517 ], [ 29.806114303797468, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16948, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.443502068965517 ], [ 29.806383797468357, -1.443771551724138 ], [ 29.806653291139241, -1.443771551724138 ], [ 29.806653291139241, -1.443502068965517 ], [ 29.806383797468357, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16949, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.441076724137931 ], [ 29.792100632911392, -1.444849482758621 ], [ 29.792370126582277, -1.444849482758621 ], [ 29.792370126582277, -1.441076724137931 ], [ 29.792100632911392, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16950, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.441076724137931 ], [ 29.793178607594935, -1.444849482758621 ], [ 29.793448101265824, -1.444849482758621 ], [ 29.793448101265824, -1.441076724137931 ], [ 29.793178607594935, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16951, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.441076724137931 ], [ 29.793717594936709, -1.445927413793104 ], [ 29.793987088607594, -1.445927413793104 ], [ 29.793987088607594, -1.443232586206897 ], [ 29.794256582278482, -1.443232586206897 ], [ 29.794256582278482, -1.442154655172414 ], [ 29.793987088607594, -1.442154655172414 ], [ 29.793987088607594, -1.441076724137931 ], [ 29.793717594936709, -1.441076724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16952, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.441346206896552 ], [ 29.790753164556961, -1.444310517241379 ], [ 29.791022658227849, -1.444310517241379 ], [ 29.791022658227849, -1.441346206896552 ], [ 29.790753164556961, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16953, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.441346206896552 ], [ 29.791022658227849, -1.444310517241379 ], [ 29.791292151898734, -1.444310517241379 ], [ 29.791292151898734, -1.441346206896552 ], [ 29.791022658227849, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16954, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.441346206896552 ], [ 29.791561645569619, -1.444310517241379 ], [ 29.791831139240507, -1.444310517241379 ], [ 29.791831139240507, -1.441346206896552 ], [ 29.791561645569619, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16955, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.441346206896552 ], [ 29.791831139240507, -1.444310517241379 ], [ 29.792100632911392, -1.444310517241379 ], [ 29.792100632911392, -1.441346206896552 ], [ 29.791831139240507, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16956, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.441346206896552 ], [ 29.792370126582277, -1.444310517241379 ], [ 29.792909113924051, -1.444310517241379 ], [ 29.792909113924051, -1.441346206896552 ], [ 29.792370126582277, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16957, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.441346206896552 ], [ 29.793448101265824, -1.444310517241379 ], [ 29.793717594936709, -1.444310517241379 ], [ 29.793717594936709, -1.441346206896552 ], [ 29.793448101265824, -1.441346206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16958, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.441615689655172 ], [ 29.789944683544302, -1.444041034482759 ], [ 29.790214177215191, -1.444041034482759 ], [ 29.790214177215191, -1.441615689655172 ], [ 29.789944683544302, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16959, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.441615689655172 ], [ 29.790214177215191, -1.444041034482759 ], [ 29.790483670886076, -1.444041034482759 ], [ 29.790483670886076, -1.441615689655172 ], [ 29.790214177215191, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16960, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.441615689655172 ], [ 29.790483670886076, -1.444041034482759 ], [ 29.790753164556961, -1.444041034482759 ], [ 29.790753164556961, -1.441615689655172 ], [ 29.790483670886076, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.441615689655172 ], [ 29.791292151898734, -1.444041034482759 ], [ 29.791561645569619, -1.444041034482759 ], [ 29.791561645569619, -1.441615689655172 ], [ 29.791292151898734, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16962, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.441615689655172 ], [ 29.792909113924051, -1.444041034482759 ], [ 29.793178607594935, -1.444041034482759 ], [ 29.793178607594935, -1.441615689655172 ], [ 29.792909113924051, -1.441615689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16963, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.441885172413793 ], [ 29.778895443037975, -1.444041034482759 ], [ 29.77916493670886, -1.444041034482759 ], [ 29.77916493670886, -1.441885172413793 ], [ 29.778895443037975, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16964, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.441885172413793 ], [ 29.779703924050633, -1.444041034482759 ], [ 29.779973417721518, -1.444041034482759 ], [ 29.779973417721518, -1.441885172413793 ], [ 29.779703924050633, -1.441885172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16965, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.442154655172414 ], [ 29.777547974683543, -1.444041034482759 ], [ 29.777817468354431, -1.444041034482759 ], [ 29.777817468354431, -1.442154655172414 ], [ 29.777547974683543, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16966, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.442154655172414 ], [ 29.778086962025316, -1.444041034482759 ], [ 29.778356455696201, -1.444041034482759 ], [ 29.778356455696201, -1.442154655172414 ], [ 29.778086962025316, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16967, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.442154655172414 ], [ 29.778356455696201, -1.444041034482759 ], [ 29.77862594936709, -1.444041034482759 ], [ 29.77862594936709, -1.442154655172414 ], [ 29.778356455696201, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16968, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.442154655172414 ], [ 29.780242911392406, -1.444041034482759 ], [ 29.780512405063291, -1.444041034482759 ], [ 29.780512405063291, -1.442154655172414 ], [ 29.780242911392406, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16969, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.442154655172414 ], [ 29.781051392405065, -1.444041034482759 ], [ 29.78132088607595, -1.444041034482759 ], [ 29.78132088607595, -1.442154655172414 ], [ 29.781051392405065, -1.442154655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16970, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.442424137931035 ], [ 29.776739493670885, -1.444041034482759 ], [ 29.777008987341773, -1.444041034482759 ], [ 29.777008987341773, -1.442424137931035 ], [ 29.776739493670885, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16971, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.442424137931035 ], [ 29.777008987341773, -1.444310517241379 ], [ 29.777278481012658, -1.444310517241379 ], [ 29.777278481012658, -1.442424137931035 ], [ 29.777008987341773, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16972, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.442424137931035 ], [ 29.796412531645569, -1.444041034482759 ], [ 29.796682025316457, -1.444041034482759 ], [ 29.796682025316457, -1.442424137931035 ], [ 29.796412531645569, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16973, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.442424137931035 ], [ 29.796682025316457, -1.444041034482759 ], [ 29.796951518987342, -1.444041034482759 ], [ 29.796951518987342, -1.442424137931035 ], [ 29.796682025316457, -1.442424137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16974, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.442693620689655 ], [ 29.796143037974684, -1.444041034482759 ], [ 29.796412531645569, -1.444041034482759 ], [ 29.796412531645569, -1.442693620689655 ], [ 29.796143037974684, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16975, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.442693620689655 ], [ 29.796951518987342, -1.444310517241379 ], [ 29.797221012658227, -1.444310517241379 ], [ 29.797221012658227, -1.442693620689655 ], [ 29.796951518987342, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16976, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.442693620689655 ], [ 29.797221012658227, -1.444310517241379 ], [ 29.797490506329115, -1.444310517241379 ], [ 29.797490506329115, -1.442693620689655 ], [ 29.797221012658227, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16977, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.442693620689655 ], [ 29.797490506329115, -1.444849482758621 ], [ 29.79776, -1.444849482758621 ], [ 29.79776, -1.442693620689655 ], [ 29.797490506329115, -1.442693620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16978, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.442963103448276 ], [ 29.749520632911391, -1.444310517241379 ], [ 29.749790126582276, -1.444310517241379 ], [ 29.749790126582276, -1.442963103448276 ], [ 29.749520632911391, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16979, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.442963103448276 ], [ 29.75032911392405, -1.444310517241379 ], [ 29.750598607594934, -1.444310517241379 ], [ 29.750598607594934, -1.442963103448276 ], [ 29.75032911392405, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16980, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.442963103448276 ], [ 29.750868101265823, -1.444041034482759 ], [ 29.751137594936708, -1.444041034482759 ], [ 29.751137594936708, -1.442963103448276 ], [ 29.750868101265823, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16981, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.442963103448276 ], [ 29.751137594936708, -1.444041034482759 ], [ 29.751407088607593, -1.444041034482759 ], [ 29.751407088607593, -1.442963103448276 ], [ 29.751137594936708, -1.442963103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16983, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.443232586206897 ], [ 29.74817316455696, -1.445118965517241 ], [ 29.748442658227848, -1.445118965517241 ], [ 29.748442658227848, -1.443232586206897 ], [ 29.74817316455696, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16984, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.443232586206897 ], [ 29.748442658227848, -1.445118965517241 ], [ 29.748712151898733, -1.445118965517241 ], [ 29.748712151898733, -1.443232586206897 ], [ 29.748442658227848, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16985, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.443232586206897 ], [ 29.748981645569618, -1.444849482758621 ], [ 29.749251139240506, -1.444849482758621 ], [ 29.749251139240506, -1.443232586206897 ], [ 29.748981645569618, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16986, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.443232586206897 ], [ 29.749251139240506, -1.444849482758621 ], [ 29.749520632911391, -1.444849482758621 ], [ 29.749520632911391, -1.443232586206897 ], [ 29.749251139240506, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16987, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.443232586206897 ], [ 29.749790126582276, -1.444310517241379 ], [ 29.750059620253165, -1.444310517241379 ], [ 29.750059620253165, -1.443232586206897 ], [ 29.749790126582276, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16988, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.443232586206897 ], [ 29.750598607594934, -1.444310517241379 ], [ 29.750868101265823, -1.444310517241379 ], [ 29.750868101265823, -1.443232586206897 ], [ 29.750598607594934, -1.443232586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16989, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747095189873416, -1.443502068965517 ], [ 29.747095189873416, -1.445657931034483 ], [ 29.747364683544301, -1.445657931034483 ], [ 29.747364683544301, -1.443502068965517 ], [ 29.747095189873416, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16990, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.443502068965517 ], [ 29.747364683544301, -1.445388448275862 ], [ 29.747903670886075, -1.445388448275862 ], [ 29.747903670886075, -1.443502068965517 ], [ 29.747364683544301, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16991, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.443502068965517 ], [ 29.747903670886075, -1.445388448275862 ], [ 29.74817316455696, -1.445388448275862 ], [ 29.74817316455696, -1.443502068965517 ], [ 29.747903670886075, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16992, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.443502068965517 ], [ 29.748712151898733, -1.445118965517241 ], [ 29.748981645569618, -1.445118965517241 ], [ 29.748981645569618, -1.443502068965517 ], [ 29.748712151898733, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16993, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.443502068965517 ], [ 29.750059620253165, -1.444849482758621 ], [ 29.75032911392405, -1.444849482758621 ], [ 29.75032911392405, -1.443502068965517 ], [ 29.750059620253165, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16994, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.443502068965517 ], [ 29.763534303797467, -1.444041034482759 ], [ 29.763803797468356, -1.444041034482759 ], [ 29.763803797468356, -1.443502068965517 ], [ 29.763534303797467, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16995, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.443502068965517 ], [ 29.77647, -1.444041034482759 ], [ 29.776739493670885, -1.444041034482759 ], [ 29.776739493670885, -1.443502068965517 ], [ 29.77647, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16996, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.443502068965517 ], [ 29.777817468354431, -1.444310517241379 ], [ 29.778086962025316, -1.444310517241379 ], [ 29.778086962025316, -1.443502068965517 ], [ 29.777817468354431, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16997, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.443502068965517 ], [ 29.77916493670886, -1.444310517241379 ], [ 29.779434430379748, -1.444310517241379 ], [ 29.779434430379748, -1.443502068965517 ], [ 29.77916493670886, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16998, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.443502068965517 ], [ 29.780512405063291, -1.444041034482759 ], [ 29.780781898734176, -1.444041034482759 ], [ 29.780781898734176, -1.443502068965517 ], [ 29.780512405063291, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 16999, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.443502068965517 ], [ 29.78132088607595, -1.444041034482759 ], [ 29.781590379746834, -1.444041034482759 ], [ 29.781590379746834, -1.443502068965517 ], [ 29.78132088607595, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17000, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.443502068965517 ], [ 29.781859873417723, -1.444041034482759 ], [ 29.782129367088608, -1.444041034482759 ], [ 29.782129367088608, -1.443502068965517 ], [ 29.781859873417723, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17001, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.443502068965517 ], [ 29.782668354430381, -1.444041034482759 ], [ 29.782937848101266, -1.444041034482759 ], [ 29.782937848101266, -1.443502068965517 ], [ 29.782668354430381, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17002, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.443502068965517 ], [ 29.783476835443039, -1.444041034482759 ], [ 29.784015822784809, -1.444041034482759 ], [ 29.784015822784809, -1.443502068965517 ], [ 29.783476835443039, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17003, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.443502068965517 ], [ 29.800724430379748, -1.444041034482759 ], [ 29.800993924050633, -1.444041034482759 ], [ 29.800993924050633, -1.443502068965517 ], [ 29.800724430379748, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17004, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.443502068965517 ], [ 29.80261088607595, -1.444041034482759 ], [ 29.802880379746835, -1.444041034482759 ], [ 29.802880379746835, -1.443502068965517 ], [ 29.80261088607595, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17005, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.443502068965517 ], [ 29.803419367088608, -1.444310517241379 ], [ 29.803688860759493, -1.444310517241379 ], [ 29.803688860759493, -1.443502068965517 ], [ 29.803419367088608, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17006, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.443502068965517 ], [ 29.806653291139241, -1.443771551724138 ], [ 29.806922784810126, -1.443771551724138 ], [ 29.806922784810126, -1.444041034482759 ], [ 29.807192278481015, -1.444041034482759 ], [ 29.807192278481015, -1.443502068965517 ], [ 29.806653291139241, -1.443502068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17007, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.443771551724138 ], [ 29.751407088607593, -1.444041034482759 ], [ 29.751676582278481, -1.444041034482759 ], [ 29.751676582278481, -1.443771551724138 ], [ 29.751407088607593, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17008, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.443771551724138 ], [ 29.751676582278481, -1.444041034482759 ], [ 29.751946075949366, -1.444041034482759 ], [ 29.751946075949366, -1.443771551724138 ], [ 29.751676582278481, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17009, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.443771551724138 ], [ 29.751946075949366, -1.444041034482759 ], [ 29.752215569620251, -1.444041034482759 ], [ 29.752215569620251, -1.443771551724138 ], [ 29.751946075949366, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17010, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.443771551724138 ], [ 29.752215569620251, -1.444041034482759 ], [ 29.75248506329114, -1.444041034482759 ], [ 29.75248506329114, -1.443771551724138 ], [ 29.752215569620251, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17011, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.443771551724138 ], [ 29.75248506329114, -1.444041034482759 ], [ 29.752754556962024, -1.444041034482759 ], [ 29.752754556962024, -1.443771551724138 ], [ 29.75248506329114, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17012, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.443771551724138 ], [ 29.752754556962024, -1.444041034482759 ], [ 29.753024050632909, -1.444041034482759 ], [ 29.753024050632909, -1.443771551724138 ], [ 29.752754556962024, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17013, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.443771551724138 ], [ 29.753024050632909, -1.444041034482759 ], [ 29.753293544303798, -1.444041034482759 ], [ 29.753293544303798, -1.443771551724138 ], [ 29.753024050632909, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17014, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.443771551724138 ], [ 29.753293544303798, -1.444041034482759 ], [ 29.753563037974683, -1.444041034482759 ], [ 29.753563037974683, -1.443771551724138 ], [ 29.753293544303798, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17015, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.443771551724138 ], [ 29.753563037974683, -1.444041034482759 ], [ 29.753832531645568, -1.444041034482759 ], [ 29.753832531645568, -1.443771551724138 ], [ 29.753563037974683, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17016, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.443771551724138 ], [ 29.753832531645568, -1.444041034482759 ], [ 29.754102025316456, -1.444041034482759 ], [ 29.754102025316456, -1.443771551724138 ], [ 29.753832531645568, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17017, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.443771551724138 ], [ 29.754102025316456, -1.444041034482759 ], [ 29.754371518987341, -1.444041034482759 ], [ 29.754371518987341, -1.443771551724138 ], [ 29.754102025316456, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17018, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.443771551724138 ], [ 29.754371518987341, -1.444310517241379 ], [ 29.754910506329114, -1.444310517241379 ], [ 29.754910506329114, -1.444041034482759 ], [ 29.754641012658226, -1.444041034482759 ], [ 29.754641012658226, -1.443771551724138 ], [ 29.754371518987341, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17019, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.443771551724138 ], [ 29.754641012658226, -1.444041034482759 ], [ 29.754910506329114, -1.444041034482759 ], [ 29.754910506329114, -1.443771551724138 ], [ 29.754641012658226, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17020, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.443771551724138 ], [ 29.754910506329114, -1.444041034482759 ], [ 29.75518, -1.444041034482759 ], [ 29.75518, -1.443771551724138 ], [ 29.754910506329114, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17021, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.443771551724138 ], [ 29.75518, -1.444041034482759 ], [ 29.755449493670884, -1.444041034482759 ], [ 29.755449493670884, -1.443771551724138 ], [ 29.75518, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17022, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.443771551724138 ], [ 29.755449493670884, -1.444041034482759 ], [ 29.755718987341773, -1.444041034482759 ], [ 29.755718987341773, -1.443771551724138 ], [ 29.755449493670884, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17023, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.443771551724138 ], [ 29.755718987341773, -1.444041034482759 ], [ 29.755988481012658, -1.444041034482759 ], [ 29.755988481012658, -1.443771551724138 ], [ 29.755718987341773, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17024, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.443771551724138 ], [ 29.755988481012658, -1.444041034482759 ], [ 29.756257974683542, -1.444041034482759 ], [ 29.756257974683542, -1.443771551724138 ], [ 29.755988481012658, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17025, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.443771551724138 ], [ 29.756257974683542, -1.444041034482759 ], [ 29.756796962025316, -1.444041034482759 ], [ 29.756796962025316, -1.443771551724138 ], [ 29.756257974683542, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17026, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.443771551724138 ], [ 29.756796962025316, -1.444041034482759 ], [ 29.757066455696201, -1.444041034482759 ], [ 29.757066455696201, -1.443771551724138 ], [ 29.756796962025316, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17027, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.443771551724138 ], [ 29.757066455696201, -1.444041034482759 ], [ 29.757335949367089, -1.444041034482759 ], [ 29.757335949367089, -1.443771551724138 ], [ 29.757066455696201, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17028, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.443771551724138 ], [ 29.757335949367089, -1.444041034482759 ], [ 29.757605443037974, -1.444041034482759 ], [ 29.757605443037974, -1.443771551724138 ], [ 29.757335949367089, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17029, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.443771551724138 ], [ 29.757605443037974, -1.444041034482759 ], [ 29.757874936708859, -1.444041034482759 ], [ 29.757874936708859, -1.443771551724138 ], [ 29.757605443037974, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17030, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.443771551724138 ], [ 29.757874936708859, -1.444041034482759 ], [ 29.758144430379748, -1.444041034482759 ], [ 29.758144430379748, -1.443771551724138 ], [ 29.757874936708859, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17031, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.443771551724138 ], [ 29.758144430379748, -1.444041034482759 ], [ 29.758413924050632, -1.444041034482759 ], [ 29.758413924050632, -1.443771551724138 ], [ 29.758144430379748, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17032, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.443771551724138 ], [ 29.758413924050632, -1.444041034482759 ], [ 29.758952911392406, -1.444041034482759 ], [ 29.758952911392406, -1.443771551724138 ], [ 29.758413924050632, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17033, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.443771551724138 ], [ 29.758952911392406, -1.444041034482759 ], [ 29.761917341772151, -1.444041034482759 ], [ 29.761917341772151, -1.443771551724138 ], [ 29.758952911392406, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17034, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.443771551724138 ], [ 29.762456329113924, -1.444041034482759 ], [ 29.762725822784809, -1.444041034482759 ], [ 29.762725822784809, -1.443771551724138 ], [ 29.762456329113924, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17035, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.443771551724138 ], [ 29.762725822784809, -1.444041034482759 ], [ 29.762995316455697, -1.444041034482759 ], [ 29.762995316455697, -1.443771551724138 ], [ 29.762725822784809, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17036, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.443771551724138 ], [ 29.762995316455697, -1.444041034482759 ], [ 29.763264810126582, -1.444041034482759 ], [ 29.763264810126582, -1.443771551724138 ], [ 29.762995316455697, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17037, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.443771551724138 ], [ 29.763264810126582, -1.444041034482759 ], [ 29.763534303797467, -1.444041034482759 ], [ 29.763534303797467, -1.443771551724138 ], [ 29.763264810126582, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17038, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.443771551724138 ], [ 29.763803797468356, -1.444041034482759 ], [ 29.76407329113924, -1.444041034482759 ], [ 29.76407329113924, -1.443771551724138 ], [ 29.763803797468356, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17039, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.443771551724138 ], [ 29.76407329113924, -1.444041034482759 ], [ 29.764342784810125, -1.444041034482759 ], [ 29.764342784810125, -1.443771551724138 ], [ 29.76407329113924, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17040, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.443771551724138 ], [ 29.764342784810125, -1.444041034482759 ], [ 29.764612278481014, -1.444041034482759 ], [ 29.764612278481014, -1.443771551724138 ], [ 29.764342784810125, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17041, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.443771551724138 ], [ 29.764612278481014, -1.444041034482759 ], [ 29.764881772151899, -1.444041034482759 ], [ 29.764881772151899, -1.443771551724138 ], [ 29.764612278481014, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17042, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.443771551724138 ], [ 29.764881772151899, -1.444041034482759 ], [ 29.765151265822784, -1.444041034482759 ], [ 29.765151265822784, -1.443771551724138 ], [ 29.764881772151899, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17043, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.443771551724138 ], [ 29.765151265822784, -1.444041034482759 ], [ 29.768115696202532, -1.444041034482759 ], [ 29.768115696202532, -1.443771551724138 ], [ 29.765151265822784, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17044, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.443771551724138 ], [ 29.768115696202532, -1.444041034482759 ], [ 29.768385189873417, -1.444041034482759 ], [ 29.768385189873417, -1.443771551724138 ], [ 29.768115696202532, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17045, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.443771551724138 ], [ 29.768385189873417, -1.444041034482759 ], [ 29.768654683544302, -1.444041034482759 ], [ 29.768654683544302, -1.443771551724138 ], [ 29.768385189873417, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17046, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.443771551724138 ], [ 29.768654683544302, -1.444041034482759 ], [ 29.76892417721519, -1.444041034482759 ], [ 29.76892417721519, -1.443771551724138 ], [ 29.768654683544302, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17047, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.443771551724138 ], [ 29.76892417721519, -1.444041034482759 ], [ 29.769193670886075, -1.444041034482759 ], [ 29.769193670886075, -1.443771551724138 ], [ 29.76892417721519, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17048, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.443771551724138 ], [ 29.769193670886075, -1.444041034482759 ], [ 29.76946316455696, -1.444041034482759 ], [ 29.76946316455696, -1.443771551724138 ], [ 29.769193670886075, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17049, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.443771551724138 ], [ 29.76946316455696, -1.444041034482759 ], [ 29.769732658227849, -1.444041034482759 ], [ 29.769732658227849, -1.443771551724138 ], [ 29.76946316455696, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17050, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.443771551724138 ], [ 29.769732658227849, -1.444041034482759 ], [ 29.770002151898733, -1.444041034482759 ], [ 29.770002151898733, -1.443771551724138 ], [ 29.769732658227849, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17051, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.443771551724138 ], [ 29.770002151898733, -1.444041034482759 ], [ 29.770271645569618, -1.444041034482759 ], [ 29.770271645569618, -1.443771551724138 ], [ 29.770002151898733, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17052, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.443771551724138 ], [ 29.770271645569618, -1.444041034482759 ], [ 29.770541139240507, -1.444041034482759 ], [ 29.770541139240507, -1.443771551724138 ], [ 29.770271645569618, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17053, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.443771551724138 ], [ 29.770541139240507, -1.444041034482759 ], [ 29.770810632911392, -1.444041034482759 ], [ 29.770810632911392, -1.443771551724138 ], [ 29.770541139240507, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17054, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.443771551724138 ], [ 29.770810632911392, -1.444041034482759 ], [ 29.771080126582277, -1.444041034482759 ], [ 29.771080126582277, -1.443771551724138 ], [ 29.770810632911392, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17055, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.443771551724138 ], [ 29.771080126582277, -1.444041034482759 ], [ 29.771349620253165, -1.444041034482759 ], [ 29.771349620253165, -1.443771551724138 ], [ 29.771080126582277, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17056, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.443771551724138 ], [ 29.771349620253165, -1.444041034482759 ], [ 29.77161911392405, -1.444041034482759 ], [ 29.77161911392405, -1.443771551724138 ], [ 29.771349620253165, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17057, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.443771551724138 ], [ 29.77161911392405, -1.444041034482759 ], [ 29.771888607594935, -1.444041034482759 ], [ 29.771888607594935, -1.443771551724138 ], [ 29.77161911392405, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17058, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.443771551724138 ], [ 29.771888607594935, -1.444041034482759 ], [ 29.772158101265823, -1.444041034482759 ], [ 29.772158101265823, -1.443771551724138 ], [ 29.771888607594935, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17059, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.443771551724138 ], [ 29.772158101265823, -1.444041034482759 ], [ 29.772427594936708, -1.444041034482759 ], [ 29.772427594936708, -1.443771551724138 ], [ 29.772158101265823, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17060, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.443771551724138 ], [ 29.772427594936708, -1.444041034482759 ], [ 29.772697088607593, -1.444041034482759 ], [ 29.772697088607593, -1.443771551724138 ], [ 29.772427594936708, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17061, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.443771551724138 ], [ 29.772697088607593, -1.444041034482759 ], [ 29.772966582278482, -1.444041034482759 ], [ 29.772966582278482, -1.443771551724138 ], [ 29.772697088607593, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17062, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.443771551724138 ], [ 29.772966582278482, -1.444041034482759 ], [ 29.773236075949367, -1.444041034482759 ], [ 29.773236075949367, -1.443771551724138 ], [ 29.772966582278482, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17063, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.443771551724138 ], [ 29.773236075949367, -1.444041034482759 ], [ 29.773505569620252, -1.444041034482759 ], [ 29.773505569620252, -1.443771551724138 ], [ 29.773236075949367, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17064, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.443771551724138 ], [ 29.773505569620252, -1.444041034482759 ], [ 29.77377506329114, -1.444041034482759 ], [ 29.77377506329114, -1.443771551724138 ], [ 29.773505569620252, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17065, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.443771551724138 ], [ 29.77377506329114, -1.444041034482759 ], [ 29.774044556962025, -1.444041034482759 ], [ 29.774044556962025, -1.443771551724138 ], [ 29.77377506329114, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17066, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.443771551724138 ], [ 29.774044556962025, -1.444041034482759 ], [ 29.77431405063291, -1.444041034482759 ], [ 29.77431405063291, -1.443771551724138 ], [ 29.774044556962025, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17067, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.443771551724138 ], [ 29.77431405063291, -1.444041034482759 ], [ 29.774853037974683, -1.444041034482759 ], [ 29.774853037974683, -1.443771551724138 ], [ 29.77431405063291, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17068, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.443771551724138 ], [ 29.774853037974683, -1.444041034482759 ], [ 29.775122531645568, -1.444041034482759 ], [ 29.775122531645568, -1.443771551724138 ], [ 29.774853037974683, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17069, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.443771551724138 ], [ 29.775122531645568, -1.444041034482759 ], [ 29.775392025316457, -1.444041034482759 ], [ 29.775392025316457, -1.443771551724138 ], [ 29.775122531645568, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17070, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.443771551724138 ], [ 29.775392025316457, -1.444041034482759 ], [ 29.775661518987341, -1.444041034482759 ], [ 29.775661518987341, -1.443771551724138 ], [ 29.775392025316457, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17071, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.443771551724138 ], [ 29.775661518987341, -1.444041034482759 ], [ 29.775931012658226, -1.444041034482759 ], [ 29.775931012658226, -1.443771551724138 ], [ 29.775661518987341, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17072, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.443771551724138 ], [ 29.775931012658226, -1.444041034482759 ], [ 29.776200506329115, -1.444041034482759 ], [ 29.776200506329115, -1.443771551724138 ], [ 29.775931012658226, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17073, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.443771551724138 ], [ 29.776200506329115, -1.444041034482759 ], [ 29.77647, -1.444041034482759 ], [ 29.77647, -1.443771551724138 ], [ 29.776200506329115, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17074, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.443771551724138 ], [ 29.777278481012658, -1.444849482758621 ], [ 29.777547974683543, -1.444849482758621 ], [ 29.777547974683543, -1.443771551724138 ], [ 29.777278481012658, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17075, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.443771551724138 ], [ 29.77862594936709, -1.444310517241379 ], [ 29.778895443037975, -1.444310517241379 ], [ 29.778895443037975, -1.443771551724138 ], [ 29.77862594936709, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17076, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.443771551724138 ], [ 29.779434430379748, -1.444310517241379 ], [ 29.779703924050633, -1.444310517241379 ], [ 29.779703924050633, -1.443771551724138 ], [ 29.779434430379748, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17077, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.443771551724138 ], [ 29.779973417721518, -1.444310517241379 ], [ 29.780242911392406, -1.444310517241379 ], [ 29.780242911392406, -1.443771551724138 ], [ 29.779973417721518, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17078, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.443771551724138 ], [ 29.780781898734176, -1.444310517241379 ], [ 29.781051392405065, -1.444310517241379 ], [ 29.781051392405065, -1.443771551724138 ], [ 29.780781898734176, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17079, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.443771551724138 ], [ 29.781590379746834, -1.444310517241379 ], [ 29.781859873417723, -1.444310517241379 ], [ 29.781859873417723, -1.443771551724138 ], [ 29.781590379746834, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17080, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.443771551724138 ], [ 29.782129367088608, -1.444041034482759 ], [ 29.782398860759493, -1.444041034482759 ], [ 29.782398860759493, -1.443771551724138 ], [ 29.782129367088608, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17081, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.443771551724138 ], [ 29.782398860759493, -1.444310517241379 ], [ 29.782668354430381, -1.444310517241379 ], [ 29.782668354430381, -1.443771551724138 ], [ 29.782398860759493, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17082, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.443771551724138 ], [ 29.782937848101266, -1.444041034482759 ], [ 29.783207341772151, -1.444041034482759 ], [ 29.783207341772151, -1.443771551724138 ], [ 29.782937848101266, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17083, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.443771551724138 ], [ 29.783207341772151, -1.444041034482759 ], [ 29.783476835443039, -1.444041034482759 ], [ 29.783476835443039, -1.443771551724138 ], [ 29.783207341772151, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17084, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.443771551724138 ], [ 29.784015822784809, -1.444041034482759 ], [ 29.784285316455698, -1.444041034482759 ], [ 29.784285316455698, -1.443771551724138 ], [ 29.784015822784809, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17085, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.443771551724138 ], [ 29.784285316455698, -1.444041034482759 ], [ 29.784554810126583, -1.444041034482759 ], [ 29.784554810126583, -1.443771551724138 ], [ 29.784285316455698, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17086, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.443771551724138 ], [ 29.784554810126583, -1.444041034482759 ], [ 29.784824303797468, -1.444041034482759 ], [ 29.784824303797468, -1.443771551724138 ], [ 29.784554810126583, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17087, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.443771551724138 ], [ 29.784824303797468, -1.444041034482759 ], [ 29.785093797468356, -1.444041034482759 ], [ 29.785093797468356, -1.443771551724138 ], [ 29.784824303797468, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17088, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.443771551724138 ], [ 29.785093797468356, -1.444041034482759 ], [ 29.785363291139241, -1.444041034482759 ], [ 29.785363291139241, -1.443771551724138 ], [ 29.785093797468356, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17089, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.443771551724138 ], [ 29.785363291139241, -1.444041034482759 ], [ 29.785632784810126, -1.444041034482759 ], [ 29.785632784810126, -1.443771551724138 ], [ 29.785363291139241, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17090, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.443771551724138 ], [ 29.785632784810126, -1.444041034482759 ], [ 29.785902278481014, -1.444041034482759 ], [ 29.785902278481014, -1.443771551724138 ], [ 29.785632784810126, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17091, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.443771551724138 ], [ 29.785902278481014, -1.444041034482759 ], [ 29.786171772151899, -1.444041034482759 ], [ 29.786171772151899, -1.443771551724138 ], [ 29.785902278481014, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17092, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.443771551724138 ], [ 29.786171772151899, -1.444041034482759 ], [ 29.786441265822784, -1.444041034482759 ], [ 29.786441265822784, -1.443771551724138 ], [ 29.786171772151899, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17093, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.443771551724138 ], [ 29.786441265822784, -1.444041034482759 ], [ 29.786710759493673, -1.444041034482759 ], [ 29.786710759493673, -1.443771551724138 ], [ 29.786441265822784, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17094, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.443771551724138 ], [ 29.786710759493673, -1.444041034482759 ], [ 29.786980253164558, -1.444041034482759 ], [ 29.786980253164558, -1.443771551724138 ], [ 29.786710759493673, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17095, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.443771551724138 ], [ 29.793987088607594, -1.444310517241379 ], [ 29.794256582278482, -1.444310517241379 ], [ 29.794256582278482, -1.443771551724138 ], [ 29.793987088607594, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17096, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.443771551724138 ], [ 29.794256582278482, -1.444041034482759 ], [ 29.794526075949367, -1.444041034482759 ], [ 29.794526075949367, -1.443771551724138 ], [ 29.794256582278482, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17097, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.443771551724138 ], [ 29.794526075949367, -1.444041034482759 ], [ 29.794795569620252, -1.444041034482759 ], [ 29.794795569620252, -1.443771551724138 ], [ 29.794526075949367, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17098, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.443771551724138 ], [ 29.794795569620252, -1.444310517241379 ], [ 29.79506506329114, -1.444310517241379 ], [ 29.79506506329114, -1.443771551724138 ], [ 29.794795569620252, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17099, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.443771551724138 ], [ 29.79506506329114, -1.444041034482759 ], [ 29.795334556962025, -1.444041034482759 ], [ 29.795334556962025, -1.443771551724138 ], [ 29.79506506329114, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17100, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.443771551724138 ], [ 29.795334556962025, -1.444041034482759 ], [ 29.79560405063291, -1.444041034482759 ], [ 29.79560405063291, -1.443771551724138 ], [ 29.795334556962025, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17101, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.443771551724138 ], [ 29.79560405063291, -1.444041034482759 ], [ 29.795873544303799, -1.444041034482759 ], [ 29.795873544303799, -1.443771551724138 ], [ 29.79560405063291, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17102, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.443771551724138 ], [ 29.795873544303799, -1.444041034482759 ], [ 29.796143037974684, -1.444041034482759 ], [ 29.796143037974684, -1.443771551724138 ], [ 29.795873544303799, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17103, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.443771551724138 ], [ 29.800993924050633, -1.444041034482759 ], [ 29.801263417721518, -1.444041034482759 ], [ 29.801263417721518, -1.443771551724138 ], [ 29.800993924050633, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17104, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.443771551724138 ], [ 29.801263417721518, -1.444041034482759 ], [ 29.801532911392407, -1.444041034482759 ], [ 29.801532911392407, -1.443771551724138 ], [ 29.801263417721518, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17105, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.443771551724138 ], [ 29.801532911392407, -1.444041034482759 ], [ 29.802071898734177, -1.444041034482759 ], [ 29.802071898734177, -1.443771551724138 ], [ 29.801532911392407, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17106, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.443771551724138 ], [ 29.802071898734177, -1.445118965517241 ], [ 29.802341392405065, -1.445118965517241 ], [ 29.802341392405065, -1.443771551724138 ], [ 29.802071898734177, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17107, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.443771551724138 ], [ 29.802341392405065, -1.445118965517241 ], [ 29.80261088607595, -1.445118965517241 ], [ 29.80261088607595, -1.443771551724138 ], [ 29.802341392405065, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17108, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.443771551724138 ], [ 29.802880379746835, -1.444849482758621 ], [ 29.803149873417723, -1.444849482758621 ], [ 29.803149873417723, -1.443771551724138 ], [ 29.802880379746835, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17109, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.443771551724138 ], [ 29.803149873417723, -1.444849482758621 ], [ 29.803419367088608, -1.444849482758621 ], [ 29.803419367088608, -1.443771551724138 ], [ 29.803149873417723, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17110, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.443771551724138 ], [ 29.803688860759493, -1.444041034482759 ], [ 29.803958354430382, -1.444041034482759 ], [ 29.803958354430382, -1.443771551724138 ], [ 29.803688860759493, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17111, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.443771551724138 ], [ 29.803958354430382, -1.444041034482759 ], [ 29.804227848101267, -1.444041034482759 ], [ 29.804227848101267, -1.443771551724138 ], [ 29.803958354430382, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17112, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.443771551724138 ], [ 29.804227848101267, -1.444041034482759 ], [ 29.804497341772151, -1.444041034482759 ], [ 29.804497341772151, -1.443771551724138 ], [ 29.804227848101267, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17113, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.443771551724138 ], [ 29.804497341772151, -1.444041034482759 ], [ 29.80476683544304, -1.444041034482759 ], [ 29.80476683544304, -1.443771551724138 ], [ 29.804497341772151, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17114, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.443771551724138 ], [ 29.80476683544304, -1.444041034482759 ], [ 29.805036329113925, -1.444041034482759 ], [ 29.805036329113925, -1.443771551724138 ], [ 29.80476683544304, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17115, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.443771551724138 ], [ 29.805036329113925, -1.444041034482759 ], [ 29.80530582278481, -1.444041034482759 ], [ 29.80530582278481, -1.443771551724138 ], [ 29.805036329113925, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17116, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.443771551724138 ], [ 29.80530582278481, -1.444041034482759 ], [ 29.805575316455698, -1.444041034482759 ], [ 29.805575316455698, -1.443771551724138 ], [ 29.80530582278481, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17117, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.443771551724138 ], [ 29.805575316455698, -1.444041034482759 ], [ 29.805844810126583, -1.444041034482759 ], [ 29.805844810126583, -1.443771551724138 ], [ 29.805575316455698, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17118, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.443771551724138 ], [ 29.805844810126583, -1.444041034482759 ], [ 29.806114303797468, -1.444041034482759 ], [ 29.806114303797468, -1.443771551724138 ], [ 29.805844810126583, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17119, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.443771551724138 ], [ 29.806114303797468, -1.444041034482759 ], [ 29.806383797468357, -1.444041034482759 ], [ 29.806383797468357, -1.443771551724138 ], [ 29.806114303797468, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17120, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.443771551724138 ], [ 29.806383797468357, -1.444041034482759 ], [ 29.806653291139241, -1.444041034482759 ], [ 29.806653291139241, -1.443771551724138 ], [ 29.806383797468357, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17121, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.443771551724138 ], [ 29.806653291139241, -1.444041034482759 ], [ 29.806922784810126, -1.444041034482759 ], [ 29.806922784810126, -1.443771551724138 ], [ 29.806653291139241, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17122, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.444041034482759 ], [ 29.750868101265823, -1.444310517241379 ], [ 29.751137594936708, -1.444310517241379 ], [ 29.751137594936708, -1.444041034482759 ], [ 29.750868101265823, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17123, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.444041034482759 ], [ 29.751137594936708, -1.444310517241379 ], [ 29.751407088607593, -1.444310517241379 ], [ 29.751407088607593, -1.444041034482759 ], [ 29.751137594936708, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17124, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.444041034482759 ], [ 29.751407088607593, -1.444310517241379 ], [ 29.751676582278481, -1.444310517241379 ], [ 29.751676582278481, -1.444041034482759 ], [ 29.751407088607593, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17125, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.444041034482759 ], [ 29.751676582278481, -1.444310517241379 ], [ 29.751946075949366, -1.444310517241379 ], [ 29.751946075949366, -1.444041034482759 ], [ 29.751676582278481, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17126, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.444041034482759 ], [ 29.751946075949366, -1.444310517241379 ], [ 29.752215569620251, -1.444310517241379 ], [ 29.752215569620251, -1.444041034482759 ], [ 29.751946075949366, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17127, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.444041034482759 ], [ 29.752215569620251, -1.444310517241379 ], [ 29.75248506329114, -1.444310517241379 ], [ 29.75248506329114, -1.444041034482759 ], [ 29.752215569620251, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17128, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.444041034482759 ], [ 29.75248506329114, -1.444310517241379 ], [ 29.752754556962024, -1.444310517241379 ], [ 29.752754556962024, -1.444041034482759 ], [ 29.75248506329114, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17129, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.444041034482759 ], [ 29.752754556962024, -1.444310517241379 ], [ 29.753024050632909, -1.444310517241379 ], [ 29.753024050632909, -1.444041034482759 ], [ 29.752754556962024, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17130, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.444041034482759 ], [ 29.753024050632909, -1.444310517241379 ], [ 29.753293544303798, -1.444310517241379 ], [ 29.753293544303798, -1.444041034482759 ], [ 29.753024050632909, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17131, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.444041034482759 ], [ 29.753293544303798, -1.444310517241379 ], [ 29.753563037974683, -1.444310517241379 ], [ 29.753563037974683, -1.444041034482759 ], [ 29.753293544303798, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17132, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.444041034482759 ], [ 29.753563037974683, -1.444310517241379 ], [ 29.753832531645568, -1.444310517241379 ], [ 29.753832531645568, -1.444041034482759 ], [ 29.753563037974683, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17133, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.444041034482759 ], [ 29.753832531645568, -1.444310517241379 ], [ 29.754102025316456, -1.444310517241379 ], [ 29.754102025316456, -1.444041034482759 ], [ 29.753832531645568, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17134, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.444041034482759 ], [ 29.754102025316456, -1.444310517241379 ], [ 29.754371518987341, -1.444310517241379 ], [ 29.754371518987341, -1.444041034482759 ], [ 29.754102025316456, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17135, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.444041034482759 ], [ 29.754910506329114, -1.444310517241379 ], [ 29.75518, -1.444310517241379 ], [ 29.75518, -1.444041034482759 ], [ 29.754910506329114, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17136, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.444041034482759 ], [ 29.75518, -1.444310517241379 ], [ 29.755449493670884, -1.444310517241379 ], [ 29.755449493670884, -1.444041034482759 ], [ 29.75518, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17137, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.444041034482759 ], [ 29.755449493670884, -1.444310517241379 ], [ 29.755718987341773, -1.444310517241379 ], [ 29.755718987341773, -1.444041034482759 ], [ 29.755449493670884, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17138, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.444041034482759 ], [ 29.755718987341773, -1.444310517241379 ], [ 29.755988481012658, -1.444310517241379 ], [ 29.755988481012658, -1.444041034482759 ], [ 29.755718987341773, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17139, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.444041034482759 ], [ 29.755988481012658, -1.444310517241379 ], [ 29.756257974683542, -1.444310517241379 ], [ 29.756257974683542, -1.444041034482759 ], [ 29.755988481012658, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17140, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.444041034482759 ], [ 29.756257974683542, -1.444310517241379 ], [ 29.756796962025316, -1.444310517241379 ], [ 29.756796962025316, -1.444041034482759 ], [ 29.756257974683542, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17141, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.444041034482759 ], [ 29.756796962025316, -1.444310517241379 ], [ 29.757066455696201, -1.444310517241379 ], [ 29.757066455696201, -1.444041034482759 ], [ 29.756796962025316, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17142, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.444041034482759 ], [ 29.757066455696201, -1.444310517241379 ], [ 29.757335949367089, -1.444310517241379 ], [ 29.757335949367089, -1.444041034482759 ], [ 29.757066455696201, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17143, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.444041034482759 ], [ 29.757335949367089, -1.444310517241379 ], [ 29.757605443037974, -1.444310517241379 ], [ 29.757605443037974, -1.444041034482759 ], [ 29.757335949367089, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17144, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.444041034482759 ], [ 29.757605443037974, -1.444310517241379 ], [ 29.757874936708859, -1.444310517241379 ], [ 29.757874936708859, -1.444041034482759 ], [ 29.757605443037974, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17145, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.444041034482759 ], [ 29.757874936708859, -1.444310517241379 ], [ 29.758144430379748, -1.444310517241379 ], [ 29.758144430379748, -1.444041034482759 ], [ 29.757874936708859, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17146, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.444041034482759 ], [ 29.758144430379748, -1.444310517241379 ], [ 29.758683417721517, -1.444310517241379 ], [ 29.758683417721517, -1.444041034482759 ], [ 29.758144430379748, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17147, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.444041034482759 ], [ 29.758683417721517, -1.444310517241379 ], [ 29.760300379746834, -1.444310517241379 ], [ 29.760300379746834, -1.444849482758621 ], [ 29.760569873417722, -1.444849482758621 ], [ 29.760569873417722, -1.444310517241379 ], [ 29.761108860759492, -1.444310517241379 ], [ 29.761108860759492, -1.444041034482759 ], [ 29.758683417721517, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17148, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.444041034482759 ], [ 29.761108860759492, -1.444310517241379 ], [ 29.761378354430381, -1.444310517241379 ], [ 29.761378354430381, -1.444041034482759 ], [ 29.761108860759492, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17149, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.444041034482759 ], [ 29.761378354430381, -1.444310517241379 ], [ 29.761647848101266, -1.444310517241379 ], [ 29.761647848101266, -1.444041034482759 ], [ 29.761378354430381, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17150, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.443771551724138 ], [ 29.762456329113924, -1.443771551724138 ], [ 29.762456329113924, -1.444041034482759 ], [ 29.762186835443039, -1.444041034482759 ], [ 29.762186835443039, -1.444310517241379 ], [ 29.761647848101266, -1.444310517241379 ], [ 29.761647848101266, -1.444041034482759 ], [ 29.761917341772151, -1.444041034482759 ], [ 29.761917341772151, -1.443771551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17151, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.444041034482759 ], [ 29.762186835443039, -1.444310517241379 ], [ 29.762995316455697, -1.444310517241379 ], [ 29.762995316455697, -1.444041034482759 ], [ 29.762186835443039, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17152, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.444041034482759 ], [ 29.762995316455697, -1.444310517241379 ], [ 29.763803797468356, -1.444310517241379 ], [ 29.763803797468356, -1.444041034482759 ], [ 29.762995316455697, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17153, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.444041034482759 ], [ 29.763803797468356, -1.444310517241379 ], [ 29.76407329113924, -1.444310517241379 ], [ 29.76407329113924, -1.444041034482759 ], [ 29.763803797468356, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17154, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.444041034482759 ], [ 29.76407329113924, -1.444310517241379 ], [ 29.764342784810125, -1.444310517241379 ], [ 29.764342784810125, -1.444041034482759 ], [ 29.76407329113924, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17155, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.444041034482759 ], [ 29.764342784810125, -1.444310517241379 ], [ 29.764612278481014, -1.444310517241379 ], [ 29.764612278481014, -1.444041034482759 ], [ 29.764342784810125, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17156, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.444041034482759 ], [ 29.764612278481014, -1.444310517241379 ], [ 29.765151265822784, -1.444310517241379 ], [ 29.765151265822784, -1.444041034482759 ], [ 29.764612278481014, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17157, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.444041034482759 ], [ 29.765151265822784, -1.444310517241379 ], [ 29.768115696202532, -1.444310517241379 ], [ 29.768115696202532, -1.444041034482759 ], [ 29.765151265822784, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17158, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.444041034482759 ], [ 29.768115696202532, -1.444310517241379 ], [ 29.768654683544302, -1.444310517241379 ], [ 29.768654683544302, -1.444041034482759 ], [ 29.768115696202532, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17159, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.444041034482759 ], [ 29.768654683544302, -1.444310517241379 ], [ 29.76892417721519, -1.444310517241379 ], [ 29.76892417721519, -1.444041034482759 ], [ 29.768654683544302, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17160, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.444041034482759 ], [ 29.76892417721519, -1.444310517241379 ], [ 29.769193670886075, -1.444310517241379 ], [ 29.769193670886075, -1.444041034482759 ], [ 29.76892417721519, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17161, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.444041034482759 ], [ 29.769193670886075, -1.444310517241379 ], [ 29.76946316455696, -1.444310517241379 ], [ 29.76946316455696, -1.444041034482759 ], [ 29.769193670886075, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17162, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.444041034482759 ], [ 29.76946316455696, -1.444310517241379 ], [ 29.769732658227849, -1.444310517241379 ], [ 29.769732658227849, -1.444041034482759 ], [ 29.76946316455696, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17163, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.444041034482759 ], [ 29.769732658227849, -1.444310517241379 ], [ 29.770002151898733, -1.444310517241379 ], [ 29.770002151898733, -1.444041034482759 ], [ 29.769732658227849, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17164, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.444041034482759 ], [ 29.770002151898733, -1.444310517241379 ], [ 29.770271645569618, -1.444310517241379 ], [ 29.770271645569618, -1.444041034482759 ], [ 29.770002151898733, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17165, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.444041034482759 ], [ 29.770271645569618, -1.444310517241379 ], [ 29.770541139240507, -1.444310517241379 ], [ 29.770541139240507, -1.444041034482759 ], [ 29.770271645569618, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17166, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.444041034482759 ], [ 29.770541139240507, -1.444310517241379 ], [ 29.770810632911392, -1.444310517241379 ], [ 29.770810632911392, -1.444041034482759 ], [ 29.770541139240507, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17167, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.444041034482759 ], [ 29.770810632911392, -1.444310517241379 ], [ 29.771080126582277, -1.444310517241379 ], [ 29.771080126582277, -1.444041034482759 ], [ 29.770810632911392, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17168, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.444041034482759 ], [ 29.771080126582277, -1.444310517241379 ], [ 29.771349620253165, -1.444310517241379 ], [ 29.771349620253165, -1.444041034482759 ], [ 29.771080126582277, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17169, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.444041034482759 ], [ 29.771349620253165, -1.444310517241379 ], [ 29.77161911392405, -1.444310517241379 ], [ 29.77161911392405, -1.444041034482759 ], [ 29.771349620253165, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17170, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.444041034482759 ], [ 29.77161911392405, -1.444310517241379 ], [ 29.771888607594935, -1.444310517241379 ], [ 29.771888607594935, -1.444041034482759 ], [ 29.77161911392405, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17171, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.444041034482759 ], [ 29.771888607594935, -1.444310517241379 ], [ 29.772158101265823, -1.444310517241379 ], [ 29.772158101265823, -1.444041034482759 ], [ 29.771888607594935, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17172, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.444041034482759 ], [ 29.772158101265823, -1.444310517241379 ], [ 29.772427594936708, -1.444310517241379 ], [ 29.772427594936708, -1.444041034482759 ], [ 29.772158101265823, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17173, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.444041034482759 ], [ 29.772427594936708, -1.444310517241379 ], [ 29.772697088607593, -1.444310517241379 ], [ 29.772697088607593, -1.444041034482759 ], [ 29.772427594936708, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17174, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.444041034482759 ], [ 29.772697088607593, -1.444310517241379 ], [ 29.772966582278482, -1.444310517241379 ], [ 29.772966582278482, -1.444041034482759 ], [ 29.772697088607593, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17175, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.444041034482759 ], [ 29.772966582278482, -1.444310517241379 ], [ 29.773236075949367, -1.444310517241379 ], [ 29.773236075949367, -1.444041034482759 ], [ 29.772966582278482, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17176, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.444041034482759 ], [ 29.773236075949367, -1.444310517241379 ], [ 29.773505569620252, -1.444310517241379 ], [ 29.773505569620252, -1.444041034482759 ], [ 29.773236075949367, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17177, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.444041034482759 ], [ 29.773505569620252, -1.444310517241379 ], [ 29.77377506329114, -1.444310517241379 ], [ 29.77377506329114, -1.444041034482759 ], [ 29.773505569620252, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17178, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.444041034482759 ], [ 29.77377506329114, -1.444310517241379 ], [ 29.774044556962025, -1.444310517241379 ], [ 29.774044556962025, -1.444041034482759 ], [ 29.77377506329114, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17179, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.444041034482759 ], [ 29.774044556962025, -1.444310517241379 ], [ 29.77431405063291, -1.444310517241379 ], [ 29.77431405063291, -1.444041034482759 ], [ 29.774044556962025, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17180, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.444041034482759 ], [ 29.77431405063291, -1.444310517241379 ], [ 29.774853037974683, -1.444310517241379 ], [ 29.774853037974683, -1.444041034482759 ], [ 29.77431405063291, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17181, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.444041034482759 ], [ 29.774853037974683, -1.444310517241379 ], [ 29.775122531645568, -1.444310517241379 ], [ 29.775122531645568, -1.444041034482759 ], [ 29.774853037974683, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17182, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.444041034482759 ], [ 29.775122531645568, -1.444310517241379 ], [ 29.775392025316457, -1.444310517241379 ], [ 29.775392025316457, -1.444041034482759 ], [ 29.775122531645568, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17183, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.444041034482759 ], [ 29.775392025316457, -1.444310517241379 ], [ 29.775661518987341, -1.444310517241379 ], [ 29.775661518987341, -1.444041034482759 ], [ 29.775392025316457, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17184, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.444041034482759 ], [ 29.775661518987341, -1.444310517241379 ], [ 29.775931012658226, -1.444310517241379 ], [ 29.775931012658226, -1.444041034482759 ], [ 29.775661518987341, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17185, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.444041034482759 ], [ 29.775931012658226, -1.444310517241379 ], [ 29.776200506329115, -1.444310517241379 ], [ 29.776200506329115, -1.444041034482759 ], [ 29.775931012658226, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17186, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.444041034482759 ], [ 29.776200506329115, -1.444310517241379 ], [ 29.77647, -1.444310517241379 ], [ 29.77647, -1.444041034482759 ], [ 29.776200506329115, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17187, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.444041034482759 ], [ 29.77647, -1.444310517241379 ], [ 29.776739493670885, -1.444310517241379 ], [ 29.776739493670885, -1.444041034482759 ], [ 29.77647, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17188, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.444041034482759 ], [ 29.776739493670885, -1.444849482758621 ], [ 29.777008987341773, -1.444849482758621 ], [ 29.777008987341773, -1.444041034482759 ], [ 29.776739493670885, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17189, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.444041034482759 ], [ 29.777547974683543, -1.444849482758621 ], [ 29.777817468354431, -1.444849482758621 ], [ 29.777817468354431, -1.444041034482759 ], [ 29.777547974683543, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17190, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.444041034482759 ], [ 29.778086962025316, -1.444849482758621 ], [ 29.778356455696201, -1.444849482758621 ], [ 29.778356455696201, -1.444041034482759 ], [ 29.778086962025316, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17191, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.444041034482759 ], [ 29.778356455696201, -1.444849482758621 ], [ 29.77862594936709, -1.444849482758621 ], [ 29.77862594936709, -1.444041034482759 ], [ 29.778356455696201, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17192, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.444041034482759 ], [ 29.778895443037975, -1.444849482758621 ], [ 29.77916493670886, -1.444849482758621 ], [ 29.77916493670886, -1.444041034482759 ], [ 29.778895443037975, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17193, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.444041034482759 ], [ 29.779703924050633, -1.444849482758621 ], [ 29.779973417721518, -1.444849482758621 ], [ 29.779973417721518, -1.444041034482759 ], [ 29.779703924050633, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17194, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.444041034482759 ], [ 29.780242911392406, -1.444310517241379 ], [ 29.780512405063291, -1.444310517241379 ], [ 29.780512405063291, -1.444041034482759 ], [ 29.780242911392406, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17195, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.444041034482759 ], [ 29.780512405063291, -1.444849482758621 ], [ 29.780781898734176, -1.444849482758621 ], [ 29.780781898734176, -1.444041034482759 ], [ 29.780512405063291, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17196, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.444041034482759 ], [ 29.781051392405065, -1.444310517241379 ], [ 29.78132088607595, -1.444310517241379 ], [ 29.78132088607595, -1.444041034482759 ], [ 29.781051392405065, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17197, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.444041034482759 ], [ 29.78132088607595, -1.444310517241379 ], [ 29.781590379746834, -1.444310517241379 ], [ 29.781590379746834, -1.444041034482759 ], [ 29.78132088607595, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17198, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.444041034482759 ], [ 29.781859873417723, -1.444310517241379 ], [ 29.782129367088608, -1.444310517241379 ], [ 29.782129367088608, -1.444041034482759 ], [ 29.781859873417723, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17199, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.444041034482759 ], [ 29.782129367088608, -1.444310517241379 ], [ 29.782398860759493, -1.444310517241379 ], [ 29.782398860759493, -1.444041034482759 ], [ 29.782129367088608, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17200, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.444041034482759 ], [ 29.782668354430381, -1.444310517241379 ], [ 29.782937848101266, -1.444310517241379 ], [ 29.782937848101266, -1.444041034482759 ], [ 29.782668354430381, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17201, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.444041034482759 ], [ 29.782937848101266, -1.444310517241379 ], [ 29.783207341772151, -1.444310517241379 ], [ 29.783207341772151, -1.444041034482759 ], [ 29.782937848101266, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17202, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.444041034482759 ], [ 29.783207341772151, -1.444310517241379 ], [ 29.783476835443039, -1.444310517241379 ], [ 29.783476835443039, -1.444041034482759 ], [ 29.783207341772151, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17203, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.444041034482759 ], [ 29.783476835443039, -1.444310517241379 ], [ 29.784015822784809, -1.444310517241379 ], [ 29.784015822784809, -1.444041034482759 ], [ 29.783476835443039, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17204, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.444041034482759 ], [ 29.784015822784809, -1.444310517241379 ], [ 29.784285316455698, -1.444310517241379 ], [ 29.784285316455698, -1.444041034482759 ], [ 29.784015822784809, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17205, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.444041034482759 ], [ 29.784285316455698, -1.444310517241379 ], [ 29.784554810126583, -1.444310517241379 ], [ 29.784554810126583, -1.444041034482759 ], [ 29.784285316455698, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17206, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.444041034482759 ], [ 29.784554810126583, -1.444310517241379 ], [ 29.784824303797468, -1.444310517241379 ], [ 29.784824303797468, -1.444041034482759 ], [ 29.784554810126583, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17207, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.444041034482759 ], [ 29.784824303797468, -1.444310517241379 ], [ 29.785093797468356, -1.444310517241379 ], [ 29.785093797468356, -1.444041034482759 ], [ 29.784824303797468, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17208, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.444041034482759 ], [ 29.785093797468356, -1.444310517241379 ], [ 29.785363291139241, -1.444310517241379 ], [ 29.785363291139241, -1.444041034482759 ], [ 29.785093797468356, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17209, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.444041034482759 ], [ 29.785363291139241, -1.444310517241379 ], [ 29.785632784810126, -1.444310517241379 ], [ 29.785632784810126, -1.444041034482759 ], [ 29.785363291139241, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17210, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.444041034482759 ], [ 29.785632784810126, -1.444310517241379 ], [ 29.785902278481014, -1.444310517241379 ], [ 29.785902278481014, -1.444041034482759 ], [ 29.785632784810126, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17211, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.444041034482759 ], [ 29.785902278481014, -1.444310517241379 ], [ 29.786171772151899, -1.444310517241379 ], [ 29.786171772151899, -1.444041034482759 ], [ 29.785902278481014, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17212, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.444041034482759 ], [ 29.786171772151899, -1.444310517241379 ], [ 29.786441265822784, -1.444310517241379 ], [ 29.786441265822784, -1.444041034482759 ], [ 29.786171772151899, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17213, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.444041034482759 ], [ 29.786441265822784, -1.444310517241379 ], [ 29.786710759493673, -1.444310517241379 ], [ 29.786710759493673, -1.444041034482759 ], [ 29.786441265822784, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17214, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.444041034482759 ], [ 29.786710759493673, -1.444310517241379 ], [ 29.786980253164558, -1.444310517241379 ], [ 29.786980253164558, -1.444041034482759 ], [ 29.786710759493673, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17215, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.444041034482759 ], [ 29.786980253164558, -1.444849482758621 ], [ 29.787249746835442, -1.444849482758621 ], [ 29.787249746835442, -1.444041034482759 ], [ 29.786980253164558, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17216, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.444041034482759 ], [ 29.789405696202532, -1.444310517241379 ], [ 29.789944683544302, -1.444310517241379 ], [ 29.789944683544302, -1.444041034482759 ], [ 29.789405696202532, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17217, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.444041034482759 ], [ 29.789944683544302, -1.444310517241379 ], [ 29.790214177215191, -1.444310517241379 ], [ 29.790214177215191, -1.444041034482759 ], [ 29.789944683544302, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17218, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.444041034482759 ], [ 29.790214177215191, -1.444310517241379 ], [ 29.790483670886076, -1.444310517241379 ], [ 29.790483670886076, -1.444041034482759 ], [ 29.790214177215191, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17219, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.444041034482759 ], [ 29.790483670886076, -1.444310517241379 ], [ 29.790753164556961, -1.444310517241379 ], [ 29.790753164556961, -1.444041034482759 ], [ 29.790483670886076, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17220, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.444041034482759 ], [ 29.791292151898734, -1.444849482758621 ], [ 29.791561645569619, -1.444849482758621 ], [ 29.791561645569619, -1.444041034482759 ], [ 29.791292151898734, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17221, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.444041034482759 ], [ 29.792909113924051, -1.444849482758621 ], [ 29.793178607594935, -1.444849482758621 ], [ 29.793178607594935, -1.444041034482759 ], [ 29.792909113924051, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17222, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.444041034482759 ], [ 29.794526075949367, -1.444849482758621 ], [ 29.794795569620252, -1.444849482758621 ], [ 29.794795569620252, -1.444041034482759 ], [ 29.794526075949367, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17223, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.444041034482759 ], [ 29.795334556962025, -1.444310517241379 ], [ 29.79560405063291, -1.444310517241379 ], [ 29.79560405063291, -1.444041034482759 ], [ 29.795334556962025, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17224, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.444041034482759 ], [ 29.79560405063291, -1.444310517241379 ], [ 29.795873544303799, -1.444310517241379 ], [ 29.795873544303799, -1.444041034482759 ], [ 29.79560405063291, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17225, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.444041034482759 ], [ 29.795873544303799, -1.444849482758621 ], [ 29.796143037974684, -1.444849482758621 ], [ 29.796143037974684, -1.444041034482759 ], [ 29.795873544303799, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17226, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.444041034482759 ], [ 29.796143037974684, -1.444310517241379 ], [ 29.796412531645569, -1.444310517241379 ], [ 29.796412531645569, -1.444041034482759 ], [ 29.796143037974684, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17227, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.444041034482759 ], [ 29.796412531645569, -1.444310517241379 ], [ 29.796682025316457, -1.444310517241379 ], [ 29.796682025316457, -1.444041034482759 ], [ 29.796412531645569, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17228, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.444041034482759 ], [ 29.796682025316457, -1.444310517241379 ], [ 29.796951518987342, -1.444310517241379 ], [ 29.796951518987342, -1.444041034482759 ], [ 29.796682025316457, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17229, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.444041034482759 ], [ 29.800993924050633, -1.445657931034483 ], [ 29.801263417721518, -1.445657931034483 ], [ 29.801263417721518, -1.444041034482759 ], [ 29.800993924050633, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17230, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.444041034482759 ], [ 29.801263417721518, -1.445388448275862 ], [ 29.801532911392407, -1.445388448275862 ], [ 29.801532911392407, -1.444041034482759 ], [ 29.801263417721518, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17231, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.444041034482759 ], [ 29.801532911392407, -1.445388448275862 ], [ 29.802071898734177, -1.445388448275862 ], [ 29.802071898734177, -1.444041034482759 ], [ 29.801532911392407, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17232, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.444041034482759 ], [ 29.80261088607595, -1.445118965517241 ], [ 29.802880379746835, -1.445118965517241 ], [ 29.802880379746835, -1.444041034482759 ], [ 29.80261088607595, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17233, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.444041034482759 ], [ 29.803688860759493, -1.444310517241379 ], [ 29.803958354430382, -1.444310517241379 ], [ 29.803958354430382, -1.444041034482759 ], [ 29.803688860759493, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17234, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.444041034482759 ], [ 29.803958354430382, -1.444310517241379 ], [ 29.804227848101267, -1.444310517241379 ], [ 29.804227848101267, -1.444041034482759 ], [ 29.803958354430382, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17235, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.444041034482759 ], [ 29.804227848101267, -1.444310517241379 ], [ 29.804497341772151, -1.444310517241379 ], [ 29.804497341772151, -1.444041034482759 ], [ 29.804227848101267, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17236, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.444041034482759 ], [ 29.804497341772151, -1.444310517241379 ], [ 29.80476683544304, -1.444310517241379 ], [ 29.80476683544304, -1.444041034482759 ], [ 29.804497341772151, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17237, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.444041034482759 ], [ 29.80476683544304, -1.444310517241379 ], [ 29.805036329113925, -1.444310517241379 ], [ 29.805036329113925, -1.444041034482759 ], [ 29.80476683544304, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17238, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.444041034482759 ], [ 29.805036329113925, -1.444310517241379 ], [ 29.80530582278481, -1.444310517241379 ], [ 29.80530582278481, -1.444041034482759 ], [ 29.805036329113925, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17239, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.444041034482759 ], [ 29.80530582278481, -1.444310517241379 ], [ 29.805575316455698, -1.444310517241379 ], [ 29.805575316455698, -1.444041034482759 ], [ 29.80530582278481, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17240, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.444041034482759 ], [ 29.805575316455698, -1.444310517241379 ], [ 29.805844810126583, -1.444310517241379 ], [ 29.805844810126583, -1.444041034482759 ], [ 29.805575316455698, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17241, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.444041034482759 ], [ 29.805844810126583, -1.444310517241379 ], [ 29.806114303797468, -1.444310517241379 ], [ 29.806114303797468, -1.444041034482759 ], [ 29.805844810126583, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17242, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.444041034482759 ], [ 29.806114303797468, -1.444310517241379 ], [ 29.806383797468357, -1.444310517241379 ], [ 29.806383797468357, -1.444041034482759 ], [ 29.806114303797468, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17243, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.444041034482759 ], [ 29.806383797468357, -1.444310517241379 ], [ 29.806653291139241, -1.444310517241379 ], [ 29.806653291139241, -1.444041034482759 ], [ 29.806383797468357, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17244, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.444041034482759 ], [ 29.806653291139241, -1.444310517241379 ], [ 29.806922784810126, -1.444310517241379 ], [ 29.806922784810126, -1.444041034482759 ], [ 29.806653291139241, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17245, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.444041034482759 ], [ 29.806922784810126, -1.444310517241379 ], [ 29.807192278481015, -1.444310517241379 ], [ 29.807192278481015, -1.444041034482759 ], [ 29.806922784810126, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17246, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.444041034482759 ], [ 29.807192278481015, -1.444310517241379 ], [ 29.8074617721519, -1.444310517241379 ], [ 29.8074617721519, -1.444041034482759 ], [ 29.807192278481015, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17247, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.444310517241379 ], [ 29.749520632911391, -1.444849482758621 ], [ 29.749790126582276, -1.444849482758621 ], [ 29.749790126582276, -1.444310517241379 ], [ 29.749520632911391, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17248, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.444310517241379 ], [ 29.749790126582276, -1.444849482758621 ], [ 29.750059620253165, -1.444849482758621 ], [ 29.750059620253165, -1.444310517241379 ], [ 29.749790126582276, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17249, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.444310517241379 ], [ 29.75032911392405, -1.444849482758621 ], [ 29.750598607594934, -1.444849482758621 ], [ 29.750598607594934, -1.444310517241379 ], [ 29.75032911392405, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17250, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.444310517241379 ], [ 29.750598607594934, -1.444849482758621 ], [ 29.750868101265823, -1.444849482758621 ], [ 29.750868101265823, -1.444310517241379 ], [ 29.750598607594934, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17251, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.444310517241379 ], [ 29.750868101265823, -1.444849482758621 ], [ 29.751137594936708, -1.444849482758621 ], [ 29.751137594936708, -1.444310517241379 ], [ 29.750868101265823, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17252, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.444310517241379 ], [ 29.751137594936708, -1.444849482758621 ], [ 29.751407088607593, -1.444849482758621 ], [ 29.751407088607593, -1.444310517241379 ], [ 29.751137594936708, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17253, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.444310517241379 ], [ 29.751407088607593, -1.444849482758621 ], [ 29.751676582278481, -1.444849482758621 ], [ 29.751676582278481, -1.444310517241379 ], [ 29.751407088607593, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17254, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.444310517241379 ], [ 29.751676582278481, -1.444849482758621 ], [ 29.751946075949366, -1.444849482758621 ], [ 29.751946075949366, -1.444310517241379 ], [ 29.751676582278481, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17255, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.444310517241379 ], [ 29.751946075949366, -1.444849482758621 ], [ 29.752215569620251, -1.444849482758621 ], [ 29.752215569620251, -1.444310517241379 ], [ 29.751946075949366, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17256, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.444310517241379 ], [ 29.752215569620251, -1.444849482758621 ], [ 29.75248506329114, -1.444849482758621 ], [ 29.75248506329114, -1.444310517241379 ], [ 29.752215569620251, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17257, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.444310517241379 ], [ 29.75248506329114, -1.444849482758621 ], [ 29.752754556962024, -1.444849482758621 ], [ 29.752754556962024, -1.444310517241379 ], [ 29.75248506329114, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17258, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.444310517241379 ], [ 29.752754556962024, -1.444849482758621 ], [ 29.753024050632909, -1.444849482758621 ], [ 29.753024050632909, -1.444310517241379 ], [ 29.752754556962024, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17259, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.444310517241379 ], [ 29.753024050632909, -1.444849482758621 ], [ 29.753293544303798, -1.444849482758621 ], [ 29.753293544303798, -1.444310517241379 ], [ 29.753024050632909, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17260, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.444310517241379 ], [ 29.753293544303798, -1.444849482758621 ], [ 29.753563037974683, -1.444849482758621 ], [ 29.753563037974683, -1.444310517241379 ], [ 29.753293544303798, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17261, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.444310517241379 ], [ 29.753563037974683, -1.444849482758621 ], [ 29.753832531645568, -1.444849482758621 ], [ 29.753832531645568, -1.444310517241379 ], [ 29.753563037974683, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17262, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.444310517241379 ], [ 29.753832531645568, -1.444849482758621 ], [ 29.754102025316456, -1.444849482758621 ], [ 29.754102025316456, -1.444310517241379 ], [ 29.753832531645568, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17263, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.444310517241379 ], [ 29.754102025316456, -1.444849482758621 ], [ 29.754371518987341, -1.444849482758621 ], [ 29.754371518987341, -1.444310517241379 ], [ 29.754102025316456, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17264, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.444310517241379 ], [ 29.754371518987341, -1.444849482758621 ], [ 29.754641012658226, -1.444849482758621 ], [ 29.754641012658226, -1.444310517241379 ], [ 29.754371518987341, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17265, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.444310517241379 ], [ 29.754641012658226, -1.444849482758621 ], [ 29.754910506329114, -1.444849482758621 ], [ 29.754910506329114, -1.444310517241379 ], [ 29.754641012658226, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17266, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.444310517241379 ], [ 29.754910506329114, -1.444849482758621 ], [ 29.75518, -1.444849482758621 ], [ 29.75518, -1.444310517241379 ], [ 29.754910506329114, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17267, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.444310517241379 ], [ 29.75518, -1.444849482758621 ], [ 29.755449493670884, -1.444849482758621 ], [ 29.755449493670884, -1.444310517241379 ], [ 29.75518, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17268, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.444310517241379 ], [ 29.755449493670884, -1.444849482758621 ], [ 29.755718987341773, -1.444849482758621 ], [ 29.755718987341773, -1.444310517241379 ], [ 29.755449493670884, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17269, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.444310517241379 ], [ 29.755718987341773, -1.444849482758621 ], [ 29.755988481012658, -1.444849482758621 ], [ 29.755988481012658, -1.444310517241379 ], [ 29.755718987341773, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17270, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.444310517241379 ], [ 29.755988481012658, -1.444849482758621 ], [ 29.756257974683542, -1.444849482758621 ], [ 29.756257974683542, -1.444310517241379 ], [ 29.755988481012658, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17271, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.444310517241379 ], [ 29.756257974683542, -1.444849482758621 ], [ 29.756796962025316, -1.444849482758621 ], [ 29.756796962025316, -1.444310517241379 ], [ 29.756257974683542, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17272, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.444310517241379 ], [ 29.756796962025316, -1.444849482758621 ], [ 29.757066455696201, -1.444849482758621 ], [ 29.757066455696201, -1.444310517241379 ], [ 29.756796962025316, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17273, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.444310517241379 ], [ 29.757066455696201, -1.444849482758621 ], [ 29.757335949367089, -1.444849482758621 ], [ 29.757335949367089, -1.444310517241379 ], [ 29.757066455696201, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17274, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.444310517241379 ], [ 29.757335949367089, -1.444849482758621 ], [ 29.757605443037974, -1.444849482758621 ], [ 29.757605443037974, -1.444310517241379 ], [ 29.757335949367089, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17275, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.444310517241379 ], [ 29.757605443037974, -1.444849482758621 ], [ 29.758144430379748, -1.444849482758621 ], [ 29.758144430379748, -1.444310517241379 ], [ 29.757605443037974, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17276, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.444310517241379 ], [ 29.758144430379748, -1.444849482758621 ], [ 29.758413924050632, -1.444849482758621 ], [ 29.758413924050632, -1.444310517241379 ], [ 29.758144430379748, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17277, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.444310517241379 ], [ 29.758413924050632, -1.444849482758621 ], [ 29.758952911392406, -1.444849482758621 ], [ 29.758952911392406, -1.444310517241379 ], [ 29.758413924050632, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17278, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.444310517241379 ], [ 29.758952911392406, -1.444849482758621 ], [ 29.759491898734176, -1.444849482758621 ], [ 29.759491898734176, -1.445118965517241 ], [ 29.759761392405064, -1.445118965517241 ], [ 29.759761392405064, -1.444849482758621 ], [ 29.760030886075949, -1.444849482758621 ], [ 29.760030886075949, -1.444310517241379 ], [ 29.758952911392406, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17279, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.444310517241379 ], [ 29.760030886075949, -1.444849482758621 ], [ 29.760300379746834, -1.444849482758621 ], [ 29.760300379746834, -1.444310517241379 ], [ 29.760030886075949, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17280, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.444310517241379 ], [ 29.760569873417722, -1.444849482758621 ], [ 29.760839367088607, -1.444849482758621 ], [ 29.760839367088607, -1.444310517241379 ], [ 29.760569873417722, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17281, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.444310517241379 ], [ 29.760839367088607, -1.444849482758621 ], [ 29.761108860759492, -1.444849482758621 ], [ 29.761108860759492, -1.444310517241379 ], [ 29.760839367088607, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17282, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.444310517241379 ], [ 29.761108860759492, -1.444849482758621 ], [ 29.761378354430381, -1.444849482758621 ], [ 29.761378354430381, -1.444310517241379 ], [ 29.761108860759492, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17283, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.444310517241379 ], [ 29.761378354430381, -1.444849482758621 ], [ 29.761647848101266, -1.444849482758621 ], [ 29.761647848101266, -1.444310517241379 ], [ 29.761378354430381, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17284, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.444310517241379 ], [ 29.761647848101266, -1.444849482758621 ], [ 29.761917341772151, -1.444849482758621 ], [ 29.761917341772151, -1.444310517241379 ], [ 29.761647848101266, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17285, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.444310517241379 ], [ 29.761917341772151, -1.444849482758621 ], [ 29.763264810126582, -1.444849482758621 ], [ 29.763264810126582, -1.444310517241379 ], [ 29.761917341772151, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17286, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.444310517241379 ], [ 29.763264810126582, -1.444849482758621 ], [ 29.763803797468356, -1.444849482758621 ], [ 29.763803797468356, -1.444310517241379 ], [ 29.763264810126582, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17287, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.444310517241379 ], [ 29.763803797468356, -1.444849482758621 ], [ 29.76407329113924, -1.444849482758621 ], [ 29.76407329113924, -1.444310517241379 ], [ 29.763803797468356, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17288, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.444310517241379 ], [ 29.76407329113924, -1.444849482758621 ], [ 29.764342784810125, -1.444849482758621 ], [ 29.764342784810125, -1.444310517241379 ], [ 29.76407329113924, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17289, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.444310517241379 ], [ 29.764342784810125, -1.444849482758621 ], [ 29.764612278481014, -1.444849482758621 ], [ 29.764612278481014, -1.444310517241379 ], [ 29.764342784810125, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17290, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.444310517241379 ], [ 29.764612278481014, -1.444849482758621 ], [ 29.764881772151899, -1.444849482758621 ], [ 29.764881772151899, -1.444310517241379 ], [ 29.764612278481014, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17291, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.444310517241379 ], [ 29.764881772151899, -1.444849482758621 ], [ 29.765420759493672, -1.444849482758621 ], [ 29.765420759493672, -1.444310517241379 ], [ 29.764881772151899, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17292, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.444310517241379 ], [ 29.765420759493672, -1.444849482758621 ], [ 29.767846202531643, -1.444849482758621 ], [ 29.767846202531643, -1.444310517241379 ], [ 29.765420759493672, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17293, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.444310517241379 ], [ 29.767846202531643, -1.444849482758621 ], [ 29.768385189873417, -1.444849482758621 ], [ 29.768385189873417, -1.444310517241379 ], [ 29.767846202531643, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17294, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.444310517241379 ], [ 29.768385189873417, -1.444849482758621 ], [ 29.768654683544302, -1.444849482758621 ], [ 29.768654683544302, -1.444310517241379 ], [ 29.768385189873417, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17295, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.444310517241379 ], [ 29.768654683544302, -1.444849482758621 ], [ 29.76892417721519, -1.444849482758621 ], [ 29.76892417721519, -1.444310517241379 ], [ 29.768654683544302, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17296, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.444310517241379 ], [ 29.76892417721519, -1.444849482758621 ], [ 29.769193670886075, -1.444849482758621 ], [ 29.769193670886075, -1.444310517241379 ], [ 29.76892417721519, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17297, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.444310517241379 ], [ 29.769193670886075, -1.444849482758621 ], [ 29.76946316455696, -1.444849482758621 ], [ 29.76946316455696, -1.444310517241379 ], [ 29.769193670886075, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17298, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.444310517241379 ], [ 29.76946316455696, -1.444849482758621 ], [ 29.769732658227849, -1.444849482758621 ], [ 29.769732658227849, -1.444310517241379 ], [ 29.76946316455696, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17299, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.444310517241379 ], [ 29.769732658227849, -1.444849482758621 ], [ 29.770002151898733, -1.444849482758621 ], [ 29.770002151898733, -1.444310517241379 ], [ 29.769732658227849, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17300, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.444310517241379 ], [ 29.770002151898733, -1.444849482758621 ], [ 29.770271645569618, -1.444849482758621 ], [ 29.770271645569618, -1.444310517241379 ], [ 29.770002151898733, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17301, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.444310517241379 ], [ 29.770271645569618, -1.444849482758621 ], [ 29.770541139240507, -1.444849482758621 ], [ 29.770541139240507, -1.444310517241379 ], [ 29.770271645569618, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17302, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.444310517241379 ], [ 29.770541139240507, -1.444849482758621 ], [ 29.770810632911392, -1.444849482758621 ], [ 29.770810632911392, -1.444310517241379 ], [ 29.770541139240507, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17303, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.444310517241379 ], [ 29.770810632911392, -1.444849482758621 ], [ 29.771080126582277, -1.444849482758621 ], [ 29.771080126582277, -1.444310517241379 ], [ 29.770810632911392, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17304, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.444310517241379 ], [ 29.771080126582277, -1.444849482758621 ], [ 29.771349620253165, -1.444849482758621 ], [ 29.771349620253165, -1.444310517241379 ], [ 29.771080126582277, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17305, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.444310517241379 ], [ 29.771349620253165, -1.444849482758621 ], [ 29.77161911392405, -1.444849482758621 ], [ 29.77161911392405, -1.444310517241379 ], [ 29.771349620253165, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17306, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.444310517241379 ], [ 29.77161911392405, -1.444849482758621 ], [ 29.771888607594935, -1.444849482758621 ], [ 29.771888607594935, -1.444310517241379 ], [ 29.77161911392405, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17307, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.444310517241379 ], [ 29.771888607594935, -1.444849482758621 ], [ 29.772158101265823, -1.444849482758621 ], [ 29.772158101265823, -1.444310517241379 ], [ 29.771888607594935, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17308, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.444310517241379 ], [ 29.772158101265823, -1.444849482758621 ], [ 29.772427594936708, -1.444849482758621 ], [ 29.772427594936708, -1.444310517241379 ], [ 29.772158101265823, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17309, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.444310517241379 ], [ 29.772427594936708, -1.444849482758621 ], [ 29.772697088607593, -1.444849482758621 ], [ 29.772697088607593, -1.444310517241379 ], [ 29.772427594936708, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17310, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.444310517241379 ], [ 29.772697088607593, -1.444849482758621 ], [ 29.772966582278482, -1.444849482758621 ], [ 29.772966582278482, -1.444310517241379 ], [ 29.772697088607593, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17311, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.444310517241379 ], [ 29.772966582278482, -1.444849482758621 ], [ 29.773236075949367, -1.444849482758621 ], [ 29.773236075949367, -1.444310517241379 ], [ 29.772966582278482, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17312, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.444310517241379 ], [ 29.773236075949367, -1.444849482758621 ], [ 29.773505569620252, -1.444849482758621 ], [ 29.773505569620252, -1.444310517241379 ], [ 29.773236075949367, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17313, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.444310517241379 ], [ 29.773505569620252, -1.444849482758621 ], [ 29.77377506329114, -1.444849482758621 ], [ 29.77377506329114, -1.444310517241379 ], [ 29.773505569620252, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17314, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.444310517241379 ], [ 29.77377506329114, -1.444849482758621 ], [ 29.774044556962025, -1.444849482758621 ], [ 29.774044556962025, -1.444310517241379 ], [ 29.77377506329114, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17315, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.444310517241379 ], [ 29.774044556962025, -1.444849482758621 ], [ 29.77431405063291, -1.444849482758621 ], [ 29.77431405063291, -1.444310517241379 ], [ 29.774044556962025, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17316, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.444310517241379 ], [ 29.77431405063291, -1.444849482758621 ], [ 29.774853037974683, -1.444849482758621 ], [ 29.774853037974683, -1.444310517241379 ], [ 29.77431405063291, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17317, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.444310517241379 ], [ 29.774853037974683, -1.444849482758621 ], [ 29.775122531645568, -1.444849482758621 ], [ 29.775122531645568, -1.444310517241379 ], [ 29.774853037974683, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17318, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.444310517241379 ], [ 29.775122531645568, -1.444849482758621 ], [ 29.775392025316457, -1.444849482758621 ], [ 29.775392025316457, -1.444310517241379 ], [ 29.775122531645568, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17319, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.444310517241379 ], [ 29.775392025316457, -1.444849482758621 ], [ 29.775661518987341, -1.444849482758621 ], [ 29.775661518987341, -1.444310517241379 ], [ 29.775392025316457, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17320, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.444310517241379 ], [ 29.775661518987341, -1.444849482758621 ], [ 29.775931012658226, -1.444849482758621 ], [ 29.775931012658226, -1.444310517241379 ], [ 29.775661518987341, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17321, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.444310517241379 ], [ 29.775931012658226, -1.444849482758621 ], [ 29.776200506329115, -1.444849482758621 ], [ 29.776200506329115, -1.444310517241379 ], [ 29.775931012658226, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17322, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.444310517241379 ], [ 29.776200506329115, -1.444849482758621 ], [ 29.77647, -1.444849482758621 ], [ 29.77647, -1.444310517241379 ], [ 29.776200506329115, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17323, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.444310517241379 ], [ 29.77647, -1.444849482758621 ], [ 29.776739493670885, -1.444849482758621 ], [ 29.776739493670885, -1.444310517241379 ], [ 29.77647, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17324, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.444310517241379 ], [ 29.777008987341773, -1.445118965517241 ], [ 29.777278481012658, -1.445118965517241 ], [ 29.777278481012658, -1.444310517241379 ], [ 29.777008987341773, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17325, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.444310517241379 ], [ 29.777817468354431, -1.445118965517241 ], [ 29.778086962025316, -1.445118965517241 ], [ 29.778086962025316, -1.444310517241379 ], [ 29.777817468354431, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17326, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.444310517241379 ], [ 29.77862594936709, -1.445118965517241 ], [ 29.778895443037975, -1.445118965517241 ], [ 29.778895443037975, -1.444310517241379 ], [ 29.77862594936709, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17327, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.444310517241379 ], [ 29.77916493670886, -1.444849482758621 ], [ 29.779434430379748, -1.444849482758621 ], [ 29.779434430379748, -1.444310517241379 ], [ 29.77916493670886, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17328, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.444310517241379 ], [ 29.779434430379748, -1.445118965517241 ], [ 29.779703924050633, -1.445118965517241 ], [ 29.779703924050633, -1.444310517241379 ], [ 29.779434430379748, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17329, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.444310517241379 ], [ 29.779973417721518, -1.444849482758621 ], [ 29.780242911392406, -1.444849482758621 ], [ 29.780242911392406, -1.444310517241379 ], [ 29.779973417721518, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17330, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.444310517241379 ], [ 29.780242911392406, -1.444849482758621 ], [ 29.780512405063291, -1.444849482758621 ], [ 29.780512405063291, -1.444310517241379 ], [ 29.780242911392406, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17331, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.444310517241379 ], [ 29.780781898734176, -1.444849482758621 ], [ 29.781051392405065, -1.444849482758621 ], [ 29.781051392405065, -1.444310517241379 ], [ 29.780781898734176, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17332, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.444310517241379 ], [ 29.781051392405065, -1.444849482758621 ], [ 29.78132088607595, -1.444849482758621 ], [ 29.78132088607595, -1.444310517241379 ], [ 29.781051392405065, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17333, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.444310517241379 ], [ 29.78132088607595, -1.445118965517241 ], [ 29.781590379746834, -1.445118965517241 ], [ 29.781590379746834, -1.444310517241379 ], [ 29.78132088607595, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17334, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.444310517241379 ], [ 29.781590379746834, -1.444849482758621 ], [ 29.781859873417723, -1.444849482758621 ], [ 29.781859873417723, -1.444310517241379 ], [ 29.781590379746834, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17335, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.444310517241379 ], [ 29.781859873417723, -1.444849482758621 ], [ 29.782129367088608, -1.444849482758621 ], [ 29.782129367088608, -1.444310517241379 ], [ 29.781859873417723, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17336, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.444310517241379 ], [ 29.782129367088608, -1.444849482758621 ], [ 29.782398860759493, -1.444849482758621 ], [ 29.782398860759493, -1.444310517241379 ], [ 29.782129367088608, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17337, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.444310517241379 ], [ 29.782398860759493, -1.444849482758621 ], [ 29.782668354430381, -1.444849482758621 ], [ 29.782668354430381, -1.444310517241379 ], [ 29.782398860759493, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17338, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.444310517241379 ], [ 29.782668354430381, -1.444849482758621 ], [ 29.782937848101266, -1.444849482758621 ], [ 29.782937848101266, -1.444310517241379 ], [ 29.782668354430381, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17339, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.444310517241379 ], [ 29.782937848101266, -1.444849482758621 ], [ 29.783207341772151, -1.444849482758621 ], [ 29.783207341772151, -1.444310517241379 ], [ 29.782937848101266, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17340, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.444310517241379 ], [ 29.783207341772151, -1.444849482758621 ], [ 29.783476835443039, -1.444849482758621 ], [ 29.783476835443039, -1.444310517241379 ], [ 29.783207341772151, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17341, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.444310517241379 ], [ 29.783476835443039, -1.444849482758621 ], [ 29.784015822784809, -1.444849482758621 ], [ 29.784015822784809, -1.444310517241379 ], [ 29.783476835443039, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17342, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.444310517241379 ], [ 29.784015822784809, -1.444849482758621 ], [ 29.784285316455698, -1.444849482758621 ], [ 29.784285316455698, -1.444310517241379 ], [ 29.784015822784809, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17343, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.444310517241379 ], [ 29.784285316455698, -1.444849482758621 ], [ 29.784554810126583, -1.444849482758621 ], [ 29.784554810126583, -1.444310517241379 ], [ 29.784285316455698, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17344, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.444310517241379 ], [ 29.784554810126583, -1.444849482758621 ], [ 29.784824303797468, -1.444849482758621 ], [ 29.784824303797468, -1.444310517241379 ], [ 29.784554810126583, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17345, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.444310517241379 ], [ 29.784824303797468, -1.444849482758621 ], [ 29.785093797468356, -1.444849482758621 ], [ 29.785093797468356, -1.444310517241379 ], [ 29.784824303797468, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17346, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.444310517241379 ], [ 29.785093797468356, -1.444849482758621 ], [ 29.785363291139241, -1.444849482758621 ], [ 29.785363291139241, -1.444310517241379 ], [ 29.785093797468356, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17347, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.444310517241379 ], [ 29.785363291139241, -1.444849482758621 ], [ 29.785632784810126, -1.444849482758621 ], [ 29.785632784810126, -1.444310517241379 ], [ 29.785363291139241, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17348, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.444310517241379 ], [ 29.785632784810126, -1.444849482758621 ], [ 29.785902278481014, -1.444849482758621 ], [ 29.785902278481014, -1.444310517241379 ], [ 29.785632784810126, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17349, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.444310517241379 ], [ 29.785902278481014, -1.444849482758621 ], [ 29.786171772151899, -1.444849482758621 ], [ 29.786171772151899, -1.444310517241379 ], [ 29.785902278481014, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17350, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.444310517241379 ], [ 29.786171772151899, -1.444849482758621 ], [ 29.786441265822784, -1.444849482758621 ], [ 29.786441265822784, -1.444310517241379 ], [ 29.786171772151899, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17351, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.444310517241379 ], [ 29.786441265822784, -1.444849482758621 ], [ 29.786710759493673, -1.444849482758621 ], [ 29.786710759493673, -1.444310517241379 ], [ 29.786441265822784, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17352, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.444310517241379 ], [ 29.786710759493673, -1.444849482758621 ], [ 29.786980253164558, -1.444849482758621 ], [ 29.786980253164558, -1.444310517241379 ], [ 29.786710759493673, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17353, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.444310517241379 ], [ 29.788327721518986, -1.445118965517241 ], [ 29.788597215189874, -1.445118965517241 ], [ 29.788597215189874, -1.444849482758621 ], [ 29.789405696202532, -1.444849482758621 ], [ 29.789405696202532, -1.444310517241379 ], [ 29.788327721518986, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17354, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.444310517241379 ], [ 29.789405696202532, -1.444849482758621 ], [ 29.789675189873417, -1.444849482758621 ], [ 29.789675189873417, -1.444310517241379 ], [ 29.789405696202532, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17355, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.444310517241379 ], [ 29.789675189873417, -1.444849482758621 ], [ 29.789944683544302, -1.444849482758621 ], [ 29.789944683544302, -1.444310517241379 ], [ 29.789675189873417, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17356, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.444310517241379 ], [ 29.789944683544302, -1.444849482758621 ], [ 29.790214177215191, -1.444849482758621 ], [ 29.790214177215191, -1.444310517241379 ], [ 29.789944683544302, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17357, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.444310517241379 ], [ 29.790214177215191, -1.444849482758621 ], [ 29.790483670886076, -1.444849482758621 ], [ 29.790483670886076, -1.444310517241379 ], [ 29.790214177215191, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17358, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.444310517241379 ], [ 29.790483670886076, -1.444849482758621 ], [ 29.790753164556961, -1.444849482758621 ], [ 29.790753164556961, -1.444310517241379 ], [ 29.790483670886076, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17359, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.444310517241379 ], [ 29.790753164556961, -1.444849482758621 ], [ 29.791022658227849, -1.444849482758621 ], [ 29.791022658227849, -1.444310517241379 ], [ 29.790753164556961, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17360, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.444310517241379 ], [ 29.791022658227849, -1.444849482758621 ], [ 29.791292151898734, -1.444849482758621 ], [ 29.791292151898734, -1.444310517241379 ], [ 29.791022658227849, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17361, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.444310517241379 ], [ 29.791561645569619, -1.444849482758621 ], [ 29.791831139240507, -1.444849482758621 ], [ 29.791831139240507, -1.444310517241379 ], [ 29.791561645569619, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17362, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.444310517241379 ], [ 29.791831139240507, -1.444849482758621 ], [ 29.792100632911392, -1.444849482758621 ], [ 29.792100632911392, -1.444310517241379 ], [ 29.791831139240507, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17363, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.444310517241379 ], [ 29.792370126582277, -1.445118965517241 ], [ 29.792909113924051, -1.445118965517241 ], [ 29.792909113924051, -1.444310517241379 ], [ 29.792370126582277, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17364, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.444310517241379 ], [ 29.793448101265824, -1.445118965517241 ], [ 29.793717594936709, -1.445118965517241 ], [ 29.793717594936709, -1.444310517241379 ], [ 29.793448101265824, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17365, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.444310517241379 ], [ 29.796682025316457, -1.444849482758621 ], [ 29.796951518987342, -1.444849482758621 ], [ 29.796951518987342, -1.444310517241379 ], [ 29.796682025316457, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17366, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.444310517241379 ], [ 29.796951518987342, -1.444849482758621 ], [ 29.797221012658227, -1.444849482758621 ], [ 29.797221012658227, -1.444310517241379 ], [ 29.796951518987342, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17367, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.444310517241379 ], [ 29.797221012658227, -1.444849482758621 ], [ 29.797490506329115, -1.444849482758621 ], [ 29.797490506329115, -1.444310517241379 ], [ 29.797221012658227, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17368, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.444310517241379 ], [ 29.803419367088608, -1.444849482758621 ], [ 29.803688860759493, -1.444849482758621 ], [ 29.803688860759493, -1.444310517241379 ], [ 29.803419367088608, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17369, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.444310517241379 ], [ 29.803688860759493, -1.444849482758621 ], [ 29.803958354430382, -1.444849482758621 ], [ 29.803958354430382, -1.444310517241379 ], [ 29.803688860759493, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17370, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.444310517241379 ], [ 29.803958354430382, -1.444849482758621 ], [ 29.804227848101267, -1.444849482758621 ], [ 29.804227848101267, -1.444310517241379 ], [ 29.803958354430382, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17371, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.444310517241379 ], [ 29.804227848101267, -1.444849482758621 ], [ 29.804497341772151, -1.444849482758621 ], [ 29.804497341772151, -1.444310517241379 ], [ 29.804227848101267, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17372, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.444310517241379 ], [ 29.804497341772151, -1.444849482758621 ], [ 29.80476683544304, -1.444849482758621 ], [ 29.80476683544304, -1.444310517241379 ], [ 29.804497341772151, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17373, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.444310517241379 ], [ 29.80476683544304, -1.444849482758621 ], [ 29.805036329113925, -1.444849482758621 ], [ 29.805036329113925, -1.444310517241379 ], [ 29.80476683544304, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17374, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.444310517241379 ], [ 29.805036329113925, -1.444849482758621 ], [ 29.80530582278481, -1.444849482758621 ], [ 29.80530582278481, -1.444310517241379 ], [ 29.805036329113925, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17375, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.444310517241379 ], [ 29.80530582278481, -1.444849482758621 ], [ 29.805575316455698, -1.444849482758621 ], [ 29.805575316455698, -1.444310517241379 ], [ 29.80530582278481, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17376, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.444310517241379 ], [ 29.805575316455698, -1.444849482758621 ], [ 29.805844810126583, -1.444849482758621 ], [ 29.805844810126583, -1.444310517241379 ], [ 29.805575316455698, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17377, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.444310517241379 ], [ 29.805844810126583, -1.444849482758621 ], [ 29.806114303797468, -1.444849482758621 ], [ 29.806114303797468, -1.444310517241379 ], [ 29.805844810126583, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17378, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.444310517241379 ], [ 29.806114303797468, -1.444849482758621 ], [ 29.806383797468357, -1.444849482758621 ], [ 29.806383797468357, -1.444310517241379 ], [ 29.806114303797468, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17379, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.444310517241379 ], [ 29.806383797468357, -1.444849482758621 ], [ 29.806653291139241, -1.444849482758621 ], [ 29.806653291139241, -1.444310517241379 ], [ 29.806383797468357, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17380, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.444310517241379 ], [ 29.806653291139241, -1.444849482758621 ], [ 29.806922784810126, -1.444849482758621 ], [ 29.806922784810126, -1.444310517241379 ], [ 29.806653291139241, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17381, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.444310517241379 ], [ 29.806922784810126, -1.444849482758621 ], [ 29.807192278481015, -1.444849482758621 ], [ 29.807192278481015, -1.444310517241379 ], [ 29.806922784810126, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17382, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.444310517241379 ], [ 29.807192278481015, -1.444849482758621 ], [ 29.8074617721519, -1.444849482758621 ], [ 29.8074617721519, -1.444310517241379 ], [ 29.807192278481015, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17383, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.444310517241379 ], [ 29.8074617721519, -1.445118965517241 ], [ 29.807731265822785, -1.445118965517241 ], [ 29.807731265822785, -1.444310517241379 ], [ 29.8074617721519, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17384, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.444849482758621 ], [ 29.748981645569618, -1.445118965517241 ], [ 29.749251139240506, -1.445118965517241 ], [ 29.749251139240506, -1.444849482758621 ], [ 29.748981645569618, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17385, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.444849482758621 ], [ 29.749251139240506, -1.445118965517241 ], [ 29.749520632911391, -1.445118965517241 ], [ 29.749520632911391, -1.444849482758621 ], [ 29.749251139240506, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17386, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.444849482758621 ], [ 29.749520632911391, -1.445118965517241 ], [ 29.749790126582276, -1.445118965517241 ], [ 29.749790126582276, -1.444849482758621 ], [ 29.749520632911391, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17387, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.444849482758621 ], [ 29.749790126582276, -1.445118965517241 ], [ 29.750059620253165, -1.445118965517241 ], [ 29.750059620253165, -1.444849482758621 ], [ 29.749790126582276, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17388, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.444849482758621 ], [ 29.750059620253165, -1.445118965517241 ], [ 29.75032911392405, -1.445118965517241 ], [ 29.75032911392405, -1.444849482758621 ], [ 29.750059620253165, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17389, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.444849482758621 ], [ 29.75032911392405, -1.445118965517241 ], [ 29.750598607594934, -1.445118965517241 ], [ 29.750598607594934, -1.444849482758621 ], [ 29.75032911392405, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17390, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.444849482758621 ], [ 29.750598607594934, -1.445118965517241 ], [ 29.750868101265823, -1.445118965517241 ], [ 29.750868101265823, -1.444849482758621 ], [ 29.750598607594934, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17391, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.444849482758621 ], [ 29.750868101265823, -1.445118965517241 ], [ 29.751137594936708, -1.445118965517241 ], [ 29.751137594936708, -1.444849482758621 ], [ 29.750868101265823, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17392, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.444849482758621 ], [ 29.751137594936708, -1.445118965517241 ], [ 29.751407088607593, -1.445118965517241 ], [ 29.751407088607593, -1.444849482758621 ], [ 29.751137594936708, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17393, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.444849482758621 ], [ 29.751407088607593, -1.445118965517241 ], [ 29.751676582278481, -1.445118965517241 ], [ 29.751676582278481, -1.444849482758621 ], [ 29.751407088607593, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17394, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.444849482758621 ], [ 29.751676582278481, -1.445118965517241 ], [ 29.751946075949366, -1.445118965517241 ], [ 29.751946075949366, -1.444849482758621 ], [ 29.751676582278481, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17395, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.444849482758621 ], [ 29.751946075949366, -1.445118965517241 ], [ 29.752215569620251, -1.445118965517241 ], [ 29.752215569620251, -1.444849482758621 ], [ 29.751946075949366, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17396, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.444849482758621 ], [ 29.752215569620251, -1.445118965517241 ], [ 29.75248506329114, -1.445118965517241 ], [ 29.75248506329114, -1.444849482758621 ], [ 29.752215569620251, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17397, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.444849482758621 ], [ 29.75248506329114, -1.445118965517241 ], [ 29.752754556962024, -1.445118965517241 ], [ 29.752754556962024, -1.444849482758621 ], [ 29.75248506329114, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17398, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.444849482758621 ], [ 29.752754556962024, -1.445118965517241 ], [ 29.753024050632909, -1.445118965517241 ], [ 29.753024050632909, -1.444849482758621 ], [ 29.752754556962024, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17399, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.444849482758621 ], [ 29.753024050632909, -1.445118965517241 ], [ 29.753293544303798, -1.445118965517241 ], [ 29.753293544303798, -1.444849482758621 ], [ 29.753024050632909, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17400, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.444849482758621 ], [ 29.753293544303798, -1.445118965517241 ], [ 29.753563037974683, -1.445118965517241 ], [ 29.753563037974683, -1.444849482758621 ], [ 29.753293544303798, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17401, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.444849482758621 ], [ 29.753563037974683, -1.445118965517241 ], [ 29.753832531645568, -1.445118965517241 ], [ 29.753832531645568, -1.444849482758621 ], [ 29.753563037974683, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17402, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.444849482758621 ], [ 29.753832531645568, -1.445118965517241 ], [ 29.754102025316456, -1.445118965517241 ], [ 29.754102025316456, -1.444849482758621 ], [ 29.753832531645568, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17403, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.444849482758621 ], [ 29.754102025316456, -1.445118965517241 ], [ 29.754371518987341, -1.445118965517241 ], [ 29.754371518987341, -1.444849482758621 ], [ 29.754102025316456, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17404, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.444849482758621 ], [ 29.754371518987341, -1.445118965517241 ], [ 29.754641012658226, -1.445118965517241 ], [ 29.754641012658226, -1.444849482758621 ], [ 29.754371518987341, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17405, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.444849482758621 ], [ 29.754641012658226, -1.445118965517241 ], [ 29.754910506329114, -1.445118965517241 ], [ 29.754910506329114, -1.444849482758621 ], [ 29.754641012658226, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17406, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.444849482758621 ], [ 29.754910506329114, -1.445118965517241 ], [ 29.75518, -1.445118965517241 ], [ 29.75518, -1.444849482758621 ], [ 29.754910506329114, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17407, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.444849482758621 ], [ 29.75518, -1.445118965517241 ], [ 29.755449493670884, -1.445118965517241 ], [ 29.755449493670884, -1.444849482758621 ], [ 29.75518, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17408, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.444849482758621 ], [ 29.755449493670884, -1.445118965517241 ], [ 29.755718987341773, -1.445118965517241 ], [ 29.755718987341773, -1.444849482758621 ], [ 29.755449493670884, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17409, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.444849482758621 ], [ 29.755718987341773, -1.445118965517241 ], [ 29.755988481012658, -1.445118965517241 ], [ 29.755988481012658, -1.444849482758621 ], [ 29.755718987341773, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17410, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.444849482758621 ], [ 29.755988481012658, -1.445118965517241 ], [ 29.756257974683542, -1.445118965517241 ], [ 29.756257974683542, -1.444849482758621 ], [ 29.755988481012658, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17411, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.444849482758621 ], [ 29.756257974683542, -1.445118965517241 ], [ 29.756796962025316, -1.445118965517241 ], [ 29.756796962025316, -1.444849482758621 ], [ 29.756257974683542, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17412, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.444849482758621 ], [ 29.756796962025316, -1.445118965517241 ], [ 29.757066455696201, -1.445118965517241 ], [ 29.757066455696201, -1.444849482758621 ], [ 29.756796962025316, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17413, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.444849482758621 ], [ 29.757066455696201, -1.445118965517241 ], [ 29.757335949367089, -1.445118965517241 ], [ 29.757335949367089, -1.444849482758621 ], [ 29.757066455696201, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17414, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.444849482758621 ], [ 29.757335949367089, -1.445118965517241 ], [ 29.757605443037974, -1.445118965517241 ], [ 29.757605443037974, -1.444849482758621 ], [ 29.757335949367089, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17415, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.444849482758621 ], [ 29.757605443037974, -1.445118965517241 ], [ 29.757874936708859, -1.445118965517241 ], [ 29.757874936708859, -1.444849482758621 ], [ 29.757605443037974, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17416, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.444849482758621 ], [ 29.757874936708859, -1.445118965517241 ], [ 29.758144430379748, -1.445118965517241 ], [ 29.758144430379748, -1.444849482758621 ], [ 29.757874936708859, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17417, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.444849482758621 ], [ 29.758144430379748, -1.445118965517241 ], [ 29.758413924050632, -1.445118965517241 ], [ 29.758413924050632, -1.444849482758621 ], [ 29.758144430379748, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17418, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.444849482758621 ], [ 29.758413924050632, -1.445118965517241 ], [ 29.758683417721517, -1.445118965517241 ], [ 29.758683417721517, -1.445388448275862 ], [ 29.758952911392406, -1.445388448275862 ], [ 29.758952911392406, -1.445118965517241 ], [ 29.759222405063291, -1.445118965517241 ], [ 29.759222405063291, -1.444849482758621 ], [ 29.758413924050632, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17419, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.444849482758621 ], [ 29.759222405063291, -1.445118965517241 ], [ 29.759491898734176, -1.445118965517241 ], [ 29.759491898734176, -1.444849482758621 ], [ 29.759222405063291, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17420, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.444849482758621 ], [ 29.759761392405064, -1.445118965517241 ], [ 29.760030886075949, -1.445118965517241 ], [ 29.760030886075949, -1.444849482758621 ], [ 29.759761392405064, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17421, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.444849482758621 ], [ 29.760030886075949, -1.445118965517241 ], [ 29.760300379746834, -1.445118965517241 ], [ 29.760300379746834, -1.444849482758621 ], [ 29.760030886075949, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17422, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.444849482758621 ], [ 29.760300379746834, -1.445118965517241 ], [ 29.760569873417722, -1.445118965517241 ], [ 29.760569873417722, -1.444849482758621 ], [ 29.760300379746834, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17423, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.444849482758621 ], [ 29.760569873417722, -1.445118965517241 ], [ 29.760839367088607, -1.445118965517241 ], [ 29.760839367088607, -1.444849482758621 ], [ 29.760569873417722, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17424, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.444849482758621 ], [ 29.760839367088607, -1.445118965517241 ], [ 29.761108860759492, -1.445118965517241 ], [ 29.761108860759492, -1.444849482758621 ], [ 29.760839367088607, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17425, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.444849482758621 ], [ 29.761108860759492, -1.445118965517241 ], [ 29.761378354430381, -1.445118965517241 ], [ 29.761378354430381, -1.444849482758621 ], [ 29.761108860759492, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17426, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.444849482758621 ], [ 29.761378354430381, -1.445118965517241 ], [ 29.761917341772151, -1.445118965517241 ], [ 29.761917341772151, -1.444849482758621 ], [ 29.761378354430381, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17427, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.444849482758621 ], [ 29.761917341772151, -1.445118965517241 ], [ 29.763264810126582, -1.445118965517241 ], [ 29.763264810126582, -1.444849482758621 ], [ 29.761917341772151, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17428, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.444849482758621 ], [ 29.763264810126582, -1.445118965517241 ], [ 29.763803797468356, -1.445118965517241 ], [ 29.763803797468356, -1.444849482758621 ], [ 29.763264810126582, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17429, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.444849482758621 ], [ 29.763803797468356, -1.445118965517241 ], [ 29.76407329113924, -1.445118965517241 ], [ 29.76407329113924, -1.444849482758621 ], [ 29.763803797468356, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17430, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.444849482758621 ], [ 29.76407329113924, -1.445118965517241 ], [ 29.764342784810125, -1.445118965517241 ], [ 29.764342784810125, -1.444849482758621 ], [ 29.76407329113924, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17431, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.444849482758621 ], [ 29.764342784810125, -1.445118965517241 ], [ 29.764612278481014, -1.445118965517241 ], [ 29.764612278481014, -1.444849482758621 ], [ 29.764342784810125, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17432, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.444849482758621 ], [ 29.764612278481014, -1.445118965517241 ], [ 29.764881772151899, -1.445118965517241 ], [ 29.764881772151899, -1.444849482758621 ], [ 29.764612278481014, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17433, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.444849482758621 ], [ 29.764881772151899, -1.445118965517241 ], [ 29.765151265822784, -1.445118965517241 ], [ 29.765151265822784, -1.444849482758621 ], [ 29.764881772151899, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17434, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.444849482758621 ], [ 29.765151265822784, -1.445118965517241 ], [ 29.768115696202532, -1.445118965517241 ], [ 29.768115696202532, -1.444849482758621 ], [ 29.765151265822784, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17435, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.444849482758621 ], [ 29.768115696202532, -1.445118965517241 ], [ 29.768385189873417, -1.445118965517241 ], [ 29.768385189873417, -1.444849482758621 ], [ 29.768115696202532, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17436, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.444849482758621 ], [ 29.768385189873417, -1.445118965517241 ], [ 29.768654683544302, -1.445118965517241 ], [ 29.768654683544302, -1.444849482758621 ], [ 29.768385189873417, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17437, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.444849482758621 ], [ 29.768654683544302, -1.445118965517241 ], [ 29.76892417721519, -1.445118965517241 ], [ 29.76892417721519, -1.444849482758621 ], [ 29.768654683544302, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17438, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.444849482758621 ], [ 29.76892417721519, -1.445118965517241 ], [ 29.769193670886075, -1.445118965517241 ], [ 29.769193670886075, -1.444849482758621 ], [ 29.76892417721519, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17439, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.444849482758621 ], [ 29.769193670886075, -1.445118965517241 ], [ 29.76946316455696, -1.445118965517241 ], [ 29.76946316455696, -1.444849482758621 ], [ 29.769193670886075, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17440, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.444849482758621 ], [ 29.76946316455696, -1.445118965517241 ], [ 29.769732658227849, -1.445118965517241 ], [ 29.769732658227849, -1.444849482758621 ], [ 29.76946316455696, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17441, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.444849482758621 ], [ 29.769732658227849, -1.445118965517241 ], [ 29.770002151898733, -1.445118965517241 ], [ 29.770002151898733, -1.444849482758621 ], [ 29.769732658227849, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17442, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.444849482758621 ], [ 29.770002151898733, -1.445118965517241 ], [ 29.770271645569618, -1.445118965517241 ], [ 29.770271645569618, -1.444849482758621 ], [ 29.770002151898733, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17443, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.444849482758621 ], [ 29.770271645569618, -1.445118965517241 ], [ 29.770541139240507, -1.445118965517241 ], [ 29.770541139240507, -1.444849482758621 ], [ 29.770271645569618, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17444, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.444849482758621 ], [ 29.770541139240507, -1.445118965517241 ], [ 29.770810632911392, -1.445118965517241 ], [ 29.770810632911392, -1.444849482758621 ], [ 29.770541139240507, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17445, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.444849482758621 ], [ 29.770810632911392, -1.445118965517241 ], [ 29.771080126582277, -1.445118965517241 ], [ 29.771080126582277, -1.444849482758621 ], [ 29.770810632911392, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17446, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.444849482758621 ], [ 29.771080126582277, -1.445118965517241 ], [ 29.771349620253165, -1.445118965517241 ], [ 29.771349620253165, -1.444849482758621 ], [ 29.771080126582277, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17447, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.444849482758621 ], [ 29.771349620253165, -1.445118965517241 ], [ 29.77161911392405, -1.445118965517241 ], [ 29.77161911392405, -1.444849482758621 ], [ 29.771349620253165, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17448, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.444849482758621 ], [ 29.77161911392405, -1.445118965517241 ], [ 29.771888607594935, -1.445118965517241 ], [ 29.771888607594935, -1.444849482758621 ], [ 29.77161911392405, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17449, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.444849482758621 ], [ 29.771888607594935, -1.445118965517241 ], [ 29.772158101265823, -1.445118965517241 ], [ 29.772158101265823, -1.444849482758621 ], [ 29.771888607594935, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17450, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.444849482758621 ], [ 29.772158101265823, -1.445118965517241 ], [ 29.772427594936708, -1.445118965517241 ], [ 29.772427594936708, -1.444849482758621 ], [ 29.772158101265823, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17451, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.444849482758621 ], [ 29.772427594936708, -1.445118965517241 ], [ 29.772697088607593, -1.445118965517241 ], [ 29.772697088607593, -1.444849482758621 ], [ 29.772427594936708, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17452, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.444849482758621 ], [ 29.772697088607593, -1.445118965517241 ], [ 29.772966582278482, -1.445118965517241 ], [ 29.772966582278482, -1.444849482758621 ], [ 29.772697088607593, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17453, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.444849482758621 ], [ 29.772966582278482, -1.445118965517241 ], [ 29.773236075949367, -1.445118965517241 ], [ 29.773236075949367, -1.444849482758621 ], [ 29.772966582278482, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17454, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.444849482758621 ], [ 29.773236075949367, -1.445118965517241 ], [ 29.773505569620252, -1.445118965517241 ], [ 29.773505569620252, -1.444849482758621 ], [ 29.773236075949367, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17455, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.444849482758621 ], [ 29.773505569620252, -1.445118965517241 ], [ 29.77377506329114, -1.445118965517241 ], [ 29.77377506329114, -1.444849482758621 ], [ 29.773505569620252, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17456, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.444849482758621 ], [ 29.77377506329114, -1.445118965517241 ], [ 29.774044556962025, -1.445118965517241 ], [ 29.774044556962025, -1.444849482758621 ], [ 29.77377506329114, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17457, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.444849482758621 ], [ 29.774044556962025, -1.445118965517241 ], [ 29.77431405063291, -1.445118965517241 ], [ 29.77431405063291, -1.444849482758621 ], [ 29.774044556962025, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17458, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.444849482758621 ], [ 29.77431405063291, -1.445118965517241 ], [ 29.774853037974683, -1.445118965517241 ], [ 29.774853037974683, -1.444849482758621 ], [ 29.77431405063291, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17459, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.444849482758621 ], [ 29.774853037974683, -1.445118965517241 ], [ 29.775122531645568, -1.445118965517241 ], [ 29.775122531645568, -1.444849482758621 ], [ 29.774853037974683, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17460, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.444849482758621 ], [ 29.775122531645568, -1.445118965517241 ], [ 29.775392025316457, -1.445118965517241 ], [ 29.775392025316457, -1.444849482758621 ], [ 29.775122531645568, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17461, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.444849482758621 ], [ 29.775392025316457, -1.445118965517241 ], [ 29.775661518987341, -1.445118965517241 ], [ 29.775661518987341, -1.444849482758621 ], [ 29.775392025316457, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17462, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.444849482758621 ], [ 29.775661518987341, -1.445118965517241 ], [ 29.775931012658226, -1.445118965517241 ], [ 29.775931012658226, -1.444849482758621 ], [ 29.775661518987341, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17463, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.444849482758621 ], [ 29.775931012658226, -1.445118965517241 ], [ 29.776200506329115, -1.445118965517241 ], [ 29.776200506329115, -1.444849482758621 ], [ 29.775931012658226, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17464, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.444849482758621 ], [ 29.776200506329115, -1.445118965517241 ], [ 29.77647, -1.445118965517241 ], [ 29.77647, -1.444849482758621 ], [ 29.776200506329115, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17465, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.444849482758621 ], [ 29.77647, -1.445118965517241 ], [ 29.776739493670885, -1.445118965517241 ], [ 29.776739493670885, -1.444849482758621 ], [ 29.77647, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17466, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.444849482758621 ], [ 29.776739493670885, -1.445388448275862 ], [ 29.777008987341773, -1.445388448275862 ], [ 29.777008987341773, -1.444849482758621 ], [ 29.776739493670885, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17467, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.444849482758621 ], [ 29.777278481012658, -1.445118965517241 ], [ 29.777547974683543, -1.445118965517241 ], [ 29.777547974683543, -1.444849482758621 ], [ 29.777278481012658, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17468, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.444849482758621 ], [ 29.777547974683543, -1.445388448275862 ], [ 29.777817468354431, -1.445388448275862 ], [ 29.777817468354431, -1.444849482758621 ], [ 29.777547974683543, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17469, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.444849482758621 ], [ 29.778086962025316, -1.445118965517241 ], [ 29.778356455696201, -1.445118965517241 ], [ 29.778356455696201, -1.444849482758621 ], [ 29.778086962025316, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17470, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.444849482758621 ], [ 29.778356455696201, -1.445388448275862 ], [ 29.77862594936709, -1.445388448275862 ], [ 29.77862594936709, -1.444849482758621 ], [ 29.778356455696201, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17471, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.444849482758621 ], [ 29.778895443037975, -1.445118965517241 ], [ 29.77916493670886, -1.445118965517241 ], [ 29.77916493670886, -1.444849482758621 ], [ 29.778895443037975, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17472, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.444849482758621 ], [ 29.77916493670886, -1.445118965517241 ], [ 29.779434430379748, -1.445118965517241 ], [ 29.779434430379748, -1.444849482758621 ], [ 29.77916493670886, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17473, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.444849482758621 ], [ 29.779703924050633, -1.445118965517241 ], [ 29.779973417721518, -1.445118965517241 ], [ 29.779973417721518, -1.444849482758621 ], [ 29.779703924050633, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17474, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.444849482758621 ], [ 29.779973417721518, -1.445118965517241 ], [ 29.780242911392406, -1.445118965517241 ], [ 29.780242911392406, -1.444849482758621 ], [ 29.779973417721518, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17475, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.444849482758621 ], [ 29.780242911392406, -1.445388448275862 ], [ 29.780512405063291, -1.445388448275862 ], [ 29.780512405063291, -1.444849482758621 ], [ 29.780242911392406, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17476, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.444849482758621 ], [ 29.780512405063291, -1.445118965517241 ], [ 29.780781898734176, -1.445118965517241 ], [ 29.780781898734176, -1.444849482758621 ], [ 29.780512405063291, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17477, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.444849482758621 ], [ 29.780781898734176, -1.445118965517241 ], [ 29.781051392405065, -1.445118965517241 ], [ 29.781051392405065, -1.444849482758621 ], [ 29.780781898734176, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17478, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.444849482758621 ], [ 29.781051392405065, -1.445118965517241 ], [ 29.78132088607595, -1.445118965517241 ], [ 29.78132088607595, -1.444849482758621 ], [ 29.781051392405065, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17479, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.444849482758621 ], [ 29.781590379746834, -1.445118965517241 ], [ 29.781859873417723, -1.445118965517241 ], [ 29.781859873417723, -1.444849482758621 ], [ 29.781590379746834, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17480, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.444849482758621 ], [ 29.781859873417723, -1.445118965517241 ], [ 29.782129367088608, -1.445118965517241 ], [ 29.782129367088608, -1.444849482758621 ], [ 29.781859873417723, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17481, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.444849482758621 ], [ 29.782129367088608, -1.445118965517241 ], [ 29.782398860759493, -1.445118965517241 ], [ 29.782398860759493, -1.444849482758621 ], [ 29.782129367088608, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17482, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.444849482758621 ], [ 29.782398860759493, -1.445118965517241 ], [ 29.782668354430381, -1.445118965517241 ], [ 29.782668354430381, -1.444849482758621 ], [ 29.782398860759493, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17483, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.444849482758621 ], [ 29.782668354430381, -1.445118965517241 ], [ 29.782937848101266, -1.445118965517241 ], [ 29.782937848101266, -1.444849482758621 ], [ 29.782668354430381, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17484, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.444849482758621 ], [ 29.782937848101266, -1.445118965517241 ], [ 29.783207341772151, -1.445118965517241 ], [ 29.783207341772151, -1.444849482758621 ], [ 29.782937848101266, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17485, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.444849482758621 ], [ 29.783207341772151, -1.445118965517241 ], [ 29.783476835443039, -1.445118965517241 ], [ 29.783476835443039, -1.444849482758621 ], [ 29.783207341772151, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17486, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.444849482758621 ], [ 29.783476835443039, -1.445118965517241 ], [ 29.784015822784809, -1.445118965517241 ], [ 29.784015822784809, -1.444849482758621 ], [ 29.783476835443039, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17487, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.444849482758621 ], [ 29.784015822784809, -1.445118965517241 ], [ 29.784285316455698, -1.445118965517241 ], [ 29.784285316455698, -1.444849482758621 ], [ 29.784015822784809, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17488, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.444849482758621 ], [ 29.784285316455698, -1.445118965517241 ], [ 29.784554810126583, -1.445118965517241 ], [ 29.784554810126583, -1.444849482758621 ], [ 29.784285316455698, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17489, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.444849482758621 ], [ 29.784554810126583, -1.445118965517241 ], [ 29.784824303797468, -1.445118965517241 ], [ 29.784824303797468, -1.444849482758621 ], [ 29.784554810126583, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17490, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.444849482758621 ], [ 29.784824303797468, -1.445118965517241 ], [ 29.785093797468356, -1.445118965517241 ], [ 29.785093797468356, -1.444849482758621 ], [ 29.784824303797468, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17491, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.444849482758621 ], [ 29.785093797468356, -1.445118965517241 ], [ 29.785363291139241, -1.445118965517241 ], [ 29.785363291139241, -1.444849482758621 ], [ 29.785093797468356, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17492, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.444849482758621 ], [ 29.785363291139241, -1.445118965517241 ], [ 29.785632784810126, -1.445118965517241 ], [ 29.785632784810126, -1.444849482758621 ], [ 29.785363291139241, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17493, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.444849482758621 ], [ 29.785632784810126, -1.445118965517241 ], [ 29.785902278481014, -1.445118965517241 ], [ 29.785902278481014, -1.444849482758621 ], [ 29.785632784810126, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17494, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.444849482758621 ], [ 29.785902278481014, -1.445118965517241 ], [ 29.786171772151899, -1.445118965517241 ], [ 29.786171772151899, -1.444849482758621 ], [ 29.785902278481014, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17495, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.444849482758621 ], [ 29.786171772151899, -1.445118965517241 ], [ 29.786441265822784, -1.445118965517241 ], [ 29.786441265822784, -1.444849482758621 ], [ 29.786171772151899, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17496, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.444849482758621 ], [ 29.786441265822784, -1.445118965517241 ], [ 29.786710759493673, -1.445118965517241 ], [ 29.786710759493673, -1.444849482758621 ], [ 29.786441265822784, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17497, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.444849482758621 ], [ 29.786710759493673, -1.445118965517241 ], [ 29.786980253164558, -1.445118965517241 ], [ 29.786980253164558, -1.444849482758621 ], [ 29.786710759493673, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17498, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.444849482758621 ], [ 29.786980253164558, -1.445118965517241 ], [ 29.787249746835442, -1.445118965517241 ], [ 29.787249746835442, -1.444849482758621 ], [ 29.786980253164558, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17499, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.444849482758621 ], [ 29.787249746835442, -1.445118965517241 ], [ 29.787788734177216, -1.445118965517241 ], [ 29.787788734177216, -1.444849482758621 ], [ 29.787249746835442, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17500, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.444849482758621 ], [ 29.788597215189874, -1.445118965517241 ], [ 29.789405696202532, -1.445118965517241 ], [ 29.789405696202532, -1.444849482758621 ], [ 29.788597215189874, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17501, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.444849482758621 ], [ 29.789405696202532, -1.445118965517241 ], [ 29.789675189873417, -1.445118965517241 ], [ 29.789675189873417, -1.444849482758621 ], [ 29.789405696202532, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17502, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.444849482758621 ], [ 29.789675189873417, -1.445118965517241 ], [ 29.789944683544302, -1.445118965517241 ], [ 29.789944683544302, -1.444849482758621 ], [ 29.789675189873417, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17503, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.444849482758621 ], [ 29.789944683544302, -1.445118965517241 ], [ 29.790214177215191, -1.445118965517241 ], [ 29.790214177215191, -1.444849482758621 ], [ 29.789944683544302, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17504, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.444849482758621 ], [ 29.790214177215191, -1.445118965517241 ], [ 29.790483670886076, -1.445118965517241 ], [ 29.790483670886076, -1.444849482758621 ], [ 29.790214177215191, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17505, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.444849482758621 ], [ 29.790483670886076, -1.445118965517241 ], [ 29.790753164556961, -1.445118965517241 ], [ 29.790753164556961, -1.444849482758621 ], [ 29.790483670886076, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17506, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.444849482758621 ], [ 29.790753164556961, -1.445118965517241 ], [ 29.791022658227849, -1.445118965517241 ], [ 29.791022658227849, -1.444849482758621 ], [ 29.790753164556961, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17507, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.444849482758621 ], [ 29.791022658227849, -1.445118965517241 ], [ 29.791292151898734, -1.445118965517241 ], [ 29.791292151898734, -1.444849482758621 ], [ 29.791022658227849, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17508, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.444849482758621 ], [ 29.791292151898734, -1.445118965517241 ], [ 29.791561645569619, -1.445118965517241 ], [ 29.791561645569619, -1.444849482758621 ], [ 29.791292151898734, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17509, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.444849482758621 ], [ 29.791561645569619, -1.445118965517241 ], [ 29.791831139240507, -1.445118965517241 ], [ 29.791831139240507, -1.444849482758621 ], [ 29.791561645569619, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17510, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.444849482758621 ], [ 29.791831139240507, -1.445118965517241 ], [ 29.792100632911392, -1.445118965517241 ], [ 29.792100632911392, -1.444849482758621 ], [ 29.791831139240507, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17511, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.444849482758621 ], [ 29.792100632911392, -1.445118965517241 ], [ 29.792370126582277, -1.445118965517241 ], [ 29.792370126582277, -1.444849482758621 ], [ 29.792100632911392, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17512, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.444849482758621 ], [ 29.792909113924051, -1.445118965517241 ], [ 29.793178607594935, -1.445118965517241 ], [ 29.793178607594935, -1.444849482758621 ], [ 29.792909113924051, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17513, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.444849482758621 ], [ 29.793178607594935, -1.445118965517241 ], [ 29.793448101265824, -1.445118965517241 ], [ 29.793448101265824, -1.444849482758621 ], [ 29.793178607594935, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17514, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.444849482758621 ], [ 29.802880379746835, -1.445118965517241 ], [ 29.803149873417723, -1.445118965517241 ], [ 29.803149873417723, -1.444849482758621 ], [ 29.802880379746835, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17515, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.444849482758621 ], [ 29.803149873417723, -1.445118965517241 ], [ 29.803419367088608, -1.445118965517241 ], [ 29.803419367088608, -1.444849482758621 ], [ 29.803149873417723, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17516, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.444849482758621 ], [ 29.803419367088608, -1.445118965517241 ], [ 29.803688860759493, -1.445118965517241 ], [ 29.803688860759493, -1.444849482758621 ], [ 29.803419367088608, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17517, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.444849482758621 ], [ 29.803688860759493, -1.445118965517241 ], [ 29.803958354430382, -1.445118965517241 ], [ 29.803958354430382, -1.444849482758621 ], [ 29.803688860759493, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17518, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.444849482758621 ], [ 29.803958354430382, -1.445388448275862 ], [ 29.804227848101267, -1.445388448275862 ], [ 29.804227848101267, -1.444849482758621 ], [ 29.803958354430382, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17519, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.444849482758621 ], [ 29.804227848101267, -1.445118965517241 ], [ 29.804497341772151, -1.445118965517241 ], [ 29.804497341772151, -1.444849482758621 ], [ 29.804227848101267, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17520, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.444849482758621 ], [ 29.804497341772151, -1.445118965517241 ], [ 29.80476683544304, -1.445118965517241 ], [ 29.80476683544304, -1.444849482758621 ], [ 29.804497341772151, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17521, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.444849482758621 ], [ 29.80476683544304, -1.445118965517241 ], [ 29.805036329113925, -1.445118965517241 ], [ 29.805036329113925, -1.444849482758621 ], [ 29.80476683544304, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17522, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.444849482758621 ], [ 29.805036329113925, -1.445118965517241 ], [ 29.80530582278481, -1.445118965517241 ], [ 29.80530582278481, -1.444849482758621 ], [ 29.805036329113925, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17523, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.444849482758621 ], [ 29.80530582278481, -1.445118965517241 ], [ 29.805575316455698, -1.445118965517241 ], [ 29.805575316455698, -1.444849482758621 ], [ 29.80530582278481, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17524, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.444849482758621 ], [ 29.805575316455698, -1.445118965517241 ], [ 29.805844810126583, -1.445118965517241 ], [ 29.805844810126583, -1.444849482758621 ], [ 29.805575316455698, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17525, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.444849482758621 ], [ 29.805844810126583, -1.445118965517241 ], [ 29.806114303797468, -1.445118965517241 ], [ 29.806114303797468, -1.444849482758621 ], [ 29.805844810126583, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17526, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.444849482758621 ], [ 29.806114303797468, -1.445118965517241 ], [ 29.806383797468357, -1.445118965517241 ], [ 29.806383797468357, -1.444849482758621 ], [ 29.806114303797468, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17527, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.444849482758621 ], [ 29.806383797468357, -1.445118965517241 ], [ 29.806653291139241, -1.445118965517241 ], [ 29.806653291139241, -1.444849482758621 ], [ 29.806383797468357, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17528, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.444849482758621 ], [ 29.806653291139241, -1.445118965517241 ], [ 29.806922784810126, -1.445118965517241 ], [ 29.806922784810126, -1.444849482758621 ], [ 29.806653291139241, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17529, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.444849482758621 ], [ 29.806922784810126, -1.445118965517241 ], [ 29.807192278481015, -1.445118965517241 ], [ 29.807192278481015, -1.444849482758621 ], [ 29.806922784810126, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17530, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.444849482758621 ], [ 29.807192278481015, -1.445118965517241 ], [ 29.8074617721519, -1.445118965517241 ], [ 29.8074617721519, -1.444849482758621 ], [ 29.807192278481015, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17531, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.445118965517241 ], [ 29.74817316455696, -1.445388448275862 ], [ 29.748442658227848, -1.445388448275862 ], [ 29.748442658227848, -1.445118965517241 ], [ 29.74817316455696, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17532, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.445118965517241 ], [ 29.748442658227848, -1.445388448275862 ], [ 29.748712151898733, -1.445388448275862 ], [ 29.748712151898733, -1.445118965517241 ], [ 29.748442658227848, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17533, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.445118965517241 ], [ 29.748712151898733, -1.445388448275862 ], [ 29.748981645569618, -1.445388448275862 ], [ 29.748981645569618, -1.445118965517241 ], [ 29.748712151898733, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17534, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.445118965517241 ], [ 29.748981645569618, -1.445657931034483 ], [ 29.749251139240506, -1.445657931034483 ], [ 29.749251139240506, -1.445118965517241 ], [ 29.748981645569618, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17535, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.445118965517241 ], [ 29.749251139240506, -1.445388448275862 ], [ 29.749520632911391, -1.445388448275862 ], [ 29.749520632911391, -1.445118965517241 ], [ 29.749251139240506, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17536, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.445118965517241 ], [ 29.749520632911391, -1.445388448275862 ], [ 29.749790126582276, -1.445388448275862 ], [ 29.749790126582276, -1.445118965517241 ], [ 29.749520632911391, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17537, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.445118965517241 ], [ 29.749790126582276, -1.445388448275862 ], [ 29.750059620253165, -1.445388448275862 ], [ 29.750059620253165, -1.445118965517241 ], [ 29.749790126582276, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17538, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.445118965517241 ], [ 29.750059620253165, -1.445388448275862 ], [ 29.75032911392405, -1.445388448275862 ], [ 29.75032911392405, -1.445118965517241 ], [ 29.750059620253165, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17539, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.445118965517241 ], [ 29.75032911392405, -1.445388448275862 ], [ 29.750598607594934, -1.445388448275862 ], [ 29.750598607594934, -1.445118965517241 ], [ 29.75032911392405, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17540, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.445118965517241 ], [ 29.750598607594934, -1.445388448275862 ], [ 29.750868101265823, -1.445388448275862 ], [ 29.750868101265823, -1.445118965517241 ], [ 29.750598607594934, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17541, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.445118965517241 ], [ 29.750868101265823, -1.445388448275862 ], [ 29.751137594936708, -1.445388448275862 ], [ 29.751137594936708, -1.445118965517241 ], [ 29.750868101265823, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17542, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.445118965517241 ], [ 29.751137594936708, -1.445388448275862 ], [ 29.751407088607593, -1.445388448275862 ], [ 29.751407088607593, -1.445118965517241 ], [ 29.751137594936708, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17543, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.445118965517241 ], [ 29.751407088607593, -1.445388448275862 ], [ 29.751676582278481, -1.445388448275862 ], [ 29.751676582278481, -1.445118965517241 ], [ 29.751407088607593, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17544, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.445118965517241 ], [ 29.751676582278481, -1.445388448275862 ], [ 29.751946075949366, -1.445388448275862 ], [ 29.751946075949366, -1.445118965517241 ], [ 29.751676582278481, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17545, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.445118965517241 ], [ 29.751946075949366, -1.445388448275862 ], [ 29.752215569620251, -1.445388448275862 ], [ 29.752215569620251, -1.445118965517241 ], [ 29.751946075949366, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17546, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.445118965517241 ], [ 29.752215569620251, -1.445388448275862 ], [ 29.75248506329114, -1.445388448275862 ], [ 29.75248506329114, -1.445118965517241 ], [ 29.752215569620251, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17547, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.445118965517241 ], [ 29.75248506329114, -1.445388448275862 ], [ 29.752754556962024, -1.445388448275862 ], [ 29.752754556962024, -1.445118965517241 ], [ 29.75248506329114, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17548, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.445118965517241 ], [ 29.752754556962024, -1.445388448275862 ], [ 29.753024050632909, -1.445388448275862 ], [ 29.753024050632909, -1.445118965517241 ], [ 29.752754556962024, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17549, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.445118965517241 ], [ 29.753024050632909, -1.445388448275862 ], [ 29.753293544303798, -1.445388448275862 ], [ 29.753293544303798, -1.445118965517241 ], [ 29.753024050632909, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17550, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.445118965517241 ], [ 29.753293544303798, -1.445388448275862 ], [ 29.753563037974683, -1.445388448275862 ], [ 29.753563037974683, -1.445118965517241 ], [ 29.753293544303798, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17551, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.445118965517241 ], [ 29.753563037974683, -1.445388448275862 ], [ 29.753832531645568, -1.445388448275862 ], [ 29.753832531645568, -1.445118965517241 ], [ 29.753563037974683, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17552, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.445118965517241 ], [ 29.753832531645568, -1.445388448275862 ], [ 29.754102025316456, -1.445388448275862 ], [ 29.754102025316456, -1.445118965517241 ], [ 29.753832531645568, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17553, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.445118965517241 ], [ 29.754102025316456, -1.445388448275862 ], [ 29.754371518987341, -1.445388448275862 ], [ 29.754371518987341, -1.445118965517241 ], [ 29.754102025316456, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17554, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.445118965517241 ], [ 29.754371518987341, -1.445388448275862 ], [ 29.754641012658226, -1.445388448275862 ], [ 29.754641012658226, -1.445118965517241 ], [ 29.754371518987341, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17555, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.445118965517241 ], [ 29.754641012658226, -1.445388448275862 ], [ 29.754910506329114, -1.445388448275862 ], [ 29.754910506329114, -1.445118965517241 ], [ 29.754641012658226, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17556, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.445118965517241 ], [ 29.754910506329114, -1.445388448275862 ], [ 29.75518, -1.445388448275862 ], [ 29.75518, -1.445118965517241 ], [ 29.754910506329114, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17557, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.445118965517241 ], [ 29.75518, -1.445388448275862 ], [ 29.755449493670884, -1.445388448275862 ], [ 29.755449493670884, -1.445118965517241 ], [ 29.75518, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17558, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.445118965517241 ], [ 29.755449493670884, -1.445388448275862 ], [ 29.755718987341773, -1.445388448275862 ], [ 29.755718987341773, -1.445118965517241 ], [ 29.755449493670884, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17559, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.445118965517241 ], [ 29.755718987341773, -1.445388448275862 ], [ 29.755988481012658, -1.445388448275862 ], [ 29.755988481012658, -1.445118965517241 ], [ 29.755718987341773, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17560, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.445118965517241 ], [ 29.755988481012658, -1.445388448275862 ], [ 29.756257974683542, -1.445388448275862 ], [ 29.756257974683542, -1.445118965517241 ], [ 29.755988481012658, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17561, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.445118965517241 ], [ 29.756257974683542, -1.445388448275862 ], [ 29.756796962025316, -1.445388448275862 ], [ 29.756796962025316, -1.445118965517241 ], [ 29.756257974683542, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17562, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.445118965517241 ], [ 29.756796962025316, -1.445388448275862 ], [ 29.757066455696201, -1.445388448275862 ], [ 29.757066455696201, -1.445118965517241 ], [ 29.756796962025316, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17563, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.445118965517241 ], [ 29.757066455696201, -1.445388448275862 ], [ 29.757335949367089, -1.445388448275862 ], [ 29.757335949367089, -1.445118965517241 ], [ 29.757066455696201, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17564, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.445118965517241 ], [ 29.757335949367089, -1.445388448275862 ], [ 29.757605443037974, -1.445388448275862 ], [ 29.757605443037974, -1.445118965517241 ], [ 29.757335949367089, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17565, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.445118965517241 ], [ 29.757605443037974, -1.445388448275862 ], [ 29.757874936708859, -1.445388448275862 ], [ 29.757874936708859, -1.445118965517241 ], [ 29.757605443037974, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17566, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.445118965517241 ], [ 29.757874936708859, -1.445388448275862 ], [ 29.758144430379748, -1.445388448275862 ], [ 29.758144430379748, -1.445118965517241 ], [ 29.757874936708859, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17567, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.445118965517241 ], [ 29.758144430379748, -1.445388448275862 ], [ 29.758413924050632, -1.445388448275862 ], [ 29.758413924050632, -1.445118965517241 ], [ 29.758144430379748, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17568, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.445118965517241 ], [ 29.758413924050632, -1.445388448275862 ], [ 29.758683417721517, -1.445388448275862 ], [ 29.758683417721517, -1.445118965517241 ], [ 29.758413924050632, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17569, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.445118965517241 ], [ 29.758952911392406, -1.445388448275862 ], [ 29.759222405063291, -1.445388448275862 ], [ 29.759222405063291, -1.445118965517241 ], [ 29.758952911392406, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17570, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.445118965517241 ], [ 29.759222405063291, -1.445388448275862 ], [ 29.759491898734176, -1.445388448275862 ], [ 29.759491898734176, -1.445118965517241 ], [ 29.759222405063291, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17571, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.445118965517241 ], [ 29.759491898734176, -1.445388448275862 ], [ 29.759761392405064, -1.445388448275862 ], [ 29.759761392405064, -1.445118965517241 ], [ 29.759491898734176, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17572, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.445118965517241 ], [ 29.759761392405064, -1.445388448275862 ], [ 29.760030886075949, -1.445388448275862 ], [ 29.760030886075949, -1.445118965517241 ], [ 29.759761392405064, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17573, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.445118965517241 ], [ 29.760030886075949, -1.445388448275862 ], [ 29.760300379746834, -1.445388448275862 ], [ 29.760300379746834, -1.445118965517241 ], [ 29.760030886075949, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17574, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.445118965517241 ], [ 29.760300379746834, -1.445388448275862 ], [ 29.760569873417722, -1.445388448275862 ], [ 29.760569873417722, -1.445118965517241 ], [ 29.760300379746834, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17575, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.445118965517241 ], [ 29.760569873417722, -1.445388448275862 ], [ 29.760839367088607, -1.445388448275862 ], [ 29.760839367088607, -1.445118965517241 ], [ 29.760569873417722, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17576, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.445118965517241 ], [ 29.760839367088607, -1.445388448275862 ], [ 29.761108860759492, -1.445388448275862 ], [ 29.761108860759492, -1.445118965517241 ], [ 29.760839367088607, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17577, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.445118965517241 ], [ 29.761108860759492, -1.445388448275862 ], [ 29.761378354430381, -1.445388448275862 ], [ 29.761378354430381, -1.445118965517241 ], [ 29.761108860759492, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17578, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.445118965517241 ], [ 29.761378354430381, -1.445388448275862 ], [ 29.761647848101266, -1.445388448275862 ], [ 29.761647848101266, -1.445118965517241 ], [ 29.761378354430381, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17579, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.445118965517241 ], [ 29.761647848101266, -1.445388448275862 ], [ 29.762186835443039, -1.445388448275862 ], [ 29.762186835443039, -1.445118965517241 ], [ 29.761647848101266, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17580, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.445118965517241 ], [ 29.762186835443039, -1.445388448275862 ], [ 29.762995316455697, -1.445388448275862 ], [ 29.762995316455697, -1.445118965517241 ], [ 29.762186835443039, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17581, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.445118965517241 ], [ 29.762995316455697, -1.445388448275862 ], [ 29.763534303797467, -1.445388448275862 ], [ 29.763534303797467, -1.445118965517241 ], [ 29.762995316455697, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17582, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.445118965517241 ], [ 29.763534303797467, -1.445388448275862 ], [ 29.763803797468356, -1.445388448275862 ], [ 29.763803797468356, -1.445118965517241 ], [ 29.763534303797467, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17583, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.445118965517241 ], [ 29.763803797468356, -1.445388448275862 ], [ 29.76407329113924, -1.445388448275862 ], [ 29.76407329113924, -1.445118965517241 ], [ 29.763803797468356, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17584, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.445118965517241 ], [ 29.76407329113924, -1.445388448275862 ], [ 29.764342784810125, -1.445388448275862 ], [ 29.764342784810125, -1.445118965517241 ], [ 29.76407329113924, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17585, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.445118965517241 ], [ 29.764342784810125, -1.445388448275862 ], [ 29.764612278481014, -1.445388448275862 ], [ 29.764612278481014, -1.445118965517241 ], [ 29.764342784810125, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17586, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.445118965517241 ], [ 29.764612278481014, -1.445388448275862 ], [ 29.764881772151899, -1.445388448275862 ], [ 29.764881772151899, -1.445118965517241 ], [ 29.764612278481014, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17587, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.445118965517241 ], [ 29.764881772151899, -1.445388448275862 ], [ 29.765151265822784, -1.445388448275862 ], [ 29.765151265822784, -1.445118965517241 ], [ 29.764881772151899, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17588, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.445118965517241 ], [ 29.765151265822784, -1.445388448275862 ], [ 29.765959746835442, -1.445388448275862 ], [ 29.765959746835442, -1.445118965517241 ], [ 29.765151265822784, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17589, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.445118965517241 ], [ 29.765959746835442, -1.445388448275862 ], [ 29.767576708860759, -1.445388448275862 ], [ 29.767576708860759, -1.445118965517241 ], [ 29.765959746835442, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17590, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.445118965517241 ], [ 29.767576708860759, -1.445388448275862 ], [ 29.768115696202532, -1.445388448275862 ], [ 29.768115696202532, -1.445118965517241 ], [ 29.767576708860759, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17591, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.445118965517241 ], [ 29.768115696202532, -1.445388448275862 ], [ 29.768385189873417, -1.445388448275862 ], [ 29.768385189873417, -1.445118965517241 ], [ 29.768115696202532, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17592, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.445118965517241 ], [ 29.768385189873417, -1.445388448275862 ], [ 29.768654683544302, -1.445388448275862 ], [ 29.768654683544302, -1.445118965517241 ], [ 29.768385189873417, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17593, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.445118965517241 ], [ 29.768654683544302, -1.445388448275862 ], [ 29.76892417721519, -1.445388448275862 ], [ 29.76892417721519, -1.445118965517241 ], [ 29.768654683544302, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17594, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.445118965517241 ], [ 29.76892417721519, -1.445388448275862 ], [ 29.769193670886075, -1.445388448275862 ], [ 29.769193670886075, -1.445118965517241 ], [ 29.76892417721519, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17595, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.445118965517241 ], [ 29.769193670886075, -1.445388448275862 ], [ 29.76946316455696, -1.445388448275862 ], [ 29.76946316455696, -1.445118965517241 ], [ 29.769193670886075, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17596, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.445118965517241 ], [ 29.76946316455696, -1.445388448275862 ], [ 29.769732658227849, -1.445388448275862 ], [ 29.769732658227849, -1.445118965517241 ], [ 29.76946316455696, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17597, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.445118965517241 ], [ 29.769732658227849, -1.445388448275862 ], [ 29.770002151898733, -1.445388448275862 ], [ 29.770002151898733, -1.445118965517241 ], [ 29.769732658227849, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17598, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.445118965517241 ], [ 29.770002151898733, -1.445388448275862 ], [ 29.770271645569618, -1.445388448275862 ], [ 29.770271645569618, -1.445118965517241 ], [ 29.770002151898733, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17599, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.445118965517241 ], [ 29.770271645569618, -1.445388448275862 ], [ 29.770541139240507, -1.445388448275862 ], [ 29.770541139240507, -1.445118965517241 ], [ 29.770271645569618, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17600, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.445118965517241 ], [ 29.770541139240507, -1.445388448275862 ], [ 29.770810632911392, -1.445388448275862 ], [ 29.770810632911392, -1.445118965517241 ], [ 29.770541139240507, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17601, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.445118965517241 ], [ 29.770810632911392, -1.445388448275862 ], [ 29.771080126582277, -1.445388448275862 ], [ 29.771080126582277, -1.445118965517241 ], [ 29.770810632911392, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17602, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.445118965517241 ], [ 29.771080126582277, -1.445388448275862 ], [ 29.771349620253165, -1.445388448275862 ], [ 29.771349620253165, -1.445118965517241 ], [ 29.771080126582277, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17603, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.445118965517241 ], [ 29.771349620253165, -1.445388448275862 ], [ 29.77161911392405, -1.445388448275862 ], [ 29.77161911392405, -1.445118965517241 ], [ 29.771349620253165, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17604, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.445118965517241 ], [ 29.77161911392405, -1.445388448275862 ], [ 29.771888607594935, -1.445388448275862 ], [ 29.771888607594935, -1.445118965517241 ], [ 29.77161911392405, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17605, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.445118965517241 ], [ 29.771888607594935, -1.445388448275862 ], [ 29.772158101265823, -1.445388448275862 ], [ 29.772158101265823, -1.445118965517241 ], [ 29.771888607594935, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17606, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.445118965517241 ], [ 29.772158101265823, -1.445388448275862 ], [ 29.772427594936708, -1.445388448275862 ], [ 29.772427594936708, -1.445118965517241 ], [ 29.772158101265823, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17607, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.445118965517241 ], [ 29.772427594936708, -1.445388448275862 ], [ 29.772697088607593, -1.445388448275862 ], [ 29.772697088607593, -1.445118965517241 ], [ 29.772427594936708, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17608, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.445118965517241 ], [ 29.772697088607593, -1.445388448275862 ], [ 29.772966582278482, -1.445388448275862 ], [ 29.772966582278482, -1.445118965517241 ], [ 29.772697088607593, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17609, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.445118965517241 ], [ 29.772966582278482, -1.445388448275862 ], [ 29.773236075949367, -1.445388448275862 ], [ 29.773236075949367, -1.445118965517241 ], [ 29.772966582278482, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17610, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.445118965517241 ], [ 29.773236075949367, -1.445388448275862 ], [ 29.773505569620252, -1.445388448275862 ], [ 29.773505569620252, -1.445118965517241 ], [ 29.773236075949367, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17611, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.445118965517241 ], [ 29.773505569620252, -1.445388448275862 ], [ 29.77377506329114, -1.445388448275862 ], [ 29.77377506329114, -1.445118965517241 ], [ 29.773505569620252, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17612, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.445118965517241 ], [ 29.77377506329114, -1.445388448275862 ], [ 29.774044556962025, -1.445388448275862 ], [ 29.774044556962025, -1.445118965517241 ], [ 29.77377506329114, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17613, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.445118965517241 ], [ 29.774044556962025, -1.445388448275862 ], [ 29.77431405063291, -1.445388448275862 ], [ 29.77431405063291, -1.445118965517241 ], [ 29.774044556962025, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17614, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.445118965517241 ], [ 29.77431405063291, -1.445388448275862 ], [ 29.774853037974683, -1.445388448275862 ], [ 29.774853037974683, -1.445118965517241 ], [ 29.77431405063291, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17615, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.445118965517241 ], [ 29.774853037974683, -1.445388448275862 ], [ 29.775122531645568, -1.445388448275862 ], [ 29.775122531645568, -1.445118965517241 ], [ 29.774853037974683, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17616, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.445118965517241 ], [ 29.775122531645568, -1.445388448275862 ], [ 29.775392025316457, -1.445388448275862 ], [ 29.775392025316457, -1.445118965517241 ], [ 29.775122531645568, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17617, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.445118965517241 ], [ 29.775392025316457, -1.445388448275862 ], [ 29.775661518987341, -1.445388448275862 ], [ 29.775661518987341, -1.445118965517241 ], [ 29.775392025316457, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17618, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.445118965517241 ], [ 29.775661518987341, -1.445388448275862 ], [ 29.775931012658226, -1.445388448275862 ], [ 29.775931012658226, -1.445118965517241 ], [ 29.775661518987341, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17619, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.445118965517241 ], [ 29.775931012658226, -1.445388448275862 ], [ 29.776200506329115, -1.445388448275862 ], [ 29.776200506329115, -1.445118965517241 ], [ 29.775931012658226, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17620, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.445118965517241 ], [ 29.776200506329115, -1.445388448275862 ], [ 29.77647, -1.445388448275862 ], [ 29.77647, -1.445118965517241 ], [ 29.776200506329115, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17621, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.445118965517241 ], [ 29.77647, -1.445388448275862 ], [ 29.776739493670885, -1.445388448275862 ], [ 29.776739493670885, -1.445118965517241 ], [ 29.77647, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17622, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.445118965517241 ], [ 29.777008987341773, -1.445388448275862 ], [ 29.777278481012658, -1.445388448275862 ], [ 29.777278481012658, -1.445118965517241 ], [ 29.777008987341773, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17623, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.445118965517241 ], [ 29.777278481012658, -1.445657931034483 ], [ 29.777547974683543, -1.445657931034483 ], [ 29.777547974683543, -1.445118965517241 ], [ 29.777278481012658, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17624, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.445118965517241 ], [ 29.777817468354431, -1.445388448275862 ], [ 29.778086962025316, -1.445388448275862 ], [ 29.778086962025316, -1.445118965517241 ], [ 29.777817468354431, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17625, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.445118965517241 ], [ 29.778086962025316, -1.445388448275862 ], [ 29.778356455696201, -1.445388448275862 ], [ 29.778356455696201, -1.445118965517241 ], [ 29.778086962025316, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17626, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.445118965517241 ], [ 29.77862594936709, -1.445388448275862 ], [ 29.778895443037975, -1.445388448275862 ], [ 29.778895443037975, -1.445118965517241 ], [ 29.77862594936709, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17627, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.445118965517241 ], [ 29.778895443037975, -1.445388448275862 ], [ 29.77916493670886, -1.445388448275862 ], [ 29.77916493670886, -1.445118965517241 ], [ 29.778895443037975, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17628, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.445118965517241 ], [ 29.77916493670886, -1.445657931034483 ], [ 29.779434430379748, -1.445657931034483 ], [ 29.779434430379748, -1.445118965517241 ], [ 29.77916493670886, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17629, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.445118965517241 ], [ 29.779434430379748, -1.445388448275862 ], [ 29.779703924050633, -1.445388448275862 ], [ 29.779703924050633, -1.445118965517241 ], [ 29.779434430379748, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17630, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.445118965517241 ], [ 29.779703924050633, -1.445388448275862 ], [ 29.779973417721518, -1.445388448275862 ], [ 29.779973417721518, -1.445118965517241 ], [ 29.779703924050633, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17631, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.445118965517241 ], [ 29.779973417721518, -1.445388448275862 ], [ 29.780242911392406, -1.445388448275862 ], [ 29.780242911392406, -1.445118965517241 ], [ 29.779973417721518, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17632, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.445118965517241 ], [ 29.780512405063291, -1.445388448275862 ], [ 29.780781898734176, -1.445388448275862 ], [ 29.780781898734176, -1.445118965517241 ], [ 29.780512405063291, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17633, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.445118965517241 ], [ 29.780781898734176, -1.445388448275862 ], [ 29.781051392405065, -1.445388448275862 ], [ 29.781051392405065, -1.445118965517241 ], [ 29.780781898734176, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17634, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.445118965517241 ], [ 29.781051392405065, -1.445388448275862 ], [ 29.78132088607595, -1.445388448275862 ], [ 29.78132088607595, -1.445118965517241 ], [ 29.781051392405065, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17635, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.445118965517241 ], [ 29.78132088607595, -1.445388448275862 ], [ 29.781590379746834, -1.445388448275862 ], [ 29.781590379746834, -1.445118965517241 ], [ 29.78132088607595, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17636, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.445118965517241 ], [ 29.781590379746834, -1.445388448275862 ], [ 29.781859873417723, -1.445388448275862 ], [ 29.781859873417723, -1.445118965517241 ], [ 29.781590379746834, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17637, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.445118965517241 ], [ 29.781859873417723, -1.445388448275862 ], [ 29.782129367088608, -1.445388448275862 ], [ 29.782129367088608, -1.445118965517241 ], [ 29.781859873417723, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17638, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.445118965517241 ], [ 29.782129367088608, -1.445388448275862 ], [ 29.782398860759493, -1.445388448275862 ], [ 29.782398860759493, -1.445118965517241 ], [ 29.782129367088608, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17639, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.445118965517241 ], [ 29.782398860759493, -1.445388448275862 ], [ 29.782668354430381, -1.445388448275862 ], [ 29.782668354430381, -1.445118965517241 ], [ 29.782398860759493, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17640, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.445118965517241 ], [ 29.782668354430381, -1.445388448275862 ], [ 29.782937848101266, -1.445388448275862 ], [ 29.782937848101266, -1.445118965517241 ], [ 29.782668354430381, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17641, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.445118965517241 ], [ 29.782937848101266, -1.445388448275862 ], [ 29.783207341772151, -1.445388448275862 ], [ 29.783207341772151, -1.445118965517241 ], [ 29.782937848101266, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17642, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.445118965517241 ], [ 29.783207341772151, -1.445388448275862 ], [ 29.783476835443039, -1.445388448275862 ], [ 29.783476835443039, -1.445118965517241 ], [ 29.783207341772151, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17643, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.445118965517241 ], [ 29.783476835443039, -1.445388448275862 ], [ 29.784015822784809, -1.445388448275862 ], [ 29.784015822784809, -1.445118965517241 ], [ 29.783476835443039, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17644, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.445118965517241 ], [ 29.784015822784809, -1.445388448275862 ], [ 29.784285316455698, -1.445388448275862 ], [ 29.784285316455698, -1.445118965517241 ], [ 29.784015822784809, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17645, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.445118965517241 ], [ 29.784285316455698, -1.445388448275862 ], [ 29.784554810126583, -1.445388448275862 ], [ 29.784554810126583, -1.445118965517241 ], [ 29.784285316455698, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17646, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.445118965517241 ], [ 29.784554810126583, -1.445388448275862 ], [ 29.784824303797468, -1.445388448275862 ], [ 29.784824303797468, -1.445118965517241 ], [ 29.784554810126583, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17647, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.445118965517241 ], [ 29.784824303797468, -1.445388448275862 ], [ 29.785093797468356, -1.445388448275862 ], [ 29.785093797468356, -1.445118965517241 ], [ 29.784824303797468, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17648, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.445118965517241 ], [ 29.785093797468356, -1.445388448275862 ], [ 29.785363291139241, -1.445388448275862 ], [ 29.785363291139241, -1.445118965517241 ], [ 29.785093797468356, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17649, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.445118965517241 ], [ 29.785363291139241, -1.445388448275862 ], [ 29.785632784810126, -1.445388448275862 ], [ 29.785632784810126, -1.445118965517241 ], [ 29.785363291139241, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17650, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.445118965517241 ], [ 29.785632784810126, -1.445388448275862 ], [ 29.785902278481014, -1.445388448275862 ], [ 29.785902278481014, -1.445118965517241 ], [ 29.785632784810126, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17651, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.445118965517241 ], [ 29.785902278481014, -1.445388448275862 ], [ 29.786171772151899, -1.445388448275862 ], [ 29.786171772151899, -1.445118965517241 ], [ 29.785902278481014, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17652, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.445118965517241 ], [ 29.786171772151899, -1.445388448275862 ], [ 29.786441265822784, -1.445388448275862 ], [ 29.786441265822784, -1.445118965517241 ], [ 29.786171772151899, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17653, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.445118965517241 ], [ 29.786441265822784, -1.445388448275862 ], [ 29.786710759493673, -1.445388448275862 ], [ 29.786710759493673, -1.445118965517241 ], [ 29.786441265822784, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17654, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.445118965517241 ], [ 29.786710759493673, -1.445388448275862 ], [ 29.786980253164558, -1.445388448275862 ], [ 29.786980253164558, -1.445118965517241 ], [ 29.786710759493673, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17655, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.445118965517241 ], [ 29.786980253164558, -1.445388448275862 ], [ 29.787249746835442, -1.445388448275862 ], [ 29.787249746835442, -1.445118965517241 ], [ 29.786980253164558, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17656, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.445118965517241 ], [ 29.787249746835442, -1.445388448275862 ], [ 29.787519240506327, -1.445388448275862 ], [ 29.787519240506327, -1.445118965517241 ], [ 29.787249746835442, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17657, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.445118965517241 ], [ 29.787519240506327, -1.445388448275862 ], [ 29.787788734177216, -1.445388448275862 ], [ 29.787788734177216, -1.445118965517241 ], [ 29.787519240506327, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17658, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.445118965517241 ], [ 29.787788734177216, -1.445388448275862 ], [ 29.788327721518986, -1.445388448275862 ], [ 29.788327721518986, -1.445118965517241 ], [ 29.787788734177216, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17659, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.445118965517241 ], [ 29.788327721518986, -1.445388448275862 ], [ 29.788597215189874, -1.445388448275862 ], [ 29.788597215189874, -1.445118965517241 ], [ 29.788327721518986, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17660, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.445118965517241 ], [ 29.788597215189874, -1.445388448275862 ], [ 29.788866708860759, -1.445388448275862 ], [ 29.788866708860759, -1.445118965517241 ], [ 29.788597215189874, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17661, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.445118965517241 ], [ 29.789405696202532, -1.445657931034483 ], [ 29.789675189873417, -1.445657931034483 ], [ 29.789675189873417, -1.445118965517241 ], [ 29.789405696202532, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17662, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.445118965517241 ], [ 29.789675189873417, -1.445657931034483 ], [ 29.789944683544302, -1.445657931034483 ], [ 29.789944683544302, -1.445118965517241 ], [ 29.789675189873417, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17663, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.445118965517241 ], [ 29.789944683544302, -1.445657931034483 ], [ 29.790214177215191, -1.445657931034483 ], [ 29.790214177215191, -1.445118965517241 ], [ 29.789944683544302, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17664, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.445118965517241 ], [ 29.790214177215191, -1.445388448275862 ], [ 29.790483670886076, -1.445388448275862 ], [ 29.790483670886076, -1.445118965517241 ], [ 29.790214177215191, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17665, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.445118965517241 ], [ 29.790483670886076, -1.445388448275862 ], [ 29.790753164556961, -1.445388448275862 ], [ 29.790753164556961, -1.445118965517241 ], [ 29.790483670886076, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17666, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.445118965517241 ], [ 29.790753164556961, -1.445388448275862 ], [ 29.791022658227849, -1.445388448275862 ], [ 29.791022658227849, -1.445118965517241 ], [ 29.790753164556961, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17667, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.445118965517241 ], [ 29.791022658227849, -1.445388448275862 ], [ 29.791292151898734, -1.445388448275862 ], [ 29.791292151898734, -1.445118965517241 ], [ 29.791022658227849, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17668, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.445118965517241 ], [ 29.791292151898734, -1.445388448275862 ], [ 29.791561645569619, -1.445388448275862 ], [ 29.791561645569619, -1.445118965517241 ], [ 29.791292151898734, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17669, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.445118965517241 ], [ 29.791561645569619, -1.445388448275862 ], [ 29.791831139240507, -1.445388448275862 ], [ 29.791831139240507, -1.445118965517241 ], [ 29.791561645569619, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17670, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.445118965517241 ], [ 29.791831139240507, -1.445388448275862 ], [ 29.792100632911392, -1.445388448275862 ], [ 29.792100632911392, -1.445118965517241 ], [ 29.791831139240507, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17671, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.445118965517241 ], [ 29.792100632911392, -1.445388448275862 ], [ 29.792370126582277, -1.445388448275862 ], [ 29.792370126582277, -1.445118965517241 ], [ 29.792100632911392, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17672, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.445118965517241 ], [ 29.792370126582277, -1.445388448275862 ], [ 29.792909113924051, -1.445388448275862 ], [ 29.792909113924051, -1.445118965517241 ], [ 29.792370126582277, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17673, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.445118965517241 ], [ 29.792909113924051, -1.445388448275862 ], [ 29.793178607594935, -1.445388448275862 ], [ 29.793178607594935, -1.445118965517241 ], [ 29.792909113924051, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17674, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.445118965517241 ], [ 29.793178607594935, -1.445388448275862 ], [ 29.793448101265824, -1.445388448275862 ], [ 29.793448101265824, -1.445118965517241 ], [ 29.793178607594935, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17675, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.445118965517241 ], [ 29.793448101265824, -1.445388448275862 ], [ 29.793717594936709, -1.445388448275862 ], [ 29.793717594936709, -1.445118965517241 ], [ 29.793448101265824, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17676, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.445118965517241 ], [ 29.802071898734177, -1.445388448275862 ], [ 29.802341392405065, -1.445388448275862 ], [ 29.802341392405065, -1.445118965517241 ], [ 29.802071898734177, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17677, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.445118965517241 ], [ 29.802341392405065, -1.445388448275862 ], [ 29.80261088607595, -1.445388448275862 ], [ 29.80261088607595, -1.445118965517241 ], [ 29.802341392405065, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17678, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.445118965517241 ], [ 29.80261088607595, -1.445388448275862 ], [ 29.802880379746835, -1.445388448275862 ], [ 29.802880379746835, -1.445118965517241 ], [ 29.80261088607595, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17679, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.445118965517241 ], [ 29.802880379746835, -1.445657931034483 ], [ 29.803149873417723, -1.445657931034483 ], [ 29.803149873417723, -1.445118965517241 ], [ 29.802880379746835, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17680, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.445118965517241 ], [ 29.803149873417723, -1.445388448275862 ], [ 29.803419367088608, -1.445388448275862 ], [ 29.803419367088608, -1.445118965517241 ], [ 29.803149873417723, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17681, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.445118965517241 ], [ 29.803419367088608, -1.445388448275862 ], [ 29.803688860759493, -1.445388448275862 ], [ 29.803688860759493, -1.445118965517241 ], [ 29.803419367088608, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17682, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.445118965517241 ], [ 29.803688860759493, -1.445927413793104 ], [ 29.803958354430382, -1.445927413793104 ], [ 29.803958354430382, -1.445118965517241 ], [ 29.803688860759493, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17683, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.445118965517241 ], [ 29.804227848101267, -1.445388448275862 ], [ 29.804497341772151, -1.445388448275862 ], [ 29.804497341772151, -1.445118965517241 ], [ 29.804227848101267, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17684, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.445118965517241 ], [ 29.804497341772151, -1.445388448275862 ], [ 29.80476683544304, -1.445388448275862 ], [ 29.80476683544304, -1.445118965517241 ], [ 29.804497341772151, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17685, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.445118965517241 ], [ 29.80476683544304, -1.445388448275862 ], [ 29.805036329113925, -1.445388448275862 ], [ 29.805036329113925, -1.445118965517241 ], [ 29.80476683544304, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17686, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.445118965517241 ], [ 29.805036329113925, -1.445388448275862 ], [ 29.80530582278481, -1.445388448275862 ], [ 29.80530582278481, -1.445118965517241 ], [ 29.805036329113925, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17687, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.445118965517241 ], [ 29.80530582278481, -1.445388448275862 ], [ 29.805575316455698, -1.445388448275862 ], [ 29.805575316455698, -1.445118965517241 ], [ 29.80530582278481, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17688, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.445118965517241 ], [ 29.805575316455698, -1.445388448275862 ], [ 29.805844810126583, -1.445388448275862 ], [ 29.805844810126583, -1.445118965517241 ], [ 29.805575316455698, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17689, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.445118965517241 ], [ 29.805844810126583, -1.445388448275862 ], [ 29.806114303797468, -1.445388448275862 ], [ 29.806114303797468, -1.445118965517241 ], [ 29.805844810126583, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17690, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.445118965517241 ], [ 29.806114303797468, -1.445388448275862 ], [ 29.806383797468357, -1.445388448275862 ], [ 29.806383797468357, -1.445118965517241 ], [ 29.806114303797468, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17691, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.445118965517241 ], [ 29.806383797468357, -1.445388448275862 ], [ 29.806653291139241, -1.445388448275862 ], [ 29.806653291139241, -1.445118965517241 ], [ 29.806383797468357, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17692, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.445118965517241 ], [ 29.806653291139241, -1.445388448275862 ], [ 29.806922784810126, -1.445388448275862 ], [ 29.806922784810126, -1.445118965517241 ], [ 29.806653291139241, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17693, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.445118965517241 ], [ 29.806922784810126, -1.445388448275862 ], [ 29.807192278481015, -1.445388448275862 ], [ 29.807192278481015, -1.445118965517241 ], [ 29.806922784810126, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17694, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.445118965517241 ], [ 29.807192278481015, -1.445388448275862 ], [ 29.8074617721519, -1.445388448275862 ], [ 29.8074617721519, -1.445118965517241 ], [ 29.807192278481015, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17695, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.445118965517241 ], [ 29.8074617721519, -1.445388448275862 ], [ 29.807731265822785, -1.445388448275862 ], [ 29.807731265822785, -1.445118965517241 ], [ 29.8074617721519, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17696, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.445388448275862 ], [ 29.747364683544301, -1.445657931034483 ], [ 29.747903670886075, -1.445657931034483 ], [ 29.747903670886075, -1.445388448275862 ], [ 29.747364683544301, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17697, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.445388448275862 ], [ 29.747903670886075, -1.445657931034483 ], [ 29.74817316455696, -1.445657931034483 ], [ 29.74817316455696, -1.445388448275862 ], [ 29.747903670886075, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17698, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.445388448275862 ], [ 29.74817316455696, -1.445657931034483 ], [ 29.748442658227848, -1.445657931034483 ], [ 29.748442658227848, -1.445388448275862 ], [ 29.74817316455696, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17699, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.445388448275862 ], [ 29.748442658227848, -1.445927413793104 ], [ 29.748712151898733, -1.445927413793104 ], [ 29.748712151898733, -1.445388448275862 ], [ 29.748442658227848, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17700, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.445388448275862 ], [ 29.748712151898733, -1.445927413793104 ], [ 29.748981645569618, -1.445927413793104 ], [ 29.748981645569618, -1.445388448275862 ], [ 29.748712151898733, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17701, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.445388448275862 ], [ 29.749251139240506, -1.445657931034483 ], [ 29.749520632911391, -1.445657931034483 ], [ 29.749520632911391, -1.445388448275862 ], [ 29.749251139240506, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17702, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.445388448275862 ], [ 29.749520632911391, -1.445657931034483 ], [ 29.749790126582276, -1.445657931034483 ], [ 29.749790126582276, -1.445388448275862 ], [ 29.749520632911391, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17703, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.445388448275862 ], [ 29.749790126582276, -1.445657931034483 ], [ 29.750059620253165, -1.445657931034483 ], [ 29.750059620253165, -1.445388448275862 ], [ 29.749790126582276, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17704, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.445388448275862 ], [ 29.750059620253165, -1.445657931034483 ], [ 29.75032911392405, -1.445657931034483 ], [ 29.75032911392405, -1.445388448275862 ], [ 29.750059620253165, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17705, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.445388448275862 ], [ 29.75032911392405, -1.445657931034483 ], [ 29.750598607594934, -1.445657931034483 ], [ 29.750598607594934, -1.445388448275862 ], [ 29.75032911392405, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17706, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.445388448275862 ], [ 29.750598607594934, -1.445657931034483 ], [ 29.750868101265823, -1.445657931034483 ], [ 29.750868101265823, -1.445388448275862 ], [ 29.750598607594934, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17707, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.445388448275862 ], [ 29.750868101265823, -1.445657931034483 ], [ 29.751137594936708, -1.445657931034483 ], [ 29.751137594936708, -1.445388448275862 ], [ 29.750868101265823, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17708, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.445388448275862 ], [ 29.751137594936708, -1.445657931034483 ], [ 29.751407088607593, -1.445657931034483 ], [ 29.751407088607593, -1.445388448275862 ], [ 29.751137594936708, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17709, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.445388448275862 ], [ 29.751407088607593, -1.445657931034483 ], [ 29.751676582278481, -1.445657931034483 ], [ 29.751676582278481, -1.445388448275862 ], [ 29.751407088607593, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17710, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.445388448275862 ], [ 29.751676582278481, -1.445657931034483 ], [ 29.751946075949366, -1.445657931034483 ], [ 29.751946075949366, -1.445388448275862 ], [ 29.751676582278481, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17711, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.445388448275862 ], [ 29.751946075949366, -1.445657931034483 ], [ 29.752215569620251, -1.445657931034483 ], [ 29.752215569620251, -1.445388448275862 ], [ 29.751946075949366, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17712, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.445388448275862 ], [ 29.752215569620251, -1.445657931034483 ], [ 29.75248506329114, -1.445657931034483 ], [ 29.75248506329114, -1.445388448275862 ], [ 29.752215569620251, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17713, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.445388448275862 ], [ 29.75248506329114, -1.445657931034483 ], [ 29.752754556962024, -1.445657931034483 ], [ 29.752754556962024, -1.445388448275862 ], [ 29.75248506329114, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17714, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.445388448275862 ], [ 29.752754556962024, -1.445657931034483 ], [ 29.753024050632909, -1.445657931034483 ], [ 29.753024050632909, -1.445388448275862 ], [ 29.752754556962024, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17715, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.445388448275862 ], [ 29.753024050632909, -1.445657931034483 ], [ 29.753293544303798, -1.445657931034483 ], [ 29.753293544303798, -1.445388448275862 ], [ 29.753024050632909, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17716, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.445388448275862 ], [ 29.753293544303798, -1.445657931034483 ], [ 29.753563037974683, -1.445657931034483 ], [ 29.753563037974683, -1.445388448275862 ], [ 29.753293544303798, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17717, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.445388448275862 ], [ 29.753563037974683, -1.445657931034483 ], [ 29.753832531645568, -1.445657931034483 ], [ 29.753832531645568, -1.445388448275862 ], [ 29.753563037974683, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17718, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.445388448275862 ], [ 29.753832531645568, -1.445657931034483 ], [ 29.754102025316456, -1.445657931034483 ], [ 29.754102025316456, -1.445388448275862 ], [ 29.753832531645568, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17719, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.445388448275862 ], [ 29.754102025316456, -1.445657931034483 ], [ 29.754371518987341, -1.445657931034483 ], [ 29.754371518987341, -1.445388448275862 ], [ 29.754102025316456, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17720, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.445388448275862 ], [ 29.754371518987341, -1.445657931034483 ], [ 29.754641012658226, -1.445657931034483 ], [ 29.754641012658226, -1.445388448275862 ], [ 29.754371518987341, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17721, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.445388448275862 ], [ 29.754641012658226, -1.445657931034483 ], [ 29.754910506329114, -1.445657931034483 ], [ 29.754910506329114, -1.445388448275862 ], [ 29.754641012658226, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17722, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.445388448275862 ], [ 29.754910506329114, -1.445657931034483 ], [ 29.75518, -1.445657931034483 ], [ 29.75518, -1.445388448275862 ], [ 29.754910506329114, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17723, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.445388448275862 ], [ 29.75518, -1.445657931034483 ], [ 29.755449493670884, -1.445657931034483 ], [ 29.755449493670884, -1.445388448275862 ], [ 29.75518, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17724, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.445388448275862 ], [ 29.755449493670884, -1.445927413793104 ], [ 29.755718987341773, -1.445927413793104 ], [ 29.755718987341773, -1.445388448275862 ], [ 29.755449493670884, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17725, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.445388448275862 ], [ 29.755718987341773, -1.445657931034483 ], [ 29.755988481012658, -1.445657931034483 ], [ 29.755988481012658, -1.445388448275862 ], [ 29.755718987341773, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17726, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.445388448275862 ], [ 29.755988481012658, -1.445657931034483 ], [ 29.756257974683542, -1.445657931034483 ], [ 29.756257974683542, -1.445388448275862 ], [ 29.755988481012658, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17727, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.445388448275862 ], [ 29.756257974683542, -1.445657931034483 ], [ 29.756796962025316, -1.445657931034483 ], [ 29.756796962025316, -1.445388448275862 ], [ 29.756257974683542, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17728, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.445388448275862 ], [ 29.756796962025316, -1.445657931034483 ], [ 29.757066455696201, -1.445657931034483 ], [ 29.757066455696201, -1.445388448275862 ], [ 29.756796962025316, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17729, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.445388448275862 ], [ 29.757066455696201, -1.445657931034483 ], [ 29.757335949367089, -1.445657931034483 ], [ 29.757335949367089, -1.445388448275862 ], [ 29.757066455696201, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17730, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.445388448275862 ], [ 29.757335949367089, -1.445927413793104 ], [ 29.757605443037974, -1.445927413793104 ], [ 29.757605443037974, -1.445388448275862 ], [ 29.757335949367089, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17731, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.445388448275862 ], [ 29.757605443037974, -1.445657931034483 ], [ 29.757874936708859, -1.445657931034483 ], [ 29.757874936708859, -1.445388448275862 ], [ 29.757605443037974, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17732, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.445388448275862 ], [ 29.757874936708859, -1.445657931034483 ], [ 29.758144430379748, -1.445657931034483 ], [ 29.758144430379748, -1.445388448275862 ], [ 29.757874936708859, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17733, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.445388448275862 ], [ 29.758144430379748, -1.445657931034483 ], [ 29.758413924050632, -1.445657931034483 ], [ 29.758413924050632, -1.445388448275862 ], [ 29.758144430379748, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17734, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.445388448275862 ], [ 29.758413924050632, -1.445657931034483 ], [ 29.758683417721517, -1.445657931034483 ], [ 29.758683417721517, -1.445388448275862 ], [ 29.758413924050632, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17735, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.445388448275862 ], [ 29.758683417721517, -1.445657931034483 ], [ 29.758952911392406, -1.445657931034483 ], [ 29.758952911392406, -1.445388448275862 ], [ 29.758683417721517, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17736, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.445388448275862 ], [ 29.758952911392406, -1.445657931034483 ], [ 29.759222405063291, -1.445657931034483 ], [ 29.759222405063291, -1.445388448275862 ], [ 29.758952911392406, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17737, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.445388448275862 ], [ 29.759222405063291, -1.445657931034483 ], [ 29.759491898734176, -1.445657931034483 ], [ 29.759491898734176, -1.445388448275862 ], [ 29.759222405063291, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17738, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.445388448275862 ], [ 29.759491898734176, -1.445657931034483 ], [ 29.759761392405064, -1.445657931034483 ], [ 29.759761392405064, -1.445388448275862 ], [ 29.759491898734176, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17739, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.445388448275862 ], [ 29.759761392405064, -1.445657931034483 ], [ 29.760030886075949, -1.445657931034483 ], [ 29.760030886075949, -1.445388448275862 ], [ 29.759761392405064, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17740, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.445388448275862 ], [ 29.760030886075949, -1.445657931034483 ], [ 29.760300379746834, -1.445657931034483 ], [ 29.760300379746834, -1.445388448275862 ], [ 29.760030886075949, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17741, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.445388448275862 ], [ 29.760300379746834, -1.445657931034483 ], [ 29.760569873417722, -1.445657931034483 ], [ 29.760569873417722, -1.445388448275862 ], [ 29.760300379746834, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17742, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.445388448275862 ], [ 29.760569873417722, -1.445657931034483 ], [ 29.760839367088607, -1.445657931034483 ], [ 29.760839367088607, -1.445388448275862 ], [ 29.760569873417722, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17743, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.445388448275862 ], [ 29.760839367088607, -1.445657931034483 ], [ 29.761108860759492, -1.445657931034483 ], [ 29.761108860759492, -1.445388448275862 ], [ 29.760839367088607, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17744, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.445388448275862 ], [ 29.761108860759492, -1.445657931034483 ], [ 29.761378354430381, -1.445657931034483 ], [ 29.761378354430381, -1.445388448275862 ], [ 29.761108860759492, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17745, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.445388448275862 ], [ 29.761378354430381, -1.445657931034483 ], [ 29.761647848101266, -1.445657931034483 ], [ 29.761647848101266, -1.445388448275862 ], [ 29.761378354430381, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17746, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.445388448275862 ], [ 29.761647848101266, -1.445657931034483 ], [ 29.761917341772151, -1.445657931034483 ], [ 29.761917341772151, -1.445388448275862 ], [ 29.761647848101266, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17747, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.445388448275862 ], [ 29.761917341772151, -1.445657931034483 ], [ 29.763264810126582, -1.445657931034483 ], [ 29.763264810126582, -1.445388448275862 ], [ 29.761917341772151, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17748, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.445388448275862 ], [ 29.763264810126582, -1.445657931034483 ], [ 29.763534303797467, -1.445657931034483 ], [ 29.763534303797467, -1.445388448275862 ], [ 29.763264810126582, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17749, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.445388448275862 ], [ 29.763534303797467, -1.445657931034483 ], [ 29.763803797468356, -1.445657931034483 ], [ 29.763803797468356, -1.445388448275862 ], [ 29.763534303797467, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17750, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.445388448275862 ], [ 29.763803797468356, -1.445657931034483 ], [ 29.76407329113924, -1.445657931034483 ], [ 29.76407329113924, -1.445388448275862 ], [ 29.763803797468356, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17751, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.445388448275862 ], [ 29.76407329113924, -1.445657931034483 ], [ 29.764342784810125, -1.445657931034483 ], [ 29.764342784810125, -1.445388448275862 ], [ 29.76407329113924, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17752, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.445388448275862 ], [ 29.764342784810125, -1.445657931034483 ], [ 29.764612278481014, -1.445657931034483 ], [ 29.764612278481014, -1.445388448275862 ], [ 29.764342784810125, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17753, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.445388448275862 ], [ 29.764612278481014, -1.445657931034483 ], [ 29.764881772151899, -1.445657931034483 ], [ 29.764881772151899, -1.445388448275862 ], [ 29.764612278481014, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17754, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.445388448275862 ], [ 29.764881772151899, -1.445657931034483 ], [ 29.765420759493672, -1.445657931034483 ], [ 29.765420759493672, -1.445388448275862 ], [ 29.764881772151899, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17755, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.445388448275862 ], [ 29.765420759493672, -1.445657931034483 ], [ 29.767846202531643, -1.445657931034483 ], [ 29.767846202531643, -1.445388448275862 ], [ 29.765420759493672, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17756, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.445388448275862 ], [ 29.767846202531643, -1.445657931034483 ], [ 29.768385189873417, -1.445657931034483 ], [ 29.768385189873417, -1.445388448275862 ], [ 29.767846202531643, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17757, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.445388448275862 ], [ 29.768385189873417, -1.445657931034483 ], [ 29.768654683544302, -1.445657931034483 ], [ 29.768654683544302, -1.445388448275862 ], [ 29.768385189873417, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17758, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.445388448275862 ], [ 29.768654683544302, -1.445657931034483 ], [ 29.76892417721519, -1.445657931034483 ], [ 29.76892417721519, -1.445388448275862 ], [ 29.768654683544302, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17759, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.445388448275862 ], [ 29.76892417721519, -1.445657931034483 ], [ 29.769193670886075, -1.445657931034483 ], [ 29.769193670886075, -1.445388448275862 ], [ 29.76892417721519, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17760, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.445388448275862 ], [ 29.769193670886075, -1.445657931034483 ], [ 29.76946316455696, -1.445657931034483 ], [ 29.76946316455696, -1.445388448275862 ], [ 29.769193670886075, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17761, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.445388448275862 ], [ 29.76946316455696, -1.445657931034483 ], [ 29.769732658227849, -1.445657931034483 ], [ 29.769732658227849, -1.445388448275862 ], [ 29.76946316455696, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17762, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.445388448275862 ], [ 29.769732658227849, -1.445657931034483 ], [ 29.770002151898733, -1.445657931034483 ], [ 29.770002151898733, -1.445388448275862 ], [ 29.769732658227849, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17763, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.445388448275862 ], [ 29.770002151898733, -1.445657931034483 ], [ 29.770271645569618, -1.445657931034483 ], [ 29.770271645569618, -1.445388448275862 ], [ 29.770002151898733, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17764, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.445388448275862 ], [ 29.770271645569618, -1.445657931034483 ], [ 29.770541139240507, -1.445657931034483 ], [ 29.770541139240507, -1.445388448275862 ], [ 29.770271645569618, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17765, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.445388448275862 ], [ 29.770541139240507, -1.445657931034483 ], [ 29.770810632911392, -1.445657931034483 ], [ 29.770810632911392, -1.445388448275862 ], [ 29.770541139240507, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17766, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.445388448275862 ], [ 29.770810632911392, -1.445657931034483 ], [ 29.771080126582277, -1.445657931034483 ], [ 29.771080126582277, -1.445388448275862 ], [ 29.770810632911392, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17767, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.445388448275862 ], [ 29.771080126582277, -1.445657931034483 ], [ 29.771349620253165, -1.445657931034483 ], [ 29.771349620253165, -1.445388448275862 ], [ 29.771080126582277, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17768, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.445388448275862 ], [ 29.771349620253165, -1.445657931034483 ], [ 29.77161911392405, -1.445657931034483 ], [ 29.77161911392405, -1.445388448275862 ], [ 29.771349620253165, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17769, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.445388448275862 ], [ 29.77161911392405, -1.445657931034483 ], [ 29.771888607594935, -1.445657931034483 ], [ 29.771888607594935, -1.445388448275862 ], [ 29.77161911392405, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17770, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.445388448275862 ], [ 29.771888607594935, -1.445657931034483 ], [ 29.772158101265823, -1.445657931034483 ], [ 29.772158101265823, -1.445388448275862 ], [ 29.771888607594935, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17771, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.445388448275862 ], [ 29.772158101265823, -1.445657931034483 ], [ 29.772427594936708, -1.445657931034483 ], [ 29.772427594936708, -1.445388448275862 ], [ 29.772158101265823, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17772, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.445388448275862 ], [ 29.772427594936708, -1.445657931034483 ], [ 29.772697088607593, -1.445657931034483 ], [ 29.772697088607593, -1.445388448275862 ], [ 29.772427594936708, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17773, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.445388448275862 ], [ 29.772697088607593, -1.445657931034483 ], [ 29.772966582278482, -1.445657931034483 ], [ 29.772966582278482, -1.445388448275862 ], [ 29.772697088607593, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17774, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.445388448275862 ], [ 29.772966582278482, -1.445657931034483 ], [ 29.773236075949367, -1.445657931034483 ], [ 29.773236075949367, -1.445388448275862 ], [ 29.772966582278482, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17775, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.445388448275862 ], [ 29.773236075949367, -1.445657931034483 ], [ 29.773505569620252, -1.445657931034483 ], [ 29.773505569620252, -1.445388448275862 ], [ 29.773236075949367, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17776, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.445388448275862 ], [ 29.773505569620252, -1.445657931034483 ], [ 29.77377506329114, -1.445657931034483 ], [ 29.77377506329114, -1.445388448275862 ], [ 29.773505569620252, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17777, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.445388448275862 ], [ 29.77377506329114, -1.445657931034483 ], [ 29.774044556962025, -1.445657931034483 ], [ 29.774044556962025, -1.445388448275862 ], [ 29.77377506329114, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17778, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.445388448275862 ], [ 29.774044556962025, -1.445657931034483 ], [ 29.77431405063291, -1.445657931034483 ], [ 29.77431405063291, -1.445388448275862 ], [ 29.774044556962025, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17779, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.445388448275862 ], [ 29.77431405063291, -1.445657931034483 ], [ 29.774853037974683, -1.445657931034483 ], [ 29.774853037974683, -1.445388448275862 ], [ 29.77431405063291, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17780, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.445388448275862 ], [ 29.774853037974683, -1.445657931034483 ], [ 29.775122531645568, -1.445657931034483 ], [ 29.775122531645568, -1.445388448275862 ], [ 29.774853037974683, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17781, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.445388448275862 ], [ 29.775122531645568, -1.445657931034483 ], [ 29.775392025316457, -1.445657931034483 ], [ 29.775392025316457, -1.445388448275862 ], [ 29.775122531645568, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17782, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.445388448275862 ], [ 29.775392025316457, -1.445657931034483 ], [ 29.775661518987341, -1.445657931034483 ], [ 29.775661518987341, -1.445388448275862 ], [ 29.775392025316457, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17783, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.445388448275862 ], [ 29.775661518987341, -1.445657931034483 ], [ 29.775931012658226, -1.445657931034483 ], [ 29.775931012658226, -1.445388448275862 ], [ 29.775661518987341, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17784, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.445388448275862 ], [ 29.775931012658226, -1.445657931034483 ], [ 29.776200506329115, -1.445657931034483 ], [ 29.776200506329115, -1.445388448275862 ], [ 29.775931012658226, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17785, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.445388448275862 ], [ 29.776200506329115, -1.445657931034483 ], [ 29.77647, -1.445657931034483 ], [ 29.77647, -1.445388448275862 ], [ 29.776200506329115, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17786, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.445388448275862 ], [ 29.77647, -1.445657931034483 ], [ 29.776739493670885, -1.445657931034483 ], [ 29.776739493670885, -1.445388448275862 ], [ 29.77647, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17787, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.445388448275862 ], [ 29.777008987341773, -1.445657931034483 ], [ 29.777278481012658, -1.445657931034483 ], [ 29.777278481012658, -1.445388448275862 ], [ 29.777008987341773, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17788, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.445388448275862 ], [ 29.777547974683543, -1.445657931034483 ], [ 29.777817468354431, -1.445657931034483 ], [ 29.777817468354431, -1.445388448275862 ], [ 29.777547974683543, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17789, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.445388448275862 ], [ 29.777817468354431, -1.445657931034483 ], [ 29.778086962025316, -1.445657931034483 ], [ 29.778086962025316, -1.445388448275862 ], [ 29.777817468354431, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17790, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.445388448275862 ], [ 29.778086962025316, -1.445927413793104 ], [ 29.778356455696201, -1.445927413793104 ], [ 29.778356455696201, -1.445388448275862 ], [ 29.778086962025316, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17791, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.445388448275862 ], [ 29.778356455696201, -1.445657931034483 ], [ 29.77862594936709, -1.445657931034483 ], [ 29.77862594936709, -1.445388448275862 ], [ 29.778356455696201, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17792, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.445388448275862 ], [ 29.77862594936709, -1.445657931034483 ], [ 29.778895443037975, -1.445657931034483 ], [ 29.778895443037975, -1.445388448275862 ], [ 29.77862594936709, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17793, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.445388448275862 ], [ 29.778895443037975, -1.445657931034483 ], [ 29.77916493670886, -1.445657931034483 ], [ 29.77916493670886, -1.445388448275862 ], [ 29.778895443037975, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17794, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.445388448275862 ], [ 29.779434430379748, -1.445657931034483 ], [ 29.779703924050633, -1.445657931034483 ], [ 29.779703924050633, -1.445388448275862 ], [ 29.779434430379748, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17795, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.445388448275862 ], [ 29.779703924050633, -1.445657931034483 ], [ 29.779973417721518, -1.445657931034483 ], [ 29.779973417721518, -1.445388448275862 ], [ 29.779703924050633, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17796, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.445388448275862 ], [ 29.779973417721518, -1.445657931034483 ], [ 29.780242911392406, -1.445657931034483 ], [ 29.780242911392406, -1.445388448275862 ], [ 29.779973417721518, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17797, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.445388448275862 ], [ 29.780242911392406, -1.445657931034483 ], [ 29.780512405063291, -1.445657931034483 ], [ 29.780512405063291, -1.445388448275862 ], [ 29.780242911392406, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17798, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.445388448275862 ], [ 29.780512405063291, -1.445657931034483 ], [ 29.780781898734176, -1.445657931034483 ], [ 29.780781898734176, -1.445388448275862 ], [ 29.780512405063291, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17799, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.445388448275862 ], [ 29.780781898734176, -1.445657931034483 ], [ 29.781051392405065, -1.445657931034483 ], [ 29.781051392405065, -1.445388448275862 ], [ 29.780781898734176, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17800, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.445388448275862 ], [ 29.781051392405065, -1.445657931034483 ], [ 29.78132088607595, -1.445657931034483 ], [ 29.78132088607595, -1.445388448275862 ], [ 29.781051392405065, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17801, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.445388448275862 ], [ 29.78132088607595, -1.445657931034483 ], [ 29.781590379746834, -1.445657931034483 ], [ 29.781590379746834, -1.445388448275862 ], [ 29.78132088607595, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17802, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.445388448275862 ], [ 29.781590379746834, -1.445657931034483 ], [ 29.781859873417723, -1.445657931034483 ], [ 29.781859873417723, -1.445388448275862 ], [ 29.781590379746834, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17803, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.445388448275862 ], [ 29.781859873417723, -1.445657931034483 ], [ 29.782129367088608, -1.445657931034483 ], [ 29.782129367088608, -1.445388448275862 ], [ 29.781859873417723, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17804, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.445388448275862 ], [ 29.782129367088608, -1.445657931034483 ], [ 29.782398860759493, -1.445657931034483 ], [ 29.782398860759493, -1.445388448275862 ], [ 29.782129367088608, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17805, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.445388448275862 ], [ 29.782398860759493, -1.445657931034483 ], [ 29.782668354430381, -1.445657931034483 ], [ 29.782668354430381, -1.445388448275862 ], [ 29.782398860759493, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17806, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.445388448275862 ], [ 29.782668354430381, -1.445657931034483 ], [ 29.782937848101266, -1.445657931034483 ], [ 29.782937848101266, -1.445388448275862 ], [ 29.782668354430381, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17807, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.445388448275862 ], [ 29.782937848101266, -1.445657931034483 ], [ 29.783207341772151, -1.445657931034483 ], [ 29.783207341772151, -1.445388448275862 ], [ 29.782937848101266, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17808, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.445388448275862 ], [ 29.783207341772151, -1.445657931034483 ], [ 29.783476835443039, -1.445657931034483 ], [ 29.783476835443039, -1.445388448275862 ], [ 29.783207341772151, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17809, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.445388448275862 ], [ 29.783476835443039, -1.445657931034483 ], [ 29.784015822784809, -1.445657931034483 ], [ 29.784015822784809, -1.445388448275862 ], [ 29.783476835443039, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17810, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.445388448275862 ], [ 29.784015822784809, -1.445657931034483 ], [ 29.784285316455698, -1.445657931034483 ], [ 29.784285316455698, -1.445388448275862 ], [ 29.784015822784809, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17811, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.445388448275862 ], [ 29.784285316455698, -1.445657931034483 ], [ 29.784554810126583, -1.445657931034483 ], [ 29.784554810126583, -1.445388448275862 ], [ 29.784285316455698, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17812, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.445388448275862 ], [ 29.784554810126583, -1.445657931034483 ], [ 29.784824303797468, -1.445657931034483 ], [ 29.784824303797468, -1.445388448275862 ], [ 29.784554810126583, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17813, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.445388448275862 ], [ 29.784824303797468, -1.445657931034483 ], [ 29.785093797468356, -1.445657931034483 ], [ 29.785093797468356, -1.445388448275862 ], [ 29.784824303797468, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17814, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.445388448275862 ], [ 29.785093797468356, -1.445657931034483 ], [ 29.785363291139241, -1.445657931034483 ], [ 29.785363291139241, -1.445388448275862 ], [ 29.785093797468356, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17815, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.445388448275862 ], [ 29.785363291139241, -1.445657931034483 ], [ 29.785632784810126, -1.445657931034483 ], [ 29.785632784810126, -1.445388448275862 ], [ 29.785363291139241, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17816, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.445388448275862 ], [ 29.785632784810126, -1.445657931034483 ], [ 29.785902278481014, -1.445657931034483 ], [ 29.785902278481014, -1.445388448275862 ], [ 29.785632784810126, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17817, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.445388448275862 ], [ 29.785902278481014, -1.445657931034483 ], [ 29.786171772151899, -1.445657931034483 ], [ 29.786171772151899, -1.445388448275862 ], [ 29.785902278481014, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17818, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.445388448275862 ], [ 29.786171772151899, -1.445657931034483 ], [ 29.786441265822784, -1.445657931034483 ], [ 29.786441265822784, -1.445388448275862 ], [ 29.786171772151899, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17819, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.445388448275862 ], [ 29.786441265822784, -1.445657931034483 ], [ 29.786710759493673, -1.445657931034483 ], [ 29.786710759493673, -1.445388448275862 ], [ 29.786441265822784, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17820, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.445388448275862 ], [ 29.786710759493673, -1.445657931034483 ], [ 29.786980253164558, -1.445657931034483 ], [ 29.786980253164558, -1.445388448275862 ], [ 29.786710759493673, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17821, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.445388448275862 ], [ 29.786980253164558, -1.445657931034483 ], [ 29.787249746835442, -1.445657931034483 ], [ 29.787249746835442, -1.445388448275862 ], [ 29.786980253164558, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17822, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.445388448275862 ], [ 29.787249746835442, -1.445657931034483 ], [ 29.787519240506327, -1.445657931034483 ], [ 29.787519240506327, -1.445388448275862 ], [ 29.787249746835442, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17823, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.445388448275862 ], [ 29.787519240506327, -1.445657931034483 ], [ 29.787788734177216, -1.445657931034483 ], [ 29.787788734177216, -1.445388448275862 ], [ 29.787519240506327, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17824, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.445388448275862 ], [ 29.787788734177216, -1.445657931034483 ], [ 29.788327721518986, -1.445657931034483 ], [ 29.788327721518986, -1.445388448275862 ], [ 29.787788734177216, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17825, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.445388448275862 ], [ 29.788327721518986, -1.445927413793104 ], [ 29.788597215189874, -1.445927413793104 ], [ 29.788597215189874, -1.445388448275862 ], [ 29.788327721518986, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17826, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.445118965517241 ], [ 29.789405696202532, -1.445118965517241 ], [ 29.789405696202532, -1.445657931034483 ], [ 29.788597215189874, -1.445657931034483 ], [ 29.788597215189874, -1.445388448275862 ], [ 29.788866708860759, -1.445388448275862 ], [ 29.788866708860759, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17827, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.445388448275862 ], [ 29.790214177215191, -1.445657931034483 ], [ 29.790483670886076, -1.445657931034483 ], [ 29.790483670886076, -1.445388448275862 ], [ 29.790214177215191, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17828, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.445388448275862 ], [ 29.790483670886076, -1.445657931034483 ], [ 29.790753164556961, -1.445657931034483 ], [ 29.790753164556961, -1.445388448275862 ], [ 29.790483670886076, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17829, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.445388448275862 ], [ 29.790753164556961, -1.445657931034483 ], [ 29.791022658227849, -1.445657931034483 ], [ 29.791022658227849, -1.445388448275862 ], [ 29.790753164556961, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17830, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.445388448275862 ], [ 29.791022658227849, -1.445657931034483 ], [ 29.791292151898734, -1.445657931034483 ], [ 29.791292151898734, -1.445388448275862 ], [ 29.791022658227849, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17831, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.445388448275862 ], [ 29.791292151898734, -1.445657931034483 ], [ 29.791561645569619, -1.445657931034483 ], [ 29.791561645569619, -1.445388448275862 ], [ 29.791292151898734, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17832, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.445388448275862 ], [ 29.791561645569619, -1.445927413793104 ], [ 29.791831139240507, -1.445927413793104 ], [ 29.791831139240507, -1.445388448275862 ], [ 29.791561645569619, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17833, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.445388448275862 ], [ 29.791831139240507, -1.445657931034483 ], [ 29.792100632911392, -1.445657931034483 ], [ 29.792100632911392, -1.445388448275862 ], [ 29.791831139240507, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17834, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.445388448275862 ], [ 29.792100632911392, -1.445657931034483 ], [ 29.792370126582277, -1.445657931034483 ], [ 29.792370126582277, -1.445388448275862 ], [ 29.792100632911392, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17835, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.445388448275862 ], [ 29.792370126582277, -1.445657931034483 ], [ 29.792909113924051, -1.445657931034483 ], [ 29.792909113924051, -1.445388448275862 ], [ 29.792370126582277, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17836, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.445388448275862 ], [ 29.792909113924051, -1.445657931034483 ], [ 29.793178607594935, -1.445657931034483 ], [ 29.793178607594935, -1.445388448275862 ], [ 29.792909113924051, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17837, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.445388448275862 ], [ 29.793178607594935, -1.445657931034483 ], [ 29.793448101265824, -1.445657931034483 ], [ 29.793448101265824, -1.445388448275862 ], [ 29.793178607594935, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17838, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.445388448275862 ], [ 29.793448101265824, -1.445657931034483 ], [ 29.793717594936709, -1.445657931034483 ], [ 29.793717594936709, -1.445388448275862 ], [ 29.793448101265824, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17839, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.445388448275862 ], [ 29.801263417721518, -1.445657931034483 ], [ 29.801532911392407, -1.445657931034483 ], [ 29.801532911392407, -1.445388448275862 ], [ 29.801263417721518, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17840, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.445388448275862 ], [ 29.801532911392407, -1.445657931034483 ], [ 29.802071898734177, -1.445657931034483 ], [ 29.802071898734177, -1.445388448275862 ], [ 29.801532911392407, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17841, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.445388448275862 ], [ 29.802071898734177, -1.445657931034483 ], [ 29.802341392405065, -1.445657931034483 ], [ 29.802341392405065, -1.445388448275862 ], [ 29.802071898734177, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17842, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.445388448275862 ], [ 29.803958354430382, -1.445927413793104 ], [ 29.804227848101267, -1.445927413793104 ], [ 29.804227848101267, -1.445388448275862 ], [ 29.803958354430382, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17843, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.445388448275862 ], [ 29.804227848101267, -1.445657931034483 ], [ 29.804497341772151, -1.445657931034483 ], [ 29.804497341772151, -1.445388448275862 ], [ 29.804227848101267, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17844, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.445388448275862 ], [ 29.804497341772151, -1.445657931034483 ], [ 29.80476683544304, -1.445657931034483 ], [ 29.80476683544304, -1.445388448275862 ], [ 29.804497341772151, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17845, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.445388448275862 ], [ 29.80476683544304, -1.445657931034483 ], [ 29.805036329113925, -1.445657931034483 ], [ 29.805036329113925, -1.445388448275862 ], [ 29.80476683544304, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17846, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.445388448275862 ], [ 29.805036329113925, -1.445657931034483 ], [ 29.80530582278481, -1.445657931034483 ], [ 29.80530582278481, -1.445388448275862 ], [ 29.805036329113925, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17847, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.445388448275862 ], [ 29.80530582278481, -1.445657931034483 ], [ 29.805575316455698, -1.445657931034483 ], [ 29.805575316455698, -1.445388448275862 ], [ 29.80530582278481, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17848, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.445388448275862 ], [ 29.805575316455698, -1.445657931034483 ], [ 29.805844810126583, -1.445657931034483 ], [ 29.805844810126583, -1.445388448275862 ], [ 29.805575316455698, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17849, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.445388448275862 ], [ 29.805844810126583, -1.445657931034483 ], [ 29.806114303797468, -1.445657931034483 ], [ 29.806114303797468, -1.445388448275862 ], [ 29.805844810126583, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17850, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.445388448275862 ], [ 29.806114303797468, -1.445657931034483 ], [ 29.806383797468357, -1.445657931034483 ], [ 29.806383797468357, -1.445388448275862 ], [ 29.806114303797468, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17851, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.445388448275862 ], [ 29.806383797468357, -1.445657931034483 ], [ 29.806653291139241, -1.445657931034483 ], [ 29.806653291139241, -1.445388448275862 ], [ 29.806383797468357, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17852, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.445388448275862 ], [ 29.806653291139241, -1.445657931034483 ], [ 29.806922784810126, -1.445657931034483 ], [ 29.806922784810126, -1.445388448275862 ], [ 29.806653291139241, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17853, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.445388448275862 ], [ 29.806922784810126, -1.445657931034483 ], [ 29.807192278481015, -1.445657931034483 ], [ 29.807192278481015, -1.445388448275862 ], [ 29.806922784810126, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17854, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.445657931034483 ], [ 29.748981645569618, -1.445927413793104 ], [ 29.749251139240506, -1.445927413793104 ], [ 29.749251139240506, -1.445657931034483 ], [ 29.748981645569618, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17855, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.445657931034483 ], [ 29.749251139240506, -1.445927413793104 ], [ 29.749520632911391, -1.445927413793104 ], [ 29.749520632911391, -1.445657931034483 ], [ 29.749251139240506, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17856, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.445657931034483 ], [ 29.749520632911391, -1.445927413793104 ], [ 29.749790126582276, -1.445927413793104 ], [ 29.749790126582276, -1.445657931034483 ], [ 29.749520632911391, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17857, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.445657931034483 ], [ 29.749790126582276, -1.445927413793104 ], [ 29.750059620253165, -1.445927413793104 ], [ 29.750059620253165, -1.445657931034483 ], [ 29.749790126582276, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17858, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.445657931034483 ], [ 29.750059620253165, -1.445927413793104 ], [ 29.75032911392405, -1.445927413793104 ], [ 29.75032911392405, -1.445657931034483 ], [ 29.750059620253165, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17859, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.445657931034483 ], [ 29.75032911392405, -1.445927413793104 ], [ 29.750598607594934, -1.445927413793104 ], [ 29.750598607594934, -1.445657931034483 ], [ 29.75032911392405, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17860, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.445657931034483 ], [ 29.750598607594934, -1.445927413793104 ], [ 29.750868101265823, -1.445927413793104 ], [ 29.750868101265823, -1.445657931034483 ], [ 29.750598607594934, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17861, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.445657931034483 ], [ 29.750868101265823, -1.445927413793104 ], [ 29.751137594936708, -1.445927413793104 ], [ 29.751137594936708, -1.445657931034483 ], [ 29.750868101265823, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17862, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.445657931034483 ], [ 29.751137594936708, -1.445927413793104 ], [ 29.751407088607593, -1.445927413793104 ], [ 29.751407088607593, -1.445657931034483 ], [ 29.751137594936708, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17863, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.445657931034483 ], [ 29.751407088607593, -1.445927413793104 ], [ 29.751676582278481, -1.445927413793104 ], [ 29.751676582278481, -1.445657931034483 ], [ 29.751407088607593, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17864, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.445657931034483 ], [ 29.751676582278481, -1.445927413793104 ], [ 29.751946075949366, -1.445927413793104 ], [ 29.751946075949366, -1.445657931034483 ], [ 29.751676582278481, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17865, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.445657931034483 ], [ 29.751946075949366, -1.445927413793104 ], [ 29.752215569620251, -1.445927413793104 ], [ 29.752215569620251, -1.445657931034483 ], [ 29.751946075949366, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17866, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.445657931034483 ], [ 29.752215569620251, -1.445927413793104 ], [ 29.75248506329114, -1.445927413793104 ], [ 29.75248506329114, -1.445657931034483 ], [ 29.752215569620251, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17867, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.445657931034483 ], [ 29.75248506329114, -1.445927413793104 ], [ 29.752754556962024, -1.445927413793104 ], [ 29.752754556962024, -1.445657931034483 ], [ 29.75248506329114, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17868, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.445657931034483 ], [ 29.752754556962024, -1.445927413793104 ], [ 29.753024050632909, -1.445927413793104 ], [ 29.753024050632909, -1.445657931034483 ], [ 29.752754556962024, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17869, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.445657931034483 ], [ 29.753024050632909, -1.445927413793104 ], [ 29.753293544303798, -1.445927413793104 ], [ 29.753293544303798, -1.445657931034483 ], [ 29.753024050632909, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17870, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.445657931034483 ], [ 29.753293544303798, -1.445927413793104 ], [ 29.753563037974683, -1.445927413793104 ], [ 29.753563037974683, -1.445657931034483 ], [ 29.753293544303798, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17871, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.445657931034483 ], [ 29.753563037974683, -1.445927413793104 ], [ 29.753832531645568, -1.445927413793104 ], [ 29.753832531645568, -1.445657931034483 ], [ 29.753563037974683, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17872, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.445657931034483 ], [ 29.753832531645568, -1.445927413793104 ], [ 29.754102025316456, -1.445927413793104 ], [ 29.754102025316456, -1.445657931034483 ], [ 29.753832531645568, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17873, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.445657931034483 ], [ 29.754102025316456, -1.445927413793104 ], [ 29.754371518987341, -1.445927413793104 ], [ 29.754371518987341, -1.445657931034483 ], [ 29.754102025316456, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17874, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.445657931034483 ], [ 29.754371518987341, -1.445927413793104 ], [ 29.754641012658226, -1.445927413793104 ], [ 29.754641012658226, -1.445657931034483 ], [ 29.754371518987341, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17875, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.445657931034483 ], [ 29.754641012658226, -1.445927413793104 ], [ 29.754910506329114, -1.445927413793104 ], [ 29.754910506329114, -1.445657931034483 ], [ 29.754641012658226, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17876, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.445657931034483 ], [ 29.754910506329114, -1.445927413793104 ], [ 29.75518, -1.445927413793104 ], [ 29.75518, -1.445657931034483 ], [ 29.754910506329114, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17877, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.445657931034483 ], [ 29.75518, -1.445927413793104 ], [ 29.755449493670884, -1.445927413793104 ], [ 29.755449493670884, -1.445657931034483 ], [ 29.75518, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17878, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.445657931034483 ], [ 29.755988481012658, -1.445927413793104 ], [ 29.756257974683542, -1.445927413793104 ], [ 29.756257974683542, -1.445657931034483 ], [ 29.755988481012658, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17879, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.445657931034483 ], [ 29.756796962025316, -1.445927413793104 ], [ 29.757066455696201, -1.445927413793104 ], [ 29.757066455696201, -1.445657931034483 ], [ 29.756796962025316, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17880, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.445657931034483 ], [ 29.757066455696201, -1.445927413793104 ], [ 29.757335949367089, -1.445927413793104 ], [ 29.757335949367089, -1.445657931034483 ], [ 29.757066455696201, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17881, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.445657931034483 ], [ 29.757605443037974, -1.445927413793104 ], [ 29.757874936708859, -1.445927413793104 ], [ 29.757874936708859, -1.445657931034483 ], [ 29.757605443037974, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17882, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.445657931034483 ], [ 29.757874936708859, -1.445927413793104 ], [ 29.758144430379748, -1.445927413793104 ], [ 29.758144430379748, -1.445657931034483 ], [ 29.757874936708859, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17883, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.445657931034483 ], [ 29.758144430379748, -1.445927413793104 ], [ 29.758413924050632, -1.445927413793104 ], [ 29.758413924050632, -1.445657931034483 ], [ 29.758144430379748, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17884, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.445657931034483 ], [ 29.758413924050632, -1.445927413793104 ], [ 29.758683417721517, -1.445927413793104 ], [ 29.758683417721517, -1.445657931034483 ], [ 29.758413924050632, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17885, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.445657931034483 ], [ 29.758683417721517, -1.445927413793104 ], [ 29.758952911392406, -1.445927413793104 ], [ 29.758952911392406, -1.445657931034483 ], [ 29.758683417721517, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17886, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.445657931034483 ], [ 29.758952911392406, -1.445927413793104 ], [ 29.759222405063291, -1.445927413793104 ], [ 29.759222405063291, -1.445657931034483 ], [ 29.758952911392406, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17887, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.445657931034483 ], [ 29.759222405063291, -1.445927413793104 ], [ 29.759491898734176, -1.445927413793104 ], [ 29.759491898734176, -1.445657931034483 ], [ 29.759222405063291, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17888, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.445657931034483 ], [ 29.759491898734176, -1.445927413793104 ], [ 29.759761392405064, -1.445927413793104 ], [ 29.759761392405064, -1.445657931034483 ], [ 29.759491898734176, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17889, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.445657931034483 ], [ 29.759761392405064, -1.445927413793104 ], [ 29.760030886075949, -1.445927413793104 ], [ 29.760030886075949, -1.445657931034483 ], [ 29.759761392405064, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17890, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.445657931034483 ], [ 29.760030886075949, -1.445927413793104 ], [ 29.760300379746834, -1.445927413793104 ], [ 29.760300379746834, -1.445657931034483 ], [ 29.760030886075949, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17891, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.445657931034483 ], [ 29.760300379746834, -1.445927413793104 ], [ 29.760569873417722, -1.445927413793104 ], [ 29.760569873417722, -1.445657931034483 ], [ 29.760300379746834, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17892, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.445657931034483 ], [ 29.760569873417722, -1.445927413793104 ], [ 29.760839367088607, -1.445927413793104 ], [ 29.760839367088607, -1.445657931034483 ], [ 29.760569873417722, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17893, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.445657931034483 ], [ 29.760839367088607, -1.445927413793104 ], [ 29.761108860759492, -1.445927413793104 ], [ 29.761108860759492, -1.445657931034483 ], [ 29.760839367088607, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17894, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.445657931034483 ], [ 29.761108860759492, -1.445927413793104 ], [ 29.761378354430381, -1.445927413793104 ], [ 29.761378354430381, -1.445657931034483 ], [ 29.761108860759492, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17895, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.445657931034483 ], [ 29.761378354430381, -1.445927413793104 ], [ 29.761647848101266, -1.445927413793104 ], [ 29.761647848101266, -1.445657931034483 ], [ 29.761378354430381, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17896, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.445657931034483 ], [ 29.761647848101266, -1.445927413793104 ], [ 29.761917341772151, -1.445927413793104 ], [ 29.761917341772151, -1.445657931034483 ], [ 29.761647848101266, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17897, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.445657931034483 ], [ 29.761917341772151, -1.445927413793104 ], [ 29.762456329113924, -1.445927413793104 ], [ 29.762456329113924, -1.445657931034483 ], [ 29.761917341772151, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17898, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.445657931034483 ], [ 29.762456329113924, -1.445927413793104 ], [ 29.762725822784809, -1.445927413793104 ], [ 29.762725822784809, -1.445657931034483 ], [ 29.762456329113924, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17899, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.445657931034483 ], [ 29.762725822784809, -1.445927413793104 ], [ 29.763264810126582, -1.445927413793104 ], [ 29.763264810126582, -1.445657931034483 ], [ 29.762725822784809, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17900, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.445657931034483 ], [ 29.763264810126582, -1.445927413793104 ], [ 29.763534303797467, -1.445927413793104 ], [ 29.763534303797467, -1.445657931034483 ], [ 29.763264810126582, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17901, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.445657931034483 ], [ 29.763534303797467, -1.445927413793104 ], [ 29.763803797468356, -1.445927413793104 ], [ 29.763803797468356, -1.445657931034483 ], [ 29.763534303797467, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17902, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.445657931034483 ], [ 29.763803797468356, -1.445927413793104 ], [ 29.76407329113924, -1.445927413793104 ], [ 29.76407329113924, -1.445657931034483 ], [ 29.763803797468356, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17903, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.445657931034483 ], [ 29.76407329113924, -1.445927413793104 ], [ 29.764342784810125, -1.445927413793104 ], [ 29.764342784810125, -1.445657931034483 ], [ 29.76407329113924, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17904, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.445657931034483 ], [ 29.764342784810125, -1.445927413793104 ], [ 29.764612278481014, -1.445927413793104 ], [ 29.764612278481014, -1.445657931034483 ], [ 29.764342784810125, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17905, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.445657931034483 ], [ 29.764612278481014, -1.445927413793104 ], [ 29.764881772151899, -1.445927413793104 ], [ 29.764881772151899, -1.445657931034483 ], [ 29.764612278481014, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17906, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.445657931034483 ], [ 29.764881772151899, -1.445927413793104 ], [ 29.765151265822784, -1.445927413793104 ], [ 29.765151265822784, -1.445657931034483 ], [ 29.764881772151899, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17907, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.445657931034483 ], [ 29.765151265822784, -1.445927413793104 ], [ 29.768115696202532, -1.445927413793104 ], [ 29.768115696202532, -1.445657931034483 ], [ 29.765151265822784, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17908, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.445657931034483 ], [ 29.768115696202532, -1.445927413793104 ], [ 29.768385189873417, -1.445927413793104 ], [ 29.768385189873417, -1.445657931034483 ], [ 29.768115696202532, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17909, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.445657931034483 ], [ 29.768385189873417, -1.445927413793104 ], [ 29.768654683544302, -1.445927413793104 ], [ 29.768654683544302, -1.445657931034483 ], [ 29.768385189873417, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17910, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.445657931034483 ], [ 29.768654683544302, -1.445927413793104 ], [ 29.76892417721519, -1.445927413793104 ], [ 29.76892417721519, -1.445657931034483 ], [ 29.768654683544302, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17911, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.445657931034483 ], [ 29.76892417721519, -1.445927413793104 ], [ 29.769193670886075, -1.445927413793104 ], [ 29.769193670886075, -1.445657931034483 ], [ 29.76892417721519, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17912, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.445657931034483 ], [ 29.769193670886075, -1.445927413793104 ], [ 29.76946316455696, -1.445927413793104 ], [ 29.76946316455696, -1.445657931034483 ], [ 29.769193670886075, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17913, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.445657931034483 ], [ 29.76946316455696, -1.445927413793104 ], [ 29.769732658227849, -1.445927413793104 ], [ 29.769732658227849, -1.445657931034483 ], [ 29.76946316455696, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17914, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.445657931034483 ], [ 29.769732658227849, -1.445927413793104 ], [ 29.770002151898733, -1.445927413793104 ], [ 29.770002151898733, -1.445657931034483 ], [ 29.769732658227849, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17915, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.445657931034483 ], [ 29.770002151898733, -1.445927413793104 ], [ 29.770271645569618, -1.445927413793104 ], [ 29.770271645569618, -1.445657931034483 ], [ 29.770002151898733, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17916, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.445657931034483 ], [ 29.770271645569618, -1.445927413793104 ], [ 29.770541139240507, -1.445927413793104 ], [ 29.770541139240507, -1.445657931034483 ], [ 29.770271645569618, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17917, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.445657931034483 ], [ 29.770541139240507, -1.445927413793104 ], [ 29.770810632911392, -1.445927413793104 ], [ 29.770810632911392, -1.445657931034483 ], [ 29.770541139240507, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17918, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.445657931034483 ], [ 29.770810632911392, -1.445927413793104 ], [ 29.771080126582277, -1.445927413793104 ], [ 29.771080126582277, -1.445657931034483 ], [ 29.770810632911392, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17919, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.445657931034483 ], [ 29.771080126582277, -1.445927413793104 ], [ 29.771349620253165, -1.445927413793104 ], [ 29.771349620253165, -1.445657931034483 ], [ 29.771080126582277, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17920, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.445657931034483 ], [ 29.771349620253165, -1.445927413793104 ], [ 29.77161911392405, -1.445927413793104 ], [ 29.77161911392405, -1.445657931034483 ], [ 29.771349620253165, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17921, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.445657931034483 ], [ 29.77161911392405, -1.445927413793104 ], [ 29.771888607594935, -1.445927413793104 ], [ 29.771888607594935, -1.445657931034483 ], [ 29.77161911392405, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17922, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.445657931034483 ], [ 29.771888607594935, -1.445927413793104 ], [ 29.772158101265823, -1.445927413793104 ], [ 29.772158101265823, -1.445657931034483 ], [ 29.771888607594935, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17923, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.445657931034483 ], [ 29.772158101265823, -1.445927413793104 ], [ 29.772427594936708, -1.445927413793104 ], [ 29.772427594936708, -1.445657931034483 ], [ 29.772158101265823, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17924, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.445657931034483 ], [ 29.772427594936708, -1.445927413793104 ], [ 29.772697088607593, -1.445927413793104 ], [ 29.772697088607593, -1.445657931034483 ], [ 29.772427594936708, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17925, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.445657931034483 ], [ 29.772697088607593, -1.445927413793104 ], [ 29.772966582278482, -1.445927413793104 ], [ 29.772966582278482, -1.445657931034483 ], [ 29.772697088607593, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17926, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.445657931034483 ], [ 29.772966582278482, -1.445927413793104 ], [ 29.773236075949367, -1.445927413793104 ], [ 29.773236075949367, -1.445657931034483 ], [ 29.772966582278482, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17927, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.445657931034483 ], [ 29.773236075949367, -1.445927413793104 ], [ 29.773505569620252, -1.445927413793104 ], [ 29.773505569620252, -1.445657931034483 ], [ 29.773236075949367, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17928, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.445657931034483 ], [ 29.773505569620252, -1.445927413793104 ], [ 29.77377506329114, -1.445927413793104 ], [ 29.77377506329114, -1.445657931034483 ], [ 29.773505569620252, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17929, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.445657931034483 ], [ 29.77377506329114, -1.445927413793104 ], [ 29.774044556962025, -1.445927413793104 ], [ 29.774044556962025, -1.445657931034483 ], [ 29.77377506329114, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17930, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.445657931034483 ], [ 29.774044556962025, -1.445927413793104 ], [ 29.77431405063291, -1.445927413793104 ], [ 29.77431405063291, -1.445657931034483 ], [ 29.774044556962025, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17931, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.445657931034483 ], [ 29.77431405063291, -1.445927413793104 ], [ 29.774853037974683, -1.445927413793104 ], [ 29.774853037974683, -1.445657931034483 ], [ 29.77431405063291, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17932, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.445657931034483 ], [ 29.774853037974683, -1.445927413793104 ], [ 29.775122531645568, -1.445927413793104 ], [ 29.775122531645568, -1.445657931034483 ], [ 29.774853037974683, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17933, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.445657931034483 ], [ 29.775122531645568, -1.445927413793104 ], [ 29.775392025316457, -1.445927413793104 ], [ 29.775392025316457, -1.445657931034483 ], [ 29.775122531645568, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17934, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.445657931034483 ], [ 29.775392025316457, -1.445927413793104 ], [ 29.775661518987341, -1.445927413793104 ], [ 29.775661518987341, -1.445657931034483 ], [ 29.775392025316457, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17935, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.445657931034483 ], [ 29.775931012658226, -1.445927413793104 ], [ 29.776200506329115, -1.445927413793104 ], [ 29.776200506329115, -1.445657931034483 ], [ 29.775931012658226, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17936, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.445657931034483 ], [ 29.776200506329115, -1.445927413793104 ], [ 29.77647, -1.445927413793104 ], [ 29.77647, -1.445657931034483 ], [ 29.776200506329115, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17937, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.445657931034483 ], [ 29.77647, -1.445927413793104 ], [ 29.776739493670885, -1.445927413793104 ], [ 29.776739493670885, -1.445657931034483 ], [ 29.77647, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17938, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.445657931034483 ], [ 29.777278481012658, -1.445927413793104 ], [ 29.777547974683543, -1.445927413793104 ], [ 29.777547974683543, -1.445657931034483 ], [ 29.777278481012658, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17939, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.445657931034483 ], [ 29.777547974683543, -1.445927413793104 ], [ 29.777817468354431, -1.445927413793104 ], [ 29.777817468354431, -1.445657931034483 ], [ 29.777547974683543, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17940, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.445657931034483 ], [ 29.777817468354431, -1.445927413793104 ], [ 29.778086962025316, -1.445927413793104 ], [ 29.778086962025316, -1.445657931034483 ], [ 29.777817468354431, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17941, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.445657931034483 ], [ 29.778356455696201, -1.445927413793104 ], [ 29.77862594936709, -1.445927413793104 ], [ 29.77862594936709, -1.445657931034483 ], [ 29.778356455696201, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17942, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.445657931034483 ], [ 29.77862594936709, -1.445927413793104 ], [ 29.778895443037975, -1.445927413793104 ], [ 29.778895443037975, -1.445657931034483 ], [ 29.77862594936709, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17943, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.445657931034483 ], [ 29.778895443037975, -1.445927413793104 ], [ 29.77916493670886, -1.445927413793104 ], [ 29.77916493670886, -1.445657931034483 ], [ 29.778895443037975, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17944, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.445657931034483 ], [ 29.77916493670886, -1.445927413793104 ], [ 29.779434430379748, -1.445927413793104 ], [ 29.779434430379748, -1.445657931034483 ], [ 29.77916493670886, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17945, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.445657931034483 ], [ 29.779434430379748, -1.445927413793104 ], [ 29.779703924050633, -1.445927413793104 ], [ 29.779703924050633, -1.445657931034483 ], [ 29.779434430379748, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17946, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.445657931034483 ], [ 29.779703924050633, -1.445927413793104 ], [ 29.779973417721518, -1.445927413793104 ], [ 29.779973417721518, -1.445657931034483 ], [ 29.779703924050633, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17947, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.445657931034483 ], [ 29.779973417721518, -1.445927413793104 ], [ 29.780242911392406, -1.445927413793104 ], [ 29.780242911392406, -1.445657931034483 ], [ 29.779973417721518, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17948, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.445657931034483 ], [ 29.780242911392406, -1.445927413793104 ], [ 29.780512405063291, -1.445927413793104 ], [ 29.780512405063291, -1.445657931034483 ], [ 29.780242911392406, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17949, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.445657931034483 ], [ 29.780512405063291, -1.445927413793104 ], [ 29.780781898734176, -1.445927413793104 ], [ 29.780781898734176, -1.445657931034483 ], [ 29.780512405063291, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17950, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.445657931034483 ], [ 29.780781898734176, -1.445927413793104 ], [ 29.781051392405065, -1.445927413793104 ], [ 29.781051392405065, -1.445657931034483 ], [ 29.780781898734176, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17951, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.445657931034483 ], [ 29.781051392405065, -1.445927413793104 ], [ 29.78132088607595, -1.445927413793104 ], [ 29.78132088607595, -1.445657931034483 ], [ 29.781051392405065, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17952, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.445657931034483 ], [ 29.78132088607595, -1.445927413793104 ], [ 29.781590379746834, -1.445927413793104 ], [ 29.781590379746834, -1.445657931034483 ], [ 29.78132088607595, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17953, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.445657931034483 ], [ 29.781590379746834, -1.445927413793104 ], [ 29.781859873417723, -1.445927413793104 ], [ 29.781859873417723, -1.445657931034483 ], [ 29.781590379746834, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17954, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.445657931034483 ], [ 29.781859873417723, -1.445927413793104 ], [ 29.782129367088608, -1.445927413793104 ], [ 29.782129367088608, -1.445657931034483 ], [ 29.781859873417723, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17955, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.445657931034483 ], [ 29.782129367088608, -1.445927413793104 ], [ 29.782398860759493, -1.445927413793104 ], [ 29.782398860759493, -1.445657931034483 ], [ 29.782129367088608, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17956, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.445657931034483 ], [ 29.782398860759493, -1.445927413793104 ], [ 29.782668354430381, -1.445927413793104 ], [ 29.782668354430381, -1.445657931034483 ], [ 29.782398860759493, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17957, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.445657931034483 ], [ 29.782668354430381, -1.445927413793104 ], [ 29.782937848101266, -1.445927413793104 ], [ 29.782937848101266, -1.445657931034483 ], [ 29.782668354430381, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17958, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.445657931034483 ], [ 29.782937848101266, -1.445927413793104 ], [ 29.783207341772151, -1.445927413793104 ], [ 29.783207341772151, -1.445657931034483 ], [ 29.782937848101266, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17959, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.445657931034483 ], [ 29.783207341772151, -1.445927413793104 ], [ 29.783476835443039, -1.445927413793104 ], [ 29.783476835443039, -1.445657931034483 ], [ 29.783207341772151, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17960, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.445657931034483 ], [ 29.783476835443039, -1.445927413793104 ], [ 29.784015822784809, -1.445927413793104 ], [ 29.784015822784809, -1.445657931034483 ], [ 29.783476835443039, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17961, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.445657931034483 ], [ 29.784015822784809, -1.445927413793104 ], [ 29.784285316455698, -1.445927413793104 ], [ 29.784285316455698, -1.445657931034483 ], [ 29.784015822784809, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17962, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.445657931034483 ], [ 29.784285316455698, -1.445927413793104 ], [ 29.784554810126583, -1.445927413793104 ], [ 29.784554810126583, -1.445657931034483 ], [ 29.784285316455698, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17963, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.445657931034483 ], [ 29.784554810126583, -1.445927413793104 ], [ 29.784824303797468, -1.445927413793104 ], [ 29.784824303797468, -1.445657931034483 ], [ 29.784554810126583, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17964, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.445657931034483 ], [ 29.784824303797468, -1.445927413793104 ], [ 29.785093797468356, -1.445927413793104 ], [ 29.785093797468356, -1.445657931034483 ], [ 29.784824303797468, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17965, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.445657931034483 ], [ 29.785093797468356, -1.445927413793104 ], [ 29.785363291139241, -1.445927413793104 ], [ 29.785363291139241, -1.445657931034483 ], [ 29.785093797468356, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17966, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.445657931034483 ], [ 29.785363291139241, -1.445927413793104 ], [ 29.785632784810126, -1.445927413793104 ], [ 29.785632784810126, -1.445657931034483 ], [ 29.785363291139241, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17967, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.445657931034483 ], [ 29.785632784810126, -1.445927413793104 ], [ 29.785902278481014, -1.445927413793104 ], [ 29.785902278481014, -1.445657931034483 ], [ 29.785632784810126, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17968, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.445657931034483 ], [ 29.785902278481014, -1.445927413793104 ], [ 29.786171772151899, -1.445927413793104 ], [ 29.786171772151899, -1.445657931034483 ], [ 29.785902278481014, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17969, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.445657931034483 ], [ 29.786171772151899, -1.445927413793104 ], [ 29.786441265822784, -1.445927413793104 ], [ 29.786441265822784, -1.445657931034483 ], [ 29.786171772151899, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17970, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.445657931034483 ], [ 29.786441265822784, -1.445927413793104 ], [ 29.786710759493673, -1.445927413793104 ], [ 29.786710759493673, -1.445657931034483 ], [ 29.786441265822784, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17971, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.445657931034483 ], [ 29.786710759493673, -1.445927413793104 ], [ 29.786980253164558, -1.445927413793104 ], [ 29.786980253164558, -1.445657931034483 ], [ 29.786710759493673, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17972, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.445657931034483 ], [ 29.786980253164558, -1.445927413793104 ], [ 29.787249746835442, -1.445927413793104 ], [ 29.787249746835442, -1.445657931034483 ], [ 29.786980253164558, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17973, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.445657931034483 ], [ 29.787249746835442, -1.445927413793104 ], [ 29.787519240506327, -1.445927413793104 ], [ 29.787519240506327, -1.445657931034483 ], [ 29.787249746835442, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17974, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.445657931034483 ], [ 29.787519240506327, -1.445927413793104 ], [ 29.787788734177216, -1.445927413793104 ], [ 29.787788734177216, -1.445657931034483 ], [ 29.787519240506327, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17975, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.445657931034483 ], [ 29.788597215189874, -1.445927413793104 ], [ 29.789405696202532, -1.445927413793104 ], [ 29.789405696202532, -1.445657931034483 ], [ 29.788597215189874, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17976, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.445657931034483 ], [ 29.789405696202532, -1.445927413793104 ], [ 29.789675189873417, -1.445927413793104 ], [ 29.789675189873417, -1.445657931034483 ], [ 29.789405696202532, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17977, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.445657931034483 ], [ 29.789675189873417, -1.445927413793104 ], [ 29.789944683544302, -1.445927413793104 ], [ 29.789944683544302, -1.445657931034483 ], [ 29.789675189873417, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17978, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.445657931034483 ], [ 29.789944683544302, -1.445927413793104 ], [ 29.790214177215191, -1.445927413793104 ], [ 29.790214177215191, -1.445657931034483 ], [ 29.789944683544302, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17979, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.445657931034483 ], [ 29.790214177215191, -1.445927413793104 ], [ 29.790483670886076, -1.445927413793104 ], [ 29.790483670886076, -1.445657931034483 ], [ 29.790214177215191, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17980, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.445657931034483 ], [ 29.790483670886076, -1.445927413793104 ], [ 29.790753164556961, -1.445927413793104 ], [ 29.790753164556961, -1.445657931034483 ], [ 29.790483670886076, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17981, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.445657931034483 ], [ 29.790753164556961, -1.445927413793104 ], [ 29.791022658227849, -1.445927413793104 ], [ 29.791022658227849, -1.445657931034483 ], [ 29.790753164556961, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17982, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.445657931034483 ], [ 29.791022658227849, -1.445927413793104 ], [ 29.791292151898734, -1.445927413793104 ], [ 29.791292151898734, -1.445657931034483 ], [ 29.791022658227849, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17983, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.445657931034483 ], [ 29.791292151898734, -1.445927413793104 ], [ 29.791561645569619, -1.445927413793104 ], [ 29.791561645569619, -1.445657931034483 ], [ 29.791292151898734, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17984, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.445657931034483 ], [ 29.791831139240507, -1.445927413793104 ], [ 29.792100632911392, -1.445927413793104 ], [ 29.792100632911392, -1.445657931034483 ], [ 29.791831139240507, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17985, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.445657931034483 ], [ 29.792100632911392, -1.445927413793104 ], [ 29.792370126582277, -1.445927413793104 ], [ 29.792370126582277, -1.445657931034483 ], [ 29.792100632911392, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17986, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.445657931034483 ], [ 29.792370126582277, -1.445927413793104 ], [ 29.792909113924051, -1.445927413793104 ], [ 29.792909113924051, -1.445657931034483 ], [ 29.792370126582277, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17987, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.445657931034483 ], [ 29.792909113924051, -1.445927413793104 ], [ 29.793178607594935, -1.445927413793104 ], [ 29.793178607594935, -1.445657931034483 ], [ 29.792909113924051, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17988, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.445657931034483 ], [ 29.793178607594935, -1.445927413793104 ], [ 29.793448101265824, -1.445927413793104 ], [ 29.793448101265824, -1.445657931034483 ], [ 29.793178607594935, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17989, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.445657931034483 ], [ 29.793448101265824, -1.445927413793104 ], [ 29.793717594936709, -1.445927413793104 ], [ 29.793717594936709, -1.445657931034483 ], [ 29.793448101265824, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17990, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.445657931034483 ], [ 29.804497341772151, -1.445927413793104 ], [ 29.80476683544304, -1.445927413793104 ], [ 29.80476683544304, -1.445657931034483 ], [ 29.804497341772151, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17991, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.445657931034483 ], [ 29.80476683544304, -1.445927413793104 ], [ 29.805036329113925, -1.445927413793104 ], [ 29.805036329113925, -1.445657931034483 ], [ 29.80476683544304, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17992, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.445657931034483 ], [ 29.805036329113925, -1.445927413793104 ], [ 29.80530582278481, -1.445927413793104 ], [ 29.80530582278481, -1.445657931034483 ], [ 29.805036329113925, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17993, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.445657931034483 ], [ 29.80530582278481, -1.445927413793104 ], [ 29.805575316455698, -1.445927413793104 ], [ 29.805575316455698, -1.445657931034483 ], [ 29.80530582278481, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17994, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.445657931034483 ], [ 29.805575316455698, -1.445927413793104 ], [ 29.805844810126583, -1.445927413793104 ], [ 29.805844810126583, -1.445657931034483 ], [ 29.805575316455698, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17995, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.445657931034483 ], [ 29.805844810126583, -1.445927413793104 ], [ 29.806114303797468, -1.445927413793104 ], [ 29.806114303797468, -1.445657931034483 ], [ 29.805844810126583, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17996, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.445657931034483 ], [ 29.806114303797468, -1.445927413793104 ], [ 29.806383797468357, -1.445927413793104 ], [ 29.806383797468357, -1.445657931034483 ], [ 29.806114303797468, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17997, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.444041034482759 ], [ 29.794256582278482, -1.446735862068966 ], [ 29.794526075949367, -1.446735862068966 ], [ 29.794526075949367, -1.444041034482759 ], [ 29.794256582278482, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17998, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.444041034482759 ], [ 29.79506506329114, -1.446735862068966 ], [ 29.795334556962025, -1.446735862068966 ], [ 29.795334556962025, -1.444041034482759 ], [ 29.79506506329114, -1.444041034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 17999, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.444310517241379 ], [ 29.793987088607594, -1.446466379310345 ], [ 29.794256582278482, -1.446466379310345 ], [ 29.794256582278482, -1.444310517241379 ], [ 29.793987088607594, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18000, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.444310517241379 ], [ 29.794795569620252, -1.446466379310345 ], [ 29.79506506329114, -1.446466379310345 ], [ 29.79506506329114, -1.444310517241379 ], [ 29.794795569620252, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18001, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.444310517241379 ], [ 29.795334556962025, -1.446466379310345 ], [ 29.79560405063291, -1.446466379310345 ], [ 29.79560405063291, -1.444310517241379 ], [ 29.795334556962025, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18002, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.444310517241379 ], [ 29.79560405063291, -1.446466379310345 ], [ 29.795873544303799, -1.446466379310345 ], [ 29.795873544303799, -1.444310517241379 ], [ 29.79560405063291, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18003, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.444310517241379 ], [ 29.796143037974684, -1.446466379310345 ], [ 29.796412531645569, -1.446466379310345 ], [ 29.796412531645569, -1.444310517241379 ], [ 29.796143037974684, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18004, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.444310517241379 ], [ 29.796412531645569, -1.446466379310345 ], [ 29.796682025316457, -1.446466379310345 ], [ 29.796682025316457, -1.444310517241379 ], [ 29.796412531645569, -1.444310517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18005, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.444849482758621 ], [ 29.794526075949367, -1.446196896551724 ], [ 29.794795569620252, -1.446196896551724 ], [ 29.794795569620252, -1.444849482758621 ], [ 29.794526075949367, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18006, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.444849482758621 ], [ 29.795873544303799, -1.446196896551724 ], [ 29.796143037974684, -1.446196896551724 ], [ 29.796143037974684, -1.444849482758621 ], [ 29.795873544303799, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18007, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.444849482758621 ], [ 29.796682025316457, -1.446196896551724 ], [ 29.796951518987342, -1.446196896551724 ], [ 29.796951518987342, -1.444849482758621 ], [ 29.796682025316457, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18008, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.444849482758621 ], [ 29.796951518987342, -1.446196896551724 ], [ 29.797221012658227, -1.446196896551724 ], [ 29.797221012658227, -1.444849482758621 ], [ 29.796951518987342, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18009, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.444849482758621 ], [ 29.797221012658227, -1.446196896551724 ], [ 29.797490506329115, -1.446196896551724 ], [ 29.797490506329115, -1.444849482758621 ], [ 29.797221012658227, -1.444849482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18010, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.445118965517241 ], [ 29.807731265822785, -1.447274827586207 ], [ 29.808000759493673, -1.447274827586207 ], [ 29.808000759493673, -1.445118965517241 ], [ 29.807731265822785, -1.445118965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18011, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.445388448275862 ], [ 29.776739493670885, -1.445927413793104 ], [ 29.777008987341773, -1.445927413793104 ], [ 29.777008987341773, -1.446196896551724 ], [ 29.777278481012658, -1.446196896551724 ], [ 29.777278481012658, -1.445657931034483 ], [ 29.777008987341773, -1.445657931034483 ], [ 29.777008987341773, -1.445388448275862 ], [ 29.776739493670885, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18012, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.445388448275862 ], [ 29.802341392405065, -1.446466379310345 ], [ 29.80261088607595, -1.446466379310345 ], [ 29.80261088607595, -1.445388448275862 ], [ 29.802341392405065, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18013, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.445388448275862 ], [ 29.80261088607595, -1.446466379310345 ], [ 29.802880379746835, -1.446466379310345 ], [ 29.802880379746835, -1.445388448275862 ], [ 29.80261088607595, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18014, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.445388448275862 ], [ 29.803149873417723, -1.446196896551724 ], [ 29.803419367088608, -1.446196896551724 ], [ 29.803419367088608, -1.445388448275862 ], [ 29.803149873417723, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18015, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.445388448275862 ], [ 29.803419367088608, -1.446196896551724 ], [ 29.803688860759493, -1.446196896551724 ], [ 29.803688860759493, -1.445388448275862 ], [ 29.803419367088608, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18016, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.445388448275862 ], [ 29.807192278481015, -1.447005344827586 ], [ 29.8074617721519, -1.447005344827586 ], [ 29.8074617721519, -1.445388448275862 ], [ 29.807192278481015, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18017, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.445388448275862 ], [ 29.8074617721519, -1.447005344827586 ], [ 29.807731265822785, -1.447005344827586 ], [ 29.807731265822785, -1.445388448275862 ], [ 29.8074617721519, -1.445388448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18018, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747364683544301, -1.445657931034483 ], [ 29.747364683544301, -1.446735862068966 ], [ 29.74817316455696, -1.446735862068966 ], [ 29.74817316455696, -1.446466379310345 ], [ 29.747903670886075, -1.446466379310345 ], [ 29.747903670886075, -1.445657931034483 ], [ 29.747364683544301, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18019, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.747903670886075, -1.445657931034483 ], [ 29.747903670886075, -1.446466379310345 ], [ 29.74817316455696, -1.446466379310345 ], [ 29.74817316455696, -1.445657931034483 ], [ 29.747903670886075, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18020, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.445657931034483 ], [ 29.74817316455696, -1.446196896551724 ], [ 29.748442658227848, -1.446196896551724 ], [ 29.748442658227848, -1.445657931034483 ], [ 29.74817316455696, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18021, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.445657931034483 ], [ 29.755718987341773, -1.446196896551724 ], [ 29.756257974683542, -1.446196896551724 ], [ 29.756257974683542, -1.445927413793104 ], [ 29.755988481012658, -1.445927413793104 ], [ 29.755988481012658, -1.445657931034483 ], [ 29.755718987341773, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18022, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.445657931034483 ], [ 29.756257974683542, -1.446196896551724 ], [ 29.756796962025316, -1.446196896551724 ], [ 29.756796962025316, -1.445657931034483 ], [ 29.756257974683542, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18023, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.445657931034483 ], [ 29.775661518987341, -1.446196896551724 ], [ 29.775931012658226, -1.446196896551724 ], [ 29.775931012658226, -1.445657931034483 ], [ 29.775661518987341, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18024, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.445657931034483 ], [ 29.787788734177216, -1.446196896551724 ], [ 29.788058227848101, -1.446196896551724 ], [ 29.788058227848101, -1.445927413793104 ], [ 29.788327721518986, -1.445927413793104 ], [ 29.788327721518986, -1.445657931034483 ], [ 29.787788734177216, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18025, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.445657931034483 ], [ 29.801263417721518, -1.447005344827586 ], [ 29.801532911392407, -1.447005344827586 ], [ 29.801532911392407, -1.445657931034483 ], [ 29.801263417721518, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18026, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.445657931034483 ], [ 29.801532911392407, -1.446735862068966 ], [ 29.802071898734177, -1.446735862068966 ], [ 29.802071898734177, -1.445657931034483 ], [ 29.801532911392407, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18027, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.445657931034483 ], [ 29.802071898734177, -1.446735862068966 ], [ 29.802341392405065, -1.446735862068966 ], [ 29.802341392405065, -1.445657931034483 ], [ 29.802071898734177, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18028, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.445657931034483 ], [ 29.802880379746835, -1.446466379310345 ], [ 29.803149873417723, -1.446466379310345 ], [ 29.803149873417723, -1.445657931034483 ], [ 29.802880379746835, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18029, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.445657931034483 ], [ 29.804227848101267, -1.446196896551724 ], [ 29.804497341772151, -1.446196896551724 ], [ 29.804497341772151, -1.445657931034483 ], [ 29.804227848101267, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18030, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.445657931034483 ], [ 29.806383797468357, -1.446466379310345 ], [ 29.806653291139241, -1.446466379310345 ], [ 29.806653291139241, -1.445657931034483 ], [ 29.806383797468357, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18031, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.445657931034483 ], [ 29.806653291139241, -1.446735862068966 ], [ 29.806922784810126, -1.446735862068966 ], [ 29.806922784810126, -1.445657931034483 ], [ 29.806653291139241, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18032, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.445657931034483 ], [ 29.806922784810126, -1.446735862068966 ], [ 29.807192278481015, -1.446735862068966 ], [ 29.807192278481015, -1.445657931034483 ], [ 29.806922784810126, -1.445657931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18033, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.445927413793104 ], [ 29.748442658227848, -1.446196896551724 ], [ 29.748712151898733, -1.446196896551724 ], [ 29.748712151898733, -1.445927413793104 ], [ 29.748442658227848, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18034, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.445927413793104 ], [ 29.748712151898733, -1.446196896551724 ], [ 29.748981645569618, -1.446196896551724 ], [ 29.748981645569618, -1.445927413793104 ], [ 29.748712151898733, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18035, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.445927413793104 ], [ 29.748981645569618, -1.446196896551724 ], [ 29.749251139240506, -1.446196896551724 ], [ 29.749251139240506, -1.445927413793104 ], [ 29.748981645569618, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18036, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.445927413793104 ], [ 29.749251139240506, -1.446196896551724 ], [ 29.749520632911391, -1.446196896551724 ], [ 29.749520632911391, -1.445927413793104 ], [ 29.749251139240506, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18037, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.445927413793104 ], [ 29.749520632911391, -1.446196896551724 ], [ 29.749790126582276, -1.446196896551724 ], [ 29.749790126582276, -1.445927413793104 ], [ 29.749520632911391, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18038, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.445927413793104 ], [ 29.749790126582276, -1.446196896551724 ], [ 29.750059620253165, -1.446196896551724 ], [ 29.750059620253165, -1.445927413793104 ], [ 29.749790126582276, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18039, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.445927413793104 ], [ 29.750059620253165, -1.446196896551724 ], [ 29.75032911392405, -1.446196896551724 ], [ 29.75032911392405, -1.445927413793104 ], [ 29.750059620253165, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18040, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.445927413793104 ], [ 29.75032911392405, -1.446196896551724 ], [ 29.750598607594934, -1.446196896551724 ], [ 29.750598607594934, -1.445927413793104 ], [ 29.75032911392405, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18041, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.445927413793104 ], [ 29.750598607594934, -1.446196896551724 ], [ 29.750868101265823, -1.446196896551724 ], [ 29.750868101265823, -1.445927413793104 ], [ 29.750598607594934, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18042, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.445927413793104 ], [ 29.750868101265823, -1.446196896551724 ], [ 29.751137594936708, -1.446196896551724 ], [ 29.751137594936708, -1.445927413793104 ], [ 29.750868101265823, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18043, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.445927413793104 ], [ 29.751137594936708, -1.446196896551724 ], [ 29.751407088607593, -1.446196896551724 ], [ 29.751407088607593, -1.445927413793104 ], [ 29.751137594936708, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18044, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.445927413793104 ], [ 29.751407088607593, -1.446196896551724 ], [ 29.751676582278481, -1.446196896551724 ], [ 29.751676582278481, -1.445927413793104 ], [ 29.751407088607593, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18045, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.445927413793104 ], [ 29.751676582278481, -1.446196896551724 ], [ 29.751946075949366, -1.446196896551724 ], [ 29.751946075949366, -1.445927413793104 ], [ 29.751676582278481, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18046, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.445927413793104 ], [ 29.751946075949366, -1.446196896551724 ], [ 29.752215569620251, -1.446196896551724 ], [ 29.752215569620251, -1.445927413793104 ], [ 29.751946075949366, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18047, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.445927413793104 ], [ 29.752215569620251, -1.446196896551724 ], [ 29.75248506329114, -1.446196896551724 ], [ 29.75248506329114, -1.445927413793104 ], [ 29.752215569620251, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18048, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.445927413793104 ], [ 29.75248506329114, -1.446196896551724 ], [ 29.752754556962024, -1.446196896551724 ], [ 29.752754556962024, -1.445927413793104 ], [ 29.75248506329114, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18049, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.445927413793104 ], [ 29.752754556962024, -1.446196896551724 ], [ 29.753024050632909, -1.446196896551724 ], [ 29.753024050632909, -1.445927413793104 ], [ 29.752754556962024, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18050, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.445927413793104 ], [ 29.753024050632909, -1.446196896551724 ], [ 29.753293544303798, -1.446196896551724 ], [ 29.753293544303798, -1.445927413793104 ], [ 29.753024050632909, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18051, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.445927413793104 ], [ 29.753293544303798, -1.446196896551724 ], [ 29.753563037974683, -1.446196896551724 ], [ 29.753563037974683, -1.445927413793104 ], [ 29.753293544303798, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18052, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.445927413793104 ], [ 29.753563037974683, -1.446196896551724 ], [ 29.753832531645568, -1.446196896551724 ], [ 29.753832531645568, -1.445927413793104 ], [ 29.753563037974683, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18053, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.445927413793104 ], [ 29.753832531645568, -1.446196896551724 ], [ 29.754102025316456, -1.446196896551724 ], [ 29.754102025316456, -1.445927413793104 ], [ 29.753832531645568, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18054, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.445927413793104 ], [ 29.754102025316456, -1.446196896551724 ], [ 29.754371518987341, -1.446196896551724 ], [ 29.754371518987341, -1.445927413793104 ], [ 29.754102025316456, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18055, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.445927413793104 ], [ 29.754371518987341, -1.446196896551724 ], [ 29.754641012658226, -1.446196896551724 ], [ 29.754641012658226, -1.445927413793104 ], [ 29.754371518987341, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18056, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.445927413793104 ], [ 29.754641012658226, -1.446196896551724 ], [ 29.754910506329114, -1.446196896551724 ], [ 29.754910506329114, -1.445927413793104 ], [ 29.754641012658226, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18057, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.445927413793104 ], [ 29.754910506329114, -1.446196896551724 ], [ 29.75518, -1.446196896551724 ], [ 29.75518, -1.445927413793104 ], [ 29.754910506329114, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18058, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.445927413793104 ], [ 29.75518, -1.446196896551724 ], [ 29.755449493670884, -1.446196896551724 ], [ 29.755449493670884, -1.445927413793104 ], [ 29.75518, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18059, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.445927413793104 ], [ 29.755449493670884, -1.446196896551724 ], [ 29.755718987341773, -1.446196896551724 ], [ 29.755718987341773, -1.445927413793104 ], [ 29.755449493670884, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18060, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.445927413793104 ], [ 29.756796962025316, -1.446196896551724 ], [ 29.757066455696201, -1.446196896551724 ], [ 29.757066455696201, -1.445927413793104 ], [ 29.756796962025316, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18061, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.445927413793104 ], [ 29.757066455696201, -1.446196896551724 ], [ 29.757335949367089, -1.446196896551724 ], [ 29.757335949367089, -1.445927413793104 ], [ 29.757066455696201, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18062, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.445927413793104 ], [ 29.757335949367089, -1.446196896551724 ], [ 29.757605443037974, -1.446196896551724 ], [ 29.757605443037974, -1.445927413793104 ], [ 29.757335949367089, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18063, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.445927413793104 ], [ 29.757605443037974, -1.446196896551724 ], [ 29.757874936708859, -1.446196896551724 ], [ 29.757874936708859, -1.445927413793104 ], [ 29.757605443037974, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18064, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.445927413793104 ], [ 29.757874936708859, -1.446196896551724 ], [ 29.758144430379748, -1.446196896551724 ], [ 29.758144430379748, -1.445927413793104 ], [ 29.757874936708859, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18065, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.445927413793104 ], [ 29.758144430379748, -1.446196896551724 ], [ 29.758413924050632, -1.446196896551724 ], [ 29.758413924050632, -1.445927413793104 ], [ 29.758144430379748, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18066, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.445927413793104 ], [ 29.758413924050632, -1.446196896551724 ], [ 29.758683417721517, -1.446196896551724 ], [ 29.758683417721517, -1.445927413793104 ], [ 29.758413924050632, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18067, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.445927413793104 ], [ 29.758683417721517, -1.446196896551724 ], [ 29.758952911392406, -1.446196896551724 ], [ 29.758952911392406, -1.445927413793104 ], [ 29.758683417721517, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18068, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.445927413793104 ], [ 29.758952911392406, -1.446196896551724 ], [ 29.759222405063291, -1.446196896551724 ], [ 29.759222405063291, -1.445927413793104 ], [ 29.758952911392406, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18069, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.445927413793104 ], [ 29.759222405063291, -1.446196896551724 ], [ 29.759491898734176, -1.446196896551724 ], [ 29.759491898734176, -1.445927413793104 ], [ 29.759222405063291, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18070, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.445927413793104 ], [ 29.759491898734176, -1.446196896551724 ], [ 29.759761392405064, -1.446196896551724 ], [ 29.759761392405064, -1.445927413793104 ], [ 29.759491898734176, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18071, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.445927413793104 ], [ 29.759761392405064, -1.446196896551724 ], [ 29.760030886075949, -1.446196896551724 ], [ 29.760030886075949, -1.445927413793104 ], [ 29.759761392405064, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18072, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.445927413793104 ], [ 29.760030886075949, -1.446196896551724 ], [ 29.760300379746834, -1.446196896551724 ], [ 29.760300379746834, -1.445927413793104 ], [ 29.760030886075949, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18073, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.445927413793104 ], [ 29.760300379746834, -1.446196896551724 ], [ 29.760569873417722, -1.446196896551724 ], [ 29.760569873417722, -1.445927413793104 ], [ 29.760300379746834, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18074, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.445927413793104 ], [ 29.760569873417722, -1.446196896551724 ], [ 29.760839367088607, -1.446196896551724 ], [ 29.760839367088607, -1.445927413793104 ], [ 29.760569873417722, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18075, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.445927413793104 ], [ 29.760839367088607, -1.446196896551724 ], [ 29.761108860759492, -1.446196896551724 ], [ 29.761108860759492, -1.445927413793104 ], [ 29.760839367088607, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18076, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.445927413793104 ], [ 29.761108860759492, -1.446196896551724 ], [ 29.761378354430381, -1.446196896551724 ], [ 29.761378354430381, -1.445927413793104 ], [ 29.761108860759492, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18077, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.445927413793104 ], [ 29.761378354430381, -1.446196896551724 ], [ 29.761647848101266, -1.446196896551724 ], [ 29.761647848101266, -1.445927413793104 ], [ 29.761378354430381, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18078, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.445927413793104 ], [ 29.761647848101266, -1.446196896551724 ], [ 29.762186835443039, -1.446196896551724 ], [ 29.762186835443039, -1.445927413793104 ], [ 29.761647848101266, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18079, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.445927413793104 ], [ 29.762186835443039, -1.446196896551724 ], [ 29.762995316455697, -1.446196896551724 ], [ 29.762995316455697, -1.445927413793104 ], [ 29.762186835443039, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18080, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.445927413793104 ], [ 29.762995316455697, -1.446196896551724 ], [ 29.763534303797467, -1.446196896551724 ], [ 29.763534303797467, -1.445927413793104 ], [ 29.762995316455697, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18081, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.445927413793104 ], [ 29.763534303797467, -1.446196896551724 ], [ 29.763803797468356, -1.446196896551724 ], [ 29.763803797468356, -1.445927413793104 ], [ 29.763534303797467, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18082, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.445927413793104 ], [ 29.763803797468356, -1.446196896551724 ], [ 29.76407329113924, -1.446196896551724 ], [ 29.76407329113924, -1.445927413793104 ], [ 29.763803797468356, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18083, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.445927413793104 ], [ 29.76407329113924, -1.446196896551724 ], [ 29.764342784810125, -1.446196896551724 ], [ 29.764342784810125, -1.445927413793104 ], [ 29.76407329113924, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18084, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.445927413793104 ], [ 29.764342784810125, -1.446196896551724 ], [ 29.764612278481014, -1.446196896551724 ], [ 29.764612278481014, -1.445927413793104 ], [ 29.764342784810125, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18085, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.445927413793104 ], [ 29.764612278481014, -1.446196896551724 ], [ 29.764881772151899, -1.446196896551724 ], [ 29.764881772151899, -1.445927413793104 ], [ 29.764612278481014, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18086, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.445927413793104 ], [ 29.764881772151899, -1.446196896551724 ], [ 29.765151265822784, -1.446196896551724 ], [ 29.765151265822784, -1.445927413793104 ], [ 29.764881772151899, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18087, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.445927413793104 ], [ 29.765151265822784, -1.446196896551724 ], [ 29.765420759493672, -1.446196896551724 ], [ 29.765420759493672, -1.445927413793104 ], [ 29.765151265822784, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18088, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.445927413793104 ], [ 29.765420759493672, -1.446196896551724 ], [ 29.767846202531643, -1.446196896551724 ], [ 29.767846202531643, -1.445927413793104 ], [ 29.765420759493672, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18089, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.445927413793104 ], [ 29.767846202531643, -1.446196896551724 ], [ 29.768115696202532, -1.446196896551724 ], [ 29.768115696202532, -1.445927413793104 ], [ 29.767846202531643, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18090, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.445927413793104 ], [ 29.768115696202532, -1.446196896551724 ], [ 29.768385189873417, -1.446196896551724 ], [ 29.768385189873417, -1.445927413793104 ], [ 29.768115696202532, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18091, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.445927413793104 ], [ 29.768385189873417, -1.446196896551724 ], [ 29.768654683544302, -1.446196896551724 ], [ 29.768654683544302, -1.445927413793104 ], [ 29.768385189873417, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18092, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.445927413793104 ], [ 29.768654683544302, -1.446196896551724 ], [ 29.76892417721519, -1.446196896551724 ], [ 29.76892417721519, -1.445927413793104 ], [ 29.768654683544302, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18093, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.445927413793104 ], [ 29.76892417721519, -1.446196896551724 ], [ 29.769193670886075, -1.446196896551724 ], [ 29.769193670886075, -1.445927413793104 ], [ 29.76892417721519, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18094, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.445927413793104 ], [ 29.769193670886075, -1.446196896551724 ], [ 29.76946316455696, -1.446196896551724 ], [ 29.76946316455696, -1.445927413793104 ], [ 29.769193670886075, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18095, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.445927413793104 ], [ 29.76946316455696, -1.446196896551724 ], [ 29.769732658227849, -1.446196896551724 ], [ 29.769732658227849, -1.445927413793104 ], [ 29.76946316455696, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18096, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.445927413793104 ], [ 29.769732658227849, -1.446196896551724 ], [ 29.770002151898733, -1.446196896551724 ], [ 29.770002151898733, -1.445927413793104 ], [ 29.769732658227849, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18097, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.445927413793104 ], [ 29.770002151898733, -1.446196896551724 ], [ 29.770271645569618, -1.446196896551724 ], [ 29.770271645569618, -1.445927413793104 ], [ 29.770002151898733, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18098, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.445927413793104 ], [ 29.770271645569618, -1.446196896551724 ], [ 29.770541139240507, -1.446196896551724 ], [ 29.770541139240507, -1.445927413793104 ], [ 29.770271645569618, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18099, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.445927413793104 ], [ 29.770541139240507, -1.446196896551724 ], [ 29.770810632911392, -1.446196896551724 ], [ 29.770810632911392, -1.445927413793104 ], [ 29.770541139240507, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18100, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.445927413793104 ], [ 29.770810632911392, -1.446196896551724 ], [ 29.771080126582277, -1.446196896551724 ], [ 29.771080126582277, -1.445927413793104 ], [ 29.770810632911392, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18101, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.445927413793104 ], [ 29.771080126582277, -1.446196896551724 ], [ 29.771349620253165, -1.446196896551724 ], [ 29.771349620253165, -1.445927413793104 ], [ 29.771080126582277, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18102, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.445927413793104 ], [ 29.771349620253165, -1.446196896551724 ], [ 29.77161911392405, -1.446196896551724 ], [ 29.77161911392405, -1.445927413793104 ], [ 29.771349620253165, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18103, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.445927413793104 ], [ 29.77161911392405, -1.446196896551724 ], [ 29.771888607594935, -1.446196896551724 ], [ 29.771888607594935, -1.445927413793104 ], [ 29.77161911392405, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18104, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.445927413793104 ], [ 29.771888607594935, -1.446196896551724 ], [ 29.772158101265823, -1.446196896551724 ], [ 29.772158101265823, -1.445927413793104 ], [ 29.771888607594935, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18105, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.445927413793104 ], [ 29.772158101265823, -1.446196896551724 ], [ 29.772427594936708, -1.446196896551724 ], [ 29.772427594936708, -1.445927413793104 ], [ 29.772158101265823, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18106, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.445927413793104 ], [ 29.772427594936708, -1.446196896551724 ], [ 29.772697088607593, -1.446196896551724 ], [ 29.772697088607593, -1.445927413793104 ], [ 29.772427594936708, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18107, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.445927413793104 ], [ 29.772697088607593, -1.446196896551724 ], [ 29.772966582278482, -1.446196896551724 ], [ 29.772966582278482, -1.445927413793104 ], [ 29.772697088607593, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18108, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.445927413793104 ], [ 29.772966582278482, -1.446196896551724 ], [ 29.773236075949367, -1.446196896551724 ], [ 29.773236075949367, -1.445927413793104 ], [ 29.772966582278482, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18109, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.445927413793104 ], [ 29.773236075949367, -1.446196896551724 ], [ 29.773505569620252, -1.446196896551724 ], [ 29.773505569620252, -1.445927413793104 ], [ 29.773236075949367, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18110, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.445927413793104 ], [ 29.773505569620252, -1.446196896551724 ], [ 29.77377506329114, -1.446196896551724 ], [ 29.77377506329114, -1.445927413793104 ], [ 29.773505569620252, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18111, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.445927413793104 ], [ 29.77377506329114, -1.446196896551724 ], [ 29.774044556962025, -1.446196896551724 ], [ 29.774044556962025, -1.445927413793104 ], [ 29.77377506329114, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18112, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.445927413793104 ], [ 29.774044556962025, -1.446196896551724 ], [ 29.77431405063291, -1.446196896551724 ], [ 29.77431405063291, -1.445927413793104 ], [ 29.774044556962025, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18113, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.445927413793104 ], [ 29.77431405063291, -1.446466379310345 ], [ 29.774853037974683, -1.446466379310345 ], [ 29.774853037974683, -1.445927413793104 ], [ 29.77431405063291, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18114, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.445927413793104 ], [ 29.774853037974683, -1.446196896551724 ], [ 29.775122531645568, -1.446196896551724 ], [ 29.775122531645568, -1.445927413793104 ], [ 29.774853037974683, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18115, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.445927413793104 ], [ 29.775122531645568, -1.446196896551724 ], [ 29.775392025316457, -1.446196896551724 ], [ 29.775392025316457, -1.445927413793104 ], [ 29.775122531645568, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18116, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.445927413793104 ], [ 29.775392025316457, -1.446196896551724 ], [ 29.775661518987341, -1.446196896551724 ], [ 29.775661518987341, -1.445927413793104 ], [ 29.775392025316457, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18117, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.445927413793104 ], [ 29.775931012658226, -1.446196896551724 ], [ 29.776200506329115, -1.446196896551724 ], [ 29.776200506329115, -1.445927413793104 ], [ 29.775931012658226, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18118, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.445927413793104 ], [ 29.776200506329115, -1.446196896551724 ], [ 29.77647, -1.446196896551724 ], [ 29.77647, -1.445927413793104 ], [ 29.776200506329115, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18119, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.445927413793104 ], [ 29.77647, -1.446466379310345 ], [ 29.776739493670885, -1.446466379310345 ], [ 29.776739493670885, -1.445927413793104 ], [ 29.77647, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18120, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.445927413793104 ], [ 29.776739493670885, -1.446196896551724 ], [ 29.777008987341773, -1.446196896551724 ], [ 29.777008987341773, -1.445927413793104 ], [ 29.776739493670885, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18121, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.445927413793104 ], [ 29.777278481012658, -1.446196896551724 ], [ 29.777547974683543, -1.446196896551724 ], [ 29.777547974683543, -1.445927413793104 ], [ 29.777278481012658, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18122, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.445927413793104 ], [ 29.777547974683543, -1.446196896551724 ], [ 29.777817468354431, -1.446196896551724 ], [ 29.777817468354431, -1.445927413793104 ], [ 29.777547974683543, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18123, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.445927413793104 ], [ 29.777817468354431, -1.446196896551724 ], [ 29.778086962025316, -1.446196896551724 ], [ 29.778086962025316, -1.445927413793104 ], [ 29.777817468354431, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18124, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.445927413793104 ], [ 29.778086962025316, -1.446196896551724 ], [ 29.778356455696201, -1.446196896551724 ], [ 29.778356455696201, -1.445927413793104 ], [ 29.778086962025316, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18125, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.445927413793104 ], [ 29.778356455696201, -1.446196896551724 ], [ 29.77862594936709, -1.446196896551724 ], [ 29.77862594936709, -1.445927413793104 ], [ 29.778356455696201, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18126, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.445927413793104 ], [ 29.77862594936709, -1.446196896551724 ], [ 29.778895443037975, -1.446196896551724 ], [ 29.778895443037975, -1.445927413793104 ], [ 29.77862594936709, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18127, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.445927413793104 ], [ 29.778895443037975, -1.446196896551724 ], [ 29.77916493670886, -1.446196896551724 ], [ 29.77916493670886, -1.445927413793104 ], [ 29.778895443037975, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18128, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.445927413793104 ], [ 29.77916493670886, -1.446196896551724 ], [ 29.779434430379748, -1.446196896551724 ], [ 29.779434430379748, -1.445927413793104 ], [ 29.77916493670886, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18129, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.445927413793104 ], [ 29.779434430379748, -1.446196896551724 ], [ 29.779703924050633, -1.446196896551724 ], [ 29.779703924050633, -1.445927413793104 ], [ 29.779434430379748, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18130, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.445927413793104 ], [ 29.779703924050633, -1.446196896551724 ], [ 29.779973417721518, -1.446196896551724 ], [ 29.779973417721518, -1.445927413793104 ], [ 29.779703924050633, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18131, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.445927413793104 ], [ 29.779973417721518, -1.446196896551724 ], [ 29.780242911392406, -1.446196896551724 ], [ 29.780242911392406, -1.445927413793104 ], [ 29.779973417721518, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18132, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.445927413793104 ], [ 29.780242911392406, -1.446196896551724 ], [ 29.780512405063291, -1.446196896551724 ], [ 29.780512405063291, -1.445927413793104 ], [ 29.780242911392406, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18133, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.445927413793104 ], [ 29.780512405063291, -1.446196896551724 ], [ 29.780781898734176, -1.446196896551724 ], [ 29.780781898734176, -1.445927413793104 ], [ 29.780512405063291, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18134, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.445927413793104 ], [ 29.780781898734176, -1.446196896551724 ], [ 29.781051392405065, -1.446196896551724 ], [ 29.781051392405065, -1.445927413793104 ], [ 29.780781898734176, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18135, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.445927413793104 ], [ 29.781051392405065, -1.446196896551724 ], [ 29.78132088607595, -1.446196896551724 ], [ 29.78132088607595, -1.445927413793104 ], [ 29.781051392405065, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18136, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.445927413793104 ], [ 29.78132088607595, -1.446196896551724 ], [ 29.781590379746834, -1.446196896551724 ], [ 29.781590379746834, -1.445927413793104 ], [ 29.78132088607595, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18137, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.445927413793104 ], [ 29.781590379746834, -1.446196896551724 ], [ 29.781859873417723, -1.446196896551724 ], [ 29.781859873417723, -1.445927413793104 ], [ 29.781590379746834, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18138, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.445927413793104 ], [ 29.781859873417723, -1.446196896551724 ], [ 29.782129367088608, -1.446196896551724 ], [ 29.782129367088608, -1.445927413793104 ], [ 29.781859873417723, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18139, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.445927413793104 ], [ 29.782129367088608, -1.446196896551724 ], [ 29.782398860759493, -1.446196896551724 ], [ 29.782398860759493, -1.445927413793104 ], [ 29.782129367088608, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18140, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.445927413793104 ], [ 29.782398860759493, -1.446196896551724 ], [ 29.782668354430381, -1.446196896551724 ], [ 29.782668354430381, -1.445927413793104 ], [ 29.782398860759493, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18141, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.445927413793104 ], [ 29.782668354430381, -1.446196896551724 ], [ 29.782937848101266, -1.446196896551724 ], [ 29.782937848101266, -1.445927413793104 ], [ 29.782668354430381, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18142, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.445927413793104 ], [ 29.782937848101266, -1.446196896551724 ], [ 29.783207341772151, -1.446196896551724 ], [ 29.783207341772151, -1.445927413793104 ], [ 29.782937848101266, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18143, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.445927413793104 ], [ 29.783207341772151, -1.446196896551724 ], [ 29.783476835443039, -1.446196896551724 ], [ 29.783476835443039, -1.445927413793104 ], [ 29.783207341772151, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18144, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.445927413793104 ], [ 29.783476835443039, -1.446196896551724 ], [ 29.784015822784809, -1.446196896551724 ], [ 29.784015822784809, -1.445927413793104 ], [ 29.783476835443039, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18145, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.445927413793104 ], [ 29.784015822784809, -1.446196896551724 ], [ 29.784285316455698, -1.446196896551724 ], [ 29.784285316455698, -1.445927413793104 ], [ 29.784015822784809, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18146, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.445927413793104 ], [ 29.784285316455698, -1.446196896551724 ], [ 29.784554810126583, -1.446196896551724 ], [ 29.784554810126583, -1.445927413793104 ], [ 29.784285316455698, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18147, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.445927413793104 ], [ 29.784554810126583, -1.446196896551724 ], [ 29.784824303797468, -1.446196896551724 ], [ 29.784824303797468, -1.445927413793104 ], [ 29.784554810126583, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18148, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.445927413793104 ], [ 29.784824303797468, -1.446196896551724 ], [ 29.785093797468356, -1.446196896551724 ], [ 29.785093797468356, -1.445927413793104 ], [ 29.784824303797468, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18149, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.445927413793104 ], [ 29.785093797468356, -1.446196896551724 ], [ 29.785363291139241, -1.446196896551724 ], [ 29.785363291139241, -1.445927413793104 ], [ 29.785093797468356, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18150, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.445927413793104 ], [ 29.785363291139241, -1.446196896551724 ], [ 29.785632784810126, -1.446196896551724 ], [ 29.785632784810126, -1.445927413793104 ], [ 29.785363291139241, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18151, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.445927413793104 ], [ 29.785632784810126, -1.446196896551724 ], [ 29.785902278481014, -1.446196896551724 ], [ 29.785902278481014, -1.445927413793104 ], [ 29.785632784810126, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18152, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.445927413793104 ], [ 29.785902278481014, -1.446196896551724 ], [ 29.786171772151899, -1.446196896551724 ], [ 29.786171772151899, -1.445927413793104 ], [ 29.785902278481014, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18153, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.445927413793104 ], [ 29.786171772151899, -1.446196896551724 ], [ 29.786441265822784, -1.446196896551724 ], [ 29.786441265822784, -1.445927413793104 ], [ 29.786171772151899, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18154, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.445927413793104 ], [ 29.786441265822784, -1.446196896551724 ], [ 29.786710759493673, -1.446196896551724 ], [ 29.786710759493673, -1.445927413793104 ], [ 29.786441265822784, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18155, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.445927413793104 ], [ 29.786710759493673, -1.446196896551724 ], [ 29.786980253164558, -1.446196896551724 ], [ 29.786980253164558, -1.445927413793104 ], [ 29.786710759493673, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18156, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.445927413793104 ], [ 29.786980253164558, -1.446466379310345 ], [ 29.787249746835442, -1.446466379310345 ], [ 29.787249746835442, -1.445927413793104 ], [ 29.786980253164558, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18157, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.445927413793104 ], [ 29.787249746835442, -1.446196896551724 ], [ 29.787519240506327, -1.446196896551724 ], [ 29.787519240506327, -1.445927413793104 ], [ 29.787249746835442, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18158, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.445927413793104 ], [ 29.787519240506327, -1.446196896551724 ], [ 29.787788734177216, -1.446196896551724 ], [ 29.787788734177216, -1.445927413793104 ], [ 29.787519240506327, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18159, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.445927413793104 ], [ 29.788058227848101, -1.446196896551724 ], [ 29.788327721518986, -1.446196896551724 ], [ 29.788327721518986, -1.445927413793104 ], [ 29.788058227848101, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18160, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.445927413793104 ], [ 29.788327721518986, -1.446196896551724 ], [ 29.788597215189874, -1.446196896551724 ], [ 29.788597215189874, -1.445927413793104 ], [ 29.788327721518986, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18161, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.445927413793104 ], [ 29.788597215189874, -1.446196896551724 ], [ 29.789405696202532, -1.446196896551724 ], [ 29.789405696202532, -1.445927413793104 ], [ 29.788597215189874, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18162, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.445927413793104 ], [ 29.789405696202532, -1.446196896551724 ], [ 29.789675189873417, -1.446196896551724 ], [ 29.789675189873417, -1.445927413793104 ], [ 29.789405696202532, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18163, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.445927413793104 ], [ 29.789675189873417, -1.446196896551724 ], [ 29.789944683544302, -1.446196896551724 ], [ 29.789944683544302, -1.445927413793104 ], [ 29.789675189873417, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18164, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.445927413793104 ], [ 29.789944683544302, -1.446196896551724 ], [ 29.790214177215191, -1.446196896551724 ], [ 29.790214177215191, -1.445927413793104 ], [ 29.789944683544302, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18165, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.445927413793104 ], [ 29.790214177215191, -1.446196896551724 ], [ 29.790483670886076, -1.446196896551724 ], [ 29.790483670886076, -1.445927413793104 ], [ 29.790214177215191, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18166, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.445927413793104 ], [ 29.790483670886076, -1.446196896551724 ], [ 29.790753164556961, -1.446196896551724 ], [ 29.790753164556961, -1.445927413793104 ], [ 29.790483670886076, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18167, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.445927413793104 ], [ 29.790753164556961, -1.446196896551724 ], [ 29.791022658227849, -1.446196896551724 ], [ 29.791022658227849, -1.445927413793104 ], [ 29.790753164556961, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18168, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.445927413793104 ], [ 29.791022658227849, -1.446196896551724 ], [ 29.791292151898734, -1.446196896551724 ], [ 29.791292151898734, -1.445927413793104 ], [ 29.791022658227849, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18169, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.445927413793104 ], [ 29.791292151898734, -1.446196896551724 ], [ 29.791561645569619, -1.446196896551724 ], [ 29.791561645569619, -1.445927413793104 ], [ 29.791292151898734, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18170, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.445927413793104 ], [ 29.791561645569619, -1.446196896551724 ], [ 29.791831139240507, -1.446196896551724 ], [ 29.791831139240507, -1.445927413793104 ], [ 29.791561645569619, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18171, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.445927413793104 ], [ 29.791831139240507, -1.446196896551724 ], [ 29.792100632911392, -1.446196896551724 ], [ 29.792100632911392, -1.445927413793104 ], [ 29.791831139240507, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18172, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.445927413793104 ], [ 29.792100632911392, -1.446196896551724 ], [ 29.792370126582277, -1.446196896551724 ], [ 29.792370126582277, -1.445927413793104 ], [ 29.792100632911392, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18173, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.445927413793104 ], [ 29.792370126582277, -1.446196896551724 ], [ 29.792909113924051, -1.446196896551724 ], [ 29.792909113924051, -1.445927413793104 ], [ 29.792370126582277, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18174, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.445927413793104 ], [ 29.792909113924051, -1.446196896551724 ], [ 29.793178607594935, -1.446196896551724 ], [ 29.793178607594935, -1.445927413793104 ], [ 29.792909113924051, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18175, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.445927413793104 ], [ 29.793178607594935, -1.446196896551724 ], [ 29.793448101265824, -1.446196896551724 ], [ 29.793448101265824, -1.445927413793104 ], [ 29.793178607594935, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18176, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.445927413793104 ], [ 29.793448101265824, -1.446196896551724 ], [ 29.793717594936709, -1.446196896551724 ], [ 29.793717594936709, -1.445927413793104 ], [ 29.793448101265824, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18177, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.445927413793104 ], [ 29.793717594936709, -1.446196896551724 ], [ 29.793987088607594, -1.446196896551724 ], [ 29.793987088607594, -1.445927413793104 ], [ 29.793717594936709, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18178, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.445927413793104 ], [ 29.803688860759493, -1.446196896551724 ], [ 29.803958354430382, -1.446196896551724 ], [ 29.803958354430382, -1.445927413793104 ], [ 29.803688860759493, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18179, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.445927413793104 ], [ 29.803958354430382, -1.446196896551724 ], [ 29.804227848101267, -1.446196896551724 ], [ 29.804227848101267, -1.445927413793104 ], [ 29.803958354430382, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18180, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.445927413793104 ], [ 29.804497341772151, -1.446196896551724 ], [ 29.80476683544304, -1.446196896551724 ], [ 29.80476683544304, -1.445927413793104 ], [ 29.804497341772151, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18181, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.445927413793104 ], [ 29.80476683544304, -1.446196896551724 ], [ 29.805036329113925, -1.446196896551724 ], [ 29.805036329113925, -1.445927413793104 ], [ 29.80476683544304, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18182, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.445927413793104 ], [ 29.805036329113925, -1.446196896551724 ], [ 29.80530582278481, -1.446196896551724 ], [ 29.80530582278481, -1.445927413793104 ], [ 29.805036329113925, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18183, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.445927413793104 ], [ 29.80530582278481, -1.446196896551724 ], [ 29.805575316455698, -1.446196896551724 ], [ 29.805575316455698, -1.445927413793104 ], [ 29.80530582278481, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18184, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.445927413793104 ], [ 29.805575316455698, -1.446196896551724 ], [ 29.805844810126583, -1.446196896551724 ], [ 29.805844810126583, -1.445927413793104 ], [ 29.805575316455698, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18185, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.445927413793104 ], [ 29.805844810126583, -1.446466379310345 ], [ 29.806114303797468, -1.446466379310345 ], [ 29.806114303797468, -1.445927413793104 ], [ 29.805844810126583, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18186, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.445927413793104 ], [ 29.806114303797468, -1.446466379310345 ], [ 29.806383797468357, -1.446466379310345 ], [ 29.806383797468357, -1.445927413793104 ], [ 29.806114303797468, -1.445927413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18187, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.446196896551724 ], [ 29.74817316455696, -1.446466379310345 ], [ 29.748442658227848, -1.446466379310345 ], [ 29.748442658227848, -1.446196896551724 ], [ 29.74817316455696, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18188, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.446196896551724 ], [ 29.748442658227848, -1.446466379310345 ], [ 29.748712151898733, -1.446466379310345 ], [ 29.748712151898733, -1.446196896551724 ], [ 29.748442658227848, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18189, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.446196896551724 ], [ 29.748712151898733, -1.446466379310345 ], [ 29.748981645569618, -1.446466379310345 ], [ 29.748981645569618, -1.446196896551724 ], [ 29.748712151898733, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.446196896551724 ], [ 29.748981645569618, -1.446466379310345 ], [ 29.749251139240506, -1.446466379310345 ], [ 29.749251139240506, -1.446196896551724 ], [ 29.748981645569618, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18191, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.446196896551724 ], [ 29.749251139240506, -1.446466379310345 ], [ 29.749520632911391, -1.446466379310345 ], [ 29.749520632911391, -1.446196896551724 ], [ 29.749251139240506, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18192, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.446196896551724 ], [ 29.749520632911391, -1.446466379310345 ], [ 29.749790126582276, -1.446466379310345 ], [ 29.749790126582276, -1.446196896551724 ], [ 29.749520632911391, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18193, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.446196896551724 ], [ 29.749790126582276, -1.446466379310345 ], [ 29.750059620253165, -1.446466379310345 ], [ 29.750059620253165, -1.446196896551724 ], [ 29.749790126582276, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18194, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.446196896551724 ], [ 29.750059620253165, -1.446466379310345 ], [ 29.75032911392405, -1.446466379310345 ], [ 29.75032911392405, -1.446196896551724 ], [ 29.750059620253165, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18195, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.446196896551724 ], [ 29.75032911392405, -1.446466379310345 ], [ 29.750598607594934, -1.446466379310345 ], [ 29.750598607594934, -1.446196896551724 ], [ 29.75032911392405, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18196, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.446196896551724 ], [ 29.750598607594934, -1.446466379310345 ], [ 29.750868101265823, -1.446466379310345 ], [ 29.750868101265823, -1.446196896551724 ], [ 29.750598607594934, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18197, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.446196896551724 ], [ 29.750868101265823, -1.446466379310345 ], [ 29.751137594936708, -1.446466379310345 ], [ 29.751137594936708, -1.446196896551724 ], [ 29.750868101265823, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18198, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.446196896551724 ], [ 29.751137594936708, -1.446466379310345 ], [ 29.751407088607593, -1.446466379310345 ], [ 29.751407088607593, -1.446196896551724 ], [ 29.751137594936708, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18199, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.446196896551724 ], [ 29.751407088607593, -1.446466379310345 ], [ 29.751676582278481, -1.446466379310345 ], [ 29.751676582278481, -1.446196896551724 ], [ 29.751407088607593, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18200, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.446196896551724 ], [ 29.751676582278481, -1.446466379310345 ], [ 29.751946075949366, -1.446466379310345 ], [ 29.751946075949366, -1.446196896551724 ], [ 29.751676582278481, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18201, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.446196896551724 ], [ 29.751946075949366, -1.446466379310345 ], [ 29.752215569620251, -1.446466379310345 ], [ 29.752215569620251, -1.446196896551724 ], [ 29.751946075949366, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18202, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.446196896551724 ], [ 29.752215569620251, -1.446466379310345 ], [ 29.75248506329114, -1.446466379310345 ], [ 29.75248506329114, -1.446196896551724 ], [ 29.752215569620251, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18203, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.446196896551724 ], [ 29.75248506329114, -1.446466379310345 ], [ 29.752754556962024, -1.446466379310345 ], [ 29.752754556962024, -1.446196896551724 ], [ 29.75248506329114, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18204, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.446196896551724 ], [ 29.752754556962024, -1.446466379310345 ], [ 29.753024050632909, -1.446466379310345 ], [ 29.753024050632909, -1.446196896551724 ], [ 29.752754556962024, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18205, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.446196896551724 ], [ 29.753024050632909, -1.446466379310345 ], [ 29.753293544303798, -1.446466379310345 ], [ 29.753293544303798, -1.446196896551724 ], [ 29.753024050632909, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18206, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.446196896551724 ], [ 29.753293544303798, -1.446466379310345 ], [ 29.753563037974683, -1.446466379310345 ], [ 29.753563037974683, -1.446196896551724 ], [ 29.753293544303798, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18207, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.446196896551724 ], [ 29.753563037974683, -1.446466379310345 ], [ 29.753832531645568, -1.446466379310345 ], [ 29.753832531645568, -1.446196896551724 ], [ 29.753563037974683, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18208, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.446196896551724 ], [ 29.753832531645568, -1.446466379310345 ], [ 29.754102025316456, -1.446466379310345 ], [ 29.754102025316456, -1.446196896551724 ], [ 29.753832531645568, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18209, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.446196896551724 ], [ 29.754102025316456, -1.446466379310345 ], [ 29.754371518987341, -1.446466379310345 ], [ 29.754371518987341, -1.446196896551724 ], [ 29.754102025316456, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18210, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.446196896551724 ], [ 29.754371518987341, -1.446466379310345 ], [ 29.754641012658226, -1.446466379310345 ], [ 29.754641012658226, -1.446196896551724 ], [ 29.754371518987341, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18211, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.446196896551724 ], [ 29.754641012658226, -1.446466379310345 ], [ 29.754910506329114, -1.446466379310345 ], [ 29.754910506329114, -1.446196896551724 ], [ 29.754641012658226, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18212, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.446196896551724 ], [ 29.754910506329114, -1.446466379310345 ], [ 29.75518, -1.446466379310345 ], [ 29.75518, -1.446196896551724 ], [ 29.754910506329114, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18213, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.446196896551724 ], [ 29.75518, -1.446466379310345 ], [ 29.755449493670884, -1.446466379310345 ], [ 29.755449493670884, -1.446196896551724 ], [ 29.75518, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18214, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.446196896551724 ], [ 29.755449493670884, -1.446466379310345 ], [ 29.755718987341773, -1.446466379310345 ], [ 29.755718987341773, -1.446196896551724 ], [ 29.755449493670884, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18215, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.446196896551724 ], [ 29.755718987341773, -1.446466379310345 ], [ 29.756257974683542, -1.446466379310345 ], [ 29.756257974683542, -1.446196896551724 ], [ 29.755718987341773, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18216, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.446196896551724 ], [ 29.756257974683542, -1.446466379310345 ], [ 29.756796962025316, -1.446466379310345 ], [ 29.756796962025316, -1.446196896551724 ], [ 29.756257974683542, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18217, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.446196896551724 ], [ 29.756796962025316, -1.446466379310345 ], [ 29.757066455696201, -1.446466379310345 ], [ 29.757066455696201, -1.446196896551724 ], [ 29.756796962025316, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18218, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.446196896551724 ], [ 29.757066455696201, -1.446466379310345 ], [ 29.757335949367089, -1.446466379310345 ], [ 29.757335949367089, -1.446196896551724 ], [ 29.757066455696201, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18219, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.446196896551724 ], [ 29.757335949367089, -1.446466379310345 ], [ 29.757605443037974, -1.446466379310345 ], [ 29.757605443037974, -1.446196896551724 ], [ 29.757335949367089, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18220, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.446196896551724 ], [ 29.757605443037974, -1.446466379310345 ], [ 29.757874936708859, -1.446466379310345 ], [ 29.757874936708859, -1.446196896551724 ], [ 29.757605443037974, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18221, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.446196896551724 ], [ 29.757874936708859, -1.446466379310345 ], [ 29.758144430379748, -1.446466379310345 ], [ 29.758144430379748, -1.446196896551724 ], [ 29.757874936708859, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18222, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.446196896551724 ], [ 29.758144430379748, -1.446466379310345 ], [ 29.758413924050632, -1.446466379310345 ], [ 29.758413924050632, -1.446196896551724 ], [ 29.758144430379748, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18223, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.446196896551724 ], [ 29.758413924050632, -1.446466379310345 ], [ 29.758683417721517, -1.446466379310345 ], [ 29.758683417721517, -1.446196896551724 ], [ 29.758413924050632, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18224, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.446196896551724 ], [ 29.758683417721517, -1.446466379310345 ], [ 29.758952911392406, -1.446466379310345 ], [ 29.758952911392406, -1.446196896551724 ], [ 29.758683417721517, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18225, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.446196896551724 ], [ 29.758952911392406, -1.446466379310345 ], [ 29.759222405063291, -1.446466379310345 ], [ 29.759222405063291, -1.446196896551724 ], [ 29.758952911392406, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18226, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.446196896551724 ], [ 29.759222405063291, -1.446466379310345 ], [ 29.759491898734176, -1.446466379310345 ], [ 29.759491898734176, -1.446196896551724 ], [ 29.759222405063291, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18227, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.446196896551724 ], [ 29.759491898734176, -1.446466379310345 ], [ 29.759761392405064, -1.446466379310345 ], [ 29.759761392405064, -1.446196896551724 ], [ 29.759491898734176, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18228, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.446196896551724 ], [ 29.759761392405064, -1.446466379310345 ], [ 29.760030886075949, -1.446466379310345 ], [ 29.760030886075949, -1.446196896551724 ], [ 29.759761392405064, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18229, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.446196896551724 ], [ 29.760030886075949, -1.446466379310345 ], [ 29.760300379746834, -1.446466379310345 ], [ 29.760300379746834, -1.446196896551724 ], [ 29.760030886075949, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18230, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.446196896551724 ], [ 29.760300379746834, -1.446466379310345 ], [ 29.760569873417722, -1.446466379310345 ], [ 29.760569873417722, -1.446196896551724 ], [ 29.760300379746834, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18231, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.446196896551724 ], [ 29.760569873417722, -1.446466379310345 ], [ 29.760839367088607, -1.446466379310345 ], [ 29.760839367088607, -1.446196896551724 ], [ 29.760569873417722, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18232, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.446196896551724 ], [ 29.760839367088607, -1.446466379310345 ], [ 29.761108860759492, -1.446466379310345 ], [ 29.761108860759492, -1.446196896551724 ], [ 29.760839367088607, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18233, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.446196896551724 ], [ 29.761108860759492, -1.446466379310345 ], [ 29.761378354430381, -1.446466379310345 ], [ 29.761378354430381, -1.446196896551724 ], [ 29.761108860759492, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18234, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.446196896551724 ], [ 29.761378354430381, -1.446466379310345 ], [ 29.761647848101266, -1.446466379310345 ], [ 29.761647848101266, -1.446196896551724 ], [ 29.761378354430381, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18235, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.446196896551724 ], [ 29.761647848101266, -1.446466379310345 ], [ 29.761917341772151, -1.446466379310345 ], [ 29.761917341772151, -1.446196896551724 ], [ 29.761647848101266, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18236, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.446196896551724 ], [ 29.761917341772151, -1.446466379310345 ], [ 29.763264810126582, -1.446466379310345 ], [ 29.763264810126582, -1.446196896551724 ], [ 29.761917341772151, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18237, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.446196896551724 ], [ 29.763264810126582, -1.446466379310345 ], [ 29.763534303797467, -1.446466379310345 ], [ 29.763534303797467, -1.446196896551724 ], [ 29.763264810126582, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18238, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.446196896551724 ], [ 29.763534303797467, -1.446466379310345 ], [ 29.76407329113924, -1.446466379310345 ], [ 29.76407329113924, -1.446196896551724 ], [ 29.763534303797467, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18239, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.446196896551724 ], [ 29.76407329113924, -1.446466379310345 ], [ 29.764342784810125, -1.446466379310345 ], [ 29.764342784810125, -1.446196896551724 ], [ 29.76407329113924, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18240, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.446196896551724 ], [ 29.764342784810125, -1.446466379310345 ], [ 29.764612278481014, -1.446466379310345 ], [ 29.764612278481014, -1.446196896551724 ], [ 29.764342784810125, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18241, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.446196896551724 ], [ 29.764612278481014, -1.446466379310345 ], [ 29.764881772151899, -1.446466379310345 ], [ 29.764881772151899, -1.446196896551724 ], [ 29.764612278481014, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18242, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.446196896551724 ], [ 29.764881772151899, -1.446466379310345 ], [ 29.765151265822784, -1.446466379310345 ], [ 29.765151265822784, -1.446196896551724 ], [ 29.764881772151899, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18243, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.446196896551724 ], [ 29.765151265822784, -1.446466379310345 ], [ 29.765420759493672, -1.446466379310345 ], [ 29.765420759493672, -1.446196896551724 ], [ 29.765151265822784, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18244, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.446196896551724 ], [ 29.765420759493672, -1.446466379310345 ], [ 29.767846202531643, -1.446466379310345 ], [ 29.767846202531643, -1.446196896551724 ], [ 29.765420759493672, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18245, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.446196896551724 ], [ 29.767846202531643, -1.446466379310345 ], [ 29.768115696202532, -1.446466379310345 ], [ 29.768115696202532, -1.446196896551724 ], [ 29.767846202531643, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18246, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.446196896551724 ], [ 29.768115696202532, -1.446466379310345 ], [ 29.768385189873417, -1.446466379310345 ], [ 29.768385189873417, -1.446196896551724 ], [ 29.768115696202532, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18247, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.446196896551724 ], [ 29.768385189873417, -1.446466379310345 ], [ 29.768654683544302, -1.446466379310345 ], [ 29.768654683544302, -1.446196896551724 ], [ 29.768385189873417, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18248, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.446196896551724 ], [ 29.768654683544302, -1.446466379310345 ], [ 29.76892417721519, -1.446466379310345 ], [ 29.76892417721519, -1.446196896551724 ], [ 29.768654683544302, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18249, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.446196896551724 ], [ 29.76892417721519, -1.446466379310345 ], [ 29.769193670886075, -1.446466379310345 ], [ 29.769193670886075, -1.446196896551724 ], [ 29.76892417721519, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18250, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.446196896551724 ], [ 29.769193670886075, -1.446466379310345 ], [ 29.76946316455696, -1.446466379310345 ], [ 29.76946316455696, -1.446196896551724 ], [ 29.769193670886075, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18251, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.446196896551724 ], [ 29.76946316455696, -1.446466379310345 ], [ 29.769732658227849, -1.446466379310345 ], [ 29.769732658227849, -1.446196896551724 ], [ 29.76946316455696, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18252, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.446196896551724 ], [ 29.769732658227849, -1.446466379310345 ], [ 29.770002151898733, -1.446466379310345 ], [ 29.770002151898733, -1.446196896551724 ], [ 29.769732658227849, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18253, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.446196896551724 ], [ 29.770002151898733, -1.446466379310345 ], [ 29.770271645569618, -1.446466379310345 ], [ 29.770271645569618, -1.446196896551724 ], [ 29.770002151898733, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18254, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.446196896551724 ], [ 29.770271645569618, -1.446466379310345 ], [ 29.770541139240507, -1.446466379310345 ], [ 29.770541139240507, -1.446196896551724 ], [ 29.770271645569618, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18255, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.446196896551724 ], [ 29.770541139240507, -1.446466379310345 ], [ 29.770810632911392, -1.446466379310345 ], [ 29.770810632911392, -1.446196896551724 ], [ 29.770541139240507, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18256, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.446196896551724 ], [ 29.770810632911392, -1.446466379310345 ], [ 29.771080126582277, -1.446466379310345 ], [ 29.771080126582277, -1.446196896551724 ], [ 29.770810632911392, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18257, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.446196896551724 ], [ 29.771080126582277, -1.446466379310345 ], [ 29.771349620253165, -1.446466379310345 ], [ 29.771349620253165, -1.446196896551724 ], [ 29.771080126582277, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18258, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.446196896551724 ], [ 29.771349620253165, -1.446466379310345 ], [ 29.77161911392405, -1.446466379310345 ], [ 29.77161911392405, -1.446196896551724 ], [ 29.771349620253165, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18259, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.446196896551724 ], [ 29.77161911392405, -1.446466379310345 ], [ 29.771888607594935, -1.446466379310345 ], [ 29.771888607594935, -1.446196896551724 ], [ 29.77161911392405, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18260, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.446196896551724 ], [ 29.771888607594935, -1.446466379310345 ], [ 29.772158101265823, -1.446466379310345 ], [ 29.772158101265823, -1.446196896551724 ], [ 29.771888607594935, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18261, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.446196896551724 ], [ 29.772158101265823, -1.446466379310345 ], [ 29.772427594936708, -1.446466379310345 ], [ 29.772427594936708, -1.446196896551724 ], [ 29.772158101265823, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18262, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.446196896551724 ], [ 29.772427594936708, -1.446466379310345 ], [ 29.772697088607593, -1.446466379310345 ], [ 29.772697088607593, -1.446196896551724 ], [ 29.772427594936708, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18263, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.446196896551724 ], [ 29.772697088607593, -1.446466379310345 ], [ 29.772966582278482, -1.446466379310345 ], [ 29.772966582278482, -1.446196896551724 ], [ 29.772697088607593, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18264, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.446196896551724 ], [ 29.772966582278482, -1.446466379310345 ], [ 29.773236075949367, -1.446466379310345 ], [ 29.773236075949367, -1.446196896551724 ], [ 29.772966582278482, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18265, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.446196896551724 ], [ 29.773236075949367, -1.446735862068966 ], [ 29.773505569620252, -1.446735862068966 ], [ 29.773505569620252, -1.446196896551724 ], [ 29.773236075949367, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18266, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.446196896551724 ], [ 29.773505569620252, -1.446466379310345 ], [ 29.77377506329114, -1.446466379310345 ], [ 29.77377506329114, -1.446196896551724 ], [ 29.773505569620252, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18267, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.446196896551724 ], [ 29.77377506329114, -1.446466379310345 ], [ 29.774044556962025, -1.446466379310345 ], [ 29.774044556962025, -1.446196896551724 ], [ 29.77377506329114, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18268, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.446196896551724 ], [ 29.774044556962025, -1.446466379310345 ], [ 29.77431405063291, -1.446466379310345 ], [ 29.77431405063291, -1.446196896551724 ], [ 29.774044556962025, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18269, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.446196896551724 ], [ 29.774853037974683, -1.446466379310345 ], [ 29.775122531645568, -1.446466379310345 ], [ 29.775122531645568, -1.446196896551724 ], [ 29.774853037974683, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18270, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.446196896551724 ], [ 29.775122531645568, -1.446466379310345 ], [ 29.775392025316457, -1.446466379310345 ], [ 29.775392025316457, -1.446196896551724 ], [ 29.775122531645568, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18271, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.446196896551724 ], [ 29.775392025316457, -1.446735862068966 ], [ 29.775661518987341, -1.446735862068966 ], [ 29.775661518987341, -1.446196896551724 ], [ 29.775392025316457, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18272, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.446196896551724 ], [ 29.775661518987341, -1.446466379310345 ], [ 29.775931012658226, -1.446466379310345 ], [ 29.775931012658226, -1.446196896551724 ], [ 29.775661518987341, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18273, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.446196896551724 ], [ 29.775931012658226, -1.446466379310345 ], [ 29.776200506329115, -1.446466379310345 ], [ 29.776200506329115, -1.446196896551724 ], [ 29.775931012658226, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18274, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.446196896551724 ], [ 29.776200506329115, -1.446735862068966 ], [ 29.77647, -1.446735862068966 ], [ 29.77647, -1.446196896551724 ], [ 29.776200506329115, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18275, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.446196896551724 ], [ 29.776739493670885, -1.446466379310345 ], [ 29.777008987341773, -1.446466379310345 ], [ 29.777008987341773, -1.446196896551724 ], [ 29.776739493670885, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18276, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.446196896551724 ], [ 29.777008987341773, -1.446735862068966 ], [ 29.777278481012658, -1.446735862068966 ], [ 29.777278481012658, -1.446196896551724 ], [ 29.777008987341773, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18277, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.446196896551724 ], [ 29.777278481012658, -1.446466379310345 ], [ 29.777547974683543, -1.446466379310345 ], [ 29.777547974683543, -1.446196896551724 ], [ 29.777278481012658, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18278, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.446196896551724 ], [ 29.777547974683543, -1.446466379310345 ], [ 29.777817468354431, -1.446466379310345 ], [ 29.777817468354431, -1.446196896551724 ], [ 29.777547974683543, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18279, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.446196896551724 ], [ 29.777817468354431, -1.446466379310345 ], [ 29.778086962025316, -1.446466379310345 ], [ 29.778086962025316, -1.446196896551724 ], [ 29.777817468354431, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18280, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.446196896551724 ], [ 29.778086962025316, -1.446466379310345 ], [ 29.778356455696201, -1.446466379310345 ], [ 29.778356455696201, -1.446196896551724 ], [ 29.778086962025316, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18281, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.446196896551724 ], [ 29.778356455696201, -1.446466379310345 ], [ 29.77862594936709, -1.446466379310345 ], [ 29.77862594936709, -1.446196896551724 ], [ 29.778356455696201, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18282, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.446196896551724 ], [ 29.77862594936709, -1.446466379310345 ], [ 29.778895443037975, -1.446466379310345 ], [ 29.778895443037975, -1.446196896551724 ], [ 29.77862594936709, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18283, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.446196896551724 ], [ 29.778895443037975, -1.446466379310345 ], [ 29.77916493670886, -1.446466379310345 ], [ 29.77916493670886, -1.446196896551724 ], [ 29.778895443037975, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18284, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.446196896551724 ], [ 29.77916493670886, -1.446466379310345 ], [ 29.779434430379748, -1.446466379310345 ], [ 29.779434430379748, -1.446196896551724 ], [ 29.77916493670886, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18285, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.446196896551724 ], [ 29.779434430379748, -1.446466379310345 ], [ 29.779703924050633, -1.446466379310345 ], [ 29.779703924050633, -1.446196896551724 ], [ 29.779434430379748, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18286, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.446196896551724 ], [ 29.779703924050633, -1.446466379310345 ], [ 29.779973417721518, -1.446466379310345 ], [ 29.779973417721518, -1.446196896551724 ], [ 29.779703924050633, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18287, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.446196896551724 ], [ 29.779973417721518, -1.446466379310345 ], [ 29.780242911392406, -1.446466379310345 ], [ 29.780242911392406, -1.446196896551724 ], [ 29.779973417721518, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18288, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.446196896551724 ], [ 29.780242911392406, -1.446466379310345 ], [ 29.780512405063291, -1.446466379310345 ], [ 29.780512405063291, -1.446196896551724 ], [ 29.780242911392406, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18289, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.446196896551724 ], [ 29.780512405063291, -1.446466379310345 ], [ 29.780781898734176, -1.446466379310345 ], [ 29.780781898734176, -1.446196896551724 ], [ 29.780512405063291, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18290, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.446196896551724 ], [ 29.780781898734176, -1.446466379310345 ], [ 29.781051392405065, -1.446466379310345 ], [ 29.781051392405065, -1.446196896551724 ], [ 29.780781898734176, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18291, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.446196896551724 ], [ 29.781051392405065, -1.446466379310345 ], [ 29.78132088607595, -1.446466379310345 ], [ 29.78132088607595, -1.446196896551724 ], [ 29.781051392405065, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18292, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.446196896551724 ], [ 29.78132088607595, -1.446466379310345 ], [ 29.781590379746834, -1.446466379310345 ], [ 29.781590379746834, -1.446196896551724 ], [ 29.78132088607595, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18293, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.446196896551724 ], [ 29.781590379746834, -1.446466379310345 ], [ 29.781859873417723, -1.446466379310345 ], [ 29.781859873417723, -1.446196896551724 ], [ 29.781590379746834, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18294, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.446196896551724 ], [ 29.781859873417723, -1.446466379310345 ], [ 29.782129367088608, -1.446466379310345 ], [ 29.782129367088608, -1.446196896551724 ], [ 29.781859873417723, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18295, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.446196896551724 ], [ 29.782129367088608, -1.446466379310345 ], [ 29.782398860759493, -1.446466379310345 ], [ 29.782398860759493, -1.446196896551724 ], [ 29.782129367088608, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18296, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.446196896551724 ], [ 29.782398860759493, -1.446466379310345 ], [ 29.782668354430381, -1.446466379310345 ], [ 29.782668354430381, -1.446196896551724 ], [ 29.782398860759493, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18297, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.446196896551724 ], [ 29.782668354430381, -1.446466379310345 ], [ 29.782937848101266, -1.446466379310345 ], [ 29.782937848101266, -1.446196896551724 ], [ 29.782668354430381, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18298, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.446196896551724 ], [ 29.782937848101266, -1.446466379310345 ], [ 29.783207341772151, -1.446466379310345 ], [ 29.783207341772151, -1.446196896551724 ], [ 29.782937848101266, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18299, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.446196896551724 ], [ 29.783207341772151, -1.446466379310345 ], [ 29.783476835443039, -1.446466379310345 ], [ 29.783476835443039, -1.446196896551724 ], [ 29.783207341772151, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18300, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.446196896551724 ], [ 29.783476835443039, -1.446466379310345 ], [ 29.784015822784809, -1.446466379310345 ], [ 29.784015822784809, -1.446196896551724 ], [ 29.783476835443039, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18301, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.446196896551724 ], [ 29.784015822784809, -1.446466379310345 ], [ 29.784285316455698, -1.446466379310345 ], [ 29.784285316455698, -1.446196896551724 ], [ 29.784015822784809, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18302, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.446196896551724 ], [ 29.784285316455698, -1.446466379310345 ], [ 29.784554810126583, -1.446466379310345 ], [ 29.784554810126583, -1.446196896551724 ], [ 29.784285316455698, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18303, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.446196896551724 ], [ 29.784554810126583, -1.446466379310345 ], [ 29.784824303797468, -1.446466379310345 ], [ 29.784824303797468, -1.446196896551724 ], [ 29.784554810126583, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18304, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.446196896551724 ], [ 29.784824303797468, -1.446466379310345 ], [ 29.785093797468356, -1.446466379310345 ], [ 29.785093797468356, -1.446196896551724 ], [ 29.784824303797468, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18305, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.446196896551724 ], [ 29.785093797468356, -1.446466379310345 ], [ 29.785363291139241, -1.446466379310345 ], [ 29.785363291139241, -1.446196896551724 ], [ 29.785093797468356, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18306, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.446196896551724 ], [ 29.785363291139241, -1.446466379310345 ], [ 29.785632784810126, -1.446466379310345 ], [ 29.785632784810126, -1.446196896551724 ], [ 29.785363291139241, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18307, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.446196896551724 ], [ 29.785632784810126, -1.446466379310345 ], [ 29.785902278481014, -1.446466379310345 ], [ 29.785902278481014, -1.446196896551724 ], [ 29.785632784810126, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18308, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.446196896551724 ], [ 29.785902278481014, -1.446735862068966 ], [ 29.786171772151899, -1.446735862068966 ], [ 29.786171772151899, -1.446196896551724 ], [ 29.785902278481014, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18309, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.446196896551724 ], [ 29.786171772151899, -1.446735862068966 ], [ 29.786441265822784, -1.446735862068966 ], [ 29.786441265822784, -1.446196896551724 ], [ 29.786171772151899, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18310, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.446196896551724 ], [ 29.786441265822784, -1.446466379310345 ], [ 29.786710759493673, -1.446466379310345 ], [ 29.786710759493673, -1.446196896551724 ], [ 29.786441265822784, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18311, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.446196896551724 ], [ 29.786710759493673, -1.446466379310345 ], [ 29.786980253164558, -1.446466379310345 ], [ 29.786980253164558, -1.446196896551724 ], [ 29.786710759493673, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18312, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.446196896551724 ], [ 29.787249746835442, -1.446466379310345 ], [ 29.787519240506327, -1.446466379310345 ], [ 29.787519240506327, -1.446196896551724 ], [ 29.787249746835442, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18313, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.446196896551724 ], [ 29.787519240506327, -1.446466379310345 ], [ 29.787788734177216, -1.446466379310345 ], [ 29.787788734177216, -1.446196896551724 ], [ 29.787519240506327, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18314, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.446196896551724 ], [ 29.787788734177216, -1.446466379310345 ], [ 29.788058227848101, -1.446466379310345 ], [ 29.788058227848101, -1.446196896551724 ], [ 29.787788734177216, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18315, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.446196896551724 ], [ 29.788058227848101, -1.446466379310345 ], [ 29.788327721518986, -1.446466379310345 ], [ 29.788327721518986, -1.446196896551724 ], [ 29.788058227848101, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18316, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.446196896551724 ], [ 29.788327721518986, -1.446466379310345 ], [ 29.788597215189874, -1.446466379310345 ], [ 29.788597215189874, -1.446196896551724 ], [ 29.788327721518986, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18317, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.446196896551724 ], [ 29.789405696202532, -1.446466379310345 ], [ 29.789675189873417, -1.446466379310345 ], [ 29.789675189873417, -1.446196896551724 ], [ 29.789405696202532, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18318, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.446196896551724 ], [ 29.789675189873417, -1.446466379310345 ], [ 29.789944683544302, -1.446466379310345 ], [ 29.789944683544302, -1.446196896551724 ], [ 29.789675189873417, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18319, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.446196896551724 ], [ 29.789944683544302, -1.446466379310345 ], [ 29.790214177215191, -1.446466379310345 ], [ 29.790214177215191, -1.446196896551724 ], [ 29.789944683544302, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18320, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.446196896551724 ], [ 29.790214177215191, -1.446466379310345 ], [ 29.790483670886076, -1.446466379310345 ], [ 29.790483670886076, -1.446196896551724 ], [ 29.790214177215191, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18321, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.446196896551724 ], [ 29.790483670886076, -1.446466379310345 ], [ 29.790753164556961, -1.446466379310345 ], [ 29.790753164556961, -1.446196896551724 ], [ 29.790483670886076, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18322, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.446196896551724 ], [ 29.790753164556961, -1.446466379310345 ], [ 29.791022658227849, -1.446466379310345 ], [ 29.791022658227849, -1.446196896551724 ], [ 29.790753164556961, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18323, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.446196896551724 ], [ 29.791022658227849, -1.446466379310345 ], [ 29.791292151898734, -1.446466379310345 ], [ 29.791292151898734, -1.446196896551724 ], [ 29.791022658227849, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18324, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.446196896551724 ], [ 29.791292151898734, -1.446466379310345 ], [ 29.791561645569619, -1.446466379310345 ], [ 29.791561645569619, -1.446196896551724 ], [ 29.791292151898734, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18325, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.446196896551724 ], [ 29.791561645569619, -1.446466379310345 ], [ 29.791831139240507, -1.446466379310345 ], [ 29.791831139240507, -1.446196896551724 ], [ 29.791561645569619, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18326, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.446196896551724 ], [ 29.791831139240507, -1.446466379310345 ], [ 29.792100632911392, -1.446466379310345 ], [ 29.792100632911392, -1.446196896551724 ], [ 29.791831139240507, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18327, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.446196896551724 ], [ 29.792100632911392, -1.446466379310345 ], [ 29.792370126582277, -1.446466379310345 ], [ 29.792370126582277, -1.446196896551724 ], [ 29.792100632911392, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18328, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.446196896551724 ], [ 29.792370126582277, -1.446466379310345 ], [ 29.792909113924051, -1.446466379310345 ], [ 29.792909113924051, -1.446196896551724 ], [ 29.792370126582277, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18329, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.446196896551724 ], [ 29.792909113924051, -1.446466379310345 ], [ 29.793178607594935, -1.446466379310345 ], [ 29.793178607594935, -1.446196896551724 ], [ 29.792909113924051, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18330, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.446196896551724 ], [ 29.793178607594935, -1.446466379310345 ], [ 29.793448101265824, -1.446466379310345 ], [ 29.793448101265824, -1.446196896551724 ], [ 29.793178607594935, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18331, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.446196896551724 ], [ 29.793448101265824, -1.446466379310345 ], [ 29.793717594936709, -1.446466379310345 ], [ 29.793717594936709, -1.446196896551724 ], [ 29.793448101265824, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18332, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.446196896551724 ], [ 29.793717594936709, -1.446466379310345 ], [ 29.793987088607594, -1.446466379310345 ], [ 29.793987088607594, -1.446196896551724 ], [ 29.793717594936709, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18333, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.446196896551724 ], [ 29.794526075949367, -1.446735862068966 ], [ 29.794795569620252, -1.446735862068966 ], [ 29.794795569620252, -1.446196896551724 ], [ 29.794526075949367, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18334, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.446196896551724 ], [ 29.795873544303799, -1.446735862068966 ], [ 29.796143037974684, -1.446735862068966 ], [ 29.796143037974684, -1.446196896551724 ], [ 29.795873544303799, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18335, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.446196896551724 ], [ 29.796682025316457, -1.446466379310345 ], [ 29.796951518987342, -1.446466379310345 ], [ 29.796951518987342, -1.446196896551724 ], [ 29.796682025316457, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18336, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.446196896551724 ], [ 29.796951518987342, -1.446466379310345 ], [ 29.797221012658227, -1.446466379310345 ], [ 29.797221012658227, -1.446196896551724 ], [ 29.796951518987342, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18337, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.446196896551724 ], [ 29.797221012658227, -1.446466379310345 ], [ 29.797490506329115, -1.446466379310345 ], [ 29.797490506329115, -1.446196896551724 ], [ 29.797221012658227, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18338, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.446196896551724 ], [ 29.797490506329115, -1.446735862068966 ], [ 29.79776, -1.446735862068966 ], [ 29.79776, -1.446196896551724 ], [ 29.797490506329115, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18339, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.446196896551724 ], [ 29.803149873417723, -1.446466379310345 ], [ 29.803419367088608, -1.446466379310345 ], [ 29.803419367088608, -1.446196896551724 ], [ 29.803149873417723, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18340, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.446196896551724 ], [ 29.803419367088608, -1.446466379310345 ], [ 29.803688860759493, -1.446466379310345 ], [ 29.803688860759493, -1.446196896551724 ], [ 29.803419367088608, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18341, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.446196896551724 ], [ 29.803688860759493, -1.446466379310345 ], [ 29.803958354430382, -1.446466379310345 ], [ 29.803958354430382, -1.446196896551724 ], [ 29.803688860759493, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18342, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.446196896551724 ], [ 29.803958354430382, -1.446466379310345 ], [ 29.804227848101267, -1.446466379310345 ], [ 29.804227848101267, -1.446196896551724 ], [ 29.803958354430382, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18343, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.446196896551724 ], [ 29.804227848101267, -1.446735862068966 ], [ 29.804497341772151, -1.446735862068966 ], [ 29.804497341772151, -1.446196896551724 ], [ 29.804227848101267, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18344, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.446196896551724 ], [ 29.804497341772151, -1.446466379310345 ], [ 29.80476683544304, -1.446466379310345 ], [ 29.80476683544304, -1.446196896551724 ], [ 29.804497341772151, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18345, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.446196896551724 ], [ 29.80476683544304, -1.446466379310345 ], [ 29.805036329113925, -1.446466379310345 ], [ 29.805036329113925, -1.446196896551724 ], [ 29.80476683544304, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18346, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.446196896551724 ], [ 29.805036329113925, -1.446466379310345 ], [ 29.80530582278481, -1.446466379310345 ], [ 29.80530582278481, -1.446196896551724 ], [ 29.805036329113925, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18347, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.446196896551724 ], [ 29.80530582278481, -1.446466379310345 ], [ 29.805575316455698, -1.446466379310345 ], [ 29.805575316455698, -1.446196896551724 ], [ 29.80530582278481, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18348, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.446196896551724 ], [ 29.805575316455698, -1.446466379310345 ], [ 29.805844810126583, -1.446466379310345 ], [ 29.805844810126583, -1.446196896551724 ], [ 29.805575316455698, -1.446196896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18349, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.446466379310345 ], [ 29.74817316455696, -1.446735862068966 ], [ 29.748442658227848, -1.446735862068966 ], [ 29.748442658227848, -1.446466379310345 ], [ 29.74817316455696, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18350, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.446466379310345 ], [ 29.748442658227848, -1.446735862068966 ], [ 29.748712151898733, -1.446735862068966 ], [ 29.748712151898733, -1.446466379310345 ], [ 29.748442658227848, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18351, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.446466379310345 ], [ 29.748712151898733, -1.446735862068966 ], [ 29.748981645569618, -1.446735862068966 ], [ 29.748981645569618, -1.446466379310345 ], [ 29.748712151898733, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18352, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.446466379310345 ], [ 29.748981645569618, -1.446735862068966 ], [ 29.749251139240506, -1.446735862068966 ], [ 29.749251139240506, -1.446466379310345 ], [ 29.748981645569618, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18353, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.446466379310345 ], [ 29.749251139240506, -1.446735862068966 ], [ 29.749520632911391, -1.446735862068966 ], [ 29.749520632911391, -1.446466379310345 ], [ 29.749251139240506, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18354, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.446466379310345 ], [ 29.749520632911391, -1.446735862068966 ], [ 29.749790126582276, -1.446735862068966 ], [ 29.749790126582276, -1.446466379310345 ], [ 29.749520632911391, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18355, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.446466379310345 ], [ 29.749790126582276, -1.446735862068966 ], [ 29.750059620253165, -1.446735862068966 ], [ 29.750059620253165, -1.446466379310345 ], [ 29.749790126582276, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18356, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.446466379310345 ], [ 29.750059620253165, -1.446735862068966 ], [ 29.75032911392405, -1.446735862068966 ], [ 29.75032911392405, -1.446466379310345 ], [ 29.750059620253165, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18357, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.446466379310345 ], [ 29.75032911392405, -1.446735862068966 ], [ 29.750598607594934, -1.446735862068966 ], [ 29.750598607594934, -1.446466379310345 ], [ 29.75032911392405, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18358, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.446466379310345 ], [ 29.750598607594934, -1.446735862068966 ], [ 29.750868101265823, -1.446735862068966 ], [ 29.750868101265823, -1.446466379310345 ], [ 29.750598607594934, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18359, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.446466379310345 ], [ 29.750868101265823, -1.446735862068966 ], [ 29.751137594936708, -1.446735862068966 ], [ 29.751137594936708, -1.446466379310345 ], [ 29.750868101265823, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18360, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.446466379310345 ], [ 29.751137594936708, -1.446735862068966 ], [ 29.751407088607593, -1.446735862068966 ], [ 29.751407088607593, -1.446466379310345 ], [ 29.751137594936708, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18361, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.446466379310345 ], [ 29.751407088607593, -1.446735862068966 ], [ 29.751676582278481, -1.446735862068966 ], [ 29.751676582278481, -1.446466379310345 ], [ 29.751407088607593, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18362, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.446466379310345 ], [ 29.751676582278481, -1.446735862068966 ], [ 29.751946075949366, -1.446735862068966 ], [ 29.751946075949366, -1.446466379310345 ], [ 29.751676582278481, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18363, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.446466379310345 ], [ 29.751946075949366, -1.446735862068966 ], [ 29.752215569620251, -1.446735862068966 ], [ 29.752215569620251, -1.446466379310345 ], [ 29.751946075949366, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18364, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.446466379310345 ], [ 29.752215569620251, -1.446735862068966 ], [ 29.75248506329114, -1.446735862068966 ], [ 29.75248506329114, -1.446466379310345 ], [ 29.752215569620251, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18365, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.446466379310345 ], [ 29.75248506329114, -1.446735862068966 ], [ 29.752754556962024, -1.446735862068966 ], [ 29.752754556962024, -1.446466379310345 ], [ 29.75248506329114, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18366, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.446466379310345 ], [ 29.752754556962024, -1.446735862068966 ], [ 29.753024050632909, -1.446735862068966 ], [ 29.753024050632909, -1.446466379310345 ], [ 29.752754556962024, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18367, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.446466379310345 ], [ 29.753024050632909, -1.446735862068966 ], [ 29.753293544303798, -1.446735862068966 ], [ 29.753293544303798, -1.446466379310345 ], [ 29.753024050632909, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18368, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.446466379310345 ], [ 29.753293544303798, -1.446735862068966 ], [ 29.753563037974683, -1.446735862068966 ], [ 29.753563037974683, -1.446466379310345 ], [ 29.753293544303798, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18369, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.446466379310345 ], [ 29.753563037974683, -1.446735862068966 ], [ 29.753832531645568, -1.446735862068966 ], [ 29.753832531645568, -1.446466379310345 ], [ 29.753563037974683, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18370, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.446466379310345 ], [ 29.753832531645568, -1.446735862068966 ], [ 29.754102025316456, -1.446735862068966 ], [ 29.754102025316456, -1.446466379310345 ], [ 29.753832531645568, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18371, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.446466379310345 ], [ 29.754102025316456, -1.446735862068966 ], [ 29.754371518987341, -1.446735862068966 ], [ 29.754371518987341, -1.446466379310345 ], [ 29.754102025316456, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18372, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.446466379310345 ], [ 29.754371518987341, -1.446735862068966 ], [ 29.754641012658226, -1.446735862068966 ], [ 29.754641012658226, -1.446466379310345 ], [ 29.754371518987341, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18373, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.446466379310345 ], [ 29.754641012658226, -1.446735862068966 ], [ 29.754910506329114, -1.446735862068966 ], [ 29.754910506329114, -1.446466379310345 ], [ 29.754641012658226, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18374, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.446466379310345 ], [ 29.754910506329114, -1.446735862068966 ], [ 29.75518, -1.446735862068966 ], [ 29.75518, -1.446466379310345 ], [ 29.754910506329114, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18375, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.446466379310345 ], [ 29.75518, -1.446735862068966 ], [ 29.755449493670884, -1.446735862068966 ], [ 29.755449493670884, -1.446466379310345 ], [ 29.75518, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18376, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.446466379310345 ], [ 29.755449493670884, -1.446735862068966 ], [ 29.755718987341773, -1.446735862068966 ], [ 29.755718987341773, -1.446466379310345 ], [ 29.755449493670884, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18377, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.446466379310345 ], [ 29.755718987341773, -1.446735862068966 ], [ 29.755988481012658, -1.446735862068966 ], [ 29.755988481012658, -1.446466379310345 ], [ 29.755718987341773, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18378, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.446466379310345 ], [ 29.755988481012658, -1.446735862068966 ], [ 29.756257974683542, -1.446735862068966 ], [ 29.756257974683542, -1.446466379310345 ], [ 29.755988481012658, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18379, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.446466379310345 ], [ 29.756257974683542, -1.446735862068966 ], [ 29.756796962025316, -1.446735862068966 ], [ 29.756796962025316, -1.446466379310345 ], [ 29.756257974683542, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18380, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.446466379310345 ], [ 29.756796962025316, -1.446735862068966 ], [ 29.757066455696201, -1.446735862068966 ], [ 29.757066455696201, -1.446466379310345 ], [ 29.756796962025316, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18381, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.446466379310345 ], [ 29.757066455696201, -1.446735862068966 ], [ 29.757335949367089, -1.446735862068966 ], [ 29.757335949367089, -1.446466379310345 ], [ 29.757066455696201, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18382, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.446466379310345 ], [ 29.757335949367089, -1.446735862068966 ], [ 29.757605443037974, -1.446735862068966 ], [ 29.757605443037974, -1.446466379310345 ], [ 29.757335949367089, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18383, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.446466379310345 ], [ 29.757605443037974, -1.446735862068966 ], [ 29.757874936708859, -1.446735862068966 ], [ 29.757874936708859, -1.446466379310345 ], [ 29.757605443037974, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18384, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.446466379310345 ], [ 29.757874936708859, -1.446735862068966 ], [ 29.758144430379748, -1.446735862068966 ], [ 29.758144430379748, -1.446466379310345 ], [ 29.757874936708859, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18385, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.446466379310345 ], [ 29.758144430379748, -1.446735862068966 ], [ 29.758413924050632, -1.446735862068966 ], [ 29.758413924050632, -1.446466379310345 ], [ 29.758144430379748, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18386, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.446466379310345 ], [ 29.758413924050632, -1.446735862068966 ], [ 29.758683417721517, -1.446735862068966 ], [ 29.758683417721517, -1.446466379310345 ], [ 29.758413924050632, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18387, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.446466379310345 ], [ 29.758683417721517, -1.446735862068966 ], [ 29.758952911392406, -1.446735862068966 ], [ 29.758952911392406, -1.446466379310345 ], [ 29.758683417721517, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18388, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.446466379310345 ], [ 29.758952911392406, -1.446735862068966 ], [ 29.759222405063291, -1.446735862068966 ], [ 29.759222405063291, -1.446466379310345 ], [ 29.758952911392406, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18389, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.446466379310345 ], [ 29.759222405063291, -1.446735862068966 ], [ 29.759491898734176, -1.446735862068966 ], [ 29.759491898734176, -1.446466379310345 ], [ 29.759222405063291, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18390, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.446466379310345 ], [ 29.759491898734176, -1.446735862068966 ], [ 29.759761392405064, -1.446735862068966 ], [ 29.759761392405064, -1.446466379310345 ], [ 29.759491898734176, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18391, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.446466379310345 ], [ 29.759761392405064, -1.446735862068966 ], [ 29.760030886075949, -1.446735862068966 ], [ 29.760030886075949, -1.446466379310345 ], [ 29.759761392405064, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18392, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.446466379310345 ], [ 29.760030886075949, -1.446735862068966 ], [ 29.760300379746834, -1.446735862068966 ], [ 29.760300379746834, -1.446466379310345 ], [ 29.760030886075949, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18393, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.446466379310345 ], [ 29.760300379746834, -1.446735862068966 ], [ 29.760569873417722, -1.446735862068966 ], [ 29.760569873417722, -1.446466379310345 ], [ 29.760300379746834, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18394, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.446466379310345 ], [ 29.760569873417722, -1.446735862068966 ], [ 29.760839367088607, -1.446735862068966 ], [ 29.760839367088607, -1.446466379310345 ], [ 29.760569873417722, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18395, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.446466379310345 ], [ 29.760839367088607, -1.446735862068966 ], [ 29.761108860759492, -1.446735862068966 ], [ 29.761108860759492, -1.446466379310345 ], [ 29.760839367088607, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18396, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.446466379310345 ], [ 29.761108860759492, -1.446735862068966 ], [ 29.761378354430381, -1.446735862068966 ], [ 29.761378354430381, -1.446466379310345 ], [ 29.761108860759492, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18397, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.446466379310345 ], [ 29.761378354430381, -1.446735862068966 ], [ 29.761647848101266, -1.446735862068966 ], [ 29.761647848101266, -1.446466379310345 ], [ 29.761378354430381, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18398, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.446466379310345 ], [ 29.761647848101266, -1.446735862068966 ], [ 29.761917341772151, -1.446735862068966 ], [ 29.761917341772151, -1.446466379310345 ], [ 29.761647848101266, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18399, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.446466379310345 ], [ 29.761917341772151, -1.446735862068966 ], [ 29.762186835443039, -1.446735862068966 ], [ 29.762186835443039, -1.446466379310345 ], [ 29.761917341772151, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18400, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.446466379310345 ], [ 29.762186835443039, -1.446735862068966 ], [ 29.762995316455697, -1.446735862068966 ], [ 29.762995316455697, -1.446466379310345 ], [ 29.762186835443039, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18401, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.446466379310345 ], [ 29.762995316455697, -1.446735862068966 ], [ 29.763264810126582, -1.446735862068966 ], [ 29.763264810126582, -1.446466379310345 ], [ 29.762995316455697, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18402, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.446466379310345 ], [ 29.763264810126582, -1.446735862068966 ], [ 29.763534303797467, -1.446735862068966 ], [ 29.763534303797467, -1.446466379310345 ], [ 29.763264810126582, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18403, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.446466379310345 ], [ 29.763534303797467, -1.446735862068966 ], [ 29.76407329113924, -1.446735862068966 ], [ 29.76407329113924, -1.446466379310345 ], [ 29.763534303797467, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18404, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.446466379310345 ], [ 29.76407329113924, -1.446735862068966 ], [ 29.764342784810125, -1.446735862068966 ], [ 29.764342784810125, -1.446466379310345 ], [ 29.76407329113924, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18405, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.446466379310345 ], [ 29.764342784810125, -1.446735862068966 ], [ 29.764612278481014, -1.446735862068966 ], [ 29.764612278481014, -1.446466379310345 ], [ 29.764342784810125, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18406, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.446466379310345 ], [ 29.764612278481014, -1.446735862068966 ], [ 29.764881772151899, -1.446735862068966 ], [ 29.764881772151899, -1.446466379310345 ], [ 29.764612278481014, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18407, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.446466379310345 ], [ 29.764881772151899, -1.446735862068966 ], [ 29.765151265822784, -1.446735862068966 ], [ 29.765151265822784, -1.446466379310345 ], [ 29.764881772151899, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18408, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.446466379310345 ], [ 29.765151265822784, -1.446735862068966 ], [ 29.765959746835442, -1.446735862068966 ], [ 29.765959746835442, -1.446466379310345 ], [ 29.765151265822784, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18409, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.446466379310345 ], [ 29.765959746835442, -1.446735862068966 ], [ 29.767576708860759, -1.446735862068966 ], [ 29.767576708860759, -1.446466379310345 ], [ 29.765959746835442, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18410, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.446466379310345 ], [ 29.767576708860759, -1.446735862068966 ], [ 29.768115696202532, -1.446735862068966 ], [ 29.768115696202532, -1.446466379310345 ], [ 29.767576708860759, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18411, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.446466379310345 ], [ 29.768115696202532, -1.446735862068966 ], [ 29.768385189873417, -1.446735862068966 ], [ 29.768385189873417, -1.446466379310345 ], [ 29.768115696202532, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18412, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.446466379310345 ], [ 29.768385189873417, -1.446735862068966 ], [ 29.768654683544302, -1.446735862068966 ], [ 29.768654683544302, -1.446466379310345 ], [ 29.768385189873417, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18413, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.446466379310345 ], [ 29.768654683544302, -1.446735862068966 ], [ 29.76892417721519, -1.446735862068966 ], [ 29.76892417721519, -1.446466379310345 ], [ 29.768654683544302, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18414, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.446466379310345 ], [ 29.76892417721519, -1.446735862068966 ], [ 29.769193670886075, -1.446735862068966 ], [ 29.769193670886075, -1.446466379310345 ], [ 29.76892417721519, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18415, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.446466379310345 ], [ 29.769193670886075, -1.446735862068966 ], [ 29.76946316455696, -1.446735862068966 ], [ 29.76946316455696, -1.446466379310345 ], [ 29.769193670886075, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18416, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.446466379310345 ], [ 29.76946316455696, -1.446735862068966 ], [ 29.769732658227849, -1.446735862068966 ], [ 29.769732658227849, -1.446466379310345 ], [ 29.76946316455696, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18417, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.446466379310345 ], [ 29.769732658227849, -1.446735862068966 ], [ 29.770002151898733, -1.446735862068966 ], [ 29.770002151898733, -1.446466379310345 ], [ 29.769732658227849, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18418, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.446466379310345 ], [ 29.770002151898733, -1.446735862068966 ], [ 29.770271645569618, -1.446735862068966 ], [ 29.770271645569618, -1.446466379310345 ], [ 29.770002151898733, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18419, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.446466379310345 ], [ 29.770271645569618, -1.446735862068966 ], [ 29.770541139240507, -1.446735862068966 ], [ 29.770541139240507, -1.446466379310345 ], [ 29.770271645569618, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18420, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.446466379310345 ], [ 29.770541139240507, -1.446735862068966 ], [ 29.770810632911392, -1.446735862068966 ], [ 29.770810632911392, -1.446466379310345 ], [ 29.770541139240507, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18421, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.446466379310345 ], [ 29.770810632911392, -1.446735862068966 ], [ 29.771080126582277, -1.446735862068966 ], [ 29.771080126582277, -1.446466379310345 ], [ 29.770810632911392, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18422, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.446466379310345 ], [ 29.771080126582277, -1.446735862068966 ], [ 29.771349620253165, -1.446735862068966 ], [ 29.771349620253165, -1.446466379310345 ], [ 29.771080126582277, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18423, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.446466379310345 ], [ 29.771349620253165, -1.446735862068966 ], [ 29.77161911392405, -1.446735862068966 ], [ 29.77161911392405, -1.446466379310345 ], [ 29.771349620253165, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18424, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.446466379310345 ], [ 29.77161911392405, -1.446735862068966 ], [ 29.771888607594935, -1.446735862068966 ], [ 29.771888607594935, -1.446466379310345 ], [ 29.77161911392405, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18425, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.446466379310345 ], [ 29.771888607594935, -1.446735862068966 ], [ 29.772158101265823, -1.446735862068966 ], [ 29.772158101265823, -1.446466379310345 ], [ 29.771888607594935, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18426, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.446466379310345 ], [ 29.772158101265823, -1.447005344827586 ], [ 29.772427594936708, -1.447005344827586 ], [ 29.772427594936708, -1.446466379310345 ], [ 29.772158101265823, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18427, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.446466379310345 ], [ 29.772427594936708, -1.446735862068966 ], [ 29.772697088607593, -1.446735862068966 ], [ 29.772697088607593, -1.446466379310345 ], [ 29.772427594936708, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18428, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.446466379310345 ], [ 29.772697088607593, -1.446735862068966 ], [ 29.772966582278482, -1.446735862068966 ], [ 29.772966582278482, -1.446466379310345 ], [ 29.772697088607593, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18429, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.446466379310345 ], [ 29.772966582278482, -1.446735862068966 ], [ 29.773236075949367, -1.446735862068966 ], [ 29.773236075949367, -1.446466379310345 ], [ 29.772966582278482, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18430, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.446466379310345 ], [ 29.773505569620252, -1.446735862068966 ], [ 29.77377506329114, -1.446735862068966 ], [ 29.77377506329114, -1.446466379310345 ], [ 29.773505569620252, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18431, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.446466379310345 ], [ 29.77377506329114, -1.446735862068966 ], [ 29.774044556962025, -1.446735862068966 ], [ 29.774044556962025, -1.446466379310345 ], [ 29.77377506329114, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18432, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.446466379310345 ], [ 29.774044556962025, -1.447005344827586 ], [ 29.77431405063291, -1.447005344827586 ], [ 29.77431405063291, -1.446466379310345 ], [ 29.774044556962025, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18433, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.446466379310345 ], [ 29.77431405063291, -1.446735862068966 ], [ 29.774853037974683, -1.446735862068966 ], [ 29.774853037974683, -1.446466379310345 ], [ 29.77431405063291, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18434, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.446466379310345 ], [ 29.774853037974683, -1.446735862068966 ], [ 29.775122531645568, -1.446735862068966 ], [ 29.775122531645568, -1.446466379310345 ], [ 29.774853037974683, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18435, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.446466379310345 ], [ 29.775122531645568, -1.447005344827586 ], [ 29.775392025316457, -1.447005344827586 ], [ 29.775392025316457, -1.446466379310345 ], [ 29.775122531645568, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18436, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.446466379310345 ], [ 29.775661518987341, -1.446735862068966 ], [ 29.775931012658226, -1.446735862068966 ], [ 29.775931012658226, -1.446466379310345 ], [ 29.775661518987341, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18437, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.446466379310345 ], [ 29.775931012658226, -1.447005344827586 ], [ 29.776200506329115, -1.447005344827586 ], [ 29.776200506329115, -1.446466379310345 ], [ 29.775931012658226, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18438, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.446466379310345 ], [ 29.77647, -1.446735862068966 ], [ 29.776739493670885, -1.446735862068966 ], [ 29.776739493670885, -1.446466379310345 ], [ 29.77647, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18439, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.446466379310345 ], [ 29.776739493670885, -1.447005344827586 ], [ 29.777008987341773, -1.447005344827586 ], [ 29.777008987341773, -1.446466379310345 ], [ 29.776739493670885, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18440, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.446466379310345 ], [ 29.777278481012658, -1.446735862068966 ], [ 29.777547974683543, -1.446735862068966 ], [ 29.777547974683543, -1.446466379310345 ], [ 29.777278481012658, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18441, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.446466379310345 ], [ 29.777547974683543, -1.446735862068966 ], [ 29.777817468354431, -1.446735862068966 ], [ 29.777817468354431, -1.446466379310345 ], [ 29.777547974683543, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18442, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.446466379310345 ], [ 29.777817468354431, -1.446735862068966 ], [ 29.778086962025316, -1.446735862068966 ], [ 29.778086962025316, -1.446466379310345 ], [ 29.777817468354431, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18443, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.446466379310345 ], [ 29.778086962025316, -1.446735862068966 ], [ 29.778356455696201, -1.446735862068966 ], [ 29.778356455696201, -1.446466379310345 ], [ 29.778086962025316, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18444, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.446466379310345 ], [ 29.778356455696201, -1.446735862068966 ], [ 29.77862594936709, -1.446735862068966 ], [ 29.77862594936709, -1.446466379310345 ], [ 29.778356455696201, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18445, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.446466379310345 ], [ 29.77862594936709, -1.446735862068966 ], [ 29.778895443037975, -1.446735862068966 ], [ 29.778895443037975, -1.446466379310345 ], [ 29.77862594936709, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18446, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.446466379310345 ], [ 29.778895443037975, -1.446735862068966 ], [ 29.77916493670886, -1.446735862068966 ], [ 29.77916493670886, -1.446466379310345 ], [ 29.778895443037975, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18447, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.446466379310345 ], [ 29.77916493670886, -1.446735862068966 ], [ 29.779434430379748, -1.446735862068966 ], [ 29.779434430379748, -1.446466379310345 ], [ 29.77916493670886, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18448, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.446466379310345 ], [ 29.779434430379748, -1.446735862068966 ], [ 29.779703924050633, -1.446735862068966 ], [ 29.779703924050633, -1.446466379310345 ], [ 29.779434430379748, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18449, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.446466379310345 ], [ 29.779703924050633, -1.446735862068966 ], [ 29.779973417721518, -1.446735862068966 ], [ 29.779973417721518, -1.446466379310345 ], [ 29.779703924050633, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18450, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.446466379310345 ], [ 29.779973417721518, -1.446735862068966 ], [ 29.780242911392406, -1.446735862068966 ], [ 29.780242911392406, -1.446466379310345 ], [ 29.779973417721518, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18451, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.446466379310345 ], [ 29.780242911392406, -1.446735862068966 ], [ 29.780512405063291, -1.446735862068966 ], [ 29.780512405063291, -1.446466379310345 ], [ 29.780242911392406, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18452, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.446466379310345 ], [ 29.780512405063291, -1.446735862068966 ], [ 29.780781898734176, -1.446735862068966 ], [ 29.780781898734176, -1.446466379310345 ], [ 29.780512405063291, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18453, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.446466379310345 ], [ 29.780781898734176, -1.446735862068966 ], [ 29.781051392405065, -1.446735862068966 ], [ 29.781051392405065, -1.446466379310345 ], [ 29.780781898734176, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18454, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.446466379310345 ], [ 29.781051392405065, -1.446735862068966 ], [ 29.78132088607595, -1.446735862068966 ], [ 29.78132088607595, -1.446466379310345 ], [ 29.781051392405065, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18455, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.446466379310345 ], [ 29.78132088607595, -1.446735862068966 ], [ 29.781590379746834, -1.446735862068966 ], [ 29.781590379746834, -1.446466379310345 ], [ 29.78132088607595, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18456, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.446466379310345 ], [ 29.781590379746834, -1.446735862068966 ], [ 29.781859873417723, -1.446735862068966 ], [ 29.781859873417723, -1.446466379310345 ], [ 29.781590379746834, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18457, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.446466379310345 ], [ 29.781859873417723, -1.446735862068966 ], [ 29.782129367088608, -1.446735862068966 ], [ 29.782129367088608, -1.446466379310345 ], [ 29.781859873417723, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18458, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.446466379310345 ], [ 29.782129367088608, -1.446735862068966 ], [ 29.782398860759493, -1.446735862068966 ], [ 29.782398860759493, -1.446466379310345 ], [ 29.782129367088608, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18459, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.446466379310345 ], [ 29.782398860759493, -1.446735862068966 ], [ 29.782668354430381, -1.446735862068966 ], [ 29.782668354430381, -1.446466379310345 ], [ 29.782398860759493, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18460, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.446466379310345 ], [ 29.782668354430381, -1.446735862068966 ], [ 29.782937848101266, -1.446735862068966 ], [ 29.782937848101266, -1.446466379310345 ], [ 29.782668354430381, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18461, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.446466379310345 ], [ 29.782937848101266, -1.446735862068966 ], [ 29.783207341772151, -1.446735862068966 ], [ 29.783207341772151, -1.446466379310345 ], [ 29.782937848101266, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18462, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.446466379310345 ], [ 29.783207341772151, -1.446735862068966 ], [ 29.783476835443039, -1.446735862068966 ], [ 29.783476835443039, -1.446466379310345 ], [ 29.783207341772151, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18463, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.446466379310345 ], [ 29.783476835443039, -1.446735862068966 ], [ 29.784015822784809, -1.446735862068966 ], [ 29.784015822784809, -1.446466379310345 ], [ 29.783476835443039, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18464, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.446466379310345 ], [ 29.784015822784809, -1.447005344827586 ], [ 29.784285316455698, -1.447005344827586 ], [ 29.784285316455698, -1.446466379310345 ], [ 29.784015822784809, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18465, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.446466379310345 ], [ 29.784285316455698, -1.447005344827586 ], [ 29.784554810126583, -1.447005344827586 ], [ 29.784554810126583, -1.446466379310345 ], [ 29.784285316455698, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18466, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.446466379310345 ], [ 29.784554810126583, -1.447005344827586 ], [ 29.784824303797468, -1.447005344827586 ], [ 29.784824303797468, -1.446466379310345 ], [ 29.784554810126583, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18467, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.446466379310345 ], [ 29.784824303797468, -1.446735862068966 ], [ 29.785093797468356, -1.446735862068966 ], [ 29.785093797468356, -1.446466379310345 ], [ 29.784824303797468, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18468, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.446466379310345 ], [ 29.785093797468356, -1.446735862068966 ], [ 29.785363291139241, -1.446735862068966 ], [ 29.785363291139241, -1.446466379310345 ], [ 29.785093797468356, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18469, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.446466379310345 ], [ 29.785363291139241, -1.446735862068966 ], [ 29.785632784810126, -1.446735862068966 ], [ 29.785632784810126, -1.446466379310345 ], [ 29.785363291139241, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18470, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.446466379310345 ], [ 29.785632784810126, -1.446735862068966 ], [ 29.785902278481014, -1.446735862068966 ], [ 29.785902278481014, -1.446466379310345 ], [ 29.785632784810126, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18471, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.446466379310345 ], [ 29.786441265822784, -1.446735862068966 ], [ 29.786710759493673, -1.446735862068966 ], [ 29.786710759493673, -1.446466379310345 ], [ 29.786441265822784, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18472, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.446466379310345 ], [ 29.786710759493673, -1.446735862068966 ], [ 29.786980253164558, -1.446735862068966 ], [ 29.786980253164558, -1.446466379310345 ], [ 29.786710759493673, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18473, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.446466379310345 ], [ 29.786980253164558, -1.446735862068966 ], [ 29.787249746835442, -1.446735862068966 ], [ 29.787249746835442, -1.446466379310345 ], [ 29.786980253164558, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18474, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.446466379310345 ], [ 29.787249746835442, -1.446735862068966 ], [ 29.787519240506327, -1.446735862068966 ], [ 29.787519240506327, -1.446466379310345 ], [ 29.787249746835442, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18475, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.446466379310345 ], [ 29.787519240506327, -1.446735862068966 ], [ 29.787788734177216, -1.446735862068966 ], [ 29.787788734177216, -1.446466379310345 ], [ 29.787519240506327, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18476, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.446466379310345 ], [ 29.787788734177216, -1.446735862068966 ], [ 29.788058227848101, -1.446735862068966 ], [ 29.788058227848101, -1.446466379310345 ], [ 29.787788734177216, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18477, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.446466379310345 ], [ 29.788058227848101, -1.447005344827586 ], [ 29.788327721518986, -1.447005344827586 ], [ 29.788327721518986, -1.446466379310345 ], [ 29.788058227848101, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18478, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.446466379310345 ], [ 29.789675189873417, -1.447005344827586 ], [ 29.789944683544302, -1.447005344827586 ], [ 29.789944683544302, -1.446466379310345 ], [ 29.789675189873417, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18479, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.446466379310345 ], [ 29.789944683544302, -1.446735862068966 ], [ 29.790214177215191, -1.446735862068966 ], [ 29.790214177215191, -1.446466379310345 ], [ 29.789944683544302, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18480, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.446466379310345 ], [ 29.790214177215191, -1.446735862068966 ], [ 29.790483670886076, -1.446735862068966 ], [ 29.790483670886076, -1.446466379310345 ], [ 29.790214177215191, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18481, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.446466379310345 ], [ 29.790483670886076, -1.446735862068966 ], [ 29.790753164556961, -1.446735862068966 ], [ 29.790753164556961, -1.446466379310345 ], [ 29.790483670886076, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18482, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.446466379310345 ], [ 29.790753164556961, -1.446735862068966 ], [ 29.791022658227849, -1.446735862068966 ], [ 29.791022658227849, -1.446466379310345 ], [ 29.790753164556961, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18483, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.446466379310345 ], [ 29.791022658227849, -1.446735862068966 ], [ 29.791292151898734, -1.446735862068966 ], [ 29.791292151898734, -1.446466379310345 ], [ 29.791022658227849, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18484, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.446466379310345 ], [ 29.791292151898734, -1.446735862068966 ], [ 29.791561645569619, -1.446735862068966 ], [ 29.791561645569619, -1.446466379310345 ], [ 29.791292151898734, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18485, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.446466379310345 ], [ 29.791561645569619, -1.446735862068966 ], [ 29.791831139240507, -1.446735862068966 ], [ 29.791831139240507, -1.446466379310345 ], [ 29.791561645569619, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18486, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.446466379310345 ], [ 29.791831139240507, -1.446735862068966 ], [ 29.792100632911392, -1.446735862068966 ], [ 29.792100632911392, -1.446466379310345 ], [ 29.791831139240507, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18487, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.446466379310345 ], [ 29.792100632911392, -1.446735862068966 ], [ 29.792370126582277, -1.446735862068966 ], [ 29.792370126582277, -1.446466379310345 ], [ 29.792100632911392, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18488, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.446466379310345 ], [ 29.792370126582277, -1.446735862068966 ], [ 29.792909113924051, -1.446735862068966 ], [ 29.792909113924051, -1.446466379310345 ], [ 29.792370126582277, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18489, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.446466379310345 ], [ 29.792909113924051, -1.447005344827586 ], [ 29.793178607594935, -1.447005344827586 ], [ 29.793178607594935, -1.446466379310345 ], [ 29.792909113924051, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18490, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.446466379310345 ], [ 29.793178607594935, -1.446735862068966 ], [ 29.793448101265824, -1.446735862068966 ], [ 29.793448101265824, -1.446466379310345 ], [ 29.793178607594935, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18491, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.446466379310345 ], [ 29.793448101265824, -1.446735862068966 ], [ 29.793717594936709, -1.446735862068966 ], [ 29.793717594936709, -1.446466379310345 ], [ 29.793448101265824, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18492, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.446466379310345 ], [ 29.793717594936709, -1.447005344827586 ], [ 29.794256582278482, -1.447005344827586 ], [ 29.794256582278482, -1.446466379310345 ], [ 29.793717594936709, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18493, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.446466379310345 ], [ 29.794795569620252, -1.447005344827586 ], [ 29.79506506329114, -1.447005344827586 ], [ 29.79506506329114, -1.446466379310345 ], [ 29.794795569620252, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18494, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.446466379310345 ], [ 29.795334556962025, -1.446735862068966 ], [ 29.79560405063291, -1.446735862068966 ], [ 29.79560405063291, -1.446466379310345 ], [ 29.795334556962025, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18495, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.446466379310345 ], [ 29.79560405063291, -1.446735862068966 ], [ 29.795873544303799, -1.446735862068966 ], [ 29.795873544303799, -1.446466379310345 ], [ 29.79560405063291, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18496, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.446466379310345 ], [ 29.796143037974684, -1.446735862068966 ], [ 29.796412531645569, -1.446735862068966 ], [ 29.796412531645569, -1.446466379310345 ], [ 29.796143037974684, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18497, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.446466379310345 ], [ 29.796412531645569, -1.446735862068966 ], [ 29.796682025316457, -1.446735862068966 ], [ 29.796682025316457, -1.446466379310345 ], [ 29.796412531645569, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18498, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.446466379310345 ], [ 29.796682025316457, -1.446735862068966 ], [ 29.796951518987342, -1.446735862068966 ], [ 29.796951518987342, -1.446466379310345 ], [ 29.796682025316457, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18499, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.446466379310345 ], [ 29.796951518987342, -1.446735862068966 ], [ 29.797221012658227, -1.446735862068966 ], [ 29.797221012658227, -1.446466379310345 ], [ 29.796951518987342, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18500, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.446466379310345 ], [ 29.797221012658227, -1.446735862068966 ], [ 29.797490506329115, -1.446735862068966 ], [ 29.797490506329115, -1.446466379310345 ], [ 29.797221012658227, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18501, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.446466379310345 ], [ 29.802341392405065, -1.446735862068966 ], [ 29.80261088607595, -1.446735862068966 ], [ 29.80261088607595, -1.446466379310345 ], [ 29.802341392405065, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18502, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.446466379310345 ], [ 29.80261088607595, -1.446735862068966 ], [ 29.802880379746835, -1.446735862068966 ], [ 29.802880379746835, -1.446466379310345 ], [ 29.80261088607595, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18503, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.446466379310345 ], [ 29.802880379746835, -1.446735862068966 ], [ 29.803149873417723, -1.446735862068966 ], [ 29.803149873417723, -1.446466379310345 ], [ 29.802880379746835, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18504, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.446466379310345 ], [ 29.803149873417723, -1.447005344827586 ], [ 29.803419367088608, -1.447005344827586 ], [ 29.803419367088608, -1.446466379310345 ], [ 29.803149873417723, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18505, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.446466379310345 ], [ 29.803419367088608, -1.446735862068966 ], [ 29.803688860759493, -1.446735862068966 ], [ 29.803688860759493, -1.446466379310345 ], [ 29.803419367088608, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18506, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.446466379310345 ], [ 29.803688860759493, -1.446735862068966 ], [ 29.803958354430382, -1.446735862068966 ], [ 29.803958354430382, -1.446466379310345 ], [ 29.803688860759493, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18507, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.446466379310345 ], [ 29.803958354430382, -1.447005344827586 ], [ 29.804227848101267, -1.447005344827586 ], [ 29.804227848101267, -1.446466379310345 ], [ 29.803958354430382, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18508, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.446466379310345 ], [ 29.804497341772151, -1.446735862068966 ], [ 29.80476683544304, -1.446735862068966 ], [ 29.80476683544304, -1.446466379310345 ], [ 29.804497341772151, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18509, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.446466379310345 ], [ 29.80476683544304, -1.446735862068966 ], [ 29.805036329113925, -1.446735862068966 ], [ 29.805036329113925, -1.446466379310345 ], [ 29.80476683544304, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18510, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.446466379310345 ], [ 29.805036329113925, -1.446735862068966 ], [ 29.80530582278481, -1.446735862068966 ], [ 29.80530582278481, -1.446466379310345 ], [ 29.805036329113925, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18511, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.446466379310345 ], [ 29.80530582278481, -1.446735862068966 ], [ 29.805575316455698, -1.446735862068966 ], [ 29.805575316455698, -1.446466379310345 ], [ 29.80530582278481, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18512, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.446466379310345 ], [ 29.805575316455698, -1.446735862068966 ], [ 29.805844810126583, -1.446735862068966 ], [ 29.805844810126583, -1.446466379310345 ], [ 29.805575316455698, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18513, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.446466379310345 ], [ 29.805844810126583, -1.446735862068966 ], [ 29.806114303797468, -1.446735862068966 ], [ 29.806114303797468, -1.446466379310345 ], [ 29.805844810126583, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18514, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.446466379310345 ], [ 29.806114303797468, -1.446735862068966 ], [ 29.806383797468357, -1.446735862068966 ], [ 29.806383797468357, -1.446466379310345 ], [ 29.806114303797468, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18515, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.446466379310345 ], [ 29.806383797468357, -1.446735862068966 ], [ 29.806653291139241, -1.446735862068966 ], [ 29.806653291139241, -1.446466379310345 ], [ 29.806383797468357, -1.446466379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18516, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.74817316455696, -1.446735862068966 ], [ 29.74817316455696, -1.447005344827586 ], [ 29.748442658227848, -1.447005344827586 ], [ 29.748442658227848, -1.446735862068966 ], [ 29.74817316455696, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18517, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.446735862068966 ], [ 29.748442658227848, -1.447005344827586 ], [ 29.748712151898733, -1.447005344827586 ], [ 29.748712151898733, -1.446735862068966 ], [ 29.748442658227848, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18518, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748712151898733, -1.446735862068966 ], [ 29.748712151898733, -1.447005344827586 ], [ 29.748981645569618, -1.447005344827586 ], [ 29.748981645569618, -1.446735862068966 ], [ 29.748712151898733, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18519, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.446735862068966 ], [ 29.748981645569618, -1.447005344827586 ], [ 29.749251139240506, -1.447005344827586 ], [ 29.749251139240506, -1.446735862068966 ], [ 29.748981645569618, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18520, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.446735862068966 ], [ 29.749251139240506, -1.447005344827586 ], [ 29.749520632911391, -1.447005344827586 ], [ 29.749520632911391, -1.446735862068966 ], [ 29.749251139240506, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18521, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.446735862068966 ], [ 29.749520632911391, -1.447005344827586 ], [ 29.749790126582276, -1.447005344827586 ], [ 29.749790126582276, -1.446735862068966 ], [ 29.749520632911391, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18522, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.446735862068966 ], [ 29.749790126582276, -1.447005344827586 ], [ 29.750059620253165, -1.447005344827586 ], [ 29.750059620253165, -1.446735862068966 ], [ 29.749790126582276, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18523, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.446735862068966 ], [ 29.750059620253165, -1.447005344827586 ], [ 29.75032911392405, -1.447005344827586 ], [ 29.75032911392405, -1.446735862068966 ], [ 29.750059620253165, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18524, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.446735862068966 ], [ 29.75032911392405, -1.447005344827586 ], [ 29.750598607594934, -1.447005344827586 ], [ 29.750598607594934, -1.446735862068966 ], [ 29.75032911392405, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18525, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.446735862068966 ], [ 29.750598607594934, -1.447005344827586 ], [ 29.750868101265823, -1.447005344827586 ], [ 29.750868101265823, -1.446735862068966 ], [ 29.750598607594934, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18526, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.446735862068966 ], [ 29.750868101265823, -1.447005344827586 ], [ 29.751137594936708, -1.447005344827586 ], [ 29.751137594936708, -1.446735862068966 ], [ 29.750868101265823, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18527, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.446735862068966 ], [ 29.751137594936708, -1.447005344827586 ], [ 29.751407088607593, -1.447005344827586 ], [ 29.751407088607593, -1.446735862068966 ], [ 29.751137594936708, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18528, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.446735862068966 ], [ 29.751407088607593, -1.447005344827586 ], [ 29.751676582278481, -1.447005344827586 ], [ 29.751676582278481, -1.446735862068966 ], [ 29.751407088607593, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18529, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.446735862068966 ], [ 29.751676582278481, -1.447005344827586 ], [ 29.751946075949366, -1.447005344827586 ], [ 29.751946075949366, -1.446735862068966 ], [ 29.751676582278481, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18530, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.446735862068966 ], [ 29.751946075949366, -1.447005344827586 ], [ 29.752215569620251, -1.447005344827586 ], [ 29.752215569620251, -1.446735862068966 ], [ 29.751946075949366, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18531, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.446735862068966 ], [ 29.752215569620251, -1.447005344827586 ], [ 29.75248506329114, -1.447005344827586 ], [ 29.75248506329114, -1.446735862068966 ], [ 29.752215569620251, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18532, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.446735862068966 ], [ 29.75248506329114, -1.447005344827586 ], [ 29.752754556962024, -1.447005344827586 ], [ 29.752754556962024, -1.446735862068966 ], [ 29.75248506329114, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18533, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.446735862068966 ], [ 29.752754556962024, -1.447005344827586 ], [ 29.753024050632909, -1.447005344827586 ], [ 29.753024050632909, -1.446735862068966 ], [ 29.752754556962024, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18534, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.446735862068966 ], [ 29.753024050632909, -1.447005344827586 ], [ 29.753293544303798, -1.447005344827586 ], [ 29.753293544303798, -1.446735862068966 ], [ 29.753024050632909, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18535, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.446735862068966 ], [ 29.753293544303798, -1.447005344827586 ], [ 29.753563037974683, -1.447005344827586 ], [ 29.753563037974683, -1.446735862068966 ], [ 29.753293544303798, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18536, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.446735862068966 ], [ 29.753563037974683, -1.447005344827586 ], [ 29.753832531645568, -1.447005344827586 ], [ 29.753832531645568, -1.446735862068966 ], [ 29.753563037974683, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18537, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.446735862068966 ], [ 29.753832531645568, -1.447005344827586 ], [ 29.754102025316456, -1.447005344827586 ], [ 29.754102025316456, -1.446735862068966 ], [ 29.753832531645568, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18538, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.446735862068966 ], [ 29.754102025316456, -1.447005344827586 ], [ 29.754371518987341, -1.447005344827586 ], [ 29.754371518987341, -1.446735862068966 ], [ 29.754102025316456, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18539, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.446735862068966 ], [ 29.754371518987341, -1.447005344827586 ], [ 29.754641012658226, -1.447005344827586 ], [ 29.754641012658226, -1.446735862068966 ], [ 29.754371518987341, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18540, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.446735862068966 ], [ 29.754641012658226, -1.447005344827586 ], [ 29.754910506329114, -1.447005344827586 ], [ 29.754910506329114, -1.446735862068966 ], [ 29.754641012658226, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18541, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.446735862068966 ], [ 29.754910506329114, -1.447005344827586 ], [ 29.75518, -1.447005344827586 ], [ 29.75518, -1.446735862068966 ], [ 29.754910506329114, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18542, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.446735862068966 ], [ 29.75518, -1.447005344827586 ], [ 29.755449493670884, -1.447005344827586 ], [ 29.755449493670884, -1.446735862068966 ], [ 29.75518, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18543, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.446735862068966 ], [ 29.755449493670884, -1.447005344827586 ], [ 29.755718987341773, -1.447005344827586 ], [ 29.755718987341773, -1.446735862068966 ], [ 29.755449493670884, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18544, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.446735862068966 ], [ 29.755718987341773, -1.447005344827586 ], [ 29.755988481012658, -1.447005344827586 ], [ 29.755988481012658, -1.446735862068966 ], [ 29.755718987341773, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18545, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.446735862068966 ], [ 29.755988481012658, -1.447005344827586 ], [ 29.756257974683542, -1.447005344827586 ], [ 29.756257974683542, -1.446735862068966 ], [ 29.755988481012658, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18546, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.446735862068966 ], [ 29.756257974683542, -1.447274827586207 ], [ 29.756796962025316, -1.447274827586207 ], [ 29.756796962025316, -1.446735862068966 ], [ 29.756257974683542, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18547, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.446735862068966 ], [ 29.756796962025316, -1.447005344827586 ], [ 29.757066455696201, -1.447005344827586 ], [ 29.757066455696201, -1.446735862068966 ], [ 29.756796962025316, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18548, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.446735862068966 ], [ 29.757066455696201, -1.447005344827586 ], [ 29.757335949367089, -1.447005344827586 ], [ 29.757335949367089, -1.446735862068966 ], [ 29.757066455696201, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18549, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.446735862068966 ], [ 29.757335949367089, -1.447005344827586 ], [ 29.757605443037974, -1.447005344827586 ], [ 29.757605443037974, -1.446735862068966 ], [ 29.757335949367089, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18550, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.446735862068966 ], [ 29.757605443037974, -1.447005344827586 ], [ 29.757874936708859, -1.447005344827586 ], [ 29.757874936708859, -1.446735862068966 ], [ 29.757605443037974, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18551, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.446735862068966 ], [ 29.757874936708859, -1.447005344827586 ], [ 29.758144430379748, -1.447005344827586 ], [ 29.758144430379748, -1.446735862068966 ], [ 29.757874936708859, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18552, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.446735862068966 ], [ 29.758144430379748, -1.447005344827586 ], [ 29.758413924050632, -1.447005344827586 ], [ 29.758413924050632, -1.446735862068966 ], [ 29.758144430379748, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18553, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.446735862068966 ], [ 29.758413924050632, -1.447005344827586 ], [ 29.758683417721517, -1.447005344827586 ], [ 29.758683417721517, -1.446735862068966 ], [ 29.758413924050632, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18554, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.446735862068966 ], [ 29.758683417721517, -1.447005344827586 ], [ 29.758952911392406, -1.447005344827586 ], [ 29.758952911392406, -1.446735862068966 ], [ 29.758683417721517, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18555, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.446735862068966 ], [ 29.758952911392406, -1.447005344827586 ], [ 29.759222405063291, -1.447005344827586 ], [ 29.759222405063291, -1.446735862068966 ], [ 29.758952911392406, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18556, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.446735862068966 ], [ 29.759222405063291, -1.447005344827586 ], [ 29.759491898734176, -1.447005344827586 ], [ 29.759491898734176, -1.446735862068966 ], [ 29.759222405063291, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18557, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.446735862068966 ], [ 29.759491898734176, -1.447005344827586 ], [ 29.759761392405064, -1.447005344827586 ], [ 29.759761392405064, -1.446735862068966 ], [ 29.759491898734176, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18558, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.446735862068966 ], [ 29.759761392405064, -1.447005344827586 ], [ 29.760030886075949, -1.447005344827586 ], [ 29.760030886075949, -1.446735862068966 ], [ 29.759761392405064, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18559, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.446735862068966 ], [ 29.760030886075949, -1.447005344827586 ], [ 29.760300379746834, -1.447005344827586 ], [ 29.760300379746834, -1.446735862068966 ], [ 29.760030886075949, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18560, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.446735862068966 ], [ 29.760300379746834, -1.447005344827586 ], [ 29.760569873417722, -1.447005344827586 ], [ 29.760569873417722, -1.446735862068966 ], [ 29.760300379746834, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18561, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.446735862068966 ], [ 29.760569873417722, -1.447005344827586 ], [ 29.760839367088607, -1.447005344827586 ], [ 29.760839367088607, -1.446735862068966 ], [ 29.760569873417722, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18562, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.446735862068966 ], [ 29.760839367088607, -1.447005344827586 ], [ 29.761108860759492, -1.447005344827586 ], [ 29.761108860759492, -1.446735862068966 ], [ 29.760839367088607, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18563, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.446735862068966 ], [ 29.761108860759492, -1.447005344827586 ], [ 29.761378354430381, -1.447005344827586 ], [ 29.761378354430381, -1.446735862068966 ], [ 29.761108860759492, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18564, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.446735862068966 ], [ 29.761378354430381, -1.447005344827586 ], [ 29.761647848101266, -1.447005344827586 ], [ 29.761647848101266, -1.446735862068966 ], [ 29.761378354430381, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18565, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.446735862068966 ], [ 29.761647848101266, -1.447005344827586 ], [ 29.761917341772151, -1.447005344827586 ], [ 29.761917341772151, -1.446735862068966 ], [ 29.761647848101266, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18566, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.446735862068966 ], [ 29.761917341772151, -1.447005344827586 ], [ 29.762186835443039, -1.447005344827586 ], [ 29.762186835443039, -1.446735862068966 ], [ 29.761917341772151, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18567, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.446735862068966 ], [ 29.762186835443039, -1.447005344827586 ], [ 29.762995316455697, -1.447005344827586 ], [ 29.762995316455697, -1.446735862068966 ], [ 29.762186835443039, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18568, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.446735862068966 ], [ 29.762995316455697, -1.447005344827586 ], [ 29.763264810126582, -1.447005344827586 ], [ 29.763264810126582, -1.446735862068966 ], [ 29.762995316455697, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18569, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.446735862068966 ], [ 29.763264810126582, -1.447005344827586 ], [ 29.763534303797467, -1.447005344827586 ], [ 29.763534303797467, -1.446735862068966 ], [ 29.763264810126582, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18570, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.446735862068966 ], [ 29.763534303797467, -1.447005344827586 ], [ 29.76407329113924, -1.447005344827586 ], [ 29.76407329113924, -1.446735862068966 ], [ 29.763534303797467, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18571, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.446735862068966 ], [ 29.76407329113924, -1.447005344827586 ], [ 29.764342784810125, -1.447005344827586 ], [ 29.764342784810125, -1.446735862068966 ], [ 29.76407329113924, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18572, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.446735862068966 ], [ 29.764342784810125, -1.447005344827586 ], [ 29.764612278481014, -1.447005344827586 ], [ 29.764612278481014, -1.446735862068966 ], [ 29.764342784810125, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18573, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.446735862068966 ], [ 29.764612278481014, -1.447005344827586 ], [ 29.764881772151899, -1.447005344827586 ], [ 29.764881772151899, -1.446735862068966 ], [ 29.764612278481014, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18574, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.446735862068966 ], [ 29.764881772151899, -1.447005344827586 ], [ 29.765151265822784, -1.447005344827586 ], [ 29.765151265822784, -1.446735862068966 ], [ 29.764881772151899, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18575, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.446735862068966 ], [ 29.765151265822784, -1.447005344827586 ], [ 29.765420759493672, -1.447005344827586 ], [ 29.765420759493672, -1.446735862068966 ], [ 29.765151265822784, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18576, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.446735862068966 ], [ 29.765420759493672, -1.447005344827586 ], [ 29.767846202531643, -1.447005344827586 ], [ 29.767846202531643, -1.446735862068966 ], [ 29.765420759493672, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18577, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.446735862068966 ], [ 29.767846202531643, -1.447005344827586 ], [ 29.768115696202532, -1.447005344827586 ], [ 29.768115696202532, -1.446735862068966 ], [ 29.767846202531643, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18578, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.446735862068966 ], [ 29.768115696202532, -1.447005344827586 ], [ 29.768385189873417, -1.447005344827586 ], [ 29.768385189873417, -1.446735862068966 ], [ 29.768115696202532, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18579, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.446735862068966 ], [ 29.768385189873417, -1.447005344827586 ], [ 29.768654683544302, -1.447005344827586 ], [ 29.768654683544302, -1.446735862068966 ], [ 29.768385189873417, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18580, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.446735862068966 ], [ 29.768654683544302, -1.447005344827586 ], [ 29.76892417721519, -1.447005344827586 ], [ 29.76892417721519, -1.446735862068966 ], [ 29.768654683544302, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18581, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.446735862068966 ], [ 29.76892417721519, -1.447005344827586 ], [ 29.769193670886075, -1.447005344827586 ], [ 29.769193670886075, -1.446735862068966 ], [ 29.76892417721519, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18582, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.446735862068966 ], [ 29.769193670886075, -1.447005344827586 ], [ 29.76946316455696, -1.447005344827586 ], [ 29.76946316455696, -1.446735862068966 ], [ 29.769193670886075, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18583, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.446735862068966 ], [ 29.76946316455696, -1.447005344827586 ], [ 29.769732658227849, -1.447005344827586 ], [ 29.769732658227849, -1.446735862068966 ], [ 29.76946316455696, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18584, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.446735862068966 ], [ 29.769732658227849, -1.447005344827586 ], [ 29.770002151898733, -1.447005344827586 ], [ 29.770002151898733, -1.446735862068966 ], [ 29.769732658227849, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18585, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.446735862068966 ], [ 29.770002151898733, -1.447005344827586 ], [ 29.770271645569618, -1.447005344827586 ], [ 29.770271645569618, -1.446735862068966 ], [ 29.770002151898733, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18586, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.446735862068966 ], [ 29.770271645569618, -1.447005344827586 ], [ 29.770541139240507, -1.447005344827586 ], [ 29.770541139240507, -1.446735862068966 ], [ 29.770271645569618, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18587, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.446735862068966 ], [ 29.770541139240507, -1.447005344827586 ], [ 29.770810632911392, -1.447005344827586 ], [ 29.770810632911392, -1.446735862068966 ], [ 29.770541139240507, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18588, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.446735862068966 ], [ 29.770810632911392, -1.447005344827586 ], [ 29.771080126582277, -1.447005344827586 ], [ 29.771080126582277, -1.446735862068966 ], [ 29.770810632911392, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18589, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.446735862068966 ], [ 29.771080126582277, -1.447005344827586 ], [ 29.771349620253165, -1.447005344827586 ], [ 29.771349620253165, -1.446735862068966 ], [ 29.771080126582277, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18590, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.446735862068966 ], [ 29.771349620253165, -1.447005344827586 ], [ 29.77161911392405, -1.447005344827586 ], [ 29.77161911392405, -1.446735862068966 ], [ 29.771349620253165, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18591, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.446735862068966 ], [ 29.77161911392405, -1.447005344827586 ], [ 29.771888607594935, -1.447005344827586 ], [ 29.771888607594935, -1.446735862068966 ], [ 29.77161911392405, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18592, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.446735862068966 ], [ 29.771888607594935, -1.447005344827586 ], [ 29.772158101265823, -1.447005344827586 ], [ 29.772158101265823, -1.446735862068966 ], [ 29.771888607594935, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18593, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.446735862068966 ], [ 29.772427594936708, -1.447005344827586 ], [ 29.772697088607593, -1.447005344827586 ], [ 29.772697088607593, -1.446735862068966 ], [ 29.772427594936708, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18594, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.446735862068966 ], [ 29.772697088607593, -1.447005344827586 ], [ 29.772966582278482, -1.447005344827586 ], [ 29.772966582278482, -1.446735862068966 ], [ 29.772697088607593, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18595, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.446735862068966 ], [ 29.772966582278482, -1.447274827586207 ], [ 29.773236075949367, -1.447274827586207 ], [ 29.773236075949367, -1.446735862068966 ], [ 29.772966582278482, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18596, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.446735862068966 ], [ 29.773236075949367, -1.447005344827586 ], [ 29.773505569620252, -1.447005344827586 ], [ 29.773505569620252, -1.446735862068966 ], [ 29.773236075949367, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18597, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.446735862068966 ], [ 29.773505569620252, -1.447005344827586 ], [ 29.77377506329114, -1.447005344827586 ], [ 29.77377506329114, -1.446735862068966 ], [ 29.773505569620252, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18598, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.446735862068966 ], [ 29.77377506329114, -1.447274827586207 ], [ 29.774044556962025, -1.447274827586207 ], [ 29.774044556962025, -1.446735862068966 ], [ 29.77377506329114, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18599, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.446735862068966 ], [ 29.77431405063291, -1.447005344827586 ], [ 29.774853037974683, -1.447005344827586 ], [ 29.774853037974683, -1.446735862068966 ], [ 29.77431405063291, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18600, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.446735862068966 ], [ 29.774853037974683, -1.447274827586207 ], [ 29.775122531645568, -1.447274827586207 ], [ 29.775122531645568, -1.446735862068966 ], [ 29.774853037974683, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18601, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.446735862068966 ], [ 29.775392025316457, -1.447274827586207 ], [ 29.775661518987341, -1.447274827586207 ], [ 29.775661518987341, -1.446735862068966 ], [ 29.775392025316457, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18602, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.446735862068966 ], [ 29.775661518987341, -1.447274827586207 ], [ 29.775931012658226, -1.447274827586207 ], [ 29.775931012658226, -1.446735862068966 ], [ 29.775661518987341, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18603, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.446735862068966 ], [ 29.776200506329115, -1.447274827586207 ], [ 29.77647, -1.447274827586207 ], [ 29.77647, -1.446735862068966 ], [ 29.776200506329115, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18604, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.446735862068966 ], [ 29.77647, -1.447274827586207 ], [ 29.776739493670885, -1.447274827586207 ], [ 29.776739493670885, -1.446735862068966 ], [ 29.77647, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18605, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.446735862068966 ], [ 29.777008987341773, -1.447274827586207 ], [ 29.777278481012658, -1.447274827586207 ], [ 29.777278481012658, -1.446735862068966 ], [ 29.777008987341773, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18606, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.446735862068966 ], [ 29.777278481012658, -1.447005344827586 ], [ 29.777547974683543, -1.447005344827586 ], [ 29.777547974683543, -1.446735862068966 ], [ 29.777278481012658, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18607, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.446735862068966 ], [ 29.777547974683543, -1.447005344827586 ], [ 29.777817468354431, -1.447005344827586 ], [ 29.777817468354431, -1.446735862068966 ], [ 29.777547974683543, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18608, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.446735862068966 ], [ 29.777817468354431, -1.447005344827586 ], [ 29.778086962025316, -1.447005344827586 ], [ 29.778086962025316, -1.446735862068966 ], [ 29.777817468354431, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18609, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.446735862068966 ], [ 29.778086962025316, -1.447005344827586 ], [ 29.778356455696201, -1.447005344827586 ], [ 29.778356455696201, -1.446735862068966 ], [ 29.778086962025316, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18610, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.446735862068966 ], [ 29.778356455696201, -1.447005344827586 ], [ 29.77862594936709, -1.447005344827586 ], [ 29.77862594936709, -1.446735862068966 ], [ 29.778356455696201, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18611, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.446735862068966 ], [ 29.77862594936709, -1.447005344827586 ], [ 29.778895443037975, -1.447005344827586 ], [ 29.778895443037975, -1.446735862068966 ], [ 29.77862594936709, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18612, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.446735862068966 ], [ 29.778895443037975, -1.447005344827586 ], [ 29.77916493670886, -1.447005344827586 ], [ 29.77916493670886, -1.446735862068966 ], [ 29.778895443037975, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18613, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.446735862068966 ], [ 29.77916493670886, -1.447005344827586 ], [ 29.779434430379748, -1.447005344827586 ], [ 29.779434430379748, -1.446735862068966 ], [ 29.77916493670886, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18614, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.446735862068966 ], [ 29.779434430379748, -1.447005344827586 ], [ 29.779703924050633, -1.447005344827586 ], [ 29.779703924050633, -1.446735862068966 ], [ 29.779434430379748, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18615, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.446735862068966 ], [ 29.779703924050633, -1.447005344827586 ], [ 29.779973417721518, -1.447005344827586 ], [ 29.779973417721518, -1.446735862068966 ], [ 29.779703924050633, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18616, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.446735862068966 ], [ 29.779973417721518, -1.447005344827586 ], [ 29.780242911392406, -1.447005344827586 ], [ 29.780242911392406, -1.446735862068966 ], [ 29.779973417721518, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18617, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.446735862068966 ], [ 29.780242911392406, -1.447005344827586 ], [ 29.780512405063291, -1.447005344827586 ], [ 29.780512405063291, -1.446735862068966 ], [ 29.780242911392406, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18618, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.446735862068966 ], [ 29.780512405063291, -1.447005344827586 ], [ 29.780781898734176, -1.447005344827586 ], [ 29.780781898734176, -1.446735862068966 ], [ 29.780512405063291, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18619, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.446735862068966 ], [ 29.780781898734176, -1.447005344827586 ], [ 29.781051392405065, -1.447005344827586 ], [ 29.781051392405065, -1.446735862068966 ], [ 29.780781898734176, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18620, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.446735862068966 ], [ 29.781051392405065, -1.447005344827586 ], [ 29.78132088607595, -1.447005344827586 ], [ 29.78132088607595, -1.446735862068966 ], [ 29.781051392405065, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18621, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.446735862068966 ], [ 29.78132088607595, -1.447005344827586 ], [ 29.781590379746834, -1.447005344827586 ], [ 29.781590379746834, -1.446735862068966 ], [ 29.78132088607595, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18622, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.446735862068966 ], [ 29.781590379746834, -1.447005344827586 ], [ 29.781859873417723, -1.447005344827586 ], [ 29.781859873417723, -1.446735862068966 ], [ 29.781590379746834, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18623, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.446735862068966 ], [ 29.781859873417723, -1.447544310344828 ], [ 29.782129367088608, -1.447544310344828 ], [ 29.782129367088608, -1.446735862068966 ], [ 29.781859873417723, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18624, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.446735862068966 ], [ 29.782129367088608, -1.447274827586207 ], [ 29.782398860759493, -1.447274827586207 ], [ 29.782398860759493, -1.446735862068966 ], [ 29.782129367088608, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18625, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.446735862068966 ], [ 29.782398860759493, -1.447274827586207 ], [ 29.782668354430381, -1.447274827586207 ], [ 29.782668354430381, -1.446735862068966 ], [ 29.782398860759493, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18626, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.446735862068966 ], [ 29.782668354430381, -1.447005344827586 ], [ 29.782937848101266, -1.447005344827586 ], [ 29.782937848101266, -1.446735862068966 ], [ 29.782668354430381, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18627, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.446735862068966 ], [ 29.782937848101266, -1.447005344827586 ], [ 29.783207341772151, -1.447005344827586 ], [ 29.783207341772151, -1.446735862068966 ], [ 29.782937848101266, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18628, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.446735862068966 ], [ 29.783207341772151, -1.447005344827586 ], [ 29.783476835443039, -1.447005344827586 ], [ 29.783476835443039, -1.446735862068966 ], [ 29.783207341772151, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18629, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.446735862068966 ], [ 29.783476835443039, -1.447005344827586 ], [ 29.784015822784809, -1.447005344827586 ], [ 29.784015822784809, -1.446735862068966 ], [ 29.783476835443039, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18630, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.446735862068966 ], [ 29.784824303797468, -1.447005344827586 ], [ 29.785093797468356, -1.447005344827586 ], [ 29.785093797468356, -1.446735862068966 ], [ 29.784824303797468, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18631, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.446735862068966 ], [ 29.785093797468356, -1.447005344827586 ], [ 29.785363291139241, -1.447005344827586 ], [ 29.785363291139241, -1.446735862068966 ], [ 29.785093797468356, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18632, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.446735862068966 ], [ 29.785363291139241, -1.447005344827586 ], [ 29.785632784810126, -1.447005344827586 ], [ 29.785632784810126, -1.446735862068966 ], [ 29.785363291139241, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18633, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.446735862068966 ], [ 29.785632784810126, -1.447005344827586 ], [ 29.785902278481014, -1.447005344827586 ], [ 29.785902278481014, -1.446735862068966 ], [ 29.785632784810126, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18634, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.446735862068966 ], [ 29.785902278481014, -1.447005344827586 ], [ 29.786171772151899, -1.447005344827586 ], [ 29.786171772151899, -1.446735862068966 ], [ 29.785902278481014, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18635, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.446735862068966 ], [ 29.786171772151899, -1.447005344827586 ], [ 29.786441265822784, -1.447005344827586 ], [ 29.786441265822784, -1.446735862068966 ], [ 29.786171772151899, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18636, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.446735862068966 ], [ 29.786441265822784, -1.447005344827586 ], [ 29.786710759493673, -1.447005344827586 ], [ 29.786710759493673, -1.446735862068966 ], [ 29.786441265822784, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18637, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.446735862068966 ], [ 29.786710759493673, -1.447005344827586 ], [ 29.786980253164558, -1.447005344827586 ], [ 29.786980253164558, -1.446735862068966 ], [ 29.786710759493673, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18638, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.446735862068966 ], [ 29.786980253164558, -1.447005344827586 ], [ 29.787249746835442, -1.447005344827586 ], [ 29.787249746835442, -1.446735862068966 ], [ 29.786980253164558, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18639, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.446735862068966 ], [ 29.787249746835442, -1.447005344827586 ], [ 29.787519240506327, -1.447005344827586 ], [ 29.787519240506327, -1.446735862068966 ], [ 29.787249746835442, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18640, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.446735862068966 ], [ 29.787519240506327, -1.447005344827586 ], [ 29.787788734177216, -1.447005344827586 ], [ 29.787788734177216, -1.446735862068966 ], [ 29.787519240506327, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18641, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.446735862068966 ], [ 29.787788734177216, -1.447005344827586 ], [ 29.788058227848101, -1.447005344827586 ], [ 29.788058227848101, -1.446735862068966 ], [ 29.787788734177216, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18642, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.446735862068966 ], [ 29.789944683544302, -1.447005344827586 ], [ 29.790214177215191, -1.447005344827586 ], [ 29.790214177215191, -1.446735862068966 ], [ 29.789944683544302, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18643, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.446735862068966 ], [ 29.790214177215191, -1.447005344827586 ], [ 29.790483670886076, -1.447005344827586 ], [ 29.790483670886076, -1.446735862068966 ], [ 29.790214177215191, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18644, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.446735862068966 ], [ 29.790483670886076, -1.447005344827586 ], [ 29.790753164556961, -1.447005344827586 ], [ 29.790753164556961, -1.446735862068966 ], [ 29.790483670886076, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18645, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.446735862068966 ], [ 29.790753164556961, -1.447005344827586 ], [ 29.791022658227849, -1.447005344827586 ], [ 29.791022658227849, -1.446735862068966 ], [ 29.790753164556961, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18646, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.446735862068966 ], [ 29.791022658227849, -1.447005344827586 ], [ 29.791292151898734, -1.447005344827586 ], [ 29.791292151898734, -1.446735862068966 ], [ 29.791022658227849, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18647, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.446735862068966 ], [ 29.791292151898734, -1.447005344827586 ], [ 29.791561645569619, -1.447005344827586 ], [ 29.791561645569619, -1.446735862068966 ], [ 29.791292151898734, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18648, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.446735862068966 ], [ 29.791561645569619, -1.447274827586207 ], [ 29.791831139240507, -1.447274827586207 ], [ 29.791831139240507, -1.446735862068966 ], [ 29.791561645569619, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18649, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.446735862068966 ], [ 29.791831139240507, -1.447005344827586 ], [ 29.792100632911392, -1.447005344827586 ], [ 29.792100632911392, -1.446735862068966 ], [ 29.791831139240507, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18650, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.446735862068966 ], [ 29.792100632911392, -1.447005344827586 ], [ 29.792370126582277, -1.447005344827586 ], [ 29.792370126582277, -1.446735862068966 ], [ 29.792100632911392, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18651, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.446735862068966 ], [ 29.793178607594935, -1.447274827586207 ], [ 29.793448101265824, -1.447274827586207 ], [ 29.793448101265824, -1.446735862068966 ], [ 29.793178607594935, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18652, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.446735862068966 ], [ 29.794256582278482, -1.447005344827586 ], [ 29.794526075949367, -1.447005344827586 ], [ 29.794526075949367, -1.446735862068966 ], [ 29.794256582278482, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18653, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.446735862068966 ], [ 29.794526075949367, -1.447005344827586 ], [ 29.794795569620252, -1.447005344827586 ], [ 29.794795569620252, -1.446735862068966 ], [ 29.794526075949367, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18654, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.446735862068966 ], [ 29.79506506329114, -1.447005344827586 ], [ 29.795334556962025, -1.447005344827586 ], [ 29.795334556962025, -1.446735862068966 ], [ 29.79506506329114, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18655, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.446735862068966 ], [ 29.795334556962025, -1.447005344827586 ], [ 29.79560405063291, -1.447005344827586 ], [ 29.79560405063291, -1.446735862068966 ], [ 29.795334556962025, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18656, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.446735862068966 ], [ 29.79560405063291, -1.447005344827586 ], [ 29.795873544303799, -1.447005344827586 ], [ 29.795873544303799, -1.446735862068966 ], [ 29.79560405063291, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18657, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.446735862068966 ], [ 29.795873544303799, -1.447005344827586 ], [ 29.796143037974684, -1.447005344827586 ], [ 29.796143037974684, -1.446735862068966 ], [ 29.795873544303799, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18658, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.446735862068966 ], [ 29.796143037974684, -1.447005344827586 ], [ 29.796412531645569, -1.447005344827586 ], [ 29.796412531645569, -1.446735862068966 ], [ 29.796143037974684, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18659, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.446735862068966 ], [ 29.796412531645569, -1.447005344827586 ], [ 29.796682025316457, -1.447005344827586 ], [ 29.796682025316457, -1.446735862068966 ], [ 29.796412531645569, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18660, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.446735862068966 ], [ 29.796682025316457, -1.447005344827586 ], [ 29.796951518987342, -1.447005344827586 ], [ 29.796951518987342, -1.446735862068966 ], [ 29.796682025316457, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18661, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.446735862068966 ], [ 29.796951518987342, -1.447005344827586 ], [ 29.797221012658227, -1.447005344827586 ], [ 29.797221012658227, -1.446735862068966 ], [ 29.796951518987342, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18662, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.446735862068966 ], [ 29.797221012658227, -1.447005344827586 ], [ 29.797490506329115, -1.447005344827586 ], [ 29.797490506329115, -1.446735862068966 ], [ 29.797221012658227, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18663, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.446735862068966 ], [ 29.797490506329115, -1.447005344827586 ], [ 29.79776, -1.447005344827586 ], [ 29.79776, -1.446735862068966 ], [ 29.797490506329115, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18664, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.446735862068966 ], [ 29.79776, -1.447274827586207 ], [ 29.798029493670885, -1.447274827586207 ], [ 29.798029493670885, -1.446735862068966 ], [ 29.79776, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18665, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.446735862068966 ], [ 29.801532911392407, -1.447005344827586 ], [ 29.802071898734177, -1.447005344827586 ], [ 29.802071898734177, -1.446735862068966 ], [ 29.801532911392407, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18666, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.446735862068966 ], [ 29.802071898734177, -1.447005344827586 ], [ 29.802341392405065, -1.447005344827586 ], [ 29.802341392405065, -1.446735862068966 ], [ 29.802071898734177, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18667, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.446735862068966 ], [ 29.802341392405065, -1.447005344827586 ], [ 29.80261088607595, -1.447005344827586 ], [ 29.80261088607595, -1.446735862068966 ], [ 29.802341392405065, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18668, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.446735862068966 ], [ 29.80261088607595, -1.447544310344828 ], [ 29.802880379746835, -1.447544310344828 ], [ 29.802880379746835, -1.446735862068966 ], [ 29.80261088607595, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18669, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.446735862068966 ], [ 29.802880379746835, -1.447544310344828 ], [ 29.803149873417723, -1.447544310344828 ], [ 29.803149873417723, -1.446735862068966 ], [ 29.802880379746835, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18670, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.446735862068966 ], [ 29.803419367088608, -1.447274827586207 ], [ 29.803688860759493, -1.447274827586207 ], [ 29.803688860759493, -1.446735862068966 ], [ 29.803419367088608, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18671, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.446735862068966 ], [ 29.803688860759493, -1.447274827586207 ], [ 29.803958354430382, -1.447274827586207 ], [ 29.803958354430382, -1.446735862068966 ], [ 29.803688860759493, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18672, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.446735862068966 ], [ 29.804227848101267, -1.447005344827586 ], [ 29.804497341772151, -1.447005344827586 ], [ 29.804497341772151, -1.446735862068966 ], [ 29.804227848101267, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18673, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.446735862068966 ], [ 29.804497341772151, -1.447005344827586 ], [ 29.80476683544304, -1.447005344827586 ], [ 29.80476683544304, -1.446735862068966 ], [ 29.804497341772151, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18674, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.446735862068966 ], [ 29.80476683544304, -1.447005344827586 ], [ 29.805036329113925, -1.447005344827586 ], [ 29.805036329113925, -1.446735862068966 ], [ 29.80476683544304, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18675, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.446735862068966 ], [ 29.805036329113925, -1.447005344827586 ], [ 29.80530582278481, -1.447005344827586 ], [ 29.80530582278481, -1.446735862068966 ], [ 29.805036329113925, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18676, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.446735862068966 ], [ 29.80530582278481, -1.447005344827586 ], [ 29.805575316455698, -1.447005344827586 ], [ 29.805575316455698, -1.446735862068966 ], [ 29.80530582278481, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18677, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.446735862068966 ], [ 29.805575316455698, -1.447005344827586 ], [ 29.805844810126583, -1.447005344827586 ], [ 29.805844810126583, -1.446735862068966 ], [ 29.805575316455698, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18678, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.446735862068966 ], [ 29.805844810126583, -1.447005344827586 ], [ 29.806114303797468, -1.447005344827586 ], [ 29.806114303797468, -1.446735862068966 ], [ 29.805844810126583, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18679, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.446735862068966 ], [ 29.806114303797468, -1.447005344827586 ], [ 29.806383797468357, -1.447005344827586 ], [ 29.806383797468357, -1.446735862068966 ], [ 29.806114303797468, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18680, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.446735862068966 ], [ 29.806383797468357, -1.447005344827586 ], [ 29.806653291139241, -1.447005344827586 ], [ 29.806653291139241, -1.446735862068966 ], [ 29.806383797468357, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18681, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.446735862068966 ], [ 29.806653291139241, -1.447005344827586 ], [ 29.806922784810126, -1.447005344827586 ], [ 29.806922784810126, -1.446735862068966 ], [ 29.806653291139241, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18682, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.446735862068966 ], [ 29.806922784810126, -1.447005344827586 ], [ 29.807192278481015, -1.447005344827586 ], [ 29.807192278481015, -1.446735862068966 ], [ 29.806922784810126, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18683, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748442658227848, -1.447005344827586 ], [ 29.748442658227848, -1.447274827586207 ], [ 29.748981645569618, -1.447274827586207 ], [ 29.748981645569618, -1.447005344827586 ], [ 29.748442658227848, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18684, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.447005344827586 ], [ 29.748981645569618, -1.447274827586207 ], [ 29.749251139240506, -1.447274827586207 ], [ 29.749251139240506, -1.447005344827586 ], [ 29.748981645569618, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18685, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.447005344827586 ], [ 29.749251139240506, -1.447274827586207 ], [ 29.749520632911391, -1.447274827586207 ], [ 29.749520632911391, -1.447005344827586 ], [ 29.749251139240506, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18686, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.447005344827586 ], [ 29.749520632911391, -1.447274827586207 ], [ 29.749790126582276, -1.447274827586207 ], [ 29.749790126582276, -1.447005344827586 ], [ 29.749520632911391, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18687, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.447005344827586 ], [ 29.749790126582276, -1.447274827586207 ], [ 29.750059620253165, -1.447274827586207 ], [ 29.750059620253165, -1.447005344827586 ], [ 29.749790126582276, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18688, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.447005344827586 ], [ 29.750059620253165, -1.447274827586207 ], [ 29.75032911392405, -1.447274827586207 ], [ 29.75032911392405, -1.447005344827586 ], [ 29.750059620253165, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18689, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.447005344827586 ], [ 29.75032911392405, -1.447274827586207 ], [ 29.750598607594934, -1.447274827586207 ], [ 29.750598607594934, -1.447005344827586 ], [ 29.75032911392405, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18690, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.447005344827586 ], [ 29.750598607594934, -1.447274827586207 ], [ 29.750868101265823, -1.447274827586207 ], [ 29.750868101265823, -1.447005344827586 ], [ 29.750598607594934, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18691, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.447005344827586 ], [ 29.750868101265823, -1.447274827586207 ], [ 29.751137594936708, -1.447274827586207 ], [ 29.751137594936708, -1.447005344827586 ], [ 29.750868101265823, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18692, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.447005344827586 ], [ 29.751137594936708, -1.447274827586207 ], [ 29.751407088607593, -1.447274827586207 ], [ 29.751407088607593, -1.447005344827586 ], [ 29.751137594936708, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18693, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.447005344827586 ], [ 29.751407088607593, -1.447274827586207 ], [ 29.751676582278481, -1.447274827586207 ], [ 29.751676582278481, -1.447005344827586 ], [ 29.751407088607593, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18694, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.447005344827586 ], [ 29.751676582278481, -1.447274827586207 ], [ 29.751946075949366, -1.447274827586207 ], [ 29.751946075949366, -1.447005344827586 ], [ 29.751676582278481, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18695, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.447005344827586 ], [ 29.751946075949366, -1.447274827586207 ], [ 29.752215569620251, -1.447274827586207 ], [ 29.752215569620251, -1.447005344827586 ], [ 29.751946075949366, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18696, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.447005344827586 ], [ 29.752215569620251, -1.447274827586207 ], [ 29.75248506329114, -1.447274827586207 ], [ 29.75248506329114, -1.447005344827586 ], [ 29.752215569620251, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18697, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.447005344827586 ], [ 29.75248506329114, -1.447274827586207 ], [ 29.752754556962024, -1.447274827586207 ], [ 29.752754556962024, -1.447005344827586 ], [ 29.75248506329114, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18698, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.447005344827586 ], [ 29.752754556962024, -1.447274827586207 ], [ 29.753024050632909, -1.447274827586207 ], [ 29.753024050632909, -1.447005344827586 ], [ 29.752754556962024, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18699, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.447005344827586 ], [ 29.753024050632909, -1.447274827586207 ], [ 29.753293544303798, -1.447274827586207 ], [ 29.753293544303798, -1.447005344827586 ], [ 29.753024050632909, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18700, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.447005344827586 ], [ 29.753293544303798, -1.447274827586207 ], [ 29.753563037974683, -1.447274827586207 ], [ 29.753563037974683, -1.447005344827586 ], [ 29.753293544303798, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18701, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.447005344827586 ], [ 29.753563037974683, -1.447274827586207 ], [ 29.753832531645568, -1.447274827586207 ], [ 29.753832531645568, -1.447005344827586 ], [ 29.753563037974683, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18702, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.447005344827586 ], [ 29.753832531645568, -1.447274827586207 ], [ 29.754102025316456, -1.447274827586207 ], [ 29.754102025316456, -1.447005344827586 ], [ 29.753832531645568, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18703, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.447005344827586 ], [ 29.754102025316456, -1.447274827586207 ], [ 29.754371518987341, -1.447274827586207 ], [ 29.754371518987341, -1.447005344827586 ], [ 29.754102025316456, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18704, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.447005344827586 ], [ 29.754371518987341, -1.447274827586207 ], [ 29.754641012658226, -1.447274827586207 ], [ 29.754641012658226, -1.447005344827586 ], [ 29.754371518987341, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18705, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.447005344827586 ], [ 29.754641012658226, -1.447274827586207 ], [ 29.754910506329114, -1.447274827586207 ], [ 29.754910506329114, -1.447005344827586 ], [ 29.754641012658226, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18706, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.447005344827586 ], [ 29.754910506329114, -1.447274827586207 ], [ 29.75518, -1.447274827586207 ], [ 29.75518, -1.447005344827586 ], [ 29.754910506329114, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18707, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.447005344827586 ], [ 29.75518, -1.447274827586207 ], [ 29.755449493670884, -1.447274827586207 ], [ 29.755449493670884, -1.447005344827586 ], [ 29.75518, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18708, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.447005344827586 ], [ 29.755449493670884, -1.447274827586207 ], [ 29.755718987341773, -1.447274827586207 ], [ 29.755718987341773, -1.447005344827586 ], [ 29.755449493670884, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18709, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.447005344827586 ], [ 29.755718987341773, -1.447274827586207 ], [ 29.755988481012658, -1.447274827586207 ], [ 29.755988481012658, -1.447005344827586 ], [ 29.755718987341773, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18710, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.447005344827586 ], [ 29.755988481012658, -1.447274827586207 ], [ 29.756257974683542, -1.447274827586207 ], [ 29.756257974683542, -1.447005344827586 ], [ 29.755988481012658, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18711, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.447005344827586 ], [ 29.756796962025316, -1.447274827586207 ], [ 29.757066455696201, -1.447274827586207 ], [ 29.757066455696201, -1.447005344827586 ], [ 29.756796962025316, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18712, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.447005344827586 ], [ 29.757066455696201, -1.447274827586207 ], [ 29.757335949367089, -1.447274827586207 ], [ 29.757335949367089, -1.447005344827586 ], [ 29.757066455696201, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18713, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.447005344827586 ], [ 29.757335949367089, -1.447274827586207 ], [ 29.757605443037974, -1.447274827586207 ], [ 29.757605443037974, -1.447005344827586 ], [ 29.757335949367089, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18714, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.447005344827586 ], [ 29.757605443037974, -1.447544310344828 ], [ 29.757874936708859, -1.447544310344828 ], [ 29.757874936708859, -1.447005344827586 ], [ 29.757605443037974, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18715, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.447005344827586 ], [ 29.757874936708859, -1.447274827586207 ], [ 29.758144430379748, -1.447274827586207 ], [ 29.758144430379748, -1.447005344827586 ], [ 29.757874936708859, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18716, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.447005344827586 ], [ 29.758144430379748, -1.447274827586207 ], [ 29.758413924050632, -1.447274827586207 ], [ 29.758413924050632, -1.447005344827586 ], [ 29.758144430379748, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18717, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.447005344827586 ], [ 29.758413924050632, -1.447274827586207 ], [ 29.758683417721517, -1.447274827586207 ], [ 29.758683417721517, -1.447005344827586 ], [ 29.758413924050632, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18718, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.447005344827586 ], [ 29.758683417721517, -1.447274827586207 ], [ 29.758952911392406, -1.447274827586207 ], [ 29.758952911392406, -1.447005344827586 ], [ 29.758683417721517, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18719, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.447005344827586 ], [ 29.758952911392406, -1.447274827586207 ], [ 29.759222405063291, -1.447274827586207 ], [ 29.759222405063291, -1.447005344827586 ], [ 29.758952911392406, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18720, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.447005344827586 ], [ 29.759222405063291, -1.447274827586207 ], [ 29.759491898734176, -1.447274827586207 ], [ 29.759491898734176, -1.447005344827586 ], [ 29.759222405063291, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18721, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.447005344827586 ], [ 29.759491898734176, -1.447274827586207 ], [ 29.759761392405064, -1.447274827586207 ], [ 29.759761392405064, -1.447005344827586 ], [ 29.759491898734176, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18722, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.447005344827586 ], [ 29.759761392405064, -1.447274827586207 ], [ 29.760030886075949, -1.447274827586207 ], [ 29.760030886075949, -1.447005344827586 ], [ 29.759761392405064, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18723, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.447005344827586 ], [ 29.760030886075949, -1.447274827586207 ], [ 29.760300379746834, -1.447274827586207 ], [ 29.760300379746834, -1.447005344827586 ], [ 29.760030886075949, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18724, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.447005344827586 ], [ 29.760300379746834, -1.447274827586207 ], [ 29.760569873417722, -1.447274827586207 ], [ 29.760569873417722, -1.447005344827586 ], [ 29.760300379746834, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18725, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.447005344827586 ], [ 29.760569873417722, -1.447274827586207 ], [ 29.760839367088607, -1.447274827586207 ], [ 29.760839367088607, -1.447005344827586 ], [ 29.760569873417722, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18726, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.447005344827586 ], [ 29.760839367088607, -1.447274827586207 ], [ 29.761108860759492, -1.447274827586207 ], [ 29.761108860759492, -1.447005344827586 ], [ 29.760839367088607, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18727, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.447005344827586 ], [ 29.761108860759492, -1.447274827586207 ], [ 29.761378354430381, -1.447274827586207 ], [ 29.761378354430381, -1.447005344827586 ], [ 29.761108860759492, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18728, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.447005344827586 ], [ 29.761378354430381, -1.447274827586207 ], [ 29.761647848101266, -1.447274827586207 ], [ 29.761647848101266, -1.447005344827586 ], [ 29.761378354430381, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18729, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.447005344827586 ], [ 29.761647848101266, -1.447274827586207 ], [ 29.761917341772151, -1.447274827586207 ], [ 29.761917341772151, -1.447005344827586 ], [ 29.761647848101266, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18730, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.447005344827586 ], [ 29.761917341772151, -1.447274827586207 ], [ 29.762456329113924, -1.447274827586207 ], [ 29.762456329113924, -1.447005344827586 ], [ 29.761917341772151, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18731, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.447005344827586 ], [ 29.762456329113924, -1.447274827586207 ], [ 29.762725822784809, -1.447274827586207 ], [ 29.762725822784809, -1.447005344827586 ], [ 29.762456329113924, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18732, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.447005344827586 ], [ 29.762725822784809, -1.447274827586207 ], [ 29.763264810126582, -1.447274827586207 ], [ 29.763264810126582, -1.447005344827586 ], [ 29.762725822784809, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18733, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.447005344827586 ], [ 29.763264810126582, -1.447274827586207 ], [ 29.763534303797467, -1.447274827586207 ], [ 29.763534303797467, -1.447005344827586 ], [ 29.763264810126582, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18734, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.447005344827586 ], [ 29.763534303797467, -1.447274827586207 ], [ 29.76407329113924, -1.447274827586207 ], [ 29.76407329113924, -1.447005344827586 ], [ 29.763534303797467, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18735, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.447005344827586 ], [ 29.76407329113924, -1.447274827586207 ], [ 29.764342784810125, -1.447274827586207 ], [ 29.764342784810125, -1.447005344827586 ], [ 29.76407329113924, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18736, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.447005344827586 ], [ 29.764342784810125, -1.447274827586207 ], [ 29.764612278481014, -1.447274827586207 ], [ 29.764612278481014, -1.447005344827586 ], [ 29.764342784810125, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18737, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.447005344827586 ], [ 29.764612278481014, -1.447274827586207 ], [ 29.764881772151899, -1.447274827586207 ], [ 29.764881772151899, -1.447005344827586 ], [ 29.764612278481014, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18738, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.447005344827586 ], [ 29.764881772151899, -1.447274827586207 ], [ 29.765151265822784, -1.447274827586207 ], [ 29.765151265822784, -1.447005344827586 ], [ 29.764881772151899, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18739, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.447005344827586 ], [ 29.765151265822784, -1.447274827586207 ], [ 29.765420759493672, -1.447274827586207 ], [ 29.765420759493672, -1.447005344827586 ], [ 29.765151265822784, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18740, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.447005344827586 ], [ 29.765420759493672, -1.447274827586207 ], [ 29.767846202531643, -1.447274827586207 ], [ 29.767846202531643, -1.447005344827586 ], [ 29.765420759493672, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18741, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.447005344827586 ], [ 29.767846202531643, -1.447274827586207 ], [ 29.768115696202532, -1.447274827586207 ], [ 29.768115696202532, -1.447005344827586 ], [ 29.767846202531643, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18742, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.447005344827586 ], [ 29.768115696202532, -1.447274827586207 ], [ 29.768385189873417, -1.447274827586207 ], [ 29.768385189873417, -1.447005344827586 ], [ 29.768115696202532, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18743, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.447005344827586 ], [ 29.768385189873417, -1.447274827586207 ], [ 29.768654683544302, -1.447274827586207 ], [ 29.768654683544302, -1.447005344827586 ], [ 29.768385189873417, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18744, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.447005344827586 ], [ 29.768654683544302, -1.447274827586207 ], [ 29.76892417721519, -1.447274827586207 ], [ 29.76892417721519, -1.447005344827586 ], [ 29.768654683544302, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18745, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.447005344827586 ], [ 29.76892417721519, -1.447274827586207 ], [ 29.769193670886075, -1.447274827586207 ], [ 29.769193670886075, -1.447005344827586 ], [ 29.76892417721519, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18746, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.447005344827586 ], [ 29.769193670886075, -1.447274827586207 ], [ 29.76946316455696, -1.447274827586207 ], [ 29.76946316455696, -1.447005344827586 ], [ 29.769193670886075, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18747, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.447005344827586 ], [ 29.76946316455696, -1.447274827586207 ], [ 29.769732658227849, -1.447274827586207 ], [ 29.769732658227849, -1.447005344827586 ], [ 29.76946316455696, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18748, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.447005344827586 ], [ 29.769732658227849, -1.447274827586207 ], [ 29.770002151898733, -1.447274827586207 ], [ 29.770002151898733, -1.447005344827586 ], [ 29.769732658227849, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18749, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.447005344827586 ], [ 29.770002151898733, -1.447274827586207 ], [ 29.770271645569618, -1.447274827586207 ], [ 29.770271645569618, -1.447005344827586 ], [ 29.770002151898733, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18750, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.447005344827586 ], [ 29.770271645569618, -1.447274827586207 ], [ 29.770541139240507, -1.447274827586207 ], [ 29.770541139240507, -1.447005344827586 ], [ 29.770271645569618, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18751, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.447005344827586 ], [ 29.770541139240507, -1.447274827586207 ], [ 29.770810632911392, -1.447274827586207 ], [ 29.770810632911392, -1.447005344827586 ], [ 29.770541139240507, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18752, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.447005344827586 ], [ 29.770810632911392, -1.447274827586207 ], [ 29.771080126582277, -1.447274827586207 ], [ 29.771080126582277, -1.447005344827586 ], [ 29.770810632911392, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18753, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.447005344827586 ], [ 29.771080126582277, -1.447544310344828 ], [ 29.771349620253165, -1.447544310344828 ], [ 29.771349620253165, -1.447005344827586 ], [ 29.771080126582277, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18754, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.447005344827586 ], [ 29.771349620253165, -1.447274827586207 ], [ 29.77161911392405, -1.447274827586207 ], [ 29.77161911392405, -1.447005344827586 ], [ 29.771349620253165, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18755, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.447005344827586 ], [ 29.77161911392405, -1.447274827586207 ], [ 29.771888607594935, -1.447274827586207 ], [ 29.771888607594935, -1.447005344827586 ], [ 29.77161911392405, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18756, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.447005344827586 ], [ 29.771888607594935, -1.447544310344828 ], [ 29.772158101265823, -1.447544310344828 ], [ 29.772158101265823, -1.447005344827586 ], [ 29.771888607594935, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18757, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.447005344827586 ], [ 29.772158101265823, -1.447274827586207 ], [ 29.772427594936708, -1.447274827586207 ], [ 29.772427594936708, -1.447005344827586 ], [ 29.772158101265823, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18758, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.447005344827586 ], [ 29.772427594936708, -1.447274827586207 ], [ 29.772697088607593, -1.447274827586207 ], [ 29.772697088607593, -1.447005344827586 ], [ 29.772427594936708, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18759, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.447005344827586 ], [ 29.772697088607593, -1.447544310344828 ], [ 29.772966582278482, -1.447544310344828 ], [ 29.772966582278482, -1.447005344827586 ], [ 29.772697088607593, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18760, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.447005344827586 ], [ 29.773236075949367, -1.447274827586207 ], [ 29.773505569620252, -1.447274827586207 ], [ 29.773505569620252, -1.447005344827586 ], [ 29.773236075949367, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18761, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.447005344827586 ], [ 29.773505569620252, -1.447544310344828 ], [ 29.77377506329114, -1.447544310344828 ], [ 29.77377506329114, -1.447005344827586 ], [ 29.773505569620252, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18762, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.447005344827586 ], [ 29.774044556962025, -1.447544310344828 ], [ 29.77431405063291, -1.447544310344828 ], [ 29.77431405063291, -1.447005344827586 ], [ 29.774044556962025, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18763, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.447005344827586 ], [ 29.77431405063291, -1.447544310344828 ], [ 29.774853037974683, -1.447544310344828 ], [ 29.774853037974683, -1.447005344827586 ], [ 29.77431405063291, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18764, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.447005344827586 ], [ 29.775122531645568, -1.447544310344828 ], [ 29.775392025316457, -1.447544310344828 ], [ 29.775392025316457, -1.447005344827586 ], [ 29.775122531645568, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18765, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.447005344827586 ], [ 29.775931012658226, -1.447544310344828 ], [ 29.776200506329115, -1.447544310344828 ], [ 29.776200506329115, -1.447005344827586 ], [ 29.775931012658226, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18766, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.447005344827586 ], [ 29.776739493670885, -1.447274827586207 ], [ 29.777008987341773, -1.447274827586207 ], [ 29.777008987341773, -1.447005344827586 ], [ 29.776739493670885, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18767, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.447005344827586 ], [ 29.777278481012658, -1.447544310344828 ], [ 29.777547974683543, -1.447544310344828 ], [ 29.777547974683543, -1.447005344827586 ], [ 29.777278481012658, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18768, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.447005344827586 ], [ 29.777547974683543, -1.447274827586207 ], [ 29.777817468354431, -1.447274827586207 ], [ 29.777817468354431, -1.447005344827586 ], [ 29.777547974683543, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18769, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.447005344827586 ], [ 29.777817468354431, -1.447274827586207 ], [ 29.778086962025316, -1.447274827586207 ], [ 29.778086962025316, -1.447005344827586 ], [ 29.777817468354431, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18770, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.447005344827586 ], [ 29.778086962025316, -1.447274827586207 ], [ 29.778356455696201, -1.447274827586207 ], [ 29.778356455696201, -1.447005344827586 ], [ 29.778086962025316, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18771, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.447005344827586 ], [ 29.778356455696201, -1.447274827586207 ], [ 29.77862594936709, -1.447274827586207 ], [ 29.77862594936709, -1.447005344827586 ], [ 29.778356455696201, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18772, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.447005344827586 ], [ 29.77862594936709, -1.447274827586207 ], [ 29.778895443037975, -1.447274827586207 ], [ 29.778895443037975, -1.447005344827586 ], [ 29.77862594936709, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18773, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.447005344827586 ], [ 29.778895443037975, -1.447274827586207 ], [ 29.77916493670886, -1.447274827586207 ], [ 29.77916493670886, -1.447005344827586 ], [ 29.778895443037975, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18774, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.447005344827586 ], [ 29.77916493670886, -1.447274827586207 ], [ 29.779434430379748, -1.447274827586207 ], [ 29.779434430379748, -1.447005344827586 ], [ 29.77916493670886, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18775, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.447005344827586 ], [ 29.779434430379748, -1.447274827586207 ], [ 29.779703924050633, -1.447274827586207 ], [ 29.779703924050633, -1.447005344827586 ], [ 29.779434430379748, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18776, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.447005344827586 ], [ 29.779703924050633, -1.447274827586207 ], [ 29.779973417721518, -1.447274827586207 ], [ 29.779973417721518, -1.447005344827586 ], [ 29.779703924050633, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18777, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.447005344827586 ], [ 29.779973417721518, -1.447274827586207 ], [ 29.780242911392406, -1.447274827586207 ], [ 29.780242911392406, -1.447005344827586 ], [ 29.779973417721518, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18778, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.447005344827586 ], [ 29.780242911392406, -1.447274827586207 ], [ 29.780512405063291, -1.447274827586207 ], [ 29.780512405063291, -1.447005344827586 ], [ 29.780242911392406, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18779, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.447005344827586 ], [ 29.780512405063291, -1.447274827586207 ], [ 29.780781898734176, -1.447274827586207 ], [ 29.780781898734176, -1.447005344827586 ], [ 29.780512405063291, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18780, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.447005344827586 ], [ 29.781051392405065, -1.447544310344828 ], [ 29.78132088607595, -1.447544310344828 ], [ 29.78132088607595, -1.447005344827586 ], [ 29.781051392405065, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18781, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.447005344827586 ], [ 29.78132088607595, -1.447274827586207 ], [ 29.781590379746834, -1.447274827586207 ], [ 29.781590379746834, -1.447005344827586 ], [ 29.78132088607595, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18782, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.447005344827586 ], [ 29.781590379746834, -1.447274827586207 ], [ 29.781859873417723, -1.447274827586207 ], [ 29.781859873417723, -1.447005344827586 ], [ 29.781590379746834, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18783, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.447005344827586 ], [ 29.782668354430381, -1.447544310344828 ], [ 29.782937848101266, -1.447544310344828 ], [ 29.782937848101266, -1.447005344827586 ], [ 29.782668354430381, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18784, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.447005344827586 ], [ 29.782937848101266, -1.447274827586207 ], [ 29.783207341772151, -1.447274827586207 ], [ 29.783207341772151, -1.447005344827586 ], [ 29.782937848101266, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18785, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.447005344827586 ], [ 29.783207341772151, -1.447274827586207 ], [ 29.783476835443039, -1.447274827586207 ], [ 29.783476835443039, -1.447005344827586 ], [ 29.783207341772151, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18786, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.447005344827586 ], [ 29.783476835443039, -1.447544310344828 ], [ 29.784015822784809, -1.447544310344828 ], [ 29.784015822784809, -1.447005344827586 ], [ 29.783476835443039, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18787, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.447005344827586 ], [ 29.784015822784809, -1.447274827586207 ], [ 29.784285316455698, -1.447274827586207 ], [ 29.784285316455698, -1.447005344827586 ], [ 29.784015822784809, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18788, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.447005344827586 ], [ 29.784285316455698, -1.447274827586207 ], [ 29.784554810126583, -1.447274827586207 ], [ 29.784554810126583, -1.447005344827586 ], [ 29.784285316455698, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18789, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.447005344827586 ], [ 29.784554810126583, -1.447274827586207 ], [ 29.784824303797468, -1.447274827586207 ], [ 29.784824303797468, -1.447005344827586 ], [ 29.784554810126583, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18790, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.447005344827586 ], [ 29.784824303797468, -1.447274827586207 ], [ 29.785093797468356, -1.447274827586207 ], [ 29.785093797468356, -1.447005344827586 ], [ 29.784824303797468, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18791, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.447005344827586 ], [ 29.785093797468356, -1.447274827586207 ], [ 29.785363291139241, -1.447274827586207 ], [ 29.785363291139241, -1.447005344827586 ], [ 29.785093797468356, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18792, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.447005344827586 ], [ 29.785363291139241, -1.447274827586207 ], [ 29.785632784810126, -1.447274827586207 ], [ 29.785632784810126, -1.447005344827586 ], [ 29.785363291139241, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18793, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.447005344827586 ], [ 29.785632784810126, -1.447274827586207 ], [ 29.785902278481014, -1.447274827586207 ], [ 29.785902278481014, -1.447005344827586 ], [ 29.785632784810126, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18794, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.447005344827586 ], [ 29.785902278481014, -1.447274827586207 ], [ 29.786171772151899, -1.447274827586207 ], [ 29.786171772151899, -1.447005344827586 ], [ 29.785902278481014, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18795, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.447005344827586 ], [ 29.786171772151899, -1.447274827586207 ], [ 29.786441265822784, -1.447274827586207 ], [ 29.786441265822784, -1.447005344827586 ], [ 29.786171772151899, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18796, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.447005344827586 ], [ 29.786441265822784, -1.447274827586207 ], [ 29.786710759493673, -1.447274827586207 ], [ 29.786710759493673, -1.447005344827586 ], [ 29.786441265822784, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18797, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.447005344827586 ], [ 29.786710759493673, -1.447274827586207 ], [ 29.786980253164558, -1.447274827586207 ], [ 29.786980253164558, -1.447005344827586 ], [ 29.786710759493673, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18798, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.447005344827586 ], [ 29.786980253164558, -1.447274827586207 ], [ 29.787249746835442, -1.447274827586207 ], [ 29.787249746835442, -1.447005344827586 ], [ 29.786980253164558, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18799, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.447005344827586 ], [ 29.787249746835442, -1.447274827586207 ], [ 29.787519240506327, -1.447274827586207 ], [ 29.787519240506327, -1.447005344827586 ], [ 29.787249746835442, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18800, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.447005344827586 ], [ 29.787519240506327, -1.447274827586207 ], [ 29.787788734177216, -1.447274827586207 ], [ 29.787788734177216, -1.447005344827586 ], [ 29.787519240506327, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18801, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.447005344827586 ], [ 29.787788734177216, -1.447544310344828 ], [ 29.788058227848101, -1.447544310344828 ], [ 29.788058227848101, -1.447005344827586 ], [ 29.787788734177216, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18802, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.447005344827586 ], [ 29.789944683544302, -1.447274827586207 ], [ 29.790214177215191, -1.447274827586207 ], [ 29.790214177215191, -1.447005344827586 ], [ 29.789944683544302, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18803, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.447005344827586 ], [ 29.790214177215191, -1.447274827586207 ], [ 29.790483670886076, -1.447274827586207 ], [ 29.790483670886076, -1.447005344827586 ], [ 29.790214177215191, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18804, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.447005344827586 ], [ 29.790483670886076, -1.447274827586207 ], [ 29.790753164556961, -1.447274827586207 ], [ 29.790753164556961, -1.447005344827586 ], [ 29.790483670886076, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18805, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.447005344827586 ], [ 29.790753164556961, -1.447274827586207 ], [ 29.791022658227849, -1.447274827586207 ], [ 29.791022658227849, -1.447005344827586 ], [ 29.790753164556961, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18806, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.447005344827586 ], [ 29.793987088607594, -1.447274827586207 ], [ 29.794256582278482, -1.447274827586207 ], [ 29.794256582278482, -1.447005344827586 ], [ 29.793987088607594, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18807, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.447005344827586 ], [ 29.794256582278482, -1.447274827586207 ], [ 29.794526075949367, -1.447274827586207 ], [ 29.794526075949367, -1.447005344827586 ], [ 29.794256582278482, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18808, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.447005344827586 ], [ 29.794526075949367, -1.447274827586207 ], [ 29.794795569620252, -1.447274827586207 ], [ 29.794795569620252, -1.447005344827586 ], [ 29.794526075949367, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18809, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.447005344827586 ], [ 29.794795569620252, -1.447274827586207 ], [ 29.79506506329114, -1.447274827586207 ], [ 29.79506506329114, -1.447005344827586 ], [ 29.794795569620252, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18810, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.447005344827586 ], [ 29.79506506329114, -1.447274827586207 ], [ 29.795334556962025, -1.447274827586207 ], [ 29.795334556962025, -1.447005344827586 ], [ 29.79506506329114, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18811, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.447005344827586 ], [ 29.795334556962025, -1.447274827586207 ], [ 29.79560405063291, -1.447274827586207 ], [ 29.79560405063291, -1.447005344827586 ], [ 29.795334556962025, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18812, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.447005344827586 ], [ 29.79560405063291, -1.447274827586207 ], [ 29.795873544303799, -1.447274827586207 ], [ 29.795873544303799, -1.447005344827586 ], [ 29.79560405063291, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18813, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.447005344827586 ], [ 29.795873544303799, -1.447274827586207 ], [ 29.796143037974684, -1.447274827586207 ], [ 29.796143037974684, -1.447005344827586 ], [ 29.795873544303799, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18814, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.447005344827586 ], [ 29.796143037974684, -1.447274827586207 ], [ 29.796412531645569, -1.447274827586207 ], [ 29.796412531645569, -1.447005344827586 ], [ 29.796143037974684, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18815, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.447005344827586 ], [ 29.796412531645569, -1.447274827586207 ], [ 29.796682025316457, -1.447274827586207 ], [ 29.796682025316457, -1.447005344827586 ], [ 29.796412531645569, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18816, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.447005344827586 ], [ 29.796682025316457, -1.447274827586207 ], [ 29.796951518987342, -1.447274827586207 ], [ 29.796951518987342, -1.447005344827586 ], [ 29.796682025316457, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18817, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.447005344827586 ], [ 29.796951518987342, -1.447274827586207 ], [ 29.797221012658227, -1.447274827586207 ], [ 29.797221012658227, -1.447005344827586 ], [ 29.796951518987342, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18818, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.447005344827586 ], [ 29.797221012658227, -1.447274827586207 ], [ 29.797490506329115, -1.447274827586207 ], [ 29.797490506329115, -1.447005344827586 ], [ 29.797221012658227, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18819, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.447005344827586 ], [ 29.797490506329115, -1.447274827586207 ], [ 29.79776, -1.447274827586207 ], [ 29.79776, -1.447005344827586 ], [ 29.797490506329115, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18820, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.447005344827586 ], [ 29.801532911392407, -1.448083275862069 ], [ 29.802071898734177, -1.448083275862069 ], [ 29.802071898734177, -1.447005344827586 ], [ 29.801532911392407, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.447005344827586 ], [ 29.802071898734177, -1.447813793103448 ], [ 29.802341392405065, -1.447813793103448 ], [ 29.802341392405065, -1.447005344827586 ], [ 29.802071898734177, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18822, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.447005344827586 ], [ 29.802341392405065, -1.447813793103448 ], [ 29.80261088607595, -1.447813793103448 ], [ 29.80261088607595, -1.447005344827586 ], [ 29.802341392405065, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18823, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.447005344827586 ], [ 29.803149873417723, -1.447274827586207 ], [ 29.803419367088608, -1.447274827586207 ], [ 29.803419367088608, -1.447005344827586 ], [ 29.803149873417723, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18824, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.447005344827586 ], [ 29.803958354430382, -1.447274827586207 ], [ 29.804227848101267, -1.447274827586207 ], [ 29.804227848101267, -1.447005344827586 ], [ 29.803958354430382, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18825, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.447005344827586 ], [ 29.804227848101267, -1.447274827586207 ], [ 29.804497341772151, -1.447274827586207 ], [ 29.804497341772151, -1.447005344827586 ], [ 29.804227848101267, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18826, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.447005344827586 ], [ 29.804497341772151, -1.447274827586207 ], [ 29.80476683544304, -1.447274827586207 ], [ 29.80476683544304, -1.447005344827586 ], [ 29.804497341772151, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18827, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.447005344827586 ], [ 29.80476683544304, -1.447274827586207 ], [ 29.805036329113925, -1.447274827586207 ], [ 29.805036329113925, -1.447005344827586 ], [ 29.80476683544304, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18828, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.447005344827586 ], [ 29.805036329113925, -1.447274827586207 ], [ 29.80530582278481, -1.447274827586207 ], [ 29.80530582278481, -1.447005344827586 ], [ 29.805036329113925, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18829, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.447005344827586 ], [ 29.80530582278481, -1.447274827586207 ], [ 29.805575316455698, -1.447274827586207 ], [ 29.805575316455698, -1.447005344827586 ], [ 29.80530582278481, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18830, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.447005344827586 ], [ 29.805575316455698, -1.447274827586207 ], [ 29.805844810126583, -1.447274827586207 ], [ 29.805844810126583, -1.447005344827586 ], [ 29.805575316455698, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18831, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.447005344827586 ], [ 29.805844810126583, -1.447274827586207 ], [ 29.806114303797468, -1.447274827586207 ], [ 29.806114303797468, -1.447005344827586 ], [ 29.805844810126583, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18832, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.447005344827586 ], [ 29.806114303797468, -1.447274827586207 ], [ 29.806383797468357, -1.447274827586207 ], [ 29.806383797468357, -1.447005344827586 ], [ 29.806114303797468, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18833, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.447005344827586 ], [ 29.806383797468357, -1.447274827586207 ], [ 29.806653291139241, -1.447274827586207 ], [ 29.806653291139241, -1.447005344827586 ], [ 29.806383797468357, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18834, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.447005344827586 ], [ 29.806653291139241, -1.447274827586207 ], [ 29.806922784810126, -1.447274827586207 ], [ 29.806922784810126, -1.447005344827586 ], [ 29.806653291139241, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18835, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.447005344827586 ], [ 29.806922784810126, -1.447274827586207 ], [ 29.807192278481015, -1.447274827586207 ], [ 29.807192278481015, -1.447005344827586 ], [ 29.806922784810126, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18836, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.447005344827586 ], [ 29.807192278481015, -1.447274827586207 ], [ 29.8074617721519, -1.447274827586207 ], [ 29.8074617721519, -1.447005344827586 ], [ 29.807192278481015, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18837, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.447005344827586 ], [ 29.8074617721519, -1.447274827586207 ], [ 29.807731265822785, -1.447274827586207 ], [ 29.807731265822785, -1.447005344827586 ], [ 29.8074617721519, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18838, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.748981645569618, -1.447274827586207 ], [ 29.748981645569618, -1.447544310344828 ], [ 29.749251139240506, -1.447544310344828 ], [ 29.749251139240506, -1.447274827586207 ], [ 29.748981645569618, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18839, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.447274827586207 ], [ 29.749251139240506, -1.447544310344828 ], [ 29.749520632911391, -1.447544310344828 ], [ 29.749520632911391, -1.447274827586207 ], [ 29.749251139240506, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18840, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.447274827586207 ], [ 29.749520632911391, -1.447544310344828 ], [ 29.749790126582276, -1.447544310344828 ], [ 29.749790126582276, -1.447274827586207 ], [ 29.749520632911391, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18841, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.447274827586207 ], [ 29.749790126582276, -1.447544310344828 ], [ 29.750059620253165, -1.447544310344828 ], [ 29.750059620253165, -1.447274827586207 ], [ 29.749790126582276, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18842, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.447274827586207 ], [ 29.750059620253165, -1.447544310344828 ], [ 29.75032911392405, -1.447544310344828 ], [ 29.75032911392405, -1.447274827586207 ], [ 29.750059620253165, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18843, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.447274827586207 ], [ 29.75032911392405, -1.447544310344828 ], [ 29.750598607594934, -1.447544310344828 ], [ 29.750598607594934, -1.447274827586207 ], [ 29.75032911392405, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18844, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.447274827586207 ], [ 29.750598607594934, -1.447544310344828 ], [ 29.750868101265823, -1.447544310344828 ], [ 29.750868101265823, -1.447274827586207 ], [ 29.750598607594934, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18845, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.447274827586207 ], [ 29.750868101265823, -1.447544310344828 ], [ 29.751137594936708, -1.447544310344828 ], [ 29.751137594936708, -1.447274827586207 ], [ 29.750868101265823, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18846, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.447274827586207 ], [ 29.751137594936708, -1.447544310344828 ], [ 29.751407088607593, -1.447544310344828 ], [ 29.751407088607593, -1.447274827586207 ], [ 29.751137594936708, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18847, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.447274827586207 ], [ 29.751407088607593, -1.447544310344828 ], [ 29.751676582278481, -1.447544310344828 ], [ 29.751676582278481, -1.447274827586207 ], [ 29.751407088607593, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18848, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.447274827586207 ], [ 29.751676582278481, -1.447544310344828 ], [ 29.751946075949366, -1.447544310344828 ], [ 29.751946075949366, -1.447274827586207 ], [ 29.751676582278481, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18849, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.447274827586207 ], [ 29.751946075949366, -1.447544310344828 ], [ 29.752215569620251, -1.447544310344828 ], [ 29.752215569620251, -1.447274827586207 ], [ 29.751946075949366, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18850, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.447274827586207 ], [ 29.752215569620251, -1.447544310344828 ], [ 29.75248506329114, -1.447544310344828 ], [ 29.75248506329114, -1.447274827586207 ], [ 29.752215569620251, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18851, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.447274827586207 ], [ 29.75248506329114, -1.447544310344828 ], [ 29.752754556962024, -1.447544310344828 ], [ 29.752754556962024, -1.447274827586207 ], [ 29.75248506329114, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18852, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.447274827586207 ], [ 29.752754556962024, -1.447544310344828 ], [ 29.753024050632909, -1.447544310344828 ], [ 29.753024050632909, -1.447274827586207 ], [ 29.752754556962024, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18853, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.447274827586207 ], [ 29.753024050632909, -1.447544310344828 ], [ 29.753293544303798, -1.447544310344828 ], [ 29.753293544303798, -1.447274827586207 ], [ 29.753024050632909, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18854, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.447274827586207 ], [ 29.753293544303798, -1.447544310344828 ], [ 29.753563037974683, -1.447544310344828 ], [ 29.753563037974683, -1.447274827586207 ], [ 29.753293544303798, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18855, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.447274827586207 ], [ 29.753563037974683, -1.447544310344828 ], [ 29.753832531645568, -1.447544310344828 ], [ 29.753832531645568, -1.447274827586207 ], [ 29.753563037974683, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18856, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.447274827586207 ], [ 29.753832531645568, -1.447544310344828 ], [ 29.754102025316456, -1.447544310344828 ], [ 29.754102025316456, -1.447274827586207 ], [ 29.753832531645568, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18857, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.447274827586207 ], [ 29.754102025316456, -1.447544310344828 ], [ 29.754371518987341, -1.447544310344828 ], [ 29.754371518987341, -1.447274827586207 ], [ 29.754102025316456, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18858, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.447274827586207 ], [ 29.754371518987341, -1.447544310344828 ], [ 29.754641012658226, -1.447544310344828 ], [ 29.754641012658226, -1.447274827586207 ], [ 29.754371518987341, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18859, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.447274827586207 ], [ 29.754641012658226, -1.447544310344828 ], [ 29.754910506329114, -1.447544310344828 ], [ 29.754910506329114, -1.447274827586207 ], [ 29.754641012658226, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18860, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.447274827586207 ], [ 29.754910506329114, -1.447544310344828 ], [ 29.75518, -1.447544310344828 ], [ 29.75518, -1.447274827586207 ], [ 29.754910506329114, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18861, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.447274827586207 ], [ 29.75518, -1.447544310344828 ], [ 29.755449493670884, -1.447544310344828 ], [ 29.755449493670884, -1.447274827586207 ], [ 29.75518, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18862, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.447274827586207 ], [ 29.755449493670884, -1.447544310344828 ], [ 29.755718987341773, -1.447544310344828 ], [ 29.755718987341773, -1.447274827586207 ], [ 29.755449493670884, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18863, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.447274827586207 ], [ 29.755718987341773, -1.447813793103448 ], [ 29.755988481012658, -1.447813793103448 ], [ 29.755988481012658, -1.447274827586207 ], [ 29.755718987341773, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18864, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.447274827586207 ], [ 29.755988481012658, -1.447544310344828 ], [ 29.756257974683542, -1.447544310344828 ], [ 29.756257974683542, -1.447274827586207 ], [ 29.755988481012658, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18865, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.447274827586207 ], [ 29.756257974683542, -1.447544310344828 ], [ 29.756796962025316, -1.447544310344828 ], [ 29.756796962025316, -1.447274827586207 ], [ 29.756257974683542, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18866, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.447274827586207 ], [ 29.756796962025316, -1.447813793103448 ], [ 29.757066455696201, -1.447813793103448 ], [ 29.757066455696201, -1.447274827586207 ], [ 29.756796962025316, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18867, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.447274827586207 ], [ 29.757066455696201, -1.447544310344828 ], [ 29.757335949367089, -1.447544310344828 ], [ 29.757335949367089, -1.447274827586207 ], [ 29.757066455696201, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18868, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.447274827586207 ], [ 29.757335949367089, -1.447544310344828 ], [ 29.757605443037974, -1.447544310344828 ], [ 29.757605443037974, -1.447274827586207 ], [ 29.757335949367089, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18869, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.447274827586207 ], [ 29.757874936708859, -1.447544310344828 ], [ 29.758144430379748, -1.447544310344828 ], [ 29.758144430379748, -1.447274827586207 ], [ 29.757874936708859, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18870, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.447274827586207 ], [ 29.758144430379748, -1.447544310344828 ], [ 29.758413924050632, -1.447544310344828 ], [ 29.758413924050632, -1.447274827586207 ], [ 29.758144430379748, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18871, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.447274827586207 ], [ 29.758413924050632, -1.447544310344828 ], [ 29.758683417721517, -1.447544310344828 ], [ 29.758683417721517, -1.447274827586207 ], [ 29.758413924050632, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18872, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.447274827586207 ], [ 29.758683417721517, -1.447544310344828 ], [ 29.758952911392406, -1.447544310344828 ], [ 29.758952911392406, -1.447274827586207 ], [ 29.758683417721517, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18873, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.447274827586207 ], [ 29.758952911392406, -1.447544310344828 ], [ 29.759222405063291, -1.447544310344828 ], [ 29.759222405063291, -1.447274827586207 ], [ 29.758952911392406, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18874, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.447274827586207 ], [ 29.759222405063291, -1.447544310344828 ], [ 29.759491898734176, -1.447544310344828 ], [ 29.759491898734176, -1.447274827586207 ], [ 29.759222405063291, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18875, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.447274827586207 ], [ 29.759491898734176, -1.447544310344828 ], [ 29.759761392405064, -1.447544310344828 ], [ 29.759761392405064, -1.447274827586207 ], [ 29.759491898734176, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18876, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.447274827586207 ], [ 29.759761392405064, -1.447544310344828 ], [ 29.760030886075949, -1.447544310344828 ], [ 29.760030886075949, -1.447274827586207 ], [ 29.759761392405064, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18877, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.447274827586207 ], [ 29.760030886075949, -1.447544310344828 ], [ 29.760300379746834, -1.447544310344828 ], [ 29.760300379746834, -1.447274827586207 ], [ 29.760030886075949, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18878, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.447274827586207 ], [ 29.760300379746834, -1.447544310344828 ], [ 29.760569873417722, -1.447544310344828 ], [ 29.760569873417722, -1.447274827586207 ], [ 29.760300379746834, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18879, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.447274827586207 ], [ 29.760569873417722, -1.447544310344828 ], [ 29.760839367088607, -1.447544310344828 ], [ 29.760839367088607, -1.447274827586207 ], [ 29.760569873417722, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18880, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.447274827586207 ], [ 29.760839367088607, -1.447544310344828 ], [ 29.761108860759492, -1.447544310344828 ], [ 29.761108860759492, -1.447274827586207 ], [ 29.760839367088607, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18881, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.447274827586207 ], [ 29.761108860759492, -1.447544310344828 ], [ 29.761378354430381, -1.447544310344828 ], [ 29.761378354430381, -1.447274827586207 ], [ 29.761108860759492, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18882, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.447274827586207 ], [ 29.761378354430381, -1.447544310344828 ], [ 29.761647848101266, -1.447544310344828 ], [ 29.761647848101266, -1.447274827586207 ], [ 29.761378354430381, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18883, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.447274827586207 ], [ 29.761647848101266, -1.447544310344828 ], [ 29.761917341772151, -1.447544310344828 ], [ 29.761917341772151, -1.447274827586207 ], [ 29.761647848101266, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18884, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.447274827586207 ], [ 29.761917341772151, -1.447544310344828 ], [ 29.762186835443039, -1.447544310344828 ], [ 29.762186835443039, -1.447274827586207 ], [ 29.761917341772151, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18885, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.447274827586207 ], [ 29.762186835443039, -1.447544310344828 ], [ 29.762995316455697, -1.447544310344828 ], [ 29.762995316455697, -1.447274827586207 ], [ 29.762186835443039, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18886, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.447274827586207 ], [ 29.762995316455697, -1.447544310344828 ], [ 29.763264810126582, -1.447544310344828 ], [ 29.763264810126582, -1.447274827586207 ], [ 29.762995316455697, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18887, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.447274827586207 ], [ 29.763264810126582, -1.447544310344828 ], [ 29.763534303797467, -1.447544310344828 ], [ 29.763534303797467, -1.447274827586207 ], [ 29.763264810126582, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18888, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.447274827586207 ], [ 29.763534303797467, -1.447544310344828 ], [ 29.76407329113924, -1.447544310344828 ], [ 29.76407329113924, -1.447274827586207 ], [ 29.763534303797467, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18889, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.447274827586207 ], [ 29.76407329113924, -1.447544310344828 ], [ 29.764342784810125, -1.447544310344828 ], [ 29.764342784810125, -1.447274827586207 ], [ 29.76407329113924, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18890, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.447274827586207 ], [ 29.764342784810125, -1.447544310344828 ], [ 29.764612278481014, -1.447544310344828 ], [ 29.764612278481014, -1.447274827586207 ], [ 29.764342784810125, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18891, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.447274827586207 ], [ 29.764612278481014, -1.447544310344828 ], [ 29.764881772151899, -1.447544310344828 ], [ 29.764881772151899, -1.447274827586207 ], [ 29.764612278481014, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18892, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.447274827586207 ], [ 29.764881772151899, -1.447544310344828 ], [ 29.765151265822784, -1.447544310344828 ], [ 29.765151265822784, -1.447274827586207 ], [ 29.764881772151899, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18893, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.447274827586207 ], [ 29.765151265822784, -1.447544310344828 ], [ 29.765420759493672, -1.447544310344828 ], [ 29.765420759493672, -1.447274827586207 ], [ 29.765151265822784, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18894, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.447274827586207 ], [ 29.765420759493672, -1.447544310344828 ], [ 29.765959746835442, -1.447544310344828 ], [ 29.765959746835442, -1.447274827586207 ], [ 29.765420759493672, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18895, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.447274827586207 ], [ 29.765959746835442, -1.447544310344828 ], [ 29.767576708860759, -1.447544310344828 ], [ 29.767576708860759, -1.447274827586207 ], [ 29.765959746835442, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18896, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.447274827586207 ], [ 29.767576708860759, -1.447544310344828 ], [ 29.767846202531643, -1.447544310344828 ], [ 29.767846202531643, -1.447274827586207 ], [ 29.767576708860759, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18897, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.447274827586207 ], [ 29.767846202531643, -1.447544310344828 ], [ 29.768115696202532, -1.447544310344828 ], [ 29.768115696202532, -1.447274827586207 ], [ 29.767846202531643, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18898, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.447274827586207 ], [ 29.768115696202532, -1.447544310344828 ], [ 29.768385189873417, -1.447544310344828 ], [ 29.768385189873417, -1.447274827586207 ], [ 29.768115696202532, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18899, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.447274827586207 ], [ 29.768385189873417, -1.447544310344828 ], [ 29.768654683544302, -1.447544310344828 ], [ 29.768654683544302, -1.447274827586207 ], [ 29.768385189873417, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18900, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.447274827586207 ], [ 29.768654683544302, -1.447544310344828 ], [ 29.76892417721519, -1.447544310344828 ], [ 29.76892417721519, -1.447274827586207 ], [ 29.768654683544302, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18901, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.447274827586207 ], [ 29.76892417721519, -1.447544310344828 ], [ 29.769193670886075, -1.447544310344828 ], [ 29.769193670886075, -1.447274827586207 ], [ 29.76892417721519, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18902, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.447274827586207 ], [ 29.769193670886075, -1.447544310344828 ], [ 29.76946316455696, -1.447544310344828 ], [ 29.76946316455696, -1.447274827586207 ], [ 29.769193670886075, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18903, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.447274827586207 ], [ 29.76946316455696, -1.447544310344828 ], [ 29.769732658227849, -1.447544310344828 ], [ 29.769732658227849, -1.447274827586207 ], [ 29.76946316455696, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18904, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.447274827586207 ], [ 29.769732658227849, -1.447544310344828 ], [ 29.770002151898733, -1.447544310344828 ], [ 29.770002151898733, -1.447274827586207 ], [ 29.769732658227849, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18905, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.447274827586207 ], [ 29.770002151898733, -1.447813793103448 ], [ 29.770271645569618, -1.447813793103448 ], [ 29.770271645569618, -1.447274827586207 ], [ 29.770002151898733, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18906, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.447274827586207 ], [ 29.770271645569618, -1.447544310344828 ], [ 29.770541139240507, -1.447544310344828 ], [ 29.770541139240507, -1.447274827586207 ], [ 29.770271645569618, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18907, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.447274827586207 ], [ 29.770541139240507, -1.447544310344828 ], [ 29.770810632911392, -1.447544310344828 ], [ 29.770810632911392, -1.447274827586207 ], [ 29.770541139240507, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18908, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.447274827586207 ], [ 29.770810632911392, -1.447813793103448 ], [ 29.771080126582277, -1.447813793103448 ], [ 29.771080126582277, -1.447274827586207 ], [ 29.770810632911392, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18909, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.447274827586207 ], [ 29.771349620253165, -1.447544310344828 ], [ 29.77161911392405, -1.447544310344828 ], [ 29.77161911392405, -1.447274827586207 ], [ 29.771349620253165, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18910, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.447274827586207 ], [ 29.77161911392405, -1.447813793103448 ], [ 29.771888607594935, -1.447813793103448 ], [ 29.771888607594935, -1.447274827586207 ], [ 29.77161911392405, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18911, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.447274827586207 ], [ 29.772158101265823, -1.447813793103448 ], [ 29.772427594936708, -1.447813793103448 ], [ 29.772427594936708, -1.447274827586207 ], [ 29.772158101265823, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18912, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.447274827586207 ], [ 29.772427594936708, -1.447813793103448 ], [ 29.772697088607593, -1.447813793103448 ], [ 29.772697088607593, -1.447274827586207 ], [ 29.772427594936708, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18913, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.447274827586207 ], [ 29.772966582278482, -1.447813793103448 ], [ 29.773236075949367, -1.447813793103448 ], [ 29.773236075949367, -1.447274827586207 ], [ 29.772966582278482, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18914, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.447274827586207 ], [ 29.773236075949367, -1.447813793103448 ], [ 29.773505569620252, -1.447813793103448 ], [ 29.773505569620252, -1.447274827586207 ], [ 29.773236075949367, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18915, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.447274827586207 ], [ 29.77377506329114, -1.447813793103448 ], [ 29.774044556962025, -1.447813793103448 ], [ 29.774044556962025, -1.447274827586207 ], [ 29.77377506329114, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18916, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.447274827586207 ], [ 29.774853037974683, -1.447813793103448 ], [ 29.775122531645568, -1.447813793103448 ], [ 29.775122531645568, -1.447274827586207 ], [ 29.774853037974683, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18917, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.447274827586207 ], [ 29.775392025316457, -1.447544310344828 ], [ 29.775661518987341, -1.447544310344828 ], [ 29.775661518987341, -1.447274827586207 ], [ 29.775392025316457, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18918, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.447274827586207 ], [ 29.775661518987341, -1.447544310344828 ], [ 29.775931012658226, -1.447544310344828 ], [ 29.775931012658226, -1.447274827586207 ], [ 29.775661518987341, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18919, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.447274827586207 ], [ 29.776200506329115, -1.447544310344828 ], [ 29.77647, -1.447544310344828 ], [ 29.77647, -1.447274827586207 ], [ 29.776200506329115, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18920, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.447274827586207 ], [ 29.77647, -1.447544310344828 ], [ 29.776739493670885, -1.447544310344828 ], [ 29.776739493670885, -1.447274827586207 ], [ 29.77647, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18921, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.447274827586207 ], [ 29.776739493670885, -1.447813793103448 ], [ 29.777008987341773, -1.447813793103448 ], [ 29.777008987341773, -1.447274827586207 ], [ 29.776739493670885, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18922, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.447274827586207 ], [ 29.777008987341773, -1.447544310344828 ], [ 29.777278481012658, -1.447544310344828 ], [ 29.777278481012658, -1.447274827586207 ], [ 29.777008987341773, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18923, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.447274827586207 ], [ 29.777547974683543, -1.447544310344828 ], [ 29.777817468354431, -1.447544310344828 ], [ 29.777817468354431, -1.447274827586207 ], [ 29.777547974683543, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18924, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.447274827586207 ], [ 29.777817468354431, -1.447544310344828 ], [ 29.778086962025316, -1.447544310344828 ], [ 29.778086962025316, -1.447274827586207 ], [ 29.777817468354431, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18925, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.447274827586207 ], [ 29.778086962025316, -1.447544310344828 ], [ 29.778356455696201, -1.447544310344828 ], [ 29.778356455696201, -1.447274827586207 ], [ 29.778086962025316, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18926, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.447274827586207 ], [ 29.778356455696201, -1.447544310344828 ], [ 29.77862594936709, -1.447544310344828 ], [ 29.77862594936709, -1.447274827586207 ], [ 29.778356455696201, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18927, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.447274827586207 ], [ 29.77862594936709, -1.447544310344828 ], [ 29.778895443037975, -1.447544310344828 ], [ 29.778895443037975, -1.447274827586207 ], [ 29.77862594936709, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18928, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.447274827586207 ], [ 29.778895443037975, -1.447544310344828 ], [ 29.77916493670886, -1.447544310344828 ], [ 29.77916493670886, -1.447274827586207 ], [ 29.778895443037975, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18929, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.447274827586207 ], [ 29.779703924050633, -1.447544310344828 ], [ 29.779973417721518, -1.447544310344828 ], [ 29.779973417721518, -1.447274827586207 ], [ 29.779703924050633, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18930, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.447274827586207 ], [ 29.780242911392406, -1.447813793103448 ], [ 29.780512405063291, -1.447813793103448 ], [ 29.780512405063291, -1.447274827586207 ], [ 29.780242911392406, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18931, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.447274827586207 ], [ 29.780512405063291, -1.447544310344828 ], [ 29.780781898734176, -1.447544310344828 ], [ 29.780781898734176, -1.447274827586207 ], [ 29.780512405063291, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18932, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.447274827586207 ], [ 29.781590379746834, -1.447813793103448 ], [ 29.781859873417723, -1.447813793103448 ], [ 29.781859873417723, -1.447274827586207 ], [ 29.781590379746834, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18933, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.447274827586207 ], [ 29.782398860759493, -1.447813793103448 ], [ 29.782668354430381, -1.447813793103448 ], [ 29.782668354430381, -1.447274827586207 ], [ 29.782398860759493, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18934, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.447274827586207 ], [ 29.782937848101266, -1.447813793103448 ], [ 29.783207341772151, -1.447813793103448 ], [ 29.783207341772151, -1.447274827586207 ], [ 29.782937848101266, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18935, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.447274827586207 ], [ 29.783207341772151, -1.447544310344828 ], [ 29.783476835443039, -1.447544310344828 ], [ 29.783476835443039, -1.447274827586207 ], [ 29.783207341772151, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18936, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.447274827586207 ], [ 29.784015822784809, -1.447813793103448 ], [ 29.784285316455698, -1.447813793103448 ], [ 29.784285316455698, -1.447274827586207 ], [ 29.784015822784809, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18937, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.447274827586207 ], [ 29.784285316455698, -1.447544310344828 ], [ 29.784554810126583, -1.447544310344828 ], [ 29.784554810126583, -1.447274827586207 ], [ 29.784285316455698, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18938, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.447274827586207 ], [ 29.784554810126583, -1.447544310344828 ], [ 29.784824303797468, -1.447544310344828 ], [ 29.784824303797468, -1.447274827586207 ], [ 29.784554810126583, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18939, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.447274827586207 ], [ 29.784824303797468, -1.447813793103448 ], [ 29.785093797468356, -1.447813793103448 ], [ 29.785093797468356, -1.447274827586207 ], [ 29.784824303797468, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18940, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.447274827586207 ], [ 29.785093797468356, -1.447544310344828 ], [ 29.785363291139241, -1.447544310344828 ], [ 29.785363291139241, -1.447274827586207 ], [ 29.785093797468356, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18941, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.447274827586207 ], [ 29.785363291139241, -1.447544310344828 ], [ 29.785632784810126, -1.447544310344828 ], [ 29.785632784810126, -1.447274827586207 ], [ 29.785363291139241, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18942, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.447274827586207 ], [ 29.785632784810126, -1.447544310344828 ], [ 29.785902278481014, -1.447544310344828 ], [ 29.785902278481014, -1.447274827586207 ], [ 29.785632784810126, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18943, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.447274827586207 ], [ 29.785902278481014, -1.447544310344828 ], [ 29.786171772151899, -1.447544310344828 ], [ 29.786171772151899, -1.447274827586207 ], [ 29.785902278481014, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18944, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.447274827586207 ], [ 29.786171772151899, -1.447544310344828 ], [ 29.786441265822784, -1.447544310344828 ], [ 29.786441265822784, -1.447274827586207 ], [ 29.786171772151899, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18945, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.447274827586207 ], [ 29.786441265822784, -1.447544310344828 ], [ 29.786710759493673, -1.447544310344828 ], [ 29.786710759493673, -1.447274827586207 ], [ 29.786441265822784, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18946, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.447274827586207 ], [ 29.786710759493673, -1.447544310344828 ], [ 29.786980253164558, -1.447544310344828 ], [ 29.786980253164558, -1.447274827586207 ], [ 29.786710759493673, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18947, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.447274827586207 ], [ 29.786980253164558, -1.447544310344828 ], [ 29.787249746835442, -1.447544310344828 ], [ 29.787249746835442, -1.447274827586207 ], [ 29.786980253164558, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18948, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.447274827586207 ], [ 29.787249746835442, -1.447544310344828 ], [ 29.787519240506327, -1.447544310344828 ], [ 29.787519240506327, -1.447274827586207 ], [ 29.787249746835442, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18949, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.447274827586207 ], [ 29.787519240506327, -1.447544310344828 ], [ 29.787788734177216, -1.447544310344828 ], [ 29.787788734177216, -1.447274827586207 ], [ 29.787519240506327, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18950, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.447274827586207 ], [ 29.793987088607594, -1.447544310344828 ], [ 29.794256582278482, -1.447544310344828 ], [ 29.794256582278482, -1.447274827586207 ], [ 29.793987088607594, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18951, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.447274827586207 ], [ 29.794256582278482, -1.447544310344828 ], [ 29.794526075949367, -1.447544310344828 ], [ 29.794526075949367, -1.447274827586207 ], [ 29.794256582278482, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18952, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.447274827586207 ], [ 29.794526075949367, -1.447544310344828 ], [ 29.794795569620252, -1.447544310344828 ], [ 29.794795569620252, -1.447274827586207 ], [ 29.794526075949367, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18953, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.447274827586207 ], [ 29.794795569620252, -1.447544310344828 ], [ 29.79506506329114, -1.447544310344828 ], [ 29.79506506329114, -1.447274827586207 ], [ 29.794795569620252, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18954, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.447274827586207 ], [ 29.79506506329114, -1.447544310344828 ], [ 29.795334556962025, -1.447544310344828 ], [ 29.795334556962025, -1.447274827586207 ], [ 29.79506506329114, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18955, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.447274827586207 ], [ 29.795334556962025, -1.447544310344828 ], [ 29.79560405063291, -1.447544310344828 ], [ 29.79560405063291, -1.447274827586207 ], [ 29.795334556962025, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18956, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.447274827586207 ], [ 29.79560405063291, -1.447544310344828 ], [ 29.795873544303799, -1.447544310344828 ], [ 29.795873544303799, -1.447274827586207 ], [ 29.79560405063291, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18957, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.447274827586207 ], [ 29.795873544303799, -1.447544310344828 ], [ 29.796143037974684, -1.447544310344828 ], [ 29.796143037974684, -1.447274827586207 ], [ 29.795873544303799, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18958, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.447274827586207 ], [ 29.796143037974684, -1.447544310344828 ], [ 29.796412531645569, -1.447544310344828 ], [ 29.796412531645569, -1.447274827586207 ], [ 29.796143037974684, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18959, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.447274827586207 ], [ 29.796412531645569, -1.447544310344828 ], [ 29.796682025316457, -1.447544310344828 ], [ 29.796682025316457, -1.447274827586207 ], [ 29.796412531645569, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18960, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.447274827586207 ], [ 29.796682025316457, -1.447544310344828 ], [ 29.796951518987342, -1.447544310344828 ], [ 29.796951518987342, -1.447274827586207 ], [ 29.796682025316457, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18961, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.447274827586207 ], [ 29.796951518987342, -1.447544310344828 ], [ 29.797221012658227, -1.447544310344828 ], [ 29.797221012658227, -1.447274827586207 ], [ 29.796951518987342, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18962, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.447274827586207 ], [ 29.797221012658227, -1.447544310344828 ], [ 29.797490506329115, -1.447544310344828 ], [ 29.797490506329115, -1.447274827586207 ], [ 29.797221012658227, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18963, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.447274827586207 ], [ 29.797490506329115, -1.447544310344828 ], [ 29.79776, -1.447544310344828 ], [ 29.79776, -1.447274827586207 ], [ 29.797490506329115, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18964, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.447274827586207 ], [ 29.79776, -1.447544310344828 ], [ 29.798029493670885, -1.447544310344828 ], [ 29.798029493670885, -1.447274827586207 ], [ 29.79776, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18965, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.447274827586207 ], [ 29.798029493670885, -1.447813793103448 ], [ 29.798298987341774, -1.447813793103448 ], [ 29.798298987341774, -1.447274827586207 ], [ 29.798029493670885, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18966, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.447274827586207 ], [ 29.803149873417723, -1.447544310344828 ], [ 29.803419367088608, -1.447544310344828 ], [ 29.803419367088608, -1.447274827586207 ], [ 29.803149873417723, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18967, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.447274827586207 ], [ 29.803419367088608, -1.447544310344828 ], [ 29.803688860759493, -1.447544310344828 ], [ 29.803688860759493, -1.447274827586207 ], [ 29.803419367088608, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18968, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.447274827586207 ], [ 29.803688860759493, -1.447544310344828 ], [ 29.803958354430382, -1.447544310344828 ], [ 29.803958354430382, -1.447274827586207 ], [ 29.803688860759493, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18969, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.447274827586207 ], [ 29.803958354430382, -1.447544310344828 ], [ 29.804227848101267, -1.447544310344828 ], [ 29.804227848101267, -1.447274827586207 ], [ 29.803958354430382, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18970, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.447274827586207 ], [ 29.804227848101267, -1.447544310344828 ], [ 29.804497341772151, -1.447544310344828 ], [ 29.804497341772151, -1.447274827586207 ], [ 29.804227848101267, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18971, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.447274827586207 ], [ 29.804497341772151, -1.447544310344828 ], [ 29.80476683544304, -1.447544310344828 ], [ 29.80476683544304, -1.447274827586207 ], [ 29.804497341772151, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18972, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.447274827586207 ], [ 29.80476683544304, -1.447544310344828 ], [ 29.805036329113925, -1.447544310344828 ], [ 29.805036329113925, -1.447274827586207 ], [ 29.80476683544304, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18973, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.447274827586207 ], [ 29.805036329113925, -1.447544310344828 ], [ 29.80530582278481, -1.447544310344828 ], [ 29.80530582278481, -1.447274827586207 ], [ 29.805036329113925, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18974, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.447274827586207 ], [ 29.80530582278481, -1.447544310344828 ], [ 29.805575316455698, -1.447544310344828 ], [ 29.805575316455698, -1.447274827586207 ], [ 29.80530582278481, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18975, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.447274827586207 ], [ 29.805575316455698, -1.447544310344828 ], [ 29.805844810126583, -1.447544310344828 ], [ 29.805844810126583, -1.447274827586207 ], [ 29.805575316455698, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18976, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.447274827586207 ], [ 29.805844810126583, -1.447544310344828 ], [ 29.806114303797468, -1.447544310344828 ], [ 29.806114303797468, -1.447274827586207 ], [ 29.805844810126583, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18977, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.447274827586207 ], [ 29.806114303797468, -1.447544310344828 ], [ 29.806383797468357, -1.447544310344828 ], [ 29.806383797468357, -1.447274827586207 ], [ 29.806114303797468, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18978, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.447274827586207 ], [ 29.806383797468357, -1.447544310344828 ], [ 29.806653291139241, -1.447544310344828 ], [ 29.806653291139241, -1.447274827586207 ], [ 29.806383797468357, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18979, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.447274827586207 ], [ 29.806653291139241, -1.447544310344828 ], [ 29.806922784810126, -1.447544310344828 ], [ 29.806922784810126, -1.447274827586207 ], [ 29.806653291139241, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18980, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.447274827586207 ], [ 29.806922784810126, -1.447544310344828 ], [ 29.807192278481015, -1.447544310344828 ], [ 29.807192278481015, -1.447274827586207 ], [ 29.806922784810126, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18981, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.447274827586207 ], [ 29.807192278481015, -1.447544310344828 ], [ 29.8074617721519, -1.447544310344828 ], [ 29.8074617721519, -1.447274827586207 ], [ 29.807192278481015, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18982, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.447274827586207 ], [ 29.8074617721519, -1.447544310344828 ], [ 29.807731265822785, -1.447544310344828 ], [ 29.807731265822785, -1.447274827586207 ], [ 29.8074617721519, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18983, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749251139240506, -1.447544310344828 ], [ 29.749251139240506, -1.448083275862069 ], [ 29.749520632911391, -1.448083275862069 ], [ 29.749520632911391, -1.447544310344828 ], [ 29.749251139240506, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18984, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.447544310344828 ], [ 29.749520632911391, -1.447813793103448 ], [ 29.749790126582276, -1.447813793103448 ], [ 29.749790126582276, -1.447544310344828 ], [ 29.749520632911391, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18985, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.447544310344828 ], [ 29.749790126582276, -1.447813793103448 ], [ 29.750059620253165, -1.447813793103448 ], [ 29.750059620253165, -1.447544310344828 ], [ 29.749790126582276, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18986, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.447544310344828 ], [ 29.750059620253165, -1.447813793103448 ], [ 29.75032911392405, -1.447813793103448 ], [ 29.75032911392405, -1.447544310344828 ], [ 29.750059620253165, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18987, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.447544310344828 ], [ 29.75032911392405, -1.447813793103448 ], [ 29.750598607594934, -1.447813793103448 ], [ 29.750598607594934, -1.447544310344828 ], [ 29.75032911392405, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18988, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.447544310344828 ], [ 29.750598607594934, -1.447813793103448 ], [ 29.750868101265823, -1.447813793103448 ], [ 29.750868101265823, -1.447544310344828 ], [ 29.750598607594934, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18989, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.447544310344828 ], [ 29.750868101265823, -1.447813793103448 ], [ 29.751137594936708, -1.447813793103448 ], [ 29.751137594936708, -1.447544310344828 ], [ 29.750868101265823, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18990, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.447544310344828 ], [ 29.751137594936708, -1.447813793103448 ], [ 29.751407088607593, -1.447813793103448 ], [ 29.751407088607593, -1.447544310344828 ], [ 29.751137594936708, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18991, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.447544310344828 ], [ 29.751407088607593, -1.447813793103448 ], [ 29.751676582278481, -1.447813793103448 ], [ 29.751676582278481, -1.447544310344828 ], [ 29.751407088607593, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18992, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.447544310344828 ], [ 29.751676582278481, -1.447813793103448 ], [ 29.751946075949366, -1.447813793103448 ], [ 29.751946075949366, -1.447544310344828 ], [ 29.751676582278481, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18993, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.447544310344828 ], [ 29.751946075949366, -1.447813793103448 ], [ 29.752215569620251, -1.447813793103448 ], [ 29.752215569620251, -1.447544310344828 ], [ 29.751946075949366, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18994, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.447544310344828 ], [ 29.752215569620251, -1.447813793103448 ], [ 29.75248506329114, -1.447813793103448 ], [ 29.75248506329114, -1.447544310344828 ], [ 29.752215569620251, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18995, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.447544310344828 ], [ 29.75248506329114, -1.447813793103448 ], [ 29.752754556962024, -1.447813793103448 ], [ 29.752754556962024, -1.447544310344828 ], [ 29.75248506329114, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18996, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.447544310344828 ], [ 29.752754556962024, -1.447813793103448 ], [ 29.753024050632909, -1.447813793103448 ], [ 29.753024050632909, -1.447544310344828 ], [ 29.752754556962024, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18997, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.447544310344828 ], [ 29.753024050632909, -1.447813793103448 ], [ 29.753293544303798, -1.447813793103448 ], [ 29.753293544303798, -1.447544310344828 ], [ 29.753024050632909, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18998, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.447544310344828 ], [ 29.753293544303798, -1.447813793103448 ], [ 29.753563037974683, -1.447813793103448 ], [ 29.753563037974683, -1.447544310344828 ], [ 29.753293544303798, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 18999, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.447544310344828 ], [ 29.753563037974683, -1.447813793103448 ], [ 29.753832531645568, -1.447813793103448 ], [ 29.753832531645568, -1.447544310344828 ], [ 29.753563037974683, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19000, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.447544310344828 ], [ 29.753832531645568, -1.447813793103448 ], [ 29.754102025316456, -1.447813793103448 ], [ 29.754102025316456, -1.447544310344828 ], [ 29.753832531645568, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19001, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.447544310344828 ], [ 29.754102025316456, -1.447813793103448 ], [ 29.754371518987341, -1.447813793103448 ], [ 29.754371518987341, -1.447544310344828 ], [ 29.754102025316456, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19002, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.447544310344828 ], [ 29.754371518987341, -1.447813793103448 ], [ 29.754641012658226, -1.447813793103448 ], [ 29.754641012658226, -1.447544310344828 ], [ 29.754371518987341, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19003, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.447544310344828 ], [ 29.754641012658226, -1.448083275862069 ], [ 29.754910506329114, -1.448083275862069 ], [ 29.754910506329114, -1.447544310344828 ], [ 29.754641012658226, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19004, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.447544310344828 ], [ 29.754910506329114, -1.447813793103448 ], [ 29.75518, -1.447813793103448 ], [ 29.75518, -1.447544310344828 ], [ 29.754910506329114, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19005, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.447544310344828 ], [ 29.75518, -1.447813793103448 ], [ 29.755449493670884, -1.447813793103448 ], [ 29.755449493670884, -1.447544310344828 ], [ 29.75518, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19006, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.447544310344828 ], [ 29.755449493670884, -1.447813793103448 ], [ 29.755718987341773, -1.447813793103448 ], [ 29.755718987341773, -1.447544310344828 ], [ 29.755449493670884, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19007, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.447544310344828 ], [ 29.755988481012658, -1.448083275862069 ], [ 29.756257974683542, -1.448083275862069 ], [ 29.756257974683542, -1.447544310344828 ], [ 29.755988481012658, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19008, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.447544310344828 ], [ 29.756257974683542, -1.447813793103448 ], [ 29.756796962025316, -1.447813793103448 ], [ 29.756796962025316, -1.447544310344828 ], [ 29.756257974683542, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19009, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.447544310344828 ], [ 29.757066455696201, -1.448083275862069 ], [ 29.757335949367089, -1.448083275862069 ], [ 29.757335949367089, -1.447544310344828 ], [ 29.757066455696201, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19010, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.447544310344828 ], [ 29.757335949367089, -1.447813793103448 ], [ 29.757605443037974, -1.447813793103448 ], [ 29.757605443037974, -1.447544310344828 ], [ 29.757335949367089, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19011, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.447544310344828 ], [ 29.757605443037974, -1.447813793103448 ], [ 29.757874936708859, -1.447813793103448 ], [ 29.757874936708859, -1.447544310344828 ], [ 29.757605443037974, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19012, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.447544310344828 ], [ 29.757874936708859, -1.448083275862069 ], [ 29.758144430379748, -1.448083275862069 ], [ 29.758144430379748, -1.447544310344828 ], [ 29.757874936708859, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19013, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.447544310344828 ], [ 29.758144430379748, -1.447813793103448 ], [ 29.758413924050632, -1.447813793103448 ], [ 29.758413924050632, -1.447544310344828 ], [ 29.758144430379748, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19014, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.447544310344828 ], [ 29.758413924050632, -1.447813793103448 ], [ 29.758683417721517, -1.447813793103448 ], [ 29.758683417721517, -1.447544310344828 ], [ 29.758413924050632, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19015, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.447544310344828 ], [ 29.758683417721517, -1.448083275862069 ], [ 29.758952911392406, -1.448083275862069 ], [ 29.758952911392406, -1.447544310344828 ], [ 29.758683417721517, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19016, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.447544310344828 ], [ 29.758952911392406, -1.447813793103448 ], [ 29.759222405063291, -1.447813793103448 ], [ 29.759222405063291, -1.447544310344828 ], [ 29.758952911392406, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19017, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.447544310344828 ], [ 29.759222405063291, -1.447813793103448 ], [ 29.759491898734176, -1.447813793103448 ], [ 29.759491898734176, -1.447544310344828 ], [ 29.759222405063291, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19018, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.447544310344828 ], [ 29.759491898734176, -1.447813793103448 ], [ 29.759761392405064, -1.447813793103448 ], [ 29.759761392405064, -1.447544310344828 ], [ 29.759491898734176, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19019, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.447544310344828 ], [ 29.759761392405064, -1.447813793103448 ], [ 29.760030886075949, -1.447813793103448 ], [ 29.760030886075949, -1.447544310344828 ], [ 29.759761392405064, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19020, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.447544310344828 ], [ 29.760030886075949, -1.447813793103448 ], [ 29.760300379746834, -1.447813793103448 ], [ 29.760300379746834, -1.447544310344828 ], [ 29.760030886075949, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19021, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.447544310344828 ], [ 29.760300379746834, -1.447813793103448 ], [ 29.760569873417722, -1.447813793103448 ], [ 29.760569873417722, -1.447544310344828 ], [ 29.760300379746834, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19022, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.447544310344828 ], [ 29.760569873417722, -1.447813793103448 ], [ 29.760839367088607, -1.447813793103448 ], [ 29.760839367088607, -1.447544310344828 ], [ 29.760569873417722, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19023, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.447544310344828 ], [ 29.760839367088607, -1.447813793103448 ], [ 29.761108860759492, -1.447813793103448 ], [ 29.761108860759492, -1.447544310344828 ], [ 29.760839367088607, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19024, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.447544310344828 ], [ 29.761108860759492, -1.447813793103448 ], [ 29.761378354430381, -1.447813793103448 ], [ 29.761378354430381, -1.447544310344828 ], [ 29.761108860759492, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19025, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.447544310344828 ], [ 29.761378354430381, -1.447813793103448 ], [ 29.761647848101266, -1.447813793103448 ], [ 29.761647848101266, -1.447544310344828 ], [ 29.761378354430381, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19026, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.447544310344828 ], [ 29.761647848101266, -1.447813793103448 ], [ 29.761917341772151, -1.447813793103448 ], [ 29.761917341772151, -1.447544310344828 ], [ 29.761647848101266, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19027, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.447544310344828 ], [ 29.761917341772151, -1.447813793103448 ], [ 29.762186835443039, -1.447813793103448 ], [ 29.762186835443039, -1.447544310344828 ], [ 29.761917341772151, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19028, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.447544310344828 ], [ 29.762186835443039, -1.447813793103448 ], [ 29.762995316455697, -1.447813793103448 ], [ 29.762995316455697, -1.447544310344828 ], [ 29.762186835443039, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19029, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.447544310344828 ], [ 29.762995316455697, -1.447813793103448 ], [ 29.763264810126582, -1.447813793103448 ], [ 29.763264810126582, -1.447544310344828 ], [ 29.762995316455697, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19030, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.447544310344828 ], [ 29.763264810126582, -1.447813793103448 ], [ 29.763534303797467, -1.447813793103448 ], [ 29.763534303797467, -1.447544310344828 ], [ 29.763264810126582, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19031, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.447544310344828 ], [ 29.763534303797467, -1.447813793103448 ], [ 29.76407329113924, -1.447813793103448 ], [ 29.76407329113924, -1.447544310344828 ], [ 29.763534303797467, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19032, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.447544310344828 ], [ 29.76407329113924, -1.447813793103448 ], [ 29.764342784810125, -1.447813793103448 ], [ 29.764342784810125, -1.447544310344828 ], [ 29.76407329113924, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19033, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.447544310344828 ], [ 29.764342784810125, -1.447813793103448 ], [ 29.764612278481014, -1.447813793103448 ], [ 29.764612278481014, -1.447544310344828 ], [ 29.764342784810125, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19034, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.447544310344828 ], [ 29.764612278481014, -1.447813793103448 ], [ 29.764881772151899, -1.447813793103448 ], [ 29.764881772151899, -1.447544310344828 ], [ 29.764612278481014, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19035, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.447544310344828 ], [ 29.764881772151899, -1.447813793103448 ], [ 29.765151265822784, -1.447813793103448 ], [ 29.765151265822784, -1.447544310344828 ], [ 29.764881772151899, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19036, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.447544310344828 ], [ 29.765151265822784, -1.447813793103448 ], [ 29.765420759493672, -1.447813793103448 ], [ 29.765420759493672, -1.447544310344828 ], [ 29.765151265822784, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19037, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.447544310344828 ], [ 29.765420759493672, -1.447813793103448 ], [ 29.765959746835442, -1.447813793103448 ], [ 29.765959746835442, -1.447544310344828 ], [ 29.765420759493672, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19038, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.447544310344828 ], [ 29.765959746835442, -1.447813793103448 ], [ 29.767576708860759, -1.447813793103448 ], [ 29.767576708860759, -1.447544310344828 ], [ 29.765959746835442, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19039, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.447544310344828 ], [ 29.767576708860759, -1.447813793103448 ], [ 29.767846202531643, -1.447813793103448 ], [ 29.767846202531643, -1.447544310344828 ], [ 29.767576708860759, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19040, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.447544310344828 ], [ 29.767846202531643, -1.447813793103448 ], [ 29.768115696202532, -1.447813793103448 ], [ 29.768115696202532, -1.447544310344828 ], [ 29.767846202531643, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19041, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.447544310344828 ], [ 29.768115696202532, -1.447813793103448 ], [ 29.768385189873417, -1.447813793103448 ], [ 29.768385189873417, -1.447544310344828 ], [ 29.768115696202532, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19042, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.447544310344828 ], [ 29.768385189873417, -1.447813793103448 ], [ 29.768654683544302, -1.447813793103448 ], [ 29.768654683544302, -1.447544310344828 ], [ 29.768385189873417, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19043, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.447544310344828 ], [ 29.768654683544302, -1.447813793103448 ], [ 29.76892417721519, -1.447813793103448 ], [ 29.76892417721519, -1.447544310344828 ], [ 29.768654683544302, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19044, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.447544310344828 ], [ 29.76892417721519, -1.448083275862069 ], [ 29.769193670886075, -1.448083275862069 ], [ 29.769193670886075, -1.447544310344828 ], [ 29.76892417721519, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19045, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.447544310344828 ], [ 29.769193670886075, -1.447813793103448 ], [ 29.76946316455696, -1.447813793103448 ], [ 29.76946316455696, -1.447544310344828 ], [ 29.769193670886075, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19046, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.447544310344828 ], [ 29.76946316455696, -1.447813793103448 ], [ 29.769732658227849, -1.447813793103448 ], [ 29.769732658227849, -1.447544310344828 ], [ 29.76946316455696, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19047, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.447544310344828 ], [ 29.770271645569618, -1.448083275862069 ], [ 29.770541139240507, -1.448083275862069 ], [ 29.770541139240507, -1.447544310344828 ], [ 29.770271645569618, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19048, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.447544310344828 ], [ 29.771080126582277, -1.448083275862069 ], [ 29.771349620253165, -1.448083275862069 ], [ 29.771349620253165, -1.447544310344828 ], [ 29.771080126582277, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19049, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.447544310344828 ], [ 29.771888607594935, -1.448083275862069 ], [ 29.772158101265823, -1.448083275862069 ], [ 29.772158101265823, -1.447544310344828 ], [ 29.771888607594935, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19050, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.447544310344828 ], [ 29.772697088607593, -1.448083275862069 ], [ 29.772966582278482, -1.448083275862069 ], [ 29.772966582278482, -1.447544310344828 ], [ 29.772697088607593, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19051, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.447544310344828 ], [ 29.773505569620252, -1.448083275862069 ], [ 29.77377506329114, -1.448083275862069 ], [ 29.77377506329114, -1.447544310344828 ], [ 29.773505569620252, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19052, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.447544310344828 ], [ 29.774044556962025, -1.447813793103448 ], [ 29.77431405063291, -1.447813793103448 ], [ 29.77431405063291, -1.447544310344828 ], [ 29.774044556962025, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19053, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.447544310344828 ], [ 29.77431405063291, -1.447813793103448 ], [ 29.774853037974683, -1.447813793103448 ], [ 29.774853037974683, -1.447544310344828 ], [ 29.77431405063291, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19054, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.447544310344828 ], [ 29.775122531645568, -1.447813793103448 ], [ 29.775392025316457, -1.447813793103448 ], [ 29.775392025316457, -1.447544310344828 ], [ 29.775122531645568, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19055, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.447544310344828 ], [ 29.775392025316457, -1.447813793103448 ], [ 29.775661518987341, -1.447813793103448 ], [ 29.775661518987341, -1.447544310344828 ], [ 29.775392025316457, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19056, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.447544310344828 ], [ 29.775661518987341, -1.448083275862069 ], [ 29.775931012658226, -1.448083275862069 ], [ 29.775931012658226, -1.447544310344828 ], [ 29.775661518987341, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19057, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.447544310344828 ], [ 29.775931012658226, -1.447813793103448 ], [ 29.776200506329115, -1.447813793103448 ], [ 29.776200506329115, -1.447544310344828 ], [ 29.775931012658226, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19058, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.447544310344828 ], [ 29.776200506329115, -1.447813793103448 ], [ 29.77647, -1.447813793103448 ], [ 29.77647, -1.447544310344828 ], [ 29.776200506329115, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19059, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.447544310344828 ], [ 29.77647, -1.448083275862069 ], [ 29.776739493670885, -1.448083275862069 ], [ 29.776739493670885, -1.447544310344828 ], [ 29.77647, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19060, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.447544310344828 ], [ 29.777008987341773, -1.447813793103448 ], [ 29.777278481012658, -1.447813793103448 ], [ 29.777278481012658, -1.447544310344828 ], [ 29.777008987341773, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19061, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.447544310344828 ], [ 29.777278481012658, -1.448083275862069 ], [ 29.777547974683543, -1.448083275862069 ], [ 29.777547974683543, -1.447544310344828 ], [ 29.777278481012658, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19062, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.447544310344828 ], [ 29.778895443037975, -1.447813793103448 ], [ 29.77916493670886, -1.447813793103448 ], [ 29.77916493670886, -1.447544310344828 ], [ 29.778895443037975, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19063, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.447544310344828 ], [ 29.784554810126583, -1.448083275862069 ], [ 29.784824303797468, -1.448083275862069 ], [ 29.784824303797468, -1.447544310344828 ], [ 29.784554810126583, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19064, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.447544310344828 ], [ 29.785363291139241, -1.447813793103448 ], [ 29.785632784810126, -1.447813793103448 ], [ 29.785632784810126, -1.447544310344828 ], [ 29.785363291139241, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19065, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.447544310344828 ], [ 29.785632784810126, -1.447813793103448 ], [ 29.785902278481014, -1.447813793103448 ], [ 29.785902278481014, -1.447544310344828 ], [ 29.785632784810126, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19066, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.447544310344828 ], [ 29.785902278481014, -1.448083275862069 ], [ 29.786171772151899, -1.448083275862069 ], [ 29.786171772151899, -1.447544310344828 ], [ 29.785902278481014, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19067, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.447544310344828 ], [ 29.786171772151899, -1.447813793103448 ], [ 29.786441265822784, -1.447813793103448 ], [ 29.786441265822784, -1.447544310344828 ], [ 29.786171772151899, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19068, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.447544310344828 ], [ 29.786441265822784, -1.447813793103448 ], [ 29.786710759493673, -1.447813793103448 ], [ 29.786710759493673, -1.447544310344828 ], [ 29.786441265822784, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19069, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.447544310344828 ], [ 29.786710759493673, -1.447813793103448 ], [ 29.786980253164558, -1.447813793103448 ], [ 29.786980253164558, -1.447544310344828 ], [ 29.786710759493673, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19070, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.447544310344828 ], [ 29.786980253164558, -1.447813793103448 ], [ 29.787249746835442, -1.447813793103448 ], [ 29.787249746835442, -1.447544310344828 ], [ 29.786980253164558, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19071, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.447544310344828 ], [ 29.787249746835442, -1.447813793103448 ], [ 29.787519240506327, -1.447813793103448 ], [ 29.787519240506327, -1.447544310344828 ], [ 29.787249746835442, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19072, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.447544310344828 ], [ 29.787519240506327, -1.448083275862069 ], [ 29.787788734177216, -1.448083275862069 ], [ 29.787788734177216, -1.447544310344828 ], [ 29.787519240506327, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19073, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.447544310344828 ], [ 29.794256582278482, -1.447813793103448 ], [ 29.794526075949367, -1.447813793103448 ], [ 29.794526075949367, -1.447544310344828 ], [ 29.794256582278482, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19074, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.447544310344828 ], [ 29.794526075949367, -1.447813793103448 ], [ 29.794795569620252, -1.447813793103448 ], [ 29.794795569620252, -1.447544310344828 ], [ 29.794526075949367, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19075, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.447544310344828 ], [ 29.794795569620252, -1.447813793103448 ], [ 29.79506506329114, -1.447813793103448 ], [ 29.79506506329114, -1.447544310344828 ], [ 29.794795569620252, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19076, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.447544310344828 ], [ 29.79506506329114, -1.447813793103448 ], [ 29.795334556962025, -1.447813793103448 ], [ 29.795334556962025, -1.447544310344828 ], [ 29.79506506329114, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19077, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.447544310344828 ], [ 29.795334556962025, -1.447813793103448 ], [ 29.79560405063291, -1.447813793103448 ], [ 29.79560405063291, -1.447544310344828 ], [ 29.795334556962025, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19078, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.447544310344828 ], [ 29.79560405063291, -1.447813793103448 ], [ 29.795873544303799, -1.447813793103448 ], [ 29.795873544303799, -1.447544310344828 ], [ 29.79560405063291, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19079, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.447544310344828 ], [ 29.795873544303799, -1.447813793103448 ], [ 29.796143037974684, -1.447813793103448 ], [ 29.796143037974684, -1.447544310344828 ], [ 29.795873544303799, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19080, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.447544310344828 ], [ 29.796143037974684, -1.447813793103448 ], [ 29.796412531645569, -1.447813793103448 ], [ 29.796412531645569, -1.447544310344828 ], [ 29.796143037974684, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19081, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.447544310344828 ], [ 29.796412531645569, -1.447813793103448 ], [ 29.796682025316457, -1.447813793103448 ], [ 29.796682025316457, -1.447544310344828 ], [ 29.796412531645569, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19082, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.447544310344828 ], [ 29.796682025316457, -1.447813793103448 ], [ 29.796951518987342, -1.447813793103448 ], [ 29.796951518987342, -1.447544310344828 ], [ 29.796682025316457, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19083, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.447544310344828 ], [ 29.796951518987342, -1.447813793103448 ], [ 29.797221012658227, -1.447813793103448 ], [ 29.797221012658227, -1.447544310344828 ], [ 29.796951518987342, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19084, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.447544310344828 ], [ 29.797221012658227, -1.447813793103448 ], [ 29.797490506329115, -1.447813793103448 ], [ 29.797490506329115, -1.447544310344828 ], [ 29.797221012658227, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19085, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.447544310344828 ], [ 29.797490506329115, -1.447813793103448 ], [ 29.79776, -1.447813793103448 ], [ 29.79776, -1.447544310344828 ], [ 29.797490506329115, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19086, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.447544310344828 ], [ 29.79776, -1.447813793103448 ], [ 29.798029493670885, -1.447813793103448 ], [ 29.798029493670885, -1.447544310344828 ], [ 29.79776, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19087, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.447544310344828 ], [ 29.80261088607595, -1.447813793103448 ], [ 29.802880379746835, -1.447813793103448 ], [ 29.802880379746835, -1.447544310344828 ], [ 29.80261088607595, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19088, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.447544310344828 ], [ 29.802880379746835, -1.447813793103448 ], [ 29.803149873417723, -1.447813793103448 ], [ 29.803149873417723, -1.447544310344828 ], [ 29.802880379746835, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19089, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.447544310344828 ], [ 29.803149873417723, -1.447813793103448 ], [ 29.803419367088608, -1.447813793103448 ], [ 29.803419367088608, -1.447544310344828 ], [ 29.803149873417723, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19090, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.447544310344828 ], [ 29.803419367088608, -1.447813793103448 ], [ 29.803688860759493, -1.447813793103448 ], [ 29.803688860759493, -1.447544310344828 ], [ 29.803419367088608, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19091, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.447544310344828 ], [ 29.803688860759493, -1.447813793103448 ], [ 29.803958354430382, -1.447813793103448 ], [ 29.803958354430382, -1.447544310344828 ], [ 29.803688860759493, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19092, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.447544310344828 ], [ 29.803958354430382, -1.447813793103448 ], [ 29.804227848101267, -1.447813793103448 ], [ 29.804227848101267, -1.447544310344828 ], [ 29.803958354430382, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19093, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.447544310344828 ], [ 29.804227848101267, -1.447813793103448 ], [ 29.804497341772151, -1.447813793103448 ], [ 29.804497341772151, -1.447544310344828 ], [ 29.804227848101267, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19094, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.447544310344828 ], [ 29.804497341772151, -1.447813793103448 ], [ 29.80476683544304, -1.447813793103448 ], [ 29.80476683544304, -1.447544310344828 ], [ 29.804497341772151, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19095, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.447544310344828 ], [ 29.80476683544304, -1.447813793103448 ], [ 29.805036329113925, -1.447813793103448 ], [ 29.805036329113925, -1.447544310344828 ], [ 29.80476683544304, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19096, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.447544310344828 ], [ 29.805036329113925, -1.447813793103448 ], [ 29.80530582278481, -1.447813793103448 ], [ 29.80530582278481, -1.447544310344828 ], [ 29.805036329113925, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19097, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.447544310344828 ], [ 29.80530582278481, -1.448083275862069 ], [ 29.805575316455698, -1.448083275862069 ], [ 29.805575316455698, -1.447544310344828 ], [ 29.80530582278481, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19098, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.447544310344828 ], [ 29.805575316455698, -1.447813793103448 ], [ 29.805844810126583, -1.447813793103448 ], [ 29.805844810126583, -1.447544310344828 ], [ 29.805575316455698, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19099, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.447544310344828 ], [ 29.805844810126583, -1.447813793103448 ], [ 29.806114303797468, -1.447813793103448 ], [ 29.806114303797468, -1.447544310344828 ], [ 29.805844810126583, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19100, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.447544310344828 ], [ 29.806114303797468, -1.447813793103448 ], [ 29.806383797468357, -1.447813793103448 ], [ 29.806383797468357, -1.447544310344828 ], [ 29.806114303797468, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19101, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.447544310344828 ], [ 29.806383797468357, -1.447813793103448 ], [ 29.806653291139241, -1.447813793103448 ], [ 29.806653291139241, -1.447544310344828 ], [ 29.806383797468357, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19102, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.447544310344828 ], [ 29.806653291139241, -1.447813793103448 ], [ 29.806922784810126, -1.447813793103448 ], [ 29.806922784810126, -1.447544310344828 ], [ 29.806653291139241, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19103, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.447544310344828 ], [ 29.806922784810126, -1.447813793103448 ], [ 29.807192278481015, -1.447813793103448 ], [ 29.807192278481015, -1.447544310344828 ], [ 29.806922784810126, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19104, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.447544310344828 ], [ 29.807192278481015, -1.447813793103448 ], [ 29.8074617721519, -1.447813793103448 ], [ 29.8074617721519, -1.447544310344828 ], [ 29.807192278481015, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19105, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.447813793103448 ], [ 29.749520632911391, -1.448083275862069 ], [ 29.749790126582276, -1.448083275862069 ], [ 29.749790126582276, -1.447813793103448 ], [ 29.749520632911391, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19106, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.447813793103448 ], [ 29.749790126582276, -1.448083275862069 ], [ 29.750059620253165, -1.448083275862069 ], [ 29.750059620253165, -1.447813793103448 ], [ 29.749790126582276, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19107, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.447813793103448 ], [ 29.750059620253165, -1.448083275862069 ], [ 29.75032911392405, -1.448083275862069 ], [ 29.75032911392405, -1.447813793103448 ], [ 29.750059620253165, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19108, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.447813793103448 ], [ 29.75032911392405, -1.448083275862069 ], [ 29.750598607594934, -1.448083275862069 ], [ 29.750598607594934, -1.447813793103448 ], [ 29.75032911392405, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19109, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.447813793103448 ], [ 29.750598607594934, -1.448083275862069 ], [ 29.750868101265823, -1.448083275862069 ], [ 29.750868101265823, -1.447813793103448 ], [ 29.750598607594934, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19110, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.447813793103448 ], [ 29.750868101265823, -1.448083275862069 ], [ 29.751137594936708, -1.448083275862069 ], [ 29.751137594936708, -1.447813793103448 ], [ 29.750868101265823, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19111, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.447813793103448 ], [ 29.751137594936708, -1.448083275862069 ], [ 29.751407088607593, -1.448083275862069 ], [ 29.751407088607593, -1.447813793103448 ], [ 29.751137594936708, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19112, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.447813793103448 ], [ 29.751407088607593, -1.448083275862069 ], [ 29.751676582278481, -1.448083275862069 ], [ 29.751676582278481, -1.447813793103448 ], [ 29.751407088607593, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19113, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.447813793103448 ], [ 29.751676582278481, -1.448083275862069 ], [ 29.751946075949366, -1.448083275862069 ], [ 29.751946075949366, -1.447813793103448 ], [ 29.751676582278481, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19114, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.447813793103448 ], [ 29.751946075949366, -1.448083275862069 ], [ 29.752215569620251, -1.448083275862069 ], [ 29.752215569620251, -1.447813793103448 ], [ 29.751946075949366, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19115, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.447813793103448 ], [ 29.752215569620251, -1.448083275862069 ], [ 29.75248506329114, -1.448083275862069 ], [ 29.75248506329114, -1.447813793103448 ], [ 29.752215569620251, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19116, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.447813793103448 ], [ 29.75248506329114, -1.448083275862069 ], [ 29.752754556962024, -1.448083275862069 ], [ 29.752754556962024, -1.447813793103448 ], [ 29.75248506329114, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19117, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.447813793103448 ], [ 29.752754556962024, -1.448083275862069 ], [ 29.753024050632909, -1.448083275862069 ], [ 29.753024050632909, -1.447813793103448 ], [ 29.752754556962024, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19118, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.447813793103448 ], [ 29.753024050632909, -1.448083275862069 ], [ 29.753293544303798, -1.448083275862069 ], [ 29.753293544303798, -1.447813793103448 ], [ 29.753024050632909, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19119, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.447813793103448 ], [ 29.753293544303798, -1.448083275862069 ], [ 29.753563037974683, -1.448083275862069 ], [ 29.753563037974683, -1.447813793103448 ], [ 29.753293544303798, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19120, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.447813793103448 ], [ 29.753563037974683, -1.448083275862069 ], [ 29.753832531645568, -1.448083275862069 ], [ 29.753832531645568, -1.447813793103448 ], [ 29.753563037974683, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19121, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.447813793103448 ], [ 29.753832531645568, -1.448083275862069 ], [ 29.754102025316456, -1.448083275862069 ], [ 29.754102025316456, -1.447813793103448 ], [ 29.753832531645568, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19122, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.447813793103448 ], [ 29.754102025316456, -1.448083275862069 ], [ 29.754371518987341, -1.448083275862069 ], [ 29.754371518987341, -1.447813793103448 ], [ 29.754102025316456, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19123, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.447813793103448 ], [ 29.754371518987341, -1.448083275862069 ], [ 29.754641012658226, -1.448083275862069 ], [ 29.754641012658226, -1.447813793103448 ], [ 29.754371518987341, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19124, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.447813793103448 ], [ 29.754910506329114, -1.448083275862069 ], [ 29.75518, -1.448083275862069 ], [ 29.75518, -1.447813793103448 ], [ 29.754910506329114, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19125, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.447813793103448 ], [ 29.75518, -1.448083275862069 ], [ 29.755449493670884, -1.448083275862069 ], [ 29.755449493670884, -1.447813793103448 ], [ 29.75518, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19126, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.447813793103448 ], [ 29.755718987341773, -1.448083275862069 ], [ 29.755988481012658, -1.448083275862069 ], [ 29.755988481012658, -1.447813793103448 ], [ 29.755718987341773, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19127, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.447813793103448 ], [ 29.758413924050632, -1.448083275862069 ], [ 29.758683417721517, -1.448083275862069 ], [ 29.758683417721517, -1.447813793103448 ], [ 29.758413924050632, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19128, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.447813793103448 ], [ 29.759222405063291, -1.448083275862069 ], [ 29.759491898734176, -1.448083275862069 ], [ 29.759491898734176, -1.447813793103448 ], [ 29.759222405063291, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19129, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.447813793103448 ], [ 29.759491898734176, -1.448083275862069 ], [ 29.759761392405064, -1.448083275862069 ], [ 29.759761392405064, -1.447813793103448 ], [ 29.759491898734176, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19130, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.447813793103448 ], [ 29.760030886075949, -1.448083275862069 ], [ 29.760300379746834, -1.448083275862069 ], [ 29.760300379746834, -1.447813793103448 ], [ 29.760030886075949, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19131, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.447813793103448 ], [ 29.760300379746834, -1.448083275862069 ], [ 29.760569873417722, -1.448083275862069 ], [ 29.760569873417722, -1.447813793103448 ], [ 29.760300379746834, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19132, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.447813793103448 ], [ 29.760569873417722, -1.448083275862069 ], [ 29.760839367088607, -1.448083275862069 ], [ 29.760839367088607, -1.447813793103448 ], [ 29.760569873417722, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19133, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.447813793103448 ], [ 29.760839367088607, -1.448083275862069 ], [ 29.761108860759492, -1.448083275862069 ], [ 29.761108860759492, -1.447813793103448 ], [ 29.760839367088607, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19134, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.447813793103448 ], [ 29.761108860759492, -1.448083275862069 ], [ 29.761378354430381, -1.448083275862069 ], [ 29.761378354430381, -1.447813793103448 ], [ 29.761108860759492, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19135, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.447813793103448 ], [ 29.761378354430381, -1.448083275862069 ], [ 29.761647848101266, -1.448083275862069 ], [ 29.761647848101266, -1.447813793103448 ], [ 29.761378354430381, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19136, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.447813793103448 ], [ 29.761647848101266, -1.448083275862069 ], [ 29.761917341772151, -1.448083275862069 ], [ 29.761917341772151, -1.447813793103448 ], [ 29.761647848101266, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19137, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.447813793103448 ], [ 29.761917341772151, -1.448083275862069 ], [ 29.762186835443039, -1.448083275862069 ], [ 29.762186835443039, -1.447813793103448 ], [ 29.761917341772151, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19138, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.447813793103448 ], [ 29.762186835443039, -1.448083275862069 ], [ 29.762456329113924, -1.448083275862069 ], [ 29.762456329113924, -1.447813793103448 ], [ 29.762186835443039, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19139, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.447813793103448 ], [ 29.762456329113924, -1.448083275862069 ], [ 29.762725822784809, -1.448083275862069 ], [ 29.762725822784809, -1.447813793103448 ], [ 29.762456329113924, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19140, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.447813793103448 ], [ 29.762725822784809, -1.448083275862069 ], [ 29.762995316455697, -1.448083275862069 ], [ 29.762995316455697, -1.447813793103448 ], [ 29.762725822784809, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19141, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.447813793103448 ], [ 29.762995316455697, -1.448083275862069 ], [ 29.763264810126582, -1.448083275862069 ], [ 29.763264810126582, -1.447813793103448 ], [ 29.762995316455697, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19142, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.447813793103448 ], [ 29.763264810126582, -1.448083275862069 ], [ 29.763534303797467, -1.448083275862069 ], [ 29.763534303797467, -1.447813793103448 ], [ 29.763264810126582, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19143, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.447813793103448 ], [ 29.763534303797467, -1.448083275862069 ], [ 29.76407329113924, -1.448083275862069 ], [ 29.76407329113924, -1.447813793103448 ], [ 29.763534303797467, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19144, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.447813793103448 ], [ 29.76407329113924, -1.448083275862069 ], [ 29.764342784810125, -1.448083275862069 ], [ 29.764342784810125, -1.447813793103448 ], [ 29.76407329113924, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19145, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.447813793103448 ], [ 29.764342784810125, -1.448083275862069 ], [ 29.764612278481014, -1.448083275862069 ], [ 29.764612278481014, -1.447813793103448 ], [ 29.764342784810125, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19146, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.447813793103448 ], [ 29.764612278481014, -1.448083275862069 ], [ 29.764881772151899, -1.448083275862069 ], [ 29.764881772151899, -1.447813793103448 ], [ 29.764612278481014, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19147, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.447813793103448 ], [ 29.764881772151899, -1.448083275862069 ], [ 29.765151265822784, -1.448083275862069 ], [ 29.765151265822784, -1.447813793103448 ], [ 29.764881772151899, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19148, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.447813793103448 ], [ 29.765151265822784, -1.448083275862069 ], [ 29.765420759493672, -1.448083275862069 ], [ 29.765420759493672, -1.447813793103448 ], [ 29.765151265822784, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19149, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.447813793103448 ], [ 29.765420759493672, -1.448083275862069 ], [ 29.765959746835442, -1.448083275862069 ], [ 29.765959746835442, -1.447813793103448 ], [ 29.765420759493672, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19150, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.447813793103448 ], [ 29.765959746835442, -1.448083275862069 ], [ 29.767576708860759, -1.448083275862069 ], [ 29.767576708860759, -1.447813793103448 ], [ 29.765959746835442, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.447813793103448 ], [ 29.767576708860759, -1.448083275862069 ], [ 29.767846202531643, -1.448083275862069 ], [ 29.767846202531643, -1.447813793103448 ], [ 29.767576708860759, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19152, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.447813793103448 ], [ 29.767846202531643, -1.448083275862069 ], [ 29.768115696202532, -1.448083275862069 ], [ 29.768115696202532, -1.447813793103448 ], [ 29.767846202531643, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19153, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.447813793103448 ], [ 29.768115696202532, -1.448083275862069 ], [ 29.768385189873417, -1.448083275862069 ], [ 29.768385189873417, -1.447813793103448 ], [ 29.768115696202532, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19154, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.447813793103448 ], [ 29.772158101265823, -1.448083275862069 ], [ 29.772427594936708, -1.448083275862069 ], [ 29.772427594936708, -1.447813793103448 ], [ 29.772158101265823, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19155, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.447813793103448 ], [ 29.772966582278482, -1.448083275862069 ], [ 29.773236075949367, -1.448083275862069 ], [ 29.773236075949367, -1.447813793103448 ], [ 29.772966582278482, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19156, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.447813793103448 ], [ 29.773236075949367, -1.448083275862069 ], [ 29.773505569620252, -1.448083275862069 ], [ 29.773505569620252, -1.447813793103448 ], [ 29.773236075949367, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19157, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.447813793103448 ], [ 29.77377506329114, -1.448083275862069 ], [ 29.774044556962025, -1.448083275862069 ], [ 29.774044556962025, -1.447813793103448 ], [ 29.77377506329114, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19158, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.447813793103448 ], [ 29.774044556962025, -1.448083275862069 ], [ 29.77431405063291, -1.448083275862069 ], [ 29.77431405063291, -1.447813793103448 ], [ 29.774044556962025, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19159, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.447813793103448 ], [ 29.774853037974683, -1.448083275862069 ], [ 29.775122531645568, -1.448083275862069 ], [ 29.775122531645568, -1.447813793103448 ], [ 29.774853037974683, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19160, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.447813793103448 ], [ 29.775122531645568, -1.448083275862069 ], [ 29.775392025316457, -1.448083275862069 ], [ 29.775392025316457, -1.447813793103448 ], [ 29.775122531645568, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19161, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.447813793103448 ], [ 29.775931012658226, -1.448083275862069 ], [ 29.776200506329115, -1.448083275862069 ], [ 29.776200506329115, -1.447813793103448 ], [ 29.775931012658226, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19162, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.447813793103448 ], [ 29.776739493670885, -1.448083275862069 ], [ 29.777008987341773, -1.448083275862069 ], [ 29.777008987341773, -1.447813793103448 ], [ 29.776739493670885, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19163, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.447813793103448 ], [ 29.786710759493673, -1.448083275862069 ], [ 29.786980253164558, -1.448083275862069 ], [ 29.786980253164558, -1.447813793103448 ], [ 29.786710759493673, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19164, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.447813793103448 ], [ 29.786980253164558, -1.448083275862069 ], [ 29.787249746835442, -1.448083275862069 ], [ 29.787249746835442, -1.447813793103448 ], [ 29.786980253164558, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19165, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.447813793103448 ], [ 29.787249746835442, -1.448083275862069 ], [ 29.787519240506327, -1.448083275862069 ], [ 29.787519240506327, -1.447813793103448 ], [ 29.787249746835442, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19166, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.447813793103448 ], [ 29.794256582278482, -1.448083275862069 ], [ 29.794526075949367, -1.448083275862069 ], [ 29.794526075949367, -1.447813793103448 ], [ 29.794256582278482, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19167, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.447813793103448 ], [ 29.794526075949367, -1.448083275862069 ], [ 29.794795569620252, -1.448083275862069 ], [ 29.794795569620252, -1.447813793103448 ], [ 29.794526075949367, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19168, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.447813793103448 ], [ 29.794795569620252, -1.448083275862069 ], [ 29.79506506329114, -1.448083275862069 ], [ 29.79506506329114, -1.447813793103448 ], [ 29.794795569620252, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19169, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.447813793103448 ], [ 29.79506506329114, -1.448083275862069 ], [ 29.795334556962025, -1.448083275862069 ], [ 29.795334556962025, -1.447813793103448 ], [ 29.79506506329114, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19170, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.447813793103448 ], [ 29.795334556962025, -1.448083275862069 ], [ 29.79560405063291, -1.448083275862069 ], [ 29.79560405063291, -1.447813793103448 ], [ 29.795334556962025, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19171, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.447813793103448 ], [ 29.79560405063291, -1.448083275862069 ], [ 29.795873544303799, -1.448083275862069 ], [ 29.795873544303799, -1.447813793103448 ], [ 29.79560405063291, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19172, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.447813793103448 ], [ 29.795873544303799, -1.448083275862069 ], [ 29.796143037974684, -1.448083275862069 ], [ 29.796143037974684, -1.447813793103448 ], [ 29.795873544303799, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19173, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.447813793103448 ], [ 29.796143037974684, -1.448083275862069 ], [ 29.796412531645569, -1.448083275862069 ], [ 29.796412531645569, -1.447813793103448 ], [ 29.796143037974684, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19174, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.447813793103448 ], [ 29.796412531645569, -1.448083275862069 ], [ 29.796682025316457, -1.448083275862069 ], [ 29.796682025316457, -1.447813793103448 ], [ 29.796412531645569, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19175, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.447813793103448 ], [ 29.796682025316457, -1.448083275862069 ], [ 29.796951518987342, -1.448083275862069 ], [ 29.796951518987342, -1.447813793103448 ], [ 29.796682025316457, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19176, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.447813793103448 ], [ 29.796951518987342, -1.448083275862069 ], [ 29.797221012658227, -1.448083275862069 ], [ 29.797221012658227, -1.447813793103448 ], [ 29.796951518987342, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19177, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.447813793103448 ], [ 29.797221012658227, -1.448083275862069 ], [ 29.797490506329115, -1.448083275862069 ], [ 29.797490506329115, -1.447813793103448 ], [ 29.797221012658227, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19178, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.447813793103448 ], [ 29.797490506329115, -1.448083275862069 ], [ 29.79776, -1.448083275862069 ], [ 29.79776, -1.447813793103448 ], [ 29.797490506329115, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19179, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.447813793103448 ], [ 29.79776, -1.448083275862069 ], [ 29.798029493670885, -1.448083275862069 ], [ 29.798029493670885, -1.447813793103448 ], [ 29.79776, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19180, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.447813793103448 ], [ 29.798029493670885, -1.448083275862069 ], [ 29.798298987341774, -1.448083275862069 ], [ 29.798298987341774, -1.447813793103448 ], [ 29.798029493670885, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19181, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.447813793103448 ], [ 29.798298987341774, -1.448083275862069 ], [ 29.798568481012659, -1.448083275862069 ], [ 29.798568481012659, -1.447813793103448 ], [ 29.798298987341774, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19182, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.447813793103448 ], [ 29.802071898734177, -1.448083275862069 ], [ 29.802341392405065, -1.448083275862069 ], [ 29.802341392405065, -1.447813793103448 ], [ 29.802071898734177, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19183, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.447813793103448 ], [ 29.802341392405065, -1.448083275862069 ], [ 29.80261088607595, -1.448083275862069 ], [ 29.80261088607595, -1.447813793103448 ], [ 29.802341392405065, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19184, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.447813793103448 ], [ 29.80261088607595, -1.448083275862069 ], [ 29.802880379746835, -1.448083275862069 ], [ 29.802880379746835, -1.447813793103448 ], [ 29.80261088607595, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19185, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.447813793103448 ], [ 29.802880379746835, -1.448083275862069 ], [ 29.803149873417723, -1.448083275862069 ], [ 29.803149873417723, -1.447813793103448 ], [ 29.802880379746835, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19186, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.447813793103448 ], [ 29.803149873417723, -1.448083275862069 ], [ 29.803419367088608, -1.448083275862069 ], [ 29.803419367088608, -1.447813793103448 ], [ 29.803149873417723, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19187, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.447813793103448 ], [ 29.803419367088608, -1.448083275862069 ], [ 29.803688860759493, -1.448083275862069 ], [ 29.803688860759493, -1.447813793103448 ], [ 29.803419367088608, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19188, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.447813793103448 ], [ 29.803688860759493, -1.448083275862069 ], [ 29.803958354430382, -1.448083275862069 ], [ 29.803958354430382, -1.447813793103448 ], [ 29.803688860759493, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19189, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.447813793103448 ], [ 29.803958354430382, -1.448083275862069 ], [ 29.804227848101267, -1.448083275862069 ], [ 29.804227848101267, -1.447813793103448 ], [ 29.803958354430382, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19190, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.447813793103448 ], [ 29.804227848101267, -1.448083275862069 ], [ 29.804497341772151, -1.448083275862069 ], [ 29.804497341772151, -1.447813793103448 ], [ 29.804227848101267, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19191, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.447813793103448 ], [ 29.806114303797468, -1.448083275862069 ], [ 29.806383797468357, -1.448083275862069 ], [ 29.806383797468357, -1.447813793103448 ], [ 29.806114303797468, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19192, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.447813793103448 ], [ 29.806383797468357, -1.448083275862069 ], [ 29.806653291139241, -1.448083275862069 ], [ 29.806653291139241, -1.447813793103448 ], [ 29.806383797468357, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19193, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.447813793103448 ], [ 29.806653291139241, -1.448083275862069 ], [ 29.806922784810126, -1.448083275862069 ], [ 29.806922784810126, -1.447813793103448 ], [ 29.806653291139241, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19194, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.447813793103448 ], [ 29.806922784810126, -1.448083275862069 ], [ 29.807192278481015, -1.448083275862069 ], [ 29.807192278481015, -1.447813793103448 ], [ 29.806922784810126, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19195, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.447544310344828 ], [ 29.769732658227849, -1.44835275862069 ], [ 29.770002151898733, -1.44835275862069 ], [ 29.770002151898733, -1.447544310344828 ], [ 29.769732658227849, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19196, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.447544310344828 ], [ 29.770541139240507, -1.44835275862069 ], [ 29.770810632911392, -1.44835275862069 ], [ 29.770810632911392, -1.447544310344828 ], [ 29.770541139240507, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19197, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.447544310344828 ], [ 29.771349620253165, -1.44835275862069 ], [ 29.77161911392405, -1.44835275862069 ], [ 29.77161911392405, -1.447544310344828 ], [ 29.771349620253165, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19198, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.447544310344828 ], [ 29.777547974683543, -1.448891724137931 ], [ 29.777817468354431, -1.448891724137931 ], [ 29.777817468354431, -1.447544310344828 ], [ 29.777547974683543, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19199, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.447813793103448 ], [ 29.755449493670884, -1.44835275862069 ], [ 29.755718987341773, -1.44835275862069 ], [ 29.755718987341773, -1.447813793103448 ], [ 29.755449493670884, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19200, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.447813793103448 ], [ 29.756796962025316, -1.44835275862069 ], [ 29.757066455696201, -1.44835275862069 ], [ 29.757066455696201, -1.447813793103448 ], [ 29.756796962025316, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19201, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.447813793103448 ], [ 29.757605443037974, -1.44835275862069 ], [ 29.757874936708859, -1.44835275862069 ], [ 29.757874936708859, -1.447813793103448 ], [ 29.757605443037974, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19202, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.447813793103448 ], [ 29.758144430379748, -1.44835275862069 ], [ 29.758413924050632, -1.44835275862069 ], [ 29.758413924050632, -1.447813793103448 ], [ 29.758144430379748, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19203, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.447813793103448 ], [ 29.758952911392406, -1.44835275862069 ], [ 29.759222405063291, -1.44835275862069 ], [ 29.759222405063291, -1.447813793103448 ], [ 29.758952911392406, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19204, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.447813793103448 ], [ 29.759761392405064, -1.44835275862069 ], [ 29.760030886075949, -1.44835275862069 ], [ 29.760030886075949, -1.447813793103448 ], [ 29.759761392405064, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19205, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.447813793103448 ], [ 29.768385189873417, -1.448891724137931 ], [ 29.768654683544302, -1.448891724137931 ], [ 29.768654683544302, -1.447813793103448 ], [ 29.768385189873417, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19206, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.447813793103448 ], [ 29.768654683544302, -1.448891724137931 ], [ 29.76892417721519, -1.448891724137931 ], [ 29.76892417721519, -1.447813793103448 ], [ 29.768654683544302, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19207, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.447813793103448 ], [ 29.769193670886075, -1.44862224137931 ], [ 29.76946316455696, -1.44862224137931 ], [ 29.76946316455696, -1.447813793103448 ], [ 29.769193670886075, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19208, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.447813793103448 ], [ 29.76946316455696, -1.44862224137931 ], [ 29.769732658227849, -1.44862224137931 ], [ 29.769732658227849, -1.447813793103448 ], [ 29.76946316455696, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19209, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.447813793103448 ], [ 29.770002151898733, -1.44835275862069 ], [ 29.770271645569618, -1.44835275862069 ], [ 29.770271645569618, -1.447813793103448 ], [ 29.770002151898733, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19210, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.447813793103448 ], [ 29.770810632911392, -1.44835275862069 ], [ 29.771080126582277, -1.44835275862069 ], [ 29.771080126582277, -1.447813793103448 ], [ 29.770810632911392, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19211, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.447813793103448 ], [ 29.77161911392405, -1.44835275862069 ], [ 29.771888607594935, -1.44835275862069 ], [ 29.771888607594935, -1.447813793103448 ], [ 29.77161911392405, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19212, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.447813793103448 ], [ 29.772427594936708, -1.44835275862069 ], [ 29.772697088607593, -1.44835275862069 ], [ 29.772697088607593, -1.447813793103448 ], [ 29.772427594936708, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19213, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.447813793103448 ], [ 29.77431405063291, -1.44835275862069 ], [ 29.774853037974683, -1.44835275862069 ], [ 29.774853037974683, -1.447813793103448 ], [ 29.77431405063291, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19214, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.447813793103448 ], [ 29.775392025316457, -1.44835275862069 ], [ 29.775661518987341, -1.44835275862069 ], [ 29.775661518987341, -1.447813793103448 ], [ 29.775392025316457, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19215, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.447813793103448 ], [ 29.776200506329115, -1.44835275862069 ], [ 29.77647, -1.44835275862069 ], [ 29.77647, -1.447813793103448 ], [ 29.776200506329115, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19216, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.447813793103448 ], [ 29.777008987341773, -1.44835275862069 ], [ 29.777278481012658, -1.44835275862069 ], [ 29.777278481012658, -1.447813793103448 ], [ 29.777008987341773, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19217, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.447813793103448 ], [ 29.804497341772151, -1.448083275862069 ], [ 29.80476683544304, -1.448083275862069 ], [ 29.80476683544304, -1.450239137931035 ], [ 29.805036329113925, -1.450239137931035 ], [ 29.805036329113925, -1.449430689655173 ], [ 29.80530582278481, -1.449430689655173 ], [ 29.80530582278481, -1.449161206896552 ], [ 29.805036329113925, -1.449161206896552 ], [ 29.805036329113925, -1.447813793103448 ], [ 29.804497341772151, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19218, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.447813793103448 ], [ 29.805036329113925, -1.44862224137931 ], [ 29.80530582278481, -1.44862224137931 ], [ 29.80530582278481, -1.447813793103448 ], [ 29.805036329113925, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19219, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.447813793103448 ], [ 29.805575316455698, -1.44862224137931 ], [ 29.805844810126583, -1.44862224137931 ], [ 29.805844810126583, -1.447813793103448 ], [ 29.805575316455698, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19220, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.447813793103448 ], [ 29.805844810126583, -1.44862224137931 ], [ 29.806114303797468, -1.44862224137931 ], [ 29.806114303797468, -1.447813793103448 ], [ 29.805844810126583, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19221, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749520632911391, -1.448083275862069 ], [ 29.749520632911391, -1.44862224137931 ], [ 29.749790126582276, -1.44862224137931 ], [ 29.749790126582276, -1.448083275862069 ], [ 29.749520632911391, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19222, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.448083275862069 ], [ 29.749790126582276, -1.44835275862069 ], [ 29.750059620253165, -1.44835275862069 ], [ 29.750059620253165, -1.448083275862069 ], [ 29.749790126582276, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19223, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.448083275862069 ], [ 29.750059620253165, -1.44835275862069 ], [ 29.75032911392405, -1.44835275862069 ], [ 29.75032911392405, -1.448083275862069 ], [ 29.750059620253165, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19224, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.448083275862069 ], [ 29.75032911392405, -1.44835275862069 ], [ 29.750598607594934, -1.44835275862069 ], [ 29.750598607594934, -1.448083275862069 ], [ 29.75032911392405, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19225, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.448083275862069 ], [ 29.750598607594934, -1.44835275862069 ], [ 29.750868101265823, -1.44835275862069 ], [ 29.750868101265823, -1.448083275862069 ], [ 29.750598607594934, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19226, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.448083275862069 ], [ 29.750868101265823, -1.44835275862069 ], [ 29.751137594936708, -1.44835275862069 ], [ 29.751137594936708, -1.448083275862069 ], [ 29.750868101265823, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19227, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.448083275862069 ], [ 29.751137594936708, -1.44835275862069 ], [ 29.751407088607593, -1.44835275862069 ], [ 29.751407088607593, -1.448083275862069 ], [ 29.751137594936708, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19228, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.448083275862069 ], [ 29.751407088607593, -1.44835275862069 ], [ 29.751676582278481, -1.44835275862069 ], [ 29.751676582278481, -1.448083275862069 ], [ 29.751407088607593, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19229, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.448083275862069 ], [ 29.751676582278481, -1.44835275862069 ], [ 29.751946075949366, -1.44835275862069 ], [ 29.751946075949366, -1.448083275862069 ], [ 29.751676582278481, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19230, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.448083275862069 ], [ 29.751946075949366, -1.44835275862069 ], [ 29.752215569620251, -1.44835275862069 ], [ 29.752215569620251, -1.448083275862069 ], [ 29.751946075949366, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19231, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.448083275862069 ], [ 29.752215569620251, -1.44835275862069 ], [ 29.75248506329114, -1.44835275862069 ], [ 29.75248506329114, -1.448083275862069 ], [ 29.752215569620251, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19232, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.448083275862069 ], [ 29.75248506329114, -1.44835275862069 ], [ 29.752754556962024, -1.44835275862069 ], [ 29.752754556962024, -1.448083275862069 ], [ 29.75248506329114, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19233, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.448083275862069 ], [ 29.752754556962024, -1.44835275862069 ], [ 29.753024050632909, -1.44835275862069 ], [ 29.753024050632909, -1.448083275862069 ], [ 29.752754556962024, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19234, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.448083275862069 ], [ 29.753024050632909, -1.44835275862069 ], [ 29.753293544303798, -1.44835275862069 ], [ 29.753293544303798, -1.448083275862069 ], [ 29.753024050632909, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19235, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.448083275862069 ], [ 29.753293544303798, -1.44835275862069 ], [ 29.753563037974683, -1.44835275862069 ], [ 29.753563037974683, -1.448083275862069 ], [ 29.753293544303798, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19236, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.448083275862069 ], [ 29.753563037974683, -1.44862224137931 ], [ 29.753832531645568, -1.44862224137931 ], [ 29.753832531645568, -1.448083275862069 ], [ 29.753563037974683, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19237, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.448083275862069 ], [ 29.753832531645568, -1.44835275862069 ], [ 29.754102025316456, -1.44835275862069 ], [ 29.754102025316456, -1.448083275862069 ], [ 29.753832531645568, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19238, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.448083275862069 ], [ 29.754102025316456, -1.44835275862069 ], [ 29.754371518987341, -1.44835275862069 ], [ 29.754371518987341, -1.448083275862069 ], [ 29.754102025316456, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19239, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.448083275862069 ], [ 29.754371518987341, -1.44862224137931 ], [ 29.754641012658226, -1.44862224137931 ], [ 29.754641012658226, -1.448083275862069 ], [ 29.754371518987341, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19240, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.448083275862069 ], [ 29.754641012658226, -1.44835275862069 ], [ 29.754910506329114, -1.44835275862069 ], [ 29.754910506329114, -1.448083275862069 ], [ 29.754641012658226, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19241, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.448083275862069 ], [ 29.754910506329114, -1.44835275862069 ], [ 29.75518, -1.44835275862069 ], [ 29.75518, -1.448083275862069 ], [ 29.754910506329114, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19242, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.448083275862069 ], [ 29.75518, -1.44862224137931 ], [ 29.755449493670884, -1.44862224137931 ], [ 29.755449493670884, -1.448083275862069 ], [ 29.75518, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19243, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.448083275862069 ], [ 29.755718987341773, -1.44835275862069 ], [ 29.755988481012658, -1.44835275862069 ], [ 29.755988481012658, -1.448083275862069 ], [ 29.755718987341773, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19244, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.448083275862069 ], [ 29.759491898734176, -1.44862224137931 ], [ 29.759761392405064, -1.44862224137931 ], [ 29.759761392405064, -1.448083275862069 ], [ 29.759491898734176, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19245, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.448083275862069 ], [ 29.760300379746834, -1.44835275862069 ], [ 29.760569873417722, -1.44835275862069 ], [ 29.760569873417722, -1.448083275862069 ], [ 29.760300379746834, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19246, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.448083275862069 ], [ 29.760569873417722, -1.44835275862069 ], [ 29.760839367088607, -1.44835275862069 ], [ 29.760839367088607, -1.448083275862069 ], [ 29.760569873417722, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19247, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.448083275862069 ], [ 29.760839367088607, -1.44862224137931 ], [ 29.761108860759492, -1.44862224137931 ], [ 29.761108860759492, -1.448083275862069 ], [ 29.760839367088607, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19248, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.448083275862069 ], [ 29.761108860759492, -1.44835275862069 ], [ 29.761378354430381, -1.44835275862069 ], [ 29.761378354430381, -1.448083275862069 ], [ 29.761108860759492, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19249, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.448083275862069 ], [ 29.761378354430381, -1.44835275862069 ], [ 29.761647848101266, -1.44835275862069 ], [ 29.761647848101266, -1.448083275862069 ], [ 29.761378354430381, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19250, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.448083275862069 ], [ 29.761647848101266, -1.44835275862069 ], [ 29.761917341772151, -1.44835275862069 ], [ 29.761917341772151, -1.448083275862069 ], [ 29.761647848101266, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19251, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.448083275862069 ], [ 29.761917341772151, -1.44835275862069 ], [ 29.762186835443039, -1.44835275862069 ], [ 29.762186835443039, -1.448083275862069 ], [ 29.761917341772151, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19252, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.448083275862069 ], [ 29.762186835443039, -1.44835275862069 ], [ 29.762456329113924, -1.44835275862069 ], [ 29.762456329113924, -1.448083275862069 ], [ 29.762186835443039, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19253, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.448083275862069 ], [ 29.762456329113924, -1.44835275862069 ], [ 29.762725822784809, -1.44835275862069 ], [ 29.762725822784809, -1.448083275862069 ], [ 29.762456329113924, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19254, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.448083275862069 ], [ 29.762725822784809, -1.44835275862069 ], [ 29.762995316455697, -1.44835275862069 ], [ 29.762995316455697, -1.448083275862069 ], [ 29.762725822784809, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19255, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.448083275862069 ], [ 29.762995316455697, -1.44835275862069 ], [ 29.763264810126582, -1.44835275862069 ], [ 29.763264810126582, -1.448083275862069 ], [ 29.762995316455697, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19256, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.448083275862069 ], [ 29.763264810126582, -1.44835275862069 ], [ 29.763534303797467, -1.44835275862069 ], [ 29.763534303797467, -1.448083275862069 ], [ 29.763264810126582, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19257, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.448083275862069 ], [ 29.763534303797467, -1.44835275862069 ], [ 29.76407329113924, -1.44835275862069 ], [ 29.76407329113924, -1.448083275862069 ], [ 29.763534303797467, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19258, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.448083275862069 ], [ 29.76407329113924, -1.44835275862069 ], [ 29.764342784810125, -1.44835275862069 ], [ 29.764342784810125, -1.448083275862069 ], [ 29.76407329113924, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19259, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.448083275862069 ], [ 29.764342784810125, -1.44835275862069 ], [ 29.764612278481014, -1.44835275862069 ], [ 29.764612278481014, -1.448083275862069 ], [ 29.764342784810125, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19260, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.448083275862069 ], [ 29.764612278481014, -1.44835275862069 ], [ 29.764881772151899, -1.44835275862069 ], [ 29.764881772151899, -1.448083275862069 ], [ 29.764612278481014, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19261, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.448083275862069 ], [ 29.764881772151899, -1.44835275862069 ], [ 29.765151265822784, -1.44835275862069 ], [ 29.765151265822784, -1.448083275862069 ], [ 29.764881772151899, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19262, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.448083275862069 ], [ 29.765151265822784, -1.44835275862069 ], [ 29.765420759493672, -1.44835275862069 ], [ 29.765420759493672, -1.448083275862069 ], [ 29.765151265822784, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19263, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.448083275862069 ], [ 29.765420759493672, -1.44835275862069 ], [ 29.765959746835442, -1.44835275862069 ], [ 29.765959746835442, -1.448083275862069 ], [ 29.765420759493672, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19264, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.448083275862069 ], [ 29.767576708860759, -1.449430689655173 ], [ 29.767846202531643, -1.449430689655173 ], [ 29.767846202531643, -1.448083275862069 ], [ 29.767576708860759, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19265, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.448083275862069 ], [ 29.767846202531643, -1.449161206896552 ], [ 29.768115696202532, -1.449161206896552 ], [ 29.768115696202532, -1.448083275862069 ], [ 29.767846202531643, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19266, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.448083275862069 ], [ 29.768115696202532, -1.449161206896552 ], [ 29.768385189873417, -1.449161206896552 ], [ 29.768385189873417, -1.448083275862069 ], [ 29.768115696202532, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19267, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.448083275862069 ], [ 29.76892417721519, -1.448891724137931 ], [ 29.769193670886075, -1.448891724137931 ], [ 29.769193670886075, -1.448083275862069 ], [ 29.76892417721519, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19268, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.448083275862069 ], [ 29.770271645569618, -1.44862224137931 ], [ 29.770541139240507, -1.44862224137931 ], [ 29.770541139240507, -1.448083275862069 ], [ 29.770271645569618, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19269, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.448083275862069 ], [ 29.771080126582277, -1.44835275862069 ], [ 29.771349620253165, -1.44835275862069 ], [ 29.771349620253165, -1.448083275862069 ], [ 29.771080126582277, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19270, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.448083275862069 ], [ 29.771888607594935, -1.44835275862069 ], [ 29.772158101265823, -1.44835275862069 ], [ 29.772158101265823, -1.448083275862069 ], [ 29.771888607594935, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19271, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.448083275862069 ], [ 29.772158101265823, -1.44835275862069 ], [ 29.772427594936708, -1.44835275862069 ], [ 29.772427594936708, -1.448083275862069 ], [ 29.772158101265823, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19272, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.448083275862069 ], [ 29.772697088607593, -1.44835275862069 ], [ 29.772966582278482, -1.44835275862069 ], [ 29.772966582278482, -1.448083275862069 ], [ 29.772697088607593, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19273, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.448083275862069 ], [ 29.772966582278482, -1.44835275862069 ], [ 29.773236075949367, -1.44835275862069 ], [ 29.773236075949367, -1.448083275862069 ], [ 29.772966582278482, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19274, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.448083275862069 ], [ 29.773236075949367, -1.44862224137931 ], [ 29.773505569620252, -1.44862224137931 ], [ 29.773505569620252, -1.448083275862069 ], [ 29.773236075949367, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19275, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.448083275862069 ], [ 29.773505569620252, -1.44835275862069 ], [ 29.77377506329114, -1.44835275862069 ], [ 29.77377506329114, -1.448083275862069 ], [ 29.773505569620252, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19276, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.448083275862069 ], [ 29.77377506329114, -1.44835275862069 ], [ 29.774044556962025, -1.44835275862069 ], [ 29.774044556962025, -1.448083275862069 ], [ 29.77377506329114, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19277, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.448083275862069 ], [ 29.774044556962025, -1.44862224137931 ], [ 29.77431405063291, -1.44862224137931 ], [ 29.77431405063291, -1.448083275862069 ], [ 29.774044556962025, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19278, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.448083275862069 ], [ 29.774853037974683, -1.44835275862069 ], [ 29.775122531645568, -1.44835275862069 ], [ 29.775122531645568, -1.448083275862069 ], [ 29.774853037974683, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19279, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.448083275862069 ], [ 29.775122531645568, -1.44862224137931 ], [ 29.775392025316457, -1.44862224137931 ], [ 29.775392025316457, -1.448083275862069 ], [ 29.775122531645568, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19280, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.448083275862069 ], [ 29.775661518987341, -1.44862224137931 ], [ 29.775931012658226, -1.44862224137931 ], [ 29.775931012658226, -1.448083275862069 ], [ 29.775661518987341, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19281, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.448083275862069 ], [ 29.775931012658226, -1.44862224137931 ], [ 29.776200506329115, -1.44862224137931 ], [ 29.776200506329115, -1.448083275862069 ], [ 29.775931012658226, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19282, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.448083275862069 ], [ 29.77647, -1.44862224137931 ], [ 29.776739493670885, -1.44862224137931 ], [ 29.776739493670885, -1.448083275862069 ], [ 29.77647, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19283, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.448083275862069 ], [ 29.776739493670885, -1.448891724137931 ], [ 29.777008987341773, -1.448891724137931 ], [ 29.777008987341773, -1.448083275862069 ], [ 29.776739493670885, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19284, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.448083275862069 ], [ 29.777278481012658, -1.44862224137931 ], [ 29.777547974683543, -1.44862224137931 ], [ 29.777547974683543, -1.448083275862069 ], [ 29.777278481012658, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19285, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.448083275862069 ], [ 29.794256582278482, -1.44835275862069 ], [ 29.794526075949367, -1.44835275862069 ], [ 29.794526075949367, -1.448083275862069 ], [ 29.794256582278482, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19286, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.448083275862069 ], [ 29.794526075949367, -1.44835275862069 ], [ 29.794795569620252, -1.44835275862069 ], [ 29.794795569620252, -1.448083275862069 ], [ 29.794526075949367, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19287, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.448083275862069 ], [ 29.794795569620252, -1.44835275862069 ], [ 29.79506506329114, -1.44835275862069 ], [ 29.79506506329114, -1.448083275862069 ], [ 29.794795569620252, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19288, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.448083275862069 ], [ 29.79506506329114, -1.44835275862069 ], [ 29.795334556962025, -1.44835275862069 ], [ 29.795334556962025, -1.448083275862069 ], [ 29.79506506329114, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19289, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.448083275862069 ], [ 29.795334556962025, -1.44835275862069 ], [ 29.79560405063291, -1.44835275862069 ], [ 29.79560405063291, -1.448083275862069 ], [ 29.795334556962025, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19290, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.448083275862069 ], [ 29.79560405063291, -1.44835275862069 ], [ 29.795873544303799, -1.44835275862069 ], [ 29.795873544303799, -1.448083275862069 ], [ 29.79560405063291, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19291, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.448083275862069 ], [ 29.795873544303799, -1.44835275862069 ], [ 29.796143037974684, -1.44835275862069 ], [ 29.796143037974684, -1.448083275862069 ], [ 29.795873544303799, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19292, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.448083275862069 ], [ 29.796143037974684, -1.44835275862069 ], [ 29.796412531645569, -1.44835275862069 ], [ 29.796412531645569, -1.448083275862069 ], [ 29.796143037974684, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19293, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.448083275862069 ], [ 29.796412531645569, -1.44835275862069 ], [ 29.796682025316457, -1.44835275862069 ], [ 29.796682025316457, -1.448083275862069 ], [ 29.796412531645569, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19294, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.448083275862069 ], [ 29.796682025316457, -1.44835275862069 ], [ 29.796951518987342, -1.44835275862069 ], [ 29.796951518987342, -1.448083275862069 ], [ 29.796682025316457, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19295, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.448083275862069 ], [ 29.796951518987342, -1.44835275862069 ], [ 29.797221012658227, -1.44835275862069 ], [ 29.797221012658227, -1.448083275862069 ], [ 29.796951518987342, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19296, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.448083275862069 ], [ 29.797221012658227, -1.44835275862069 ], [ 29.797490506329115, -1.44835275862069 ], [ 29.797490506329115, -1.448083275862069 ], [ 29.797221012658227, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19297, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.448083275862069 ], [ 29.797490506329115, -1.44835275862069 ], [ 29.79776, -1.44835275862069 ], [ 29.79776, -1.448083275862069 ], [ 29.797490506329115, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19298, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.448083275862069 ], [ 29.79776, -1.44835275862069 ], [ 29.798029493670885, -1.44835275862069 ], [ 29.798029493670885, -1.448083275862069 ], [ 29.79776, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19299, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.448083275862069 ], [ 29.798029493670885, -1.44835275862069 ], [ 29.798298987341774, -1.44835275862069 ], [ 29.798298987341774, -1.448083275862069 ], [ 29.798029493670885, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19300, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.448083275862069 ], [ 29.798298987341774, -1.44835275862069 ], [ 29.798568481012659, -1.44835275862069 ], [ 29.798568481012659, -1.448083275862069 ], [ 29.798298987341774, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19301, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.448083275862069 ], [ 29.798568481012659, -1.44835275862069 ], [ 29.798837974683543, -1.44835275862069 ], [ 29.798837974683543, -1.448083275862069 ], [ 29.798568481012659, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19302, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.448083275862069 ], [ 29.802071898734177, -1.44835275862069 ], [ 29.802341392405065, -1.44835275862069 ], [ 29.802341392405065, -1.448083275862069 ], [ 29.802071898734177, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19303, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.448083275862069 ], [ 29.802341392405065, -1.44835275862069 ], [ 29.80261088607595, -1.44835275862069 ], [ 29.80261088607595, -1.448083275862069 ], [ 29.802341392405065, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19304, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.448083275862069 ], [ 29.80261088607595, -1.44835275862069 ], [ 29.802880379746835, -1.44835275862069 ], [ 29.802880379746835, -1.448083275862069 ], [ 29.80261088607595, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19305, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.448083275862069 ], [ 29.802880379746835, -1.44835275862069 ], [ 29.803149873417723, -1.44835275862069 ], [ 29.803149873417723, -1.448083275862069 ], [ 29.802880379746835, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19306, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.448083275862069 ], [ 29.803149873417723, -1.44835275862069 ], [ 29.803419367088608, -1.44835275862069 ], [ 29.803419367088608, -1.448083275862069 ], [ 29.803149873417723, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19307, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.448083275862069 ], [ 29.803419367088608, -1.44835275862069 ], [ 29.803688860759493, -1.44835275862069 ], [ 29.803688860759493, -1.448083275862069 ], [ 29.803419367088608, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19308, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.448083275862069 ], [ 29.803688860759493, -1.44835275862069 ], [ 29.803958354430382, -1.44835275862069 ], [ 29.803958354430382, -1.448083275862069 ], [ 29.803688860759493, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19309, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.448083275862069 ], [ 29.803958354430382, -1.44835275862069 ], [ 29.804227848101267, -1.44835275862069 ], [ 29.804227848101267, -1.448083275862069 ], [ 29.803958354430382, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19310, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.448083275862069 ], [ 29.804227848101267, -1.44835275862069 ], [ 29.804497341772151, -1.44835275862069 ], [ 29.804497341772151, -1.448083275862069 ], [ 29.804227848101267, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19311, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.448083275862069 ], [ 29.804497341772151, -1.44835275862069 ], [ 29.80476683544304, -1.44835275862069 ], [ 29.80476683544304, -1.448083275862069 ], [ 29.804497341772151, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19312, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.448083275862069 ], [ 29.80530582278481, -1.44835275862069 ], [ 29.805575316455698, -1.44835275862069 ], [ 29.805575316455698, -1.448083275862069 ], [ 29.80530582278481, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19313, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.448083275862069 ], [ 29.806114303797468, -1.44835275862069 ], [ 29.806383797468357, -1.44835275862069 ], [ 29.806383797468357, -1.448083275862069 ], [ 29.806114303797468, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19314, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.448083275862069 ], [ 29.806383797468357, -1.44835275862069 ], [ 29.806653291139241, -1.44835275862069 ], [ 29.806653291139241, -1.448083275862069 ], [ 29.806383797468357, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19315, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.448083275862069 ], [ 29.806653291139241, -1.44835275862069 ], [ 29.806922784810126, -1.44835275862069 ], [ 29.806922784810126, -1.448083275862069 ], [ 29.806653291139241, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19316, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.44835275862069 ], [ 29.749790126582276, -1.44862224137931 ], [ 29.750059620253165, -1.44862224137931 ], [ 29.750059620253165, -1.44835275862069 ], [ 29.749790126582276, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19317, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.44835275862069 ], [ 29.750059620253165, -1.44862224137931 ], [ 29.75032911392405, -1.44862224137931 ], [ 29.75032911392405, -1.44835275862069 ], [ 29.750059620253165, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19318, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.44835275862069 ], [ 29.75032911392405, -1.44862224137931 ], [ 29.750598607594934, -1.44862224137931 ], [ 29.750598607594934, -1.44835275862069 ], [ 29.75032911392405, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19319, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.44835275862069 ], [ 29.750598607594934, -1.44862224137931 ], [ 29.750868101265823, -1.44862224137931 ], [ 29.750868101265823, -1.44835275862069 ], [ 29.750598607594934, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19320, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.44835275862069 ], [ 29.750868101265823, -1.44862224137931 ], [ 29.751137594936708, -1.44862224137931 ], [ 29.751137594936708, -1.44835275862069 ], [ 29.750868101265823, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19321, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.44835275862069 ], [ 29.751137594936708, -1.44862224137931 ], [ 29.751407088607593, -1.44862224137931 ], [ 29.751407088607593, -1.44835275862069 ], [ 29.751137594936708, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19322, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.44835275862069 ], [ 29.751407088607593, -1.44862224137931 ], [ 29.751676582278481, -1.44862224137931 ], [ 29.751676582278481, -1.44835275862069 ], [ 29.751407088607593, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19323, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.44835275862069 ], [ 29.751676582278481, -1.44862224137931 ], [ 29.751946075949366, -1.44862224137931 ], [ 29.751946075949366, -1.44835275862069 ], [ 29.751676582278481, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19324, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.44835275862069 ], [ 29.751946075949366, -1.44862224137931 ], [ 29.752215569620251, -1.44862224137931 ], [ 29.752215569620251, -1.44835275862069 ], [ 29.751946075949366, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19325, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.44835275862069 ], [ 29.752215569620251, -1.44862224137931 ], [ 29.75248506329114, -1.44862224137931 ], [ 29.75248506329114, -1.44835275862069 ], [ 29.752215569620251, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19326, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.44835275862069 ], [ 29.75248506329114, -1.448891724137931 ], [ 29.752754556962024, -1.448891724137931 ], [ 29.752754556962024, -1.44835275862069 ], [ 29.75248506329114, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19327, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.44835275862069 ], [ 29.752754556962024, -1.44862224137931 ], [ 29.753024050632909, -1.44862224137931 ], [ 29.753024050632909, -1.44835275862069 ], [ 29.752754556962024, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19328, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.44835275862069 ], [ 29.753024050632909, -1.44862224137931 ], [ 29.753293544303798, -1.44862224137931 ], [ 29.753293544303798, -1.44835275862069 ], [ 29.753024050632909, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19329, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.44835275862069 ], [ 29.753293544303798, -1.448891724137931 ], [ 29.753563037974683, -1.448891724137931 ], [ 29.753563037974683, -1.44835275862069 ], [ 29.753293544303798, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19330, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.44835275862069 ], [ 29.753832531645568, -1.44862224137931 ], [ 29.754102025316456, -1.44862224137931 ], [ 29.754102025316456, -1.44835275862069 ], [ 29.753832531645568, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19331, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.44835275862069 ], [ 29.754102025316456, -1.448891724137931 ], [ 29.754371518987341, -1.448891724137931 ], [ 29.754371518987341, -1.44835275862069 ], [ 29.754102025316456, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19332, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.44835275862069 ], [ 29.754641012658226, -1.448891724137931 ], [ 29.754910506329114, -1.448891724137931 ], [ 29.754910506329114, -1.44835275862069 ], [ 29.754641012658226, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19333, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.44835275862069 ], [ 29.755449493670884, -1.448891724137931 ], [ 29.755718987341773, -1.448891724137931 ], [ 29.755718987341773, -1.44835275862069 ], [ 29.755449493670884, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19334, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.44835275862069 ], [ 29.761647848101266, -1.44862224137931 ], [ 29.761917341772151, -1.44862224137931 ], [ 29.761917341772151, -1.44835275862069 ], [ 29.761647848101266, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19335, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.44835275862069 ], [ 29.761917341772151, -1.44862224137931 ], [ 29.762186835443039, -1.44862224137931 ], [ 29.762186835443039, -1.44835275862069 ], [ 29.761917341772151, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19336, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.44835275862069 ], [ 29.762186835443039, -1.44862224137931 ], [ 29.762456329113924, -1.44862224137931 ], [ 29.762456329113924, -1.44835275862069 ], [ 29.762186835443039, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19337, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.44835275862069 ], [ 29.762456329113924, -1.44862224137931 ], [ 29.762725822784809, -1.44862224137931 ], [ 29.762725822784809, -1.44835275862069 ], [ 29.762456329113924, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19338, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.44835275862069 ], [ 29.762725822784809, -1.44862224137931 ], [ 29.762995316455697, -1.44862224137931 ], [ 29.762995316455697, -1.44835275862069 ], [ 29.762725822784809, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19339, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.44835275862069 ], [ 29.762995316455697, -1.44862224137931 ], [ 29.763264810126582, -1.44862224137931 ], [ 29.763264810126582, -1.44835275862069 ], [ 29.762995316455697, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19340, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.44835275862069 ], [ 29.763264810126582, -1.44862224137931 ], [ 29.763534303797467, -1.44862224137931 ], [ 29.763534303797467, -1.44835275862069 ], [ 29.763264810126582, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19341, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.44835275862069 ], [ 29.763534303797467, -1.44862224137931 ], [ 29.763803797468356, -1.44862224137931 ], [ 29.763803797468356, -1.44835275862069 ], [ 29.763534303797467, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19342, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.44835275862069 ], [ 29.763803797468356, -1.44862224137931 ], [ 29.76407329113924, -1.44862224137931 ], [ 29.76407329113924, -1.44835275862069 ], [ 29.763803797468356, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19343, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.44835275862069 ], [ 29.76407329113924, -1.44862224137931 ], [ 29.764342784810125, -1.44862224137931 ], [ 29.764342784810125, -1.44835275862069 ], [ 29.76407329113924, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19344, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.44835275862069 ], [ 29.764342784810125, -1.44862224137931 ], [ 29.764612278481014, -1.44862224137931 ], [ 29.764612278481014, -1.44835275862069 ], [ 29.764342784810125, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19345, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.44835275862069 ], [ 29.764612278481014, -1.44862224137931 ], [ 29.764881772151899, -1.44862224137931 ], [ 29.764881772151899, -1.44835275862069 ], [ 29.764612278481014, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19346, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.44835275862069 ], [ 29.764881772151899, -1.44862224137931 ], [ 29.765420759493672, -1.44862224137931 ], [ 29.765420759493672, -1.44835275862069 ], [ 29.764881772151899, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19347, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.44835275862069 ], [ 29.769732658227849, -1.44862224137931 ], [ 29.770002151898733, -1.44862224137931 ], [ 29.770002151898733, -1.44835275862069 ], [ 29.769732658227849, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19348, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.44835275862069 ], [ 29.770002151898733, -1.44862224137931 ], [ 29.770271645569618, -1.44862224137931 ], [ 29.770271645569618, -1.44835275862069 ], [ 29.770002151898733, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19349, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.44835275862069 ], [ 29.770541139240507, -1.44862224137931 ], [ 29.770810632911392, -1.44862224137931 ], [ 29.770810632911392, -1.44835275862069 ], [ 29.770541139240507, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19350, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.44835275862069 ], [ 29.770810632911392, -1.44862224137931 ], [ 29.771080126582277, -1.44862224137931 ], [ 29.771080126582277, -1.44835275862069 ], [ 29.770810632911392, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19351, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.44835275862069 ], [ 29.771080126582277, -1.44862224137931 ], [ 29.771349620253165, -1.44862224137931 ], [ 29.771349620253165, -1.44835275862069 ], [ 29.771080126582277, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19352, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.44835275862069 ], [ 29.771349620253165, -1.448891724137931 ], [ 29.77161911392405, -1.448891724137931 ], [ 29.77161911392405, -1.44835275862069 ], [ 29.771349620253165, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19353, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.44835275862069 ], [ 29.77161911392405, -1.44862224137931 ], [ 29.771888607594935, -1.44862224137931 ], [ 29.771888607594935, -1.44835275862069 ], [ 29.77161911392405, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19354, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.44835275862069 ], [ 29.771888607594935, -1.44862224137931 ], [ 29.772158101265823, -1.44862224137931 ], [ 29.772158101265823, -1.44835275862069 ], [ 29.771888607594935, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19355, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.44835275862069 ], [ 29.772158101265823, -1.448891724137931 ], [ 29.772427594936708, -1.448891724137931 ], [ 29.772427594936708, -1.44835275862069 ], [ 29.772158101265823, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19356, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.44835275862069 ], [ 29.772427594936708, -1.44862224137931 ], [ 29.772697088607593, -1.44862224137931 ], [ 29.772697088607593, -1.44835275862069 ], [ 29.772427594936708, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19357, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.44835275862069 ], [ 29.772697088607593, -1.44862224137931 ], [ 29.772966582278482, -1.44862224137931 ], [ 29.772966582278482, -1.44835275862069 ], [ 29.772697088607593, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19358, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.44835275862069 ], [ 29.772966582278482, -1.448891724137931 ], [ 29.773236075949367, -1.448891724137931 ], [ 29.773236075949367, -1.44835275862069 ], [ 29.772966582278482, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19359, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.44835275862069 ], [ 29.773505569620252, -1.44862224137931 ], [ 29.77377506329114, -1.44862224137931 ], [ 29.77377506329114, -1.44835275862069 ], [ 29.773505569620252, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19360, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.44835275862069 ], [ 29.77377506329114, -1.448891724137931 ], [ 29.774044556962025, -1.448891724137931 ], [ 29.774044556962025, -1.44835275862069 ], [ 29.77377506329114, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19361, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.44835275862069 ], [ 29.77431405063291, -1.448891724137931 ], [ 29.774853037974683, -1.448891724137931 ], [ 29.774853037974683, -1.44835275862069 ], [ 29.77431405063291, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19362, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.44835275862069 ], [ 29.774853037974683, -1.449161206896552 ], [ 29.775122531645568, -1.449161206896552 ], [ 29.775122531645568, -1.44835275862069 ], [ 29.774853037974683, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19363, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.44835275862069 ], [ 29.775392025316457, -1.448891724137931 ], [ 29.775661518987341, -1.448891724137931 ], [ 29.775661518987341, -1.44835275862069 ], [ 29.775392025316457, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19364, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.44835275862069 ], [ 29.776200506329115, -1.449161206896552 ], [ 29.77647, -1.449161206896552 ], [ 29.77647, -1.44835275862069 ], [ 29.776200506329115, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19365, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.44835275862069 ], [ 29.777008987341773, -1.448891724137931 ], [ 29.777278481012658, -1.448891724137931 ], [ 29.777278481012658, -1.44835275862069 ], [ 29.777008987341773, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19366, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.44835275862069 ], [ 29.794256582278482, -1.44862224137931 ], [ 29.794526075949367, -1.44862224137931 ], [ 29.794526075949367, -1.44835275862069 ], [ 29.794256582278482, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19367, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.44835275862069 ], [ 29.794526075949367, -1.44862224137931 ], [ 29.794795569620252, -1.44862224137931 ], [ 29.794795569620252, -1.44835275862069 ], [ 29.794526075949367, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19368, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.44835275862069 ], [ 29.794795569620252, -1.44862224137931 ], [ 29.79506506329114, -1.44862224137931 ], [ 29.79506506329114, -1.44835275862069 ], [ 29.794795569620252, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19369, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.44835275862069 ], [ 29.79506506329114, -1.44862224137931 ], [ 29.795334556962025, -1.44862224137931 ], [ 29.795334556962025, -1.44835275862069 ], [ 29.79506506329114, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19370, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.44835275862069 ], [ 29.795334556962025, -1.44862224137931 ], [ 29.79560405063291, -1.44862224137931 ], [ 29.79560405063291, -1.44835275862069 ], [ 29.795334556962025, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19371, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.44835275862069 ], [ 29.79560405063291, -1.44862224137931 ], [ 29.795873544303799, -1.44862224137931 ], [ 29.795873544303799, -1.44835275862069 ], [ 29.79560405063291, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19372, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.44835275862069 ], [ 29.795873544303799, -1.44862224137931 ], [ 29.796143037974684, -1.44862224137931 ], [ 29.796143037974684, -1.44835275862069 ], [ 29.795873544303799, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19373, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.44835275862069 ], [ 29.796143037974684, -1.44862224137931 ], [ 29.796412531645569, -1.44862224137931 ], [ 29.796412531645569, -1.44835275862069 ], [ 29.796143037974684, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19374, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.44835275862069 ], [ 29.796412531645569, -1.44862224137931 ], [ 29.796682025316457, -1.44862224137931 ], [ 29.796682025316457, -1.44835275862069 ], [ 29.796412531645569, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19375, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.44835275862069 ], [ 29.796682025316457, -1.44862224137931 ], [ 29.796951518987342, -1.44862224137931 ], [ 29.796951518987342, -1.44835275862069 ], [ 29.796682025316457, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19376, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.44835275862069 ], [ 29.796951518987342, -1.44862224137931 ], [ 29.797221012658227, -1.44862224137931 ], [ 29.797221012658227, -1.44835275862069 ], [ 29.796951518987342, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19377, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.44835275862069 ], [ 29.797221012658227, -1.44862224137931 ], [ 29.797490506329115, -1.44862224137931 ], [ 29.797490506329115, -1.44835275862069 ], [ 29.797221012658227, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19378, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.44835275862069 ], [ 29.797490506329115, -1.44862224137931 ], [ 29.79776, -1.44862224137931 ], [ 29.79776, -1.44835275862069 ], [ 29.797490506329115, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19379, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.44835275862069 ], [ 29.79776, -1.44862224137931 ], [ 29.798029493670885, -1.44862224137931 ], [ 29.798029493670885, -1.44835275862069 ], [ 29.79776, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19380, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.44835275862069 ], [ 29.798029493670885, -1.44862224137931 ], [ 29.798298987341774, -1.44862224137931 ], [ 29.798298987341774, -1.44835275862069 ], [ 29.798029493670885, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19381, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.44835275862069 ], [ 29.798298987341774, -1.44862224137931 ], [ 29.798568481012659, -1.44862224137931 ], [ 29.798568481012659, -1.44835275862069 ], [ 29.798298987341774, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19382, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.44835275862069 ], [ 29.798568481012659, -1.44862224137931 ], [ 29.798837974683543, -1.44862224137931 ], [ 29.798837974683543, -1.44835275862069 ], [ 29.798568481012659, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19383, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.44835275862069 ], [ 29.798837974683543, -1.448891724137931 ], [ 29.799107468354432, -1.448891724137931 ], [ 29.799107468354432, -1.44835275862069 ], [ 29.798837974683543, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19384, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.44835275862069 ], [ 29.802341392405065, -1.44862224137931 ], [ 29.80261088607595, -1.44862224137931 ], [ 29.80261088607595, -1.44835275862069 ], [ 29.802341392405065, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19385, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.44835275862069 ], [ 29.80261088607595, -1.44862224137931 ], [ 29.802880379746835, -1.44862224137931 ], [ 29.802880379746835, -1.44835275862069 ], [ 29.80261088607595, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19386, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.44835275862069 ], [ 29.802880379746835, -1.44862224137931 ], [ 29.803149873417723, -1.44862224137931 ], [ 29.803149873417723, -1.44835275862069 ], [ 29.802880379746835, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19387, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.44835275862069 ], [ 29.803149873417723, -1.44862224137931 ], [ 29.803419367088608, -1.44862224137931 ], [ 29.803419367088608, -1.44835275862069 ], [ 29.803149873417723, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19388, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.44835275862069 ], [ 29.803419367088608, -1.44862224137931 ], [ 29.803688860759493, -1.44862224137931 ], [ 29.803688860759493, -1.44835275862069 ], [ 29.803419367088608, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19389, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.44835275862069 ], [ 29.803688860759493, -1.44862224137931 ], [ 29.803958354430382, -1.44862224137931 ], [ 29.803958354430382, -1.44835275862069 ], [ 29.803688860759493, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19390, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.44835275862069 ], [ 29.803958354430382, -1.44862224137931 ], [ 29.804227848101267, -1.44862224137931 ], [ 29.804227848101267, -1.44835275862069 ], [ 29.803958354430382, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19391, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.44835275862069 ], [ 29.804227848101267, -1.448891724137931 ], [ 29.804497341772151, -1.448891724137931 ], [ 29.804497341772151, -1.44835275862069 ], [ 29.804227848101267, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19392, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.44835275862069 ], [ 29.804497341772151, -1.44862224137931 ], [ 29.80476683544304, -1.44862224137931 ], [ 29.80476683544304, -1.44835275862069 ], [ 29.804497341772151, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19393, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.44835275862069 ], [ 29.80530582278481, -1.448891724137931 ], [ 29.805575316455698, -1.448891724137931 ], [ 29.805575316455698, -1.44835275862069 ], [ 29.80530582278481, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19394, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.44835275862069 ], [ 29.806114303797468, -1.44862224137931 ], [ 29.806383797468357, -1.44862224137931 ], [ 29.806383797468357, -1.44835275862069 ], [ 29.806114303797468, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19395, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.44835275862069 ], [ 29.806383797468357, -1.44862224137931 ], [ 29.806653291139241, -1.44862224137931 ], [ 29.806653291139241, -1.44835275862069 ], [ 29.806383797468357, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19396, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.44835275862069 ], [ 29.806653291139241, -1.44862224137931 ], [ 29.806922784810126, -1.44862224137931 ], [ 29.806922784810126, -1.44835275862069 ], [ 29.806653291139241, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19397, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.44835275862069 ], [ 29.806922784810126, -1.44862224137931 ], [ 29.807192278481015, -1.44862224137931 ], [ 29.807192278481015, -1.44835275862069 ], [ 29.806922784810126, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19398, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.749790126582276, -1.44862224137931 ], [ 29.749790126582276, -1.449161206896552 ], [ 29.750059620253165, -1.449161206896552 ], [ 29.750059620253165, -1.44862224137931 ], [ 29.749790126582276, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19399, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.44862224137931 ], [ 29.750059620253165, -1.448891724137931 ], [ 29.75032911392405, -1.448891724137931 ], [ 29.75032911392405, -1.44862224137931 ], [ 29.750059620253165, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19400, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.44862224137931 ], [ 29.75032911392405, -1.448891724137931 ], [ 29.750598607594934, -1.448891724137931 ], [ 29.750598607594934, -1.44862224137931 ], [ 29.75032911392405, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19401, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.44862224137931 ], [ 29.750598607594934, -1.448891724137931 ], [ 29.750868101265823, -1.448891724137931 ], [ 29.750868101265823, -1.44862224137931 ], [ 29.750598607594934, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19402, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.44862224137931 ], [ 29.750868101265823, -1.448891724137931 ], [ 29.751137594936708, -1.448891724137931 ], [ 29.751137594936708, -1.44862224137931 ], [ 29.750868101265823, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19403, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.44862224137931 ], [ 29.751137594936708, -1.448891724137931 ], [ 29.751407088607593, -1.448891724137931 ], [ 29.751407088607593, -1.44862224137931 ], [ 29.751137594936708, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19404, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.44862224137931 ], [ 29.751407088607593, -1.449161206896552 ], [ 29.751676582278481, -1.449161206896552 ], [ 29.751676582278481, -1.44862224137931 ], [ 29.751407088607593, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19405, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.44862224137931 ], [ 29.751676582278481, -1.448891724137931 ], [ 29.751946075949366, -1.448891724137931 ], [ 29.751946075949366, -1.44862224137931 ], [ 29.751676582278481, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19406, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.44862224137931 ], [ 29.751946075949366, -1.448891724137931 ], [ 29.752215569620251, -1.448891724137931 ], [ 29.752215569620251, -1.44862224137931 ], [ 29.751946075949366, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19407, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.44862224137931 ], [ 29.752754556962024, -1.449161206896552 ], [ 29.753024050632909, -1.449161206896552 ], [ 29.753024050632909, -1.44862224137931 ], [ 29.752754556962024, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19408, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.44862224137931 ], [ 29.762725822784809, -1.448891724137931 ], [ 29.762995316455697, -1.448891724137931 ], [ 29.762995316455697, -1.44862224137931 ], [ 29.762725822784809, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19409, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.44862224137931 ], [ 29.762995316455697, -1.448891724137931 ], [ 29.763264810126582, -1.448891724137931 ], [ 29.763264810126582, -1.44862224137931 ], [ 29.762995316455697, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19410, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.44862224137931 ], [ 29.763264810126582, -1.448891724137931 ], [ 29.763534303797467, -1.448891724137931 ], [ 29.763534303797467, -1.44862224137931 ], [ 29.763264810126582, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19411, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.44862224137931 ], [ 29.763534303797467, -1.448891724137931 ], [ 29.763803797468356, -1.448891724137931 ], [ 29.763803797468356, -1.44862224137931 ], [ 29.763534303797467, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19412, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.44862224137931 ], [ 29.763803797468356, -1.448891724137931 ], [ 29.76407329113924, -1.448891724137931 ], [ 29.76407329113924, -1.44862224137931 ], [ 29.763803797468356, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19413, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.44862224137931 ], [ 29.76407329113924, -1.448891724137931 ], [ 29.764612278481014, -1.448891724137931 ], [ 29.764612278481014, -1.44862224137931 ], [ 29.76407329113924, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19414, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.44862224137931 ], [ 29.769193670886075, -1.448891724137931 ], [ 29.76946316455696, -1.448891724137931 ], [ 29.76946316455696, -1.44862224137931 ], [ 29.769193670886075, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19415, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.44862224137931 ], [ 29.76946316455696, -1.448891724137931 ], [ 29.769732658227849, -1.448891724137931 ], [ 29.769732658227849, -1.44862224137931 ], [ 29.76946316455696, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19416, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.44862224137931 ], [ 29.769732658227849, -1.448891724137931 ], [ 29.770002151898733, -1.448891724137931 ], [ 29.770002151898733, -1.44862224137931 ], [ 29.769732658227849, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19417, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.44862224137931 ], [ 29.770002151898733, -1.448891724137931 ], [ 29.770271645569618, -1.448891724137931 ], [ 29.770271645569618, -1.44862224137931 ], [ 29.770002151898733, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19418, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.44862224137931 ], [ 29.770271645569618, -1.449161206896552 ], [ 29.770541139240507, -1.449161206896552 ], [ 29.770541139240507, -1.44862224137931 ], [ 29.770271645569618, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19419, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.44862224137931 ], [ 29.770541139240507, -1.448891724137931 ], [ 29.770810632911392, -1.448891724137931 ], [ 29.770810632911392, -1.44862224137931 ], [ 29.770541139240507, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19420, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.44862224137931 ], [ 29.770810632911392, -1.448891724137931 ], [ 29.771080126582277, -1.448891724137931 ], [ 29.771080126582277, -1.44862224137931 ], [ 29.770810632911392, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19421, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.44862224137931 ], [ 29.771080126582277, -1.449161206896552 ], [ 29.771349620253165, -1.449161206896552 ], [ 29.771349620253165, -1.44862224137931 ], [ 29.771080126582277, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19422, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.44862224137931 ], [ 29.77161911392405, -1.448891724137931 ], [ 29.771888607594935, -1.448891724137931 ], [ 29.771888607594935, -1.44862224137931 ], [ 29.77161911392405, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19423, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.44862224137931 ], [ 29.771888607594935, -1.449161206896552 ], [ 29.772158101265823, -1.449161206896552 ], [ 29.772158101265823, -1.44862224137931 ], [ 29.771888607594935, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19424, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.44862224137931 ], [ 29.772427594936708, -1.449161206896552 ], [ 29.772697088607593, -1.449161206896552 ], [ 29.772697088607593, -1.44862224137931 ], [ 29.772427594936708, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19425, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.44862224137931 ], [ 29.772697088607593, -1.450239137931035 ], [ 29.772966582278482, -1.450239137931035 ], [ 29.772966582278482, -1.44862224137931 ], [ 29.772697088607593, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19426, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.44862224137931 ], [ 29.773236075949367, -1.449161206896552 ], [ 29.773505569620252, -1.449161206896552 ], [ 29.773505569620252, -1.44862224137931 ], [ 29.773236075949367, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19427, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.44862224137931 ], [ 29.773505569620252, -1.449969655172414 ], [ 29.77377506329114, -1.449969655172414 ], [ 29.77377506329114, -1.44862224137931 ], [ 29.773505569620252, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19428, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.44862224137931 ], [ 29.774044556962025, -1.449700172413793 ], [ 29.77431405063291, -1.449700172413793 ], [ 29.77431405063291, -1.44862224137931 ], [ 29.774044556962025, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19429, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.44862224137931 ], [ 29.775122531645568, -1.449430689655173 ], [ 29.775392025316457, -1.449430689655173 ], [ 29.775392025316457, -1.44862224137931 ], [ 29.775122531645568, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19430, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.44862224137931 ], [ 29.775661518987341, -1.449161206896552 ], [ 29.775931012658226, -1.449161206896552 ], [ 29.775931012658226, -1.44862224137931 ], [ 29.775661518987341, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19431, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.44862224137931 ], [ 29.775931012658226, -1.449161206896552 ], [ 29.776200506329115, -1.449161206896552 ], [ 29.776200506329115, -1.44862224137931 ], [ 29.775931012658226, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19432, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.44862224137931 ], [ 29.77647, -1.448891724137931 ], [ 29.776739493670885, -1.448891724137931 ], [ 29.776739493670885, -1.44862224137931 ], [ 29.77647, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19433, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.44862224137931 ], [ 29.777278481012658, -1.448891724137931 ], [ 29.777547974683543, -1.448891724137931 ], [ 29.777547974683543, -1.44862224137931 ], [ 29.777278481012658, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19434, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.44862224137931 ], [ 29.794526075949367, -1.448891724137931 ], [ 29.794795569620252, -1.448891724137931 ], [ 29.794795569620252, -1.44862224137931 ], [ 29.794526075949367, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19435, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.44862224137931 ], [ 29.794795569620252, -1.448891724137931 ], [ 29.79506506329114, -1.448891724137931 ], [ 29.79506506329114, -1.44862224137931 ], [ 29.794795569620252, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19436, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.44862224137931 ], [ 29.79506506329114, -1.448891724137931 ], [ 29.795334556962025, -1.448891724137931 ], [ 29.795334556962025, -1.44862224137931 ], [ 29.79506506329114, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19437, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.44862224137931 ], [ 29.795334556962025, -1.448891724137931 ], [ 29.79560405063291, -1.448891724137931 ], [ 29.79560405063291, -1.44862224137931 ], [ 29.795334556962025, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19438, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.44862224137931 ], [ 29.79560405063291, -1.448891724137931 ], [ 29.795873544303799, -1.448891724137931 ], [ 29.795873544303799, -1.44862224137931 ], [ 29.79560405063291, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19439, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.44862224137931 ], [ 29.795873544303799, -1.448891724137931 ], [ 29.796143037974684, -1.448891724137931 ], [ 29.796143037974684, -1.44862224137931 ], [ 29.795873544303799, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19440, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.44862224137931 ], [ 29.796143037974684, -1.448891724137931 ], [ 29.796412531645569, -1.448891724137931 ], [ 29.796412531645569, -1.44862224137931 ], [ 29.796143037974684, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19441, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.44862224137931 ], [ 29.796412531645569, -1.448891724137931 ], [ 29.796682025316457, -1.448891724137931 ], [ 29.796682025316457, -1.44862224137931 ], [ 29.796412531645569, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19442, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.44862224137931 ], [ 29.796682025316457, -1.448891724137931 ], [ 29.796951518987342, -1.448891724137931 ], [ 29.796951518987342, -1.44862224137931 ], [ 29.796682025316457, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19443, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.44862224137931 ], [ 29.796951518987342, -1.448891724137931 ], [ 29.797221012658227, -1.448891724137931 ], [ 29.797221012658227, -1.44862224137931 ], [ 29.796951518987342, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19444, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.44862224137931 ], [ 29.797221012658227, -1.448891724137931 ], [ 29.797490506329115, -1.448891724137931 ], [ 29.797490506329115, -1.44862224137931 ], [ 29.797221012658227, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19445, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.44862224137931 ], [ 29.797490506329115, -1.448891724137931 ], [ 29.79776, -1.448891724137931 ], [ 29.79776, -1.44862224137931 ], [ 29.797490506329115, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19446, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.44862224137931 ], [ 29.79776, -1.448891724137931 ], [ 29.798029493670885, -1.448891724137931 ], [ 29.798029493670885, -1.44862224137931 ], [ 29.79776, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19447, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.44862224137931 ], [ 29.798029493670885, -1.448891724137931 ], [ 29.798298987341774, -1.448891724137931 ], [ 29.798298987341774, -1.44862224137931 ], [ 29.798029493670885, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19448, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.44862224137931 ], [ 29.798298987341774, -1.448891724137931 ], [ 29.798568481012659, -1.448891724137931 ], [ 29.798568481012659, -1.44862224137931 ], [ 29.798298987341774, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19449, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.44862224137931 ], [ 29.798568481012659, -1.448891724137931 ], [ 29.798837974683543, -1.448891724137931 ], [ 29.798837974683543, -1.44862224137931 ], [ 29.798568481012659, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19450, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.44862224137931 ], [ 29.80261088607595, -1.448891724137931 ], [ 29.802880379746835, -1.448891724137931 ], [ 29.802880379746835, -1.44862224137931 ], [ 29.80261088607595, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19451, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.44862224137931 ], [ 29.802880379746835, -1.448891724137931 ], [ 29.803149873417723, -1.448891724137931 ], [ 29.803149873417723, -1.44862224137931 ], [ 29.802880379746835, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19452, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.44862224137931 ], [ 29.803149873417723, -1.448891724137931 ], [ 29.803419367088608, -1.448891724137931 ], [ 29.803419367088608, -1.44862224137931 ], [ 29.803149873417723, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19453, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.44862224137931 ], [ 29.803419367088608, -1.448891724137931 ], [ 29.803688860759493, -1.448891724137931 ], [ 29.803688860759493, -1.44862224137931 ], [ 29.803419367088608, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19454, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.44862224137931 ], [ 29.805036329113925, -1.448891724137931 ], [ 29.80530582278481, -1.448891724137931 ], [ 29.80530582278481, -1.44862224137931 ], [ 29.805036329113925, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19455, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.44862224137931 ], [ 29.805575316455698, -1.448891724137931 ], [ 29.805844810126583, -1.448891724137931 ], [ 29.805844810126583, -1.44862224137931 ], [ 29.805575316455698, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19456, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.44862224137931 ], [ 29.805844810126583, -1.448891724137931 ], [ 29.806114303797468, -1.448891724137931 ], [ 29.806114303797468, -1.44862224137931 ], [ 29.805844810126583, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19457, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.44862224137931 ], [ 29.806114303797468, -1.448891724137931 ], [ 29.806383797468357, -1.448891724137931 ], [ 29.806383797468357, -1.44862224137931 ], [ 29.806114303797468, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19458, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.44862224137931 ], [ 29.806383797468357, -1.448891724137931 ], [ 29.806653291139241, -1.448891724137931 ], [ 29.806653291139241, -1.44862224137931 ], [ 29.806383797468357, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19459, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.44862224137931 ], [ 29.806653291139241, -1.448891724137931 ], [ 29.806922784810126, -1.448891724137931 ], [ 29.806922784810126, -1.44862224137931 ], [ 29.806653291139241, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19460, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.44862224137931 ], [ 29.806922784810126, -1.448891724137931 ], [ 29.807192278481015, -1.448891724137931 ], [ 29.807192278481015, -1.44862224137931 ], [ 29.806922784810126, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19461, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.44862224137931 ], [ 29.807192278481015, -1.449161206896552 ], [ 29.8074617721519, -1.449161206896552 ], [ 29.8074617721519, -1.44862224137931 ], [ 29.807192278481015, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19462, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.448891724137931 ], [ 29.750059620253165, -1.449161206896552 ], [ 29.75032911392405, -1.449161206896552 ], [ 29.75032911392405, -1.448891724137931 ], [ 29.750059620253165, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19463, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.448891724137931 ], [ 29.75032911392405, -1.449161206896552 ], [ 29.750598607594934, -1.449161206896552 ], [ 29.750598607594934, -1.448891724137931 ], [ 29.75032911392405, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19464, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.448891724137931 ], [ 29.750598607594934, -1.449161206896552 ], [ 29.750868101265823, -1.449161206896552 ], [ 29.750868101265823, -1.448891724137931 ], [ 29.750598607594934, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19465, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.448891724137931 ], [ 29.762995316455697, -1.449161206896552 ], [ 29.763264810126582, -1.449161206896552 ], [ 29.763264810126582, -1.448891724137931 ], [ 29.762995316455697, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19466, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.448891724137931 ], [ 29.763264810126582, -1.449161206896552 ], [ 29.763534303797467, -1.449161206896552 ], [ 29.763534303797467, -1.448891724137931 ], [ 29.763264810126582, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19467, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.448891724137931 ], [ 29.763534303797467, -1.449161206896552 ], [ 29.763803797468356, -1.449161206896552 ], [ 29.763803797468356, -1.448891724137931 ], [ 29.763534303797467, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19468, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.448891724137931 ], [ 29.763803797468356, -1.449161206896552 ], [ 29.76407329113924, -1.449161206896552 ], [ 29.76407329113924, -1.448891724137931 ], [ 29.763803797468356, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19469, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.44862224137931 ], [ 29.764881772151899, -1.44862224137931 ], [ 29.764881772151899, -1.449161206896552 ], [ 29.76407329113924, -1.449161206896552 ], [ 29.76407329113924, -1.448891724137931 ], [ 29.764612278481014, -1.448891724137931 ], [ 29.764612278481014, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19470, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.448891724137931 ], [ 29.768385189873417, -1.449161206896552 ], [ 29.768654683544302, -1.449161206896552 ], [ 29.768654683544302, -1.448891724137931 ], [ 29.768385189873417, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19471, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.448891724137931 ], [ 29.768654683544302, -1.449161206896552 ], [ 29.76892417721519, -1.449161206896552 ], [ 29.76892417721519, -1.448891724137931 ], [ 29.768654683544302, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19472, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.448891724137931 ], [ 29.76892417721519, -1.449161206896552 ], [ 29.769193670886075, -1.449161206896552 ], [ 29.769193670886075, -1.448891724137931 ], [ 29.76892417721519, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19473, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.448891724137931 ], [ 29.769193670886075, -1.449430689655173 ], [ 29.76946316455696, -1.449430689655173 ], [ 29.76946316455696, -1.448891724137931 ], [ 29.769193670886075, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19474, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.448891724137931 ], [ 29.76946316455696, -1.449161206896552 ], [ 29.769732658227849, -1.449161206896552 ], [ 29.769732658227849, -1.448891724137931 ], [ 29.76946316455696, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19475, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.448891724137931 ], [ 29.769732658227849, -1.449161206896552 ], [ 29.770002151898733, -1.449161206896552 ], [ 29.770002151898733, -1.448891724137931 ], [ 29.769732658227849, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19476, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.448891724137931 ], [ 29.770541139240507, -1.449430689655173 ], [ 29.770810632911392, -1.449430689655173 ], [ 29.770810632911392, -1.448891724137931 ], [ 29.770541139240507, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19477, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.448891724137931 ], [ 29.772966582278482, -1.449969655172414 ], [ 29.773236075949367, -1.449969655172414 ], [ 29.773236075949367, -1.448891724137931 ], [ 29.772966582278482, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19478, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.448891724137931 ], [ 29.77377506329114, -1.449969655172414 ], [ 29.774044556962025, -1.449969655172414 ], [ 29.774044556962025, -1.448891724137931 ], [ 29.77377506329114, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19479, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.448891724137931 ], [ 29.77431405063291, -1.449700172413793 ], [ 29.774853037974683, -1.449700172413793 ], [ 29.774853037974683, -1.448891724137931 ], [ 29.77431405063291, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19480, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.448891724137931 ], [ 29.775392025316457, -1.449430689655173 ], [ 29.775661518987341, -1.449430689655173 ], [ 29.775661518987341, -1.448891724137931 ], [ 29.775392025316457, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19481, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.448891724137931 ], [ 29.77647, -1.449161206896552 ], [ 29.776739493670885, -1.449161206896552 ], [ 29.776739493670885, -1.448891724137931 ], [ 29.77647, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19482, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.448891724137931 ], [ 29.776739493670885, -1.449161206896552 ], [ 29.777008987341773, -1.449161206896552 ], [ 29.777008987341773, -1.448891724137931 ], [ 29.776739493670885, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19483, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.448891724137931 ], [ 29.777008987341773, -1.449161206896552 ], [ 29.777278481012658, -1.449161206896552 ], [ 29.777278481012658, -1.448891724137931 ], [ 29.777008987341773, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19484, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.448891724137931 ], [ 29.777278481012658, -1.449161206896552 ], [ 29.777547974683543, -1.449161206896552 ], [ 29.777547974683543, -1.448891724137931 ], [ 29.777278481012658, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19485, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.448891724137931 ], [ 29.777547974683543, -1.449161206896552 ], [ 29.777817468354431, -1.449161206896552 ], [ 29.777817468354431, -1.448891724137931 ], [ 29.777547974683543, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19486, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.448891724137931 ], [ 29.794526075949367, -1.449161206896552 ], [ 29.794795569620252, -1.449161206896552 ], [ 29.794795569620252, -1.448891724137931 ], [ 29.794526075949367, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19487, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.448891724137931 ], [ 29.794795569620252, -1.449161206896552 ], [ 29.79506506329114, -1.449161206896552 ], [ 29.79506506329114, -1.448891724137931 ], [ 29.794795569620252, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19488, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.448891724137931 ], [ 29.79506506329114, -1.449161206896552 ], [ 29.795334556962025, -1.449161206896552 ], [ 29.795334556962025, -1.448891724137931 ], [ 29.79506506329114, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19489, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.448891724137931 ], [ 29.795334556962025, -1.449161206896552 ], [ 29.79560405063291, -1.449161206896552 ], [ 29.79560405063291, -1.448891724137931 ], [ 29.795334556962025, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19490, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.448891724137931 ], [ 29.79560405063291, -1.449161206896552 ], [ 29.795873544303799, -1.449161206896552 ], [ 29.795873544303799, -1.448891724137931 ], [ 29.79560405063291, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19491, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.448891724137931 ], [ 29.795873544303799, -1.449161206896552 ], [ 29.796143037974684, -1.449161206896552 ], [ 29.796143037974684, -1.448891724137931 ], [ 29.795873544303799, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19492, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.448891724137931 ], [ 29.796143037974684, -1.449161206896552 ], [ 29.796412531645569, -1.449161206896552 ], [ 29.796412531645569, -1.448891724137931 ], [ 29.796143037974684, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19493, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.448891724137931 ], [ 29.796412531645569, -1.449161206896552 ], [ 29.796682025316457, -1.449161206896552 ], [ 29.796682025316457, -1.448891724137931 ], [ 29.796412531645569, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19494, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.448891724137931 ], [ 29.796682025316457, -1.449161206896552 ], [ 29.796951518987342, -1.449161206896552 ], [ 29.796951518987342, -1.448891724137931 ], [ 29.796682025316457, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19495, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.448891724137931 ], [ 29.796951518987342, -1.449161206896552 ], [ 29.797221012658227, -1.449161206896552 ], [ 29.797221012658227, -1.448891724137931 ], [ 29.796951518987342, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19496, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.448891724137931 ], [ 29.797221012658227, -1.449161206896552 ], [ 29.797490506329115, -1.449161206896552 ], [ 29.797490506329115, -1.448891724137931 ], [ 29.797221012658227, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19497, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.448891724137931 ], [ 29.797490506329115, -1.449161206896552 ], [ 29.79776, -1.449161206896552 ], [ 29.79776, -1.448891724137931 ], [ 29.797490506329115, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19498, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.448891724137931 ], [ 29.79776, -1.449969655172414 ], [ 29.798029493670885, -1.449969655172414 ], [ 29.798029493670885, -1.448891724137931 ], [ 29.79776, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19499, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.448891724137931 ], [ 29.798029493670885, -1.449969655172414 ], [ 29.798298987341774, -1.449969655172414 ], [ 29.798298987341774, -1.448891724137931 ], [ 29.798029493670885, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19500, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.448891724137931 ], [ 29.798298987341774, -1.449430689655173 ], [ 29.798568481012659, -1.449430689655173 ], [ 29.798568481012659, -1.448891724137931 ], [ 29.798298987341774, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19501, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.448891724137931 ], [ 29.798568481012659, -1.449430689655173 ], [ 29.798837974683543, -1.449430689655173 ], [ 29.798837974683543, -1.448891724137931 ], [ 29.798568481012659, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19502, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.448891724137931 ], [ 29.798837974683543, -1.449430689655173 ], [ 29.799107468354432, -1.449430689655173 ], [ 29.799107468354432, -1.448891724137931 ], [ 29.798837974683543, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19503, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.448891724137931 ], [ 29.799107468354432, -1.449430689655173 ], [ 29.799376962025317, -1.449430689655173 ], [ 29.799376962025317, -1.448891724137931 ], [ 29.799107468354432, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19504, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.448891724137931 ], [ 29.805036329113925, -1.449161206896552 ], [ 29.80530582278481, -1.449161206896552 ], [ 29.80530582278481, -1.448891724137931 ], [ 29.805036329113925, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19505, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.448891724137931 ], [ 29.80530582278481, -1.449161206896552 ], [ 29.805575316455698, -1.449161206896552 ], [ 29.805575316455698, -1.448891724137931 ], [ 29.80530582278481, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19506, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.448891724137931 ], [ 29.805575316455698, -1.449161206896552 ], [ 29.805844810126583, -1.449161206896552 ], [ 29.805844810126583, -1.448891724137931 ], [ 29.805575316455698, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19507, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.448891724137931 ], [ 29.805844810126583, -1.449161206896552 ], [ 29.806114303797468, -1.449161206896552 ], [ 29.806114303797468, -1.448891724137931 ], [ 29.805844810126583, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19508, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.448891724137931 ], [ 29.806114303797468, -1.449161206896552 ], [ 29.806383797468357, -1.449161206896552 ], [ 29.806383797468357, -1.448891724137931 ], [ 29.806114303797468, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19509, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.448891724137931 ], [ 29.806383797468357, -1.449161206896552 ], [ 29.806653291139241, -1.449161206896552 ], [ 29.806653291139241, -1.448891724137931 ], [ 29.806383797468357, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19510, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.448891724137931 ], [ 29.806653291139241, -1.449161206896552 ], [ 29.806922784810126, -1.449161206896552 ], [ 29.806922784810126, -1.448891724137931 ], [ 29.806653291139241, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19511, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.448891724137931 ], [ 29.806922784810126, -1.449161206896552 ], [ 29.807192278481015, -1.449161206896552 ], [ 29.807192278481015, -1.448891724137931 ], [ 29.806922784810126, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19512, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.449161206896552 ], [ 29.763264810126582, -1.449430689655173 ], [ 29.76407329113924, -1.449430689655173 ], [ 29.76407329113924, -1.449161206896552 ], [ 29.763264810126582, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19513, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.449161206896552 ], [ 29.767846202531643, -1.449430689655173 ], [ 29.768115696202532, -1.449430689655173 ], [ 29.768115696202532, -1.449161206896552 ], [ 29.767846202531643, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19514, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.449161206896552 ], [ 29.768115696202532, -1.449430689655173 ], [ 29.768385189873417, -1.449430689655173 ], [ 29.768385189873417, -1.449161206896552 ], [ 29.768115696202532, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19515, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.449161206896552 ], [ 29.768385189873417, -1.449430689655173 ], [ 29.768654683544302, -1.449430689655173 ], [ 29.768654683544302, -1.449161206896552 ], [ 29.768385189873417, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19516, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.449161206896552 ], [ 29.772427594936708, -1.450239137931035 ], [ 29.772697088607593, -1.450239137931035 ], [ 29.772697088607593, -1.449161206896552 ], [ 29.772427594936708, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19517, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.449161206896552 ], [ 29.773236075949367, -1.449969655172414 ], [ 29.773505569620252, -1.449969655172414 ], [ 29.773505569620252, -1.449161206896552 ], [ 29.773236075949367, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19518, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.449161206896552 ], [ 29.774853037974683, -1.449700172413793 ], [ 29.775122531645568, -1.449700172413793 ], [ 29.775122531645568, -1.449161206896552 ], [ 29.774853037974683, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19519, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.449161206896552 ], [ 29.775661518987341, -1.449430689655173 ], [ 29.775931012658226, -1.449430689655173 ], [ 29.775931012658226, -1.449161206896552 ], [ 29.775661518987341, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19520, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.449161206896552 ], [ 29.775931012658226, -1.449430689655173 ], [ 29.776200506329115, -1.449430689655173 ], [ 29.776200506329115, -1.449161206896552 ], [ 29.775931012658226, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19521, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.449161206896552 ], [ 29.776200506329115, -1.449430689655173 ], [ 29.77647, -1.449430689655173 ], [ 29.77647, -1.449161206896552 ], [ 29.776200506329115, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19522, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.449161206896552 ], [ 29.77647, -1.449430689655173 ], [ 29.776739493670885, -1.449430689655173 ], [ 29.776739493670885, -1.449161206896552 ], [ 29.77647, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19523, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.449161206896552 ], [ 29.776739493670885, -1.449430689655173 ], [ 29.777008987341773, -1.449430689655173 ], [ 29.777008987341773, -1.449161206896552 ], [ 29.776739493670885, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19524, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.449161206896552 ], [ 29.777008987341773, -1.449430689655173 ], [ 29.777278481012658, -1.449430689655173 ], [ 29.777278481012658, -1.449161206896552 ], [ 29.777008987341773, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19525, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.449161206896552 ], [ 29.777278481012658, -1.449430689655173 ], [ 29.777547974683543, -1.449430689655173 ], [ 29.777547974683543, -1.449161206896552 ], [ 29.777278481012658, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19526, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.447544310344828 ], [ 29.778086962025316, -1.447544310344828 ], [ 29.778086962025316, -1.449969655172414 ], [ 29.777817468354431, -1.449969655172414 ], [ 29.777817468354431, -1.449430689655173 ], [ 29.777547974683543, -1.449430689655173 ], [ 29.777547974683543, -1.449161206896552 ], [ 29.777817468354431, -1.449161206896552 ], [ 29.777817468354431, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19527, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.449161206896552 ], [ 29.794526075949367, -1.449430689655173 ], [ 29.794795569620252, -1.449430689655173 ], [ 29.794795569620252, -1.449161206896552 ], [ 29.794526075949367, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19528, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.449161206896552 ], [ 29.794795569620252, -1.449430689655173 ], [ 29.79506506329114, -1.449430689655173 ], [ 29.79506506329114, -1.449161206896552 ], [ 29.794795569620252, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19529, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.449161206896552 ], [ 29.79506506329114, -1.449430689655173 ], [ 29.795334556962025, -1.449430689655173 ], [ 29.795334556962025, -1.449161206896552 ], [ 29.79506506329114, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19530, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.449161206896552 ], [ 29.795334556962025, -1.449430689655173 ], [ 29.79560405063291, -1.449430689655173 ], [ 29.79560405063291, -1.449161206896552 ], [ 29.795334556962025, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19531, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.449161206896552 ], [ 29.79560405063291, -1.449430689655173 ], [ 29.795873544303799, -1.449430689655173 ], [ 29.795873544303799, -1.449161206896552 ], [ 29.79560405063291, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19532, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.449161206896552 ], [ 29.795873544303799, -1.449430689655173 ], [ 29.796143037974684, -1.449430689655173 ], [ 29.796143037974684, -1.449161206896552 ], [ 29.795873544303799, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19533, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.449161206896552 ], [ 29.796143037974684, -1.449430689655173 ], [ 29.796412531645569, -1.449430689655173 ], [ 29.796412531645569, -1.449161206896552 ], [ 29.796143037974684, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19534, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.449161206896552 ], [ 29.796412531645569, -1.449430689655173 ], [ 29.796682025316457, -1.449430689655173 ], [ 29.796682025316457, -1.449161206896552 ], [ 29.796412531645569, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19535, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.449161206896552 ], [ 29.796682025316457, -1.449430689655173 ], [ 29.796951518987342, -1.449430689655173 ], [ 29.796951518987342, -1.449161206896552 ], [ 29.796682025316457, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19536, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.449161206896552 ], [ 29.796951518987342, -1.449700172413793 ], [ 29.797221012658227, -1.449700172413793 ], [ 29.797221012658227, -1.449161206896552 ], [ 29.796951518987342, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19537, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.449161206896552 ], [ 29.797221012658227, -1.449700172413793 ], [ 29.797490506329115, -1.449700172413793 ], [ 29.797490506329115, -1.449161206896552 ], [ 29.797221012658227, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19538, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.449161206896552 ], [ 29.797490506329115, -1.449430689655173 ], [ 29.79776, -1.449430689655173 ], [ 29.79776, -1.449161206896552 ], [ 29.797490506329115, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19539, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.449161206896552 ], [ 29.80530582278481, -1.449430689655173 ], [ 29.805575316455698, -1.449430689655173 ], [ 29.805575316455698, -1.449161206896552 ], [ 29.80530582278481, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19540, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.449161206896552 ], [ 29.805575316455698, -1.449430689655173 ], [ 29.805844810126583, -1.449430689655173 ], [ 29.805844810126583, -1.449161206896552 ], [ 29.805575316455698, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19541, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.449161206896552 ], [ 29.805844810126583, -1.449430689655173 ], [ 29.806114303797468, -1.449430689655173 ], [ 29.806114303797468, -1.449161206896552 ], [ 29.805844810126583, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19542, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.449161206896552 ], [ 29.806114303797468, -1.449430689655173 ], [ 29.806383797468357, -1.449430689655173 ], [ 29.806383797468357, -1.449161206896552 ], [ 29.806114303797468, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19543, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.449161206896552 ], [ 29.806383797468357, -1.449430689655173 ], [ 29.806653291139241, -1.449430689655173 ], [ 29.806653291139241, -1.449161206896552 ], [ 29.806383797468357, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19544, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.449161206896552 ], [ 29.806653291139241, -1.449430689655173 ], [ 29.806922784810126, -1.449430689655173 ], [ 29.806922784810126, -1.449161206896552 ], [ 29.806653291139241, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19545, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.449161206896552 ], [ 29.806922784810126, -1.449430689655173 ], [ 29.807192278481015, -1.449430689655173 ], [ 29.807192278481015, -1.449161206896552 ], [ 29.806922784810126, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19546, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.449161206896552 ], [ 29.807192278481015, -1.449430689655173 ], [ 29.8074617721519, -1.449430689655173 ], [ 29.8074617721519, -1.449161206896552 ], [ 29.807192278481015, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19547, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.449161206896552 ], [ 29.8074617721519, -1.449430689655173 ], [ 29.807731265822785, -1.449430689655173 ], [ 29.807731265822785, -1.449161206896552 ], [ 29.8074617721519, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19548, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.449430689655173 ], [ 29.775122531645568, -1.449700172413793 ], [ 29.775392025316457, -1.449700172413793 ], [ 29.775392025316457, -1.449430689655173 ], [ 29.775122531645568, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19549, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.449430689655173 ], [ 29.775392025316457, -1.449700172413793 ], [ 29.775661518987341, -1.449700172413793 ], [ 29.775661518987341, -1.449430689655173 ], [ 29.775392025316457, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19550, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.449430689655173 ], [ 29.775661518987341, -1.449700172413793 ], [ 29.775931012658226, -1.449700172413793 ], [ 29.775931012658226, -1.449430689655173 ], [ 29.775661518987341, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19551, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.449430689655173 ], [ 29.775931012658226, -1.449700172413793 ], [ 29.776200506329115, -1.449700172413793 ], [ 29.776200506329115, -1.449430689655173 ], [ 29.775931012658226, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19552, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.449430689655173 ], [ 29.776200506329115, -1.449700172413793 ], [ 29.77647, -1.449700172413793 ], [ 29.77647, -1.449430689655173 ], [ 29.776200506329115, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19553, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.449430689655173 ], [ 29.77647, -1.449700172413793 ], [ 29.776739493670885, -1.449700172413793 ], [ 29.776739493670885, -1.449430689655173 ], [ 29.77647, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19554, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.449430689655173 ], [ 29.776739493670885, -1.449700172413793 ], [ 29.777008987341773, -1.449700172413793 ], [ 29.777008987341773, -1.449430689655173 ], [ 29.776739493670885, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19555, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.449430689655173 ], [ 29.777008987341773, -1.449700172413793 ], [ 29.777278481012658, -1.449700172413793 ], [ 29.777278481012658, -1.449430689655173 ], [ 29.777008987341773, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19556, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.449430689655173 ], [ 29.777278481012658, -1.449700172413793 ], [ 29.777547974683543, -1.449700172413793 ], [ 29.777547974683543, -1.449430689655173 ], [ 29.777278481012658, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19557, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.449430689655173 ], [ 29.777547974683543, -1.449700172413793 ], [ 29.777817468354431, -1.449700172413793 ], [ 29.777817468354431, -1.449430689655173 ], [ 29.777547974683543, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19558, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.449430689655173 ], [ 29.794526075949367, -1.449700172413793 ], [ 29.794795569620252, -1.449700172413793 ], [ 29.794795569620252, -1.449430689655173 ], [ 29.794526075949367, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19559, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.449430689655173 ], [ 29.794795569620252, -1.449700172413793 ], [ 29.79506506329114, -1.449700172413793 ], [ 29.79506506329114, -1.449430689655173 ], [ 29.794795569620252, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19560, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.449430689655173 ], [ 29.79506506329114, -1.449700172413793 ], [ 29.795334556962025, -1.449700172413793 ], [ 29.795334556962025, -1.449430689655173 ], [ 29.79506506329114, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19561, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.449430689655173 ], [ 29.795334556962025, -1.449700172413793 ], [ 29.79560405063291, -1.449700172413793 ], [ 29.79560405063291, -1.449430689655173 ], [ 29.795334556962025, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19562, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.449430689655173 ], [ 29.79560405063291, -1.449700172413793 ], [ 29.795873544303799, -1.449700172413793 ], [ 29.795873544303799, -1.449430689655173 ], [ 29.79560405063291, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19563, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.449430689655173 ], [ 29.795873544303799, -1.449969655172414 ], [ 29.796143037974684, -1.449969655172414 ], [ 29.796143037974684, -1.449430689655173 ], [ 29.795873544303799, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19564, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.449430689655173 ], [ 29.796143037974684, -1.449700172413793 ], [ 29.796412531645569, -1.449700172413793 ], [ 29.796412531645569, -1.449430689655173 ], [ 29.796143037974684, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19565, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.449430689655173 ], [ 29.796412531645569, -1.449700172413793 ], [ 29.796682025316457, -1.449700172413793 ], [ 29.796682025316457, -1.449430689655173 ], [ 29.796412531645569, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19566, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.449430689655173 ], [ 29.796682025316457, -1.449700172413793 ], [ 29.796951518987342, -1.449700172413793 ], [ 29.796951518987342, -1.449430689655173 ], [ 29.796682025316457, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19567, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.449430689655173 ], [ 29.797490506329115, -1.449969655172414 ], [ 29.79776, -1.449969655172414 ], [ 29.79776, -1.449430689655173 ], [ 29.797490506329115, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19568, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.449430689655173 ], [ 29.798298987341774, -1.450239137931035 ], [ 29.798568481012659, -1.450239137931035 ], [ 29.798568481012659, -1.449430689655173 ], [ 29.798298987341774, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19569, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.449430689655173 ], [ 29.798568481012659, -1.450239137931035 ], [ 29.798837974683543, -1.450239137931035 ], [ 29.798837974683543, -1.449430689655173 ], [ 29.798568481012659, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19570, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.449430689655173 ], [ 29.805036329113925, -1.449700172413793 ], [ 29.80530582278481, -1.449700172413793 ], [ 29.80530582278481, -1.449430689655173 ], [ 29.805036329113925, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19571, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.449430689655173 ], [ 29.80530582278481, -1.449700172413793 ], [ 29.805575316455698, -1.449700172413793 ], [ 29.805575316455698, -1.449430689655173 ], [ 29.80530582278481, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19572, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.449430689655173 ], [ 29.805575316455698, -1.449700172413793 ], [ 29.805844810126583, -1.449700172413793 ], [ 29.805844810126583, -1.449430689655173 ], [ 29.805575316455698, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19573, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.449430689655173 ], [ 29.805844810126583, -1.449700172413793 ], [ 29.806114303797468, -1.449700172413793 ], [ 29.806114303797468, -1.449430689655173 ], [ 29.805844810126583, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19574, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.449430689655173 ], [ 29.806114303797468, -1.449700172413793 ], [ 29.806383797468357, -1.449700172413793 ], [ 29.806383797468357, -1.449430689655173 ], [ 29.806114303797468, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19575, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.449430689655173 ], [ 29.806383797468357, -1.449700172413793 ], [ 29.806653291139241, -1.449700172413793 ], [ 29.806653291139241, -1.449430689655173 ], [ 29.806383797468357, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19576, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.449430689655173 ], [ 29.806653291139241, -1.449700172413793 ], [ 29.806922784810126, -1.449700172413793 ], [ 29.806922784810126, -1.449430689655173 ], [ 29.806653291139241, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19577, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.449430689655173 ], [ 29.806922784810126, -1.449700172413793 ], [ 29.807192278481015, -1.449700172413793 ], [ 29.807192278481015, -1.449430689655173 ], [ 29.806922784810126, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19578, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.449430689655173 ], [ 29.807192278481015, -1.449700172413793 ], [ 29.8074617721519, -1.449700172413793 ], [ 29.8074617721519, -1.449430689655173 ], [ 29.807192278481015, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19579, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.449430689655173 ], [ 29.8074617721519, -1.449700172413793 ], [ 29.807731265822785, -1.449700172413793 ], [ 29.807731265822785, -1.449430689655173 ], [ 29.8074617721519, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19580, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.449430689655173 ], [ 29.807731265822785, -1.449700172413793 ], [ 29.809078734177216, -1.449700172413793 ], [ 29.809078734177216, -1.449430689655173 ], [ 29.807731265822785, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19581, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.449700172413793 ], [ 29.774044556962025, -1.449969655172414 ], [ 29.77431405063291, -1.449969655172414 ], [ 29.77431405063291, -1.449700172413793 ], [ 29.774044556962025, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19582, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.449700172413793 ], [ 29.77431405063291, -1.449969655172414 ], [ 29.774853037974683, -1.449969655172414 ], [ 29.774853037974683, -1.449700172413793 ], [ 29.77431405063291, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19583, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.449700172413793 ], [ 29.774853037974683, -1.449969655172414 ], [ 29.775122531645568, -1.449969655172414 ], [ 29.775122531645568, -1.449700172413793 ], [ 29.774853037974683, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19584, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.449700172413793 ], [ 29.775122531645568, -1.449969655172414 ], [ 29.775392025316457, -1.449969655172414 ], [ 29.775392025316457, -1.449700172413793 ], [ 29.775122531645568, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19585, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.449700172413793 ], [ 29.775392025316457, -1.449969655172414 ], [ 29.775661518987341, -1.449969655172414 ], [ 29.775661518987341, -1.449700172413793 ], [ 29.775392025316457, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19586, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.449700172413793 ], [ 29.775661518987341, -1.449969655172414 ], [ 29.775931012658226, -1.449969655172414 ], [ 29.775931012658226, -1.449700172413793 ], [ 29.775661518987341, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19587, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.449700172413793 ], [ 29.775931012658226, -1.449969655172414 ], [ 29.776200506329115, -1.449969655172414 ], [ 29.776200506329115, -1.449700172413793 ], [ 29.775931012658226, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19588, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.449700172413793 ], [ 29.776200506329115, -1.449969655172414 ], [ 29.77647, -1.449969655172414 ], [ 29.77647, -1.449700172413793 ], [ 29.776200506329115, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19589, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.449700172413793 ], [ 29.77647, -1.449969655172414 ], [ 29.776739493670885, -1.449969655172414 ], [ 29.776739493670885, -1.449700172413793 ], [ 29.77647, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19590, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.449700172413793 ], [ 29.776739493670885, -1.449969655172414 ], [ 29.777008987341773, -1.449969655172414 ], [ 29.777008987341773, -1.449700172413793 ], [ 29.776739493670885, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19591, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.449700172413793 ], [ 29.777008987341773, -1.449969655172414 ], [ 29.777278481012658, -1.449969655172414 ], [ 29.777278481012658, -1.449700172413793 ], [ 29.777008987341773, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19592, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.449700172413793 ], [ 29.777278481012658, -1.449969655172414 ], [ 29.777547974683543, -1.449969655172414 ], [ 29.777547974683543, -1.449700172413793 ], [ 29.777278481012658, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19593, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.449700172413793 ], [ 29.777547974683543, -1.449969655172414 ], [ 29.777817468354431, -1.449969655172414 ], [ 29.777817468354431, -1.449700172413793 ], [ 29.777547974683543, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19594, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.449700172413793 ], [ 29.794795569620252, -1.449969655172414 ], [ 29.79506506329114, -1.449969655172414 ], [ 29.79506506329114, -1.449700172413793 ], [ 29.794795569620252, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19595, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.449700172413793 ], [ 29.79506506329114, -1.449969655172414 ], [ 29.795334556962025, -1.449969655172414 ], [ 29.795334556962025, -1.449700172413793 ], [ 29.79506506329114, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19596, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.449700172413793 ], [ 29.795334556962025, -1.450239137931035 ], [ 29.79560405063291, -1.450239137931035 ], [ 29.79560405063291, -1.449700172413793 ], [ 29.795334556962025, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19597, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.449700172413793 ], [ 29.79560405063291, -1.449969655172414 ], [ 29.795873544303799, -1.449969655172414 ], [ 29.795873544303799, -1.449700172413793 ], [ 29.79560405063291, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19598, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.449700172413793 ], [ 29.796143037974684, -1.450239137931035 ], [ 29.796412531645569, -1.450239137931035 ], [ 29.796412531645569, -1.449700172413793 ], [ 29.796143037974684, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19599, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.449700172413793 ], [ 29.796412531645569, -1.449969655172414 ], [ 29.796682025316457, -1.449969655172414 ], [ 29.796682025316457, -1.449700172413793 ], [ 29.796412531645569, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19600, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.449700172413793 ], [ 29.796682025316457, -1.449969655172414 ], [ 29.796951518987342, -1.449969655172414 ], [ 29.796951518987342, -1.449700172413793 ], [ 29.796682025316457, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19601, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.449700172413793 ], [ 29.796951518987342, -1.449969655172414 ], [ 29.797221012658227, -1.449969655172414 ], [ 29.797221012658227, -1.449700172413793 ], [ 29.796951518987342, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19602, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.449700172413793 ], [ 29.797221012658227, -1.449969655172414 ], [ 29.797490506329115, -1.449969655172414 ], [ 29.797490506329115, -1.449700172413793 ], [ 29.797221012658227, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19603, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.449700172413793 ], [ 29.805036329113925, -1.449969655172414 ], [ 29.80530582278481, -1.449969655172414 ], [ 29.80530582278481, -1.449700172413793 ], [ 29.805036329113925, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19604, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.449700172413793 ], [ 29.80530582278481, -1.449969655172414 ], [ 29.805575316455698, -1.449969655172414 ], [ 29.805575316455698, -1.449700172413793 ], [ 29.80530582278481, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19605, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.449700172413793 ], [ 29.805575316455698, -1.449969655172414 ], [ 29.805844810126583, -1.449969655172414 ], [ 29.805844810126583, -1.449700172413793 ], [ 29.805575316455698, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19606, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.449700172413793 ], [ 29.805844810126583, -1.449969655172414 ], [ 29.806114303797468, -1.449969655172414 ], [ 29.806114303797468, -1.449700172413793 ], [ 29.805844810126583, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19607, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.449700172413793 ], [ 29.806114303797468, -1.449969655172414 ], [ 29.806383797468357, -1.449969655172414 ], [ 29.806383797468357, -1.449700172413793 ], [ 29.806114303797468, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19608, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.449700172413793 ], [ 29.806383797468357, -1.449969655172414 ], [ 29.806653291139241, -1.449969655172414 ], [ 29.806653291139241, -1.449700172413793 ], [ 29.806383797468357, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19609, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.449700172413793 ], [ 29.806653291139241, -1.449969655172414 ], [ 29.806922784810126, -1.449969655172414 ], [ 29.806922784810126, -1.449700172413793 ], [ 29.806653291139241, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19610, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.449700172413793 ], [ 29.806922784810126, -1.449969655172414 ], [ 29.807192278481015, -1.449969655172414 ], [ 29.807192278481015, -1.449700172413793 ], [ 29.806922784810126, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19611, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.449700172413793 ], [ 29.807192278481015, -1.449969655172414 ], [ 29.8074617721519, -1.449969655172414 ], [ 29.8074617721519, -1.449700172413793 ], [ 29.807192278481015, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19612, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.449700172413793 ], [ 29.8074617721519, -1.449969655172414 ], [ 29.807731265822785, -1.449969655172414 ], [ 29.807731265822785, -1.449700172413793 ], [ 29.8074617721519, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19613, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.449700172413793 ], [ 29.807731265822785, -1.449969655172414 ], [ 29.808809240506328, -1.449969655172414 ], [ 29.808809240506328, -1.449700172413793 ], [ 29.807731265822785, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19614, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.449700172413793 ], [ 29.808809240506328, -1.449969655172414 ], [ 29.809078734177216, -1.449969655172414 ], [ 29.809078734177216, -1.449700172413793 ], [ 29.808809240506328, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19615, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.449700172413793 ], [ 29.809078734177216, -1.449969655172414 ], [ 29.809348227848101, -1.449969655172414 ], [ 29.809348227848101, -1.449700172413793 ], [ 29.809078734177216, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19616, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.449969655172414 ], [ 29.772966582278482, -1.450239137931035 ], [ 29.773236075949367, -1.450239137931035 ], [ 29.773236075949367, -1.449969655172414 ], [ 29.772966582278482, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19617, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.449969655172414 ], [ 29.773236075949367, -1.450239137931035 ], [ 29.773505569620252, -1.450239137931035 ], [ 29.773505569620252, -1.449969655172414 ], [ 29.773236075949367, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19618, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.449969655172414 ], [ 29.773505569620252, -1.450239137931035 ], [ 29.77377506329114, -1.450239137931035 ], [ 29.77377506329114, -1.449969655172414 ], [ 29.773505569620252, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19619, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.449969655172414 ], [ 29.77377506329114, -1.450239137931035 ], [ 29.774044556962025, -1.450239137931035 ], [ 29.774044556962025, -1.449969655172414 ], [ 29.77377506329114, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19620, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.449969655172414 ], [ 29.774044556962025, -1.450239137931035 ], [ 29.77431405063291, -1.450239137931035 ], [ 29.77431405063291, -1.449969655172414 ], [ 29.774044556962025, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19621, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.449969655172414 ], [ 29.77431405063291, -1.450239137931035 ], [ 29.774853037974683, -1.450239137931035 ], [ 29.774853037974683, -1.449969655172414 ], [ 29.77431405063291, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19622, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.449969655172414 ], [ 29.774853037974683, -1.450239137931035 ], [ 29.775122531645568, -1.450239137931035 ], [ 29.775122531645568, -1.449969655172414 ], [ 29.774853037974683, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19623, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.449969655172414 ], [ 29.775122531645568, -1.450239137931035 ], [ 29.775392025316457, -1.450239137931035 ], [ 29.775392025316457, -1.449969655172414 ], [ 29.775122531645568, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19624, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.449969655172414 ], [ 29.775392025316457, -1.450239137931035 ], [ 29.775661518987341, -1.450239137931035 ], [ 29.775661518987341, -1.449969655172414 ], [ 29.775392025316457, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19625, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.449969655172414 ], [ 29.775661518987341, -1.450239137931035 ], [ 29.775931012658226, -1.450239137931035 ], [ 29.775931012658226, -1.449969655172414 ], [ 29.775661518987341, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19626, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.449969655172414 ], [ 29.775931012658226, -1.450239137931035 ], [ 29.776200506329115, -1.450239137931035 ], [ 29.776200506329115, -1.449969655172414 ], [ 29.775931012658226, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19627, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.449969655172414 ], [ 29.776200506329115, -1.450239137931035 ], [ 29.77647, -1.450239137931035 ], [ 29.77647, -1.449969655172414 ], [ 29.776200506329115, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19628, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.449969655172414 ], [ 29.77647, -1.450239137931035 ], [ 29.776739493670885, -1.450239137931035 ], [ 29.776739493670885, -1.449969655172414 ], [ 29.77647, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19629, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.449969655172414 ], [ 29.776739493670885, -1.450239137931035 ], [ 29.777008987341773, -1.450239137931035 ], [ 29.777008987341773, -1.449969655172414 ], [ 29.776739493670885, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19630, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.449969655172414 ], [ 29.777008987341773, -1.450239137931035 ], [ 29.777278481012658, -1.450239137931035 ], [ 29.777278481012658, -1.449969655172414 ], [ 29.777008987341773, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19631, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.449969655172414 ], [ 29.777278481012658, -1.450239137931035 ], [ 29.777547974683543, -1.450239137931035 ], [ 29.777547974683543, -1.449969655172414 ], [ 29.777278481012658, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19632, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.449969655172414 ], [ 29.777547974683543, -1.450239137931035 ], [ 29.777817468354431, -1.450239137931035 ], [ 29.777817468354431, -1.449969655172414 ], [ 29.777547974683543, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19633, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.449969655172414 ], [ 29.777817468354431, -1.450239137931035 ], [ 29.778086962025316, -1.450239137931035 ], [ 29.778086962025316, -1.449969655172414 ], [ 29.777817468354431, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19634, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.449969655172414 ], [ 29.796682025316457, -1.450239137931035 ], [ 29.796951518987342, -1.450239137931035 ], [ 29.796951518987342, -1.449969655172414 ], [ 29.796682025316457, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19635, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.449969655172414 ], [ 29.796951518987342, -1.450239137931035 ], [ 29.797221012658227, -1.450239137931035 ], [ 29.797221012658227, -1.449969655172414 ], [ 29.796951518987342, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19636, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.449969655172414 ], [ 29.797490506329115, -1.450239137931035 ], [ 29.79776, -1.450239137931035 ], [ 29.79776, -1.449969655172414 ], [ 29.797490506329115, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19637, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.449969655172414 ], [ 29.79776, -1.450239137931035 ], [ 29.798029493670885, -1.450239137931035 ], [ 29.798029493670885, -1.449969655172414 ], [ 29.79776, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19638, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.449969655172414 ], [ 29.798029493670885, -1.450239137931035 ], [ 29.798298987341774, -1.450239137931035 ], [ 29.798298987341774, -1.449969655172414 ], [ 29.798029493670885, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19639, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.449969655172414 ], [ 29.805036329113925, -1.450239137931035 ], [ 29.805575316455698, -1.450239137931035 ], [ 29.805575316455698, -1.449969655172414 ], [ 29.805036329113925, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19640, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.449969655172414 ], [ 29.805575316455698, -1.450239137931035 ], [ 29.805844810126583, -1.450239137931035 ], [ 29.805844810126583, -1.449969655172414 ], [ 29.805575316455698, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19641, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.449969655172414 ], [ 29.805844810126583, -1.450239137931035 ], [ 29.806114303797468, -1.450239137931035 ], [ 29.806114303797468, -1.449969655172414 ], [ 29.805844810126583, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19642, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.449969655172414 ], [ 29.806114303797468, -1.450239137931035 ], [ 29.806383797468357, -1.450239137931035 ], [ 29.806383797468357, -1.449969655172414 ], [ 29.806114303797468, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19643, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.449969655172414 ], [ 29.806383797468357, -1.450239137931035 ], [ 29.806653291139241, -1.450239137931035 ], [ 29.806653291139241, -1.449969655172414 ], [ 29.806383797468357, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19644, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.449969655172414 ], [ 29.806653291139241, -1.450239137931035 ], [ 29.806922784810126, -1.450239137931035 ], [ 29.806922784810126, -1.449969655172414 ], [ 29.806653291139241, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19645, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.449969655172414 ], [ 29.806922784810126, -1.450239137931035 ], [ 29.807192278481015, -1.450239137931035 ], [ 29.807192278481015, -1.449969655172414 ], [ 29.806922784810126, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19646, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.449969655172414 ], [ 29.807192278481015, -1.450239137931035 ], [ 29.8074617721519, -1.450239137931035 ], [ 29.8074617721519, -1.449969655172414 ], [ 29.807192278481015, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19647, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.449969655172414 ], [ 29.8074617721519, -1.450239137931035 ], [ 29.807731265822785, -1.450239137931035 ], [ 29.807731265822785, -1.449969655172414 ], [ 29.8074617721519, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19648, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.449969655172414 ], [ 29.807731265822785, -1.450239137931035 ], [ 29.808809240506328, -1.450239137931035 ], [ 29.808809240506328, -1.449969655172414 ], [ 29.807731265822785, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19649, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.449969655172414 ], [ 29.808809240506328, -1.450239137931035 ], [ 29.809078734177216, -1.450239137931035 ], [ 29.809078734177216, -1.449969655172414 ], [ 29.808809240506328, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19650, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.449969655172414 ], [ 29.809078734177216, -1.450239137931035 ], [ 29.809348227848101, -1.450239137931035 ], [ 29.809348227848101, -1.449969655172414 ], [ 29.809078734177216, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19651, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.446735862068966 ], [ 29.792370126582277, -1.450778103448276 ], [ 29.792909113924051, -1.450778103448276 ], [ 29.792909113924051, -1.446735862068966 ], [ 29.792370126582277, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19652, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.446735862068966 ], [ 29.793448101265824, -1.450778103448276 ], [ 29.793717594936709, -1.450778103448276 ], [ 29.793717594936709, -1.446735862068966 ], [ 29.793448101265824, -1.446735862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19653, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.447005344827586 ], [ 29.780781898734176, -1.452125517241379 ], [ 29.781051392405065, -1.452125517241379 ], [ 29.781051392405065, -1.447005344827586 ], [ 29.780781898734176, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19654, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.447005344827586 ], [ 29.791022658227849, -1.451317068965517 ], [ 29.791292151898734, -1.451317068965517 ], [ 29.791292151898734, -1.447005344827586 ], [ 29.791022658227849, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19655, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.447005344827586 ], [ 29.791292151898734, -1.451317068965517 ], [ 29.791561645569619, -1.451317068965517 ], [ 29.791561645569619, -1.447005344827586 ], [ 29.791292151898734, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19656, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.447005344827586 ], [ 29.791831139240507, -1.451047586206897 ], [ 29.792100632911392, -1.451047586206897 ], [ 29.792100632911392, -1.447005344827586 ], [ 29.791831139240507, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19657, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.447005344827586 ], [ 29.792100632911392, -1.451047586206897 ], [ 29.792370126582277, -1.451047586206897 ], [ 29.792370126582277, -1.447005344827586 ], [ 29.792100632911392, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19658, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.447005344827586 ], [ 29.792909113924051, -1.450778103448276 ], [ 29.793178607594935, -1.450778103448276 ], [ 29.793178607594935, -1.447005344827586 ], [ 29.792909113924051, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19659, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.447005344827586 ], [ 29.793717594936709, -1.450778103448276 ], [ 29.793987088607594, -1.450778103448276 ], [ 29.793987088607594, -1.447005344827586 ], [ 29.793717594936709, -1.447005344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19660, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.447274827586207 ], [ 29.77916493670886, -1.452125517241379 ], [ 29.779434430379748, -1.452125517241379 ], [ 29.779434430379748, -1.447274827586207 ], [ 29.77916493670886, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19661, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.447274827586207 ], [ 29.779434430379748, -1.452125517241379 ], [ 29.779703924050633, -1.452125517241379 ], [ 29.779703924050633, -1.447274827586207 ], [ 29.779434430379748, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19662, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.447274827586207 ], [ 29.779973417721518, -1.452125517241379 ], [ 29.780242911392406, -1.452125517241379 ], [ 29.780242911392406, -1.447274827586207 ], [ 29.779973417721518, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19663, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.447274827586207 ], [ 29.78132088607595, -1.452125517241379 ], [ 29.781590379746834, -1.452125517241379 ], [ 29.781590379746834, -1.447274827586207 ], [ 29.78132088607595, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19664, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.447274827586207 ], [ 29.782129367088608, -1.452125517241379 ], [ 29.782398860759493, -1.452125517241379 ], [ 29.782398860759493, -1.447274827586207 ], [ 29.782129367088608, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19665, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.447274827586207 ], [ 29.790214177215191, -1.451856034482759 ], [ 29.790483670886076, -1.451856034482759 ], [ 29.790483670886076, -1.447274827586207 ], [ 29.790214177215191, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19666, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.447274827586207 ], [ 29.790483670886076, -1.451586551724138 ], [ 29.790753164556961, -1.451586551724138 ], [ 29.790753164556961, -1.447274827586207 ], [ 29.790483670886076, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19667, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.447274827586207 ], [ 29.790753164556961, -1.451586551724138 ], [ 29.791022658227849, -1.451586551724138 ], [ 29.791022658227849, -1.447274827586207 ], [ 29.790753164556961, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19668, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.447274827586207 ], [ 29.791561645569619, -1.451317068965517 ], [ 29.791831139240507, -1.451317068965517 ], [ 29.791831139240507, -1.447274827586207 ], [ 29.791561645569619, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19669, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.447274827586207 ], [ 29.793178607594935, -1.451047586206897 ], [ 29.793448101265824, -1.451047586206897 ], [ 29.793448101265824, -1.447274827586207 ], [ 29.793178607594935, -1.447274827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19670, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.447544310344828 ], [ 29.778356455696201, -1.451856034482759 ], [ 29.77862594936709, -1.451856034482759 ], [ 29.77862594936709, -1.447544310344828 ], [ 29.778356455696201, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19671, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.447544310344828 ], [ 29.77862594936709, -1.452125517241379 ], [ 29.778895443037975, -1.452125517241379 ], [ 29.778895443037975, -1.447544310344828 ], [ 29.77862594936709, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19672, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.447544310344828 ], [ 29.779703924050633, -1.451856034482759 ], [ 29.779973417721518, -1.451856034482759 ], [ 29.779973417721518, -1.447544310344828 ], [ 29.779703924050633, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19673, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.447544310344828 ], [ 29.780512405063291, -1.451856034482759 ], [ 29.780781898734176, -1.451856034482759 ], [ 29.780781898734176, -1.447544310344828 ], [ 29.780512405063291, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19674, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.447544310344828 ], [ 29.781051392405065, -1.451856034482759 ], [ 29.78132088607595, -1.451856034482759 ], [ 29.78132088607595, -1.447544310344828 ], [ 29.781051392405065, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19675, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.447544310344828 ], [ 29.781859873417723, -1.451856034482759 ], [ 29.782129367088608, -1.451856034482759 ], [ 29.782129367088608, -1.447544310344828 ], [ 29.781859873417723, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19676, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.447544310344828 ], [ 29.782668354430381, -1.451856034482759 ], [ 29.782937848101266, -1.451856034482759 ], [ 29.782937848101266, -1.447544310344828 ], [ 29.782668354430381, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19677, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.447544310344828 ], [ 29.783207341772151, -1.451856034482759 ], [ 29.783476835443039, -1.451856034482759 ], [ 29.783476835443039, -1.447544310344828 ], [ 29.783207341772151, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19678, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.447544310344828 ], [ 29.783476835443039, -1.451856034482759 ], [ 29.784015822784809, -1.451856034482759 ], [ 29.784015822784809, -1.447544310344828 ], [ 29.783476835443039, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19679, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.447544310344828 ], [ 29.784285316455698, -1.451856034482759 ], [ 29.784554810126583, -1.451856034482759 ], [ 29.784554810126583, -1.447544310344828 ], [ 29.784285316455698, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19680, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.447544310344828 ], [ 29.785093797468356, -1.451856034482759 ], [ 29.785363291139241, -1.451856034482759 ], [ 29.785363291139241, -1.447544310344828 ], [ 29.785093797468356, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19681, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.447544310344828 ], [ 29.793987088607594, -1.450508620689655 ], [ 29.794256582278482, -1.450508620689655 ], [ 29.794256582278482, -1.447544310344828 ], [ 29.793987088607594, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19682, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.447813793103448 ], [ 29.778895443037975, -1.451586551724138 ], [ 29.77916493670886, -1.451586551724138 ], [ 29.77916493670886, -1.447813793103448 ], [ 29.778895443037975, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19683, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.447813793103448 ], [ 29.780242911392406, -1.451586551724138 ], [ 29.780512405063291, -1.451586551724138 ], [ 29.780512405063291, -1.447813793103448 ], [ 29.780242911392406, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19684, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.447813793103448 ], [ 29.781590379746834, -1.451586551724138 ], [ 29.781859873417723, -1.451586551724138 ], [ 29.781859873417723, -1.447813793103448 ], [ 29.781590379746834, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19685, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.447813793103448 ], [ 29.782398860759493, -1.451586551724138 ], [ 29.782668354430381, -1.451586551724138 ], [ 29.782668354430381, -1.447813793103448 ], [ 29.782398860759493, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19686, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.447813793103448 ], [ 29.782937848101266, -1.451586551724138 ], [ 29.783207341772151, -1.451586551724138 ], [ 29.783207341772151, -1.447813793103448 ], [ 29.782937848101266, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19687, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.447813793103448 ], [ 29.784015822784809, -1.451586551724138 ], [ 29.784285316455698, -1.451586551724138 ], [ 29.784285316455698, -1.447813793103448 ], [ 29.784015822784809, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19688, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.447813793103448 ], [ 29.784824303797468, -1.451586551724138 ], [ 29.785093797468356, -1.451586551724138 ], [ 29.785093797468356, -1.447813793103448 ], [ 29.784824303797468, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19689, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.447813793103448 ], [ 29.785363291139241, -1.451586551724138 ], [ 29.785632784810126, -1.451586551724138 ], [ 29.785632784810126, -1.447813793103448 ], [ 29.785363291139241, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19690, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.447813793103448 ], [ 29.785632784810126, -1.451586551724138 ], [ 29.785902278481014, -1.451586551724138 ], [ 29.785902278481014, -1.447813793103448 ], [ 29.785632784810126, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19691, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.447813793103448 ], [ 29.786171772151899, -1.451586551724138 ], [ 29.786441265822784, -1.451586551724138 ], [ 29.786441265822784, -1.447813793103448 ], [ 29.786171772151899, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19692, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.447813793103448 ], [ 29.786441265822784, -1.451586551724138 ], [ 29.786710759493673, -1.451586551724138 ], [ 29.786710759493673, -1.447813793103448 ], [ 29.786441265822784, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19693, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.448083275862069 ], [ 29.784554810126583, -1.451317068965517 ], [ 29.784824303797468, -1.451317068965517 ], [ 29.784824303797468, -1.448083275862069 ], [ 29.784554810126583, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19694, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.448083275862069 ], [ 29.785902278481014, -1.451317068965517 ], [ 29.786171772151899, -1.451317068965517 ], [ 29.786171772151899, -1.448083275862069 ], [ 29.785902278481014, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19695, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.448083275862069 ], [ 29.786710759493673, -1.451317068965517 ], [ 29.786980253164558, -1.451317068965517 ], [ 29.786980253164558, -1.448083275862069 ], [ 29.786710759493673, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19696, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.448083275862069 ], [ 29.786980253164558, -1.451317068965517 ], [ 29.787249746835442, -1.451317068965517 ], [ 29.787249746835442, -1.448083275862069 ], [ 29.786980253164558, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19697, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.448083275862069 ], [ 29.787249746835442, -1.451317068965517 ], [ 29.787519240506327, -1.451317068965517 ], [ 29.787519240506327, -1.448083275862069 ], [ 29.787249746835442, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19698, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.44862224137931 ], [ 29.794256582278482, -1.450778103448276 ], [ 29.794526075949367, -1.450778103448276 ], [ 29.794526075949367, -1.44862224137931 ], [ 29.794256582278482, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19699, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.44862224137931 ], [ 29.803688860759493, -1.450778103448276 ], [ 29.803958354430382, -1.450778103448276 ], [ 29.803958354430382, -1.44862224137931 ], [ 29.803688860759493, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19700, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.44862224137931 ], [ 29.803958354430382, -1.450778103448276 ], [ 29.804227848101267, -1.450778103448276 ], [ 29.804227848101267, -1.44862224137931 ], [ 29.803958354430382, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19701, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.44862224137931 ], [ 29.804497341772151, -1.450508620689655 ], [ 29.80476683544304, -1.450508620689655 ], [ 29.80476683544304, -1.44862224137931 ], [ 29.804497341772151, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19702, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.448891724137931 ], [ 29.770002151898733, -1.450778103448276 ], [ 29.770271645569618, -1.450778103448276 ], [ 29.770271645569618, -1.448891724137931 ], [ 29.770002151898733, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19703, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.448891724137931 ], [ 29.770810632911392, -1.450778103448276 ], [ 29.771080126582277, -1.450778103448276 ], [ 29.771080126582277, -1.448891724137931 ], [ 29.770810632911392, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19704, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.448891724137931 ], [ 29.771349620253165, -1.450508620689655 ], [ 29.77161911392405, -1.450508620689655 ], [ 29.77161911392405, -1.448891724137931 ], [ 29.771349620253165, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19705, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.448891724137931 ], [ 29.77161911392405, -1.450778103448276 ], [ 29.771888607594935, -1.450778103448276 ], [ 29.771888607594935, -1.448891724137931 ], [ 29.77161911392405, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19706, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.448891724137931 ], [ 29.772158101265823, -1.450508620689655 ], [ 29.772427594936708, -1.450508620689655 ], [ 29.772427594936708, -1.448891724137931 ], [ 29.772158101265823, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19707, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.448891724137931 ], [ 29.802880379746835, -1.451586551724138 ], [ 29.803419367088608, -1.451586551724138 ], [ 29.803419367088608, -1.451317068965517 ], [ 29.803149873417723, -1.451317068965517 ], [ 29.803149873417723, -1.448891724137931 ], [ 29.802880379746835, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19708, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.448891724137931 ], [ 29.803149873417723, -1.451317068965517 ], [ 29.803419367088608, -1.451317068965517 ], [ 29.803419367088608, -1.448891724137931 ], [ 29.803149873417723, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19709, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.448891724137931 ], [ 29.803419367088608, -1.451047586206897 ], [ 29.803688860759493, -1.451047586206897 ], [ 29.803688860759493, -1.448891724137931 ], [ 29.803419367088608, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19710, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.448891724137931 ], [ 29.804227848101267, -1.450508620689655 ], [ 29.804497341772151, -1.450508620689655 ], [ 29.804497341772151, -1.448891724137931 ], [ 29.804227848101267, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19711, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.449161206896552 ], [ 29.768654683544302, -1.451317068965517 ], [ 29.76892417721519, -1.451317068965517 ], [ 29.76892417721519, -1.449161206896552 ], [ 29.768654683544302, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19712, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.449161206896552 ], [ 29.76892417721519, -1.451317068965517 ], [ 29.769193670886075, -1.451317068965517 ], [ 29.769193670886075, -1.449161206896552 ], [ 29.76892417721519, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19713, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.449161206896552 ], [ 29.76946316455696, -1.451047586206897 ], [ 29.769732658227849, -1.451047586206897 ], [ 29.769732658227849, -1.449161206896552 ], [ 29.76946316455696, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19714, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.449161206896552 ], [ 29.769732658227849, -1.451047586206897 ], [ 29.770002151898733, -1.451047586206897 ], [ 29.770002151898733, -1.449161206896552 ], [ 29.769732658227849, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19715, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.449161206896552 ], [ 29.770271645569618, -1.450778103448276 ], [ 29.770541139240507, -1.450778103448276 ], [ 29.770541139240507, -1.449161206896552 ], [ 29.770271645569618, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19716, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.449161206896552 ], [ 29.771080126582277, -1.450778103448276 ], [ 29.771349620253165, -1.450778103448276 ], [ 29.771349620253165, -1.449161206896552 ], [ 29.771080126582277, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19717, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.449161206896552 ], [ 29.771888607594935, -1.450508620689655 ], [ 29.772158101265823, -1.450508620689655 ], [ 29.772158101265823, -1.449161206896552 ], [ 29.771888607594935, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19718, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.449430689655173 ], [ 29.767846202531643, -1.451856034482759 ], [ 29.768115696202532, -1.451856034482759 ], [ 29.768115696202532, -1.449430689655173 ], [ 29.767846202531643, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19719, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.449430689655173 ], [ 29.768115696202532, -1.451586551724138 ], [ 29.768385189873417, -1.451586551724138 ], [ 29.768385189873417, -1.449430689655173 ], [ 29.768115696202532, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19720, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.449430689655173 ], [ 29.768385189873417, -1.451586551724138 ], [ 29.768654683544302, -1.451586551724138 ], [ 29.768654683544302, -1.449430689655173 ], [ 29.768385189873417, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19721, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.449430689655173 ], [ 29.769193670886075, -1.451317068965517 ], [ 29.76946316455696, -1.451317068965517 ], [ 29.76946316455696, -1.449430689655173 ], [ 29.769193670886075, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19722, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.449430689655173 ], [ 29.770541139240507, -1.451047586206897 ], [ 29.770810632911392, -1.451047586206897 ], [ 29.770810632911392, -1.449430689655173 ], [ 29.770541139240507, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.449430689655173 ], [ 29.798837974683543, -1.450508620689655 ], [ 29.799107468354432, -1.450508620689655 ], [ 29.799107468354432, -1.449430689655173 ], [ 29.798837974683543, -1.449430689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19724, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.449700172413793 ], [ 29.794526075949367, -1.450778103448276 ], [ 29.794795569620252, -1.450778103448276 ], [ 29.794795569620252, -1.449700172413793 ], [ 29.794526075949367, -1.449700172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19725, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.449969655172414 ], [ 29.794795569620252, -1.451856034482759 ], [ 29.79506506329114, -1.451856034482759 ], [ 29.79506506329114, -1.449969655172414 ], [ 29.794795569620252, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19726, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.449969655172414 ], [ 29.79506506329114, -1.452395 ], [ 29.795334556962025, -1.452395 ], [ 29.795334556962025, -1.449969655172414 ], [ 29.79506506329114, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19727, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.449969655172414 ], [ 29.79560405063291, -1.452395 ], [ 29.795873544303799, -1.452395 ], [ 29.795873544303799, -1.449969655172414 ], [ 29.79560405063291, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19728, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.449969655172414 ], [ 29.795873544303799, -1.450508620689655 ], [ 29.796143037974684, -1.450508620689655 ], [ 29.796143037974684, -1.449969655172414 ], [ 29.795873544303799, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19729, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.449969655172414 ], [ 29.796412531645569, -1.452395 ], [ 29.796682025316457, -1.452395 ], [ 29.796682025316457, -1.449969655172414 ], [ 29.796412531645569, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19730, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.449969655172414 ], [ 29.797221012658227, -1.450508620689655 ], [ 29.797490506329115, -1.450508620689655 ], [ 29.797490506329115, -1.449969655172414 ], [ 29.797221012658227, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19731, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.449969655172414 ], [ 29.809348227848101, -1.450508620689655 ], [ 29.809617721518986, -1.450508620689655 ], [ 29.809617721518986, -1.449969655172414 ], [ 29.809348227848101, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19732, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.449969655172414 ], [ 29.811773670886076, -1.451047586206897 ], [ 29.812043164556961, -1.451047586206897 ], [ 29.812043164556961, -1.450508620689655 ], [ 29.812582151898734, -1.450508620689655 ], [ 29.812582151898734, -1.450239137931035 ], [ 29.812043164556961, -1.450239137931035 ], [ 29.812043164556961, -1.449969655172414 ], [ 29.811773670886076, -1.449969655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19733, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.450239137931035 ], [ 29.772427594936708, -1.450508620689655 ], [ 29.772697088607593, -1.450508620689655 ], [ 29.772697088607593, -1.450239137931035 ], [ 29.772427594936708, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19734, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.450239137931035 ], [ 29.772697088607593, -1.450508620689655 ], [ 29.772966582278482, -1.450508620689655 ], [ 29.772966582278482, -1.450239137931035 ], [ 29.772697088607593, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19735, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.450239137931035 ], [ 29.772966582278482, -1.450508620689655 ], [ 29.773236075949367, -1.450508620689655 ], [ 29.773236075949367, -1.450239137931035 ], [ 29.772966582278482, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19736, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.450239137931035 ], [ 29.773236075949367, -1.450508620689655 ], [ 29.773505569620252, -1.450508620689655 ], [ 29.773505569620252, -1.450239137931035 ], [ 29.773236075949367, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19737, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.450239137931035 ], [ 29.773505569620252, -1.450508620689655 ], [ 29.77377506329114, -1.450508620689655 ], [ 29.77377506329114, -1.450239137931035 ], [ 29.773505569620252, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19738, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.450239137931035 ], [ 29.77377506329114, -1.450508620689655 ], [ 29.774044556962025, -1.450508620689655 ], [ 29.774044556962025, -1.450239137931035 ], [ 29.77377506329114, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19739, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.450239137931035 ], [ 29.774044556962025, -1.450508620689655 ], [ 29.77431405063291, -1.450508620689655 ], [ 29.77431405063291, -1.450239137931035 ], [ 29.774044556962025, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19740, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.450239137931035 ], [ 29.77431405063291, -1.450508620689655 ], [ 29.774853037974683, -1.450508620689655 ], [ 29.774853037974683, -1.450239137931035 ], [ 29.77431405063291, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19741, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.450239137931035 ], [ 29.774853037974683, -1.450508620689655 ], [ 29.775122531645568, -1.450508620689655 ], [ 29.775122531645568, -1.450239137931035 ], [ 29.774853037974683, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19742, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.450239137931035 ], [ 29.775122531645568, -1.450508620689655 ], [ 29.775392025316457, -1.450508620689655 ], [ 29.775392025316457, -1.450239137931035 ], [ 29.775122531645568, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19743, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.450239137931035 ], [ 29.775392025316457, -1.450508620689655 ], [ 29.775661518987341, -1.450508620689655 ], [ 29.775661518987341, -1.450239137931035 ], [ 29.775392025316457, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19744, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.450239137931035 ], [ 29.775661518987341, -1.450508620689655 ], [ 29.775931012658226, -1.450508620689655 ], [ 29.775931012658226, -1.450239137931035 ], [ 29.775661518987341, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19745, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.450239137931035 ], [ 29.775931012658226, -1.450508620689655 ], [ 29.776200506329115, -1.450508620689655 ], [ 29.776200506329115, -1.450239137931035 ], [ 29.775931012658226, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19746, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.450239137931035 ], [ 29.776200506329115, -1.450508620689655 ], [ 29.77647, -1.450508620689655 ], [ 29.77647, -1.450239137931035 ], [ 29.776200506329115, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19747, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.450239137931035 ], [ 29.77647, -1.450508620689655 ], [ 29.776739493670885, -1.450508620689655 ], [ 29.776739493670885, -1.450239137931035 ], [ 29.77647, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19748, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.450239137931035 ], [ 29.776739493670885, -1.450508620689655 ], [ 29.777008987341773, -1.450508620689655 ], [ 29.777008987341773, -1.450239137931035 ], [ 29.776739493670885, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19749, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.450239137931035 ], [ 29.777008987341773, -1.450508620689655 ], [ 29.777278481012658, -1.450508620689655 ], [ 29.777278481012658, -1.450239137931035 ], [ 29.777008987341773, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19750, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.450239137931035 ], [ 29.777278481012658, -1.450508620689655 ], [ 29.777547974683543, -1.450508620689655 ], [ 29.777547974683543, -1.450239137931035 ], [ 29.777278481012658, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19751, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.450239137931035 ], [ 29.777547974683543, -1.450508620689655 ], [ 29.777817468354431, -1.450508620689655 ], [ 29.777817468354431, -1.450239137931035 ], [ 29.777547974683543, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19752, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.447544310344828 ], [ 29.778356455696201, -1.447544310344828 ], [ 29.778356455696201, -1.451317068965517 ], [ 29.778086962025316, -1.451317068965517 ], [ 29.778086962025316, -1.450508620689655 ], [ 29.777817468354431, -1.450508620689655 ], [ 29.777817468354431, -1.450239137931035 ], [ 29.778086962025316, -1.450239137931035 ], [ 29.778086962025316, -1.447544310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19753, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.450239137931035 ], [ 29.795334556962025, -1.452125517241379 ], [ 29.79560405063291, -1.452125517241379 ], [ 29.79560405063291, -1.450239137931035 ], [ 29.795334556962025, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19754, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.450239137931035 ], [ 29.796143037974684, -1.452125517241379 ], [ 29.796412531645569, -1.452125517241379 ], [ 29.796412531645569, -1.450239137931035 ], [ 29.796143037974684, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19755, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.450239137931035 ], [ 29.796682025316457, -1.452125517241379 ], [ 29.796951518987342, -1.452125517241379 ], [ 29.796951518987342, -1.450239137931035 ], [ 29.796682025316457, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19756, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.450239137931035 ], [ 29.796951518987342, -1.452125517241379 ], [ 29.797221012658227, -1.452125517241379 ], [ 29.797221012658227, -1.450239137931035 ], [ 29.796951518987342, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19757, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.450239137931035 ], [ 29.797490506329115, -1.452125517241379 ], [ 29.79776, -1.452125517241379 ], [ 29.79776, -1.450239137931035 ], [ 29.797490506329115, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19758, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.450239137931035 ], [ 29.79776, -1.452125517241379 ], [ 29.798029493670885, -1.452125517241379 ], [ 29.798029493670885, -1.450239137931035 ], [ 29.79776, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19759, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.450239137931035 ], [ 29.798029493670885, -1.450508620689655 ], [ 29.798298987341774, -1.450508620689655 ], [ 29.798298987341774, -1.450239137931035 ], [ 29.798029493670885, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19760, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.450239137931035 ], [ 29.798298987341774, -1.450508620689655 ], [ 29.798568481012659, -1.450508620689655 ], [ 29.798568481012659, -1.450239137931035 ], [ 29.798298987341774, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19761, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.450239137931035 ], [ 29.798568481012659, -1.450508620689655 ], [ 29.798837974683543, -1.450508620689655 ], [ 29.798837974683543, -1.450239137931035 ], [ 29.798568481012659, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19762, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.450239137931035 ], [ 29.80476683544304, -1.450508620689655 ], [ 29.805036329113925, -1.450508620689655 ], [ 29.805036329113925, -1.450239137931035 ], [ 29.80476683544304, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19763, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.450239137931035 ], [ 29.805036329113925, -1.450508620689655 ], [ 29.80530582278481, -1.450508620689655 ], [ 29.80530582278481, -1.450239137931035 ], [ 29.805036329113925, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19764, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.450239137931035 ], [ 29.80530582278481, -1.450508620689655 ], [ 29.805844810126583, -1.450508620689655 ], [ 29.805844810126583, -1.450239137931035 ], [ 29.80530582278481, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19765, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.450239137931035 ], [ 29.805844810126583, -1.450508620689655 ], [ 29.806114303797468, -1.450508620689655 ], [ 29.806114303797468, -1.450239137931035 ], [ 29.805844810126583, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19766, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.450239137931035 ], [ 29.806114303797468, -1.450508620689655 ], [ 29.806383797468357, -1.450508620689655 ], [ 29.806383797468357, -1.450239137931035 ], [ 29.806114303797468, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19767, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.450239137931035 ], [ 29.806383797468357, -1.450508620689655 ], [ 29.806653291139241, -1.450508620689655 ], [ 29.806653291139241, -1.450239137931035 ], [ 29.806383797468357, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19768, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.450239137931035 ], [ 29.806653291139241, -1.450508620689655 ], [ 29.806922784810126, -1.450508620689655 ], [ 29.806922784810126, -1.450239137931035 ], [ 29.806653291139241, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19769, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.450239137931035 ], [ 29.806922784810126, -1.450508620689655 ], [ 29.807192278481015, -1.450508620689655 ], [ 29.807192278481015, -1.450239137931035 ], [ 29.806922784810126, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19770, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.450239137931035 ], [ 29.807192278481015, -1.450508620689655 ], [ 29.8074617721519, -1.450508620689655 ], [ 29.8074617721519, -1.450239137931035 ], [ 29.807192278481015, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19771, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.450239137931035 ], [ 29.8074617721519, -1.450508620689655 ], [ 29.808539746835443, -1.450508620689655 ], [ 29.808539746835443, -1.450239137931035 ], [ 29.8074617721519, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19772, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.450239137931035 ], [ 29.808539746835443, -1.450508620689655 ], [ 29.808809240506328, -1.450508620689655 ], [ 29.808809240506328, -1.450239137931035 ], [ 29.808539746835443, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19773, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.450239137931035 ], [ 29.808809240506328, -1.450508620689655 ], [ 29.809078734177216, -1.450508620689655 ], [ 29.809078734177216, -1.450239137931035 ], [ 29.808809240506328, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19774, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.450239137931035 ], [ 29.809078734177216, -1.450508620689655 ], [ 29.809348227848101, -1.450508620689655 ], [ 29.809348227848101, -1.450239137931035 ], [ 29.809078734177216, -1.450239137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19775, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.450508620689655 ], [ 29.771349620253165, -1.450778103448276 ], [ 29.77161911392405, -1.450778103448276 ], [ 29.77161911392405, -1.450508620689655 ], [ 29.771349620253165, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19776, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.450508620689655 ], [ 29.771888607594935, -1.450778103448276 ], [ 29.772158101265823, -1.450778103448276 ], [ 29.772158101265823, -1.450508620689655 ], [ 29.771888607594935, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19777, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.450508620689655 ], [ 29.772158101265823, -1.450778103448276 ], [ 29.772427594936708, -1.450778103448276 ], [ 29.772427594936708, -1.450508620689655 ], [ 29.772158101265823, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19778, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.450508620689655 ], [ 29.772427594936708, -1.450778103448276 ], [ 29.772697088607593, -1.450778103448276 ], [ 29.772697088607593, -1.450508620689655 ], [ 29.772427594936708, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19779, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.450508620689655 ], [ 29.772697088607593, -1.450778103448276 ], [ 29.772966582278482, -1.450778103448276 ], [ 29.772966582278482, -1.450508620689655 ], [ 29.772697088607593, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19780, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.450508620689655 ], [ 29.772966582278482, -1.450778103448276 ], [ 29.773236075949367, -1.450778103448276 ], [ 29.773236075949367, -1.450508620689655 ], [ 29.772966582278482, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19781, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.450508620689655 ], [ 29.773236075949367, -1.450778103448276 ], [ 29.773505569620252, -1.450778103448276 ], [ 29.773505569620252, -1.450508620689655 ], [ 29.773236075949367, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19782, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.450508620689655 ], [ 29.773505569620252, -1.450778103448276 ], [ 29.77377506329114, -1.450778103448276 ], [ 29.77377506329114, -1.450508620689655 ], [ 29.773505569620252, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19783, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.450508620689655 ], [ 29.77377506329114, -1.450778103448276 ], [ 29.774044556962025, -1.450778103448276 ], [ 29.774044556962025, -1.450508620689655 ], [ 29.77377506329114, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19784, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.450508620689655 ], [ 29.774044556962025, -1.450778103448276 ], [ 29.77431405063291, -1.450778103448276 ], [ 29.77431405063291, -1.450508620689655 ], [ 29.774044556962025, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19785, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.450508620689655 ], [ 29.77431405063291, -1.450778103448276 ], [ 29.774853037974683, -1.450778103448276 ], [ 29.774853037974683, -1.450508620689655 ], [ 29.77431405063291, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19786, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.450508620689655 ], [ 29.774853037974683, -1.450778103448276 ], [ 29.775122531645568, -1.450778103448276 ], [ 29.775122531645568, -1.450508620689655 ], [ 29.774853037974683, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19787, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.450508620689655 ], [ 29.775122531645568, -1.450778103448276 ], [ 29.775392025316457, -1.450778103448276 ], [ 29.775392025316457, -1.450508620689655 ], [ 29.775122531645568, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19788, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.450508620689655 ], [ 29.775392025316457, -1.450778103448276 ], [ 29.775661518987341, -1.450778103448276 ], [ 29.775661518987341, -1.450508620689655 ], [ 29.775392025316457, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19789, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.450508620689655 ], [ 29.775661518987341, -1.450778103448276 ], [ 29.775931012658226, -1.450778103448276 ], [ 29.775931012658226, -1.450508620689655 ], [ 29.775661518987341, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19790, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.450508620689655 ], [ 29.775931012658226, -1.450778103448276 ], [ 29.776200506329115, -1.450778103448276 ], [ 29.776200506329115, -1.450508620689655 ], [ 29.775931012658226, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19791, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.450508620689655 ], [ 29.776200506329115, -1.450778103448276 ], [ 29.77647, -1.450778103448276 ], [ 29.77647, -1.450508620689655 ], [ 29.776200506329115, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19792, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.450508620689655 ], [ 29.77647, -1.450778103448276 ], [ 29.776739493670885, -1.450778103448276 ], [ 29.776739493670885, -1.450508620689655 ], [ 29.77647, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19793, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.450508620689655 ], [ 29.776739493670885, -1.450778103448276 ], [ 29.777008987341773, -1.450778103448276 ], [ 29.777008987341773, -1.450508620689655 ], [ 29.776739493670885, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19794, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.450508620689655 ], [ 29.777008987341773, -1.450778103448276 ], [ 29.777278481012658, -1.450778103448276 ], [ 29.777278481012658, -1.450508620689655 ], [ 29.777008987341773, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19795, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.450508620689655 ], [ 29.777278481012658, -1.450778103448276 ], [ 29.777547974683543, -1.450778103448276 ], [ 29.777547974683543, -1.450508620689655 ], [ 29.777278481012658, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19796, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.450508620689655 ], [ 29.777547974683543, -1.450778103448276 ], [ 29.777817468354431, -1.450778103448276 ], [ 29.777817468354431, -1.450508620689655 ], [ 29.777547974683543, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19797, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.450508620689655 ], [ 29.777817468354431, -1.450778103448276 ], [ 29.778086962025316, -1.450778103448276 ], [ 29.778086962025316, -1.450508620689655 ], [ 29.777817468354431, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19798, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.450508620689655 ], [ 29.793987088607594, -1.450778103448276 ], [ 29.794256582278482, -1.450778103448276 ], [ 29.794256582278482, -1.450508620689655 ], [ 29.793987088607594, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19799, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.450508620689655 ], [ 29.795873544303799, -1.451856034482759 ], [ 29.796143037974684, -1.451856034482759 ], [ 29.796143037974684, -1.450508620689655 ], [ 29.795873544303799, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19800, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.450508620689655 ], [ 29.797221012658227, -1.451856034482759 ], [ 29.797490506329115, -1.451856034482759 ], [ 29.797490506329115, -1.450508620689655 ], [ 29.797221012658227, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19801, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.450508620689655 ], [ 29.798029493670885, -1.451856034482759 ], [ 29.798298987341774, -1.451856034482759 ], [ 29.798298987341774, -1.450508620689655 ], [ 29.798029493670885, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19802, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.450508620689655 ], [ 29.798298987341774, -1.451856034482759 ], [ 29.798568481012659, -1.451856034482759 ], [ 29.798568481012659, -1.450508620689655 ], [ 29.798298987341774, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19803, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.450508620689655 ], [ 29.798568481012659, -1.451856034482759 ], [ 29.798837974683543, -1.451856034482759 ], [ 29.798837974683543, -1.450508620689655 ], [ 29.798568481012659, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19804, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.450508620689655 ], [ 29.804227848101267, -1.450778103448276 ], [ 29.804497341772151, -1.450778103448276 ], [ 29.804497341772151, -1.450508620689655 ], [ 29.804227848101267, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19805, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.450508620689655 ], [ 29.804497341772151, -1.450778103448276 ], [ 29.80476683544304, -1.450778103448276 ], [ 29.80476683544304, -1.450508620689655 ], [ 29.804497341772151, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19806, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.450508620689655 ], [ 29.80476683544304, -1.450778103448276 ], [ 29.805036329113925, -1.450778103448276 ], [ 29.805036329113925, -1.450508620689655 ], [ 29.80476683544304, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19807, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.450508620689655 ], [ 29.805036329113925, -1.450778103448276 ], [ 29.80530582278481, -1.450778103448276 ], [ 29.80530582278481, -1.450508620689655 ], [ 29.805036329113925, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19808, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.450508620689655 ], [ 29.80530582278481, -1.450778103448276 ], [ 29.805575316455698, -1.450778103448276 ], [ 29.805575316455698, -1.450508620689655 ], [ 29.80530582278481, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19809, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.450508620689655 ], [ 29.805575316455698, -1.450778103448276 ], [ 29.805844810126583, -1.450778103448276 ], [ 29.805844810126583, -1.450508620689655 ], [ 29.805575316455698, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19810, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.450508620689655 ], [ 29.805844810126583, -1.450778103448276 ], [ 29.806114303797468, -1.450778103448276 ], [ 29.806114303797468, -1.450508620689655 ], [ 29.805844810126583, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19811, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.450508620689655 ], [ 29.806114303797468, -1.451047586206897 ], [ 29.806383797468357, -1.451047586206897 ], [ 29.806383797468357, -1.451317068965517 ], [ 29.806653291139241, -1.451317068965517 ], [ 29.806653291139241, -1.451586551724138 ], [ 29.806922784810126, -1.451586551724138 ], [ 29.806922784810126, -1.451047586206897 ], [ 29.806653291139241, -1.451047586206897 ], [ 29.806653291139241, -1.450778103448276 ], [ 29.806383797468357, -1.450778103448276 ], [ 29.806383797468357, -1.450508620689655 ], [ 29.806114303797468, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19812, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.450508620689655 ], [ 29.806383797468357, -1.450778103448276 ], [ 29.806653291139241, -1.450778103448276 ], [ 29.806653291139241, -1.450508620689655 ], [ 29.806383797468357, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19813, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.450508620689655 ], [ 29.806653291139241, -1.450778103448276 ], [ 29.806922784810126, -1.450778103448276 ], [ 29.806922784810126, -1.450508620689655 ], [ 29.806653291139241, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19814, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.450508620689655 ], [ 29.806922784810126, -1.450778103448276 ], [ 29.807192278481015, -1.450778103448276 ], [ 29.807192278481015, -1.450508620689655 ], [ 29.806922784810126, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19815, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.450508620689655 ], [ 29.807192278481015, -1.450778103448276 ], [ 29.8074617721519, -1.450778103448276 ], [ 29.8074617721519, -1.450508620689655 ], [ 29.807192278481015, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19816, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.450508620689655 ], [ 29.8074617721519, -1.450778103448276 ], [ 29.808539746835443, -1.450778103448276 ], [ 29.808539746835443, -1.450508620689655 ], [ 29.8074617721519, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19817, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.450508620689655 ], [ 29.808539746835443, -1.450778103448276 ], [ 29.808809240506328, -1.450778103448276 ], [ 29.808809240506328, -1.450508620689655 ], [ 29.808539746835443, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19818, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.450508620689655 ], [ 29.808809240506328, -1.450778103448276 ], [ 29.809078734177216, -1.450778103448276 ], [ 29.809078734177216, -1.450508620689655 ], [ 29.808809240506328, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19819, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.450508620689655 ], [ 29.809078734177216, -1.450778103448276 ], [ 29.809348227848101, -1.450778103448276 ], [ 29.809348227848101, -1.450508620689655 ], [ 29.809078734177216, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19820, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.450508620689655 ], [ 29.809348227848101, -1.450778103448276 ], [ 29.809617721518986, -1.450778103448276 ], [ 29.809617721518986, -1.450508620689655 ], [ 29.809348227848101, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19821, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.450508620689655 ], [ 29.809617721518986, -1.451047586206897 ], [ 29.809887215189875, -1.451047586206897 ], [ 29.809887215189875, -1.450508620689655 ], [ 29.809617721518986, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19822, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.450508620689655 ], [ 29.812312658227849, -1.450778103448276 ], [ 29.812582151898734, -1.450778103448276 ], [ 29.812582151898734, -1.450508620689655 ], [ 29.812312658227849, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19823, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.450778103448276 ], [ 29.770002151898733, -1.451047586206897 ], [ 29.770271645569618, -1.451047586206897 ], [ 29.770271645569618, -1.450778103448276 ], [ 29.770002151898733, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19824, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.450778103448276 ], [ 29.770271645569618, -1.451047586206897 ], [ 29.770541139240507, -1.451047586206897 ], [ 29.770541139240507, -1.450778103448276 ], [ 29.770271645569618, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19825, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.450778103448276 ], [ 29.770810632911392, -1.451047586206897 ], [ 29.771080126582277, -1.451047586206897 ], [ 29.771080126582277, -1.450778103448276 ], [ 29.770810632911392, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19826, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.450778103448276 ], [ 29.771080126582277, -1.451047586206897 ], [ 29.771349620253165, -1.451047586206897 ], [ 29.771349620253165, -1.450778103448276 ], [ 29.771080126582277, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19827, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.450778103448276 ], [ 29.771349620253165, -1.451047586206897 ], [ 29.77161911392405, -1.451047586206897 ], [ 29.77161911392405, -1.450778103448276 ], [ 29.771349620253165, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19828, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.450778103448276 ], [ 29.77161911392405, -1.451047586206897 ], [ 29.771888607594935, -1.451047586206897 ], [ 29.771888607594935, -1.450778103448276 ], [ 29.77161911392405, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19829, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.450778103448276 ], [ 29.771888607594935, -1.451047586206897 ], [ 29.772158101265823, -1.451047586206897 ], [ 29.772158101265823, -1.450778103448276 ], [ 29.771888607594935, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19830, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.450778103448276 ], [ 29.772158101265823, -1.451047586206897 ], [ 29.772427594936708, -1.451047586206897 ], [ 29.772427594936708, -1.450778103448276 ], [ 29.772158101265823, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19831, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.450778103448276 ], [ 29.772427594936708, -1.451047586206897 ], [ 29.772697088607593, -1.451047586206897 ], [ 29.772697088607593, -1.450778103448276 ], [ 29.772427594936708, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19832, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.450778103448276 ], [ 29.772697088607593, -1.451047586206897 ], [ 29.772966582278482, -1.451047586206897 ], [ 29.772966582278482, -1.450778103448276 ], [ 29.772697088607593, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19833, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.450778103448276 ], [ 29.772966582278482, -1.451047586206897 ], [ 29.773236075949367, -1.451047586206897 ], [ 29.773236075949367, -1.450778103448276 ], [ 29.772966582278482, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19834, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.450778103448276 ], [ 29.773236075949367, -1.451047586206897 ], [ 29.773505569620252, -1.451047586206897 ], [ 29.773505569620252, -1.450778103448276 ], [ 29.773236075949367, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19835, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.450778103448276 ], [ 29.773505569620252, -1.451047586206897 ], [ 29.77377506329114, -1.451047586206897 ], [ 29.77377506329114, -1.450778103448276 ], [ 29.773505569620252, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19836, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.450778103448276 ], [ 29.77377506329114, -1.451047586206897 ], [ 29.774044556962025, -1.451047586206897 ], [ 29.774044556962025, -1.450778103448276 ], [ 29.77377506329114, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19837, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.450778103448276 ], [ 29.774044556962025, -1.451047586206897 ], [ 29.77431405063291, -1.451047586206897 ], [ 29.77431405063291, -1.450778103448276 ], [ 29.774044556962025, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19838, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.450778103448276 ], [ 29.77431405063291, -1.451047586206897 ], [ 29.774853037974683, -1.451047586206897 ], [ 29.774853037974683, -1.450778103448276 ], [ 29.77431405063291, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19839, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.450778103448276 ], [ 29.774853037974683, -1.451047586206897 ], [ 29.775122531645568, -1.451047586206897 ], [ 29.775122531645568, -1.450778103448276 ], [ 29.774853037974683, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19840, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.450778103448276 ], [ 29.775122531645568, -1.451047586206897 ], [ 29.775392025316457, -1.451047586206897 ], [ 29.775392025316457, -1.450778103448276 ], [ 29.775122531645568, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19841, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.450778103448276 ], [ 29.775392025316457, -1.451047586206897 ], [ 29.775661518987341, -1.451047586206897 ], [ 29.775661518987341, -1.450778103448276 ], [ 29.775392025316457, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19842, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.450778103448276 ], [ 29.775661518987341, -1.451047586206897 ], [ 29.775931012658226, -1.451047586206897 ], [ 29.775931012658226, -1.450778103448276 ], [ 29.775661518987341, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19843, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.450778103448276 ], [ 29.775931012658226, -1.451047586206897 ], [ 29.776200506329115, -1.451047586206897 ], [ 29.776200506329115, -1.450778103448276 ], [ 29.775931012658226, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19844, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.450778103448276 ], [ 29.776200506329115, -1.451047586206897 ], [ 29.77647, -1.451047586206897 ], [ 29.77647, -1.450778103448276 ], [ 29.776200506329115, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19845, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.450778103448276 ], [ 29.77647, -1.451047586206897 ], [ 29.776739493670885, -1.451047586206897 ], [ 29.776739493670885, -1.450778103448276 ], [ 29.77647, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19846, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.450778103448276 ], [ 29.776739493670885, -1.451047586206897 ], [ 29.777008987341773, -1.451047586206897 ], [ 29.777008987341773, -1.450778103448276 ], [ 29.776739493670885, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19847, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.450778103448276 ], [ 29.777008987341773, -1.451047586206897 ], [ 29.777278481012658, -1.451047586206897 ], [ 29.777278481012658, -1.450778103448276 ], [ 29.777008987341773, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19848, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.450778103448276 ], [ 29.777278481012658, -1.451047586206897 ], [ 29.777547974683543, -1.451047586206897 ], [ 29.777547974683543, -1.450778103448276 ], [ 29.777278481012658, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19849, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.450778103448276 ], [ 29.777547974683543, -1.451047586206897 ], [ 29.777817468354431, -1.451047586206897 ], [ 29.777817468354431, -1.450778103448276 ], [ 29.777547974683543, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19850, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.450778103448276 ], [ 29.777817468354431, -1.451047586206897 ], [ 29.778086962025316, -1.451047586206897 ], [ 29.778086962025316, -1.450778103448276 ], [ 29.777817468354431, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19851, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.450778103448276 ], [ 29.792370126582277, -1.451047586206897 ], [ 29.792909113924051, -1.451047586206897 ], [ 29.792909113924051, -1.450778103448276 ], [ 29.792370126582277, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19852, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.450778103448276 ], [ 29.792909113924051, -1.451047586206897 ], [ 29.793178607594935, -1.451047586206897 ], [ 29.793178607594935, -1.450778103448276 ], [ 29.792909113924051, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19853, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.450778103448276 ], [ 29.793448101265824, -1.451047586206897 ], [ 29.793717594936709, -1.451047586206897 ], [ 29.793717594936709, -1.450778103448276 ], [ 29.793448101265824, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19854, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.450778103448276 ], [ 29.793717594936709, -1.451047586206897 ], [ 29.793987088607594, -1.451047586206897 ], [ 29.793987088607594, -1.450778103448276 ], [ 29.793717594936709, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19855, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.450778103448276 ], [ 29.793987088607594, -1.451047586206897 ], [ 29.794256582278482, -1.451047586206897 ], [ 29.794256582278482, -1.450778103448276 ], [ 29.793987088607594, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19856, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.450778103448276 ], [ 29.794256582278482, -1.451317068965517 ], [ 29.794526075949367, -1.451317068965517 ], [ 29.794526075949367, -1.450778103448276 ], [ 29.794256582278482, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19857, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.450778103448276 ], [ 29.794526075949367, -1.451047586206897 ], [ 29.794795569620252, -1.451047586206897 ], [ 29.794795569620252, -1.450778103448276 ], [ 29.794526075949367, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19858, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.450778103448276 ], [ 29.803688860759493, -1.451047586206897 ], [ 29.803958354430382, -1.451047586206897 ], [ 29.803958354430382, -1.450778103448276 ], [ 29.803688860759493, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19859, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.450778103448276 ], [ 29.803958354430382, -1.451047586206897 ], [ 29.804227848101267, -1.451047586206897 ], [ 29.804227848101267, -1.450778103448276 ], [ 29.803958354430382, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19860, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.450778103448276 ], [ 29.804227848101267, -1.451047586206897 ], [ 29.804497341772151, -1.451047586206897 ], [ 29.804497341772151, -1.450778103448276 ], [ 29.804227848101267, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19861, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.450778103448276 ], [ 29.804497341772151, -1.451047586206897 ], [ 29.80476683544304, -1.451047586206897 ], [ 29.80476683544304, -1.450778103448276 ], [ 29.804497341772151, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19862, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.450778103448276 ], [ 29.80476683544304, -1.451047586206897 ], [ 29.805036329113925, -1.451047586206897 ], [ 29.805036329113925, -1.450778103448276 ], [ 29.80476683544304, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19863, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.450778103448276 ], [ 29.805036329113925, -1.451047586206897 ], [ 29.80530582278481, -1.451047586206897 ], [ 29.80530582278481, -1.450778103448276 ], [ 29.805036329113925, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19864, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.450778103448276 ], [ 29.80530582278481, -1.451047586206897 ], [ 29.805575316455698, -1.451047586206897 ], [ 29.805575316455698, -1.450778103448276 ], [ 29.80530582278481, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19865, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.450778103448276 ], [ 29.805575316455698, -1.451047586206897 ], [ 29.805844810126583, -1.451047586206897 ], [ 29.805844810126583, -1.450778103448276 ], [ 29.805575316455698, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19866, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.450778103448276 ], [ 29.805844810126583, -1.451047586206897 ], [ 29.806114303797468, -1.451047586206897 ], [ 29.806114303797468, -1.450778103448276 ], [ 29.805844810126583, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19867, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.450778103448276 ], [ 29.806653291139241, -1.451047586206897 ], [ 29.806922784810126, -1.451047586206897 ], [ 29.806922784810126, -1.450778103448276 ], [ 29.806653291139241, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19868, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.450778103448276 ], [ 29.806922784810126, -1.451047586206897 ], [ 29.807192278481015, -1.451047586206897 ], [ 29.807192278481015, -1.450778103448276 ], [ 29.806922784810126, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19869, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.450778103448276 ], [ 29.807192278481015, -1.451047586206897 ], [ 29.807731265822785, -1.451047586206897 ], [ 29.807731265822785, -1.450778103448276 ], [ 29.807192278481015, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19870, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.450778103448276 ], [ 29.807731265822785, -1.451047586206897 ], [ 29.808270253164558, -1.451047586206897 ], [ 29.808270253164558, -1.450778103448276 ], [ 29.807731265822785, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19871, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.450778103448276 ], [ 29.808270253164558, -1.451047586206897 ], [ 29.808539746835443, -1.451047586206897 ], [ 29.808539746835443, -1.450778103448276 ], [ 29.808270253164558, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19872, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.450778103448276 ], [ 29.808539746835443, -1.451047586206897 ], [ 29.808809240506328, -1.451047586206897 ], [ 29.808809240506328, -1.450778103448276 ], [ 29.808539746835443, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19873, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.450778103448276 ], [ 29.808809240506328, -1.451047586206897 ], [ 29.809078734177216, -1.451047586206897 ], [ 29.809078734177216, -1.450778103448276 ], [ 29.808809240506328, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19874, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.450778103448276 ], [ 29.809078734177216, -1.451047586206897 ], [ 29.809348227848101, -1.451047586206897 ], [ 29.809348227848101, -1.450778103448276 ], [ 29.809078734177216, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19875, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.450778103448276 ], [ 29.809348227848101, -1.451047586206897 ], [ 29.809617721518986, -1.451047586206897 ], [ 29.809617721518986, -1.450778103448276 ], [ 29.809348227848101, -1.450778103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19876, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.451047586206897 ], [ 29.76946316455696, -1.451317068965517 ], [ 29.769732658227849, -1.451317068965517 ], [ 29.769732658227849, -1.451047586206897 ], [ 29.76946316455696, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19877, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.451047586206897 ], [ 29.769732658227849, -1.451317068965517 ], [ 29.770002151898733, -1.451317068965517 ], [ 29.770002151898733, -1.451047586206897 ], [ 29.769732658227849, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19878, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.451047586206897 ], [ 29.770002151898733, -1.451317068965517 ], [ 29.770271645569618, -1.451317068965517 ], [ 29.770271645569618, -1.451047586206897 ], [ 29.770002151898733, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19879, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.451047586206897 ], [ 29.770271645569618, -1.451317068965517 ], [ 29.770541139240507, -1.451317068965517 ], [ 29.770541139240507, -1.451047586206897 ], [ 29.770271645569618, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19880, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.451047586206897 ], [ 29.770541139240507, -1.451317068965517 ], [ 29.770810632911392, -1.451317068965517 ], [ 29.770810632911392, -1.451047586206897 ], [ 29.770541139240507, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19881, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.451047586206897 ], [ 29.770810632911392, -1.451317068965517 ], [ 29.771080126582277, -1.451317068965517 ], [ 29.771080126582277, -1.451047586206897 ], [ 29.770810632911392, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19882, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.451047586206897 ], [ 29.771080126582277, -1.451317068965517 ], [ 29.771349620253165, -1.451317068965517 ], [ 29.771349620253165, -1.451047586206897 ], [ 29.771080126582277, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19883, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.451047586206897 ], [ 29.771349620253165, -1.451317068965517 ], [ 29.77161911392405, -1.451317068965517 ], [ 29.77161911392405, -1.451047586206897 ], [ 29.771349620253165, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19884, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.451047586206897 ], [ 29.77161911392405, -1.451317068965517 ], [ 29.771888607594935, -1.451317068965517 ], [ 29.771888607594935, -1.451047586206897 ], [ 29.77161911392405, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19885, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.451047586206897 ], [ 29.771888607594935, -1.451317068965517 ], [ 29.772158101265823, -1.451317068965517 ], [ 29.772158101265823, -1.451047586206897 ], [ 29.771888607594935, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19886, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.451047586206897 ], [ 29.772158101265823, -1.451317068965517 ], [ 29.772427594936708, -1.451317068965517 ], [ 29.772427594936708, -1.451047586206897 ], [ 29.772158101265823, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19887, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.451047586206897 ], [ 29.772427594936708, -1.451317068965517 ], [ 29.772697088607593, -1.451317068965517 ], [ 29.772697088607593, -1.451047586206897 ], [ 29.772427594936708, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19888, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.451047586206897 ], [ 29.772697088607593, -1.451317068965517 ], [ 29.772966582278482, -1.451317068965517 ], [ 29.772966582278482, -1.451047586206897 ], [ 29.772697088607593, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19889, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.451047586206897 ], [ 29.772966582278482, -1.451317068965517 ], [ 29.773236075949367, -1.451317068965517 ], [ 29.773236075949367, -1.451047586206897 ], [ 29.772966582278482, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19890, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.451047586206897 ], [ 29.773236075949367, -1.451317068965517 ], [ 29.773505569620252, -1.451317068965517 ], [ 29.773505569620252, -1.451047586206897 ], [ 29.773236075949367, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19891, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.451047586206897 ], [ 29.773505569620252, -1.451317068965517 ], [ 29.77377506329114, -1.451317068965517 ], [ 29.77377506329114, -1.451047586206897 ], [ 29.773505569620252, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19892, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.451047586206897 ], [ 29.77377506329114, -1.451317068965517 ], [ 29.774044556962025, -1.451317068965517 ], [ 29.774044556962025, -1.451047586206897 ], [ 29.77377506329114, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19893, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.451047586206897 ], [ 29.774044556962025, -1.451317068965517 ], [ 29.77431405063291, -1.451317068965517 ], [ 29.77431405063291, -1.451047586206897 ], [ 29.774044556962025, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19894, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.451047586206897 ], [ 29.77431405063291, -1.451317068965517 ], [ 29.774853037974683, -1.451317068965517 ], [ 29.774853037974683, -1.451047586206897 ], [ 29.77431405063291, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19895, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.451047586206897 ], [ 29.774853037974683, -1.451317068965517 ], [ 29.775122531645568, -1.451317068965517 ], [ 29.775122531645568, -1.451047586206897 ], [ 29.774853037974683, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19896, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.451047586206897 ], [ 29.775122531645568, -1.451317068965517 ], [ 29.775392025316457, -1.451317068965517 ], [ 29.775392025316457, -1.451047586206897 ], [ 29.775122531645568, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19897, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.451047586206897 ], [ 29.775392025316457, -1.451317068965517 ], [ 29.775661518987341, -1.451317068965517 ], [ 29.775661518987341, -1.451047586206897 ], [ 29.775392025316457, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19898, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.451047586206897 ], [ 29.775661518987341, -1.451317068965517 ], [ 29.775931012658226, -1.451317068965517 ], [ 29.775931012658226, -1.451047586206897 ], [ 29.775661518987341, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19899, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.451047586206897 ], [ 29.775931012658226, -1.451317068965517 ], [ 29.776200506329115, -1.451317068965517 ], [ 29.776200506329115, -1.451047586206897 ], [ 29.775931012658226, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19900, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.451047586206897 ], [ 29.776200506329115, -1.451317068965517 ], [ 29.77647, -1.451317068965517 ], [ 29.77647, -1.451047586206897 ], [ 29.776200506329115, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19901, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.451047586206897 ], [ 29.77647, -1.451317068965517 ], [ 29.776739493670885, -1.451317068965517 ], [ 29.776739493670885, -1.451047586206897 ], [ 29.77647, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19902, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.451047586206897 ], [ 29.776739493670885, -1.451317068965517 ], [ 29.777008987341773, -1.451317068965517 ], [ 29.777008987341773, -1.451047586206897 ], [ 29.776739493670885, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19903, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.451047586206897 ], [ 29.777008987341773, -1.451317068965517 ], [ 29.777278481012658, -1.451317068965517 ], [ 29.777278481012658, -1.451047586206897 ], [ 29.777008987341773, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19904, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.451047586206897 ], [ 29.777278481012658, -1.451586551724138 ], [ 29.777547974683543, -1.451586551724138 ], [ 29.777547974683543, -1.451047586206897 ], [ 29.777278481012658, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19905, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.451047586206897 ], [ 29.777547974683543, -1.451317068965517 ], [ 29.777817468354431, -1.451317068965517 ], [ 29.777817468354431, -1.451047586206897 ], [ 29.777547974683543, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19906, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.451047586206897 ], [ 29.777817468354431, -1.451317068965517 ], [ 29.778086962025316, -1.451317068965517 ], [ 29.778086962025316, -1.451047586206897 ], [ 29.777817468354431, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19907, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.451047586206897 ], [ 29.791831139240507, -1.451317068965517 ], [ 29.792100632911392, -1.451317068965517 ], [ 29.792100632911392, -1.451047586206897 ], [ 29.791831139240507, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19908, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.451047586206897 ], [ 29.792100632911392, -1.451317068965517 ], [ 29.792370126582277, -1.451317068965517 ], [ 29.792370126582277, -1.451047586206897 ], [ 29.792100632911392, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19909, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.451047586206897 ], [ 29.792370126582277, -1.451317068965517 ], [ 29.792909113924051, -1.451317068965517 ], [ 29.792909113924051, -1.451047586206897 ], [ 29.792370126582277, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19910, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.451047586206897 ], [ 29.792909113924051, -1.451317068965517 ], [ 29.793178607594935, -1.451317068965517 ], [ 29.793178607594935, -1.451047586206897 ], [ 29.792909113924051, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19911, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.451047586206897 ], [ 29.793178607594935, -1.451586551724138 ], [ 29.793448101265824, -1.451586551724138 ], [ 29.793448101265824, -1.451047586206897 ], [ 29.793178607594935, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19912, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.451047586206897 ], [ 29.793448101265824, -1.451317068965517 ], [ 29.793717594936709, -1.451317068965517 ], [ 29.793717594936709, -1.451047586206897 ], [ 29.793448101265824, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19913, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.451047586206897 ], [ 29.793717594936709, -1.451317068965517 ], [ 29.793987088607594, -1.451317068965517 ], [ 29.793987088607594, -1.451047586206897 ], [ 29.793717594936709, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19914, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.451047586206897 ], [ 29.793987088607594, -1.451317068965517 ], [ 29.794256582278482, -1.451317068965517 ], [ 29.794256582278482, -1.451047586206897 ], [ 29.793987088607594, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19915, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.451047586206897 ], [ 29.794526075949367, -1.451317068965517 ], [ 29.794795569620252, -1.451317068965517 ], [ 29.794795569620252, -1.451047586206897 ], [ 29.794526075949367, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19916, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.451047586206897 ], [ 29.803419367088608, -1.451317068965517 ], [ 29.803688860759493, -1.451317068965517 ], [ 29.803688860759493, -1.451047586206897 ], [ 29.803419367088608, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19917, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.451047586206897 ], [ 29.803688860759493, -1.451317068965517 ], [ 29.803958354430382, -1.451317068965517 ], [ 29.803958354430382, -1.451047586206897 ], [ 29.803688860759493, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19918, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.451047586206897 ], [ 29.803958354430382, -1.451317068965517 ], [ 29.804227848101267, -1.451317068965517 ], [ 29.804227848101267, -1.451047586206897 ], [ 29.803958354430382, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19919, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.451047586206897 ], [ 29.804227848101267, -1.451317068965517 ], [ 29.804497341772151, -1.451317068965517 ], [ 29.804497341772151, -1.451047586206897 ], [ 29.804227848101267, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19920, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.451047586206897 ], [ 29.804497341772151, -1.451317068965517 ], [ 29.80476683544304, -1.451317068965517 ], [ 29.80476683544304, -1.451047586206897 ], [ 29.804497341772151, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19921, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.451047586206897 ], [ 29.80476683544304, -1.451317068965517 ], [ 29.805036329113925, -1.451317068965517 ], [ 29.805036329113925, -1.451047586206897 ], [ 29.80476683544304, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19922, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.451047586206897 ], [ 29.805036329113925, -1.451317068965517 ], [ 29.80530582278481, -1.451317068965517 ], [ 29.80530582278481, -1.451047586206897 ], [ 29.805036329113925, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19923, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.451047586206897 ], [ 29.80530582278481, -1.451317068965517 ], [ 29.805575316455698, -1.451317068965517 ], [ 29.805575316455698, -1.451047586206897 ], [ 29.80530582278481, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19924, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.451047586206897 ], [ 29.805575316455698, -1.451317068965517 ], [ 29.805844810126583, -1.451317068965517 ], [ 29.805844810126583, -1.451047586206897 ], [ 29.805575316455698, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19925, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.451047586206897 ], [ 29.805844810126583, -1.451317068965517 ], [ 29.806114303797468, -1.451317068965517 ], [ 29.806114303797468, -1.451047586206897 ], [ 29.805844810126583, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19926, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.451047586206897 ], [ 29.806114303797468, -1.451317068965517 ], [ 29.806383797468357, -1.451317068965517 ], [ 29.806383797468357, -1.451047586206897 ], [ 29.806114303797468, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19927, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.451047586206897 ], [ 29.806922784810126, -1.451317068965517 ], [ 29.807192278481015, -1.451317068965517 ], [ 29.807192278481015, -1.451047586206897 ], [ 29.806922784810126, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19928, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.451047586206897 ], [ 29.807192278481015, -1.451317068965517 ], [ 29.8074617721519, -1.451317068965517 ], [ 29.8074617721519, -1.451047586206897 ], [ 29.807192278481015, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19929, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.451047586206897 ], [ 29.8074617721519, -1.451317068965517 ], [ 29.808000759493673, -1.451317068965517 ], [ 29.808000759493673, -1.451047586206897 ], [ 29.8074617721519, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19930, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.451047586206897 ], [ 29.808000759493673, -1.451317068965517 ], [ 29.808270253164558, -1.451317068965517 ], [ 29.808270253164558, -1.451047586206897 ], [ 29.808000759493673, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19931, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.451047586206897 ], [ 29.808270253164558, -1.451317068965517 ], [ 29.808539746835443, -1.451317068965517 ], [ 29.808539746835443, -1.451047586206897 ], [ 29.808270253164558, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19932, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.451047586206897 ], [ 29.808539746835443, -1.451317068965517 ], [ 29.808809240506328, -1.451317068965517 ], [ 29.808809240506328, -1.451047586206897 ], [ 29.808539746835443, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19933, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.451047586206897 ], [ 29.808809240506328, -1.451317068965517 ], [ 29.809078734177216, -1.451317068965517 ], [ 29.809078734177216, -1.451047586206897 ], [ 29.808809240506328, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19934, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.451047586206897 ], [ 29.809078734177216, -1.451317068965517 ], [ 29.809348227848101, -1.451317068965517 ], [ 29.809348227848101, -1.451047586206897 ], [ 29.809078734177216, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19935, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.451047586206897 ], [ 29.809348227848101, -1.451317068965517 ], [ 29.809617721518986, -1.451317068965517 ], [ 29.809617721518986, -1.451047586206897 ], [ 29.809348227848101, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19936, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.451047586206897 ], [ 29.809617721518986, -1.451317068965517 ], [ 29.809887215189875, -1.451317068965517 ], [ 29.809887215189875, -1.451047586206897 ], [ 29.809617721518986, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19937, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.451047586206897 ], [ 29.809887215189875, -1.451317068965517 ], [ 29.81015670886076, -1.451317068965517 ], [ 29.81015670886076, -1.451047586206897 ], [ 29.809887215189875, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19938, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.451047586206897 ], [ 29.811504177215191, -1.451317068965517 ], [ 29.811773670886076, -1.451317068965517 ], [ 29.811773670886076, -1.451047586206897 ], [ 29.811504177215191, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19939, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.451047586206897 ], [ 29.811773670886076, -1.451317068965517 ], [ 29.812043164556961, -1.451317068965517 ], [ 29.812043164556961, -1.451047586206897 ], [ 29.811773670886076, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19940, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.451047586206897 ], [ 29.812043164556961, -1.451317068965517 ], [ 29.812312658227849, -1.451317068965517 ], [ 29.812312658227849, -1.451047586206897 ], [ 29.812043164556961, -1.451047586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19941, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.451317068965517 ], [ 29.768654683544302, -1.451586551724138 ], [ 29.76892417721519, -1.451586551724138 ], [ 29.76892417721519, -1.451317068965517 ], [ 29.768654683544302, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19942, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.451317068965517 ], [ 29.76892417721519, -1.451586551724138 ], [ 29.769193670886075, -1.451586551724138 ], [ 29.769193670886075, -1.451317068965517 ], [ 29.76892417721519, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19943, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.451317068965517 ], [ 29.769193670886075, -1.451586551724138 ], [ 29.76946316455696, -1.451586551724138 ], [ 29.76946316455696, -1.451317068965517 ], [ 29.769193670886075, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19944, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.451317068965517 ], [ 29.76946316455696, -1.451856034482759 ], [ 29.769732658227849, -1.451856034482759 ], [ 29.769732658227849, -1.451317068965517 ], [ 29.76946316455696, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19945, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.451317068965517 ], [ 29.769732658227849, -1.451586551724138 ], [ 29.770002151898733, -1.451586551724138 ], [ 29.770002151898733, -1.451317068965517 ], [ 29.769732658227849, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19946, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.451317068965517 ], [ 29.770002151898733, -1.451586551724138 ], [ 29.770271645569618, -1.451586551724138 ], [ 29.770271645569618, -1.451317068965517 ], [ 29.770002151898733, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19947, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.451317068965517 ], [ 29.770271645569618, -1.451586551724138 ], [ 29.770541139240507, -1.451586551724138 ], [ 29.770541139240507, -1.451317068965517 ], [ 29.770271645569618, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19948, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.451317068965517 ], [ 29.770541139240507, -1.451586551724138 ], [ 29.770810632911392, -1.451586551724138 ], [ 29.770810632911392, -1.451317068965517 ], [ 29.770541139240507, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19949, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.451317068965517 ], [ 29.770810632911392, -1.451586551724138 ], [ 29.771080126582277, -1.451586551724138 ], [ 29.771080126582277, -1.451317068965517 ], [ 29.770810632911392, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19950, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.451317068965517 ], [ 29.771080126582277, -1.451586551724138 ], [ 29.771349620253165, -1.451586551724138 ], [ 29.771349620253165, -1.451317068965517 ], [ 29.771080126582277, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19951, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.451317068965517 ], [ 29.771349620253165, -1.451586551724138 ], [ 29.77161911392405, -1.451586551724138 ], [ 29.77161911392405, -1.451317068965517 ], [ 29.771349620253165, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19952, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.451317068965517 ], [ 29.77161911392405, -1.451586551724138 ], [ 29.771888607594935, -1.451586551724138 ], [ 29.771888607594935, -1.451317068965517 ], [ 29.77161911392405, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19953, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.451317068965517 ], [ 29.771888607594935, -1.451586551724138 ], [ 29.772158101265823, -1.451586551724138 ], [ 29.772158101265823, -1.451317068965517 ], [ 29.771888607594935, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19954, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.451317068965517 ], [ 29.772158101265823, -1.451586551724138 ], [ 29.772427594936708, -1.451586551724138 ], [ 29.772427594936708, -1.451317068965517 ], [ 29.772158101265823, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19955, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.451317068965517 ], [ 29.772427594936708, -1.451586551724138 ], [ 29.772697088607593, -1.451586551724138 ], [ 29.772697088607593, -1.451317068965517 ], [ 29.772427594936708, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19956, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.451317068965517 ], [ 29.772697088607593, -1.451586551724138 ], [ 29.772966582278482, -1.451586551724138 ], [ 29.772966582278482, -1.451317068965517 ], [ 29.772697088607593, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19957, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.451317068965517 ], [ 29.772966582278482, -1.451586551724138 ], [ 29.773236075949367, -1.451586551724138 ], [ 29.773236075949367, -1.451317068965517 ], [ 29.772966582278482, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19958, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.451317068965517 ], [ 29.773236075949367, -1.451586551724138 ], [ 29.773505569620252, -1.451586551724138 ], [ 29.773505569620252, -1.451317068965517 ], [ 29.773236075949367, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19959, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.451317068965517 ], [ 29.773505569620252, -1.451586551724138 ], [ 29.77377506329114, -1.451586551724138 ], [ 29.77377506329114, -1.451317068965517 ], [ 29.773505569620252, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19960, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.451317068965517 ], [ 29.77377506329114, -1.451586551724138 ], [ 29.774044556962025, -1.451586551724138 ], [ 29.774044556962025, -1.451317068965517 ], [ 29.77377506329114, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19961, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.451317068965517 ], [ 29.774044556962025, -1.451586551724138 ], [ 29.77431405063291, -1.451586551724138 ], [ 29.77431405063291, -1.451317068965517 ], [ 29.774044556962025, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19962, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.451317068965517 ], [ 29.77431405063291, -1.451586551724138 ], [ 29.774853037974683, -1.451586551724138 ], [ 29.774853037974683, -1.451317068965517 ], [ 29.77431405063291, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19963, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.451317068965517 ], [ 29.774853037974683, -1.451586551724138 ], [ 29.775122531645568, -1.451586551724138 ], [ 29.775122531645568, -1.451317068965517 ], [ 29.774853037974683, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19964, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.451317068965517 ], [ 29.775122531645568, -1.451586551724138 ], [ 29.775392025316457, -1.451586551724138 ], [ 29.775392025316457, -1.451317068965517 ], [ 29.775122531645568, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19965, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.451317068965517 ], [ 29.775392025316457, -1.451586551724138 ], [ 29.775661518987341, -1.451586551724138 ], [ 29.775661518987341, -1.451317068965517 ], [ 29.775392025316457, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19966, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.451317068965517 ], [ 29.775661518987341, -1.451586551724138 ], [ 29.775931012658226, -1.451586551724138 ], [ 29.775931012658226, -1.451317068965517 ], [ 29.775661518987341, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19967, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.451317068965517 ], [ 29.775931012658226, -1.451586551724138 ], [ 29.776200506329115, -1.451586551724138 ], [ 29.776200506329115, -1.451317068965517 ], [ 29.775931012658226, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19968, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.451317068965517 ], [ 29.776200506329115, -1.451856034482759 ], [ 29.77647, -1.451856034482759 ], [ 29.77647, -1.451317068965517 ], [ 29.776200506329115, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19969, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.451317068965517 ], [ 29.77647, -1.451586551724138 ], [ 29.776739493670885, -1.451586551724138 ], [ 29.776739493670885, -1.451317068965517 ], [ 29.77647, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19970, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.451317068965517 ], [ 29.776739493670885, -1.451586551724138 ], [ 29.777008987341773, -1.451586551724138 ], [ 29.777008987341773, -1.451317068965517 ], [ 29.776739493670885, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19971, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.451317068965517 ], [ 29.777008987341773, -1.451586551724138 ], [ 29.777278481012658, -1.451586551724138 ], [ 29.777278481012658, -1.451317068965517 ], [ 29.777008987341773, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19972, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.451317068965517 ], [ 29.777547974683543, -1.451586551724138 ], [ 29.777817468354431, -1.451586551724138 ], [ 29.777817468354431, -1.451317068965517 ], [ 29.777547974683543, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19973, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.451317068965517 ], [ 29.777817468354431, -1.451586551724138 ], [ 29.778086962025316, -1.451586551724138 ], [ 29.778086962025316, -1.451317068965517 ], [ 29.777817468354431, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19974, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.451317068965517 ], [ 29.778086962025316, -1.451856034482759 ], [ 29.778356455696201, -1.451856034482759 ], [ 29.778356455696201, -1.451317068965517 ], [ 29.778086962025316, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19975, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.451317068965517 ], [ 29.784554810126583, -1.451856034482759 ], [ 29.784824303797468, -1.451856034482759 ], [ 29.784824303797468, -1.451317068965517 ], [ 29.784554810126583, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19976, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.451317068965517 ], [ 29.785902278481014, -1.451856034482759 ], [ 29.786171772151899, -1.451856034482759 ], [ 29.786171772151899, -1.451317068965517 ], [ 29.785902278481014, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19977, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.451317068965517 ], [ 29.786710759493673, -1.451586551724138 ], [ 29.786980253164558, -1.451586551724138 ], [ 29.786980253164558, -1.451317068965517 ], [ 29.786710759493673, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19978, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.451317068965517 ], [ 29.786980253164558, -1.451586551724138 ], [ 29.787249746835442, -1.451586551724138 ], [ 29.787249746835442, -1.451317068965517 ], [ 29.786980253164558, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19979, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.451317068965517 ], [ 29.787249746835442, -1.451586551724138 ], [ 29.787519240506327, -1.451586551724138 ], [ 29.787519240506327, -1.451317068965517 ], [ 29.787249746835442, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19980, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.451317068965517 ], [ 29.787519240506327, -1.451586551724138 ], [ 29.787788734177216, -1.451586551724138 ], [ 29.787788734177216, -1.451317068965517 ], [ 29.787519240506327, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19981, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.451317068965517 ], [ 29.791022658227849, -1.451586551724138 ], [ 29.791292151898734, -1.451586551724138 ], [ 29.791292151898734, -1.451317068965517 ], [ 29.791022658227849, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19982, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.451317068965517 ], [ 29.791292151898734, -1.451586551724138 ], [ 29.791561645569619, -1.451586551724138 ], [ 29.791561645569619, -1.451317068965517 ], [ 29.791292151898734, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19983, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.451317068965517 ], [ 29.791561645569619, -1.451586551724138 ], [ 29.791831139240507, -1.451586551724138 ], [ 29.791831139240507, -1.451317068965517 ], [ 29.791561645569619, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19984, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.451317068965517 ], [ 29.791831139240507, -1.451856034482759 ], [ 29.792100632911392, -1.451856034482759 ], [ 29.792100632911392, -1.451317068965517 ], [ 29.791831139240507, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19985, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.451317068965517 ], [ 29.792100632911392, -1.451586551724138 ], [ 29.792370126582277, -1.451586551724138 ], [ 29.792370126582277, -1.451317068965517 ], [ 29.792100632911392, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19986, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.451317068965517 ], [ 29.792370126582277, -1.451586551724138 ], [ 29.792909113924051, -1.451586551724138 ], [ 29.792909113924051, -1.451317068965517 ], [ 29.792370126582277, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19987, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.451317068965517 ], [ 29.792909113924051, -1.451586551724138 ], [ 29.793178607594935, -1.451586551724138 ], [ 29.793178607594935, -1.451317068965517 ], [ 29.792909113924051, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19988, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.451317068965517 ], [ 29.793448101265824, -1.451586551724138 ], [ 29.793717594936709, -1.451586551724138 ], [ 29.793717594936709, -1.451317068965517 ], [ 29.793448101265824, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19989, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.451317068965517 ], [ 29.793717594936709, -1.451586551724138 ], [ 29.793987088607594, -1.451586551724138 ], [ 29.793987088607594, -1.451317068965517 ], [ 29.793717594936709, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19990, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.451317068965517 ], [ 29.793987088607594, -1.451586551724138 ], [ 29.794256582278482, -1.451586551724138 ], [ 29.794256582278482, -1.451317068965517 ], [ 29.793987088607594, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19991, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.451317068965517 ], [ 29.794256582278482, -1.451586551724138 ], [ 29.794526075949367, -1.451586551724138 ], [ 29.794526075949367, -1.451317068965517 ], [ 29.794256582278482, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19992, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.451317068965517 ], [ 29.794526075949367, -1.451586551724138 ], [ 29.794795569620252, -1.451586551724138 ], [ 29.794795569620252, -1.451317068965517 ], [ 29.794526075949367, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19993, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.451317068965517 ], [ 29.803419367088608, -1.451586551724138 ], [ 29.803688860759493, -1.451586551724138 ], [ 29.803688860759493, -1.451317068965517 ], [ 29.803419367088608, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19994, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.451317068965517 ], [ 29.803688860759493, -1.451586551724138 ], [ 29.803958354430382, -1.451586551724138 ], [ 29.803958354430382, -1.451317068965517 ], [ 29.803688860759493, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19995, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.451317068965517 ], [ 29.803958354430382, -1.451586551724138 ], [ 29.804227848101267, -1.451586551724138 ], [ 29.804227848101267, -1.451317068965517 ], [ 29.803958354430382, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19996, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.451317068965517 ], [ 29.804227848101267, -1.451586551724138 ], [ 29.804497341772151, -1.451586551724138 ], [ 29.804497341772151, -1.451317068965517 ], [ 29.804227848101267, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19997, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.451317068965517 ], [ 29.804497341772151, -1.451586551724138 ], [ 29.80476683544304, -1.451586551724138 ], [ 29.80476683544304, -1.451317068965517 ], [ 29.804497341772151, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19998, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.451317068965517 ], [ 29.80476683544304, -1.451586551724138 ], [ 29.805036329113925, -1.451586551724138 ], [ 29.805036329113925, -1.451317068965517 ], [ 29.80476683544304, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 19999, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.451317068965517 ], [ 29.805036329113925, -1.451586551724138 ], [ 29.80530582278481, -1.451586551724138 ], [ 29.80530582278481, -1.451317068965517 ], [ 29.805036329113925, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20000, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.451317068965517 ], [ 29.80530582278481, -1.451586551724138 ], [ 29.805575316455698, -1.451586551724138 ], [ 29.805575316455698, -1.451317068965517 ], [ 29.80530582278481, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20001, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.451317068965517 ], [ 29.805575316455698, -1.451586551724138 ], [ 29.805844810126583, -1.451586551724138 ], [ 29.805844810126583, -1.451317068965517 ], [ 29.805575316455698, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20002, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.451317068965517 ], [ 29.805844810126583, -1.451586551724138 ], [ 29.806114303797468, -1.451586551724138 ], [ 29.806114303797468, -1.451317068965517 ], [ 29.805844810126583, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20003, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.451317068965517 ], [ 29.806114303797468, -1.451586551724138 ], [ 29.806383797468357, -1.451586551724138 ], [ 29.806383797468357, -1.451317068965517 ], [ 29.806114303797468, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20004, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.451317068965517 ], [ 29.806383797468357, -1.451586551724138 ], [ 29.806653291139241, -1.451586551724138 ], [ 29.806653291139241, -1.451317068965517 ], [ 29.806383797468357, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20005, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.451317068965517 ], [ 29.806922784810126, -1.451586551724138 ], [ 29.807192278481015, -1.451586551724138 ], [ 29.807192278481015, -1.451317068965517 ], [ 29.806922784810126, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20006, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.451317068965517 ], [ 29.807192278481015, -1.451586551724138 ], [ 29.8074617721519, -1.451586551724138 ], [ 29.8074617721519, -1.451317068965517 ], [ 29.807192278481015, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20007, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.451317068965517 ], [ 29.8074617721519, -1.451586551724138 ], [ 29.808000759493673, -1.451586551724138 ], [ 29.808000759493673, -1.451317068965517 ], [ 29.8074617721519, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20008, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.451317068965517 ], [ 29.808000759493673, -1.451586551724138 ], [ 29.808270253164558, -1.451586551724138 ], [ 29.808270253164558, -1.451317068965517 ], [ 29.808000759493673, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20009, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.451317068965517 ], [ 29.808270253164558, -1.451586551724138 ], [ 29.808539746835443, -1.451586551724138 ], [ 29.808539746835443, -1.451317068965517 ], [ 29.808270253164558, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20010, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.451317068965517 ], [ 29.808539746835443, -1.451586551724138 ], [ 29.808809240506328, -1.451586551724138 ], [ 29.808809240506328, -1.451317068965517 ], [ 29.808539746835443, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20011, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.451317068965517 ], [ 29.808809240506328, -1.451586551724138 ], [ 29.809078734177216, -1.451586551724138 ], [ 29.809078734177216, -1.451317068965517 ], [ 29.808809240506328, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20012, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.451317068965517 ], [ 29.809078734177216, -1.451586551724138 ], [ 29.809348227848101, -1.451586551724138 ], [ 29.809348227848101, -1.451317068965517 ], [ 29.809078734177216, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20013, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.451317068965517 ], [ 29.809348227848101, -1.451586551724138 ], [ 29.809617721518986, -1.451586551724138 ], [ 29.809617721518986, -1.451317068965517 ], [ 29.809348227848101, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20014, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.451317068965517 ], [ 29.809617721518986, -1.451586551724138 ], [ 29.809887215189875, -1.451586551724138 ], [ 29.809887215189875, -1.451317068965517 ], [ 29.809617721518986, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20015, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.451317068965517 ], [ 29.809887215189875, -1.451586551724138 ], [ 29.81015670886076, -1.451586551724138 ], [ 29.81015670886076, -1.451317068965517 ], [ 29.809887215189875, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20016, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.451317068965517 ], [ 29.81015670886076, -1.451586551724138 ], [ 29.811504177215191, -1.451586551724138 ], [ 29.811504177215191, -1.451317068965517 ], [ 29.81015670886076, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20017, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.451317068965517 ], [ 29.811504177215191, -1.451586551724138 ], [ 29.811773670886076, -1.451586551724138 ], [ 29.811773670886076, -1.451317068965517 ], [ 29.811504177215191, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20018, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.451317068965517 ], [ 29.811773670886076, -1.451586551724138 ], [ 29.812043164556961, -1.451586551724138 ], [ 29.812043164556961, -1.451317068965517 ], [ 29.811773670886076, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20019, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.451586551724138 ], [ 29.768115696202532, -1.451856034482759 ], [ 29.768385189873417, -1.451856034482759 ], [ 29.768385189873417, -1.451586551724138 ], [ 29.768115696202532, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20020, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.451586551724138 ], [ 29.768385189873417, -1.451856034482759 ], [ 29.768654683544302, -1.451856034482759 ], [ 29.768654683544302, -1.451586551724138 ], [ 29.768385189873417, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20021, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.451586551724138 ], [ 29.768654683544302, -1.451856034482759 ], [ 29.76892417721519, -1.451856034482759 ], [ 29.76892417721519, -1.451586551724138 ], [ 29.768654683544302, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20022, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.451586551724138 ], [ 29.76892417721519, -1.452125517241379 ], [ 29.769193670886075, -1.452125517241379 ], [ 29.769193670886075, -1.451586551724138 ], [ 29.76892417721519, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20023, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.451586551724138 ], [ 29.769193670886075, -1.452125517241379 ], [ 29.76946316455696, -1.452125517241379 ], [ 29.76946316455696, -1.451586551724138 ], [ 29.769193670886075, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20024, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.451586551724138 ], [ 29.769732658227849, -1.451856034482759 ], [ 29.770002151898733, -1.451856034482759 ], [ 29.770002151898733, -1.451586551724138 ], [ 29.769732658227849, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20025, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.451586551724138 ], [ 29.770002151898733, -1.451856034482759 ], [ 29.770271645569618, -1.451856034482759 ], [ 29.770271645569618, -1.451586551724138 ], [ 29.770002151898733, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20026, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.451586551724138 ], [ 29.770271645569618, -1.451856034482759 ], [ 29.770541139240507, -1.451856034482759 ], [ 29.770541139240507, -1.451586551724138 ], [ 29.770271645569618, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20027, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.451586551724138 ], [ 29.770541139240507, -1.451856034482759 ], [ 29.770810632911392, -1.451856034482759 ], [ 29.770810632911392, -1.451586551724138 ], [ 29.770541139240507, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20028, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.451586551724138 ], [ 29.770810632911392, -1.451856034482759 ], [ 29.771080126582277, -1.451856034482759 ], [ 29.771080126582277, -1.451586551724138 ], [ 29.770810632911392, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20029, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.451586551724138 ], [ 29.771080126582277, -1.451856034482759 ], [ 29.771349620253165, -1.451856034482759 ], [ 29.771349620253165, -1.451586551724138 ], [ 29.771080126582277, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20030, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.451586551724138 ], [ 29.771349620253165, -1.451856034482759 ], [ 29.77161911392405, -1.451856034482759 ], [ 29.77161911392405, -1.451586551724138 ], [ 29.771349620253165, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20031, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.451586551724138 ], [ 29.77161911392405, -1.451856034482759 ], [ 29.771888607594935, -1.451856034482759 ], [ 29.771888607594935, -1.451586551724138 ], [ 29.77161911392405, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20032, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.451586551724138 ], [ 29.771888607594935, -1.451856034482759 ], [ 29.772158101265823, -1.451856034482759 ], [ 29.772158101265823, -1.451586551724138 ], [ 29.771888607594935, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20033, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.451586551724138 ], [ 29.772158101265823, -1.451856034482759 ], [ 29.772427594936708, -1.451856034482759 ], [ 29.772427594936708, -1.451586551724138 ], [ 29.772158101265823, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20034, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.451586551724138 ], [ 29.772427594936708, -1.451856034482759 ], [ 29.772697088607593, -1.451856034482759 ], [ 29.772697088607593, -1.451586551724138 ], [ 29.772427594936708, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20035, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.451586551724138 ], [ 29.772697088607593, -1.451856034482759 ], [ 29.772966582278482, -1.451856034482759 ], [ 29.772966582278482, -1.451586551724138 ], [ 29.772697088607593, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20036, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.451586551724138 ], [ 29.772966582278482, -1.451856034482759 ], [ 29.773236075949367, -1.451856034482759 ], [ 29.773236075949367, -1.451586551724138 ], [ 29.772966582278482, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20037, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.451586551724138 ], [ 29.773236075949367, -1.451856034482759 ], [ 29.773505569620252, -1.451856034482759 ], [ 29.773505569620252, -1.451586551724138 ], [ 29.773236075949367, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20038, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.451586551724138 ], [ 29.773505569620252, -1.451856034482759 ], [ 29.77377506329114, -1.451856034482759 ], [ 29.77377506329114, -1.451586551724138 ], [ 29.773505569620252, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20039, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.451586551724138 ], [ 29.77377506329114, -1.451856034482759 ], [ 29.774044556962025, -1.451856034482759 ], [ 29.774044556962025, -1.451586551724138 ], [ 29.77377506329114, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20040, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.451586551724138 ], [ 29.774044556962025, -1.451856034482759 ], [ 29.77431405063291, -1.451856034482759 ], [ 29.77431405063291, -1.451586551724138 ], [ 29.774044556962025, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20041, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.451586551724138 ], [ 29.77431405063291, -1.451856034482759 ], [ 29.774853037974683, -1.451856034482759 ], [ 29.774853037974683, -1.451586551724138 ], [ 29.77431405063291, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20042, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.451586551724138 ], [ 29.774853037974683, -1.451856034482759 ], [ 29.775122531645568, -1.451856034482759 ], [ 29.775122531645568, -1.451586551724138 ], [ 29.774853037974683, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20043, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.451586551724138 ], [ 29.775122531645568, -1.452125517241379 ], [ 29.775392025316457, -1.452125517241379 ], [ 29.775392025316457, -1.451586551724138 ], [ 29.775122531645568, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20044, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.451586551724138 ], [ 29.775392025316457, -1.451856034482759 ], [ 29.775661518987341, -1.451856034482759 ], [ 29.775661518987341, -1.451586551724138 ], [ 29.775392025316457, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20045, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.451586551724138 ], [ 29.775661518987341, -1.451856034482759 ], [ 29.775931012658226, -1.451856034482759 ], [ 29.775931012658226, -1.451586551724138 ], [ 29.775661518987341, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20046, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.451586551724138 ], [ 29.775931012658226, -1.451856034482759 ], [ 29.776200506329115, -1.451856034482759 ], [ 29.776200506329115, -1.451586551724138 ], [ 29.775931012658226, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20047, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.451586551724138 ], [ 29.77647, -1.451856034482759 ], [ 29.776739493670885, -1.451856034482759 ], [ 29.776739493670885, -1.451586551724138 ], [ 29.77647, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20048, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.451586551724138 ], [ 29.776739493670885, -1.451856034482759 ], [ 29.777008987341773, -1.451856034482759 ], [ 29.777008987341773, -1.451586551724138 ], [ 29.776739493670885, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20049, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.451586551724138 ], [ 29.777008987341773, -1.452125517241379 ], [ 29.777278481012658, -1.452125517241379 ], [ 29.777278481012658, -1.451586551724138 ], [ 29.777008987341773, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20050, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.451586551724138 ], [ 29.777278481012658, -1.451856034482759 ], [ 29.777547974683543, -1.451856034482759 ], [ 29.777547974683543, -1.451586551724138 ], [ 29.777278481012658, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20051, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.451586551724138 ], [ 29.777547974683543, -1.451856034482759 ], [ 29.777817468354431, -1.451856034482759 ], [ 29.777817468354431, -1.451586551724138 ], [ 29.777547974683543, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20052, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.451586551724138 ], [ 29.777817468354431, -1.452125517241379 ], [ 29.778086962025316, -1.452125517241379 ], [ 29.778086962025316, -1.451586551724138 ], [ 29.777817468354431, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20053, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.451586551724138 ], [ 29.778895443037975, -1.452395 ], [ 29.77916493670886, -1.452395 ], [ 29.77916493670886, -1.451586551724138 ], [ 29.778895443037975, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20054, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.451586551724138 ], [ 29.780242911392406, -1.452395 ], [ 29.780512405063291, -1.452395 ], [ 29.780512405063291, -1.451586551724138 ], [ 29.780242911392406, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20055, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.451586551724138 ], [ 29.781590379746834, -1.452125517241379 ], [ 29.781859873417723, -1.452125517241379 ], [ 29.781859873417723, -1.451586551724138 ], [ 29.781590379746834, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20056, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.451586551724138 ], [ 29.782398860759493, -1.452125517241379 ], [ 29.782668354430381, -1.452125517241379 ], [ 29.782668354430381, -1.451586551724138 ], [ 29.782398860759493, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20057, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.451586551724138 ], [ 29.782937848101266, -1.452125517241379 ], [ 29.783207341772151, -1.452125517241379 ], [ 29.783207341772151, -1.451586551724138 ], [ 29.782937848101266, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20058, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.451586551724138 ], [ 29.784015822784809, -1.452125517241379 ], [ 29.784285316455698, -1.452125517241379 ], [ 29.784285316455698, -1.451586551724138 ], [ 29.784015822784809, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20059, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.451586551724138 ], [ 29.784824303797468, -1.452125517241379 ], [ 29.785093797468356, -1.452125517241379 ], [ 29.785093797468356, -1.451586551724138 ], [ 29.784824303797468, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20060, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.451586551724138 ], [ 29.785363291139241, -1.451856034482759 ], [ 29.785632784810126, -1.451856034482759 ], [ 29.785632784810126, -1.451586551724138 ], [ 29.785363291139241, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20061, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.451586551724138 ], [ 29.785632784810126, -1.451856034482759 ], [ 29.785902278481014, -1.451856034482759 ], [ 29.785902278481014, -1.451586551724138 ], [ 29.785632784810126, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20062, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.451586551724138 ], [ 29.786171772151899, -1.451856034482759 ], [ 29.786441265822784, -1.451856034482759 ], [ 29.786441265822784, -1.451586551724138 ], [ 29.786171772151899, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20063, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.451586551724138 ], [ 29.786441265822784, -1.451856034482759 ], [ 29.786710759493673, -1.451856034482759 ], [ 29.786710759493673, -1.451586551724138 ], [ 29.786441265822784, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20064, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.451586551724138 ], [ 29.786710759493673, -1.451856034482759 ], [ 29.786980253164558, -1.451856034482759 ], [ 29.786980253164558, -1.451586551724138 ], [ 29.786710759493673, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20065, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.451586551724138 ], [ 29.786980253164558, -1.451856034482759 ], [ 29.787249746835442, -1.451856034482759 ], [ 29.787249746835442, -1.451586551724138 ], [ 29.786980253164558, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20066, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.451586551724138 ], [ 29.787249746835442, -1.451856034482759 ], [ 29.787519240506327, -1.451856034482759 ], [ 29.787519240506327, -1.451586551724138 ], [ 29.787249746835442, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20067, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.451586551724138 ], [ 29.787519240506327, -1.451856034482759 ], [ 29.787788734177216, -1.451856034482759 ], [ 29.787788734177216, -1.451586551724138 ], [ 29.787519240506327, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20068, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.451586551724138 ], [ 29.790483670886076, -1.451856034482759 ], [ 29.790753164556961, -1.451856034482759 ], [ 29.790753164556961, -1.451586551724138 ], [ 29.790483670886076, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20069, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.451586551724138 ], [ 29.790753164556961, -1.451856034482759 ], [ 29.791022658227849, -1.451856034482759 ], [ 29.791022658227849, -1.451586551724138 ], [ 29.790753164556961, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20070, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.451586551724138 ], [ 29.791022658227849, -1.451856034482759 ], [ 29.791292151898734, -1.451856034482759 ], [ 29.791292151898734, -1.451586551724138 ], [ 29.791022658227849, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20071, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.451586551724138 ], [ 29.791292151898734, -1.452125517241379 ], [ 29.791561645569619, -1.452125517241379 ], [ 29.791561645569619, -1.451586551724138 ], [ 29.791292151898734, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20072, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.451586551724138 ], [ 29.791561645569619, -1.452125517241379 ], [ 29.791831139240507, -1.452125517241379 ], [ 29.791831139240507, -1.451586551724138 ], [ 29.791561645569619, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20073, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.451586551724138 ], [ 29.792100632911392, -1.451856034482759 ], [ 29.792370126582277, -1.451856034482759 ], [ 29.792370126582277, -1.451586551724138 ], [ 29.792100632911392, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20074, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.451586551724138 ], [ 29.792370126582277, -1.451856034482759 ], [ 29.792909113924051, -1.451856034482759 ], [ 29.792909113924051, -1.451586551724138 ], [ 29.792370126582277, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20075, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.451586551724138 ], [ 29.792909113924051, -1.451856034482759 ], [ 29.793178607594935, -1.451856034482759 ], [ 29.793178607594935, -1.451586551724138 ], [ 29.792909113924051, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20076, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.451586551724138 ], [ 29.793178607594935, -1.451856034482759 ], [ 29.793448101265824, -1.451856034482759 ], [ 29.793448101265824, -1.451586551724138 ], [ 29.793178607594935, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20077, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.451586551724138 ], [ 29.793448101265824, -1.451856034482759 ], [ 29.793717594936709, -1.451856034482759 ], [ 29.793717594936709, -1.451586551724138 ], [ 29.793448101265824, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20078, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.451586551724138 ], [ 29.793717594936709, -1.451856034482759 ], [ 29.793987088607594, -1.451856034482759 ], [ 29.793987088607594, -1.451586551724138 ], [ 29.793717594936709, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20079, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.451586551724138 ], [ 29.793987088607594, -1.451856034482759 ], [ 29.794256582278482, -1.451856034482759 ], [ 29.794256582278482, -1.451586551724138 ], [ 29.793987088607594, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20080, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.451586551724138 ], [ 29.794256582278482, -1.451856034482759 ], [ 29.794526075949367, -1.451856034482759 ], [ 29.794526075949367, -1.451586551724138 ], [ 29.794256582278482, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20081, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.451586551724138 ], [ 29.794526075949367, -1.451856034482759 ], [ 29.794795569620252, -1.451856034482759 ], [ 29.794795569620252, -1.451586551724138 ], [ 29.794526075949367, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20082, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.451586551724138 ], [ 29.803419367088608, -1.451856034482759 ], [ 29.803688860759493, -1.451856034482759 ], [ 29.803688860759493, -1.451586551724138 ], [ 29.803419367088608, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20083, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.451586551724138 ], [ 29.803688860759493, -1.451856034482759 ], [ 29.803958354430382, -1.451856034482759 ], [ 29.803958354430382, -1.451586551724138 ], [ 29.803688860759493, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20084, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.451586551724138 ], [ 29.803958354430382, -1.451856034482759 ], [ 29.804227848101267, -1.451856034482759 ], [ 29.804227848101267, -1.451586551724138 ], [ 29.803958354430382, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20085, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.451586551724138 ], [ 29.804227848101267, -1.451856034482759 ], [ 29.804497341772151, -1.451856034482759 ], [ 29.804497341772151, -1.451586551724138 ], [ 29.804227848101267, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20086, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.451586551724138 ], [ 29.804497341772151, -1.451856034482759 ], [ 29.80476683544304, -1.451856034482759 ], [ 29.80476683544304, -1.451586551724138 ], [ 29.804497341772151, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20087, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.451586551724138 ], [ 29.80476683544304, -1.451856034482759 ], [ 29.805036329113925, -1.451856034482759 ], [ 29.805036329113925, -1.451586551724138 ], [ 29.80476683544304, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20088, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.451586551724138 ], [ 29.805036329113925, -1.451856034482759 ], [ 29.80530582278481, -1.451856034482759 ], [ 29.80530582278481, -1.451586551724138 ], [ 29.805036329113925, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20089, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.451586551724138 ], [ 29.80530582278481, -1.451856034482759 ], [ 29.805575316455698, -1.451856034482759 ], [ 29.805575316455698, -1.451586551724138 ], [ 29.80530582278481, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20090, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.451586551724138 ], [ 29.805575316455698, -1.451856034482759 ], [ 29.805844810126583, -1.451856034482759 ], [ 29.805844810126583, -1.451586551724138 ], [ 29.805575316455698, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20091, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.451586551724138 ], [ 29.805844810126583, -1.451856034482759 ], [ 29.806114303797468, -1.451856034482759 ], [ 29.806114303797468, -1.451586551724138 ], [ 29.805844810126583, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20092, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.451586551724138 ], [ 29.806114303797468, -1.451856034482759 ], [ 29.806383797468357, -1.451856034482759 ], [ 29.806383797468357, -1.451586551724138 ], [ 29.806114303797468, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20093, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.451586551724138 ], [ 29.806383797468357, -1.451856034482759 ], [ 29.806653291139241, -1.451856034482759 ], [ 29.806653291139241, -1.451586551724138 ], [ 29.806383797468357, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20094, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.451586551724138 ], [ 29.806653291139241, -1.451856034482759 ], [ 29.806922784810126, -1.451856034482759 ], [ 29.806922784810126, -1.451586551724138 ], [ 29.806653291139241, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20095, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.451586551724138 ], [ 29.806922784810126, -1.451856034482759 ], [ 29.807192278481015, -1.451856034482759 ], [ 29.807192278481015, -1.451586551724138 ], [ 29.806922784810126, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20096, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.451586551724138 ], [ 29.807192278481015, -1.451856034482759 ], [ 29.8074617721519, -1.451856034482759 ], [ 29.8074617721519, -1.451586551724138 ], [ 29.807192278481015, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20097, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.451586551724138 ], [ 29.8074617721519, -1.451856034482759 ], [ 29.807731265822785, -1.451856034482759 ], [ 29.807731265822785, -1.451586551724138 ], [ 29.8074617721519, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20098, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.451586551724138 ], [ 29.807731265822785, -1.451856034482759 ], [ 29.808000759493673, -1.451856034482759 ], [ 29.808000759493673, -1.451586551724138 ], [ 29.807731265822785, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20099, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.451586551724138 ], [ 29.808000759493673, -1.451856034482759 ], [ 29.808270253164558, -1.451856034482759 ], [ 29.808270253164558, -1.451586551724138 ], [ 29.808000759493673, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20100, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.451586551724138 ], [ 29.808270253164558, -1.451856034482759 ], [ 29.808539746835443, -1.451856034482759 ], [ 29.808539746835443, -1.451586551724138 ], [ 29.808270253164558, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20101, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.451586551724138 ], [ 29.808539746835443, -1.451856034482759 ], [ 29.808809240506328, -1.451856034482759 ], [ 29.808809240506328, -1.451586551724138 ], [ 29.808539746835443, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20102, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.451586551724138 ], [ 29.808809240506328, -1.451856034482759 ], [ 29.809078734177216, -1.451856034482759 ], [ 29.809078734177216, -1.451586551724138 ], [ 29.808809240506328, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20103, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.451586551724138 ], [ 29.809078734177216, -1.451856034482759 ], [ 29.809348227848101, -1.451856034482759 ], [ 29.809348227848101, -1.451586551724138 ], [ 29.809078734177216, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20104, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.451586551724138 ], [ 29.809348227848101, -1.451856034482759 ], [ 29.809617721518986, -1.451856034482759 ], [ 29.809617721518986, -1.451586551724138 ], [ 29.809348227848101, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20105, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.451586551724138 ], [ 29.809617721518986, -1.451856034482759 ], [ 29.809887215189875, -1.451856034482759 ], [ 29.809887215189875, -1.451586551724138 ], [ 29.809617721518986, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20106, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.451586551724138 ], [ 29.809887215189875, -1.451856034482759 ], [ 29.81015670886076, -1.451856034482759 ], [ 29.81015670886076, -1.451586551724138 ], [ 29.809887215189875, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20107, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.451586551724138 ], [ 29.81015670886076, -1.451856034482759 ], [ 29.811504177215191, -1.451856034482759 ], [ 29.811504177215191, -1.451586551724138 ], [ 29.81015670886076, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20108, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.451586551724138 ], [ 29.811504177215191, -1.451856034482759 ], [ 29.811773670886076, -1.451856034482759 ], [ 29.811773670886076, -1.451586551724138 ], [ 29.811504177215191, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20109, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.451856034482759 ], [ 29.768385189873417, -1.452395 ], [ 29.768654683544302, -1.452395 ], [ 29.768654683544302, -1.451856034482759 ], [ 29.768385189873417, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20110, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.451856034482759 ], [ 29.768654683544302, -1.452395 ], [ 29.76892417721519, -1.452395 ], [ 29.76892417721519, -1.451856034482759 ], [ 29.768654683544302, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20111, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.451856034482759 ], [ 29.76946316455696, -1.452125517241379 ], [ 29.769732658227849, -1.452125517241379 ], [ 29.769732658227849, -1.451856034482759 ], [ 29.76946316455696, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20112, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.451856034482759 ], [ 29.769732658227849, -1.452125517241379 ], [ 29.770002151898733, -1.452125517241379 ], [ 29.770002151898733, -1.451856034482759 ], [ 29.769732658227849, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20113, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.451856034482759 ], [ 29.770002151898733, -1.452125517241379 ], [ 29.770271645569618, -1.452125517241379 ], [ 29.770271645569618, -1.451856034482759 ], [ 29.770002151898733, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20114, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.451856034482759 ], [ 29.770271645569618, -1.452125517241379 ], [ 29.770541139240507, -1.452125517241379 ], [ 29.770541139240507, -1.451856034482759 ], [ 29.770271645569618, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20115, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.451856034482759 ], [ 29.770541139240507, -1.452125517241379 ], [ 29.770810632911392, -1.452125517241379 ], [ 29.770810632911392, -1.451856034482759 ], [ 29.770541139240507, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20116, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.451856034482759 ], [ 29.770810632911392, -1.452125517241379 ], [ 29.771080126582277, -1.452125517241379 ], [ 29.771080126582277, -1.451856034482759 ], [ 29.770810632911392, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20117, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.451856034482759 ], [ 29.771080126582277, -1.452125517241379 ], [ 29.771349620253165, -1.452125517241379 ], [ 29.771349620253165, -1.451856034482759 ], [ 29.771080126582277, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20118, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.451856034482759 ], [ 29.771349620253165, -1.452125517241379 ], [ 29.77161911392405, -1.452125517241379 ], [ 29.77161911392405, -1.451856034482759 ], [ 29.771349620253165, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20119, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.451856034482759 ], [ 29.77161911392405, -1.452125517241379 ], [ 29.771888607594935, -1.452125517241379 ], [ 29.771888607594935, -1.451856034482759 ], [ 29.77161911392405, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20120, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.451856034482759 ], [ 29.771888607594935, -1.452125517241379 ], [ 29.772158101265823, -1.452125517241379 ], [ 29.772158101265823, -1.451856034482759 ], [ 29.771888607594935, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20121, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.451856034482759 ], [ 29.772158101265823, -1.452125517241379 ], [ 29.772427594936708, -1.452125517241379 ], [ 29.772427594936708, -1.451856034482759 ], [ 29.772158101265823, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20122, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.451856034482759 ], [ 29.772427594936708, -1.452125517241379 ], [ 29.772697088607593, -1.452125517241379 ], [ 29.772697088607593, -1.451856034482759 ], [ 29.772427594936708, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20123, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.451856034482759 ], [ 29.772697088607593, -1.452125517241379 ], [ 29.772966582278482, -1.452125517241379 ], [ 29.772966582278482, -1.451856034482759 ], [ 29.772697088607593, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20124, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.451856034482759 ], [ 29.772966582278482, -1.452125517241379 ], [ 29.773236075949367, -1.452125517241379 ], [ 29.773236075949367, -1.451856034482759 ], [ 29.772966582278482, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20125, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.451856034482759 ], [ 29.773236075949367, -1.452125517241379 ], [ 29.773505569620252, -1.452125517241379 ], [ 29.773505569620252, -1.451856034482759 ], [ 29.773236075949367, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20126, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.451856034482759 ], [ 29.773505569620252, -1.452125517241379 ], [ 29.77377506329114, -1.452125517241379 ], [ 29.77377506329114, -1.451856034482759 ], [ 29.773505569620252, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20127, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.451856034482759 ], [ 29.77377506329114, -1.452395 ], [ 29.774044556962025, -1.452395 ], [ 29.774044556962025, -1.451856034482759 ], [ 29.77377506329114, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20128, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.451856034482759 ], [ 29.774044556962025, -1.452125517241379 ], [ 29.77431405063291, -1.452125517241379 ], [ 29.77431405063291, -1.451856034482759 ], [ 29.774044556962025, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20129, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.451856034482759 ], [ 29.77431405063291, -1.452125517241379 ], [ 29.774853037974683, -1.452125517241379 ], [ 29.774853037974683, -1.451856034482759 ], [ 29.77431405063291, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20130, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.451856034482759 ], [ 29.774853037974683, -1.452125517241379 ], [ 29.775122531645568, -1.452125517241379 ], [ 29.775122531645568, -1.451856034482759 ], [ 29.774853037974683, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20131, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.451856034482759 ], [ 29.775392025316457, -1.452125517241379 ], [ 29.775661518987341, -1.452125517241379 ], [ 29.775661518987341, -1.451856034482759 ], [ 29.775392025316457, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20132, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.451856034482759 ], [ 29.775661518987341, -1.452125517241379 ], [ 29.775931012658226, -1.452125517241379 ], [ 29.775931012658226, -1.451856034482759 ], [ 29.775661518987341, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20133, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.451856034482759 ], [ 29.775931012658226, -1.452395 ], [ 29.776200506329115, -1.452395 ], [ 29.776200506329115, -1.451856034482759 ], [ 29.775931012658226, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20134, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.451856034482759 ], [ 29.776200506329115, -1.452125517241379 ], [ 29.77647, -1.452125517241379 ], [ 29.77647, -1.451856034482759 ], [ 29.776200506329115, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20135, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.451856034482759 ], [ 29.77647, -1.452125517241379 ], [ 29.776739493670885, -1.452125517241379 ], [ 29.776739493670885, -1.451856034482759 ], [ 29.77647, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20136, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.451856034482759 ], [ 29.776739493670885, -1.452395 ], [ 29.777008987341773, -1.452395 ], [ 29.777008987341773, -1.451856034482759 ], [ 29.776739493670885, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20137, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.451856034482759 ], [ 29.777278481012658, -1.452125517241379 ], [ 29.777547974683543, -1.452125517241379 ], [ 29.777547974683543, -1.451856034482759 ], [ 29.777278481012658, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20138, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.451856034482759 ], [ 29.777547974683543, -1.452395 ], [ 29.777817468354431, -1.452395 ], [ 29.777817468354431, -1.451856034482759 ], [ 29.777547974683543, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20139, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.451856034482759 ], [ 29.778086962025316, -1.452125517241379 ], [ 29.778356455696201, -1.452125517241379 ], [ 29.778356455696201, -1.451856034482759 ], [ 29.778086962025316, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20140, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.451856034482759 ], [ 29.779703924050633, -1.452395 ], [ 29.779973417721518, -1.452395 ], [ 29.779973417721518, -1.451856034482759 ], [ 29.779703924050633, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20141, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.451856034482759 ], [ 29.780512405063291, -1.452395 ], [ 29.780781898734176, -1.452395 ], [ 29.780781898734176, -1.451856034482759 ], [ 29.780512405063291, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20142, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.451856034482759 ], [ 29.781051392405065, -1.452395 ], [ 29.78132088607595, -1.452395 ], [ 29.78132088607595, -1.451856034482759 ], [ 29.781051392405065, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20143, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.451856034482759 ], [ 29.781859873417723, -1.452395 ], [ 29.782129367088608, -1.452395 ], [ 29.782129367088608, -1.451856034482759 ], [ 29.781859873417723, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20144, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.451856034482759 ], [ 29.782668354430381, -1.452395 ], [ 29.782937848101266, -1.452395 ], [ 29.782937848101266, -1.451856034482759 ], [ 29.782668354430381, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20145, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.451856034482759 ], [ 29.783207341772151, -1.452125517241379 ], [ 29.783476835443039, -1.452125517241379 ], [ 29.783476835443039, -1.451856034482759 ], [ 29.783207341772151, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20146, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.451856034482759 ], [ 29.783476835443039, -1.452395 ], [ 29.784015822784809, -1.452395 ], [ 29.784015822784809, -1.451856034482759 ], [ 29.783476835443039, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20147, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.451856034482759 ], [ 29.784285316455698, -1.452125517241379 ], [ 29.784554810126583, -1.452125517241379 ], [ 29.784554810126583, -1.451856034482759 ], [ 29.784285316455698, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20148, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.451856034482759 ], [ 29.784554810126583, -1.452125517241379 ], [ 29.784824303797468, -1.452125517241379 ], [ 29.784824303797468, -1.451856034482759 ], [ 29.784554810126583, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20149, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.451856034482759 ], [ 29.785093797468356, -1.452125517241379 ], [ 29.785363291139241, -1.452125517241379 ], [ 29.785363291139241, -1.451856034482759 ], [ 29.785093797468356, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20150, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.451856034482759 ], [ 29.785363291139241, -1.452125517241379 ], [ 29.785632784810126, -1.452125517241379 ], [ 29.785632784810126, -1.451856034482759 ], [ 29.785363291139241, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20151, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.451856034482759 ], [ 29.785632784810126, -1.452125517241379 ], [ 29.785902278481014, -1.452125517241379 ], [ 29.785902278481014, -1.451856034482759 ], [ 29.785632784810126, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20152, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.451856034482759 ], [ 29.785902278481014, -1.452125517241379 ], [ 29.786171772151899, -1.452125517241379 ], [ 29.786171772151899, -1.451856034482759 ], [ 29.785902278481014, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20153, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.451856034482759 ], [ 29.786171772151899, -1.452125517241379 ], [ 29.786441265822784, -1.452125517241379 ], [ 29.786441265822784, -1.451856034482759 ], [ 29.786171772151899, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20154, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.451856034482759 ], [ 29.786441265822784, -1.452125517241379 ], [ 29.786710759493673, -1.452125517241379 ], [ 29.786710759493673, -1.451856034482759 ], [ 29.786441265822784, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20155, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.451856034482759 ], [ 29.786710759493673, -1.452125517241379 ], [ 29.786980253164558, -1.452125517241379 ], [ 29.786980253164558, -1.451856034482759 ], [ 29.786710759493673, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20156, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.451856034482759 ], [ 29.786980253164558, -1.452125517241379 ], [ 29.787249746835442, -1.452125517241379 ], [ 29.787249746835442, -1.451856034482759 ], [ 29.786980253164558, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20157, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.451856034482759 ], [ 29.787249746835442, -1.452125517241379 ], [ 29.787519240506327, -1.452125517241379 ], [ 29.787519240506327, -1.451856034482759 ], [ 29.787249746835442, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20158, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.451856034482759 ], [ 29.787519240506327, -1.452125517241379 ], [ 29.787788734177216, -1.452125517241379 ], [ 29.787788734177216, -1.451856034482759 ], [ 29.787519240506327, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20159, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.451856034482759 ], [ 29.790753164556961, -1.452395 ], [ 29.791022658227849, -1.452395 ], [ 29.791022658227849, -1.451856034482759 ], [ 29.790753164556961, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20160, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.451856034482759 ], [ 29.791022658227849, -1.452395 ], [ 29.791292151898734, -1.452395 ], [ 29.791292151898734, -1.451856034482759 ], [ 29.791022658227849, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20161, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.451856034482759 ], [ 29.791831139240507, -1.452125517241379 ], [ 29.792100632911392, -1.452125517241379 ], [ 29.792100632911392, -1.451856034482759 ], [ 29.791831139240507, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20162, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.451856034482759 ], [ 29.792100632911392, -1.452125517241379 ], [ 29.792370126582277, -1.452125517241379 ], [ 29.792370126582277, -1.451856034482759 ], [ 29.792100632911392, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20163, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.451856034482759 ], [ 29.792370126582277, -1.452125517241379 ], [ 29.792909113924051, -1.452125517241379 ], [ 29.792909113924051, -1.451856034482759 ], [ 29.792370126582277, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20164, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.451856034482759 ], [ 29.792909113924051, -1.452125517241379 ], [ 29.793178607594935, -1.452125517241379 ], [ 29.793178607594935, -1.451856034482759 ], [ 29.792909113924051, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20165, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.451856034482759 ], [ 29.793178607594935, -1.452125517241379 ], [ 29.793448101265824, -1.452125517241379 ], [ 29.793448101265824, -1.451856034482759 ], [ 29.793178607594935, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20166, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.451856034482759 ], [ 29.793448101265824, -1.452125517241379 ], [ 29.793717594936709, -1.452125517241379 ], [ 29.793717594936709, -1.451856034482759 ], [ 29.793448101265824, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20167, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.451856034482759 ], [ 29.793717594936709, -1.452125517241379 ], [ 29.793987088607594, -1.452125517241379 ], [ 29.793987088607594, -1.451856034482759 ], [ 29.793717594936709, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20168, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.451856034482759 ], [ 29.793987088607594, -1.452125517241379 ], [ 29.794256582278482, -1.452125517241379 ], [ 29.794256582278482, -1.451856034482759 ], [ 29.793987088607594, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20169, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.451856034482759 ], [ 29.794256582278482, -1.452125517241379 ], [ 29.794526075949367, -1.452125517241379 ], [ 29.794526075949367, -1.451856034482759 ], [ 29.794256582278482, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20170, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.451856034482759 ], [ 29.794526075949367, -1.452125517241379 ], [ 29.794795569620252, -1.452125517241379 ], [ 29.794795569620252, -1.451856034482759 ], [ 29.794526075949367, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20171, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.451856034482759 ], [ 29.794795569620252, -1.452125517241379 ], [ 29.79506506329114, -1.452125517241379 ], [ 29.79506506329114, -1.451856034482759 ], [ 29.794795569620252, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20172, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.451856034482759 ], [ 29.795873544303799, -1.452395 ], [ 29.796143037974684, -1.452395 ], [ 29.796143037974684, -1.451856034482759 ], [ 29.795873544303799, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20173, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.451856034482759 ], [ 29.797221012658227, -1.452395 ], [ 29.797490506329115, -1.452395 ], [ 29.797490506329115, -1.451856034482759 ], [ 29.797221012658227, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20174, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.451856034482759 ], [ 29.798029493670885, -1.452125517241379 ], [ 29.798298987341774, -1.452125517241379 ], [ 29.798298987341774, -1.451856034482759 ], [ 29.798029493670885, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20175, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.451856034482759 ], [ 29.798298987341774, -1.452125517241379 ], [ 29.798568481012659, -1.452125517241379 ], [ 29.798568481012659, -1.451856034482759 ], [ 29.798298987341774, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20176, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.451856034482759 ], [ 29.798568481012659, -1.452125517241379 ], [ 29.798837974683543, -1.452125517241379 ], [ 29.798837974683543, -1.451856034482759 ], [ 29.798568481012659, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20177, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.451856034482759 ], [ 29.803688860759493, -1.452125517241379 ], [ 29.804227848101267, -1.452125517241379 ], [ 29.804227848101267, -1.451856034482759 ], [ 29.803688860759493, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20178, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.451856034482759 ], [ 29.804227848101267, -1.452125517241379 ], [ 29.804497341772151, -1.452125517241379 ], [ 29.804497341772151, -1.451856034482759 ], [ 29.804227848101267, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20179, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.451856034482759 ], [ 29.804497341772151, -1.452125517241379 ], [ 29.80476683544304, -1.452125517241379 ], [ 29.80476683544304, -1.451856034482759 ], [ 29.804497341772151, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20180, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.451856034482759 ], [ 29.80476683544304, -1.452125517241379 ], [ 29.805036329113925, -1.452125517241379 ], [ 29.805036329113925, -1.451856034482759 ], [ 29.80476683544304, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20181, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.451856034482759 ], [ 29.805036329113925, -1.452125517241379 ], [ 29.80530582278481, -1.452125517241379 ], [ 29.80530582278481, -1.451856034482759 ], [ 29.805036329113925, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20182, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.451856034482759 ], [ 29.80530582278481, -1.452125517241379 ], [ 29.805575316455698, -1.452125517241379 ], [ 29.805575316455698, -1.451856034482759 ], [ 29.80530582278481, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20183, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.451856034482759 ], [ 29.805575316455698, -1.452125517241379 ], [ 29.805844810126583, -1.452125517241379 ], [ 29.805844810126583, -1.451856034482759 ], [ 29.805575316455698, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20184, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.451856034482759 ], [ 29.805844810126583, -1.452125517241379 ], [ 29.806114303797468, -1.452125517241379 ], [ 29.806114303797468, -1.451856034482759 ], [ 29.805844810126583, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20185, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.451856034482759 ], [ 29.806114303797468, -1.452125517241379 ], [ 29.806383797468357, -1.452125517241379 ], [ 29.806383797468357, -1.451856034482759 ], [ 29.806114303797468, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20186, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.451856034482759 ], [ 29.806383797468357, -1.452125517241379 ], [ 29.806653291139241, -1.452125517241379 ], [ 29.806653291139241, -1.451856034482759 ], [ 29.806383797468357, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20187, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.451856034482759 ], [ 29.806653291139241, -1.452125517241379 ], [ 29.806922784810126, -1.452125517241379 ], [ 29.806922784810126, -1.451856034482759 ], [ 29.806653291139241, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20188, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.451856034482759 ], [ 29.806922784810126, -1.452125517241379 ], [ 29.807192278481015, -1.452125517241379 ], [ 29.807192278481015, -1.451856034482759 ], [ 29.806922784810126, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20189, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.451856034482759 ], [ 29.807192278481015, -1.452125517241379 ], [ 29.8074617721519, -1.452125517241379 ], [ 29.8074617721519, -1.451856034482759 ], [ 29.807192278481015, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20190, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.451856034482759 ], [ 29.8074617721519, -1.452125517241379 ], [ 29.808000759493673, -1.452125517241379 ], [ 29.808000759493673, -1.451856034482759 ], [ 29.8074617721519, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20191, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.451856034482759 ], [ 29.808000759493673, -1.452125517241379 ], [ 29.808270253164558, -1.452125517241379 ], [ 29.808270253164558, -1.451856034482759 ], [ 29.808000759493673, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20192, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.451856034482759 ], [ 29.808270253164558, -1.452125517241379 ], [ 29.808539746835443, -1.452125517241379 ], [ 29.808539746835443, -1.451856034482759 ], [ 29.808270253164558, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20193, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.451856034482759 ], [ 29.808539746835443, -1.452125517241379 ], [ 29.808809240506328, -1.452125517241379 ], [ 29.808809240506328, -1.451856034482759 ], [ 29.808539746835443, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20194, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.451856034482759 ], [ 29.808809240506328, -1.452125517241379 ], [ 29.809078734177216, -1.452125517241379 ], [ 29.809078734177216, -1.451856034482759 ], [ 29.808809240506328, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20195, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.451856034482759 ], [ 29.809078734177216, -1.452125517241379 ], [ 29.809348227848101, -1.452125517241379 ], [ 29.809348227848101, -1.451856034482759 ], [ 29.809078734177216, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20196, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.451856034482759 ], [ 29.809348227848101, -1.452125517241379 ], [ 29.809617721518986, -1.452125517241379 ], [ 29.809617721518986, -1.451856034482759 ], [ 29.809348227848101, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20197, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.451856034482759 ], [ 29.809617721518986, -1.452125517241379 ], [ 29.809887215189875, -1.452125517241379 ], [ 29.809887215189875, -1.451856034482759 ], [ 29.809617721518986, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20198, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.451856034482759 ], [ 29.809887215189875, -1.452125517241379 ], [ 29.81015670886076, -1.452125517241379 ], [ 29.81015670886076, -1.451856034482759 ], [ 29.809887215189875, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20199, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.451856034482759 ], [ 29.81015670886076, -1.452125517241379 ], [ 29.811504177215191, -1.452125517241379 ], [ 29.811504177215191, -1.451856034482759 ], [ 29.81015670886076, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20200, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.451856034482759 ], [ 29.811504177215191, -1.452125517241379 ], [ 29.811773670886076, -1.452125517241379 ], [ 29.811773670886076, -1.451856034482759 ], [ 29.811504177215191, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20201, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.451856034482759 ], [ 29.811773670886076, -1.452125517241379 ], [ 29.812043164556961, -1.452125517241379 ], [ 29.812043164556961, -1.451856034482759 ], [ 29.811773670886076, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20202, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.452125517241379 ], [ 29.76892417721519, -1.452395 ], [ 29.769193670886075, -1.452395 ], [ 29.769193670886075, -1.452125517241379 ], [ 29.76892417721519, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20203, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.452125517241379 ], [ 29.769193670886075, -1.452395 ], [ 29.76946316455696, -1.452395 ], [ 29.76946316455696, -1.452125517241379 ], [ 29.769193670886075, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20204, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.452125517241379 ], [ 29.76946316455696, -1.452395 ], [ 29.769732658227849, -1.452395 ], [ 29.769732658227849, -1.452125517241379 ], [ 29.76946316455696, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20205, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.452125517241379 ], [ 29.769732658227849, -1.452395 ], [ 29.770002151898733, -1.452395 ], [ 29.770002151898733, -1.452125517241379 ], [ 29.769732658227849, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20206, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.452125517241379 ], [ 29.770002151898733, -1.452395 ], [ 29.770271645569618, -1.452395 ], [ 29.770271645569618, -1.452125517241379 ], [ 29.770002151898733, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20207, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.452125517241379 ], [ 29.770271645569618, -1.452395 ], [ 29.770541139240507, -1.452395 ], [ 29.770541139240507, -1.452125517241379 ], [ 29.770271645569618, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20208, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.452125517241379 ], [ 29.770541139240507, -1.452395 ], [ 29.770810632911392, -1.452395 ], [ 29.770810632911392, -1.452125517241379 ], [ 29.770541139240507, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20209, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.452125517241379 ], [ 29.770810632911392, -1.452395 ], [ 29.771080126582277, -1.452395 ], [ 29.771080126582277, -1.452125517241379 ], [ 29.770810632911392, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20210, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.452125517241379 ], [ 29.771080126582277, -1.452395 ], [ 29.771349620253165, -1.452395 ], [ 29.771349620253165, -1.452125517241379 ], [ 29.771080126582277, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20211, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.452125517241379 ], [ 29.771349620253165, -1.452395 ], [ 29.77161911392405, -1.452395 ], [ 29.77161911392405, -1.452125517241379 ], [ 29.771349620253165, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20212, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.452125517241379 ], [ 29.77161911392405, -1.452395 ], [ 29.771888607594935, -1.452395 ], [ 29.771888607594935, -1.452125517241379 ], [ 29.77161911392405, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20213, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.452125517241379 ], [ 29.771888607594935, -1.452395 ], [ 29.772158101265823, -1.452395 ], [ 29.772158101265823, -1.452125517241379 ], [ 29.771888607594935, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20214, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.452125517241379 ], [ 29.772158101265823, -1.452395 ], [ 29.772427594936708, -1.452395 ], [ 29.772427594936708, -1.452125517241379 ], [ 29.772158101265823, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20215, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.452125517241379 ], [ 29.772427594936708, -1.452395 ], [ 29.772697088607593, -1.452395 ], [ 29.772697088607593, -1.452125517241379 ], [ 29.772427594936708, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20216, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.452125517241379 ], [ 29.772697088607593, -1.452395 ], [ 29.772966582278482, -1.452395 ], [ 29.772966582278482, -1.452125517241379 ], [ 29.772697088607593, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20217, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.452125517241379 ], [ 29.772966582278482, -1.452395 ], [ 29.773236075949367, -1.452395 ], [ 29.773236075949367, -1.452125517241379 ], [ 29.772966582278482, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20218, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.452125517241379 ], [ 29.773236075949367, -1.452395 ], [ 29.773505569620252, -1.452395 ], [ 29.773505569620252, -1.452125517241379 ], [ 29.773236075949367, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20219, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.452125517241379 ], [ 29.773505569620252, -1.452395 ], [ 29.77377506329114, -1.452395 ], [ 29.77377506329114, -1.452125517241379 ], [ 29.773505569620252, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20220, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.452125517241379 ], [ 29.774044556962025, -1.452395 ], [ 29.77431405063291, -1.452395 ], [ 29.77431405063291, -1.452125517241379 ], [ 29.774044556962025, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20221, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.452125517241379 ], [ 29.77431405063291, -1.452395 ], [ 29.774853037974683, -1.452395 ], [ 29.774853037974683, -1.452125517241379 ], [ 29.77431405063291, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20222, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.452125517241379 ], [ 29.775122531645568, -1.452395 ], [ 29.775392025316457, -1.452395 ], [ 29.775392025316457, -1.452125517241379 ], [ 29.775122531645568, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20223, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.452125517241379 ], [ 29.775392025316457, -1.452395 ], [ 29.775661518987341, -1.452395 ], [ 29.775661518987341, -1.452125517241379 ], [ 29.775392025316457, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20224, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.452125517241379 ], [ 29.776200506329115, -1.452395 ], [ 29.77647, -1.452395 ], [ 29.77647, -1.452125517241379 ], [ 29.776200506329115, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20225, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.452125517241379 ], [ 29.78132088607595, -1.452395 ], [ 29.781590379746834, -1.452395 ], [ 29.781590379746834, -1.452125517241379 ], [ 29.78132088607595, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20226, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.452125517241379 ], [ 29.782129367088608, -1.452395 ], [ 29.782398860759493, -1.452395 ], [ 29.782398860759493, -1.452125517241379 ], [ 29.782129367088608, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20227, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.452125517241379 ], [ 29.782398860759493, -1.452395 ], [ 29.782668354430381, -1.452395 ], [ 29.782668354430381, -1.452125517241379 ], [ 29.782398860759493, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20228, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.452125517241379 ], [ 29.782937848101266, -1.452395 ], [ 29.783207341772151, -1.452395 ], [ 29.783207341772151, -1.452125517241379 ], [ 29.782937848101266, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20229, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.452125517241379 ], [ 29.783207341772151, -1.452395 ], [ 29.783476835443039, -1.452395 ], [ 29.783476835443039, -1.452125517241379 ], [ 29.783207341772151, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20230, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.452125517241379 ], [ 29.784015822784809, -1.452395 ], [ 29.784285316455698, -1.452395 ], [ 29.784285316455698, -1.452125517241379 ], [ 29.784015822784809, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20231, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.452125517241379 ], [ 29.784285316455698, -1.452395 ], [ 29.784554810126583, -1.452395 ], [ 29.784554810126583, -1.452125517241379 ], [ 29.784285316455698, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20232, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.452125517241379 ], [ 29.784554810126583, -1.452395 ], [ 29.784824303797468, -1.452395 ], [ 29.784824303797468, -1.452125517241379 ], [ 29.784554810126583, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20233, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.452125517241379 ], [ 29.784824303797468, -1.452395 ], [ 29.785093797468356, -1.452395 ], [ 29.785093797468356, -1.452125517241379 ], [ 29.784824303797468, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20234, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.452125517241379 ], [ 29.785093797468356, -1.452395 ], [ 29.785363291139241, -1.452395 ], [ 29.785363291139241, -1.452125517241379 ], [ 29.785093797468356, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20235, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.452125517241379 ], [ 29.785363291139241, -1.452395 ], [ 29.785632784810126, -1.452395 ], [ 29.785632784810126, -1.452125517241379 ], [ 29.785363291139241, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20236, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.452125517241379 ], [ 29.785632784810126, -1.452395 ], [ 29.785902278481014, -1.452395 ], [ 29.785902278481014, -1.452125517241379 ], [ 29.785632784810126, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20237, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.452125517241379 ], [ 29.785902278481014, -1.452395 ], [ 29.786171772151899, -1.452395 ], [ 29.786171772151899, -1.452125517241379 ], [ 29.785902278481014, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20238, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.452125517241379 ], [ 29.786171772151899, -1.452395 ], [ 29.786441265822784, -1.452395 ], [ 29.786441265822784, -1.452125517241379 ], [ 29.786171772151899, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20239, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.452125517241379 ], [ 29.786441265822784, -1.452395 ], [ 29.786710759493673, -1.452395 ], [ 29.786710759493673, -1.452125517241379 ], [ 29.786441265822784, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20240, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.452125517241379 ], [ 29.786710759493673, -1.452395 ], [ 29.786980253164558, -1.452395 ], [ 29.786980253164558, -1.452125517241379 ], [ 29.786710759493673, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20241, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.452125517241379 ], [ 29.786980253164558, -1.452395 ], [ 29.787249746835442, -1.452395 ], [ 29.787249746835442, -1.452125517241379 ], [ 29.786980253164558, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20242, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.452125517241379 ], [ 29.787249746835442, -1.452395 ], [ 29.787519240506327, -1.452395 ], [ 29.787519240506327, -1.452125517241379 ], [ 29.787249746835442, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20243, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.452125517241379 ], [ 29.791292151898734, -1.452395 ], [ 29.791561645569619, -1.452395 ], [ 29.791561645569619, -1.452125517241379 ], [ 29.791292151898734, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20244, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.452125517241379 ], [ 29.791561645569619, -1.452395 ], [ 29.791831139240507, -1.452395 ], [ 29.791831139240507, -1.452125517241379 ], [ 29.791561645569619, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20245, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.452125517241379 ], [ 29.791831139240507, -1.452395 ], [ 29.792100632911392, -1.452395 ], [ 29.792100632911392, -1.452125517241379 ], [ 29.791831139240507, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20246, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.452125517241379 ], [ 29.792100632911392, -1.452395 ], [ 29.792370126582277, -1.452395 ], [ 29.792370126582277, -1.452125517241379 ], [ 29.792100632911392, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20247, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.452125517241379 ], [ 29.792370126582277, -1.452395 ], [ 29.792909113924051, -1.452395 ], [ 29.792909113924051, -1.452125517241379 ], [ 29.792370126582277, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20248, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.452125517241379 ], [ 29.792909113924051, -1.452395 ], [ 29.793178607594935, -1.452395 ], [ 29.793178607594935, -1.452125517241379 ], [ 29.792909113924051, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20249, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.452125517241379 ], [ 29.793178607594935, -1.452395 ], [ 29.793448101265824, -1.452395 ], [ 29.793448101265824, -1.452125517241379 ], [ 29.793178607594935, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20250, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.452125517241379 ], [ 29.793448101265824, -1.452395 ], [ 29.793717594936709, -1.452395 ], [ 29.793717594936709, -1.452125517241379 ], [ 29.793448101265824, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20251, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.452125517241379 ], [ 29.793717594936709, -1.452395 ], [ 29.793987088607594, -1.452395 ], [ 29.793987088607594, -1.452125517241379 ], [ 29.793717594936709, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20252, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.452125517241379 ], [ 29.793987088607594, -1.452395 ], [ 29.794256582278482, -1.452395 ], [ 29.794256582278482, -1.452125517241379 ], [ 29.793987088607594, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20253, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.452125517241379 ], [ 29.794256582278482, -1.452395 ], [ 29.794526075949367, -1.452395 ], [ 29.794526075949367, -1.452125517241379 ], [ 29.794256582278482, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20254, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.452125517241379 ], [ 29.794526075949367, -1.452395 ], [ 29.794795569620252, -1.452395 ], [ 29.794795569620252, -1.452125517241379 ], [ 29.794526075949367, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20255, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.452125517241379 ], [ 29.794795569620252, -1.452395 ], [ 29.79506506329114, -1.452395 ], [ 29.79506506329114, -1.452125517241379 ], [ 29.794795569620252, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20256, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.452125517241379 ], [ 29.796682025316457, -1.452395 ], [ 29.796951518987342, -1.452395 ], [ 29.796951518987342, -1.452125517241379 ], [ 29.796682025316457, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20257, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.452125517241379 ], [ 29.796951518987342, -1.452395 ], [ 29.797221012658227, -1.452395 ], [ 29.797221012658227, -1.452125517241379 ], [ 29.796951518987342, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20258, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.452125517241379 ], [ 29.797490506329115, -1.452395 ], [ 29.79776, -1.452395 ], [ 29.79776, -1.452125517241379 ], [ 29.797490506329115, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20259, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.452125517241379 ], [ 29.79776, -1.452395 ], [ 29.798029493670885, -1.452395 ], [ 29.798029493670885, -1.452125517241379 ], [ 29.79776, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20260, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.452125517241379 ], [ 29.798029493670885, -1.452395 ], [ 29.798298987341774, -1.452395 ], [ 29.798298987341774, -1.452125517241379 ], [ 29.798029493670885, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20261, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.452125517241379 ], [ 29.804227848101267, -1.452395 ], [ 29.80476683544304, -1.452395 ], [ 29.80476683544304, -1.452125517241379 ], [ 29.804227848101267, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20262, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.452125517241379 ], [ 29.80476683544304, -1.452395 ], [ 29.805036329113925, -1.452395 ], [ 29.805036329113925, -1.452125517241379 ], [ 29.80476683544304, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20263, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.452125517241379 ], [ 29.805036329113925, -1.452395 ], [ 29.80530582278481, -1.452395 ], [ 29.80530582278481, -1.452125517241379 ], [ 29.805036329113925, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20264, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.452125517241379 ], [ 29.80530582278481, -1.452395 ], [ 29.805575316455698, -1.452395 ], [ 29.805575316455698, -1.452125517241379 ], [ 29.80530582278481, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20265, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.452125517241379 ], [ 29.805575316455698, -1.452395 ], [ 29.805844810126583, -1.452395 ], [ 29.805844810126583, -1.452125517241379 ], [ 29.805575316455698, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20266, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.452125517241379 ], [ 29.805844810126583, -1.452395 ], [ 29.806114303797468, -1.452395 ], [ 29.806114303797468, -1.452125517241379 ], [ 29.805844810126583, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20267, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.452125517241379 ], [ 29.806114303797468, -1.452395 ], [ 29.806383797468357, -1.452395 ], [ 29.806383797468357, -1.452125517241379 ], [ 29.806114303797468, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20268, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.452125517241379 ], [ 29.806383797468357, -1.452395 ], [ 29.806653291139241, -1.452395 ], [ 29.806653291139241, -1.452125517241379 ], [ 29.806383797468357, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20269, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.452125517241379 ], [ 29.806653291139241, -1.452395 ], [ 29.806922784810126, -1.452395 ], [ 29.806922784810126, -1.452125517241379 ], [ 29.806653291139241, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20270, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.452125517241379 ], [ 29.806922784810126, -1.452395 ], [ 29.807192278481015, -1.452395 ], [ 29.807192278481015, -1.452125517241379 ], [ 29.806922784810126, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20271, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.452125517241379 ], [ 29.807192278481015, -1.452395 ], [ 29.8074617721519, -1.452395 ], [ 29.8074617721519, -1.452125517241379 ], [ 29.807192278481015, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20272, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.452125517241379 ], [ 29.8074617721519, -1.452395 ], [ 29.807731265822785, -1.452395 ], [ 29.807731265822785, -1.452125517241379 ], [ 29.8074617721519, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20273, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.452125517241379 ], [ 29.807731265822785, -1.452395 ], [ 29.808000759493673, -1.452395 ], [ 29.808000759493673, -1.452125517241379 ], [ 29.807731265822785, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.452125517241379 ], [ 29.808000759493673, -1.452395 ], [ 29.808270253164558, -1.452395 ], [ 29.808270253164558, -1.452125517241379 ], [ 29.808000759493673, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20275, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.452125517241379 ], [ 29.808270253164558, -1.452395 ], [ 29.808539746835443, -1.452395 ], [ 29.808539746835443, -1.452125517241379 ], [ 29.808270253164558, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20276, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.452125517241379 ], [ 29.808539746835443, -1.452395 ], [ 29.808809240506328, -1.452395 ], [ 29.808809240506328, -1.452125517241379 ], [ 29.808539746835443, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20277, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.452125517241379 ], [ 29.808809240506328, -1.452395 ], [ 29.809078734177216, -1.452395 ], [ 29.809078734177216, -1.452125517241379 ], [ 29.808809240506328, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20278, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.452125517241379 ], [ 29.809078734177216, -1.452395 ], [ 29.809348227848101, -1.452395 ], [ 29.809348227848101, -1.452125517241379 ], [ 29.809078734177216, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20279, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.452125517241379 ], [ 29.809348227848101, -1.452395 ], [ 29.809617721518986, -1.452395 ], [ 29.809617721518986, -1.452125517241379 ], [ 29.809348227848101, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20280, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.452125517241379 ], [ 29.809617721518986, -1.452395 ], [ 29.809887215189875, -1.452395 ], [ 29.809887215189875, -1.452125517241379 ], [ 29.809617721518986, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20281, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.448083275862069 ], [ 29.755988481012658, -1.452933965517242 ], [ 29.756257974683542, -1.452933965517242 ], [ 29.756257974683542, -1.448083275862069 ], [ 29.755988481012658, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20282, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.44835275862069 ], [ 29.754910506329114, -1.452664482758621 ], [ 29.75518, -1.452664482758621 ], [ 29.75518, -1.44835275862069 ], [ 29.754910506329114, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20283, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.44835275862069 ], [ 29.755718987341773, -1.452664482758621 ], [ 29.755988481012658, -1.452664482758621 ], [ 29.755988481012658, -1.44835275862069 ], [ 29.755718987341773, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20284, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.44862224137931 ], [ 29.752215569620251, -1.453203448275862 ], [ 29.75248506329114, -1.453203448275862 ], [ 29.75248506329114, -1.44862224137931 ], [ 29.752215569620251, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20285, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.44862224137931 ], [ 29.753024050632909, -1.453203448275862 ], [ 29.753293544303798, -1.453203448275862 ], [ 29.753293544303798, -1.44862224137931 ], [ 29.753024050632909, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20286, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.44862224137931 ], [ 29.753563037974683, -1.452933965517242 ], [ 29.753832531645568, -1.452933965517242 ], [ 29.753832531645568, -1.44862224137931 ], [ 29.753563037974683, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20287, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.44862224137931 ], [ 29.753832531645568, -1.453203448275862 ], [ 29.754102025316456, -1.453203448275862 ], [ 29.754102025316456, -1.44862224137931 ], [ 29.753832531645568, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20288, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.44862224137931 ], [ 29.754371518987341, -1.452933965517242 ], [ 29.754641012658226, -1.452933965517242 ], [ 29.754641012658226, -1.44862224137931 ], [ 29.754371518987341, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20289, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.44862224137931 ], [ 29.75518, -1.452933965517242 ], [ 29.755449493670884, -1.452933965517242 ], [ 29.755449493670884, -1.44862224137931 ], [ 29.75518, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20290, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.44862224137931 ], [ 29.759491898734176, -1.454550862068966 ], [ 29.759761392405064, -1.454550862068966 ], [ 29.759761392405064, -1.44862224137931 ], [ 29.759491898734176, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20291, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.44862224137931 ], [ 29.760839367088607, -1.454550862068966 ], [ 29.761108860759492, -1.454550862068966 ], [ 29.761108860759492, -1.44862224137931 ], [ 29.760839367088607, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20292, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.44862224137931 ], [ 29.761647848101266, -1.454550862068966 ], [ 29.761917341772151, -1.454550862068966 ], [ 29.761917341772151, -1.44862224137931 ], [ 29.761647848101266, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20293, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.44862224137931 ], [ 29.761917341772151, -1.454550862068966 ], [ 29.762186835443039, -1.454550862068966 ], [ 29.762186835443039, -1.44862224137931 ], [ 29.761917341772151, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20294, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.44862224137931 ], [ 29.762186835443039, -1.454550862068966 ], [ 29.762456329113924, -1.454550862068966 ], [ 29.762456329113924, -1.44862224137931 ], [ 29.762186835443039, -1.44862224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20295, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.448891724137931 ], [ 29.750868101265823, -1.454011896551724 ], [ 29.751137594936708, -1.454011896551724 ], [ 29.751137594936708, -1.448891724137931 ], [ 29.750868101265823, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20296, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.448891724137931 ], [ 29.751137594936708, -1.454011896551724 ], [ 29.751407088607593, -1.454011896551724 ], [ 29.751407088607593, -1.448891724137931 ], [ 29.751137594936708, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20297, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.448891724137931 ], [ 29.751676582278481, -1.453742413793103 ], [ 29.751946075949366, -1.453742413793103 ], [ 29.751946075949366, -1.448891724137931 ], [ 29.751676582278481, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20298, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.448891724137931 ], [ 29.751946075949366, -1.453742413793103 ], [ 29.752215569620251, -1.453742413793103 ], [ 29.752215569620251, -1.448891724137931 ], [ 29.751946075949366, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20299, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.448891724137931 ], [ 29.75248506329114, -1.453203448275862 ], [ 29.752754556962024, -1.453203448275862 ], [ 29.752754556962024, -1.448891724137931 ], [ 29.75248506329114, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20300, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.448891724137931 ], [ 29.753293544303798, -1.453203448275862 ], [ 29.753563037974683, -1.453203448275862 ], [ 29.753563037974683, -1.448891724137931 ], [ 29.753293544303798, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20301, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.448891724137931 ], [ 29.754102025316456, -1.453203448275862 ], [ 29.754371518987341, -1.453203448275862 ], [ 29.754371518987341, -1.448891724137931 ], [ 29.754102025316456, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20302, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.448891724137931 ], [ 29.754641012658226, -1.452933965517242 ], [ 29.754910506329114, -1.452933965517242 ], [ 29.754910506329114, -1.448891724137931 ], [ 29.754641012658226, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20303, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.448891724137931 ], [ 29.755449493670884, -1.452933965517242 ], [ 29.755718987341773, -1.452933965517242 ], [ 29.755718987341773, -1.448891724137931 ], [ 29.755449493670884, -1.448891724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20304, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750059620253165, -1.449161206896552 ], [ 29.750059620253165, -1.454550862068966 ], [ 29.75032911392405, -1.454550862068966 ], [ 29.75032911392405, -1.449161206896552 ], [ 29.750059620253165, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20305, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.449161206896552 ], [ 29.75032911392405, -1.454281379310345 ], [ 29.750598607594934, -1.454281379310345 ], [ 29.750598607594934, -1.449161206896552 ], [ 29.75032911392405, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20306, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.449161206896552 ], [ 29.750598607594934, -1.454281379310345 ], [ 29.750868101265823, -1.454281379310345 ], [ 29.750868101265823, -1.449161206896552 ], [ 29.750598607594934, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20307, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.449161206896552 ], [ 29.751407088607593, -1.454011896551724 ], [ 29.751676582278481, -1.454011896551724 ], [ 29.751676582278481, -1.449161206896552 ], [ 29.751407088607593, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20308, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.449161206896552 ], [ 29.752754556962024, -1.453742413793103 ], [ 29.753024050632909, -1.453742413793103 ], [ 29.753024050632909, -1.449161206896552 ], [ 29.752754556962024, -1.449161206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20309, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.450508620689655 ], [ 29.812043164556961, -1.451047586206897 ], [ 29.812312658227849, -1.451047586206897 ], [ 29.812312658227849, -1.452664482758621 ], [ 29.812582151898734, -1.452664482758621 ], [ 29.812582151898734, -1.450778103448276 ], [ 29.812312658227849, -1.450778103448276 ], [ 29.812312658227849, -1.450508620689655 ], [ 29.812043164556961, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20310, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.450508620689655 ], [ 29.812582151898734, -1.452664482758621 ], [ 29.812851645569619, -1.452664482758621 ], [ 29.812851645569619, -1.450508620689655 ], [ 29.812582151898734, -1.450508620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20311, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.451586551724138 ], [ 29.787788734177216, -1.451856034482759 ], [ 29.788058227848101, -1.451856034482759 ], [ 29.788058227848101, -1.454281379310345 ], [ 29.788327721518986, -1.454281379310345 ], [ 29.788327721518986, -1.451586551724138 ], [ 29.787788734177216, -1.451586551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20312, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.451317068965517 ], [ 29.812312658227849, -1.451317068965517 ], [ 29.812312658227849, -1.452664482758621 ], [ 29.812043164556961, -1.452664482758621 ], [ 29.812043164556961, -1.451856034482759 ], [ 29.811773670886076, -1.451856034482759 ], [ 29.811773670886076, -1.451586551724138 ], [ 29.812043164556961, -1.451586551724138 ], [ 29.812043164556961, -1.451317068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20313, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.451856034482759 ], [ 29.768115696202532, -1.452664482758621 ], [ 29.768385189873417, -1.452664482758621 ], [ 29.768385189873417, -1.451856034482759 ], [ 29.768115696202532, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20314, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.451856034482759 ], [ 29.787788734177216, -1.454281379310345 ], [ 29.788058227848101, -1.454281379310345 ], [ 29.788058227848101, -1.451856034482759 ], [ 29.787788734177216, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20315, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.451856034482759 ], [ 29.790483670886076, -1.452664482758621 ], [ 29.790753164556961, -1.452664482758621 ], [ 29.790753164556961, -1.451856034482759 ], [ 29.790483670886076, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20316, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.451856034482759 ], [ 29.798837974683543, -1.453203448275862 ], [ 29.799107468354432, -1.453203448275862 ], [ 29.799107468354432, -1.451856034482759 ], [ 29.798837974683543, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20317, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.452125517241379 ], [ 29.774853037974683, -1.452664482758621 ], [ 29.775122531645568, -1.452664482758621 ], [ 29.775122531645568, -1.452125517241379 ], [ 29.774853037974683, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20318, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.452125517241379 ], [ 29.775661518987341, -1.452664482758621 ], [ 29.775931012658226, -1.452664482758621 ], [ 29.775931012658226, -1.452125517241379 ], [ 29.775661518987341, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20319, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.452125517241379 ], [ 29.77647, -1.452664482758621 ], [ 29.776739493670885, -1.452664482758621 ], [ 29.776739493670885, -1.452125517241379 ], [ 29.77647, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20320, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.452125517241379 ], [ 29.777008987341773, -1.452664482758621 ], [ 29.777278481012658, -1.452664482758621 ], [ 29.777278481012658, -1.452125517241379 ], [ 29.777008987341773, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20321, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.452125517241379 ], [ 29.777278481012658, -1.452664482758621 ], [ 29.777547974683543, -1.452664482758621 ], [ 29.777547974683543, -1.452125517241379 ], [ 29.777278481012658, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20322, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.452125517241379 ], [ 29.777817468354431, -1.452664482758621 ], [ 29.778086962025316, -1.452664482758621 ], [ 29.778086962025316, -1.452125517241379 ], [ 29.777817468354431, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20323, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.451856034482759 ], [ 29.77862594936709, -1.451856034482759 ], [ 29.77862594936709, -1.452664482758621 ], [ 29.778356455696201, -1.452664482758621 ], [ 29.778356455696201, -1.452933965517242 ], [ 29.778086962025316, -1.452933965517242 ], [ 29.778086962025316, -1.452125517241379 ], [ 29.778356455696201, -1.452125517241379 ], [ 29.778356455696201, -1.451856034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20324, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.452125517241379 ], [ 29.77862594936709, -1.452664482758621 ], [ 29.778895443037975, -1.452664482758621 ], [ 29.778895443037975, -1.452125517241379 ], [ 29.77862594936709, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20325, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.452125517241379 ], [ 29.77916493670886, -1.452664482758621 ], [ 29.779434430379748, -1.452664482758621 ], [ 29.779434430379748, -1.452125517241379 ], [ 29.77916493670886, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20326, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.452125517241379 ], [ 29.779434430379748, -1.452664482758621 ], [ 29.779703924050633, -1.452664482758621 ], [ 29.779703924050633, -1.452125517241379 ], [ 29.779434430379748, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20327, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.452125517241379 ], [ 29.779973417721518, -1.452664482758621 ], [ 29.780242911392406, -1.452664482758621 ], [ 29.780242911392406, -1.452125517241379 ], [ 29.779973417721518, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20328, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.452125517241379 ], [ 29.780781898734176, -1.452664482758621 ], [ 29.781051392405065, -1.452664482758621 ], [ 29.781051392405065, -1.452125517241379 ], [ 29.780781898734176, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20329, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.452125517241379 ], [ 29.781590379746834, -1.452664482758621 ], [ 29.781859873417723, -1.452664482758621 ], [ 29.781859873417723, -1.452125517241379 ], [ 29.781590379746834, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20330, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.452125517241379 ], [ 29.787519240506327, -1.454281379310345 ], [ 29.787788734177216, -1.454281379310345 ], [ 29.787788734177216, -1.452125517241379 ], [ 29.787519240506327, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20331, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.452125517241379 ], [ 29.795334556962025, -1.452664482758621 ], [ 29.79560405063291, -1.452664482758621 ], [ 29.79560405063291, -1.452125517241379 ], [ 29.795334556962025, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20332, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.452125517241379 ], [ 29.796143037974684, -1.452664482758621 ], [ 29.796412531645569, -1.452664482758621 ], [ 29.796412531645569, -1.452125517241379 ], [ 29.796143037974684, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20333, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.452125517241379 ], [ 29.798298987341774, -1.453203448275862 ], [ 29.798568481012659, -1.453203448275862 ], [ 29.798568481012659, -1.452125517241379 ], [ 29.798298987341774, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20334, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.452125517241379 ], [ 29.798568481012659, -1.453203448275862 ], [ 29.798837974683543, -1.453203448275862 ], [ 29.798837974683543, -1.452125517241379 ], [ 29.798568481012659, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20335, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.452125517241379 ], [ 29.809887215189875, -1.452395 ], [ 29.811773670886076, -1.452395 ], [ 29.811773670886076, -1.452933965517242 ], [ 29.812043164556961, -1.452933965517242 ], [ 29.812043164556961, -1.452125517241379 ], [ 29.809887215189875, -1.452125517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20336, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.452395 ], [ 29.768385189873417, -1.452664482758621 ], [ 29.768654683544302, -1.452664482758621 ], [ 29.768654683544302, -1.452395 ], [ 29.768385189873417, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20337, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.452395 ], [ 29.768654683544302, -1.452664482758621 ], [ 29.76892417721519, -1.452664482758621 ], [ 29.76892417721519, -1.452395 ], [ 29.768654683544302, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20338, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.452395 ], [ 29.76892417721519, -1.452664482758621 ], [ 29.769193670886075, -1.452664482758621 ], [ 29.769193670886075, -1.452395 ], [ 29.76892417721519, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20339, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.452395 ], [ 29.769193670886075, -1.452664482758621 ], [ 29.76946316455696, -1.452664482758621 ], [ 29.76946316455696, -1.452395 ], [ 29.769193670886075, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20340, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.452395 ], [ 29.76946316455696, -1.452664482758621 ], [ 29.769732658227849, -1.452664482758621 ], [ 29.769732658227849, -1.452395 ], [ 29.76946316455696, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20341, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.452395 ], [ 29.769732658227849, -1.452664482758621 ], [ 29.770002151898733, -1.452664482758621 ], [ 29.770002151898733, -1.452395 ], [ 29.769732658227849, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20342, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.452395 ], [ 29.770002151898733, -1.452664482758621 ], [ 29.770271645569618, -1.452664482758621 ], [ 29.770271645569618, -1.452395 ], [ 29.770002151898733, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20343, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.452395 ], [ 29.770271645569618, -1.452664482758621 ], [ 29.770541139240507, -1.452664482758621 ], [ 29.770541139240507, -1.452395 ], [ 29.770271645569618, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20344, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.452395 ], [ 29.770541139240507, -1.452664482758621 ], [ 29.770810632911392, -1.452664482758621 ], [ 29.770810632911392, -1.452395 ], [ 29.770541139240507, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20345, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.452395 ], [ 29.770810632911392, -1.452664482758621 ], [ 29.771080126582277, -1.452664482758621 ], [ 29.771080126582277, -1.452395 ], [ 29.770810632911392, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20346, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.452395 ], [ 29.771080126582277, -1.452664482758621 ], [ 29.771349620253165, -1.452664482758621 ], [ 29.771349620253165, -1.452395 ], [ 29.771080126582277, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20347, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.452395 ], [ 29.771349620253165, -1.452664482758621 ], [ 29.77161911392405, -1.452664482758621 ], [ 29.77161911392405, -1.452395 ], [ 29.771349620253165, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20348, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.452395 ], [ 29.77161911392405, -1.452664482758621 ], [ 29.771888607594935, -1.452664482758621 ], [ 29.771888607594935, -1.452395 ], [ 29.77161911392405, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20349, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.452395 ], [ 29.771888607594935, -1.452664482758621 ], [ 29.772158101265823, -1.452664482758621 ], [ 29.772158101265823, -1.452395 ], [ 29.771888607594935, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20350, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.452395 ], [ 29.772158101265823, -1.452664482758621 ], [ 29.772427594936708, -1.452664482758621 ], [ 29.772427594936708, -1.452395 ], [ 29.772158101265823, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20351, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.452395 ], [ 29.772427594936708, -1.452664482758621 ], [ 29.772697088607593, -1.452664482758621 ], [ 29.772697088607593, -1.452395 ], [ 29.772427594936708, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20352, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.452395 ], [ 29.772697088607593, -1.452933965517242 ], [ 29.772966582278482, -1.452933965517242 ], [ 29.772966582278482, -1.452395 ], [ 29.772697088607593, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20353, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.452395 ], [ 29.772966582278482, -1.452664482758621 ], [ 29.773236075949367, -1.452664482758621 ], [ 29.773236075949367, -1.452395 ], [ 29.772966582278482, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20354, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.452395 ], [ 29.773236075949367, -1.452664482758621 ], [ 29.773505569620252, -1.452664482758621 ], [ 29.773505569620252, -1.452395 ], [ 29.773236075949367, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20355, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.452395 ], [ 29.773505569620252, -1.452933965517242 ], [ 29.77377506329114, -1.452933965517242 ], [ 29.77377506329114, -1.452395 ], [ 29.773505569620252, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20356, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.452395 ], [ 29.77377506329114, -1.452664482758621 ], [ 29.774044556962025, -1.452664482758621 ], [ 29.774044556962025, -1.452395 ], [ 29.77377506329114, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20357, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.452395 ], [ 29.774044556962025, -1.452664482758621 ], [ 29.77431405063291, -1.452664482758621 ], [ 29.77431405063291, -1.452395 ], [ 29.774044556962025, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20358, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.452395 ], [ 29.77431405063291, -1.452933965517242 ], [ 29.774853037974683, -1.452933965517242 ], [ 29.774853037974683, -1.452395 ], [ 29.77431405063291, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20359, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.452395 ], [ 29.775122531645568, -1.452664482758621 ], [ 29.775392025316457, -1.452664482758621 ], [ 29.775392025316457, -1.452395 ], [ 29.775122531645568, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20360, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.452395 ], [ 29.775392025316457, -1.452933965517242 ], [ 29.775661518987341, -1.452933965517242 ], [ 29.775661518987341, -1.452395 ], [ 29.775392025316457, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20361, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.452395 ], [ 29.775931012658226, -1.452933965517242 ], [ 29.776200506329115, -1.452933965517242 ], [ 29.776200506329115, -1.452395 ], [ 29.775931012658226, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20362, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.452395 ], [ 29.776200506329115, -1.453203448275862 ], [ 29.77647, -1.453203448275862 ], [ 29.77647, -1.452395 ], [ 29.776200506329115, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20363, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.452395 ], [ 29.776739493670885, -1.452933965517242 ], [ 29.777008987341773, -1.452933965517242 ], [ 29.777008987341773, -1.452395 ], [ 29.776739493670885, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20364, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.452395 ], [ 29.777547974683543, -1.453203448275862 ], [ 29.777817468354431, -1.453203448275862 ], [ 29.777817468354431, -1.452395 ], [ 29.777547974683543, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20365, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.452395 ], [ 29.778895443037975, -1.452933965517242 ], [ 29.77916493670886, -1.452933965517242 ], [ 29.77916493670886, -1.452395 ], [ 29.778895443037975, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20366, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.452395 ], [ 29.779703924050633, -1.452933965517242 ], [ 29.779973417721518, -1.452933965517242 ], [ 29.779973417721518, -1.452395 ], [ 29.779703924050633, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20367, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.452395 ], [ 29.780242911392406, -1.452664482758621 ], [ 29.780512405063291, -1.452664482758621 ], [ 29.780512405063291, -1.452395 ], [ 29.780242911392406, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20368, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.452395 ], [ 29.780512405063291, -1.452933965517242 ], [ 29.780781898734176, -1.452933965517242 ], [ 29.780781898734176, -1.452395 ], [ 29.780512405063291, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20369, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.452395 ], [ 29.781051392405065, -1.452664482758621 ], [ 29.78132088607595, -1.452664482758621 ], [ 29.78132088607595, -1.452395 ], [ 29.781051392405065, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20370, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.452395 ], [ 29.78132088607595, -1.452664482758621 ], [ 29.781590379746834, -1.452664482758621 ], [ 29.781590379746834, -1.452395 ], [ 29.78132088607595, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20371, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.452395 ], [ 29.781859873417723, -1.452664482758621 ], [ 29.782129367088608, -1.452664482758621 ], [ 29.782129367088608, -1.452395 ], [ 29.781859873417723, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20372, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.452395 ], [ 29.782129367088608, -1.452664482758621 ], [ 29.782398860759493, -1.452664482758621 ], [ 29.782398860759493, -1.452395 ], [ 29.782129367088608, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20373, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.452395 ], [ 29.782398860759493, -1.452933965517242 ], [ 29.782668354430381, -1.452933965517242 ], [ 29.782668354430381, -1.452395 ], [ 29.782398860759493, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20374, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.452395 ], [ 29.782668354430381, -1.452664482758621 ], [ 29.782937848101266, -1.452664482758621 ], [ 29.782937848101266, -1.452395 ], [ 29.782668354430381, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20375, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.452395 ], [ 29.782937848101266, -1.452664482758621 ], [ 29.783207341772151, -1.452664482758621 ], [ 29.783207341772151, -1.452395 ], [ 29.782937848101266, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20376, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.452395 ], [ 29.783207341772151, -1.452664482758621 ], [ 29.783476835443039, -1.452664482758621 ], [ 29.783476835443039, -1.452395 ], [ 29.783207341772151, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20377, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.452395 ], [ 29.783476835443039, -1.452664482758621 ], [ 29.784015822784809, -1.452664482758621 ], [ 29.784015822784809, -1.452395 ], [ 29.783476835443039, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20378, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.452395 ], [ 29.784015822784809, -1.452664482758621 ], [ 29.784285316455698, -1.452664482758621 ], [ 29.784285316455698, -1.452395 ], [ 29.784015822784809, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20379, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.452395 ], [ 29.784285316455698, -1.452664482758621 ], [ 29.784554810126583, -1.452664482758621 ], [ 29.784554810126583, -1.452395 ], [ 29.784285316455698, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20380, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.452395 ], [ 29.784554810126583, -1.452664482758621 ], [ 29.784824303797468, -1.452664482758621 ], [ 29.784824303797468, -1.452395 ], [ 29.784554810126583, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20381, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.452395 ], [ 29.784824303797468, -1.452664482758621 ], [ 29.785093797468356, -1.452664482758621 ], [ 29.785093797468356, -1.452395 ], [ 29.784824303797468, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20382, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.452395 ], [ 29.785093797468356, -1.452664482758621 ], [ 29.785363291139241, -1.452664482758621 ], [ 29.785363291139241, -1.452395 ], [ 29.785093797468356, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20383, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.452395 ], [ 29.785363291139241, -1.452664482758621 ], [ 29.785632784810126, -1.452664482758621 ], [ 29.785632784810126, -1.452395 ], [ 29.785363291139241, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20384, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.452395 ], [ 29.785632784810126, -1.452664482758621 ], [ 29.785902278481014, -1.452664482758621 ], [ 29.785902278481014, -1.452395 ], [ 29.785632784810126, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20385, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.452395 ], [ 29.785902278481014, -1.452664482758621 ], [ 29.786171772151899, -1.452664482758621 ], [ 29.786171772151899, -1.452395 ], [ 29.785902278481014, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20386, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.452395 ], [ 29.786171772151899, -1.452664482758621 ], [ 29.786441265822784, -1.452664482758621 ], [ 29.786441265822784, -1.452395 ], [ 29.786171772151899, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20387, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.452395 ], [ 29.786441265822784, -1.452664482758621 ], [ 29.786710759493673, -1.452664482758621 ], [ 29.786710759493673, -1.452395 ], [ 29.786441265822784, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20388, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.452395 ], [ 29.786710759493673, -1.452664482758621 ], [ 29.786980253164558, -1.452664482758621 ], [ 29.786980253164558, -1.452395 ], [ 29.786710759493673, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20389, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.452395 ], [ 29.786980253164558, -1.454550862068966 ], [ 29.787249746835442, -1.454550862068966 ], [ 29.787249746835442, -1.452395 ], [ 29.786980253164558, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20390, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.452395 ], [ 29.787249746835442, -1.454550862068966 ], [ 29.787519240506327, -1.454550862068966 ], [ 29.787519240506327, -1.452395 ], [ 29.787249746835442, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20391, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.452395 ], [ 29.790753164556961, -1.452664482758621 ], [ 29.791022658227849, -1.452664482758621 ], [ 29.791022658227849, -1.452395 ], [ 29.790753164556961, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20392, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.452395 ], [ 29.791022658227849, -1.452664482758621 ], [ 29.791292151898734, -1.452664482758621 ], [ 29.791292151898734, -1.452395 ], [ 29.791022658227849, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20393, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.452395 ], [ 29.791292151898734, -1.452664482758621 ], [ 29.791561645569619, -1.452664482758621 ], [ 29.791561645569619, -1.452395 ], [ 29.791292151898734, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20394, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.452395 ], [ 29.791561645569619, -1.452664482758621 ], [ 29.791831139240507, -1.452664482758621 ], [ 29.791831139240507, -1.452395 ], [ 29.791561645569619, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20395, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.452395 ], [ 29.791831139240507, -1.452664482758621 ], [ 29.792100632911392, -1.452664482758621 ], [ 29.792100632911392, -1.452395 ], [ 29.791831139240507, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20396, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.452395 ], [ 29.792100632911392, -1.452664482758621 ], [ 29.792370126582277, -1.452664482758621 ], [ 29.792370126582277, -1.452395 ], [ 29.792100632911392, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20397, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.452395 ], [ 29.792370126582277, -1.452664482758621 ], [ 29.792909113924051, -1.452664482758621 ], [ 29.792909113924051, -1.452395 ], [ 29.792370126582277, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20398, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.452395 ], [ 29.792909113924051, -1.452664482758621 ], [ 29.793178607594935, -1.452664482758621 ], [ 29.793178607594935, -1.452395 ], [ 29.792909113924051, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20399, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.452395 ], [ 29.793178607594935, -1.452664482758621 ], [ 29.793448101265824, -1.452664482758621 ], [ 29.793448101265824, -1.452395 ], [ 29.793178607594935, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20400, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.452395 ], [ 29.793448101265824, -1.452664482758621 ], [ 29.793717594936709, -1.452664482758621 ], [ 29.793717594936709, -1.452395 ], [ 29.793448101265824, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20401, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.452395 ], [ 29.793717594936709, -1.452664482758621 ], [ 29.793987088607594, -1.452664482758621 ], [ 29.793987088607594, -1.452395 ], [ 29.793717594936709, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20402, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.452395 ], [ 29.793987088607594, -1.452664482758621 ], [ 29.794256582278482, -1.452664482758621 ], [ 29.794256582278482, -1.452395 ], [ 29.793987088607594, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20403, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.452395 ], [ 29.794256582278482, -1.452664482758621 ], [ 29.794526075949367, -1.452664482758621 ], [ 29.794526075949367, -1.452395 ], [ 29.794256582278482, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20404, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.452395 ], [ 29.794526075949367, -1.452664482758621 ], [ 29.794795569620252, -1.452664482758621 ], [ 29.794795569620252, -1.452395 ], [ 29.794526075949367, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20405, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.452395 ], [ 29.794795569620252, -1.452664482758621 ], [ 29.79506506329114, -1.452664482758621 ], [ 29.79506506329114, -1.452395 ], [ 29.794795569620252, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20406, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.452395 ], [ 29.79506506329114, -1.453203448275862 ], [ 29.795334556962025, -1.453203448275862 ], [ 29.795334556962025, -1.452395 ], [ 29.79506506329114, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20407, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.452395 ], [ 29.79560405063291, -1.452664482758621 ], [ 29.795873544303799, -1.452664482758621 ], [ 29.795873544303799, -1.452395 ], [ 29.79560405063291, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20408, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.452395 ], [ 29.795873544303799, -1.452664482758621 ], [ 29.796143037974684, -1.452664482758621 ], [ 29.796143037974684, -1.452395 ], [ 29.795873544303799, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20409, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.452395 ], [ 29.796412531645569, -1.452664482758621 ], [ 29.796682025316457, -1.452664482758621 ], [ 29.796682025316457, -1.452395 ], [ 29.796412531645569, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20410, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.452395 ], [ 29.796682025316457, -1.452664482758621 ], [ 29.796951518987342, -1.452664482758621 ], [ 29.796951518987342, -1.452395 ], [ 29.796682025316457, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20411, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.452395 ], [ 29.796951518987342, -1.452664482758621 ], [ 29.797221012658227, -1.452664482758621 ], [ 29.797221012658227, -1.452395 ], [ 29.796951518987342, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20412, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.452395 ], [ 29.797221012658227, -1.452664482758621 ], [ 29.797490506329115, -1.452664482758621 ], [ 29.797490506329115, -1.452395 ], [ 29.797221012658227, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20413, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.452395 ], [ 29.797490506329115, -1.453203448275862 ], [ 29.79776, -1.453203448275862 ], [ 29.79776, -1.452395 ], [ 29.797490506329115, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20414, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.452395 ], [ 29.79776, -1.453742413793103 ], [ 29.798029493670885, -1.453742413793103 ], [ 29.798029493670885, -1.452395 ], [ 29.79776, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20415, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.452395 ], [ 29.798029493670885, -1.453742413793103 ], [ 29.798298987341774, -1.453742413793103 ], [ 29.798298987341774, -1.452395 ], [ 29.798029493670885, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20416, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.452395 ], [ 29.80476683544304, -1.452664482758621 ], [ 29.80530582278481, -1.452664482758621 ], [ 29.80530582278481, -1.452395 ], [ 29.80476683544304, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20417, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.452395 ], [ 29.80530582278481, -1.452664482758621 ], [ 29.805575316455698, -1.452664482758621 ], [ 29.805575316455698, -1.452395 ], [ 29.80530582278481, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20418, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.452395 ], [ 29.805575316455698, -1.452664482758621 ], [ 29.805844810126583, -1.452664482758621 ], [ 29.805844810126583, -1.452395 ], [ 29.805575316455698, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20419, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.452395 ], [ 29.805844810126583, -1.452664482758621 ], [ 29.806114303797468, -1.452664482758621 ], [ 29.806114303797468, -1.452395 ], [ 29.805844810126583, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20420, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.452395 ], [ 29.806114303797468, -1.452664482758621 ], [ 29.806383797468357, -1.452664482758621 ], [ 29.806383797468357, -1.452395 ], [ 29.806114303797468, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20421, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.452395 ], [ 29.806383797468357, -1.452664482758621 ], [ 29.806653291139241, -1.452664482758621 ], [ 29.806653291139241, -1.452395 ], [ 29.806383797468357, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20422, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.452395 ], [ 29.806653291139241, -1.452664482758621 ], [ 29.806922784810126, -1.452664482758621 ], [ 29.806922784810126, -1.452395 ], [ 29.806653291139241, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20423, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.452395 ], [ 29.806922784810126, -1.452933965517242 ], [ 29.807192278481015, -1.452933965517242 ], [ 29.807192278481015, -1.452395 ], [ 29.806922784810126, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20424, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.452395 ], [ 29.807192278481015, -1.452664482758621 ], [ 29.8074617721519, -1.452664482758621 ], [ 29.8074617721519, -1.452395 ], [ 29.807192278481015, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20425, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.452395 ], [ 29.8074617721519, -1.452664482758621 ], [ 29.807731265822785, -1.452664482758621 ], [ 29.807731265822785, -1.452395 ], [ 29.8074617721519, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20426, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.452395 ], [ 29.807731265822785, -1.452933965517242 ], [ 29.808000759493673, -1.452933965517242 ], [ 29.808000759493673, -1.452664482758621 ], [ 29.808270253164558, -1.452664482758621 ], [ 29.808270253164558, -1.452395 ], [ 29.807731265822785, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20427, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.452395 ], [ 29.808270253164558, -1.452664482758621 ], [ 29.808539746835443, -1.452664482758621 ], [ 29.808539746835443, -1.452395 ], [ 29.808270253164558, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20428, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.452395 ], [ 29.808539746835443, -1.452664482758621 ], [ 29.808809240506328, -1.452664482758621 ], [ 29.808809240506328, -1.452395 ], [ 29.808539746835443, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20429, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.452395 ], [ 29.808809240506328, -1.452664482758621 ], [ 29.809078734177216, -1.452664482758621 ], [ 29.809078734177216, -1.452395 ], [ 29.808809240506328, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20430, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.452395 ], [ 29.809078734177216, -1.452664482758621 ], [ 29.809348227848101, -1.452664482758621 ], [ 29.809348227848101, -1.452395 ], [ 29.809078734177216, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20431, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.452395 ], [ 29.809348227848101, -1.452664482758621 ], [ 29.809617721518986, -1.452664482758621 ], [ 29.809617721518986, -1.452395 ], [ 29.809348227848101, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20432, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.452395 ], [ 29.809617721518986, -1.452664482758621 ], [ 29.809887215189875, -1.452664482758621 ], [ 29.809887215189875, -1.452395 ], [ 29.809617721518986, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20433, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.452395 ], [ 29.809887215189875, -1.452664482758621 ], [ 29.811504177215191, -1.452664482758621 ], [ 29.811504177215191, -1.452933965517242 ], [ 29.811773670886076, -1.452933965517242 ], [ 29.811773670886076, -1.452395 ], [ 29.809887215189875, -1.452395 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20434, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.452664482758621 ], [ 29.754910506329114, -1.453203448275862 ], [ 29.75518, -1.453203448275862 ], [ 29.75518, -1.452664482758621 ], [ 29.754910506329114, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20435, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.452664482758621 ], [ 29.755718987341773, -1.452933965517242 ], [ 29.755988481012658, -1.452933965517242 ], [ 29.755988481012658, -1.452664482758621 ], [ 29.755718987341773, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20436, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.452664482758621 ], [ 29.768385189873417, -1.452933965517242 ], [ 29.768654683544302, -1.452933965517242 ], [ 29.768654683544302, -1.452664482758621 ], [ 29.768385189873417, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20437, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.452664482758621 ], [ 29.768654683544302, -1.452933965517242 ], [ 29.76892417721519, -1.452933965517242 ], [ 29.76892417721519, -1.452664482758621 ], [ 29.768654683544302, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20438, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.452664482758621 ], [ 29.76892417721519, -1.452933965517242 ], [ 29.769193670886075, -1.452933965517242 ], [ 29.769193670886075, -1.452664482758621 ], [ 29.76892417721519, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20439, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.452664482758621 ], [ 29.769193670886075, -1.452933965517242 ], [ 29.76946316455696, -1.452933965517242 ], [ 29.76946316455696, -1.452664482758621 ], [ 29.769193670886075, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20440, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.452664482758621 ], [ 29.76946316455696, -1.452933965517242 ], [ 29.769732658227849, -1.452933965517242 ], [ 29.769732658227849, -1.452664482758621 ], [ 29.76946316455696, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20441, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.452664482758621 ], [ 29.769732658227849, -1.452933965517242 ], [ 29.770002151898733, -1.452933965517242 ], [ 29.770002151898733, -1.452664482758621 ], [ 29.769732658227849, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20442, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.452664482758621 ], [ 29.770002151898733, -1.452933965517242 ], [ 29.770271645569618, -1.452933965517242 ], [ 29.770271645569618, -1.452664482758621 ], [ 29.770002151898733, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20443, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.452664482758621 ], [ 29.770271645569618, -1.452933965517242 ], [ 29.770541139240507, -1.452933965517242 ], [ 29.770541139240507, -1.452664482758621 ], [ 29.770271645569618, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20444, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.452664482758621 ], [ 29.770541139240507, -1.452933965517242 ], [ 29.770810632911392, -1.452933965517242 ], [ 29.770810632911392, -1.452664482758621 ], [ 29.770541139240507, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20445, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.452664482758621 ], [ 29.770810632911392, -1.452933965517242 ], [ 29.771080126582277, -1.452933965517242 ], [ 29.771080126582277, -1.452664482758621 ], [ 29.770810632911392, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20446, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.452664482758621 ], [ 29.771080126582277, -1.452933965517242 ], [ 29.771349620253165, -1.452933965517242 ], [ 29.771349620253165, -1.452664482758621 ], [ 29.771080126582277, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20447, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.452664482758621 ], [ 29.771349620253165, -1.452933965517242 ], [ 29.77161911392405, -1.452933965517242 ], [ 29.77161911392405, -1.452664482758621 ], [ 29.771349620253165, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20448, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.452664482758621 ], [ 29.77161911392405, -1.453203448275862 ], [ 29.771888607594935, -1.453203448275862 ], [ 29.771888607594935, -1.452664482758621 ], [ 29.77161911392405, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20449, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.452664482758621 ], [ 29.771888607594935, -1.452933965517242 ], [ 29.772158101265823, -1.452933965517242 ], [ 29.772158101265823, -1.452664482758621 ], [ 29.771888607594935, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20450, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.452664482758621 ], [ 29.772158101265823, -1.452933965517242 ], [ 29.772427594936708, -1.452933965517242 ], [ 29.772427594936708, -1.452664482758621 ], [ 29.772158101265823, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20451, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.452664482758621 ], [ 29.772427594936708, -1.453203448275862 ], [ 29.772697088607593, -1.453203448275862 ], [ 29.772697088607593, -1.452664482758621 ], [ 29.772427594936708, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20452, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.452664482758621 ], [ 29.772966582278482, -1.452933965517242 ], [ 29.773236075949367, -1.452933965517242 ], [ 29.773236075949367, -1.452664482758621 ], [ 29.772966582278482, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20453, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.452664482758621 ], [ 29.773236075949367, -1.453203448275862 ], [ 29.773505569620252, -1.453203448275862 ], [ 29.773505569620252, -1.452664482758621 ], [ 29.773236075949367, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20454, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.452664482758621 ], [ 29.77377506329114, -1.453203448275862 ], [ 29.774044556962025, -1.453203448275862 ], [ 29.774044556962025, -1.452664482758621 ], [ 29.77377506329114, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20455, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.452664482758621 ], [ 29.774853037974683, -1.453203448275862 ], [ 29.775122531645568, -1.453203448275862 ], [ 29.775122531645568, -1.452664482758621 ], [ 29.774853037974683, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20456, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.452664482758621 ], [ 29.778356455696201, -1.452933965517242 ], [ 29.77862594936709, -1.452933965517242 ], [ 29.77862594936709, -1.452664482758621 ], [ 29.778356455696201, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20457, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.452664482758621 ], [ 29.77862594936709, -1.453203448275862 ], [ 29.778895443037975, -1.453203448275862 ], [ 29.778895443037975, -1.452664482758621 ], [ 29.77862594936709, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20458, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.452664482758621 ], [ 29.77916493670886, -1.452933965517242 ], [ 29.779434430379748, -1.452933965517242 ], [ 29.779434430379748, -1.452664482758621 ], [ 29.77916493670886, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20459, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.452664482758621 ], [ 29.779434430379748, -1.453203448275862 ], [ 29.779703924050633, -1.453203448275862 ], [ 29.779703924050633, -1.452664482758621 ], [ 29.779434430379748, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20460, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.452664482758621 ], [ 29.779973417721518, -1.452933965517242 ], [ 29.780242911392406, -1.452933965517242 ], [ 29.780242911392406, -1.452664482758621 ], [ 29.779973417721518, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20461, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.452664482758621 ], [ 29.780242911392406, -1.452933965517242 ], [ 29.780512405063291, -1.452933965517242 ], [ 29.780512405063291, -1.452664482758621 ], [ 29.780242911392406, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20462, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.452664482758621 ], [ 29.780781898734176, -1.452933965517242 ], [ 29.781051392405065, -1.452933965517242 ], [ 29.781051392405065, -1.452664482758621 ], [ 29.780781898734176, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20463, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.452664482758621 ], [ 29.781051392405065, -1.452933965517242 ], [ 29.78132088607595, -1.452933965517242 ], [ 29.78132088607595, -1.452664482758621 ], [ 29.781051392405065, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20464, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.452664482758621 ], [ 29.78132088607595, -1.453203448275862 ], [ 29.781590379746834, -1.453203448275862 ], [ 29.781590379746834, -1.452664482758621 ], [ 29.78132088607595, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20465, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.452664482758621 ], [ 29.781590379746834, -1.452933965517242 ], [ 29.781859873417723, -1.452933965517242 ], [ 29.781859873417723, -1.452664482758621 ], [ 29.781590379746834, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20466, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.452664482758621 ], [ 29.781859873417723, -1.452933965517242 ], [ 29.782129367088608, -1.452933965517242 ], [ 29.782129367088608, -1.452664482758621 ], [ 29.781859873417723, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20467, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.452664482758621 ], [ 29.782129367088608, -1.452933965517242 ], [ 29.782398860759493, -1.452933965517242 ], [ 29.782398860759493, -1.452664482758621 ], [ 29.782129367088608, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20468, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.452664482758621 ], [ 29.782668354430381, -1.452933965517242 ], [ 29.782937848101266, -1.452933965517242 ], [ 29.782937848101266, -1.452664482758621 ], [ 29.782668354430381, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20469, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.452664482758621 ], [ 29.782937848101266, -1.452933965517242 ], [ 29.783207341772151, -1.452933965517242 ], [ 29.783207341772151, -1.452664482758621 ], [ 29.782937848101266, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20470, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.452664482758621 ], [ 29.783207341772151, -1.452933965517242 ], [ 29.783476835443039, -1.452933965517242 ], [ 29.783476835443039, -1.452664482758621 ], [ 29.783207341772151, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20471, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.452664482758621 ], [ 29.783476835443039, -1.452933965517242 ], [ 29.784015822784809, -1.452933965517242 ], [ 29.784015822784809, -1.452664482758621 ], [ 29.783476835443039, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20472, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.452664482758621 ], [ 29.784015822784809, -1.452933965517242 ], [ 29.784285316455698, -1.452933965517242 ], [ 29.784285316455698, -1.452664482758621 ], [ 29.784015822784809, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20473, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.452664482758621 ], [ 29.784285316455698, -1.452933965517242 ], [ 29.784554810126583, -1.452933965517242 ], [ 29.784554810126583, -1.452664482758621 ], [ 29.784285316455698, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20474, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.452664482758621 ], [ 29.784554810126583, -1.452933965517242 ], [ 29.784824303797468, -1.452933965517242 ], [ 29.784824303797468, -1.452664482758621 ], [ 29.784554810126583, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20475, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.452664482758621 ], [ 29.784824303797468, -1.452933965517242 ], [ 29.785093797468356, -1.452933965517242 ], [ 29.785093797468356, -1.452664482758621 ], [ 29.784824303797468, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20476, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.452664482758621 ], [ 29.785093797468356, -1.452933965517242 ], [ 29.785363291139241, -1.452933965517242 ], [ 29.785363291139241, -1.452664482758621 ], [ 29.785093797468356, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20477, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.452664482758621 ], [ 29.785363291139241, -1.452933965517242 ], [ 29.785632784810126, -1.452933965517242 ], [ 29.785632784810126, -1.452664482758621 ], [ 29.785363291139241, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20478, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.452664482758621 ], [ 29.785632784810126, -1.452933965517242 ], [ 29.785902278481014, -1.452933965517242 ], [ 29.785902278481014, -1.452664482758621 ], [ 29.785632784810126, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20479, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.452664482758621 ], [ 29.785902278481014, -1.452933965517242 ], [ 29.786171772151899, -1.452933965517242 ], [ 29.786171772151899, -1.452664482758621 ], [ 29.785902278481014, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20480, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.452664482758621 ], [ 29.786171772151899, -1.454550862068966 ], [ 29.786441265822784, -1.454550862068966 ], [ 29.786441265822784, -1.452664482758621 ], [ 29.786171772151899, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20481, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.452664482758621 ], [ 29.786441265822784, -1.454550862068966 ], [ 29.786710759493673, -1.454550862068966 ], [ 29.786710759493673, -1.452664482758621 ], [ 29.786441265822784, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20482, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.452664482758621 ], [ 29.786710759493673, -1.454281379310345 ], [ 29.786980253164558, -1.454281379310345 ], [ 29.786980253164558, -1.452664482758621 ], [ 29.786710759493673, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20483, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.452664482758621 ], [ 29.790753164556961, -1.453203448275862 ], [ 29.791022658227849, -1.453203448275862 ], [ 29.791022658227849, -1.452664482758621 ], [ 29.790753164556961, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20484, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.452664482758621 ], [ 29.791022658227849, -1.452933965517242 ], [ 29.791292151898734, -1.452933965517242 ], [ 29.791292151898734, -1.452664482758621 ], [ 29.791022658227849, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20485, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.452664482758621 ], [ 29.791292151898734, -1.452933965517242 ], [ 29.791561645569619, -1.452933965517242 ], [ 29.791561645569619, -1.452664482758621 ], [ 29.791292151898734, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20486, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.452664482758621 ], [ 29.791561645569619, -1.452933965517242 ], [ 29.791831139240507, -1.452933965517242 ], [ 29.791831139240507, -1.452664482758621 ], [ 29.791561645569619, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20487, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.452664482758621 ], [ 29.791831139240507, -1.452933965517242 ], [ 29.792100632911392, -1.452933965517242 ], [ 29.792100632911392, -1.452664482758621 ], [ 29.791831139240507, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20488, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.452664482758621 ], [ 29.792100632911392, -1.452933965517242 ], [ 29.792370126582277, -1.452933965517242 ], [ 29.792370126582277, -1.452664482758621 ], [ 29.792100632911392, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20489, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.452664482758621 ], [ 29.792370126582277, -1.452933965517242 ], [ 29.792909113924051, -1.452933965517242 ], [ 29.792909113924051, -1.452664482758621 ], [ 29.792370126582277, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20490, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.452664482758621 ], [ 29.792909113924051, -1.452933965517242 ], [ 29.793178607594935, -1.452933965517242 ], [ 29.793178607594935, -1.452664482758621 ], [ 29.792909113924051, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20491, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.452664482758621 ], [ 29.793178607594935, -1.452933965517242 ], [ 29.793448101265824, -1.452933965517242 ], [ 29.793448101265824, -1.452664482758621 ], [ 29.793178607594935, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20492, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.452664482758621 ], [ 29.793448101265824, -1.452933965517242 ], [ 29.793717594936709, -1.452933965517242 ], [ 29.793717594936709, -1.452664482758621 ], [ 29.793448101265824, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20493, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.452664482758621 ], [ 29.793717594936709, -1.452933965517242 ], [ 29.793987088607594, -1.452933965517242 ], [ 29.793987088607594, -1.452664482758621 ], [ 29.793717594936709, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20494, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.452664482758621 ], [ 29.793987088607594, -1.452933965517242 ], [ 29.794256582278482, -1.452933965517242 ], [ 29.794256582278482, -1.452664482758621 ], [ 29.793987088607594, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20495, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.452664482758621 ], [ 29.794256582278482, -1.452933965517242 ], [ 29.794526075949367, -1.452933965517242 ], [ 29.794526075949367, -1.452664482758621 ], [ 29.794256582278482, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20496, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.452664482758621 ], [ 29.794526075949367, -1.452933965517242 ], [ 29.794795569620252, -1.452933965517242 ], [ 29.794795569620252, -1.452664482758621 ], [ 29.794526075949367, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20497, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.452664482758621 ], [ 29.794795569620252, -1.452933965517242 ], [ 29.79506506329114, -1.452933965517242 ], [ 29.79506506329114, -1.452664482758621 ], [ 29.794795569620252, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20498, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.452664482758621 ], [ 29.795334556962025, -1.452933965517242 ], [ 29.79560405063291, -1.452933965517242 ], [ 29.79560405063291, -1.452664482758621 ], [ 29.795334556962025, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20499, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.452664482758621 ], [ 29.79560405063291, -1.452933965517242 ], [ 29.795873544303799, -1.452933965517242 ], [ 29.795873544303799, -1.452664482758621 ], [ 29.79560405063291, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20500, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.452664482758621 ], [ 29.795873544303799, -1.452933965517242 ], [ 29.796143037974684, -1.452933965517242 ], [ 29.796143037974684, -1.452664482758621 ], [ 29.795873544303799, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20501, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.452664482758621 ], [ 29.796143037974684, -1.453203448275862 ], [ 29.796412531645569, -1.453203448275862 ], [ 29.796412531645569, -1.452664482758621 ], [ 29.796143037974684, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20502, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.452664482758621 ], [ 29.796412531645569, -1.452933965517242 ], [ 29.796682025316457, -1.452933965517242 ], [ 29.796682025316457, -1.452664482758621 ], [ 29.796412531645569, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20503, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.452664482758621 ], [ 29.796682025316457, -1.452933965517242 ], [ 29.796951518987342, -1.452933965517242 ], [ 29.796951518987342, -1.452664482758621 ], [ 29.796682025316457, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20504, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.452664482758621 ], [ 29.796951518987342, -1.453742413793103 ], [ 29.797221012658227, -1.453742413793103 ], [ 29.797221012658227, -1.452664482758621 ], [ 29.796951518987342, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20505, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.452664482758621 ], [ 29.797221012658227, -1.453742413793103 ], [ 29.797490506329115, -1.453742413793103 ], [ 29.797490506329115, -1.452664482758621 ], [ 29.797221012658227, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20506, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80530582278481, -1.452664482758621 ], [ 29.80530582278481, -1.452933965517242 ], [ 29.805575316455698, -1.452933965517242 ], [ 29.805575316455698, -1.452664482758621 ], [ 29.80530582278481, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20507, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.452664482758621 ], [ 29.805575316455698, -1.452933965517242 ], [ 29.805844810126583, -1.452933965517242 ], [ 29.805844810126583, -1.452664482758621 ], [ 29.805575316455698, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20508, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.452664482758621 ], [ 29.805844810126583, -1.452933965517242 ], [ 29.806114303797468, -1.452933965517242 ], [ 29.806114303797468, -1.452664482758621 ], [ 29.805844810126583, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20509, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.452664482758621 ], [ 29.806114303797468, -1.452933965517242 ], [ 29.806383797468357, -1.452933965517242 ], [ 29.806383797468357, -1.452664482758621 ], [ 29.806114303797468, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20510, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806383797468357, -1.452664482758621 ], [ 29.806383797468357, -1.454550862068966 ], [ 29.8074617721519, -1.454550862068966 ], [ 29.8074617721519, -1.454281379310345 ], [ 29.806653291139241, -1.454281379310345 ], [ 29.806653291139241, -1.452664482758621 ], [ 29.806383797468357, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20511, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806653291139241, -1.452664482758621 ], [ 29.806653291139241, -1.454281379310345 ], [ 29.8074617721519, -1.454281379310345 ], [ 29.8074617721519, -1.454011896551724 ], [ 29.806922784810126, -1.454011896551724 ], [ 29.806922784810126, -1.452664482758621 ], [ 29.806653291139241, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20512, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.452664482758621 ], [ 29.807192278481015, -1.453742413793103 ], [ 29.8074617721519, -1.453742413793103 ], [ 29.8074617721519, -1.452664482758621 ], [ 29.807192278481015, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20513, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.452664482758621 ], [ 29.8074617721519, -1.453203448275862 ], [ 29.807731265822785, -1.453203448275862 ], [ 29.807731265822785, -1.452664482758621 ], [ 29.8074617721519, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20514, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.452664482758621 ], [ 29.808000759493673, -1.452933965517242 ], [ 29.808270253164558, -1.452933965517242 ], [ 29.808270253164558, -1.452664482758621 ], [ 29.808000759493673, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20515, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.452664482758621 ], [ 29.808270253164558, -1.453203448275862 ], [ 29.808809240506328, -1.453203448275862 ], [ 29.808809240506328, -1.452933965517242 ], [ 29.808539746835443, -1.452933965517242 ], [ 29.808539746835443, -1.452664482758621 ], [ 29.808270253164558, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20516, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.452664482758621 ], [ 29.808539746835443, -1.452933965517242 ], [ 29.808809240506328, -1.452933965517242 ], [ 29.808809240506328, -1.452664482758621 ], [ 29.808539746835443, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20517, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.452664482758621 ], [ 29.808809240506328, -1.452933965517242 ], [ 29.809078734177216, -1.452933965517242 ], [ 29.809078734177216, -1.452664482758621 ], [ 29.808809240506328, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20518, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.452664482758621 ], [ 29.809078734177216, -1.452933965517242 ], [ 29.809348227848101, -1.452933965517242 ], [ 29.809348227848101, -1.452664482758621 ], [ 29.809078734177216, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20519, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.452664482758621 ], [ 29.809348227848101, -1.452933965517242 ], [ 29.809617721518986, -1.452933965517242 ], [ 29.809617721518986, -1.452664482758621 ], [ 29.809348227848101, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20520, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.452664482758621 ], [ 29.809617721518986, -1.452933965517242 ], [ 29.81015670886076, -1.452933965517242 ], [ 29.81015670886076, -1.452664482758621 ], [ 29.809617721518986, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20521, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.452664482758621 ], [ 29.81015670886076, -1.452933965517242 ], [ 29.811504177215191, -1.452933965517242 ], [ 29.811504177215191, -1.452664482758621 ], [ 29.81015670886076, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20522, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.452664482758621 ], [ 29.812043164556961, -1.452933965517242 ], [ 29.812312658227849, -1.452933965517242 ], [ 29.812312658227849, -1.452664482758621 ], [ 29.812043164556961, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20523, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.452664482758621 ], [ 29.812312658227849, -1.452933965517242 ], [ 29.812582151898734, -1.452933965517242 ], [ 29.812582151898734, -1.452664482758621 ], [ 29.812312658227849, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20524, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.452664482758621 ], [ 29.812582151898734, -1.452933965517242 ], [ 29.812851645569619, -1.452933965517242 ], [ 29.812851645569619, -1.452664482758621 ], [ 29.812582151898734, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20525, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.452664482758621 ], [ 29.812851645569619, -1.454011896551724 ], [ 29.813121139240508, -1.454011896551724 ], [ 29.813121139240508, -1.452664482758621 ], [ 29.812851645569619, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20526, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.452933965517242 ], [ 29.753563037974683, -1.453203448275862 ], [ 29.753832531645568, -1.453203448275862 ], [ 29.753832531645568, -1.452933965517242 ], [ 29.753563037974683, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20527, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.452933965517242 ], [ 29.754371518987341, -1.453203448275862 ], [ 29.754641012658226, -1.453203448275862 ], [ 29.754641012658226, -1.452933965517242 ], [ 29.754371518987341, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20528, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.452933965517242 ], [ 29.754641012658226, -1.453203448275862 ], [ 29.754910506329114, -1.453203448275862 ], [ 29.754910506329114, -1.452933965517242 ], [ 29.754641012658226, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20529, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.452933965517242 ], [ 29.75518, -1.453203448275862 ], [ 29.755449493670884, -1.453203448275862 ], [ 29.755449493670884, -1.452933965517242 ], [ 29.75518, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20530, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.452933965517242 ], [ 29.755449493670884, -1.453203448275862 ], [ 29.755718987341773, -1.453203448275862 ], [ 29.755718987341773, -1.452933965517242 ], [ 29.755449493670884, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20531, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.452933965517242 ], [ 29.755718987341773, -1.453203448275862 ], [ 29.755988481012658, -1.453203448275862 ], [ 29.755988481012658, -1.452933965517242 ], [ 29.755718987341773, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20532, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.452933965517242 ], [ 29.755988481012658, -1.453203448275862 ], [ 29.756257974683542, -1.453203448275862 ], [ 29.756257974683542, -1.452933965517242 ], [ 29.755988481012658, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20533, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.452933965517242 ], [ 29.768654683544302, -1.453203448275862 ], [ 29.76892417721519, -1.453203448275862 ], [ 29.76892417721519, -1.452933965517242 ], [ 29.768654683544302, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20534, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.452933965517242 ], [ 29.76892417721519, -1.453203448275862 ], [ 29.769193670886075, -1.453203448275862 ], [ 29.769193670886075, -1.452933965517242 ], [ 29.76892417721519, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20535, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.452933965517242 ], [ 29.769193670886075, -1.453203448275862 ], [ 29.76946316455696, -1.453203448275862 ], [ 29.76946316455696, -1.452933965517242 ], [ 29.769193670886075, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20536, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.452933965517242 ], [ 29.76946316455696, -1.453203448275862 ], [ 29.769732658227849, -1.453203448275862 ], [ 29.769732658227849, -1.452933965517242 ], [ 29.76946316455696, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20537, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.452933965517242 ], [ 29.769732658227849, -1.453203448275862 ], [ 29.770002151898733, -1.453203448275862 ], [ 29.770002151898733, -1.452933965517242 ], [ 29.769732658227849, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20538, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.452933965517242 ], [ 29.770002151898733, -1.453203448275862 ], [ 29.770271645569618, -1.453203448275862 ], [ 29.770271645569618, -1.452933965517242 ], [ 29.770002151898733, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20539, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.452933965517242 ], [ 29.770271645569618, -1.453203448275862 ], [ 29.770541139240507, -1.453203448275862 ], [ 29.770541139240507, -1.452933965517242 ], [ 29.770271645569618, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20540, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.452933965517242 ], [ 29.770541139240507, -1.453742413793103 ], [ 29.770810632911392, -1.453742413793103 ], [ 29.770810632911392, -1.452933965517242 ], [ 29.770541139240507, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20541, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.452933965517242 ], [ 29.770810632911392, -1.453203448275862 ], [ 29.771080126582277, -1.453203448275862 ], [ 29.771080126582277, -1.452933965517242 ], [ 29.770810632911392, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20542, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.452933965517242 ], [ 29.771080126582277, -1.453203448275862 ], [ 29.771349620253165, -1.453203448275862 ], [ 29.771349620253165, -1.452933965517242 ], [ 29.771080126582277, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20543, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.452933965517242 ], [ 29.771349620253165, -1.454550862068966 ], [ 29.77161911392405, -1.454550862068966 ], [ 29.77161911392405, -1.452933965517242 ], [ 29.771349620253165, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20544, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.452933965517242 ], [ 29.771888607594935, -1.453742413793103 ], [ 29.772158101265823, -1.453742413793103 ], [ 29.772158101265823, -1.452933965517242 ], [ 29.771888607594935, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20545, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.452933965517242 ], [ 29.772158101265823, -1.454550862068966 ], [ 29.772427594936708, -1.454550862068966 ], [ 29.772427594936708, -1.452933965517242 ], [ 29.772158101265823, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20546, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.452933965517242 ], [ 29.772697088607593, -1.454550862068966 ], [ 29.772966582278482, -1.454550862068966 ], [ 29.772966582278482, -1.452933965517242 ], [ 29.772697088607593, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20547, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.452933965517242 ], [ 29.772966582278482, -1.454550862068966 ], [ 29.773236075949367, -1.454550862068966 ], [ 29.773236075949367, -1.452933965517242 ], [ 29.772966582278482, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20548, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.452933965517242 ], [ 29.773505569620252, -1.454550862068966 ], [ 29.77377506329114, -1.454550862068966 ], [ 29.77377506329114, -1.452933965517242 ], [ 29.773505569620252, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20549, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.452933965517242 ], [ 29.77431405063291, -1.454550862068966 ], [ 29.774853037974683, -1.454550862068966 ], [ 29.774853037974683, -1.452933965517242 ], [ 29.77431405063291, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20550, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.452933965517242 ], [ 29.775392025316457, -1.454550862068966 ], [ 29.775661518987341, -1.454550862068966 ], [ 29.775661518987341, -1.452933965517242 ], [ 29.775392025316457, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20551, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.452933965517242 ], [ 29.775931012658226, -1.454550862068966 ], [ 29.776200506329115, -1.454550862068966 ], [ 29.776200506329115, -1.452933965517242 ], [ 29.775931012658226, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20552, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.452933965517242 ], [ 29.776739493670885, -1.454550862068966 ], [ 29.777008987341773, -1.454550862068966 ], [ 29.777008987341773, -1.452933965517242 ], [ 29.776739493670885, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20553, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.452933965517242 ], [ 29.778086962025316, -1.454550862068966 ], [ 29.778356455696201, -1.454550862068966 ], [ 29.778356455696201, -1.452933965517242 ], [ 29.778086962025316, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20554, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.452933965517242 ], [ 29.778356455696201, -1.453742413793103 ], [ 29.77862594936709, -1.453742413793103 ], [ 29.77862594936709, -1.452933965517242 ], [ 29.778356455696201, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20555, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.452933965517242 ], [ 29.778895443037975, -1.453203448275862 ], [ 29.77916493670886, -1.453203448275862 ], [ 29.77916493670886, -1.452933965517242 ], [ 29.778895443037975, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20556, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.452933965517242 ], [ 29.77916493670886, -1.453203448275862 ], [ 29.779434430379748, -1.453203448275862 ], [ 29.779434430379748, -1.452933965517242 ], [ 29.77916493670886, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20557, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.452933965517242 ], [ 29.779703924050633, -1.453203448275862 ], [ 29.779973417721518, -1.453203448275862 ], [ 29.779973417721518, -1.452933965517242 ], [ 29.779703924050633, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20558, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.452933965517242 ], [ 29.779973417721518, -1.453203448275862 ], [ 29.780242911392406, -1.453203448275862 ], [ 29.780242911392406, -1.452933965517242 ], [ 29.779973417721518, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20559, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.452933965517242 ], [ 29.780242911392406, -1.453742413793103 ], [ 29.780512405063291, -1.453742413793103 ], [ 29.780512405063291, -1.452933965517242 ], [ 29.780242911392406, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20560, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.452933965517242 ], [ 29.780512405063291, -1.453203448275862 ], [ 29.780781898734176, -1.453203448275862 ], [ 29.780781898734176, -1.452933965517242 ], [ 29.780512405063291, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20561, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.452933965517242 ], [ 29.780781898734176, -1.453203448275862 ], [ 29.781051392405065, -1.453203448275862 ], [ 29.781051392405065, -1.452933965517242 ], [ 29.780781898734176, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20562, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.452933965517242 ], [ 29.781051392405065, -1.453203448275862 ], [ 29.78132088607595, -1.453203448275862 ], [ 29.78132088607595, -1.452933965517242 ], [ 29.781051392405065, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20563, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.452933965517242 ], [ 29.781590379746834, -1.453203448275862 ], [ 29.781859873417723, -1.453203448275862 ], [ 29.781859873417723, -1.452933965517242 ], [ 29.781590379746834, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20564, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.452933965517242 ], [ 29.781859873417723, -1.453203448275862 ], [ 29.782129367088608, -1.453203448275862 ], [ 29.782129367088608, -1.452933965517242 ], [ 29.781859873417723, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20565, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.452933965517242 ], [ 29.782129367088608, -1.453203448275862 ], [ 29.782398860759493, -1.453203448275862 ], [ 29.782398860759493, -1.452933965517242 ], [ 29.782129367088608, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20566, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.452933965517242 ], [ 29.782398860759493, -1.453203448275862 ], [ 29.782668354430381, -1.453203448275862 ], [ 29.782668354430381, -1.452933965517242 ], [ 29.782398860759493, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20567, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.452933965517242 ], [ 29.782668354430381, -1.453203448275862 ], [ 29.782937848101266, -1.453203448275862 ], [ 29.782937848101266, -1.452933965517242 ], [ 29.782668354430381, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20568, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.452933965517242 ], [ 29.782937848101266, -1.453203448275862 ], [ 29.783207341772151, -1.453203448275862 ], [ 29.783207341772151, -1.452933965517242 ], [ 29.782937848101266, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20569, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.452933965517242 ], [ 29.783207341772151, -1.453203448275862 ], [ 29.783476835443039, -1.453203448275862 ], [ 29.783476835443039, -1.452933965517242 ], [ 29.783207341772151, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20570, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.452933965517242 ], [ 29.783476835443039, -1.453203448275862 ], [ 29.784015822784809, -1.453203448275862 ], [ 29.784015822784809, -1.452933965517242 ], [ 29.783476835443039, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20571, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.452933965517242 ], [ 29.784015822784809, -1.453203448275862 ], [ 29.784285316455698, -1.453203448275862 ], [ 29.784285316455698, -1.452933965517242 ], [ 29.784015822784809, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20572, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.452933965517242 ], [ 29.784285316455698, -1.453203448275862 ], [ 29.784554810126583, -1.453203448275862 ], [ 29.784554810126583, -1.452933965517242 ], [ 29.784285316455698, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20573, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.452933965517242 ], [ 29.784554810126583, -1.453203448275862 ], [ 29.784824303797468, -1.453203448275862 ], [ 29.784824303797468, -1.452933965517242 ], [ 29.784554810126583, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20574, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.452933965517242 ], [ 29.784824303797468, -1.453203448275862 ], [ 29.785093797468356, -1.453203448275862 ], [ 29.785093797468356, -1.452933965517242 ], [ 29.784824303797468, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20575, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.452933965517242 ], [ 29.785093797468356, -1.453203448275862 ], [ 29.785363291139241, -1.453203448275862 ], [ 29.785363291139241, -1.452933965517242 ], [ 29.785093797468356, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20576, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.452933965517242 ], [ 29.785363291139241, -1.453203448275862 ], [ 29.785632784810126, -1.453203448275862 ], [ 29.785632784810126, -1.452933965517242 ], [ 29.785363291139241, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20577, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.452933965517242 ], [ 29.785632784810126, -1.454550862068966 ], [ 29.785902278481014, -1.454550862068966 ], [ 29.785902278481014, -1.452933965517242 ], [ 29.785632784810126, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20578, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.452933965517242 ], [ 29.791022658227849, -1.453203448275862 ], [ 29.791292151898734, -1.453203448275862 ], [ 29.791292151898734, -1.452933965517242 ], [ 29.791022658227849, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20579, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.452933965517242 ], [ 29.791292151898734, -1.453203448275862 ], [ 29.791561645569619, -1.453203448275862 ], [ 29.791561645569619, -1.452933965517242 ], [ 29.791292151898734, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20580, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.452933965517242 ], [ 29.791561645569619, -1.453203448275862 ], [ 29.791831139240507, -1.453203448275862 ], [ 29.791831139240507, -1.452933965517242 ], [ 29.791561645569619, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20581, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.452933965517242 ], [ 29.791831139240507, -1.453203448275862 ], [ 29.792100632911392, -1.453203448275862 ], [ 29.792100632911392, -1.452933965517242 ], [ 29.791831139240507, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20582, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.452933965517242 ], [ 29.792100632911392, -1.453203448275862 ], [ 29.792370126582277, -1.453203448275862 ], [ 29.792370126582277, -1.452933965517242 ], [ 29.792100632911392, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20583, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.452933965517242 ], [ 29.792370126582277, -1.453203448275862 ], [ 29.792909113924051, -1.453203448275862 ], [ 29.792909113924051, -1.452933965517242 ], [ 29.792370126582277, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20584, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.452933965517242 ], [ 29.792909113924051, -1.453203448275862 ], [ 29.793178607594935, -1.453203448275862 ], [ 29.793178607594935, -1.452933965517242 ], [ 29.792909113924051, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20585, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.452933965517242 ], [ 29.793178607594935, -1.453203448275862 ], [ 29.793448101265824, -1.453203448275862 ], [ 29.793448101265824, -1.452933965517242 ], [ 29.793178607594935, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20586, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.452933965517242 ], [ 29.793448101265824, -1.453203448275862 ], [ 29.793717594936709, -1.453203448275862 ], [ 29.793717594936709, -1.452933965517242 ], [ 29.793448101265824, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20587, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.452933965517242 ], [ 29.793717594936709, -1.453203448275862 ], [ 29.793987088607594, -1.453203448275862 ], [ 29.793987088607594, -1.452933965517242 ], [ 29.793717594936709, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20588, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.452933965517242 ], [ 29.793987088607594, -1.453742413793103 ], [ 29.794256582278482, -1.453742413793103 ], [ 29.794256582278482, -1.452933965517242 ], [ 29.793987088607594, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20589, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.452933965517242 ], [ 29.794256582278482, -1.453203448275862 ], [ 29.794526075949367, -1.453203448275862 ], [ 29.794526075949367, -1.452933965517242 ], [ 29.794256582278482, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20590, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.452933965517242 ], [ 29.794526075949367, -1.453203448275862 ], [ 29.794795569620252, -1.453203448275862 ], [ 29.794795569620252, -1.452933965517242 ], [ 29.794526075949367, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20591, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.452933965517242 ], [ 29.794795569620252, -1.453742413793103 ], [ 29.79506506329114, -1.453742413793103 ], [ 29.79506506329114, -1.452933965517242 ], [ 29.794795569620252, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20592, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.452933965517242 ], [ 29.795334556962025, -1.453203448275862 ], [ 29.79560405063291, -1.453203448275862 ], [ 29.79560405063291, -1.452933965517242 ], [ 29.795334556962025, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20593, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.452933965517242 ], [ 29.79560405063291, -1.453742413793103 ], [ 29.795873544303799, -1.453742413793103 ], [ 29.795873544303799, -1.452933965517242 ], [ 29.79560405063291, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20594, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.452933965517242 ], [ 29.795873544303799, -1.454011896551724 ], [ 29.796143037974684, -1.454011896551724 ], [ 29.796143037974684, -1.452933965517242 ], [ 29.795873544303799, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20595, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.452933965517242 ], [ 29.796412531645569, -1.453742413793103 ], [ 29.796682025316457, -1.453742413793103 ], [ 29.796682025316457, -1.452933965517242 ], [ 29.796412531645569, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20596, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.452933965517242 ], [ 29.796682025316457, -1.454011896551724 ], [ 29.796951518987342, -1.454011896551724 ], [ 29.796951518987342, -1.452933965517242 ], [ 29.796682025316457, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20597, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806922784810126, -1.452933965517242 ], [ 29.806922784810126, -1.454011896551724 ], [ 29.807192278481015, -1.454011896551724 ], [ 29.807192278481015, -1.452933965517242 ], [ 29.806922784810126, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20598, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.452933965517242 ], [ 29.807731265822785, -1.453203448275862 ], [ 29.808000759493673, -1.453203448275862 ], [ 29.808000759493673, -1.452933965517242 ], [ 29.807731265822785, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20599, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.452933965517242 ], [ 29.808000759493673, -1.453203448275862 ], [ 29.808270253164558, -1.453203448275862 ], [ 29.808270253164558, -1.452933965517242 ], [ 29.808000759493673, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20600, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.452933965517242 ], [ 29.808809240506328, -1.453203448275862 ], [ 29.809078734177216, -1.453203448275862 ], [ 29.809078734177216, -1.452933965517242 ], [ 29.808809240506328, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20601, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.452933965517242 ], [ 29.809078734177216, -1.453203448275862 ], [ 29.809348227848101, -1.453203448275862 ], [ 29.809348227848101, -1.452933965517242 ], [ 29.809078734177216, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20602, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.452933965517242 ], [ 29.809348227848101, -1.453203448275862 ], [ 29.809617721518986, -1.453203448275862 ], [ 29.809617721518986, -1.452933965517242 ], [ 29.809348227848101, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20603, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.452933965517242 ], [ 29.809617721518986, -1.453203448275862 ], [ 29.809887215189875, -1.453203448275862 ], [ 29.809887215189875, -1.452933965517242 ], [ 29.809617721518986, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20604, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.452933965517242 ], [ 29.809887215189875, -1.453203448275862 ], [ 29.811234683544303, -1.453203448275862 ], [ 29.811234683544303, -1.452933965517242 ], [ 29.809887215189875, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20605, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.452933965517242 ], [ 29.811234683544303, -1.453203448275862 ], [ 29.811504177215191, -1.453203448275862 ], [ 29.811504177215191, -1.452933965517242 ], [ 29.811234683544303, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20606, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.452933965517242 ], [ 29.811504177215191, -1.453203448275862 ], [ 29.811773670886076, -1.453203448275862 ], [ 29.811773670886076, -1.452933965517242 ], [ 29.811504177215191, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20607, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.452933965517242 ], [ 29.811773670886076, -1.453203448275862 ], [ 29.812043164556961, -1.453203448275862 ], [ 29.812043164556961, -1.452933965517242 ], [ 29.811773670886076, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20608, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.452933965517242 ], [ 29.812043164556961, -1.453203448275862 ], [ 29.812312658227849, -1.453203448275862 ], [ 29.812312658227849, -1.452933965517242 ], [ 29.812043164556961, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20609, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.452933965517242 ], [ 29.812312658227849, -1.454011896551724 ], [ 29.812582151898734, -1.454011896551724 ], [ 29.812582151898734, -1.452933965517242 ], [ 29.812312658227849, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20610, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.452933965517242 ], [ 29.812582151898734, -1.454011896551724 ], [ 29.812851645569619, -1.454011896551724 ], [ 29.812851645569619, -1.452933965517242 ], [ 29.812582151898734, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20611, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.453203448275862 ], [ 29.752215569620251, -1.453742413793103 ], [ 29.75248506329114, -1.453742413793103 ], [ 29.75248506329114, -1.453203448275862 ], [ 29.752215569620251, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20612, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.453203448275862 ], [ 29.75248506329114, -1.453742413793103 ], [ 29.752754556962024, -1.453742413793103 ], [ 29.752754556962024, -1.453203448275862 ], [ 29.75248506329114, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20613, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.453203448275862 ], [ 29.753024050632909, -1.453742413793103 ], [ 29.753293544303798, -1.453742413793103 ], [ 29.753293544303798, -1.453203448275862 ], [ 29.753024050632909, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20614, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.453203448275862 ], [ 29.753293544303798, -1.453742413793103 ], [ 29.753563037974683, -1.453742413793103 ], [ 29.753563037974683, -1.453203448275862 ], [ 29.753293544303798, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20615, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.453203448275862 ], [ 29.753563037974683, -1.453742413793103 ], [ 29.753832531645568, -1.453742413793103 ], [ 29.753832531645568, -1.453203448275862 ], [ 29.753563037974683, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20616, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.453203448275862 ], [ 29.753832531645568, -1.454011896551724 ], [ 29.754102025316456, -1.454011896551724 ], [ 29.754102025316456, -1.453203448275862 ], [ 29.753832531645568, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20617, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.453203448275862 ], [ 29.754102025316456, -1.453742413793103 ], [ 29.754371518987341, -1.453742413793103 ], [ 29.754371518987341, -1.453203448275862 ], [ 29.754102025316456, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20618, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.453203448275862 ], [ 29.754371518987341, -1.453742413793103 ], [ 29.754641012658226, -1.453742413793103 ], [ 29.754641012658226, -1.453203448275862 ], [ 29.754371518987341, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20619, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.453203448275862 ], [ 29.754641012658226, -1.453742413793103 ], [ 29.754910506329114, -1.453742413793103 ], [ 29.754910506329114, -1.453203448275862 ], [ 29.754641012658226, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20620, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.453203448275862 ], [ 29.754910506329114, -1.453742413793103 ], [ 29.75518, -1.453742413793103 ], [ 29.75518, -1.453203448275862 ], [ 29.754910506329114, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20621, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.453203448275862 ], [ 29.75518, -1.453742413793103 ], [ 29.755449493670884, -1.453742413793103 ], [ 29.755449493670884, -1.453203448275862 ], [ 29.75518, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20622, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.453203448275862 ], [ 29.755449493670884, -1.453742413793103 ], [ 29.755718987341773, -1.453742413793103 ], [ 29.755718987341773, -1.453203448275862 ], [ 29.755449493670884, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20623, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.453203448275862 ], [ 29.755718987341773, -1.453742413793103 ], [ 29.755988481012658, -1.453742413793103 ], [ 29.755988481012658, -1.453203448275862 ], [ 29.755718987341773, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20624, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.447813793103448 ], [ 29.756796962025316, -1.447813793103448 ], [ 29.756796962025316, -1.454550862068966 ], [ 29.756257974683542, -1.454550862068966 ], [ 29.756257974683542, -1.453742413793103 ], [ 29.755988481012658, -1.453742413793103 ], [ 29.755988481012658, -1.453203448275862 ], [ 29.756257974683542, -1.453203448275862 ], [ 29.756257974683542, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20625, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.453203448275862 ], [ 29.76892417721519, -1.453742413793103 ], [ 29.769193670886075, -1.453742413793103 ], [ 29.769193670886075, -1.453203448275862 ], [ 29.76892417721519, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20626, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.453203448275862 ], [ 29.769193670886075, -1.453742413793103 ], [ 29.76946316455696, -1.453742413793103 ], [ 29.76946316455696, -1.453203448275862 ], [ 29.769193670886075, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20627, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.453203448275862 ], [ 29.76946316455696, -1.453742413793103 ], [ 29.769732658227849, -1.453742413793103 ], [ 29.769732658227849, -1.453203448275862 ], [ 29.76946316455696, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20628, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.453203448275862 ], [ 29.769732658227849, -1.453742413793103 ], [ 29.770002151898733, -1.453742413793103 ], [ 29.770002151898733, -1.453203448275862 ], [ 29.769732658227849, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20629, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.453203448275862 ], [ 29.770002151898733, -1.454281379310345 ], [ 29.770271645569618, -1.454281379310345 ], [ 29.770271645569618, -1.453203448275862 ], [ 29.770002151898733, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20630, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.453203448275862 ], [ 29.770271645569618, -1.454281379310345 ], [ 29.770541139240507, -1.454281379310345 ], [ 29.770541139240507, -1.453203448275862 ], [ 29.770271645569618, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20631, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.453203448275862 ], [ 29.770810632911392, -1.454281379310345 ], [ 29.771080126582277, -1.454281379310345 ], [ 29.771080126582277, -1.453203448275862 ], [ 29.770810632911392, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20632, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.453203448275862 ], [ 29.771080126582277, -1.454281379310345 ], [ 29.771349620253165, -1.454281379310345 ], [ 29.771349620253165, -1.453203448275862 ], [ 29.771080126582277, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20633, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.453203448275862 ], [ 29.77161911392405, -1.454281379310345 ], [ 29.771888607594935, -1.454281379310345 ], [ 29.771888607594935, -1.453203448275862 ], [ 29.77161911392405, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20634, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.453203448275862 ], [ 29.772427594936708, -1.454281379310345 ], [ 29.772697088607593, -1.454281379310345 ], [ 29.772697088607593, -1.453203448275862 ], [ 29.772427594936708, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20635, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.453203448275862 ], [ 29.773236075949367, -1.454281379310345 ], [ 29.773505569620252, -1.454281379310345 ], [ 29.773505569620252, -1.453203448275862 ], [ 29.773236075949367, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20636, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.453203448275862 ], [ 29.77377506329114, -1.454281379310345 ], [ 29.774044556962025, -1.454281379310345 ], [ 29.774044556962025, -1.453203448275862 ], [ 29.77377506329114, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20637, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.453203448275862 ], [ 29.774853037974683, -1.454281379310345 ], [ 29.775122531645568, -1.454281379310345 ], [ 29.775122531645568, -1.453203448275862 ], [ 29.774853037974683, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20638, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.453203448275862 ], [ 29.776200506329115, -1.454281379310345 ], [ 29.77647, -1.454281379310345 ], [ 29.77647, -1.453203448275862 ], [ 29.776200506329115, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20639, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.453203448275862 ], [ 29.777547974683543, -1.454281379310345 ], [ 29.777817468354431, -1.454281379310345 ], [ 29.777817468354431, -1.453203448275862 ], [ 29.777547974683543, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20640, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.453203448275862 ], [ 29.77862594936709, -1.453742413793103 ], [ 29.778895443037975, -1.453742413793103 ], [ 29.778895443037975, -1.453203448275862 ], [ 29.77862594936709, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20641, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.453203448275862 ], [ 29.778895443037975, -1.453742413793103 ], [ 29.77916493670886, -1.453742413793103 ], [ 29.77916493670886, -1.453203448275862 ], [ 29.778895443037975, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20642, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.453203448275862 ], [ 29.77916493670886, -1.454011896551724 ], [ 29.779434430379748, -1.454011896551724 ], [ 29.779434430379748, -1.453203448275862 ], [ 29.77916493670886, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20643, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.453203448275862 ], [ 29.779434430379748, -1.453742413793103 ], [ 29.779703924050633, -1.453742413793103 ], [ 29.779703924050633, -1.453203448275862 ], [ 29.779434430379748, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20644, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.453203448275862 ], [ 29.779703924050633, -1.453742413793103 ], [ 29.779973417721518, -1.453742413793103 ], [ 29.779973417721518, -1.453203448275862 ], [ 29.779703924050633, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20645, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.453203448275862 ], [ 29.779973417721518, -1.453742413793103 ], [ 29.780242911392406, -1.453742413793103 ], [ 29.780242911392406, -1.453203448275862 ], [ 29.779973417721518, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20646, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.453203448275862 ], [ 29.780512405063291, -1.453742413793103 ], [ 29.780781898734176, -1.453742413793103 ], [ 29.780781898734176, -1.453203448275862 ], [ 29.780512405063291, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20647, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.453203448275862 ], [ 29.780781898734176, -1.453742413793103 ], [ 29.781051392405065, -1.453742413793103 ], [ 29.781051392405065, -1.453203448275862 ], [ 29.780781898734176, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20648, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.453203448275862 ], [ 29.781051392405065, -1.453742413793103 ], [ 29.78132088607595, -1.453742413793103 ], [ 29.78132088607595, -1.453203448275862 ], [ 29.781051392405065, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20649, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.453203448275862 ], [ 29.78132088607595, -1.453742413793103 ], [ 29.781590379746834, -1.453742413793103 ], [ 29.781590379746834, -1.453203448275862 ], [ 29.78132088607595, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20650, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.453203448275862 ], [ 29.781590379746834, -1.453742413793103 ], [ 29.781859873417723, -1.453742413793103 ], [ 29.781859873417723, -1.453203448275862 ], [ 29.781590379746834, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20651, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.453203448275862 ], [ 29.781859873417723, -1.453742413793103 ], [ 29.782129367088608, -1.453742413793103 ], [ 29.782129367088608, -1.453203448275862 ], [ 29.781859873417723, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20652, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.453203448275862 ], [ 29.782129367088608, -1.453742413793103 ], [ 29.782398860759493, -1.453742413793103 ], [ 29.782398860759493, -1.453203448275862 ], [ 29.782129367088608, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20653, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.453203448275862 ], [ 29.782398860759493, -1.453742413793103 ], [ 29.782668354430381, -1.453742413793103 ], [ 29.782668354430381, -1.453203448275862 ], [ 29.782398860759493, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20654, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.453203448275862 ], [ 29.782668354430381, -1.453742413793103 ], [ 29.782937848101266, -1.453742413793103 ], [ 29.782937848101266, -1.453203448275862 ], [ 29.782668354430381, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20655, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.453203448275862 ], [ 29.782937848101266, -1.453742413793103 ], [ 29.783207341772151, -1.453742413793103 ], [ 29.783207341772151, -1.453203448275862 ], [ 29.782937848101266, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20656, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.453203448275862 ], [ 29.783207341772151, -1.453742413793103 ], [ 29.783476835443039, -1.453742413793103 ], [ 29.783476835443039, -1.453203448275862 ], [ 29.783207341772151, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20657, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.453203448275862 ], [ 29.783476835443039, -1.453742413793103 ], [ 29.784015822784809, -1.453742413793103 ], [ 29.784015822784809, -1.453203448275862 ], [ 29.783476835443039, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20658, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.453203448275862 ], [ 29.784015822784809, -1.453742413793103 ], [ 29.784285316455698, -1.453742413793103 ], [ 29.784285316455698, -1.453203448275862 ], [ 29.784015822784809, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20659, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.453203448275862 ], [ 29.784285316455698, -1.453742413793103 ], [ 29.784554810126583, -1.453742413793103 ], [ 29.784554810126583, -1.453203448275862 ], [ 29.784285316455698, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20660, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.453203448275862 ], [ 29.784554810126583, -1.453742413793103 ], [ 29.784824303797468, -1.453742413793103 ], [ 29.784824303797468, -1.453203448275862 ], [ 29.784554810126583, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20661, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.453203448275862 ], [ 29.784824303797468, -1.454550862068966 ], [ 29.785093797468356, -1.454550862068966 ], [ 29.785093797468356, -1.453203448275862 ], [ 29.784824303797468, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20662, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.453203448275862 ], [ 29.785363291139241, -1.454281379310345 ], [ 29.785632784810126, -1.454281379310345 ], [ 29.785632784810126, -1.453203448275862 ], [ 29.785363291139241, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20663, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.453203448275862 ], [ 29.791022658227849, -1.454011896551724 ], [ 29.791292151898734, -1.454011896551724 ], [ 29.791292151898734, -1.453203448275862 ], [ 29.791022658227849, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20664, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.453203448275862 ], [ 29.791292151898734, -1.453742413793103 ], [ 29.791561645569619, -1.453742413793103 ], [ 29.791561645569619, -1.453203448275862 ], [ 29.791292151898734, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20665, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.453203448275862 ], [ 29.791561645569619, -1.453742413793103 ], [ 29.791831139240507, -1.453742413793103 ], [ 29.791831139240507, -1.453203448275862 ], [ 29.791561645569619, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20666, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.453203448275862 ], [ 29.791831139240507, -1.453742413793103 ], [ 29.792100632911392, -1.453742413793103 ], [ 29.792100632911392, -1.453203448275862 ], [ 29.791831139240507, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20667, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.453203448275862 ], [ 29.792100632911392, -1.453742413793103 ], [ 29.792370126582277, -1.453742413793103 ], [ 29.792370126582277, -1.453203448275862 ], [ 29.792100632911392, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20668, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.453203448275862 ], [ 29.792370126582277, -1.453742413793103 ], [ 29.792909113924051, -1.453742413793103 ], [ 29.792909113924051, -1.453203448275862 ], [ 29.792370126582277, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20669, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.453203448275862 ], [ 29.792909113924051, -1.454011896551724 ], [ 29.793178607594935, -1.454011896551724 ], [ 29.793178607594935, -1.453203448275862 ], [ 29.792909113924051, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20670, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.453203448275862 ], [ 29.793178607594935, -1.453742413793103 ], [ 29.793448101265824, -1.453742413793103 ], [ 29.793448101265824, -1.453203448275862 ], [ 29.793178607594935, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20671, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.453203448275862 ], [ 29.793448101265824, -1.453742413793103 ], [ 29.793717594936709, -1.453742413793103 ], [ 29.793717594936709, -1.453203448275862 ], [ 29.793448101265824, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20672, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.453203448275862 ], [ 29.794256582278482, -1.454011896551724 ], [ 29.794526075949367, -1.454011896551724 ], [ 29.794526075949367, -1.453203448275862 ], [ 29.794256582278482, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20673, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.453203448275862 ], [ 29.796143037974684, -1.454011896551724 ], [ 29.796412531645569, -1.454011896551724 ], [ 29.796412531645569, -1.453203448275862 ], [ 29.796143037974684, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20674, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.453203448275862 ], [ 29.797490506329115, -1.454011896551724 ], [ 29.79776, -1.454011896551724 ], [ 29.79776, -1.453203448275862 ], [ 29.797490506329115, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20675, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.453203448275862 ], [ 29.798298987341774, -1.453742413793103 ], [ 29.798568481012659, -1.453742413793103 ], [ 29.798568481012659, -1.453203448275862 ], [ 29.798298987341774, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20676, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.453203448275862 ], [ 29.798568481012659, -1.453742413793103 ], [ 29.798837974683543, -1.453742413793103 ], [ 29.798837974683543, -1.453203448275862 ], [ 29.798568481012659, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20677, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.453203448275862 ], [ 29.798837974683543, -1.453742413793103 ], [ 29.799107468354432, -1.453742413793103 ], [ 29.799107468354432, -1.453203448275862 ], [ 29.798837974683543, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20678, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.453203448275862 ], [ 29.8074617721519, -1.453742413793103 ], [ 29.807731265822785, -1.453742413793103 ], [ 29.807731265822785, -1.453203448275862 ], [ 29.8074617721519, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20679, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.453203448275862 ], [ 29.807731265822785, -1.453742413793103 ], [ 29.808000759493673, -1.453742413793103 ], [ 29.808000759493673, -1.453203448275862 ], [ 29.807731265822785, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20680, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.453203448275862 ], [ 29.808000759493673, -1.453742413793103 ], [ 29.808809240506328, -1.453742413793103 ], [ 29.808809240506328, -1.453203448275862 ], [ 29.808000759493673, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20681, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.453203448275862 ], [ 29.808809240506328, -1.453742413793103 ], [ 29.809078734177216, -1.453742413793103 ], [ 29.809078734177216, -1.453203448275862 ], [ 29.808809240506328, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20682, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.453203448275862 ], [ 29.809078734177216, -1.453742413793103 ], [ 29.809617721518986, -1.453742413793103 ], [ 29.809617721518986, -1.453203448275862 ], [ 29.809078734177216, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20683, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.453203448275862 ], [ 29.809617721518986, -1.453742413793103 ], [ 29.809887215189875, -1.453742413793103 ], [ 29.809887215189875, -1.453203448275862 ], [ 29.809617721518986, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20684, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.453203448275862 ], [ 29.809887215189875, -1.453742413793103 ], [ 29.810965189873418, -1.453742413793103 ], [ 29.810965189873418, -1.453203448275862 ], [ 29.809887215189875, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20685, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.453203448275862 ], [ 29.810965189873418, -1.453742413793103 ], [ 29.811234683544303, -1.453742413793103 ], [ 29.811234683544303, -1.453203448275862 ], [ 29.810965189873418, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20686, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.453203448275862 ], [ 29.811234683544303, -1.453742413793103 ], [ 29.811504177215191, -1.453742413793103 ], [ 29.811504177215191, -1.453203448275862 ], [ 29.811234683544303, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20687, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.453203448275862 ], [ 29.811504177215191, -1.454011896551724 ], [ 29.811773670886076, -1.454011896551724 ], [ 29.811773670886076, -1.453203448275862 ], [ 29.811504177215191, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20688, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.453203448275862 ], [ 29.811773670886076, -1.454281379310345 ], [ 29.812043164556961, -1.454281379310345 ], [ 29.812043164556961, -1.453203448275862 ], [ 29.811773670886076, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20689, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.453203448275862 ], [ 29.812043164556961, -1.454281379310345 ], [ 29.812312658227849, -1.454281379310345 ], [ 29.812312658227849, -1.453203448275862 ], [ 29.812043164556961, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20690, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.453742413793103 ], [ 29.751676582278481, -1.454011896551724 ], [ 29.751946075949366, -1.454011896551724 ], [ 29.751946075949366, -1.453742413793103 ], [ 29.751676582278481, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20691, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.453742413793103 ], [ 29.751946075949366, -1.454011896551724 ], [ 29.752215569620251, -1.454011896551724 ], [ 29.752215569620251, -1.453742413793103 ], [ 29.751946075949366, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20692, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.453742413793103 ], [ 29.752215569620251, -1.454011896551724 ], [ 29.75248506329114, -1.454011896551724 ], [ 29.75248506329114, -1.453742413793103 ], [ 29.752215569620251, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20693, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.453742413793103 ], [ 29.75248506329114, -1.454011896551724 ], [ 29.752754556962024, -1.454011896551724 ], [ 29.752754556962024, -1.453742413793103 ], [ 29.75248506329114, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20694, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.453742413793103 ], [ 29.752754556962024, -1.454281379310345 ], [ 29.753024050632909, -1.454281379310345 ], [ 29.753024050632909, -1.453742413793103 ], [ 29.752754556962024, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20695, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.453742413793103 ], [ 29.753024050632909, -1.454011896551724 ], [ 29.753293544303798, -1.454011896551724 ], [ 29.753293544303798, -1.453742413793103 ], [ 29.753024050632909, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20696, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.453742413793103 ], [ 29.753293544303798, -1.454011896551724 ], [ 29.753563037974683, -1.454011896551724 ], [ 29.753563037974683, -1.453742413793103 ], [ 29.753293544303798, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20697, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.453742413793103 ], [ 29.753563037974683, -1.454011896551724 ], [ 29.753832531645568, -1.454011896551724 ], [ 29.753832531645568, -1.453742413793103 ], [ 29.753563037974683, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20698, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.453742413793103 ], [ 29.754102025316456, -1.454011896551724 ], [ 29.754371518987341, -1.454011896551724 ], [ 29.754371518987341, -1.453742413793103 ], [ 29.754102025316456, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20699, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.453742413793103 ], [ 29.754371518987341, -1.454011896551724 ], [ 29.754641012658226, -1.454011896551724 ], [ 29.754641012658226, -1.453742413793103 ], [ 29.754371518987341, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20700, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.453742413793103 ], [ 29.754641012658226, -1.454011896551724 ], [ 29.754910506329114, -1.454011896551724 ], [ 29.754910506329114, -1.453742413793103 ], [ 29.754641012658226, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20701, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.453742413793103 ], [ 29.754910506329114, -1.454011896551724 ], [ 29.75518, -1.454011896551724 ], [ 29.75518, -1.453742413793103 ], [ 29.754910506329114, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20702, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.453742413793103 ], [ 29.75518, -1.454281379310345 ], [ 29.755449493670884, -1.454281379310345 ], [ 29.755449493670884, -1.453742413793103 ], [ 29.75518, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20703, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.453742413793103 ], [ 29.755449493670884, -1.454011896551724 ], [ 29.755718987341773, -1.454011896551724 ], [ 29.755718987341773, -1.453742413793103 ], [ 29.755449493670884, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20704, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.453742413793103 ], [ 29.755718987341773, -1.454011896551724 ], [ 29.755988481012658, -1.454011896551724 ], [ 29.755988481012658, -1.453742413793103 ], [ 29.755718987341773, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20705, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.453742413793103 ], [ 29.755988481012658, -1.454011896551724 ], [ 29.756257974683542, -1.454011896551724 ], [ 29.756257974683542, -1.453742413793103 ], [ 29.755988481012658, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20706, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.453742413793103 ], [ 29.769193670886075, -1.454011896551724 ], [ 29.76946316455696, -1.454011896551724 ], [ 29.76946316455696, -1.453742413793103 ], [ 29.769193670886075, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20707, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.453742413793103 ], [ 29.76946316455696, -1.454011896551724 ], [ 29.769732658227849, -1.454011896551724 ], [ 29.769732658227849, -1.453742413793103 ], [ 29.76946316455696, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20708, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.453742413793103 ], [ 29.769732658227849, -1.454011896551724 ], [ 29.770002151898733, -1.454011896551724 ], [ 29.770002151898733, -1.453742413793103 ], [ 29.769732658227849, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20709, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.453742413793103 ], [ 29.770541139240507, -1.454011896551724 ], [ 29.770810632911392, -1.454011896551724 ], [ 29.770810632911392, -1.453742413793103 ], [ 29.770541139240507, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20710, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.453742413793103 ], [ 29.771888607594935, -1.454011896551724 ], [ 29.772158101265823, -1.454011896551724 ], [ 29.772158101265823, -1.453742413793103 ], [ 29.771888607594935, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20711, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.453742413793103 ], [ 29.77862594936709, -1.454011896551724 ], [ 29.778895443037975, -1.454011896551724 ], [ 29.778895443037975, -1.453742413793103 ], [ 29.77862594936709, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20712, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.453742413793103 ], [ 29.778895443037975, -1.454011896551724 ], [ 29.77916493670886, -1.454011896551724 ], [ 29.77916493670886, -1.453742413793103 ], [ 29.778895443037975, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20713, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.453742413793103 ], [ 29.779434430379748, -1.454011896551724 ], [ 29.779703924050633, -1.454011896551724 ], [ 29.779703924050633, -1.453742413793103 ], [ 29.779434430379748, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20714, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.453742413793103 ], [ 29.779703924050633, -1.454011896551724 ], [ 29.779973417721518, -1.454011896551724 ], [ 29.779973417721518, -1.453742413793103 ], [ 29.779703924050633, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20715, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.453742413793103 ], [ 29.779973417721518, -1.454011896551724 ], [ 29.780242911392406, -1.454011896551724 ], [ 29.780242911392406, -1.453742413793103 ], [ 29.779973417721518, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20716, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.453742413793103 ], [ 29.780242911392406, -1.454011896551724 ], [ 29.780512405063291, -1.454011896551724 ], [ 29.780512405063291, -1.453742413793103 ], [ 29.780242911392406, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20717, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.453742413793103 ], [ 29.780512405063291, -1.454011896551724 ], [ 29.780781898734176, -1.454011896551724 ], [ 29.780781898734176, -1.453742413793103 ], [ 29.780512405063291, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20718, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.453742413793103 ], [ 29.780781898734176, -1.454011896551724 ], [ 29.781051392405065, -1.454011896551724 ], [ 29.781051392405065, -1.453742413793103 ], [ 29.780781898734176, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20719, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.453742413793103 ], [ 29.781051392405065, -1.454011896551724 ], [ 29.78132088607595, -1.454011896551724 ], [ 29.78132088607595, -1.453742413793103 ], [ 29.781051392405065, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20720, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.453742413793103 ], [ 29.78132088607595, -1.454011896551724 ], [ 29.781590379746834, -1.454011896551724 ], [ 29.781590379746834, -1.453742413793103 ], [ 29.78132088607595, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20721, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.453742413793103 ], [ 29.781590379746834, -1.454011896551724 ], [ 29.781859873417723, -1.454011896551724 ], [ 29.781859873417723, -1.453742413793103 ], [ 29.781590379746834, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20722, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.453742413793103 ], [ 29.781859873417723, -1.454011896551724 ], [ 29.782129367088608, -1.454011896551724 ], [ 29.782129367088608, -1.453742413793103 ], [ 29.781859873417723, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20723, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.453742413793103 ], [ 29.782129367088608, -1.454011896551724 ], [ 29.782398860759493, -1.454011896551724 ], [ 29.782398860759493, -1.453742413793103 ], [ 29.782129367088608, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20724, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.453742413793103 ], [ 29.782398860759493, -1.454011896551724 ], [ 29.782668354430381, -1.454011896551724 ], [ 29.782668354430381, -1.453742413793103 ], [ 29.782398860759493, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20725, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.453742413793103 ], [ 29.782668354430381, -1.454011896551724 ], [ 29.782937848101266, -1.454011896551724 ], [ 29.782937848101266, -1.453742413793103 ], [ 29.782668354430381, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20726, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.453742413793103 ], [ 29.782937848101266, -1.454011896551724 ], [ 29.783207341772151, -1.454011896551724 ], [ 29.783207341772151, -1.453742413793103 ], [ 29.782937848101266, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20727, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.453742413793103 ], [ 29.783207341772151, -1.454011896551724 ], [ 29.783476835443039, -1.454011896551724 ], [ 29.783476835443039, -1.453742413793103 ], [ 29.783207341772151, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20728, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.453742413793103 ], [ 29.783476835443039, -1.454011896551724 ], [ 29.784015822784809, -1.454011896551724 ], [ 29.784015822784809, -1.453742413793103 ], [ 29.783476835443039, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20729, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.453742413793103 ], [ 29.784015822784809, -1.454550862068966 ], [ 29.784285316455698, -1.454550862068966 ], [ 29.784285316455698, -1.453742413793103 ], [ 29.784015822784809, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20730, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.453742413793103 ], [ 29.791292151898734, -1.454011896551724 ], [ 29.791561645569619, -1.454011896551724 ], [ 29.791561645569619, -1.453742413793103 ], [ 29.791292151898734, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20731, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.453742413793103 ], [ 29.791561645569619, -1.454011896551724 ], [ 29.791831139240507, -1.454011896551724 ], [ 29.791831139240507, -1.453742413793103 ], [ 29.791561645569619, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20732, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.453742413793103 ], [ 29.791831139240507, -1.454011896551724 ], [ 29.792100632911392, -1.454011896551724 ], [ 29.792100632911392, -1.453742413793103 ], [ 29.791831139240507, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20733, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.453742413793103 ], [ 29.79560405063291, -1.454281379310345 ], [ 29.795873544303799, -1.454281379310345 ], [ 29.795873544303799, -1.453742413793103 ], [ 29.79560405063291, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20734, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.453742413793103 ], [ 29.796412531645569, -1.454281379310345 ], [ 29.796682025316457, -1.454281379310345 ], [ 29.796682025316457, -1.453742413793103 ], [ 29.796412531645569, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20735, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.453742413793103 ], [ 29.796951518987342, -1.454011896551724 ], [ 29.797221012658227, -1.454011896551724 ], [ 29.797221012658227, -1.453742413793103 ], [ 29.796951518987342, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20736, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.453742413793103 ], [ 29.797221012658227, -1.454011896551724 ], [ 29.797490506329115, -1.454011896551724 ], [ 29.797490506329115, -1.453742413793103 ], [ 29.797221012658227, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20737, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.453742413793103 ], [ 29.79776, -1.454011896551724 ], [ 29.798029493670885, -1.454011896551724 ], [ 29.798029493670885, -1.453742413793103 ], [ 29.79776, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20738, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.453742413793103 ], [ 29.798029493670885, -1.454011896551724 ], [ 29.798298987341774, -1.454011896551724 ], [ 29.798298987341774, -1.453742413793103 ], [ 29.798029493670885, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20739, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.453742413793103 ], [ 29.798298987341774, -1.454011896551724 ], [ 29.798568481012659, -1.454011896551724 ], [ 29.798568481012659, -1.453742413793103 ], [ 29.798298987341774, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20740, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.453742413793103 ], [ 29.807192278481015, -1.454011896551724 ], [ 29.807731265822785, -1.454011896551724 ], [ 29.807731265822785, -1.453742413793103 ], [ 29.807192278481015, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20741, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.453742413793103 ], [ 29.807731265822785, -1.454011896551724 ], [ 29.808000759493673, -1.454011896551724 ], [ 29.808000759493673, -1.453742413793103 ], [ 29.807731265822785, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20742, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.453742413793103 ], [ 29.808000759493673, -1.454011896551724 ], [ 29.808809240506328, -1.454011896551724 ], [ 29.808809240506328, -1.453742413793103 ], [ 29.808000759493673, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20743, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.453742413793103 ], [ 29.808809240506328, -1.454011896551724 ], [ 29.809078734177216, -1.454011896551724 ], [ 29.809078734177216, -1.453742413793103 ], [ 29.808809240506328, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20744, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.453742413793103 ], [ 29.809078734177216, -1.454011896551724 ], [ 29.809348227848101, -1.454011896551724 ], [ 29.809348227848101, -1.453742413793103 ], [ 29.809078734177216, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20745, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.453742413793103 ], [ 29.809348227848101, -1.454011896551724 ], [ 29.809617721518986, -1.454011896551724 ], [ 29.809617721518986, -1.453742413793103 ], [ 29.809348227848101, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20746, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.453742413793103 ], [ 29.809617721518986, -1.454011896551724 ], [ 29.809887215189875, -1.454011896551724 ], [ 29.809887215189875, -1.453742413793103 ], [ 29.809617721518986, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20747, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.453742413793103 ], [ 29.809887215189875, -1.454011896551724 ], [ 29.81015670886076, -1.454011896551724 ], [ 29.81015670886076, -1.454281379310345 ], [ 29.810426202531644, -1.454281379310345 ], [ 29.810426202531644, -1.454550862068966 ], [ 29.810965189873418, -1.454550862068966 ], [ 29.810965189873418, -1.454011896551724 ], [ 29.810426202531644, -1.454011896551724 ], [ 29.810426202531644, -1.453742413793103 ], [ 29.809887215189875, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20748, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.453742413793103 ], [ 29.810426202531644, -1.454011896551724 ], [ 29.810965189873418, -1.454011896551724 ], [ 29.810965189873418, -1.453742413793103 ], [ 29.810426202531644, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20749, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.453742413793103 ], [ 29.810965189873418, -1.454281379310345 ], [ 29.811234683544303, -1.454281379310345 ], [ 29.811234683544303, -1.453742413793103 ], [ 29.810965189873418, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20750, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.453742413793103 ], [ 29.811234683544303, -1.454281379310345 ], [ 29.811504177215191, -1.454281379310345 ], [ 29.811504177215191, -1.453742413793103 ], [ 29.811234683544303, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20751, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.454011896551724 ], [ 29.750868101265823, -1.454281379310345 ], [ 29.751137594936708, -1.454281379310345 ], [ 29.751137594936708, -1.454011896551724 ], [ 29.750868101265823, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20752, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.454011896551724 ], [ 29.751137594936708, -1.454281379310345 ], [ 29.751407088607593, -1.454281379310345 ], [ 29.751407088607593, -1.454011896551724 ], [ 29.751137594936708, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20753, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.454011896551724 ], [ 29.751407088607593, -1.454281379310345 ], [ 29.751676582278481, -1.454281379310345 ], [ 29.751676582278481, -1.454011896551724 ], [ 29.751407088607593, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20754, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.454011896551724 ], [ 29.751676582278481, -1.454550862068966 ], [ 29.751946075949366, -1.454550862068966 ], [ 29.751946075949366, -1.454011896551724 ], [ 29.751676582278481, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20755, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.454011896551724 ], [ 29.751946075949366, -1.454281379310345 ], [ 29.752215569620251, -1.454281379310345 ], [ 29.752215569620251, -1.454011896551724 ], [ 29.751946075949366, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20756, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.454011896551724 ], [ 29.752215569620251, -1.454281379310345 ], [ 29.75248506329114, -1.454281379310345 ], [ 29.75248506329114, -1.454011896551724 ], [ 29.752215569620251, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20757, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.454011896551724 ], [ 29.75248506329114, -1.454281379310345 ], [ 29.752754556962024, -1.454281379310345 ], [ 29.752754556962024, -1.454011896551724 ], [ 29.75248506329114, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20758, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.454011896551724 ], [ 29.753024050632909, -1.454281379310345 ], [ 29.753293544303798, -1.454281379310345 ], [ 29.753293544303798, -1.454011896551724 ], [ 29.753024050632909, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20759, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.454011896551724 ], [ 29.753293544303798, -1.454281379310345 ], [ 29.753563037974683, -1.454281379310345 ], [ 29.753563037974683, -1.454011896551724 ], [ 29.753293544303798, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20760, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.454011896551724 ], [ 29.753563037974683, -1.454281379310345 ], [ 29.753832531645568, -1.454281379310345 ], [ 29.753832531645568, -1.454011896551724 ], [ 29.753563037974683, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20761, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.454011896551724 ], [ 29.753832531645568, -1.454281379310345 ], [ 29.754102025316456, -1.454281379310345 ], [ 29.754102025316456, -1.454011896551724 ], [ 29.753832531645568, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20762, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.454011896551724 ], [ 29.754102025316456, -1.454281379310345 ], [ 29.754371518987341, -1.454281379310345 ], [ 29.754371518987341, -1.454011896551724 ], [ 29.754102025316456, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20763, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.454011896551724 ], [ 29.754371518987341, -1.454281379310345 ], [ 29.754641012658226, -1.454281379310345 ], [ 29.754641012658226, -1.454011896551724 ], [ 29.754371518987341, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20764, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.454011896551724 ], [ 29.754641012658226, -1.454281379310345 ], [ 29.754910506329114, -1.454281379310345 ], [ 29.754910506329114, -1.454011896551724 ], [ 29.754641012658226, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20765, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.454011896551724 ], [ 29.754910506329114, -1.454281379310345 ], [ 29.75518, -1.454281379310345 ], [ 29.75518, -1.454011896551724 ], [ 29.754910506329114, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20766, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.454011896551724 ], [ 29.755449493670884, -1.454281379310345 ], [ 29.755718987341773, -1.454281379310345 ], [ 29.755718987341773, -1.454011896551724 ], [ 29.755449493670884, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20767, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.454011896551724 ], [ 29.755718987341773, -1.454281379310345 ], [ 29.755988481012658, -1.454281379310345 ], [ 29.755988481012658, -1.454011896551724 ], [ 29.755718987341773, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20768, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.454011896551724 ], [ 29.755988481012658, -1.454281379310345 ], [ 29.756257974683542, -1.454281379310345 ], [ 29.756257974683542, -1.454011896551724 ], [ 29.755988481012658, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20769, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.454011896551724 ], [ 29.76892417721519, -1.454281379310345 ], [ 29.769193670886075, -1.454281379310345 ], [ 29.769193670886075, -1.454011896551724 ], [ 29.76892417721519, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20770, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.454011896551724 ], [ 29.769193670886075, -1.454281379310345 ], [ 29.76946316455696, -1.454281379310345 ], [ 29.76946316455696, -1.454011896551724 ], [ 29.769193670886075, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20771, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.454011896551724 ], [ 29.76946316455696, -1.454281379310345 ], [ 29.769732658227849, -1.454281379310345 ], [ 29.769732658227849, -1.454011896551724 ], [ 29.76946316455696, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20772, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.454011896551724 ], [ 29.769732658227849, -1.454281379310345 ], [ 29.770002151898733, -1.454281379310345 ], [ 29.770002151898733, -1.454011896551724 ], [ 29.769732658227849, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20773, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.454011896551724 ], [ 29.770541139240507, -1.454550862068966 ], [ 29.770810632911392, -1.454550862068966 ], [ 29.770810632911392, -1.454011896551724 ], [ 29.770541139240507, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20774, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.454011896551724 ], [ 29.771888607594935, -1.454550862068966 ], [ 29.772158101265823, -1.454550862068966 ], [ 29.772158101265823, -1.454011896551724 ], [ 29.771888607594935, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20775, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.454011896551724 ], [ 29.77862594936709, -1.454281379310345 ], [ 29.778895443037975, -1.454281379310345 ], [ 29.778895443037975, -1.454011896551724 ], [ 29.77862594936709, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20776, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.454011896551724 ], [ 29.778895443037975, -1.454281379310345 ], [ 29.77916493670886, -1.454281379310345 ], [ 29.77916493670886, -1.454011896551724 ], [ 29.778895443037975, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20777, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.454011896551724 ], [ 29.77916493670886, -1.454281379310345 ], [ 29.779434430379748, -1.454281379310345 ], [ 29.779434430379748, -1.454011896551724 ], [ 29.77916493670886, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20778, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.454011896551724 ], [ 29.779434430379748, -1.454281379310345 ], [ 29.779703924050633, -1.454281379310345 ], [ 29.779703924050633, -1.454011896551724 ], [ 29.779434430379748, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20779, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.454011896551724 ], [ 29.779703924050633, -1.454281379310345 ], [ 29.779973417721518, -1.454281379310345 ], [ 29.779973417721518, -1.454011896551724 ], [ 29.779703924050633, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20780, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.454011896551724 ], [ 29.779973417721518, -1.454281379310345 ], [ 29.780242911392406, -1.454281379310345 ], [ 29.780242911392406, -1.454011896551724 ], [ 29.779973417721518, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20781, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.454011896551724 ], [ 29.780242911392406, -1.454281379310345 ], [ 29.780512405063291, -1.454281379310345 ], [ 29.780512405063291, -1.454011896551724 ], [ 29.780242911392406, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20782, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.454011896551724 ], [ 29.780512405063291, -1.454281379310345 ], [ 29.780781898734176, -1.454281379310345 ], [ 29.780781898734176, -1.454011896551724 ], [ 29.780512405063291, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20783, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.454011896551724 ], [ 29.780781898734176, -1.454281379310345 ], [ 29.781051392405065, -1.454281379310345 ], [ 29.781051392405065, -1.454011896551724 ], [ 29.780781898734176, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20784, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.454011896551724 ], [ 29.781051392405065, -1.454281379310345 ], [ 29.78132088607595, -1.454281379310345 ], [ 29.78132088607595, -1.454011896551724 ], [ 29.781051392405065, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20785, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.454011896551724 ], [ 29.78132088607595, -1.454281379310345 ], [ 29.781590379746834, -1.454281379310345 ], [ 29.781590379746834, -1.454011896551724 ], [ 29.78132088607595, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20786, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.454011896551724 ], [ 29.781590379746834, -1.454281379310345 ], [ 29.781859873417723, -1.454281379310345 ], [ 29.781859873417723, -1.454011896551724 ], [ 29.781590379746834, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20787, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.454011896551724 ], [ 29.781859873417723, -1.454281379310345 ], [ 29.782129367088608, -1.454281379310345 ], [ 29.782129367088608, -1.454011896551724 ], [ 29.781859873417723, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20788, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.454011896551724 ], [ 29.782129367088608, -1.454281379310345 ], [ 29.782398860759493, -1.454281379310345 ], [ 29.782398860759493, -1.454011896551724 ], [ 29.782129367088608, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20789, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.454011896551724 ], [ 29.782398860759493, -1.454281379310345 ], [ 29.782668354430381, -1.454281379310345 ], [ 29.782668354430381, -1.454011896551724 ], [ 29.782398860759493, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20790, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.454011896551724 ], [ 29.782668354430381, -1.454281379310345 ], [ 29.782937848101266, -1.454281379310345 ], [ 29.782937848101266, -1.454011896551724 ], [ 29.782668354430381, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20791, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.454011896551724 ], [ 29.782937848101266, -1.454281379310345 ], [ 29.783207341772151, -1.454281379310345 ], [ 29.783207341772151, -1.454011896551724 ], [ 29.782937848101266, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20792, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.454011896551724 ], [ 29.783207341772151, -1.454550862068966 ], [ 29.783476835443039, -1.454550862068966 ], [ 29.783476835443039, -1.454011896551724 ], [ 29.783207341772151, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20793, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.454011896551724 ], [ 29.795334556962025, -1.454550862068966 ], [ 29.79560405063291, -1.454550862068966 ], [ 29.79560405063291, -1.454011896551724 ], [ 29.795334556962025, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20794, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.454011896551724 ], [ 29.795873544303799, -1.454281379310345 ], [ 29.796143037974684, -1.454281379310345 ], [ 29.796143037974684, -1.454011896551724 ], [ 29.795873544303799, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20795, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.454011896551724 ], [ 29.796143037974684, -1.454281379310345 ], [ 29.796412531645569, -1.454281379310345 ], [ 29.796412531645569, -1.454011896551724 ], [ 29.796143037974684, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20796, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.454011896551724 ], [ 29.796682025316457, -1.454281379310345 ], [ 29.796951518987342, -1.454281379310345 ], [ 29.796951518987342, -1.454011896551724 ], [ 29.796682025316457, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20797, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.454011896551724 ], [ 29.796951518987342, -1.454281379310345 ], [ 29.797221012658227, -1.454281379310345 ], [ 29.797221012658227, -1.454011896551724 ], [ 29.796951518987342, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20798, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.454011896551724 ], [ 29.797221012658227, -1.454281379310345 ], [ 29.797490506329115, -1.454281379310345 ], [ 29.797490506329115, -1.454011896551724 ], [ 29.797221012658227, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20799, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.454011896551724 ], [ 29.797490506329115, -1.454281379310345 ], [ 29.79776, -1.454281379310345 ], [ 29.79776, -1.454011896551724 ], [ 29.797490506329115, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20800, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.454011896551724 ], [ 29.8074617721519, -1.454281379310345 ], [ 29.807731265822785, -1.454281379310345 ], [ 29.807731265822785, -1.454011896551724 ], [ 29.8074617721519, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20801, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.454011896551724 ], [ 29.807731265822785, -1.454281379310345 ], [ 29.808539746835443, -1.454281379310345 ], [ 29.808539746835443, -1.454011896551724 ], [ 29.807731265822785, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20802, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.454011896551724 ], [ 29.808539746835443, -1.454281379310345 ], [ 29.809078734177216, -1.454281379310345 ], [ 29.809078734177216, -1.454011896551724 ], [ 29.808539746835443, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20803, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.454011896551724 ], [ 29.809078734177216, -1.454281379310345 ], [ 29.809348227848101, -1.454281379310345 ], [ 29.809348227848101, -1.454011896551724 ], [ 29.809078734177216, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20804, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.454011896551724 ], [ 29.809348227848101, -1.454281379310345 ], [ 29.809617721518986, -1.454281379310345 ], [ 29.809617721518986, -1.454011896551724 ], [ 29.809348227848101, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20805, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.454011896551724 ], [ 29.809617721518986, -1.454281379310345 ], [ 29.809887215189875, -1.454281379310345 ], [ 29.809887215189875, -1.454011896551724 ], [ 29.809617721518986, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20806, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.454011896551724 ], [ 29.809887215189875, -1.454281379310345 ], [ 29.81015670886076, -1.454281379310345 ], [ 29.81015670886076, -1.454011896551724 ], [ 29.809887215189875, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20807, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.454011896551724 ], [ 29.811504177215191, -1.454550862068966 ], [ 29.811773670886076, -1.454550862068966 ], [ 29.811773670886076, -1.454011896551724 ], [ 29.811504177215191, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20808, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.454011896551724 ], [ 29.812312658227849, -1.454281379310345 ], [ 29.812582151898734, -1.454281379310345 ], [ 29.812582151898734, -1.454011896551724 ], [ 29.812312658227849, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20809, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.454011896551724 ], [ 29.812582151898734, -1.454281379310345 ], [ 29.812851645569619, -1.454281379310345 ], [ 29.812851645569619, -1.454011896551724 ], [ 29.812582151898734, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20810, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.454011896551724 ], [ 29.812851645569619, -1.454281379310345 ], [ 29.813121139240508, -1.454281379310345 ], [ 29.813121139240508, -1.454011896551724 ], [ 29.812851645569619, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20811, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.454281379310345 ], [ 29.75032911392405, -1.454550862068966 ], [ 29.750598607594934, -1.454550862068966 ], [ 29.750598607594934, -1.454281379310345 ], [ 29.75032911392405, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20812, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.454281379310345 ], [ 29.750598607594934, -1.454550862068966 ], [ 29.750868101265823, -1.454550862068966 ], [ 29.750868101265823, -1.454281379310345 ], [ 29.750598607594934, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20813, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.454281379310345 ], [ 29.750868101265823, -1.454550862068966 ], [ 29.751137594936708, -1.454550862068966 ], [ 29.751137594936708, -1.454281379310345 ], [ 29.750868101265823, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20814, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.454281379310345 ], [ 29.751946075949366, -1.454550862068966 ], [ 29.752215569620251, -1.454550862068966 ], [ 29.752215569620251, -1.454281379310345 ], [ 29.751946075949366, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20815, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.454281379310345 ], [ 29.752215569620251, -1.454550862068966 ], [ 29.75248506329114, -1.454550862068966 ], [ 29.75248506329114, -1.454281379310345 ], [ 29.752215569620251, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20816, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.454281379310345 ], [ 29.75248506329114, -1.454550862068966 ], [ 29.752754556962024, -1.454550862068966 ], [ 29.752754556962024, -1.454281379310345 ], [ 29.75248506329114, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20817, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.454281379310345 ], [ 29.752754556962024, -1.454550862068966 ], [ 29.753024050632909, -1.454550862068966 ], [ 29.753024050632909, -1.454281379310345 ], [ 29.752754556962024, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20818, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.454281379310345 ], [ 29.753024050632909, -1.454550862068966 ], [ 29.753293544303798, -1.454550862068966 ], [ 29.753293544303798, -1.454281379310345 ], [ 29.753024050632909, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20819, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.454281379310345 ], [ 29.753293544303798, -1.454550862068966 ], [ 29.753563037974683, -1.454550862068966 ], [ 29.753563037974683, -1.454281379310345 ], [ 29.753293544303798, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20820, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.454281379310345 ], [ 29.753563037974683, -1.454550862068966 ], [ 29.753832531645568, -1.454550862068966 ], [ 29.753832531645568, -1.454281379310345 ], [ 29.753563037974683, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20821, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.454281379310345 ], [ 29.753832531645568, -1.454550862068966 ], [ 29.754102025316456, -1.454550862068966 ], [ 29.754102025316456, -1.454281379310345 ], [ 29.753832531645568, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20822, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.454281379310345 ], [ 29.754371518987341, -1.454550862068966 ], [ 29.754641012658226, -1.454550862068966 ], [ 29.754641012658226, -1.454281379310345 ], [ 29.754371518987341, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20823, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.454281379310345 ], [ 29.754641012658226, -1.454550862068966 ], [ 29.754910506329114, -1.454550862068966 ], [ 29.754910506329114, -1.454281379310345 ], [ 29.754641012658226, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20824, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.454281379310345 ], [ 29.754910506329114, -1.454550862068966 ], [ 29.75518, -1.454550862068966 ], [ 29.75518, -1.454281379310345 ], [ 29.754910506329114, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20825, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.454281379310345 ], [ 29.75518, -1.454550862068966 ], [ 29.755449493670884, -1.454550862068966 ], [ 29.755449493670884, -1.454281379310345 ], [ 29.75518, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20826, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.454281379310345 ], [ 29.755449493670884, -1.454550862068966 ], [ 29.755718987341773, -1.454550862068966 ], [ 29.755718987341773, -1.454281379310345 ], [ 29.755449493670884, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20827, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.454281379310345 ], [ 29.755718987341773, -1.454550862068966 ], [ 29.755988481012658, -1.454550862068966 ], [ 29.755988481012658, -1.454281379310345 ], [ 29.755718987341773, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20828, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.454281379310345 ], [ 29.755988481012658, -1.454550862068966 ], [ 29.756257974683542, -1.454550862068966 ], [ 29.756257974683542, -1.454281379310345 ], [ 29.755988481012658, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20829, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.454281379310345 ], [ 29.768654683544302, -1.454550862068966 ], [ 29.76892417721519, -1.454550862068966 ], [ 29.76892417721519, -1.454281379310345 ], [ 29.768654683544302, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20830, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.454281379310345 ], [ 29.76892417721519, -1.454550862068966 ], [ 29.769193670886075, -1.454550862068966 ], [ 29.769193670886075, -1.454281379310345 ], [ 29.76892417721519, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20831, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.454281379310345 ], [ 29.769193670886075, -1.454550862068966 ], [ 29.76946316455696, -1.454550862068966 ], [ 29.76946316455696, -1.454281379310345 ], [ 29.769193670886075, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20832, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.454281379310345 ], [ 29.76946316455696, -1.454550862068966 ], [ 29.769732658227849, -1.454550862068966 ], [ 29.769732658227849, -1.454281379310345 ], [ 29.76946316455696, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20833, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.454281379310345 ], [ 29.769732658227849, -1.454550862068966 ], [ 29.770002151898733, -1.454550862068966 ], [ 29.770002151898733, -1.454281379310345 ], [ 29.769732658227849, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20834, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.454281379310345 ], [ 29.770002151898733, -1.454550862068966 ], [ 29.770271645569618, -1.454550862068966 ], [ 29.770271645569618, -1.454281379310345 ], [ 29.770002151898733, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20835, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.454281379310345 ], [ 29.770271645569618, -1.454550862068966 ], [ 29.770541139240507, -1.454550862068966 ], [ 29.770541139240507, -1.454281379310345 ], [ 29.770271645569618, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20836, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.454281379310345 ], [ 29.770810632911392, -1.454550862068966 ], [ 29.771080126582277, -1.454550862068966 ], [ 29.771080126582277, -1.454281379310345 ], [ 29.770810632911392, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20837, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.454281379310345 ], [ 29.771080126582277, -1.454550862068966 ], [ 29.771349620253165, -1.454550862068966 ], [ 29.771349620253165, -1.454281379310345 ], [ 29.771080126582277, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20838, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.454281379310345 ], [ 29.77862594936709, -1.454550862068966 ], [ 29.778895443037975, -1.454550862068966 ], [ 29.778895443037975, -1.454281379310345 ], [ 29.77862594936709, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20839, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.454281379310345 ], [ 29.778895443037975, -1.454550862068966 ], [ 29.77916493670886, -1.454550862068966 ], [ 29.77916493670886, -1.454281379310345 ], [ 29.778895443037975, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20840, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.454281379310345 ], [ 29.77916493670886, -1.454550862068966 ], [ 29.779434430379748, -1.454550862068966 ], [ 29.779434430379748, -1.454281379310345 ], [ 29.77916493670886, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20841, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.454281379310345 ], [ 29.779434430379748, -1.454550862068966 ], [ 29.779703924050633, -1.454550862068966 ], [ 29.779703924050633, -1.454281379310345 ], [ 29.779434430379748, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20842, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.454281379310345 ], [ 29.779703924050633, -1.454550862068966 ], [ 29.779973417721518, -1.454550862068966 ], [ 29.779973417721518, -1.454281379310345 ], [ 29.779703924050633, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20843, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.454281379310345 ], [ 29.779973417721518, -1.454550862068966 ], [ 29.780242911392406, -1.454550862068966 ], [ 29.780242911392406, -1.454281379310345 ], [ 29.779973417721518, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20844, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.454281379310345 ], [ 29.780242911392406, -1.454550862068966 ], [ 29.780512405063291, -1.454550862068966 ], [ 29.780512405063291, -1.454281379310345 ], [ 29.780242911392406, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20845, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.454281379310345 ], [ 29.780512405063291, -1.454550862068966 ], [ 29.780781898734176, -1.454550862068966 ], [ 29.780781898734176, -1.454281379310345 ], [ 29.780512405063291, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20846, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.454281379310345 ], [ 29.780781898734176, -1.454550862068966 ], [ 29.781051392405065, -1.454550862068966 ], [ 29.781051392405065, -1.454281379310345 ], [ 29.780781898734176, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20847, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.454281379310345 ], [ 29.781051392405065, -1.454550862068966 ], [ 29.78132088607595, -1.454550862068966 ], [ 29.78132088607595, -1.454281379310345 ], [ 29.781051392405065, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20848, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.454281379310345 ], [ 29.78132088607595, -1.454550862068966 ], [ 29.781590379746834, -1.454550862068966 ], [ 29.781590379746834, -1.454281379310345 ], [ 29.78132088607595, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20849, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.454281379310345 ], [ 29.781590379746834, -1.454550862068966 ], [ 29.781859873417723, -1.454550862068966 ], [ 29.781859873417723, -1.454281379310345 ], [ 29.781590379746834, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20850, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.454281379310345 ], [ 29.782398860759493, -1.454550862068966 ], [ 29.782668354430381, -1.454550862068966 ], [ 29.782668354430381, -1.454281379310345 ], [ 29.782398860759493, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20851, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.454281379310345 ], [ 29.787519240506327, -1.454550862068966 ], [ 29.787788734177216, -1.454550862068966 ], [ 29.787788734177216, -1.454281379310345 ], [ 29.787519240506327, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.454281379310345 ], [ 29.787788734177216, -1.454550862068966 ], [ 29.788058227848101, -1.454550862068966 ], [ 29.788058227848101, -1.454281379310345 ], [ 29.787788734177216, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20853, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.454281379310345 ], [ 29.788058227848101, -1.454550862068966 ], [ 29.788327721518986, -1.454550862068966 ], [ 29.788327721518986, -1.454281379310345 ], [ 29.788058227848101, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20854, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.454281379310345 ], [ 29.79560405063291, -1.454550862068966 ], [ 29.795873544303799, -1.454550862068966 ], [ 29.795873544303799, -1.454281379310345 ], [ 29.79560405063291, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20855, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.454281379310345 ], [ 29.795873544303799, -1.454550862068966 ], [ 29.796143037974684, -1.454550862068966 ], [ 29.796143037974684, -1.454281379310345 ], [ 29.795873544303799, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20856, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.454281379310345 ], [ 29.796143037974684, -1.454550862068966 ], [ 29.796412531645569, -1.454550862068966 ], [ 29.796412531645569, -1.454281379310345 ], [ 29.796143037974684, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20857, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.454281379310345 ], [ 29.796682025316457, -1.454550862068966 ], [ 29.796951518987342, -1.454550862068966 ], [ 29.796951518987342, -1.454281379310345 ], [ 29.796682025316457, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20858, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.454281379310345 ], [ 29.796951518987342, -1.454550862068966 ], [ 29.797221012658227, -1.454550862068966 ], [ 29.797221012658227, -1.454281379310345 ], [ 29.796951518987342, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20859, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.454281379310345 ], [ 29.8074617721519, -1.454550862068966 ], [ 29.807731265822785, -1.454550862068966 ], [ 29.807731265822785, -1.454281379310345 ], [ 29.8074617721519, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20860, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.454281379310345 ], [ 29.807731265822785, -1.454550862068966 ], [ 29.808809240506328, -1.454550862068966 ], [ 29.808809240506328, -1.454281379310345 ], [ 29.807731265822785, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20861, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.454281379310345 ], [ 29.808809240506328, -1.454550862068966 ], [ 29.809078734177216, -1.454550862068966 ], [ 29.809078734177216, -1.454281379310345 ], [ 29.808809240506328, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20862, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.454281379310345 ], [ 29.809078734177216, -1.454550862068966 ], [ 29.809348227848101, -1.454550862068966 ], [ 29.809348227848101, -1.454281379310345 ], [ 29.809078734177216, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20863, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.454281379310345 ], [ 29.809348227848101, -1.454550862068966 ], [ 29.809617721518986, -1.454550862068966 ], [ 29.809617721518986, -1.454281379310345 ], [ 29.809348227848101, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20864, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.454281379310345 ], [ 29.809617721518986, -1.454550862068966 ], [ 29.809887215189875, -1.454550862068966 ], [ 29.809887215189875, -1.454281379310345 ], [ 29.809617721518986, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20865, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.454281379310345 ], [ 29.809887215189875, -1.454550862068966 ], [ 29.81015670886076, -1.454550862068966 ], [ 29.81015670886076, -1.454281379310345 ], [ 29.809887215189875, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20866, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.454281379310345 ], [ 29.81015670886076, -1.454550862068966 ], [ 29.810426202531644, -1.454550862068966 ], [ 29.810426202531644, -1.454281379310345 ], [ 29.81015670886076, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20867, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.454281379310345 ], [ 29.810965189873418, -1.454550862068966 ], [ 29.811234683544303, -1.454550862068966 ], [ 29.811234683544303, -1.454281379310345 ], [ 29.810965189873418, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20868, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.454281379310345 ], [ 29.811234683544303, -1.454550862068966 ], [ 29.811504177215191, -1.454550862068966 ], [ 29.811504177215191, -1.454281379310345 ], [ 29.811234683544303, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20869, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.454281379310345 ], [ 29.811773670886076, -1.454550862068966 ], [ 29.812043164556961, -1.454550862068966 ], [ 29.812043164556961, -1.454281379310345 ], [ 29.811773670886076, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20870, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.454281379310345 ], [ 29.812043164556961, -1.454550862068966 ], [ 29.812312658227849, -1.454550862068966 ], [ 29.812312658227849, -1.454281379310345 ], [ 29.812043164556961, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20871, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.454281379310345 ], [ 29.812312658227849, -1.454550862068966 ], [ 29.812582151898734, -1.454550862068966 ], [ 29.812582151898734, -1.454281379310345 ], [ 29.812312658227849, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20872, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.447813793103448 ], [ 29.757335949367089, -1.455359310344828 ], [ 29.757605443037974, -1.455359310344828 ], [ 29.757605443037974, -1.447813793103448 ], [ 29.757335949367089, -1.447813793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20873, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.448083275862069 ], [ 29.757066455696201, -1.455089827586207 ], [ 29.757335949367089, -1.455089827586207 ], [ 29.757335949367089, -1.448083275862069 ], [ 29.757066455696201, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20874, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.448083275862069 ], [ 29.757874936708859, -1.455089827586207 ], [ 29.758144430379748, -1.455089827586207 ], [ 29.758144430379748, -1.448083275862069 ], [ 29.757874936708859, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20875, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.448083275862069 ], [ 29.758413924050632, -1.455089827586207 ], [ 29.758683417721517, -1.455089827586207 ], [ 29.758683417721517, -1.448083275862069 ], [ 29.758413924050632, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20876, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.448083275862069 ], [ 29.758683417721517, -1.455089827586207 ], [ 29.758952911392406, -1.455089827586207 ], [ 29.758952911392406, -1.448083275862069 ], [ 29.758683417721517, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20877, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.448083275862069 ], [ 29.759222405063291, -1.455089827586207 ], [ 29.759491898734176, -1.455089827586207 ], [ 29.759491898734176, -1.448083275862069 ], [ 29.759222405063291, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20878, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.448083275862069 ], [ 29.760030886075949, -1.455089827586207 ], [ 29.760300379746834, -1.455089827586207 ], [ 29.760300379746834, -1.448083275862069 ], [ 29.760030886075949, -1.448083275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20879, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.44835275862069 ], [ 29.756796962025316, -1.454820344827586 ], [ 29.757066455696201, -1.454820344827586 ], [ 29.757066455696201, -1.44835275862069 ], [ 29.756796962025316, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20880, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.44835275862069 ], [ 29.757605443037974, -1.454820344827586 ], [ 29.757874936708859, -1.454820344827586 ], [ 29.757874936708859, -1.44835275862069 ], [ 29.757605443037974, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20881, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.44835275862069 ], [ 29.758144430379748, -1.454820344827586 ], [ 29.758413924050632, -1.454820344827586 ], [ 29.758413924050632, -1.44835275862069 ], [ 29.758144430379748, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20882, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.44835275862069 ], [ 29.758952911392406, -1.454820344827586 ], [ 29.759222405063291, -1.454820344827586 ], [ 29.759222405063291, -1.44835275862069 ], [ 29.758952911392406, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20883, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.44835275862069 ], [ 29.759761392405064, -1.454820344827586 ], [ 29.760030886075949, -1.454820344827586 ], [ 29.760030886075949, -1.44835275862069 ], [ 29.759761392405064, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20884, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.44835275862069 ], [ 29.760300379746834, -1.454820344827586 ], [ 29.760569873417722, -1.454820344827586 ], [ 29.760569873417722, -1.44835275862069 ], [ 29.760300379746834, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20885, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.44835275862069 ], [ 29.760569873417722, -1.454820344827586 ], [ 29.760839367088607, -1.454820344827586 ], [ 29.760839367088607, -1.44835275862069 ], [ 29.760569873417722, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20886, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.44835275862069 ], [ 29.761108860759492, -1.454820344827586 ], [ 29.761378354430381, -1.454820344827586 ], [ 29.761378354430381, -1.44835275862069 ], [ 29.761108860759492, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20887, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.44835275862069 ], [ 29.761378354430381, -1.454820344827586 ], [ 29.761647848101266, -1.454820344827586 ], [ 29.761647848101266, -1.44835275862069 ], [ 29.761378354430381, -1.44835275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20889, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.452664482758621 ], [ 29.774044556962025, -1.454820344827586 ], [ 29.77431405063291, -1.454820344827586 ], [ 29.77431405063291, -1.452664482758621 ], [ 29.774044556962025, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20890, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.452664482758621 ], [ 29.775122531645568, -1.454820344827586 ], [ 29.775392025316457, -1.454820344827586 ], [ 29.775392025316457, -1.452664482758621 ], [ 29.775122531645568, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20891, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.452664482758621 ], [ 29.775661518987341, -1.454820344827586 ], [ 29.775931012658226, -1.454820344827586 ], [ 29.775931012658226, -1.452664482758621 ], [ 29.775661518987341, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20892, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.452664482758621 ], [ 29.77647, -1.454820344827586 ], [ 29.776739493670885, -1.454820344827586 ], [ 29.776739493670885, -1.452664482758621 ], [ 29.77647, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20893, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.452664482758621 ], [ 29.777008987341773, -1.454820344827586 ], [ 29.777278481012658, -1.454820344827586 ], [ 29.777278481012658, -1.452664482758621 ], [ 29.777008987341773, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20894, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.452664482758621 ], [ 29.777278481012658, -1.454820344827586 ], [ 29.777547974683543, -1.454820344827586 ], [ 29.777547974683543, -1.452664482758621 ], [ 29.777278481012658, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20895, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.452664482758621 ], [ 29.777817468354431, -1.454820344827586 ], [ 29.778086962025316, -1.454820344827586 ], [ 29.778086962025316, -1.452664482758621 ], [ 29.777817468354431, -1.452664482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20896, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.452933965517242 ], [ 29.785902278481014, -1.454820344827586 ], [ 29.786171772151899, -1.454820344827586 ], [ 29.786171772151899, -1.452933965517242 ], [ 29.785902278481014, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20897, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.452933965517242 ], [ 29.805575316455698, -1.455359310344828 ], [ 29.807192278481015, -1.455359310344828 ], [ 29.807192278481015, -1.455089827586207 ], [ 29.805844810126583, -1.455089827586207 ], [ 29.805844810126583, -1.452933965517242 ], [ 29.805575316455698, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20898, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.452933965517242 ], [ 29.805844810126583, -1.455089827586207 ], [ 29.807192278481015, -1.455089827586207 ], [ 29.807192278481015, -1.454820344827586 ], [ 29.806114303797468, -1.454820344827586 ], [ 29.806114303797468, -1.452933965517242 ], [ 29.805844810126583, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.452933965517242 ], [ 29.806114303797468, -1.454820344827586 ], [ 29.807192278481015, -1.454820344827586 ], [ 29.807192278481015, -1.454550862068966 ], [ 29.806383797468357, -1.454550862068966 ], [ 29.806383797468357, -1.452933965517242 ], [ 29.806114303797468, -1.452933965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20900, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.453203448275862 ], [ 29.785093797468356, -1.454820344827586 ], [ 29.785363291139241, -1.454820344827586 ], [ 29.785363291139241, -1.453203448275862 ], [ 29.785093797468356, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20901, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.453203448275862 ], [ 29.793717594936709, -1.455359310344828 ], [ 29.793987088607594, -1.455359310344828 ], [ 29.793987088607594, -1.453203448275862 ], [ 29.793717594936709, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20902, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.453203448275862 ], [ 29.794526075949367, -1.455089827586207 ], [ 29.794795569620252, -1.455089827586207 ], [ 29.794795569620252, -1.453203448275862 ], [ 29.794526075949367, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20903, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.453203448275862 ], [ 29.79506506329114, -1.454820344827586 ], [ 29.795334556962025, -1.454820344827586 ], [ 29.795334556962025, -1.454011896551724 ], [ 29.79560405063291, -1.454011896551724 ], [ 29.79560405063291, -1.453203448275862 ], [ 29.79506506329114, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20904, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.453203448275862 ], [ 29.799107468354432, -1.454820344827586 ], [ 29.799376962025317, -1.454820344827586 ], [ 29.799376962025317, -1.453203448275862 ], [ 29.799107468354432, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20905, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.453203448275862 ], [ 29.765959746835442, -1.453203448275862 ], [ 29.765959746835442, -1.454550862068966 ], [ 29.765420759493672, -1.454550862068966 ], [ 29.765420759493672, -1.454011896551724 ], [ 29.76407329113924, -1.454011896551724 ], [ 29.76407329113924, -1.454820344827586 ], [ 29.763803797468356, -1.454820344827586 ], [ 29.763803797468356, -1.453742413793103 ], [ 29.765420759493672, -1.453742413793103 ], [ 29.765420759493672, -1.453203448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20906, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.453742413793103 ], [ 29.778356455696201, -1.455089827586207 ], [ 29.77862594936709, -1.455089827586207 ], [ 29.77862594936709, -1.454820344827586 ], [ 29.778895443037975, -1.454820344827586 ], [ 29.778895443037975, -1.454550862068966 ], [ 29.77862594936709, -1.454550862068966 ], [ 29.77862594936709, -1.453742413793103 ], [ 29.778356455696201, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20907, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.453742413793103 ], [ 29.784285316455698, -1.454820344827586 ], [ 29.784554810126583, -1.454820344827586 ], [ 29.784554810126583, -1.453742413793103 ], [ 29.784285316455698, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20908, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.453742413793103 ], [ 29.784554810126583, -1.454820344827586 ], [ 29.784824303797468, -1.454820344827586 ], [ 29.784824303797468, -1.453742413793103 ], [ 29.784554810126583, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20909, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.453742413793103 ], [ 29.792100632911392, -1.455898275862069 ], [ 29.792370126582277, -1.455898275862069 ], [ 29.792370126582277, -1.453742413793103 ], [ 29.792100632911392, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20910, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.453742413793103 ], [ 29.792370126582277, -1.455898275862069 ], [ 29.792909113924051, -1.455898275862069 ], [ 29.792909113924051, -1.453742413793103 ], [ 29.792370126582277, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20911, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.453742413793103 ], [ 29.793178607594935, -1.455628793103448 ], [ 29.793448101265824, -1.455628793103448 ], [ 29.793448101265824, -1.453742413793103 ], [ 29.793178607594935, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20912, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.453742413793103 ], [ 29.793448101265824, -1.455628793103448 ], [ 29.793717594936709, -1.455628793103448 ], [ 29.793717594936709, -1.453742413793103 ], [ 29.793448101265824, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20913, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.453742413793103 ], [ 29.793987088607594, -1.455359310344828 ], [ 29.794256582278482, -1.455359310344828 ], [ 29.794256582278482, -1.453742413793103 ], [ 29.793987088607594, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20914, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.453742413793103 ], [ 29.794795569620252, -1.455089827586207 ], [ 29.79506506329114, -1.455089827586207 ], [ 29.79506506329114, -1.453742413793103 ], [ 29.794795569620252, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20915, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.453742413793103 ], [ 29.798568481012659, -1.454820344827586 ], [ 29.798837974683543, -1.454820344827586 ], [ 29.798837974683543, -1.453742413793103 ], [ 29.798568481012659, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20916, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.453742413793103 ], [ 29.798837974683543, -1.454820344827586 ], [ 29.799107468354432, -1.454820344827586 ], [ 29.799107468354432, -1.453742413793103 ], [ 29.798837974683543, -1.453742413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20917, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.454011896551724 ], [ 29.76407329113924, -1.454820344827586 ], [ 29.764342784810125, -1.454820344827586 ], [ 29.764342784810125, -1.454281379310345 ], [ 29.765151265822784, -1.454281379310345 ], [ 29.765151265822784, -1.454550862068966 ], [ 29.765420759493672, -1.454550862068966 ], [ 29.765420759493672, -1.454011896551724 ], [ 29.76407329113924, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20918, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.454011896551724 ], [ 29.783476835443039, -1.454820344827586 ], [ 29.784015822784809, -1.454820344827586 ], [ 29.784015822784809, -1.454011896551724 ], [ 29.783476835443039, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20919, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.454011896551724 ], [ 29.791292151898734, -1.455359310344828 ], [ 29.791561645569619, -1.455359310344828 ], [ 29.791561645569619, -1.454011896551724 ], [ 29.791292151898734, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20920, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.454011896551724 ], [ 29.791561645569619, -1.455359310344828 ], [ 29.791831139240507, -1.455359310344828 ], [ 29.791831139240507, -1.454011896551724 ], [ 29.791561645569619, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20921, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.454011896551724 ], [ 29.791831139240507, -1.455359310344828 ], [ 29.792100632911392, -1.455359310344828 ], [ 29.792100632911392, -1.454011896551724 ], [ 29.791831139240507, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20922, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.454011896551724 ], [ 29.792909113924051, -1.455359310344828 ], [ 29.793178607594935, -1.455359310344828 ], [ 29.793178607594935, -1.454011896551724 ], [ 29.792909113924051, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20923, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.454011896551724 ], [ 29.794256582278482, -1.455089827586207 ], [ 29.794526075949367, -1.455089827586207 ], [ 29.794526075949367, -1.454011896551724 ], [ 29.794256582278482, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20924, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.454011896551724 ], [ 29.79776, -1.454820344827586 ], [ 29.798029493670885, -1.454820344827586 ], [ 29.798029493670885, -1.454011896551724 ], [ 29.79776, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20925, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.454011896551724 ], [ 29.798029493670885, -1.455089827586207 ], [ 29.798298987341774, -1.455089827586207 ], [ 29.798298987341774, -1.454011896551724 ], [ 29.798029493670885, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20926, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.454011896551724 ], [ 29.798298987341774, -1.455089827586207 ], [ 29.798568481012659, -1.455089827586207 ], [ 29.798568481012659, -1.454011896551724 ], [ 29.798298987341774, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20927, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.454011896551724 ], [ 29.813121139240508, -1.454820344827586 ], [ 29.813390632911393, -1.454820344827586 ], [ 29.813390632911393, -1.454011896551724 ], [ 29.813121139240508, -1.454011896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20928, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.454281379310345 ], [ 29.751137594936708, -1.454820344827586 ], [ 29.751407088607593, -1.454820344827586 ], [ 29.751407088607593, -1.454281379310345 ], [ 29.751137594936708, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20929, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.454281379310345 ], [ 29.751407088607593, -1.454820344827586 ], [ 29.751676582278481, -1.454820344827586 ], [ 29.751676582278481, -1.454281379310345 ], [ 29.751407088607593, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20930, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.454281379310345 ], [ 29.754102025316456, -1.454820344827586 ], [ 29.754371518987341, -1.454820344827586 ], [ 29.754371518987341, -1.454281379310345 ], [ 29.754102025316456, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20931, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.454281379310345 ], [ 29.764342784810125, -1.454820344827586 ], [ 29.764612278481014, -1.454820344827586 ], [ 29.764612278481014, -1.454550862068966 ], [ 29.765151265822784, -1.454550862068966 ], [ 29.765151265822784, -1.454281379310345 ], [ 29.764342784810125, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20932, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.454281379310345 ], [ 29.77161911392405, -1.454820344827586 ], [ 29.771888607594935, -1.454820344827586 ], [ 29.771888607594935, -1.454281379310345 ], [ 29.77161911392405, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20933, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.454281379310345 ], [ 29.772427594936708, -1.454820344827586 ], [ 29.772697088607593, -1.454820344827586 ], [ 29.772697088607593, -1.454281379310345 ], [ 29.772427594936708, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20934, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.454281379310345 ], [ 29.773236075949367, -1.454820344827586 ], [ 29.773505569620252, -1.454820344827586 ], [ 29.773505569620252, -1.454281379310345 ], [ 29.773236075949367, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20935, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.454281379310345 ], [ 29.77377506329114, -1.454820344827586 ], [ 29.774044556962025, -1.454820344827586 ], [ 29.774044556962025, -1.454281379310345 ], [ 29.77377506329114, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20936, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.454281379310345 ], [ 29.774853037974683, -1.454820344827586 ], [ 29.775122531645568, -1.454820344827586 ], [ 29.775122531645568, -1.454281379310345 ], [ 29.774853037974683, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20937, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.454281379310345 ], [ 29.776200506329115, -1.455089827586207 ], [ 29.77647, -1.455089827586207 ], [ 29.77647, -1.454281379310345 ], [ 29.776200506329115, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20938, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.454281379310345 ], [ 29.777547974683543, -1.455089827586207 ], [ 29.777817468354431, -1.455089827586207 ], [ 29.777817468354431, -1.454281379310345 ], [ 29.777547974683543, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20939, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.454281379310345 ], [ 29.781859873417723, -1.454820344827586 ], [ 29.782129367088608, -1.454820344827586 ], [ 29.782129367088608, -1.454281379310345 ], [ 29.781859873417723, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20940, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.454281379310345 ], [ 29.782129367088608, -1.455089827586207 ], [ 29.782398860759493, -1.455089827586207 ], [ 29.782398860759493, -1.454281379310345 ], [ 29.782129367088608, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20941, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.454281379310345 ], [ 29.782668354430381, -1.454820344827586 ], [ 29.782937848101266, -1.454820344827586 ], [ 29.782937848101266, -1.454281379310345 ], [ 29.782668354430381, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20942, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.454281379310345 ], [ 29.782937848101266, -1.455089827586207 ], [ 29.783207341772151, -1.455089827586207 ], [ 29.783207341772151, -1.454281379310345 ], [ 29.782937848101266, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20943, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.454281379310345 ], [ 29.785363291139241, -1.454820344827586 ], [ 29.785632784810126, -1.454820344827586 ], [ 29.785632784810126, -1.454281379310345 ], [ 29.785363291139241, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20944, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.454281379310345 ], [ 29.786710759493673, -1.454820344827586 ], [ 29.786980253164558, -1.454820344827586 ], [ 29.786980253164558, -1.454281379310345 ], [ 29.786710759493673, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20945, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.454281379310345 ], [ 29.788327721518986, -1.455089827586207 ], [ 29.788597215189874, -1.455089827586207 ], [ 29.788597215189874, -1.454281379310345 ], [ 29.788327721518986, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20946, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.454281379310345 ], [ 29.796412531645569, -1.454820344827586 ], [ 29.796682025316457, -1.454820344827586 ], [ 29.796682025316457, -1.454281379310345 ], [ 29.796412531645569, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20947, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.454281379310345 ], [ 29.797221012658227, -1.455089827586207 ], [ 29.797490506329115, -1.455089827586207 ], [ 29.797490506329115, -1.454281379310345 ], [ 29.797221012658227, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20948, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.454281379310345 ], [ 29.797490506329115, -1.455089827586207 ], [ 29.79776, -1.455089827586207 ], [ 29.79776, -1.454281379310345 ], [ 29.797490506329115, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20949, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.454281379310345 ], [ 29.812582151898734, -1.454820344827586 ], [ 29.812851645569619, -1.454820344827586 ], [ 29.812851645569619, -1.454281379310345 ], [ 29.812582151898734, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20950, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.454281379310345 ], [ 29.812851645569619, -1.454820344827586 ], [ 29.813121139240508, -1.454820344827586 ], [ 29.813121139240508, -1.454281379310345 ], [ 29.812851645569619, -1.454281379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20951, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75032911392405, -1.454550862068966 ], [ 29.75032911392405, -1.455359310344828 ], [ 29.750598607594934, -1.455359310344828 ], [ 29.750598607594934, -1.454550862068966 ], [ 29.75032911392405, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20952, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.454550862068966 ], [ 29.750598607594934, -1.455089827586207 ], [ 29.750868101265823, -1.455089827586207 ], [ 29.750868101265823, -1.454550862068966 ], [ 29.750598607594934, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20953, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.454550862068966 ], [ 29.750868101265823, -1.455089827586207 ], [ 29.751137594936708, -1.455089827586207 ], [ 29.751137594936708, -1.454550862068966 ], [ 29.750868101265823, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20954, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.454550862068966 ], [ 29.751676582278481, -1.454820344827586 ], [ 29.751946075949366, -1.454820344827586 ], [ 29.751946075949366, -1.454550862068966 ], [ 29.751676582278481, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20955, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.454550862068966 ], [ 29.751946075949366, -1.454820344827586 ], [ 29.752215569620251, -1.454820344827586 ], [ 29.752215569620251, -1.454550862068966 ], [ 29.751946075949366, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20956, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.454550862068966 ], [ 29.752215569620251, -1.454820344827586 ], [ 29.75248506329114, -1.454820344827586 ], [ 29.75248506329114, -1.454550862068966 ], [ 29.752215569620251, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20957, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.454550862068966 ], [ 29.75248506329114, -1.454820344827586 ], [ 29.752754556962024, -1.454820344827586 ], [ 29.752754556962024, -1.454550862068966 ], [ 29.75248506329114, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20958, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.454550862068966 ], [ 29.752754556962024, -1.454820344827586 ], [ 29.753024050632909, -1.454820344827586 ], [ 29.753024050632909, -1.454550862068966 ], [ 29.752754556962024, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20959, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.454550862068966 ], [ 29.753024050632909, -1.455089827586207 ], [ 29.753293544303798, -1.455089827586207 ], [ 29.753293544303798, -1.454550862068966 ], [ 29.753024050632909, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20960, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.454550862068966 ], [ 29.753293544303798, -1.454820344827586 ], [ 29.753563037974683, -1.454820344827586 ], [ 29.753563037974683, -1.454550862068966 ], [ 29.753293544303798, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20961, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.454550862068966 ], [ 29.753563037974683, -1.454820344827586 ], [ 29.753832531645568, -1.454820344827586 ], [ 29.753832531645568, -1.454550862068966 ], [ 29.753563037974683, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20962, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.454550862068966 ], [ 29.753832531645568, -1.454820344827586 ], [ 29.754102025316456, -1.454820344827586 ], [ 29.754102025316456, -1.454550862068966 ], [ 29.753832531645568, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20963, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.454550862068966 ], [ 29.754371518987341, -1.454820344827586 ], [ 29.754641012658226, -1.454820344827586 ], [ 29.754641012658226, -1.454550862068966 ], [ 29.754371518987341, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20964, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.454550862068966 ], [ 29.754641012658226, -1.454820344827586 ], [ 29.754910506329114, -1.454820344827586 ], [ 29.754910506329114, -1.454550862068966 ], [ 29.754641012658226, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20965, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.454550862068966 ], [ 29.754910506329114, -1.454820344827586 ], [ 29.75518, -1.454820344827586 ], [ 29.75518, -1.454550862068966 ], [ 29.754910506329114, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20966, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.454550862068966 ], [ 29.75518, -1.454820344827586 ], [ 29.755449493670884, -1.454820344827586 ], [ 29.755449493670884, -1.454550862068966 ], [ 29.75518, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20967, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.454550862068966 ], [ 29.755449493670884, -1.454820344827586 ], [ 29.755718987341773, -1.454820344827586 ], [ 29.755718987341773, -1.454550862068966 ], [ 29.755449493670884, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20968, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.454550862068966 ], [ 29.755718987341773, -1.454820344827586 ], [ 29.755988481012658, -1.454820344827586 ], [ 29.755988481012658, -1.454550862068966 ], [ 29.755718987341773, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20969, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.454550862068966 ], [ 29.755988481012658, -1.454820344827586 ], [ 29.756257974683542, -1.454820344827586 ], [ 29.756257974683542, -1.454550862068966 ], [ 29.755988481012658, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20970, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.454550862068966 ], [ 29.756257974683542, -1.454820344827586 ], [ 29.756796962025316, -1.454820344827586 ], [ 29.756796962025316, -1.454550862068966 ], [ 29.756257974683542, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20971, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.454550862068966 ], [ 29.759491898734176, -1.455089827586207 ], [ 29.759761392405064, -1.455089827586207 ], [ 29.759761392405064, -1.454550862068966 ], [ 29.759491898734176, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20972, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.454550862068966 ], [ 29.760839367088607, -1.455089827586207 ], [ 29.761108860759492, -1.455089827586207 ], [ 29.761108860759492, -1.454550862068966 ], [ 29.760839367088607, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20973, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.454550862068966 ], [ 29.761647848101266, -1.454820344827586 ], [ 29.761917341772151, -1.454820344827586 ], [ 29.761917341772151, -1.454550862068966 ], [ 29.761647848101266, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20974, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.454550862068966 ], [ 29.761917341772151, -1.454820344827586 ], [ 29.762186835443039, -1.454820344827586 ], [ 29.762186835443039, -1.454550862068966 ], [ 29.761917341772151, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20975, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.454550862068966 ], [ 29.762186835443039, -1.454820344827586 ], [ 29.762456329113924, -1.454820344827586 ], [ 29.762456329113924, -1.454550862068966 ], [ 29.762186835443039, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20976, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.454550862068966 ], [ 29.762456329113924, -1.455359310344828 ], [ 29.762725822784809, -1.455359310344828 ], [ 29.762725822784809, -1.454550862068966 ], [ 29.762456329113924, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20977, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.454550862068966 ], [ 29.764612278481014, -1.455089827586207 ], [ 29.764881772151899, -1.455089827586207 ], [ 29.764881772151899, -1.454550862068966 ], [ 29.764612278481014, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20978, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.454550862068966 ], [ 29.764881772151899, -1.454820344827586 ], [ 29.765151265822784, -1.454820344827586 ], [ 29.765151265822784, -1.454550862068966 ], [ 29.764881772151899, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20979, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.454550862068966 ], [ 29.765151265822784, -1.454820344827586 ], [ 29.765420759493672, -1.454820344827586 ], [ 29.765420759493672, -1.454550862068966 ], [ 29.765151265822784, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20980, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.454550862068966 ], [ 29.765420759493672, -1.454820344827586 ], [ 29.765959746835442, -1.454820344827586 ], [ 29.765959746835442, -1.454550862068966 ], [ 29.765420759493672, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20981, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.454550862068966 ], [ 29.765959746835442, -1.454820344827586 ], [ 29.766229240506327, -1.454820344827586 ], [ 29.766229240506327, -1.454550862068966 ], [ 29.765959746835442, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20982, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.454550862068966 ], [ 29.768385189873417, -1.455359310344828 ], [ 29.768654683544302, -1.455359310344828 ], [ 29.768654683544302, -1.454550862068966 ], [ 29.768385189873417, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20983, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.454550862068966 ], [ 29.768654683544302, -1.454820344827586 ], [ 29.76892417721519, -1.454820344827586 ], [ 29.76892417721519, -1.454550862068966 ], [ 29.768654683544302, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20984, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.454550862068966 ], [ 29.76892417721519, -1.454820344827586 ], [ 29.769193670886075, -1.454820344827586 ], [ 29.769193670886075, -1.454550862068966 ], [ 29.76892417721519, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20985, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.454550862068966 ], [ 29.769193670886075, -1.454820344827586 ], [ 29.76946316455696, -1.454820344827586 ], [ 29.76946316455696, -1.454550862068966 ], [ 29.769193670886075, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20986, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.454550862068966 ], [ 29.76946316455696, -1.454820344827586 ], [ 29.769732658227849, -1.454820344827586 ], [ 29.769732658227849, -1.454550862068966 ], [ 29.76946316455696, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20987, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.454550862068966 ], [ 29.769732658227849, -1.454820344827586 ], [ 29.770002151898733, -1.454820344827586 ], [ 29.770002151898733, -1.454550862068966 ], [ 29.769732658227849, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20988, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.454550862068966 ], [ 29.770002151898733, -1.454820344827586 ], [ 29.770271645569618, -1.454820344827586 ], [ 29.770271645569618, -1.454550862068966 ], [ 29.770002151898733, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20989, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.454550862068966 ], [ 29.770271645569618, -1.454820344827586 ], [ 29.770541139240507, -1.454820344827586 ], [ 29.770541139240507, -1.454550862068966 ], [ 29.770271645569618, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20990, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.454550862068966 ], [ 29.770541139240507, -1.454820344827586 ], [ 29.770810632911392, -1.454820344827586 ], [ 29.770810632911392, -1.454550862068966 ], [ 29.770541139240507, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20991, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.454550862068966 ], [ 29.770810632911392, -1.454820344827586 ], [ 29.771080126582277, -1.454820344827586 ], [ 29.771080126582277, -1.454550862068966 ], [ 29.770810632911392, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20992, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.454550862068966 ], [ 29.771080126582277, -1.454820344827586 ], [ 29.771349620253165, -1.454820344827586 ], [ 29.771349620253165, -1.454550862068966 ], [ 29.771080126582277, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20993, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.454550862068966 ], [ 29.771349620253165, -1.454820344827586 ], [ 29.77161911392405, -1.454820344827586 ], [ 29.77161911392405, -1.454550862068966 ], [ 29.771349620253165, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20994, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.454550862068966 ], [ 29.771888607594935, -1.454820344827586 ], [ 29.772158101265823, -1.454820344827586 ], [ 29.772158101265823, -1.454550862068966 ], [ 29.771888607594935, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20995, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.454550862068966 ], [ 29.772158101265823, -1.454820344827586 ], [ 29.772427594936708, -1.454820344827586 ], [ 29.772427594936708, -1.454550862068966 ], [ 29.772158101265823, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20996, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.454550862068966 ], [ 29.772697088607593, -1.455089827586207 ], [ 29.772966582278482, -1.455089827586207 ], [ 29.772966582278482, -1.454550862068966 ], [ 29.772697088607593, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20997, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.454550862068966 ], [ 29.772966582278482, -1.454820344827586 ], [ 29.773236075949367, -1.454820344827586 ], [ 29.773236075949367, -1.454550862068966 ], [ 29.772966582278482, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20998, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.454550862068966 ], [ 29.773505569620252, -1.455089827586207 ], [ 29.77377506329114, -1.455089827586207 ], [ 29.77377506329114, -1.454550862068966 ], [ 29.773505569620252, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 20999, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.454550862068966 ], [ 29.77431405063291, -1.455089827586207 ], [ 29.774853037974683, -1.455089827586207 ], [ 29.774853037974683, -1.454550862068966 ], [ 29.77431405063291, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21000, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.454550862068966 ], [ 29.775392025316457, -1.455089827586207 ], [ 29.775661518987341, -1.455089827586207 ], [ 29.775661518987341, -1.454550862068966 ], [ 29.775392025316457, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21001, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.454550862068966 ], [ 29.775931012658226, -1.455089827586207 ], [ 29.776200506329115, -1.455089827586207 ], [ 29.776200506329115, -1.454550862068966 ], [ 29.775931012658226, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21002, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.454550862068966 ], [ 29.776739493670885, -1.455089827586207 ], [ 29.777008987341773, -1.455089827586207 ], [ 29.777008987341773, -1.454550862068966 ], [ 29.776739493670885, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21003, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.454550862068966 ], [ 29.778086962025316, -1.455359310344828 ], [ 29.778356455696201, -1.455359310344828 ], [ 29.778356455696201, -1.454550862068966 ], [ 29.778086962025316, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21004, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.454550862068966 ], [ 29.778895443037975, -1.454820344827586 ], [ 29.77916493670886, -1.454820344827586 ], [ 29.77916493670886, -1.454550862068966 ], [ 29.778895443037975, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21005, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.454550862068966 ], [ 29.77916493670886, -1.454820344827586 ], [ 29.779434430379748, -1.454820344827586 ], [ 29.779434430379748, -1.454550862068966 ], [ 29.77916493670886, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21006, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.454550862068966 ], [ 29.779434430379748, -1.454820344827586 ], [ 29.779703924050633, -1.454820344827586 ], [ 29.779703924050633, -1.454550862068966 ], [ 29.779434430379748, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21007, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.454550862068966 ], [ 29.779703924050633, -1.454820344827586 ], [ 29.779973417721518, -1.454820344827586 ], [ 29.779973417721518, -1.454550862068966 ], [ 29.779703924050633, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21008, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.454550862068966 ], [ 29.779973417721518, -1.454820344827586 ], [ 29.780242911392406, -1.454820344827586 ], [ 29.780242911392406, -1.454550862068966 ], [ 29.779973417721518, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21009, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.454550862068966 ], [ 29.780242911392406, -1.454820344827586 ], [ 29.780512405063291, -1.454820344827586 ], [ 29.780512405063291, -1.454550862068966 ], [ 29.780242911392406, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21010, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.454550862068966 ], [ 29.780512405063291, -1.454820344827586 ], [ 29.780781898734176, -1.454820344827586 ], [ 29.780781898734176, -1.454550862068966 ], [ 29.780512405063291, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21011, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.454550862068966 ], [ 29.780781898734176, -1.454820344827586 ], [ 29.781051392405065, -1.454820344827586 ], [ 29.781051392405065, -1.454550862068966 ], [ 29.780781898734176, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21012, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.454550862068966 ], [ 29.781051392405065, -1.454820344827586 ], [ 29.78132088607595, -1.454820344827586 ], [ 29.78132088607595, -1.454550862068966 ], [ 29.781051392405065, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21013, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.454550862068966 ], [ 29.78132088607595, -1.454820344827586 ], [ 29.781590379746834, -1.454820344827586 ], [ 29.781590379746834, -1.454550862068966 ], [ 29.78132088607595, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21014, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.454550862068966 ], [ 29.781590379746834, -1.455089827586207 ], [ 29.781859873417723, -1.455089827586207 ], [ 29.781859873417723, -1.454550862068966 ], [ 29.781590379746834, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21015, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.454550862068966 ], [ 29.782398860759493, -1.455089827586207 ], [ 29.782668354430381, -1.455089827586207 ], [ 29.782668354430381, -1.454550862068966 ], [ 29.782398860759493, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21016, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.454550862068966 ], [ 29.783207341772151, -1.455089827586207 ], [ 29.783476835443039, -1.455089827586207 ], [ 29.783476835443039, -1.454550862068966 ], [ 29.783207341772151, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21017, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.454550862068966 ], [ 29.784015822784809, -1.455089827586207 ], [ 29.784285316455698, -1.455089827586207 ], [ 29.784285316455698, -1.454550862068966 ], [ 29.784015822784809, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21018, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.454550862068966 ], [ 29.784824303797468, -1.455089827586207 ], [ 29.785093797468356, -1.455089827586207 ], [ 29.785093797468356, -1.454550862068966 ], [ 29.784824303797468, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21019, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.454550862068966 ], [ 29.785632784810126, -1.455089827586207 ], [ 29.785902278481014, -1.455089827586207 ], [ 29.785902278481014, -1.454550862068966 ], [ 29.785632784810126, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21020, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.454550862068966 ], [ 29.786171772151899, -1.454820344827586 ], [ 29.786441265822784, -1.454820344827586 ], [ 29.786441265822784, -1.454550862068966 ], [ 29.786171772151899, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21021, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.454550862068966 ], [ 29.786441265822784, -1.454820344827586 ], [ 29.786710759493673, -1.454820344827586 ], [ 29.786710759493673, -1.454550862068966 ], [ 29.786441265822784, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21022, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.454550862068966 ], [ 29.786980253164558, -1.454820344827586 ], [ 29.787249746835442, -1.454820344827586 ], [ 29.787249746835442, -1.454550862068966 ], [ 29.786980253164558, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21023, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.454550862068966 ], [ 29.787249746835442, -1.454820344827586 ], [ 29.787519240506327, -1.454820344827586 ], [ 29.787519240506327, -1.454550862068966 ], [ 29.787249746835442, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21024, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.454550862068966 ], [ 29.787519240506327, -1.454820344827586 ], [ 29.787788734177216, -1.454820344827586 ], [ 29.787788734177216, -1.454550862068966 ], [ 29.787519240506327, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21025, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.454550862068966 ], [ 29.787788734177216, -1.455089827586207 ], [ 29.788058227848101, -1.455089827586207 ], [ 29.788058227848101, -1.454550862068966 ], [ 29.787788734177216, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21026, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.454550862068966 ], [ 29.788058227848101, -1.455089827586207 ], [ 29.788327721518986, -1.455089827586207 ], [ 29.788327721518986, -1.454550862068966 ], [ 29.788058227848101, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21027, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.454550862068966 ], [ 29.795334556962025, -1.454820344827586 ], [ 29.79560405063291, -1.454820344827586 ], [ 29.79560405063291, -1.454550862068966 ], [ 29.795334556962025, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21028, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.454550862068966 ], [ 29.79560405063291, -1.454820344827586 ], [ 29.795873544303799, -1.454820344827586 ], [ 29.795873544303799, -1.454550862068966 ], [ 29.79560405063291, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21029, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.454550862068966 ], [ 29.795873544303799, -1.455089827586207 ], [ 29.796143037974684, -1.455089827586207 ], [ 29.796143037974684, -1.454550862068966 ], [ 29.795873544303799, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21030, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.454550862068966 ], [ 29.796143037974684, -1.455359310344828 ], [ 29.796412531645569, -1.455359310344828 ], [ 29.796412531645569, -1.454550862068966 ], [ 29.796143037974684, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21031, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.454550862068966 ], [ 29.796682025316457, -1.455089827586207 ], [ 29.796951518987342, -1.455089827586207 ], [ 29.796951518987342, -1.454550862068966 ], [ 29.796682025316457, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21032, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.454550862068966 ], [ 29.796951518987342, -1.455359310344828 ], [ 29.797221012658227, -1.455359310344828 ], [ 29.797221012658227, -1.454550862068966 ], [ 29.796951518987342, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21033, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.454550862068966 ], [ 29.807192278481015, -1.454820344827586 ], [ 29.8074617721519, -1.454820344827586 ], [ 29.8074617721519, -1.454550862068966 ], [ 29.807192278481015, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21034, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.454550862068966 ], [ 29.8074617721519, -1.454820344827586 ], [ 29.807731265822785, -1.454820344827586 ], [ 29.807731265822785, -1.454550862068966 ], [ 29.8074617721519, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21035, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.454550862068966 ], [ 29.807731265822785, -1.454820344827586 ], [ 29.808809240506328, -1.454820344827586 ], [ 29.808809240506328, -1.454550862068966 ], [ 29.807731265822785, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21036, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.454550862068966 ], [ 29.808809240506328, -1.454820344827586 ], [ 29.809078734177216, -1.454820344827586 ], [ 29.809078734177216, -1.454550862068966 ], [ 29.808809240506328, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21037, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.454550862068966 ], [ 29.809078734177216, -1.454820344827586 ], [ 29.809348227848101, -1.454820344827586 ], [ 29.809348227848101, -1.454550862068966 ], [ 29.809078734177216, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21038, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.454550862068966 ], [ 29.809348227848101, -1.454820344827586 ], [ 29.809617721518986, -1.454820344827586 ], [ 29.809617721518986, -1.454550862068966 ], [ 29.809348227848101, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21039, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.454550862068966 ], [ 29.809617721518986, -1.454820344827586 ], [ 29.809887215189875, -1.454820344827586 ], [ 29.809887215189875, -1.454550862068966 ], [ 29.809617721518986, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21040, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.454550862068966 ], [ 29.809887215189875, -1.454820344827586 ], [ 29.81015670886076, -1.454820344827586 ], [ 29.81015670886076, -1.454550862068966 ], [ 29.809887215189875, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21041, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.454550862068966 ], [ 29.81015670886076, -1.454820344827586 ], [ 29.810426202531644, -1.454820344827586 ], [ 29.810426202531644, -1.454550862068966 ], [ 29.81015670886076, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21042, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.454550862068966 ], [ 29.810426202531644, -1.454820344827586 ], [ 29.810965189873418, -1.454820344827586 ], [ 29.810965189873418, -1.455089827586207 ], [ 29.811234683544303, -1.455089827586207 ], [ 29.811234683544303, -1.454550862068966 ], [ 29.810426202531644, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21043, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.454550862068966 ], [ 29.811234683544303, -1.454820344827586 ], [ 29.811504177215191, -1.454820344827586 ], [ 29.811504177215191, -1.454550862068966 ], [ 29.811234683544303, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21044, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.454550862068966 ], [ 29.811504177215191, -1.454820344827586 ], [ 29.811773670886076, -1.454820344827586 ], [ 29.811773670886076, -1.454550862068966 ], [ 29.811504177215191, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21045, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.454550862068966 ], [ 29.811773670886076, -1.454820344827586 ], [ 29.812043164556961, -1.454820344827586 ], [ 29.812043164556961, -1.454550862068966 ], [ 29.811773670886076, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21046, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.454550862068966 ], [ 29.812043164556961, -1.455089827586207 ], [ 29.812312658227849, -1.455089827586207 ], [ 29.812312658227849, -1.454550862068966 ], [ 29.812043164556961, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21047, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.454550862068966 ], [ 29.812312658227849, -1.455089827586207 ], [ 29.812582151898734, -1.455089827586207 ], [ 29.812582151898734, -1.454550862068966 ], [ 29.812312658227849, -1.454550862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21048, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.454820344827586 ], [ 29.751137594936708, -1.455089827586207 ], [ 29.751407088607593, -1.455089827586207 ], [ 29.751407088607593, -1.454820344827586 ], [ 29.751137594936708, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21049, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.454820344827586 ], [ 29.751407088607593, -1.455089827586207 ], [ 29.751676582278481, -1.455089827586207 ], [ 29.751676582278481, -1.454820344827586 ], [ 29.751407088607593, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21050, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.454820344827586 ], [ 29.751676582278481, -1.455089827586207 ], [ 29.751946075949366, -1.455089827586207 ], [ 29.751946075949366, -1.454820344827586 ], [ 29.751676582278481, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21051, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.454820344827586 ], [ 29.751946075949366, -1.455359310344828 ], [ 29.752215569620251, -1.455359310344828 ], [ 29.752215569620251, -1.454820344827586 ], [ 29.751946075949366, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21052, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.454820344827586 ], [ 29.752215569620251, -1.455089827586207 ], [ 29.75248506329114, -1.455089827586207 ], [ 29.75248506329114, -1.454820344827586 ], [ 29.752215569620251, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21053, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.454820344827586 ], [ 29.75248506329114, -1.455089827586207 ], [ 29.752754556962024, -1.455089827586207 ], [ 29.752754556962024, -1.454820344827586 ], [ 29.75248506329114, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21054, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.454820344827586 ], [ 29.752754556962024, -1.455089827586207 ], [ 29.753024050632909, -1.455089827586207 ], [ 29.753024050632909, -1.454820344827586 ], [ 29.752754556962024, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21055, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.454820344827586 ], [ 29.753293544303798, -1.455089827586207 ], [ 29.753563037974683, -1.455089827586207 ], [ 29.753563037974683, -1.454820344827586 ], [ 29.753293544303798, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21056, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.454820344827586 ], [ 29.753563037974683, -1.455089827586207 ], [ 29.753832531645568, -1.455089827586207 ], [ 29.753832531645568, -1.454820344827586 ], [ 29.753563037974683, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21057, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.454820344827586 ], [ 29.753832531645568, -1.455089827586207 ], [ 29.754102025316456, -1.455089827586207 ], [ 29.754102025316456, -1.454820344827586 ], [ 29.753832531645568, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21058, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.454820344827586 ], [ 29.754102025316456, -1.455089827586207 ], [ 29.754371518987341, -1.455089827586207 ], [ 29.754371518987341, -1.454820344827586 ], [ 29.754102025316456, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21059, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.454820344827586 ], [ 29.754371518987341, -1.455089827586207 ], [ 29.754641012658226, -1.455089827586207 ], [ 29.754641012658226, -1.454820344827586 ], [ 29.754371518987341, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21060, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.454820344827586 ], [ 29.754641012658226, -1.455089827586207 ], [ 29.754910506329114, -1.455089827586207 ], [ 29.754910506329114, -1.454820344827586 ], [ 29.754641012658226, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21061, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.454820344827586 ], [ 29.754910506329114, -1.455089827586207 ], [ 29.75518, -1.455089827586207 ], [ 29.75518, -1.454820344827586 ], [ 29.754910506329114, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21062, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.454820344827586 ], [ 29.75518, -1.455089827586207 ], [ 29.755449493670884, -1.455089827586207 ], [ 29.755449493670884, -1.454820344827586 ], [ 29.75518, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21063, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.454820344827586 ], [ 29.755449493670884, -1.455089827586207 ], [ 29.755718987341773, -1.455089827586207 ], [ 29.755718987341773, -1.454820344827586 ], [ 29.755449493670884, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21064, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.454820344827586 ], [ 29.755718987341773, -1.455089827586207 ], [ 29.755988481012658, -1.455089827586207 ], [ 29.755988481012658, -1.454820344827586 ], [ 29.755718987341773, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21065, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.454820344827586 ], [ 29.755988481012658, -1.455089827586207 ], [ 29.756257974683542, -1.455089827586207 ], [ 29.756257974683542, -1.454820344827586 ], [ 29.755988481012658, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21066, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.454820344827586 ], [ 29.756257974683542, -1.455089827586207 ], [ 29.756796962025316, -1.455089827586207 ], [ 29.756796962025316, -1.454820344827586 ], [ 29.756257974683542, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21067, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.454820344827586 ], [ 29.756796962025316, -1.455359310344828 ], [ 29.757066455696201, -1.455359310344828 ], [ 29.757066455696201, -1.454820344827586 ], [ 29.756796962025316, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21068, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.454820344827586 ], [ 29.757605443037974, -1.455359310344828 ], [ 29.757874936708859, -1.455359310344828 ], [ 29.757874936708859, -1.454820344827586 ], [ 29.757605443037974, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21069, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.454820344827586 ], [ 29.758144430379748, -1.455359310344828 ], [ 29.758413924050632, -1.455359310344828 ], [ 29.758413924050632, -1.454820344827586 ], [ 29.758144430379748, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21070, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.454820344827586 ], [ 29.758952911392406, -1.455359310344828 ], [ 29.759222405063291, -1.455359310344828 ], [ 29.759222405063291, -1.454820344827586 ], [ 29.758952911392406, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21071, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.454820344827586 ], [ 29.759761392405064, -1.455359310344828 ], [ 29.760030886075949, -1.455359310344828 ], [ 29.760030886075949, -1.454820344827586 ], [ 29.759761392405064, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21072, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.454820344827586 ], [ 29.760300379746834, -1.455089827586207 ], [ 29.760569873417722, -1.455089827586207 ], [ 29.760569873417722, -1.454820344827586 ], [ 29.760300379746834, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21073, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.454820344827586 ], [ 29.760569873417722, -1.455089827586207 ], [ 29.760839367088607, -1.455089827586207 ], [ 29.760839367088607, -1.454820344827586 ], [ 29.760569873417722, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21074, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.454820344827586 ], [ 29.761108860759492, -1.455089827586207 ], [ 29.761378354430381, -1.455089827586207 ], [ 29.761378354430381, -1.454820344827586 ], [ 29.761108860759492, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21075, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.454820344827586 ], [ 29.761378354430381, -1.455089827586207 ], [ 29.761647848101266, -1.455089827586207 ], [ 29.761647848101266, -1.454820344827586 ], [ 29.761378354430381, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21076, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.454820344827586 ], [ 29.761647848101266, -1.455089827586207 ], [ 29.761917341772151, -1.455089827586207 ], [ 29.761917341772151, -1.454820344827586 ], [ 29.761647848101266, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21077, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.454820344827586 ], [ 29.761917341772151, -1.455359310344828 ], [ 29.762186835443039, -1.455359310344828 ], [ 29.762186835443039, -1.454820344827586 ], [ 29.761917341772151, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21078, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.454820344827586 ], [ 29.762186835443039, -1.455359310344828 ], [ 29.762456329113924, -1.455359310344828 ], [ 29.762456329113924, -1.454820344827586 ], [ 29.762186835443039, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21079, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.454820344827586 ], [ 29.763534303797467, -1.455089827586207 ], [ 29.763803797468356, -1.455089827586207 ], [ 29.763803797468356, -1.454820344827586 ], [ 29.763534303797467, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21080, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.454820344827586 ], [ 29.763803797468356, -1.455089827586207 ], [ 29.76407329113924, -1.455089827586207 ], [ 29.76407329113924, -1.454820344827586 ], [ 29.763803797468356, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21081, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.454820344827586 ], [ 29.76407329113924, -1.455089827586207 ], [ 29.764342784810125, -1.455089827586207 ], [ 29.764342784810125, -1.454820344827586 ], [ 29.76407329113924, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21082, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.454820344827586 ], [ 29.764342784810125, -1.455089827586207 ], [ 29.764612278481014, -1.455089827586207 ], [ 29.764612278481014, -1.454820344827586 ], [ 29.764342784810125, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21083, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.454820344827586 ], [ 29.764881772151899, -1.455089827586207 ], [ 29.765151265822784, -1.455089827586207 ], [ 29.765151265822784, -1.454820344827586 ], [ 29.764881772151899, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21084, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.454820344827586 ], [ 29.765151265822784, -1.455089827586207 ], [ 29.765420759493672, -1.455089827586207 ], [ 29.765420759493672, -1.454820344827586 ], [ 29.765151265822784, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21085, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.454820344827586 ], [ 29.765420759493672, -1.455089827586207 ], [ 29.765959746835442, -1.455089827586207 ], [ 29.765959746835442, -1.454820344827586 ], [ 29.765420759493672, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21086, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.454820344827586 ], [ 29.765959746835442, -1.455089827586207 ], [ 29.766229240506327, -1.455089827586207 ], [ 29.766229240506327, -1.454820344827586 ], [ 29.765959746835442, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21087, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.454820344827586 ], [ 29.766229240506327, -1.455089827586207 ], [ 29.766498734177215, -1.455089827586207 ], [ 29.766498734177215, -1.454820344827586 ], [ 29.766229240506327, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21088, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.454820344827586 ], [ 29.768654683544302, -1.455359310344828 ], [ 29.76892417721519, -1.455359310344828 ], [ 29.76892417721519, -1.454820344827586 ], [ 29.768654683544302, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21089, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.454820344827586 ], [ 29.76892417721519, -1.455359310344828 ], [ 29.769193670886075, -1.455359310344828 ], [ 29.769193670886075, -1.454820344827586 ], [ 29.76892417721519, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21090, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.454820344827586 ], [ 29.769193670886075, -1.455089827586207 ], [ 29.76946316455696, -1.455089827586207 ], [ 29.76946316455696, -1.454820344827586 ], [ 29.769193670886075, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21091, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.454820344827586 ], [ 29.76946316455696, -1.455089827586207 ], [ 29.769732658227849, -1.455089827586207 ], [ 29.769732658227849, -1.454820344827586 ], [ 29.76946316455696, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21092, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.454820344827586 ], [ 29.769732658227849, -1.455089827586207 ], [ 29.770002151898733, -1.455089827586207 ], [ 29.770002151898733, -1.454820344827586 ], [ 29.769732658227849, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21093, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.454820344827586 ], [ 29.770002151898733, -1.455089827586207 ], [ 29.770271645569618, -1.455089827586207 ], [ 29.770271645569618, -1.454820344827586 ], [ 29.770002151898733, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21094, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.454820344827586 ], [ 29.770271645569618, -1.455089827586207 ], [ 29.770541139240507, -1.455089827586207 ], [ 29.770541139240507, -1.454820344827586 ], [ 29.770271645569618, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21095, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.454820344827586 ], [ 29.770541139240507, -1.455089827586207 ], [ 29.770810632911392, -1.455089827586207 ], [ 29.770810632911392, -1.454820344827586 ], [ 29.770541139240507, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21096, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.454820344827586 ], [ 29.770810632911392, -1.455089827586207 ], [ 29.771080126582277, -1.455089827586207 ], [ 29.771080126582277, -1.454820344827586 ], [ 29.770810632911392, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21097, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.454820344827586 ], [ 29.771080126582277, -1.455089827586207 ], [ 29.771349620253165, -1.455089827586207 ], [ 29.771349620253165, -1.454820344827586 ], [ 29.771080126582277, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21098, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.454820344827586 ], [ 29.771349620253165, -1.455089827586207 ], [ 29.77161911392405, -1.455089827586207 ], [ 29.77161911392405, -1.454820344827586 ], [ 29.771349620253165, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21099, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.454820344827586 ], [ 29.77161911392405, -1.455089827586207 ], [ 29.771888607594935, -1.455089827586207 ], [ 29.771888607594935, -1.454820344827586 ], [ 29.77161911392405, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21100, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.454820344827586 ], [ 29.771888607594935, -1.455089827586207 ], [ 29.772158101265823, -1.455089827586207 ], [ 29.772158101265823, -1.454820344827586 ], [ 29.771888607594935, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21101, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.454820344827586 ], [ 29.772158101265823, -1.455089827586207 ], [ 29.772427594936708, -1.455089827586207 ], [ 29.772427594936708, -1.454820344827586 ], [ 29.772158101265823, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21102, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.454820344827586 ], [ 29.772427594936708, -1.455089827586207 ], [ 29.772697088607593, -1.455089827586207 ], [ 29.772697088607593, -1.454820344827586 ], [ 29.772427594936708, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21103, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.454820344827586 ], [ 29.772966582278482, -1.455089827586207 ], [ 29.773236075949367, -1.455089827586207 ], [ 29.773236075949367, -1.454820344827586 ], [ 29.772966582278482, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21104, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.454820344827586 ], [ 29.773236075949367, -1.455089827586207 ], [ 29.773505569620252, -1.455089827586207 ], [ 29.773505569620252, -1.454820344827586 ], [ 29.773236075949367, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21105, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.454820344827586 ], [ 29.77377506329114, -1.455089827586207 ], [ 29.774044556962025, -1.455089827586207 ], [ 29.774044556962025, -1.454820344827586 ], [ 29.77377506329114, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21106, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.454820344827586 ], [ 29.774044556962025, -1.455089827586207 ], [ 29.77431405063291, -1.455089827586207 ], [ 29.77431405063291, -1.454820344827586 ], [ 29.774044556962025, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21107, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.454820344827586 ], [ 29.774853037974683, -1.455359310344828 ], [ 29.775122531645568, -1.455359310344828 ], [ 29.775122531645568, -1.454820344827586 ], [ 29.774853037974683, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21108, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.454820344827586 ], [ 29.775122531645568, -1.455089827586207 ], [ 29.775392025316457, -1.455089827586207 ], [ 29.775392025316457, -1.454820344827586 ], [ 29.775122531645568, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21109, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.454820344827586 ], [ 29.775661518987341, -1.455359310344828 ], [ 29.775931012658226, -1.455359310344828 ], [ 29.775931012658226, -1.454820344827586 ], [ 29.775661518987341, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21110, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.454820344827586 ], [ 29.77647, -1.455359310344828 ], [ 29.776739493670885, -1.455359310344828 ], [ 29.776739493670885, -1.454820344827586 ], [ 29.77647, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21111, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.454820344827586 ], [ 29.777008987341773, -1.455359310344828 ], [ 29.777278481012658, -1.455359310344828 ], [ 29.777278481012658, -1.454820344827586 ], [ 29.777008987341773, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21112, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.454820344827586 ], [ 29.777278481012658, -1.455359310344828 ], [ 29.777547974683543, -1.455359310344828 ], [ 29.777547974683543, -1.454820344827586 ], [ 29.777278481012658, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21113, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.454820344827586 ], [ 29.777817468354431, -1.455359310344828 ], [ 29.778086962025316, -1.455359310344828 ], [ 29.778086962025316, -1.454820344827586 ], [ 29.777817468354431, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21114, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.454820344827586 ], [ 29.77862594936709, -1.455089827586207 ], [ 29.778895443037975, -1.455089827586207 ], [ 29.778895443037975, -1.454820344827586 ], [ 29.77862594936709, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21115, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.454820344827586 ], [ 29.778895443037975, -1.455089827586207 ], [ 29.77916493670886, -1.455089827586207 ], [ 29.77916493670886, -1.454820344827586 ], [ 29.778895443037975, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21116, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.454820344827586 ], [ 29.77916493670886, -1.455089827586207 ], [ 29.779434430379748, -1.455089827586207 ], [ 29.779434430379748, -1.454820344827586 ], [ 29.77916493670886, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21117, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.454820344827586 ], [ 29.779434430379748, -1.455089827586207 ], [ 29.779703924050633, -1.455089827586207 ], [ 29.779703924050633, -1.454820344827586 ], [ 29.779434430379748, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21118, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.454820344827586 ], [ 29.779703924050633, -1.455089827586207 ], [ 29.779973417721518, -1.455089827586207 ], [ 29.779973417721518, -1.454820344827586 ], [ 29.779703924050633, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21119, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.454820344827586 ], [ 29.779973417721518, -1.455089827586207 ], [ 29.780242911392406, -1.455089827586207 ], [ 29.780242911392406, -1.454820344827586 ], [ 29.779973417721518, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21120, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.454820344827586 ], [ 29.780242911392406, -1.455089827586207 ], [ 29.780512405063291, -1.455089827586207 ], [ 29.780512405063291, -1.454820344827586 ], [ 29.780242911392406, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21121, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.454820344827586 ], [ 29.780512405063291, -1.455359310344828 ], [ 29.780781898734176, -1.455359310344828 ], [ 29.780781898734176, -1.454820344827586 ], [ 29.780512405063291, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21122, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.454820344827586 ], [ 29.780781898734176, -1.455089827586207 ], [ 29.781051392405065, -1.455089827586207 ], [ 29.781051392405065, -1.454820344827586 ], [ 29.780781898734176, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21123, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.454820344827586 ], [ 29.781051392405065, -1.455359310344828 ], [ 29.78132088607595, -1.455359310344828 ], [ 29.78132088607595, -1.454820344827586 ], [ 29.781051392405065, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21124, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.454820344827586 ], [ 29.78132088607595, -1.455359310344828 ], [ 29.781590379746834, -1.455359310344828 ], [ 29.781590379746834, -1.454820344827586 ], [ 29.78132088607595, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21125, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.454820344827586 ], [ 29.781859873417723, -1.455359310344828 ], [ 29.782129367088608, -1.455359310344828 ], [ 29.782129367088608, -1.454820344827586 ], [ 29.781859873417723, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21126, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.454820344827586 ], [ 29.782668354430381, -1.455359310344828 ], [ 29.782937848101266, -1.455359310344828 ], [ 29.782937848101266, -1.454820344827586 ], [ 29.782668354430381, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21127, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.454820344827586 ], [ 29.783476835443039, -1.455359310344828 ], [ 29.784015822784809, -1.455359310344828 ], [ 29.784015822784809, -1.454820344827586 ], [ 29.783476835443039, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21128, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.454820344827586 ], [ 29.784285316455698, -1.455089827586207 ], [ 29.784554810126583, -1.455089827586207 ], [ 29.784554810126583, -1.454820344827586 ], [ 29.784285316455698, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21129, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.454820344827586 ], [ 29.784554810126583, -1.455359310344828 ], [ 29.784824303797468, -1.455359310344828 ], [ 29.784824303797468, -1.454820344827586 ], [ 29.784554810126583, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21130, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.454820344827586 ], [ 29.785093797468356, -1.455089827586207 ], [ 29.785363291139241, -1.455089827586207 ], [ 29.785363291139241, -1.454820344827586 ], [ 29.785093797468356, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21131, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.454820344827586 ], [ 29.785363291139241, -1.455089827586207 ], [ 29.785632784810126, -1.455089827586207 ], [ 29.785632784810126, -1.454820344827586 ], [ 29.785363291139241, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21132, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.454820344827586 ], [ 29.785902278481014, -1.455089827586207 ], [ 29.786171772151899, -1.455089827586207 ], [ 29.786171772151899, -1.454820344827586 ], [ 29.785902278481014, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21133, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.454820344827586 ], [ 29.786171772151899, -1.455089827586207 ], [ 29.786441265822784, -1.455089827586207 ], [ 29.786441265822784, -1.454820344827586 ], [ 29.786171772151899, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21134, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.454820344827586 ], [ 29.786441265822784, -1.455089827586207 ], [ 29.786710759493673, -1.455089827586207 ], [ 29.786710759493673, -1.454820344827586 ], [ 29.786441265822784, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21135, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.454820344827586 ], [ 29.786710759493673, -1.455089827586207 ], [ 29.786980253164558, -1.455089827586207 ], [ 29.786980253164558, -1.454820344827586 ], [ 29.786710759493673, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21136, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.454820344827586 ], [ 29.786980253164558, -1.455089827586207 ], [ 29.787249746835442, -1.455089827586207 ], [ 29.787249746835442, -1.454820344827586 ], [ 29.786980253164558, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21137, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.454820344827586 ], [ 29.787249746835442, -1.455359310344828 ], [ 29.787519240506327, -1.455359310344828 ], [ 29.787519240506327, -1.454820344827586 ], [ 29.787249746835442, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21138, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.454820344827586 ], [ 29.787519240506327, -1.455359310344828 ], [ 29.787788734177216, -1.455359310344828 ], [ 29.787788734177216, -1.454820344827586 ], [ 29.787519240506327, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21139, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.454820344827586 ], [ 29.79506506329114, -1.455089827586207 ], [ 29.795334556962025, -1.455089827586207 ], [ 29.795334556962025, -1.454820344827586 ], [ 29.79506506329114, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21140, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.454820344827586 ], [ 29.795334556962025, -1.455089827586207 ], [ 29.79560405063291, -1.455089827586207 ], [ 29.79560405063291, -1.454820344827586 ], [ 29.795334556962025, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21141, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.454820344827586 ], [ 29.79560405063291, -1.455359310344828 ], [ 29.795873544303799, -1.455359310344828 ], [ 29.795873544303799, -1.454820344827586 ], [ 29.79560405063291, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21142, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.454820344827586 ], [ 29.796412531645569, -1.455359310344828 ], [ 29.796682025316457, -1.455359310344828 ], [ 29.796682025316457, -1.454820344827586 ], [ 29.796412531645569, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21143, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.454820344827586 ], [ 29.79776, -1.455359310344828 ], [ 29.798029493670885, -1.455359310344828 ], [ 29.798029493670885, -1.454820344827586 ], [ 29.79776, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21144, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.454820344827586 ], [ 29.798568481012659, -1.455089827586207 ], [ 29.798837974683543, -1.455089827586207 ], [ 29.798837974683543, -1.454820344827586 ], [ 29.798568481012659, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21145, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.454820344827586 ], [ 29.798837974683543, -1.455089827586207 ], [ 29.799107468354432, -1.455089827586207 ], [ 29.799107468354432, -1.454820344827586 ], [ 29.798837974683543, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21146, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.454820344827586 ], [ 29.799107468354432, -1.455089827586207 ], [ 29.799376962025317, -1.455089827586207 ], [ 29.799376962025317, -1.454820344827586 ], [ 29.799107468354432, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21147, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.454820344827586 ], [ 29.799376962025317, -1.455898275862069 ], [ 29.799646455696202, -1.455898275862069 ], [ 29.799646455696202, -1.454820344827586 ], [ 29.799376962025317, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21148, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.454820344827586 ], [ 29.807192278481015, -1.455089827586207 ], [ 29.8074617721519, -1.455089827586207 ], [ 29.8074617721519, -1.454820344827586 ], [ 29.807192278481015, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21149, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.454820344827586 ], [ 29.8074617721519, -1.455089827586207 ], [ 29.808539746835443, -1.455089827586207 ], [ 29.808539746835443, -1.454820344827586 ], [ 29.8074617721519, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21150, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.454820344827586 ], [ 29.808539746835443, -1.455089827586207 ], [ 29.808809240506328, -1.455089827586207 ], [ 29.808809240506328, -1.454820344827586 ], [ 29.808539746835443, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21151, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.454820344827586 ], [ 29.808809240506328, -1.455089827586207 ], [ 29.809078734177216, -1.455089827586207 ], [ 29.809078734177216, -1.454820344827586 ], [ 29.808809240506328, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21152, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.454820344827586 ], [ 29.809078734177216, -1.455089827586207 ], [ 29.809348227848101, -1.455089827586207 ], [ 29.809348227848101, -1.454820344827586 ], [ 29.809078734177216, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21153, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.454820344827586 ], [ 29.809348227848101, -1.455089827586207 ], [ 29.809617721518986, -1.455089827586207 ], [ 29.809617721518986, -1.454820344827586 ], [ 29.809348227848101, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21154, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.454820344827586 ], [ 29.809617721518986, -1.455089827586207 ], [ 29.809887215189875, -1.455089827586207 ], [ 29.809887215189875, -1.454820344827586 ], [ 29.809617721518986, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21155, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.454820344827586 ], [ 29.809887215189875, -1.455089827586207 ], [ 29.81015670886076, -1.455089827586207 ], [ 29.81015670886076, -1.454820344827586 ], [ 29.809887215189875, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21156, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.454820344827586 ], [ 29.81015670886076, -1.455089827586207 ], [ 29.810426202531644, -1.455089827586207 ], [ 29.810426202531644, -1.454820344827586 ], [ 29.81015670886076, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21157, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.454820344827586 ], [ 29.810426202531644, -1.455089827586207 ], [ 29.810965189873418, -1.455089827586207 ], [ 29.810965189873418, -1.454820344827586 ], [ 29.810426202531644, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21158, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.454820344827586 ], [ 29.811234683544303, -1.455089827586207 ], [ 29.811504177215191, -1.455089827586207 ], [ 29.811504177215191, -1.454820344827586 ], [ 29.811234683544303, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21159, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.454820344827586 ], [ 29.811504177215191, -1.455089827586207 ], [ 29.811773670886076, -1.455089827586207 ], [ 29.811773670886076, -1.454820344827586 ], [ 29.811504177215191, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21160, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.454820344827586 ], [ 29.811773670886076, -1.455359310344828 ], [ 29.812043164556961, -1.455359310344828 ], [ 29.812043164556961, -1.454820344827586 ], [ 29.811773670886076, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21161, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.454820344827586 ], [ 29.812582151898734, -1.455089827586207 ], [ 29.812851645569619, -1.455089827586207 ], [ 29.812851645569619, -1.454820344827586 ], [ 29.812582151898734, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21162, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.454820344827586 ], [ 29.812851645569619, -1.455089827586207 ], [ 29.813121139240508, -1.455089827586207 ], [ 29.813121139240508, -1.454820344827586 ], [ 29.812851645569619, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21163, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.454820344827586 ], [ 29.813121139240508, -1.455089827586207 ], [ 29.813390632911393, -1.455089827586207 ], [ 29.813390632911393, -1.454820344827586 ], [ 29.813121139240508, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21164, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.454820344827586 ], [ 29.813390632911393, -1.455359310344828 ], [ 29.813660126582278, -1.455359310344828 ], [ 29.813660126582278, -1.454820344827586 ], [ 29.813390632911393, -1.454820344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21165, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.455089827586207 ], [ 29.750598607594934, -1.455359310344828 ], [ 29.750868101265823, -1.455359310344828 ], [ 29.750868101265823, -1.455089827586207 ], [ 29.750598607594934, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21166, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.455089827586207 ], [ 29.750868101265823, -1.455359310344828 ], [ 29.751137594936708, -1.455359310344828 ], [ 29.751137594936708, -1.455089827586207 ], [ 29.750868101265823, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21167, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.455089827586207 ], [ 29.751137594936708, -1.455359310344828 ], [ 29.751407088607593, -1.455359310344828 ], [ 29.751407088607593, -1.455089827586207 ], [ 29.751137594936708, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21168, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.455089827586207 ], [ 29.751407088607593, -1.455628793103448 ], [ 29.751676582278481, -1.455628793103448 ], [ 29.751676582278481, -1.455089827586207 ], [ 29.751407088607593, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21169, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.455089827586207 ], [ 29.751676582278481, -1.455628793103448 ], [ 29.751946075949366, -1.455628793103448 ], [ 29.751946075949366, -1.455089827586207 ], [ 29.751676582278481, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21170, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.455089827586207 ], [ 29.752215569620251, -1.455359310344828 ], [ 29.75248506329114, -1.455359310344828 ], [ 29.75248506329114, -1.455089827586207 ], [ 29.752215569620251, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21171, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.455089827586207 ], [ 29.75248506329114, -1.455359310344828 ], [ 29.752754556962024, -1.455359310344828 ], [ 29.752754556962024, -1.455089827586207 ], [ 29.75248506329114, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21172, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.455089827586207 ], [ 29.752754556962024, -1.455359310344828 ], [ 29.753024050632909, -1.455359310344828 ], [ 29.753024050632909, -1.455089827586207 ], [ 29.752754556962024, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21173, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.455089827586207 ], [ 29.753024050632909, -1.455359310344828 ], [ 29.753293544303798, -1.455359310344828 ], [ 29.753293544303798, -1.455089827586207 ], [ 29.753024050632909, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21174, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.455089827586207 ], [ 29.753293544303798, -1.455359310344828 ], [ 29.753563037974683, -1.455359310344828 ], [ 29.753563037974683, -1.455089827586207 ], [ 29.753293544303798, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21175, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.455089827586207 ], [ 29.753563037974683, -1.455359310344828 ], [ 29.753832531645568, -1.455359310344828 ], [ 29.753832531645568, -1.455089827586207 ], [ 29.753563037974683, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21176, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.455089827586207 ], [ 29.753832531645568, -1.455359310344828 ], [ 29.754102025316456, -1.455359310344828 ], [ 29.754102025316456, -1.455089827586207 ], [ 29.753832531645568, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21177, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.455089827586207 ], [ 29.754102025316456, -1.455359310344828 ], [ 29.754371518987341, -1.455359310344828 ], [ 29.754371518987341, -1.455089827586207 ], [ 29.754102025316456, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21178, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.455089827586207 ], [ 29.754371518987341, -1.455359310344828 ], [ 29.754641012658226, -1.455359310344828 ], [ 29.754641012658226, -1.455089827586207 ], [ 29.754371518987341, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21179, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.455089827586207 ], [ 29.754641012658226, -1.455359310344828 ], [ 29.754910506329114, -1.455359310344828 ], [ 29.754910506329114, -1.455089827586207 ], [ 29.754641012658226, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21180, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.455089827586207 ], [ 29.754910506329114, -1.455359310344828 ], [ 29.75518, -1.455359310344828 ], [ 29.75518, -1.455089827586207 ], [ 29.754910506329114, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21181, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.455089827586207 ], [ 29.75518, -1.455359310344828 ], [ 29.755449493670884, -1.455359310344828 ], [ 29.755449493670884, -1.455089827586207 ], [ 29.75518, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21182, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.455089827586207 ], [ 29.755449493670884, -1.455359310344828 ], [ 29.755718987341773, -1.455359310344828 ], [ 29.755718987341773, -1.455089827586207 ], [ 29.755449493670884, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21183, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.455089827586207 ], [ 29.755718987341773, -1.455359310344828 ], [ 29.755988481012658, -1.455359310344828 ], [ 29.755988481012658, -1.455089827586207 ], [ 29.755718987341773, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21184, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.455089827586207 ], [ 29.755988481012658, -1.455359310344828 ], [ 29.756257974683542, -1.455359310344828 ], [ 29.756257974683542, -1.455089827586207 ], [ 29.755988481012658, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21185, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.455089827586207 ], [ 29.756257974683542, -1.455359310344828 ], [ 29.756796962025316, -1.455359310344828 ], [ 29.756796962025316, -1.455089827586207 ], [ 29.756257974683542, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21186, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.455089827586207 ], [ 29.757066455696201, -1.455628793103448 ], [ 29.757335949367089, -1.455628793103448 ], [ 29.757335949367089, -1.455089827586207 ], [ 29.757066455696201, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21187, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.455089827586207 ], [ 29.757874936708859, -1.455628793103448 ], [ 29.758144430379748, -1.455628793103448 ], [ 29.758144430379748, -1.455089827586207 ], [ 29.757874936708859, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21188, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.455089827586207 ], [ 29.758413924050632, -1.455359310344828 ], [ 29.758683417721517, -1.455359310344828 ], [ 29.758683417721517, -1.455089827586207 ], [ 29.758413924050632, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21189, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.455089827586207 ], [ 29.758683417721517, -1.455628793103448 ], [ 29.758952911392406, -1.455628793103448 ], [ 29.758952911392406, -1.455089827586207 ], [ 29.758683417721517, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21190, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.455089827586207 ], [ 29.759222405063291, -1.455359310344828 ], [ 29.759491898734176, -1.455359310344828 ], [ 29.759491898734176, -1.455089827586207 ], [ 29.759222405063291, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21191, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.455089827586207 ], [ 29.759491898734176, -1.455359310344828 ], [ 29.759761392405064, -1.455359310344828 ], [ 29.759761392405064, -1.455089827586207 ], [ 29.759491898734176, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21192, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.455089827586207 ], [ 29.760030886075949, -1.455359310344828 ], [ 29.760300379746834, -1.455359310344828 ], [ 29.760300379746834, -1.455089827586207 ], [ 29.760030886075949, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21193, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.455089827586207 ], [ 29.760300379746834, -1.455359310344828 ], [ 29.760569873417722, -1.455359310344828 ], [ 29.760569873417722, -1.455089827586207 ], [ 29.760300379746834, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21194, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.455089827586207 ], [ 29.760569873417722, -1.455359310344828 ], [ 29.760839367088607, -1.455359310344828 ], [ 29.760839367088607, -1.455089827586207 ], [ 29.760569873417722, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21195, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.455089827586207 ], [ 29.760839367088607, -1.455359310344828 ], [ 29.761108860759492, -1.455359310344828 ], [ 29.761108860759492, -1.455089827586207 ], [ 29.760839367088607, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21196, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.455089827586207 ], [ 29.761108860759492, -1.455359310344828 ], [ 29.761378354430381, -1.455359310344828 ], [ 29.761378354430381, -1.455089827586207 ], [ 29.761108860759492, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21197, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.455089827586207 ], [ 29.761378354430381, -1.455628793103448 ], [ 29.761647848101266, -1.455628793103448 ], [ 29.761647848101266, -1.455089827586207 ], [ 29.761378354430381, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21198, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.455089827586207 ], [ 29.761647848101266, -1.455628793103448 ], [ 29.761917341772151, -1.455628793103448 ], [ 29.761917341772151, -1.455089827586207 ], [ 29.761647848101266, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21199, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.455089827586207 ], [ 29.763264810126582, -1.455359310344828 ], [ 29.763534303797467, -1.455359310344828 ], [ 29.763534303797467, -1.455089827586207 ], [ 29.763264810126582, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21200, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.455089827586207 ], [ 29.763534303797467, -1.455359310344828 ], [ 29.763803797468356, -1.455359310344828 ], [ 29.763803797468356, -1.455089827586207 ], [ 29.763534303797467, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21201, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.455089827586207 ], [ 29.763803797468356, -1.455359310344828 ], [ 29.76407329113924, -1.455359310344828 ], [ 29.76407329113924, -1.455089827586207 ], [ 29.763803797468356, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21202, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.455089827586207 ], [ 29.76407329113924, -1.455359310344828 ], [ 29.764342784810125, -1.455359310344828 ], [ 29.764342784810125, -1.455089827586207 ], [ 29.76407329113924, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21203, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.455089827586207 ], [ 29.764342784810125, -1.455359310344828 ], [ 29.764612278481014, -1.455359310344828 ], [ 29.764612278481014, -1.455089827586207 ], [ 29.764342784810125, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21204, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.455089827586207 ], [ 29.764612278481014, -1.455359310344828 ], [ 29.764881772151899, -1.455359310344828 ], [ 29.764881772151899, -1.455089827586207 ], [ 29.764612278481014, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21205, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.455089827586207 ], [ 29.764881772151899, -1.455359310344828 ], [ 29.765151265822784, -1.455359310344828 ], [ 29.765151265822784, -1.455089827586207 ], [ 29.764881772151899, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21206, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.455089827586207 ], [ 29.765151265822784, -1.455359310344828 ], [ 29.765420759493672, -1.455359310344828 ], [ 29.765420759493672, -1.455089827586207 ], [ 29.765151265822784, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21207, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.455089827586207 ], [ 29.765420759493672, -1.455359310344828 ], [ 29.765959746835442, -1.455359310344828 ], [ 29.765959746835442, -1.455089827586207 ], [ 29.765420759493672, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21208, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.455089827586207 ], [ 29.765959746835442, -1.455359310344828 ], [ 29.766229240506327, -1.455359310344828 ], [ 29.766229240506327, -1.455089827586207 ], [ 29.765959746835442, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21209, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.455089827586207 ], [ 29.766229240506327, -1.455359310344828 ], [ 29.766498734177215, -1.455359310344828 ], [ 29.766498734177215, -1.455089827586207 ], [ 29.766229240506327, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21210, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.455089827586207 ], [ 29.766498734177215, -1.455359310344828 ], [ 29.767307215189874, -1.455359310344828 ], [ 29.767307215189874, -1.455089827586207 ], [ 29.766498734177215, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21211, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.455089827586207 ], [ 29.769193670886075, -1.455628793103448 ], [ 29.76946316455696, -1.455628793103448 ], [ 29.76946316455696, -1.455089827586207 ], [ 29.769193670886075, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21212, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.455089827586207 ], [ 29.76946316455696, -1.455628793103448 ], [ 29.769732658227849, -1.455628793103448 ], [ 29.769732658227849, -1.455089827586207 ], [ 29.76946316455696, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21213, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.455089827586207 ], [ 29.769732658227849, -1.455359310344828 ], [ 29.770002151898733, -1.455359310344828 ], [ 29.770002151898733, -1.455089827586207 ], [ 29.769732658227849, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21214, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.455089827586207 ], [ 29.770002151898733, -1.455359310344828 ], [ 29.770271645569618, -1.455359310344828 ], [ 29.770271645569618, -1.455089827586207 ], [ 29.770002151898733, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21215, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.455089827586207 ], [ 29.770271645569618, -1.455359310344828 ], [ 29.770541139240507, -1.455359310344828 ], [ 29.770541139240507, -1.455089827586207 ], [ 29.770271645569618, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21216, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.455089827586207 ], [ 29.770541139240507, -1.455359310344828 ], [ 29.770810632911392, -1.455359310344828 ], [ 29.770810632911392, -1.455089827586207 ], [ 29.770541139240507, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21217, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.455089827586207 ], [ 29.770810632911392, -1.455359310344828 ], [ 29.771080126582277, -1.455359310344828 ], [ 29.771080126582277, -1.455089827586207 ], [ 29.770810632911392, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21218, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.455089827586207 ], [ 29.771080126582277, -1.455359310344828 ], [ 29.771349620253165, -1.455359310344828 ], [ 29.771349620253165, -1.455089827586207 ], [ 29.771080126582277, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21219, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.455089827586207 ], [ 29.771349620253165, -1.455359310344828 ], [ 29.77161911392405, -1.455359310344828 ], [ 29.77161911392405, -1.455089827586207 ], [ 29.771349620253165, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21220, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.455089827586207 ], [ 29.77161911392405, -1.455359310344828 ], [ 29.771888607594935, -1.455359310344828 ], [ 29.771888607594935, -1.455089827586207 ], [ 29.77161911392405, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21221, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.455089827586207 ], [ 29.771888607594935, -1.455359310344828 ], [ 29.772158101265823, -1.455359310344828 ], [ 29.772158101265823, -1.455089827586207 ], [ 29.771888607594935, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21222, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.455089827586207 ], [ 29.772158101265823, -1.455359310344828 ], [ 29.772427594936708, -1.455359310344828 ], [ 29.772427594936708, -1.455089827586207 ], [ 29.772158101265823, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21223, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.455089827586207 ], [ 29.772427594936708, -1.455359310344828 ], [ 29.772697088607593, -1.455359310344828 ], [ 29.772697088607593, -1.455089827586207 ], [ 29.772427594936708, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21224, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.455089827586207 ], [ 29.772697088607593, -1.455359310344828 ], [ 29.772966582278482, -1.455359310344828 ], [ 29.772966582278482, -1.455089827586207 ], [ 29.772697088607593, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21225, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.455089827586207 ], [ 29.772966582278482, -1.455359310344828 ], [ 29.773236075949367, -1.455359310344828 ], [ 29.773236075949367, -1.455089827586207 ], [ 29.772966582278482, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21226, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.455089827586207 ], [ 29.773236075949367, -1.455359310344828 ], [ 29.773505569620252, -1.455359310344828 ], [ 29.773505569620252, -1.455089827586207 ], [ 29.773236075949367, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21227, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.455089827586207 ], [ 29.773505569620252, -1.455359310344828 ], [ 29.77377506329114, -1.455359310344828 ], [ 29.77377506329114, -1.455089827586207 ], [ 29.773505569620252, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21228, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.455089827586207 ], [ 29.77377506329114, -1.455628793103448 ], [ 29.774044556962025, -1.455628793103448 ], [ 29.774044556962025, -1.455089827586207 ], [ 29.77377506329114, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21229, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.455089827586207 ], [ 29.774044556962025, -1.455359310344828 ], [ 29.77431405063291, -1.455359310344828 ], [ 29.77431405063291, -1.455089827586207 ], [ 29.774044556962025, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21230, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.455089827586207 ], [ 29.77431405063291, -1.455359310344828 ], [ 29.774853037974683, -1.455359310344828 ], [ 29.774853037974683, -1.455089827586207 ], [ 29.77431405063291, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21231, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.455089827586207 ], [ 29.775122531645568, -1.455359310344828 ], [ 29.775392025316457, -1.455359310344828 ], [ 29.775392025316457, -1.455089827586207 ], [ 29.775122531645568, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21232, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.455089827586207 ], [ 29.775392025316457, -1.455359310344828 ], [ 29.775661518987341, -1.455359310344828 ], [ 29.775661518987341, -1.455089827586207 ], [ 29.775392025316457, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21233, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.455089827586207 ], [ 29.775931012658226, -1.455628793103448 ], [ 29.776200506329115, -1.455628793103448 ], [ 29.776200506329115, -1.455089827586207 ], [ 29.775931012658226, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21234, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.455089827586207 ], [ 29.776200506329115, -1.455359310344828 ], [ 29.77647, -1.455359310344828 ], [ 29.77647, -1.455089827586207 ], [ 29.776200506329115, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21235, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.455089827586207 ], [ 29.776739493670885, -1.455628793103448 ], [ 29.777008987341773, -1.455628793103448 ], [ 29.777008987341773, -1.455089827586207 ], [ 29.776739493670885, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21236, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.455089827586207 ], [ 29.777547974683543, -1.455628793103448 ], [ 29.777817468354431, -1.455628793103448 ], [ 29.777817468354431, -1.455089827586207 ], [ 29.777547974683543, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21237, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.455089827586207 ], [ 29.778356455696201, -1.455628793103448 ], [ 29.77862594936709, -1.455628793103448 ], [ 29.77862594936709, -1.455089827586207 ], [ 29.778356455696201, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21238, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.455089827586207 ], [ 29.77862594936709, -1.455359310344828 ], [ 29.778895443037975, -1.455359310344828 ], [ 29.778895443037975, -1.455089827586207 ], [ 29.77862594936709, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21239, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.455089827586207 ], [ 29.778895443037975, -1.455359310344828 ], [ 29.77916493670886, -1.455359310344828 ], [ 29.77916493670886, -1.455089827586207 ], [ 29.778895443037975, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21240, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.455089827586207 ], [ 29.77916493670886, -1.455628793103448 ], [ 29.779434430379748, -1.455628793103448 ], [ 29.779434430379748, -1.455089827586207 ], [ 29.77916493670886, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21241, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.455089827586207 ], [ 29.779434430379748, -1.455359310344828 ], [ 29.779703924050633, -1.455359310344828 ], [ 29.779703924050633, -1.455089827586207 ], [ 29.779434430379748, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21242, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.455089827586207 ], [ 29.779703924050633, -1.455359310344828 ], [ 29.779973417721518, -1.455359310344828 ], [ 29.779973417721518, -1.455089827586207 ], [ 29.779703924050633, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21243, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.455089827586207 ], [ 29.779973417721518, -1.455628793103448 ], [ 29.780242911392406, -1.455628793103448 ], [ 29.780242911392406, -1.455089827586207 ], [ 29.779973417721518, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21244, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.455089827586207 ], [ 29.780242911392406, -1.455628793103448 ], [ 29.780512405063291, -1.455628793103448 ], [ 29.780512405063291, -1.455089827586207 ], [ 29.780242911392406, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21245, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.455089827586207 ], [ 29.780781898734176, -1.455628793103448 ], [ 29.781051392405065, -1.455628793103448 ], [ 29.781051392405065, -1.455089827586207 ], [ 29.780781898734176, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21246, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.455089827586207 ], [ 29.781590379746834, -1.455628793103448 ], [ 29.781859873417723, -1.455628793103448 ], [ 29.781859873417723, -1.455089827586207 ], [ 29.781590379746834, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21247, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.455089827586207 ], [ 29.782129367088608, -1.455359310344828 ], [ 29.782398860759493, -1.455359310344828 ], [ 29.782398860759493, -1.455089827586207 ], [ 29.782129367088608, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21248, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.455089827586207 ], [ 29.782398860759493, -1.455628793103448 ], [ 29.782668354430381, -1.455628793103448 ], [ 29.782668354430381, -1.455089827586207 ], [ 29.782398860759493, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21249, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.455089827586207 ], [ 29.782937848101266, -1.455359310344828 ], [ 29.783207341772151, -1.455359310344828 ], [ 29.783207341772151, -1.455089827586207 ], [ 29.782937848101266, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21250, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.455089827586207 ], [ 29.783207341772151, -1.455359310344828 ], [ 29.783476835443039, -1.455359310344828 ], [ 29.783476835443039, -1.455089827586207 ], [ 29.783207341772151, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21251, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.455089827586207 ], [ 29.784015822784809, -1.455359310344828 ], [ 29.784285316455698, -1.455359310344828 ], [ 29.784285316455698, -1.455089827586207 ], [ 29.784015822784809, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21252, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.455089827586207 ], [ 29.784285316455698, -1.455359310344828 ], [ 29.784554810126583, -1.455359310344828 ], [ 29.784554810126583, -1.455089827586207 ], [ 29.784285316455698, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21253, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.455089827586207 ], [ 29.784824303797468, -1.455359310344828 ], [ 29.785093797468356, -1.455359310344828 ], [ 29.785093797468356, -1.455089827586207 ], [ 29.784824303797468, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21254, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.455089827586207 ], [ 29.785093797468356, -1.455359310344828 ], [ 29.785363291139241, -1.455359310344828 ], [ 29.785363291139241, -1.455089827586207 ], [ 29.785093797468356, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21255, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.455089827586207 ], [ 29.785363291139241, -1.455359310344828 ], [ 29.785632784810126, -1.455359310344828 ], [ 29.785632784810126, -1.455089827586207 ], [ 29.785363291139241, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21256, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.455089827586207 ], [ 29.785632784810126, -1.455359310344828 ], [ 29.785902278481014, -1.455359310344828 ], [ 29.785902278481014, -1.455089827586207 ], [ 29.785632784810126, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21257, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.455089827586207 ], [ 29.785902278481014, -1.455359310344828 ], [ 29.786171772151899, -1.455359310344828 ], [ 29.786171772151899, -1.455089827586207 ], [ 29.785902278481014, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21258, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.455089827586207 ], [ 29.786171772151899, -1.455359310344828 ], [ 29.786441265822784, -1.455359310344828 ], [ 29.786441265822784, -1.455089827586207 ], [ 29.786171772151899, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21259, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.455089827586207 ], [ 29.786441265822784, -1.455359310344828 ], [ 29.786710759493673, -1.455359310344828 ], [ 29.786710759493673, -1.455089827586207 ], [ 29.786441265822784, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21260, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.455089827586207 ], [ 29.786710759493673, -1.455359310344828 ], [ 29.786980253164558, -1.455359310344828 ], [ 29.786980253164558, -1.455089827586207 ], [ 29.786710759493673, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21261, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.455089827586207 ], [ 29.786980253164558, -1.455628793103448 ], [ 29.787249746835442, -1.455628793103448 ], [ 29.787249746835442, -1.455089827586207 ], [ 29.786980253164558, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21262, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.455089827586207 ], [ 29.787788734177216, -1.455359310344828 ], [ 29.788058227848101, -1.455359310344828 ], [ 29.788058227848101, -1.455089827586207 ], [ 29.787788734177216, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21263, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.455089827586207 ], [ 29.788058227848101, -1.455359310344828 ], [ 29.788327721518986, -1.455359310344828 ], [ 29.788327721518986, -1.455089827586207 ], [ 29.788058227848101, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21264, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.455089827586207 ], [ 29.788327721518986, -1.455359310344828 ], [ 29.788597215189874, -1.455359310344828 ], [ 29.788597215189874, -1.455089827586207 ], [ 29.788327721518986, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21265, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.455089827586207 ], [ 29.794256582278482, -1.455359310344828 ], [ 29.794526075949367, -1.455359310344828 ], [ 29.794526075949367, -1.455089827586207 ], [ 29.794256582278482, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21266, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.455089827586207 ], [ 29.794526075949367, -1.455359310344828 ], [ 29.794795569620252, -1.455359310344828 ], [ 29.794795569620252, -1.455089827586207 ], [ 29.794526075949367, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21267, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.455089827586207 ], [ 29.794795569620252, -1.455359310344828 ], [ 29.79506506329114, -1.455359310344828 ], [ 29.79506506329114, -1.455089827586207 ], [ 29.794795569620252, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21268, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.455089827586207 ], [ 29.79506506329114, -1.455359310344828 ], [ 29.795334556962025, -1.455359310344828 ], [ 29.795334556962025, -1.455089827586207 ], [ 29.79506506329114, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21269, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.455089827586207 ], [ 29.795334556962025, -1.455359310344828 ], [ 29.79560405063291, -1.455359310344828 ], [ 29.79560405063291, -1.455089827586207 ], [ 29.795334556962025, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21270, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.455089827586207 ], [ 29.795873544303799, -1.455628793103448 ], [ 29.796143037974684, -1.455628793103448 ], [ 29.796143037974684, -1.455089827586207 ], [ 29.795873544303799, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21271, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.455089827586207 ], [ 29.796682025316457, -1.455628793103448 ], [ 29.796951518987342, -1.455628793103448 ], [ 29.796951518987342, -1.455089827586207 ], [ 29.796682025316457, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21272, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.455089827586207 ], [ 29.797221012658227, -1.455359310344828 ], [ 29.797490506329115, -1.455359310344828 ], [ 29.797490506329115, -1.455089827586207 ], [ 29.797221012658227, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21273, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.455089827586207 ], [ 29.797490506329115, -1.455359310344828 ], [ 29.79776, -1.455359310344828 ], [ 29.79776, -1.455089827586207 ], [ 29.797490506329115, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21274, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.455089827586207 ], [ 29.798029493670885, -1.455359310344828 ], [ 29.798298987341774, -1.455359310344828 ], [ 29.798298987341774, -1.455089827586207 ], [ 29.798029493670885, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21275, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.455089827586207 ], [ 29.798298987341774, -1.455359310344828 ], [ 29.798568481012659, -1.455359310344828 ], [ 29.798568481012659, -1.455089827586207 ], [ 29.798298987341774, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21276, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.455089827586207 ], [ 29.798568481012659, -1.455359310344828 ], [ 29.798837974683543, -1.455359310344828 ], [ 29.798837974683543, -1.455089827586207 ], [ 29.798568481012659, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21277, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.455089827586207 ], [ 29.798837974683543, -1.455898275862069 ], [ 29.799107468354432, -1.455898275862069 ], [ 29.799107468354432, -1.455089827586207 ], [ 29.798837974683543, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21278, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.455089827586207 ], [ 29.799107468354432, -1.455898275862069 ], [ 29.799376962025317, -1.455898275862069 ], [ 29.799376962025317, -1.455089827586207 ], [ 29.799107468354432, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21279, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.455089827586207 ], [ 29.807192278481015, -1.455359310344828 ], [ 29.8074617721519, -1.455359310344828 ], [ 29.8074617721519, -1.455089827586207 ], [ 29.807192278481015, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21280, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.455089827586207 ], [ 29.8074617721519, -1.455359310344828 ], [ 29.808539746835443, -1.455359310344828 ], [ 29.808539746835443, -1.455089827586207 ], [ 29.8074617721519, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21281, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.455089827586207 ], [ 29.808539746835443, -1.455359310344828 ], [ 29.808809240506328, -1.455359310344828 ], [ 29.808809240506328, -1.455089827586207 ], [ 29.808539746835443, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21282, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.455089827586207 ], [ 29.808809240506328, -1.455359310344828 ], [ 29.809078734177216, -1.455359310344828 ], [ 29.809078734177216, -1.455089827586207 ], [ 29.808809240506328, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21283, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.455089827586207 ], [ 29.809078734177216, -1.455359310344828 ], [ 29.809348227848101, -1.455359310344828 ], [ 29.809348227848101, -1.455089827586207 ], [ 29.809078734177216, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21284, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.455089827586207 ], [ 29.809348227848101, -1.455359310344828 ], [ 29.809617721518986, -1.455359310344828 ], [ 29.809617721518986, -1.455089827586207 ], [ 29.809348227848101, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21285, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.455089827586207 ], [ 29.809617721518986, -1.455359310344828 ], [ 29.809887215189875, -1.455359310344828 ], [ 29.809887215189875, -1.455089827586207 ], [ 29.809617721518986, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21286, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.455089827586207 ], [ 29.809887215189875, -1.455359310344828 ], [ 29.81015670886076, -1.455359310344828 ], [ 29.81015670886076, -1.455089827586207 ], [ 29.809887215189875, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21287, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.455089827586207 ], [ 29.81015670886076, -1.455359310344828 ], [ 29.810426202531644, -1.455359310344828 ], [ 29.810426202531644, -1.455089827586207 ], [ 29.81015670886076, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21288, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.455089827586207 ], [ 29.810426202531644, -1.455359310344828 ], [ 29.810965189873418, -1.455359310344828 ], [ 29.810965189873418, -1.455089827586207 ], [ 29.810426202531644, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21289, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.455089827586207 ], [ 29.810965189873418, -1.455359310344828 ], [ 29.811234683544303, -1.455359310344828 ], [ 29.811234683544303, -1.455089827586207 ], [ 29.810965189873418, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21290, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.455089827586207 ], [ 29.811234683544303, -1.455359310344828 ], [ 29.811504177215191, -1.455359310344828 ], [ 29.811504177215191, -1.455089827586207 ], [ 29.811234683544303, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21291, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.455089827586207 ], [ 29.811504177215191, -1.455359310344828 ], [ 29.811773670886076, -1.455359310344828 ], [ 29.811773670886076, -1.455089827586207 ], [ 29.811504177215191, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21292, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.455089827586207 ], [ 29.812043164556961, -1.455359310344828 ], [ 29.812312658227849, -1.455359310344828 ], [ 29.812312658227849, -1.455089827586207 ], [ 29.812043164556961, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21293, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.455089827586207 ], [ 29.812312658227849, -1.455359310344828 ], [ 29.812582151898734, -1.455359310344828 ], [ 29.812582151898734, -1.455089827586207 ], [ 29.812312658227849, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21294, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.455089827586207 ], [ 29.812582151898734, -1.455359310344828 ], [ 29.812851645569619, -1.455359310344828 ], [ 29.812851645569619, -1.455089827586207 ], [ 29.812582151898734, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21295, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.455089827586207 ], [ 29.812851645569619, -1.455359310344828 ], [ 29.813121139240508, -1.455359310344828 ], [ 29.813121139240508, -1.455089827586207 ], [ 29.812851645569619, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21296, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.455089827586207 ], [ 29.813121139240508, -1.455359310344828 ], [ 29.813390632911393, -1.455359310344828 ], [ 29.813390632911393, -1.455089827586207 ], [ 29.813121139240508, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21297, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750598607594934, -1.455359310344828 ], [ 29.750598607594934, -1.45616775862069 ], [ 29.750868101265823, -1.45616775862069 ], [ 29.750868101265823, -1.455359310344828 ], [ 29.750598607594934, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21298, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.455359310344828 ], [ 29.750868101265823, -1.455898275862069 ], [ 29.751137594936708, -1.455898275862069 ], [ 29.751137594936708, -1.455359310344828 ], [ 29.750868101265823, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21299, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.455359310344828 ], [ 29.751137594936708, -1.455898275862069 ], [ 29.751407088607593, -1.455898275862069 ], [ 29.751407088607593, -1.455359310344828 ], [ 29.751137594936708, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21300, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.455359310344828 ], [ 29.751946075949366, -1.455628793103448 ], [ 29.752215569620251, -1.455628793103448 ], [ 29.752215569620251, -1.455359310344828 ], [ 29.751946075949366, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21301, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.455359310344828 ], [ 29.752215569620251, -1.455628793103448 ], [ 29.75248506329114, -1.455628793103448 ], [ 29.75248506329114, -1.455359310344828 ], [ 29.752215569620251, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21302, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.455359310344828 ], [ 29.75248506329114, -1.455628793103448 ], [ 29.752754556962024, -1.455628793103448 ], [ 29.752754556962024, -1.455359310344828 ], [ 29.75248506329114, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21303, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.455359310344828 ], [ 29.752754556962024, -1.455628793103448 ], [ 29.753024050632909, -1.455628793103448 ], [ 29.753024050632909, -1.455359310344828 ], [ 29.752754556962024, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21304, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.455359310344828 ], [ 29.753024050632909, -1.455628793103448 ], [ 29.753293544303798, -1.455628793103448 ], [ 29.753293544303798, -1.455359310344828 ], [ 29.753024050632909, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21305, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.455359310344828 ], [ 29.753293544303798, -1.455628793103448 ], [ 29.753563037974683, -1.455628793103448 ], [ 29.753563037974683, -1.455359310344828 ], [ 29.753293544303798, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21306, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.455359310344828 ], [ 29.753563037974683, -1.455628793103448 ], [ 29.753832531645568, -1.455628793103448 ], [ 29.753832531645568, -1.455359310344828 ], [ 29.753563037974683, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21307, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.455359310344828 ], [ 29.753832531645568, -1.455628793103448 ], [ 29.754102025316456, -1.455628793103448 ], [ 29.754102025316456, -1.455359310344828 ], [ 29.753832531645568, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21308, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.455359310344828 ], [ 29.754102025316456, -1.455628793103448 ], [ 29.754371518987341, -1.455628793103448 ], [ 29.754371518987341, -1.455359310344828 ], [ 29.754102025316456, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21309, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.455359310344828 ], [ 29.754371518987341, -1.455628793103448 ], [ 29.754641012658226, -1.455628793103448 ], [ 29.754641012658226, -1.455359310344828 ], [ 29.754371518987341, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21310, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.455359310344828 ], [ 29.754641012658226, -1.455628793103448 ], [ 29.754910506329114, -1.455628793103448 ], [ 29.754910506329114, -1.455359310344828 ], [ 29.754641012658226, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21311, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.455359310344828 ], [ 29.754910506329114, -1.455628793103448 ], [ 29.75518, -1.455628793103448 ], [ 29.75518, -1.455359310344828 ], [ 29.754910506329114, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21312, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.455359310344828 ], [ 29.75518, -1.455628793103448 ], [ 29.755449493670884, -1.455628793103448 ], [ 29.755449493670884, -1.455359310344828 ], [ 29.75518, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21313, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.455359310344828 ], [ 29.755449493670884, -1.455628793103448 ], [ 29.755718987341773, -1.455628793103448 ], [ 29.755718987341773, -1.455359310344828 ], [ 29.755449493670884, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21314, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.455359310344828 ], [ 29.755718987341773, -1.455628793103448 ], [ 29.755988481012658, -1.455628793103448 ], [ 29.755988481012658, -1.455359310344828 ], [ 29.755718987341773, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21315, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.455359310344828 ], [ 29.755988481012658, -1.455628793103448 ], [ 29.756257974683542, -1.455628793103448 ], [ 29.756257974683542, -1.455359310344828 ], [ 29.755988481012658, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21316, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.455359310344828 ], [ 29.756257974683542, -1.455628793103448 ], [ 29.756796962025316, -1.455628793103448 ], [ 29.756796962025316, -1.455359310344828 ], [ 29.756257974683542, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21317, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.455359310344828 ], [ 29.756796962025316, -1.455628793103448 ], [ 29.757066455696201, -1.455628793103448 ], [ 29.757066455696201, -1.455359310344828 ], [ 29.756796962025316, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21318, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.455359310344828 ], [ 29.757335949367089, -1.455628793103448 ], [ 29.757605443037974, -1.455628793103448 ], [ 29.757605443037974, -1.455359310344828 ], [ 29.757335949367089, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21319, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.455359310344828 ], [ 29.757605443037974, -1.455628793103448 ], [ 29.757874936708859, -1.455628793103448 ], [ 29.757874936708859, -1.455359310344828 ], [ 29.757605443037974, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21320, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.455359310344828 ], [ 29.758144430379748, -1.455628793103448 ], [ 29.758413924050632, -1.455628793103448 ], [ 29.758413924050632, -1.455359310344828 ], [ 29.758144430379748, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21321, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.455359310344828 ], [ 29.758413924050632, -1.455628793103448 ], [ 29.758683417721517, -1.455628793103448 ], [ 29.758683417721517, -1.455359310344828 ], [ 29.758413924050632, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21322, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.455359310344828 ], [ 29.758952911392406, -1.455628793103448 ], [ 29.759222405063291, -1.455628793103448 ], [ 29.759222405063291, -1.455359310344828 ], [ 29.758952911392406, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21323, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.455359310344828 ], [ 29.759222405063291, -1.455628793103448 ], [ 29.759491898734176, -1.455628793103448 ], [ 29.759491898734176, -1.455359310344828 ], [ 29.759222405063291, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21324, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.455359310344828 ], [ 29.759491898734176, -1.455628793103448 ], [ 29.759761392405064, -1.455628793103448 ], [ 29.759761392405064, -1.455359310344828 ], [ 29.759491898734176, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21325, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.455359310344828 ], [ 29.759761392405064, -1.455628793103448 ], [ 29.760030886075949, -1.455628793103448 ], [ 29.760030886075949, -1.455359310344828 ], [ 29.759761392405064, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21326, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.455359310344828 ], [ 29.760030886075949, -1.455628793103448 ], [ 29.760300379746834, -1.455628793103448 ], [ 29.760300379746834, -1.455359310344828 ], [ 29.760030886075949, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21327, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.455359310344828 ], [ 29.760300379746834, -1.455628793103448 ], [ 29.760569873417722, -1.455628793103448 ], [ 29.760569873417722, -1.455359310344828 ], [ 29.760300379746834, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21328, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.455359310344828 ], [ 29.760569873417722, -1.455628793103448 ], [ 29.760839367088607, -1.455628793103448 ], [ 29.760839367088607, -1.455359310344828 ], [ 29.760569873417722, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21329, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.455359310344828 ], [ 29.760839367088607, -1.455628793103448 ], [ 29.761108860759492, -1.455628793103448 ], [ 29.761108860759492, -1.455359310344828 ], [ 29.760839367088607, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21330, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.455359310344828 ], [ 29.761108860759492, -1.455898275862069 ], [ 29.761378354430381, -1.455898275862069 ], [ 29.761378354430381, -1.455359310344828 ], [ 29.761108860759492, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21331, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.455359310344828 ], [ 29.761917341772151, -1.455628793103448 ], [ 29.762186835443039, -1.455628793103448 ], [ 29.762186835443039, -1.455359310344828 ], [ 29.761917341772151, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21332, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.455359310344828 ], [ 29.762186835443039, -1.455628793103448 ], [ 29.762456329113924, -1.455628793103448 ], [ 29.762456329113924, -1.455359310344828 ], [ 29.762186835443039, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21333, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.455359310344828 ], [ 29.762456329113924, -1.455628793103448 ], [ 29.762725822784809, -1.455628793103448 ], [ 29.762725822784809, -1.455359310344828 ], [ 29.762456329113924, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21334, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.455359310344828 ], [ 29.762725822784809, -1.455628793103448 ], [ 29.763264810126582, -1.455628793103448 ], [ 29.763264810126582, -1.455359310344828 ], [ 29.762725822784809, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21335, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.455359310344828 ], [ 29.763264810126582, -1.455628793103448 ], [ 29.763534303797467, -1.455628793103448 ], [ 29.763534303797467, -1.455359310344828 ], [ 29.763264810126582, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21336, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.455359310344828 ], [ 29.763534303797467, -1.455628793103448 ], [ 29.763803797468356, -1.455628793103448 ], [ 29.763803797468356, -1.455359310344828 ], [ 29.763534303797467, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21337, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.455359310344828 ], [ 29.763803797468356, -1.455628793103448 ], [ 29.76407329113924, -1.455628793103448 ], [ 29.76407329113924, -1.455359310344828 ], [ 29.763803797468356, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21338, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.455359310344828 ], [ 29.76407329113924, -1.455628793103448 ], [ 29.764342784810125, -1.455628793103448 ], [ 29.764342784810125, -1.455359310344828 ], [ 29.76407329113924, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21339, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.455359310344828 ], [ 29.764342784810125, -1.455628793103448 ], [ 29.764612278481014, -1.455628793103448 ], [ 29.764612278481014, -1.455359310344828 ], [ 29.764342784810125, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21340, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.455359310344828 ], [ 29.764612278481014, -1.455628793103448 ], [ 29.765151265822784, -1.455628793103448 ], [ 29.765151265822784, -1.455359310344828 ], [ 29.764612278481014, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21341, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.455359310344828 ], [ 29.765151265822784, -1.455628793103448 ], [ 29.765420759493672, -1.455628793103448 ], [ 29.765420759493672, -1.455359310344828 ], [ 29.765151265822784, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21342, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.455359310344828 ], [ 29.765420759493672, -1.455628793103448 ], [ 29.765959746835442, -1.455628793103448 ], [ 29.765959746835442, -1.455359310344828 ], [ 29.765420759493672, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21343, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.455359310344828 ], [ 29.765959746835442, -1.455628793103448 ], [ 29.766229240506327, -1.455628793103448 ], [ 29.766229240506327, -1.455359310344828 ], [ 29.765959746835442, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21344, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.455359310344828 ], [ 29.766229240506327, -1.455628793103448 ], [ 29.766498734177215, -1.455628793103448 ], [ 29.766498734177215, -1.455359310344828 ], [ 29.766229240506327, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21345, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.455359310344828 ], [ 29.766498734177215, -1.455628793103448 ], [ 29.767037721518985, -1.455628793103448 ], [ 29.767037721518985, -1.455359310344828 ], [ 29.766498734177215, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21346, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.455359310344828 ], [ 29.767037721518985, -1.455628793103448 ], [ 29.767307215189874, -1.455628793103448 ], [ 29.767307215189874, -1.455359310344828 ], [ 29.767037721518985, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21347, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.455359310344828 ], [ 29.767307215189874, -1.455628793103448 ], [ 29.768385189873417, -1.455628793103448 ], [ 29.768385189873417, -1.455359310344828 ], [ 29.767307215189874, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21348, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.455359310344828 ], [ 29.768385189873417, -1.455628793103448 ], [ 29.768654683544302, -1.455628793103448 ], [ 29.768654683544302, -1.455359310344828 ], [ 29.768385189873417, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21349, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.455359310344828 ], [ 29.768654683544302, -1.455628793103448 ], [ 29.76892417721519, -1.455628793103448 ], [ 29.76892417721519, -1.455359310344828 ], [ 29.768654683544302, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21350, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.455359310344828 ], [ 29.76892417721519, -1.455628793103448 ], [ 29.769193670886075, -1.455628793103448 ], [ 29.769193670886075, -1.455359310344828 ], [ 29.76892417721519, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21351, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.455359310344828 ], [ 29.769732658227849, -1.455898275862069 ], [ 29.770002151898733, -1.455898275862069 ], [ 29.770002151898733, -1.455359310344828 ], [ 29.769732658227849, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21352, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.455359310344828 ], [ 29.770002151898733, -1.455628793103448 ], [ 29.770271645569618, -1.455628793103448 ], [ 29.770271645569618, -1.455359310344828 ], [ 29.770002151898733, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21353, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.455359310344828 ], [ 29.770271645569618, -1.455628793103448 ], [ 29.770541139240507, -1.455628793103448 ], [ 29.770541139240507, -1.455359310344828 ], [ 29.770271645569618, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21354, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.455359310344828 ], [ 29.770541139240507, -1.455628793103448 ], [ 29.770810632911392, -1.455628793103448 ], [ 29.770810632911392, -1.455359310344828 ], [ 29.770541139240507, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21355, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.455359310344828 ], [ 29.770810632911392, -1.455628793103448 ], [ 29.771080126582277, -1.455628793103448 ], [ 29.771080126582277, -1.455359310344828 ], [ 29.770810632911392, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21356, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.455359310344828 ], [ 29.771080126582277, -1.455628793103448 ], [ 29.771349620253165, -1.455628793103448 ], [ 29.771349620253165, -1.455359310344828 ], [ 29.771080126582277, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21357, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.455359310344828 ], [ 29.771349620253165, -1.455628793103448 ], [ 29.77161911392405, -1.455628793103448 ], [ 29.77161911392405, -1.455359310344828 ], [ 29.771349620253165, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21358, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.455359310344828 ], [ 29.77161911392405, -1.455628793103448 ], [ 29.771888607594935, -1.455628793103448 ], [ 29.771888607594935, -1.455359310344828 ], [ 29.77161911392405, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21359, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.455359310344828 ], [ 29.771888607594935, -1.455628793103448 ], [ 29.772158101265823, -1.455628793103448 ], [ 29.772158101265823, -1.455359310344828 ], [ 29.771888607594935, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21360, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.455359310344828 ], [ 29.772158101265823, -1.455628793103448 ], [ 29.772427594936708, -1.455628793103448 ], [ 29.772427594936708, -1.455359310344828 ], [ 29.772158101265823, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21361, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.455359310344828 ], [ 29.772427594936708, -1.455628793103448 ], [ 29.772697088607593, -1.455628793103448 ], [ 29.772697088607593, -1.455359310344828 ], [ 29.772427594936708, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21362, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.455359310344828 ], [ 29.772697088607593, -1.455628793103448 ], [ 29.772966582278482, -1.455628793103448 ], [ 29.772966582278482, -1.455359310344828 ], [ 29.772697088607593, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21363, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.455359310344828 ], [ 29.772966582278482, -1.455628793103448 ], [ 29.773236075949367, -1.455628793103448 ], [ 29.773236075949367, -1.455359310344828 ], [ 29.772966582278482, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21364, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.455359310344828 ], [ 29.773236075949367, -1.455628793103448 ], [ 29.773505569620252, -1.455628793103448 ], [ 29.773505569620252, -1.455359310344828 ], [ 29.773236075949367, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21365, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.455359310344828 ], [ 29.773505569620252, -1.455628793103448 ], [ 29.77377506329114, -1.455628793103448 ], [ 29.77377506329114, -1.455359310344828 ], [ 29.773505569620252, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21366, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.455359310344828 ], [ 29.774044556962025, -1.455628793103448 ], [ 29.77431405063291, -1.455628793103448 ], [ 29.77431405063291, -1.455359310344828 ], [ 29.774044556962025, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21367, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.455359310344828 ], [ 29.77431405063291, -1.455628793103448 ], [ 29.774853037974683, -1.455628793103448 ], [ 29.774853037974683, -1.455359310344828 ], [ 29.77431405063291, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21368, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.455359310344828 ], [ 29.774853037974683, -1.455628793103448 ], [ 29.775122531645568, -1.455628793103448 ], [ 29.775122531645568, -1.455359310344828 ], [ 29.774853037974683, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21369, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.455359310344828 ], [ 29.775122531645568, -1.455898275862069 ], [ 29.775392025316457, -1.455898275862069 ], [ 29.775392025316457, -1.455359310344828 ], [ 29.775122531645568, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21370, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.455359310344828 ], [ 29.775392025316457, -1.455628793103448 ], [ 29.775661518987341, -1.455628793103448 ], [ 29.775661518987341, -1.455359310344828 ], [ 29.775392025316457, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21371, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.455359310344828 ], [ 29.775661518987341, -1.455628793103448 ], [ 29.775931012658226, -1.455628793103448 ], [ 29.775931012658226, -1.455359310344828 ], [ 29.775661518987341, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21372, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.455359310344828 ], [ 29.776200506329115, -1.455628793103448 ], [ 29.77647, -1.455628793103448 ], [ 29.77647, -1.455359310344828 ], [ 29.776200506329115, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21373, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.455359310344828 ], [ 29.77647, -1.455628793103448 ], [ 29.776739493670885, -1.455628793103448 ], [ 29.776739493670885, -1.455359310344828 ], [ 29.77647, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21374, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.455359310344828 ], [ 29.777008987341773, -1.455898275862069 ], [ 29.777278481012658, -1.455898275862069 ], [ 29.777278481012658, -1.455359310344828 ], [ 29.777008987341773, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21375, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.455359310344828 ], [ 29.777278481012658, -1.455628793103448 ], [ 29.777547974683543, -1.455628793103448 ], [ 29.777547974683543, -1.455359310344828 ], [ 29.777278481012658, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21376, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.455359310344828 ], [ 29.777817468354431, -1.455898275862069 ], [ 29.778086962025316, -1.455898275862069 ], [ 29.778086962025316, -1.455359310344828 ], [ 29.777817468354431, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21377, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.455359310344828 ], [ 29.778086962025316, -1.455628793103448 ], [ 29.778356455696201, -1.455628793103448 ], [ 29.778356455696201, -1.455359310344828 ], [ 29.778086962025316, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21378, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.455359310344828 ], [ 29.77862594936709, -1.455628793103448 ], [ 29.778895443037975, -1.455628793103448 ], [ 29.778895443037975, -1.455359310344828 ], [ 29.77862594936709, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21379, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.455359310344828 ], [ 29.778895443037975, -1.455898275862069 ], [ 29.77916493670886, -1.455898275862069 ], [ 29.77916493670886, -1.455359310344828 ], [ 29.778895443037975, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21380, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.455359310344828 ], [ 29.779434430379748, -1.455628793103448 ], [ 29.779703924050633, -1.455628793103448 ], [ 29.779703924050633, -1.455359310344828 ], [ 29.779434430379748, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21381, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.455359310344828 ], [ 29.779703924050633, -1.455898275862069 ], [ 29.779973417721518, -1.455898275862069 ], [ 29.779973417721518, -1.455359310344828 ], [ 29.779703924050633, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21382, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.455359310344828 ], [ 29.780512405063291, -1.455898275862069 ], [ 29.780781898734176, -1.455898275862069 ], [ 29.780781898734176, -1.455359310344828 ], [ 29.780512405063291, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21383, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.455359310344828 ], [ 29.781051392405065, -1.455628793103448 ], [ 29.78132088607595, -1.455628793103448 ], [ 29.78132088607595, -1.455359310344828 ], [ 29.781051392405065, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21384, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.455359310344828 ], [ 29.78132088607595, -1.455898275862069 ], [ 29.781590379746834, -1.455898275862069 ], [ 29.781590379746834, -1.455359310344828 ], [ 29.78132088607595, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21385, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.455359310344828 ], [ 29.781859873417723, -1.455628793103448 ], [ 29.782129367088608, -1.455628793103448 ], [ 29.782129367088608, -1.455359310344828 ], [ 29.781859873417723, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21386, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.455359310344828 ], [ 29.782129367088608, -1.455628793103448 ], [ 29.782398860759493, -1.455628793103448 ], [ 29.782398860759493, -1.455359310344828 ], [ 29.782129367088608, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21387, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.455359310344828 ], [ 29.782668354430381, -1.455628793103448 ], [ 29.782937848101266, -1.455628793103448 ], [ 29.782937848101266, -1.455359310344828 ], [ 29.782668354430381, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21388, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.455359310344828 ], [ 29.782937848101266, -1.455628793103448 ], [ 29.783207341772151, -1.455628793103448 ], [ 29.783207341772151, -1.455359310344828 ], [ 29.782937848101266, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21389, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.455359310344828 ], [ 29.783207341772151, -1.455898275862069 ], [ 29.783476835443039, -1.455898275862069 ], [ 29.783476835443039, -1.455359310344828 ], [ 29.783207341772151, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21390, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.455359310344828 ], [ 29.783476835443039, -1.455628793103448 ], [ 29.784015822784809, -1.455628793103448 ], [ 29.784015822784809, -1.455359310344828 ], [ 29.783476835443039, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21391, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.455359310344828 ], [ 29.784015822784809, -1.455628793103448 ], [ 29.784285316455698, -1.455628793103448 ], [ 29.784285316455698, -1.455359310344828 ], [ 29.784015822784809, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21392, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.455359310344828 ], [ 29.784285316455698, -1.455628793103448 ], [ 29.784554810126583, -1.455628793103448 ], [ 29.784554810126583, -1.455359310344828 ], [ 29.784285316455698, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21393, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.455359310344828 ], [ 29.784554810126583, -1.455628793103448 ], [ 29.784824303797468, -1.455628793103448 ], [ 29.784824303797468, -1.455359310344828 ], [ 29.784554810126583, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21394, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.455359310344828 ], [ 29.784824303797468, -1.455628793103448 ], [ 29.785093797468356, -1.455628793103448 ], [ 29.785093797468356, -1.455359310344828 ], [ 29.784824303797468, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21395, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.455359310344828 ], [ 29.785093797468356, -1.455628793103448 ], [ 29.785363291139241, -1.455628793103448 ], [ 29.785363291139241, -1.455359310344828 ], [ 29.785093797468356, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21396, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.455359310344828 ], [ 29.785363291139241, -1.455628793103448 ], [ 29.785632784810126, -1.455628793103448 ], [ 29.785632784810126, -1.455359310344828 ], [ 29.785363291139241, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21397, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.455359310344828 ], [ 29.785632784810126, -1.455628793103448 ], [ 29.785902278481014, -1.455628793103448 ], [ 29.785902278481014, -1.455359310344828 ], [ 29.785632784810126, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21398, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.455359310344828 ], [ 29.785902278481014, -1.455898275862069 ], [ 29.786171772151899, -1.455898275862069 ], [ 29.786171772151899, -1.455359310344828 ], [ 29.785902278481014, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21399, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.455359310344828 ], [ 29.786171772151899, -1.455628793103448 ], [ 29.786441265822784, -1.455628793103448 ], [ 29.786441265822784, -1.455359310344828 ], [ 29.786171772151899, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21400, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.455359310344828 ], [ 29.786441265822784, -1.455628793103448 ], [ 29.786710759493673, -1.455628793103448 ], [ 29.786710759493673, -1.455359310344828 ], [ 29.786441265822784, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21401, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.455359310344828 ], [ 29.786710759493673, -1.455628793103448 ], [ 29.786980253164558, -1.455628793103448 ], [ 29.786980253164558, -1.455359310344828 ], [ 29.786710759493673, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21402, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.455359310344828 ], [ 29.787249746835442, -1.455628793103448 ], [ 29.787519240506327, -1.455628793103448 ], [ 29.787519240506327, -1.455359310344828 ], [ 29.787249746835442, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21403, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.455359310344828 ], [ 29.787519240506327, -1.455628793103448 ], [ 29.787788734177216, -1.455628793103448 ], [ 29.787788734177216, -1.455359310344828 ], [ 29.787519240506327, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21404, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.455359310344828 ], [ 29.787788734177216, -1.455628793103448 ], [ 29.788058227848101, -1.455628793103448 ], [ 29.788058227848101, -1.455359310344828 ], [ 29.787788734177216, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21405, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.455359310344828 ], [ 29.791022658227849, -1.45616775862069 ], [ 29.791292151898734, -1.45616775862069 ], [ 29.791292151898734, -1.455359310344828 ], [ 29.791022658227849, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21406, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.455359310344828 ], [ 29.791292151898734, -1.455628793103448 ], [ 29.791561645569619, -1.455628793103448 ], [ 29.791561645569619, -1.455359310344828 ], [ 29.791292151898734, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21407, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.455359310344828 ], [ 29.791561645569619, -1.455628793103448 ], [ 29.791831139240507, -1.455628793103448 ], [ 29.791831139240507, -1.455359310344828 ], [ 29.791561645569619, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21408, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.455359310344828 ], [ 29.791831139240507, -1.455628793103448 ], [ 29.792100632911392, -1.455628793103448 ], [ 29.792100632911392, -1.455359310344828 ], [ 29.791831139240507, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21409, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.455359310344828 ], [ 29.792909113924051, -1.455628793103448 ], [ 29.793178607594935, -1.455628793103448 ], [ 29.793178607594935, -1.455359310344828 ], [ 29.792909113924051, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21410, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.455359310344828 ], [ 29.793717594936709, -1.455628793103448 ], [ 29.793987088607594, -1.455628793103448 ], [ 29.793987088607594, -1.455359310344828 ], [ 29.793717594936709, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21411, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.455359310344828 ], [ 29.793987088607594, -1.455628793103448 ], [ 29.794256582278482, -1.455628793103448 ], [ 29.794256582278482, -1.455359310344828 ], [ 29.793987088607594, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21412, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.455359310344828 ], [ 29.794256582278482, -1.455628793103448 ], [ 29.794526075949367, -1.455628793103448 ], [ 29.794526075949367, -1.455359310344828 ], [ 29.794256582278482, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21413, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.455359310344828 ], [ 29.794526075949367, -1.455628793103448 ], [ 29.794795569620252, -1.455628793103448 ], [ 29.794795569620252, -1.455359310344828 ], [ 29.794526075949367, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21414, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.455359310344828 ], [ 29.794795569620252, -1.455628793103448 ], [ 29.79506506329114, -1.455628793103448 ], [ 29.79506506329114, -1.455359310344828 ], [ 29.794795569620252, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21415, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.455359310344828 ], [ 29.79506506329114, -1.455628793103448 ], [ 29.795334556962025, -1.455628793103448 ], [ 29.795334556962025, -1.455359310344828 ], [ 29.79506506329114, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21416, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.455359310344828 ], [ 29.795334556962025, -1.455628793103448 ], [ 29.79560405063291, -1.455628793103448 ], [ 29.79560405063291, -1.455359310344828 ], [ 29.795334556962025, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21417, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.455359310344828 ], [ 29.79560405063291, -1.455628793103448 ], [ 29.795873544303799, -1.455628793103448 ], [ 29.795873544303799, -1.455359310344828 ], [ 29.79560405063291, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21418, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.455359310344828 ], [ 29.796143037974684, -1.455628793103448 ], [ 29.796412531645569, -1.455628793103448 ], [ 29.796412531645569, -1.455359310344828 ], [ 29.796143037974684, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21419, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.455359310344828 ], [ 29.796412531645569, -1.455628793103448 ], [ 29.796682025316457, -1.455628793103448 ], [ 29.796682025316457, -1.455359310344828 ], [ 29.796412531645569, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21420, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.455359310344828 ], [ 29.796951518987342, -1.455628793103448 ], [ 29.797221012658227, -1.455628793103448 ], [ 29.797221012658227, -1.455359310344828 ], [ 29.796951518987342, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21421, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.455359310344828 ], [ 29.797221012658227, -1.455628793103448 ], [ 29.797490506329115, -1.455628793103448 ], [ 29.797490506329115, -1.455359310344828 ], [ 29.797221012658227, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21422, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.455359310344828 ], [ 29.797490506329115, -1.455628793103448 ], [ 29.79776, -1.455628793103448 ], [ 29.79776, -1.455359310344828 ], [ 29.797490506329115, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21423, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.455359310344828 ], [ 29.79776, -1.455628793103448 ], [ 29.798029493670885, -1.455628793103448 ], [ 29.798029493670885, -1.455359310344828 ], [ 29.79776, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21424, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.455359310344828 ], [ 29.798029493670885, -1.455898275862069 ], [ 29.798298987341774, -1.455898275862069 ], [ 29.798298987341774, -1.455359310344828 ], [ 29.798029493670885, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21425, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.455359310344828 ], [ 29.798298987341774, -1.45616775862069 ], [ 29.798568481012659, -1.45616775862069 ], [ 29.798568481012659, -1.455359310344828 ], [ 29.798298987341774, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21426, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.455359310344828 ], [ 29.798568481012659, -1.45616775862069 ], [ 29.798837974683543, -1.45616775862069 ], [ 29.798837974683543, -1.455359310344828 ], [ 29.798568481012659, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21427, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.455359310344828 ], [ 29.807192278481015, -1.455628793103448 ], [ 29.808539746835443, -1.455628793103448 ], [ 29.808539746835443, -1.455359310344828 ], [ 29.807192278481015, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21428, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.455359310344828 ], [ 29.808539746835443, -1.455628793103448 ], [ 29.808809240506328, -1.455628793103448 ], [ 29.808809240506328, -1.455359310344828 ], [ 29.808539746835443, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21429, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.455359310344828 ], [ 29.808809240506328, -1.455628793103448 ], [ 29.809078734177216, -1.455628793103448 ], [ 29.809078734177216, -1.455359310344828 ], [ 29.808809240506328, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21430, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.455359310344828 ], [ 29.809078734177216, -1.455628793103448 ], [ 29.809348227848101, -1.455628793103448 ], [ 29.809348227848101, -1.455359310344828 ], [ 29.809078734177216, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21431, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.455359310344828 ], [ 29.809348227848101, -1.455628793103448 ], [ 29.809617721518986, -1.455628793103448 ], [ 29.809617721518986, -1.455359310344828 ], [ 29.809348227848101, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21432, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.455359310344828 ], [ 29.809617721518986, -1.455628793103448 ], [ 29.809887215189875, -1.455628793103448 ], [ 29.809887215189875, -1.455359310344828 ], [ 29.809617721518986, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21433, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.455359310344828 ], [ 29.809887215189875, -1.455628793103448 ], [ 29.81015670886076, -1.455628793103448 ], [ 29.81015670886076, -1.455359310344828 ], [ 29.809887215189875, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21434, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.455359310344828 ], [ 29.81015670886076, -1.455628793103448 ], [ 29.810426202531644, -1.455628793103448 ], [ 29.810426202531644, -1.455359310344828 ], [ 29.81015670886076, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21435, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.455359310344828 ], [ 29.810426202531644, -1.455628793103448 ], [ 29.810965189873418, -1.455628793103448 ], [ 29.810965189873418, -1.455359310344828 ], [ 29.810426202531644, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21436, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.455359310344828 ], [ 29.810965189873418, -1.455628793103448 ], [ 29.811234683544303, -1.455628793103448 ], [ 29.811234683544303, -1.455359310344828 ], [ 29.810965189873418, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21437, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.455359310344828 ], [ 29.811234683544303, -1.455628793103448 ], [ 29.811504177215191, -1.455628793103448 ], [ 29.811504177215191, -1.455359310344828 ], [ 29.811234683544303, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21438, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.455359310344828 ], [ 29.811504177215191, -1.455628793103448 ], [ 29.811773670886076, -1.455628793103448 ], [ 29.811773670886076, -1.455359310344828 ], [ 29.811504177215191, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21439, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.455359310344828 ], [ 29.811773670886076, -1.455628793103448 ], [ 29.812043164556961, -1.455628793103448 ], [ 29.812043164556961, -1.455359310344828 ], [ 29.811773670886076, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21440, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.455359310344828 ], [ 29.812043164556961, -1.455628793103448 ], [ 29.812312658227849, -1.455628793103448 ], [ 29.812312658227849, -1.455359310344828 ], [ 29.812043164556961, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21441, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.455359310344828 ], [ 29.812312658227849, -1.455628793103448 ], [ 29.812582151898734, -1.455628793103448 ], [ 29.812582151898734, -1.455359310344828 ], [ 29.812312658227849, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21442, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.455359310344828 ], [ 29.812582151898734, -1.455628793103448 ], [ 29.812851645569619, -1.455628793103448 ], [ 29.812851645569619, -1.455359310344828 ], [ 29.812582151898734, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21443, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.455359310344828 ], [ 29.812851645569619, -1.455628793103448 ], [ 29.813121139240508, -1.455628793103448 ], [ 29.813121139240508, -1.455359310344828 ], [ 29.812851645569619, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21444, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.455359310344828 ], [ 29.813121139240508, -1.455628793103448 ], [ 29.813390632911393, -1.455628793103448 ], [ 29.813390632911393, -1.455359310344828 ], [ 29.813121139240508, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21445, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.455359310344828 ], [ 29.813390632911393, -1.455628793103448 ], [ 29.813660126582278, -1.455628793103448 ], [ 29.813660126582278, -1.455359310344828 ], [ 29.813390632911393, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21446, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.455359310344828 ], [ 29.813660126582278, -1.455628793103448 ], [ 29.813929620253166, -1.455628793103448 ], [ 29.813929620253166, -1.455359310344828 ], [ 29.813660126582278, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21447, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.455628793103448 ], [ 29.751407088607593, -1.455898275862069 ], [ 29.751676582278481, -1.455898275862069 ], [ 29.751676582278481, -1.455628793103448 ], [ 29.751407088607593, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21448, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.455628793103448 ], [ 29.751676582278481, -1.455898275862069 ], [ 29.751946075949366, -1.455898275862069 ], [ 29.751946075949366, -1.455628793103448 ], [ 29.751676582278481, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21449, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.455628793103448 ], [ 29.751946075949366, -1.455898275862069 ], [ 29.752215569620251, -1.455898275862069 ], [ 29.752215569620251, -1.455628793103448 ], [ 29.751946075949366, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21450, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.455628793103448 ], [ 29.752215569620251, -1.455898275862069 ], [ 29.75248506329114, -1.455898275862069 ], [ 29.75248506329114, -1.455628793103448 ], [ 29.752215569620251, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21451, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.455628793103448 ], [ 29.75248506329114, -1.455898275862069 ], [ 29.752754556962024, -1.455898275862069 ], [ 29.752754556962024, -1.455628793103448 ], [ 29.75248506329114, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21452, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.455628793103448 ], [ 29.752754556962024, -1.455898275862069 ], [ 29.753024050632909, -1.455898275862069 ], [ 29.753024050632909, -1.455628793103448 ], [ 29.752754556962024, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21453, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.455628793103448 ], [ 29.753024050632909, -1.455898275862069 ], [ 29.753293544303798, -1.455898275862069 ], [ 29.753293544303798, -1.455628793103448 ], [ 29.753024050632909, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21454, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.455628793103448 ], [ 29.753293544303798, -1.455898275862069 ], [ 29.753563037974683, -1.455898275862069 ], [ 29.753563037974683, -1.455628793103448 ], [ 29.753293544303798, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21455, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.455628793103448 ], [ 29.753563037974683, -1.455898275862069 ], [ 29.753832531645568, -1.455898275862069 ], [ 29.753832531645568, -1.455628793103448 ], [ 29.753563037974683, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21456, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.455628793103448 ], [ 29.753832531645568, -1.455898275862069 ], [ 29.754102025316456, -1.455898275862069 ], [ 29.754102025316456, -1.455628793103448 ], [ 29.753832531645568, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21457, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.455628793103448 ], [ 29.754102025316456, -1.455898275862069 ], [ 29.754371518987341, -1.455898275862069 ], [ 29.754371518987341, -1.455628793103448 ], [ 29.754102025316456, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21458, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.455628793103448 ], [ 29.754371518987341, -1.455898275862069 ], [ 29.754641012658226, -1.455898275862069 ], [ 29.754641012658226, -1.455628793103448 ], [ 29.754371518987341, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21459, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.455628793103448 ], [ 29.754641012658226, -1.455898275862069 ], [ 29.754910506329114, -1.455898275862069 ], [ 29.754910506329114, -1.455628793103448 ], [ 29.754641012658226, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21460, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.455628793103448 ], [ 29.754910506329114, -1.455898275862069 ], [ 29.75518, -1.455898275862069 ], [ 29.75518, -1.455628793103448 ], [ 29.754910506329114, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21461, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.455628793103448 ], [ 29.75518, -1.455898275862069 ], [ 29.755449493670884, -1.455898275862069 ], [ 29.755449493670884, -1.455628793103448 ], [ 29.75518, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21462, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.455628793103448 ], [ 29.755449493670884, -1.455898275862069 ], [ 29.755718987341773, -1.455898275862069 ], [ 29.755718987341773, -1.455628793103448 ], [ 29.755449493670884, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21463, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.455628793103448 ], [ 29.755718987341773, -1.455898275862069 ], [ 29.755988481012658, -1.455898275862069 ], [ 29.755988481012658, -1.455628793103448 ], [ 29.755718987341773, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21464, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.455628793103448 ], [ 29.755988481012658, -1.455898275862069 ], [ 29.756257974683542, -1.455898275862069 ], [ 29.756257974683542, -1.455628793103448 ], [ 29.755988481012658, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21465, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.455628793103448 ], [ 29.756257974683542, -1.455898275862069 ], [ 29.756796962025316, -1.455898275862069 ], [ 29.756796962025316, -1.455628793103448 ], [ 29.756257974683542, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21466, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.455628793103448 ], [ 29.756796962025316, -1.455898275862069 ], [ 29.757335949367089, -1.455898275862069 ], [ 29.757335949367089, -1.455628793103448 ], [ 29.756796962025316, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21467, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.455628793103448 ], [ 29.757335949367089, -1.455898275862069 ], [ 29.757605443037974, -1.455898275862069 ], [ 29.757605443037974, -1.455628793103448 ], [ 29.757335949367089, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21468, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.455628793103448 ], [ 29.757605443037974, -1.45616775862069 ], [ 29.757874936708859, -1.45616775862069 ], [ 29.757874936708859, -1.455628793103448 ], [ 29.757605443037974, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21469, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.455628793103448 ], [ 29.757874936708859, -1.455898275862069 ], [ 29.758144430379748, -1.455898275862069 ], [ 29.758144430379748, -1.455628793103448 ], [ 29.757874936708859, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21470, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.455628793103448 ], [ 29.758144430379748, -1.455898275862069 ], [ 29.758413924050632, -1.455898275862069 ], [ 29.758413924050632, -1.455628793103448 ], [ 29.758144430379748, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21471, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.455628793103448 ], [ 29.758413924050632, -1.455898275862069 ], [ 29.758683417721517, -1.455898275862069 ], [ 29.758683417721517, -1.455628793103448 ], [ 29.758413924050632, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21472, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.455628793103448 ], [ 29.758683417721517, -1.455898275862069 ], [ 29.758952911392406, -1.455898275862069 ], [ 29.758952911392406, -1.455628793103448 ], [ 29.758683417721517, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21473, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.455628793103448 ], [ 29.758952911392406, -1.455898275862069 ], [ 29.759222405063291, -1.455898275862069 ], [ 29.759222405063291, -1.455628793103448 ], [ 29.758952911392406, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21474, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.455628793103448 ], [ 29.759222405063291, -1.455898275862069 ], [ 29.759491898734176, -1.455898275862069 ], [ 29.759491898734176, -1.455628793103448 ], [ 29.759222405063291, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21475, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.455628793103448 ], [ 29.759491898734176, -1.455898275862069 ], [ 29.759761392405064, -1.455898275862069 ], [ 29.759761392405064, -1.455628793103448 ], [ 29.759491898734176, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21476, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.455628793103448 ], [ 29.759761392405064, -1.455898275862069 ], [ 29.760030886075949, -1.455898275862069 ], [ 29.760030886075949, -1.455628793103448 ], [ 29.759761392405064, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21477, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.455628793103448 ], [ 29.760030886075949, -1.45616775862069 ], [ 29.760300379746834, -1.45616775862069 ], [ 29.760300379746834, -1.455628793103448 ], [ 29.760030886075949, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21478, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.455628793103448 ], [ 29.760300379746834, -1.455898275862069 ], [ 29.760569873417722, -1.455898275862069 ], [ 29.760569873417722, -1.455628793103448 ], [ 29.760300379746834, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21479, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.455628793103448 ], [ 29.760569873417722, -1.455898275862069 ], [ 29.760839367088607, -1.455898275862069 ], [ 29.760839367088607, -1.455628793103448 ], [ 29.760569873417722, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21480, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.455628793103448 ], [ 29.760839367088607, -1.455898275862069 ], [ 29.761108860759492, -1.455898275862069 ], [ 29.761108860759492, -1.455628793103448 ], [ 29.760839367088607, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21481, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.455628793103448 ], [ 29.761378354430381, -1.455898275862069 ], [ 29.761647848101266, -1.455898275862069 ], [ 29.761647848101266, -1.455628793103448 ], [ 29.761378354430381, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21482, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.455628793103448 ], [ 29.761647848101266, -1.455898275862069 ], [ 29.761917341772151, -1.455898275862069 ], [ 29.761917341772151, -1.455628793103448 ], [ 29.761647848101266, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21483, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.455628793103448 ], [ 29.761917341772151, -1.455898275862069 ], [ 29.762186835443039, -1.455898275862069 ], [ 29.762186835443039, -1.455628793103448 ], [ 29.761917341772151, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21484, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.455628793103448 ], [ 29.762186835443039, -1.455898275862069 ], [ 29.762456329113924, -1.455898275862069 ], [ 29.762456329113924, -1.455628793103448 ], [ 29.762186835443039, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21485, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.455628793103448 ], [ 29.762456329113924, -1.455898275862069 ], [ 29.762725822784809, -1.455898275862069 ], [ 29.762725822784809, -1.455628793103448 ], [ 29.762456329113924, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21486, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.455628793103448 ], [ 29.762725822784809, -1.455898275862069 ], [ 29.763264810126582, -1.455898275862069 ], [ 29.763264810126582, -1.455628793103448 ], [ 29.762725822784809, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21487, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.455628793103448 ], [ 29.763264810126582, -1.455898275862069 ], [ 29.763534303797467, -1.455898275862069 ], [ 29.763534303797467, -1.455628793103448 ], [ 29.763264810126582, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21488, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.455628793103448 ], [ 29.763534303797467, -1.455898275862069 ], [ 29.763803797468356, -1.455898275862069 ], [ 29.763803797468356, -1.455628793103448 ], [ 29.763534303797467, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21489, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.455628793103448 ], [ 29.763803797468356, -1.455898275862069 ], [ 29.76407329113924, -1.455898275862069 ], [ 29.76407329113924, -1.455628793103448 ], [ 29.763803797468356, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21490, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.455628793103448 ], [ 29.76407329113924, -1.455898275862069 ], [ 29.764342784810125, -1.455898275862069 ], [ 29.764342784810125, -1.455628793103448 ], [ 29.76407329113924, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21491, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.455628793103448 ], [ 29.764342784810125, -1.455898275862069 ], [ 29.764612278481014, -1.455898275862069 ], [ 29.764612278481014, -1.455628793103448 ], [ 29.764342784810125, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21492, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.455628793103448 ], [ 29.764612278481014, -1.455898275862069 ], [ 29.765151265822784, -1.455898275862069 ], [ 29.765151265822784, -1.455628793103448 ], [ 29.764612278481014, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21493, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.455628793103448 ], [ 29.765151265822784, -1.455898275862069 ], [ 29.765420759493672, -1.455898275862069 ], [ 29.765420759493672, -1.455628793103448 ], [ 29.765151265822784, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21494, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.455628793103448 ], [ 29.765420759493672, -1.455898275862069 ], [ 29.765959746835442, -1.455898275862069 ], [ 29.765959746835442, -1.455628793103448 ], [ 29.765420759493672, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21495, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.455628793103448 ], [ 29.765959746835442, -1.455898275862069 ], [ 29.766229240506327, -1.455898275862069 ], [ 29.766229240506327, -1.455628793103448 ], [ 29.765959746835442, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21496, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.455628793103448 ], [ 29.766229240506327, -1.455898275862069 ], [ 29.766498734177215, -1.455898275862069 ], [ 29.766498734177215, -1.455628793103448 ], [ 29.766229240506327, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21497, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.455628793103448 ], [ 29.766498734177215, -1.455898275862069 ], [ 29.767037721518985, -1.455898275862069 ], [ 29.767037721518985, -1.455628793103448 ], [ 29.766498734177215, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21498, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.455628793103448 ], [ 29.767037721518985, -1.455898275862069 ], [ 29.767307215189874, -1.455898275862069 ], [ 29.767307215189874, -1.455628793103448 ], [ 29.767037721518985, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21499, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.455628793103448 ], [ 29.767307215189874, -1.455898275862069 ], [ 29.768385189873417, -1.455898275862069 ], [ 29.768385189873417, -1.455628793103448 ], [ 29.767307215189874, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21500, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.455628793103448 ], [ 29.768385189873417, -1.455898275862069 ], [ 29.768654683544302, -1.455898275862069 ], [ 29.768654683544302, -1.455628793103448 ], [ 29.768385189873417, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21501, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.455628793103448 ], [ 29.768654683544302, -1.455898275862069 ], [ 29.76892417721519, -1.455898275862069 ], [ 29.76892417721519, -1.455628793103448 ], [ 29.768654683544302, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21502, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.455628793103448 ], [ 29.76892417721519, -1.455898275862069 ], [ 29.769193670886075, -1.455898275862069 ], [ 29.769193670886075, -1.455628793103448 ], [ 29.76892417721519, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21503, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.455628793103448 ], [ 29.769193670886075, -1.455898275862069 ], [ 29.76946316455696, -1.455898275862069 ], [ 29.76946316455696, -1.455628793103448 ], [ 29.769193670886075, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21504, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.455628793103448 ], [ 29.76946316455696, -1.455898275862069 ], [ 29.769732658227849, -1.455898275862069 ], [ 29.769732658227849, -1.455628793103448 ], [ 29.76946316455696, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21505, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.455628793103448 ], [ 29.770002151898733, -1.455898275862069 ], [ 29.770271645569618, -1.455898275862069 ], [ 29.770271645569618, -1.455628793103448 ], [ 29.770002151898733, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21506, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.455628793103448 ], [ 29.770271645569618, -1.455898275862069 ], [ 29.770541139240507, -1.455898275862069 ], [ 29.770541139240507, -1.455628793103448 ], [ 29.770271645569618, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21507, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.455628793103448 ], [ 29.770541139240507, -1.455898275862069 ], [ 29.770810632911392, -1.455898275862069 ], [ 29.770810632911392, -1.455628793103448 ], [ 29.770541139240507, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21508, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.455628793103448 ], [ 29.770810632911392, -1.455898275862069 ], [ 29.771080126582277, -1.455898275862069 ], [ 29.771080126582277, -1.455628793103448 ], [ 29.770810632911392, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21509, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.455628793103448 ], [ 29.771080126582277, -1.455898275862069 ], [ 29.771349620253165, -1.455898275862069 ], [ 29.771349620253165, -1.455628793103448 ], [ 29.771080126582277, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21510, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.455628793103448 ], [ 29.771349620253165, -1.455898275862069 ], [ 29.77161911392405, -1.455898275862069 ], [ 29.77161911392405, -1.455628793103448 ], [ 29.771349620253165, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21511, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.455628793103448 ], [ 29.77161911392405, -1.455898275862069 ], [ 29.771888607594935, -1.455898275862069 ], [ 29.771888607594935, -1.455628793103448 ], [ 29.77161911392405, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21512, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.455628793103448 ], [ 29.771888607594935, -1.455898275862069 ], [ 29.772158101265823, -1.455898275862069 ], [ 29.772158101265823, -1.455628793103448 ], [ 29.771888607594935, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21513, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.455628793103448 ], [ 29.772158101265823, -1.455898275862069 ], [ 29.772427594936708, -1.455898275862069 ], [ 29.772427594936708, -1.455628793103448 ], [ 29.772158101265823, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21514, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.455628793103448 ], [ 29.772427594936708, -1.455898275862069 ], [ 29.772697088607593, -1.455898275862069 ], [ 29.772697088607593, -1.455628793103448 ], [ 29.772427594936708, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21515, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.455628793103448 ], [ 29.772697088607593, -1.455898275862069 ], [ 29.772966582278482, -1.455898275862069 ], [ 29.772966582278482, -1.455628793103448 ], [ 29.772697088607593, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21516, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.455628793103448 ], [ 29.772966582278482, -1.455898275862069 ], [ 29.773236075949367, -1.455898275862069 ], [ 29.773236075949367, -1.455628793103448 ], [ 29.772966582278482, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21517, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.455628793103448 ], [ 29.773236075949367, -1.455898275862069 ], [ 29.773505569620252, -1.455898275862069 ], [ 29.773505569620252, -1.455628793103448 ], [ 29.773236075949367, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21518, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.455628793103448 ], [ 29.773505569620252, -1.455898275862069 ], [ 29.77377506329114, -1.455898275862069 ], [ 29.77377506329114, -1.455628793103448 ], [ 29.773505569620252, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21519, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.455628793103448 ], [ 29.77377506329114, -1.455898275862069 ], [ 29.774044556962025, -1.455898275862069 ], [ 29.774044556962025, -1.455628793103448 ], [ 29.77377506329114, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21520, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.455628793103448 ], [ 29.774044556962025, -1.455898275862069 ], [ 29.77431405063291, -1.455898275862069 ], [ 29.77431405063291, -1.455628793103448 ], [ 29.774044556962025, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21521, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.455628793103448 ], [ 29.77431405063291, -1.455898275862069 ], [ 29.774853037974683, -1.455898275862069 ], [ 29.774853037974683, -1.455628793103448 ], [ 29.77431405063291, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21522, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.455628793103448 ], [ 29.774853037974683, -1.455898275862069 ], [ 29.775122531645568, -1.455898275862069 ], [ 29.775122531645568, -1.455628793103448 ], [ 29.774853037974683, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21523, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.455628793103448 ], [ 29.775392025316457, -1.455898275862069 ], [ 29.775661518987341, -1.455898275862069 ], [ 29.775661518987341, -1.455628793103448 ], [ 29.775392025316457, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21524, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.455628793103448 ], [ 29.775661518987341, -1.455898275862069 ], [ 29.775931012658226, -1.455898275862069 ], [ 29.775931012658226, -1.455628793103448 ], [ 29.775661518987341, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21525, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.455628793103448 ], [ 29.775931012658226, -1.455898275862069 ], [ 29.776200506329115, -1.455898275862069 ], [ 29.776200506329115, -1.455628793103448 ], [ 29.775931012658226, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21526, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.455628793103448 ], [ 29.776200506329115, -1.45616775862069 ], [ 29.77647, -1.45616775862069 ], [ 29.77647, -1.455628793103448 ], [ 29.776200506329115, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21527, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.455628793103448 ], [ 29.77647, -1.455898275862069 ], [ 29.776739493670885, -1.455898275862069 ], [ 29.776739493670885, -1.455628793103448 ], [ 29.77647, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21528, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.455628793103448 ], [ 29.776739493670885, -1.455898275862069 ], [ 29.777008987341773, -1.455898275862069 ], [ 29.777008987341773, -1.455628793103448 ], [ 29.776739493670885, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21529, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.455628793103448 ], [ 29.777278481012658, -1.455898275862069 ], [ 29.777547974683543, -1.455898275862069 ], [ 29.777547974683543, -1.455628793103448 ], [ 29.777278481012658, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21530, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.455628793103448 ], [ 29.777547974683543, -1.455898275862069 ], [ 29.777817468354431, -1.455898275862069 ], [ 29.777817468354431, -1.455628793103448 ], [ 29.777547974683543, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21531, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.455628793103448 ], [ 29.778086962025316, -1.45616775862069 ], [ 29.778356455696201, -1.45616775862069 ], [ 29.778356455696201, -1.455628793103448 ], [ 29.778086962025316, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21532, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.455628793103448 ], [ 29.778356455696201, -1.455898275862069 ], [ 29.77862594936709, -1.455898275862069 ], [ 29.77862594936709, -1.455628793103448 ], [ 29.778356455696201, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21533, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.455628793103448 ], [ 29.77862594936709, -1.45616775862069 ], [ 29.778895443037975, -1.45616775862069 ], [ 29.778895443037975, -1.455628793103448 ], [ 29.77862594936709, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21534, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.455628793103448 ], [ 29.77916493670886, -1.455898275862069 ], [ 29.779434430379748, -1.455898275862069 ], [ 29.779434430379748, -1.455628793103448 ], [ 29.77916493670886, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21535, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.455628793103448 ], [ 29.779434430379748, -1.45616775862069 ], [ 29.779703924050633, -1.45616775862069 ], [ 29.779703924050633, -1.455628793103448 ], [ 29.779434430379748, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21536, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.455628793103448 ], [ 29.779973417721518, -1.455898275862069 ], [ 29.780242911392406, -1.455898275862069 ], [ 29.780242911392406, -1.455628793103448 ], [ 29.779973417721518, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21537, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.455628793103448 ], [ 29.780242911392406, -1.45616775862069 ], [ 29.780512405063291, -1.45616775862069 ], [ 29.780512405063291, -1.455628793103448 ], [ 29.780242911392406, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21538, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.455628793103448 ], [ 29.780781898734176, -1.455898275862069 ], [ 29.781051392405065, -1.455898275862069 ], [ 29.781051392405065, -1.455628793103448 ], [ 29.780781898734176, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21539, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.455628793103448 ], [ 29.781051392405065, -1.455898275862069 ], [ 29.78132088607595, -1.455898275862069 ], [ 29.78132088607595, -1.455628793103448 ], [ 29.781051392405065, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21540, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.455628793103448 ], [ 29.781590379746834, -1.455898275862069 ], [ 29.781859873417723, -1.455898275862069 ], [ 29.781859873417723, -1.455628793103448 ], [ 29.781590379746834, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21541, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.455628793103448 ], [ 29.781859873417723, -1.455898275862069 ], [ 29.782129367088608, -1.455898275862069 ], [ 29.782129367088608, -1.455628793103448 ], [ 29.781859873417723, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21542, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.455628793103448 ], [ 29.782129367088608, -1.45616775862069 ], [ 29.782398860759493, -1.45616775862069 ], [ 29.782398860759493, -1.455628793103448 ], [ 29.782129367088608, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21543, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.455628793103448 ], [ 29.782398860759493, -1.455898275862069 ], [ 29.782668354430381, -1.455898275862069 ], [ 29.782668354430381, -1.455628793103448 ], [ 29.782398860759493, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21544, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.455628793103448 ], [ 29.782668354430381, -1.455898275862069 ], [ 29.782937848101266, -1.455898275862069 ], [ 29.782937848101266, -1.455628793103448 ], [ 29.782668354430381, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21545, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.455628793103448 ], [ 29.782937848101266, -1.455898275862069 ], [ 29.783207341772151, -1.455898275862069 ], [ 29.783207341772151, -1.455628793103448 ], [ 29.782937848101266, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21546, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.455628793103448 ], [ 29.783476835443039, -1.455898275862069 ], [ 29.784015822784809, -1.455898275862069 ], [ 29.784015822784809, -1.455628793103448 ], [ 29.783476835443039, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21547, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.455628793103448 ], [ 29.784015822784809, -1.455898275862069 ], [ 29.784285316455698, -1.455898275862069 ], [ 29.784285316455698, -1.455628793103448 ], [ 29.784015822784809, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21548, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.455628793103448 ], [ 29.784285316455698, -1.455898275862069 ], [ 29.784554810126583, -1.455898275862069 ], [ 29.784554810126583, -1.455628793103448 ], [ 29.784285316455698, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21549, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.455628793103448 ], [ 29.784554810126583, -1.455898275862069 ], [ 29.784824303797468, -1.455898275862069 ], [ 29.784824303797468, -1.455628793103448 ], [ 29.784554810126583, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21550, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.455628793103448 ], [ 29.784824303797468, -1.45616775862069 ], [ 29.785093797468356, -1.45616775862069 ], [ 29.785093797468356, -1.455628793103448 ], [ 29.784824303797468, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21551, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.455628793103448 ], [ 29.785093797468356, -1.455898275862069 ], [ 29.785363291139241, -1.455898275862069 ], [ 29.785363291139241, -1.455628793103448 ], [ 29.785093797468356, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21552, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.455628793103448 ], [ 29.785363291139241, -1.455898275862069 ], [ 29.785632784810126, -1.455898275862069 ], [ 29.785632784810126, -1.455628793103448 ], [ 29.785363291139241, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21553, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.455628793103448 ], [ 29.785632784810126, -1.455898275862069 ], [ 29.785902278481014, -1.455898275862069 ], [ 29.785902278481014, -1.455628793103448 ], [ 29.785632784810126, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21554, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.455628793103448 ], [ 29.786171772151899, -1.455898275862069 ], [ 29.786441265822784, -1.455898275862069 ], [ 29.786441265822784, -1.455628793103448 ], [ 29.786171772151899, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21555, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.455628793103448 ], [ 29.786441265822784, -1.455898275862069 ], [ 29.786710759493673, -1.455898275862069 ], [ 29.786710759493673, -1.455628793103448 ], [ 29.786441265822784, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21556, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.455628793103448 ], [ 29.786710759493673, -1.455898275862069 ], [ 29.786980253164558, -1.455898275862069 ], [ 29.786980253164558, -1.455628793103448 ], [ 29.786710759493673, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21557, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.455628793103448 ], [ 29.786980253164558, -1.455898275862069 ], [ 29.787249746835442, -1.455898275862069 ], [ 29.787249746835442, -1.455628793103448 ], [ 29.786980253164558, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21558, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.455628793103448 ], [ 29.791292151898734, -1.455898275862069 ], [ 29.791561645569619, -1.455898275862069 ], [ 29.791561645569619, -1.455628793103448 ], [ 29.791292151898734, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21559, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.455628793103448 ], [ 29.791561645569619, -1.455898275862069 ], [ 29.791831139240507, -1.455898275862069 ], [ 29.791831139240507, -1.455628793103448 ], [ 29.791561645569619, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21560, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.455628793103448 ], [ 29.791831139240507, -1.455898275862069 ], [ 29.792100632911392, -1.455898275862069 ], [ 29.792100632911392, -1.455628793103448 ], [ 29.791831139240507, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21561, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.455628793103448 ], [ 29.792909113924051, -1.455898275862069 ], [ 29.793178607594935, -1.455898275862069 ], [ 29.793178607594935, -1.455628793103448 ], [ 29.792909113924051, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21562, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.455628793103448 ], [ 29.793178607594935, -1.455898275862069 ], [ 29.793448101265824, -1.455898275862069 ], [ 29.793448101265824, -1.455628793103448 ], [ 29.793178607594935, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21563, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.455628793103448 ], [ 29.793448101265824, -1.455898275862069 ], [ 29.793717594936709, -1.455898275862069 ], [ 29.793717594936709, -1.455628793103448 ], [ 29.793448101265824, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21564, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.455628793103448 ], [ 29.793717594936709, -1.455898275862069 ], [ 29.793987088607594, -1.455898275862069 ], [ 29.793987088607594, -1.455628793103448 ], [ 29.793717594936709, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21565, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.455628793103448 ], [ 29.793987088607594, -1.455898275862069 ], [ 29.794256582278482, -1.455898275862069 ], [ 29.794256582278482, -1.455628793103448 ], [ 29.793987088607594, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21566, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.455628793103448 ], [ 29.794256582278482, -1.455898275862069 ], [ 29.794526075949367, -1.455898275862069 ], [ 29.794526075949367, -1.455628793103448 ], [ 29.794256582278482, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21567, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.455628793103448 ], [ 29.794526075949367, -1.455898275862069 ], [ 29.794795569620252, -1.455898275862069 ], [ 29.794795569620252, -1.455628793103448 ], [ 29.794526075949367, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21568, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.455628793103448 ], [ 29.794795569620252, -1.455898275862069 ], [ 29.79506506329114, -1.455898275862069 ], [ 29.79506506329114, -1.455628793103448 ], [ 29.794795569620252, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21569, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.455628793103448 ], [ 29.79506506329114, -1.455898275862069 ], [ 29.795334556962025, -1.455898275862069 ], [ 29.795334556962025, -1.455628793103448 ], [ 29.79506506329114, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21570, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.455628793103448 ], [ 29.795334556962025, -1.455898275862069 ], [ 29.79560405063291, -1.455898275862069 ], [ 29.79560405063291, -1.455628793103448 ], [ 29.795334556962025, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21571, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.455628793103448 ], [ 29.79560405063291, -1.455898275862069 ], [ 29.795873544303799, -1.455898275862069 ], [ 29.795873544303799, -1.455628793103448 ], [ 29.79560405063291, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21572, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.455628793103448 ], [ 29.795873544303799, -1.455898275862069 ], [ 29.796143037974684, -1.455898275862069 ], [ 29.796143037974684, -1.455628793103448 ], [ 29.795873544303799, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21573, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.455628793103448 ], [ 29.796143037974684, -1.455898275862069 ], [ 29.796412531645569, -1.455898275862069 ], [ 29.796412531645569, -1.455628793103448 ], [ 29.796143037974684, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21574, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.455628793103448 ], [ 29.796412531645569, -1.455898275862069 ], [ 29.796682025316457, -1.455898275862069 ], [ 29.796682025316457, -1.455628793103448 ], [ 29.796412531645569, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21575, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.455628793103448 ], [ 29.796682025316457, -1.455898275862069 ], [ 29.796951518987342, -1.455898275862069 ], [ 29.796951518987342, -1.455628793103448 ], [ 29.796682025316457, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21576, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.455628793103448 ], [ 29.796951518987342, -1.455898275862069 ], [ 29.797221012658227, -1.455898275862069 ], [ 29.797221012658227, -1.455628793103448 ], [ 29.796951518987342, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21577, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.455628793103448 ], [ 29.797221012658227, -1.455898275862069 ], [ 29.797490506329115, -1.455898275862069 ], [ 29.797490506329115, -1.455628793103448 ], [ 29.797221012658227, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21578, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.455628793103448 ], [ 29.797490506329115, -1.45616775862069 ], [ 29.79776, -1.45616775862069 ], [ 29.79776, -1.455628793103448 ], [ 29.797490506329115, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21579, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.455628793103448 ], [ 29.79776, -1.45616775862069 ], [ 29.798029493670885, -1.45616775862069 ], [ 29.798029493670885, -1.455628793103448 ], [ 29.79776, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21580, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.455628793103448 ], [ 29.808539746835443, -1.455898275862069 ], [ 29.808809240506328, -1.455898275862069 ], [ 29.808809240506328, -1.455628793103448 ], [ 29.808539746835443, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21581, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.455628793103448 ], [ 29.808809240506328, -1.455898275862069 ], [ 29.809078734177216, -1.455898275862069 ], [ 29.809078734177216, -1.455628793103448 ], [ 29.808809240506328, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21582, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.455628793103448 ], [ 29.809078734177216, -1.455898275862069 ], [ 29.809348227848101, -1.455898275862069 ], [ 29.809348227848101, -1.455628793103448 ], [ 29.809078734177216, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21583, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.455628793103448 ], [ 29.809348227848101, -1.455898275862069 ], [ 29.809617721518986, -1.455898275862069 ], [ 29.809617721518986, -1.455628793103448 ], [ 29.809348227848101, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21584, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.455628793103448 ], [ 29.809617721518986, -1.455898275862069 ], [ 29.809887215189875, -1.455898275862069 ], [ 29.809887215189875, -1.455628793103448 ], [ 29.809617721518986, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21585, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.455628793103448 ], [ 29.809887215189875, -1.455898275862069 ], [ 29.81015670886076, -1.455898275862069 ], [ 29.81015670886076, -1.455628793103448 ], [ 29.809887215189875, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21586, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.455628793103448 ], [ 29.81015670886076, -1.455898275862069 ], [ 29.810426202531644, -1.455898275862069 ], [ 29.810426202531644, -1.455628793103448 ], [ 29.81015670886076, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21587, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.455628793103448 ], [ 29.810426202531644, -1.45616775862069 ], [ 29.810965189873418, -1.45616775862069 ], [ 29.810965189873418, -1.455628793103448 ], [ 29.810426202531644, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21588, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.455628793103448 ], [ 29.810965189873418, -1.455898275862069 ], [ 29.811234683544303, -1.455898275862069 ], [ 29.811234683544303, -1.455628793103448 ], [ 29.810965189873418, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21589, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.455628793103448 ], [ 29.811234683544303, -1.455898275862069 ], [ 29.811504177215191, -1.455898275862069 ], [ 29.811504177215191, -1.455628793103448 ], [ 29.811234683544303, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21590, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.455628793103448 ], [ 29.811504177215191, -1.455898275862069 ], [ 29.811773670886076, -1.455898275862069 ], [ 29.811773670886076, -1.455628793103448 ], [ 29.811504177215191, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21591, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.455628793103448 ], [ 29.811773670886076, -1.455898275862069 ], [ 29.812043164556961, -1.455898275862069 ], [ 29.812043164556961, -1.455628793103448 ], [ 29.811773670886076, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21592, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.455628793103448 ], [ 29.812043164556961, -1.455898275862069 ], [ 29.812312658227849, -1.455898275862069 ], [ 29.812312658227849, -1.455628793103448 ], [ 29.812043164556961, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21593, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.455628793103448 ], [ 29.812312658227849, -1.455898275862069 ], [ 29.812582151898734, -1.455898275862069 ], [ 29.812582151898734, -1.455628793103448 ], [ 29.812312658227849, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21594, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.455628793103448 ], [ 29.812582151898734, -1.455898275862069 ], [ 29.812851645569619, -1.455898275862069 ], [ 29.812851645569619, -1.455628793103448 ], [ 29.812582151898734, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21595, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.455628793103448 ], [ 29.812851645569619, -1.455898275862069 ], [ 29.813121139240508, -1.455898275862069 ], [ 29.813121139240508, -1.455628793103448 ], [ 29.812851645569619, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21596, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.455628793103448 ], [ 29.813121139240508, -1.455898275862069 ], [ 29.813390632911393, -1.455898275862069 ], [ 29.813390632911393, -1.455628793103448 ], [ 29.813121139240508, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21597, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.455628793103448 ], [ 29.813390632911393, -1.455898275862069 ], [ 29.813660126582278, -1.455898275862069 ], [ 29.813660126582278, -1.455628793103448 ], [ 29.813390632911393, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21598, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.455628793103448 ], [ 29.813660126582278, -1.455898275862069 ], [ 29.813929620253166, -1.455898275862069 ], [ 29.813929620253166, -1.455628793103448 ], [ 29.813660126582278, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21599, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.455628793103448 ], [ 29.813929620253166, -1.45616775862069 ], [ 29.814199113924051, -1.45616775862069 ], [ 29.814199113924051, -1.455628793103448 ], [ 29.813929620253166, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21600, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.455898275862069 ], [ 29.750868101265823, -1.45616775862069 ], [ 29.751137594936708, -1.45616775862069 ], [ 29.751137594936708, -1.455898275862069 ], [ 29.750868101265823, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21601, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.455898275862069 ], [ 29.751137594936708, -1.45616775862069 ], [ 29.751407088607593, -1.45616775862069 ], [ 29.751407088607593, -1.455898275862069 ], [ 29.751137594936708, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21602, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.455898275862069 ], [ 29.751407088607593, -1.45616775862069 ], [ 29.751676582278481, -1.45616775862069 ], [ 29.751676582278481, -1.455898275862069 ], [ 29.751407088607593, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21603, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.455898275862069 ], [ 29.751676582278481, -1.45616775862069 ], [ 29.751946075949366, -1.45616775862069 ], [ 29.751946075949366, -1.455898275862069 ], [ 29.751676582278481, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21604, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.455898275862069 ], [ 29.751946075949366, -1.45616775862069 ], [ 29.752215569620251, -1.45616775862069 ], [ 29.752215569620251, -1.455898275862069 ], [ 29.751946075949366, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21605, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.455898275862069 ], [ 29.752215569620251, -1.45616775862069 ], [ 29.75248506329114, -1.45616775862069 ], [ 29.75248506329114, -1.455898275862069 ], [ 29.752215569620251, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21606, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.455898275862069 ], [ 29.75248506329114, -1.45616775862069 ], [ 29.752754556962024, -1.45616775862069 ], [ 29.752754556962024, -1.455898275862069 ], [ 29.75248506329114, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21607, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.455898275862069 ], [ 29.752754556962024, -1.45616775862069 ], [ 29.753024050632909, -1.45616775862069 ], [ 29.753024050632909, -1.455898275862069 ], [ 29.752754556962024, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21608, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.455898275862069 ], [ 29.753024050632909, -1.45616775862069 ], [ 29.753293544303798, -1.45616775862069 ], [ 29.753293544303798, -1.455898275862069 ], [ 29.753024050632909, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21609, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.455898275862069 ], [ 29.753293544303798, -1.45616775862069 ], [ 29.753563037974683, -1.45616775862069 ], [ 29.753563037974683, -1.455898275862069 ], [ 29.753293544303798, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21610, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.455898275862069 ], [ 29.753563037974683, -1.45616775862069 ], [ 29.753832531645568, -1.45616775862069 ], [ 29.753832531645568, -1.455898275862069 ], [ 29.753563037974683, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21611, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.455898275862069 ], [ 29.753832531645568, -1.45616775862069 ], [ 29.754102025316456, -1.45616775862069 ], [ 29.754102025316456, -1.455898275862069 ], [ 29.753832531645568, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21612, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.455898275862069 ], [ 29.754102025316456, -1.45616775862069 ], [ 29.754371518987341, -1.45616775862069 ], [ 29.754371518987341, -1.455898275862069 ], [ 29.754102025316456, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21613, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.455898275862069 ], [ 29.754371518987341, -1.45616775862069 ], [ 29.754641012658226, -1.45616775862069 ], [ 29.754641012658226, -1.455898275862069 ], [ 29.754371518987341, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21614, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.455898275862069 ], [ 29.754641012658226, -1.45616775862069 ], [ 29.754910506329114, -1.45616775862069 ], [ 29.754910506329114, -1.455898275862069 ], [ 29.754641012658226, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21615, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.455898275862069 ], [ 29.754910506329114, -1.45616775862069 ], [ 29.75518, -1.45616775862069 ], [ 29.75518, -1.455898275862069 ], [ 29.754910506329114, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21616, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.455898275862069 ], [ 29.75518, -1.45616775862069 ], [ 29.755449493670884, -1.45616775862069 ], [ 29.755449493670884, -1.455898275862069 ], [ 29.75518, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21617, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.455898275862069 ], [ 29.755449493670884, -1.45616775862069 ], [ 29.755718987341773, -1.45616775862069 ], [ 29.755718987341773, -1.455898275862069 ], [ 29.755449493670884, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21618, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.455898275862069 ], [ 29.755718987341773, -1.45616775862069 ], [ 29.755988481012658, -1.45616775862069 ], [ 29.755988481012658, -1.455898275862069 ], [ 29.755718987341773, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21619, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.455898275862069 ], [ 29.755988481012658, -1.45616775862069 ], [ 29.756257974683542, -1.45616775862069 ], [ 29.756257974683542, -1.455898275862069 ], [ 29.755988481012658, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21620, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.455898275862069 ], [ 29.756257974683542, -1.45616775862069 ], [ 29.756796962025316, -1.45616775862069 ], [ 29.756796962025316, -1.455898275862069 ], [ 29.756257974683542, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21621, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.455898275862069 ], [ 29.756796962025316, -1.45616775862069 ], [ 29.757066455696201, -1.45616775862069 ], [ 29.757066455696201, -1.455898275862069 ], [ 29.756796962025316, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21622, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.455898275862069 ], [ 29.757066455696201, -1.45616775862069 ], [ 29.757335949367089, -1.45616775862069 ], [ 29.757335949367089, -1.455898275862069 ], [ 29.757066455696201, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21623, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.455898275862069 ], [ 29.757335949367089, -1.45616775862069 ], [ 29.757605443037974, -1.45616775862069 ], [ 29.757605443037974, -1.455898275862069 ], [ 29.757335949367089, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21624, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.455898275862069 ], [ 29.757874936708859, -1.45616775862069 ], [ 29.758144430379748, -1.45616775862069 ], [ 29.758144430379748, -1.455898275862069 ], [ 29.757874936708859, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21625, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.455898275862069 ], [ 29.758144430379748, -1.45616775862069 ], [ 29.758413924050632, -1.45616775862069 ], [ 29.758413924050632, -1.455898275862069 ], [ 29.758144430379748, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21626, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.455898275862069 ], [ 29.758413924050632, -1.45616775862069 ], [ 29.758683417721517, -1.45616775862069 ], [ 29.758683417721517, -1.455898275862069 ], [ 29.758413924050632, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21627, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.455898275862069 ], [ 29.758683417721517, -1.45616775862069 ], [ 29.758952911392406, -1.45616775862069 ], [ 29.758952911392406, -1.455898275862069 ], [ 29.758683417721517, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21628, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.455898275862069 ], [ 29.758952911392406, -1.456437241379311 ], [ 29.759222405063291, -1.456437241379311 ], [ 29.759222405063291, -1.455898275862069 ], [ 29.758952911392406, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21629, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.455898275862069 ], [ 29.759222405063291, -1.45616775862069 ], [ 29.759491898734176, -1.45616775862069 ], [ 29.759491898734176, -1.455898275862069 ], [ 29.759222405063291, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21630, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.455898275862069 ], [ 29.759491898734176, -1.45616775862069 ], [ 29.759761392405064, -1.45616775862069 ], [ 29.759761392405064, -1.455898275862069 ], [ 29.759491898734176, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21631, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.455898275862069 ], [ 29.759761392405064, -1.45616775862069 ], [ 29.760030886075949, -1.45616775862069 ], [ 29.760030886075949, -1.455898275862069 ], [ 29.759761392405064, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21632, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.455898275862069 ], [ 29.760300379746834, -1.45616775862069 ], [ 29.760569873417722, -1.45616775862069 ], [ 29.760569873417722, -1.455898275862069 ], [ 29.760300379746834, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21633, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.455898275862069 ], [ 29.760569873417722, -1.45616775862069 ], [ 29.760839367088607, -1.45616775862069 ], [ 29.760839367088607, -1.455898275862069 ], [ 29.760569873417722, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21634, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.455898275862069 ], [ 29.760839367088607, -1.45616775862069 ], [ 29.761108860759492, -1.45616775862069 ], [ 29.761108860759492, -1.455898275862069 ], [ 29.760839367088607, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21635, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.455898275862069 ], [ 29.761108860759492, -1.45616775862069 ], [ 29.761378354430381, -1.45616775862069 ], [ 29.761378354430381, -1.455898275862069 ], [ 29.761108860759492, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21636, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.455898275862069 ], [ 29.761378354430381, -1.45616775862069 ], [ 29.761647848101266, -1.45616775862069 ], [ 29.761647848101266, -1.455898275862069 ], [ 29.761378354430381, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21637, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.455898275862069 ], [ 29.761647848101266, -1.45616775862069 ], [ 29.761917341772151, -1.45616775862069 ], [ 29.761917341772151, -1.455898275862069 ], [ 29.761647848101266, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21638, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.455898275862069 ], [ 29.761917341772151, -1.45616775862069 ], [ 29.762186835443039, -1.45616775862069 ], [ 29.762186835443039, -1.455898275862069 ], [ 29.761917341772151, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21639, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.455898275862069 ], [ 29.762186835443039, -1.45616775862069 ], [ 29.762456329113924, -1.45616775862069 ], [ 29.762456329113924, -1.455898275862069 ], [ 29.762186835443039, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21640, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.455898275862069 ], [ 29.762456329113924, -1.45616775862069 ], [ 29.762725822784809, -1.45616775862069 ], [ 29.762725822784809, -1.455898275862069 ], [ 29.762456329113924, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21641, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.455898275862069 ], [ 29.762725822784809, -1.45616775862069 ], [ 29.763264810126582, -1.45616775862069 ], [ 29.763264810126582, -1.455898275862069 ], [ 29.762725822784809, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21642, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.455898275862069 ], [ 29.763264810126582, -1.45616775862069 ], [ 29.763534303797467, -1.45616775862069 ], [ 29.763534303797467, -1.455898275862069 ], [ 29.763264810126582, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21643, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.455898275862069 ], [ 29.763534303797467, -1.45616775862069 ], [ 29.763803797468356, -1.45616775862069 ], [ 29.763803797468356, -1.455898275862069 ], [ 29.763534303797467, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21644, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.455898275862069 ], [ 29.763803797468356, -1.45616775862069 ], [ 29.76407329113924, -1.45616775862069 ], [ 29.76407329113924, -1.455898275862069 ], [ 29.763803797468356, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21645, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.455898275862069 ], [ 29.76407329113924, -1.45616775862069 ], [ 29.764342784810125, -1.45616775862069 ], [ 29.764342784810125, -1.455898275862069 ], [ 29.76407329113924, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21646, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.455898275862069 ], [ 29.764342784810125, -1.45616775862069 ], [ 29.764612278481014, -1.45616775862069 ], [ 29.764612278481014, -1.455898275862069 ], [ 29.764342784810125, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21647, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.455898275862069 ], [ 29.764612278481014, -1.45616775862069 ], [ 29.765151265822784, -1.45616775862069 ], [ 29.765151265822784, -1.455898275862069 ], [ 29.764612278481014, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21648, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.455898275862069 ], [ 29.765151265822784, -1.45616775862069 ], [ 29.765420759493672, -1.45616775862069 ], [ 29.765420759493672, -1.455898275862069 ], [ 29.765151265822784, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21649, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.455898275862069 ], [ 29.765420759493672, -1.45616775862069 ], [ 29.765959746835442, -1.45616775862069 ], [ 29.765959746835442, -1.455898275862069 ], [ 29.765420759493672, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21650, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.455898275862069 ], [ 29.765959746835442, -1.45616775862069 ], [ 29.766229240506327, -1.45616775862069 ], [ 29.766229240506327, -1.455898275862069 ], [ 29.765959746835442, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21651, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.455898275862069 ], [ 29.766229240506327, -1.45616775862069 ], [ 29.7667682278481, -1.45616775862069 ], [ 29.7667682278481, -1.455898275862069 ], [ 29.766229240506327, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21652, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.455898275862069 ], [ 29.7667682278481, -1.45616775862069 ], [ 29.767037721518985, -1.45616775862069 ], [ 29.767037721518985, -1.455898275862069 ], [ 29.7667682278481, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21653, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.455898275862069 ], [ 29.767037721518985, -1.45616775862069 ], [ 29.767307215189874, -1.45616775862069 ], [ 29.767307215189874, -1.455898275862069 ], [ 29.767037721518985, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21654, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.455898275862069 ], [ 29.767307215189874, -1.45616775862069 ], [ 29.768385189873417, -1.45616775862069 ], [ 29.768385189873417, -1.455898275862069 ], [ 29.767307215189874, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21655, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.455898275862069 ], [ 29.768385189873417, -1.45616775862069 ], [ 29.768654683544302, -1.45616775862069 ], [ 29.768654683544302, -1.455898275862069 ], [ 29.768385189873417, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21656, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.455898275862069 ], [ 29.768654683544302, -1.45616775862069 ], [ 29.76892417721519, -1.45616775862069 ], [ 29.76892417721519, -1.455898275862069 ], [ 29.768654683544302, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21657, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.455898275862069 ], [ 29.76892417721519, -1.45616775862069 ], [ 29.769193670886075, -1.45616775862069 ], [ 29.769193670886075, -1.455898275862069 ], [ 29.76892417721519, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21658, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.455898275862069 ], [ 29.769193670886075, -1.45616775862069 ], [ 29.76946316455696, -1.45616775862069 ], [ 29.76946316455696, -1.455898275862069 ], [ 29.769193670886075, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21659, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.455898275862069 ], [ 29.76946316455696, -1.45616775862069 ], [ 29.769732658227849, -1.45616775862069 ], [ 29.769732658227849, -1.455898275862069 ], [ 29.76946316455696, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21660, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.455898275862069 ], [ 29.769732658227849, -1.45616775862069 ], [ 29.770002151898733, -1.45616775862069 ], [ 29.770002151898733, -1.455898275862069 ], [ 29.769732658227849, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21661, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.455898275862069 ], [ 29.770002151898733, -1.45616775862069 ], [ 29.770271645569618, -1.45616775862069 ], [ 29.770271645569618, -1.455898275862069 ], [ 29.770002151898733, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21662, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.455898275862069 ], [ 29.770271645569618, -1.45616775862069 ], [ 29.770541139240507, -1.45616775862069 ], [ 29.770541139240507, -1.455898275862069 ], [ 29.770271645569618, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21663, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.455898275862069 ], [ 29.770541139240507, -1.45616775862069 ], [ 29.770810632911392, -1.45616775862069 ], [ 29.770810632911392, -1.455898275862069 ], [ 29.770541139240507, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21664, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.455898275862069 ], [ 29.770810632911392, -1.45616775862069 ], [ 29.771080126582277, -1.45616775862069 ], [ 29.771080126582277, -1.455898275862069 ], [ 29.770810632911392, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21665, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.455898275862069 ], [ 29.771080126582277, -1.45616775862069 ], [ 29.771349620253165, -1.45616775862069 ], [ 29.771349620253165, -1.455898275862069 ], [ 29.771080126582277, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21666, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.455898275862069 ], [ 29.771349620253165, -1.45616775862069 ], [ 29.77161911392405, -1.45616775862069 ], [ 29.77161911392405, -1.455898275862069 ], [ 29.771349620253165, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21667, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.455898275862069 ], [ 29.77161911392405, -1.45616775862069 ], [ 29.771888607594935, -1.45616775862069 ], [ 29.771888607594935, -1.455898275862069 ], [ 29.77161911392405, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21668, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.455898275862069 ], [ 29.771888607594935, -1.45616775862069 ], [ 29.772158101265823, -1.45616775862069 ], [ 29.772158101265823, -1.455898275862069 ], [ 29.771888607594935, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21669, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.455898275862069 ], [ 29.772158101265823, -1.45616775862069 ], [ 29.772427594936708, -1.45616775862069 ], [ 29.772427594936708, -1.455898275862069 ], [ 29.772158101265823, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21670, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.455898275862069 ], [ 29.772427594936708, -1.45616775862069 ], [ 29.772697088607593, -1.45616775862069 ], [ 29.772697088607593, -1.455898275862069 ], [ 29.772427594936708, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21671, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.455898275862069 ], [ 29.772697088607593, -1.45616775862069 ], [ 29.772966582278482, -1.45616775862069 ], [ 29.772966582278482, -1.455898275862069 ], [ 29.772697088607593, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21672, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.455898275862069 ], [ 29.772966582278482, -1.45616775862069 ], [ 29.773236075949367, -1.45616775862069 ], [ 29.773236075949367, -1.455898275862069 ], [ 29.772966582278482, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21673, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.455898275862069 ], [ 29.773236075949367, -1.45616775862069 ], [ 29.773505569620252, -1.45616775862069 ], [ 29.773505569620252, -1.455898275862069 ], [ 29.773236075949367, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21674, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.455898275862069 ], [ 29.773505569620252, -1.45616775862069 ], [ 29.77377506329114, -1.45616775862069 ], [ 29.77377506329114, -1.455898275862069 ], [ 29.773505569620252, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21675, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.455898275862069 ], [ 29.77377506329114, -1.45616775862069 ], [ 29.774044556962025, -1.45616775862069 ], [ 29.774044556962025, -1.455898275862069 ], [ 29.77377506329114, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21676, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.455898275862069 ], [ 29.774044556962025, -1.45616775862069 ], [ 29.77431405063291, -1.45616775862069 ], [ 29.77431405063291, -1.455898275862069 ], [ 29.774044556962025, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21677, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.455898275862069 ], [ 29.77431405063291, -1.45616775862069 ], [ 29.774853037974683, -1.45616775862069 ], [ 29.774853037974683, -1.455898275862069 ], [ 29.77431405063291, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21678, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.455898275862069 ], [ 29.774853037974683, -1.45616775862069 ], [ 29.775122531645568, -1.45616775862069 ], [ 29.775122531645568, -1.455898275862069 ], [ 29.774853037974683, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21679, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.455898275862069 ], [ 29.775122531645568, -1.45616775862069 ], [ 29.775392025316457, -1.45616775862069 ], [ 29.775392025316457, -1.455898275862069 ], [ 29.775122531645568, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21680, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.455898275862069 ], [ 29.775392025316457, -1.45616775862069 ], [ 29.775661518987341, -1.45616775862069 ], [ 29.775661518987341, -1.455898275862069 ], [ 29.775392025316457, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21681, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.455898275862069 ], [ 29.775661518987341, -1.45616775862069 ], [ 29.775931012658226, -1.45616775862069 ], [ 29.775931012658226, -1.455898275862069 ], [ 29.775661518987341, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21682, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.455898275862069 ], [ 29.775931012658226, -1.45616775862069 ], [ 29.776200506329115, -1.45616775862069 ], [ 29.776200506329115, -1.455898275862069 ], [ 29.775931012658226, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21683, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.455898275862069 ], [ 29.77647, -1.45616775862069 ], [ 29.776739493670885, -1.45616775862069 ], [ 29.776739493670885, -1.455898275862069 ], [ 29.77647, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21684, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.455898275862069 ], [ 29.776739493670885, -1.45616775862069 ], [ 29.777008987341773, -1.45616775862069 ], [ 29.777008987341773, -1.455898275862069 ], [ 29.776739493670885, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21685, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.455898275862069 ], [ 29.777008987341773, -1.45616775862069 ], [ 29.777278481012658, -1.45616775862069 ], [ 29.777278481012658, -1.455898275862069 ], [ 29.777008987341773, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21686, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.455898275862069 ], [ 29.777278481012658, -1.456437241379311 ], [ 29.777547974683543, -1.456437241379311 ], [ 29.777547974683543, -1.455898275862069 ], [ 29.777278481012658, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21687, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.455898275862069 ], [ 29.777547974683543, -1.45616775862069 ], [ 29.777817468354431, -1.45616775862069 ], [ 29.777817468354431, -1.455898275862069 ], [ 29.777547974683543, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21688, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.455898275862069 ], [ 29.777817468354431, -1.45616775862069 ], [ 29.778086962025316, -1.45616775862069 ], [ 29.778086962025316, -1.455898275862069 ], [ 29.777817468354431, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21689, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.455898275862069 ], [ 29.778356455696201, -1.45616775862069 ], [ 29.77862594936709, -1.45616775862069 ], [ 29.77862594936709, -1.455898275862069 ], [ 29.778356455696201, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21690, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.455898275862069 ], [ 29.778895443037975, -1.45616775862069 ], [ 29.77916493670886, -1.45616775862069 ], [ 29.77916493670886, -1.455898275862069 ], [ 29.778895443037975, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21691, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.455898275862069 ], [ 29.77916493670886, -1.456437241379311 ], [ 29.779434430379748, -1.456437241379311 ], [ 29.779434430379748, -1.455898275862069 ], [ 29.77916493670886, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21692, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.455898275862069 ], [ 29.779703924050633, -1.45616775862069 ], [ 29.779973417721518, -1.45616775862069 ], [ 29.779973417721518, -1.455898275862069 ], [ 29.779703924050633, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21693, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.455898275862069 ], [ 29.779973417721518, -1.45616775862069 ], [ 29.780242911392406, -1.45616775862069 ], [ 29.780242911392406, -1.455898275862069 ], [ 29.779973417721518, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21694, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.455898275862069 ], [ 29.780512405063291, -1.45616775862069 ], [ 29.780781898734176, -1.45616775862069 ], [ 29.780781898734176, -1.455898275862069 ], [ 29.780512405063291, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21695, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.455898275862069 ], [ 29.780781898734176, -1.45616775862069 ], [ 29.781051392405065, -1.45616775862069 ], [ 29.781051392405065, -1.455898275862069 ], [ 29.780781898734176, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21696, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.455898275862069 ], [ 29.781051392405065, -1.456437241379311 ], [ 29.78132088607595, -1.456437241379311 ], [ 29.78132088607595, -1.455898275862069 ], [ 29.781051392405065, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21697, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.455898275862069 ], [ 29.78132088607595, -1.45616775862069 ], [ 29.781590379746834, -1.45616775862069 ], [ 29.781590379746834, -1.455898275862069 ], [ 29.78132088607595, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21698, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.455898275862069 ], [ 29.781590379746834, -1.45616775862069 ], [ 29.781859873417723, -1.45616775862069 ], [ 29.781859873417723, -1.455898275862069 ], [ 29.781590379746834, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21699, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.455898275862069 ], [ 29.781859873417723, -1.45616775862069 ], [ 29.782129367088608, -1.45616775862069 ], [ 29.782129367088608, -1.455898275862069 ], [ 29.781859873417723, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21700, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.455898275862069 ], [ 29.782398860759493, -1.45616775862069 ], [ 29.782668354430381, -1.45616775862069 ], [ 29.782668354430381, -1.455898275862069 ], [ 29.782398860759493, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21701, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.455898275862069 ], [ 29.782668354430381, -1.45616775862069 ], [ 29.782937848101266, -1.45616775862069 ], [ 29.782937848101266, -1.455898275862069 ], [ 29.782668354430381, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21702, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.455898275862069 ], [ 29.782937848101266, -1.45616775862069 ], [ 29.783207341772151, -1.45616775862069 ], [ 29.783207341772151, -1.455898275862069 ], [ 29.782937848101266, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21703, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.455898275862069 ], [ 29.783207341772151, -1.45616775862069 ], [ 29.783476835443039, -1.45616775862069 ], [ 29.783476835443039, -1.455898275862069 ], [ 29.783207341772151, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21704, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.455898275862069 ], [ 29.783476835443039, -1.45616775862069 ], [ 29.784015822784809, -1.45616775862069 ], [ 29.784015822784809, -1.455898275862069 ], [ 29.783476835443039, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21705, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.455898275862069 ], [ 29.784015822784809, -1.45616775862069 ], [ 29.784285316455698, -1.45616775862069 ], [ 29.784285316455698, -1.455898275862069 ], [ 29.784015822784809, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21706, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.455898275862069 ], [ 29.784285316455698, -1.45616775862069 ], [ 29.784554810126583, -1.45616775862069 ], [ 29.784554810126583, -1.455898275862069 ], [ 29.784285316455698, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21707, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.455898275862069 ], [ 29.784554810126583, -1.45616775862069 ], [ 29.784824303797468, -1.45616775862069 ], [ 29.784824303797468, -1.455898275862069 ], [ 29.784554810126583, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21708, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.455898275862069 ], [ 29.785093797468356, -1.45616775862069 ], [ 29.785363291139241, -1.45616775862069 ], [ 29.785363291139241, -1.455898275862069 ], [ 29.785093797468356, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21709, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.455898275862069 ], [ 29.785363291139241, -1.45616775862069 ], [ 29.785632784810126, -1.45616775862069 ], [ 29.785632784810126, -1.455898275862069 ], [ 29.785363291139241, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21710, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.455898275862069 ], [ 29.785632784810126, -1.45616775862069 ], [ 29.785902278481014, -1.45616775862069 ], [ 29.785902278481014, -1.455898275862069 ], [ 29.785632784810126, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21711, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.455898275862069 ], [ 29.786171772151899, -1.45616775862069 ], [ 29.786441265822784, -1.45616775862069 ], [ 29.786441265822784, -1.455898275862069 ], [ 29.786171772151899, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21712, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.455898275862069 ], [ 29.786441265822784, -1.45616775862069 ], [ 29.786710759493673, -1.45616775862069 ], [ 29.786710759493673, -1.455898275862069 ], [ 29.786441265822784, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21713, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.455898275862069 ], [ 29.791292151898734, -1.45616775862069 ], [ 29.791561645569619, -1.45616775862069 ], [ 29.791561645569619, -1.455898275862069 ], [ 29.791292151898734, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.455898275862069 ], [ 29.791561645569619, -1.45616775862069 ], [ 29.791831139240507, -1.45616775862069 ], [ 29.791831139240507, -1.455898275862069 ], [ 29.791561645569619, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21715, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.455898275862069 ], [ 29.791831139240507, -1.45616775862069 ], [ 29.792100632911392, -1.45616775862069 ], [ 29.792100632911392, -1.455898275862069 ], [ 29.791831139240507, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21716, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.455898275862069 ], [ 29.792100632911392, -1.45616775862069 ], [ 29.792370126582277, -1.45616775862069 ], [ 29.792370126582277, -1.455898275862069 ], [ 29.792100632911392, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21717, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.455898275862069 ], [ 29.792370126582277, -1.45616775862069 ], [ 29.792909113924051, -1.45616775862069 ], [ 29.792909113924051, -1.455898275862069 ], [ 29.792370126582277, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21718, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.455898275862069 ], [ 29.792909113924051, -1.45616775862069 ], [ 29.793178607594935, -1.45616775862069 ], [ 29.793178607594935, -1.455898275862069 ], [ 29.792909113924051, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21719, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.455898275862069 ], [ 29.793178607594935, -1.45616775862069 ], [ 29.793448101265824, -1.45616775862069 ], [ 29.793448101265824, -1.455898275862069 ], [ 29.793178607594935, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21720, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.455898275862069 ], [ 29.793448101265824, -1.45616775862069 ], [ 29.793717594936709, -1.45616775862069 ], [ 29.793717594936709, -1.455898275862069 ], [ 29.793448101265824, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21721, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.455898275862069 ], [ 29.793717594936709, -1.45616775862069 ], [ 29.793987088607594, -1.45616775862069 ], [ 29.793987088607594, -1.455898275862069 ], [ 29.793717594936709, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21722, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.455898275862069 ], [ 29.793987088607594, -1.45616775862069 ], [ 29.794256582278482, -1.45616775862069 ], [ 29.794256582278482, -1.455898275862069 ], [ 29.793987088607594, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21723, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.455898275862069 ], [ 29.794256582278482, -1.45616775862069 ], [ 29.794526075949367, -1.45616775862069 ], [ 29.794526075949367, -1.455898275862069 ], [ 29.794256582278482, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21724, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.455898275862069 ], [ 29.794526075949367, -1.45616775862069 ], [ 29.794795569620252, -1.45616775862069 ], [ 29.794795569620252, -1.455898275862069 ], [ 29.794526075949367, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21725, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.455898275862069 ], [ 29.794795569620252, -1.45616775862069 ], [ 29.79506506329114, -1.45616775862069 ], [ 29.79506506329114, -1.455898275862069 ], [ 29.794795569620252, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21726, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.455898275862069 ], [ 29.79506506329114, -1.45616775862069 ], [ 29.795334556962025, -1.45616775862069 ], [ 29.795334556962025, -1.455898275862069 ], [ 29.79506506329114, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21727, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.455898275862069 ], [ 29.795334556962025, -1.45616775862069 ], [ 29.79560405063291, -1.45616775862069 ], [ 29.79560405063291, -1.455898275862069 ], [ 29.795334556962025, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21728, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.455898275862069 ], [ 29.79560405063291, -1.45616775862069 ], [ 29.795873544303799, -1.45616775862069 ], [ 29.795873544303799, -1.455898275862069 ], [ 29.79560405063291, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21729, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.455898275862069 ], [ 29.795873544303799, -1.45616775862069 ], [ 29.796143037974684, -1.45616775862069 ], [ 29.796143037974684, -1.455898275862069 ], [ 29.795873544303799, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21730, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.455898275862069 ], [ 29.796143037974684, -1.45616775862069 ], [ 29.796412531645569, -1.45616775862069 ], [ 29.796412531645569, -1.455898275862069 ], [ 29.796143037974684, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21731, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.455898275862069 ], [ 29.796412531645569, -1.456437241379311 ], [ 29.796682025316457, -1.456437241379311 ], [ 29.796682025316457, -1.455898275862069 ], [ 29.796412531645569, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21732, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.455898275862069 ], [ 29.796682025316457, -1.456437241379311 ], [ 29.796951518987342, -1.456437241379311 ], [ 29.796951518987342, -1.455898275862069 ], [ 29.796682025316457, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21733, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.455898275862069 ], [ 29.796951518987342, -1.45616775862069 ], [ 29.797221012658227, -1.45616775862069 ], [ 29.797221012658227, -1.455898275862069 ], [ 29.796951518987342, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21734, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.455898275862069 ], [ 29.797221012658227, -1.456437241379311 ], [ 29.797490506329115, -1.456437241379311 ], [ 29.797490506329115, -1.455898275862069 ], [ 29.797221012658227, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21735, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.455898275862069 ], [ 29.798029493670885, -1.456437241379311 ], [ 29.798298987341774, -1.456437241379311 ], [ 29.798298987341774, -1.455898275862069 ], [ 29.798029493670885, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21736, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.455898275862069 ], [ 29.798837974683543, -1.45616775862069 ], [ 29.799107468354432, -1.45616775862069 ], [ 29.799107468354432, -1.455898275862069 ], [ 29.798837974683543, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21737, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.455898275862069 ], [ 29.799107468354432, -1.45616775862069 ], [ 29.799376962025317, -1.45616775862069 ], [ 29.799376962025317, -1.455898275862069 ], [ 29.799107468354432, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21738, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.455898275862069 ], [ 29.799376962025317, -1.45616775862069 ], [ 29.799646455696202, -1.45616775862069 ], [ 29.799646455696202, -1.455898275862069 ], [ 29.799376962025317, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21739, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.455898275862069 ], [ 29.799646455696202, -1.456437241379311 ], [ 29.79991594936709, -1.456437241379311 ], [ 29.79991594936709, -1.455898275862069 ], [ 29.799646455696202, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21740, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.455898275862069 ], [ 29.808809240506328, -1.45616775862069 ], [ 29.809078734177216, -1.45616775862069 ], [ 29.809078734177216, -1.455898275862069 ], [ 29.808809240506328, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21741, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.455898275862069 ], [ 29.809078734177216, -1.45616775862069 ], [ 29.809348227848101, -1.45616775862069 ], [ 29.809348227848101, -1.455898275862069 ], [ 29.809078734177216, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21742, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.455898275862069 ], [ 29.809348227848101, -1.45616775862069 ], [ 29.809617721518986, -1.45616775862069 ], [ 29.809617721518986, -1.455898275862069 ], [ 29.809348227848101, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21743, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.455898275862069 ], [ 29.809617721518986, -1.45616775862069 ], [ 29.809887215189875, -1.45616775862069 ], [ 29.809887215189875, -1.455898275862069 ], [ 29.809617721518986, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21744, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.455898275862069 ], [ 29.810965189873418, -1.456437241379311 ], [ 29.811234683544303, -1.456437241379311 ], [ 29.811234683544303, -1.455898275862069 ], [ 29.810965189873418, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21745, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.455898275862069 ], [ 29.811234683544303, -1.45616775862069 ], [ 29.811504177215191, -1.45616775862069 ], [ 29.811504177215191, -1.455898275862069 ], [ 29.811234683544303, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21746, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.455898275862069 ], [ 29.811504177215191, -1.45616775862069 ], [ 29.811773670886076, -1.45616775862069 ], [ 29.811773670886076, -1.455898275862069 ], [ 29.811504177215191, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21747, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.455898275862069 ], [ 29.811773670886076, -1.45616775862069 ], [ 29.812043164556961, -1.45616775862069 ], [ 29.812043164556961, -1.455898275862069 ], [ 29.811773670886076, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21748, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.455898275862069 ], [ 29.812043164556961, -1.45616775862069 ], [ 29.812312658227849, -1.45616775862069 ], [ 29.812312658227849, -1.455898275862069 ], [ 29.812043164556961, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21749, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.455898275862069 ], [ 29.812312658227849, -1.45616775862069 ], [ 29.812582151898734, -1.45616775862069 ], [ 29.812582151898734, -1.455898275862069 ], [ 29.812312658227849, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21750, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.455898275862069 ], [ 29.812582151898734, -1.45616775862069 ], [ 29.812851645569619, -1.45616775862069 ], [ 29.812851645569619, -1.455898275862069 ], [ 29.812582151898734, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21751, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.455898275862069 ], [ 29.812851645569619, -1.45616775862069 ], [ 29.813121139240508, -1.45616775862069 ], [ 29.813121139240508, -1.455898275862069 ], [ 29.812851645569619, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21752, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.455898275862069 ], [ 29.813121139240508, -1.45616775862069 ], [ 29.813390632911393, -1.45616775862069 ], [ 29.813390632911393, -1.455898275862069 ], [ 29.813121139240508, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21753, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.455898275862069 ], [ 29.813390632911393, -1.45616775862069 ], [ 29.813660126582278, -1.45616775862069 ], [ 29.813660126582278, -1.455898275862069 ], [ 29.813390632911393, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21754, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.455898275862069 ], [ 29.813660126582278, -1.45616775862069 ], [ 29.813929620253166, -1.45616775862069 ], [ 29.813929620253166, -1.455898275862069 ], [ 29.813660126582278, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21755, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.750868101265823, -1.45616775862069 ], [ 29.750868101265823, -1.456706724137931 ], [ 29.751137594936708, -1.456706724137931 ], [ 29.751137594936708, -1.45616775862069 ], [ 29.750868101265823, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21756, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.45616775862069 ], [ 29.751137594936708, -1.456437241379311 ], [ 29.751407088607593, -1.456437241379311 ], [ 29.751407088607593, -1.45616775862069 ], [ 29.751137594936708, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21757, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.45616775862069 ], [ 29.751407088607593, -1.456437241379311 ], [ 29.751676582278481, -1.456437241379311 ], [ 29.751676582278481, -1.45616775862069 ], [ 29.751407088607593, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21758, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.45616775862069 ], [ 29.751676582278481, -1.456437241379311 ], [ 29.751946075949366, -1.456437241379311 ], [ 29.751946075949366, -1.45616775862069 ], [ 29.751676582278481, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21759, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.45616775862069 ], [ 29.751946075949366, -1.456437241379311 ], [ 29.752215569620251, -1.456437241379311 ], [ 29.752215569620251, -1.45616775862069 ], [ 29.751946075949366, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21760, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.45616775862069 ], [ 29.752215569620251, -1.456437241379311 ], [ 29.75248506329114, -1.456437241379311 ], [ 29.75248506329114, -1.45616775862069 ], [ 29.752215569620251, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21761, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.45616775862069 ], [ 29.75248506329114, -1.456437241379311 ], [ 29.752754556962024, -1.456437241379311 ], [ 29.752754556962024, -1.45616775862069 ], [ 29.75248506329114, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21762, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.45616775862069 ], [ 29.752754556962024, -1.456437241379311 ], [ 29.753024050632909, -1.456437241379311 ], [ 29.753024050632909, -1.45616775862069 ], [ 29.752754556962024, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21763, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.45616775862069 ], [ 29.753024050632909, -1.456437241379311 ], [ 29.753293544303798, -1.456437241379311 ], [ 29.753293544303798, -1.45616775862069 ], [ 29.753024050632909, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21764, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.45616775862069 ], [ 29.753293544303798, -1.456437241379311 ], [ 29.753563037974683, -1.456437241379311 ], [ 29.753563037974683, -1.45616775862069 ], [ 29.753293544303798, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21765, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.45616775862069 ], [ 29.753563037974683, -1.456437241379311 ], [ 29.753832531645568, -1.456437241379311 ], [ 29.753832531645568, -1.45616775862069 ], [ 29.753563037974683, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21766, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.45616775862069 ], [ 29.753832531645568, -1.456437241379311 ], [ 29.754102025316456, -1.456437241379311 ], [ 29.754102025316456, -1.45616775862069 ], [ 29.753832531645568, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21767, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.45616775862069 ], [ 29.754102025316456, -1.456437241379311 ], [ 29.754371518987341, -1.456437241379311 ], [ 29.754371518987341, -1.45616775862069 ], [ 29.754102025316456, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21768, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.45616775862069 ], [ 29.754371518987341, -1.456437241379311 ], [ 29.754641012658226, -1.456437241379311 ], [ 29.754641012658226, -1.45616775862069 ], [ 29.754371518987341, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21769, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.45616775862069 ], [ 29.754641012658226, -1.456437241379311 ], [ 29.754910506329114, -1.456437241379311 ], [ 29.754910506329114, -1.45616775862069 ], [ 29.754641012658226, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21770, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.45616775862069 ], [ 29.754910506329114, -1.456437241379311 ], [ 29.75518, -1.456437241379311 ], [ 29.75518, -1.45616775862069 ], [ 29.754910506329114, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21771, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.45616775862069 ], [ 29.75518, -1.456437241379311 ], [ 29.755449493670884, -1.456437241379311 ], [ 29.755449493670884, -1.45616775862069 ], [ 29.75518, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21772, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.45616775862069 ], [ 29.755449493670884, -1.456437241379311 ], [ 29.755718987341773, -1.456437241379311 ], [ 29.755718987341773, -1.45616775862069 ], [ 29.755449493670884, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21773, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.45616775862069 ], [ 29.755718987341773, -1.456437241379311 ], [ 29.755988481012658, -1.456437241379311 ], [ 29.755988481012658, -1.45616775862069 ], [ 29.755718987341773, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21774, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.45616775862069 ], [ 29.755988481012658, -1.456437241379311 ], [ 29.756257974683542, -1.456437241379311 ], [ 29.756257974683542, -1.45616775862069 ], [ 29.755988481012658, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21775, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.45616775862069 ], [ 29.756257974683542, -1.456437241379311 ], [ 29.756796962025316, -1.456437241379311 ], [ 29.756796962025316, -1.45616775862069 ], [ 29.756257974683542, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21776, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.45616775862069 ], [ 29.756796962025316, -1.456437241379311 ], [ 29.757066455696201, -1.456437241379311 ], [ 29.757066455696201, -1.45616775862069 ], [ 29.756796962025316, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21777, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.45616775862069 ], [ 29.757066455696201, -1.456437241379311 ], [ 29.757335949367089, -1.456437241379311 ], [ 29.757335949367089, -1.45616775862069 ], [ 29.757066455696201, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21778, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.45616775862069 ], [ 29.757335949367089, -1.456437241379311 ], [ 29.757605443037974, -1.456437241379311 ], [ 29.757605443037974, -1.45616775862069 ], [ 29.757335949367089, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21779, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.45616775862069 ], [ 29.757605443037974, -1.456437241379311 ], [ 29.757874936708859, -1.456437241379311 ], [ 29.757874936708859, -1.45616775862069 ], [ 29.757605443037974, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21780, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.45616775862069 ], [ 29.757874936708859, -1.456437241379311 ], [ 29.758144430379748, -1.456437241379311 ], [ 29.758144430379748, -1.45616775862069 ], [ 29.757874936708859, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21781, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.45616775862069 ], [ 29.758144430379748, -1.456437241379311 ], [ 29.758413924050632, -1.456437241379311 ], [ 29.758413924050632, -1.45616775862069 ], [ 29.758144430379748, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21782, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.45616775862069 ], [ 29.758413924050632, -1.456437241379311 ], [ 29.758683417721517, -1.456437241379311 ], [ 29.758683417721517, -1.45616775862069 ], [ 29.758413924050632, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21783, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.45616775862069 ], [ 29.758683417721517, -1.456437241379311 ], [ 29.758952911392406, -1.456437241379311 ], [ 29.758952911392406, -1.45616775862069 ], [ 29.758683417721517, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21784, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.45616775862069 ], [ 29.759222405063291, -1.456437241379311 ], [ 29.759491898734176, -1.456437241379311 ], [ 29.759491898734176, -1.45616775862069 ], [ 29.759222405063291, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21785, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.45616775862069 ], [ 29.759491898734176, -1.456437241379311 ], [ 29.759761392405064, -1.456437241379311 ], [ 29.759761392405064, -1.45616775862069 ], [ 29.759491898734176, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21786, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.45616775862069 ], [ 29.759761392405064, -1.456437241379311 ], [ 29.760030886075949, -1.456437241379311 ], [ 29.760030886075949, -1.45616775862069 ], [ 29.759761392405064, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21787, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.45616775862069 ], [ 29.760030886075949, -1.456437241379311 ], [ 29.760300379746834, -1.456437241379311 ], [ 29.760300379746834, -1.45616775862069 ], [ 29.760030886075949, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21788, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.45616775862069 ], [ 29.760300379746834, -1.456437241379311 ], [ 29.760569873417722, -1.456437241379311 ], [ 29.760569873417722, -1.45616775862069 ], [ 29.760300379746834, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21789, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.45616775862069 ], [ 29.760569873417722, -1.456437241379311 ], [ 29.760839367088607, -1.456437241379311 ], [ 29.760839367088607, -1.45616775862069 ], [ 29.760569873417722, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21790, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.45616775862069 ], [ 29.760839367088607, -1.456437241379311 ], [ 29.761108860759492, -1.456437241379311 ], [ 29.761108860759492, -1.45616775862069 ], [ 29.760839367088607, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21791, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.45616775862069 ], [ 29.761108860759492, -1.456437241379311 ], [ 29.761378354430381, -1.456437241379311 ], [ 29.761378354430381, -1.45616775862069 ], [ 29.761108860759492, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21792, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.45616775862069 ], [ 29.761378354430381, -1.456437241379311 ], [ 29.761647848101266, -1.456437241379311 ], [ 29.761647848101266, -1.45616775862069 ], [ 29.761378354430381, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21793, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.45616775862069 ], [ 29.761647848101266, -1.456437241379311 ], [ 29.761917341772151, -1.456437241379311 ], [ 29.761917341772151, -1.45616775862069 ], [ 29.761647848101266, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21794, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.45616775862069 ], [ 29.761917341772151, -1.456437241379311 ], [ 29.762186835443039, -1.456437241379311 ], [ 29.762186835443039, -1.45616775862069 ], [ 29.761917341772151, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21795, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.45616775862069 ], [ 29.762186835443039, -1.456437241379311 ], [ 29.762456329113924, -1.456437241379311 ], [ 29.762456329113924, -1.45616775862069 ], [ 29.762186835443039, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21796, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.45616775862069 ], [ 29.762456329113924, -1.456437241379311 ], [ 29.763534303797467, -1.456437241379311 ], [ 29.763534303797467, -1.45616775862069 ], [ 29.762456329113924, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21797, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.45616775862069 ], [ 29.763534303797467, -1.456437241379311 ], [ 29.763803797468356, -1.456437241379311 ], [ 29.763803797468356, -1.45616775862069 ], [ 29.763534303797467, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21798, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.45616775862069 ], [ 29.763803797468356, -1.456437241379311 ], [ 29.76407329113924, -1.456437241379311 ], [ 29.76407329113924, -1.45616775862069 ], [ 29.763803797468356, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21799, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.45616775862069 ], [ 29.76407329113924, -1.456437241379311 ], [ 29.764342784810125, -1.456437241379311 ], [ 29.764342784810125, -1.45616775862069 ], [ 29.76407329113924, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21800, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.45616775862069 ], [ 29.764342784810125, -1.456437241379311 ], [ 29.764612278481014, -1.456437241379311 ], [ 29.764612278481014, -1.45616775862069 ], [ 29.764342784810125, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21801, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.45616775862069 ], [ 29.764612278481014, -1.456437241379311 ], [ 29.765151265822784, -1.456437241379311 ], [ 29.765151265822784, -1.45616775862069 ], [ 29.764612278481014, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21802, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.45616775862069 ], [ 29.765151265822784, -1.456437241379311 ], [ 29.765420759493672, -1.456437241379311 ], [ 29.765420759493672, -1.45616775862069 ], [ 29.765151265822784, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21803, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.45616775862069 ], [ 29.765420759493672, -1.456437241379311 ], [ 29.765959746835442, -1.456437241379311 ], [ 29.765959746835442, -1.45616775862069 ], [ 29.765420759493672, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21804, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.45616775862069 ], [ 29.765959746835442, -1.456437241379311 ], [ 29.766229240506327, -1.456437241379311 ], [ 29.766229240506327, -1.45616775862069 ], [ 29.765959746835442, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21805, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.45616775862069 ], [ 29.766229240506327, -1.456437241379311 ], [ 29.7667682278481, -1.456437241379311 ], [ 29.7667682278481, -1.45616775862069 ], [ 29.766229240506327, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21806, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.45616775862069 ], [ 29.7667682278481, -1.456437241379311 ], [ 29.767037721518985, -1.456437241379311 ], [ 29.767037721518985, -1.45616775862069 ], [ 29.7667682278481, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21807, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.45616775862069 ], [ 29.767037721518985, -1.456437241379311 ], [ 29.768654683544302, -1.456437241379311 ], [ 29.768654683544302, -1.45616775862069 ], [ 29.767037721518985, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21808, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.45616775862069 ], [ 29.768654683544302, -1.456437241379311 ], [ 29.76892417721519, -1.456437241379311 ], [ 29.76892417721519, -1.45616775862069 ], [ 29.768654683544302, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21809, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.45616775862069 ], [ 29.76892417721519, -1.456437241379311 ], [ 29.769193670886075, -1.456437241379311 ], [ 29.769193670886075, -1.45616775862069 ], [ 29.76892417721519, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21810, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.45616775862069 ], [ 29.769193670886075, -1.456437241379311 ], [ 29.76946316455696, -1.456437241379311 ], [ 29.76946316455696, -1.45616775862069 ], [ 29.769193670886075, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21811, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.45616775862069 ], [ 29.76946316455696, -1.456437241379311 ], [ 29.769732658227849, -1.456437241379311 ], [ 29.769732658227849, -1.45616775862069 ], [ 29.76946316455696, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21812, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.45616775862069 ], [ 29.769732658227849, -1.456437241379311 ], [ 29.770002151898733, -1.456437241379311 ], [ 29.770002151898733, -1.45616775862069 ], [ 29.769732658227849, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21813, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.45616775862069 ], [ 29.770002151898733, -1.456437241379311 ], [ 29.770271645569618, -1.456437241379311 ], [ 29.770271645569618, -1.45616775862069 ], [ 29.770002151898733, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21814, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.45616775862069 ], [ 29.770271645569618, -1.456437241379311 ], [ 29.770541139240507, -1.456437241379311 ], [ 29.770541139240507, -1.45616775862069 ], [ 29.770271645569618, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21815, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.45616775862069 ], [ 29.770541139240507, -1.456437241379311 ], [ 29.770810632911392, -1.456437241379311 ], [ 29.770810632911392, -1.45616775862069 ], [ 29.770541139240507, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21816, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.45616775862069 ], [ 29.770810632911392, -1.456437241379311 ], [ 29.771080126582277, -1.456437241379311 ], [ 29.771080126582277, -1.45616775862069 ], [ 29.770810632911392, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21817, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.45616775862069 ], [ 29.771080126582277, -1.456437241379311 ], [ 29.771349620253165, -1.456437241379311 ], [ 29.771349620253165, -1.45616775862069 ], [ 29.771080126582277, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21818, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.45616775862069 ], [ 29.771349620253165, -1.456437241379311 ], [ 29.77161911392405, -1.456437241379311 ], [ 29.77161911392405, -1.45616775862069 ], [ 29.771349620253165, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21819, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.45616775862069 ], [ 29.77161911392405, -1.456437241379311 ], [ 29.771888607594935, -1.456437241379311 ], [ 29.771888607594935, -1.45616775862069 ], [ 29.77161911392405, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21820, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.45616775862069 ], [ 29.771888607594935, -1.456437241379311 ], [ 29.772158101265823, -1.456437241379311 ], [ 29.772158101265823, -1.45616775862069 ], [ 29.771888607594935, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21821, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.45616775862069 ], [ 29.772158101265823, -1.456437241379311 ], [ 29.772427594936708, -1.456437241379311 ], [ 29.772427594936708, -1.45616775862069 ], [ 29.772158101265823, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21822, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.45616775862069 ], [ 29.772427594936708, -1.456437241379311 ], [ 29.772697088607593, -1.456437241379311 ], [ 29.772697088607593, -1.45616775862069 ], [ 29.772427594936708, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21823, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.45616775862069 ], [ 29.772697088607593, -1.456437241379311 ], [ 29.772966582278482, -1.456437241379311 ], [ 29.772966582278482, -1.45616775862069 ], [ 29.772697088607593, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21824, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.45616775862069 ], [ 29.772966582278482, -1.456437241379311 ], [ 29.773236075949367, -1.456437241379311 ], [ 29.773236075949367, -1.45616775862069 ], [ 29.772966582278482, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21825, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.45616775862069 ], [ 29.773236075949367, -1.456437241379311 ], [ 29.773505569620252, -1.456437241379311 ], [ 29.773505569620252, -1.45616775862069 ], [ 29.773236075949367, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21826, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.45616775862069 ], [ 29.773505569620252, -1.456437241379311 ], [ 29.77377506329114, -1.456437241379311 ], [ 29.77377506329114, -1.45616775862069 ], [ 29.773505569620252, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21827, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.45616775862069 ], [ 29.77377506329114, -1.456437241379311 ], [ 29.774044556962025, -1.456437241379311 ], [ 29.774044556962025, -1.45616775862069 ], [ 29.77377506329114, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21828, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.45616775862069 ], [ 29.774044556962025, -1.456437241379311 ], [ 29.77431405063291, -1.456437241379311 ], [ 29.77431405063291, -1.45616775862069 ], [ 29.774044556962025, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21829, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.45616775862069 ], [ 29.77431405063291, -1.456437241379311 ], [ 29.774853037974683, -1.456437241379311 ], [ 29.774853037974683, -1.45616775862069 ], [ 29.77431405063291, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21830, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.45616775862069 ], [ 29.774853037974683, -1.456437241379311 ], [ 29.775122531645568, -1.456437241379311 ], [ 29.775122531645568, -1.45616775862069 ], [ 29.774853037974683, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21831, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.45616775862069 ], [ 29.775122531645568, -1.456437241379311 ], [ 29.775392025316457, -1.456437241379311 ], [ 29.775392025316457, -1.45616775862069 ], [ 29.775122531645568, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21832, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.45616775862069 ], [ 29.775392025316457, -1.456437241379311 ], [ 29.775661518987341, -1.456437241379311 ], [ 29.775661518987341, -1.45616775862069 ], [ 29.775392025316457, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21833, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.45616775862069 ], [ 29.775661518987341, -1.456437241379311 ], [ 29.775931012658226, -1.456437241379311 ], [ 29.775931012658226, -1.45616775862069 ], [ 29.775661518987341, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21834, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.45616775862069 ], [ 29.775931012658226, -1.456437241379311 ], [ 29.776200506329115, -1.456437241379311 ], [ 29.776200506329115, -1.45616775862069 ], [ 29.775931012658226, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21835, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.45616775862069 ], [ 29.776200506329115, -1.456437241379311 ], [ 29.77647, -1.456437241379311 ], [ 29.77647, -1.45616775862069 ], [ 29.776200506329115, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21836, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.45616775862069 ], [ 29.77647, -1.456437241379311 ], [ 29.776739493670885, -1.456437241379311 ], [ 29.776739493670885, -1.45616775862069 ], [ 29.77647, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21837, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.45616775862069 ], [ 29.776739493670885, -1.456437241379311 ], [ 29.777008987341773, -1.456437241379311 ], [ 29.777008987341773, -1.45616775862069 ], [ 29.776739493670885, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21838, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.45616775862069 ], [ 29.777008987341773, -1.456437241379311 ], [ 29.777278481012658, -1.456437241379311 ], [ 29.777278481012658, -1.45616775862069 ], [ 29.777008987341773, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21839, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.45616775862069 ], [ 29.777547974683543, -1.456437241379311 ], [ 29.777817468354431, -1.456437241379311 ], [ 29.777817468354431, -1.45616775862069 ], [ 29.777547974683543, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21840, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.45616775862069 ], [ 29.777817468354431, -1.456437241379311 ], [ 29.778086962025316, -1.456437241379311 ], [ 29.778086962025316, -1.45616775862069 ], [ 29.777817468354431, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21841, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.45616775862069 ], [ 29.778086962025316, -1.456437241379311 ], [ 29.778356455696201, -1.456437241379311 ], [ 29.778356455696201, -1.45616775862069 ], [ 29.778086962025316, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21842, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.45616775862069 ], [ 29.778356455696201, -1.456706724137931 ], [ 29.77862594936709, -1.456706724137931 ], [ 29.77862594936709, -1.45616775862069 ], [ 29.778356455696201, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21843, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.45616775862069 ], [ 29.77862594936709, -1.456437241379311 ], [ 29.778895443037975, -1.456437241379311 ], [ 29.778895443037975, -1.45616775862069 ], [ 29.77862594936709, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21844, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.45616775862069 ], [ 29.778895443037975, -1.456437241379311 ], [ 29.77916493670886, -1.456437241379311 ], [ 29.77916493670886, -1.45616775862069 ], [ 29.778895443037975, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21845, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.45616775862069 ], [ 29.779434430379748, -1.456437241379311 ], [ 29.779703924050633, -1.456437241379311 ], [ 29.779703924050633, -1.45616775862069 ], [ 29.779434430379748, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21846, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.45616775862069 ], [ 29.779703924050633, -1.456437241379311 ], [ 29.779973417721518, -1.456437241379311 ], [ 29.779973417721518, -1.45616775862069 ], [ 29.779703924050633, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21847, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.45616775862069 ], [ 29.779973417721518, -1.456706724137931 ], [ 29.780242911392406, -1.456706724137931 ], [ 29.780242911392406, -1.45616775862069 ], [ 29.779973417721518, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21848, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.45616775862069 ], [ 29.780242911392406, -1.456437241379311 ], [ 29.780512405063291, -1.456437241379311 ], [ 29.780512405063291, -1.45616775862069 ], [ 29.780242911392406, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21849, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.45616775862069 ], [ 29.780512405063291, -1.456437241379311 ], [ 29.780781898734176, -1.456437241379311 ], [ 29.780781898734176, -1.45616775862069 ], [ 29.780512405063291, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21850, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.45616775862069 ], [ 29.780781898734176, -1.456437241379311 ], [ 29.781051392405065, -1.456437241379311 ], [ 29.781051392405065, -1.45616775862069 ], [ 29.780781898734176, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21851, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.45616775862069 ], [ 29.78132088607595, -1.456437241379311 ], [ 29.781590379746834, -1.456437241379311 ], [ 29.781590379746834, -1.45616775862069 ], [ 29.78132088607595, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21852, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.45616775862069 ], [ 29.781590379746834, -1.456437241379311 ], [ 29.781859873417723, -1.456437241379311 ], [ 29.781859873417723, -1.45616775862069 ], [ 29.781590379746834, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21853, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.45616775862069 ], [ 29.781859873417723, -1.456437241379311 ], [ 29.782129367088608, -1.456437241379311 ], [ 29.782129367088608, -1.45616775862069 ], [ 29.781859873417723, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21854, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.45616775862069 ], [ 29.782129367088608, -1.456437241379311 ], [ 29.782398860759493, -1.456437241379311 ], [ 29.782398860759493, -1.45616775862069 ], [ 29.782129367088608, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21855, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.45616775862069 ], [ 29.782398860759493, -1.456437241379311 ], [ 29.782668354430381, -1.456437241379311 ], [ 29.782668354430381, -1.45616775862069 ], [ 29.782398860759493, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21856, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.45616775862069 ], [ 29.782668354430381, -1.456437241379311 ], [ 29.782937848101266, -1.456437241379311 ], [ 29.782937848101266, -1.45616775862069 ], [ 29.782668354430381, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21857, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.45616775862069 ], [ 29.782937848101266, -1.456437241379311 ], [ 29.783207341772151, -1.456437241379311 ], [ 29.783207341772151, -1.45616775862069 ], [ 29.782937848101266, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21858, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.45616775862069 ], [ 29.783207341772151, -1.456437241379311 ], [ 29.783476835443039, -1.456437241379311 ], [ 29.783476835443039, -1.45616775862069 ], [ 29.783207341772151, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21859, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.45616775862069 ], [ 29.783476835443039, -1.456706724137931 ], [ 29.784015822784809, -1.456706724137931 ], [ 29.784015822784809, -1.45616775862069 ], [ 29.783476835443039, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21860, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.45616775862069 ], [ 29.784015822784809, -1.456437241379311 ], [ 29.784285316455698, -1.456437241379311 ], [ 29.784285316455698, -1.45616775862069 ], [ 29.784015822784809, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21861, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.45616775862069 ], [ 29.784285316455698, -1.456437241379311 ], [ 29.784554810126583, -1.456437241379311 ], [ 29.784554810126583, -1.45616775862069 ], [ 29.784285316455698, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21862, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.45616775862069 ], [ 29.785093797468356, -1.456437241379311 ], [ 29.785363291139241, -1.456437241379311 ], [ 29.785363291139241, -1.45616775862069 ], [ 29.785093797468356, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21863, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.45616775862069 ], [ 29.791292151898734, -1.456437241379311 ], [ 29.791561645569619, -1.456437241379311 ], [ 29.791561645569619, -1.45616775862069 ], [ 29.791292151898734, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21864, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.45616775862069 ], [ 29.791561645569619, -1.456437241379311 ], [ 29.791831139240507, -1.456437241379311 ], [ 29.791831139240507, -1.45616775862069 ], [ 29.791561645569619, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21865, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.45616775862069 ], [ 29.791831139240507, -1.456437241379311 ], [ 29.792100632911392, -1.456437241379311 ], [ 29.792100632911392, -1.45616775862069 ], [ 29.791831139240507, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21866, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.45616775862069 ], [ 29.792100632911392, -1.456437241379311 ], [ 29.792370126582277, -1.456437241379311 ], [ 29.792370126582277, -1.45616775862069 ], [ 29.792100632911392, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21867, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.45616775862069 ], [ 29.792370126582277, -1.456437241379311 ], [ 29.792909113924051, -1.456437241379311 ], [ 29.792909113924051, -1.45616775862069 ], [ 29.792370126582277, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21868, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.45616775862069 ], [ 29.792909113924051, -1.456437241379311 ], [ 29.793178607594935, -1.456437241379311 ], [ 29.793178607594935, -1.45616775862069 ], [ 29.792909113924051, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21869, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.45616775862069 ], [ 29.793178607594935, -1.456437241379311 ], [ 29.793448101265824, -1.456437241379311 ], [ 29.793448101265824, -1.45616775862069 ], [ 29.793178607594935, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21870, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.45616775862069 ], [ 29.793448101265824, -1.456437241379311 ], [ 29.793717594936709, -1.456437241379311 ], [ 29.793717594936709, -1.45616775862069 ], [ 29.793448101265824, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21871, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.45616775862069 ], [ 29.793717594936709, -1.456437241379311 ], [ 29.793987088607594, -1.456437241379311 ], [ 29.793987088607594, -1.45616775862069 ], [ 29.793717594936709, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21872, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.45616775862069 ], [ 29.793987088607594, -1.456437241379311 ], [ 29.794256582278482, -1.456437241379311 ], [ 29.794256582278482, -1.45616775862069 ], [ 29.793987088607594, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21873, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.45616775862069 ], [ 29.794256582278482, -1.456437241379311 ], [ 29.794526075949367, -1.456437241379311 ], [ 29.794526075949367, -1.45616775862069 ], [ 29.794256582278482, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21874, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.45616775862069 ], [ 29.794526075949367, -1.456437241379311 ], [ 29.794795569620252, -1.456437241379311 ], [ 29.794795569620252, -1.45616775862069 ], [ 29.794526075949367, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21875, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.45616775862069 ], [ 29.794795569620252, -1.456706724137931 ], [ 29.79506506329114, -1.456706724137931 ], [ 29.79506506329114, -1.45616775862069 ], [ 29.794795569620252, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21876, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.45616775862069 ], [ 29.79506506329114, -1.456437241379311 ], [ 29.795334556962025, -1.456437241379311 ], [ 29.795334556962025, -1.45616775862069 ], [ 29.79506506329114, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21877, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.45616775862069 ], [ 29.795334556962025, -1.456437241379311 ], [ 29.79560405063291, -1.456437241379311 ], [ 29.79560405063291, -1.45616775862069 ], [ 29.795334556962025, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21878, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.45616775862069 ], [ 29.79560405063291, -1.456706724137931 ], [ 29.795873544303799, -1.456706724137931 ], [ 29.795873544303799, -1.45616775862069 ], [ 29.79560405063291, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21879, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.45616775862069 ], [ 29.796143037974684, -1.456706724137931 ], [ 29.796412531645569, -1.456706724137931 ], [ 29.796412531645569, -1.45616775862069 ], [ 29.796143037974684, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21880, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.45616775862069 ], [ 29.796951518987342, -1.456706724137931 ], [ 29.797221012658227, -1.456706724137931 ], [ 29.797221012658227, -1.45616775862069 ], [ 29.796951518987342, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21881, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.45616775862069 ], [ 29.797490506329115, -1.456437241379311 ], [ 29.79776, -1.456437241379311 ], [ 29.79776, -1.45616775862069 ], [ 29.797490506329115, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21882, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.45616775862069 ], [ 29.79776, -1.456437241379311 ], [ 29.798029493670885, -1.456437241379311 ], [ 29.798029493670885, -1.45616775862069 ], [ 29.79776, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21883, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.45616775862069 ], [ 29.798298987341774, -1.456437241379311 ], [ 29.798568481012659, -1.456437241379311 ], [ 29.798568481012659, -1.45616775862069 ], [ 29.798298987341774, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21884, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.45616775862069 ], [ 29.798568481012659, -1.456437241379311 ], [ 29.798837974683543, -1.456437241379311 ], [ 29.798837974683543, -1.45616775862069 ], [ 29.798568481012659, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21885, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.45616775862069 ], [ 29.798837974683543, -1.456437241379311 ], [ 29.799107468354432, -1.456437241379311 ], [ 29.799107468354432, -1.45616775862069 ], [ 29.798837974683543, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21886, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.45616775862069 ], [ 29.799107468354432, -1.456437241379311 ], [ 29.799376962025317, -1.456437241379311 ], [ 29.799376962025317, -1.45616775862069 ], [ 29.799107468354432, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21887, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.45616775862069 ], [ 29.799376962025317, -1.456437241379311 ], [ 29.799646455696202, -1.456437241379311 ], [ 29.799646455696202, -1.45616775862069 ], [ 29.799376962025317, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21888, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.45616775862069 ], [ 29.809078734177216, -1.456706724137931 ], [ 29.809348227848101, -1.456706724137931 ], [ 29.809348227848101, -1.45616775862069 ], [ 29.809078734177216, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21889, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.45616775862069 ], [ 29.809348227848101, -1.456706724137931 ], [ 29.809617721518986, -1.456706724137931 ], [ 29.809617721518986, -1.45616775862069 ], [ 29.809348227848101, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21890, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.45616775862069 ], [ 29.809617721518986, -1.456706724137931 ], [ 29.809887215189875, -1.456706724137931 ], [ 29.809887215189875, -1.45616775862069 ], [ 29.809617721518986, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21891, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.45616775862069 ], [ 29.810426202531644, -1.456706724137931 ], [ 29.810965189873418, -1.456706724137931 ], [ 29.810965189873418, -1.45616775862069 ], [ 29.810426202531644, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21892, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.45616775862069 ], [ 29.811234683544303, -1.456437241379311 ], [ 29.811504177215191, -1.456437241379311 ], [ 29.811504177215191, -1.45616775862069 ], [ 29.811234683544303, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21893, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.45616775862069 ], [ 29.811504177215191, -1.456437241379311 ], [ 29.812043164556961, -1.456437241379311 ], [ 29.812043164556961, -1.45616775862069 ], [ 29.811504177215191, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21894, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.45616775862069 ], [ 29.812043164556961, -1.456437241379311 ], [ 29.812312658227849, -1.456437241379311 ], [ 29.812312658227849, -1.45616775862069 ], [ 29.812043164556961, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21895, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.45616775862069 ], [ 29.812312658227849, -1.456437241379311 ], [ 29.812582151898734, -1.456437241379311 ], [ 29.812582151898734, -1.45616775862069 ], [ 29.812312658227849, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21896, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.45616775862069 ], [ 29.812582151898734, -1.456437241379311 ], [ 29.812851645569619, -1.456437241379311 ], [ 29.812851645569619, -1.45616775862069 ], [ 29.812582151898734, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21897, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.45616775862069 ], [ 29.812851645569619, -1.456437241379311 ], [ 29.813121139240508, -1.456437241379311 ], [ 29.813121139240508, -1.45616775862069 ], [ 29.812851645569619, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21898, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.45616775862069 ], [ 29.813121139240508, -1.456437241379311 ], [ 29.813390632911393, -1.456437241379311 ], [ 29.813390632911393, -1.45616775862069 ], [ 29.813121139240508, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21899, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.45616775862069 ], [ 29.813390632911393, -1.456437241379311 ], [ 29.813660126582278, -1.456437241379311 ], [ 29.813660126582278, -1.45616775862069 ], [ 29.813390632911393, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21900, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.45616775862069 ], [ 29.813660126582278, -1.456437241379311 ], [ 29.813929620253166, -1.456437241379311 ], [ 29.813929620253166, -1.45616775862069 ], [ 29.813660126582278, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21901, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.45616775862069 ], [ 29.813929620253166, -1.456437241379311 ], [ 29.814199113924051, -1.456437241379311 ], [ 29.814199113924051, -1.45616775862069 ], [ 29.813929620253166, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21902, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.45616775862069 ], [ 29.814199113924051, -1.456706724137931 ], [ 29.814468607594936, -1.456706724137931 ], [ 29.814468607594936, -1.45616775862069 ], [ 29.814199113924051, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21903, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.456437241379311 ], [ 29.751137594936708, -1.456706724137931 ], [ 29.751407088607593, -1.456706724137931 ], [ 29.751407088607593, -1.456437241379311 ], [ 29.751137594936708, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21904, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.456437241379311 ], [ 29.751407088607593, -1.456706724137931 ], [ 29.751676582278481, -1.456706724137931 ], [ 29.751676582278481, -1.456437241379311 ], [ 29.751407088607593, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21905, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.456437241379311 ], [ 29.751676582278481, -1.456706724137931 ], [ 29.751946075949366, -1.456706724137931 ], [ 29.751946075949366, -1.456437241379311 ], [ 29.751676582278481, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21906, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.456437241379311 ], [ 29.751946075949366, -1.456706724137931 ], [ 29.752215569620251, -1.456706724137931 ], [ 29.752215569620251, -1.456437241379311 ], [ 29.751946075949366, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21907, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.456437241379311 ], [ 29.752215569620251, -1.456706724137931 ], [ 29.75248506329114, -1.456706724137931 ], [ 29.75248506329114, -1.456437241379311 ], [ 29.752215569620251, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21908, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.456437241379311 ], [ 29.75248506329114, -1.456706724137931 ], [ 29.752754556962024, -1.456706724137931 ], [ 29.752754556962024, -1.456437241379311 ], [ 29.75248506329114, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21909, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.456437241379311 ], [ 29.752754556962024, -1.456706724137931 ], [ 29.753024050632909, -1.456706724137931 ], [ 29.753024050632909, -1.456437241379311 ], [ 29.752754556962024, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21910, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.456437241379311 ], [ 29.753024050632909, -1.456706724137931 ], [ 29.753293544303798, -1.456706724137931 ], [ 29.753293544303798, -1.456437241379311 ], [ 29.753024050632909, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21911, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.456437241379311 ], [ 29.753293544303798, -1.456706724137931 ], [ 29.753563037974683, -1.456706724137931 ], [ 29.753563037974683, -1.456437241379311 ], [ 29.753293544303798, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21912, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.456437241379311 ], [ 29.753563037974683, -1.456706724137931 ], [ 29.753832531645568, -1.456706724137931 ], [ 29.753832531645568, -1.456437241379311 ], [ 29.753563037974683, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21913, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.456437241379311 ], [ 29.753832531645568, -1.456706724137931 ], [ 29.754102025316456, -1.456706724137931 ], [ 29.754102025316456, -1.456437241379311 ], [ 29.753832531645568, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21914, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.456437241379311 ], [ 29.754102025316456, -1.456706724137931 ], [ 29.754371518987341, -1.456706724137931 ], [ 29.754371518987341, -1.456437241379311 ], [ 29.754102025316456, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21915, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.456437241379311 ], [ 29.754371518987341, -1.456706724137931 ], [ 29.754641012658226, -1.456706724137931 ], [ 29.754641012658226, -1.456437241379311 ], [ 29.754371518987341, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21916, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.456437241379311 ], [ 29.754641012658226, -1.456706724137931 ], [ 29.754910506329114, -1.456706724137931 ], [ 29.754910506329114, -1.456437241379311 ], [ 29.754641012658226, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21917, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.456437241379311 ], [ 29.754910506329114, -1.456706724137931 ], [ 29.75518, -1.456706724137931 ], [ 29.75518, -1.456437241379311 ], [ 29.754910506329114, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21918, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.456437241379311 ], [ 29.75518, -1.456706724137931 ], [ 29.755449493670884, -1.456706724137931 ], [ 29.755449493670884, -1.456437241379311 ], [ 29.75518, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21919, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.456437241379311 ], [ 29.755449493670884, -1.456706724137931 ], [ 29.755718987341773, -1.456706724137931 ], [ 29.755718987341773, -1.456437241379311 ], [ 29.755449493670884, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21920, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.456437241379311 ], [ 29.755718987341773, -1.456706724137931 ], [ 29.755988481012658, -1.456706724137931 ], [ 29.755988481012658, -1.456437241379311 ], [ 29.755718987341773, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21921, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.456437241379311 ], [ 29.755988481012658, -1.456706724137931 ], [ 29.756257974683542, -1.456706724137931 ], [ 29.756257974683542, -1.456437241379311 ], [ 29.755988481012658, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21922, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.456437241379311 ], [ 29.756257974683542, -1.456706724137931 ], [ 29.756796962025316, -1.456706724137931 ], [ 29.756796962025316, -1.456437241379311 ], [ 29.756257974683542, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21923, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.456437241379311 ], [ 29.756796962025316, -1.456706724137931 ], [ 29.757066455696201, -1.456706724137931 ], [ 29.757066455696201, -1.456437241379311 ], [ 29.756796962025316, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21924, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.456437241379311 ], [ 29.757066455696201, -1.456706724137931 ], [ 29.757335949367089, -1.456706724137931 ], [ 29.757335949367089, -1.456437241379311 ], [ 29.757066455696201, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21925, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.456437241379311 ], [ 29.757605443037974, -1.456706724137931 ], [ 29.757874936708859, -1.456706724137931 ], [ 29.757874936708859, -1.456437241379311 ], [ 29.757605443037974, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21926, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.456437241379311 ], [ 29.758144430379748, -1.456706724137931 ], [ 29.758413924050632, -1.456706724137931 ], [ 29.758413924050632, -1.456437241379311 ], [ 29.758144430379748, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21927, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.456437241379311 ], [ 29.758413924050632, -1.456706724137931 ], [ 29.758683417721517, -1.456706724137931 ], [ 29.758683417721517, -1.456437241379311 ], [ 29.758413924050632, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21928, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.456437241379311 ], [ 29.758683417721517, -1.456706724137931 ], [ 29.758952911392406, -1.456706724137931 ], [ 29.758952911392406, -1.456437241379311 ], [ 29.758683417721517, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21929, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.456437241379311 ], [ 29.758952911392406, -1.456706724137931 ], [ 29.759222405063291, -1.456706724137931 ], [ 29.759222405063291, -1.456437241379311 ], [ 29.758952911392406, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21930, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.456437241379311 ], [ 29.759222405063291, -1.456706724137931 ], [ 29.759491898734176, -1.456706724137931 ], [ 29.759491898734176, -1.456437241379311 ], [ 29.759222405063291, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21931, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.456437241379311 ], [ 29.759491898734176, -1.456706724137931 ], [ 29.759761392405064, -1.456706724137931 ], [ 29.759761392405064, -1.456437241379311 ], [ 29.759491898734176, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21932, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.456437241379311 ], [ 29.759761392405064, -1.456706724137931 ], [ 29.760030886075949, -1.456706724137931 ], [ 29.760030886075949, -1.456437241379311 ], [ 29.759761392405064, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21933, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.456437241379311 ], [ 29.760030886075949, -1.456706724137931 ], [ 29.760300379746834, -1.456706724137931 ], [ 29.760300379746834, -1.456437241379311 ], [ 29.760030886075949, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21934, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.456437241379311 ], [ 29.760300379746834, -1.456706724137931 ], [ 29.760569873417722, -1.456706724137931 ], [ 29.760569873417722, -1.456437241379311 ], [ 29.760300379746834, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21935, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.456437241379311 ], [ 29.760569873417722, -1.456706724137931 ], [ 29.760839367088607, -1.456706724137931 ], [ 29.760839367088607, -1.456437241379311 ], [ 29.760569873417722, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21936, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.456437241379311 ], [ 29.760839367088607, -1.456706724137931 ], [ 29.761108860759492, -1.456706724137931 ], [ 29.761108860759492, -1.456437241379311 ], [ 29.760839367088607, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21937, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.456437241379311 ], [ 29.761108860759492, -1.456706724137931 ], [ 29.761378354430381, -1.456706724137931 ], [ 29.761378354430381, -1.456437241379311 ], [ 29.761108860759492, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21938, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.456437241379311 ], [ 29.761378354430381, -1.456706724137931 ], [ 29.761647848101266, -1.456706724137931 ], [ 29.761647848101266, -1.456437241379311 ], [ 29.761378354430381, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21939, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.456437241379311 ], [ 29.761647848101266, -1.456706724137931 ], [ 29.761917341772151, -1.456706724137931 ], [ 29.761917341772151, -1.456437241379311 ], [ 29.761647848101266, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21940, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.456437241379311 ], [ 29.761917341772151, -1.456706724137931 ], [ 29.762186835443039, -1.456706724137931 ], [ 29.762186835443039, -1.456437241379311 ], [ 29.761917341772151, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21941, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.456437241379311 ], [ 29.762186835443039, -1.456706724137931 ], [ 29.762456329113924, -1.456706724137931 ], [ 29.762456329113924, -1.456437241379311 ], [ 29.762186835443039, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21942, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.456437241379311 ], [ 29.762456329113924, -1.456706724137931 ], [ 29.763534303797467, -1.456706724137931 ], [ 29.763534303797467, -1.456437241379311 ], [ 29.762456329113924, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21943, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.456437241379311 ], [ 29.763534303797467, -1.456706724137931 ], [ 29.763803797468356, -1.456706724137931 ], [ 29.763803797468356, -1.456437241379311 ], [ 29.763534303797467, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21944, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.456437241379311 ], [ 29.763803797468356, -1.456706724137931 ], [ 29.76407329113924, -1.456706724137931 ], [ 29.76407329113924, -1.456437241379311 ], [ 29.763803797468356, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21945, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.456437241379311 ], [ 29.76407329113924, -1.456706724137931 ], [ 29.764342784810125, -1.456706724137931 ], [ 29.764342784810125, -1.456437241379311 ], [ 29.76407329113924, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21946, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.456437241379311 ], [ 29.764342784810125, -1.456706724137931 ], [ 29.764612278481014, -1.456706724137931 ], [ 29.764612278481014, -1.456437241379311 ], [ 29.764342784810125, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21947, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.456437241379311 ], [ 29.764612278481014, -1.456706724137931 ], [ 29.765151265822784, -1.456706724137931 ], [ 29.765151265822784, -1.456437241379311 ], [ 29.764612278481014, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21948, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.456437241379311 ], [ 29.765151265822784, -1.456706724137931 ], [ 29.765420759493672, -1.456706724137931 ], [ 29.765420759493672, -1.456437241379311 ], [ 29.765151265822784, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21949, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.456437241379311 ], [ 29.765420759493672, -1.456706724137931 ], [ 29.765959746835442, -1.456706724137931 ], [ 29.765959746835442, -1.456437241379311 ], [ 29.765420759493672, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21950, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.456437241379311 ], [ 29.765959746835442, -1.456706724137931 ], [ 29.766498734177215, -1.456706724137931 ], [ 29.766498734177215, -1.456437241379311 ], [ 29.765959746835442, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21951, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.456437241379311 ], [ 29.766498734177215, -1.456706724137931 ], [ 29.7667682278481, -1.456706724137931 ], [ 29.7667682278481, -1.456437241379311 ], [ 29.766498734177215, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21952, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.456437241379311 ], [ 29.7667682278481, -1.456706724137931 ], [ 29.767037721518985, -1.456706724137931 ], [ 29.767037721518985, -1.456437241379311 ], [ 29.7667682278481, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21953, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.456437241379311 ], [ 29.767037721518985, -1.456706724137931 ], [ 29.768654683544302, -1.456706724137931 ], [ 29.768654683544302, -1.456437241379311 ], [ 29.767037721518985, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21954, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.456437241379311 ], [ 29.768654683544302, -1.456706724137931 ], [ 29.76892417721519, -1.456706724137931 ], [ 29.76892417721519, -1.456437241379311 ], [ 29.768654683544302, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21955, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.456437241379311 ], [ 29.76892417721519, -1.456706724137931 ], [ 29.769193670886075, -1.456706724137931 ], [ 29.769193670886075, -1.456437241379311 ], [ 29.76892417721519, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21956, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.456437241379311 ], [ 29.769193670886075, -1.456706724137931 ], [ 29.76946316455696, -1.456706724137931 ], [ 29.76946316455696, -1.456437241379311 ], [ 29.769193670886075, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21957, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.456437241379311 ], [ 29.76946316455696, -1.456706724137931 ], [ 29.769732658227849, -1.456706724137931 ], [ 29.769732658227849, -1.456437241379311 ], [ 29.76946316455696, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21958, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.456437241379311 ], [ 29.769732658227849, -1.456706724137931 ], [ 29.770002151898733, -1.456706724137931 ], [ 29.770002151898733, -1.456437241379311 ], [ 29.769732658227849, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21959, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.456437241379311 ], [ 29.770002151898733, -1.456706724137931 ], [ 29.770271645569618, -1.456706724137931 ], [ 29.770271645569618, -1.456437241379311 ], [ 29.770002151898733, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21960, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.456437241379311 ], [ 29.770271645569618, -1.456706724137931 ], [ 29.770541139240507, -1.456706724137931 ], [ 29.770541139240507, -1.456437241379311 ], [ 29.770271645569618, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21961, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.456437241379311 ], [ 29.770541139240507, -1.456706724137931 ], [ 29.770810632911392, -1.456706724137931 ], [ 29.770810632911392, -1.456437241379311 ], [ 29.770541139240507, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21962, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.456437241379311 ], [ 29.770810632911392, -1.456706724137931 ], [ 29.771080126582277, -1.456706724137931 ], [ 29.771080126582277, -1.456437241379311 ], [ 29.770810632911392, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21963, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.456437241379311 ], [ 29.771080126582277, -1.456706724137931 ], [ 29.771349620253165, -1.456706724137931 ], [ 29.771349620253165, -1.456437241379311 ], [ 29.771080126582277, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21964, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.456437241379311 ], [ 29.771349620253165, -1.456706724137931 ], [ 29.77161911392405, -1.456706724137931 ], [ 29.77161911392405, -1.456437241379311 ], [ 29.771349620253165, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21965, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.456437241379311 ], [ 29.77161911392405, -1.456706724137931 ], [ 29.771888607594935, -1.456706724137931 ], [ 29.771888607594935, -1.456437241379311 ], [ 29.77161911392405, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21966, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.456437241379311 ], [ 29.771888607594935, -1.456706724137931 ], [ 29.772158101265823, -1.456706724137931 ], [ 29.772158101265823, -1.456437241379311 ], [ 29.771888607594935, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21967, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.456437241379311 ], [ 29.772158101265823, -1.456706724137931 ], [ 29.772427594936708, -1.456706724137931 ], [ 29.772427594936708, -1.456437241379311 ], [ 29.772158101265823, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21968, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.456437241379311 ], [ 29.772427594936708, -1.456706724137931 ], [ 29.772697088607593, -1.456706724137931 ], [ 29.772697088607593, -1.456437241379311 ], [ 29.772427594936708, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21969, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.456437241379311 ], [ 29.772697088607593, -1.456706724137931 ], [ 29.772966582278482, -1.456706724137931 ], [ 29.772966582278482, -1.456437241379311 ], [ 29.772697088607593, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21970, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.456437241379311 ], [ 29.772966582278482, -1.456706724137931 ], [ 29.773236075949367, -1.456706724137931 ], [ 29.773236075949367, -1.456437241379311 ], [ 29.772966582278482, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21971, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.456437241379311 ], [ 29.773236075949367, -1.456706724137931 ], [ 29.773505569620252, -1.456706724137931 ], [ 29.773505569620252, -1.456437241379311 ], [ 29.773236075949367, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21972, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.456437241379311 ], [ 29.773505569620252, -1.456706724137931 ], [ 29.77377506329114, -1.456706724137931 ], [ 29.77377506329114, -1.456437241379311 ], [ 29.773505569620252, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21973, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.456437241379311 ], [ 29.77377506329114, -1.456706724137931 ], [ 29.774044556962025, -1.456706724137931 ], [ 29.774044556962025, -1.456437241379311 ], [ 29.77377506329114, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21974, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.456437241379311 ], [ 29.774044556962025, -1.456706724137931 ], [ 29.77431405063291, -1.456706724137931 ], [ 29.77431405063291, -1.456437241379311 ], [ 29.774044556962025, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21975, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.456437241379311 ], [ 29.77431405063291, -1.456706724137931 ], [ 29.774853037974683, -1.456706724137931 ], [ 29.774853037974683, -1.456437241379311 ], [ 29.77431405063291, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21976, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.456437241379311 ], [ 29.774853037974683, -1.456706724137931 ], [ 29.775122531645568, -1.456706724137931 ], [ 29.775122531645568, -1.456437241379311 ], [ 29.774853037974683, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21977, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.456437241379311 ], [ 29.775122531645568, -1.456706724137931 ], [ 29.775392025316457, -1.456706724137931 ], [ 29.775392025316457, -1.456437241379311 ], [ 29.775122531645568, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21978, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.456437241379311 ], [ 29.775392025316457, -1.456706724137931 ], [ 29.775661518987341, -1.456706724137931 ], [ 29.775661518987341, -1.456437241379311 ], [ 29.775392025316457, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21979, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.456437241379311 ], [ 29.775661518987341, -1.456706724137931 ], [ 29.775931012658226, -1.456706724137931 ], [ 29.775931012658226, -1.456437241379311 ], [ 29.775661518987341, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21980, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.456437241379311 ], [ 29.775931012658226, -1.456706724137931 ], [ 29.776200506329115, -1.456706724137931 ], [ 29.776200506329115, -1.456437241379311 ], [ 29.775931012658226, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21981, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.456437241379311 ], [ 29.776200506329115, -1.456706724137931 ], [ 29.77647, -1.456706724137931 ], [ 29.77647, -1.456437241379311 ], [ 29.776200506329115, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21982, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.456437241379311 ], [ 29.77647, -1.456706724137931 ], [ 29.776739493670885, -1.456706724137931 ], [ 29.776739493670885, -1.456437241379311 ], [ 29.77647, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21983, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.456437241379311 ], [ 29.776739493670885, -1.456706724137931 ], [ 29.777008987341773, -1.456706724137931 ], [ 29.777008987341773, -1.456437241379311 ], [ 29.776739493670885, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21984, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.456437241379311 ], [ 29.777008987341773, -1.456706724137931 ], [ 29.777278481012658, -1.456706724137931 ], [ 29.777278481012658, -1.456437241379311 ], [ 29.777008987341773, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21985, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.456437241379311 ], [ 29.777278481012658, -1.456706724137931 ], [ 29.777547974683543, -1.456706724137931 ], [ 29.777547974683543, -1.456437241379311 ], [ 29.777278481012658, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21986, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.456437241379311 ], [ 29.777547974683543, -1.456706724137931 ], [ 29.777817468354431, -1.456706724137931 ], [ 29.777817468354431, -1.456437241379311 ], [ 29.777547974683543, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21987, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.456437241379311 ], [ 29.777817468354431, -1.456706724137931 ], [ 29.778086962025316, -1.456706724137931 ], [ 29.778086962025316, -1.456437241379311 ], [ 29.777817468354431, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21988, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.456437241379311 ], [ 29.778086962025316, -1.456706724137931 ], [ 29.778356455696201, -1.456706724137931 ], [ 29.778356455696201, -1.456437241379311 ], [ 29.778086962025316, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21989, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.456437241379311 ], [ 29.77862594936709, -1.456706724137931 ], [ 29.778895443037975, -1.456706724137931 ], [ 29.778895443037975, -1.456437241379311 ], [ 29.77862594936709, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21990, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.456437241379311 ], [ 29.77916493670886, -1.456706724137931 ], [ 29.779434430379748, -1.456706724137931 ], [ 29.779434430379748, -1.456437241379311 ], [ 29.77916493670886, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21991, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.456437241379311 ], [ 29.779434430379748, -1.456706724137931 ], [ 29.779703924050633, -1.456706724137931 ], [ 29.779703924050633, -1.456437241379311 ], [ 29.779434430379748, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21992, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.456437241379311 ], [ 29.779703924050633, -1.456706724137931 ], [ 29.779973417721518, -1.456706724137931 ], [ 29.779973417721518, -1.456437241379311 ], [ 29.779703924050633, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21993, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.456437241379311 ], [ 29.780242911392406, -1.456706724137931 ], [ 29.780512405063291, -1.456706724137931 ], [ 29.780512405063291, -1.456437241379311 ], [ 29.780242911392406, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21994, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.456437241379311 ], [ 29.780512405063291, -1.456706724137931 ], [ 29.780781898734176, -1.456706724137931 ], [ 29.780781898734176, -1.456437241379311 ], [ 29.780512405063291, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21995, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.456437241379311 ], [ 29.780781898734176, -1.456706724137931 ], [ 29.781051392405065, -1.456706724137931 ], [ 29.781051392405065, -1.456437241379311 ], [ 29.780781898734176, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21996, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.456437241379311 ], [ 29.781051392405065, -1.456706724137931 ], [ 29.78132088607595, -1.456706724137931 ], [ 29.78132088607595, -1.456437241379311 ], [ 29.781051392405065, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21997, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.456437241379311 ], [ 29.78132088607595, -1.456706724137931 ], [ 29.781590379746834, -1.456706724137931 ], [ 29.781590379746834, -1.456437241379311 ], [ 29.78132088607595, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21998, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.456437241379311 ], [ 29.781590379746834, -1.456706724137931 ], [ 29.781859873417723, -1.456706724137931 ], [ 29.781859873417723, -1.456437241379311 ], [ 29.781590379746834, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 21999, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.456437241379311 ], [ 29.781859873417723, -1.456706724137931 ], [ 29.782129367088608, -1.456706724137931 ], [ 29.782129367088608, -1.456437241379311 ], [ 29.781859873417723, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22000, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.456437241379311 ], [ 29.782129367088608, -1.456706724137931 ], [ 29.782398860759493, -1.456706724137931 ], [ 29.782398860759493, -1.456437241379311 ], [ 29.782129367088608, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22001, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.456437241379311 ], [ 29.782668354430381, -1.456706724137931 ], [ 29.782937848101266, -1.456706724137931 ], [ 29.782937848101266, -1.456437241379311 ], [ 29.782668354430381, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22002, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.456437241379311 ], [ 29.791561645569619, -1.456706724137931 ], [ 29.791831139240507, -1.456706724137931 ], [ 29.791831139240507, -1.456437241379311 ], [ 29.791561645569619, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22003, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.456437241379311 ], [ 29.791831139240507, -1.456706724137931 ], [ 29.792100632911392, -1.456706724137931 ], [ 29.792100632911392, -1.456437241379311 ], [ 29.791831139240507, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22004, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.456437241379311 ], [ 29.792100632911392, -1.456706724137931 ], [ 29.792370126582277, -1.456706724137931 ], [ 29.792370126582277, -1.456437241379311 ], [ 29.792100632911392, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22005, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.456437241379311 ], [ 29.792370126582277, -1.456706724137931 ], [ 29.792909113924051, -1.456706724137931 ], [ 29.792909113924051, -1.456437241379311 ], [ 29.792370126582277, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22006, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.456437241379311 ], [ 29.792909113924051, -1.456706724137931 ], [ 29.793178607594935, -1.456706724137931 ], [ 29.793178607594935, -1.456437241379311 ], [ 29.792909113924051, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22007, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.456437241379311 ], [ 29.793178607594935, -1.456706724137931 ], [ 29.793448101265824, -1.456706724137931 ], [ 29.793448101265824, -1.456437241379311 ], [ 29.793178607594935, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22008, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.456437241379311 ], [ 29.793448101265824, -1.456706724137931 ], [ 29.793717594936709, -1.456706724137931 ], [ 29.793717594936709, -1.456437241379311 ], [ 29.793448101265824, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22009, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.456437241379311 ], [ 29.793987088607594, -1.456706724137931 ], [ 29.794256582278482, -1.456706724137931 ], [ 29.794256582278482, -1.456437241379311 ], [ 29.793987088607594, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22010, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.456437241379311 ], [ 29.794256582278482, -1.456706724137931 ], [ 29.794526075949367, -1.456706724137931 ], [ 29.794526075949367, -1.456437241379311 ], [ 29.794256582278482, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22011, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.456437241379311 ], [ 29.796412531645569, -1.456706724137931 ], [ 29.796682025316457, -1.456706724137931 ], [ 29.796682025316457, -1.456437241379311 ], [ 29.796412531645569, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22012, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.456437241379311 ], [ 29.796682025316457, -1.456706724137931 ], [ 29.796951518987342, -1.456706724137931 ], [ 29.796951518987342, -1.456437241379311 ], [ 29.796682025316457, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22013, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.456437241379311 ], [ 29.797221012658227, -1.456706724137931 ], [ 29.797490506329115, -1.456706724137931 ], [ 29.797490506329115, -1.456437241379311 ], [ 29.797221012658227, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22014, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.456437241379311 ], [ 29.797490506329115, -1.456706724137931 ], [ 29.79776, -1.456706724137931 ], [ 29.79776, -1.456437241379311 ], [ 29.797490506329115, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22015, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.456437241379311 ], [ 29.79776, -1.456706724137931 ], [ 29.798029493670885, -1.456706724137931 ], [ 29.798029493670885, -1.456437241379311 ], [ 29.79776, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22016, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.456437241379311 ], [ 29.798029493670885, -1.456706724137931 ], [ 29.798298987341774, -1.456706724137931 ], [ 29.798298987341774, -1.456437241379311 ], [ 29.798029493670885, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22017, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.456437241379311 ], [ 29.798298987341774, -1.456706724137931 ], [ 29.798568481012659, -1.456706724137931 ], [ 29.798568481012659, -1.456437241379311 ], [ 29.798298987341774, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22018, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.456437241379311 ], [ 29.798568481012659, -1.456706724137931 ], [ 29.798837974683543, -1.456706724137931 ], [ 29.798837974683543, -1.456437241379311 ], [ 29.798568481012659, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22019, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.456437241379311 ], [ 29.798837974683543, -1.456706724137931 ], [ 29.799107468354432, -1.456706724137931 ], [ 29.799107468354432, -1.456437241379311 ], [ 29.798837974683543, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22020, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.456437241379311 ], [ 29.799107468354432, -1.456706724137931 ], [ 29.799376962025317, -1.456706724137931 ], [ 29.799376962025317, -1.456437241379311 ], [ 29.799107468354432, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22021, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.456437241379311 ], [ 29.799376962025317, -1.456706724137931 ], [ 29.799646455696202, -1.456706724137931 ], [ 29.799646455696202, -1.456437241379311 ], [ 29.799376962025317, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22022, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.456437241379311 ], [ 29.799646455696202, -1.456706724137931 ], [ 29.79991594936709, -1.456706724137931 ], [ 29.79991594936709, -1.456437241379311 ], [ 29.799646455696202, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22023, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.456437241379311 ], [ 29.810965189873418, -1.456706724137931 ], [ 29.811234683544303, -1.456706724137931 ], [ 29.811234683544303, -1.456437241379311 ], [ 29.810965189873418, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22024, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.456437241379311 ], [ 29.811234683544303, -1.456706724137931 ], [ 29.811504177215191, -1.456706724137931 ], [ 29.811504177215191, -1.456437241379311 ], [ 29.811234683544303, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22025, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.456437241379311 ], [ 29.811504177215191, -1.456706724137931 ], [ 29.811773670886076, -1.456706724137931 ], [ 29.811773670886076, -1.456437241379311 ], [ 29.811504177215191, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22026, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.456437241379311 ], [ 29.811773670886076, -1.456706724137931 ], [ 29.812043164556961, -1.456706724137931 ], [ 29.812043164556961, -1.456437241379311 ], [ 29.811773670886076, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22027, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.456437241379311 ], [ 29.812043164556961, -1.456706724137931 ], [ 29.812312658227849, -1.456706724137931 ], [ 29.812312658227849, -1.456437241379311 ], [ 29.812043164556961, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22028, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.456437241379311 ], [ 29.812312658227849, -1.456706724137931 ], [ 29.812582151898734, -1.456706724137931 ], [ 29.812582151898734, -1.456437241379311 ], [ 29.812312658227849, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22029, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.456437241379311 ], [ 29.812582151898734, -1.456706724137931 ], [ 29.812851645569619, -1.456706724137931 ], [ 29.812851645569619, -1.456437241379311 ], [ 29.812582151898734, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22030, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.456437241379311 ], [ 29.812851645569619, -1.456706724137931 ], [ 29.813121139240508, -1.456706724137931 ], [ 29.813121139240508, -1.456437241379311 ], [ 29.812851645569619, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22031, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.456437241379311 ], [ 29.813121139240508, -1.456706724137931 ], [ 29.813390632911393, -1.456706724137931 ], [ 29.813390632911393, -1.456437241379311 ], [ 29.813121139240508, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22032, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.456437241379311 ], [ 29.813390632911393, -1.456706724137931 ], [ 29.813660126582278, -1.456706724137931 ], [ 29.813660126582278, -1.456437241379311 ], [ 29.813390632911393, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22033, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.456437241379311 ], [ 29.813660126582278, -1.456706724137931 ], [ 29.813929620253166, -1.456706724137931 ], [ 29.813929620253166, -1.456437241379311 ], [ 29.813660126582278, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22034, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.456437241379311 ], [ 29.813929620253166, -1.456706724137931 ], [ 29.814199113924051, -1.456706724137931 ], [ 29.814199113924051, -1.456437241379311 ], [ 29.813929620253166, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22035, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.455089827586207 ], [ 29.788597215189874, -1.458054137931035 ], [ 29.788866708860759, -1.458054137931035 ], [ 29.788866708860759, -1.455089827586207 ], [ 29.788597215189874, -1.455089827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22036, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.455359310344828 ], [ 29.788058227848101, -1.458054137931035 ], [ 29.788327721518986, -1.458054137931035 ], [ 29.788327721518986, -1.455359310344828 ], [ 29.788058227848101, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22037, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.455359310344828 ], [ 29.788327721518986, -1.458054137931035 ], [ 29.788597215189874, -1.458054137931035 ], [ 29.788597215189874, -1.455359310344828 ], [ 29.788327721518986, -1.455359310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22038, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.455628793103448 ], [ 29.787249746835442, -1.458054137931035 ], [ 29.787519240506327, -1.458054137931035 ], [ 29.787519240506327, -1.455628793103448 ], [ 29.787249746835442, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22039, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.455628793103448 ], [ 29.787519240506327, -1.458323620689655 ], [ 29.787788734177216, -1.458323620689655 ], [ 29.787788734177216, -1.455628793103448 ], [ 29.787519240506327, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22040, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.455628793103448 ], [ 29.787788734177216, -1.458323620689655 ], [ 29.788058227848101, -1.458323620689655 ], [ 29.788058227848101, -1.455628793103448 ], [ 29.787788734177216, -1.455628793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22041, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.455898275862069 ], [ 29.785902278481014, -1.458054137931035 ], [ 29.786171772151899, -1.458054137931035 ], [ 29.786171772151899, -1.455898275862069 ], [ 29.785902278481014, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22042, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.455898275862069 ], [ 29.786710759493673, -1.458323620689655 ], [ 29.786980253164558, -1.458323620689655 ], [ 29.786980253164558, -1.455898275862069 ], [ 29.786710759493673, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22043, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.455898275862069 ], [ 29.786980253164558, -1.458323620689655 ], [ 29.787249746835442, -1.458323620689655 ], [ 29.787249746835442, -1.455898275862069 ], [ 29.786980253164558, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22044, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.455898275862069 ], [ 29.809887215189875, -1.457245689655172 ], [ 29.810965189873418, -1.457245689655172 ], [ 29.810965189873418, -1.456976206896552 ], [ 29.81015670886076, -1.456976206896552 ], [ 29.81015670886076, -1.455898275862069 ], [ 29.809887215189875, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22045, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.455898275862069 ], [ 29.81015670886076, -1.456976206896552 ], [ 29.810965189873418, -1.456976206896552 ], [ 29.810965189873418, -1.456706724137931 ], [ 29.810426202531644, -1.456706724137931 ], [ 29.810426202531644, -1.455898275862069 ], [ 29.81015670886076, -1.455898275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22046, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.45616775862069 ], [ 29.784554810126583, -1.458323620689655 ], [ 29.784824303797468, -1.458323620689655 ], [ 29.784824303797468, -1.45616775862069 ], [ 29.784554810126583, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22047, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.45616775862069 ], [ 29.784824303797468, -1.458593103448276 ], [ 29.785093797468356, -1.458593103448276 ], [ 29.785093797468356, -1.45616775862069 ], [ 29.784824303797468, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22048, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.45616775862069 ], [ 29.785363291139241, -1.458323620689655 ], [ 29.785632784810126, -1.458323620689655 ], [ 29.785632784810126, -1.45616775862069 ], [ 29.785363291139241, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22049, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.45616775862069 ], [ 29.785632784810126, -1.458593103448276 ], [ 29.785902278481014, -1.458593103448276 ], [ 29.785902278481014, -1.45616775862069 ], [ 29.785632784810126, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22050, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.45616775862069 ], [ 29.786171772151899, -1.458323620689655 ], [ 29.786441265822784, -1.458323620689655 ], [ 29.786441265822784, -1.45616775862069 ], [ 29.786171772151899, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22051, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.45616775862069 ], [ 29.786441265822784, -1.458593103448276 ], [ 29.786710759493673, -1.458593103448276 ], [ 29.786710759493673, -1.45616775862069 ], [ 29.786441265822784, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22052, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.45616775862069 ], [ 29.795873544303799, -1.457515172413793 ], [ 29.796143037974684, -1.457515172413793 ], [ 29.796143037974684, -1.45616775862069 ], [ 29.795873544303799, -1.45616775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22053, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.456437241379311 ], [ 29.757335949367089, -1.456976206896552 ], [ 29.757605443037974, -1.456976206896552 ], [ 29.757605443037974, -1.456437241379311 ], [ 29.757335949367089, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22054, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.456437241379311 ], [ 29.757874936708859, -1.456976206896552 ], [ 29.758144430379748, -1.456976206896552 ], [ 29.758144430379748, -1.456437241379311 ], [ 29.757874936708859, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22055, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.456437241379311 ], [ 29.778895443037975, -1.456976206896552 ], [ 29.77916493670886, -1.456976206896552 ], [ 29.77916493670886, -1.456437241379311 ], [ 29.778895443037975, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22056, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.456437241379311 ], [ 29.782398860759493, -1.458593103448276 ], [ 29.782668354430381, -1.458593103448276 ], [ 29.782668354430381, -1.456437241379311 ], [ 29.782398860759493, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22057, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.456437241379311 ], [ 29.782937848101266, -1.458323620689655 ], [ 29.783207341772151, -1.458323620689655 ], [ 29.783207341772151, -1.456437241379311 ], [ 29.782937848101266, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22058, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.456437241379311 ], [ 29.783207341772151, -1.458593103448276 ], [ 29.783476835443039, -1.458593103448276 ], [ 29.783476835443039, -1.456437241379311 ], [ 29.783207341772151, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22059, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.456437241379311 ], [ 29.784015822784809, -1.458323620689655 ], [ 29.784285316455698, -1.458323620689655 ], [ 29.784285316455698, -1.456437241379311 ], [ 29.784015822784809, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22060, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.456437241379311 ], [ 29.784285316455698, -1.458593103448276 ], [ 29.784554810126583, -1.458593103448276 ], [ 29.784554810126583, -1.456437241379311 ], [ 29.784285316455698, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22061, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.456437241379311 ], [ 29.785093797468356, -1.458593103448276 ], [ 29.785363291139241, -1.458593103448276 ], [ 29.785363291139241, -1.456437241379311 ], [ 29.785093797468356, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22062, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.456437241379311 ], [ 29.793717594936709, -1.456976206896552 ], [ 29.793987088607594, -1.456976206896552 ], [ 29.793987088607594, -1.456437241379311 ], [ 29.793717594936709, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22063, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.456437241379311 ], [ 29.794526075949367, -1.458054137931035 ], [ 29.794795569620252, -1.458054137931035 ], [ 29.794795569620252, -1.456437241379311 ], [ 29.794526075949367, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22064, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.456437241379311 ], [ 29.79506506329114, -1.456976206896552 ], [ 29.795334556962025, -1.456976206896552 ], [ 29.795334556962025, -1.456437241379311 ], [ 29.79506506329114, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22065, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.456437241379311 ], [ 29.795334556962025, -1.457784655172414 ], [ 29.79560405063291, -1.457784655172414 ], [ 29.79560405063291, -1.456437241379311 ], [ 29.795334556962025, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22066, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.456437241379311 ], [ 29.79991594936709, -1.457245689655172 ], [ 29.800185443037975, -1.457245689655172 ], [ 29.800185443037975, -1.456437241379311 ], [ 29.79991594936709, -1.456437241379311 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22067, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751137594936708, -1.456706724137931 ], [ 29.751137594936708, -1.457245689655172 ], [ 29.751407088607593, -1.457245689655172 ], [ 29.751407088607593, -1.456706724137931 ], [ 29.751137594936708, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22068, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.456706724137931 ], [ 29.751407088607593, -1.456976206896552 ], [ 29.751676582278481, -1.456976206896552 ], [ 29.751676582278481, -1.456706724137931 ], [ 29.751407088607593, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22069, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.456706724137931 ], [ 29.751676582278481, -1.456976206896552 ], [ 29.751946075949366, -1.456976206896552 ], [ 29.751946075949366, -1.456706724137931 ], [ 29.751676582278481, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22070, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.456706724137931 ], [ 29.751946075949366, -1.456976206896552 ], [ 29.752215569620251, -1.456976206896552 ], [ 29.752215569620251, -1.456706724137931 ], [ 29.751946075949366, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22071, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.456706724137931 ], [ 29.752215569620251, -1.456976206896552 ], [ 29.75248506329114, -1.456976206896552 ], [ 29.75248506329114, -1.456706724137931 ], [ 29.752215569620251, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22072, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.456706724137931 ], [ 29.75248506329114, -1.456976206896552 ], [ 29.752754556962024, -1.456976206896552 ], [ 29.752754556962024, -1.456706724137931 ], [ 29.75248506329114, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22073, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.456706724137931 ], [ 29.752754556962024, -1.456976206896552 ], [ 29.753024050632909, -1.456976206896552 ], [ 29.753024050632909, -1.456706724137931 ], [ 29.752754556962024, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22074, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.456706724137931 ], [ 29.753024050632909, -1.456976206896552 ], [ 29.753293544303798, -1.456976206896552 ], [ 29.753293544303798, -1.456706724137931 ], [ 29.753024050632909, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22075, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.456706724137931 ], [ 29.753293544303798, -1.456976206896552 ], [ 29.753563037974683, -1.456976206896552 ], [ 29.753563037974683, -1.456706724137931 ], [ 29.753293544303798, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22076, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.456706724137931 ], [ 29.753563037974683, -1.456976206896552 ], [ 29.753832531645568, -1.456976206896552 ], [ 29.753832531645568, -1.456706724137931 ], [ 29.753563037974683, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22077, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.456706724137931 ], [ 29.753832531645568, -1.456976206896552 ], [ 29.754102025316456, -1.456976206896552 ], [ 29.754102025316456, -1.456706724137931 ], [ 29.753832531645568, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22078, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.456706724137931 ], [ 29.754102025316456, -1.456976206896552 ], [ 29.754371518987341, -1.456976206896552 ], [ 29.754371518987341, -1.456706724137931 ], [ 29.754102025316456, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22079, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.456706724137931 ], [ 29.754371518987341, -1.456976206896552 ], [ 29.754641012658226, -1.456976206896552 ], [ 29.754641012658226, -1.456706724137931 ], [ 29.754371518987341, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22080, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.456706724137931 ], [ 29.754641012658226, -1.456976206896552 ], [ 29.754910506329114, -1.456976206896552 ], [ 29.754910506329114, -1.456706724137931 ], [ 29.754641012658226, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22081, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.456706724137931 ], [ 29.754910506329114, -1.456976206896552 ], [ 29.75518, -1.456976206896552 ], [ 29.75518, -1.456706724137931 ], [ 29.754910506329114, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22082, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.456706724137931 ], [ 29.75518, -1.456976206896552 ], [ 29.755449493670884, -1.456976206896552 ], [ 29.755449493670884, -1.456706724137931 ], [ 29.75518, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22083, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.456706724137931 ], [ 29.755449493670884, -1.456976206896552 ], [ 29.755718987341773, -1.456976206896552 ], [ 29.755718987341773, -1.456706724137931 ], [ 29.755449493670884, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22084, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.456706724137931 ], [ 29.755718987341773, -1.456976206896552 ], [ 29.755988481012658, -1.456976206896552 ], [ 29.755988481012658, -1.456706724137931 ], [ 29.755718987341773, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22085, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.456706724137931 ], [ 29.755988481012658, -1.456976206896552 ], [ 29.756257974683542, -1.456976206896552 ], [ 29.756257974683542, -1.456706724137931 ], [ 29.755988481012658, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22086, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.456706724137931 ], [ 29.756257974683542, -1.456976206896552 ], [ 29.756796962025316, -1.456976206896552 ], [ 29.756796962025316, -1.456706724137931 ], [ 29.756257974683542, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22087, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.456706724137931 ], [ 29.756796962025316, -1.456976206896552 ], [ 29.757066455696201, -1.456976206896552 ], [ 29.757066455696201, -1.456706724137931 ], [ 29.756796962025316, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22088, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.456706724137931 ], [ 29.757066455696201, -1.456976206896552 ], [ 29.757335949367089, -1.456976206896552 ], [ 29.757335949367089, -1.456706724137931 ], [ 29.757066455696201, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22089, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.456706724137931 ], [ 29.757605443037974, -1.456976206896552 ], [ 29.757874936708859, -1.456976206896552 ], [ 29.757874936708859, -1.456706724137931 ], [ 29.757605443037974, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22090, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.456706724137931 ], [ 29.758144430379748, -1.456976206896552 ], [ 29.758413924050632, -1.456976206896552 ], [ 29.758413924050632, -1.456706724137931 ], [ 29.758144430379748, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22091, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.456706724137931 ], [ 29.758413924050632, -1.456976206896552 ], [ 29.758683417721517, -1.456976206896552 ], [ 29.758683417721517, -1.456706724137931 ], [ 29.758413924050632, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22092, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.456706724137931 ], [ 29.758683417721517, -1.456976206896552 ], [ 29.758952911392406, -1.456976206896552 ], [ 29.758952911392406, -1.456706724137931 ], [ 29.758683417721517, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22093, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.456706724137931 ], [ 29.758952911392406, -1.456976206896552 ], [ 29.759222405063291, -1.456976206896552 ], [ 29.759222405063291, -1.456706724137931 ], [ 29.758952911392406, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22094, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.456706724137931 ], [ 29.759222405063291, -1.456976206896552 ], [ 29.759491898734176, -1.456976206896552 ], [ 29.759491898734176, -1.456706724137931 ], [ 29.759222405063291, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22095, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.456706724137931 ], [ 29.759491898734176, -1.456976206896552 ], [ 29.759761392405064, -1.456976206896552 ], [ 29.759761392405064, -1.456706724137931 ], [ 29.759491898734176, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22096, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.456706724137931 ], [ 29.759761392405064, -1.456976206896552 ], [ 29.760030886075949, -1.456976206896552 ], [ 29.760030886075949, -1.456706724137931 ], [ 29.759761392405064, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22097, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.456706724137931 ], [ 29.760030886075949, -1.456976206896552 ], [ 29.760300379746834, -1.456976206896552 ], [ 29.760300379746834, -1.456706724137931 ], [ 29.760030886075949, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22098, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.456706724137931 ], [ 29.760300379746834, -1.456976206896552 ], [ 29.760569873417722, -1.456976206896552 ], [ 29.760569873417722, -1.456706724137931 ], [ 29.760300379746834, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22099, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.456706724137931 ], [ 29.760569873417722, -1.456976206896552 ], [ 29.760839367088607, -1.456976206896552 ], [ 29.760839367088607, -1.456706724137931 ], [ 29.760569873417722, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22100, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.456706724137931 ], [ 29.760839367088607, -1.456976206896552 ], [ 29.761108860759492, -1.456976206896552 ], [ 29.761108860759492, -1.456706724137931 ], [ 29.760839367088607, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22101, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.456706724137931 ], [ 29.761108860759492, -1.456976206896552 ], [ 29.761378354430381, -1.456976206896552 ], [ 29.761378354430381, -1.456706724137931 ], [ 29.761108860759492, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22102, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.456706724137931 ], [ 29.761378354430381, -1.456976206896552 ], [ 29.761647848101266, -1.456976206896552 ], [ 29.761647848101266, -1.456706724137931 ], [ 29.761378354430381, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22103, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.456706724137931 ], [ 29.761647848101266, -1.456976206896552 ], [ 29.761917341772151, -1.456976206896552 ], [ 29.761917341772151, -1.456706724137931 ], [ 29.761647848101266, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22104, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.456706724137931 ], [ 29.761917341772151, -1.456976206896552 ], [ 29.762186835443039, -1.456976206896552 ], [ 29.762186835443039, -1.456706724137931 ], [ 29.761917341772151, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22105, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.456706724137931 ], [ 29.762186835443039, -1.456976206896552 ], [ 29.762725822784809, -1.456976206896552 ], [ 29.762725822784809, -1.456706724137931 ], [ 29.762186835443039, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22106, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.456706724137931 ], [ 29.762725822784809, -1.456976206896552 ], [ 29.763264810126582, -1.456976206896552 ], [ 29.763264810126582, -1.456706724137931 ], [ 29.762725822784809, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22107, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.456706724137931 ], [ 29.763264810126582, -1.456976206896552 ], [ 29.763803797468356, -1.456976206896552 ], [ 29.763803797468356, -1.456706724137931 ], [ 29.763264810126582, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22108, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.456706724137931 ], [ 29.763803797468356, -1.456976206896552 ], [ 29.76407329113924, -1.456976206896552 ], [ 29.76407329113924, -1.456706724137931 ], [ 29.763803797468356, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22109, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.456706724137931 ], [ 29.76407329113924, -1.456976206896552 ], [ 29.764342784810125, -1.456976206896552 ], [ 29.764342784810125, -1.456706724137931 ], [ 29.76407329113924, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22110, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.456706724137931 ], [ 29.764342784810125, -1.456976206896552 ], [ 29.764612278481014, -1.456976206896552 ], [ 29.764612278481014, -1.456706724137931 ], [ 29.764342784810125, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22111, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.456706724137931 ], [ 29.764612278481014, -1.456976206896552 ], [ 29.765151265822784, -1.456976206896552 ], [ 29.765151265822784, -1.456706724137931 ], [ 29.764612278481014, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22112, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.456706724137931 ], [ 29.765151265822784, -1.456976206896552 ], [ 29.765420759493672, -1.456976206896552 ], [ 29.765420759493672, -1.456706724137931 ], [ 29.765151265822784, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22113, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.456706724137931 ], [ 29.765420759493672, -1.456976206896552 ], [ 29.765959746835442, -1.456976206896552 ], [ 29.765959746835442, -1.456706724137931 ], [ 29.765420759493672, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22114, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.456706724137931 ], [ 29.765959746835442, -1.456976206896552 ], [ 29.766229240506327, -1.456976206896552 ], [ 29.766229240506327, -1.456706724137931 ], [ 29.765959746835442, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22115, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.456706724137931 ], [ 29.766229240506327, -1.456976206896552 ], [ 29.766498734177215, -1.456976206896552 ], [ 29.766498734177215, -1.456706724137931 ], [ 29.766229240506327, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22116, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.456706724137931 ], [ 29.766498734177215, -1.456976206896552 ], [ 29.7667682278481, -1.456976206896552 ], [ 29.7667682278481, -1.456706724137931 ], [ 29.766498734177215, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22117, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.456706724137931 ], [ 29.7667682278481, -1.456976206896552 ], [ 29.767307215189874, -1.456976206896552 ], [ 29.767307215189874, -1.456706724137931 ], [ 29.7667682278481, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22118, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.456706724137931 ], [ 29.767307215189874, -1.456976206896552 ], [ 29.768385189873417, -1.456976206896552 ], [ 29.768385189873417, -1.456706724137931 ], [ 29.767307215189874, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22119, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.456706724137931 ], [ 29.768385189873417, -1.456976206896552 ], [ 29.76892417721519, -1.456976206896552 ], [ 29.76892417721519, -1.456706724137931 ], [ 29.768385189873417, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22120, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.456706724137931 ], [ 29.76892417721519, -1.456976206896552 ], [ 29.769193670886075, -1.456976206896552 ], [ 29.769193670886075, -1.456706724137931 ], [ 29.76892417721519, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22121, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.456706724137931 ], [ 29.769193670886075, -1.456976206896552 ], [ 29.76946316455696, -1.456976206896552 ], [ 29.76946316455696, -1.456706724137931 ], [ 29.769193670886075, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22122, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.456706724137931 ], [ 29.76946316455696, -1.456976206896552 ], [ 29.769732658227849, -1.456976206896552 ], [ 29.769732658227849, -1.456706724137931 ], [ 29.76946316455696, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22123, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.456706724137931 ], [ 29.769732658227849, -1.456976206896552 ], [ 29.770002151898733, -1.456976206896552 ], [ 29.770002151898733, -1.456706724137931 ], [ 29.769732658227849, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22124, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.456706724137931 ], [ 29.770002151898733, -1.456976206896552 ], [ 29.770271645569618, -1.456976206896552 ], [ 29.770271645569618, -1.456706724137931 ], [ 29.770002151898733, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22125, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.456706724137931 ], [ 29.770271645569618, -1.456976206896552 ], [ 29.770541139240507, -1.456976206896552 ], [ 29.770541139240507, -1.456706724137931 ], [ 29.770271645569618, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22126, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.456706724137931 ], [ 29.770541139240507, -1.456976206896552 ], [ 29.770810632911392, -1.456976206896552 ], [ 29.770810632911392, -1.456706724137931 ], [ 29.770541139240507, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22127, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.456706724137931 ], [ 29.770810632911392, -1.456976206896552 ], [ 29.771080126582277, -1.456976206896552 ], [ 29.771080126582277, -1.456706724137931 ], [ 29.770810632911392, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22128, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.456706724137931 ], [ 29.771080126582277, -1.456976206896552 ], [ 29.771349620253165, -1.456976206896552 ], [ 29.771349620253165, -1.456706724137931 ], [ 29.771080126582277, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22129, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.456706724137931 ], [ 29.771349620253165, -1.456976206896552 ], [ 29.77161911392405, -1.456976206896552 ], [ 29.77161911392405, -1.456706724137931 ], [ 29.771349620253165, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22130, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.456706724137931 ], [ 29.77161911392405, -1.456976206896552 ], [ 29.771888607594935, -1.456976206896552 ], [ 29.771888607594935, -1.456706724137931 ], [ 29.77161911392405, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22131, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.456706724137931 ], [ 29.771888607594935, -1.456976206896552 ], [ 29.772158101265823, -1.456976206896552 ], [ 29.772158101265823, -1.456706724137931 ], [ 29.771888607594935, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22132, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.456706724137931 ], [ 29.772158101265823, -1.456976206896552 ], [ 29.772427594936708, -1.456976206896552 ], [ 29.772427594936708, -1.456706724137931 ], [ 29.772158101265823, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22133, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.456706724137931 ], [ 29.772427594936708, -1.456976206896552 ], [ 29.772697088607593, -1.456976206896552 ], [ 29.772697088607593, -1.456706724137931 ], [ 29.772427594936708, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22134, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.456706724137931 ], [ 29.772697088607593, -1.456976206896552 ], [ 29.772966582278482, -1.456976206896552 ], [ 29.772966582278482, -1.456706724137931 ], [ 29.772697088607593, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22135, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.456706724137931 ], [ 29.772966582278482, -1.456976206896552 ], [ 29.773236075949367, -1.456976206896552 ], [ 29.773236075949367, -1.456706724137931 ], [ 29.772966582278482, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22136, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.456706724137931 ], [ 29.773236075949367, -1.456976206896552 ], [ 29.773505569620252, -1.456976206896552 ], [ 29.773505569620252, -1.456706724137931 ], [ 29.773236075949367, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22137, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.456706724137931 ], [ 29.773505569620252, -1.456976206896552 ], [ 29.77377506329114, -1.456976206896552 ], [ 29.77377506329114, -1.456706724137931 ], [ 29.773505569620252, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22138, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.456706724137931 ], [ 29.77377506329114, -1.456976206896552 ], [ 29.774044556962025, -1.456976206896552 ], [ 29.774044556962025, -1.456706724137931 ], [ 29.77377506329114, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22139, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.456706724137931 ], [ 29.774044556962025, -1.456976206896552 ], [ 29.77431405063291, -1.456976206896552 ], [ 29.77431405063291, -1.456706724137931 ], [ 29.774044556962025, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22140, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.456706724137931 ], [ 29.77431405063291, -1.456976206896552 ], [ 29.774853037974683, -1.456976206896552 ], [ 29.774853037974683, -1.456706724137931 ], [ 29.77431405063291, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22141, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.456706724137931 ], [ 29.774853037974683, -1.456976206896552 ], [ 29.775122531645568, -1.456976206896552 ], [ 29.775122531645568, -1.456706724137931 ], [ 29.774853037974683, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22142, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.456706724137931 ], [ 29.775122531645568, -1.456976206896552 ], [ 29.775392025316457, -1.456976206896552 ], [ 29.775392025316457, -1.456706724137931 ], [ 29.775122531645568, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22143, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.456706724137931 ], [ 29.775392025316457, -1.456976206896552 ], [ 29.775661518987341, -1.456976206896552 ], [ 29.775661518987341, -1.456706724137931 ], [ 29.775392025316457, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22144, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.456706724137931 ], [ 29.775661518987341, -1.456976206896552 ], [ 29.775931012658226, -1.456976206896552 ], [ 29.775931012658226, -1.456706724137931 ], [ 29.775661518987341, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22145, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.456706724137931 ], [ 29.775931012658226, -1.456976206896552 ], [ 29.776200506329115, -1.456976206896552 ], [ 29.776200506329115, -1.456706724137931 ], [ 29.775931012658226, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22146, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.456706724137931 ], [ 29.776200506329115, -1.456976206896552 ], [ 29.77647, -1.456976206896552 ], [ 29.77647, -1.456706724137931 ], [ 29.776200506329115, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22147, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.456706724137931 ], [ 29.77647, -1.456976206896552 ], [ 29.776739493670885, -1.456976206896552 ], [ 29.776739493670885, -1.456706724137931 ], [ 29.77647, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22148, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.456706724137931 ], [ 29.776739493670885, -1.456976206896552 ], [ 29.777008987341773, -1.456976206896552 ], [ 29.777008987341773, -1.456706724137931 ], [ 29.776739493670885, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22149, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.456706724137931 ], [ 29.777008987341773, -1.456976206896552 ], [ 29.777278481012658, -1.456976206896552 ], [ 29.777278481012658, -1.456706724137931 ], [ 29.777008987341773, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22150, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.456706724137931 ], [ 29.777278481012658, -1.456976206896552 ], [ 29.777547974683543, -1.456976206896552 ], [ 29.777547974683543, -1.456706724137931 ], [ 29.777278481012658, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22151, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.456706724137931 ], [ 29.777547974683543, -1.456976206896552 ], [ 29.777817468354431, -1.456976206896552 ], [ 29.777817468354431, -1.456706724137931 ], [ 29.777547974683543, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22152, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.456706724137931 ], [ 29.777817468354431, -1.456976206896552 ], [ 29.778086962025316, -1.456976206896552 ], [ 29.778086962025316, -1.456706724137931 ], [ 29.777817468354431, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22153, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.456706724137931 ], [ 29.778086962025316, -1.456976206896552 ], [ 29.778356455696201, -1.456976206896552 ], [ 29.778356455696201, -1.456706724137931 ], [ 29.778086962025316, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22154, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.456706724137931 ], [ 29.778356455696201, -1.456976206896552 ], [ 29.77862594936709, -1.456976206896552 ], [ 29.77862594936709, -1.456706724137931 ], [ 29.778356455696201, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22155, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.456706724137931 ], [ 29.77862594936709, -1.456976206896552 ], [ 29.778895443037975, -1.456976206896552 ], [ 29.778895443037975, -1.456706724137931 ], [ 29.77862594936709, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22156, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.456706724137931 ], [ 29.77916493670886, -1.456976206896552 ], [ 29.779434430379748, -1.456976206896552 ], [ 29.779434430379748, -1.456706724137931 ], [ 29.77916493670886, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22157, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.456706724137931 ], [ 29.779434430379748, -1.456976206896552 ], [ 29.779703924050633, -1.456976206896552 ], [ 29.779703924050633, -1.456706724137931 ], [ 29.779434430379748, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22158, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.456706724137931 ], [ 29.779703924050633, -1.456976206896552 ], [ 29.779973417721518, -1.456976206896552 ], [ 29.779973417721518, -1.456706724137931 ], [ 29.779703924050633, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22159, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.456706724137931 ], [ 29.779973417721518, -1.456976206896552 ], [ 29.780242911392406, -1.456976206896552 ], [ 29.780242911392406, -1.456706724137931 ], [ 29.779973417721518, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22160, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.456706724137931 ], [ 29.780242911392406, -1.456976206896552 ], [ 29.780512405063291, -1.456976206896552 ], [ 29.780512405063291, -1.456706724137931 ], [ 29.780242911392406, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22161, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.456706724137931 ], [ 29.780512405063291, -1.456976206896552 ], [ 29.780781898734176, -1.456976206896552 ], [ 29.780781898734176, -1.456706724137931 ], [ 29.780512405063291, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22162, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.456706724137931 ], [ 29.780781898734176, -1.456976206896552 ], [ 29.781051392405065, -1.456976206896552 ], [ 29.781051392405065, -1.456706724137931 ], [ 29.780781898734176, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22163, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.456706724137931 ], [ 29.781051392405065, -1.458593103448276 ], [ 29.78132088607595, -1.458593103448276 ], [ 29.78132088607595, -1.456706724137931 ], [ 29.781051392405065, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22164, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.456706724137931 ], [ 29.781590379746834, -1.458323620689655 ], [ 29.781859873417723, -1.458323620689655 ], [ 29.781859873417723, -1.456706724137931 ], [ 29.781590379746834, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22165, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.456706724137931 ], [ 29.781859873417723, -1.458593103448276 ], [ 29.782129367088608, -1.458593103448276 ], [ 29.782129367088608, -1.456706724137931 ], [ 29.781859873417723, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22166, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.456706724137931 ], [ 29.782129367088608, -1.458862586206897 ], [ 29.782398860759493, -1.458862586206897 ], [ 29.782398860759493, -1.456706724137931 ], [ 29.782129367088608, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22167, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.456706724137931 ], [ 29.782668354430381, -1.458862586206897 ], [ 29.782937848101266, -1.458862586206897 ], [ 29.782937848101266, -1.456706724137931 ], [ 29.782668354430381, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22168, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.456706724137931 ], [ 29.783476835443039, -1.458862586206897 ], [ 29.784015822784809, -1.458862586206897 ], [ 29.784015822784809, -1.456706724137931 ], [ 29.783476835443039, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22169, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.456706724137931 ], [ 29.791831139240507, -1.456976206896552 ], [ 29.792100632911392, -1.456976206896552 ], [ 29.792100632911392, -1.456706724137931 ], [ 29.791831139240507, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22170, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.456706724137931 ], [ 29.792100632911392, -1.456976206896552 ], [ 29.792370126582277, -1.456976206896552 ], [ 29.792370126582277, -1.456706724137931 ], [ 29.792100632911392, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22171, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.456706724137931 ], [ 29.792370126582277, -1.456976206896552 ], [ 29.792909113924051, -1.456976206896552 ], [ 29.792909113924051, -1.456706724137931 ], [ 29.792370126582277, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22172, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.456706724137931 ], [ 29.792909113924051, -1.456976206896552 ], [ 29.793178607594935, -1.456976206896552 ], [ 29.793178607594935, -1.456706724137931 ], [ 29.792909113924051, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22173, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.456706724137931 ], [ 29.793178607594935, -1.458054137931035 ], [ 29.793448101265824, -1.458054137931035 ], [ 29.793448101265824, -1.456706724137931 ], [ 29.793178607594935, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22174, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.456706724137931 ], [ 29.793448101265824, -1.458054137931035 ], [ 29.793717594936709, -1.458054137931035 ], [ 29.793717594936709, -1.456706724137931 ], [ 29.793448101265824, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22175, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.456706724137931 ], [ 29.793987088607594, -1.458323620689655 ], [ 29.794256582278482, -1.458323620689655 ], [ 29.794256582278482, -1.456706724137931 ], [ 29.793987088607594, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22176, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.456706724137931 ], [ 29.794256582278482, -1.458323620689655 ], [ 29.794526075949367, -1.458323620689655 ], [ 29.794526075949367, -1.456706724137931 ], [ 29.794256582278482, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22177, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.456706724137931 ], [ 29.794795569620252, -1.458054137931035 ], [ 29.79506506329114, -1.458054137931035 ], [ 29.79506506329114, -1.456706724137931 ], [ 29.794795569620252, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22178, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.456706724137931 ], [ 29.79560405063291, -1.457515172413793 ], [ 29.795873544303799, -1.457515172413793 ], [ 29.795873544303799, -1.456706724137931 ], [ 29.79560405063291, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22179, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.456706724137931 ], [ 29.796143037974684, -1.456976206896552 ], [ 29.796412531645569, -1.456976206896552 ], [ 29.796412531645569, -1.456706724137931 ], [ 29.796143037974684, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22180, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.456706724137931 ], [ 29.796412531645569, -1.456976206896552 ], [ 29.796682025316457, -1.456976206896552 ], [ 29.796682025316457, -1.456706724137931 ], [ 29.796412531645569, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22181, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.456706724137931 ], [ 29.796682025316457, -1.456976206896552 ], [ 29.796951518987342, -1.456976206896552 ], [ 29.796951518987342, -1.456706724137931 ], [ 29.796682025316457, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22182, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.456706724137931 ], [ 29.796951518987342, -1.456976206896552 ], [ 29.797221012658227, -1.456976206896552 ], [ 29.797221012658227, -1.456706724137931 ], [ 29.796951518987342, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22183, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.456706724137931 ], [ 29.797221012658227, -1.456976206896552 ], [ 29.797490506329115, -1.456976206896552 ], [ 29.797490506329115, -1.456706724137931 ], [ 29.797221012658227, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22184, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.456706724137931 ], [ 29.797490506329115, -1.456976206896552 ], [ 29.79776, -1.456976206896552 ], [ 29.79776, -1.456706724137931 ], [ 29.797490506329115, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22185, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.456706724137931 ], [ 29.79776, -1.456976206896552 ], [ 29.798029493670885, -1.456976206896552 ], [ 29.798029493670885, -1.456706724137931 ], [ 29.79776, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22186, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.456706724137931 ], [ 29.798029493670885, -1.456976206896552 ], [ 29.798298987341774, -1.456976206896552 ], [ 29.798298987341774, -1.456706724137931 ], [ 29.798029493670885, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22187, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.456706724137931 ], [ 29.798298987341774, -1.456976206896552 ], [ 29.798568481012659, -1.456976206896552 ], [ 29.798568481012659, -1.456706724137931 ], [ 29.798298987341774, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22188, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.456706724137931 ], [ 29.798568481012659, -1.456976206896552 ], [ 29.798837974683543, -1.456976206896552 ], [ 29.798837974683543, -1.456706724137931 ], [ 29.798568481012659, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22189, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.456706724137931 ], [ 29.798837974683543, -1.456976206896552 ], [ 29.799107468354432, -1.456976206896552 ], [ 29.799107468354432, -1.456706724137931 ], [ 29.798837974683543, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22190, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.456706724137931 ], [ 29.799107468354432, -1.456976206896552 ], [ 29.799376962025317, -1.456976206896552 ], [ 29.799376962025317, -1.456706724137931 ], [ 29.799107468354432, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22191, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.456706724137931 ], [ 29.799376962025317, -1.457245689655172 ], [ 29.799646455696202, -1.457245689655172 ], [ 29.799646455696202, -1.456706724137931 ], [ 29.799376962025317, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22192, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.456706724137931 ], [ 29.799646455696202, -1.457245689655172 ], [ 29.79991594936709, -1.457245689655172 ], [ 29.79991594936709, -1.456706724137931 ], [ 29.799646455696202, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22193, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.456706724137931 ], [ 29.808809240506328, -1.456976206896552 ], [ 29.809078734177216, -1.456976206896552 ], [ 29.809078734177216, -1.456706724137931 ], [ 29.808809240506328, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22194, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.456706724137931 ], [ 29.809078734177216, -1.456976206896552 ], [ 29.809348227848101, -1.456976206896552 ], [ 29.809348227848101, -1.456706724137931 ], [ 29.809078734177216, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22195, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.456706724137931 ], [ 29.809348227848101, -1.456976206896552 ], [ 29.809617721518986, -1.456976206896552 ], [ 29.809617721518986, -1.456706724137931 ], [ 29.809348227848101, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22196, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.456706724137931 ], [ 29.809617721518986, -1.456976206896552 ], [ 29.809887215189875, -1.456976206896552 ], [ 29.809887215189875, -1.456706724137931 ], [ 29.809617721518986, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22197, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.456706724137931 ], [ 29.810965189873418, -1.456976206896552 ], [ 29.811234683544303, -1.456976206896552 ], [ 29.811234683544303, -1.456706724137931 ], [ 29.810965189873418, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22198, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.456706724137931 ], [ 29.811234683544303, -1.456976206896552 ], [ 29.811504177215191, -1.456976206896552 ], [ 29.811504177215191, -1.456706724137931 ], [ 29.811234683544303, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22199, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.456706724137931 ], [ 29.811504177215191, -1.456976206896552 ], [ 29.811773670886076, -1.456976206896552 ], [ 29.811773670886076, -1.456706724137931 ], [ 29.811504177215191, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22200, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.456706724137931 ], [ 29.811773670886076, -1.456976206896552 ], [ 29.812043164556961, -1.456976206896552 ], [ 29.812043164556961, -1.456706724137931 ], [ 29.811773670886076, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22201, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.456706724137931 ], [ 29.812043164556961, -1.456976206896552 ], [ 29.812312658227849, -1.456976206896552 ], [ 29.812312658227849, -1.456706724137931 ], [ 29.812043164556961, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22202, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.456706724137931 ], [ 29.812312658227849, -1.456976206896552 ], [ 29.812582151898734, -1.456976206896552 ], [ 29.812582151898734, -1.456706724137931 ], [ 29.812312658227849, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22203, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.456706724137931 ], [ 29.812582151898734, -1.456976206896552 ], [ 29.812851645569619, -1.456976206896552 ], [ 29.812851645569619, -1.456706724137931 ], [ 29.812582151898734, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22204, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.456706724137931 ], [ 29.812851645569619, -1.456976206896552 ], [ 29.813121139240508, -1.456976206896552 ], [ 29.813121139240508, -1.456706724137931 ], [ 29.812851645569619, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22205, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.456706724137931 ], [ 29.813121139240508, -1.456976206896552 ], [ 29.813390632911393, -1.456976206896552 ], [ 29.813390632911393, -1.456706724137931 ], [ 29.813121139240508, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22206, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.456706724137931 ], [ 29.813390632911393, -1.456976206896552 ], [ 29.813660126582278, -1.456976206896552 ], [ 29.813660126582278, -1.456706724137931 ], [ 29.813390632911393, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22207, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.456706724137931 ], [ 29.813660126582278, -1.456976206896552 ], [ 29.813929620253166, -1.456976206896552 ], [ 29.813929620253166, -1.456706724137931 ], [ 29.813660126582278, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22208, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.456706724137931 ], [ 29.813929620253166, -1.456976206896552 ], [ 29.814199113924051, -1.456976206896552 ], [ 29.814199113924051, -1.456706724137931 ], [ 29.813929620253166, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22209, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.456706724137931 ], [ 29.814199113924051, -1.456976206896552 ], [ 29.814468607594936, -1.456976206896552 ], [ 29.814468607594936, -1.456706724137931 ], [ 29.814199113924051, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22210, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.456706724137931 ], [ 29.814468607594936, -1.457245689655172 ], [ 29.814738101265824, -1.457245689655172 ], [ 29.814738101265824, -1.456706724137931 ], [ 29.814468607594936, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22211, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.456976206896552 ], [ 29.751407088607593, -1.457245689655172 ], [ 29.751676582278481, -1.457245689655172 ], [ 29.751676582278481, -1.456976206896552 ], [ 29.751407088607593, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22212, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.456976206896552 ], [ 29.751676582278481, -1.457245689655172 ], [ 29.751946075949366, -1.457245689655172 ], [ 29.751946075949366, -1.456976206896552 ], [ 29.751676582278481, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22213, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.456976206896552 ], [ 29.751946075949366, -1.457245689655172 ], [ 29.752215569620251, -1.457245689655172 ], [ 29.752215569620251, -1.456976206896552 ], [ 29.751946075949366, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22214, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.456976206896552 ], [ 29.752215569620251, -1.457245689655172 ], [ 29.75248506329114, -1.457245689655172 ], [ 29.75248506329114, -1.456976206896552 ], [ 29.752215569620251, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22215, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.456976206896552 ], [ 29.75248506329114, -1.457245689655172 ], [ 29.752754556962024, -1.457245689655172 ], [ 29.752754556962024, -1.456976206896552 ], [ 29.75248506329114, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22216, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.456976206896552 ], [ 29.752754556962024, -1.457245689655172 ], [ 29.753024050632909, -1.457245689655172 ], [ 29.753024050632909, -1.456976206896552 ], [ 29.752754556962024, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22217, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.456976206896552 ], [ 29.753024050632909, -1.457245689655172 ], [ 29.753293544303798, -1.457245689655172 ], [ 29.753293544303798, -1.456976206896552 ], [ 29.753024050632909, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22218, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.456976206896552 ], [ 29.753293544303798, -1.457245689655172 ], [ 29.753563037974683, -1.457245689655172 ], [ 29.753563037974683, -1.456976206896552 ], [ 29.753293544303798, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22219, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.456976206896552 ], [ 29.753563037974683, -1.457245689655172 ], [ 29.753832531645568, -1.457245689655172 ], [ 29.753832531645568, -1.456976206896552 ], [ 29.753563037974683, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22220, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.456976206896552 ], [ 29.753832531645568, -1.457245689655172 ], [ 29.754102025316456, -1.457245689655172 ], [ 29.754102025316456, -1.456976206896552 ], [ 29.753832531645568, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22221, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.456976206896552 ], [ 29.754102025316456, -1.457245689655172 ], [ 29.754371518987341, -1.457245689655172 ], [ 29.754371518987341, -1.456976206896552 ], [ 29.754102025316456, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22222, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.456976206896552 ], [ 29.754371518987341, -1.457245689655172 ], [ 29.754641012658226, -1.457245689655172 ], [ 29.754641012658226, -1.456976206896552 ], [ 29.754371518987341, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22223, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.456976206896552 ], [ 29.754641012658226, -1.457245689655172 ], [ 29.754910506329114, -1.457245689655172 ], [ 29.754910506329114, -1.456976206896552 ], [ 29.754641012658226, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22224, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.456976206896552 ], [ 29.754910506329114, -1.457245689655172 ], [ 29.75518, -1.457245689655172 ], [ 29.75518, -1.456976206896552 ], [ 29.754910506329114, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22225, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.456976206896552 ], [ 29.75518, -1.457245689655172 ], [ 29.755449493670884, -1.457245689655172 ], [ 29.755449493670884, -1.456976206896552 ], [ 29.75518, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22226, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.456976206896552 ], [ 29.755449493670884, -1.457245689655172 ], [ 29.755718987341773, -1.457245689655172 ], [ 29.755718987341773, -1.456976206896552 ], [ 29.755449493670884, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22227, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.456976206896552 ], [ 29.755718987341773, -1.457245689655172 ], [ 29.755988481012658, -1.457245689655172 ], [ 29.755988481012658, -1.456976206896552 ], [ 29.755718987341773, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22228, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.456976206896552 ], [ 29.755988481012658, -1.457245689655172 ], [ 29.756257974683542, -1.457245689655172 ], [ 29.756257974683542, -1.456976206896552 ], [ 29.755988481012658, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22229, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.456976206896552 ], [ 29.756257974683542, -1.457245689655172 ], [ 29.756796962025316, -1.457245689655172 ], [ 29.756796962025316, -1.456976206896552 ], [ 29.756257974683542, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22230, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.456976206896552 ], [ 29.756796962025316, -1.457245689655172 ], [ 29.757066455696201, -1.457245689655172 ], [ 29.757066455696201, -1.456976206896552 ], [ 29.756796962025316, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22231, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.456976206896552 ], [ 29.757066455696201, -1.457245689655172 ], [ 29.757335949367089, -1.457245689655172 ], [ 29.757335949367089, -1.456976206896552 ], [ 29.757066455696201, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22232, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.456976206896552 ], [ 29.757335949367089, -1.457245689655172 ], [ 29.757605443037974, -1.457245689655172 ], [ 29.757605443037974, -1.456976206896552 ], [ 29.757335949367089, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22233, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.456976206896552 ], [ 29.757605443037974, -1.457245689655172 ], [ 29.757874936708859, -1.457245689655172 ], [ 29.757874936708859, -1.456976206896552 ], [ 29.757605443037974, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22234, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.456976206896552 ], [ 29.757874936708859, -1.457245689655172 ], [ 29.758144430379748, -1.457245689655172 ], [ 29.758144430379748, -1.456976206896552 ], [ 29.757874936708859, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22235, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.456976206896552 ], [ 29.758144430379748, -1.457245689655172 ], [ 29.758413924050632, -1.457245689655172 ], [ 29.758413924050632, -1.456976206896552 ], [ 29.758144430379748, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22236, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.456976206896552 ], [ 29.758413924050632, -1.457245689655172 ], [ 29.758683417721517, -1.457245689655172 ], [ 29.758683417721517, -1.456976206896552 ], [ 29.758413924050632, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22237, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.456976206896552 ], [ 29.758683417721517, -1.457245689655172 ], [ 29.758952911392406, -1.457245689655172 ], [ 29.758952911392406, -1.456976206896552 ], [ 29.758683417721517, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22238, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.456976206896552 ], [ 29.758952911392406, -1.457245689655172 ], [ 29.759222405063291, -1.457245689655172 ], [ 29.759222405063291, -1.456976206896552 ], [ 29.758952911392406, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22239, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.456976206896552 ], [ 29.759222405063291, -1.457245689655172 ], [ 29.759491898734176, -1.457245689655172 ], [ 29.759491898734176, -1.456976206896552 ], [ 29.759222405063291, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22240, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.456976206896552 ], [ 29.759491898734176, -1.457245689655172 ], [ 29.759761392405064, -1.457245689655172 ], [ 29.759761392405064, -1.456976206896552 ], [ 29.759491898734176, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22241, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.456976206896552 ], [ 29.759761392405064, -1.457245689655172 ], [ 29.760030886075949, -1.457245689655172 ], [ 29.760030886075949, -1.456976206896552 ], [ 29.759761392405064, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22242, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.456976206896552 ], [ 29.760030886075949, -1.457245689655172 ], [ 29.760300379746834, -1.457245689655172 ], [ 29.760300379746834, -1.456976206896552 ], [ 29.760030886075949, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22243, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.456976206896552 ], [ 29.760300379746834, -1.457245689655172 ], [ 29.760569873417722, -1.457245689655172 ], [ 29.760569873417722, -1.456976206896552 ], [ 29.760300379746834, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22244, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.456976206896552 ], [ 29.760569873417722, -1.457245689655172 ], [ 29.760839367088607, -1.457245689655172 ], [ 29.760839367088607, -1.456976206896552 ], [ 29.760569873417722, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22245, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.456976206896552 ], [ 29.760839367088607, -1.457245689655172 ], [ 29.761108860759492, -1.457245689655172 ], [ 29.761108860759492, -1.456976206896552 ], [ 29.760839367088607, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22246, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.456976206896552 ], [ 29.761108860759492, -1.457245689655172 ], [ 29.761378354430381, -1.457245689655172 ], [ 29.761378354430381, -1.456976206896552 ], [ 29.761108860759492, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22247, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.456976206896552 ], [ 29.761378354430381, -1.457245689655172 ], [ 29.761647848101266, -1.457245689655172 ], [ 29.761647848101266, -1.456976206896552 ], [ 29.761378354430381, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22248, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.456976206896552 ], [ 29.761647848101266, -1.457245689655172 ], [ 29.761917341772151, -1.457245689655172 ], [ 29.761917341772151, -1.456976206896552 ], [ 29.761647848101266, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22249, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.456976206896552 ], [ 29.761917341772151, -1.457245689655172 ], [ 29.762186835443039, -1.457245689655172 ], [ 29.762186835443039, -1.456976206896552 ], [ 29.761917341772151, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22250, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.456976206896552 ], [ 29.762186835443039, -1.457245689655172 ], [ 29.762456329113924, -1.457245689655172 ], [ 29.762456329113924, -1.456976206896552 ], [ 29.762186835443039, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22251, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.456976206896552 ], [ 29.762456329113924, -1.457245689655172 ], [ 29.763534303797467, -1.457245689655172 ], [ 29.763534303797467, -1.456976206896552 ], [ 29.762456329113924, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22252, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.456976206896552 ], [ 29.763534303797467, -1.457245689655172 ], [ 29.763803797468356, -1.457245689655172 ], [ 29.763803797468356, -1.456976206896552 ], [ 29.763534303797467, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22253, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.456976206896552 ], [ 29.763803797468356, -1.457245689655172 ], [ 29.76407329113924, -1.457245689655172 ], [ 29.76407329113924, -1.456976206896552 ], [ 29.763803797468356, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22254, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.456976206896552 ], [ 29.76407329113924, -1.457245689655172 ], [ 29.764342784810125, -1.457245689655172 ], [ 29.764342784810125, -1.456976206896552 ], [ 29.76407329113924, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22255, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.456976206896552 ], [ 29.764342784810125, -1.457245689655172 ], [ 29.764612278481014, -1.457245689655172 ], [ 29.764612278481014, -1.456976206896552 ], [ 29.764342784810125, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22256, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.456976206896552 ], [ 29.764612278481014, -1.457245689655172 ], [ 29.764881772151899, -1.457245689655172 ], [ 29.764881772151899, -1.456976206896552 ], [ 29.764612278481014, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22257, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.456976206896552 ], [ 29.764881772151899, -1.457245689655172 ], [ 29.765151265822784, -1.457245689655172 ], [ 29.765151265822784, -1.456976206896552 ], [ 29.764881772151899, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22258, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.456976206896552 ], [ 29.765151265822784, -1.457245689655172 ], [ 29.765420759493672, -1.457245689655172 ], [ 29.765420759493672, -1.456976206896552 ], [ 29.765151265822784, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22259, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.456976206896552 ], [ 29.765420759493672, -1.457245689655172 ], [ 29.765959746835442, -1.457245689655172 ], [ 29.765959746835442, -1.456976206896552 ], [ 29.765420759493672, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22260, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.456976206896552 ], [ 29.765959746835442, -1.457245689655172 ], [ 29.766229240506327, -1.457245689655172 ], [ 29.766229240506327, -1.456976206896552 ], [ 29.765959746835442, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22261, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.456976206896552 ], [ 29.766229240506327, -1.457245689655172 ], [ 29.766498734177215, -1.457245689655172 ], [ 29.766498734177215, -1.456976206896552 ], [ 29.766229240506327, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22262, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.456976206896552 ], [ 29.766498734177215, -1.457245689655172 ], [ 29.7667682278481, -1.457245689655172 ], [ 29.7667682278481, -1.456976206896552 ], [ 29.766498734177215, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22263, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.456976206896552 ], [ 29.7667682278481, -1.457245689655172 ], [ 29.767037721518985, -1.457245689655172 ], [ 29.767037721518985, -1.456976206896552 ], [ 29.7667682278481, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22264, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.456976206896552 ], [ 29.767037721518985, -1.457245689655172 ], [ 29.768654683544302, -1.457245689655172 ], [ 29.768654683544302, -1.456976206896552 ], [ 29.767037721518985, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22265, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.456976206896552 ], [ 29.768654683544302, -1.457245689655172 ], [ 29.76892417721519, -1.457245689655172 ], [ 29.76892417721519, -1.456976206896552 ], [ 29.768654683544302, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22266, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.456976206896552 ], [ 29.76892417721519, -1.457245689655172 ], [ 29.769193670886075, -1.457245689655172 ], [ 29.769193670886075, -1.456976206896552 ], [ 29.76892417721519, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22267, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.456976206896552 ], [ 29.769193670886075, -1.457245689655172 ], [ 29.76946316455696, -1.457245689655172 ], [ 29.76946316455696, -1.456976206896552 ], [ 29.769193670886075, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22268, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.456976206896552 ], [ 29.76946316455696, -1.457245689655172 ], [ 29.769732658227849, -1.457245689655172 ], [ 29.769732658227849, -1.456976206896552 ], [ 29.76946316455696, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22269, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.456976206896552 ], [ 29.769732658227849, -1.457245689655172 ], [ 29.770002151898733, -1.457245689655172 ], [ 29.770002151898733, -1.456976206896552 ], [ 29.769732658227849, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22270, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.456976206896552 ], [ 29.770002151898733, -1.457245689655172 ], [ 29.770271645569618, -1.457245689655172 ], [ 29.770271645569618, -1.456976206896552 ], [ 29.770002151898733, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22271, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.456976206896552 ], [ 29.770271645569618, -1.457245689655172 ], [ 29.770541139240507, -1.457245689655172 ], [ 29.770541139240507, -1.456976206896552 ], [ 29.770271645569618, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22272, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.456976206896552 ], [ 29.770541139240507, -1.457245689655172 ], [ 29.770810632911392, -1.457245689655172 ], [ 29.770810632911392, -1.456976206896552 ], [ 29.770541139240507, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22273, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.456976206896552 ], [ 29.770810632911392, -1.457245689655172 ], [ 29.771080126582277, -1.457245689655172 ], [ 29.771080126582277, -1.456976206896552 ], [ 29.770810632911392, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22274, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.456976206896552 ], [ 29.771080126582277, -1.457245689655172 ], [ 29.771349620253165, -1.457245689655172 ], [ 29.771349620253165, -1.456976206896552 ], [ 29.771080126582277, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22275, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.456976206896552 ], [ 29.771349620253165, -1.457245689655172 ], [ 29.77161911392405, -1.457245689655172 ], [ 29.77161911392405, -1.456976206896552 ], [ 29.771349620253165, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22276, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.456976206896552 ], [ 29.77161911392405, -1.457245689655172 ], [ 29.771888607594935, -1.457245689655172 ], [ 29.771888607594935, -1.456976206896552 ], [ 29.77161911392405, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22277, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.456976206896552 ], [ 29.771888607594935, -1.457245689655172 ], [ 29.772158101265823, -1.457245689655172 ], [ 29.772158101265823, -1.456976206896552 ], [ 29.771888607594935, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22278, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.456976206896552 ], [ 29.772158101265823, -1.457245689655172 ], [ 29.772427594936708, -1.457245689655172 ], [ 29.772427594936708, -1.456976206896552 ], [ 29.772158101265823, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22279, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.456976206896552 ], [ 29.772427594936708, -1.457245689655172 ], [ 29.772697088607593, -1.457245689655172 ], [ 29.772697088607593, -1.456976206896552 ], [ 29.772427594936708, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22280, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.456976206896552 ], [ 29.772697088607593, -1.457245689655172 ], [ 29.772966582278482, -1.457245689655172 ], [ 29.772966582278482, -1.456976206896552 ], [ 29.772697088607593, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22281, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.456976206896552 ], [ 29.772966582278482, -1.457245689655172 ], [ 29.773236075949367, -1.457245689655172 ], [ 29.773236075949367, -1.456976206896552 ], [ 29.772966582278482, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22282, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.456976206896552 ], [ 29.773236075949367, -1.457245689655172 ], [ 29.773505569620252, -1.457245689655172 ], [ 29.773505569620252, -1.456976206896552 ], [ 29.773236075949367, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22283, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.456976206896552 ], [ 29.773505569620252, -1.457245689655172 ], [ 29.77377506329114, -1.457245689655172 ], [ 29.77377506329114, -1.456976206896552 ], [ 29.773505569620252, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22284, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.456976206896552 ], [ 29.77377506329114, -1.457245689655172 ], [ 29.774044556962025, -1.457245689655172 ], [ 29.774044556962025, -1.456976206896552 ], [ 29.77377506329114, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22285, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.456976206896552 ], [ 29.774044556962025, -1.457245689655172 ], [ 29.77431405063291, -1.457245689655172 ], [ 29.77431405063291, -1.456976206896552 ], [ 29.774044556962025, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22286, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.456976206896552 ], [ 29.77431405063291, -1.457245689655172 ], [ 29.774853037974683, -1.457245689655172 ], [ 29.774853037974683, -1.456976206896552 ], [ 29.77431405063291, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22287, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.456976206896552 ], [ 29.774853037974683, -1.457245689655172 ], [ 29.775122531645568, -1.457245689655172 ], [ 29.775122531645568, -1.456976206896552 ], [ 29.774853037974683, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22288, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.456976206896552 ], [ 29.775122531645568, -1.457245689655172 ], [ 29.775392025316457, -1.457245689655172 ], [ 29.775392025316457, -1.456976206896552 ], [ 29.775122531645568, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22289, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.456976206896552 ], [ 29.775392025316457, -1.457245689655172 ], [ 29.775661518987341, -1.457245689655172 ], [ 29.775661518987341, -1.456976206896552 ], [ 29.775392025316457, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22290, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.456976206896552 ], [ 29.775661518987341, -1.457245689655172 ], [ 29.775931012658226, -1.457245689655172 ], [ 29.775931012658226, -1.456976206896552 ], [ 29.775661518987341, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22291, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.456976206896552 ], [ 29.775931012658226, -1.457245689655172 ], [ 29.776200506329115, -1.457245689655172 ], [ 29.776200506329115, -1.456976206896552 ], [ 29.775931012658226, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22292, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.456976206896552 ], [ 29.776200506329115, -1.457245689655172 ], [ 29.77647, -1.457245689655172 ], [ 29.77647, -1.456976206896552 ], [ 29.776200506329115, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22293, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.456976206896552 ], [ 29.77647, -1.457245689655172 ], [ 29.776739493670885, -1.457245689655172 ], [ 29.776739493670885, -1.456976206896552 ], [ 29.77647, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22294, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.456976206896552 ], [ 29.776739493670885, -1.457245689655172 ], [ 29.777008987341773, -1.457245689655172 ], [ 29.777008987341773, -1.456976206896552 ], [ 29.776739493670885, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22295, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.456976206896552 ], [ 29.777008987341773, -1.457245689655172 ], [ 29.777278481012658, -1.457245689655172 ], [ 29.777278481012658, -1.456976206896552 ], [ 29.777008987341773, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22296, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.456976206896552 ], [ 29.777278481012658, -1.457245689655172 ], [ 29.777547974683543, -1.457245689655172 ], [ 29.777547974683543, -1.456976206896552 ], [ 29.777278481012658, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22297, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.456976206896552 ], [ 29.777547974683543, -1.457245689655172 ], [ 29.777817468354431, -1.457245689655172 ], [ 29.777817468354431, -1.456976206896552 ], [ 29.777547974683543, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22298, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.456976206896552 ], [ 29.777817468354431, -1.457245689655172 ], [ 29.778086962025316, -1.457245689655172 ], [ 29.778086962025316, -1.456976206896552 ], [ 29.777817468354431, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22299, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.456976206896552 ], [ 29.778086962025316, -1.457245689655172 ], [ 29.778356455696201, -1.457245689655172 ], [ 29.778356455696201, -1.456976206896552 ], [ 29.778086962025316, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22300, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.456976206896552 ], [ 29.778356455696201, -1.457245689655172 ], [ 29.77862594936709, -1.457245689655172 ], [ 29.77862594936709, -1.456976206896552 ], [ 29.778356455696201, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22301, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.456976206896552 ], [ 29.77862594936709, -1.457245689655172 ], [ 29.778895443037975, -1.457245689655172 ], [ 29.778895443037975, -1.456976206896552 ], [ 29.77862594936709, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22302, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.456976206896552 ], [ 29.778895443037975, -1.457245689655172 ], [ 29.77916493670886, -1.457245689655172 ], [ 29.77916493670886, -1.456976206896552 ], [ 29.778895443037975, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22303, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.456976206896552 ], [ 29.77916493670886, -1.457245689655172 ], [ 29.779434430379748, -1.457245689655172 ], [ 29.779434430379748, -1.456976206896552 ], [ 29.77916493670886, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22304, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.456976206896552 ], [ 29.779434430379748, -1.457245689655172 ], [ 29.779703924050633, -1.457245689655172 ], [ 29.779703924050633, -1.456976206896552 ], [ 29.779434430379748, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22305, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.456976206896552 ], [ 29.779703924050633, -1.458593103448276 ], [ 29.779973417721518, -1.458593103448276 ], [ 29.779973417721518, -1.456976206896552 ], [ 29.779703924050633, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22306, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.456976206896552 ], [ 29.779973417721518, -1.458593103448276 ], [ 29.780242911392406, -1.458593103448276 ], [ 29.780242911392406, -1.456976206896552 ], [ 29.779973417721518, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22307, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.456976206896552 ], [ 29.780242911392406, -1.458323620689655 ], [ 29.780512405063291, -1.458323620689655 ], [ 29.780512405063291, -1.456976206896552 ], [ 29.780242911392406, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22308, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.456976206896552 ], [ 29.780512405063291, -1.458862586206897 ], [ 29.780781898734176, -1.458862586206897 ], [ 29.780781898734176, -1.456976206896552 ], [ 29.780512405063291, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22309, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.456976206896552 ], [ 29.780781898734176, -1.458862586206897 ], [ 29.781051392405065, -1.458862586206897 ], [ 29.781051392405065, -1.456976206896552 ], [ 29.780781898734176, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22310, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.456976206896552 ], [ 29.792100632911392, -1.457784655172414 ], [ 29.792370126582277, -1.457784655172414 ], [ 29.792370126582277, -1.456976206896552 ], [ 29.792100632911392, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22311, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.456976206896552 ], [ 29.792370126582277, -1.457784655172414 ], [ 29.792909113924051, -1.457784655172414 ], [ 29.792909113924051, -1.456976206896552 ], [ 29.792370126582277, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22312, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.456976206896552 ], [ 29.792909113924051, -1.457784655172414 ], [ 29.793178607594935, -1.457784655172414 ], [ 29.793178607594935, -1.456976206896552 ], [ 29.792909113924051, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22313, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.456976206896552 ], [ 29.793717594936709, -1.457784655172414 ], [ 29.793987088607594, -1.457784655172414 ], [ 29.793987088607594, -1.456976206896552 ], [ 29.793717594936709, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22314, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.456976206896552 ], [ 29.79506506329114, -1.457784655172414 ], [ 29.795334556962025, -1.457784655172414 ], [ 29.795334556962025, -1.456976206896552 ], [ 29.79506506329114, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22315, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.456976206896552 ], [ 29.796143037974684, -1.457245689655172 ], [ 29.796412531645569, -1.457245689655172 ], [ 29.796412531645569, -1.456976206896552 ], [ 29.796143037974684, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22316, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.456976206896552 ], [ 29.796412531645569, -1.457245689655172 ], [ 29.796682025316457, -1.457245689655172 ], [ 29.796682025316457, -1.456976206896552 ], [ 29.796412531645569, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22317, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.456976206896552 ], [ 29.796682025316457, -1.457245689655172 ], [ 29.796951518987342, -1.457245689655172 ], [ 29.796951518987342, -1.456976206896552 ], [ 29.796682025316457, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22318, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.456976206896552 ], [ 29.796951518987342, -1.457245689655172 ], [ 29.797221012658227, -1.457245689655172 ], [ 29.797221012658227, -1.456976206896552 ], [ 29.796951518987342, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22319, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.456976206896552 ], [ 29.797221012658227, -1.457245689655172 ], [ 29.797490506329115, -1.457245689655172 ], [ 29.797490506329115, -1.456976206896552 ], [ 29.797221012658227, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22320, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.456976206896552 ], [ 29.797490506329115, -1.457245689655172 ], [ 29.79776, -1.457245689655172 ], [ 29.79776, -1.456976206896552 ], [ 29.797490506329115, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22321, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.456976206896552 ], [ 29.79776, -1.457245689655172 ], [ 29.798029493670885, -1.457245689655172 ], [ 29.798029493670885, -1.456976206896552 ], [ 29.79776, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22322, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.456976206896552 ], [ 29.798029493670885, -1.457245689655172 ], [ 29.798298987341774, -1.457245689655172 ], [ 29.798298987341774, -1.456976206896552 ], [ 29.798029493670885, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22323, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.456976206896552 ], [ 29.798298987341774, -1.457245689655172 ], [ 29.798568481012659, -1.457245689655172 ], [ 29.798568481012659, -1.456976206896552 ], [ 29.798298987341774, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22324, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.456976206896552 ], [ 29.798568481012659, -1.457245689655172 ], [ 29.798837974683543, -1.457245689655172 ], [ 29.798837974683543, -1.456976206896552 ], [ 29.798568481012659, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.456976206896552 ], [ 29.798837974683543, -1.457515172413793 ], [ 29.799107468354432, -1.457515172413793 ], [ 29.799107468354432, -1.456976206896552 ], [ 29.798837974683543, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22326, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.456976206896552 ], [ 29.799107468354432, -1.457515172413793 ], [ 29.799376962025317, -1.457515172413793 ], [ 29.799376962025317, -1.456976206896552 ], [ 29.799107468354432, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22327, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.456976206896552 ], [ 29.808539746835443, -1.457245689655172 ], [ 29.808809240506328, -1.457245689655172 ], [ 29.808809240506328, -1.456976206896552 ], [ 29.808539746835443, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22328, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.456976206896552 ], [ 29.808809240506328, -1.457245689655172 ], [ 29.809078734177216, -1.457245689655172 ], [ 29.809078734177216, -1.456976206896552 ], [ 29.808809240506328, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22329, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.456976206896552 ], [ 29.809078734177216, -1.457245689655172 ], [ 29.809348227848101, -1.457245689655172 ], [ 29.809348227848101, -1.456976206896552 ], [ 29.809078734177216, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22330, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.456976206896552 ], [ 29.809348227848101, -1.457245689655172 ], [ 29.809617721518986, -1.457245689655172 ], [ 29.809617721518986, -1.456976206896552 ], [ 29.809348227848101, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22331, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.456976206896552 ], [ 29.810965189873418, -1.457245689655172 ], [ 29.811234683544303, -1.457245689655172 ], [ 29.811234683544303, -1.456976206896552 ], [ 29.810965189873418, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22332, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.456976206896552 ], [ 29.811234683544303, -1.457245689655172 ], [ 29.811504177215191, -1.457245689655172 ], [ 29.811504177215191, -1.456976206896552 ], [ 29.811234683544303, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22333, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.456976206896552 ], [ 29.811504177215191, -1.457245689655172 ], [ 29.811773670886076, -1.457245689655172 ], [ 29.811773670886076, -1.456976206896552 ], [ 29.811504177215191, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22334, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.456976206896552 ], [ 29.811773670886076, -1.457245689655172 ], [ 29.812043164556961, -1.457245689655172 ], [ 29.812043164556961, -1.456976206896552 ], [ 29.811773670886076, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22335, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.456976206896552 ], [ 29.812043164556961, -1.457245689655172 ], [ 29.812312658227849, -1.457245689655172 ], [ 29.812312658227849, -1.456976206896552 ], [ 29.812043164556961, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22336, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.456976206896552 ], [ 29.812312658227849, -1.457245689655172 ], [ 29.812582151898734, -1.457245689655172 ], [ 29.812582151898734, -1.456976206896552 ], [ 29.812312658227849, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22337, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.456976206896552 ], [ 29.812582151898734, -1.457245689655172 ], [ 29.812851645569619, -1.457245689655172 ], [ 29.812851645569619, -1.456976206896552 ], [ 29.812582151898734, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22338, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.456976206896552 ], [ 29.812851645569619, -1.457245689655172 ], [ 29.813121139240508, -1.457245689655172 ], [ 29.813121139240508, -1.456976206896552 ], [ 29.812851645569619, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22339, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.456976206896552 ], [ 29.813121139240508, -1.457245689655172 ], [ 29.813390632911393, -1.457245689655172 ], [ 29.813390632911393, -1.456976206896552 ], [ 29.813121139240508, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22340, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.456976206896552 ], [ 29.813390632911393, -1.457245689655172 ], [ 29.813660126582278, -1.457245689655172 ], [ 29.813660126582278, -1.456976206896552 ], [ 29.813390632911393, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22341, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.456976206896552 ], [ 29.813660126582278, -1.457245689655172 ], [ 29.813929620253166, -1.457245689655172 ], [ 29.813929620253166, -1.456976206896552 ], [ 29.813660126582278, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22342, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.456976206896552 ], [ 29.813929620253166, -1.457245689655172 ], [ 29.814199113924051, -1.457245689655172 ], [ 29.814199113924051, -1.456976206896552 ], [ 29.813929620253166, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22343, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.456976206896552 ], [ 29.814199113924051, -1.457245689655172 ], [ 29.814468607594936, -1.457245689655172 ], [ 29.814468607594936, -1.456976206896552 ], [ 29.814199113924051, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22344, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751407088607593, -1.457245689655172 ], [ 29.751407088607593, -1.457784655172414 ], [ 29.751676582278481, -1.457784655172414 ], [ 29.751676582278481, -1.457245689655172 ], [ 29.751407088607593, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22345, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.457245689655172 ], [ 29.751676582278481, -1.457515172413793 ], [ 29.751946075949366, -1.457515172413793 ], [ 29.751946075949366, -1.457245689655172 ], [ 29.751676582278481, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22346, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.457245689655172 ], [ 29.751946075949366, -1.457515172413793 ], [ 29.752215569620251, -1.457515172413793 ], [ 29.752215569620251, -1.457245689655172 ], [ 29.751946075949366, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22347, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.457245689655172 ], [ 29.752215569620251, -1.457515172413793 ], [ 29.75248506329114, -1.457515172413793 ], [ 29.75248506329114, -1.457245689655172 ], [ 29.752215569620251, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22348, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.457245689655172 ], [ 29.75248506329114, -1.457515172413793 ], [ 29.752754556962024, -1.457515172413793 ], [ 29.752754556962024, -1.457245689655172 ], [ 29.75248506329114, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22349, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.457245689655172 ], [ 29.752754556962024, -1.457515172413793 ], [ 29.753024050632909, -1.457515172413793 ], [ 29.753024050632909, -1.457245689655172 ], [ 29.752754556962024, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22350, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.457245689655172 ], [ 29.753024050632909, -1.457515172413793 ], [ 29.753293544303798, -1.457515172413793 ], [ 29.753293544303798, -1.457245689655172 ], [ 29.753024050632909, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22351, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.457245689655172 ], [ 29.753293544303798, -1.457515172413793 ], [ 29.753563037974683, -1.457515172413793 ], [ 29.753563037974683, -1.457245689655172 ], [ 29.753293544303798, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22352, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.457245689655172 ], [ 29.753563037974683, -1.457515172413793 ], [ 29.753832531645568, -1.457515172413793 ], [ 29.753832531645568, -1.457245689655172 ], [ 29.753563037974683, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22353, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.457245689655172 ], [ 29.753832531645568, -1.457515172413793 ], [ 29.754102025316456, -1.457515172413793 ], [ 29.754102025316456, -1.457245689655172 ], [ 29.753832531645568, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22354, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.457245689655172 ], [ 29.754102025316456, -1.457515172413793 ], [ 29.754371518987341, -1.457515172413793 ], [ 29.754371518987341, -1.457245689655172 ], [ 29.754102025316456, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22355, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.457245689655172 ], [ 29.754371518987341, -1.457515172413793 ], [ 29.754641012658226, -1.457515172413793 ], [ 29.754641012658226, -1.457245689655172 ], [ 29.754371518987341, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22356, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.457245689655172 ], [ 29.754641012658226, -1.457515172413793 ], [ 29.754910506329114, -1.457515172413793 ], [ 29.754910506329114, -1.457245689655172 ], [ 29.754641012658226, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22357, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.457245689655172 ], [ 29.754910506329114, -1.457515172413793 ], [ 29.75518, -1.457515172413793 ], [ 29.75518, -1.457245689655172 ], [ 29.754910506329114, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22358, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.457245689655172 ], [ 29.75518, -1.457515172413793 ], [ 29.755449493670884, -1.457515172413793 ], [ 29.755449493670884, -1.457245689655172 ], [ 29.75518, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22359, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.457245689655172 ], [ 29.755449493670884, -1.457515172413793 ], [ 29.755718987341773, -1.457515172413793 ], [ 29.755718987341773, -1.457245689655172 ], [ 29.755449493670884, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22360, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.457245689655172 ], [ 29.755718987341773, -1.457515172413793 ], [ 29.755988481012658, -1.457515172413793 ], [ 29.755988481012658, -1.457245689655172 ], [ 29.755718987341773, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22361, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.457245689655172 ], [ 29.755988481012658, -1.457515172413793 ], [ 29.756257974683542, -1.457515172413793 ], [ 29.756257974683542, -1.457245689655172 ], [ 29.755988481012658, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22362, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.457245689655172 ], [ 29.756257974683542, -1.457515172413793 ], [ 29.756796962025316, -1.457515172413793 ], [ 29.756796962025316, -1.457245689655172 ], [ 29.756257974683542, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22363, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.457245689655172 ], [ 29.756796962025316, -1.457515172413793 ], [ 29.757066455696201, -1.457515172413793 ], [ 29.757066455696201, -1.457245689655172 ], [ 29.756796962025316, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22364, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.457245689655172 ], [ 29.757066455696201, -1.457515172413793 ], [ 29.757335949367089, -1.457515172413793 ], [ 29.757335949367089, -1.457245689655172 ], [ 29.757066455696201, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22365, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.457245689655172 ], [ 29.757335949367089, -1.457515172413793 ], [ 29.757605443037974, -1.457515172413793 ], [ 29.757605443037974, -1.457245689655172 ], [ 29.757335949367089, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22366, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.457245689655172 ], [ 29.757605443037974, -1.457515172413793 ], [ 29.757874936708859, -1.457515172413793 ], [ 29.757874936708859, -1.457245689655172 ], [ 29.757605443037974, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22367, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.457245689655172 ], [ 29.757874936708859, -1.457515172413793 ], [ 29.758144430379748, -1.457515172413793 ], [ 29.758144430379748, -1.457245689655172 ], [ 29.757874936708859, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22368, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.457245689655172 ], [ 29.758144430379748, -1.457515172413793 ], [ 29.758413924050632, -1.457515172413793 ], [ 29.758413924050632, -1.457245689655172 ], [ 29.758144430379748, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22369, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.457245689655172 ], [ 29.758413924050632, -1.457515172413793 ], [ 29.758683417721517, -1.457515172413793 ], [ 29.758683417721517, -1.457245689655172 ], [ 29.758413924050632, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22370, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.457245689655172 ], [ 29.758683417721517, -1.457515172413793 ], [ 29.758952911392406, -1.457515172413793 ], [ 29.758952911392406, -1.457245689655172 ], [ 29.758683417721517, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22371, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.457245689655172 ], [ 29.758952911392406, -1.457515172413793 ], [ 29.759222405063291, -1.457515172413793 ], [ 29.759222405063291, -1.457245689655172 ], [ 29.758952911392406, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22372, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.457245689655172 ], [ 29.759222405063291, -1.457515172413793 ], [ 29.759491898734176, -1.457515172413793 ], [ 29.759491898734176, -1.457245689655172 ], [ 29.759222405063291, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22373, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.457245689655172 ], [ 29.759491898734176, -1.457515172413793 ], [ 29.759761392405064, -1.457515172413793 ], [ 29.759761392405064, -1.457245689655172 ], [ 29.759491898734176, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22374, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.457245689655172 ], [ 29.759761392405064, -1.457515172413793 ], [ 29.760030886075949, -1.457515172413793 ], [ 29.760030886075949, -1.457245689655172 ], [ 29.759761392405064, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22375, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.457245689655172 ], [ 29.760030886075949, -1.457515172413793 ], [ 29.760300379746834, -1.457515172413793 ], [ 29.760300379746834, -1.457245689655172 ], [ 29.760030886075949, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22376, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.457245689655172 ], [ 29.760300379746834, -1.457515172413793 ], [ 29.760569873417722, -1.457515172413793 ], [ 29.760569873417722, -1.457245689655172 ], [ 29.760300379746834, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22377, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.457245689655172 ], [ 29.760569873417722, -1.457515172413793 ], [ 29.760839367088607, -1.457515172413793 ], [ 29.760839367088607, -1.457245689655172 ], [ 29.760569873417722, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22378, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.457245689655172 ], [ 29.760839367088607, -1.457515172413793 ], [ 29.761108860759492, -1.457515172413793 ], [ 29.761108860759492, -1.457245689655172 ], [ 29.760839367088607, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22379, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.457245689655172 ], [ 29.761108860759492, -1.457515172413793 ], [ 29.761378354430381, -1.457515172413793 ], [ 29.761378354430381, -1.457245689655172 ], [ 29.761108860759492, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22380, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.457245689655172 ], [ 29.761378354430381, -1.457515172413793 ], [ 29.761647848101266, -1.457515172413793 ], [ 29.761647848101266, -1.457245689655172 ], [ 29.761378354430381, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22381, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.457245689655172 ], [ 29.761647848101266, -1.457515172413793 ], [ 29.761917341772151, -1.457515172413793 ], [ 29.761917341772151, -1.457245689655172 ], [ 29.761647848101266, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22382, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.457245689655172 ], [ 29.761917341772151, -1.457515172413793 ], [ 29.762186835443039, -1.457515172413793 ], [ 29.762186835443039, -1.457245689655172 ], [ 29.761917341772151, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22383, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.457245689655172 ], [ 29.762186835443039, -1.457515172413793 ], [ 29.762456329113924, -1.457515172413793 ], [ 29.762456329113924, -1.457245689655172 ], [ 29.762186835443039, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22384, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.457245689655172 ], [ 29.762456329113924, -1.457515172413793 ], [ 29.763534303797467, -1.457515172413793 ], [ 29.763534303797467, -1.457245689655172 ], [ 29.762456329113924, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22385, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.457245689655172 ], [ 29.763534303797467, -1.457515172413793 ], [ 29.763803797468356, -1.457515172413793 ], [ 29.763803797468356, -1.457245689655172 ], [ 29.763534303797467, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22386, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.457245689655172 ], [ 29.763803797468356, -1.457515172413793 ], [ 29.76407329113924, -1.457515172413793 ], [ 29.76407329113924, -1.457245689655172 ], [ 29.763803797468356, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22387, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.457245689655172 ], [ 29.76407329113924, -1.457515172413793 ], [ 29.764342784810125, -1.457515172413793 ], [ 29.764342784810125, -1.457245689655172 ], [ 29.76407329113924, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22388, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.457245689655172 ], [ 29.764342784810125, -1.457515172413793 ], [ 29.764612278481014, -1.457515172413793 ], [ 29.764612278481014, -1.457245689655172 ], [ 29.764342784810125, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22389, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.457245689655172 ], [ 29.764612278481014, -1.457515172413793 ], [ 29.764881772151899, -1.457515172413793 ], [ 29.764881772151899, -1.457245689655172 ], [ 29.764612278481014, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22390, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.457245689655172 ], [ 29.764881772151899, -1.457515172413793 ], [ 29.765151265822784, -1.457515172413793 ], [ 29.765151265822784, -1.457245689655172 ], [ 29.764881772151899, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22391, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.457245689655172 ], [ 29.765151265822784, -1.457515172413793 ], [ 29.765420759493672, -1.457515172413793 ], [ 29.765420759493672, -1.457245689655172 ], [ 29.765151265822784, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22392, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.457245689655172 ], [ 29.765420759493672, -1.457515172413793 ], [ 29.765959746835442, -1.457515172413793 ], [ 29.765959746835442, -1.457245689655172 ], [ 29.765420759493672, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22393, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.457245689655172 ], [ 29.765959746835442, -1.457515172413793 ], [ 29.766229240506327, -1.457515172413793 ], [ 29.766229240506327, -1.457245689655172 ], [ 29.765959746835442, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22394, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.457245689655172 ], [ 29.766229240506327, -1.457515172413793 ], [ 29.766498734177215, -1.457515172413793 ], [ 29.766498734177215, -1.457245689655172 ], [ 29.766229240506327, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22395, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.457245689655172 ], [ 29.766498734177215, -1.457515172413793 ], [ 29.7667682278481, -1.457515172413793 ], [ 29.7667682278481, -1.457245689655172 ], [ 29.766498734177215, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22396, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.457245689655172 ], [ 29.7667682278481, -1.457515172413793 ], [ 29.767037721518985, -1.457515172413793 ], [ 29.767037721518985, -1.457245689655172 ], [ 29.7667682278481, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22397, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.457245689655172 ], [ 29.767037721518985, -1.457515172413793 ], [ 29.768654683544302, -1.457515172413793 ], [ 29.768654683544302, -1.457245689655172 ], [ 29.767037721518985, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22398, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.457245689655172 ], [ 29.768654683544302, -1.457515172413793 ], [ 29.76892417721519, -1.457515172413793 ], [ 29.76892417721519, -1.457245689655172 ], [ 29.768654683544302, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22399, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.457245689655172 ], [ 29.76892417721519, -1.457515172413793 ], [ 29.769193670886075, -1.457515172413793 ], [ 29.769193670886075, -1.457245689655172 ], [ 29.76892417721519, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22400, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.457245689655172 ], [ 29.769193670886075, -1.457515172413793 ], [ 29.76946316455696, -1.457515172413793 ], [ 29.76946316455696, -1.457245689655172 ], [ 29.769193670886075, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22401, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.457245689655172 ], [ 29.76946316455696, -1.457515172413793 ], [ 29.769732658227849, -1.457515172413793 ], [ 29.769732658227849, -1.457245689655172 ], [ 29.76946316455696, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22402, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.457245689655172 ], [ 29.769732658227849, -1.457515172413793 ], [ 29.770002151898733, -1.457515172413793 ], [ 29.770002151898733, -1.457245689655172 ], [ 29.769732658227849, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22403, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.457245689655172 ], [ 29.770002151898733, -1.457515172413793 ], [ 29.770271645569618, -1.457515172413793 ], [ 29.770271645569618, -1.457245689655172 ], [ 29.770002151898733, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22404, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.457245689655172 ], [ 29.770271645569618, -1.457515172413793 ], [ 29.770541139240507, -1.457515172413793 ], [ 29.770541139240507, -1.457245689655172 ], [ 29.770271645569618, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22405, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.457245689655172 ], [ 29.770541139240507, -1.457515172413793 ], [ 29.770810632911392, -1.457515172413793 ], [ 29.770810632911392, -1.457245689655172 ], [ 29.770541139240507, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22406, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.457245689655172 ], [ 29.770810632911392, -1.457515172413793 ], [ 29.771080126582277, -1.457515172413793 ], [ 29.771080126582277, -1.457245689655172 ], [ 29.770810632911392, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22407, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.457245689655172 ], [ 29.771080126582277, -1.457515172413793 ], [ 29.771349620253165, -1.457515172413793 ], [ 29.771349620253165, -1.457245689655172 ], [ 29.771080126582277, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22408, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.457245689655172 ], [ 29.771349620253165, -1.457515172413793 ], [ 29.77161911392405, -1.457515172413793 ], [ 29.77161911392405, -1.457245689655172 ], [ 29.771349620253165, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22409, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.457245689655172 ], [ 29.77161911392405, -1.457515172413793 ], [ 29.771888607594935, -1.457515172413793 ], [ 29.771888607594935, -1.457245689655172 ], [ 29.77161911392405, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22410, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.457245689655172 ], [ 29.771888607594935, -1.457515172413793 ], [ 29.772158101265823, -1.457515172413793 ], [ 29.772158101265823, -1.457245689655172 ], [ 29.771888607594935, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22411, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.457245689655172 ], [ 29.772158101265823, -1.457515172413793 ], [ 29.772427594936708, -1.457515172413793 ], [ 29.772427594936708, -1.457245689655172 ], [ 29.772158101265823, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22412, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.457245689655172 ], [ 29.772427594936708, -1.457515172413793 ], [ 29.772697088607593, -1.457515172413793 ], [ 29.772697088607593, -1.457245689655172 ], [ 29.772427594936708, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22413, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.457245689655172 ], [ 29.772697088607593, -1.457515172413793 ], [ 29.772966582278482, -1.457515172413793 ], [ 29.772966582278482, -1.457245689655172 ], [ 29.772697088607593, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22414, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.457245689655172 ], [ 29.772966582278482, -1.457515172413793 ], [ 29.773236075949367, -1.457515172413793 ], [ 29.773236075949367, -1.457245689655172 ], [ 29.772966582278482, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22415, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.457245689655172 ], [ 29.773236075949367, -1.457515172413793 ], [ 29.773505569620252, -1.457515172413793 ], [ 29.773505569620252, -1.457245689655172 ], [ 29.773236075949367, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22416, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.457245689655172 ], [ 29.773505569620252, -1.457515172413793 ], [ 29.77377506329114, -1.457515172413793 ], [ 29.77377506329114, -1.457245689655172 ], [ 29.773505569620252, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22417, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.457245689655172 ], [ 29.77377506329114, -1.457515172413793 ], [ 29.774044556962025, -1.457515172413793 ], [ 29.774044556962025, -1.457245689655172 ], [ 29.77377506329114, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22418, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.457245689655172 ], [ 29.774044556962025, -1.457515172413793 ], [ 29.77431405063291, -1.457515172413793 ], [ 29.77431405063291, -1.457245689655172 ], [ 29.774044556962025, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22419, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.457245689655172 ], [ 29.77431405063291, -1.457515172413793 ], [ 29.774853037974683, -1.457515172413793 ], [ 29.774853037974683, -1.457245689655172 ], [ 29.77431405063291, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22420, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.457245689655172 ], [ 29.774853037974683, -1.457515172413793 ], [ 29.775122531645568, -1.457515172413793 ], [ 29.775122531645568, -1.457245689655172 ], [ 29.774853037974683, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22421, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.457245689655172 ], [ 29.775122531645568, -1.457515172413793 ], [ 29.775392025316457, -1.457515172413793 ], [ 29.775392025316457, -1.457245689655172 ], [ 29.775122531645568, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22422, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.457245689655172 ], [ 29.775392025316457, -1.457515172413793 ], [ 29.775661518987341, -1.457515172413793 ], [ 29.775661518987341, -1.457245689655172 ], [ 29.775392025316457, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22423, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.457245689655172 ], [ 29.775661518987341, -1.457515172413793 ], [ 29.775931012658226, -1.457515172413793 ], [ 29.775931012658226, -1.457245689655172 ], [ 29.775661518987341, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22424, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.457245689655172 ], [ 29.775931012658226, -1.457515172413793 ], [ 29.776200506329115, -1.457515172413793 ], [ 29.776200506329115, -1.457245689655172 ], [ 29.775931012658226, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22425, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.457245689655172 ], [ 29.776200506329115, -1.457515172413793 ], [ 29.77647, -1.457515172413793 ], [ 29.77647, -1.457245689655172 ], [ 29.776200506329115, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22426, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.457245689655172 ], [ 29.77647, -1.457515172413793 ], [ 29.776739493670885, -1.457515172413793 ], [ 29.776739493670885, -1.457245689655172 ], [ 29.77647, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22427, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.457245689655172 ], [ 29.776739493670885, -1.457515172413793 ], [ 29.777008987341773, -1.457515172413793 ], [ 29.777008987341773, -1.457245689655172 ], [ 29.776739493670885, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22428, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.457245689655172 ], [ 29.777008987341773, -1.457515172413793 ], [ 29.777278481012658, -1.457515172413793 ], [ 29.777278481012658, -1.457245689655172 ], [ 29.777008987341773, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22429, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.457245689655172 ], [ 29.777278481012658, -1.457515172413793 ], [ 29.777547974683543, -1.457515172413793 ], [ 29.777547974683543, -1.457245689655172 ], [ 29.777278481012658, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22430, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.457245689655172 ], [ 29.777547974683543, -1.457515172413793 ], [ 29.777817468354431, -1.457515172413793 ], [ 29.777817468354431, -1.457245689655172 ], [ 29.777547974683543, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22431, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.457245689655172 ], [ 29.777817468354431, -1.457515172413793 ], [ 29.778086962025316, -1.457515172413793 ], [ 29.778086962025316, -1.457245689655172 ], [ 29.777817468354431, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22432, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.457245689655172 ], [ 29.778086962025316, -1.457515172413793 ], [ 29.778356455696201, -1.457515172413793 ], [ 29.778356455696201, -1.457245689655172 ], [ 29.778086962025316, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22433, "El": 124 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.457245689655172 ], [ 29.778356455696201, -1.457515172413793 ], [ 29.77862594936709, -1.457515172413793 ], [ 29.77862594936709, -1.457245689655172 ], [ 29.778356455696201, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22434, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.457245689655172 ], [ 29.778895443037975, -1.458593103448276 ], [ 29.77916493670886, -1.458593103448276 ], [ 29.77916493670886, -1.457245689655172 ], [ 29.778895443037975, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22435, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.457245689655172 ], [ 29.77916493670886, -1.458593103448276 ], [ 29.779434430379748, -1.458593103448276 ], [ 29.779434430379748, -1.457245689655172 ], [ 29.77916493670886, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22436, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.457245689655172 ], [ 29.779434430379748, -1.458593103448276 ], [ 29.779703924050633, -1.458593103448276 ], [ 29.779703924050633, -1.457245689655172 ], [ 29.779434430379748, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22437, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.457245689655172 ], [ 29.796143037974684, -1.457515172413793 ], [ 29.796412531645569, -1.457515172413793 ], [ 29.796412531645569, -1.457245689655172 ], [ 29.796143037974684, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22438, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.457245689655172 ], [ 29.796412531645569, -1.457515172413793 ], [ 29.796682025316457, -1.457515172413793 ], [ 29.796682025316457, -1.457245689655172 ], [ 29.796412531645569, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22439, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.457245689655172 ], [ 29.796682025316457, -1.457515172413793 ], [ 29.796951518987342, -1.457515172413793 ], [ 29.796951518987342, -1.457245689655172 ], [ 29.796682025316457, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22440, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.457245689655172 ], [ 29.796951518987342, -1.457515172413793 ], [ 29.797221012658227, -1.457515172413793 ], [ 29.797221012658227, -1.457245689655172 ], [ 29.796951518987342, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22441, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.457245689655172 ], [ 29.797221012658227, -1.457515172413793 ], [ 29.797490506329115, -1.457515172413793 ], [ 29.797490506329115, -1.457245689655172 ], [ 29.797221012658227, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22442, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.457245689655172 ], [ 29.797490506329115, -1.457515172413793 ], [ 29.79776, -1.457515172413793 ], [ 29.79776, -1.457245689655172 ], [ 29.797490506329115, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22443, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.457245689655172 ], [ 29.79776, -1.457515172413793 ], [ 29.798029493670885, -1.457515172413793 ], [ 29.798029493670885, -1.457245689655172 ], [ 29.79776, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22444, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.457245689655172 ], [ 29.798029493670885, -1.457515172413793 ], [ 29.798298987341774, -1.457515172413793 ], [ 29.798298987341774, -1.457245689655172 ], [ 29.798029493670885, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22445, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.457245689655172 ], [ 29.798298987341774, -1.457515172413793 ], [ 29.798568481012659, -1.457515172413793 ], [ 29.798568481012659, -1.457245689655172 ], [ 29.798298987341774, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22446, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.457245689655172 ], [ 29.798568481012659, -1.457784655172414 ], [ 29.798837974683543, -1.457784655172414 ], [ 29.798837974683543, -1.457245689655172 ], [ 29.798568481012659, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22447, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.457245689655172 ], [ 29.799376962025317, -1.457515172413793 ], [ 29.799646455696202, -1.457515172413793 ], [ 29.799646455696202, -1.457245689655172 ], [ 29.799376962025317, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22448, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.457245689655172 ], [ 29.799646455696202, -1.457515172413793 ], [ 29.79991594936709, -1.457515172413793 ], [ 29.79991594936709, -1.457245689655172 ], [ 29.799646455696202, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22449, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.457245689655172 ], [ 29.79991594936709, -1.457515172413793 ], [ 29.800185443037975, -1.457515172413793 ], [ 29.800185443037975, -1.457245689655172 ], [ 29.79991594936709, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22450, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.457245689655172 ], [ 29.800185443037975, -1.458054137931035 ], [ 29.80045493670886, -1.458054137931035 ], [ 29.80045493670886, -1.457245689655172 ], [ 29.800185443037975, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22451, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.457245689655172 ], [ 29.808270253164558, -1.457515172413793 ], [ 29.808539746835443, -1.457515172413793 ], [ 29.808539746835443, -1.457245689655172 ], [ 29.808270253164558, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22452, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.457245689655172 ], [ 29.808539746835443, -1.457515172413793 ], [ 29.808809240506328, -1.457515172413793 ], [ 29.808809240506328, -1.457245689655172 ], [ 29.808539746835443, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22453, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.457245689655172 ], [ 29.808809240506328, -1.457515172413793 ], [ 29.809078734177216, -1.457515172413793 ], [ 29.809078734177216, -1.457245689655172 ], [ 29.808809240506328, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22454, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.457245689655172 ], [ 29.809078734177216, -1.457515172413793 ], [ 29.809348227848101, -1.457515172413793 ], [ 29.809348227848101, -1.457245689655172 ], [ 29.809078734177216, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22455, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.456976206896552 ], [ 29.809887215189875, -1.456976206896552 ], [ 29.809887215189875, -1.457515172413793 ], [ 29.809348227848101, -1.457515172413793 ], [ 29.809348227848101, -1.457245689655172 ], [ 29.809617721518986, -1.457245689655172 ], [ 29.809617721518986, -1.456976206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22456, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.457245689655172 ], [ 29.809887215189875, -1.457515172413793 ], [ 29.81015670886076, -1.457515172413793 ], [ 29.81015670886076, -1.457245689655172 ], [ 29.809887215189875, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22457, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.457245689655172 ], [ 29.81015670886076, -1.457515172413793 ], [ 29.810426202531644, -1.457515172413793 ], [ 29.810426202531644, -1.457245689655172 ], [ 29.81015670886076, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22458, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.457245689655172 ], [ 29.810426202531644, -1.457515172413793 ], [ 29.810965189873418, -1.457515172413793 ], [ 29.810965189873418, -1.457245689655172 ], [ 29.810426202531644, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22459, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.457245689655172 ], [ 29.810965189873418, -1.457515172413793 ], [ 29.811234683544303, -1.457515172413793 ], [ 29.811234683544303, -1.457245689655172 ], [ 29.810965189873418, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22460, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.457245689655172 ], [ 29.811234683544303, -1.457515172413793 ], [ 29.811504177215191, -1.457515172413793 ], [ 29.811504177215191, -1.457245689655172 ], [ 29.811234683544303, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22461, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.457245689655172 ], [ 29.811504177215191, -1.457515172413793 ], [ 29.811773670886076, -1.457515172413793 ], [ 29.811773670886076, -1.457245689655172 ], [ 29.811504177215191, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22462, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.457245689655172 ], [ 29.811773670886076, -1.457515172413793 ], [ 29.812043164556961, -1.457515172413793 ], [ 29.812043164556961, -1.457245689655172 ], [ 29.811773670886076, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22463, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.457245689655172 ], [ 29.812043164556961, -1.457515172413793 ], [ 29.812312658227849, -1.457515172413793 ], [ 29.812312658227849, -1.457245689655172 ], [ 29.812043164556961, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22464, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.457245689655172 ], [ 29.812312658227849, -1.457515172413793 ], [ 29.812582151898734, -1.457515172413793 ], [ 29.812582151898734, -1.457245689655172 ], [ 29.812312658227849, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22465, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.457245689655172 ], [ 29.812582151898734, -1.457515172413793 ], [ 29.813121139240508, -1.457515172413793 ], [ 29.813121139240508, -1.457245689655172 ], [ 29.812582151898734, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22466, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.457245689655172 ], [ 29.813121139240508, -1.457515172413793 ], [ 29.813390632911393, -1.457515172413793 ], [ 29.813390632911393, -1.457245689655172 ], [ 29.813121139240508, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22467, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.457245689655172 ], [ 29.813390632911393, -1.457515172413793 ], [ 29.813660126582278, -1.457515172413793 ], [ 29.813660126582278, -1.457245689655172 ], [ 29.813390632911393, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22468, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.457245689655172 ], [ 29.813660126582278, -1.457515172413793 ], [ 29.813929620253166, -1.457515172413793 ], [ 29.813929620253166, -1.457245689655172 ], [ 29.813660126582278, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22469, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.457245689655172 ], [ 29.813929620253166, -1.457515172413793 ], [ 29.814199113924051, -1.457515172413793 ], [ 29.814199113924051, -1.457245689655172 ], [ 29.813929620253166, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22470, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.457245689655172 ], [ 29.814199113924051, -1.457515172413793 ], [ 29.814468607594936, -1.457515172413793 ], [ 29.814468607594936, -1.457245689655172 ], [ 29.814199113924051, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22471, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.457245689655172 ], [ 29.814468607594936, -1.457515172413793 ], [ 29.814738101265824, -1.457515172413793 ], [ 29.814738101265824, -1.457245689655172 ], [ 29.814468607594936, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.457515172413793 ], [ 29.751676582278481, -1.457784655172414 ], [ 29.751946075949366, -1.457784655172414 ], [ 29.751946075949366, -1.457515172413793 ], [ 29.751676582278481, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22473, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.457515172413793 ], [ 29.751946075949366, -1.457784655172414 ], [ 29.752215569620251, -1.457784655172414 ], [ 29.752215569620251, -1.457515172413793 ], [ 29.751946075949366, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22474, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.457515172413793 ], [ 29.752215569620251, -1.457784655172414 ], [ 29.75248506329114, -1.457784655172414 ], [ 29.75248506329114, -1.457515172413793 ], [ 29.752215569620251, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22475, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.457515172413793 ], [ 29.75248506329114, -1.457784655172414 ], [ 29.752754556962024, -1.457784655172414 ], [ 29.752754556962024, -1.457515172413793 ], [ 29.75248506329114, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22476, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.457515172413793 ], [ 29.752754556962024, -1.457784655172414 ], [ 29.753024050632909, -1.457784655172414 ], [ 29.753024050632909, -1.457515172413793 ], [ 29.752754556962024, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22477, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.457515172413793 ], [ 29.753024050632909, -1.457784655172414 ], [ 29.753293544303798, -1.457784655172414 ], [ 29.753293544303798, -1.457515172413793 ], [ 29.753024050632909, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22478, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.457515172413793 ], [ 29.753293544303798, -1.457784655172414 ], [ 29.753563037974683, -1.457784655172414 ], [ 29.753563037974683, -1.457515172413793 ], [ 29.753293544303798, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22479, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.457515172413793 ], [ 29.753563037974683, -1.457784655172414 ], [ 29.753832531645568, -1.457784655172414 ], [ 29.753832531645568, -1.457515172413793 ], [ 29.753563037974683, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22480, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.457515172413793 ], [ 29.753832531645568, -1.457784655172414 ], [ 29.754102025316456, -1.457784655172414 ], [ 29.754102025316456, -1.457515172413793 ], [ 29.753832531645568, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22481, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.457515172413793 ], [ 29.754102025316456, -1.457784655172414 ], [ 29.754371518987341, -1.457784655172414 ], [ 29.754371518987341, -1.457515172413793 ], [ 29.754102025316456, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22482, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.457515172413793 ], [ 29.754371518987341, -1.457784655172414 ], [ 29.754641012658226, -1.457784655172414 ], [ 29.754641012658226, -1.457515172413793 ], [ 29.754371518987341, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22483, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.457515172413793 ], [ 29.754641012658226, -1.457784655172414 ], [ 29.754910506329114, -1.457784655172414 ], [ 29.754910506329114, -1.457515172413793 ], [ 29.754641012658226, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22484, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.457515172413793 ], [ 29.754910506329114, -1.457784655172414 ], [ 29.75518, -1.457784655172414 ], [ 29.75518, -1.457515172413793 ], [ 29.754910506329114, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22485, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.457515172413793 ], [ 29.75518, -1.457784655172414 ], [ 29.755449493670884, -1.457784655172414 ], [ 29.755449493670884, -1.457515172413793 ], [ 29.75518, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22486, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.457515172413793 ], [ 29.755449493670884, -1.457784655172414 ], [ 29.755718987341773, -1.457784655172414 ], [ 29.755718987341773, -1.457515172413793 ], [ 29.755449493670884, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22487, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.457515172413793 ], [ 29.755718987341773, -1.457784655172414 ], [ 29.755988481012658, -1.457784655172414 ], [ 29.755988481012658, -1.457515172413793 ], [ 29.755718987341773, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22488, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.457515172413793 ], [ 29.755988481012658, -1.457784655172414 ], [ 29.756257974683542, -1.457784655172414 ], [ 29.756257974683542, -1.457515172413793 ], [ 29.755988481012658, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22489, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.457515172413793 ], [ 29.756257974683542, -1.457784655172414 ], [ 29.756796962025316, -1.457784655172414 ], [ 29.756796962025316, -1.457515172413793 ], [ 29.756257974683542, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22490, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.457515172413793 ], [ 29.756796962025316, -1.457784655172414 ], [ 29.757066455696201, -1.457784655172414 ], [ 29.757066455696201, -1.457515172413793 ], [ 29.756796962025316, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22491, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.457515172413793 ], [ 29.757066455696201, -1.457784655172414 ], [ 29.757335949367089, -1.457784655172414 ], [ 29.757335949367089, -1.457515172413793 ], [ 29.757066455696201, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22492, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.457515172413793 ], [ 29.757335949367089, -1.457784655172414 ], [ 29.757605443037974, -1.457784655172414 ], [ 29.757605443037974, -1.457515172413793 ], [ 29.757335949367089, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22493, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.457515172413793 ], [ 29.757605443037974, -1.457784655172414 ], [ 29.757874936708859, -1.457784655172414 ], [ 29.757874936708859, -1.457515172413793 ], [ 29.757605443037974, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22494, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.457515172413793 ], [ 29.757874936708859, -1.457784655172414 ], [ 29.758144430379748, -1.457784655172414 ], [ 29.758144430379748, -1.457515172413793 ], [ 29.757874936708859, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22495, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.457515172413793 ], [ 29.758144430379748, -1.457784655172414 ], [ 29.758413924050632, -1.457784655172414 ], [ 29.758413924050632, -1.457515172413793 ], [ 29.758144430379748, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22496, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.457515172413793 ], [ 29.758413924050632, -1.457784655172414 ], [ 29.758683417721517, -1.457784655172414 ], [ 29.758683417721517, -1.457515172413793 ], [ 29.758413924050632, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22497, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.457515172413793 ], [ 29.758683417721517, -1.457784655172414 ], [ 29.758952911392406, -1.457784655172414 ], [ 29.758952911392406, -1.457515172413793 ], [ 29.758683417721517, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22498, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.457515172413793 ], [ 29.758952911392406, -1.457784655172414 ], [ 29.759222405063291, -1.457784655172414 ], [ 29.759222405063291, -1.457515172413793 ], [ 29.758952911392406, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22499, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.457515172413793 ], [ 29.759222405063291, -1.457784655172414 ], [ 29.759491898734176, -1.457784655172414 ], [ 29.759491898734176, -1.457515172413793 ], [ 29.759222405063291, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22500, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.457515172413793 ], [ 29.759491898734176, -1.457784655172414 ], [ 29.759761392405064, -1.457784655172414 ], [ 29.759761392405064, -1.457515172413793 ], [ 29.759491898734176, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22501, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.457515172413793 ], [ 29.759761392405064, -1.457784655172414 ], [ 29.760030886075949, -1.457784655172414 ], [ 29.760030886075949, -1.457515172413793 ], [ 29.759761392405064, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22502, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.457515172413793 ], [ 29.760030886075949, -1.457784655172414 ], [ 29.760300379746834, -1.457784655172414 ], [ 29.760300379746834, -1.457515172413793 ], [ 29.760030886075949, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22503, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.457515172413793 ], [ 29.760300379746834, -1.457784655172414 ], [ 29.760569873417722, -1.457784655172414 ], [ 29.760569873417722, -1.457515172413793 ], [ 29.760300379746834, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22504, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.457515172413793 ], [ 29.760569873417722, -1.457784655172414 ], [ 29.760839367088607, -1.457784655172414 ], [ 29.760839367088607, -1.457515172413793 ], [ 29.760569873417722, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22505, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.457515172413793 ], [ 29.760839367088607, -1.457784655172414 ], [ 29.761108860759492, -1.457784655172414 ], [ 29.761108860759492, -1.457515172413793 ], [ 29.760839367088607, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22506, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.457515172413793 ], [ 29.761108860759492, -1.457784655172414 ], [ 29.761378354430381, -1.457784655172414 ], [ 29.761378354430381, -1.457515172413793 ], [ 29.761108860759492, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22507, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.457515172413793 ], [ 29.761378354430381, -1.457784655172414 ], [ 29.761647848101266, -1.457784655172414 ], [ 29.761647848101266, -1.457515172413793 ], [ 29.761378354430381, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22508, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.457515172413793 ], [ 29.761647848101266, -1.457784655172414 ], [ 29.761917341772151, -1.457784655172414 ], [ 29.761917341772151, -1.457515172413793 ], [ 29.761647848101266, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22509, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.457515172413793 ], [ 29.761917341772151, -1.457784655172414 ], [ 29.762186835443039, -1.457784655172414 ], [ 29.762186835443039, -1.457515172413793 ], [ 29.761917341772151, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22510, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.457515172413793 ], [ 29.762186835443039, -1.457784655172414 ], [ 29.763803797468356, -1.457784655172414 ], [ 29.763803797468356, -1.457515172413793 ], [ 29.762186835443039, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22511, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.457515172413793 ], [ 29.763803797468356, -1.457784655172414 ], [ 29.76407329113924, -1.457784655172414 ], [ 29.76407329113924, -1.457515172413793 ], [ 29.763803797468356, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22512, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.457515172413793 ], [ 29.76407329113924, -1.457784655172414 ], [ 29.764342784810125, -1.457784655172414 ], [ 29.764342784810125, -1.457515172413793 ], [ 29.76407329113924, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22513, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.457515172413793 ], [ 29.764342784810125, -1.457784655172414 ], [ 29.764612278481014, -1.457784655172414 ], [ 29.764612278481014, -1.457515172413793 ], [ 29.764342784810125, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22514, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.457515172413793 ], [ 29.764612278481014, -1.457784655172414 ], [ 29.764881772151899, -1.457784655172414 ], [ 29.764881772151899, -1.457515172413793 ], [ 29.764612278481014, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22515, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.457515172413793 ], [ 29.764881772151899, -1.457784655172414 ], [ 29.765151265822784, -1.457784655172414 ], [ 29.765151265822784, -1.457515172413793 ], [ 29.764881772151899, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22516, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.457515172413793 ], [ 29.765151265822784, -1.457784655172414 ], [ 29.765420759493672, -1.457784655172414 ], [ 29.765420759493672, -1.457515172413793 ], [ 29.765151265822784, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22517, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.457515172413793 ], [ 29.765420759493672, -1.457784655172414 ], [ 29.766229240506327, -1.457784655172414 ], [ 29.766229240506327, -1.457515172413793 ], [ 29.765420759493672, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22518, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.457515172413793 ], [ 29.766229240506327, -1.457784655172414 ], [ 29.766498734177215, -1.457784655172414 ], [ 29.766498734177215, -1.457515172413793 ], [ 29.766229240506327, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22519, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.457515172413793 ], [ 29.766498734177215, -1.457784655172414 ], [ 29.7667682278481, -1.457784655172414 ], [ 29.7667682278481, -1.457515172413793 ], [ 29.766498734177215, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22520, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.457515172413793 ], [ 29.7667682278481, -1.457784655172414 ], [ 29.76892417721519, -1.457784655172414 ], [ 29.76892417721519, -1.457515172413793 ], [ 29.7667682278481, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22521, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.457515172413793 ], [ 29.76892417721519, -1.457784655172414 ], [ 29.769193670886075, -1.457784655172414 ], [ 29.769193670886075, -1.457515172413793 ], [ 29.76892417721519, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22522, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.457515172413793 ], [ 29.769193670886075, -1.457784655172414 ], [ 29.76946316455696, -1.457784655172414 ], [ 29.76946316455696, -1.457515172413793 ], [ 29.769193670886075, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22523, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.457515172413793 ], [ 29.76946316455696, -1.457784655172414 ], [ 29.769732658227849, -1.457784655172414 ], [ 29.769732658227849, -1.457515172413793 ], [ 29.76946316455696, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22524, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.457515172413793 ], [ 29.769732658227849, -1.457784655172414 ], [ 29.770002151898733, -1.457784655172414 ], [ 29.770002151898733, -1.457515172413793 ], [ 29.769732658227849, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22525, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.457515172413793 ], [ 29.770002151898733, -1.457784655172414 ], [ 29.770271645569618, -1.457784655172414 ], [ 29.770271645569618, -1.457515172413793 ], [ 29.770002151898733, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22526, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.457515172413793 ], [ 29.770271645569618, -1.457784655172414 ], [ 29.770541139240507, -1.457784655172414 ], [ 29.770541139240507, -1.457515172413793 ], [ 29.770271645569618, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22527, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.457515172413793 ], [ 29.770541139240507, -1.457784655172414 ], [ 29.770810632911392, -1.457784655172414 ], [ 29.770810632911392, -1.457515172413793 ], [ 29.770541139240507, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22528, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.457515172413793 ], [ 29.770810632911392, -1.457784655172414 ], [ 29.771080126582277, -1.457784655172414 ], [ 29.771080126582277, -1.457515172413793 ], [ 29.770810632911392, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22529, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.457515172413793 ], [ 29.771080126582277, -1.457784655172414 ], [ 29.771349620253165, -1.457784655172414 ], [ 29.771349620253165, -1.457515172413793 ], [ 29.771080126582277, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22530, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.457515172413793 ], [ 29.771349620253165, -1.457784655172414 ], [ 29.77161911392405, -1.457784655172414 ], [ 29.77161911392405, -1.457515172413793 ], [ 29.771349620253165, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22531, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.457515172413793 ], [ 29.77161911392405, -1.457784655172414 ], [ 29.771888607594935, -1.457784655172414 ], [ 29.771888607594935, -1.457515172413793 ], [ 29.77161911392405, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22532, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.457515172413793 ], [ 29.771888607594935, -1.457784655172414 ], [ 29.772158101265823, -1.457784655172414 ], [ 29.772158101265823, -1.457515172413793 ], [ 29.771888607594935, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22533, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.457515172413793 ], [ 29.772158101265823, -1.457784655172414 ], [ 29.772427594936708, -1.457784655172414 ], [ 29.772427594936708, -1.457515172413793 ], [ 29.772158101265823, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22534, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.457515172413793 ], [ 29.772427594936708, -1.457784655172414 ], [ 29.772697088607593, -1.457784655172414 ], [ 29.772697088607593, -1.457515172413793 ], [ 29.772427594936708, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22535, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.457515172413793 ], [ 29.772697088607593, -1.457784655172414 ], [ 29.772966582278482, -1.457784655172414 ], [ 29.772966582278482, -1.457515172413793 ], [ 29.772697088607593, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22536, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.457515172413793 ], [ 29.772966582278482, -1.457784655172414 ], [ 29.773236075949367, -1.457784655172414 ], [ 29.773236075949367, -1.457515172413793 ], [ 29.772966582278482, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22537, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.457515172413793 ], [ 29.773236075949367, -1.457784655172414 ], [ 29.773505569620252, -1.457784655172414 ], [ 29.773505569620252, -1.457515172413793 ], [ 29.773236075949367, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22538, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.457515172413793 ], [ 29.773505569620252, -1.457784655172414 ], [ 29.77377506329114, -1.457784655172414 ], [ 29.77377506329114, -1.457515172413793 ], [ 29.773505569620252, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22539, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.457515172413793 ], [ 29.77377506329114, -1.457784655172414 ], [ 29.774044556962025, -1.457784655172414 ], [ 29.774044556962025, -1.457515172413793 ], [ 29.77377506329114, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22540, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.457515172413793 ], [ 29.774044556962025, -1.457784655172414 ], [ 29.77431405063291, -1.457784655172414 ], [ 29.77431405063291, -1.457515172413793 ], [ 29.774044556962025, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22541, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.457515172413793 ], [ 29.77431405063291, -1.457784655172414 ], [ 29.774853037974683, -1.457784655172414 ], [ 29.774853037974683, -1.457515172413793 ], [ 29.77431405063291, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22542, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.457515172413793 ], [ 29.774853037974683, -1.457784655172414 ], [ 29.775122531645568, -1.457784655172414 ], [ 29.775122531645568, -1.457515172413793 ], [ 29.774853037974683, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22543, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.457515172413793 ], [ 29.775122531645568, -1.457784655172414 ], [ 29.775392025316457, -1.457784655172414 ], [ 29.775392025316457, -1.457515172413793 ], [ 29.775122531645568, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22544, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.457515172413793 ], [ 29.775392025316457, -1.457784655172414 ], [ 29.775661518987341, -1.457784655172414 ], [ 29.775661518987341, -1.457515172413793 ], [ 29.775392025316457, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22545, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.457515172413793 ], [ 29.775661518987341, -1.457784655172414 ], [ 29.775931012658226, -1.457784655172414 ], [ 29.775931012658226, -1.457515172413793 ], [ 29.775661518987341, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22546, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.457515172413793 ], [ 29.775931012658226, -1.457784655172414 ], [ 29.776200506329115, -1.457784655172414 ], [ 29.776200506329115, -1.457515172413793 ], [ 29.775931012658226, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22547, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.457515172413793 ], [ 29.776200506329115, -1.457784655172414 ], [ 29.77647, -1.457784655172414 ], [ 29.77647, -1.457515172413793 ], [ 29.776200506329115, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22548, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.457515172413793 ], [ 29.77647, -1.457784655172414 ], [ 29.776739493670885, -1.457784655172414 ], [ 29.776739493670885, -1.457515172413793 ], [ 29.77647, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22549, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.457515172413793 ], [ 29.776739493670885, -1.457784655172414 ], [ 29.777008987341773, -1.457784655172414 ], [ 29.777008987341773, -1.457515172413793 ], [ 29.776739493670885, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22550, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.457515172413793 ], [ 29.777008987341773, -1.457784655172414 ], [ 29.777278481012658, -1.457784655172414 ], [ 29.777278481012658, -1.457515172413793 ], [ 29.777008987341773, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22551, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.457515172413793 ], [ 29.777278481012658, -1.457784655172414 ], [ 29.777547974683543, -1.457784655172414 ], [ 29.777547974683543, -1.457515172413793 ], [ 29.777278481012658, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22552, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.457515172413793 ], [ 29.777547974683543, -1.457784655172414 ], [ 29.777817468354431, -1.457784655172414 ], [ 29.777817468354431, -1.457515172413793 ], [ 29.777547974683543, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22553, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.457515172413793 ], [ 29.777817468354431, -1.457784655172414 ], [ 29.778086962025316, -1.457784655172414 ], [ 29.778086962025316, -1.457515172413793 ], [ 29.777817468354431, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22554, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.457515172413793 ], [ 29.778086962025316, -1.457784655172414 ], [ 29.778356455696201, -1.457784655172414 ], [ 29.778356455696201, -1.457515172413793 ], [ 29.778086962025316, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22555, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.457515172413793 ], [ 29.778356455696201, -1.457784655172414 ], [ 29.77862594936709, -1.457784655172414 ], [ 29.77862594936709, -1.457515172413793 ], [ 29.778356455696201, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22556, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.457515172413793 ], [ 29.79560405063291, -1.457784655172414 ], [ 29.795873544303799, -1.457784655172414 ], [ 29.795873544303799, -1.457515172413793 ], [ 29.79560405063291, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22557, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.457515172413793 ], [ 29.795873544303799, -1.457784655172414 ], [ 29.796143037974684, -1.457784655172414 ], [ 29.796143037974684, -1.457515172413793 ], [ 29.795873544303799, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22558, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.457515172413793 ], [ 29.796143037974684, -1.457784655172414 ], [ 29.796412531645569, -1.457784655172414 ], [ 29.796412531645569, -1.457515172413793 ], [ 29.796143037974684, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22559, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.457515172413793 ], [ 29.796412531645569, -1.457784655172414 ], [ 29.796682025316457, -1.457784655172414 ], [ 29.796682025316457, -1.457515172413793 ], [ 29.796412531645569, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22560, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.457515172413793 ], [ 29.796682025316457, -1.457784655172414 ], [ 29.796951518987342, -1.457784655172414 ], [ 29.796951518987342, -1.457515172413793 ], [ 29.796682025316457, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22561, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.457515172413793 ], [ 29.796951518987342, -1.457784655172414 ], [ 29.797221012658227, -1.457784655172414 ], [ 29.797221012658227, -1.457515172413793 ], [ 29.796951518987342, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22562, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.457515172413793 ], [ 29.797221012658227, -1.457784655172414 ], [ 29.797490506329115, -1.457784655172414 ], [ 29.797490506329115, -1.457515172413793 ], [ 29.797221012658227, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22563, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.457515172413793 ], [ 29.797490506329115, -1.458054137931035 ], [ 29.79776, -1.458054137931035 ], [ 29.79776, -1.457515172413793 ], [ 29.797490506329115, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22564, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.457515172413793 ], [ 29.79776, -1.457784655172414 ], [ 29.798029493670885, -1.457784655172414 ], [ 29.798029493670885, -1.457515172413793 ], [ 29.79776, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22565, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.457515172413793 ], [ 29.798029493670885, -1.457784655172414 ], [ 29.798298987341774, -1.457784655172414 ], [ 29.798298987341774, -1.457515172413793 ], [ 29.798029493670885, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22566, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.457515172413793 ], [ 29.798298987341774, -1.457784655172414 ], [ 29.798568481012659, -1.457784655172414 ], [ 29.798568481012659, -1.457515172413793 ], [ 29.798298987341774, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22567, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.457515172413793 ], [ 29.798837974683543, -1.457784655172414 ], [ 29.799107468354432, -1.457784655172414 ], [ 29.799107468354432, -1.457515172413793 ], [ 29.798837974683543, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22568, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.457515172413793 ], [ 29.799107468354432, -1.457784655172414 ], [ 29.799376962025317, -1.457784655172414 ], [ 29.799376962025317, -1.457515172413793 ], [ 29.799107468354432, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22569, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.457515172413793 ], [ 29.799376962025317, -1.457784655172414 ], [ 29.799646455696202, -1.457784655172414 ], [ 29.799646455696202, -1.457515172413793 ], [ 29.799376962025317, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22570, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.457515172413793 ], [ 29.799646455696202, -1.458054137931035 ], [ 29.79991594936709, -1.458054137931035 ], [ 29.79991594936709, -1.457515172413793 ], [ 29.799646455696202, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22571, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.457515172413793 ], [ 29.79991594936709, -1.458054137931035 ], [ 29.800185443037975, -1.458054137931035 ], [ 29.800185443037975, -1.457515172413793 ], [ 29.79991594936709, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22572, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.457515172413793 ], [ 29.808000759493673, -1.457784655172414 ], [ 29.808270253164558, -1.457784655172414 ], [ 29.808270253164558, -1.457515172413793 ], [ 29.808000759493673, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22573, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.457515172413793 ], [ 29.808270253164558, -1.457784655172414 ], [ 29.808539746835443, -1.457784655172414 ], [ 29.808539746835443, -1.457515172413793 ], [ 29.808270253164558, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22574, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.457515172413793 ], [ 29.808539746835443, -1.457784655172414 ], [ 29.808809240506328, -1.457784655172414 ], [ 29.808809240506328, -1.457515172413793 ], [ 29.808539746835443, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22575, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809078734177216, -1.457515172413793 ], [ 29.809078734177216, -1.457784655172414 ], [ 29.809348227848101, -1.457784655172414 ], [ 29.809348227848101, -1.457515172413793 ], [ 29.809078734177216, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22576, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.457515172413793 ], [ 29.809348227848101, -1.457784655172414 ], [ 29.809617721518986, -1.457784655172414 ], [ 29.809617721518986, -1.457515172413793 ], [ 29.809348227848101, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22577, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.457515172413793 ], [ 29.809617721518986, -1.457784655172414 ], [ 29.809887215189875, -1.457784655172414 ], [ 29.809887215189875, -1.457515172413793 ], [ 29.809617721518986, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22578, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.457515172413793 ], [ 29.809887215189875, -1.457784655172414 ], [ 29.81015670886076, -1.457784655172414 ], [ 29.81015670886076, -1.457515172413793 ], [ 29.809887215189875, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22579, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.457515172413793 ], [ 29.81015670886076, -1.457784655172414 ], [ 29.810426202531644, -1.457784655172414 ], [ 29.810426202531644, -1.457515172413793 ], [ 29.81015670886076, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22580, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.457515172413793 ], [ 29.810426202531644, -1.457784655172414 ], [ 29.810965189873418, -1.457784655172414 ], [ 29.810965189873418, -1.457515172413793 ], [ 29.810426202531644, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22581, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.457515172413793 ], [ 29.810965189873418, -1.457784655172414 ], [ 29.811234683544303, -1.457784655172414 ], [ 29.811234683544303, -1.457515172413793 ], [ 29.810965189873418, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22582, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.457515172413793 ], [ 29.811234683544303, -1.457784655172414 ], [ 29.811504177215191, -1.457784655172414 ], [ 29.811504177215191, -1.457515172413793 ], [ 29.811234683544303, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22583, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.457515172413793 ], [ 29.811504177215191, -1.457784655172414 ], [ 29.811773670886076, -1.457784655172414 ], [ 29.811773670886076, -1.457515172413793 ], [ 29.811504177215191, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22584, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.457515172413793 ], [ 29.811773670886076, -1.457784655172414 ], [ 29.812043164556961, -1.457784655172414 ], [ 29.812043164556961, -1.457515172413793 ], [ 29.811773670886076, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22585, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.457515172413793 ], [ 29.812043164556961, -1.457784655172414 ], [ 29.812312658227849, -1.457784655172414 ], [ 29.812312658227849, -1.457515172413793 ], [ 29.812043164556961, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22586, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.457515172413793 ], [ 29.812312658227849, -1.457784655172414 ], [ 29.812582151898734, -1.457784655172414 ], [ 29.812582151898734, -1.457515172413793 ], [ 29.812312658227849, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22587, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.457515172413793 ], [ 29.812582151898734, -1.457784655172414 ], [ 29.812851645569619, -1.457784655172414 ], [ 29.812851645569619, -1.457515172413793 ], [ 29.812582151898734, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22588, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.457515172413793 ], [ 29.812851645569619, -1.457784655172414 ], [ 29.813121139240508, -1.457784655172414 ], [ 29.813121139240508, -1.457515172413793 ], [ 29.812851645569619, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22589, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.457515172413793 ], [ 29.813121139240508, -1.458054137931035 ], [ 29.813390632911393, -1.458054137931035 ], [ 29.813390632911393, -1.457515172413793 ], [ 29.813121139240508, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22590, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.457515172413793 ], [ 29.813390632911393, -1.457784655172414 ], [ 29.813660126582278, -1.457784655172414 ], [ 29.813660126582278, -1.457515172413793 ], [ 29.813390632911393, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22591, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.457515172413793 ], [ 29.813660126582278, -1.457784655172414 ], [ 29.813929620253166, -1.457784655172414 ], [ 29.813929620253166, -1.457515172413793 ], [ 29.813660126582278, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22592, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.457515172413793 ], [ 29.813929620253166, -1.457784655172414 ], [ 29.814199113924051, -1.457784655172414 ], [ 29.814199113924051, -1.457515172413793 ], [ 29.813929620253166, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22593, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751676582278481, -1.457784655172414 ], [ 29.751676582278481, -1.458323620689655 ], [ 29.751946075949366, -1.458323620689655 ], [ 29.751946075949366, -1.457784655172414 ], [ 29.751676582278481, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22594, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.457784655172414 ], [ 29.751946075949366, -1.458054137931035 ], [ 29.752215569620251, -1.458054137931035 ], [ 29.752215569620251, -1.457784655172414 ], [ 29.751946075949366, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22595, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.457784655172414 ], [ 29.752215569620251, -1.458054137931035 ], [ 29.75248506329114, -1.458054137931035 ], [ 29.75248506329114, -1.457784655172414 ], [ 29.752215569620251, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22596, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.457784655172414 ], [ 29.75248506329114, -1.458054137931035 ], [ 29.752754556962024, -1.458054137931035 ], [ 29.752754556962024, -1.457784655172414 ], [ 29.75248506329114, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22597, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.457784655172414 ], [ 29.752754556962024, -1.458054137931035 ], [ 29.753024050632909, -1.458054137931035 ], [ 29.753024050632909, -1.457784655172414 ], [ 29.752754556962024, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22598, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.457784655172414 ], [ 29.753024050632909, -1.458054137931035 ], [ 29.753293544303798, -1.458054137931035 ], [ 29.753293544303798, -1.457784655172414 ], [ 29.753024050632909, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22599, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.457784655172414 ], [ 29.753293544303798, -1.458054137931035 ], [ 29.753563037974683, -1.458054137931035 ], [ 29.753563037974683, -1.457784655172414 ], [ 29.753293544303798, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22600, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.457784655172414 ], [ 29.753563037974683, -1.458054137931035 ], [ 29.753832531645568, -1.458054137931035 ], [ 29.753832531645568, -1.457784655172414 ], [ 29.753563037974683, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22601, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.457784655172414 ], [ 29.753832531645568, -1.458054137931035 ], [ 29.754102025316456, -1.458054137931035 ], [ 29.754102025316456, -1.457784655172414 ], [ 29.753832531645568, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22602, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.457784655172414 ], [ 29.754102025316456, -1.458054137931035 ], [ 29.754371518987341, -1.458054137931035 ], [ 29.754371518987341, -1.457784655172414 ], [ 29.754102025316456, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22603, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.457784655172414 ], [ 29.754371518987341, -1.458054137931035 ], [ 29.754641012658226, -1.458054137931035 ], [ 29.754641012658226, -1.457784655172414 ], [ 29.754371518987341, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22604, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.457784655172414 ], [ 29.754641012658226, -1.458054137931035 ], [ 29.754910506329114, -1.458054137931035 ], [ 29.754910506329114, -1.457784655172414 ], [ 29.754641012658226, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22605, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.457784655172414 ], [ 29.754910506329114, -1.458054137931035 ], [ 29.75518, -1.458054137931035 ], [ 29.75518, -1.457784655172414 ], [ 29.754910506329114, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22606, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.457784655172414 ], [ 29.75518, -1.458054137931035 ], [ 29.755449493670884, -1.458054137931035 ], [ 29.755449493670884, -1.457784655172414 ], [ 29.75518, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22607, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.457784655172414 ], [ 29.755449493670884, -1.458054137931035 ], [ 29.755718987341773, -1.458054137931035 ], [ 29.755718987341773, -1.457784655172414 ], [ 29.755449493670884, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22608, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.457784655172414 ], [ 29.755718987341773, -1.458054137931035 ], [ 29.755988481012658, -1.458054137931035 ], [ 29.755988481012658, -1.457784655172414 ], [ 29.755718987341773, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22609, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.457784655172414 ], [ 29.755988481012658, -1.458054137931035 ], [ 29.756257974683542, -1.458054137931035 ], [ 29.756257974683542, -1.457784655172414 ], [ 29.755988481012658, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22610, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.457784655172414 ], [ 29.756257974683542, -1.458054137931035 ], [ 29.756796962025316, -1.458054137931035 ], [ 29.756796962025316, -1.457784655172414 ], [ 29.756257974683542, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22611, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.457784655172414 ], [ 29.756796962025316, -1.458054137931035 ], [ 29.757066455696201, -1.458054137931035 ], [ 29.757066455696201, -1.457784655172414 ], [ 29.756796962025316, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22612, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.457784655172414 ], [ 29.757066455696201, -1.458054137931035 ], [ 29.757335949367089, -1.458054137931035 ], [ 29.757335949367089, -1.457784655172414 ], [ 29.757066455696201, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22613, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.457784655172414 ], [ 29.757335949367089, -1.458323620689655 ], [ 29.757605443037974, -1.458323620689655 ], [ 29.757605443037974, -1.457784655172414 ], [ 29.757335949367089, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22614, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.457784655172414 ], [ 29.757605443037974, -1.458054137931035 ], [ 29.757874936708859, -1.458054137931035 ], [ 29.757874936708859, -1.457784655172414 ], [ 29.757605443037974, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22615, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.457784655172414 ], [ 29.757874936708859, -1.458323620689655 ], [ 29.758144430379748, -1.458323620689655 ], [ 29.758144430379748, -1.457784655172414 ], [ 29.757874936708859, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22616, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.457784655172414 ], [ 29.758144430379748, -1.458054137931035 ], [ 29.758413924050632, -1.458054137931035 ], [ 29.758413924050632, -1.457784655172414 ], [ 29.758144430379748, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22617, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.457784655172414 ], [ 29.758413924050632, -1.458054137931035 ], [ 29.758683417721517, -1.458054137931035 ], [ 29.758683417721517, -1.457784655172414 ], [ 29.758413924050632, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22618, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.457784655172414 ], [ 29.758683417721517, -1.458054137931035 ], [ 29.758952911392406, -1.458054137931035 ], [ 29.758952911392406, -1.457784655172414 ], [ 29.758683417721517, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22619, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.457784655172414 ], [ 29.758952911392406, -1.458054137931035 ], [ 29.759222405063291, -1.458054137931035 ], [ 29.759222405063291, -1.457784655172414 ], [ 29.758952911392406, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22620, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.457784655172414 ], [ 29.759222405063291, -1.458054137931035 ], [ 29.759491898734176, -1.458054137931035 ], [ 29.759491898734176, -1.457784655172414 ], [ 29.759222405063291, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22621, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.457784655172414 ], [ 29.759491898734176, -1.458054137931035 ], [ 29.759761392405064, -1.458054137931035 ], [ 29.759761392405064, -1.457784655172414 ], [ 29.759491898734176, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22622, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.457784655172414 ], [ 29.759761392405064, -1.458054137931035 ], [ 29.760030886075949, -1.458054137931035 ], [ 29.760030886075949, -1.457784655172414 ], [ 29.759761392405064, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22623, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.457784655172414 ], [ 29.760030886075949, -1.458054137931035 ], [ 29.760300379746834, -1.458054137931035 ], [ 29.760300379746834, -1.457784655172414 ], [ 29.760030886075949, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22624, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.457784655172414 ], [ 29.760300379746834, -1.458054137931035 ], [ 29.760569873417722, -1.458054137931035 ], [ 29.760569873417722, -1.457784655172414 ], [ 29.760300379746834, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22625, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.457784655172414 ], [ 29.760569873417722, -1.458054137931035 ], [ 29.760839367088607, -1.458054137931035 ], [ 29.760839367088607, -1.457784655172414 ], [ 29.760569873417722, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22626, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.457784655172414 ], [ 29.760839367088607, -1.458054137931035 ], [ 29.761108860759492, -1.458054137931035 ], [ 29.761108860759492, -1.457784655172414 ], [ 29.760839367088607, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22627, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.457784655172414 ], [ 29.761108860759492, -1.458054137931035 ], [ 29.761378354430381, -1.458054137931035 ], [ 29.761378354430381, -1.457784655172414 ], [ 29.761108860759492, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22628, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.457784655172414 ], [ 29.761378354430381, -1.458054137931035 ], [ 29.761647848101266, -1.458054137931035 ], [ 29.761647848101266, -1.457784655172414 ], [ 29.761378354430381, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22629, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.457784655172414 ], [ 29.761647848101266, -1.458054137931035 ], [ 29.761917341772151, -1.458054137931035 ], [ 29.761917341772151, -1.457784655172414 ], [ 29.761647848101266, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22630, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.457784655172414 ], [ 29.761917341772151, -1.458054137931035 ], [ 29.762456329113924, -1.458054137931035 ], [ 29.762456329113924, -1.457784655172414 ], [ 29.761917341772151, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22631, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.457784655172414 ], [ 29.762456329113924, -1.458054137931035 ], [ 29.763534303797467, -1.458054137931035 ], [ 29.763534303797467, -1.457784655172414 ], [ 29.762456329113924, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22632, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.457784655172414 ], [ 29.763534303797467, -1.458054137931035 ], [ 29.76407329113924, -1.458054137931035 ], [ 29.76407329113924, -1.457784655172414 ], [ 29.763534303797467, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22633, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.457784655172414 ], [ 29.76407329113924, -1.458054137931035 ], [ 29.764342784810125, -1.458054137931035 ], [ 29.764342784810125, -1.457784655172414 ], [ 29.76407329113924, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22634, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.457784655172414 ], [ 29.764342784810125, -1.458054137931035 ], [ 29.764612278481014, -1.458054137931035 ], [ 29.764612278481014, -1.457784655172414 ], [ 29.764342784810125, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22635, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.457784655172414 ], [ 29.764612278481014, -1.458054137931035 ], [ 29.764881772151899, -1.458054137931035 ], [ 29.764881772151899, -1.457784655172414 ], [ 29.764612278481014, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22636, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.457784655172414 ], [ 29.764881772151899, -1.458054137931035 ], [ 29.765151265822784, -1.458054137931035 ], [ 29.765151265822784, -1.457784655172414 ], [ 29.764881772151899, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22637, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.457784655172414 ], [ 29.765151265822784, -1.458054137931035 ], [ 29.765420759493672, -1.458054137931035 ], [ 29.765420759493672, -1.457784655172414 ], [ 29.765151265822784, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22638, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.457784655172414 ], [ 29.765420759493672, -1.458054137931035 ], [ 29.765959746835442, -1.458054137931035 ], [ 29.765959746835442, -1.457784655172414 ], [ 29.765420759493672, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22639, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.457784655172414 ], [ 29.765959746835442, -1.458054137931035 ], [ 29.766229240506327, -1.458054137931035 ], [ 29.766229240506327, -1.457784655172414 ], [ 29.765959746835442, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22640, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.457784655172414 ], [ 29.766229240506327, -1.458054137931035 ], [ 29.766498734177215, -1.458054137931035 ], [ 29.766498734177215, -1.457784655172414 ], [ 29.766229240506327, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22641, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.457784655172414 ], [ 29.766498734177215, -1.458054137931035 ], [ 29.767037721518985, -1.458054137931035 ], [ 29.767037721518985, -1.457784655172414 ], [ 29.766498734177215, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22642, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.457784655172414 ], [ 29.767037721518985, -1.458054137931035 ], [ 29.768654683544302, -1.458054137931035 ], [ 29.768654683544302, -1.457784655172414 ], [ 29.767037721518985, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22643, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.457784655172414 ], [ 29.768654683544302, -1.458054137931035 ], [ 29.769193670886075, -1.458054137931035 ], [ 29.769193670886075, -1.457784655172414 ], [ 29.768654683544302, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22644, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.457784655172414 ], [ 29.769193670886075, -1.458054137931035 ], [ 29.76946316455696, -1.458054137931035 ], [ 29.76946316455696, -1.457784655172414 ], [ 29.769193670886075, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22645, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.457784655172414 ], [ 29.76946316455696, -1.458054137931035 ], [ 29.769732658227849, -1.458054137931035 ], [ 29.769732658227849, -1.457784655172414 ], [ 29.76946316455696, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22646, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.457784655172414 ], [ 29.769732658227849, -1.458054137931035 ], [ 29.770002151898733, -1.458054137931035 ], [ 29.770002151898733, -1.457784655172414 ], [ 29.769732658227849, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22647, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.457784655172414 ], [ 29.770002151898733, -1.458054137931035 ], [ 29.770271645569618, -1.458054137931035 ], [ 29.770271645569618, -1.457784655172414 ], [ 29.770002151898733, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22648, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.457784655172414 ], [ 29.770271645569618, -1.458054137931035 ], [ 29.770541139240507, -1.458054137931035 ], [ 29.770541139240507, -1.457784655172414 ], [ 29.770271645569618, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22649, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.457784655172414 ], [ 29.770541139240507, -1.458054137931035 ], [ 29.770810632911392, -1.458054137931035 ], [ 29.770810632911392, -1.457784655172414 ], [ 29.770541139240507, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22650, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.457784655172414 ], [ 29.770810632911392, -1.458054137931035 ], [ 29.771080126582277, -1.458054137931035 ], [ 29.771080126582277, -1.457784655172414 ], [ 29.770810632911392, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22651, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.457784655172414 ], [ 29.771080126582277, -1.458054137931035 ], [ 29.771349620253165, -1.458054137931035 ], [ 29.771349620253165, -1.457784655172414 ], [ 29.771080126582277, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22652, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.457784655172414 ], [ 29.771349620253165, -1.458054137931035 ], [ 29.77161911392405, -1.458054137931035 ], [ 29.77161911392405, -1.457784655172414 ], [ 29.771349620253165, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22653, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.457784655172414 ], [ 29.77161911392405, -1.458054137931035 ], [ 29.771888607594935, -1.458054137931035 ], [ 29.771888607594935, -1.457784655172414 ], [ 29.77161911392405, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22654, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.457784655172414 ], [ 29.771888607594935, -1.458054137931035 ], [ 29.772158101265823, -1.458054137931035 ], [ 29.772158101265823, -1.457784655172414 ], [ 29.771888607594935, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22655, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.457784655172414 ], [ 29.772158101265823, -1.458054137931035 ], [ 29.772427594936708, -1.458054137931035 ], [ 29.772427594936708, -1.457784655172414 ], [ 29.772158101265823, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22656, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.457784655172414 ], [ 29.772427594936708, -1.458054137931035 ], [ 29.772697088607593, -1.458054137931035 ], [ 29.772697088607593, -1.457784655172414 ], [ 29.772427594936708, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22657, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.457784655172414 ], [ 29.772697088607593, -1.458054137931035 ], [ 29.772966582278482, -1.458054137931035 ], [ 29.772966582278482, -1.457784655172414 ], [ 29.772697088607593, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22658, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.457784655172414 ], [ 29.772966582278482, -1.458054137931035 ], [ 29.773236075949367, -1.458054137931035 ], [ 29.773236075949367, -1.457784655172414 ], [ 29.772966582278482, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22659, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.457784655172414 ], [ 29.773236075949367, -1.458054137931035 ], [ 29.773505569620252, -1.458054137931035 ], [ 29.773505569620252, -1.457784655172414 ], [ 29.773236075949367, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22660, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.457784655172414 ], [ 29.773505569620252, -1.458054137931035 ], [ 29.77377506329114, -1.458054137931035 ], [ 29.77377506329114, -1.457784655172414 ], [ 29.773505569620252, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22661, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.457784655172414 ], [ 29.77377506329114, -1.458054137931035 ], [ 29.774044556962025, -1.458054137931035 ], [ 29.774044556962025, -1.457784655172414 ], [ 29.77377506329114, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22662, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.457784655172414 ], [ 29.774044556962025, -1.458054137931035 ], [ 29.77431405063291, -1.458054137931035 ], [ 29.77431405063291, -1.457784655172414 ], [ 29.774044556962025, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22663, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.457784655172414 ], [ 29.77431405063291, -1.458054137931035 ], [ 29.774853037974683, -1.458054137931035 ], [ 29.774853037974683, -1.457784655172414 ], [ 29.77431405063291, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22664, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.457784655172414 ], [ 29.774853037974683, -1.458054137931035 ], [ 29.775122531645568, -1.458054137931035 ], [ 29.775122531645568, -1.457784655172414 ], [ 29.774853037974683, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22665, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.457784655172414 ], [ 29.775122531645568, -1.458054137931035 ], [ 29.775392025316457, -1.458054137931035 ], [ 29.775392025316457, -1.457784655172414 ], [ 29.775122531645568, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22666, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.457784655172414 ], [ 29.775392025316457, -1.458054137931035 ], [ 29.775661518987341, -1.458054137931035 ], [ 29.775661518987341, -1.457784655172414 ], [ 29.775392025316457, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22667, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.457784655172414 ], [ 29.775661518987341, -1.458054137931035 ], [ 29.775931012658226, -1.458054137931035 ], [ 29.775931012658226, -1.457784655172414 ], [ 29.775661518987341, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22668, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.457784655172414 ], [ 29.775931012658226, -1.458054137931035 ], [ 29.776200506329115, -1.458054137931035 ], [ 29.776200506329115, -1.457784655172414 ], [ 29.775931012658226, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22669, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.457784655172414 ], [ 29.776200506329115, -1.458054137931035 ], [ 29.77647, -1.458054137931035 ], [ 29.77647, -1.457784655172414 ], [ 29.776200506329115, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22670, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.457784655172414 ], [ 29.77647, -1.458054137931035 ], [ 29.776739493670885, -1.458054137931035 ], [ 29.776739493670885, -1.457784655172414 ], [ 29.77647, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22671, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.457784655172414 ], [ 29.776739493670885, -1.458054137931035 ], [ 29.777008987341773, -1.458054137931035 ], [ 29.777008987341773, -1.457784655172414 ], [ 29.776739493670885, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22672, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.457784655172414 ], [ 29.777008987341773, -1.458054137931035 ], [ 29.777278481012658, -1.458054137931035 ], [ 29.777278481012658, -1.457784655172414 ], [ 29.777008987341773, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22673, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.457784655172414 ], [ 29.777278481012658, -1.458054137931035 ], [ 29.777547974683543, -1.458054137931035 ], [ 29.777547974683543, -1.457784655172414 ], [ 29.777278481012658, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22674, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.457784655172414 ], [ 29.777547974683543, -1.458054137931035 ], [ 29.777817468354431, -1.458054137931035 ], [ 29.777817468354431, -1.457784655172414 ], [ 29.777547974683543, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22675, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.457784655172414 ], [ 29.777817468354431, -1.458054137931035 ], [ 29.778086962025316, -1.458054137931035 ], [ 29.778086962025316, -1.457784655172414 ], [ 29.777817468354431, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22676, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.457784655172414 ], [ 29.778086962025316, -1.458054137931035 ], [ 29.778356455696201, -1.458054137931035 ], [ 29.778356455696201, -1.457784655172414 ], [ 29.778086962025316, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22677, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.457245689655172 ], [ 29.778895443037975, -1.457245689655172 ], [ 29.778895443037975, -1.458323620689655 ], [ 29.77862594936709, -1.458323620689655 ], [ 29.77862594936709, -1.458054137931035 ], [ 29.778356455696201, -1.458054137931035 ], [ 29.778356455696201, -1.457784655172414 ], [ 29.77862594936709, -1.457784655172414 ], [ 29.77862594936709, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22678, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.457784655172414 ], [ 29.791831139240507, -1.458862586206897 ], [ 29.792100632911392, -1.458862586206897 ], [ 29.792100632911392, -1.457784655172414 ], [ 29.791831139240507, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22679, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.457784655172414 ], [ 29.792100632911392, -1.458054137931035 ], [ 29.792370126582277, -1.458054137931035 ], [ 29.792370126582277, -1.457784655172414 ], [ 29.792100632911392, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22680, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.457784655172414 ], [ 29.792370126582277, -1.458054137931035 ], [ 29.792909113924051, -1.458054137931035 ], [ 29.792909113924051, -1.457784655172414 ], [ 29.792370126582277, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22681, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.457784655172414 ], [ 29.792909113924051, -1.458054137931035 ], [ 29.793178607594935, -1.458054137931035 ], [ 29.793178607594935, -1.457784655172414 ], [ 29.792909113924051, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22682, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.457784655172414 ], [ 29.793717594936709, -1.458323620689655 ], [ 29.793987088607594, -1.458323620689655 ], [ 29.793987088607594, -1.457784655172414 ], [ 29.793717594936709, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22683, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.457784655172414 ], [ 29.79506506329114, -1.458054137931035 ], [ 29.795334556962025, -1.458054137931035 ], [ 29.795334556962025, -1.457784655172414 ], [ 29.79506506329114, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22684, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.457784655172414 ], [ 29.795334556962025, -1.458054137931035 ], [ 29.79560405063291, -1.458054137931035 ], [ 29.79560405063291, -1.457784655172414 ], [ 29.795334556962025, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22685, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.457784655172414 ], [ 29.79560405063291, -1.458054137931035 ], [ 29.795873544303799, -1.458054137931035 ], [ 29.795873544303799, -1.457784655172414 ], [ 29.79560405063291, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22686, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.457784655172414 ], [ 29.795873544303799, -1.458054137931035 ], [ 29.796143037974684, -1.458054137931035 ], [ 29.796143037974684, -1.457784655172414 ], [ 29.795873544303799, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22687, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.457784655172414 ], [ 29.796143037974684, -1.458054137931035 ], [ 29.796412531645569, -1.458054137931035 ], [ 29.796412531645569, -1.457784655172414 ], [ 29.796143037974684, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22688, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.457784655172414 ], [ 29.796412531645569, -1.458054137931035 ], [ 29.796682025316457, -1.458054137931035 ], [ 29.796682025316457, -1.457784655172414 ], [ 29.796412531645569, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22689, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.457784655172414 ], [ 29.796682025316457, -1.458054137931035 ], [ 29.796951518987342, -1.458054137931035 ], [ 29.796951518987342, -1.457784655172414 ], [ 29.796682025316457, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22690, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.457784655172414 ], [ 29.796951518987342, -1.458054137931035 ], [ 29.797221012658227, -1.458054137931035 ], [ 29.797221012658227, -1.457784655172414 ], [ 29.796951518987342, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22691, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.457784655172414 ], [ 29.797221012658227, -1.458054137931035 ], [ 29.797490506329115, -1.458054137931035 ], [ 29.797490506329115, -1.457784655172414 ], [ 29.797221012658227, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22692, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.457784655172414 ], [ 29.79776, -1.458054137931035 ], [ 29.798029493670885, -1.458054137931035 ], [ 29.798029493670885, -1.457784655172414 ], [ 29.79776, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22693, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.457784655172414 ], [ 29.798029493670885, -1.458054137931035 ], [ 29.798298987341774, -1.458054137931035 ], [ 29.798298987341774, -1.457784655172414 ], [ 29.798029493670885, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22694, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.457784655172414 ], [ 29.798298987341774, -1.458054137931035 ], [ 29.798568481012659, -1.458054137931035 ], [ 29.798568481012659, -1.457784655172414 ], [ 29.798298987341774, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22695, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.457784655172414 ], [ 29.798568481012659, -1.458054137931035 ], [ 29.798837974683543, -1.458054137931035 ], [ 29.798837974683543, -1.457784655172414 ], [ 29.798568481012659, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22696, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.457784655172414 ], [ 29.798837974683543, -1.458054137931035 ], [ 29.799107468354432, -1.458054137931035 ], [ 29.799107468354432, -1.457784655172414 ], [ 29.798837974683543, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22697, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.457784655172414 ], [ 29.799107468354432, -1.458323620689655 ], [ 29.799376962025317, -1.458323620689655 ], [ 29.799376962025317, -1.457784655172414 ], [ 29.799107468354432, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22698, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.457784655172414 ], [ 29.799376962025317, -1.458323620689655 ], [ 29.799646455696202, -1.458323620689655 ], [ 29.799646455696202, -1.457784655172414 ], [ 29.799376962025317, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22699, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.457784655172414 ], [ 29.807731265822785, -1.458054137931035 ], [ 29.808000759493673, -1.458054137931035 ], [ 29.808000759493673, -1.457784655172414 ], [ 29.807731265822785, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22700, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.457784655172414 ], [ 29.808000759493673, -1.458054137931035 ], [ 29.808270253164558, -1.458054137931035 ], [ 29.808270253164558, -1.457784655172414 ], [ 29.808000759493673, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22701, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.457784655172414 ], [ 29.808270253164558, -1.458054137931035 ], [ 29.808539746835443, -1.458054137931035 ], [ 29.808539746835443, -1.457784655172414 ], [ 29.808270253164558, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22702, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.457515172413793 ], [ 29.809078734177216, -1.457515172413793 ], [ 29.809078734177216, -1.457784655172414 ], [ 29.809348227848101, -1.457784655172414 ], [ 29.809348227848101, -1.458054137931035 ], [ 29.808539746835443, -1.458054137931035 ], [ 29.808539746835443, -1.457784655172414 ], [ 29.808809240506328, -1.457784655172414 ], [ 29.808809240506328, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22703, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.457784655172414 ], [ 29.809348227848101, -1.458054137931035 ], [ 29.809617721518986, -1.458054137931035 ], [ 29.809617721518986, -1.457784655172414 ], [ 29.809348227848101, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22704, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.457784655172414 ], [ 29.809617721518986, -1.458054137931035 ], [ 29.809887215189875, -1.458054137931035 ], [ 29.809887215189875, -1.457784655172414 ], [ 29.809617721518986, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22705, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809887215189875, -1.457784655172414 ], [ 29.809887215189875, -1.458054137931035 ], [ 29.81015670886076, -1.458054137931035 ], [ 29.81015670886076, -1.457784655172414 ], [ 29.809887215189875, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22706, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.81015670886076, -1.457784655172414 ], [ 29.81015670886076, -1.458054137931035 ], [ 29.810426202531644, -1.458054137931035 ], [ 29.810426202531644, -1.457784655172414 ], [ 29.81015670886076, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22707, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.457784655172414 ], [ 29.810426202531644, -1.458054137931035 ], [ 29.810965189873418, -1.458054137931035 ], [ 29.810965189873418, -1.457784655172414 ], [ 29.810426202531644, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22708, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810965189873418, -1.457784655172414 ], [ 29.810965189873418, -1.458054137931035 ], [ 29.811234683544303, -1.458054137931035 ], [ 29.811234683544303, -1.457784655172414 ], [ 29.810965189873418, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22709, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.457784655172414 ], [ 29.811234683544303, -1.458054137931035 ], [ 29.811504177215191, -1.458054137931035 ], [ 29.811504177215191, -1.457784655172414 ], [ 29.811234683544303, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22710, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.457784655172414 ], [ 29.811504177215191, -1.458054137931035 ], [ 29.811773670886076, -1.458054137931035 ], [ 29.811773670886076, -1.457784655172414 ], [ 29.811504177215191, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22711, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.457784655172414 ], [ 29.811773670886076, -1.458054137931035 ], [ 29.812043164556961, -1.458054137931035 ], [ 29.812043164556961, -1.457784655172414 ], [ 29.811773670886076, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22712, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.457784655172414 ], [ 29.812043164556961, -1.458054137931035 ], [ 29.812312658227849, -1.458054137931035 ], [ 29.812312658227849, -1.457784655172414 ], [ 29.812043164556961, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22713, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.457784655172414 ], [ 29.812312658227849, -1.458054137931035 ], [ 29.812582151898734, -1.458054137931035 ], [ 29.812582151898734, -1.457784655172414 ], [ 29.812312658227849, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22714, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.457784655172414 ], [ 29.812582151898734, -1.458054137931035 ], [ 29.812851645569619, -1.458054137931035 ], [ 29.812851645569619, -1.457784655172414 ], [ 29.812582151898734, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22715, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.457784655172414 ], [ 29.812851645569619, -1.458054137931035 ], [ 29.813121139240508, -1.458054137931035 ], [ 29.813121139240508, -1.457784655172414 ], [ 29.812851645569619, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22716, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.457784655172414 ], [ 29.813390632911393, -1.458323620689655 ], [ 29.813660126582278, -1.458323620689655 ], [ 29.813660126582278, -1.457784655172414 ], [ 29.813390632911393, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22717, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.458054137931035 ], [ 29.751946075949366, -1.458323620689655 ], [ 29.752215569620251, -1.458323620689655 ], [ 29.752215569620251, -1.458054137931035 ], [ 29.751946075949366, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22718, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.458054137931035 ], [ 29.752215569620251, -1.458323620689655 ], [ 29.75248506329114, -1.458323620689655 ], [ 29.75248506329114, -1.458054137931035 ], [ 29.752215569620251, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22719, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.458054137931035 ], [ 29.75248506329114, -1.458323620689655 ], [ 29.752754556962024, -1.458323620689655 ], [ 29.752754556962024, -1.458054137931035 ], [ 29.75248506329114, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22720, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.458054137931035 ], [ 29.752754556962024, -1.458323620689655 ], [ 29.753024050632909, -1.458323620689655 ], [ 29.753024050632909, -1.458054137931035 ], [ 29.752754556962024, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22721, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.458054137931035 ], [ 29.753024050632909, -1.458323620689655 ], [ 29.753293544303798, -1.458323620689655 ], [ 29.753293544303798, -1.458054137931035 ], [ 29.753024050632909, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22722, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.458054137931035 ], [ 29.753293544303798, -1.458323620689655 ], [ 29.753563037974683, -1.458323620689655 ], [ 29.753563037974683, -1.458054137931035 ], [ 29.753293544303798, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22723, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.458054137931035 ], [ 29.753563037974683, -1.458323620689655 ], [ 29.753832531645568, -1.458323620689655 ], [ 29.753832531645568, -1.458054137931035 ], [ 29.753563037974683, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22724, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.458054137931035 ], [ 29.753832531645568, -1.458323620689655 ], [ 29.754102025316456, -1.458323620689655 ], [ 29.754102025316456, -1.458054137931035 ], [ 29.753832531645568, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22725, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.458054137931035 ], [ 29.754102025316456, -1.458323620689655 ], [ 29.754371518987341, -1.458323620689655 ], [ 29.754371518987341, -1.458054137931035 ], [ 29.754102025316456, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22726, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.458054137931035 ], [ 29.754371518987341, -1.458323620689655 ], [ 29.754641012658226, -1.458323620689655 ], [ 29.754641012658226, -1.458054137931035 ], [ 29.754371518987341, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22727, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.458054137931035 ], [ 29.754641012658226, -1.458323620689655 ], [ 29.754910506329114, -1.458323620689655 ], [ 29.754910506329114, -1.458054137931035 ], [ 29.754641012658226, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22728, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.458054137931035 ], [ 29.754910506329114, -1.458323620689655 ], [ 29.75518, -1.458323620689655 ], [ 29.75518, -1.458054137931035 ], [ 29.754910506329114, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22729, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.458054137931035 ], [ 29.75518, -1.458323620689655 ], [ 29.755449493670884, -1.458323620689655 ], [ 29.755449493670884, -1.458054137931035 ], [ 29.75518, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22730, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.458054137931035 ], [ 29.755449493670884, -1.458323620689655 ], [ 29.755718987341773, -1.458323620689655 ], [ 29.755718987341773, -1.458054137931035 ], [ 29.755449493670884, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22731, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.458054137931035 ], [ 29.755718987341773, -1.458323620689655 ], [ 29.755988481012658, -1.458323620689655 ], [ 29.755988481012658, -1.458054137931035 ], [ 29.755718987341773, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22732, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.458054137931035 ], [ 29.755988481012658, -1.458323620689655 ], [ 29.756257974683542, -1.458323620689655 ], [ 29.756257974683542, -1.458054137931035 ], [ 29.755988481012658, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22733, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.458054137931035 ], [ 29.756257974683542, -1.458323620689655 ], [ 29.756796962025316, -1.458323620689655 ], [ 29.756796962025316, -1.458054137931035 ], [ 29.756257974683542, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22734, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.458054137931035 ], [ 29.756796962025316, -1.458323620689655 ], [ 29.757066455696201, -1.458323620689655 ], [ 29.757066455696201, -1.458054137931035 ], [ 29.756796962025316, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22735, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.458054137931035 ], [ 29.757066455696201, -1.458323620689655 ], [ 29.757335949367089, -1.458323620689655 ], [ 29.757335949367089, -1.458054137931035 ], [ 29.757066455696201, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22736, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.458054137931035 ], [ 29.757605443037974, -1.458323620689655 ], [ 29.757874936708859, -1.458323620689655 ], [ 29.757874936708859, -1.458054137931035 ], [ 29.757605443037974, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22737, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.458054137931035 ], [ 29.758144430379748, -1.458323620689655 ], [ 29.758413924050632, -1.458323620689655 ], [ 29.758413924050632, -1.458054137931035 ], [ 29.758144430379748, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22738, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.458054137931035 ], [ 29.758413924050632, -1.458323620689655 ], [ 29.758683417721517, -1.458323620689655 ], [ 29.758683417721517, -1.458054137931035 ], [ 29.758413924050632, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22739, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.458054137931035 ], [ 29.758683417721517, -1.458323620689655 ], [ 29.758952911392406, -1.458323620689655 ], [ 29.758952911392406, -1.458054137931035 ], [ 29.758683417721517, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22740, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.458054137931035 ], [ 29.758952911392406, -1.458323620689655 ], [ 29.759222405063291, -1.458323620689655 ], [ 29.759222405063291, -1.458054137931035 ], [ 29.758952911392406, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22741, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.458054137931035 ], [ 29.759222405063291, -1.458323620689655 ], [ 29.759491898734176, -1.458323620689655 ], [ 29.759491898734176, -1.458054137931035 ], [ 29.759222405063291, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22742, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.458054137931035 ], [ 29.759491898734176, -1.458323620689655 ], [ 29.759761392405064, -1.458323620689655 ], [ 29.759761392405064, -1.458054137931035 ], [ 29.759491898734176, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22743, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.458054137931035 ], [ 29.759761392405064, -1.458323620689655 ], [ 29.760030886075949, -1.458323620689655 ], [ 29.760030886075949, -1.458054137931035 ], [ 29.759761392405064, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22744, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.458054137931035 ], [ 29.760030886075949, -1.458323620689655 ], [ 29.760300379746834, -1.458323620689655 ], [ 29.760300379746834, -1.458054137931035 ], [ 29.760030886075949, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22745, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.458054137931035 ], [ 29.760300379746834, -1.458323620689655 ], [ 29.760569873417722, -1.458323620689655 ], [ 29.760569873417722, -1.458054137931035 ], [ 29.760300379746834, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22746, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.458054137931035 ], [ 29.760569873417722, -1.458323620689655 ], [ 29.760839367088607, -1.458323620689655 ], [ 29.760839367088607, -1.458054137931035 ], [ 29.760569873417722, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22747, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.458054137931035 ], [ 29.760839367088607, -1.458323620689655 ], [ 29.761108860759492, -1.458323620689655 ], [ 29.761108860759492, -1.458054137931035 ], [ 29.760839367088607, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22748, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.458054137931035 ], [ 29.761108860759492, -1.458323620689655 ], [ 29.761378354430381, -1.458323620689655 ], [ 29.761378354430381, -1.458054137931035 ], [ 29.761108860759492, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22749, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.458054137931035 ], [ 29.761378354430381, -1.458323620689655 ], [ 29.761647848101266, -1.458323620689655 ], [ 29.761647848101266, -1.458054137931035 ], [ 29.761378354430381, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22750, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.458054137931035 ], [ 29.761647848101266, -1.458323620689655 ], [ 29.761917341772151, -1.458323620689655 ], [ 29.761917341772151, -1.458054137931035 ], [ 29.761647848101266, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22751, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.458054137931035 ], [ 29.761917341772151, -1.458323620689655 ], [ 29.762186835443039, -1.458323620689655 ], [ 29.762186835443039, -1.458054137931035 ], [ 29.761917341772151, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22752, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.458054137931035 ], [ 29.762186835443039, -1.458323620689655 ], [ 29.762725822784809, -1.458323620689655 ], [ 29.762725822784809, -1.458054137931035 ], [ 29.762186835443039, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22753, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.458054137931035 ], [ 29.762725822784809, -1.458323620689655 ], [ 29.763264810126582, -1.458323620689655 ], [ 29.763264810126582, -1.458054137931035 ], [ 29.762725822784809, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22754, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.458054137931035 ], [ 29.763264810126582, -1.458323620689655 ], [ 29.763803797468356, -1.458323620689655 ], [ 29.763803797468356, -1.458054137931035 ], [ 29.763264810126582, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22755, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.458054137931035 ], [ 29.763803797468356, -1.458323620689655 ], [ 29.76407329113924, -1.458323620689655 ], [ 29.76407329113924, -1.458054137931035 ], [ 29.763803797468356, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22756, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.458054137931035 ], [ 29.76407329113924, -1.458323620689655 ], [ 29.764342784810125, -1.458323620689655 ], [ 29.764342784810125, -1.458054137931035 ], [ 29.76407329113924, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22757, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.458054137931035 ], [ 29.764342784810125, -1.458323620689655 ], [ 29.764612278481014, -1.458323620689655 ], [ 29.764612278481014, -1.458054137931035 ], [ 29.764342784810125, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22758, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.458054137931035 ], [ 29.764612278481014, -1.458323620689655 ], [ 29.764881772151899, -1.458323620689655 ], [ 29.764881772151899, -1.458054137931035 ], [ 29.764612278481014, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22759, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.458054137931035 ], [ 29.764881772151899, -1.458323620689655 ], [ 29.765151265822784, -1.458323620689655 ], [ 29.765151265822784, -1.458054137931035 ], [ 29.764881772151899, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22760, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.458054137931035 ], [ 29.765151265822784, -1.458323620689655 ], [ 29.765420759493672, -1.458323620689655 ], [ 29.765420759493672, -1.458054137931035 ], [ 29.765151265822784, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22761, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.458054137931035 ], [ 29.765420759493672, -1.458323620689655 ], [ 29.765959746835442, -1.458323620689655 ], [ 29.765959746835442, -1.458054137931035 ], [ 29.765420759493672, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22762, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.458054137931035 ], [ 29.765959746835442, -1.458323620689655 ], [ 29.766229240506327, -1.458323620689655 ], [ 29.766229240506327, -1.458054137931035 ], [ 29.765959746835442, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22763, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.458054137931035 ], [ 29.766229240506327, -1.458323620689655 ], [ 29.766498734177215, -1.458323620689655 ], [ 29.766498734177215, -1.458054137931035 ], [ 29.766229240506327, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22764, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.458054137931035 ], [ 29.766498734177215, -1.458323620689655 ], [ 29.7667682278481, -1.458323620689655 ], [ 29.7667682278481, -1.458054137931035 ], [ 29.766498734177215, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22765, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.458054137931035 ], [ 29.7667682278481, -1.458323620689655 ], [ 29.767307215189874, -1.458323620689655 ], [ 29.767307215189874, -1.458054137931035 ], [ 29.7667682278481, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22766, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.458054137931035 ], [ 29.767307215189874, -1.458323620689655 ], [ 29.768385189873417, -1.458323620689655 ], [ 29.768385189873417, -1.458054137931035 ], [ 29.767307215189874, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22767, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.458054137931035 ], [ 29.768385189873417, -1.458323620689655 ], [ 29.76892417721519, -1.458323620689655 ], [ 29.76892417721519, -1.458054137931035 ], [ 29.768385189873417, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22768, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.458054137931035 ], [ 29.76892417721519, -1.458323620689655 ], [ 29.769193670886075, -1.458323620689655 ], [ 29.769193670886075, -1.458054137931035 ], [ 29.76892417721519, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22769, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.458054137931035 ], [ 29.769193670886075, -1.458323620689655 ], [ 29.76946316455696, -1.458323620689655 ], [ 29.76946316455696, -1.458054137931035 ], [ 29.769193670886075, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22770, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.458054137931035 ], [ 29.76946316455696, -1.458323620689655 ], [ 29.769732658227849, -1.458323620689655 ], [ 29.769732658227849, -1.458054137931035 ], [ 29.76946316455696, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22771, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.458054137931035 ], [ 29.769732658227849, -1.458323620689655 ], [ 29.770002151898733, -1.458323620689655 ], [ 29.770002151898733, -1.458054137931035 ], [ 29.769732658227849, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22772, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.458054137931035 ], [ 29.770002151898733, -1.458323620689655 ], [ 29.770271645569618, -1.458323620689655 ], [ 29.770271645569618, -1.458054137931035 ], [ 29.770002151898733, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22773, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.458054137931035 ], [ 29.770271645569618, -1.458323620689655 ], [ 29.770541139240507, -1.458323620689655 ], [ 29.770541139240507, -1.458054137931035 ], [ 29.770271645569618, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22774, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.458054137931035 ], [ 29.770541139240507, -1.458323620689655 ], [ 29.770810632911392, -1.458323620689655 ], [ 29.770810632911392, -1.458054137931035 ], [ 29.770541139240507, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22775, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.458054137931035 ], [ 29.770810632911392, -1.458323620689655 ], [ 29.771080126582277, -1.458323620689655 ], [ 29.771080126582277, -1.458054137931035 ], [ 29.770810632911392, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22776, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.458054137931035 ], [ 29.771080126582277, -1.458323620689655 ], [ 29.771349620253165, -1.458323620689655 ], [ 29.771349620253165, -1.458054137931035 ], [ 29.771080126582277, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22777, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.458054137931035 ], [ 29.771349620253165, -1.458323620689655 ], [ 29.77161911392405, -1.458323620689655 ], [ 29.77161911392405, -1.458054137931035 ], [ 29.771349620253165, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22778, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.458054137931035 ], [ 29.77161911392405, -1.458323620689655 ], [ 29.771888607594935, -1.458323620689655 ], [ 29.771888607594935, -1.458054137931035 ], [ 29.77161911392405, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22779, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.458054137931035 ], [ 29.771888607594935, -1.458323620689655 ], [ 29.772158101265823, -1.458323620689655 ], [ 29.772158101265823, -1.458054137931035 ], [ 29.771888607594935, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22780, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.458054137931035 ], [ 29.772158101265823, -1.458323620689655 ], [ 29.772427594936708, -1.458323620689655 ], [ 29.772427594936708, -1.458054137931035 ], [ 29.772158101265823, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22781, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.458054137931035 ], [ 29.772427594936708, -1.458323620689655 ], [ 29.772697088607593, -1.458323620689655 ], [ 29.772697088607593, -1.458054137931035 ], [ 29.772427594936708, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22782, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.458054137931035 ], [ 29.772697088607593, -1.458323620689655 ], [ 29.772966582278482, -1.458323620689655 ], [ 29.772966582278482, -1.458054137931035 ], [ 29.772697088607593, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22783, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.458054137931035 ], [ 29.772966582278482, -1.458323620689655 ], [ 29.773236075949367, -1.458323620689655 ], [ 29.773236075949367, -1.458054137931035 ], [ 29.772966582278482, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22784, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.458054137931035 ], [ 29.773236075949367, -1.458323620689655 ], [ 29.773505569620252, -1.458323620689655 ], [ 29.773505569620252, -1.458054137931035 ], [ 29.773236075949367, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22785, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.458054137931035 ], [ 29.773505569620252, -1.458323620689655 ], [ 29.77377506329114, -1.458323620689655 ], [ 29.77377506329114, -1.458054137931035 ], [ 29.773505569620252, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22786, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.458054137931035 ], [ 29.77377506329114, -1.458323620689655 ], [ 29.774044556962025, -1.458323620689655 ], [ 29.774044556962025, -1.458054137931035 ], [ 29.77377506329114, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22787, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.458054137931035 ], [ 29.774044556962025, -1.458593103448276 ], [ 29.77431405063291, -1.458593103448276 ], [ 29.77431405063291, -1.458054137931035 ], [ 29.774044556962025, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22788, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.458054137931035 ], [ 29.77431405063291, -1.458593103448276 ], [ 29.774853037974683, -1.458593103448276 ], [ 29.774853037974683, -1.458054137931035 ], [ 29.77431405063291, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22789, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.458054137931035 ], [ 29.774853037974683, -1.458323620689655 ], [ 29.775122531645568, -1.458323620689655 ], [ 29.775122531645568, -1.458054137931035 ], [ 29.774853037974683, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22790, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.458054137931035 ], [ 29.775122531645568, -1.458593103448276 ], [ 29.775392025316457, -1.458593103448276 ], [ 29.775392025316457, -1.458054137931035 ], [ 29.775122531645568, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22791, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.458054137931035 ], [ 29.775392025316457, -1.458593103448276 ], [ 29.775661518987341, -1.458593103448276 ], [ 29.775661518987341, -1.458054137931035 ], [ 29.775392025316457, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22792, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.458054137931035 ], [ 29.775661518987341, -1.458323620689655 ], [ 29.775931012658226, -1.458323620689655 ], [ 29.775931012658226, -1.458054137931035 ], [ 29.775661518987341, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22793, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.458054137931035 ], [ 29.775931012658226, -1.458323620689655 ], [ 29.776200506329115, -1.458323620689655 ], [ 29.776200506329115, -1.458054137931035 ], [ 29.775931012658226, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22794, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.458054137931035 ], [ 29.776200506329115, -1.458323620689655 ], [ 29.77647, -1.458323620689655 ], [ 29.77647, -1.458054137931035 ], [ 29.776200506329115, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22795, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.458054137931035 ], [ 29.77647, -1.458323620689655 ], [ 29.776739493670885, -1.458323620689655 ], [ 29.776739493670885, -1.458054137931035 ], [ 29.77647, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22796, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.458054137931035 ], [ 29.776739493670885, -1.458323620689655 ], [ 29.777008987341773, -1.458323620689655 ], [ 29.777008987341773, -1.458054137931035 ], [ 29.776739493670885, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22797, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.458054137931035 ], [ 29.777008987341773, -1.458323620689655 ], [ 29.777278481012658, -1.458323620689655 ], [ 29.777278481012658, -1.458054137931035 ], [ 29.777008987341773, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22798, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.458054137931035 ], [ 29.777278481012658, -1.458323620689655 ], [ 29.777547974683543, -1.458323620689655 ], [ 29.777547974683543, -1.458054137931035 ], [ 29.777278481012658, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22799, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.458054137931035 ], [ 29.777547974683543, -1.458323620689655 ], [ 29.777817468354431, -1.458323620689655 ], [ 29.777817468354431, -1.458054137931035 ], [ 29.777547974683543, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22800, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.458054137931035 ], [ 29.777817468354431, -1.458323620689655 ], [ 29.778086962025316, -1.458323620689655 ], [ 29.778086962025316, -1.458054137931035 ], [ 29.777817468354431, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22801, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.458054137931035 ], [ 29.778086962025316, -1.458323620689655 ], [ 29.778356455696201, -1.458323620689655 ], [ 29.778356455696201, -1.458054137931035 ], [ 29.778086962025316, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22802, "El": 128 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.458054137931035 ], [ 29.778356455696201, -1.458323620689655 ], [ 29.77862594936709, -1.458323620689655 ], [ 29.77862594936709, -1.458054137931035 ], [ 29.778356455696201, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22803, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.458054137931035 ], [ 29.785902278481014, -1.458593103448276 ], [ 29.786171772151899, -1.458593103448276 ], [ 29.786171772151899, -1.458054137931035 ], [ 29.785902278481014, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22804, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.458054137931035 ], [ 29.787249746835442, -1.458593103448276 ], [ 29.787519240506327, -1.458593103448276 ], [ 29.787519240506327, -1.458054137931035 ], [ 29.787249746835442, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22805, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.458054137931035 ], [ 29.788058227848101, -1.458323620689655 ], [ 29.788327721518986, -1.458323620689655 ], [ 29.788327721518986, -1.458054137931035 ], [ 29.788058227848101, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22806, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.458054137931035 ], [ 29.788327721518986, -1.458323620689655 ], [ 29.788597215189874, -1.458323620689655 ], [ 29.788597215189874, -1.458054137931035 ], [ 29.788327721518986, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22807, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.458054137931035 ], [ 29.788597215189874, -1.458323620689655 ], [ 29.788866708860759, -1.458323620689655 ], [ 29.788866708860759, -1.458054137931035 ], [ 29.788597215189874, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22808, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.458054137931035 ], [ 29.792100632911392, -1.458593103448276 ], [ 29.792370126582277, -1.458593103448276 ], [ 29.792370126582277, -1.458054137931035 ], [ 29.792100632911392, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22809, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.458054137931035 ], [ 29.792370126582277, -1.458593103448276 ], [ 29.792909113924051, -1.458593103448276 ], [ 29.792909113924051, -1.458054137931035 ], [ 29.792370126582277, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22810, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.458054137931035 ], [ 29.792909113924051, -1.458593103448276 ], [ 29.793178607594935, -1.458593103448276 ], [ 29.793178607594935, -1.458054137931035 ], [ 29.792909113924051, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22811, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.458054137931035 ], [ 29.793178607594935, -1.458323620689655 ], [ 29.793448101265824, -1.458323620689655 ], [ 29.793448101265824, -1.458054137931035 ], [ 29.793178607594935, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22812, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.458054137931035 ], [ 29.793448101265824, -1.458323620689655 ], [ 29.793717594936709, -1.458323620689655 ], [ 29.793717594936709, -1.458054137931035 ], [ 29.793448101265824, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22813, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.458054137931035 ], [ 29.794526075949367, -1.458323620689655 ], [ 29.794795569620252, -1.458323620689655 ], [ 29.794795569620252, -1.458054137931035 ], [ 29.794526075949367, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22814, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.458054137931035 ], [ 29.794795569620252, -1.458323620689655 ], [ 29.79506506329114, -1.458323620689655 ], [ 29.79506506329114, -1.458054137931035 ], [ 29.794795569620252, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22815, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.458054137931035 ], [ 29.79506506329114, -1.458323620689655 ], [ 29.795334556962025, -1.458323620689655 ], [ 29.795334556962025, -1.458054137931035 ], [ 29.79506506329114, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22816, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.458054137931035 ], [ 29.795334556962025, -1.458323620689655 ], [ 29.79560405063291, -1.458323620689655 ], [ 29.79560405063291, -1.458054137931035 ], [ 29.795334556962025, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22817, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.458054137931035 ], [ 29.79560405063291, -1.458323620689655 ], [ 29.795873544303799, -1.458323620689655 ], [ 29.795873544303799, -1.458054137931035 ], [ 29.79560405063291, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22818, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.458054137931035 ], [ 29.795873544303799, -1.458323620689655 ], [ 29.796143037974684, -1.458323620689655 ], [ 29.796143037974684, -1.458054137931035 ], [ 29.795873544303799, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22819, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.458054137931035 ], [ 29.796143037974684, -1.458323620689655 ], [ 29.796412531645569, -1.458323620689655 ], [ 29.796412531645569, -1.458054137931035 ], [ 29.796143037974684, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22820, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.458054137931035 ], [ 29.796412531645569, -1.458323620689655 ], [ 29.796682025316457, -1.458323620689655 ], [ 29.796682025316457, -1.458593103448276 ], [ 29.796951518987342, -1.458593103448276 ], [ 29.796951518987342, -1.458054137931035 ], [ 29.796412531645569, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22821, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.458054137931035 ], [ 29.796951518987342, -1.458323620689655 ], [ 29.797221012658227, -1.458323620689655 ], [ 29.797221012658227, -1.458054137931035 ], [ 29.796951518987342, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22822, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.458054137931035 ], [ 29.797221012658227, -1.458323620689655 ], [ 29.797490506329115, -1.458323620689655 ], [ 29.797490506329115, -1.458054137931035 ], [ 29.797221012658227, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22823, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.458054137931035 ], [ 29.797490506329115, -1.458323620689655 ], [ 29.79776, -1.458323620689655 ], [ 29.79776, -1.458054137931035 ], [ 29.797490506329115, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22824, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.458054137931035 ], [ 29.79776, -1.458323620689655 ], [ 29.798029493670885, -1.458323620689655 ], [ 29.798029493670885, -1.458054137931035 ], [ 29.79776, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22825, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.458054137931035 ], [ 29.798029493670885, -1.458323620689655 ], [ 29.798298987341774, -1.458323620689655 ], [ 29.798298987341774, -1.458054137931035 ], [ 29.798029493670885, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22826, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.458054137931035 ], [ 29.798298987341774, -1.458323620689655 ], [ 29.798568481012659, -1.458323620689655 ], [ 29.798568481012659, -1.458054137931035 ], [ 29.798298987341774, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22827, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.458054137931035 ], [ 29.798568481012659, -1.458323620689655 ], [ 29.798837974683543, -1.458323620689655 ], [ 29.798837974683543, -1.458054137931035 ], [ 29.798568481012659, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22828, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.458054137931035 ], [ 29.798837974683543, -1.458593103448276 ], [ 29.799107468354432, -1.458593103448276 ], [ 29.799107468354432, -1.458054137931035 ], [ 29.798837974683543, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22829, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.458054137931035 ], [ 29.799646455696202, -1.458323620689655 ], [ 29.79991594936709, -1.458323620689655 ], [ 29.79991594936709, -1.458054137931035 ], [ 29.799646455696202, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22830, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.458054137931035 ], [ 29.79991594936709, -1.458323620689655 ], [ 29.800185443037975, -1.458323620689655 ], [ 29.800185443037975, -1.458054137931035 ], [ 29.79991594936709, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22831, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.458054137931035 ], [ 29.800185443037975, -1.458323620689655 ], [ 29.80045493670886, -1.458323620689655 ], [ 29.80045493670886, -1.458054137931035 ], [ 29.800185443037975, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22832, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.458054137931035 ], [ 29.8074617721519, -1.458323620689655 ], [ 29.807731265822785, -1.458323620689655 ], [ 29.807731265822785, -1.458054137931035 ], [ 29.8074617721519, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22833, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807731265822785, -1.458054137931035 ], [ 29.807731265822785, -1.458323620689655 ], [ 29.808000759493673, -1.458323620689655 ], [ 29.808000759493673, -1.458054137931035 ], [ 29.807731265822785, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22834, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.458054137931035 ], [ 29.808270253164558, -1.458323620689655 ], [ 29.808539746835443, -1.458323620689655 ], [ 29.808539746835443, -1.458054137931035 ], [ 29.808270253164558, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22835, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.458054137931035 ], [ 29.808539746835443, -1.458323620689655 ], [ 29.808809240506328, -1.458323620689655 ], [ 29.808809240506328, -1.458054137931035 ], [ 29.808539746835443, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22836, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.458054137931035 ], [ 29.808809240506328, -1.458323620689655 ], [ 29.809348227848101, -1.458323620689655 ], [ 29.809348227848101, -1.458054137931035 ], [ 29.808809240506328, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22837, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809348227848101, -1.458054137931035 ], [ 29.809348227848101, -1.458323620689655 ], [ 29.809617721518986, -1.458323620689655 ], [ 29.809617721518986, -1.458054137931035 ], [ 29.809348227848101, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22838, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.809617721518986, -1.458054137931035 ], [ 29.809617721518986, -1.458323620689655 ], [ 29.81015670886076, -1.458323620689655 ], [ 29.81015670886076, -1.458054137931035 ], [ 29.809617721518986, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22839, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.810426202531644, -1.458054137931035 ], [ 29.810426202531644, -1.458323620689655 ], [ 29.811234683544303, -1.458323620689655 ], [ 29.811234683544303, -1.458054137931035 ], [ 29.810426202531644, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22840, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.458054137931035 ], [ 29.811234683544303, -1.458323620689655 ], [ 29.811504177215191, -1.458323620689655 ], [ 29.811504177215191, -1.458054137931035 ], [ 29.811234683544303, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22841, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.458054137931035 ], [ 29.811504177215191, -1.458323620689655 ], [ 29.811773670886076, -1.458323620689655 ], [ 29.811773670886076, -1.458054137931035 ], [ 29.811504177215191, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22842, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.458054137931035 ], [ 29.811773670886076, -1.458323620689655 ], [ 29.812043164556961, -1.458323620689655 ], [ 29.812043164556961, -1.458054137931035 ], [ 29.811773670886076, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22843, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.458054137931035 ], [ 29.812043164556961, -1.458323620689655 ], [ 29.812312658227849, -1.458323620689655 ], [ 29.812312658227849, -1.458054137931035 ], [ 29.812043164556961, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22844, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.458054137931035 ], [ 29.812312658227849, -1.458323620689655 ], [ 29.812582151898734, -1.458323620689655 ], [ 29.812582151898734, -1.458054137931035 ], [ 29.812312658227849, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22845, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.458054137931035 ], [ 29.812582151898734, -1.458323620689655 ], [ 29.812851645569619, -1.458323620689655 ], [ 29.812851645569619, -1.458054137931035 ], [ 29.812582151898734, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22846, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.458054137931035 ], [ 29.812851645569619, -1.458323620689655 ], [ 29.813121139240508, -1.458323620689655 ], [ 29.813121139240508, -1.458054137931035 ], [ 29.812851645569619, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22847, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.458054137931035 ], [ 29.813121139240508, -1.458323620689655 ], [ 29.813390632911393, -1.458323620689655 ], [ 29.813390632911393, -1.458054137931035 ], [ 29.813121139240508, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22848, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.751946075949366, -1.458323620689655 ], [ 29.751946075949366, -1.458862586206897 ], [ 29.752215569620251, -1.458862586206897 ], [ 29.752215569620251, -1.458323620689655 ], [ 29.751946075949366, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22849, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.458323620689655 ], [ 29.752215569620251, -1.458593103448276 ], [ 29.75248506329114, -1.458593103448276 ], [ 29.75248506329114, -1.458323620689655 ], [ 29.752215569620251, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22850, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.458323620689655 ], [ 29.75248506329114, -1.458593103448276 ], [ 29.752754556962024, -1.458593103448276 ], [ 29.752754556962024, -1.458323620689655 ], [ 29.75248506329114, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22851, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.458323620689655 ], [ 29.752754556962024, -1.458593103448276 ], [ 29.753024050632909, -1.458593103448276 ], [ 29.753024050632909, -1.458323620689655 ], [ 29.752754556962024, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22852, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.458323620689655 ], [ 29.753024050632909, -1.458593103448276 ], [ 29.753293544303798, -1.458593103448276 ], [ 29.753293544303798, -1.458323620689655 ], [ 29.753024050632909, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22853, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.458323620689655 ], [ 29.753293544303798, -1.458593103448276 ], [ 29.753563037974683, -1.458593103448276 ], [ 29.753563037974683, -1.458323620689655 ], [ 29.753293544303798, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22854, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.458323620689655 ], [ 29.753563037974683, -1.458593103448276 ], [ 29.753832531645568, -1.458593103448276 ], [ 29.753832531645568, -1.458323620689655 ], [ 29.753563037974683, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22855, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.458323620689655 ], [ 29.753832531645568, -1.458593103448276 ], [ 29.754102025316456, -1.458593103448276 ], [ 29.754102025316456, -1.458323620689655 ], [ 29.753832531645568, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22856, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.458323620689655 ], [ 29.754102025316456, -1.458593103448276 ], [ 29.754371518987341, -1.458593103448276 ], [ 29.754371518987341, -1.458323620689655 ], [ 29.754102025316456, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22857, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.458323620689655 ], [ 29.754371518987341, -1.458593103448276 ], [ 29.754641012658226, -1.458593103448276 ], [ 29.754641012658226, -1.458323620689655 ], [ 29.754371518987341, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22858, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.458323620689655 ], [ 29.754641012658226, -1.458593103448276 ], [ 29.754910506329114, -1.458593103448276 ], [ 29.754910506329114, -1.458323620689655 ], [ 29.754641012658226, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22859, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.458323620689655 ], [ 29.754910506329114, -1.458593103448276 ], [ 29.75518, -1.458593103448276 ], [ 29.75518, -1.458323620689655 ], [ 29.754910506329114, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22860, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.458323620689655 ], [ 29.75518, -1.458593103448276 ], [ 29.755449493670884, -1.458593103448276 ], [ 29.755449493670884, -1.458323620689655 ], [ 29.75518, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22861, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.458323620689655 ], [ 29.755449493670884, -1.458593103448276 ], [ 29.755718987341773, -1.458593103448276 ], [ 29.755718987341773, -1.458323620689655 ], [ 29.755449493670884, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22862, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.458323620689655 ], [ 29.755718987341773, -1.458593103448276 ], [ 29.755988481012658, -1.458593103448276 ], [ 29.755988481012658, -1.458323620689655 ], [ 29.755718987341773, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22863, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.458323620689655 ], [ 29.755988481012658, -1.458862586206897 ], [ 29.756257974683542, -1.458862586206897 ], [ 29.756257974683542, -1.458323620689655 ], [ 29.755988481012658, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22864, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.458323620689655 ], [ 29.756257974683542, -1.458593103448276 ], [ 29.756796962025316, -1.458593103448276 ], [ 29.756796962025316, -1.458323620689655 ], [ 29.756257974683542, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22865, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.458323620689655 ], [ 29.756796962025316, -1.458593103448276 ], [ 29.757066455696201, -1.458593103448276 ], [ 29.757066455696201, -1.458323620689655 ], [ 29.756796962025316, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22866, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.458323620689655 ], [ 29.757066455696201, -1.458593103448276 ], [ 29.757335949367089, -1.458593103448276 ], [ 29.757335949367089, -1.458323620689655 ], [ 29.757066455696201, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22867, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.458323620689655 ], [ 29.757335949367089, -1.458593103448276 ], [ 29.757605443037974, -1.458593103448276 ], [ 29.757605443037974, -1.458323620689655 ], [ 29.757335949367089, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22868, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.458323620689655 ], [ 29.757605443037974, -1.458593103448276 ], [ 29.757874936708859, -1.458593103448276 ], [ 29.757874936708859, -1.458323620689655 ], [ 29.757605443037974, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22869, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.458323620689655 ], [ 29.757874936708859, -1.458593103448276 ], [ 29.758144430379748, -1.458593103448276 ], [ 29.758144430379748, -1.458323620689655 ], [ 29.757874936708859, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22870, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.458323620689655 ], [ 29.758144430379748, -1.458862586206897 ], [ 29.758413924050632, -1.458862586206897 ], [ 29.758413924050632, -1.458323620689655 ], [ 29.758144430379748, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22871, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.458323620689655 ], [ 29.758413924050632, -1.458593103448276 ], [ 29.758683417721517, -1.458593103448276 ], [ 29.758683417721517, -1.458323620689655 ], [ 29.758413924050632, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22872, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.458323620689655 ], [ 29.758683417721517, -1.458593103448276 ], [ 29.758952911392406, -1.458593103448276 ], [ 29.758952911392406, -1.458323620689655 ], [ 29.758683417721517, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22873, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.458323620689655 ], [ 29.758952911392406, -1.458593103448276 ], [ 29.759222405063291, -1.458593103448276 ], [ 29.759222405063291, -1.458323620689655 ], [ 29.758952911392406, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22874, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.458323620689655 ], [ 29.759222405063291, -1.458593103448276 ], [ 29.759491898734176, -1.458593103448276 ], [ 29.759491898734176, -1.458323620689655 ], [ 29.759222405063291, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22875, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.458323620689655 ], [ 29.759491898734176, -1.458593103448276 ], [ 29.759761392405064, -1.458593103448276 ], [ 29.759761392405064, -1.458323620689655 ], [ 29.759491898734176, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22876, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.458323620689655 ], [ 29.759761392405064, -1.458593103448276 ], [ 29.760030886075949, -1.458593103448276 ], [ 29.760030886075949, -1.458323620689655 ], [ 29.759761392405064, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22877, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.458323620689655 ], [ 29.760030886075949, -1.458593103448276 ], [ 29.760300379746834, -1.458593103448276 ], [ 29.760300379746834, -1.458323620689655 ], [ 29.760030886075949, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22878, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.458323620689655 ], [ 29.760300379746834, -1.458593103448276 ], [ 29.760569873417722, -1.458593103448276 ], [ 29.760569873417722, -1.458323620689655 ], [ 29.760300379746834, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22879, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.458323620689655 ], [ 29.760569873417722, -1.458593103448276 ], [ 29.760839367088607, -1.458593103448276 ], [ 29.760839367088607, -1.458323620689655 ], [ 29.760569873417722, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22880, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.458323620689655 ], [ 29.760839367088607, -1.458593103448276 ], [ 29.761108860759492, -1.458593103448276 ], [ 29.761108860759492, -1.458323620689655 ], [ 29.760839367088607, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22881, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.458323620689655 ], [ 29.761108860759492, -1.458593103448276 ], [ 29.761378354430381, -1.458593103448276 ], [ 29.761378354430381, -1.458323620689655 ], [ 29.761108860759492, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22882, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.458323620689655 ], [ 29.761378354430381, -1.458593103448276 ], [ 29.761647848101266, -1.458593103448276 ], [ 29.761647848101266, -1.458323620689655 ], [ 29.761378354430381, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22883, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.458323620689655 ], [ 29.761647848101266, -1.458593103448276 ], [ 29.761917341772151, -1.458593103448276 ], [ 29.761917341772151, -1.458323620689655 ], [ 29.761647848101266, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22884, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.458323620689655 ], [ 29.761917341772151, -1.458593103448276 ], [ 29.762186835443039, -1.458593103448276 ], [ 29.762186835443039, -1.458323620689655 ], [ 29.761917341772151, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22885, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.458323620689655 ], [ 29.762186835443039, -1.458593103448276 ], [ 29.763803797468356, -1.458593103448276 ], [ 29.763803797468356, -1.458323620689655 ], [ 29.762186835443039, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22886, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.458323620689655 ], [ 29.763803797468356, -1.458593103448276 ], [ 29.76407329113924, -1.458593103448276 ], [ 29.76407329113924, -1.458323620689655 ], [ 29.763803797468356, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22887, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.458323620689655 ], [ 29.76407329113924, -1.458593103448276 ], [ 29.764342784810125, -1.458593103448276 ], [ 29.764342784810125, -1.458323620689655 ], [ 29.76407329113924, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22888, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.458323620689655 ], [ 29.764342784810125, -1.458593103448276 ], [ 29.764612278481014, -1.458593103448276 ], [ 29.764612278481014, -1.458323620689655 ], [ 29.764342784810125, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22889, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.458323620689655 ], [ 29.764612278481014, -1.458593103448276 ], [ 29.764881772151899, -1.458593103448276 ], [ 29.764881772151899, -1.458323620689655 ], [ 29.764612278481014, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22890, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.458323620689655 ], [ 29.764881772151899, -1.458593103448276 ], [ 29.765151265822784, -1.458593103448276 ], [ 29.765151265822784, -1.458323620689655 ], [ 29.764881772151899, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22891, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.458323620689655 ], [ 29.765151265822784, -1.458593103448276 ], [ 29.765420759493672, -1.458593103448276 ], [ 29.765420759493672, -1.458323620689655 ], [ 29.765151265822784, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22892, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.458323620689655 ], [ 29.765420759493672, -1.458593103448276 ], [ 29.765959746835442, -1.458593103448276 ], [ 29.765959746835442, -1.458323620689655 ], [ 29.765420759493672, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22893, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.458323620689655 ], [ 29.765959746835442, -1.458593103448276 ], [ 29.766229240506327, -1.458593103448276 ], [ 29.766229240506327, -1.458323620689655 ], [ 29.765959746835442, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22894, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.458323620689655 ], [ 29.766229240506327, -1.458593103448276 ], [ 29.766498734177215, -1.458593103448276 ], [ 29.766498734177215, -1.458323620689655 ], [ 29.766229240506327, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22895, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.458323620689655 ], [ 29.766498734177215, -1.458593103448276 ], [ 29.7667682278481, -1.458593103448276 ], [ 29.7667682278481, -1.458323620689655 ], [ 29.766498734177215, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22896, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.458323620689655 ], [ 29.7667682278481, -1.458593103448276 ], [ 29.76892417721519, -1.458593103448276 ], [ 29.76892417721519, -1.458323620689655 ], [ 29.7667682278481, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22897, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.458323620689655 ], [ 29.76892417721519, -1.458593103448276 ], [ 29.769193670886075, -1.458593103448276 ], [ 29.769193670886075, -1.458323620689655 ], [ 29.76892417721519, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22898, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.458323620689655 ], [ 29.769193670886075, -1.458593103448276 ], [ 29.76946316455696, -1.458593103448276 ], [ 29.76946316455696, -1.458323620689655 ], [ 29.769193670886075, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22899, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.458323620689655 ], [ 29.76946316455696, -1.458593103448276 ], [ 29.769732658227849, -1.458593103448276 ], [ 29.769732658227849, -1.458323620689655 ], [ 29.76946316455696, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22900, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.458323620689655 ], [ 29.769732658227849, -1.458593103448276 ], [ 29.770002151898733, -1.458593103448276 ], [ 29.770002151898733, -1.458323620689655 ], [ 29.769732658227849, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22901, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.458323620689655 ], [ 29.770002151898733, -1.458593103448276 ], [ 29.770271645569618, -1.458593103448276 ], [ 29.770271645569618, -1.458323620689655 ], [ 29.770002151898733, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22902, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.458323620689655 ], [ 29.770271645569618, -1.458593103448276 ], [ 29.770541139240507, -1.458593103448276 ], [ 29.770541139240507, -1.458323620689655 ], [ 29.770271645569618, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22903, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.458323620689655 ], [ 29.770541139240507, -1.458593103448276 ], [ 29.770810632911392, -1.458593103448276 ], [ 29.770810632911392, -1.458323620689655 ], [ 29.770541139240507, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22904, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.458323620689655 ], [ 29.770810632911392, -1.458593103448276 ], [ 29.771080126582277, -1.458593103448276 ], [ 29.771080126582277, -1.458323620689655 ], [ 29.770810632911392, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22905, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.458323620689655 ], [ 29.771080126582277, -1.458593103448276 ], [ 29.771349620253165, -1.458593103448276 ], [ 29.771349620253165, -1.458323620689655 ], [ 29.771080126582277, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22906, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.458323620689655 ], [ 29.771349620253165, -1.458593103448276 ], [ 29.77161911392405, -1.458593103448276 ], [ 29.77161911392405, -1.458323620689655 ], [ 29.771349620253165, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22907, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.458323620689655 ], [ 29.77161911392405, -1.458593103448276 ], [ 29.771888607594935, -1.458593103448276 ], [ 29.771888607594935, -1.458323620689655 ], [ 29.77161911392405, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22908, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.458323620689655 ], [ 29.771888607594935, -1.458593103448276 ], [ 29.772158101265823, -1.458593103448276 ], [ 29.772158101265823, -1.458323620689655 ], [ 29.771888607594935, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22909, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.458323620689655 ], [ 29.772158101265823, -1.458593103448276 ], [ 29.772427594936708, -1.458593103448276 ], [ 29.772427594936708, -1.458323620689655 ], [ 29.772158101265823, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22910, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.458323620689655 ], [ 29.772427594936708, -1.458862586206897 ], [ 29.772697088607593, -1.458862586206897 ], [ 29.772697088607593, -1.458323620689655 ], [ 29.772427594936708, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22911, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.458323620689655 ], [ 29.772697088607593, -1.458593103448276 ], [ 29.772966582278482, -1.458593103448276 ], [ 29.772966582278482, -1.458323620689655 ], [ 29.772697088607593, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22912, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.458323620689655 ], [ 29.772966582278482, -1.458593103448276 ], [ 29.773236075949367, -1.458593103448276 ], [ 29.773236075949367, -1.458323620689655 ], [ 29.772966582278482, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22913, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.458323620689655 ], [ 29.773236075949367, -1.458593103448276 ], [ 29.773505569620252, -1.458593103448276 ], [ 29.773505569620252, -1.458323620689655 ], [ 29.773236075949367, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22914, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.458323620689655 ], [ 29.773505569620252, -1.458593103448276 ], [ 29.77377506329114, -1.458593103448276 ], [ 29.77377506329114, -1.458323620689655 ], [ 29.773505569620252, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22915, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.458323620689655 ], [ 29.77377506329114, -1.458593103448276 ], [ 29.774044556962025, -1.458593103448276 ], [ 29.774044556962025, -1.458323620689655 ], [ 29.77377506329114, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22916, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.458323620689655 ], [ 29.774853037974683, -1.458593103448276 ], [ 29.775122531645568, -1.458593103448276 ], [ 29.775122531645568, -1.458323620689655 ], [ 29.774853037974683, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22917, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.458323620689655 ], [ 29.775661518987341, -1.458593103448276 ], [ 29.775931012658226, -1.458593103448276 ], [ 29.775931012658226, -1.458323620689655 ], [ 29.775661518987341, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22918, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.458323620689655 ], [ 29.775931012658226, -1.458593103448276 ], [ 29.776200506329115, -1.458593103448276 ], [ 29.776200506329115, -1.458323620689655 ], [ 29.775931012658226, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22919, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.458323620689655 ], [ 29.776200506329115, -1.458593103448276 ], [ 29.77647, -1.458593103448276 ], [ 29.77647, -1.458323620689655 ], [ 29.776200506329115, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22920, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.458323620689655 ], [ 29.77647, -1.458593103448276 ], [ 29.776739493670885, -1.458593103448276 ], [ 29.776739493670885, -1.458323620689655 ], [ 29.77647, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22921, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.458323620689655 ], [ 29.776739493670885, -1.458593103448276 ], [ 29.777008987341773, -1.458593103448276 ], [ 29.777008987341773, -1.458323620689655 ], [ 29.776739493670885, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22922, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.458323620689655 ], [ 29.777008987341773, -1.458593103448276 ], [ 29.777278481012658, -1.458593103448276 ], [ 29.777278481012658, -1.458323620689655 ], [ 29.777008987341773, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22923, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.458323620689655 ], [ 29.777278481012658, -1.458593103448276 ], [ 29.777547974683543, -1.458593103448276 ], [ 29.777547974683543, -1.458323620689655 ], [ 29.777278481012658, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22924, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.458323620689655 ], [ 29.777547974683543, -1.458593103448276 ], [ 29.777817468354431, -1.458593103448276 ], [ 29.777817468354431, -1.458323620689655 ], [ 29.777547974683543, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22925, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.458323620689655 ], [ 29.777817468354431, -1.458593103448276 ], [ 29.778086962025316, -1.458593103448276 ], [ 29.778086962025316, -1.458323620689655 ], [ 29.777817468354431, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22926, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.458323620689655 ], [ 29.778086962025316, -1.458593103448276 ], [ 29.778356455696201, -1.458593103448276 ], [ 29.778356455696201, -1.458323620689655 ], [ 29.778086962025316, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22927, "El": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.458323620689655 ], [ 29.778356455696201, -1.458593103448276 ], [ 29.77862594936709, -1.458593103448276 ], [ 29.77862594936709, -1.458323620689655 ], [ 29.778356455696201, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22928, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.458323620689655 ], [ 29.77862594936709, -1.458593103448276 ], [ 29.778895443037975, -1.458593103448276 ], [ 29.778895443037975, -1.458323620689655 ], [ 29.77862594936709, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22929, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.458323620689655 ], [ 29.780242911392406, -1.458593103448276 ], [ 29.780512405063291, -1.458593103448276 ], [ 29.780512405063291, -1.458323620689655 ], [ 29.780242911392406, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22930, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.458323620689655 ], [ 29.782937848101266, -1.458862586206897 ], [ 29.783207341772151, -1.458862586206897 ], [ 29.783207341772151, -1.458323620689655 ], [ 29.782937848101266, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22931, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.458323620689655 ], [ 29.784015822784809, -1.458862586206897 ], [ 29.784285316455698, -1.458862586206897 ], [ 29.784285316455698, -1.458323620689655 ], [ 29.784015822784809, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22932, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.458323620689655 ], [ 29.784554810126583, -1.458862586206897 ], [ 29.784824303797468, -1.458862586206897 ], [ 29.784824303797468, -1.458323620689655 ], [ 29.784554810126583, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22933, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.458323620689655 ], [ 29.785363291139241, -1.458862586206897 ], [ 29.785632784810126, -1.458862586206897 ], [ 29.785632784810126, -1.458323620689655 ], [ 29.785363291139241, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22934, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.458323620689655 ], [ 29.786171772151899, -1.458862586206897 ], [ 29.786441265822784, -1.458862586206897 ], [ 29.786441265822784, -1.458323620689655 ], [ 29.786171772151899, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22935, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.458323620689655 ], [ 29.786710759493673, -1.458593103448276 ], [ 29.786980253164558, -1.458593103448276 ], [ 29.786980253164558, -1.458323620689655 ], [ 29.786710759493673, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22936, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.458323620689655 ], [ 29.786980253164558, -1.458593103448276 ], [ 29.787249746835442, -1.458593103448276 ], [ 29.787249746835442, -1.458323620689655 ], [ 29.786980253164558, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22937, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.458323620689655 ], [ 29.787519240506327, -1.458593103448276 ], [ 29.787788734177216, -1.458593103448276 ], [ 29.787788734177216, -1.458323620689655 ], [ 29.787519240506327, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22938, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.458323620689655 ], [ 29.787788734177216, -1.458593103448276 ], [ 29.788058227848101, -1.458593103448276 ], [ 29.788058227848101, -1.458323620689655 ], [ 29.787788734177216, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22939, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.458323620689655 ], [ 29.788058227848101, -1.458593103448276 ], [ 29.788327721518986, -1.458593103448276 ], [ 29.788327721518986, -1.458323620689655 ], [ 29.788058227848101, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22940, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.458323620689655 ], [ 29.793178607594935, -1.458593103448276 ], [ 29.793448101265824, -1.458593103448276 ], [ 29.793448101265824, -1.458323620689655 ], [ 29.793178607594935, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22941, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.458323620689655 ], [ 29.793448101265824, -1.458593103448276 ], [ 29.793717594936709, -1.458593103448276 ], [ 29.793717594936709, -1.458323620689655 ], [ 29.793448101265824, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22942, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.458323620689655 ], [ 29.793717594936709, -1.458593103448276 ], [ 29.793987088607594, -1.458593103448276 ], [ 29.793987088607594, -1.458323620689655 ], [ 29.793717594936709, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22943, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.458323620689655 ], [ 29.793987088607594, -1.458593103448276 ], [ 29.794256582278482, -1.458593103448276 ], [ 29.794256582278482, -1.458323620689655 ], [ 29.793987088607594, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22944, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.458323620689655 ], [ 29.794256582278482, -1.458593103448276 ], [ 29.794526075949367, -1.458593103448276 ], [ 29.794526075949367, -1.458323620689655 ], [ 29.794256582278482, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22945, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.458323620689655 ], [ 29.794526075949367, -1.458593103448276 ], [ 29.794795569620252, -1.458593103448276 ], [ 29.794795569620252, -1.458323620689655 ], [ 29.794526075949367, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22946, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.458323620689655 ], [ 29.794795569620252, -1.458593103448276 ], [ 29.79506506329114, -1.458593103448276 ], [ 29.79506506329114, -1.458323620689655 ], [ 29.794795569620252, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22947, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.458323620689655 ], [ 29.79506506329114, -1.458593103448276 ], [ 29.795334556962025, -1.458593103448276 ], [ 29.795334556962025, -1.458323620689655 ], [ 29.79506506329114, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22948, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.458323620689655 ], [ 29.795334556962025, -1.458593103448276 ], [ 29.79560405063291, -1.458593103448276 ], [ 29.79560405063291, -1.458323620689655 ], [ 29.795334556962025, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22949, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.458323620689655 ], [ 29.79560405063291, -1.458593103448276 ], [ 29.795873544303799, -1.458593103448276 ], [ 29.795873544303799, -1.458323620689655 ], [ 29.79560405063291, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22950, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.458323620689655 ], [ 29.795873544303799, -1.458593103448276 ], [ 29.796143037974684, -1.458593103448276 ], [ 29.796143037974684, -1.458323620689655 ], [ 29.795873544303799, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22951, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.458323620689655 ], [ 29.796143037974684, -1.458593103448276 ], [ 29.796412531645569, -1.458593103448276 ], [ 29.796412531645569, -1.458323620689655 ], [ 29.796143037974684, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22952, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.458323620689655 ], [ 29.796412531645569, -1.458593103448276 ], [ 29.796682025316457, -1.458593103448276 ], [ 29.796682025316457, -1.458323620689655 ], [ 29.796412531645569, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22953, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.458323620689655 ], [ 29.796951518987342, -1.458593103448276 ], [ 29.797221012658227, -1.458593103448276 ], [ 29.797221012658227, -1.458323620689655 ], [ 29.796951518987342, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22954, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.458323620689655 ], [ 29.797221012658227, -1.458593103448276 ], [ 29.797490506329115, -1.458593103448276 ], [ 29.797490506329115, -1.458323620689655 ], [ 29.797221012658227, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22955, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.458323620689655 ], [ 29.797490506329115, -1.458593103448276 ], [ 29.79776, -1.458593103448276 ], [ 29.79776, -1.458323620689655 ], [ 29.797490506329115, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22956, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.458323620689655 ], [ 29.79776, -1.458862586206897 ], [ 29.798029493670885, -1.458862586206897 ], [ 29.798029493670885, -1.458323620689655 ], [ 29.79776, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22957, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.458323620689655 ], [ 29.798029493670885, -1.458593103448276 ], [ 29.798298987341774, -1.458593103448276 ], [ 29.798298987341774, -1.458323620689655 ], [ 29.798029493670885, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22958, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.458323620689655 ], [ 29.798298987341774, -1.458593103448276 ], [ 29.798568481012659, -1.458593103448276 ], [ 29.798568481012659, -1.458323620689655 ], [ 29.798298987341774, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22959, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.458323620689655 ], [ 29.798568481012659, -1.458593103448276 ], [ 29.798837974683543, -1.458593103448276 ], [ 29.798837974683543, -1.458323620689655 ], [ 29.798568481012659, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22960, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.458323620689655 ], [ 29.799107468354432, -1.458593103448276 ], [ 29.799376962025317, -1.458593103448276 ], [ 29.799376962025317, -1.458323620689655 ], [ 29.799107468354432, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22961, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.458323620689655 ], [ 29.799376962025317, -1.458593103448276 ], [ 29.799646455696202, -1.458593103448276 ], [ 29.799646455696202, -1.458323620689655 ], [ 29.799376962025317, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22962, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.458323620689655 ], [ 29.799646455696202, -1.458593103448276 ], [ 29.79991594936709, -1.458593103448276 ], [ 29.79991594936709, -1.458323620689655 ], [ 29.799646455696202, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22963, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.458323620689655 ], [ 29.8074617721519, -1.458593103448276 ], [ 29.807731265822785, -1.458593103448276 ], [ 29.807731265822785, -1.458323620689655 ], [ 29.8074617721519, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22964, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.458054137931035 ], [ 29.808270253164558, -1.458054137931035 ], [ 29.808270253164558, -1.458593103448276 ], [ 29.807731265822785, -1.458593103448276 ], [ 29.807731265822785, -1.458323620689655 ], [ 29.808000759493673, -1.458323620689655 ], [ 29.808000759493673, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22965, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.458323620689655 ], [ 29.808270253164558, -1.458593103448276 ], [ 29.808539746835443, -1.458593103448276 ], [ 29.808539746835443, -1.458323620689655 ], [ 29.808270253164558, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22966, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808539746835443, -1.458323620689655 ], [ 29.808539746835443, -1.458593103448276 ], [ 29.808809240506328, -1.458593103448276 ], [ 29.808809240506328, -1.458323620689655 ], [ 29.808539746835443, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22967, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808809240506328, -1.458323620689655 ], [ 29.808809240506328, -1.458593103448276 ], [ 29.809617721518986, -1.458593103448276 ], [ 29.809617721518986, -1.458323620689655 ], [ 29.808809240506328, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22968, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811234683544303, -1.458323620689655 ], [ 29.811234683544303, -1.458593103448276 ], [ 29.811504177215191, -1.458593103448276 ], [ 29.811504177215191, -1.458323620689655 ], [ 29.811234683544303, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22969, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.458323620689655 ], [ 29.811504177215191, -1.458593103448276 ], [ 29.811773670886076, -1.458593103448276 ], [ 29.811773670886076, -1.458323620689655 ], [ 29.811504177215191, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22970, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811773670886076, -1.458323620689655 ], [ 29.811773670886076, -1.458593103448276 ], [ 29.812043164556961, -1.458593103448276 ], [ 29.812043164556961, -1.458323620689655 ], [ 29.811773670886076, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22971, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.458323620689655 ], [ 29.812043164556961, -1.458593103448276 ], [ 29.812312658227849, -1.458593103448276 ], [ 29.812312658227849, -1.458323620689655 ], [ 29.812043164556961, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22972, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.458323620689655 ], [ 29.812312658227849, -1.458593103448276 ], [ 29.812582151898734, -1.458593103448276 ], [ 29.812582151898734, -1.458323620689655 ], [ 29.812312658227849, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22973, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.458323620689655 ], [ 29.812582151898734, -1.458593103448276 ], [ 29.812851645569619, -1.458593103448276 ], [ 29.812851645569619, -1.458323620689655 ], [ 29.812582151898734, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22974, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.458323620689655 ], [ 29.812851645569619, -1.458593103448276 ], [ 29.813121139240508, -1.458593103448276 ], [ 29.813121139240508, -1.458323620689655 ], [ 29.812851645569619, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22975, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.458323620689655 ], [ 29.813121139240508, -1.458593103448276 ], [ 29.813390632911393, -1.458593103448276 ], [ 29.813390632911393, -1.458323620689655 ], [ 29.813121139240508, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22976, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.457784655172414 ], [ 29.813929620253166, -1.457784655172414 ], [ 29.813929620253166, -1.458862586206897 ], [ 29.813660126582278, -1.458862586206897 ], [ 29.813660126582278, -1.458593103448276 ], [ 29.813390632911393, -1.458593103448276 ], [ 29.813390632911393, -1.458323620689655 ], [ 29.813660126582278, -1.458323620689655 ], [ 29.813660126582278, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22977, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.458593103448276 ], [ 29.752215569620251, -1.458862586206897 ], [ 29.75248506329114, -1.458862586206897 ], [ 29.75248506329114, -1.458593103448276 ], [ 29.752215569620251, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22978, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.458593103448276 ], [ 29.75248506329114, -1.458862586206897 ], [ 29.752754556962024, -1.458862586206897 ], [ 29.752754556962024, -1.458593103448276 ], [ 29.75248506329114, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22979, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.458593103448276 ], [ 29.752754556962024, -1.458862586206897 ], [ 29.753024050632909, -1.458862586206897 ], [ 29.753024050632909, -1.458593103448276 ], [ 29.752754556962024, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.458593103448276 ], [ 29.753024050632909, -1.458862586206897 ], [ 29.753293544303798, -1.458862586206897 ], [ 29.753293544303798, -1.458593103448276 ], [ 29.753024050632909, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22981, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.458593103448276 ], [ 29.753293544303798, -1.458862586206897 ], [ 29.753563037974683, -1.458862586206897 ], [ 29.753563037974683, -1.458593103448276 ], [ 29.753293544303798, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22982, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.458593103448276 ], [ 29.753563037974683, -1.458862586206897 ], [ 29.753832531645568, -1.458862586206897 ], [ 29.753832531645568, -1.458593103448276 ], [ 29.753563037974683, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22983, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.458593103448276 ], [ 29.753832531645568, -1.458862586206897 ], [ 29.754102025316456, -1.458862586206897 ], [ 29.754102025316456, -1.458593103448276 ], [ 29.753832531645568, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22984, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.458593103448276 ], [ 29.754102025316456, -1.458862586206897 ], [ 29.754371518987341, -1.458862586206897 ], [ 29.754371518987341, -1.458593103448276 ], [ 29.754102025316456, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22985, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.458593103448276 ], [ 29.754371518987341, -1.458862586206897 ], [ 29.754641012658226, -1.458862586206897 ], [ 29.754641012658226, -1.458593103448276 ], [ 29.754371518987341, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22986, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.458593103448276 ], [ 29.754641012658226, -1.458862586206897 ], [ 29.754910506329114, -1.458862586206897 ], [ 29.754910506329114, -1.458593103448276 ], [ 29.754641012658226, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22987, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.458593103448276 ], [ 29.75518, -1.458862586206897 ], [ 29.755449493670884, -1.458862586206897 ], [ 29.755449493670884, -1.458593103448276 ], [ 29.75518, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22988, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.458593103448276 ], [ 29.755449493670884, -1.458862586206897 ], [ 29.755718987341773, -1.458862586206897 ], [ 29.755718987341773, -1.458593103448276 ], [ 29.755449493670884, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22989, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.458593103448276 ], [ 29.755718987341773, -1.458862586206897 ], [ 29.755988481012658, -1.458862586206897 ], [ 29.755988481012658, -1.458593103448276 ], [ 29.755718987341773, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22990, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.458593103448276 ], [ 29.756257974683542, -1.458862586206897 ], [ 29.756796962025316, -1.458862586206897 ], [ 29.756796962025316, -1.458593103448276 ], [ 29.756257974683542, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22991, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.458593103448276 ], [ 29.756796962025316, -1.458862586206897 ], [ 29.757066455696201, -1.458862586206897 ], [ 29.757066455696201, -1.458593103448276 ], [ 29.756796962025316, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22992, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.458593103448276 ], [ 29.757066455696201, -1.458862586206897 ], [ 29.757335949367089, -1.458862586206897 ], [ 29.757335949367089, -1.458593103448276 ], [ 29.757066455696201, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22993, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.458593103448276 ], [ 29.757335949367089, -1.458862586206897 ], [ 29.757605443037974, -1.458862586206897 ], [ 29.757605443037974, -1.458593103448276 ], [ 29.757335949367089, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22994, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.458593103448276 ], [ 29.757874936708859, -1.458862586206897 ], [ 29.758144430379748, -1.458862586206897 ], [ 29.758144430379748, -1.458593103448276 ], [ 29.757874936708859, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22995, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.458593103448276 ], [ 29.758413924050632, -1.458862586206897 ], [ 29.758683417721517, -1.458862586206897 ], [ 29.758683417721517, -1.458593103448276 ], [ 29.758413924050632, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22996, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.458593103448276 ], [ 29.758683417721517, -1.458862586206897 ], [ 29.758952911392406, -1.458862586206897 ], [ 29.758952911392406, -1.458593103448276 ], [ 29.758683417721517, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22997, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.458593103448276 ], [ 29.758952911392406, -1.458862586206897 ], [ 29.759222405063291, -1.458862586206897 ], [ 29.759222405063291, -1.458593103448276 ], [ 29.758952911392406, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22998, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.458593103448276 ], [ 29.759222405063291, -1.458862586206897 ], [ 29.759491898734176, -1.458862586206897 ], [ 29.759491898734176, -1.458593103448276 ], [ 29.759222405063291, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 22999, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.458593103448276 ], [ 29.759491898734176, -1.458862586206897 ], [ 29.759761392405064, -1.458862586206897 ], [ 29.759761392405064, -1.458593103448276 ], [ 29.759491898734176, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23000, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.458593103448276 ], [ 29.759761392405064, -1.458862586206897 ], [ 29.760030886075949, -1.458862586206897 ], [ 29.760030886075949, -1.458593103448276 ], [ 29.759761392405064, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23001, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.458593103448276 ], [ 29.760030886075949, -1.458862586206897 ], [ 29.760300379746834, -1.458862586206897 ], [ 29.760300379746834, -1.458593103448276 ], [ 29.760030886075949, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23002, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.458593103448276 ], [ 29.760300379746834, -1.458862586206897 ], [ 29.760569873417722, -1.458862586206897 ], [ 29.760569873417722, -1.458593103448276 ], [ 29.760300379746834, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23003, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.458593103448276 ], [ 29.760569873417722, -1.458862586206897 ], [ 29.760839367088607, -1.458862586206897 ], [ 29.760839367088607, -1.458593103448276 ], [ 29.760569873417722, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23004, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.458593103448276 ], [ 29.760839367088607, -1.458862586206897 ], [ 29.761108860759492, -1.458862586206897 ], [ 29.761108860759492, -1.458593103448276 ], [ 29.760839367088607, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23005, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.458593103448276 ], [ 29.761108860759492, -1.458862586206897 ], [ 29.761378354430381, -1.458862586206897 ], [ 29.761378354430381, -1.458593103448276 ], [ 29.761108860759492, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23006, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.458593103448276 ], [ 29.761378354430381, -1.458862586206897 ], [ 29.761647848101266, -1.458862586206897 ], [ 29.761647848101266, -1.458593103448276 ], [ 29.761378354430381, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23007, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.458593103448276 ], [ 29.761647848101266, -1.458862586206897 ], [ 29.761917341772151, -1.458862586206897 ], [ 29.761917341772151, -1.458593103448276 ], [ 29.761647848101266, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23008, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.458593103448276 ], [ 29.761917341772151, -1.458862586206897 ], [ 29.762456329113924, -1.458862586206897 ], [ 29.762456329113924, -1.458593103448276 ], [ 29.761917341772151, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23009, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.458593103448276 ], [ 29.762456329113924, -1.458862586206897 ], [ 29.763534303797467, -1.458862586206897 ], [ 29.763534303797467, -1.458593103448276 ], [ 29.762456329113924, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23010, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.458593103448276 ], [ 29.763534303797467, -1.458862586206897 ], [ 29.76407329113924, -1.458862586206897 ], [ 29.76407329113924, -1.458593103448276 ], [ 29.763534303797467, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23011, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.458593103448276 ], [ 29.76407329113924, -1.458862586206897 ], [ 29.764342784810125, -1.458862586206897 ], [ 29.764342784810125, -1.458593103448276 ], [ 29.76407329113924, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23012, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.458593103448276 ], [ 29.764342784810125, -1.458862586206897 ], [ 29.764612278481014, -1.458862586206897 ], [ 29.764612278481014, -1.458593103448276 ], [ 29.764342784810125, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23013, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.458593103448276 ], [ 29.764612278481014, -1.458862586206897 ], [ 29.764881772151899, -1.458862586206897 ], [ 29.764881772151899, -1.458593103448276 ], [ 29.764612278481014, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23014, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.458593103448276 ], [ 29.764881772151899, -1.458862586206897 ], [ 29.765151265822784, -1.458862586206897 ], [ 29.765151265822784, -1.458593103448276 ], [ 29.764881772151899, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23015, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.458593103448276 ], [ 29.765151265822784, -1.458862586206897 ], [ 29.765959746835442, -1.458862586206897 ], [ 29.765959746835442, -1.458593103448276 ], [ 29.765151265822784, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23016, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.458593103448276 ], [ 29.765959746835442, -1.458862586206897 ], [ 29.766229240506327, -1.458862586206897 ], [ 29.766229240506327, -1.458593103448276 ], [ 29.765959746835442, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23017, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.458593103448276 ], [ 29.766229240506327, -1.458862586206897 ], [ 29.766498734177215, -1.458862586206897 ], [ 29.766498734177215, -1.458593103448276 ], [ 29.766229240506327, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23018, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.458593103448276 ], [ 29.766498734177215, -1.458862586206897 ], [ 29.767037721518985, -1.458862586206897 ], [ 29.767037721518985, -1.458593103448276 ], [ 29.766498734177215, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23019, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.458593103448276 ], [ 29.767037721518985, -1.458862586206897 ], [ 29.768654683544302, -1.458862586206897 ], [ 29.768654683544302, -1.458593103448276 ], [ 29.767037721518985, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23020, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.458593103448276 ], [ 29.768654683544302, -1.458862586206897 ], [ 29.769193670886075, -1.458862586206897 ], [ 29.769193670886075, -1.458593103448276 ], [ 29.768654683544302, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23021, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.458593103448276 ], [ 29.769193670886075, -1.458862586206897 ], [ 29.76946316455696, -1.458862586206897 ], [ 29.76946316455696, -1.458593103448276 ], [ 29.769193670886075, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23022, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.458593103448276 ], [ 29.76946316455696, -1.458862586206897 ], [ 29.769732658227849, -1.458862586206897 ], [ 29.769732658227849, -1.458593103448276 ], [ 29.76946316455696, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23023, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.458593103448276 ], [ 29.769732658227849, -1.458862586206897 ], [ 29.770002151898733, -1.458862586206897 ], [ 29.770002151898733, -1.458593103448276 ], [ 29.769732658227849, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23024, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.458593103448276 ], [ 29.770002151898733, -1.458862586206897 ], [ 29.770271645569618, -1.458862586206897 ], [ 29.770271645569618, -1.458593103448276 ], [ 29.770002151898733, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23025, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.458593103448276 ], [ 29.770271645569618, -1.458862586206897 ], [ 29.770541139240507, -1.458862586206897 ], [ 29.770541139240507, -1.458593103448276 ], [ 29.770271645569618, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23026, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.458593103448276 ], [ 29.770810632911392, -1.458862586206897 ], [ 29.771080126582277, -1.458862586206897 ], [ 29.771080126582277, -1.458593103448276 ], [ 29.770810632911392, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23027, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.458593103448276 ], [ 29.771080126582277, -1.458862586206897 ], [ 29.771349620253165, -1.458862586206897 ], [ 29.771349620253165, -1.458593103448276 ], [ 29.771080126582277, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23028, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.458593103448276 ], [ 29.771349620253165, -1.458862586206897 ], [ 29.77161911392405, -1.458862586206897 ], [ 29.77161911392405, -1.458593103448276 ], [ 29.771349620253165, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23029, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.458593103448276 ], [ 29.77161911392405, -1.458862586206897 ], [ 29.771888607594935, -1.458862586206897 ], [ 29.771888607594935, -1.458593103448276 ], [ 29.77161911392405, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23030, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.458593103448276 ], [ 29.771888607594935, -1.458862586206897 ], [ 29.772158101265823, -1.458862586206897 ], [ 29.772158101265823, -1.458593103448276 ], [ 29.771888607594935, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23031, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.458593103448276 ], [ 29.772158101265823, -1.458862586206897 ], [ 29.772427594936708, -1.458862586206897 ], [ 29.772427594936708, -1.458593103448276 ], [ 29.772158101265823, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23032, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.458593103448276 ], [ 29.772697088607593, -1.458862586206897 ], [ 29.772966582278482, -1.458862586206897 ], [ 29.772966582278482, -1.458593103448276 ], [ 29.772697088607593, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23033, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.458593103448276 ], [ 29.772966582278482, -1.458862586206897 ], [ 29.773236075949367, -1.458862586206897 ], [ 29.773236075949367, -1.458593103448276 ], [ 29.772966582278482, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23034, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.458593103448276 ], [ 29.773236075949367, -1.458862586206897 ], [ 29.773505569620252, -1.458862586206897 ], [ 29.773505569620252, -1.458593103448276 ], [ 29.773236075949367, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23035, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.458593103448276 ], [ 29.773505569620252, -1.458862586206897 ], [ 29.77377506329114, -1.458862586206897 ], [ 29.77377506329114, -1.458593103448276 ], [ 29.773505569620252, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23036, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.458593103448276 ], [ 29.77377506329114, -1.458862586206897 ], [ 29.774044556962025, -1.458862586206897 ], [ 29.774044556962025, -1.458593103448276 ], [ 29.77377506329114, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23037, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.458593103448276 ], [ 29.774044556962025, -1.458862586206897 ], [ 29.77431405063291, -1.458862586206897 ], [ 29.77431405063291, -1.458593103448276 ], [ 29.774044556962025, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23038, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.458593103448276 ], [ 29.77431405063291, -1.458862586206897 ], [ 29.774853037974683, -1.458862586206897 ], [ 29.774853037974683, -1.458593103448276 ], [ 29.77431405063291, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23039, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.458593103448276 ], [ 29.774853037974683, -1.458862586206897 ], [ 29.775122531645568, -1.458862586206897 ], [ 29.775122531645568, -1.458593103448276 ], [ 29.774853037974683, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23040, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.458593103448276 ], [ 29.775122531645568, -1.458862586206897 ], [ 29.775392025316457, -1.458862586206897 ], [ 29.775392025316457, -1.458593103448276 ], [ 29.775122531645568, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23041, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.458593103448276 ], [ 29.775392025316457, -1.458862586206897 ], [ 29.775661518987341, -1.458862586206897 ], [ 29.775661518987341, -1.458593103448276 ], [ 29.775392025316457, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23042, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.458593103448276 ], [ 29.775661518987341, -1.458862586206897 ], [ 29.775931012658226, -1.458862586206897 ], [ 29.775931012658226, -1.458593103448276 ], [ 29.775661518987341, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23043, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.458593103448276 ], [ 29.775931012658226, -1.458862586206897 ], [ 29.776200506329115, -1.458862586206897 ], [ 29.776200506329115, -1.458593103448276 ], [ 29.775931012658226, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23044, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.458593103448276 ], [ 29.776200506329115, -1.458862586206897 ], [ 29.77647, -1.458862586206897 ], [ 29.77647, -1.458593103448276 ], [ 29.776200506329115, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23045, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.458593103448276 ], [ 29.77647, -1.458862586206897 ], [ 29.776739493670885, -1.458862586206897 ], [ 29.776739493670885, -1.458593103448276 ], [ 29.77647, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23046, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.458593103448276 ], [ 29.776739493670885, -1.458862586206897 ], [ 29.777008987341773, -1.458862586206897 ], [ 29.777008987341773, -1.458593103448276 ], [ 29.776739493670885, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23047, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.458593103448276 ], [ 29.777008987341773, -1.458862586206897 ], [ 29.777278481012658, -1.458862586206897 ], [ 29.777278481012658, -1.458593103448276 ], [ 29.777008987341773, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23048, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.458593103448276 ], [ 29.777278481012658, -1.458862586206897 ], [ 29.777547974683543, -1.458862586206897 ], [ 29.777547974683543, -1.458593103448276 ], [ 29.777278481012658, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23049, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.458593103448276 ], [ 29.777547974683543, -1.458862586206897 ], [ 29.777817468354431, -1.458862586206897 ], [ 29.777817468354431, -1.458593103448276 ], [ 29.777547974683543, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23050, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.458593103448276 ], [ 29.777817468354431, -1.458862586206897 ], [ 29.778086962025316, -1.458862586206897 ], [ 29.778086962025316, -1.458593103448276 ], [ 29.777817468354431, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23051, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.458593103448276 ], [ 29.778086962025316, -1.458862586206897 ], [ 29.778356455696201, -1.458862586206897 ], [ 29.778356455696201, -1.458593103448276 ], [ 29.778086962025316, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23052, "El": 125 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.458593103448276 ], [ 29.778356455696201, -1.458862586206897 ], [ 29.77862594936709, -1.458862586206897 ], [ 29.77862594936709, -1.458593103448276 ], [ 29.778356455696201, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23053, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.458593103448276 ], [ 29.77862594936709, -1.458862586206897 ], [ 29.778895443037975, -1.458862586206897 ], [ 29.778895443037975, -1.458593103448276 ], [ 29.77862594936709, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23054, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.458593103448276 ], [ 29.778895443037975, -1.458862586206897 ], [ 29.77916493670886, -1.458862586206897 ], [ 29.77916493670886, -1.458593103448276 ], [ 29.778895443037975, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23055, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.458593103448276 ], [ 29.77916493670886, -1.458862586206897 ], [ 29.779434430379748, -1.458862586206897 ], [ 29.779434430379748, -1.458593103448276 ], [ 29.77916493670886, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23056, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.458593103448276 ], [ 29.779434430379748, -1.458862586206897 ], [ 29.779703924050633, -1.458862586206897 ], [ 29.779703924050633, -1.458593103448276 ], [ 29.779434430379748, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23057, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.458593103448276 ], [ 29.779703924050633, -1.458862586206897 ], [ 29.779973417721518, -1.458862586206897 ], [ 29.779973417721518, -1.458593103448276 ], [ 29.779703924050633, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23058, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.458593103448276 ], [ 29.779973417721518, -1.458862586206897 ], [ 29.780242911392406, -1.458862586206897 ], [ 29.780242911392406, -1.458593103448276 ], [ 29.779973417721518, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23059, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.458593103448276 ], [ 29.780242911392406, -1.458862586206897 ], [ 29.780512405063291, -1.458862586206897 ], [ 29.780512405063291, -1.458593103448276 ], [ 29.780242911392406, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23060, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.458593103448276 ], [ 29.781051392405065, -1.458862586206897 ], [ 29.78132088607595, -1.458862586206897 ], [ 29.78132088607595, -1.458593103448276 ], [ 29.781051392405065, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23061, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.458593103448276 ], [ 29.784824303797468, -1.458862586206897 ], [ 29.785093797468356, -1.458862586206897 ], [ 29.785093797468356, -1.458593103448276 ], [ 29.784824303797468, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23062, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.458593103448276 ], [ 29.785632784810126, -1.458862586206897 ], [ 29.785902278481014, -1.458862586206897 ], [ 29.785902278481014, -1.458593103448276 ], [ 29.785632784810126, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23063, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.458593103448276 ], [ 29.785902278481014, -1.458862586206897 ], [ 29.786171772151899, -1.458862586206897 ], [ 29.786171772151899, -1.458593103448276 ], [ 29.785902278481014, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23064, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.458593103448276 ], [ 29.786441265822784, -1.458862586206897 ], [ 29.786710759493673, -1.458862586206897 ], [ 29.786710759493673, -1.458593103448276 ], [ 29.786441265822784, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23065, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.458593103448276 ], [ 29.786710759493673, -1.458862586206897 ], [ 29.786980253164558, -1.458862586206897 ], [ 29.786980253164558, -1.458593103448276 ], [ 29.786710759493673, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23066, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.458593103448276 ], [ 29.786980253164558, -1.458862586206897 ], [ 29.787249746835442, -1.458862586206897 ], [ 29.787249746835442, -1.458593103448276 ], [ 29.786980253164558, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23067, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.458593103448276 ], [ 29.787249746835442, -1.458862586206897 ], [ 29.787519240506327, -1.458862586206897 ], [ 29.787519240506327, -1.458593103448276 ], [ 29.787249746835442, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23068, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.458593103448276 ], [ 29.792100632911392, -1.458862586206897 ], [ 29.792370126582277, -1.458862586206897 ], [ 29.792370126582277, -1.458593103448276 ], [ 29.792100632911392, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23069, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.458593103448276 ], [ 29.792370126582277, -1.458862586206897 ], [ 29.792909113924051, -1.458862586206897 ], [ 29.792909113924051, -1.458593103448276 ], [ 29.792370126582277, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23070, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.458593103448276 ], [ 29.792909113924051, -1.458862586206897 ], [ 29.793178607594935, -1.458862586206897 ], [ 29.793178607594935, -1.458593103448276 ], [ 29.792909113924051, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23071, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.458593103448276 ], [ 29.793178607594935, -1.458862586206897 ], [ 29.793448101265824, -1.458862586206897 ], [ 29.793448101265824, -1.458593103448276 ], [ 29.793178607594935, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23072, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.458593103448276 ], [ 29.793448101265824, -1.458862586206897 ], [ 29.793717594936709, -1.458862586206897 ], [ 29.793717594936709, -1.458593103448276 ], [ 29.793448101265824, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23073, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.458593103448276 ], [ 29.793717594936709, -1.458862586206897 ], [ 29.793987088607594, -1.458862586206897 ], [ 29.793987088607594, -1.458593103448276 ], [ 29.793717594936709, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23074, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.458593103448276 ], [ 29.793987088607594, -1.458862586206897 ], [ 29.794256582278482, -1.458862586206897 ], [ 29.794256582278482, -1.458593103448276 ], [ 29.793987088607594, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23075, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.458593103448276 ], [ 29.794256582278482, -1.458862586206897 ], [ 29.794526075949367, -1.458862586206897 ], [ 29.794526075949367, -1.458593103448276 ], [ 29.794256582278482, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23076, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.458593103448276 ], [ 29.794526075949367, -1.458862586206897 ], [ 29.794795569620252, -1.458862586206897 ], [ 29.794795569620252, -1.458593103448276 ], [ 29.794526075949367, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23077, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.458593103448276 ], [ 29.794795569620252, -1.458862586206897 ], [ 29.79506506329114, -1.458862586206897 ], [ 29.79506506329114, -1.458593103448276 ], [ 29.794795569620252, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23078, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.458593103448276 ], [ 29.79506506329114, -1.458862586206897 ], [ 29.795334556962025, -1.458862586206897 ], [ 29.795334556962025, -1.458593103448276 ], [ 29.79506506329114, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23079, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.458593103448276 ], [ 29.795334556962025, -1.458862586206897 ], [ 29.79560405063291, -1.458862586206897 ], [ 29.79560405063291, -1.458593103448276 ], [ 29.795334556962025, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23080, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.458593103448276 ], [ 29.79560405063291, -1.458862586206897 ], [ 29.795873544303799, -1.458862586206897 ], [ 29.795873544303799, -1.458593103448276 ], [ 29.79560405063291, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23081, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.458593103448276 ], [ 29.795873544303799, -1.458862586206897 ], [ 29.796143037974684, -1.458862586206897 ], [ 29.796143037974684, -1.458593103448276 ], [ 29.795873544303799, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23082, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.458593103448276 ], [ 29.796143037974684, -1.458862586206897 ], [ 29.796412531645569, -1.458862586206897 ], [ 29.796412531645569, -1.458593103448276 ], [ 29.796143037974684, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23083, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.458593103448276 ], [ 29.796412531645569, -1.458862586206897 ], [ 29.796682025316457, -1.458862586206897 ], [ 29.796682025316457, -1.458593103448276 ], [ 29.796412531645569, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23084, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.458593103448276 ], [ 29.796682025316457, -1.458862586206897 ], [ 29.796951518987342, -1.458862586206897 ], [ 29.796951518987342, -1.458593103448276 ], [ 29.796682025316457, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23085, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.458593103448276 ], [ 29.796951518987342, -1.458862586206897 ], [ 29.797221012658227, -1.458862586206897 ], [ 29.797221012658227, -1.458593103448276 ], [ 29.796951518987342, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23086, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.458593103448276 ], [ 29.797221012658227, -1.458862586206897 ], [ 29.797490506329115, -1.458862586206897 ], [ 29.797490506329115, -1.458593103448276 ], [ 29.797221012658227, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23087, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.458593103448276 ], [ 29.797490506329115, -1.458862586206897 ], [ 29.79776, -1.458862586206897 ], [ 29.79776, -1.458593103448276 ], [ 29.797490506329115, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23088, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.458593103448276 ], [ 29.798029493670885, -1.458862586206897 ], [ 29.798298987341774, -1.458862586206897 ], [ 29.798298987341774, -1.458593103448276 ], [ 29.798029493670885, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23089, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.458593103448276 ], [ 29.798298987341774, -1.458862586206897 ], [ 29.798568481012659, -1.458862586206897 ], [ 29.798568481012659, -1.458593103448276 ], [ 29.798298987341774, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23090, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.458593103448276 ], [ 29.798568481012659, -1.458862586206897 ], [ 29.798837974683543, -1.458862586206897 ], [ 29.798837974683543, -1.458593103448276 ], [ 29.798568481012659, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23091, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.458593103448276 ], [ 29.798837974683543, -1.458862586206897 ], [ 29.799107468354432, -1.458862586206897 ], [ 29.799107468354432, -1.458593103448276 ], [ 29.798837974683543, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23092, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.8074617721519, -1.458593103448276 ], [ 29.8074617721519, -1.458862586206897 ], [ 29.808000759493673, -1.458862586206897 ], [ 29.808000759493673, -1.458593103448276 ], [ 29.8074617721519, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23093, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808000759493673, -1.458593103448276 ], [ 29.808000759493673, -1.458862586206897 ], [ 29.808270253164558, -1.458862586206897 ], [ 29.808270253164558, -1.458593103448276 ], [ 29.808000759493673, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23094, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.808270253164558, -1.458593103448276 ], [ 29.808270253164558, -1.458862586206897 ], [ 29.808809240506328, -1.458862586206897 ], [ 29.808809240506328, -1.458593103448276 ], [ 29.808270253164558, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23095, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.811504177215191, -1.458593103448276 ], [ 29.811504177215191, -1.458862586206897 ], [ 29.812043164556961, -1.458862586206897 ], [ 29.812043164556961, -1.458593103448276 ], [ 29.811504177215191, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23096, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.458593103448276 ], [ 29.812043164556961, -1.458862586206897 ], [ 29.812312658227849, -1.458862586206897 ], [ 29.812312658227849, -1.458593103448276 ], [ 29.812043164556961, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23097, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.458593103448276 ], [ 29.812312658227849, -1.458862586206897 ], [ 29.812582151898734, -1.458862586206897 ], [ 29.812582151898734, -1.458593103448276 ], [ 29.812312658227849, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23098, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.458593103448276 ], [ 29.812582151898734, -1.458862586206897 ], [ 29.812851645569619, -1.458862586206897 ], [ 29.812851645569619, -1.458593103448276 ], [ 29.812582151898734, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23099, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.458593103448276 ], [ 29.812851645569619, -1.458862586206897 ], [ 29.813121139240508, -1.458862586206897 ], [ 29.813121139240508, -1.458593103448276 ], [ 29.812851645569619, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23100, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.458593103448276 ], [ 29.813121139240508, -1.458862586206897 ], [ 29.813390632911393, -1.458862586206897 ], [ 29.813390632911393, -1.458593103448276 ], [ 29.813121139240508, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23101, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.458593103448276 ], [ 29.813390632911393, -1.458862586206897 ], [ 29.813660126582278, -1.458862586206897 ], [ 29.813660126582278, -1.458593103448276 ], [ 29.813390632911393, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23102, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.456706724137931 ], [ 29.78132088607595, -1.459132068965517 ], [ 29.781590379746834, -1.459132068965517 ], [ 29.781590379746834, -1.456706724137931 ], [ 29.78132088607595, -1.456706724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23103, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814738101265824, -1.457245689655172 ], [ 29.814738101265824, -1.459671034482759 ], [ 29.815007594936709, -1.459671034482759 ], [ 29.815007594936709, -1.457245689655172 ], [ 29.814738101265824, -1.457245689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23104, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.457515172413793 ], [ 29.814199113924051, -1.459401551724138 ], [ 29.814468607594936, -1.459401551724138 ], [ 29.814468607594936, -1.457515172413793 ], [ 29.814199113924051, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23105, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.457515172413793 ], [ 29.814468607594936, -1.459401551724138 ], [ 29.814738101265824, -1.459401551724138 ], [ 29.814738101265824, -1.457515172413793 ], [ 29.814468607594936, -1.457515172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23106, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.458054137931035 ], [ 29.80045493670886, -1.459132068965517 ], [ 29.800724430379748, -1.459132068965517 ], [ 29.800724430379748, -1.458054137931035 ], [ 29.80045493670886, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23107, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.458323620689655 ], [ 29.781590379746834, -1.459132068965517 ], [ 29.781859873417723, -1.459132068965517 ], [ 29.781859873417723, -1.458323620689655 ], [ 29.781590379746834, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23108, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.458323620689655 ], [ 29.788327721518986, -1.461018448275862 ], [ 29.788597215189874, -1.461018448275862 ], [ 29.788597215189874, -1.458323620689655 ], [ 29.788327721518986, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23109, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.458323620689655 ], [ 29.788597215189874, -1.461018448275862 ], [ 29.788866708860759, -1.461018448275862 ], [ 29.788866708860759, -1.458323620689655 ], [ 29.788597215189874, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23110, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.458323620689655 ], [ 29.79991594936709, -1.459132068965517 ], [ 29.800185443037975, -1.459132068965517 ], [ 29.800185443037975, -1.458323620689655 ], [ 29.79991594936709, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23111, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.458323620689655 ], [ 29.800185443037975, -1.459132068965517 ], [ 29.80045493670886, -1.459132068965517 ], [ 29.80045493670886, -1.458323620689655 ], [ 29.800185443037975, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23112, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.807192278481015, -1.458323620689655 ], [ 29.807192278481015, -1.459132068965517 ], [ 29.808270253164558, -1.459132068965517 ], [ 29.808270253164558, -1.458862586206897 ], [ 29.8074617721519, -1.458862586206897 ], [ 29.8074617721519, -1.458323620689655 ], [ 29.807192278481015, -1.458323620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23113, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.458593103448276 ], [ 29.754910506329114, -1.459132068965517 ], [ 29.75518, -1.459132068965517 ], [ 29.75518, -1.458593103448276 ], [ 29.754910506329114, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23114, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.458593103448276 ], [ 29.757605443037974, -1.459132068965517 ], [ 29.757874936708859, -1.459132068965517 ], [ 29.757874936708859, -1.458593103448276 ], [ 29.757605443037974, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23115, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.458593103448276 ], [ 29.770541139240507, -1.459132068965517 ], [ 29.770810632911392, -1.459132068965517 ], [ 29.770810632911392, -1.458593103448276 ], [ 29.770541139240507, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23116, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.458593103448276 ], [ 29.781859873417723, -1.459132068965517 ], [ 29.782129367088608, -1.459132068965517 ], [ 29.782129367088608, -1.458593103448276 ], [ 29.781859873417723, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23117, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.458593103448276 ], [ 29.782398860759493, -1.459132068965517 ], [ 29.782668354430381, -1.459132068965517 ], [ 29.782668354430381, -1.458593103448276 ], [ 29.782398860759493, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23118, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.458593103448276 ], [ 29.783207341772151, -1.459132068965517 ], [ 29.783476835443039, -1.459132068965517 ], [ 29.783476835443039, -1.458593103448276 ], [ 29.783207341772151, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23119, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.458593103448276 ], [ 29.784285316455698, -1.459132068965517 ], [ 29.784554810126583, -1.459132068965517 ], [ 29.784554810126583, -1.458593103448276 ], [ 29.784285316455698, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23120, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.458593103448276 ], [ 29.785093797468356, -1.459132068965517 ], [ 29.785363291139241, -1.459132068965517 ], [ 29.785363291139241, -1.458593103448276 ], [ 29.785093797468356, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23121, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.458593103448276 ], [ 29.787519240506327, -1.460748965517241 ], [ 29.787788734177216, -1.460748965517241 ], [ 29.787788734177216, -1.458593103448276 ], [ 29.787519240506327, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23122, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.458593103448276 ], [ 29.787788734177216, -1.460748965517241 ], [ 29.788058227848101, -1.460748965517241 ], [ 29.788058227848101, -1.458593103448276 ], [ 29.787788734177216, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23123, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.458593103448276 ], [ 29.788058227848101, -1.460748965517241 ], [ 29.788327721518986, -1.460748965517241 ], [ 29.788327721518986, -1.458593103448276 ], [ 29.788058227848101, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23124, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.458593103448276 ], [ 29.799107468354432, -1.459132068965517 ], [ 29.799376962025317, -1.459132068965517 ], [ 29.799376962025317, -1.458593103448276 ], [ 29.799107468354432, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23125, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.458593103448276 ], [ 29.799376962025317, -1.459401551724138 ], [ 29.799646455696202, -1.459401551724138 ], [ 29.799646455696202, -1.458593103448276 ], [ 29.799376962025317, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23126, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.458593103448276 ], [ 29.799646455696202, -1.459401551724138 ], [ 29.79991594936709, -1.459401551724138 ], [ 29.79991594936709, -1.458593103448276 ], [ 29.799646455696202, -1.458593103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23127, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752215569620251, -1.458862586206897 ], [ 29.752215569620251, -1.459401551724138 ], [ 29.75248506329114, -1.459401551724138 ], [ 29.75248506329114, -1.458862586206897 ], [ 29.752215569620251, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23128, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.458862586206897 ], [ 29.75248506329114, -1.459132068965517 ], [ 29.752754556962024, -1.459132068965517 ], [ 29.752754556962024, -1.458862586206897 ], [ 29.75248506329114, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23129, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.458862586206897 ], [ 29.752754556962024, -1.459132068965517 ], [ 29.753024050632909, -1.459132068965517 ], [ 29.753024050632909, -1.458862586206897 ], [ 29.752754556962024, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23130, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.458862586206897 ], [ 29.753024050632909, -1.459132068965517 ], [ 29.753293544303798, -1.459132068965517 ], [ 29.753293544303798, -1.458862586206897 ], [ 29.753024050632909, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23131, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.458862586206897 ], [ 29.753293544303798, -1.459132068965517 ], [ 29.753563037974683, -1.459132068965517 ], [ 29.753563037974683, -1.458862586206897 ], [ 29.753293544303798, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23132, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.458862586206897 ], [ 29.753563037974683, -1.459132068965517 ], [ 29.753832531645568, -1.459132068965517 ], [ 29.753832531645568, -1.458862586206897 ], [ 29.753563037974683, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23133, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.458862586206897 ], [ 29.753832531645568, -1.459401551724138 ], [ 29.754102025316456, -1.459401551724138 ], [ 29.754102025316456, -1.458862586206897 ], [ 29.753832531645568, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23134, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.458862586206897 ], [ 29.754102025316456, -1.459132068965517 ], [ 29.754371518987341, -1.459132068965517 ], [ 29.754371518987341, -1.458862586206897 ], [ 29.754102025316456, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23135, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.458862586206897 ], [ 29.754371518987341, -1.459132068965517 ], [ 29.754641012658226, -1.459132068965517 ], [ 29.754641012658226, -1.458862586206897 ], [ 29.754371518987341, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23136, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.458862586206897 ], [ 29.754641012658226, -1.459132068965517 ], [ 29.754910506329114, -1.459132068965517 ], [ 29.754910506329114, -1.458862586206897 ], [ 29.754641012658226, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23137, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.458862586206897 ], [ 29.75518, -1.459132068965517 ], [ 29.755449493670884, -1.459132068965517 ], [ 29.755449493670884, -1.458862586206897 ], [ 29.75518, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23138, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.458862586206897 ], [ 29.755449493670884, -1.459132068965517 ], [ 29.755718987341773, -1.459132068965517 ], [ 29.755718987341773, -1.458862586206897 ], [ 29.755449493670884, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23139, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.458862586206897 ], [ 29.755718987341773, -1.459132068965517 ], [ 29.755988481012658, -1.459132068965517 ], [ 29.755988481012658, -1.458862586206897 ], [ 29.755718987341773, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23140, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.458862586206897 ], [ 29.755988481012658, -1.459132068965517 ], [ 29.756257974683542, -1.459132068965517 ], [ 29.756257974683542, -1.458862586206897 ], [ 29.755988481012658, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23141, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.458862586206897 ], [ 29.756257974683542, -1.459132068965517 ], [ 29.756796962025316, -1.459132068965517 ], [ 29.756796962025316, -1.458862586206897 ], [ 29.756257974683542, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23142, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.458862586206897 ], [ 29.756796962025316, -1.459132068965517 ], [ 29.757066455696201, -1.459132068965517 ], [ 29.757066455696201, -1.458862586206897 ], [ 29.756796962025316, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23143, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.458862586206897 ], [ 29.757066455696201, -1.459132068965517 ], [ 29.757335949367089, -1.459132068965517 ], [ 29.757335949367089, -1.458862586206897 ], [ 29.757066455696201, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23144, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.458862586206897 ], [ 29.757335949367089, -1.459132068965517 ], [ 29.757605443037974, -1.459132068965517 ], [ 29.757605443037974, -1.458862586206897 ], [ 29.757335949367089, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23145, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.458862586206897 ], [ 29.757874936708859, -1.459132068965517 ], [ 29.758144430379748, -1.459132068965517 ], [ 29.758144430379748, -1.458862586206897 ], [ 29.757874936708859, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23146, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.458862586206897 ], [ 29.758144430379748, -1.459132068965517 ], [ 29.758413924050632, -1.459132068965517 ], [ 29.758413924050632, -1.458862586206897 ], [ 29.758144430379748, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23147, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.458862586206897 ], [ 29.758413924050632, -1.459401551724138 ], [ 29.758952911392406, -1.459401551724138 ], [ 29.758952911392406, -1.459132068965517 ], [ 29.758683417721517, -1.459132068965517 ], [ 29.758683417721517, -1.458862586206897 ], [ 29.758413924050632, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23148, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.458862586206897 ], [ 29.758683417721517, -1.459132068965517 ], [ 29.758952911392406, -1.459132068965517 ], [ 29.758952911392406, -1.458862586206897 ], [ 29.758683417721517, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23149, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.458862586206897 ], [ 29.758952911392406, -1.459132068965517 ], [ 29.759222405063291, -1.459132068965517 ], [ 29.759222405063291, -1.458862586206897 ], [ 29.758952911392406, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23150, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.458862586206897 ], [ 29.759222405063291, -1.459132068965517 ], [ 29.759491898734176, -1.459132068965517 ], [ 29.759491898734176, -1.458862586206897 ], [ 29.759222405063291, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23151, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.458862586206897 ], [ 29.759491898734176, -1.459132068965517 ], [ 29.759761392405064, -1.459132068965517 ], [ 29.759761392405064, -1.458862586206897 ], [ 29.759491898734176, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23152, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.458862586206897 ], [ 29.759761392405064, -1.459132068965517 ], [ 29.760030886075949, -1.459132068965517 ], [ 29.760030886075949, -1.458862586206897 ], [ 29.759761392405064, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23153, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.458862586206897 ], [ 29.760030886075949, -1.459132068965517 ], [ 29.760300379746834, -1.459132068965517 ], [ 29.760300379746834, -1.458862586206897 ], [ 29.760030886075949, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23154, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.458862586206897 ], [ 29.760300379746834, -1.459132068965517 ], [ 29.760569873417722, -1.459132068965517 ], [ 29.760569873417722, -1.458862586206897 ], [ 29.760300379746834, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23155, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.458862586206897 ], [ 29.760569873417722, -1.459132068965517 ], [ 29.760839367088607, -1.459132068965517 ], [ 29.760839367088607, -1.458862586206897 ], [ 29.760569873417722, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23156, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.458862586206897 ], [ 29.760839367088607, -1.459132068965517 ], [ 29.761108860759492, -1.459132068965517 ], [ 29.761108860759492, -1.458862586206897 ], [ 29.760839367088607, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23157, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.458862586206897 ], [ 29.761108860759492, -1.459132068965517 ], [ 29.761378354430381, -1.459132068965517 ], [ 29.761378354430381, -1.458862586206897 ], [ 29.761108860759492, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23158, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.458862586206897 ], [ 29.761378354430381, -1.459132068965517 ], [ 29.761647848101266, -1.459132068965517 ], [ 29.761647848101266, -1.458862586206897 ], [ 29.761378354430381, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23159, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.458862586206897 ], [ 29.761647848101266, -1.459132068965517 ], [ 29.762186835443039, -1.459132068965517 ], [ 29.762186835443039, -1.458862586206897 ], [ 29.761647848101266, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23160, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.458862586206897 ], [ 29.762186835443039, -1.459132068965517 ], [ 29.763803797468356, -1.459132068965517 ], [ 29.763803797468356, -1.458862586206897 ], [ 29.762186835443039, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23161, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.458862586206897 ], [ 29.763803797468356, -1.459132068965517 ], [ 29.764342784810125, -1.459132068965517 ], [ 29.764342784810125, -1.458862586206897 ], [ 29.763803797468356, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23162, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.458862586206897 ], [ 29.764342784810125, -1.459132068965517 ], [ 29.764612278481014, -1.459132068965517 ], [ 29.764612278481014, -1.458862586206897 ], [ 29.764342784810125, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23163, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.458862586206897 ], [ 29.764612278481014, -1.459132068965517 ], [ 29.764881772151899, -1.459132068965517 ], [ 29.764881772151899, -1.458862586206897 ], [ 29.764612278481014, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23164, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.458862586206897 ], [ 29.764881772151899, -1.459132068965517 ], [ 29.765420759493672, -1.459132068965517 ], [ 29.765420759493672, -1.458862586206897 ], [ 29.764881772151899, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23165, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.458862586206897 ], [ 29.765420759493672, -1.459132068965517 ], [ 29.765959746835442, -1.459132068965517 ], [ 29.765959746835442, -1.458862586206897 ], [ 29.765420759493672, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23166, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.458862586206897 ], [ 29.765959746835442, -1.459132068965517 ], [ 29.766229240506327, -1.459132068965517 ], [ 29.766229240506327, -1.458862586206897 ], [ 29.765959746835442, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23167, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.458862586206897 ], [ 29.766229240506327, -1.459132068965517 ], [ 29.7667682278481, -1.459132068965517 ], [ 29.7667682278481, -1.458862586206897 ], [ 29.766229240506327, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23168, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.458862586206897 ], [ 29.7667682278481, -1.459132068965517 ], [ 29.768654683544302, -1.459132068965517 ], [ 29.768654683544302, -1.459401551724138 ], [ 29.770002151898733, -1.459401551724138 ], [ 29.770002151898733, -1.459132068965517 ], [ 29.76892417721519, -1.459132068965517 ], [ 29.76892417721519, -1.458862586206897 ], [ 29.7667682278481, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23169, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.458862586206897 ], [ 29.76892417721519, -1.459132068965517 ], [ 29.76946316455696, -1.459132068965517 ], [ 29.76946316455696, -1.458862586206897 ], [ 29.76892417721519, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23170, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.458862586206897 ], [ 29.76946316455696, -1.459132068965517 ], [ 29.770002151898733, -1.459132068965517 ], [ 29.770002151898733, -1.458862586206897 ], [ 29.76946316455696, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23171, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.458862586206897 ], [ 29.770002151898733, -1.459132068965517 ], [ 29.770271645569618, -1.459132068965517 ], [ 29.770271645569618, -1.458862586206897 ], [ 29.770002151898733, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23172, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.458862586206897 ], [ 29.770271645569618, -1.459132068965517 ], [ 29.770541139240507, -1.459132068965517 ], [ 29.770541139240507, -1.458862586206897 ], [ 29.770271645569618, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23173, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.458862586206897 ], [ 29.770810632911392, -1.459132068965517 ], [ 29.771080126582277, -1.459132068965517 ], [ 29.771080126582277, -1.458862586206897 ], [ 29.770810632911392, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23174, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.458862586206897 ], [ 29.771080126582277, -1.459132068965517 ], [ 29.771349620253165, -1.459132068965517 ], [ 29.771349620253165, -1.458862586206897 ], [ 29.771080126582277, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23175, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.458862586206897 ], [ 29.771349620253165, -1.459132068965517 ], [ 29.77161911392405, -1.459132068965517 ], [ 29.77161911392405, -1.458862586206897 ], [ 29.771349620253165, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23176, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.458862586206897 ], [ 29.77161911392405, -1.459132068965517 ], [ 29.771888607594935, -1.459132068965517 ], [ 29.771888607594935, -1.458862586206897 ], [ 29.77161911392405, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23177, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.458862586206897 ], [ 29.771888607594935, -1.459132068965517 ], [ 29.772158101265823, -1.459132068965517 ], [ 29.772158101265823, -1.458862586206897 ], [ 29.771888607594935, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23178, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.458862586206897 ], [ 29.772158101265823, -1.459132068965517 ], [ 29.772427594936708, -1.459132068965517 ], [ 29.772427594936708, -1.458862586206897 ], [ 29.772158101265823, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23179, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.458862586206897 ], [ 29.772427594936708, -1.459132068965517 ], [ 29.772697088607593, -1.459132068965517 ], [ 29.772697088607593, -1.458862586206897 ], [ 29.772427594936708, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23180, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.458862586206897 ], [ 29.772697088607593, -1.459132068965517 ], [ 29.772966582278482, -1.459132068965517 ], [ 29.772966582278482, -1.458862586206897 ], [ 29.772697088607593, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23181, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.458862586206897 ], [ 29.772966582278482, -1.459132068965517 ], [ 29.773236075949367, -1.459132068965517 ], [ 29.773236075949367, -1.458862586206897 ], [ 29.772966582278482, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23182, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.458862586206897 ], [ 29.773236075949367, -1.459132068965517 ], [ 29.773505569620252, -1.459132068965517 ], [ 29.773505569620252, -1.458862586206897 ], [ 29.773236075949367, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23183, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.458862586206897 ], [ 29.773505569620252, -1.459132068965517 ], [ 29.77377506329114, -1.459132068965517 ], [ 29.77377506329114, -1.458862586206897 ], [ 29.773505569620252, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23184, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.458862586206897 ], [ 29.77377506329114, -1.459132068965517 ], [ 29.774044556962025, -1.459132068965517 ], [ 29.774044556962025, -1.458862586206897 ], [ 29.77377506329114, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23185, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.458862586206897 ], [ 29.774044556962025, -1.459132068965517 ], [ 29.77431405063291, -1.459132068965517 ], [ 29.77431405063291, -1.458862586206897 ], [ 29.774044556962025, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23186, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.458862586206897 ], [ 29.77431405063291, -1.459132068965517 ], [ 29.774853037974683, -1.459132068965517 ], [ 29.774853037974683, -1.458862586206897 ], [ 29.77431405063291, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23187, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.458862586206897 ], [ 29.774853037974683, -1.459132068965517 ], [ 29.775122531645568, -1.459132068965517 ], [ 29.775122531645568, -1.458862586206897 ], [ 29.774853037974683, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23188, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.458862586206897 ], [ 29.775122531645568, -1.459132068965517 ], [ 29.775392025316457, -1.459132068965517 ], [ 29.775392025316457, -1.458862586206897 ], [ 29.775122531645568, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23189, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.458862586206897 ], [ 29.775392025316457, -1.459132068965517 ], [ 29.775661518987341, -1.459132068965517 ], [ 29.775661518987341, -1.458862586206897 ], [ 29.775392025316457, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23190, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.458862586206897 ], [ 29.775661518987341, -1.459132068965517 ], [ 29.775931012658226, -1.459132068965517 ], [ 29.775931012658226, -1.458862586206897 ], [ 29.775661518987341, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23191, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.458862586206897 ], [ 29.775931012658226, -1.459132068965517 ], [ 29.776200506329115, -1.459132068965517 ], [ 29.776200506329115, -1.458862586206897 ], [ 29.775931012658226, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23192, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.458862586206897 ], [ 29.776200506329115, -1.459132068965517 ], [ 29.77647, -1.459132068965517 ], [ 29.77647, -1.458862586206897 ], [ 29.776200506329115, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23193, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.458862586206897 ], [ 29.77647, -1.459132068965517 ], [ 29.776739493670885, -1.459132068965517 ], [ 29.776739493670885, -1.458862586206897 ], [ 29.77647, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23194, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.458862586206897 ], [ 29.776739493670885, -1.459132068965517 ], [ 29.777008987341773, -1.459132068965517 ], [ 29.777008987341773, -1.458862586206897 ], [ 29.776739493670885, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23195, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.458862586206897 ], [ 29.777008987341773, -1.459132068965517 ], [ 29.777278481012658, -1.459132068965517 ], [ 29.777278481012658, -1.458862586206897 ], [ 29.777008987341773, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23196, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.458862586206897 ], [ 29.777278481012658, -1.459132068965517 ], [ 29.777547974683543, -1.459132068965517 ], [ 29.777547974683543, -1.458862586206897 ], [ 29.777278481012658, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23197, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.458862586206897 ], [ 29.777547974683543, -1.459132068965517 ], [ 29.777817468354431, -1.459132068965517 ], [ 29.777817468354431, -1.458862586206897 ], [ 29.777547974683543, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23198, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.458862586206897 ], [ 29.777817468354431, -1.459132068965517 ], [ 29.778086962025316, -1.459132068965517 ], [ 29.778086962025316, -1.458862586206897 ], [ 29.777817468354431, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23199, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.458862586206897 ], [ 29.778086962025316, -1.459132068965517 ], [ 29.778356455696201, -1.459132068965517 ], [ 29.778356455696201, -1.458862586206897 ], [ 29.778086962025316, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23200, "El": 123 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.458862586206897 ], [ 29.778356455696201, -1.459132068965517 ], [ 29.77862594936709, -1.459132068965517 ], [ 29.77862594936709, -1.458862586206897 ], [ 29.778356455696201, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23201, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.458862586206897 ], [ 29.77862594936709, -1.459132068965517 ], [ 29.778895443037975, -1.459132068965517 ], [ 29.778895443037975, -1.458862586206897 ], [ 29.77862594936709, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23202, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.458862586206897 ], [ 29.778895443037975, -1.459132068965517 ], [ 29.77916493670886, -1.459132068965517 ], [ 29.77916493670886, -1.458862586206897 ], [ 29.778895443037975, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23203, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.458862586206897 ], [ 29.77916493670886, -1.459132068965517 ], [ 29.779434430379748, -1.459132068965517 ], [ 29.779434430379748, -1.458862586206897 ], [ 29.77916493670886, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23204, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.458862586206897 ], [ 29.779434430379748, -1.459132068965517 ], [ 29.779703924050633, -1.459132068965517 ], [ 29.779703924050633, -1.458862586206897 ], [ 29.779434430379748, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23205, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.458862586206897 ], [ 29.779703924050633, -1.459132068965517 ], [ 29.779973417721518, -1.459132068965517 ], [ 29.779973417721518, -1.458862586206897 ], [ 29.779703924050633, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23206, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.458862586206897 ], [ 29.779973417721518, -1.459132068965517 ], [ 29.780242911392406, -1.459132068965517 ], [ 29.780242911392406, -1.458862586206897 ], [ 29.779973417721518, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23207, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.458862586206897 ], [ 29.780242911392406, -1.459132068965517 ], [ 29.780512405063291, -1.459132068965517 ], [ 29.780512405063291, -1.458862586206897 ], [ 29.780242911392406, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23208, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.458862586206897 ], [ 29.780512405063291, -1.459132068965517 ], [ 29.780781898734176, -1.459132068965517 ], [ 29.780781898734176, -1.458862586206897 ], [ 29.780512405063291, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23209, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.458862586206897 ], [ 29.780781898734176, -1.459132068965517 ], [ 29.781051392405065, -1.459132068965517 ], [ 29.781051392405065, -1.458862586206897 ], [ 29.780781898734176, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23210, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.458862586206897 ], [ 29.781051392405065, -1.459132068965517 ], [ 29.78132088607595, -1.459132068965517 ], [ 29.78132088607595, -1.458862586206897 ], [ 29.781051392405065, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23211, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.458862586206897 ], [ 29.782129367088608, -1.459132068965517 ], [ 29.782398860759493, -1.459132068965517 ], [ 29.782398860759493, -1.458862586206897 ], [ 29.782129367088608, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23212, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.458862586206897 ], [ 29.782668354430381, -1.459132068965517 ], [ 29.782937848101266, -1.459132068965517 ], [ 29.782937848101266, -1.458862586206897 ], [ 29.782668354430381, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23213, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.458862586206897 ], [ 29.782937848101266, -1.459401551724138 ], [ 29.783207341772151, -1.459401551724138 ], [ 29.783207341772151, -1.458862586206897 ], [ 29.782937848101266, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23214, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.458862586206897 ], [ 29.783476835443039, -1.459132068965517 ], [ 29.784015822784809, -1.459132068965517 ], [ 29.784015822784809, -1.458862586206897 ], [ 29.783476835443039, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23215, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.458862586206897 ], [ 29.784015822784809, -1.459132068965517 ], [ 29.784285316455698, -1.459132068965517 ], [ 29.784285316455698, -1.458862586206897 ], [ 29.784015822784809, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23216, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.458862586206897 ], [ 29.784554810126583, -1.459132068965517 ], [ 29.784824303797468, -1.459132068965517 ], [ 29.784824303797468, -1.458862586206897 ], [ 29.784554810126583, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23217, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.458862586206897 ], [ 29.784824303797468, -1.459132068965517 ], [ 29.785093797468356, -1.459132068965517 ], [ 29.785093797468356, -1.458862586206897 ], [ 29.784824303797468, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23218, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.458862586206897 ], [ 29.785363291139241, -1.459132068965517 ], [ 29.785632784810126, -1.459132068965517 ], [ 29.785632784810126, -1.458862586206897 ], [ 29.785363291139241, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23219, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.458862586206897 ], [ 29.785632784810126, -1.459132068965517 ], [ 29.785902278481014, -1.459132068965517 ], [ 29.785902278481014, -1.458862586206897 ], [ 29.785632784810126, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23220, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.458862586206897 ], [ 29.785902278481014, -1.459132068965517 ], [ 29.786171772151899, -1.459132068965517 ], [ 29.786171772151899, -1.458862586206897 ], [ 29.785902278481014, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23221, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.458862586206897 ], [ 29.786171772151899, -1.46021 ], [ 29.786441265822784, -1.46021 ], [ 29.786441265822784, -1.458862586206897 ], [ 29.786171772151899, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23222, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.458862586206897 ], [ 29.786441265822784, -1.459132068965517 ], [ 29.786710759493673, -1.459132068965517 ], [ 29.786710759493673, -1.458862586206897 ], [ 29.786441265822784, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23223, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.458862586206897 ], [ 29.786710759493673, -1.459132068965517 ], [ 29.786980253164558, -1.459132068965517 ], [ 29.786980253164558, -1.458862586206897 ], [ 29.786710759493673, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23224, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.458862586206897 ], [ 29.786980253164558, -1.460479482758621 ], [ 29.787249746835442, -1.460479482758621 ], [ 29.787249746835442, -1.458862586206897 ], [ 29.786980253164558, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23225, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.458862586206897 ], [ 29.787249746835442, -1.460479482758621 ], [ 29.787519240506327, -1.460479482758621 ], [ 29.787519240506327, -1.458862586206897 ], [ 29.787249746835442, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23226, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792100632911392, -1.458862586206897 ], [ 29.792100632911392, -1.459132068965517 ], [ 29.792370126582277, -1.459132068965517 ], [ 29.792370126582277, -1.458862586206897 ], [ 29.792100632911392, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23227, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.458862586206897 ], [ 29.792370126582277, -1.459132068965517 ], [ 29.792909113924051, -1.459132068965517 ], [ 29.792909113924051, -1.458862586206897 ], [ 29.792370126582277, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23228, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.458862586206897 ], [ 29.792909113924051, -1.459132068965517 ], [ 29.793178607594935, -1.459132068965517 ], [ 29.793178607594935, -1.458862586206897 ], [ 29.792909113924051, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23229, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.458862586206897 ], [ 29.793178607594935, -1.459132068965517 ], [ 29.793448101265824, -1.459132068965517 ], [ 29.793448101265824, -1.458862586206897 ], [ 29.793178607594935, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23230, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.458862586206897 ], [ 29.793448101265824, -1.459132068965517 ], [ 29.793717594936709, -1.459132068965517 ], [ 29.793717594936709, -1.458862586206897 ], [ 29.793448101265824, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23231, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.458862586206897 ], [ 29.793717594936709, -1.459132068965517 ], [ 29.793987088607594, -1.459132068965517 ], [ 29.793987088607594, -1.458862586206897 ], [ 29.793717594936709, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23232, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.458862586206897 ], [ 29.793987088607594, -1.459132068965517 ], [ 29.794256582278482, -1.459132068965517 ], [ 29.794256582278482, -1.458862586206897 ], [ 29.793987088607594, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23233, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.458862586206897 ], [ 29.794256582278482, -1.459132068965517 ], [ 29.794526075949367, -1.459132068965517 ], [ 29.794526075949367, -1.458862586206897 ], [ 29.794256582278482, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23234, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.458862586206897 ], [ 29.794526075949367, -1.459132068965517 ], [ 29.794795569620252, -1.459132068965517 ], [ 29.794795569620252, -1.458862586206897 ], [ 29.794526075949367, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23235, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.458862586206897 ], [ 29.794795569620252, -1.459132068965517 ], [ 29.79506506329114, -1.459132068965517 ], [ 29.79506506329114, -1.458862586206897 ], [ 29.794795569620252, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23236, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.458862586206897 ], [ 29.79506506329114, -1.459132068965517 ], [ 29.795334556962025, -1.459132068965517 ], [ 29.795334556962025, -1.458862586206897 ], [ 29.79506506329114, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23237, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.458862586206897 ], [ 29.795334556962025, -1.459132068965517 ], [ 29.79560405063291, -1.459132068965517 ], [ 29.79560405063291, -1.458862586206897 ], [ 29.795334556962025, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23238, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.458862586206897 ], [ 29.79560405063291, -1.459132068965517 ], [ 29.795873544303799, -1.459132068965517 ], [ 29.795873544303799, -1.458862586206897 ], [ 29.79560405063291, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23239, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.458862586206897 ], [ 29.795873544303799, -1.459132068965517 ], [ 29.796143037974684, -1.459132068965517 ], [ 29.796143037974684, -1.458862586206897 ], [ 29.795873544303799, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23240, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.458862586206897 ], [ 29.796143037974684, -1.459132068965517 ], [ 29.796412531645569, -1.459132068965517 ], [ 29.796412531645569, -1.458862586206897 ], [ 29.796143037974684, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23241, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.458862586206897 ], [ 29.796412531645569, -1.459132068965517 ], [ 29.796682025316457, -1.459132068965517 ], [ 29.796682025316457, -1.458862586206897 ], [ 29.796412531645569, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23242, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.458862586206897 ], [ 29.796682025316457, -1.459132068965517 ], [ 29.796951518987342, -1.459132068965517 ], [ 29.796951518987342, -1.458862586206897 ], [ 29.796682025316457, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23243, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.458862586206897 ], [ 29.796951518987342, -1.459132068965517 ], [ 29.797221012658227, -1.459132068965517 ], [ 29.797221012658227, -1.458862586206897 ], [ 29.796951518987342, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23244, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.458862586206897 ], [ 29.797221012658227, -1.459132068965517 ], [ 29.797490506329115, -1.459132068965517 ], [ 29.797490506329115, -1.458862586206897 ], [ 29.797221012658227, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23245, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.458862586206897 ], [ 29.797490506329115, -1.459132068965517 ], [ 29.79776, -1.459132068965517 ], [ 29.79776, -1.458862586206897 ], [ 29.797490506329115, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23246, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.458862586206897 ], [ 29.79776, -1.459132068965517 ], [ 29.798029493670885, -1.459132068965517 ], [ 29.798029493670885, -1.458862586206897 ], [ 29.79776, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23247, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.458862586206897 ], [ 29.798029493670885, -1.459132068965517 ], [ 29.798298987341774, -1.459132068965517 ], [ 29.798298987341774, -1.458862586206897 ], [ 29.798029493670885, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23248, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.458862586206897 ], [ 29.798298987341774, -1.459132068965517 ], [ 29.798568481012659, -1.459132068965517 ], [ 29.798568481012659, -1.458862586206897 ], [ 29.798298987341774, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23249, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.458862586206897 ], [ 29.798568481012659, -1.459401551724138 ], [ 29.798837974683543, -1.459401551724138 ], [ 29.798837974683543, -1.458862586206897 ], [ 29.798568481012659, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23250, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.458862586206897 ], [ 29.798837974683543, -1.459401551724138 ], [ 29.799107468354432, -1.459401551724138 ], [ 29.799107468354432, -1.458862586206897 ], [ 29.798837974683543, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23251, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812043164556961, -1.458862586206897 ], [ 29.812043164556961, -1.459132068965517 ], [ 29.812312658227849, -1.459132068965517 ], [ 29.812312658227849, -1.458862586206897 ], [ 29.812043164556961, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23252, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.458862586206897 ], [ 29.812312658227849, -1.459132068965517 ], [ 29.812582151898734, -1.459132068965517 ], [ 29.812582151898734, -1.458862586206897 ], [ 29.812312658227849, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23253, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812582151898734, -1.458862586206897 ], [ 29.812582151898734, -1.459132068965517 ], [ 29.812851645569619, -1.459132068965517 ], [ 29.812851645569619, -1.458862586206897 ], [ 29.812582151898734, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23254, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.458862586206897 ], [ 29.812851645569619, -1.459132068965517 ], [ 29.813121139240508, -1.459132068965517 ], [ 29.813121139240508, -1.458862586206897 ], [ 29.812851645569619, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23255, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.458862586206897 ], [ 29.813121139240508, -1.459132068965517 ], [ 29.813390632911393, -1.459132068965517 ], [ 29.813390632911393, -1.458862586206897 ], [ 29.813121139240508, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23256, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.458862586206897 ], [ 29.813390632911393, -1.459132068965517 ], [ 29.813660126582278, -1.459132068965517 ], [ 29.813660126582278, -1.458862586206897 ], [ 29.813390632911393, -1.458862586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23257, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.457784655172414 ], [ 29.814199113924051, -1.457784655172414 ], [ 29.814199113924051, -1.459132068965517 ], [ 29.813660126582278, -1.459132068965517 ], [ 29.813660126582278, -1.458862586206897 ], [ 29.813929620253166, -1.458862586206897 ], [ 29.813929620253166, -1.457784655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23258, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.459132068965517 ], [ 29.75248506329114, -1.459401551724138 ], [ 29.752754556962024, -1.459401551724138 ], [ 29.752754556962024, -1.459132068965517 ], [ 29.75248506329114, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23259, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.459132068965517 ], [ 29.752754556962024, -1.459401551724138 ], [ 29.753024050632909, -1.459401551724138 ], [ 29.753024050632909, -1.459132068965517 ], [ 29.752754556962024, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23260, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.459132068965517 ], [ 29.753024050632909, -1.459401551724138 ], [ 29.753293544303798, -1.459401551724138 ], [ 29.753293544303798, -1.459132068965517 ], [ 29.753024050632909, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23261, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.459132068965517 ], [ 29.753293544303798, -1.459671034482759 ], [ 29.753563037974683, -1.459671034482759 ], [ 29.753563037974683, -1.459132068965517 ], [ 29.753293544303798, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23262, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.459132068965517 ], [ 29.753563037974683, -1.459671034482759 ], [ 29.753832531645568, -1.459671034482759 ], [ 29.753832531645568, -1.459132068965517 ], [ 29.753563037974683, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23263, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.459132068965517 ], [ 29.754102025316456, -1.459401551724138 ], [ 29.754371518987341, -1.459401551724138 ], [ 29.754371518987341, -1.459132068965517 ], [ 29.754102025316456, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23264, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.459132068965517 ], [ 29.754371518987341, -1.459401551724138 ], [ 29.754641012658226, -1.459401551724138 ], [ 29.754641012658226, -1.459132068965517 ], [ 29.754371518987341, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23265, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.459132068965517 ], [ 29.754641012658226, -1.459401551724138 ], [ 29.754910506329114, -1.459401551724138 ], [ 29.754910506329114, -1.459132068965517 ], [ 29.754641012658226, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23266, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.459132068965517 ], [ 29.754910506329114, -1.459401551724138 ], [ 29.75518, -1.459401551724138 ], [ 29.75518, -1.459132068965517 ], [ 29.754910506329114, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23267, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.459132068965517 ], [ 29.75518, -1.459401551724138 ], [ 29.755449493670884, -1.459401551724138 ], [ 29.755449493670884, -1.459132068965517 ], [ 29.75518, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23268, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.459132068965517 ], [ 29.755449493670884, -1.459401551724138 ], [ 29.755718987341773, -1.459401551724138 ], [ 29.755718987341773, -1.459132068965517 ], [ 29.755449493670884, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23269, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.459132068965517 ], [ 29.755718987341773, -1.459401551724138 ], [ 29.755988481012658, -1.459401551724138 ], [ 29.755988481012658, -1.459132068965517 ], [ 29.755718987341773, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23270, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.459132068965517 ], [ 29.755988481012658, -1.459401551724138 ], [ 29.756257974683542, -1.459401551724138 ], [ 29.756257974683542, -1.459132068965517 ], [ 29.755988481012658, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23271, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.459132068965517 ], [ 29.756257974683542, -1.459671034482759 ], [ 29.756796962025316, -1.459671034482759 ], [ 29.756796962025316, -1.459132068965517 ], [ 29.756257974683542, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23272, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.459132068965517 ], [ 29.756796962025316, -1.459401551724138 ], [ 29.757066455696201, -1.459401551724138 ], [ 29.757066455696201, -1.459132068965517 ], [ 29.756796962025316, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23273, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.459132068965517 ], [ 29.757066455696201, -1.459401551724138 ], [ 29.757335949367089, -1.459401551724138 ], [ 29.757335949367089, -1.459132068965517 ], [ 29.757066455696201, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23274, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.459132068965517 ], [ 29.757335949367089, -1.459401551724138 ], [ 29.757605443037974, -1.459401551724138 ], [ 29.757605443037974, -1.459132068965517 ], [ 29.757335949367089, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23275, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.459132068965517 ], [ 29.757605443037974, -1.459401551724138 ], [ 29.757874936708859, -1.459401551724138 ], [ 29.757874936708859, -1.459132068965517 ], [ 29.757605443037974, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23276, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.459132068965517 ], [ 29.757874936708859, -1.459401551724138 ], [ 29.758144430379748, -1.459401551724138 ], [ 29.758144430379748, -1.459132068965517 ], [ 29.757874936708859, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23277, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.459132068965517 ], [ 29.758144430379748, -1.459401551724138 ], [ 29.758413924050632, -1.459401551724138 ], [ 29.758413924050632, -1.459132068965517 ], [ 29.758144430379748, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23278, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.459132068965517 ], [ 29.758952911392406, -1.459401551724138 ], [ 29.759222405063291, -1.459401551724138 ], [ 29.759222405063291, -1.459132068965517 ], [ 29.758952911392406, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23279, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.459132068965517 ], [ 29.759222405063291, -1.459401551724138 ], [ 29.759491898734176, -1.459401551724138 ], [ 29.759491898734176, -1.459132068965517 ], [ 29.759222405063291, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23280, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.459132068965517 ], [ 29.759491898734176, -1.459401551724138 ], [ 29.759761392405064, -1.459401551724138 ], [ 29.759761392405064, -1.459132068965517 ], [ 29.759491898734176, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23281, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.459132068965517 ], [ 29.759761392405064, -1.459401551724138 ], [ 29.760030886075949, -1.459401551724138 ], [ 29.760030886075949, -1.459132068965517 ], [ 29.759761392405064, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23282, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.459132068965517 ], [ 29.760030886075949, -1.459401551724138 ], [ 29.760300379746834, -1.459401551724138 ], [ 29.760300379746834, -1.459132068965517 ], [ 29.760030886075949, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23283, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.459132068965517 ], [ 29.760300379746834, -1.459401551724138 ], [ 29.760569873417722, -1.459401551724138 ], [ 29.760569873417722, -1.459132068965517 ], [ 29.760300379746834, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23284, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.459132068965517 ], [ 29.760569873417722, -1.459401551724138 ], [ 29.760839367088607, -1.459401551724138 ], [ 29.760839367088607, -1.459132068965517 ], [ 29.760569873417722, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23285, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.459132068965517 ], [ 29.760839367088607, -1.459401551724138 ], [ 29.761108860759492, -1.459401551724138 ], [ 29.761108860759492, -1.459132068965517 ], [ 29.760839367088607, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23286, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.459132068965517 ], [ 29.761108860759492, -1.459401551724138 ], [ 29.761378354430381, -1.459401551724138 ], [ 29.761378354430381, -1.459132068965517 ], [ 29.761108860759492, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23287, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.459132068965517 ], [ 29.761378354430381, -1.459401551724138 ], [ 29.761647848101266, -1.459401551724138 ], [ 29.761647848101266, -1.459132068965517 ], [ 29.761378354430381, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23288, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.459132068965517 ], [ 29.761647848101266, -1.459401551724138 ], [ 29.761917341772151, -1.459401551724138 ], [ 29.761917341772151, -1.459132068965517 ], [ 29.761647848101266, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23289, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.459132068965517 ], [ 29.761917341772151, -1.459401551724138 ], [ 29.762186835443039, -1.459401551724138 ], [ 29.762186835443039, -1.459132068965517 ], [ 29.761917341772151, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23290, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.459132068965517 ], [ 29.762186835443039, -1.459401551724138 ], [ 29.763803797468356, -1.459401551724138 ], [ 29.763803797468356, -1.459132068965517 ], [ 29.762186835443039, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23291, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.459132068965517 ], [ 29.763803797468356, -1.459401551724138 ], [ 29.76407329113924, -1.459401551724138 ], [ 29.76407329113924, -1.459132068965517 ], [ 29.763803797468356, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23292, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.459132068965517 ], [ 29.76407329113924, -1.459401551724138 ], [ 29.764342784810125, -1.459401551724138 ], [ 29.764342784810125, -1.459132068965517 ], [ 29.76407329113924, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23293, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.459132068965517 ], [ 29.764342784810125, -1.459401551724138 ], [ 29.764612278481014, -1.459401551724138 ], [ 29.764612278481014, -1.459132068965517 ], [ 29.764342784810125, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23294, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.459132068965517 ], [ 29.764612278481014, -1.459401551724138 ], [ 29.764881772151899, -1.459401551724138 ], [ 29.764881772151899, -1.459132068965517 ], [ 29.764612278481014, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23295, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.459132068965517 ], [ 29.764881772151899, -1.459401551724138 ], [ 29.765420759493672, -1.459401551724138 ], [ 29.765420759493672, -1.459132068965517 ], [ 29.764881772151899, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23296, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.459132068965517 ], [ 29.765420759493672, -1.459401551724138 ], [ 29.765959746835442, -1.459401551724138 ], [ 29.765959746835442, -1.459132068965517 ], [ 29.765420759493672, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23297, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.459132068965517 ], [ 29.765959746835442, -1.459401551724138 ], [ 29.766229240506327, -1.459401551724138 ], [ 29.766229240506327, -1.459132068965517 ], [ 29.765959746835442, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23298, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.459132068965517 ], [ 29.766229240506327, -1.459401551724138 ], [ 29.766498734177215, -1.459401551724138 ], [ 29.766498734177215, -1.459132068965517 ], [ 29.766229240506327, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23299, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.459132068965517 ], [ 29.766498734177215, -1.459401551724138 ], [ 29.7667682278481, -1.459401551724138 ], [ 29.7667682278481, -1.459132068965517 ], [ 29.766498734177215, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23300, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.459132068965517 ], [ 29.7667682278481, -1.459401551724138 ], [ 29.767037721518985, -1.459401551724138 ], [ 29.767037721518985, -1.459671034482759 ], [ 29.767307215189874, -1.459671034482759 ], [ 29.767307215189874, -1.459401551724138 ], [ 29.768115696202532, -1.459401551724138 ], [ 29.768115696202532, -1.459132068965517 ], [ 29.7667682278481, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23301, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.459132068965517 ], [ 29.768115696202532, -1.459401551724138 ], [ 29.768654683544302, -1.459401551724138 ], [ 29.768654683544302, -1.459132068965517 ], [ 29.768115696202532, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23302, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.459132068965517 ], [ 29.770002151898733, -1.459401551724138 ], [ 29.770541139240507, -1.459401551724138 ], [ 29.770541139240507, -1.459132068965517 ], [ 29.770002151898733, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23303, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.459132068965517 ], [ 29.770541139240507, -1.459401551724138 ], [ 29.770810632911392, -1.459401551724138 ], [ 29.770810632911392, -1.459132068965517 ], [ 29.770541139240507, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23304, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.459132068965517 ], [ 29.770810632911392, -1.459401551724138 ], [ 29.771080126582277, -1.459401551724138 ], [ 29.771080126582277, -1.459132068965517 ], [ 29.770810632911392, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23305, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.459132068965517 ], [ 29.771080126582277, -1.459401551724138 ], [ 29.771349620253165, -1.459401551724138 ], [ 29.771349620253165, -1.459132068965517 ], [ 29.771080126582277, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23306, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.459132068965517 ], [ 29.771349620253165, -1.459401551724138 ], [ 29.77161911392405, -1.459401551724138 ], [ 29.77161911392405, -1.459132068965517 ], [ 29.771349620253165, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23307, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.459132068965517 ], [ 29.77161911392405, -1.459401551724138 ], [ 29.771888607594935, -1.459401551724138 ], [ 29.771888607594935, -1.459132068965517 ], [ 29.77161911392405, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23308, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.459132068965517 ], [ 29.771888607594935, -1.459401551724138 ], [ 29.772158101265823, -1.459401551724138 ], [ 29.772158101265823, -1.459132068965517 ], [ 29.771888607594935, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23309, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.459132068965517 ], [ 29.772158101265823, -1.459401551724138 ], [ 29.772427594936708, -1.459401551724138 ], [ 29.772427594936708, -1.459132068965517 ], [ 29.772158101265823, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23310, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.459132068965517 ], [ 29.772427594936708, -1.459401551724138 ], [ 29.772697088607593, -1.459401551724138 ], [ 29.772697088607593, -1.459132068965517 ], [ 29.772427594936708, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23311, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.459132068965517 ], [ 29.772697088607593, -1.459401551724138 ], [ 29.772966582278482, -1.459401551724138 ], [ 29.772966582278482, -1.459132068965517 ], [ 29.772697088607593, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23312, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.459132068965517 ], [ 29.772966582278482, -1.459401551724138 ], [ 29.773236075949367, -1.459401551724138 ], [ 29.773236075949367, -1.459132068965517 ], [ 29.772966582278482, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23313, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.459132068965517 ], [ 29.773236075949367, -1.459401551724138 ], [ 29.773505569620252, -1.459401551724138 ], [ 29.773505569620252, -1.459132068965517 ], [ 29.773236075949367, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23314, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.459132068965517 ], [ 29.773505569620252, -1.459401551724138 ], [ 29.77377506329114, -1.459401551724138 ], [ 29.77377506329114, -1.459132068965517 ], [ 29.773505569620252, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23315, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.459132068965517 ], [ 29.77377506329114, -1.459401551724138 ], [ 29.774044556962025, -1.459401551724138 ], [ 29.774044556962025, -1.459132068965517 ], [ 29.77377506329114, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23316, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.459132068965517 ], [ 29.774044556962025, -1.459401551724138 ], [ 29.77431405063291, -1.459401551724138 ], [ 29.77431405063291, -1.459132068965517 ], [ 29.774044556962025, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23317, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.459132068965517 ], [ 29.77431405063291, -1.459401551724138 ], [ 29.774853037974683, -1.459401551724138 ], [ 29.774853037974683, -1.459132068965517 ], [ 29.77431405063291, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23318, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.459132068965517 ], [ 29.774853037974683, -1.459401551724138 ], [ 29.775122531645568, -1.459401551724138 ], [ 29.775122531645568, -1.459132068965517 ], [ 29.774853037974683, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23319, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.459132068965517 ], [ 29.775122531645568, -1.459401551724138 ], [ 29.775392025316457, -1.459401551724138 ], [ 29.775392025316457, -1.459132068965517 ], [ 29.775122531645568, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23320, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.459132068965517 ], [ 29.775392025316457, -1.459401551724138 ], [ 29.775661518987341, -1.459401551724138 ], [ 29.775661518987341, -1.459132068965517 ], [ 29.775392025316457, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23321, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.459132068965517 ], [ 29.775661518987341, -1.459401551724138 ], [ 29.775931012658226, -1.459401551724138 ], [ 29.775931012658226, -1.459132068965517 ], [ 29.775661518987341, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23322, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.459132068965517 ], [ 29.775931012658226, -1.459401551724138 ], [ 29.776200506329115, -1.459401551724138 ], [ 29.776200506329115, -1.459132068965517 ], [ 29.775931012658226, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23323, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.459132068965517 ], [ 29.776200506329115, -1.459401551724138 ], [ 29.77647, -1.459401551724138 ], [ 29.77647, -1.459132068965517 ], [ 29.776200506329115, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23324, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.459132068965517 ], [ 29.77647, -1.459401551724138 ], [ 29.776739493670885, -1.459401551724138 ], [ 29.776739493670885, -1.459132068965517 ], [ 29.77647, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23325, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.459132068965517 ], [ 29.776739493670885, -1.459401551724138 ], [ 29.777008987341773, -1.459401551724138 ], [ 29.777008987341773, -1.459132068965517 ], [ 29.776739493670885, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23326, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.459132068965517 ], [ 29.777008987341773, -1.459401551724138 ], [ 29.777278481012658, -1.459401551724138 ], [ 29.777278481012658, -1.459132068965517 ], [ 29.777008987341773, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23327, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.459132068965517 ], [ 29.777278481012658, -1.459401551724138 ], [ 29.777547974683543, -1.459401551724138 ], [ 29.777547974683543, -1.459132068965517 ], [ 29.777278481012658, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23328, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.459132068965517 ], [ 29.777547974683543, -1.459401551724138 ], [ 29.777817468354431, -1.459401551724138 ], [ 29.777817468354431, -1.459132068965517 ], [ 29.777547974683543, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23329, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.459132068965517 ], [ 29.777817468354431, -1.459401551724138 ], [ 29.778086962025316, -1.459401551724138 ], [ 29.778086962025316, -1.459132068965517 ], [ 29.777817468354431, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23330, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.459132068965517 ], [ 29.778086962025316, -1.459401551724138 ], [ 29.778356455696201, -1.459401551724138 ], [ 29.778356455696201, -1.459132068965517 ], [ 29.778086962025316, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23331, "El": 122 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.459132068965517 ], [ 29.778356455696201, -1.459401551724138 ], [ 29.77862594936709, -1.459401551724138 ], [ 29.77862594936709, -1.459132068965517 ], [ 29.778356455696201, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23332, "El": 121 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.459132068965517 ], [ 29.77862594936709, -1.459401551724138 ], [ 29.778895443037975, -1.459401551724138 ], [ 29.778895443037975, -1.459132068965517 ], [ 29.77862594936709, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23333, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.459132068965517 ], [ 29.778895443037975, -1.459401551724138 ], [ 29.77916493670886, -1.459401551724138 ], [ 29.77916493670886, -1.459132068965517 ], [ 29.778895443037975, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23334, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.459132068965517 ], [ 29.77916493670886, -1.459401551724138 ], [ 29.779434430379748, -1.459401551724138 ], [ 29.779434430379748, -1.459132068965517 ], [ 29.77916493670886, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23335, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.459132068965517 ], [ 29.779434430379748, -1.459401551724138 ], [ 29.779703924050633, -1.459401551724138 ], [ 29.779703924050633, -1.459132068965517 ], [ 29.779434430379748, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23336, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.459132068965517 ], [ 29.779703924050633, -1.459401551724138 ], [ 29.779973417721518, -1.459401551724138 ], [ 29.779973417721518, -1.459132068965517 ], [ 29.779703924050633, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23337, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.459132068965517 ], [ 29.779973417721518, -1.459401551724138 ], [ 29.780242911392406, -1.459401551724138 ], [ 29.780242911392406, -1.459132068965517 ], [ 29.779973417721518, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23338, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.459132068965517 ], [ 29.780242911392406, -1.459401551724138 ], [ 29.780512405063291, -1.459401551724138 ], [ 29.780512405063291, -1.459132068965517 ], [ 29.780242911392406, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23339, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.459132068965517 ], [ 29.780512405063291, -1.459401551724138 ], [ 29.780781898734176, -1.459401551724138 ], [ 29.780781898734176, -1.459132068965517 ], [ 29.780512405063291, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23340, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.459132068965517 ], [ 29.780781898734176, -1.459401551724138 ], [ 29.781051392405065, -1.459401551724138 ], [ 29.781051392405065, -1.459132068965517 ], [ 29.780781898734176, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23341, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.459132068965517 ], [ 29.781051392405065, -1.459401551724138 ], [ 29.78132088607595, -1.459401551724138 ], [ 29.78132088607595, -1.459132068965517 ], [ 29.781051392405065, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23342, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.459132068965517 ], [ 29.78132088607595, -1.459401551724138 ], [ 29.781590379746834, -1.459401551724138 ], [ 29.781590379746834, -1.459132068965517 ], [ 29.78132088607595, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23343, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.459132068965517 ], [ 29.781590379746834, -1.459401551724138 ], [ 29.781859873417723, -1.459401551724138 ], [ 29.781859873417723, -1.459132068965517 ], [ 29.781590379746834, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23344, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.459132068965517 ], [ 29.781859873417723, -1.459401551724138 ], [ 29.782129367088608, -1.459401551724138 ], [ 29.782129367088608, -1.459132068965517 ], [ 29.781859873417723, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23345, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.459132068965517 ], [ 29.782129367088608, -1.459401551724138 ], [ 29.782398860759493, -1.459401551724138 ], [ 29.782398860759493, -1.459132068965517 ], [ 29.782129367088608, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23346, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.459132068965517 ], [ 29.782398860759493, -1.459401551724138 ], [ 29.782668354430381, -1.459401551724138 ], [ 29.782668354430381, -1.459132068965517 ], [ 29.782398860759493, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23347, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.459132068965517 ], [ 29.782668354430381, -1.459401551724138 ], [ 29.782937848101266, -1.459401551724138 ], [ 29.782937848101266, -1.459132068965517 ], [ 29.782668354430381, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23348, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.459132068965517 ], [ 29.783207341772151, -1.459401551724138 ], [ 29.783476835443039, -1.459401551724138 ], [ 29.783476835443039, -1.459132068965517 ], [ 29.783207341772151, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23349, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.459132068965517 ], [ 29.783476835443039, -1.459401551724138 ], [ 29.784015822784809, -1.459401551724138 ], [ 29.784015822784809, -1.459132068965517 ], [ 29.783476835443039, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23350, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.459132068965517 ], [ 29.784015822784809, -1.459401551724138 ], [ 29.784285316455698, -1.459401551724138 ], [ 29.784285316455698, -1.459132068965517 ], [ 29.784015822784809, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23351, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.459132068965517 ], [ 29.784285316455698, -1.459671034482759 ], [ 29.784554810126583, -1.459671034482759 ], [ 29.784554810126583, -1.459132068965517 ], [ 29.784285316455698, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23352, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.459132068965517 ], [ 29.784554810126583, -1.459401551724138 ], [ 29.784824303797468, -1.459401551724138 ], [ 29.784824303797468, -1.459132068965517 ], [ 29.784554810126583, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23353, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.459132068965517 ], [ 29.784824303797468, -1.459671034482759 ], [ 29.785093797468356, -1.459671034482759 ], [ 29.785093797468356, -1.459132068965517 ], [ 29.784824303797468, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23354, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.459132068965517 ], [ 29.785093797468356, -1.459940517241379 ], [ 29.785363291139241, -1.459940517241379 ], [ 29.785363291139241, -1.459132068965517 ], [ 29.785093797468356, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23355, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.459132068965517 ], [ 29.785363291139241, -1.459401551724138 ], [ 29.785632784810126, -1.459401551724138 ], [ 29.785632784810126, -1.459132068965517 ], [ 29.785363291139241, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23356, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.459132068965517 ], [ 29.785632784810126, -1.46021 ], [ 29.785902278481014, -1.46021 ], [ 29.785902278481014, -1.459132068965517 ], [ 29.785632784810126, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23357, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.459132068965517 ], [ 29.785902278481014, -1.46021 ], [ 29.786171772151899, -1.46021 ], [ 29.786171772151899, -1.459132068965517 ], [ 29.785902278481014, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23358, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.459132068965517 ], [ 29.786441265822784, -1.46021 ], [ 29.786710759493673, -1.46021 ], [ 29.786710759493673, -1.459132068965517 ], [ 29.786441265822784, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23359, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.459132068965517 ], [ 29.786710759493673, -1.46021 ], [ 29.786980253164558, -1.46021 ], [ 29.786980253164558, -1.459132068965517 ], [ 29.786710759493673, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23360, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.459132068965517 ], [ 29.792370126582277, -1.459401551724138 ], [ 29.792909113924051, -1.459401551724138 ], [ 29.792909113924051, -1.459132068965517 ], [ 29.792370126582277, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23361, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.459132068965517 ], [ 29.792909113924051, -1.459401551724138 ], [ 29.793178607594935, -1.459401551724138 ], [ 29.793178607594935, -1.459132068965517 ], [ 29.792909113924051, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23362, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.459132068965517 ], [ 29.793178607594935, -1.459401551724138 ], [ 29.793448101265824, -1.459401551724138 ], [ 29.793448101265824, -1.459132068965517 ], [ 29.793178607594935, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23363, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.459132068965517 ], [ 29.793448101265824, -1.459401551724138 ], [ 29.793717594936709, -1.459401551724138 ], [ 29.793717594936709, -1.459132068965517 ], [ 29.793448101265824, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23364, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.459132068965517 ], [ 29.793717594936709, -1.459401551724138 ], [ 29.793987088607594, -1.459401551724138 ], [ 29.793987088607594, -1.459132068965517 ], [ 29.793717594936709, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23365, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.459132068965517 ], [ 29.793987088607594, -1.459401551724138 ], [ 29.794256582278482, -1.459401551724138 ], [ 29.794256582278482, -1.459132068965517 ], [ 29.793987088607594, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23366, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.459132068965517 ], [ 29.794256582278482, -1.459401551724138 ], [ 29.794526075949367, -1.459401551724138 ], [ 29.794526075949367, -1.459132068965517 ], [ 29.794256582278482, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23367, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.459132068965517 ], [ 29.794526075949367, -1.459401551724138 ], [ 29.794795569620252, -1.459401551724138 ], [ 29.794795569620252, -1.459132068965517 ], [ 29.794526075949367, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23368, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.459132068965517 ], [ 29.794795569620252, -1.459401551724138 ], [ 29.79506506329114, -1.459401551724138 ], [ 29.79506506329114, -1.459132068965517 ], [ 29.794795569620252, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23369, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.459132068965517 ], [ 29.79506506329114, -1.459401551724138 ], [ 29.795334556962025, -1.459401551724138 ], [ 29.795334556962025, -1.459132068965517 ], [ 29.79506506329114, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23370, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.459132068965517 ], [ 29.795334556962025, -1.459401551724138 ], [ 29.79560405063291, -1.459401551724138 ], [ 29.79560405063291, -1.459132068965517 ], [ 29.795334556962025, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23371, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.459132068965517 ], [ 29.79560405063291, -1.459401551724138 ], [ 29.795873544303799, -1.459401551724138 ], [ 29.795873544303799, -1.459132068965517 ], [ 29.79560405063291, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23372, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.459132068965517 ], [ 29.795873544303799, -1.459401551724138 ], [ 29.796143037974684, -1.459401551724138 ], [ 29.796143037974684, -1.459132068965517 ], [ 29.795873544303799, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23373, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.459132068965517 ], [ 29.796143037974684, -1.459401551724138 ], [ 29.796412531645569, -1.459401551724138 ], [ 29.796412531645569, -1.459132068965517 ], [ 29.796143037974684, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23374, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.459132068965517 ], [ 29.796412531645569, -1.459401551724138 ], [ 29.796682025316457, -1.459401551724138 ], [ 29.796682025316457, -1.459132068965517 ], [ 29.796412531645569, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23375, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.459132068965517 ], [ 29.796682025316457, -1.459401551724138 ], [ 29.796951518987342, -1.459401551724138 ], [ 29.796951518987342, -1.459132068965517 ], [ 29.796682025316457, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23376, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.459132068965517 ], [ 29.796951518987342, -1.459401551724138 ], [ 29.797490506329115, -1.459401551724138 ], [ 29.797490506329115, -1.459132068965517 ], [ 29.796951518987342, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23377, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.459132068965517 ], [ 29.797490506329115, -1.459671034482759 ], [ 29.79776, -1.459671034482759 ], [ 29.79776, -1.459132068965517 ], [ 29.797490506329115, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23378, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.459132068965517 ], [ 29.79776, -1.459671034482759 ], [ 29.798029493670885, -1.459671034482759 ], [ 29.798029493670885, -1.459132068965517 ], [ 29.79776, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23379, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.459132068965517 ], [ 29.798029493670885, -1.459401551724138 ], [ 29.798298987341774, -1.459401551724138 ], [ 29.798298987341774, -1.459132068965517 ], [ 29.798029493670885, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23380, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.459132068965517 ], [ 29.798298987341774, -1.459671034482759 ], [ 29.798568481012659, -1.459671034482759 ], [ 29.798568481012659, -1.459132068965517 ], [ 29.798298987341774, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23381, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.459132068965517 ], [ 29.799107468354432, -1.459671034482759 ], [ 29.799376962025317, -1.459671034482759 ], [ 29.799376962025317, -1.459132068965517 ], [ 29.799107468354432, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23382, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.459132068965517 ], [ 29.79991594936709, -1.459401551724138 ], [ 29.800185443037975, -1.459401551724138 ], [ 29.800185443037975, -1.459132068965517 ], [ 29.79991594936709, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23383, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.459132068965517 ], [ 29.800185443037975, -1.459401551724138 ], [ 29.80045493670886, -1.459401551724138 ], [ 29.80045493670886, -1.459132068965517 ], [ 29.800185443037975, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23384, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.459132068965517 ], [ 29.80045493670886, -1.459401551724138 ], [ 29.800724430379748, -1.459401551724138 ], [ 29.800724430379748, -1.459132068965517 ], [ 29.80045493670886, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23385, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.459132068965517 ], [ 29.800724430379748, -1.46021 ], [ 29.800993924050633, -1.46021 ], [ 29.800993924050633, -1.459132068965517 ], [ 29.800724430379748, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23386, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812312658227849, -1.459132068965517 ], [ 29.812312658227849, -1.459401551724138 ], [ 29.812851645569619, -1.459401551724138 ], [ 29.812851645569619, -1.459132068965517 ], [ 29.812312658227849, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23387, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.459132068965517 ], [ 29.812851645569619, -1.459401551724138 ], [ 29.813121139240508, -1.459401551724138 ], [ 29.813121139240508, -1.459132068965517 ], [ 29.812851645569619, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23388, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.459132068965517 ], [ 29.813121139240508, -1.459401551724138 ], [ 29.813390632911393, -1.459401551724138 ], [ 29.813390632911393, -1.459132068965517 ], [ 29.813121139240508, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23389, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.459132068965517 ], [ 29.813390632911393, -1.459401551724138 ], [ 29.813660126582278, -1.459401551724138 ], [ 29.813660126582278, -1.459132068965517 ], [ 29.813390632911393, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23390, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.459132068965517 ], [ 29.813660126582278, -1.459401551724138 ], [ 29.814199113924051, -1.459401551724138 ], [ 29.814199113924051, -1.459132068965517 ], [ 29.813660126582278, -1.459132068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23391, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75248506329114, -1.459401551724138 ], [ 29.75248506329114, -1.46021 ], [ 29.752754556962024, -1.46021 ], [ 29.752754556962024, -1.459401551724138 ], [ 29.75248506329114, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23392, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.459401551724138 ], [ 29.752754556962024, -1.459940517241379 ], [ 29.753024050632909, -1.459940517241379 ], [ 29.753024050632909, -1.459401551724138 ], [ 29.752754556962024, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23393, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.459401551724138 ], [ 29.753024050632909, -1.459940517241379 ], [ 29.753293544303798, -1.459940517241379 ], [ 29.753293544303798, -1.459401551724138 ], [ 29.753024050632909, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23394, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.459401551724138 ], [ 29.753832531645568, -1.459671034482759 ], [ 29.754102025316456, -1.459671034482759 ], [ 29.754102025316456, -1.459401551724138 ], [ 29.753832531645568, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23395, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.459401551724138 ], [ 29.754102025316456, -1.459671034482759 ], [ 29.754371518987341, -1.459671034482759 ], [ 29.754371518987341, -1.459401551724138 ], [ 29.754102025316456, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23396, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.459401551724138 ], [ 29.754371518987341, -1.459671034482759 ], [ 29.754641012658226, -1.459671034482759 ], [ 29.754641012658226, -1.459401551724138 ], [ 29.754371518987341, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23397, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.459401551724138 ], [ 29.754641012658226, -1.459671034482759 ], [ 29.754910506329114, -1.459671034482759 ], [ 29.754910506329114, -1.459401551724138 ], [ 29.754641012658226, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23398, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.459401551724138 ], [ 29.754910506329114, -1.459671034482759 ], [ 29.75518, -1.459671034482759 ], [ 29.75518, -1.459401551724138 ], [ 29.754910506329114, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23399, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.459401551724138 ], [ 29.75518, -1.459940517241379 ], [ 29.755449493670884, -1.459940517241379 ], [ 29.755449493670884, -1.459401551724138 ], [ 29.75518, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23400, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.459401551724138 ], [ 29.755449493670884, -1.459671034482759 ], [ 29.755718987341773, -1.459671034482759 ], [ 29.755718987341773, -1.459401551724138 ], [ 29.755449493670884, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23401, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.459401551724138 ], [ 29.755718987341773, -1.459671034482759 ], [ 29.755988481012658, -1.459671034482759 ], [ 29.755988481012658, -1.459401551724138 ], [ 29.755718987341773, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23402, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.459401551724138 ], [ 29.755988481012658, -1.459671034482759 ], [ 29.756257974683542, -1.459671034482759 ], [ 29.756257974683542, -1.459401551724138 ], [ 29.755988481012658, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23403, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.459401551724138 ], [ 29.756796962025316, -1.459671034482759 ], [ 29.757066455696201, -1.459671034482759 ], [ 29.757066455696201, -1.459401551724138 ], [ 29.756796962025316, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23404, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.459401551724138 ], [ 29.757066455696201, -1.459671034482759 ], [ 29.757335949367089, -1.459671034482759 ], [ 29.757335949367089, -1.459401551724138 ], [ 29.757066455696201, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23405, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.459401551724138 ], [ 29.757335949367089, -1.459671034482759 ], [ 29.757605443037974, -1.459671034482759 ], [ 29.757605443037974, -1.459401551724138 ], [ 29.757335949367089, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23406, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.459401551724138 ], [ 29.757605443037974, -1.459671034482759 ], [ 29.757874936708859, -1.459671034482759 ], [ 29.757874936708859, -1.459401551724138 ], [ 29.757605443037974, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23407, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.459401551724138 ], [ 29.757874936708859, -1.459940517241379 ], [ 29.758144430379748, -1.459940517241379 ], [ 29.758144430379748, -1.459401551724138 ], [ 29.757874936708859, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23408, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.459401551724138 ], [ 29.758144430379748, -1.459671034482759 ], [ 29.758413924050632, -1.459671034482759 ], [ 29.758413924050632, -1.459401551724138 ], [ 29.758144430379748, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23409, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.459401551724138 ], [ 29.758413924050632, -1.459671034482759 ], [ 29.758683417721517, -1.459671034482759 ], [ 29.758683417721517, -1.459401551724138 ], [ 29.758413924050632, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23410, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.459401551724138 ], [ 29.758683417721517, -1.459671034482759 ], [ 29.758952911392406, -1.459671034482759 ], [ 29.758952911392406, -1.459401551724138 ], [ 29.758683417721517, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23411, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.459401551724138 ], [ 29.758952911392406, -1.459671034482759 ], [ 29.759222405063291, -1.459671034482759 ], [ 29.759222405063291, -1.459401551724138 ], [ 29.758952911392406, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23412, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.459401551724138 ], [ 29.759222405063291, -1.459671034482759 ], [ 29.759491898734176, -1.459671034482759 ], [ 29.759491898734176, -1.459401551724138 ], [ 29.759222405063291, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23413, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.459401551724138 ], [ 29.759491898734176, -1.459671034482759 ], [ 29.759761392405064, -1.459671034482759 ], [ 29.759761392405064, -1.459401551724138 ], [ 29.759491898734176, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23414, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.459401551724138 ], [ 29.759761392405064, -1.459671034482759 ], [ 29.760030886075949, -1.459671034482759 ], [ 29.760030886075949, -1.459401551724138 ], [ 29.759761392405064, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23415, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.459401551724138 ], [ 29.760030886075949, -1.459671034482759 ], [ 29.760300379746834, -1.459671034482759 ], [ 29.760300379746834, -1.459401551724138 ], [ 29.760030886075949, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23416, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.459401551724138 ], [ 29.760300379746834, -1.459671034482759 ], [ 29.760569873417722, -1.459671034482759 ], [ 29.760569873417722, -1.459401551724138 ], [ 29.760300379746834, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23417, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.459401551724138 ], [ 29.760569873417722, -1.459671034482759 ], [ 29.760839367088607, -1.459671034482759 ], [ 29.760839367088607, -1.459401551724138 ], [ 29.760569873417722, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23418, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.459401551724138 ], [ 29.760839367088607, -1.459671034482759 ], [ 29.761108860759492, -1.459671034482759 ], [ 29.761108860759492, -1.459401551724138 ], [ 29.760839367088607, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23419, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.459401551724138 ], [ 29.761108860759492, -1.459671034482759 ], [ 29.761378354430381, -1.459671034482759 ], [ 29.761378354430381, -1.459401551724138 ], [ 29.761108860759492, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23420, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.459401551724138 ], [ 29.761378354430381, -1.459671034482759 ], [ 29.761647848101266, -1.459671034482759 ], [ 29.761647848101266, -1.459401551724138 ], [ 29.761378354430381, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23421, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.459401551724138 ], [ 29.761647848101266, -1.459671034482759 ], [ 29.761917341772151, -1.459671034482759 ], [ 29.761917341772151, -1.459401551724138 ], [ 29.761647848101266, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23422, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.459401551724138 ], [ 29.761917341772151, -1.459671034482759 ], [ 29.762456329113924, -1.459671034482759 ], [ 29.762456329113924, -1.459401551724138 ], [ 29.761917341772151, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23423, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.459401551724138 ], [ 29.762456329113924, -1.459671034482759 ], [ 29.763534303797467, -1.459671034482759 ], [ 29.763534303797467, -1.459401551724138 ], [ 29.762456329113924, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23424, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.459401551724138 ], [ 29.763534303797467, -1.459671034482759 ], [ 29.76407329113924, -1.459671034482759 ], [ 29.76407329113924, -1.459401551724138 ], [ 29.763534303797467, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23425, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.459401551724138 ], [ 29.76407329113924, -1.459671034482759 ], [ 29.764342784810125, -1.459671034482759 ], [ 29.764342784810125, -1.459401551724138 ], [ 29.76407329113924, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23426, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.459401551724138 ], [ 29.764342784810125, -1.459671034482759 ], [ 29.764612278481014, -1.459671034482759 ], [ 29.764612278481014, -1.459401551724138 ], [ 29.764342784810125, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23427, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.459401551724138 ], [ 29.764612278481014, -1.459940517241379 ], [ 29.764881772151899, -1.459940517241379 ], [ 29.764881772151899, -1.459401551724138 ], [ 29.764612278481014, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23428, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.459401551724138 ], [ 29.764881772151899, -1.459671034482759 ], [ 29.765151265822784, -1.459671034482759 ], [ 29.765151265822784, -1.459401551724138 ], [ 29.764881772151899, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23429, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.459401551724138 ], [ 29.765151265822784, -1.459671034482759 ], [ 29.765420759493672, -1.459671034482759 ], [ 29.765420759493672, -1.459401551724138 ], [ 29.765151265822784, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23430, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.459401551724138 ], [ 29.765420759493672, -1.459671034482759 ], [ 29.765959746835442, -1.459671034482759 ], [ 29.765959746835442, -1.459401551724138 ], [ 29.765420759493672, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23431, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.459401551724138 ], [ 29.765959746835442, -1.459671034482759 ], [ 29.766229240506327, -1.459671034482759 ], [ 29.766229240506327, -1.459401551724138 ], [ 29.765959746835442, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23432, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.459401551724138 ], [ 29.766229240506327, -1.459671034482759 ], [ 29.766498734177215, -1.459671034482759 ], [ 29.766498734177215, -1.459401551724138 ], [ 29.766229240506327, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23433, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.459401551724138 ], [ 29.766498734177215, -1.459671034482759 ], [ 29.7667682278481, -1.459671034482759 ], [ 29.7667682278481, -1.459401551724138 ], [ 29.766498734177215, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23434, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.459401551724138 ], [ 29.7667682278481, -1.459671034482759 ], [ 29.767037721518985, -1.459671034482759 ], [ 29.767037721518985, -1.459401551724138 ], [ 29.7667682278481, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23435, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.459401551724138 ], [ 29.767307215189874, -1.459671034482759 ], [ 29.767846202531643, -1.459671034482759 ], [ 29.767846202531643, -1.459401551724138 ], [ 29.767307215189874, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23436, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.459401551724138 ], [ 29.767846202531643, -1.459671034482759 ], [ 29.768115696202532, -1.459671034482759 ], [ 29.768115696202532, -1.459401551724138 ], [ 29.767846202531643, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23437, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.459401551724138 ], [ 29.768115696202532, -1.459671034482759 ], [ 29.768385189873417, -1.459671034482759 ], [ 29.768385189873417, -1.459401551724138 ], [ 29.768115696202532, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23438, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.459401551724138 ], [ 29.768385189873417, -1.459671034482759 ], [ 29.76892417721519, -1.459671034482759 ], [ 29.76892417721519, -1.459401551724138 ], [ 29.768385189873417, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23439, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.459401551724138 ], [ 29.76892417721519, -1.459671034482759 ], [ 29.769732658227849, -1.459671034482759 ], [ 29.769732658227849, -1.459401551724138 ], [ 29.76892417721519, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23440, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.459401551724138 ], [ 29.769732658227849, -1.459671034482759 ], [ 29.770271645569618, -1.459671034482759 ], [ 29.770271645569618, -1.459401551724138 ], [ 29.769732658227849, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23441, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.459401551724138 ], [ 29.770271645569618, -1.459671034482759 ], [ 29.770541139240507, -1.459671034482759 ], [ 29.770541139240507, -1.459401551724138 ], [ 29.770271645569618, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23442, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.459401551724138 ], [ 29.770541139240507, -1.459671034482759 ], [ 29.770810632911392, -1.459671034482759 ], [ 29.770810632911392, -1.459401551724138 ], [ 29.770541139240507, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23443, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.459401551724138 ], [ 29.770810632911392, -1.459671034482759 ], [ 29.771080126582277, -1.459671034482759 ], [ 29.771080126582277, -1.459401551724138 ], [ 29.770810632911392, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23444, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.459401551724138 ], [ 29.771080126582277, -1.459671034482759 ], [ 29.771349620253165, -1.459671034482759 ], [ 29.771349620253165, -1.459401551724138 ], [ 29.771080126582277, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23445, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.459401551724138 ], [ 29.771349620253165, -1.459671034482759 ], [ 29.77161911392405, -1.459671034482759 ], [ 29.77161911392405, -1.459401551724138 ], [ 29.771349620253165, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23446, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.459401551724138 ], [ 29.77161911392405, -1.459671034482759 ], [ 29.771888607594935, -1.459671034482759 ], [ 29.771888607594935, -1.459401551724138 ], [ 29.77161911392405, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23447, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.459401551724138 ], [ 29.771888607594935, -1.459671034482759 ], [ 29.772158101265823, -1.459671034482759 ], [ 29.772158101265823, -1.459401551724138 ], [ 29.771888607594935, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23448, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.459401551724138 ], [ 29.772158101265823, -1.459671034482759 ], [ 29.772427594936708, -1.459671034482759 ], [ 29.772427594936708, -1.459401551724138 ], [ 29.772158101265823, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23449, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.459401551724138 ], [ 29.772427594936708, -1.459671034482759 ], [ 29.772697088607593, -1.459671034482759 ], [ 29.772697088607593, -1.459401551724138 ], [ 29.772427594936708, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23450, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.459401551724138 ], [ 29.772697088607593, -1.459671034482759 ], [ 29.772966582278482, -1.459671034482759 ], [ 29.772966582278482, -1.459401551724138 ], [ 29.772697088607593, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23451, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.459401551724138 ], [ 29.772966582278482, -1.459671034482759 ], [ 29.773236075949367, -1.459671034482759 ], [ 29.773236075949367, -1.459401551724138 ], [ 29.772966582278482, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23452, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.459401551724138 ], [ 29.773236075949367, -1.459671034482759 ], [ 29.773505569620252, -1.459671034482759 ], [ 29.773505569620252, -1.459401551724138 ], [ 29.773236075949367, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23453, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.459401551724138 ], [ 29.773505569620252, -1.459671034482759 ], [ 29.77377506329114, -1.459671034482759 ], [ 29.77377506329114, -1.459401551724138 ], [ 29.773505569620252, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23454, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.459401551724138 ], [ 29.77377506329114, -1.459671034482759 ], [ 29.774044556962025, -1.459671034482759 ], [ 29.774044556962025, -1.459401551724138 ], [ 29.77377506329114, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23455, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.459401551724138 ], [ 29.774044556962025, -1.459671034482759 ], [ 29.77431405063291, -1.459671034482759 ], [ 29.77431405063291, -1.459401551724138 ], [ 29.774044556962025, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23456, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.459401551724138 ], [ 29.77431405063291, -1.459671034482759 ], [ 29.774853037974683, -1.459671034482759 ], [ 29.774853037974683, -1.459401551724138 ], [ 29.77431405063291, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23457, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.459401551724138 ], [ 29.774853037974683, -1.459671034482759 ], [ 29.775122531645568, -1.459671034482759 ], [ 29.775122531645568, -1.459401551724138 ], [ 29.774853037974683, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23458, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.459401551724138 ], [ 29.775122531645568, -1.459671034482759 ], [ 29.775392025316457, -1.459671034482759 ], [ 29.775392025316457, -1.459401551724138 ], [ 29.775122531645568, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23459, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.459401551724138 ], [ 29.775392025316457, -1.459671034482759 ], [ 29.775661518987341, -1.459671034482759 ], [ 29.775661518987341, -1.459401551724138 ], [ 29.775392025316457, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23460, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.459401551724138 ], [ 29.775661518987341, -1.459671034482759 ], [ 29.775931012658226, -1.459671034482759 ], [ 29.775931012658226, -1.459401551724138 ], [ 29.775661518987341, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23461, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.459401551724138 ], [ 29.775931012658226, -1.459671034482759 ], [ 29.776200506329115, -1.459671034482759 ], [ 29.776200506329115, -1.459401551724138 ], [ 29.775931012658226, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23462, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.459401551724138 ], [ 29.776200506329115, -1.459671034482759 ], [ 29.77647, -1.459671034482759 ], [ 29.77647, -1.459401551724138 ], [ 29.776200506329115, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23463, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.459401551724138 ], [ 29.77647, -1.459671034482759 ], [ 29.776739493670885, -1.459671034482759 ], [ 29.776739493670885, -1.459401551724138 ], [ 29.77647, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23464, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.459401551724138 ], [ 29.776739493670885, -1.459671034482759 ], [ 29.777008987341773, -1.459671034482759 ], [ 29.777008987341773, -1.459401551724138 ], [ 29.776739493670885, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23465, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.459401551724138 ], [ 29.777008987341773, -1.459671034482759 ], [ 29.777278481012658, -1.459671034482759 ], [ 29.777278481012658, -1.459401551724138 ], [ 29.777008987341773, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23466, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.459401551724138 ], [ 29.777278481012658, -1.459671034482759 ], [ 29.777547974683543, -1.459671034482759 ], [ 29.777547974683543, -1.459401551724138 ], [ 29.777278481012658, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23467, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.459401551724138 ], [ 29.777547974683543, -1.459671034482759 ], [ 29.777817468354431, -1.459671034482759 ], [ 29.777817468354431, -1.459401551724138 ], [ 29.777547974683543, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23468, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.459401551724138 ], [ 29.777817468354431, -1.459671034482759 ], [ 29.778086962025316, -1.459671034482759 ], [ 29.778086962025316, -1.459401551724138 ], [ 29.777817468354431, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23469, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.459401551724138 ], [ 29.778086962025316, -1.459671034482759 ], [ 29.778356455696201, -1.459671034482759 ], [ 29.778356455696201, -1.459401551724138 ], [ 29.778086962025316, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23470, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.459401551724138 ], [ 29.778356455696201, -1.459671034482759 ], [ 29.77862594936709, -1.459671034482759 ], [ 29.77862594936709, -1.459401551724138 ], [ 29.778356455696201, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23471, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.459401551724138 ], [ 29.77862594936709, -1.459671034482759 ], [ 29.778895443037975, -1.459671034482759 ], [ 29.778895443037975, -1.459401551724138 ], [ 29.77862594936709, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23472, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.459401551724138 ], [ 29.778895443037975, -1.459671034482759 ], [ 29.77916493670886, -1.459671034482759 ], [ 29.77916493670886, -1.459401551724138 ], [ 29.778895443037975, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23473, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.459401551724138 ], [ 29.77916493670886, -1.459671034482759 ], [ 29.779434430379748, -1.459671034482759 ], [ 29.779434430379748, -1.459401551724138 ], [ 29.77916493670886, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23474, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.459401551724138 ], [ 29.779434430379748, -1.459671034482759 ], [ 29.779703924050633, -1.459671034482759 ], [ 29.779703924050633, -1.459401551724138 ], [ 29.779434430379748, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23475, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.459401551724138 ], [ 29.779703924050633, -1.459671034482759 ], [ 29.779973417721518, -1.459671034482759 ], [ 29.779973417721518, -1.459401551724138 ], [ 29.779703924050633, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23476, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.459401551724138 ], [ 29.779973417721518, -1.459671034482759 ], [ 29.780242911392406, -1.459671034482759 ], [ 29.780242911392406, -1.459401551724138 ], [ 29.779973417721518, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23477, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.459401551724138 ], [ 29.780242911392406, -1.459671034482759 ], [ 29.780512405063291, -1.459671034482759 ], [ 29.780512405063291, -1.459401551724138 ], [ 29.780242911392406, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23478, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.459401551724138 ], [ 29.780512405063291, -1.459671034482759 ], [ 29.780781898734176, -1.459671034482759 ], [ 29.780781898734176, -1.459401551724138 ], [ 29.780512405063291, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23479, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.459401551724138 ], [ 29.780781898734176, -1.459671034482759 ], [ 29.781051392405065, -1.459671034482759 ], [ 29.781051392405065, -1.459401551724138 ], [ 29.780781898734176, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23480, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.459401551724138 ], [ 29.781051392405065, -1.459671034482759 ], [ 29.78132088607595, -1.459671034482759 ], [ 29.78132088607595, -1.459401551724138 ], [ 29.781051392405065, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23481, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.459401551724138 ], [ 29.78132088607595, -1.459671034482759 ], [ 29.781590379746834, -1.459671034482759 ], [ 29.781590379746834, -1.459401551724138 ], [ 29.78132088607595, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23482, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.459401551724138 ], [ 29.781590379746834, -1.459671034482759 ], [ 29.781859873417723, -1.459671034482759 ], [ 29.781859873417723, -1.459401551724138 ], [ 29.781590379746834, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23483, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.459401551724138 ], [ 29.781859873417723, -1.459671034482759 ], [ 29.782129367088608, -1.459671034482759 ], [ 29.782129367088608, -1.459401551724138 ], [ 29.781859873417723, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23484, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.459401551724138 ], [ 29.782129367088608, -1.459671034482759 ], [ 29.782398860759493, -1.459671034482759 ], [ 29.782398860759493, -1.459401551724138 ], [ 29.782129367088608, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23485, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.459401551724138 ], [ 29.782398860759493, -1.459671034482759 ], [ 29.782668354430381, -1.459671034482759 ], [ 29.782668354430381, -1.459401551724138 ], [ 29.782398860759493, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23486, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.459401551724138 ], [ 29.782668354430381, -1.459671034482759 ], [ 29.782937848101266, -1.459671034482759 ], [ 29.782937848101266, -1.459401551724138 ], [ 29.782668354430381, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23487, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.459401551724138 ], [ 29.782937848101266, -1.459671034482759 ], [ 29.783207341772151, -1.459671034482759 ], [ 29.783207341772151, -1.459401551724138 ], [ 29.782937848101266, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23488, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.459401551724138 ], [ 29.783207341772151, -1.459671034482759 ], [ 29.783476835443039, -1.459671034482759 ], [ 29.783476835443039, -1.459401551724138 ], [ 29.783207341772151, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23489, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.459401551724138 ], [ 29.783476835443039, -1.459671034482759 ], [ 29.784015822784809, -1.459671034482759 ], [ 29.784015822784809, -1.459401551724138 ], [ 29.783476835443039, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23490, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.459401551724138 ], [ 29.784015822784809, -1.459671034482759 ], [ 29.784285316455698, -1.459671034482759 ], [ 29.784285316455698, -1.459401551724138 ], [ 29.784015822784809, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23491, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.459401551724138 ], [ 29.784554810126583, -1.459671034482759 ], [ 29.784824303797468, -1.459671034482759 ], [ 29.784824303797468, -1.459401551724138 ], [ 29.784554810126583, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23492, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.459401551724138 ], [ 29.785363291139241, -1.459940517241379 ], [ 29.785632784810126, -1.459940517241379 ], [ 29.785632784810126, -1.459401551724138 ], [ 29.785363291139241, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23493, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.459401551724138 ], [ 29.792909113924051, -1.459940517241379 ], [ 29.793448101265824, -1.459940517241379 ], [ 29.793448101265824, -1.459671034482759 ], [ 29.793178607594935, -1.459671034482759 ], [ 29.793178607594935, -1.459401551724138 ], [ 29.792909113924051, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23494, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.459401551724138 ], [ 29.793178607594935, -1.459671034482759 ], [ 29.793448101265824, -1.459671034482759 ], [ 29.793448101265824, -1.459401551724138 ], [ 29.793178607594935, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23495, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.459401551724138 ], [ 29.793448101265824, -1.459671034482759 ], [ 29.793717594936709, -1.459671034482759 ], [ 29.793717594936709, -1.459401551724138 ], [ 29.793448101265824, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23496, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.459401551724138 ], [ 29.793717594936709, -1.459671034482759 ], [ 29.793987088607594, -1.459671034482759 ], [ 29.793987088607594, -1.459401551724138 ], [ 29.793717594936709, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23497, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.459401551724138 ], [ 29.793987088607594, -1.459671034482759 ], [ 29.794256582278482, -1.459671034482759 ], [ 29.794256582278482, -1.459401551724138 ], [ 29.793987088607594, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23498, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.459401551724138 ], [ 29.794256582278482, -1.459671034482759 ], [ 29.794526075949367, -1.459671034482759 ], [ 29.794526075949367, -1.459401551724138 ], [ 29.794256582278482, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23499, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.459401551724138 ], [ 29.794526075949367, -1.459671034482759 ], [ 29.794795569620252, -1.459671034482759 ], [ 29.794795569620252, -1.459401551724138 ], [ 29.794526075949367, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23500, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.459401551724138 ], [ 29.794795569620252, -1.459671034482759 ], [ 29.79506506329114, -1.459671034482759 ], [ 29.79506506329114, -1.459401551724138 ], [ 29.794795569620252, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23501, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.459401551724138 ], [ 29.79506506329114, -1.459671034482759 ], [ 29.795334556962025, -1.459671034482759 ], [ 29.795334556962025, -1.459401551724138 ], [ 29.79506506329114, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23502, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.459401551724138 ], [ 29.795334556962025, -1.459671034482759 ], [ 29.79560405063291, -1.459671034482759 ], [ 29.79560405063291, -1.459401551724138 ], [ 29.795334556962025, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23503, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.459401551724138 ], [ 29.79560405063291, -1.459671034482759 ], [ 29.795873544303799, -1.459671034482759 ], [ 29.795873544303799, -1.459401551724138 ], [ 29.79560405063291, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23504, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.459401551724138 ], [ 29.795873544303799, -1.459671034482759 ], [ 29.796143037974684, -1.459671034482759 ], [ 29.796143037974684, -1.459401551724138 ], [ 29.795873544303799, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23505, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.459401551724138 ], [ 29.796143037974684, -1.459671034482759 ], [ 29.796412531645569, -1.459671034482759 ], [ 29.796412531645569, -1.459401551724138 ], [ 29.796143037974684, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23506, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.459401551724138 ], [ 29.796412531645569, -1.459671034482759 ], [ 29.796682025316457, -1.459671034482759 ], [ 29.796682025316457, -1.459401551724138 ], [ 29.796412531645569, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23507, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.459401551724138 ], [ 29.796682025316457, -1.459671034482759 ], [ 29.796951518987342, -1.459671034482759 ], [ 29.796951518987342, -1.459401551724138 ], [ 29.796682025316457, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23508, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.459401551724138 ], [ 29.796951518987342, -1.459671034482759 ], [ 29.797221012658227, -1.459671034482759 ], [ 29.797221012658227, -1.459401551724138 ], [ 29.796951518987342, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23509, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.459401551724138 ], [ 29.797221012658227, -1.46021 ], [ 29.797490506329115, -1.46021 ], [ 29.797490506329115, -1.459401551724138 ], [ 29.797221012658227, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23510, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.459401551724138 ], [ 29.798029493670885, -1.459940517241379 ], [ 29.798298987341774, -1.459940517241379 ], [ 29.798298987341774, -1.459401551724138 ], [ 29.798029493670885, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23511, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.459401551724138 ], [ 29.798568481012659, -1.459671034482759 ], [ 29.798837974683543, -1.459671034482759 ], [ 29.798837974683543, -1.459401551724138 ], [ 29.798568481012659, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23512, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.459401551724138 ], [ 29.798837974683543, -1.459671034482759 ], [ 29.799107468354432, -1.459671034482759 ], [ 29.799107468354432, -1.459401551724138 ], [ 29.798837974683543, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23513, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.459401551724138 ], [ 29.799376962025317, -1.459671034482759 ], [ 29.799646455696202, -1.459671034482759 ], [ 29.799646455696202, -1.459401551724138 ], [ 29.799376962025317, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23514, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.459401551724138 ], [ 29.799646455696202, -1.459671034482759 ], [ 29.79991594936709, -1.459671034482759 ], [ 29.79991594936709, -1.459401551724138 ], [ 29.799646455696202, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23515, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.459401551724138 ], [ 29.79991594936709, -1.459671034482759 ], [ 29.800185443037975, -1.459671034482759 ], [ 29.800185443037975, -1.459401551724138 ], [ 29.79991594936709, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23516, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.459401551724138 ], [ 29.800185443037975, -1.46021 ], [ 29.80045493670886, -1.46021 ], [ 29.80045493670886, -1.459401551724138 ], [ 29.800185443037975, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23517, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.459401551724138 ], [ 29.80045493670886, -1.46021 ], [ 29.800724430379748, -1.46021 ], [ 29.800724430379748, -1.459401551724138 ], [ 29.80045493670886, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23518, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.812851645569619, -1.459401551724138 ], [ 29.812851645569619, -1.459671034482759 ], [ 29.813121139240508, -1.459671034482759 ], [ 29.813121139240508, -1.459401551724138 ], [ 29.812851645569619, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23519, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.459401551724138 ], [ 29.813121139240508, -1.459671034482759 ], [ 29.813390632911393, -1.459671034482759 ], [ 29.813390632911393, -1.459401551724138 ], [ 29.813121139240508, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23520, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.459401551724138 ], [ 29.813390632911393, -1.459671034482759 ], [ 29.813660126582278, -1.459671034482759 ], [ 29.813660126582278, -1.459401551724138 ], [ 29.813390632911393, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23521, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.459401551724138 ], [ 29.813660126582278, -1.459671034482759 ], [ 29.813929620253166, -1.459671034482759 ], [ 29.813929620253166, -1.459401551724138 ], [ 29.813660126582278, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23522, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.459401551724138 ], [ 29.813929620253166, -1.459671034482759 ], [ 29.814199113924051, -1.459671034482759 ], [ 29.814199113924051, -1.459401551724138 ], [ 29.813929620253166, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23523, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.459401551724138 ], [ 29.814199113924051, -1.459671034482759 ], [ 29.814468607594936, -1.459671034482759 ], [ 29.814468607594936, -1.459401551724138 ], [ 29.814199113924051, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23524, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.459401551724138 ], [ 29.814468607594936, -1.459671034482759 ], [ 29.814738101265824, -1.459671034482759 ], [ 29.814738101265824, -1.459401551724138 ], [ 29.814468607594936, -1.459401551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23525, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.459671034482759 ], [ 29.753293544303798, -1.459940517241379 ], [ 29.753563037974683, -1.459940517241379 ], [ 29.753563037974683, -1.459671034482759 ], [ 29.753293544303798, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23526, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.459671034482759 ], [ 29.753563037974683, -1.459940517241379 ], [ 29.753832531645568, -1.459940517241379 ], [ 29.753832531645568, -1.459671034482759 ], [ 29.753563037974683, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23527, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.459671034482759 ], [ 29.753832531645568, -1.459940517241379 ], [ 29.754102025316456, -1.459940517241379 ], [ 29.754102025316456, -1.459671034482759 ], [ 29.753832531645568, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23528, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.459671034482759 ], [ 29.754102025316456, -1.46021 ], [ 29.754371518987341, -1.46021 ], [ 29.754371518987341, -1.459671034482759 ], [ 29.754102025316456, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23529, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.459671034482759 ], [ 29.754371518987341, -1.459940517241379 ], [ 29.754641012658226, -1.459940517241379 ], [ 29.754641012658226, -1.459671034482759 ], [ 29.754371518987341, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23530, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.459671034482759 ], [ 29.754641012658226, -1.459940517241379 ], [ 29.754910506329114, -1.459940517241379 ], [ 29.754910506329114, -1.459671034482759 ], [ 29.754641012658226, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23531, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.459671034482759 ], [ 29.754910506329114, -1.459940517241379 ], [ 29.75518, -1.459940517241379 ], [ 29.75518, -1.459671034482759 ], [ 29.754910506329114, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23532, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.459671034482759 ], [ 29.755449493670884, -1.459940517241379 ], [ 29.755718987341773, -1.459940517241379 ], [ 29.755718987341773, -1.459671034482759 ], [ 29.755449493670884, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23533, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.459671034482759 ], [ 29.755718987341773, -1.459940517241379 ], [ 29.755988481012658, -1.459940517241379 ], [ 29.755988481012658, -1.459671034482759 ], [ 29.755718987341773, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23534, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.459671034482759 ], [ 29.755988481012658, -1.459940517241379 ], [ 29.756257974683542, -1.459940517241379 ], [ 29.756257974683542, -1.459671034482759 ], [ 29.755988481012658, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23535, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.459671034482759 ], [ 29.756257974683542, -1.459940517241379 ], [ 29.756796962025316, -1.459940517241379 ], [ 29.756796962025316, -1.459671034482759 ], [ 29.756257974683542, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23536, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.459671034482759 ], [ 29.756796962025316, -1.459940517241379 ], [ 29.757066455696201, -1.459940517241379 ], [ 29.757066455696201, -1.459671034482759 ], [ 29.756796962025316, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23537, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.459671034482759 ], [ 29.757066455696201, -1.459940517241379 ], [ 29.757335949367089, -1.459940517241379 ], [ 29.757335949367089, -1.459671034482759 ], [ 29.757066455696201, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23538, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.459671034482759 ], [ 29.757335949367089, -1.459940517241379 ], [ 29.757605443037974, -1.459940517241379 ], [ 29.757605443037974, -1.459671034482759 ], [ 29.757335949367089, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23539, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.459671034482759 ], [ 29.757605443037974, -1.459940517241379 ], [ 29.757874936708859, -1.459940517241379 ], [ 29.757874936708859, -1.459671034482759 ], [ 29.757605443037974, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23540, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.459671034482759 ], [ 29.758144430379748, -1.459940517241379 ], [ 29.758413924050632, -1.459940517241379 ], [ 29.758413924050632, -1.459671034482759 ], [ 29.758144430379748, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23541, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.459671034482759 ], [ 29.758413924050632, -1.459940517241379 ], [ 29.758683417721517, -1.459940517241379 ], [ 29.758683417721517, -1.459671034482759 ], [ 29.758413924050632, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23542, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.459671034482759 ], [ 29.758683417721517, -1.459940517241379 ], [ 29.758952911392406, -1.459940517241379 ], [ 29.758952911392406, -1.459671034482759 ], [ 29.758683417721517, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23543, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.459671034482759 ], [ 29.758952911392406, -1.46021 ], [ 29.759491898734176, -1.46021 ], [ 29.759491898734176, -1.459940517241379 ], [ 29.759222405063291, -1.459940517241379 ], [ 29.759222405063291, -1.459671034482759 ], [ 29.758952911392406, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23544, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.459671034482759 ], [ 29.759222405063291, -1.459940517241379 ], [ 29.759491898734176, -1.459940517241379 ], [ 29.759491898734176, -1.459671034482759 ], [ 29.759222405063291, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23545, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.459671034482759 ], [ 29.759491898734176, -1.459940517241379 ], [ 29.759761392405064, -1.459940517241379 ], [ 29.759761392405064, -1.459671034482759 ], [ 29.759491898734176, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23546, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.459671034482759 ], [ 29.759761392405064, -1.459940517241379 ], [ 29.760030886075949, -1.459940517241379 ], [ 29.760030886075949, -1.459671034482759 ], [ 29.759761392405064, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23547, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.459671034482759 ], [ 29.760030886075949, -1.459940517241379 ], [ 29.760300379746834, -1.459940517241379 ], [ 29.760300379746834, -1.459671034482759 ], [ 29.760030886075949, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23548, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.459671034482759 ], [ 29.760300379746834, -1.459940517241379 ], [ 29.760569873417722, -1.459940517241379 ], [ 29.760569873417722, -1.459671034482759 ], [ 29.760300379746834, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23549, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.459671034482759 ], [ 29.760569873417722, -1.459940517241379 ], [ 29.760839367088607, -1.459940517241379 ], [ 29.760839367088607, -1.459671034482759 ], [ 29.760569873417722, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23550, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.459671034482759 ], [ 29.760839367088607, -1.459940517241379 ], [ 29.761108860759492, -1.459940517241379 ], [ 29.761108860759492, -1.459671034482759 ], [ 29.760839367088607, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23551, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.459671034482759 ], [ 29.761108860759492, -1.459940517241379 ], [ 29.761378354430381, -1.459940517241379 ], [ 29.761378354430381, -1.459671034482759 ], [ 29.761108860759492, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23552, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.459671034482759 ], [ 29.761378354430381, -1.459940517241379 ], [ 29.761647848101266, -1.459940517241379 ], [ 29.761647848101266, -1.459671034482759 ], [ 29.761378354430381, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23553, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.459671034482759 ], [ 29.761647848101266, -1.459940517241379 ], [ 29.762186835443039, -1.459940517241379 ], [ 29.762186835443039, -1.459671034482759 ], [ 29.761647848101266, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23554, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.459671034482759 ], [ 29.762186835443039, -1.459940517241379 ], [ 29.763803797468356, -1.459940517241379 ], [ 29.763803797468356, -1.459671034482759 ], [ 29.762186835443039, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23555, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.459671034482759 ], [ 29.763803797468356, -1.459940517241379 ], [ 29.764612278481014, -1.459940517241379 ], [ 29.764612278481014, -1.459671034482759 ], [ 29.763803797468356, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23556, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.459671034482759 ], [ 29.764881772151899, -1.459940517241379 ], [ 29.765151265822784, -1.459940517241379 ], [ 29.765151265822784, -1.459671034482759 ], [ 29.764881772151899, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23557, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.459671034482759 ], [ 29.765151265822784, -1.459940517241379 ], [ 29.765420759493672, -1.459940517241379 ], [ 29.765420759493672, -1.459671034482759 ], [ 29.765151265822784, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23558, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.459671034482759 ], [ 29.765420759493672, -1.459940517241379 ], [ 29.765959746835442, -1.459940517241379 ], [ 29.765959746835442, -1.459671034482759 ], [ 29.765420759493672, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23559, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.459671034482759 ], [ 29.765959746835442, -1.459940517241379 ], [ 29.766229240506327, -1.459940517241379 ], [ 29.766229240506327, -1.459671034482759 ], [ 29.765959746835442, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23560, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.459671034482759 ], [ 29.766229240506327, -1.459940517241379 ], [ 29.766498734177215, -1.459940517241379 ], [ 29.766498734177215, -1.459671034482759 ], [ 29.766229240506327, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23561, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.459671034482759 ], [ 29.766498734177215, -1.459940517241379 ], [ 29.767037721518985, -1.459940517241379 ], [ 29.767037721518985, -1.459671034482759 ], [ 29.766498734177215, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23562, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.459671034482759 ], [ 29.767037721518985, -1.459940517241379 ], [ 29.767307215189874, -1.459940517241379 ], [ 29.767307215189874, -1.459671034482759 ], [ 29.767037721518985, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23563, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.459671034482759 ], [ 29.767307215189874, -1.459940517241379 ], [ 29.767576708860759, -1.459940517241379 ], [ 29.767576708860759, -1.459671034482759 ], [ 29.767307215189874, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23564, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.459671034482759 ], [ 29.767576708860759, -1.459940517241379 ], [ 29.767846202531643, -1.459940517241379 ], [ 29.767846202531643, -1.459671034482759 ], [ 29.767576708860759, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23565, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.459671034482759 ], [ 29.767846202531643, -1.459940517241379 ], [ 29.768115696202532, -1.459940517241379 ], [ 29.768115696202532, -1.459671034482759 ], [ 29.767846202531643, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23566, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.459671034482759 ], [ 29.768115696202532, -1.459940517241379 ], [ 29.768385189873417, -1.459940517241379 ], [ 29.768385189873417, -1.459671034482759 ], [ 29.768115696202532, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23567, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.459671034482759 ], [ 29.768385189873417, -1.459940517241379 ], [ 29.768654683544302, -1.459940517241379 ], [ 29.768654683544302, -1.459671034482759 ], [ 29.768385189873417, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23568, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.459671034482759 ], [ 29.768654683544302, -1.459940517241379 ], [ 29.770002151898733, -1.459940517241379 ], [ 29.770002151898733, -1.459671034482759 ], [ 29.768654683544302, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23569, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.459671034482759 ], [ 29.770002151898733, -1.459940517241379 ], [ 29.770271645569618, -1.459940517241379 ], [ 29.770271645569618, -1.459671034482759 ], [ 29.770002151898733, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23570, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.459671034482759 ], [ 29.770271645569618, -1.459940517241379 ], [ 29.770541139240507, -1.459940517241379 ], [ 29.770541139240507, -1.459671034482759 ], [ 29.770271645569618, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23571, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.459671034482759 ], [ 29.770541139240507, -1.459940517241379 ], [ 29.770810632911392, -1.459940517241379 ], [ 29.770810632911392, -1.459671034482759 ], [ 29.770541139240507, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23572, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.459671034482759 ], [ 29.770810632911392, -1.459940517241379 ], [ 29.771080126582277, -1.459940517241379 ], [ 29.771080126582277, -1.459671034482759 ], [ 29.770810632911392, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23573, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.459671034482759 ], [ 29.771080126582277, -1.459940517241379 ], [ 29.771349620253165, -1.459940517241379 ], [ 29.771349620253165, -1.459671034482759 ], [ 29.771080126582277, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23574, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.459671034482759 ], [ 29.771349620253165, -1.459940517241379 ], [ 29.77161911392405, -1.459940517241379 ], [ 29.77161911392405, -1.459671034482759 ], [ 29.771349620253165, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23575, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.459671034482759 ], [ 29.77161911392405, -1.459940517241379 ], [ 29.771888607594935, -1.459940517241379 ], [ 29.771888607594935, -1.459671034482759 ], [ 29.77161911392405, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23576, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.459671034482759 ], [ 29.771888607594935, -1.459940517241379 ], [ 29.772158101265823, -1.459940517241379 ], [ 29.772158101265823, -1.459671034482759 ], [ 29.771888607594935, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23577, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.459671034482759 ], [ 29.772158101265823, -1.459940517241379 ], [ 29.772427594936708, -1.459940517241379 ], [ 29.772427594936708, -1.459671034482759 ], [ 29.772158101265823, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23578, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.459671034482759 ], [ 29.772427594936708, -1.459940517241379 ], [ 29.772697088607593, -1.459940517241379 ], [ 29.772697088607593, -1.459671034482759 ], [ 29.772427594936708, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23579, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.459671034482759 ], [ 29.772697088607593, -1.459940517241379 ], [ 29.772966582278482, -1.459940517241379 ], [ 29.772966582278482, -1.459671034482759 ], [ 29.772697088607593, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23580, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.459671034482759 ], [ 29.772966582278482, -1.459940517241379 ], [ 29.773236075949367, -1.459940517241379 ], [ 29.773236075949367, -1.459671034482759 ], [ 29.772966582278482, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23581, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.459671034482759 ], [ 29.773236075949367, -1.459940517241379 ], [ 29.773505569620252, -1.459940517241379 ], [ 29.773505569620252, -1.459671034482759 ], [ 29.773236075949367, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23582, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.459671034482759 ], [ 29.773505569620252, -1.459940517241379 ], [ 29.77377506329114, -1.459940517241379 ], [ 29.77377506329114, -1.459671034482759 ], [ 29.773505569620252, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23583, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.459671034482759 ], [ 29.77377506329114, -1.459940517241379 ], [ 29.774044556962025, -1.459940517241379 ], [ 29.774044556962025, -1.459671034482759 ], [ 29.77377506329114, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23584, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.459671034482759 ], [ 29.774044556962025, -1.459940517241379 ], [ 29.77431405063291, -1.459940517241379 ], [ 29.77431405063291, -1.459671034482759 ], [ 29.774044556962025, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23585, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.459671034482759 ], [ 29.77431405063291, -1.459940517241379 ], [ 29.774853037974683, -1.459940517241379 ], [ 29.774853037974683, -1.459671034482759 ], [ 29.77431405063291, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23586, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.459671034482759 ], [ 29.774853037974683, -1.459940517241379 ], [ 29.775122531645568, -1.459940517241379 ], [ 29.775122531645568, -1.459671034482759 ], [ 29.774853037974683, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23587, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.459671034482759 ], [ 29.775122531645568, -1.459940517241379 ], [ 29.775392025316457, -1.459940517241379 ], [ 29.775392025316457, -1.459671034482759 ], [ 29.775122531645568, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23588, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.459671034482759 ], [ 29.775392025316457, -1.459940517241379 ], [ 29.775661518987341, -1.459940517241379 ], [ 29.775661518987341, -1.459671034482759 ], [ 29.775392025316457, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23589, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.459671034482759 ], [ 29.775661518987341, -1.459940517241379 ], [ 29.775931012658226, -1.459940517241379 ], [ 29.775931012658226, -1.459671034482759 ], [ 29.775661518987341, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23590, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.459671034482759 ], [ 29.775931012658226, -1.459940517241379 ], [ 29.776200506329115, -1.459940517241379 ], [ 29.776200506329115, -1.459671034482759 ], [ 29.775931012658226, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23591, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.459671034482759 ], [ 29.776200506329115, -1.459940517241379 ], [ 29.77647, -1.459940517241379 ], [ 29.77647, -1.459671034482759 ], [ 29.776200506329115, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23592, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.459671034482759 ], [ 29.77647, -1.459940517241379 ], [ 29.776739493670885, -1.459940517241379 ], [ 29.776739493670885, -1.459671034482759 ], [ 29.77647, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23593, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.459671034482759 ], [ 29.776739493670885, -1.459940517241379 ], [ 29.777008987341773, -1.459940517241379 ], [ 29.777008987341773, -1.459671034482759 ], [ 29.776739493670885, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23594, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.459671034482759 ], [ 29.777008987341773, -1.459940517241379 ], [ 29.777278481012658, -1.459940517241379 ], [ 29.777278481012658, -1.459671034482759 ], [ 29.777008987341773, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23595, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.459671034482759 ], [ 29.777278481012658, -1.459940517241379 ], [ 29.777547974683543, -1.459940517241379 ], [ 29.777547974683543, -1.459671034482759 ], [ 29.777278481012658, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23596, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.459671034482759 ], [ 29.777547974683543, -1.459940517241379 ], [ 29.777817468354431, -1.459940517241379 ], [ 29.777817468354431, -1.459671034482759 ], [ 29.777547974683543, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23597, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.459671034482759 ], [ 29.777817468354431, -1.459940517241379 ], [ 29.778086962025316, -1.459940517241379 ], [ 29.778086962025316, -1.459671034482759 ], [ 29.777817468354431, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23598, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.459671034482759 ], [ 29.778086962025316, -1.459940517241379 ], [ 29.778356455696201, -1.459940517241379 ], [ 29.778356455696201, -1.459671034482759 ], [ 29.778086962025316, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23599, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.459671034482759 ], [ 29.778356455696201, -1.459940517241379 ], [ 29.77862594936709, -1.459940517241379 ], [ 29.77862594936709, -1.459671034482759 ], [ 29.778356455696201, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23600, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.459671034482759 ], [ 29.77862594936709, -1.459940517241379 ], [ 29.778895443037975, -1.459940517241379 ], [ 29.778895443037975, -1.459671034482759 ], [ 29.77862594936709, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23601, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.459671034482759 ], [ 29.778895443037975, -1.459940517241379 ], [ 29.77916493670886, -1.459940517241379 ], [ 29.77916493670886, -1.459671034482759 ], [ 29.778895443037975, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23602, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.459671034482759 ], [ 29.77916493670886, -1.459940517241379 ], [ 29.779434430379748, -1.459940517241379 ], [ 29.779434430379748, -1.459671034482759 ], [ 29.77916493670886, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23603, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.459671034482759 ], [ 29.779434430379748, -1.459940517241379 ], [ 29.779703924050633, -1.459940517241379 ], [ 29.779703924050633, -1.459671034482759 ], [ 29.779434430379748, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23604, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.459671034482759 ], [ 29.779703924050633, -1.459940517241379 ], [ 29.779973417721518, -1.459940517241379 ], [ 29.779973417721518, -1.459671034482759 ], [ 29.779703924050633, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23605, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.459671034482759 ], [ 29.779973417721518, -1.459940517241379 ], [ 29.780242911392406, -1.459940517241379 ], [ 29.780242911392406, -1.459671034482759 ], [ 29.779973417721518, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23606, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.459671034482759 ], [ 29.780242911392406, -1.459940517241379 ], [ 29.780512405063291, -1.459940517241379 ], [ 29.780512405063291, -1.459671034482759 ], [ 29.780242911392406, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23607, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.459671034482759 ], [ 29.780512405063291, -1.459940517241379 ], [ 29.780781898734176, -1.459940517241379 ], [ 29.780781898734176, -1.459671034482759 ], [ 29.780512405063291, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23608, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.459671034482759 ], [ 29.780781898734176, -1.459940517241379 ], [ 29.781051392405065, -1.459940517241379 ], [ 29.781051392405065, -1.459671034482759 ], [ 29.780781898734176, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23609, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.459671034482759 ], [ 29.781051392405065, -1.459940517241379 ], [ 29.78132088607595, -1.459940517241379 ], [ 29.78132088607595, -1.459671034482759 ], [ 29.781051392405065, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23610, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.459671034482759 ], [ 29.78132088607595, -1.459940517241379 ], [ 29.781590379746834, -1.459940517241379 ], [ 29.781590379746834, -1.459671034482759 ], [ 29.78132088607595, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23611, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.459671034482759 ], [ 29.781590379746834, -1.459940517241379 ], [ 29.781859873417723, -1.459940517241379 ], [ 29.781859873417723, -1.459671034482759 ], [ 29.781590379746834, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23612, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.459671034482759 ], [ 29.781859873417723, -1.459940517241379 ], [ 29.782129367088608, -1.459940517241379 ], [ 29.782129367088608, -1.459671034482759 ], [ 29.781859873417723, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23613, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.459671034482759 ], [ 29.782129367088608, -1.459940517241379 ], [ 29.782398860759493, -1.459940517241379 ], [ 29.782398860759493, -1.459671034482759 ], [ 29.782129367088608, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23614, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.459671034482759 ], [ 29.782398860759493, -1.459940517241379 ], [ 29.782668354430381, -1.459940517241379 ], [ 29.782668354430381, -1.459671034482759 ], [ 29.782398860759493, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23615, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.459671034482759 ], [ 29.782668354430381, -1.459940517241379 ], [ 29.782937848101266, -1.459940517241379 ], [ 29.782937848101266, -1.459671034482759 ], [ 29.782668354430381, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23616, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.459671034482759 ], [ 29.782937848101266, -1.459940517241379 ], [ 29.783207341772151, -1.459940517241379 ], [ 29.783207341772151, -1.459671034482759 ], [ 29.782937848101266, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23617, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.459671034482759 ], [ 29.783207341772151, -1.459940517241379 ], [ 29.783476835443039, -1.459940517241379 ], [ 29.783476835443039, -1.459671034482759 ], [ 29.783207341772151, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23618, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.459671034482759 ], [ 29.783476835443039, -1.459940517241379 ], [ 29.784015822784809, -1.459940517241379 ], [ 29.784015822784809, -1.459671034482759 ], [ 29.783476835443039, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23619, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.459671034482759 ], [ 29.784015822784809, -1.459940517241379 ], [ 29.784285316455698, -1.459940517241379 ], [ 29.784285316455698, -1.459671034482759 ], [ 29.784015822784809, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23620, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.459671034482759 ], [ 29.784285316455698, -1.459940517241379 ], [ 29.784554810126583, -1.459940517241379 ], [ 29.784554810126583, -1.459671034482759 ], [ 29.784285316455698, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23621, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.459671034482759 ], [ 29.784554810126583, -1.459940517241379 ], [ 29.784824303797468, -1.459940517241379 ], [ 29.784824303797468, -1.459671034482759 ], [ 29.784554810126583, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23622, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.459671034482759 ], [ 29.784824303797468, -1.459940517241379 ], [ 29.785093797468356, -1.459940517241379 ], [ 29.785093797468356, -1.459671034482759 ], [ 29.784824303797468, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23623, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.459671034482759 ], [ 29.793448101265824, -1.459940517241379 ], [ 29.793717594936709, -1.459940517241379 ], [ 29.793717594936709, -1.459671034482759 ], [ 29.793448101265824, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23624, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.459671034482759 ], [ 29.793717594936709, -1.459940517241379 ], [ 29.793987088607594, -1.459940517241379 ], [ 29.793987088607594, -1.459671034482759 ], [ 29.793717594936709, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23625, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.459671034482759 ], [ 29.793987088607594, -1.459940517241379 ], [ 29.794256582278482, -1.459940517241379 ], [ 29.794256582278482, -1.459671034482759 ], [ 29.793987088607594, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23626, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.459671034482759 ], [ 29.794256582278482, -1.459940517241379 ], [ 29.794526075949367, -1.459940517241379 ], [ 29.794526075949367, -1.459671034482759 ], [ 29.794256582278482, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23627, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.459671034482759 ], [ 29.794526075949367, -1.459940517241379 ], [ 29.794795569620252, -1.459940517241379 ], [ 29.794795569620252, -1.459671034482759 ], [ 29.794526075949367, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23628, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.459671034482759 ], [ 29.794795569620252, -1.459940517241379 ], [ 29.79506506329114, -1.459940517241379 ], [ 29.79506506329114, -1.459671034482759 ], [ 29.794795569620252, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23629, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.459671034482759 ], [ 29.79506506329114, -1.459940517241379 ], [ 29.795334556962025, -1.459940517241379 ], [ 29.795334556962025, -1.459671034482759 ], [ 29.79506506329114, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23630, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.459671034482759 ], [ 29.795334556962025, -1.459940517241379 ], [ 29.79560405063291, -1.459940517241379 ], [ 29.79560405063291, -1.459671034482759 ], [ 29.795334556962025, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23631, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.459671034482759 ], [ 29.79560405063291, -1.459940517241379 ], [ 29.795873544303799, -1.459940517241379 ], [ 29.795873544303799, -1.459671034482759 ], [ 29.79560405063291, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23632, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.459671034482759 ], [ 29.795873544303799, -1.459940517241379 ], [ 29.796143037974684, -1.459940517241379 ], [ 29.796143037974684, -1.459671034482759 ], [ 29.795873544303799, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23633, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.459671034482759 ], [ 29.796143037974684, -1.459940517241379 ], [ 29.796412531645569, -1.459940517241379 ], [ 29.796412531645569, -1.459671034482759 ], [ 29.796143037974684, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23634, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.459671034482759 ], [ 29.796412531645569, -1.46021 ], [ 29.796682025316457, -1.46021 ], [ 29.796682025316457, -1.459671034482759 ], [ 29.796412531645569, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23635, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.459671034482759 ], [ 29.796682025316457, -1.459940517241379 ], [ 29.796951518987342, -1.459940517241379 ], [ 29.796951518987342, -1.459671034482759 ], [ 29.796682025316457, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23636, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.459671034482759 ], [ 29.796951518987342, -1.459940517241379 ], [ 29.797221012658227, -1.459940517241379 ], [ 29.797221012658227, -1.459671034482759 ], [ 29.796951518987342, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23637, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.459671034482759 ], [ 29.797490506329115, -1.459940517241379 ], [ 29.79776, -1.459940517241379 ], [ 29.79776, -1.459671034482759 ], [ 29.797490506329115, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23638, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.459671034482759 ], [ 29.79776, -1.459940517241379 ], [ 29.798029493670885, -1.459940517241379 ], [ 29.798029493670885, -1.459671034482759 ], [ 29.79776, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23639, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.459671034482759 ], [ 29.798298987341774, -1.459940517241379 ], [ 29.798568481012659, -1.459940517241379 ], [ 29.798568481012659, -1.459671034482759 ], [ 29.798298987341774, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23640, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.459671034482759 ], [ 29.798568481012659, -1.459940517241379 ], [ 29.798837974683543, -1.459940517241379 ], [ 29.798837974683543, -1.459671034482759 ], [ 29.798568481012659, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23641, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.459671034482759 ], [ 29.798837974683543, -1.459940517241379 ], [ 29.799107468354432, -1.459940517241379 ], [ 29.799107468354432, -1.459671034482759 ], [ 29.798837974683543, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23642, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.459671034482759 ], [ 29.799107468354432, -1.459940517241379 ], [ 29.799376962025317, -1.459940517241379 ], [ 29.799376962025317, -1.459671034482759 ], [ 29.799107468354432, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23643, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.459671034482759 ], [ 29.799376962025317, -1.46021 ], [ 29.799646455696202, -1.46021 ], [ 29.799646455696202, -1.459671034482759 ], [ 29.799376962025317, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23644, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.459671034482759 ], [ 29.799646455696202, -1.460479482758621 ], [ 29.79991594936709, -1.460479482758621 ], [ 29.79991594936709, -1.459671034482759 ], [ 29.799646455696202, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23645, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.459671034482759 ], [ 29.79991594936709, -1.460479482758621 ], [ 29.800185443037975, -1.460479482758621 ], [ 29.800185443037975, -1.459671034482759 ], [ 29.79991594936709, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23646, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813121139240508, -1.459671034482759 ], [ 29.813121139240508, -1.46021 ], [ 29.813390632911393, -1.46021 ], [ 29.813390632911393, -1.459671034482759 ], [ 29.813121139240508, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23647, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.459671034482759 ], [ 29.813390632911393, -1.459940517241379 ], [ 29.813660126582278, -1.459940517241379 ], [ 29.813660126582278, -1.459671034482759 ], [ 29.813390632911393, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23648, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.459671034482759 ], [ 29.813660126582278, -1.459940517241379 ], [ 29.813929620253166, -1.459940517241379 ], [ 29.813929620253166, -1.459671034482759 ], [ 29.813660126582278, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23649, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.459671034482759 ], [ 29.813929620253166, -1.459940517241379 ], [ 29.814199113924051, -1.459940517241379 ], [ 29.814199113924051, -1.459671034482759 ], [ 29.813929620253166, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23650, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.459671034482759 ], [ 29.814199113924051, -1.459940517241379 ], [ 29.814468607594936, -1.459940517241379 ], [ 29.814468607594936, -1.459671034482759 ], [ 29.814199113924051, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23651, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814468607594936, -1.459671034482759 ], [ 29.814468607594936, -1.459940517241379 ], [ 29.814738101265824, -1.459940517241379 ], [ 29.814738101265824, -1.459671034482759 ], [ 29.814468607594936, -1.459671034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23652, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.459940517241379 ], [ 29.752754556962024, -1.46021 ], [ 29.753024050632909, -1.46021 ], [ 29.753024050632909, -1.459940517241379 ], [ 29.752754556962024, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23653, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.459940517241379 ], [ 29.753024050632909, -1.46021 ], [ 29.753293544303798, -1.46021 ], [ 29.753293544303798, -1.459940517241379 ], [ 29.753024050632909, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23654, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.459940517241379 ], [ 29.753293544303798, -1.46021 ], [ 29.753563037974683, -1.46021 ], [ 29.753563037974683, -1.459940517241379 ], [ 29.753293544303798, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23655, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.459940517241379 ], [ 29.753563037974683, -1.460479482758621 ], [ 29.753832531645568, -1.460479482758621 ], [ 29.753832531645568, -1.459940517241379 ], [ 29.753563037974683, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23656, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.459940517241379 ], [ 29.753832531645568, -1.460479482758621 ], [ 29.754102025316456, -1.460479482758621 ], [ 29.754102025316456, -1.459940517241379 ], [ 29.753832531645568, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23657, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.459940517241379 ], [ 29.754371518987341, -1.46021 ], [ 29.754641012658226, -1.46021 ], [ 29.754641012658226, -1.459940517241379 ], [ 29.754371518987341, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23658, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.459940517241379 ], [ 29.754641012658226, -1.46021 ], [ 29.754910506329114, -1.46021 ], [ 29.754910506329114, -1.459940517241379 ], [ 29.754641012658226, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23659, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.459940517241379 ], [ 29.754910506329114, -1.46021 ], [ 29.75518, -1.46021 ], [ 29.75518, -1.459940517241379 ], [ 29.754910506329114, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23660, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.459940517241379 ], [ 29.75518, -1.46021 ], [ 29.755449493670884, -1.46021 ], [ 29.755449493670884, -1.459940517241379 ], [ 29.75518, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23661, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.459940517241379 ], [ 29.755449493670884, -1.46021 ], [ 29.755718987341773, -1.46021 ], [ 29.755718987341773, -1.459940517241379 ], [ 29.755449493670884, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23662, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.459940517241379 ], [ 29.755718987341773, -1.46021 ], [ 29.755988481012658, -1.46021 ], [ 29.755988481012658, -1.459940517241379 ], [ 29.755718987341773, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23663, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.459940517241379 ], [ 29.755988481012658, -1.46021 ], [ 29.756257974683542, -1.46021 ], [ 29.756257974683542, -1.459940517241379 ], [ 29.755988481012658, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23664, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.459940517241379 ], [ 29.756257974683542, -1.46021 ], [ 29.756796962025316, -1.46021 ], [ 29.756796962025316, -1.459940517241379 ], [ 29.756257974683542, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23665, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.459940517241379 ], [ 29.756796962025316, -1.460479482758621 ], [ 29.757066455696201, -1.460479482758621 ], [ 29.757066455696201, -1.459940517241379 ], [ 29.756796962025316, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23666, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.459940517241379 ], [ 29.757066455696201, -1.46021 ], [ 29.757335949367089, -1.46021 ], [ 29.757335949367089, -1.459940517241379 ], [ 29.757066455696201, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23667, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.459940517241379 ], [ 29.757335949367089, -1.46021 ], [ 29.757605443037974, -1.46021 ], [ 29.757605443037974, -1.459940517241379 ], [ 29.757335949367089, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23668, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.459940517241379 ], [ 29.757605443037974, -1.46021 ], [ 29.757874936708859, -1.46021 ], [ 29.757874936708859, -1.459940517241379 ], [ 29.757605443037974, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23669, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.459940517241379 ], [ 29.757874936708859, -1.46021 ], [ 29.758144430379748, -1.46021 ], [ 29.758144430379748, -1.459940517241379 ], [ 29.757874936708859, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23670, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.459940517241379 ], [ 29.758144430379748, -1.46021 ], [ 29.758413924050632, -1.46021 ], [ 29.758413924050632, -1.459940517241379 ], [ 29.758144430379748, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23671, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.459940517241379 ], [ 29.758413924050632, -1.46021 ], [ 29.758683417721517, -1.46021 ], [ 29.758683417721517, -1.459940517241379 ], [ 29.758413924050632, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23672, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.459940517241379 ], [ 29.758683417721517, -1.46021 ], [ 29.758952911392406, -1.46021 ], [ 29.758952911392406, -1.459940517241379 ], [ 29.758683417721517, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23673, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.459940517241379 ], [ 29.759491898734176, -1.46021 ], [ 29.759761392405064, -1.46021 ], [ 29.759761392405064, -1.459940517241379 ], [ 29.759491898734176, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23674, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.459940517241379 ], [ 29.759761392405064, -1.46021 ], [ 29.760030886075949, -1.46021 ], [ 29.760030886075949, -1.459940517241379 ], [ 29.759761392405064, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23675, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.459940517241379 ], [ 29.760030886075949, -1.46021 ], [ 29.760300379746834, -1.46021 ], [ 29.760300379746834, -1.459940517241379 ], [ 29.760030886075949, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23676, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.459940517241379 ], [ 29.760300379746834, -1.46021 ], [ 29.760569873417722, -1.46021 ], [ 29.760569873417722, -1.459940517241379 ], [ 29.760300379746834, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23677, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.459940517241379 ], [ 29.760569873417722, -1.46021 ], [ 29.760839367088607, -1.46021 ], [ 29.760839367088607, -1.459940517241379 ], [ 29.760569873417722, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23678, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.459940517241379 ], [ 29.760839367088607, -1.46021 ], [ 29.761108860759492, -1.46021 ], [ 29.761108860759492, -1.459940517241379 ], [ 29.760839367088607, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23679, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.459940517241379 ], [ 29.761108860759492, -1.46021 ], [ 29.761647848101266, -1.46021 ], [ 29.761647848101266, -1.459940517241379 ], [ 29.761108860759492, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23680, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.459940517241379 ], [ 29.761647848101266, -1.46021 ], [ 29.761917341772151, -1.46021 ], [ 29.761917341772151, -1.459940517241379 ], [ 29.761647848101266, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23681, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.459940517241379 ], [ 29.761917341772151, -1.46021 ], [ 29.762456329113924, -1.46021 ], [ 29.762456329113924, -1.459940517241379 ], [ 29.761917341772151, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23682, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.459940517241379 ], [ 29.762456329113924, -1.46021 ], [ 29.763534303797467, -1.46021 ], [ 29.763534303797467, -1.459940517241379 ], [ 29.762456329113924, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23683, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.459940517241379 ], [ 29.763534303797467, -1.46021 ], [ 29.763803797468356, -1.46021 ], [ 29.763803797468356, -1.459940517241379 ], [ 29.763534303797467, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23684, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.459940517241379 ], [ 29.763803797468356, -1.46021 ], [ 29.76407329113924, -1.46021 ], [ 29.76407329113924, -1.459940517241379 ], [ 29.763803797468356, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23685, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.459940517241379 ], [ 29.76407329113924, -1.46021 ], [ 29.764342784810125, -1.46021 ], [ 29.764342784810125, -1.459940517241379 ], [ 29.76407329113924, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23686, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.459940517241379 ], [ 29.764342784810125, -1.46021 ], [ 29.764612278481014, -1.46021 ], [ 29.764612278481014, -1.459940517241379 ], [ 29.764342784810125, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23687, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.459940517241379 ], [ 29.764612278481014, -1.46021 ], [ 29.764881772151899, -1.46021 ], [ 29.764881772151899, -1.459940517241379 ], [ 29.764612278481014, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23688, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.459940517241379 ], [ 29.764881772151899, -1.46021 ], [ 29.765151265822784, -1.46021 ], [ 29.765151265822784, -1.459940517241379 ], [ 29.764881772151899, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23689, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.459940517241379 ], [ 29.765151265822784, -1.46021 ], [ 29.765420759493672, -1.46021 ], [ 29.765420759493672, -1.459940517241379 ], [ 29.765151265822784, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23690, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.459940517241379 ], [ 29.765420759493672, -1.46021 ], [ 29.765959746835442, -1.46021 ], [ 29.765959746835442, -1.459940517241379 ], [ 29.765420759493672, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23691, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.459940517241379 ], [ 29.765959746835442, -1.46021 ], [ 29.766229240506327, -1.46021 ], [ 29.766229240506327, -1.459940517241379 ], [ 29.765959746835442, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23692, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.459940517241379 ], [ 29.766229240506327, -1.46021 ], [ 29.766498734177215, -1.46021 ], [ 29.766498734177215, -1.459940517241379 ], [ 29.766229240506327, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23693, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.459940517241379 ], [ 29.766498734177215, -1.46021 ], [ 29.7667682278481, -1.46021 ], [ 29.7667682278481, -1.459940517241379 ], [ 29.766498734177215, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23694, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.459940517241379 ], [ 29.7667682278481, -1.46021 ], [ 29.767037721518985, -1.46021 ], [ 29.767037721518985, -1.459940517241379 ], [ 29.7667682278481, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23695, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.459940517241379 ], [ 29.767037721518985, -1.46021 ], [ 29.767307215189874, -1.46021 ], [ 29.767307215189874, -1.459940517241379 ], [ 29.767037721518985, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23696, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.459940517241379 ], [ 29.767307215189874, -1.46021 ], [ 29.767576708860759, -1.46021 ], [ 29.767576708860759, -1.459940517241379 ], [ 29.767307215189874, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23697, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.459940517241379 ], [ 29.767576708860759, -1.46021 ], [ 29.767846202531643, -1.46021 ], [ 29.767846202531643, -1.459940517241379 ], [ 29.767576708860759, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23698, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.459940517241379 ], [ 29.767846202531643, -1.46021 ], [ 29.768115696202532, -1.46021 ], [ 29.768115696202532, -1.459940517241379 ], [ 29.767846202531643, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23699, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.459940517241379 ], [ 29.768115696202532, -1.46021 ], [ 29.768385189873417, -1.46021 ], [ 29.768385189873417, -1.459940517241379 ], [ 29.768115696202532, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23700, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.459940517241379 ], [ 29.768385189873417, -1.46021 ], [ 29.768654683544302, -1.46021 ], [ 29.768654683544302, -1.459940517241379 ], [ 29.768385189873417, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23701, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.459940517241379 ], [ 29.768654683544302, -1.46021 ], [ 29.769193670886075, -1.46021 ], [ 29.769193670886075, -1.459940517241379 ], [ 29.768654683544302, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23702, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.459940517241379 ], [ 29.769193670886075, -1.46021 ], [ 29.76946316455696, -1.46021 ], [ 29.76946316455696, -1.459940517241379 ], [ 29.769193670886075, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23703, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.459940517241379 ], [ 29.76946316455696, -1.46021 ], [ 29.770002151898733, -1.46021 ], [ 29.770002151898733, -1.459940517241379 ], [ 29.76946316455696, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23704, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.459940517241379 ], [ 29.770002151898733, -1.46021 ], [ 29.770271645569618, -1.46021 ], [ 29.770271645569618, -1.459940517241379 ], [ 29.770002151898733, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23705, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.459940517241379 ], [ 29.770271645569618, -1.46021 ], [ 29.770541139240507, -1.46021 ], [ 29.770541139240507, -1.459940517241379 ], [ 29.770271645569618, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23706, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.459940517241379 ], [ 29.770541139240507, -1.46021 ], [ 29.770810632911392, -1.46021 ], [ 29.770810632911392, -1.459940517241379 ], [ 29.770541139240507, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23707, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.459940517241379 ], [ 29.770810632911392, -1.46021 ], [ 29.771080126582277, -1.46021 ], [ 29.771080126582277, -1.459940517241379 ], [ 29.770810632911392, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23708, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.459940517241379 ], [ 29.771080126582277, -1.46021 ], [ 29.771349620253165, -1.46021 ], [ 29.771349620253165, -1.459940517241379 ], [ 29.771080126582277, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23709, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.459940517241379 ], [ 29.771349620253165, -1.46021 ], [ 29.77161911392405, -1.46021 ], [ 29.77161911392405, -1.459940517241379 ], [ 29.771349620253165, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23710, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.459940517241379 ], [ 29.77161911392405, -1.46021 ], [ 29.771888607594935, -1.46021 ], [ 29.771888607594935, -1.459940517241379 ], [ 29.77161911392405, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23711, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.459940517241379 ], [ 29.771888607594935, -1.46021 ], [ 29.772158101265823, -1.46021 ], [ 29.772158101265823, -1.459940517241379 ], [ 29.771888607594935, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23712, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.459940517241379 ], [ 29.772158101265823, -1.46021 ], [ 29.772427594936708, -1.46021 ], [ 29.772427594936708, -1.459940517241379 ], [ 29.772158101265823, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23713, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.459940517241379 ], [ 29.772427594936708, -1.46021 ], [ 29.772697088607593, -1.46021 ], [ 29.772697088607593, -1.459940517241379 ], [ 29.772427594936708, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23714, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.459940517241379 ], [ 29.772697088607593, -1.46021 ], [ 29.772966582278482, -1.46021 ], [ 29.772966582278482, -1.459940517241379 ], [ 29.772697088607593, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23715, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.459940517241379 ], [ 29.772966582278482, -1.46021 ], [ 29.773236075949367, -1.46021 ], [ 29.773236075949367, -1.459940517241379 ], [ 29.772966582278482, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23716, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.459940517241379 ], [ 29.773236075949367, -1.46021 ], [ 29.773505569620252, -1.46021 ], [ 29.773505569620252, -1.459940517241379 ], [ 29.773236075949367, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23717, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.459940517241379 ], [ 29.773505569620252, -1.46021 ], [ 29.77377506329114, -1.46021 ], [ 29.77377506329114, -1.459940517241379 ], [ 29.773505569620252, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23718, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.459940517241379 ], [ 29.77377506329114, -1.46021 ], [ 29.774044556962025, -1.46021 ], [ 29.774044556962025, -1.459940517241379 ], [ 29.77377506329114, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23719, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.459940517241379 ], [ 29.774044556962025, -1.46021 ], [ 29.77431405063291, -1.46021 ], [ 29.77431405063291, -1.459940517241379 ], [ 29.774044556962025, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23720, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.459940517241379 ], [ 29.77431405063291, -1.46021 ], [ 29.774853037974683, -1.46021 ], [ 29.774853037974683, -1.459940517241379 ], [ 29.77431405063291, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23721, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.459940517241379 ], [ 29.774853037974683, -1.46021 ], [ 29.775122531645568, -1.46021 ], [ 29.775122531645568, -1.459940517241379 ], [ 29.774853037974683, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23722, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.459940517241379 ], [ 29.775122531645568, -1.46021 ], [ 29.775392025316457, -1.46021 ], [ 29.775392025316457, -1.459940517241379 ], [ 29.775122531645568, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23723, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.459940517241379 ], [ 29.775392025316457, -1.46021 ], [ 29.775661518987341, -1.46021 ], [ 29.775661518987341, -1.459940517241379 ], [ 29.775392025316457, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23724, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.459940517241379 ], [ 29.775661518987341, -1.46021 ], [ 29.775931012658226, -1.46021 ], [ 29.775931012658226, -1.459940517241379 ], [ 29.775661518987341, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23725, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.459940517241379 ], [ 29.775931012658226, -1.46021 ], [ 29.776200506329115, -1.46021 ], [ 29.776200506329115, -1.459940517241379 ], [ 29.775931012658226, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23726, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.459940517241379 ], [ 29.776200506329115, -1.46021 ], [ 29.77647, -1.46021 ], [ 29.77647, -1.459940517241379 ], [ 29.776200506329115, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23727, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.459940517241379 ], [ 29.77647, -1.46021 ], [ 29.776739493670885, -1.46021 ], [ 29.776739493670885, -1.459940517241379 ], [ 29.77647, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23728, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.459940517241379 ], [ 29.776739493670885, -1.46021 ], [ 29.777008987341773, -1.46021 ], [ 29.777008987341773, -1.459940517241379 ], [ 29.776739493670885, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23729, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.459940517241379 ], [ 29.777008987341773, -1.46021 ], [ 29.777278481012658, -1.46021 ], [ 29.777278481012658, -1.459940517241379 ], [ 29.777008987341773, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23730, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.459940517241379 ], [ 29.777278481012658, -1.46021 ], [ 29.777547974683543, -1.46021 ], [ 29.777547974683543, -1.459940517241379 ], [ 29.777278481012658, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23731, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.459940517241379 ], [ 29.777547974683543, -1.46021 ], [ 29.777817468354431, -1.46021 ], [ 29.777817468354431, -1.459940517241379 ], [ 29.777547974683543, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23732, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.459940517241379 ], [ 29.777817468354431, -1.46021 ], [ 29.778086962025316, -1.46021 ], [ 29.778086962025316, -1.459940517241379 ], [ 29.777817468354431, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23733, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.459940517241379 ], [ 29.778086962025316, -1.46021 ], [ 29.778356455696201, -1.46021 ], [ 29.778356455696201, -1.459940517241379 ], [ 29.778086962025316, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23734, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.459940517241379 ], [ 29.778356455696201, -1.46021 ], [ 29.778895443037975, -1.46021 ], [ 29.778895443037975, -1.459940517241379 ], [ 29.778356455696201, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23735, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.459940517241379 ], [ 29.778895443037975, -1.46021 ], [ 29.77916493670886, -1.46021 ], [ 29.77916493670886, -1.459940517241379 ], [ 29.778895443037975, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23736, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.459940517241379 ], [ 29.77916493670886, -1.46021 ], [ 29.779434430379748, -1.46021 ], [ 29.779434430379748, -1.459940517241379 ], [ 29.77916493670886, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23737, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.459940517241379 ], [ 29.779434430379748, -1.46021 ], [ 29.779703924050633, -1.46021 ], [ 29.779703924050633, -1.459940517241379 ], [ 29.779434430379748, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23738, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.459940517241379 ], [ 29.779703924050633, -1.46021 ], [ 29.779973417721518, -1.46021 ], [ 29.779973417721518, -1.459940517241379 ], [ 29.779703924050633, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23739, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.459940517241379 ], [ 29.779973417721518, -1.46021 ], [ 29.780242911392406, -1.46021 ], [ 29.780242911392406, -1.459940517241379 ], [ 29.779973417721518, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23740, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.459940517241379 ], [ 29.780242911392406, -1.46021 ], [ 29.780512405063291, -1.46021 ], [ 29.780512405063291, -1.459940517241379 ], [ 29.780242911392406, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23741, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.459940517241379 ], [ 29.780512405063291, -1.46021 ], [ 29.780781898734176, -1.46021 ], [ 29.780781898734176, -1.459940517241379 ], [ 29.780512405063291, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23742, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.459940517241379 ], [ 29.780781898734176, -1.46021 ], [ 29.781051392405065, -1.46021 ], [ 29.781051392405065, -1.459940517241379 ], [ 29.780781898734176, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23743, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.459940517241379 ], [ 29.781051392405065, -1.46021 ], [ 29.78132088607595, -1.46021 ], [ 29.78132088607595, -1.459940517241379 ], [ 29.781051392405065, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23744, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.459940517241379 ], [ 29.78132088607595, -1.46021 ], [ 29.781590379746834, -1.46021 ], [ 29.781590379746834, -1.459940517241379 ], [ 29.78132088607595, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23745, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.459940517241379 ], [ 29.781590379746834, -1.46021 ], [ 29.781859873417723, -1.46021 ], [ 29.781859873417723, -1.459940517241379 ], [ 29.781590379746834, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23746, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.459940517241379 ], [ 29.781859873417723, -1.46021 ], [ 29.782129367088608, -1.46021 ], [ 29.782129367088608, -1.459940517241379 ], [ 29.781859873417723, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23747, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.459940517241379 ], [ 29.782129367088608, -1.46021 ], [ 29.782398860759493, -1.46021 ], [ 29.782398860759493, -1.459940517241379 ], [ 29.782129367088608, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23748, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.459940517241379 ], [ 29.782398860759493, -1.46021 ], [ 29.782668354430381, -1.46021 ], [ 29.782668354430381, -1.459940517241379 ], [ 29.782398860759493, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23749, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.459940517241379 ], [ 29.782668354430381, -1.46021 ], [ 29.782937848101266, -1.46021 ], [ 29.782937848101266, -1.459940517241379 ], [ 29.782668354430381, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23750, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.459940517241379 ], [ 29.782937848101266, -1.46021 ], [ 29.783207341772151, -1.46021 ], [ 29.783207341772151, -1.459940517241379 ], [ 29.782937848101266, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23751, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.459940517241379 ], [ 29.783207341772151, -1.46021 ], [ 29.783476835443039, -1.46021 ], [ 29.783476835443039, -1.459940517241379 ], [ 29.783207341772151, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23752, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.459940517241379 ], [ 29.783476835443039, -1.46021 ], [ 29.784015822784809, -1.46021 ], [ 29.784015822784809, -1.459940517241379 ], [ 29.783476835443039, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23753, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.459940517241379 ], [ 29.784015822784809, -1.46021 ], [ 29.784285316455698, -1.46021 ], [ 29.784285316455698, -1.459940517241379 ], [ 29.784015822784809, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23754, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.459940517241379 ], [ 29.784285316455698, -1.46021 ], [ 29.784554810126583, -1.46021 ], [ 29.784554810126583, -1.459940517241379 ], [ 29.784285316455698, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23755, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.459940517241379 ], [ 29.784554810126583, -1.46021 ], [ 29.784824303797468, -1.46021 ], [ 29.784824303797468, -1.459940517241379 ], [ 29.784554810126583, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23756, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.459940517241379 ], [ 29.784824303797468, -1.46021 ], [ 29.785093797468356, -1.46021 ], [ 29.785093797468356, -1.459940517241379 ], [ 29.784824303797468, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23757, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.459940517241379 ], [ 29.785093797468356, -1.46021 ], [ 29.785363291139241, -1.46021 ], [ 29.785363291139241, -1.459940517241379 ], [ 29.785093797468356, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23758, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.459940517241379 ], [ 29.785363291139241, -1.46021 ], [ 29.785632784810126, -1.46021 ], [ 29.785632784810126, -1.459940517241379 ], [ 29.785363291139241, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23759, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.459940517241379 ], [ 29.793448101265824, -1.46021 ], [ 29.793717594936709, -1.46021 ], [ 29.793717594936709, -1.459940517241379 ], [ 29.793448101265824, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23760, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.459940517241379 ], [ 29.793717594936709, -1.46021 ], [ 29.793987088607594, -1.46021 ], [ 29.793987088607594, -1.459940517241379 ], [ 29.793717594936709, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23761, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.459940517241379 ], [ 29.793987088607594, -1.46021 ], [ 29.794256582278482, -1.46021 ], [ 29.794256582278482, -1.459940517241379 ], [ 29.793987088607594, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23762, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.459940517241379 ], [ 29.794256582278482, -1.46021 ], [ 29.794526075949367, -1.46021 ], [ 29.794526075949367, -1.459940517241379 ], [ 29.794256582278482, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23763, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.459940517241379 ], [ 29.794526075949367, -1.46021 ], [ 29.794795569620252, -1.46021 ], [ 29.794795569620252, -1.459940517241379 ], [ 29.794526075949367, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23764, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.459940517241379 ], [ 29.794795569620252, -1.46021 ], [ 29.79506506329114, -1.46021 ], [ 29.79506506329114, -1.459940517241379 ], [ 29.794795569620252, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23765, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.459940517241379 ], [ 29.79506506329114, -1.46021 ], [ 29.795334556962025, -1.46021 ], [ 29.795334556962025, -1.459940517241379 ], [ 29.79506506329114, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23766, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.459940517241379 ], [ 29.795334556962025, -1.460479482758621 ], [ 29.79560405063291, -1.460479482758621 ], [ 29.79560405063291, -1.459940517241379 ], [ 29.795334556962025, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23767, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.459940517241379 ], [ 29.79560405063291, -1.46021 ], [ 29.795873544303799, -1.46021 ], [ 29.795873544303799, -1.459940517241379 ], [ 29.79560405063291, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23768, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.459940517241379 ], [ 29.795873544303799, -1.46021 ], [ 29.796143037974684, -1.46021 ], [ 29.796143037974684, -1.459940517241379 ], [ 29.795873544303799, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23769, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.459940517241379 ], [ 29.796682025316457, -1.460479482758621 ], [ 29.796951518987342, -1.460479482758621 ], [ 29.796951518987342, -1.459940517241379 ], [ 29.796682025316457, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23770, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.459940517241379 ], [ 29.797490506329115, -1.46021 ], [ 29.79776, -1.46021 ], [ 29.79776, -1.459940517241379 ], [ 29.797490506329115, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23771, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.459940517241379 ], [ 29.79776, -1.46021 ], [ 29.798029493670885, -1.46021 ], [ 29.798029493670885, -1.459940517241379 ], [ 29.79776, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23772, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.459940517241379 ], [ 29.798029493670885, -1.46021 ], [ 29.798298987341774, -1.46021 ], [ 29.798298987341774, -1.459940517241379 ], [ 29.798029493670885, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23773, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.459940517241379 ], [ 29.798298987341774, -1.46021 ], [ 29.798568481012659, -1.46021 ], [ 29.798568481012659, -1.459940517241379 ], [ 29.798298987341774, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23774, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.459940517241379 ], [ 29.798568481012659, -1.46021 ], [ 29.798837974683543, -1.46021 ], [ 29.798837974683543, -1.459940517241379 ], [ 29.798568481012659, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23775, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.459940517241379 ], [ 29.798837974683543, -1.460479482758621 ], [ 29.799107468354432, -1.460479482758621 ], [ 29.799107468354432, -1.459940517241379 ], [ 29.798837974683543, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23776, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.459940517241379 ], [ 29.799107468354432, -1.460479482758621 ], [ 29.799376962025317, -1.460479482758621 ], [ 29.799376962025317, -1.459940517241379 ], [ 29.799107468354432, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23777, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.459940517241379 ], [ 29.813390632911393, -1.46021 ], [ 29.813660126582278, -1.46021 ], [ 29.813660126582278, -1.459940517241379 ], [ 29.813390632911393, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23778, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.459940517241379 ], [ 29.813660126582278, -1.46021 ], [ 29.813929620253166, -1.46021 ], [ 29.813929620253166, -1.459940517241379 ], [ 29.813660126582278, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23779, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813929620253166, -1.459940517241379 ], [ 29.813929620253166, -1.46021 ], [ 29.814199113924051, -1.46021 ], [ 29.814199113924051, -1.459940517241379 ], [ 29.813929620253166, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23780, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.814199113924051, -1.459940517241379 ], [ 29.814199113924051, -1.460479482758621 ], [ 29.814468607594936, -1.460479482758621 ], [ 29.814468607594936, -1.459940517241379 ], [ 29.814199113924051, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23781, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.752754556962024, -1.46021 ], [ 29.752754556962024, -1.461018448275862 ], [ 29.753024050632909, -1.461018448275862 ], [ 29.753024050632909, -1.46021 ], [ 29.752754556962024, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23782, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.46021 ], [ 29.753024050632909, -1.460748965517241 ], [ 29.753293544303798, -1.460748965517241 ], [ 29.753293544303798, -1.46021 ], [ 29.753024050632909, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23783, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.46021 ], [ 29.753293544303798, -1.460748965517241 ], [ 29.753563037974683, -1.460748965517241 ], [ 29.753563037974683, -1.46021 ], [ 29.753293544303798, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23784, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.46021 ], [ 29.754102025316456, -1.460479482758621 ], [ 29.754371518987341, -1.460479482758621 ], [ 29.754371518987341, -1.46021 ], [ 29.754102025316456, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23785, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.46021 ], [ 29.754371518987341, -1.460479482758621 ], [ 29.754641012658226, -1.460479482758621 ], [ 29.754641012658226, -1.46021 ], [ 29.754371518987341, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23786, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.46021 ], [ 29.754641012658226, -1.460479482758621 ], [ 29.754910506329114, -1.460479482758621 ], [ 29.754910506329114, -1.46021 ], [ 29.754641012658226, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23787, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.46021 ], [ 29.754910506329114, -1.460479482758621 ], [ 29.75518, -1.460479482758621 ], [ 29.75518, -1.46021 ], [ 29.754910506329114, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23788, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.46021 ], [ 29.75518, -1.460479482758621 ], [ 29.755449493670884, -1.460479482758621 ], [ 29.755449493670884, -1.46021 ], [ 29.75518, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23789, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.46021 ], [ 29.755449493670884, -1.460748965517241 ], [ 29.755718987341773, -1.460748965517241 ], [ 29.755718987341773, -1.46021 ], [ 29.755449493670884, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23790, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.46021 ], [ 29.755718987341773, -1.460479482758621 ], [ 29.755988481012658, -1.460479482758621 ], [ 29.755988481012658, -1.46021 ], [ 29.755718987341773, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23791, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.46021 ], [ 29.755988481012658, -1.460479482758621 ], [ 29.756257974683542, -1.460479482758621 ], [ 29.756257974683542, -1.46021 ], [ 29.755988481012658, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23792, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.46021 ], [ 29.756257974683542, -1.460479482758621 ], [ 29.756796962025316, -1.460479482758621 ], [ 29.756796962025316, -1.46021 ], [ 29.756257974683542, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23793, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.46021 ], [ 29.757066455696201, -1.460479482758621 ], [ 29.757335949367089, -1.460479482758621 ], [ 29.757335949367089, -1.46021 ], [ 29.757066455696201, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23794, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.46021 ], [ 29.757335949367089, -1.460479482758621 ], [ 29.757605443037974, -1.460479482758621 ], [ 29.757605443037974, -1.46021 ], [ 29.757335949367089, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23795, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.46021 ], [ 29.757605443037974, -1.460479482758621 ], [ 29.757874936708859, -1.460479482758621 ], [ 29.757874936708859, -1.46021 ], [ 29.757605443037974, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23796, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.46021 ], [ 29.757874936708859, -1.460479482758621 ], [ 29.758144430379748, -1.460479482758621 ], [ 29.758144430379748, -1.46021 ], [ 29.757874936708859, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23797, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.46021 ], [ 29.758144430379748, -1.460748965517241 ], [ 29.758413924050632, -1.460748965517241 ], [ 29.758413924050632, -1.46021 ], [ 29.758144430379748, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23798, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.46021 ], [ 29.758413924050632, -1.460479482758621 ], [ 29.758683417721517, -1.460479482758621 ], [ 29.758683417721517, -1.46021 ], [ 29.758413924050632, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23799, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.46021 ], [ 29.758683417721517, -1.460479482758621 ], [ 29.758952911392406, -1.460479482758621 ], [ 29.758952911392406, -1.46021 ], [ 29.758683417721517, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23800, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.46021 ], [ 29.758952911392406, -1.460479482758621 ], [ 29.759222405063291, -1.460479482758621 ], [ 29.759222405063291, -1.46021 ], [ 29.758952911392406, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23801, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.46021 ], [ 29.759222405063291, -1.460479482758621 ], [ 29.759491898734176, -1.460479482758621 ], [ 29.759491898734176, -1.46021 ], [ 29.759222405063291, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23802, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.46021 ], [ 29.759491898734176, -1.460479482758621 ], [ 29.759761392405064, -1.460479482758621 ], [ 29.759761392405064, -1.46021 ], [ 29.759491898734176, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23803, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.46021 ], [ 29.759761392405064, -1.460479482758621 ], [ 29.760030886075949, -1.460479482758621 ], [ 29.760030886075949, -1.46021 ], [ 29.759761392405064, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23804, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.46021 ], [ 29.760030886075949, -1.460479482758621 ], [ 29.760300379746834, -1.460479482758621 ], [ 29.760300379746834, -1.46021 ], [ 29.760030886075949, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23805, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.46021 ], [ 29.760300379746834, -1.460479482758621 ], [ 29.760569873417722, -1.460479482758621 ], [ 29.760569873417722, -1.46021 ], [ 29.760300379746834, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23806, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.46021 ], [ 29.760569873417722, -1.460479482758621 ], [ 29.760839367088607, -1.460479482758621 ], [ 29.760839367088607, -1.46021 ], [ 29.760569873417722, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23807, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.46021 ], [ 29.760839367088607, -1.460479482758621 ], [ 29.761108860759492, -1.460479482758621 ], [ 29.761108860759492, -1.46021 ], [ 29.760839367088607, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23808, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.46021 ], [ 29.761108860759492, -1.460479482758621 ], [ 29.761378354430381, -1.460479482758621 ], [ 29.761378354430381, -1.46021 ], [ 29.761108860759492, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23809, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.46021 ], [ 29.761378354430381, -1.460479482758621 ], [ 29.761647848101266, -1.460479482758621 ], [ 29.761647848101266, -1.46021 ], [ 29.761378354430381, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23810, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.46021 ], [ 29.761647848101266, -1.460479482758621 ], [ 29.761917341772151, -1.460479482758621 ], [ 29.761917341772151, -1.46021 ], [ 29.761647848101266, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23811, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.46021 ], [ 29.761917341772151, -1.460479482758621 ], [ 29.762725822784809, -1.460479482758621 ], [ 29.762725822784809, -1.460748965517241 ], [ 29.762995316455697, -1.460748965517241 ], [ 29.762995316455697, -1.460479482758621 ], [ 29.763264810126582, -1.460479482758621 ], [ 29.763264810126582, -1.46021 ], [ 29.761917341772151, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23812, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.46021 ], [ 29.763264810126582, -1.460479482758621 ], [ 29.763534303797467, -1.460479482758621 ], [ 29.763534303797467, -1.46021 ], [ 29.763264810126582, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23813, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.46021 ], [ 29.763534303797467, -1.460479482758621 ], [ 29.763803797468356, -1.460479482758621 ], [ 29.763803797468356, -1.46021 ], [ 29.763534303797467, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23814, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.46021 ], [ 29.763803797468356, -1.460479482758621 ], [ 29.76407329113924, -1.460479482758621 ], [ 29.76407329113924, -1.46021 ], [ 29.763803797468356, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23815, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.46021 ], [ 29.76407329113924, -1.460479482758621 ], [ 29.764342784810125, -1.460479482758621 ], [ 29.764342784810125, -1.46021 ], [ 29.76407329113924, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23816, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.46021 ], [ 29.764342784810125, -1.460479482758621 ], [ 29.764612278481014, -1.460479482758621 ], [ 29.764612278481014, -1.46021 ], [ 29.764342784810125, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23817, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.46021 ], [ 29.764612278481014, -1.460479482758621 ], [ 29.764881772151899, -1.460479482758621 ], [ 29.764881772151899, -1.46021 ], [ 29.764612278481014, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23818, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.46021 ], [ 29.764881772151899, -1.460479482758621 ], [ 29.765151265822784, -1.460479482758621 ], [ 29.765151265822784, -1.46021 ], [ 29.764881772151899, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23819, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.46021 ], [ 29.765151265822784, -1.460479482758621 ], [ 29.765420759493672, -1.460479482758621 ], [ 29.765420759493672, -1.46021 ], [ 29.765151265822784, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23820, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.46021 ], [ 29.765420759493672, -1.460479482758621 ], [ 29.765959746835442, -1.460479482758621 ], [ 29.765959746835442, -1.46021 ], [ 29.765420759493672, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23821, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.46021 ], [ 29.765959746835442, -1.460479482758621 ], [ 29.766229240506327, -1.460479482758621 ], [ 29.766229240506327, -1.46021 ], [ 29.765959746835442, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23822, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.46021 ], [ 29.766229240506327, -1.460479482758621 ], [ 29.766498734177215, -1.460479482758621 ], [ 29.766498734177215, -1.46021 ], [ 29.766229240506327, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23823, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.46021 ], [ 29.766498734177215, -1.460479482758621 ], [ 29.7667682278481, -1.460479482758621 ], [ 29.7667682278481, -1.46021 ], [ 29.766498734177215, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23824, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.46021 ], [ 29.7667682278481, -1.460479482758621 ], [ 29.767037721518985, -1.460479482758621 ], [ 29.767037721518985, -1.46021 ], [ 29.7667682278481, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23825, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.46021 ], [ 29.767037721518985, -1.460479482758621 ], [ 29.767307215189874, -1.460479482758621 ], [ 29.767307215189874, -1.46021 ], [ 29.767037721518985, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23826, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.46021 ], [ 29.767307215189874, -1.460479482758621 ], [ 29.767576708860759, -1.460479482758621 ], [ 29.767576708860759, -1.46021 ], [ 29.767307215189874, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23827, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.46021 ], [ 29.767576708860759, -1.460479482758621 ], [ 29.767846202531643, -1.460479482758621 ], [ 29.767846202531643, -1.46021 ], [ 29.767576708860759, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23828, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.46021 ], [ 29.767846202531643, -1.460479482758621 ], [ 29.768115696202532, -1.460479482758621 ], [ 29.768115696202532, -1.46021 ], [ 29.767846202531643, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23829, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.46021 ], [ 29.768115696202532, -1.460479482758621 ], [ 29.768385189873417, -1.460479482758621 ], [ 29.768385189873417, -1.46021 ], [ 29.768115696202532, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23830, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.46021 ], [ 29.768385189873417, -1.460479482758621 ], [ 29.76892417721519, -1.460479482758621 ], [ 29.76892417721519, -1.46021 ], [ 29.768385189873417, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23831, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.46021 ], [ 29.76892417721519, -1.460479482758621 ], [ 29.769732658227849, -1.460479482758621 ], [ 29.769732658227849, -1.46021 ], [ 29.76892417721519, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23832, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.46021 ], [ 29.769732658227849, -1.460479482758621 ], [ 29.770271645569618, -1.460479482758621 ], [ 29.770271645569618, -1.46021 ], [ 29.769732658227849, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23833, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.46021 ], [ 29.770271645569618, -1.460479482758621 ], [ 29.770541139240507, -1.460479482758621 ], [ 29.770541139240507, -1.46021 ], [ 29.770271645569618, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23834, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.46021 ], [ 29.770541139240507, -1.460479482758621 ], [ 29.770810632911392, -1.460479482758621 ], [ 29.770810632911392, -1.46021 ], [ 29.770541139240507, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23835, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.46021 ], [ 29.770810632911392, -1.460479482758621 ], [ 29.771080126582277, -1.460479482758621 ], [ 29.771080126582277, -1.46021 ], [ 29.770810632911392, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23836, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.46021 ], [ 29.771080126582277, -1.460479482758621 ], [ 29.771349620253165, -1.460479482758621 ], [ 29.771349620253165, -1.46021 ], [ 29.771080126582277, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23837, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.46021 ], [ 29.771349620253165, -1.460479482758621 ], [ 29.77161911392405, -1.460479482758621 ], [ 29.77161911392405, -1.46021 ], [ 29.771349620253165, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23838, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.46021 ], [ 29.77161911392405, -1.460479482758621 ], [ 29.771888607594935, -1.460479482758621 ], [ 29.771888607594935, -1.46021 ], [ 29.77161911392405, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23839, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.46021 ], [ 29.771888607594935, -1.460479482758621 ], [ 29.772158101265823, -1.460479482758621 ], [ 29.772158101265823, -1.46021 ], [ 29.771888607594935, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23840, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.46021 ], [ 29.772158101265823, -1.460479482758621 ], [ 29.772427594936708, -1.460479482758621 ], [ 29.772427594936708, -1.46021 ], [ 29.772158101265823, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23841, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.46021 ], [ 29.772427594936708, -1.460479482758621 ], [ 29.772697088607593, -1.460479482758621 ], [ 29.772697088607593, -1.46021 ], [ 29.772427594936708, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23842, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.46021 ], [ 29.772697088607593, -1.460479482758621 ], [ 29.772966582278482, -1.460479482758621 ], [ 29.772966582278482, -1.46021 ], [ 29.772697088607593, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23843, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.46021 ], [ 29.772966582278482, -1.460479482758621 ], [ 29.773236075949367, -1.460479482758621 ], [ 29.773236075949367, -1.46021 ], [ 29.772966582278482, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23844, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.46021 ], [ 29.773236075949367, -1.460479482758621 ], [ 29.773505569620252, -1.460479482758621 ], [ 29.773505569620252, -1.46021 ], [ 29.773236075949367, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23845, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.46021 ], [ 29.773505569620252, -1.460479482758621 ], [ 29.77377506329114, -1.460479482758621 ], [ 29.77377506329114, -1.46021 ], [ 29.773505569620252, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23846, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.46021 ], [ 29.77377506329114, -1.460479482758621 ], [ 29.774044556962025, -1.460479482758621 ], [ 29.774044556962025, -1.46021 ], [ 29.77377506329114, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23847, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.46021 ], [ 29.774044556962025, -1.460479482758621 ], [ 29.77431405063291, -1.460479482758621 ], [ 29.77431405063291, -1.46021 ], [ 29.774044556962025, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23848, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.46021 ], [ 29.77431405063291, -1.460479482758621 ], [ 29.774853037974683, -1.460479482758621 ], [ 29.774853037974683, -1.46021 ], [ 29.77431405063291, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23849, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.46021 ], [ 29.774853037974683, -1.460479482758621 ], [ 29.775122531645568, -1.460479482758621 ], [ 29.775122531645568, -1.46021 ], [ 29.774853037974683, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23850, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.46021 ], [ 29.775122531645568, -1.460479482758621 ], [ 29.775392025316457, -1.460479482758621 ], [ 29.775392025316457, -1.46021 ], [ 29.775122531645568, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23851, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.46021 ], [ 29.775392025316457, -1.460479482758621 ], [ 29.775661518987341, -1.460479482758621 ], [ 29.775661518987341, -1.46021 ], [ 29.775392025316457, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23852, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.46021 ], [ 29.775661518987341, -1.460479482758621 ], [ 29.775931012658226, -1.460479482758621 ], [ 29.775931012658226, -1.46021 ], [ 29.775661518987341, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23853, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.46021 ], [ 29.775931012658226, -1.460479482758621 ], [ 29.776200506329115, -1.460479482758621 ], [ 29.776200506329115, -1.46021 ], [ 29.775931012658226, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23854, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.46021 ], [ 29.776200506329115, -1.460479482758621 ], [ 29.77647, -1.460479482758621 ], [ 29.77647, -1.46021 ], [ 29.776200506329115, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23855, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.46021 ], [ 29.77647, -1.460479482758621 ], [ 29.776739493670885, -1.460479482758621 ], [ 29.776739493670885, -1.46021 ], [ 29.77647, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23856, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.46021 ], [ 29.776739493670885, -1.460479482758621 ], [ 29.777008987341773, -1.460479482758621 ], [ 29.777008987341773, -1.46021 ], [ 29.776739493670885, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23857, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.46021 ], [ 29.777008987341773, -1.460479482758621 ], [ 29.777278481012658, -1.460479482758621 ], [ 29.777278481012658, -1.46021 ], [ 29.777008987341773, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23858, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.46021 ], [ 29.777278481012658, -1.460479482758621 ], [ 29.777547974683543, -1.460479482758621 ], [ 29.777547974683543, -1.46021 ], [ 29.777278481012658, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23859, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.46021 ], [ 29.777547974683543, -1.460479482758621 ], [ 29.777817468354431, -1.460479482758621 ], [ 29.777817468354431, -1.46021 ], [ 29.777547974683543, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23860, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.46021 ], [ 29.777817468354431, -1.460479482758621 ], [ 29.778086962025316, -1.460479482758621 ], [ 29.778086962025316, -1.46021 ], [ 29.777817468354431, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23861, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.46021 ], [ 29.778086962025316, -1.460479482758621 ], [ 29.778356455696201, -1.460479482758621 ], [ 29.778356455696201, -1.46021 ], [ 29.778086962025316, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23862, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.46021 ], [ 29.778356455696201, -1.460479482758621 ], [ 29.778895443037975, -1.460479482758621 ], [ 29.778895443037975, -1.46021 ], [ 29.778356455696201, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23863, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.46021 ], [ 29.778895443037975, -1.460479482758621 ], [ 29.77916493670886, -1.460479482758621 ], [ 29.77916493670886, -1.46021 ], [ 29.778895443037975, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23864, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.46021 ], [ 29.77916493670886, -1.460479482758621 ], [ 29.779434430379748, -1.460479482758621 ], [ 29.779434430379748, -1.46021 ], [ 29.77916493670886, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23865, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.46021 ], [ 29.779434430379748, -1.460748965517241 ], [ 29.779703924050633, -1.460748965517241 ], [ 29.779703924050633, -1.46021 ], [ 29.779434430379748, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23866, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.46021 ], [ 29.779703924050633, -1.460479482758621 ], [ 29.779973417721518, -1.460479482758621 ], [ 29.779973417721518, -1.46021 ], [ 29.779703924050633, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23867, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.46021 ], [ 29.779973417721518, -1.460479482758621 ], [ 29.780242911392406, -1.460479482758621 ], [ 29.780242911392406, -1.46021 ], [ 29.779973417721518, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23868, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.46021 ], [ 29.780242911392406, -1.460479482758621 ], [ 29.780512405063291, -1.460479482758621 ], [ 29.780512405063291, -1.46021 ], [ 29.780242911392406, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23869, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.46021 ], [ 29.780512405063291, -1.460479482758621 ], [ 29.780781898734176, -1.460479482758621 ], [ 29.780781898734176, -1.46021 ], [ 29.780512405063291, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23870, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.46021 ], [ 29.780781898734176, -1.460479482758621 ], [ 29.781051392405065, -1.460479482758621 ], [ 29.781051392405065, -1.46021 ], [ 29.780781898734176, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23871, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.46021 ], [ 29.781051392405065, -1.460479482758621 ], [ 29.78132088607595, -1.460479482758621 ], [ 29.78132088607595, -1.46021 ], [ 29.781051392405065, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23872, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.46021 ], [ 29.78132088607595, -1.460479482758621 ], [ 29.781590379746834, -1.460479482758621 ], [ 29.781590379746834, -1.46021 ], [ 29.78132088607595, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23873, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.46021 ], [ 29.781590379746834, -1.460479482758621 ], [ 29.781859873417723, -1.460479482758621 ], [ 29.781859873417723, -1.46021 ], [ 29.781590379746834, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23874, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.46021 ], [ 29.781859873417723, -1.460479482758621 ], [ 29.782129367088608, -1.460479482758621 ], [ 29.782129367088608, -1.46021 ], [ 29.781859873417723, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23875, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.46021 ], [ 29.782129367088608, -1.460479482758621 ], [ 29.782398860759493, -1.460479482758621 ], [ 29.782398860759493, -1.46021 ], [ 29.782129367088608, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23876, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.46021 ], [ 29.782398860759493, -1.460479482758621 ], [ 29.782668354430381, -1.460479482758621 ], [ 29.782668354430381, -1.46021 ], [ 29.782398860759493, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23877, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.46021 ], [ 29.782668354430381, -1.460479482758621 ], [ 29.782937848101266, -1.460479482758621 ], [ 29.782937848101266, -1.46021 ], [ 29.782668354430381, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23878, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.46021 ], [ 29.782937848101266, -1.460479482758621 ], [ 29.783207341772151, -1.460479482758621 ], [ 29.783207341772151, -1.46021 ], [ 29.782937848101266, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23879, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.46021 ], [ 29.783207341772151, -1.460479482758621 ], [ 29.783476835443039, -1.460479482758621 ], [ 29.783476835443039, -1.46021 ], [ 29.783207341772151, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23880, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.46021 ], [ 29.783476835443039, -1.460479482758621 ], [ 29.784015822784809, -1.460479482758621 ], [ 29.784015822784809, -1.46021 ], [ 29.783476835443039, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23881, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.46021 ], [ 29.784015822784809, -1.460479482758621 ], [ 29.784285316455698, -1.460479482758621 ], [ 29.784285316455698, -1.46021 ], [ 29.784015822784809, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23882, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.46021 ], [ 29.784285316455698, -1.460479482758621 ], [ 29.784554810126583, -1.460479482758621 ], [ 29.784554810126583, -1.46021 ], [ 29.784285316455698, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23883, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.46021 ], [ 29.784554810126583, -1.460479482758621 ], [ 29.784824303797468, -1.460479482758621 ], [ 29.784824303797468, -1.46021 ], [ 29.784554810126583, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23884, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.46021 ], [ 29.784824303797468, -1.460479482758621 ], [ 29.785093797468356, -1.460479482758621 ], [ 29.785093797468356, -1.46021 ], [ 29.784824303797468, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23885, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.46021 ], [ 29.785093797468356, -1.460479482758621 ], [ 29.785363291139241, -1.460479482758621 ], [ 29.785363291139241, -1.46021 ], [ 29.785093797468356, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23886, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.46021 ], [ 29.785363291139241, -1.460479482758621 ], [ 29.785632784810126, -1.460479482758621 ], [ 29.785632784810126, -1.46021 ], [ 29.785363291139241, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23887, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.46021 ], [ 29.785632784810126, -1.460479482758621 ], [ 29.785902278481014, -1.460479482758621 ], [ 29.785902278481014, -1.46021 ], [ 29.785632784810126, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23888, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.46021 ], [ 29.785902278481014, -1.460479482758621 ], [ 29.786171772151899, -1.460479482758621 ], [ 29.786171772151899, -1.46021 ], [ 29.785902278481014, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23889, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.46021 ], [ 29.786171772151899, -1.460479482758621 ], [ 29.786441265822784, -1.460479482758621 ], [ 29.786441265822784, -1.46021 ], [ 29.786171772151899, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23890, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.46021 ], [ 29.786441265822784, -1.460479482758621 ], [ 29.786710759493673, -1.460479482758621 ], [ 29.786710759493673, -1.46021 ], [ 29.786441265822784, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23891, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.46021 ], [ 29.786710759493673, -1.460479482758621 ], [ 29.786980253164558, -1.460479482758621 ], [ 29.786980253164558, -1.46021 ], [ 29.786710759493673, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23892, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.46021 ], [ 29.793717594936709, -1.460479482758621 ], [ 29.793987088607594, -1.460479482758621 ], [ 29.793987088607594, -1.46021 ], [ 29.793717594936709, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23893, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.46021 ], [ 29.793987088607594, -1.460479482758621 ], [ 29.794256582278482, -1.460479482758621 ], [ 29.794256582278482, -1.46021 ], [ 29.793987088607594, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23894, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.46021 ], [ 29.794256582278482, -1.460479482758621 ], [ 29.794526075949367, -1.460479482758621 ], [ 29.794526075949367, -1.46021 ], [ 29.794256582278482, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23895, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.46021 ], [ 29.794526075949367, -1.460479482758621 ], [ 29.794795569620252, -1.460479482758621 ], [ 29.794795569620252, -1.46021 ], [ 29.794526075949367, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23896, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.46021 ], [ 29.79776, -1.460748965517241 ], [ 29.798029493670885, -1.460748965517241 ], [ 29.798029493670885, -1.46021 ], [ 29.79776, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23897, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.46021 ], [ 29.798029493670885, -1.460748965517241 ], [ 29.798298987341774, -1.460748965517241 ], [ 29.798298987341774, -1.46021 ], [ 29.798029493670885, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23898, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.46021 ], [ 29.798298987341774, -1.460479482758621 ], [ 29.798568481012659, -1.460479482758621 ], [ 29.798568481012659, -1.46021 ], [ 29.798298987341774, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23899, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.46021 ], [ 29.798568481012659, -1.461018448275862 ], [ 29.798837974683543, -1.461018448275862 ], [ 29.798837974683543, -1.46021 ], [ 29.798568481012659, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23900, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.46021 ], [ 29.799376962025317, -1.460748965517241 ], [ 29.799646455696202, -1.460748965517241 ], [ 29.799646455696202, -1.46021 ], [ 29.799376962025317, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23901, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.46021 ], [ 29.800185443037975, -1.460479482758621 ], [ 29.80045493670886, -1.460479482758621 ], [ 29.80045493670886, -1.46021 ], [ 29.800185443037975, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23902, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.46021 ], [ 29.80045493670886, -1.460479482758621 ], [ 29.800724430379748, -1.460479482758621 ], [ 29.800724430379748, -1.46021 ], [ 29.80045493670886, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23903, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.46021 ], [ 29.800724430379748, -1.460479482758621 ], [ 29.800993924050633, -1.460479482758621 ], [ 29.800993924050633, -1.46021 ], [ 29.800724430379748, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23904, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813390632911393, -1.46021 ], [ 29.813390632911393, -1.460479482758621 ], [ 29.813660126582278, -1.460479482758621 ], [ 29.813660126582278, -1.46021 ], [ 29.813390632911393, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23905, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.46021 ], [ 29.813660126582278, -1.460479482758621 ], [ 29.814199113924051, -1.460479482758621 ], [ 29.814199113924051, -1.46021 ], [ 29.813660126582278, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23906, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.460479482758621 ], [ 29.753563037974683, -1.460748965517241 ], [ 29.753832531645568, -1.460748965517241 ], [ 29.753832531645568, -1.460479482758621 ], [ 29.753563037974683, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23907, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.460479482758621 ], [ 29.753832531645568, -1.460748965517241 ], [ 29.754102025316456, -1.460748965517241 ], [ 29.754102025316456, -1.460479482758621 ], [ 29.753832531645568, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23908, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.460479482758621 ], [ 29.754102025316456, -1.460748965517241 ], [ 29.754371518987341, -1.460748965517241 ], [ 29.754371518987341, -1.460479482758621 ], [ 29.754102025316456, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23909, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.460479482758621 ], [ 29.754371518987341, -1.461018448275862 ], [ 29.754641012658226, -1.461018448275862 ], [ 29.754641012658226, -1.460479482758621 ], [ 29.754371518987341, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23910, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.460479482758621 ], [ 29.754641012658226, -1.460748965517241 ], [ 29.754910506329114, -1.460748965517241 ], [ 29.754910506329114, -1.460479482758621 ], [ 29.754641012658226, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23911, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.460479482758621 ], [ 29.754910506329114, -1.460748965517241 ], [ 29.75518, -1.460748965517241 ], [ 29.75518, -1.460479482758621 ], [ 29.754910506329114, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23912, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.460479482758621 ], [ 29.75518, -1.460748965517241 ], [ 29.755449493670884, -1.460748965517241 ], [ 29.755449493670884, -1.460479482758621 ], [ 29.75518, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23913, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.460479482758621 ], [ 29.755718987341773, -1.460748965517241 ], [ 29.755988481012658, -1.460748965517241 ], [ 29.755988481012658, -1.460479482758621 ], [ 29.755718987341773, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23914, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.460479482758621 ], [ 29.755988481012658, -1.460748965517241 ], [ 29.756257974683542, -1.460748965517241 ], [ 29.756257974683542, -1.460479482758621 ], [ 29.755988481012658, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23915, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.460479482758621 ], [ 29.756257974683542, -1.460748965517241 ], [ 29.756796962025316, -1.460748965517241 ], [ 29.756796962025316, -1.460479482758621 ], [ 29.756257974683542, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23916, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.460479482758621 ], [ 29.756796962025316, -1.460748965517241 ], [ 29.757066455696201, -1.460748965517241 ], [ 29.757066455696201, -1.460479482758621 ], [ 29.756796962025316, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23917, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.460479482758621 ], [ 29.757066455696201, -1.460748965517241 ], [ 29.757335949367089, -1.460748965517241 ], [ 29.757335949367089, -1.460479482758621 ], [ 29.757066455696201, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23918, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.460479482758621 ], [ 29.757335949367089, -1.460748965517241 ], [ 29.757605443037974, -1.460748965517241 ], [ 29.757605443037974, -1.460479482758621 ], [ 29.757335949367089, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23919, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.460479482758621 ], [ 29.757605443037974, -1.460748965517241 ], [ 29.757874936708859, -1.460748965517241 ], [ 29.757874936708859, -1.460479482758621 ], [ 29.757605443037974, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23920, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.460479482758621 ], [ 29.757874936708859, -1.460748965517241 ], [ 29.758144430379748, -1.460748965517241 ], [ 29.758144430379748, -1.460479482758621 ], [ 29.757874936708859, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23921, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.460479482758621 ], [ 29.758413924050632, -1.460748965517241 ], [ 29.758683417721517, -1.460748965517241 ], [ 29.758683417721517, -1.460479482758621 ], [ 29.758413924050632, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23922, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.460479482758621 ], [ 29.758683417721517, -1.460748965517241 ], [ 29.758952911392406, -1.460748965517241 ], [ 29.758952911392406, -1.460479482758621 ], [ 29.758683417721517, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23923, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.460479482758621 ], [ 29.758952911392406, -1.461018448275862 ], [ 29.759222405063291, -1.461018448275862 ], [ 29.759222405063291, -1.460479482758621 ], [ 29.758952911392406, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23924, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.460479482758621 ], [ 29.759222405063291, -1.460748965517241 ], [ 29.759491898734176, -1.460748965517241 ], [ 29.759491898734176, -1.460479482758621 ], [ 29.759222405063291, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23925, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.460479482758621 ], [ 29.759491898734176, -1.461018448275862 ], [ 29.759761392405064, -1.461018448275862 ], [ 29.759761392405064, -1.460479482758621 ], [ 29.759491898734176, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23926, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.460479482758621 ], [ 29.759761392405064, -1.460748965517241 ], [ 29.760030886075949, -1.460748965517241 ], [ 29.760030886075949, -1.460479482758621 ], [ 29.759761392405064, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23927, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.460479482758621 ], [ 29.760030886075949, -1.460748965517241 ], [ 29.760300379746834, -1.460748965517241 ], [ 29.760300379746834, -1.460479482758621 ], [ 29.760030886075949, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23928, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.460479482758621 ], [ 29.760300379746834, -1.460748965517241 ], [ 29.760569873417722, -1.460748965517241 ], [ 29.760569873417722, -1.460479482758621 ], [ 29.760300379746834, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23929, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.460479482758621 ], [ 29.760569873417722, -1.460748965517241 ], [ 29.760839367088607, -1.460748965517241 ], [ 29.760839367088607, -1.460479482758621 ], [ 29.760569873417722, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23930, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.460479482758621 ], [ 29.760839367088607, -1.460748965517241 ], [ 29.761108860759492, -1.460748965517241 ], [ 29.761108860759492, -1.460479482758621 ], [ 29.760839367088607, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23931, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.460479482758621 ], [ 29.761108860759492, -1.460748965517241 ], [ 29.761378354430381, -1.460748965517241 ], [ 29.761378354430381, -1.460479482758621 ], [ 29.761108860759492, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23932, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.460479482758621 ], [ 29.761378354430381, -1.460748965517241 ], [ 29.761647848101266, -1.460748965517241 ], [ 29.761647848101266, -1.460479482758621 ], [ 29.761378354430381, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23933, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.460479482758621 ], [ 29.761647848101266, -1.460748965517241 ], [ 29.762186835443039, -1.460748965517241 ], [ 29.762186835443039, -1.460479482758621 ], [ 29.761647848101266, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23934, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.460479482758621 ], [ 29.762186835443039, -1.460748965517241 ], [ 29.762725822784809, -1.460748965517241 ], [ 29.762725822784809, -1.460479482758621 ], [ 29.762186835443039, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23935, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.460479482758621 ], [ 29.762995316455697, -1.460748965517241 ], [ 29.763264810126582, -1.460748965517241 ], [ 29.763264810126582, -1.460479482758621 ], [ 29.762995316455697, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23936, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.460479482758621 ], [ 29.763264810126582, -1.460748965517241 ], [ 29.763534303797467, -1.460748965517241 ], [ 29.763534303797467, -1.460479482758621 ], [ 29.763264810126582, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23937, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.460479482758621 ], [ 29.763534303797467, -1.460748965517241 ], [ 29.763803797468356, -1.460748965517241 ], [ 29.763803797468356, -1.460479482758621 ], [ 29.763534303797467, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23938, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.460479482758621 ], [ 29.763803797468356, -1.460748965517241 ], [ 29.76407329113924, -1.460748965517241 ], [ 29.76407329113924, -1.460479482758621 ], [ 29.763803797468356, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23939, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.460479482758621 ], [ 29.76407329113924, -1.460748965517241 ], [ 29.764342784810125, -1.460748965517241 ], [ 29.764342784810125, -1.460479482758621 ], [ 29.76407329113924, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23940, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.460479482758621 ], [ 29.764342784810125, -1.460748965517241 ], [ 29.764612278481014, -1.460748965517241 ], [ 29.764612278481014, -1.460479482758621 ], [ 29.764342784810125, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23941, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.460479482758621 ], [ 29.764612278481014, -1.460748965517241 ], [ 29.764881772151899, -1.460748965517241 ], [ 29.764881772151899, -1.460479482758621 ], [ 29.764612278481014, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23942, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.460479482758621 ], [ 29.764881772151899, -1.460748965517241 ], [ 29.765151265822784, -1.460748965517241 ], [ 29.765151265822784, -1.460479482758621 ], [ 29.764881772151899, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23943, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.460479482758621 ], [ 29.765151265822784, -1.460748965517241 ], [ 29.765420759493672, -1.460748965517241 ], [ 29.765420759493672, -1.460479482758621 ], [ 29.765151265822784, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23944, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.460479482758621 ], [ 29.765420759493672, -1.460748965517241 ], [ 29.765959746835442, -1.460748965517241 ], [ 29.765959746835442, -1.460479482758621 ], [ 29.765420759493672, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23945, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.460479482758621 ], [ 29.765959746835442, -1.460748965517241 ], [ 29.766229240506327, -1.460748965517241 ], [ 29.766229240506327, -1.460479482758621 ], [ 29.765959746835442, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23946, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.460479482758621 ], [ 29.766229240506327, -1.460748965517241 ], [ 29.766498734177215, -1.460748965517241 ], [ 29.766498734177215, -1.460479482758621 ], [ 29.766229240506327, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23947, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.460479482758621 ], [ 29.766498734177215, -1.460748965517241 ], [ 29.7667682278481, -1.460748965517241 ], [ 29.7667682278481, -1.460479482758621 ], [ 29.766498734177215, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23948, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.460479482758621 ], [ 29.7667682278481, -1.460748965517241 ], [ 29.767037721518985, -1.460748965517241 ], [ 29.767037721518985, -1.460479482758621 ], [ 29.7667682278481, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23949, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.460479482758621 ], [ 29.767037721518985, -1.460748965517241 ], [ 29.767307215189874, -1.460748965517241 ], [ 29.767307215189874, -1.460479482758621 ], [ 29.767037721518985, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23950, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.460479482758621 ], [ 29.767307215189874, -1.460748965517241 ], [ 29.767576708860759, -1.460748965517241 ], [ 29.767576708860759, -1.460479482758621 ], [ 29.767307215189874, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23951, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.460479482758621 ], [ 29.767576708860759, -1.460748965517241 ], [ 29.768115696202532, -1.460748965517241 ], [ 29.768115696202532, -1.460479482758621 ], [ 29.767576708860759, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23952, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.460479482758621 ], [ 29.768115696202532, -1.460748965517241 ], [ 29.768385189873417, -1.460748965517241 ], [ 29.768385189873417, -1.460479482758621 ], [ 29.768115696202532, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23953, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.460479482758621 ], [ 29.768385189873417, -1.460748965517241 ], [ 29.768654683544302, -1.460748965517241 ], [ 29.768654683544302, -1.460479482758621 ], [ 29.768385189873417, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23954, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.460479482758621 ], [ 29.768654683544302, -1.460748965517241 ], [ 29.770002151898733, -1.460748965517241 ], [ 29.770002151898733, -1.460479482758621 ], [ 29.768654683544302, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23955, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.460479482758621 ], [ 29.770002151898733, -1.460748965517241 ], [ 29.770271645569618, -1.460748965517241 ], [ 29.770271645569618, -1.460479482758621 ], [ 29.770002151898733, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23956, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.460479482758621 ], [ 29.770271645569618, -1.460748965517241 ], [ 29.770541139240507, -1.460748965517241 ], [ 29.770541139240507, -1.460479482758621 ], [ 29.770271645569618, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23957, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.460479482758621 ], [ 29.770541139240507, -1.460748965517241 ], [ 29.770810632911392, -1.460748965517241 ], [ 29.770810632911392, -1.460479482758621 ], [ 29.770541139240507, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23958, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.460479482758621 ], [ 29.770810632911392, -1.460748965517241 ], [ 29.771080126582277, -1.460748965517241 ], [ 29.771080126582277, -1.460479482758621 ], [ 29.770810632911392, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23959, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.460479482758621 ], [ 29.771080126582277, -1.460748965517241 ], [ 29.771349620253165, -1.460748965517241 ], [ 29.771349620253165, -1.460479482758621 ], [ 29.771080126582277, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23960, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.460479482758621 ], [ 29.771349620253165, -1.460748965517241 ], [ 29.77161911392405, -1.460748965517241 ], [ 29.77161911392405, -1.460479482758621 ], [ 29.771349620253165, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23961, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.460479482758621 ], [ 29.77161911392405, -1.460748965517241 ], [ 29.771888607594935, -1.460748965517241 ], [ 29.771888607594935, -1.460479482758621 ], [ 29.77161911392405, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23962, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.460479482758621 ], [ 29.771888607594935, -1.460748965517241 ], [ 29.772158101265823, -1.460748965517241 ], [ 29.772158101265823, -1.460479482758621 ], [ 29.771888607594935, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23963, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.460479482758621 ], [ 29.772158101265823, -1.460748965517241 ], [ 29.772427594936708, -1.460748965517241 ], [ 29.772427594936708, -1.460479482758621 ], [ 29.772158101265823, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23964, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.460479482758621 ], [ 29.772427594936708, -1.460748965517241 ], [ 29.772697088607593, -1.460748965517241 ], [ 29.772697088607593, -1.460479482758621 ], [ 29.772427594936708, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23965, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.460479482758621 ], [ 29.772697088607593, -1.460748965517241 ], [ 29.772966582278482, -1.460748965517241 ], [ 29.772966582278482, -1.460479482758621 ], [ 29.772697088607593, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23966, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.460479482758621 ], [ 29.772966582278482, -1.460748965517241 ], [ 29.773236075949367, -1.460748965517241 ], [ 29.773236075949367, -1.460479482758621 ], [ 29.772966582278482, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23967, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.460479482758621 ], [ 29.773236075949367, -1.460748965517241 ], [ 29.773505569620252, -1.460748965517241 ], [ 29.773505569620252, -1.460479482758621 ], [ 29.773236075949367, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23968, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.460479482758621 ], [ 29.773505569620252, -1.460748965517241 ], [ 29.77377506329114, -1.460748965517241 ], [ 29.77377506329114, -1.460479482758621 ], [ 29.773505569620252, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23969, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.460479482758621 ], [ 29.77377506329114, -1.460748965517241 ], [ 29.774044556962025, -1.460748965517241 ], [ 29.774044556962025, -1.460479482758621 ], [ 29.77377506329114, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23970, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.460479482758621 ], [ 29.774044556962025, -1.460748965517241 ], [ 29.77431405063291, -1.460748965517241 ], [ 29.77431405063291, -1.460479482758621 ], [ 29.774044556962025, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23971, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.460479482758621 ], [ 29.77431405063291, -1.460748965517241 ], [ 29.774853037974683, -1.460748965517241 ], [ 29.774853037974683, -1.460479482758621 ], [ 29.77431405063291, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23972, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.460479482758621 ], [ 29.774853037974683, -1.460748965517241 ], [ 29.775122531645568, -1.460748965517241 ], [ 29.775122531645568, -1.460479482758621 ], [ 29.774853037974683, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23973, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.460479482758621 ], [ 29.775122531645568, -1.460748965517241 ], [ 29.775392025316457, -1.460748965517241 ], [ 29.775392025316457, -1.460479482758621 ], [ 29.775122531645568, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23974, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.460479482758621 ], [ 29.775392025316457, -1.460748965517241 ], [ 29.775661518987341, -1.460748965517241 ], [ 29.775661518987341, -1.460479482758621 ], [ 29.775392025316457, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23975, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.460479482758621 ], [ 29.775661518987341, -1.460748965517241 ], [ 29.775931012658226, -1.460748965517241 ], [ 29.775931012658226, -1.460479482758621 ], [ 29.775661518987341, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23976, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.460479482758621 ], [ 29.775931012658226, -1.460748965517241 ], [ 29.776200506329115, -1.460748965517241 ], [ 29.776200506329115, -1.460479482758621 ], [ 29.775931012658226, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23977, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.460479482758621 ], [ 29.776200506329115, -1.460748965517241 ], [ 29.77647, -1.460748965517241 ], [ 29.77647, -1.460479482758621 ], [ 29.776200506329115, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23978, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.460479482758621 ], [ 29.77647, -1.460748965517241 ], [ 29.776739493670885, -1.460748965517241 ], [ 29.776739493670885, -1.460479482758621 ], [ 29.77647, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23979, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.460479482758621 ], [ 29.776739493670885, -1.460748965517241 ], [ 29.777008987341773, -1.460748965517241 ], [ 29.777008987341773, -1.460479482758621 ], [ 29.776739493670885, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23980, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.460479482758621 ], [ 29.777008987341773, -1.460748965517241 ], [ 29.777278481012658, -1.460748965517241 ], [ 29.777278481012658, -1.460479482758621 ], [ 29.777008987341773, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23981, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.460479482758621 ], [ 29.777278481012658, -1.460748965517241 ], [ 29.777547974683543, -1.460748965517241 ], [ 29.777547974683543, -1.460479482758621 ], [ 29.777278481012658, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23982, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.460479482758621 ], [ 29.777547974683543, -1.460748965517241 ], [ 29.777817468354431, -1.460748965517241 ], [ 29.777817468354431, -1.460479482758621 ], [ 29.777547974683543, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23983, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.460479482758621 ], [ 29.777817468354431, -1.460748965517241 ], [ 29.778086962025316, -1.460748965517241 ], [ 29.778086962025316, -1.460479482758621 ], [ 29.777817468354431, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23984, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.460479482758621 ], [ 29.778086962025316, -1.460748965517241 ], [ 29.778356455696201, -1.460748965517241 ], [ 29.778356455696201, -1.460479482758621 ], [ 29.778086962025316, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23985, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.460479482758621 ], [ 29.778356455696201, -1.460748965517241 ], [ 29.77862594936709, -1.460748965517241 ], [ 29.77862594936709, -1.461018448275862 ], [ 29.778895443037975, -1.461018448275862 ], [ 29.778895443037975, -1.460479482758621 ], [ 29.778356455696201, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23986, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.460479482758621 ], [ 29.778895443037975, -1.460748965517241 ], [ 29.77916493670886, -1.460748965517241 ], [ 29.77916493670886, -1.460479482758621 ], [ 29.778895443037975, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23987, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.460479482758621 ], [ 29.77916493670886, -1.460748965517241 ], [ 29.779434430379748, -1.460748965517241 ], [ 29.779434430379748, -1.460479482758621 ], [ 29.77916493670886, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23988, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.460479482758621 ], [ 29.779703924050633, -1.460748965517241 ], [ 29.779973417721518, -1.460748965517241 ], [ 29.779973417721518, -1.460479482758621 ], [ 29.779703924050633, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23989, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.460479482758621 ], [ 29.779973417721518, -1.460748965517241 ], [ 29.780242911392406, -1.460748965517241 ], [ 29.780242911392406, -1.460479482758621 ], [ 29.779973417721518, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23990, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.460479482758621 ], [ 29.780242911392406, -1.460748965517241 ], [ 29.780512405063291, -1.460748965517241 ], [ 29.780512405063291, -1.460479482758621 ], [ 29.780242911392406, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23991, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.460479482758621 ], [ 29.780512405063291, -1.461018448275862 ], [ 29.780781898734176, -1.461018448275862 ], [ 29.780781898734176, -1.460479482758621 ], [ 29.780512405063291, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23992, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.460479482758621 ], [ 29.780781898734176, -1.460748965517241 ], [ 29.781051392405065, -1.460748965517241 ], [ 29.781051392405065, -1.460479482758621 ], [ 29.780781898734176, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23993, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.460479482758621 ], [ 29.781051392405065, -1.460748965517241 ], [ 29.78132088607595, -1.460748965517241 ], [ 29.78132088607595, -1.460479482758621 ], [ 29.781051392405065, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23994, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.460479482758621 ], [ 29.78132088607595, -1.460748965517241 ], [ 29.781590379746834, -1.460748965517241 ], [ 29.781590379746834, -1.460479482758621 ], [ 29.78132088607595, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23995, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.460479482758621 ], [ 29.781590379746834, -1.460748965517241 ], [ 29.781859873417723, -1.460748965517241 ], [ 29.781859873417723, -1.460479482758621 ], [ 29.781590379746834, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23996, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.460479482758621 ], [ 29.781859873417723, -1.460748965517241 ], [ 29.782129367088608, -1.460748965517241 ], [ 29.782129367088608, -1.460479482758621 ], [ 29.781859873417723, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23997, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.460479482758621 ], [ 29.782129367088608, -1.460748965517241 ], [ 29.782398860759493, -1.460748965517241 ], [ 29.782398860759493, -1.460479482758621 ], [ 29.782129367088608, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23998, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.460479482758621 ], [ 29.782398860759493, -1.460748965517241 ], [ 29.782668354430381, -1.460748965517241 ], [ 29.782668354430381, -1.460479482758621 ], [ 29.782398860759493, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 23999, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.460479482758621 ], [ 29.782668354430381, -1.460748965517241 ], [ 29.782937848101266, -1.460748965517241 ], [ 29.782937848101266, -1.460479482758621 ], [ 29.782668354430381, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24000, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.460479482758621 ], [ 29.782937848101266, -1.460748965517241 ], [ 29.783207341772151, -1.460748965517241 ], [ 29.783207341772151, -1.460479482758621 ], [ 29.782937848101266, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24001, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.460479482758621 ], [ 29.783207341772151, -1.460748965517241 ], [ 29.783476835443039, -1.460748965517241 ], [ 29.783476835443039, -1.460479482758621 ], [ 29.783207341772151, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24002, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.460479482758621 ], [ 29.783476835443039, -1.460748965517241 ], [ 29.784015822784809, -1.460748965517241 ], [ 29.784015822784809, -1.460479482758621 ], [ 29.783476835443039, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24003, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.460479482758621 ], [ 29.784015822784809, -1.460748965517241 ], [ 29.784285316455698, -1.460748965517241 ], [ 29.784285316455698, -1.460479482758621 ], [ 29.784015822784809, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24004, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.460479482758621 ], [ 29.784285316455698, -1.460748965517241 ], [ 29.784554810126583, -1.460748965517241 ], [ 29.784554810126583, -1.460479482758621 ], [ 29.784285316455698, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24005, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.460479482758621 ], [ 29.784554810126583, -1.460748965517241 ], [ 29.784824303797468, -1.460748965517241 ], [ 29.784824303797468, -1.460479482758621 ], [ 29.784554810126583, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24006, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.460479482758621 ], [ 29.784824303797468, -1.460748965517241 ], [ 29.785093797468356, -1.460748965517241 ], [ 29.785093797468356, -1.460479482758621 ], [ 29.784824303797468, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24007, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.460479482758621 ], [ 29.785093797468356, -1.460748965517241 ], [ 29.785363291139241, -1.460748965517241 ], [ 29.785363291139241, -1.460479482758621 ], [ 29.785093797468356, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24008, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.460479482758621 ], [ 29.785363291139241, -1.460748965517241 ], [ 29.785632784810126, -1.460748965517241 ], [ 29.785632784810126, -1.460479482758621 ], [ 29.785363291139241, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24009, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.460479482758621 ], [ 29.785632784810126, -1.460748965517241 ], [ 29.785902278481014, -1.460748965517241 ], [ 29.785902278481014, -1.460479482758621 ], [ 29.785632784810126, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24010, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.460479482758621 ], [ 29.785902278481014, -1.460748965517241 ], [ 29.786171772151899, -1.460748965517241 ], [ 29.786171772151899, -1.460479482758621 ], [ 29.785902278481014, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24011, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.460479482758621 ], [ 29.786171772151899, -1.460748965517241 ], [ 29.786441265822784, -1.460748965517241 ], [ 29.786441265822784, -1.460479482758621 ], [ 29.786171772151899, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24012, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.460479482758621 ], [ 29.786441265822784, -1.460748965517241 ], [ 29.786710759493673, -1.460748965517241 ], [ 29.786710759493673, -1.460479482758621 ], [ 29.786441265822784, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24013, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.460479482758621 ], [ 29.786710759493673, -1.460748965517241 ], [ 29.786980253164558, -1.460748965517241 ], [ 29.786980253164558, -1.460479482758621 ], [ 29.786710759493673, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24014, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.460479482758621 ], [ 29.786980253164558, -1.460748965517241 ], [ 29.787249746835442, -1.460748965517241 ], [ 29.787249746835442, -1.460479482758621 ], [ 29.786980253164558, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24015, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.460479482758621 ], [ 29.787249746835442, -1.460748965517241 ], [ 29.787519240506327, -1.460748965517241 ], [ 29.787519240506327, -1.460479482758621 ], [ 29.787249746835442, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24016, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.460479482758621 ], [ 29.798298987341774, -1.460748965517241 ], [ 29.798568481012659, -1.460748965517241 ], [ 29.798568481012659, -1.460479482758621 ], [ 29.798298987341774, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24017, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.460479482758621 ], [ 29.798837974683543, -1.460748965517241 ], [ 29.799107468354432, -1.460748965517241 ], [ 29.799107468354432, -1.460479482758621 ], [ 29.798837974683543, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24018, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.460479482758621 ], [ 29.799107468354432, -1.460748965517241 ], [ 29.799376962025317, -1.460748965517241 ], [ 29.799376962025317, -1.460479482758621 ], [ 29.799107468354432, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24019, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.460479482758621 ], [ 29.799646455696202, -1.460748965517241 ], [ 29.79991594936709, -1.460748965517241 ], [ 29.79991594936709, -1.460479482758621 ], [ 29.799646455696202, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24020, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.460479482758621 ], [ 29.79991594936709, -1.460748965517241 ], [ 29.800185443037975, -1.460748965517241 ], [ 29.800185443037975, -1.460479482758621 ], [ 29.79991594936709, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24021, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.460479482758621 ], [ 29.800185443037975, -1.460748965517241 ], [ 29.80045493670886, -1.460748965517241 ], [ 29.80045493670886, -1.460479482758621 ], [ 29.800185443037975, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24022, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.813660126582278, -1.460479482758621 ], [ 29.813660126582278, -1.460748965517241 ], [ 29.814199113924051, -1.460748965517241 ], [ 29.814199113924051, -1.460479482758621 ], [ 29.813660126582278, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24023, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.460748965517241 ], [ 29.753024050632909, -1.461018448275862 ], [ 29.753293544303798, -1.461018448275862 ], [ 29.753293544303798, -1.460748965517241 ], [ 29.753024050632909, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24024, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.460748965517241 ], [ 29.753293544303798, -1.461018448275862 ], [ 29.753563037974683, -1.461018448275862 ], [ 29.753563037974683, -1.460748965517241 ], [ 29.753293544303798, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24025, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.460748965517241 ], [ 29.753563037974683, -1.461018448275862 ], [ 29.753832531645568, -1.461018448275862 ], [ 29.753832531645568, -1.460748965517241 ], [ 29.753563037974683, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24026, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.460748965517241 ], [ 29.754641012658226, -1.461018448275862 ], [ 29.754910506329114, -1.461018448275862 ], [ 29.754910506329114, -1.460748965517241 ], [ 29.754641012658226, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24027, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.460748965517241 ], [ 29.754910506329114, -1.461018448275862 ], [ 29.75518, -1.461018448275862 ], [ 29.75518, -1.460748965517241 ], [ 29.754910506329114, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24028, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.460748965517241 ], [ 29.75518, -1.461018448275862 ], [ 29.755449493670884, -1.461018448275862 ], [ 29.755449493670884, -1.460748965517241 ], [ 29.75518, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24029, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.460748965517241 ], [ 29.755449493670884, -1.461018448275862 ], [ 29.755718987341773, -1.461018448275862 ], [ 29.755718987341773, -1.460748965517241 ], [ 29.755449493670884, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24030, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.460748965517241 ], [ 29.755718987341773, -1.461018448275862 ], [ 29.755988481012658, -1.461018448275862 ], [ 29.755988481012658, -1.460748965517241 ], [ 29.755718987341773, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24031, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.460748965517241 ], [ 29.755988481012658, -1.461018448275862 ], [ 29.756257974683542, -1.461018448275862 ], [ 29.756257974683542, -1.460748965517241 ], [ 29.755988481012658, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24032, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.460748965517241 ], [ 29.756257974683542, -1.461018448275862 ], [ 29.756796962025316, -1.461018448275862 ], [ 29.756796962025316, -1.460748965517241 ], [ 29.756257974683542, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24033, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.460748965517241 ], [ 29.756796962025316, -1.461018448275862 ], [ 29.757066455696201, -1.461018448275862 ], [ 29.757066455696201, -1.460748965517241 ], [ 29.756796962025316, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24034, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.460748965517241 ], [ 29.757335949367089, -1.461018448275862 ], [ 29.757605443037974, -1.461018448275862 ], [ 29.757605443037974, -1.460748965517241 ], [ 29.757335949367089, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24035, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.460748965517241 ], [ 29.757605443037974, -1.461018448275862 ], [ 29.757874936708859, -1.461018448275862 ], [ 29.757874936708859, -1.460748965517241 ], [ 29.757605443037974, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24036, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.460748965517241 ], [ 29.758144430379748, -1.461018448275862 ], [ 29.758413924050632, -1.461018448275862 ], [ 29.758413924050632, -1.460748965517241 ], [ 29.758144430379748, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24037, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.460748965517241 ], [ 29.758413924050632, -1.461018448275862 ], [ 29.758683417721517, -1.461018448275862 ], [ 29.758683417721517, -1.460748965517241 ], [ 29.758413924050632, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24038, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.460748965517241 ], [ 29.759222405063291, -1.461018448275862 ], [ 29.759491898734176, -1.461018448275862 ], [ 29.759491898734176, -1.460748965517241 ], [ 29.759222405063291, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24039, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.460748965517241 ], [ 29.759761392405064, -1.461018448275862 ], [ 29.760030886075949, -1.461018448275862 ], [ 29.760030886075949, -1.460748965517241 ], [ 29.759761392405064, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24040, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.460748965517241 ], [ 29.760030886075949, -1.461018448275862 ], [ 29.760300379746834, -1.461018448275862 ], [ 29.760300379746834, -1.460748965517241 ], [ 29.760030886075949, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24041, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.460748965517241 ], [ 29.760300379746834, -1.461018448275862 ], [ 29.760569873417722, -1.461018448275862 ], [ 29.760569873417722, -1.460748965517241 ], [ 29.760300379746834, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24042, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.460748965517241 ], [ 29.760569873417722, -1.461018448275862 ], [ 29.760839367088607, -1.461018448275862 ], [ 29.760839367088607, -1.460748965517241 ], [ 29.760569873417722, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24043, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.460748965517241 ], [ 29.760839367088607, -1.461018448275862 ], [ 29.761108860759492, -1.461018448275862 ], [ 29.761108860759492, -1.460748965517241 ], [ 29.760839367088607, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24044, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.460748965517241 ], [ 29.761108860759492, -1.461018448275862 ], [ 29.761378354430381, -1.461018448275862 ], [ 29.761378354430381, -1.460748965517241 ], [ 29.761108860759492, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24045, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.460748965517241 ], [ 29.761917341772151, -1.461018448275862 ], [ 29.762186835443039, -1.461018448275862 ], [ 29.762186835443039, -1.460748965517241 ], [ 29.761917341772151, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24046, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.460748965517241 ], [ 29.762186835443039, -1.461018448275862 ], [ 29.762456329113924, -1.461018448275862 ], [ 29.762456329113924, -1.460748965517241 ], [ 29.762186835443039, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24047, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.460748965517241 ], [ 29.762456329113924, -1.461018448275862 ], [ 29.762725822784809, -1.461018448275862 ], [ 29.762725822784809, -1.460748965517241 ], [ 29.762456329113924, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24048, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.460748965517241 ], [ 29.762725822784809, -1.461018448275862 ], [ 29.762995316455697, -1.461018448275862 ], [ 29.762995316455697, -1.460748965517241 ], [ 29.762725822784809, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24049, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.460748965517241 ], [ 29.762995316455697, -1.461018448275862 ], [ 29.763264810126582, -1.461018448275862 ], [ 29.763264810126582, -1.460748965517241 ], [ 29.762995316455697, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24050, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.460748965517241 ], [ 29.763264810126582, -1.461018448275862 ], [ 29.763534303797467, -1.461018448275862 ], [ 29.763534303797467, -1.460748965517241 ], [ 29.763264810126582, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24051, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.460748965517241 ], [ 29.763534303797467, -1.461018448275862 ], [ 29.763803797468356, -1.461018448275862 ], [ 29.763803797468356, -1.460748965517241 ], [ 29.763534303797467, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24052, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.460748965517241 ], [ 29.763803797468356, -1.461018448275862 ], [ 29.76407329113924, -1.461018448275862 ], [ 29.76407329113924, -1.460748965517241 ], [ 29.763803797468356, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24053, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.460748965517241 ], [ 29.76407329113924, -1.461018448275862 ], [ 29.764342784810125, -1.461018448275862 ], [ 29.764342784810125, -1.460748965517241 ], [ 29.76407329113924, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24054, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.460748965517241 ], [ 29.764342784810125, -1.461018448275862 ], [ 29.764612278481014, -1.461018448275862 ], [ 29.764612278481014, -1.460748965517241 ], [ 29.764342784810125, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24055, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.460748965517241 ], [ 29.764612278481014, -1.461018448275862 ], [ 29.764881772151899, -1.461018448275862 ], [ 29.764881772151899, -1.460748965517241 ], [ 29.764612278481014, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24056, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.460748965517241 ], [ 29.764881772151899, -1.461018448275862 ], [ 29.765151265822784, -1.461018448275862 ], [ 29.765151265822784, -1.460748965517241 ], [ 29.764881772151899, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24057, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.460748965517241 ], [ 29.765151265822784, -1.461018448275862 ], [ 29.765420759493672, -1.461018448275862 ], [ 29.765420759493672, -1.460748965517241 ], [ 29.765151265822784, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24058, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.460748965517241 ], [ 29.765420759493672, -1.461018448275862 ], [ 29.765959746835442, -1.461018448275862 ], [ 29.765959746835442, -1.460748965517241 ], [ 29.765420759493672, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24059, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.460748965517241 ], [ 29.765959746835442, -1.461018448275862 ], [ 29.766229240506327, -1.461018448275862 ], [ 29.766229240506327, -1.460748965517241 ], [ 29.765959746835442, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24060, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.460748965517241 ], [ 29.766229240506327, -1.461018448275862 ], [ 29.766498734177215, -1.461018448275862 ], [ 29.766498734177215, -1.460748965517241 ], [ 29.766229240506327, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24061, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.460748965517241 ], [ 29.766498734177215, -1.461018448275862 ], [ 29.7667682278481, -1.461018448275862 ], [ 29.7667682278481, -1.460748965517241 ], [ 29.766498734177215, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24062, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.460748965517241 ], [ 29.7667682278481, -1.461018448275862 ], [ 29.767307215189874, -1.461018448275862 ], [ 29.767307215189874, -1.460748965517241 ], [ 29.7667682278481, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24063, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.460748965517241 ], [ 29.767307215189874, -1.461018448275862 ], [ 29.767576708860759, -1.461018448275862 ], [ 29.767576708860759, -1.460748965517241 ], [ 29.767307215189874, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24064, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.460748965517241 ], [ 29.767576708860759, -1.461018448275862 ], [ 29.767846202531643, -1.461018448275862 ], [ 29.767846202531643, -1.460748965517241 ], [ 29.767576708860759, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24065, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.460748965517241 ], [ 29.767846202531643, -1.461018448275862 ], [ 29.768115696202532, -1.461018448275862 ], [ 29.768115696202532, -1.460748965517241 ], [ 29.767846202531643, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24066, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.460748965517241 ], [ 29.768115696202532, -1.461018448275862 ], [ 29.768385189873417, -1.461018448275862 ], [ 29.768385189873417, -1.460748965517241 ], [ 29.768115696202532, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24067, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.460748965517241 ], [ 29.768385189873417, -1.461018448275862 ], [ 29.768654683544302, -1.461018448275862 ], [ 29.768654683544302, -1.460748965517241 ], [ 29.768385189873417, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24068, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.460748965517241 ], [ 29.768654683544302, -1.461018448275862 ], [ 29.76892417721519, -1.461018448275862 ], [ 29.76892417721519, -1.460748965517241 ], [ 29.768654683544302, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24069, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.460748965517241 ], [ 29.76892417721519, -1.461018448275862 ], [ 29.769732658227849, -1.461018448275862 ], [ 29.769732658227849, -1.460748965517241 ], [ 29.76892417721519, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24070, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.460748965517241 ], [ 29.769732658227849, -1.461018448275862 ], [ 29.770002151898733, -1.461018448275862 ], [ 29.770002151898733, -1.460748965517241 ], [ 29.769732658227849, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24071, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.460748965517241 ], [ 29.770002151898733, -1.461018448275862 ], [ 29.770271645569618, -1.461018448275862 ], [ 29.770271645569618, -1.460748965517241 ], [ 29.770002151898733, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24072, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.460748965517241 ], [ 29.770271645569618, -1.461018448275862 ], [ 29.770541139240507, -1.461018448275862 ], [ 29.770541139240507, -1.460748965517241 ], [ 29.770271645569618, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24073, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.460748965517241 ], [ 29.770541139240507, -1.461018448275862 ], [ 29.770810632911392, -1.461018448275862 ], [ 29.770810632911392, -1.460748965517241 ], [ 29.770541139240507, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24074, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.460748965517241 ], [ 29.770810632911392, -1.461018448275862 ], [ 29.771080126582277, -1.461018448275862 ], [ 29.771080126582277, -1.460748965517241 ], [ 29.770810632911392, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24075, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.460748965517241 ], [ 29.771080126582277, -1.461018448275862 ], [ 29.771349620253165, -1.461018448275862 ], [ 29.771349620253165, -1.460748965517241 ], [ 29.771080126582277, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24076, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.460748965517241 ], [ 29.771349620253165, -1.461018448275862 ], [ 29.77161911392405, -1.461018448275862 ], [ 29.77161911392405, -1.460748965517241 ], [ 29.771349620253165, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24077, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.460748965517241 ], [ 29.77161911392405, -1.461018448275862 ], [ 29.771888607594935, -1.461018448275862 ], [ 29.771888607594935, -1.460748965517241 ], [ 29.77161911392405, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24078, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.460748965517241 ], [ 29.771888607594935, -1.461018448275862 ], [ 29.772158101265823, -1.461018448275862 ], [ 29.772158101265823, -1.460748965517241 ], [ 29.771888607594935, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24079, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.460748965517241 ], [ 29.772158101265823, -1.461018448275862 ], [ 29.772427594936708, -1.461018448275862 ], [ 29.772427594936708, -1.460748965517241 ], [ 29.772158101265823, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24080, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.460748965517241 ], [ 29.772427594936708, -1.461018448275862 ], [ 29.772697088607593, -1.461018448275862 ], [ 29.772697088607593, -1.460748965517241 ], [ 29.772427594936708, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24081, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.460748965517241 ], [ 29.772697088607593, -1.461018448275862 ], [ 29.772966582278482, -1.461018448275862 ], [ 29.772966582278482, -1.460748965517241 ], [ 29.772697088607593, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24082, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.460748965517241 ], [ 29.772966582278482, -1.461018448275862 ], [ 29.773236075949367, -1.461018448275862 ], [ 29.773236075949367, -1.460748965517241 ], [ 29.772966582278482, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24083, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.460748965517241 ], [ 29.773236075949367, -1.461018448275862 ], [ 29.773505569620252, -1.461018448275862 ], [ 29.773505569620252, -1.460748965517241 ], [ 29.773236075949367, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24084, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.460748965517241 ], [ 29.773505569620252, -1.461018448275862 ], [ 29.77377506329114, -1.461018448275862 ], [ 29.77377506329114, -1.460748965517241 ], [ 29.773505569620252, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24085, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.460748965517241 ], [ 29.77377506329114, -1.461018448275862 ], [ 29.774044556962025, -1.461018448275862 ], [ 29.774044556962025, -1.460748965517241 ], [ 29.77377506329114, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24086, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.460748965517241 ], [ 29.774044556962025, -1.461018448275862 ], [ 29.77431405063291, -1.461018448275862 ], [ 29.77431405063291, -1.460748965517241 ], [ 29.774044556962025, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24087, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.460748965517241 ], [ 29.77431405063291, -1.461018448275862 ], [ 29.774853037974683, -1.461018448275862 ], [ 29.774853037974683, -1.460748965517241 ], [ 29.77431405063291, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24088, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.460748965517241 ], [ 29.774853037974683, -1.461018448275862 ], [ 29.775122531645568, -1.461018448275862 ], [ 29.775122531645568, -1.460748965517241 ], [ 29.774853037974683, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24089, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.460748965517241 ], [ 29.775122531645568, -1.461018448275862 ], [ 29.775392025316457, -1.461018448275862 ], [ 29.775392025316457, -1.460748965517241 ], [ 29.775122531645568, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24090, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.460748965517241 ], [ 29.775392025316457, -1.461018448275862 ], [ 29.775661518987341, -1.461018448275862 ], [ 29.775661518987341, -1.460748965517241 ], [ 29.775392025316457, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24091, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.460748965517241 ], [ 29.775661518987341, -1.461018448275862 ], [ 29.775931012658226, -1.461018448275862 ], [ 29.775931012658226, -1.460748965517241 ], [ 29.775661518987341, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24092, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.460748965517241 ], [ 29.775931012658226, -1.461018448275862 ], [ 29.776200506329115, -1.461018448275862 ], [ 29.776200506329115, -1.460748965517241 ], [ 29.775931012658226, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24093, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.460748965517241 ], [ 29.776200506329115, -1.461018448275862 ], [ 29.77647, -1.461018448275862 ], [ 29.77647, -1.460748965517241 ], [ 29.776200506329115, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24094, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.460748965517241 ], [ 29.77647, -1.461018448275862 ], [ 29.776739493670885, -1.461018448275862 ], [ 29.776739493670885, -1.460748965517241 ], [ 29.77647, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24095, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.460748965517241 ], [ 29.776739493670885, -1.461018448275862 ], [ 29.777008987341773, -1.461018448275862 ], [ 29.777008987341773, -1.460748965517241 ], [ 29.776739493670885, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24096, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.460748965517241 ], [ 29.777008987341773, -1.461018448275862 ], [ 29.777278481012658, -1.461018448275862 ], [ 29.777278481012658, -1.460748965517241 ], [ 29.777008987341773, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24097, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.460748965517241 ], [ 29.777278481012658, -1.461018448275862 ], [ 29.777547974683543, -1.461018448275862 ], [ 29.777547974683543, -1.460748965517241 ], [ 29.777278481012658, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24098, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.460748965517241 ], [ 29.777547974683543, -1.461018448275862 ], [ 29.777817468354431, -1.461018448275862 ], [ 29.777817468354431, -1.460748965517241 ], [ 29.777547974683543, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24099, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.460748965517241 ], [ 29.777817468354431, -1.461018448275862 ], [ 29.778086962025316, -1.461018448275862 ], [ 29.778086962025316, -1.460748965517241 ], [ 29.777817468354431, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24100, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.460748965517241 ], [ 29.778086962025316, -1.461018448275862 ], [ 29.778356455696201, -1.461018448275862 ], [ 29.778356455696201, -1.460748965517241 ], [ 29.778086962025316, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24101, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.460748965517241 ], [ 29.778356455696201, -1.461018448275862 ], [ 29.77862594936709, -1.461018448275862 ], [ 29.77862594936709, -1.460748965517241 ], [ 29.778356455696201, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24102, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.460748965517241 ], [ 29.77916493670886, -1.461018448275862 ], [ 29.779434430379748, -1.461018448275862 ], [ 29.779434430379748, -1.460748965517241 ], [ 29.77916493670886, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24103, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.460748965517241 ], [ 29.779434430379748, -1.461018448275862 ], [ 29.779703924050633, -1.461018448275862 ], [ 29.779703924050633, -1.460748965517241 ], [ 29.779434430379748, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24104, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.460748965517241 ], [ 29.779973417721518, -1.461018448275862 ], [ 29.780242911392406, -1.461018448275862 ], [ 29.780242911392406, -1.460748965517241 ], [ 29.779973417721518, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24105, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.460748965517241 ], [ 29.780242911392406, -1.461018448275862 ], [ 29.780512405063291, -1.461018448275862 ], [ 29.780512405063291, -1.460748965517241 ], [ 29.780242911392406, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24106, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.460748965517241 ], [ 29.780781898734176, -1.461018448275862 ], [ 29.781051392405065, -1.461018448275862 ], [ 29.781051392405065, -1.460748965517241 ], [ 29.780781898734176, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24107, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.460748965517241 ], [ 29.781051392405065, -1.461018448275862 ], [ 29.78132088607595, -1.461018448275862 ], [ 29.78132088607595, -1.460748965517241 ], [ 29.781051392405065, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24108, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.460748965517241 ], [ 29.78132088607595, -1.461018448275862 ], [ 29.781590379746834, -1.461018448275862 ], [ 29.781590379746834, -1.460748965517241 ], [ 29.78132088607595, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24109, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.460748965517241 ], [ 29.781859873417723, -1.461018448275862 ], [ 29.782129367088608, -1.461018448275862 ], [ 29.782129367088608, -1.460748965517241 ], [ 29.781859873417723, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24110, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.460748965517241 ], [ 29.782129367088608, -1.461018448275862 ], [ 29.782398860759493, -1.461018448275862 ], [ 29.782398860759493, -1.460748965517241 ], [ 29.782129367088608, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24111, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.460748965517241 ], [ 29.782398860759493, -1.461018448275862 ], [ 29.782668354430381, -1.461018448275862 ], [ 29.782668354430381, -1.460748965517241 ], [ 29.782398860759493, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24112, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.460748965517241 ], [ 29.782668354430381, -1.461018448275862 ], [ 29.782937848101266, -1.461018448275862 ], [ 29.782937848101266, -1.460748965517241 ], [ 29.782668354430381, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24113, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.460748965517241 ], [ 29.782937848101266, -1.461018448275862 ], [ 29.783207341772151, -1.461018448275862 ], [ 29.783207341772151, -1.460748965517241 ], [ 29.782937848101266, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24114, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.460748965517241 ], [ 29.783207341772151, -1.461018448275862 ], [ 29.783476835443039, -1.461018448275862 ], [ 29.783476835443039, -1.460748965517241 ], [ 29.783207341772151, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24115, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.460748965517241 ], [ 29.783476835443039, -1.461018448275862 ], [ 29.784015822784809, -1.461018448275862 ], [ 29.784015822784809, -1.460748965517241 ], [ 29.783476835443039, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24116, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.460748965517241 ], [ 29.784015822784809, -1.461018448275862 ], [ 29.784285316455698, -1.461018448275862 ], [ 29.784285316455698, -1.460748965517241 ], [ 29.784015822784809, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24117, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.460748965517241 ], [ 29.784285316455698, -1.461018448275862 ], [ 29.784554810126583, -1.461018448275862 ], [ 29.784554810126583, -1.460748965517241 ], [ 29.784285316455698, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24118, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.460748965517241 ], [ 29.784554810126583, -1.461018448275862 ], [ 29.784824303797468, -1.461018448275862 ], [ 29.784824303797468, -1.460748965517241 ], [ 29.784554810126583, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24119, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.460748965517241 ], [ 29.784824303797468, -1.461018448275862 ], [ 29.785093797468356, -1.461018448275862 ], [ 29.785093797468356, -1.460748965517241 ], [ 29.784824303797468, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24120, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.460748965517241 ], [ 29.785093797468356, -1.461018448275862 ], [ 29.785363291139241, -1.461018448275862 ], [ 29.785363291139241, -1.460748965517241 ], [ 29.785093797468356, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24121, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.460748965517241 ], [ 29.785363291139241, -1.461018448275862 ], [ 29.785632784810126, -1.461018448275862 ], [ 29.785632784810126, -1.460748965517241 ], [ 29.785363291139241, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24122, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.460748965517241 ], [ 29.785632784810126, -1.461018448275862 ], [ 29.785902278481014, -1.461018448275862 ], [ 29.785902278481014, -1.460748965517241 ], [ 29.785632784810126, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24123, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.460748965517241 ], [ 29.785902278481014, -1.461018448275862 ], [ 29.786171772151899, -1.461018448275862 ], [ 29.786171772151899, -1.460748965517241 ], [ 29.785902278481014, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24124, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.460748965517241 ], [ 29.786171772151899, -1.461018448275862 ], [ 29.786441265822784, -1.461018448275862 ], [ 29.786441265822784, -1.460748965517241 ], [ 29.786171772151899, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24125, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.460748965517241 ], [ 29.786441265822784, -1.461018448275862 ], [ 29.786710759493673, -1.461018448275862 ], [ 29.786710759493673, -1.460748965517241 ], [ 29.786441265822784, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24126, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.460748965517241 ], [ 29.786710759493673, -1.461018448275862 ], [ 29.786980253164558, -1.461018448275862 ], [ 29.786980253164558, -1.460748965517241 ], [ 29.786710759493673, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24127, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.460748965517241 ], [ 29.786980253164558, -1.461018448275862 ], [ 29.787249746835442, -1.461018448275862 ], [ 29.787249746835442, -1.460748965517241 ], [ 29.786980253164558, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24128, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.460748965517241 ], [ 29.787249746835442, -1.461018448275862 ], [ 29.787519240506327, -1.461018448275862 ], [ 29.787519240506327, -1.460748965517241 ], [ 29.787249746835442, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24129, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.460748965517241 ], [ 29.787519240506327, -1.461018448275862 ], [ 29.787788734177216, -1.461018448275862 ], [ 29.787788734177216, -1.460748965517241 ], [ 29.787519240506327, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24130, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.460748965517241 ], [ 29.787788734177216, -1.461018448275862 ], [ 29.788058227848101, -1.461018448275862 ], [ 29.788058227848101, -1.460748965517241 ], [ 29.787788734177216, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24131, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.460748965517241 ], [ 29.788058227848101, -1.461018448275862 ], [ 29.788327721518986, -1.461018448275862 ], [ 29.788327721518986, -1.460748965517241 ], [ 29.788058227848101, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24132, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.460748965517241 ], [ 29.797490506329115, -1.461018448275862 ], [ 29.79776, -1.461018448275862 ], [ 29.79776, -1.460748965517241 ], [ 29.797490506329115, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24133, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.460748965517241 ], [ 29.79776, -1.461018448275862 ], [ 29.798029493670885, -1.461018448275862 ], [ 29.798029493670885, -1.460748965517241 ], [ 29.79776, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24134, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.460748965517241 ], [ 29.798029493670885, -1.461018448275862 ], [ 29.798298987341774, -1.461018448275862 ], [ 29.798298987341774, -1.460748965517241 ], [ 29.798029493670885, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24135, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.460748965517241 ], [ 29.798298987341774, -1.461018448275862 ], [ 29.798568481012659, -1.461018448275862 ], [ 29.798568481012659, -1.460748965517241 ], [ 29.798298987341774, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24136, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.460748965517241 ], [ 29.798837974683543, -1.461018448275862 ], [ 29.799107468354432, -1.461018448275862 ], [ 29.799107468354432, -1.460748965517241 ], [ 29.798837974683543, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24137, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.460748965517241 ], [ 29.799107468354432, -1.461018448275862 ], [ 29.799376962025317, -1.461018448275862 ], [ 29.799376962025317, -1.460748965517241 ], [ 29.799107468354432, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24138, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.460748965517241 ], [ 29.799376962025317, -1.461018448275862 ], [ 29.799646455696202, -1.461018448275862 ], [ 29.799646455696202, -1.460748965517241 ], [ 29.799376962025317, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24139, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.460748965517241 ], [ 29.799646455696202, -1.461018448275862 ], [ 29.79991594936709, -1.461018448275862 ], [ 29.79991594936709, -1.460748965517241 ], [ 29.799646455696202, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24140, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.458054137931035 ], [ 29.788866708860759, -1.461287931034483 ], [ 29.789136202531644, -1.461287931034483 ], [ 29.789136202531644, -1.458054137931035 ], [ 29.788866708860759, -1.458054137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24141, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.459940517241379 ], [ 29.796143037974684, -1.461826896551724 ], [ 29.796412531645569, -1.461826896551724 ], [ 29.796412531645569, -1.459940517241379 ], [ 29.796143037974684, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24142, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.459940517241379 ], [ 29.796951518987342, -1.461826896551724 ], [ 29.797221012658227, -1.461826896551724 ], [ 29.797221012658227, -1.459940517241379 ], [ 29.796951518987342, -1.459940517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24143, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.46021 ], [ 29.794795569620252, -1.461557413793104 ], [ 29.79506506329114, -1.461557413793104 ], [ 29.79506506329114, -1.46021 ], [ 29.794795569620252, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24144, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.46021 ], [ 29.79506506329114, -1.461557413793104 ], [ 29.795334556962025, -1.461557413793104 ], [ 29.795334556962025, -1.46021 ], [ 29.79506506329114, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24145, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.46021 ], [ 29.79560405063291, -1.461557413793104 ], [ 29.795873544303799, -1.461557413793104 ], [ 29.795873544303799, -1.46021 ], [ 29.79560405063291, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24146, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.46021 ], [ 29.795873544303799, -1.461557413793104 ], [ 29.796143037974684, -1.461557413793104 ], [ 29.796143037974684, -1.46021 ], [ 29.795873544303799, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24147, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.46021 ], [ 29.796412531645569, -1.461557413793104 ], [ 29.796682025316457, -1.461557413793104 ], [ 29.796682025316457, -1.46021 ], [ 29.796412531645569, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24148, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.46021 ], [ 29.797221012658227, -1.461287931034483 ], [ 29.797490506329115, -1.461287931034483 ], [ 29.797490506329115, -1.460748965517241 ], [ 29.79776, -1.460748965517241 ], [ 29.79776, -1.46021 ], [ 29.797221012658227, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24149, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.46021 ], [ 29.800993924050633, -1.461287931034483 ], [ 29.801263417721518, -1.461287931034483 ], [ 29.801263417721518, -1.46021 ], [ 29.800993924050633, -1.46021 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24150, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.460479482758621 ], [ 29.793987088607594, -1.461287931034483 ], [ 29.794256582278482, -1.461287931034483 ], [ 29.794256582278482, -1.460479482758621 ], [ 29.793987088607594, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24151, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.460479482758621 ], [ 29.794256582278482, -1.461287931034483 ], [ 29.794526075949367, -1.461287931034483 ], [ 29.794526075949367, -1.460479482758621 ], [ 29.794256582278482, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24152, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.460479482758621 ], [ 29.794526075949367, -1.461287931034483 ], [ 29.794795569620252, -1.461287931034483 ], [ 29.794795569620252, -1.460479482758621 ], [ 29.794526075949367, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24153, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.460479482758621 ], [ 29.795334556962025, -1.461287931034483 ], [ 29.79560405063291, -1.461287931034483 ], [ 29.79560405063291, -1.460479482758621 ], [ 29.795334556962025, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24154, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.460479482758621 ], [ 29.796682025316457, -1.461287931034483 ], [ 29.796951518987342, -1.461287931034483 ], [ 29.796951518987342, -1.460479482758621 ], [ 29.796682025316457, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24155, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.460479482758621 ], [ 29.80045493670886, -1.461557413793104 ], [ 29.800724430379748, -1.461557413793104 ], [ 29.800724430379748, -1.460479482758621 ], [ 29.80045493670886, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24156, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.460479482758621 ], [ 29.800724430379748, -1.461287931034483 ], [ 29.800993924050633, -1.461287931034483 ], [ 29.800993924050633, -1.460479482758621 ], [ 29.800724430379748, -1.460479482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24157, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.460748965517241 ], [ 29.753832531645568, -1.461287931034483 ], [ 29.754102025316456, -1.461287931034483 ], [ 29.754102025316456, -1.460748965517241 ], [ 29.753832531645568, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24158, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.460748965517241 ], [ 29.754102025316456, -1.461287931034483 ], [ 29.754371518987341, -1.461287931034483 ], [ 29.754371518987341, -1.460748965517241 ], [ 29.754102025316456, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24159, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.460748965517241 ], [ 29.757066455696201, -1.461287931034483 ], [ 29.757335949367089, -1.461287931034483 ], [ 29.757335949367089, -1.460748965517241 ], [ 29.757066455696201, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24160, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.460748965517241 ], [ 29.757874936708859, -1.461287931034483 ], [ 29.758144430379748, -1.461287931034483 ], [ 29.758144430379748, -1.460748965517241 ], [ 29.757874936708859, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24161, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.460748965517241 ], [ 29.758683417721517, -1.461287931034483 ], [ 29.758952911392406, -1.461287931034483 ], [ 29.758952911392406, -1.460748965517241 ], [ 29.758683417721517, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24162, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.460748965517241 ], [ 29.761378354430381, -1.461018448275862 ], [ 29.761647848101266, -1.461018448275862 ], [ 29.761647848101266, -1.461287931034483 ], [ 29.761917341772151, -1.461287931034483 ], [ 29.761917341772151, -1.460748965517241 ], [ 29.761378354430381, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24163, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.460748965517241 ], [ 29.778895443037975, -1.461287931034483 ], [ 29.77916493670886, -1.461287931034483 ], [ 29.77916493670886, -1.460748965517241 ], [ 29.778895443037975, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24164, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.460748965517241 ], [ 29.779703924050633, -1.461287931034483 ], [ 29.779973417721518, -1.461287931034483 ], [ 29.779973417721518, -1.460748965517241 ], [ 29.779703924050633, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24165, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.460748965517241 ], [ 29.781590379746834, -1.461287931034483 ], [ 29.781859873417723, -1.461287931034483 ], [ 29.781859873417723, -1.460748965517241 ], [ 29.781590379746834, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24166, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.460748965517241 ], [ 29.79991594936709, -1.461287931034483 ], [ 29.800185443037975, -1.461287931034483 ], [ 29.800185443037975, -1.460748965517241 ], [ 29.79991594936709, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24167, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.460748965517241 ], [ 29.800185443037975, -1.461287931034483 ], [ 29.80045493670886, -1.461287931034483 ], [ 29.80045493670886, -1.460748965517241 ], [ 29.800185443037975, -1.460748965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24168, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753024050632909, -1.461018448275862 ], [ 29.753024050632909, -1.461826896551724 ], [ 29.753293544303798, -1.461826896551724 ], [ 29.753293544303798, -1.461018448275862 ], [ 29.753024050632909, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24169, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.461018448275862 ], [ 29.753293544303798, -1.461557413793104 ], [ 29.753563037974683, -1.461557413793104 ], [ 29.753563037974683, -1.461018448275862 ], [ 29.753293544303798, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24170, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.461018448275862 ], [ 29.753563037974683, -1.461557413793104 ], [ 29.753832531645568, -1.461557413793104 ], [ 29.753832531645568, -1.461018448275862 ], [ 29.753563037974683, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24171, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.461018448275862 ], [ 29.754371518987341, -1.461287931034483 ], [ 29.754641012658226, -1.461287931034483 ], [ 29.754641012658226, -1.461018448275862 ], [ 29.754371518987341, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24172, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.461018448275862 ], [ 29.754641012658226, -1.461287931034483 ], [ 29.754910506329114, -1.461287931034483 ], [ 29.754910506329114, -1.461018448275862 ], [ 29.754641012658226, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24173, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.461018448275862 ], [ 29.754910506329114, -1.461287931034483 ], [ 29.75518, -1.461287931034483 ], [ 29.75518, -1.461018448275862 ], [ 29.754910506329114, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24174, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.461018448275862 ], [ 29.75518, -1.461287931034483 ], [ 29.755449493670884, -1.461287931034483 ], [ 29.755449493670884, -1.461018448275862 ], [ 29.75518, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24175, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.461018448275862 ], [ 29.755449493670884, -1.461287931034483 ], [ 29.755718987341773, -1.461287931034483 ], [ 29.755718987341773, -1.461018448275862 ], [ 29.755449493670884, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24176, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.461018448275862 ], [ 29.755718987341773, -1.461557413793104 ], [ 29.755988481012658, -1.461557413793104 ], [ 29.755988481012658, -1.461018448275862 ], [ 29.755718987341773, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24177, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.461018448275862 ], [ 29.755988481012658, -1.461287931034483 ], [ 29.756257974683542, -1.461287931034483 ], [ 29.756257974683542, -1.461018448275862 ], [ 29.755988481012658, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24178, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.461018448275862 ], [ 29.756257974683542, -1.461287931034483 ], [ 29.756796962025316, -1.461287931034483 ], [ 29.756796962025316, -1.461018448275862 ], [ 29.756257974683542, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24179, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.461018448275862 ], [ 29.756796962025316, -1.461557413793104 ], [ 29.757066455696201, -1.461557413793104 ], [ 29.757066455696201, -1.461018448275862 ], [ 29.756796962025316, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24180, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.461018448275862 ], [ 29.757335949367089, -1.461287931034483 ], [ 29.757605443037974, -1.461287931034483 ], [ 29.757605443037974, -1.461018448275862 ], [ 29.757335949367089, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24181, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.461018448275862 ], [ 29.757605443037974, -1.461557413793104 ], [ 29.757874936708859, -1.461557413793104 ], [ 29.757874936708859, -1.461018448275862 ], [ 29.757605443037974, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24182, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.461018448275862 ], [ 29.758144430379748, -1.461287931034483 ], [ 29.758413924050632, -1.461287931034483 ], [ 29.758413924050632, -1.461018448275862 ], [ 29.758144430379748, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24183, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.461018448275862 ], [ 29.758413924050632, -1.461287931034483 ], [ 29.758683417721517, -1.461287931034483 ], [ 29.758683417721517, -1.461018448275862 ], [ 29.758413924050632, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24184, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.461018448275862 ], [ 29.758952911392406, -1.461287931034483 ], [ 29.759222405063291, -1.461287931034483 ], [ 29.759222405063291, -1.461018448275862 ], [ 29.758952911392406, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24185, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.461018448275862 ], [ 29.759222405063291, -1.461287931034483 ], [ 29.759491898734176, -1.461287931034483 ], [ 29.759491898734176, -1.461018448275862 ], [ 29.759222405063291, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24186, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.461018448275862 ], [ 29.759491898734176, -1.461287931034483 ], [ 29.759761392405064, -1.461287931034483 ], [ 29.759761392405064, -1.461018448275862 ], [ 29.759491898734176, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24187, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.461018448275862 ], [ 29.759761392405064, -1.461287931034483 ], [ 29.760030886075949, -1.461287931034483 ], [ 29.760030886075949, -1.461557413793104 ], [ 29.760300379746834, -1.461557413793104 ], [ 29.760300379746834, -1.461018448275862 ], [ 29.759761392405064, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24188, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.461018448275862 ], [ 29.760300379746834, -1.461287931034483 ], [ 29.760569873417722, -1.461287931034483 ], [ 29.760569873417722, -1.461018448275862 ], [ 29.760300379746834, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24189, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.461018448275862 ], [ 29.760569873417722, -1.461287931034483 ], [ 29.760839367088607, -1.461287931034483 ], [ 29.760839367088607, -1.461018448275862 ], [ 29.760569873417722, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24190, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.461018448275862 ], [ 29.760839367088607, -1.461287931034483 ], [ 29.761108860759492, -1.461287931034483 ], [ 29.761108860759492, -1.461557413793104 ], [ 29.761378354430381, -1.461557413793104 ], [ 29.761378354430381, -1.461018448275862 ], [ 29.760839367088607, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24191, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.461018448275862 ], [ 29.761378354430381, -1.461287931034483 ], [ 29.761647848101266, -1.461287931034483 ], [ 29.761647848101266, -1.461018448275862 ], [ 29.761378354430381, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24192, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.461018448275862 ], [ 29.761917341772151, -1.461287931034483 ], [ 29.762186835443039, -1.461287931034483 ], [ 29.762186835443039, -1.461018448275862 ], [ 29.761917341772151, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24193, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.461018448275862 ], [ 29.762186835443039, -1.461287931034483 ], [ 29.762456329113924, -1.461287931034483 ], [ 29.762456329113924, -1.461018448275862 ], [ 29.762186835443039, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24194, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.461018448275862 ], [ 29.762456329113924, -1.461287931034483 ], [ 29.762725822784809, -1.461287931034483 ], [ 29.762725822784809, -1.461018448275862 ], [ 29.762456329113924, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24195, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.461018448275862 ], [ 29.762725822784809, -1.461287931034483 ], [ 29.762995316455697, -1.461287931034483 ], [ 29.762995316455697, -1.461018448275862 ], [ 29.762725822784809, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24196, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.461018448275862 ], [ 29.762995316455697, -1.461287931034483 ], [ 29.763264810126582, -1.461287931034483 ], [ 29.763264810126582, -1.461018448275862 ], [ 29.762995316455697, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24197, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.461018448275862 ], [ 29.763264810126582, -1.461287931034483 ], [ 29.763534303797467, -1.461287931034483 ], [ 29.763534303797467, -1.461018448275862 ], [ 29.763264810126582, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24198, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.461018448275862 ], [ 29.763534303797467, -1.461287931034483 ], [ 29.763803797468356, -1.461287931034483 ], [ 29.763803797468356, -1.461018448275862 ], [ 29.763534303797467, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24199, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.461018448275862 ], [ 29.763803797468356, -1.461287931034483 ], [ 29.76407329113924, -1.461287931034483 ], [ 29.76407329113924, -1.461018448275862 ], [ 29.763803797468356, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24200, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.461018448275862 ], [ 29.76407329113924, -1.461287931034483 ], [ 29.764342784810125, -1.461287931034483 ], [ 29.764342784810125, -1.461018448275862 ], [ 29.76407329113924, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24201, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.461018448275862 ], [ 29.764342784810125, -1.461287931034483 ], [ 29.764612278481014, -1.461287931034483 ], [ 29.764612278481014, -1.461018448275862 ], [ 29.764342784810125, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24202, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.461018448275862 ], [ 29.764612278481014, -1.461287931034483 ], [ 29.764881772151899, -1.461287931034483 ], [ 29.764881772151899, -1.461018448275862 ], [ 29.764612278481014, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24203, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.461018448275862 ], [ 29.764881772151899, -1.461287931034483 ], [ 29.765151265822784, -1.461287931034483 ], [ 29.765151265822784, -1.461018448275862 ], [ 29.764881772151899, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24204, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.461018448275862 ], [ 29.765151265822784, -1.461287931034483 ], [ 29.765420759493672, -1.461287931034483 ], [ 29.765420759493672, -1.461018448275862 ], [ 29.765151265822784, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24205, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.461018448275862 ], [ 29.765420759493672, -1.461287931034483 ], [ 29.765959746835442, -1.461287931034483 ], [ 29.765959746835442, -1.461018448275862 ], [ 29.765420759493672, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24206, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.461018448275862 ], [ 29.765959746835442, -1.461287931034483 ], [ 29.766229240506327, -1.461287931034483 ], [ 29.766229240506327, -1.461018448275862 ], [ 29.765959746835442, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24207, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.461018448275862 ], [ 29.766229240506327, -1.461287931034483 ], [ 29.766498734177215, -1.461287931034483 ], [ 29.766498734177215, -1.461018448275862 ], [ 29.766229240506327, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24208, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.461018448275862 ], [ 29.766498734177215, -1.461287931034483 ], [ 29.7667682278481, -1.461287931034483 ], [ 29.7667682278481, -1.461018448275862 ], [ 29.766498734177215, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24209, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.461018448275862 ], [ 29.7667682278481, -1.461287931034483 ], [ 29.767037721518985, -1.461287931034483 ], [ 29.767037721518985, -1.461018448275862 ], [ 29.7667682278481, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24210, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.461018448275862 ], [ 29.767037721518985, -1.461287931034483 ], [ 29.767307215189874, -1.461287931034483 ], [ 29.767307215189874, -1.461018448275862 ], [ 29.767037721518985, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24211, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.461018448275862 ], [ 29.767307215189874, -1.461287931034483 ], [ 29.767576708860759, -1.461287931034483 ], [ 29.767576708860759, -1.461018448275862 ], [ 29.767307215189874, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24212, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.461018448275862 ], [ 29.767576708860759, -1.461287931034483 ], [ 29.767846202531643, -1.461287931034483 ], [ 29.767846202531643, -1.461018448275862 ], [ 29.767576708860759, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24213, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.461018448275862 ], [ 29.767846202531643, -1.461287931034483 ], [ 29.768115696202532, -1.461287931034483 ], [ 29.768115696202532, -1.461018448275862 ], [ 29.767846202531643, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24214, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.461018448275862 ], [ 29.768115696202532, -1.461287931034483 ], [ 29.768385189873417, -1.461287931034483 ], [ 29.768385189873417, -1.461018448275862 ], [ 29.768115696202532, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24215, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.461018448275862 ], [ 29.768385189873417, -1.461287931034483 ], [ 29.768654683544302, -1.461287931034483 ], [ 29.768654683544302, -1.461018448275862 ], [ 29.768385189873417, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24216, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.461018448275862 ], [ 29.768654683544302, -1.461287931034483 ], [ 29.76892417721519, -1.461287931034483 ], [ 29.76892417721519, -1.461018448275862 ], [ 29.768654683544302, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24217, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.461018448275862 ], [ 29.76892417721519, -1.461287931034483 ], [ 29.769732658227849, -1.461287931034483 ], [ 29.769732658227849, -1.461018448275862 ], [ 29.76892417721519, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24218, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.461018448275862 ], [ 29.769732658227849, -1.461287931034483 ], [ 29.770002151898733, -1.461287931034483 ], [ 29.770002151898733, -1.461018448275862 ], [ 29.769732658227849, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24219, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.461018448275862 ], [ 29.770002151898733, -1.461287931034483 ], [ 29.770271645569618, -1.461287931034483 ], [ 29.770271645569618, -1.461018448275862 ], [ 29.770002151898733, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24220, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.461018448275862 ], [ 29.770271645569618, -1.461287931034483 ], [ 29.770541139240507, -1.461287931034483 ], [ 29.770541139240507, -1.461018448275862 ], [ 29.770271645569618, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24221, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.461018448275862 ], [ 29.770541139240507, -1.461287931034483 ], [ 29.770810632911392, -1.461287931034483 ], [ 29.770810632911392, -1.461018448275862 ], [ 29.770541139240507, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24222, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.461018448275862 ], [ 29.770810632911392, -1.461287931034483 ], [ 29.771080126582277, -1.461287931034483 ], [ 29.771080126582277, -1.461018448275862 ], [ 29.770810632911392, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24223, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.461018448275862 ], [ 29.771080126582277, -1.461287931034483 ], [ 29.771349620253165, -1.461287931034483 ], [ 29.771349620253165, -1.461018448275862 ], [ 29.771080126582277, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24224, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.461018448275862 ], [ 29.771349620253165, -1.461287931034483 ], [ 29.77161911392405, -1.461287931034483 ], [ 29.77161911392405, -1.461018448275862 ], [ 29.771349620253165, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24225, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.461018448275862 ], [ 29.77161911392405, -1.461287931034483 ], [ 29.771888607594935, -1.461287931034483 ], [ 29.771888607594935, -1.461018448275862 ], [ 29.77161911392405, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24226, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.461018448275862 ], [ 29.771888607594935, -1.461287931034483 ], [ 29.772158101265823, -1.461287931034483 ], [ 29.772158101265823, -1.461018448275862 ], [ 29.771888607594935, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24227, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.461018448275862 ], [ 29.772158101265823, -1.461287931034483 ], [ 29.772427594936708, -1.461287931034483 ], [ 29.772427594936708, -1.461018448275862 ], [ 29.772158101265823, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24228, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.461018448275862 ], [ 29.772427594936708, -1.461287931034483 ], [ 29.772697088607593, -1.461287931034483 ], [ 29.772697088607593, -1.461018448275862 ], [ 29.772427594936708, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24229, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.461018448275862 ], [ 29.772697088607593, -1.461287931034483 ], [ 29.772966582278482, -1.461287931034483 ], [ 29.772966582278482, -1.461018448275862 ], [ 29.772697088607593, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24230, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.461018448275862 ], [ 29.772966582278482, -1.461287931034483 ], [ 29.773236075949367, -1.461287931034483 ], [ 29.773236075949367, -1.461018448275862 ], [ 29.772966582278482, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24231, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.461018448275862 ], [ 29.773236075949367, -1.461287931034483 ], [ 29.773505569620252, -1.461287931034483 ], [ 29.773505569620252, -1.461018448275862 ], [ 29.773236075949367, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24232, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.461018448275862 ], [ 29.773505569620252, -1.461287931034483 ], [ 29.77377506329114, -1.461287931034483 ], [ 29.77377506329114, -1.461018448275862 ], [ 29.773505569620252, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24233, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.461018448275862 ], [ 29.77377506329114, -1.461287931034483 ], [ 29.774044556962025, -1.461287931034483 ], [ 29.774044556962025, -1.461018448275862 ], [ 29.77377506329114, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24234, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.461018448275862 ], [ 29.774044556962025, -1.461287931034483 ], [ 29.77431405063291, -1.461287931034483 ], [ 29.77431405063291, -1.461018448275862 ], [ 29.774044556962025, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24235, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.461018448275862 ], [ 29.77431405063291, -1.461287931034483 ], [ 29.774853037974683, -1.461287931034483 ], [ 29.774853037974683, -1.461018448275862 ], [ 29.77431405063291, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24236, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.461018448275862 ], [ 29.774853037974683, -1.461287931034483 ], [ 29.775122531645568, -1.461287931034483 ], [ 29.775122531645568, -1.461018448275862 ], [ 29.774853037974683, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24237, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.461018448275862 ], [ 29.775122531645568, -1.461287931034483 ], [ 29.775392025316457, -1.461287931034483 ], [ 29.775392025316457, -1.461018448275862 ], [ 29.775122531645568, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24238, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.461018448275862 ], [ 29.775392025316457, -1.461287931034483 ], [ 29.775661518987341, -1.461287931034483 ], [ 29.775661518987341, -1.461018448275862 ], [ 29.775392025316457, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24239, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.461018448275862 ], [ 29.775661518987341, -1.461287931034483 ], [ 29.775931012658226, -1.461287931034483 ], [ 29.775931012658226, -1.461018448275862 ], [ 29.775661518987341, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24240, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.461018448275862 ], [ 29.775931012658226, -1.461287931034483 ], [ 29.776200506329115, -1.461287931034483 ], [ 29.776200506329115, -1.461018448275862 ], [ 29.775931012658226, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24241, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.461018448275862 ], [ 29.776200506329115, -1.461287931034483 ], [ 29.77647, -1.461287931034483 ], [ 29.77647, -1.461018448275862 ], [ 29.776200506329115, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24242, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.461018448275862 ], [ 29.77647, -1.461287931034483 ], [ 29.776739493670885, -1.461287931034483 ], [ 29.776739493670885, -1.461018448275862 ], [ 29.77647, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24243, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.461018448275862 ], [ 29.776739493670885, -1.461287931034483 ], [ 29.777008987341773, -1.461287931034483 ], [ 29.777008987341773, -1.461018448275862 ], [ 29.776739493670885, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24244, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.461018448275862 ], [ 29.777008987341773, -1.461287931034483 ], [ 29.777278481012658, -1.461287931034483 ], [ 29.777278481012658, -1.461018448275862 ], [ 29.777008987341773, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24245, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.461018448275862 ], [ 29.777278481012658, -1.461287931034483 ], [ 29.777547974683543, -1.461287931034483 ], [ 29.777547974683543, -1.461018448275862 ], [ 29.777278481012658, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24246, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.461018448275862 ], [ 29.777547974683543, -1.461287931034483 ], [ 29.777817468354431, -1.461287931034483 ], [ 29.777817468354431, -1.461018448275862 ], [ 29.777547974683543, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24247, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.461018448275862 ], [ 29.777817468354431, -1.461557413793104 ], [ 29.778086962025316, -1.461557413793104 ], [ 29.778086962025316, -1.461018448275862 ], [ 29.777817468354431, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24248, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.461018448275862 ], [ 29.778086962025316, -1.461287931034483 ], [ 29.778356455696201, -1.461287931034483 ], [ 29.778356455696201, -1.461018448275862 ], [ 29.778086962025316, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24249, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.461018448275862 ], [ 29.778356455696201, -1.461287931034483 ], [ 29.77862594936709, -1.461287931034483 ], [ 29.77862594936709, -1.461018448275862 ], [ 29.778356455696201, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24250, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.461018448275862 ], [ 29.77862594936709, -1.461287931034483 ], [ 29.778895443037975, -1.461287931034483 ], [ 29.778895443037975, -1.461018448275862 ], [ 29.77862594936709, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24251, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.461018448275862 ], [ 29.77916493670886, -1.461557413793104 ], [ 29.779434430379748, -1.461557413793104 ], [ 29.779434430379748, -1.461018448275862 ], [ 29.77916493670886, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24252, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.461018448275862 ], [ 29.779434430379748, -1.461287931034483 ], [ 29.779703924050633, -1.461287931034483 ], [ 29.779703924050633, -1.461018448275862 ], [ 29.779434430379748, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24253, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.461018448275862 ], [ 29.779973417721518, -1.461557413793104 ], [ 29.780242911392406, -1.461557413793104 ], [ 29.780242911392406, -1.461018448275862 ], [ 29.779973417721518, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24254, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.461018448275862 ], [ 29.780242911392406, -1.461287931034483 ], [ 29.780512405063291, -1.461287931034483 ], [ 29.780512405063291, -1.461018448275862 ], [ 29.780242911392406, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24255, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.461018448275862 ], [ 29.780512405063291, -1.461287931034483 ], [ 29.780781898734176, -1.461287931034483 ], [ 29.780781898734176, -1.461018448275862 ], [ 29.780512405063291, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24256, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.461018448275862 ], [ 29.780781898734176, -1.461557413793104 ], [ 29.781051392405065, -1.461557413793104 ], [ 29.781051392405065, -1.461018448275862 ], [ 29.780781898734176, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24257, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.461018448275862 ], [ 29.781051392405065, -1.461287931034483 ], [ 29.78132088607595, -1.461287931034483 ], [ 29.78132088607595, -1.461018448275862 ], [ 29.781051392405065, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24258, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.461018448275862 ], [ 29.78132088607595, -1.461287931034483 ], [ 29.781590379746834, -1.461287931034483 ], [ 29.781590379746834, -1.461018448275862 ], [ 29.78132088607595, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24259, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.461018448275862 ], [ 29.781859873417723, -1.461287931034483 ], [ 29.782129367088608, -1.461287931034483 ], [ 29.782129367088608, -1.461018448275862 ], [ 29.781859873417723, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24260, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.461018448275862 ], [ 29.782129367088608, -1.461287931034483 ], [ 29.782398860759493, -1.461287931034483 ], [ 29.782398860759493, -1.461018448275862 ], [ 29.782129367088608, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24261, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.461018448275862 ], [ 29.782398860759493, -1.461287931034483 ], [ 29.782668354430381, -1.461287931034483 ], [ 29.782668354430381, -1.461018448275862 ], [ 29.782398860759493, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24262, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.461018448275862 ], [ 29.782668354430381, -1.461557413793104 ], [ 29.782937848101266, -1.461557413793104 ], [ 29.782937848101266, -1.461018448275862 ], [ 29.782668354430381, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24263, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.461018448275862 ], [ 29.782937848101266, -1.461287931034483 ], [ 29.783207341772151, -1.461287931034483 ], [ 29.783207341772151, -1.461018448275862 ], [ 29.782937848101266, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24264, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.461018448275862 ], [ 29.783207341772151, -1.461287931034483 ], [ 29.783476835443039, -1.461287931034483 ], [ 29.783476835443039, -1.461018448275862 ], [ 29.783207341772151, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24265, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.461018448275862 ], [ 29.783476835443039, -1.461287931034483 ], [ 29.784015822784809, -1.461287931034483 ], [ 29.784015822784809, -1.461018448275862 ], [ 29.783476835443039, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24266, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.461018448275862 ], [ 29.784015822784809, -1.461287931034483 ], [ 29.784285316455698, -1.461287931034483 ], [ 29.784285316455698, -1.461018448275862 ], [ 29.784015822784809, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24267, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.461018448275862 ], [ 29.784285316455698, -1.461287931034483 ], [ 29.784554810126583, -1.461287931034483 ], [ 29.784554810126583, -1.461018448275862 ], [ 29.784285316455698, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24268, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.461018448275862 ], [ 29.784554810126583, -1.461287931034483 ], [ 29.784824303797468, -1.461287931034483 ], [ 29.784824303797468, -1.461018448275862 ], [ 29.784554810126583, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24269, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.461018448275862 ], [ 29.784824303797468, -1.461287931034483 ], [ 29.785093797468356, -1.461287931034483 ], [ 29.785093797468356, -1.461018448275862 ], [ 29.784824303797468, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24270, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.461018448275862 ], [ 29.785093797468356, -1.461287931034483 ], [ 29.785363291139241, -1.461287931034483 ], [ 29.785363291139241, -1.461018448275862 ], [ 29.785093797468356, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24271, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.461018448275862 ], [ 29.785363291139241, -1.461287931034483 ], [ 29.785632784810126, -1.461287931034483 ], [ 29.785632784810126, -1.461018448275862 ], [ 29.785363291139241, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24272, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.461018448275862 ], [ 29.785632784810126, -1.461287931034483 ], [ 29.785902278481014, -1.461287931034483 ], [ 29.785902278481014, -1.461018448275862 ], [ 29.785632784810126, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24273, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.461018448275862 ], [ 29.785902278481014, -1.461287931034483 ], [ 29.786171772151899, -1.461287931034483 ], [ 29.786171772151899, -1.461018448275862 ], [ 29.785902278481014, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24274, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.461018448275862 ], [ 29.786171772151899, -1.461287931034483 ], [ 29.786441265822784, -1.461287931034483 ], [ 29.786441265822784, -1.461018448275862 ], [ 29.786171772151899, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24275, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.461018448275862 ], [ 29.786441265822784, -1.461287931034483 ], [ 29.786710759493673, -1.461287931034483 ], [ 29.786710759493673, -1.461018448275862 ], [ 29.786441265822784, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24276, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.461018448275862 ], [ 29.786710759493673, -1.461287931034483 ], [ 29.786980253164558, -1.461287931034483 ], [ 29.786980253164558, -1.461018448275862 ], [ 29.786710759493673, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24277, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.461018448275862 ], [ 29.786980253164558, -1.461287931034483 ], [ 29.787249746835442, -1.461287931034483 ], [ 29.787249746835442, -1.461018448275862 ], [ 29.786980253164558, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24278, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.461018448275862 ], [ 29.787249746835442, -1.461287931034483 ], [ 29.787519240506327, -1.461287931034483 ], [ 29.787519240506327, -1.461018448275862 ], [ 29.787249746835442, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24279, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.461018448275862 ], [ 29.787519240506327, -1.461287931034483 ], [ 29.787788734177216, -1.461287931034483 ], [ 29.787788734177216, -1.461018448275862 ], [ 29.787519240506327, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24280, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.461018448275862 ], [ 29.787788734177216, -1.461287931034483 ], [ 29.788058227848101, -1.461287931034483 ], [ 29.788058227848101, -1.461018448275862 ], [ 29.787788734177216, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24281, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.461018448275862 ], [ 29.788058227848101, -1.461287931034483 ], [ 29.788327721518986, -1.461287931034483 ], [ 29.788327721518986, -1.461018448275862 ], [ 29.788058227848101, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24282, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.461018448275862 ], [ 29.788327721518986, -1.461287931034483 ], [ 29.788597215189874, -1.461287931034483 ], [ 29.788597215189874, -1.461018448275862 ], [ 29.788327721518986, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24283, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.461018448275862 ], [ 29.788597215189874, -1.461287931034483 ], [ 29.788866708860759, -1.461287931034483 ], [ 29.788866708860759, -1.461018448275862 ], [ 29.788597215189874, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24284, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.461018448275862 ], [ 29.797490506329115, -1.461287931034483 ], [ 29.79776, -1.461287931034483 ], [ 29.79776, -1.461018448275862 ], [ 29.797490506329115, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24285, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.461018448275862 ], [ 29.79776, -1.461287931034483 ], [ 29.798029493670885, -1.461287931034483 ], [ 29.798029493670885, -1.461018448275862 ], [ 29.79776, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24286, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.461018448275862 ], [ 29.798029493670885, -1.461287931034483 ], [ 29.798298987341774, -1.461287931034483 ], [ 29.798298987341774, -1.461018448275862 ], [ 29.798029493670885, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24287, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.461018448275862 ], [ 29.798298987341774, -1.461287931034483 ], [ 29.798568481012659, -1.461287931034483 ], [ 29.798568481012659, -1.461018448275862 ], [ 29.798298987341774, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24288, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.461018448275862 ], [ 29.798568481012659, -1.461287931034483 ], [ 29.798837974683543, -1.461287931034483 ], [ 29.798837974683543, -1.461018448275862 ], [ 29.798568481012659, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24289, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.461018448275862 ], [ 29.798837974683543, -1.461287931034483 ], [ 29.799107468354432, -1.461287931034483 ], [ 29.799107468354432, -1.461018448275862 ], [ 29.798837974683543, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24290, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.461018448275862 ], [ 29.799107468354432, -1.461287931034483 ], [ 29.799376962025317, -1.461287931034483 ], [ 29.799376962025317, -1.461018448275862 ], [ 29.799107468354432, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24291, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.461018448275862 ], [ 29.799376962025317, -1.461287931034483 ], [ 29.799646455696202, -1.461287931034483 ], [ 29.799646455696202, -1.461018448275862 ], [ 29.799376962025317, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24292, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.461018448275862 ], [ 29.799646455696202, -1.461287931034483 ], [ 29.79991594936709, -1.461287931034483 ], [ 29.79991594936709, -1.461018448275862 ], [ 29.799646455696202, -1.461018448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24293, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.461287931034483 ], [ 29.753832531645568, -1.461557413793104 ], [ 29.754102025316456, -1.461557413793104 ], [ 29.754102025316456, -1.461287931034483 ], [ 29.753832531645568, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24294, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.461287931034483 ], [ 29.754102025316456, -1.461557413793104 ], [ 29.754371518987341, -1.461557413793104 ], [ 29.754371518987341, -1.461287931034483 ], [ 29.754102025316456, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24295, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.461287931034483 ], [ 29.754371518987341, -1.461557413793104 ], [ 29.754641012658226, -1.461557413793104 ], [ 29.754641012658226, -1.461287931034483 ], [ 29.754371518987341, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24296, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.461287931034483 ], [ 29.754641012658226, -1.461826896551724 ], [ 29.754910506329114, -1.461826896551724 ], [ 29.754910506329114, -1.461287931034483 ], [ 29.754641012658226, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24297, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.461287931034483 ], [ 29.754910506329114, -1.461557413793104 ], [ 29.75518, -1.461557413793104 ], [ 29.75518, -1.461287931034483 ], [ 29.754910506329114, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24298, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.461287931034483 ], [ 29.75518, -1.461557413793104 ], [ 29.755449493670884, -1.461557413793104 ], [ 29.755449493670884, -1.461287931034483 ], [ 29.75518, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24299, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.461287931034483 ], [ 29.755449493670884, -1.461826896551724 ], [ 29.755718987341773, -1.461826896551724 ], [ 29.755718987341773, -1.461287931034483 ], [ 29.755449493670884, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24300, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.461287931034483 ], [ 29.755988481012658, -1.461826896551724 ], [ 29.756257974683542, -1.461826896551724 ], [ 29.756257974683542, -1.461287931034483 ], [ 29.755988481012658, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24301, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.461287931034483 ], [ 29.756257974683542, -1.461826896551724 ], [ 29.756796962025316, -1.461826896551724 ], [ 29.756796962025316, -1.461287931034483 ], [ 29.756257974683542, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24302, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.461287931034483 ], [ 29.757066455696201, -1.461557413793104 ], [ 29.757335949367089, -1.461557413793104 ], [ 29.757335949367089, -1.461287931034483 ], [ 29.757066455696201, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24303, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.461287931034483 ], [ 29.757335949367089, -1.461826896551724 ], [ 29.757605443037974, -1.461826896551724 ], [ 29.757605443037974, -1.461287931034483 ], [ 29.757335949367089, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24304, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.461287931034483 ], [ 29.757874936708859, -1.461557413793104 ], [ 29.758144430379748, -1.461557413793104 ], [ 29.758144430379748, -1.461287931034483 ], [ 29.757874936708859, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24305, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.461287931034483 ], [ 29.758144430379748, -1.461557413793104 ], [ 29.758413924050632, -1.461557413793104 ], [ 29.758413924050632, -1.461287931034483 ], [ 29.758144430379748, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24306, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.461287931034483 ], [ 29.758413924050632, -1.461826896551724 ], [ 29.758683417721517, -1.461826896551724 ], [ 29.758683417721517, -1.461287931034483 ], [ 29.758413924050632, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24307, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.461287931034483 ], [ 29.758683417721517, -1.461557413793104 ], [ 29.758952911392406, -1.461557413793104 ], [ 29.758952911392406, -1.461287931034483 ], [ 29.758683417721517, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24308, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.461287931034483 ], [ 29.758952911392406, -1.461557413793104 ], [ 29.759222405063291, -1.461557413793104 ], [ 29.759222405063291, -1.461287931034483 ], [ 29.758952911392406, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24309, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.461287931034483 ], [ 29.759222405063291, -1.461557413793104 ], [ 29.759491898734176, -1.461557413793104 ], [ 29.759491898734176, -1.461287931034483 ], [ 29.759222405063291, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24310, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.461287931034483 ], [ 29.759491898734176, -1.461557413793104 ], [ 29.759761392405064, -1.461557413793104 ], [ 29.759761392405064, -1.461287931034483 ], [ 29.759491898734176, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24311, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.461287931034483 ], [ 29.759761392405064, -1.461557413793104 ], [ 29.760030886075949, -1.461557413793104 ], [ 29.760030886075949, -1.461287931034483 ], [ 29.759761392405064, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24312, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.461287931034483 ], [ 29.760300379746834, -1.461557413793104 ], [ 29.760569873417722, -1.461557413793104 ], [ 29.760569873417722, -1.461287931034483 ], [ 29.760300379746834, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24313, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.461287931034483 ], [ 29.760569873417722, -1.461826896551724 ], [ 29.760839367088607, -1.461826896551724 ], [ 29.760839367088607, -1.461287931034483 ], [ 29.760569873417722, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24314, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.461287931034483 ], [ 29.760839367088607, -1.461557413793104 ], [ 29.761108860759492, -1.461557413793104 ], [ 29.761108860759492, -1.461287931034483 ], [ 29.760839367088607, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24315, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.461287931034483 ], [ 29.761378354430381, -1.461557413793104 ], [ 29.761647848101266, -1.461557413793104 ], [ 29.761647848101266, -1.461287931034483 ], [ 29.761378354430381, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24316, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.461287931034483 ], [ 29.761647848101266, -1.461557413793104 ], [ 29.761917341772151, -1.461557413793104 ], [ 29.761917341772151, -1.461287931034483 ], [ 29.761647848101266, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24317, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.461287931034483 ], [ 29.761917341772151, -1.461557413793104 ], [ 29.762186835443039, -1.461557413793104 ], [ 29.762186835443039, -1.461287931034483 ], [ 29.761917341772151, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24318, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.461287931034483 ], [ 29.762186835443039, -1.461557413793104 ], [ 29.762456329113924, -1.461557413793104 ], [ 29.762456329113924, -1.461287931034483 ], [ 29.762186835443039, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24319, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.461287931034483 ], [ 29.762456329113924, -1.461557413793104 ], [ 29.762725822784809, -1.461557413793104 ], [ 29.762725822784809, -1.461287931034483 ], [ 29.762456329113924, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24320, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.461287931034483 ], [ 29.762725822784809, -1.461557413793104 ], [ 29.762995316455697, -1.461557413793104 ], [ 29.762995316455697, -1.461287931034483 ], [ 29.762725822784809, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24321, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.461287931034483 ], [ 29.762995316455697, -1.461557413793104 ], [ 29.763264810126582, -1.461557413793104 ], [ 29.763264810126582, -1.461287931034483 ], [ 29.762995316455697, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24322, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.461287931034483 ], [ 29.763264810126582, -1.461557413793104 ], [ 29.763534303797467, -1.461557413793104 ], [ 29.763534303797467, -1.461287931034483 ], [ 29.763264810126582, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24323, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.461287931034483 ], [ 29.763534303797467, -1.461557413793104 ], [ 29.763803797468356, -1.461557413793104 ], [ 29.763803797468356, -1.461287931034483 ], [ 29.763534303797467, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24324, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.461287931034483 ], [ 29.763803797468356, -1.461557413793104 ], [ 29.76407329113924, -1.461557413793104 ], [ 29.76407329113924, -1.461287931034483 ], [ 29.763803797468356, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24325, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.461287931034483 ], [ 29.76407329113924, -1.461557413793104 ], [ 29.764342784810125, -1.461557413793104 ], [ 29.764342784810125, -1.461287931034483 ], [ 29.76407329113924, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24326, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.461287931034483 ], [ 29.764342784810125, -1.461557413793104 ], [ 29.764612278481014, -1.461557413793104 ], [ 29.764612278481014, -1.461287931034483 ], [ 29.764342784810125, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24327, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.461287931034483 ], [ 29.764612278481014, -1.461557413793104 ], [ 29.764881772151899, -1.461557413793104 ], [ 29.764881772151899, -1.461287931034483 ], [ 29.764612278481014, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24328, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.461287931034483 ], [ 29.764881772151899, -1.461557413793104 ], [ 29.765151265822784, -1.461557413793104 ], [ 29.765151265822784, -1.461287931034483 ], [ 29.764881772151899, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24329, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.461287931034483 ], [ 29.765151265822784, -1.461557413793104 ], [ 29.765420759493672, -1.461557413793104 ], [ 29.765420759493672, -1.461287931034483 ], [ 29.765151265822784, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24330, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.461287931034483 ], [ 29.765420759493672, -1.461557413793104 ], [ 29.765959746835442, -1.461557413793104 ], [ 29.765959746835442, -1.461287931034483 ], [ 29.765420759493672, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24331, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.461287931034483 ], [ 29.765959746835442, -1.461557413793104 ], [ 29.766229240506327, -1.461557413793104 ], [ 29.766229240506327, -1.461287931034483 ], [ 29.765959746835442, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24332, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.461287931034483 ], [ 29.766229240506327, -1.461557413793104 ], [ 29.766498734177215, -1.461557413793104 ], [ 29.766498734177215, -1.461287931034483 ], [ 29.766229240506327, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24333, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.461287931034483 ], [ 29.766498734177215, -1.461557413793104 ], [ 29.7667682278481, -1.461557413793104 ], [ 29.7667682278481, -1.461287931034483 ], [ 29.766498734177215, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24334, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.461287931034483 ], [ 29.7667682278481, -1.461557413793104 ], [ 29.767037721518985, -1.461557413793104 ], [ 29.767037721518985, -1.461287931034483 ], [ 29.7667682278481, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24335, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.461287931034483 ], [ 29.767037721518985, -1.461557413793104 ], [ 29.767307215189874, -1.461557413793104 ], [ 29.767307215189874, -1.461287931034483 ], [ 29.767037721518985, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24336, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.461287931034483 ], [ 29.767307215189874, -1.461557413793104 ], [ 29.767576708860759, -1.461557413793104 ], [ 29.767576708860759, -1.461287931034483 ], [ 29.767307215189874, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24337, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.461287931034483 ], [ 29.767576708860759, -1.461557413793104 ], [ 29.767846202531643, -1.461557413793104 ], [ 29.767846202531643, -1.461287931034483 ], [ 29.767576708860759, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24338, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.461287931034483 ], [ 29.767846202531643, -1.461557413793104 ], [ 29.768115696202532, -1.461557413793104 ], [ 29.768115696202532, -1.461287931034483 ], [ 29.767846202531643, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24339, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.461287931034483 ], [ 29.768115696202532, -1.461557413793104 ], [ 29.768385189873417, -1.461557413793104 ], [ 29.768385189873417, -1.461287931034483 ], [ 29.768115696202532, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24340, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.461287931034483 ], [ 29.768385189873417, -1.461557413793104 ], [ 29.768654683544302, -1.461557413793104 ], [ 29.768654683544302, -1.461287931034483 ], [ 29.768385189873417, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24341, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.461287931034483 ], [ 29.768654683544302, -1.461557413793104 ], [ 29.769193670886075, -1.461557413793104 ], [ 29.769193670886075, -1.461287931034483 ], [ 29.768654683544302, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24342, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.461287931034483 ], [ 29.769193670886075, -1.461557413793104 ], [ 29.76946316455696, -1.461557413793104 ], [ 29.76946316455696, -1.461287931034483 ], [ 29.769193670886075, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24343, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.461287931034483 ], [ 29.76946316455696, -1.461557413793104 ], [ 29.770002151898733, -1.461557413793104 ], [ 29.770002151898733, -1.461287931034483 ], [ 29.76946316455696, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24344, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.461287931034483 ], [ 29.770002151898733, -1.461557413793104 ], [ 29.770271645569618, -1.461557413793104 ], [ 29.770271645569618, -1.461287931034483 ], [ 29.770002151898733, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24345, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.461287931034483 ], [ 29.770271645569618, -1.461557413793104 ], [ 29.770541139240507, -1.461557413793104 ], [ 29.770541139240507, -1.461287931034483 ], [ 29.770271645569618, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24346, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.461287931034483 ], [ 29.770541139240507, -1.461557413793104 ], [ 29.770810632911392, -1.461557413793104 ], [ 29.770810632911392, -1.461287931034483 ], [ 29.770541139240507, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24347, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.461287931034483 ], [ 29.770810632911392, -1.461557413793104 ], [ 29.771080126582277, -1.461557413793104 ], [ 29.771080126582277, -1.461287931034483 ], [ 29.770810632911392, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24348, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.461287931034483 ], [ 29.771080126582277, -1.461557413793104 ], [ 29.771349620253165, -1.461557413793104 ], [ 29.771349620253165, -1.461287931034483 ], [ 29.771080126582277, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24349, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.461287931034483 ], [ 29.771349620253165, -1.461557413793104 ], [ 29.77161911392405, -1.461557413793104 ], [ 29.77161911392405, -1.461287931034483 ], [ 29.771349620253165, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24350, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.461287931034483 ], [ 29.77161911392405, -1.461557413793104 ], [ 29.771888607594935, -1.461557413793104 ], [ 29.771888607594935, -1.461287931034483 ], [ 29.77161911392405, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24351, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.461287931034483 ], [ 29.771888607594935, -1.461557413793104 ], [ 29.772158101265823, -1.461557413793104 ], [ 29.772158101265823, -1.461287931034483 ], [ 29.771888607594935, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24352, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.461287931034483 ], [ 29.772158101265823, -1.461557413793104 ], [ 29.772427594936708, -1.461557413793104 ], [ 29.772427594936708, -1.461287931034483 ], [ 29.772158101265823, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24353, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.461287931034483 ], [ 29.772427594936708, -1.461557413793104 ], [ 29.772697088607593, -1.461557413793104 ], [ 29.772697088607593, -1.461287931034483 ], [ 29.772427594936708, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24354, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.461287931034483 ], [ 29.772697088607593, -1.461557413793104 ], [ 29.772966582278482, -1.461557413793104 ], [ 29.772966582278482, -1.461287931034483 ], [ 29.772697088607593, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24355, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.461287931034483 ], [ 29.772966582278482, -1.461557413793104 ], [ 29.773236075949367, -1.461557413793104 ], [ 29.773236075949367, -1.461287931034483 ], [ 29.772966582278482, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24356, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.461287931034483 ], [ 29.773236075949367, -1.461557413793104 ], [ 29.773505569620252, -1.461557413793104 ], [ 29.773505569620252, -1.461287931034483 ], [ 29.773236075949367, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24357, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.461287931034483 ], [ 29.773505569620252, -1.461557413793104 ], [ 29.77377506329114, -1.461557413793104 ], [ 29.77377506329114, -1.461287931034483 ], [ 29.773505569620252, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24358, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.461287931034483 ], [ 29.77377506329114, -1.461557413793104 ], [ 29.774044556962025, -1.461557413793104 ], [ 29.774044556962025, -1.461287931034483 ], [ 29.77377506329114, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24359, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.461287931034483 ], [ 29.774044556962025, -1.461557413793104 ], [ 29.77431405063291, -1.461557413793104 ], [ 29.77431405063291, -1.461287931034483 ], [ 29.774044556962025, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24360, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.461287931034483 ], [ 29.77431405063291, -1.461557413793104 ], [ 29.774853037974683, -1.461557413793104 ], [ 29.774853037974683, -1.461287931034483 ], [ 29.77431405063291, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24361, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.461287931034483 ], [ 29.774853037974683, -1.461557413793104 ], [ 29.775122531645568, -1.461557413793104 ], [ 29.775122531645568, -1.461287931034483 ], [ 29.774853037974683, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24362, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.461287931034483 ], [ 29.775122531645568, -1.461557413793104 ], [ 29.775392025316457, -1.461557413793104 ], [ 29.775392025316457, -1.461287931034483 ], [ 29.775122531645568, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24363, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.461287931034483 ], [ 29.775392025316457, -1.461557413793104 ], [ 29.775661518987341, -1.461557413793104 ], [ 29.775661518987341, -1.461287931034483 ], [ 29.775392025316457, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24364, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.461287931034483 ], [ 29.775661518987341, -1.461557413793104 ], [ 29.775931012658226, -1.461557413793104 ], [ 29.775931012658226, -1.461287931034483 ], [ 29.775661518987341, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24365, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.461287931034483 ], [ 29.775931012658226, -1.461557413793104 ], [ 29.776200506329115, -1.461557413793104 ], [ 29.776200506329115, -1.461287931034483 ], [ 29.775931012658226, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24366, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.461287931034483 ], [ 29.776200506329115, -1.461557413793104 ], [ 29.77647, -1.461557413793104 ], [ 29.77647, -1.461287931034483 ], [ 29.776200506329115, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24367, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.461287931034483 ], [ 29.77647, -1.461557413793104 ], [ 29.776739493670885, -1.461557413793104 ], [ 29.776739493670885, -1.461287931034483 ], [ 29.77647, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24368, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.461287931034483 ], [ 29.776739493670885, -1.461826896551724 ], [ 29.777008987341773, -1.461826896551724 ], [ 29.777008987341773, -1.461287931034483 ], [ 29.776739493670885, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24369, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.461287931034483 ], [ 29.777008987341773, -1.461557413793104 ], [ 29.777278481012658, -1.461557413793104 ], [ 29.777278481012658, -1.461287931034483 ], [ 29.777008987341773, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24370, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.461287931034483 ], [ 29.777278481012658, -1.461557413793104 ], [ 29.777547974683543, -1.461557413793104 ], [ 29.777547974683543, -1.461287931034483 ], [ 29.777278481012658, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24371, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.461287931034483 ], [ 29.777547974683543, -1.461557413793104 ], [ 29.777817468354431, -1.461557413793104 ], [ 29.777817468354431, -1.461287931034483 ], [ 29.777547974683543, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24372, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.461287931034483 ], [ 29.778086962025316, -1.461557413793104 ], [ 29.778356455696201, -1.461557413793104 ], [ 29.778356455696201, -1.461287931034483 ], [ 29.778086962025316, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24373, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.461287931034483 ], [ 29.778356455696201, -1.461557413793104 ], [ 29.77862594936709, -1.461557413793104 ], [ 29.77862594936709, -1.461287931034483 ], [ 29.778356455696201, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24374, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.461287931034483 ], [ 29.77862594936709, -1.462096379310345 ], [ 29.778895443037975, -1.462096379310345 ], [ 29.778895443037975, -1.461287931034483 ], [ 29.77862594936709, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24375, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.461287931034483 ], [ 29.778895443037975, -1.461826896551724 ], [ 29.77916493670886, -1.461826896551724 ], [ 29.77916493670886, -1.461287931034483 ], [ 29.778895443037975, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24376, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.461287931034483 ], [ 29.779434430379748, -1.461826896551724 ], [ 29.779703924050633, -1.461826896551724 ], [ 29.779703924050633, -1.461287931034483 ], [ 29.779434430379748, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24377, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.461287931034483 ], [ 29.779703924050633, -1.461826896551724 ], [ 29.779973417721518, -1.461826896551724 ], [ 29.779973417721518, -1.461287931034483 ], [ 29.779703924050633, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24378, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.461287931034483 ], [ 29.780242911392406, -1.461826896551724 ], [ 29.780512405063291, -1.461826896551724 ], [ 29.780512405063291, -1.461287931034483 ], [ 29.780242911392406, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24379, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.461287931034483 ], [ 29.780512405063291, -1.461557413793104 ], [ 29.780781898734176, -1.461557413793104 ], [ 29.780781898734176, -1.461287931034483 ], [ 29.780512405063291, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24380, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.461287931034483 ], [ 29.781051392405065, -1.461826896551724 ], [ 29.78132088607595, -1.461826896551724 ], [ 29.78132088607595, -1.461287931034483 ], [ 29.781051392405065, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24381, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.461287931034483 ], [ 29.78132088607595, -1.461557413793104 ], [ 29.781590379746834, -1.461557413793104 ], [ 29.781590379746834, -1.461287931034483 ], [ 29.78132088607595, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24382, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.461287931034483 ], [ 29.781590379746834, -1.461557413793104 ], [ 29.781859873417723, -1.461557413793104 ], [ 29.781859873417723, -1.461287931034483 ], [ 29.781590379746834, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24383, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.461287931034483 ], [ 29.781859873417723, -1.461826896551724 ], [ 29.782129367088608, -1.461826896551724 ], [ 29.782129367088608, -1.461287931034483 ], [ 29.781859873417723, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24384, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.461287931034483 ], [ 29.782129367088608, -1.461557413793104 ], [ 29.782398860759493, -1.461557413793104 ], [ 29.782398860759493, -1.461287931034483 ], [ 29.782129367088608, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24385, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.461287931034483 ], [ 29.782398860759493, -1.461557413793104 ], [ 29.782668354430381, -1.461557413793104 ], [ 29.782668354430381, -1.461287931034483 ], [ 29.782398860759493, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24386, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.461287931034483 ], [ 29.782937848101266, -1.461557413793104 ], [ 29.783207341772151, -1.461557413793104 ], [ 29.783207341772151, -1.461287931034483 ], [ 29.782937848101266, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24387, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.461287931034483 ], [ 29.783207341772151, -1.461557413793104 ], [ 29.783476835443039, -1.461557413793104 ], [ 29.783476835443039, -1.461287931034483 ], [ 29.783207341772151, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24388, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.461287931034483 ], [ 29.783476835443039, -1.461557413793104 ], [ 29.784015822784809, -1.461557413793104 ], [ 29.784015822784809, -1.461287931034483 ], [ 29.783476835443039, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24389, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.461287931034483 ], [ 29.784015822784809, -1.461557413793104 ], [ 29.784285316455698, -1.461557413793104 ], [ 29.784285316455698, -1.461287931034483 ], [ 29.784015822784809, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24390, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.461287931034483 ], [ 29.784285316455698, -1.461557413793104 ], [ 29.784554810126583, -1.461557413793104 ], [ 29.784554810126583, -1.461287931034483 ], [ 29.784285316455698, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24391, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.461287931034483 ], [ 29.784554810126583, -1.461557413793104 ], [ 29.784824303797468, -1.461557413793104 ], [ 29.784824303797468, -1.461287931034483 ], [ 29.784554810126583, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24392, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.461287931034483 ], [ 29.784824303797468, -1.461557413793104 ], [ 29.785093797468356, -1.461557413793104 ], [ 29.785093797468356, -1.461287931034483 ], [ 29.784824303797468, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24393, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.461287931034483 ], [ 29.785093797468356, -1.461557413793104 ], [ 29.785363291139241, -1.461557413793104 ], [ 29.785363291139241, -1.461287931034483 ], [ 29.785093797468356, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24394, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.461287931034483 ], [ 29.785363291139241, -1.461557413793104 ], [ 29.785632784810126, -1.461557413793104 ], [ 29.785632784810126, -1.461287931034483 ], [ 29.785363291139241, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24395, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.461287931034483 ], [ 29.785632784810126, -1.461557413793104 ], [ 29.785902278481014, -1.461557413793104 ], [ 29.785902278481014, -1.461287931034483 ], [ 29.785632784810126, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24396, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.461287931034483 ], [ 29.785902278481014, -1.461557413793104 ], [ 29.786171772151899, -1.461557413793104 ], [ 29.786171772151899, -1.461287931034483 ], [ 29.785902278481014, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24397, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.461287931034483 ], [ 29.786171772151899, -1.461557413793104 ], [ 29.786441265822784, -1.461557413793104 ], [ 29.786441265822784, -1.461287931034483 ], [ 29.786171772151899, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24398, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.461287931034483 ], [ 29.786441265822784, -1.461557413793104 ], [ 29.786710759493673, -1.461557413793104 ], [ 29.786710759493673, -1.461287931034483 ], [ 29.786441265822784, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24399, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.461287931034483 ], [ 29.786710759493673, -1.461557413793104 ], [ 29.786980253164558, -1.461557413793104 ], [ 29.786980253164558, -1.461287931034483 ], [ 29.786710759493673, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24400, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.461287931034483 ], [ 29.786980253164558, -1.461557413793104 ], [ 29.787249746835442, -1.461557413793104 ], [ 29.787249746835442, -1.461287931034483 ], [ 29.786980253164558, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24401, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.461287931034483 ], [ 29.787249746835442, -1.461557413793104 ], [ 29.787519240506327, -1.461557413793104 ], [ 29.787519240506327, -1.461287931034483 ], [ 29.787249746835442, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24402, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.461287931034483 ], [ 29.787519240506327, -1.461557413793104 ], [ 29.787788734177216, -1.461557413793104 ], [ 29.787788734177216, -1.461287931034483 ], [ 29.787519240506327, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24403, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.461287931034483 ], [ 29.787788734177216, -1.461557413793104 ], [ 29.788058227848101, -1.461557413793104 ], [ 29.788058227848101, -1.461287931034483 ], [ 29.787788734177216, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24404, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.461287931034483 ], [ 29.788058227848101, -1.461557413793104 ], [ 29.788327721518986, -1.461557413793104 ], [ 29.788327721518986, -1.461287931034483 ], [ 29.788058227848101, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24405, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.461287931034483 ], [ 29.788327721518986, -1.461557413793104 ], [ 29.788597215189874, -1.461557413793104 ], [ 29.788597215189874, -1.461287931034483 ], [ 29.788327721518986, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24406, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.461287931034483 ], [ 29.788597215189874, -1.461826896551724 ], [ 29.788866708860759, -1.461826896551724 ], [ 29.788866708860759, -1.461287931034483 ], [ 29.788597215189874, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24407, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.461287931034483 ], [ 29.793717594936709, -1.461557413793104 ], [ 29.793987088607594, -1.461557413793104 ], [ 29.793987088607594, -1.461287931034483 ], [ 29.793717594936709, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24408, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.461287931034483 ], [ 29.793987088607594, -1.461557413793104 ], [ 29.794256582278482, -1.461557413793104 ], [ 29.794256582278482, -1.461287931034483 ], [ 29.793987088607594, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24409, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.461287931034483 ], [ 29.794256582278482, -1.461557413793104 ], [ 29.794526075949367, -1.461557413793104 ], [ 29.794526075949367, -1.461287931034483 ], [ 29.794256582278482, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24410, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.461287931034483 ], [ 29.794526075949367, -1.461557413793104 ], [ 29.794795569620252, -1.461557413793104 ], [ 29.794795569620252, -1.461287931034483 ], [ 29.794526075949367, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24411, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.461287931034483 ], [ 29.795334556962025, -1.461826896551724 ], [ 29.79560405063291, -1.461826896551724 ], [ 29.79560405063291, -1.461287931034483 ], [ 29.795334556962025, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24412, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.461287931034483 ], [ 29.796682025316457, -1.461826896551724 ], [ 29.796951518987342, -1.461826896551724 ], [ 29.796951518987342, -1.461287931034483 ], [ 29.796682025316457, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24413, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.461287931034483 ], [ 29.797221012658227, -1.461557413793104 ], [ 29.797490506329115, -1.461557413793104 ], [ 29.797490506329115, -1.461287931034483 ], [ 29.797221012658227, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24414, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.461287931034483 ], [ 29.797490506329115, -1.461557413793104 ], [ 29.79776, -1.461557413793104 ], [ 29.79776, -1.461287931034483 ], [ 29.797490506329115, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24415, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.461287931034483 ], [ 29.79776, -1.461557413793104 ], [ 29.798029493670885, -1.461557413793104 ], [ 29.798029493670885, -1.461287931034483 ], [ 29.79776, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24416, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.461287931034483 ], [ 29.798029493670885, -1.461557413793104 ], [ 29.798298987341774, -1.461557413793104 ], [ 29.798298987341774, -1.461287931034483 ], [ 29.798029493670885, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24417, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.461287931034483 ], [ 29.798298987341774, -1.461557413793104 ], [ 29.798568481012659, -1.461557413793104 ], [ 29.798568481012659, -1.461287931034483 ], [ 29.798298987341774, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24418, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.461287931034483 ], [ 29.798568481012659, -1.461557413793104 ], [ 29.798837974683543, -1.461557413793104 ], [ 29.798837974683543, -1.461287931034483 ], [ 29.798568481012659, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24419, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.461287931034483 ], [ 29.798837974683543, -1.461557413793104 ], [ 29.799107468354432, -1.461557413793104 ], [ 29.799107468354432, -1.461287931034483 ], [ 29.798837974683543, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24420, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.461287931034483 ], [ 29.799107468354432, -1.461557413793104 ], [ 29.799376962025317, -1.461557413793104 ], [ 29.799376962025317, -1.461287931034483 ], [ 29.799107468354432, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24421, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.461287931034483 ], [ 29.799376962025317, -1.461557413793104 ], [ 29.799646455696202, -1.461557413793104 ], [ 29.799646455696202, -1.461287931034483 ], [ 29.799376962025317, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24422, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.461287931034483 ], [ 29.799646455696202, -1.461557413793104 ], [ 29.79991594936709, -1.461557413793104 ], [ 29.79991594936709, -1.461287931034483 ], [ 29.799646455696202, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24423, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.461287931034483 ], [ 29.79991594936709, -1.461557413793104 ], [ 29.800185443037975, -1.461557413793104 ], [ 29.800185443037975, -1.461287931034483 ], [ 29.79991594936709, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24424, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.461287931034483 ], [ 29.800185443037975, -1.461557413793104 ], [ 29.80045493670886, -1.461557413793104 ], [ 29.80045493670886, -1.461287931034483 ], [ 29.800185443037975, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24425, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.461287931034483 ], [ 29.800724430379748, -1.461557413793104 ], [ 29.800993924050633, -1.461557413793104 ], [ 29.800993924050633, -1.461287931034483 ], [ 29.800724430379748, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24426, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.461287931034483 ], [ 29.800993924050633, -1.461557413793104 ], [ 29.801263417721518, -1.461557413793104 ], [ 29.801263417721518, -1.461287931034483 ], [ 29.800993924050633, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24427, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.461557413793104 ], [ 29.753293544303798, -1.461826896551724 ], [ 29.753563037974683, -1.461826896551724 ], [ 29.753563037974683, -1.461557413793104 ], [ 29.753293544303798, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24428, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.461557413793104 ], [ 29.753563037974683, -1.461826896551724 ], [ 29.753832531645568, -1.461826896551724 ], [ 29.753832531645568, -1.461557413793104 ], [ 29.753563037974683, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24429, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.461557413793104 ], [ 29.753832531645568, -1.461826896551724 ], [ 29.754102025316456, -1.461826896551724 ], [ 29.754102025316456, -1.461557413793104 ], [ 29.753832531645568, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24430, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.461557413793104 ], [ 29.754102025316456, -1.462365862068966 ], [ 29.754371518987341, -1.462365862068966 ], [ 29.754371518987341, -1.461557413793104 ], [ 29.754102025316456, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24431, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.461557413793104 ], [ 29.754371518987341, -1.462365862068966 ], [ 29.754641012658226, -1.462365862068966 ], [ 29.754641012658226, -1.461557413793104 ], [ 29.754371518987341, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24432, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.461557413793104 ], [ 29.754910506329114, -1.462096379310345 ], [ 29.75518, -1.462096379310345 ], [ 29.75518, -1.461557413793104 ], [ 29.754910506329114, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24433, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.461557413793104 ], [ 29.75518, -1.462096379310345 ], [ 29.755449493670884, -1.462096379310345 ], [ 29.755449493670884, -1.461557413793104 ], [ 29.75518, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24434, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.461557413793104 ], [ 29.755718987341773, -1.461826896551724 ], [ 29.755988481012658, -1.461826896551724 ], [ 29.755988481012658, -1.461557413793104 ], [ 29.755718987341773, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24435, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.461557413793104 ], [ 29.756796962025316, -1.461826896551724 ], [ 29.757066455696201, -1.461826896551724 ], [ 29.757066455696201, -1.461557413793104 ], [ 29.756796962025316, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24436, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.461557413793104 ], [ 29.757066455696201, -1.461826896551724 ], [ 29.757335949367089, -1.461826896551724 ], [ 29.757335949367089, -1.461557413793104 ], [ 29.757066455696201, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24437, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.461557413793104 ], [ 29.757605443037974, -1.461826896551724 ], [ 29.757874936708859, -1.461826896551724 ], [ 29.757874936708859, -1.461557413793104 ], [ 29.757605443037974, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24438, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.461557413793104 ], [ 29.757874936708859, -1.461826896551724 ], [ 29.758144430379748, -1.461826896551724 ], [ 29.758144430379748, -1.461557413793104 ], [ 29.757874936708859, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24439, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.461557413793104 ], [ 29.758144430379748, -1.461826896551724 ], [ 29.758413924050632, -1.461826896551724 ], [ 29.758413924050632, -1.461557413793104 ], [ 29.758144430379748, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24440, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.461557413793104 ], [ 29.758683417721517, -1.461826896551724 ], [ 29.758952911392406, -1.461826896551724 ], [ 29.758952911392406, -1.461557413793104 ], [ 29.758683417721517, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24441, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.461557413793104 ], [ 29.758952911392406, -1.461826896551724 ], [ 29.759222405063291, -1.461826896551724 ], [ 29.759222405063291, -1.461557413793104 ], [ 29.758952911392406, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24442, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.461557413793104 ], [ 29.759222405063291, -1.461826896551724 ], [ 29.759491898734176, -1.461826896551724 ], [ 29.759491898734176, -1.461557413793104 ], [ 29.759222405063291, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24443, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.461557413793104 ], [ 29.759491898734176, -1.461826896551724 ], [ 29.759761392405064, -1.461826896551724 ], [ 29.759761392405064, -1.461557413793104 ], [ 29.759491898734176, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24444, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.461557413793104 ], [ 29.759761392405064, -1.461826896551724 ], [ 29.760030886075949, -1.461826896551724 ], [ 29.760030886075949, -1.461557413793104 ], [ 29.759761392405064, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24445, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.461557413793104 ], [ 29.760030886075949, -1.461826896551724 ], [ 29.760300379746834, -1.461826896551724 ], [ 29.760300379746834, -1.461557413793104 ], [ 29.760030886075949, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24446, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.461557413793104 ], [ 29.760300379746834, -1.461826896551724 ], [ 29.760569873417722, -1.461826896551724 ], [ 29.760569873417722, -1.461557413793104 ], [ 29.760300379746834, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24447, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.461557413793104 ], [ 29.760839367088607, -1.461826896551724 ], [ 29.761108860759492, -1.461826896551724 ], [ 29.761108860759492, -1.461557413793104 ], [ 29.760839367088607, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24448, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.461557413793104 ], [ 29.761108860759492, -1.461826896551724 ], [ 29.761378354430381, -1.461826896551724 ], [ 29.761378354430381, -1.461557413793104 ], [ 29.761108860759492, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24449, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.461557413793104 ], [ 29.761378354430381, -1.461826896551724 ], [ 29.761647848101266, -1.461826896551724 ], [ 29.761647848101266, -1.461557413793104 ], [ 29.761378354430381, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24450, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.461557413793104 ], [ 29.761647848101266, -1.461826896551724 ], [ 29.761917341772151, -1.461826896551724 ], [ 29.761917341772151, -1.461557413793104 ], [ 29.761647848101266, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24451, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.461557413793104 ], [ 29.761917341772151, -1.461826896551724 ], [ 29.762186835443039, -1.461826896551724 ], [ 29.762186835443039, -1.461557413793104 ], [ 29.761917341772151, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24452, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.461557413793104 ], [ 29.762186835443039, -1.461826896551724 ], [ 29.762456329113924, -1.461826896551724 ], [ 29.762456329113924, -1.461557413793104 ], [ 29.762186835443039, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24453, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.461557413793104 ], [ 29.762456329113924, -1.461826896551724 ], [ 29.762725822784809, -1.461826896551724 ], [ 29.762725822784809, -1.461557413793104 ], [ 29.762456329113924, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24454, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.461557413793104 ], [ 29.762725822784809, -1.461826896551724 ], [ 29.762995316455697, -1.461826896551724 ], [ 29.762995316455697, -1.461557413793104 ], [ 29.762725822784809, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24455, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.461557413793104 ], [ 29.762995316455697, -1.461826896551724 ], [ 29.763264810126582, -1.461826896551724 ], [ 29.763264810126582, -1.461557413793104 ], [ 29.762995316455697, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24456, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.461557413793104 ], [ 29.763264810126582, -1.461826896551724 ], [ 29.763534303797467, -1.461826896551724 ], [ 29.763534303797467, -1.461557413793104 ], [ 29.763264810126582, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24457, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.461557413793104 ], [ 29.763534303797467, -1.461826896551724 ], [ 29.763803797468356, -1.461826896551724 ], [ 29.763803797468356, -1.461557413793104 ], [ 29.763534303797467, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24458, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.461557413793104 ], [ 29.763803797468356, -1.461826896551724 ], [ 29.76407329113924, -1.461826896551724 ], [ 29.76407329113924, -1.461557413793104 ], [ 29.763803797468356, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24459, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.461557413793104 ], [ 29.76407329113924, -1.461826896551724 ], [ 29.764342784810125, -1.461826896551724 ], [ 29.764342784810125, -1.461557413793104 ], [ 29.76407329113924, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24460, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.461557413793104 ], [ 29.764342784810125, -1.461826896551724 ], [ 29.764612278481014, -1.461826896551724 ], [ 29.764612278481014, -1.461557413793104 ], [ 29.764342784810125, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24461, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.461557413793104 ], [ 29.764612278481014, -1.461826896551724 ], [ 29.764881772151899, -1.461826896551724 ], [ 29.764881772151899, -1.461557413793104 ], [ 29.764612278481014, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24462, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.461557413793104 ], [ 29.764881772151899, -1.461826896551724 ], [ 29.765151265822784, -1.461826896551724 ], [ 29.765151265822784, -1.461557413793104 ], [ 29.764881772151899, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24463, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.461557413793104 ], [ 29.765151265822784, -1.461826896551724 ], [ 29.765420759493672, -1.461826896551724 ], [ 29.765420759493672, -1.461557413793104 ], [ 29.765151265822784, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24464, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.461557413793104 ], [ 29.765420759493672, -1.461826896551724 ], [ 29.765959746835442, -1.461826896551724 ], [ 29.765959746835442, -1.461557413793104 ], [ 29.765420759493672, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24465, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.461557413793104 ], [ 29.765959746835442, -1.461826896551724 ], [ 29.766229240506327, -1.461826896551724 ], [ 29.766229240506327, -1.461557413793104 ], [ 29.765959746835442, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24466, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.461557413793104 ], [ 29.766229240506327, -1.461826896551724 ], [ 29.766498734177215, -1.461826896551724 ], [ 29.766498734177215, -1.461557413793104 ], [ 29.766229240506327, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24467, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.461557413793104 ], [ 29.766498734177215, -1.461826896551724 ], [ 29.7667682278481, -1.461826896551724 ], [ 29.7667682278481, -1.461557413793104 ], [ 29.766498734177215, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24468, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.461557413793104 ], [ 29.7667682278481, -1.461826896551724 ], [ 29.767037721518985, -1.461826896551724 ], [ 29.767037721518985, -1.461557413793104 ], [ 29.7667682278481, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24469, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.461557413793104 ], [ 29.767037721518985, -1.461826896551724 ], [ 29.767307215189874, -1.461826896551724 ], [ 29.767307215189874, -1.461557413793104 ], [ 29.767037721518985, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24470, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.461557413793104 ], [ 29.767307215189874, -1.461826896551724 ], [ 29.767576708860759, -1.461826896551724 ], [ 29.767576708860759, -1.461557413793104 ], [ 29.767307215189874, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24471, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.461557413793104 ], [ 29.767576708860759, -1.461826896551724 ], [ 29.767846202531643, -1.461826896551724 ], [ 29.767846202531643, -1.461557413793104 ], [ 29.767576708860759, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24472, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.461557413793104 ], [ 29.767846202531643, -1.461826896551724 ], [ 29.768385189873417, -1.461826896551724 ], [ 29.768385189873417, -1.461557413793104 ], [ 29.767846202531643, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24473, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.461557413793104 ], [ 29.768385189873417, -1.461826896551724 ], [ 29.768654683544302, -1.461826896551724 ], [ 29.768654683544302, -1.461557413793104 ], [ 29.768385189873417, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24474, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.461557413793104 ], [ 29.768654683544302, -1.461826896551724 ], [ 29.76892417721519, -1.461826896551724 ], [ 29.76892417721519, -1.461557413793104 ], [ 29.768654683544302, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24475, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.461557413793104 ], [ 29.76892417721519, -1.461826896551724 ], [ 29.769732658227849, -1.461826896551724 ], [ 29.769732658227849, -1.461557413793104 ], [ 29.76892417721519, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24476, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.461557413793104 ], [ 29.769732658227849, -1.461826896551724 ], [ 29.770002151898733, -1.461826896551724 ], [ 29.770002151898733, -1.461557413793104 ], [ 29.769732658227849, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24477, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.461557413793104 ], [ 29.770002151898733, -1.461826896551724 ], [ 29.770271645569618, -1.461826896551724 ], [ 29.770271645569618, -1.461557413793104 ], [ 29.770002151898733, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24478, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.461557413793104 ], [ 29.770271645569618, -1.461826896551724 ], [ 29.770541139240507, -1.461826896551724 ], [ 29.770541139240507, -1.461557413793104 ], [ 29.770271645569618, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24479, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.461557413793104 ], [ 29.770541139240507, -1.461826896551724 ], [ 29.770810632911392, -1.461826896551724 ], [ 29.770810632911392, -1.461557413793104 ], [ 29.770541139240507, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24480, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.461557413793104 ], [ 29.770810632911392, -1.461826896551724 ], [ 29.771080126582277, -1.461826896551724 ], [ 29.771080126582277, -1.461557413793104 ], [ 29.770810632911392, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24481, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.461557413793104 ], [ 29.771080126582277, -1.461826896551724 ], [ 29.771349620253165, -1.461826896551724 ], [ 29.771349620253165, -1.461557413793104 ], [ 29.771080126582277, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24482, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.461557413793104 ], [ 29.771349620253165, -1.461826896551724 ], [ 29.77161911392405, -1.461826896551724 ], [ 29.77161911392405, -1.461557413793104 ], [ 29.771349620253165, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24483, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.461557413793104 ], [ 29.77161911392405, -1.461826896551724 ], [ 29.771888607594935, -1.461826896551724 ], [ 29.771888607594935, -1.461557413793104 ], [ 29.77161911392405, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24484, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.461557413793104 ], [ 29.771888607594935, -1.461826896551724 ], [ 29.772158101265823, -1.461826896551724 ], [ 29.772158101265823, -1.461557413793104 ], [ 29.771888607594935, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24485, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.461557413793104 ], [ 29.772158101265823, -1.461826896551724 ], [ 29.772427594936708, -1.461826896551724 ], [ 29.772427594936708, -1.461557413793104 ], [ 29.772158101265823, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24486, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.461557413793104 ], [ 29.772427594936708, -1.461826896551724 ], [ 29.772697088607593, -1.461826896551724 ], [ 29.772697088607593, -1.461557413793104 ], [ 29.772427594936708, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24487, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.461557413793104 ], [ 29.772697088607593, -1.461826896551724 ], [ 29.772966582278482, -1.461826896551724 ], [ 29.772966582278482, -1.461557413793104 ], [ 29.772697088607593, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24488, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.461557413793104 ], [ 29.772966582278482, -1.461826896551724 ], [ 29.773236075949367, -1.461826896551724 ], [ 29.773236075949367, -1.461557413793104 ], [ 29.772966582278482, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24489, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.461557413793104 ], [ 29.773236075949367, -1.461826896551724 ], [ 29.773505569620252, -1.461826896551724 ], [ 29.773505569620252, -1.461557413793104 ], [ 29.773236075949367, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24490, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.461557413793104 ], [ 29.773505569620252, -1.461826896551724 ], [ 29.77377506329114, -1.461826896551724 ], [ 29.77377506329114, -1.461557413793104 ], [ 29.773505569620252, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24491, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.461557413793104 ], [ 29.77377506329114, -1.461826896551724 ], [ 29.774044556962025, -1.461826896551724 ], [ 29.774044556962025, -1.461557413793104 ], [ 29.77377506329114, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24492, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.461557413793104 ], [ 29.774044556962025, -1.461826896551724 ], [ 29.77431405063291, -1.461826896551724 ], [ 29.77431405063291, -1.461557413793104 ], [ 29.774044556962025, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24493, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.461557413793104 ], [ 29.77431405063291, -1.461826896551724 ], [ 29.774853037974683, -1.461826896551724 ], [ 29.774853037974683, -1.461557413793104 ], [ 29.77431405063291, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24494, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.461557413793104 ], [ 29.774853037974683, -1.461826896551724 ], [ 29.775122531645568, -1.461826896551724 ], [ 29.775122531645568, -1.461557413793104 ], [ 29.774853037974683, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24495, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.461557413793104 ], [ 29.775122531645568, -1.461826896551724 ], [ 29.775392025316457, -1.461826896551724 ], [ 29.775392025316457, -1.461557413793104 ], [ 29.775122531645568, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24496, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.461557413793104 ], [ 29.775392025316457, -1.461826896551724 ], [ 29.775661518987341, -1.461826896551724 ], [ 29.775661518987341, -1.461557413793104 ], [ 29.775392025316457, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24497, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.461557413793104 ], [ 29.775661518987341, -1.462096379310345 ], [ 29.775931012658226, -1.462096379310345 ], [ 29.775931012658226, -1.461557413793104 ], [ 29.775661518987341, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24498, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.461557413793104 ], [ 29.775931012658226, -1.461826896551724 ], [ 29.776200506329115, -1.461826896551724 ], [ 29.776200506329115, -1.461557413793104 ], [ 29.775931012658226, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24499, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.461557413793104 ], [ 29.776200506329115, -1.461826896551724 ], [ 29.77647, -1.461826896551724 ], [ 29.77647, -1.461557413793104 ], [ 29.776200506329115, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24500, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.461557413793104 ], [ 29.77647, -1.461826896551724 ], [ 29.776739493670885, -1.461826896551724 ], [ 29.776739493670885, -1.461557413793104 ], [ 29.77647, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24501, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.461557413793104 ], [ 29.777008987341773, -1.461826896551724 ], [ 29.777278481012658, -1.461826896551724 ], [ 29.777278481012658, -1.461557413793104 ], [ 29.777008987341773, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24502, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.461557413793104 ], [ 29.777278481012658, -1.461826896551724 ], [ 29.777547974683543, -1.461826896551724 ], [ 29.777547974683543, -1.461557413793104 ], [ 29.777278481012658, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24503, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.461557413793104 ], [ 29.777547974683543, -1.462096379310345 ], [ 29.777817468354431, -1.462096379310345 ], [ 29.777817468354431, -1.461557413793104 ], [ 29.777547974683543, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24504, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.461557413793104 ], [ 29.777817468354431, -1.461826896551724 ], [ 29.778086962025316, -1.461826896551724 ], [ 29.778086962025316, -1.461557413793104 ], [ 29.777817468354431, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24505, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.461557413793104 ], [ 29.778086962025316, -1.461826896551724 ], [ 29.778356455696201, -1.461826896551724 ], [ 29.778356455696201, -1.461557413793104 ], [ 29.778086962025316, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24506, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.461557413793104 ], [ 29.778356455696201, -1.462096379310345 ], [ 29.77862594936709, -1.462096379310345 ], [ 29.77862594936709, -1.461557413793104 ], [ 29.778356455696201, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24507, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.461557413793104 ], [ 29.77916493670886, -1.462365862068966 ], [ 29.779434430379748, -1.462365862068966 ], [ 29.779434430379748, -1.461557413793104 ], [ 29.77916493670886, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24508, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.461557413793104 ], [ 29.779973417721518, -1.462096379310345 ], [ 29.780242911392406, -1.462096379310345 ], [ 29.780242911392406, -1.461557413793104 ], [ 29.779973417721518, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24509, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.461557413793104 ], [ 29.780512405063291, -1.462365862068966 ], [ 29.780781898734176, -1.462365862068966 ], [ 29.780781898734176, -1.461557413793104 ], [ 29.780512405063291, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24510, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.461557413793104 ], [ 29.780781898734176, -1.462096379310345 ], [ 29.781051392405065, -1.462096379310345 ], [ 29.781051392405065, -1.461557413793104 ], [ 29.780781898734176, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24511, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.461557413793104 ], [ 29.78132088607595, -1.462096379310345 ], [ 29.781590379746834, -1.462096379310345 ], [ 29.781590379746834, -1.461557413793104 ], [ 29.78132088607595, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24512, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.461557413793104 ], [ 29.781590379746834, -1.461826896551724 ], [ 29.781859873417723, -1.461826896551724 ], [ 29.781859873417723, -1.461557413793104 ], [ 29.781590379746834, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24513, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.461557413793104 ], [ 29.782129367088608, -1.462096379310345 ], [ 29.782398860759493, -1.462096379310345 ], [ 29.782398860759493, -1.461557413793104 ], [ 29.782129367088608, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24514, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.461557413793104 ], [ 29.782398860759493, -1.461826896551724 ], [ 29.782668354430381, -1.461826896551724 ], [ 29.782668354430381, -1.461557413793104 ], [ 29.782398860759493, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24515, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.461557413793104 ], [ 29.782668354430381, -1.461826896551724 ], [ 29.782937848101266, -1.461826896551724 ], [ 29.782937848101266, -1.461557413793104 ], [ 29.782668354430381, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24516, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.461557413793104 ], [ 29.782937848101266, -1.462096379310345 ], [ 29.783207341772151, -1.462096379310345 ], [ 29.783207341772151, -1.461557413793104 ], [ 29.782937848101266, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24517, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.461557413793104 ], [ 29.783207341772151, -1.461826896551724 ], [ 29.783476835443039, -1.461826896551724 ], [ 29.783476835443039, -1.461557413793104 ], [ 29.783207341772151, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24518, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.461557413793104 ], [ 29.783476835443039, -1.461826896551724 ], [ 29.784015822784809, -1.461826896551724 ], [ 29.784015822784809, -1.461557413793104 ], [ 29.783476835443039, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24519, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.461557413793104 ], [ 29.784015822784809, -1.462096379310345 ], [ 29.784285316455698, -1.462096379310345 ], [ 29.784285316455698, -1.461557413793104 ], [ 29.784015822784809, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24520, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.461557413793104 ], [ 29.784285316455698, -1.461826896551724 ], [ 29.784554810126583, -1.461826896551724 ], [ 29.784554810126583, -1.461557413793104 ], [ 29.784285316455698, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24521, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.461557413793104 ], [ 29.784554810126583, -1.461826896551724 ], [ 29.784824303797468, -1.461826896551724 ], [ 29.784824303797468, -1.461557413793104 ], [ 29.784554810126583, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24522, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.461557413793104 ], [ 29.784824303797468, -1.461826896551724 ], [ 29.785093797468356, -1.461826896551724 ], [ 29.785093797468356, -1.461557413793104 ], [ 29.784824303797468, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24523, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.461557413793104 ], [ 29.785093797468356, -1.461826896551724 ], [ 29.785363291139241, -1.461826896551724 ], [ 29.785363291139241, -1.461557413793104 ], [ 29.785093797468356, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24524, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.461557413793104 ], [ 29.785363291139241, -1.461826896551724 ], [ 29.785632784810126, -1.461826896551724 ], [ 29.785632784810126, -1.461557413793104 ], [ 29.785363291139241, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24525, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.461557413793104 ], [ 29.785632784810126, -1.461826896551724 ], [ 29.785902278481014, -1.461826896551724 ], [ 29.785902278481014, -1.461557413793104 ], [ 29.785632784810126, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24526, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.461557413793104 ], [ 29.785902278481014, -1.461826896551724 ], [ 29.786171772151899, -1.461826896551724 ], [ 29.786171772151899, -1.461557413793104 ], [ 29.785902278481014, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24527, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.461557413793104 ], [ 29.786171772151899, -1.461826896551724 ], [ 29.786441265822784, -1.461826896551724 ], [ 29.786441265822784, -1.461557413793104 ], [ 29.786171772151899, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24528, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.461557413793104 ], [ 29.786441265822784, -1.461826896551724 ], [ 29.786710759493673, -1.461826896551724 ], [ 29.786710759493673, -1.461557413793104 ], [ 29.786441265822784, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24529, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.461557413793104 ], [ 29.786710759493673, -1.461826896551724 ], [ 29.786980253164558, -1.461826896551724 ], [ 29.786980253164558, -1.461557413793104 ], [ 29.786710759493673, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24530, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.461557413793104 ], [ 29.786980253164558, -1.461826896551724 ], [ 29.787249746835442, -1.461826896551724 ], [ 29.787249746835442, -1.461557413793104 ], [ 29.786980253164558, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24531, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.461557413793104 ], [ 29.787249746835442, -1.461826896551724 ], [ 29.787519240506327, -1.461826896551724 ], [ 29.787519240506327, -1.461557413793104 ], [ 29.787249746835442, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24532, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.461557413793104 ], [ 29.787519240506327, -1.461826896551724 ], [ 29.787788734177216, -1.461826896551724 ], [ 29.787788734177216, -1.461557413793104 ], [ 29.787519240506327, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24533, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.461557413793104 ], [ 29.787788734177216, -1.461826896551724 ], [ 29.788058227848101, -1.461826896551724 ], [ 29.788058227848101, -1.461557413793104 ], [ 29.787788734177216, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24534, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.461557413793104 ], [ 29.788058227848101, -1.461826896551724 ], [ 29.788327721518986, -1.461826896551724 ], [ 29.788327721518986, -1.461557413793104 ], [ 29.788058227848101, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24535, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.461557413793104 ], [ 29.788327721518986, -1.461826896551724 ], [ 29.788597215189874, -1.461826896551724 ], [ 29.788597215189874, -1.461557413793104 ], [ 29.788327721518986, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24536, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.461557413793104 ], [ 29.793448101265824, -1.461826896551724 ], [ 29.793717594936709, -1.461826896551724 ], [ 29.793717594936709, -1.461557413793104 ], [ 29.793448101265824, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24537, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.461557413793104 ], [ 29.793717594936709, -1.461826896551724 ], [ 29.793987088607594, -1.461826896551724 ], [ 29.793987088607594, -1.461557413793104 ], [ 29.793717594936709, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24538, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.461557413793104 ], [ 29.793987088607594, -1.461826896551724 ], [ 29.794256582278482, -1.461826896551724 ], [ 29.794256582278482, -1.461557413793104 ], [ 29.793987088607594, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24539, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.461557413793104 ], [ 29.794256582278482, -1.461826896551724 ], [ 29.794526075949367, -1.461826896551724 ], [ 29.794526075949367, -1.461557413793104 ], [ 29.794256582278482, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24540, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.461557413793104 ], [ 29.794526075949367, -1.461826896551724 ], [ 29.794795569620252, -1.461826896551724 ], [ 29.794795569620252, -1.461557413793104 ], [ 29.794526075949367, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24541, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.461557413793104 ], [ 29.794795569620252, -1.461826896551724 ], [ 29.79506506329114, -1.461826896551724 ], [ 29.79506506329114, -1.461557413793104 ], [ 29.794795569620252, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24542, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.461557413793104 ], [ 29.79506506329114, -1.461826896551724 ], [ 29.795334556962025, -1.461826896551724 ], [ 29.795334556962025, -1.461557413793104 ], [ 29.79506506329114, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24543, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.461557413793104 ], [ 29.79560405063291, -1.461826896551724 ], [ 29.795873544303799, -1.461826896551724 ], [ 29.795873544303799, -1.461557413793104 ], [ 29.79560405063291, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24544, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.461557413793104 ], [ 29.795873544303799, -1.461826896551724 ], [ 29.796143037974684, -1.461826896551724 ], [ 29.796143037974684, -1.461557413793104 ], [ 29.795873544303799, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24545, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.461557413793104 ], [ 29.796412531645569, -1.462096379310345 ], [ 29.796682025316457, -1.462096379310345 ], [ 29.796682025316457, -1.461557413793104 ], [ 29.796412531645569, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24546, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.461557413793104 ], [ 29.797221012658227, -1.461826896551724 ], [ 29.797490506329115, -1.461826896551724 ], [ 29.797490506329115, -1.461557413793104 ], [ 29.797221012658227, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24547, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.461557413793104 ], [ 29.797490506329115, -1.461826896551724 ], [ 29.79776, -1.461826896551724 ], [ 29.79776, -1.461557413793104 ], [ 29.797490506329115, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24548, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.461557413793104 ], [ 29.79776, -1.462096379310345 ], [ 29.798029493670885, -1.462096379310345 ], [ 29.798029493670885, -1.461557413793104 ], [ 29.79776, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24549, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.461557413793104 ], [ 29.798029493670885, -1.461826896551724 ], [ 29.798298987341774, -1.461826896551724 ], [ 29.798298987341774, -1.461557413793104 ], [ 29.798029493670885, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24550, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.461557413793104 ], [ 29.798298987341774, -1.461826896551724 ], [ 29.798568481012659, -1.461826896551724 ], [ 29.798568481012659, -1.461557413793104 ], [ 29.798298987341774, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24551, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.461557413793104 ], [ 29.798568481012659, -1.461826896551724 ], [ 29.798837974683543, -1.461826896551724 ], [ 29.798837974683543, -1.461557413793104 ], [ 29.798568481012659, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24552, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.461557413793104 ], [ 29.798837974683543, -1.461826896551724 ], [ 29.799107468354432, -1.461826896551724 ], [ 29.799107468354432, -1.461557413793104 ], [ 29.798837974683543, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24553, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.461557413793104 ], [ 29.799107468354432, -1.461826896551724 ], [ 29.799376962025317, -1.461826896551724 ], [ 29.799376962025317, -1.461557413793104 ], [ 29.799107468354432, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24554, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.461557413793104 ], [ 29.799376962025317, -1.461826896551724 ], [ 29.799646455696202, -1.461826896551724 ], [ 29.799646455696202, -1.461557413793104 ], [ 29.799376962025317, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24555, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.461557413793104 ], [ 29.799646455696202, -1.461826896551724 ], [ 29.79991594936709, -1.461826896551724 ], [ 29.79991594936709, -1.461557413793104 ], [ 29.799646455696202, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24556, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.461557413793104 ], [ 29.79991594936709, -1.461826896551724 ], [ 29.800185443037975, -1.461826896551724 ], [ 29.800185443037975, -1.461557413793104 ], [ 29.79991594936709, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24557, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.461557413793104 ], [ 29.800185443037975, -1.461826896551724 ], [ 29.80045493670886, -1.461826896551724 ], [ 29.80045493670886, -1.461557413793104 ], [ 29.800185443037975, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24558, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.461557413793104 ], [ 29.80045493670886, -1.461826896551724 ], [ 29.800724430379748, -1.461826896551724 ], [ 29.800724430379748, -1.461557413793104 ], [ 29.80045493670886, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24559, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.461557413793104 ], [ 29.800724430379748, -1.461826896551724 ], [ 29.800993924050633, -1.461826896551724 ], [ 29.800993924050633, -1.461557413793104 ], [ 29.800724430379748, -1.461557413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24560, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.461287931034483 ], [ 29.801532911392407, -1.461287931034483 ], [ 29.801532911392407, -1.461826896551724 ], [ 29.800993924050633, -1.461826896551724 ], [ 29.800993924050633, -1.461557413793104 ], [ 29.801263417721518, -1.461557413793104 ], [ 29.801263417721518, -1.461287931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24561, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753293544303798, -1.461826896551724 ], [ 29.753293544303798, -1.463174310344828 ], [ 29.753563037974683, -1.463174310344828 ], [ 29.753563037974683, -1.461826896551724 ], [ 29.753293544303798, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24562, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.461826896551724 ], [ 29.753563037974683, -1.462904827586207 ], [ 29.753832531645568, -1.462904827586207 ], [ 29.753832531645568, -1.461826896551724 ], [ 29.753563037974683, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.461826896551724 ], [ 29.753832531645568, -1.462904827586207 ], [ 29.754102025316456, -1.462904827586207 ], [ 29.754102025316456, -1.461826896551724 ], [ 29.753832531645568, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24564, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.461826896551724 ], [ 29.754641012658226, -1.462365862068966 ], [ 29.754910506329114, -1.462365862068966 ], [ 29.754910506329114, -1.461826896551724 ], [ 29.754641012658226, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24565, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.461826896551724 ], [ 29.755449493670884, -1.462096379310345 ], [ 29.755718987341773, -1.462096379310345 ], [ 29.755718987341773, -1.461826896551724 ], [ 29.755449493670884, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24566, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.461826896551724 ], [ 29.755718987341773, -1.462096379310345 ], [ 29.755988481012658, -1.462096379310345 ], [ 29.755988481012658, -1.461826896551724 ], [ 29.755718987341773, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24567, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.461826896551724 ], [ 29.755988481012658, -1.462096379310345 ], [ 29.756257974683542, -1.462096379310345 ], [ 29.756257974683542, -1.461826896551724 ], [ 29.755988481012658, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24568, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.461826896551724 ], [ 29.756257974683542, -1.462096379310345 ], [ 29.756796962025316, -1.462096379310345 ], [ 29.756796962025316, -1.461826896551724 ], [ 29.756257974683542, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24569, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.461826896551724 ], [ 29.756796962025316, -1.462096379310345 ], [ 29.757066455696201, -1.462096379310345 ], [ 29.757066455696201, -1.461826896551724 ], [ 29.756796962025316, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24570, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.461826896551724 ], [ 29.757066455696201, -1.462096379310345 ], [ 29.757335949367089, -1.462096379310345 ], [ 29.757335949367089, -1.461826896551724 ], [ 29.757066455696201, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24571, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.461826896551724 ], [ 29.757335949367089, -1.462365862068966 ], [ 29.757605443037974, -1.462365862068966 ], [ 29.757605443037974, -1.461826896551724 ], [ 29.757335949367089, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24572, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.461826896551724 ], [ 29.757605443037974, -1.462096379310345 ], [ 29.757874936708859, -1.462096379310345 ], [ 29.757874936708859, -1.461826896551724 ], [ 29.757605443037974, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24573, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.461826896551724 ], [ 29.757874936708859, -1.462096379310345 ], [ 29.758144430379748, -1.462096379310345 ], [ 29.758144430379748, -1.461826896551724 ], [ 29.757874936708859, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24574, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.461826896551724 ], [ 29.758144430379748, -1.462096379310345 ], [ 29.758413924050632, -1.462096379310345 ], [ 29.758413924050632, -1.461826896551724 ], [ 29.758144430379748, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24575, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.461826896551724 ], [ 29.758413924050632, -1.462096379310345 ], [ 29.758683417721517, -1.462096379310345 ], [ 29.758683417721517, -1.461826896551724 ], [ 29.758413924050632, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24576, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.461826896551724 ], [ 29.758683417721517, -1.462096379310345 ], [ 29.758952911392406, -1.462096379310345 ], [ 29.758952911392406, -1.461826896551724 ], [ 29.758683417721517, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24577, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.461826896551724 ], [ 29.758952911392406, -1.462096379310345 ], [ 29.759222405063291, -1.462096379310345 ], [ 29.759222405063291, -1.461826896551724 ], [ 29.758952911392406, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24578, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.461826896551724 ], [ 29.759222405063291, -1.462096379310345 ], [ 29.759491898734176, -1.462096379310345 ], [ 29.759491898734176, -1.461826896551724 ], [ 29.759222405063291, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24579, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.461826896551724 ], [ 29.759491898734176, -1.462096379310345 ], [ 29.759761392405064, -1.462096379310345 ], [ 29.759761392405064, -1.461826896551724 ], [ 29.759491898734176, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24580, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.461826896551724 ], [ 29.759761392405064, -1.462096379310345 ], [ 29.760030886075949, -1.462096379310345 ], [ 29.760030886075949, -1.461826896551724 ], [ 29.759761392405064, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24581, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.461826896551724 ], [ 29.760030886075949, -1.462096379310345 ], [ 29.760300379746834, -1.462096379310345 ], [ 29.760300379746834, -1.461826896551724 ], [ 29.760030886075949, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24582, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.461826896551724 ], [ 29.760300379746834, -1.462096379310345 ], [ 29.760569873417722, -1.462096379310345 ], [ 29.760569873417722, -1.461826896551724 ], [ 29.760300379746834, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24583, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.461826896551724 ], [ 29.760569873417722, -1.462096379310345 ], [ 29.760839367088607, -1.462096379310345 ], [ 29.760839367088607, -1.461826896551724 ], [ 29.760569873417722, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24584, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.461826896551724 ], [ 29.760839367088607, -1.462365862068966 ], [ 29.761108860759492, -1.462365862068966 ], [ 29.761108860759492, -1.461826896551724 ], [ 29.760839367088607, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24585, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.461826896551724 ], [ 29.761108860759492, -1.462096379310345 ], [ 29.761378354430381, -1.462096379310345 ], [ 29.761378354430381, -1.461826896551724 ], [ 29.761108860759492, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24586, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.461826896551724 ], [ 29.761378354430381, -1.462096379310345 ], [ 29.761647848101266, -1.462096379310345 ], [ 29.761647848101266, -1.461826896551724 ], [ 29.761378354430381, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24587, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.461826896551724 ], [ 29.761647848101266, -1.462096379310345 ], [ 29.761917341772151, -1.462096379310345 ], [ 29.761917341772151, -1.461826896551724 ], [ 29.761647848101266, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24588, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.461826896551724 ], [ 29.761917341772151, -1.462096379310345 ], [ 29.762186835443039, -1.462096379310345 ], [ 29.762186835443039, -1.461826896551724 ], [ 29.761917341772151, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24589, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.461826896551724 ], [ 29.762186835443039, -1.462096379310345 ], [ 29.762456329113924, -1.462096379310345 ], [ 29.762456329113924, -1.461826896551724 ], [ 29.762186835443039, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24590, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.461826896551724 ], [ 29.762456329113924, -1.462096379310345 ], [ 29.762725822784809, -1.462096379310345 ], [ 29.762725822784809, -1.461826896551724 ], [ 29.762456329113924, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24591, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.461826896551724 ], [ 29.762725822784809, -1.462096379310345 ], [ 29.762995316455697, -1.462096379310345 ], [ 29.762995316455697, -1.461826896551724 ], [ 29.762725822784809, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24592, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.461826896551724 ], [ 29.762995316455697, -1.462096379310345 ], [ 29.763264810126582, -1.462096379310345 ], [ 29.763264810126582, -1.461826896551724 ], [ 29.762995316455697, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24593, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.461826896551724 ], [ 29.763264810126582, -1.462096379310345 ], [ 29.763534303797467, -1.462096379310345 ], [ 29.763534303797467, -1.461826896551724 ], [ 29.763264810126582, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24594, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.461826896551724 ], [ 29.763534303797467, -1.462096379310345 ], [ 29.763803797468356, -1.462096379310345 ], [ 29.763803797468356, -1.461826896551724 ], [ 29.763534303797467, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24595, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.461826896551724 ], [ 29.763803797468356, -1.462096379310345 ], [ 29.76407329113924, -1.462096379310345 ], [ 29.76407329113924, -1.461826896551724 ], [ 29.763803797468356, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24596, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.461826896551724 ], [ 29.76407329113924, -1.462096379310345 ], [ 29.764342784810125, -1.462096379310345 ], [ 29.764342784810125, -1.461826896551724 ], [ 29.76407329113924, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24597, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.461826896551724 ], [ 29.764342784810125, -1.462096379310345 ], [ 29.764612278481014, -1.462096379310345 ], [ 29.764612278481014, -1.461826896551724 ], [ 29.764342784810125, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24598, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.461826896551724 ], [ 29.764612278481014, -1.462096379310345 ], [ 29.764881772151899, -1.462096379310345 ], [ 29.764881772151899, -1.461826896551724 ], [ 29.764612278481014, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24599, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.461826896551724 ], [ 29.764881772151899, -1.462096379310345 ], [ 29.765151265822784, -1.462096379310345 ], [ 29.765151265822784, -1.461826896551724 ], [ 29.764881772151899, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24600, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.461826896551724 ], [ 29.765151265822784, -1.462096379310345 ], [ 29.765420759493672, -1.462096379310345 ], [ 29.765420759493672, -1.461826896551724 ], [ 29.765151265822784, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24601, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.461826896551724 ], [ 29.765420759493672, -1.462096379310345 ], [ 29.765959746835442, -1.462096379310345 ], [ 29.765959746835442, -1.461826896551724 ], [ 29.765420759493672, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24602, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.461826896551724 ], [ 29.765959746835442, -1.462096379310345 ], [ 29.766229240506327, -1.462096379310345 ], [ 29.766229240506327, -1.461826896551724 ], [ 29.765959746835442, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24603, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.461826896551724 ], [ 29.766229240506327, -1.462096379310345 ], [ 29.766498734177215, -1.462096379310345 ], [ 29.766498734177215, -1.461826896551724 ], [ 29.766229240506327, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24604, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.461826896551724 ], [ 29.766498734177215, -1.462096379310345 ], [ 29.7667682278481, -1.462096379310345 ], [ 29.7667682278481, -1.461826896551724 ], [ 29.766498734177215, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24605, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.461826896551724 ], [ 29.7667682278481, -1.462096379310345 ], [ 29.767037721518985, -1.462096379310345 ], [ 29.767037721518985, -1.461826896551724 ], [ 29.7667682278481, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24606, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.461826896551724 ], [ 29.767037721518985, -1.462096379310345 ], [ 29.767576708860759, -1.462096379310345 ], [ 29.767576708860759, -1.461826896551724 ], [ 29.767037721518985, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24607, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.461826896551724 ], [ 29.767576708860759, -1.462096379310345 ], [ 29.767846202531643, -1.462096379310345 ], [ 29.767846202531643, -1.461826896551724 ], [ 29.767576708860759, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24608, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.461826896551724 ], [ 29.767846202531643, -1.462096379310345 ], [ 29.768115696202532, -1.462096379310345 ], [ 29.768115696202532, -1.461826896551724 ], [ 29.767846202531643, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24609, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.461826896551724 ], [ 29.768115696202532, -1.462096379310345 ], [ 29.768654683544302, -1.462096379310345 ], [ 29.768654683544302, -1.461826896551724 ], [ 29.768115696202532, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24610, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.461826896551724 ], [ 29.768654683544302, -1.462096379310345 ], [ 29.76892417721519, -1.462096379310345 ], [ 29.76892417721519, -1.461826896551724 ], [ 29.768654683544302, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24611, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.461826896551724 ], [ 29.76892417721519, -1.462096379310345 ], [ 29.769732658227849, -1.462096379310345 ], [ 29.769732658227849, -1.461826896551724 ], [ 29.76892417721519, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24612, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.461826896551724 ], [ 29.769732658227849, -1.462096379310345 ], [ 29.770002151898733, -1.462096379310345 ], [ 29.770002151898733, -1.461826896551724 ], [ 29.769732658227849, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24613, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.461826896551724 ], [ 29.770002151898733, -1.462096379310345 ], [ 29.770271645569618, -1.462096379310345 ], [ 29.770271645569618, -1.461826896551724 ], [ 29.770002151898733, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24614, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.461826896551724 ], [ 29.770271645569618, -1.462096379310345 ], [ 29.770541139240507, -1.462096379310345 ], [ 29.770541139240507, -1.461826896551724 ], [ 29.770271645569618, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24615, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.461826896551724 ], [ 29.770541139240507, -1.462096379310345 ], [ 29.770810632911392, -1.462096379310345 ], [ 29.770810632911392, -1.461826896551724 ], [ 29.770541139240507, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24616, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.461826896551724 ], [ 29.770810632911392, -1.462096379310345 ], [ 29.771080126582277, -1.462096379310345 ], [ 29.771080126582277, -1.461826896551724 ], [ 29.770810632911392, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24617, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.461826896551724 ], [ 29.771080126582277, -1.462096379310345 ], [ 29.771349620253165, -1.462096379310345 ], [ 29.771349620253165, -1.461826896551724 ], [ 29.771080126582277, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24618, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.461826896551724 ], [ 29.771349620253165, -1.462096379310345 ], [ 29.77161911392405, -1.462096379310345 ], [ 29.77161911392405, -1.461826896551724 ], [ 29.771349620253165, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24619, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.461826896551724 ], [ 29.77161911392405, -1.462096379310345 ], [ 29.771888607594935, -1.462096379310345 ], [ 29.771888607594935, -1.461826896551724 ], [ 29.77161911392405, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24620, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.461826896551724 ], [ 29.771888607594935, -1.462096379310345 ], [ 29.772158101265823, -1.462096379310345 ], [ 29.772158101265823, -1.461826896551724 ], [ 29.771888607594935, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24621, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.461826896551724 ], [ 29.772158101265823, -1.462096379310345 ], [ 29.772427594936708, -1.462096379310345 ], [ 29.772427594936708, -1.461826896551724 ], [ 29.772158101265823, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24622, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.461826896551724 ], [ 29.772427594936708, -1.462096379310345 ], [ 29.772697088607593, -1.462096379310345 ], [ 29.772697088607593, -1.461826896551724 ], [ 29.772427594936708, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24623, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.461826896551724 ], [ 29.772697088607593, -1.462096379310345 ], [ 29.772966582278482, -1.462096379310345 ], [ 29.772966582278482, -1.461826896551724 ], [ 29.772697088607593, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24624, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.461826896551724 ], [ 29.772966582278482, -1.462096379310345 ], [ 29.773236075949367, -1.462096379310345 ], [ 29.773236075949367, -1.461826896551724 ], [ 29.772966582278482, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24625, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.461826896551724 ], [ 29.773236075949367, -1.462096379310345 ], [ 29.773505569620252, -1.462096379310345 ], [ 29.773505569620252, -1.461826896551724 ], [ 29.773236075949367, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24626, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.461826896551724 ], [ 29.773505569620252, -1.462096379310345 ], [ 29.77377506329114, -1.462096379310345 ], [ 29.77377506329114, -1.461826896551724 ], [ 29.773505569620252, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24627, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.461826896551724 ], [ 29.77377506329114, -1.462096379310345 ], [ 29.774044556962025, -1.462096379310345 ], [ 29.774044556962025, -1.461826896551724 ], [ 29.77377506329114, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24628, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.461826896551724 ], [ 29.774044556962025, -1.462096379310345 ], [ 29.77431405063291, -1.462096379310345 ], [ 29.77431405063291, -1.461826896551724 ], [ 29.774044556962025, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24629, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.461826896551724 ], [ 29.77431405063291, -1.462365862068966 ], [ 29.774853037974683, -1.462365862068966 ], [ 29.774853037974683, -1.461826896551724 ], [ 29.77431405063291, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24630, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.461826896551724 ], [ 29.774853037974683, -1.462096379310345 ], [ 29.775122531645568, -1.462096379310345 ], [ 29.775122531645568, -1.461826896551724 ], [ 29.774853037974683, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24631, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.461826896551724 ], [ 29.775122531645568, -1.462096379310345 ], [ 29.775392025316457, -1.462096379310345 ], [ 29.775392025316457, -1.461826896551724 ], [ 29.775122531645568, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24632, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.461826896551724 ], [ 29.775392025316457, -1.462096379310345 ], [ 29.775661518987341, -1.462096379310345 ], [ 29.775661518987341, -1.461826896551724 ], [ 29.775392025316457, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24633, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.461826896551724 ], [ 29.775931012658226, -1.462096379310345 ], [ 29.776200506329115, -1.462096379310345 ], [ 29.776200506329115, -1.461826896551724 ], [ 29.775931012658226, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24634, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.461826896551724 ], [ 29.776200506329115, -1.462096379310345 ], [ 29.77647, -1.462096379310345 ], [ 29.77647, -1.461826896551724 ], [ 29.776200506329115, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24635, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.461826896551724 ], [ 29.77647, -1.462365862068966 ], [ 29.776739493670885, -1.462365862068966 ], [ 29.776739493670885, -1.461826896551724 ], [ 29.77647, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24636, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.461826896551724 ], [ 29.776739493670885, -1.462096379310345 ], [ 29.777008987341773, -1.462096379310345 ], [ 29.777008987341773, -1.461826896551724 ], [ 29.776739493670885, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24637, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.461826896551724 ], [ 29.777008987341773, -1.462096379310345 ], [ 29.777278481012658, -1.462096379310345 ], [ 29.777278481012658, -1.461826896551724 ], [ 29.777008987341773, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24638, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.461826896551724 ], [ 29.777278481012658, -1.462365862068966 ], [ 29.777547974683543, -1.462365862068966 ], [ 29.777547974683543, -1.461826896551724 ], [ 29.777278481012658, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24639, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.461826896551724 ], [ 29.777817468354431, -1.462096379310345 ], [ 29.778086962025316, -1.462096379310345 ], [ 29.778086962025316, -1.461826896551724 ], [ 29.777817468354431, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24640, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.461826896551724 ], [ 29.778086962025316, -1.462365862068966 ], [ 29.778356455696201, -1.462365862068966 ], [ 29.778356455696201, -1.461826896551724 ], [ 29.778086962025316, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24641, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.461826896551724 ], [ 29.781859873417723, -1.462365862068966 ], [ 29.782129367088608, -1.462365862068966 ], [ 29.782129367088608, -1.461826896551724 ], [ 29.781859873417723, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24642, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.461826896551724 ], [ 29.782668354430381, -1.462365862068966 ], [ 29.782937848101266, -1.462365862068966 ], [ 29.782937848101266, -1.461826896551724 ], [ 29.782668354430381, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24643, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.461826896551724 ], [ 29.783207341772151, -1.462365862068966 ], [ 29.783476835443039, -1.462365862068966 ], [ 29.783476835443039, -1.461826896551724 ], [ 29.783207341772151, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24644, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.461826896551724 ], [ 29.783476835443039, -1.462096379310345 ], [ 29.784015822784809, -1.462096379310345 ], [ 29.784015822784809, -1.461826896551724 ], [ 29.783476835443039, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24645, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.461826896551724 ], [ 29.784285316455698, -1.462365862068966 ], [ 29.784554810126583, -1.462365862068966 ], [ 29.784554810126583, -1.461826896551724 ], [ 29.784285316455698, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24646, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.461826896551724 ], [ 29.784554810126583, -1.462096379310345 ], [ 29.784824303797468, -1.462096379310345 ], [ 29.784824303797468, -1.461826896551724 ], [ 29.784554810126583, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24647, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.461826896551724 ], [ 29.784824303797468, -1.462096379310345 ], [ 29.785093797468356, -1.462096379310345 ], [ 29.785093797468356, -1.461826896551724 ], [ 29.784824303797468, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24648, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.461826896551724 ], [ 29.785093797468356, -1.462365862068966 ], [ 29.785363291139241, -1.462365862068966 ], [ 29.785363291139241, -1.461826896551724 ], [ 29.785093797468356, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24649, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.461826896551724 ], [ 29.785363291139241, -1.462096379310345 ], [ 29.785632784810126, -1.462096379310345 ], [ 29.785632784810126, -1.461826896551724 ], [ 29.785363291139241, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24650, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.461826896551724 ], [ 29.785632784810126, -1.462096379310345 ], [ 29.785902278481014, -1.462096379310345 ], [ 29.785902278481014, -1.461826896551724 ], [ 29.785632784810126, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24651, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.461826896551724 ], [ 29.785902278481014, -1.462096379310345 ], [ 29.786171772151899, -1.462096379310345 ], [ 29.786171772151899, -1.461826896551724 ], [ 29.785902278481014, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24652, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.461826896551724 ], [ 29.786171772151899, -1.462096379310345 ], [ 29.786441265822784, -1.462096379310345 ], [ 29.786441265822784, -1.461826896551724 ], [ 29.786171772151899, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24653, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.461826896551724 ], [ 29.786441265822784, -1.462096379310345 ], [ 29.786710759493673, -1.462096379310345 ], [ 29.786710759493673, -1.461826896551724 ], [ 29.786441265822784, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24654, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.461826896551724 ], [ 29.786710759493673, -1.462096379310345 ], [ 29.786980253164558, -1.462096379310345 ], [ 29.786980253164558, -1.461826896551724 ], [ 29.786710759493673, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24655, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.461826896551724 ], [ 29.786980253164558, -1.462096379310345 ], [ 29.787249746835442, -1.462096379310345 ], [ 29.787249746835442, -1.461826896551724 ], [ 29.786980253164558, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24656, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.461826896551724 ], [ 29.787249746835442, -1.462096379310345 ], [ 29.787519240506327, -1.462096379310345 ], [ 29.787519240506327, -1.461826896551724 ], [ 29.787249746835442, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24657, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.461826896551724 ], [ 29.787519240506327, -1.462096379310345 ], [ 29.787788734177216, -1.462096379310345 ], [ 29.787788734177216, -1.461826896551724 ], [ 29.787519240506327, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24658, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.461826896551724 ], [ 29.787788734177216, -1.462096379310345 ], [ 29.788058227848101, -1.462096379310345 ], [ 29.788058227848101, -1.461826896551724 ], [ 29.787788734177216, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24659, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.461826896551724 ], [ 29.788058227848101, -1.462096379310345 ], [ 29.788327721518986, -1.462096379310345 ], [ 29.788327721518986, -1.461826896551724 ], [ 29.788058227848101, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24660, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.461826896551724 ], [ 29.788327721518986, -1.462096379310345 ], [ 29.788597215189874, -1.462096379310345 ], [ 29.788597215189874, -1.461826896551724 ], [ 29.788327721518986, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24661, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.461826896551724 ], [ 29.793178607594935, -1.462096379310345 ], [ 29.793448101265824, -1.462096379310345 ], [ 29.793448101265824, -1.461826896551724 ], [ 29.793178607594935, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24662, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.461826896551724 ], [ 29.793448101265824, -1.462096379310345 ], [ 29.793717594936709, -1.462096379310345 ], [ 29.793717594936709, -1.461826896551724 ], [ 29.793448101265824, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24663, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.461826896551724 ], [ 29.793717594936709, -1.462096379310345 ], [ 29.793987088607594, -1.462096379310345 ], [ 29.793987088607594, -1.461826896551724 ], [ 29.793717594936709, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24664, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.461826896551724 ], [ 29.793987088607594, -1.462096379310345 ], [ 29.794256582278482, -1.462096379310345 ], [ 29.794256582278482, -1.461826896551724 ], [ 29.793987088607594, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24665, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.461826896551724 ], [ 29.794256582278482, -1.462096379310345 ], [ 29.794526075949367, -1.462096379310345 ], [ 29.794526075949367, -1.461826896551724 ], [ 29.794256582278482, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24666, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.461826896551724 ], [ 29.794526075949367, -1.462096379310345 ], [ 29.794795569620252, -1.462096379310345 ], [ 29.794795569620252, -1.461826896551724 ], [ 29.794526075949367, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24667, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.461826896551724 ], [ 29.794795569620252, -1.462096379310345 ], [ 29.79506506329114, -1.462096379310345 ], [ 29.79506506329114, -1.461826896551724 ], [ 29.794795569620252, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24668, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.461826896551724 ], [ 29.79506506329114, -1.462096379310345 ], [ 29.795334556962025, -1.462096379310345 ], [ 29.795334556962025, -1.461826896551724 ], [ 29.79506506329114, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24669, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.461826896551724 ], [ 29.795334556962025, -1.462096379310345 ], [ 29.79560405063291, -1.462096379310345 ], [ 29.79560405063291, -1.461826896551724 ], [ 29.795334556962025, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24670, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.461826896551724 ], [ 29.79560405063291, -1.462096379310345 ], [ 29.795873544303799, -1.462096379310345 ], [ 29.795873544303799, -1.461826896551724 ], [ 29.79560405063291, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24671, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.461826896551724 ], [ 29.795873544303799, -1.462096379310345 ], [ 29.796143037974684, -1.462096379310345 ], [ 29.796143037974684, -1.461826896551724 ], [ 29.795873544303799, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24672, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.461826896551724 ], [ 29.796143037974684, -1.462096379310345 ], [ 29.796412531645569, -1.462096379310345 ], [ 29.796412531645569, -1.461826896551724 ], [ 29.796143037974684, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24673, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.461826896551724 ], [ 29.796682025316457, -1.462096379310345 ], [ 29.796951518987342, -1.462096379310345 ], [ 29.796951518987342, -1.461826896551724 ], [ 29.796682025316457, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24674, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.461826896551724 ], [ 29.796951518987342, -1.462096379310345 ], [ 29.797221012658227, -1.462096379310345 ], [ 29.797221012658227, -1.461826896551724 ], [ 29.796951518987342, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24675, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.461826896551724 ], [ 29.797490506329115, -1.462365862068966 ], [ 29.79776, -1.462365862068966 ], [ 29.79776, -1.461826896551724 ], [ 29.797490506329115, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24676, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.461826896551724 ], [ 29.798298987341774, -1.462096379310345 ], [ 29.798568481012659, -1.462096379310345 ], [ 29.798568481012659, -1.461826896551724 ], [ 29.798298987341774, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24677, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.461826896551724 ], [ 29.798568481012659, -1.462096379310345 ], [ 29.798837974683543, -1.462096379310345 ], [ 29.798837974683543, -1.461826896551724 ], [ 29.798568481012659, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24678, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.461826896551724 ], [ 29.798837974683543, -1.462365862068966 ], [ 29.799107468354432, -1.462365862068966 ], [ 29.799107468354432, -1.461826896551724 ], [ 29.798837974683543, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24679, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.461826896551724 ], [ 29.799107468354432, -1.462096379310345 ], [ 29.799376962025317, -1.462096379310345 ], [ 29.799376962025317, -1.461826896551724 ], [ 29.799107468354432, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24680, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.461826896551724 ], [ 29.799376962025317, -1.462096379310345 ], [ 29.799646455696202, -1.462096379310345 ], [ 29.799646455696202, -1.461826896551724 ], [ 29.799376962025317, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24681, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.461826896551724 ], [ 29.799646455696202, -1.462096379310345 ], [ 29.79991594936709, -1.462096379310345 ], [ 29.79991594936709, -1.461826896551724 ], [ 29.799646455696202, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24682, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.461826896551724 ], [ 29.79991594936709, -1.462096379310345 ], [ 29.800185443037975, -1.462096379310345 ], [ 29.800185443037975, -1.461826896551724 ], [ 29.79991594936709, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24683, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.461826896551724 ], [ 29.800185443037975, -1.462096379310345 ], [ 29.80045493670886, -1.462096379310345 ], [ 29.80045493670886, -1.461826896551724 ], [ 29.800185443037975, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24684, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.461826896551724 ], [ 29.80045493670886, -1.462096379310345 ], [ 29.800724430379748, -1.462096379310345 ], [ 29.800724430379748, -1.461826896551724 ], [ 29.80045493670886, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24685, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.461826896551724 ], [ 29.800724430379748, -1.462096379310345 ], [ 29.800993924050633, -1.462096379310345 ], [ 29.800993924050633, -1.461826896551724 ], [ 29.800724430379748, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24686, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.462096379310345 ], [ 29.754910506329114, -1.462365862068966 ], [ 29.75518, -1.462365862068966 ], [ 29.75518, -1.462096379310345 ], [ 29.754910506329114, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24687, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.462096379310345 ], [ 29.75518, -1.462365862068966 ], [ 29.755449493670884, -1.462365862068966 ], [ 29.755449493670884, -1.462096379310345 ], [ 29.75518, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24688, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.462096379310345 ], [ 29.755449493670884, -1.462365862068966 ], [ 29.755718987341773, -1.462365862068966 ], [ 29.755718987341773, -1.462096379310345 ], [ 29.755449493670884, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24689, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.462096379310345 ], [ 29.755718987341773, -1.462365862068966 ], [ 29.755988481012658, -1.462365862068966 ], [ 29.755988481012658, -1.462096379310345 ], [ 29.755718987341773, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24690, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.462096379310345 ], [ 29.755988481012658, -1.462904827586207 ], [ 29.756257974683542, -1.462904827586207 ], [ 29.756257974683542, -1.462096379310345 ], [ 29.755988481012658, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24691, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.462096379310345 ], [ 29.756257974683542, -1.462365862068966 ], [ 29.756796962025316, -1.462365862068966 ], [ 29.756796962025316, -1.462096379310345 ], [ 29.756257974683542, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24692, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.462096379310345 ], [ 29.756796962025316, -1.462365862068966 ], [ 29.757066455696201, -1.462365862068966 ], [ 29.757066455696201, -1.462096379310345 ], [ 29.756796962025316, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24693, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.462096379310345 ], [ 29.757066455696201, -1.462365862068966 ], [ 29.757335949367089, -1.462365862068966 ], [ 29.757335949367089, -1.462096379310345 ], [ 29.757066455696201, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24694, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.462096379310345 ], [ 29.757605443037974, -1.462365862068966 ], [ 29.757874936708859, -1.462365862068966 ], [ 29.757874936708859, -1.462096379310345 ], [ 29.757605443037974, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24695, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.462096379310345 ], [ 29.757874936708859, -1.462365862068966 ], [ 29.758144430379748, -1.462365862068966 ], [ 29.758144430379748, -1.462096379310345 ], [ 29.757874936708859, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24696, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.462096379310345 ], [ 29.758144430379748, -1.462365862068966 ], [ 29.758413924050632, -1.462365862068966 ], [ 29.758413924050632, -1.462096379310345 ], [ 29.758144430379748, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24697, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.462096379310345 ], [ 29.758413924050632, -1.462365862068966 ], [ 29.758683417721517, -1.462365862068966 ], [ 29.758683417721517, -1.462096379310345 ], [ 29.758413924050632, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24698, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.462096379310345 ], [ 29.758683417721517, -1.462365862068966 ], [ 29.758952911392406, -1.462365862068966 ], [ 29.758952911392406, -1.462096379310345 ], [ 29.758683417721517, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24699, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.462096379310345 ], [ 29.758952911392406, -1.462365862068966 ], [ 29.759222405063291, -1.462365862068966 ], [ 29.759222405063291, -1.462096379310345 ], [ 29.758952911392406, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24700, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.462096379310345 ], [ 29.759222405063291, -1.462365862068966 ], [ 29.759491898734176, -1.462365862068966 ], [ 29.759491898734176, -1.462096379310345 ], [ 29.759222405063291, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24701, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.462096379310345 ], [ 29.759491898734176, -1.462365862068966 ], [ 29.759761392405064, -1.462365862068966 ], [ 29.759761392405064, -1.462096379310345 ], [ 29.759491898734176, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24702, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.462096379310345 ], [ 29.759761392405064, -1.462365862068966 ], [ 29.760030886075949, -1.462365862068966 ], [ 29.760030886075949, -1.462096379310345 ], [ 29.759761392405064, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24703, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.462096379310345 ], [ 29.760030886075949, -1.462365862068966 ], [ 29.760300379746834, -1.462365862068966 ], [ 29.760300379746834, -1.462096379310345 ], [ 29.760030886075949, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24704, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.462096379310345 ], [ 29.760300379746834, -1.462365862068966 ], [ 29.760569873417722, -1.462365862068966 ], [ 29.760569873417722, -1.462096379310345 ], [ 29.760300379746834, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24705, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.462096379310345 ], [ 29.760569873417722, -1.462365862068966 ], [ 29.760839367088607, -1.462365862068966 ], [ 29.760839367088607, -1.462096379310345 ], [ 29.760569873417722, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24706, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.462096379310345 ], [ 29.761108860759492, -1.462365862068966 ], [ 29.761378354430381, -1.462365862068966 ], [ 29.761378354430381, -1.462096379310345 ], [ 29.761108860759492, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24707, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.462096379310345 ], [ 29.761378354430381, -1.462365862068966 ], [ 29.761647848101266, -1.462365862068966 ], [ 29.761647848101266, -1.462096379310345 ], [ 29.761378354430381, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24708, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.462096379310345 ], [ 29.761647848101266, -1.462365862068966 ], [ 29.761917341772151, -1.462365862068966 ], [ 29.761917341772151, -1.462096379310345 ], [ 29.761647848101266, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24709, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.462096379310345 ], [ 29.761917341772151, -1.462904827586207 ], [ 29.762186835443039, -1.462904827586207 ], [ 29.762186835443039, -1.462096379310345 ], [ 29.761917341772151, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24710, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.462096379310345 ], [ 29.762186835443039, -1.462365862068966 ], [ 29.762456329113924, -1.462365862068966 ], [ 29.762456329113924, -1.462096379310345 ], [ 29.762186835443039, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24711, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.462096379310345 ], [ 29.762456329113924, -1.462365862068966 ], [ 29.762725822784809, -1.462365862068966 ], [ 29.762725822784809, -1.462096379310345 ], [ 29.762456329113924, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24712, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.462096379310345 ], [ 29.762725822784809, -1.462365862068966 ], [ 29.762995316455697, -1.462365862068966 ], [ 29.762995316455697, -1.462096379310345 ], [ 29.762725822784809, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24713, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.462096379310345 ], [ 29.762995316455697, -1.462365862068966 ], [ 29.763264810126582, -1.462365862068966 ], [ 29.763264810126582, -1.462096379310345 ], [ 29.762995316455697, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24714, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.462096379310345 ], [ 29.763264810126582, -1.462365862068966 ], [ 29.763534303797467, -1.462365862068966 ], [ 29.763534303797467, -1.462096379310345 ], [ 29.763264810126582, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24715, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.462096379310345 ], [ 29.763534303797467, -1.462365862068966 ], [ 29.763803797468356, -1.462365862068966 ], [ 29.763803797468356, -1.462096379310345 ], [ 29.763534303797467, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24716, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.462096379310345 ], [ 29.763803797468356, -1.462365862068966 ], [ 29.76407329113924, -1.462365862068966 ], [ 29.76407329113924, -1.462096379310345 ], [ 29.763803797468356, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24717, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.462096379310345 ], [ 29.76407329113924, -1.462365862068966 ], [ 29.764342784810125, -1.462365862068966 ], [ 29.764342784810125, -1.462096379310345 ], [ 29.76407329113924, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24718, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.462096379310345 ], [ 29.764342784810125, -1.462365862068966 ], [ 29.764612278481014, -1.462365862068966 ], [ 29.764612278481014, -1.462096379310345 ], [ 29.764342784810125, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24719, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.462096379310345 ], [ 29.764612278481014, -1.462365862068966 ], [ 29.764881772151899, -1.462365862068966 ], [ 29.764881772151899, -1.462096379310345 ], [ 29.764612278481014, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24720, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.462096379310345 ], [ 29.764881772151899, -1.462365862068966 ], [ 29.765151265822784, -1.462365862068966 ], [ 29.765151265822784, -1.462096379310345 ], [ 29.764881772151899, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24721, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.462096379310345 ], [ 29.765151265822784, -1.462365862068966 ], [ 29.765420759493672, -1.462365862068966 ], [ 29.765420759493672, -1.462096379310345 ], [ 29.765151265822784, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24722, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.462096379310345 ], [ 29.765420759493672, -1.462365862068966 ], [ 29.765959746835442, -1.462365862068966 ], [ 29.765959746835442, -1.462096379310345 ], [ 29.765420759493672, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24723, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.462096379310345 ], [ 29.765959746835442, -1.462365862068966 ], [ 29.766229240506327, -1.462365862068966 ], [ 29.766229240506327, -1.462096379310345 ], [ 29.765959746835442, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24724, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.462096379310345 ], [ 29.766229240506327, -1.462365862068966 ], [ 29.766498734177215, -1.462365862068966 ], [ 29.766498734177215, -1.462096379310345 ], [ 29.766229240506327, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24725, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.462096379310345 ], [ 29.766498734177215, -1.462365862068966 ], [ 29.7667682278481, -1.462365862068966 ], [ 29.7667682278481, -1.462096379310345 ], [ 29.766498734177215, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24726, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.462096379310345 ], [ 29.7667682278481, -1.462365862068966 ], [ 29.767037721518985, -1.462365862068966 ], [ 29.767037721518985, -1.462096379310345 ], [ 29.7667682278481, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24727, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.462096379310345 ], [ 29.767037721518985, -1.462365862068966 ], [ 29.767307215189874, -1.462365862068966 ], [ 29.767307215189874, -1.462096379310345 ], [ 29.767037721518985, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24728, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.462096379310345 ], [ 29.767307215189874, -1.462365862068966 ], [ 29.767846202531643, -1.462365862068966 ], [ 29.767846202531643, -1.462096379310345 ], [ 29.767307215189874, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24729, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.462096379310345 ], [ 29.767846202531643, -1.462365862068966 ], [ 29.768115696202532, -1.462365862068966 ], [ 29.768115696202532, -1.462096379310345 ], [ 29.767846202531643, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24730, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.462096379310345 ], [ 29.768115696202532, -1.462365862068966 ], [ 29.768654683544302, -1.462365862068966 ], [ 29.768654683544302, -1.462096379310345 ], [ 29.768115696202532, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24731, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.462096379310345 ], [ 29.768654683544302, -1.462365862068966 ], [ 29.76892417721519, -1.462365862068966 ], [ 29.76892417721519, -1.462096379310345 ], [ 29.768654683544302, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24732, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.462096379310345 ], [ 29.76892417721519, -1.462365862068966 ], [ 29.769193670886075, -1.462365862068966 ], [ 29.769193670886075, -1.462096379310345 ], [ 29.76892417721519, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24733, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.462096379310345 ], [ 29.769193670886075, -1.462365862068966 ], [ 29.76946316455696, -1.462365862068966 ], [ 29.76946316455696, -1.462096379310345 ], [ 29.769193670886075, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24734, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.462096379310345 ], [ 29.76946316455696, -1.462365862068966 ], [ 29.769732658227849, -1.462365862068966 ], [ 29.769732658227849, -1.462096379310345 ], [ 29.76946316455696, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24735, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.462096379310345 ], [ 29.769732658227849, -1.462365862068966 ], [ 29.770002151898733, -1.462365862068966 ], [ 29.770002151898733, -1.462096379310345 ], [ 29.769732658227849, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24736, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.462096379310345 ], [ 29.770002151898733, -1.462365862068966 ], [ 29.770271645569618, -1.462365862068966 ], [ 29.770271645569618, -1.462096379310345 ], [ 29.770002151898733, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24737, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.462096379310345 ], [ 29.770271645569618, -1.462365862068966 ], [ 29.770541139240507, -1.462365862068966 ], [ 29.770541139240507, -1.462096379310345 ], [ 29.770271645569618, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24738, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.462096379310345 ], [ 29.770541139240507, -1.462365862068966 ], [ 29.770810632911392, -1.462365862068966 ], [ 29.770810632911392, -1.462096379310345 ], [ 29.770541139240507, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24739, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.462096379310345 ], [ 29.770810632911392, -1.462365862068966 ], [ 29.771080126582277, -1.462365862068966 ], [ 29.771080126582277, -1.462096379310345 ], [ 29.770810632911392, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24740, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.462096379310345 ], [ 29.771080126582277, -1.462365862068966 ], [ 29.771349620253165, -1.462365862068966 ], [ 29.771349620253165, -1.462096379310345 ], [ 29.771080126582277, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24741, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.462096379310345 ], [ 29.771349620253165, -1.462365862068966 ], [ 29.77161911392405, -1.462365862068966 ], [ 29.77161911392405, -1.462096379310345 ], [ 29.771349620253165, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24742, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.462096379310345 ], [ 29.77161911392405, -1.462365862068966 ], [ 29.771888607594935, -1.462365862068966 ], [ 29.771888607594935, -1.462096379310345 ], [ 29.77161911392405, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24743, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.462096379310345 ], [ 29.771888607594935, -1.462365862068966 ], [ 29.772158101265823, -1.462365862068966 ], [ 29.772158101265823, -1.462096379310345 ], [ 29.771888607594935, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24744, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.462096379310345 ], [ 29.772158101265823, -1.462365862068966 ], [ 29.772427594936708, -1.462365862068966 ], [ 29.772427594936708, -1.462096379310345 ], [ 29.772158101265823, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24745, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.462096379310345 ], [ 29.772427594936708, -1.462365862068966 ], [ 29.772697088607593, -1.462365862068966 ], [ 29.772697088607593, -1.462096379310345 ], [ 29.772427594936708, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24746, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.462096379310345 ], [ 29.772697088607593, -1.462365862068966 ], [ 29.772966582278482, -1.462365862068966 ], [ 29.772966582278482, -1.462096379310345 ], [ 29.772697088607593, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24747, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.462096379310345 ], [ 29.772966582278482, -1.462365862068966 ], [ 29.773236075949367, -1.462365862068966 ], [ 29.773236075949367, -1.462096379310345 ], [ 29.772966582278482, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24748, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.462096379310345 ], [ 29.773236075949367, -1.462365862068966 ], [ 29.773505569620252, -1.462365862068966 ], [ 29.773505569620252, -1.462096379310345 ], [ 29.773236075949367, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24749, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.462096379310345 ], [ 29.773505569620252, -1.462365862068966 ], [ 29.77377506329114, -1.462365862068966 ], [ 29.77377506329114, -1.462096379310345 ], [ 29.773505569620252, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24750, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.462096379310345 ], [ 29.77377506329114, -1.462365862068966 ], [ 29.774044556962025, -1.462365862068966 ], [ 29.774044556962025, -1.462096379310345 ], [ 29.77377506329114, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24751, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.462096379310345 ], [ 29.774044556962025, -1.462365862068966 ], [ 29.77431405063291, -1.462365862068966 ], [ 29.77431405063291, -1.462096379310345 ], [ 29.774044556962025, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24752, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.462096379310345 ], [ 29.774853037974683, -1.462365862068966 ], [ 29.775122531645568, -1.462365862068966 ], [ 29.775122531645568, -1.462096379310345 ], [ 29.774853037974683, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24753, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.462096379310345 ], [ 29.775122531645568, -1.462365862068966 ], [ 29.775392025316457, -1.462365862068966 ], [ 29.775392025316457, -1.462096379310345 ], [ 29.775122531645568, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24754, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.462096379310345 ], [ 29.775392025316457, -1.462904827586207 ], [ 29.775661518987341, -1.462904827586207 ], [ 29.775661518987341, -1.462096379310345 ], [ 29.775392025316457, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24755, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.462096379310345 ], [ 29.775661518987341, -1.462365862068966 ], [ 29.775931012658226, -1.462365862068966 ], [ 29.775931012658226, -1.462096379310345 ], [ 29.775661518987341, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24756, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.462096379310345 ], [ 29.775931012658226, -1.462365862068966 ], [ 29.776200506329115, -1.462365862068966 ], [ 29.776200506329115, -1.462096379310345 ], [ 29.775931012658226, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24757, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.462096379310345 ], [ 29.776200506329115, -1.462904827586207 ], [ 29.77647, -1.462904827586207 ], [ 29.77647, -1.462096379310345 ], [ 29.776200506329115, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24758, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.462096379310345 ], [ 29.776739493670885, -1.462365862068966 ], [ 29.777008987341773, -1.462365862068966 ], [ 29.777008987341773, -1.462096379310345 ], [ 29.776739493670885, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24759, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.462096379310345 ], [ 29.777008987341773, -1.462904827586207 ], [ 29.777278481012658, -1.462904827586207 ], [ 29.777278481012658, -1.462096379310345 ], [ 29.777008987341773, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24760, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.462096379310345 ], [ 29.777547974683543, -1.462904827586207 ], [ 29.777817468354431, -1.462904827586207 ], [ 29.777817468354431, -1.462096379310345 ], [ 29.777547974683543, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24761, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.462096379310345 ], [ 29.777817468354431, -1.462904827586207 ], [ 29.778086962025316, -1.462904827586207 ], [ 29.778086962025316, -1.462096379310345 ], [ 29.777817468354431, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24762, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.462096379310345 ], [ 29.778356455696201, -1.462904827586207 ], [ 29.77862594936709, -1.462904827586207 ], [ 29.77862594936709, -1.462096379310345 ], [ 29.778356455696201, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24763, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.462096379310345 ], [ 29.77862594936709, -1.463174310344828 ], [ 29.778895443037975, -1.463174310344828 ], [ 29.778895443037975, -1.462096379310345 ], [ 29.77862594936709, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24764, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.462096379310345 ], [ 29.784824303797468, -1.462904827586207 ], [ 29.785093797468356, -1.462904827586207 ], [ 29.785093797468356, -1.462096379310345 ], [ 29.784824303797468, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24765, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.462096379310345 ], [ 29.785632784810126, -1.462365862068966 ], [ 29.785902278481014, -1.462365862068966 ], [ 29.785902278481014, -1.462096379310345 ], [ 29.785632784810126, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24766, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.462096379310345 ], [ 29.785902278481014, -1.462365862068966 ], [ 29.786171772151899, -1.462365862068966 ], [ 29.786171772151899, -1.462096379310345 ], [ 29.785902278481014, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24767, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.462096379310345 ], [ 29.786171772151899, -1.462904827586207 ], [ 29.786441265822784, -1.462904827586207 ], [ 29.786441265822784, -1.462096379310345 ], [ 29.786171772151899, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24768, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.462096379310345 ], [ 29.786441265822784, -1.462365862068966 ], [ 29.786710759493673, -1.462365862068966 ], [ 29.786710759493673, -1.462096379310345 ], [ 29.786441265822784, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24769, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.462096379310345 ], [ 29.786710759493673, -1.462365862068966 ], [ 29.786980253164558, -1.462365862068966 ], [ 29.786980253164558, -1.462096379310345 ], [ 29.786710759493673, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24770, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.462096379310345 ], [ 29.786980253164558, -1.462365862068966 ], [ 29.787249746835442, -1.462365862068966 ], [ 29.787249746835442, -1.462096379310345 ], [ 29.786980253164558, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24771, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.462096379310345 ], [ 29.787249746835442, -1.462365862068966 ], [ 29.787519240506327, -1.462365862068966 ], [ 29.787519240506327, -1.462096379310345 ], [ 29.787249746835442, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24772, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.462096379310345 ], [ 29.787519240506327, -1.462365862068966 ], [ 29.787788734177216, -1.462365862068966 ], [ 29.787788734177216, -1.462096379310345 ], [ 29.787519240506327, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24773, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.462096379310345 ], [ 29.787788734177216, -1.462365862068966 ], [ 29.788058227848101, -1.462365862068966 ], [ 29.788058227848101, -1.462096379310345 ], [ 29.787788734177216, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24774, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.462096379310345 ], [ 29.788058227848101, -1.462365862068966 ], [ 29.788327721518986, -1.462365862068966 ], [ 29.788327721518986, -1.462096379310345 ], [ 29.788058227848101, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24775, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.462096379310345 ], [ 29.792909113924051, -1.463174310344828 ], [ 29.793178607594935, -1.463174310344828 ], [ 29.793178607594935, -1.462096379310345 ], [ 29.792909113924051, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24776, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.462096379310345 ], [ 29.793178607594935, -1.462365862068966 ], [ 29.793448101265824, -1.462365862068966 ], [ 29.793448101265824, -1.462096379310345 ], [ 29.793178607594935, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24777, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.462096379310345 ], [ 29.793448101265824, -1.462365862068966 ], [ 29.793717594936709, -1.462365862068966 ], [ 29.793717594936709, -1.462096379310345 ], [ 29.793448101265824, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24778, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.462096379310345 ], [ 29.793717594936709, -1.462365862068966 ], [ 29.793987088607594, -1.462365862068966 ], [ 29.793987088607594, -1.462096379310345 ], [ 29.793717594936709, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24779, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.462096379310345 ], [ 29.793987088607594, -1.462365862068966 ], [ 29.794256582278482, -1.462365862068966 ], [ 29.794256582278482, -1.462096379310345 ], [ 29.793987088607594, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24780, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.462096379310345 ], [ 29.794256582278482, -1.462365862068966 ], [ 29.794526075949367, -1.462365862068966 ], [ 29.794526075949367, -1.462096379310345 ], [ 29.794256582278482, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24781, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.462096379310345 ], [ 29.794526075949367, -1.462365862068966 ], [ 29.794795569620252, -1.462365862068966 ], [ 29.794795569620252, -1.462096379310345 ], [ 29.794526075949367, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24782, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.462096379310345 ], [ 29.794795569620252, -1.462365862068966 ], [ 29.79506506329114, -1.462365862068966 ], [ 29.79506506329114, -1.462096379310345 ], [ 29.794795569620252, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24783, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.462096379310345 ], [ 29.79506506329114, -1.462365862068966 ], [ 29.795334556962025, -1.462365862068966 ], [ 29.795334556962025, -1.462096379310345 ], [ 29.79506506329114, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24784, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.462096379310345 ], [ 29.795334556962025, -1.462365862068966 ], [ 29.79560405063291, -1.462365862068966 ], [ 29.79560405063291, -1.462096379310345 ], [ 29.795334556962025, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24785, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.462096379310345 ], [ 29.79560405063291, -1.462365862068966 ], [ 29.795873544303799, -1.462365862068966 ], [ 29.795873544303799, -1.462096379310345 ], [ 29.79560405063291, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24786, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.462096379310345 ], [ 29.795873544303799, -1.462365862068966 ], [ 29.796143037974684, -1.462365862068966 ], [ 29.796143037974684, -1.462096379310345 ], [ 29.795873544303799, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24787, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.462096379310345 ], [ 29.796143037974684, -1.462365862068966 ], [ 29.796412531645569, -1.462365862068966 ], [ 29.796412531645569, -1.462096379310345 ], [ 29.796143037974684, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24788, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.462096379310345 ], [ 29.796412531645569, -1.462365862068966 ], [ 29.796682025316457, -1.462365862068966 ], [ 29.796682025316457, -1.462096379310345 ], [ 29.796412531645569, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24789, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.462096379310345 ], [ 29.796682025316457, -1.462365862068966 ], [ 29.796951518987342, -1.462365862068966 ], [ 29.796951518987342, -1.462096379310345 ], [ 29.796682025316457, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24790, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.462096379310345 ], [ 29.796951518987342, -1.462365862068966 ], [ 29.797221012658227, -1.462365862068966 ], [ 29.797221012658227, -1.462096379310345 ], [ 29.796951518987342, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24791, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.462096379310345 ], [ 29.799646455696202, -1.462365862068966 ], [ 29.79991594936709, -1.462365862068966 ], [ 29.79991594936709, -1.462096379310345 ], [ 29.799646455696202, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24792, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.462096379310345 ], [ 29.79991594936709, -1.462365862068966 ], [ 29.800185443037975, -1.462365862068966 ], [ 29.800185443037975, -1.462096379310345 ], [ 29.79991594936709, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24793, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.462096379310345 ], [ 29.800185443037975, -1.462365862068966 ], [ 29.80045493670886, -1.462365862068966 ], [ 29.80045493670886, -1.462096379310345 ], [ 29.800185443037975, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24794, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.462096379310345 ], [ 29.80045493670886, -1.462365862068966 ], [ 29.800724430379748, -1.462365862068966 ], [ 29.800724430379748, -1.462096379310345 ], [ 29.80045493670886, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24795, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.462365862068966 ], [ 29.754102025316456, -1.462904827586207 ], [ 29.754371518987341, -1.462904827586207 ], [ 29.754371518987341, -1.462365862068966 ], [ 29.754102025316456, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24796, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.462365862068966 ], [ 29.754371518987341, -1.462904827586207 ], [ 29.754641012658226, -1.462904827586207 ], [ 29.754641012658226, -1.462365862068966 ], [ 29.754371518987341, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24797, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.462365862068966 ], [ 29.754641012658226, -1.462904827586207 ], [ 29.754910506329114, -1.462904827586207 ], [ 29.754910506329114, -1.462365862068966 ], [ 29.754641012658226, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24798, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.462365862068966 ], [ 29.754910506329114, -1.463174310344828 ], [ 29.75518, -1.463174310344828 ], [ 29.75518, -1.462365862068966 ], [ 29.754910506329114, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24799, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.462365862068966 ], [ 29.75518, -1.462904827586207 ], [ 29.755449493670884, -1.462904827586207 ], [ 29.755449493670884, -1.462365862068966 ], [ 29.75518, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24800, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.462365862068966 ], [ 29.755449493670884, -1.462904827586207 ], [ 29.755718987341773, -1.462904827586207 ], [ 29.755718987341773, -1.462365862068966 ], [ 29.755449493670884, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24801, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.462365862068966 ], [ 29.755718987341773, -1.462904827586207 ], [ 29.755988481012658, -1.462904827586207 ], [ 29.755988481012658, -1.462365862068966 ], [ 29.755718987341773, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24802, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.462365862068966 ], [ 29.756257974683542, -1.462904827586207 ], [ 29.756796962025316, -1.462904827586207 ], [ 29.756796962025316, -1.462365862068966 ], [ 29.756257974683542, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24803, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.462365862068966 ], [ 29.756796962025316, -1.462904827586207 ], [ 29.757066455696201, -1.462904827586207 ], [ 29.757066455696201, -1.462365862068966 ], [ 29.756796962025316, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24804, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.462365862068966 ], [ 29.757066455696201, -1.462904827586207 ], [ 29.757335949367089, -1.462904827586207 ], [ 29.757335949367089, -1.462365862068966 ], [ 29.757066455696201, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24805, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.462365862068966 ], [ 29.757335949367089, -1.462904827586207 ], [ 29.757605443037974, -1.462904827586207 ], [ 29.757605443037974, -1.462365862068966 ], [ 29.757335949367089, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24806, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.462365862068966 ], [ 29.757605443037974, -1.462904827586207 ], [ 29.757874936708859, -1.462904827586207 ], [ 29.757874936708859, -1.462365862068966 ], [ 29.757605443037974, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24807, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.462365862068966 ], [ 29.757874936708859, -1.462904827586207 ], [ 29.758144430379748, -1.462904827586207 ], [ 29.758144430379748, -1.462365862068966 ], [ 29.757874936708859, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24808, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.462365862068966 ], [ 29.758144430379748, -1.462904827586207 ], [ 29.758413924050632, -1.462904827586207 ], [ 29.758413924050632, -1.462365862068966 ], [ 29.758144430379748, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24809, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.462365862068966 ], [ 29.758413924050632, -1.462904827586207 ], [ 29.758683417721517, -1.462904827586207 ], [ 29.758683417721517, -1.462365862068966 ], [ 29.758413924050632, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24810, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.462365862068966 ], [ 29.758683417721517, -1.462904827586207 ], [ 29.758952911392406, -1.462904827586207 ], [ 29.758952911392406, -1.462365862068966 ], [ 29.758683417721517, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24811, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.462365862068966 ], [ 29.758952911392406, -1.462904827586207 ], [ 29.759222405063291, -1.462904827586207 ], [ 29.759222405063291, -1.462365862068966 ], [ 29.758952911392406, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24812, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.462365862068966 ], [ 29.759222405063291, -1.462904827586207 ], [ 29.759491898734176, -1.462904827586207 ], [ 29.759491898734176, -1.462365862068966 ], [ 29.759222405063291, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24813, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.462365862068966 ], [ 29.759491898734176, -1.462904827586207 ], [ 29.759761392405064, -1.462904827586207 ], [ 29.759761392405064, -1.462365862068966 ], [ 29.759491898734176, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24814, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.462365862068966 ], [ 29.759761392405064, -1.462904827586207 ], [ 29.760030886075949, -1.462904827586207 ], [ 29.760030886075949, -1.462365862068966 ], [ 29.759761392405064, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24815, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.462365862068966 ], [ 29.760030886075949, -1.462904827586207 ], [ 29.760300379746834, -1.462904827586207 ], [ 29.760300379746834, -1.462365862068966 ], [ 29.760030886075949, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24816, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.462365862068966 ], [ 29.760300379746834, -1.462904827586207 ], [ 29.760569873417722, -1.462904827586207 ], [ 29.760569873417722, -1.462365862068966 ], [ 29.760300379746834, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24817, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.462365862068966 ], [ 29.760569873417722, -1.462904827586207 ], [ 29.760839367088607, -1.462904827586207 ], [ 29.760839367088607, -1.462365862068966 ], [ 29.760569873417722, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24818, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.462365862068966 ], [ 29.760839367088607, -1.462904827586207 ], [ 29.761108860759492, -1.462904827586207 ], [ 29.761108860759492, -1.462365862068966 ], [ 29.760839367088607, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24819, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.462365862068966 ], [ 29.761108860759492, -1.463174310344828 ], [ 29.761378354430381, -1.463174310344828 ], [ 29.761378354430381, -1.462365862068966 ], [ 29.761108860759492, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24820, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.462365862068966 ], [ 29.761378354430381, -1.462904827586207 ], [ 29.761647848101266, -1.462904827586207 ], [ 29.761647848101266, -1.462365862068966 ], [ 29.761378354430381, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24821, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.462365862068966 ], [ 29.761647848101266, -1.462904827586207 ], [ 29.761917341772151, -1.462904827586207 ], [ 29.761917341772151, -1.462365862068966 ], [ 29.761647848101266, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24822, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.462365862068966 ], [ 29.762186835443039, -1.462904827586207 ], [ 29.762456329113924, -1.462904827586207 ], [ 29.762456329113924, -1.462365862068966 ], [ 29.762186835443039, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24823, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.462365862068966 ], [ 29.762456329113924, -1.462904827586207 ], [ 29.762725822784809, -1.462904827586207 ], [ 29.762725822784809, -1.462365862068966 ], [ 29.762456329113924, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24824, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.462365862068966 ], [ 29.762725822784809, -1.462904827586207 ], [ 29.762995316455697, -1.462904827586207 ], [ 29.762995316455697, -1.462365862068966 ], [ 29.762725822784809, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24825, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.462365862068966 ], [ 29.762995316455697, -1.462904827586207 ], [ 29.763264810126582, -1.462904827586207 ], [ 29.763264810126582, -1.462365862068966 ], [ 29.762995316455697, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24826, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.462365862068966 ], [ 29.763264810126582, -1.462904827586207 ], [ 29.763534303797467, -1.462904827586207 ], [ 29.763534303797467, -1.462365862068966 ], [ 29.763264810126582, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24827, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.462365862068966 ], [ 29.763534303797467, -1.462904827586207 ], [ 29.763803797468356, -1.462904827586207 ], [ 29.763803797468356, -1.462365862068966 ], [ 29.763534303797467, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24828, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.462365862068966 ], [ 29.763803797468356, -1.462904827586207 ], [ 29.76407329113924, -1.462904827586207 ], [ 29.76407329113924, -1.462365862068966 ], [ 29.763803797468356, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24829, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.462365862068966 ], [ 29.76407329113924, -1.462904827586207 ], [ 29.764342784810125, -1.462904827586207 ], [ 29.764342784810125, -1.462365862068966 ], [ 29.76407329113924, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24830, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.462365862068966 ], [ 29.764342784810125, -1.462904827586207 ], [ 29.764612278481014, -1.462904827586207 ], [ 29.764612278481014, -1.462365862068966 ], [ 29.764342784810125, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24831, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.462365862068966 ], [ 29.764612278481014, -1.462904827586207 ], [ 29.764881772151899, -1.462904827586207 ], [ 29.764881772151899, -1.462365862068966 ], [ 29.764612278481014, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24832, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.462365862068966 ], [ 29.764881772151899, -1.462904827586207 ], [ 29.765151265822784, -1.462904827586207 ], [ 29.765151265822784, -1.462365862068966 ], [ 29.764881772151899, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24833, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.462365862068966 ], [ 29.765151265822784, -1.462904827586207 ], [ 29.765420759493672, -1.462904827586207 ], [ 29.765420759493672, -1.462365862068966 ], [ 29.765151265822784, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24834, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.462365862068966 ], [ 29.765420759493672, -1.462904827586207 ], [ 29.765959746835442, -1.462904827586207 ], [ 29.765959746835442, -1.462365862068966 ], [ 29.765420759493672, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24835, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.462365862068966 ], [ 29.765959746835442, -1.462904827586207 ], [ 29.766229240506327, -1.462904827586207 ], [ 29.766229240506327, -1.462365862068966 ], [ 29.765959746835442, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24836, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.462365862068966 ], [ 29.766229240506327, -1.462904827586207 ], [ 29.766498734177215, -1.462904827586207 ], [ 29.766498734177215, -1.462365862068966 ], [ 29.766229240506327, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24837, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.462365862068966 ], [ 29.766498734177215, -1.462904827586207 ], [ 29.7667682278481, -1.462904827586207 ], [ 29.7667682278481, -1.462365862068966 ], [ 29.766498734177215, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24838, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.462365862068966 ], [ 29.7667682278481, -1.462904827586207 ], [ 29.767037721518985, -1.462904827586207 ], [ 29.767037721518985, -1.462365862068966 ], [ 29.7667682278481, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24839, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.462365862068966 ], [ 29.767037721518985, -1.462904827586207 ], [ 29.767307215189874, -1.462904827586207 ], [ 29.767307215189874, -1.462365862068966 ], [ 29.767037721518985, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24840, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.462365862068966 ], [ 29.767307215189874, -1.462904827586207 ], [ 29.767846202531643, -1.462904827586207 ], [ 29.767846202531643, -1.462365862068966 ], [ 29.767307215189874, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24841, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.462365862068966 ], [ 29.767846202531643, -1.462904827586207 ], [ 29.768115696202532, -1.462904827586207 ], [ 29.768115696202532, -1.462365862068966 ], [ 29.767846202531643, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24842, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.462365862068966 ], [ 29.768115696202532, -1.462904827586207 ], [ 29.768385189873417, -1.462904827586207 ], [ 29.768385189873417, -1.462365862068966 ], [ 29.768115696202532, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24843, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.462365862068966 ], [ 29.768385189873417, -1.462904827586207 ], [ 29.76892417721519, -1.462904827586207 ], [ 29.76892417721519, -1.462365862068966 ], [ 29.768385189873417, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24844, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.462365862068966 ], [ 29.76892417721519, -1.462904827586207 ], [ 29.769193670886075, -1.462904827586207 ], [ 29.769193670886075, -1.462365862068966 ], [ 29.76892417721519, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24845, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.462365862068966 ], [ 29.769193670886075, -1.462904827586207 ], [ 29.76946316455696, -1.462904827586207 ], [ 29.76946316455696, -1.462365862068966 ], [ 29.769193670886075, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24846, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.462365862068966 ], [ 29.76946316455696, -1.462904827586207 ], [ 29.769732658227849, -1.462904827586207 ], [ 29.769732658227849, -1.462365862068966 ], [ 29.76946316455696, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24847, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.462365862068966 ], [ 29.769732658227849, -1.462904827586207 ], [ 29.770002151898733, -1.462904827586207 ], [ 29.770002151898733, -1.462365862068966 ], [ 29.769732658227849, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24848, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.462365862068966 ], [ 29.770002151898733, -1.462904827586207 ], [ 29.770271645569618, -1.462904827586207 ], [ 29.770271645569618, -1.462365862068966 ], [ 29.770002151898733, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24849, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.462365862068966 ], [ 29.770271645569618, -1.462904827586207 ], [ 29.770541139240507, -1.462904827586207 ], [ 29.770541139240507, -1.462365862068966 ], [ 29.770271645569618, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24850, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.462365862068966 ], [ 29.770541139240507, -1.462904827586207 ], [ 29.770810632911392, -1.462904827586207 ], [ 29.770810632911392, -1.462365862068966 ], [ 29.770541139240507, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24851, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.462365862068966 ], [ 29.770810632911392, -1.462904827586207 ], [ 29.771080126582277, -1.462904827586207 ], [ 29.771080126582277, -1.462365862068966 ], [ 29.770810632911392, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24852, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.462365862068966 ], [ 29.771080126582277, -1.462904827586207 ], [ 29.771349620253165, -1.462904827586207 ], [ 29.771349620253165, -1.462365862068966 ], [ 29.771080126582277, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24853, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.462365862068966 ], [ 29.771349620253165, -1.462904827586207 ], [ 29.77161911392405, -1.462904827586207 ], [ 29.77161911392405, -1.462365862068966 ], [ 29.771349620253165, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24854, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.462365862068966 ], [ 29.77161911392405, -1.462904827586207 ], [ 29.771888607594935, -1.462904827586207 ], [ 29.771888607594935, -1.462365862068966 ], [ 29.77161911392405, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24855, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.462365862068966 ], [ 29.771888607594935, -1.462904827586207 ], [ 29.772158101265823, -1.462904827586207 ], [ 29.772158101265823, -1.462365862068966 ], [ 29.771888607594935, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24856, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.462365862068966 ], [ 29.772158101265823, -1.462904827586207 ], [ 29.772427594936708, -1.462904827586207 ], [ 29.772427594936708, -1.462365862068966 ], [ 29.772158101265823, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24857, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.462365862068966 ], [ 29.772427594936708, -1.462904827586207 ], [ 29.772697088607593, -1.462904827586207 ], [ 29.772697088607593, -1.462365862068966 ], [ 29.772427594936708, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24858, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.462365862068966 ], [ 29.772697088607593, -1.462904827586207 ], [ 29.772966582278482, -1.462904827586207 ], [ 29.772966582278482, -1.462365862068966 ], [ 29.772697088607593, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24859, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.462365862068966 ], [ 29.772966582278482, -1.462904827586207 ], [ 29.773236075949367, -1.462904827586207 ], [ 29.773236075949367, -1.462365862068966 ], [ 29.772966582278482, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24860, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.462365862068966 ], [ 29.773236075949367, -1.463174310344828 ], [ 29.773505569620252, -1.463174310344828 ], [ 29.773505569620252, -1.462365862068966 ], [ 29.773236075949367, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24861, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.462365862068966 ], [ 29.773505569620252, -1.462904827586207 ], [ 29.77377506329114, -1.462904827586207 ], [ 29.77377506329114, -1.462365862068966 ], [ 29.773505569620252, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24862, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.462365862068966 ], [ 29.77377506329114, -1.462904827586207 ], [ 29.774044556962025, -1.462904827586207 ], [ 29.774044556962025, -1.462365862068966 ], [ 29.77377506329114, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24863, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.462365862068966 ], [ 29.774044556962025, -1.463174310344828 ], [ 29.77431405063291, -1.463174310344828 ], [ 29.77431405063291, -1.462365862068966 ], [ 29.774044556962025, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24864, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.462365862068966 ], [ 29.77431405063291, -1.462904827586207 ], [ 29.774853037974683, -1.462904827586207 ], [ 29.774853037974683, -1.462365862068966 ], [ 29.77431405063291, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24865, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.462365862068966 ], [ 29.774853037974683, -1.462904827586207 ], [ 29.775122531645568, -1.462904827586207 ], [ 29.775122531645568, -1.462365862068966 ], [ 29.774853037974683, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24866, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.462365862068966 ], [ 29.775122531645568, -1.463174310344828 ], [ 29.775392025316457, -1.463174310344828 ], [ 29.775392025316457, -1.462365862068966 ], [ 29.775122531645568, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24867, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.462365862068966 ], [ 29.775661518987341, -1.462904827586207 ], [ 29.775931012658226, -1.462904827586207 ], [ 29.775931012658226, -1.462365862068966 ], [ 29.775661518987341, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24868, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.462365862068966 ], [ 29.775931012658226, -1.463174310344828 ], [ 29.776200506329115, -1.463174310344828 ], [ 29.776200506329115, -1.462365862068966 ], [ 29.775931012658226, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24869, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.462365862068966 ], [ 29.77647, -1.463174310344828 ], [ 29.776739493670885, -1.463174310344828 ], [ 29.776739493670885, -1.462365862068966 ], [ 29.77647, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24870, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.462365862068966 ], [ 29.776739493670885, -1.463174310344828 ], [ 29.777008987341773, -1.463174310344828 ], [ 29.777008987341773, -1.462365862068966 ], [ 29.776739493670885, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24871, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.462365862068966 ], [ 29.777278481012658, -1.463174310344828 ], [ 29.777547974683543, -1.463174310344828 ], [ 29.777547974683543, -1.462365862068966 ], [ 29.777278481012658, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24872, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.462365862068966 ], [ 29.778086962025316, -1.462904827586207 ], [ 29.778356455696201, -1.462904827586207 ], [ 29.778356455696201, -1.462365862068966 ], [ 29.778086962025316, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24873, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.462365862068966 ], [ 29.786980253164558, -1.462904827586207 ], [ 29.787249746835442, -1.462904827586207 ], [ 29.787249746835442, -1.462365862068966 ], [ 29.786980253164558, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24874, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.462365862068966 ], [ 29.787249746835442, -1.462904827586207 ], [ 29.787519240506327, -1.462904827586207 ], [ 29.787519240506327, -1.462365862068966 ], [ 29.787249746835442, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24875, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.462365862068966 ], [ 29.787519240506327, -1.462904827586207 ], [ 29.787788734177216, -1.462904827586207 ], [ 29.787788734177216, -1.462365862068966 ], [ 29.787519240506327, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24876, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.462365862068966 ], [ 29.787788734177216, -1.462904827586207 ], [ 29.788058227848101, -1.462904827586207 ], [ 29.788058227848101, -1.462365862068966 ], [ 29.787788734177216, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24877, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.462365862068966 ], [ 29.793178607594935, -1.463174310344828 ], [ 29.793448101265824, -1.463174310344828 ], [ 29.793448101265824, -1.462365862068966 ], [ 29.793178607594935, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24878, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.462365862068966 ], [ 29.793448101265824, -1.463174310344828 ], [ 29.793717594936709, -1.463174310344828 ], [ 29.793717594936709, -1.462365862068966 ], [ 29.793448101265824, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24879, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.462365862068966 ], [ 29.793717594936709, -1.462904827586207 ], [ 29.793987088607594, -1.462904827586207 ], [ 29.793987088607594, -1.462365862068966 ], [ 29.793717594936709, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24880, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.462365862068966 ], [ 29.793987088607594, -1.462904827586207 ], [ 29.794256582278482, -1.462904827586207 ], [ 29.794256582278482, -1.462365862068966 ], [ 29.793987088607594, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24881, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.462365862068966 ], [ 29.794526075949367, -1.462904827586207 ], [ 29.794795569620252, -1.462904827586207 ], [ 29.794795569620252, -1.462365862068966 ], [ 29.794526075949367, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24882, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.462365862068966 ], [ 29.794795569620252, -1.462904827586207 ], [ 29.79506506329114, -1.462904827586207 ], [ 29.79506506329114, -1.462365862068966 ], [ 29.794795569620252, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24883, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.462365862068966 ], [ 29.79506506329114, -1.462904827586207 ], [ 29.795334556962025, -1.462904827586207 ], [ 29.795334556962025, -1.462365862068966 ], [ 29.79506506329114, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24884, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.462365862068966 ], [ 29.795334556962025, -1.462904827586207 ], [ 29.79560405063291, -1.462904827586207 ], [ 29.79560405063291, -1.462365862068966 ], [ 29.795334556962025, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24885, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.462365862068966 ], [ 29.79560405063291, -1.462904827586207 ], [ 29.795873544303799, -1.462904827586207 ], [ 29.795873544303799, -1.462365862068966 ], [ 29.79560405063291, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24886, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.462365862068966 ], [ 29.795873544303799, -1.462904827586207 ], [ 29.796143037974684, -1.462904827586207 ], [ 29.796143037974684, -1.462365862068966 ], [ 29.795873544303799, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24887, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.462365862068966 ], [ 29.796143037974684, -1.462904827586207 ], [ 29.796412531645569, -1.462904827586207 ], [ 29.796412531645569, -1.462365862068966 ], [ 29.796143037974684, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24888, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.462365862068966 ], [ 29.796412531645569, -1.462904827586207 ], [ 29.796682025316457, -1.462904827586207 ], [ 29.796682025316457, -1.462365862068966 ], [ 29.796412531645569, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24889, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.462365862068966 ], [ 29.796682025316457, -1.462904827586207 ], [ 29.796951518987342, -1.462904827586207 ], [ 29.796951518987342, -1.462365862068966 ], [ 29.796682025316457, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24890, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.462365862068966 ], [ 29.797490506329115, -1.463174310344828 ], [ 29.79776, -1.463174310344828 ], [ 29.79776, -1.462365862068966 ], [ 29.797490506329115, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24891, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.462365862068966 ], [ 29.798837974683543, -1.463174310344828 ], [ 29.799107468354432, -1.463174310344828 ], [ 29.799107468354432, -1.462365862068966 ], [ 29.798837974683543, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24892, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.462365862068966 ], [ 29.799646455696202, -1.463174310344828 ], [ 29.79991594936709, -1.463174310344828 ], [ 29.79991594936709, -1.462365862068966 ], [ 29.799646455696202, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24893, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.462365862068966 ], [ 29.79991594936709, -1.463174310344828 ], [ 29.800185443037975, -1.463174310344828 ], [ 29.800185443037975, -1.462365862068966 ], [ 29.79991594936709, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24894, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.462365862068966 ], [ 29.800185443037975, -1.463174310344828 ], [ 29.80045493670886, -1.463174310344828 ], [ 29.80045493670886, -1.462365862068966 ], [ 29.800185443037975, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24895, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.462904827586207 ], [ 29.753563037974683, -1.463174310344828 ], [ 29.753832531645568, -1.463174310344828 ], [ 29.753832531645568, -1.462904827586207 ], [ 29.753563037974683, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24896, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.462904827586207 ], [ 29.753832531645568, -1.463174310344828 ], [ 29.754102025316456, -1.463174310344828 ], [ 29.754102025316456, -1.462904827586207 ], [ 29.753832531645568, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24897, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.462904827586207 ], [ 29.754102025316456, -1.463174310344828 ], [ 29.754371518987341, -1.463174310344828 ], [ 29.754371518987341, -1.462904827586207 ], [ 29.754102025316456, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24898, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.462904827586207 ], [ 29.75518, -1.463174310344828 ], [ 29.755449493670884, -1.463174310344828 ], [ 29.755449493670884, -1.462904827586207 ], [ 29.75518, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24899, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.462904827586207 ], [ 29.755449493670884, -1.463174310344828 ], [ 29.755718987341773, -1.463174310344828 ], [ 29.755718987341773, -1.462904827586207 ], [ 29.755449493670884, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24900, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.462904827586207 ], [ 29.755718987341773, -1.463174310344828 ], [ 29.755988481012658, -1.463174310344828 ], [ 29.755988481012658, -1.462904827586207 ], [ 29.755718987341773, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24901, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.462904827586207 ], [ 29.755988481012658, -1.463174310344828 ], [ 29.756257974683542, -1.463174310344828 ], [ 29.756257974683542, -1.462904827586207 ], [ 29.755988481012658, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24902, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.462904827586207 ], [ 29.756257974683542, -1.463174310344828 ], [ 29.756796962025316, -1.463174310344828 ], [ 29.756796962025316, -1.462904827586207 ], [ 29.756257974683542, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24903, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.462904827586207 ], [ 29.756796962025316, -1.463174310344828 ], [ 29.757066455696201, -1.463174310344828 ], [ 29.757066455696201, -1.462904827586207 ], [ 29.756796962025316, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24904, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.462904827586207 ], [ 29.757066455696201, -1.463174310344828 ], [ 29.757335949367089, -1.463174310344828 ], [ 29.757335949367089, -1.462904827586207 ], [ 29.757066455696201, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24905, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.462904827586207 ], [ 29.757335949367089, -1.463174310344828 ], [ 29.757605443037974, -1.463174310344828 ], [ 29.757605443037974, -1.462904827586207 ], [ 29.757335949367089, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24906, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.462904827586207 ], [ 29.757605443037974, -1.463174310344828 ], [ 29.757874936708859, -1.463174310344828 ], [ 29.757874936708859, -1.462904827586207 ], [ 29.757605443037974, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24907, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.462904827586207 ], [ 29.757874936708859, -1.463174310344828 ], [ 29.758144430379748, -1.463174310344828 ], [ 29.758144430379748, -1.462904827586207 ], [ 29.757874936708859, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24908, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.462904827586207 ], [ 29.758144430379748, -1.463174310344828 ], [ 29.758413924050632, -1.463174310344828 ], [ 29.758413924050632, -1.462904827586207 ], [ 29.758144430379748, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24909, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.462904827586207 ], [ 29.758413924050632, -1.463174310344828 ], [ 29.758683417721517, -1.463174310344828 ], [ 29.758683417721517, -1.462904827586207 ], [ 29.758413924050632, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24910, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.462904827586207 ], [ 29.758683417721517, -1.463174310344828 ], [ 29.758952911392406, -1.463174310344828 ], [ 29.758952911392406, -1.462904827586207 ], [ 29.758683417721517, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24911, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.462904827586207 ], [ 29.758952911392406, -1.463174310344828 ], [ 29.759222405063291, -1.463174310344828 ], [ 29.759222405063291, -1.462904827586207 ], [ 29.758952911392406, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24912, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.462904827586207 ], [ 29.759222405063291, -1.463174310344828 ], [ 29.759491898734176, -1.463174310344828 ], [ 29.759491898734176, -1.462904827586207 ], [ 29.759222405063291, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24913, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.462904827586207 ], [ 29.759491898734176, -1.463174310344828 ], [ 29.759761392405064, -1.463174310344828 ], [ 29.759761392405064, -1.462904827586207 ], [ 29.759491898734176, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24914, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.462904827586207 ], [ 29.759761392405064, -1.463174310344828 ], [ 29.760030886075949, -1.463174310344828 ], [ 29.760030886075949, -1.462904827586207 ], [ 29.759761392405064, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24915, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.462904827586207 ], [ 29.760030886075949, -1.463174310344828 ], [ 29.760300379746834, -1.463174310344828 ], [ 29.760300379746834, -1.462904827586207 ], [ 29.760030886075949, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24916, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.462904827586207 ], [ 29.760300379746834, -1.463174310344828 ], [ 29.760569873417722, -1.463174310344828 ], [ 29.760569873417722, -1.462904827586207 ], [ 29.760300379746834, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24917, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.462904827586207 ], [ 29.760569873417722, -1.463174310344828 ], [ 29.760839367088607, -1.463174310344828 ], [ 29.760839367088607, -1.462904827586207 ], [ 29.760569873417722, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24918, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.462904827586207 ], [ 29.760839367088607, -1.463174310344828 ], [ 29.761108860759492, -1.463174310344828 ], [ 29.761108860759492, -1.462904827586207 ], [ 29.760839367088607, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24919, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.462904827586207 ], [ 29.761647848101266, -1.463174310344828 ], [ 29.761917341772151, -1.463174310344828 ], [ 29.761917341772151, -1.462904827586207 ], [ 29.761647848101266, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24920, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.462904827586207 ], [ 29.761917341772151, -1.463174310344828 ], [ 29.762186835443039, -1.463174310344828 ], [ 29.762186835443039, -1.462904827586207 ], [ 29.761917341772151, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24921, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.462904827586207 ], [ 29.762456329113924, -1.463174310344828 ], [ 29.762725822784809, -1.463174310344828 ], [ 29.762725822784809, -1.462904827586207 ], [ 29.762456329113924, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24922, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.462904827586207 ], [ 29.762725822784809, -1.463174310344828 ], [ 29.762995316455697, -1.463174310344828 ], [ 29.762995316455697, -1.462904827586207 ], [ 29.762725822784809, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24923, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.462904827586207 ], [ 29.763264810126582, -1.463174310344828 ], [ 29.763534303797467, -1.463174310344828 ], [ 29.763534303797467, -1.462904827586207 ], [ 29.763264810126582, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24924, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.462904827586207 ], [ 29.763534303797467, -1.463174310344828 ], [ 29.763803797468356, -1.463174310344828 ], [ 29.763803797468356, -1.462904827586207 ], [ 29.763534303797467, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24925, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.462904827586207 ], [ 29.763803797468356, -1.463174310344828 ], [ 29.76407329113924, -1.463174310344828 ], [ 29.76407329113924, -1.462904827586207 ], [ 29.763803797468356, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24926, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.462904827586207 ], [ 29.76407329113924, -1.463174310344828 ], [ 29.764342784810125, -1.463174310344828 ], [ 29.764342784810125, -1.462904827586207 ], [ 29.76407329113924, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24927, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.462904827586207 ], [ 29.764342784810125, -1.463174310344828 ], [ 29.764612278481014, -1.463174310344828 ], [ 29.764612278481014, -1.462904827586207 ], [ 29.764342784810125, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24928, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.462904827586207 ], [ 29.764612278481014, -1.463174310344828 ], [ 29.764881772151899, -1.463174310344828 ], [ 29.764881772151899, -1.462904827586207 ], [ 29.764612278481014, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24929, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.462904827586207 ], [ 29.764881772151899, -1.463174310344828 ], [ 29.765151265822784, -1.463174310344828 ], [ 29.765151265822784, -1.462904827586207 ], [ 29.764881772151899, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24930, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.462904827586207 ], [ 29.765151265822784, -1.463174310344828 ], [ 29.765420759493672, -1.463174310344828 ], [ 29.765420759493672, -1.462904827586207 ], [ 29.765151265822784, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24931, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.462904827586207 ], [ 29.765420759493672, -1.463174310344828 ], [ 29.765959746835442, -1.463174310344828 ], [ 29.765959746835442, -1.462904827586207 ], [ 29.765420759493672, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24932, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.462904827586207 ], [ 29.765959746835442, -1.463174310344828 ], [ 29.766229240506327, -1.463174310344828 ], [ 29.766229240506327, -1.462904827586207 ], [ 29.765959746835442, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24933, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.462904827586207 ], [ 29.766229240506327, -1.463174310344828 ], [ 29.766498734177215, -1.463174310344828 ], [ 29.766498734177215, -1.462904827586207 ], [ 29.766229240506327, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24934, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.462904827586207 ], [ 29.766498734177215, -1.463174310344828 ], [ 29.7667682278481, -1.463174310344828 ], [ 29.7667682278481, -1.462904827586207 ], [ 29.766498734177215, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24935, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.462904827586207 ], [ 29.7667682278481, -1.463174310344828 ], [ 29.767037721518985, -1.463174310344828 ], [ 29.767037721518985, -1.462904827586207 ], [ 29.7667682278481, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24936, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.462904827586207 ], [ 29.767037721518985, -1.463174310344828 ], [ 29.767307215189874, -1.463174310344828 ], [ 29.767307215189874, -1.462904827586207 ], [ 29.767037721518985, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24937, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.462904827586207 ], [ 29.767307215189874, -1.463174310344828 ], [ 29.767576708860759, -1.463174310344828 ], [ 29.767576708860759, -1.462904827586207 ], [ 29.767307215189874, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24938, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.462904827586207 ], [ 29.767576708860759, -1.463174310344828 ], [ 29.768115696202532, -1.463174310344828 ], [ 29.768115696202532, -1.462904827586207 ], [ 29.767576708860759, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24939, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.462904827586207 ], [ 29.768115696202532, -1.463174310344828 ], [ 29.768385189873417, -1.463174310344828 ], [ 29.768385189873417, -1.462904827586207 ], [ 29.768115696202532, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24940, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.462904827586207 ], [ 29.768385189873417, -1.463174310344828 ], [ 29.76892417721519, -1.463174310344828 ], [ 29.76892417721519, -1.462904827586207 ], [ 29.768385189873417, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24941, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.462904827586207 ], [ 29.76892417721519, -1.463174310344828 ], [ 29.769193670886075, -1.463174310344828 ], [ 29.769193670886075, -1.462904827586207 ], [ 29.76892417721519, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24942, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.462904827586207 ], [ 29.769193670886075, -1.463174310344828 ], [ 29.76946316455696, -1.463174310344828 ], [ 29.76946316455696, -1.462904827586207 ], [ 29.769193670886075, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24943, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.462904827586207 ], [ 29.76946316455696, -1.463174310344828 ], [ 29.769732658227849, -1.463174310344828 ], [ 29.769732658227849, -1.462904827586207 ], [ 29.76946316455696, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24944, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.462904827586207 ], [ 29.769732658227849, -1.463174310344828 ], [ 29.770002151898733, -1.463174310344828 ], [ 29.770002151898733, -1.462904827586207 ], [ 29.769732658227849, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24945, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.462904827586207 ], [ 29.770002151898733, -1.463174310344828 ], [ 29.770271645569618, -1.463174310344828 ], [ 29.770271645569618, -1.462904827586207 ], [ 29.770002151898733, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24946, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.462904827586207 ], [ 29.770271645569618, -1.463174310344828 ], [ 29.770541139240507, -1.463174310344828 ], [ 29.770541139240507, -1.462904827586207 ], [ 29.770271645569618, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24947, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.462904827586207 ], [ 29.770541139240507, -1.463174310344828 ], [ 29.770810632911392, -1.463174310344828 ], [ 29.770810632911392, -1.462904827586207 ], [ 29.770541139240507, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24948, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.462904827586207 ], [ 29.770810632911392, -1.463174310344828 ], [ 29.771080126582277, -1.463174310344828 ], [ 29.771080126582277, -1.462904827586207 ], [ 29.770810632911392, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24949, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.462904827586207 ], [ 29.771080126582277, -1.463174310344828 ], [ 29.771349620253165, -1.463174310344828 ], [ 29.771349620253165, -1.462904827586207 ], [ 29.771080126582277, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24950, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.462904827586207 ], [ 29.771349620253165, -1.463174310344828 ], [ 29.77161911392405, -1.463174310344828 ], [ 29.77161911392405, -1.462904827586207 ], [ 29.771349620253165, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24951, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.462904827586207 ], [ 29.77161911392405, -1.463174310344828 ], [ 29.771888607594935, -1.463174310344828 ], [ 29.771888607594935, -1.462904827586207 ], [ 29.77161911392405, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24952, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.462904827586207 ], [ 29.771888607594935, -1.463174310344828 ], [ 29.772158101265823, -1.463174310344828 ], [ 29.772158101265823, -1.462904827586207 ], [ 29.771888607594935, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24953, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.462904827586207 ], [ 29.772427594936708, -1.463174310344828 ], [ 29.772697088607593, -1.463174310344828 ], [ 29.772697088607593, -1.462904827586207 ], [ 29.772427594936708, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24954, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.462904827586207 ], [ 29.772697088607593, -1.463174310344828 ], [ 29.772966582278482, -1.463174310344828 ], [ 29.772966582278482, -1.462904827586207 ], [ 29.772697088607593, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24955, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.462904827586207 ], [ 29.773505569620252, -1.463174310344828 ], [ 29.77377506329114, -1.463174310344828 ], [ 29.77377506329114, -1.462904827586207 ], [ 29.773505569620252, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24956, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.462904827586207 ], [ 29.777547974683543, -1.463174310344828 ], [ 29.777817468354431, -1.463174310344828 ], [ 29.777817468354431, -1.462904827586207 ], [ 29.777547974683543, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24957, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.462904827586207 ], [ 29.777817468354431, -1.463174310344828 ], [ 29.778086962025316, -1.463174310344828 ], [ 29.778086962025316, -1.462904827586207 ], [ 29.777817468354431, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24958, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.462904827586207 ], [ 29.778086962025316, -1.463174310344828 ], [ 29.778356455696201, -1.463174310344828 ], [ 29.778356455696201, -1.462904827586207 ], [ 29.778086962025316, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24959, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.462904827586207 ], [ 29.778356455696201, -1.463174310344828 ], [ 29.77862594936709, -1.463174310344828 ], [ 29.77862594936709, -1.462904827586207 ], [ 29.778356455696201, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24960, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.462904827586207 ], [ 29.784824303797468, -1.463174310344828 ], [ 29.785093797468356, -1.463174310344828 ], [ 29.785093797468356, -1.462904827586207 ], [ 29.784824303797468, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24961, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.462904827586207 ], [ 29.786171772151899, -1.463174310344828 ], [ 29.786441265822784, -1.463174310344828 ], [ 29.786441265822784, -1.462904827586207 ], [ 29.786171772151899, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24962, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.462904827586207 ], [ 29.786980253164558, -1.463174310344828 ], [ 29.787249746835442, -1.463174310344828 ], [ 29.787249746835442, -1.462904827586207 ], [ 29.786980253164558, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24963, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.462904827586207 ], [ 29.787249746835442, -1.463174310344828 ], [ 29.787519240506327, -1.463174310344828 ], [ 29.787519240506327, -1.462904827586207 ], [ 29.787249746835442, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24964, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.462904827586207 ], [ 29.787519240506327, -1.463174310344828 ], [ 29.787788734177216, -1.463174310344828 ], [ 29.787788734177216, -1.462904827586207 ], [ 29.787519240506327, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24965, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.462904827586207 ], [ 29.793717594936709, -1.463174310344828 ], [ 29.793987088607594, -1.463174310344828 ], [ 29.793987088607594, -1.462904827586207 ], [ 29.793717594936709, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24966, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.462365862068966 ], [ 29.794526075949367, -1.462365862068966 ], [ 29.794526075949367, -1.463174310344828 ], [ 29.793987088607594, -1.463174310344828 ], [ 29.793987088607594, -1.462904827586207 ], [ 29.794256582278482, -1.462904827586207 ], [ 29.794256582278482, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24967, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.462904827586207 ], [ 29.794526075949367, -1.463174310344828 ], [ 29.794795569620252, -1.463174310344828 ], [ 29.794795569620252, -1.462904827586207 ], [ 29.794526075949367, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24968, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.462904827586207 ], [ 29.794795569620252, -1.463174310344828 ], [ 29.79506506329114, -1.463174310344828 ], [ 29.79506506329114, -1.462904827586207 ], [ 29.794795569620252, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24969, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.462904827586207 ], [ 29.79506506329114, -1.463174310344828 ], [ 29.795334556962025, -1.463174310344828 ], [ 29.795334556962025, -1.462904827586207 ], [ 29.79506506329114, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24970, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.462904827586207 ], [ 29.795334556962025, -1.463174310344828 ], [ 29.79560405063291, -1.463174310344828 ], [ 29.79560405063291, -1.462904827586207 ], [ 29.795334556962025, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24971, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.462904827586207 ], [ 29.79560405063291, -1.463174310344828 ], [ 29.795873544303799, -1.463174310344828 ], [ 29.795873544303799, -1.462904827586207 ], [ 29.79560405063291, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24972, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.462904827586207 ], [ 29.795873544303799, -1.463174310344828 ], [ 29.796143037974684, -1.463174310344828 ], [ 29.796143037974684, -1.462904827586207 ], [ 29.795873544303799, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24973, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.462904827586207 ], [ 29.796143037974684, -1.463174310344828 ], [ 29.796412531645569, -1.463174310344828 ], [ 29.796412531645569, -1.462904827586207 ], [ 29.796143037974684, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24974, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.462904827586207 ], [ 29.796412531645569, -1.463174310344828 ], [ 29.796682025316457, -1.463174310344828 ], [ 29.796682025316457, -1.462904827586207 ], [ 29.796412531645569, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24975, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.462904827586207 ], [ 29.796682025316457, -1.463174310344828 ], [ 29.796951518987342, -1.463174310344828 ], [ 29.796951518987342, -1.462904827586207 ], [ 29.796682025316457, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24976, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.462904827586207 ], [ 29.796951518987342, -1.463174310344828 ], [ 29.797221012658227, -1.463174310344828 ], [ 29.797221012658227, -1.462904827586207 ], [ 29.796951518987342, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24977, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.461826896551724 ], [ 29.779434430379748, -1.46398275862069 ], [ 29.779703924050633, -1.46398275862069 ], [ 29.779703924050633, -1.461826896551724 ], [ 29.779434430379748, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24978, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.461826896551724 ], [ 29.779703924050633, -1.46398275862069 ], [ 29.779973417721518, -1.46398275862069 ], [ 29.779973417721518, -1.461826896551724 ], [ 29.779703924050633, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24979, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.461826896551724 ], [ 29.780242911392406, -1.46398275862069 ], [ 29.780512405063291, -1.46398275862069 ], [ 29.780512405063291, -1.461826896551724 ], [ 29.780242911392406, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24980, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.461826896551724 ], [ 29.781051392405065, -1.46398275862069 ], [ 29.78132088607595, -1.46398275862069 ], [ 29.78132088607595, -1.461826896551724 ], [ 29.781051392405065, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24981, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.461826896551724 ], [ 29.781590379746834, -1.46398275862069 ], [ 29.781859873417723, -1.46398275862069 ], [ 29.781859873417723, -1.461826896551724 ], [ 29.781590379746834, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24982, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.461826896551724 ], [ 29.782398860759493, -1.46398275862069 ], [ 29.782668354430381, -1.46398275862069 ], [ 29.782668354430381, -1.461826896551724 ], [ 29.782398860759493, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24983, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.461826896551724 ], [ 29.798029493670885, -1.463713275862069 ], [ 29.798298987341774, -1.463713275862069 ], [ 29.798298987341774, -1.461826896551724 ], [ 29.798029493670885, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24984, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.462096379310345 ], [ 29.779973417721518, -1.463713275862069 ], [ 29.780242911392406, -1.463713275862069 ], [ 29.780242911392406, -1.462096379310345 ], [ 29.779973417721518, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24985, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.462096379310345 ], [ 29.780781898734176, -1.463713275862069 ], [ 29.781051392405065, -1.463713275862069 ], [ 29.781051392405065, -1.462096379310345 ], [ 29.780781898734176, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24986, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.462096379310345 ], [ 29.78132088607595, -1.463713275862069 ], [ 29.781590379746834, -1.463713275862069 ], [ 29.781590379746834, -1.462096379310345 ], [ 29.78132088607595, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24987, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.462096379310345 ], [ 29.782129367088608, -1.463713275862069 ], [ 29.782398860759493, -1.463713275862069 ], [ 29.782398860759493, -1.462096379310345 ], [ 29.782129367088608, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24988, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.462096379310345 ], [ 29.782937848101266, -1.463713275862069 ], [ 29.783207341772151, -1.463713275862069 ], [ 29.783207341772151, -1.462096379310345 ], [ 29.782937848101266, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24989, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.462096379310345 ], [ 29.783476835443039, -1.463713275862069 ], [ 29.784015822784809, -1.463713275862069 ], [ 29.784015822784809, -1.462096379310345 ], [ 29.783476835443039, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24990, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.462096379310345 ], [ 29.784015822784809, -1.463713275862069 ], [ 29.784285316455698, -1.463713275862069 ], [ 29.784285316455698, -1.462096379310345 ], [ 29.784015822784809, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24991, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.462096379310345 ], [ 29.784554810126583, -1.463713275862069 ], [ 29.784824303797468, -1.463713275862069 ], [ 29.784824303797468, -1.462096379310345 ], [ 29.784554810126583, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24992, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.462096379310345 ], [ 29.785363291139241, -1.463713275862069 ], [ 29.785632784810126, -1.463713275862069 ], [ 29.785632784810126, -1.462096379310345 ], [ 29.785363291139241, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24993, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.462096379310345 ], [ 29.79776, -1.463443793103448 ], [ 29.798029493670885, -1.463443793103448 ], [ 29.798029493670885, -1.462096379310345 ], [ 29.79776, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24994, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.462096379310345 ], [ 29.798298987341774, -1.463443793103448 ], [ 29.798568481012659, -1.463443793103448 ], [ 29.798568481012659, -1.462096379310345 ], [ 29.798298987341774, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24995, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.462096379310345 ], [ 29.798568481012659, -1.463443793103448 ], [ 29.798837974683543, -1.463443793103448 ], [ 29.798837974683543, -1.462096379310345 ], [ 29.798568481012659, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24996, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.462096379310345 ], [ 29.799107468354432, -1.463443793103448 ], [ 29.799376962025317, -1.463443793103448 ], [ 29.799376962025317, -1.462096379310345 ], [ 29.799107468354432, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24997, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.462096379310345 ], [ 29.799376962025317, -1.463443793103448 ], [ 29.799646455696202, -1.463443793103448 ], [ 29.799646455696202, -1.462096379310345 ], [ 29.799376962025317, -1.462096379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24998, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.462365862068966 ], [ 29.77916493670886, -1.463443793103448 ], [ 29.779434430379748, -1.463443793103448 ], [ 29.779434430379748, -1.462365862068966 ], [ 29.77916493670886, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 24999, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.462365862068966 ], [ 29.780512405063291, -1.463443793103448 ], [ 29.780781898734176, -1.463443793103448 ], [ 29.780781898734176, -1.462365862068966 ], [ 29.780512405063291, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25000, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.462365862068966 ], [ 29.781859873417723, -1.463443793103448 ], [ 29.782129367088608, -1.463443793103448 ], [ 29.782129367088608, -1.462365862068966 ], [ 29.781859873417723, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25001, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.462365862068966 ], [ 29.782668354430381, -1.463443793103448 ], [ 29.782937848101266, -1.463443793103448 ], [ 29.782937848101266, -1.462365862068966 ], [ 29.782668354430381, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25002, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.462365862068966 ], [ 29.783207341772151, -1.463443793103448 ], [ 29.783476835443039, -1.463443793103448 ], [ 29.783476835443039, -1.462365862068966 ], [ 29.783207341772151, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25003, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.462365862068966 ], [ 29.784285316455698, -1.463443793103448 ], [ 29.784554810126583, -1.463443793103448 ], [ 29.784554810126583, -1.462365862068966 ], [ 29.784285316455698, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25004, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.462365862068966 ], [ 29.785093797468356, -1.463443793103448 ], [ 29.785363291139241, -1.463443793103448 ], [ 29.785363291139241, -1.462365862068966 ], [ 29.785093797468356, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25005, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.462365862068966 ], [ 29.785632784810126, -1.463443793103448 ], [ 29.785902278481014, -1.463443793103448 ], [ 29.785902278481014, -1.462365862068966 ], [ 29.785632784810126, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25006, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.462365862068966 ], [ 29.785902278481014, -1.463443793103448 ], [ 29.786171772151899, -1.463443793103448 ], [ 29.786171772151899, -1.462365862068966 ], [ 29.785902278481014, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25007, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.462365862068966 ], [ 29.786441265822784, -1.463443793103448 ], [ 29.786710759493673, -1.463443793103448 ], [ 29.786710759493673, -1.462365862068966 ], [ 29.786441265822784, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25008, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.462365862068966 ], [ 29.786710759493673, -1.463443793103448 ], [ 29.786980253164558, -1.463443793103448 ], [ 29.786980253164558, -1.462365862068966 ], [ 29.786710759493673, -1.462365862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25009, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.461826896551724 ], [ 29.797490506329115, -1.461826896551724 ], [ 29.797490506329115, -1.463443793103448 ], [ 29.797221012658227, -1.463443793103448 ], [ 29.797221012658227, -1.462904827586207 ], [ 29.796951518987342, -1.462904827586207 ], [ 29.796951518987342, -1.462365862068966 ], [ 29.797221012658227, -1.462365862068966 ], [ 29.797221012658227, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25010, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.462904827586207 ], [ 29.754371518987341, -1.463443793103448 ], [ 29.754641012658226, -1.463443793103448 ], [ 29.754641012658226, -1.462904827586207 ], [ 29.754371518987341, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25011, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.462904827586207 ], [ 29.754641012658226, -1.463443793103448 ], [ 29.754910506329114, -1.463443793103448 ], [ 29.754910506329114, -1.462904827586207 ], [ 29.754641012658226, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25012, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.462904827586207 ], [ 29.761378354430381, -1.463443793103448 ], [ 29.761647848101266, -1.463443793103448 ], [ 29.761647848101266, -1.462904827586207 ], [ 29.761378354430381, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25013, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.462904827586207 ], [ 29.762186835443039, -1.463443793103448 ], [ 29.762456329113924, -1.463443793103448 ], [ 29.762456329113924, -1.462904827586207 ], [ 29.762186835443039, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25014, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.462904827586207 ], [ 29.762995316455697, -1.463443793103448 ], [ 29.763264810126582, -1.463443793103448 ], [ 29.763264810126582, -1.462904827586207 ], [ 29.762995316455697, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25015, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.462904827586207 ], [ 29.772158101265823, -1.463443793103448 ], [ 29.772427594936708, -1.463443793103448 ], [ 29.772427594936708, -1.462904827586207 ], [ 29.772158101265823, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25016, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.462904827586207 ], [ 29.772966582278482, -1.463443793103448 ], [ 29.773236075949367, -1.463443793103448 ], [ 29.773236075949367, -1.462904827586207 ], [ 29.772966582278482, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25017, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.462904827586207 ], [ 29.77377506329114, -1.463443793103448 ], [ 29.774044556962025, -1.463443793103448 ], [ 29.774044556962025, -1.462904827586207 ], [ 29.77377506329114, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25018, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.462904827586207 ], [ 29.77431405063291, -1.463443793103448 ], [ 29.774853037974683, -1.463443793103448 ], [ 29.774853037974683, -1.462904827586207 ], [ 29.77431405063291, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25019, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.462904827586207 ], [ 29.774853037974683, -1.463713275862069 ], [ 29.775122531645568, -1.463713275862069 ], [ 29.775122531645568, -1.462904827586207 ], [ 29.774853037974683, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25020, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.462904827586207 ], [ 29.775392025316457, -1.463443793103448 ], [ 29.775661518987341, -1.463443793103448 ], [ 29.775661518987341, -1.462904827586207 ], [ 29.775392025316457, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25021, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.462904827586207 ], [ 29.775661518987341, -1.463713275862069 ], [ 29.775931012658226, -1.463713275862069 ], [ 29.775931012658226, -1.462904827586207 ], [ 29.775661518987341, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25022, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.462904827586207 ], [ 29.776200506329115, -1.463443793103448 ], [ 29.77647, -1.463443793103448 ], [ 29.77647, -1.462904827586207 ], [ 29.776200506329115, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25023, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.462904827586207 ], [ 29.777008987341773, -1.463443793103448 ], [ 29.777278481012658, -1.463443793103448 ], [ 29.777278481012658, -1.462904827586207 ], [ 29.777008987341773, -1.462904827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25024, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753563037974683, -1.463174310344828 ], [ 29.753563037974683, -1.46398275862069 ], [ 29.753832531645568, -1.46398275862069 ], [ 29.753832531645568, -1.463174310344828 ], [ 29.753563037974683, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25025, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.463174310344828 ], [ 29.753832531645568, -1.463713275862069 ], [ 29.754102025316456, -1.463713275862069 ], [ 29.754102025316456, -1.463174310344828 ], [ 29.753832531645568, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25026, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.463174310344828 ], [ 29.754102025316456, -1.463713275862069 ], [ 29.754371518987341, -1.463713275862069 ], [ 29.754371518987341, -1.463174310344828 ], [ 29.754102025316456, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25027, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.463174310344828 ], [ 29.754910506329114, -1.463443793103448 ], [ 29.75518, -1.463443793103448 ], [ 29.75518, -1.463174310344828 ], [ 29.754910506329114, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25028, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.463174310344828 ], [ 29.75518, -1.463443793103448 ], [ 29.755449493670884, -1.463443793103448 ], [ 29.755449493670884, -1.463174310344828 ], [ 29.75518, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25029, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.463174310344828 ], [ 29.755449493670884, -1.463443793103448 ], [ 29.755718987341773, -1.463443793103448 ], [ 29.755718987341773, -1.463174310344828 ], [ 29.755449493670884, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25030, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.463174310344828 ], [ 29.755718987341773, -1.463443793103448 ], [ 29.755988481012658, -1.463443793103448 ], [ 29.755988481012658, -1.463174310344828 ], [ 29.755718987341773, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25031, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.463174310344828 ], [ 29.755988481012658, -1.463443793103448 ], [ 29.756257974683542, -1.463443793103448 ], [ 29.756257974683542, -1.463174310344828 ], [ 29.755988481012658, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25032, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.463174310344828 ], [ 29.756257974683542, -1.463443793103448 ], [ 29.756796962025316, -1.463443793103448 ], [ 29.756796962025316, -1.463174310344828 ], [ 29.756257974683542, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25033, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.463174310344828 ], [ 29.756796962025316, -1.463443793103448 ], [ 29.757066455696201, -1.463443793103448 ], [ 29.757066455696201, -1.463174310344828 ], [ 29.756796962025316, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25034, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.463174310344828 ], [ 29.757066455696201, -1.463443793103448 ], [ 29.757335949367089, -1.463443793103448 ], [ 29.757335949367089, -1.463174310344828 ], [ 29.757066455696201, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25035, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.463174310344828 ], [ 29.757335949367089, -1.463443793103448 ], [ 29.757605443037974, -1.463443793103448 ], [ 29.757605443037974, -1.463174310344828 ], [ 29.757335949367089, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25036, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.463174310344828 ], [ 29.757605443037974, -1.463443793103448 ], [ 29.757874936708859, -1.463443793103448 ], [ 29.757874936708859, -1.463174310344828 ], [ 29.757605443037974, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25037, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.463174310344828 ], [ 29.757874936708859, -1.463443793103448 ], [ 29.758144430379748, -1.463443793103448 ], [ 29.758144430379748, -1.463174310344828 ], [ 29.757874936708859, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25038, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.463174310344828 ], [ 29.758144430379748, -1.463443793103448 ], [ 29.758413924050632, -1.463443793103448 ], [ 29.758413924050632, -1.463174310344828 ], [ 29.758144430379748, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25039, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.463174310344828 ], [ 29.758413924050632, -1.463443793103448 ], [ 29.758683417721517, -1.463443793103448 ], [ 29.758683417721517, -1.463174310344828 ], [ 29.758413924050632, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25040, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.463174310344828 ], [ 29.758683417721517, -1.463443793103448 ], [ 29.758952911392406, -1.463443793103448 ], [ 29.758952911392406, -1.463174310344828 ], [ 29.758683417721517, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25041, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.463174310344828 ], [ 29.758952911392406, -1.463443793103448 ], [ 29.759222405063291, -1.463443793103448 ], [ 29.759222405063291, -1.463174310344828 ], [ 29.758952911392406, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25042, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.463174310344828 ], [ 29.759222405063291, -1.463443793103448 ], [ 29.759491898734176, -1.463443793103448 ], [ 29.759491898734176, -1.463174310344828 ], [ 29.759222405063291, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25043, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.463174310344828 ], [ 29.759491898734176, -1.463443793103448 ], [ 29.759761392405064, -1.463443793103448 ], [ 29.759761392405064, -1.463174310344828 ], [ 29.759491898734176, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25044, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.463174310344828 ], [ 29.759761392405064, -1.463443793103448 ], [ 29.760030886075949, -1.463443793103448 ], [ 29.760030886075949, -1.463174310344828 ], [ 29.759761392405064, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25045, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.463174310344828 ], [ 29.760030886075949, -1.463443793103448 ], [ 29.760300379746834, -1.463443793103448 ], [ 29.760300379746834, -1.463174310344828 ], [ 29.760030886075949, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25046, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.463174310344828 ], [ 29.760300379746834, -1.463443793103448 ], [ 29.760569873417722, -1.463443793103448 ], [ 29.760569873417722, -1.463174310344828 ], [ 29.760300379746834, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25047, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.463174310344828 ], [ 29.760569873417722, -1.463443793103448 ], [ 29.760839367088607, -1.463443793103448 ], [ 29.760839367088607, -1.463174310344828 ], [ 29.760569873417722, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25048, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.463174310344828 ], [ 29.761108860759492, -1.463713275862069 ], [ 29.761378354430381, -1.463713275862069 ], [ 29.761378354430381, -1.463174310344828 ], [ 29.761108860759492, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25049, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.463174310344828 ], [ 29.761647848101266, -1.46398275862069 ], [ 29.761917341772151, -1.46398275862069 ], [ 29.761917341772151, -1.463174310344828 ], [ 29.761647848101266, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25050, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.463174310344828 ], [ 29.761917341772151, -1.463713275862069 ], [ 29.762186835443039, -1.463713275862069 ], [ 29.762186835443039, -1.463174310344828 ], [ 29.761917341772151, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25051, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.463174310344828 ], [ 29.762456329113924, -1.463713275862069 ], [ 29.762725822784809, -1.463713275862069 ], [ 29.762725822784809, -1.463174310344828 ], [ 29.762456329113924, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25052, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.463174310344828 ], [ 29.762725822784809, -1.463443793103448 ], [ 29.762995316455697, -1.463443793103448 ], [ 29.762995316455697, -1.463174310344828 ], [ 29.762725822784809, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25053, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.463174310344828 ], [ 29.763264810126582, -1.463713275862069 ], [ 29.763534303797467, -1.463713275862069 ], [ 29.763534303797467, -1.463174310344828 ], [ 29.763264810126582, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25054, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.463174310344828 ], [ 29.763534303797467, -1.463443793103448 ], [ 29.763803797468356, -1.463443793103448 ], [ 29.763803797468356, -1.463174310344828 ], [ 29.763534303797467, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25055, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.463174310344828 ], [ 29.763803797468356, -1.463443793103448 ], [ 29.76407329113924, -1.463443793103448 ], [ 29.76407329113924, -1.463174310344828 ], [ 29.763803797468356, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25056, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.463174310344828 ], [ 29.76407329113924, -1.463713275862069 ], [ 29.764342784810125, -1.463713275862069 ], [ 29.764342784810125, -1.463174310344828 ], [ 29.76407329113924, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25057, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.463174310344828 ], [ 29.764342784810125, -1.463443793103448 ], [ 29.764612278481014, -1.463443793103448 ], [ 29.764612278481014, -1.463174310344828 ], [ 29.764342784810125, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25058, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.463174310344828 ], [ 29.764612278481014, -1.463443793103448 ], [ 29.764881772151899, -1.463443793103448 ], [ 29.764881772151899, -1.463174310344828 ], [ 29.764612278481014, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25059, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.463174310344828 ], [ 29.764881772151899, -1.463443793103448 ], [ 29.765151265822784, -1.463443793103448 ], [ 29.765151265822784, -1.463174310344828 ], [ 29.764881772151899, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25060, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.463174310344828 ], [ 29.765151265822784, -1.463443793103448 ], [ 29.765420759493672, -1.463443793103448 ], [ 29.765420759493672, -1.463174310344828 ], [ 29.765151265822784, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25061, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.463174310344828 ], [ 29.765420759493672, -1.463443793103448 ], [ 29.765959746835442, -1.463443793103448 ], [ 29.765959746835442, -1.463174310344828 ], [ 29.765420759493672, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25062, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.463174310344828 ], [ 29.765959746835442, -1.463443793103448 ], [ 29.766229240506327, -1.463443793103448 ], [ 29.766229240506327, -1.463174310344828 ], [ 29.765959746835442, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25063, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.463174310344828 ], [ 29.766229240506327, -1.463443793103448 ], [ 29.766498734177215, -1.463443793103448 ], [ 29.766498734177215, -1.463174310344828 ], [ 29.766229240506327, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25064, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.463174310344828 ], [ 29.766498734177215, -1.463443793103448 ], [ 29.7667682278481, -1.463443793103448 ], [ 29.7667682278481, -1.463174310344828 ], [ 29.766498734177215, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25065, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.463174310344828 ], [ 29.7667682278481, -1.463443793103448 ], [ 29.767037721518985, -1.463443793103448 ], [ 29.767037721518985, -1.463174310344828 ], [ 29.7667682278481, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25066, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.463174310344828 ], [ 29.767037721518985, -1.463443793103448 ], [ 29.767307215189874, -1.463443793103448 ], [ 29.767307215189874, -1.463174310344828 ], [ 29.767037721518985, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25067, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.463174310344828 ], [ 29.767307215189874, -1.463443793103448 ], [ 29.767576708860759, -1.463443793103448 ], [ 29.767576708860759, -1.463174310344828 ], [ 29.767307215189874, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25068, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.463174310344828 ], [ 29.767576708860759, -1.463443793103448 ], [ 29.768115696202532, -1.463443793103448 ], [ 29.768115696202532, -1.463174310344828 ], [ 29.767576708860759, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25069, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.463174310344828 ], [ 29.768115696202532, -1.463443793103448 ], [ 29.768385189873417, -1.463443793103448 ], [ 29.768385189873417, -1.463174310344828 ], [ 29.768115696202532, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25070, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.463174310344828 ], [ 29.768385189873417, -1.463443793103448 ], [ 29.769193670886075, -1.463443793103448 ], [ 29.769193670886075, -1.463174310344828 ], [ 29.768385189873417, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25071, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.463174310344828 ], [ 29.76946316455696, -1.463713275862069 ], [ 29.769732658227849, -1.463713275862069 ], [ 29.769732658227849, -1.463174310344828 ], [ 29.76946316455696, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25072, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.463174310344828 ], [ 29.769732658227849, -1.463443793103448 ], [ 29.770002151898733, -1.463443793103448 ], [ 29.770002151898733, -1.463174310344828 ], [ 29.769732658227849, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25073, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.463174310344828 ], [ 29.770002151898733, -1.463443793103448 ], [ 29.770271645569618, -1.463443793103448 ], [ 29.770271645569618, -1.463174310344828 ], [ 29.770002151898733, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25074, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.463174310344828 ], [ 29.770271645569618, -1.463443793103448 ], [ 29.770541139240507, -1.463443793103448 ], [ 29.770541139240507, -1.463174310344828 ], [ 29.770271645569618, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25075, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.463174310344828 ], [ 29.770541139240507, -1.463443793103448 ], [ 29.770810632911392, -1.463443793103448 ], [ 29.770810632911392, -1.463174310344828 ], [ 29.770541139240507, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25076, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.463174310344828 ], [ 29.770810632911392, -1.463443793103448 ], [ 29.771080126582277, -1.463443793103448 ], [ 29.771080126582277, -1.463174310344828 ], [ 29.770810632911392, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25077, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.463174310344828 ], [ 29.771080126582277, -1.463713275862069 ], [ 29.771349620253165, -1.463713275862069 ], [ 29.771349620253165, -1.463174310344828 ], [ 29.771080126582277, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25078, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.463174310344828 ], [ 29.771349620253165, -1.463443793103448 ], [ 29.77161911392405, -1.463443793103448 ], [ 29.77161911392405, -1.463174310344828 ], [ 29.771349620253165, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25079, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.463174310344828 ], [ 29.77161911392405, -1.463443793103448 ], [ 29.771888607594935, -1.463443793103448 ], [ 29.771888607594935, -1.463174310344828 ], [ 29.77161911392405, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25080, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.463174310344828 ], [ 29.771888607594935, -1.464521724137931 ], [ 29.772158101265823, -1.464521724137931 ], [ 29.772158101265823, -1.463174310344828 ], [ 29.771888607594935, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25081, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.463174310344828 ], [ 29.772427594936708, -1.463713275862069 ], [ 29.772697088607593, -1.463713275862069 ], [ 29.772697088607593, -1.463174310344828 ], [ 29.772427594936708, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25082, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.463174310344828 ], [ 29.772697088607593, -1.46425224137931 ], [ 29.772966582278482, -1.46425224137931 ], [ 29.772966582278482, -1.463174310344828 ], [ 29.772697088607593, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25083, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.463174310344828 ], [ 29.773236075949367, -1.46398275862069 ], [ 29.773505569620252, -1.46398275862069 ], [ 29.773505569620252, -1.463174310344828 ], [ 29.773236075949367, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25084, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.463174310344828 ], [ 29.773505569620252, -1.46398275862069 ], [ 29.77377506329114, -1.46398275862069 ], [ 29.77377506329114, -1.463174310344828 ], [ 29.773505569620252, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25085, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.463174310344828 ], [ 29.774044556962025, -1.46398275862069 ], [ 29.77431405063291, -1.46398275862069 ], [ 29.77431405063291, -1.463174310344828 ], [ 29.774044556962025, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25086, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.463174310344828 ], [ 29.775122531645568, -1.463713275862069 ], [ 29.775392025316457, -1.463713275862069 ], [ 29.775392025316457, -1.463174310344828 ], [ 29.775122531645568, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25087, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.463174310344828 ], [ 29.775931012658226, -1.463713275862069 ], [ 29.776200506329115, -1.463713275862069 ], [ 29.776200506329115, -1.463174310344828 ], [ 29.775931012658226, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25088, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.463174310344828 ], [ 29.77647, -1.463443793103448 ], [ 29.776739493670885, -1.463443793103448 ], [ 29.776739493670885, -1.463174310344828 ], [ 29.77647, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25089, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.463174310344828 ], [ 29.776739493670885, -1.463443793103448 ], [ 29.777008987341773, -1.463443793103448 ], [ 29.777008987341773, -1.463174310344828 ], [ 29.776739493670885, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25090, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.463174310344828 ], [ 29.777278481012658, -1.463443793103448 ], [ 29.777547974683543, -1.463443793103448 ], [ 29.777547974683543, -1.463174310344828 ], [ 29.777278481012658, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25091, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.463174310344828 ], [ 29.777547974683543, -1.463443793103448 ], [ 29.777817468354431, -1.463443793103448 ], [ 29.777817468354431, -1.463174310344828 ], [ 29.777547974683543, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25092, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.463174310344828 ], [ 29.777817468354431, -1.463443793103448 ], [ 29.778086962025316, -1.463443793103448 ], [ 29.778086962025316, -1.463174310344828 ], [ 29.777817468354431, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25093, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.463174310344828 ], [ 29.778086962025316, -1.463443793103448 ], [ 29.778356455696201, -1.463443793103448 ], [ 29.778356455696201, -1.463174310344828 ], [ 29.778086962025316, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25094, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.463174310344828 ], [ 29.778356455696201, -1.463443793103448 ], [ 29.77862594936709, -1.463443793103448 ], [ 29.77862594936709, -1.463174310344828 ], [ 29.778356455696201, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25095, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.463174310344828 ], [ 29.77862594936709, -1.463443793103448 ], [ 29.778895443037975, -1.463443793103448 ], [ 29.778895443037975, -1.463174310344828 ], [ 29.77862594936709, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25096, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.463174310344828 ], [ 29.784824303797468, -1.463713275862069 ], [ 29.785093797468356, -1.463713275862069 ], [ 29.785093797468356, -1.463174310344828 ], [ 29.784824303797468, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25097, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.463174310344828 ], [ 29.786171772151899, -1.463713275862069 ], [ 29.786441265822784, -1.463713275862069 ], [ 29.786441265822784, -1.463174310344828 ], [ 29.786171772151899, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25098, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.463174310344828 ], [ 29.786980253164558, -1.463443793103448 ], [ 29.787249746835442, -1.463443793103448 ], [ 29.787249746835442, -1.463174310344828 ], [ 29.786980253164558, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25099, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.463174310344828 ], [ 29.787249746835442, -1.463443793103448 ], [ 29.787519240506327, -1.463443793103448 ], [ 29.787519240506327, -1.463174310344828 ], [ 29.787249746835442, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25100, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.463174310344828 ], [ 29.787519240506327, -1.463443793103448 ], [ 29.787788734177216, -1.463443793103448 ], [ 29.787788734177216, -1.463174310344828 ], [ 29.787519240506327, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25101, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.463174310344828 ], [ 29.787788734177216, -1.463443793103448 ], [ 29.788058227848101, -1.463443793103448 ], [ 29.788058227848101, -1.463174310344828 ], [ 29.787788734177216, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25102, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792370126582277, -1.463174310344828 ], [ 29.792370126582277, -1.463713275862069 ], [ 29.792909113924051, -1.463713275862069 ], [ 29.792909113924051, -1.463174310344828 ], [ 29.792370126582277, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25103, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.463174310344828 ], [ 29.792909113924051, -1.463713275862069 ], [ 29.793178607594935, -1.463713275862069 ], [ 29.793178607594935, -1.463174310344828 ], [ 29.792909113924051, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25104, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.463174310344828 ], [ 29.793178607594935, -1.463443793103448 ], [ 29.793448101265824, -1.463443793103448 ], [ 29.793448101265824, -1.463174310344828 ], [ 29.793178607594935, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25105, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.463174310344828 ], [ 29.793448101265824, -1.463443793103448 ], [ 29.793717594936709, -1.463443793103448 ], [ 29.793717594936709, -1.463174310344828 ], [ 29.793448101265824, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25106, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.463174310344828 ], [ 29.793717594936709, -1.463443793103448 ], [ 29.793987088607594, -1.463443793103448 ], [ 29.793987088607594, -1.463174310344828 ], [ 29.793717594936709, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25107, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793987088607594, -1.463174310344828 ], [ 29.793987088607594, -1.463443793103448 ], [ 29.794526075949367, -1.463443793103448 ], [ 29.794526075949367, -1.463174310344828 ], [ 29.793987088607594, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25108, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.463174310344828 ], [ 29.794526075949367, -1.463443793103448 ], [ 29.794795569620252, -1.463443793103448 ], [ 29.794795569620252, -1.463174310344828 ], [ 29.794526075949367, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25109, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.463174310344828 ], [ 29.794795569620252, -1.463443793103448 ], [ 29.79506506329114, -1.463443793103448 ], [ 29.79506506329114, -1.463174310344828 ], [ 29.794795569620252, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25110, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.463174310344828 ], [ 29.79506506329114, -1.463443793103448 ], [ 29.795334556962025, -1.463443793103448 ], [ 29.795334556962025, -1.463174310344828 ], [ 29.79506506329114, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25111, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.463174310344828 ], [ 29.795334556962025, -1.463443793103448 ], [ 29.79560405063291, -1.463443793103448 ], [ 29.79560405063291, -1.463174310344828 ], [ 29.795334556962025, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25112, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.463174310344828 ], [ 29.79560405063291, -1.463443793103448 ], [ 29.795873544303799, -1.463443793103448 ], [ 29.795873544303799, -1.463174310344828 ], [ 29.79560405063291, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25113, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.463174310344828 ], [ 29.795873544303799, -1.463443793103448 ], [ 29.796143037974684, -1.463443793103448 ], [ 29.796143037974684, -1.463174310344828 ], [ 29.795873544303799, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25114, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.463174310344828 ], [ 29.796143037974684, -1.463443793103448 ], [ 29.796412531645569, -1.463443793103448 ], [ 29.796412531645569, -1.463174310344828 ], [ 29.796143037974684, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25115, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.463174310344828 ], [ 29.796412531645569, -1.463443793103448 ], [ 29.796682025316457, -1.463443793103448 ], [ 29.796682025316457, -1.463174310344828 ], [ 29.796412531645569, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25116, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.463174310344828 ], [ 29.796682025316457, -1.463443793103448 ], [ 29.796951518987342, -1.463443793103448 ], [ 29.796951518987342, -1.463174310344828 ], [ 29.796682025316457, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25117, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.463174310344828 ], [ 29.796951518987342, -1.463443793103448 ], [ 29.797221012658227, -1.463443793103448 ], [ 29.797221012658227, -1.463174310344828 ], [ 29.796951518987342, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25118, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.463174310344828 ], [ 29.797490506329115, -1.463713275862069 ], [ 29.79776, -1.463713275862069 ], [ 29.79776, -1.463174310344828 ], [ 29.797490506329115, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25119, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.463174310344828 ], [ 29.798837974683543, -1.463713275862069 ], [ 29.799107468354432, -1.463713275862069 ], [ 29.799107468354432, -1.463174310344828 ], [ 29.798837974683543, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25120, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.463174310344828 ], [ 29.799646455696202, -1.463443793103448 ], [ 29.79991594936709, -1.463443793103448 ], [ 29.79991594936709, -1.463174310344828 ], [ 29.799646455696202, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.463174310344828 ], [ 29.79991594936709, -1.463443793103448 ], [ 29.800185443037975, -1.463443793103448 ], [ 29.800185443037975, -1.463174310344828 ], [ 29.79991594936709, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25122, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.463174310344828 ], [ 29.800185443037975, -1.463443793103448 ], [ 29.80045493670886, -1.463443793103448 ], [ 29.80045493670886, -1.463174310344828 ], [ 29.800185443037975, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25123, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.463174310344828 ], [ 29.80045493670886, -1.46398275862069 ], [ 29.800724430379748, -1.46398275862069 ], [ 29.800724430379748, -1.463174310344828 ], [ 29.80045493670886, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25124, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.463443793103448 ], [ 29.754371518987341, -1.463713275862069 ], [ 29.754641012658226, -1.463713275862069 ], [ 29.754641012658226, -1.463443793103448 ], [ 29.754371518987341, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25125, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.463443793103448 ], [ 29.754641012658226, -1.463713275862069 ], [ 29.754910506329114, -1.463713275862069 ], [ 29.754910506329114, -1.463443793103448 ], [ 29.754641012658226, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25126, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.463443793103448 ], [ 29.754910506329114, -1.463713275862069 ], [ 29.75518, -1.463713275862069 ], [ 29.75518, -1.463443793103448 ], [ 29.754910506329114, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25127, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.463443793103448 ], [ 29.75518, -1.46398275862069 ], [ 29.755449493670884, -1.46398275862069 ], [ 29.755449493670884, -1.463443793103448 ], [ 29.75518, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25128, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.463443793103448 ], [ 29.755449493670884, -1.463713275862069 ], [ 29.755718987341773, -1.463713275862069 ], [ 29.755718987341773, -1.463443793103448 ], [ 29.755449493670884, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25129, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.463443793103448 ], [ 29.755718987341773, -1.463713275862069 ], [ 29.755988481012658, -1.463713275862069 ], [ 29.755988481012658, -1.463443793103448 ], [ 29.755718987341773, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25130, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.463443793103448 ], [ 29.755988481012658, -1.463713275862069 ], [ 29.756257974683542, -1.463713275862069 ], [ 29.756257974683542, -1.463443793103448 ], [ 29.755988481012658, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25131, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.463443793103448 ], [ 29.756257974683542, -1.463713275862069 ], [ 29.756796962025316, -1.463713275862069 ], [ 29.756796962025316, -1.463443793103448 ], [ 29.756257974683542, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25132, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.463443793103448 ], [ 29.756796962025316, -1.463713275862069 ], [ 29.757066455696201, -1.463713275862069 ], [ 29.757066455696201, -1.463443793103448 ], [ 29.756796962025316, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25133, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.463443793103448 ], [ 29.757066455696201, -1.463713275862069 ], [ 29.757335949367089, -1.463713275862069 ], [ 29.757335949367089, -1.463443793103448 ], [ 29.757066455696201, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25134, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.463443793103448 ], [ 29.757335949367089, -1.463713275862069 ], [ 29.757605443037974, -1.463713275862069 ], [ 29.757605443037974, -1.463443793103448 ], [ 29.757335949367089, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25135, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.463443793103448 ], [ 29.757605443037974, -1.463713275862069 ], [ 29.757874936708859, -1.463713275862069 ], [ 29.757874936708859, -1.463443793103448 ], [ 29.757605443037974, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25136, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.463443793103448 ], [ 29.757874936708859, -1.463713275862069 ], [ 29.758144430379748, -1.463713275862069 ], [ 29.758144430379748, -1.463443793103448 ], [ 29.757874936708859, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25137, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.463443793103448 ], [ 29.758144430379748, -1.463713275862069 ], [ 29.758413924050632, -1.463713275862069 ], [ 29.758413924050632, -1.463443793103448 ], [ 29.758144430379748, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25138, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.463443793103448 ], [ 29.758413924050632, -1.463713275862069 ], [ 29.758683417721517, -1.463713275862069 ], [ 29.758683417721517, -1.463443793103448 ], [ 29.758413924050632, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25139, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.463443793103448 ], [ 29.758683417721517, -1.463713275862069 ], [ 29.758952911392406, -1.463713275862069 ], [ 29.758952911392406, -1.463443793103448 ], [ 29.758683417721517, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25140, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.463443793103448 ], [ 29.758952911392406, -1.463713275862069 ], [ 29.759222405063291, -1.463713275862069 ], [ 29.759222405063291, -1.463443793103448 ], [ 29.758952911392406, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25141, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.463443793103448 ], [ 29.759222405063291, -1.463713275862069 ], [ 29.759491898734176, -1.463713275862069 ], [ 29.759491898734176, -1.463443793103448 ], [ 29.759222405063291, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25142, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.463443793103448 ], [ 29.759491898734176, -1.463713275862069 ], [ 29.759761392405064, -1.463713275862069 ], [ 29.759761392405064, -1.463443793103448 ], [ 29.759491898734176, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25143, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.463443793103448 ], [ 29.759761392405064, -1.463713275862069 ], [ 29.760030886075949, -1.463713275862069 ], [ 29.760030886075949, -1.463443793103448 ], [ 29.759761392405064, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25144, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.463443793103448 ], [ 29.760030886075949, -1.463713275862069 ], [ 29.760300379746834, -1.463713275862069 ], [ 29.760300379746834, -1.463443793103448 ], [ 29.760030886075949, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25145, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.463443793103448 ], [ 29.760300379746834, -1.463713275862069 ], [ 29.760569873417722, -1.463713275862069 ], [ 29.760569873417722, -1.463443793103448 ], [ 29.760300379746834, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25146, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.463174310344828 ], [ 29.761108860759492, -1.463174310344828 ], [ 29.761108860759492, -1.46398275862069 ], [ 29.760839367088607, -1.46398275862069 ], [ 29.760839367088607, -1.463713275862069 ], [ 29.760569873417722, -1.463713275862069 ], [ 29.760569873417722, -1.463443793103448 ], [ 29.760839367088607, -1.463443793103448 ], [ 29.760839367088607, -1.463174310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25147, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.463443793103448 ], [ 29.761378354430381, -1.46425224137931 ], [ 29.761647848101266, -1.46425224137931 ], [ 29.761647848101266, -1.463443793103448 ], [ 29.761378354430381, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25148, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.463443793103448 ], [ 29.762186835443039, -1.46425224137931 ], [ 29.762456329113924, -1.46425224137931 ], [ 29.762456329113924, -1.463443793103448 ], [ 29.762186835443039, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25149, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.463443793103448 ], [ 29.762725822784809, -1.464521724137931 ], [ 29.762995316455697, -1.464521724137931 ], [ 29.762995316455697, -1.463443793103448 ], [ 29.762725822784809, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25150, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.463443793103448 ], [ 29.762995316455697, -1.46425224137931 ], [ 29.763264810126582, -1.46425224137931 ], [ 29.763264810126582, -1.463443793103448 ], [ 29.762995316455697, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25151, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.463443793103448 ], [ 29.763534303797467, -1.464521724137931 ], [ 29.763803797468356, -1.464521724137931 ], [ 29.763803797468356, -1.463443793103448 ], [ 29.763534303797467, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25152, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.463443793103448 ], [ 29.763803797468356, -1.46398275862069 ], [ 29.76407329113924, -1.46398275862069 ], [ 29.76407329113924, -1.463443793103448 ], [ 29.763803797468356, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25153, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.463443793103448 ], [ 29.764342784810125, -1.464521724137931 ], [ 29.764612278481014, -1.464521724137931 ], [ 29.764612278481014, -1.463443793103448 ], [ 29.764342784810125, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25154, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.463443793103448 ], [ 29.764612278481014, -1.463713275862069 ], [ 29.764881772151899, -1.463713275862069 ], [ 29.764881772151899, -1.463443793103448 ], [ 29.764612278481014, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25155, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.463443793103448 ], [ 29.764881772151899, -1.463713275862069 ], [ 29.765151265822784, -1.463713275862069 ], [ 29.765151265822784, -1.463443793103448 ], [ 29.764881772151899, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25156, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.463443793103448 ], [ 29.765151265822784, -1.46398275862069 ], [ 29.765420759493672, -1.46398275862069 ], [ 29.765420759493672, -1.463443793103448 ], [ 29.765151265822784, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25157, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.463443793103448 ], [ 29.765420759493672, -1.463713275862069 ], [ 29.765959746835442, -1.463713275862069 ], [ 29.765959746835442, -1.463443793103448 ], [ 29.765420759493672, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25158, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.463443793103448 ], [ 29.765959746835442, -1.463713275862069 ], [ 29.766229240506327, -1.463713275862069 ], [ 29.766229240506327, -1.463443793103448 ], [ 29.765959746835442, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25159, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.463443793103448 ], [ 29.766229240506327, -1.463713275862069 ], [ 29.766498734177215, -1.463713275862069 ], [ 29.766498734177215, -1.463443793103448 ], [ 29.766229240506327, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25160, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.463443793103448 ], [ 29.766498734177215, -1.463713275862069 ], [ 29.7667682278481, -1.463713275862069 ], [ 29.7667682278481, -1.463443793103448 ], [ 29.766498734177215, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25161, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.463443793103448 ], [ 29.7667682278481, -1.463713275862069 ], [ 29.767037721518985, -1.463713275862069 ], [ 29.767037721518985, -1.463443793103448 ], [ 29.7667682278481, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25162, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.463443793103448 ], [ 29.767037721518985, -1.463713275862069 ], [ 29.767307215189874, -1.463713275862069 ], [ 29.767307215189874, -1.463443793103448 ], [ 29.767037721518985, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25163, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.463443793103448 ], [ 29.767307215189874, -1.463713275862069 ], [ 29.767576708860759, -1.463713275862069 ], [ 29.767576708860759, -1.463443793103448 ], [ 29.767307215189874, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25164, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.463443793103448 ], [ 29.767576708860759, -1.463713275862069 ], [ 29.768385189873417, -1.463713275862069 ], [ 29.768385189873417, -1.463443793103448 ], [ 29.767576708860759, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25165, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.463443793103448 ], [ 29.769732658227849, -1.463713275862069 ], [ 29.770002151898733, -1.463713275862069 ], [ 29.770002151898733, -1.463443793103448 ], [ 29.769732658227849, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25166, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.463443793103448 ], [ 29.770002151898733, -1.463713275862069 ], [ 29.770271645569618, -1.463713275862069 ], [ 29.770271645569618, -1.463443793103448 ], [ 29.770002151898733, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25167, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.463443793103448 ], [ 29.770271645569618, -1.463713275862069 ], [ 29.770541139240507, -1.463713275862069 ], [ 29.770541139240507, -1.463443793103448 ], [ 29.770271645569618, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25168, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.463443793103448 ], [ 29.770541139240507, -1.465060689655173 ], [ 29.770810632911392, -1.465060689655173 ], [ 29.770810632911392, -1.463443793103448 ], [ 29.770541139240507, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25169, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.463443793103448 ], [ 29.770810632911392, -1.465060689655173 ], [ 29.771080126582277, -1.465060689655173 ], [ 29.771080126582277, -1.463443793103448 ], [ 29.770810632911392, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25170, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.463443793103448 ], [ 29.771349620253165, -1.464791206896552 ], [ 29.77161911392405, -1.464791206896552 ], [ 29.77161911392405, -1.463443793103448 ], [ 29.771349620253165, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25171, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.463443793103448 ], [ 29.77161911392405, -1.464791206896552 ], [ 29.771888607594935, -1.464791206896552 ], [ 29.771888607594935, -1.463443793103448 ], [ 29.77161911392405, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25172, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.463443793103448 ], [ 29.772158101265823, -1.464521724137931 ], [ 29.772427594936708, -1.464521724137931 ], [ 29.772427594936708, -1.463443793103448 ], [ 29.772158101265823, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25173, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.463443793103448 ], [ 29.772966582278482, -1.46425224137931 ], [ 29.773236075949367, -1.46425224137931 ], [ 29.773236075949367, -1.463443793103448 ], [ 29.772966582278482, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25174, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.463443793103448 ], [ 29.77377506329114, -1.46398275862069 ], [ 29.774044556962025, -1.46398275862069 ], [ 29.774044556962025, -1.463443793103448 ], [ 29.77377506329114, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25175, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.463443793103448 ], [ 29.77431405063291, -1.46398275862069 ], [ 29.774853037974683, -1.46398275862069 ], [ 29.774853037974683, -1.463443793103448 ], [ 29.77431405063291, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25176, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.463443793103448 ], [ 29.775392025316457, -1.463713275862069 ], [ 29.775661518987341, -1.463713275862069 ], [ 29.775661518987341, -1.463443793103448 ], [ 29.775392025316457, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25177, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.463443793103448 ], [ 29.776200506329115, -1.463713275862069 ], [ 29.77647, -1.463713275862069 ], [ 29.77647, -1.463443793103448 ], [ 29.776200506329115, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25178, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.463443793103448 ], [ 29.77647, -1.463713275862069 ], [ 29.776739493670885, -1.463713275862069 ], [ 29.776739493670885, -1.463443793103448 ], [ 29.77647, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25179, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.463443793103448 ], [ 29.776739493670885, -1.463713275862069 ], [ 29.777008987341773, -1.463713275862069 ], [ 29.777008987341773, -1.463443793103448 ], [ 29.776739493670885, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25180, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.463443793103448 ], [ 29.777008987341773, -1.463713275862069 ], [ 29.777278481012658, -1.463713275862069 ], [ 29.777278481012658, -1.463443793103448 ], [ 29.777008987341773, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25181, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.463443793103448 ], [ 29.777278481012658, -1.463713275862069 ], [ 29.777547974683543, -1.463713275862069 ], [ 29.777547974683543, -1.463443793103448 ], [ 29.777278481012658, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25182, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.463443793103448 ], [ 29.777547974683543, -1.463713275862069 ], [ 29.777817468354431, -1.463713275862069 ], [ 29.777817468354431, -1.463443793103448 ], [ 29.777547974683543, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25183, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.463443793103448 ], [ 29.777817468354431, -1.463713275862069 ], [ 29.778086962025316, -1.463713275862069 ], [ 29.778086962025316, -1.463443793103448 ], [ 29.777817468354431, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25184, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.463443793103448 ], [ 29.778086962025316, -1.463713275862069 ], [ 29.778356455696201, -1.463713275862069 ], [ 29.778356455696201, -1.463443793103448 ], [ 29.778086962025316, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25185, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.463443793103448 ], [ 29.778356455696201, -1.46398275862069 ], [ 29.77862594936709, -1.46398275862069 ], [ 29.77862594936709, -1.463443793103448 ], [ 29.778356455696201, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25186, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.463443793103448 ], [ 29.77862594936709, -1.463713275862069 ], [ 29.778895443037975, -1.463713275862069 ], [ 29.778895443037975, -1.463443793103448 ], [ 29.77862594936709, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25187, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.463443793103448 ], [ 29.77916493670886, -1.46425224137931 ], [ 29.779434430379748, -1.46425224137931 ], [ 29.779434430379748, -1.463443793103448 ], [ 29.77916493670886, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25188, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.463443793103448 ], [ 29.780512405063291, -1.46425224137931 ], [ 29.780781898734176, -1.46425224137931 ], [ 29.780781898734176, -1.463443793103448 ], [ 29.780512405063291, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25189, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.463443793103448 ], [ 29.781859873417723, -1.46398275862069 ], [ 29.782129367088608, -1.46398275862069 ], [ 29.782129367088608, -1.463443793103448 ], [ 29.781859873417723, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25190, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.463443793103448 ], [ 29.782668354430381, -1.46398275862069 ], [ 29.782937848101266, -1.46398275862069 ], [ 29.782937848101266, -1.463443793103448 ], [ 29.782668354430381, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25191, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.463443793103448 ], [ 29.783207341772151, -1.46398275862069 ], [ 29.783476835443039, -1.46398275862069 ], [ 29.783476835443039, -1.463443793103448 ], [ 29.783207341772151, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25192, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.463443793103448 ], [ 29.784285316455698, -1.46398275862069 ], [ 29.784554810126583, -1.46398275862069 ], [ 29.784554810126583, -1.463443793103448 ], [ 29.784285316455698, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25193, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.463443793103448 ], [ 29.785093797468356, -1.46398275862069 ], [ 29.785363291139241, -1.46398275862069 ], [ 29.785363291139241, -1.463443793103448 ], [ 29.785093797468356, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25194, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.463443793103448 ], [ 29.785632784810126, -1.463713275862069 ], [ 29.785902278481014, -1.463713275862069 ], [ 29.785902278481014, -1.463443793103448 ], [ 29.785632784810126, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25195, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.463443793103448 ], [ 29.785902278481014, -1.463713275862069 ], [ 29.786171772151899, -1.463713275862069 ], [ 29.786171772151899, -1.463443793103448 ], [ 29.785902278481014, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25196, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.463443793103448 ], [ 29.786441265822784, -1.463713275862069 ], [ 29.786710759493673, -1.463713275862069 ], [ 29.786710759493673, -1.463443793103448 ], [ 29.786441265822784, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25197, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.463443793103448 ], [ 29.786710759493673, -1.463713275862069 ], [ 29.786980253164558, -1.463713275862069 ], [ 29.786980253164558, -1.463443793103448 ], [ 29.786710759493673, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25198, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.463443793103448 ], [ 29.786980253164558, -1.463713275862069 ], [ 29.787249746835442, -1.463713275862069 ], [ 29.787249746835442, -1.463443793103448 ], [ 29.786980253164558, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25199, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.463443793103448 ], [ 29.787249746835442, -1.463713275862069 ], [ 29.787519240506327, -1.463713275862069 ], [ 29.787519240506327, -1.463443793103448 ], [ 29.787249746835442, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25200, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.463443793103448 ], [ 29.787519240506327, -1.463713275862069 ], [ 29.787788734177216, -1.463713275862069 ], [ 29.787788734177216, -1.463443793103448 ], [ 29.787519240506327, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25201, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.463443793103448 ], [ 29.787788734177216, -1.463713275862069 ], [ 29.788058227848101, -1.463713275862069 ], [ 29.788058227848101, -1.463443793103448 ], [ 29.787788734177216, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25202, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.463443793103448 ], [ 29.788058227848101, -1.463713275862069 ], [ 29.788327721518986, -1.463713275862069 ], [ 29.788327721518986, -1.463443793103448 ], [ 29.788058227848101, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25203, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793178607594935, -1.463443793103448 ], [ 29.793178607594935, -1.463713275862069 ], [ 29.793448101265824, -1.463713275862069 ], [ 29.793448101265824, -1.463443793103448 ], [ 29.793178607594935, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25204, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.463443793103448 ], [ 29.793448101265824, -1.463713275862069 ], [ 29.793717594936709, -1.463713275862069 ], [ 29.793717594936709, -1.463443793103448 ], [ 29.793448101265824, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25205, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.463443793103448 ], [ 29.793717594936709, -1.463713275862069 ], [ 29.794256582278482, -1.463713275862069 ], [ 29.794256582278482, -1.463443793103448 ], [ 29.793717594936709, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25206, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.463443793103448 ], [ 29.794256582278482, -1.463713275862069 ], [ 29.794526075949367, -1.463713275862069 ], [ 29.794526075949367, -1.463443793103448 ], [ 29.794256582278482, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25207, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.463443793103448 ], [ 29.794526075949367, -1.463713275862069 ], [ 29.794795569620252, -1.463713275862069 ], [ 29.794795569620252, -1.463443793103448 ], [ 29.794526075949367, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25208, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.463443793103448 ], [ 29.794795569620252, -1.463713275862069 ], [ 29.79506506329114, -1.463713275862069 ], [ 29.79506506329114, -1.463443793103448 ], [ 29.794795569620252, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25209, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.463443793103448 ], [ 29.79506506329114, -1.463713275862069 ], [ 29.795334556962025, -1.463713275862069 ], [ 29.795334556962025, -1.463443793103448 ], [ 29.79506506329114, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25210, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.463443793103448 ], [ 29.795334556962025, -1.463713275862069 ], [ 29.79560405063291, -1.463713275862069 ], [ 29.79560405063291, -1.463443793103448 ], [ 29.795334556962025, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25211, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.463443793103448 ], [ 29.79560405063291, -1.463713275862069 ], [ 29.795873544303799, -1.463713275862069 ], [ 29.795873544303799, -1.463443793103448 ], [ 29.79560405063291, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25212, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.463443793103448 ], [ 29.795873544303799, -1.463713275862069 ], [ 29.796143037974684, -1.463713275862069 ], [ 29.796143037974684, -1.463443793103448 ], [ 29.795873544303799, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25213, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.463443793103448 ], [ 29.796143037974684, -1.463713275862069 ], [ 29.796412531645569, -1.463713275862069 ], [ 29.796412531645569, -1.463443793103448 ], [ 29.796143037974684, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25214, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.463443793103448 ], [ 29.796412531645569, -1.463713275862069 ], [ 29.796682025316457, -1.463713275862069 ], [ 29.796682025316457, -1.463443793103448 ], [ 29.796412531645569, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25215, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.463443793103448 ], [ 29.796682025316457, -1.463713275862069 ], [ 29.796951518987342, -1.463713275862069 ], [ 29.796951518987342, -1.463443793103448 ], [ 29.796682025316457, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25216, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.463443793103448 ], [ 29.796951518987342, -1.463713275862069 ], [ 29.797221012658227, -1.463713275862069 ], [ 29.797221012658227, -1.463443793103448 ], [ 29.796951518987342, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25217, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.463443793103448 ], [ 29.797221012658227, -1.463713275862069 ], [ 29.797490506329115, -1.463713275862069 ], [ 29.797490506329115, -1.463443793103448 ], [ 29.797221012658227, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25218, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.463443793103448 ], [ 29.79776, -1.46398275862069 ], [ 29.798029493670885, -1.46398275862069 ], [ 29.798029493670885, -1.463443793103448 ], [ 29.79776, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25219, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.463443793103448 ], [ 29.798298987341774, -1.463713275862069 ], [ 29.798568481012659, -1.463713275862069 ], [ 29.798568481012659, -1.463443793103448 ], [ 29.798298987341774, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25220, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.463443793103448 ], [ 29.798568481012659, -1.463713275862069 ], [ 29.798837974683543, -1.463713275862069 ], [ 29.798837974683543, -1.463443793103448 ], [ 29.798568481012659, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25221, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.463443793103448 ], [ 29.799107468354432, -1.463713275862069 ], [ 29.799376962025317, -1.463713275862069 ], [ 29.799376962025317, -1.463443793103448 ], [ 29.799107468354432, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25222, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.463443793103448 ], [ 29.799376962025317, -1.463713275862069 ], [ 29.799646455696202, -1.463713275862069 ], [ 29.799646455696202, -1.463443793103448 ], [ 29.799376962025317, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25223, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.463443793103448 ], [ 29.799646455696202, -1.463713275862069 ], [ 29.79991594936709, -1.463713275862069 ], [ 29.79991594936709, -1.463443793103448 ], [ 29.799646455696202, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25224, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.463443793103448 ], [ 29.79991594936709, -1.46398275862069 ], [ 29.800185443037975, -1.46398275862069 ], [ 29.800185443037975, -1.463443793103448 ], [ 29.79991594936709, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25225, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.463443793103448 ], [ 29.800185443037975, -1.46398275862069 ], [ 29.80045493670886, -1.46398275862069 ], [ 29.80045493670886, -1.463443793103448 ], [ 29.800185443037975, -1.463443793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25226, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.463713275862069 ], [ 29.753832531645568, -1.46398275862069 ], [ 29.754102025316456, -1.46398275862069 ], [ 29.754102025316456, -1.463713275862069 ], [ 29.753832531645568, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25227, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.463713275862069 ], [ 29.754102025316456, -1.46398275862069 ], [ 29.754371518987341, -1.46398275862069 ], [ 29.754371518987341, -1.463713275862069 ], [ 29.754102025316456, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25228, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.463713275862069 ], [ 29.754371518987341, -1.46398275862069 ], [ 29.754641012658226, -1.46398275862069 ], [ 29.754641012658226, -1.463713275862069 ], [ 29.754371518987341, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25229, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.463713275862069 ], [ 29.754641012658226, -1.46425224137931 ], [ 29.754910506329114, -1.46425224137931 ], [ 29.754910506329114, -1.463713275862069 ], [ 29.754641012658226, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25230, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.463713275862069 ], [ 29.754910506329114, -1.46425224137931 ], [ 29.75518, -1.46425224137931 ], [ 29.75518, -1.463713275862069 ], [ 29.754910506329114, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25231, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.463713275862069 ], [ 29.755449493670884, -1.46398275862069 ], [ 29.755718987341773, -1.46398275862069 ], [ 29.755718987341773, -1.463713275862069 ], [ 29.755449493670884, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25232, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.463713275862069 ], [ 29.755718987341773, -1.46398275862069 ], [ 29.755988481012658, -1.46398275862069 ], [ 29.755988481012658, -1.463713275862069 ], [ 29.755718987341773, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25233, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.463713275862069 ], [ 29.755988481012658, -1.46398275862069 ], [ 29.756257974683542, -1.46398275862069 ], [ 29.756257974683542, -1.463713275862069 ], [ 29.755988481012658, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25234, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.463713275862069 ], [ 29.756257974683542, -1.46398275862069 ], [ 29.756796962025316, -1.46398275862069 ], [ 29.756796962025316, -1.463713275862069 ], [ 29.756257974683542, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25235, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.463713275862069 ], [ 29.756796962025316, -1.46398275862069 ], [ 29.757066455696201, -1.46398275862069 ], [ 29.757066455696201, -1.463713275862069 ], [ 29.756796962025316, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25236, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.463713275862069 ], [ 29.757066455696201, -1.46398275862069 ], [ 29.757335949367089, -1.46398275862069 ], [ 29.757335949367089, -1.463713275862069 ], [ 29.757066455696201, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25237, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.463713275862069 ], [ 29.757335949367089, -1.46398275862069 ], [ 29.757605443037974, -1.46398275862069 ], [ 29.757605443037974, -1.463713275862069 ], [ 29.757335949367089, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25238, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.463713275862069 ], [ 29.757605443037974, -1.46398275862069 ], [ 29.757874936708859, -1.46398275862069 ], [ 29.757874936708859, -1.463713275862069 ], [ 29.757605443037974, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25239, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.463713275862069 ], [ 29.757874936708859, -1.46398275862069 ], [ 29.758144430379748, -1.46398275862069 ], [ 29.758144430379748, -1.463713275862069 ], [ 29.757874936708859, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25240, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.463713275862069 ], [ 29.758144430379748, -1.46398275862069 ], [ 29.758413924050632, -1.46398275862069 ], [ 29.758413924050632, -1.463713275862069 ], [ 29.758144430379748, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25241, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.463713275862069 ], [ 29.758413924050632, -1.46398275862069 ], [ 29.758683417721517, -1.46398275862069 ], [ 29.758683417721517, -1.463713275862069 ], [ 29.758413924050632, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25242, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.463713275862069 ], [ 29.758683417721517, -1.46398275862069 ], [ 29.758952911392406, -1.46398275862069 ], [ 29.758952911392406, -1.463713275862069 ], [ 29.758683417721517, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25243, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.463713275862069 ], [ 29.758952911392406, -1.46398275862069 ], [ 29.759222405063291, -1.46398275862069 ], [ 29.759222405063291, -1.463713275862069 ], [ 29.758952911392406, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25244, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.463713275862069 ], [ 29.759222405063291, -1.46398275862069 ], [ 29.759491898734176, -1.46398275862069 ], [ 29.759491898734176, -1.463713275862069 ], [ 29.759222405063291, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25245, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.463713275862069 ], [ 29.759491898734176, -1.46398275862069 ], [ 29.759761392405064, -1.46398275862069 ], [ 29.759761392405064, -1.463713275862069 ], [ 29.759491898734176, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25246, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.463713275862069 ], [ 29.759761392405064, -1.46398275862069 ], [ 29.760030886075949, -1.46398275862069 ], [ 29.760030886075949, -1.463713275862069 ], [ 29.759761392405064, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25247, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.463713275862069 ], [ 29.760030886075949, -1.46398275862069 ], [ 29.760300379746834, -1.46398275862069 ], [ 29.760300379746834, -1.463713275862069 ], [ 29.760030886075949, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25248, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.463713275862069 ], [ 29.760300379746834, -1.46398275862069 ], [ 29.760569873417722, -1.46398275862069 ], [ 29.760569873417722, -1.463713275862069 ], [ 29.760300379746834, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25249, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.463713275862069 ], [ 29.760569873417722, -1.46398275862069 ], [ 29.760839367088607, -1.46398275862069 ], [ 29.760839367088607, -1.463713275862069 ], [ 29.760569873417722, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25250, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.463713275862069 ], [ 29.761108860759492, -1.46425224137931 ], [ 29.761378354430381, -1.46425224137931 ], [ 29.761378354430381, -1.463713275862069 ], [ 29.761108860759492, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25251, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.463713275862069 ], [ 29.761917341772151, -1.46425224137931 ], [ 29.762186835443039, -1.46425224137931 ], [ 29.762186835443039, -1.463713275862069 ], [ 29.761917341772151, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25252, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.463713275862069 ], [ 29.762456329113924, -1.464521724137931 ], [ 29.762725822784809, -1.464521724137931 ], [ 29.762725822784809, -1.463713275862069 ], [ 29.762456329113924, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25253, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.463713275862069 ], [ 29.763264810126582, -1.464521724137931 ], [ 29.763534303797467, -1.464521724137931 ], [ 29.763534303797467, -1.463713275862069 ], [ 29.763264810126582, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25254, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.463713275862069 ], [ 29.76407329113924, -1.464521724137931 ], [ 29.764342784810125, -1.464521724137931 ], [ 29.764342784810125, -1.463713275862069 ], [ 29.76407329113924, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25255, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.463713275862069 ], [ 29.764612278481014, -1.464791206896552 ], [ 29.764881772151899, -1.464791206896552 ], [ 29.764881772151899, -1.463713275862069 ], [ 29.764612278481014, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25256, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.463713275862069 ], [ 29.764881772151899, -1.464791206896552 ], [ 29.765151265822784, -1.464791206896552 ], [ 29.765151265822784, -1.463713275862069 ], [ 29.764881772151899, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25257, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.463713275862069 ], [ 29.765420759493672, -1.465060689655173 ], [ 29.765959746835442, -1.465060689655173 ], [ 29.765959746835442, -1.463713275862069 ], [ 29.765420759493672, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25258, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.463713275862069 ], [ 29.765959746835442, -1.465060689655173 ], [ 29.766229240506327, -1.465060689655173 ], [ 29.766229240506327, -1.463713275862069 ], [ 29.765959746835442, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25259, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.463713275862069 ], [ 29.766229240506327, -1.46398275862069 ], [ 29.766498734177215, -1.46398275862069 ], [ 29.766498734177215, -1.463713275862069 ], [ 29.766229240506327, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25260, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.463713275862069 ], [ 29.766498734177215, -1.46398275862069 ], [ 29.7667682278481, -1.46398275862069 ], [ 29.7667682278481, -1.463713275862069 ], [ 29.766498734177215, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25261, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.463713275862069 ], [ 29.7667682278481, -1.46398275862069 ], [ 29.767037721518985, -1.46398275862069 ], [ 29.767037721518985, -1.463713275862069 ], [ 29.7667682278481, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25262, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.463713275862069 ], [ 29.767037721518985, -1.46398275862069 ], [ 29.767576708860759, -1.46398275862069 ], [ 29.767576708860759, -1.463713275862069 ], [ 29.767037721518985, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25263, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.463713275862069 ], [ 29.770002151898733, -1.465330172413793 ], [ 29.770271645569618, -1.465330172413793 ], [ 29.770271645569618, -1.463713275862069 ], [ 29.770002151898733, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25264, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.463713275862069 ], [ 29.770271645569618, -1.465330172413793 ], [ 29.770541139240507, -1.465330172413793 ], [ 29.770541139240507, -1.463713275862069 ], [ 29.770271645569618, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25265, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.463713275862069 ], [ 29.771080126582277, -1.464791206896552 ], [ 29.771349620253165, -1.464791206896552 ], [ 29.771349620253165, -1.463713275862069 ], [ 29.771080126582277, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25266, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.463713275862069 ], [ 29.772427594936708, -1.464521724137931 ], [ 29.772697088607593, -1.464521724137931 ], [ 29.772697088607593, -1.463713275862069 ], [ 29.772427594936708, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25267, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.463713275862069 ], [ 29.774853037974683, -1.46398275862069 ], [ 29.775122531645568, -1.46398275862069 ], [ 29.775122531645568, -1.463713275862069 ], [ 29.774853037974683, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25268, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.463713275862069 ], [ 29.775122531645568, -1.46398275862069 ], [ 29.775392025316457, -1.46398275862069 ], [ 29.775392025316457, -1.463713275862069 ], [ 29.775122531645568, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25269, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.463713275862069 ], [ 29.775392025316457, -1.46398275862069 ], [ 29.775661518987341, -1.46398275862069 ], [ 29.775661518987341, -1.463713275862069 ], [ 29.775392025316457, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25270, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.463713275862069 ], [ 29.775661518987341, -1.46398275862069 ], [ 29.775931012658226, -1.46398275862069 ], [ 29.775931012658226, -1.463713275862069 ], [ 29.775661518987341, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25271, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.463713275862069 ], [ 29.775931012658226, -1.46398275862069 ], [ 29.776200506329115, -1.46398275862069 ], [ 29.776200506329115, -1.463713275862069 ], [ 29.775931012658226, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25272, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.463713275862069 ], [ 29.776200506329115, -1.46398275862069 ], [ 29.77647, -1.46398275862069 ], [ 29.77647, -1.463713275862069 ], [ 29.776200506329115, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25273, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.463713275862069 ], [ 29.77647, -1.46398275862069 ], [ 29.776739493670885, -1.46398275862069 ], [ 29.776739493670885, -1.463713275862069 ], [ 29.77647, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25274, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.463713275862069 ], [ 29.776739493670885, -1.46398275862069 ], [ 29.777008987341773, -1.46398275862069 ], [ 29.777008987341773, -1.463713275862069 ], [ 29.776739493670885, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25275, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.463713275862069 ], [ 29.777008987341773, -1.46398275862069 ], [ 29.777278481012658, -1.46398275862069 ], [ 29.777278481012658, -1.463713275862069 ], [ 29.777008987341773, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25276, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.463713275862069 ], [ 29.777278481012658, -1.46425224137931 ], [ 29.777547974683543, -1.46425224137931 ], [ 29.777547974683543, -1.463713275862069 ], [ 29.777278481012658, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25277, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.463713275862069 ], [ 29.777547974683543, -1.46398275862069 ], [ 29.777817468354431, -1.46398275862069 ], [ 29.777817468354431, -1.463713275862069 ], [ 29.777547974683543, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25278, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.463713275862069 ], [ 29.777817468354431, -1.46398275862069 ], [ 29.778086962025316, -1.46398275862069 ], [ 29.778086962025316, -1.463713275862069 ], [ 29.777817468354431, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25279, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.463713275862069 ], [ 29.778086962025316, -1.46398275862069 ], [ 29.778356455696201, -1.46398275862069 ], [ 29.778356455696201, -1.463713275862069 ], [ 29.778086962025316, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25280, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.461826896551724 ], [ 29.77916493670886, -1.461826896551724 ], [ 29.77916493670886, -1.46398275862069 ], [ 29.77862594936709, -1.46398275862069 ], [ 29.77862594936709, -1.463713275862069 ], [ 29.778895443037975, -1.463713275862069 ], [ 29.778895443037975, -1.461826896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25281, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.463713275862069 ], [ 29.779973417721518, -1.46425224137931 ], [ 29.780242911392406, -1.46425224137931 ], [ 29.780242911392406, -1.463713275862069 ], [ 29.779973417721518, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25282, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.463713275862069 ], [ 29.780781898734176, -1.46425224137931 ], [ 29.781051392405065, -1.46425224137931 ], [ 29.781051392405065, -1.463713275862069 ], [ 29.780781898734176, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25283, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.463713275862069 ], [ 29.78132088607595, -1.46425224137931 ], [ 29.781590379746834, -1.46425224137931 ], [ 29.781590379746834, -1.463713275862069 ], [ 29.78132088607595, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25284, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.463713275862069 ], [ 29.782129367088608, -1.46425224137931 ], [ 29.782398860759493, -1.46425224137931 ], [ 29.782398860759493, -1.463713275862069 ], [ 29.782129367088608, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25285, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.463713275862069 ], [ 29.782937848101266, -1.46425224137931 ], [ 29.783207341772151, -1.46425224137931 ], [ 29.783207341772151, -1.463713275862069 ], [ 29.782937848101266, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25286, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.463713275862069 ], [ 29.783476835443039, -1.46398275862069 ], [ 29.784015822784809, -1.46398275862069 ], [ 29.784015822784809, -1.463713275862069 ], [ 29.783476835443039, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25287, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.463713275862069 ], [ 29.784015822784809, -1.46425224137931 ], [ 29.784285316455698, -1.46425224137931 ], [ 29.784285316455698, -1.463713275862069 ], [ 29.784015822784809, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25288, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.463713275862069 ], [ 29.784554810126583, -1.46398275862069 ], [ 29.784824303797468, -1.46398275862069 ], [ 29.784824303797468, -1.463713275862069 ], [ 29.784554810126583, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25289, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.463713275862069 ], [ 29.784824303797468, -1.46398275862069 ], [ 29.785093797468356, -1.46398275862069 ], [ 29.785093797468356, -1.463713275862069 ], [ 29.784824303797468, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25290, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.463713275862069 ], [ 29.785363291139241, -1.46398275862069 ], [ 29.785632784810126, -1.46398275862069 ], [ 29.785632784810126, -1.463713275862069 ], [ 29.785363291139241, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25291, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.463713275862069 ], [ 29.785632784810126, -1.46398275862069 ], [ 29.785902278481014, -1.46398275862069 ], [ 29.785902278481014, -1.463713275862069 ], [ 29.785632784810126, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25292, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.463713275862069 ], [ 29.785902278481014, -1.46398275862069 ], [ 29.786171772151899, -1.46398275862069 ], [ 29.786171772151899, -1.463713275862069 ], [ 29.785902278481014, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25293, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.463713275862069 ], [ 29.786171772151899, -1.46398275862069 ], [ 29.786441265822784, -1.46398275862069 ], [ 29.786441265822784, -1.463713275862069 ], [ 29.786171772151899, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25294, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.463713275862069 ], [ 29.786441265822784, -1.46398275862069 ], [ 29.786710759493673, -1.46398275862069 ], [ 29.786710759493673, -1.463713275862069 ], [ 29.786441265822784, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25295, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.463713275862069 ], [ 29.786710759493673, -1.46398275862069 ], [ 29.786980253164558, -1.46398275862069 ], [ 29.786980253164558, -1.463713275862069 ], [ 29.786710759493673, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25296, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.463713275862069 ], [ 29.786980253164558, -1.46398275862069 ], [ 29.787249746835442, -1.46398275862069 ], [ 29.787249746835442, -1.463713275862069 ], [ 29.786980253164558, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25297, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.463713275862069 ], [ 29.787249746835442, -1.46398275862069 ], [ 29.787519240506327, -1.46398275862069 ], [ 29.787519240506327, -1.463713275862069 ], [ 29.787249746835442, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25298, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.463713275862069 ], [ 29.787519240506327, -1.46398275862069 ], [ 29.787788734177216, -1.46398275862069 ], [ 29.787788734177216, -1.463713275862069 ], [ 29.787519240506327, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25299, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.463713275862069 ], [ 29.787788734177216, -1.46398275862069 ], [ 29.788058227848101, -1.46398275862069 ], [ 29.788058227848101, -1.463713275862069 ], [ 29.787788734177216, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25300, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.463713275862069 ], [ 29.788058227848101, -1.46398275862069 ], [ 29.788327721518986, -1.46398275862069 ], [ 29.788327721518986, -1.463713275862069 ], [ 29.788058227848101, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25301, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.463713275862069 ], [ 29.788327721518986, -1.46425224137931 ], [ 29.788597215189874, -1.46425224137931 ], [ 29.788597215189874, -1.463713275862069 ], [ 29.788327721518986, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25302, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.792909113924051, -1.463713275862069 ], [ 29.792909113924051, -1.46398275862069 ], [ 29.793448101265824, -1.46398275862069 ], [ 29.793448101265824, -1.463713275862069 ], [ 29.792909113924051, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25303, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.463713275862069 ], [ 29.793448101265824, -1.46398275862069 ], [ 29.793717594936709, -1.46398275862069 ], [ 29.793717594936709, -1.463713275862069 ], [ 29.793448101265824, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25304, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793717594936709, -1.463713275862069 ], [ 29.793717594936709, -1.46398275862069 ], [ 29.794256582278482, -1.46398275862069 ], [ 29.794256582278482, -1.463713275862069 ], [ 29.793717594936709, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25305, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.463713275862069 ], [ 29.794256582278482, -1.46398275862069 ], [ 29.794526075949367, -1.46398275862069 ], [ 29.794526075949367, -1.463713275862069 ], [ 29.794256582278482, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25306, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.463713275862069 ], [ 29.794526075949367, -1.46398275862069 ], [ 29.794795569620252, -1.46398275862069 ], [ 29.794795569620252, -1.463713275862069 ], [ 29.794526075949367, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25307, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.463713275862069 ], [ 29.794795569620252, -1.46398275862069 ], [ 29.79506506329114, -1.46398275862069 ], [ 29.79506506329114, -1.463713275862069 ], [ 29.794795569620252, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25308, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.463713275862069 ], [ 29.79506506329114, -1.46398275862069 ], [ 29.795334556962025, -1.46398275862069 ], [ 29.795334556962025, -1.463713275862069 ], [ 29.79506506329114, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25309, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.463713275862069 ], [ 29.795334556962025, -1.46398275862069 ], [ 29.79560405063291, -1.46398275862069 ], [ 29.79560405063291, -1.463713275862069 ], [ 29.795334556962025, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25310, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.463713275862069 ], [ 29.79560405063291, -1.46398275862069 ], [ 29.795873544303799, -1.46398275862069 ], [ 29.795873544303799, -1.463713275862069 ], [ 29.79560405063291, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25311, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.463713275862069 ], [ 29.795873544303799, -1.46398275862069 ], [ 29.796143037974684, -1.46398275862069 ], [ 29.796143037974684, -1.463713275862069 ], [ 29.795873544303799, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25312, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.463713275862069 ], [ 29.796143037974684, -1.46398275862069 ], [ 29.796412531645569, -1.46398275862069 ], [ 29.796412531645569, -1.463713275862069 ], [ 29.796143037974684, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25313, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.463713275862069 ], [ 29.796412531645569, -1.46398275862069 ], [ 29.796682025316457, -1.46398275862069 ], [ 29.796682025316457, -1.463713275862069 ], [ 29.796412531645569, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25314, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.463713275862069 ], [ 29.796682025316457, -1.46398275862069 ], [ 29.796951518987342, -1.46398275862069 ], [ 29.796951518987342, -1.463713275862069 ], [ 29.796682025316457, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25315, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.463713275862069 ], [ 29.796951518987342, -1.46425224137931 ], [ 29.797221012658227, -1.46425224137931 ], [ 29.797221012658227, -1.463713275862069 ], [ 29.796951518987342, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25316, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.463713275862069 ], [ 29.797221012658227, -1.46398275862069 ], [ 29.79776, -1.46398275862069 ], [ 29.79776, -1.463713275862069 ], [ 29.797221012658227, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25317, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.463713275862069 ], [ 29.798029493670885, -1.46398275862069 ], [ 29.798298987341774, -1.46398275862069 ], [ 29.798298987341774, -1.463713275862069 ], [ 29.798029493670885, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25318, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.463713275862069 ], [ 29.798298987341774, -1.46398275862069 ], [ 29.798568481012659, -1.46398275862069 ], [ 29.798568481012659, -1.463713275862069 ], [ 29.798298987341774, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25319, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.463713275862069 ], [ 29.798568481012659, -1.46398275862069 ], [ 29.798837974683543, -1.46398275862069 ], [ 29.798837974683543, -1.463713275862069 ], [ 29.798568481012659, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25320, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.463713275862069 ], [ 29.798837974683543, -1.46398275862069 ], [ 29.799107468354432, -1.46398275862069 ], [ 29.799107468354432, -1.463713275862069 ], [ 29.798837974683543, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25321, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.463713275862069 ], [ 29.799107468354432, -1.46398275862069 ], [ 29.799376962025317, -1.46398275862069 ], [ 29.799376962025317, -1.463713275862069 ], [ 29.799107468354432, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25322, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.463713275862069 ], [ 29.799376962025317, -1.46425224137931 ], [ 29.799646455696202, -1.46425224137931 ], [ 29.799646455696202, -1.463713275862069 ], [ 29.799376962025317, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25323, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.463713275862069 ], [ 29.799646455696202, -1.46425224137931 ], [ 29.79991594936709, -1.46425224137931 ], [ 29.79991594936709, -1.463713275862069 ], [ 29.799646455696202, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25324, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.753832531645568, -1.46398275862069 ], [ 29.753832531645568, -1.464791206896552 ], [ 29.754102025316456, -1.464791206896552 ], [ 29.754102025316456, -1.46398275862069 ], [ 29.753832531645568, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25325, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.46398275862069 ], [ 29.754102025316456, -1.464521724137931 ], [ 29.754371518987341, -1.464521724137931 ], [ 29.754371518987341, -1.46398275862069 ], [ 29.754102025316456, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25326, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.46398275862069 ], [ 29.754371518987341, -1.464521724137931 ], [ 29.754641012658226, -1.464521724137931 ], [ 29.754641012658226, -1.46398275862069 ], [ 29.754371518987341, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25327, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.46398275862069 ], [ 29.75518, -1.46425224137931 ], [ 29.755449493670884, -1.46425224137931 ], [ 29.755449493670884, -1.46398275862069 ], [ 29.75518, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25328, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.46398275862069 ], [ 29.755449493670884, -1.46425224137931 ], [ 29.755718987341773, -1.46425224137931 ], [ 29.755718987341773, -1.46398275862069 ], [ 29.755449493670884, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25329, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.46398275862069 ], [ 29.755718987341773, -1.46425224137931 ], [ 29.755988481012658, -1.46425224137931 ], [ 29.755988481012658, -1.46398275862069 ], [ 29.755718987341773, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25330, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.46398275862069 ], [ 29.755988481012658, -1.46425224137931 ], [ 29.756257974683542, -1.46425224137931 ], [ 29.756257974683542, -1.46398275862069 ], [ 29.755988481012658, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25331, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.46398275862069 ], [ 29.756257974683542, -1.46425224137931 ], [ 29.756796962025316, -1.46425224137931 ], [ 29.756796962025316, -1.46398275862069 ], [ 29.756257974683542, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25332, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.46398275862069 ], [ 29.756796962025316, -1.46425224137931 ], [ 29.757066455696201, -1.46425224137931 ], [ 29.757066455696201, -1.46398275862069 ], [ 29.756796962025316, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25333, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.46398275862069 ], [ 29.757066455696201, -1.46425224137931 ], [ 29.757335949367089, -1.46425224137931 ], [ 29.757335949367089, -1.46398275862069 ], [ 29.757066455696201, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25334, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.46398275862069 ], [ 29.757335949367089, -1.46425224137931 ], [ 29.757605443037974, -1.46425224137931 ], [ 29.757605443037974, -1.46398275862069 ], [ 29.757335949367089, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25335, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.46398275862069 ], [ 29.757605443037974, -1.46425224137931 ], [ 29.757874936708859, -1.46425224137931 ], [ 29.757874936708859, -1.46398275862069 ], [ 29.757605443037974, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25336, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.46398275862069 ], [ 29.757874936708859, -1.46425224137931 ], [ 29.758144430379748, -1.46425224137931 ], [ 29.758144430379748, -1.46398275862069 ], [ 29.757874936708859, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25337, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.46398275862069 ], [ 29.758144430379748, -1.46425224137931 ], [ 29.758413924050632, -1.46425224137931 ], [ 29.758413924050632, -1.46398275862069 ], [ 29.758144430379748, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25338, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.46398275862069 ], [ 29.758413924050632, -1.46425224137931 ], [ 29.758683417721517, -1.46425224137931 ], [ 29.758683417721517, -1.46398275862069 ], [ 29.758413924050632, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25339, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.46398275862069 ], [ 29.758683417721517, -1.46425224137931 ], [ 29.758952911392406, -1.46425224137931 ], [ 29.758952911392406, -1.46398275862069 ], [ 29.758683417721517, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25340, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.46398275862069 ], [ 29.758952911392406, -1.46425224137931 ], [ 29.759222405063291, -1.46425224137931 ], [ 29.759222405063291, -1.46398275862069 ], [ 29.758952911392406, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25341, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.46398275862069 ], [ 29.759222405063291, -1.46425224137931 ], [ 29.759491898734176, -1.46425224137931 ], [ 29.759491898734176, -1.46398275862069 ], [ 29.759222405063291, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25342, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.46398275862069 ], [ 29.759491898734176, -1.46425224137931 ], [ 29.759761392405064, -1.46425224137931 ], [ 29.759761392405064, -1.46398275862069 ], [ 29.759491898734176, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25343, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.46398275862069 ], [ 29.759761392405064, -1.46425224137931 ], [ 29.760030886075949, -1.46425224137931 ], [ 29.760030886075949, -1.46398275862069 ], [ 29.759761392405064, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25344, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.46398275862069 ], [ 29.760030886075949, -1.46425224137931 ], [ 29.760300379746834, -1.46425224137931 ], [ 29.760300379746834, -1.46398275862069 ], [ 29.760030886075949, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25345, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.46398275862069 ], [ 29.760300379746834, -1.46425224137931 ], [ 29.760569873417722, -1.46425224137931 ], [ 29.760569873417722, -1.46398275862069 ], [ 29.760300379746834, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25346, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.46398275862069 ], [ 29.760569873417722, -1.46425224137931 ], [ 29.760839367088607, -1.46425224137931 ], [ 29.760839367088607, -1.46398275862069 ], [ 29.760569873417722, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25347, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.46398275862069 ], [ 29.760839367088607, -1.46425224137931 ], [ 29.761108860759492, -1.46425224137931 ], [ 29.761108860759492, -1.46398275862069 ], [ 29.760839367088607, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25348, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.46398275862069 ], [ 29.761647848101266, -1.464521724137931 ], [ 29.761917341772151, -1.464521724137931 ], [ 29.761917341772151, -1.46398275862069 ], [ 29.761647848101266, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25349, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.46398275862069 ], [ 29.763803797468356, -1.464791206896552 ], [ 29.76407329113924, -1.464791206896552 ], [ 29.76407329113924, -1.46398275862069 ], [ 29.763803797468356, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25350, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.46398275862069 ], [ 29.765151265822784, -1.465060689655173 ], [ 29.765420759493672, -1.465060689655173 ], [ 29.765420759493672, -1.46398275862069 ], [ 29.765151265822784, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25351, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.46398275862069 ], [ 29.766229240506327, -1.465330172413793 ], [ 29.766498734177215, -1.465330172413793 ], [ 29.766498734177215, -1.46398275862069 ], [ 29.766229240506327, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25352, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.46398275862069 ], [ 29.766498734177215, -1.465330172413793 ], [ 29.7667682278481, -1.465330172413793 ], [ 29.7667682278481, -1.46398275862069 ], [ 29.766498734177215, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25353, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.46398275862069 ], [ 29.773236075949367, -1.46425224137931 ], [ 29.773505569620252, -1.46425224137931 ], [ 29.773505569620252, -1.46398275862069 ], [ 29.773236075949367, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25354, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.46398275862069 ], [ 29.773505569620252, -1.46425224137931 ], [ 29.77377506329114, -1.46425224137931 ], [ 29.77377506329114, -1.46398275862069 ], [ 29.773505569620252, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25355, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.46398275862069 ], [ 29.77377506329114, -1.46425224137931 ], [ 29.774044556962025, -1.46425224137931 ], [ 29.774044556962025, -1.46398275862069 ], [ 29.77377506329114, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25356, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.46398275862069 ], [ 29.774044556962025, -1.46425224137931 ], [ 29.77431405063291, -1.46425224137931 ], [ 29.77431405063291, -1.46398275862069 ], [ 29.774044556962025, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25357, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.46398275862069 ], [ 29.77431405063291, -1.46425224137931 ], [ 29.774853037974683, -1.46425224137931 ], [ 29.774853037974683, -1.46398275862069 ], [ 29.77431405063291, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25358, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.46398275862069 ], [ 29.774853037974683, -1.46425224137931 ], [ 29.775122531645568, -1.46425224137931 ], [ 29.775122531645568, -1.46398275862069 ], [ 29.774853037974683, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25359, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.46398275862069 ], [ 29.775122531645568, -1.46425224137931 ], [ 29.775392025316457, -1.46425224137931 ], [ 29.775392025316457, -1.46398275862069 ], [ 29.775122531645568, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25360, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.46398275862069 ], [ 29.775392025316457, -1.46425224137931 ], [ 29.775661518987341, -1.46425224137931 ], [ 29.775661518987341, -1.46398275862069 ], [ 29.775392025316457, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25361, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.46398275862069 ], [ 29.775661518987341, -1.46425224137931 ], [ 29.775931012658226, -1.46425224137931 ], [ 29.775931012658226, -1.46398275862069 ], [ 29.775661518987341, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25362, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.46398275862069 ], [ 29.775931012658226, -1.46425224137931 ], [ 29.776200506329115, -1.46425224137931 ], [ 29.776200506329115, -1.46398275862069 ], [ 29.775931012658226, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25363, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.46398275862069 ], [ 29.776200506329115, -1.464521724137931 ], [ 29.77647, -1.464521724137931 ], [ 29.77647, -1.46398275862069 ], [ 29.776200506329115, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25364, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.46398275862069 ], [ 29.77647, -1.46425224137931 ], [ 29.776739493670885, -1.46425224137931 ], [ 29.776739493670885, -1.46398275862069 ], [ 29.77647, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25365, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.46398275862069 ], [ 29.776739493670885, -1.46425224137931 ], [ 29.777008987341773, -1.46425224137931 ], [ 29.777008987341773, -1.46398275862069 ], [ 29.776739493670885, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25366, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.46398275862069 ], [ 29.777008987341773, -1.46425224137931 ], [ 29.777278481012658, -1.46425224137931 ], [ 29.777278481012658, -1.46398275862069 ], [ 29.777008987341773, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25367, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.46398275862069 ], [ 29.777547974683543, -1.46425224137931 ], [ 29.777817468354431, -1.46425224137931 ], [ 29.777817468354431, -1.46398275862069 ], [ 29.777547974683543, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25368, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.46398275862069 ], [ 29.777817468354431, -1.46425224137931 ], [ 29.778086962025316, -1.46425224137931 ], [ 29.778086962025316, -1.46398275862069 ], [ 29.777817468354431, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25369, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.46398275862069 ], [ 29.778086962025316, -1.464521724137931 ], [ 29.778356455696201, -1.464521724137931 ], [ 29.778356455696201, -1.46398275862069 ], [ 29.778086962025316, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25370, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.46398275862069 ], [ 29.778356455696201, -1.46425224137931 ], [ 29.77862594936709, -1.46425224137931 ], [ 29.77862594936709, -1.46398275862069 ], [ 29.778356455696201, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25371, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.46398275862069 ], [ 29.77862594936709, -1.46425224137931 ], [ 29.778895443037975, -1.46425224137931 ], [ 29.778895443037975, -1.46398275862069 ], [ 29.77862594936709, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25372, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.46398275862069 ], [ 29.778895443037975, -1.465060689655173 ], [ 29.77916493670886, -1.465060689655173 ], [ 29.77916493670886, -1.46398275862069 ], [ 29.778895443037975, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25373, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.46398275862069 ], [ 29.779434430379748, -1.464521724137931 ], [ 29.779703924050633, -1.464521724137931 ], [ 29.779703924050633, -1.46398275862069 ], [ 29.779434430379748, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25374, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.46398275862069 ], [ 29.779703924050633, -1.464521724137931 ], [ 29.779973417721518, -1.464521724137931 ], [ 29.779973417721518, -1.46398275862069 ], [ 29.779703924050633, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25375, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.46398275862069 ], [ 29.780242911392406, -1.464521724137931 ], [ 29.780512405063291, -1.464521724137931 ], [ 29.780512405063291, -1.46398275862069 ], [ 29.780242911392406, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25376, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.46398275862069 ], [ 29.781051392405065, -1.464521724137931 ], [ 29.78132088607595, -1.464521724137931 ], [ 29.78132088607595, -1.46398275862069 ], [ 29.781051392405065, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25377, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.46398275862069 ], [ 29.781590379746834, -1.46425224137931 ], [ 29.781859873417723, -1.46425224137931 ], [ 29.781859873417723, -1.46398275862069 ], [ 29.781590379746834, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25378, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.46398275862069 ], [ 29.781859873417723, -1.464521724137931 ], [ 29.782129367088608, -1.464521724137931 ], [ 29.782129367088608, -1.46398275862069 ], [ 29.781859873417723, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25379, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.46398275862069 ], [ 29.782398860759493, -1.46425224137931 ], [ 29.782668354430381, -1.46425224137931 ], [ 29.782668354430381, -1.46398275862069 ], [ 29.782398860759493, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25380, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.46398275862069 ], [ 29.782668354430381, -1.46425224137931 ], [ 29.782937848101266, -1.46425224137931 ], [ 29.782937848101266, -1.46398275862069 ], [ 29.782668354430381, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25381, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.46398275862069 ], [ 29.783207341772151, -1.46425224137931 ], [ 29.783476835443039, -1.46425224137931 ], [ 29.783476835443039, -1.46398275862069 ], [ 29.783207341772151, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25382, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.46398275862069 ], [ 29.783476835443039, -1.46425224137931 ], [ 29.784015822784809, -1.46425224137931 ], [ 29.784015822784809, -1.46398275862069 ], [ 29.783476835443039, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25383, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.46398275862069 ], [ 29.784285316455698, -1.46425224137931 ], [ 29.784554810126583, -1.46425224137931 ], [ 29.784554810126583, -1.46398275862069 ], [ 29.784285316455698, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25384, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.46398275862069 ], [ 29.784554810126583, -1.46425224137931 ], [ 29.784824303797468, -1.46425224137931 ], [ 29.784824303797468, -1.46398275862069 ], [ 29.784554810126583, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25385, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.46398275862069 ], [ 29.784824303797468, -1.46425224137931 ], [ 29.785093797468356, -1.46425224137931 ], [ 29.785093797468356, -1.46398275862069 ], [ 29.784824303797468, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25386, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.46398275862069 ], [ 29.785093797468356, -1.46425224137931 ], [ 29.785363291139241, -1.46425224137931 ], [ 29.785363291139241, -1.46398275862069 ], [ 29.785093797468356, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25387, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.46398275862069 ], [ 29.785363291139241, -1.46425224137931 ], [ 29.785632784810126, -1.46425224137931 ], [ 29.785632784810126, -1.46398275862069 ], [ 29.785363291139241, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25388, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.46398275862069 ], [ 29.785632784810126, -1.46425224137931 ], [ 29.785902278481014, -1.46425224137931 ], [ 29.785902278481014, -1.46398275862069 ], [ 29.785632784810126, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25389, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.46398275862069 ], [ 29.785902278481014, -1.46425224137931 ], [ 29.786171772151899, -1.46425224137931 ], [ 29.786171772151899, -1.46398275862069 ], [ 29.785902278481014, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25390, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.46398275862069 ], [ 29.786171772151899, -1.46425224137931 ], [ 29.786441265822784, -1.46425224137931 ], [ 29.786441265822784, -1.46398275862069 ], [ 29.786171772151899, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25391, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.46398275862069 ], [ 29.786441265822784, -1.46425224137931 ], [ 29.786710759493673, -1.46425224137931 ], [ 29.786710759493673, -1.46398275862069 ], [ 29.786441265822784, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25392, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.46398275862069 ], [ 29.786710759493673, -1.46425224137931 ], [ 29.786980253164558, -1.46425224137931 ], [ 29.786980253164558, -1.46398275862069 ], [ 29.786710759493673, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25393, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.46398275862069 ], [ 29.786980253164558, -1.46425224137931 ], [ 29.787249746835442, -1.46425224137931 ], [ 29.787249746835442, -1.46398275862069 ], [ 29.786980253164558, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25394, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.46398275862069 ], [ 29.787249746835442, -1.46425224137931 ], [ 29.787519240506327, -1.46425224137931 ], [ 29.787519240506327, -1.46398275862069 ], [ 29.787249746835442, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25395, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.46398275862069 ], [ 29.787519240506327, -1.46425224137931 ], [ 29.787788734177216, -1.46425224137931 ], [ 29.787788734177216, -1.46398275862069 ], [ 29.787519240506327, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25396, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.46398275862069 ], [ 29.787788734177216, -1.46425224137931 ], [ 29.788058227848101, -1.46425224137931 ], [ 29.788058227848101, -1.46398275862069 ], [ 29.787788734177216, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25397, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.46398275862069 ], [ 29.788058227848101, -1.46425224137931 ], [ 29.788327721518986, -1.46425224137931 ], [ 29.788327721518986, -1.46398275862069 ], [ 29.788058227848101, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25398, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.793448101265824, -1.46398275862069 ], [ 29.793448101265824, -1.46425224137931 ], [ 29.794256582278482, -1.46425224137931 ], [ 29.794256582278482, -1.46398275862069 ], [ 29.793448101265824, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25399, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.46398275862069 ], [ 29.794256582278482, -1.46425224137931 ], [ 29.794526075949367, -1.46425224137931 ], [ 29.794526075949367, -1.46398275862069 ], [ 29.794256582278482, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25400, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.46398275862069 ], [ 29.794526075949367, -1.46425224137931 ], [ 29.794795569620252, -1.46425224137931 ], [ 29.794795569620252, -1.46398275862069 ], [ 29.794526075949367, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25401, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.46398275862069 ], [ 29.794795569620252, -1.46425224137931 ], [ 29.79506506329114, -1.46425224137931 ], [ 29.79506506329114, -1.46398275862069 ], [ 29.794795569620252, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25402, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.46398275862069 ], [ 29.79506506329114, -1.46425224137931 ], [ 29.795334556962025, -1.46425224137931 ], [ 29.795334556962025, -1.46398275862069 ], [ 29.79506506329114, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25403, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.46398275862069 ], [ 29.795334556962025, -1.46425224137931 ], [ 29.79560405063291, -1.46425224137931 ], [ 29.79560405063291, -1.46398275862069 ], [ 29.795334556962025, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25404, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.46398275862069 ], [ 29.79560405063291, -1.46425224137931 ], [ 29.795873544303799, -1.46425224137931 ], [ 29.795873544303799, -1.46398275862069 ], [ 29.79560405063291, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25405, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.46398275862069 ], [ 29.795873544303799, -1.464521724137931 ], [ 29.796143037974684, -1.464521724137931 ], [ 29.796143037974684, -1.46398275862069 ], [ 29.795873544303799, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25406, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.46398275862069 ], [ 29.796143037974684, -1.46425224137931 ], [ 29.796412531645569, -1.46425224137931 ], [ 29.796412531645569, -1.46398275862069 ], [ 29.796143037974684, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25407, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.46398275862069 ], [ 29.796412531645569, -1.46425224137931 ], [ 29.796682025316457, -1.46425224137931 ], [ 29.796682025316457, -1.46398275862069 ], [ 29.796412531645569, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25408, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.46398275862069 ], [ 29.797221012658227, -1.464521724137931 ], [ 29.797490506329115, -1.464521724137931 ], [ 29.797490506329115, -1.46398275862069 ], [ 29.797221012658227, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25409, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.46398275862069 ], [ 29.797490506329115, -1.46425224137931 ], [ 29.79776, -1.46425224137931 ], [ 29.79776, -1.46398275862069 ], [ 29.797490506329115, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25410, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.46398275862069 ], [ 29.79776, -1.46425224137931 ], [ 29.798029493670885, -1.46425224137931 ], [ 29.798029493670885, -1.46398275862069 ], [ 29.79776, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25411, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.46398275862069 ], [ 29.798029493670885, -1.46425224137931 ], [ 29.798298987341774, -1.46425224137931 ], [ 29.798298987341774, -1.46398275862069 ], [ 29.798029493670885, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25412, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.46398275862069 ], [ 29.798298987341774, -1.46425224137931 ], [ 29.798568481012659, -1.46425224137931 ], [ 29.798568481012659, -1.46398275862069 ], [ 29.798298987341774, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25413, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.46398275862069 ], [ 29.798568481012659, -1.46425224137931 ], [ 29.798837974683543, -1.46425224137931 ], [ 29.798837974683543, -1.46398275862069 ], [ 29.798568481012659, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25414, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.46398275862069 ], [ 29.798837974683543, -1.46425224137931 ], [ 29.799107468354432, -1.46425224137931 ], [ 29.799107468354432, -1.46398275862069 ], [ 29.798837974683543, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25415, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.46398275862069 ], [ 29.799107468354432, -1.464521724137931 ], [ 29.799376962025317, -1.464521724137931 ], [ 29.799376962025317, -1.46398275862069 ], [ 29.799107468354432, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25416, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.46398275862069 ], [ 29.79991594936709, -1.46425224137931 ], [ 29.800185443037975, -1.46425224137931 ], [ 29.800185443037975, -1.46398275862069 ], [ 29.79991594936709, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25417, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.46398275862069 ], [ 29.800185443037975, -1.46425224137931 ], [ 29.80045493670886, -1.46425224137931 ], [ 29.80045493670886, -1.46398275862069 ], [ 29.800185443037975, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25418, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.46398275862069 ], [ 29.80045493670886, -1.46425224137931 ], [ 29.800724430379748, -1.46425224137931 ], [ 29.800724430379748, -1.46398275862069 ], [ 29.80045493670886, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25419, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.46398275862069 ], [ 29.800724430379748, -1.464521724137931 ], [ 29.800993924050633, -1.464521724137931 ], [ 29.800993924050633, -1.46398275862069 ], [ 29.800724430379748, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25420, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.46425224137931 ], [ 29.754641012658226, -1.464521724137931 ], [ 29.754910506329114, -1.464521724137931 ], [ 29.754910506329114, -1.46425224137931 ], [ 29.754641012658226, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25421, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.46425224137931 ], [ 29.754910506329114, -1.464521724137931 ], [ 29.75518, -1.464521724137931 ], [ 29.75518, -1.46425224137931 ], [ 29.754910506329114, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25422, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.46425224137931 ], [ 29.75518, -1.464521724137931 ], [ 29.755449493670884, -1.464521724137931 ], [ 29.755449493670884, -1.46425224137931 ], [ 29.75518, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25423, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.46425224137931 ], [ 29.755449493670884, -1.464521724137931 ], [ 29.755718987341773, -1.464521724137931 ], [ 29.755718987341773, -1.46425224137931 ], [ 29.755449493670884, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25424, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.46425224137931 ], [ 29.755718987341773, -1.464521724137931 ], [ 29.755988481012658, -1.464521724137931 ], [ 29.755988481012658, -1.46425224137931 ], [ 29.755718987341773, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25425, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.46425224137931 ], [ 29.755988481012658, -1.464521724137931 ], [ 29.756257974683542, -1.464521724137931 ], [ 29.756257974683542, -1.46425224137931 ], [ 29.755988481012658, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25426, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.46425224137931 ], [ 29.756257974683542, -1.464521724137931 ], [ 29.756796962025316, -1.464521724137931 ], [ 29.756796962025316, -1.46425224137931 ], [ 29.756257974683542, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25427, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.46425224137931 ], [ 29.756796962025316, -1.464521724137931 ], [ 29.757066455696201, -1.464521724137931 ], [ 29.757066455696201, -1.46425224137931 ], [ 29.756796962025316, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25428, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.46425224137931 ], [ 29.757066455696201, -1.464521724137931 ], [ 29.757335949367089, -1.464521724137931 ], [ 29.757335949367089, -1.46425224137931 ], [ 29.757066455696201, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25429, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.46425224137931 ], [ 29.757335949367089, -1.464521724137931 ], [ 29.757605443037974, -1.464521724137931 ], [ 29.757605443037974, -1.46425224137931 ], [ 29.757335949367089, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25430, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.46425224137931 ], [ 29.757605443037974, -1.464521724137931 ], [ 29.757874936708859, -1.464521724137931 ], [ 29.757874936708859, -1.46425224137931 ], [ 29.757605443037974, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25431, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.46425224137931 ], [ 29.757874936708859, -1.464521724137931 ], [ 29.758144430379748, -1.464521724137931 ], [ 29.758144430379748, -1.46425224137931 ], [ 29.757874936708859, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25432, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.46425224137931 ], [ 29.758144430379748, -1.464521724137931 ], [ 29.758413924050632, -1.464521724137931 ], [ 29.758413924050632, -1.46425224137931 ], [ 29.758144430379748, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25433, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.46425224137931 ], [ 29.758413924050632, -1.464521724137931 ], [ 29.758683417721517, -1.464521724137931 ], [ 29.758683417721517, -1.46425224137931 ], [ 29.758413924050632, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25434, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.46425224137931 ], [ 29.758683417721517, -1.464521724137931 ], [ 29.758952911392406, -1.464521724137931 ], [ 29.758952911392406, -1.46425224137931 ], [ 29.758683417721517, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25435, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.46425224137931 ], [ 29.758952911392406, -1.464521724137931 ], [ 29.759222405063291, -1.464521724137931 ], [ 29.759222405063291, -1.46425224137931 ], [ 29.758952911392406, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25436, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.46425224137931 ], [ 29.759222405063291, -1.464521724137931 ], [ 29.759491898734176, -1.464521724137931 ], [ 29.759491898734176, -1.46425224137931 ], [ 29.759222405063291, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25437, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.46425224137931 ], [ 29.759491898734176, -1.464521724137931 ], [ 29.759761392405064, -1.464521724137931 ], [ 29.759761392405064, -1.46425224137931 ], [ 29.759491898734176, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25438, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.46425224137931 ], [ 29.759761392405064, -1.464521724137931 ], [ 29.760030886075949, -1.464521724137931 ], [ 29.760030886075949, -1.46425224137931 ], [ 29.759761392405064, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25439, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.46425224137931 ], [ 29.760030886075949, -1.464791206896552 ], [ 29.760300379746834, -1.464791206896552 ], [ 29.760300379746834, -1.46425224137931 ], [ 29.760030886075949, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25440, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.46425224137931 ], [ 29.760300379746834, -1.464521724137931 ], [ 29.760569873417722, -1.464521724137931 ], [ 29.760569873417722, -1.46425224137931 ], [ 29.760300379746834, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25441, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.46425224137931 ], [ 29.760569873417722, -1.464521724137931 ], [ 29.760839367088607, -1.464521724137931 ], [ 29.760839367088607, -1.46425224137931 ], [ 29.760569873417722, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25442, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.46425224137931 ], [ 29.760839367088607, -1.464791206896552 ], [ 29.761108860759492, -1.464791206896552 ], [ 29.761108860759492, -1.46425224137931 ], [ 29.760839367088607, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25443, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.46425224137931 ], [ 29.761108860759492, -1.464521724137931 ], [ 29.761378354430381, -1.464521724137931 ], [ 29.761378354430381, -1.46425224137931 ], [ 29.761108860759492, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25444, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.46425224137931 ], [ 29.761378354430381, -1.464521724137931 ], [ 29.761647848101266, -1.464521724137931 ], [ 29.761647848101266, -1.46425224137931 ], [ 29.761378354430381, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25445, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.46425224137931 ], [ 29.761917341772151, -1.464521724137931 ], [ 29.762186835443039, -1.464521724137931 ], [ 29.762186835443039, -1.46425224137931 ], [ 29.761917341772151, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25446, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.46425224137931 ], [ 29.762186835443039, -1.464521724137931 ], [ 29.762456329113924, -1.464521724137931 ], [ 29.762456329113924, -1.46425224137931 ], [ 29.762186835443039, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25447, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.46425224137931 ], [ 29.762995316455697, -1.464521724137931 ], [ 29.763264810126582, -1.464521724137931 ], [ 29.763264810126582, -1.46425224137931 ], [ 29.762995316455697, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25448, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.46425224137931 ], [ 29.772697088607593, -1.464521724137931 ], [ 29.772966582278482, -1.464521724137931 ], [ 29.772966582278482, -1.46425224137931 ], [ 29.772697088607593, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25449, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.46425224137931 ], [ 29.772966582278482, -1.464521724137931 ], [ 29.773236075949367, -1.464521724137931 ], [ 29.773236075949367, -1.46425224137931 ], [ 29.772966582278482, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25450, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.46425224137931 ], [ 29.773236075949367, -1.464521724137931 ], [ 29.773505569620252, -1.464521724137931 ], [ 29.773505569620252, -1.46425224137931 ], [ 29.773236075949367, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25451, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.46425224137931 ], [ 29.773505569620252, -1.464521724137931 ], [ 29.77377506329114, -1.464521724137931 ], [ 29.77377506329114, -1.46425224137931 ], [ 29.773505569620252, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25452, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.46425224137931 ], [ 29.77377506329114, -1.464521724137931 ], [ 29.774044556962025, -1.464521724137931 ], [ 29.774044556962025, -1.46425224137931 ], [ 29.77377506329114, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25453, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.46425224137931 ], [ 29.774044556962025, -1.464521724137931 ], [ 29.77431405063291, -1.464521724137931 ], [ 29.77431405063291, -1.46425224137931 ], [ 29.774044556962025, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25454, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.46425224137931 ], [ 29.77431405063291, -1.464521724137931 ], [ 29.774853037974683, -1.464521724137931 ], [ 29.774853037974683, -1.46425224137931 ], [ 29.77431405063291, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25455, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.46425224137931 ], [ 29.774853037974683, -1.464521724137931 ], [ 29.775122531645568, -1.464521724137931 ], [ 29.775122531645568, -1.46425224137931 ], [ 29.774853037974683, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25456, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.46425224137931 ], [ 29.775122531645568, -1.464791206896552 ], [ 29.775392025316457, -1.464791206896552 ], [ 29.775392025316457, -1.46425224137931 ], [ 29.775122531645568, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25457, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.46425224137931 ], [ 29.775392025316457, -1.464521724137931 ], [ 29.775661518987341, -1.464521724137931 ], [ 29.775661518987341, -1.46425224137931 ], [ 29.775392025316457, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25458, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.46425224137931 ], [ 29.775661518987341, -1.464521724137931 ], [ 29.775931012658226, -1.464521724137931 ], [ 29.775931012658226, -1.46425224137931 ], [ 29.775661518987341, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25459, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.46425224137931 ], [ 29.775931012658226, -1.464521724137931 ], [ 29.776200506329115, -1.464521724137931 ], [ 29.776200506329115, -1.46425224137931 ], [ 29.775931012658226, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25460, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.46425224137931 ], [ 29.77647, -1.464521724137931 ], [ 29.776739493670885, -1.464521724137931 ], [ 29.776739493670885, -1.46425224137931 ], [ 29.77647, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25461, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.46425224137931 ], [ 29.776739493670885, -1.464521724137931 ], [ 29.777008987341773, -1.464521724137931 ], [ 29.777008987341773, -1.46425224137931 ], [ 29.776739493670885, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25462, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.46425224137931 ], [ 29.777008987341773, -1.464791206896552 ], [ 29.777278481012658, -1.464791206896552 ], [ 29.777278481012658, -1.46425224137931 ], [ 29.777008987341773, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25463, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.46425224137931 ], [ 29.777278481012658, -1.464521724137931 ], [ 29.777547974683543, -1.464521724137931 ], [ 29.777547974683543, -1.46425224137931 ], [ 29.777278481012658, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25464, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.46425224137931 ], [ 29.777547974683543, -1.464521724137931 ], [ 29.777817468354431, -1.464521724137931 ], [ 29.777817468354431, -1.46425224137931 ], [ 29.777547974683543, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25465, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.46425224137931 ], [ 29.777817468354431, -1.464791206896552 ], [ 29.778086962025316, -1.464791206896552 ], [ 29.778086962025316, -1.46425224137931 ], [ 29.777817468354431, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25466, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.46425224137931 ], [ 29.778356455696201, -1.464521724137931 ], [ 29.77862594936709, -1.464521724137931 ], [ 29.77862594936709, -1.46425224137931 ], [ 29.778356455696201, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25467, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.46425224137931 ], [ 29.77862594936709, -1.464791206896552 ], [ 29.778895443037975, -1.464791206896552 ], [ 29.778895443037975, -1.46425224137931 ], [ 29.77862594936709, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25468, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.46425224137931 ], [ 29.77916493670886, -1.464791206896552 ], [ 29.779434430379748, -1.464791206896552 ], [ 29.779434430379748, -1.46425224137931 ], [ 29.77916493670886, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25469, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.46425224137931 ], [ 29.779973417721518, -1.464791206896552 ], [ 29.780242911392406, -1.464791206896552 ], [ 29.780242911392406, -1.46425224137931 ], [ 29.779973417721518, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25470, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.46425224137931 ], [ 29.780512405063291, -1.464521724137931 ], [ 29.780781898734176, -1.464521724137931 ], [ 29.780781898734176, -1.46425224137931 ], [ 29.780512405063291, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25471, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.46425224137931 ], [ 29.780781898734176, -1.464791206896552 ], [ 29.781051392405065, -1.464791206896552 ], [ 29.781051392405065, -1.46425224137931 ], [ 29.780781898734176, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25472, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.46425224137931 ], [ 29.78132088607595, -1.464521724137931 ], [ 29.781590379746834, -1.464521724137931 ], [ 29.781590379746834, -1.46425224137931 ], [ 29.78132088607595, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25473, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.46425224137931 ], [ 29.781590379746834, -1.464521724137931 ], [ 29.781859873417723, -1.464521724137931 ], [ 29.781859873417723, -1.46425224137931 ], [ 29.781590379746834, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25474, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.46425224137931 ], [ 29.782129367088608, -1.464521724137931 ], [ 29.782398860759493, -1.464521724137931 ], [ 29.782398860759493, -1.46425224137931 ], [ 29.782129367088608, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25475, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.46425224137931 ], [ 29.782398860759493, -1.464521724137931 ], [ 29.782668354430381, -1.464521724137931 ], [ 29.782668354430381, -1.46425224137931 ], [ 29.782398860759493, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25476, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.46425224137931 ], [ 29.782668354430381, -1.464791206896552 ], [ 29.782937848101266, -1.464791206896552 ], [ 29.782937848101266, -1.46425224137931 ], [ 29.782668354430381, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25477, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.46425224137931 ], [ 29.782937848101266, -1.464521724137931 ], [ 29.783207341772151, -1.464521724137931 ], [ 29.783207341772151, -1.46425224137931 ], [ 29.782937848101266, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25478, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.46425224137931 ], [ 29.783207341772151, -1.464521724137931 ], [ 29.783476835443039, -1.464521724137931 ], [ 29.783476835443039, -1.46425224137931 ], [ 29.783207341772151, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25479, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.46425224137931 ], [ 29.783476835443039, -1.464521724137931 ], [ 29.784015822784809, -1.464521724137931 ], [ 29.784015822784809, -1.46425224137931 ], [ 29.783476835443039, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25480, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.46425224137931 ], [ 29.784015822784809, -1.464521724137931 ], [ 29.784285316455698, -1.464521724137931 ], [ 29.784285316455698, -1.46425224137931 ], [ 29.784015822784809, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25481, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.46425224137931 ], [ 29.784285316455698, -1.464521724137931 ], [ 29.784554810126583, -1.464521724137931 ], [ 29.784554810126583, -1.46425224137931 ], [ 29.784285316455698, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25482, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.46425224137931 ], [ 29.784554810126583, -1.464521724137931 ], [ 29.784824303797468, -1.464521724137931 ], [ 29.784824303797468, -1.46425224137931 ], [ 29.784554810126583, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25483, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.46425224137931 ], [ 29.784824303797468, -1.464521724137931 ], [ 29.785093797468356, -1.464521724137931 ], [ 29.785093797468356, -1.46425224137931 ], [ 29.784824303797468, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25484, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.46425224137931 ], [ 29.785093797468356, -1.464521724137931 ], [ 29.785363291139241, -1.464521724137931 ], [ 29.785363291139241, -1.46425224137931 ], [ 29.785093797468356, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25485, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.46425224137931 ], [ 29.785363291139241, -1.464521724137931 ], [ 29.785632784810126, -1.464521724137931 ], [ 29.785632784810126, -1.46425224137931 ], [ 29.785363291139241, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25486, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.46425224137931 ], [ 29.785632784810126, -1.464521724137931 ], [ 29.785902278481014, -1.464521724137931 ], [ 29.785902278481014, -1.46425224137931 ], [ 29.785632784810126, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25487, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.46425224137931 ], [ 29.785902278481014, -1.464521724137931 ], [ 29.786171772151899, -1.464521724137931 ], [ 29.786171772151899, -1.46425224137931 ], [ 29.785902278481014, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25488, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.46425224137931 ], [ 29.786171772151899, -1.464521724137931 ], [ 29.786441265822784, -1.464521724137931 ], [ 29.786441265822784, -1.46425224137931 ], [ 29.786171772151899, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25489, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.46425224137931 ], [ 29.786441265822784, -1.464521724137931 ], [ 29.786710759493673, -1.464521724137931 ], [ 29.786710759493673, -1.46425224137931 ], [ 29.786441265822784, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25490, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.46425224137931 ], [ 29.786710759493673, -1.464521724137931 ], [ 29.786980253164558, -1.464521724137931 ], [ 29.786980253164558, -1.46425224137931 ], [ 29.786710759493673, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25491, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.46425224137931 ], [ 29.786980253164558, -1.464521724137931 ], [ 29.787249746835442, -1.464521724137931 ], [ 29.787249746835442, -1.46425224137931 ], [ 29.786980253164558, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25492, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.46425224137931 ], [ 29.787249746835442, -1.464521724137931 ], [ 29.787519240506327, -1.464521724137931 ], [ 29.787519240506327, -1.46425224137931 ], [ 29.787249746835442, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25493, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.46425224137931 ], [ 29.787519240506327, -1.464521724137931 ], [ 29.787788734177216, -1.464521724137931 ], [ 29.787788734177216, -1.46425224137931 ], [ 29.787519240506327, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25494, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.46425224137931 ], [ 29.787788734177216, -1.464521724137931 ], [ 29.788058227848101, -1.464521724137931 ], [ 29.788058227848101, -1.46425224137931 ], [ 29.787788734177216, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25495, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.46425224137931 ], [ 29.788058227848101, -1.464521724137931 ], [ 29.788327721518986, -1.464521724137931 ], [ 29.788327721518986, -1.46425224137931 ], [ 29.788058227848101, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25496, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.46425224137931 ], [ 29.788327721518986, -1.464521724137931 ], [ 29.788597215189874, -1.464521724137931 ], [ 29.788597215189874, -1.46425224137931 ], [ 29.788327721518986, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25497, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794256582278482, -1.46425224137931 ], [ 29.794256582278482, -1.464521724137931 ], [ 29.794526075949367, -1.464521724137931 ], [ 29.794526075949367, -1.46425224137931 ], [ 29.794256582278482, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25498, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.46425224137931 ], [ 29.794526075949367, -1.464521724137931 ], [ 29.794795569620252, -1.464521724137931 ], [ 29.794795569620252, -1.46425224137931 ], [ 29.794526075949367, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25499, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.46425224137931 ], [ 29.794795569620252, -1.464521724137931 ], [ 29.79506506329114, -1.464521724137931 ], [ 29.79506506329114, -1.46425224137931 ], [ 29.794795569620252, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25500, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.46425224137931 ], [ 29.79506506329114, -1.464521724137931 ], [ 29.795334556962025, -1.464521724137931 ], [ 29.795334556962025, -1.46425224137931 ], [ 29.79506506329114, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25501, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.46425224137931 ], [ 29.79776, -1.464521724137931 ], [ 29.798029493670885, -1.464521724137931 ], [ 29.798029493670885, -1.46425224137931 ], [ 29.79776, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25502, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.46425224137931 ], [ 29.798029493670885, -1.464791206896552 ], [ 29.798298987341774, -1.464791206896552 ], [ 29.798298987341774, -1.46425224137931 ], [ 29.798029493670885, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25503, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.46425224137931 ], [ 29.798298987341774, -1.464521724137931 ], [ 29.798568481012659, -1.464521724137931 ], [ 29.798568481012659, -1.46425224137931 ], [ 29.798298987341774, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25504, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.46425224137931 ], [ 29.798568481012659, -1.464521724137931 ], [ 29.798837974683543, -1.464521724137931 ], [ 29.798837974683543, -1.46425224137931 ], [ 29.798568481012659, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25505, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.46425224137931 ], [ 29.798837974683543, -1.464521724137931 ], [ 29.799107468354432, -1.464521724137931 ], [ 29.799107468354432, -1.46425224137931 ], [ 29.798837974683543, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25506, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.46425224137931 ], [ 29.799376962025317, -1.464521724137931 ], [ 29.799646455696202, -1.464521724137931 ], [ 29.799646455696202, -1.46425224137931 ], [ 29.799376962025317, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25507, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.46425224137931 ], [ 29.799646455696202, -1.464521724137931 ], [ 29.79991594936709, -1.464521724137931 ], [ 29.79991594936709, -1.46425224137931 ], [ 29.799646455696202, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25508, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.46425224137931 ], [ 29.79991594936709, -1.464521724137931 ], [ 29.800185443037975, -1.464521724137931 ], [ 29.800185443037975, -1.46425224137931 ], [ 29.79991594936709, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25509, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.46425224137931 ], [ 29.800185443037975, -1.464521724137931 ], [ 29.80045493670886, -1.464521724137931 ], [ 29.80045493670886, -1.46425224137931 ], [ 29.800185443037975, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25510, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.46425224137931 ], [ 29.80045493670886, -1.464521724137931 ], [ 29.800724430379748, -1.464521724137931 ], [ 29.800724430379748, -1.46425224137931 ], [ 29.80045493670886, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25511, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.464521724137931 ], [ 29.754102025316456, -1.464791206896552 ], [ 29.754371518987341, -1.464791206896552 ], [ 29.754371518987341, -1.464521724137931 ], [ 29.754102025316456, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25512, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.464521724137931 ], [ 29.754371518987341, -1.464791206896552 ], [ 29.754641012658226, -1.464791206896552 ], [ 29.754641012658226, -1.464521724137931 ], [ 29.754371518987341, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25513, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.464521724137931 ], [ 29.754641012658226, -1.464791206896552 ], [ 29.754910506329114, -1.464791206896552 ], [ 29.754910506329114, -1.464521724137931 ], [ 29.754641012658226, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25514, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.464521724137931 ], [ 29.754910506329114, -1.464791206896552 ], [ 29.75518, -1.464791206896552 ], [ 29.75518, -1.464521724137931 ], [ 29.754910506329114, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25515, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.464521724137931 ], [ 29.75518, -1.464791206896552 ], [ 29.755449493670884, -1.464791206896552 ], [ 29.755449493670884, -1.464521724137931 ], [ 29.75518, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25516, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.464521724137931 ], [ 29.755449493670884, -1.464791206896552 ], [ 29.755718987341773, -1.464791206896552 ], [ 29.755718987341773, -1.464521724137931 ], [ 29.755449493670884, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25517, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.464521724137931 ], [ 29.755718987341773, -1.464791206896552 ], [ 29.755988481012658, -1.464791206896552 ], [ 29.755988481012658, -1.464521724137931 ], [ 29.755718987341773, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25518, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.464521724137931 ], [ 29.755988481012658, -1.464791206896552 ], [ 29.756257974683542, -1.464791206896552 ], [ 29.756257974683542, -1.464521724137931 ], [ 29.755988481012658, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25519, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.464521724137931 ], [ 29.756257974683542, -1.464791206896552 ], [ 29.756796962025316, -1.464791206896552 ], [ 29.756796962025316, -1.464521724137931 ], [ 29.756257974683542, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25520, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.464521724137931 ], [ 29.756796962025316, -1.464791206896552 ], [ 29.757066455696201, -1.464791206896552 ], [ 29.757066455696201, -1.464521724137931 ], [ 29.756796962025316, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25521, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.464521724137931 ], [ 29.757066455696201, -1.464791206896552 ], [ 29.757335949367089, -1.464791206896552 ], [ 29.757335949367089, -1.464521724137931 ], [ 29.757066455696201, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25522, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.464521724137931 ], [ 29.757335949367089, -1.464791206896552 ], [ 29.757605443037974, -1.464791206896552 ], [ 29.757605443037974, -1.464521724137931 ], [ 29.757335949367089, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25523, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.464521724137931 ], [ 29.757605443037974, -1.464791206896552 ], [ 29.757874936708859, -1.464791206896552 ], [ 29.757874936708859, -1.464521724137931 ], [ 29.757605443037974, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25524, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.464521724137931 ], [ 29.757874936708859, -1.464791206896552 ], [ 29.758144430379748, -1.464791206896552 ], [ 29.758144430379748, -1.464521724137931 ], [ 29.757874936708859, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25525, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.464521724137931 ], [ 29.758144430379748, -1.464791206896552 ], [ 29.758413924050632, -1.464791206896552 ], [ 29.758413924050632, -1.464521724137931 ], [ 29.758144430379748, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25526, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.464521724137931 ], [ 29.758413924050632, -1.464791206896552 ], [ 29.758683417721517, -1.464791206896552 ], [ 29.758683417721517, -1.464521724137931 ], [ 29.758413924050632, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25527, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.464521724137931 ], [ 29.758683417721517, -1.464791206896552 ], [ 29.758952911392406, -1.464791206896552 ], [ 29.758952911392406, -1.464521724137931 ], [ 29.758683417721517, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25528, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.464521724137931 ], [ 29.758952911392406, -1.464791206896552 ], [ 29.759222405063291, -1.464791206896552 ], [ 29.759222405063291, -1.464521724137931 ], [ 29.758952911392406, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25529, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.464521724137931 ], [ 29.759222405063291, -1.464791206896552 ], [ 29.759491898734176, -1.464791206896552 ], [ 29.759491898734176, -1.464521724137931 ], [ 29.759222405063291, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25530, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.464521724137931 ], [ 29.759491898734176, -1.464791206896552 ], [ 29.759761392405064, -1.464791206896552 ], [ 29.759761392405064, -1.464521724137931 ], [ 29.759491898734176, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25531, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.464521724137931 ], [ 29.759761392405064, -1.464791206896552 ], [ 29.760030886075949, -1.464791206896552 ], [ 29.760030886075949, -1.464521724137931 ], [ 29.759761392405064, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25532, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.464521724137931 ], [ 29.760300379746834, -1.464791206896552 ], [ 29.760569873417722, -1.464791206896552 ], [ 29.760569873417722, -1.464521724137931 ], [ 29.760300379746834, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25533, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.464521724137931 ], [ 29.760569873417722, -1.464791206896552 ], [ 29.760839367088607, -1.464791206896552 ], [ 29.760839367088607, -1.464521724137931 ], [ 29.760569873417722, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25534, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.464521724137931 ], [ 29.761108860759492, -1.465060689655173 ], [ 29.761378354430381, -1.465060689655173 ], [ 29.761378354430381, -1.464521724137931 ], [ 29.761108860759492, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25535, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.464521724137931 ], [ 29.761378354430381, -1.464791206896552 ], [ 29.761647848101266, -1.464791206896552 ], [ 29.761647848101266, -1.464521724137931 ], [ 29.761378354430381, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25536, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.464521724137931 ], [ 29.761647848101266, -1.464791206896552 ], [ 29.761917341772151, -1.464791206896552 ], [ 29.761917341772151, -1.464521724137931 ], [ 29.761647848101266, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25537, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.464521724137931 ], [ 29.761917341772151, -1.465060689655173 ], [ 29.762186835443039, -1.465060689655173 ], [ 29.762186835443039, -1.464521724137931 ], [ 29.761917341772151, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25538, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.464521724137931 ], [ 29.762186835443039, -1.464791206896552 ], [ 29.762456329113924, -1.464791206896552 ], [ 29.762456329113924, -1.464521724137931 ], [ 29.762186835443039, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25539, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.464521724137931 ], [ 29.762456329113924, -1.464791206896552 ], [ 29.762725822784809, -1.464791206896552 ], [ 29.762725822784809, -1.464521724137931 ], [ 29.762456329113924, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25540, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.464521724137931 ], [ 29.762725822784809, -1.465060689655173 ], [ 29.762995316455697, -1.465060689655173 ], [ 29.762995316455697, -1.464521724137931 ], [ 29.762725822784809, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25541, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.464521724137931 ], [ 29.762995316455697, -1.464791206896552 ], [ 29.763264810126582, -1.464791206896552 ], [ 29.763264810126582, -1.464521724137931 ], [ 29.762995316455697, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25542, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.464521724137931 ], [ 29.763264810126582, -1.464791206896552 ], [ 29.763534303797467, -1.464791206896552 ], [ 29.763534303797467, -1.464521724137931 ], [ 29.763264810126582, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25543, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.464521724137931 ], [ 29.763534303797467, -1.464791206896552 ], [ 29.763803797468356, -1.464791206896552 ], [ 29.763803797468356, -1.464521724137931 ], [ 29.763534303797467, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25544, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.464521724137931 ], [ 29.76407329113924, -1.464791206896552 ], [ 29.764342784810125, -1.464791206896552 ], [ 29.764342784810125, -1.464521724137931 ], [ 29.76407329113924, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25545, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.464521724137931 ], [ 29.764342784810125, -1.464791206896552 ], [ 29.764612278481014, -1.464791206896552 ], [ 29.764612278481014, -1.464521724137931 ], [ 29.764342784810125, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25546, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.464521724137931 ], [ 29.771888607594935, -1.464791206896552 ], [ 29.772158101265823, -1.464791206896552 ], [ 29.772158101265823, -1.464521724137931 ], [ 29.771888607594935, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25547, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.464521724137931 ], [ 29.772158101265823, -1.464791206896552 ], [ 29.772427594936708, -1.464791206896552 ], [ 29.772427594936708, -1.464521724137931 ], [ 29.772158101265823, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25548, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.464521724137931 ], [ 29.772427594936708, -1.464791206896552 ], [ 29.772697088607593, -1.464791206896552 ], [ 29.772697088607593, -1.464521724137931 ], [ 29.772427594936708, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25549, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.464521724137931 ], [ 29.772697088607593, -1.464791206896552 ], [ 29.772966582278482, -1.464791206896552 ], [ 29.772966582278482, -1.464521724137931 ], [ 29.772697088607593, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25550, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.464521724137931 ], [ 29.772966582278482, -1.464791206896552 ], [ 29.773236075949367, -1.464791206896552 ], [ 29.773236075949367, -1.464521724137931 ], [ 29.772966582278482, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25551, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.464521724137931 ], [ 29.773236075949367, -1.464791206896552 ], [ 29.773505569620252, -1.464791206896552 ], [ 29.773505569620252, -1.464521724137931 ], [ 29.773236075949367, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25552, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.464521724137931 ], [ 29.773505569620252, -1.464791206896552 ], [ 29.77377506329114, -1.464791206896552 ], [ 29.77377506329114, -1.464521724137931 ], [ 29.773505569620252, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25553, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.464521724137931 ], [ 29.77377506329114, -1.464791206896552 ], [ 29.774044556962025, -1.464791206896552 ], [ 29.774044556962025, -1.464521724137931 ], [ 29.77377506329114, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25554, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.464521724137931 ], [ 29.774044556962025, -1.464791206896552 ], [ 29.77431405063291, -1.464791206896552 ], [ 29.77431405063291, -1.464521724137931 ], [ 29.774044556962025, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25555, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.464521724137931 ], [ 29.77431405063291, -1.464791206896552 ], [ 29.774853037974683, -1.464791206896552 ], [ 29.774853037974683, -1.464521724137931 ], [ 29.77431405063291, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25556, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.464521724137931 ], [ 29.774853037974683, -1.464791206896552 ], [ 29.775122531645568, -1.464791206896552 ], [ 29.775122531645568, -1.464521724137931 ], [ 29.774853037974683, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25557, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.464521724137931 ], [ 29.775392025316457, -1.464791206896552 ], [ 29.775661518987341, -1.464791206896552 ], [ 29.775661518987341, -1.464521724137931 ], [ 29.775392025316457, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25558, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.464521724137931 ], [ 29.775661518987341, -1.464791206896552 ], [ 29.775931012658226, -1.464791206896552 ], [ 29.775931012658226, -1.464521724137931 ], [ 29.775661518987341, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25559, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.464521724137931 ], [ 29.775931012658226, -1.465060689655173 ], [ 29.776200506329115, -1.465060689655173 ], [ 29.776200506329115, -1.464521724137931 ], [ 29.775931012658226, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25560, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.464521724137931 ], [ 29.776200506329115, -1.464791206896552 ], [ 29.77647, -1.464791206896552 ], [ 29.77647, -1.464521724137931 ], [ 29.776200506329115, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25561, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.464521724137931 ], [ 29.77647, -1.464791206896552 ], [ 29.776739493670885, -1.464791206896552 ], [ 29.776739493670885, -1.464521724137931 ], [ 29.77647, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25562, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.464521724137931 ], [ 29.776739493670885, -1.465060689655173 ], [ 29.777008987341773, -1.465060689655173 ], [ 29.777008987341773, -1.464521724137931 ], [ 29.776739493670885, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25563, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.464521724137931 ], [ 29.777278481012658, -1.464791206896552 ], [ 29.777547974683543, -1.464791206896552 ], [ 29.777547974683543, -1.464521724137931 ], [ 29.777278481012658, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25564, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.464521724137931 ], [ 29.777547974683543, -1.465060689655173 ], [ 29.777817468354431, -1.465060689655173 ], [ 29.777817468354431, -1.464521724137931 ], [ 29.777547974683543, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25565, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.464521724137931 ], [ 29.778086962025316, -1.465060689655173 ], [ 29.778356455696201, -1.465060689655173 ], [ 29.778356455696201, -1.464521724137931 ], [ 29.778086962025316, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25566, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.464521724137931 ], [ 29.778356455696201, -1.465060689655173 ], [ 29.77862594936709, -1.465060689655173 ], [ 29.77862594936709, -1.464521724137931 ], [ 29.778356455696201, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25567, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.464521724137931 ], [ 29.779434430379748, -1.464791206896552 ], [ 29.779703924050633, -1.464791206896552 ], [ 29.779703924050633, -1.464521724137931 ], [ 29.779434430379748, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25568, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.464521724137931 ], [ 29.779703924050633, -1.465060689655173 ], [ 29.779973417721518, -1.465060689655173 ], [ 29.779973417721518, -1.464521724137931 ], [ 29.779703924050633, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25569, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.464521724137931 ], [ 29.780242911392406, -1.464791206896552 ], [ 29.780512405063291, -1.464791206896552 ], [ 29.780512405063291, -1.464521724137931 ], [ 29.780242911392406, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25570, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.464521724137931 ], [ 29.780512405063291, -1.464791206896552 ], [ 29.780781898734176, -1.464791206896552 ], [ 29.780781898734176, -1.464521724137931 ], [ 29.780512405063291, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25571, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.464521724137931 ], [ 29.781051392405065, -1.464791206896552 ], [ 29.78132088607595, -1.464791206896552 ], [ 29.78132088607595, -1.464521724137931 ], [ 29.781051392405065, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25572, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.464521724137931 ], [ 29.78132088607595, -1.464791206896552 ], [ 29.781590379746834, -1.464791206896552 ], [ 29.781590379746834, -1.464521724137931 ], [ 29.78132088607595, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25573, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.464521724137931 ], [ 29.781590379746834, -1.465060689655173 ], [ 29.781859873417723, -1.465060689655173 ], [ 29.781859873417723, -1.464521724137931 ], [ 29.781590379746834, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25574, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.464521724137931 ], [ 29.781859873417723, -1.464791206896552 ], [ 29.782129367088608, -1.464791206896552 ], [ 29.782129367088608, -1.464521724137931 ], [ 29.781859873417723, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25575, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.464521724137931 ], [ 29.782129367088608, -1.464791206896552 ], [ 29.782398860759493, -1.464791206896552 ], [ 29.782398860759493, -1.464521724137931 ], [ 29.782129367088608, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25576, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.464521724137931 ], [ 29.782398860759493, -1.464791206896552 ], [ 29.782668354430381, -1.464791206896552 ], [ 29.782668354430381, -1.464521724137931 ], [ 29.782398860759493, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25577, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.464521724137931 ], [ 29.782937848101266, -1.464791206896552 ], [ 29.783207341772151, -1.464791206896552 ], [ 29.783207341772151, -1.464521724137931 ], [ 29.782937848101266, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25578, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.464521724137931 ], [ 29.783207341772151, -1.464791206896552 ], [ 29.783476835443039, -1.464791206896552 ], [ 29.783476835443039, -1.464521724137931 ], [ 29.783207341772151, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25579, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.464521724137931 ], [ 29.783476835443039, -1.464791206896552 ], [ 29.784015822784809, -1.464791206896552 ], [ 29.784015822784809, -1.464521724137931 ], [ 29.783476835443039, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25580, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.464521724137931 ], [ 29.784015822784809, -1.464791206896552 ], [ 29.784285316455698, -1.464791206896552 ], [ 29.784285316455698, -1.464521724137931 ], [ 29.784015822784809, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25581, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.464521724137931 ], [ 29.784285316455698, -1.464791206896552 ], [ 29.784554810126583, -1.464791206896552 ], [ 29.784554810126583, -1.464521724137931 ], [ 29.784285316455698, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25582, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.464521724137931 ], [ 29.784554810126583, -1.464791206896552 ], [ 29.784824303797468, -1.464791206896552 ], [ 29.784824303797468, -1.464521724137931 ], [ 29.784554810126583, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25583, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.464521724137931 ], [ 29.784824303797468, -1.464791206896552 ], [ 29.785093797468356, -1.464791206896552 ], [ 29.785093797468356, -1.464521724137931 ], [ 29.784824303797468, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25584, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.464521724137931 ], [ 29.785093797468356, -1.464791206896552 ], [ 29.785363291139241, -1.464791206896552 ], [ 29.785363291139241, -1.464521724137931 ], [ 29.785093797468356, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25585, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.464521724137931 ], [ 29.785363291139241, -1.464791206896552 ], [ 29.785632784810126, -1.464791206896552 ], [ 29.785632784810126, -1.464521724137931 ], [ 29.785363291139241, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25586, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.464521724137931 ], [ 29.785632784810126, -1.464791206896552 ], [ 29.785902278481014, -1.464791206896552 ], [ 29.785902278481014, -1.464521724137931 ], [ 29.785632784810126, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25587, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.464521724137931 ], [ 29.785902278481014, -1.464791206896552 ], [ 29.786171772151899, -1.464791206896552 ], [ 29.786171772151899, -1.464521724137931 ], [ 29.785902278481014, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25588, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.464521724137931 ], [ 29.786171772151899, -1.464791206896552 ], [ 29.786441265822784, -1.464791206896552 ], [ 29.786441265822784, -1.464521724137931 ], [ 29.786171772151899, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25589, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.464521724137931 ], [ 29.786441265822784, -1.464791206896552 ], [ 29.786710759493673, -1.464791206896552 ], [ 29.786710759493673, -1.464521724137931 ], [ 29.786441265822784, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25590, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.464521724137931 ], [ 29.786710759493673, -1.464791206896552 ], [ 29.786980253164558, -1.464791206896552 ], [ 29.786980253164558, -1.464521724137931 ], [ 29.786710759493673, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25591, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.464521724137931 ], [ 29.786980253164558, -1.464791206896552 ], [ 29.787249746835442, -1.464791206896552 ], [ 29.787249746835442, -1.464521724137931 ], [ 29.786980253164558, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25592, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.464521724137931 ], [ 29.787249746835442, -1.464791206896552 ], [ 29.787519240506327, -1.464791206896552 ], [ 29.787519240506327, -1.464521724137931 ], [ 29.787249746835442, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25593, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.464521724137931 ], [ 29.787519240506327, -1.464791206896552 ], [ 29.787788734177216, -1.464791206896552 ], [ 29.787788734177216, -1.464521724137931 ], [ 29.787519240506327, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25594, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.464521724137931 ], [ 29.787788734177216, -1.464791206896552 ], [ 29.788058227848101, -1.464791206896552 ], [ 29.788058227848101, -1.464521724137931 ], [ 29.787788734177216, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25595, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.464521724137931 ], [ 29.79776, -1.464791206896552 ], [ 29.798029493670885, -1.464791206896552 ], [ 29.798029493670885, -1.464521724137931 ], [ 29.79776, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25596, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.464521724137931 ], [ 29.798298987341774, -1.464791206896552 ], [ 29.798568481012659, -1.464791206896552 ], [ 29.798568481012659, -1.464521724137931 ], [ 29.798298987341774, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25597, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.464521724137931 ], [ 29.798568481012659, -1.464791206896552 ], [ 29.798837974683543, -1.464791206896552 ], [ 29.798837974683543, -1.464521724137931 ], [ 29.798568481012659, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25598, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.464521724137931 ], [ 29.798837974683543, -1.464791206896552 ], [ 29.799107468354432, -1.464791206896552 ], [ 29.799107468354432, -1.464521724137931 ], [ 29.798837974683543, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25599, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.464521724137931 ], [ 29.799107468354432, -1.464791206896552 ], [ 29.799376962025317, -1.464791206896552 ], [ 29.799376962025317, -1.464521724137931 ], [ 29.799107468354432, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25600, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.464521724137931 ], [ 29.799376962025317, -1.464791206896552 ], [ 29.799646455696202, -1.464791206896552 ], [ 29.799646455696202, -1.464521724137931 ], [ 29.799376962025317, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25601, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.464521724137931 ], [ 29.799646455696202, -1.464791206896552 ], [ 29.79991594936709, -1.464791206896552 ], [ 29.79991594936709, -1.464521724137931 ], [ 29.799646455696202, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25602, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.464521724137931 ], [ 29.79991594936709, -1.464791206896552 ], [ 29.800185443037975, -1.464791206896552 ], [ 29.800185443037975, -1.464521724137931 ], [ 29.79991594936709, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25603, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.464521724137931 ], [ 29.800185443037975, -1.464791206896552 ], [ 29.80045493670886, -1.464791206896552 ], [ 29.80045493670886, -1.464521724137931 ], [ 29.800185443037975, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25604, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.464521724137931 ], [ 29.80045493670886, -1.464791206896552 ], [ 29.800724430379748, -1.464791206896552 ], [ 29.800724430379748, -1.464521724137931 ], [ 29.80045493670886, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25605, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.464521724137931 ], [ 29.800724430379748, -1.464791206896552 ], [ 29.800993924050633, -1.464791206896552 ], [ 29.800993924050633, -1.464521724137931 ], [ 29.800724430379748, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25606, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.464521724137931 ], [ 29.800993924050633, -1.465060689655173 ], [ 29.801263417721518, -1.465060689655173 ], [ 29.801263417721518, -1.464521724137931 ], [ 29.800993924050633, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25607, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754102025316456, -1.464791206896552 ], [ 29.754102025316456, -1.465060689655173 ], [ 29.754371518987341, -1.465060689655173 ], [ 29.754371518987341, -1.464791206896552 ], [ 29.754102025316456, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25608, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.464791206896552 ], [ 29.754371518987341, -1.465060689655173 ], [ 29.754641012658226, -1.465060689655173 ], [ 29.754641012658226, -1.464791206896552 ], [ 29.754371518987341, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25609, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.464791206896552 ], [ 29.754641012658226, -1.465060689655173 ], [ 29.754910506329114, -1.465060689655173 ], [ 29.754910506329114, -1.464791206896552 ], [ 29.754641012658226, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25610, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.464791206896552 ], [ 29.754910506329114, -1.465060689655173 ], [ 29.75518, -1.465060689655173 ], [ 29.75518, -1.464791206896552 ], [ 29.754910506329114, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25611, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.464791206896552 ], [ 29.75518, -1.465060689655173 ], [ 29.755449493670884, -1.465060689655173 ], [ 29.755449493670884, -1.464791206896552 ], [ 29.75518, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25612, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.464791206896552 ], [ 29.755449493670884, -1.465060689655173 ], [ 29.755718987341773, -1.465060689655173 ], [ 29.755718987341773, -1.464791206896552 ], [ 29.755449493670884, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25613, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.464791206896552 ], [ 29.755718987341773, -1.465060689655173 ], [ 29.755988481012658, -1.465060689655173 ], [ 29.755988481012658, -1.464791206896552 ], [ 29.755718987341773, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25614, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.464791206896552 ], [ 29.755988481012658, -1.465060689655173 ], [ 29.756257974683542, -1.465060689655173 ], [ 29.756257974683542, -1.464791206896552 ], [ 29.755988481012658, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25615, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.464791206896552 ], [ 29.756257974683542, -1.465060689655173 ], [ 29.756796962025316, -1.465060689655173 ], [ 29.756796962025316, -1.464791206896552 ], [ 29.756257974683542, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25616, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.464791206896552 ], [ 29.756796962025316, -1.465060689655173 ], [ 29.757066455696201, -1.465060689655173 ], [ 29.757066455696201, -1.464791206896552 ], [ 29.756796962025316, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25617, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.464791206896552 ], [ 29.757066455696201, -1.465060689655173 ], [ 29.757335949367089, -1.465060689655173 ], [ 29.757335949367089, -1.464791206896552 ], [ 29.757066455696201, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25618, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.464791206896552 ], [ 29.757335949367089, -1.465060689655173 ], [ 29.757605443037974, -1.465060689655173 ], [ 29.757605443037974, -1.464791206896552 ], [ 29.757335949367089, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25619, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.464791206896552 ], [ 29.757605443037974, -1.465060689655173 ], [ 29.757874936708859, -1.465060689655173 ], [ 29.757874936708859, -1.464791206896552 ], [ 29.757605443037974, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25620, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.464791206896552 ], [ 29.757874936708859, -1.465060689655173 ], [ 29.758144430379748, -1.465060689655173 ], [ 29.758144430379748, -1.464791206896552 ], [ 29.757874936708859, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25621, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.464791206896552 ], [ 29.758144430379748, -1.465060689655173 ], [ 29.758413924050632, -1.465060689655173 ], [ 29.758413924050632, -1.464791206896552 ], [ 29.758144430379748, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25622, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.464791206896552 ], [ 29.758413924050632, -1.465060689655173 ], [ 29.758683417721517, -1.465060689655173 ], [ 29.758683417721517, -1.464791206896552 ], [ 29.758413924050632, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25623, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.464791206896552 ], [ 29.758683417721517, -1.465060689655173 ], [ 29.758952911392406, -1.465060689655173 ], [ 29.758952911392406, -1.464791206896552 ], [ 29.758683417721517, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25624, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.464791206896552 ], [ 29.758952911392406, -1.465330172413793 ], [ 29.759222405063291, -1.465330172413793 ], [ 29.759222405063291, -1.464791206896552 ], [ 29.758952911392406, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25625, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.464791206896552 ], [ 29.759222405063291, -1.465060689655173 ], [ 29.759491898734176, -1.465060689655173 ], [ 29.759491898734176, -1.464791206896552 ], [ 29.759222405063291, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25626, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.464791206896552 ], [ 29.759491898734176, -1.465060689655173 ], [ 29.759761392405064, -1.465060689655173 ], [ 29.759761392405064, -1.464791206896552 ], [ 29.759491898734176, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25627, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.464791206896552 ], [ 29.759761392405064, -1.465060689655173 ], [ 29.760030886075949, -1.465060689655173 ], [ 29.760030886075949, -1.464791206896552 ], [ 29.759761392405064, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25628, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.464791206896552 ], [ 29.760030886075949, -1.465060689655173 ], [ 29.760300379746834, -1.465060689655173 ], [ 29.760300379746834, -1.464791206896552 ], [ 29.760030886075949, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25629, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.464791206896552 ], [ 29.760300379746834, -1.465060689655173 ], [ 29.760569873417722, -1.465060689655173 ], [ 29.760569873417722, -1.464791206896552 ], [ 29.760300379746834, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25630, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.464791206896552 ], [ 29.760569873417722, -1.465060689655173 ], [ 29.760839367088607, -1.465060689655173 ], [ 29.760839367088607, -1.464791206896552 ], [ 29.760569873417722, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25631, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.464791206896552 ], [ 29.760839367088607, -1.465060689655173 ], [ 29.761108860759492, -1.465060689655173 ], [ 29.761108860759492, -1.464791206896552 ], [ 29.760839367088607, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25632, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.464791206896552 ], [ 29.761647848101266, -1.465330172413793 ], [ 29.761917341772151, -1.465330172413793 ], [ 29.761917341772151, -1.464791206896552 ], [ 29.761647848101266, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25633, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.464791206896552 ], [ 29.762186835443039, -1.465330172413793 ], [ 29.762456329113924, -1.465330172413793 ], [ 29.762456329113924, -1.464791206896552 ], [ 29.762186835443039, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25634, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.464791206896552 ], [ 29.762456329113924, -1.465060689655173 ], [ 29.762725822784809, -1.465060689655173 ], [ 29.762725822784809, -1.464791206896552 ], [ 29.762456329113924, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25635, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.464791206896552 ], [ 29.762995316455697, -1.465330172413793 ], [ 29.763264810126582, -1.465330172413793 ], [ 29.763264810126582, -1.464791206896552 ], [ 29.762995316455697, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25636, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.464791206896552 ], [ 29.763264810126582, -1.465060689655173 ], [ 29.763534303797467, -1.465060689655173 ], [ 29.763534303797467, -1.464791206896552 ], [ 29.763264810126582, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25637, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.464791206896552 ], [ 29.763534303797467, -1.465060689655173 ], [ 29.763803797468356, -1.465060689655173 ], [ 29.763803797468356, -1.464791206896552 ], [ 29.763534303797467, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25638, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.464791206896552 ], [ 29.763803797468356, -1.465330172413793 ], [ 29.76407329113924, -1.465330172413793 ], [ 29.76407329113924, -1.464791206896552 ], [ 29.763803797468356, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25639, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.464791206896552 ], [ 29.76407329113924, -1.465060689655173 ], [ 29.764342784810125, -1.465060689655173 ], [ 29.764342784810125, -1.464791206896552 ], [ 29.76407329113924, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25640, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.464791206896552 ], [ 29.764342784810125, -1.465060689655173 ], [ 29.764612278481014, -1.465060689655173 ], [ 29.764612278481014, -1.464791206896552 ], [ 29.764342784810125, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25641, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.464791206896552 ], [ 29.764612278481014, -1.465060689655173 ], [ 29.764881772151899, -1.465060689655173 ], [ 29.764881772151899, -1.464791206896552 ], [ 29.764612278481014, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25642, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.464791206896552 ], [ 29.764881772151899, -1.465060689655173 ], [ 29.765151265822784, -1.465060689655173 ], [ 29.765151265822784, -1.464791206896552 ], [ 29.764881772151899, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25643, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.464791206896552 ], [ 29.771080126582277, -1.465060689655173 ], [ 29.771349620253165, -1.465060689655173 ], [ 29.771349620253165, -1.464791206896552 ], [ 29.771080126582277, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25644, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.464791206896552 ], [ 29.771349620253165, -1.465060689655173 ], [ 29.77161911392405, -1.465060689655173 ], [ 29.77161911392405, -1.464791206896552 ], [ 29.771349620253165, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25645, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.464791206896552 ], [ 29.77161911392405, -1.465060689655173 ], [ 29.771888607594935, -1.465060689655173 ], [ 29.771888607594935, -1.464791206896552 ], [ 29.77161911392405, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25646, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.464791206896552 ], [ 29.771888607594935, -1.465060689655173 ], [ 29.772158101265823, -1.465060689655173 ], [ 29.772158101265823, -1.464791206896552 ], [ 29.771888607594935, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25647, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.464791206896552 ], [ 29.772158101265823, -1.465060689655173 ], [ 29.772427594936708, -1.465060689655173 ], [ 29.772427594936708, -1.464791206896552 ], [ 29.772158101265823, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25648, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.464791206896552 ], [ 29.772427594936708, -1.465060689655173 ], [ 29.772697088607593, -1.465060689655173 ], [ 29.772697088607593, -1.464791206896552 ], [ 29.772427594936708, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25649, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.464791206896552 ], [ 29.772697088607593, -1.465060689655173 ], [ 29.772966582278482, -1.465060689655173 ], [ 29.772966582278482, -1.464791206896552 ], [ 29.772697088607593, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25650, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.464791206896552 ], [ 29.772966582278482, -1.465060689655173 ], [ 29.773236075949367, -1.465060689655173 ], [ 29.773236075949367, -1.464791206896552 ], [ 29.772966582278482, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25651, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.464791206896552 ], [ 29.773236075949367, -1.465060689655173 ], [ 29.773505569620252, -1.465060689655173 ], [ 29.773505569620252, -1.464791206896552 ], [ 29.773236075949367, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25652, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.464791206896552 ], [ 29.773505569620252, -1.465060689655173 ], [ 29.77377506329114, -1.465060689655173 ], [ 29.77377506329114, -1.464791206896552 ], [ 29.773505569620252, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25653, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.464791206896552 ], [ 29.77377506329114, -1.465330172413793 ], [ 29.774044556962025, -1.465330172413793 ], [ 29.774044556962025, -1.464791206896552 ], [ 29.77377506329114, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25654, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.464791206896552 ], [ 29.774044556962025, -1.465060689655173 ], [ 29.77431405063291, -1.465060689655173 ], [ 29.77431405063291, -1.464791206896552 ], [ 29.774044556962025, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25655, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.464791206896552 ], [ 29.77431405063291, -1.465060689655173 ], [ 29.774853037974683, -1.465060689655173 ], [ 29.774853037974683, -1.464791206896552 ], [ 29.77431405063291, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25656, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.464791206896552 ], [ 29.774853037974683, -1.465330172413793 ], [ 29.775122531645568, -1.465330172413793 ], [ 29.775122531645568, -1.464791206896552 ], [ 29.774853037974683, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25657, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.464791206896552 ], [ 29.775122531645568, -1.465060689655173 ], [ 29.775392025316457, -1.465060689655173 ], [ 29.775392025316457, -1.464791206896552 ], [ 29.775122531645568, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25658, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.464791206896552 ], [ 29.775392025316457, -1.465060689655173 ], [ 29.775661518987341, -1.465060689655173 ], [ 29.775661518987341, -1.464791206896552 ], [ 29.775392025316457, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25659, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.464791206896552 ], [ 29.775661518987341, -1.465330172413793 ], [ 29.775931012658226, -1.465330172413793 ], [ 29.775931012658226, -1.464791206896552 ], [ 29.775661518987341, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25660, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.464791206896552 ], [ 29.776200506329115, -1.465060689655173 ], [ 29.77647, -1.465060689655173 ], [ 29.77647, -1.464791206896552 ], [ 29.776200506329115, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25661, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.464791206896552 ], [ 29.77647, -1.465330172413793 ], [ 29.776739493670885, -1.465330172413793 ], [ 29.776739493670885, -1.464791206896552 ], [ 29.77647, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25662, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.464791206896552 ], [ 29.777008987341773, -1.465330172413793 ], [ 29.777278481012658, -1.465330172413793 ], [ 29.777278481012658, -1.464791206896552 ], [ 29.777008987341773, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25663, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.464791206896552 ], [ 29.777817468354431, -1.465330172413793 ], [ 29.778086962025316, -1.465330172413793 ], [ 29.778086962025316, -1.464791206896552 ], [ 29.777817468354431, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25664, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.464791206896552 ], [ 29.77916493670886, -1.465060689655173 ], [ 29.779434430379748, -1.465060689655173 ], [ 29.779434430379748, -1.464791206896552 ], [ 29.77916493670886, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25665, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.464791206896552 ], [ 29.779434430379748, -1.465060689655173 ], [ 29.779703924050633, -1.465060689655173 ], [ 29.779703924050633, -1.464791206896552 ], [ 29.779434430379748, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25666, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.464791206896552 ], [ 29.779973417721518, -1.465060689655173 ], [ 29.780242911392406, -1.465060689655173 ], [ 29.780242911392406, -1.464791206896552 ], [ 29.779973417721518, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25667, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.464791206896552 ], [ 29.780242911392406, -1.465060689655173 ], [ 29.780512405063291, -1.465060689655173 ], [ 29.780512405063291, -1.464791206896552 ], [ 29.780242911392406, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25668, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.464791206896552 ], [ 29.780512405063291, -1.465330172413793 ], [ 29.780781898734176, -1.465330172413793 ], [ 29.780781898734176, -1.464791206896552 ], [ 29.780512405063291, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25669, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.464791206896552 ], [ 29.780781898734176, -1.465060689655173 ], [ 29.781051392405065, -1.465060689655173 ], [ 29.781051392405065, -1.464791206896552 ], [ 29.780781898734176, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25670, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.464791206896552 ], [ 29.781051392405065, -1.465060689655173 ], [ 29.78132088607595, -1.465060689655173 ], [ 29.78132088607595, -1.464791206896552 ], [ 29.781051392405065, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25671, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.464791206896552 ], [ 29.78132088607595, -1.465060689655173 ], [ 29.781590379746834, -1.465060689655173 ], [ 29.781590379746834, -1.464791206896552 ], [ 29.78132088607595, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25672, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.464791206896552 ], [ 29.781859873417723, -1.465060689655173 ], [ 29.782129367088608, -1.465060689655173 ], [ 29.782129367088608, -1.464791206896552 ], [ 29.781859873417723, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25673, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.464791206896552 ], [ 29.782129367088608, -1.465060689655173 ], [ 29.782398860759493, -1.465060689655173 ], [ 29.782398860759493, -1.464791206896552 ], [ 29.782129367088608, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25674, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.464791206896552 ], [ 29.782398860759493, -1.465060689655173 ], [ 29.782668354430381, -1.465060689655173 ], [ 29.782668354430381, -1.464791206896552 ], [ 29.782398860759493, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25675, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.464791206896552 ], [ 29.782668354430381, -1.465060689655173 ], [ 29.782937848101266, -1.465060689655173 ], [ 29.782937848101266, -1.464791206896552 ], [ 29.782668354430381, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25676, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.464791206896552 ], [ 29.782937848101266, -1.465060689655173 ], [ 29.783207341772151, -1.465060689655173 ], [ 29.783207341772151, -1.464791206896552 ], [ 29.782937848101266, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25677, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.464791206896552 ], [ 29.783207341772151, -1.465060689655173 ], [ 29.783476835443039, -1.465060689655173 ], [ 29.783476835443039, -1.464791206896552 ], [ 29.783207341772151, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25678, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.464791206896552 ], [ 29.783476835443039, -1.465060689655173 ], [ 29.784015822784809, -1.465060689655173 ], [ 29.784015822784809, -1.464791206896552 ], [ 29.783476835443039, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25679, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.464791206896552 ], [ 29.784015822784809, -1.465060689655173 ], [ 29.784285316455698, -1.465060689655173 ], [ 29.784285316455698, -1.464791206896552 ], [ 29.784015822784809, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25680, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.464791206896552 ], [ 29.784285316455698, -1.465060689655173 ], [ 29.784554810126583, -1.465060689655173 ], [ 29.784554810126583, -1.464791206896552 ], [ 29.784285316455698, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25681, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.464791206896552 ], [ 29.784554810126583, -1.465060689655173 ], [ 29.784824303797468, -1.465060689655173 ], [ 29.784824303797468, -1.464791206896552 ], [ 29.784554810126583, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25682, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.464791206896552 ], [ 29.784824303797468, -1.465060689655173 ], [ 29.785093797468356, -1.465060689655173 ], [ 29.785093797468356, -1.464791206896552 ], [ 29.784824303797468, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25683, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.464791206896552 ], [ 29.785093797468356, -1.465060689655173 ], [ 29.785363291139241, -1.465060689655173 ], [ 29.785363291139241, -1.464791206896552 ], [ 29.785093797468356, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25684, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.464791206896552 ], [ 29.785363291139241, -1.465060689655173 ], [ 29.785632784810126, -1.465060689655173 ], [ 29.785632784810126, -1.464791206896552 ], [ 29.785363291139241, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25685, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.464791206896552 ], [ 29.785632784810126, -1.465060689655173 ], [ 29.785902278481014, -1.465060689655173 ], [ 29.785902278481014, -1.464791206896552 ], [ 29.785632784810126, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25686, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.464791206896552 ], [ 29.785902278481014, -1.465060689655173 ], [ 29.786171772151899, -1.465060689655173 ], [ 29.786171772151899, -1.464791206896552 ], [ 29.785902278481014, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25687, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.464791206896552 ], [ 29.786171772151899, -1.465060689655173 ], [ 29.786441265822784, -1.465060689655173 ], [ 29.786441265822784, -1.464791206896552 ], [ 29.786171772151899, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25688, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.464791206896552 ], [ 29.786441265822784, -1.465060689655173 ], [ 29.786710759493673, -1.465060689655173 ], [ 29.786710759493673, -1.464791206896552 ], [ 29.786441265822784, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25689, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.464791206896552 ], [ 29.786710759493673, -1.465060689655173 ], [ 29.786980253164558, -1.465060689655173 ], [ 29.786980253164558, -1.464791206896552 ], [ 29.786710759493673, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25690, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.464791206896552 ], [ 29.786980253164558, -1.465060689655173 ], [ 29.787249746835442, -1.465060689655173 ], [ 29.787249746835442, -1.464791206896552 ], [ 29.786980253164558, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25691, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.464791206896552 ], [ 29.79776, -1.465060689655173 ], [ 29.798029493670885, -1.465060689655173 ], [ 29.798029493670885, -1.464791206896552 ], [ 29.79776, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25692, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.464791206896552 ], [ 29.798029493670885, -1.465060689655173 ], [ 29.798298987341774, -1.465060689655173 ], [ 29.798298987341774, -1.464791206896552 ], [ 29.798029493670885, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25693, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.464791206896552 ], [ 29.798298987341774, -1.465060689655173 ], [ 29.798568481012659, -1.465060689655173 ], [ 29.798568481012659, -1.464791206896552 ], [ 29.798298987341774, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25694, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.464791206896552 ], [ 29.798568481012659, -1.465060689655173 ], [ 29.798837974683543, -1.465060689655173 ], [ 29.798837974683543, -1.464791206896552 ], [ 29.798568481012659, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25695, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.464791206896552 ], [ 29.798837974683543, -1.465060689655173 ], [ 29.799107468354432, -1.465060689655173 ], [ 29.799107468354432, -1.464791206896552 ], [ 29.798837974683543, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25696, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.464791206896552 ], [ 29.799107468354432, -1.465060689655173 ], [ 29.799376962025317, -1.465060689655173 ], [ 29.799376962025317, -1.464791206896552 ], [ 29.799107468354432, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25697, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.464791206896552 ], [ 29.799376962025317, -1.465060689655173 ], [ 29.799646455696202, -1.465060689655173 ], [ 29.799646455696202, -1.464791206896552 ], [ 29.799376962025317, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25698, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.464791206896552 ], [ 29.799646455696202, -1.465060689655173 ], [ 29.79991594936709, -1.465060689655173 ], [ 29.79991594936709, -1.464791206896552 ], [ 29.799646455696202, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25699, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.464791206896552 ], [ 29.79991594936709, -1.465060689655173 ], [ 29.800185443037975, -1.465060689655173 ], [ 29.800185443037975, -1.464791206896552 ], [ 29.79991594936709, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25700, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.464791206896552 ], [ 29.800185443037975, -1.465060689655173 ], [ 29.80045493670886, -1.465060689655173 ], [ 29.80045493670886, -1.464791206896552 ], [ 29.800185443037975, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25701, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.464791206896552 ], [ 29.80045493670886, -1.465060689655173 ], [ 29.800724430379748, -1.465060689655173 ], [ 29.800724430379748, -1.464791206896552 ], [ 29.80045493670886, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25702, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.464791206896552 ], [ 29.800724430379748, -1.465060689655173 ], [ 29.800993924050633, -1.465060689655173 ], [ 29.800993924050633, -1.464791206896552 ], [ 29.800724430379748, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25703, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754371518987341, -1.465060689655173 ], [ 29.754371518987341, -1.465330172413793 ], [ 29.754641012658226, -1.465330172413793 ], [ 29.754641012658226, -1.465060689655173 ], [ 29.754371518987341, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25704, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.465060689655173 ], [ 29.754641012658226, -1.465330172413793 ], [ 29.754910506329114, -1.465330172413793 ], [ 29.754910506329114, -1.465060689655173 ], [ 29.754641012658226, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25705, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.465060689655173 ], [ 29.754910506329114, -1.465330172413793 ], [ 29.75518, -1.465330172413793 ], [ 29.75518, -1.465060689655173 ], [ 29.754910506329114, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25706, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.465060689655173 ], [ 29.75518, -1.465330172413793 ], [ 29.755449493670884, -1.465330172413793 ], [ 29.755449493670884, -1.465060689655173 ], [ 29.75518, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.465060689655173 ], [ 29.755449493670884, -1.465330172413793 ], [ 29.755718987341773, -1.465330172413793 ], [ 29.755718987341773, -1.465060689655173 ], [ 29.755449493670884, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25708, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.465060689655173 ], [ 29.755718987341773, -1.465330172413793 ], [ 29.755988481012658, -1.465330172413793 ], [ 29.755988481012658, -1.465060689655173 ], [ 29.755718987341773, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25709, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.465060689655173 ], [ 29.755988481012658, -1.465330172413793 ], [ 29.756257974683542, -1.465330172413793 ], [ 29.756257974683542, -1.465060689655173 ], [ 29.755988481012658, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25710, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.465060689655173 ], [ 29.756257974683542, -1.465330172413793 ], [ 29.756796962025316, -1.465330172413793 ], [ 29.756796962025316, -1.465060689655173 ], [ 29.756257974683542, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25711, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.465060689655173 ], [ 29.756796962025316, -1.465330172413793 ], [ 29.757066455696201, -1.465330172413793 ], [ 29.757066455696201, -1.465060689655173 ], [ 29.756796962025316, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25712, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.465060689655173 ], [ 29.757066455696201, -1.465330172413793 ], [ 29.757335949367089, -1.465330172413793 ], [ 29.757335949367089, -1.465060689655173 ], [ 29.757066455696201, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25713, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.465060689655173 ], [ 29.757335949367089, -1.465330172413793 ], [ 29.757605443037974, -1.465330172413793 ], [ 29.757605443037974, -1.465060689655173 ], [ 29.757335949367089, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25714, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.465060689655173 ], [ 29.757605443037974, -1.465330172413793 ], [ 29.757874936708859, -1.465330172413793 ], [ 29.757874936708859, -1.465060689655173 ], [ 29.757605443037974, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25715, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.465060689655173 ], [ 29.758144430379748, -1.465330172413793 ], [ 29.758413924050632, -1.465330172413793 ], [ 29.758413924050632, -1.465060689655173 ], [ 29.758144430379748, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25716, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.465060689655173 ], [ 29.758413924050632, -1.465330172413793 ], [ 29.758683417721517, -1.465330172413793 ], [ 29.758683417721517, -1.465060689655173 ], [ 29.758413924050632, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25717, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.465060689655173 ], [ 29.758683417721517, -1.465330172413793 ], [ 29.758952911392406, -1.465330172413793 ], [ 29.758952911392406, -1.465060689655173 ], [ 29.758683417721517, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25718, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.465060689655173 ], [ 29.759222405063291, -1.465330172413793 ], [ 29.759491898734176, -1.465330172413793 ], [ 29.759491898734176, -1.465060689655173 ], [ 29.759222405063291, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25719, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.465060689655173 ], [ 29.759491898734176, -1.465330172413793 ], [ 29.759761392405064, -1.465330172413793 ], [ 29.759761392405064, -1.465060689655173 ], [ 29.759491898734176, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25720, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.465060689655173 ], [ 29.759761392405064, -1.465330172413793 ], [ 29.760030886075949, -1.465330172413793 ], [ 29.760030886075949, -1.465060689655173 ], [ 29.759761392405064, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25721, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.465060689655173 ], [ 29.760030886075949, -1.465330172413793 ], [ 29.760300379746834, -1.465330172413793 ], [ 29.760300379746834, -1.465060689655173 ], [ 29.760030886075949, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25722, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.465060689655173 ], [ 29.760569873417722, -1.465330172413793 ], [ 29.760839367088607, -1.465330172413793 ], [ 29.760839367088607, -1.465060689655173 ], [ 29.760569873417722, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25723, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.465060689655173 ], [ 29.760839367088607, -1.465330172413793 ], [ 29.761108860759492, -1.465330172413793 ], [ 29.761108860759492, -1.465060689655173 ], [ 29.760839367088607, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25724, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.465060689655173 ], [ 29.764342784810125, -1.465330172413793 ], [ 29.764612278481014, -1.465330172413793 ], [ 29.764612278481014, -1.465060689655173 ], [ 29.764342784810125, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25725, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.465060689655173 ], [ 29.764612278481014, -1.465330172413793 ], [ 29.764881772151899, -1.465330172413793 ], [ 29.764881772151899, -1.465060689655173 ], [ 29.764612278481014, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25726, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.465060689655173 ], [ 29.765151265822784, -1.465330172413793 ], [ 29.765420759493672, -1.465330172413793 ], [ 29.765420759493672, -1.465060689655173 ], [ 29.765151265822784, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25727, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.465060689655173 ], [ 29.765420759493672, -1.465330172413793 ], [ 29.765959746835442, -1.465330172413793 ], [ 29.765959746835442, -1.465060689655173 ], [ 29.765420759493672, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25728, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.465060689655173 ], [ 29.765959746835442, -1.465330172413793 ], [ 29.766229240506327, -1.465330172413793 ], [ 29.766229240506327, -1.465060689655173 ], [ 29.765959746835442, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25729, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.465060689655173 ], [ 29.770541139240507, -1.465330172413793 ], [ 29.770810632911392, -1.465330172413793 ], [ 29.770810632911392, -1.465060689655173 ], [ 29.770541139240507, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25730, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.465060689655173 ], [ 29.770810632911392, -1.465330172413793 ], [ 29.771080126582277, -1.465330172413793 ], [ 29.771080126582277, -1.465060689655173 ], [ 29.770810632911392, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25731, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.465060689655173 ], [ 29.771080126582277, -1.465330172413793 ], [ 29.771349620253165, -1.465330172413793 ], [ 29.771349620253165, -1.465060689655173 ], [ 29.771080126582277, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25732, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.465060689655173 ], [ 29.771349620253165, -1.465330172413793 ], [ 29.77161911392405, -1.465330172413793 ], [ 29.77161911392405, -1.465060689655173 ], [ 29.771349620253165, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25733, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.465060689655173 ], [ 29.77161911392405, -1.465330172413793 ], [ 29.771888607594935, -1.465330172413793 ], [ 29.771888607594935, -1.465060689655173 ], [ 29.77161911392405, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25734, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.465060689655173 ], [ 29.771888607594935, -1.465330172413793 ], [ 29.772158101265823, -1.465330172413793 ], [ 29.772158101265823, -1.465060689655173 ], [ 29.771888607594935, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25735, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.465060689655173 ], [ 29.772158101265823, -1.465330172413793 ], [ 29.772427594936708, -1.465330172413793 ], [ 29.772427594936708, -1.465060689655173 ], [ 29.772158101265823, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25736, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.465060689655173 ], [ 29.772427594936708, -1.465330172413793 ], [ 29.772697088607593, -1.465330172413793 ], [ 29.772697088607593, -1.465060689655173 ], [ 29.772427594936708, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25737, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.465060689655173 ], [ 29.772966582278482, -1.465330172413793 ], [ 29.773236075949367, -1.465330172413793 ], [ 29.773236075949367, -1.465060689655173 ], [ 29.772966582278482, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25738, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.465060689655173 ], [ 29.773236075949367, -1.465330172413793 ], [ 29.773505569620252, -1.465330172413793 ], [ 29.773505569620252, -1.465060689655173 ], [ 29.773236075949367, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25739, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.465060689655173 ], [ 29.774044556962025, -1.465330172413793 ], [ 29.77431405063291, -1.465330172413793 ], [ 29.77431405063291, -1.465060689655173 ], [ 29.774044556962025, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25740, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.465060689655173 ], [ 29.779703924050633, -1.465330172413793 ], [ 29.779973417721518, -1.465330172413793 ], [ 29.779973417721518, -1.465060689655173 ], [ 29.779703924050633, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25741, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.465060689655173 ], [ 29.779973417721518, -1.465330172413793 ], [ 29.780242911392406, -1.465330172413793 ], [ 29.780242911392406, -1.465060689655173 ], [ 29.779973417721518, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25742, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.465060689655173 ], [ 29.780242911392406, -1.465330172413793 ], [ 29.780512405063291, -1.465330172413793 ], [ 29.780512405063291, -1.465060689655173 ], [ 29.780242911392406, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25743, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.465060689655173 ], [ 29.780781898734176, -1.465330172413793 ], [ 29.781051392405065, -1.465330172413793 ], [ 29.781051392405065, -1.465060689655173 ], [ 29.780781898734176, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25744, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.465060689655173 ], [ 29.781051392405065, -1.465330172413793 ], [ 29.78132088607595, -1.465330172413793 ], [ 29.78132088607595, -1.465060689655173 ], [ 29.781051392405065, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25745, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.465060689655173 ], [ 29.78132088607595, -1.465330172413793 ], [ 29.781590379746834, -1.465330172413793 ], [ 29.781590379746834, -1.465060689655173 ], [ 29.78132088607595, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25746, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.465060689655173 ], [ 29.781590379746834, -1.465330172413793 ], [ 29.781859873417723, -1.465330172413793 ], [ 29.781859873417723, -1.465060689655173 ], [ 29.781590379746834, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25747, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.465060689655173 ], [ 29.781859873417723, -1.465330172413793 ], [ 29.782129367088608, -1.465330172413793 ], [ 29.782129367088608, -1.465060689655173 ], [ 29.781859873417723, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25748, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.465060689655173 ], [ 29.782129367088608, -1.465330172413793 ], [ 29.782398860759493, -1.465330172413793 ], [ 29.782398860759493, -1.465060689655173 ], [ 29.782129367088608, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25749, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.465060689655173 ], [ 29.782398860759493, -1.465330172413793 ], [ 29.782668354430381, -1.465330172413793 ], [ 29.782668354430381, -1.465060689655173 ], [ 29.782398860759493, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25750, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.465060689655173 ], [ 29.782668354430381, -1.465330172413793 ], [ 29.782937848101266, -1.465330172413793 ], [ 29.782937848101266, -1.465060689655173 ], [ 29.782668354430381, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25751, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.465060689655173 ], [ 29.782937848101266, -1.465330172413793 ], [ 29.783207341772151, -1.465330172413793 ], [ 29.783207341772151, -1.465060689655173 ], [ 29.782937848101266, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25752, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.465060689655173 ], [ 29.783207341772151, -1.465330172413793 ], [ 29.783476835443039, -1.465330172413793 ], [ 29.783476835443039, -1.465060689655173 ], [ 29.783207341772151, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25753, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.465060689655173 ], [ 29.783476835443039, -1.465330172413793 ], [ 29.784015822784809, -1.465330172413793 ], [ 29.784015822784809, -1.465060689655173 ], [ 29.783476835443039, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25754, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.465060689655173 ], [ 29.784015822784809, -1.465330172413793 ], [ 29.784285316455698, -1.465330172413793 ], [ 29.784285316455698, -1.465060689655173 ], [ 29.784015822784809, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25755, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.465060689655173 ], [ 29.784285316455698, -1.465330172413793 ], [ 29.784554810126583, -1.465330172413793 ], [ 29.784554810126583, -1.465060689655173 ], [ 29.784285316455698, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25756, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.465060689655173 ], [ 29.784554810126583, -1.465330172413793 ], [ 29.784824303797468, -1.465330172413793 ], [ 29.784824303797468, -1.465060689655173 ], [ 29.784554810126583, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25757, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.465060689655173 ], [ 29.784824303797468, -1.465330172413793 ], [ 29.785093797468356, -1.465330172413793 ], [ 29.785093797468356, -1.465060689655173 ], [ 29.784824303797468, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25758, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.465060689655173 ], [ 29.785093797468356, -1.465330172413793 ], [ 29.785363291139241, -1.465330172413793 ], [ 29.785363291139241, -1.465060689655173 ], [ 29.785093797468356, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25759, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.465060689655173 ], [ 29.785363291139241, -1.465330172413793 ], [ 29.785632784810126, -1.465330172413793 ], [ 29.785632784810126, -1.465060689655173 ], [ 29.785363291139241, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25760, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.465060689655173 ], [ 29.785632784810126, -1.465330172413793 ], [ 29.785902278481014, -1.465330172413793 ], [ 29.785902278481014, -1.465060689655173 ], [ 29.785632784810126, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25761, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.465060689655173 ], [ 29.785902278481014, -1.465330172413793 ], [ 29.786171772151899, -1.465330172413793 ], [ 29.786171772151899, -1.465060689655173 ], [ 29.785902278481014, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25762, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.465060689655173 ], [ 29.786171772151899, -1.465330172413793 ], [ 29.786441265822784, -1.465330172413793 ], [ 29.786441265822784, -1.465060689655173 ], [ 29.786171772151899, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25763, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.465060689655173 ], [ 29.786441265822784, -1.465330172413793 ], [ 29.786710759493673, -1.465330172413793 ], [ 29.786710759493673, -1.465060689655173 ], [ 29.786441265822784, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25764, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.465060689655173 ], [ 29.798029493670885, -1.465330172413793 ], [ 29.798298987341774, -1.465330172413793 ], [ 29.798298987341774, -1.465060689655173 ], [ 29.798029493670885, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25765, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.465060689655173 ], [ 29.798298987341774, -1.465330172413793 ], [ 29.798568481012659, -1.465330172413793 ], [ 29.798568481012659, -1.465060689655173 ], [ 29.798298987341774, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25766, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.465060689655173 ], [ 29.798568481012659, -1.465330172413793 ], [ 29.798837974683543, -1.465330172413793 ], [ 29.798837974683543, -1.465060689655173 ], [ 29.798568481012659, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25767, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.465060689655173 ], [ 29.798837974683543, -1.465330172413793 ], [ 29.799107468354432, -1.465330172413793 ], [ 29.799107468354432, -1.465060689655173 ], [ 29.798837974683543, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25768, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.465060689655173 ], [ 29.799107468354432, -1.465330172413793 ], [ 29.799376962025317, -1.465330172413793 ], [ 29.799376962025317, -1.465060689655173 ], [ 29.799107468354432, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25769, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.465060689655173 ], [ 29.799376962025317, -1.465330172413793 ], [ 29.799646455696202, -1.465330172413793 ], [ 29.799646455696202, -1.465060689655173 ], [ 29.799376962025317, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25770, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.465060689655173 ], [ 29.799646455696202, -1.465330172413793 ], [ 29.79991594936709, -1.465330172413793 ], [ 29.79991594936709, -1.465060689655173 ], [ 29.799646455696202, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25771, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.465060689655173 ], [ 29.79991594936709, -1.465330172413793 ], [ 29.800185443037975, -1.465330172413793 ], [ 29.800185443037975, -1.465060689655173 ], [ 29.79991594936709, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25772, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.465060689655173 ], [ 29.800185443037975, -1.465330172413793 ], [ 29.80045493670886, -1.465330172413793 ], [ 29.80045493670886, -1.465060689655173 ], [ 29.800185443037975, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25773, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.465060689655173 ], [ 29.80045493670886, -1.465330172413793 ], [ 29.800724430379748, -1.465330172413793 ], [ 29.800724430379748, -1.465060689655173 ], [ 29.80045493670886, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25774, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.465060689655173 ], [ 29.800724430379748, -1.465330172413793 ], [ 29.800993924050633, -1.465330172413793 ], [ 29.800993924050633, -1.465060689655173 ], [ 29.800724430379748, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25775, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.465060689655173 ], [ 29.800993924050633, -1.465330172413793 ], [ 29.801263417721518, -1.465330172413793 ], [ 29.801263417721518, -1.465060689655173 ], [ 29.800993924050633, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25776, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.465060689655173 ], [ 29.801263417721518, -1.465330172413793 ], [ 29.801532911392407, -1.465330172413793 ], [ 29.801532911392407, -1.465060689655173 ], [ 29.801263417721518, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25777, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.463713275862069 ], [ 29.769732658227849, -1.465599655172414 ], [ 29.770002151898733, -1.465599655172414 ], [ 29.770002151898733, -1.463713275862069 ], [ 29.769732658227849, -1.463713275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25778, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.46398275862069 ], [ 29.7667682278481, -1.465599655172414 ], [ 29.767037721518985, -1.465599655172414 ], [ 29.767037721518985, -1.46398275862069 ], [ 29.7667682278481, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25779, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.46398275862069 ], [ 29.796682025316457, -1.466677586206897 ], [ 29.796951518987342, -1.466677586206897 ], [ 29.796951518987342, -1.46398275862069 ], [ 29.796682025316457, -1.46398275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25780, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.46425224137931 ], [ 29.788597215189874, -1.465599655172414 ], [ 29.788866708860759, -1.465599655172414 ], [ 29.788866708860759, -1.46425224137931 ], [ 29.788597215189874, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25781, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.46425224137931 ], [ 29.795334556962025, -1.467216551724138 ], [ 29.79560405063291, -1.467216551724138 ], [ 29.79560405063291, -1.46425224137931 ], [ 29.795334556962025, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25782, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.46425224137931 ], [ 29.79560405063291, -1.467216551724138 ], [ 29.795873544303799, -1.467216551724138 ], [ 29.795873544303799, -1.46425224137931 ], [ 29.79560405063291, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25783, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.46425224137931 ], [ 29.796143037974684, -1.466947068965517 ], [ 29.796412531645569, -1.466947068965517 ], [ 29.796412531645569, -1.46425224137931 ], [ 29.796143037974684, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25784, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.46425224137931 ], [ 29.796412531645569, -1.466947068965517 ], [ 29.796682025316457, -1.466947068965517 ], [ 29.796682025316457, -1.46425224137931 ], [ 29.796412531645569, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25785, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.46425224137931 ], [ 29.796951518987342, -1.466677586206897 ], [ 29.797221012658227, -1.466677586206897 ], [ 29.797221012658227, -1.46425224137931 ], [ 29.796951518987342, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25786, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.46425224137931 ], [ 29.797490506329115, -1.466677586206897 ], [ 29.79776, -1.466677586206897 ], [ 29.79776, -1.465330172413793 ], [ 29.798029493670885, -1.465330172413793 ], [ 29.798029493670885, -1.465060689655173 ], [ 29.79776, -1.465060689655173 ], [ 29.79776, -1.46425224137931 ], [ 29.797490506329115, -1.46425224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25787, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.464521724137931 ], [ 29.788058227848101, -1.465599655172414 ], [ 29.788327721518986, -1.465599655172414 ], [ 29.788327721518986, -1.464521724137931 ], [ 29.788058227848101, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25788, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.464521724137931 ], [ 29.788327721518986, -1.465599655172414 ], [ 29.788597215189874, -1.465599655172414 ], [ 29.788597215189874, -1.464521724137931 ], [ 29.788327721518986, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25789, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.464521724137931 ], [ 29.794795569620252, -1.467486034482759 ], [ 29.79506506329114, -1.467486034482759 ], [ 29.79506506329114, -1.464521724137931 ], [ 29.794795569620252, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25790, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.464521724137931 ], [ 29.79506506329114, -1.467486034482759 ], [ 29.795334556962025, -1.467486034482759 ], [ 29.795334556962025, -1.464521724137931 ], [ 29.79506506329114, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25791, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.464521724137931 ], [ 29.795873544303799, -1.467216551724138 ], [ 29.796143037974684, -1.467216551724138 ], [ 29.796143037974684, -1.464521724137931 ], [ 29.795873544303799, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25792, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.464521724137931 ], [ 29.797221012658227, -1.466947068965517 ], [ 29.797490506329115, -1.466947068965517 ], [ 29.797490506329115, -1.464521724137931 ], [ 29.797221012658227, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25793, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.464791206896552 ], [ 29.777278481012658, -1.465599655172414 ], [ 29.777547974683543, -1.465599655172414 ], [ 29.777547974683543, -1.464791206896552 ], [ 29.777278481012658, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25794, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.464791206896552 ], [ 29.77862594936709, -1.465599655172414 ], [ 29.778895443037975, -1.465599655172414 ], [ 29.778895443037975, -1.464791206896552 ], [ 29.77862594936709, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25795, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.464791206896552 ], [ 29.787249746835442, -1.465599655172414 ], [ 29.787519240506327, -1.465599655172414 ], [ 29.787519240506327, -1.464791206896552 ], [ 29.787249746835442, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25796, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.464791206896552 ], [ 29.787519240506327, -1.465869137931035 ], [ 29.787788734177216, -1.465869137931035 ], [ 29.787788734177216, -1.464791206896552 ], [ 29.787519240506327, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25797, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.464791206896552 ], [ 29.787788734177216, -1.465869137931035 ], [ 29.788058227848101, -1.465869137931035 ], [ 29.788058227848101, -1.464791206896552 ], [ 29.787788734177216, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25798, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.465060689655173 ], [ 29.757874936708859, -1.465599655172414 ], [ 29.758144430379748, -1.465599655172414 ], [ 29.758144430379748, -1.465060689655173 ], [ 29.757874936708859, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25799, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.465060689655173 ], [ 29.760300379746834, -1.465599655172414 ], [ 29.760569873417722, -1.465599655172414 ], [ 29.760569873417722, -1.465060689655173 ], [ 29.760300379746834, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25800, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.465060689655173 ], [ 29.761917341772151, -1.467216551724138 ], [ 29.762186835443039, -1.467216551724138 ], [ 29.762186835443039, -1.465060689655173 ], [ 29.761917341772151, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25801, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.465060689655173 ], [ 29.762456329113924, -1.467216551724138 ], [ 29.762725822784809, -1.467216551724138 ], [ 29.762725822784809, -1.465060689655173 ], [ 29.762456329113924, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25802, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.465060689655173 ], [ 29.762725822784809, -1.467216551724138 ], [ 29.762995316455697, -1.467216551724138 ], [ 29.762995316455697, -1.465060689655173 ], [ 29.762725822784809, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25803, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.465060689655173 ], [ 29.763264810126582, -1.467216551724138 ], [ 29.763534303797467, -1.467216551724138 ], [ 29.763534303797467, -1.465060689655173 ], [ 29.763264810126582, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25804, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.465060689655173 ], [ 29.763534303797467, -1.465599655172414 ], [ 29.763803797468356, -1.465599655172414 ], [ 29.763803797468356, -1.465060689655173 ], [ 29.763534303797467, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25805, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.465060689655173 ], [ 29.76407329113924, -1.467216551724138 ], [ 29.764342784810125, -1.467216551724138 ], [ 29.764342784810125, -1.465060689655173 ], [ 29.76407329113924, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25806, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.465060689655173 ], [ 29.764881772151899, -1.465599655172414 ], [ 29.765151265822784, -1.465599655172414 ], [ 29.765151265822784, -1.465060689655173 ], [ 29.764881772151899, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25807, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.465060689655173 ], [ 29.772697088607593, -1.465599655172414 ], [ 29.772966582278482, -1.465599655172414 ], [ 29.772966582278482, -1.465060689655173 ], [ 29.772697088607593, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25808, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.465060689655173 ], [ 29.773505569620252, -1.465599655172414 ], [ 29.77377506329114, -1.465599655172414 ], [ 29.77377506329114, -1.465060689655173 ], [ 29.773505569620252, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25809, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.465060689655173 ], [ 29.77431405063291, -1.465599655172414 ], [ 29.774853037974683, -1.465599655172414 ], [ 29.774853037974683, -1.465060689655173 ], [ 29.77431405063291, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25810, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.465060689655173 ], [ 29.775122531645568, -1.465599655172414 ], [ 29.775392025316457, -1.465599655172414 ], [ 29.775392025316457, -1.465060689655173 ], [ 29.775122531645568, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25811, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.465060689655173 ], [ 29.775392025316457, -1.466947068965517 ], [ 29.775661518987341, -1.466947068965517 ], [ 29.775661518987341, -1.465060689655173 ], [ 29.775392025316457, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25812, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.465060689655173 ], [ 29.775931012658226, -1.465599655172414 ], [ 29.776200506329115, -1.465599655172414 ], [ 29.776200506329115, -1.465060689655173 ], [ 29.775931012658226, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25813, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.465060689655173 ], [ 29.776200506329115, -1.466947068965517 ], [ 29.77647, -1.466947068965517 ], [ 29.77647, -1.465060689655173 ], [ 29.776200506329115, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25814, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.465060689655173 ], [ 29.776739493670885, -1.466677586206897 ], [ 29.777008987341773, -1.466677586206897 ], [ 29.777008987341773, -1.465060689655173 ], [ 29.776739493670885, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25815, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.465060689655173 ], [ 29.777547974683543, -1.466408103448276 ], [ 29.777817468354431, -1.466408103448276 ], [ 29.777817468354431, -1.465060689655173 ], [ 29.777547974683543, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25816, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.465060689655173 ], [ 29.778086962025316, -1.466408103448276 ], [ 29.778356455696201, -1.466408103448276 ], [ 29.778356455696201, -1.465060689655173 ], [ 29.778086962025316, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25817, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.465060689655173 ], [ 29.778356455696201, -1.466408103448276 ], [ 29.77862594936709, -1.466408103448276 ], [ 29.77862594936709, -1.465060689655173 ], [ 29.778356455696201, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25818, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.465060689655173 ], [ 29.778895443037975, -1.466138620689655 ], [ 29.77916493670886, -1.466138620689655 ], [ 29.77916493670886, -1.465330172413793 ], [ 29.779434430379748, -1.465330172413793 ], [ 29.779434430379748, -1.465060689655173 ], [ 29.778895443037975, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25819, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.465060689655173 ], [ 29.779434430379748, -1.465599655172414 ], [ 29.779703924050633, -1.465599655172414 ], [ 29.779703924050633, -1.465060689655173 ], [ 29.779434430379748, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25820, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.465060689655173 ], [ 29.786710759493673, -1.465869137931035 ], [ 29.786980253164558, -1.465869137931035 ], [ 29.786980253164558, -1.465060689655173 ], [ 29.786710759493673, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25821, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.465060689655173 ], [ 29.786980253164558, -1.465869137931035 ], [ 29.787249746835442, -1.465869137931035 ], [ 29.787249746835442, -1.465060689655173 ], [ 29.786980253164558, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25822, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754641012658226, -1.465330172413793 ], [ 29.754641012658226, -1.465599655172414 ], [ 29.754910506329114, -1.465599655172414 ], [ 29.754910506329114, -1.465330172413793 ], [ 29.754641012658226, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25823, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.465330172413793 ], [ 29.754910506329114, -1.465599655172414 ], [ 29.75518, -1.465599655172414 ], [ 29.75518, -1.465330172413793 ], [ 29.754910506329114, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25824, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.465330172413793 ], [ 29.75518, -1.465599655172414 ], [ 29.755449493670884, -1.465599655172414 ], [ 29.755449493670884, -1.465330172413793 ], [ 29.75518, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25825, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.465330172413793 ], [ 29.755449493670884, -1.465599655172414 ], [ 29.755718987341773, -1.465599655172414 ], [ 29.755718987341773, -1.465330172413793 ], [ 29.755449493670884, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25826, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.465330172413793 ], [ 29.755718987341773, -1.465599655172414 ], [ 29.755988481012658, -1.465599655172414 ], [ 29.755988481012658, -1.465330172413793 ], [ 29.755718987341773, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25827, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.465330172413793 ], [ 29.755988481012658, -1.465599655172414 ], [ 29.756257974683542, -1.465599655172414 ], [ 29.756257974683542, -1.465330172413793 ], [ 29.755988481012658, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25828, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.465330172413793 ], [ 29.756257974683542, -1.465599655172414 ], [ 29.756796962025316, -1.465599655172414 ], [ 29.756796962025316, -1.465330172413793 ], [ 29.756257974683542, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25829, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.465330172413793 ], [ 29.756796962025316, -1.465869137931035 ], [ 29.757066455696201, -1.465869137931035 ], [ 29.757066455696201, -1.465330172413793 ], [ 29.756796962025316, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25830, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.465330172413793 ], [ 29.757066455696201, -1.465599655172414 ], [ 29.757335949367089, -1.465599655172414 ], [ 29.757335949367089, -1.465330172413793 ], [ 29.757066455696201, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25831, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.465330172413793 ], [ 29.757335949367089, -1.465599655172414 ], [ 29.757605443037974, -1.465599655172414 ], [ 29.757605443037974, -1.465330172413793 ], [ 29.757335949367089, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25832, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.465330172413793 ], [ 29.757605443037974, -1.465599655172414 ], [ 29.757874936708859, -1.465599655172414 ], [ 29.757874936708859, -1.465330172413793 ], [ 29.757605443037974, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25833, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.465330172413793 ], [ 29.758144430379748, -1.465599655172414 ], [ 29.758413924050632, -1.465599655172414 ], [ 29.758413924050632, -1.465330172413793 ], [ 29.758144430379748, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25834, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.465330172413793 ], [ 29.758413924050632, -1.465599655172414 ], [ 29.758683417721517, -1.465599655172414 ], [ 29.758683417721517, -1.465330172413793 ], [ 29.758413924050632, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25835, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.465330172413793 ], [ 29.758683417721517, -1.465599655172414 ], [ 29.758952911392406, -1.465599655172414 ], [ 29.758952911392406, -1.465330172413793 ], [ 29.758683417721517, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25836, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.465330172413793 ], [ 29.758952911392406, -1.465599655172414 ], [ 29.759222405063291, -1.465599655172414 ], [ 29.759222405063291, -1.465330172413793 ], [ 29.758952911392406, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25837, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.465330172413793 ], [ 29.759222405063291, -1.465599655172414 ], [ 29.759491898734176, -1.465599655172414 ], [ 29.759491898734176, -1.465330172413793 ], [ 29.759222405063291, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25838, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.465330172413793 ], [ 29.759491898734176, -1.465599655172414 ], [ 29.759761392405064, -1.465599655172414 ], [ 29.759761392405064, -1.465330172413793 ], [ 29.759491898734176, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25839, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.465330172413793 ], [ 29.759761392405064, -1.465599655172414 ], [ 29.760030886075949, -1.465599655172414 ], [ 29.760030886075949, -1.465330172413793 ], [ 29.759761392405064, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25840, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.465330172413793 ], [ 29.760030886075949, -1.465599655172414 ], [ 29.760300379746834, -1.465599655172414 ], [ 29.760300379746834, -1.465330172413793 ], [ 29.760030886075949, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25841, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.465330172413793 ], [ 29.760569873417722, -1.465599655172414 ], [ 29.760839367088607, -1.465599655172414 ], [ 29.760839367088607, -1.465330172413793 ], [ 29.760569873417722, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25842, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.465330172413793 ], [ 29.760839367088607, -1.465599655172414 ], [ 29.761108860759492, -1.465599655172414 ], [ 29.761108860759492, -1.465330172413793 ], [ 29.760839367088607, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25843, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.465330172413793 ], [ 29.761647848101266, -1.466947068965517 ], [ 29.761917341772151, -1.466947068965517 ], [ 29.761917341772151, -1.465330172413793 ], [ 29.761647848101266, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25844, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.465330172413793 ], [ 29.762186835443039, -1.466947068965517 ], [ 29.762456329113924, -1.466947068965517 ], [ 29.762456329113924, -1.465330172413793 ], [ 29.762186835443039, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25845, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.465330172413793 ], [ 29.762995316455697, -1.466947068965517 ], [ 29.763264810126582, -1.466947068965517 ], [ 29.763264810126582, -1.465330172413793 ], [ 29.762995316455697, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25846, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.465330172413793 ], [ 29.763803797468356, -1.466947068965517 ], [ 29.76407329113924, -1.466947068965517 ], [ 29.76407329113924, -1.465330172413793 ], [ 29.763803797468356, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25847, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.465330172413793 ], [ 29.764342784810125, -1.466947068965517 ], [ 29.764612278481014, -1.466947068965517 ], [ 29.764612278481014, -1.465330172413793 ], [ 29.764342784810125, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25848, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.465330172413793 ], [ 29.764612278481014, -1.466947068965517 ], [ 29.764881772151899, -1.466947068965517 ], [ 29.764881772151899, -1.465330172413793 ], [ 29.764612278481014, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25849, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.465330172413793 ], [ 29.765151265822784, -1.466947068965517 ], [ 29.765420759493672, -1.466947068965517 ], [ 29.765420759493672, -1.465330172413793 ], [ 29.765151265822784, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25850, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.465330172413793 ], [ 29.765420759493672, -1.466947068965517 ], [ 29.765959746835442, -1.466947068965517 ], [ 29.765959746835442, -1.465330172413793 ], [ 29.765420759493672, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25851, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.465330172413793 ], [ 29.765959746835442, -1.465599655172414 ], [ 29.766229240506327, -1.465599655172414 ], [ 29.766229240506327, -1.465330172413793 ], [ 29.765959746835442, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25852, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.465330172413793 ], [ 29.766229240506327, -1.465599655172414 ], [ 29.766498734177215, -1.465599655172414 ], [ 29.766498734177215, -1.465330172413793 ], [ 29.766229240506327, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25853, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.465330172413793 ], [ 29.766498734177215, -1.465599655172414 ], [ 29.7667682278481, -1.465599655172414 ], [ 29.7667682278481, -1.465330172413793 ], [ 29.766498734177215, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25854, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.465330172413793 ], [ 29.770002151898733, -1.465599655172414 ], [ 29.770271645569618, -1.465599655172414 ], [ 29.770271645569618, -1.465330172413793 ], [ 29.770002151898733, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25855, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.465330172413793 ], [ 29.770271645569618, -1.465599655172414 ], [ 29.770541139240507, -1.465599655172414 ], [ 29.770541139240507, -1.465330172413793 ], [ 29.770271645569618, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25856, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.465330172413793 ], [ 29.770541139240507, -1.465599655172414 ], [ 29.770810632911392, -1.465599655172414 ], [ 29.770810632911392, -1.465330172413793 ], [ 29.770541139240507, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25857, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.465330172413793 ], [ 29.770810632911392, -1.465599655172414 ], [ 29.771080126582277, -1.465599655172414 ], [ 29.771080126582277, -1.465330172413793 ], [ 29.770810632911392, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25858, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.465330172413793 ], [ 29.771080126582277, -1.465599655172414 ], [ 29.771349620253165, -1.465599655172414 ], [ 29.771349620253165, -1.465330172413793 ], [ 29.771080126582277, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25859, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.465330172413793 ], [ 29.771349620253165, -1.465599655172414 ], [ 29.77161911392405, -1.465599655172414 ], [ 29.77161911392405, -1.465330172413793 ], [ 29.771349620253165, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25860, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.465330172413793 ], [ 29.77161911392405, -1.465869137931035 ], [ 29.771888607594935, -1.465869137931035 ], [ 29.771888607594935, -1.465330172413793 ], [ 29.77161911392405, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25861, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.465330172413793 ], [ 29.771888607594935, -1.465599655172414 ], [ 29.772158101265823, -1.465599655172414 ], [ 29.772158101265823, -1.465330172413793 ], [ 29.771888607594935, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25862, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.465330172413793 ], [ 29.772158101265823, -1.465599655172414 ], [ 29.772427594936708, -1.465599655172414 ], [ 29.772427594936708, -1.465330172413793 ], [ 29.772158101265823, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25863, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.465330172413793 ], [ 29.772427594936708, -1.467486034482759 ], [ 29.772697088607593, -1.467486034482759 ], [ 29.772697088607593, -1.465330172413793 ], [ 29.772427594936708, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25864, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.465330172413793 ], [ 29.772966582278482, -1.465869137931035 ], [ 29.773236075949367, -1.465869137931035 ], [ 29.773236075949367, -1.465330172413793 ], [ 29.772966582278482, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25865, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.465330172413793 ], [ 29.773236075949367, -1.467486034482759 ], [ 29.773505569620252, -1.467486034482759 ], [ 29.773505569620252, -1.465330172413793 ], [ 29.773236075949367, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25866, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.465330172413793 ], [ 29.77377506329114, -1.467216551724138 ], [ 29.774044556962025, -1.467216551724138 ], [ 29.774044556962025, -1.465330172413793 ], [ 29.77377506329114, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25867, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.465330172413793 ], [ 29.774044556962025, -1.467486034482759 ], [ 29.77431405063291, -1.467486034482759 ], [ 29.77431405063291, -1.465330172413793 ], [ 29.774044556962025, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25868, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.465330172413793 ], [ 29.774853037974683, -1.467216551724138 ], [ 29.775122531645568, -1.467216551724138 ], [ 29.775122531645568, -1.465330172413793 ], [ 29.774853037974683, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25869, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.465330172413793 ], [ 29.775661518987341, -1.466947068965517 ], [ 29.775931012658226, -1.466947068965517 ], [ 29.775931012658226, -1.465330172413793 ], [ 29.775661518987341, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25870, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.465330172413793 ], [ 29.77647, -1.466947068965517 ], [ 29.776739493670885, -1.466947068965517 ], [ 29.776739493670885, -1.465330172413793 ], [ 29.77647, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25871, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.465330172413793 ], [ 29.777008987341773, -1.466677586206897 ], [ 29.777278481012658, -1.466677586206897 ], [ 29.777278481012658, -1.465330172413793 ], [ 29.777008987341773, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25872, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.465330172413793 ], [ 29.777817468354431, -1.466408103448276 ], [ 29.778086962025316, -1.466408103448276 ], [ 29.778086962025316, -1.465330172413793 ], [ 29.777817468354431, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25873, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.465330172413793 ], [ 29.77916493670886, -1.465599655172414 ], [ 29.779434430379748, -1.465599655172414 ], [ 29.779434430379748, -1.465330172413793 ], [ 29.77916493670886, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25874, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.465330172413793 ], [ 29.779703924050633, -1.465599655172414 ], [ 29.779973417721518, -1.465599655172414 ], [ 29.779973417721518, -1.465330172413793 ], [ 29.779703924050633, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25875, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.465330172413793 ], [ 29.779973417721518, -1.465599655172414 ], [ 29.780242911392406, -1.465599655172414 ], [ 29.780242911392406, -1.465330172413793 ], [ 29.779973417721518, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25876, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.465330172413793 ], [ 29.780242911392406, -1.465599655172414 ], [ 29.780512405063291, -1.465599655172414 ], [ 29.780512405063291, -1.465330172413793 ], [ 29.780242911392406, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25877, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.465330172413793 ], [ 29.780512405063291, -1.465599655172414 ], [ 29.780781898734176, -1.465599655172414 ], [ 29.780781898734176, -1.465330172413793 ], [ 29.780512405063291, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25878, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.465330172413793 ], [ 29.780781898734176, -1.465599655172414 ], [ 29.781051392405065, -1.465599655172414 ], [ 29.781051392405065, -1.465330172413793 ], [ 29.780781898734176, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25879, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.465330172413793 ], [ 29.781051392405065, -1.465599655172414 ], [ 29.78132088607595, -1.465599655172414 ], [ 29.78132088607595, -1.465330172413793 ], [ 29.781051392405065, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25880, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.465330172413793 ], [ 29.78132088607595, -1.465599655172414 ], [ 29.781590379746834, -1.465599655172414 ], [ 29.781590379746834, -1.465330172413793 ], [ 29.78132088607595, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25881, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.465330172413793 ], [ 29.781590379746834, -1.465599655172414 ], [ 29.781859873417723, -1.465599655172414 ], [ 29.781859873417723, -1.465330172413793 ], [ 29.781590379746834, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25882, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.465330172413793 ], [ 29.781859873417723, -1.465599655172414 ], [ 29.782129367088608, -1.465599655172414 ], [ 29.782129367088608, -1.465330172413793 ], [ 29.781859873417723, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25883, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.465330172413793 ], [ 29.782129367088608, -1.465599655172414 ], [ 29.782398860759493, -1.465599655172414 ], [ 29.782398860759493, -1.465330172413793 ], [ 29.782129367088608, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25884, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.465330172413793 ], [ 29.782398860759493, -1.465599655172414 ], [ 29.782668354430381, -1.465599655172414 ], [ 29.782668354430381, -1.465330172413793 ], [ 29.782398860759493, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25885, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.465330172413793 ], [ 29.782668354430381, -1.465599655172414 ], [ 29.782937848101266, -1.465599655172414 ], [ 29.782937848101266, -1.465330172413793 ], [ 29.782668354430381, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25886, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.465330172413793 ], [ 29.782937848101266, -1.465599655172414 ], [ 29.783207341772151, -1.465599655172414 ], [ 29.783207341772151, -1.465330172413793 ], [ 29.782937848101266, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25887, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.465330172413793 ], [ 29.783207341772151, -1.465599655172414 ], [ 29.783476835443039, -1.465599655172414 ], [ 29.783476835443039, -1.465330172413793 ], [ 29.783207341772151, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25888, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.465330172413793 ], [ 29.783476835443039, -1.465599655172414 ], [ 29.784015822784809, -1.465599655172414 ], [ 29.784015822784809, -1.465330172413793 ], [ 29.783476835443039, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25889, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.465330172413793 ], [ 29.784015822784809, -1.465599655172414 ], [ 29.784285316455698, -1.465599655172414 ], [ 29.784285316455698, -1.465330172413793 ], [ 29.784015822784809, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25890, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.465330172413793 ], [ 29.784285316455698, -1.465599655172414 ], [ 29.784554810126583, -1.465599655172414 ], [ 29.784554810126583, -1.465330172413793 ], [ 29.784285316455698, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25891, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.465330172413793 ], [ 29.784554810126583, -1.465599655172414 ], [ 29.784824303797468, -1.465599655172414 ], [ 29.784824303797468, -1.465330172413793 ], [ 29.784554810126583, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25892, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.465330172413793 ], [ 29.784824303797468, -1.465599655172414 ], [ 29.785093797468356, -1.465599655172414 ], [ 29.785093797468356, -1.465330172413793 ], [ 29.784824303797468, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25893, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.465330172413793 ], [ 29.785093797468356, -1.465599655172414 ], [ 29.785363291139241, -1.465599655172414 ], [ 29.785363291139241, -1.465330172413793 ], [ 29.785093797468356, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25894, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.465330172413793 ], [ 29.785363291139241, -1.465599655172414 ], [ 29.785632784810126, -1.465599655172414 ], [ 29.785632784810126, -1.465330172413793 ], [ 29.785363291139241, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25895, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.465330172413793 ], [ 29.785632784810126, -1.466138620689655 ], [ 29.785902278481014, -1.466138620689655 ], [ 29.785902278481014, -1.465330172413793 ], [ 29.785632784810126, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25896, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.465330172413793 ], [ 29.785902278481014, -1.465599655172414 ], [ 29.786171772151899, -1.465599655172414 ], [ 29.786171772151899, -1.465330172413793 ], [ 29.785902278481014, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25897, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.465330172413793 ], [ 29.786171772151899, -1.465869137931035 ], [ 29.786441265822784, -1.465869137931035 ], [ 29.786441265822784, -1.465330172413793 ], [ 29.786171772151899, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25898, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.465330172413793 ], [ 29.786441265822784, -1.466138620689655 ], [ 29.786710759493673, -1.466138620689655 ], [ 29.786710759493673, -1.465330172413793 ], [ 29.786441265822784, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25899, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.465330172413793 ], [ 29.79776, -1.465599655172414 ], [ 29.798029493670885, -1.465599655172414 ], [ 29.798029493670885, -1.465330172413793 ], [ 29.79776, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25900, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.465330172413793 ], [ 29.798029493670885, -1.465599655172414 ], [ 29.798298987341774, -1.465599655172414 ], [ 29.798298987341774, -1.465330172413793 ], [ 29.798029493670885, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25901, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.465330172413793 ], [ 29.798298987341774, -1.465599655172414 ], [ 29.798568481012659, -1.465599655172414 ], [ 29.798568481012659, -1.465330172413793 ], [ 29.798298987341774, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25902, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.465330172413793 ], [ 29.798568481012659, -1.465599655172414 ], [ 29.798837974683543, -1.465599655172414 ], [ 29.798837974683543, -1.465330172413793 ], [ 29.798568481012659, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25903, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.465330172413793 ], [ 29.798837974683543, -1.465599655172414 ], [ 29.799107468354432, -1.465599655172414 ], [ 29.799107468354432, -1.465330172413793 ], [ 29.798837974683543, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25904, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.465330172413793 ], [ 29.799107468354432, -1.465599655172414 ], [ 29.799376962025317, -1.465599655172414 ], [ 29.799376962025317, -1.465330172413793 ], [ 29.799107468354432, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25905, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.465330172413793 ], [ 29.799376962025317, -1.465599655172414 ], [ 29.799646455696202, -1.465599655172414 ], [ 29.799646455696202, -1.465330172413793 ], [ 29.799376962025317, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25906, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.465330172413793 ], [ 29.799646455696202, -1.465599655172414 ], [ 29.79991594936709, -1.465599655172414 ], [ 29.79991594936709, -1.465330172413793 ], [ 29.799646455696202, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25907, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.465330172413793 ], [ 29.79991594936709, -1.465599655172414 ], [ 29.800185443037975, -1.465599655172414 ], [ 29.800185443037975, -1.465330172413793 ], [ 29.79991594936709, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25908, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.465330172413793 ], [ 29.800185443037975, -1.465599655172414 ], [ 29.80045493670886, -1.465599655172414 ], [ 29.80045493670886, -1.465330172413793 ], [ 29.800185443037975, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25909, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.465330172413793 ], [ 29.80045493670886, -1.465599655172414 ], [ 29.800724430379748, -1.465599655172414 ], [ 29.800724430379748, -1.465330172413793 ], [ 29.80045493670886, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25910, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.465330172413793 ], [ 29.800724430379748, -1.465599655172414 ], [ 29.800993924050633, -1.465599655172414 ], [ 29.800993924050633, -1.465330172413793 ], [ 29.800724430379748, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25911, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.465330172413793 ], [ 29.800993924050633, -1.465599655172414 ], [ 29.801263417721518, -1.465599655172414 ], [ 29.801263417721518, -1.465330172413793 ], [ 29.800993924050633, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25912, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.465330172413793 ], [ 29.801263417721518, -1.465599655172414 ], [ 29.801532911392407, -1.465599655172414 ], [ 29.801532911392407, -1.465330172413793 ], [ 29.801263417721518, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25913, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.465330172413793 ], [ 29.801532911392407, -1.465869137931035 ], [ 29.802071898734177, -1.465869137931035 ], [ 29.802071898734177, -1.465330172413793 ], [ 29.801532911392407, -1.465330172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25914, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.754910506329114, -1.465599655172414 ], [ 29.754910506329114, -1.465869137931035 ], [ 29.75518, -1.465869137931035 ], [ 29.75518, -1.465599655172414 ], [ 29.754910506329114, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25915, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.465599655172414 ], [ 29.75518, -1.465869137931035 ], [ 29.755449493670884, -1.465869137931035 ], [ 29.755449493670884, -1.465599655172414 ], [ 29.75518, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25916, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.465599655172414 ], [ 29.755449493670884, -1.465869137931035 ], [ 29.755718987341773, -1.465869137931035 ], [ 29.755718987341773, -1.465599655172414 ], [ 29.755449493670884, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25917, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.465599655172414 ], [ 29.755718987341773, -1.465869137931035 ], [ 29.755988481012658, -1.465869137931035 ], [ 29.755988481012658, -1.465599655172414 ], [ 29.755718987341773, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25918, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.465599655172414 ], [ 29.755988481012658, -1.466138620689655 ], [ 29.756257974683542, -1.466138620689655 ], [ 29.756257974683542, -1.465599655172414 ], [ 29.755988481012658, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25919, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.465599655172414 ], [ 29.756257974683542, -1.466138620689655 ], [ 29.756796962025316, -1.466138620689655 ], [ 29.756796962025316, -1.465599655172414 ], [ 29.756257974683542, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25920, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.465599655172414 ], [ 29.757066455696201, -1.465869137931035 ], [ 29.757335949367089, -1.465869137931035 ], [ 29.757335949367089, -1.465599655172414 ], [ 29.757066455696201, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25921, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.465599655172414 ], [ 29.757335949367089, -1.465869137931035 ], [ 29.757605443037974, -1.465869137931035 ], [ 29.757605443037974, -1.465599655172414 ], [ 29.757335949367089, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25922, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.465599655172414 ], [ 29.757605443037974, -1.465869137931035 ], [ 29.757874936708859, -1.465869137931035 ], [ 29.757874936708859, -1.465599655172414 ], [ 29.757605443037974, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25923, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.465599655172414 ], [ 29.757874936708859, -1.465869137931035 ], [ 29.758144430379748, -1.465869137931035 ], [ 29.758144430379748, -1.465599655172414 ], [ 29.757874936708859, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25924, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.465599655172414 ], [ 29.758144430379748, -1.465869137931035 ], [ 29.758413924050632, -1.465869137931035 ], [ 29.758413924050632, -1.465599655172414 ], [ 29.758144430379748, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25925, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.465599655172414 ], [ 29.758413924050632, -1.465869137931035 ], [ 29.758683417721517, -1.465869137931035 ], [ 29.758683417721517, -1.465599655172414 ], [ 29.758413924050632, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25926, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.465599655172414 ], [ 29.758683417721517, -1.465869137931035 ], [ 29.758952911392406, -1.465869137931035 ], [ 29.758952911392406, -1.465599655172414 ], [ 29.758683417721517, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25927, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.465599655172414 ], [ 29.758952911392406, -1.465869137931035 ], [ 29.759222405063291, -1.465869137931035 ], [ 29.759222405063291, -1.465599655172414 ], [ 29.758952911392406, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25928, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.465599655172414 ], [ 29.759222405063291, -1.466138620689655 ], [ 29.759491898734176, -1.466138620689655 ], [ 29.759491898734176, -1.465599655172414 ], [ 29.759222405063291, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25929, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.465599655172414 ], [ 29.759491898734176, -1.465869137931035 ], [ 29.759761392405064, -1.465869137931035 ], [ 29.759761392405064, -1.465599655172414 ], [ 29.759491898734176, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25930, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.465599655172414 ], [ 29.759761392405064, -1.465869137931035 ], [ 29.760030886075949, -1.465869137931035 ], [ 29.760030886075949, -1.465599655172414 ], [ 29.759761392405064, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25931, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.465599655172414 ], [ 29.760030886075949, -1.466138620689655 ], [ 29.760300379746834, -1.466138620689655 ], [ 29.760300379746834, -1.465599655172414 ], [ 29.760030886075949, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25932, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.465599655172414 ], [ 29.760300379746834, -1.465869137931035 ], [ 29.760569873417722, -1.465869137931035 ], [ 29.760569873417722, -1.465599655172414 ], [ 29.760300379746834, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25933, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.465599655172414 ], [ 29.760569873417722, -1.465869137931035 ], [ 29.760839367088607, -1.465869137931035 ], [ 29.760839367088607, -1.465599655172414 ], [ 29.760569873417722, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25934, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.465599655172414 ], [ 29.760839367088607, -1.466138620689655 ], [ 29.761108860759492, -1.466138620689655 ], [ 29.761108860759492, -1.465599655172414 ], [ 29.760839367088607, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25935, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.465599655172414 ], [ 29.763534303797467, -1.466677586206897 ], [ 29.763803797468356, -1.466677586206897 ], [ 29.763803797468356, -1.465599655172414 ], [ 29.763534303797467, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25936, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.465599655172414 ], [ 29.764881772151899, -1.466677586206897 ], [ 29.765151265822784, -1.466677586206897 ], [ 29.765151265822784, -1.465599655172414 ], [ 29.764881772151899, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25937, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.465599655172414 ], [ 29.765959746835442, -1.466677586206897 ], [ 29.766229240506327, -1.466677586206897 ], [ 29.766229240506327, -1.465599655172414 ], [ 29.765959746835442, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25938, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.465599655172414 ], [ 29.766229240506327, -1.466677586206897 ], [ 29.766498734177215, -1.466677586206897 ], [ 29.766498734177215, -1.465599655172414 ], [ 29.766229240506327, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25939, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.465599655172414 ], [ 29.766498734177215, -1.466677586206897 ], [ 29.7667682278481, -1.466677586206897 ], [ 29.7667682278481, -1.465599655172414 ], [ 29.766498734177215, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25940, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.465599655172414 ], [ 29.770002151898733, -1.465869137931035 ], [ 29.770271645569618, -1.465869137931035 ], [ 29.770271645569618, -1.465599655172414 ], [ 29.770002151898733, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25941, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.465599655172414 ], [ 29.770271645569618, -1.465869137931035 ], [ 29.770541139240507, -1.465869137931035 ], [ 29.770541139240507, -1.465599655172414 ], [ 29.770271645569618, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25942, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.465599655172414 ], [ 29.770541139240507, -1.465869137931035 ], [ 29.770810632911392, -1.465869137931035 ], [ 29.770810632911392, -1.465599655172414 ], [ 29.770541139240507, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25943, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.465599655172414 ], [ 29.770810632911392, -1.465869137931035 ], [ 29.771080126582277, -1.465869137931035 ], [ 29.771080126582277, -1.465599655172414 ], [ 29.770810632911392, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25944, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.465599655172414 ], [ 29.771080126582277, -1.466947068965517 ], [ 29.771349620253165, -1.466947068965517 ], [ 29.771349620253165, -1.465599655172414 ], [ 29.771080126582277, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25945, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.465599655172414 ], [ 29.771349620253165, -1.466947068965517 ], [ 29.77161911392405, -1.466947068965517 ], [ 29.77161911392405, -1.465599655172414 ], [ 29.771349620253165, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25946, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.465599655172414 ], [ 29.771888607594935, -1.466947068965517 ], [ 29.772158101265823, -1.466947068965517 ], [ 29.772158101265823, -1.465599655172414 ], [ 29.771888607594935, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25947, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.465599655172414 ], [ 29.772158101265823, -1.466947068965517 ], [ 29.772427594936708, -1.466947068965517 ], [ 29.772427594936708, -1.465599655172414 ], [ 29.772158101265823, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25948, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.465599655172414 ], [ 29.772697088607593, -1.466947068965517 ], [ 29.772966582278482, -1.466947068965517 ], [ 29.772966582278482, -1.465599655172414 ], [ 29.772697088607593, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25949, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.465599655172414 ], [ 29.773505569620252, -1.466947068965517 ], [ 29.77377506329114, -1.466947068965517 ], [ 29.77377506329114, -1.465599655172414 ], [ 29.773505569620252, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25950, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.465599655172414 ], [ 29.77431405063291, -1.466947068965517 ], [ 29.774853037974683, -1.466947068965517 ], [ 29.774853037974683, -1.465599655172414 ], [ 29.77431405063291, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25951, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.465599655172414 ], [ 29.775122531645568, -1.467216551724138 ], [ 29.775392025316457, -1.467216551724138 ], [ 29.775392025316457, -1.465599655172414 ], [ 29.775122531645568, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25952, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.465599655172414 ], [ 29.775931012658226, -1.466947068965517 ], [ 29.776200506329115, -1.466947068965517 ], [ 29.776200506329115, -1.465599655172414 ], [ 29.775931012658226, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25953, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.465599655172414 ], [ 29.777278481012658, -1.466677586206897 ], [ 29.777547974683543, -1.466677586206897 ], [ 29.777547974683543, -1.465599655172414 ], [ 29.777278481012658, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25954, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.465599655172414 ], [ 29.77862594936709, -1.466408103448276 ], [ 29.778895443037975, -1.466408103448276 ], [ 29.778895443037975, -1.465599655172414 ], [ 29.77862594936709, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25955, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.465599655172414 ], [ 29.77916493670886, -1.465869137931035 ], [ 29.779434430379748, -1.465869137931035 ], [ 29.779434430379748, -1.465599655172414 ], [ 29.77916493670886, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25956, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.465599655172414 ], [ 29.779434430379748, -1.465869137931035 ], [ 29.779703924050633, -1.465869137931035 ], [ 29.779703924050633, -1.465599655172414 ], [ 29.779434430379748, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25957, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.465599655172414 ], [ 29.779703924050633, -1.465869137931035 ], [ 29.779973417721518, -1.465869137931035 ], [ 29.779973417721518, -1.465599655172414 ], [ 29.779703924050633, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25958, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.465599655172414 ], [ 29.779973417721518, -1.465869137931035 ], [ 29.780242911392406, -1.465869137931035 ], [ 29.780242911392406, -1.465599655172414 ], [ 29.779973417721518, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25959, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.465599655172414 ], [ 29.780242911392406, -1.465869137931035 ], [ 29.780512405063291, -1.465869137931035 ], [ 29.780512405063291, -1.465599655172414 ], [ 29.780242911392406, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25960, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.465599655172414 ], [ 29.780512405063291, -1.465869137931035 ], [ 29.780781898734176, -1.465869137931035 ], [ 29.780781898734176, -1.465599655172414 ], [ 29.780512405063291, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25961, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.465599655172414 ], [ 29.780781898734176, -1.465869137931035 ], [ 29.781051392405065, -1.465869137931035 ], [ 29.781051392405065, -1.465599655172414 ], [ 29.780781898734176, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25962, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.465599655172414 ], [ 29.781051392405065, -1.465869137931035 ], [ 29.78132088607595, -1.465869137931035 ], [ 29.78132088607595, -1.465599655172414 ], [ 29.781051392405065, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25963, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.465599655172414 ], [ 29.78132088607595, -1.465869137931035 ], [ 29.781590379746834, -1.465869137931035 ], [ 29.781590379746834, -1.465599655172414 ], [ 29.78132088607595, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25964, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.465599655172414 ], [ 29.781590379746834, -1.465869137931035 ], [ 29.781859873417723, -1.465869137931035 ], [ 29.781859873417723, -1.465599655172414 ], [ 29.781590379746834, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25965, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.465599655172414 ], [ 29.781859873417723, -1.465869137931035 ], [ 29.782129367088608, -1.465869137931035 ], [ 29.782129367088608, -1.465599655172414 ], [ 29.781859873417723, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25966, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.465599655172414 ], [ 29.782129367088608, -1.465869137931035 ], [ 29.782398860759493, -1.465869137931035 ], [ 29.782398860759493, -1.465599655172414 ], [ 29.782129367088608, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25967, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.465599655172414 ], [ 29.782398860759493, -1.465869137931035 ], [ 29.782668354430381, -1.465869137931035 ], [ 29.782668354430381, -1.465599655172414 ], [ 29.782398860759493, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25968, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.465599655172414 ], [ 29.782668354430381, -1.465869137931035 ], [ 29.782937848101266, -1.465869137931035 ], [ 29.782937848101266, -1.465599655172414 ], [ 29.782668354430381, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25969, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.465599655172414 ], [ 29.782937848101266, -1.465869137931035 ], [ 29.783207341772151, -1.465869137931035 ], [ 29.783207341772151, -1.465599655172414 ], [ 29.782937848101266, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25970, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.465599655172414 ], [ 29.783207341772151, -1.465869137931035 ], [ 29.783476835443039, -1.465869137931035 ], [ 29.783476835443039, -1.465599655172414 ], [ 29.783207341772151, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25971, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.465599655172414 ], [ 29.783476835443039, -1.465869137931035 ], [ 29.784015822784809, -1.465869137931035 ], [ 29.784015822784809, -1.465599655172414 ], [ 29.783476835443039, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25972, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.465599655172414 ], [ 29.784015822784809, -1.465869137931035 ], [ 29.784285316455698, -1.465869137931035 ], [ 29.784285316455698, -1.465599655172414 ], [ 29.784015822784809, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25973, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.465599655172414 ], [ 29.784285316455698, -1.465869137931035 ], [ 29.784554810126583, -1.465869137931035 ], [ 29.784554810126583, -1.465599655172414 ], [ 29.784285316455698, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25974, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.465599655172414 ], [ 29.784554810126583, -1.465869137931035 ], [ 29.784824303797468, -1.465869137931035 ], [ 29.784824303797468, -1.465599655172414 ], [ 29.784554810126583, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25975, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.465599655172414 ], [ 29.784824303797468, -1.466138620689655 ], [ 29.785093797468356, -1.466138620689655 ], [ 29.785093797468356, -1.465599655172414 ], [ 29.784824303797468, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25976, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.465599655172414 ], [ 29.785093797468356, -1.466138620689655 ], [ 29.785363291139241, -1.466138620689655 ], [ 29.785363291139241, -1.465599655172414 ], [ 29.785093797468356, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25977, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.465599655172414 ], [ 29.785363291139241, -1.465869137931035 ], [ 29.785632784810126, -1.465869137931035 ], [ 29.785632784810126, -1.465599655172414 ], [ 29.785363291139241, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25978, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.465599655172414 ], [ 29.785902278481014, -1.466138620689655 ], [ 29.786171772151899, -1.466138620689655 ], [ 29.786171772151899, -1.465599655172414 ], [ 29.785902278481014, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25979, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.465599655172414 ], [ 29.787249746835442, -1.466138620689655 ], [ 29.787519240506327, -1.466138620689655 ], [ 29.787519240506327, -1.465599655172414 ], [ 29.787249746835442, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25980, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.465599655172414 ], [ 29.788058227848101, -1.465869137931035 ], [ 29.788327721518986, -1.465869137931035 ], [ 29.788327721518986, -1.465599655172414 ], [ 29.788058227848101, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25981, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.465599655172414 ], [ 29.788327721518986, -1.465869137931035 ], [ 29.788597215189874, -1.465869137931035 ], [ 29.788597215189874, -1.465599655172414 ], [ 29.788327721518986, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25982, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.465599655172414 ], [ 29.788597215189874, -1.465869137931035 ], [ 29.788866708860759, -1.465869137931035 ], [ 29.788866708860759, -1.465599655172414 ], [ 29.788597215189874, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25983, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.465599655172414 ], [ 29.788866708860759, -1.466947068965517 ], [ 29.789136202531644, -1.466947068965517 ], [ 29.789136202531644, -1.465599655172414 ], [ 29.788866708860759, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25984, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.465599655172414 ], [ 29.79776, -1.466677586206897 ], [ 29.798029493670885, -1.466677586206897 ], [ 29.798029493670885, -1.466408103448276 ], [ 29.798298987341774, -1.466408103448276 ], [ 29.798298987341774, -1.466138620689655 ], [ 29.798029493670885, -1.466138620689655 ], [ 29.798029493670885, -1.465599655172414 ], [ 29.79776, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25985, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.465599655172414 ], [ 29.798029493670885, -1.465869137931035 ], [ 29.798298987341774, -1.465869137931035 ], [ 29.798298987341774, -1.465599655172414 ], [ 29.798029493670885, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25986, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.465599655172414 ], [ 29.798298987341774, -1.465869137931035 ], [ 29.798568481012659, -1.465869137931035 ], [ 29.798568481012659, -1.465599655172414 ], [ 29.798298987341774, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25987, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.465599655172414 ], [ 29.798568481012659, -1.465869137931035 ], [ 29.798837974683543, -1.465869137931035 ], [ 29.798837974683543, -1.465599655172414 ], [ 29.798568481012659, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25988, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.465599655172414 ], [ 29.798837974683543, -1.465869137931035 ], [ 29.799107468354432, -1.465869137931035 ], [ 29.799107468354432, -1.465599655172414 ], [ 29.798837974683543, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25989, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.465599655172414 ], [ 29.799107468354432, -1.465869137931035 ], [ 29.799376962025317, -1.465869137931035 ], [ 29.799376962025317, -1.465599655172414 ], [ 29.799107468354432, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25990, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.465599655172414 ], [ 29.799376962025317, -1.465869137931035 ], [ 29.799646455696202, -1.465869137931035 ], [ 29.799646455696202, -1.465599655172414 ], [ 29.799376962025317, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25991, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.465599655172414 ], [ 29.799646455696202, -1.465869137931035 ], [ 29.79991594936709, -1.465869137931035 ], [ 29.79991594936709, -1.465599655172414 ], [ 29.799646455696202, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25992, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.465599655172414 ], [ 29.79991594936709, -1.465869137931035 ], [ 29.800185443037975, -1.465869137931035 ], [ 29.800185443037975, -1.465599655172414 ], [ 29.79991594936709, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25993, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.465599655172414 ], [ 29.800185443037975, -1.465869137931035 ], [ 29.80045493670886, -1.465869137931035 ], [ 29.80045493670886, -1.465599655172414 ], [ 29.800185443037975, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25994, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.465599655172414 ], [ 29.80045493670886, -1.465869137931035 ], [ 29.800724430379748, -1.465869137931035 ], [ 29.800724430379748, -1.465599655172414 ], [ 29.80045493670886, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25995, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.465599655172414 ], [ 29.800724430379748, -1.465869137931035 ], [ 29.800993924050633, -1.465869137931035 ], [ 29.800993924050633, -1.465599655172414 ], [ 29.800724430379748, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25996, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.465599655172414 ], [ 29.800993924050633, -1.465869137931035 ], [ 29.801263417721518, -1.465869137931035 ], [ 29.801263417721518, -1.465599655172414 ], [ 29.800993924050633, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25997, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.465599655172414 ], [ 29.801263417721518, -1.465869137931035 ], [ 29.801532911392407, -1.465869137931035 ], [ 29.801532911392407, -1.465599655172414 ], [ 29.801263417721518, -1.465599655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25998, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.75518, -1.465869137931035 ], [ 29.75518, -1.466677586206897 ], [ 29.755449493670884, -1.466677586206897 ], [ 29.755449493670884, -1.465869137931035 ], [ 29.75518, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 25999, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.465869137931035 ], [ 29.755449493670884, -1.466408103448276 ], [ 29.755718987341773, -1.466408103448276 ], [ 29.755718987341773, -1.465869137931035 ], [ 29.755449493670884, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26000, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.465869137931035 ], [ 29.755718987341773, -1.466408103448276 ], [ 29.755988481012658, -1.466408103448276 ], [ 29.755988481012658, -1.465869137931035 ], [ 29.755718987341773, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26001, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.465869137931035 ], [ 29.756796962025316, -1.466138620689655 ], [ 29.757066455696201, -1.466138620689655 ], [ 29.757066455696201, -1.465869137931035 ], [ 29.756796962025316, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26002, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.465869137931035 ], [ 29.757066455696201, -1.466138620689655 ], [ 29.757335949367089, -1.466138620689655 ], [ 29.757335949367089, -1.465869137931035 ], [ 29.757066455696201, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26003, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.465869137931035 ], [ 29.757335949367089, -1.466138620689655 ], [ 29.757605443037974, -1.466138620689655 ], [ 29.757605443037974, -1.465869137931035 ], [ 29.757335949367089, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26004, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.465869137931035 ], [ 29.757605443037974, -1.466138620689655 ], [ 29.757874936708859, -1.466138620689655 ], [ 29.757874936708859, -1.465869137931035 ], [ 29.757605443037974, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26005, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.465869137931035 ], [ 29.757874936708859, -1.466138620689655 ], [ 29.758144430379748, -1.466138620689655 ], [ 29.758144430379748, -1.465869137931035 ], [ 29.757874936708859, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26006, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.465869137931035 ], [ 29.758144430379748, -1.466408103448276 ], [ 29.758413924050632, -1.466408103448276 ], [ 29.758413924050632, -1.465869137931035 ], [ 29.758144430379748, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26007, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.465869137931035 ], [ 29.758413924050632, -1.466138620689655 ], [ 29.758683417721517, -1.466138620689655 ], [ 29.758683417721517, -1.465869137931035 ], [ 29.758413924050632, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26008, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.465869137931035 ], [ 29.758683417721517, -1.466138620689655 ], [ 29.758952911392406, -1.466138620689655 ], [ 29.758952911392406, -1.465869137931035 ], [ 29.758683417721517, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26009, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.465869137931035 ], [ 29.758952911392406, -1.466408103448276 ], [ 29.759222405063291, -1.466408103448276 ], [ 29.759222405063291, -1.465869137931035 ], [ 29.758952911392406, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26010, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.465869137931035 ], [ 29.759491898734176, -1.466138620689655 ], [ 29.759761392405064, -1.466138620689655 ], [ 29.759761392405064, -1.465869137931035 ], [ 29.759491898734176, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26011, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.465869137931035 ], [ 29.759761392405064, -1.466408103448276 ], [ 29.760030886075949, -1.466408103448276 ], [ 29.760030886075949, -1.465869137931035 ], [ 29.759761392405064, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26012, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.465869137931035 ], [ 29.760300379746834, -1.466138620689655 ], [ 29.760569873417722, -1.466138620689655 ], [ 29.760569873417722, -1.465869137931035 ], [ 29.760300379746834, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26013, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.465869137931035 ], [ 29.760569873417722, -1.466138620689655 ], [ 29.760839367088607, -1.466138620689655 ], [ 29.760839367088607, -1.465869137931035 ], [ 29.760569873417722, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26014, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.465869137931035 ], [ 29.770271645569618, -1.466677586206897 ], [ 29.770541139240507, -1.466677586206897 ], [ 29.770541139240507, -1.465869137931035 ], [ 29.770271645569618, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26015, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.465869137931035 ], [ 29.770541139240507, -1.466677586206897 ], [ 29.770810632911392, -1.466677586206897 ], [ 29.770810632911392, -1.465869137931035 ], [ 29.770541139240507, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26016, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.465869137931035 ], [ 29.770810632911392, -1.466677586206897 ], [ 29.771080126582277, -1.466677586206897 ], [ 29.771080126582277, -1.465869137931035 ], [ 29.770810632911392, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26017, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.465869137931035 ], [ 29.77161911392405, -1.466677586206897 ], [ 29.771888607594935, -1.466677586206897 ], [ 29.771888607594935, -1.465869137931035 ], [ 29.77161911392405, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26018, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.465869137931035 ], [ 29.772966582278482, -1.466677586206897 ], [ 29.773236075949367, -1.466677586206897 ], [ 29.773236075949367, -1.465869137931035 ], [ 29.772966582278482, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26019, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.465869137931035 ], [ 29.77916493670886, -1.466138620689655 ], [ 29.779434430379748, -1.466138620689655 ], [ 29.779434430379748, -1.465869137931035 ], [ 29.77916493670886, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26020, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.465869137931035 ], [ 29.779434430379748, -1.466138620689655 ], [ 29.779703924050633, -1.466138620689655 ], [ 29.779703924050633, -1.465869137931035 ], [ 29.779434430379748, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26021, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.465869137931035 ], [ 29.779703924050633, -1.466138620689655 ], [ 29.779973417721518, -1.466138620689655 ], [ 29.779973417721518, -1.465869137931035 ], [ 29.779703924050633, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26022, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.465869137931035 ], [ 29.779973417721518, -1.466138620689655 ], [ 29.780242911392406, -1.466138620689655 ], [ 29.780242911392406, -1.465869137931035 ], [ 29.779973417721518, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26023, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.465869137931035 ], [ 29.780242911392406, -1.466138620689655 ], [ 29.780512405063291, -1.466138620689655 ], [ 29.780512405063291, -1.465869137931035 ], [ 29.780242911392406, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26024, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.465869137931035 ], [ 29.780512405063291, -1.466138620689655 ], [ 29.780781898734176, -1.466138620689655 ], [ 29.780781898734176, -1.465869137931035 ], [ 29.780512405063291, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26025, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.465869137931035 ], [ 29.780781898734176, -1.466138620689655 ], [ 29.781051392405065, -1.466138620689655 ], [ 29.781051392405065, -1.465869137931035 ], [ 29.780781898734176, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26026, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.465869137931035 ], [ 29.781051392405065, -1.466138620689655 ], [ 29.78132088607595, -1.466138620689655 ], [ 29.78132088607595, -1.465869137931035 ], [ 29.781051392405065, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26027, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.465869137931035 ], [ 29.78132088607595, -1.466138620689655 ], [ 29.781590379746834, -1.466138620689655 ], [ 29.781590379746834, -1.465869137931035 ], [ 29.78132088607595, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26028, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.465869137931035 ], [ 29.781590379746834, -1.466138620689655 ], [ 29.781859873417723, -1.466138620689655 ], [ 29.781859873417723, -1.465869137931035 ], [ 29.781590379746834, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26029, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.465869137931035 ], [ 29.781859873417723, -1.466138620689655 ], [ 29.782129367088608, -1.466138620689655 ], [ 29.782129367088608, -1.465869137931035 ], [ 29.781859873417723, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26030, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.465869137931035 ], [ 29.782129367088608, -1.466138620689655 ], [ 29.782398860759493, -1.466138620689655 ], [ 29.782398860759493, -1.465869137931035 ], [ 29.782129367088608, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26031, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.465869137931035 ], [ 29.782398860759493, -1.466138620689655 ], [ 29.782668354430381, -1.466138620689655 ], [ 29.782668354430381, -1.465869137931035 ], [ 29.782398860759493, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26032, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.465869137931035 ], [ 29.782668354430381, -1.466138620689655 ], [ 29.782937848101266, -1.466138620689655 ], [ 29.782937848101266, -1.465869137931035 ], [ 29.782668354430381, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26033, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.465869137931035 ], [ 29.782937848101266, -1.466138620689655 ], [ 29.783207341772151, -1.466138620689655 ], [ 29.783207341772151, -1.465869137931035 ], [ 29.782937848101266, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26034, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.465869137931035 ], [ 29.783207341772151, -1.466138620689655 ], [ 29.783476835443039, -1.466138620689655 ], [ 29.783476835443039, -1.465869137931035 ], [ 29.783207341772151, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26035, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.465869137931035 ], [ 29.783476835443039, -1.466138620689655 ], [ 29.784015822784809, -1.466138620689655 ], [ 29.784015822784809, -1.465869137931035 ], [ 29.783476835443039, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26036, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.465869137931035 ], [ 29.784015822784809, -1.466138620689655 ], [ 29.784285316455698, -1.466138620689655 ], [ 29.784285316455698, -1.465869137931035 ], [ 29.784015822784809, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26037, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.465869137931035 ], [ 29.784285316455698, -1.466138620689655 ], [ 29.784554810126583, -1.466138620689655 ], [ 29.784554810126583, -1.465869137931035 ], [ 29.784285316455698, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26038, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.465869137931035 ], [ 29.784554810126583, -1.466408103448276 ], [ 29.784824303797468, -1.466408103448276 ], [ 29.784824303797468, -1.465869137931035 ], [ 29.784554810126583, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26039, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.465869137931035 ], [ 29.785363291139241, -1.466408103448276 ], [ 29.785632784810126, -1.466408103448276 ], [ 29.785632784810126, -1.465869137931035 ], [ 29.785363291139241, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26040, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.465869137931035 ], [ 29.786171772151899, -1.466408103448276 ], [ 29.786441265822784, -1.466408103448276 ], [ 29.786441265822784, -1.465869137931035 ], [ 29.786171772151899, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26041, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.465869137931035 ], [ 29.786710759493673, -1.466138620689655 ], [ 29.786980253164558, -1.466138620689655 ], [ 29.786980253164558, -1.465869137931035 ], [ 29.786710759493673, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26042, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.465869137931035 ], [ 29.786980253164558, -1.466138620689655 ], [ 29.787249746835442, -1.466138620689655 ], [ 29.787249746835442, -1.465869137931035 ], [ 29.786980253164558, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26043, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.465869137931035 ], [ 29.787519240506327, -1.466138620689655 ], [ 29.787788734177216, -1.466138620689655 ], [ 29.787788734177216, -1.465869137931035 ], [ 29.787519240506327, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26044, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.465869137931035 ], [ 29.787788734177216, -1.466138620689655 ], [ 29.788058227848101, -1.466138620689655 ], [ 29.788058227848101, -1.465869137931035 ], [ 29.787788734177216, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26045, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.465869137931035 ], [ 29.788058227848101, -1.466138620689655 ], [ 29.788327721518986, -1.466138620689655 ], [ 29.788327721518986, -1.465869137931035 ], [ 29.788058227848101, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26046, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.465869137931035 ], [ 29.788327721518986, -1.466947068965517 ], [ 29.788597215189874, -1.466947068965517 ], [ 29.788597215189874, -1.465869137931035 ], [ 29.788327721518986, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26047, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.465869137931035 ], [ 29.788597215189874, -1.466947068965517 ], [ 29.788866708860759, -1.466947068965517 ], [ 29.788866708860759, -1.465869137931035 ], [ 29.788597215189874, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26048, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.465869137931035 ], [ 29.798029493670885, -1.466138620689655 ], [ 29.798298987341774, -1.466138620689655 ], [ 29.798298987341774, -1.465869137931035 ], [ 29.798029493670885, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26049, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.465869137931035 ], [ 29.798298987341774, -1.466138620689655 ], [ 29.798568481012659, -1.466138620689655 ], [ 29.798568481012659, -1.465869137931035 ], [ 29.798298987341774, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26050, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.465869137931035 ], [ 29.798568481012659, -1.466138620689655 ], [ 29.798837974683543, -1.466138620689655 ], [ 29.798837974683543, -1.465869137931035 ], [ 29.798568481012659, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26051, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.465869137931035 ], [ 29.798837974683543, -1.466138620689655 ], [ 29.799107468354432, -1.466138620689655 ], [ 29.799107468354432, -1.465869137931035 ], [ 29.798837974683543, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26052, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.465869137931035 ], [ 29.799107468354432, -1.466138620689655 ], [ 29.799376962025317, -1.466138620689655 ], [ 29.799376962025317, -1.465869137931035 ], [ 29.799107468354432, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26053, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.465869137931035 ], [ 29.799376962025317, -1.466138620689655 ], [ 29.799646455696202, -1.466138620689655 ], [ 29.799646455696202, -1.465869137931035 ], [ 29.799376962025317, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26054, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.465869137931035 ], [ 29.799646455696202, -1.466138620689655 ], [ 29.79991594936709, -1.466138620689655 ], [ 29.79991594936709, -1.465869137931035 ], [ 29.799646455696202, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26055, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.465869137931035 ], [ 29.79991594936709, -1.466138620689655 ], [ 29.800185443037975, -1.466138620689655 ], [ 29.800185443037975, -1.465869137931035 ], [ 29.79991594936709, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26056, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.465869137931035 ], [ 29.800185443037975, -1.466138620689655 ], [ 29.80045493670886, -1.466138620689655 ], [ 29.80045493670886, -1.465869137931035 ], [ 29.800185443037975, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26057, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.465869137931035 ], [ 29.80045493670886, -1.466138620689655 ], [ 29.800724430379748, -1.466138620689655 ], [ 29.800724430379748, -1.465869137931035 ], [ 29.80045493670886, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26058, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.465869137931035 ], [ 29.800724430379748, -1.466138620689655 ], [ 29.800993924050633, -1.466138620689655 ], [ 29.800993924050633, -1.465869137931035 ], [ 29.800724430379748, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26059, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.465869137931035 ], [ 29.800993924050633, -1.466138620689655 ], [ 29.801263417721518, -1.466138620689655 ], [ 29.801263417721518, -1.465869137931035 ], [ 29.800993924050633, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26060, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.465869137931035 ], [ 29.801263417721518, -1.466138620689655 ], [ 29.801532911392407, -1.466138620689655 ], [ 29.801532911392407, -1.465869137931035 ], [ 29.801263417721518, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26061, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.465869137931035 ], [ 29.801532911392407, -1.466138620689655 ], [ 29.802071898734177, -1.466138620689655 ], [ 29.802071898734177, -1.465869137931035 ], [ 29.801532911392407, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26062, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.465869137931035 ], [ 29.802071898734177, -1.466138620689655 ], [ 29.802341392405065, -1.466138620689655 ], [ 29.802341392405065, -1.465869137931035 ], [ 29.802071898734177, -1.465869137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26063, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.466138620689655 ], [ 29.755988481012658, -1.466408103448276 ], [ 29.756257974683542, -1.466408103448276 ], [ 29.756257974683542, -1.466138620689655 ], [ 29.755988481012658, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26064, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.466138620689655 ], [ 29.756257974683542, -1.466408103448276 ], [ 29.756796962025316, -1.466408103448276 ], [ 29.756796962025316, -1.466138620689655 ], [ 29.756257974683542, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26065, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.466138620689655 ], [ 29.756796962025316, -1.466408103448276 ], [ 29.757066455696201, -1.466408103448276 ], [ 29.757066455696201, -1.466138620689655 ], [ 29.756796962025316, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26066, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.466138620689655 ], [ 29.757066455696201, -1.466677586206897 ], [ 29.757335949367089, -1.466677586206897 ], [ 29.757335949367089, -1.466138620689655 ], [ 29.757066455696201, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26067, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.466138620689655 ], [ 29.757335949367089, -1.466408103448276 ], [ 29.757605443037974, -1.466408103448276 ], [ 29.757605443037974, -1.466138620689655 ], [ 29.757335949367089, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26068, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.466138620689655 ], [ 29.757605443037974, -1.466408103448276 ], [ 29.757874936708859, -1.466408103448276 ], [ 29.757874936708859, -1.466138620689655 ], [ 29.757605443037974, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26069, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.466138620689655 ], [ 29.757874936708859, -1.466677586206897 ], [ 29.758144430379748, -1.466677586206897 ], [ 29.758144430379748, -1.466138620689655 ], [ 29.757874936708859, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26070, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.466138620689655 ], [ 29.758413924050632, -1.466677586206897 ], [ 29.758683417721517, -1.466677586206897 ], [ 29.758683417721517, -1.466138620689655 ], [ 29.758413924050632, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26071, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.466138620689655 ], [ 29.758683417721517, -1.466677586206897 ], [ 29.758952911392406, -1.466677586206897 ], [ 29.758952911392406, -1.466138620689655 ], [ 29.758683417721517, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26072, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.466138620689655 ], [ 29.759222405063291, -1.466408103448276 ], [ 29.759491898734176, -1.466408103448276 ], [ 29.759491898734176, -1.466138620689655 ], [ 29.759222405063291, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26073, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.466138620689655 ], [ 29.759491898734176, -1.466677586206897 ], [ 29.759761392405064, -1.466677586206897 ], [ 29.759761392405064, -1.466138620689655 ], [ 29.759491898734176, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26074, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.466138620689655 ], [ 29.760030886075949, -1.466408103448276 ], [ 29.760300379746834, -1.466408103448276 ], [ 29.760300379746834, -1.466138620689655 ], [ 29.760030886075949, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26075, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.466138620689655 ], [ 29.760300379746834, -1.466408103448276 ], [ 29.760569873417722, -1.466408103448276 ], [ 29.760569873417722, -1.466138620689655 ], [ 29.760300379746834, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26076, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.466138620689655 ], [ 29.760569873417722, -1.466677586206897 ], [ 29.760839367088607, -1.466677586206897 ], [ 29.760839367088607, -1.466138620689655 ], [ 29.760569873417722, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26077, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.465060689655173 ], [ 29.761378354430381, -1.465060689655173 ], [ 29.761378354430381, -1.467486034482759 ], [ 29.761108860759492, -1.467486034482759 ], [ 29.761108860759492, -1.466408103448276 ], [ 29.760839367088607, -1.466408103448276 ], [ 29.760839367088607, -1.466138620689655 ], [ 29.761108860759492, -1.466138620689655 ], [ 29.761108860759492, -1.465060689655173 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26078, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.466138620689655 ], [ 29.778895443037975, -1.466408103448276 ], [ 29.77916493670886, -1.466408103448276 ], [ 29.77916493670886, -1.466138620689655 ], [ 29.778895443037975, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26079, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.466138620689655 ], [ 29.77916493670886, -1.466408103448276 ], [ 29.779434430379748, -1.466408103448276 ], [ 29.779434430379748, -1.466138620689655 ], [ 29.77916493670886, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26080, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.466138620689655 ], [ 29.779434430379748, -1.466408103448276 ], [ 29.779703924050633, -1.466408103448276 ], [ 29.779703924050633, -1.466138620689655 ], [ 29.779434430379748, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26081, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.466138620689655 ], [ 29.779703924050633, -1.466408103448276 ], [ 29.779973417721518, -1.466408103448276 ], [ 29.779973417721518, -1.466138620689655 ], [ 29.779703924050633, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26082, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.466138620689655 ], [ 29.779973417721518, -1.466408103448276 ], [ 29.780242911392406, -1.466408103448276 ], [ 29.780242911392406, -1.466138620689655 ], [ 29.779973417721518, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26083, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.466138620689655 ], [ 29.780242911392406, -1.466408103448276 ], [ 29.780512405063291, -1.466408103448276 ], [ 29.780512405063291, -1.466138620689655 ], [ 29.780242911392406, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26084, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.466138620689655 ], [ 29.780512405063291, -1.466408103448276 ], [ 29.780781898734176, -1.466408103448276 ], [ 29.780781898734176, -1.466138620689655 ], [ 29.780512405063291, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26085, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.466138620689655 ], [ 29.780781898734176, -1.466408103448276 ], [ 29.781051392405065, -1.466408103448276 ], [ 29.781051392405065, -1.466138620689655 ], [ 29.780781898734176, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26086, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.466138620689655 ], [ 29.781051392405065, -1.466408103448276 ], [ 29.78132088607595, -1.466408103448276 ], [ 29.78132088607595, -1.466138620689655 ], [ 29.781051392405065, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26087, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.466138620689655 ], [ 29.78132088607595, -1.466408103448276 ], [ 29.781590379746834, -1.466408103448276 ], [ 29.781590379746834, -1.466138620689655 ], [ 29.78132088607595, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26088, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.466138620689655 ], [ 29.781590379746834, -1.466408103448276 ], [ 29.781859873417723, -1.466408103448276 ], [ 29.781859873417723, -1.466138620689655 ], [ 29.781590379746834, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26089, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.466138620689655 ], [ 29.781859873417723, -1.466408103448276 ], [ 29.782129367088608, -1.466408103448276 ], [ 29.782129367088608, -1.466138620689655 ], [ 29.781859873417723, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26090, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.466138620689655 ], [ 29.782129367088608, -1.466408103448276 ], [ 29.782398860759493, -1.466408103448276 ], [ 29.782398860759493, -1.466138620689655 ], [ 29.782129367088608, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26091, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.466138620689655 ], [ 29.782398860759493, -1.466408103448276 ], [ 29.782668354430381, -1.466408103448276 ], [ 29.782668354430381, -1.466138620689655 ], [ 29.782398860759493, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26092, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.466138620689655 ], [ 29.782668354430381, -1.466408103448276 ], [ 29.782937848101266, -1.466408103448276 ], [ 29.782937848101266, -1.466138620689655 ], [ 29.782668354430381, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26093, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.466138620689655 ], [ 29.782937848101266, -1.466408103448276 ], [ 29.783207341772151, -1.466408103448276 ], [ 29.783207341772151, -1.466138620689655 ], [ 29.782937848101266, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26094, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.466138620689655 ], [ 29.783207341772151, -1.466408103448276 ], [ 29.783476835443039, -1.466408103448276 ], [ 29.783476835443039, -1.466138620689655 ], [ 29.783207341772151, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26095, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.466138620689655 ], [ 29.783476835443039, -1.466408103448276 ], [ 29.784015822784809, -1.466408103448276 ], [ 29.784015822784809, -1.466138620689655 ], [ 29.783476835443039, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26096, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.466138620689655 ], [ 29.784015822784809, -1.466408103448276 ], [ 29.784285316455698, -1.466408103448276 ], [ 29.784285316455698, -1.466138620689655 ], [ 29.784015822784809, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26097, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.466138620689655 ], [ 29.784285316455698, -1.466677586206897 ], [ 29.784554810126583, -1.466677586206897 ], [ 29.784554810126583, -1.466138620689655 ], [ 29.784285316455698, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26098, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.466138620689655 ], [ 29.784824303797468, -1.466408103448276 ], [ 29.785093797468356, -1.466408103448276 ], [ 29.785093797468356, -1.466138620689655 ], [ 29.784824303797468, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26099, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.466138620689655 ], [ 29.785093797468356, -1.466677586206897 ], [ 29.785363291139241, -1.466677586206897 ], [ 29.785363291139241, -1.466138620689655 ], [ 29.785093797468356, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26100, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.466138620689655 ], [ 29.785632784810126, -1.466408103448276 ], [ 29.785902278481014, -1.466408103448276 ], [ 29.785902278481014, -1.466138620689655 ], [ 29.785632784810126, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26101, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.466138620689655 ], [ 29.785902278481014, -1.466408103448276 ], [ 29.786171772151899, -1.466408103448276 ], [ 29.786171772151899, -1.466138620689655 ], [ 29.785902278481014, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26102, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.466138620689655 ], [ 29.786441265822784, -1.466408103448276 ], [ 29.786710759493673, -1.466408103448276 ], [ 29.786710759493673, -1.466138620689655 ], [ 29.786441265822784, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26103, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.466138620689655 ], [ 29.786710759493673, -1.466408103448276 ], [ 29.786980253164558, -1.466408103448276 ], [ 29.786980253164558, -1.466138620689655 ], [ 29.786710759493673, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26104, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.466138620689655 ], [ 29.786980253164558, -1.466408103448276 ], [ 29.787249746835442, -1.466408103448276 ], [ 29.787249746835442, -1.466138620689655 ], [ 29.786980253164558, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26105, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.466138620689655 ], [ 29.787249746835442, -1.466408103448276 ], [ 29.787519240506327, -1.466408103448276 ], [ 29.787519240506327, -1.466138620689655 ], [ 29.787249746835442, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26106, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.466138620689655 ], [ 29.787519240506327, -1.466947068965517 ], [ 29.787788734177216, -1.466947068965517 ], [ 29.787788734177216, -1.466138620689655 ], [ 29.787519240506327, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26107, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.466138620689655 ], [ 29.787788734177216, -1.467216551724138 ], [ 29.788058227848101, -1.467216551724138 ], [ 29.788058227848101, -1.466138620689655 ], [ 29.787788734177216, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26108, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.466138620689655 ], [ 29.788058227848101, -1.467216551724138 ], [ 29.788327721518986, -1.467216551724138 ], [ 29.788327721518986, -1.466138620689655 ], [ 29.788058227848101, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26109, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.466138620689655 ], [ 29.798298987341774, -1.466408103448276 ], [ 29.798568481012659, -1.466408103448276 ], [ 29.798568481012659, -1.466138620689655 ], [ 29.798298987341774, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26110, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.466138620689655 ], [ 29.798568481012659, -1.466408103448276 ], [ 29.798837974683543, -1.466408103448276 ], [ 29.798837974683543, -1.466138620689655 ], [ 29.798568481012659, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26111, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.466138620689655 ], [ 29.798837974683543, -1.466408103448276 ], [ 29.799107468354432, -1.466408103448276 ], [ 29.799107468354432, -1.466138620689655 ], [ 29.798837974683543, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26112, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.466138620689655 ], [ 29.799107468354432, -1.466408103448276 ], [ 29.799376962025317, -1.466408103448276 ], [ 29.799376962025317, -1.466138620689655 ], [ 29.799107468354432, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26113, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.466138620689655 ], [ 29.799376962025317, -1.466408103448276 ], [ 29.799646455696202, -1.466408103448276 ], [ 29.799646455696202, -1.466138620689655 ], [ 29.799376962025317, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26114, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.466138620689655 ], [ 29.799646455696202, -1.466408103448276 ], [ 29.79991594936709, -1.466408103448276 ], [ 29.79991594936709, -1.466138620689655 ], [ 29.799646455696202, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26115, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.466138620689655 ], [ 29.79991594936709, -1.466408103448276 ], [ 29.800185443037975, -1.466408103448276 ], [ 29.800185443037975, -1.466138620689655 ], [ 29.79991594936709, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26116, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.466138620689655 ], [ 29.800185443037975, -1.466408103448276 ], [ 29.80045493670886, -1.466408103448276 ], [ 29.80045493670886, -1.466138620689655 ], [ 29.800185443037975, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26117, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.466138620689655 ], [ 29.80045493670886, -1.466408103448276 ], [ 29.800724430379748, -1.466408103448276 ], [ 29.800724430379748, -1.466138620689655 ], [ 29.80045493670886, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26118, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.466138620689655 ], [ 29.800724430379748, -1.466408103448276 ], [ 29.800993924050633, -1.466408103448276 ], [ 29.800993924050633, -1.466138620689655 ], [ 29.800724430379748, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26119, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.466138620689655 ], [ 29.800993924050633, -1.466408103448276 ], [ 29.801263417721518, -1.466408103448276 ], [ 29.801263417721518, -1.466138620689655 ], [ 29.800993924050633, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.466138620689655 ], [ 29.801263417721518, -1.466408103448276 ], [ 29.801532911392407, -1.466408103448276 ], [ 29.801532911392407, -1.466138620689655 ], [ 29.801263417721518, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.466138620689655 ], [ 29.801532911392407, -1.466408103448276 ], [ 29.802071898734177, -1.466408103448276 ], [ 29.802071898734177, -1.466138620689655 ], [ 29.801532911392407, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26122, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.466138620689655 ], [ 29.802071898734177, -1.466408103448276 ], [ 29.802341392405065, -1.466408103448276 ], [ 29.802341392405065, -1.466138620689655 ], [ 29.802071898734177, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26123, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.466138620689655 ], [ 29.802341392405065, -1.466677586206897 ], [ 29.80261088607595, -1.466677586206897 ], [ 29.80261088607595, -1.466138620689655 ], [ 29.802341392405065, -1.466138620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26124, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.466408103448276 ], [ 29.755449493670884, -1.466677586206897 ], [ 29.755718987341773, -1.466677586206897 ], [ 29.755718987341773, -1.466408103448276 ], [ 29.755449493670884, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26125, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.466408103448276 ], [ 29.755718987341773, -1.466677586206897 ], [ 29.755988481012658, -1.466677586206897 ], [ 29.755988481012658, -1.466408103448276 ], [ 29.755718987341773, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26126, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.466408103448276 ], [ 29.755988481012658, -1.466677586206897 ], [ 29.756257974683542, -1.466677586206897 ], [ 29.756257974683542, -1.466408103448276 ], [ 29.755988481012658, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26127, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.466408103448276 ], [ 29.756257974683542, -1.467216551724138 ], [ 29.756796962025316, -1.467216551724138 ], [ 29.756796962025316, -1.466408103448276 ], [ 29.756257974683542, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26128, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.466408103448276 ], [ 29.756796962025316, -1.467216551724138 ], [ 29.757066455696201, -1.467216551724138 ], [ 29.757066455696201, -1.466408103448276 ], [ 29.756796962025316, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26129, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.466408103448276 ], [ 29.757335949367089, -1.466947068965517 ], [ 29.757605443037974, -1.466947068965517 ], [ 29.757605443037974, -1.466408103448276 ], [ 29.757335949367089, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26130, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.466408103448276 ], [ 29.757605443037974, -1.466947068965517 ], [ 29.757874936708859, -1.466947068965517 ], [ 29.757874936708859, -1.466408103448276 ], [ 29.757605443037974, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26131, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.466408103448276 ], [ 29.758144430379748, -1.466677586206897 ], [ 29.758413924050632, -1.466677586206897 ], [ 29.758413924050632, -1.466408103448276 ], [ 29.758144430379748, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26132, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.466408103448276 ], [ 29.758952911392406, -1.466677586206897 ], [ 29.759222405063291, -1.466677586206897 ], [ 29.759222405063291, -1.466408103448276 ], [ 29.758952911392406, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26133, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.466408103448276 ], [ 29.759222405063291, -1.466677586206897 ], [ 29.759491898734176, -1.466677586206897 ], [ 29.759491898734176, -1.466408103448276 ], [ 29.759222405063291, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26134, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.466408103448276 ], [ 29.759761392405064, -1.466677586206897 ], [ 29.760030886075949, -1.466677586206897 ], [ 29.760030886075949, -1.466408103448276 ], [ 29.759761392405064, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26135, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.466408103448276 ], [ 29.760030886075949, -1.466677586206897 ], [ 29.760300379746834, -1.466677586206897 ], [ 29.760300379746834, -1.466408103448276 ], [ 29.760030886075949, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26136, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.466408103448276 ], [ 29.760300379746834, -1.466677586206897 ], [ 29.760569873417722, -1.466677586206897 ], [ 29.760569873417722, -1.466408103448276 ], [ 29.760300379746834, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26137, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.466408103448276 ], [ 29.760839367088607, -1.466677586206897 ], [ 29.761108860759492, -1.466677586206897 ], [ 29.761108860759492, -1.466408103448276 ], [ 29.760839367088607, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26138, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.466408103448276 ], [ 29.777547974683543, -1.466677586206897 ], [ 29.777817468354431, -1.466677586206897 ], [ 29.777817468354431, -1.466408103448276 ], [ 29.777547974683543, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26139, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.466408103448276 ], [ 29.777817468354431, -1.466677586206897 ], [ 29.778086962025316, -1.466677586206897 ], [ 29.778086962025316, -1.466408103448276 ], [ 29.777817468354431, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26140, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.466408103448276 ], [ 29.778086962025316, -1.466677586206897 ], [ 29.778356455696201, -1.466677586206897 ], [ 29.778356455696201, -1.466408103448276 ], [ 29.778086962025316, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26141, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.466408103448276 ], [ 29.778356455696201, -1.466677586206897 ], [ 29.77862594936709, -1.466677586206897 ], [ 29.77862594936709, -1.466408103448276 ], [ 29.778356455696201, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26142, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.466408103448276 ], [ 29.77862594936709, -1.466677586206897 ], [ 29.778895443037975, -1.466677586206897 ], [ 29.778895443037975, -1.466408103448276 ], [ 29.77862594936709, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26143, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.466408103448276 ], [ 29.778895443037975, -1.466677586206897 ], [ 29.77916493670886, -1.466677586206897 ], [ 29.77916493670886, -1.466408103448276 ], [ 29.778895443037975, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26144, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.466408103448276 ], [ 29.77916493670886, -1.466677586206897 ], [ 29.779434430379748, -1.466677586206897 ], [ 29.779434430379748, -1.466408103448276 ], [ 29.77916493670886, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26145, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.466408103448276 ], [ 29.779434430379748, -1.466677586206897 ], [ 29.779703924050633, -1.466677586206897 ], [ 29.779703924050633, -1.466408103448276 ], [ 29.779434430379748, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26146, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.466408103448276 ], [ 29.779703924050633, -1.466677586206897 ], [ 29.779973417721518, -1.466677586206897 ], [ 29.779973417721518, -1.466408103448276 ], [ 29.779703924050633, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26147, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.466408103448276 ], [ 29.779973417721518, -1.466677586206897 ], [ 29.780242911392406, -1.466677586206897 ], [ 29.780242911392406, -1.466408103448276 ], [ 29.779973417721518, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26148, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.466408103448276 ], [ 29.780242911392406, -1.466677586206897 ], [ 29.780512405063291, -1.466677586206897 ], [ 29.780512405063291, -1.466408103448276 ], [ 29.780242911392406, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26149, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.466408103448276 ], [ 29.780512405063291, -1.466677586206897 ], [ 29.780781898734176, -1.466677586206897 ], [ 29.780781898734176, -1.466408103448276 ], [ 29.780512405063291, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26150, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.466408103448276 ], [ 29.780781898734176, -1.466677586206897 ], [ 29.781051392405065, -1.466677586206897 ], [ 29.781051392405065, -1.466408103448276 ], [ 29.780781898734176, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26151, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.466408103448276 ], [ 29.781051392405065, -1.466677586206897 ], [ 29.78132088607595, -1.466677586206897 ], [ 29.78132088607595, -1.466408103448276 ], [ 29.781051392405065, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26152, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.466408103448276 ], [ 29.78132088607595, -1.466677586206897 ], [ 29.781590379746834, -1.466677586206897 ], [ 29.781590379746834, -1.466408103448276 ], [ 29.78132088607595, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26153, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.466408103448276 ], [ 29.781590379746834, -1.466677586206897 ], [ 29.781859873417723, -1.466677586206897 ], [ 29.781859873417723, -1.466408103448276 ], [ 29.781590379746834, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26154, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.466408103448276 ], [ 29.781859873417723, -1.466677586206897 ], [ 29.782129367088608, -1.466677586206897 ], [ 29.782129367088608, -1.466408103448276 ], [ 29.781859873417723, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26155, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.466408103448276 ], [ 29.782129367088608, -1.466677586206897 ], [ 29.782398860759493, -1.466677586206897 ], [ 29.782398860759493, -1.466408103448276 ], [ 29.782129367088608, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26156, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.466408103448276 ], [ 29.782398860759493, -1.466677586206897 ], [ 29.782668354430381, -1.466677586206897 ], [ 29.782668354430381, -1.466408103448276 ], [ 29.782398860759493, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26157, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.466408103448276 ], [ 29.782668354430381, -1.466677586206897 ], [ 29.782937848101266, -1.466677586206897 ], [ 29.782937848101266, -1.466408103448276 ], [ 29.782668354430381, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26158, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.466408103448276 ], [ 29.782937848101266, -1.466947068965517 ], [ 29.783207341772151, -1.466947068965517 ], [ 29.783207341772151, -1.466408103448276 ], [ 29.782937848101266, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26159, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.466408103448276 ], [ 29.783207341772151, -1.466677586206897 ], [ 29.783476835443039, -1.466677586206897 ], [ 29.783476835443039, -1.466408103448276 ], [ 29.783207341772151, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26160, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.466408103448276 ], [ 29.783476835443039, -1.466677586206897 ], [ 29.784015822784809, -1.466677586206897 ], [ 29.784015822784809, -1.466408103448276 ], [ 29.783476835443039, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26161, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.466408103448276 ], [ 29.784015822784809, -1.466677586206897 ], [ 29.784285316455698, -1.466677586206897 ], [ 29.784285316455698, -1.466408103448276 ], [ 29.784015822784809, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26162, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.466408103448276 ], [ 29.784554810126583, -1.466677586206897 ], [ 29.784824303797468, -1.466677586206897 ], [ 29.784824303797468, -1.466408103448276 ], [ 29.784554810126583, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26163, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.466408103448276 ], [ 29.784824303797468, -1.466677586206897 ], [ 29.785093797468356, -1.466677586206897 ], [ 29.785093797468356, -1.466408103448276 ], [ 29.784824303797468, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26164, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.466408103448276 ], [ 29.785363291139241, -1.466677586206897 ], [ 29.785632784810126, -1.466677586206897 ], [ 29.785632784810126, -1.466408103448276 ], [ 29.785363291139241, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26165, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.466408103448276 ], [ 29.785632784810126, -1.466677586206897 ], [ 29.785902278481014, -1.466677586206897 ], [ 29.785902278481014, -1.466408103448276 ], [ 29.785632784810126, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26166, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.466408103448276 ], [ 29.785902278481014, -1.466677586206897 ], [ 29.786171772151899, -1.466677586206897 ], [ 29.786171772151899, -1.466408103448276 ], [ 29.785902278481014, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26167, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.466408103448276 ], [ 29.786171772151899, -1.466947068965517 ], [ 29.786441265822784, -1.466947068965517 ], [ 29.786441265822784, -1.466408103448276 ], [ 29.786171772151899, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26168, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.466408103448276 ], [ 29.786441265822784, -1.466677586206897 ], [ 29.786710759493673, -1.466677586206897 ], [ 29.786710759493673, -1.466408103448276 ], [ 29.786441265822784, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26169, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.466408103448276 ], [ 29.786710759493673, -1.466677586206897 ], [ 29.786980253164558, -1.466677586206897 ], [ 29.786980253164558, -1.466408103448276 ], [ 29.786710759493673, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26170, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.466408103448276 ], [ 29.786980253164558, -1.467216551724138 ], [ 29.787249746835442, -1.467216551724138 ], [ 29.787249746835442, -1.466408103448276 ], [ 29.786980253164558, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26171, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.466408103448276 ], [ 29.787249746835442, -1.467216551724138 ], [ 29.787519240506327, -1.467216551724138 ], [ 29.787519240506327, -1.466408103448276 ], [ 29.787249746835442, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26172, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.466408103448276 ], [ 29.798029493670885, -1.466947068965517 ], [ 29.798298987341774, -1.466947068965517 ], [ 29.798298987341774, -1.466408103448276 ], [ 29.798029493670885, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26173, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.466408103448276 ], [ 29.798298987341774, -1.466677586206897 ], [ 29.798568481012659, -1.466677586206897 ], [ 29.798568481012659, -1.466408103448276 ], [ 29.798298987341774, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26174, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.466408103448276 ], [ 29.798568481012659, -1.466677586206897 ], [ 29.798837974683543, -1.466677586206897 ], [ 29.798837974683543, -1.466408103448276 ], [ 29.798568481012659, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26175, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.466408103448276 ], [ 29.798837974683543, -1.466677586206897 ], [ 29.799107468354432, -1.466677586206897 ], [ 29.799107468354432, -1.466408103448276 ], [ 29.798837974683543, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26176, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.466408103448276 ], [ 29.799107468354432, -1.466677586206897 ], [ 29.799376962025317, -1.466677586206897 ], [ 29.799376962025317, -1.466408103448276 ], [ 29.799107468354432, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26177, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.466408103448276 ], [ 29.799376962025317, -1.466677586206897 ], [ 29.799646455696202, -1.466677586206897 ], [ 29.799646455696202, -1.466408103448276 ], [ 29.799376962025317, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26178, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.466408103448276 ], [ 29.799646455696202, -1.466677586206897 ], [ 29.79991594936709, -1.466677586206897 ], [ 29.79991594936709, -1.466408103448276 ], [ 29.799646455696202, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26179, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.466408103448276 ], [ 29.79991594936709, -1.466677586206897 ], [ 29.800185443037975, -1.466677586206897 ], [ 29.800185443037975, -1.466408103448276 ], [ 29.79991594936709, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26180, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.466408103448276 ], [ 29.800185443037975, -1.466677586206897 ], [ 29.80045493670886, -1.466677586206897 ], [ 29.80045493670886, -1.466408103448276 ], [ 29.800185443037975, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26181, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.466408103448276 ], [ 29.80045493670886, -1.466677586206897 ], [ 29.800724430379748, -1.466677586206897 ], [ 29.800724430379748, -1.466408103448276 ], [ 29.80045493670886, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26182, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.466408103448276 ], [ 29.800724430379748, -1.466677586206897 ], [ 29.800993924050633, -1.466677586206897 ], [ 29.800993924050633, -1.466408103448276 ], [ 29.800724430379748, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26183, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.466408103448276 ], [ 29.800993924050633, -1.466677586206897 ], [ 29.801263417721518, -1.466677586206897 ], [ 29.801263417721518, -1.466408103448276 ], [ 29.800993924050633, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26184, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.466408103448276 ], [ 29.801263417721518, -1.466677586206897 ], [ 29.801532911392407, -1.466677586206897 ], [ 29.801532911392407, -1.466408103448276 ], [ 29.801263417721518, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26185, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.466408103448276 ], [ 29.801532911392407, -1.466677586206897 ], [ 29.802071898734177, -1.466677586206897 ], [ 29.802071898734177, -1.466408103448276 ], [ 29.801532911392407, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26186, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.466408103448276 ], [ 29.802071898734177, -1.466677586206897 ], [ 29.802341392405065, -1.466677586206897 ], [ 29.802341392405065, -1.466408103448276 ], [ 29.802071898734177, -1.466408103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26187, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.466677586206897 ], [ 29.755718987341773, -1.467486034482759 ], [ 29.755988481012658, -1.467486034482759 ], [ 29.755988481012658, -1.466677586206897 ], [ 29.755718987341773, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26188, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.466677586206897 ], [ 29.755988481012658, -1.467486034482759 ], [ 29.756257974683542, -1.467486034482759 ], [ 29.756257974683542, -1.466677586206897 ], [ 29.755988481012658, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26189, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.466677586206897 ], [ 29.757066455696201, -1.467216551724138 ], [ 29.757335949367089, -1.467216551724138 ], [ 29.757335949367089, -1.466677586206897 ], [ 29.757066455696201, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26190, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.466677586206897 ], [ 29.757874936708859, -1.466947068965517 ], [ 29.758144430379748, -1.466947068965517 ], [ 29.758144430379748, -1.466677586206897 ], [ 29.757874936708859, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26191, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.466677586206897 ], [ 29.758144430379748, -1.466947068965517 ], [ 29.758413924050632, -1.466947068965517 ], [ 29.758413924050632, -1.466677586206897 ], [ 29.758144430379748, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26192, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.466677586206897 ], [ 29.758413924050632, -1.466947068965517 ], [ 29.758683417721517, -1.466947068965517 ], [ 29.758683417721517, -1.466677586206897 ], [ 29.758413924050632, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26193, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.466677586206897 ], [ 29.758683417721517, -1.466947068965517 ], [ 29.758952911392406, -1.466947068965517 ], [ 29.758952911392406, -1.466677586206897 ], [ 29.758683417721517, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26194, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.466677586206897 ], [ 29.758952911392406, -1.466947068965517 ], [ 29.759222405063291, -1.466947068965517 ], [ 29.759222405063291, -1.466677586206897 ], [ 29.758952911392406, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26195, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.466677586206897 ], [ 29.759222405063291, -1.466947068965517 ], [ 29.759491898734176, -1.466947068965517 ], [ 29.759491898734176, -1.466677586206897 ], [ 29.759222405063291, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26196, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.466677586206897 ], [ 29.759491898734176, -1.467216551724138 ], [ 29.759761392405064, -1.467216551724138 ], [ 29.759761392405064, -1.466677586206897 ], [ 29.759491898734176, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26197, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.466677586206897 ], [ 29.759761392405064, -1.466947068965517 ], [ 29.760030886075949, -1.466947068965517 ], [ 29.760030886075949, -1.466677586206897 ], [ 29.759761392405064, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26198, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.466677586206897 ], [ 29.760030886075949, -1.466947068965517 ], [ 29.760300379746834, -1.466947068965517 ], [ 29.760300379746834, -1.466677586206897 ], [ 29.760030886075949, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26199, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.466677586206897 ], [ 29.760300379746834, -1.467216551724138 ], [ 29.760569873417722, -1.467216551724138 ], [ 29.760569873417722, -1.466677586206897 ], [ 29.760300379746834, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26200, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.466677586206897 ], [ 29.760569873417722, -1.466947068965517 ], [ 29.760839367088607, -1.466947068965517 ], [ 29.760839367088607, -1.466677586206897 ], [ 29.760569873417722, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26201, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.466677586206897 ], [ 29.760839367088607, -1.466947068965517 ], [ 29.761108860759492, -1.466947068965517 ], [ 29.761108860759492, -1.466677586206897 ], [ 29.760839367088607, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26202, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.466677586206897 ], [ 29.763534303797467, -1.467216551724138 ], [ 29.763803797468356, -1.467216551724138 ], [ 29.763803797468356, -1.466677586206897 ], [ 29.763534303797467, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26203, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.466677586206897 ], [ 29.764881772151899, -1.467216551724138 ], [ 29.765151265822784, -1.467216551724138 ], [ 29.765151265822784, -1.466677586206897 ], [ 29.764881772151899, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26204, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.466677586206897 ], [ 29.765959746835442, -1.466947068965517 ], [ 29.766229240506327, -1.466947068965517 ], [ 29.766229240506327, -1.466677586206897 ], [ 29.765959746835442, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26205, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.466677586206897 ], [ 29.766229240506327, -1.466947068965517 ], [ 29.766498734177215, -1.466947068965517 ], [ 29.766498734177215, -1.466677586206897 ], [ 29.766229240506327, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26206, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.466677586206897 ], [ 29.766498734177215, -1.466947068965517 ], [ 29.7667682278481, -1.466947068965517 ], [ 29.7667682278481, -1.466677586206897 ], [ 29.766498734177215, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26207, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.466677586206897 ], [ 29.7667682278481, -1.467486034482759 ], [ 29.767037721518985, -1.467486034482759 ], [ 29.767037721518985, -1.466677586206897 ], [ 29.7667682278481, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26208, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.466677586206897 ], [ 29.770271645569618, -1.466947068965517 ], [ 29.770541139240507, -1.466947068965517 ], [ 29.770541139240507, -1.466677586206897 ], [ 29.770271645569618, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26209, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.466677586206897 ], [ 29.770541139240507, -1.466947068965517 ], [ 29.770810632911392, -1.466947068965517 ], [ 29.770810632911392, -1.466677586206897 ], [ 29.770541139240507, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26210, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.466677586206897 ], [ 29.770810632911392, -1.466947068965517 ], [ 29.771080126582277, -1.466947068965517 ], [ 29.771080126582277, -1.466677586206897 ], [ 29.770810632911392, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26211, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.466677586206897 ], [ 29.772966582278482, -1.467486034482759 ], [ 29.773236075949367, -1.467486034482759 ], [ 29.773236075949367, -1.466677586206897 ], [ 29.772966582278482, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26212, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.466677586206897 ], [ 29.776739493670885, -1.466947068965517 ], [ 29.777008987341773, -1.466947068965517 ], [ 29.777008987341773, -1.466677586206897 ], [ 29.776739493670885, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26213, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.466677586206897 ], [ 29.777008987341773, -1.466947068965517 ], [ 29.777278481012658, -1.466947068965517 ], [ 29.777278481012658, -1.466677586206897 ], [ 29.777008987341773, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26214, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.466677586206897 ], [ 29.777278481012658, -1.466947068965517 ], [ 29.777547974683543, -1.466947068965517 ], [ 29.777547974683543, -1.466677586206897 ], [ 29.777278481012658, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26215, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.466677586206897 ], [ 29.777547974683543, -1.466947068965517 ], [ 29.777817468354431, -1.466947068965517 ], [ 29.777817468354431, -1.466677586206897 ], [ 29.777547974683543, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26216, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.466677586206897 ], [ 29.777817468354431, -1.466947068965517 ], [ 29.778086962025316, -1.466947068965517 ], [ 29.778086962025316, -1.466677586206897 ], [ 29.777817468354431, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26217, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.466677586206897 ], [ 29.778086962025316, -1.466947068965517 ], [ 29.778356455696201, -1.466947068965517 ], [ 29.778356455696201, -1.466677586206897 ], [ 29.778086962025316, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26218, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.466677586206897 ], [ 29.778356455696201, -1.466947068965517 ], [ 29.77862594936709, -1.466947068965517 ], [ 29.77862594936709, -1.466677586206897 ], [ 29.778356455696201, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26219, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.466677586206897 ], [ 29.77862594936709, -1.466947068965517 ], [ 29.778895443037975, -1.466947068965517 ], [ 29.778895443037975, -1.466677586206897 ], [ 29.77862594936709, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26220, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.466677586206897 ], [ 29.778895443037975, -1.466947068965517 ], [ 29.77916493670886, -1.466947068965517 ], [ 29.77916493670886, -1.466677586206897 ], [ 29.778895443037975, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26221, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.466677586206897 ], [ 29.77916493670886, -1.466947068965517 ], [ 29.779434430379748, -1.466947068965517 ], [ 29.779434430379748, -1.466677586206897 ], [ 29.77916493670886, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26222, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.466677586206897 ], [ 29.779434430379748, -1.466947068965517 ], [ 29.779703924050633, -1.466947068965517 ], [ 29.779703924050633, -1.466677586206897 ], [ 29.779434430379748, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26223, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.466677586206897 ], [ 29.779703924050633, -1.466947068965517 ], [ 29.779973417721518, -1.466947068965517 ], [ 29.779973417721518, -1.466677586206897 ], [ 29.779703924050633, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26224, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.466677586206897 ], [ 29.779973417721518, -1.466947068965517 ], [ 29.780242911392406, -1.466947068965517 ], [ 29.780242911392406, -1.466677586206897 ], [ 29.779973417721518, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26225, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.466677586206897 ], [ 29.780242911392406, -1.466947068965517 ], [ 29.780512405063291, -1.466947068965517 ], [ 29.780512405063291, -1.466677586206897 ], [ 29.780242911392406, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26226, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.466677586206897 ], [ 29.780512405063291, -1.466947068965517 ], [ 29.780781898734176, -1.466947068965517 ], [ 29.780781898734176, -1.466677586206897 ], [ 29.780512405063291, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26227, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.466677586206897 ], [ 29.780781898734176, -1.466947068965517 ], [ 29.781051392405065, -1.466947068965517 ], [ 29.781051392405065, -1.466677586206897 ], [ 29.780781898734176, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26228, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.466677586206897 ], [ 29.781051392405065, -1.466947068965517 ], [ 29.78132088607595, -1.466947068965517 ], [ 29.78132088607595, -1.466677586206897 ], [ 29.781051392405065, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26229, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.466677586206897 ], [ 29.78132088607595, -1.466947068965517 ], [ 29.781590379746834, -1.466947068965517 ], [ 29.781590379746834, -1.466677586206897 ], [ 29.78132088607595, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26230, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.466677586206897 ], [ 29.781590379746834, -1.466947068965517 ], [ 29.781859873417723, -1.466947068965517 ], [ 29.781859873417723, -1.466677586206897 ], [ 29.781590379746834, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26231, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.466677586206897 ], [ 29.781859873417723, -1.466947068965517 ], [ 29.782129367088608, -1.466947068965517 ], [ 29.782129367088608, -1.466677586206897 ], [ 29.781859873417723, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26232, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.466677586206897 ], [ 29.782129367088608, -1.466947068965517 ], [ 29.782398860759493, -1.466947068965517 ], [ 29.782398860759493, -1.466677586206897 ], [ 29.782129367088608, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26233, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.466677586206897 ], [ 29.782398860759493, -1.466947068965517 ], [ 29.782668354430381, -1.466947068965517 ], [ 29.782668354430381, -1.466677586206897 ], [ 29.782398860759493, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26234, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.466677586206897 ], [ 29.782668354430381, -1.466947068965517 ], [ 29.782937848101266, -1.466947068965517 ], [ 29.782937848101266, -1.466677586206897 ], [ 29.782668354430381, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26235, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.466677586206897 ], [ 29.783207341772151, -1.466947068965517 ], [ 29.783476835443039, -1.466947068965517 ], [ 29.783476835443039, -1.466677586206897 ], [ 29.783207341772151, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26236, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.466677586206897 ], [ 29.783476835443039, -1.466947068965517 ], [ 29.784015822784809, -1.466947068965517 ], [ 29.784015822784809, -1.466677586206897 ], [ 29.783476835443039, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26237, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.466677586206897 ], [ 29.784015822784809, -1.467216551724138 ], [ 29.784285316455698, -1.467216551724138 ], [ 29.784285316455698, -1.466677586206897 ], [ 29.784015822784809, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26238, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.466677586206897 ], [ 29.784285316455698, -1.466947068965517 ], [ 29.784554810126583, -1.466947068965517 ], [ 29.784554810126583, -1.466677586206897 ], [ 29.784285316455698, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26239, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.466677586206897 ], [ 29.784554810126583, -1.466947068965517 ], [ 29.784824303797468, -1.466947068965517 ], [ 29.784824303797468, -1.466677586206897 ], [ 29.784554810126583, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26240, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.466677586206897 ], [ 29.784824303797468, -1.467216551724138 ], [ 29.785093797468356, -1.467216551724138 ], [ 29.785093797468356, -1.466677586206897 ], [ 29.784824303797468, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26241, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.466677586206897 ], [ 29.785093797468356, -1.467486034482759 ], [ 29.785363291139241, -1.467486034482759 ], [ 29.785363291139241, -1.466677586206897 ], [ 29.785093797468356, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26242, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.466677586206897 ], [ 29.785363291139241, -1.466947068965517 ], [ 29.785632784810126, -1.466947068965517 ], [ 29.785632784810126, -1.466677586206897 ], [ 29.785363291139241, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26243, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.466677586206897 ], [ 29.785632784810126, -1.467216551724138 ], [ 29.785902278481014, -1.467216551724138 ], [ 29.785902278481014, -1.466677586206897 ], [ 29.785632784810126, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26244, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.466677586206897 ], [ 29.785902278481014, -1.467486034482759 ], [ 29.786171772151899, -1.467486034482759 ], [ 29.786171772151899, -1.466677586206897 ], [ 29.785902278481014, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26245, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.466677586206897 ], [ 29.786441265822784, -1.467216551724138 ], [ 29.786710759493673, -1.467216551724138 ], [ 29.786710759493673, -1.466677586206897 ], [ 29.786441265822784, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26246, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.466677586206897 ], [ 29.786710759493673, -1.467486034482759 ], [ 29.786980253164558, -1.467486034482759 ], [ 29.786980253164558, -1.466677586206897 ], [ 29.786710759493673, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26247, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.466677586206897 ], [ 29.796682025316457, -1.466947068965517 ], [ 29.796951518987342, -1.466947068965517 ], [ 29.796951518987342, -1.466677586206897 ], [ 29.796682025316457, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26248, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.466677586206897 ], [ 29.796951518987342, -1.466947068965517 ], [ 29.797221012658227, -1.466947068965517 ], [ 29.797221012658227, -1.466677586206897 ], [ 29.796951518987342, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26249, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.466677586206897 ], [ 29.797490506329115, -1.466947068965517 ], [ 29.79776, -1.466947068965517 ], [ 29.79776, -1.466677586206897 ], [ 29.797490506329115, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26250, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.466677586206897 ], [ 29.79776, -1.466947068965517 ], [ 29.798029493670885, -1.466947068965517 ], [ 29.798029493670885, -1.466677586206897 ], [ 29.79776, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26251, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.466677586206897 ], [ 29.798298987341774, -1.466947068965517 ], [ 29.798568481012659, -1.466947068965517 ], [ 29.798568481012659, -1.466677586206897 ], [ 29.798298987341774, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26252, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.466677586206897 ], [ 29.798568481012659, -1.466947068965517 ], [ 29.798837974683543, -1.466947068965517 ], [ 29.798837974683543, -1.466677586206897 ], [ 29.798568481012659, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26253, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.466677586206897 ], [ 29.798837974683543, -1.466947068965517 ], [ 29.799107468354432, -1.466947068965517 ], [ 29.799107468354432, -1.466677586206897 ], [ 29.798837974683543, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26254, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.466677586206897 ], [ 29.799107468354432, -1.466947068965517 ], [ 29.799376962025317, -1.466947068965517 ], [ 29.799376962025317, -1.466677586206897 ], [ 29.799107468354432, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26255, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.466677586206897 ], [ 29.799376962025317, -1.467216551724138 ], [ 29.799646455696202, -1.467216551724138 ], [ 29.799646455696202, -1.466677586206897 ], [ 29.799376962025317, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26256, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.466677586206897 ], [ 29.799646455696202, -1.467216551724138 ], [ 29.79991594936709, -1.467216551724138 ], [ 29.79991594936709, -1.466677586206897 ], [ 29.799646455696202, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26257, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.466677586206897 ], [ 29.79991594936709, -1.467216551724138 ], [ 29.800185443037975, -1.467216551724138 ], [ 29.800185443037975, -1.466677586206897 ], [ 29.79991594936709, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26258, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.466677586206897 ], [ 29.800185443037975, -1.467216551724138 ], [ 29.80045493670886, -1.467216551724138 ], [ 29.80045493670886, -1.466677586206897 ], [ 29.800185443037975, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26259, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.466677586206897 ], [ 29.80045493670886, -1.466947068965517 ], [ 29.800724430379748, -1.466947068965517 ], [ 29.800724430379748, -1.466677586206897 ], [ 29.80045493670886, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26260, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.466677586206897 ], [ 29.800724430379748, -1.466947068965517 ], [ 29.800993924050633, -1.466947068965517 ], [ 29.800993924050633, -1.466677586206897 ], [ 29.800724430379748, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26261, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.466677586206897 ], [ 29.800993924050633, -1.466947068965517 ], [ 29.801263417721518, -1.466947068965517 ], [ 29.801263417721518, -1.466677586206897 ], [ 29.800993924050633, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26262, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.466677586206897 ], [ 29.801263417721518, -1.466947068965517 ], [ 29.801532911392407, -1.466947068965517 ], [ 29.801532911392407, -1.466677586206897 ], [ 29.801263417721518, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26263, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.466677586206897 ], [ 29.801532911392407, -1.466947068965517 ], [ 29.802071898734177, -1.466947068965517 ], [ 29.802071898734177, -1.466677586206897 ], [ 29.801532911392407, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26264, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.466677586206897 ], [ 29.802071898734177, -1.466947068965517 ], [ 29.802341392405065, -1.466947068965517 ], [ 29.802341392405065, -1.466677586206897 ], [ 29.802071898734177, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26265, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.466677586206897 ], [ 29.802341392405065, -1.466947068965517 ], [ 29.80261088607595, -1.466947068965517 ], [ 29.80261088607595, -1.466677586206897 ], [ 29.802341392405065, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26266, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.466677586206897 ], [ 29.80261088607595, -1.466947068965517 ], [ 29.802880379746835, -1.466947068965517 ], [ 29.802880379746835, -1.466677586206897 ], [ 29.80261088607595, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26267, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.466947068965517 ], [ 29.757335949367089, -1.467216551724138 ], [ 29.757605443037974, -1.467216551724138 ], [ 29.757605443037974, -1.466947068965517 ], [ 29.757335949367089, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26268, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.466947068965517 ], [ 29.757605443037974, -1.467216551724138 ], [ 29.757874936708859, -1.467216551724138 ], [ 29.757874936708859, -1.466947068965517 ], [ 29.757605443037974, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26269, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.466947068965517 ], [ 29.757874936708859, -1.467216551724138 ], [ 29.758144430379748, -1.467216551724138 ], [ 29.758144430379748, -1.466947068965517 ], [ 29.757874936708859, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26270, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.466947068965517 ], [ 29.758144430379748, -1.467216551724138 ], [ 29.758413924050632, -1.467216551724138 ], [ 29.758413924050632, -1.466947068965517 ], [ 29.758144430379748, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26271, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.466947068965517 ], [ 29.758413924050632, -1.467486034482759 ], [ 29.758683417721517, -1.467486034482759 ], [ 29.758683417721517, -1.466947068965517 ], [ 29.758413924050632, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26272, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.466947068965517 ], [ 29.758683417721517, -1.467216551724138 ], [ 29.758952911392406, -1.467216551724138 ], [ 29.758952911392406, -1.466947068965517 ], [ 29.758683417721517, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26273, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.466947068965517 ], [ 29.758952911392406, -1.467216551724138 ], [ 29.759222405063291, -1.467216551724138 ], [ 29.759222405063291, -1.466947068965517 ], [ 29.758952911392406, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26274, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.466947068965517 ], [ 29.759222405063291, -1.467486034482759 ], [ 29.759491898734176, -1.467486034482759 ], [ 29.759491898734176, -1.466947068965517 ], [ 29.759222405063291, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26275, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.466947068965517 ], [ 29.759761392405064, -1.467216551724138 ], [ 29.760030886075949, -1.467216551724138 ], [ 29.760030886075949, -1.466947068965517 ], [ 29.759761392405064, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26276, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.466947068965517 ], [ 29.760030886075949, -1.467486034482759 ], [ 29.760300379746834, -1.467486034482759 ], [ 29.760300379746834, -1.466947068965517 ], [ 29.760030886075949, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26277, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.466947068965517 ], [ 29.760569873417722, -1.467486034482759 ], [ 29.760839367088607, -1.467486034482759 ], [ 29.760839367088607, -1.466947068965517 ], [ 29.760569873417722, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26278, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.466947068965517 ], [ 29.760839367088607, -1.467486034482759 ], [ 29.761108860759492, -1.467486034482759 ], [ 29.761108860759492, -1.466947068965517 ], [ 29.760839367088607, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26279, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.466947068965517 ], [ 29.764342784810125, -1.467216551724138 ], [ 29.764612278481014, -1.467216551724138 ], [ 29.764612278481014, -1.466947068965517 ], [ 29.764342784810125, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26280, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.466947068965517 ], [ 29.764612278481014, -1.467216551724138 ], [ 29.764881772151899, -1.467216551724138 ], [ 29.764881772151899, -1.466947068965517 ], [ 29.764612278481014, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26281, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.466947068965517 ], [ 29.765151265822784, -1.467216551724138 ], [ 29.765420759493672, -1.467216551724138 ], [ 29.765420759493672, -1.466947068965517 ], [ 29.765151265822784, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26282, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.466947068965517 ], [ 29.765420759493672, -1.467216551724138 ], [ 29.765959746835442, -1.467216551724138 ], [ 29.765959746835442, -1.466947068965517 ], [ 29.765420759493672, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26283, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.466947068965517 ], [ 29.765959746835442, -1.467216551724138 ], [ 29.766229240506327, -1.467216551724138 ], [ 29.766229240506327, -1.466947068965517 ], [ 29.765959746835442, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26284, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.466947068965517 ], [ 29.766229240506327, -1.467486034482759 ], [ 29.766498734177215, -1.467486034482759 ], [ 29.766498734177215, -1.466947068965517 ], [ 29.766229240506327, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26285, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.466947068965517 ], [ 29.766498734177215, -1.467486034482759 ], [ 29.7667682278481, -1.467486034482759 ], [ 29.7667682278481, -1.466947068965517 ], [ 29.766498734177215, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26286, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.466947068965517 ], [ 29.770271645569618, -1.467486034482759 ], [ 29.770541139240507, -1.467486034482759 ], [ 29.770541139240507, -1.466947068965517 ], [ 29.770271645569618, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26287, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.466947068965517 ], [ 29.770541139240507, -1.467486034482759 ], [ 29.770810632911392, -1.467486034482759 ], [ 29.770810632911392, -1.466947068965517 ], [ 29.770541139240507, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26288, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.466947068965517 ], [ 29.770810632911392, -1.467486034482759 ], [ 29.771080126582277, -1.467486034482759 ], [ 29.771080126582277, -1.466947068965517 ], [ 29.770810632911392, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26289, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.466947068965517 ], [ 29.771080126582277, -1.467486034482759 ], [ 29.771349620253165, -1.467486034482759 ], [ 29.771349620253165, -1.466947068965517 ], [ 29.771080126582277, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26290, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.466947068965517 ], [ 29.771349620253165, -1.467486034482759 ], [ 29.77161911392405, -1.467486034482759 ], [ 29.77161911392405, -1.466947068965517 ], [ 29.771349620253165, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26291, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.466947068965517 ], [ 29.771888607594935, -1.467486034482759 ], [ 29.772158101265823, -1.467486034482759 ], [ 29.772158101265823, -1.466947068965517 ], [ 29.771888607594935, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26292, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.466947068965517 ], [ 29.772158101265823, -1.467486034482759 ], [ 29.772427594936708, -1.467486034482759 ], [ 29.772427594936708, -1.466947068965517 ], [ 29.772158101265823, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26293, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.466947068965517 ], [ 29.772697088607593, -1.467486034482759 ], [ 29.772966582278482, -1.467486034482759 ], [ 29.772966582278482, -1.466947068965517 ], [ 29.772697088607593, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26294, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.466947068965517 ], [ 29.773505569620252, -1.467216551724138 ], [ 29.77377506329114, -1.467216551724138 ], [ 29.77377506329114, -1.466947068965517 ], [ 29.773505569620252, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26295, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.466947068965517 ], [ 29.77431405063291, -1.467216551724138 ], [ 29.774853037974683, -1.467216551724138 ], [ 29.774853037974683, -1.466947068965517 ], [ 29.77431405063291, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26296, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.466947068965517 ], [ 29.775392025316457, -1.467216551724138 ], [ 29.775661518987341, -1.467216551724138 ], [ 29.775661518987341, -1.466947068965517 ], [ 29.775392025316457, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26297, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.466947068965517 ], [ 29.775661518987341, -1.467216551724138 ], [ 29.775931012658226, -1.467216551724138 ], [ 29.775931012658226, -1.466947068965517 ], [ 29.775661518987341, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26298, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.466947068965517 ], [ 29.775931012658226, -1.467216551724138 ], [ 29.776200506329115, -1.467216551724138 ], [ 29.776200506329115, -1.466947068965517 ], [ 29.775931012658226, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26299, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.466947068965517 ], [ 29.776200506329115, -1.467216551724138 ], [ 29.77647, -1.467216551724138 ], [ 29.77647, -1.466947068965517 ], [ 29.776200506329115, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26300, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.466947068965517 ], [ 29.77647, -1.467216551724138 ], [ 29.776739493670885, -1.467216551724138 ], [ 29.776739493670885, -1.466947068965517 ], [ 29.77647, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26301, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.466947068965517 ], [ 29.776739493670885, -1.467216551724138 ], [ 29.777008987341773, -1.467216551724138 ], [ 29.777008987341773, -1.466947068965517 ], [ 29.776739493670885, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26302, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.466947068965517 ], [ 29.777008987341773, -1.467216551724138 ], [ 29.777278481012658, -1.467216551724138 ], [ 29.777278481012658, -1.466947068965517 ], [ 29.777008987341773, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26303, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.466947068965517 ], [ 29.777278481012658, -1.467216551724138 ], [ 29.777547974683543, -1.467216551724138 ], [ 29.777547974683543, -1.466947068965517 ], [ 29.777278481012658, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26304, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.466947068965517 ], [ 29.777547974683543, -1.467216551724138 ], [ 29.777817468354431, -1.467216551724138 ], [ 29.777817468354431, -1.466947068965517 ], [ 29.777547974683543, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26305, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.466947068965517 ], [ 29.777817468354431, -1.467216551724138 ], [ 29.778086962025316, -1.467216551724138 ], [ 29.778086962025316, -1.466947068965517 ], [ 29.777817468354431, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26306, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.466947068965517 ], [ 29.778086962025316, -1.467216551724138 ], [ 29.778356455696201, -1.467216551724138 ], [ 29.778356455696201, -1.466947068965517 ], [ 29.778086962025316, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26307, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.466947068965517 ], [ 29.778356455696201, -1.467216551724138 ], [ 29.77862594936709, -1.467216551724138 ], [ 29.77862594936709, -1.466947068965517 ], [ 29.778356455696201, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26308, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.466947068965517 ], [ 29.77862594936709, -1.467216551724138 ], [ 29.778895443037975, -1.467216551724138 ], [ 29.778895443037975, -1.466947068965517 ], [ 29.77862594936709, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26309, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.466947068965517 ], [ 29.778895443037975, -1.467216551724138 ], [ 29.77916493670886, -1.467216551724138 ], [ 29.77916493670886, -1.466947068965517 ], [ 29.778895443037975, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26310, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.466947068965517 ], [ 29.77916493670886, -1.467216551724138 ], [ 29.779434430379748, -1.467216551724138 ], [ 29.779434430379748, -1.466947068965517 ], [ 29.77916493670886, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26311, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.466947068965517 ], [ 29.779434430379748, -1.467216551724138 ], [ 29.779973417721518, -1.467216551724138 ], [ 29.779973417721518, -1.466947068965517 ], [ 29.779434430379748, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26312, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.466947068965517 ], [ 29.779973417721518, -1.467216551724138 ], [ 29.780242911392406, -1.467216551724138 ], [ 29.780242911392406, -1.466947068965517 ], [ 29.779973417721518, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26313, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.466947068965517 ], [ 29.780242911392406, -1.467216551724138 ], [ 29.780512405063291, -1.467216551724138 ], [ 29.780512405063291, -1.466947068965517 ], [ 29.780242911392406, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26314, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.466947068965517 ], [ 29.780512405063291, -1.467216551724138 ], [ 29.780781898734176, -1.467216551724138 ], [ 29.780781898734176, -1.466947068965517 ], [ 29.780512405063291, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26315, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.466947068965517 ], [ 29.780781898734176, -1.467216551724138 ], [ 29.781051392405065, -1.467216551724138 ], [ 29.781051392405065, -1.466947068965517 ], [ 29.780781898734176, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26316, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.466947068965517 ], [ 29.781051392405065, -1.467216551724138 ], [ 29.78132088607595, -1.467216551724138 ], [ 29.78132088607595, -1.466947068965517 ], [ 29.781051392405065, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26317, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.466947068965517 ], [ 29.78132088607595, -1.467216551724138 ], [ 29.781590379746834, -1.467216551724138 ], [ 29.781590379746834, -1.466947068965517 ], [ 29.78132088607595, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26318, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.466947068965517 ], [ 29.781590379746834, -1.467216551724138 ], [ 29.781859873417723, -1.467216551724138 ], [ 29.781859873417723, -1.466947068965517 ], [ 29.781590379746834, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26319, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.466947068965517 ], [ 29.781859873417723, -1.467216551724138 ], [ 29.782129367088608, -1.467216551724138 ], [ 29.782129367088608, -1.466947068965517 ], [ 29.781859873417723, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26320, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.466947068965517 ], [ 29.782129367088608, -1.467216551724138 ], [ 29.782398860759493, -1.467216551724138 ], [ 29.782398860759493, -1.466947068965517 ], [ 29.782129367088608, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26321, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.466947068965517 ], [ 29.782398860759493, -1.467216551724138 ], [ 29.782668354430381, -1.467216551724138 ], [ 29.782668354430381, -1.466947068965517 ], [ 29.782398860759493, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26322, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.466947068965517 ], [ 29.782668354430381, -1.467486034482759 ], [ 29.782937848101266, -1.467486034482759 ], [ 29.782937848101266, -1.466947068965517 ], [ 29.782668354430381, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26323, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.466947068965517 ], [ 29.782937848101266, -1.467216551724138 ], [ 29.783207341772151, -1.467216551724138 ], [ 29.783207341772151, -1.466947068965517 ], [ 29.782937848101266, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26324, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.466947068965517 ], [ 29.783207341772151, -1.467216551724138 ], [ 29.783476835443039, -1.467216551724138 ], [ 29.783476835443039, -1.466947068965517 ], [ 29.783207341772151, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26325, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.466947068965517 ], [ 29.783476835443039, -1.467486034482759 ], [ 29.784015822784809, -1.467486034482759 ], [ 29.784015822784809, -1.466947068965517 ], [ 29.783476835443039, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26326, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.466947068965517 ], [ 29.784285316455698, -1.467216551724138 ], [ 29.784554810126583, -1.467216551724138 ], [ 29.784554810126583, -1.466947068965517 ], [ 29.784285316455698, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26327, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.466947068965517 ], [ 29.784554810126583, -1.467486034482759 ], [ 29.784824303797468, -1.467486034482759 ], [ 29.784824303797468, -1.466947068965517 ], [ 29.784554810126583, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26328, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.466947068965517 ], [ 29.785363291139241, -1.467486034482759 ], [ 29.785632784810126, -1.467486034482759 ], [ 29.785632784810126, -1.466947068965517 ], [ 29.785363291139241, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26329, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.466947068965517 ], [ 29.786171772151899, -1.467486034482759 ], [ 29.786441265822784, -1.467486034482759 ], [ 29.786441265822784, -1.466947068965517 ], [ 29.786171772151899, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26330, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.466947068965517 ], [ 29.787519240506327, -1.467486034482759 ], [ 29.787788734177216, -1.467486034482759 ], [ 29.787788734177216, -1.466947068965517 ], [ 29.787519240506327, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26331, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.466947068965517 ], [ 29.788327721518986, -1.467216551724138 ], [ 29.788597215189874, -1.467216551724138 ], [ 29.788597215189874, -1.466947068965517 ], [ 29.788327721518986, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26332, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.466947068965517 ], [ 29.788597215189874, -1.467216551724138 ], [ 29.788866708860759, -1.467216551724138 ], [ 29.788866708860759, -1.466947068965517 ], [ 29.788597215189874, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26333, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.466947068965517 ], [ 29.788866708860759, -1.467216551724138 ], [ 29.789136202531644, -1.467216551724138 ], [ 29.789136202531644, -1.466947068965517 ], [ 29.788866708860759, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26334, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.466947068965517 ], [ 29.796143037974684, -1.467216551724138 ], [ 29.796412531645569, -1.467216551724138 ], [ 29.796412531645569, -1.466947068965517 ], [ 29.796143037974684, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26335, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.466947068965517 ], [ 29.796412531645569, -1.467216551724138 ], [ 29.796682025316457, -1.467216551724138 ], [ 29.796682025316457, -1.466947068965517 ], [ 29.796412531645569, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26336, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.466947068965517 ], [ 29.796682025316457, -1.467216551724138 ], [ 29.796951518987342, -1.467216551724138 ], [ 29.796951518987342, -1.466947068965517 ], [ 29.796682025316457, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26337, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.466947068965517 ], [ 29.796951518987342, -1.467216551724138 ], [ 29.797221012658227, -1.467216551724138 ], [ 29.797221012658227, -1.466947068965517 ], [ 29.796951518987342, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26338, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.466947068965517 ], [ 29.797221012658227, -1.467216551724138 ], [ 29.797490506329115, -1.467216551724138 ], [ 29.797490506329115, -1.466947068965517 ], [ 29.797221012658227, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26339, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.466947068965517 ], [ 29.797490506329115, -1.467216551724138 ], [ 29.79776, -1.467216551724138 ], [ 29.79776, -1.466947068965517 ], [ 29.797490506329115, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26340, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.466947068965517 ], [ 29.79776, -1.467216551724138 ], [ 29.798029493670885, -1.467216551724138 ], [ 29.798029493670885, -1.466947068965517 ], [ 29.79776, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26341, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.466947068965517 ], [ 29.798029493670885, -1.467216551724138 ], [ 29.798298987341774, -1.467216551724138 ], [ 29.798298987341774, -1.466947068965517 ], [ 29.798029493670885, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26342, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.466947068965517 ], [ 29.798298987341774, -1.467216551724138 ], [ 29.798568481012659, -1.467216551724138 ], [ 29.798568481012659, -1.466947068965517 ], [ 29.798298987341774, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26343, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.466947068965517 ], [ 29.798568481012659, -1.467216551724138 ], [ 29.798837974683543, -1.467216551724138 ], [ 29.798837974683543, -1.466947068965517 ], [ 29.798568481012659, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26344, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.466947068965517 ], [ 29.798837974683543, -1.467216551724138 ], [ 29.799107468354432, -1.467216551724138 ], [ 29.799107468354432, -1.466947068965517 ], [ 29.798837974683543, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26345, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.466947068965517 ], [ 29.799107468354432, -1.467216551724138 ], [ 29.799376962025317, -1.467216551724138 ], [ 29.799376962025317, -1.466947068965517 ], [ 29.799107468354432, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26346, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.466947068965517 ], [ 29.80045493670886, -1.467216551724138 ], [ 29.800724430379748, -1.467216551724138 ], [ 29.800724430379748, -1.466947068965517 ], [ 29.80045493670886, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26347, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.466947068965517 ], [ 29.800724430379748, -1.467216551724138 ], [ 29.800993924050633, -1.467216551724138 ], [ 29.800993924050633, -1.466947068965517 ], [ 29.800724430379748, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26348, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.466947068965517 ], [ 29.800993924050633, -1.467216551724138 ], [ 29.801263417721518, -1.467216551724138 ], [ 29.801263417721518, -1.466947068965517 ], [ 29.800993924050633, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26349, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.466947068965517 ], [ 29.801263417721518, -1.467216551724138 ], [ 29.801532911392407, -1.467216551724138 ], [ 29.801532911392407, -1.466947068965517 ], [ 29.801263417721518, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26350, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.466947068965517 ], [ 29.801532911392407, -1.467216551724138 ], [ 29.802071898734177, -1.467216551724138 ], [ 29.802071898734177, -1.466947068965517 ], [ 29.801532911392407, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26351, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.466947068965517 ], [ 29.802071898734177, -1.467216551724138 ], [ 29.802341392405065, -1.467216551724138 ], [ 29.802341392405065, -1.466947068965517 ], [ 29.802071898734177, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26352, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.466947068965517 ], [ 29.802341392405065, -1.467486034482759 ], [ 29.80261088607595, -1.467486034482759 ], [ 29.80261088607595, -1.466947068965517 ], [ 29.802341392405065, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26353, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.467216551724138 ], [ 29.756257974683542, -1.467486034482759 ], [ 29.756796962025316, -1.467486034482759 ], [ 29.756796962025316, -1.467216551724138 ], [ 29.756257974683542, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26354, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.467216551724138 ], [ 29.756796962025316, -1.467486034482759 ], [ 29.757066455696201, -1.467486034482759 ], [ 29.757066455696201, -1.467216551724138 ], [ 29.756796962025316, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26355, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.467216551724138 ], [ 29.757066455696201, -1.467486034482759 ], [ 29.757335949367089, -1.467486034482759 ], [ 29.757335949367089, -1.467216551724138 ], [ 29.757066455696201, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26356, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.467216551724138 ], [ 29.757605443037974, -1.467486034482759 ], [ 29.757874936708859, -1.467486034482759 ], [ 29.757874936708859, -1.467216551724138 ], [ 29.757605443037974, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26357, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.467216551724138 ], [ 29.757874936708859, -1.467486034482759 ], [ 29.758144430379748, -1.467486034482759 ], [ 29.758144430379748, -1.467216551724138 ], [ 29.757874936708859, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26358, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.467216551724138 ], [ 29.773505569620252, -1.467486034482759 ], [ 29.77377506329114, -1.467486034482759 ], [ 29.77377506329114, -1.467216551724138 ], [ 29.773505569620252, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26359, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.467216551724138 ], [ 29.77377506329114, -1.467486034482759 ], [ 29.774044556962025, -1.467486034482759 ], [ 29.774044556962025, -1.467216551724138 ], [ 29.77377506329114, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26360, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.467216551724138 ], [ 29.77431405063291, -1.467486034482759 ], [ 29.774853037974683, -1.467486034482759 ], [ 29.774853037974683, -1.467216551724138 ], [ 29.77431405063291, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26361, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.467216551724138 ], [ 29.774853037974683, -1.467486034482759 ], [ 29.775122531645568, -1.467486034482759 ], [ 29.775122531645568, -1.467216551724138 ], [ 29.774853037974683, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26362, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.467216551724138 ], [ 29.775122531645568, -1.467486034482759 ], [ 29.775392025316457, -1.467486034482759 ], [ 29.775392025316457, -1.467216551724138 ], [ 29.775122531645568, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26363, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.467216551724138 ], [ 29.775392025316457, -1.467486034482759 ], [ 29.775661518987341, -1.467486034482759 ], [ 29.775661518987341, -1.467216551724138 ], [ 29.775392025316457, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26364, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.467216551724138 ], [ 29.775661518987341, -1.467486034482759 ], [ 29.775931012658226, -1.467486034482759 ], [ 29.775931012658226, -1.467216551724138 ], [ 29.775661518987341, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26365, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.467216551724138 ], [ 29.775931012658226, -1.467486034482759 ], [ 29.776200506329115, -1.467486034482759 ], [ 29.776200506329115, -1.467216551724138 ], [ 29.775931012658226, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26366, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.467216551724138 ], [ 29.776200506329115, -1.467486034482759 ], [ 29.77647, -1.467486034482759 ], [ 29.77647, -1.467216551724138 ], [ 29.776200506329115, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26367, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.467216551724138 ], [ 29.77647, -1.467486034482759 ], [ 29.776739493670885, -1.467486034482759 ], [ 29.776739493670885, -1.467216551724138 ], [ 29.77647, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26368, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.467216551724138 ], [ 29.776739493670885, -1.467486034482759 ], [ 29.777008987341773, -1.467486034482759 ], [ 29.777008987341773, -1.467216551724138 ], [ 29.776739493670885, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26369, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.467216551724138 ], [ 29.777008987341773, -1.467486034482759 ], [ 29.777278481012658, -1.467486034482759 ], [ 29.777278481012658, -1.467216551724138 ], [ 29.777008987341773, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26370, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.467216551724138 ], [ 29.777278481012658, -1.467486034482759 ], [ 29.777547974683543, -1.467486034482759 ], [ 29.777547974683543, -1.467216551724138 ], [ 29.777278481012658, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26371, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.467216551724138 ], [ 29.777547974683543, -1.467486034482759 ], [ 29.777817468354431, -1.467486034482759 ], [ 29.777817468354431, -1.467216551724138 ], [ 29.777547974683543, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26372, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.467216551724138 ], [ 29.777817468354431, -1.467486034482759 ], [ 29.778086962025316, -1.467486034482759 ], [ 29.778086962025316, -1.467216551724138 ], [ 29.777817468354431, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26373, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.467216551724138 ], [ 29.778086962025316, -1.467486034482759 ], [ 29.778356455696201, -1.467486034482759 ], [ 29.778356455696201, -1.467216551724138 ], [ 29.778086962025316, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26374, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.467216551724138 ], [ 29.778356455696201, -1.467486034482759 ], [ 29.77862594936709, -1.467486034482759 ], [ 29.77862594936709, -1.467216551724138 ], [ 29.778356455696201, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26375, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.467216551724138 ], [ 29.77862594936709, -1.467486034482759 ], [ 29.778895443037975, -1.467486034482759 ], [ 29.778895443037975, -1.467216551724138 ], [ 29.77862594936709, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26376, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.467216551724138 ], [ 29.778895443037975, -1.467486034482759 ], [ 29.77916493670886, -1.467486034482759 ], [ 29.77916493670886, -1.467216551724138 ], [ 29.778895443037975, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26377, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.467216551724138 ], [ 29.779434430379748, -1.467486034482759 ], [ 29.779703924050633, -1.467486034482759 ], [ 29.779703924050633, -1.467216551724138 ], [ 29.779434430379748, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26378, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.467216551724138 ], [ 29.779703924050633, -1.467486034482759 ], [ 29.779973417721518, -1.467486034482759 ], [ 29.779973417721518, -1.467216551724138 ], [ 29.779703924050633, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26379, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.467216551724138 ], [ 29.779973417721518, -1.467486034482759 ], [ 29.780242911392406, -1.467486034482759 ], [ 29.780242911392406, -1.467216551724138 ], [ 29.779973417721518, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26380, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.467216551724138 ], [ 29.780242911392406, -1.467486034482759 ], [ 29.780512405063291, -1.467486034482759 ], [ 29.780512405063291, -1.467216551724138 ], [ 29.780242911392406, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26381, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.467216551724138 ], [ 29.780512405063291, -1.467486034482759 ], [ 29.780781898734176, -1.467486034482759 ], [ 29.780781898734176, -1.467216551724138 ], [ 29.780512405063291, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26382, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.467216551724138 ], [ 29.780781898734176, -1.467486034482759 ], [ 29.781051392405065, -1.467486034482759 ], [ 29.781051392405065, -1.467216551724138 ], [ 29.780781898734176, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26383, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.467216551724138 ], [ 29.781051392405065, -1.467486034482759 ], [ 29.78132088607595, -1.467486034482759 ], [ 29.78132088607595, -1.467216551724138 ], [ 29.781051392405065, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26384, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.467216551724138 ], [ 29.78132088607595, -1.467486034482759 ], [ 29.781590379746834, -1.467486034482759 ], [ 29.781590379746834, -1.467216551724138 ], [ 29.78132088607595, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26385, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.467216551724138 ], [ 29.781859873417723, -1.467486034482759 ], [ 29.782129367088608, -1.467486034482759 ], [ 29.782129367088608, -1.467216551724138 ], [ 29.781859873417723, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26386, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.467216551724138 ], [ 29.782129367088608, -1.467486034482759 ], [ 29.782398860759493, -1.467486034482759 ], [ 29.782398860759493, -1.467216551724138 ], [ 29.782129367088608, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26387, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.467216551724138 ], [ 29.786980253164558, -1.467486034482759 ], [ 29.787249746835442, -1.467486034482759 ], [ 29.787249746835442, -1.467216551724138 ], [ 29.786980253164558, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26388, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.467216551724138 ], [ 29.787249746835442, -1.467486034482759 ], [ 29.787519240506327, -1.467486034482759 ], [ 29.787519240506327, -1.467216551724138 ], [ 29.787249746835442, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26389, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.467216551724138 ], [ 29.787788734177216, -1.467486034482759 ], [ 29.788058227848101, -1.467486034482759 ], [ 29.788058227848101, -1.467216551724138 ], [ 29.787788734177216, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26390, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.467216551724138 ], [ 29.788058227848101, -1.467486034482759 ], [ 29.788327721518986, -1.467486034482759 ], [ 29.788327721518986, -1.467216551724138 ], [ 29.788058227848101, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26391, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.467216551724138 ], [ 29.788327721518986, -1.467486034482759 ], [ 29.788597215189874, -1.467486034482759 ], [ 29.788597215189874, -1.467216551724138 ], [ 29.788327721518986, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26392, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.467216551724138 ], [ 29.795334556962025, -1.467486034482759 ], [ 29.79560405063291, -1.467486034482759 ], [ 29.79560405063291, -1.467216551724138 ], [ 29.795334556962025, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26393, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.467216551724138 ], [ 29.79560405063291, -1.467486034482759 ], [ 29.795873544303799, -1.467486034482759 ], [ 29.795873544303799, -1.467216551724138 ], [ 29.79560405063291, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26394, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.467216551724138 ], [ 29.795873544303799, -1.467486034482759 ], [ 29.796143037974684, -1.467486034482759 ], [ 29.796143037974684, -1.467216551724138 ], [ 29.795873544303799, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26395, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.467216551724138 ], [ 29.796143037974684, -1.467486034482759 ], [ 29.796412531645569, -1.467486034482759 ], [ 29.796412531645569, -1.467216551724138 ], [ 29.796143037974684, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26396, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.467216551724138 ], [ 29.796412531645569, -1.467486034482759 ], [ 29.796682025316457, -1.467486034482759 ], [ 29.796682025316457, -1.467216551724138 ], [ 29.796412531645569, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26397, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.467216551724138 ], [ 29.796682025316457, -1.467486034482759 ], [ 29.796951518987342, -1.467486034482759 ], [ 29.796951518987342, -1.467216551724138 ], [ 29.796682025316457, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26398, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.467216551724138 ], [ 29.796951518987342, -1.467486034482759 ], [ 29.797221012658227, -1.467486034482759 ], [ 29.797221012658227, -1.467216551724138 ], [ 29.796951518987342, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26399, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.467216551724138 ], [ 29.797221012658227, -1.467486034482759 ], [ 29.797490506329115, -1.467486034482759 ], [ 29.797490506329115, -1.467216551724138 ], [ 29.797221012658227, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26400, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.467216551724138 ], [ 29.797490506329115, -1.467486034482759 ], [ 29.79776, -1.467486034482759 ], [ 29.79776, -1.467216551724138 ], [ 29.797490506329115, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26401, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.467216551724138 ], [ 29.79776, -1.467486034482759 ], [ 29.798029493670885, -1.467486034482759 ], [ 29.798029493670885, -1.467216551724138 ], [ 29.79776, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26402, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.467216551724138 ], [ 29.798029493670885, -1.467486034482759 ], [ 29.798298987341774, -1.467486034482759 ], [ 29.798298987341774, -1.467216551724138 ], [ 29.798029493670885, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26403, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.467216551724138 ], [ 29.798298987341774, -1.467486034482759 ], [ 29.798568481012659, -1.467486034482759 ], [ 29.798568481012659, -1.467216551724138 ], [ 29.798298987341774, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26404, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.467216551724138 ], [ 29.798568481012659, -1.467486034482759 ], [ 29.798837974683543, -1.467486034482759 ], [ 29.798837974683543, -1.467216551724138 ], [ 29.798568481012659, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26405, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.467216551724138 ], [ 29.798837974683543, -1.467486034482759 ], [ 29.799107468354432, -1.467486034482759 ], [ 29.799107468354432, -1.467216551724138 ], [ 29.798837974683543, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26406, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.467216551724138 ], [ 29.799376962025317, -1.467486034482759 ], [ 29.799646455696202, -1.467486034482759 ], [ 29.799646455696202, -1.467216551724138 ], [ 29.799376962025317, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26407, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.467216551724138 ], [ 29.799646455696202, -1.467486034482759 ], [ 29.79991594936709, -1.467486034482759 ], [ 29.79991594936709, -1.467216551724138 ], [ 29.799646455696202, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26408, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.467216551724138 ], [ 29.79991594936709, -1.467486034482759 ], [ 29.800185443037975, -1.467486034482759 ], [ 29.800185443037975, -1.467216551724138 ], [ 29.79991594936709, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26409, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.467216551724138 ], [ 29.800185443037975, -1.467486034482759 ], [ 29.80045493670886, -1.467486034482759 ], [ 29.80045493670886, -1.467216551724138 ], [ 29.800185443037975, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26410, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.467216551724138 ], [ 29.80045493670886, -1.467486034482759 ], [ 29.800724430379748, -1.467486034482759 ], [ 29.800724430379748, -1.467216551724138 ], [ 29.80045493670886, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26411, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.467216551724138 ], [ 29.800724430379748, -1.467486034482759 ], [ 29.800993924050633, -1.467486034482759 ], [ 29.800993924050633, -1.467216551724138 ], [ 29.800724430379748, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26412, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.467216551724138 ], [ 29.800993924050633, -1.467486034482759 ], [ 29.801263417721518, -1.467486034482759 ], [ 29.801263417721518, -1.467216551724138 ], [ 29.800993924050633, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26413, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.467216551724138 ], [ 29.801263417721518, -1.467486034482759 ], [ 29.801532911392407, -1.467486034482759 ], [ 29.801532911392407, -1.467216551724138 ], [ 29.801263417721518, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26414, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.467216551724138 ], [ 29.801532911392407, -1.467486034482759 ], [ 29.802071898734177, -1.467486034482759 ], [ 29.802071898734177, -1.467216551724138 ], [ 29.801532911392407, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26415, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.467216551724138 ], [ 29.802071898734177, -1.467486034482759 ], [ 29.802341392405065, -1.467486034482759 ], [ 29.802341392405065, -1.467216551724138 ], [ 29.802071898734177, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26416, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794526075949367, -1.464521724137931 ], [ 29.794526075949367, -1.467755517241379 ], [ 29.794795569620252, -1.467755517241379 ], [ 29.794795569620252, -1.464521724137931 ], [ 29.794526075949367, -1.464521724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26417, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755449493670884, -1.466677586206897 ], [ 29.755449493670884, -1.467755517241379 ], [ 29.755718987341773, -1.467755517241379 ], [ 29.755718987341773, -1.466677586206897 ], [ 29.755449493670884, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26418, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.466677586206897 ], [ 29.770002151898733, -1.467755517241379 ], [ 29.770271645569618, -1.467755517241379 ], [ 29.770271645569618, -1.466677586206897 ], [ 29.770002151898733, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26419, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.466677586206897 ], [ 29.77161911392405, -1.467755517241379 ], [ 29.771888607594935, -1.467755517241379 ], [ 29.771888607594935, -1.466677586206897 ], [ 29.77161911392405, -1.466677586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26420, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.466947068965517 ], [ 29.761647848101266, -1.468025 ], [ 29.761917341772151, -1.468025 ], [ 29.761917341772151, -1.466947068965517 ], [ 29.761647848101266, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26421, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.466947068965517 ], [ 29.762186835443039, -1.468025 ], [ 29.762456329113924, -1.468025 ], [ 29.762456329113924, -1.466947068965517 ], [ 29.762186835443039, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26422, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.466947068965517 ], [ 29.762995316455697, -1.468025 ], [ 29.763264810126582, -1.468025 ], [ 29.763264810126582, -1.466947068965517 ], [ 29.762995316455697, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26423, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.466947068965517 ], [ 29.763803797468356, -1.468025 ], [ 29.76407329113924, -1.468025 ], [ 29.76407329113924, -1.466947068965517 ], [ 29.763803797468356, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26424, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.466947068965517 ], [ 29.789136202531644, -1.467755517241379 ], [ 29.789405696202532, -1.467755517241379 ], [ 29.789405696202532, -1.466947068965517 ], [ 29.789136202531644, -1.466947068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26425, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.467216551724138 ], [ 29.757335949367089, -1.467755517241379 ], [ 29.757605443037974, -1.467755517241379 ], [ 29.757605443037974, -1.467216551724138 ], [ 29.757335949367089, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26426, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.467216551724138 ], [ 29.758144430379748, -1.468025 ], [ 29.758413924050632, -1.468025 ], [ 29.758413924050632, -1.467216551724138 ], [ 29.758144430379748, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26427, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.467216551724138 ], [ 29.758683417721517, -1.467755517241379 ], [ 29.758952911392406, -1.467755517241379 ], [ 29.758952911392406, -1.467216551724138 ], [ 29.758683417721517, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26428, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.467216551724138 ], [ 29.758952911392406, -1.468025 ], [ 29.759222405063291, -1.468025 ], [ 29.759222405063291, -1.467216551724138 ], [ 29.758952911392406, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26429, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.467216551724138 ], [ 29.759491898734176, -1.467755517241379 ], [ 29.759761392405064, -1.467755517241379 ], [ 29.759761392405064, -1.467216551724138 ], [ 29.759491898734176, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26430, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.467216551724138 ], [ 29.759761392405064, -1.467755517241379 ], [ 29.760030886075949, -1.467755517241379 ], [ 29.760030886075949, -1.467216551724138 ], [ 29.759761392405064, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26431, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.467216551724138 ], [ 29.760300379746834, -1.467755517241379 ], [ 29.760569873417722, -1.467755517241379 ], [ 29.760569873417722, -1.467216551724138 ], [ 29.760300379746834, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26432, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.467216551724138 ], [ 29.761917341772151, -1.467755517241379 ], [ 29.762186835443039, -1.467755517241379 ], [ 29.762186835443039, -1.467216551724138 ], [ 29.761917341772151, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26433, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.467216551724138 ], [ 29.762456329113924, -1.467755517241379 ], [ 29.762725822784809, -1.467755517241379 ], [ 29.762725822784809, -1.467216551724138 ], [ 29.762456329113924, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26434, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.467216551724138 ], [ 29.762725822784809, -1.467755517241379 ], [ 29.762995316455697, -1.467755517241379 ], [ 29.762995316455697, -1.467216551724138 ], [ 29.762725822784809, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26435, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.467216551724138 ], [ 29.763264810126582, -1.467755517241379 ], [ 29.763534303797467, -1.467755517241379 ], [ 29.763534303797467, -1.467216551724138 ], [ 29.763264810126582, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26436, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.467216551724138 ], [ 29.763534303797467, -1.467755517241379 ], [ 29.763803797468356, -1.467755517241379 ], [ 29.763803797468356, -1.467216551724138 ], [ 29.763534303797467, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26437, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.467216551724138 ], [ 29.76407329113924, -1.467755517241379 ], [ 29.764342784810125, -1.467755517241379 ], [ 29.764342784810125, -1.467216551724138 ], [ 29.76407329113924, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26438, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.467216551724138 ], [ 29.764342784810125, -1.467755517241379 ], [ 29.764612278481014, -1.467755517241379 ], [ 29.764612278481014, -1.467216551724138 ], [ 29.764342784810125, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26439, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.467216551724138 ], [ 29.764612278481014, -1.467755517241379 ], [ 29.764881772151899, -1.467755517241379 ], [ 29.764881772151899, -1.467216551724138 ], [ 29.764612278481014, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26440, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.467216551724138 ], [ 29.764881772151899, -1.467755517241379 ], [ 29.765151265822784, -1.467755517241379 ], [ 29.765151265822784, -1.467216551724138 ], [ 29.764881772151899, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26441, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.467216551724138 ], [ 29.765151265822784, -1.467755517241379 ], [ 29.765420759493672, -1.467755517241379 ], [ 29.765420759493672, -1.467216551724138 ], [ 29.765151265822784, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26442, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.467216551724138 ], [ 29.765420759493672, -1.467755517241379 ], [ 29.765959746835442, -1.467755517241379 ], [ 29.765959746835442, -1.467216551724138 ], [ 29.765420759493672, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26443, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.467216551724138 ], [ 29.765959746835442, -1.467755517241379 ], [ 29.766229240506327, -1.467755517241379 ], [ 29.766229240506327, -1.467216551724138 ], [ 29.765959746835442, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26444, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.467216551724138 ], [ 29.77916493670886, -1.467755517241379 ], [ 29.779434430379748, -1.467755517241379 ], [ 29.779434430379748, -1.467216551724138 ], [ 29.77916493670886, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26445, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.467216551724138 ], [ 29.781590379746834, -1.467755517241379 ], [ 29.781859873417723, -1.467755517241379 ], [ 29.781859873417723, -1.467216551724138 ], [ 29.781590379746834, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26446, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.467216551724138 ], [ 29.782398860759493, -1.467755517241379 ], [ 29.782668354430381, -1.467755517241379 ], [ 29.782668354430381, -1.467216551724138 ], [ 29.782398860759493, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26447, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.467216551724138 ], [ 29.782937848101266, -1.467755517241379 ], [ 29.783207341772151, -1.467755517241379 ], [ 29.783207341772151, -1.467216551724138 ], [ 29.782937848101266, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26448, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.467216551724138 ], [ 29.783207341772151, -1.467755517241379 ], [ 29.783476835443039, -1.467755517241379 ], [ 29.783476835443039, -1.467216551724138 ], [ 29.783207341772151, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26449, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.467216551724138 ], [ 29.784015822784809, -1.467755517241379 ], [ 29.784285316455698, -1.467755517241379 ], [ 29.784285316455698, -1.467216551724138 ], [ 29.784015822784809, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26450, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.467216551724138 ], [ 29.784285316455698, -1.467755517241379 ], [ 29.784554810126583, -1.467755517241379 ], [ 29.784554810126583, -1.467216551724138 ], [ 29.784285316455698, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26451, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.467216551724138 ], [ 29.784824303797468, -1.467755517241379 ], [ 29.785093797468356, -1.467755517241379 ], [ 29.785093797468356, -1.467216551724138 ], [ 29.784824303797468, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26452, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.467216551724138 ], [ 29.785632784810126, -1.467755517241379 ], [ 29.785902278481014, -1.467755517241379 ], [ 29.785902278481014, -1.467216551724138 ], [ 29.785632784810126, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26453, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.467216551724138 ], [ 29.786441265822784, -1.467755517241379 ], [ 29.786710759493673, -1.467755517241379 ], [ 29.786710759493673, -1.467216551724138 ], [ 29.786441265822784, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26454, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.467216551724138 ], [ 29.788597215189874, -1.467755517241379 ], [ 29.788866708860759, -1.467755517241379 ], [ 29.788866708860759, -1.467216551724138 ], [ 29.788597215189874, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26455, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.467216551724138 ], [ 29.788866708860759, -1.467755517241379 ], [ 29.789136202531644, -1.467755517241379 ], [ 29.789136202531644, -1.467216551724138 ], [ 29.788866708860759, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26456, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.467216551724138 ], [ 29.799107468354432, -1.467755517241379 ], [ 29.799376962025317, -1.467755517241379 ], [ 29.799376962025317, -1.467216551724138 ], [ 29.799107468354432, -1.467216551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26457, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.467486034482759 ], [ 29.755718987341773, -1.467755517241379 ], [ 29.755988481012658, -1.467755517241379 ], [ 29.755988481012658, -1.467486034482759 ], [ 29.755718987341773, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26458, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.467486034482759 ], [ 29.755988481012658, -1.467755517241379 ], [ 29.756257974683542, -1.467755517241379 ], [ 29.756257974683542, -1.467486034482759 ], [ 29.755988481012658, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26459, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.467486034482759 ], [ 29.756257974683542, -1.467755517241379 ], [ 29.756796962025316, -1.467755517241379 ], [ 29.756796962025316, -1.467486034482759 ], [ 29.756257974683542, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26460, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.467486034482759 ], [ 29.756796962025316, -1.468563965517242 ], [ 29.757066455696201, -1.468563965517242 ], [ 29.757066455696201, -1.467486034482759 ], [ 29.756796962025316, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26461, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.467486034482759 ], [ 29.757066455696201, -1.468563965517242 ], [ 29.757335949367089, -1.468563965517242 ], [ 29.757335949367089, -1.467486034482759 ], [ 29.757066455696201, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26462, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.467486034482759 ], [ 29.757605443037974, -1.468294482758621 ], [ 29.757874936708859, -1.468294482758621 ], [ 29.757874936708859, -1.467486034482759 ], [ 29.757605443037974, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26463, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.467486034482759 ], [ 29.757874936708859, -1.468294482758621 ], [ 29.758144430379748, -1.468294482758621 ], [ 29.758144430379748, -1.467486034482759 ], [ 29.757874936708859, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26464, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.467486034482759 ], [ 29.758413924050632, -1.468025 ], [ 29.758683417721517, -1.468025 ], [ 29.758683417721517, -1.467486034482759 ], [ 29.758413924050632, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26465, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.467486034482759 ], [ 29.759222405063291, -1.468025 ], [ 29.759491898734176, -1.468025 ], [ 29.759491898734176, -1.467486034482759 ], [ 29.759222405063291, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26466, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.467486034482759 ], [ 29.760030886075949, -1.467755517241379 ], [ 29.760300379746834, -1.467755517241379 ], [ 29.760300379746834, -1.467486034482759 ], [ 29.760030886075949, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26467, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.467486034482759 ], [ 29.760569873417722, -1.467755517241379 ], [ 29.760839367088607, -1.467755517241379 ], [ 29.760839367088607, -1.467486034482759 ], [ 29.760569873417722, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26468, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.467486034482759 ], [ 29.760839367088607, -1.467755517241379 ], [ 29.761108860759492, -1.467755517241379 ], [ 29.761108860759492, -1.467486034482759 ], [ 29.760839367088607, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26469, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.467486034482759 ], [ 29.761108860759492, -1.467755517241379 ], [ 29.761378354430381, -1.467755517241379 ], [ 29.761378354430381, -1.467486034482759 ], [ 29.761108860759492, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26470, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.467486034482759 ], [ 29.766229240506327, -1.467755517241379 ], [ 29.766498734177215, -1.467755517241379 ], [ 29.766498734177215, -1.467486034482759 ], [ 29.766229240506327, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26471, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.467486034482759 ], [ 29.766498734177215, -1.467755517241379 ], [ 29.7667682278481, -1.467755517241379 ], [ 29.7667682278481, -1.467486034482759 ], [ 29.766498734177215, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.467486034482759 ], [ 29.7667682278481, -1.467755517241379 ], [ 29.767037721518985, -1.467755517241379 ], [ 29.767037721518985, -1.467486034482759 ], [ 29.7667682278481, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26473, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.467486034482759 ], [ 29.767037721518985, -1.467755517241379 ], [ 29.767307215189874, -1.467755517241379 ], [ 29.767307215189874, -1.467486034482759 ], [ 29.767037721518985, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26474, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.467486034482759 ], [ 29.770271645569618, -1.467755517241379 ], [ 29.770541139240507, -1.467755517241379 ], [ 29.770541139240507, -1.467486034482759 ], [ 29.770271645569618, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26475, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.467486034482759 ], [ 29.770541139240507, -1.467755517241379 ], [ 29.770810632911392, -1.467755517241379 ], [ 29.770810632911392, -1.467486034482759 ], [ 29.770541139240507, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26476, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.467486034482759 ], [ 29.770810632911392, -1.467755517241379 ], [ 29.771080126582277, -1.467755517241379 ], [ 29.771080126582277, -1.467486034482759 ], [ 29.770810632911392, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26477, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.467486034482759 ], [ 29.771080126582277, -1.468025 ], [ 29.771349620253165, -1.468025 ], [ 29.771349620253165, -1.467486034482759 ], [ 29.771080126582277, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26478, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.467486034482759 ], [ 29.771349620253165, -1.468025 ], [ 29.77161911392405, -1.468025 ], [ 29.77161911392405, -1.467486034482759 ], [ 29.771349620253165, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26479, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.467486034482759 ], [ 29.771888607594935, -1.467755517241379 ], [ 29.772158101265823, -1.467755517241379 ], [ 29.772158101265823, -1.467486034482759 ], [ 29.771888607594935, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26480, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.467486034482759 ], [ 29.772158101265823, -1.467755517241379 ], [ 29.772427594936708, -1.467755517241379 ], [ 29.772427594936708, -1.467486034482759 ], [ 29.772158101265823, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26481, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.467486034482759 ], [ 29.772427594936708, -1.467755517241379 ], [ 29.772697088607593, -1.467755517241379 ], [ 29.772697088607593, -1.467486034482759 ], [ 29.772427594936708, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26482, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.467486034482759 ], [ 29.772697088607593, -1.467755517241379 ], [ 29.772966582278482, -1.467755517241379 ], [ 29.772966582278482, -1.467486034482759 ], [ 29.772697088607593, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26483, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.467486034482759 ], [ 29.772966582278482, -1.467755517241379 ], [ 29.773236075949367, -1.467755517241379 ], [ 29.773236075949367, -1.467486034482759 ], [ 29.772966582278482, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26484, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.467486034482759 ], [ 29.773236075949367, -1.467755517241379 ], [ 29.773505569620252, -1.467755517241379 ], [ 29.773505569620252, -1.467486034482759 ], [ 29.773236075949367, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26485, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.467486034482759 ], [ 29.773505569620252, -1.467755517241379 ], [ 29.77377506329114, -1.467755517241379 ], [ 29.77377506329114, -1.467486034482759 ], [ 29.773505569620252, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26486, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.467486034482759 ], [ 29.77377506329114, -1.467755517241379 ], [ 29.774044556962025, -1.467755517241379 ], [ 29.774044556962025, -1.467486034482759 ], [ 29.77377506329114, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26487, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.467486034482759 ], [ 29.774044556962025, -1.467755517241379 ], [ 29.77431405063291, -1.467755517241379 ], [ 29.77431405063291, -1.467486034482759 ], [ 29.774044556962025, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26488, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.467486034482759 ], [ 29.77431405063291, -1.467755517241379 ], [ 29.774853037974683, -1.467755517241379 ], [ 29.774853037974683, -1.467486034482759 ], [ 29.77431405063291, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26489, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.467486034482759 ], [ 29.774853037974683, -1.467755517241379 ], [ 29.775122531645568, -1.467755517241379 ], [ 29.775122531645568, -1.467486034482759 ], [ 29.774853037974683, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26490, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.467486034482759 ], [ 29.775122531645568, -1.467755517241379 ], [ 29.775392025316457, -1.467755517241379 ], [ 29.775392025316457, -1.467486034482759 ], [ 29.775122531645568, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26491, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.467486034482759 ], [ 29.775392025316457, -1.467755517241379 ], [ 29.775661518987341, -1.467755517241379 ], [ 29.775661518987341, -1.467486034482759 ], [ 29.775392025316457, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26492, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.467486034482759 ], [ 29.775661518987341, -1.467755517241379 ], [ 29.775931012658226, -1.467755517241379 ], [ 29.775931012658226, -1.467486034482759 ], [ 29.775661518987341, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26493, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.467486034482759 ], [ 29.775931012658226, -1.467755517241379 ], [ 29.776200506329115, -1.467755517241379 ], [ 29.776200506329115, -1.467486034482759 ], [ 29.775931012658226, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26494, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.467486034482759 ], [ 29.776200506329115, -1.467755517241379 ], [ 29.77647, -1.467755517241379 ], [ 29.77647, -1.467486034482759 ], [ 29.776200506329115, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26495, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.467486034482759 ], [ 29.77647, -1.467755517241379 ], [ 29.776739493670885, -1.467755517241379 ], [ 29.776739493670885, -1.467486034482759 ], [ 29.77647, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26496, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.467486034482759 ], [ 29.776739493670885, -1.467755517241379 ], [ 29.777008987341773, -1.467755517241379 ], [ 29.777008987341773, -1.467486034482759 ], [ 29.776739493670885, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26497, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.467486034482759 ], [ 29.777008987341773, -1.467755517241379 ], [ 29.777278481012658, -1.467755517241379 ], [ 29.777278481012658, -1.467486034482759 ], [ 29.777008987341773, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26498, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.467486034482759 ], [ 29.777278481012658, -1.467755517241379 ], [ 29.777547974683543, -1.467755517241379 ], [ 29.777547974683543, -1.467486034482759 ], [ 29.777278481012658, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26499, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.467486034482759 ], [ 29.777547974683543, -1.467755517241379 ], [ 29.777817468354431, -1.467755517241379 ], [ 29.777817468354431, -1.467486034482759 ], [ 29.777547974683543, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26500, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.467486034482759 ], [ 29.777817468354431, -1.467755517241379 ], [ 29.778086962025316, -1.467755517241379 ], [ 29.778086962025316, -1.467486034482759 ], [ 29.777817468354431, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26501, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.467486034482759 ], [ 29.778086962025316, -1.468025 ], [ 29.778356455696201, -1.468025 ], [ 29.778356455696201, -1.467486034482759 ], [ 29.778086962025316, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26502, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.467486034482759 ], [ 29.778356455696201, -1.467755517241379 ], [ 29.77862594936709, -1.467755517241379 ], [ 29.77862594936709, -1.467486034482759 ], [ 29.778356455696201, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26503, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.467486034482759 ], [ 29.77862594936709, -1.467755517241379 ], [ 29.778895443037975, -1.467755517241379 ], [ 29.778895443037975, -1.467486034482759 ], [ 29.77862594936709, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26504, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.467486034482759 ], [ 29.778895443037975, -1.467755517241379 ], [ 29.77916493670886, -1.467755517241379 ], [ 29.77916493670886, -1.467486034482759 ], [ 29.778895443037975, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26505, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.467486034482759 ], [ 29.779434430379748, -1.467755517241379 ], [ 29.779703924050633, -1.467755517241379 ], [ 29.779703924050633, -1.467486034482759 ], [ 29.779434430379748, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26506, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.467486034482759 ], [ 29.779703924050633, -1.467755517241379 ], [ 29.779973417721518, -1.467755517241379 ], [ 29.779973417721518, -1.467486034482759 ], [ 29.779703924050633, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26507, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.467486034482759 ], [ 29.779973417721518, -1.467755517241379 ], [ 29.780242911392406, -1.467755517241379 ], [ 29.780242911392406, -1.467486034482759 ], [ 29.779973417721518, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26508, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.467486034482759 ], [ 29.780242911392406, -1.467755517241379 ], [ 29.780512405063291, -1.467755517241379 ], [ 29.780512405063291, -1.467486034482759 ], [ 29.780242911392406, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26509, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.467486034482759 ], [ 29.780512405063291, -1.468025 ], [ 29.780781898734176, -1.468025 ], [ 29.780781898734176, -1.467486034482759 ], [ 29.780512405063291, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26510, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.467486034482759 ], [ 29.780781898734176, -1.467755517241379 ], [ 29.781051392405065, -1.467755517241379 ], [ 29.781051392405065, -1.467486034482759 ], [ 29.780781898734176, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26511, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.467486034482759 ], [ 29.781051392405065, -1.467755517241379 ], [ 29.78132088607595, -1.467755517241379 ], [ 29.78132088607595, -1.467486034482759 ], [ 29.781051392405065, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26512, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.467486034482759 ], [ 29.78132088607595, -1.468025 ], [ 29.781590379746834, -1.468025 ], [ 29.781590379746834, -1.467486034482759 ], [ 29.78132088607595, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26513, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.467486034482759 ], [ 29.781859873417723, -1.468025 ], [ 29.782129367088608, -1.468025 ], [ 29.782129367088608, -1.467486034482759 ], [ 29.781859873417723, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26514, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.467486034482759 ], [ 29.782129367088608, -1.468025 ], [ 29.782398860759493, -1.468025 ], [ 29.782398860759493, -1.467486034482759 ], [ 29.782129367088608, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26515, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.467486034482759 ], [ 29.782668354430381, -1.468025 ], [ 29.782937848101266, -1.468025 ], [ 29.782937848101266, -1.467486034482759 ], [ 29.782668354430381, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26516, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.467486034482759 ], [ 29.783476835443039, -1.468025 ], [ 29.784015822784809, -1.468025 ], [ 29.784015822784809, -1.467486034482759 ], [ 29.783476835443039, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26517, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.467486034482759 ], [ 29.784554810126583, -1.468025 ], [ 29.784824303797468, -1.468025 ], [ 29.784824303797468, -1.467486034482759 ], [ 29.784554810126583, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26518, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.467486034482759 ], [ 29.785093797468356, -1.467755517241379 ], [ 29.785363291139241, -1.467755517241379 ], [ 29.785363291139241, -1.467486034482759 ], [ 29.785093797468356, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26519, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.467486034482759 ], [ 29.785363291139241, -1.468025 ], [ 29.785632784810126, -1.468025 ], [ 29.785632784810126, -1.467486034482759 ], [ 29.785363291139241, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26520, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.467486034482759 ], [ 29.785902278481014, -1.467755517241379 ], [ 29.786171772151899, -1.467755517241379 ], [ 29.786171772151899, -1.467486034482759 ], [ 29.785902278481014, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26521, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.467486034482759 ], [ 29.786171772151899, -1.467755517241379 ], [ 29.786441265822784, -1.467755517241379 ], [ 29.786441265822784, -1.467486034482759 ], [ 29.786171772151899, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26522, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.467486034482759 ], [ 29.786710759493673, -1.467755517241379 ], [ 29.786980253164558, -1.467755517241379 ], [ 29.786980253164558, -1.467486034482759 ], [ 29.786710759493673, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26523, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.467486034482759 ], [ 29.786980253164558, -1.467755517241379 ], [ 29.787249746835442, -1.467755517241379 ], [ 29.787249746835442, -1.467486034482759 ], [ 29.786980253164558, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26524, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.467486034482759 ], [ 29.787249746835442, -1.467755517241379 ], [ 29.787519240506327, -1.467755517241379 ], [ 29.787519240506327, -1.467486034482759 ], [ 29.787249746835442, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26525, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.467486034482759 ], [ 29.787519240506327, -1.467755517241379 ], [ 29.787788734177216, -1.467755517241379 ], [ 29.787788734177216, -1.467486034482759 ], [ 29.787519240506327, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26526, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.467486034482759 ], [ 29.787788734177216, -1.467755517241379 ], [ 29.788058227848101, -1.467755517241379 ], [ 29.788058227848101, -1.467486034482759 ], [ 29.787788734177216, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26527, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.467486034482759 ], [ 29.788058227848101, -1.468025 ], [ 29.788327721518986, -1.468025 ], [ 29.788327721518986, -1.467486034482759 ], [ 29.788058227848101, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26528, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.467486034482759 ], [ 29.788327721518986, -1.468025 ], [ 29.788597215189874, -1.468025 ], [ 29.788597215189874, -1.467486034482759 ], [ 29.788327721518986, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26529, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.467486034482759 ], [ 29.794795569620252, -1.467755517241379 ], [ 29.79506506329114, -1.467755517241379 ], [ 29.79506506329114, -1.467486034482759 ], [ 29.794795569620252, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26530, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.467486034482759 ], [ 29.79506506329114, -1.467755517241379 ], [ 29.795334556962025, -1.467755517241379 ], [ 29.795334556962025, -1.467486034482759 ], [ 29.79506506329114, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26531, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.467486034482759 ], [ 29.795334556962025, -1.467755517241379 ], [ 29.79560405063291, -1.467755517241379 ], [ 29.79560405063291, -1.467486034482759 ], [ 29.795334556962025, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26532, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.467486034482759 ], [ 29.79560405063291, -1.467755517241379 ], [ 29.795873544303799, -1.467755517241379 ], [ 29.795873544303799, -1.467486034482759 ], [ 29.79560405063291, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26533, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.467486034482759 ], [ 29.795873544303799, -1.467755517241379 ], [ 29.796143037974684, -1.467755517241379 ], [ 29.796143037974684, -1.467486034482759 ], [ 29.795873544303799, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26534, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.467486034482759 ], [ 29.796143037974684, -1.467755517241379 ], [ 29.796412531645569, -1.467755517241379 ], [ 29.796412531645569, -1.467486034482759 ], [ 29.796143037974684, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26535, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.467486034482759 ], [ 29.796412531645569, -1.467755517241379 ], [ 29.796682025316457, -1.467755517241379 ], [ 29.796682025316457, -1.467486034482759 ], [ 29.796412531645569, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26536, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.467486034482759 ], [ 29.796682025316457, -1.467755517241379 ], [ 29.796951518987342, -1.467755517241379 ], [ 29.796951518987342, -1.467486034482759 ], [ 29.796682025316457, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26537, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.467486034482759 ], [ 29.796951518987342, -1.467755517241379 ], [ 29.797221012658227, -1.467755517241379 ], [ 29.797221012658227, -1.467486034482759 ], [ 29.796951518987342, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26538, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.467486034482759 ], [ 29.797221012658227, -1.467755517241379 ], [ 29.797490506329115, -1.467755517241379 ], [ 29.797490506329115, -1.467486034482759 ], [ 29.797221012658227, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26539, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.467486034482759 ], [ 29.797490506329115, -1.467755517241379 ], [ 29.79776, -1.467755517241379 ], [ 29.79776, -1.467486034482759 ], [ 29.797490506329115, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26540, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.467486034482759 ], [ 29.79776, -1.467755517241379 ], [ 29.798029493670885, -1.467755517241379 ], [ 29.798029493670885, -1.467486034482759 ], [ 29.79776, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26541, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.467486034482759 ], [ 29.798029493670885, -1.467755517241379 ], [ 29.798298987341774, -1.467755517241379 ], [ 29.798298987341774, -1.467486034482759 ], [ 29.798029493670885, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26542, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.467486034482759 ], [ 29.798298987341774, -1.467755517241379 ], [ 29.798568481012659, -1.467755517241379 ], [ 29.798568481012659, -1.467486034482759 ], [ 29.798298987341774, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26543, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.467486034482759 ], [ 29.798837974683543, -1.468025 ], [ 29.799107468354432, -1.468025 ], [ 29.799107468354432, -1.467486034482759 ], [ 29.798837974683543, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26544, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.467486034482759 ], [ 29.799376962025317, -1.469372413793103 ], [ 29.799646455696202, -1.469372413793103 ], [ 29.799646455696202, -1.467486034482759 ], [ 29.799376962025317, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26545, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.467486034482759 ], [ 29.799646455696202, -1.467755517241379 ], [ 29.79991594936709, -1.467755517241379 ], [ 29.79991594936709, -1.467486034482759 ], [ 29.799646455696202, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26546, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.467486034482759 ], [ 29.79991594936709, -1.467755517241379 ], [ 29.800185443037975, -1.467755517241379 ], [ 29.800185443037975, -1.467486034482759 ], [ 29.79991594936709, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26547, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.467486034482759 ], [ 29.800185443037975, -1.468025 ], [ 29.80045493670886, -1.468025 ], [ 29.80045493670886, -1.467486034482759 ], [ 29.800185443037975, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26548, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.467486034482759 ], [ 29.80045493670886, -1.467755517241379 ], [ 29.800724430379748, -1.467755517241379 ], [ 29.800724430379748, -1.467486034482759 ], [ 29.80045493670886, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26549, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.467486034482759 ], [ 29.800724430379748, -1.467755517241379 ], [ 29.800993924050633, -1.467755517241379 ], [ 29.800993924050633, -1.467486034482759 ], [ 29.800724430379748, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26550, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.467486034482759 ], [ 29.800993924050633, -1.467755517241379 ], [ 29.801263417721518, -1.467755517241379 ], [ 29.801263417721518, -1.467486034482759 ], [ 29.800993924050633, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26551, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.467486034482759 ], [ 29.801263417721518, -1.467755517241379 ], [ 29.801532911392407, -1.467755517241379 ], [ 29.801532911392407, -1.467486034482759 ], [ 29.801263417721518, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26552, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.467486034482759 ], [ 29.801532911392407, -1.467755517241379 ], [ 29.802071898734177, -1.467755517241379 ], [ 29.802071898734177, -1.467486034482759 ], [ 29.801532911392407, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26553, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.467486034482759 ], [ 29.802071898734177, -1.468025 ], [ 29.802341392405065, -1.468025 ], [ 29.802341392405065, -1.467486034482759 ], [ 29.802071898734177, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26554, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755718987341773, -1.467755517241379 ], [ 29.755718987341773, -1.469102931034483 ], [ 29.755988481012658, -1.469102931034483 ], [ 29.755988481012658, -1.467755517241379 ], [ 29.755718987341773, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26555, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.467755517241379 ], [ 29.755988481012658, -1.468833448275862 ], [ 29.756257974683542, -1.468833448275862 ], [ 29.756257974683542, -1.467755517241379 ], [ 29.755988481012658, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26556, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.467755517241379 ], [ 29.756257974683542, -1.468833448275862 ], [ 29.756796962025316, -1.468833448275862 ], [ 29.756796962025316, -1.467755517241379 ], [ 29.756257974683542, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26557, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.467755517241379 ], [ 29.757335949367089, -1.468563965517242 ], [ 29.757605443037974, -1.468563965517242 ], [ 29.757605443037974, -1.467755517241379 ], [ 29.757335949367089, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26558, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.467755517241379 ], [ 29.758683417721517, -1.468294482758621 ], [ 29.758952911392406, -1.468294482758621 ], [ 29.758952911392406, -1.467755517241379 ], [ 29.758683417721517, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26559, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.467755517241379 ], [ 29.759491898734176, -1.468025 ], [ 29.759761392405064, -1.468025 ], [ 29.759761392405064, -1.467755517241379 ], [ 29.759491898734176, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26560, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.467755517241379 ], [ 29.759761392405064, -1.468025 ], [ 29.760030886075949, -1.468025 ], [ 29.760030886075949, -1.467755517241379 ], [ 29.759761392405064, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26561, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.467755517241379 ], [ 29.760030886075949, -1.468025 ], [ 29.760300379746834, -1.468025 ], [ 29.760300379746834, -1.467755517241379 ], [ 29.760030886075949, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26562, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.467755517241379 ], [ 29.760300379746834, -1.468025 ], [ 29.760569873417722, -1.468025 ], [ 29.760569873417722, -1.467755517241379 ], [ 29.760300379746834, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26563, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.467755517241379 ], [ 29.760569873417722, -1.468025 ], [ 29.760839367088607, -1.468025 ], [ 29.760839367088607, -1.467755517241379 ], [ 29.760569873417722, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26564, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.467755517241379 ], [ 29.760839367088607, -1.468025 ], [ 29.761108860759492, -1.468025 ], [ 29.761108860759492, -1.467755517241379 ], [ 29.760839367088607, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26565, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.464791206896552 ], [ 29.761647848101266, -1.464791206896552 ], [ 29.761647848101266, -1.468563965517242 ], [ 29.761378354430381, -1.468563965517242 ], [ 29.761378354430381, -1.468025 ], [ 29.761108860759492, -1.468025 ], [ 29.761108860759492, -1.467755517241379 ], [ 29.761378354430381, -1.467755517241379 ], [ 29.761378354430381, -1.464791206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26566, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.467755517241379 ], [ 29.763534303797467, -1.468294482758621 ], [ 29.763803797468356, -1.468294482758621 ], [ 29.763803797468356, -1.467755517241379 ], [ 29.763534303797467, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26567, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.467755517241379 ], [ 29.764342784810125, -1.468025 ], [ 29.764612278481014, -1.468025 ], [ 29.764612278481014, -1.467755517241379 ], [ 29.764342784810125, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26568, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.467755517241379 ], [ 29.764612278481014, -1.468025 ], [ 29.764881772151899, -1.468025 ], [ 29.764881772151899, -1.467755517241379 ], [ 29.764612278481014, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26569, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.467755517241379 ], [ 29.764881772151899, -1.468294482758621 ], [ 29.765151265822784, -1.468294482758621 ], [ 29.765151265822784, -1.467755517241379 ], [ 29.764881772151899, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26570, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.467755517241379 ], [ 29.765151265822784, -1.468025 ], [ 29.765420759493672, -1.468025 ], [ 29.765420759493672, -1.467755517241379 ], [ 29.765151265822784, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26571, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.467755517241379 ], [ 29.765420759493672, -1.468025 ], [ 29.765959746835442, -1.468025 ], [ 29.765959746835442, -1.467755517241379 ], [ 29.765420759493672, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26572, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.467755517241379 ], [ 29.765959746835442, -1.468025 ], [ 29.766229240506327, -1.468025 ], [ 29.766229240506327, -1.467755517241379 ], [ 29.765959746835442, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26573, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.467755517241379 ], [ 29.766229240506327, -1.468025 ], [ 29.766498734177215, -1.468025 ], [ 29.766498734177215, -1.467755517241379 ], [ 29.766229240506327, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26574, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.467755517241379 ], [ 29.766498734177215, -1.468025 ], [ 29.7667682278481, -1.468025 ], [ 29.7667682278481, -1.467755517241379 ], [ 29.766498734177215, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26575, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.467755517241379 ], [ 29.7667682278481, -1.468294482758621 ], [ 29.767037721518985, -1.468294482758621 ], [ 29.767037721518985, -1.467755517241379 ], [ 29.7667682278481, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26576, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.467755517241379 ], [ 29.770271645569618, -1.468563965517242 ], [ 29.770541139240507, -1.468563965517242 ], [ 29.770541139240507, -1.467755517241379 ], [ 29.770271645569618, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26577, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.467755517241379 ], [ 29.770541139240507, -1.468294482758621 ], [ 29.770810632911392, -1.468294482758621 ], [ 29.770810632911392, -1.467755517241379 ], [ 29.770541139240507, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26578, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.467755517241379 ], [ 29.770810632911392, -1.468294482758621 ], [ 29.771080126582277, -1.468294482758621 ], [ 29.771080126582277, -1.467755517241379 ], [ 29.770810632911392, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26579, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.467755517241379 ], [ 29.77161911392405, -1.468025 ], [ 29.771888607594935, -1.468025 ], [ 29.771888607594935, -1.467755517241379 ], [ 29.77161911392405, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26580, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.467755517241379 ], [ 29.771888607594935, -1.468025 ], [ 29.772158101265823, -1.468025 ], [ 29.772158101265823, -1.467755517241379 ], [ 29.771888607594935, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26581, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.467755517241379 ], [ 29.772158101265823, -1.468025 ], [ 29.772427594936708, -1.468025 ], [ 29.772427594936708, -1.467755517241379 ], [ 29.772158101265823, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26582, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.467755517241379 ], [ 29.772427594936708, -1.468025 ], [ 29.772697088607593, -1.468025 ], [ 29.772697088607593, -1.467755517241379 ], [ 29.772427594936708, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26583, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.467755517241379 ], [ 29.772697088607593, -1.468025 ], [ 29.772966582278482, -1.468025 ], [ 29.772966582278482, -1.467755517241379 ], [ 29.772697088607593, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26584, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.467755517241379 ], [ 29.772966582278482, -1.468025 ], [ 29.773236075949367, -1.468025 ], [ 29.773236075949367, -1.467755517241379 ], [ 29.772966582278482, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26585, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.467755517241379 ], [ 29.773236075949367, -1.468025 ], [ 29.773505569620252, -1.468025 ], [ 29.773505569620252, -1.467755517241379 ], [ 29.773236075949367, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26586, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.467755517241379 ], [ 29.773505569620252, -1.468025 ], [ 29.77377506329114, -1.468025 ], [ 29.77377506329114, -1.467755517241379 ], [ 29.773505569620252, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26587, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.467755517241379 ], [ 29.77377506329114, -1.468025 ], [ 29.774044556962025, -1.468025 ], [ 29.774044556962025, -1.467755517241379 ], [ 29.77377506329114, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26588, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.467755517241379 ], [ 29.774044556962025, -1.468025 ], [ 29.77431405063291, -1.468025 ], [ 29.77431405063291, -1.467755517241379 ], [ 29.774044556962025, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26589, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.467755517241379 ], [ 29.77431405063291, -1.468025 ], [ 29.774853037974683, -1.468025 ], [ 29.774853037974683, -1.467755517241379 ], [ 29.77431405063291, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26590, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.467755517241379 ], [ 29.774853037974683, -1.468025 ], [ 29.775122531645568, -1.468025 ], [ 29.775122531645568, -1.467755517241379 ], [ 29.774853037974683, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26591, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.467755517241379 ], [ 29.775122531645568, -1.468025 ], [ 29.775392025316457, -1.468025 ], [ 29.775392025316457, -1.467755517241379 ], [ 29.775122531645568, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26592, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.467755517241379 ], [ 29.775392025316457, -1.468025 ], [ 29.775661518987341, -1.468025 ], [ 29.775661518987341, -1.467755517241379 ], [ 29.775392025316457, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26593, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.467755517241379 ], [ 29.775661518987341, -1.468025 ], [ 29.775931012658226, -1.468025 ], [ 29.775931012658226, -1.467755517241379 ], [ 29.775661518987341, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26594, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.467755517241379 ], [ 29.775931012658226, -1.468025 ], [ 29.776200506329115, -1.468025 ], [ 29.776200506329115, -1.467755517241379 ], [ 29.775931012658226, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26595, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.467755517241379 ], [ 29.776200506329115, -1.468025 ], [ 29.77647, -1.468025 ], [ 29.77647, -1.467755517241379 ], [ 29.776200506329115, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26596, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.467755517241379 ], [ 29.77647, -1.468025 ], [ 29.776739493670885, -1.468025 ], [ 29.776739493670885, -1.467755517241379 ], [ 29.77647, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26597, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.467755517241379 ], [ 29.776739493670885, -1.468025 ], [ 29.777008987341773, -1.468025 ], [ 29.777008987341773, -1.467755517241379 ], [ 29.776739493670885, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26598, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.467755517241379 ], [ 29.777008987341773, -1.468294482758621 ], [ 29.777278481012658, -1.468294482758621 ], [ 29.777278481012658, -1.467755517241379 ], [ 29.777008987341773, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26599, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.467755517241379 ], [ 29.777278481012658, -1.468025 ], [ 29.777547974683543, -1.468025 ], [ 29.777547974683543, -1.467755517241379 ], [ 29.777278481012658, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26600, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.467755517241379 ], [ 29.777547974683543, -1.468025 ], [ 29.777817468354431, -1.468025 ], [ 29.777817468354431, -1.467755517241379 ], [ 29.777547974683543, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26601, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.467755517241379 ], [ 29.777817468354431, -1.468025 ], [ 29.778086962025316, -1.468025 ], [ 29.778086962025316, -1.467755517241379 ], [ 29.777817468354431, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26602, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.467755517241379 ], [ 29.778356455696201, -1.468025 ], [ 29.77862594936709, -1.468025 ], [ 29.77862594936709, -1.467755517241379 ], [ 29.778356455696201, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26603, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.467755517241379 ], [ 29.77862594936709, -1.468025 ], [ 29.778895443037975, -1.468025 ], [ 29.778895443037975, -1.467755517241379 ], [ 29.77862594936709, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26604, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.467755517241379 ], [ 29.778895443037975, -1.468294482758621 ], [ 29.77916493670886, -1.468294482758621 ], [ 29.77916493670886, -1.467755517241379 ], [ 29.778895443037975, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26605, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.467755517241379 ], [ 29.77916493670886, -1.468025 ], [ 29.779434430379748, -1.468025 ], [ 29.779434430379748, -1.467755517241379 ], [ 29.77916493670886, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26606, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.467755517241379 ], [ 29.779434430379748, -1.468025 ], [ 29.779703924050633, -1.468025 ], [ 29.779703924050633, -1.467755517241379 ], [ 29.779434430379748, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26607, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.467755517241379 ], [ 29.779703924050633, -1.468294482758621 ], [ 29.779973417721518, -1.468294482758621 ], [ 29.779973417721518, -1.467755517241379 ], [ 29.779703924050633, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26608, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.467755517241379 ], [ 29.779973417721518, -1.469102931034483 ], [ 29.780242911392406, -1.469102931034483 ], [ 29.780242911392406, -1.467755517241379 ], [ 29.779973417721518, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26609, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.467755517241379 ], [ 29.780242911392406, -1.468294482758621 ], [ 29.780512405063291, -1.468294482758621 ], [ 29.780512405063291, -1.467755517241379 ], [ 29.780242911392406, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26610, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.467755517241379 ], [ 29.780781898734176, -1.468294482758621 ], [ 29.781051392405065, -1.468294482758621 ], [ 29.781051392405065, -1.467755517241379 ], [ 29.780781898734176, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26611, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.467755517241379 ], [ 29.781051392405065, -1.468294482758621 ], [ 29.78132088607595, -1.468294482758621 ], [ 29.78132088607595, -1.467755517241379 ], [ 29.781051392405065, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26612, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.467755517241379 ], [ 29.781590379746834, -1.468294482758621 ], [ 29.781859873417723, -1.468294482758621 ], [ 29.781859873417723, -1.467755517241379 ], [ 29.781590379746834, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26613, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.467755517241379 ], [ 29.782398860759493, -1.468294482758621 ], [ 29.782668354430381, -1.468294482758621 ], [ 29.782668354430381, -1.467755517241379 ], [ 29.782398860759493, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26614, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.467755517241379 ], [ 29.782937848101266, -1.468025 ], [ 29.783207341772151, -1.468025 ], [ 29.783207341772151, -1.467755517241379 ], [ 29.782937848101266, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26615, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.467755517241379 ], [ 29.783207341772151, -1.468294482758621 ], [ 29.783476835443039, -1.468294482758621 ], [ 29.783476835443039, -1.467755517241379 ], [ 29.783207341772151, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26616, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.467755517241379 ], [ 29.784015822784809, -1.468025 ], [ 29.784285316455698, -1.468025 ], [ 29.784285316455698, -1.467755517241379 ], [ 29.784015822784809, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26617, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.467755517241379 ], [ 29.784285316455698, -1.468025 ], [ 29.784554810126583, -1.468025 ], [ 29.784554810126583, -1.467755517241379 ], [ 29.784285316455698, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26618, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.467755517241379 ], [ 29.784824303797468, -1.468025 ], [ 29.785093797468356, -1.468025 ], [ 29.785093797468356, -1.467755517241379 ], [ 29.784824303797468, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26619, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.467755517241379 ], [ 29.785093797468356, -1.468025 ], [ 29.785363291139241, -1.468025 ], [ 29.785363291139241, -1.467755517241379 ], [ 29.785093797468356, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26620, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.467755517241379 ], [ 29.785632784810126, -1.468025 ], [ 29.785902278481014, -1.468025 ], [ 29.785902278481014, -1.467755517241379 ], [ 29.785632784810126, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26621, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.467755517241379 ], [ 29.785902278481014, -1.468025 ], [ 29.786171772151899, -1.468025 ], [ 29.786171772151899, -1.467755517241379 ], [ 29.785902278481014, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26622, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.467755517241379 ], [ 29.786171772151899, -1.468025 ], [ 29.786441265822784, -1.468025 ], [ 29.786441265822784, -1.467755517241379 ], [ 29.786171772151899, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26623, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.467755517241379 ], [ 29.786441265822784, -1.468025 ], [ 29.786710759493673, -1.468025 ], [ 29.786710759493673, -1.467755517241379 ], [ 29.786441265822784, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26624, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.467755517241379 ], [ 29.786710759493673, -1.468025 ], [ 29.786980253164558, -1.468025 ], [ 29.786980253164558, -1.467755517241379 ], [ 29.786710759493673, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26625, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.467755517241379 ], [ 29.786980253164558, -1.468025 ], [ 29.787249746835442, -1.468025 ], [ 29.787249746835442, -1.467755517241379 ], [ 29.786980253164558, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26626, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.467755517241379 ], [ 29.787249746835442, -1.468025 ], [ 29.787519240506327, -1.468025 ], [ 29.787519240506327, -1.467755517241379 ], [ 29.787249746835442, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26627, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.467755517241379 ], [ 29.787519240506327, -1.468025 ], [ 29.787788734177216, -1.468025 ], [ 29.787788734177216, -1.467755517241379 ], [ 29.787519240506327, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26628, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.467755517241379 ], [ 29.787788734177216, -1.468294482758621 ], [ 29.788058227848101, -1.468294482758621 ], [ 29.788058227848101, -1.467755517241379 ], [ 29.787788734177216, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26629, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.467755517241379 ], [ 29.788597215189874, -1.468025 ], [ 29.788866708860759, -1.468025 ], [ 29.788866708860759, -1.467755517241379 ], [ 29.788597215189874, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26630, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.467755517241379 ], [ 29.788866708860759, -1.468025 ], [ 29.789136202531644, -1.468025 ], [ 29.789136202531644, -1.467755517241379 ], [ 29.788866708860759, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26631, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.467755517241379 ], [ 29.789136202531644, -1.468025 ], [ 29.789405696202532, -1.468025 ], [ 29.789405696202532, -1.467755517241379 ], [ 29.789136202531644, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26632, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.467755517241379 ], [ 29.789405696202532, -1.468563965517242 ], [ 29.789675189873417, -1.468563965517242 ], [ 29.789675189873417, -1.467755517241379 ], [ 29.789405696202532, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26633, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.794795569620252, -1.467755517241379 ], [ 29.794795569620252, -1.468294482758621 ], [ 29.79506506329114, -1.468294482758621 ], [ 29.79506506329114, -1.467755517241379 ], [ 29.794795569620252, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26634, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.467755517241379 ], [ 29.79506506329114, -1.468025 ], [ 29.795334556962025, -1.468025 ], [ 29.795334556962025, -1.467755517241379 ], [ 29.79506506329114, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26635, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.467755517241379 ], [ 29.795334556962025, -1.468025 ], [ 29.79560405063291, -1.468025 ], [ 29.79560405063291, -1.467755517241379 ], [ 29.795334556962025, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26636, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.467755517241379 ], [ 29.79560405063291, -1.468025 ], [ 29.795873544303799, -1.468025 ], [ 29.795873544303799, -1.467755517241379 ], [ 29.79560405063291, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26637, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.467755517241379 ], [ 29.795873544303799, -1.468025 ], [ 29.796143037974684, -1.468025 ], [ 29.796143037974684, -1.467755517241379 ], [ 29.795873544303799, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26638, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.467755517241379 ], [ 29.796143037974684, -1.468025 ], [ 29.796412531645569, -1.468025 ], [ 29.796412531645569, -1.467755517241379 ], [ 29.796143037974684, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26639, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.467755517241379 ], [ 29.796412531645569, -1.468025 ], [ 29.796682025316457, -1.468025 ], [ 29.796682025316457, -1.467755517241379 ], [ 29.796412531645569, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26640, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.467755517241379 ], [ 29.796682025316457, -1.468025 ], [ 29.796951518987342, -1.468025 ], [ 29.796951518987342, -1.467755517241379 ], [ 29.796682025316457, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26641, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.467755517241379 ], [ 29.796951518987342, -1.468025 ], [ 29.797221012658227, -1.468025 ], [ 29.797221012658227, -1.467755517241379 ], [ 29.796951518987342, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26642, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.467755517241379 ], [ 29.797221012658227, -1.468025 ], [ 29.797490506329115, -1.468025 ], [ 29.797490506329115, -1.467755517241379 ], [ 29.797221012658227, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26643, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.467755517241379 ], [ 29.797490506329115, -1.468025 ], [ 29.79776, -1.468025 ], [ 29.79776, -1.467755517241379 ], [ 29.797490506329115, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26644, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.467755517241379 ], [ 29.79776, -1.468025 ], [ 29.798029493670885, -1.468025 ], [ 29.798029493670885, -1.467755517241379 ], [ 29.79776, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26645, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.467755517241379 ], [ 29.798029493670885, -1.468025 ], [ 29.798298987341774, -1.468025 ], [ 29.798298987341774, -1.467755517241379 ], [ 29.798029493670885, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26646, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.467486034482759 ], [ 29.798837974683543, -1.467486034482759 ], [ 29.798837974683543, -1.468563965517242 ], [ 29.798568481012659, -1.468563965517242 ], [ 29.798568481012659, -1.468025 ], [ 29.798298987341774, -1.468025 ], [ 29.798298987341774, -1.467755517241379 ], [ 29.798568481012659, -1.467755517241379 ], [ 29.798568481012659, -1.467486034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26647, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.467755517241379 ], [ 29.799107468354432, -1.469102931034483 ], [ 29.799376962025317, -1.469102931034483 ], [ 29.799376962025317, -1.467755517241379 ], [ 29.799107468354432, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26648, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.467755517241379 ], [ 29.799646455696202, -1.469102931034483 ], [ 29.79991594936709, -1.469102931034483 ], [ 29.79991594936709, -1.467755517241379 ], [ 29.799646455696202, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26649, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.467755517241379 ], [ 29.79991594936709, -1.469102931034483 ], [ 29.800185443037975, -1.469102931034483 ], [ 29.800185443037975, -1.467755517241379 ], [ 29.79991594936709, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26650, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.467755517241379 ], [ 29.80045493670886, -1.469102931034483 ], [ 29.800724430379748, -1.469102931034483 ], [ 29.800724430379748, -1.467755517241379 ], [ 29.80045493670886, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26651, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.467755517241379 ], [ 29.800724430379748, -1.469102931034483 ], [ 29.800993924050633, -1.469102931034483 ], [ 29.800993924050633, -1.467755517241379 ], [ 29.800724430379748, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26652, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.467755517241379 ], [ 29.800993924050633, -1.468025 ], [ 29.801263417721518, -1.468025 ], [ 29.801263417721518, -1.467755517241379 ], [ 29.800993924050633, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26653, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.467755517241379 ], [ 29.801263417721518, -1.468025 ], [ 29.801532911392407, -1.468025 ], [ 29.801532911392407, -1.467755517241379 ], [ 29.801263417721518, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26654, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.467755517241379 ], [ 29.801532911392407, -1.468025 ], [ 29.802071898734177, -1.468025 ], [ 29.802071898734177, -1.467755517241379 ], [ 29.801532911392407, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26655, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.468025 ], [ 29.758144430379748, -1.468294482758621 ], [ 29.758413924050632, -1.468294482758621 ], [ 29.758413924050632, -1.468025 ], [ 29.758144430379748, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26656, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.468025 ], [ 29.758413924050632, -1.468294482758621 ], [ 29.758683417721517, -1.468294482758621 ], [ 29.758683417721517, -1.468025 ], [ 29.758413924050632, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26657, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.468025 ], [ 29.758952911392406, -1.468294482758621 ], [ 29.759222405063291, -1.468294482758621 ], [ 29.759222405063291, -1.468025 ], [ 29.758952911392406, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26658, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.468025 ], [ 29.759222405063291, -1.468294482758621 ], [ 29.759491898734176, -1.468294482758621 ], [ 29.759491898734176, -1.468025 ], [ 29.759222405063291, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26659, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.468025 ], [ 29.759491898734176, -1.468294482758621 ], [ 29.759761392405064, -1.468294482758621 ], [ 29.759761392405064, -1.468025 ], [ 29.759491898734176, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26660, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.468025 ], [ 29.759761392405064, -1.468294482758621 ], [ 29.760030886075949, -1.468294482758621 ], [ 29.760030886075949, -1.468025 ], [ 29.759761392405064, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26661, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.468025 ], [ 29.760030886075949, -1.468294482758621 ], [ 29.760300379746834, -1.468294482758621 ], [ 29.760300379746834, -1.468025 ], [ 29.760030886075949, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26662, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.468025 ], [ 29.760300379746834, -1.468294482758621 ], [ 29.760569873417722, -1.468294482758621 ], [ 29.760569873417722, -1.468025 ], [ 29.760300379746834, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26663, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.468025 ], [ 29.760569873417722, -1.468294482758621 ], [ 29.760839367088607, -1.468294482758621 ], [ 29.760839367088607, -1.468025 ], [ 29.760569873417722, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26664, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.468025 ], [ 29.760839367088607, -1.468294482758621 ], [ 29.761108860759492, -1.468294482758621 ], [ 29.761108860759492, -1.468025 ], [ 29.760839367088607, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26665, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.468025 ], [ 29.761108860759492, -1.468294482758621 ], [ 29.761378354430381, -1.468294482758621 ], [ 29.761378354430381, -1.468025 ], [ 29.761108860759492, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26666, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.468025 ], [ 29.765959746835442, -1.468294482758621 ], [ 29.766229240506327, -1.468294482758621 ], [ 29.766229240506327, -1.468025 ], [ 29.765959746835442, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26667, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.468025 ], [ 29.766229240506327, -1.468294482758621 ], [ 29.766498734177215, -1.468294482758621 ], [ 29.766498734177215, -1.468025 ], [ 29.766229240506327, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26668, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.468025 ], [ 29.766498734177215, -1.468294482758621 ], [ 29.7667682278481, -1.468294482758621 ], [ 29.7667682278481, -1.468025 ], [ 29.766498734177215, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26669, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.468025 ], [ 29.771080126582277, -1.468294482758621 ], [ 29.771349620253165, -1.468294482758621 ], [ 29.771349620253165, -1.468025 ], [ 29.771080126582277, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26670, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.468025 ], [ 29.771349620253165, -1.468294482758621 ], [ 29.77161911392405, -1.468294482758621 ], [ 29.77161911392405, -1.468025 ], [ 29.771349620253165, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26671, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.468025 ], [ 29.77161911392405, -1.468294482758621 ], [ 29.771888607594935, -1.468294482758621 ], [ 29.771888607594935, -1.468025 ], [ 29.77161911392405, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26672, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.468025 ], [ 29.771888607594935, -1.468563965517242 ], [ 29.772158101265823, -1.468563965517242 ], [ 29.772158101265823, -1.468025 ], [ 29.771888607594935, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26673, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.468025 ], [ 29.772158101265823, -1.468294482758621 ], [ 29.772427594936708, -1.468294482758621 ], [ 29.772427594936708, -1.468025 ], [ 29.772158101265823, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26674, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.468025 ], [ 29.772427594936708, -1.468294482758621 ], [ 29.772697088607593, -1.468294482758621 ], [ 29.772697088607593, -1.468025 ], [ 29.772427594936708, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26675, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.468025 ], [ 29.772697088607593, -1.468294482758621 ], [ 29.772966582278482, -1.468294482758621 ], [ 29.772966582278482, -1.468025 ], [ 29.772697088607593, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26676, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.468025 ], [ 29.772966582278482, -1.468294482758621 ], [ 29.773236075949367, -1.468294482758621 ], [ 29.773236075949367, -1.468025 ], [ 29.772966582278482, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26677, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.468025 ], [ 29.773236075949367, -1.468294482758621 ], [ 29.773505569620252, -1.468294482758621 ], [ 29.773505569620252, -1.468025 ], [ 29.773236075949367, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26678, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.468025 ], [ 29.773505569620252, -1.468294482758621 ], [ 29.77377506329114, -1.468294482758621 ], [ 29.77377506329114, -1.468025 ], [ 29.773505569620252, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26679, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.468025 ], [ 29.77377506329114, -1.468294482758621 ], [ 29.774044556962025, -1.468294482758621 ], [ 29.774044556962025, -1.468025 ], [ 29.77377506329114, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26680, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.468025 ], [ 29.774044556962025, -1.468294482758621 ], [ 29.77431405063291, -1.468294482758621 ], [ 29.77431405063291, -1.468025 ], [ 29.774044556962025, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26681, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.468025 ], [ 29.77431405063291, -1.468294482758621 ], [ 29.774853037974683, -1.468294482758621 ], [ 29.774853037974683, -1.468025 ], [ 29.77431405063291, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26682, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.468025 ], [ 29.774853037974683, -1.468294482758621 ], [ 29.775122531645568, -1.468294482758621 ], [ 29.775122531645568, -1.468025 ], [ 29.774853037974683, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26683, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.468025 ], [ 29.775122531645568, -1.468294482758621 ], [ 29.775392025316457, -1.468294482758621 ], [ 29.775392025316457, -1.468025 ], [ 29.775122531645568, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26684, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.468025 ], [ 29.775392025316457, -1.468294482758621 ], [ 29.775661518987341, -1.468294482758621 ], [ 29.775661518987341, -1.468025 ], [ 29.775392025316457, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26685, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.468025 ], [ 29.775661518987341, -1.468294482758621 ], [ 29.775931012658226, -1.468294482758621 ], [ 29.775931012658226, -1.468025 ], [ 29.775661518987341, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26686, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.468025 ], [ 29.775931012658226, -1.468563965517242 ], [ 29.776200506329115, -1.468563965517242 ], [ 29.776200506329115, -1.468025 ], [ 29.775931012658226, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26687, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.468025 ], [ 29.776200506329115, -1.468294482758621 ], [ 29.77647, -1.468294482758621 ], [ 29.77647, -1.468025 ], [ 29.776200506329115, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26688, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.468025 ], [ 29.77647, -1.468294482758621 ], [ 29.776739493670885, -1.468294482758621 ], [ 29.776739493670885, -1.468025 ], [ 29.77647, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26689, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.468025 ], [ 29.776739493670885, -1.468294482758621 ], [ 29.777008987341773, -1.468294482758621 ], [ 29.777008987341773, -1.468025 ], [ 29.776739493670885, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26690, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.468025 ], [ 29.777278481012658, -1.468294482758621 ], [ 29.777547974683543, -1.468294482758621 ], [ 29.777547974683543, -1.468025 ], [ 29.777278481012658, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26691, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.468025 ], [ 29.777547974683543, -1.468294482758621 ], [ 29.777817468354431, -1.468294482758621 ], [ 29.777817468354431, -1.468025 ], [ 29.777547974683543, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26692, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.468025 ], [ 29.777817468354431, -1.468563965517242 ], [ 29.778086962025316, -1.468563965517242 ], [ 29.778086962025316, -1.468025 ], [ 29.777817468354431, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26693, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.468025 ], [ 29.778086962025316, -1.468294482758621 ], [ 29.778356455696201, -1.468294482758621 ], [ 29.778356455696201, -1.468025 ], [ 29.778086962025316, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26694, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.468025 ], [ 29.778356455696201, -1.468294482758621 ], [ 29.77862594936709, -1.468294482758621 ], [ 29.77862594936709, -1.468025 ], [ 29.778356455696201, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26695, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.468025 ], [ 29.77862594936709, -1.468563965517242 ], [ 29.778895443037975, -1.468563965517242 ], [ 29.778895443037975, -1.468025 ], [ 29.77862594936709, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26696, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.468025 ], [ 29.77916493670886, -1.468294482758621 ], [ 29.779434430379748, -1.468294482758621 ], [ 29.779434430379748, -1.468025 ], [ 29.77916493670886, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26697, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.468025 ], [ 29.779434430379748, -1.468563965517242 ], [ 29.779703924050633, -1.468563965517242 ], [ 29.779703924050633, -1.468025 ], [ 29.779434430379748, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26698, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.468025 ], [ 29.780512405063291, -1.468563965517242 ], [ 29.780781898734176, -1.468563965517242 ], [ 29.780781898734176, -1.468025 ], [ 29.780512405063291, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26699, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.468025 ], [ 29.78132088607595, -1.468563965517242 ], [ 29.781590379746834, -1.468563965517242 ], [ 29.781590379746834, -1.468025 ], [ 29.78132088607595, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26700, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.468025 ], [ 29.781859873417723, -1.468294482758621 ], [ 29.782129367088608, -1.468294482758621 ], [ 29.782129367088608, -1.468025 ], [ 29.781859873417723, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26701, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.468025 ], [ 29.782129367088608, -1.468563965517242 ], [ 29.782398860759493, -1.468563965517242 ], [ 29.782398860759493, -1.468025 ], [ 29.782129367088608, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26702, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.468025 ], [ 29.782668354430381, -1.468294482758621 ], [ 29.782937848101266, -1.468294482758621 ], [ 29.782937848101266, -1.468025 ], [ 29.782668354430381, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26703, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.468025 ], [ 29.782937848101266, -1.468294482758621 ], [ 29.783207341772151, -1.468294482758621 ], [ 29.783207341772151, -1.468025 ], [ 29.782937848101266, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26704, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.468025 ], [ 29.783476835443039, -1.468294482758621 ], [ 29.784015822784809, -1.468294482758621 ], [ 29.784015822784809, -1.468025 ], [ 29.783476835443039, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26705, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.468025 ], [ 29.784015822784809, -1.468294482758621 ], [ 29.784285316455698, -1.468294482758621 ], [ 29.784285316455698, -1.468025 ], [ 29.784015822784809, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26706, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.468025 ], [ 29.784285316455698, -1.468563965517242 ], [ 29.784554810126583, -1.468563965517242 ], [ 29.784554810126583, -1.468025 ], [ 29.784285316455698, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26707, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.468025 ], [ 29.784554810126583, -1.468294482758621 ], [ 29.784824303797468, -1.468294482758621 ], [ 29.784824303797468, -1.468025 ], [ 29.784554810126583, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26708, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.468025 ], [ 29.784824303797468, -1.468294482758621 ], [ 29.785093797468356, -1.468294482758621 ], [ 29.785093797468356, -1.468025 ], [ 29.784824303797468, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26709, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.468025 ], [ 29.785093797468356, -1.468294482758621 ], [ 29.785363291139241, -1.468294482758621 ], [ 29.785363291139241, -1.468025 ], [ 29.785093797468356, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26710, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.468025 ], [ 29.785363291139241, -1.468294482758621 ], [ 29.785632784810126, -1.468294482758621 ], [ 29.785632784810126, -1.468025 ], [ 29.785363291139241, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26711, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.468025 ], [ 29.785632784810126, -1.468294482758621 ], [ 29.785902278481014, -1.468294482758621 ], [ 29.785902278481014, -1.468025 ], [ 29.785632784810126, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26712, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.468025 ], [ 29.785902278481014, -1.468294482758621 ], [ 29.786171772151899, -1.468294482758621 ], [ 29.786171772151899, -1.468025 ], [ 29.785902278481014, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26713, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.468025 ], [ 29.786171772151899, -1.468294482758621 ], [ 29.786441265822784, -1.468294482758621 ], [ 29.786441265822784, -1.468025 ], [ 29.786171772151899, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26714, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.468025 ], [ 29.786441265822784, -1.468294482758621 ], [ 29.786710759493673, -1.468294482758621 ], [ 29.786710759493673, -1.468025 ], [ 29.786441265822784, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26715, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.468025 ], [ 29.786710759493673, -1.468563965517242 ], [ 29.786980253164558, -1.468563965517242 ], [ 29.786980253164558, -1.468025 ], [ 29.786710759493673, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26716, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.468025 ], [ 29.786980253164558, -1.468294482758621 ], [ 29.787249746835442, -1.468294482758621 ], [ 29.787249746835442, -1.468025 ], [ 29.786980253164558, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26717, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.468025 ], [ 29.787249746835442, -1.468294482758621 ], [ 29.787519240506327, -1.468294482758621 ], [ 29.787519240506327, -1.468025 ], [ 29.787249746835442, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26718, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.468025 ], [ 29.787519240506327, -1.468294482758621 ], [ 29.787788734177216, -1.468294482758621 ], [ 29.787788734177216, -1.468025 ], [ 29.787519240506327, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26719, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.468025 ], [ 29.788058227848101, -1.468294482758621 ], [ 29.788327721518986, -1.468294482758621 ], [ 29.788327721518986, -1.468025 ], [ 29.788058227848101, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26720, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.468025 ], [ 29.788327721518986, -1.468294482758621 ], [ 29.788597215189874, -1.468294482758621 ], [ 29.788597215189874, -1.468025 ], [ 29.788327721518986, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26721, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.468025 ], [ 29.788597215189874, -1.468294482758621 ], [ 29.788866708860759, -1.468294482758621 ], [ 29.788866708860759, -1.468025 ], [ 29.788597215189874, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26722, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.468025 ], [ 29.788866708860759, -1.468563965517242 ], [ 29.789136202531644, -1.468563965517242 ], [ 29.789136202531644, -1.468025 ], [ 29.788866708860759, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26723, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.468025 ], [ 29.789136202531644, -1.468563965517242 ], [ 29.789405696202532, -1.468563965517242 ], [ 29.789405696202532, -1.468025 ], [ 29.789136202531644, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26724, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.468025 ], [ 29.79506506329114, -1.468294482758621 ], [ 29.795334556962025, -1.468294482758621 ], [ 29.795334556962025, -1.468025 ], [ 29.79506506329114, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26725, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.468025 ], [ 29.795334556962025, -1.468294482758621 ], [ 29.79560405063291, -1.468294482758621 ], [ 29.79560405063291, -1.468025 ], [ 29.795334556962025, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26726, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.468025 ], [ 29.79560405063291, -1.468294482758621 ], [ 29.795873544303799, -1.468294482758621 ], [ 29.795873544303799, -1.468025 ], [ 29.79560405063291, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26727, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.468025 ], [ 29.795873544303799, -1.468294482758621 ], [ 29.796143037974684, -1.468294482758621 ], [ 29.796143037974684, -1.468025 ], [ 29.795873544303799, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26728, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.468025 ], [ 29.796143037974684, -1.468294482758621 ], [ 29.796412531645569, -1.468294482758621 ], [ 29.796412531645569, -1.468025 ], [ 29.796143037974684, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26729, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.468025 ], [ 29.796412531645569, -1.468294482758621 ], [ 29.796682025316457, -1.468294482758621 ], [ 29.796682025316457, -1.468025 ], [ 29.796412531645569, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26730, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.468025 ], [ 29.796682025316457, -1.468294482758621 ], [ 29.796951518987342, -1.468294482758621 ], [ 29.796951518987342, -1.468025 ], [ 29.796682025316457, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26731, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.468025 ], [ 29.796951518987342, -1.468294482758621 ], [ 29.797221012658227, -1.468294482758621 ], [ 29.797221012658227, -1.468025 ], [ 29.796951518987342, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26732, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.468025 ], [ 29.797221012658227, -1.468294482758621 ], [ 29.797490506329115, -1.468294482758621 ], [ 29.797490506329115, -1.468025 ], [ 29.797221012658227, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26733, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.468025 ], [ 29.797490506329115, -1.468294482758621 ], [ 29.79776, -1.468294482758621 ], [ 29.79776, -1.468025 ], [ 29.797490506329115, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26734, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.468025 ], [ 29.79776, -1.468294482758621 ], [ 29.798029493670885, -1.468294482758621 ], [ 29.798029493670885, -1.468025 ], [ 29.79776, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26735, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.468025 ], [ 29.798029493670885, -1.468294482758621 ], [ 29.798298987341774, -1.468294482758621 ], [ 29.798298987341774, -1.468025 ], [ 29.798029493670885, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26736, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.468025 ], [ 29.798298987341774, -1.468294482758621 ], [ 29.798568481012659, -1.468294482758621 ], [ 29.798568481012659, -1.468025 ], [ 29.798298987341774, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26737, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.468025 ], [ 29.798837974683543, -1.468833448275862 ], [ 29.799107468354432, -1.468833448275862 ], [ 29.799107468354432, -1.468025 ], [ 29.798837974683543, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26738, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.468025 ], [ 29.800185443037975, -1.468833448275862 ], [ 29.80045493670886, -1.468833448275862 ], [ 29.80045493670886, -1.468025 ], [ 29.800185443037975, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26739, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.468025 ], [ 29.800993924050633, -1.468833448275862 ], [ 29.801263417721518, -1.468833448275862 ], [ 29.801263417721518, -1.468025 ], [ 29.800993924050633, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26740, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.468025 ], [ 29.801263417721518, -1.468833448275862 ], [ 29.801532911392407, -1.468833448275862 ], [ 29.801532911392407, -1.468025 ], [ 29.801263417721518, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26741, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.468025 ], [ 29.801532911392407, -1.468833448275862 ], [ 29.802071898734177, -1.468833448275862 ], [ 29.802071898734177, -1.468025 ], [ 29.801532911392407, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26742, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.468294482758621 ], [ 29.757605443037974, -1.468563965517242 ], [ 29.757874936708859, -1.468563965517242 ], [ 29.757874936708859, -1.468294482758621 ], [ 29.757605443037974, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26743, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.468294482758621 ], [ 29.757874936708859, -1.468563965517242 ], [ 29.758144430379748, -1.468563965517242 ], [ 29.758144430379748, -1.468294482758621 ], [ 29.757874936708859, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26744, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.468294482758621 ], [ 29.758144430379748, -1.468563965517242 ], [ 29.758413924050632, -1.468563965517242 ], [ 29.758413924050632, -1.468294482758621 ], [ 29.758144430379748, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26745, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.468294482758621 ], [ 29.758413924050632, -1.468563965517242 ], [ 29.758683417721517, -1.468563965517242 ], [ 29.758683417721517, -1.468294482758621 ], [ 29.758413924050632, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26746, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.468294482758621 ], [ 29.758683417721517, -1.468563965517242 ], [ 29.758952911392406, -1.468563965517242 ], [ 29.758952911392406, -1.468294482758621 ], [ 29.758683417721517, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26747, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.468294482758621 ], [ 29.758952911392406, -1.468563965517242 ], [ 29.759222405063291, -1.468563965517242 ], [ 29.759222405063291, -1.468294482758621 ], [ 29.758952911392406, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26748, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.468294482758621 ], [ 29.759222405063291, -1.468563965517242 ], [ 29.759491898734176, -1.468563965517242 ], [ 29.759491898734176, -1.468294482758621 ], [ 29.759222405063291, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26749, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.468294482758621 ], [ 29.759491898734176, -1.468563965517242 ], [ 29.759761392405064, -1.468563965517242 ], [ 29.759761392405064, -1.468294482758621 ], [ 29.759491898734176, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26750, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.468294482758621 ], [ 29.759761392405064, -1.468563965517242 ], [ 29.760030886075949, -1.468563965517242 ], [ 29.760030886075949, -1.468294482758621 ], [ 29.759761392405064, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26751, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.468294482758621 ], [ 29.760030886075949, -1.468563965517242 ], [ 29.760300379746834, -1.468563965517242 ], [ 29.760300379746834, -1.468294482758621 ], [ 29.760030886075949, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26752, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.468294482758621 ], [ 29.760300379746834, -1.468563965517242 ], [ 29.760569873417722, -1.468563965517242 ], [ 29.760569873417722, -1.468294482758621 ], [ 29.760300379746834, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26753, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.468294482758621 ], [ 29.760569873417722, -1.468563965517242 ], [ 29.760839367088607, -1.468563965517242 ], [ 29.760839367088607, -1.468294482758621 ], [ 29.760569873417722, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26754, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.468294482758621 ], [ 29.760839367088607, -1.468563965517242 ], [ 29.761108860759492, -1.468563965517242 ], [ 29.761108860759492, -1.468294482758621 ], [ 29.760839367088607, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26755, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.468294482758621 ], [ 29.761108860759492, -1.468563965517242 ], [ 29.761378354430381, -1.468563965517242 ], [ 29.761378354430381, -1.468294482758621 ], [ 29.761108860759492, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26756, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.468294482758621 ], [ 29.770541139240507, -1.468563965517242 ], [ 29.770810632911392, -1.468563965517242 ], [ 29.770810632911392, -1.468294482758621 ], [ 29.770541139240507, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26757, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.468294482758621 ], [ 29.770810632911392, -1.468563965517242 ], [ 29.771080126582277, -1.468563965517242 ], [ 29.771080126582277, -1.468294482758621 ], [ 29.770810632911392, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26758, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.468294482758621 ], [ 29.771080126582277, -1.468563965517242 ], [ 29.771349620253165, -1.468563965517242 ], [ 29.771349620253165, -1.468294482758621 ], [ 29.771080126582277, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26759, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.468294482758621 ], [ 29.771349620253165, -1.468833448275862 ], [ 29.77161911392405, -1.468833448275862 ], [ 29.77161911392405, -1.468294482758621 ], [ 29.771349620253165, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26760, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.468294482758621 ], [ 29.77161911392405, -1.468833448275862 ], [ 29.771888607594935, -1.468833448275862 ], [ 29.771888607594935, -1.468294482758621 ], [ 29.77161911392405, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26761, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.468294482758621 ], [ 29.772158101265823, -1.468563965517242 ], [ 29.772427594936708, -1.468563965517242 ], [ 29.772427594936708, -1.468294482758621 ], [ 29.772158101265823, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26762, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.468294482758621 ], [ 29.772427594936708, -1.468563965517242 ], [ 29.772697088607593, -1.468563965517242 ], [ 29.772697088607593, -1.468294482758621 ], [ 29.772427594936708, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26763, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.468294482758621 ], [ 29.772697088607593, -1.468563965517242 ], [ 29.772966582278482, -1.468563965517242 ], [ 29.772966582278482, -1.468294482758621 ], [ 29.772697088607593, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26764, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.468294482758621 ], [ 29.772966582278482, -1.468563965517242 ], [ 29.773236075949367, -1.468563965517242 ], [ 29.773236075949367, -1.468294482758621 ], [ 29.772966582278482, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26765, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.468294482758621 ], [ 29.773236075949367, -1.468563965517242 ], [ 29.773505569620252, -1.468563965517242 ], [ 29.773505569620252, -1.468294482758621 ], [ 29.773236075949367, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26766, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.468294482758621 ], [ 29.773505569620252, -1.468563965517242 ], [ 29.77377506329114, -1.468563965517242 ], [ 29.77377506329114, -1.468294482758621 ], [ 29.773505569620252, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26767, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.468294482758621 ], [ 29.77377506329114, -1.468563965517242 ], [ 29.774044556962025, -1.468563965517242 ], [ 29.774044556962025, -1.468294482758621 ], [ 29.77377506329114, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26768, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.468294482758621 ], [ 29.774044556962025, -1.468563965517242 ], [ 29.77431405063291, -1.468563965517242 ], [ 29.77431405063291, -1.468294482758621 ], [ 29.774044556962025, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26769, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.468294482758621 ], [ 29.77431405063291, -1.468563965517242 ], [ 29.774853037974683, -1.468563965517242 ], [ 29.774853037974683, -1.468294482758621 ], [ 29.77431405063291, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26770, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.468294482758621 ], [ 29.774853037974683, -1.468563965517242 ], [ 29.775122531645568, -1.468563965517242 ], [ 29.775122531645568, -1.468294482758621 ], [ 29.774853037974683, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26771, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.468294482758621 ], [ 29.775122531645568, -1.468563965517242 ], [ 29.775392025316457, -1.468563965517242 ], [ 29.775392025316457, -1.468294482758621 ], [ 29.775122531645568, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26772, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.468294482758621 ], [ 29.775392025316457, -1.468563965517242 ], [ 29.775661518987341, -1.468563965517242 ], [ 29.775661518987341, -1.468294482758621 ], [ 29.775392025316457, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26773, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.468294482758621 ], [ 29.775661518987341, -1.468563965517242 ], [ 29.775931012658226, -1.468563965517242 ], [ 29.775931012658226, -1.468294482758621 ], [ 29.775661518987341, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26774, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.468294482758621 ], [ 29.776200506329115, -1.468563965517242 ], [ 29.77647, -1.468563965517242 ], [ 29.77647, -1.468294482758621 ], [ 29.776200506329115, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26775, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.468294482758621 ], [ 29.77647, -1.468563965517242 ], [ 29.776739493670885, -1.468563965517242 ], [ 29.776739493670885, -1.468294482758621 ], [ 29.77647, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26776, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.468294482758621 ], [ 29.776739493670885, -1.468833448275862 ], [ 29.777008987341773, -1.468833448275862 ], [ 29.777008987341773, -1.468294482758621 ], [ 29.776739493670885, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26777, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.468294482758621 ], [ 29.777008987341773, -1.468563965517242 ], [ 29.777278481012658, -1.468563965517242 ], [ 29.777278481012658, -1.468294482758621 ], [ 29.777008987341773, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26778, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.468294482758621 ], [ 29.777278481012658, -1.468563965517242 ], [ 29.777547974683543, -1.468563965517242 ], [ 29.777547974683543, -1.468294482758621 ], [ 29.777278481012658, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26779, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.468294482758621 ], [ 29.777547974683543, -1.468833448275862 ], [ 29.777817468354431, -1.468833448275862 ], [ 29.777817468354431, -1.468294482758621 ], [ 29.777547974683543, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26780, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.468294482758621 ], [ 29.778086962025316, -1.468563965517242 ], [ 29.778356455696201, -1.468563965517242 ], [ 29.778356455696201, -1.468294482758621 ], [ 29.778086962025316, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26781, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.468294482758621 ], [ 29.778356455696201, -1.468833448275862 ], [ 29.77862594936709, -1.468833448275862 ], [ 29.77862594936709, -1.468294482758621 ], [ 29.778356455696201, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26782, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.468294482758621 ], [ 29.778895443037975, -1.468833448275862 ], [ 29.77916493670886, -1.468833448275862 ], [ 29.77916493670886, -1.468294482758621 ], [ 29.778895443037975, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26783, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.468294482758621 ], [ 29.77916493670886, -1.468833448275862 ], [ 29.779434430379748, -1.468833448275862 ], [ 29.779434430379748, -1.468294482758621 ], [ 29.77916493670886, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26784, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.468294482758621 ], [ 29.779703924050633, -1.468833448275862 ], [ 29.779973417721518, -1.468833448275862 ], [ 29.779973417721518, -1.468294482758621 ], [ 29.779703924050633, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26785, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.468294482758621 ], [ 29.780242911392406, -1.468833448275862 ], [ 29.780512405063291, -1.468833448275862 ], [ 29.780512405063291, -1.468294482758621 ], [ 29.780242911392406, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26786, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.468294482758621 ], [ 29.780781898734176, -1.468563965517242 ], [ 29.781051392405065, -1.468563965517242 ], [ 29.781051392405065, -1.468294482758621 ], [ 29.780781898734176, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26787, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.468294482758621 ], [ 29.781051392405065, -1.468833448275862 ], [ 29.78132088607595, -1.468833448275862 ], [ 29.78132088607595, -1.468294482758621 ], [ 29.781051392405065, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26788, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.468294482758621 ], [ 29.781590379746834, -1.468563965517242 ], [ 29.781859873417723, -1.468563965517242 ], [ 29.781859873417723, -1.468294482758621 ], [ 29.781590379746834, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26789, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.468294482758621 ], [ 29.781859873417723, -1.468563965517242 ], [ 29.782129367088608, -1.468563965517242 ], [ 29.782129367088608, -1.468294482758621 ], [ 29.781859873417723, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26790, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.468294482758621 ], [ 29.782398860759493, -1.468563965517242 ], [ 29.782668354430381, -1.468563965517242 ], [ 29.782668354430381, -1.468294482758621 ], [ 29.782398860759493, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26791, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.468294482758621 ], [ 29.782668354430381, -1.468563965517242 ], [ 29.782937848101266, -1.468563965517242 ], [ 29.782937848101266, -1.468294482758621 ], [ 29.782668354430381, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26792, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.468294482758621 ], [ 29.782937848101266, -1.468833448275862 ], [ 29.783207341772151, -1.468833448275862 ], [ 29.783207341772151, -1.468294482758621 ], [ 29.782937848101266, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26793, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.468294482758621 ], [ 29.783207341772151, -1.468563965517242 ], [ 29.783476835443039, -1.468563965517242 ], [ 29.783476835443039, -1.468294482758621 ], [ 29.783207341772151, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26794, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.468294482758621 ], [ 29.783476835443039, -1.468563965517242 ], [ 29.784015822784809, -1.468563965517242 ], [ 29.784015822784809, -1.468294482758621 ], [ 29.783476835443039, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26795, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.468294482758621 ], [ 29.784015822784809, -1.468563965517242 ], [ 29.784285316455698, -1.468563965517242 ], [ 29.784285316455698, -1.468294482758621 ], [ 29.784015822784809, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26796, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.468294482758621 ], [ 29.784554810126583, -1.468563965517242 ], [ 29.784824303797468, -1.468563965517242 ], [ 29.784824303797468, -1.468294482758621 ], [ 29.784554810126583, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26797, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.468294482758621 ], [ 29.784824303797468, -1.468563965517242 ], [ 29.785093797468356, -1.468563965517242 ], [ 29.785093797468356, -1.468294482758621 ], [ 29.784824303797468, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26798, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.468294482758621 ], [ 29.785093797468356, -1.468563965517242 ], [ 29.785363291139241, -1.468563965517242 ], [ 29.785363291139241, -1.468294482758621 ], [ 29.785093797468356, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26799, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.468294482758621 ], [ 29.785363291139241, -1.468563965517242 ], [ 29.785632784810126, -1.468563965517242 ], [ 29.785632784810126, -1.468294482758621 ], [ 29.785363291139241, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26800, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.468294482758621 ], [ 29.785632784810126, -1.468833448275862 ], [ 29.785902278481014, -1.468833448275862 ], [ 29.785902278481014, -1.468294482758621 ], [ 29.785632784810126, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26801, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.468294482758621 ], [ 29.785902278481014, -1.468563965517242 ], [ 29.786171772151899, -1.468563965517242 ], [ 29.786171772151899, -1.468294482758621 ], [ 29.785902278481014, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26802, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.468294482758621 ], [ 29.786171772151899, -1.468563965517242 ], [ 29.786441265822784, -1.468563965517242 ], [ 29.786441265822784, -1.468294482758621 ], [ 29.786171772151899, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26803, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.468294482758621 ], [ 29.786441265822784, -1.468563965517242 ], [ 29.786710759493673, -1.468563965517242 ], [ 29.786710759493673, -1.468294482758621 ], [ 29.786441265822784, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26804, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.468294482758621 ], [ 29.786980253164558, -1.468563965517242 ], [ 29.787249746835442, -1.468563965517242 ], [ 29.787249746835442, -1.468294482758621 ], [ 29.786980253164558, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26805, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.468294482758621 ], [ 29.787249746835442, -1.468563965517242 ], [ 29.787519240506327, -1.468563965517242 ], [ 29.787519240506327, -1.468294482758621 ], [ 29.787249746835442, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26806, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.468294482758621 ], [ 29.787519240506327, -1.468563965517242 ], [ 29.787788734177216, -1.468563965517242 ], [ 29.787788734177216, -1.468294482758621 ], [ 29.787519240506327, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26807, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.468294482758621 ], [ 29.787788734177216, -1.468563965517242 ], [ 29.788058227848101, -1.468563965517242 ], [ 29.788058227848101, -1.468294482758621 ], [ 29.787788734177216, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26808, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.468294482758621 ], [ 29.788058227848101, -1.468563965517242 ], [ 29.788327721518986, -1.468563965517242 ], [ 29.788327721518986, -1.468294482758621 ], [ 29.788058227848101, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26809, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.468294482758621 ], [ 29.788327721518986, -1.468833448275862 ], [ 29.788597215189874, -1.468833448275862 ], [ 29.788597215189874, -1.468294482758621 ], [ 29.788327721518986, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26810, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.468294482758621 ], [ 29.788597215189874, -1.468833448275862 ], [ 29.788866708860759, -1.468833448275862 ], [ 29.788866708860759, -1.468294482758621 ], [ 29.788597215189874, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26811, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79506506329114, -1.468294482758621 ], [ 29.79506506329114, -1.468833448275862 ], [ 29.795334556962025, -1.468833448275862 ], [ 29.795334556962025, -1.468294482758621 ], [ 29.79506506329114, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26812, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.468294482758621 ], [ 29.795334556962025, -1.468563965517242 ], [ 29.79560405063291, -1.468563965517242 ], [ 29.79560405063291, -1.468294482758621 ], [ 29.795334556962025, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26813, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.468294482758621 ], [ 29.79560405063291, -1.468563965517242 ], [ 29.795873544303799, -1.468563965517242 ], [ 29.795873544303799, -1.468294482758621 ], [ 29.79560405063291, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26814, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.468294482758621 ], [ 29.795873544303799, -1.468563965517242 ], [ 29.796143037974684, -1.468563965517242 ], [ 29.796143037974684, -1.468294482758621 ], [ 29.795873544303799, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26815, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.468294482758621 ], [ 29.796143037974684, -1.468563965517242 ], [ 29.796412531645569, -1.468563965517242 ], [ 29.796412531645569, -1.468294482758621 ], [ 29.796143037974684, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26816, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.468294482758621 ], [ 29.796412531645569, -1.468563965517242 ], [ 29.796682025316457, -1.468563965517242 ], [ 29.796682025316457, -1.468294482758621 ], [ 29.796412531645569, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26817, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.468294482758621 ], [ 29.796682025316457, -1.468563965517242 ], [ 29.796951518987342, -1.468563965517242 ], [ 29.796951518987342, -1.468294482758621 ], [ 29.796682025316457, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26818, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.468294482758621 ], [ 29.796951518987342, -1.468563965517242 ], [ 29.797221012658227, -1.468563965517242 ], [ 29.797221012658227, -1.468294482758621 ], [ 29.796951518987342, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26819, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.468294482758621 ], [ 29.797221012658227, -1.468563965517242 ], [ 29.797490506329115, -1.468563965517242 ], [ 29.797490506329115, -1.468294482758621 ], [ 29.797221012658227, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26820, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.468294482758621 ], [ 29.797490506329115, -1.468563965517242 ], [ 29.79776, -1.468563965517242 ], [ 29.79776, -1.468294482758621 ], [ 29.797490506329115, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26821, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.468294482758621 ], [ 29.79776, -1.468563965517242 ], [ 29.798029493670885, -1.468563965517242 ], [ 29.798029493670885, -1.468294482758621 ], [ 29.79776, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26822, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.468294482758621 ], [ 29.798029493670885, -1.468563965517242 ], [ 29.798298987341774, -1.468563965517242 ], [ 29.798298987341774, -1.468294482758621 ], [ 29.798029493670885, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26823, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.468294482758621 ], [ 29.798298987341774, -1.468563965517242 ], [ 29.798568481012659, -1.468563965517242 ], [ 29.798568481012659, -1.468294482758621 ], [ 29.798298987341774, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26824, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.468563965517242 ], [ 29.756796962025316, -1.468833448275862 ], [ 29.757066455696201, -1.468833448275862 ], [ 29.757066455696201, -1.468563965517242 ], [ 29.756796962025316, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26825, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.468563965517242 ], [ 29.757066455696201, -1.468833448275862 ], [ 29.757335949367089, -1.468833448275862 ], [ 29.757335949367089, -1.468563965517242 ], [ 29.757066455696201, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26826, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.468563965517242 ], [ 29.757335949367089, -1.468833448275862 ], [ 29.757605443037974, -1.468833448275862 ], [ 29.757605443037974, -1.468563965517242 ], [ 29.757335949367089, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26827, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.468563965517242 ], [ 29.757605443037974, -1.468833448275862 ], [ 29.757874936708859, -1.468833448275862 ], [ 29.757874936708859, -1.468563965517242 ], [ 29.757605443037974, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26828, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.468563965517242 ], [ 29.757874936708859, -1.468833448275862 ], [ 29.758144430379748, -1.468833448275862 ], [ 29.758144430379748, -1.468563965517242 ], [ 29.757874936708859, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26829, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.468563965517242 ], [ 29.758144430379748, -1.468833448275862 ], [ 29.758413924050632, -1.468833448275862 ], [ 29.758413924050632, -1.468563965517242 ], [ 29.758144430379748, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26830, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.468563965517242 ], [ 29.758413924050632, -1.468833448275862 ], [ 29.758683417721517, -1.468833448275862 ], [ 29.758683417721517, -1.468563965517242 ], [ 29.758413924050632, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26831, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.468563965517242 ], [ 29.758683417721517, -1.468833448275862 ], [ 29.758952911392406, -1.468833448275862 ], [ 29.758952911392406, -1.468563965517242 ], [ 29.758683417721517, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26832, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.468563965517242 ], [ 29.758952911392406, -1.468833448275862 ], [ 29.759222405063291, -1.468833448275862 ], [ 29.759222405063291, -1.468563965517242 ], [ 29.758952911392406, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26833, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.468563965517242 ], [ 29.759222405063291, -1.468833448275862 ], [ 29.759491898734176, -1.468833448275862 ], [ 29.759491898734176, -1.468563965517242 ], [ 29.759222405063291, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26834, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.468563965517242 ], [ 29.759491898734176, -1.468833448275862 ], [ 29.759761392405064, -1.468833448275862 ], [ 29.759761392405064, -1.468563965517242 ], [ 29.759491898734176, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26835, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.468563965517242 ], [ 29.759761392405064, -1.468833448275862 ], [ 29.760030886075949, -1.468833448275862 ], [ 29.760030886075949, -1.468563965517242 ], [ 29.759761392405064, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26836, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.468563965517242 ], [ 29.760030886075949, -1.468833448275862 ], [ 29.760300379746834, -1.468833448275862 ], [ 29.760300379746834, -1.468563965517242 ], [ 29.760030886075949, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26837, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.468563965517242 ], [ 29.760300379746834, -1.468833448275862 ], [ 29.760569873417722, -1.468833448275862 ], [ 29.760569873417722, -1.468563965517242 ], [ 29.760300379746834, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26838, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.468563965517242 ], [ 29.760569873417722, -1.468833448275862 ], [ 29.760839367088607, -1.468833448275862 ], [ 29.760839367088607, -1.468563965517242 ], [ 29.760569873417722, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26839, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.468563965517242 ], [ 29.760839367088607, -1.468833448275862 ], [ 29.761108860759492, -1.468833448275862 ], [ 29.761108860759492, -1.468563965517242 ], [ 29.760839367088607, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26840, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.468563965517242 ], [ 29.761108860759492, -1.468833448275862 ], [ 29.761378354430381, -1.468833448275862 ], [ 29.761378354430381, -1.468563965517242 ], [ 29.761108860759492, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26841, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.468563965517242 ], [ 29.761378354430381, -1.468833448275862 ], [ 29.761647848101266, -1.468833448275862 ], [ 29.761647848101266, -1.468563965517242 ], [ 29.761378354430381, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26842, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.468563965517242 ], [ 29.770541139240507, -1.469372413793103 ], [ 29.770810632911392, -1.469372413793103 ], [ 29.770810632911392, -1.468563965517242 ], [ 29.770541139240507, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26843, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.468563965517242 ], [ 29.770810632911392, -1.469102931034483 ], [ 29.771080126582277, -1.469102931034483 ], [ 29.771080126582277, -1.468563965517242 ], [ 29.770810632911392, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26844, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.468563965517242 ], [ 29.771080126582277, -1.469102931034483 ], [ 29.771349620253165, -1.469102931034483 ], [ 29.771349620253165, -1.468563965517242 ], [ 29.771080126582277, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26845, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.468563965517242 ], [ 29.771888607594935, -1.468833448275862 ], [ 29.772158101265823, -1.468833448275862 ], [ 29.772158101265823, -1.468563965517242 ], [ 29.771888607594935, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26846, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.468563965517242 ], [ 29.772158101265823, -1.468833448275862 ], [ 29.772427594936708, -1.468833448275862 ], [ 29.772427594936708, -1.468563965517242 ], [ 29.772158101265823, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26847, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.468563965517242 ], [ 29.772427594936708, -1.468833448275862 ], [ 29.772697088607593, -1.468833448275862 ], [ 29.772697088607593, -1.468563965517242 ], [ 29.772427594936708, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26848, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.468563965517242 ], [ 29.772697088607593, -1.468833448275862 ], [ 29.772966582278482, -1.468833448275862 ], [ 29.772966582278482, -1.468563965517242 ], [ 29.772697088607593, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26849, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.468563965517242 ], [ 29.772966582278482, -1.468833448275862 ], [ 29.773236075949367, -1.468833448275862 ], [ 29.773236075949367, -1.468563965517242 ], [ 29.772966582278482, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26850, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.468563965517242 ], [ 29.773236075949367, -1.468833448275862 ], [ 29.773505569620252, -1.468833448275862 ], [ 29.773505569620252, -1.468563965517242 ], [ 29.773236075949367, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26851, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.468563965517242 ], [ 29.773505569620252, -1.468833448275862 ], [ 29.77377506329114, -1.468833448275862 ], [ 29.77377506329114, -1.468563965517242 ], [ 29.773505569620252, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26852, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.468563965517242 ], [ 29.77377506329114, -1.468833448275862 ], [ 29.774044556962025, -1.468833448275862 ], [ 29.774044556962025, -1.468563965517242 ], [ 29.77377506329114, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26853, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.468563965517242 ], [ 29.774044556962025, -1.468833448275862 ], [ 29.77431405063291, -1.468833448275862 ], [ 29.77431405063291, -1.468563965517242 ], [ 29.774044556962025, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26854, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.468563965517242 ], [ 29.77431405063291, -1.468833448275862 ], [ 29.774853037974683, -1.468833448275862 ], [ 29.774853037974683, -1.468563965517242 ], [ 29.77431405063291, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26855, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.468563965517242 ], [ 29.774853037974683, -1.469102931034483 ], [ 29.775122531645568, -1.469102931034483 ], [ 29.775122531645568, -1.468563965517242 ], [ 29.774853037974683, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26856, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.468563965517242 ], [ 29.775122531645568, -1.468833448275862 ], [ 29.775392025316457, -1.468833448275862 ], [ 29.775392025316457, -1.468563965517242 ], [ 29.775122531645568, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26857, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.468563965517242 ], [ 29.775392025316457, -1.468833448275862 ], [ 29.775661518987341, -1.468833448275862 ], [ 29.775661518987341, -1.468563965517242 ], [ 29.775392025316457, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26858, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.468563965517242 ], [ 29.775661518987341, -1.469102931034483 ], [ 29.775931012658226, -1.469102931034483 ], [ 29.775931012658226, -1.468563965517242 ], [ 29.775661518987341, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26859, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.468563965517242 ], [ 29.775931012658226, -1.468833448275862 ], [ 29.776200506329115, -1.468833448275862 ], [ 29.776200506329115, -1.468563965517242 ], [ 29.775931012658226, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26860, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.468563965517242 ], [ 29.776200506329115, -1.468833448275862 ], [ 29.77647, -1.468833448275862 ], [ 29.77647, -1.468563965517242 ], [ 29.776200506329115, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26861, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.468563965517242 ], [ 29.77647, -1.469102931034483 ], [ 29.776739493670885, -1.469102931034483 ], [ 29.776739493670885, -1.468563965517242 ], [ 29.77647, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26862, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.468563965517242 ], [ 29.777008987341773, -1.468833448275862 ], [ 29.777278481012658, -1.468833448275862 ], [ 29.777278481012658, -1.468563965517242 ], [ 29.777008987341773, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26863, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.468563965517242 ], [ 29.777278481012658, -1.469102931034483 ], [ 29.777547974683543, -1.469102931034483 ], [ 29.777547974683543, -1.468563965517242 ], [ 29.777278481012658, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26864, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.468563965517242 ], [ 29.777817468354431, -1.469102931034483 ], [ 29.778086962025316, -1.469102931034483 ], [ 29.778086962025316, -1.468563965517242 ], [ 29.777817468354431, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26865, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.468563965517242 ], [ 29.778086962025316, -1.469372413793103 ], [ 29.778356455696201, -1.469372413793103 ], [ 29.778356455696201, -1.468563965517242 ], [ 29.778086962025316, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26866, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.468563965517242 ], [ 29.77862594936709, -1.469102931034483 ], [ 29.778895443037975, -1.469102931034483 ], [ 29.778895443037975, -1.468563965517242 ], [ 29.77862594936709, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26867, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.468563965517242 ], [ 29.779434430379748, -1.469372413793103 ], [ 29.779703924050633, -1.469372413793103 ], [ 29.779703924050633, -1.468563965517242 ], [ 29.779434430379748, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26868, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.468563965517242 ], [ 29.780512405063291, -1.468833448275862 ], [ 29.780781898734176, -1.468833448275862 ], [ 29.780781898734176, -1.468563965517242 ], [ 29.780512405063291, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26869, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.468563965517242 ], [ 29.780781898734176, -1.468833448275862 ], [ 29.781051392405065, -1.468833448275862 ], [ 29.781051392405065, -1.468563965517242 ], [ 29.780781898734176, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26870, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.468563965517242 ], [ 29.78132088607595, -1.468833448275862 ], [ 29.781590379746834, -1.468833448275862 ], [ 29.781590379746834, -1.468563965517242 ], [ 29.78132088607595, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26871, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.468563965517242 ], [ 29.781590379746834, -1.468833448275862 ], [ 29.781859873417723, -1.468833448275862 ], [ 29.781859873417723, -1.468563965517242 ], [ 29.781590379746834, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26872, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.468563965517242 ], [ 29.781859873417723, -1.469102931034483 ], [ 29.782129367088608, -1.469102931034483 ], [ 29.782129367088608, -1.468563965517242 ], [ 29.781859873417723, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26873, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.468563965517242 ], [ 29.782129367088608, -1.468833448275862 ], [ 29.782398860759493, -1.468833448275862 ], [ 29.782398860759493, -1.468563965517242 ], [ 29.782129367088608, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26874, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.468563965517242 ], [ 29.782398860759493, -1.468833448275862 ], [ 29.782668354430381, -1.468833448275862 ], [ 29.782668354430381, -1.468563965517242 ], [ 29.782398860759493, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26875, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.468563965517242 ], [ 29.782668354430381, -1.468833448275862 ], [ 29.782937848101266, -1.468833448275862 ], [ 29.782937848101266, -1.468563965517242 ], [ 29.782668354430381, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26876, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.468563965517242 ], [ 29.783207341772151, -1.468833448275862 ], [ 29.783476835443039, -1.468833448275862 ], [ 29.783476835443039, -1.468563965517242 ], [ 29.783207341772151, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26877, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.468563965517242 ], [ 29.783476835443039, -1.468833448275862 ], [ 29.784015822784809, -1.468833448275862 ], [ 29.784015822784809, -1.468563965517242 ], [ 29.783476835443039, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26878, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.468563965517242 ], [ 29.784015822784809, -1.468833448275862 ], [ 29.784285316455698, -1.468833448275862 ], [ 29.784285316455698, -1.468563965517242 ], [ 29.784015822784809, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26879, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.468563965517242 ], [ 29.784285316455698, -1.468833448275862 ], [ 29.784554810126583, -1.468833448275862 ], [ 29.784554810126583, -1.468563965517242 ], [ 29.784285316455698, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26880, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.468563965517242 ], [ 29.784554810126583, -1.468833448275862 ], [ 29.784824303797468, -1.468833448275862 ], [ 29.784824303797468, -1.468563965517242 ], [ 29.784554810126583, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26881, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.468563965517242 ], [ 29.784824303797468, -1.468833448275862 ], [ 29.785093797468356, -1.468833448275862 ], [ 29.785093797468356, -1.468563965517242 ], [ 29.784824303797468, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26882, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.468563965517242 ], [ 29.785093797468356, -1.468833448275862 ], [ 29.785363291139241, -1.468833448275862 ], [ 29.785363291139241, -1.468563965517242 ], [ 29.785093797468356, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26883, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.468563965517242 ], [ 29.785363291139241, -1.468833448275862 ], [ 29.785632784810126, -1.468833448275862 ], [ 29.785632784810126, -1.468563965517242 ], [ 29.785363291139241, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26884, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.468563965517242 ], [ 29.785902278481014, -1.468833448275862 ], [ 29.786171772151899, -1.468833448275862 ], [ 29.786171772151899, -1.468563965517242 ], [ 29.785902278481014, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26885, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.468563965517242 ], [ 29.786171772151899, -1.468833448275862 ], [ 29.786441265822784, -1.468833448275862 ], [ 29.786441265822784, -1.468563965517242 ], [ 29.786171772151899, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26886, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.468563965517242 ], [ 29.786441265822784, -1.468833448275862 ], [ 29.786710759493673, -1.468833448275862 ], [ 29.786710759493673, -1.468563965517242 ], [ 29.786441265822784, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26887, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.468563965517242 ], [ 29.786710759493673, -1.468833448275862 ], [ 29.786980253164558, -1.468833448275862 ], [ 29.786980253164558, -1.468563965517242 ], [ 29.786710759493673, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26888, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.468563965517242 ], [ 29.786980253164558, -1.468833448275862 ], [ 29.787249746835442, -1.468833448275862 ], [ 29.787249746835442, -1.468563965517242 ], [ 29.786980253164558, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26889, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.468563965517242 ], [ 29.787249746835442, -1.468833448275862 ], [ 29.787519240506327, -1.468833448275862 ], [ 29.787519240506327, -1.468563965517242 ], [ 29.787249746835442, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26890, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.468563965517242 ], [ 29.787519240506327, -1.468833448275862 ], [ 29.787788734177216, -1.468833448275862 ], [ 29.787788734177216, -1.468563965517242 ], [ 29.787519240506327, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26891, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.468563965517242 ], [ 29.787788734177216, -1.468833448275862 ], [ 29.788058227848101, -1.468833448275862 ], [ 29.788058227848101, -1.468563965517242 ], [ 29.787788734177216, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26892, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.468563965517242 ], [ 29.788058227848101, -1.469102931034483 ], [ 29.788327721518986, -1.469102931034483 ], [ 29.788327721518986, -1.468563965517242 ], [ 29.788058227848101, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26893, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.468563965517242 ], [ 29.788866708860759, -1.468833448275862 ], [ 29.789136202531644, -1.468833448275862 ], [ 29.789136202531644, -1.468563965517242 ], [ 29.788866708860759, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26894, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.468563965517242 ], [ 29.789136202531644, -1.468833448275862 ], [ 29.789405696202532, -1.468833448275862 ], [ 29.789405696202532, -1.468563965517242 ], [ 29.789136202531644, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26895, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.468563965517242 ], [ 29.789405696202532, -1.468833448275862 ], [ 29.789675189873417, -1.468833448275862 ], [ 29.789675189873417, -1.468563965517242 ], [ 29.789405696202532, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26896, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.468563965517242 ], [ 29.789675189873417, -1.469641896551724 ], [ 29.789944683544302, -1.469641896551724 ], [ 29.789944683544302, -1.468563965517242 ], [ 29.789675189873417, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26897, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.468563965517242 ], [ 29.795334556962025, -1.468833448275862 ], [ 29.79560405063291, -1.468833448275862 ], [ 29.79560405063291, -1.468563965517242 ], [ 29.795334556962025, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26898, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.468563965517242 ], [ 29.79560405063291, -1.468833448275862 ], [ 29.795873544303799, -1.468833448275862 ], [ 29.795873544303799, -1.468563965517242 ], [ 29.79560405063291, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26899, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.468563965517242 ], [ 29.795873544303799, -1.468833448275862 ], [ 29.796143037974684, -1.468833448275862 ], [ 29.796143037974684, -1.468563965517242 ], [ 29.795873544303799, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26900, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.468563965517242 ], [ 29.796143037974684, -1.468833448275862 ], [ 29.796412531645569, -1.468833448275862 ], [ 29.796412531645569, -1.468563965517242 ], [ 29.796143037974684, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26901, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.468563965517242 ], [ 29.796412531645569, -1.468833448275862 ], [ 29.796682025316457, -1.468833448275862 ], [ 29.796682025316457, -1.468563965517242 ], [ 29.796412531645569, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26902, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.468563965517242 ], [ 29.796682025316457, -1.468833448275862 ], [ 29.796951518987342, -1.468833448275862 ], [ 29.796951518987342, -1.468563965517242 ], [ 29.796682025316457, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26903, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.468563965517242 ], [ 29.796951518987342, -1.468833448275862 ], [ 29.797221012658227, -1.468833448275862 ], [ 29.797221012658227, -1.468563965517242 ], [ 29.796951518987342, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26904, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.468563965517242 ], [ 29.797221012658227, -1.468833448275862 ], [ 29.797490506329115, -1.468833448275862 ], [ 29.797490506329115, -1.468563965517242 ], [ 29.797221012658227, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26905, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.468563965517242 ], [ 29.797490506329115, -1.468833448275862 ], [ 29.79776, -1.468833448275862 ], [ 29.79776, -1.468563965517242 ], [ 29.797490506329115, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26906, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.468563965517242 ], [ 29.79776, -1.468833448275862 ], [ 29.798029493670885, -1.468833448275862 ], [ 29.798029493670885, -1.468563965517242 ], [ 29.79776, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26907, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.468563965517242 ], [ 29.798029493670885, -1.468833448275862 ], [ 29.798298987341774, -1.468833448275862 ], [ 29.798298987341774, -1.468563965517242 ], [ 29.798029493670885, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26908, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.468563965517242 ], [ 29.798298987341774, -1.468833448275862 ], [ 29.798568481012659, -1.468833448275862 ], [ 29.798568481012659, -1.468563965517242 ], [ 29.798298987341774, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26909, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.468563965517242 ], [ 29.798568481012659, -1.468833448275862 ], [ 29.798837974683543, -1.468833448275862 ], [ 29.798837974683543, -1.468563965517242 ], [ 29.798568481012659, -1.468563965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26910, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.468833448275862 ], [ 29.755988481012658, -1.469102931034483 ], [ 29.756257974683542, -1.469102931034483 ], [ 29.756257974683542, -1.468833448275862 ], [ 29.755988481012658, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26911, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.468833448275862 ], [ 29.756257974683542, -1.469102931034483 ], [ 29.756796962025316, -1.469102931034483 ], [ 29.756796962025316, -1.468833448275862 ], [ 29.756257974683542, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26912, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.468833448275862 ], [ 29.756796962025316, -1.469102931034483 ], [ 29.757066455696201, -1.469102931034483 ], [ 29.757066455696201, -1.468833448275862 ], [ 29.756796962025316, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26913, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.468833448275862 ], [ 29.757066455696201, -1.469102931034483 ], [ 29.757335949367089, -1.469102931034483 ], [ 29.757335949367089, -1.468833448275862 ], [ 29.757066455696201, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26914, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.468833448275862 ], [ 29.757335949367089, -1.469102931034483 ], [ 29.757605443037974, -1.469102931034483 ], [ 29.757605443037974, -1.468833448275862 ], [ 29.757335949367089, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26915, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.468833448275862 ], [ 29.757605443037974, -1.469102931034483 ], [ 29.757874936708859, -1.469102931034483 ], [ 29.757874936708859, -1.468833448275862 ], [ 29.757605443037974, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26916, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.468833448275862 ], [ 29.757874936708859, -1.469102931034483 ], [ 29.758144430379748, -1.469102931034483 ], [ 29.758144430379748, -1.468833448275862 ], [ 29.757874936708859, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26917, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.468833448275862 ], [ 29.758144430379748, -1.469102931034483 ], [ 29.758413924050632, -1.469102931034483 ], [ 29.758413924050632, -1.468833448275862 ], [ 29.758144430379748, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26918, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.468833448275862 ], [ 29.758413924050632, -1.469102931034483 ], [ 29.758683417721517, -1.469102931034483 ], [ 29.758683417721517, -1.468833448275862 ], [ 29.758413924050632, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26919, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.468833448275862 ], [ 29.758683417721517, -1.469102931034483 ], [ 29.758952911392406, -1.469102931034483 ], [ 29.758952911392406, -1.468833448275862 ], [ 29.758683417721517, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26920, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.468833448275862 ], [ 29.758952911392406, -1.469102931034483 ], [ 29.759222405063291, -1.469102931034483 ], [ 29.759222405063291, -1.468833448275862 ], [ 29.758952911392406, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26921, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.468833448275862 ], [ 29.759222405063291, -1.469102931034483 ], [ 29.759491898734176, -1.469102931034483 ], [ 29.759491898734176, -1.468833448275862 ], [ 29.759222405063291, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26922, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.468833448275862 ], [ 29.759491898734176, -1.469102931034483 ], [ 29.759761392405064, -1.469102931034483 ], [ 29.759761392405064, -1.468833448275862 ], [ 29.759491898734176, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26923, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.468833448275862 ], [ 29.759761392405064, -1.469102931034483 ], [ 29.760030886075949, -1.469102931034483 ], [ 29.760030886075949, -1.468833448275862 ], [ 29.759761392405064, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26924, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.468833448275862 ], [ 29.760030886075949, -1.469102931034483 ], [ 29.760300379746834, -1.469102931034483 ], [ 29.760300379746834, -1.468833448275862 ], [ 29.760030886075949, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26925, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.468833448275862 ], [ 29.760300379746834, -1.469102931034483 ], [ 29.760569873417722, -1.469102931034483 ], [ 29.760569873417722, -1.468833448275862 ], [ 29.760300379746834, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26926, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.468833448275862 ], [ 29.760569873417722, -1.469102931034483 ], [ 29.760839367088607, -1.469102931034483 ], [ 29.760839367088607, -1.468833448275862 ], [ 29.760569873417722, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26927, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.468833448275862 ], [ 29.760839367088607, -1.469102931034483 ], [ 29.761108860759492, -1.469102931034483 ], [ 29.761108860759492, -1.468833448275862 ], [ 29.760839367088607, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26928, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.468833448275862 ], [ 29.761108860759492, -1.469102931034483 ], [ 29.761378354430381, -1.469102931034483 ], [ 29.761378354430381, -1.468833448275862 ], [ 29.761108860759492, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26929, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.468833448275862 ], [ 29.761378354430381, -1.469102931034483 ], [ 29.761647848101266, -1.469102931034483 ], [ 29.761647848101266, -1.468833448275862 ], [ 29.761378354430381, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26930, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.468833448275862 ], [ 29.771349620253165, -1.469102931034483 ], [ 29.77161911392405, -1.469102931034483 ], [ 29.77161911392405, -1.468833448275862 ], [ 29.771349620253165, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26931, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.468833448275862 ], [ 29.77161911392405, -1.469102931034483 ], [ 29.771888607594935, -1.469102931034483 ], [ 29.771888607594935, -1.468833448275862 ], [ 29.77161911392405, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26932, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.468833448275862 ], [ 29.771888607594935, -1.469102931034483 ], [ 29.772158101265823, -1.469102931034483 ], [ 29.772158101265823, -1.468833448275862 ], [ 29.771888607594935, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26933, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.468833448275862 ], [ 29.772158101265823, -1.469102931034483 ], [ 29.772427594936708, -1.469102931034483 ], [ 29.772427594936708, -1.468833448275862 ], [ 29.772158101265823, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26934, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.468833448275862 ], [ 29.772427594936708, -1.469102931034483 ], [ 29.772697088607593, -1.469102931034483 ], [ 29.772697088607593, -1.468833448275862 ], [ 29.772427594936708, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26935, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.468833448275862 ], [ 29.772697088607593, -1.469102931034483 ], [ 29.772966582278482, -1.469102931034483 ], [ 29.772966582278482, -1.468833448275862 ], [ 29.772697088607593, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26936, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.468833448275862 ], [ 29.772966582278482, -1.469102931034483 ], [ 29.773236075949367, -1.469102931034483 ], [ 29.773236075949367, -1.468833448275862 ], [ 29.772966582278482, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26937, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.468833448275862 ], [ 29.773236075949367, -1.469102931034483 ], [ 29.773505569620252, -1.469102931034483 ], [ 29.773505569620252, -1.468833448275862 ], [ 29.773236075949367, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26938, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.468833448275862 ], [ 29.773505569620252, -1.469372413793103 ], [ 29.77377506329114, -1.469372413793103 ], [ 29.77377506329114, -1.468833448275862 ], [ 29.773505569620252, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26939, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.468833448275862 ], [ 29.77377506329114, -1.469102931034483 ], [ 29.774044556962025, -1.469102931034483 ], [ 29.774044556962025, -1.468833448275862 ], [ 29.77377506329114, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26940, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.468833448275862 ], [ 29.774044556962025, -1.469102931034483 ], [ 29.77431405063291, -1.469102931034483 ], [ 29.77431405063291, -1.468833448275862 ], [ 29.774044556962025, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26941, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.468833448275862 ], [ 29.77431405063291, -1.469372413793103 ], [ 29.774853037974683, -1.469372413793103 ], [ 29.774853037974683, -1.468833448275862 ], [ 29.77431405063291, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26942, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.468833448275862 ], [ 29.775122531645568, -1.469102931034483 ], [ 29.775392025316457, -1.469102931034483 ], [ 29.775392025316457, -1.468833448275862 ], [ 29.775122531645568, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26943, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.468833448275862 ], [ 29.775392025316457, -1.469372413793103 ], [ 29.775661518987341, -1.469372413793103 ], [ 29.775661518987341, -1.468833448275862 ], [ 29.775392025316457, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26944, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.468833448275862 ], [ 29.775931012658226, -1.469372413793103 ], [ 29.776200506329115, -1.469372413793103 ], [ 29.776200506329115, -1.468833448275862 ], [ 29.775931012658226, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26945, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.468833448275862 ], [ 29.776739493670885, -1.469372413793103 ], [ 29.777008987341773, -1.469372413793103 ], [ 29.777008987341773, -1.468833448275862 ], [ 29.776739493670885, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26946, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.468833448275862 ], [ 29.780242911392406, -1.469102931034483 ], [ 29.780512405063291, -1.469102931034483 ], [ 29.780512405063291, -1.468833448275862 ], [ 29.780242911392406, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26947, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.468833448275862 ], [ 29.780512405063291, -1.469102931034483 ], [ 29.780781898734176, -1.469102931034483 ], [ 29.780781898734176, -1.468833448275862 ], [ 29.780512405063291, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26948, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.468833448275862 ], [ 29.780781898734176, -1.469372413793103 ], [ 29.781051392405065, -1.469372413793103 ], [ 29.781051392405065, -1.468833448275862 ], [ 29.780781898734176, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26949, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.468833448275862 ], [ 29.781051392405065, -1.469102931034483 ], [ 29.78132088607595, -1.469102931034483 ], [ 29.78132088607595, -1.468833448275862 ], [ 29.781051392405065, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26950, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.468833448275862 ], [ 29.78132088607595, -1.469102931034483 ], [ 29.781590379746834, -1.469102931034483 ], [ 29.781590379746834, -1.468833448275862 ], [ 29.78132088607595, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26951, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.468833448275862 ], [ 29.781590379746834, -1.469102931034483 ], [ 29.781859873417723, -1.469102931034483 ], [ 29.781859873417723, -1.468833448275862 ], [ 29.781590379746834, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26952, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.468833448275862 ], [ 29.782129367088608, -1.469102931034483 ], [ 29.782398860759493, -1.469102931034483 ], [ 29.782398860759493, -1.468833448275862 ], [ 29.782129367088608, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26953, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.468833448275862 ], [ 29.782398860759493, -1.469102931034483 ], [ 29.782668354430381, -1.469102931034483 ], [ 29.782668354430381, -1.468833448275862 ], [ 29.782398860759493, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26954, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.468833448275862 ], [ 29.782668354430381, -1.469102931034483 ], [ 29.782937848101266, -1.469102931034483 ], [ 29.782937848101266, -1.468833448275862 ], [ 29.782668354430381, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26955, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.468833448275862 ], [ 29.782937848101266, -1.469102931034483 ], [ 29.783207341772151, -1.469102931034483 ], [ 29.783207341772151, -1.468833448275862 ], [ 29.782937848101266, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26956, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.468833448275862 ], [ 29.783207341772151, -1.469102931034483 ], [ 29.783476835443039, -1.469102931034483 ], [ 29.783476835443039, -1.468833448275862 ], [ 29.783207341772151, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26957, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.468833448275862 ], [ 29.783476835443039, -1.469102931034483 ], [ 29.784015822784809, -1.469102931034483 ], [ 29.784015822784809, -1.468833448275862 ], [ 29.783476835443039, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26958, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.468833448275862 ], [ 29.784015822784809, -1.469102931034483 ], [ 29.784285316455698, -1.469102931034483 ], [ 29.784285316455698, -1.468833448275862 ], [ 29.784015822784809, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26959, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.468833448275862 ], [ 29.784285316455698, -1.469102931034483 ], [ 29.784554810126583, -1.469102931034483 ], [ 29.784554810126583, -1.468833448275862 ], [ 29.784285316455698, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26960, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.468833448275862 ], [ 29.784554810126583, -1.469372413793103 ], [ 29.784824303797468, -1.469372413793103 ], [ 29.784824303797468, -1.468833448275862 ], [ 29.784554810126583, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26961, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.468833448275862 ], [ 29.784824303797468, -1.469102931034483 ], [ 29.785093797468356, -1.469102931034483 ], [ 29.785093797468356, -1.468833448275862 ], [ 29.784824303797468, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26962, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.468833448275862 ], [ 29.785093797468356, -1.469102931034483 ], [ 29.785363291139241, -1.469102931034483 ], [ 29.785363291139241, -1.468833448275862 ], [ 29.785093797468356, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26963, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.468833448275862 ], [ 29.785363291139241, -1.469102931034483 ], [ 29.785632784810126, -1.469102931034483 ], [ 29.785632784810126, -1.468833448275862 ], [ 29.785363291139241, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26964, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.468833448275862 ], [ 29.785632784810126, -1.469102931034483 ], [ 29.785902278481014, -1.469102931034483 ], [ 29.785902278481014, -1.468833448275862 ], [ 29.785632784810126, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26965, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.468833448275862 ], [ 29.785902278481014, -1.469102931034483 ], [ 29.786171772151899, -1.469102931034483 ], [ 29.786171772151899, -1.468833448275862 ], [ 29.785902278481014, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26966, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.468833448275862 ], [ 29.786171772151899, -1.469102931034483 ], [ 29.786441265822784, -1.469102931034483 ], [ 29.786441265822784, -1.468833448275862 ], [ 29.786171772151899, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26967, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.468833448275862 ], [ 29.786441265822784, -1.469102931034483 ], [ 29.786710759493673, -1.469102931034483 ], [ 29.786710759493673, -1.468833448275862 ], [ 29.786441265822784, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26968, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.468833448275862 ], [ 29.786710759493673, -1.469102931034483 ], [ 29.786980253164558, -1.469102931034483 ], [ 29.786980253164558, -1.468833448275862 ], [ 29.786710759493673, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26969, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.468833448275862 ], [ 29.786980253164558, -1.469372413793103 ], [ 29.787249746835442, -1.469372413793103 ], [ 29.787249746835442, -1.468833448275862 ], [ 29.786980253164558, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26970, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.468833448275862 ], [ 29.787249746835442, -1.469102931034483 ], [ 29.787519240506327, -1.469102931034483 ], [ 29.787519240506327, -1.468833448275862 ], [ 29.787249746835442, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26971, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.468833448275862 ], [ 29.787519240506327, -1.469102931034483 ], [ 29.787788734177216, -1.469102931034483 ], [ 29.787788734177216, -1.468833448275862 ], [ 29.787519240506327, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26972, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.468833448275862 ], [ 29.787788734177216, -1.469102931034483 ], [ 29.788058227848101, -1.469102931034483 ], [ 29.788058227848101, -1.468833448275862 ], [ 29.787788734177216, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26973, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.468833448275862 ], [ 29.788327721518986, -1.469102931034483 ], [ 29.788597215189874, -1.469102931034483 ], [ 29.788597215189874, -1.468833448275862 ], [ 29.788327721518986, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26974, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.468833448275862 ], [ 29.788597215189874, -1.469102931034483 ], [ 29.788866708860759, -1.469102931034483 ], [ 29.788866708860759, -1.468833448275862 ], [ 29.788597215189874, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26975, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.468833448275862 ], [ 29.788866708860759, -1.469102931034483 ], [ 29.789136202531644, -1.469102931034483 ], [ 29.789136202531644, -1.468833448275862 ], [ 29.788866708860759, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26976, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.468833448275862 ], [ 29.789136202531644, -1.469641896551724 ], [ 29.789405696202532, -1.469641896551724 ], [ 29.789405696202532, -1.468833448275862 ], [ 29.789136202531644, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26977, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.468833448275862 ], [ 29.789405696202532, -1.469641896551724 ], [ 29.789675189873417, -1.469641896551724 ], [ 29.789675189873417, -1.468833448275862 ], [ 29.789405696202532, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26978, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795334556962025, -1.468833448275862 ], [ 29.795334556962025, -1.469102931034483 ], [ 29.79560405063291, -1.469102931034483 ], [ 29.79560405063291, -1.468833448275862 ], [ 29.795334556962025, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26979, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.468833448275862 ], [ 29.79560405063291, -1.469102931034483 ], [ 29.795873544303799, -1.469102931034483 ], [ 29.795873544303799, -1.468833448275862 ], [ 29.79560405063291, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26980, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.468833448275862 ], [ 29.795873544303799, -1.469102931034483 ], [ 29.796143037974684, -1.469102931034483 ], [ 29.796143037974684, -1.468833448275862 ], [ 29.795873544303799, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26981, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.468833448275862 ], [ 29.796143037974684, -1.469102931034483 ], [ 29.796412531645569, -1.469102931034483 ], [ 29.796412531645569, -1.468833448275862 ], [ 29.796143037974684, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26982, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.468833448275862 ], [ 29.796412531645569, -1.469102931034483 ], [ 29.796682025316457, -1.469102931034483 ], [ 29.796682025316457, -1.468833448275862 ], [ 29.796412531645569, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26983, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.468833448275862 ], [ 29.796682025316457, -1.469102931034483 ], [ 29.796951518987342, -1.469102931034483 ], [ 29.796951518987342, -1.468833448275862 ], [ 29.796682025316457, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26984, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.468833448275862 ], [ 29.796951518987342, -1.469102931034483 ], [ 29.797221012658227, -1.469102931034483 ], [ 29.797221012658227, -1.468833448275862 ], [ 29.796951518987342, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26985, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.468833448275862 ], [ 29.797221012658227, -1.469102931034483 ], [ 29.797490506329115, -1.469102931034483 ], [ 29.797490506329115, -1.468833448275862 ], [ 29.797221012658227, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26986, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.468833448275862 ], [ 29.797490506329115, -1.469102931034483 ], [ 29.79776, -1.469102931034483 ], [ 29.79776, -1.468833448275862 ], [ 29.797490506329115, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26987, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.468833448275862 ], [ 29.79776, -1.469102931034483 ], [ 29.798029493670885, -1.469102931034483 ], [ 29.798029493670885, -1.468833448275862 ], [ 29.79776, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26988, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.468833448275862 ], [ 29.798029493670885, -1.469102931034483 ], [ 29.798298987341774, -1.469102931034483 ], [ 29.798298987341774, -1.468833448275862 ], [ 29.798029493670885, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26989, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.468833448275862 ], [ 29.798298987341774, -1.469372413793103 ], [ 29.798568481012659, -1.469372413793103 ], [ 29.798568481012659, -1.468833448275862 ], [ 29.798298987341774, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26990, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.468833448275862 ], [ 29.798568481012659, -1.469102931034483 ], [ 29.798837974683543, -1.469102931034483 ], [ 29.798837974683543, -1.468833448275862 ], [ 29.798568481012659, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26991, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.468833448275862 ], [ 29.800185443037975, -1.469372413793103 ], [ 29.80045493670886, -1.469372413793103 ], [ 29.80045493670886, -1.468833448275862 ], [ 29.800185443037975, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26992, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.468833448275862 ], [ 29.800993924050633, -1.469102931034483 ], [ 29.801263417721518, -1.469102931034483 ], [ 29.801263417721518, -1.468833448275862 ], [ 29.800993924050633, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26993, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.468833448275862 ], [ 29.801263417721518, -1.469102931034483 ], [ 29.801532911392407, -1.469102931034483 ], [ 29.801532911392407, -1.468833448275862 ], [ 29.801263417721518, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26994, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.468833448275862 ], [ 29.801532911392407, -1.469102931034483 ], [ 29.802071898734177, -1.469102931034483 ], [ 29.802071898734177, -1.468833448275862 ], [ 29.801532911392407, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26995, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.468833448275862 ], [ 29.802071898734177, -1.469102931034483 ], [ 29.802341392405065, -1.469102931034483 ], [ 29.802341392405065, -1.468833448275862 ], [ 29.802071898734177, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26996, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.755988481012658, -1.469102931034483 ], [ 29.755988481012658, -1.469641896551724 ], [ 29.756257974683542, -1.469641896551724 ], [ 29.756257974683542, -1.469102931034483 ], [ 29.755988481012658, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26997, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.469102931034483 ], [ 29.756257974683542, -1.469372413793103 ], [ 29.756796962025316, -1.469372413793103 ], [ 29.756796962025316, -1.469102931034483 ], [ 29.756257974683542, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26998, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.469102931034483 ], [ 29.756796962025316, -1.469372413793103 ], [ 29.757066455696201, -1.469372413793103 ], [ 29.757066455696201, -1.469102931034483 ], [ 29.756796962025316, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 26999, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.469102931034483 ], [ 29.757066455696201, -1.469372413793103 ], [ 29.757335949367089, -1.469372413793103 ], [ 29.757335949367089, -1.469102931034483 ], [ 29.757066455696201, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27000, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.469102931034483 ], [ 29.757335949367089, -1.469372413793103 ], [ 29.757605443037974, -1.469372413793103 ], [ 29.757605443037974, -1.469102931034483 ], [ 29.757335949367089, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27001, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.469102931034483 ], [ 29.757605443037974, -1.469372413793103 ], [ 29.757874936708859, -1.469372413793103 ], [ 29.757874936708859, -1.469102931034483 ], [ 29.757605443037974, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27002, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.469102931034483 ], [ 29.757874936708859, -1.469372413793103 ], [ 29.758144430379748, -1.469372413793103 ], [ 29.758144430379748, -1.469102931034483 ], [ 29.757874936708859, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27003, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.469102931034483 ], [ 29.758144430379748, -1.469372413793103 ], [ 29.758413924050632, -1.469372413793103 ], [ 29.758413924050632, -1.469102931034483 ], [ 29.758144430379748, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27004, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.469102931034483 ], [ 29.758413924050632, -1.469372413793103 ], [ 29.758683417721517, -1.469372413793103 ], [ 29.758683417721517, -1.469102931034483 ], [ 29.758413924050632, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27005, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.469102931034483 ], [ 29.758683417721517, -1.469372413793103 ], [ 29.758952911392406, -1.469372413793103 ], [ 29.758952911392406, -1.469102931034483 ], [ 29.758683417721517, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27006, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.469102931034483 ], [ 29.758952911392406, -1.469372413793103 ], [ 29.759222405063291, -1.469372413793103 ], [ 29.759222405063291, -1.469102931034483 ], [ 29.758952911392406, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27007, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.469102931034483 ], [ 29.759222405063291, -1.469372413793103 ], [ 29.759491898734176, -1.469372413793103 ], [ 29.759491898734176, -1.469102931034483 ], [ 29.759222405063291, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27008, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.469102931034483 ], [ 29.759491898734176, -1.469372413793103 ], [ 29.759761392405064, -1.469372413793103 ], [ 29.759761392405064, -1.469102931034483 ], [ 29.759491898734176, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27009, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.469102931034483 ], [ 29.759761392405064, -1.469372413793103 ], [ 29.760030886075949, -1.469372413793103 ], [ 29.760030886075949, -1.469102931034483 ], [ 29.759761392405064, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27010, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.469102931034483 ], [ 29.760030886075949, -1.469372413793103 ], [ 29.760300379746834, -1.469372413793103 ], [ 29.760300379746834, -1.469102931034483 ], [ 29.760030886075949, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27011, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.469102931034483 ], [ 29.760300379746834, -1.469372413793103 ], [ 29.760569873417722, -1.469372413793103 ], [ 29.760569873417722, -1.469102931034483 ], [ 29.760300379746834, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27012, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.469102931034483 ], [ 29.760569873417722, -1.469372413793103 ], [ 29.760839367088607, -1.469372413793103 ], [ 29.760839367088607, -1.469102931034483 ], [ 29.760569873417722, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27013, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.469102931034483 ], [ 29.760839367088607, -1.469372413793103 ], [ 29.761108860759492, -1.469372413793103 ], [ 29.761108860759492, -1.469102931034483 ], [ 29.760839367088607, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27014, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.469102931034483 ], [ 29.761108860759492, -1.469372413793103 ], [ 29.761378354430381, -1.469372413793103 ], [ 29.761378354430381, -1.469102931034483 ], [ 29.761108860759492, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27015, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.469102931034483 ], [ 29.770810632911392, -1.469372413793103 ], [ 29.771080126582277, -1.469372413793103 ], [ 29.771080126582277, -1.469102931034483 ], [ 29.770810632911392, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27016, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.469102931034483 ], [ 29.771080126582277, -1.469372413793103 ], [ 29.771349620253165, -1.469372413793103 ], [ 29.771349620253165, -1.469102931034483 ], [ 29.771080126582277, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27017, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.469102931034483 ], [ 29.771349620253165, -1.469372413793103 ], [ 29.77161911392405, -1.469372413793103 ], [ 29.77161911392405, -1.469102931034483 ], [ 29.771349620253165, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27018, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.469102931034483 ], [ 29.77161911392405, -1.469372413793103 ], [ 29.771888607594935, -1.469372413793103 ], [ 29.771888607594935, -1.469102931034483 ], [ 29.77161911392405, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27019, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.469102931034483 ], [ 29.771888607594935, -1.469372413793103 ], [ 29.772158101265823, -1.469372413793103 ], [ 29.772158101265823, -1.469102931034483 ], [ 29.771888607594935, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27020, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.469102931034483 ], [ 29.772158101265823, -1.469372413793103 ], [ 29.772427594936708, -1.469372413793103 ], [ 29.772427594936708, -1.469102931034483 ], [ 29.772158101265823, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27021, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.469102931034483 ], [ 29.772427594936708, -1.469641896551724 ], [ 29.772697088607593, -1.469641896551724 ], [ 29.772697088607593, -1.469102931034483 ], [ 29.772427594936708, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27022, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.469102931034483 ], [ 29.772697088607593, -1.469372413793103 ], [ 29.772966582278482, -1.469372413793103 ], [ 29.772966582278482, -1.469102931034483 ], [ 29.772697088607593, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27023, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.469102931034483 ], [ 29.772966582278482, -1.469372413793103 ], [ 29.773236075949367, -1.469372413793103 ], [ 29.773236075949367, -1.469102931034483 ], [ 29.772966582278482, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27024, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.469102931034483 ], [ 29.77377506329114, -1.469641896551724 ], [ 29.774044556962025, -1.469641896551724 ], [ 29.774044556962025, -1.469102931034483 ], [ 29.77377506329114, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27025, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.469102931034483 ], [ 29.780242911392406, -1.469372413793103 ], [ 29.780512405063291, -1.469372413793103 ], [ 29.780512405063291, -1.469102931034483 ], [ 29.780242911392406, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27026, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.469102931034483 ], [ 29.780512405063291, -1.469372413793103 ], [ 29.780781898734176, -1.469372413793103 ], [ 29.780781898734176, -1.469102931034483 ], [ 29.780512405063291, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27027, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.469102931034483 ], [ 29.781051392405065, -1.469372413793103 ], [ 29.78132088607595, -1.469372413793103 ], [ 29.78132088607595, -1.469102931034483 ], [ 29.781051392405065, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27028, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.469102931034483 ], [ 29.78132088607595, -1.469372413793103 ], [ 29.781590379746834, -1.469372413793103 ], [ 29.781590379746834, -1.469102931034483 ], [ 29.78132088607595, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27029, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.469102931034483 ], [ 29.781590379746834, -1.469372413793103 ], [ 29.781859873417723, -1.469372413793103 ], [ 29.781859873417723, -1.469102931034483 ], [ 29.781590379746834, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27030, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.469102931034483 ], [ 29.781859873417723, -1.469372413793103 ], [ 29.782129367088608, -1.469372413793103 ], [ 29.782129367088608, -1.469102931034483 ], [ 29.781859873417723, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27031, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.469102931034483 ], [ 29.782129367088608, -1.469372413793103 ], [ 29.782398860759493, -1.469372413793103 ], [ 29.782398860759493, -1.469102931034483 ], [ 29.782129367088608, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27032, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.469102931034483 ], [ 29.782398860759493, -1.469372413793103 ], [ 29.782668354430381, -1.469372413793103 ], [ 29.782668354430381, -1.469102931034483 ], [ 29.782398860759493, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27033, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.469102931034483 ], [ 29.782668354430381, -1.469372413793103 ], [ 29.782937848101266, -1.469372413793103 ], [ 29.782937848101266, -1.469102931034483 ], [ 29.782668354430381, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27034, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.469102931034483 ], [ 29.782937848101266, -1.469372413793103 ], [ 29.783207341772151, -1.469372413793103 ], [ 29.783207341772151, -1.469102931034483 ], [ 29.782937848101266, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27035, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.469102931034483 ], [ 29.783207341772151, -1.469641896551724 ], [ 29.783476835443039, -1.469641896551724 ], [ 29.783476835443039, -1.469102931034483 ], [ 29.783207341772151, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27036, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.469102931034483 ], [ 29.783476835443039, -1.469372413793103 ], [ 29.784015822784809, -1.469372413793103 ], [ 29.784015822784809, -1.469102931034483 ], [ 29.783476835443039, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27037, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.469102931034483 ], [ 29.784015822784809, -1.469372413793103 ], [ 29.784285316455698, -1.469372413793103 ], [ 29.784285316455698, -1.469102931034483 ], [ 29.784015822784809, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27038, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.469102931034483 ], [ 29.784285316455698, -1.469372413793103 ], [ 29.784554810126583, -1.469372413793103 ], [ 29.784554810126583, -1.469102931034483 ], [ 29.784285316455698, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27039, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.469102931034483 ], [ 29.784824303797468, -1.469372413793103 ], [ 29.785093797468356, -1.469372413793103 ], [ 29.785093797468356, -1.469102931034483 ], [ 29.784824303797468, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27040, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.469102931034483 ], [ 29.785093797468356, -1.469372413793103 ], [ 29.785363291139241, -1.469372413793103 ], [ 29.785363291139241, -1.469102931034483 ], [ 29.785093797468356, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27041, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.469102931034483 ], [ 29.785363291139241, -1.469372413793103 ], [ 29.785632784810126, -1.469372413793103 ], [ 29.785632784810126, -1.469102931034483 ], [ 29.785363291139241, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27042, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.469102931034483 ], [ 29.785632784810126, -1.469372413793103 ], [ 29.785902278481014, -1.469372413793103 ], [ 29.785902278481014, -1.469102931034483 ], [ 29.785632784810126, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27043, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.469102931034483 ], [ 29.785902278481014, -1.469641896551724 ], [ 29.786171772151899, -1.469641896551724 ], [ 29.786171772151899, -1.469102931034483 ], [ 29.785902278481014, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27044, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.469102931034483 ], [ 29.786171772151899, -1.469372413793103 ], [ 29.786441265822784, -1.469372413793103 ], [ 29.786441265822784, -1.469102931034483 ], [ 29.786171772151899, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27045, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.469102931034483 ], [ 29.786441265822784, -1.469372413793103 ], [ 29.786710759493673, -1.469372413793103 ], [ 29.786710759493673, -1.469102931034483 ], [ 29.786441265822784, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27046, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.469102931034483 ], [ 29.786710759493673, -1.469372413793103 ], [ 29.786980253164558, -1.469372413793103 ], [ 29.786980253164558, -1.469102931034483 ], [ 29.786710759493673, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27047, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.469102931034483 ], [ 29.787249746835442, -1.469372413793103 ], [ 29.787519240506327, -1.469372413793103 ], [ 29.787519240506327, -1.469102931034483 ], [ 29.787249746835442, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27048, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.469102931034483 ], [ 29.787519240506327, -1.469372413793103 ], [ 29.787788734177216, -1.469372413793103 ], [ 29.787788734177216, -1.469102931034483 ], [ 29.787519240506327, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27049, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.469102931034483 ], [ 29.787788734177216, -1.469372413793103 ], [ 29.788058227848101, -1.469372413793103 ], [ 29.788058227848101, -1.469102931034483 ], [ 29.787788734177216, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27050, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.469102931034483 ], [ 29.788058227848101, -1.469372413793103 ], [ 29.788327721518986, -1.469372413793103 ], [ 29.788327721518986, -1.469102931034483 ], [ 29.788058227848101, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27051, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.469102931034483 ], [ 29.788327721518986, -1.469641896551724 ], [ 29.788597215189874, -1.469641896551724 ], [ 29.788597215189874, -1.469102931034483 ], [ 29.788327721518986, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27052, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79560405063291, -1.469102931034483 ], [ 29.79560405063291, -1.469641896551724 ], [ 29.795873544303799, -1.469641896551724 ], [ 29.795873544303799, -1.469102931034483 ], [ 29.79560405063291, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27053, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.469102931034483 ], [ 29.795873544303799, -1.469372413793103 ], [ 29.796143037974684, -1.469372413793103 ], [ 29.796143037974684, -1.469102931034483 ], [ 29.795873544303799, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27054, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.469102931034483 ], [ 29.796143037974684, -1.469372413793103 ], [ 29.796412531645569, -1.469372413793103 ], [ 29.796412531645569, -1.469102931034483 ], [ 29.796143037974684, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27055, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.469102931034483 ], [ 29.796412531645569, -1.469372413793103 ], [ 29.796682025316457, -1.469372413793103 ], [ 29.796682025316457, -1.469102931034483 ], [ 29.796412531645569, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27056, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.469102931034483 ], [ 29.796682025316457, -1.469372413793103 ], [ 29.796951518987342, -1.469372413793103 ], [ 29.796951518987342, -1.469102931034483 ], [ 29.796682025316457, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27057, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.469102931034483 ], [ 29.796951518987342, -1.469372413793103 ], [ 29.797221012658227, -1.469372413793103 ], [ 29.797221012658227, -1.469102931034483 ], [ 29.796951518987342, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27058, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.469102931034483 ], [ 29.797221012658227, -1.469641896551724 ], [ 29.797490506329115, -1.469641896551724 ], [ 29.797490506329115, -1.469102931034483 ], [ 29.797221012658227, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27059, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.469102931034483 ], [ 29.797490506329115, -1.469372413793103 ], [ 29.79776, -1.469372413793103 ], [ 29.79776, -1.469102931034483 ], [ 29.797490506329115, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27060, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.469102931034483 ], [ 29.79776, -1.469372413793103 ], [ 29.798029493670885, -1.469372413793103 ], [ 29.798029493670885, -1.469102931034483 ], [ 29.79776, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27061, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.469102931034483 ], [ 29.798029493670885, -1.469372413793103 ], [ 29.798298987341774, -1.469372413793103 ], [ 29.798298987341774, -1.469102931034483 ], [ 29.798029493670885, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27062, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.468833448275862 ], [ 29.799107468354432, -1.468833448275862 ], [ 29.799107468354432, -1.469372413793103 ], [ 29.798568481012659, -1.469372413793103 ], [ 29.798568481012659, -1.469102931034483 ], [ 29.798837974683543, -1.469102931034483 ], [ 29.798837974683543, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27063, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.469102931034483 ], [ 29.799107468354432, -1.469641896551724 ], [ 29.799376962025317, -1.469641896551724 ], [ 29.799376962025317, -1.469102931034483 ], [ 29.799107468354432, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27064, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.469102931034483 ], [ 29.799646455696202, -1.469372413793103 ], [ 29.79991594936709, -1.469372413793103 ], [ 29.79991594936709, -1.469102931034483 ], [ 29.799646455696202, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27065, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.469102931034483 ], [ 29.79991594936709, -1.469372413793103 ], [ 29.800185443037975, -1.469372413793103 ], [ 29.800185443037975, -1.469102931034483 ], [ 29.79991594936709, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27066, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.469102931034483 ], [ 29.80045493670886, -1.469372413793103 ], [ 29.800724430379748, -1.469372413793103 ], [ 29.800724430379748, -1.469102931034483 ], [ 29.80045493670886, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27067, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.469102931034483 ], [ 29.800724430379748, -1.469372413793103 ], [ 29.800993924050633, -1.469372413793103 ], [ 29.800993924050633, -1.469102931034483 ], [ 29.800724430379748, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27068, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.469102931034483 ], [ 29.800993924050633, -1.469372413793103 ], [ 29.801263417721518, -1.469372413793103 ], [ 29.801263417721518, -1.469102931034483 ], [ 29.800993924050633, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27069, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.469102931034483 ], [ 29.801263417721518, -1.469372413793103 ], [ 29.801532911392407, -1.469372413793103 ], [ 29.801532911392407, -1.469102931034483 ], [ 29.801263417721518, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27070, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.469102931034483 ], [ 29.801532911392407, -1.469372413793103 ], [ 29.802071898734177, -1.469372413793103 ], [ 29.802071898734177, -1.469102931034483 ], [ 29.801532911392407, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27071, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.469102931034483 ], [ 29.802071898734177, -1.469372413793103 ], [ 29.802341392405065, -1.469372413793103 ], [ 29.802341392405065, -1.469102931034483 ], [ 29.802071898734177, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27072, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.469102931034483 ], [ 29.802341392405065, -1.469372413793103 ], [ 29.80261088607595, -1.469372413793103 ], [ 29.80261088607595, -1.469102931034483 ], [ 29.802341392405065, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27073, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.469372413793103 ], [ 29.756257974683542, -1.469641896551724 ], [ 29.756796962025316, -1.469641896551724 ], [ 29.756796962025316, -1.469372413793103 ], [ 29.756257974683542, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27074, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.469372413793103 ], [ 29.756796962025316, -1.469641896551724 ], [ 29.757066455696201, -1.469641896551724 ], [ 29.757066455696201, -1.469372413793103 ], [ 29.756796962025316, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27075, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.469372413793103 ], [ 29.757066455696201, -1.469641896551724 ], [ 29.757335949367089, -1.469641896551724 ], [ 29.757335949367089, -1.469372413793103 ], [ 29.757066455696201, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27076, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.469372413793103 ], [ 29.757335949367089, -1.469641896551724 ], [ 29.757605443037974, -1.469641896551724 ], [ 29.757605443037974, -1.469372413793103 ], [ 29.757335949367089, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27077, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.469372413793103 ], [ 29.757605443037974, -1.469641896551724 ], [ 29.757874936708859, -1.469641896551724 ], [ 29.757874936708859, -1.469372413793103 ], [ 29.757605443037974, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27078, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.469372413793103 ], [ 29.757874936708859, -1.469641896551724 ], [ 29.758144430379748, -1.469641896551724 ], [ 29.758144430379748, -1.469372413793103 ], [ 29.757874936708859, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27079, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.469372413793103 ], [ 29.758144430379748, -1.469641896551724 ], [ 29.758413924050632, -1.469641896551724 ], [ 29.758413924050632, -1.469372413793103 ], [ 29.758144430379748, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27080, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.469372413793103 ], [ 29.758413924050632, -1.469641896551724 ], [ 29.758683417721517, -1.469641896551724 ], [ 29.758683417721517, -1.469372413793103 ], [ 29.758413924050632, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27081, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.469372413793103 ], [ 29.758683417721517, -1.469641896551724 ], [ 29.758952911392406, -1.469641896551724 ], [ 29.758952911392406, -1.469372413793103 ], [ 29.758683417721517, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27082, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.469372413793103 ], [ 29.758952911392406, -1.469641896551724 ], [ 29.759222405063291, -1.469641896551724 ], [ 29.759222405063291, -1.469372413793103 ], [ 29.758952911392406, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27083, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.469372413793103 ], [ 29.759222405063291, -1.469641896551724 ], [ 29.759491898734176, -1.469641896551724 ], [ 29.759491898734176, -1.469372413793103 ], [ 29.759222405063291, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27084, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.469372413793103 ], [ 29.759491898734176, -1.469641896551724 ], [ 29.759761392405064, -1.469641896551724 ], [ 29.759761392405064, -1.469372413793103 ], [ 29.759491898734176, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27085, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.469372413793103 ], [ 29.759761392405064, -1.469641896551724 ], [ 29.760030886075949, -1.469641896551724 ], [ 29.760030886075949, -1.469372413793103 ], [ 29.759761392405064, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27086, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.469372413793103 ], [ 29.760030886075949, -1.469641896551724 ], [ 29.760300379746834, -1.469641896551724 ], [ 29.760300379746834, -1.469372413793103 ], [ 29.760030886075949, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27087, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.469372413793103 ], [ 29.760300379746834, -1.469641896551724 ], [ 29.760569873417722, -1.469641896551724 ], [ 29.760569873417722, -1.469372413793103 ], [ 29.760300379746834, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27088, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.469372413793103 ], [ 29.760569873417722, -1.469641896551724 ], [ 29.760839367088607, -1.469641896551724 ], [ 29.760839367088607, -1.469372413793103 ], [ 29.760569873417722, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27089, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.469372413793103 ], [ 29.760839367088607, -1.469641896551724 ], [ 29.761108860759492, -1.469641896551724 ], [ 29.761108860759492, -1.469372413793103 ], [ 29.760839367088607, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27090, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.469372413793103 ], [ 29.761108860759492, -1.469641896551724 ], [ 29.761378354430381, -1.469641896551724 ], [ 29.761378354430381, -1.469372413793103 ], [ 29.761108860759492, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27091, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.469372413793103 ], [ 29.761378354430381, -1.469641896551724 ], [ 29.761647848101266, -1.469641896551724 ], [ 29.761647848101266, -1.469372413793103 ], [ 29.761378354430381, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27092, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.469372413793103 ], [ 29.770810632911392, -1.469641896551724 ], [ 29.771080126582277, -1.469641896551724 ], [ 29.771080126582277, -1.469372413793103 ], [ 29.770810632911392, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27093, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.469372413793103 ], [ 29.771080126582277, -1.469641896551724 ], [ 29.771349620253165, -1.469641896551724 ], [ 29.771349620253165, -1.469372413793103 ], [ 29.771080126582277, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27094, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.469372413793103 ], [ 29.771349620253165, -1.469641896551724 ], [ 29.77161911392405, -1.469641896551724 ], [ 29.77161911392405, -1.469372413793103 ], [ 29.771349620253165, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27095, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.469372413793103 ], [ 29.77161911392405, -1.469641896551724 ], [ 29.771888607594935, -1.469641896551724 ], [ 29.771888607594935, -1.469372413793103 ], [ 29.77161911392405, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27096, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.469372413793103 ], [ 29.780242911392406, -1.469641896551724 ], [ 29.780512405063291, -1.469641896551724 ], [ 29.780512405063291, -1.469372413793103 ], [ 29.780242911392406, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27097, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.469372413793103 ], [ 29.780512405063291, -1.469641896551724 ], [ 29.780781898734176, -1.469641896551724 ], [ 29.780781898734176, -1.469372413793103 ], [ 29.780512405063291, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27098, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.469372413793103 ], [ 29.780781898734176, -1.469641896551724 ], [ 29.781051392405065, -1.469641896551724 ], [ 29.781051392405065, -1.469372413793103 ], [ 29.780781898734176, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27099, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.469372413793103 ], [ 29.781051392405065, -1.469641896551724 ], [ 29.78132088607595, -1.469641896551724 ], [ 29.78132088607595, -1.469372413793103 ], [ 29.781051392405065, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27100, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.469372413793103 ], [ 29.78132088607595, -1.469641896551724 ], [ 29.781590379746834, -1.469641896551724 ], [ 29.781590379746834, -1.469372413793103 ], [ 29.78132088607595, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27101, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.469372413793103 ], [ 29.781590379746834, -1.469641896551724 ], [ 29.781859873417723, -1.469641896551724 ], [ 29.781859873417723, -1.469372413793103 ], [ 29.781590379746834, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27102, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.469372413793103 ], [ 29.781859873417723, -1.469641896551724 ], [ 29.782129367088608, -1.469641896551724 ], [ 29.782129367088608, -1.469372413793103 ], [ 29.781859873417723, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27103, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.469372413793103 ], [ 29.782398860759493, -1.469641896551724 ], [ 29.782668354430381, -1.469641896551724 ], [ 29.782668354430381, -1.469372413793103 ], [ 29.782398860759493, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27104, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.469372413793103 ], [ 29.782668354430381, -1.469641896551724 ], [ 29.782937848101266, -1.469641896551724 ], [ 29.782937848101266, -1.469372413793103 ], [ 29.782668354430381, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27105, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.469372413793103 ], [ 29.782937848101266, -1.469641896551724 ], [ 29.783207341772151, -1.469641896551724 ], [ 29.783207341772151, -1.469372413793103 ], [ 29.782937848101266, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27106, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.469372413793103 ], [ 29.783476835443039, -1.469641896551724 ], [ 29.784015822784809, -1.469641896551724 ], [ 29.784015822784809, -1.469372413793103 ], [ 29.783476835443039, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27107, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.469372413793103 ], [ 29.784015822784809, -1.469641896551724 ], [ 29.784285316455698, -1.469641896551724 ], [ 29.784285316455698, -1.469372413793103 ], [ 29.784015822784809, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27108, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.469372413793103 ], [ 29.784285316455698, -1.469641896551724 ], [ 29.784554810126583, -1.469641896551724 ], [ 29.784554810126583, -1.469372413793103 ], [ 29.784285316455698, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27109, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.469372413793103 ], [ 29.784554810126583, -1.469641896551724 ], [ 29.784824303797468, -1.469641896551724 ], [ 29.784824303797468, -1.469372413793103 ], [ 29.784554810126583, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27110, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.469372413793103 ], [ 29.784824303797468, -1.469641896551724 ], [ 29.785093797468356, -1.469641896551724 ], [ 29.785093797468356, -1.469372413793103 ], [ 29.784824303797468, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27111, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.469372413793103 ], [ 29.785093797468356, -1.469641896551724 ], [ 29.785363291139241, -1.469641896551724 ], [ 29.785363291139241, -1.469372413793103 ], [ 29.785093797468356, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27112, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.469372413793103 ], [ 29.785363291139241, -1.469641896551724 ], [ 29.785632784810126, -1.469641896551724 ], [ 29.785632784810126, -1.469372413793103 ], [ 29.785363291139241, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27113, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.469372413793103 ], [ 29.785632784810126, -1.469641896551724 ], [ 29.785902278481014, -1.469641896551724 ], [ 29.785902278481014, -1.469372413793103 ], [ 29.785632784810126, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27114, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.469372413793103 ], [ 29.786171772151899, -1.469641896551724 ], [ 29.786441265822784, -1.469641896551724 ], [ 29.786441265822784, -1.469372413793103 ], [ 29.786171772151899, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27115, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.469372413793103 ], [ 29.786441265822784, -1.469641896551724 ], [ 29.786710759493673, -1.469641896551724 ], [ 29.786710759493673, -1.469372413793103 ], [ 29.786441265822784, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27116, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.469372413793103 ], [ 29.786710759493673, -1.469641896551724 ], [ 29.786980253164558, -1.469641896551724 ], [ 29.786980253164558, -1.469372413793103 ], [ 29.786710759493673, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27117, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.469372413793103 ], [ 29.786980253164558, -1.469641896551724 ], [ 29.787249746835442, -1.469641896551724 ], [ 29.787249746835442, -1.469372413793103 ], [ 29.786980253164558, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27118, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.469372413793103 ], [ 29.787249746835442, -1.469641896551724 ], [ 29.787519240506327, -1.469641896551724 ], [ 29.787519240506327, -1.469372413793103 ], [ 29.787249746835442, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27119, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.469372413793103 ], [ 29.787519240506327, -1.469641896551724 ], [ 29.787788734177216, -1.469641896551724 ], [ 29.787788734177216, -1.469372413793103 ], [ 29.787519240506327, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27120, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.469372413793103 ], [ 29.795873544303799, -1.469641896551724 ], [ 29.796143037974684, -1.469641896551724 ], [ 29.796143037974684, -1.469372413793103 ], [ 29.795873544303799, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27121, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.469372413793103 ], [ 29.796143037974684, -1.469641896551724 ], [ 29.796412531645569, -1.469641896551724 ], [ 29.796412531645569, -1.469372413793103 ], [ 29.796143037974684, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27122, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.469372413793103 ], [ 29.796412531645569, -1.469641896551724 ], [ 29.796682025316457, -1.469641896551724 ], [ 29.796682025316457, -1.469372413793103 ], [ 29.796412531645569, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27123, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.469372413793103 ], [ 29.797490506329115, -1.469641896551724 ], [ 29.79776, -1.469641896551724 ], [ 29.79776, -1.469372413793103 ], [ 29.797490506329115, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27124, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.469372413793103 ], [ 29.79776, -1.469641896551724 ], [ 29.798029493670885, -1.469641896551724 ], [ 29.798029493670885, -1.469372413793103 ], [ 29.79776, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27125, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.469372413793103 ], [ 29.798029493670885, -1.469641896551724 ], [ 29.798298987341774, -1.469641896551724 ], [ 29.798298987341774, -1.469372413793103 ], [ 29.798029493670885, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27126, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.469372413793103 ], [ 29.798298987341774, -1.469641896551724 ], [ 29.798568481012659, -1.469641896551724 ], [ 29.798568481012659, -1.469372413793103 ], [ 29.798298987341774, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27127, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.469372413793103 ], [ 29.798568481012659, -1.469641896551724 ], [ 29.798837974683543, -1.469641896551724 ], [ 29.798837974683543, -1.469372413793103 ], [ 29.798568481012659, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27128, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.469372413793103 ], [ 29.799376962025317, -1.469641896551724 ], [ 29.799646455696202, -1.469641896551724 ], [ 29.799646455696202, -1.469372413793103 ], [ 29.799376962025317, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27129, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.469372413793103 ], [ 29.799646455696202, -1.469641896551724 ], [ 29.79991594936709, -1.469641896551724 ], [ 29.79991594936709, -1.469372413793103 ], [ 29.799646455696202, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27130, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.469372413793103 ], [ 29.79991594936709, -1.469641896551724 ], [ 29.800185443037975, -1.469641896551724 ], [ 29.800185443037975, -1.469372413793103 ], [ 29.79991594936709, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27131, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.469372413793103 ], [ 29.800185443037975, -1.469641896551724 ], [ 29.80045493670886, -1.469641896551724 ], [ 29.80045493670886, -1.469372413793103 ], [ 29.800185443037975, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27132, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.469372413793103 ], [ 29.80045493670886, -1.469641896551724 ], [ 29.800724430379748, -1.469641896551724 ], [ 29.800724430379748, -1.469372413793103 ], [ 29.80045493670886, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27133, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.469372413793103 ], [ 29.800724430379748, -1.469641896551724 ], [ 29.800993924050633, -1.469641896551724 ], [ 29.800993924050633, -1.469372413793103 ], [ 29.800724430379748, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27134, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.469372413793103 ], [ 29.800993924050633, -1.469641896551724 ], [ 29.801263417721518, -1.469641896551724 ], [ 29.801263417721518, -1.469372413793103 ], [ 29.800993924050633, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27135, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.469372413793103 ], [ 29.801263417721518, -1.469641896551724 ], [ 29.801532911392407, -1.469641896551724 ], [ 29.801532911392407, -1.469372413793103 ], [ 29.801263417721518, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27136, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.469372413793103 ], [ 29.801532911392407, -1.469641896551724 ], [ 29.802071898734177, -1.469641896551724 ], [ 29.802071898734177, -1.469372413793103 ], [ 29.801532911392407, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27137, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.469372413793103 ], [ 29.802071898734177, -1.469641896551724 ], [ 29.802341392405065, -1.469641896551724 ], [ 29.802341392405065, -1.469372413793103 ], [ 29.802071898734177, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27138, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.469372413793103 ], [ 29.802341392405065, -1.469641896551724 ], [ 29.80261088607595, -1.469641896551724 ], [ 29.80261088607595, -1.469372413793103 ], [ 29.802341392405065, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27139, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.469372413793103 ], [ 29.80261088607595, -1.469641896551724 ], [ 29.803149873417723, -1.469641896551724 ], [ 29.803149873417723, -1.469372413793103 ], [ 29.80261088607595, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27140, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.467755517241379 ], [ 29.762456329113924, -1.470719827586207 ], [ 29.762725822784809, -1.470719827586207 ], [ 29.762725822784809, -1.467755517241379 ], [ 29.762456329113924, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27141, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.467755517241379 ], [ 29.762725822784809, -1.470719827586207 ], [ 29.762995316455697, -1.470719827586207 ], [ 29.762995316455697, -1.467755517241379 ], [ 29.762725822784809, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27142, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.467755517241379 ], [ 29.763264810126582, -1.470719827586207 ], [ 29.763534303797467, -1.470719827586207 ], [ 29.763534303797467, -1.467755517241379 ], [ 29.763264810126582, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27143, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.467755517241379 ], [ 29.76407329113924, -1.470719827586207 ], [ 29.764342784810125, -1.470719827586207 ], [ 29.764342784810125, -1.467755517241379 ], [ 29.76407329113924, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27144, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.468025 ], [ 29.762186835443039, -1.470450344827586 ], [ 29.762456329113924, -1.470450344827586 ], [ 29.762456329113924, -1.468025 ], [ 29.762186835443039, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27145, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.468025 ], [ 29.762995316455697, -1.470450344827586 ], [ 29.763264810126582, -1.470450344827586 ], [ 29.763264810126582, -1.468025 ], [ 29.762995316455697, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27146, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.468025 ], [ 29.763803797468356, -1.470450344827586 ], [ 29.76407329113924, -1.470450344827586 ], [ 29.76407329113924, -1.468025 ], [ 29.763803797468356, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27147, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.468025 ], [ 29.764342784810125, -1.470450344827586 ], [ 29.764612278481014, -1.470450344827586 ], [ 29.764612278481014, -1.468025 ], [ 29.764342784810125, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27148, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.468025 ], [ 29.764612278481014, -1.470450344827586 ], [ 29.764881772151899, -1.470450344827586 ], [ 29.764881772151899, -1.468025 ], [ 29.764612278481014, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27149, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.468025 ], [ 29.765151265822784, -1.470450344827586 ], [ 29.765420759493672, -1.470450344827586 ], [ 29.765420759493672, -1.468025 ], [ 29.765151265822784, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27150, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.468025 ], [ 29.765420759493672, -1.470450344827586 ], [ 29.765959746835442, -1.470450344827586 ], [ 29.765959746835442, -1.468025 ], [ 29.765420759493672, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27151, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.468294482758621 ], [ 29.763534303797467, -1.470180862068966 ], [ 29.763803797468356, -1.470180862068966 ], [ 29.763803797468356, -1.468294482758621 ], [ 29.763534303797467, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27152, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.468294482758621 ], [ 29.764881772151899, -1.470180862068966 ], [ 29.765151265822784, -1.470180862068966 ], [ 29.765151265822784, -1.468294482758621 ], [ 29.764881772151899, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27153, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.468294482758621 ], [ 29.765959746835442, -1.470180862068966 ], [ 29.766229240506327, -1.470180862068966 ], [ 29.766229240506327, -1.468294482758621 ], [ 29.765959746835442, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27154, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.468294482758621 ], [ 29.766229240506327, -1.470180862068966 ], [ 29.766498734177215, -1.470180862068966 ], [ 29.766498734177215, -1.468294482758621 ], [ 29.766229240506327, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27155, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.468294482758621 ], [ 29.766498734177215, -1.470180862068966 ], [ 29.7667682278481, -1.470180862068966 ], [ 29.7667682278481, -1.468294482758621 ], [ 29.766498734177215, -1.468294482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27156, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.468833448275862 ], [ 29.776200506329115, -1.470989310344828 ], [ 29.77647, -1.470989310344828 ], [ 29.77647, -1.468833448275862 ], [ 29.776200506329115, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27157, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.468833448275862 ], [ 29.777008987341773, -1.470989310344828 ], [ 29.777278481012658, -1.470989310344828 ], [ 29.777278481012658, -1.468833448275862 ], [ 29.777008987341773, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27158, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.468833448275862 ], [ 29.777547974683543, -1.470989310344828 ], [ 29.777817468354431, -1.470989310344828 ], [ 29.777817468354431, -1.468833448275862 ], [ 29.777547974683543, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27159, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.468833448275862 ], [ 29.778356455696201, -1.470989310344828 ], [ 29.77862594936709, -1.470989310344828 ], [ 29.77862594936709, -1.468833448275862 ], [ 29.778356455696201, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27160, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.468833448275862 ], [ 29.778895443037975, -1.470989310344828 ], [ 29.77916493670886, -1.470989310344828 ], [ 29.77916493670886, -1.468833448275862 ], [ 29.778895443037975, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27161, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.468833448275862 ], [ 29.77916493670886, -1.470989310344828 ], [ 29.779434430379748, -1.470989310344828 ], [ 29.779434430379748, -1.468833448275862 ], [ 29.77916493670886, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27162, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.468833448275862 ], [ 29.779703924050633, -1.470989310344828 ], [ 29.779973417721518, -1.470989310344828 ], [ 29.779973417721518, -1.468833448275862 ], [ 29.779703924050633, -1.468833448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27163, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.468025 ], [ 29.761917341772151, -1.468025 ], [ 29.761917341772151, -1.469911379310345 ], [ 29.761647848101266, -1.469911379310345 ], [ 29.761647848101266, -1.469372413793103 ], [ 29.761378354430381, -1.469372413793103 ], [ 29.761378354430381, -1.469102931034483 ], [ 29.761647848101266, -1.469102931034483 ], [ 29.761647848101266, -1.468025 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27164, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.469102931034483 ], [ 29.773236075949367, -1.470719827586207 ], [ 29.773505569620252, -1.470719827586207 ], [ 29.773505569620252, -1.469102931034483 ], [ 29.773236075949367, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27165, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.469102931034483 ], [ 29.774044556962025, -1.470719827586207 ], [ 29.77431405063291, -1.470719827586207 ], [ 29.77431405063291, -1.469102931034483 ], [ 29.774044556962025, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27166, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.469102931034483 ], [ 29.774853037974683, -1.470719827586207 ], [ 29.775122531645568, -1.470719827586207 ], [ 29.775122531645568, -1.469102931034483 ], [ 29.774853037974683, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27167, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.469102931034483 ], [ 29.775122531645568, -1.470719827586207 ], [ 29.775392025316457, -1.470719827586207 ], [ 29.775392025316457, -1.469102931034483 ], [ 29.775122531645568, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27168, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.469102931034483 ], [ 29.775661518987341, -1.470719827586207 ], [ 29.775931012658226, -1.470719827586207 ], [ 29.775931012658226, -1.469102931034483 ], [ 29.775661518987341, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27169, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.469102931034483 ], [ 29.77647, -1.470719827586207 ], [ 29.776739493670885, -1.470719827586207 ], [ 29.776739493670885, -1.469102931034483 ], [ 29.77647, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27170, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.469102931034483 ], [ 29.777278481012658, -1.470719827586207 ], [ 29.777547974683543, -1.470719827586207 ], [ 29.777547974683543, -1.469102931034483 ], [ 29.777278481012658, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27171, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.469102931034483 ], [ 29.777817468354431, -1.470719827586207 ], [ 29.778086962025316, -1.470719827586207 ], [ 29.778086962025316, -1.469102931034483 ], [ 29.777817468354431, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27172, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.469102931034483 ], [ 29.77862594936709, -1.470719827586207 ], [ 29.778895443037975, -1.470719827586207 ], [ 29.778895443037975, -1.469102931034483 ], [ 29.77862594936709, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27173, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.469102931034483 ], [ 29.779973417721518, -1.470719827586207 ], [ 29.780242911392406, -1.470719827586207 ], [ 29.780242911392406, -1.469102931034483 ], [ 29.779973417721518, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27174, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.469102931034483 ], [ 29.788597215189874, -1.469911379310345 ], [ 29.788866708860759, -1.469911379310345 ], [ 29.788866708860759, -1.469102931034483 ], [ 29.788597215189874, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27175, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.469102931034483 ], [ 29.788866708860759, -1.469911379310345 ], [ 29.789136202531644, -1.469911379310345 ], [ 29.789136202531644, -1.469102931034483 ], [ 29.788866708860759, -1.469102931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27176, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.469372413793103 ], [ 29.771888607594935, -1.470450344827586 ], [ 29.772158101265823, -1.470450344827586 ], [ 29.772158101265823, -1.469372413793103 ], [ 29.771888607594935, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27177, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.469372413793103 ], [ 29.772158101265823, -1.470450344827586 ], [ 29.772427594936708, -1.470450344827586 ], [ 29.772427594936708, -1.469372413793103 ], [ 29.772158101265823, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27178, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.469372413793103 ], [ 29.772697088607593, -1.470450344827586 ], [ 29.772966582278482, -1.470450344827586 ], [ 29.772966582278482, -1.469372413793103 ], [ 29.772697088607593, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27179, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.469372413793103 ], [ 29.772966582278482, -1.470450344827586 ], [ 29.773236075949367, -1.470450344827586 ], [ 29.773236075949367, -1.469372413793103 ], [ 29.772966582278482, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27180, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.469372413793103 ], [ 29.773505569620252, -1.470450344827586 ], [ 29.77377506329114, -1.470450344827586 ], [ 29.77377506329114, -1.469372413793103 ], [ 29.773505569620252, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27181, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.469372413793103 ], [ 29.77431405063291, -1.470450344827586 ], [ 29.774853037974683, -1.470450344827586 ], [ 29.774853037974683, -1.469372413793103 ], [ 29.77431405063291, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27182, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.469372413793103 ], [ 29.775392025316457, -1.470450344827586 ], [ 29.775661518987341, -1.470450344827586 ], [ 29.775661518987341, -1.469372413793103 ], [ 29.775392025316457, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27183, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.469372413793103 ], [ 29.775931012658226, -1.470450344827586 ], [ 29.776200506329115, -1.470450344827586 ], [ 29.776200506329115, -1.469372413793103 ], [ 29.775931012658226, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27184, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.469372413793103 ], [ 29.776739493670885, -1.470450344827586 ], [ 29.777008987341773, -1.470450344827586 ], [ 29.777008987341773, -1.469372413793103 ], [ 29.776739493670885, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27185, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.469372413793103 ], [ 29.778086962025316, -1.470450344827586 ], [ 29.778356455696201, -1.470450344827586 ], [ 29.778356455696201, -1.469372413793103 ], [ 29.778086962025316, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27186, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.469372413793103 ], [ 29.779434430379748, -1.470450344827586 ], [ 29.779703924050633, -1.470450344827586 ], [ 29.779703924050633, -1.469372413793103 ], [ 29.779434430379748, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27187, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.469372413793103 ], [ 29.782129367088608, -1.469911379310345 ], [ 29.782398860759493, -1.469911379310345 ], [ 29.782398860759493, -1.469372413793103 ], [ 29.782129367088608, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27188, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.469372413793103 ], [ 29.787788734177216, -1.469911379310345 ], [ 29.788058227848101, -1.469911379310345 ], [ 29.788058227848101, -1.469372413793103 ], [ 29.787788734177216, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27189, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.469372413793103 ], [ 29.788058227848101, -1.469911379310345 ], [ 29.788327721518986, -1.469911379310345 ], [ 29.788327721518986, -1.469372413793103 ], [ 29.788058227848101, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.469372413793103 ], [ 29.796682025316457, -1.469911379310345 ], [ 29.796951518987342, -1.469911379310345 ], [ 29.796951518987342, -1.469372413793103 ], [ 29.796682025316457, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27191, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.469372413793103 ], [ 29.796951518987342, -1.469911379310345 ], [ 29.797221012658227, -1.469911379310345 ], [ 29.797221012658227, -1.469372413793103 ], [ 29.796951518987342, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27192, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.469372413793103 ], [ 29.798837974683543, -1.469911379310345 ], [ 29.799107468354432, -1.469911379310345 ], [ 29.799107468354432, -1.469372413793103 ], [ 29.798837974683543, -1.469372413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27193, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756257974683542, -1.469641896551724 ], [ 29.756257974683542, -1.470180862068966 ], [ 29.756796962025316, -1.470180862068966 ], [ 29.756796962025316, -1.469641896551724 ], [ 29.756257974683542, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27194, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.469641896551724 ], [ 29.756796962025316, -1.469911379310345 ], [ 29.757066455696201, -1.469911379310345 ], [ 29.757066455696201, -1.469641896551724 ], [ 29.756796962025316, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27195, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.469641896551724 ], [ 29.757066455696201, -1.469911379310345 ], [ 29.757335949367089, -1.469911379310345 ], [ 29.757335949367089, -1.469641896551724 ], [ 29.757066455696201, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27196, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.469641896551724 ], [ 29.757335949367089, -1.469911379310345 ], [ 29.757605443037974, -1.469911379310345 ], [ 29.757605443037974, -1.469641896551724 ], [ 29.757335949367089, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27197, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.469641896551724 ], [ 29.757605443037974, -1.469911379310345 ], [ 29.757874936708859, -1.469911379310345 ], [ 29.757874936708859, -1.469641896551724 ], [ 29.757605443037974, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27198, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.469641896551724 ], [ 29.757874936708859, -1.469911379310345 ], [ 29.758144430379748, -1.469911379310345 ], [ 29.758144430379748, -1.469641896551724 ], [ 29.757874936708859, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27199, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.469641896551724 ], [ 29.758144430379748, -1.469911379310345 ], [ 29.758413924050632, -1.469911379310345 ], [ 29.758413924050632, -1.469641896551724 ], [ 29.758144430379748, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27200, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.469641896551724 ], [ 29.758413924050632, -1.469911379310345 ], [ 29.758683417721517, -1.469911379310345 ], [ 29.758683417721517, -1.469641896551724 ], [ 29.758413924050632, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27201, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.469641896551724 ], [ 29.758683417721517, -1.469911379310345 ], [ 29.758952911392406, -1.469911379310345 ], [ 29.758952911392406, -1.469641896551724 ], [ 29.758683417721517, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27202, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.469641896551724 ], [ 29.758952911392406, -1.469911379310345 ], [ 29.759222405063291, -1.469911379310345 ], [ 29.759222405063291, -1.469641896551724 ], [ 29.758952911392406, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27203, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.469641896551724 ], [ 29.759222405063291, -1.469911379310345 ], [ 29.759491898734176, -1.469911379310345 ], [ 29.759491898734176, -1.469641896551724 ], [ 29.759222405063291, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27204, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.469641896551724 ], [ 29.759491898734176, -1.469911379310345 ], [ 29.759761392405064, -1.469911379310345 ], [ 29.759761392405064, -1.469641896551724 ], [ 29.759491898734176, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27205, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.469641896551724 ], [ 29.759761392405064, -1.469911379310345 ], [ 29.760030886075949, -1.469911379310345 ], [ 29.760030886075949, -1.469641896551724 ], [ 29.759761392405064, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27206, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.469641896551724 ], [ 29.760030886075949, -1.469911379310345 ], [ 29.760300379746834, -1.469911379310345 ], [ 29.760300379746834, -1.469641896551724 ], [ 29.760030886075949, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27207, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.469641896551724 ], [ 29.760300379746834, -1.469911379310345 ], [ 29.760569873417722, -1.469911379310345 ], [ 29.760569873417722, -1.469641896551724 ], [ 29.760300379746834, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27208, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.469641896551724 ], [ 29.760569873417722, -1.469911379310345 ], [ 29.760839367088607, -1.469911379310345 ], [ 29.760839367088607, -1.469641896551724 ], [ 29.760569873417722, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27209, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.469641896551724 ], [ 29.760839367088607, -1.469911379310345 ], [ 29.761108860759492, -1.469911379310345 ], [ 29.761108860759492, -1.469641896551724 ], [ 29.760839367088607, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27210, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.469641896551724 ], [ 29.761108860759492, -1.469911379310345 ], [ 29.761378354430381, -1.469911379310345 ], [ 29.761378354430381, -1.469641896551724 ], [ 29.761108860759492, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27211, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.469641896551724 ], [ 29.761378354430381, -1.469911379310345 ], [ 29.761647848101266, -1.469911379310345 ], [ 29.761647848101266, -1.469641896551724 ], [ 29.761378354430381, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27212, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.469641896551724 ], [ 29.771080126582277, -1.470180862068966 ], [ 29.771349620253165, -1.470180862068966 ], [ 29.771349620253165, -1.469641896551724 ], [ 29.771080126582277, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27213, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.469641896551724 ], [ 29.771349620253165, -1.470180862068966 ], [ 29.77161911392405, -1.470180862068966 ], [ 29.77161911392405, -1.469641896551724 ], [ 29.771349620253165, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27214, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.469641896551724 ], [ 29.77161911392405, -1.470180862068966 ], [ 29.771888607594935, -1.470180862068966 ], [ 29.771888607594935, -1.469641896551724 ], [ 29.77161911392405, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27215, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.469641896551724 ], [ 29.772427594936708, -1.470180862068966 ], [ 29.772697088607593, -1.470180862068966 ], [ 29.772697088607593, -1.469641896551724 ], [ 29.772427594936708, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27216, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.469641896551724 ], [ 29.77377506329114, -1.470180862068966 ], [ 29.774044556962025, -1.470180862068966 ], [ 29.774044556962025, -1.469641896551724 ], [ 29.77377506329114, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27217, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.469641896551724 ], [ 29.780242911392406, -1.469911379310345 ], [ 29.780512405063291, -1.469911379310345 ], [ 29.780512405063291, -1.469641896551724 ], [ 29.780242911392406, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27218, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.469641896551724 ], [ 29.780512405063291, -1.469911379310345 ], [ 29.780781898734176, -1.469911379310345 ], [ 29.780781898734176, -1.469641896551724 ], [ 29.780512405063291, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27219, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.469641896551724 ], [ 29.780781898734176, -1.469911379310345 ], [ 29.781051392405065, -1.469911379310345 ], [ 29.781051392405065, -1.469641896551724 ], [ 29.780781898734176, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27220, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.469641896551724 ], [ 29.781051392405065, -1.469911379310345 ], [ 29.78132088607595, -1.469911379310345 ], [ 29.78132088607595, -1.469641896551724 ], [ 29.781051392405065, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27221, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.469641896551724 ], [ 29.78132088607595, -1.469911379310345 ], [ 29.781590379746834, -1.469911379310345 ], [ 29.781590379746834, -1.469641896551724 ], [ 29.78132088607595, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27222, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.469641896551724 ], [ 29.781590379746834, -1.469911379310345 ], [ 29.781859873417723, -1.469911379310345 ], [ 29.781859873417723, -1.469641896551724 ], [ 29.781590379746834, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27223, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.469641896551724 ], [ 29.781859873417723, -1.469911379310345 ], [ 29.782129367088608, -1.469911379310345 ], [ 29.782129367088608, -1.469641896551724 ], [ 29.781859873417723, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27224, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.469641896551724 ], [ 29.782398860759493, -1.469911379310345 ], [ 29.782668354430381, -1.469911379310345 ], [ 29.782668354430381, -1.469641896551724 ], [ 29.782398860759493, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27225, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.469641896551724 ], [ 29.782668354430381, -1.469911379310345 ], [ 29.782937848101266, -1.469911379310345 ], [ 29.782937848101266, -1.469641896551724 ], [ 29.782668354430381, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27226, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.469641896551724 ], [ 29.782937848101266, -1.469911379310345 ], [ 29.783207341772151, -1.469911379310345 ], [ 29.783207341772151, -1.469641896551724 ], [ 29.782937848101266, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27227, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.469641896551724 ], [ 29.783207341772151, -1.469911379310345 ], [ 29.783476835443039, -1.469911379310345 ], [ 29.783476835443039, -1.469641896551724 ], [ 29.783207341772151, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27228, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.469641896551724 ], [ 29.783476835443039, -1.469911379310345 ], [ 29.784015822784809, -1.469911379310345 ], [ 29.784015822784809, -1.469641896551724 ], [ 29.783476835443039, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27229, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.469641896551724 ], [ 29.784015822784809, -1.469911379310345 ], [ 29.784285316455698, -1.469911379310345 ], [ 29.784285316455698, -1.469641896551724 ], [ 29.784015822784809, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27230, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.469641896551724 ], [ 29.784285316455698, -1.469911379310345 ], [ 29.784554810126583, -1.469911379310345 ], [ 29.784554810126583, -1.469641896551724 ], [ 29.784285316455698, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27231, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.469641896551724 ], [ 29.784554810126583, -1.469911379310345 ], [ 29.784824303797468, -1.469911379310345 ], [ 29.784824303797468, -1.469641896551724 ], [ 29.784554810126583, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27232, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.469641896551724 ], [ 29.784824303797468, -1.470180862068966 ], [ 29.785093797468356, -1.470180862068966 ], [ 29.785093797468356, -1.469641896551724 ], [ 29.784824303797468, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27233, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.469641896551724 ], [ 29.785093797468356, -1.469911379310345 ], [ 29.785363291139241, -1.469911379310345 ], [ 29.785363291139241, -1.469641896551724 ], [ 29.785093797468356, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27234, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.469641896551724 ], [ 29.785363291139241, -1.469911379310345 ], [ 29.785632784810126, -1.469911379310345 ], [ 29.785632784810126, -1.469641896551724 ], [ 29.785363291139241, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27235, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.469641896551724 ], [ 29.785632784810126, -1.469911379310345 ], [ 29.785902278481014, -1.469911379310345 ], [ 29.785902278481014, -1.469641896551724 ], [ 29.785632784810126, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27236, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.469641896551724 ], [ 29.785902278481014, -1.470180862068966 ], [ 29.786171772151899, -1.470180862068966 ], [ 29.786171772151899, -1.469641896551724 ], [ 29.785902278481014, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27237, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.469641896551724 ], [ 29.786171772151899, -1.469911379310345 ], [ 29.786441265822784, -1.469911379310345 ], [ 29.786441265822784, -1.469641896551724 ], [ 29.786171772151899, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27238, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.469641896551724 ], [ 29.786441265822784, -1.469911379310345 ], [ 29.786710759493673, -1.469911379310345 ], [ 29.786710759493673, -1.469641896551724 ], [ 29.786441265822784, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27239, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.469641896551724 ], [ 29.786710759493673, -1.470180862068966 ], [ 29.786980253164558, -1.470180862068966 ], [ 29.786980253164558, -1.469641896551724 ], [ 29.786710759493673, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27240, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.469641896551724 ], [ 29.786980253164558, -1.470180862068966 ], [ 29.787249746835442, -1.470180862068966 ], [ 29.787249746835442, -1.469641896551724 ], [ 29.786980253164558, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27241, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.469641896551724 ], [ 29.787249746835442, -1.469911379310345 ], [ 29.787519240506327, -1.469911379310345 ], [ 29.787519240506327, -1.469641896551724 ], [ 29.787249746835442, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27242, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.469641896551724 ], [ 29.787519240506327, -1.470180862068966 ], [ 29.787788734177216, -1.470180862068966 ], [ 29.787788734177216, -1.469641896551724 ], [ 29.787519240506327, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27243, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.469641896551724 ], [ 29.788327721518986, -1.470180862068966 ], [ 29.788597215189874, -1.470180862068966 ], [ 29.788597215189874, -1.469641896551724 ], [ 29.788327721518986, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27244, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.469641896551724 ], [ 29.789136202531644, -1.469911379310345 ], [ 29.789405696202532, -1.469911379310345 ], [ 29.789405696202532, -1.469641896551724 ], [ 29.789136202531644, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27245, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.469641896551724 ], [ 29.789405696202532, -1.469911379310345 ], [ 29.789675189873417, -1.469911379310345 ], [ 29.789675189873417, -1.469641896551724 ], [ 29.789405696202532, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27246, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.469641896551724 ], [ 29.789675189873417, -1.469911379310345 ], [ 29.789944683544302, -1.469911379310345 ], [ 29.789944683544302, -1.469641896551724 ], [ 29.789675189873417, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27247, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.469641896551724 ], [ 29.789944683544302, -1.470450344827586 ], [ 29.790214177215191, -1.470450344827586 ], [ 29.790214177215191, -1.469641896551724 ], [ 29.789944683544302, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27248, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.795873544303799, -1.469641896551724 ], [ 29.795873544303799, -1.470450344827586 ], [ 29.796143037974684, -1.470450344827586 ], [ 29.796143037974684, -1.469641896551724 ], [ 29.795873544303799, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27249, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.469641896551724 ], [ 29.796143037974684, -1.470180862068966 ], [ 29.796412531645569, -1.470180862068966 ], [ 29.796412531645569, -1.469641896551724 ], [ 29.796143037974684, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27250, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.469641896551724 ], [ 29.796412531645569, -1.470180862068966 ], [ 29.796682025316457, -1.470180862068966 ], [ 29.796682025316457, -1.469641896551724 ], [ 29.796412531645569, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27251, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.469641896551724 ], [ 29.797221012658227, -1.469911379310345 ], [ 29.797490506329115, -1.469911379310345 ], [ 29.797490506329115, -1.469641896551724 ], [ 29.797221012658227, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27252, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.469641896551724 ], [ 29.797490506329115, -1.469911379310345 ], [ 29.79776, -1.469911379310345 ], [ 29.79776, -1.469641896551724 ], [ 29.797490506329115, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27253, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.469641896551724 ], [ 29.79776, -1.469911379310345 ], [ 29.798029493670885, -1.469911379310345 ], [ 29.798029493670885, -1.469641896551724 ], [ 29.79776, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27254, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.469641896551724 ], [ 29.798029493670885, -1.469911379310345 ], [ 29.798298987341774, -1.469911379310345 ], [ 29.798298987341774, -1.469641896551724 ], [ 29.798029493670885, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27255, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.469641896551724 ], [ 29.798298987341774, -1.469911379310345 ], [ 29.798568481012659, -1.469911379310345 ], [ 29.798568481012659, -1.469641896551724 ], [ 29.798298987341774, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27256, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.469641896551724 ], [ 29.798568481012659, -1.470180862068966 ], [ 29.798837974683543, -1.470180862068966 ], [ 29.798837974683543, -1.469641896551724 ], [ 29.798568481012659, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27257, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.469641896551724 ], [ 29.799107468354432, -1.469911379310345 ], [ 29.799376962025317, -1.469911379310345 ], [ 29.799376962025317, -1.469641896551724 ], [ 29.799107468354432, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27258, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.469641896551724 ], [ 29.799376962025317, -1.469911379310345 ], [ 29.799646455696202, -1.469911379310345 ], [ 29.799646455696202, -1.469641896551724 ], [ 29.799376962025317, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27259, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.469641896551724 ], [ 29.799646455696202, -1.469911379310345 ], [ 29.79991594936709, -1.469911379310345 ], [ 29.79991594936709, -1.469641896551724 ], [ 29.799646455696202, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27260, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.469641896551724 ], [ 29.79991594936709, -1.469911379310345 ], [ 29.800185443037975, -1.469911379310345 ], [ 29.800185443037975, -1.469641896551724 ], [ 29.79991594936709, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27261, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.469641896551724 ], [ 29.800185443037975, -1.469911379310345 ], [ 29.80045493670886, -1.469911379310345 ], [ 29.80045493670886, -1.469641896551724 ], [ 29.800185443037975, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27262, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.469641896551724 ], [ 29.80045493670886, -1.469911379310345 ], [ 29.800724430379748, -1.469911379310345 ], [ 29.800724430379748, -1.469641896551724 ], [ 29.80045493670886, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27263, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.469641896551724 ], [ 29.800724430379748, -1.469911379310345 ], [ 29.800993924050633, -1.469911379310345 ], [ 29.800993924050633, -1.469641896551724 ], [ 29.800724430379748, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27264, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.469641896551724 ], [ 29.800993924050633, -1.469911379310345 ], [ 29.801263417721518, -1.469911379310345 ], [ 29.801263417721518, -1.469641896551724 ], [ 29.800993924050633, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27265, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.469641896551724 ], [ 29.801263417721518, -1.469911379310345 ], [ 29.801532911392407, -1.469911379310345 ], [ 29.801532911392407, -1.469641896551724 ], [ 29.801263417721518, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27266, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.469641896551724 ], [ 29.801532911392407, -1.469911379310345 ], [ 29.802071898734177, -1.469911379310345 ], [ 29.802071898734177, -1.469641896551724 ], [ 29.801532911392407, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27267, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.469641896551724 ], [ 29.802071898734177, -1.469911379310345 ], [ 29.802341392405065, -1.469911379310345 ], [ 29.802341392405065, -1.469641896551724 ], [ 29.802071898734177, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27268, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.469641896551724 ], [ 29.802341392405065, -1.469911379310345 ], [ 29.80261088607595, -1.469911379310345 ], [ 29.80261088607595, -1.469641896551724 ], [ 29.802341392405065, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27269, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.469641896551724 ], [ 29.80261088607595, -1.469911379310345 ], [ 29.802880379746835, -1.469911379310345 ], [ 29.802880379746835, -1.469641896551724 ], [ 29.80261088607595, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27270, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.469641896551724 ], [ 29.802880379746835, -1.469911379310345 ], [ 29.803149873417723, -1.469911379310345 ], [ 29.803149873417723, -1.469641896551724 ], [ 29.802880379746835, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27271, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.469911379310345 ], [ 29.756796962025316, -1.470180862068966 ], [ 29.757066455696201, -1.470180862068966 ], [ 29.757066455696201, -1.469911379310345 ], [ 29.756796962025316, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27272, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.469911379310345 ], [ 29.757066455696201, -1.470180862068966 ], [ 29.757335949367089, -1.470180862068966 ], [ 29.757335949367089, -1.469911379310345 ], [ 29.757066455696201, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27273, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.469911379310345 ], [ 29.757335949367089, -1.470180862068966 ], [ 29.757605443037974, -1.470180862068966 ], [ 29.757605443037974, -1.469911379310345 ], [ 29.757335949367089, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27274, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.469911379310345 ], [ 29.757605443037974, -1.470180862068966 ], [ 29.757874936708859, -1.470180862068966 ], [ 29.757874936708859, -1.469911379310345 ], [ 29.757605443037974, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27275, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.469911379310345 ], [ 29.757874936708859, -1.470180862068966 ], [ 29.758144430379748, -1.470180862068966 ], [ 29.758144430379748, -1.469911379310345 ], [ 29.757874936708859, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27276, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.469911379310345 ], [ 29.758144430379748, -1.470180862068966 ], [ 29.758413924050632, -1.470180862068966 ], [ 29.758413924050632, -1.469911379310345 ], [ 29.758144430379748, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27277, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.469911379310345 ], [ 29.758413924050632, -1.470180862068966 ], [ 29.758683417721517, -1.470180862068966 ], [ 29.758683417721517, -1.469911379310345 ], [ 29.758413924050632, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27278, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.469911379310345 ], [ 29.758683417721517, -1.470180862068966 ], [ 29.758952911392406, -1.470180862068966 ], [ 29.758952911392406, -1.469911379310345 ], [ 29.758683417721517, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27279, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.469911379310345 ], [ 29.758952911392406, -1.470180862068966 ], [ 29.759222405063291, -1.470180862068966 ], [ 29.759222405063291, -1.469911379310345 ], [ 29.758952911392406, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27280, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.469911379310345 ], [ 29.759222405063291, -1.470180862068966 ], [ 29.759491898734176, -1.470180862068966 ], [ 29.759491898734176, -1.469911379310345 ], [ 29.759222405063291, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27281, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.469911379310345 ], [ 29.759491898734176, -1.470180862068966 ], [ 29.759761392405064, -1.470180862068966 ], [ 29.759761392405064, -1.469911379310345 ], [ 29.759491898734176, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27282, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.469911379310345 ], [ 29.759761392405064, -1.470180862068966 ], [ 29.760030886075949, -1.470180862068966 ], [ 29.760030886075949, -1.469911379310345 ], [ 29.759761392405064, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27283, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.469911379310345 ], [ 29.760030886075949, -1.470180862068966 ], [ 29.760300379746834, -1.470180862068966 ], [ 29.760300379746834, -1.469911379310345 ], [ 29.760030886075949, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27284, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.469911379310345 ], [ 29.760300379746834, -1.470180862068966 ], [ 29.760569873417722, -1.470180862068966 ], [ 29.760569873417722, -1.469911379310345 ], [ 29.760300379746834, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27285, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.469911379310345 ], [ 29.760569873417722, -1.470180862068966 ], [ 29.760839367088607, -1.470180862068966 ], [ 29.760839367088607, -1.469911379310345 ], [ 29.760569873417722, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27286, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.469911379310345 ], [ 29.760839367088607, -1.470180862068966 ], [ 29.761108860759492, -1.470180862068966 ], [ 29.761108860759492, -1.469911379310345 ], [ 29.760839367088607, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27287, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.469911379310345 ], [ 29.761108860759492, -1.470180862068966 ], [ 29.761378354430381, -1.470180862068966 ], [ 29.761378354430381, -1.469911379310345 ], [ 29.761108860759492, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27288, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.469911379310345 ], [ 29.761378354430381, -1.470180862068966 ], [ 29.761647848101266, -1.470180862068966 ], [ 29.761647848101266, -1.469911379310345 ], [ 29.761378354430381, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27289, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.469911379310345 ], [ 29.761647848101266, -1.470180862068966 ], [ 29.761917341772151, -1.470180862068966 ], [ 29.761917341772151, -1.469911379310345 ], [ 29.761647848101266, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27290, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.469911379310345 ], [ 29.780242911392406, -1.471258793103448 ], [ 29.780512405063291, -1.471258793103448 ], [ 29.780512405063291, -1.470989310344828 ], [ 29.780781898734176, -1.470989310344828 ], [ 29.780781898734176, -1.470719827586207 ], [ 29.780512405063291, -1.470719827586207 ], [ 29.780512405063291, -1.469911379310345 ], [ 29.780242911392406, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27291, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.469911379310345 ], [ 29.780512405063291, -1.470180862068966 ], [ 29.780781898734176, -1.470180862068966 ], [ 29.780781898734176, -1.469911379310345 ], [ 29.780512405063291, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27292, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.469911379310345 ], [ 29.780781898734176, -1.470180862068966 ], [ 29.781051392405065, -1.470180862068966 ], [ 29.781051392405065, -1.469911379310345 ], [ 29.780781898734176, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27293, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.469911379310345 ], [ 29.781051392405065, -1.470180862068966 ], [ 29.78132088607595, -1.470180862068966 ], [ 29.78132088607595, -1.469911379310345 ], [ 29.781051392405065, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27294, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.469911379310345 ], [ 29.78132088607595, -1.470180862068966 ], [ 29.781590379746834, -1.470180862068966 ], [ 29.781590379746834, -1.469911379310345 ], [ 29.78132088607595, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27295, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.469911379310345 ], [ 29.781590379746834, -1.470180862068966 ], [ 29.781859873417723, -1.470180862068966 ], [ 29.781859873417723, -1.469911379310345 ], [ 29.781590379746834, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27296, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.469911379310345 ], [ 29.781859873417723, -1.470180862068966 ], [ 29.782129367088608, -1.470180862068966 ], [ 29.782129367088608, -1.469911379310345 ], [ 29.781859873417723, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27297, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.469911379310345 ], [ 29.782129367088608, -1.470180862068966 ], [ 29.782398860759493, -1.470180862068966 ], [ 29.782398860759493, -1.469911379310345 ], [ 29.782129367088608, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27298, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.469911379310345 ], [ 29.782398860759493, -1.470180862068966 ], [ 29.782668354430381, -1.470180862068966 ], [ 29.782668354430381, -1.469911379310345 ], [ 29.782398860759493, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27299, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.469911379310345 ], [ 29.782668354430381, -1.470180862068966 ], [ 29.782937848101266, -1.470180862068966 ], [ 29.782937848101266, -1.469911379310345 ], [ 29.782668354430381, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27300, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.469911379310345 ], [ 29.782937848101266, -1.470180862068966 ], [ 29.783207341772151, -1.470180862068966 ], [ 29.783207341772151, -1.469911379310345 ], [ 29.782937848101266, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27301, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.469911379310345 ], [ 29.783207341772151, -1.470180862068966 ], [ 29.783476835443039, -1.470180862068966 ], [ 29.783476835443039, -1.469911379310345 ], [ 29.783207341772151, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27302, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.469911379310345 ], [ 29.783476835443039, -1.470450344827586 ], [ 29.784015822784809, -1.470450344827586 ], [ 29.784015822784809, -1.469911379310345 ], [ 29.783476835443039, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27303, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.469911379310345 ], [ 29.784015822784809, -1.470180862068966 ], [ 29.784285316455698, -1.470180862068966 ], [ 29.784285316455698, -1.469911379310345 ], [ 29.784015822784809, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27304, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.469911379310345 ], [ 29.784285316455698, -1.470180862068966 ], [ 29.784554810126583, -1.470180862068966 ], [ 29.784554810126583, -1.469911379310345 ], [ 29.784285316455698, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27305, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.469911379310345 ], [ 29.784554810126583, -1.470180862068966 ], [ 29.784824303797468, -1.470180862068966 ], [ 29.784824303797468, -1.469911379310345 ], [ 29.784554810126583, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27306, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.469911379310345 ], [ 29.785093797468356, -1.470180862068966 ], [ 29.785363291139241, -1.470180862068966 ], [ 29.785363291139241, -1.469911379310345 ], [ 29.785093797468356, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27307, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.469911379310345 ], [ 29.785363291139241, -1.470180862068966 ], [ 29.785632784810126, -1.470180862068966 ], [ 29.785632784810126, -1.469911379310345 ], [ 29.785363291139241, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27308, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.469911379310345 ], [ 29.785632784810126, -1.470450344827586 ], [ 29.785902278481014, -1.470450344827586 ], [ 29.785902278481014, -1.469911379310345 ], [ 29.785632784810126, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27309, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.469911379310345 ], [ 29.786171772151899, -1.470180862068966 ], [ 29.786441265822784, -1.470180862068966 ], [ 29.786441265822784, -1.469911379310345 ], [ 29.786171772151899, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27310, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.469911379310345 ], [ 29.786441265822784, -1.470450344827586 ], [ 29.786710759493673, -1.470450344827586 ], [ 29.786710759493673, -1.469911379310345 ], [ 29.786441265822784, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27311, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.469911379310345 ], [ 29.787249746835442, -1.470450344827586 ], [ 29.787519240506327, -1.470450344827586 ], [ 29.787519240506327, -1.469911379310345 ], [ 29.787249746835442, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27312, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.469911379310345 ], [ 29.787788734177216, -1.470180862068966 ], [ 29.788058227848101, -1.470180862068966 ], [ 29.788058227848101, -1.469911379310345 ], [ 29.787788734177216, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27313, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.469911379310345 ], [ 29.788058227848101, -1.470180862068966 ], [ 29.788327721518986, -1.470180862068966 ], [ 29.788327721518986, -1.469911379310345 ], [ 29.788058227848101, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27314, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.469911379310345 ], [ 29.788597215189874, -1.470180862068966 ], [ 29.788866708860759, -1.470180862068966 ], [ 29.788866708860759, -1.469911379310345 ], [ 29.788597215189874, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27315, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.469911379310345 ], [ 29.788866708860759, -1.470180862068966 ], [ 29.789136202531644, -1.470180862068966 ], [ 29.789136202531644, -1.469911379310345 ], [ 29.788866708860759, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27316, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.469911379310345 ], [ 29.789136202531644, -1.470180862068966 ], [ 29.789405696202532, -1.470180862068966 ], [ 29.789405696202532, -1.469911379310345 ], [ 29.789136202531644, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27317, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.469911379310345 ], [ 29.789405696202532, -1.470450344827586 ], [ 29.789675189873417, -1.470450344827586 ], [ 29.789675189873417, -1.469911379310345 ], [ 29.789405696202532, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27318, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.469911379310345 ], [ 29.789675189873417, -1.470450344827586 ], [ 29.789944683544302, -1.470450344827586 ], [ 29.789944683544302, -1.469911379310345 ], [ 29.789675189873417, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27319, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.469911379310345 ], [ 29.796682025316457, -1.470180862068966 ], [ 29.796951518987342, -1.470180862068966 ], [ 29.796951518987342, -1.469911379310345 ], [ 29.796682025316457, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27320, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.469911379310345 ], [ 29.796951518987342, -1.470180862068966 ], [ 29.797221012658227, -1.470180862068966 ], [ 29.797221012658227, -1.469911379310345 ], [ 29.796951518987342, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27321, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.469911379310345 ], [ 29.797221012658227, -1.470180862068966 ], [ 29.797490506329115, -1.470180862068966 ], [ 29.797490506329115, -1.469911379310345 ], [ 29.797221012658227, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27322, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.469911379310345 ], [ 29.797490506329115, -1.470450344827586 ], [ 29.79776, -1.470450344827586 ], [ 29.79776, -1.469911379310345 ], [ 29.797490506329115, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27323, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.469911379310345 ], [ 29.79776, -1.470180862068966 ], [ 29.798029493670885, -1.470180862068966 ], [ 29.798029493670885, -1.469911379310345 ], [ 29.79776, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27324, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.469911379310345 ], [ 29.798029493670885, -1.470180862068966 ], [ 29.798298987341774, -1.470180862068966 ], [ 29.798298987341774, -1.469911379310345 ], [ 29.798029493670885, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27325, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.469911379310345 ], [ 29.798837974683543, -1.470450344827586 ], [ 29.799107468354432, -1.470450344827586 ], [ 29.799107468354432, -1.470180862068966 ], [ 29.799376962025317, -1.470180862068966 ], [ 29.799376962025317, -1.469911379310345 ], [ 29.798837974683543, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27326, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.469911379310345 ], [ 29.799376962025317, -1.470180862068966 ], [ 29.799646455696202, -1.470180862068966 ], [ 29.799646455696202, -1.469911379310345 ], [ 29.799376962025317, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27327, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.469911379310345 ], [ 29.799646455696202, -1.470180862068966 ], [ 29.79991594936709, -1.470180862068966 ], [ 29.79991594936709, -1.469911379310345 ], [ 29.799646455696202, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27328, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.469911379310345 ], [ 29.79991594936709, -1.470180862068966 ], [ 29.800185443037975, -1.470180862068966 ], [ 29.800185443037975, -1.469911379310345 ], [ 29.79991594936709, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27329, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.469911379310345 ], [ 29.800185443037975, -1.470180862068966 ], [ 29.80045493670886, -1.470180862068966 ], [ 29.80045493670886, -1.469911379310345 ], [ 29.800185443037975, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27330, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.469911379310345 ], [ 29.80045493670886, -1.470180862068966 ], [ 29.800724430379748, -1.470180862068966 ], [ 29.800724430379748, -1.469911379310345 ], [ 29.80045493670886, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27331, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.469911379310345 ], [ 29.800724430379748, -1.470180862068966 ], [ 29.800993924050633, -1.470180862068966 ], [ 29.800993924050633, -1.469911379310345 ], [ 29.800724430379748, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27332, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.469911379310345 ], [ 29.800993924050633, -1.470180862068966 ], [ 29.801263417721518, -1.470180862068966 ], [ 29.801263417721518, -1.469911379310345 ], [ 29.800993924050633, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27333, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.469911379310345 ], [ 29.801263417721518, -1.470180862068966 ], [ 29.801532911392407, -1.470180862068966 ], [ 29.801532911392407, -1.469911379310345 ], [ 29.801263417721518, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27334, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.469911379310345 ], [ 29.801532911392407, -1.470180862068966 ], [ 29.802071898734177, -1.470180862068966 ], [ 29.802071898734177, -1.469911379310345 ], [ 29.801532911392407, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27335, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.469911379310345 ], [ 29.802071898734177, -1.470180862068966 ], [ 29.802341392405065, -1.470180862068966 ], [ 29.802341392405065, -1.469911379310345 ], [ 29.802071898734177, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27336, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.469911379310345 ], [ 29.802341392405065, -1.470180862068966 ], [ 29.80261088607595, -1.470180862068966 ], [ 29.80261088607595, -1.469911379310345 ], [ 29.802341392405065, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27337, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.469911379310345 ], [ 29.80261088607595, -1.470180862068966 ], [ 29.802880379746835, -1.470180862068966 ], [ 29.802880379746835, -1.469911379310345 ], [ 29.80261088607595, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27338, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.469911379310345 ], [ 29.802880379746835, -1.470180862068966 ], [ 29.803149873417723, -1.470180862068966 ], [ 29.803149873417723, -1.469911379310345 ], [ 29.802880379746835, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27339, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.756796962025316, -1.470180862068966 ], [ 29.756796962025316, -1.470719827586207 ], [ 29.757066455696201, -1.470719827586207 ], [ 29.757066455696201, -1.470180862068966 ], [ 29.756796962025316, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27340, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.470180862068966 ], [ 29.757066455696201, -1.470450344827586 ], [ 29.757335949367089, -1.470450344827586 ], [ 29.757335949367089, -1.470180862068966 ], [ 29.757066455696201, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27341, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.470180862068966 ], [ 29.757335949367089, -1.470450344827586 ], [ 29.757605443037974, -1.470450344827586 ], [ 29.757605443037974, -1.470180862068966 ], [ 29.757335949367089, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27342, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.470180862068966 ], [ 29.757605443037974, -1.470450344827586 ], [ 29.757874936708859, -1.470450344827586 ], [ 29.757874936708859, -1.470180862068966 ], [ 29.757605443037974, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27343, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.470180862068966 ], [ 29.757874936708859, -1.470450344827586 ], [ 29.758144430379748, -1.470450344827586 ], [ 29.758144430379748, -1.470180862068966 ], [ 29.757874936708859, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27344, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.470180862068966 ], [ 29.758144430379748, -1.470450344827586 ], [ 29.758413924050632, -1.470450344827586 ], [ 29.758413924050632, -1.470180862068966 ], [ 29.758144430379748, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27345, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.470180862068966 ], [ 29.758413924050632, -1.470450344827586 ], [ 29.758683417721517, -1.470450344827586 ], [ 29.758683417721517, -1.470180862068966 ], [ 29.758413924050632, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27346, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.470180862068966 ], [ 29.758683417721517, -1.470450344827586 ], [ 29.758952911392406, -1.470450344827586 ], [ 29.758952911392406, -1.470180862068966 ], [ 29.758683417721517, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27347, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.470180862068966 ], [ 29.758952911392406, -1.470450344827586 ], [ 29.759222405063291, -1.470450344827586 ], [ 29.759222405063291, -1.470180862068966 ], [ 29.758952911392406, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27348, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.470180862068966 ], [ 29.759222405063291, -1.470450344827586 ], [ 29.759491898734176, -1.470450344827586 ], [ 29.759491898734176, -1.470180862068966 ], [ 29.759222405063291, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27349, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.470180862068966 ], [ 29.759491898734176, -1.470450344827586 ], [ 29.759761392405064, -1.470450344827586 ], [ 29.759761392405064, -1.470180862068966 ], [ 29.759491898734176, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27350, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.470180862068966 ], [ 29.759761392405064, -1.470450344827586 ], [ 29.760030886075949, -1.470450344827586 ], [ 29.760030886075949, -1.470180862068966 ], [ 29.759761392405064, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27351, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.470180862068966 ], [ 29.760030886075949, -1.470450344827586 ], [ 29.760300379746834, -1.470450344827586 ], [ 29.760300379746834, -1.470180862068966 ], [ 29.760030886075949, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27352, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.470180862068966 ], [ 29.760300379746834, -1.470450344827586 ], [ 29.760569873417722, -1.470450344827586 ], [ 29.760569873417722, -1.470180862068966 ], [ 29.760300379746834, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27353, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.470180862068966 ], [ 29.760569873417722, -1.470450344827586 ], [ 29.760839367088607, -1.470450344827586 ], [ 29.760839367088607, -1.470180862068966 ], [ 29.760569873417722, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27354, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.470180862068966 ], [ 29.760839367088607, -1.470450344827586 ], [ 29.761108860759492, -1.470450344827586 ], [ 29.761108860759492, -1.470180862068966 ], [ 29.760839367088607, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27355, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.470180862068966 ], [ 29.761108860759492, -1.470450344827586 ], [ 29.761378354430381, -1.470450344827586 ], [ 29.761378354430381, -1.470180862068966 ], [ 29.761108860759492, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27356, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.470180862068966 ], [ 29.761378354430381, -1.470450344827586 ], [ 29.761647848101266, -1.470450344827586 ], [ 29.761647848101266, -1.470180862068966 ], [ 29.761378354430381, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27357, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.467755517241379 ], [ 29.762186835443039, -1.467755517241379 ], [ 29.762186835443039, -1.470719827586207 ], [ 29.761917341772151, -1.470719827586207 ], [ 29.761917341772151, -1.470450344827586 ], [ 29.761647848101266, -1.470450344827586 ], [ 29.761647848101266, -1.470180862068966 ], [ 29.761917341772151, -1.470180862068966 ], [ 29.761917341772151, -1.467755517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27358, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.470180862068966 ], [ 29.763534303797467, -1.470719827586207 ], [ 29.763803797468356, -1.470719827586207 ], [ 29.763803797468356, -1.470180862068966 ], [ 29.763534303797467, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27359, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.470180862068966 ], [ 29.764881772151899, -1.470719827586207 ], [ 29.765151265822784, -1.470719827586207 ], [ 29.765151265822784, -1.470180862068966 ], [ 29.764881772151899, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27360, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.470180862068966 ], [ 29.765959746835442, -1.470450344827586 ], [ 29.766229240506327, -1.470450344827586 ], [ 29.766229240506327, -1.470180862068966 ], [ 29.765959746835442, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27361, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.470180862068966 ], [ 29.766229240506327, -1.470450344827586 ], [ 29.766498734177215, -1.470450344827586 ], [ 29.766498734177215, -1.470180862068966 ], [ 29.766229240506327, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27362, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.470180862068966 ], [ 29.766498734177215, -1.470450344827586 ], [ 29.7667682278481, -1.470450344827586 ], [ 29.7667682278481, -1.470180862068966 ], [ 29.766498734177215, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27363, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.470180862068966 ], [ 29.7667682278481, -1.470989310344828 ], [ 29.767037721518985, -1.470989310344828 ], [ 29.767037721518985, -1.470180862068966 ], [ 29.7667682278481, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27364, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.470180862068966 ], [ 29.770810632911392, -1.470989310344828 ], [ 29.771080126582277, -1.470989310344828 ], [ 29.771080126582277, -1.470180862068966 ], [ 29.770810632911392, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27365, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.470180862068966 ], [ 29.771080126582277, -1.470450344827586 ], [ 29.771349620253165, -1.470450344827586 ], [ 29.771349620253165, -1.470180862068966 ], [ 29.771080126582277, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27366, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.470180862068966 ], [ 29.771349620253165, -1.470450344827586 ], [ 29.77161911392405, -1.470450344827586 ], [ 29.77161911392405, -1.470180862068966 ], [ 29.771349620253165, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27367, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.470180862068966 ], [ 29.77161911392405, -1.470450344827586 ], [ 29.771888607594935, -1.470450344827586 ], [ 29.771888607594935, -1.470180862068966 ], [ 29.77161911392405, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27368, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.470180862068966 ], [ 29.772427594936708, -1.470719827586207 ], [ 29.772697088607593, -1.470719827586207 ], [ 29.772697088607593, -1.470180862068966 ], [ 29.772427594936708, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27369, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.470180862068966 ], [ 29.77377506329114, -1.470719827586207 ], [ 29.774044556962025, -1.470719827586207 ], [ 29.774044556962025, -1.470180862068966 ], [ 29.77377506329114, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27370, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.470180862068966 ], [ 29.780512405063291, -1.470450344827586 ], [ 29.780781898734176, -1.470450344827586 ], [ 29.780781898734176, -1.470180862068966 ], [ 29.780512405063291, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27371, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.470180862068966 ], [ 29.780781898734176, -1.470450344827586 ], [ 29.781051392405065, -1.470450344827586 ], [ 29.781051392405065, -1.470180862068966 ], [ 29.780781898734176, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27372, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.470180862068966 ], [ 29.781051392405065, -1.470450344827586 ], [ 29.78132088607595, -1.470450344827586 ], [ 29.78132088607595, -1.470180862068966 ], [ 29.781051392405065, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27373, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.470180862068966 ], [ 29.78132088607595, -1.470450344827586 ], [ 29.781590379746834, -1.470450344827586 ], [ 29.781590379746834, -1.470180862068966 ], [ 29.78132088607595, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27374, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.470180862068966 ], [ 29.781590379746834, -1.470450344827586 ], [ 29.781859873417723, -1.470450344827586 ], [ 29.781859873417723, -1.470180862068966 ], [ 29.781590379746834, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27375, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.470180862068966 ], [ 29.781859873417723, -1.470450344827586 ], [ 29.782129367088608, -1.470450344827586 ], [ 29.782129367088608, -1.470180862068966 ], [ 29.781859873417723, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27376, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.470180862068966 ], [ 29.782129367088608, -1.470450344827586 ], [ 29.782398860759493, -1.470450344827586 ], [ 29.782398860759493, -1.470180862068966 ], [ 29.782129367088608, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27377, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.470180862068966 ], [ 29.782398860759493, -1.470719827586207 ], [ 29.782668354430381, -1.470719827586207 ], [ 29.782668354430381, -1.470180862068966 ], [ 29.782398860759493, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27378, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.470180862068966 ], [ 29.782668354430381, -1.470450344827586 ], [ 29.782937848101266, -1.470450344827586 ], [ 29.782937848101266, -1.470180862068966 ], [ 29.782668354430381, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27379, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.470180862068966 ], [ 29.782937848101266, -1.470450344827586 ], [ 29.783207341772151, -1.470450344827586 ], [ 29.783207341772151, -1.470180862068966 ], [ 29.782937848101266, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27380, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.470180862068966 ], [ 29.783207341772151, -1.470450344827586 ], [ 29.783476835443039, -1.470450344827586 ], [ 29.783476835443039, -1.470180862068966 ], [ 29.783207341772151, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27381, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.470180862068966 ], [ 29.784015822784809, -1.470450344827586 ], [ 29.784285316455698, -1.470450344827586 ], [ 29.784285316455698, -1.470180862068966 ], [ 29.784015822784809, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27382, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.470180862068966 ], [ 29.784285316455698, -1.470450344827586 ], [ 29.784554810126583, -1.470450344827586 ], [ 29.784554810126583, -1.470180862068966 ], [ 29.784285316455698, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27383, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.470180862068966 ], [ 29.784554810126583, -1.470719827586207 ], [ 29.784824303797468, -1.470719827586207 ], [ 29.784824303797468, -1.470180862068966 ], [ 29.784554810126583, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27384, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.470180862068966 ], [ 29.784824303797468, -1.470450344827586 ], [ 29.785093797468356, -1.470450344827586 ], [ 29.785093797468356, -1.470180862068966 ], [ 29.784824303797468, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27385, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.470180862068966 ], [ 29.785093797468356, -1.470450344827586 ], [ 29.785363291139241, -1.470450344827586 ], [ 29.785363291139241, -1.470180862068966 ], [ 29.785093797468356, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27386, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.470180862068966 ], [ 29.785363291139241, -1.470719827586207 ], [ 29.785632784810126, -1.470719827586207 ], [ 29.785632784810126, -1.470180862068966 ], [ 29.785363291139241, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27387, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.470180862068966 ], [ 29.785902278481014, -1.470450344827586 ], [ 29.786171772151899, -1.470450344827586 ], [ 29.786171772151899, -1.470180862068966 ], [ 29.785902278481014, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27388, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.470180862068966 ], [ 29.786171772151899, -1.470719827586207 ], [ 29.786441265822784, -1.470719827586207 ], [ 29.786441265822784, -1.470180862068966 ], [ 29.786171772151899, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27389, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.470180862068966 ], [ 29.786710759493673, -1.470450344827586 ], [ 29.786980253164558, -1.470450344827586 ], [ 29.786980253164558, -1.470180862068966 ], [ 29.786710759493673, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27390, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.470180862068966 ], [ 29.786980253164558, -1.470450344827586 ], [ 29.787249746835442, -1.470450344827586 ], [ 29.787249746835442, -1.470180862068966 ], [ 29.786980253164558, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27391, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.470180862068966 ], [ 29.787519240506327, -1.470450344827586 ], [ 29.787788734177216, -1.470450344827586 ], [ 29.787788734177216, -1.470180862068966 ], [ 29.787519240506327, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27392, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.470180862068966 ], [ 29.787788734177216, -1.470450344827586 ], [ 29.788058227848101, -1.470450344827586 ], [ 29.788058227848101, -1.470180862068966 ], [ 29.787788734177216, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27393, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.470180862068966 ], [ 29.788058227848101, -1.470450344827586 ], [ 29.788327721518986, -1.470450344827586 ], [ 29.788327721518986, -1.470180862068966 ], [ 29.788058227848101, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27394, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.470180862068966 ], [ 29.788327721518986, -1.470450344827586 ], [ 29.788597215189874, -1.470450344827586 ], [ 29.788597215189874, -1.470180862068966 ], [ 29.788327721518986, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27395, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.470180862068966 ], [ 29.788597215189874, -1.470450344827586 ], [ 29.788866708860759, -1.470450344827586 ], [ 29.788866708860759, -1.470180862068966 ], [ 29.788597215189874, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27396, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.470180862068966 ], [ 29.788866708860759, -1.470719827586207 ], [ 29.789136202531644, -1.470719827586207 ], [ 29.789136202531644, -1.470180862068966 ], [ 29.788866708860759, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27397, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.470180862068966 ], [ 29.789136202531644, -1.470719827586207 ], [ 29.789405696202532, -1.470719827586207 ], [ 29.789405696202532, -1.470180862068966 ], [ 29.789136202531644, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27398, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.470180862068966 ], [ 29.796143037974684, -1.470450344827586 ], [ 29.796412531645569, -1.470450344827586 ], [ 29.796412531645569, -1.470180862068966 ], [ 29.796143037974684, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27399, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.470180862068966 ], [ 29.796412531645569, -1.470450344827586 ], [ 29.796682025316457, -1.470450344827586 ], [ 29.796682025316457, -1.470180862068966 ], [ 29.796412531645569, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27400, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.470180862068966 ], [ 29.796682025316457, -1.470450344827586 ], [ 29.796951518987342, -1.470450344827586 ], [ 29.796951518987342, -1.470180862068966 ], [ 29.796682025316457, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27401, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.470180862068966 ], [ 29.799107468354432, -1.470450344827586 ], [ 29.799376962025317, -1.470450344827586 ], [ 29.799376962025317, -1.470180862068966 ], [ 29.799107468354432, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27402, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.470180862068966 ], [ 29.799376962025317, -1.470450344827586 ], [ 29.799646455696202, -1.470450344827586 ], [ 29.799646455696202, -1.470180862068966 ], [ 29.799376962025317, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27403, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.470180862068966 ], [ 29.799646455696202, -1.470450344827586 ], [ 29.79991594936709, -1.470450344827586 ], [ 29.79991594936709, -1.470180862068966 ], [ 29.799646455696202, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27404, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.470180862068966 ], [ 29.79991594936709, -1.470450344827586 ], [ 29.800185443037975, -1.470450344827586 ], [ 29.800185443037975, -1.470180862068966 ], [ 29.79991594936709, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27405, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.470180862068966 ], [ 29.800185443037975, -1.470450344827586 ], [ 29.80045493670886, -1.470450344827586 ], [ 29.80045493670886, -1.470180862068966 ], [ 29.800185443037975, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27406, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.470180862068966 ], [ 29.80045493670886, -1.470450344827586 ], [ 29.800724430379748, -1.470450344827586 ], [ 29.800724430379748, -1.470180862068966 ], [ 29.80045493670886, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27407, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.470180862068966 ], [ 29.800724430379748, -1.470450344827586 ], [ 29.800993924050633, -1.470450344827586 ], [ 29.800993924050633, -1.470180862068966 ], [ 29.800724430379748, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27408, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.470180862068966 ], [ 29.800993924050633, -1.470450344827586 ], [ 29.801263417721518, -1.470450344827586 ], [ 29.801263417721518, -1.470180862068966 ], [ 29.800993924050633, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27409, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.470180862068966 ], [ 29.801263417721518, -1.470450344827586 ], [ 29.801532911392407, -1.470450344827586 ], [ 29.801532911392407, -1.470180862068966 ], [ 29.801263417721518, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27410, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.470180862068966 ], [ 29.801532911392407, -1.470450344827586 ], [ 29.802071898734177, -1.470450344827586 ], [ 29.802071898734177, -1.470180862068966 ], [ 29.801532911392407, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27411, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.470180862068966 ], [ 29.802071898734177, -1.470450344827586 ], [ 29.802341392405065, -1.470450344827586 ], [ 29.802341392405065, -1.470180862068966 ], [ 29.802071898734177, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27412, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.470180862068966 ], [ 29.802341392405065, -1.470450344827586 ], [ 29.80261088607595, -1.470450344827586 ], [ 29.80261088607595, -1.470180862068966 ], [ 29.802341392405065, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27413, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.470180862068966 ], [ 29.80261088607595, -1.470450344827586 ], [ 29.802880379746835, -1.470450344827586 ], [ 29.802880379746835, -1.470180862068966 ], [ 29.80261088607595, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27414, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.470450344827586 ], [ 29.757066455696201, -1.470719827586207 ], [ 29.757335949367089, -1.470719827586207 ], [ 29.757335949367089, -1.470450344827586 ], [ 29.757066455696201, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27415, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.470450344827586 ], [ 29.757335949367089, -1.470719827586207 ], [ 29.757605443037974, -1.470719827586207 ], [ 29.757605443037974, -1.470450344827586 ], [ 29.757335949367089, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27416, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.470450344827586 ], [ 29.757605443037974, -1.470719827586207 ], [ 29.757874936708859, -1.470719827586207 ], [ 29.757874936708859, -1.470450344827586 ], [ 29.757605443037974, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27417, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.470450344827586 ], [ 29.757874936708859, -1.470719827586207 ], [ 29.758144430379748, -1.470719827586207 ], [ 29.758144430379748, -1.470450344827586 ], [ 29.757874936708859, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27418, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.470450344827586 ], [ 29.758144430379748, -1.470719827586207 ], [ 29.758413924050632, -1.470719827586207 ], [ 29.758413924050632, -1.470450344827586 ], [ 29.758144430379748, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27419, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.470450344827586 ], [ 29.758413924050632, -1.470719827586207 ], [ 29.758683417721517, -1.470719827586207 ], [ 29.758683417721517, -1.470450344827586 ], [ 29.758413924050632, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27420, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.470450344827586 ], [ 29.758683417721517, -1.470719827586207 ], [ 29.758952911392406, -1.470719827586207 ], [ 29.758952911392406, -1.470450344827586 ], [ 29.758683417721517, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27421, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.470450344827586 ], [ 29.758952911392406, -1.470719827586207 ], [ 29.759222405063291, -1.470719827586207 ], [ 29.759222405063291, -1.470450344827586 ], [ 29.758952911392406, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27422, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.470450344827586 ], [ 29.759222405063291, -1.470719827586207 ], [ 29.759491898734176, -1.470719827586207 ], [ 29.759491898734176, -1.470450344827586 ], [ 29.759222405063291, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27423, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.470450344827586 ], [ 29.759491898734176, -1.470719827586207 ], [ 29.759761392405064, -1.470719827586207 ], [ 29.759761392405064, -1.470450344827586 ], [ 29.759491898734176, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27424, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.470450344827586 ], [ 29.759761392405064, -1.470719827586207 ], [ 29.760030886075949, -1.470719827586207 ], [ 29.760030886075949, -1.470450344827586 ], [ 29.759761392405064, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27425, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.470450344827586 ], [ 29.760030886075949, -1.470719827586207 ], [ 29.760300379746834, -1.470719827586207 ], [ 29.760300379746834, -1.470450344827586 ], [ 29.760030886075949, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27426, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.470450344827586 ], [ 29.760300379746834, -1.470719827586207 ], [ 29.760569873417722, -1.470719827586207 ], [ 29.760569873417722, -1.470450344827586 ], [ 29.760300379746834, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27427, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.470450344827586 ], [ 29.760569873417722, -1.470719827586207 ], [ 29.760839367088607, -1.470719827586207 ], [ 29.760839367088607, -1.470450344827586 ], [ 29.760569873417722, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27428, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.470450344827586 ], [ 29.760839367088607, -1.470719827586207 ], [ 29.761108860759492, -1.470719827586207 ], [ 29.761108860759492, -1.470450344827586 ], [ 29.760839367088607, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27429, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.470450344827586 ], [ 29.761108860759492, -1.470719827586207 ], [ 29.761378354430381, -1.470719827586207 ], [ 29.761378354430381, -1.470450344827586 ], [ 29.761108860759492, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27430, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.470450344827586 ], [ 29.761378354430381, -1.470719827586207 ], [ 29.761647848101266, -1.470719827586207 ], [ 29.761647848101266, -1.470450344827586 ], [ 29.761378354430381, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27431, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.470450344827586 ], [ 29.761647848101266, -1.470719827586207 ], [ 29.761917341772151, -1.470719827586207 ], [ 29.761917341772151, -1.470450344827586 ], [ 29.761647848101266, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27432, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.470450344827586 ], [ 29.762186835443039, -1.470989310344828 ], [ 29.762456329113924, -1.470989310344828 ], [ 29.762456329113924, -1.470450344827586 ], [ 29.762186835443039, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27433, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.470450344827586 ], [ 29.762995316455697, -1.470989310344828 ], [ 29.763264810126582, -1.470989310344828 ], [ 29.763264810126582, -1.470450344827586 ], [ 29.762995316455697, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27434, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.470450344827586 ], [ 29.763803797468356, -1.470989310344828 ], [ 29.76407329113924, -1.470989310344828 ], [ 29.76407329113924, -1.470450344827586 ], [ 29.763803797468356, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27435, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.470450344827586 ], [ 29.764342784810125, -1.470719827586207 ], [ 29.764612278481014, -1.470719827586207 ], [ 29.764612278481014, -1.470450344827586 ], [ 29.764342784810125, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27436, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.470450344827586 ], [ 29.764612278481014, -1.470719827586207 ], [ 29.764881772151899, -1.470719827586207 ], [ 29.764881772151899, -1.470450344827586 ], [ 29.764612278481014, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27437, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.470450344827586 ], [ 29.765151265822784, -1.470719827586207 ], [ 29.765420759493672, -1.470719827586207 ], [ 29.765420759493672, -1.470450344827586 ], [ 29.765151265822784, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27438, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.470450344827586 ], [ 29.765420759493672, -1.470719827586207 ], [ 29.765959746835442, -1.470719827586207 ], [ 29.765959746835442, -1.470450344827586 ], [ 29.765420759493672, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27439, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.470450344827586 ], [ 29.765959746835442, -1.470719827586207 ], [ 29.766229240506327, -1.470719827586207 ], [ 29.766229240506327, -1.470450344827586 ], [ 29.765959746835442, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27440, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.470450344827586 ], [ 29.766229240506327, -1.470989310344828 ], [ 29.766498734177215, -1.470989310344828 ], [ 29.766498734177215, -1.470450344827586 ], [ 29.766229240506327, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27441, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.470450344827586 ], [ 29.766498734177215, -1.470989310344828 ], [ 29.7667682278481, -1.470989310344828 ], [ 29.7667682278481, -1.470450344827586 ], [ 29.766498734177215, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27442, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.470450344827586 ], [ 29.771080126582277, -1.470989310344828 ], [ 29.771349620253165, -1.470989310344828 ], [ 29.771349620253165, -1.470450344827586 ], [ 29.771080126582277, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27443, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.470450344827586 ], [ 29.771349620253165, -1.470989310344828 ], [ 29.77161911392405, -1.470989310344828 ], [ 29.77161911392405, -1.470450344827586 ], [ 29.771349620253165, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27444, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.470450344827586 ], [ 29.77161911392405, -1.470719827586207 ], [ 29.771888607594935, -1.470719827586207 ], [ 29.771888607594935, -1.470450344827586 ], [ 29.77161911392405, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27445, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.470450344827586 ], [ 29.771888607594935, -1.470719827586207 ], [ 29.772158101265823, -1.470719827586207 ], [ 29.772158101265823, -1.470450344827586 ], [ 29.771888607594935, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27446, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.470450344827586 ], [ 29.772158101265823, -1.470719827586207 ], [ 29.772427594936708, -1.470719827586207 ], [ 29.772427594936708, -1.470450344827586 ], [ 29.772158101265823, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27447, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.470450344827586 ], [ 29.772697088607593, -1.470719827586207 ], [ 29.772966582278482, -1.470719827586207 ], [ 29.772966582278482, -1.470450344827586 ], [ 29.772697088607593, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27448, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.470450344827586 ], [ 29.772966582278482, -1.470719827586207 ], [ 29.773236075949367, -1.470719827586207 ], [ 29.773236075949367, -1.470450344827586 ], [ 29.772966582278482, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27449, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.470450344827586 ], [ 29.773505569620252, -1.470989310344828 ], [ 29.77377506329114, -1.470989310344828 ], [ 29.77377506329114, -1.470450344827586 ], [ 29.773505569620252, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27450, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.470450344827586 ], [ 29.77431405063291, -1.470989310344828 ], [ 29.774853037974683, -1.470989310344828 ], [ 29.774853037974683, -1.470450344827586 ], [ 29.77431405063291, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27451, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.470450344827586 ], [ 29.775392025316457, -1.470989310344828 ], [ 29.775661518987341, -1.470989310344828 ], [ 29.775661518987341, -1.470450344827586 ], [ 29.775392025316457, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27452, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.470450344827586 ], [ 29.775931012658226, -1.470989310344828 ], [ 29.776200506329115, -1.470989310344828 ], [ 29.776200506329115, -1.470450344827586 ], [ 29.775931012658226, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27453, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.470450344827586 ], [ 29.776739493670885, -1.470989310344828 ], [ 29.777008987341773, -1.470989310344828 ], [ 29.777008987341773, -1.470450344827586 ], [ 29.776739493670885, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27454, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.470450344827586 ], [ 29.778086962025316, -1.471258793103448 ], [ 29.778356455696201, -1.471258793103448 ], [ 29.778356455696201, -1.470450344827586 ], [ 29.778086962025316, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27455, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.470450344827586 ], [ 29.779434430379748, -1.471258793103448 ], [ 29.779703924050633, -1.471258793103448 ], [ 29.779703924050633, -1.470450344827586 ], [ 29.779434430379748, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27456, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.470450344827586 ], [ 29.780512405063291, -1.470719827586207 ], [ 29.780781898734176, -1.470719827586207 ], [ 29.780781898734176, -1.470450344827586 ], [ 29.780512405063291, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27457, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.470450344827586 ], [ 29.780781898734176, -1.470719827586207 ], [ 29.781051392405065, -1.470719827586207 ], [ 29.781051392405065, -1.470450344827586 ], [ 29.780781898734176, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27458, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.470450344827586 ], [ 29.781051392405065, -1.470719827586207 ], [ 29.78132088607595, -1.470719827586207 ], [ 29.78132088607595, -1.470450344827586 ], [ 29.781051392405065, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27459, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.470450344827586 ], [ 29.78132088607595, -1.470719827586207 ], [ 29.781590379746834, -1.470719827586207 ], [ 29.781590379746834, -1.470450344827586 ], [ 29.78132088607595, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27460, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.470450344827586 ], [ 29.781590379746834, -1.470719827586207 ], [ 29.781859873417723, -1.470719827586207 ], [ 29.781859873417723, -1.470450344827586 ], [ 29.781590379746834, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27461, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.470450344827586 ], [ 29.781859873417723, -1.470719827586207 ], [ 29.782129367088608, -1.470719827586207 ], [ 29.782129367088608, -1.470450344827586 ], [ 29.781859873417723, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27462, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.470450344827586 ], [ 29.782129367088608, -1.470719827586207 ], [ 29.782398860759493, -1.470719827586207 ], [ 29.782398860759493, -1.470450344827586 ], [ 29.782129367088608, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27463, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.470450344827586 ], [ 29.782668354430381, -1.470719827586207 ], [ 29.782937848101266, -1.470719827586207 ], [ 29.782937848101266, -1.470450344827586 ], [ 29.782668354430381, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27464, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.470450344827586 ], [ 29.782937848101266, -1.470719827586207 ], [ 29.783207341772151, -1.470719827586207 ], [ 29.783207341772151, -1.470450344827586 ], [ 29.782937848101266, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27465, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.470450344827586 ], [ 29.783207341772151, -1.470989310344828 ], [ 29.783476835443039, -1.470989310344828 ], [ 29.783476835443039, -1.470450344827586 ], [ 29.783207341772151, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27466, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.470450344827586 ], [ 29.783476835443039, -1.470719827586207 ], [ 29.784015822784809, -1.470719827586207 ], [ 29.784015822784809, -1.470450344827586 ], [ 29.783476835443039, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27467, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.470450344827586 ], [ 29.784015822784809, -1.470719827586207 ], [ 29.784285316455698, -1.470719827586207 ], [ 29.784285316455698, -1.470450344827586 ], [ 29.784015822784809, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27468, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.470450344827586 ], [ 29.784285316455698, -1.470989310344828 ], [ 29.784554810126583, -1.470989310344828 ], [ 29.784554810126583, -1.470450344827586 ], [ 29.784285316455698, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27469, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.470450344827586 ], [ 29.784824303797468, -1.470719827586207 ], [ 29.785093797468356, -1.470719827586207 ], [ 29.785093797468356, -1.470450344827586 ], [ 29.784824303797468, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27470, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.470450344827586 ], [ 29.785093797468356, -1.470719827586207 ], [ 29.785363291139241, -1.470719827586207 ], [ 29.785363291139241, -1.470450344827586 ], [ 29.785093797468356, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27471, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.470450344827586 ], [ 29.785632784810126, -1.470719827586207 ], [ 29.785902278481014, -1.470719827586207 ], [ 29.785902278481014, -1.470450344827586 ], [ 29.785632784810126, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27472, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.470450344827586 ], [ 29.785902278481014, -1.470719827586207 ], [ 29.786171772151899, -1.470719827586207 ], [ 29.786171772151899, -1.470450344827586 ], [ 29.785902278481014, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27473, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.470450344827586 ], [ 29.786441265822784, -1.470719827586207 ], [ 29.786710759493673, -1.470719827586207 ], [ 29.786710759493673, -1.470450344827586 ], [ 29.786441265822784, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27474, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.470450344827586 ], [ 29.786710759493673, -1.470719827586207 ], [ 29.786980253164558, -1.470719827586207 ], [ 29.786980253164558, -1.470450344827586 ], [ 29.786710759493673, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27475, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.470450344827586 ], [ 29.786980253164558, -1.470719827586207 ], [ 29.787249746835442, -1.470719827586207 ], [ 29.787249746835442, -1.470450344827586 ], [ 29.786980253164558, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27476, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.470450344827586 ], [ 29.787249746835442, -1.470719827586207 ], [ 29.787519240506327, -1.470719827586207 ], [ 29.787519240506327, -1.470450344827586 ], [ 29.787249746835442, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27477, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.470450344827586 ], [ 29.787519240506327, -1.470719827586207 ], [ 29.787788734177216, -1.470719827586207 ], [ 29.787788734177216, -1.470450344827586 ], [ 29.787519240506327, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27478, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.470450344827586 ], [ 29.787788734177216, -1.470719827586207 ], [ 29.788058227848101, -1.470719827586207 ], [ 29.788058227848101, -1.470450344827586 ], [ 29.787788734177216, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27479, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.470450344827586 ], [ 29.788058227848101, -1.470719827586207 ], [ 29.788327721518986, -1.470719827586207 ], [ 29.788327721518986, -1.470450344827586 ], [ 29.788058227848101, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27480, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.470450344827586 ], [ 29.788327721518986, -1.470719827586207 ], [ 29.788597215189874, -1.470719827586207 ], [ 29.788597215189874, -1.470450344827586 ], [ 29.788327721518986, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27481, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.470450344827586 ], [ 29.788597215189874, -1.470989310344828 ], [ 29.788866708860759, -1.470989310344828 ], [ 29.788866708860759, -1.470450344827586 ], [ 29.788597215189874, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27482, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.470450344827586 ], [ 29.789405696202532, -1.470719827586207 ], [ 29.789675189873417, -1.470719827586207 ], [ 29.789675189873417, -1.470450344827586 ], [ 29.789405696202532, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27483, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.470450344827586 ], [ 29.789675189873417, -1.470719827586207 ], [ 29.789944683544302, -1.470719827586207 ], [ 29.789944683544302, -1.470450344827586 ], [ 29.789675189873417, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27484, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.470450344827586 ], [ 29.789944683544302, -1.470719827586207 ], [ 29.790214177215191, -1.470719827586207 ], [ 29.790214177215191, -1.470450344827586 ], [ 29.789944683544302, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27485, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.470450344827586 ], [ 29.799376962025317, -1.470719827586207 ], [ 29.799646455696202, -1.470719827586207 ], [ 29.799646455696202, -1.470450344827586 ], [ 29.799376962025317, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27486, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.470450344827586 ], [ 29.799646455696202, -1.470719827586207 ], [ 29.79991594936709, -1.470719827586207 ], [ 29.79991594936709, -1.470450344827586 ], [ 29.799646455696202, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27487, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.470450344827586 ], [ 29.79991594936709, -1.470719827586207 ], [ 29.800185443037975, -1.470719827586207 ], [ 29.800185443037975, -1.470450344827586 ], [ 29.79991594936709, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27488, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.470450344827586 ], [ 29.800185443037975, -1.470719827586207 ], [ 29.80045493670886, -1.470719827586207 ], [ 29.80045493670886, -1.470450344827586 ], [ 29.800185443037975, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27489, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.470450344827586 ], [ 29.80045493670886, -1.470719827586207 ], [ 29.800724430379748, -1.470719827586207 ], [ 29.800724430379748, -1.470450344827586 ], [ 29.80045493670886, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27490, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.470450344827586 ], [ 29.800724430379748, -1.470719827586207 ], [ 29.800993924050633, -1.470719827586207 ], [ 29.800993924050633, -1.470450344827586 ], [ 29.800724430379748, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27491, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.470450344827586 ], [ 29.800993924050633, -1.470719827586207 ], [ 29.801263417721518, -1.470719827586207 ], [ 29.801263417721518, -1.470450344827586 ], [ 29.800993924050633, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27492, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.470450344827586 ], [ 29.801263417721518, -1.470719827586207 ], [ 29.801532911392407, -1.470719827586207 ], [ 29.801532911392407, -1.470450344827586 ], [ 29.801263417721518, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27493, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.470450344827586 ], [ 29.801532911392407, -1.470719827586207 ], [ 29.802071898734177, -1.470719827586207 ], [ 29.802071898734177, -1.470450344827586 ], [ 29.801532911392407, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27494, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.470450344827586 ], [ 29.802071898734177, -1.470719827586207 ], [ 29.802341392405065, -1.470719827586207 ], [ 29.802341392405065, -1.470450344827586 ], [ 29.802071898734177, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27495, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.470450344827586 ], [ 29.802341392405065, -1.47179775862069 ], [ 29.80261088607595, -1.47179775862069 ], [ 29.80261088607595, -1.470450344827586 ], [ 29.802341392405065, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27496, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.470450344827586 ], [ 29.80261088607595, -1.47179775862069 ], [ 29.802880379746835, -1.47179775862069 ], [ 29.802880379746835, -1.470450344827586 ], [ 29.80261088607595, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27497, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757066455696201, -1.470719827586207 ], [ 29.757066455696201, -1.471258793103448 ], [ 29.757335949367089, -1.471258793103448 ], [ 29.757335949367089, -1.470719827586207 ], [ 29.757066455696201, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27498, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.470719827586207 ], [ 29.757335949367089, -1.470989310344828 ], [ 29.757605443037974, -1.470989310344828 ], [ 29.757605443037974, -1.470719827586207 ], [ 29.757335949367089, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27499, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.470719827586207 ], [ 29.757605443037974, -1.470989310344828 ], [ 29.757874936708859, -1.470989310344828 ], [ 29.757874936708859, -1.470719827586207 ], [ 29.757605443037974, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27500, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.470719827586207 ], [ 29.757874936708859, -1.470989310344828 ], [ 29.758144430379748, -1.470989310344828 ], [ 29.758144430379748, -1.470719827586207 ], [ 29.757874936708859, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27501, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.470719827586207 ], [ 29.758144430379748, -1.470989310344828 ], [ 29.758413924050632, -1.470989310344828 ], [ 29.758413924050632, -1.470719827586207 ], [ 29.758144430379748, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27502, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.470719827586207 ], [ 29.758413924050632, -1.470989310344828 ], [ 29.758683417721517, -1.470989310344828 ], [ 29.758683417721517, -1.470719827586207 ], [ 29.758413924050632, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27503, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.470719827586207 ], [ 29.758683417721517, -1.470989310344828 ], [ 29.758952911392406, -1.470989310344828 ], [ 29.758952911392406, -1.470719827586207 ], [ 29.758683417721517, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27504, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.470719827586207 ], [ 29.758952911392406, -1.470989310344828 ], [ 29.759222405063291, -1.470989310344828 ], [ 29.759222405063291, -1.470719827586207 ], [ 29.758952911392406, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27505, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.470719827586207 ], [ 29.759222405063291, -1.470989310344828 ], [ 29.759491898734176, -1.470989310344828 ], [ 29.759491898734176, -1.470719827586207 ], [ 29.759222405063291, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27506, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.470719827586207 ], [ 29.759491898734176, -1.470989310344828 ], [ 29.759761392405064, -1.470989310344828 ], [ 29.759761392405064, -1.470719827586207 ], [ 29.759491898734176, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27507, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.470719827586207 ], [ 29.759761392405064, -1.470989310344828 ], [ 29.760030886075949, -1.470989310344828 ], [ 29.760030886075949, -1.470719827586207 ], [ 29.759761392405064, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27508, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.470719827586207 ], [ 29.760030886075949, -1.470989310344828 ], [ 29.760300379746834, -1.470989310344828 ], [ 29.760300379746834, -1.470719827586207 ], [ 29.760030886075949, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27509, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.470719827586207 ], [ 29.760300379746834, -1.470989310344828 ], [ 29.760569873417722, -1.470989310344828 ], [ 29.760569873417722, -1.470719827586207 ], [ 29.760300379746834, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27510, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.470719827586207 ], [ 29.760569873417722, -1.470989310344828 ], [ 29.760839367088607, -1.470989310344828 ], [ 29.760839367088607, -1.470719827586207 ], [ 29.760569873417722, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27511, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.470719827586207 ], [ 29.760839367088607, -1.470989310344828 ], [ 29.761108860759492, -1.470989310344828 ], [ 29.761108860759492, -1.470719827586207 ], [ 29.760839367088607, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27512, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.470719827586207 ], [ 29.761108860759492, -1.471258793103448 ], [ 29.761378354430381, -1.471258793103448 ], [ 29.761378354430381, -1.470719827586207 ], [ 29.761108860759492, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27513, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.470719827586207 ], [ 29.761378354430381, -1.470989310344828 ], [ 29.761647848101266, -1.470989310344828 ], [ 29.761647848101266, -1.470719827586207 ], [ 29.761378354430381, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27514, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.470719827586207 ], [ 29.761647848101266, -1.470989310344828 ], [ 29.761917341772151, -1.470989310344828 ], [ 29.761917341772151, -1.470719827586207 ], [ 29.761647848101266, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27515, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.470719827586207 ], [ 29.761917341772151, -1.471258793103448 ], [ 29.762186835443039, -1.471258793103448 ], [ 29.762186835443039, -1.470719827586207 ], [ 29.761917341772151, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27516, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.470719827586207 ], [ 29.762456329113924, -1.470989310344828 ], [ 29.762725822784809, -1.470989310344828 ], [ 29.762725822784809, -1.470719827586207 ], [ 29.762456329113924, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27517, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.470719827586207 ], [ 29.762725822784809, -1.47179775862069 ], [ 29.762995316455697, -1.47179775862069 ], [ 29.762995316455697, -1.470719827586207 ], [ 29.762725822784809, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27518, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.470719827586207 ], [ 29.763264810126582, -1.470989310344828 ], [ 29.763534303797467, -1.470989310344828 ], [ 29.763534303797467, -1.470719827586207 ], [ 29.763264810126582, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27519, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.470719827586207 ], [ 29.763534303797467, -1.470989310344828 ], [ 29.763803797468356, -1.470989310344828 ], [ 29.763803797468356, -1.470719827586207 ], [ 29.763534303797467, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27520, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.470719827586207 ], [ 29.76407329113924, -1.470989310344828 ], [ 29.764342784810125, -1.470989310344828 ], [ 29.764342784810125, -1.470719827586207 ], [ 29.76407329113924, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27521, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.470719827586207 ], [ 29.764342784810125, -1.470989310344828 ], [ 29.764612278481014, -1.470989310344828 ], [ 29.764612278481014, -1.470719827586207 ], [ 29.764342784810125, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27522, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.470719827586207 ], [ 29.764612278481014, -1.470989310344828 ], [ 29.764881772151899, -1.470989310344828 ], [ 29.764881772151899, -1.470719827586207 ], [ 29.764612278481014, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27523, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.470719827586207 ], [ 29.764881772151899, -1.470989310344828 ], [ 29.765151265822784, -1.470989310344828 ], [ 29.765151265822784, -1.470719827586207 ], [ 29.764881772151899, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27524, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.470719827586207 ], [ 29.765151265822784, -1.470989310344828 ], [ 29.765420759493672, -1.470989310344828 ], [ 29.765420759493672, -1.470719827586207 ], [ 29.765151265822784, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27525, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.470719827586207 ], [ 29.765420759493672, -1.47179775862069 ], [ 29.765959746835442, -1.47179775862069 ], [ 29.765959746835442, -1.470719827586207 ], [ 29.765420759493672, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27526, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.470719827586207 ], [ 29.765959746835442, -1.47179775862069 ], [ 29.766229240506327, -1.47179775862069 ], [ 29.766229240506327, -1.470719827586207 ], [ 29.765959746835442, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27527, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.470719827586207 ], [ 29.77161911392405, -1.471258793103448 ], [ 29.771888607594935, -1.471258793103448 ], [ 29.771888607594935, -1.470719827586207 ], [ 29.77161911392405, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27528, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.470719827586207 ], [ 29.771888607594935, -1.471258793103448 ], [ 29.772158101265823, -1.471258793103448 ], [ 29.772158101265823, -1.470719827586207 ], [ 29.771888607594935, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27529, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.470719827586207 ], [ 29.772158101265823, -1.470989310344828 ], [ 29.772427594936708, -1.470989310344828 ], [ 29.772427594936708, -1.470719827586207 ], [ 29.772158101265823, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27530, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.470719827586207 ], [ 29.772427594936708, -1.470989310344828 ], [ 29.772697088607593, -1.470989310344828 ], [ 29.772697088607593, -1.470719827586207 ], [ 29.772427594936708, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27531, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.470719827586207 ], [ 29.772697088607593, -1.470989310344828 ], [ 29.772966582278482, -1.470989310344828 ], [ 29.772966582278482, -1.470719827586207 ], [ 29.772697088607593, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27532, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.470719827586207 ], [ 29.772966582278482, -1.470989310344828 ], [ 29.773236075949367, -1.470989310344828 ], [ 29.773236075949367, -1.470719827586207 ], [ 29.772966582278482, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27533, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.470719827586207 ], [ 29.773236075949367, -1.470989310344828 ], [ 29.773505569620252, -1.470989310344828 ], [ 29.773505569620252, -1.470719827586207 ], [ 29.773236075949367, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27534, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.470719827586207 ], [ 29.77377506329114, -1.470989310344828 ], [ 29.774044556962025, -1.470989310344828 ], [ 29.774044556962025, -1.470719827586207 ], [ 29.77377506329114, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27535, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.470719827586207 ], [ 29.774044556962025, -1.470989310344828 ], [ 29.77431405063291, -1.470989310344828 ], [ 29.77431405063291, -1.470719827586207 ], [ 29.774044556962025, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27536, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.470719827586207 ], [ 29.774853037974683, -1.471258793103448 ], [ 29.775122531645568, -1.471258793103448 ], [ 29.775122531645568, -1.470719827586207 ], [ 29.774853037974683, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27537, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.470719827586207 ], [ 29.775122531645568, -1.470989310344828 ], [ 29.775392025316457, -1.470989310344828 ], [ 29.775392025316457, -1.470719827586207 ], [ 29.775122531645568, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27538, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.470719827586207 ], [ 29.775661518987341, -1.471258793103448 ], [ 29.775931012658226, -1.471258793103448 ], [ 29.775931012658226, -1.470719827586207 ], [ 29.775661518987341, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27539, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.470719827586207 ], [ 29.77647, -1.471258793103448 ], [ 29.776739493670885, -1.471258793103448 ], [ 29.776739493670885, -1.470719827586207 ], [ 29.77647, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27540, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.470719827586207 ], [ 29.777278481012658, -1.471258793103448 ], [ 29.777547974683543, -1.471258793103448 ], [ 29.777547974683543, -1.470719827586207 ], [ 29.777278481012658, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27541, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.470719827586207 ], [ 29.777817468354431, -1.471258793103448 ], [ 29.778086962025316, -1.471258793103448 ], [ 29.778086962025316, -1.470719827586207 ], [ 29.777817468354431, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27542, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.470719827586207 ], [ 29.77862594936709, -1.471258793103448 ], [ 29.778895443037975, -1.471258793103448 ], [ 29.778895443037975, -1.470719827586207 ], [ 29.77862594936709, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27543, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.470719827586207 ], [ 29.779973417721518, -1.47179775862069 ], [ 29.780242911392406, -1.47179775862069 ], [ 29.780242911392406, -1.470719827586207 ], [ 29.779973417721518, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27544, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.470719827586207 ], [ 29.780781898734176, -1.470989310344828 ], [ 29.781051392405065, -1.470989310344828 ], [ 29.781051392405065, -1.470719827586207 ], [ 29.780781898734176, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27545, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.470719827586207 ], [ 29.781051392405065, -1.470989310344828 ], [ 29.78132088607595, -1.470989310344828 ], [ 29.78132088607595, -1.470719827586207 ], [ 29.781051392405065, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27546, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.470719827586207 ], [ 29.78132088607595, -1.470989310344828 ], [ 29.781590379746834, -1.470989310344828 ], [ 29.781590379746834, -1.470719827586207 ], [ 29.78132088607595, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27547, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.470719827586207 ], [ 29.781590379746834, -1.470989310344828 ], [ 29.781859873417723, -1.470989310344828 ], [ 29.781859873417723, -1.470719827586207 ], [ 29.781590379746834, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27548, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.470719827586207 ], [ 29.781859873417723, -1.470989310344828 ], [ 29.782129367088608, -1.470989310344828 ], [ 29.782129367088608, -1.470719827586207 ], [ 29.781859873417723, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27549, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.470719827586207 ], [ 29.782129367088608, -1.471258793103448 ], [ 29.782398860759493, -1.471258793103448 ], [ 29.782398860759493, -1.470719827586207 ], [ 29.782129367088608, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27550, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.470719827586207 ], [ 29.782398860759493, -1.470989310344828 ], [ 29.782668354430381, -1.470989310344828 ], [ 29.782668354430381, -1.470719827586207 ], [ 29.782398860759493, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27551, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.470719827586207 ], [ 29.782668354430381, -1.470989310344828 ], [ 29.782937848101266, -1.470989310344828 ], [ 29.782937848101266, -1.470719827586207 ], [ 29.782668354430381, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27552, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.470719827586207 ], [ 29.782937848101266, -1.471258793103448 ], [ 29.783207341772151, -1.471258793103448 ], [ 29.783207341772151, -1.470719827586207 ], [ 29.782937848101266, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27553, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.470719827586207 ], [ 29.783476835443039, -1.470989310344828 ], [ 29.784015822784809, -1.470989310344828 ], [ 29.784015822784809, -1.470719827586207 ], [ 29.783476835443039, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27554, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.470719827586207 ], [ 29.784015822784809, -1.470989310344828 ], [ 29.784285316455698, -1.470989310344828 ], [ 29.784285316455698, -1.470719827586207 ], [ 29.784015822784809, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27555, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.470719827586207 ], [ 29.784554810126583, -1.470989310344828 ], [ 29.784824303797468, -1.470989310344828 ], [ 29.784824303797468, -1.470719827586207 ], [ 29.784554810126583, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27556, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.470719827586207 ], [ 29.784824303797468, -1.470989310344828 ], [ 29.785093797468356, -1.470989310344828 ], [ 29.785093797468356, -1.470719827586207 ], [ 29.784824303797468, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27557, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.470719827586207 ], [ 29.785093797468356, -1.471258793103448 ], [ 29.785363291139241, -1.471258793103448 ], [ 29.785363291139241, -1.470719827586207 ], [ 29.785093797468356, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27558, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.470719827586207 ], [ 29.785363291139241, -1.470989310344828 ], [ 29.785632784810126, -1.470989310344828 ], [ 29.785632784810126, -1.470719827586207 ], [ 29.785363291139241, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27559, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.470719827586207 ], [ 29.785632784810126, -1.470989310344828 ], [ 29.785902278481014, -1.470989310344828 ], [ 29.785902278481014, -1.470719827586207 ], [ 29.785632784810126, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27560, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.470719827586207 ], [ 29.785902278481014, -1.470989310344828 ], [ 29.786171772151899, -1.470989310344828 ], [ 29.786171772151899, -1.470719827586207 ], [ 29.785902278481014, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27561, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.470719827586207 ], [ 29.786171772151899, -1.470989310344828 ], [ 29.786441265822784, -1.470989310344828 ], [ 29.786441265822784, -1.470719827586207 ], [ 29.786171772151899, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27562, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.470719827586207 ], [ 29.786441265822784, -1.470989310344828 ], [ 29.786710759493673, -1.470989310344828 ], [ 29.786710759493673, -1.470719827586207 ], [ 29.786441265822784, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27563, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.470719827586207 ], [ 29.786710759493673, -1.470989310344828 ], [ 29.786980253164558, -1.470989310344828 ], [ 29.786980253164558, -1.470719827586207 ], [ 29.786710759493673, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27564, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.470719827586207 ], [ 29.786980253164558, -1.470989310344828 ], [ 29.787249746835442, -1.470989310344828 ], [ 29.787249746835442, -1.470719827586207 ], [ 29.786980253164558, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27565, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.470719827586207 ], [ 29.787249746835442, -1.470989310344828 ], [ 29.787519240506327, -1.470989310344828 ], [ 29.787519240506327, -1.470719827586207 ], [ 29.787249746835442, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27566, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.470719827586207 ], [ 29.787519240506327, -1.471258793103448 ], [ 29.787788734177216, -1.471258793103448 ], [ 29.787788734177216, -1.470719827586207 ], [ 29.787519240506327, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27567, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.470719827586207 ], [ 29.787788734177216, -1.470989310344828 ], [ 29.788058227848101, -1.470989310344828 ], [ 29.788058227848101, -1.470719827586207 ], [ 29.787788734177216, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27568, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.470719827586207 ], [ 29.788058227848101, -1.470989310344828 ], [ 29.788327721518986, -1.470989310344828 ], [ 29.788327721518986, -1.470719827586207 ], [ 29.788058227848101, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27569, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.470719827586207 ], [ 29.788327721518986, -1.470989310344828 ], [ 29.788597215189874, -1.470989310344828 ], [ 29.788597215189874, -1.470719827586207 ], [ 29.788327721518986, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27570, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.470719827586207 ], [ 29.788866708860759, -1.470989310344828 ], [ 29.789136202531644, -1.470989310344828 ], [ 29.789136202531644, -1.470719827586207 ], [ 29.788866708860759, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27571, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.470719827586207 ], [ 29.789136202531644, -1.470989310344828 ], [ 29.789405696202532, -1.470989310344828 ], [ 29.789405696202532, -1.470719827586207 ], [ 29.789136202531644, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27572, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.470719827586207 ], [ 29.789405696202532, -1.470989310344828 ], [ 29.789675189873417, -1.470989310344828 ], [ 29.789675189873417, -1.470719827586207 ], [ 29.789405696202532, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27573, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.470719827586207 ], [ 29.799646455696202, -1.470989310344828 ], [ 29.79991594936709, -1.470989310344828 ], [ 29.79991594936709, -1.470719827586207 ], [ 29.799646455696202, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27574, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.470719827586207 ], [ 29.79991594936709, -1.470989310344828 ], [ 29.800185443037975, -1.470989310344828 ], [ 29.800185443037975, -1.470719827586207 ], [ 29.79991594936709, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27575, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.470719827586207 ], [ 29.800185443037975, -1.470989310344828 ], [ 29.80045493670886, -1.470989310344828 ], [ 29.80045493670886, -1.470719827586207 ], [ 29.800185443037975, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27576, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.470719827586207 ], [ 29.80045493670886, -1.470989310344828 ], [ 29.800724430379748, -1.470989310344828 ], [ 29.800724430379748, -1.470719827586207 ], [ 29.80045493670886, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27577, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.470719827586207 ], [ 29.800724430379748, -1.470989310344828 ], [ 29.800993924050633, -1.470989310344828 ], [ 29.800993924050633, -1.470719827586207 ], [ 29.800724430379748, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27578, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.470719827586207 ], [ 29.800993924050633, -1.470989310344828 ], [ 29.801263417721518, -1.470989310344828 ], [ 29.801263417721518, -1.470719827586207 ], [ 29.800993924050633, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27579, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.470719827586207 ], [ 29.801263417721518, -1.470989310344828 ], [ 29.801532911392407, -1.470989310344828 ], [ 29.801532911392407, -1.470719827586207 ], [ 29.801263417721518, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27580, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.470719827586207 ], [ 29.801532911392407, -1.470989310344828 ], [ 29.802071898734177, -1.470989310344828 ], [ 29.802071898734177, -1.470719827586207 ], [ 29.801532911392407, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27581, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.470719827586207 ], [ 29.802071898734177, -1.47179775862069 ], [ 29.802341392405065, -1.47179775862069 ], [ 29.802341392405065, -1.470719827586207 ], [ 29.802071898734177, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27582, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.470989310344828 ], [ 29.757335949367089, -1.471258793103448 ], [ 29.757605443037974, -1.471258793103448 ], [ 29.757605443037974, -1.470989310344828 ], [ 29.757335949367089, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27583, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.470989310344828 ], [ 29.757605443037974, -1.471258793103448 ], [ 29.757874936708859, -1.471258793103448 ], [ 29.757874936708859, -1.470989310344828 ], [ 29.757605443037974, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27584, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.470989310344828 ], [ 29.757874936708859, -1.471258793103448 ], [ 29.758144430379748, -1.471258793103448 ], [ 29.758144430379748, -1.470989310344828 ], [ 29.757874936708859, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27585, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.470989310344828 ], [ 29.758144430379748, -1.471258793103448 ], [ 29.758413924050632, -1.471258793103448 ], [ 29.758413924050632, -1.470989310344828 ], [ 29.758144430379748, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27586, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.470989310344828 ], [ 29.758413924050632, -1.471258793103448 ], [ 29.758683417721517, -1.471258793103448 ], [ 29.758683417721517, -1.470989310344828 ], [ 29.758413924050632, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27587, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.470989310344828 ], [ 29.758683417721517, -1.471258793103448 ], [ 29.758952911392406, -1.471258793103448 ], [ 29.758952911392406, -1.470989310344828 ], [ 29.758683417721517, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27588, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.470989310344828 ], [ 29.758952911392406, -1.471258793103448 ], [ 29.759222405063291, -1.471258793103448 ], [ 29.759222405063291, -1.470989310344828 ], [ 29.758952911392406, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27589, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.470989310344828 ], [ 29.759222405063291, -1.471258793103448 ], [ 29.759491898734176, -1.471258793103448 ], [ 29.759491898734176, -1.470989310344828 ], [ 29.759222405063291, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27590, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.470989310344828 ], [ 29.759491898734176, -1.471258793103448 ], [ 29.759761392405064, -1.471258793103448 ], [ 29.759761392405064, -1.470989310344828 ], [ 29.759491898734176, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27591, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.470989310344828 ], [ 29.759761392405064, -1.471258793103448 ], [ 29.760030886075949, -1.471258793103448 ], [ 29.760030886075949, -1.470989310344828 ], [ 29.759761392405064, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27592, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.470989310344828 ], [ 29.760030886075949, -1.47179775862069 ], [ 29.760300379746834, -1.47179775862069 ], [ 29.760300379746834, -1.470989310344828 ], [ 29.760030886075949, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27593, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.470989310344828 ], [ 29.760300379746834, -1.471258793103448 ], [ 29.760569873417722, -1.471258793103448 ], [ 29.760569873417722, -1.470989310344828 ], [ 29.760300379746834, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27594, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.470989310344828 ], [ 29.760569873417722, -1.471258793103448 ], [ 29.760839367088607, -1.471258793103448 ], [ 29.760839367088607, -1.470989310344828 ], [ 29.760569873417722, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27595, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.470989310344828 ], [ 29.760839367088607, -1.47179775862069 ], [ 29.761108860759492, -1.47179775862069 ], [ 29.761108860759492, -1.470989310344828 ], [ 29.760839367088607, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27596, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.470989310344828 ], [ 29.761378354430381, -1.471258793103448 ], [ 29.761647848101266, -1.471258793103448 ], [ 29.761647848101266, -1.470989310344828 ], [ 29.761378354430381, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27597, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.470989310344828 ], [ 29.761647848101266, -1.47179775862069 ], [ 29.761917341772151, -1.47179775862069 ], [ 29.761917341772151, -1.470989310344828 ], [ 29.761647848101266, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27598, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.470989310344828 ], [ 29.762186835443039, -1.471258793103448 ], [ 29.762456329113924, -1.471258793103448 ], [ 29.762456329113924, -1.470989310344828 ], [ 29.762186835443039, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27599, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.470989310344828 ], [ 29.762456329113924, -1.471258793103448 ], [ 29.762725822784809, -1.471258793103448 ], [ 29.762725822784809, -1.470989310344828 ], [ 29.762456329113924, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27600, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.470989310344828 ], [ 29.762995316455697, -1.471258793103448 ], [ 29.763264810126582, -1.471258793103448 ], [ 29.763264810126582, -1.470989310344828 ], [ 29.762995316455697, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27601, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.470989310344828 ], [ 29.763264810126582, -1.471258793103448 ], [ 29.763534303797467, -1.471258793103448 ], [ 29.763534303797467, -1.470989310344828 ], [ 29.763264810126582, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27602, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.470989310344828 ], [ 29.763534303797467, -1.471258793103448 ], [ 29.763803797468356, -1.471258793103448 ], [ 29.763803797468356, -1.470989310344828 ], [ 29.763534303797467, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27603, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.470989310344828 ], [ 29.763803797468356, -1.471258793103448 ], [ 29.76407329113924, -1.471258793103448 ], [ 29.76407329113924, -1.470989310344828 ], [ 29.763803797468356, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27604, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.470989310344828 ], [ 29.76407329113924, -1.471258793103448 ], [ 29.764342784810125, -1.471258793103448 ], [ 29.764342784810125, -1.470989310344828 ], [ 29.76407329113924, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27605, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.470989310344828 ], [ 29.764342784810125, -1.471258793103448 ], [ 29.764612278481014, -1.471258793103448 ], [ 29.764612278481014, -1.470989310344828 ], [ 29.764342784810125, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27606, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.470989310344828 ], [ 29.764612278481014, -1.471258793103448 ], [ 29.764881772151899, -1.471258793103448 ], [ 29.764881772151899, -1.470989310344828 ], [ 29.764612278481014, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27607, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.470989310344828 ], [ 29.764881772151899, -1.471258793103448 ], [ 29.765151265822784, -1.471258793103448 ], [ 29.765151265822784, -1.470989310344828 ], [ 29.764881772151899, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27608, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.470989310344828 ], [ 29.765151265822784, -1.471258793103448 ], [ 29.765420759493672, -1.471258793103448 ], [ 29.765420759493672, -1.470989310344828 ], [ 29.765151265822784, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27609, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.470989310344828 ], [ 29.766229240506327, -1.471258793103448 ], [ 29.766498734177215, -1.471258793103448 ], [ 29.766498734177215, -1.470989310344828 ], [ 29.766229240506327, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27610, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.470989310344828 ], [ 29.766498734177215, -1.471258793103448 ], [ 29.7667682278481, -1.471258793103448 ], [ 29.7667682278481, -1.470989310344828 ], [ 29.766498734177215, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27611, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.470989310344828 ], [ 29.7667682278481, -1.471258793103448 ], [ 29.767037721518985, -1.471258793103448 ], [ 29.767037721518985, -1.470989310344828 ], [ 29.7667682278481, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27612, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.470989310344828 ], [ 29.767037721518985, -1.471258793103448 ], [ 29.767307215189874, -1.471258793103448 ], [ 29.767307215189874, -1.470989310344828 ], [ 29.767037721518985, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27613, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.470989310344828 ], [ 29.770541139240507, -1.47179775862069 ], [ 29.770810632911392, -1.47179775862069 ], [ 29.770810632911392, -1.470989310344828 ], [ 29.770541139240507, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27614, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.470989310344828 ], [ 29.770810632911392, -1.471258793103448 ], [ 29.771080126582277, -1.471258793103448 ], [ 29.771080126582277, -1.470989310344828 ], [ 29.770810632911392, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27615, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.470989310344828 ], [ 29.771080126582277, -1.471258793103448 ], [ 29.771349620253165, -1.471258793103448 ], [ 29.771349620253165, -1.470989310344828 ], [ 29.771080126582277, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27616, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.470989310344828 ], [ 29.771349620253165, -1.471258793103448 ], [ 29.77161911392405, -1.471258793103448 ], [ 29.77161911392405, -1.470989310344828 ], [ 29.771349620253165, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27617, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.470989310344828 ], [ 29.772158101265823, -1.47179775862069 ], [ 29.772427594936708, -1.47179775862069 ], [ 29.772427594936708, -1.470989310344828 ], [ 29.772158101265823, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27618, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.470989310344828 ], [ 29.772427594936708, -1.471258793103448 ], [ 29.772697088607593, -1.471258793103448 ], [ 29.772697088607593, -1.470989310344828 ], [ 29.772427594936708, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27619, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.470989310344828 ], [ 29.772697088607593, -1.471258793103448 ], [ 29.772966582278482, -1.471258793103448 ], [ 29.772966582278482, -1.470989310344828 ], [ 29.772697088607593, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27620, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.470989310344828 ], [ 29.772966582278482, -1.471258793103448 ], [ 29.773236075949367, -1.471258793103448 ], [ 29.773236075949367, -1.470989310344828 ], [ 29.772966582278482, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27621, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.470989310344828 ], [ 29.773236075949367, -1.471258793103448 ], [ 29.773505569620252, -1.471258793103448 ], [ 29.773505569620252, -1.470989310344828 ], [ 29.773236075949367, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27622, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.470989310344828 ], [ 29.773505569620252, -1.471258793103448 ], [ 29.77377506329114, -1.471258793103448 ], [ 29.77377506329114, -1.470989310344828 ], [ 29.773505569620252, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27623, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.470989310344828 ], [ 29.77377506329114, -1.471258793103448 ], [ 29.774044556962025, -1.471258793103448 ], [ 29.774044556962025, -1.470989310344828 ], [ 29.77377506329114, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27624, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.470989310344828 ], [ 29.774044556962025, -1.471258793103448 ], [ 29.77431405063291, -1.471258793103448 ], [ 29.77431405063291, -1.470989310344828 ], [ 29.774044556962025, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27625, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.470989310344828 ], [ 29.77431405063291, -1.471258793103448 ], [ 29.774853037974683, -1.471258793103448 ], [ 29.774853037974683, -1.470989310344828 ], [ 29.77431405063291, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27626, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.470989310344828 ], [ 29.775122531645568, -1.471258793103448 ], [ 29.775392025316457, -1.471258793103448 ], [ 29.775392025316457, -1.470989310344828 ], [ 29.775122531645568, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27627, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.470989310344828 ], [ 29.775392025316457, -1.471258793103448 ], [ 29.775661518987341, -1.471258793103448 ], [ 29.775661518987341, -1.470989310344828 ], [ 29.775392025316457, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27628, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.470989310344828 ], [ 29.775931012658226, -1.471258793103448 ], [ 29.776200506329115, -1.471258793103448 ], [ 29.776200506329115, -1.470989310344828 ], [ 29.775931012658226, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27629, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.470989310344828 ], [ 29.776200506329115, -1.471258793103448 ], [ 29.77647, -1.471258793103448 ], [ 29.77647, -1.470989310344828 ], [ 29.776200506329115, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27630, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.470989310344828 ], [ 29.776739493670885, -1.47179775862069 ], [ 29.777008987341773, -1.47179775862069 ], [ 29.777008987341773, -1.470989310344828 ], [ 29.776739493670885, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27631, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.470989310344828 ], [ 29.777008987341773, -1.471258793103448 ], [ 29.777278481012658, -1.471258793103448 ], [ 29.777278481012658, -1.470989310344828 ], [ 29.777008987341773, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27632, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.470989310344828 ], [ 29.777547974683543, -1.47179775862069 ], [ 29.777817468354431, -1.47179775862069 ], [ 29.777817468354431, -1.470989310344828 ], [ 29.777547974683543, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27633, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.470989310344828 ], [ 29.778356455696201, -1.47179775862069 ], [ 29.77862594936709, -1.47179775862069 ], [ 29.77862594936709, -1.470989310344828 ], [ 29.778356455696201, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27634, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.470989310344828 ], [ 29.778895443037975, -1.47179775862069 ], [ 29.77916493670886, -1.47179775862069 ], [ 29.77916493670886, -1.470989310344828 ], [ 29.778895443037975, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27635, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.470989310344828 ], [ 29.77916493670886, -1.47179775862069 ], [ 29.779434430379748, -1.47179775862069 ], [ 29.779434430379748, -1.470989310344828 ], [ 29.77916493670886, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27636, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.470989310344828 ], [ 29.779703924050633, -1.47179775862069 ], [ 29.779973417721518, -1.47179775862069 ], [ 29.779973417721518, -1.470989310344828 ], [ 29.779703924050633, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27637, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.470989310344828 ], [ 29.780512405063291, -1.471258793103448 ], [ 29.780781898734176, -1.471258793103448 ], [ 29.780781898734176, -1.470989310344828 ], [ 29.780512405063291, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27638, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.470989310344828 ], [ 29.780781898734176, -1.471258793103448 ], [ 29.781051392405065, -1.471258793103448 ], [ 29.781051392405065, -1.470989310344828 ], [ 29.780781898734176, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27639, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.470989310344828 ], [ 29.781051392405065, -1.47179775862069 ], [ 29.78132088607595, -1.47179775862069 ], [ 29.78132088607595, -1.470989310344828 ], [ 29.781051392405065, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27640, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.470989310344828 ], [ 29.78132088607595, -1.471258793103448 ], [ 29.781590379746834, -1.471258793103448 ], [ 29.781590379746834, -1.470989310344828 ], [ 29.78132088607595, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27641, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.470989310344828 ], [ 29.781590379746834, -1.471258793103448 ], [ 29.781859873417723, -1.471258793103448 ], [ 29.781859873417723, -1.470989310344828 ], [ 29.781590379746834, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27642, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.470989310344828 ], [ 29.781859873417723, -1.47179775862069 ], [ 29.782129367088608, -1.47179775862069 ], [ 29.782129367088608, -1.470989310344828 ], [ 29.781859873417723, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27643, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.470989310344828 ], [ 29.782398860759493, -1.471258793103448 ], [ 29.782668354430381, -1.471258793103448 ], [ 29.782668354430381, -1.470989310344828 ], [ 29.782398860759493, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27644, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.470989310344828 ], [ 29.782668354430381, -1.471258793103448 ], [ 29.782937848101266, -1.471258793103448 ], [ 29.782937848101266, -1.470989310344828 ], [ 29.782668354430381, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27645, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.470989310344828 ], [ 29.783207341772151, -1.471258793103448 ], [ 29.783476835443039, -1.471258793103448 ], [ 29.783476835443039, -1.470989310344828 ], [ 29.783207341772151, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27646, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.470989310344828 ], [ 29.783476835443039, -1.471258793103448 ], [ 29.784015822784809, -1.471258793103448 ], [ 29.784015822784809, -1.470989310344828 ], [ 29.783476835443039, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27647, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.470989310344828 ], [ 29.784015822784809, -1.47179775862069 ], [ 29.784285316455698, -1.47179775862069 ], [ 29.784285316455698, -1.470989310344828 ], [ 29.784015822784809, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27648, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.470989310344828 ], [ 29.784285316455698, -1.471258793103448 ], [ 29.784554810126583, -1.471258793103448 ], [ 29.784554810126583, -1.470989310344828 ], [ 29.784285316455698, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27649, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.470989310344828 ], [ 29.784554810126583, -1.471258793103448 ], [ 29.784824303797468, -1.471258793103448 ], [ 29.784824303797468, -1.470989310344828 ], [ 29.784554810126583, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27650, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.470989310344828 ], [ 29.784824303797468, -1.471258793103448 ], [ 29.785093797468356, -1.471258793103448 ], [ 29.785093797468356, -1.470989310344828 ], [ 29.784824303797468, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27651, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.470989310344828 ], [ 29.785363291139241, -1.471258793103448 ], [ 29.785632784810126, -1.471258793103448 ], [ 29.785632784810126, -1.470989310344828 ], [ 29.785363291139241, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27652, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.470989310344828 ], [ 29.785632784810126, -1.471258793103448 ], [ 29.785902278481014, -1.471258793103448 ], [ 29.785902278481014, -1.470989310344828 ], [ 29.785632784810126, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27653, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.470989310344828 ], [ 29.785902278481014, -1.471258793103448 ], [ 29.786171772151899, -1.471258793103448 ], [ 29.786171772151899, -1.470989310344828 ], [ 29.785902278481014, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27654, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.470989310344828 ], [ 29.786171772151899, -1.471258793103448 ], [ 29.786441265822784, -1.471258793103448 ], [ 29.786441265822784, -1.470989310344828 ], [ 29.786171772151899, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27655, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.470989310344828 ], [ 29.786441265822784, -1.47179775862069 ], [ 29.786710759493673, -1.47179775862069 ], [ 29.786710759493673, -1.470989310344828 ], [ 29.786441265822784, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27656, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.470989310344828 ], [ 29.786710759493673, -1.471258793103448 ], [ 29.786980253164558, -1.471258793103448 ], [ 29.786980253164558, -1.470989310344828 ], [ 29.786710759493673, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27657, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.470989310344828 ], [ 29.786980253164558, -1.471258793103448 ], [ 29.787249746835442, -1.471258793103448 ], [ 29.787249746835442, -1.470989310344828 ], [ 29.786980253164558, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27658, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.470989310344828 ], [ 29.787249746835442, -1.471258793103448 ], [ 29.787519240506327, -1.471258793103448 ], [ 29.787519240506327, -1.470989310344828 ], [ 29.787249746835442, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27659, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.470989310344828 ], [ 29.787788734177216, -1.471258793103448 ], [ 29.788058227848101, -1.471258793103448 ], [ 29.788058227848101, -1.470989310344828 ], [ 29.787788734177216, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27660, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.470989310344828 ], [ 29.788058227848101, -1.471258793103448 ], [ 29.788327721518986, -1.471258793103448 ], [ 29.788327721518986, -1.470989310344828 ], [ 29.788058227848101, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27661, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.470989310344828 ], [ 29.788327721518986, -1.471258793103448 ], [ 29.788597215189874, -1.471258793103448 ], [ 29.788597215189874, -1.470989310344828 ], [ 29.788327721518986, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27662, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.470989310344828 ], [ 29.788597215189874, -1.471258793103448 ], [ 29.788866708860759, -1.471258793103448 ], [ 29.788866708860759, -1.470989310344828 ], [ 29.788597215189874, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27663, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.470989310344828 ], [ 29.799376962025317, -1.471258793103448 ], [ 29.799646455696202, -1.471258793103448 ], [ 29.799646455696202, -1.470989310344828 ], [ 29.799376962025317, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27664, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.470989310344828 ], [ 29.799646455696202, -1.471258793103448 ], [ 29.79991594936709, -1.471258793103448 ], [ 29.79991594936709, -1.470989310344828 ], [ 29.799646455696202, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27665, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.470989310344828 ], [ 29.79991594936709, -1.471258793103448 ], [ 29.800185443037975, -1.471258793103448 ], [ 29.800185443037975, -1.470989310344828 ], [ 29.79991594936709, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27666, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.470989310344828 ], [ 29.800185443037975, -1.471258793103448 ], [ 29.80045493670886, -1.471258793103448 ], [ 29.80045493670886, -1.470989310344828 ], [ 29.800185443037975, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27667, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.470989310344828 ], [ 29.80045493670886, -1.471258793103448 ], [ 29.800724430379748, -1.471258793103448 ], [ 29.800724430379748, -1.470989310344828 ], [ 29.80045493670886, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27668, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.470989310344828 ], [ 29.800724430379748, -1.471258793103448 ], [ 29.800993924050633, -1.471258793103448 ], [ 29.800993924050633, -1.470989310344828 ], [ 29.800724430379748, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27669, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.470989310344828 ], [ 29.800993924050633, -1.47179775862069 ], [ 29.801263417721518, -1.47179775862069 ], [ 29.801263417721518, -1.470989310344828 ], [ 29.800993924050633, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27670, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.470989310344828 ], [ 29.801263417721518, -1.471258793103448 ], [ 29.801532911392407, -1.471258793103448 ], [ 29.801532911392407, -1.470989310344828 ], [ 29.801263417721518, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27671, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.470989310344828 ], [ 29.801532911392407, -1.471258793103448 ], [ 29.802071898734177, -1.471258793103448 ], [ 29.802071898734177, -1.470989310344828 ], [ 29.801532911392407, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27672, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.471258793103448 ], [ 29.757605443037974, -1.47179775862069 ], [ 29.757874936708859, -1.47179775862069 ], [ 29.757874936708859, -1.471258793103448 ], [ 29.757605443037974, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27673, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.471258793103448 ], [ 29.757874936708859, -1.47179775862069 ], [ 29.758144430379748, -1.47179775862069 ], [ 29.758144430379748, -1.471258793103448 ], [ 29.757874936708859, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27674, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.471258793103448 ], [ 29.758144430379748, -1.47179775862069 ], [ 29.758413924050632, -1.47179775862069 ], [ 29.758413924050632, -1.471258793103448 ], [ 29.758144430379748, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27675, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.471258793103448 ], [ 29.758413924050632, -1.47179775862069 ], [ 29.758683417721517, -1.47179775862069 ], [ 29.758683417721517, -1.471258793103448 ], [ 29.758413924050632, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27676, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.471258793103448 ], [ 29.758683417721517, -1.47179775862069 ], [ 29.758952911392406, -1.47179775862069 ], [ 29.758952911392406, -1.471258793103448 ], [ 29.758683417721517, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27677, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.471258793103448 ], [ 29.759222405063291, -1.47179775862069 ], [ 29.759491898734176, -1.47179775862069 ], [ 29.759491898734176, -1.471258793103448 ], [ 29.759222405063291, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27678, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.471258793103448 ], [ 29.759491898734176, -1.47179775862069 ], [ 29.759761392405064, -1.47179775862069 ], [ 29.759761392405064, -1.471258793103448 ], [ 29.759491898734176, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27679, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.471258793103448 ], [ 29.761108860759492, -1.47179775862069 ], [ 29.761378354430381, -1.47179775862069 ], [ 29.761378354430381, -1.471258793103448 ], [ 29.761108860759492, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27680, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.471258793103448 ], [ 29.761917341772151, -1.47179775862069 ], [ 29.762186835443039, -1.47179775862069 ], [ 29.762186835443039, -1.471258793103448 ], [ 29.761917341772151, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27681, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.471258793103448 ], [ 29.762456329113924, -1.47179775862069 ], [ 29.762725822784809, -1.47179775862069 ], [ 29.762725822784809, -1.471258793103448 ], [ 29.762456329113924, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27682, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.471258793103448 ], [ 29.763264810126582, -1.47179775862069 ], [ 29.763534303797467, -1.47179775862069 ], [ 29.763534303797467, -1.471258793103448 ], [ 29.763264810126582, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27683, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.471258793103448 ], [ 29.763534303797467, -1.47179775862069 ], [ 29.763803797468356, -1.47179775862069 ], [ 29.763803797468356, -1.471258793103448 ], [ 29.763534303797467, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27684, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.471258793103448 ], [ 29.76407329113924, -1.47179775862069 ], [ 29.764342784810125, -1.47179775862069 ], [ 29.764342784810125, -1.471258793103448 ], [ 29.76407329113924, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27685, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.471258793103448 ], [ 29.764342784810125, -1.47179775862069 ], [ 29.764612278481014, -1.47179775862069 ], [ 29.764612278481014, -1.471258793103448 ], [ 29.764342784810125, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27686, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.471258793103448 ], [ 29.764612278481014, -1.47179775862069 ], [ 29.764881772151899, -1.47179775862069 ], [ 29.764881772151899, -1.471258793103448 ], [ 29.764612278481014, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27687, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.471258793103448 ], [ 29.764881772151899, -1.47179775862069 ], [ 29.765151265822784, -1.47179775862069 ], [ 29.765151265822784, -1.471258793103448 ], [ 29.764881772151899, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27688, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.471258793103448 ], [ 29.765151265822784, -1.47179775862069 ], [ 29.765420759493672, -1.47179775862069 ], [ 29.765420759493672, -1.471258793103448 ], [ 29.765151265822784, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27689, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.471258793103448 ], [ 29.770810632911392, -1.47179775862069 ], [ 29.771080126582277, -1.47179775862069 ], [ 29.771080126582277, -1.471258793103448 ], [ 29.770810632911392, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27690, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.471258793103448 ], [ 29.771080126582277, -1.47179775862069 ], [ 29.771349620253165, -1.47179775862069 ], [ 29.771349620253165, -1.471258793103448 ], [ 29.771080126582277, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27691, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.471258793103448 ], [ 29.771349620253165, -1.47179775862069 ], [ 29.77161911392405, -1.47179775862069 ], [ 29.77161911392405, -1.471258793103448 ], [ 29.771349620253165, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27692, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.471258793103448 ], [ 29.77161911392405, -1.47179775862069 ], [ 29.771888607594935, -1.47179775862069 ], [ 29.771888607594935, -1.471258793103448 ], [ 29.77161911392405, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27693, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.471258793103448 ], [ 29.771888607594935, -1.47179775862069 ], [ 29.772158101265823, -1.47179775862069 ], [ 29.772158101265823, -1.471258793103448 ], [ 29.771888607594935, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27694, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.471258793103448 ], [ 29.772427594936708, -1.47179775862069 ], [ 29.772697088607593, -1.47179775862069 ], [ 29.772697088607593, -1.471258793103448 ], [ 29.772427594936708, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27695, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.471258793103448 ], [ 29.772697088607593, -1.47179775862069 ], [ 29.772966582278482, -1.47179775862069 ], [ 29.772966582278482, -1.471258793103448 ], [ 29.772697088607593, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27696, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.471258793103448 ], [ 29.772966582278482, -1.47179775862069 ], [ 29.773236075949367, -1.47179775862069 ], [ 29.773236075949367, -1.471258793103448 ], [ 29.772966582278482, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27697, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.471258793103448 ], [ 29.773505569620252, -1.47179775862069 ], [ 29.77377506329114, -1.47179775862069 ], [ 29.77377506329114, -1.471258793103448 ], [ 29.773505569620252, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27698, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.471258793103448 ], [ 29.77377506329114, -1.47179775862069 ], [ 29.774044556962025, -1.47179775862069 ], [ 29.774044556962025, -1.471258793103448 ], [ 29.77377506329114, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27699, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.471258793103448 ], [ 29.774044556962025, -1.47179775862069 ], [ 29.77431405063291, -1.47179775862069 ], [ 29.77431405063291, -1.471258793103448 ], [ 29.774044556962025, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27700, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.471258793103448 ], [ 29.77431405063291, -1.47179775862069 ], [ 29.774853037974683, -1.47179775862069 ], [ 29.774853037974683, -1.471258793103448 ], [ 29.77431405063291, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27701, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.471258793103448 ], [ 29.774853037974683, -1.47179775862069 ], [ 29.775122531645568, -1.47179775862069 ], [ 29.775122531645568, -1.471258793103448 ], [ 29.774853037974683, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27702, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.471258793103448 ], [ 29.775122531645568, -1.47179775862069 ], [ 29.775392025316457, -1.47179775862069 ], [ 29.775392025316457, -1.471258793103448 ], [ 29.775122531645568, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27703, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.471258793103448 ], [ 29.775392025316457, -1.47179775862069 ], [ 29.775661518987341, -1.47179775862069 ], [ 29.775661518987341, -1.471258793103448 ], [ 29.775392025316457, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27704, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.471258793103448 ], [ 29.775661518987341, -1.47179775862069 ], [ 29.775931012658226, -1.47179775862069 ], [ 29.775931012658226, -1.471258793103448 ], [ 29.775661518987341, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27705, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.471258793103448 ], [ 29.776200506329115, -1.47179775862069 ], [ 29.77647, -1.47179775862069 ], [ 29.77647, -1.471258793103448 ], [ 29.776200506329115, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27706, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.471258793103448 ], [ 29.77647, -1.47179775862069 ], [ 29.776739493670885, -1.47179775862069 ], [ 29.776739493670885, -1.471258793103448 ], [ 29.77647, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27707, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.471258793103448 ], [ 29.777008987341773, -1.47179775862069 ], [ 29.777278481012658, -1.47179775862069 ], [ 29.777278481012658, -1.471258793103448 ], [ 29.777008987341773, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27708, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.471258793103448 ], [ 29.777278481012658, -1.47179775862069 ], [ 29.777547974683543, -1.47179775862069 ], [ 29.777547974683543, -1.471258793103448 ], [ 29.777278481012658, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27709, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.471258793103448 ], [ 29.778086962025316, -1.47179775862069 ], [ 29.778356455696201, -1.47179775862069 ], [ 29.778356455696201, -1.471258793103448 ], [ 29.778086962025316, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27710, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.471258793103448 ], [ 29.780512405063291, -1.47179775862069 ], [ 29.780781898734176, -1.47179775862069 ], [ 29.780781898734176, -1.471258793103448 ], [ 29.780512405063291, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27711, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.471258793103448 ], [ 29.78132088607595, -1.47179775862069 ], [ 29.781590379746834, -1.47179775862069 ], [ 29.781590379746834, -1.471258793103448 ], [ 29.78132088607595, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27712, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.471258793103448 ], [ 29.781590379746834, -1.47179775862069 ], [ 29.781859873417723, -1.47179775862069 ], [ 29.781859873417723, -1.471258793103448 ], [ 29.781590379746834, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27713, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.471258793103448 ], [ 29.782129367088608, -1.47179775862069 ], [ 29.782398860759493, -1.47179775862069 ], [ 29.782398860759493, -1.471258793103448 ], [ 29.782129367088608, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27714, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.471258793103448 ], [ 29.782398860759493, -1.47179775862069 ], [ 29.782668354430381, -1.47179775862069 ], [ 29.782668354430381, -1.471258793103448 ], [ 29.782398860759493, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27715, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.471258793103448 ], [ 29.782937848101266, -1.47179775862069 ], [ 29.783207341772151, -1.47179775862069 ], [ 29.783207341772151, -1.471258793103448 ], [ 29.782937848101266, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27716, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.471258793103448 ], [ 29.783207341772151, -1.47179775862069 ], [ 29.783476835443039, -1.47179775862069 ], [ 29.783476835443039, -1.471258793103448 ], [ 29.783207341772151, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27717, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.471258793103448 ], [ 29.783476835443039, -1.47179775862069 ], [ 29.784015822784809, -1.47179775862069 ], [ 29.784015822784809, -1.471258793103448 ], [ 29.783476835443039, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27718, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.471258793103448 ], [ 29.784285316455698, -1.47179775862069 ], [ 29.784554810126583, -1.47179775862069 ], [ 29.784554810126583, -1.471258793103448 ], [ 29.784285316455698, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27719, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.471258793103448 ], [ 29.784554810126583, -1.47179775862069 ], [ 29.784824303797468, -1.47179775862069 ], [ 29.784824303797468, -1.471258793103448 ], [ 29.784554810126583, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27720, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.471258793103448 ], [ 29.784824303797468, -1.47179775862069 ], [ 29.785093797468356, -1.47179775862069 ], [ 29.785093797468356, -1.471258793103448 ], [ 29.784824303797468, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27721, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.471258793103448 ], [ 29.785093797468356, -1.47179775862069 ], [ 29.785363291139241, -1.47179775862069 ], [ 29.785363291139241, -1.471258793103448 ], [ 29.785093797468356, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27722, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.471258793103448 ], [ 29.785363291139241, -1.47179775862069 ], [ 29.785632784810126, -1.47179775862069 ], [ 29.785632784810126, -1.471258793103448 ], [ 29.785363291139241, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27723, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.471258793103448 ], [ 29.785632784810126, -1.47179775862069 ], [ 29.785902278481014, -1.47179775862069 ], [ 29.785902278481014, -1.471258793103448 ], [ 29.785632784810126, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27724, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.471258793103448 ], [ 29.785902278481014, -1.47179775862069 ], [ 29.786171772151899, -1.47179775862069 ], [ 29.786171772151899, -1.471258793103448 ], [ 29.785902278481014, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27725, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.471258793103448 ], [ 29.786171772151899, -1.47179775862069 ], [ 29.786441265822784, -1.47179775862069 ], [ 29.786441265822784, -1.471258793103448 ], [ 29.786171772151899, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27726, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.471258793103448 ], [ 29.786710759493673, -1.47179775862069 ], [ 29.786980253164558, -1.47179775862069 ], [ 29.786980253164558, -1.471258793103448 ], [ 29.786710759493673, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27727, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.471258793103448 ], [ 29.786980253164558, -1.47179775862069 ], [ 29.787249746835442, -1.47179775862069 ], [ 29.787249746835442, -1.471258793103448 ], [ 29.786980253164558, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27728, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.471258793103448 ], [ 29.787249746835442, -1.47179775862069 ], [ 29.787519240506327, -1.47179775862069 ], [ 29.787519240506327, -1.471258793103448 ], [ 29.787249746835442, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27729, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.471258793103448 ], [ 29.787788734177216, -1.47179775862069 ], [ 29.788058227848101, -1.47179775862069 ], [ 29.788058227848101, -1.471258793103448 ], [ 29.787788734177216, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27730, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.471258793103448 ], [ 29.788058227848101, -1.47179775862069 ], [ 29.788327721518986, -1.47179775862069 ], [ 29.788327721518986, -1.471258793103448 ], [ 29.788058227848101, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27731, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.471258793103448 ], [ 29.799646455696202, -1.47179775862069 ], [ 29.79991594936709, -1.47179775862069 ], [ 29.79991594936709, -1.471258793103448 ], [ 29.799646455696202, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27732, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.471258793103448 ], [ 29.800724430379748, -1.47179775862069 ], [ 29.800993924050633, -1.47179775862069 ], [ 29.800993924050633, -1.471258793103448 ], [ 29.800724430379748, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27733, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.471258793103448 ], [ 29.801263417721518, -1.47179775862069 ], [ 29.801532911392407, -1.47179775862069 ], [ 29.801532911392407, -1.471258793103448 ], [ 29.801263417721518, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27734, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.471258793103448 ], [ 29.801532911392407, -1.47179775862069 ], [ 29.802071898734177, -1.47179775862069 ], [ 29.802071898734177, -1.471258793103448 ], [ 29.801532911392407, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27735, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.469641896551724 ], [ 29.803149873417723, -1.469911379310345 ], [ 29.803419367088608, -1.469911379310345 ], [ 29.803419367088608, -1.472336724137931 ], [ 29.803688860759493, -1.472336724137931 ], [ 29.803688860759493, -1.469641896551724 ], [ 29.803149873417723, -1.469641896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27736, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.469911379310345 ], [ 29.798298987341774, -1.472336724137931 ], [ 29.798568481012659, -1.472336724137931 ], [ 29.798568481012659, -1.469911379310345 ], [ 29.798298987341774, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27737, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.469911379310345 ], [ 29.803149873417723, -1.47206724137931 ], [ 29.803419367088608, -1.47206724137931 ], [ 29.803419367088608, -1.469911379310345 ], [ 29.803149873417723, -1.469911379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27738, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.470180862068966 ], [ 29.796951518987342, -1.472875689655172 ], [ 29.797221012658227, -1.472875689655172 ], [ 29.797221012658227, -1.470180862068966 ], [ 29.796951518987342, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27739, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.470180862068966 ], [ 29.797221012658227, -1.472875689655172 ], [ 29.797490506329115, -1.472875689655172 ], [ 29.797490506329115, -1.470180862068966 ], [ 29.797221012658227, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27740, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.470180862068966 ], [ 29.79776, -1.472606206896552 ], [ 29.798029493670885, -1.472606206896552 ], [ 29.798029493670885, -1.470180862068966 ], [ 29.79776, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27741, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.470180862068966 ], [ 29.798029493670885, -1.472606206896552 ], [ 29.798298987341774, -1.472606206896552 ], [ 29.798298987341774, -1.470180862068966 ], [ 29.798029493670885, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27742, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.470180862068966 ], [ 29.798568481012659, -1.472336724137931 ], [ 29.798837974683543, -1.472336724137931 ], [ 29.798837974683543, -1.470180862068966 ], [ 29.798568481012659, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27743, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.470180862068966 ], [ 29.802880379746835, -1.47206724137931 ], [ 29.803149873417723, -1.47206724137931 ], [ 29.803149873417723, -1.470180862068966 ], [ 29.802880379746835, -1.470180862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27744, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.470450344827586 ], [ 29.790214177215191, -1.472336724137931 ], [ 29.790483670886076, -1.472336724137931 ], [ 29.790483670886076, -1.470450344827586 ], [ 29.790214177215191, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27745, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796143037974684, -1.470450344827586 ], [ 29.796143037974684, -1.473414655172414 ], [ 29.796412531645569, -1.473414655172414 ], [ 29.796412531645569, -1.470450344827586 ], [ 29.796143037974684, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27746, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.470450344827586 ], [ 29.796412531645569, -1.473145172413793 ], [ 29.796682025316457, -1.473145172413793 ], [ 29.796682025316457, -1.470450344827586 ], [ 29.796412531645569, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27747, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.470450344827586 ], [ 29.796682025316457, -1.473145172413793 ], [ 29.796951518987342, -1.473145172413793 ], [ 29.796951518987342, -1.470450344827586 ], [ 29.796682025316457, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27748, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.470450344827586 ], [ 29.797490506329115, -1.472875689655172 ], [ 29.79776, -1.472875689655172 ], [ 29.79776, -1.470450344827586 ], [ 29.797490506329115, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27749, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.470450344827586 ], [ 29.798837974683543, -1.472606206896552 ], [ 29.799107468354432, -1.472606206896552 ], [ 29.799107468354432, -1.470450344827586 ], [ 29.798837974683543, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27750, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.470450344827586 ], [ 29.799107468354432, -1.472336724137931 ], [ 29.799376962025317, -1.472336724137931 ], [ 29.799376962025317, -1.470989310344828 ], [ 29.799646455696202, -1.470989310344828 ], [ 29.799646455696202, -1.470719827586207 ], [ 29.799376962025317, -1.470719827586207 ], [ 29.799376962025317, -1.470450344827586 ], [ 29.799107468354432, -1.470450344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27751, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.470719827586207 ], [ 29.789675189873417, -1.472336724137931 ], [ 29.789944683544302, -1.472336724137931 ], [ 29.789944683544302, -1.470719827586207 ], [ 29.789675189873417, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27752, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.470719827586207 ], [ 29.789944683544302, -1.472336724137931 ], [ 29.790214177215191, -1.472336724137931 ], [ 29.790214177215191, -1.470719827586207 ], [ 29.789944683544302, -1.470719827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27753, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.470989310344828 ], [ 29.788866708860759, -1.472336724137931 ], [ 29.789136202531644, -1.472336724137931 ], [ 29.789136202531644, -1.470989310344828 ], [ 29.788866708860759, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27754, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.470989310344828 ], [ 29.789136202531644, -1.472606206896552 ], [ 29.789405696202532, -1.472606206896552 ], [ 29.789405696202532, -1.470989310344828 ], [ 29.789136202531644, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27755, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.470989310344828 ], [ 29.789405696202532, -1.472606206896552 ], [ 29.789675189873417, -1.472606206896552 ], [ 29.789675189873417, -1.470989310344828 ], [ 29.789405696202532, -1.470989310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27756, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757335949367089, -1.471258793103448 ], [ 29.757335949367089, -1.47206724137931 ], [ 29.757605443037974, -1.47206724137931 ], [ 29.757605443037974, -1.471258793103448 ], [ 29.757335949367089, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27757, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.471258793103448 ], [ 29.758952911392406, -1.47206724137931 ], [ 29.759222405063291, -1.47206724137931 ], [ 29.759222405063291, -1.471258793103448 ], [ 29.758952911392406, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27758, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.471258793103448 ], [ 29.759761392405064, -1.47206724137931 ], [ 29.760030886075949, -1.47206724137931 ], [ 29.760030886075949, -1.471258793103448 ], [ 29.759761392405064, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27759, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.471258793103448 ], [ 29.760300379746834, -1.47206724137931 ], [ 29.760569873417722, -1.47206724137931 ], [ 29.760569873417722, -1.471258793103448 ], [ 29.760300379746834, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27760, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.471258793103448 ], [ 29.760569873417722, -1.47206724137931 ], [ 29.760839367088607, -1.47206724137931 ], [ 29.760839367088607, -1.471258793103448 ], [ 29.760569873417722, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27761, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.471258793103448 ], [ 29.761378354430381, -1.47206724137931 ], [ 29.761647848101266, -1.47206724137931 ], [ 29.761647848101266, -1.471258793103448 ], [ 29.761378354430381, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27762, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.471258793103448 ], [ 29.762186835443039, -1.47206724137931 ], [ 29.762456329113924, -1.47206724137931 ], [ 29.762456329113924, -1.471258793103448 ], [ 29.762186835443039, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27763, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.471258793103448 ], [ 29.762995316455697, -1.47206724137931 ], [ 29.763264810126582, -1.47206724137931 ], [ 29.763264810126582, -1.471258793103448 ], [ 29.762995316455697, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27764, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.471258793103448 ], [ 29.763803797468356, -1.47206724137931 ], [ 29.76407329113924, -1.47206724137931 ], [ 29.76407329113924, -1.471258793103448 ], [ 29.763803797468356, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27765, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.471258793103448 ], [ 29.766229240506327, -1.47206724137931 ], [ 29.766498734177215, -1.47206724137931 ], [ 29.766498734177215, -1.471258793103448 ], [ 29.766229240506327, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27766, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.471258793103448 ], [ 29.766498734177215, -1.47206724137931 ], [ 29.7667682278481, -1.47206724137931 ], [ 29.7667682278481, -1.471258793103448 ], [ 29.766498734177215, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27767, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.471258793103448 ], [ 29.7667682278481, -1.472336724137931 ], [ 29.767037721518985, -1.472336724137931 ], [ 29.767037721518985, -1.471258793103448 ], [ 29.7667682278481, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27768, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.471258793103448 ], [ 29.773236075949367, -1.47206724137931 ], [ 29.773505569620252, -1.47206724137931 ], [ 29.773505569620252, -1.471258793103448 ], [ 29.773236075949367, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27769, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.471258793103448 ], [ 29.775931012658226, -1.47206724137931 ], [ 29.776200506329115, -1.47206724137931 ], [ 29.776200506329115, -1.471258793103448 ], [ 29.775931012658226, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27770, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.471258793103448 ], [ 29.777817468354431, -1.47206724137931 ], [ 29.778086962025316, -1.47206724137931 ], [ 29.778086962025316, -1.471258793103448 ], [ 29.777817468354431, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27771, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.471258793103448 ], [ 29.77862594936709, -1.47206724137931 ], [ 29.778895443037975, -1.47206724137931 ], [ 29.778895443037975, -1.471258793103448 ], [ 29.77862594936709, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27772, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.471258793103448 ], [ 29.779434430379748, -1.47206724137931 ], [ 29.779703924050633, -1.47206724137931 ], [ 29.779703924050633, -1.471258793103448 ], [ 29.779434430379748, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27773, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.471258793103448 ], [ 29.780242911392406, -1.47206724137931 ], [ 29.780512405063291, -1.47206724137931 ], [ 29.780512405063291, -1.471258793103448 ], [ 29.780242911392406, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27774, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.471258793103448 ], [ 29.780781898734176, -1.47206724137931 ], [ 29.781051392405065, -1.47206724137931 ], [ 29.781051392405065, -1.471258793103448 ], [ 29.780781898734176, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27775, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.471258793103448 ], [ 29.782668354430381, -1.47206724137931 ], [ 29.782937848101266, -1.47206724137931 ], [ 29.782937848101266, -1.471258793103448 ], [ 29.782668354430381, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27776, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.471258793103448 ], [ 29.787519240506327, -1.472336724137931 ], [ 29.787788734177216, -1.472336724137931 ], [ 29.787788734177216, -1.471258793103448 ], [ 29.787519240506327, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27777, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.471258793103448 ], [ 29.788327721518986, -1.472606206896552 ], [ 29.788597215189874, -1.472606206896552 ], [ 29.788597215189874, -1.471258793103448 ], [ 29.788327721518986, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27778, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.471258793103448 ], [ 29.788597215189874, -1.472606206896552 ], [ 29.788866708860759, -1.472606206896552 ], [ 29.788866708860759, -1.471258793103448 ], [ 29.788597215189874, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27779, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.471258793103448 ], [ 29.800185443037975, -1.472336724137931 ], [ 29.80045493670886, -1.472336724137931 ], [ 29.80045493670886, -1.471258793103448 ], [ 29.800185443037975, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27780, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.471258793103448 ], [ 29.80045493670886, -1.47206724137931 ], [ 29.800724430379748, -1.47206724137931 ], [ 29.800724430379748, -1.471258793103448 ], [ 29.80045493670886, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27781, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.47179775862069 ], [ 29.757605443037974, -1.47206724137931 ], [ 29.757874936708859, -1.47206724137931 ], [ 29.757874936708859, -1.47179775862069 ], [ 29.757605443037974, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27782, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.47179775862069 ], [ 29.757874936708859, -1.47206724137931 ], [ 29.758144430379748, -1.47206724137931 ], [ 29.758144430379748, -1.47179775862069 ], [ 29.757874936708859, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27783, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.47179775862069 ], [ 29.758144430379748, -1.47206724137931 ], [ 29.758413924050632, -1.47206724137931 ], [ 29.758413924050632, -1.47179775862069 ], [ 29.758144430379748, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27784, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.47179775862069 ], [ 29.758413924050632, -1.472606206896552 ], [ 29.758683417721517, -1.472606206896552 ], [ 29.758683417721517, -1.47179775862069 ], [ 29.758413924050632, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27785, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.47179775862069 ], [ 29.758683417721517, -1.472606206896552 ], [ 29.758952911392406, -1.472606206896552 ], [ 29.758952911392406, -1.47179775862069 ], [ 29.758683417721517, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27786, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.47179775862069 ], [ 29.759222405063291, -1.472336724137931 ], [ 29.759491898734176, -1.472336724137931 ], [ 29.759491898734176, -1.47179775862069 ], [ 29.759222405063291, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27787, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.47179775862069 ], [ 29.759491898734176, -1.472336724137931 ], [ 29.759761392405064, -1.472336724137931 ], [ 29.759761392405064, -1.47179775862069 ], [ 29.759491898734176, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27788, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.47179775862069 ], [ 29.760030886075949, -1.47206724137931 ], [ 29.760300379746834, -1.47206724137931 ], [ 29.760300379746834, -1.47179775862069 ], [ 29.760030886075949, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27789, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.47179775862069 ], [ 29.760839367088607, -1.47206724137931 ], [ 29.761108860759492, -1.47206724137931 ], [ 29.761108860759492, -1.47179775862069 ], [ 29.760839367088607, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27790, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.47179775862069 ], [ 29.761108860759492, -1.47206724137931 ], [ 29.761378354430381, -1.47206724137931 ], [ 29.761378354430381, -1.47179775862069 ], [ 29.761108860759492, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27791, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.47179775862069 ], [ 29.761647848101266, -1.47206724137931 ], [ 29.761917341772151, -1.47206724137931 ], [ 29.761917341772151, -1.47179775862069 ], [ 29.761647848101266, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27792, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.47179775862069 ], [ 29.761917341772151, -1.47206724137931 ], [ 29.762186835443039, -1.47206724137931 ], [ 29.762186835443039, -1.47179775862069 ], [ 29.761917341772151, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27793, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.47179775862069 ], [ 29.762456329113924, -1.473414655172414 ], [ 29.762725822784809, -1.473414655172414 ], [ 29.762725822784809, -1.47179775862069 ], [ 29.762456329113924, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27794, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.47179775862069 ], [ 29.762725822784809, -1.473414655172414 ], [ 29.762995316455697, -1.473414655172414 ], [ 29.762995316455697, -1.47179775862069 ], [ 29.762725822784809, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27795, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.47179775862069 ], [ 29.763264810126582, -1.473414655172414 ], [ 29.763534303797467, -1.473414655172414 ], [ 29.763534303797467, -1.47179775862069 ], [ 29.763264810126582, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27796, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.47179775862069 ], [ 29.763534303797467, -1.472336724137931 ], [ 29.763803797468356, -1.472336724137931 ], [ 29.763803797468356, -1.47179775862069 ], [ 29.763534303797467, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27797, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.47179775862069 ], [ 29.76407329113924, -1.473414655172414 ], [ 29.764342784810125, -1.473414655172414 ], [ 29.764342784810125, -1.47179775862069 ], [ 29.76407329113924, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27798, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.47179775862069 ], [ 29.764342784810125, -1.47206724137931 ], [ 29.764612278481014, -1.47206724137931 ], [ 29.764612278481014, -1.47179775862069 ], [ 29.764342784810125, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27799, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.47179775862069 ], [ 29.764612278481014, -1.47206724137931 ], [ 29.764881772151899, -1.47206724137931 ], [ 29.764881772151899, -1.47179775862069 ], [ 29.764612278481014, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27800, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.47179775862069 ], [ 29.764881772151899, -1.472336724137931 ], [ 29.765151265822784, -1.472336724137931 ], [ 29.765151265822784, -1.47179775862069 ], [ 29.764881772151899, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27801, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.47179775862069 ], [ 29.765151265822784, -1.47206724137931 ], [ 29.765420759493672, -1.47206724137931 ], [ 29.765420759493672, -1.47179775862069 ], [ 29.765151265822784, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27802, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.47179775862069 ], [ 29.765420759493672, -1.47206724137931 ], [ 29.765959746835442, -1.47206724137931 ], [ 29.765959746835442, -1.47179775862069 ], [ 29.765420759493672, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27803, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.47179775862069 ], [ 29.765959746835442, -1.47206724137931 ], [ 29.766229240506327, -1.47206724137931 ], [ 29.766229240506327, -1.47179775862069 ], [ 29.765959746835442, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27804, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.47179775862069 ], [ 29.770271645569618, -1.473145172413793 ], [ 29.770541139240507, -1.473145172413793 ], [ 29.770541139240507, -1.47179775862069 ], [ 29.770271645569618, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27805, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.47179775862069 ], [ 29.770541139240507, -1.47206724137931 ], [ 29.770810632911392, -1.47206724137931 ], [ 29.770810632911392, -1.47179775862069 ], [ 29.770541139240507, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27806, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.47179775862069 ], [ 29.770810632911392, -1.47206724137931 ], [ 29.771080126582277, -1.47206724137931 ], [ 29.771080126582277, -1.47179775862069 ], [ 29.770810632911392, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27807, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.47179775862069 ], [ 29.771080126582277, -1.47206724137931 ], [ 29.771349620253165, -1.47206724137931 ], [ 29.771349620253165, -1.47179775862069 ], [ 29.771080126582277, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27808, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.47179775862069 ], [ 29.771349620253165, -1.47206724137931 ], [ 29.77161911392405, -1.47206724137931 ], [ 29.77161911392405, -1.47179775862069 ], [ 29.771349620253165, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27809, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.47179775862069 ], [ 29.77161911392405, -1.47206724137931 ], [ 29.771888607594935, -1.47206724137931 ], [ 29.771888607594935, -1.47179775862069 ], [ 29.77161911392405, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27810, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.47179775862069 ], [ 29.771888607594935, -1.47206724137931 ], [ 29.772158101265823, -1.47206724137931 ], [ 29.772158101265823, -1.47179775862069 ], [ 29.771888607594935, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27811, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.47179775862069 ], [ 29.772158101265823, -1.47206724137931 ], [ 29.772427594936708, -1.47206724137931 ], [ 29.772427594936708, -1.47179775862069 ], [ 29.772158101265823, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27812, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.47179775862069 ], [ 29.772427594936708, -1.47206724137931 ], [ 29.772697088607593, -1.47206724137931 ], [ 29.772697088607593, -1.47179775862069 ], [ 29.772427594936708, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27813, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.47179775862069 ], [ 29.772697088607593, -1.47206724137931 ], [ 29.772966582278482, -1.47206724137931 ], [ 29.772966582278482, -1.47179775862069 ], [ 29.772697088607593, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27814, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.47179775862069 ], [ 29.772966582278482, -1.47206724137931 ], [ 29.773236075949367, -1.47206724137931 ], [ 29.773236075949367, -1.47179775862069 ], [ 29.772966582278482, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27815, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.47179775862069 ], [ 29.773505569620252, -1.47206724137931 ], [ 29.77377506329114, -1.47206724137931 ], [ 29.77377506329114, -1.47179775862069 ], [ 29.773505569620252, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27816, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.47179775862069 ], [ 29.77377506329114, -1.47206724137931 ], [ 29.774044556962025, -1.47206724137931 ], [ 29.774044556962025, -1.47179775862069 ], [ 29.77377506329114, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27817, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.47179775862069 ], [ 29.774044556962025, -1.47206724137931 ], [ 29.77431405063291, -1.47206724137931 ], [ 29.77431405063291, -1.47179775862069 ], [ 29.774044556962025, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27818, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.47179775862069 ], [ 29.77431405063291, -1.472336724137931 ], [ 29.774853037974683, -1.472336724137931 ], [ 29.774853037974683, -1.47179775862069 ], [ 29.77431405063291, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27819, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.47179775862069 ], [ 29.774853037974683, -1.47206724137931 ], [ 29.775122531645568, -1.47206724137931 ], [ 29.775122531645568, -1.47179775862069 ], [ 29.774853037974683, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27820, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.47179775862069 ], [ 29.775122531645568, -1.47206724137931 ], [ 29.775392025316457, -1.47206724137931 ], [ 29.775392025316457, -1.47179775862069 ], [ 29.775122531645568, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27821, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.47179775862069 ], [ 29.775392025316457, -1.47206724137931 ], [ 29.775661518987341, -1.47206724137931 ], [ 29.775661518987341, -1.47179775862069 ], [ 29.775392025316457, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27822, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.47179775862069 ], [ 29.775661518987341, -1.47206724137931 ], [ 29.775931012658226, -1.47206724137931 ], [ 29.775931012658226, -1.47179775862069 ], [ 29.775661518987341, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27823, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.47179775862069 ], [ 29.776200506329115, -1.47206724137931 ], [ 29.77647, -1.47206724137931 ], [ 29.77647, -1.47179775862069 ], [ 29.776200506329115, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27824, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.47179775862069 ], [ 29.77647, -1.47206724137931 ], [ 29.776739493670885, -1.47206724137931 ], [ 29.776739493670885, -1.47179775862069 ], [ 29.77647, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27825, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.47179775862069 ], [ 29.776739493670885, -1.47206724137931 ], [ 29.777008987341773, -1.47206724137931 ], [ 29.777008987341773, -1.47179775862069 ], [ 29.776739493670885, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27826, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.47179775862069 ], [ 29.777008987341773, -1.472336724137931 ], [ 29.777278481012658, -1.472336724137931 ], [ 29.777278481012658, -1.47179775862069 ], [ 29.777008987341773, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27827, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.47179775862069 ], [ 29.777278481012658, -1.47206724137931 ], [ 29.777547974683543, -1.47206724137931 ], [ 29.777547974683543, -1.47179775862069 ], [ 29.777278481012658, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27828, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.47179775862069 ], [ 29.777547974683543, -1.47206724137931 ], [ 29.777817468354431, -1.47206724137931 ], [ 29.777817468354431, -1.47179775862069 ], [ 29.777547974683543, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27829, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.47179775862069 ], [ 29.778086962025316, -1.47206724137931 ], [ 29.778356455696201, -1.47206724137931 ], [ 29.778356455696201, -1.47179775862069 ], [ 29.778086962025316, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27830, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.47179775862069 ], [ 29.778356455696201, -1.47206724137931 ], [ 29.77862594936709, -1.47206724137931 ], [ 29.77862594936709, -1.47179775862069 ], [ 29.778356455696201, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27831, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.47179775862069 ], [ 29.778895443037975, -1.472336724137931 ], [ 29.77916493670886, -1.472336724137931 ], [ 29.77916493670886, -1.47179775862069 ], [ 29.778895443037975, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27832, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.47179775862069 ], [ 29.77916493670886, -1.47206724137931 ], [ 29.779434430379748, -1.47206724137931 ], [ 29.779434430379748, -1.47179775862069 ], [ 29.77916493670886, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27833, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.47179775862069 ], [ 29.779703924050633, -1.472336724137931 ], [ 29.779973417721518, -1.472336724137931 ], [ 29.779973417721518, -1.47179775862069 ], [ 29.779703924050633, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27834, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.47179775862069 ], [ 29.779973417721518, -1.47206724137931 ], [ 29.780242911392406, -1.47206724137931 ], [ 29.780242911392406, -1.47179775862069 ], [ 29.779973417721518, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27835, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.47179775862069 ], [ 29.780512405063291, -1.47206724137931 ], [ 29.780781898734176, -1.47206724137931 ], [ 29.780781898734176, -1.47179775862069 ], [ 29.780512405063291, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27836, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.47179775862069 ], [ 29.781051392405065, -1.47206724137931 ], [ 29.78132088607595, -1.47206724137931 ], [ 29.78132088607595, -1.47179775862069 ], [ 29.781051392405065, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27837, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.47179775862069 ], [ 29.78132088607595, -1.47206724137931 ], [ 29.781590379746834, -1.47206724137931 ], [ 29.781590379746834, -1.47179775862069 ], [ 29.78132088607595, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27838, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.47179775862069 ], [ 29.781590379746834, -1.472336724137931 ], [ 29.781859873417723, -1.472336724137931 ], [ 29.781859873417723, -1.47179775862069 ], [ 29.781590379746834, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27839, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.47179775862069 ], [ 29.781859873417723, -1.47206724137931 ], [ 29.782129367088608, -1.47206724137931 ], [ 29.782129367088608, -1.47179775862069 ], [ 29.781859873417723, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27840, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.47179775862069 ], [ 29.782129367088608, -1.47206724137931 ], [ 29.782398860759493, -1.47206724137931 ], [ 29.782398860759493, -1.47179775862069 ], [ 29.782129367088608, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27841, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.47179775862069 ], [ 29.782398860759493, -1.47206724137931 ], [ 29.782668354430381, -1.47206724137931 ], [ 29.782668354430381, -1.47179775862069 ], [ 29.782398860759493, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27842, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.47179775862069 ], [ 29.782937848101266, -1.47206724137931 ], [ 29.783207341772151, -1.47206724137931 ], [ 29.783207341772151, -1.47179775862069 ], [ 29.782937848101266, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27843, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.47179775862069 ], [ 29.783207341772151, -1.47206724137931 ], [ 29.783476835443039, -1.47206724137931 ], [ 29.783476835443039, -1.47179775862069 ], [ 29.783207341772151, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27844, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.47179775862069 ], [ 29.783476835443039, -1.47206724137931 ], [ 29.784015822784809, -1.47206724137931 ], [ 29.784015822784809, -1.47179775862069 ], [ 29.783476835443039, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27845, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.47179775862069 ], [ 29.784015822784809, -1.47206724137931 ], [ 29.784285316455698, -1.47206724137931 ], [ 29.784285316455698, -1.47179775862069 ], [ 29.784015822784809, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27846, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.47179775862069 ], [ 29.784285316455698, -1.47206724137931 ], [ 29.784554810126583, -1.47206724137931 ], [ 29.784554810126583, -1.47179775862069 ], [ 29.784285316455698, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27847, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.47179775862069 ], [ 29.784554810126583, -1.47206724137931 ], [ 29.784824303797468, -1.47206724137931 ], [ 29.784824303797468, -1.47179775862069 ], [ 29.784554810126583, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27848, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.47179775862069 ], [ 29.784824303797468, -1.47206724137931 ], [ 29.785093797468356, -1.47206724137931 ], [ 29.785093797468356, -1.47179775862069 ], [ 29.784824303797468, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27849, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.47179775862069 ], [ 29.785093797468356, -1.47206724137931 ], [ 29.785363291139241, -1.47206724137931 ], [ 29.785363291139241, -1.47179775862069 ], [ 29.785093797468356, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27850, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.47179775862069 ], [ 29.785363291139241, -1.472336724137931 ], [ 29.785632784810126, -1.472336724137931 ], [ 29.785632784810126, -1.47179775862069 ], [ 29.785363291139241, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27851, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.47179775862069 ], [ 29.785632784810126, -1.47206724137931 ], [ 29.785902278481014, -1.47206724137931 ], [ 29.785902278481014, -1.47179775862069 ], [ 29.785632784810126, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27852, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.47179775862069 ], [ 29.785902278481014, -1.47206724137931 ], [ 29.786171772151899, -1.47206724137931 ], [ 29.786171772151899, -1.47179775862069 ], [ 29.785902278481014, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27853, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.47179775862069 ], [ 29.786171772151899, -1.472606206896552 ], [ 29.786441265822784, -1.472606206896552 ], [ 29.786441265822784, -1.47179775862069 ], [ 29.786171772151899, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27854, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.47179775862069 ], [ 29.786441265822784, -1.472875689655172 ], [ 29.786710759493673, -1.472875689655172 ], [ 29.786710759493673, -1.47179775862069 ], [ 29.786441265822784, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27855, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.47179775862069 ], [ 29.786710759493673, -1.47206724137931 ], [ 29.786980253164558, -1.47206724137931 ], [ 29.786980253164558, -1.47179775862069 ], [ 29.786710759493673, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27856, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.47179775862069 ], [ 29.786980253164558, -1.472606206896552 ], [ 29.787249746835442, -1.472606206896552 ], [ 29.787249746835442, -1.47179775862069 ], [ 29.786980253164558, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27857, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.47179775862069 ], [ 29.787249746835442, -1.472875689655172 ], [ 29.787519240506327, -1.472875689655172 ], [ 29.787519240506327, -1.47179775862069 ], [ 29.787249746835442, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27858, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.47179775862069 ], [ 29.787788734177216, -1.472606206896552 ], [ 29.788058227848101, -1.472606206896552 ], [ 29.788058227848101, -1.47179775862069 ], [ 29.787788734177216, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27859, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.47179775862069 ], [ 29.788058227848101, -1.472875689655172 ], [ 29.788327721518986, -1.472875689655172 ], [ 29.788327721518986, -1.47179775862069 ], [ 29.788058227848101, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27860, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.47179775862069 ], [ 29.799646455696202, -1.47206724137931 ], [ 29.79991594936709, -1.47206724137931 ], [ 29.79991594936709, -1.47179775862069 ], [ 29.799646455696202, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27861, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.47179775862069 ], [ 29.800993924050633, -1.47206724137931 ], [ 29.801263417721518, -1.47206724137931 ], [ 29.801263417721518, -1.47179775862069 ], [ 29.800993924050633, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27862, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.47179775862069 ], [ 29.801263417721518, -1.47206724137931 ], [ 29.801532911392407, -1.47206724137931 ], [ 29.801532911392407, -1.47179775862069 ], [ 29.801263417721518, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27863, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.47179775862069 ], [ 29.801532911392407, -1.472336724137931 ], [ 29.802071898734177, -1.472336724137931 ], [ 29.802071898734177, -1.47179775862069 ], [ 29.801532911392407, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27864, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.47179775862069 ], [ 29.802071898734177, -1.47206724137931 ], [ 29.802341392405065, -1.47206724137931 ], [ 29.802341392405065, -1.47179775862069 ], [ 29.802071898734177, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27865, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.47179775862069 ], [ 29.802341392405065, -1.47206724137931 ], [ 29.80261088607595, -1.47206724137931 ], [ 29.80261088607595, -1.47179775862069 ], [ 29.802341392405065, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27866, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.47179775862069 ], [ 29.80261088607595, -1.47206724137931 ], [ 29.802880379746835, -1.47206724137931 ], [ 29.802880379746835, -1.47179775862069 ], [ 29.80261088607595, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27867, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757605443037974, -1.47206724137931 ], [ 29.757605443037974, -1.473145172413793 ], [ 29.757874936708859, -1.473145172413793 ], [ 29.757874936708859, -1.47206724137931 ], [ 29.757605443037974, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27868, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.47206724137931 ], [ 29.757874936708859, -1.472875689655172 ], [ 29.758144430379748, -1.472875689655172 ], [ 29.758144430379748, -1.47206724137931 ], [ 29.757874936708859, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27869, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.47206724137931 ], [ 29.758144430379748, -1.472875689655172 ], [ 29.758413924050632, -1.472875689655172 ], [ 29.758413924050632, -1.47206724137931 ], [ 29.758144430379748, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27870, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.47206724137931 ], [ 29.758952911392406, -1.472606206896552 ], [ 29.759222405063291, -1.472606206896552 ], [ 29.759222405063291, -1.47206724137931 ], [ 29.758952911392406, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27871, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.47206724137931 ], [ 29.759761392405064, -1.472336724137931 ], [ 29.760030886075949, -1.472336724137931 ], [ 29.760030886075949, -1.47206724137931 ], [ 29.759761392405064, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27872, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.47206724137931 ], [ 29.760030886075949, -1.472336724137931 ], [ 29.760300379746834, -1.472336724137931 ], [ 29.760300379746834, -1.47206724137931 ], [ 29.760030886075949, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27873, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.47206724137931 ], [ 29.760300379746834, -1.472336724137931 ], [ 29.760569873417722, -1.472336724137931 ], [ 29.760569873417722, -1.47206724137931 ], [ 29.760300379746834, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27874, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.47206724137931 ], [ 29.760569873417722, -1.472336724137931 ], [ 29.760839367088607, -1.472336724137931 ], [ 29.760839367088607, -1.47206724137931 ], [ 29.760569873417722, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27875, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.47206724137931 ], [ 29.760839367088607, -1.472336724137931 ], [ 29.761108860759492, -1.472336724137931 ], [ 29.761108860759492, -1.47206724137931 ], [ 29.760839367088607, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27876, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.47206724137931 ], [ 29.761108860759492, -1.472336724137931 ], [ 29.761378354430381, -1.472336724137931 ], [ 29.761378354430381, -1.47206724137931 ], [ 29.761108860759492, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27877, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.47206724137931 ], [ 29.761378354430381, -1.472606206896552 ], [ 29.761647848101266, -1.472606206896552 ], [ 29.761647848101266, -1.47206724137931 ], [ 29.761378354430381, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27878, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.47206724137931 ], [ 29.761647848101266, -1.472336724137931 ], [ 29.761917341772151, -1.472336724137931 ], [ 29.761917341772151, -1.47206724137931 ], [ 29.761647848101266, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27879, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.47206724137931 ], [ 29.761917341772151, -1.472336724137931 ], [ 29.762186835443039, -1.472336724137931 ], [ 29.762186835443039, -1.47206724137931 ], [ 29.761917341772151, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27880, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.47206724137931 ], [ 29.762995316455697, -1.473145172413793 ], [ 29.763264810126582, -1.473145172413793 ], [ 29.763264810126582, -1.47206724137931 ], [ 29.762995316455697, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27881, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.47206724137931 ], [ 29.763803797468356, -1.473145172413793 ], [ 29.76407329113924, -1.473145172413793 ], [ 29.76407329113924, -1.47206724137931 ], [ 29.763803797468356, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27882, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.47206724137931 ], [ 29.764342784810125, -1.473145172413793 ], [ 29.764612278481014, -1.473145172413793 ], [ 29.764612278481014, -1.47206724137931 ], [ 29.764342784810125, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27883, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.47206724137931 ], [ 29.764612278481014, -1.473145172413793 ], [ 29.764881772151899, -1.473145172413793 ], [ 29.764881772151899, -1.47206724137931 ], [ 29.764612278481014, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27884, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.47206724137931 ], [ 29.765151265822784, -1.473145172413793 ], [ 29.765420759493672, -1.473145172413793 ], [ 29.765420759493672, -1.47206724137931 ], [ 29.765151265822784, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27885, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.47206724137931 ], [ 29.765420759493672, -1.473145172413793 ], [ 29.765959746835442, -1.473145172413793 ], [ 29.765959746835442, -1.47206724137931 ], [ 29.765420759493672, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27886, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.47206724137931 ], [ 29.765959746835442, -1.472336724137931 ], [ 29.766229240506327, -1.472336724137931 ], [ 29.766229240506327, -1.47206724137931 ], [ 29.765959746835442, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27887, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.47206724137931 ], [ 29.766229240506327, -1.472336724137931 ], [ 29.766498734177215, -1.472336724137931 ], [ 29.766498734177215, -1.47206724137931 ], [ 29.766229240506327, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27888, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.47206724137931 ], [ 29.766498734177215, -1.472336724137931 ], [ 29.7667682278481, -1.472336724137931 ], [ 29.7667682278481, -1.47206724137931 ], [ 29.766498734177215, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27889, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.47206724137931 ], [ 29.770541139240507, -1.473145172413793 ], [ 29.770810632911392, -1.473145172413793 ], [ 29.770810632911392, -1.47206724137931 ], [ 29.770541139240507, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27890, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.47206724137931 ], [ 29.770810632911392, -1.473145172413793 ], [ 29.771080126582277, -1.473145172413793 ], [ 29.771080126582277, -1.47206724137931 ], [ 29.770810632911392, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27891, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.47206724137931 ], [ 29.771080126582277, -1.472336724137931 ], [ 29.771349620253165, -1.472336724137931 ], [ 29.771349620253165, -1.47206724137931 ], [ 29.771080126582277, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27892, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.47206724137931 ], [ 29.771349620253165, -1.472336724137931 ], [ 29.77161911392405, -1.472336724137931 ], [ 29.77161911392405, -1.47206724137931 ], [ 29.771349620253165, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27893, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.47206724137931 ], [ 29.77161911392405, -1.472336724137931 ], [ 29.771888607594935, -1.472336724137931 ], [ 29.771888607594935, -1.47206724137931 ], [ 29.77161911392405, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27894, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.47206724137931 ], [ 29.771888607594935, -1.472336724137931 ], [ 29.772158101265823, -1.472336724137931 ], [ 29.772158101265823, -1.47206724137931 ], [ 29.771888607594935, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27895, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.47206724137931 ], [ 29.772158101265823, -1.472336724137931 ], [ 29.772427594936708, -1.472336724137931 ], [ 29.772427594936708, -1.47206724137931 ], [ 29.772158101265823, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27896, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.47206724137931 ], [ 29.772427594936708, -1.472336724137931 ], [ 29.772697088607593, -1.472336724137931 ], [ 29.772697088607593, -1.47206724137931 ], [ 29.772427594936708, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27897, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.47206724137931 ], [ 29.772697088607593, -1.472336724137931 ], [ 29.772966582278482, -1.472336724137931 ], [ 29.772966582278482, -1.47206724137931 ], [ 29.772697088607593, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27898, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.47206724137931 ], [ 29.772966582278482, -1.472336724137931 ], [ 29.773236075949367, -1.472336724137931 ], [ 29.773236075949367, -1.47206724137931 ], [ 29.772966582278482, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27899, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.47206724137931 ], [ 29.773236075949367, -1.472336724137931 ], [ 29.773505569620252, -1.472336724137931 ], [ 29.773505569620252, -1.47206724137931 ], [ 29.773236075949367, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27900, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.47206724137931 ], [ 29.773505569620252, -1.472336724137931 ], [ 29.77377506329114, -1.472336724137931 ], [ 29.77377506329114, -1.47206724137931 ], [ 29.773505569620252, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27901, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.47206724137931 ], [ 29.77377506329114, -1.472336724137931 ], [ 29.774044556962025, -1.472336724137931 ], [ 29.774044556962025, -1.47206724137931 ], [ 29.77377506329114, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27902, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.47206724137931 ], [ 29.774044556962025, -1.472336724137931 ], [ 29.77431405063291, -1.472336724137931 ], [ 29.77431405063291, -1.47206724137931 ], [ 29.774044556962025, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27903, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.47206724137931 ], [ 29.774853037974683, -1.472336724137931 ], [ 29.775122531645568, -1.472336724137931 ], [ 29.775122531645568, -1.47206724137931 ], [ 29.774853037974683, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27904, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.47206724137931 ], [ 29.775122531645568, -1.472336724137931 ], [ 29.775392025316457, -1.472336724137931 ], [ 29.775392025316457, -1.47206724137931 ], [ 29.775122531645568, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27905, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.47206724137931 ], [ 29.775392025316457, -1.472336724137931 ], [ 29.775661518987341, -1.472336724137931 ], [ 29.775661518987341, -1.47206724137931 ], [ 29.775392025316457, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27906, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.47206724137931 ], [ 29.775661518987341, -1.472336724137931 ], [ 29.775931012658226, -1.472336724137931 ], [ 29.775931012658226, -1.47206724137931 ], [ 29.775661518987341, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27907, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.47206724137931 ], [ 29.775931012658226, -1.472336724137931 ], [ 29.776200506329115, -1.472336724137931 ], [ 29.776200506329115, -1.47206724137931 ], [ 29.775931012658226, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27908, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.47206724137931 ], [ 29.776200506329115, -1.472336724137931 ], [ 29.77647, -1.472336724137931 ], [ 29.77647, -1.47206724137931 ], [ 29.776200506329115, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27909, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.47206724137931 ], [ 29.77647, -1.472336724137931 ], [ 29.776739493670885, -1.472336724137931 ], [ 29.776739493670885, -1.47206724137931 ], [ 29.77647, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27910, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.47206724137931 ], [ 29.776739493670885, -1.472336724137931 ], [ 29.777008987341773, -1.472336724137931 ], [ 29.777008987341773, -1.47206724137931 ], [ 29.776739493670885, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27911, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.47206724137931 ], [ 29.777278481012658, -1.472336724137931 ], [ 29.777547974683543, -1.472336724137931 ], [ 29.777547974683543, -1.47206724137931 ], [ 29.777278481012658, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27912, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.47206724137931 ], [ 29.777547974683543, -1.472336724137931 ], [ 29.777817468354431, -1.472336724137931 ], [ 29.777817468354431, -1.47206724137931 ], [ 29.777547974683543, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27913, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.47206724137931 ], [ 29.777817468354431, -1.472336724137931 ], [ 29.778086962025316, -1.472336724137931 ], [ 29.778086962025316, -1.47206724137931 ], [ 29.777817468354431, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27914, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.47206724137931 ], [ 29.778086962025316, -1.472606206896552 ], [ 29.778356455696201, -1.472606206896552 ], [ 29.778356455696201, -1.47206724137931 ], [ 29.778086962025316, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27915, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.47206724137931 ], [ 29.778356455696201, -1.472336724137931 ], [ 29.77862594936709, -1.472336724137931 ], [ 29.77862594936709, -1.47206724137931 ], [ 29.778356455696201, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27916, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.47206724137931 ], [ 29.77862594936709, -1.472336724137931 ], [ 29.778895443037975, -1.472336724137931 ], [ 29.778895443037975, -1.47206724137931 ], [ 29.77862594936709, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27917, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.47206724137931 ], [ 29.77916493670886, -1.472336724137931 ], [ 29.779434430379748, -1.472336724137931 ], [ 29.779434430379748, -1.47206724137931 ], [ 29.77916493670886, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27918, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.47206724137931 ], [ 29.779434430379748, -1.472336724137931 ], [ 29.779703924050633, -1.472336724137931 ], [ 29.779703924050633, -1.47206724137931 ], [ 29.779434430379748, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27919, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.47206724137931 ], [ 29.779973417721518, -1.472606206896552 ], [ 29.780242911392406, -1.472606206896552 ], [ 29.780242911392406, -1.47206724137931 ], [ 29.779973417721518, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27920, "El": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.47206724137931 ], [ 29.780242911392406, -1.472336724137931 ], [ 29.780512405063291, -1.472336724137931 ], [ 29.780512405063291, -1.47206724137931 ], [ 29.780242911392406, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27921, "El": 120 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.47206724137931 ], [ 29.780512405063291, -1.472336724137931 ], [ 29.780781898734176, -1.472336724137931 ], [ 29.780781898734176, -1.47206724137931 ], [ 29.780512405063291, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27922, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.47206724137931 ], [ 29.780781898734176, -1.472336724137931 ], [ 29.781051392405065, -1.472336724137931 ], [ 29.781051392405065, -1.47206724137931 ], [ 29.780781898734176, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27923, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.47206724137931 ], [ 29.781051392405065, -1.472606206896552 ], [ 29.78132088607595, -1.472606206896552 ], [ 29.78132088607595, -1.47206724137931 ], [ 29.781051392405065, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27924, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.47206724137931 ], [ 29.78132088607595, -1.472336724137931 ], [ 29.781590379746834, -1.472336724137931 ], [ 29.781590379746834, -1.47206724137931 ], [ 29.78132088607595, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27925, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.47206724137931 ], [ 29.781859873417723, -1.472336724137931 ], [ 29.782129367088608, -1.472336724137931 ], [ 29.782129367088608, -1.47206724137931 ], [ 29.781859873417723, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27926, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.47206724137931 ], [ 29.782129367088608, -1.472336724137931 ], [ 29.782398860759493, -1.472336724137931 ], [ 29.782398860759493, -1.47206724137931 ], [ 29.782129367088608, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27927, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.47206724137931 ], [ 29.782398860759493, -1.472336724137931 ], [ 29.782668354430381, -1.472336724137931 ], [ 29.782668354430381, -1.47206724137931 ], [ 29.782398860759493, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27928, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.47206724137931 ], [ 29.782668354430381, -1.472336724137931 ], [ 29.782937848101266, -1.472336724137931 ], [ 29.782937848101266, -1.47206724137931 ], [ 29.782668354430381, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27929, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.47206724137931 ], [ 29.782937848101266, -1.472336724137931 ], [ 29.783207341772151, -1.472336724137931 ], [ 29.783207341772151, -1.47206724137931 ], [ 29.782937848101266, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27930, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.47206724137931 ], [ 29.783207341772151, -1.472336724137931 ], [ 29.783476835443039, -1.472336724137931 ], [ 29.783476835443039, -1.47206724137931 ], [ 29.783207341772151, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27931, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.47206724137931 ], [ 29.783476835443039, -1.472336724137931 ], [ 29.784015822784809, -1.472336724137931 ], [ 29.784015822784809, -1.47206724137931 ], [ 29.783476835443039, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27932, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.47206724137931 ], [ 29.784015822784809, -1.472336724137931 ], [ 29.784285316455698, -1.472336724137931 ], [ 29.784285316455698, -1.47206724137931 ], [ 29.784015822784809, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27933, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.47206724137931 ], [ 29.784285316455698, -1.472875689655172 ], [ 29.784554810126583, -1.472875689655172 ], [ 29.784554810126583, -1.47206724137931 ], [ 29.784285316455698, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27934, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.47206724137931 ], [ 29.784554810126583, -1.472336724137931 ], [ 29.784824303797468, -1.472336724137931 ], [ 29.784824303797468, -1.47206724137931 ], [ 29.784554810126583, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27935, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.47206724137931 ], [ 29.784824303797468, -1.472606206896552 ], [ 29.785093797468356, -1.472606206896552 ], [ 29.785093797468356, -1.47206724137931 ], [ 29.784824303797468, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27936, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.47206724137931 ], [ 29.785093797468356, -1.472875689655172 ], [ 29.785363291139241, -1.472875689655172 ], [ 29.785363291139241, -1.47206724137931 ], [ 29.785093797468356, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27937, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.47206724137931 ], [ 29.785632784810126, -1.472606206896552 ], [ 29.785902278481014, -1.472606206896552 ], [ 29.785902278481014, -1.47206724137931 ], [ 29.785632784810126, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27938, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.47206724137931 ], [ 29.785902278481014, -1.472875689655172 ], [ 29.786171772151899, -1.472875689655172 ], [ 29.786171772151899, -1.47206724137931 ], [ 29.785902278481014, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27939, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.47206724137931 ], [ 29.786710759493673, -1.472875689655172 ], [ 29.786980253164558, -1.472875689655172 ], [ 29.786980253164558, -1.47206724137931 ], [ 29.786710759493673, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27940, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.47206724137931 ], [ 29.80261088607595, -1.472336724137931 ], [ 29.802880379746835, -1.472336724137931 ], [ 29.802880379746835, -1.47206724137931 ], [ 29.80261088607595, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27941, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.47206724137931 ], [ 29.802880379746835, -1.472336724137931 ], [ 29.803149873417723, -1.472336724137931 ], [ 29.803149873417723, -1.47206724137931 ], [ 29.802880379746835, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27942, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.47206724137931 ], [ 29.803149873417723, -1.472336724137931 ], [ 29.803419367088608, -1.472336724137931 ], [ 29.803419367088608, -1.47206724137931 ], [ 29.803149873417723, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27943, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.472336724137931 ], [ 29.759222405063291, -1.472606206896552 ], [ 29.759491898734176, -1.472606206896552 ], [ 29.759491898734176, -1.472336724137931 ], [ 29.759222405063291, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27944, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.472336724137931 ], [ 29.759491898734176, -1.472606206896552 ], [ 29.759761392405064, -1.472606206896552 ], [ 29.759761392405064, -1.472336724137931 ], [ 29.759491898734176, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27945, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.472336724137931 ], [ 29.759761392405064, -1.472606206896552 ], [ 29.760030886075949, -1.472606206896552 ], [ 29.760030886075949, -1.472336724137931 ], [ 29.759761392405064, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27946, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.472336724137931 ], [ 29.760030886075949, -1.472606206896552 ], [ 29.760300379746834, -1.472606206896552 ], [ 29.760300379746834, -1.472336724137931 ], [ 29.760030886075949, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27947, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.472336724137931 ], [ 29.760300379746834, -1.472875689655172 ], [ 29.760569873417722, -1.472875689655172 ], [ 29.760569873417722, -1.472336724137931 ], [ 29.760300379746834, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27948, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.472336724137931 ], [ 29.760569873417722, -1.472606206896552 ], [ 29.760839367088607, -1.472606206896552 ], [ 29.760839367088607, -1.472336724137931 ], [ 29.760569873417722, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27949, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.472336724137931 ], [ 29.760839367088607, -1.472606206896552 ], [ 29.761108860759492, -1.472606206896552 ], [ 29.761108860759492, -1.472336724137931 ], [ 29.760839367088607, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27950, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.472336724137931 ], [ 29.761108860759492, -1.472875689655172 ], [ 29.761378354430381, -1.472875689655172 ], [ 29.761378354430381, -1.472336724137931 ], [ 29.761108860759492, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27951, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.472336724137931 ], [ 29.761647848101266, -1.472606206896552 ], [ 29.761917341772151, -1.472606206896552 ], [ 29.761917341772151, -1.472336724137931 ], [ 29.761647848101266, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27952, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.472336724137931 ], [ 29.761917341772151, -1.472875689655172 ], [ 29.762186835443039, -1.472875689655172 ], [ 29.762186835443039, -1.472336724137931 ], [ 29.761917341772151, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27953, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.472336724137931 ], [ 29.763534303797467, -1.472875689655172 ], [ 29.763803797468356, -1.472875689655172 ], [ 29.763803797468356, -1.472336724137931 ], [ 29.763534303797467, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27954, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.472336724137931 ], [ 29.764881772151899, -1.472875689655172 ], [ 29.765151265822784, -1.472875689655172 ], [ 29.765151265822784, -1.472336724137931 ], [ 29.764881772151899, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27955, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.472336724137931 ], [ 29.765959746835442, -1.472875689655172 ], [ 29.766229240506327, -1.472875689655172 ], [ 29.766229240506327, -1.472336724137931 ], [ 29.765959746835442, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27956, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.472336724137931 ], [ 29.766229240506327, -1.472875689655172 ], [ 29.766498734177215, -1.472875689655172 ], [ 29.766498734177215, -1.472336724137931 ], [ 29.766229240506327, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27957, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.472336724137931 ], [ 29.766498734177215, -1.472875689655172 ], [ 29.7667682278481, -1.472875689655172 ], [ 29.7667682278481, -1.472336724137931 ], [ 29.766498734177215, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27958, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.472336724137931 ], [ 29.771080126582277, -1.473953620689655 ], [ 29.771349620253165, -1.473953620689655 ], [ 29.771349620253165, -1.472336724137931 ], [ 29.771080126582277, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27959, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.472336724137931 ], [ 29.771349620253165, -1.473953620689655 ], [ 29.77161911392405, -1.473953620689655 ], [ 29.77161911392405, -1.472336724137931 ], [ 29.771349620253165, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27960, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.472336724137931 ], [ 29.77161911392405, -1.473145172413793 ], [ 29.771888607594935, -1.473145172413793 ], [ 29.771888607594935, -1.472336724137931 ], [ 29.77161911392405, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27961, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.472336724137931 ], [ 29.771888607594935, -1.472606206896552 ], [ 29.772158101265823, -1.472606206896552 ], [ 29.772158101265823, -1.472336724137931 ], [ 29.771888607594935, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27962, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.472336724137931 ], [ 29.772158101265823, -1.472606206896552 ], [ 29.772427594936708, -1.472606206896552 ], [ 29.772427594936708, -1.472336724137931 ], [ 29.772158101265823, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27963, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.472336724137931 ], [ 29.772427594936708, -1.472606206896552 ], [ 29.772697088607593, -1.472606206896552 ], [ 29.772697088607593, -1.472336724137931 ], [ 29.772427594936708, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27964, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.472336724137931 ], [ 29.772697088607593, -1.472606206896552 ], [ 29.772966582278482, -1.472606206896552 ], [ 29.772966582278482, -1.472336724137931 ], [ 29.772697088607593, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27965, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.472336724137931 ], [ 29.772966582278482, -1.472606206896552 ], [ 29.773236075949367, -1.472606206896552 ], [ 29.773236075949367, -1.472336724137931 ], [ 29.772966582278482, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27966, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.472336724137931 ], [ 29.773236075949367, -1.472606206896552 ], [ 29.773505569620252, -1.472606206896552 ], [ 29.773505569620252, -1.472336724137931 ], [ 29.773236075949367, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27967, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.472336724137931 ], [ 29.773505569620252, -1.472606206896552 ], [ 29.77377506329114, -1.472606206896552 ], [ 29.77377506329114, -1.472336724137931 ], [ 29.773505569620252, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27968, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.472336724137931 ], [ 29.77377506329114, -1.472606206896552 ], [ 29.774044556962025, -1.472606206896552 ], [ 29.774044556962025, -1.472336724137931 ], [ 29.77377506329114, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27969, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.472336724137931 ], [ 29.774044556962025, -1.472606206896552 ], [ 29.77431405063291, -1.472606206896552 ], [ 29.77431405063291, -1.472336724137931 ], [ 29.774044556962025, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27970, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.472336724137931 ], [ 29.77431405063291, -1.472606206896552 ], [ 29.774853037974683, -1.472606206896552 ], [ 29.774853037974683, -1.472336724137931 ], [ 29.77431405063291, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27971, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.472336724137931 ], [ 29.774853037974683, -1.472606206896552 ], [ 29.775122531645568, -1.472606206896552 ], [ 29.775122531645568, -1.472336724137931 ], [ 29.774853037974683, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27972, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.472336724137931 ], [ 29.775122531645568, -1.472606206896552 ], [ 29.775392025316457, -1.472606206896552 ], [ 29.775392025316457, -1.472336724137931 ], [ 29.775122531645568, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27973, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.472336724137931 ], [ 29.775392025316457, -1.472606206896552 ], [ 29.775661518987341, -1.472606206896552 ], [ 29.775661518987341, -1.472336724137931 ], [ 29.775392025316457, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27974, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.472336724137931 ], [ 29.775661518987341, -1.472875689655172 ], [ 29.775931012658226, -1.472875689655172 ], [ 29.775931012658226, -1.472336724137931 ], [ 29.775661518987341, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27975, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.472336724137931 ], [ 29.775931012658226, -1.472606206896552 ], [ 29.776200506329115, -1.472606206896552 ], [ 29.776200506329115, -1.472336724137931 ], [ 29.775931012658226, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27976, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.472336724137931 ], [ 29.776200506329115, -1.472606206896552 ], [ 29.77647, -1.472606206896552 ], [ 29.77647, -1.472336724137931 ], [ 29.776200506329115, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27977, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.472336724137931 ], [ 29.77647, -1.472606206896552 ], [ 29.776739493670885, -1.472606206896552 ], [ 29.776739493670885, -1.472336724137931 ], [ 29.77647, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27978, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.472336724137931 ], [ 29.776739493670885, -1.472606206896552 ], [ 29.777008987341773, -1.472606206896552 ], [ 29.777008987341773, -1.472336724137931 ], [ 29.776739493670885, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27979, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.472336724137931 ], [ 29.777008987341773, -1.472606206896552 ], [ 29.777278481012658, -1.472606206896552 ], [ 29.777278481012658, -1.472336724137931 ], [ 29.777008987341773, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27980, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.472336724137931 ], [ 29.777278481012658, -1.472606206896552 ], [ 29.777547974683543, -1.472606206896552 ], [ 29.777547974683543, -1.472336724137931 ], [ 29.777278481012658, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27981, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.472336724137931 ], [ 29.777547974683543, -1.472606206896552 ], [ 29.777817468354431, -1.472606206896552 ], [ 29.777817468354431, -1.472336724137931 ], [ 29.777547974683543, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27982, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.472336724137931 ], [ 29.777817468354431, -1.472606206896552 ], [ 29.778086962025316, -1.472606206896552 ], [ 29.778086962025316, -1.472336724137931 ], [ 29.777817468354431, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27983, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.472336724137931 ], [ 29.778356455696201, -1.472606206896552 ], [ 29.77862594936709, -1.472606206896552 ], [ 29.77862594936709, -1.472336724137931 ], [ 29.778356455696201, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27984, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.472336724137931 ], [ 29.77862594936709, -1.472606206896552 ], [ 29.778895443037975, -1.472606206896552 ], [ 29.778895443037975, -1.472336724137931 ], [ 29.77862594936709, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27985, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.472336724137931 ], [ 29.778895443037975, -1.472606206896552 ], [ 29.77916493670886, -1.472606206896552 ], [ 29.77916493670886, -1.472336724137931 ], [ 29.778895443037975, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27986, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.472336724137931 ], [ 29.77916493670886, -1.472875689655172 ], [ 29.779434430379748, -1.472875689655172 ], [ 29.779434430379748, -1.472336724137931 ], [ 29.77916493670886, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27987, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.472336724137931 ], [ 29.779434430379748, -1.472606206896552 ], [ 29.779703924050633, -1.472606206896552 ], [ 29.779703924050633, -1.472336724137931 ], [ 29.779434430379748, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27988, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.472336724137931 ], [ 29.779703924050633, -1.472606206896552 ], [ 29.779973417721518, -1.472606206896552 ], [ 29.779973417721518, -1.472336724137931 ], [ 29.779703924050633, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27989, "El": 119 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.472336724137931 ], [ 29.780242911392406, -1.472606206896552 ], [ 29.780512405063291, -1.472606206896552 ], [ 29.780512405063291, -1.472336724137931 ], [ 29.780242911392406, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27990, "El": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.472336724137931 ], [ 29.780512405063291, -1.472606206896552 ], [ 29.780781898734176, -1.472606206896552 ], [ 29.780781898734176, -1.472336724137931 ], [ 29.780512405063291, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27991, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.472336724137931 ], [ 29.780781898734176, -1.472606206896552 ], [ 29.781051392405065, -1.472606206896552 ], [ 29.781051392405065, -1.472336724137931 ], [ 29.780781898734176, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27992, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.472336724137931 ], [ 29.78132088607595, -1.472606206896552 ], [ 29.781590379746834, -1.472606206896552 ], [ 29.781590379746834, -1.472336724137931 ], [ 29.78132088607595, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27993, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.472336724137931 ], [ 29.781590379746834, -1.472875689655172 ], [ 29.781859873417723, -1.472875689655172 ], [ 29.781859873417723, -1.472336724137931 ], [ 29.781590379746834, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27994, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.472336724137931 ], [ 29.781859873417723, -1.472606206896552 ], [ 29.782129367088608, -1.472606206896552 ], [ 29.782129367088608, -1.472336724137931 ], [ 29.781859873417723, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27995, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.472336724137931 ], [ 29.782129367088608, -1.472606206896552 ], [ 29.782398860759493, -1.472606206896552 ], [ 29.782398860759493, -1.472336724137931 ], [ 29.782129367088608, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27996, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.472336724137931 ], [ 29.782398860759493, -1.472606206896552 ], [ 29.782668354430381, -1.472606206896552 ], [ 29.782668354430381, -1.472336724137931 ], [ 29.782398860759493, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27997, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.472336724137931 ], [ 29.782668354430381, -1.472875689655172 ], [ 29.782937848101266, -1.472875689655172 ], [ 29.782937848101266, -1.472336724137931 ], [ 29.782668354430381, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27998, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.472336724137931 ], [ 29.782937848101266, -1.473145172413793 ], [ 29.783207341772151, -1.473145172413793 ], [ 29.783207341772151, -1.472336724137931 ], [ 29.782937848101266, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 27999, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.472336724137931 ], [ 29.783207341772151, -1.472606206896552 ], [ 29.783476835443039, -1.472606206896552 ], [ 29.783476835443039, -1.472336724137931 ], [ 29.783207341772151, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28000, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.472336724137931 ], [ 29.783476835443039, -1.472875689655172 ], [ 29.784015822784809, -1.472875689655172 ], [ 29.784015822784809, -1.472336724137931 ], [ 29.783476835443039, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28001, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.472336724137931 ], [ 29.784015822784809, -1.473145172413793 ], [ 29.784285316455698, -1.473145172413793 ], [ 29.784285316455698, -1.472336724137931 ], [ 29.784015822784809, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28002, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.472336724137931 ], [ 29.784554810126583, -1.473145172413793 ], [ 29.784824303797468, -1.473145172413793 ], [ 29.784824303797468, -1.472336724137931 ], [ 29.784554810126583, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28003, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.472336724137931 ], [ 29.785363291139241, -1.473145172413793 ], [ 29.785632784810126, -1.473145172413793 ], [ 29.785632784810126, -1.472336724137931 ], [ 29.785363291139241, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28004, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.472336724137931 ], [ 29.787519240506327, -1.472875689655172 ], [ 29.787788734177216, -1.472875689655172 ], [ 29.787788734177216, -1.472336724137931 ], [ 29.787519240506327, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28005, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.472336724137931 ], [ 29.788866708860759, -1.472875689655172 ], [ 29.789136202531644, -1.472875689655172 ], [ 29.789136202531644, -1.472336724137931 ], [ 29.788866708860759, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28006, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.472336724137931 ], [ 29.789675189873417, -1.472606206896552 ], [ 29.789944683544302, -1.472606206896552 ], [ 29.789944683544302, -1.472336724137931 ], [ 29.789675189873417, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28007, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.472336724137931 ], [ 29.789944683544302, -1.472606206896552 ], [ 29.790214177215191, -1.472606206896552 ], [ 29.790214177215191, -1.472336724137931 ], [ 29.789944683544302, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28008, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.472336724137931 ], [ 29.790214177215191, -1.472606206896552 ], [ 29.790483670886076, -1.472606206896552 ], [ 29.790483670886076, -1.472336724137931 ], [ 29.790214177215191, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28009, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.472336724137931 ], [ 29.790483670886076, -1.472875689655172 ], [ 29.790753164556961, -1.472875689655172 ], [ 29.790753164556961, -1.472336724137931 ], [ 29.790483670886076, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28010, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.472336724137931 ], [ 29.798298987341774, -1.472606206896552 ], [ 29.798568481012659, -1.472606206896552 ], [ 29.798568481012659, -1.472336724137931 ], [ 29.798298987341774, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28011, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.472336724137931 ], [ 29.798568481012659, -1.472606206896552 ], [ 29.798837974683543, -1.472606206896552 ], [ 29.798837974683543, -1.472336724137931 ], [ 29.798568481012659, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28012, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.472336724137931 ], [ 29.799107468354432, -1.472606206896552 ], [ 29.799376962025317, -1.472606206896552 ], [ 29.799376962025317, -1.472336724137931 ], [ 29.799107468354432, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28013, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.472336724137931 ], [ 29.799376962025317, -1.472606206896552 ], [ 29.799646455696202, -1.472606206896552 ], [ 29.799646455696202, -1.472336724137931 ], [ 29.799376962025317, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28014, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.472606206896552 ], [ 29.758413924050632, -1.472875689655172 ], [ 29.758683417721517, -1.472875689655172 ], [ 29.758683417721517, -1.472606206896552 ], [ 29.758413924050632, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28015, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.472606206896552 ], [ 29.758683417721517, -1.472875689655172 ], [ 29.758952911392406, -1.472875689655172 ], [ 29.758952911392406, -1.472606206896552 ], [ 29.758683417721517, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28016, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.472606206896552 ], [ 29.758952911392406, -1.472875689655172 ], [ 29.759222405063291, -1.472875689655172 ], [ 29.759222405063291, -1.472606206896552 ], [ 29.758952911392406, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28017, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.472606206896552 ], [ 29.759222405063291, -1.473145172413793 ], [ 29.759491898734176, -1.473145172413793 ], [ 29.759491898734176, -1.472606206896552 ], [ 29.759222405063291, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28018, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.472606206896552 ], [ 29.759491898734176, -1.472875689655172 ], [ 29.759761392405064, -1.472875689655172 ], [ 29.759761392405064, -1.472606206896552 ], [ 29.759491898734176, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28019, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.472606206896552 ], [ 29.759761392405064, -1.472875689655172 ], [ 29.760030886075949, -1.472875689655172 ], [ 29.760030886075949, -1.472606206896552 ], [ 29.759761392405064, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28020, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.472606206896552 ], [ 29.760569873417722, -1.473145172413793 ], [ 29.760839367088607, -1.473145172413793 ], [ 29.760839367088607, -1.472606206896552 ], [ 29.760569873417722, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28021, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.472606206896552 ], [ 29.771888607594935, -1.473684137931035 ], [ 29.772158101265823, -1.473684137931035 ], [ 29.772158101265823, -1.472606206896552 ], [ 29.771888607594935, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28022, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.472606206896552 ], [ 29.772158101265823, -1.473684137931035 ], [ 29.772427594936708, -1.473684137931035 ], [ 29.772427594936708, -1.472606206896552 ], [ 29.772158101265823, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28023, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.472606206896552 ], [ 29.772427594936708, -1.472875689655172 ], [ 29.772697088607593, -1.472875689655172 ], [ 29.772697088607593, -1.472606206896552 ], [ 29.772427594936708, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28024, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.472606206896552 ], [ 29.772697088607593, -1.472875689655172 ], [ 29.772966582278482, -1.472875689655172 ], [ 29.772966582278482, -1.472606206896552 ], [ 29.772697088607593, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28025, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.472606206896552 ], [ 29.772966582278482, -1.473145172413793 ], [ 29.773236075949367, -1.473145172413793 ], [ 29.773236075949367, -1.472606206896552 ], [ 29.772966582278482, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28026, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.472606206896552 ], [ 29.773236075949367, -1.472875689655172 ], [ 29.773505569620252, -1.472875689655172 ], [ 29.773505569620252, -1.472606206896552 ], [ 29.773236075949367, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28027, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.472606206896552 ], [ 29.773505569620252, -1.472875689655172 ], [ 29.77377506329114, -1.472875689655172 ], [ 29.77377506329114, -1.472606206896552 ], [ 29.773505569620252, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28028, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.472606206896552 ], [ 29.77377506329114, -1.472875689655172 ], [ 29.774044556962025, -1.472875689655172 ], [ 29.774044556962025, -1.472606206896552 ], [ 29.77377506329114, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28029, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.472606206896552 ], [ 29.774044556962025, -1.472875689655172 ], [ 29.77431405063291, -1.472875689655172 ], [ 29.77431405063291, -1.472606206896552 ], [ 29.774044556962025, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28030, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.472606206896552 ], [ 29.77431405063291, -1.472875689655172 ], [ 29.774853037974683, -1.472875689655172 ], [ 29.774853037974683, -1.472606206896552 ], [ 29.77431405063291, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28031, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.472606206896552 ], [ 29.774853037974683, -1.472875689655172 ], [ 29.775122531645568, -1.472875689655172 ], [ 29.775122531645568, -1.472606206896552 ], [ 29.774853037974683, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28032, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.472606206896552 ], [ 29.775122531645568, -1.472875689655172 ], [ 29.775392025316457, -1.472875689655172 ], [ 29.775392025316457, -1.472606206896552 ], [ 29.775122531645568, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28033, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.472606206896552 ], [ 29.775392025316457, -1.472875689655172 ], [ 29.775661518987341, -1.472875689655172 ], [ 29.775661518987341, -1.472606206896552 ], [ 29.775392025316457, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28034, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.472606206896552 ], [ 29.775931012658226, -1.472875689655172 ], [ 29.776200506329115, -1.472875689655172 ], [ 29.776200506329115, -1.472606206896552 ], [ 29.775931012658226, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28035, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.472606206896552 ], [ 29.776200506329115, -1.472875689655172 ], [ 29.77647, -1.472875689655172 ], [ 29.77647, -1.472606206896552 ], [ 29.776200506329115, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28036, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.472606206896552 ], [ 29.77647, -1.472875689655172 ], [ 29.776739493670885, -1.472875689655172 ], [ 29.776739493670885, -1.472606206896552 ], [ 29.77647, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28037, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.472606206896552 ], [ 29.776739493670885, -1.473684137931035 ], [ 29.777008987341773, -1.473684137931035 ], [ 29.777008987341773, -1.472606206896552 ], [ 29.776739493670885, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28038, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.472606206896552 ], [ 29.777008987341773, -1.472875689655172 ], [ 29.777278481012658, -1.472875689655172 ], [ 29.777278481012658, -1.472606206896552 ], [ 29.777008987341773, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28039, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.472606206896552 ], [ 29.777278481012658, -1.472875689655172 ], [ 29.777547974683543, -1.472875689655172 ], [ 29.777547974683543, -1.472606206896552 ], [ 29.777278481012658, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28040, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.472606206896552 ], [ 29.777547974683543, -1.472875689655172 ], [ 29.777817468354431, -1.472875689655172 ], [ 29.777817468354431, -1.472606206896552 ], [ 29.777547974683543, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28041, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.472606206896552 ], [ 29.777817468354431, -1.472875689655172 ], [ 29.778086962025316, -1.472875689655172 ], [ 29.778086962025316, -1.472606206896552 ], [ 29.777817468354431, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28042, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.472606206896552 ], [ 29.778086962025316, -1.472875689655172 ], [ 29.778356455696201, -1.472875689655172 ], [ 29.778356455696201, -1.472606206896552 ], [ 29.778086962025316, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28043, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.472606206896552 ], [ 29.778356455696201, -1.472875689655172 ], [ 29.77862594936709, -1.472875689655172 ], [ 29.77862594936709, -1.472606206896552 ], [ 29.778356455696201, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28044, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.472606206896552 ], [ 29.77862594936709, -1.472875689655172 ], [ 29.778895443037975, -1.472875689655172 ], [ 29.778895443037975, -1.472606206896552 ], [ 29.77862594936709, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28045, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.472606206896552 ], [ 29.778895443037975, -1.472875689655172 ], [ 29.77916493670886, -1.472875689655172 ], [ 29.77916493670886, -1.472606206896552 ], [ 29.778895443037975, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28046, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.472606206896552 ], [ 29.779434430379748, -1.472875689655172 ], [ 29.779703924050633, -1.472875689655172 ], [ 29.779703924050633, -1.472606206896552 ], [ 29.779434430379748, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28047, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.472606206896552 ], [ 29.779703924050633, -1.472875689655172 ], [ 29.779973417721518, -1.472875689655172 ], [ 29.779973417721518, -1.472606206896552 ], [ 29.779703924050633, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28048, "El": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.472606206896552 ], [ 29.779973417721518, -1.473145172413793 ], [ 29.780242911392406, -1.473145172413793 ], [ 29.780242911392406, -1.472875689655172 ], [ 29.780512405063291, -1.472875689655172 ], [ 29.780512405063291, -1.472606206896552 ], [ 29.779973417721518, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28049, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.472606206896552 ], [ 29.780512405063291, -1.472875689655172 ], [ 29.780781898734176, -1.472875689655172 ], [ 29.780781898734176, -1.472606206896552 ], [ 29.780512405063291, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28050, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.472606206896552 ], [ 29.780781898734176, -1.472875689655172 ], [ 29.781051392405065, -1.472875689655172 ], [ 29.781051392405065, -1.472606206896552 ], [ 29.780781898734176, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28051, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.472606206896552 ], [ 29.781051392405065, -1.472875689655172 ], [ 29.78132088607595, -1.472875689655172 ], [ 29.78132088607595, -1.472606206896552 ], [ 29.781051392405065, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28052, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.472606206896552 ], [ 29.78132088607595, -1.472875689655172 ], [ 29.781590379746834, -1.472875689655172 ], [ 29.781590379746834, -1.472606206896552 ], [ 29.78132088607595, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28053, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.472606206896552 ], [ 29.781859873417723, -1.472875689655172 ], [ 29.782129367088608, -1.472875689655172 ], [ 29.782129367088608, -1.472606206896552 ], [ 29.781859873417723, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28054, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.472606206896552 ], [ 29.782129367088608, -1.472875689655172 ], [ 29.782398860759493, -1.472875689655172 ], [ 29.782398860759493, -1.472606206896552 ], [ 29.782129367088608, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28055, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.472606206896552 ], [ 29.782398860759493, -1.473145172413793 ], [ 29.782668354430381, -1.473145172413793 ], [ 29.782668354430381, -1.472606206896552 ], [ 29.782398860759493, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28056, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.472606206896552 ], [ 29.783207341772151, -1.473414655172414 ], [ 29.783476835443039, -1.473414655172414 ], [ 29.783476835443039, -1.472606206896552 ], [ 29.783207341772151, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28057, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.472606206896552 ], [ 29.784824303797468, -1.473145172413793 ], [ 29.785093797468356, -1.473145172413793 ], [ 29.785093797468356, -1.472606206896552 ], [ 29.784824303797468, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28058, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.472606206896552 ], [ 29.785632784810126, -1.473145172413793 ], [ 29.785902278481014, -1.473145172413793 ], [ 29.785902278481014, -1.472606206896552 ], [ 29.785632784810126, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28059, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.472606206896552 ], [ 29.786171772151899, -1.473145172413793 ], [ 29.786441265822784, -1.473145172413793 ], [ 29.786441265822784, -1.472606206896552 ], [ 29.786171772151899, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28060, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.472606206896552 ], [ 29.786980253164558, -1.473145172413793 ], [ 29.787249746835442, -1.473145172413793 ], [ 29.787249746835442, -1.472606206896552 ], [ 29.786980253164558, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28061, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.472606206896552 ], [ 29.787788734177216, -1.473145172413793 ], [ 29.788058227848101, -1.473145172413793 ], [ 29.788058227848101, -1.472606206896552 ], [ 29.787788734177216, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28062, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.472606206896552 ], [ 29.788327721518986, -1.472875689655172 ], [ 29.788597215189874, -1.472875689655172 ], [ 29.788597215189874, -1.472606206896552 ], [ 29.788327721518986, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28063, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.472606206896552 ], [ 29.788597215189874, -1.472875689655172 ], [ 29.788866708860759, -1.472875689655172 ], [ 29.788866708860759, -1.472606206896552 ], [ 29.788597215189874, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28064, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.472606206896552 ], [ 29.789136202531644, -1.472875689655172 ], [ 29.789405696202532, -1.472875689655172 ], [ 29.789405696202532, -1.472606206896552 ], [ 29.789136202531644, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28065, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.472606206896552 ], [ 29.789405696202532, -1.472875689655172 ], [ 29.789675189873417, -1.472875689655172 ], [ 29.789675189873417, -1.472606206896552 ], [ 29.789405696202532, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28066, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.472606206896552 ], [ 29.789675189873417, -1.472875689655172 ], [ 29.789944683544302, -1.472875689655172 ], [ 29.789944683544302, -1.472606206896552 ], [ 29.789675189873417, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28067, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.472606206896552 ], [ 29.789944683544302, -1.472875689655172 ], [ 29.790214177215191, -1.472875689655172 ], [ 29.790214177215191, -1.472606206896552 ], [ 29.789944683544302, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28068, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.472606206896552 ], [ 29.790214177215191, -1.472875689655172 ], [ 29.790483670886076, -1.472875689655172 ], [ 29.790483670886076, -1.472606206896552 ], [ 29.790214177215191, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28069, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.472606206896552 ], [ 29.79776, -1.472875689655172 ], [ 29.798029493670885, -1.472875689655172 ], [ 29.798029493670885, -1.472606206896552 ], [ 29.79776, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28070, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.472606206896552 ], [ 29.798029493670885, -1.472875689655172 ], [ 29.798298987341774, -1.472875689655172 ], [ 29.798298987341774, -1.472606206896552 ], [ 29.798029493670885, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28071, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.472606206896552 ], [ 29.798298987341774, -1.472875689655172 ], [ 29.798568481012659, -1.472875689655172 ], [ 29.798568481012659, -1.472606206896552 ], [ 29.798298987341774, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28072, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.472606206896552 ], [ 29.798568481012659, -1.472875689655172 ], [ 29.798837974683543, -1.472875689655172 ], [ 29.798837974683543, -1.472606206896552 ], [ 29.798568481012659, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28073, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.472606206896552 ], [ 29.798837974683543, -1.473145172413793 ], [ 29.799107468354432, -1.473145172413793 ], [ 29.799107468354432, -1.472606206896552 ], [ 29.798837974683543, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28074, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.472606206896552 ], [ 29.799107468354432, -1.472875689655172 ], [ 29.799376962025317, -1.472875689655172 ], [ 29.799376962025317, -1.472606206896552 ], [ 29.799107468354432, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28075, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.472606206896552 ], [ 29.799376962025317, -1.472875689655172 ], [ 29.799646455696202, -1.472875689655172 ], [ 29.799646455696202, -1.472606206896552 ], [ 29.799376962025317, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28076, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.472875689655172 ], [ 29.757874936708859, -1.473145172413793 ], [ 29.758144430379748, -1.473145172413793 ], [ 29.758144430379748, -1.472875689655172 ], [ 29.757874936708859, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28077, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.472875689655172 ], [ 29.758144430379748, -1.473145172413793 ], [ 29.758413924050632, -1.473145172413793 ], [ 29.758413924050632, -1.472875689655172 ], [ 29.758144430379748, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28078, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.472875689655172 ], [ 29.758413924050632, -1.473145172413793 ], [ 29.758683417721517, -1.473145172413793 ], [ 29.758683417721517, -1.472875689655172 ], [ 29.758413924050632, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28079, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.47206724137931 ], [ 29.762456329113924, -1.47206724137931 ], [ 29.762456329113924, -1.473145172413793 ], [ 29.762186835443039, -1.473145172413793 ], [ 29.762186835443039, -1.473953620689655 ], [ 29.761917341772151, -1.473953620689655 ], [ 29.761917341772151, -1.472875689655172 ], [ 29.762186835443039, -1.472875689655172 ], [ 29.762186835443039, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28080, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.472875689655172 ], [ 29.763534303797467, -1.473414655172414 ], [ 29.763803797468356, -1.473414655172414 ], [ 29.763803797468356, -1.472875689655172 ], [ 29.763534303797467, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28081, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.472875689655172 ], [ 29.764881772151899, -1.473414655172414 ], [ 29.765151265822784, -1.473414655172414 ], [ 29.765151265822784, -1.472875689655172 ], [ 29.764881772151899, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28082, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.472875689655172 ], [ 29.765959746835442, -1.473145172413793 ], [ 29.766229240506327, -1.473145172413793 ], [ 29.766229240506327, -1.472875689655172 ], [ 29.765959746835442, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28083, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.472875689655172 ], [ 29.766229240506327, -1.473145172413793 ], [ 29.766498734177215, -1.473145172413793 ], [ 29.766498734177215, -1.472875689655172 ], [ 29.766229240506327, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28084, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.472875689655172 ], [ 29.766498734177215, -1.473145172413793 ], [ 29.7667682278481, -1.473145172413793 ], [ 29.7667682278481, -1.472875689655172 ], [ 29.766498734177215, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28085, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.472875689655172 ], [ 29.772427594936708, -1.473414655172414 ], [ 29.772697088607593, -1.473414655172414 ], [ 29.772697088607593, -1.472875689655172 ], [ 29.772427594936708, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28086, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.472875689655172 ], [ 29.772697088607593, -1.473414655172414 ], [ 29.772966582278482, -1.473414655172414 ], [ 29.772966582278482, -1.472875689655172 ], [ 29.772697088607593, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28087, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.472875689655172 ], [ 29.773236075949367, -1.473414655172414 ], [ 29.773505569620252, -1.473414655172414 ], [ 29.773505569620252, -1.472875689655172 ], [ 29.773236075949367, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28088, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.472875689655172 ], [ 29.773505569620252, -1.473414655172414 ], [ 29.77377506329114, -1.473414655172414 ], [ 29.77377506329114, -1.472875689655172 ], [ 29.773505569620252, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28089, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.472875689655172 ], [ 29.77377506329114, -1.473414655172414 ], [ 29.774044556962025, -1.473414655172414 ], [ 29.774044556962025, -1.472875689655172 ], [ 29.77377506329114, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28090, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.472875689655172 ], [ 29.774044556962025, -1.473414655172414 ], [ 29.77431405063291, -1.473414655172414 ], [ 29.77431405063291, -1.472875689655172 ], [ 29.774044556962025, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28091, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.472875689655172 ], [ 29.77431405063291, -1.473414655172414 ], [ 29.774853037974683, -1.473414655172414 ], [ 29.774853037974683, -1.472875689655172 ], [ 29.77431405063291, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28092, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.472875689655172 ], [ 29.774853037974683, -1.473414655172414 ], [ 29.775122531645568, -1.473414655172414 ], [ 29.775122531645568, -1.472875689655172 ], [ 29.774853037974683, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28093, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.472875689655172 ], [ 29.775122531645568, -1.473414655172414 ], [ 29.775392025316457, -1.473414655172414 ], [ 29.775392025316457, -1.472875689655172 ], [ 29.775122531645568, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28094, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.472875689655172 ], [ 29.775392025316457, -1.473414655172414 ], [ 29.775661518987341, -1.473414655172414 ], [ 29.775661518987341, -1.472875689655172 ], [ 29.775392025316457, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28095, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.472875689655172 ], [ 29.775661518987341, -1.473414655172414 ], [ 29.775931012658226, -1.473414655172414 ], [ 29.775931012658226, -1.472875689655172 ], [ 29.775661518987341, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28096, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.472875689655172 ], [ 29.775931012658226, -1.473414655172414 ], [ 29.776200506329115, -1.473414655172414 ], [ 29.776200506329115, -1.472875689655172 ], [ 29.775931012658226, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28097, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.472875689655172 ], [ 29.776200506329115, -1.473414655172414 ], [ 29.77647, -1.473414655172414 ], [ 29.77647, -1.472875689655172 ], [ 29.776200506329115, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28098, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.472875689655172 ], [ 29.77647, -1.473414655172414 ], [ 29.776739493670885, -1.473414655172414 ], [ 29.776739493670885, -1.472875689655172 ], [ 29.77647, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28099, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.472875689655172 ], [ 29.777008987341773, -1.473414655172414 ], [ 29.777278481012658, -1.473414655172414 ], [ 29.777278481012658, -1.472875689655172 ], [ 29.777008987341773, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28100, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.472875689655172 ], [ 29.777278481012658, -1.473414655172414 ], [ 29.777547974683543, -1.473414655172414 ], [ 29.777547974683543, -1.472875689655172 ], [ 29.777278481012658, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28101, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.472875689655172 ], [ 29.777547974683543, -1.473414655172414 ], [ 29.777817468354431, -1.473414655172414 ], [ 29.777817468354431, -1.472875689655172 ], [ 29.777547974683543, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28102, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.472875689655172 ], [ 29.777817468354431, -1.473414655172414 ], [ 29.778086962025316, -1.473414655172414 ], [ 29.778086962025316, -1.472875689655172 ], [ 29.777817468354431, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28103, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.472875689655172 ], [ 29.778086962025316, -1.473414655172414 ], [ 29.778356455696201, -1.473414655172414 ], [ 29.778356455696201, -1.472875689655172 ], [ 29.778086962025316, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28104, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.472875689655172 ], [ 29.778356455696201, -1.473414655172414 ], [ 29.77862594936709, -1.473414655172414 ], [ 29.77862594936709, -1.472875689655172 ], [ 29.778356455696201, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28105, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.472875689655172 ], [ 29.77862594936709, -1.473684137931035 ], [ 29.778895443037975, -1.473684137931035 ], [ 29.778895443037975, -1.472875689655172 ], [ 29.77862594936709, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28106, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.472875689655172 ], [ 29.778895443037975, -1.473145172413793 ], [ 29.77916493670886, -1.473145172413793 ], [ 29.77916493670886, -1.472875689655172 ], [ 29.778895443037975, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28107, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.472875689655172 ], [ 29.77916493670886, -1.473145172413793 ], [ 29.779434430379748, -1.473145172413793 ], [ 29.779434430379748, -1.472875689655172 ], [ 29.77916493670886, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28108, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.472875689655172 ], [ 29.779434430379748, -1.473684137931035 ], [ 29.779703924050633, -1.473684137931035 ], [ 29.779703924050633, -1.472875689655172 ], [ 29.779434430379748, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28109, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.472875689655172 ], [ 29.779703924050633, -1.473145172413793 ], [ 29.779973417721518, -1.473145172413793 ], [ 29.779973417721518, -1.472875689655172 ], [ 29.779703924050633, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28110, "El": 114 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.472875689655172 ], [ 29.780242911392406, -1.473145172413793 ], [ 29.780512405063291, -1.473145172413793 ], [ 29.780512405063291, -1.472875689655172 ], [ 29.780242911392406, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28111, "El": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.472875689655172 ], [ 29.780512405063291, -1.473145172413793 ], [ 29.780781898734176, -1.473145172413793 ], [ 29.780781898734176, -1.472875689655172 ], [ 29.780512405063291, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28112, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.472875689655172 ], [ 29.780781898734176, -1.473145172413793 ], [ 29.781051392405065, -1.473145172413793 ], [ 29.781051392405065, -1.472875689655172 ], [ 29.780781898734176, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28113, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.472875689655172 ], [ 29.781051392405065, -1.473145172413793 ], [ 29.78132088607595, -1.473145172413793 ], [ 29.78132088607595, -1.472875689655172 ], [ 29.781051392405065, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28114, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.472875689655172 ], [ 29.78132088607595, -1.473145172413793 ], [ 29.781590379746834, -1.473145172413793 ], [ 29.781590379746834, -1.472875689655172 ], [ 29.78132088607595, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28115, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.472875689655172 ], [ 29.781590379746834, -1.473145172413793 ], [ 29.781859873417723, -1.473145172413793 ], [ 29.781859873417723, -1.472875689655172 ], [ 29.781590379746834, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28116, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.472875689655172 ], [ 29.781859873417723, -1.473145172413793 ], [ 29.782129367088608, -1.473145172413793 ], [ 29.782129367088608, -1.472875689655172 ], [ 29.781859873417723, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28117, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.472875689655172 ], [ 29.782129367088608, -1.473145172413793 ], [ 29.782398860759493, -1.473145172413793 ], [ 29.782398860759493, -1.472875689655172 ], [ 29.782129367088608, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28118, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.472875689655172 ], [ 29.782668354430381, -1.473145172413793 ], [ 29.782937848101266, -1.473145172413793 ], [ 29.782937848101266, -1.472875689655172 ], [ 29.782668354430381, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28119, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.472875689655172 ], [ 29.783476835443039, -1.473414655172414 ], [ 29.784015822784809, -1.473414655172414 ], [ 29.784015822784809, -1.472875689655172 ], [ 29.783476835443039, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28120, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.472875689655172 ], [ 29.784285316455698, -1.473414655172414 ], [ 29.784554810126583, -1.473414655172414 ], [ 29.784554810126583, -1.472875689655172 ], [ 29.784285316455698, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28121, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.472875689655172 ], [ 29.785093797468356, -1.473414655172414 ], [ 29.785363291139241, -1.473414655172414 ], [ 29.785363291139241, -1.472875689655172 ], [ 29.785093797468356, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28122, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.472875689655172 ], [ 29.785902278481014, -1.473414655172414 ], [ 29.786171772151899, -1.473414655172414 ], [ 29.786171772151899, -1.472875689655172 ], [ 29.785902278481014, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28123, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.472875689655172 ], [ 29.786441265822784, -1.473145172413793 ], [ 29.786710759493673, -1.473145172413793 ], [ 29.786710759493673, -1.472875689655172 ], [ 29.786441265822784, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28124, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.472875689655172 ], [ 29.786710759493673, -1.473414655172414 ], [ 29.786980253164558, -1.473414655172414 ], [ 29.786980253164558, -1.472875689655172 ], [ 29.786710759493673, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28125, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.472875689655172 ], [ 29.787249746835442, -1.473145172413793 ], [ 29.787519240506327, -1.473145172413793 ], [ 29.787519240506327, -1.472875689655172 ], [ 29.787249746835442, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28126, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.472875689655172 ], [ 29.787519240506327, -1.473145172413793 ], [ 29.787788734177216, -1.473145172413793 ], [ 29.787788734177216, -1.472875689655172 ], [ 29.787519240506327, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28127, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.472875689655172 ], [ 29.788058227848101, -1.473145172413793 ], [ 29.788327721518986, -1.473145172413793 ], [ 29.788327721518986, -1.472875689655172 ], [ 29.788058227848101, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28128, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.472875689655172 ], [ 29.788327721518986, -1.473145172413793 ], [ 29.788597215189874, -1.473145172413793 ], [ 29.788597215189874, -1.472875689655172 ], [ 29.788327721518986, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28129, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.472875689655172 ], [ 29.788597215189874, -1.473145172413793 ], [ 29.788866708860759, -1.473145172413793 ], [ 29.788866708860759, -1.472875689655172 ], [ 29.788597215189874, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28130, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.472875689655172 ], [ 29.788866708860759, -1.473145172413793 ], [ 29.789136202531644, -1.473145172413793 ], [ 29.789136202531644, -1.472875689655172 ], [ 29.788866708860759, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28131, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.472875689655172 ], [ 29.789136202531644, -1.473145172413793 ], [ 29.789405696202532, -1.473145172413793 ], [ 29.789405696202532, -1.472875689655172 ], [ 29.789136202531644, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28132, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.472875689655172 ], [ 29.789405696202532, -1.473145172413793 ], [ 29.789675189873417, -1.473145172413793 ], [ 29.789675189873417, -1.472875689655172 ], [ 29.789405696202532, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28133, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.472875689655172 ], [ 29.789675189873417, -1.473145172413793 ], [ 29.789944683544302, -1.473145172413793 ], [ 29.789944683544302, -1.472875689655172 ], [ 29.789675189873417, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28134, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.472875689655172 ], [ 29.789944683544302, -1.473145172413793 ], [ 29.790214177215191, -1.473145172413793 ], [ 29.790214177215191, -1.472875689655172 ], [ 29.789944683544302, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28135, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.472875689655172 ], [ 29.790214177215191, -1.473145172413793 ], [ 29.790483670886076, -1.473145172413793 ], [ 29.790483670886076, -1.472875689655172 ], [ 29.790214177215191, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28136, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.472875689655172 ], [ 29.790483670886076, -1.473145172413793 ], [ 29.790753164556961, -1.473145172413793 ], [ 29.790753164556961, -1.472875689655172 ], [ 29.790483670886076, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28137, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.472875689655172 ], [ 29.790753164556961, -1.473414655172414 ], [ 29.791022658227849, -1.473414655172414 ], [ 29.791022658227849, -1.472875689655172 ], [ 29.790753164556961, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28138, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.472875689655172 ], [ 29.796951518987342, -1.473145172413793 ], [ 29.797221012658227, -1.473145172413793 ], [ 29.797221012658227, -1.472875689655172 ], [ 29.796951518987342, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28139, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.472875689655172 ], [ 29.797221012658227, -1.473145172413793 ], [ 29.797490506329115, -1.473145172413793 ], [ 29.797490506329115, -1.472875689655172 ], [ 29.797221012658227, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28140, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.472875689655172 ], [ 29.797490506329115, -1.473145172413793 ], [ 29.79776, -1.473145172413793 ], [ 29.79776, -1.472875689655172 ], [ 29.797490506329115, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28141, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.472875689655172 ], [ 29.79776, -1.473414655172414 ], [ 29.798029493670885, -1.473414655172414 ], [ 29.798029493670885, -1.472875689655172 ], [ 29.79776, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28142, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.472875689655172 ], [ 29.798029493670885, -1.473145172413793 ], [ 29.798298987341774, -1.473145172413793 ], [ 29.798298987341774, -1.472875689655172 ], [ 29.798029493670885, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28143, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.472875689655172 ], [ 29.798298987341774, -1.473145172413793 ], [ 29.798568481012659, -1.473145172413793 ], [ 29.798568481012659, -1.472875689655172 ], [ 29.798298987341774, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28144, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.472875689655172 ], [ 29.799107468354432, -1.473414655172414 ], [ 29.799376962025317, -1.473414655172414 ], [ 29.799376962025317, -1.472875689655172 ], [ 29.799107468354432, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28145, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.473145172413793 ], [ 29.762186835443039, -1.473684137931035 ], [ 29.762456329113924, -1.473684137931035 ], [ 29.762456329113924, -1.473145172413793 ], [ 29.762186835443039, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28146, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.473145172413793 ], [ 29.762995316455697, -1.473684137931035 ], [ 29.763264810126582, -1.473684137931035 ], [ 29.763264810126582, -1.473145172413793 ], [ 29.762995316455697, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28147, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.473145172413793 ], [ 29.763803797468356, -1.473684137931035 ], [ 29.76407329113924, -1.473684137931035 ], [ 29.76407329113924, -1.473145172413793 ], [ 29.763803797468356, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28148, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.473145172413793 ], [ 29.764342784810125, -1.473414655172414 ], [ 29.764612278481014, -1.473414655172414 ], [ 29.764612278481014, -1.473145172413793 ], [ 29.764342784810125, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28149, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.473145172413793 ], [ 29.764612278481014, -1.473414655172414 ], [ 29.764881772151899, -1.473414655172414 ], [ 29.764881772151899, -1.473145172413793 ], [ 29.764612278481014, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28150, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.473145172413793 ], [ 29.765151265822784, -1.473414655172414 ], [ 29.765420759493672, -1.473414655172414 ], [ 29.765420759493672, -1.473145172413793 ], [ 29.765151265822784, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28151, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.473145172413793 ], [ 29.765420759493672, -1.473414655172414 ], [ 29.765959746835442, -1.473414655172414 ], [ 29.765959746835442, -1.473145172413793 ], [ 29.765420759493672, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28152, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.473145172413793 ], [ 29.765959746835442, -1.473414655172414 ], [ 29.766229240506327, -1.473414655172414 ], [ 29.766229240506327, -1.473145172413793 ], [ 29.765959746835442, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28153, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.473145172413793 ], [ 29.770002151898733, -1.473684137931035 ], [ 29.770271645569618, -1.473684137931035 ], [ 29.770271645569618, -1.473145172413793 ], [ 29.770002151898733, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28154, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.473145172413793 ], [ 29.770271645569618, -1.473684137931035 ], [ 29.770541139240507, -1.473684137931035 ], [ 29.770541139240507, -1.473145172413793 ], [ 29.770271645569618, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28155, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.473145172413793 ], [ 29.770541139240507, -1.473684137931035 ], [ 29.770810632911392, -1.473684137931035 ], [ 29.770810632911392, -1.473145172413793 ], [ 29.770541139240507, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28156, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.473145172413793 ], [ 29.770810632911392, -1.473684137931035 ], [ 29.771080126582277, -1.473684137931035 ], [ 29.771080126582277, -1.473145172413793 ], [ 29.770810632911392, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28157, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.473145172413793 ], [ 29.77161911392405, -1.473684137931035 ], [ 29.771888607594935, -1.473684137931035 ], [ 29.771888607594935, -1.473145172413793 ], [ 29.77161911392405, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28158, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.473145172413793 ], [ 29.772966582278482, -1.473414655172414 ], [ 29.773236075949367, -1.473414655172414 ], [ 29.773236075949367, -1.473145172413793 ], [ 29.772966582278482, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28159, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.473145172413793 ], [ 29.778895443037975, -1.473414655172414 ], [ 29.77916493670886, -1.473414655172414 ], [ 29.77916493670886, -1.473145172413793 ], [ 29.778895443037975, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28160, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.473145172413793 ], [ 29.77916493670886, -1.473414655172414 ], [ 29.779434430379748, -1.473414655172414 ], [ 29.779434430379748, -1.473145172413793 ], [ 29.77916493670886, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28161, "El": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.473145172413793 ], [ 29.779703924050633, -1.473414655172414 ], [ 29.779973417721518, -1.473414655172414 ], [ 29.779973417721518, -1.473145172413793 ], [ 29.779703924050633, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28162, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.473145172413793 ], [ 29.779973417721518, -1.473414655172414 ], [ 29.780242911392406, -1.473414655172414 ], [ 29.780242911392406, -1.473145172413793 ], [ 29.779973417721518, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28163, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.473145172413793 ], [ 29.780242911392406, -1.473414655172414 ], [ 29.780512405063291, -1.473414655172414 ], [ 29.780512405063291, -1.473145172413793 ], [ 29.780242911392406, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28164, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.473145172413793 ], [ 29.780512405063291, -1.473414655172414 ], [ 29.780781898734176, -1.473414655172414 ], [ 29.780781898734176, -1.473145172413793 ], [ 29.780512405063291, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28165, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.473145172413793 ], [ 29.780781898734176, -1.473414655172414 ], [ 29.781051392405065, -1.473414655172414 ], [ 29.781051392405065, -1.473145172413793 ], [ 29.780781898734176, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28166, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.473145172413793 ], [ 29.781051392405065, -1.473414655172414 ], [ 29.78132088607595, -1.473414655172414 ], [ 29.78132088607595, -1.473145172413793 ], [ 29.781051392405065, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28167, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.473145172413793 ], [ 29.78132088607595, -1.473414655172414 ], [ 29.781590379746834, -1.473414655172414 ], [ 29.781590379746834, -1.473145172413793 ], [ 29.78132088607595, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28168, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.473145172413793 ], [ 29.781590379746834, -1.473414655172414 ], [ 29.781859873417723, -1.473414655172414 ], [ 29.781859873417723, -1.473145172413793 ], [ 29.781590379746834, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28169, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.473145172413793 ], [ 29.781859873417723, -1.473414655172414 ], [ 29.782129367088608, -1.473414655172414 ], [ 29.782129367088608, -1.473145172413793 ], [ 29.781859873417723, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28170, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.473145172413793 ], [ 29.782129367088608, -1.473414655172414 ], [ 29.782398860759493, -1.473414655172414 ], [ 29.782398860759493, -1.473145172413793 ], [ 29.782129367088608, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28171, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.473145172413793 ], [ 29.782398860759493, -1.473414655172414 ], [ 29.782668354430381, -1.473414655172414 ], [ 29.782668354430381, -1.473145172413793 ], [ 29.782398860759493, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28172, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.473145172413793 ], [ 29.782668354430381, -1.473414655172414 ], [ 29.782937848101266, -1.473414655172414 ], [ 29.782937848101266, -1.473145172413793 ], [ 29.782668354430381, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28173, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.473145172413793 ], [ 29.782937848101266, -1.473414655172414 ], [ 29.783207341772151, -1.473414655172414 ], [ 29.783207341772151, -1.473145172413793 ], [ 29.782937848101266, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28174, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.473145172413793 ], [ 29.784015822784809, -1.473953620689655 ], [ 29.784285316455698, -1.473953620689655 ], [ 29.784285316455698, -1.473145172413793 ], [ 29.784015822784809, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28175, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.473145172413793 ], [ 29.784554810126583, -1.473414655172414 ], [ 29.784824303797468, -1.473414655172414 ], [ 29.784824303797468, -1.473145172413793 ], [ 29.784554810126583, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28176, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.473145172413793 ], [ 29.784824303797468, -1.473684137931035 ], [ 29.785093797468356, -1.473684137931035 ], [ 29.785093797468356, -1.473145172413793 ], [ 29.784824303797468, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28177, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.473145172413793 ], [ 29.785363291139241, -1.473414655172414 ], [ 29.785632784810126, -1.473414655172414 ], [ 29.785632784810126, -1.473145172413793 ], [ 29.785363291139241, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28178, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.473145172413793 ], [ 29.785632784810126, -1.473414655172414 ], [ 29.785902278481014, -1.473414655172414 ], [ 29.785902278481014, -1.473145172413793 ], [ 29.785632784810126, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28179, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.473145172413793 ], [ 29.786171772151899, -1.473414655172414 ], [ 29.786441265822784, -1.473414655172414 ], [ 29.786441265822784, -1.473145172413793 ], [ 29.786171772151899, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28180, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.473145172413793 ], [ 29.786441265822784, -1.473414655172414 ], [ 29.786710759493673, -1.473414655172414 ], [ 29.786710759493673, -1.473145172413793 ], [ 29.786441265822784, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28181, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.473145172413793 ], [ 29.786980253164558, -1.473414655172414 ], [ 29.787249746835442, -1.473414655172414 ], [ 29.787249746835442, -1.473145172413793 ], [ 29.786980253164558, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28182, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.473145172413793 ], [ 29.787249746835442, -1.473414655172414 ], [ 29.787519240506327, -1.473414655172414 ], [ 29.787519240506327, -1.473145172413793 ], [ 29.787249746835442, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28183, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.473145172413793 ], [ 29.787519240506327, -1.473414655172414 ], [ 29.787788734177216, -1.473414655172414 ], [ 29.787788734177216, -1.473145172413793 ], [ 29.787519240506327, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28184, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.473145172413793 ], [ 29.787788734177216, -1.473414655172414 ], [ 29.788058227848101, -1.473414655172414 ], [ 29.788058227848101, -1.473145172413793 ], [ 29.787788734177216, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28185, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.473145172413793 ], [ 29.788058227848101, -1.473414655172414 ], [ 29.788327721518986, -1.473414655172414 ], [ 29.788327721518986, -1.473145172413793 ], [ 29.788058227848101, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28186, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.473145172413793 ], [ 29.788327721518986, -1.473414655172414 ], [ 29.788597215189874, -1.473414655172414 ], [ 29.788597215189874, -1.473145172413793 ], [ 29.788327721518986, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28187, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.473145172413793 ], [ 29.788597215189874, -1.473414655172414 ], [ 29.788866708860759, -1.473414655172414 ], [ 29.788866708860759, -1.473145172413793 ], [ 29.788597215189874, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28188, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.473145172413793 ], [ 29.788866708860759, -1.473414655172414 ], [ 29.789136202531644, -1.473414655172414 ], [ 29.789136202531644, -1.473145172413793 ], [ 29.788866708860759, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28189, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.473145172413793 ], [ 29.789136202531644, -1.473414655172414 ], [ 29.789405696202532, -1.473414655172414 ], [ 29.789405696202532, -1.473145172413793 ], [ 29.789136202531644, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28190, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.473145172413793 ], [ 29.789405696202532, -1.473414655172414 ], [ 29.789675189873417, -1.473414655172414 ], [ 29.789675189873417, -1.473145172413793 ], [ 29.789405696202532, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28191, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.473145172413793 ], [ 29.789675189873417, -1.473414655172414 ], [ 29.789944683544302, -1.473414655172414 ], [ 29.789944683544302, -1.473145172413793 ], [ 29.789675189873417, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28192, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.473145172413793 ], [ 29.789944683544302, -1.473414655172414 ], [ 29.790214177215191, -1.473414655172414 ], [ 29.790214177215191, -1.473145172413793 ], [ 29.789944683544302, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28193, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.473145172413793 ], [ 29.790214177215191, -1.473414655172414 ], [ 29.790483670886076, -1.473414655172414 ], [ 29.790483670886076, -1.473145172413793 ], [ 29.790214177215191, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28194, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.473145172413793 ], [ 29.790483670886076, -1.473414655172414 ], [ 29.790753164556961, -1.473414655172414 ], [ 29.790753164556961, -1.473145172413793 ], [ 29.790483670886076, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28195, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.473145172413793 ], [ 29.796412531645569, -1.473414655172414 ], [ 29.796682025316457, -1.473414655172414 ], [ 29.796682025316457, -1.473145172413793 ], [ 29.796412531645569, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28196, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.473145172413793 ], [ 29.796682025316457, -1.473414655172414 ], [ 29.796951518987342, -1.473414655172414 ], [ 29.796951518987342, -1.473145172413793 ], [ 29.796682025316457, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28197, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.473145172413793 ], [ 29.796951518987342, -1.473414655172414 ], [ 29.797221012658227, -1.473414655172414 ], [ 29.797221012658227, -1.473145172413793 ], [ 29.796951518987342, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28198, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.473414655172414 ], [ 29.762456329113924, -1.473684137931035 ], [ 29.762725822784809, -1.473684137931035 ], [ 29.762725822784809, -1.473414655172414 ], [ 29.762456329113924, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28199, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.473414655172414 ], [ 29.762725822784809, -1.473953620689655 ], [ 29.762995316455697, -1.473953620689655 ], [ 29.762995316455697, -1.473414655172414 ], [ 29.762725822784809, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28200, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.473414655172414 ], [ 29.763264810126582, -1.473684137931035 ], [ 29.763534303797467, -1.473684137931035 ], [ 29.763534303797467, -1.473414655172414 ], [ 29.763264810126582, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28201, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.473414655172414 ], [ 29.763534303797467, -1.473684137931035 ], [ 29.763803797468356, -1.473684137931035 ], [ 29.763803797468356, -1.473414655172414 ], [ 29.763534303797467, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28202, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.473414655172414 ], [ 29.76407329113924, -1.473684137931035 ], [ 29.764342784810125, -1.473684137931035 ], [ 29.764342784810125, -1.473414655172414 ], [ 29.76407329113924, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28203, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.473414655172414 ], [ 29.764342784810125, -1.473684137931035 ], [ 29.764612278481014, -1.473684137931035 ], [ 29.764612278481014, -1.473414655172414 ], [ 29.764342784810125, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28204, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.473414655172414 ], [ 29.764612278481014, -1.473684137931035 ], [ 29.764881772151899, -1.473684137931035 ], [ 29.764881772151899, -1.473414655172414 ], [ 29.764612278481014, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28205, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.473414655172414 ], [ 29.764881772151899, -1.473684137931035 ], [ 29.765151265822784, -1.473684137931035 ], [ 29.765151265822784, -1.473414655172414 ], [ 29.764881772151899, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28206, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.473414655172414 ], [ 29.772427594936708, -1.473684137931035 ], [ 29.772697088607593, -1.473684137931035 ], [ 29.772697088607593, -1.473414655172414 ], [ 29.772427594936708, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28207, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.473414655172414 ], [ 29.772697088607593, -1.473684137931035 ], [ 29.772966582278482, -1.473684137931035 ], [ 29.772966582278482, -1.473414655172414 ], [ 29.772697088607593, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28208, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.473414655172414 ], [ 29.772966582278482, -1.473684137931035 ], [ 29.773236075949367, -1.473684137931035 ], [ 29.773236075949367, -1.473414655172414 ], [ 29.772966582278482, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28209, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.473414655172414 ], [ 29.773236075949367, -1.473684137931035 ], [ 29.773505569620252, -1.473684137931035 ], [ 29.773505569620252, -1.473414655172414 ], [ 29.773236075949367, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28210, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.473414655172414 ], [ 29.773505569620252, -1.473684137931035 ], [ 29.77377506329114, -1.473684137931035 ], [ 29.77377506329114, -1.473414655172414 ], [ 29.773505569620252, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28211, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.473414655172414 ], [ 29.77377506329114, -1.473684137931035 ], [ 29.774044556962025, -1.473684137931035 ], [ 29.774044556962025, -1.473414655172414 ], [ 29.77377506329114, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28212, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.473414655172414 ], [ 29.774044556962025, -1.473684137931035 ], [ 29.77431405063291, -1.473684137931035 ], [ 29.77431405063291, -1.473414655172414 ], [ 29.774044556962025, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28213, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.473414655172414 ], [ 29.77431405063291, -1.473684137931035 ], [ 29.774853037974683, -1.473684137931035 ], [ 29.774853037974683, -1.473414655172414 ], [ 29.77431405063291, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28214, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.473414655172414 ], [ 29.774853037974683, -1.473684137931035 ], [ 29.775122531645568, -1.473684137931035 ], [ 29.775122531645568, -1.473414655172414 ], [ 29.774853037974683, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28215, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.473414655172414 ], [ 29.775122531645568, -1.473684137931035 ], [ 29.775392025316457, -1.473684137931035 ], [ 29.775392025316457, -1.473414655172414 ], [ 29.775122531645568, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28216, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.473414655172414 ], [ 29.775392025316457, -1.473684137931035 ], [ 29.775661518987341, -1.473684137931035 ], [ 29.775661518987341, -1.473414655172414 ], [ 29.775392025316457, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28217, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.473414655172414 ], [ 29.775661518987341, -1.473953620689655 ], [ 29.775931012658226, -1.473953620689655 ], [ 29.775931012658226, -1.473414655172414 ], [ 29.775661518987341, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28218, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.473414655172414 ], [ 29.775931012658226, -1.473684137931035 ], [ 29.776200506329115, -1.473684137931035 ], [ 29.776200506329115, -1.473414655172414 ], [ 29.775931012658226, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28219, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.473414655172414 ], [ 29.776200506329115, -1.473684137931035 ], [ 29.77647, -1.473684137931035 ], [ 29.77647, -1.473414655172414 ], [ 29.776200506329115, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28220, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.473414655172414 ], [ 29.77647, -1.473684137931035 ], [ 29.776739493670885, -1.473684137931035 ], [ 29.776739493670885, -1.473414655172414 ], [ 29.77647, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28221, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.473414655172414 ], [ 29.777008987341773, -1.473684137931035 ], [ 29.777278481012658, -1.473684137931035 ], [ 29.777278481012658, -1.473414655172414 ], [ 29.777008987341773, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28222, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.473414655172414 ], [ 29.777278481012658, -1.473684137931035 ], [ 29.777547974683543, -1.473684137931035 ], [ 29.777547974683543, -1.473414655172414 ], [ 29.777278481012658, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28223, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.473414655172414 ], [ 29.777547974683543, -1.473953620689655 ], [ 29.777817468354431, -1.473953620689655 ], [ 29.777817468354431, -1.473414655172414 ], [ 29.777547974683543, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28224, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.473414655172414 ], [ 29.777817468354431, -1.473684137931035 ], [ 29.778086962025316, -1.473684137931035 ], [ 29.778086962025316, -1.473414655172414 ], [ 29.777817468354431, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28225, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.473414655172414 ], [ 29.778086962025316, -1.473684137931035 ], [ 29.778356455696201, -1.473684137931035 ], [ 29.778356455696201, -1.473414655172414 ], [ 29.778086962025316, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28226, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.473414655172414 ], [ 29.778356455696201, -1.473953620689655 ], [ 29.77862594936709, -1.473953620689655 ], [ 29.77862594936709, -1.473414655172414 ], [ 29.778356455696201, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28227, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.473414655172414 ], [ 29.778895443037975, -1.473684137931035 ], [ 29.77916493670886, -1.473684137931035 ], [ 29.77916493670886, -1.473414655172414 ], [ 29.778895443037975, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28228, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.473414655172414 ], [ 29.77916493670886, -1.473953620689655 ], [ 29.779434430379748, -1.473953620689655 ], [ 29.779434430379748, -1.473414655172414 ], [ 29.77916493670886, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28229, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.473414655172414 ], [ 29.779703924050633, -1.473684137931035 ], [ 29.779973417721518, -1.473684137931035 ], [ 29.779973417721518, -1.473414655172414 ], [ 29.779703924050633, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28230, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.473414655172414 ], [ 29.779973417721518, -1.473684137931035 ], [ 29.780242911392406, -1.473684137931035 ], [ 29.780242911392406, -1.473414655172414 ], [ 29.779973417721518, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28231, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.473414655172414 ], [ 29.780242911392406, -1.473684137931035 ], [ 29.780512405063291, -1.473684137931035 ], [ 29.780512405063291, -1.473414655172414 ], [ 29.780242911392406, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28232, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.473414655172414 ], [ 29.780512405063291, -1.473684137931035 ], [ 29.780781898734176, -1.473684137931035 ], [ 29.780781898734176, -1.473414655172414 ], [ 29.780512405063291, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28233, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.473414655172414 ], [ 29.780781898734176, -1.473684137931035 ], [ 29.781051392405065, -1.473684137931035 ], [ 29.781051392405065, -1.473414655172414 ], [ 29.780781898734176, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28234, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.473414655172414 ], [ 29.781051392405065, -1.473684137931035 ], [ 29.78132088607595, -1.473684137931035 ], [ 29.78132088607595, -1.473414655172414 ], [ 29.781051392405065, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28235, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.473414655172414 ], [ 29.78132088607595, -1.473684137931035 ], [ 29.781590379746834, -1.473684137931035 ], [ 29.781590379746834, -1.473414655172414 ], [ 29.78132088607595, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28236, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.473414655172414 ], [ 29.781590379746834, -1.473684137931035 ], [ 29.781859873417723, -1.473684137931035 ], [ 29.781859873417723, -1.473414655172414 ], [ 29.781590379746834, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28237, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.473414655172414 ], [ 29.781859873417723, -1.473684137931035 ], [ 29.782129367088608, -1.473684137931035 ], [ 29.782129367088608, -1.473414655172414 ], [ 29.781859873417723, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28238, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.473414655172414 ], [ 29.782129367088608, -1.473684137931035 ], [ 29.782398860759493, -1.473684137931035 ], [ 29.782398860759493, -1.473414655172414 ], [ 29.782129367088608, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28239, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.473414655172414 ], [ 29.782398860759493, -1.473684137931035 ], [ 29.782668354430381, -1.473684137931035 ], [ 29.782668354430381, -1.473414655172414 ], [ 29.782398860759493, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28240, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.473414655172414 ], [ 29.782668354430381, -1.473684137931035 ], [ 29.782937848101266, -1.473684137931035 ], [ 29.782937848101266, -1.473414655172414 ], [ 29.782668354430381, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28241, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.473414655172414 ], [ 29.782937848101266, -1.473684137931035 ], [ 29.783207341772151, -1.473684137931035 ], [ 29.783207341772151, -1.473414655172414 ], [ 29.782937848101266, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28242, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.473414655172414 ], [ 29.783207341772151, -1.473684137931035 ], [ 29.783476835443039, -1.473684137931035 ], [ 29.783476835443039, -1.473414655172414 ], [ 29.783207341772151, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28243, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.473414655172414 ], [ 29.783476835443039, -1.473684137931035 ], [ 29.784015822784809, -1.473684137931035 ], [ 29.784015822784809, -1.473414655172414 ], [ 29.783476835443039, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28244, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.473414655172414 ], [ 29.784285316455698, -1.473684137931035 ], [ 29.784554810126583, -1.473684137931035 ], [ 29.784554810126583, -1.473414655172414 ], [ 29.784285316455698, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28245, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.473414655172414 ], [ 29.784554810126583, -1.473684137931035 ], [ 29.784824303797468, -1.473684137931035 ], [ 29.784824303797468, -1.473414655172414 ], [ 29.784554810126583, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28246, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.473414655172414 ], [ 29.785093797468356, -1.473684137931035 ], [ 29.785363291139241, -1.473684137931035 ], [ 29.785363291139241, -1.473414655172414 ], [ 29.785093797468356, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28247, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.473414655172414 ], [ 29.785363291139241, -1.473684137931035 ], [ 29.785632784810126, -1.473684137931035 ], [ 29.785632784810126, -1.473414655172414 ], [ 29.785363291139241, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28248, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.473414655172414 ], [ 29.785632784810126, -1.473953620689655 ], [ 29.785902278481014, -1.473953620689655 ], [ 29.785902278481014, -1.473414655172414 ], [ 29.785632784810126, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28249, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.473414655172414 ], [ 29.785902278481014, -1.473684137931035 ], [ 29.786171772151899, -1.473684137931035 ], [ 29.786171772151899, -1.473414655172414 ], [ 29.785902278481014, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28250, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.473414655172414 ], [ 29.786171772151899, -1.473684137931035 ], [ 29.786441265822784, -1.473684137931035 ], [ 29.786441265822784, -1.473414655172414 ], [ 29.786171772151899, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28251, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.473414655172414 ], [ 29.786441265822784, -1.473684137931035 ], [ 29.786710759493673, -1.473684137931035 ], [ 29.786710759493673, -1.473414655172414 ], [ 29.786441265822784, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28252, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.473414655172414 ], [ 29.786710759493673, -1.473684137931035 ], [ 29.786980253164558, -1.473684137931035 ], [ 29.786980253164558, -1.473414655172414 ], [ 29.786710759493673, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28253, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.473414655172414 ], [ 29.786980253164558, -1.473684137931035 ], [ 29.787249746835442, -1.473684137931035 ], [ 29.787249746835442, -1.473414655172414 ], [ 29.786980253164558, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28254, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.473414655172414 ], [ 29.787249746835442, -1.473684137931035 ], [ 29.787519240506327, -1.473684137931035 ], [ 29.787519240506327, -1.473414655172414 ], [ 29.787249746835442, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28255, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.473414655172414 ], [ 29.787519240506327, -1.473684137931035 ], [ 29.787788734177216, -1.473684137931035 ], [ 29.787788734177216, -1.473414655172414 ], [ 29.787519240506327, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28256, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.473414655172414 ], [ 29.787788734177216, -1.473684137931035 ], [ 29.788058227848101, -1.473684137931035 ], [ 29.788058227848101, -1.473414655172414 ], [ 29.787788734177216, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28257, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.473414655172414 ], [ 29.788058227848101, -1.473684137931035 ], [ 29.788327721518986, -1.473684137931035 ], [ 29.788327721518986, -1.473414655172414 ], [ 29.788058227848101, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28258, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.473414655172414 ], [ 29.788327721518986, -1.473684137931035 ], [ 29.788597215189874, -1.473684137931035 ], [ 29.788597215189874, -1.473414655172414 ], [ 29.788327721518986, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28259, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.473414655172414 ], [ 29.788597215189874, -1.473684137931035 ], [ 29.788866708860759, -1.473684137931035 ], [ 29.788866708860759, -1.473414655172414 ], [ 29.788597215189874, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28260, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.473414655172414 ], [ 29.788866708860759, -1.473684137931035 ], [ 29.789136202531644, -1.473684137931035 ], [ 29.789136202531644, -1.473414655172414 ], [ 29.788866708860759, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28261, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.473414655172414 ], [ 29.789136202531644, -1.473684137931035 ], [ 29.789405696202532, -1.473684137931035 ], [ 29.789405696202532, -1.473414655172414 ], [ 29.789136202531644, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28262, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.473414655172414 ], [ 29.789405696202532, -1.473684137931035 ], [ 29.789675189873417, -1.473684137931035 ], [ 29.789675189873417, -1.473414655172414 ], [ 29.789405696202532, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28263, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.473414655172414 ], [ 29.789675189873417, -1.473684137931035 ], [ 29.789944683544302, -1.473684137931035 ], [ 29.789944683544302, -1.473414655172414 ], [ 29.789675189873417, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28264, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.473414655172414 ], [ 29.789944683544302, -1.473684137931035 ], [ 29.790214177215191, -1.473684137931035 ], [ 29.790214177215191, -1.473414655172414 ], [ 29.789944683544302, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28265, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.473414655172414 ], [ 29.790214177215191, -1.473684137931035 ], [ 29.790483670886076, -1.473684137931035 ], [ 29.790483670886076, -1.473414655172414 ], [ 29.790214177215191, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28266, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.473414655172414 ], [ 29.790483670886076, -1.473684137931035 ], [ 29.790753164556961, -1.473684137931035 ], [ 29.790753164556961, -1.473414655172414 ], [ 29.790483670886076, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28267, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.473414655172414 ], [ 29.790753164556961, -1.473684137931035 ], [ 29.791022658227849, -1.473684137931035 ], [ 29.791022658227849, -1.473414655172414 ], [ 29.790753164556961, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28268, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.473684137931035 ], [ 29.762186835443039, -1.473953620689655 ], [ 29.762456329113924, -1.473953620689655 ], [ 29.762456329113924, -1.473684137931035 ], [ 29.762186835443039, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28269, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.473684137931035 ], [ 29.762456329113924, -1.473953620689655 ], [ 29.762725822784809, -1.473953620689655 ], [ 29.762725822784809, -1.473684137931035 ], [ 29.762456329113924, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28270, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.473684137931035 ], [ 29.762995316455697, -1.473953620689655 ], [ 29.763264810126582, -1.473953620689655 ], [ 29.763264810126582, -1.473684137931035 ], [ 29.762995316455697, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28271, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.473684137931035 ], [ 29.763264810126582, -1.473953620689655 ], [ 29.763534303797467, -1.473953620689655 ], [ 29.763534303797467, -1.473684137931035 ], [ 29.763264810126582, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28272, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.473684137931035 ], [ 29.763534303797467, -1.473953620689655 ], [ 29.763803797468356, -1.473953620689655 ], [ 29.763803797468356, -1.473684137931035 ], [ 29.763534303797467, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28273, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.473684137931035 ], [ 29.76407329113924, -1.473953620689655 ], [ 29.764342784810125, -1.473953620689655 ], [ 29.764342784810125, -1.473684137931035 ], [ 29.76407329113924, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28274, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.473684137931035 ], [ 29.764342784810125, -1.473953620689655 ], [ 29.764612278481014, -1.473953620689655 ], [ 29.764612278481014, -1.473684137931035 ], [ 29.764342784810125, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28275, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.473684137931035 ], [ 29.77161911392405, -1.473953620689655 ], [ 29.771888607594935, -1.473953620689655 ], [ 29.771888607594935, -1.473684137931035 ], [ 29.77161911392405, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28276, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.473684137931035 ], [ 29.771888607594935, -1.473953620689655 ], [ 29.772158101265823, -1.473953620689655 ], [ 29.772158101265823, -1.473684137931035 ], [ 29.771888607594935, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28277, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.473684137931035 ], [ 29.772158101265823, -1.473953620689655 ], [ 29.772427594936708, -1.473953620689655 ], [ 29.772427594936708, -1.473684137931035 ], [ 29.772158101265823, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28278, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.473684137931035 ], [ 29.772427594936708, -1.473953620689655 ], [ 29.772697088607593, -1.473953620689655 ], [ 29.772697088607593, -1.473684137931035 ], [ 29.772427594936708, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28279, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.473684137931035 ], [ 29.772697088607593, -1.473953620689655 ], [ 29.772966582278482, -1.473953620689655 ], [ 29.772966582278482, -1.473684137931035 ], [ 29.772697088607593, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28280, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.473684137931035 ], [ 29.772966582278482, -1.473953620689655 ], [ 29.773236075949367, -1.473953620689655 ], [ 29.773236075949367, -1.473684137931035 ], [ 29.772966582278482, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28281, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.473684137931035 ], [ 29.773236075949367, -1.473953620689655 ], [ 29.773505569620252, -1.473953620689655 ], [ 29.773505569620252, -1.473684137931035 ], [ 29.773236075949367, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28282, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.473684137931035 ], [ 29.773505569620252, -1.473953620689655 ], [ 29.77377506329114, -1.473953620689655 ], [ 29.77377506329114, -1.473684137931035 ], [ 29.773505569620252, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28283, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.473684137931035 ], [ 29.77377506329114, -1.473953620689655 ], [ 29.774044556962025, -1.473953620689655 ], [ 29.774044556962025, -1.473684137931035 ], [ 29.77377506329114, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28284, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.473684137931035 ], [ 29.774044556962025, -1.473953620689655 ], [ 29.77431405063291, -1.473953620689655 ], [ 29.77431405063291, -1.473684137931035 ], [ 29.774044556962025, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28285, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.473684137931035 ], [ 29.77431405063291, -1.473953620689655 ], [ 29.774853037974683, -1.473953620689655 ], [ 29.774853037974683, -1.473684137931035 ], [ 29.77431405063291, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28286, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.473684137931035 ], [ 29.774853037974683, -1.473953620689655 ], [ 29.775122531645568, -1.473953620689655 ], [ 29.775122531645568, -1.473684137931035 ], [ 29.774853037974683, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28287, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.473684137931035 ], [ 29.775122531645568, -1.473953620689655 ], [ 29.775392025316457, -1.473953620689655 ], [ 29.775392025316457, -1.473684137931035 ], [ 29.775122531645568, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28288, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.473684137931035 ], [ 29.775392025316457, -1.473953620689655 ], [ 29.775661518987341, -1.473953620689655 ], [ 29.775661518987341, -1.473684137931035 ], [ 29.775392025316457, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28289, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.473684137931035 ], [ 29.775931012658226, -1.473953620689655 ], [ 29.776200506329115, -1.473953620689655 ], [ 29.776200506329115, -1.473684137931035 ], [ 29.775931012658226, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28290, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.473684137931035 ], [ 29.776200506329115, -1.473953620689655 ], [ 29.77647, -1.473953620689655 ], [ 29.77647, -1.473684137931035 ], [ 29.776200506329115, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28291, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.473684137931035 ], [ 29.776739493670885, -1.473953620689655 ], [ 29.777008987341773, -1.473953620689655 ], [ 29.777008987341773, -1.473684137931035 ], [ 29.776739493670885, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28292, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.473684137931035 ], [ 29.777008987341773, -1.473953620689655 ], [ 29.777278481012658, -1.473953620689655 ], [ 29.777278481012658, -1.473684137931035 ], [ 29.777008987341773, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28293, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.473684137931035 ], [ 29.777817468354431, -1.473953620689655 ], [ 29.778086962025316, -1.473953620689655 ], [ 29.778086962025316, -1.473684137931035 ], [ 29.777817468354431, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28294, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.473684137931035 ], [ 29.779703924050633, -1.473953620689655 ], [ 29.779973417721518, -1.473953620689655 ], [ 29.779973417721518, -1.473684137931035 ], [ 29.779703924050633, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28295, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.473684137931035 ], [ 29.779973417721518, -1.473953620689655 ], [ 29.780242911392406, -1.473953620689655 ], [ 29.780242911392406, -1.473684137931035 ], [ 29.779973417721518, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28296, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.473684137931035 ], [ 29.780242911392406, -1.473953620689655 ], [ 29.780512405063291, -1.473953620689655 ], [ 29.780512405063291, -1.473684137931035 ], [ 29.780242911392406, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28297, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.473684137931035 ], [ 29.780512405063291, -1.473953620689655 ], [ 29.780781898734176, -1.473953620689655 ], [ 29.780781898734176, -1.473684137931035 ], [ 29.780512405063291, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28298, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.473684137931035 ], [ 29.780781898734176, -1.473953620689655 ], [ 29.781051392405065, -1.473953620689655 ], [ 29.781051392405065, -1.473684137931035 ], [ 29.780781898734176, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28299, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.473684137931035 ], [ 29.781051392405065, -1.473953620689655 ], [ 29.78132088607595, -1.473953620689655 ], [ 29.78132088607595, -1.473684137931035 ], [ 29.781051392405065, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28300, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.473684137931035 ], [ 29.78132088607595, -1.473953620689655 ], [ 29.781590379746834, -1.473953620689655 ], [ 29.781590379746834, -1.473684137931035 ], [ 29.78132088607595, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28301, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.473684137931035 ], [ 29.781590379746834, -1.473953620689655 ], [ 29.781859873417723, -1.473953620689655 ], [ 29.781859873417723, -1.473684137931035 ], [ 29.781590379746834, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28302, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.473684137931035 ], [ 29.781859873417723, -1.473953620689655 ], [ 29.782129367088608, -1.473953620689655 ], [ 29.782129367088608, -1.473684137931035 ], [ 29.781859873417723, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28303, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.473684137931035 ], [ 29.782129367088608, -1.473953620689655 ], [ 29.782398860759493, -1.473953620689655 ], [ 29.782398860759493, -1.473684137931035 ], [ 29.782129367088608, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28304, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.473684137931035 ], [ 29.782398860759493, -1.473953620689655 ], [ 29.782668354430381, -1.473953620689655 ], [ 29.782668354430381, -1.473684137931035 ], [ 29.782398860759493, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28305, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.473684137931035 ], [ 29.782668354430381, -1.473953620689655 ], [ 29.782937848101266, -1.473953620689655 ], [ 29.782937848101266, -1.473684137931035 ], [ 29.782668354430381, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28306, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.473684137931035 ], [ 29.782937848101266, -1.473953620689655 ], [ 29.783207341772151, -1.473953620689655 ], [ 29.783207341772151, -1.473684137931035 ], [ 29.782937848101266, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28307, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.473684137931035 ], [ 29.783207341772151, -1.473953620689655 ], [ 29.783476835443039, -1.473953620689655 ], [ 29.783476835443039, -1.473684137931035 ], [ 29.783207341772151, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28308, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.473684137931035 ], [ 29.783476835443039, -1.473953620689655 ], [ 29.784015822784809, -1.473953620689655 ], [ 29.784015822784809, -1.473684137931035 ], [ 29.783476835443039, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28309, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.473684137931035 ], [ 29.784285316455698, -1.473953620689655 ], [ 29.784554810126583, -1.473953620689655 ], [ 29.784554810126583, -1.473684137931035 ], [ 29.784285316455698, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28310, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.473684137931035 ], [ 29.784824303797468, -1.473953620689655 ], [ 29.785093797468356, -1.473953620689655 ], [ 29.785093797468356, -1.473684137931035 ], [ 29.784824303797468, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28311, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.473684137931035 ], [ 29.785093797468356, -1.473953620689655 ], [ 29.785363291139241, -1.473953620689655 ], [ 29.785363291139241, -1.473684137931035 ], [ 29.785093797468356, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28312, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.473684137931035 ], [ 29.785363291139241, -1.473953620689655 ], [ 29.785632784810126, -1.473953620689655 ], [ 29.785632784810126, -1.473684137931035 ], [ 29.785363291139241, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28313, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.473684137931035 ], [ 29.785902278481014, -1.473953620689655 ], [ 29.786171772151899, -1.473953620689655 ], [ 29.786171772151899, -1.473684137931035 ], [ 29.785902278481014, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28314, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.473684137931035 ], [ 29.786171772151899, -1.473953620689655 ], [ 29.786441265822784, -1.473953620689655 ], [ 29.786441265822784, -1.473684137931035 ], [ 29.786171772151899, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28315, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.473684137931035 ], [ 29.786441265822784, -1.473953620689655 ], [ 29.786710759493673, -1.473953620689655 ], [ 29.786710759493673, -1.473684137931035 ], [ 29.786441265822784, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28316, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.473684137931035 ], [ 29.786710759493673, -1.473953620689655 ], [ 29.786980253164558, -1.473953620689655 ], [ 29.786980253164558, -1.473684137931035 ], [ 29.786710759493673, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28317, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.473684137931035 ], [ 29.786980253164558, -1.473953620689655 ], [ 29.787249746835442, -1.473953620689655 ], [ 29.787249746835442, -1.473684137931035 ], [ 29.786980253164558, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28318, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.473684137931035 ], [ 29.787249746835442, -1.473953620689655 ], [ 29.787519240506327, -1.473953620689655 ], [ 29.787519240506327, -1.473684137931035 ], [ 29.787249746835442, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28319, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.473684137931035 ], [ 29.787519240506327, -1.473953620689655 ], [ 29.787788734177216, -1.473953620689655 ], [ 29.787788734177216, -1.473684137931035 ], [ 29.787519240506327, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28320, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.473684137931035 ], [ 29.787788734177216, -1.473953620689655 ], [ 29.788058227848101, -1.473953620689655 ], [ 29.788058227848101, -1.473684137931035 ], [ 29.787788734177216, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28321, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.473684137931035 ], [ 29.788058227848101, -1.473953620689655 ], [ 29.788327721518986, -1.473953620689655 ], [ 29.788327721518986, -1.473684137931035 ], [ 29.788058227848101, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28322, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.473684137931035 ], [ 29.788327721518986, -1.473953620689655 ], [ 29.788597215189874, -1.473953620689655 ], [ 29.788597215189874, -1.473684137931035 ], [ 29.788327721518986, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28323, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.473684137931035 ], [ 29.788597215189874, -1.473953620689655 ], [ 29.788866708860759, -1.473953620689655 ], [ 29.788866708860759, -1.473684137931035 ], [ 29.788597215189874, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28324, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.473684137931035 ], [ 29.788866708860759, -1.473953620689655 ], [ 29.789136202531644, -1.473953620689655 ], [ 29.789136202531644, -1.473684137931035 ], [ 29.788866708860759, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28325, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.473684137931035 ], [ 29.789136202531644, -1.473953620689655 ], [ 29.789405696202532, -1.473953620689655 ], [ 29.789405696202532, -1.473684137931035 ], [ 29.789136202531644, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28326, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.473684137931035 ], [ 29.789405696202532, -1.473953620689655 ], [ 29.789675189873417, -1.473953620689655 ], [ 29.789675189873417, -1.473684137931035 ], [ 29.789405696202532, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28327, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.473684137931035 ], [ 29.789675189873417, -1.473953620689655 ], [ 29.789944683544302, -1.473953620689655 ], [ 29.789944683544302, -1.473684137931035 ], [ 29.789675189873417, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28328, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.473684137931035 ], [ 29.789944683544302, -1.473953620689655 ], [ 29.790214177215191, -1.473953620689655 ], [ 29.790214177215191, -1.473684137931035 ], [ 29.789944683544302, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28329, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.473684137931035 ], [ 29.790214177215191, -1.473953620689655 ], [ 29.790483670886076, -1.473953620689655 ], [ 29.790483670886076, -1.473684137931035 ], [ 29.790214177215191, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28330, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.471258793103448 ], [ 29.799376962025317, -1.472336724137931 ], [ 29.799646455696202, -1.472336724137931 ], [ 29.799646455696202, -1.474492586206897 ], [ 29.79991594936709, -1.474492586206897 ], [ 29.79991594936709, -1.47206724137931 ], [ 29.799646455696202, -1.47206724137931 ], [ 29.799646455696202, -1.471258793103448 ], [ 29.799376962025317, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28331, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.47179775862069 ], [ 29.800724430379748, -1.475031551724138 ], [ 29.800993924050633, -1.475031551724138 ], [ 29.800993924050633, -1.47179775862069 ], [ 29.800724430379748, -1.47179775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28332, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.47206724137931 ], [ 29.80045493670886, -1.474762068965517 ], [ 29.800724430379748, -1.474762068965517 ], [ 29.800724430379748, -1.47206724137931 ], [ 29.80045493670886, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28333, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.47206724137931 ], [ 29.800993924050633, -1.474762068965517 ], [ 29.801263417721518, -1.474762068965517 ], [ 29.801263417721518, -1.47206724137931 ], [ 29.800993924050633, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28334, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.47206724137931 ], [ 29.801263417721518, -1.474762068965517 ], [ 29.801532911392407, -1.474762068965517 ], [ 29.801532911392407, -1.47206724137931 ], [ 29.801263417721518, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28335, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.47206724137931 ], [ 29.802071898734177, -1.474762068965517 ], [ 29.802341392405065, -1.474762068965517 ], [ 29.802341392405065, -1.47206724137931 ], [ 29.802071898734177, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28336, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.47206724137931 ], [ 29.802341392405065, -1.474762068965517 ], [ 29.80261088607595, -1.474762068965517 ], [ 29.80261088607595, -1.47206724137931 ], [ 29.802341392405065, -1.47206724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28337, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.472336724137931 ], [ 29.800185443037975, -1.474492586206897 ], [ 29.80045493670886, -1.474492586206897 ], [ 29.80045493670886, -1.472336724137931 ], [ 29.800185443037975, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28338, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.472336724137931 ], [ 29.801532911392407, -1.474492586206897 ], [ 29.802071898734177, -1.474492586206897 ], [ 29.802071898734177, -1.472336724137931 ], [ 29.801532911392407, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28339, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.472336724137931 ], [ 29.80261088607595, -1.474492586206897 ], [ 29.802880379746835, -1.474492586206897 ], [ 29.802880379746835, -1.472336724137931 ], [ 29.80261088607595, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28340, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.472336724137931 ], [ 29.802880379746835, -1.474492586206897 ], [ 29.803149873417723, -1.474492586206897 ], [ 29.803149873417723, -1.472336724137931 ], [ 29.802880379746835, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28341, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.472336724137931 ], [ 29.803149873417723, -1.474492586206897 ], [ 29.803419367088608, -1.474492586206897 ], [ 29.803419367088608, -1.472336724137931 ], [ 29.803149873417723, -1.472336724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28342, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.472606206896552 ], [ 29.760030886075949, -1.474762068965517 ], [ 29.760300379746834, -1.474762068965517 ], [ 29.760300379746834, -1.472606206896552 ], [ 29.760030886075949, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28343, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.472606206896552 ], [ 29.760839367088607, -1.474492586206897 ], [ 29.761108860759492, -1.474492586206897 ], [ 29.761108860759492, -1.472606206896552 ], [ 29.760839367088607, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28344, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.472606206896552 ], [ 29.761378354430381, -1.474223103448276 ], [ 29.761647848101266, -1.474223103448276 ], [ 29.761647848101266, -1.472606206896552 ], [ 29.761378354430381, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28345, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.472606206896552 ], [ 29.761647848101266, -1.474223103448276 ], [ 29.761917341772151, -1.474223103448276 ], [ 29.761917341772151, -1.472606206896552 ], [ 29.761647848101266, -1.472606206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28346, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.472875689655172 ], [ 29.758683417721517, -1.475301034482759 ], [ 29.758952911392406, -1.475301034482759 ], [ 29.758952911392406, -1.472875689655172 ], [ 29.758683417721517, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28347, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.472875689655172 ], [ 29.758952911392406, -1.475301034482759 ], [ 29.759222405063291, -1.475301034482759 ], [ 29.759222405063291, -1.472875689655172 ], [ 29.758952911392406, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28348, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.472875689655172 ], [ 29.759491898734176, -1.475031551724138 ], [ 29.759761392405064, -1.475031551724138 ], [ 29.759761392405064, -1.472875689655172 ], [ 29.759491898734176, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28349, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.472875689655172 ], [ 29.759761392405064, -1.475031551724138 ], [ 29.760030886075949, -1.475031551724138 ], [ 29.760030886075949, -1.472875689655172 ], [ 29.759761392405064, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28350, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.472875689655172 ], [ 29.760300379746834, -1.474762068965517 ], [ 29.760569873417722, -1.474762068965517 ], [ 29.760569873417722, -1.472875689655172 ], [ 29.760300379746834, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28351, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.472875689655172 ], [ 29.761108860759492, -1.474223103448276 ], [ 29.761378354430381, -1.474223103448276 ], [ 29.761378354430381, -1.472875689655172 ], [ 29.761108860759492, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28352, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.472875689655172 ], [ 29.7667682278481, -1.474223103448276 ], [ 29.767037721518985, -1.474223103448276 ], [ 29.767037721518985, -1.472875689655172 ], [ 29.7667682278481, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28353, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.472875689655172 ], [ 29.798568481012659, -1.475031551724138 ], [ 29.798837974683543, -1.475031551724138 ], [ 29.798837974683543, -1.472875689655172 ], [ 29.798568481012659, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28354, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.472875689655172 ], [ 29.799376962025317, -1.474762068965517 ], [ 29.799646455696202, -1.474762068965517 ], [ 29.799646455696202, -1.472875689655172 ], [ 29.799376962025317, -1.472875689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28355, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.757874936708859, -1.473145172413793 ], [ 29.757874936708859, -1.47584 ], [ 29.758144430379748, -1.47584 ], [ 29.758144430379748, -1.473145172413793 ], [ 29.757874936708859, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28356, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.473145172413793 ], [ 29.758144430379748, -1.475570517241379 ], [ 29.758413924050632, -1.475570517241379 ], [ 29.758413924050632, -1.473145172413793 ], [ 29.758144430379748, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28357, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.473145172413793 ], [ 29.758413924050632, -1.475570517241379 ], [ 29.758683417721517, -1.475570517241379 ], [ 29.758683417721517, -1.473145172413793 ], [ 29.758413924050632, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28358, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.473145172413793 ], [ 29.759222405063291, -1.475031551724138 ], [ 29.759491898734176, -1.475031551724138 ], [ 29.759491898734176, -1.473145172413793 ], [ 29.759222405063291, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28359, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.473145172413793 ], [ 29.760569873417722, -1.474492586206897 ], [ 29.760839367088607, -1.474492586206897 ], [ 29.760839367088607, -1.473145172413793 ], [ 29.760569873417722, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28360, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.473145172413793 ], [ 29.766229240506327, -1.474223103448276 ], [ 29.766498734177215, -1.474223103448276 ], [ 29.766498734177215, -1.473145172413793 ], [ 29.766229240506327, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28361, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.473145172413793 ], [ 29.766498734177215, -1.474223103448276 ], [ 29.7667682278481, -1.474223103448276 ], [ 29.7667682278481, -1.473145172413793 ], [ 29.766498734177215, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28362, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.473145172413793 ], [ 29.797221012658227, -1.47584 ], [ 29.797490506329115, -1.47584 ], [ 29.797490506329115, -1.473145172413793 ], [ 29.797221012658227, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28363, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.473145172413793 ], [ 29.797490506329115, -1.475570517241379 ], [ 29.79776, -1.475570517241379 ], [ 29.79776, -1.473145172413793 ], [ 29.797490506329115, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28364, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.473145172413793 ], [ 29.798029493670885, -1.475301034482759 ], [ 29.798298987341774, -1.475301034482759 ], [ 29.798298987341774, -1.473145172413793 ], [ 29.798029493670885, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28365, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.473145172413793 ], [ 29.798298987341774, -1.475301034482759 ], [ 29.798568481012659, -1.475301034482759 ], [ 29.798568481012659, -1.473145172413793 ], [ 29.798298987341774, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28366, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.473145172413793 ], [ 29.798837974683543, -1.475031551724138 ], [ 29.799107468354432, -1.475031551724138 ], [ 29.799107468354432, -1.473145172413793 ], [ 29.798837974683543, -1.473145172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28367, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.473414655172414 ], [ 29.765151265822784, -1.474223103448276 ], [ 29.765420759493672, -1.474223103448276 ], [ 29.765420759493672, -1.473414655172414 ], [ 29.765151265822784, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28368, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.473414655172414 ], [ 29.765420759493672, -1.474492586206897 ], [ 29.765959746835442, -1.474492586206897 ], [ 29.765959746835442, -1.473414655172414 ], [ 29.765420759493672, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28369, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.473414655172414 ], [ 29.765959746835442, -1.474492586206897 ], [ 29.766229240506327, -1.474492586206897 ], [ 29.766229240506327, -1.473414655172414 ], [ 29.765959746835442, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28370, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.473414655172414 ], [ 29.791022658227849, -1.474762068965517 ], [ 29.791292151898734, -1.474762068965517 ], [ 29.791292151898734, -1.473414655172414 ], [ 29.791022658227849, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28371, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.473414655172414 ], [ 29.796682025316457, -1.476109482758621 ], [ 29.796951518987342, -1.476109482758621 ], [ 29.796951518987342, -1.473414655172414 ], [ 29.796682025316457, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28372, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.473414655172414 ], [ 29.796951518987342, -1.476109482758621 ], [ 29.797221012658227, -1.476109482758621 ], [ 29.797221012658227, -1.473414655172414 ], [ 29.796951518987342, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28373, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.473414655172414 ], [ 29.79776, -1.475570517241379 ], [ 29.798029493670885, -1.475570517241379 ], [ 29.798029493670885, -1.473414655172414 ], [ 29.79776, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28374, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.473414655172414 ], [ 29.799107468354432, -1.474762068965517 ], [ 29.799376962025317, -1.474762068965517 ], [ 29.799376962025317, -1.473414655172414 ], [ 29.799107468354432, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28375, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.473684137931035 ], [ 29.763803797468356, -1.474223103448276 ], [ 29.76407329113924, -1.474223103448276 ], [ 29.76407329113924, -1.473684137931035 ], [ 29.763803797468356, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28376, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.473684137931035 ], [ 29.764612278481014, -1.474492586206897 ], [ 29.764881772151899, -1.474492586206897 ], [ 29.764881772151899, -1.473684137931035 ], [ 29.764612278481014, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28377, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.473684137931035 ], [ 29.764881772151899, -1.474492586206897 ], [ 29.765151265822784, -1.474492586206897 ], [ 29.765151265822784, -1.473684137931035 ], [ 29.764881772151899, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28378, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.473684137931035 ], [ 29.770271645569618, -1.474492586206897 ], [ 29.770541139240507, -1.474492586206897 ], [ 29.770541139240507, -1.473684137931035 ], [ 29.770271645569618, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28379, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.473684137931035 ], [ 29.770541139240507, -1.474223103448276 ], [ 29.770810632911392, -1.474223103448276 ], [ 29.770810632911392, -1.473684137931035 ], [ 29.770541139240507, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28380, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.473684137931035 ], [ 29.770810632911392, -1.474223103448276 ], [ 29.771080126582277, -1.474223103448276 ], [ 29.771080126582277, -1.473684137931035 ], [ 29.770810632911392, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28381, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.473684137931035 ], [ 29.77647, -1.474223103448276 ], [ 29.776739493670885, -1.474223103448276 ], [ 29.776739493670885, -1.473684137931035 ], [ 29.77647, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28382, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.473684137931035 ], [ 29.777278481012658, -1.474223103448276 ], [ 29.777547974683543, -1.474223103448276 ], [ 29.777547974683543, -1.473684137931035 ], [ 29.777278481012658, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28383, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.473684137931035 ], [ 29.778086962025316, -1.474223103448276 ], [ 29.778356455696201, -1.474223103448276 ], [ 29.778356455696201, -1.473684137931035 ], [ 29.778086962025316, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28384, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.473684137931035 ], [ 29.77862594936709, -1.474223103448276 ], [ 29.778895443037975, -1.474223103448276 ], [ 29.778895443037975, -1.473684137931035 ], [ 29.77862594936709, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28385, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.473684137931035 ], [ 29.778895443037975, -1.474223103448276 ], [ 29.77916493670886, -1.474223103448276 ], [ 29.77916493670886, -1.473684137931035 ], [ 29.778895443037975, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28386, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.473684137931035 ], [ 29.779434430379748, -1.474223103448276 ], [ 29.779703924050633, -1.474223103448276 ], [ 29.779703924050633, -1.473684137931035 ], [ 29.779434430379748, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28387, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.473684137931035 ], [ 29.790483670886076, -1.474762068965517 ], [ 29.790753164556961, -1.474762068965517 ], [ 29.790753164556961, -1.473684137931035 ], [ 29.790483670886076, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28388, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.473684137931035 ], [ 29.790753164556961, -1.474762068965517 ], [ 29.791022658227849, -1.474762068965517 ], [ 29.791022658227849, -1.473684137931035 ], [ 29.790753164556961, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28389, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.473953620689655 ], [ 29.761917341772151, -1.474223103448276 ], [ 29.762186835443039, -1.474223103448276 ], [ 29.762186835443039, -1.473953620689655 ], [ 29.761917341772151, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28390, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.473953620689655 ], [ 29.762186835443039, -1.474223103448276 ], [ 29.762456329113924, -1.474223103448276 ], [ 29.762456329113924, -1.473953620689655 ], [ 29.762186835443039, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28391, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.473953620689655 ], [ 29.762456329113924, -1.474492586206897 ], [ 29.762725822784809, -1.474492586206897 ], [ 29.762725822784809, -1.473953620689655 ], [ 29.762456329113924, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28392, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.473953620689655 ], [ 29.762725822784809, -1.474762068965517 ], [ 29.762995316455697, -1.474762068965517 ], [ 29.762995316455697, -1.473953620689655 ], [ 29.762725822784809, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28393, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.473953620689655 ], [ 29.762995316455697, -1.474223103448276 ], [ 29.763264810126582, -1.474223103448276 ], [ 29.763264810126582, -1.473953620689655 ], [ 29.762995316455697, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28394, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.473953620689655 ], [ 29.763264810126582, -1.474492586206897 ], [ 29.763534303797467, -1.474492586206897 ], [ 29.763534303797467, -1.473953620689655 ], [ 29.763264810126582, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28395, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.473953620689655 ], [ 29.763534303797467, -1.474762068965517 ], [ 29.763803797468356, -1.474762068965517 ], [ 29.763803797468356, -1.473953620689655 ], [ 29.763534303797467, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28396, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.473953620689655 ], [ 29.76407329113924, -1.474492586206897 ], [ 29.764342784810125, -1.474492586206897 ], [ 29.764342784810125, -1.473953620689655 ], [ 29.76407329113924, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28397, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.473953620689655 ], [ 29.764342784810125, -1.474762068965517 ], [ 29.764612278481014, -1.474762068965517 ], [ 29.764612278481014, -1.473953620689655 ], [ 29.764342784810125, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28398, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.473953620689655 ], [ 29.771080126582277, -1.474223103448276 ], [ 29.771349620253165, -1.474223103448276 ], [ 29.771349620253165, -1.473953620689655 ], [ 29.771080126582277, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28399, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.473953620689655 ], [ 29.771349620253165, -1.474223103448276 ], [ 29.77161911392405, -1.474223103448276 ], [ 29.77161911392405, -1.473953620689655 ], [ 29.771349620253165, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28400, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.473953620689655 ], [ 29.77161911392405, -1.474223103448276 ], [ 29.771888607594935, -1.474223103448276 ], [ 29.771888607594935, -1.473953620689655 ], [ 29.77161911392405, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28401, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.473953620689655 ], [ 29.771888607594935, -1.474223103448276 ], [ 29.772158101265823, -1.474223103448276 ], [ 29.772158101265823, -1.473953620689655 ], [ 29.771888607594935, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28402, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.473953620689655 ], [ 29.772158101265823, -1.474223103448276 ], [ 29.772427594936708, -1.474223103448276 ], [ 29.772427594936708, -1.473953620689655 ], [ 29.772158101265823, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28403, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.473953620689655 ], [ 29.772427594936708, -1.474223103448276 ], [ 29.772697088607593, -1.474223103448276 ], [ 29.772697088607593, -1.473953620689655 ], [ 29.772427594936708, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28404, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.473953620689655 ], [ 29.772697088607593, -1.474223103448276 ], [ 29.772966582278482, -1.474223103448276 ], [ 29.772966582278482, -1.473953620689655 ], [ 29.772697088607593, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28405, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.473953620689655 ], [ 29.772966582278482, -1.474223103448276 ], [ 29.773236075949367, -1.474223103448276 ], [ 29.773236075949367, -1.473953620689655 ], [ 29.772966582278482, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28406, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.473953620689655 ], [ 29.773236075949367, -1.474223103448276 ], [ 29.773505569620252, -1.474223103448276 ], [ 29.773505569620252, -1.473953620689655 ], [ 29.773236075949367, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28407, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.473953620689655 ], [ 29.773505569620252, -1.474223103448276 ], [ 29.77377506329114, -1.474223103448276 ], [ 29.77377506329114, -1.473953620689655 ], [ 29.773505569620252, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28408, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.473953620689655 ], [ 29.77377506329114, -1.474223103448276 ], [ 29.774044556962025, -1.474223103448276 ], [ 29.774044556962025, -1.473953620689655 ], [ 29.77377506329114, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28409, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.473953620689655 ], [ 29.774044556962025, -1.474223103448276 ], [ 29.77431405063291, -1.474223103448276 ], [ 29.77431405063291, -1.473953620689655 ], [ 29.774044556962025, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28410, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.473953620689655 ], [ 29.77431405063291, -1.474492586206897 ], [ 29.774853037974683, -1.474492586206897 ], [ 29.774853037974683, -1.473953620689655 ], [ 29.77431405063291, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28411, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.473953620689655 ], [ 29.774853037974683, -1.474223103448276 ], [ 29.775122531645568, -1.474223103448276 ], [ 29.775122531645568, -1.473953620689655 ], [ 29.774853037974683, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28412, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.473953620689655 ], [ 29.775122531645568, -1.474223103448276 ], [ 29.775392025316457, -1.474223103448276 ], [ 29.775392025316457, -1.473953620689655 ], [ 29.775122531645568, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28413, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.473953620689655 ], [ 29.775392025316457, -1.474492586206897 ], [ 29.775661518987341, -1.474492586206897 ], [ 29.775661518987341, -1.473953620689655 ], [ 29.775392025316457, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28414, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.473953620689655 ], [ 29.775661518987341, -1.474223103448276 ], [ 29.775931012658226, -1.474223103448276 ], [ 29.775931012658226, -1.473953620689655 ], [ 29.775661518987341, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28415, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.473953620689655 ], [ 29.775931012658226, -1.474223103448276 ], [ 29.776200506329115, -1.474223103448276 ], [ 29.776200506329115, -1.473953620689655 ], [ 29.775931012658226, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28416, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.473953620689655 ], [ 29.776200506329115, -1.474492586206897 ], [ 29.77647, -1.474492586206897 ], [ 29.77647, -1.473953620689655 ], [ 29.776200506329115, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28417, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.473953620689655 ], [ 29.776739493670885, -1.474223103448276 ], [ 29.777008987341773, -1.474223103448276 ], [ 29.777008987341773, -1.473953620689655 ], [ 29.776739493670885, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28418, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.473953620689655 ], [ 29.777008987341773, -1.474492586206897 ], [ 29.777278481012658, -1.474492586206897 ], [ 29.777278481012658, -1.473953620689655 ], [ 29.777008987341773, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28419, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.473953620689655 ], [ 29.777547974683543, -1.474492586206897 ], [ 29.777817468354431, -1.474492586206897 ], [ 29.777817468354431, -1.473953620689655 ], [ 29.777547974683543, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28420, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.473953620689655 ], [ 29.777817468354431, -1.474762068965517 ], [ 29.778086962025316, -1.474762068965517 ], [ 29.778086962025316, -1.473953620689655 ], [ 29.777817468354431, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28421, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.473953620689655 ], [ 29.778356455696201, -1.474492586206897 ], [ 29.77862594936709, -1.474492586206897 ], [ 29.77862594936709, -1.473953620689655 ], [ 29.778356455696201, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28422, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.473953620689655 ], [ 29.77916493670886, -1.474762068965517 ], [ 29.779703924050633, -1.474762068965517 ], [ 29.779703924050633, -1.474492586206897 ], [ 29.779434430379748, -1.474492586206897 ], [ 29.779434430379748, -1.473953620689655 ], [ 29.77916493670886, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28423, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.473953620689655 ], [ 29.779703924050633, -1.474223103448276 ], [ 29.779973417721518, -1.474223103448276 ], [ 29.779973417721518, -1.473953620689655 ], [ 29.779703924050633, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28424, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.473953620689655 ], [ 29.779973417721518, -1.474223103448276 ], [ 29.780242911392406, -1.474223103448276 ], [ 29.780242911392406, -1.473953620689655 ], [ 29.779973417721518, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28425, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.473953620689655 ], [ 29.780242911392406, -1.474223103448276 ], [ 29.780512405063291, -1.474223103448276 ], [ 29.780512405063291, -1.473953620689655 ], [ 29.780242911392406, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28426, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.473953620689655 ], [ 29.780512405063291, -1.474223103448276 ], [ 29.780781898734176, -1.474223103448276 ], [ 29.780781898734176, -1.473953620689655 ], [ 29.780512405063291, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28427, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.473953620689655 ], [ 29.780781898734176, -1.474223103448276 ], [ 29.781051392405065, -1.474223103448276 ], [ 29.781051392405065, -1.473953620689655 ], [ 29.780781898734176, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28428, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.473953620689655 ], [ 29.781051392405065, -1.474223103448276 ], [ 29.78132088607595, -1.474223103448276 ], [ 29.78132088607595, -1.473953620689655 ], [ 29.781051392405065, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28429, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.473953620689655 ], [ 29.78132088607595, -1.474223103448276 ], [ 29.781590379746834, -1.474223103448276 ], [ 29.781590379746834, -1.473953620689655 ], [ 29.78132088607595, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28430, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.473953620689655 ], [ 29.781590379746834, -1.474223103448276 ], [ 29.781859873417723, -1.474223103448276 ], [ 29.781859873417723, -1.473953620689655 ], [ 29.781590379746834, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28431, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.473953620689655 ], [ 29.781859873417723, -1.474223103448276 ], [ 29.782129367088608, -1.474223103448276 ], [ 29.782129367088608, -1.473953620689655 ], [ 29.781859873417723, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28432, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.473953620689655 ], [ 29.782129367088608, -1.474223103448276 ], [ 29.782398860759493, -1.474223103448276 ], [ 29.782398860759493, -1.473953620689655 ], [ 29.782129367088608, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28433, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.473953620689655 ], [ 29.782398860759493, -1.474223103448276 ], [ 29.782668354430381, -1.474223103448276 ], [ 29.782668354430381, -1.473953620689655 ], [ 29.782398860759493, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28434, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.473953620689655 ], [ 29.782668354430381, -1.474223103448276 ], [ 29.782937848101266, -1.474223103448276 ], [ 29.782937848101266, -1.473953620689655 ], [ 29.782668354430381, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28435, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.473953620689655 ], [ 29.782937848101266, -1.474223103448276 ], [ 29.783207341772151, -1.474223103448276 ], [ 29.783207341772151, -1.473953620689655 ], [ 29.782937848101266, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28436, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.473953620689655 ], [ 29.783207341772151, -1.474223103448276 ], [ 29.783476835443039, -1.474223103448276 ], [ 29.783476835443039, -1.473953620689655 ], [ 29.783207341772151, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28437, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.473953620689655 ], [ 29.783476835443039, -1.474223103448276 ], [ 29.784015822784809, -1.474223103448276 ], [ 29.784015822784809, -1.473953620689655 ], [ 29.783476835443039, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28438, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.473953620689655 ], [ 29.784015822784809, -1.474223103448276 ], [ 29.784285316455698, -1.474223103448276 ], [ 29.784285316455698, -1.473953620689655 ], [ 29.784015822784809, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28439, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.473684137931035 ], [ 29.784824303797468, -1.473684137931035 ], [ 29.784824303797468, -1.474223103448276 ], [ 29.784285316455698, -1.474223103448276 ], [ 29.784285316455698, -1.473953620689655 ], [ 29.784554810126583, -1.473953620689655 ], [ 29.784554810126583, -1.473684137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28440, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.473953620689655 ], [ 29.784824303797468, -1.474223103448276 ], [ 29.785093797468356, -1.474223103448276 ], [ 29.785093797468356, -1.473953620689655 ], [ 29.784824303797468, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28441, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.473953620689655 ], [ 29.785093797468356, -1.474223103448276 ], [ 29.785363291139241, -1.474223103448276 ], [ 29.785363291139241, -1.473953620689655 ], [ 29.785093797468356, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28442, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.473953620689655 ], [ 29.785363291139241, -1.474223103448276 ], [ 29.785632784810126, -1.474223103448276 ], [ 29.785632784810126, -1.473953620689655 ], [ 29.785363291139241, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28443, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.473953620689655 ], [ 29.785632784810126, -1.474223103448276 ], [ 29.785902278481014, -1.474223103448276 ], [ 29.785902278481014, -1.473953620689655 ], [ 29.785632784810126, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28444, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.473953620689655 ], [ 29.785902278481014, -1.474223103448276 ], [ 29.786171772151899, -1.474223103448276 ], [ 29.786171772151899, -1.473953620689655 ], [ 29.785902278481014, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28445, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.473953620689655 ], [ 29.786171772151899, -1.474223103448276 ], [ 29.786441265822784, -1.474223103448276 ], [ 29.786441265822784, -1.473953620689655 ], [ 29.786171772151899, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28446, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.473953620689655 ], [ 29.786441265822784, -1.474223103448276 ], [ 29.786710759493673, -1.474223103448276 ], [ 29.786710759493673, -1.473953620689655 ], [ 29.786441265822784, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28447, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.473953620689655 ], [ 29.786710759493673, -1.474223103448276 ], [ 29.786980253164558, -1.474223103448276 ], [ 29.786980253164558, -1.473953620689655 ], [ 29.786710759493673, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28448, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.473953620689655 ], [ 29.786980253164558, -1.474223103448276 ], [ 29.787249746835442, -1.474223103448276 ], [ 29.787249746835442, -1.473953620689655 ], [ 29.786980253164558, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28449, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.473953620689655 ], [ 29.787249746835442, -1.474223103448276 ], [ 29.787519240506327, -1.474223103448276 ], [ 29.787519240506327, -1.473953620689655 ], [ 29.787249746835442, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28450, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.473953620689655 ], [ 29.787519240506327, -1.474223103448276 ], [ 29.787788734177216, -1.474223103448276 ], [ 29.787788734177216, -1.473953620689655 ], [ 29.787519240506327, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28451, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.473953620689655 ], [ 29.787788734177216, -1.474223103448276 ], [ 29.788058227848101, -1.474223103448276 ], [ 29.788058227848101, -1.473953620689655 ], [ 29.787788734177216, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28452, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.473953620689655 ], [ 29.788058227848101, -1.474223103448276 ], [ 29.788327721518986, -1.474223103448276 ], [ 29.788327721518986, -1.473953620689655 ], [ 29.788058227848101, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28453, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.473953620689655 ], [ 29.788327721518986, -1.474223103448276 ], [ 29.788597215189874, -1.474223103448276 ], [ 29.788597215189874, -1.473953620689655 ], [ 29.788327721518986, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28454, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.473953620689655 ], [ 29.788597215189874, -1.474223103448276 ], [ 29.788866708860759, -1.474223103448276 ], [ 29.788866708860759, -1.473953620689655 ], [ 29.788597215189874, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28455, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.473953620689655 ], [ 29.788866708860759, -1.474223103448276 ], [ 29.789136202531644, -1.474223103448276 ], [ 29.789136202531644, -1.473953620689655 ], [ 29.788866708860759, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28456, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.473953620689655 ], [ 29.789136202531644, -1.474223103448276 ], [ 29.789405696202532, -1.474223103448276 ], [ 29.789405696202532, -1.473953620689655 ], [ 29.789136202531644, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28457, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.473953620689655 ], [ 29.789405696202532, -1.474223103448276 ], [ 29.789675189873417, -1.474223103448276 ], [ 29.789675189873417, -1.473953620689655 ], [ 29.789405696202532, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28458, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.473953620689655 ], [ 29.789675189873417, -1.474762068965517 ], [ 29.789944683544302, -1.474762068965517 ], [ 29.789944683544302, -1.473953620689655 ], [ 29.789675189873417, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28459, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.473953620689655 ], [ 29.789944683544302, -1.475031551724138 ], [ 29.790214177215191, -1.475031551724138 ], [ 29.790214177215191, -1.473953620689655 ], [ 29.789944683544302, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28460, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.473953620689655 ], [ 29.790214177215191, -1.475031551724138 ], [ 29.790483670886076, -1.475031551724138 ], [ 29.790483670886076, -1.473953620689655 ], [ 29.790214177215191, -1.473953620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28461, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.474223103448276 ], [ 29.761108860759492, -1.474492586206897 ], [ 29.761378354430381, -1.474492586206897 ], [ 29.761378354430381, -1.474223103448276 ], [ 29.761108860759492, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28462, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.474223103448276 ], [ 29.761378354430381, -1.474492586206897 ], [ 29.761647848101266, -1.474492586206897 ], [ 29.761647848101266, -1.474223103448276 ], [ 29.761378354430381, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28463, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.474223103448276 ], [ 29.761647848101266, -1.474492586206897 ], [ 29.761917341772151, -1.474492586206897 ], [ 29.761917341772151, -1.474223103448276 ], [ 29.761647848101266, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28464, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.474223103448276 ], [ 29.761917341772151, -1.474492586206897 ], [ 29.762186835443039, -1.474492586206897 ], [ 29.762186835443039, -1.474223103448276 ], [ 29.761917341772151, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28465, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.474223103448276 ], [ 29.762186835443039, -1.474492586206897 ], [ 29.762456329113924, -1.474492586206897 ], [ 29.762456329113924, -1.474223103448276 ], [ 29.762186835443039, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28466, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.474223103448276 ], [ 29.762995316455697, -1.474762068965517 ], [ 29.763264810126582, -1.474762068965517 ], [ 29.763264810126582, -1.474223103448276 ], [ 29.762995316455697, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28467, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.474223103448276 ], [ 29.763803797468356, -1.474762068965517 ], [ 29.76407329113924, -1.474762068965517 ], [ 29.76407329113924, -1.474223103448276 ], [ 29.763803797468356, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28468, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.474223103448276 ], [ 29.765151265822784, -1.474762068965517 ], [ 29.765420759493672, -1.474762068965517 ], [ 29.765420759493672, -1.474223103448276 ], [ 29.765151265822784, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28469, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.474223103448276 ], [ 29.766229240506327, -1.474492586206897 ], [ 29.766498734177215, -1.474492586206897 ], [ 29.766498734177215, -1.474223103448276 ], [ 29.766229240506327, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28470, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.474223103448276 ], [ 29.766498734177215, -1.474492586206897 ], [ 29.7667682278481, -1.474492586206897 ], [ 29.7667682278481, -1.474223103448276 ], [ 29.766498734177215, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28471, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.474223103448276 ], [ 29.7667682278481, -1.474492586206897 ], [ 29.767037721518985, -1.474492586206897 ], [ 29.767037721518985, -1.474223103448276 ], [ 29.7667682278481, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28472, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.474223103448276 ], [ 29.767037721518985, -1.47584 ], [ 29.767307215189874, -1.47584 ], [ 29.767307215189874, -1.474223103448276 ], [ 29.767037721518985, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28473, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.474223103448276 ], [ 29.770541139240507, -1.474492586206897 ], [ 29.770810632911392, -1.474492586206897 ], [ 29.770810632911392, -1.474223103448276 ], [ 29.770541139240507, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28474, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.474223103448276 ], [ 29.770810632911392, -1.474492586206897 ], [ 29.771080126582277, -1.474492586206897 ], [ 29.771080126582277, -1.474223103448276 ], [ 29.770810632911392, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28475, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.474223103448276 ], [ 29.771080126582277, -1.474492586206897 ], [ 29.771349620253165, -1.474492586206897 ], [ 29.771349620253165, -1.474223103448276 ], [ 29.771080126582277, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28476, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.474223103448276 ], [ 29.771349620253165, -1.474492586206897 ], [ 29.77161911392405, -1.474492586206897 ], [ 29.77161911392405, -1.474223103448276 ], [ 29.771349620253165, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28477, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.474223103448276 ], [ 29.77161911392405, -1.474492586206897 ], [ 29.771888607594935, -1.474492586206897 ], [ 29.771888607594935, -1.474223103448276 ], [ 29.77161911392405, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28478, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.474223103448276 ], [ 29.771888607594935, -1.474492586206897 ], [ 29.772158101265823, -1.474492586206897 ], [ 29.772158101265823, -1.474223103448276 ], [ 29.771888607594935, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28479, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.474223103448276 ], [ 29.772158101265823, -1.474492586206897 ], [ 29.772427594936708, -1.474492586206897 ], [ 29.772427594936708, -1.474223103448276 ], [ 29.772158101265823, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28480, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.474223103448276 ], [ 29.772427594936708, -1.474492586206897 ], [ 29.772697088607593, -1.474492586206897 ], [ 29.772697088607593, -1.474223103448276 ], [ 29.772427594936708, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28481, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.474223103448276 ], [ 29.772697088607593, -1.474492586206897 ], [ 29.772966582278482, -1.474492586206897 ], [ 29.772966582278482, -1.474223103448276 ], [ 29.772697088607593, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28482, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.474223103448276 ], [ 29.772966582278482, -1.474492586206897 ], [ 29.773236075949367, -1.474492586206897 ], [ 29.773236075949367, -1.474223103448276 ], [ 29.772966582278482, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28483, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.474223103448276 ], [ 29.773236075949367, -1.474762068965517 ], [ 29.773505569620252, -1.474762068965517 ], [ 29.773505569620252, -1.474223103448276 ], [ 29.773236075949367, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28484, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.474223103448276 ], [ 29.773505569620252, -1.474492586206897 ], [ 29.77377506329114, -1.474492586206897 ], [ 29.77377506329114, -1.474223103448276 ], [ 29.773505569620252, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28485, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.474223103448276 ], [ 29.77377506329114, -1.474492586206897 ], [ 29.774044556962025, -1.474492586206897 ], [ 29.774044556962025, -1.474223103448276 ], [ 29.77377506329114, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28486, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.474223103448276 ], [ 29.774044556962025, -1.474762068965517 ], [ 29.77431405063291, -1.474762068965517 ], [ 29.77431405063291, -1.474223103448276 ], [ 29.774044556962025, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28487, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.474223103448276 ], [ 29.774853037974683, -1.474492586206897 ], [ 29.775122531645568, -1.474492586206897 ], [ 29.775122531645568, -1.474223103448276 ], [ 29.774853037974683, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28488, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.474223103448276 ], [ 29.775122531645568, -1.474762068965517 ], [ 29.775392025316457, -1.474762068965517 ], [ 29.775392025316457, -1.474223103448276 ], [ 29.775122531645568, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28489, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.474223103448276 ], [ 29.775661518987341, -1.474762068965517 ], [ 29.775931012658226, -1.474762068965517 ], [ 29.775931012658226, -1.474223103448276 ], [ 29.775661518987341, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28490, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.474223103448276 ], [ 29.77647, -1.474762068965517 ], [ 29.776739493670885, -1.474762068965517 ], [ 29.776739493670885, -1.474223103448276 ], [ 29.77647, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28491, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.474223103448276 ], [ 29.778895443037975, -1.47584 ], [ 29.77916493670886, -1.47584 ], [ 29.77916493670886, -1.475570517241379 ], [ 29.779434430379748, -1.475570517241379 ], [ 29.779434430379748, -1.475301034482759 ], [ 29.77916493670886, -1.475301034482759 ], [ 29.77916493670886, -1.474223103448276 ], [ 29.778895443037975, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28492, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.474223103448276 ], [ 29.779434430379748, -1.474492586206897 ], [ 29.779703924050633, -1.474492586206897 ], [ 29.779703924050633, -1.474223103448276 ], [ 29.779434430379748, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28493, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.474223103448276 ], [ 29.779703924050633, -1.474492586206897 ], [ 29.779973417721518, -1.474492586206897 ], [ 29.779973417721518, -1.474223103448276 ], [ 29.779703924050633, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28494, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.474223103448276 ], [ 29.779973417721518, -1.474492586206897 ], [ 29.780242911392406, -1.474492586206897 ], [ 29.780242911392406, -1.474223103448276 ], [ 29.779973417721518, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28495, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.474223103448276 ], [ 29.780242911392406, -1.474492586206897 ], [ 29.780512405063291, -1.474492586206897 ], [ 29.780512405063291, -1.474223103448276 ], [ 29.780242911392406, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28496, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.474223103448276 ], [ 29.780512405063291, -1.474492586206897 ], [ 29.780781898734176, -1.474492586206897 ], [ 29.780781898734176, -1.474223103448276 ], [ 29.780512405063291, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28497, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.474223103448276 ], [ 29.780781898734176, -1.474492586206897 ], [ 29.781051392405065, -1.474492586206897 ], [ 29.781051392405065, -1.474223103448276 ], [ 29.780781898734176, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28498, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.474223103448276 ], [ 29.781051392405065, -1.474492586206897 ], [ 29.78132088607595, -1.474492586206897 ], [ 29.78132088607595, -1.474223103448276 ], [ 29.781051392405065, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28499, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.474223103448276 ], [ 29.78132088607595, -1.474492586206897 ], [ 29.781590379746834, -1.474492586206897 ], [ 29.781590379746834, -1.474223103448276 ], [ 29.78132088607595, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28500, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.474223103448276 ], [ 29.781590379746834, -1.474492586206897 ], [ 29.781859873417723, -1.474492586206897 ], [ 29.781859873417723, -1.474223103448276 ], [ 29.781590379746834, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28501, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.474223103448276 ], [ 29.781859873417723, -1.474492586206897 ], [ 29.782129367088608, -1.474492586206897 ], [ 29.782129367088608, -1.474223103448276 ], [ 29.781859873417723, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28502, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.474223103448276 ], [ 29.782129367088608, -1.474492586206897 ], [ 29.782398860759493, -1.474492586206897 ], [ 29.782398860759493, -1.474223103448276 ], [ 29.782129367088608, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28503, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.474223103448276 ], [ 29.782398860759493, -1.474492586206897 ], [ 29.782668354430381, -1.474492586206897 ], [ 29.782668354430381, -1.474223103448276 ], [ 29.782398860759493, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28504, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.474223103448276 ], [ 29.782668354430381, -1.474492586206897 ], [ 29.782937848101266, -1.474492586206897 ], [ 29.782937848101266, -1.474223103448276 ], [ 29.782668354430381, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28505, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.474223103448276 ], [ 29.782937848101266, -1.474492586206897 ], [ 29.783207341772151, -1.474492586206897 ], [ 29.783207341772151, -1.474223103448276 ], [ 29.782937848101266, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28506, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.474223103448276 ], [ 29.783207341772151, -1.474492586206897 ], [ 29.783476835443039, -1.474492586206897 ], [ 29.783476835443039, -1.474223103448276 ], [ 29.783207341772151, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28507, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.474223103448276 ], [ 29.783476835443039, -1.474492586206897 ], [ 29.784015822784809, -1.474492586206897 ], [ 29.784015822784809, -1.474223103448276 ], [ 29.783476835443039, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28508, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.474223103448276 ], [ 29.784015822784809, -1.474492586206897 ], [ 29.784285316455698, -1.474492586206897 ], [ 29.784285316455698, -1.474223103448276 ], [ 29.784015822784809, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28509, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.474223103448276 ], [ 29.784285316455698, -1.474492586206897 ], [ 29.784554810126583, -1.474492586206897 ], [ 29.784554810126583, -1.474223103448276 ], [ 29.784285316455698, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28510, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.474223103448276 ], [ 29.784554810126583, -1.474492586206897 ], [ 29.784824303797468, -1.474492586206897 ], [ 29.784824303797468, -1.474223103448276 ], [ 29.784554810126583, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28511, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.474223103448276 ], [ 29.784824303797468, -1.474492586206897 ], [ 29.785363291139241, -1.474492586206897 ], [ 29.785363291139241, -1.474223103448276 ], [ 29.784824303797468, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28512, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.474223103448276 ], [ 29.785363291139241, -1.474492586206897 ], [ 29.785632784810126, -1.474492586206897 ], [ 29.785632784810126, -1.474223103448276 ], [ 29.785363291139241, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28513, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.474223103448276 ], [ 29.785632784810126, -1.474492586206897 ], [ 29.785902278481014, -1.474492586206897 ], [ 29.785902278481014, -1.474223103448276 ], [ 29.785632784810126, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28514, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.474223103448276 ], [ 29.785902278481014, -1.474492586206897 ], [ 29.786171772151899, -1.474492586206897 ], [ 29.786171772151899, -1.474223103448276 ], [ 29.785902278481014, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28515, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.474223103448276 ], [ 29.786171772151899, -1.474492586206897 ], [ 29.786441265822784, -1.474492586206897 ], [ 29.786441265822784, -1.474223103448276 ], [ 29.786171772151899, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28516, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.474223103448276 ], [ 29.786441265822784, -1.474492586206897 ], [ 29.786710759493673, -1.474492586206897 ], [ 29.786710759493673, -1.474223103448276 ], [ 29.786441265822784, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28517, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.474223103448276 ], [ 29.786710759493673, -1.474492586206897 ], [ 29.786980253164558, -1.474492586206897 ], [ 29.786980253164558, -1.474223103448276 ], [ 29.786710759493673, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28518, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.474223103448276 ], [ 29.786980253164558, -1.474492586206897 ], [ 29.787249746835442, -1.474492586206897 ], [ 29.787249746835442, -1.474223103448276 ], [ 29.786980253164558, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28519, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.474223103448276 ], [ 29.787249746835442, -1.474492586206897 ], [ 29.787519240506327, -1.474492586206897 ], [ 29.787519240506327, -1.474223103448276 ], [ 29.787249746835442, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28520, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.474223103448276 ], [ 29.787519240506327, -1.474492586206897 ], [ 29.787788734177216, -1.474492586206897 ], [ 29.787788734177216, -1.474223103448276 ], [ 29.787519240506327, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28521, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.474223103448276 ], [ 29.787788734177216, -1.474492586206897 ], [ 29.788058227848101, -1.474492586206897 ], [ 29.788058227848101, -1.474223103448276 ], [ 29.787788734177216, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28522, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.474223103448276 ], [ 29.788058227848101, -1.474492586206897 ], [ 29.788327721518986, -1.474492586206897 ], [ 29.788327721518986, -1.474223103448276 ], [ 29.788058227848101, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28523, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.474223103448276 ], [ 29.788327721518986, -1.474762068965517 ], [ 29.788597215189874, -1.474762068965517 ], [ 29.788597215189874, -1.474223103448276 ], [ 29.788327721518986, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28524, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.474223103448276 ], [ 29.788597215189874, -1.474492586206897 ], [ 29.788866708860759, -1.474492586206897 ], [ 29.788866708860759, -1.474223103448276 ], [ 29.788597215189874, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28525, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.474223103448276 ], [ 29.788866708860759, -1.474492586206897 ], [ 29.789136202531644, -1.474492586206897 ], [ 29.789136202531644, -1.474223103448276 ], [ 29.788866708860759, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28526, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.474223103448276 ], [ 29.789136202531644, -1.475031551724138 ], [ 29.789405696202532, -1.475031551724138 ], [ 29.789405696202532, -1.474223103448276 ], [ 29.789136202531644, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28527, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.474223103448276 ], [ 29.789405696202532, -1.475031551724138 ], [ 29.789675189873417, -1.475031551724138 ], [ 29.789675189873417, -1.474223103448276 ], [ 29.789405696202532, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28528, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.474492586206897 ], [ 29.760569873417722, -1.474762068965517 ], [ 29.760839367088607, -1.474762068965517 ], [ 29.760839367088607, -1.474492586206897 ], [ 29.760569873417722, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28529, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.474492586206897 ], [ 29.760839367088607, -1.474762068965517 ], [ 29.761108860759492, -1.474762068965517 ], [ 29.761108860759492, -1.474492586206897 ], [ 29.760839367088607, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28530, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.474492586206897 ], [ 29.761108860759492, -1.474762068965517 ], [ 29.761378354430381, -1.474762068965517 ], [ 29.761378354430381, -1.474492586206897 ], [ 29.761108860759492, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28531, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.474492586206897 ], [ 29.761378354430381, -1.474762068965517 ], [ 29.761647848101266, -1.474762068965517 ], [ 29.761647848101266, -1.474492586206897 ], [ 29.761378354430381, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28532, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.474492586206897 ], [ 29.761647848101266, -1.474762068965517 ], [ 29.761917341772151, -1.474762068965517 ], [ 29.761917341772151, -1.474492586206897 ], [ 29.761647848101266, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28533, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.474492586206897 ], [ 29.761917341772151, -1.474762068965517 ], [ 29.762186835443039, -1.474762068965517 ], [ 29.762186835443039, -1.474492586206897 ], [ 29.761917341772151, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28534, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.474492586206897 ], [ 29.762186835443039, -1.474762068965517 ], [ 29.762456329113924, -1.474762068965517 ], [ 29.762456329113924, -1.474492586206897 ], [ 29.762186835443039, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28535, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.474492586206897 ], [ 29.762456329113924, -1.474762068965517 ], [ 29.762725822784809, -1.474762068965517 ], [ 29.762725822784809, -1.474492586206897 ], [ 29.762456329113924, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28536, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.474492586206897 ], [ 29.763264810126582, -1.475031551724138 ], [ 29.763534303797467, -1.475031551724138 ], [ 29.763534303797467, -1.474492586206897 ], [ 29.763264810126582, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28537, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.474492586206897 ], [ 29.76407329113924, -1.475031551724138 ], [ 29.764342784810125, -1.475031551724138 ], [ 29.764342784810125, -1.474492586206897 ], [ 29.76407329113924, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28538, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.474492586206897 ], [ 29.764612278481014, -1.474762068965517 ], [ 29.764881772151899, -1.474762068965517 ], [ 29.764881772151899, -1.474492586206897 ], [ 29.764612278481014, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28539, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.474492586206897 ], [ 29.764881772151899, -1.474762068965517 ], [ 29.765151265822784, -1.474762068965517 ], [ 29.765151265822784, -1.474492586206897 ], [ 29.764881772151899, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28540, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.474492586206897 ], [ 29.765420759493672, -1.474762068965517 ], [ 29.765959746835442, -1.474762068965517 ], [ 29.765959746835442, -1.474492586206897 ], [ 29.765420759493672, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28541, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.474492586206897 ], [ 29.765959746835442, -1.474762068965517 ], [ 29.766229240506327, -1.474762068965517 ], [ 29.766229240506327, -1.474492586206897 ], [ 29.765959746835442, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28542, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.474492586206897 ], [ 29.766229240506327, -1.474762068965517 ], [ 29.766498734177215, -1.474762068965517 ], [ 29.766498734177215, -1.474492586206897 ], [ 29.766229240506327, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28543, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.474492586206897 ], [ 29.766498734177215, -1.47584 ], [ 29.7667682278481, -1.47584 ], [ 29.7667682278481, -1.474492586206897 ], [ 29.766498734177215, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28544, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.474492586206897 ], [ 29.7667682278481, -1.47584 ], [ 29.767037721518985, -1.47584 ], [ 29.767037721518985, -1.474492586206897 ], [ 29.7667682278481, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28545, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.474492586206897 ], [ 29.770541139240507, -1.475031551724138 ], [ 29.770810632911392, -1.475031551724138 ], [ 29.770810632911392, -1.474492586206897 ], [ 29.770541139240507, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28546, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.474492586206897 ], [ 29.770810632911392, -1.474762068965517 ], [ 29.771080126582277, -1.474762068965517 ], [ 29.771080126582277, -1.474492586206897 ], [ 29.770810632911392, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28547, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.474492586206897 ], [ 29.771080126582277, -1.474762068965517 ], [ 29.771349620253165, -1.474762068965517 ], [ 29.771349620253165, -1.474492586206897 ], [ 29.771080126582277, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28548, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.474492586206897 ], [ 29.771349620253165, -1.474762068965517 ], [ 29.77161911392405, -1.474762068965517 ], [ 29.77161911392405, -1.474492586206897 ], [ 29.771349620253165, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28549, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.474492586206897 ], [ 29.77161911392405, -1.474762068965517 ], [ 29.771888607594935, -1.474762068965517 ], [ 29.771888607594935, -1.474492586206897 ], [ 29.77161911392405, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28550, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.474492586206897 ], [ 29.771888607594935, -1.474762068965517 ], [ 29.772158101265823, -1.474762068965517 ], [ 29.772158101265823, -1.474492586206897 ], [ 29.771888607594935, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28551, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.474492586206897 ], [ 29.772158101265823, -1.475031551724138 ], [ 29.772427594936708, -1.475031551724138 ], [ 29.772427594936708, -1.474492586206897 ], [ 29.772158101265823, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28552, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.474492586206897 ], [ 29.772427594936708, -1.474762068965517 ], [ 29.772697088607593, -1.474762068965517 ], [ 29.772697088607593, -1.474492586206897 ], [ 29.772427594936708, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28553, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.474492586206897 ], [ 29.772697088607593, -1.474762068965517 ], [ 29.772966582278482, -1.474762068965517 ], [ 29.772966582278482, -1.474492586206897 ], [ 29.772697088607593, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28554, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.474492586206897 ], [ 29.773505569620252, -1.475031551724138 ], [ 29.77377506329114, -1.475031551724138 ], [ 29.77377506329114, -1.474492586206897 ], [ 29.773505569620252, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28555, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.474492586206897 ], [ 29.779703924050633, -1.474762068965517 ], [ 29.779973417721518, -1.474762068965517 ], [ 29.779973417721518, -1.474492586206897 ], [ 29.779703924050633, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28556, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.474492586206897 ], [ 29.779973417721518, -1.474762068965517 ], [ 29.780242911392406, -1.474762068965517 ], [ 29.780242911392406, -1.474492586206897 ], [ 29.779973417721518, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28557, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.474492586206897 ], [ 29.780242911392406, -1.474762068965517 ], [ 29.780512405063291, -1.474762068965517 ], [ 29.780512405063291, -1.474492586206897 ], [ 29.780242911392406, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28558, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.474492586206897 ], [ 29.780512405063291, -1.474762068965517 ], [ 29.780781898734176, -1.474762068965517 ], [ 29.780781898734176, -1.474492586206897 ], [ 29.780512405063291, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28559, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.474492586206897 ], [ 29.780781898734176, -1.474762068965517 ], [ 29.781051392405065, -1.474762068965517 ], [ 29.781051392405065, -1.474492586206897 ], [ 29.780781898734176, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28560, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.474492586206897 ], [ 29.781051392405065, -1.474762068965517 ], [ 29.78132088607595, -1.474762068965517 ], [ 29.78132088607595, -1.474492586206897 ], [ 29.781051392405065, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28561, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.474492586206897 ], [ 29.78132088607595, -1.474762068965517 ], [ 29.781590379746834, -1.474762068965517 ], [ 29.781590379746834, -1.474492586206897 ], [ 29.78132088607595, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28562, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.474492586206897 ], [ 29.781590379746834, -1.474762068965517 ], [ 29.781859873417723, -1.474762068965517 ], [ 29.781859873417723, -1.474492586206897 ], [ 29.781590379746834, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28563, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.474492586206897 ], [ 29.781859873417723, -1.474762068965517 ], [ 29.782129367088608, -1.474762068965517 ], [ 29.782129367088608, -1.474492586206897 ], [ 29.781859873417723, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28564, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.474492586206897 ], [ 29.782129367088608, -1.474762068965517 ], [ 29.782398860759493, -1.474762068965517 ], [ 29.782398860759493, -1.474492586206897 ], [ 29.782129367088608, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28565, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.474492586206897 ], [ 29.782398860759493, -1.474762068965517 ], [ 29.782668354430381, -1.474762068965517 ], [ 29.782668354430381, -1.474492586206897 ], [ 29.782398860759493, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28566, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.474492586206897 ], [ 29.782668354430381, -1.474762068965517 ], [ 29.782937848101266, -1.474762068965517 ], [ 29.782937848101266, -1.474492586206897 ], [ 29.782668354430381, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28567, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.474492586206897 ], [ 29.782937848101266, -1.474762068965517 ], [ 29.783207341772151, -1.474762068965517 ], [ 29.783207341772151, -1.474492586206897 ], [ 29.782937848101266, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28568, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.474492586206897 ], [ 29.783207341772151, -1.474762068965517 ], [ 29.783476835443039, -1.474762068965517 ], [ 29.783476835443039, -1.474492586206897 ], [ 29.783207341772151, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28569, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.474492586206897 ], [ 29.783476835443039, -1.474762068965517 ], [ 29.784015822784809, -1.474762068965517 ], [ 29.784015822784809, -1.474492586206897 ], [ 29.783476835443039, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28570, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.474492586206897 ], [ 29.784015822784809, -1.474762068965517 ], [ 29.784285316455698, -1.474762068965517 ], [ 29.784285316455698, -1.474492586206897 ], [ 29.784015822784809, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28571, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.474492586206897 ], [ 29.784285316455698, -1.474762068965517 ], [ 29.784554810126583, -1.474762068965517 ], [ 29.784554810126583, -1.474492586206897 ], [ 29.784285316455698, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28572, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.474492586206897 ], [ 29.784554810126583, -1.474762068965517 ], [ 29.784824303797468, -1.474762068965517 ], [ 29.784824303797468, -1.474492586206897 ], [ 29.784554810126583, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28573, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.474492586206897 ], [ 29.784824303797468, -1.474762068965517 ], [ 29.785093797468356, -1.474762068965517 ], [ 29.785093797468356, -1.474492586206897 ], [ 29.784824303797468, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28574, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.474492586206897 ], [ 29.785093797468356, -1.474762068965517 ], [ 29.785632784810126, -1.474762068965517 ], [ 29.785632784810126, -1.474492586206897 ], [ 29.785093797468356, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28575, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.474492586206897 ], [ 29.785632784810126, -1.474762068965517 ], [ 29.785902278481014, -1.474762068965517 ], [ 29.785902278481014, -1.474492586206897 ], [ 29.785632784810126, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28576, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.474492586206897 ], [ 29.786171772151899, -1.474762068965517 ], [ 29.786441265822784, -1.474762068965517 ], [ 29.786441265822784, -1.474492586206897 ], [ 29.786171772151899, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28577, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.474492586206897 ], [ 29.786441265822784, -1.474762068965517 ], [ 29.786710759493673, -1.474762068965517 ], [ 29.786710759493673, -1.474492586206897 ], [ 29.786441265822784, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28578, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.474492586206897 ], [ 29.786710759493673, -1.474762068965517 ], [ 29.786980253164558, -1.474762068965517 ], [ 29.786980253164558, -1.474492586206897 ], [ 29.786710759493673, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28579, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.474492586206897 ], [ 29.786980253164558, -1.474762068965517 ], [ 29.787249746835442, -1.474762068965517 ], [ 29.787249746835442, -1.474492586206897 ], [ 29.786980253164558, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28580, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.474492586206897 ], [ 29.787249746835442, -1.474762068965517 ], [ 29.787519240506327, -1.474762068965517 ], [ 29.787519240506327, -1.474492586206897 ], [ 29.787249746835442, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28581, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.474492586206897 ], [ 29.787519240506327, -1.474762068965517 ], [ 29.787788734177216, -1.474762068965517 ], [ 29.787788734177216, -1.474492586206897 ], [ 29.787519240506327, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28582, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.474492586206897 ], [ 29.787788734177216, -1.475031551724138 ], [ 29.788058227848101, -1.475031551724138 ], [ 29.788058227848101, -1.474492586206897 ], [ 29.787788734177216, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28583, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.474492586206897 ], [ 29.788058227848101, -1.476109482758621 ], [ 29.788327721518986, -1.476109482758621 ], [ 29.788327721518986, -1.474492586206897 ], [ 29.788058227848101, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28584, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.474492586206897 ], [ 29.788597215189874, -1.475031551724138 ], [ 29.788866708860759, -1.475031551724138 ], [ 29.788866708860759, -1.474492586206897 ], [ 29.788597215189874, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28585, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.474492586206897 ], [ 29.788866708860759, -1.476109482758621 ], [ 29.789136202531644, -1.476109482758621 ], [ 29.789136202531644, -1.474492586206897 ], [ 29.788866708860759, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28586, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.474492586206897 ], [ 29.799646455696202, -1.474762068965517 ], [ 29.79991594936709, -1.474762068965517 ], [ 29.79991594936709, -1.474492586206897 ], [ 29.799646455696202, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28587, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.474492586206897 ], [ 29.800185443037975, -1.475031551724138 ], [ 29.80045493670886, -1.475031551724138 ], [ 29.80045493670886, -1.474492586206897 ], [ 29.800185443037975, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28588, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.474492586206897 ], [ 29.801532911392407, -1.475031551724138 ], [ 29.802071898734177, -1.475031551724138 ], [ 29.802071898734177, -1.474492586206897 ], [ 29.801532911392407, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28589, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.474492586206897 ], [ 29.80261088607595, -1.474762068965517 ], [ 29.802880379746835, -1.474762068965517 ], [ 29.802880379746835, -1.474492586206897 ], [ 29.80261088607595, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28590, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.474492586206897 ], [ 29.802880379746835, -1.474762068965517 ], [ 29.803149873417723, -1.474762068965517 ], [ 29.803149873417723, -1.474492586206897 ], [ 29.802880379746835, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28591, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.474492586206897 ], [ 29.803149873417723, -1.474762068965517 ], [ 29.803419367088608, -1.474762068965517 ], [ 29.803419367088608, -1.474492586206897 ], [ 29.803149873417723, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28592, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.474492586206897 ], [ 29.803419367088608, -1.475031551724138 ], [ 29.803688860759493, -1.475031551724138 ], [ 29.803688860759493, -1.474492586206897 ], [ 29.803419367088608, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28593, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.474762068965517 ], [ 29.760030886075949, -1.475031551724138 ], [ 29.760300379746834, -1.475031551724138 ], [ 29.760300379746834, -1.474762068965517 ], [ 29.760030886075949, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28594, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.474762068965517 ], [ 29.760300379746834, -1.475031551724138 ], [ 29.760569873417722, -1.475031551724138 ], [ 29.760569873417722, -1.474762068965517 ], [ 29.760300379746834, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28595, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.474762068965517 ], [ 29.760569873417722, -1.475031551724138 ], [ 29.760839367088607, -1.475031551724138 ], [ 29.760839367088607, -1.474762068965517 ], [ 29.760569873417722, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28596, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.474762068965517 ], [ 29.760839367088607, -1.475031551724138 ], [ 29.761108860759492, -1.475031551724138 ], [ 29.761108860759492, -1.474762068965517 ], [ 29.760839367088607, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28597, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.474762068965517 ], [ 29.761108860759492, -1.475031551724138 ], [ 29.761378354430381, -1.475031551724138 ], [ 29.761378354430381, -1.474762068965517 ], [ 29.761108860759492, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28598, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.474762068965517 ], [ 29.761378354430381, -1.475031551724138 ], [ 29.761647848101266, -1.475031551724138 ], [ 29.761647848101266, -1.474762068965517 ], [ 29.761378354430381, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28599, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.474762068965517 ], [ 29.761647848101266, -1.475031551724138 ], [ 29.761917341772151, -1.475031551724138 ], [ 29.761917341772151, -1.474762068965517 ], [ 29.761647848101266, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28600, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.474762068965517 ], [ 29.761917341772151, -1.475031551724138 ], [ 29.762186835443039, -1.475031551724138 ], [ 29.762186835443039, -1.474762068965517 ], [ 29.761917341772151, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28601, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.474762068965517 ], [ 29.762186835443039, -1.475031551724138 ], [ 29.762456329113924, -1.475031551724138 ], [ 29.762456329113924, -1.474762068965517 ], [ 29.762186835443039, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28602, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.474762068965517 ], [ 29.762456329113924, -1.475031551724138 ], [ 29.762725822784809, -1.475031551724138 ], [ 29.762725822784809, -1.474762068965517 ], [ 29.762456329113924, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28603, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.474762068965517 ], [ 29.762725822784809, -1.475031551724138 ], [ 29.762995316455697, -1.475031551724138 ], [ 29.762995316455697, -1.474762068965517 ], [ 29.762725822784809, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28604, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.474762068965517 ], [ 29.762995316455697, -1.475301034482759 ], [ 29.763264810126582, -1.475301034482759 ], [ 29.763264810126582, -1.474762068965517 ], [ 29.762995316455697, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28605, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.474762068965517 ], [ 29.763534303797467, -1.475031551724138 ], [ 29.763803797468356, -1.475031551724138 ], [ 29.763803797468356, -1.474762068965517 ], [ 29.763534303797467, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28606, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.474762068965517 ], [ 29.763803797468356, -1.475031551724138 ], [ 29.76407329113924, -1.475031551724138 ], [ 29.76407329113924, -1.474762068965517 ], [ 29.763803797468356, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28607, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.474762068965517 ], [ 29.764342784810125, -1.475031551724138 ], [ 29.764612278481014, -1.475031551724138 ], [ 29.764612278481014, -1.474762068965517 ], [ 29.764342784810125, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28608, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.474762068965517 ], [ 29.764612278481014, -1.475031551724138 ], [ 29.764881772151899, -1.475031551724138 ], [ 29.764881772151899, -1.474762068965517 ], [ 29.764612278481014, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28609, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.474762068965517 ], [ 29.764881772151899, -1.475031551724138 ], [ 29.765151265822784, -1.475031551724138 ], [ 29.765151265822784, -1.474762068965517 ], [ 29.764881772151899, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28610, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.474762068965517 ], [ 29.765151265822784, -1.475031551724138 ], [ 29.765420759493672, -1.475031551724138 ], [ 29.765420759493672, -1.474762068965517 ], [ 29.765151265822784, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28611, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.474762068965517 ], [ 29.765420759493672, -1.47584 ], [ 29.765959746835442, -1.47584 ], [ 29.765959746835442, -1.474762068965517 ], [ 29.765420759493672, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28612, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.474762068965517 ], [ 29.765959746835442, -1.476109482758621 ], [ 29.766229240506327, -1.476109482758621 ], [ 29.766229240506327, -1.474762068965517 ], [ 29.765959746835442, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28613, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.474762068965517 ], [ 29.766229240506327, -1.476109482758621 ], [ 29.766498734177215, -1.476109482758621 ], [ 29.766498734177215, -1.474762068965517 ], [ 29.766229240506327, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28614, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.474762068965517 ], [ 29.770810632911392, -1.475031551724138 ], [ 29.771080126582277, -1.475031551724138 ], [ 29.771080126582277, -1.474762068965517 ], [ 29.770810632911392, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28615, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.474762068965517 ], [ 29.771080126582277, -1.475031551724138 ], [ 29.771349620253165, -1.475031551724138 ], [ 29.771349620253165, -1.474762068965517 ], [ 29.771080126582277, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28616, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.474762068965517 ], [ 29.771349620253165, -1.475031551724138 ], [ 29.77161911392405, -1.475031551724138 ], [ 29.77161911392405, -1.474762068965517 ], [ 29.771349620253165, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28617, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.474762068965517 ], [ 29.77916493670886, -1.475031551724138 ], [ 29.779434430379748, -1.475031551724138 ], [ 29.779434430379748, -1.474762068965517 ], [ 29.77916493670886, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28618, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.474762068965517 ], [ 29.779434430379748, -1.475031551724138 ], [ 29.779703924050633, -1.475031551724138 ], [ 29.779703924050633, -1.474762068965517 ], [ 29.779434430379748, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28619, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.474762068965517 ], [ 29.779703924050633, -1.475031551724138 ], [ 29.779973417721518, -1.475031551724138 ], [ 29.779973417721518, -1.474762068965517 ], [ 29.779703924050633, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28620, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.474762068965517 ], [ 29.779973417721518, -1.475031551724138 ], [ 29.780242911392406, -1.475031551724138 ], [ 29.780242911392406, -1.474762068965517 ], [ 29.779973417721518, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28621, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.474762068965517 ], [ 29.780242911392406, -1.475031551724138 ], [ 29.780512405063291, -1.475031551724138 ], [ 29.780512405063291, -1.474762068965517 ], [ 29.780242911392406, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28622, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.474762068965517 ], [ 29.780512405063291, -1.475031551724138 ], [ 29.780781898734176, -1.475031551724138 ], [ 29.780781898734176, -1.474762068965517 ], [ 29.780512405063291, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28623, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.474762068965517 ], [ 29.780781898734176, -1.475031551724138 ], [ 29.781051392405065, -1.475031551724138 ], [ 29.781051392405065, -1.474762068965517 ], [ 29.780781898734176, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28624, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.474762068965517 ], [ 29.781051392405065, -1.475031551724138 ], [ 29.78132088607595, -1.475031551724138 ], [ 29.78132088607595, -1.474762068965517 ], [ 29.781051392405065, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28625, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.474762068965517 ], [ 29.78132088607595, -1.475031551724138 ], [ 29.781590379746834, -1.475031551724138 ], [ 29.781590379746834, -1.474762068965517 ], [ 29.78132088607595, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28626, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.474762068965517 ], [ 29.781590379746834, -1.475031551724138 ], [ 29.781859873417723, -1.475031551724138 ], [ 29.781859873417723, -1.474762068965517 ], [ 29.781590379746834, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28627, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.474762068965517 ], [ 29.781859873417723, -1.475031551724138 ], [ 29.782129367088608, -1.475031551724138 ], [ 29.782129367088608, -1.474762068965517 ], [ 29.781859873417723, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28628, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.474762068965517 ], [ 29.782129367088608, -1.475031551724138 ], [ 29.782398860759493, -1.475031551724138 ], [ 29.782398860759493, -1.474762068965517 ], [ 29.782129367088608, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28629, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.474762068965517 ], [ 29.782398860759493, -1.475031551724138 ], [ 29.782668354430381, -1.475031551724138 ], [ 29.782668354430381, -1.474762068965517 ], [ 29.782398860759493, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28630, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.474762068965517 ], [ 29.782668354430381, -1.475031551724138 ], [ 29.782937848101266, -1.475031551724138 ], [ 29.782937848101266, -1.474762068965517 ], [ 29.782668354430381, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28631, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.474762068965517 ], [ 29.782937848101266, -1.475031551724138 ], [ 29.783207341772151, -1.475031551724138 ], [ 29.783207341772151, -1.474762068965517 ], [ 29.782937848101266, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28632, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.474762068965517 ], [ 29.783207341772151, -1.475031551724138 ], [ 29.783476835443039, -1.475031551724138 ], [ 29.783476835443039, -1.474762068965517 ], [ 29.783207341772151, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28633, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.474762068965517 ], [ 29.783476835443039, -1.475031551724138 ], [ 29.784015822784809, -1.475031551724138 ], [ 29.784015822784809, -1.474762068965517 ], [ 29.783476835443039, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28634, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.474762068965517 ], [ 29.784015822784809, -1.475031551724138 ], [ 29.784285316455698, -1.475031551724138 ], [ 29.784285316455698, -1.474762068965517 ], [ 29.784015822784809, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28635, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.474762068965517 ], [ 29.784285316455698, -1.475031551724138 ], [ 29.784554810126583, -1.475031551724138 ], [ 29.784554810126583, -1.474762068965517 ], [ 29.784285316455698, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28636, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.474762068965517 ], [ 29.784554810126583, -1.475031551724138 ], [ 29.784824303797468, -1.475031551724138 ], [ 29.784824303797468, -1.474762068965517 ], [ 29.784554810126583, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28637, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.474762068965517 ], [ 29.784824303797468, -1.475031551724138 ], [ 29.785093797468356, -1.475031551724138 ], [ 29.785093797468356, -1.474762068965517 ], [ 29.784824303797468, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28638, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.474762068965517 ], [ 29.785093797468356, -1.475031551724138 ], [ 29.785363291139241, -1.475031551724138 ], [ 29.785363291139241, -1.474762068965517 ], [ 29.785093797468356, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28639, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.474492586206897 ], [ 29.786171772151899, -1.474492586206897 ], [ 29.786171772151899, -1.475031551724138 ], [ 29.785363291139241, -1.475031551724138 ], [ 29.785363291139241, -1.474762068965517 ], [ 29.785902278481014, -1.474762068965517 ], [ 29.785902278481014, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28640, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.474762068965517 ], [ 29.786171772151899, -1.475031551724138 ], [ 29.786441265822784, -1.475031551724138 ], [ 29.786441265822784, -1.474762068965517 ], [ 29.786171772151899, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28641, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.474762068965517 ], [ 29.786980253164558, -1.475031551724138 ], [ 29.787249746835442, -1.475031551724138 ], [ 29.787249746835442, -1.474762068965517 ], [ 29.786980253164558, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28642, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.474762068965517 ], [ 29.787519240506327, -1.47584 ], [ 29.787788734177216, -1.47584 ], [ 29.787788734177216, -1.474762068965517 ], [ 29.787519240506327, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28643, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.474762068965517 ], [ 29.788327721518986, -1.475570517241379 ], [ 29.788597215189874, -1.475570517241379 ], [ 29.788597215189874, -1.474762068965517 ], [ 29.788327721518986, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28644, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.474762068965517 ], [ 29.789675189873417, -1.475570517241379 ], [ 29.789944683544302, -1.475570517241379 ], [ 29.789944683544302, -1.474762068965517 ], [ 29.789675189873417, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28645, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.474762068965517 ], [ 29.790483670886076, -1.475031551724138 ], [ 29.790753164556961, -1.475031551724138 ], [ 29.790753164556961, -1.474762068965517 ], [ 29.790483670886076, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28646, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.474762068965517 ], [ 29.790753164556961, -1.475031551724138 ], [ 29.791022658227849, -1.475031551724138 ], [ 29.791022658227849, -1.474762068965517 ], [ 29.790753164556961, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28647, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.474762068965517 ], [ 29.791022658227849, -1.475031551724138 ], [ 29.791292151898734, -1.475031551724138 ], [ 29.791292151898734, -1.474762068965517 ], [ 29.791022658227849, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28648, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.474762068965517 ], [ 29.791292151898734, -1.475570517241379 ], [ 29.791561645569619, -1.475570517241379 ], [ 29.791561645569619, -1.474762068965517 ], [ 29.791292151898734, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28649, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.474762068965517 ], [ 29.799107468354432, -1.475031551724138 ], [ 29.799376962025317, -1.475031551724138 ], [ 29.799376962025317, -1.474762068965517 ], [ 29.799107468354432, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28650, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.474762068965517 ], [ 29.799376962025317, -1.475031551724138 ], [ 29.799646455696202, -1.475031551724138 ], [ 29.799646455696202, -1.474762068965517 ], [ 29.799376962025317, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28651, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.471258793103448 ], [ 29.800185443037975, -1.471258793103448 ], [ 29.800185443037975, -1.475301034482759 ], [ 29.79991594936709, -1.475301034482759 ], [ 29.79991594936709, -1.475031551724138 ], [ 29.799646455696202, -1.475031551724138 ], [ 29.799646455696202, -1.474762068965517 ], [ 29.79991594936709, -1.474762068965517 ], [ 29.79991594936709, -1.471258793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28652, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.474762068965517 ], [ 29.80045493670886, -1.475301034482759 ], [ 29.800724430379748, -1.475301034482759 ], [ 29.800724430379748, -1.474762068965517 ], [ 29.80045493670886, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28653, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.474762068965517 ], [ 29.800993924050633, -1.475031551724138 ], [ 29.801263417721518, -1.475031551724138 ], [ 29.801263417721518, -1.474762068965517 ], [ 29.800993924050633, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28654, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.474762068965517 ], [ 29.801263417721518, -1.475031551724138 ], [ 29.801532911392407, -1.475031551724138 ], [ 29.801532911392407, -1.474762068965517 ], [ 29.801263417721518, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28655, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.474762068965517 ], [ 29.802071898734177, -1.475031551724138 ], [ 29.802341392405065, -1.475031551724138 ], [ 29.802341392405065, -1.474762068965517 ], [ 29.802071898734177, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28656, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.474762068965517 ], [ 29.802341392405065, -1.475031551724138 ], [ 29.80261088607595, -1.475031551724138 ], [ 29.80261088607595, -1.474762068965517 ], [ 29.802341392405065, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28657, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.474762068965517 ], [ 29.80261088607595, -1.475031551724138 ], [ 29.802880379746835, -1.475031551724138 ], [ 29.802880379746835, -1.474762068965517 ], [ 29.80261088607595, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28658, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.474762068965517 ], [ 29.802880379746835, -1.475031551724138 ], [ 29.803149873417723, -1.475031551724138 ], [ 29.803149873417723, -1.474762068965517 ], [ 29.802880379746835, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28659, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.474762068965517 ], [ 29.803149873417723, -1.475031551724138 ], [ 29.803419367088608, -1.475031551724138 ], [ 29.803419367088608, -1.474762068965517 ], [ 29.803149873417723, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28660, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.475031551724138 ], [ 29.759222405063291, -1.475301034482759 ], [ 29.759491898734176, -1.475301034482759 ], [ 29.759491898734176, -1.475031551724138 ], [ 29.759222405063291, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28661, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.475031551724138 ], [ 29.759491898734176, -1.475301034482759 ], [ 29.759761392405064, -1.475301034482759 ], [ 29.759761392405064, -1.475031551724138 ], [ 29.759491898734176, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28662, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.475031551724138 ], [ 29.759761392405064, -1.475301034482759 ], [ 29.760030886075949, -1.475301034482759 ], [ 29.760030886075949, -1.475031551724138 ], [ 29.759761392405064, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28663, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.475031551724138 ], [ 29.760030886075949, -1.475301034482759 ], [ 29.760300379746834, -1.475301034482759 ], [ 29.760300379746834, -1.475031551724138 ], [ 29.760030886075949, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28664, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.475031551724138 ], [ 29.760300379746834, -1.475301034482759 ], [ 29.760569873417722, -1.475301034482759 ], [ 29.760569873417722, -1.475031551724138 ], [ 29.760300379746834, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28665, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.475031551724138 ], [ 29.760569873417722, -1.475301034482759 ], [ 29.760839367088607, -1.475301034482759 ], [ 29.760839367088607, -1.475031551724138 ], [ 29.760569873417722, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28666, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.475031551724138 ], [ 29.760839367088607, -1.475301034482759 ], [ 29.761108860759492, -1.475301034482759 ], [ 29.761108860759492, -1.475031551724138 ], [ 29.760839367088607, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28667, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.475031551724138 ], [ 29.761108860759492, -1.475301034482759 ], [ 29.761378354430381, -1.475301034482759 ], [ 29.761378354430381, -1.475031551724138 ], [ 29.761108860759492, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28668, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.475031551724138 ], [ 29.761378354430381, -1.475301034482759 ], [ 29.761647848101266, -1.475301034482759 ], [ 29.761647848101266, -1.475031551724138 ], [ 29.761378354430381, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28669, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.475031551724138 ], [ 29.761647848101266, -1.475301034482759 ], [ 29.761917341772151, -1.475301034482759 ], [ 29.761917341772151, -1.475031551724138 ], [ 29.761647848101266, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28670, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.475031551724138 ], [ 29.761917341772151, -1.475301034482759 ], [ 29.762186835443039, -1.475301034482759 ], [ 29.762186835443039, -1.475031551724138 ], [ 29.761917341772151, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28671, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.475031551724138 ], [ 29.762186835443039, -1.475301034482759 ], [ 29.762456329113924, -1.475301034482759 ], [ 29.762456329113924, -1.475031551724138 ], [ 29.762186835443039, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28672, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.475031551724138 ], [ 29.762456329113924, -1.475301034482759 ], [ 29.762725822784809, -1.475301034482759 ], [ 29.762725822784809, -1.475031551724138 ], [ 29.762456329113924, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28673, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.475031551724138 ], [ 29.762725822784809, -1.475301034482759 ], [ 29.762995316455697, -1.475301034482759 ], [ 29.762995316455697, -1.475031551724138 ], [ 29.762725822784809, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28674, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.475031551724138 ], [ 29.763264810126582, -1.475301034482759 ], [ 29.763534303797467, -1.475301034482759 ], [ 29.763534303797467, -1.475031551724138 ], [ 29.763264810126582, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28675, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.475031551724138 ], [ 29.763534303797467, -1.475301034482759 ], [ 29.763803797468356, -1.475301034482759 ], [ 29.763803797468356, -1.475031551724138 ], [ 29.763534303797467, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28676, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.475031551724138 ], [ 29.763803797468356, -1.475301034482759 ], [ 29.76407329113924, -1.475301034482759 ], [ 29.76407329113924, -1.475031551724138 ], [ 29.763803797468356, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28677, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.475031551724138 ], [ 29.76407329113924, -1.47584 ], [ 29.764342784810125, -1.47584 ], [ 29.764342784810125, -1.475031551724138 ], [ 29.76407329113924, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28678, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.475031551724138 ], [ 29.764342784810125, -1.475301034482759 ], [ 29.764612278481014, -1.475301034482759 ], [ 29.764612278481014, -1.475031551724138 ], [ 29.764342784810125, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28679, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.475031551724138 ], [ 29.764612278481014, -1.475301034482759 ], [ 29.764881772151899, -1.475301034482759 ], [ 29.764881772151899, -1.475031551724138 ], [ 29.764612278481014, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28680, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.475031551724138 ], [ 29.764881772151899, -1.476109482758621 ], [ 29.765151265822784, -1.476109482758621 ], [ 29.765151265822784, -1.475031551724138 ], [ 29.764881772151899, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28681, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.475031551724138 ], [ 29.765151265822784, -1.476109482758621 ], [ 29.765420759493672, -1.476109482758621 ], [ 29.765420759493672, -1.475031551724138 ], [ 29.765151265822784, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28682, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.475031551724138 ], [ 29.77916493670886, -1.475301034482759 ], [ 29.779434430379748, -1.475301034482759 ], [ 29.779434430379748, -1.475031551724138 ], [ 29.77916493670886, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28683, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.475031551724138 ], [ 29.779434430379748, -1.475301034482759 ], [ 29.779703924050633, -1.475301034482759 ], [ 29.779703924050633, -1.475031551724138 ], [ 29.779434430379748, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28684, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.475031551724138 ], [ 29.779703924050633, -1.475301034482759 ], [ 29.779973417721518, -1.475301034482759 ], [ 29.779973417721518, -1.475031551724138 ], [ 29.779703924050633, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28685, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.475031551724138 ], [ 29.779973417721518, -1.475301034482759 ], [ 29.780242911392406, -1.475301034482759 ], [ 29.780242911392406, -1.475031551724138 ], [ 29.779973417721518, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28686, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.475031551724138 ], [ 29.780242911392406, -1.475301034482759 ], [ 29.780512405063291, -1.475301034482759 ], [ 29.780512405063291, -1.475031551724138 ], [ 29.780242911392406, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28687, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.475031551724138 ], [ 29.780512405063291, -1.475301034482759 ], [ 29.780781898734176, -1.475301034482759 ], [ 29.780781898734176, -1.475031551724138 ], [ 29.780512405063291, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28688, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.475031551724138 ], [ 29.780781898734176, -1.475301034482759 ], [ 29.781051392405065, -1.475301034482759 ], [ 29.781051392405065, -1.475031551724138 ], [ 29.780781898734176, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28689, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.475031551724138 ], [ 29.781051392405065, -1.475301034482759 ], [ 29.78132088607595, -1.475301034482759 ], [ 29.78132088607595, -1.475031551724138 ], [ 29.781051392405065, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28690, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.475031551724138 ], [ 29.78132088607595, -1.475301034482759 ], [ 29.781590379746834, -1.475301034482759 ], [ 29.781590379746834, -1.475031551724138 ], [ 29.78132088607595, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28691, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.475031551724138 ], [ 29.781590379746834, -1.475301034482759 ], [ 29.781859873417723, -1.475301034482759 ], [ 29.781859873417723, -1.475031551724138 ], [ 29.781590379746834, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28692, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.475031551724138 ], [ 29.781859873417723, -1.475301034482759 ], [ 29.782129367088608, -1.475301034482759 ], [ 29.782129367088608, -1.475031551724138 ], [ 29.781859873417723, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28693, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.475031551724138 ], [ 29.782129367088608, -1.475301034482759 ], [ 29.782398860759493, -1.475301034482759 ], [ 29.782398860759493, -1.475031551724138 ], [ 29.782129367088608, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28694, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.475031551724138 ], [ 29.782398860759493, -1.475301034482759 ], [ 29.782668354430381, -1.475301034482759 ], [ 29.782668354430381, -1.475031551724138 ], [ 29.782398860759493, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28695, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.475031551724138 ], [ 29.782668354430381, -1.475301034482759 ], [ 29.782937848101266, -1.475301034482759 ], [ 29.782937848101266, -1.475031551724138 ], [ 29.782668354430381, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28696, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.475031551724138 ], [ 29.782937848101266, -1.475301034482759 ], [ 29.783207341772151, -1.475301034482759 ], [ 29.783207341772151, -1.475031551724138 ], [ 29.782937848101266, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28697, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.475031551724138 ], [ 29.783207341772151, -1.475301034482759 ], [ 29.783476835443039, -1.475301034482759 ], [ 29.783476835443039, -1.475031551724138 ], [ 29.783207341772151, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28698, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.475031551724138 ], [ 29.783476835443039, -1.475301034482759 ], [ 29.784015822784809, -1.475301034482759 ], [ 29.784015822784809, -1.475031551724138 ], [ 29.783476835443039, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28699, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.475031551724138 ], [ 29.784015822784809, -1.475301034482759 ], [ 29.784285316455698, -1.475301034482759 ], [ 29.784285316455698, -1.475031551724138 ], [ 29.784015822784809, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28700, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.475031551724138 ], [ 29.784285316455698, -1.475301034482759 ], [ 29.784554810126583, -1.475301034482759 ], [ 29.784554810126583, -1.475031551724138 ], [ 29.784285316455698, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28701, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.475031551724138 ], [ 29.784554810126583, -1.475301034482759 ], [ 29.785093797468356, -1.475301034482759 ], [ 29.785093797468356, -1.475031551724138 ], [ 29.784554810126583, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28702, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.475031551724138 ], [ 29.785093797468356, -1.475301034482759 ], [ 29.785363291139241, -1.475301034482759 ], [ 29.785363291139241, -1.475031551724138 ], [ 29.785093797468356, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28703, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.475031551724138 ], [ 29.785363291139241, -1.475301034482759 ], [ 29.785902278481014, -1.475301034482759 ], [ 29.785902278481014, -1.475031551724138 ], [ 29.785363291139241, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28704, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.474762068965517 ], [ 29.786710759493673, -1.474762068965517 ], [ 29.786710759493673, -1.475301034482759 ], [ 29.785902278481014, -1.475301034482759 ], [ 29.785902278481014, -1.475031551724138 ], [ 29.786441265822784, -1.475031551724138 ], [ 29.786441265822784, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28705, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.475031551724138 ], [ 29.786980253164558, -1.475301034482759 ], [ 29.787249746835442, -1.475301034482759 ], [ 29.787249746835442, -1.475031551724138 ], [ 29.786980253164558, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28706, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.475031551724138 ], [ 29.787788734177216, -1.475301034482759 ], [ 29.788058227848101, -1.475301034482759 ], [ 29.788058227848101, -1.475031551724138 ], [ 29.787788734177216, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28707, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.475031551724138 ], [ 29.788597215189874, -1.475301034482759 ], [ 29.788866708860759, -1.475301034482759 ], [ 29.788866708860759, -1.475031551724138 ], [ 29.788597215189874, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28708, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.475031551724138 ], [ 29.789136202531644, -1.475301034482759 ], [ 29.789405696202532, -1.475301034482759 ], [ 29.789405696202532, -1.475031551724138 ], [ 29.789136202531644, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28709, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.475031551724138 ], [ 29.789405696202532, -1.475301034482759 ], [ 29.789675189873417, -1.475301034482759 ], [ 29.789675189873417, -1.475031551724138 ], [ 29.789405696202532, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28710, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.475031551724138 ], [ 29.789944683544302, -1.475301034482759 ], [ 29.790214177215191, -1.475301034482759 ], [ 29.790214177215191, -1.475031551724138 ], [ 29.789944683544302, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28711, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.475031551724138 ], [ 29.790214177215191, -1.475301034482759 ], [ 29.790483670886076, -1.475301034482759 ], [ 29.790483670886076, -1.475031551724138 ], [ 29.790214177215191, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28712, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.475031551724138 ], [ 29.790483670886076, -1.475301034482759 ], [ 29.790753164556961, -1.475301034482759 ], [ 29.790753164556961, -1.475031551724138 ], [ 29.790483670886076, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28713, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.475031551724138 ], [ 29.790753164556961, -1.475301034482759 ], [ 29.791022658227849, -1.475301034482759 ], [ 29.791022658227849, -1.475031551724138 ], [ 29.790753164556961, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28714, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.475031551724138 ], [ 29.791022658227849, -1.475301034482759 ], [ 29.791292151898734, -1.475301034482759 ], [ 29.791292151898734, -1.475031551724138 ], [ 29.791022658227849, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28715, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.475031551724138 ], [ 29.798568481012659, -1.475301034482759 ], [ 29.798837974683543, -1.475301034482759 ], [ 29.798837974683543, -1.475031551724138 ], [ 29.798568481012659, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28716, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.475031551724138 ], [ 29.798837974683543, -1.475301034482759 ], [ 29.799107468354432, -1.475301034482759 ], [ 29.799107468354432, -1.475031551724138 ], [ 29.798837974683543, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28717, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.475031551724138 ], [ 29.799107468354432, -1.475301034482759 ], [ 29.799376962025317, -1.475301034482759 ], [ 29.799376962025317, -1.475031551724138 ], [ 29.799107468354432, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28718, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.475031551724138 ], [ 29.799376962025317, -1.475301034482759 ], [ 29.799646455696202, -1.475301034482759 ], [ 29.799646455696202, -1.475031551724138 ], [ 29.799376962025317, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28719, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.475031551724138 ], [ 29.799646455696202, -1.475301034482759 ], [ 29.79991594936709, -1.475301034482759 ], [ 29.79991594936709, -1.475031551724138 ], [ 29.799646455696202, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28720, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.475031551724138 ], [ 29.800185443037975, -1.475301034482759 ], [ 29.80045493670886, -1.475301034482759 ], [ 29.80045493670886, -1.475031551724138 ], [ 29.800185443037975, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28721, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.475031551724138 ], [ 29.800724430379748, -1.475301034482759 ], [ 29.800993924050633, -1.475301034482759 ], [ 29.800993924050633, -1.475031551724138 ], [ 29.800724430379748, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28722, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.475031551724138 ], [ 29.800993924050633, -1.475301034482759 ], [ 29.801263417721518, -1.475301034482759 ], [ 29.801263417721518, -1.475031551724138 ], [ 29.800993924050633, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28723, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.475031551724138 ], [ 29.801263417721518, -1.475301034482759 ], [ 29.801532911392407, -1.475301034482759 ], [ 29.801532911392407, -1.475031551724138 ], [ 29.801263417721518, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28724, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.475031551724138 ], [ 29.801532911392407, -1.475301034482759 ], [ 29.802071898734177, -1.475301034482759 ], [ 29.802071898734177, -1.475031551724138 ], [ 29.801532911392407, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28725, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.475031551724138 ], [ 29.802071898734177, -1.475301034482759 ], [ 29.802341392405065, -1.475301034482759 ], [ 29.802341392405065, -1.475031551724138 ], [ 29.802071898734177, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28726, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.475031551724138 ], [ 29.802341392405065, -1.475301034482759 ], [ 29.80261088607595, -1.475301034482759 ], [ 29.80261088607595, -1.475031551724138 ], [ 29.802341392405065, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28727, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.475031551724138 ], [ 29.80261088607595, -1.475301034482759 ], [ 29.802880379746835, -1.475301034482759 ], [ 29.802880379746835, -1.475031551724138 ], [ 29.80261088607595, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28728, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.475031551724138 ], [ 29.802880379746835, -1.475301034482759 ], [ 29.803149873417723, -1.475301034482759 ], [ 29.803149873417723, -1.475031551724138 ], [ 29.802880379746835, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28729, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.475031551724138 ], [ 29.803149873417723, -1.475301034482759 ], [ 29.803419367088608, -1.475301034482759 ], [ 29.803419367088608, -1.475031551724138 ], [ 29.803149873417723, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28730, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.475031551724138 ], [ 29.803419367088608, -1.475301034482759 ], [ 29.803688860759493, -1.475301034482759 ], [ 29.803688860759493, -1.475031551724138 ], [ 29.803419367088608, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28731, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.475031551724138 ], [ 29.803688860759493, -1.475301034482759 ], [ 29.804227848101267, -1.475301034482759 ], [ 29.804227848101267, -1.475031551724138 ], [ 29.803688860759493, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28732, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.475301034482759 ], [ 29.758683417721517, -1.475570517241379 ], [ 29.758952911392406, -1.475570517241379 ], [ 29.758952911392406, -1.475301034482759 ], [ 29.758683417721517, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28733, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.475301034482759 ], [ 29.758952911392406, -1.475570517241379 ], [ 29.759222405063291, -1.475570517241379 ], [ 29.759222405063291, -1.475301034482759 ], [ 29.758952911392406, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28734, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.475301034482759 ], [ 29.759222405063291, -1.475570517241379 ], [ 29.759491898734176, -1.475570517241379 ], [ 29.759491898734176, -1.475301034482759 ], [ 29.759222405063291, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28735, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.475301034482759 ], [ 29.759491898734176, -1.475570517241379 ], [ 29.759761392405064, -1.475570517241379 ], [ 29.759761392405064, -1.475301034482759 ], [ 29.759491898734176, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28736, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.475301034482759 ], [ 29.759761392405064, -1.475570517241379 ], [ 29.760030886075949, -1.475570517241379 ], [ 29.760030886075949, -1.475301034482759 ], [ 29.759761392405064, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28737, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.475301034482759 ], [ 29.760030886075949, -1.475570517241379 ], [ 29.760300379746834, -1.475570517241379 ], [ 29.760300379746834, -1.475301034482759 ], [ 29.760030886075949, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28738, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.475301034482759 ], [ 29.760300379746834, -1.475570517241379 ], [ 29.760569873417722, -1.475570517241379 ], [ 29.760569873417722, -1.475301034482759 ], [ 29.760300379746834, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28739, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.475301034482759 ], [ 29.760569873417722, -1.475570517241379 ], [ 29.760839367088607, -1.475570517241379 ], [ 29.760839367088607, -1.475301034482759 ], [ 29.760569873417722, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28740, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.475301034482759 ], [ 29.760839367088607, -1.475570517241379 ], [ 29.761108860759492, -1.475570517241379 ], [ 29.761108860759492, -1.475301034482759 ], [ 29.760839367088607, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28741, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.475301034482759 ], [ 29.761108860759492, -1.475570517241379 ], [ 29.761378354430381, -1.475570517241379 ], [ 29.761378354430381, -1.475301034482759 ], [ 29.761108860759492, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28742, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.475301034482759 ], [ 29.761378354430381, -1.475570517241379 ], [ 29.761647848101266, -1.475570517241379 ], [ 29.761647848101266, -1.475301034482759 ], [ 29.761378354430381, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28743, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.475301034482759 ], [ 29.761647848101266, -1.475570517241379 ], [ 29.761917341772151, -1.475570517241379 ], [ 29.761917341772151, -1.475301034482759 ], [ 29.761647848101266, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28744, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.475301034482759 ], [ 29.761917341772151, -1.475570517241379 ], [ 29.762186835443039, -1.475570517241379 ], [ 29.762186835443039, -1.475301034482759 ], [ 29.761917341772151, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28745, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.475301034482759 ], [ 29.762186835443039, -1.475570517241379 ], [ 29.762456329113924, -1.475570517241379 ], [ 29.762456329113924, -1.475301034482759 ], [ 29.762186835443039, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28746, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.475301034482759 ], [ 29.762456329113924, -1.475570517241379 ], [ 29.762725822784809, -1.475570517241379 ], [ 29.762725822784809, -1.475301034482759 ], [ 29.762456329113924, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28747, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.475301034482759 ], [ 29.762725822784809, -1.475570517241379 ], [ 29.762995316455697, -1.475570517241379 ], [ 29.762995316455697, -1.475301034482759 ], [ 29.762725822784809, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28748, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.475301034482759 ], [ 29.762995316455697, -1.475570517241379 ], [ 29.763264810126582, -1.475570517241379 ], [ 29.763264810126582, -1.475301034482759 ], [ 29.762995316455697, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28749, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.475301034482759 ], [ 29.763264810126582, -1.475570517241379 ], [ 29.763534303797467, -1.475570517241379 ], [ 29.763534303797467, -1.475301034482759 ], [ 29.763264810126582, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28750, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.475301034482759 ], [ 29.763534303797467, -1.476109482758621 ], [ 29.763803797468356, -1.476109482758621 ], [ 29.763803797468356, -1.475301034482759 ], [ 29.763534303797467, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28751, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.475301034482759 ], [ 29.764342784810125, -1.476109482758621 ], [ 29.764612278481014, -1.476109482758621 ], [ 29.764612278481014, -1.475301034482759 ], [ 29.764342784810125, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28752, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.475301034482759 ], [ 29.779434430379748, -1.475570517241379 ], [ 29.779703924050633, -1.475570517241379 ], [ 29.779703924050633, -1.475301034482759 ], [ 29.779434430379748, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28753, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.475301034482759 ], [ 29.779703924050633, -1.475570517241379 ], [ 29.779973417721518, -1.475570517241379 ], [ 29.779973417721518, -1.475301034482759 ], [ 29.779703924050633, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28754, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.475301034482759 ], [ 29.779973417721518, -1.475570517241379 ], [ 29.780242911392406, -1.475570517241379 ], [ 29.780242911392406, -1.475301034482759 ], [ 29.779973417721518, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28755, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.475301034482759 ], [ 29.780242911392406, -1.475570517241379 ], [ 29.780512405063291, -1.475570517241379 ], [ 29.780512405063291, -1.475301034482759 ], [ 29.780242911392406, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28756, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.475301034482759 ], [ 29.780512405063291, -1.475570517241379 ], [ 29.780781898734176, -1.475570517241379 ], [ 29.780781898734176, -1.475301034482759 ], [ 29.780512405063291, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28757, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.475301034482759 ], [ 29.780781898734176, -1.475570517241379 ], [ 29.781051392405065, -1.475570517241379 ], [ 29.781051392405065, -1.475301034482759 ], [ 29.780781898734176, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28758, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.475301034482759 ], [ 29.781051392405065, -1.475570517241379 ], [ 29.78132088607595, -1.475570517241379 ], [ 29.78132088607595, -1.475301034482759 ], [ 29.781051392405065, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28759, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.475301034482759 ], [ 29.78132088607595, -1.475570517241379 ], [ 29.781590379746834, -1.475570517241379 ], [ 29.781590379746834, -1.475301034482759 ], [ 29.78132088607595, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28760, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.475301034482759 ], [ 29.781590379746834, -1.475570517241379 ], [ 29.781859873417723, -1.475570517241379 ], [ 29.781859873417723, -1.475301034482759 ], [ 29.781590379746834, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28761, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.475301034482759 ], [ 29.781859873417723, -1.475570517241379 ], [ 29.782129367088608, -1.475570517241379 ], [ 29.782129367088608, -1.475301034482759 ], [ 29.781859873417723, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28762, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.475301034482759 ], [ 29.782129367088608, -1.475570517241379 ], [ 29.782398860759493, -1.475570517241379 ], [ 29.782398860759493, -1.475301034482759 ], [ 29.782129367088608, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28763, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.475301034482759 ], [ 29.782398860759493, -1.475570517241379 ], [ 29.782668354430381, -1.475570517241379 ], [ 29.782668354430381, -1.475301034482759 ], [ 29.782398860759493, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28764, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.475301034482759 ], [ 29.782668354430381, -1.475570517241379 ], [ 29.782937848101266, -1.475570517241379 ], [ 29.782937848101266, -1.475301034482759 ], [ 29.782668354430381, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28765, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.475301034482759 ], [ 29.782937848101266, -1.475570517241379 ], [ 29.783207341772151, -1.475570517241379 ], [ 29.783207341772151, -1.475301034482759 ], [ 29.782937848101266, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28766, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.475301034482759 ], [ 29.783207341772151, -1.475570517241379 ], [ 29.783476835443039, -1.475570517241379 ], [ 29.783476835443039, -1.475301034482759 ], [ 29.783207341772151, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28767, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.475301034482759 ], [ 29.783476835443039, -1.475570517241379 ], [ 29.784015822784809, -1.475570517241379 ], [ 29.784015822784809, -1.475301034482759 ], [ 29.783476835443039, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28768, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.475301034482759 ], [ 29.784015822784809, -1.475570517241379 ], [ 29.784285316455698, -1.475570517241379 ], [ 29.784285316455698, -1.475301034482759 ], [ 29.784015822784809, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28769, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.475301034482759 ], [ 29.784285316455698, -1.475570517241379 ], [ 29.784554810126583, -1.475570517241379 ], [ 29.784554810126583, -1.475301034482759 ], [ 29.784285316455698, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28770, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.475301034482759 ], [ 29.784554810126583, -1.475570517241379 ], [ 29.784824303797468, -1.475570517241379 ], [ 29.784824303797468, -1.475301034482759 ], [ 29.784554810126583, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28771, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.475301034482759 ], [ 29.784824303797468, -1.475570517241379 ], [ 29.785093797468356, -1.475570517241379 ], [ 29.785093797468356, -1.475301034482759 ], [ 29.784824303797468, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28772, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.475301034482759 ], [ 29.785093797468356, -1.475570517241379 ], [ 29.785632784810126, -1.475570517241379 ], [ 29.785632784810126, -1.475301034482759 ], [ 29.785093797468356, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28773, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.474762068965517 ], [ 29.786980253164558, -1.474762068965517 ], [ 29.786980253164558, -1.475570517241379 ], [ 29.785632784810126, -1.475570517241379 ], [ 29.785632784810126, -1.475301034482759 ], [ 29.786710759493673, -1.475301034482759 ], [ 29.786710759493673, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28774, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.475301034482759 ], [ 29.787788734177216, -1.476109482758621 ], [ 29.788058227848101, -1.476109482758621 ], [ 29.788058227848101, -1.475301034482759 ], [ 29.787788734177216, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28775, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.475301034482759 ], [ 29.788597215189874, -1.476109482758621 ], [ 29.788866708860759, -1.476109482758621 ], [ 29.788866708860759, -1.475301034482759 ], [ 29.788597215189874, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28776, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.475301034482759 ], [ 29.790483670886076, -1.475570517241379 ], [ 29.790753164556961, -1.475570517241379 ], [ 29.790753164556961, -1.475301034482759 ], [ 29.790483670886076, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28777, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.475301034482759 ], [ 29.790753164556961, -1.475570517241379 ], [ 29.791022658227849, -1.475570517241379 ], [ 29.791022658227849, -1.475301034482759 ], [ 29.790753164556961, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28778, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.475301034482759 ], [ 29.791022658227849, -1.475570517241379 ], [ 29.791292151898734, -1.475570517241379 ], [ 29.791292151898734, -1.475301034482759 ], [ 29.791022658227849, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28779, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.475301034482759 ], [ 29.798029493670885, -1.475570517241379 ], [ 29.798298987341774, -1.475570517241379 ], [ 29.798298987341774, -1.475301034482759 ], [ 29.798029493670885, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28780, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.475301034482759 ], [ 29.798298987341774, -1.475570517241379 ], [ 29.798568481012659, -1.475570517241379 ], [ 29.798568481012659, -1.475301034482759 ], [ 29.798298987341774, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28781, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.475301034482759 ], [ 29.798568481012659, -1.475570517241379 ], [ 29.798837974683543, -1.475570517241379 ], [ 29.798837974683543, -1.475301034482759 ], [ 29.798568481012659, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28782, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.475301034482759 ], [ 29.798837974683543, -1.475570517241379 ], [ 29.799107468354432, -1.475570517241379 ], [ 29.799107468354432, -1.475301034482759 ], [ 29.798837974683543, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28783, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.475301034482759 ], [ 29.799107468354432, -1.475570517241379 ], [ 29.799376962025317, -1.475570517241379 ], [ 29.799376962025317, -1.475301034482759 ], [ 29.799107468354432, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28784, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.475301034482759 ], [ 29.799376962025317, -1.475570517241379 ], [ 29.799646455696202, -1.475570517241379 ], [ 29.799646455696202, -1.475301034482759 ], [ 29.799376962025317, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28785, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.475301034482759 ], [ 29.799646455696202, -1.475570517241379 ], [ 29.79991594936709, -1.475570517241379 ], [ 29.79991594936709, -1.475301034482759 ], [ 29.799646455696202, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28786, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.475301034482759 ], [ 29.79991594936709, -1.475570517241379 ], [ 29.80045493670886, -1.475570517241379 ], [ 29.80045493670886, -1.475301034482759 ], [ 29.79991594936709, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28787, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.475301034482759 ], [ 29.80045493670886, -1.475570517241379 ], [ 29.800724430379748, -1.475570517241379 ], [ 29.800724430379748, -1.475301034482759 ], [ 29.80045493670886, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28788, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.475301034482759 ], [ 29.800724430379748, -1.475570517241379 ], [ 29.800993924050633, -1.475570517241379 ], [ 29.800993924050633, -1.475301034482759 ], [ 29.800724430379748, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28789, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.475301034482759 ], [ 29.800993924050633, -1.475570517241379 ], [ 29.801263417721518, -1.475570517241379 ], [ 29.801263417721518, -1.475301034482759 ], [ 29.800993924050633, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28790, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.475301034482759 ], [ 29.801263417721518, -1.475570517241379 ], [ 29.801532911392407, -1.475570517241379 ], [ 29.801532911392407, -1.475301034482759 ], [ 29.801263417721518, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28791, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.475301034482759 ], [ 29.801532911392407, -1.475570517241379 ], [ 29.802071898734177, -1.475570517241379 ], [ 29.802071898734177, -1.475301034482759 ], [ 29.801532911392407, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28792, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.475301034482759 ], [ 29.802071898734177, -1.475570517241379 ], [ 29.802341392405065, -1.475570517241379 ], [ 29.802341392405065, -1.475301034482759 ], [ 29.802071898734177, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28793, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.475301034482759 ], [ 29.802341392405065, -1.475570517241379 ], [ 29.80261088607595, -1.475570517241379 ], [ 29.80261088607595, -1.475301034482759 ], [ 29.802341392405065, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28794, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.475301034482759 ], [ 29.80261088607595, -1.475570517241379 ], [ 29.802880379746835, -1.475570517241379 ], [ 29.802880379746835, -1.475301034482759 ], [ 29.80261088607595, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28795, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.475301034482759 ], [ 29.802880379746835, -1.475570517241379 ], [ 29.803149873417723, -1.475570517241379 ], [ 29.803149873417723, -1.475301034482759 ], [ 29.802880379746835, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28796, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.475301034482759 ], [ 29.803149873417723, -1.475570517241379 ], [ 29.803419367088608, -1.475570517241379 ], [ 29.803419367088608, -1.475301034482759 ], [ 29.803149873417723, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28797, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.475301034482759 ], [ 29.803419367088608, -1.475570517241379 ], [ 29.803688860759493, -1.475570517241379 ], [ 29.803688860759493, -1.475301034482759 ], [ 29.803419367088608, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28798, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.475301034482759 ], [ 29.803688860759493, -1.475570517241379 ], [ 29.803958354430382, -1.475570517241379 ], [ 29.803958354430382, -1.475301034482759 ], [ 29.803688860759493, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28799, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.475301034482759 ], [ 29.803958354430382, -1.475570517241379 ], [ 29.804227848101267, -1.475570517241379 ], [ 29.804227848101267, -1.475301034482759 ], [ 29.803958354430382, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28800, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.475301034482759 ], [ 29.804227848101267, -1.475570517241379 ], [ 29.80476683544304, -1.475570517241379 ], [ 29.80476683544304, -1.475301034482759 ], [ 29.804227848101267, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28801, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.475570517241379 ], [ 29.758144430379748, -1.47584 ], [ 29.758413924050632, -1.47584 ], [ 29.758413924050632, -1.475570517241379 ], [ 29.758144430379748, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28802, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.475570517241379 ], [ 29.758413924050632, -1.47584 ], [ 29.758683417721517, -1.47584 ], [ 29.758683417721517, -1.475570517241379 ], [ 29.758413924050632, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28803, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.475570517241379 ], [ 29.758683417721517, -1.47584 ], [ 29.758952911392406, -1.47584 ], [ 29.758952911392406, -1.475570517241379 ], [ 29.758683417721517, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28804, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.475570517241379 ], [ 29.758952911392406, -1.47584 ], [ 29.759222405063291, -1.47584 ], [ 29.759222405063291, -1.475570517241379 ], [ 29.758952911392406, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28805, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.475570517241379 ], [ 29.759222405063291, -1.47584 ], [ 29.759491898734176, -1.47584 ], [ 29.759491898734176, -1.475570517241379 ], [ 29.759222405063291, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28806, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.475570517241379 ], [ 29.759491898734176, -1.47584 ], [ 29.759761392405064, -1.47584 ], [ 29.759761392405064, -1.475570517241379 ], [ 29.759491898734176, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28807, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.475570517241379 ], [ 29.759761392405064, -1.47584 ], [ 29.760030886075949, -1.47584 ], [ 29.760030886075949, -1.475570517241379 ], [ 29.759761392405064, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28808, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.475570517241379 ], [ 29.760030886075949, -1.47584 ], [ 29.760300379746834, -1.47584 ], [ 29.760300379746834, -1.475570517241379 ], [ 29.760030886075949, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28809, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.475570517241379 ], [ 29.760300379746834, -1.47584 ], [ 29.760569873417722, -1.47584 ], [ 29.760569873417722, -1.475570517241379 ], [ 29.760300379746834, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28810, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.475570517241379 ], [ 29.760569873417722, -1.47584 ], [ 29.760839367088607, -1.47584 ], [ 29.760839367088607, -1.475570517241379 ], [ 29.760569873417722, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28811, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.475570517241379 ], [ 29.760839367088607, -1.47584 ], [ 29.761108860759492, -1.47584 ], [ 29.761108860759492, -1.475570517241379 ], [ 29.760839367088607, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28812, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.475570517241379 ], [ 29.761108860759492, -1.47584 ], [ 29.761378354430381, -1.47584 ], [ 29.761378354430381, -1.475570517241379 ], [ 29.761108860759492, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28813, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.475570517241379 ], [ 29.761378354430381, -1.47584 ], [ 29.761647848101266, -1.47584 ], [ 29.761647848101266, -1.475570517241379 ], [ 29.761378354430381, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28814, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.475570517241379 ], [ 29.761647848101266, -1.47584 ], [ 29.761917341772151, -1.47584 ], [ 29.761917341772151, -1.475570517241379 ], [ 29.761647848101266, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28815, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.475570517241379 ], [ 29.761917341772151, -1.47584 ], [ 29.762186835443039, -1.47584 ], [ 29.762186835443039, -1.475570517241379 ], [ 29.761917341772151, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28816, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.475570517241379 ], [ 29.762186835443039, -1.47584 ], [ 29.762456329113924, -1.47584 ], [ 29.762456329113924, -1.475570517241379 ], [ 29.762186835443039, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28817, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.475570517241379 ], [ 29.762456329113924, -1.47584 ], [ 29.762725822784809, -1.47584 ], [ 29.762725822784809, -1.475570517241379 ], [ 29.762456329113924, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28818, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.475570517241379 ], [ 29.762725822784809, -1.47584 ], [ 29.762995316455697, -1.47584 ], [ 29.762995316455697, -1.475570517241379 ], [ 29.762725822784809, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28819, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.475570517241379 ], [ 29.762995316455697, -1.47584 ], [ 29.763264810126582, -1.47584 ], [ 29.763264810126582, -1.476109482758621 ], [ 29.763534303797467, -1.476109482758621 ], [ 29.763534303797467, -1.475570517241379 ], [ 29.762995316455697, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28820, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.475570517241379 ], [ 29.77916493670886, -1.47584 ], [ 29.779434430379748, -1.47584 ], [ 29.779434430379748, -1.475570517241379 ], [ 29.77916493670886, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28821, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.475570517241379 ], [ 29.779434430379748, -1.47584 ], [ 29.779703924050633, -1.47584 ], [ 29.779703924050633, -1.475570517241379 ], [ 29.779434430379748, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28822, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.475570517241379 ], [ 29.779703924050633, -1.47584 ], [ 29.779973417721518, -1.47584 ], [ 29.779973417721518, -1.475570517241379 ], [ 29.779703924050633, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28823, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.475570517241379 ], [ 29.779973417721518, -1.47584 ], [ 29.780242911392406, -1.47584 ], [ 29.780242911392406, -1.475570517241379 ], [ 29.779973417721518, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28824, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.475570517241379 ], [ 29.780242911392406, -1.47584 ], [ 29.780512405063291, -1.47584 ], [ 29.780512405063291, -1.475570517241379 ], [ 29.780242911392406, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28825, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.475570517241379 ], [ 29.780512405063291, -1.47584 ], [ 29.780781898734176, -1.47584 ], [ 29.780781898734176, -1.475570517241379 ], [ 29.780512405063291, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28826, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.475570517241379 ], [ 29.780781898734176, -1.47584 ], [ 29.781051392405065, -1.47584 ], [ 29.781051392405065, -1.475570517241379 ], [ 29.780781898734176, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28827, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.475570517241379 ], [ 29.781051392405065, -1.47584 ], [ 29.78132088607595, -1.47584 ], [ 29.78132088607595, -1.475570517241379 ], [ 29.781051392405065, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28828, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.475570517241379 ], [ 29.78132088607595, -1.47584 ], [ 29.781590379746834, -1.47584 ], [ 29.781590379746834, -1.475570517241379 ], [ 29.78132088607595, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28829, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.475570517241379 ], [ 29.781590379746834, -1.47584 ], [ 29.781859873417723, -1.47584 ], [ 29.781859873417723, -1.475570517241379 ], [ 29.781590379746834, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28830, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.475570517241379 ], [ 29.781859873417723, -1.47584 ], [ 29.782129367088608, -1.47584 ], [ 29.782129367088608, -1.475570517241379 ], [ 29.781859873417723, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28831, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.475570517241379 ], [ 29.782129367088608, -1.47584 ], [ 29.782398860759493, -1.47584 ], [ 29.782398860759493, -1.475570517241379 ], [ 29.782129367088608, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28832, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.475570517241379 ], [ 29.782398860759493, -1.47584 ], [ 29.782668354430381, -1.47584 ], [ 29.782668354430381, -1.475570517241379 ], [ 29.782398860759493, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28833, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.475570517241379 ], [ 29.782668354430381, -1.47584 ], [ 29.782937848101266, -1.47584 ], [ 29.782937848101266, -1.475570517241379 ], [ 29.782668354430381, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28834, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.475570517241379 ], [ 29.782937848101266, -1.47584 ], [ 29.783207341772151, -1.47584 ], [ 29.783207341772151, -1.475570517241379 ], [ 29.782937848101266, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28835, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.475570517241379 ], [ 29.783207341772151, -1.47584 ], [ 29.783476835443039, -1.47584 ], [ 29.783476835443039, -1.475570517241379 ], [ 29.783207341772151, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28836, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.475570517241379 ], [ 29.783476835443039, -1.47584 ], [ 29.784015822784809, -1.47584 ], [ 29.784015822784809, -1.475570517241379 ], [ 29.783476835443039, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28837, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.475570517241379 ], [ 29.784015822784809, -1.47584 ], [ 29.784285316455698, -1.47584 ], [ 29.784285316455698, -1.475570517241379 ], [ 29.784015822784809, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28838, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.475570517241379 ], [ 29.784285316455698, -1.47584 ], [ 29.784554810126583, -1.47584 ], [ 29.784554810126583, -1.475570517241379 ], [ 29.784285316455698, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28839, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.475570517241379 ], [ 29.784554810126583, -1.47584 ], [ 29.784824303797468, -1.47584 ], [ 29.784824303797468, -1.475570517241379 ], [ 29.784554810126583, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28840, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.475570517241379 ], [ 29.784824303797468, -1.47584 ], [ 29.785093797468356, -1.47584 ], [ 29.785093797468356, -1.475570517241379 ], [ 29.784824303797468, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28841, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.475570517241379 ], [ 29.785093797468356, -1.47584 ], [ 29.785363291139241, -1.47584 ], [ 29.785363291139241, -1.475570517241379 ], [ 29.785093797468356, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28842, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.475570517241379 ], [ 29.785363291139241, -1.47584 ], [ 29.785902278481014, -1.47584 ], [ 29.785902278481014, -1.475570517241379 ], [ 29.785363291139241, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28843, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.475301034482759 ], [ 29.787249746835442, -1.475301034482759 ], [ 29.787249746835442, -1.47584 ], [ 29.785902278481014, -1.47584 ], [ 29.785902278481014, -1.475570517241379 ], [ 29.786980253164558, -1.475570517241379 ], [ 29.786980253164558, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28844, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.475570517241379 ], [ 29.797490506329115, -1.47584 ], [ 29.79776, -1.47584 ], [ 29.79776, -1.475570517241379 ], [ 29.797490506329115, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28845, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.475570517241379 ], [ 29.79776, -1.47584 ], [ 29.798029493670885, -1.47584 ], [ 29.798029493670885, -1.475570517241379 ], [ 29.79776, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28846, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.475570517241379 ], [ 29.798029493670885, -1.47584 ], [ 29.798298987341774, -1.47584 ], [ 29.798298987341774, -1.475570517241379 ], [ 29.798029493670885, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28847, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.475570517241379 ], [ 29.798298987341774, -1.47584 ], [ 29.798568481012659, -1.47584 ], [ 29.798568481012659, -1.475570517241379 ], [ 29.798298987341774, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28848, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.475570517241379 ], [ 29.798568481012659, -1.47584 ], [ 29.798837974683543, -1.47584 ], [ 29.798837974683543, -1.475570517241379 ], [ 29.798568481012659, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28849, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.475570517241379 ], [ 29.798837974683543, -1.47584 ], [ 29.799107468354432, -1.47584 ], [ 29.799107468354432, -1.475570517241379 ], [ 29.798837974683543, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28850, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.475570517241379 ], [ 29.799107468354432, -1.47584 ], [ 29.799376962025317, -1.47584 ], [ 29.799376962025317, -1.475570517241379 ], [ 29.799107468354432, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28851, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.475570517241379 ], [ 29.799376962025317, -1.47584 ], [ 29.799646455696202, -1.47584 ], [ 29.799646455696202, -1.475570517241379 ], [ 29.799376962025317, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28852, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.475570517241379 ], [ 29.799646455696202, -1.47584 ], [ 29.79991594936709, -1.47584 ], [ 29.79991594936709, -1.475570517241379 ], [ 29.799646455696202, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28853, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.475570517241379 ], [ 29.79991594936709, -1.47584 ], [ 29.800185443037975, -1.47584 ], [ 29.800185443037975, -1.475570517241379 ], [ 29.79991594936709, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28854, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.475570517241379 ], [ 29.800185443037975, -1.47584 ], [ 29.80045493670886, -1.47584 ], [ 29.80045493670886, -1.475570517241379 ], [ 29.800185443037975, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28855, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.475570517241379 ], [ 29.80045493670886, -1.47584 ], [ 29.800724430379748, -1.47584 ], [ 29.800724430379748, -1.475570517241379 ], [ 29.80045493670886, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28856, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.475570517241379 ], [ 29.800724430379748, -1.47584 ], [ 29.800993924050633, -1.47584 ], [ 29.800993924050633, -1.475570517241379 ], [ 29.800724430379748, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28857, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.475570517241379 ], [ 29.800993924050633, -1.47584 ], [ 29.801263417721518, -1.47584 ], [ 29.801263417721518, -1.475570517241379 ], [ 29.800993924050633, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28858, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.475570517241379 ], [ 29.801263417721518, -1.47584 ], [ 29.801532911392407, -1.47584 ], [ 29.801532911392407, -1.475570517241379 ], [ 29.801263417721518, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28859, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.475570517241379 ], [ 29.801532911392407, -1.47584 ], [ 29.802071898734177, -1.47584 ], [ 29.802071898734177, -1.475570517241379 ], [ 29.801532911392407, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28860, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.475570517241379 ], [ 29.802071898734177, -1.47584 ], [ 29.802341392405065, -1.47584 ], [ 29.802341392405065, -1.475570517241379 ], [ 29.802071898734177, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28861, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.475570517241379 ], [ 29.802341392405065, -1.47584 ], [ 29.80261088607595, -1.47584 ], [ 29.80261088607595, -1.475570517241379 ], [ 29.802341392405065, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28862, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.475570517241379 ], [ 29.80261088607595, -1.47584 ], [ 29.802880379746835, -1.47584 ], [ 29.802880379746835, -1.475570517241379 ], [ 29.80261088607595, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28863, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.475570517241379 ], [ 29.802880379746835, -1.47584 ], [ 29.803149873417723, -1.47584 ], [ 29.803149873417723, -1.475570517241379 ], [ 29.802880379746835, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28864, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.475570517241379 ], [ 29.803149873417723, -1.47584 ], [ 29.803419367088608, -1.47584 ], [ 29.803419367088608, -1.475570517241379 ], [ 29.803149873417723, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28865, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.475570517241379 ], [ 29.803419367088608, -1.47584 ], [ 29.803688860759493, -1.47584 ], [ 29.803688860759493, -1.475570517241379 ], [ 29.803419367088608, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28866, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.475570517241379 ], [ 29.803688860759493, -1.47584 ], [ 29.803958354430382, -1.47584 ], [ 29.803958354430382, -1.475570517241379 ], [ 29.803688860759493, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28867, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.475570517241379 ], [ 29.803958354430382, -1.47584 ], [ 29.804227848101267, -1.47584 ], [ 29.804227848101267, -1.475570517241379 ], [ 29.803958354430382, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28868, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.475570517241379 ], [ 29.804227848101267, -1.47584 ], [ 29.804497341772151, -1.47584 ], [ 29.804497341772151, -1.475570517241379 ], [ 29.804227848101267, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28869, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.475570517241379 ], [ 29.804497341772151, -1.47584 ], [ 29.80476683544304, -1.47584 ], [ 29.80476683544304, -1.475570517241379 ], [ 29.804497341772151, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28870, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.475570517241379 ], [ 29.80476683544304, -1.47584 ], [ 29.805575316455698, -1.47584 ], [ 29.805575316455698, -1.475570517241379 ], [ 29.80476683544304, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28871, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.475570517241379 ], [ 29.805575316455698, -1.47584 ], [ 29.805844810126583, -1.47584 ], [ 29.805844810126583, -1.475570517241379 ], [ 29.805575316455698, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28872, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.475570517241379 ], [ 29.805844810126583, -1.47584 ], [ 29.806114303797468, -1.47584 ], [ 29.806114303797468, -1.476109482758621 ], [ 29.806383797468357, -1.476109482758621 ], [ 29.806383797468357, -1.475570517241379 ], [ 29.805844810126583, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28873, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758144430379748, -1.47584 ], [ 29.758144430379748, -1.476109482758621 ], [ 29.758413924050632, -1.476109482758621 ], [ 29.758413924050632, -1.47584 ], [ 29.758144430379748, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28874, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.47584 ], [ 29.758413924050632, -1.476109482758621 ], [ 29.758683417721517, -1.476109482758621 ], [ 29.758683417721517, -1.47584 ], [ 29.758413924050632, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28875, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.47584 ], [ 29.758683417721517, -1.476109482758621 ], [ 29.758952911392406, -1.476109482758621 ], [ 29.758952911392406, -1.47584 ], [ 29.758683417721517, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28876, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.47584 ], [ 29.758952911392406, -1.476109482758621 ], [ 29.759222405063291, -1.476109482758621 ], [ 29.759222405063291, -1.47584 ], [ 29.758952911392406, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28877, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.47584 ], [ 29.759222405063291, -1.476109482758621 ], [ 29.759491898734176, -1.476109482758621 ], [ 29.759491898734176, -1.47584 ], [ 29.759222405063291, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28878, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.47584 ], [ 29.759491898734176, -1.476109482758621 ], [ 29.759761392405064, -1.476109482758621 ], [ 29.759761392405064, -1.47584 ], [ 29.759491898734176, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28879, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.47584 ], [ 29.759761392405064, -1.476109482758621 ], [ 29.760030886075949, -1.476109482758621 ], [ 29.760030886075949, -1.47584 ], [ 29.759761392405064, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28880, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.47584 ], [ 29.760030886075949, -1.476109482758621 ], [ 29.760300379746834, -1.476109482758621 ], [ 29.760300379746834, -1.47584 ], [ 29.760030886075949, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28881, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.47584 ], [ 29.760300379746834, -1.476109482758621 ], [ 29.760569873417722, -1.476109482758621 ], [ 29.760569873417722, -1.47584 ], [ 29.760300379746834, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28882, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.47584 ], [ 29.760569873417722, -1.476109482758621 ], [ 29.760839367088607, -1.476109482758621 ], [ 29.760839367088607, -1.47584 ], [ 29.760569873417722, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28883, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.47584 ], [ 29.760839367088607, -1.476109482758621 ], [ 29.761108860759492, -1.476109482758621 ], [ 29.761108860759492, -1.47584 ], [ 29.760839367088607, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28884, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.47584 ], [ 29.761108860759492, -1.476109482758621 ], [ 29.761378354430381, -1.476109482758621 ], [ 29.761378354430381, -1.47584 ], [ 29.761108860759492, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28885, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.47584 ], [ 29.761378354430381, -1.476109482758621 ], [ 29.761647848101266, -1.476109482758621 ], [ 29.761647848101266, -1.47584 ], [ 29.761378354430381, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28886, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.47584 ], [ 29.761647848101266, -1.476109482758621 ], [ 29.761917341772151, -1.476109482758621 ], [ 29.761917341772151, -1.47584 ], [ 29.761647848101266, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28887, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.47584 ], [ 29.761917341772151, -1.476109482758621 ], [ 29.762186835443039, -1.476109482758621 ], [ 29.762186835443039, -1.47584 ], [ 29.761917341772151, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28888, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.47584 ], [ 29.762186835443039, -1.476109482758621 ], [ 29.762456329113924, -1.476109482758621 ], [ 29.762456329113924, -1.47584 ], [ 29.762186835443039, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28889, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.47584 ], [ 29.762456329113924, -1.476109482758621 ], [ 29.762725822784809, -1.476109482758621 ], [ 29.762725822784809, -1.47584 ], [ 29.762456329113924, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28890, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.47584 ], [ 29.762725822784809, -1.476109482758621 ], [ 29.762995316455697, -1.476109482758621 ], [ 29.762995316455697, -1.47584 ], [ 29.762725822784809, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28891, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.47584 ], [ 29.762995316455697, -1.476109482758621 ], [ 29.763264810126582, -1.476109482758621 ], [ 29.763264810126582, -1.47584 ], [ 29.762995316455697, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28892, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.47584 ], [ 29.766498734177215, -1.476109482758621 ], [ 29.7667682278481, -1.476109482758621 ], [ 29.7667682278481, -1.47584 ], [ 29.766498734177215, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28893, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.47584 ], [ 29.7667682278481, -1.476109482758621 ], [ 29.767037721518985, -1.476109482758621 ], [ 29.767037721518985, -1.47584 ], [ 29.7667682278481, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28894, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.47584 ], [ 29.767037721518985, -1.476109482758621 ], [ 29.767307215189874, -1.476109482758621 ], [ 29.767307215189874, -1.47584 ], [ 29.767037721518985, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28895, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.47584 ], [ 29.767307215189874, -1.476109482758621 ], [ 29.767576708860759, -1.476109482758621 ], [ 29.767576708860759, -1.47584 ], [ 29.767307215189874, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28896, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.47584 ], [ 29.778895443037975, -1.476109482758621 ], [ 29.77916493670886, -1.476109482758621 ], [ 29.77916493670886, -1.47584 ], [ 29.778895443037975, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28897, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.47584 ], [ 29.77916493670886, -1.476109482758621 ], [ 29.779434430379748, -1.476109482758621 ], [ 29.779434430379748, -1.47584 ], [ 29.77916493670886, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28898, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.47584 ], [ 29.779434430379748, -1.476109482758621 ], [ 29.779703924050633, -1.476109482758621 ], [ 29.779703924050633, -1.47584 ], [ 29.779434430379748, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28899, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.47584 ], [ 29.779703924050633, -1.476109482758621 ], [ 29.779973417721518, -1.476109482758621 ], [ 29.779973417721518, -1.47584 ], [ 29.779703924050633, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28900, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.47584 ], [ 29.779973417721518, -1.476109482758621 ], [ 29.780242911392406, -1.476109482758621 ], [ 29.780242911392406, -1.47584 ], [ 29.779973417721518, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28901, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.47584 ], [ 29.780242911392406, -1.476109482758621 ], [ 29.780512405063291, -1.476109482758621 ], [ 29.780512405063291, -1.47584 ], [ 29.780242911392406, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28902, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.47584 ], [ 29.780512405063291, -1.476109482758621 ], [ 29.780781898734176, -1.476109482758621 ], [ 29.780781898734176, -1.47584 ], [ 29.780512405063291, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28903, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.47584 ], [ 29.780781898734176, -1.476109482758621 ], [ 29.781051392405065, -1.476109482758621 ], [ 29.781051392405065, -1.47584 ], [ 29.780781898734176, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28904, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.47584 ], [ 29.781051392405065, -1.476109482758621 ], [ 29.78132088607595, -1.476109482758621 ], [ 29.78132088607595, -1.47584 ], [ 29.781051392405065, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28905, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.47584 ], [ 29.78132088607595, -1.476109482758621 ], [ 29.781590379746834, -1.476109482758621 ], [ 29.781590379746834, -1.47584 ], [ 29.78132088607595, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28906, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.47584 ], [ 29.781590379746834, -1.476109482758621 ], [ 29.781859873417723, -1.476109482758621 ], [ 29.781859873417723, -1.47584 ], [ 29.781590379746834, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28907, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.47584 ], [ 29.781859873417723, -1.476109482758621 ], [ 29.782129367088608, -1.476109482758621 ], [ 29.782129367088608, -1.47584 ], [ 29.781859873417723, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28908, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.47584 ], [ 29.782129367088608, -1.476109482758621 ], [ 29.782398860759493, -1.476109482758621 ], [ 29.782398860759493, -1.47584 ], [ 29.782129367088608, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28909, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.47584 ], [ 29.782398860759493, -1.476109482758621 ], [ 29.782668354430381, -1.476109482758621 ], [ 29.782668354430381, -1.47584 ], [ 29.782398860759493, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28910, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.47584 ], [ 29.782668354430381, -1.476109482758621 ], [ 29.782937848101266, -1.476109482758621 ], [ 29.782937848101266, -1.47584 ], [ 29.782668354430381, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28911, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.47584 ], [ 29.782937848101266, -1.476109482758621 ], [ 29.783207341772151, -1.476109482758621 ], [ 29.783207341772151, -1.47584 ], [ 29.782937848101266, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28912, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.47584 ], [ 29.783207341772151, -1.476109482758621 ], [ 29.783476835443039, -1.476109482758621 ], [ 29.783476835443039, -1.47584 ], [ 29.783207341772151, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28913, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.47584 ], [ 29.783476835443039, -1.476109482758621 ], [ 29.784015822784809, -1.476109482758621 ], [ 29.784015822784809, -1.47584 ], [ 29.783476835443039, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28914, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.47584 ], [ 29.784015822784809, -1.476109482758621 ], [ 29.784285316455698, -1.476109482758621 ], [ 29.784285316455698, -1.47584 ], [ 29.784015822784809, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28915, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.47584 ], [ 29.784285316455698, -1.476109482758621 ], [ 29.784554810126583, -1.476109482758621 ], [ 29.784554810126583, -1.47584 ], [ 29.784285316455698, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28916, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.47584 ], [ 29.784554810126583, -1.476109482758621 ], [ 29.784824303797468, -1.476109482758621 ], [ 29.784824303797468, -1.47584 ], [ 29.784554810126583, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28917, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.47584 ], [ 29.784824303797468, -1.476109482758621 ], [ 29.785093797468356, -1.476109482758621 ], [ 29.785093797468356, -1.47584 ], [ 29.784824303797468, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28918, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.47584 ], [ 29.785093797468356, -1.476109482758621 ], [ 29.785363291139241, -1.476109482758621 ], [ 29.785363291139241, -1.47584 ], [ 29.785093797468356, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28919, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.47584 ], [ 29.785363291139241, -1.476109482758621 ], [ 29.785632784810126, -1.476109482758621 ], [ 29.785632784810126, -1.47584 ], [ 29.785363291139241, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28920, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.474762068965517 ], [ 29.787519240506327, -1.474762068965517 ], [ 29.787519240506327, -1.476109482758621 ], [ 29.785632784810126, -1.476109482758621 ], [ 29.785632784810126, -1.47584 ], [ 29.787249746835442, -1.47584 ], [ 29.787249746835442, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28921, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.47584 ], [ 29.787519240506327, -1.476109482758621 ], [ 29.787788734177216, -1.476109482758621 ], [ 29.787788734177216, -1.47584 ], [ 29.787519240506327, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28922, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.47584 ], [ 29.797221012658227, -1.476109482758621 ], [ 29.797490506329115, -1.476109482758621 ], [ 29.797490506329115, -1.47584 ], [ 29.797221012658227, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28923, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.47584 ], [ 29.797490506329115, -1.476109482758621 ], [ 29.79776, -1.476109482758621 ], [ 29.79776, -1.47584 ], [ 29.797490506329115, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28924, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.47584 ], [ 29.79776, -1.476109482758621 ], [ 29.798029493670885, -1.476109482758621 ], [ 29.798029493670885, -1.47584 ], [ 29.79776, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28925, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.47584 ], [ 29.798029493670885, -1.476109482758621 ], [ 29.798298987341774, -1.476109482758621 ], [ 29.798298987341774, -1.47584 ], [ 29.798029493670885, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28926, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.47584 ], [ 29.798298987341774, -1.476109482758621 ], [ 29.798568481012659, -1.476109482758621 ], [ 29.798568481012659, -1.47584 ], [ 29.798298987341774, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28927, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.47584 ], [ 29.798568481012659, -1.476109482758621 ], [ 29.798837974683543, -1.476109482758621 ], [ 29.798837974683543, -1.47584 ], [ 29.798568481012659, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28928, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.47584 ], [ 29.798837974683543, -1.476109482758621 ], [ 29.799107468354432, -1.476109482758621 ], [ 29.799107468354432, -1.47584 ], [ 29.798837974683543, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28929, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.47584 ], [ 29.799107468354432, -1.476109482758621 ], [ 29.799376962025317, -1.476109482758621 ], [ 29.799376962025317, -1.47584 ], [ 29.799107468354432, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28930, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.47584 ], [ 29.799376962025317, -1.476109482758621 ], [ 29.799646455696202, -1.476109482758621 ], [ 29.799646455696202, -1.47584 ], [ 29.799376962025317, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28931, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.47584 ], [ 29.799646455696202, -1.476109482758621 ], [ 29.79991594936709, -1.476109482758621 ], [ 29.79991594936709, -1.47584 ], [ 29.799646455696202, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28932, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.47584 ], [ 29.79991594936709, -1.476109482758621 ], [ 29.800185443037975, -1.476109482758621 ], [ 29.800185443037975, -1.47584 ], [ 29.79991594936709, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28933, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.47584 ], [ 29.800185443037975, -1.476109482758621 ], [ 29.800724430379748, -1.476109482758621 ], [ 29.800724430379748, -1.47584 ], [ 29.800185443037975, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28934, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.47584 ], [ 29.800724430379748, -1.476109482758621 ], [ 29.800993924050633, -1.476109482758621 ], [ 29.800993924050633, -1.47584 ], [ 29.800724430379748, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28935, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.47584 ], [ 29.800993924050633, -1.476109482758621 ], [ 29.801263417721518, -1.476109482758621 ], [ 29.801263417721518, -1.47584 ], [ 29.800993924050633, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28936, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.47584 ], [ 29.801263417721518, -1.476109482758621 ], [ 29.801532911392407, -1.476109482758621 ], [ 29.801532911392407, -1.47584 ], [ 29.801263417721518, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28937, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.47584 ], [ 29.801532911392407, -1.476109482758621 ], [ 29.802071898734177, -1.476109482758621 ], [ 29.802071898734177, -1.47584 ], [ 29.801532911392407, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28938, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.47584 ], [ 29.802071898734177, -1.476109482758621 ], [ 29.802341392405065, -1.476109482758621 ], [ 29.802341392405065, -1.47584 ], [ 29.802071898734177, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28939, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.47584 ], [ 29.802341392405065, -1.476109482758621 ], [ 29.80261088607595, -1.476109482758621 ], [ 29.80261088607595, -1.47584 ], [ 29.802341392405065, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28940, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.47584 ], [ 29.80261088607595, -1.476109482758621 ], [ 29.802880379746835, -1.476109482758621 ], [ 29.802880379746835, -1.47584 ], [ 29.80261088607595, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28941, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.47584 ], [ 29.802880379746835, -1.476109482758621 ], [ 29.803149873417723, -1.476109482758621 ], [ 29.803149873417723, -1.47584 ], [ 29.802880379746835, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28942, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.47584 ], [ 29.803149873417723, -1.476109482758621 ], [ 29.803419367088608, -1.476109482758621 ], [ 29.803419367088608, -1.47584 ], [ 29.803149873417723, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28943, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.47584 ], [ 29.803419367088608, -1.476109482758621 ], [ 29.803688860759493, -1.476109482758621 ], [ 29.803688860759493, -1.47584 ], [ 29.803419367088608, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28944, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.47584 ], [ 29.803688860759493, -1.476109482758621 ], [ 29.803958354430382, -1.476109482758621 ], [ 29.803958354430382, -1.47584 ], [ 29.803688860759493, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28945, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.47584 ], [ 29.803958354430382, -1.476109482758621 ], [ 29.804227848101267, -1.476109482758621 ], [ 29.804227848101267, -1.47584 ], [ 29.803958354430382, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28946, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.47584 ], [ 29.804227848101267, -1.476109482758621 ], [ 29.804497341772151, -1.476109482758621 ], [ 29.804497341772151, -1.47584 ], [ 29.804227848101267, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28947, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.47584 ], [ 29.804497341772151, -1.476109482758621 ], [ 29.80476683544304, -1.476109482758621 ], [ 29.80476683544304, -1.47584 ], [ 29.804497341772151, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28948, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.47584 ], [ 29.80476683544304, -1.476109482758621 ], [ 29.805575316455698, -1.476109482758621 ], [ 29.805575316455698, -1.47584 ], [ 29.80476683544304, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28949, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.47584 ], [ 29.805844810126583, -1.476109482758621 ], [ 29.806114303797468, -1.476109482758621 ], [ 29.806114303797468, -1.47584 ], [ 29.805844810126583, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28951, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796412531645569, -1.473414655172414 ], [ 29.796412531645569, -1.476378965517241 ], [ 29.796682025316457, -1.476378965517241 ], [ 29.796682025316457, -1.473414655172414 ], [ 29.796412531645569, -1.473414655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28952, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.474223103448276 ], [ 29.775931012658226, -1.477187413793104 ], [ 29.776200506329115, -1.477187413793104 ], [ 29.776200506329115, -1.474223103448276 ], [ 29.775931012658226, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28953, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.474223103448276 ], [ 29.776739493670885, -1.477187413793104 ], [ 29.777008987341773, -1.477187413793104 ], [ 29.777008987341773, -1.474223103448276 ], [ 29.776739493670885, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28954, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.474223103448276 ], [ 29.777278481012658, -1.477187413793104 ], [ 29.777547974683543, -1.477187413793104 ], [ 29.777547974683543, -1.474223103448276 ], [ 29.777278481012658, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28955, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.474223103448276 ], [ 29.778086962025316, -1.477187413793104 ], [ 29.778356455696201, -1.477187413793104 ], [ 29.778356455696201, -1.474223103448276 ], [ 29.778086962025316, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28956, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.474223103448276 ], [ 29.77862594936709, -1.476917931034483 ], [ 29.778895443037975, -1.476917931034483 ], [ 29.778895443037975, -1.476378965517241 ], [ 29.77916493670886, -1.476378965517241 ], [ 29.77916493670886, -1.476109482758621 ], [ 29.778895443037975, -1.476109482758621 ], [ 29.778895443037975, -1.474223103448276 ], [ 29.77862594936709, -1.474223103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28957, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.474492586206897 ], [ 29.772966582278482, -1.476917931034483 ], [ 29.773236075949367, -1.476917931034483 ], [ 29.773236075949367, -1.474492586206897 ], [ 29.772966582278482, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28958, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.474492586206897 ], [ 29.77377506329114, -1.476917931034483 ], [ 29.774044556962025, -1.476917931034483 ], [ 29.774044556962025, -1.474492586206897 ], [ 29.77377506329114, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28959, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.474492586206897 ], [ 29.77431405063291, -1.476917931034483 ], [ 29.774853037974683, -1.476917931034483 ], [ 29.774853037974683, -1.474492586206897 ], [ 29.77431405063291, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28960, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.474492586206897 ], [ 29.774853037974683, -1.476917931034483 ], [ 29.775122531645568, -1.476917931034483 ], [ 29.775122531645568, -1.474492586206897 ], [ 29.774853037974683, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28961, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.474492586206897 ], [ 29.775392025316457, -1.476917931034483 ], [ 29.775661518987341, -1.476917931034483 ], [ 29.775661518987341, -1.474492586206897 ], [ 29.775392025316457, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28962, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.474492586206897 ], [ 29.776200506329115, -1.476917931034483 ], [ 29.77647, -1.476917931034483 ], [ 29.77647, -1.474492586206897 ], [ 29.776200506329115, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28963, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.474492586206897 ], [ 29.777008987341773, -1.476917931034483 ], [ 29.777278481012658, -1.476917931034483 ], [ 29.777278481012658, -1.474492586206897 ], [ 29.777008987341773, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28964, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.474492586206897 ], [ 29.777547974683543, -1.476917931034483 ], [ 29.777817468354431, -1.476917931034483 ], [ 29.777817468354431, -1.474492586206897 ], [ 29.777547974683543, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28965, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.474492586206897 ], [ 29.778356455696201, -1.476917931034483 ], [ 29.77862594936709, -1.476917931034483 ], [ 29.77862594936709, -1.474492586206897 ], [ 29.778356455696201, -1.474492586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28966, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.474762068965517 ], [ 29.77161911392405, -1.476648448275862 ], [ 29.771888607594935, -1.476648448275862 ], [ 29.771888607594935, -1.474762068965517 ], [ 29.77161911392405, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28967, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.474762068965517 ], [ 29.771888607594935, -1.476648448275862 ], [ 29.772158101265823, -1.476648448275862 ], [ 29.772158101265823, -1.474762068965517 ], [ 29.771888607594935, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28968, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.474762068965517 ], [ 29.772427594936708, -1.476648448275862 ], [ 29.772697088607593, -1.476648448275862 ], [ 29.772697088607593, -1.474762068965517 ], [ 29.772427594936708, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28969, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.474762068965517 ], [ 29.772697088607593, -1.476648448275862 ], [ 29.772966582278482, -1.476648448275862 ], [ 29.772966582278482, -1.474762068965517 ], [ 29.772697088607593, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28970, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.474762068965517 ], [ 29.773236075949367, -1.476648448275862 ], [ 29.773505569620252, -1.476648448275862 ], [ 29.773505569620252, -1.474762068965517 ], [ 29.773236075949367, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28971, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.474762068965517 ], [ 29.774044556962025, -1.476648448275862 ], [ 29.77431405063291, -1.476648448275862 ], [ 29.77431405063291, -1.474762068965517 ], [ 29.774044556962025, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28972, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.474762068965517 ], [ 29.775122531645568, -1.476648448275862 ], [ 29.775392025316457, -1.476648448275862 ], [ 29.775392025316457, -1.474762068965517 ], [ 29.775122531645568, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28973, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.474762068965517 ], [ 29.775661518987341, -1.476648448275862 ], [ 29.775931012658226, -1.476648448275862 ], [ 29.775931012658226, -1.474762068965517 ], [ 29.775661518987341, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28974, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.474762068965517 ], [ 29.77647, -1.476648448275862 ], [ 29.776739493670885, -1.476648448275862 ], [ 29.776739493670885, -1.474762068965517 ], [ 29.77647, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28975, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.474762068965517 ], [ 29.777817468354431, -1.476648448275862 ], [ 29.778086962025316, -1.476648448275862 ], [ 29.778086962025316, -1.474762068965517 ], [ 29.777817468354431, -1.474762068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28976, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.475031551724138 ], [ 29.770810632911392, -1.476378965517241 ], [ 29.771080126582277, -1.476378965517241 ], [ 29.771080126582277, -1.475031551724138 ], [ 29.770810632911392, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28977, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.475031551724138 ], [ 29.771080126582277, -1.476378965517241 ], [ 29.771349620253165, -1.476378965517241 ], [ 29.771349620253165, -1.475031551724138 ], [ 29.771080126582277, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28978, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.475031551724138 ], [ 29.771349620253165, -1.476378965517241 ], [ 29.77161911392405, -1.476378965517241 ], [ 29.77161911392405, -1.475031551724138 ], [ 29.771349620253165, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28979, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.475031551724138 ], [ 29.772158101265823, -1.476378965517241 ], [ 29.772427594936708, -1.476378965517241 ], [ 29.772427594936708, -1.475031551724138 ], [ 29.772158101265823, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28980, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.475031551724138 ], [ 29.773505569620252, -1.476378965517241 ], [ 29.77377506329114, -1.476378965517241 ], [ 29.77377506329114, -1.475031551724138 ], [ 29.773505569620252, -1.475031551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28981, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.475301034482759 ], [ 29.763803797468356, -1.476378965517241 ], [ 29.76407329113924, -1.476378965517241 ], [ 29.76407329113924, -1.475301034482759 ], [ 29.763803797468356, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28982, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.475301034482759 ], [ 29.764612278481014, -1.476378965517241 ], [ 29.764881772151899, -1.476378965517241 ], [ 29.764881772151899, -1.475301034482759 ], [ 29.764612278481014, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28983, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.475301034482759 ], [ 29.789136202531644, -1.476378965517241 ], [ 29.789405696202532, -1.476378965517241 ], [ 29.789405696202532, -1.475301034482759 ], [ 29.789136202531644, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28984, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.475301034482759 ], [ 29.789405696202532, -1.476378965517241 ], [ 29.789675189873417, -1.476378965517241 ], [ 29.789675189873417, -1.475301034482759 ], [ 29.789405696202532, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28985, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.475301034482759 ], [ 29.789944683544302, -1.476648448275862 ], [ 29.790214177215191, -1.476648448275862 ], [ 29.790214177215191, -1.475301034482759 ], [ 29.789944683544302, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28986, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.475301034482759 ], [ 29.790214177215191, -1.476648448275862 ], [ 29.790483670886076, -1.476648448275862 ], [ 29.790483670886076, -1.475301034482759 ], [ 29.790214177215191, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28987, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.475301034482759 ], [ 29.805575316455698, -1.475570517241379 ], [ 29.806383797468357, -1.475570517241379 ], [ 29.806383797468357, -1.476378965517241 ], [ 29.806653291139241, -1.476378965517241 ], [ 29.806653291139241, -1.475301034482759 ], [ 29.805575316455698, -1.475301034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28988, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.475570517241379 ], [ 29.788327721518986, -1.476378965517241 ], [ 29.788597215189874, -1.476378965517241 ], [ 29.788597215189874, -1.475570517241379 ], [ 29.788327721518986, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28989, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.475570517241379 ], [ 29.789675189873417, -1.476648448275862 ], [ 29.789944683544302, -1.476648448275862 ], [ 29.789944683544302, -1.475570517241379 ], [ 29.789675189873417, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28990, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.475570517241379 ], [ 29.790483670886076, -1.476917931034483 ], [ 29.790753164556961, -1.476917931034483 ], [ 29.790753164556961, -1.475570517241379 ], [ 29.790483670886076, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28991, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.475570517241379 ], [ 29.790753164556961, -1.476917931034483 ], [ 29.791022658227849, -1.476917931034483 ], [ 29.791022658227849, -1.475570517241379 ], [ 29.790753164556961, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28992, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.475570517241379 ], [ 29.791022658227849, -1.477187413793104 ], [ 29.791292151898734, -1.477187413793104 ], [ 29.791292151898734, -1.475570517241379 ], [ 29.791022658227849, -1.475570517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28993, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.47584 ], [ 29.76407329113924, -1.476378965517241 ], [ 29.764342784810125, -1.476378965517241 ], [ 29.764342784810125, -1.47584 ], [ 29.76407329113924, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28994, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.47584 ], [ 29.765420759493672, -1.476378965517241 ], [ 29.765959746835442, -1.476378965517241 ], [ 29.765959746835442, -1.47584 ], [ 29.765420759493672, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28995, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.47584 ], [ 29.805575316455698, -1.476378965517241 ], [ 29.805844810126583, -1.476378965517241 ], [ 29.805844810126583, -1.47584 ], [ 29.805575316455698, -1.47584 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28996, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758413924050632, -1.476109482758621 ], [ 29.758413924050632, -1.476378965517241 ], [ 29.758683417721517, -1.476378965517241 ], [ 29.758683417721517, -1.476109482758621 ], [ 29.758413924050632, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28997, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.476109482758621 ], [ 29.758683417721517, -1.476378965517241 ], [ 29.758952911392406, -1.476378965517241 ], [ 29.758952911392406, -1.476109482758621 ], [ 29.758683417721517, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28998, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.476109482758621 ], [ 29.758952911392406, -1.476378965517241 ], [ 29.759222405063291, -1.476378965517241 ], [ 29.759222405063291, -1.476109482758621 ], [ 29.758952911392406, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 28999, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.476109482758621 ], [ 29.759222405063291, -1.476378965517241 ], [ 29.759491898734176, -1.476378965517241 ], [ 29.759491898734176, -1.476109482758621 ], [ 29.759222405063291, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29000, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.476109482758621 ], [ 29.759491898734176, -1.476378965517241 ], [ 29.759761392405064, -1.476378965517241 ], [ 29.759761392405064, -1.476109482758621 ], [ 29.759491898734176, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29001, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.476109482758621 ], [ 29.759761392405064, -1.476378965517241 ], [ 29.760030886075949, -1.476378965517241 ], [ 29.760030886075949, -1.476109482758621 ], [ 29.759761392405064, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29002, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.476109482758621 ], [ 29.760030886075949, -1.476378965517241 ], [ 29.760300379746834, -1.476378965517241 ], [ 29.760300379746834, -1.476109482758621 ], [ 29.760030886075949, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29003, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.476109482758621 ], [ 29.760300379746834, -1.476378965517241 ], [ 29.760569873417722, -1.476378965517241 ], [ 29.760569873417722, -1.476109482758621 ], [ 29.760300379746834, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29004, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.476109482758621 ], [ 29.760569873417722, -1.476378965517241 ], [ 29.760839367088607, -1.476378965517241 ], [ 29.760839367088607, -1.476109482758621 ], [ 29.760569873417722, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29005, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.476109482758621 ], [ 29.760839367088607, -1.476378965517241 ], [ 29.761108860759492, -1.476378965517241 ], [ 29.761108860759492, -1.476109482758621 ], [ 29.760839367088607, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29006, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.476109482758621 ], [ 29.761108860759492, -1.476378965517241 ], [ 29.761378354430381, -1.476378965517241 ], [ 29.761378354430381, -1.476109482758621 ], [ 29.761108860759492, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29007, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.476109482758621 ], [ 29.761378354430381, -1.476378965517241 ], [ 29.761647848101266, -1.476378965517241 ], [ 29.761647848101266, -1.476109482758621 ], [ 29.761378354430381, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29008, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.476109482758621 ], [ 29.761647848101266, -1.476378965517241 ], [ 29.761917341772151, -1.476378965517241 ], [ 29.761917341772151, -1.476109482758621 ], [ 29.761647848101266, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29009, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.476109482758621 ], [ 29.761917341772151, -1.476378965517241 ], [ 29.762186835443039, -1.476378965517241 ], [ 29.762186835443039, -1.476109482758621 ], [ 29.761917341772151, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29010, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.476109482758621 ], [ 29.762186835443039, -1.476378965517241 ], [ 29.762456329113924, -1.476378965517241 ], [ 29.762456329113924, -1.476109482758621 ], [ 29.762186835443039, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29011, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.476109482758621 ], [ 29.762456329113924, -1.476378965517241 ], [ 29.762725822784809, -1.476378965517241 ], [ 29.762725822784809, -1.476109482758621 ], [ 29.762456329113924, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29012, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.476109482758621 ], [ 29.762725822784809, -1.476378965517241 ], [ 29.762995316455697, -1.476378965517241 ], [ 29.762995316455697, -1.476109482758621 ], [ 29.762725822784809, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29013, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.476109482758621 ], [ 29.762995316455697, -1.476378965517241 ], [ 29.763264810126582, -1.476378965517241 ], [ 29.763264810126582, -1.476109482758621 ], [ 29.762995316455697, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29014, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.476109482758621 ], [ 29.763264810126582, -1.476378965517241 ], [ 29.763534303797467, -1.476378965517241 ], [ 29.763534303797467, -1.476109482758621 ], [ 29.763264810126582, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29015, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.476109482758621 ], [ 29.763534303797467, -1.476648448275862 ], [ 29.763803797468356, -1.476648448275862 ], [ 29.763803797468356, -1.476109482758621 ], [ 29.763534303797467, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29016, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.476109482758621 ], [ 29.764342784810125, -1.476648448275862 ], [ 29.764612278481014, -1.476648448275862 ], [ 29.764612278481014, -1.476109482758621 ], [ 29.764342784810125, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29017, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.476109482758621 ], [ 29.764881772151899, -1.476378965517241 ], [ 29.765151265822784, -1.476378965517241 ], [ 29.765151265822784, -1.476109482758621 ], [ 29.764881772151899, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29018, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.476109482758621 ], [ 29.765151265822784, -1.476378965517241 ], [ 29.765420759493672, -1.476378965517241 ], [ 29.765420759493672, -1.476109482758621 ], [ 29.765151265822784, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29019, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.476109482758621 ], [ 29.765959746835442, -1.476378965517241 ], [ 29.766229240506327, -1.476378965517241 ], [ 29.766229240506327, -1.476109482758621 ], [ 29.765959746835442, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29020, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.476109482758621 ], [ 29.766229240506327, -1.476378965517241 ], [ 29.766498734177215, -1.476378965517241 ], [ 29.766498734177215, -1.476109482758621 ], [ 29.766229240506327, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29021, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.476109482758621 ], [ 29.766498734177215, -1.476378965517241 ], [ 29.7667682278481, -1.476378965517241 ], [ 29.7667682278481, -1.476109482758621 ], [ 29.766498734177215, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29022, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.476109482758621 ], [ 29.7667682278481, -1.476378965517241 ], [ 29.767037721518985, -1.476378965517241 ], [ 29.767037721518985, -1.476109482758621 ], [ 29.7667682278481, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29023, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.476109482758621 ], [ 29.767037721518985, -1.476378965517241 ], [ 29.767307215189874, -1.476378965517241 ], [ 29.767307215189874, -1.476109482758621 ], [ 29.767037721518985, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29024, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.476109482758621 ], [ 29.767307215189874, -1.476378965517241 ], [ 29.767576708860759, -1.476378965517241 ], [ 29.767576708860759, -1.476109482758621 ], [ 29.767307215189874, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29025, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.476109482758621 ], [ 29.767576708860759, -1.476378965517241 ], [ 29.767846202531643, -1.476378965517241 ], [ 29.767846202531643, -1.476109482758621 ], [ 29.767576708860759, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29026, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.476109482758621 ], [ 29.77916493670886, -1.476378965517241 ], [ 29.779434430379748, -1.476378965517241 ], [ 29.779434430379748, -1.476109482758621 ], [ 29.77916493670886, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29027, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.476109482758621 ], [ 29.779434430379748, -1.476378965517241 ], [ 29.779703924050633, -1.476378965517241 ], [ 29.779703924050633, -1.476109482758621 ], [ 29.779434430379748, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29028, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.476109482758621 ], [ 29.779703924050633, -1.476378965517241 ], [ 29.779973417721518, -1.476378965517241 ], [ 29.779973417721518, -1.476109482758621 ], [ 29.779703924050633, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29029, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.476109482758621 ], [ 29.779973417721518, -1.476378965517241 ], [ 29.780242911392406, -1.476378965517241 ], [ 29.780242911392406, -1.476109482758621 ], [ 29.779973417721518, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29030, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.476109482758621 ], [ 29.780242911392406, -1.476378965517241 ], [ 29.780512405063291, -1.476378965517241 ], [ 29.780512405063291, -1.476109482758621 ], [ 29.780242911392406, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29031, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.476109482758621 ], [ 29.780512405063291, -1.476378965517241 ], [ 29.780781898734176, -1.476378965517241 ], [ 29.780781898734176, -1.476109482758621 ], [ 29.780512405063291, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29032, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.476109482758621 ], [ 29.780781898734176, -1.476378965517241 ], [ 29.781051392405065, -1.476378965517241 ], [ 29.781051392405065, -1.476109482758621 ], [ 29.780781898734176, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29033, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.476109482758621 ], [ 29.781051392405065, -1.476378965517241 ], [ 29.78132088607595, -1.476378965517241 ], [ 29.78132088607595, -1.476109482758621 ], [ 29.781051392405065, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29034, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.476109482758621 ], [ 29.78132088607595, -1.476378965517241 ], [ 29.781590379746834, -1.476378965517241 ], [ 29.781590379746834, -1.476109482758621 ], [ 29.78132088607595, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29035, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.476109482758621 ], [ 29.781590379746834, -1.476378965517241 ], [ 29.781859873417723, -1.476378965517241 ], [ 29.781859873417723, -1.476109482758621 ], [ 29.781590379746834, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29036, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.476109482758621 ], [ 29.781859873417723, -1.476378965517241 ], [ 29.782129367088608, -1.476378965517241 ], [ 29.782129367088608, -1.476109482758621 ], [ 29.781859873417723, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29037, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.476109482758621 ], [ 29.782129367088608, -1.476378965517241 ], [ 29.782398860759493, -1.476378965517241 ], [ 29.782398860759493, -1.476109482758621 ], [ 29.782129367088608, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29038, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.476109482758621 ], [ 29.782398860759493, -1.476378965517241 ], [ 29.782668354430381, -1.476378965517241 ], [ 29.782668354430381, -1.476109482758621 ], [ 29.782398860759493, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29039, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.476109482758621 ], [ 29.782668354430381, -1.476378965517241 ], [ 29.782937848101266, -1.476378965517241 ], [ 29.782937848101266, -1.476109482758621 ], [ 29.782668354430381, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29040, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.476109482758621 ], [ 29.782937848101266, -1.476378965517241 ], [ 29.783207341772151, -1.476378965517241 ], [ 29.783207341772151, -1.476109482758621 ], [ 29.782937848101266, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29041, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.476109482758621 ], [ 29.783207341772151, -1.476378965517241 ], [ 29.783476835443039, -1.476378965517241 ], [ 29.783476835443039, -1.476109482758621 ], [ 29.783207341772151, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29042, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.476109482758621 ], [ 29.783476835443039, -1.476378965517241 ], [ 29.784015822784809, -1.476378965517241 ], [ 29.784015822784809, -1.476109482758621 ], [ 29.783476835443039, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29043, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.476109482758621 ], [ 29.784015822784809, -1.476378965517241 ], [ 29.784285316455698, -1.476378965517241 ], [ 29.784285316455698, -1.476109482758621 ], [ 29.784015822784809, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29044, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.476109482758621 ], [ 29.784285316455698, -1.476378965517241 ], [ 29.784554810126583, -1.476378965517241 ], [ 29.784554810126583, -1.476109482758621 ], [ 29.784285316455698, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29045, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.476109482758621 ], [ 29.784554810126583, -1.476378965517241 ], [ 29.784824303797468, -1.476378965517241 ], [ 29.784824303797468, -1.476109482758621 ], [ 29.784554810126583, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29046, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.476109482758621 ], [ 29.784824303797468, -1.476378965517241 ], [ 29.785093797468356, -1.476378965517241 ], [ 29.785093797468356, -1.476109482758621 ], [ 29.784824303797468, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29047, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.476109482758621 ], [ 29.785093797468356, -1.476378965517241 ], [ 29.785632784810126, -1.476378965517241 ], [ 29.785632784810126, -1.476109482758621 ], [ 29.785093797468356, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29048, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.476109482758621 ], [ 29.785632784810126, -1.476378965517241 ], [ 29.787519240506327, -1.476378965517241 ], [ 29.787519240506327, -1.476109482758621 ], [ 29.785632784810126, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29049, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.476109482758621 ], [ 29.787519240506327, -1.476378965517241 ], [ 29.787788734177216, -1.476378965517241 ], [ 29.787788734177216, -1.476109482758621 ], [ 29.787519240506327, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29050, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.476109482758621 ], [ 29.787788734177216, -1.476378965517241 ], [ 29.788058227848101, -1.476378965517241 ], [ 29.788058227848101, -1.476109482758621 ], [ 29.787788734177216, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29051, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.476109482758621 ], [ 29.788058227848101, -1.476378965517241 ], [ 29.788327721518986, -1.476378965517241 ], [ 29.788327721518986, -1.476109482758621 ], [ 29.788058227848101, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29052, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.476109482758621 ], [ 29.788597215189874, -1.476378965517241 ], [ 29.788866708860759, -1.476378965517241 ], [ 29.788866708860759, -1.476109482758621 ], [ 29.788597215189874, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29053, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.476109482758621 ], [ 29.788866708860759, -1.476378965517241 ], [ 29.789136202531644, -1.476378965517241 ], [ 29.789136202531644, -1.476109482758621 ], [ 29.788866708860759, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29054, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.476109482758621 ], [ 29.796682025316457, -1.476378965517241 ], [ 29.796951518987342, -1.476378965517241 ], [ 29.796951518987342, -1.476109482758621 ], [ 29.796682025316457, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29055, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796951518987342, -1.476109482758621 ], [ 29.796951518987342, -1.476378965517241 ], [ 29.797221012658227, -1.476378965517241 ], [ 29.797221012658227, -1.476109482758621 ], [ 29.796951518987342, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29056, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.476109482758621 ], [ 29.797221012658227, -1.476378965517241 ], [ 29.797490506329115, -1.476378965517241 ], [ 29.797490506329115, -1.476109482758621 ], [ 29.797221012658227, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29057, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.476109482758621 ], [ 29.797490506329115, -1.476378965517241 ], [ 29.79776, -1.476378965517241 ], [ 29.79776, -1.476109482758621 ], [ 29.797490506329115, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29058, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.476109482758621 ], [ 29.79776, -1.476378965517241 ], [ 29.798029493670885, -1.476378965517241 ], [ 29.798029493670885, -1.476109482758621 ], [ 29.79776, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29059, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.476109482758621 ], [ 29.798029493670885, -1.476378965517241 ], [ 29.798298987341774, -1.476378965517241 ], [ 29.798298987341774, -1.476109482758621 ], [ 29.798029493670885, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29060, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.476109482758621 ], [ 29.798298987341774, -1.476378965517241 ], [ 29.798568481012659, -1.476378965517241 ], [ 29.798568481012659, -1.476109482758621 ], [ 29.798298987341774, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29061, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.476109482758621 ], [ 29.798568481012659, -1.476378965517241 ], [ 29.798837974683543, -1.476378965517241 ], [ 29.798837974683543, -1.476109482758621 ], [ 29.798568481012659, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29062, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.476109482758621 ], [ 29.798837974683543, -1.476378965517241 ], [ 29.799107468354432, -1.476378965517241 ], [ 29.799107468354432, -1.476109482758621 ], [ 29.798837974683543, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29063, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.476109482758621 ], [ 29.799107468354432, -1.476378965517241 ], [ 29.799376962025317, -1.476378965517241 ], [ 29.799376962025317, -1.476109482758621 ], [ 29.799107468354432, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29064, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.476109482758621 ], [ 29.799376962025317, -1.476378965517241 ], [ 29.799646455696202, -1.476378965517241 ], [ 29.799646455696202, -1.476109482758621 ], [ 29.799376962025317, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29065, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.476109482758621 ], [ 29.799646455696202, -1.476378965517241 ], [ 29.79991594936709, -1.476378965517241 ], [ 29.79991594936709, -1.476109482758621 ], [ 29.799646455696202, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29066, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.476109482758621 ], [ 29.79991594936709, -1.476648448275862 ], [ 29.800185443037975, -1.476648448275862 ], [ 29.800185443037975, -1.476109482758621 ], [ 29.79991594936709, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29067, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.476109482758621 ], [ 29.800185443037975, -1.476378965517241 ], [ 29.80045493670886, -1.476378965517241 ], [ 29.80045493670886, -1.476109482758621 ], [ 29.800185443037975, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29068, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.476109482758621 ], [ 29.80045493670886, -1.476917931034483 ], [ 29.800724430379748, -1.476917931034483 ], [ 29.800724430379748, -1.477995862068966 ], [ 29.800993924050633, -1.477995862068966 ], [ 29.800993924050633, -1.476648448275862 ], [ 29.800724430379748, -1.476648448275862 ], [ 29.800724430379748, -1.476109482758621 ], [ 29.80045493670886, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29069, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.476109482758621 ], [ 29.800724430379748, -1.476378965517241 ], [ 29.800993924050633, -1.476378965517241 ], [ 29.800993924050633, -1.476109482758621 ], [ 29.800724430379748, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29070, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.476109482758621 ], [ 29.800993924050633, -1.476378965517241 ], [ 29.801263417721518, -1.476378965517241 ], [ 29.801263417721518, -1.476109482758621 ], [ 29.800993924050633, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29071, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.476109482758621 ], [ 29.801263417721518, -1.476378965517241 ], [ 29.801532911392407, -1.476378965517241 ], [ 29.801532911392407, -1.476109482758621 ], [ 29.801263417721518, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29072, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.476109482758621 ], [ 29.801532911392407, -1.476378965517241 ], [ 29.802071898734177, -1.476378965517241 ], [ 29.802071898734177, -1.476109482758621 ], [ 29.801532911392407, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29073, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.476109482758621 ], [ 29.802071898734177, -1.476378965517241 ], [ 29.802341392405065, -1.476378965517241 ], [ 29.802341392405065, -1.476109482758621 ], [ 29.802071898734177, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29074, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.476109482758621 ], [ 29.802341392405065, -1.476378965517241 ], [ 29.80261088607595, -1.476378965517241 ], [ 29.80261088607595, -1.476109482758621 ], [ 29.802341392405065, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29075, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.476109482758621 ], [ 29.80261088607595, -1.476378965517241 ], [ 29.802880379746835, -1.476378965517241 ], [ 29.802880379746835, -1.476109482758621 ], [ 29.80261088607595, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29076, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.476109482758621 ], [ 29.802880379746835, -1.476648448275862 ], [ 29.803149873417723, -1.476648448275862 ], [ 29.803149873417723, -1.476109482758621 ], [ 29.802880379746835, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29077, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.476109482758621 ], [ 29.803149873417723, -1.476378965517241 ], [ 29.803419367088608, -1.476378965517241 ], [ 29.803419367088608, -1.476109482758621 ], [ 29.803149873417723, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29078, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.476109482758621 ], [ 29.803419367088608, -1.476378965517241 ], [ 29.803688860759493, -1.476378965517241 ], [ 29.803688860759493, -1.476109482758621 ], [ 29.803419367088608, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29079, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.476109482758621 ], [ 29.803688860759493, -1.476378965517241 ], [ 29.803958354430382, -1.476378965517241 ], [ 29.803958354430382, -1.476109482758621 ], [ 29.803688860759493, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29080, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.476109482758621 ], [ 29.803958354430382, -1.476378965517241 ], [ 29.804227848101267, -1.476378965517241 ], [ 29.804227848101267, -1.476109482758621 ], [ 29.803958354430382, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29081, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.476109482758621 ], [ 29.804227848101267, -1.476378965517241 ], [ 29.804497341772151, -1.476378965517241 ], [ 29.804497341772151, -1.476109482758621 ], [ 29.804227848101267, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29082, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.476109482758621 ], [ 29.804497341772151, -1.476378965517241 ], [ 29.80476683544304, -1.476378965517241 ], [ 29.80476683544304, -1.476109482758621 ], [ 29.804497341772151, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29083, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.476109482758621 ], [ 29.80476683544304, -1.476378965517241 ], [ 29.805575316455698, -1.476378965517241 ], [ 29.805575316455698, -1.476109482758621 ], [ 29.80476683544304, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29084, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.476109482758621 ], [ 29.805844810126583, -1.476378965517241 ], [ 29.806114303797468, -1.476378965517241 ], [ 29.806114303797468, -1.476109482758621 ], [ 29.805844810126583, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29085, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.806114303797468, -1.476109482758621 ], [ 29.806114303797468, -1.476378965517241 ], [ 29.806383797468357, -1.476378965517241 ], [ 29.806383797468357, -1.476109482758621 ], [ 29.806114303797468, -1.476109482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29086, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758683417721517, -1.476378965517241 ], [ 29.758683417721517, -1.476648448275862 ], [ 29.758952911392406, -1.476648448275862 ], [ 29.758952911392406, -1.476378965517241 ], [ 29.758683417721517, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29087, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.476378965517241 ], [ 29.758952911392406, -1.476648448275862 ], [ 29.759222405063291, -1.476648448275862 ], [ 29.759222405063291, -1.476378965517241 ], [ 29.758952911392406, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29088, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.476378965517241 ], [ 29.759222405063291, -1.476648448275862 ], [ 29.759491898734176, -1.476648448275862 ], [ 29.759491898734176, -1.476378965517241 ], [ 29.759222405063291, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29089, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.476378965517241 ], [ 29.759491898734176, -1.476648448275862 ], [ 29.759761392405064, -1.476648448275862 ], [ 29.759761392405064, -1.476378965517241 ], [ 29.759491898734176, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29090, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.476378965517241 ], [ 29.759761392405064, -1.476648448275862 ], [ 29.760030886075949, -1.476648448275862 ], [ 29.760030886075949, -1.476378965517241 ], [ 29.759761392405064, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29091, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.476378965517241 ], [ 29.760030886075949, -1.476648448275862 ], [ 29.760300379746834, -1.476648448275862 ], [ 29.760300379746834, -1.476378965517241 ], [ 29.760030886075949, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29092, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.476378965517241 ], [ 29.760300379746834, -1.476648448275862 ], [ 29.760569873417722, -1.476648448275862 ], [ 29.760569873417722, -1.476378965517241 ], [ 29.760300379746834, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29093, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.476378965517241 ], [ 29.760569873417722, -1.476648448275862 ], [ 29.760839367088607, -1.476648448275862 ], [ 29.760839367088607, -1.476378965517241 ], [ 29.760569873417722, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29094, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.476378965517241 ], [ 29.760839367088607, -1.476648448275862 ], [ 29.761108860759492, -1.476648448275862 ], [ 29.761108860759492, -1.476378965517241 ], [ 29.760839367088607, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29095, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.476378965517241 ], [ 29.761108860759492, -1.476648448275862 ], [ 29.761378354430381, -1.476648448275862 ], [ 29.761378354430381, -1.476378965517241 ], [ 29.761108860759492, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29096, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.476378965517241 ], [ 29.761378354430381, -1.476648448275862 ], [ 29.761647848101266, -1.476648448275862 ], [ 29.761647848101266, -1.476378965517241 ], [ 29.761378354430381, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29097, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.476378965517241 ], [ 29.761647848101266, -1.476648448275862 ], [ 29.761917341772151, -1.476648448275862 ], [ 29.761917341772151, -1.476378965517241 ], [ 29.761647848101266, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29098, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.476378965517241 ], [ 29.761917341772151, -1.476648448275862 ], [ 29.762186835443039, -1.476648448275862 ], [ 29.762186835443039, -1.476378965517241 ], [ 29.761917341772151, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29099, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.476378965517241 ], [ 29.762186835443039, -1.476648448275862 ], [ 29.762456329113924, -1.476648448275862 ], [ 29.762456329113924, -1.476378965517241 ], [ 29.762186835443039, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29100, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.476378965517241 ], [ 29.762456329113924, -1.476648448275862 ], [ 29.762725822784809, -1.476648448275862 ], [ 29.762725822784809, -1.476378965517241 ], [ 29.762456329113924, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29101, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.476378965517241 ], [ 29.762725822784809, -1.476648448275862 ], [ 29.762995316455697, -1.476648448275862 ], [ 29.762995316455697, -1.476378965517241 ], [ 29.762725822784809, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29102, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.476378965517241 ], [ 29.762995316455697, -1.476648448275862 ], [ 29.763264810126582, -1.476648448275862 ], [ 29.763264810126582, -1.476378965517241 ], [ 29.762995316455697, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29103, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.476378965517241 ], [ 29.763264810126582, -1.476648448275862 ], [ 29.763534303797467, -1.476648448275862 ], [ 29.763534303797467, -1.476378965517241 ], [ 29.763264810126582, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29104, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.476378965517241 ], [ 29.763803797468356, -1.476648448275862 ], [ 29.76407329113924, -1.476648448275862 ], [ 29.76407329113924, -1.476378965517241 ], [ 29.763803797468356, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29105, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.476378965517241 ], [ 29.76407329113924, -1.476648448275862 ], [ 29.764342784810125, -1.476648448275862 ], [ 29.764342784810125, -1.476378965517241 ], [ 29.76407329113924, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29106, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.476378965517241 ], [ 29.764612278481014, -1.476648448275862 ], [ 29.764881772151899, -1.476648448275862 ], [ 29.764881772151899, -1.476378965517241 ], [ 29.764612278481014, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29107, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.476378965517241 ], [ 29.764881772151899, -1.476648448275862 ], [ 29.765151265822784, -1.476648448275862 ], [ 29.765151265822784, -1.476378965517241 ], [ 29.764881772151899, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29108, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.476378965517241 ], [ 29.765151265822784, -1.476648448275862 ], [ 29.765420759493672, -1.476648448275862 ], [ 29.765420759493672, -1.476378965517241 ], [ 29.765151265822784, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29109, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.476378965517241 ], [ 29.765420759493672, -1.476648448275862 ], [ 29.765959746835442, -1.476648448275862 ], [ 29.765959746835442, -1.476378965517241 ], [ 29.765420759493672, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29110, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.476378965517241 ], [ 29.765959746835442, -1.476648448275862 ], [ 29.766229240506327, -1.476648448275862 ], [ 29.766229240506327, -1.476378965517241 ], [ 29.765959746835442, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29111, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.476378965517241 ], [ 29.766229240506327, -1.476648448275862 ], [ 29.766498734177215, -1.476648448275862 ], [ 29.766498734177215, -1.476378965517241 ], [ 29.766229240506327, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29112, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.476378965517241 ], [ 29.766498734177215, -1.476648448275862 ], [ 29.7667682278481, -1.476648448275862 ], [ 29.7667682278481, -1.476378965517241 ], [ 29.766498734177215, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29113, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.476378965517241 ], [ 29.7667682278481, -1.476648448275862 ], [ 29.767037721518985, -1.476648448275862 ], [ 29.767037721518985, -1.476378965517241 ], [ 29.7667682278481, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29114, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.476378965517241 ], [ 29.767037721518985, -1.476648448275862 ], [ 29.767307215189874, -1.476648448275862 ], [ 29.767307215189874, -1.476378965517241 ], [ 29.767037721518985, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29115, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.476378965517241 ], [ 29.767307215189874, -1.476648448275862 ], [ 29.767576708860759, -1.476648448275862 ], [ 29.767576708860759, -1.476378965517241 ], [ 29.767307215189874, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29116, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.476378965517241 ], [ 29.767576708860759, -1.476648448275862 ], [ 29.767846202531643, -1.476648448275862 ], [ 29.767846202531643, -1.476378965517241 ], [ 29.767576708860759, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29117, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.476378965517241 ], [ 29.767846202531643, -1.476917931034483 ], [ 29.768115696202532, -1.476917931034483 ], [ 29.768115696202532, -1.476378965517241 ], [ 29.767846202531643, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29118, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.476378965517241 ], [ 29.770541139240507, -1.476648448275862 ], [ 29.770810632911392, -1.476648448275862 ], [ 29.770810632911392, -1.476378965517241 ], [ 29.770541139240507, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29119, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.476378965517241 ], [ 29.770810632911392, -1.476648448275862 ], [ 29.771080126582277, -1.476648448275862 ], [ 29.771080126582277, -1.476378965517241 ], [ 29.770810632911392, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29120, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.476378965517241 ], [ 29.771080126582277, -1.476648448275862 ], [ 29.771349620253165, -1.476648448275862 ], [ 29.771349620253165, -1.476378965517241 ], [ 29.771080126582277, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29121, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.476378965517241 ], [ 29.771349620253165, -1.476648448275862 ], [ 29.77161911392405, -1.476648448275862 ], [ 29.77161911392405, -1.476378965517241 ], [ 29.771349620253165, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29122, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.476378965517241 ], [ 29.772158101265823, -1.476917931034483 ], [ 29.772427594936708, -1.476917931034483 ], [ 29.772427594936708, -1.476378965517241 ], [ 29.772158101265823, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29123, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.476378965517241 ], [ 29.773505569620252, -1.476917931034483 ], [ 29.77377506329114, -1.476917931034483 ], [ 29.77377506329114, -1.476378965517241 ], [ 29.773505569620252, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29124, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.476378965517241 ], [ 29.778895443037975, -1.476648448275862 ], [ 29.77916493670886, -1.476648448275862 ], [ 29.77916493670886, -1.476378965517241 ], [ 29.778895443037975, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29125, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.476378965517241 ], [ 29.77916493670886, -1.476648448275862 ], [ 29.779434430379748, -1.476648448275862 ], [ 29.779434430379748, -1.476378965517241 ], [ 29.77916493670886, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29126, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.476378965517241 ], [ 29.779434430379748, -1.476648448275862 ], [ 29.779703924050633, -1.476648448275862 ], [ 29.779703924050633, -1.476378965517241 ], [ 29.779434430379748, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29127, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.476378965517241 ], [ 29.779703924050633, -1.476648448275862 ], [ 29.779973417721518, -1.476648448275862 ], [ 29.779973417721518, -1.476378965517241 ], [ 29.779703924050633, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29128, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.476378965517241 ], [ 29.779973417721518, -1.476648448275862 ], [ 29.780242911392406, -1.476648448275862 ], [ 29.780242911392406, -1.476378965517241 ], [ 29.779973417721518, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29129, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.476378965517241 ], [ 29.780242911392406, -1.476648448275862 ], [ 29.780512405063291, -1.476648448275862 ], [ 29.780512405063291, -1.476378965517241 ], [ 29.780242911392406, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29130, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.476378965517241 ], [ 29.780512405063291, -1.476648448275862 ], [ 29.780781898734176, -1.476648448275862 ], [ 29.780781898734176, -1.476378965517241 ], [ 29.780512405063291, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29131, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.476378965517241 ], [ 29.780781898734176, -1.476648448275862 ], [ 29.781051392405065, -1.476648448275862 ], [ 29.781051392405065, -1.476378965517241 ], [ 29.780781898734176, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29132, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.476378965517241 ], [ 29.781051392405065, -1.476648448275862 ], [ 29.78132088607595, -1.476648448275862 ], [ 29.78132088607595, -1.476378965517241 ], [ 29.781051392405065, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29133, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.476378965517241 ], [ 29.78132088607595, -1.476648448275862 ], [ 29.781590379746834, -1.476648448275862 ], [ 29.781590379746834, -1.476378965517241 ], [ 29.78132088607595, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29134, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.476378965517241 ], [ 29.781590379746834, -1.476648448275862 ], [ 29.781859873417723, -1.476648448275862 ], [ 29.781859873417723, -1.476378965517241 ], [ 29.781590379746834, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29135, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.476378965517241 ], [ 29.781859873417723, -1.476648448275862 ], [ 29.782129367088608, -1.476648448275862 ], [ 29.782129367088608, -1.476378965517241 ], [ 29.781859873417723, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29136, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.476378965517241 ], [ 29.782129367088608, -1.476648448275862 ], [ 29.782398860759493, -1.476648448275862 ], [ 29.782398860759493, -1.476378965517241 ], [ 29.782129367088608, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29137, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.476378965517241 ], [ 29.782398860759493, -1.476648448275862 ], [ 29.782668354430381, -1.476648448275862 ], [ 29.782668354430381, -1.476378965517241 ], [ 29.782398860759493, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29138, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.476378965517241 ], [ 29.782668354430381, -1.476648448275862 ], [ 29.782937848101266, -1.476648448275862 ], [ 29.782937848101266, -1.476378965517241 ], [ 29.782668354430381, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29139, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.476378965517241 ], [ 29.782937848101266, -1.476648448275862 ], [ 29.783207341772151, -1.476648448275862 ], [ 29.783207341772151, -1.476378965517241 ], [ 29.782937848101266, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29140, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.476378965517241 ], [ 29.783207341772151, -1.476648448275862 ], [ 29.783476835443039, -1.476648448275862 ], [ 29.783476835443039, -1.476378965517241 ], [ 29.783207341772151, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29141, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.476378965517241 ], [ 29.783476835443039, -1.476648448275862 ], [ 29.784015822784809, -1.476648448275862 ], [ 29.784015822784809, -1.476378965517241 ], [ 29.783476835443039, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29142, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.476378965517241 ], [ 29.784015822784809, -1.476648448275862 ], [ 29.784285316455698, -1.476648448275862 ], [ 29.784285316455698, -1.476378965517241 ], [ 29.784015822784809, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29143, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.476378965517241 ], [ 29.784285316455698, -1.476648448275862 ], [ 29.784554810126583, -1.476648448275862 ], [ 29.784554810126583, -1.476378965517241 ], [ 29.784285316455698, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29144, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.476378965517241 ], [ 29.784554810126583, -1.476648448275862 ], [ 29.784824303797468, -1.476648448275862 ], [ 29.784824303797468, -1.476378965517241 ], [ 29.784554810126583, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29145, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.476378965517241 ], [ 29.784824303797468, -1.476648448275862 ], [ 29.785093797468356, -1.476648448275862 ], [ 29.785093797468356, -1.476378965517241 ], [ 29.784824303797468, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29146, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.476378965517241 ], [ 29.785093797468356, -1.476648448275862 ], [ 29.785363291139241, -1.476648448275862 ], [ 29.785363291139241, -1.476378965517241 ], [ 29.785093797468356, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29147, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.476378965517241 ], [ 29.785363291139241, -1.476648448275862 ], [ 29.785902278481014, -1.476648448275862 ], [ 29.785902278481014, -1.476378965517241 ], [ 29.785363291139241, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29148, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.476378965517241 ], [ 29.785902278481014, -1.476648448275862 ], [ 29.787249746835442, -1.476648448275862 ], [ 29.787249746835442, -1.476378965517241 ], [ 29.785902278481014, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29149, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.476378965517241 ], [ 29.787249746835442, -1.476648448275862 ], [ 29.787788734177216, -1.476648448275862 ], [ 29.787788734177216, -1.476378965517241 ], [ 29.787249746835442, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29150, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.476378965517241 ], [ 29.787788734177216, -1.476648448275862 ], [ 29.788058227848101, -1.476648448275862 ], [ 29.788058227848101, -1.476378965517241 ], [ 29.787788734177216, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29151, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.476378965517241 ], [ 29.788058227848101, -1.476648448275862 ], [ 29.788327721518986, -1.476648448275862 ], [ 29.788327721518986, -1.476378965517241 ], [ 29.788058227848101, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29152, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.476378965517241 ], [ 29.788327721518986, -1.476917931034483 ], [ 29.788597215189874, -1.476917931034483 ], [ 29.788597215189874, -1.476378965517241 ], [ 29.788327721518986, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29153, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.476378965517241 ], [ 29.788597215189874, -1.476648448275862 ], [ 29.788866708860759, -1.476648448275862 ], [ 29.788866708860759, -1.476378965517241 ], [ 29.788597215189874, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29154, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.476378965517241 ], [ 29.788866708860759, -1.476648448275862 ], [ 29.789136202531644, -1.476648448275862 ], [ 29.789136202531644, -1.476378965517241 ], [ 29.788866708860759, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29155, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.476378965517241 ], [ 29.789136202531644, -1.476648448275862 ], [ 29.789405696202532, -1.476648448275862 ], [ 29.789405696202532, -1.476378965517241 ], [ 29.789136202531644, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29156, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.476378965517241 ], [ 29.789405696202532, -1.476648448275862 ], [ 29.789675189873417, -1.476648448275862 ], [ 29.789675189873417, -1.476378965517241 ], [ 29.789405696202532, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29157, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.796682025316457, -1.476378965517241 ], [ 29.796682025316457, -1.476648448275862 ], [ 29.797221012658227, -1.476648448275862 ], [ 29.797221012658227, -1.476378965517241 ], [ 29.796682025316457, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29158, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.476378965517241 ], [ 29.797221012658227, -1.476648448275862 ], [ 29.797490506329115, -1.476648448275862 ], [ 29.797490506329115, -1.476378965517241 ], [ 29.797221012658227, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29159, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.476378965517241 ], [ 29.797490506329115, -1.476648448275862 ], [ 29.79776, -1.476648448275862 ], [ 29.79776, -1.476378965517241 ], [ 29.797490506329115, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29160, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.476378965517241 ], [ 29.79776, -1.476648448275862 ], [ 29.798029493670885, -1.476648448275862 ], [ 29.798029493670885, -1.476378965517241 ], [ 29.79776, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29161, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.476378965517241 ], [ 29.798029493670885, -1.476648448275862 ], [ 29.798298987341774, -1.476648448275862 ], [ 29.798298987341774, -1.476378965517241 ], [ 29.798029493670885, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29162, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.476378965517241 ], [ 29.798298987341774, -1.476648448275862 ], [ 29.798568481012659, -1.476648448275862 ], [ 29.798568481012659, -1.476378965517241 ], [ 29.798298987341774, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29163, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.476378965517241 ], [ 29.798568481012659, -1.476648448275862 ], [ 29.798837974683543, -1.476648448275862 ], [ 29.798837974683543, -1.476378965517241 ], [ 29.798568481012659, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29164, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.476378965517241 ], [ 29.798837974683543, -1.476917931034483 ], [ 29.799107468354432, -1.476917931034483 ], [ 29.799107468354432, -1.476378965517241 ], [ 29.798837974683543, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29165, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.476378965517241 ], [ 29.799107468354432, -1.476648448275862 ], [ 29.799376962025317, -1.476648448275862 ], [ 29.799376962025317, -1.476378965517241 ], [ 29.799107468354432, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29166, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.476378965517241 ], [ 29.799376962025317, -1.476648448275862 ], [ 29.799646455696202, -1.476648448275862 ], [ 29.799646455696202, -1.476378965517241 ], [ 29.799376962025317, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29167, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.476378965517241 ], [ 29.799646455696202, -1.476917931034483 ], [ 29.79991594936709, -1.476917931034483 ], [ 29.79991594936709, -1.476378965517241 ], [ 29.799646455696202, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29168, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.476378965517241 ], [ 29.800185443037975, -1.476917931034483 ], [ 29.80045493670886, -1.476917931034483 ], [ 29.80045493670886, -1.476378965517241 ], [ 29.800185443037975, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29169, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.476378965517241 ], [ 29.800724430379748, -1.476648448275862 ], [ 29.800993924050633, -1.476648448275862 ], [ 29.800993924050633, -1.476378965517241 ], [ 29.800724430379748, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29170, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.476378965517241 ], [ 29.800993924050633, -1.476648448275862 ], [ 29.801263417721518, -1.476648448275862 ], [ 29.801263417721518, -1.476378965517241 ], [ 29.800993924050633, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29171, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.476378965517241 ], [ 29.801263417721518, -1.476648448275862 ], [ 29.801532911392407, -1.476648448275862 ], [ 29.801532911392407, -1.476378965517241 ], [ 29.801263417721518, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29172, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.476378965517241 ], [ 29.801532911392407, -1.477995862068966 ], [ 29.802071898734177, -1.477995862068966 ], [ 29.802071898734177, -1.476378965517241 ], [ 29.801532911392407, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29173, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.476378965517241 ], [ 29.802071898734177, -1.476648448275862 ], [ 29.802341392405065, -1.476648448275862 ], [ 29.802341392405065, -1.476378965517241 ], [ 29.802071898734177, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29174, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.476378965517241 ], [ 29.802341392405065, -1.476648448275862 ], [ 29.80261088607595, -1.476648448275862 ], [ 29.80261088607595, -1.476378965517241 ], [ 29.802341392405065, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29175, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.476378965517241 ], [ 29.80261088607595, -1.476648448275862 ], [ 29.802880379746835, -1.476648448275862 ], [ 29.802880379746835, -1.476378965517241 ], [ 29.80261088607595, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29176, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.476378965517241 ], [ 29.803149873417723, -1.476648448275862 ], [ 29.803419367088608, -1.476648448275862 ], [ 29.803419367088608, -1.476378965517241 ], [ 29.803149873417723, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29177, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.476378965517241 ], [ 29.803419367088608, -1.476648448275862 ], [ 29.803688860759493, -1.476648448275862 ], [ 29.803688860759493, -1.476378965517241 ], [ 29.803419367088608, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29178, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.476378965517241 ], [ 29.803688860759493, -1.476648448275862 ], [ 29.803958354430382, -1.476648448275862 ], [ 29.803958354430382, -1.476378965517241 ], [ 29.803688860759493, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29179, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.476378965517241 ], [ 29.803958354430382, -1.476648448275862 ], [ 29.804227848101267, -1.476648448275862 ], [ 29.804227848101267, -1.476378965517241 ], [ 29.803958354430382, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29180, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.476378965517241 ], [ 29.804227848101267, -1.476648448275862 ], [ 29.80476683544304, -1.476648448275862 ], [ 29.80476683544304, -1.476378965517241 ], [ 29.804227848101267, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29181, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.476378965517241 ], [ 29.80476683544304, -1.476648448275862 ], [ 29.805036329113925, -1.476648448275862 ], [ 29.805036329113925, -1.476378965517241 ], [ 29.80476683544304, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29182, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.476378965517241 ], [ 29.805036329113925, -1.476648448275862 ], [ 29.805575316455698, -1.476648448275862 ], [ 29.805575316455698, -1.476378965517241 ], [ 29.805036329113925, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29183, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805575316455698, -1.476378965517241 ], [ 29.805575316455698, -1.476648448275862 ], [ 29.805844810126583, -1.476648448275862 ], [ 29.805844810126583, -1.476378965517241 ], [ 29.805575316455698, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29184, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805844810126583, -1.476378965517241 ], [ 29.805844810126583, -1.476648448275862 ], [ 29.806383797468357, -1.476648448275862 ], [ 29.806383797468357, -1.476378965517241 ], [ 29.805844810126583, -1.476378965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29185, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.758952911392406, -1.476648448275862 ], [ 29.758952911392406, -1.476917931034483 ], [ 29.759222405063291, -1.476917931034483 ], [ 29.759222405063291, -1.476648448275862 ], [ 29.758952911392406, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29186, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.476648448275862 ], [ 29.759222405063291, -1.476917931034483 ], [ 29.759491898734176, -1.476917931034483 ], [ 29.759491898734176, -1.476648448275862 ], [ 29.759222405063291, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29187, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.476648448275862 ], [ 29.759491898734176, -1.476917931034483 ], [ 29.759761392405064, -1.476917931034483 ], [ 29.759761392405064, -1.476648448275862 ], [ 29.759491898734176, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29188, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.476648448275862 ], [ 29.759761392405064, -1.476917931034483 ], [ 29.760030886075949, -1.476917931034483 ], [ 29.760030886075949, -1.476648448275862 ], [ 29.759761392405064, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29189, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.476648448275862 ], [ 29.760030886075949, -1.476917931034483 ], [ 29.760300379746834, -1.476917931034483 ], [ 29.760300379746834, -1.476648448275862 ], [ 29.760030886075949, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29190, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.476648448275862 ], [ 29.760300379746834, -1.476917931034483 ], [ 29.760569873417722, -1.476917931034483 ], [ 29.760569873417722, -1.476648448275862 ], [ 29.760300379746834, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29191, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.476648448275862 ], [ 29.760569873417722, -1.476917931034483 ], [ 29.760839367088607, -1.476917931034483 ], [ 29.760839367088607, -1.476648448275862 ], [ 29.760569873417722, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29192, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.476648448275862 ], [ 29.760839367088607, -1.476917931034483 ], [ 29.761108860759492, -1.476917931034483 ], [ 29.761108860759492, -1.476648448275862 ], [ 29.760839367088607, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29193, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.476648448275862 ], [ 29.761108860759492, -1.476917931034483 ], [ 29.761378354430381, -1.476917931034483 ], [ 29.761378354430381, -1.476648448275862 ], [ 29.761108860759492, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29194, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.476648448275862 ], [ 29.761378354430381, -1.476917931034483 ], [ 29.761647848101266, -1.476917931034483 ], [ 29.761647848101266, -1.476648448275862 ], [ 29.761378354430381, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29195, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.476648448275862 ], [ 29.761647848101266, -1.476917931034483 ], [ 29.761917341772151, -1.476917931034483 ], [ 29.761917341772151, -1.476648448275862 ], [ 29.761647848101266, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29196, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.476648448275862 ], [ 29.761917341772151, -1.476917931034483 ], [ 29.762186835443039, -1.476917931034483 ], [ 29.762186835443039, -1.476648448275862 ], [ 29.761917341772151, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29197, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.476648448275862 ], [ 29.762186835443039, -1.476917931034483 ], [ 29.762456329113924, -1.476917931034483 ], [ 29.762456329113924, -1.476648448275862 ], [ 29.762186835443039, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29198, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.476648448275862 ], [ 29.762456329113924, -1.476917931034483 ], [ 29.762725822784809, -1.476917931034483 ], [ 29.762725822784809, -1.476648448275862 ], [ 29.762456329113924, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29199, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.476648448275862 ], [ 29.762725822784809, -1.476917931034483 ], [ 29.762995316455697, -1.476917931034483 ], [ 29.762995316455697, -1.476648448275862 ], [ 29.762725822784809, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29200, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.476648448275862 ], [ 29.762995316455697, -1.476917931034483 ], [ 29.763264810126582, -1.476917931034483 ], [ 29.763264810126582, -1.476648448275862 ], [ 29.762995316455697, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29201, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.476648448275862 ], [ 29.763264810126582, -1.476917931034483 ], [ 29.763534303797467, -1.476917931034483 ], [ 29.763534303797467, -1.476648448275862 ], [ 29.763264810126582, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29202, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.476648448275862 ], [ 29.763534303797467, -1.476917931034483 ], [ 29.763803797468356, -1.476917931034483 ], [ 29.763803797468356, -1.476648448275862 ], [ 29.763534303797467, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29203, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.476648448275862 ], [ 29.763803797468356, -1.476917931034483 ], [ 29.76407329113924, -1.476917931034483 ], [ 29.76407329113924, -1.476648448275862 ], [ 29.763803797468356, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29204, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.476648448275862 ], [ 29.76407329113924, -1.476917931034483 ], [ 29.764342784810125, -1.476917931034483 ], [ 29.764342784810125, -1.476648448275862 ], [ 29.76407329113924, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29205, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.476648448275862 ], [ 29.764342784810125, -1.476917931034483 ], [ 29.764612278481014, -1.476917931034483 ], [ 29.764612278481014, -1.476648448275862 ], [ 29.764342784810125, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29206, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.476648448275862 ], [ 29.764612278481014, -1.476917931034483 ], [ 29.764881772151899, -1.476917931034483 ], [ 29.764881772151899, -1.476648448275862 ], [ 29.764612278481014, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29207, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.476648448275862 ], [ 29.764881772151899, -1.476917931034483 ], [ 29.765151265822784, -1.476917931034483 ], [ 29.765151265822784, -1.476648448275862 ], [ 29.764881772151899, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29208, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.476648448275862 ], [ 29.765151265822784, -1.476917931034483 ], [ 29.765420759493672, -1.476917931034483 ], [ 29.765420759493672, -1.476648448275862 ], [ 29.765151265822784, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29209, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.476648448275862 ], [ 29.765420759493672, -1.476917931034483 ], [ 29.765959746835442, -1.476917931034483 ], [ 29.765959746835442, -1.476648448275862 ], [ 29.765420759493672, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29210, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.476648448275862 ], [ 29.765959746835442, -1.476917931034483 ], [ 29.766229240506327, -1.476917931034483 ], [ 29.766229240506327, -1.476648448275862 ], [ 29.765959746835442, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29211, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.476648448275862 ], [ 29.766229240506327, -1.476917931034483 ], [ 29.766498734177215, -1.476917931034483 ], [ 29.766498734177215, -1.476648448275862 ], [ 29.766229240506327, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29212, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.476648448275862 ], [ 29.766498734177215, -1.476917931034483 ], [ 29.7667682278481, -1.476917931034483 ], [ 29.7667682278481, -1.476648448275862 ], [ 29.766498734177215, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29213, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.476648448275862 ], [ 29.7667682278481, -1.476917931034483 ], [ 29.767037721518985, -1.476917931034483 ], [ 29.767037721518985, -1.476648448275862 ], [ 29.7667682278481, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29214, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.476648448275862 ], [ 29.767037721518985, -1.476917931034483 ], [ 29.767307215189874, -1.476917931034483 ], [ 29.767307215189874, -1.476648448275862 ], [ 29.767037721518985, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29215, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.476648448275862 ], [ 29.767307215189874, -1.476917931034483 ], [ 29.767576708860759, -1.476917931034483 ], [ 29.767576708860759, -1.476648448275862 ], [ 29.767307215189874, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29216, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.476648448275862 ], [ 29.767576708860759, -1.476917931034483 ], [ 29.767846202531643, -1.476917931034483 ], [ 29.767846202531643, -1.476648448275862 ], [ 29.767576708860759, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29217, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.476648448275862 ], [ 29.770271645569618, -1.477187413793104 ], [ 29.770541139240507, -1.477187413793104 ], [ 29.770541139240507, -1.476648448275862 ], [ 29.770271645569618, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29218, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.476648448275862 ], [ 29.770541139240507, -1.476917931034483 ], [ 29.770810632911392, -1.476917931034483 ], [ 29.770810632911392, -1.476648448275862 ], [ 29.770541139240507, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29219, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.476648448275862 ], [ 29.770810632911392, -1.476917931034483 ], [ 29.771080126582277, -1.476917931034483 ], [ 29.771080126582277, -1.476648448275862 ], [ 29.770810632911392, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29220, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.476648448275862 ], [ 29.771080126582277, -1.476917931034483 ], [ 29.771349620253165, -1.476917931034483 ], [ 29.771349620253165, -1.476648448275862 ], [ 29.771080126582277, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29221, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.476648448275862 ], [ 29.771349620253165, -1.476917931034483 ], [ 29.77161911392405, -1.476917931034483 ], [ 29.77161911392405, -1.476648448275862 ], [ 29.771349620253165, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29222, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.476648448275862 ], [ 29.77161911392405, -1.476917931034483 ], [ 29.771888607594935, -1.476917931034483 ], [ 29.771888607594935, -1.476648448275862 ], [ 29.77161911392405, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29223, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.476648448275862 ], [ 29.771888607594935, -1.476917931034483 ], [ 29.772158101265823, -1.476917931034483 ], [ 29.772158101265823, -1.476648448275862 ], [ 29.771888607594935, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29224, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.476648448275862 ], [ 29.772427594936708, -1.476917931034483 ], [ 29.772697088607593, -1.476917931034483 ], [ 29.772697088607593, -1.476648448275862 ], [ 29.772427594936708, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29225, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.476648448275862 ], [ 29.772697088607593, -1.476917931034483 ], [ 29.772966582278482, -1.476917931034483 ], [ 29.772966582278482, -1.476648448275862 ], [ 29.772697088607593, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29226, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.476648448275862 ], [ 29.773236075949367, -1.477187413793104 ], [ 29.773505569620252, -1.477187413793104 ], [ 29.773505569620252, -1.476648448275862 ], [ 29.773236075949367, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29227, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.476648448275862 ], [ 29.774044556962025, -1.477187413793104 ], [ 29.77431405063291, -1.477187413793104 ], [ 29.77431405063291, -1.476648448275862 ], [ 29.774044556962025, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29228, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.476648448275862 ], [ 29.775122531645568, -1.477187413793104 ], [ 29.775392025316457, -1.477187413793104 ], [ 29.775392025316457, -1.476648448275862 ], [ 29.775122531645568, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29229, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.476648448275862 ], [ 29.775661518987341, -1.477187413793104 ], [ 29.775931012658226, -1.477187413793104 ], [ 29.775931012658226, -1.476648448275862 ], [ 29.775661518987341, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29230, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.476648448275862 ], [ 29.77647, -1.477187413793104 ], [ 29.776739493670885, -1.477187413793104 ], [ 29.776739493670885, -1.476648448275862 ], [ 29.77647, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29231, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.476648448275862 ], [ 29.777817468354431, -1.477456896551724 ], [ 29.778086962025316, -1.477456896551724 ], [ 29.778086962025316, -1.476648448275862 ], [ 29.777817468354431, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29232, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.476648448275862 ], [ 29.778895443037975, -1.476917931034483 ], [ 29.77916493670886, -1.476917931034483 ], [ 29.77916493670886, -1.476648448275862 ], [ 29.778895443037975, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29233, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.476648448275862 ], [ 29.77916493670886, -1.476917931034483 ], [ 29.779434430379748, -1.476917931034483 ], [ 29.779434430379748, -1.476648448275862 ], [ 29.77916493670886, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29234, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.476648448275862 ], [ 29.779434430379748, -1.476917931034483 ], [ 29.779703924050633, -1.476917931034483 ], [ 29.779703924050633, -1.476648448275862 ], [ 29.779434430379748, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29235, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.476648448275862 ], [ 29.779703924050633, -1.476917931034483 ], [ 29.779973417721518, -1.476917931034483 ], [ 29.779973417721518, -1.476648448275862 ], [ 29.779703924050633, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29236, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.476648448275862 ], [ 29.779973417721518, -1.476917931034483 ], [ 29.780242911392406, -1.476917931034483 ], [ 29.780242911392406, -1.476648448275862 ], [ 29.779973417721518, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29237, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.476648448275862 ], [ 29.780242911392406, -1.476917931034483 ], [ 29.780512405063291, -1.476917931034483 ], [ 29.780512405063291, -1.476648448275862 ], [ 29.780242911392406, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29238, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.476648448275862 ], [ 29.780512405063291, -1.476917931034483 ], [ 29.780781898734176, -1.476917931034483 ], [ 29.780781898734176, -1.476648448275862 ], [ 29.780512405063291, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29239, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.476648448275862 ], [ 29.780781898734176, -1.476917931034483 ], [ 29.781051392405065, -1.476917931034483 ], [ 29.781051392405065, -1.476648448275862 ], [ 29.780781898734176, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29240, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.476648448275862 ], [ 29.781051392405065, -1.476917931034483 ], [ 29.78132088607595, -1.476917931034483 ], [ 29.78132088607595, -1.476648448275862 ], [ 29.781051392405065, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29241, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.476648448275862 ], [ 29.78132088607595, -1.476917931034483 ], [ 29.781590379746834, -1.476917931034483 ], [ 29.781590379746834, -1.476648448275862 ], [ 29.78132088607595, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29242, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.476648448275862 ], [ 29.781590379746834, -1.476917931034483 ], [ 29.781859873417723, -1.476917931034483 ], [ 29.781859873417723, -1.476648448275862 ], [ 29.781590379746834, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29243, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.476648448275862 ], [ 29.781859873417723, -1.476917931034483 ], [ 29.782129367088608, -1.476917931034483 ], [ 29.782129367088608, -1.476648448275862 ], [ 29.781859873417723, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29244, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.476648448275862 ], [ 29.782129367088608, -1.476917931034483 ], [ 29.782398860759493, -1.476917931034483 ], [ 29.782398860759493, -1.476648448275862 ], [ 29.782129367088608, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29245, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.476648448275862 ], [ 29.782398860759493, -1.476917931034483 ], [ 29.782668354430381, -1.476917931034483 ], [ 29.782668354430381, -1.476648448275862 ], [ 29.782398860759493, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29246, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.476648448275862 ], [ 29.782668354430381, -1.476917931034483 ], [ 29.782937848101266, -1.476917931034483 ], [ 29.782937848101266, -1.476648448275862 ], [ 29.782668354430381, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29247, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.476648448275862 ], [ 29.782937848101266, -1.476917931034483 ], [ 29.783207341772151, -1.476917931034483 ], [ 29.783207341772151, -1.476648448275862 ], [ 29.782937848101266, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29248, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.476648448275862 ], [ 29.783207341772151, -1.476917931034483 ], [ 29.783476835443039, -1.476917931034483 ], [ 29.783476835443039, -1.476648448275862 ], [ 29.783207341772151, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29249, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.476648448275862 ], [ 29.783476835443039, -1.476917931034483 ], [ 29.784015822784809, -1.476917931034483 ], [ 29.784015822784809, -1.476648448275862 ], [ 29.783476835443039, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29250, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.476648448275862 ], [ 29.784015822784809, -1.476917931034483 ], [ 29.784285316455698, -1.476917931034483 ], [ 29.784285316455698, -1.476648448275862 ], [ 29.784015822784809, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29251, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.476648448275862 ], [ 29.784285316455698, -1.476917931034483 ], [ 29.784554810126583, -1.476917931034483 ], [ 29.784554810126583, -1.476648448275862 ], [ 29.784285316455698, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29252, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.476648448275862 ], [ 29.784554810126583, -1.476917931034483 ], [ 29.784824303797468, -1.476917931034483 ], [ 29.784824303797468, -1.476648448275862 ], [ 29.784554810126583, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29253, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.476648448275862 ], [ 29.784824303797468, -1.476917931034483 ], [ 29.785093797468356, -1.476917931034483 ], [ 29.785093797468356, -1.476648448275862 ], [ 29.784824303797468, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29254, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.476648448275862 ], [ 29.785093797468356, -1.476917931034483 ], [ 29.785363291139241, -1.476917931034483 ], [ 29.785363291139241, -1.476648448275862 ], [ 29.785093797468356, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29255, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.476648448275862 ], [ 29.785363291139241, -1.476917931034483 ], [ 29.785632784810126, -1.476917931034483 ], [ 29.785632784810126, -1.476648448275862 ], [ 29.785363291139241, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29256, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.476648448275862 ], [ 29.785632784810126, -1.476917931034483 ], [ 29.787519240506327, -1.476917931034483 ], [ 29.787519240506327, -1.476648448275862 ], [ 29.785632784810126, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29257, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.476648448275862 ], [ 29.787519240506327, -1.476917931034483 ], [ 29.787788734177216, -1.476917931034483 ], [ 29.787788734177216, -1.476648448275862 ], [ 29.787519240506327, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29258, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.476648448275862 ], [ 29.787788734177216, -1.476917931034483 ], [ 29.788058227848101, -1.476917931034483 ], [ 29.788058227848101, -1.476648448275862 ], [ 29.787788734177216, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29259, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.476648448275862 ], [ 29.788058227848101, -1.477187413793104 ], [ 29.788327721518986, -1.477187413793104 ], [ 29.788327721518986, -1.476648448275862 ], [ 29.788058227848101, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29260, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.476648448275862 ], [ 29.788597215189874, -1.477726379310345 ], [ 29.788866708860759, -1.477726379310345 ], [ 29.788866708860759, -1.476648448275862 ], [ 29.788597215189874, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29261, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.476648448275862 ], [ 29.788866708860759, -1.476917931034483 ], [ 29.789136202531644, -1.476917931034483 ], [ 29.789136202531644, -1.476648448275862 ], [ 29.788866708860759, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29262, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.476648448275862 ], [ 29.789136202531644, -1.476917931034483 ], [ 29.789405696202532, -1.476917931034483 ], [ 29.789405696202532, -1.476648448275862 ], [ 29.789136202531644, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29263, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.476648448275862 ], [ 29.789405696202532, -1.477187413793104 ], [ 29.789675189873417, -1.477187413793104 ], [ 29.789675189873417, -1.476648448275862 ], [ 29.789405696202532, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29264, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.476648448275862 ], [ 29.789675189873417, -1.476917931034483 ], [ 29.789944683544302, -1.476917931034483 ], [ 29.789944683544302, -1.476648448275862 ], [ 29.789675189873417, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29265, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.476648448275862 ], [ 29.789944683544302, -1.476917931034483 ], [ 29.790214177215191, -1.476917931034483 ], [ 29.790214177215191, -1.476648448275862 ], [ 29.789944683544302, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29266, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.476648448275862 ], [ 29.790214177215191, -1.476917931034483 ], [ 29.790483670886076, -1.476917931034483 ], [ 29.790483670886076, -1.476648448275862 ], [ 29.790214177215191, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29267, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797221012658227, -1.476648448275862 ], [ 29.797221012658227, -1.476917931034483 ], [ 29.797490506329115, -1.476917931034483 ], [ 29.797490506329115, -1.476648448275862 ], [ 29.797221012658227, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29268, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.476648448275862 ], [ 29.797490506329115, -1.476917931034483 ], [ 29.79776, -1.476917931034483 ], [ 29.79776, -1.476648448275862 ], [ 29.797490506329115, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29269, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.476648448275862 ], [ 29.79776, -1.476917931034483 ], [ 29.798029493670885, -1.476917931034483 ], [ 29.798029493670885, -1.476648448275862 ], [ 29.79776, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29270, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.476648448275862 ], [ 29.798029493670885, -1.476917931034483 ], [ 29.798298987341774, -1.476917931034483 ], [ 29.798298987341774, -1.476648448275862 ], [ 29.798029493670885, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29271, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.476648448275862 ], [ 29.798298987341774, -1.477456896551724 ], [ 29.798568481012659, -1.477456896551724 ], [ 29.798568481012659, -1.476648448275862 ], [ 29.798298987341774, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29272, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.476648448275862 ], [ 29.798568481012659, -1.477456896551724 ], [ 29.798837974683543, -1.477456896551724 ], [ 29.798837974683543, -1.476648448275862 ], [ 29.798568481012659, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29273, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.476648448275862 ], [ 29.799107468354432, -1.477187413793104 ], [ 29.799376962025317, -1.477187413793104 ], [ 29.799376962025317, -1.476648448275862 ], [ 29.799107468354432, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29274, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.476648448275862 ], [ 29.799376962025317, -1.477187413793104 ], [ 29.799646455696202, -1.477187413793104 ], [ 29.799646455696202, -1.476648448275862 ], [ 29.799376962025317, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29275, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.476648448275862 ], [ 29.79991594936709, -1.476917931034483 ], [ 29.800185443037975, -1.476917931034483 ], [ 29.800185443037975, -1.476648448275862 ], [ 29.79991594936709, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29276, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.476648448275862 ], [ 29.800993924050633, -1.477187413793104 ], [ 29.801263417721518, -1.477187413793104 ], [ 29.801263417721518, -1.476648448275862 ], [ 29.800993924050633, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29277, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.476648448275862 ], [ 29.801263417721518, -1.476917931034483 ], [ 29.801532911392407, -1.476917931034483 ], [ 29.801532911392407, -1.476648448275862 ], [ 29.801263417721518, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29278, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.476648448275862 ], [ 29.802071898734177, -1.476917931034483 ], [ 29.802341392405065, -1.476917931034483 ], [ 29.802341392405065, -1.476648448275862 ], [ 29.802071898734177, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29279, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.476648448275862 ], [ 29.802341392405065, -1.476917931034483 ], [ 29.80261088607595, -1.476917931034483 ], [ 29.80261088607595, -1.476648448275862 ], [ 29.802341392405065, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29280, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.476648448275862 ], [ 29.80261088607595, -1.477187413793104 ], [ 29.802880379746835, -1.477187413793104 ], [ 29.802880379746835, -1.476648448275862 ], [ 29.80261088607595, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29281, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.476648448275862 ], [ 29.802880379746835, -1.476917931034483 ], [ 29.803149873417723, -1.476917931034483 ], [ 29.803149873417723, -1.476648448275862 ], [ 29.802880379746835, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29282, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.476648448275862 ], [ 29.803149873417723, -1.476917931034483 ], [ 29.803419367088608, -1.476917931034483 ], [ 29.803419367088608, -1.476648448275862 ], [ 29.803149873417723, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29283, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.476648448275862 ], [ 29.803419367088608, -1.476917931034483 ], [ 29.803688860759493, -1.476917931034483 ], [ 29.803688860759493, -1.476648448275862 ], [ 29.803419367088608, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29284, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.476648448275862 ], [ 29.803688860759493, -1.476917931034483 ], [ 29.803958354430382, -1.476917931034483 ], [ 29.803958354430382, -1.476648448275862 ], [ 29.803688860759493, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29285, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.476648448275862 ], [ 29.803958354430382, -1.476917931034483 ], [ 29.804227848101267, -1.476917931034483 ], [ 29.804227848101267, -1.476648448275862 ], [ 29.803958354430382, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29286, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.476648448275862 ], [ 29.804227848101267, -1.476917931034483 ], [ 29.80476683544304, -1.476917931034483 ], [ 29.80476683544304, -1.476648448275862 ], [ 29.804227848101267, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29287, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80476683544304, -1.476648448275862 ], [ 29.80476683544304, -1.476917931034483 ], [ 29.805036329113925, -1.476917931034483 ], [ 29.805036329113925, -1.476648448275862 ], [ 29.80476683544304, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29288, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.805036329113925, -1.476648448275862 ], [ 29.805036329113925, -1.476917931034483 ], [ 29.805844810126583, -1.476917931034483 ], [ 29.805844810126583, -1.476648448275862 ], [ 29.805036329113925, -1.476648448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29289, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759222405063291, -1.476917931034483 ], [ 29.759222405063291, -1.477187413793104 ], [ 29.759491898734176, -1.477187413793104 ], [ 29.759491898734176, -1.476917931034483 ], [ 29.759222405063291, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29290, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.476917931034483 ], [ 29.759491898734176, -1.477187413793104 ], [ 29.759761392405064, -1.477187413793104 ], [ 29.759761392405064, -1.476917931034483 ], [ 29.759491898734176, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29291, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.476917931034483 ], [ 29.759761392405064, -1.477187413793104 ], [ 29.760030886075949, -1.477187413793104 ], [ 29.760030886075949, -1.476917931034483 ], [ 29.759761392405064, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29292, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.476917931034483 ], [ 29.760030886075949, -1.477187413793104 ], [ 29.760300379746834, -1.477187413793104 ], [ 29.760300379746834, -1.476917931034483 ], [ 29.760030886075949, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29293, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.476917931034483 ], [ 29.760300379746834, -1.477187413793104 ], [ 29.760569873417722, -1.477187413793104 ], [ 29.760569873417722, -1.476917931034483 ], [ 29.760300379746834, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29294, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.476917931034483 ], [ 29.760569873417722, -1.477187413793104 ], [ 29.760839367088607, -1.477187413793104 ], [ 29.760839367088607, -1.476917931034483 ], [ 29.760569873417722, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29295, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.476917931034483 ], [ 29.760839367088607, -1.477187413793104 ], [ 29.761108860759492, -1.477187413793104 ], [ 29.761108860759492, -1.476917931034483 ], [ 29.760839367088607, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29296, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.476917931034483 ], [ 29.761108860759492, -1.477187413793104 ], [ 29.761378354430381, -1.477187413793104 ], [ 29.761378354430381, -1.476917931034483 ], [ 29.761108860759492, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29297, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.476917931034483 ], [ 29.761378354430381, -1.477187413793104 ], [ 29.761647848101266, -1.477187413793104 ], [ 29.761647848101266, -1.476917931034483 ], [ 29.761378354430381, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29298, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.476917931034483 ], [ 29.761647848101266, -1.477187413793104 ], [ 29.761917341772151, -1.477187413793104 ], [ 29.761917341772151, -1.476917931034483 ], [ 29.761647848101266, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29299, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.476917931034483 ], [ 29.761917341772151, -1.477187413793104 ], [ 29.762186835443039, -1.477187413793104 ], [ 29.762186835443039, -1.476917931034483 ], [ 29.761917341772151, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29300, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.476917931034483 ], [ 29.762186835443039, -1.477187413793104 ], [ 29.762456329113924, -1.477187413793104 ], [ 29.762456329113924, -1.476917931034483 ], [ 29.762186835443039, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29301, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.476917931034483 ], [ 29.762456329113924, -1.477187413793104 ], [ 29.762725822784809, -1.477187413793104 ], [ 29.762725822784809, -1.476917931034483 ], [ 29.762456329113924, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29302, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.476917931034483 ], [ 29.762725822784809, -1.477187413793104 ], [ 29.762995316455697, -1.477187413793104 ], [ 29.762995316455697, -1.476917931034483 ], [ 29.762725822784809, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29303, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.476917931034483 ], [ 29.762995316455697, -1.477187413793104 ], [ 29.763264810126582, -1.477187413793104 ], [ 29.763264810126582, -1.476917931034483 ], [ 29.762995316455697, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29304, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.476917931034483 ], [ 29.763264810126582, -1.477187413793104 ], [ 29.763534303797467, -1.477187413793104 ], [ 29.763534303797467, -1.476917931034483 ], [ 29.763264810126582, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29305, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.476917931034483 ], [ 29.763534303797467, -1.477187413793104 ], [ 29.763803797468356, -1.477187413793104 ], [ 29.763803797468356, -1.476917931034483 ], [ 29.763534303797467, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29306, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.476917931034483 ], [ 29.763803797468356, -1.477456896551724 ], [ 29.76407329113924, -1.477456896551724 ], [ 29.76407329113924, -1.476917931034483 ], [ 29.763803797468356, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29307, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.476917931034483 ], [ 29.76407329113924, -1.477187413793104 ], [ 29.764342784810125, -1.477187413793104 ], [ 29.764342784810125, -1.476917931034483 ], [ 29.76407329113924, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29308, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.476917931034483 ], [ 29.764342784810125, -1.477187413793104 ], [ 29.764612278481014, -1.477187413793104 ], [ 29.764612278481014, -1.476917931034483 ], [ 29.764342784810125, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29309, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.476917931034483 ], [ 29.764612278481014, -1.477187413793104 ], [ 29.764881772151899, -1.477187413793104 ], [ 29.764881772151899, -1.476917931034483 ], [ 29.764612278481014, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29310, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.476917931034483 ], [ 29.764881772151899, -1.477187413793104 ], [ 29.765151265822784, -1.477187413793104 ], [ 29.765151265822784, -1.476917931034483 ], [ 29.764881772151899, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29311, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.476917931034483 ], [ 29.765151265822784, -1.477187413793104 ], [ 29.765420759493672, -1.477187413793104 ], [ 29.765420759493672, -1.476917931034483 ], [ 29.765151265822784, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29312, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.476917931034483 ], [ 29.765420759493672, -1.477187413793104 ], [ 29.765959746835442, -1.477187413793104 ], [ 29.765959746835442, -1.476917931034483 ], [ 29.765420759493672, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29313, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.476917931034483 ], [ 29.765959746835442, -1.477187413793104 ], [ 29.766229240506327, -1.477187413793104 ], [ 29.766229240506327, -1.476917931034483 ], [ 29.765959746835442, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29314, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.476917931034483 ], [ 29.766229240506327, -1.477187413793104 ], [ 29.766498734177215, -1.477187413793104 ], [ 29.766498734177215, -1.476917931034483 ], [ 29.766229240506327, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29315, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.476917931034483 ], [ 29.766498734177215, -1.477187413793104 ], [ 29.7667682278481, -1.477187413793104 ], [ 29.7667682278481, -1.476917931034483 ], [ 29.766498734177215, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29316, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.476917931034483 ], [ 29.7667682278481, -1.477187413793104 ], [ 29.767037721518985, -1.477187413793104 ], [ 29.767037721518985, -1.476917931034483 ], [ 29.7667682278481, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29317, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.476917931034483 ], [ 29.767037721518985, -1.477187413793104 ], [ 29.767307215189874, -1.477187413793104 ], [ 29.767307215189874, -1.476917931034483 ], [ 29.767037721518985, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29318, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.476917931034483 ], [ 29.767307215189874, -1.477187413793104 ], [ 29.767576708860759, -1.477187413793104 ], [ 29.767576708860759, -1.476917931034483 ], [ 29.767307215189874, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29319, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.476917931034483 ], [ 29.767576708860759, -1.477187413793104 ], [ 29.767846202531643, -1.477187413793104 ], [ 29.767846202531643, -1.476917931034483 ], [ 29.767576708860759, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29320, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.476917931034483 ], [ 29.767846202531643, -1.477187413793104 ], [ 29.768115696202532, -1.477187413793104 ], [ 29.768115696202532, -1.476917931034483 ], [ 29.767846202531643, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29321, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.476917931034483 ], [ 29.768115696202532, -1.477187413793104 ], [ 29.768385189873417, -1.477187413793104 ], [ 29.768385189873417, -1.476917931034483 ], [ 29.768115696202532, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29322, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.476917931034483 ], [ 29.770541139240507, -1.477187413793104 ], [ 29.770810632911392, -1.477187413793104 ], [ 29.770810632911392, -1.476917931034483 ], [ 29.770541139240507, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29323, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.476917931034483 ], [ 29.770810632911392, -1.477187413793104 ], [ 29.771080126582277, -1.477187413793104 ], [ 29.771080126582277, -1.476917931034483 ], [ 29.770810632911392, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29324, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.476917931034483 ], [ 29.771080126582277, -1.477187413793104 ], [ 29.771349620253165, -1.477187413793104 ], [ 29.771349620253165, -1.476917931034483 ], [ 29.771080126582277, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29325, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.476917931034483 ], [ 29.771349620253165, -1.477187413793104 ], [ 29.77161911392405, -1.477187413793104 ], [ 29.77161911392405, -1.476917931034483 ], [ 29.771349620253165, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29326, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.476917931034483 ], [ 29.77161911392405, -1.477187413793104 ], [ 29.771888607594935, -1.477187413793104 ], [ 29.771888607594935, -1.476917931034483 ], [ 29.77161911392405, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29327, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.476917931034483 ], [ 29.771888607594935, -1.477187413793104 ], [ 29.772158101265823, -1.477187413793104 ], [ 29.772158101265823, -1.476917931034483 ], [ 29.771888607594935, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29328, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.476917931034483 ], [ 29.772158101265823, -1.477187413793104 ], [ 29.772427594936708, -1.477187413793104 ], [ 29.772427594936708, -1.476917931034483 ], [ 29.772158101265823, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29329, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.476917931034483 ], [ 29.772427594936708, -1.477187413793104 ], [ 29.772697088607593, -1.477187413793104 ], [ 29.772697088607593, -1.476917931034483 ], [ 29.772427594936708, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29330, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.476917931034483 ], [ 29.772697088607593, -1.477187413793104 ], [ 29.772966582278482, -1.477187413793104 ], [ 29.772966582278482, -1.476917931034483 ], [ 29.772697088607593, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29331, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.476917931034483 ], [ 29.772966582278482, -1.477187413793104 ], [ 29.773236075949367, -1.477187413793104 ], [ 29.773236075949367, -1.476917931034483 ], [ 29.772966582278482, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29332, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.476917931034483 ], [ 29.773505569620252, -1.477187413793104 ], [ 29.77377506329114, -1.477187413793104 ], [ 29.77377506329114, -1.476917931034483 ], [ 29.773505569620252, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29333, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.476917931034483 ], [ 29.77377506329114, -1.477187413793104 ], [ 29.774044556962025, -1.477187413793104 ], [ 29.774044556962025, -1.476917931034483 ], [ 29.77377506329114, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29334, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.476917931034483 ], [ 29.77431405063291, -1.477456896551724 ], [ 29.774853037974683, -1.477456896551724 ], [ 29.774853037974683, -1.476917931034483 ], [ 29.77431405063291, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29335, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.476917931034483 ], [ 29.774853037974683, -1.477187413793104 ], [ 29.775122531645568, -1.477187413793104 ], [ 29.775122531645568, -1.476917931034483 ], [ 29.774853037974683, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29336, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.476917931034483 ], [ 29.775392025316457, -1.477456896551724 ], [ 29.775661518987341, -1.477456896551724 ], [ 29.775661518987341, -1.476917931034483 ], [ 29.775392025316457, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29337, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.476917931034483 ], [ 29.776200506329115, -1.477456896551724 ], [ 29.77647, -1.477456896551724 ], [ 29.77647, -1.476917931034483 ], [ 29.776200506329115, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29338, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.476917931034483 ], [ 29.777008987341773, -1.477456896551724 ], [ 29.777278481012658, -1.477456896551724 ], [ 29.777278481012658, -1.476917931034483 ], [ 29.777008987341773, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29339, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.476917931034483 ], [ 29.777547974683543, -1.477456896551724 ], [ 29.777817468354431, -1.477456896551724 ], [ 29.777817468354431, -1.476917931034483 ], [ 29.777547974683543, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29340, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.476917931034483 ], [ 29.778356455696201, -1.477456896551724 ], [ 29.778895443037975, -1.477456896551724 ], [ 29.778895443037975, -1.477187413793104 ], [ 29.77862594936709, -1.477187413793104 ], [ 29.77862594936709, -1.476917931034483 ], [ 29.778356455696201, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29341, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.476917931034483 ], [ 29.77862594936709, -1.477187413793104 ], [ 29.778895443037975, -1.477187413793104 ], [ 29.778895443037975, -1.476917931034483 ], [ 29.77862594936709, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29342, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.476917931034483 ], [ 29.778895443037975, -1.477187413793104 ], [ 29.77916493670886, -1.477187413793104 ], [ 29.77916493670886, -1.476917931034483 ], [ 29.778895443037975, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29343, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.476917931034483 ], [ 29.77916493670886, -1.477187413793104 ], [ 29.779434430379748, -1.477187413793104 ], [ 29.779434430379748, -1.476917931034483 ], [ 29.77916493670886, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29344, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.476917931034483 ], [ 29.779434430379748, -1.477187413793104 ], [ 29.779703924050633, -1.477187413793104 ], [ 29.779703924050633, -1.476917931034483 ], [ 29.779434430379748, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29345, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.476917931034483 ], [ 29.779703924050633, -1.477187413793104 ], [ 29.779973417721518, -1.477187413793104 ], [ 29.779973417721518, -1.476917931034483 ], [ 29.779703924050633, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29346, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.476917931034483 ], [ 29.779973417721518, -1.477187413793104 ], [ 29.780242911392406, -1.477187413793104 ], [ 29.780242911392406, -1.476917931034483 ], [ 29.779973417721518, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29347, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.476917931034483 ], [ 29.780242911392406, -1.477187413793104 ], [ 29.780512405063291, -1.477187413793104 ], [ 29.780512405063291, -1.476917931034483 ], [ 29.780242911392406, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29348, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.476917931034483 ], [ 29.780512405063291, -1.477187413793104 ], [ 29.780781898734176, -1.477187413793104 ], [ 29.780781898734176, -1.476917931034483 ], [ 29.780512405063291, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29349, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.476917931034483 ], [ 29.780781898734176, -1.477187413793104 ], [ 29.781051392405065, -1.477187413793104 ], [ 29.781051392405065, -1.476917931034483 ], [ 29.780781898734176, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29350, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.476917931034483 ], [ 29.781051392405065, -1.477187413793104 ], [ 29.78132088607595, -1.477187413793104 ], [ 29.78132088607595, -1.476917931034483 ], [ 29.781051392405065, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29351, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.476917931034483 ], [ 29.78132088607595, -1.477187413793104 ], [ 29.781590379746834, -1.477187413793104 ], [ 29.781590379746834, -1.476917931034483 ], [ 29.78132088607595, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29352, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.476917931034483 ], [ 29.781590379746834, -1.477187413793104 ], [ 29.781859873417723, -1.477187413793104 ], [ 29.781859873417723, -1.476917931034483 ], [ 29.781590379746834, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29353, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.476917931034483 ], [ 29.781859873417723, -1.477187413793104 ], [ 29.782129367088608, -1.477187413793104 ], [ 29.782129367088608, -1.476917931034483 ], [ 29.781859873417723, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29354, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.476917931034483 ], [ 29.782129367088608, -1.477187413793104 ], [ 29.782398860759493, -1.477187413793104 ], [ 29.782398860759493, -1.476917931034483 ], [ 29.782129367088608, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29355, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.476917931034483 ], [ 29.782398860759493, -1.477187413793104 ], [ 29.782668354430381, -1.477187413793104 ], [ 29.782668354430381, -1.476917931034483 ], [ 29.782398860759493, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29356, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.476917931034483 ], [ 29.782668354430381, -1.477187413793104 ], [ 29.782937848101266, -1.477187413793104 ], [ 29.782937848101266, -1.476917931034483 ], [ 29.782668354430381, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29357, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.476917931034483 ], [ 29.782937848101266, -1.477187413793104 ], [ 29.783207341772151, -1.477187413793104 ], [ 29.783207341772151, -1.476917931034483 ], [ 29.782937848101266, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29358, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.476917931034483 ], [ 29.783207341772151, -1.477187413793104 ], [ 29.783476835443039, -1.477187413793104 ], [ 29.783476835443039, -1.476917931034483 ], [ 29.783207341772151, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29359, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.476917931034483 ], [ 29.783476835443039, -1.477187413793104 ], [ 29.784015822784809, -1.477187413793104 ], [ 29.784015822784809, -1.476917931034483 ], [ 29.783476835443039, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29360, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.476917931034483 ], [ 29.784015822784809, -1.477187413793104 ], [ 29.784285316455698, -1.477187413793104 ], [ 29.784285316455698, -1.476917931034483 ], [ 29.784015822784809, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29361, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.476917931034483 ], [ 29.784285316455698, -1.477187413793104 ], [ 29.784554810126583, -1.477187413793104 ], [ 29.784554810126583, -1.476917931034483 ], [ 29.784285316455698, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29362, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.476917931034483 ], [ 29.784554810126583, -1.477187413793104 ], [ 29.784824303797468, -1.477187413793104 ], [ 29.784824303797468, -1.476917931034483 ], [ 29.784554810126583, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29363, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.476917931034483 ], [ 29.784824303797468, -1.477187413793104 ], [ 29.785093797468356, -1.477187413793104 ], [ 29.785093797468356, -1.476917931034483 ], [ 29.784824303797468, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29364, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.476917931034483 ], [ 29.785093797468356, -1.477187413793104 ], [ 29.785363291139241, -1.477187413793104 ], [ 29.785363291139241, -1.476917931034483 ], [ 29.785093797468356, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29365, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.476917931034483 ], [ 29.785363291139241, -1.477187413793104 ], [ 29.785632784810126, -1.477187413793104 ], [ 29.785632784810126, -1.476917931034483 ], [ 29.785363291139241, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29366, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.476917931034483 ], [ 29.785632784810126, -1.477187413793104 ], [ 29.786171772151899, -1.477187413793104 ], [ 29.786171772151899, -1.476917931034483 ], [ 29.785632784810126, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29367, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.476917931034483 ], [ 29.786171772151899, -1.477187413793104 ], [ 29.786980253164558, -1.477187413793104 ], [ 29.786980253164558, -1.476917931034483 ], [ 29.786171772151899, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29368, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.476917931034483 ], [ 29.786980253164558, -1.477187413793104 ], [ 29.787519240506327, -1.477187413793104 ], [ 29.787519240506327, -1.476917931034483 ], [ 29.786980253164558, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29369, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.476917931034483 ], [ 29.787519240506327, -1.477187413793104 ], [ 29.787788734177216, -1.477187413793104 ], [ 29.787788734177216, -1.476917931034483 ], [ 29.787519240506327, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29370, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.476917931034483 ], [ 29.787788734177216, -1.477187413793104 ], [ 29.788058227848101, -1.477187413793104 ], [ 29.788058227848101, -1.476917931034483 ], [ 29.787788734177216, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29371, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.476917931034483 ], [ 29.788327721518986, -1.477726379310345 ], [ 29.788597215189874, -1.477726379310345 ], [ 29.788597215189874, -1.476917931034483 ], [ 29.788327721518986, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29372, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.476917931034483 ], [ 29.788866708860759, -1.477995862068966 ], [ 29.789136202531644, -1.477995862068966 ], [ 29.789136202531644, -1.476917931034483 ], [ 29.788866708860759, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29373, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.476917931034483 ], [ 29.789136202531644, -1.477995862068966 ], [ 29.789405696202532, -1.477995862068966 ], [ 29.789405696202532, -1.476917931034483 ], [ 29.789136202531644, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29374, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.476917931034483 ], [ 29.789675189873417, -1.478265344827586 ], [ 29.789944683544302, -1.478265344827586 ], [ 29.789944683544302, -1.476917931034483 ], [ 29.789675189873417, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29375, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.476917931034483 ], [ 29.789944683544302, -1.478265344827586 ], [ 29.790214177215191, -1.478265344827586 ], [ 29.790214177215191, -1.476917931034483 ], [ 29.789944683544302, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29376, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.476917931034483 ], [ 29.790214177215191, -1.477187413793104 ], [ 29.790483670886076, -1.477187413793104 ], [ 29.790483670886076, -1.476917931034483 ], [ 29.790214177215191, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29377, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.476917931034483 ], [ 29.790483670886076, -1.477187413793104 ], [ 29.790753164556961, -1.477187413793104 ], [ 29.790753164556961, -1.476917931034483 ], [ 29.790483670886076, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29378, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.476917931034483 ], [ 29.790753164556961, -1.477187413793104 ], [ 29.791022658227849, -1.477187413793104 ], [ 29.791022658227849, -1.476917931034483 ], [ 29.790753164556961, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29379, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.797490506329115, -1.476917931034483 ], [ 29.797490506329115, -1.477995862068966 ], [ 29.79776, -1.477995862068966 ], [ 29.79776, -1.476917931034483 ], [ 29.797490506329115, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29380, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.476917931034483 ], [ 29.79776, -1.477726379310345 ], [ 29.798029493670885, -1.477726379310345 ], [ 29.798029493670885, -1.476917931034483 ], [ 29.79776, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29381, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.476917931034483 ], [ 29.798029493670885, -1.477726379310345 ], [ 29.798298987341774, -1.477726379310345 ], [ 29.798298987341774, -1.476917931034483 ], [ 29.798029493670885, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29382, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.476917931034483 ], [ 29.798837974683543, -1.477456896551724 ], [ 29.799107468354432, -1.477456896551724 ], [ 29.799107468354432, -1.476917931034483 ], [ 29.798837974683543, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29383, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.476917931034483 ], [ 29.799646455696202, -1.477187413793104 ], [ 29.79991594936709, -1.477187413793104 ], [ 29.79991594936709, -1.476917931034483 ], [ 29.799646455696202, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29384, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.476917931034483 ], [ 29.79991594936709, -1.477187413793104 ], [ 29.800185443037975, -1.477187413793104 ], [ 29.800185443037975, -1.476917931034483 ], [ 29.79991594936709, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29385, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.476917931034483 ], [ 29.800185443037975, -1.477187413793104 ], [ 29.80045493670886, -1.477187413793104 ], [ 29.80045493670886, -1.476917931034483 ], [ 29.800185443037975, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29386, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.476917931034483 ], [ 29.80045493670886, -1.477187413793104 ], [ 29.800724430379748, -1.477187413793104 ], [ 29.800724430379748, -1.476917931034483 ], [ 29.80045493670886, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29387, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.476917931034483 ], [ 29.801263417721518, -1.477995862068966 ], [ 29.801532911392407, -1.477995862068966 ], [ 29.801532911392407, -1.476917931034483 ], [ 29.801263417721518, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29388, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.476917931034483 ], [ 29.802071898734177, -1.478265344827586 ], [ 29.802341392405065, -1.478265344827586 ], [ 29.802341392405065, -1.476917931034483 ], [ 29.802071898734177, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29389, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.476917931034483 ], [ 29.802341392405065, -1.478265344827586 ], [ 29.80261088607595, -1.478265344827586 ], [ 29.80261088607595, -1.476917931034483 ], [ 29.802341392405065, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29390, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.476917931034483 ], [ 29.803419367088608, -1.477187413793104 ], [ 29.803688860759493, -1.477187413793104 ], [ 29.803688860759493, -1.476917931034483 ], [ 29.803419367088608, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29391, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.476917931034483 ], [ 29.803688860759493, -1.477187413793104 ], [ 29.803958354430382, -1.477187413793104 ], [ 29.803958354430382, -1.476917931034483 ], [ 29.803688860759493, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29392, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.476917931034483 ], [ 29.803958354430382, -1.477187413793104 ], [ 29.804227848101267, -1.477187413793104 ], [ 29.804227848101267, -1.476917931034483 ], [ 29.803958354430382, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.476917931034483 ], [ 29.804227848101267, -1.477187413793104 ], [ 29.805036329113925, -1.477187413793104 ], [ 29.805036329113925, -1.476917931034483 ], [ 29.804227848101267, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29394, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759491898734176, -1.477187413793104 ], [ 29.759491898734176, -1.477456896551724 ], [ 29.759761392405064, -1.477456896551724 ], [ 29.759761392405064, -1.477187413793104 ], [ 29.759491898734176, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29395, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.477187413793104 ], [ 29.759761392405064, -1.477456896551724 ], [ 29.760030886075949, -1.477456896551724 ], [ 29.760030886075949, -1.477187413793104 ], [ 29.759761392405064, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29396, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.477187413793104 ], [ 29.760030886075949, -1.477456896551724 ], [ 29.760300379746834, -1.477456896551724 ], [ 29.760300379746834, -1.477187413793104 ], [ 29.760030886075949, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29397, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.477187413793104 ], [ 29.760300379746834, -1.477456896551724 ], [ 29.760569873417722, -1.477456896551724 ], [ 29.760569873417722, -1.477187413793104 ], [ 29.760300379746834, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29398, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.477187413793104 ], [ 29.760569873417722, -1.477456896551724 ], [ 29.760839367088607, -1.477456896551724 ], [ 29.760839367088607, -1.477187413793104 ], [ 29.760569873417722, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29399, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.477187413793104 ], [ 29.760839367088607, -1.477456896551724 ], [ 29.761108860759492, -1.477456896551724 ], [ 29.761108860759492, -1.477187413793104 ], [ 29.760839367088607, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29400, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.477187413793104 ], [ 29.761108860759492, -1.477456896551724 ], [ 29.761378354430381, -1.477456896551724 ], [ 29.761378354430381, -1.477187413793104 ], [ 29.761108860759492, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29401, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.477187413793104 ], [ 29.761378354430381, -1.477456896551724 ], [ 29.761647848101266, -1.477456896551724 ], [ 29.761647848101266, -1.477187413793104 ], [ 29.761378354430381, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29402, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.477187413793104 ], [ 29.761647848101266, -1.477456896551724 ], [ 29.761917341772151, -1.477456896551724 ], [ 29.761917341772151, -1.477187413793104 ], [ 29.761647848101266, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29403, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.477187413793104 ], [ 29.761917341772151, -1.477456896551724 ], [ 29.762186835443039, -1.477456896551724 ], [ 29.762186835443039, -1.477187413793104 ], [ 29.761917341772151, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29404, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.477187413793104 ], [ 29.762186835443039, -1.477456896551724 ], [ 29.762456329113924, -1.477456896551724 ], [ 29.762456329113924, -1.477187413793104 ], [ 29.762186835443039, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29405, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.477187413793104 ], [ 29.762456329113924, -1.477456896551724 ], [ 29.762725822784809, -1.477456896551724 ], [ 29.762725822784809, -1.477187413793104 ], [ 29.762456329113924, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29406, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.477187413793104 ], [ 29.762725822784809, -1.477456896551724 ], [ 29.762995316455697, -1.477456896551724 ], [ 29.762995316455697, -1.477187413793104 ], [ 29.762725822784809, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29407, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.477187413793104 ], [ 29.762995316455697, -1.477456896551724 ], [ 29.763264810126582, -1.477456896551724 ], [ 29.763264810126582, -1.477187413793104 ], [ 29.762995316455697, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29408, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.477187413793104 ], [ 29.763264810126582, -1.477456896551724 ], [ 29.763534303797467, -1.477456896551724 ], [ 29.763534303797467, -1.477187413793104 ], [ 29.763264810126582, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29409, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.477187413793104 ], [ 29.763534303797467, -1.477456896551724 ], [ 29.763803797468356, -1.477456896551724 ], [ 29.763803797468356, -1.477187413793104 ], [ 29.763534303797467, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29410, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.477187413793104 ], [ 29.76407329113924, -1.477456896551724 ], [ 29.764342784810125, -1.477456896551724 ], [ 29.764342784810125, -1.477187413793104 ], [ 29.76407329113924, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29411, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.477187413793104 ], [ 29.764342784810125, -1.477456896551724 ], [ 29.764612278481014, -1.477456896551724 ], [ 29.764612278481014, -1.477187413793104 ], [ 29.764342784810125, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29412, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.477187413793104 ], [ 29.764612278481014, -1.477456896551724 ], [ 29.764881772151899, -1.477456896551724 ], [ 29.764881772151899, -1.477187413793104 ], [ 29.764612278481014, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29413, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.477187413793104 ], [ 29.764881772151899, -1.477456896551724 ], [ 29.765151265822784, -1.477456896551724 ], [ 29.765151265822784, -1.477187413793104 ], [ 29.764881772151899, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29414, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.477187413793104 ], [ 29.765151265822784, -1.477456896551724 ], [ 29.765420759493672, -1.477456896551724 ], [ 29.765420759493672, -1.477187413793104 ], [ 29.765151265822784, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29415, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.477187413793104 ], [ 29.765420759493672, -1.477456896551724 ], [ 29.765959746835442, -1.477456896551724 ], [ 29.765959746835442, -1.477187413793104 ], [ 29.765420759493672, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29416, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.477187413793104 ], [ 29.765959746835442, -1.477456896551724 ], [ 29.766229240506327, -1.477456896551724 ], [ 29.766229240506327, -1.477187413793104 ], [ 29.765959746835442, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29417, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.477187413793104 ], [ 29.766229240506327, -1.477456896551724 ], [ 29.766498734177215, -1.477456896551724 ], [ 29.766498734177215, -1.477187413793104 ], [ 29.766229240506327, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29418, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.477187413793104 ], [ 29.766498734177215, -1.477456896551724 ], [ 29.7667682278481, -1.477456896551724 ], [ 29.7667682278481, -1.477187413793104 ], [ 29.766498734177215, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29419, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.477187413793104 ], [ 29.7667682278481, -1.477456896551724 ], [ 29.767037721518985, -1.477456896551724 ], [ 29.767037721518985, -1.477187413793104 ], [ 29.7667682278481, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29420, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.477187413793104 ], [ 29.767037721518985, -1.477456896551724 ], [ 29.767307215189874, -1.477456896551724 ], [ 29.767307215189874, -1.477187413793104 ], [ 29.767037721518985, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29421, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.477187413793104 ], [ 29.767307215189874, -1.477456896551724 ], [ 29.767576708860759, -1.477456896551724 ], [ 29.767576708860759, -1.477187413793104 ], [ 29.767307215189874, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29422, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.477187413793104 ], [ 29.767576708860759, -1.477456896551724 ], [ 29.767846202531643, -1.477456896551724 ], [ 29.767846202531643, -1.477187413793104 ], [ 29.767576708860759, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29423, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.477187413793104 ], [ 29.767846202531643, -1.477456896551724 ], [ 29.768115696202532, -1.477456896551724 ], [ 29.768115696202532, -1.477187413793104 ], [ 29.767846202531643, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29424, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.477187413793104 ], [ 29.768115696202532, -1.477456896551724 ], [ 29.768385189873417, -1.477456896551724 ], [ 29.768385189873417, -1.477187413793104 ], [ 29.768115696202532, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29425, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.477187413793104 ], [ 29.768385189873417, -1.477456896551724 ], [ 29.76892417721519, -1.477456896551724 ], [ 29.76892417721519, -1.477187413793104 ], [ 29.768385189873417, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29426, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.477187413793104 ], [ 29.770002151898733, -1.477456896551724 ], [ 29.770271645569618, -1.477456896551724 ], [ 29.770271645569618, -1.477187413793104 ], [ 29.770002151898733, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29427, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.477187413793104 ], [ 29.770271645569618, -1.477456896551724 ], [ 29.770541139240507, -1.477456896551724 ], [ 29.770541139240507, -1.477187413793104 ], [ 29.770271645569618, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29428, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.477187413793104 ], [ 29.770541139240507, -1.477456896551724 ], [ 29.770810632911392, -1.477456896551724 ], [ 29.770810632911392, -1.477187413793104 ], [ 29.770541139240507, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29429, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.477187413793104 ], [ 29.770810632911392, -1.477456896551724 ], [ 29.771080126582277, -1.477456896551724 ], [ 29.771080126582277, -1.477187413793104 ], [ 29.770810632911392, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29430, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.477187413793104 ], [ 29.771080126582277, -1.477456896551724 ], [ 29.771349620253165, -1.477456896551724 ], [ 29.771349620253165, -1.477187413793104 ], [ 29.771080126582277, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29431, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.477187413793104 ], [ 29.771349620253165, -1.477456896551724 ], [ 29.77161911392405, -1.477456896551724 ], [ 29.77161911392405, -1.477187413793104 ], [ 29.771349620253165, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29432, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.477187413793104 ], [ 29.77161911392405, -1.477456896551724 ], [ 29.771888607594935, -1.477456896551724 ], [ 29.771888607594935, -1.477187413793104 ], [ 29.77161911392405, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29433, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.477187413793104 ], [ 29.771888607594935, -1.477456896551724 ], [ 29.772158101265823, -1.477456896551724 ], [ 29.772158101265823, -1.477187413793104 ], [ 29.771888607594935, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29434, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.477187413793104 ], [ 29.772158101265823, -1.477456896551724 ], [ 29.772427594936708, -1.477456896551724 ], [ 29.772427594936708, -1.477187413793104 ], [ 29.772158101265823, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29435, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.477187413793104 ], [ 29.772427594936708, -1.477456896551724 ], [ 29.772697088607593, -1.477456896551724 ], [ 29.772697088607593, -1.477187413793104 ], [ 29.772427594936708, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29436, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.477187413793104 ], [ 29.772697088607593, -1.477456896551724 ], [ 29.772966582278482, -1.477456896551724 ], [ 29.772966582278482, -1.477187413793104 ], [ 29.772697088607593, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29437, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.477187413793104 ], [ 29.772966582278482, -1.477456896551724 ], [ 29.773236075949367, -1.477456896551724 ], [ 29.773236075949367, -1.477187413793104 ], [ 29.772966582278482, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29438, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.477187413793104 ], [ 29.773236075949367, -1.477456896551724 ], [ 29.773505569620252, -1.477456896551724 ], [ 29.773505569620252, -1.477187413793104 ], [ 29.773236075949367, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29439, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.477187413793104 ], [ 29.773505569620252, -1.477456896551724 ], [ 29.77377506329114, -1.477456896551724 ], [ 29.77377506329114, -1.477187413793104 ], [ 29.773505569620252, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29440, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.477187413793104 ], [ 29.77377506329114, -1.477456896551724 ], [ 29.774044556962025, -1.477456896551724 ], [ 29.774044556962025, -1.477187413793104 ], [ 29.77377506329114, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29441, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.477187413793104 ], [ 29.774044556962025, -1.477456896551724 ], [ 29.77431405063291, -1.477456896551724 ], [ 29.77431405063291, -1.477187413793104 ], [ 29.774044556962025, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29442, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.477187413793104 ], [ 29.774853037974683, -1.477456896551724 ], [ 29.775122531645568, -1.477456896551724 ], [ 29.775122531645568, -1.477187413793104 ], [ 29.774853037974683, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29443, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.477187413793104 ], [ 29.775122531645568, -1.477456896551724 ], [ 29.775392025316457, -1.477456896551724 ], [ 29.775392025316457, -1.477187413793104 ], [ 29.775122531645568, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29444, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.477187413793104 ], [ 29.775661518987341, -1.477456896551724 ], [ 29.775931012658226, -1.477456896551724 ], [ 29.775931012658226, -1.477187413793104 ], [ 29.775661518987341, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29445, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.477187413793104 ], [ 29.775931012658226, -1.477456896551724 ], [ 29.776200506329115, -1.477456896551724 ], [ 29.776200506329115, -1.477187413793104 ], [ 29.775931012658226, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29446, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.477187413793104 ], [ 29.77647, -1.477726379310345 ], [ 29.776739493670885, -1.477726379310345 ], [ 29.776739493670885, -1.477187413793104 ], [ 29.77647, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29447, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.477187413793104 ], [ 29.776739493670885, -1.477456896551724 ], [ 29.777008987341773, -1.477456896551724 ], [ 29.777008987341773, -1.477187413793104 ], [ 29.776739493670885, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29448, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.477187413793104 ], [ 29.777278481012658, -1.477726379310345 ], [ 29.777547974683543, -1.477726379310345 ], [ 29.777547974683543, -1.477187413793104 ], [ 29.777278481012658, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29449, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.477187413793104 ], [ 29.778086962025316, -1.477726379310345 ], [ 29.778356455696201, -1.477726379310345 ], [ 29.778356455696201, -1.477187413793104 ], [ 29.778086962025316, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29450, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.477187413793104 ], [ 29.778895443037975, -1.477456896551724 ], [ 29.77916493670886, -1.477456896551724 ], [ 29.77916493670886, -1.477187413793104 ], [ 29.778895443037975, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29451, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.477187413793104 ], [ 29.77916493670886, -1.477456896551724 ], [ 29.779434430379748, -1.477456896551724 ], [ 29.779434430379748, -1.477187413793104 ], [ 29.77916493670886, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29452, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.477187413793104 ], [ 29.779434430379748, -1.477456896551724 ], [ 29.779703924050633, -1.477456896551724 ], [ 29.779703924050633, -1.477187413793104 ], [ 29.779434430379748, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29453, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.477187413793104 ], [ 29.779703924050633, -1.477456896551724 ], [ 29.779973417721518, -1.477456896551724 ], [ 29.779973417721518, -1.477187413793104 ], [ 29.779703924050633, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29454, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.477187413793104 ], [ 29.779973417721518, -1.477456896551724 ], [ 29.780242911392406, -1.477456896551724 ], [ 29.780242911392406, -1.477187413793104 ], [ 29.779973417721518, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29455, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.477187413793104 ], [ 29.780242911392406, -1.477456896551724 ], [ 29.780512405063291, -1.477456896551724 ], [ 29.780512405063291, -1.477187413793104 ], [ 29.780242911392406, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29456, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.477187413793104 ], [ 29.780512405063291, -1.477456896551724 ], [ 29.780781898734176, -1.477456896551724 ], [ 29.780781898734176, -1.477187413793104 ], [ 29.780512405063291, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29457, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.477187413793104 ], [ 29.780781898734176, -1.477456896551724 ], [ 29.781051392405065, -1.477456896551724 ], [ 29.781051392405065, -1.477187413793104 ], [ 29.780781898734176, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29458, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.477187413793104 ], [ 29.781051392405065, -1.477456896551724 ], [ 29.78132088607595, -1.477456896551724 ], [ 29.78132088607595, -1.477187413793104 ], [ 29.781051392405065, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29459, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.477187413793104 ], [ 29.78132088607595, -1.477456896551724 ], [ 29.781590379746834, -1.477456896551724 ], [ 29.781590379746834, -1.477187413793104 ], [ 29.78132088607595, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29460, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.477187413793104 ], [ 29.781590379746834, -1.477456896551724 ], [ 29.781859873417723, -1.477456896551724 ], [ 29.781859873417723, -1.477187413793104 ], [ 29.781590379746834, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29461, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.477187413793104 ], [ 29.781859873417723, -1.477456896551724 ], [ 29.782129367088608, -1.477456896551724 ], [ 29.782129367088608, -1.477187413793104 ], [ 29.781859873417723, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29462, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.477187413793104 ], [ 29.782129367088608, -1.477456896551724 ], [ 29.782398860759493, -1.477456896551724 ], [ 29.782398860759493, -1.477187413793104 ], [ 29.782129367088608, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29463, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.477187413793104 ], [ 29.782398860759493, -1.477456896551724 ], [ 29.782668354430381, -1.477456896551724 ], [ 29.782668354430381, -1.477187413793104 ], [ 29.782398860759493, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29464, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.477187413793104 ], [ 29.782668354430381, -1.477456896551724 ], [ 29.782937848101266, -1.477456896551724 ], [ 29.782937848101266, -1.477187413793104 ], [ 29.782668354430381, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29465, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.477187413793104 ], [ 29.782937848101266, -1.477456896551724 ], [ 29.783207341772151, -1.477456896551724 ], [ 29.783207341772151, -1.477187413793104 ], [ 29.782937848101266, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29466, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.477187413793104 ], [ 29.783207341772151, -1.477456896551724 ], [ 29.783476835443039, -1.477456896551724 ], [ 29.783476835443039, -1.477187413793104 ], [ 29.783207341772151, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29467, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.477187413793104 ], [ 29.783476835443039, -1.477456896551724 ], [ 29.784015822784809, -1.477456896551724 ], [ 29.784015822784809, -1.477187413793104 ], [ 29.783476835443039, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29468, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.477187413793104 ], [ 29.784015822784809, -1.477456896551724 ], [ 29.784285316455698, -1.477456896551724 ], [ 29.784285316455698, -1.477187413793104 ], [ 29.784015822784809, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29469, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.477187413793104 ], [ 29.784285316455698, -1.477456896551724 ], [ 29.784554810126583, -1.477456896551724 ], [ 29.784554810126583, -1.477187413793104 ], [ 29.784285316455698, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29470, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.477187413793104 ], [ 29.784554810126583, -1.477456896551724 ], [ 29.784824303797468, -1.477456896551724 ], [ 29.784824303797468, -1.477187413793104 ], [ 29.784554810126583, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29471, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.477187413793104 ], [ 29.784824303797468, -1.477456896551724 ], [ 29.785093797468356, -1.477456896551724 ], [ 29.785093797468356, -1.477187413793104 ], [ 29.784824303797468, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29472, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.477187413793104 ], [ 29.785093797468356, -1.477456896551724 ], [ 29.785363291139241, -1.477456896551724 ], [ 29.785363291139241, -1.477187413793104 ], [ 29.785093797468356, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29473, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.477187413793104 ], [ 29.785363291139241, -1.477456896551724 ], [ 29.785902278481014, -1.477456896551724 ], [ 29.785902278481014, -1.477187413793104 ], [ 29.785363291139241, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29474, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.477187413793104 ], [ 29.785902278481014, -1.477456896551724 ], [ 29.787249746835442, -1.477456896551724 ], [ 29.787249746835442, -1.477187413793104 ], [ 29.785902278481014, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29475, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.477187413793104 ], [ 29.787249746835442, -1.477456896551724 ], [ 29.787788734177216, -1.477456896551724 ], [ 29.787788734177216, -1.477187413793104 ], [ 29.787249746835442, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29476, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.477187413793104 ], [ 29.787788734177216, -1.477456896551724 ], [ 29.788327721518986, -1.477456896551724 ], [ 29.788327721518986, -1.477187413793104 ], [ 29.787788734177216, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29477, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.477187413793104 ], [ 29.789405696202532, -1.477995862068966 ], [ 29.789675189873417, -1.477995862068966 ], [ 29.789675189873417, -1.477187413793104 ], [ 29.789405696202532, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29478, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.477187413793104 ], [ 29.799107468354432, -1.477456896551724 ], [ 29.799376962025317, -1.477456896551724 ], [ 29.799376962025317, -1.477187413793104 ], [ 29.799107468354432, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29479, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.477187413793104 ], [ 29.799376962025317, -1.477456896551724 ], [ 29.799646455696202, -1.477456896551724 ], [ 29.799646455696202, -1.477187413793104 ], [ 29.799376962025317, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29480, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.477187413793104 ], [ 29.799646455696202, -1.477456896551724 ], [ 29.79991594936709, -1.477456896551724 ], [ 29.79991594936709, -1.477187413793104 ], [ 29.799646455696202, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29481, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.477187413793104 ], [ 29.79991594936709, -1.477456896551724 ], [ 29.800185443037975, -1.477456896551724 ], [ 29.800185443037975, -1.477187413793104 ], [ 29.79991594936709, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29482, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.477187413793104 ], [ 29.800185443037975, -1.477726379310345 ], [ 29.80045493670886, -1.477726379310345 ], [ 29.80045493670886, -1.477187413793104 ], [ 29.800185443037975, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29483, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.477187413793104 ], [ 29.80045493670886, -1.477456896551724 ], [ 29.800724430379748, -1.477456896551724 ], [ 29.800724430379748, -1.477187413793104 ], [ 29.80045493670886, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29484, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.477187413793104 ], [ 29.800993924050633, -1.478265344827586 ], [ 29.801263417721518, -1.478265344827586 ], [ 29.801263417721518, -1.477187413793104 ], [ 29.800993924050633, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29485, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.759761392405064, -1.477456896551724 ], [ 29.759761392405064, -1.477726379310345 ], [ 29.760030886075949, -1.477726379310345 ], [ 29.760030886075949, -1.477456896551724 ], [ 29.759761392405064, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29486, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.477456896551724 ], [ 29.760030886075949, -1.477726379310345 ], [ 29.760300379746834, -1.477726379310345 ], [ 29.760300379746834, -1.477456896551724 ], [ 29.760030886075949, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29487, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.477456896551724 ], [ 29.760300379746834, -1.477726379310345 ], [ 29.760569873417722, -1.477726379310345 ], [ 29.760569873417722, -1.477456896551724 ], [ 29.760300379746834, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29488, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.477456896551724 ], [ 29.760569873417722, -1.477726379310345 ], [ 29.760839367088607, -1.477726379310345 ], [ 29.760839367088607, -1.477456896551724 ], [ 29.760569873417722, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29489, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.477456896551724 ], [ 29.760839367088607, -1.477726379310345 ], [ 29.761108860759492, -1.477726379310345 ], [ 29.761108860759492, -1.477456896551724 ], [ 29.760839367088607, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29490, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.477456896551724 ], [ 29.761108860759492, -1.477726379310345 ], [ 29.761378354430381, -1.477726379310345 ], [ 29.761378354430381, -1.477456896551724 ], [ 29.761108860759492, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29491, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.477456896551724 ], [ 29.761378354430381, -1.477726379310345 ], [ 29.761647848101266, -1.477726379310345 ], [ 29.761647848101266, -1.477456896551724 ], [ 29.761378354430381, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29492, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.477456896551724 ], [ 29.761647848101266, -1.477726379310345 ], [ 29.761917341772151, -1.477726379310345 ], [ 29.761917341772151, -1.477456896551724 ], [ 29.761647848101266, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29493, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.477456896551724 ], [ 29.761917341772151, -1.477726379310345 ], [ 29.762186835443039, -1.477726379310345 ], [ 29.762186835443039, -1.477456896551724 ], [ 29.761917341772151, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29494, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.477456896551724 ], [ 29.762186835443039, -1.477726379310345 ], [ 29.762456329113924, -1.477726379310345 ], [ 29.762456329113924, -1.477456896551724 ], [ 29.762186835443039, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29495, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.477456896551724 ], [ 29.762456329113924, -1.477726379310345 ], [ 29.762725822784809, -1.477726379310345 ], [ 29.762725822784809, -1.477456896551724 ], [ 29.762456329113924, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29496, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.477456896551724 ], [ 29.762725822784809, -1.477726379310345 ], [ 29.762995316455697, -1.477726379310345 ], [ 29.762995316455697, -1.477456896551724 ], [ 29.762725822784809, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29497, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.477456896551724 ], [ 29.762995316455697, -1.477726379310345 ], [ 29.763264810126582, -1.477726379310345 ], [ 29.763264810126582, -1.477456896551724 ], [ 29.762995316455697, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29498, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.477456896551724 ], [ 29.763264810126582, -1.477726379310345 ], [ 29.763534303797467, -1.477726379310345 ], [ 29.763534303797467, -1.477456896551724 ], [ 29.763264810126582, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29499, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.477456896551724 ], [ 29.763534303797467, -1.477726379310345 ], [ 29.763803797468356, -1.477726379310345 ], [ 29.763803797468356, -1.477456896551724 ], [ 29.763534303797467, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29500, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.477456896551724 ], [ 29.763803797468356, -1.477726379310345 ], [ 29.76407329113924, -1.477726379310345 ], [ 29.76407329113924, -1.477456896551724 ], [ 29.763803797468356, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29501, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.477456896551724 ], [ 29.76407329113924, -1.477995862068966 ], [ 29.764612278481014, -1.477995862068966 ], [ 29.764612278481014, -1.477726379310345 ], [ 29.764342784810125, -1.477726379310345 ], [ 29.764342784810125, -1.477456896551724 ], [ 29.76407329113924, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29502, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.477456896551724 ], [ 29.764342784810125, -1.477726379310345 ], [ 29.764612278481014, -1.477726379310345 ], [ 29.764612278481014, -1.477456896551724 ], [ 29.764342784810125, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29503, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.477456896551724 ], [ 29.764612278481014, -1.477726379310345 ], [ 29.764881772151899, -1.477726379310345 ], [ 29.764881772151899, -1.477456896551724 ], [ 29.764612278481014, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29504, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.477456896551724 ], [ 29.764881772151899, -1.477726379310345 ], [ 29.765151265822784, -1.477726379310345 ], [ 29.765151265822784, -1.477456896551724 ], [ 29.764881772151899, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29505, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.477456896551724 ], [ 29.765151265822784, -1.477726379310345 ], [ 29.765420759493672, -1.477726379310345 ], [ 29.765420759493672, -1.477456896551724 ], [ 29.765151265822784, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29506, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.477456896551724 ], [ 29.765420759493672, -1.477726379310345 ], [ 29.765959746835442, -1.477726379310345 ], [ 29.765959746835442, -1.477456896551724 ], [ 29.765420759493672, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29507, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.477456896551724 ], [ 29.765959746835442, -1.477726379310345 ], [ 29.766229240506327, -1.477726379310345 ], [ 29.766229240506327, -1.477456896551724 ], [ 29.765959746835442, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29508, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.477456896551724 ], [ 29.766229240506327, -1.477726379310345 ], [ 29.766498734177215, -1.477726379310345 ], [ 29.766498734177215, -1.477456896551724 ], [ 29.766229240506327, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29509, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.477456896551724 ], [ 29.766498734177215, -1.477726379310345 ], [ 29.7667682278481, -1.477726379310345 ], [ 29.7667682278481, -1.477456896551724 ], [ 29.766498734177215, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29510, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.477456896551724 ], [ 29.7667682278481, -1.477726379310345 ], [ 29.767037721518985, -1.477726379310345 ], [ 29.767037721518985, -1.477456896551724 ], [ 29.7667682278481, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29511, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.477456896551724 ], [ 29.767037721518985, -1.477726379310345 ], [ 29.767307215189874, -1.477726379310345 ], [ 29.767307215189874, -1.477456896551724 ], [ 29.767037721518985, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29512, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.477456896551724 ], [ 29.767307215189874, -1.477726379310345 ], [ 29.767576708860759, -1.477726379310345 ], [ 29.767576708860759, -1.477456896551724 ], [ 29.767307215189874, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29513, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.477456896551724 ], [ 29.767576708860759, -1.477726379310345 ], [ 29.767846202531643, -1.477726379310345 ], [ 29.767846202531643, -1.477456896551724 ], [ 29.767576708860759, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29514, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.477456896551724 ], [ 29.767846202531643, -1.477726379310345 ], [ 29.768115696202532, -1.477726379310345 ], [ 29.768115696202532, -1.477456896551724 ], [ 29.767846202531643, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29515, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.477456896551724 ], [ 29.768115696202532, -1.477726379310345 ], [ 29.768385189873417, -1.477726379310345 ], [ 29.768385189873417, -1.477456896551724 ], [ 29.768115696202532, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29516, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.477456896551724 ], [ 29.768385189873417, -1.477726379310345 ], [ 29.768654683544302, -1.477726379310345 ], [ 29.768654683544302, -1.477456896551724 ], [ 29.768385189873417, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29517, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.477456896551724 ], [ 29.768654683544302, -1.477726379310345 ], [ 29.76892417721519, -1.477726379310345 ], [ 29.76892417721519, -1.477456896551724 ], [ 29.768654683544302, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29518, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.477456896551724 ], [ 29.76892417721519, -1.477726379310345 ], [ 29.770002151898733, -1.477726379310345 ], [ 29.770002151898733, -1.477456896551724 ], [ 29.76892417721519, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29519, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.477456896551724 ], [ 29.770002151898733, -1.477726379310345 ], [ 29.770271645569618, -1.477726379310345 ], [ 29.770271645569618, -1.477456896551724 ], [ 29.770002151898733, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29520, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.477456896551724 ], [ 29.770271645569618, -1.477726379310345 ], [ 29.770541139240507, -1.477726379310345 ], [ 29.770541139240507, -1.477456896551724 ], [ 29.770271645569618, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29521, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.477456896551724 ], [ 29.770541139240507, -1.477726379310345 ], [ 29.770810632911392, -1.477726379310345 ], [ 29.770810632911392, -1.477456896551724 ], [ 29.770541139240507, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29522, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.477456896551724 ], [ 29.770810632911392, -1.477726379310345 ], [ 29.771080126582277, -1.477726379310345 ], [ 29.771080126582277, -1.477456896551724 ], [ 29.770810632911392, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29523, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.477456896551724 ], [ 29.771080126582277, -1.477726379310345 ], [ 29.771349620253165, -1.477726379310345 ], [ 29.771349620253165, -1.477456896551724 ], [ 29.771080126582277, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29524, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.477456896551724 ], [ 29.771349620253165, -1.477726379310345 ], [ 29.77161911392405, -1.477726379310345 ], [ 29.77161911392405, -1.477456896551724 ], [ 29.771349620253165, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29525, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.477456896551724 ], [ 29.77161911392405, -1.477726379310345 ], [ 29.771888607594935, -1.477726379310345 ], [ 29.771888607594935, -1.477456896551724 ], [ 29.77161911392405, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29526, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.477456896551724 ], [ 29.771888607594935, -1.477726379310345 ], [ 29.772158101265823, -1.477726379310345 ], [ 29.772158101265823, -1.477456896551724 ], [ 29.771888607594935, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29527, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.477456896551724 ], [ 29.772158101265823, -1.477726379310345 ], [ 29.772427594936708, -1.477726379310345 ], [ 29.772427594936708, -1.477456896551724 ], [ 29.772158101265823, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29528, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.477456896551724 ], [ 29.772427594936708, -1.477726379310345 ], [ 29.772697088607593, -1.477726379310345 ], [ 29.772697088607593, -1.477456896551724 ], [ 29.772427594936708, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29529, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.477456896551724 ], [ 29.772697088607593, -1.477726379310345 ], [ 29.772966582278482, -1.477726379310345 ], [ 29.772966582278482, -1.477456896551724 ], [ 29.772697088607593, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29530, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.477456896551724 ], [ 29.772966582278482, -1.477726379310345 ], [ 29.773236075949367, -1.477726379310345 ], [ 29.773236075949367, -1.477456896551724 ], [ 29.772966582278482, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29531, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.477456896551724 ], [ 29.773236075949367, -1.477726379310345 ], [ 29.773505569620252, -1.477726379310345 ], [ 29.773505569620252, -1.477456896551724 ], [ 29.773236075949367, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29532, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.477456896551724 ], [ 29.773505569620252, -1.477726379310345 ], [ 29.77377506329114, -1.477726379310345 ], [ 29.77377506329114, -1.477456896551724 ], [ 29.773505569620252, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29533, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.477456896551724 ], [ 29.77377506329114, -1.477726379310345 ], [ 29.774044556962025, -1.477726379310345 ], [ 29.774044556962025, -1.477456896551724 ], [ 29.77377506329114, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29534, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.477456896551724 ], [ 29.774044556962025, -1.477726379310345 ], [ 29.77431405063291, -1.477726379310345 ], [ 29.77431405063291, -1.477456896551724 ], [ 29.774044556962025, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29535, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.477456896551724 ], [ 29.77431405063291, -1.477726379310345 ], [ 29.774853037974683, -1.477726379310345 ], [ 29.774853037974683, -1.477456896551724 ], [ 29.77431405063291, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29536, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.477456896551724 ], [ 29.774853037974683, -1.477726379310345 ], [ 29.775122531645568, -1.477726379310345 ], [ 29.775122531645568, -1.477456896551724 ], [ 29.774853037974683, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29537, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.477456896551724 ], [ 29.775122531645568, -1.477726379310345 ], [ 29.775392025316457, -1.477726379310345 ], [ 29.775392025316457, -1.477456896551724 ], [ 29.775122531645568, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29538, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.477456896551724 ], [ 29.775392025316457, -1.477726379310345 ], [ 29.775661518987341, -1.477726379310345 ], [ 29.775661518987341, -1.477456896551724 ], [ 29.775392025316457, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29539, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.477456896551724 ], [ 29.775661518987341, -1.477995862068966 ], [ 29.775931012658226, -1.477995862068966 ], [ 29.775931012658226, -1.477456896551724 ], [ 29.775661518987341, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29540, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.477456896551724 ], [ 29.775931012658226, -1.477726379310345 ], [ 29.776200506329115, -1.477726379310345 ], [ 29.776200506329115, -1.477456896551724 ], [ 29.775931012658226, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29541, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.477456896551724 ], [ 29.776200506329115, -1.477726379310345 ], [ 29.77647, -1.477726379310345 ], [ 29.77647, -1.477456896551724 ], [ 29.776200506329115, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29542, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.477456896551724 ], [ 29.776739493670885, -1.477726379310345 ], [ 29.777008987341773, -1.477726379310345 ], [ 29.777008987341773, -1.477456896551724 ], [ 29.776739493670885, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29543, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.477456896551724 ], [ 29.777008987341773, -1.477726379310345 ], [ 29.777278481012658, -1.477726379310345 ], [ 29.777278481012658, -1.477456896551724 ], [ 29.777008987341773, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29544, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.477456896551724 ], [ 29.777547974683543, -1.477995862068966 ], [ 29.777817468354431, -1.477995862068966 ], [ 29.777817468354431, -1.477456896551724 ], [ 29.777547974683543, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29545, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.477456896551724 ], [ 29.777817468354431, -1.477726379310345 ], [ 29.778086962025316, -1.477726379310345 ], [ 29.778086962025316, -1.477456896551724 ], [ 29.777817468354431, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29546, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.477456896551724 ], [ 29.778356455696201, -1.477995862068966 ], [ 29.77862594936709, -1.477995862068966 ], [ 29.77862594936709, -1.477456896551724 ], [ 29.778356455696201, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29547, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.477456896551724 ], [ 29.77862594936709, -1.477726379310345 ], [ 29.778895443037975, -1.477726379310345 ], [ 29.778895443037975, -1.477456896551724 ], [ 29.77862594936709, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29548, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.477456896551724 ], [ 29.778895443037975, -1.477726379310345 ], [ 29.77916493670886, -1.477726379310345 ], [ 29.77916493670886, -1.477456896551724 ], [ 29.778895443037975, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29549, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.477456896551724 ], [ 29.77916493670886, -1.477726379310345 ], [ 29.779434430379748, -1.477726379310345 ], [ 29.779434430379748, -1.477456896551724 ], [ 29.77916493670886, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29550, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.477456896551724 ], [ 29.779434430379748, -1.477726379310345 ], [ 29.779703924050633, -1.477726379310345 ], [ 29.779703924050633, -1.477456896551724 ], [ 29.779434430379748, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29551, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.477456896551724 ], [ 29.779703924050633, -1.477726379310345 ], [ 29.779973417721518, -1.477726379310345 ], [ 29.779973417721518, -1.477456896551724 ], [ 29.779703924050633, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29552, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.477456896551724 ], [ 29.779973417721518, -1.477726379310345 ], [ 29.780242911392406, -1.477726379310345 ], [ 29.780242911392406, -1.477456896551724 ], [ 29.779973417721518, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29553, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.477456896551724 ], [ 29.780242911392406, -1.477726379310345 ], [ 29.780512405063291, -1.477726379310345 ], [ 29.780512405063291, -1.477456896551724 ], [ 29.780242911392406, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29554, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.477456896551724 ], [ 29.780512405063291, -1.477726379310345 ], [ 29.780781898734176, -1.477726379310345 ], [ 29.780781898734176, -1.477456896551724 ], [ 29.780512405063291, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29555, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.477456896551724 ], [ 29.780781898734176, -1.477726379310345 ], [ 29.781051392405065, -1.477726379310345 ], [ 29.781051392405065, -1.477456896551724 ], [ 29.780781898734176, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29556, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.477456896551724 ], [ 29.781051392405065, -1.477726379310345 ], [ 29.78132088607595, -1.477726379310345 ], [ 29.78132088607595, -1.477456896551724 ], [ 29.781051392405065, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29557, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.477456896551724 ], [ 29.78132088607595, -1.477726379310345 ], [ 29.781590379746834, -1.477726379310345 ], [ 29.781590379746834, -1.477456896551724 ], [ 29.78132088607595, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29558, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.477456896551724 ], [ 29.781590379746834, -1.477726379310345 ], [ 29.781859873417723, -1.477726379310345 ], [ 29.781859873417723, -1.477456896551724 ], [ 29.781590379746834, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29559, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.477456896551724 ], [ 29.781859873417723, -1.477726379310345 ], [ 29.782129367088608, -1.477726379310345 ], [ 29.782129367088608, -1.477456896551724 ], [ 29.781859873417723, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29560, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.477456896551724 ], [ 29.782129367088608, -1.477726379310345 ], [ 29.782398860759493, -1.477726379310345 ], [ 29.782398860759493, -1.477456896551724 ], [ 29.782129367088608, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29561, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.477456896551724 ], [ 29.782398860759493, -1.477726379310345 ], [ 29.782668354430381, -1.477726379310345 ], [ 29.782668354430381, -1.477456896551724 ], [ 29.782398860759493, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29562, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.477456896551724 ], [ 29.782668354430381, -1.477726379310345 ], [ 29.782937848101266, -1.477726379310345 ], [ 29.782937848101266, -1.477456896551724 ], [ 29.782668354430381, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29563, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.477456896551724 ], [ 29.782937848101266, -1.477726379310345 ], [ 29.783207341772151, -1.477726379310345 ], [ 29.783207341772151, -1.477456896551724 ], [ 29.782937848101266, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29564, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.477456896551724 ], [ 29.783207341772151, -1.477726379310345 ], [ 29.783476835443039, -1.477726379310345 ], [ 29.783476835443039, -1.477456896551724 ], [ 29.783207341772151, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29565, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.477456896551724 ], [ 29.783476835443039, -1.477726379310345 ], [ 29.784015822784809, -1.477726379310345 ], [ 29.784015822784809, -1.477456896551724 ], [ 29.783476835443039, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29566, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.477456896551724 ], [ 29.784015822784809, -1.477726379310345 ], [ 29.784285316455698, -1.477726379310345 ], [ 29.784285316455698, -1.477456896551724 ], [ 29.784015822784809, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29567, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.477456896551724 ], [ 29.784285316455698, -1.477726379310345 ], [ 29.784554810126583, -1.477726379310345 ], [ 29.784554810126583, -1.477456896551724 ], [ 29.784285316455698, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29568, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.477456896551724 ], [ 29.784554810126583, -1.477726379310345 ], [ 29.784824303797468, -1.477726379310345 ], [ 29.784824303797468, -1.477456896551724 ], [ 29.784554810126583, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29569, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.477456896551724 ], [ 29.784824303797468, -1.477726379310345 ], [ 29.785093797468356, -1.477726379310345 ], [ 29.785093797468356, -1.477456896551724 ], [ 29.784824303797468, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29570, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.477456896551724 ], [ 29.785093797468356, -1.477726379310345 ], [ 29.785363291139241, -1.477726379310345 ], [ 29.785363291139241, -1.477456896551724 ], [ 29.785093797468356, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29571, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.477456896551724 ], [ 29.785363291139241, -1.477726379310345 ], [ 29.785632784810126, -1.477726379310345 ], [ 29.785632784810126, -1.477456896551724 ], [ 29.785363291139241, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29572, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.477456896551724 ], [ 29.785632784810126, -1.477726379310345 ], [ 29.787519240506327, -1.477726379310345 ], [ 29.787519240506327, -1.477456896551724 ], [ 29.785632784810126, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29573, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.477456896551724 ], [ 29.787519240506327, -1.477726379310345 ], [ 29.787788734177216, -1.477726379310345 ], [ 29.787788734177216, -1.477456896551724 ], [ 29.787519240506327, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29574, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.477456896551724 ], [ 29.787788734177216, -1.477726379310345 ], [ 29.788058227848101, -1.477726379310345 ], [ 29.788058227848101, -1.477456896551724 ], [ 29.787788734177216, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29575, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.477456896551724 ], [ 29.788058227848101, -1.477726379310345 ], [ 29.788327721518986, -1.477726379310345 ], [ 29.788327721518986, -1.477456896551724 ], [ 29.788058227848101, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29576, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.477456896551724 ], [ 29.798298987341774, -1.477726379310345 ], [ 29.798568481012659, -1.477726379310345 ], [ 29.798568481012659, -1.477456896551724 ], [ 29.798298987341774, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29577, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.477456896551724 ], [ 29.798568481012659, -1.477726379310345 ], [ 29.798837974683543, -1.477726379310345 ], [ 29.798837974683543, -1.477456896551724 ], [ 29.798568481012659, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29578, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.477456896551724 ], [ 29.798837974683543, -1.477726379310345 ], [ 29.799107468354432, -1.477726379310345 ], [ 29.799107468354432, -1.477456896551724 ], [ 29.798837974683543, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29579, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.477456896551724 ], [ 29.799107468354432, -1.477995862068966 ], [ 29.799376962025317, -1.477995862068966 ], [ 29.799376962025317, -1.477456896551724 ], [ 29.799107468354432, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29580, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.477456896551724 ], [ 29.799376962025317, -1.477726379310345 ], [ 29.799646455696202, -1.477726379310345 ], [ 29.799646455696202, -1.477456896551724 ], [ 29.799376962025317, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29581, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.477456896551724 ], [ 29.799646455696202, -1.477726379310345 ], [ 29.79991594936709, -1.477726379310345 ], [ 29.79991594936709, -1.477456896551724 ], [ 29.799646455696202, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29582, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.477456896551724 ], [ 29.80045493670886, -1.477995862068966 ], [ 29.800724430379748, -1.477995862068966 ], [ 29.800724430379748, -1.477456896551724 ], [ 29.80045493670886, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29583, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760030886075949, -1.477726379310345 ], [ 29.760030886075949, -1.477995862068966 ], [ 29.760300379746834, -1.477995862068966 ], [ 29.760300379746834, -1.477726379310345 ], [ 29.760030886075949, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29584, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.477726379310345 ], [ 29.760300379746834, -1.477995862068966 ], [ 29.760569873417722, -1.477995862068966 ], [ 29.760569873417722, -1.477726379310345 ], [ 29.760300379746834, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29585, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.477726379310345 ], [ 29.760569873417722, -1.477995862068966 ], [ 29.760839367088607, -1.477995862068966 ], [ 29.760839367088607, -1.477726379310345 ], [ 29.760569873417722, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29586, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.477726379310345 ], [ 29.760839367088607, -1.477995862068966 ], [ 29.761108860759492, -1.477995862068966 ], [ 29.761108860759492, -1.477726379310345 ], [ 29.760839367088607, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29587, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.477726379310345 ], [ 29.761108860759492, -1.477995862068966 ], [ 29.761378354430381, -1.477995862068966 ], [ 29.761378354430381, -1.477726379310345 ], [ 29.761108860759492, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29588, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.477726379310345 ], [ 29.761378354430381, -1.477995862068966 ], [ 29.761647848101266, -1.477995862068966 ], [ 29.761647848101266, -1.477726379310345 ], [ 29.761378354430381, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29589, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.477726379310345 ], [ 29.761647848101266, -1.477995862068966 ], [ 29.761917341772151, -1.477995862068966 ], [ 29.761917341772151, -1.477726379310345 ], [ 29.761647848101266, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29590, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.477726379310345 ], [ 29.761917341772151, -1.477995862068966 ], [ 29.762186835443039, -1.477995862068966 ], [ 29.762186835443039, -1.477726379310345 ], [ 29.761917341772151, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29591, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.477726379310345 ], [ 29.762186835443039, -1.477995862068966 ], [ 29.762456329113924, -1.477995862068966 ], [ 29.762456329113924, -1.477726379310345 ], [ 29.762186835443039, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29592, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.477726379310345 ], [ 29.762456329113924, -1.477995862068966 ], [ 29.762725822784809, -1.477995862068966 ], [ 29.762725822784809, -1.477726379310345 ], [ 29.762456329113924, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29593, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.477726379310345 ], [ 29.762725822784809, -1.477995862068966 ], [ 29.762995316455697, -1.477995862068966 ], [ 29.762995316455697, -1.477726379310345 ], [ 29.762725822784809, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29594, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.477726379310345 ], [ 29.762995316455697, -1.477995862068966 ], [ 29.763264810126582, -1.477995862068966 ], [ 29.763264810126582, -1.477726379310345 ], [ 29.762995316455697, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29595, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.477726379310345 ], [ 29.763264810126582, -1.477995862068966 ], [ 29.763534303797467, -1.477995862068966 ], [ 29.763534303797467, -1.477726379310345 ], [ 29.763264810126582, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29596, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.477726379310345 ], [ 29.763534303797467, -1.477995862068966 ], [ 29.763803797468356, -1.477995862068966 ], [ 29.763803797468356, -1.477726379310345 ], [ 29.763534303797467, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29597, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.477726379310345 ], [ 29.763803797468356, -1.477995862068966 ], [ 29.76407329113924, -1.477995862068966 ], [ 29.76407329113924, -1.477726379310345 ], [ 29.763803797468356, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29598, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.477726379310345 ], [ 29.764612278481014, -1.477995862068966 ], [ 29.764881772151899, -1.477995862068966 ], [ 29.764881772151899, -1.477726379310345 ], [ 29.764612278481014, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29599, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.477726379310345 ], [ 29.764881772151899, -1.477995862068966 ], [ 29.765151265822784, -1.477995862068966 ], [ 29.765151265822784, -1.477726379310345 ], [ 29.764881772151899, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29600, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.477726379310345 ], [ 29.765151265822784, -1.477995862068966 ], [ 29.765420759493672, -1.477995862068966 ], [ 29.765420759493672, -1.477726379310345 ], [ 29.765151265822784, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29601, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.477726379310345 ], [ 29.765420759493672, -1.477995862068966 ], [ 29.765959746835442, -1.477995862068966 ], [ 29.765959746835442, -1.477726379310345 ], [ 29.765420759493672, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29602, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.477726379310345 ], [ 29.765959746835442, -1.477995862068966 ], [ 29.766229240506327, -1.477995862068966 ], [ 29.766229240506327, -1.477726379310345 ], [ 29.765959746835442, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29603, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.477726379310345 ], [ 29.766229240506327, -1.477995862068966 ], [ 29.766498734177215, -1.477995862068966 ], [ 29.766498734177215, -1.477726379310345 ], [ 29.766229240506327, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29604, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.477726379310345 ], [ 29.766498734177215, -1.477995862068966 ], [ 29.7667682278481, -1.477995862068966 ], [ 29.7667682278481, -1.477726379310345 ], [ 29.766498734177215, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29605, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.477726379310345 ], [ 29.7667682278481, -1.477995862068966 ], [ 29.767037721518985, -1.477995862068966 ], [ 29.767037721518985, -1.477726379310345 ], [ 29.7667682278481, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29606, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.477726379310345 ], [ 29.767037721518985, -1.477995862068966 ], [ 29.767307215189874, -1.477995862068966 ], [ 29.767307215189874, -1.477726379310345 ], [ 29.767037721518985, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29607, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.477726379310345 ], [ 29.767307215189874, -1.477995862068966 ], [ 29.767576708860759, -1.477995862068966 ], [ 29.767576708860759, -1.477726379310345 ], [ 29.767307215189874, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29608, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.477726379310345 ], [ 29.767576708860759, -1.477995862068966 ], [ 29.767846202531643, -1.477995862068966 ], [ 29.767846202531643, -1.477726379310345 ], [ 29.767576708860759, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29609, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.477726379310345 ], [ 29.767846202531643, -1.477995862068966 ], [ 29.768115696202532, -1.477995862068966 ], [ 29.768115696202532, -1.477726379310345 ], [ 29.767846202531643, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29610, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.477726379310345 ], [ 29.768115696202532, -1.477995862068966 ], [ 29.768385189873417, -1.477995862068966 ], [ 29.768385189873417, -1.477726379310345 ], [ 29.768115696202532, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29611, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.477726379310345 ], [ 29.768385189873417, -1.477995862068966 ], [ 29.768654683544302, -1.477995862068966 ], [ 29.768654683544302, -1.477726379310345 ], [ 29.768385189873417, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29612, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.477726379310345 ], [ 29.768654683544302, -1.477995862068966 ], [ 29.76892417721519, -1.477995862068966 ], [ 29.76892417721519, -1.477726379310345 ], [ 29.768654683544302, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29613, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.477726379310345 ], [ 29.76892417721519, -1.477995862068966 ], [ 29.770002151898733, -1.477995862068966 ], [ 29.770002151898733, -1.477726379310345 ], [ 29.76892417721519, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29614, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.477726379310345 ], [ 29.770002151898733, -1.477995862068966 ], [ 29.770271645569618, -1.477995862068966 ], [ 29.770271645569618, -1.477726379310345 ], [ 29.770002151898733, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29615, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.477726379310345 ], [ 29.770271645569618, -1.477995862068966 ], [ 29.770541139240507, -1.477995862068966 ], [ 29.770541139240507, -1.477726379310345 ], [ 29.770271645569618, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29616, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.477726379310345 ], [ 29.770541139240507, -1.477995862068966 ], [ 29.770810632911392, -1.477995862068966 ], [ 29.770810632911392, -1.477726379310345 ], [ 29.770541139240507, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29617, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.477726379310345 ], [ 29.770810632911392, -1.477995862068966 ], [ 29.771080126582277, -1.477995862068966 ], [ 29.771080126582277, -1.477726379310345 ], [ 29.770810632911392, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29618, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.477726379310345 ], [ 29.771080126582277, -1.477995862068966 ], [ 29.771349620253165, -1.477995862068966 ], [ 29.771349620253165, -1.477726379310345 ], [ 29.771080126582277, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29619, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.477726379310345 ], [ 29.771349620253165, -1.477995862068966 ], [ 29.77161911392405, -1.477995862068966 ], [ 29.77161911392405, -1.477726379310345 ], [ 29.771349620253165, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29620, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.477726379310345 ], [ 29.77161911392405, -1.477995862068966 ], [ 29.771888607594935, -1.477995862068966 ], [ 29.771888607594935, -1.477726379310345 ], [ 29.77161911392405, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29621, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.477726379310345 ], [ 29.771888607594935, -1.477995862068966 ], [ 29.772158101265823, -1.477995862068966 ], [ 29.772158101265823, -1.477726379310345 ], [ 29.771888607594935, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29622, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.477726379310345 ], [ 29.772158101265823, -1.477995862068966 ], [ 29.772427594936708, -1.477995862068966 ], [ 29.772427594936708, -1.477726379310345 ], [ 29.772158101265823, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29623, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.477726379310345 ], [ 29.772427594936708, -1.477995862068966 ], [ 29.772697088607593, -1.477995862068966 ], [ 29.772697088607593, -1.477726379310345 ], [ 29.772427594936708, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29624, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.477726379310345 ], [ 29.772697088607593, -1.477995862068966 ], [ 29.772966582278482, -1.477995862068966 ], [ 29.772966582278482, -1.477726379310345 ], [ 29.772697088607593, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29625, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.477726379310345 ], [ 29.772966582278482, -1.477995862068966 ], [ 29.773236075949367, -1.477995862068966 ], [ 29.773236075949367, -1.477726379310345 ], [ 29.772966582278482, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29626, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.477726379310345 ], [ 29.773236075949367, -1.477995862068966 ], [ 29.773505569620252, -1.477995862068966 ], [ 29.773505569620252, -1.477726379310345 ], [ 29.773236075949367, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29627, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.477726379310345 ], [ 29.773505569620252, -1.477995862068966 ], [ 29.77377506329114, -1.477995862068966 ], [ 29.77377506329114, -1.477726379310345 ], [ 29.773505569620252, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29628, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.477726379310345 ], [ 29.77377506329114, -1.477995862068966 ], [ 29.774044556962025, -1.477995862068966 ], [ 29.774044556962025, -1.477726379310345 ], [ 29.77377506329114, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29629, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.477726379310345 ], [ 29.774044556962025, -1.477995862068966 ], [ 29.77431405063291, -1.477995862068966 ], [ 29.77431405063291, -1.477726379310345 ], [ 29.774044556962025, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29630, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.477726379310345 ], [ 29.77431405063291, -1.477995862068966 ], [ 29.774853037974683, -1.477995862068966 ], [ 29.774853037974683, -1.477726379310345 ], [ 29.77431405063291, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29631, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.477726379310345 ], [ 29.774853037974683, -1.477995862068966 ], [ 29.775122531645568, -1.477995862068966 ], [ 29.775122531645568, -1.477726379310345 ], [ 29.774853037974683, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29632, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.477726379310345 ], [ 29.775122531645568, -1.477995862068966 ], [ 29.775392025316457, -1.477995862068966 ], [ 29.775392025316457, -1.477726379310345 ], [ 29.775122531645568, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29633, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.477726379310345 ], [ 29.775392025316457, -1.477995862068966 ], [ 29.775661518987341, -1.477995862068966 ], [ 29.775661518987341, -1.477726379310345 ], [ 29.775392025316457, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29634, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.477726379310345 ], [ 29.775931012658226, -1.477995862068966 ], [ 29.776200506329115, -1.477995862068966 ], [ 29.776200506329115, -1.477726379310345 ], [ 29.775931012658226, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29635, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.477726379310345 ], [ 29.776200506329115, -1.477995862068966 ], [ 29.77647, -1.477995862068966 ], [ 29.77647, -1.477726379310345 ], [ 29.776200506329115, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29636, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.477726379310345 ], [ 29.77647, -1.477995862068966 ], [ 29.776739493670885, -1.477995862068966 ], [ 29.776739493670885, -1.477726379310345 ], [ 29.77647, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29637, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.477726379310345 ], [ 29.776739493670885, -1.478265344827586 ], [ 29.777008987341773, -1.478265344827586 ], [ 29.777008987341773, -1.477726379310345 ], [ 29.776739493670885, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29638, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.477726379310345 ], [ 29.777008987341773, -1.477995862068966 ], [ 29.777278481012658, -1.477995862068966 ], [ 29.777278481012658, -1.477726379310345 ], [ 29.777008987341773, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29639, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.477726379310345 ], [ 29.777278481012658, -1.477995862068966 ], [ 29.777547974683543, -1.477995862068966 ], [ 29.777547974683543, -1.477726379310345 ], [ 29.777278481012658, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29640, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.477726379310345 ], [ 29.777817468354431, -1.477995862068966 ], [ 29.778086962025316, -1.477995862068966 ], [ 29.778086962025316, -1.477726379310345 ], [ 29.777817468354431, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29641, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.477726379310345 ], [ 29.778086962025316, -1.477995862068966 ], [ 29.778356455696201, -1.477995862068966 ], [ 29.778356455696201, -1.477726379310345 ], [ 29.778086962025316, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29642, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.477726379310345 ], [ 29.77862594936709, -1.477995862068966 ], [ 29.778895443037975, -1.477995862068966 ], [ 29.778895443037975, -1.477726379310345 ], [ 29.77862594936709, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29643, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.477726379310345 ], [ 29.778895443037975, -1.477995862068966 ], [ 29.77916493670886, -1.477995862068966 ], [ 29.77916493670886, -1.477726379310345 ], [ 29.778895443037975, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29644, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.477726379310345 ], [ 29.77916493670886, -1.477995862068966 ], [ 29.779434430379748, -1.477995862068966 ], [ 29.779434430379748, -1.477726379310345 ], [ 29.77916493670886, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29645, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.477726379310345 ], [ 29.779434430379748, -1.477995862068966 ], [ 29.779703924050633, -1.477995862068966 ], [ 29.779703924050633, -1.477726379310345 ], [ 29.779434430379748, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29646, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.477726379310345 ], [ 29.779703924050633, -1.477995862068966 ], [ 29.779973417721518, -1.477995862068966 ], [ 29.779973417721518, -1.477726379310345 ], [ 29.779703924050633, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29647, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.477726379310345 ], [ 29.779973417721518, -1.477995862068966 ], [ 29.780242911392406, -1.477995862068966 ], [ 29.780242911392406, -1.477726379310345 ], [ 29.779973417721518, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29648, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.477726379310345 ], [ 29.780242911392406, -1.477995862068966 ], [ 29.780512405063291, -1.477995862068966 ], [ 29.780512405063291, -1.477726379310345 ], [ 29.780242911392406, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29649, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.477726379310345 ], [ 29.780512405063291, -1.477995862068966 ], [ 29.780781898734176, -1.477995862068966 ], [ 29.780781898734176, -1.477726379310345 ], [ 29.780512405063291, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29650, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.477726379310345 ], [ 29.780781898734176, -1.477995862068966 ], [ 29.781051392405065, -1.477995862068966 ], [ 29.781051392405065, -1.477726379310345 ], [ 29.780781898734176, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29651, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.477726379310345 ], [ 29.781051392405065, -1.477995862068966 ], [ 29.78132088607595, -1.477995862068966 ], [ 29.78132088607595, -1.477726379310345 ], [ 29.781051392405065, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29652, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.477726379310345 ], [ 29.78132088607595, -1.477995862068966 ], [ 29.781590379746834, -1.477995862068966 ], [ 29.781590379746834, -1.477726379310345 ], [ 29.78132088607595, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29653, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.477726379310345 ], [ 29.781590379746834, -1.477995862068966 ], [ 29.781859873417723, -1.477995862068966 ], [ 29.781859873417723, -1.477726379310345 ], [ 29.781590379746834, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29654, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.477726379310345 ], [ 29.781859873417723, -1.477995862068966 ], [ 29.782129367088608, -1.477995862068966 ], [ 29.782129367088608, -1.477726379310345 ], [ 29.781859873417723, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29655, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.477726379310345 ], [ 29.782129367088608, -1.477995862068966 ], [ 29.782398860759493, -1.477995862068966 ], [ 29.782398860759493, -1.477726379310345 ], [ 29.782129367088608, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29656, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.477726379310345 ], [ 29.782398860759493, -1.477995862068966 ], [ 29.782668354430381, -1.477995862068966 ], [ 29.782668354430381, -1.477726379310345 ], [ 29.782398860759493, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29657, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.477726379310345 ], [ 29.782668354430381, -1.477995862068966 ], [ 29.782937848101266, -1.477995862068966 ], [ 29.782937848101266, -1.477726379310345 ], [ 29.782668354430381, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29658, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.477726379310345 ], [ 29.782937848101266, -1.477995862068966 ], [ 29.783207341772151, -1.477995862068966 ], [ 29.783207341772151, -1.477726379310345 ], [ 29.782937848101266, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29659, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.477726379310345 ], [ 29.783207341772151, -1.477995862068966 ], [ 29.783476835443039, -1.477995862068966 ], [ 29.783476835443039, -1.477726379310345 ], [ 29.783207341772151, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29660, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.477726379310345 ], [ 29.783476835443039, -1.477995862068966 ], [ 29.784015822784809, -1.477995862068966 ], [ 29.784015822784809, -1.477726379310345 ], [ 29.783476835443039, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29661, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.477726379310345 ], [ 29.784015822784809, -1.477995862068966 ], [ 29.784285316455698, -1.477995862068966 ], [ 29.784285316455698, -1.477726379310345 ], [ 29.784015822784809, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29662, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.477726379310345 ], [ 29.784285316455698, -1.477995862068966 ], [ 29.784554810126583, -1.477995862068966 ], [ 29.784554810126583, -1.477726379310345 ], [ 29.784285316455698, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29663, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.477726379310345 ], [ 29.784554810126583, -1.477995862068966 ], [ 29.784824303797468, -1.477995862068966 ], [ 29.784824303797468, -1.477726379310345 ], [ 29.784554810126583, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29664, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.477726379310345 ], [ 29.784824303797468, -1.477995862068966 ], [ 29.785093797468356, -1.477995862068966 ], [ 29.785093797468356, -1.477726379310345 ], [ 29.784824303797468, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29665, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.477726379310345 ], [ 29.785093797468356, -1.477995862068966 ], [ 29.785363291139241, -1.477995862068966 ], [ 29.785363291139241, -1.477726379310345 ], [ 29.785093797468356, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29666, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.477726379310345 ], [ 29.785363291139241, -1.477995862068966 ], [ 29.785632784810126, -1.477995862068966 ], [ 29.785632784810126, -1.477726379310345 ], [ 29.785363291139241, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29667, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.477726379310345 ], [ 29.785632784810126, -1.477995862068966 ], [ 29.785902278481014, -1.477995862068966 ], [ 29.785902278481014, -1.477726379310345 ], [ 29.785632784810126, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29668, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.477726379310345 ], [ 29.785902278481014, -1.477995862068966 ], [ 29.787249746835442, -1.477995862068966 ], [ 29.787249746835442, -1.477726379310345 ], [ 29.785902278481014, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29669, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.477726379310345 ], [ 29.787249746835442, -1.477995862068966 ], [ 29.787519240506327, -1.477995862068966 ], [ 29.787519240506327, -1.477726379310345 ], [ 29.787249746835442, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29670, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.477726379310345 ], [ 29.787519240506327, -1.477995862068966 ], [ 29.787788734177216, -1.477995862068966 ], [ 29.787788734177216, -1.477726379310345 ], [ 29.787519240506327, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29671, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.477726379310345 ], [ 29.787788734177216, -1.477995862068966 ], [ 29.788058227848101, -1.477995862068966 ], [ 29.788058227848101, -1.477726379310345 ], [ 29.787788734177216, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29672, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.477726379310345 ], [ 29.788058227848101, -1.477995862068966 ], [ 29.788327721518986, -1.477995862068966 ], [ 29.788327721518986, -1.477726379310345 ], [ 29.788058227848101, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29673, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.477726379310345 ], [ 29.788327721518986, -1.477995862068966 ], [ 29.788597215189874, -1.477995862068966 ], [ 29.788597215189874, -1.477726379310345 ], [ 29.788327721518986, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29674, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.477726379310345 ], [ 29.788597215189874, -1.477995862068966 ], [ 29.788866708860759, -1.477995862068966 ], [ 29.788866708860759, -1.477726379310345 ], [ 29.788597215189874, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29675, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.477726379310345 ], [ 29.79776, -1.477995862068966 ], [ 29.798029493670885, -1.477995862068966 ], [ 29.798029493670885, -1.477726379310345 ], [ 29.79776, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29676, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.477726379310345 ], [ 29.798029493670885, -1.477995862068966 ], [ 29.798298987341774, -1.477995862068966 ], [ 29.798298987341774, -1.477726379310345 ], [ 29.798029493670885, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29677, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.477726379310345 ], [ 29.798298987341774, -1.477995862068966 ], [ 29.798568481012659, -1.477995862068966 ], [ 29.798568481012659, -1.477726379310345 ], [ 29.798298987341774, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29678, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760300379746834, -1.477995862068966 ], [ 29.760300379746834, -1.478265344827586 ], [ 29.760569873417722, -1.478265344827586 ], [ 29.760569873417722, -1.477995862068966 ], [ 29.760300379746834, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29679, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.477995862068966 ], [ 29.760569873417722, -1.478265344827586 ], [ 29.760839367088607, -1.478265344827586 ], [ 29.760839367088607, -1.477995862068966 ], [ 29.760569873417722, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29680, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.477995862068966 ], [ 29.760839367088607, -1.478265344827586 ], [ 29.761108860759492, -1.478265344827586 ], [ 29.761108860759492, -1.477995862068966 ], [ 29.760839367088607, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29681, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.477995862068966 ], [ 29.761108860759492, -1.478265344827586 ], [ 29.761378354430381, -1.478265344827586 ], [ 29.761378354430381, -1.477995862068966 ], [ 29.761108860759492, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29682, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.477995862068966 ], [ 29.761378354430381, -1.478265344827586 ], [ 29.761647848101266, -1.478265344827586 ], [ 29.761647848101266, -1.477995862068966 ], [ 29.761378354430381, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29683, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.477995862068966 ], [ 29.761647848101266, -1.478265344827586 ], [ 29.761917341772151, -1.478265344827586 ], [ 29.761917341772151, -1.477995862068966 ], [ 29.761647848101266, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29684, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.477995862068966 ], [ 29.761917341772151, -1.478265344827586 ], [ 29.762186835443039, -1.478265344827586 ], [ 29.762186835443039, -1.477995862068966 ], [ 29.761917341772151, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29685, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.477995862068966 ], [ 29.762186835443039, -1.478265344827586 ], [ 29.762456329113924, -1.478265344827586 ], [ 29.762456329113924, -1.477995862068966 ], [ 29.762186835443039, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29686, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.477995862068966 ], [ 29.762456329113924, -1.478265344827586 ], [ 29.762725822784809, -1.478265344827586 ], [ 29.762725822784809, -1.477995862068966 ], [ 29.762456329113924, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29687, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.477995862068966 ], [ 29.762725822784809, -1.478265344827586 ], [ 29.762995316455697, -1.478265344827586 ], [ 29.762995316455697, -1.477995862068966 ], [ 29.762725822784809, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29688, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.477995862068966 ], [ 29.762995316455697, -1.478265344827586 ], [ 29.763264810126582, -1.478265344827586 ], [ 29.763264810126582, -1.477995862068966 ], [ 29.762995316455697, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29689, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.477995862068966 ], [ 29.763264810126582, -1.478265344827586 ], [ 29.763534303797467, -1.478265344827586 ], [ 29.763534303797467, -1.477995862068966 ], [ 29.763264810126582, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29690, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.477995862068966 ], [ 29.763534303797467, -1.478265344827586 ], [ 29.763803797468356, -1.478265344827586 ], [ 29.763803797468356, -1.477995862068966 ], [ 29.763534303797467, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29691, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.477995862068966 ], [ 29.763803797468356, -1.478265344827586 ], [ 29.76407329113924, -1.478265344827586 ], [ 29.76407329113924, -1.477995862068966 ], [ 29.763803797468356, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29692, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.477995862068966 ], [ 29.76407329113924, -1.478265344827586 ], [ 29.764342784810125, -1.478265344827586 ], [ 29.764342784810125, -1.477995862068966 ], [ 29.76407329113924, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29693, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.477995862068966 ], [ 29.764342784810125, -1.478265344827586 ], [ 29.764612278481014, -1.478265344827586 ], [ 29.764612278481014, -1.477995862068966 ], [ 29.764342784810125, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29694, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.477995862068966 ], [ 29.764612278481014, -1.478265344827586 ], [ 29.764881772151899, -1.478265344827586 ], [ 29.764881772151899, -1.477995862068966 ], [ 29.764612278481014, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29695, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.477995862068966 ], [ 29.764881772151899, -1.478265344827586 ], [ 29.765151265822784, -1.478265344827586 ], [ 29.765151265822784, -1.477995862068966 ], [ 29.764881772151899, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29696, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.477995862068966 ], [ 29.765151265822784, -1.478265344827586 ], [ 29.765420759493672, -1.478265344827586 ], [ 29.765420759493672, -1.477995862068966 ], [ 29.765151265822784, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29697, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.477995862068966 ], [ 29.765420759493672, -1.478265344827586 ], [ 29.765959746835442, -1.478265344827586 ], [ 29.765959746835442, -1.477995862068966 ], [ 29.765420759493672, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29698, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.477995862068966 ], [ 29.765959746835442, -1.478265344827586 ], [ 29.766229240506327, -1.478265344827586 ], [ 29.766229240506327, -1.477995862068966 ], [ 29.765959746835442, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29699, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.477995862068966 ], [ 29.766229240506327, -1.478265344827586 ], [ 29.766498734177215, -1.478265344827586 ], [ 29.766498734177215, -1.477995862068966 ], [ 29.766229240506327, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29700, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.477995862068966 ], [ 29.766498734177215, -1.478265344827586 ], [ 29.7667682278481, -1.478265344827586 ], [ 29.7667682278481, -1.477995862068966 ], [ 29.766498734177215, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29701, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.477995862068966 ], [ 29.7667682278481, -1.478265344827586 ], [ 29.767037721518985, -1.478265344827586 ], [ 29.767037721518985, -1.477995862068966 ], [ 29.7667682278481, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29702, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.477995862068966 ], [ 29.767037721518985, -1.478265344827586 ], [ 29.767307215189874, -1.478265344827586 ], [ 29.767307215189874, -1.477995862068966 ], [ 29.767037721518985, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29703, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.477995862068966 ], [ 29.767307215189874, -1.478265344827586 ], [ 29.767576708860759, -1.478265344827586 ], [ 29.767576708860759, -1.477995862068966 ], [ 29.767307215189874, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29704, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.477995862068966 ], [ 29.767576708860759, -1.478265344827586 ], [ 29.767846202531643, -1.478265344827586 ], [ 29.767846202531643, -1.477995862068966 ], [ 29.767576708860759, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29705, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.477995862068966 ], [ 29.767846202531643, -1.478265344827586 ], [ 29.768115696202532, -1.478265344827586 ], [ 29.768115696202532, -1.477995862068966 ], [ 29.767846202531643, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29706, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.477995862068966 ], [ 29.768115696202532, -1.478265344827586 ], [ 29.768385189873417, -1.478265344827586 ], [ 29.768385189873417, -1.477995862068966 ], [ 29.768115696202532, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29707, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.477995862068966 ], [ 29.768385189873417, -1.478265344827586 ], [ 29.768654683544302, -1.478265344827586 ], [ 29.768654683544302, -1.477995862068966 ], [ 29.768385189873417, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29708, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.477995862068966 ], [ 29.768654683544302, -1.478265344827586 ], [ 29.76892417721519, -1.478265344827586 ], [ 29.76892417721519, -1.477995862068966 ], [ 29.768654683544302, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29709, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.477995862068966 ], [ 29.76892417721519, -1.478265344827586 ], [ 29.770002151898733, -1.478265344827586 ], [ 29.770002151898733, -1.477995862068966 ], [ 29.76892417721519, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29710, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.477995862068966 ], [ 29.770002151898733, -1.478265344827586 ], [ 29.770271645569618, -1.478265344827586 ], [ 29.770271645569618, -1.477995862068966 ], [ 29.770002151898733, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29711, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.477995862068966 ], [ 29.770271645569618, -1.478265344827586 ], [ 29.770541139240507, -1.478265344827586 ], [ 29.770541139240507, -1.477995862068966 ], [ 29.770271645569618, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29712, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.477995862068966 ], [ 29.770541139240507, -1.478265344827586 ], [ 29.770810632911392, -1.478265344827586 ], [ 29.770810632911392, -1.477995862068966 ], [ 29.770541139240507, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29713, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.477995862068966 ], [ 29.770810632911392, -1.478265344827586 ], [ 29.771080126582277, -1.478265344827586 ], [ 29.771080126582277, -1.477995862068966 ], [ 29.770810632911392, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29714, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.477995862068966 ], [ 29.771080126582277, -1.478265344827586 ], [ 29.771349620253165, -1.478265344827586 ], [ 29.771349620253165, -1.477995862068966 ], [ 29.771080126582277, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29715, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.477995862068966 ], [ 29.771349620253165, -1.478265344827586 ], [ 29.77161911392405, -1.478265344827586 ], [ 29.77161911392405, -1.477995862068966 ], [ 29.771349620253165, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29716, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.477995862068966 ], [ 29.77161911392405, -1.478265344827586 ], [ 29.771888607594935, -1.478265344827586 ], [ 29.771888607594935, -1.477995862068966 ], [ 29.77161911392405, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29717, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.477995862068966 ], [ 29.771888607594935, -1.478265344827586 ], [ 29.772158101265823, -1.478265344827586 ], [ 29.772158101265823, -1.477995862068966 ], [ 29.771888607594935, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29718, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.477995862068966 ], [ 29.772158101265823, -1.478265344827586 ], [ 29.772427594936708, -1.478265344827586 ], [ 29.772427594936708, -1.477995862068966 ], [ 29.772158101265823, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29719, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.477995862068966 ], [ 29.772427594936708, -1.478265344827586 ], [ 29.772697088607593, -1.478265344827586 ], [ 29.772697088607593, -1.477995862068966 ], [ 29.772427594936708, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29720, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.477995862068966 ], [ 29.772697088607593, -1.478265344827586 ], [ 29.772966582278482, -1.478265344827586 ], [ 29.772966582278482, -1.477995862068966 ], [ 29.772697088607593, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29721, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.477995862068966 ], [ 29.772966582278482, -1.478265344827586 ], [ 29.773236075949367, -1.478265344827586 ], [ 29.773236075949367, -1.477995862068966 ], [ 29.772966582278482, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29722, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.477995862068966 ], [ 29.773236075949367, -1.478265344827586 ], [ 29.773505569620252, -1.478265344827586 ], [ 29.773505569620252, -1.477995862068966 ], [ 29.773236075949367, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29723, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.477995862068966 ], [ 29.773505569620252, -1.478265344827586 ], [ 29.77377506329114, -1.478265344827586 ], [ 29.77377506329114, -1.477995862068966 ], [ 29.773505569620252, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29724, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.477995862068966 ], [ 29.77377506329114, -1.478265344827586 ], [ 29.774044556962025, -1.478265344827586 ], [ 29.774044556962025, -1.477995862068966 ], [ 29.77377506329114, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29725, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.477995862068966 ], [ 29.774044556962025, -1.478265344827586 ], [ 29.77431405063291, -1.478265344827586 ], [ 29.77431405063291, -1.477995862068966 ], [ 29.774044556962025, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29726, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.477995862068966 ], [ 29.77431405063291, -1.478265344827586 ], [ 29.774853037974683, -1.478265344827586 ], [ 29.774853037974683, -1.477995862068966 ], [ 29.77431405063291, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29727, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.477995862068966 ], [ 29.774853037974683, -1.478265344827586 ], [ 29.775122531645568, -1.478265344827586 ], [ 29.775122531645568, -1.477995862068966 ], [ 29.774853037974683, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29728, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.477995862068966 ], [ 29.775122531645568, -1.478265344827586 ], [ 29.775392025316457, -1.478265344827586 ], [ 29.775392025316457, -1.477995862068966 ], [ 29.775122531645568, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29729, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.477995862068966 ], [ 29.775392025316457, -1.478265344827586 ], [ 29.775661518987341, -1.478265344827586 ], [ 29.775661518987341, -1.477995862068966 ], [ 29.775392025316457, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29730, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.477995862068966 ], [ 29.775661518987341, -1.478265344827586 ], [ 29.775931012658226, -1.478265344827586 ], [ 29.775931012658226, -1.477995862068966 ], [ 29.775661518987341, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29731, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.477995862068966 ], [ 29.775931012658226, -1.478265344827586 ], [ 29.776200506329115, -1.478265344827586 ], [ 29.776200506329115, -1.477995862068966 ], [ 29.775931012658226, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29732, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.477995862068966 ], [ 29.776200506329115, -1.478265344827586 ], [ 29.77647, -1.478265344827586 ], [ 29.77647, -1.477995862068966 ], [ 29.776200506329115, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29733, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.477995862068966 ], [ 29.77647, -1.478265344827586 ], [ 29.776739493670885, -1.478265344827586 ], [ 29.776739493670885, -1.477995862068966 ], [ 29.77647, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29734, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.477995862068966 ], [ 29.777008987341773, -1.478265344827586 ], [ 29.777278481012658, -1.478265344827586 ], [ 29.777278481012658, -1.477995862068966 ], [ 29.777008987341773, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29735, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.477995862068966 ], [ 29.777278481012658, -1.478265344827586 ], [ 29.777547974683543, -1.478265344827586 ], [ 29.777547974683543, -1.477995862068966 ], [ 29.777278481012658, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29736, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.477995862068966 ], [ 29.777547974683543, -1.478265344827586 ], [ 29.777817468354431, -1.478265344827586 ], [ 29.777817468354431, -1.477995862068966 ], [ 29.777547974683543, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29737, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.477995862068966 ], [ 29.778086962025316, -1.478265344827586 ], [ 29.778356455696201, -1.478265344827586 ], [ 29.778356455696201, -1.477995862068966 ], [ 29.778086962025316, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29738, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.477995862068966 ], [ 29.778356455696201, -1.478265344827586 ], [ 29.77862594936709, -1.478265344827586 ], [ 29.77862594936709, -1.477995862068966 ], [ 29.778356455696201, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29739, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.477995862068966 ], [ 29.77862594936709, -1.478265344827586 ], [ 29.778895443037975, -1.478265344827586 ], [ 29.778895443037975, -1.477995862068966 ], [ 29.77862594936709, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29740, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.477995862068966 ], [ 29.778895443037975, -1.478265344827586 ], [ 29.77916493670886, -1.478265344827586 ], [ 29.77916493670886, -1.477995862068966 ], [ 29.778895443037975, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29741, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.477995862068966 ], [ 29.77916493670886, -1.478265344827586 ], [ 29.779434430379748, -1.478265344827586 ], [ 29.779434430379748, -1.477995862068966 ], [ 29.77916493670886, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29742, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.477995862068966 ], [ 29.779434430379748, -1.478265344827586 ], [ 29.779703924050633, -1.478265344827586 ], [ 29.779703924050633, -1.477995862068966 ], [ 29.779434430379748, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29743, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.477995862068966 ], [ 29.779703924050633, -1.478265344827586 ], [ 29.779973417721518, -1.478265344827586 ], [ 29.779973417721518, -1.477995862068966 ], [ 29.779703924050633, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29744, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.477995862068966 ], [ 29.779973417721518, -1.478265344827586 ], [ 29.780242911392406, -1.478265344827586 ], [ 29.780242911392406, -1.477995862068966 ], [ 29.779973417721518, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29745, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.477995862068966 ], [ 29.780242911392406, -1.478265344827586 ], [ 29.780512405063291, -1.478265344827586 ], [ 29.780512405063291, -1.477995862068966 ], [ 29.780242911392406, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29746, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.477995862068966 ], [ 29.780512405063291, -1.478265344827586 ], [ 29.780781898734176, -1.478265344827586 ], [ 29.780781898734176, -1.477995862068966 ], [ 29.780512405063291, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29747, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.477995862068966 ], [ 29.780781898734176, -1.478265344827586 ], [ 29.781051392405065, -1.478265344827586 ], [ 29.781051392405065, -1.477995862068966 ], [ 29.780781898734176, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29748, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.477995862068966 ], [ 29.781051392405065, -1.478265344827586 ], [ 29.78132088607595, -1.478265344827586 ], [ 29.78132088607595, -1.477995862068966 ], [ 29.781051392405065, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29749, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.477995862068966 ], [ 29.78132088607595, -1.478265344827586 ], [ 29.781590379746834, -1.478265344827586 ], [ 29.781590379746834, -1.477995862068966 ], [ 29.78132088607595, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29750, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.477995862068966 ], [ 29.781590379746834, -1.478265344827586 ], [ 29.781859873417723, -1.478265344827586 ], [ 29.781859873417723, -1.477995862068966 ], [ 29.781590379746834, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29751, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.477995862068966 ], [ 29.781859873417723, -1.478265344827586 ], [ 29.782129367088608, -1.478265344827586 ], [ 29.782129367088608, -1.477995862068966 ], [ 29.781859873417723, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29752, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.477995862068966 ], [ 29.782129367088608, -1.478265344827586 ], [ 29.782398860759493, -1.478265344827586 ], [ 29.782398860759493, -1.477995862068966 ], [ 29.782129367088608, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29753, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.477995862068966 ], [ 29.782398860759493, -1.478265344827586 ], [ 29.782668354430381, -1.478265344827586 ], [ 29.782668354430381, -1.477995862068966 ], [ 29.782398860759493, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29754, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.477995862068966 ], [ 29.782668354430381, -1.478265344827586 ], [ 29.782937848101266, -1.478265344827586 ], [ 29.782937848101266, -1.477995862068966 ], [ 29.782668354430381, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29755, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.477995862068966 ], [ 29.782937848101266, -1.478265344827586 ], [ 29.783207341772151, -1.478265344827586 ], [ 29.783207341772151, -1.477995862068966 ], [ 29.782937848101266, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29756, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.477995862068966 ], [ 29.783207341772151, -1.478265344827586 ], [ 29.783476835443039, -1.478265344827586 ], [ 29.783476835443039, -1.477995862068966 ], [ 29.783207341772151, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29757, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.477995862068966 ], [ 29.783476835443039, -1.478265344827586 ], [ 29.784015822784809, -1.478265344827586 ], [ 29.784015822784809, -1.477995862068966 ], [ 29.783476835443039, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29758, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.477995862068966 ], [ 29.784015822784809, -1.478265344827586 ], [ 29.784285316455698, -1.478265344827586 ], [ 29.784285316455698, -1.477995862068966 ], [ 29.784015822784809, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29759, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.477995862068966 ], [ 29.784285316455698, -1.478265344827586 ], [ 29.784554810126583, -1.478265344827586 ], [ 29.784554810126583, -1.477995862068966 ], [ 29.784285316455698, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29760, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.477995862068966 ], [ 29.784554810126583, -1.478265344827586 ], [ 29.784824303797468, -1.478265344827586 ], [ 29.784824303797468, -1.477995862068966 ], [ 29.784554810126583, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29761, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.477995862068966 ], [ 29.784824303797468, -1.478265344827586 ], [ 29.785093797468356, -1.478265344827586 ], [ 29.785093797468356, -1.477995862068966 ], [ 29.784824303797468, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29762, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.477995862068966 ], [ 29.785093797468356, -1.478265344827586 ], [ 29.785363291139241, -1.478265344827586 ], [ 29.785363291139241, -1.477995862068966 ], [ 29.785093797468356, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29763, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.477995862068966 ], [ 29.785363291139241, -1.478265344827586 ], [ 29.785632784810126, -1.478265344827586 ], [ 29.785632784810126, -1.477995862068966 ], [ 29.785363291139241, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29764, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.477995862068966 ], [ 29.785632784810126, -1.478265344827586 ], [ 29.785902278481014, -1.478265344827586 ], [ 29.785902278481014, -1.477995862068966 ], [ 29.785632784810126, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29765, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.477995862068966 ], [ 29.785902278481014, -1.478265344827586 ], [ 29.787249746835442, -1.478265344827586 ], [ 29.787249746835442, -1.477995862068966 ], [ 29.785902278481014, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29766, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.477995862068966 ], [ 29.787249746835442, -1.478265344827586 ], [ 29.787519240506327, -1.478265344827586 ], [ 29.787519240506327, -1.477995862068966 ], [ 29.787249746835442, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29767, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.477995862068966 ], [ 29.787519240506327, -1.478265344827586 ], [ 29.787788734177216, -1.478265344827586 ], [ 29.787788734177216, -1.477995862068966 ], [ 29.787519240506327, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29768, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.477995862068966 ], [ 29.787788734177216, -1.478265344827586 ], [ 29.788058227848101, -1.478265344827586 ], [ 29.788058227848101, -1.477995862068966 ], [ 29.787788734177216, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29769, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.477995862068966 ], [ 29.788058227848101, -1.478265344827586 ], [ 29.788327721518986, -1.478265344827586 ], [ 29.788327721518986, -1.477995862068966 ], [ 29.788058227848101, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29770, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.477995862068966 ], [ 29.788327721518986, -1.478265344827586 ], [ 29.788597215189874, -1.478265344827586 ], [ 29.788597215189874, -1.477995862068966 ], [ 29.788327721518986, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29771, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.477995862068966 ], [ 29.788597215189874, -1.478265344827586 ], [ 29.788866708860759, -1.478265344827586 ], [ 29.788866708860759, -1.477995862068966 ], [ 29.788597215189874, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29772, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.477995862068966 ], [ 29.788866708860759, -1.478265344827586 ], [ 29.789136202531644, -1.478265344827586 ], [ 29.789136202531644, -1.477995862068966 ], [ 29.788866708860759, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29773, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.477995862068966 ], [ 29.789136202531644, -1.478265344827586 ], [ 29.789405696202532, -1.478265344827586 ], [ 29.789405696202532, -1.477995862068966 ], [ 29.789136202531644, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29774, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.477995862068966 ], [ 29.789405696202532, -1.478265344827586 ], [ 29.789675189873417, -1.478265344827586 ], [ 29.789675189873417, -1.477995862068966 ], [ 29.789405696202532, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29775, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.477995862068966 ], [ 29.800724430379748, -1.478265344827586 ], [ 29.800993924050633, -1.478265344827586 ], [ 29.800993924050633, -1.477995862068966 ], [ 29.800724430379748, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29776, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.477995862068966 ], [ 29.801263417721518, -1.478265344827586 ], [ 29.801532911392407, -1.478265344827586 ], [ 29.801532911392407, -1.477995862068966 ], [ 29.801263417721518, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29777, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.477995862068966 ], [ 29.801532911392407, -1.478265344827586 ], [ 29.802071898734177, -1.478265344827586 ], [ 29.802071898734177, -1.477995862068966 ], [ 29.801532911392407, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29778, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.476917931034483 ], [ 29.802880379746835, -1.478534827586207 ], [ 29.803149873417723, -1.478534827586207 ], [ 29.803149873417723, -1.476917931034483 ], [ 29.802880379746835, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29779, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.476917931034483 ], [ 29.803149873417723, -1.478534827586207 ], [ 29.803419367088608, -1.478534827586207 ], [ 29.803419367088608, -1.476917931034483 ], [ 29.803149873417723, -1.476917931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29780, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.477187413793104 ], [ 29.790214177215191, -1.478534827586207 ], [ 29.790483670886076, -1.478534827586207 ], [ 29.790483670886076, -1.477187413793104 ], [ 29.790214177215191, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29781, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.477187413793104 ], [ 29.790483670886076, -1.478804310344828 ], [ 29.790753164556961, -1.478804310344828 ], [ 29.790753164556961, -1.477187413793104 ], [ 29.790483670886076, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29782, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.477187413793104 ], [ 29.80261088607595, -1.478534827586207 ], [ 29.802880379746835, -1.478534827586207 ], [ 29.802880379746835, -1.477187413793104 ], [ 29.80261088607595, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29783, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.477187413793104 ], [ 29.803419367088608, -1.478804310344828 ], [ 29.803688860759493, -1.478804310344828 ], [ 29.803688860759493, -1.477187413793104 ], [ 29.803419367088608, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29784, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.477187413793104 ], [ 29.803688860759493, -1.478804310344828 ], [ 29.803958354430382, -1.478804310344828 ], [ 29.803958354430382, -1.477187413793104 ], [ 29.803688860759493, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29785, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.477187413793104 ], [ 29.803958354430382, -1.479073793103448 ], [ 29.804227848101267, -1.479073793103448 ], [ 29.804227848101267, -1.477187413793104 ], [ 29.803958354430382, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29786, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.477456896551724 ], [ 29.79991594936709, -1.47961275862069 ], [ 29.800185443037975, -1.47961275862069 ], [ 29.800185443037975, -1.477456896551724 ], [ 29.79991594936709, -1.477456896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29787, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.477726379310345 ], [ 29.798568481012659, -1.480151724137931 ], [ 29.798837974683543, -1.480151724137931 ], [ 29.798837974683543, -1.477726379310345 ], [ 29.798568481012659, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29788, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.477726379310345 ], [ 29.798837974683543, -1.480151724137931 ], [ 29.799107468354432, -1.480151724137931 ], [ 29.799107468354432, -1.477726379310345 ], [ 29.798837974683543, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29789, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.477726379310345 ], [ 29.799376962025317, -1.47988224137931 ], [ 29.799646455696202, -1.47988224137931 ], [ 29.799646455696202, -1.477726379310345 ], [ 29.799376962025317, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29790, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.477726379310345 ], [ 29.799646455696202, -1.47988224137931 ], [ 29.79991594936709, -1.47988224137931 ], [ 29.79991594936709, -1.477726379310345 ], [ 29.799646455696202, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29791, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.477726379310345 ], [ 29.800185443037975, -1.47961275862069 ], [ 29.80045493670886, -1.47961275862069 ], [ 29.80045493670886, -1.477726379310345 ], [ 29.800185443037975, -1.477726379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29792, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.477995862068966 ], [ 29.777817468354431, -1.478534827586207 ], [ 29.778086962025316, -1.478534827586207 ], [ 29.778086962025316, -1.477995862068966 ], [ 29.777817468354431, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29793, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.477995862068966 ], [ 29.798029493670885, -1.480421206896552 ], [ 29.798298987341774, -1.480421206896552 ], [ 29.798298987341774, -1.477995862068966 ], [ 29.798029493670885, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29794, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.477995862068966 ], [ 29.798298987341774, -1.480421206896552 ], [ 29.798568481012659, -1.480421206896552 ], [ 29.798568481012659, -1.477995862068966 ], [ 29.798298987341774, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29795, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.477995862068966 ], [ 29.799107468354432, -1.47988224137931 ], [ 29.799376962025317, -1.47988224137931 ], [ 29.799376962025317, -1.477995862068966 ], [ 29.799107468354432, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29796, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.477995862068966 ], [ 29.80045493670886, -1.479343275862069 ], [ 29.800724430379748, -1.479343275862069 ], [ 29.800724430379748, -1.47988224137931 ], [ 29.800993924050633, -1.47988224137931 ], [ 29.800993924050633, -1.479073793103448 ], [ 29.800724430379748, -1.479073793103448 ], [ 29.800724430379748, -1.477995862068966 ], [ 29.80045493670886, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29797, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760569873417722, -1.478265344827586 ], [ 29.760569873417722, -1.478534827586207 ], [ 29.760839367088607, -1.478534827586207 ], [ 29.760839367088607, -1.478265344827586 ], [ 29.760569873417722, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29798, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.478265344827586 ], [ 29.760839367088607, -1.478534827586207 ], [ 29.761108860759492, -1.478534827586207 ], [ 29.761108860759492, -1.478265344827586 ], [ 29.760839367088607, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29799, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.478265344827586 ], [ 29.761108860759492, -1.478534827586207 ], [ 29.761378354430381, -1.478534827586207 ], [ 29.761378354430381, -1.478265344827586 ], [ 29.761108860759492, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29800, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.478265344827586 ], [ 29.761378354430381, -1.478534827586207 ], [ 29.761647848101266, -1.478534827586207 ], [ 29.761647848101266, -1.478265344827586 ], [ 29.761378354430381, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29801, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.478265344827586 ], [ 29.761647848101266, -1.478534827586207 ], [ 29.761917341772151, -1.478534827586207 ], [ 29.761917341772151, -1.478265344827586 ], [ 29.761647848101266, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29802, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.478265344827586 ], [ 29.761917341772151, -1.478534827586207 ], [ 29.762186835443039, -1.478534827586207 ], [ 29.762186835443039, -1.478265344827586 ], [ 29.761917341772151, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29803, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.478265344827586 ], [ 29.762186835443039, -1.478534827586207 ], [ 29.762456329113924, -1.478534827586207 ], [ 29.762456329113924, -1.478265344827586 ], [ 29.762186835443039, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29804, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.478265344827586 ], [ 29.762456329113924, -1.478534827586207 ], [ 29.762725822784809, -1.478534827586207 ], [ 29.762725822784809, -1.478265344827586 ], [ 29.762456329113924, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29805, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.478265344827586 ], [ 29.762725822784809, -1.478534827586207 ], [ 29.762995316455697, -1.478534827586207 ], [ 29.762995316455697, -1.478265344827586 ], [ 29.762725822784809, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29806, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.478265344827586 ], [ 29.762995316455697, -1.478534827586207 ], [ 29.763264810126582, -1.478534827586207 ], [ 29.763264810126582, -1.478265344827586 ], [ 29.762995316455697, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29807, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.478265344827586 ], [ 29.763264810126582, -1.478534827586207 ], [ 29.763534303797467, -1.478534827586207 ], [ 29.763534303797467, -1.478265344827586 ], [ 29.763264810126582, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29808, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.478265344827586 ], [ 29.763534303797467, -1.478534827586207 ], [ 29.763803797468356, -1.478534827586207 ], [ 29.763803797468356, -1.478265344827586 ], [ 29.763534303797467, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29809, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.478265344827586 ], [ 29.763803797468356, -1.478534827586207 ], [ 29.76407329113924, -1.478534827586207 ], [ 29.76407329113924, -1.478265344827586 ], [ 29.763803797468356, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29810, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.478265344827586 ], [ 29.76407329113924, -1.478534827586207 ], [ 29.764342784810125, -1.478534827586207 ], [ 29.764342784810125, -1.478265344827586 ], [ 29.76407329113924, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29811, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.478265344827586 ], [ 29.764342784810125, -1.478534827586207 ], [ 29.764612278481014, -1.478534827586207 ], [ 29.764612278481014, -1.478265344827586 ], [ 29.764342784810125, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29812, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.478265344827586 ], [ 29.764612278481014, -1.478804310344828 ], [ 29.764881772151899, -1.478804310344828 ], [ 29.764881772151899, -1.478265344827586 ], [ 29.764612278481014, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29813, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.478265344827586 ], [ 29.764881772151899, -1.478534827586207 ], [ 29.765151265822784, -1.478534827586207 ], [ 29.765151265822784, -1.478265344827586 ], [ 29.764881772151899, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29814, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.478265344827586 ], [ 29.765151265822784, -1.478534827586207 ], [ 29.765420759493672, -1.478534827586207 ], [ 29.765420759493672, -1.478265344827586 ], [ 29.765151265822784, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29815, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.478265344827586 ], [ 29.765420759493672, -1.478534827586207 ], [ 29.765959746835442, -1.478534827586207 ], [ 29.765959746835442, -1.478265344827586 ], [ 29.765420759493672, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29816, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.478265344827586 ], [ 29.765959746835442, -1.478534827586207 ], [ 29.766229240506327, -1.478534827586207 ], [ 29.766229240506327, -1.478265344827586 ], [ 29.765959746835442, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29817, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.478265344827586 ], [ 29.766229240506327, -1.478534827586207 ], [ 29.766498734177215, -1.478534827586207 ], [ 29.766498734177215, -1.478265344827586 ], [ 29.766229240506327, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29818, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.478265344827586 ], [ 29.766498734177215, -1.478534827586207 ], [ 29.7667682278481, -1.478534827586207 ], [ 29.7667682278481, -1.478265344827586 ], [ 29.766498734177215, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29819, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.478265344827586 ], [ 29.7667682278481, -1.478534827586207 ], [ 29.767037721518985, -1.478534827586207 ], [ 29.767037721518985, -1.478265344827586 ], [ 29.7667682278481, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29820, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.478265344827586 ], [ 29.767037721518985, -1.478534827586207 ], [ 29.767307215189874, -1.478534827586207 ], [ 29.767307215189874, -1.478265344827586 ], [ 29.767037721518985, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29821, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.478265344827586 ], [ 29.767307215189874, -1.478534827586207 ], [ 29.767576708860759, -1.478534827586207 ], [ 29.767576708860759, -1.478265344827586 ], [ 29.767307215189874, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29822, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.478265344827586 ], [ 29.767576708860759, -1.478534827586207 ], [ 29.767846202531643, -1.478534827586207 ], [ 29.767846202531643, -1.478265344827586 ], [ 29.767576708860759, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29823, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.478265344827586 ], [ 29.767846202531643, -1.478534827586207 ], [ 29.768115696202532, -1.478534827586207 ], [ 29.768115696202532, -1.478265344827586 ], [ 29.767846202531643, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29824, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.478265344827586 ], [ 29.768115696202532, -1.478534827586207 ], [ 29.768385189873417, -1.478534827586207 ], [ 29.768385189873417, -1.478265344827586 ], [ 29.768115696202532, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29825, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.478265344827586 ], [ 29.768385189873417, -1.478534827586207 ], [ 29.768654683544302, -1.478534827586207 ], [ 29.768654683544302, -1.478265344827586 ], [ 29.768385189873417, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29826, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.478265344827586 ], [ 29.768654683544302, -1.478534827586207 ], [ 29.770271645569618, -1.478534827586207 ], [ 29.770271645569618, -1.478265344827586 ], [ 29.768654683544302, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29827, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.478265344827586 ], [ 29.770271645569618, -1.478534827586207 ], [ 29.770541139240507, -1.478534827586207 ], [ 29.770541139240507, -1.478265344827586 ], [ 29.770271645569618, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29828, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.478265344827586 ], [ 29.770541139240507, -1.478534827586207 ], [ 29.770810632911392, -1.478534827586207 ], [ 29.770810632911392, -1.478265344827586 ], [ 29.770541139240507, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29829, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.478265344827586 ], [ 29.770810632911392, -1.478534827586207 ], [ 29.771080126582277, -1.478534827586207 ], [ 29.771080126582277, -1.478265344827586 ], [ 29.770810632911392, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29830, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.478265344827586 ], [ 29.771080126582277, -1.478534827586207 ], [ 29.771349620253165, -1.478534827586207 ], [ 29.771349620253165, -1.478265344827586 ], [ 29.771080126582277, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29831, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.478265344827586 ], [ 29.771349620253165, -1.478534827586207 ], [ 29.77161911392405, -1.478534827586207 ], [ 29.77161911392405, -1.478265344827586 ], [ 29.771349620253165, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29832, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.478265344827586 ], [ 29.77161911392405, -1.478534827586207 ], [ 29.771888607594935, -1.478534827586207 ], [ 29.771888607594935, -1.478265344827586 ], [ 29.77161911392405, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29833, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.478265344827586 ], [ 29.771888607594935, -1.478534827586207 ], [ 29.772158101265823, -1.478534827586207 ], [ 29.772158101265823, -1.478265344827586 ], [ 29.771888607594935, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29834, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.478265344827586 ], [ 29.772158101265823, -1.478534827586207 ], [ 29.772427594936708, -1.478534827586207 ], [ 29.772427594936708, -1.478265344827586 ], [ 29.772158101265823, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29835, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.478265344827586 ], [ 29.772427594936708, -1.478534827586207 ], [ 29.772697088607593, -1.478534827586207 ], [ 29.772697088607593, -1.478265344827586 ], [ 29.772427594936708, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29836, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.478265344827586 ], [ 29.772697088607593, -1.478534827586207 ], [ 29.772966582278482, -1.478534827586207 ], [ 29.772966582278482, -1.478265344827586 ], [ 29.772697088607593, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29837, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.478265344827586 ], [ 29.772966582278482, -1.478534827586207 ], [ 29.773236075949367, -1.478534827586207 ], [ 29.773236075949367, -1.478265344827586 ], [ 29.772966582278482, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29838, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.478265344827586 ], [ 29.773236075949367, -1.478534827586207 ], [ 29.773505569620252, -1.478534827586207 ], [ 29.773505569620252, -1.478265344827586 ], [ 29.773236075949367, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29839, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.478265344827586 ], [ 29.773505569620252, -1.478534827586207 ], [ 29.77377506329114, -1.478534827586207 ], [ 29.77377506329114, -1.478265344827586 ], [ 29.773505569620252, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29840, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.478265344827586 ], [ 29.77377506329114, -1.478534827586207 ], [ 29.774044556962025, -1.478534827586207 ], [ 29.774044556962025, -1.478265344827586 ], [ 29.77377506329114, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29841, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.478265344827586 ], [ 29.774044556962025, -1.478534827586207 ], [ 29.77431405063291, -1.478534827586207 ], [ 29.77431405063291, -1.478265344827586 ], [ 29.774044556962025, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29842, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.478265344827586 ], [ 29.77431405063291, -1.478534827586207 ], [ 29.774853037974683, -1.478534827586207 ], [ 29.774853037974683, -1.478265344827586 ], [ 29.77431405063291, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29843, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.478265344827586 ], [ 29.774853037974683, -1.478534827586207 ], [ 29.775122531645568, -1.478534827586207 ], [ 29.775122531645568, -1.478265344827586 ], [ 29.774853037974683, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29844, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.478265344827586 ], [ 29.775122531645568, -1.478534827586207 ], [ 29.775392025316457, -1.478534827586207 ], [ 29.775392025316457, -1.478265344827586 ], [ 29.775122531645568, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29845, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.478265344827586 ], [ 29.775392025316457, -1.478534827586207 ], [ 29.775661518987341, -1.478534827586207 ], [ 29.775661518987341, -1.478265344827586 ], [ 29.775392025316457, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29846, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.478265344827586 ], [ 29.775661518987341, -1.478534827586207 ], [ 29.775931012658226, -1.478534827586207 ], [ 29.775931012658226, -1.478265344827586 ], [ 29.775661518987341, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29847, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.478265344827586 ], [ 29.775931012658226, -1.478534827586207 ], [ 29.776200506329115, -1.478534827586207 ], [ 29.776200506329115, -1.478265344827586 ], [ 29.775931012658226, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29848, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.478265344827586 ], [ 29.776200506329115, -1.478534827586207 ], [ 29.77647, -1.478534827586207 ], [ 29.77647, -1.478265344827586 ], [ 29.776200506329115, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29849, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.478265344827586 ], [ 29.77647, -1.478534827586207 ], [ 29.776739493670885, -1.478534827586207 ], [ 29.776739493670885, -1.478265344827586 ], [ 29.77647, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29850, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.478265344827586 ], [ 29.776739493670885, -1.478534827586207 ], [ 29.777008987341773, -1.478534827586207 ], [ 29.777008987341773, -1.478265344827586 ], [ 29.776739493670885, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29851, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.478265344827586 ], [ 29.777008987341773, -1.478534827586207 ], [ 29.777278481012658, -1.478534827586207 ], [ 29.777278481012658, -1.478265344827586 ], [ 29.777008987341773, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29852, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.478265344827586 ], [ 29.777278481012658, -1.478534827586207 ], [ 29.777547974683543, -1.478534827586207 ], [ 29.777547974683543, -1.478265344827586 ], [ 29.777278481012658, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29853, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.478265344827586 ], [ 29.777547974683543, -1.478534827586207 ], [ 29.777817468354431, -1.478534827586207 ], [ 29.777817468354431, -1.478265344827586 ], [ 29.777547974683543, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29854, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.478265344827586 ], [ 29.778086962025316, -1.478804310344828 ], [ 29.778356455696201, -1.478804310344828 ], [ 29.778356455696201, -1.478265344827586 ], [ 29.778086962025316, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29855, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.478265344827586 ], [ 29.778356455696201, -1.478534827586207 ], [ 29.77862594936709, -1.478534827586207 ], [ 29.77862594936709, -1.478265344827586 ], [ 29.778356455696201, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29856, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.478265344827586 ], [ 29.77862594936709, -1.478534827586207 ], [ 29.778895443037975, -1.478534827586207 ], [ 29.778895443037975, -1.478265344827586 ], [ 29.77862594936709, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29857, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.478265344827586 ], [ 29.778895443037975, -1.478534827586207 ], [ 29.77916493670886, -1.478534827586207 ], [ 29.77916493670886, -1.478265344827586 ], [ 29.778895443037975, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29858, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.478265344827586 ], [ 29.77916493670886, -1.478534827586207 ], [ 29.779434430379748, -1.478534827586207 ], [ 29.779434430379748, -1.478265344827586 ], [ 29.77916493670886, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29859, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.478265344827586 ], [ 29.779434430379748, -1.478534827586207 ], [ 29.779703924050633, -1.478534827586207 ], [ 29.779703924050633, -1.478265344827586 ], [ 29.779434430379748, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29860, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.478265344827586 ], [ 29.779703924050633, -1.478804310344828 ], [ 29.779973417721518, -1.478804310344828 ], [ 29.779973417721518, -1.478265344827586 ], [ 29.779703924050633, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29861, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.478265344827586 ], [ 29.779973417721518, -1.478534827586207 ], [ 29.780242911392406, -1.478534827586207 ], [ 29.780242911392406, -1.478265344827586 ], [ 29.779973417721518, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29862, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.478265344827586 ], [ 29.780242911392406, -1.478534827586207 ], [ 29.780512405063291, -1.478534827586207 ], [ 29.780512405063291, -1.478265344827586 ], [ 29.780242911392406, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29863, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.478265344827586 ], [ 29.780512405063291, -1.478534827586207 ], [ 29.780781898734176, -1.478534827586207 ], [ 29.780781898734176, -1.478265344827586 ], [ 29.780512405063291, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29864, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.478265344827586 ], [ 29.780781898734176, -1.478534827586207 ], [ 29.781051392405065, -1.478534827586207 ], [ 29.781051392405065, -1.478265344827586 ], [ 29.780781898734176, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29865, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.478265344827586 ], [ 29.781051392405065, -1.478534827586207 ], [ 29.78132088607595, -1.478534827586207 ], [ 29.78132088607595, -1.478265344827586 ], [ 29.781051392405065, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29866, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.478265344827586 ], [ 29.78132088607595, -1.478534827586207 ], [ 29.781590379746834, -1.478534827586207 ], [ 29.781590379746834, -1.478265344827586 ], [ 29.78132088607595, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29867, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.478265344827586 ], [ 29.781590379746834, -1.478534827586207 ], [ 29.781859873417723, -1.478534827586207 ], [ 29.781859873417723, -1.478265344827586 ], [ 29.781590379746834, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29868, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.478265344827586 ], [ 29.781859873417723, -1.478534827586207 ], [ 29.782129367088608, -1.478534827586207 ], [ 29.782129367088608, -1.478265344827586 ], [ 29.781859873417723, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29869, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.478265344827586 ], [ 29.782129367088608, -1.478534827586207 ], [ 29.782398860759493, -1.478534827586207 ], [ 29.782398860759493, -1.478265344827586 ], [ 29.782129367088608, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29870, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.478265344827586 ], [ 29.782398860759493, -1.478534827586207 ], [ 29.782668354430381, -1.478534827586207 ], [ 29.782668354430381, -1.478265344827586 ], [ 29.782398860759493, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29871, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.478265344827586 ], [ 29.782668354430381, -1.478534827586207 ], [ 29.782937848101266, -1.478534827586207 ], [ 29.782937848101266, -1.478265344827586 ], [ 29.782668354430381, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29872, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.478265344827586 ], [ 29.782937848101266, -1.478534827586207 ], [ 29.783207341772151, -1.478534827586207 ], [ 29.783207341772151, -1.478265344827586 ], [ 29.782937848101266, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29873, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.478265344827586 ], [ 29.783207341772151, -1.478534827586207 ], [ 29.783476835443039, -1.478534827586207 ], [ 29.783476835443039, -1.478265344827586 ], [ 29.783207341772151, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29874, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.478265344827586 ], [ 29.783476835443039, -1.478534827586207 ], [ 29.784015822784809, -1.478534827586207 ], [ 29.784015822784809, -1.478265344827586 ], [ 29.783476835443039, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29875, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.478265344827586 ], [ 29.784015822784809, -1.478534827586207 ], [ 29.784285316455698, -1.478534827586207 ], [ 29.784285316455698, -1.478265344827586 ], [ 29.784015822784809, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29876, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.478265344827586 ], [ 29.784285316455698, -1.478534827586207 ], [ 29.784554810126583, -1.478534827586207 ], [ 29.784554810126583, -1.478265344827586 ], [ 29.784285316455698, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29877, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.478265344827586 ], [ 29.784554810126583, -1.478534827586207 ], [ 29.784824303797468, -1.478534827586207 ], [ 29.784824303797468, -1.478265344827586 ], [ 29.784554810126583, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29878, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.478265344827586 ], [ 29.784824303797468, -1.478534827586207 ], [ 29.785093797468356, -1.478534827586207 ], [ 29.785093797468356, -1.478265344827586 ], [ 29.784824303797468, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29879, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.478265344827586 ], [ 29.785093797468356, -1.478534827586207 ], [ 29.785363291139241, -1.478534827586207 ], [ 29.785363291139241, -1.478265344827586 ], [ 29.785093797468356, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29880, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.478265344827586 ], [ 29.785363291139241, -1.478534827586207 ], [ 29.785632784810126, -1.478534827586207 ], [ 29.785632784810126, -1.478265344827586 ], [ 29.785363291139241, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29881, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.478265344827586 ], [ 29.785632784810126, -1.478534827586207 ], [ 29.786171772151899, -1.478534827586207 ], [ 29.786171772151899, -1.478265344827586 ], [ 29.785632784810126, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29882, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.478265344827586 ], [ 29.786171772151899, -1.478534827586207 ], [ 29.786980253164558, -1.478534827586207 ], [ 29.786980253164558, -1.478265344827586 ], [ 29.786171772151899, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29883, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.478265344827586 ], [ 29.786980253164558, -1.478534827586207 ], [ 29.787519240506327, -1.478534827586207 ], [ 29.787519240506327, -1.478265344827586 ], [ 29.786980253164558, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29884, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.478265344827586 ], [ 29.787519240506327, -1.478534827586207 ], [ 29.787788734177216, -1.478534827586207 ], [ 29.787788734177216, -1.478265344827586 ], [ 29.787519240506327, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29885, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.478265344827586 ], [ 29.787788734177216, -1.478534827586207 ], [ 29.788058227848101, -1.478534827586207 ], [ 29.788058227848101, -1.478265344827586 ], [ 29.787788734177216, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29886, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.478265344827586 ], [ 29.788058227848101, -1.478534827586207 ], [ 29.788327721518986, -1.478534827586207 ], [ 29.788327721518986, -1.478265344827586 ], [ 29.788058227848101, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29887, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.478265344827586 ], [ 29.788327721518986, -1.478534827586207 ], [ 29.788866708860759, -1.478534827586207 ], [ 29.788866708860759, -1.478265344827586 ], [ 29.788327721518986, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29888, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.478265344827586 ], [ 29.788866708860759, -1.478534827586207 ], [ 29.789136202531644, -1.478534827586207 ], [ 29.789136202531644, -1.478265344827586 ], [ 29.788866708860759, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29889, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.478265344827586 ], [ 29.789136202531644, -1.478534827586207 ], [ 29.789405696202532, -1.478534827586207 ], [ 29.789405696202532, -1.478265344827586 ], [ 29.789136202531644, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29890, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.478265344827586 ], [ 29.789405696202532, -1.478534827586207 ], [ 29.789675189873417, -1.478534827586207 ], [ 29.789675189873417, -1.478265344827586 ], [ 29.789405696202532, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29891, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.478265344827586 ], [ 29.789675189873417, -1.478534827586207 ], [ 29.789944683544302, -1.478534827586207 ], [ 29.789944683544302, -1.478265344827586 ], [ 29.789675189873417, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29892, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.478265344827586 ], [ 29.789944683544302, -1.478534827586207 ], [ 29.790214177215191, -1.478534827586207 ], [ 29.790214177215191, -1.478265344827586 ], [ 29.789944683544302, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29893, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.478265344827586 ], [ 29.800724430379748, -1.478534827586207 ], [ 29.800993924050633, -1.478534827586207 ], [ 29.800993924050633, -1.478265344827586 ], [ 29.800724430379748, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29894, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.478265344827586 ], [ 29.800993924050633, -1.478804310344828 ], [ 29.801263417721518, -1.478804310344828 ], [ 29.801263417721518, -1.478265344827586 ], [ 29.800993924050633, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29895, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.478265344827586 ], [ 29.801263417721518, -1.478534827586207 ], [ 29.801532911392407, -1.478534827586207 ], [ 29.801532911392407, -1.478265344827586 ], [ 29.801263417721518, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29896, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.478265344827586 ], [ 29.801532911392407, -1.478534827586207 ], [ 29.802071898734177, -1.478534827586207 ], [ 29.802071898734177, -1.478265344827586 ], [ 29.801532911392407, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29897, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.478265344827586 ], [ 29.802071898734177, -1.478534827586207 ], [ 29.802341392405065, -1.478534827586207 ], [ 29.802341392405065, -1.478265344827586 ], [ 29.802071898734177, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29898, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.478265344827586 ], [ 29.802341392405065, -1.478534827586207 ], [ 29.80261088607595, -1.478534827586207 ], [ 29.80261088607595, -1.478265344827586 ], [ 29.802341392405065, -1.478265344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29899, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.760839367088607, -1.478534827586207 ], [ 29.760839367088607, -1.479073793103448 ], [ 29.761108860759492, -1.479073793103448 ], [ 29.761108860759492, -1.478534827586207 ], [ 29.760839367088607, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29900, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.478534827586207 ], [ 29.761108860759492, -1.478804310344828 ], [ 29.761378354430381, -1.478804310344828 ], [ 29.761378354430381, -1.478534827586207 ], [ 29.761108860759492, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29901, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.478534827586207 ], [ 29.761378354430381, -1.478804310344828 ], [ 29.761647848101266, -1.478804310344828 ], [ 29.761647848101266, -1.478534827586207 ], [ 29.761378354430381, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29902, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.478534827586207 ], [ 29.761647848101266, -1.478804310344828 ], [ 29.761917341772151, -1.478804310344828 ], [ 29.761917341772151, -1.478534827586207 ], [ 29.761647848101266, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29903, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.478534827586207 ], [ 29.761917341772151, -1.478804310344828 ], [ 29.762186835443039, -1.478804310344828 ], [ 29.762186835443039, -1.478534827586207 ], [ 29.761917341772151, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29904, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.478534827586207 ], [ 29.762186835443039, -1.478804310344828 ], [ 29.762456329113924, -1.478804310344828 ], [ 29.762456329113924, -1.478534827586207 ], [ 29.762186835443039, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29905, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.478534827586207 ], [ 29.762456329113924, -1.478804310344828 ], [ 29.762725822784809, -1.478804310344828 ], [ 29.762725822784809, -1.478534827586207 ], [ 29.762456329113924, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29906, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.478534827586207 ], [ 29.762725822784809, -1.478804310344828 ], [ 29.762995316455697, -1.478804310344828 ], [ 29.762995316455697, -1.478534827586207 ], [ 29.762725822784809, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29907, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.478534827586207 ], [ 29.762995316455697, -1.478804310344828 ], [ 29.763264810126582, -1.478804310344828 ], [ 29.763264810126582, -1.478534827586207 ], [ 29.762995316455697, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29908, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.478534827586207 ], [ 29.763264810126582, -1.478804310344828 ], [ 29.763534303797467, -1.478804310344828 ], [ 29.763534303797467, -1.478534827586207 ], [ 29.763264810126582, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29909, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.478534827586207 ], [ 29.763534303797467, -1.478804310344828 ], [ 29.763803797468356, -1.478804310344828 ], [ 29.763803797468356, -1.478534827586207 ], [ 29.763534303797467, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29910, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.478534827586207 ], [ 29.763803797468356, -1.478804310344828 ], [ 29.76407329113924, -1.478804310344828 ], [ 29.76407329113924, -1.478534827586207 ], [ 29.763803797468356, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29911, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.478534827586207 ], [ 29.76407329113924, -1.478804310344828 ], [ 29.764342784810125, -1.478804310344828 ], [ 29.764342784810125, -1.478534827586207 ], [ 29.76407329113924, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29912, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.478534827586207 ], [ 29.764342784810125, -1.478804310344828 ], [ 29.764612278481014, -1.478804310344828 ], [ 29.764612278481014, -1.478534827586207 ], [ 29.764342784810125, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29913, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.478534827586207 ], [ 29.764881772151899, -1.478804310344828 ], [ 29.765151265822784, -1.478804310344828 ], [ 29.765151265822784, -1.478534827586207 ], [ 29.764881772151899, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29914, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.478534827586207 ], [ 29.765151265822784, -1.478804310344828 ], [ 29.765420759493672, -1.478804310344828 ], [ 29.765420759493672, -1.478534827586207 ], [ 29.765151265822784, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29915, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.478534827586207 ], [ 29.765420759493672, -1.478804310344828 ], [ 29.765959746835442, -1.478804310344828 ], [ 29.765959746835442, -1.478534827586207 ], [ 29.765420759493672, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29916, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.478534827586207 ], [ 29.765959746835442, -1.478804310344828 ], [ 29.766229240506327, -1.478804310344828 ], [ 29.766229240506327, -1.478534827586207 ], [ 29.765959746835442, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29917, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.478534827586207 ], [ 29.766229240506327, -1.478804310344828 ], [ 29.766498734177215, -1.478804310344828 ], [ 29.766498734177215, -1.478534827586207 ], [ 29.766229240506327, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29918, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.478534827586207 ], [ 29.766498734177215, -1.478804310344828 ], [ 29.7667682278481, -1.478804310344828 ], [ 29.7667682278481, -1.478534827586207 ], [ 29.766498734177215, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29919, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.478534827586207 ], [ 29.7667682278481, -1.478804310344828 ], [ 29.767037721518985, -1.478804310344828 ], [ 29.767037721518985, -1.478534827586207 ], [ 29.7667682278481, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29920, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.478534827586207 ], [ 29.767037721518985, -1.478804310344828 ], [ 29.767307215189874, -1.478804310344828 ], [ 29.767307215189874, -1.478534827586207 ], [ 29.767037721518985, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29921, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.478534827586207 ], [ 29.767307215189874, -1.478804310344828 ], [ 29.767576708860759, -1.478804310344828 ], [ 29.767576708860759, -1.478534827586207 ], [ 29.767307215189874, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29922, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.478534827586207 ], [ 29.767576708860759, -1.478804310344828 ], [ 29.767846202531643, -1.478804310344828 ], [ 29.767846202531643, -1.478534827586207 ], [ 29.767576708860759, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29923, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.478534827586207 ], [ 29.767846202531643, -1.478804310344828 ], [ 29.768115696202532, -1.478804310344828 ], [ 29.768115696202532, -1.478534827586207 ], [ 29.767846202531643, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29924, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.478534827586207 ], [ 29.768115696202532, -1.478804310344828 ], [ 29.768385189873417, -1.478804310344828 ], [ 29.768385189873417, -1.478534827586207 ], [ 29.768115696202532, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29925, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.478534827586207 ], [ 29.768385189873417, -1.478804310344828 ], [ 29.768654683544302, -1.478804310344828 ], [ 29.768654683544302, -1.478534827586207 ], [ 29.768385189873417, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29926, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.478534827586207 ], [ 29.768654683544302, -1.478804310344828 ], [ 29.770271645569618, -1.478804310344828 ], [ 29.770271645569618, -1.478534827586207 ], [ 29.768654683544302, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29927, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.478534827586207 ], [ 29.770271645569618, -1.478804310344828 ], [ 29.770541139240507, -1.478804310344828 ], [ 29.770541139240507, -1.478534827586207 ], [ 29.770271645569618, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29928, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.478534827586207 ], [ 29.770541139240507, -1.478804310344828 ], [ 29.770810632911392, -1.478804310344828 ], [ 29.770810632911392, -1.478534827586207 ], [ 29.770541139240507, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29929, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.478534827586207 ], [ 29.770810632911392, -1.478804310344828 ], [ 29.771080126582277, -1.478804310344828 ], [ 29.771080126582277, -1.478534827586207 ], [ 29.770810632911392, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29930, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.478534827586207 ], [ 29.771080126582277, -1.478804310344828 ], [ 29.771349620253165, -1.478804310344828 ], [ 29.771349620253165, -1.478534827586207 ], [ 29.771080126582277, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29931, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.478534827586207 ], [ 29.771349620253165, -1.478804310344828 ], [ 29.77161911392405, -1.478804310344828 ], [ 29.77161911392405, -1.478534827586207 ], [ 29.771349620253165, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29932, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.478534827586207 ], [ 29.77161911392405, -1.478804310344828 ], [ 29.771888607594935, -1.478804310344828 ], [ 29.771888607594935, -1.478534827586207 ], [ 29.77161911392405, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29933, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.478534827586207 ], [ 29.771888607594935, -1.478804310344828 ], [ 29.772158101265823, -1.478804310344828 ], [ 29.772158101265823, -1.478534827586207 ], [ 29.771888607594935, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29934, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.478534827586207 ], [ 29.772158101265823, -1.478804310344828 ], [ 29.772427594936708, -1.478804310344828 ], [ 29.772427594936708, -1.478534827586207 ], [ 29.772158101265823, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29935, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.478534827586207 ], [ 29.772427594936708, -1.478804310344828 ], [ 29.772697088607593, -1.478804310344828 ], [ 29.772697088607593, -1.478534827586207 ], [ 29.772427594936708, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29936, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.478534827586207 ], [ 29.772697088607593, -1.478804310344828 ], [ 29.772966582278482, -1.478804310344828 ], [ 29.772966582278482, -1.478534827586207 ], [ 29.772697088607593, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29937, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.478534827586207 ], [ 29.772966582278482, -1.478804310344828 ], [ 29.773236075949367, -1.478804310344828 ], [ 29.773236075949367, -1.478534827586207 ], [ 29.772966582278482, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29938, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.478534827586207 ], [ 29.773236075949367, -1.478804310344828 ], [ 29.773505569620252, -1.478804310344828 ], [ 29.773505569620252, -1.478534827586207 ], [ 29.773236075949367, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29939, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.478534827586207 ], [ 29.773505569620252, -1.478804310344828 ], [ 29.77377506329114, -1.478804310344828 ], [ 29.77377506329114, -1.478534827586207 ], [ 29.773505569620252, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29940, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.478534827586207 ], [ 29.77377506329114, -1.478804310344828 ], [ 29.774044556962025, -1.478804310344828 ], [ 29.774044556962025, -1.478534827586207 ], [ 29.77377506329114, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29941, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.478534827586207 ], [ 29.774044556962025, -1.478804310344828 ], [ 29.77431405063291, -1.478804310344828 ], [ 29.77431405063291, -1.478534827586207 ], [ 29.774044556962025, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29942, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.478534827586207 ], [ 29.77431405063291, -1.478804310344828 ], [ 29.774853037974683, -1.478804310344828 ], [ 29.774853037974683, -1.478534827586207 ], [ 29.77431405063291, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29943, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.478534827586207 ], [ 29.774853037974683, -1.478804310344828 ], [ 29.775122531645568, -1.478804310344828 ], [ 29.775122531645568, -1.478534827586207 ], [ 29.774853037974683, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29944, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.478534827586207 ], [ 29.775122531645568, -1.478804310344828 ], [ 29.775392025316457, -1.478804310344828 ], [ 29.775392025316457, -1.478534827586207 ], [ 29.775122531645568, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29945, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.478534827586207 ], [ 29.775392025316457, -1.478804310344828 ], [ 29.775661518987341, -1.478804310344828 ], [ 29.775661518987341, -1.478534827586207 ], [ 29.775392025316457, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29946, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.478534827586207 ], [ 29.775661518987341, -1.478804310344828 ], [ 29.775931012658226, -1.478804310344828 ], [ 29.775931012658226, -1.478534827586207 ], [ 29.775661518987341, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29947, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.478534827586207 ], [ 29.775931012658226, -1.478804310344828 ], [ 29.776200506329115, -1.478804310344828 ], [ 29.776200506329115, -1.478534827586207 ], [ 29.775931012658226, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29948, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.478534827586207 ], [ 29.776200506329115, -1.478804310344828 ], [ 29.77647, -1.478804310344828 ], [ 29.77647, -1.478534827586207 ], [ 29.776200506329115, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29949, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.478534827586207 ], [ 29.77647, -1.478804310344828 ], [ 29.776739493670885, -1.478804310344828 ], [ 29.776739493670885, -1.478534827586207 ], [ 29.77647, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29950, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.478534827586207 ], [ 29.776739493670885, -1.478804310344828 ], [ 29.777008987341773, -1.478804310344828 ], [ 29.777008987341773, -1.478534827586207 ], [ 29.776739493670885, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29951, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.478534827586207 ], [ 29.777008987341773, -1.478804310344828 ], [ 29.777278481012658, -1.478804310344828 ], [ 29.777278481012658, -1.478534827586207 ], [ 29.777008987341773, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29952, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.478534827586207 ], [ 29.777278481012658, -1.478804310344828 ], [ 29.777547974683543, -1.478804310344828 ], [ 29.777547974683543, -1.478534827586207 ], [ 29.777278481012658, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29953, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.478534827586207 ], [ 29.777547974683543, -1.478804310344828 ], [ 29.777817468354431, -1.478804310344828 ], [ 29.777817468354431, -1.478534827586207 ], [ 29.777547974683543, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29954, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.478534827586207 ], [ 29.777817468354431, -1.478804310344828 ], [ 29.778086962025316, -1.478804310344828 ], [ 29.778086962025316, -1.478534827586207 ], [ 29.777817468354431, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29955, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.478534827586207 ], [ 29.778356455696201, -1.478804310344828 ], [ 29.77862594936709, -1.478804310344828 ], [ 29.77862594936709, -1.478534827586207 ], [ 29.778356455696201, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29956, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.478534827586207 ], [ 29.77862594936709, -1.478804310344828 ], [ 29.778895443037975, -1.478804310344828 ], [ 29.778895443037975, -1.478534827586207 ], [ 29.77862594936709, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29957, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.478534827586207 ], [ 29.778895443037975, -1.478804310344828 ], [ 29.77916493670886, -1.478804310344828 ], [ 29.77916493670886, -1.478534827586207 ], [ 29.778895443037975, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29958, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.478534827586207 ], [ 29.77916493670886, -1.478804310344828 ], [ 29.779434430379748, -1.478804310344828 ], [ 29.779434430379748, -1.478534827586207 ], [ 29.77916493670886, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29959, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.478534827586207 ], [ 29.779434430379748, -1.478804310344828 ], [ 29.779703924050633, -1.478804310344828 ], [ 29.779703924050633, -1.478534827586207 ], [ 29.779434430379748, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29960, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.478534827586207 ], [ 29.779973417721518, -1.478804310344828 ], [ 29.780242911392406, -1.478804310344828 ], [ 29.780242911392406, -1.478534827586207 ], [ 29.779973417721518, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29961, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.478534827586207 ], [ 29.780242911392406, -1.478804310344828 ], [ 29.780512405063291, -1.478804310344828 ], [ 29.780512405063291, -1.478534827586207 ], [ 29.780242911392406, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29962, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.478534827586207 ], [ 29.780512405063291, -1.478804310344828 ], [ 29.780781898734176, -1.478804310344828 ], [ 29.780781898734176, -1.478534827586207 ], [ 29.780512405063291, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29963, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.478534827586207 ], [ 29.780781898734176, -1.479073793103448 ], [ 29.781051392405065, -1.479073793103448 ], [ 29.781051392405065, -1.478534827586207 ], [ 29.780781898734176, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29964, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.478534827586207 ], [ 29.781051392405065, -1.478804310344828 ], [ 29.78132088607595, -1.478804310344828 ], [ 29.78132088607595, -1.478534827586207 ], [ 29.781051392405065, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29965, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.478534827586207 ], [ 29.78132088607595, -1.478804310344828 ], [ 29.781590379746834, -1.478804310344828 ], [ 29.781590379746834, -1.478534827586207 ], [ 29.78132088607595, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29966, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.478534827586207 ], [ 29.781590379746834, -1.478804310344828 ], [ 29.781859873417723, -1.478804310344828 ], [ 29.781859873417723, -1.478534827586207 ], [ 29.781590379746834, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29967, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.478534827586207 ], [ 29.781859873417723, -1.478804310344828 ], [ 29.782129367088608, -1.478804310344828 ], [ 29.782129367088608, -1.478534827586207 ], [ 29.781859873417723, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29968, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.478534827586207 ], [ 29.782129367088608, -1.478804310344828 ], [ 29.782398860759493, -1.478804310344828 ], [ 29.782398860759493, -1.478534827586207 ], [ 29.782129367088608, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29969, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.478534827586207 ], [ 29.782398860759493, -1.478804310344828 ], [ 29.782668354430381, -1.478804310344828 ], [ 29.782668354430381, -1.478534827586207 ], [ 29.782398860759493, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29970, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.478534827586207 ], [ 29.782668354430381, -1.478804310344828 ], [ 29.782937848101266, -1.478804310344828 ], [ 29.782937848101266, -1.478534827586207 ], [ 29.782668354430381, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29971, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.478534827586207 ], [ 29.782937848101266, -1.478804310344828 ], [ 29.783207341772151, -1.478804310344828 ], [ 29.783207341772151, -1.478534827586207 ], [ 29.782937848101266, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29972, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.478534827586207 ], [ 29.783207341772151, -1.478804310344828 ], [ 29.783476835443039, -1.478804310344828 ], [ 29.783476835443039, -1.478534827586207 ], [ 29.783207341772151, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29973, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.478534827586207 ], [ 29.783476835443039, -1.478804310344828 ], [ 29.784015822784809, -1.478804310344828 ], [ 29.784015822784809, -1.478534827586207 ], [ 29.783476835443039, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29974, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.478534827586207 ], [ 29.784015822784809, -1.478804310344828 ], [ 29.784285316455698, -1.478804310344828 ], [ 29.784285316455698, -1.478534827586207 ], [ 29.784015822784809, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29975, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.478534827586207 ], [ 29.784285316455698, -1.478804310344828 ], [ 29.784554810126583, -1.478804310344828 ], [ 29.784554810126583, -1.478534827586207 ], [ 29.784285316455698, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29976, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.478534827586207 ], [ 29.784554810126583, -1.478804310344828 ], [ 29.784824303797468, -1.478804310344828 ], [ 29.784824303797468, -1.478534827586207 ], [ 29.784554810126583, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29977, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.478534827586207 ], [ 29.784824303797468, -1.478804310344828 ], [ 29.785093797468356, -1.478804310344828 ], [ 29.785093797468356, -1.478534827586207 ], [ 29.784824303797468, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29978, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.478534827586207 ], [ 29.785093797468356, -1.478804310344828 ], [ 29.785363291139241, -1.478804310344828 ], [ 29.785363291139241, -1.478534827586207 ], [ 29.785093797468356, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29979, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.478534827586207 ], [ 29.785363291139241, -1.478804310344828 ], [ 29.785632784810126, -1.478804310344828 ], [ 29.785632784810126, -1.478534827586207 ], [ 29.785363291139241, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29980, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.478534827586207 ], [ 29.785632784810126, -1.478804310344828 ], [ 29.785902278481014, -1.478804310344828 ], [ 29.785902278481014, -1.478534827586207 ], [ 29.785632784810126, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29981, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.478534827586207 ], [ 29.785902278481014, -1.478804310344828 ], [ 29.787249746835442, -1.478804310344828 ], [ 29.787249746835442, -1.478534827586207 ], [ 29.785902278481014, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29982, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.478534827586207 ], [ 29.787249746835442, -1.478804310344828 ], [ 29.787519240506327, -1.478804310344828 ], [ 29.787519240506327, -1.478534827586207 ], [ 29.787249746835442, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29983, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.478534827586207 ], [ 29.787519240506327, -1.478804310344828 ], [ 29.787788734177216, -1.478804310344828 ], [ 29.787788734177216, -1.478534827586207 ], [ 29.787519240506327, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29984, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.478534827586207 ], [ 29.787788734177216, -1.478804310344828 ], [ 29.788327721518986, -1.478804310344828 ], [ 29.788327721518986, -1.478534827586207 ], [ 29.787788734177216, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29985, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.478534827586207 ], [ 29.788327721518986, -1.478804310344828 ], [ 29.788597215189874, -1.478804310344828 ], [ 29.788597215189874, -1.478534827586207 ], [ 29.788327721518986, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29986, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.478534827586207 ], [ 29.788597215189874, -1.478804310344828 ], [ 29.788866708860759, -1.478804310344828 ], [ 29.788866708860759, -1.478534827586207 ], [ 29.788597215189874, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29987, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.478534827586207 ], [ 29.788866708860759, -1.479073793103448 ], [ 29.789136202531644, -1.479073793103448 ], [ 29.789136202531644, -1.478534827586207 ], [ 29.788866708860759, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29988, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.478534827586207 ], [ 29.789136202531644, -1.478804310344828 ], [ 29.789405696202532, -1.478804310344828 ], [ 29.789405696202532, -1.478534827586207 ], [ 29.789136202531644, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29989, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.478534827586207 ], [ 29.789405696202532, -1.478804310344828 ], [ 29.789675189873417, -1.478804310344828 ], [ 29.789675189873417, -1.478534827586207 ], [ 29.789405696202532, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29990, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.478534827586207 ], [ 29.789675189873417, -1.478804310344828 ], [ 29.789944683544302, -1.478804310344828 ], [ 29.789944683544302, -1.478534827586207 ], [ 29.789675189873417, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29991, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.478534827586207 ], [ 29.789944683544302, -1.478804310344828 ], [ 29.790214177215191, -1.478804310344828 ], [ 29.790214177215191, -1.478534827586207 ], [ 29.789944683544302, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29992, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.478534827586207 ], [ 29.790214177215191, -1.478804310344828 ], [ 29.790483670886076, -1.478804310344828 ], [ 29.790483670886076, -1.478534827586207 ], [ 29.790214177215191, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29993, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.478534827586207 ], [ 29.800724430379748, -1.479073793103448 ], [ 29.800993924050633, -1.479073793103448 ], [ 29.800993924050633, -1.478534827586207 ], [ 29.800724430379748, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29994, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.478534827586207 ], [ 29.801532911392407, -1.478804310344828 ], [ 29.802071898734177, -1.478804310344828 ], [ 29.802071898734177, -1.478534827586207 ], [ 29.801532911392407, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29995, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.478534827586207 ], [ 29.802071898734177, -1.478804310344828 ], [ 29.802341392405065, -1.478804310344828 ], [ 29.802341392405065, -1.478534827586207 ], [ 29.802071898734177, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29996, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.478534827586207 ], [ 29.802341392405065, -1.479073793103448 ], [ 29.80261088607595, -1.479073793103448 ], [ 29.80261088607595, -1.478534827586207 ], [ 29.802341392405065, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29997, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.478534827586207 ], [ 29.80261088607595, -1.478804310344828 ], [ 29.802880379746835, -1.478804310344828 ], [ 29.802880379746835, -1.478534827586207 ], [ 29.80261088607595, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29998, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.478534827586207 ], [ 29.802880379746835, -1.478804310344828 ], [ 29.803149873417723, -1.478804310344828 ], [ 29.803149873417723, -1.478534827586207 ], [ 29.802880379746835, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 29999, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.478534827586207 ], [ 29.803149873417723, -1.478804310344828 ], [ 29.803419367088608, -1.478804310344828 ], [ 29.803419367088608, -1.478534827586207 ], [ 29.803149873417723, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30000, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.478804310344828 ], [ 29.761108860759492, -1.479073793103448 ], [ 29.761378354430381, -1.479073793103448 ], [ 29.761378354430381, -1.478804310344828 ], [ 29.761108860759492, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30001, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.478804310344828 ], [ 29.761378354430381, -1.479073793103448 ], [ 29.761647848101266, -1.479073793103448 ], [ 29.761647848101266, -1.478804310344828 ], [ 29.761378354430381, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30002, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.478804310344828 ], [ 29.761647848101266, -1.479073793103448 ], [ 29.761917341772151, -1.479073793103448 ], [ 29.761917341772151, -1.478804310344828 ], [ 29.761647848101266, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30003, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.478804310344828 ], [ 29.761917341772151, -1.479073793103448 ], [ 29.762186835443039, -1.479073793103448 ], [ 29.762186835443039, -1.478804310344828 ], [ 29.761917341772151, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30004, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.478804310344828 ], [ 29.762186835443039, -1.479073793103448 ], [ 29.762456329113924, -1.479073793103448 ], [ 29.762456329113924, -1.478804310344828 ], [ 29.762186835443039, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30005, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.478804310344828 ], [ 29.762456329113924, -1.479073793103448 ], [ 29.762725822784809, -1.479073793103448 ], [ 29.762725822784809, -1.478804310344828 ], [ 29.762456329113924, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30006, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.478804310344828 ], [ 29.762725822784809, -1.479073793103448 ], [ 29.762995316455697, -1.479073793103448 ], [ 29.762995316455697, -1.478804310344828 ], [ 29.762725822784809, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30007, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.478804310344828 ], [ 29.762995316455697, -1.479073793103448 ], [ 29.763264810126582, -1.479073793103448 ], [ 29.763264810126582, -1.478804310344828 ], [ 29.762995316455697, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30008, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.478804310344828 ], [ 29.763264810126582, -1.479073793103448 ], [ 29.763534303797467, -1.479073793103448 ], [ 29.763534303797467, -1.478804310344828 ], [ 29.763264810126582, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30009, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.478804310344828 ], [ 29.763534303797467, -1.479073793103448 ], [ 29.763803797468356, -1.479073793103448 ], [ 29.763803797468356, -1.478804310344828 ], [ 29.763534303797467, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30010, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.478804310344828 ], [ 29.763803797468356, -1.479343275862069 ], [ 29.76407329113924, -1.479343275862069 ], [ 29.76407329113924, -1.478804310344828 ], [ 29.763803797468356, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30011, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.478804310344828 ], [ 29.76407329113924, -1.479073793103448 ], [ 29.764342784810125, -1.479073793103448 ], [ 29.764342784810125, -1.478804310344828 ], [ 29.76407329113924, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30012, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.478804310344828 ], [ 29.764342784810125, -1.479073793103448 ], [ 29.764612278481014, -1.479073793103448 ], [ 29.764612278481014, -1.478804310344828 ], [ 29.764342784810125, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30013, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.478804310344828 ], [ 29.764612278481014, -1.479073793103448 ], [ 29.764881772151899, -1.479073793103448 ], [ 29.764881772151899, -1.478804310344828 ], [ 29.764612278481014, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30014, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.478804310344828 ], [ 29.764881772151899, -1.479073793103448 ], [ 29.765151265822784, -1.479073793103448 ], [ 29.765151265822784, -1.478804310344828 ], [ 29.764881772151899, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30015, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.478804310344828 ], [ 29.765151265822784, -1.479073793103448 ], [ 29.765420759493672, -1.479073793103448 ], [ 29.765420759493672, -1.478804310344828 ], [ 29.765151265822784, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30016, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.478804310344828 ], [ 29.765420759493672, -1.479073793103448 ], [ 29.765959746835442, -1.479073793103448 ], [ 29.765959746835442, -1.478804310344828 ], [ 29.765420759493672, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30017, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.478804310344828 ], [ 29.765959746835442, -1.479073793103448 ], [ 29.766229240506327, -1.479073793103448 ], [ 29.766229240506327, -1.478804310344828 ], [ 29.765959746835442, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30018, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.478804310344828 ], [ 29.766229240506327, -1.479073793103448 ], [ 29.766498734177215, -1.479073793103448 ], [ 29.766498734177215, -1.478804310344828 ], [ 29.766229240506327, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30019, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.478804310344828 ], [ 29.766498734177215, -1.479073793103448 ], [ 29.7667682278481, -1.479073793103448 ], [ 29.7667682278481, -1.478804310344828 ], [ 29.766498734177215, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30020, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.478804310344828 ], [ 29.7667682278481, -1.479073793103448 ], [ 29.767037721518985, -1.479073793103448 ], [ 29.767037721518985, -1.478804310344828 ], [ 29.7667682278481, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30021, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.478804310344828 ], [ 29.767037721518985, -1.479073793103448 ], [ 29.767307215189874, -1.479073793103448 ], [ 29.767307215189874, -1.478804310344828 ], [ 29.767037721518985, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30022, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.478804310344828 ], [ 29.767307215189874, -1.479073793103448 ], [ 29.767576708860759, -1.479073793103448 ], [ 29.767576708860759, -1.478804310344828 ], [ 29.767307215189874, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30023, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.478804310344828 ], [ 29.767576708860759, -1.479073793103448 ], [ 29.767846202531643, -1.479073793103448 ], [ 29.767846202531643, -1.478804310344828 ], [ 29.767576708860759, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30024, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.478804310344828 ], [ 29.767846202531643, -1.479073793103448 ], [ 29.768115696202532, -1.479073793103448 ], [ 29.768115696202532, -1.478804310344828 ], [ 29.767846202531643, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30025, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.478804310344828 ], [ 29.768115696202532, -1.479073793103448 ], [ 29.768385189873417, -1.479073793103448 ], [ 29.768385189873417, -1.478804310344828 ], [ 29.768115696202532, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30026, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.478804310344828 ], [ 29.768385189873417, -1.479073793103448 ], [ 29.76892417721519, -1.479073793103448 ], [ 29.76892417721519, -1.478804310344828 ], [ 29.768385189873417, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30027, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.478804310344828 ], [ 29.76892417721519, -1.479073793103448 ], [ 29.770002151898733, -1.479073793103448 ], [ 29.770002151898733, -1.478804310344828 ], [ 29.76892417721519, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30028, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.478804310344828 ], [ 29.770002151898733, -1.479073793103448 ], [ 29.770541139240507, -1.479073793103448 ], [ 29.770541139240507, -1.478804310344828 ], [ 29.770002151898733, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30029, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.478804310344828 ], [ 29.770541139240507, -1.479073793103448 ], [ 29.770810632911392, -1.479073793103448 ], [ 29.770810632911392, -1.478804310344828 ], [ 29.770541139240507, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30030, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.478804310344828 ], [ 29.770810632911392, -1.479073793103448 ], [ 29.771080126582277, -1.479073793103448 ], [ 29.771080126582277, -1.478804310344828 ], [ 29.770810632911392, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30031, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.478804310344828 ], [ 29.771080126582277, -1.479073793103448 ], [ 29.771349620253165, -1.479073793103448 ], [ 29.771349620253165, -1.478804310344828 ], [ 29.771080126582277, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30032, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.478804310344828 ], [ 29.771349620253165, -1.479073793103448 ], [ 29.77161911392405, -1.479073793103448 ], [ 29.77161911392405, -1.478804310344828 ], [ 29.771349620253165, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30033, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.478804310344828 ], [ 29.77161911392405, -1.479073793103448 ], [ 29.771888607594935, -1.479073793103448 ], [ 29.771888607594935, -1.478804310344828 ], [ 29.77161911392405, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30034, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.478804310344828 ], [ 29.771888607594935, -1.479073793103448 ], [ 29.772158101265823, -1.479073793103448 ], [ 29.772158101265823, -1.478804310344828 ], [ 29.771888607594935, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30035, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.478804310344828 ], [ 29.772158101265823, -1.479073793103448 ], [ 29.772427594936708, -1.479073793103448 ], [ 29.772427594936708, -1.478804310344828 ], [ 29.772158101265823, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30036, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.478804310344828 ], [ 29.772427594936708, -1.479073793103448 ], [ 29.772697088607593, -1.479073793103448 ], [ 29.772697088607593, -1.478804310344828 ], [ 29.772427594936708, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30037, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.478804310344828 ], [ 29.772697088607593, -1.479073793103448 ], [ 29.772966582278482, -1.479073793103448 ], [ 29.772966582278482, -1.478804310344828 ], [ 29.772697088607593, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30038, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.478804310344828 ], [ 29.772966582278482, -1.479073793103448 ], [ 29.773236075949367, -1.479073793103448 ], [ 29.773236075949367, -1.478804310344828 ], [ 29.772966582278482, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30039, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.478804310344828 ], [ 29.773236075949367, -1.479073793103448 ], [ 29.773505569620252, -1.479073793103448 ], [ 29.773505569620252, -1.478804310344828 ], [ 29.773236075949367, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30040, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.478804310344828 ], [ 29.773505569620252, -1.479073793103448 ], [ 29.77377506329114, -1.479073793103448 ], [ 29.77377506329114, -1.478804310344828 ], [ 29.773505569620252, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30041, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.478804310344828 ], [ 29.77377506329114, -1.479073793103448 ], [ 29.774044556962025, -1.479073793103448 ], [ 29.774044556962025, -1.478804310344828 ], [ 29.77377506329114, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30042, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.478804310344828 ], [ 29.774044556962025, -1.479073793103448 ], [ 29.77431405063291, -1.479073793103448 ], [ 29.77431405063291, -1.478804310344828 ], [ 29.774044556962025, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30043, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.478804310344828 ], [ 29.77431405063291, -1.479073793103448 ], [ 29.774853037974683, -1.479073793103448 ], [ 29.774853037974683, -1.478804310344828 ], [ 29.77431405063291, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30044, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.478804310344828 ], [ 29.774853037974683, -1.479073793103448 ], [ 29.775122531645568, -1.479073793103448 ], [ 29.775122531645568, -1.478804310344828 ], [ 29.774853037974683, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30045, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.478804310344828 ], [ 29.775122531645568, -1.479073793103448 ], [ 29.775392025316457, -1.479073793103448 ], [ 29.775392025316457, -1.478804310344828 ], [ 29.775122531645568, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30046, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.478804310344828 ], [ 29.775392025316457, -1.479073793103448 ], [ 29.775661518987341, -1.479073793103448 ], [ 29.775661518987341, -1.478804310344828 ], [ 29.775392025316457, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30047, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.478804310344828 ], [ 29.775661518987341, -1.479073793103448 ], [ 29.775931012658226, -1.479073793103448 ], [ 29.775931012658226, -1.478804310344828 ], [ 29.775661518987341, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30048, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.478804310344828 ], [ 29.775931012658226, -1.479073793103448 ], [ 29.776200506329115, -1.479073793103448 ], [ 29.776200506329115, -1.478804310344828 ], [ 29.775931012658226, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30049, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.478804310344828 ], [ 29.776200506329115, -1.479073793103448 ], [ 29.77647, -1.479073793103448 ], [ 29.77647, -1.478804310344828 ], [ 29.776200506329115, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30050, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.478804310344828 ], [ 29.77647, -1.479073793103448 ], [ 29.776739493670885, -1.479073793103448 ], [ 29.776739493670885, -1.478804310344828 ], [ 29.77647, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30051, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.478804310344828 ], [ 29.776739493670885, -1.479073793103448 ], [ 29.777008987341773, -1.479073793103448 ], [ 29.777008987341773, -1.478804310344828 ], [ 29.776739493670885, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30052, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.478804310344828 ], [ 29.777008987341773, -1.479073793103448 ], [ 29.777278481012658, -1.479073793103448 ], [ 29.777278481012658, -1.478804310344828 ], [ 29.777008987341773, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30053, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.478804310344828 ], [ 29.777278481012658, -1.479073793103448 ], [ 29.777547974683543, -1.479073793103448 ], [ 29.777547974683543, -1.478804310344828 ], [ 29.777278481012658, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30054, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.478804310344828 ], [ 29.777547974683543, -1.479073793103448 ], [ 29.777817468354431, -1.479073793103448 ], [ 29.777817468354431, -1.478804310344828 ], [ 29.777547974683543, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30055, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.478804310344828 ], [ 29.777817468354431, -1.479073793103448 ], [ 29.778356455696201, -1.479073793103448 ], [ 29.778356455696201, -1.478804310344828 ], [ 29.777817468354431, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30056, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.478804310344828 ], [ 29.778356455696201, -1.479073793103448 ], [ 29.77862594936709, -1.479073793103448 ], [ 29.77862594936709, -1.478804310344828 ], [ 29.778356455696201, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30057, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.478804310344828 ], [ 29.77862594936709, -1.479073793103448 ], [ 29.778895443037975, -1.479073793103448 ], [ 29.778895443037975, -1.478804310344828 ], [ 29.77862594936709, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30058, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.478804310344828 ], [ 29.778895443037975, -1.479073793103448 ], [ 29.77916493670886, -1.479073793103448 ], [ 29.77916493670886, -1.478804310344828 ], [ 29.778895443037975, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30059, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.478804310344828 ], [ 29.77916493670886, -1.479073793103448 ], [ 29.779434430379748, -1.479073793103448 ], [ 29.779434430379748, -1.478804310344828 ], [ 29.77916493670886, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30060, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.478804310344828 ], [ 29.779434430379748, -1.479073793103448 ], [ 29.779703924050633, -1.479073793103448 ], [ 29.779703924050633, -1.478804310344828 ], [ 29.779434430379748, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30061, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.478804310344828 ], [ 29.779703924050633, -1.479073793103448 ], [ 29.779973417721518, -1.479073793103448 ], [ 29.779973417721518, -1.478804310344828 ], [ 29.779703924050633, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30062, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.478804310344828 ], [ 29.779973417721518, -1.479073793103448 ], [ 29.780242911392406, -1.479073793103448 ], [ 29.780242911392406, -1.478804310344828 ], [ 29.779973417721518, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30063, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.478804310344828 ], [ 29.780242911392406, -1.479073793103448 ], [ 29.780512405063291, -1.479073793103448 ], [ 29.780512405063291, -1.478804310344828 ], [ 29.780242911392406, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30064, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.478804310344828 ], [ 29.780512405063291, -1.479073793103448 ], [ 29.780781898734176, -1.479073793103448 ], [ 29.780781898734176, -1.478804310344828 ], [ 29.780512405063291, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30065, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.478804310344828 ], [ 29.781051392405065, -1.479073793103448 ], [ 29.78132088607595, -1.479073793103448 ], [ 29.78132088607595, -1.478804310344828 ], [ 29.781051392405065, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30066, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.478804310344828 ], [ 29.78132088607595, -1.479073793103448 ], [ 29.781590379746834, -1.479073793103448 ], [ 29.781590379746834, -1.478804310344828 ], [ 29.78132088607595, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30067, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.478804310344828 ], [ 29.781590379746834, -1.479073793103448 ], [ 29.781859873417723, -1.479073793103448 ], [ 29.781859873417723, -1.478804310344828 ], [ 29.781590379746834, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30068, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.478804310344828 ], [ 29.781859873417723, -1.479073793103448 ], [ 29.782129367088608, -1.479073793103448 ], [ 29.782129367088608, -1.478804310344828 ], [ 29.781859873417723, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30069, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.478804310344828 ], [ 29.782129367088608, -1.479073793103448 ], [ 29.782398860759493, -1.479073793103448 ], [ 29.782398860759493, -1.478804310344828 ], [ 29.782129367088608, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30070, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.478804310344828 ], [ 29.782398860759493, -1.479073793103448 ], [ 29.782668354430381, -1.479073793103448 ], [ 29.782668354430381, -1.478804310344828 ], [ 29.782398860759493, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30071, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.478804310344828 ], [ 29.782668354430381, -1.479073793103448 ], [ 29.782937848101266, -1.479073793103448 ], [ 29.782937848101266, -1.478804310344828 ], [ 29.782668354430381, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30072, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.478804310344828 ], [ 29.782937848101266, -1.479073793103448 ], [ 29.783207341772151, -1.479073793103448 ], [ 29.783207341772151, -1.478804310344828 ], [ 29.782937848101266, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30073, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.478804310344828 ], [ 29.783207341772151, -1.479073793103448 ], [ 29.783476835443039, -1.479073793103448 ], [ 29.783476835443039, -1.478804310344828 ], [ 29.783207341772151, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30074, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.478804310344828 ], [ 29.783476835443039, -1.479073793103448 ], [ 29.784015822784809, -1.479073793103448 ], [ 29.784015822784809, -1.478804310344828 ], [ 29.783476835443039, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30075, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.478804310344828 ], [ 29.784015822784809, -1.479073793103448 ], [ 29.784285316455698, -1.479073793103448 ], [ 29.784285316455698, -1.478804310344828 ], [ 29.784015822784809, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30076, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.478804310344828 ], [ 29.784285316455698, -1.479073793103448 ], [ 29.784554810126583, -1.479073793103448 ], [ 29.784554810126583, -1.478804310344828 ], [ 29.784285316455698, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30077, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.478804310344828 ], [ 29.784554810126583, -1.479073793103448 ], [ 29.784824303797468, -1.479073793103448 ], [ 29.784824303797468, -1.478804310344828 ], [ 29.784554810126583, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30078, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.478804310344828 ], [ 29.784824303797468, -1.479073793103448 ], [ 29.785093797468356, -1.479073793103448 ], [ 29.785093797468356, -1.478804310344828 ], [ 29.784824303797468, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30079, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.478804310344828 ], [ 29.785093797468356, -1.479073793103448 ], [ 29.785363291139241, -1.479073793103448 ], [ 29.785363291139241, -1.478804310344828 ], [ 29.785093797468356, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30080, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.478804310344828 ], [ 29.785363291139241, -1.479073793103448 ], [ 29.785632784810126, -1.479073793103448 ], [ 29.785632784810126, -1.478804310344828 ], [ 29.785363291139241, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30081, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.478804310344828 ], [ 29.785632784810126, -1.479073793103448 ], [ 29.785902278481014, -1.479073793103448 ], [ 29.785902278481014, -1.478804310344828 ], [ 29.785632784810126, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30082, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.478804310344828 ], [ 29.785902278481014, -1.479073793103448 ], [ 29.787249746835442, -1.479073793103448 ], [ 29.787249746835442, -1.478804310344828 ], [ 29.785902278481014, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30083, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.478804310344828 ], [ 29.787249746835442, -1.479073793103448 ], [ 29.787519240506327, -1.479073793103448 ], [ 29.787519240506327, -1.478804310344828 ], [ 29.787249746835442, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30084, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.478804310344828 ], [ 29.787519240506327, -1.479073793103448 ], [ 29.788058227848101, -1.479073793103448 ], [ 29.788058227848101, -1.478804310344828 ], [ 29.787519240506327, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30085, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.478804310344828 ], [ 29.788058227848101, -1.479073793103448 ], [ 29.788327721518986, -1.479073793103448 ], [ 29.788327721518986, -1.478804310344828 ], [ 29.788058227848101, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30086, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.478804310344828 ], [ 29.788327721518986, -1.479073793103448 ], [ 29.788866708860759, -1.479073793103448 ], [ 29.788866708860759, -1.478804310344828 ], [ 29.788327721518986, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30087, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.478804310344828 ], [ 29.789405696202532, -1.47988224137931 ], [ 29.789675189873417, -1.47988224137931 ], [ 29.789675189873417, -1.478804310344828 ], [ 29.789405696202532, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30088, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.478804310344828 ], [ 29.789675189873417, -1.479073793103448 ], [ 29.789944683544302, -1.479073793103448 ], [ 29.789944683544302, -1.478804310344828 ], [ 29.789675189873417, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30089, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.478804310344828 ], [ 29.789944683544302, -1.479073793103448 ], [ 29.790214177215191, -1.479073793103448 ], [ 29.790214177215191, -1.478804310344828 ], [ 29.789944683544302, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30090, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.478804310344828 ], [ 29.790214177215191, -1.479073793103448 ], [ 29.790483670886076, -1.479073793103448 ], [ 29.790483670886076, -1.478804310344828 ], [ 29.790214177215191, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30091, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.477187413793104 ], [ 29.791022658227849, -1.477187413793104 ], [ 29.791022658227849, -1.479073793103448 ], [ 29.790483670886076, -1.479073793103448 ], [ 29.790483670886076, -1.478804310344828 ], [ 29.790753164556961, -1.478804310344828 ], [ 29.790753164556961, -1.477187413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30092, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.478804310344828 ], [ 29.803149873417723, -1.479073793103448 ], [ 29.803419367088608, -1.479073793103448 ], [ 29.803419367088608, -1.478804310344828 ], [ 29.803149873417723, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30093, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.478804310344828 ], [ 29.803419367088608, -1.479073793103448 ], [ 29.803688860759493, -1.479073793103448 ], [ 29.803688860759493, -1.478804310344828 ], [ 29.803419367088608, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30094, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.478804310344828 ], [ 29.803688860759493, -1.479073793103448 ], [ 29.803958354430382, -1.479073793103448 ], [ 29.803958354430382, -1.478804310344828 ], [ 29.803688860759493, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30095, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761108860759492, -1.479073793103448 ], [ 29.761108860759492, -1.47961275862069 ], [ 29.761378354430381, -1.47961275862069 ], [ 29.761378354430381, -1.479073793103448 ], [ 29.761108860759492, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30096, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.479073793103448 ], [ 29.761378354430381, -1.479343275862069 ], [ 29.761647848101266, -1.479343275862069 ], [ 29.761647848101266, -1.479073793103448 ], [ 29.761378354430381, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30097, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.479073793103448 ], [ 29.761647848101266, -1.479343275862069 ], [ 29.761917341772151, -1.479343275862069 ], [ 29.761917341772151, -1.479073793103448 ], [ 29.761647848101266, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30098, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.479073793103448 ], [ 29.761917341772151, -1.479343275862069 ], [ 29.762186835443039, -1.479343275862069 ], [ 29.762186835443039, -1.479073793103448 ], [ 29.761917341772151, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30099, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.479073793103448 ], [ 29.762186835443039, -1.479343275862069 ], [ 29.762456329113924, -1.479343275862069 ], [ 29.762456329113924, -1.479073793103448 ], [ 29.762186835443039, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30100, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.479073793103448 ], [ 29.762456329113924, -1.479343275862069 ], [ 29.762725822784809, -1.479343275862069 ], [ 29.762725822784809, -1.479073793103448 ], [ 29.762456329113924, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30101, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.479073793103448 ], [ 29.762725822784809, -1.47961275862069 ], [ 29.762995316455697, -1.47961275862069 ], [ 29.762995316455697, -1.479073793103448 ], [ 29.762725822784809, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30102, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.479073793103448 ], [ 29.762995316455697, -1.479343275862069 ], [ 29.763264810126582, -1.479343275862069 ], [ 29.763264810126582, -1.479073793103448 ], [ 29.762995316455697, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30103, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.479073793103448 ], [ 29.763264810126582, -1.479343275862069 ], [ 29.763534303797467, -1.479343275862069 ], [ 29.763534303797467, -1.479073793103448 ], [ 29.763264810126582, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30104, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.479073793103448 ], [ 29.763534303797467, -1.47961275862069 ], [ 29.763803797468356, -1.47961275862069 ], [ 29.763803797468356, -1.479073793103448 ], [ 29.763534303797467, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30105, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.479073793103448 ], [ 29.76407329113924, -1.479343275862069 ], [ 29.764342784810125, -1.479343275862069 ], [ 29.764342784810125, -1.479073793103448 ], [ 29.76407329113924, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30106, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.479073793103448 ], [ 29.764342784810125, -1.479343275862069 ], [ 29.764612278481014, -1.479343275862069 ], [ 29.764612278481014, -1.479073793103448 ], [ 29.764342784810125, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30107, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.479073793103448 ], [ 29.764612278481014, -1.479343275862069 ], [ 29.764881772151899, -1.479343275862069 ], [ 29.764881772151899, -1.479073793103448 ], [ 29.764612278481014, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30108, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.479073793103448 ], [ 29.764881772151899, -1.479343275862069 ], [ 29.765151265822784, -1.479343275862069 ], [ 29.765151265822784, -1.479073793103448 ], [ 29.764881772151899, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30109, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.479073793103448 ], [ 29.765151265822784, -1.479343275862069 ], [ 29.765420759493672, -1.479343275862069 ], [ 29.765420759493672, -1.479073793103448 ], [ 29.765151265822784, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30110, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.479073793103448 ], [ 29.765420759493672, -1.479343275862069 ], [ 29.765959746835442, -1.479343275862069 ], [ 29.765959746835442, -1.479073793103448 ], [ 29.765420759493672, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30111, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.479073793103448 ], [ 29.765959746835442, -1.479343275862069 ], [ 29.766229240506327, -1.479343275862069 ], [ 29.766229240506327, -1.479073793103448 ], [ 29.765959746835442, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30112, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.479073793103448 ], [ 29.766229240506327, -1.479343275862069 ], [ 29.766498734177215, -1.479343275862069 ], [ 29.766498734177215, -1.479073793103448 ], [ 29.766229240506327, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30113, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.479073793103448 ], [ 29.766498734177215, -1.479343275862069 ], [ 29.7667682278481, -1.479343275862069 ], [ 29.7667682278481, -1.479073793103448 ], [ 29.766498734177215, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30114, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.479073793103448 ], [ 29.7667682278481, -1.479343275862069 ], [ 29.767037721518985, -1.479343275862069 ], [ 29.767037721518985, -1.479073793103448 ], [ 29.7667682278481, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30115, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.479073793103448 ], [ 29.767037721518985, -1.479343275862069 ], [ 29.767307215189874, -1.479343275862069 ], [ 29.767307215189874, -1.479073793103448 ], [ 29.767037721518985, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30116, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.479073793103448 ], [ 29.767307215189874, -1.479343275862069 ], [ 29.767576708860759, -1.479343275862069 ], [ 29.767576708860759, -1.479073793103448 ], [ 29.767307215189874, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30117, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.479073793103448 ], [ 29.767576708860759, -1.479343275862069 ], [ 29.767846202531643, -1.479343275862069 ], [ 29.767846202531643, -1.479073793103448 ], [ 29.767576708860759, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30118, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.479073793103448 ], [ 29.767846202531643, -1.479343275862069 ], [ 29.768115696202532, -1.479343275862069 ], [ 29.768115696202532, -1.479073793103448 ], [ 29.767846202531643, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30119, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.479073793103448 ], [ 29.768115696202532, -1.479343275862069 ], [ 29.768385189873417, -1.479343275862069 ], [ 29.768385189873417, -1.479073793103448 ], [ 29.768115696202532, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30120, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.479073793103448 ], [ 29.768385189873417, -1.479343275862069 ], [ 29.768654683544302, -1.479343275862069 ], [ 29.768654683544302, -1.479073793103448 ], [ 29.768385189873417, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30121, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.479073793103448 ], [ 29.768654683544302, -1.479343275862069 ], [ 29.770271645569618, -1.479343275862069 ], [ 29.770271645569618, -1.479073793103448 ], [ 29.768654683544302, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30122, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.479073793103448 ], [ 29.770271645569618, -1.479343275862069 ], [ 29.770541139240507, -1.479343275862069 ], [ 29.770541139240507, -1.479073793103448 ], [ 29.770271645569618, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30123, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.479073793103448 ], [ 29.770541139240507, -1.479343275862069 ], [ 29.770810632911392, -1.479343275862069 ], [ 29.770810632911392, -1.479073793103448 ], [ 29.770541139240507, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30124, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.479073793103448 ], [ 29.770810632911392, -1.479343275862069 ], [ 29.771080126582277, -1.479343275862069 ], [ 29.771080126582277, -1.479073793103448 ], [ 29.770810632911392, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30125, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.479073793103448 ], [ 29.771080126582277, -1.479343275862069 ], [ 29.771349620253165, -1.479343275862069 ], [ 29.771349620253165, -1.479073793103448 ], [ 29.771080126582277, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30126, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.479073793103448 ], [ 29.771349620253165, -1.479343275862069 ], [ 29.77161911392405, -1.479343275862069 ], [ 29.77161911392405, -1.479073793103448 ], [ 29.771349620253165, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30127, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.479073793103448 ], [ 29.77161911392405, -1.479343275862069 ], [ 29.771888607594935, -1.479343275862069 ], [ 29.771888607594935, -1.479073793103448 ], [ 29.77161911392405, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30128, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.479073793103448 ], [ 29.771888607594935, -1.479343275862069 ], [ 29.772158101265823, -1.479343275862069 ], [ 29.772158101265823, -1.479073793103448 ], [ 29.771888607594935, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30129, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.479073793103448 ], [ 29.772158101265823, -1.479343275862069 ], [ 29.772427594936708, -1.479343275862069 ], [ 29.772427594936708, -1.479073793103448 ], [ 29.772158101265823, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30130, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.479073793103448 ], [ 29.772427594936708, -1.479343275862069 ], [ 29.772697088607593, -1.479343275862069 ], [ 29.772697088607593, -1.479073793103448 ], [ 29.772427594936708, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30131, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.479073793103448 ], [ 29.772697088607593, -1.479343275862069 ], [ 29.772966582278482, -1.479343275862069 ], [ 29.772966582278482, -1.479073793103448 ], [ 29.772697088607593, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30132, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.479073793103448 ], [ 29.772966582278482, -1.479343275862069 ], [ 29.773236075949367, -1.479343275862069 ], [ 29.773236075949367, -1.479073793103448 ], [ 29.772966582278482, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30133, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.479073793103448 ], [ 29.773236075949367, -1.479343275862069 ], [ 29.773505569620252, -1.479343275862069 ], [ 29.773505569620252, -1.479073793103448 ], [ 29.773236075949367, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30134, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.479073793103448 ], [ 29.773505569620252, -1.479343275862069 ], [ 29.77377506329114, -1.479343275862069 ], [ 29.77377506329114, -1.479073793103448 ], [ 29.773505569620252, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30135, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.479073793103448 ], [ 29.77377506329114, -1.479343275862069 ], [ 29.774044556962025, -1.479343275862069 ], [ 29.774044556962025, -1.479073793103448 ], [ 29.77377506329114, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30136, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.479073793103448 ], [ 29.774044556962025, -1.479343275862069 ], [ 29.77431405063291, -1.479343275862069 ], [ 29.77431405063291, -1.479073793103448 ], [ 29.774044556962025, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30137, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.479073793103448 ], [ 29.77431405063291, -1.479343275862069 ], [ 29.774853037974683, -1.479343275862069 ], [ 29.774853037974683, -1.479073793103448 ], [ 29.77431405063291, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30138, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.479073793103448 ], [ 29.774853037974683, -1.479343275862069 ], [ 29.775122531645568, -1.479343275862069 ], [ 29.775122531645568, -1.479073793103448 ], [ 29.774853037974683, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30139, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.479073793103448 ], [ 29.775122531645568, -1.479343275862069 ], [ 29.775392025316457, -1.479343275862069 ], [ 29.775392025316457, -1.479073793103448 ], [ 29.775122531645568, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30140, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.479073793103448 ], [ 29.775392025316457, -1.479343275862069 ], [ 29.775661518987341, -1.479343275862069 ], [ 29.775661518987341, -1.479073793103448 ], [ 29.775392025316457, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30141, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.479073793103448 ], [ 29.775661518987341, -1.479343275862069 ], [ 29.775931012658226, -1.479343275862069 ], [ 29.775931012658226, -1.479073793103448 ], [ 29.775661518987341, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30142, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.479073793103448 ], [ 29.775931012658226, -1.479343275862069 ], [ 29.776200506329115, -1.479343275862069 ], [ 29.776200506329115, -1.479073793103448 ], [ 29.775931012658226, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30143, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.479073793103448 ], [ 29.776200506329115, -1.479343275862069 ], [ 29.77647, -1.479343275862069 ], [ 29.77647, -1.479073793103448 ], [ 29.776200506329115, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30144, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.479073793103448 ], [ 29.77647, -1.479343275862069 ], [ 29.776739493670885, -1.479343275862069 ], [ 29.776739493670885, -1.479073793103448 ], [ 29.77647, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30145, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.479073793103448 ], [ 29.776739493670885, -1.479343275862069 ], [ 29.777008987341773, -1.479343275862069 ], [ 29.777008987341773, -1.479073793103448 ], [ 29.776739493670885, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30146, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.479073793103448 ], [ 29.777008987341773, -1.479343275862069 ], [ 29.777278481012658, -1.479343275862069 ], [ 29.777278481012658, -1.479073793103448 ], [ 29.777008987341773, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30147, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.479073793103448 ], [ 29.777278481012658, -1.479343275862069 ], [ 29.777547974683543, -1.479343275862069 ], [ 29.777547974683543, -1.479073793103448 ], [ 29.777278481012658, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30148, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.479073793103448 ], [ 29.777547974683543, -1.479343275862069 ], [ 29.777817468354431, -1.479343275862069 ], [ 29.777817468354431, -1.479073793103448 ], [ 29.777547974683543, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30149, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.479073793103448 ], [ 29.777817468354431, -1.47961275862069 ], [ 29.778086962025316, -1.47961275862069 ], [ 29.778086962025316, -1.479073793103448 ], [ 29.777817468354431, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30150, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.479073793103448 ], [ 29.778086962025316, -1.479343275862069 ], [ 29.778356455696201, -1.479343275862069 ], [ 29.778356455696201, -1.479073793103448 ], [ 29.778086962025316, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30151, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.479073793103448 ], [ 29.778356455696201, -1.479343275862069 ], [ 29.77862594936709, -1.479343275862069 ], [ 29.77862594936709, -1.479073793103448 ], [ 29.778356455696201, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30152, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.479073793103448 ], [ 29.77862594936709, -1.479343275862069 ], [ 29.778895443037975, -1.479343275862069 ], [ 29.778895443037975, -1.479073793103448 ], [ 29.77862594936709, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30153, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.479073793103448 ], [ 29.778895443037975, -1.479343275862069 ], [ 29.77916493670886, -1.479343275862069 ], [ 29.77916493670886, -1.479073793103448 ], [ 29.778895443037975, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30154, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.479073793103448 ], [ 29.77916493670886, -1.479343275862069 ], [ 29.779434430379748, -1.479343275862069 ], [ 29.779434430379748, -1.479073793103448 ], [ 29.77916493670886, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30155, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.479073793103448 ], [ 29.779434430379748, -1.479343275862069 ], [ 29.779703924050633, -1.479343275862069 ], [ 29.779703924050633, -1.479073793103448 ], [ 29.779434430379748, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30156, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.479073793103448 ], [ 29.779703924050633, -1.479343275862069 ], [ 29.779973417721518, -1.479343275862069 ], [ 29.779973417721518, -1.479073793103448 ], [ 29.779703924050633, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30157, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.479073793103448 ], [ 29.779973417721518, -1.479343275862069 ], [ 29.780242911392406, -1.479343275862069 ], [ 29.780242911392406, -1.479073793103448 ], [ 29.779973417721518, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30158, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.479073793103448 ], [ 29.780242911392406, -1.479343275862069 ], [ 29.780512405063291, -1.479343275862069 ], [ 29.780512405063291, -1.479073793103448 ], [ 29.780242911392406, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30159, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.479073793103448 ], [ 29.780512405063291, -1.479343275862069 ], [ 29.780781898734176, -1.479343275862069 ], [ 29.780781898734176, -1.479073793103448 ], [ 29.780512405063291, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30160, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.479073793103448 ], [ 29.780781898734176, -1.479343275862069 ], [ 29.781051392405065, -1.479343275862069 ], [ 29.781051392405065, -1.479073793103448 ], [ 29.780781898734176, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30161, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.479073793103448 ], [ 29.781051392405065, -1.479343275862069 ], [ 29.78132088607595, -1.479343275862069 ], [ 29.78132088607595, -1.479073793103448 ], [ 29.781051392405065, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30162, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.479073793103448 ], [ 29.78132088607595, -1.479343275862069 ], [ 29.781590379746834, -1.479343275862069 ], [ 29.781590379746834, -1.479073793103448 ], [ 29.78132088607595, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30163, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.479073793103448 ], [ 29.781590379746834, -1.479343275862069 ], [ 29.781859873417723, -1.479343275862069 ], [ 29.781859873417723, -1.479073793103448 ], [ 29.781590379746834, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30164, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.479073793103448 ], [ 29.781859873417723, -1.47961275862069 ], [ 29.782129367088608, -1.47961275862069 ], [ 29.782129367088608, -1.479073793103448 ], [ 29.781859873417723, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30165, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.479073793103448 ], [ 29.782129367088608, -1.479343275862069 ], [ 29.782398860759493, -1.479343275862069 ], [ 29.782398860759493, -1.479073793103448 ], [ 29.782129367088608, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30166, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.479073793103448 ], [ 29.782398860759493, -1.479343275862069 ], [ 29.782668354430381, -1.479343275862069 ], [ 29.782668354430381, -1.479073793103448 ], [ 29.782398860759493, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30167, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.479073793103448 ], [ 29.782668354430381, -1.479343275862069 ], [ 29.782937848101266, -1.479343275862069 ], [ 29.782937848101266, -1.479073793103448 ], [ 29.782668354430381, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30168, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.479073793103448 ], [ 29.782937848101266, -1.479343275862069 ], [ 29.783207341772151, -1.479343275862069 ], [ 29.783207341772151, -1.479073793103448 ], [ 29.782937848101266, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30169, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.479073793103448 ], [ 29.783207341772151, -1.479343275862069 ], [ 29.783476835443039, -1.479343275862069 ], [ 29.783476835443039, -1.479073793103448 ], [ 29.783207341772151, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30170, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.479073793103448 ], [ 29.783476835443039, -1.479343275862069 ], [ 29.784015822784809, -1.479343275862069 ], [ 29.784015822784809, -1.479073793103448 ], [ 29.783476835443039, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30171, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.479073793103448 ], [ 29.784015822784809, -1.479343275862069 ], [ 29.784285316455698, -1.479343275862069 ], [ 29.784285316455698, -1.479073793103448 ], [ 29.784015822784809, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30172, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.479073793103448 ], [ 29.784285316455698, -1.479343275862069 ], [ 29.784554810126583, -1.479343275862069 ], [ 29.784554810126583, -1.479073793103448 ], [ 29.784285316455698, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30173, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.479073793103448 ], [ 29.784554810126583, -1.479343275862069 ], [ 29.784824303797468, -1.479343275862069 ], [ 29.784824303797468, -1.479073793103448 ], [ 29.784554810126583, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30174, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.479073793103448 ], [ 29.784824303797468, -1.479343275862069 ], [ 29.785093797468356, -1.479343275862069 ], [ 29.785093797468356, -1.479073793103448 ], [ 29.784824303797468, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30175, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.479073793103448 ], [ 29.785093797468356, -1.479343275862069 ], [ 29.785363291139241, -1.479343275862069 ], [ 29.785363291139241, -1.479073793103448 ], [ 29.785093797468356, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30176, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.479073793103448 ], [ 29.785363291139241, -1.479343275862069 ], [ 29.785632784810126, -1.479343275862069 ], [ 29.785632784810126, -1.479073793103448 ], [ 29.785363291139241, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30177, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.479073793103448 ], [ 29.785632784810126, -1.479343275862069 ], [ 29.785902278481014, -1.479343275862069 ], [ 29.785902278481014, -1.479073793103448 ], [ 29.785632784810126, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30178, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.479073793103448 ], [ 29.785902278481014, -1.479343275862069 ], [ 29.786171772151899, -1.479343275862069 ], [ 29.786171772151899, -1.479073793103448 ], [ 29.785902278481014, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30179, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.479073793103448 ], [ 29.786171772151899, -1.479343275862069 ], [ 29.786980253164558, -1.479343275862069 ], [ 29.786980253164558, -1.479073793103448 ], [ 29.786171772151899, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30180, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.479073793103448 ], [ 29.786980253164558, -1.479343275862069 ], [ 29.787249746835442, -1.479343275862069 ], [ 29.787249746835442, -1.479073793103448 ], [ 29.786980253164558, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30181, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.479073793103448 ], [ 29.787249746835442, -1.479343275862069 ], [ 29.787519240506327, -1.479343275862069 ], [ 29.787519240506327, -1.479073793103448 ], [ 29.787249746835442, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30182, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.479073793103448 ], [ 29.787519240506327, -1.479343275862069 ], [ 29.788058227848101, -1.479343275862069 ], [ 29.788058227848101, -1.479073793103448 ], [ 29.787519240506327, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30183, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.479073793103448 ], [ 29.788058227848101, -1.479343275862069 ], [ 29.788327721518986, -1.479343275862069 ], [ 29.788327721518986, -1.479073793103448 ], [ 29.788058227848101, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30184, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.478804310344828 ], [ 29.789405696202532, -1.478804310344828 ], [ 29.789405696202532, -1.479343275862069 ], [ 29.788327721518986, -1.479343275862069 ], [ 29.788327721518986, -1.479073793103448 ], [ 29.789136202531644, -1.479073793103448 ], [ 29.789136202531644, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30185, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.479073793103448 ], [ 29.789675189873417, -1.47961275862069 ], [ 29.789944683544302, -1.47961275862069 ], [ 29.789944683544302, -1.479073793103448 ], [ 29.789675189873417, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30186, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.479073793103448 ], [ 29.789944683544302, -1.47961275862069 ], [ 29.790214177215191, -1.47961275862069 ], [ 29.790214177215191, -1.479073793103448 ], [ 29.789944683544302, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30187, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.479073793103448 ], [ 29.790214177215191, -1.47961275862069 ], [ 29.790483670886076, -1.47961275862069 ], [ 29.790483670886076, -1.479073793103448 ], [ 29.790214177215191, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30188, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.479343275862069 ], [ 29.761378354430381, -1.47961275862069 ], [ 29.761647848101266, -1.47961275862069 ], [ 29.761647848101266, -1.479343275862069 ], [ 29.761378354430381, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30189, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.479343275862069 ], [ 29.761647848101266, -1.47961275862069 ], [ 29.761917341772151, -1.47961275862069 ], [ 29.761917341772151, -1.479343275862069 ], [ 29.761647848101266, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30190, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.479343275862069 ], [ 29.761917341772151, -1.47961275862069 ], [ 29.762186835443039, -1.47961275862069 ], [ 29.762186835443039, -1.479343275862069 ], [ 29.761917341772151, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30191, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.479343275862069 ], [ 29.762186835443039, -1.480151724137931 ], [ 29.762456329113924, -1.480151724137931 ], [ 29.762456329113924, -1.479343275862069 ], [ 29.762186835443039, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30192, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.479343275862069 ], [ 29.762456329113924, -1.480151724137931 ], [ 29.762725822784809, -1.480151724137931 ], [ 29.762725822784809, -1.479343275862069 ], [ 29.762456329113924, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30193, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.479343275862069 ], [ 29.762995316455697, -1.47988224137931 ], [ 29.763264810126582, -1.47988224137931 ], [ 29.763264810126582, -1.479343275862069 ], [ 29.762995316455697, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30194, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.479343275862069 ], [ 29.763264810126582, -1.47988224137931 ], [ 29.763534303797467, -1.47988224137931 ], [ 29.763534303797467, -1.479343275862069 ], [ 29.763264810126582, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30195, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.479343275862069 ], [ 29.763803797468356, -1.47961275862069 ], [ 29.76407329113924, -1.47961275862069 ], [ 29.76407329113924, -1.479343275862069 ], [ 29.763803797468356, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30196, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.479343275862069 ], [ 29.76407329113924, -1.47961275862069 ], [ 29.764342784810125, -1.47961275862069 ], [ 29.764342784810125, -1.479343275862069 ], [ 29.76407329113924, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30197, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.479343275862069 ], [ 29.764342784810125, -1.47961275862069 ], [ 29.764612278481014, -1.47961275862069 ], [ 29.764612278481014, -1.479343275862069 ], [ 29.764342784810125, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30198, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.479343275862069 ], [ 29.764612278481014, -1.47961275862069 ], [ 29.764881772151899, -1.47961275862069 ], [ 29.764881772151899, -1.479343275862069 ], [ 29.764612278481014, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30199, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.479343275862069 ], [ 29.764881772151899, -1.47961275862069 ], [ 29.765151265822784, -1.47961275862069 ], [ 29.765151265822784, -1.479343275862069 ], [ 29.764881772151899, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30200, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.479343275862069 ], [ 29.765151265822784, -1.47961275862069 ], [ 29.765420759493672, -1.47961275862069 ], [ 29.765420759493672, -1.479343275862069 ], [ 29.765151265822784, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30201, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.479343275862069 ], [ 29.765420759493672, -1.47961275862069 ], [ 29.765959746835442, -1.47961275862069 ], [ 29.765959746835442, -1.479343275862069 ], [ 29.765420759493672, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30202, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.479343275862069 ], [ 29.765959746835442, -1.47961275862069 ], [ 29.766229240506327, -1.47961275862069 ], [ 29.766229240506327, -1.479343275862069 ], [ 29.765959746835442, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30203, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.479343275862069 ], [ 29.766229240506327, -1.47961275862069 ], [ 29.766498734177215, -1.47961275862069 ], [ 29.766498734177215, -1.479343275862069 ], [ 29.766229240506327, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30204, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.479343275862069 ], [ 29.766498734177215, -1.47961275862069 ], [ 29.7667682278481, -1.47961275862069 ], [ 29.7667682278481, -1.479343275862069 ], [ 29.766498734177215, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30205, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.479343275862069 ], [ 29.7667682278481, -1.47961275862069 ], [ 29.767037721518985, -1.47961275862069 ], [ 29.767037721518985, -1.479343275862069 ], [ 29.7667682278481, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30206, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.479343275862069 ], [ 29.767037721518985, -1.47961275862069 ], [ 29.767307215189874, -1.47961275862069 ], [ 29.767307215189874, -1.479343275862069 ], [ 29.767037721518985, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30207, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.479343275862069 ], [ 29.767307215189874, -1.47961275862069 ], [ 29.767576708860759, -1.47961275862069 ], [ 29.767576708860759, -1.479343275862069 ], [ 29.767307215189874, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30208, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.479343275862069 ], [ 29.767576708860759, -1.47961275862069 ], [ 29.767846202531643, -1.47961275862069 ], [ 29.767846202531643, -1.479343275862069 ], [ 29.767576708860759, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30209, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.479343275862069 ], [ 29.767846202531643, -1.47961275862069 ], [ 29.768115696202532, -1.47961275862069 ], [ 29.768115696202532, -1.479343275862069 ], [ 29.767846202531643, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30210, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.479343275862069 ], [ 29.768115696202532, -1.47961275862069 ], [ 29.768385189873417, -1.47961275862069 ], [ 29.768385189873417, -1.479343275862069 ], [ 29.768115696202532, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30211, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.479343275862069 ], [ 29.768385189873417, -1.47961275862069 ], [ 29.768654683544302, -1.47961275862069 ], [ 29.768654683544302, -1.479343275862069 ], [ 29.768385189873417, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30212, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.479343275862069 ], [ 29.768654683544302, -1.47961275862069 ], [ 29.770271645569618, -1.47961275862069 ], [ 29.770271645569618, -1.479343275862069 ], [ 29.768654683544302, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30213, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.479343275862069 ], [ 29.770271645569618, -1.47961275862069 ], [ 29.770541139240507, -1.47961275862069 ], [ 29.770541139240507, -1.479343275862069 ], [ 29.770271645569618, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30214, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.479343275862069 ], [ 29.770541139240507, -1.47961275862069 ], [ 29.770810632911392, -1.47961275862069 ], [ 29.770810632911392, -1.479343275862069 ], [ 29.770541139240507, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30215, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.479343275862069 ], [ 29.770810632911392, -1.47961275862069 ], [ 29.771080126582277, -1.47961275862069 ], [ 29.771080126582277, -1.479343275862069 ], [ 29.770810632911392, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30216, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.479343275862069 ], [ 29.771080126582277, -1.47961275862069 ], [ 29.771349620253165, -1.47961275862069 ], [ 29.771349620253165, -1.479343275862069 ], [ 29.771080126582277, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30217, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.479343275862069 ], [ 29.771349620253165, -1.47961275862069 ], [ 29.77161911392405, -1.47961275862069 ], [ 29.77161911392405, -1.479343275862069 ], [ 29.771349620253165, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30218, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.479343275862069 ], [ 29.77161911392405, -1.47961275862069 ], [ 29.771888607594935, -1.47961275862069 ], [ 29.771888607594935, -1.479343275862069 ], [ 29.77161911392405, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30219, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.479343275862069 ], [ 29.771888607594935, -1.47961275862069 ], [ 29.772158101265823, -1.47961275862069 ], [ 29.772158101265823, -1.479343275862069 ], [ 29.771888607594935, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30220, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.479343275862069 ], [ 29.772158101265823, -1.47961275862069 ], [ 29.772427594936708, -1.47961275862069 ], [ 29.772427594936708, -1.479343275862069 ], [ 29.772158101265823, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30221, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.479343275862069 ], [ 29.772427594936708, -1.47961275862069 ], [ 29.772697088607593, -1.47961275862069 ], [ 29.772697088607593, -1.479343275862069 ], [ 29.772427594936708, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30222, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.479343275862069 ], [ 29.772697088607593, -1.47961275862069 ], [ 29.772966582278482, -1.47961275862069 ], [ 29.772966582278482, -1.479343275862069 ], [ 29.772697088607593, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30223, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.479343275862069 ], [ 29.772966582278482, -1.47961275862069 ], [ 29.773236075949367, -1.47961275862069 ], [ 29.773236075949367, -1.479343275862069 ], [ 29.772966582278482, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30224, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.479343275862069 ], [ 29.773236075949367, -1.47961275862069 ], [ 29.773505569620252, -1.47961275862069 ], [ 29.773505569620252, -1.479343275862069 ], [ 29.773236075949367, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30225, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.479343275862069 ], [ 29.773505569620252, -1.47961275862069 ], [ 29.77377506329114, -1.47961275862069 ], [ 29.77377506329114, -1.479343275862069 ], [ 29.773505569620252, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30226, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.479343275862069 ], [ 29.77377506329114, -1.47961275862069 ], [ 29.774044556962025, -1.47961275862069 ], [ 29.774044556962025, -1.479343275862069 ], [ 29.77377506329114, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30227, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.479343275862069 ], [ 29.774044556962025, -1.47961275862069 ], [ 29.77431405063291, -1.47961275862069 ], [ 29.77431405063291, -1.479343275862069 ], [ 29.774044556962025, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30228, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.479343275862069 ], [ 29.77431405063291, -1.47961275862069 ], [ 29.774853037974683, -1.47961275862069 ], [ 29.774853037974683, -1.479343275862069 ], [ 29.77431405063291, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30229, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.479343275862069 ], [ 29.774853037974683, -1.47961275862069 ], [ 29.775122531645568, -1.47961275862069 ], [ 29.775122531645568, -1.479343275862069 ], [ 29.774853037974683, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30230, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.479343275862069 ], [ 29.775122531645568, -1.47961275862069 ], [ 29.775392025316457, -1.47961275862069 ], [ 29.775392025316457, -1.479343275862069 ], [ 29.775122531645568, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30231, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.479343275862069 ], [ 29.775392025316457, -1.47961275862069 ], [ 29.775661518987341, -1.47961275862069 ], [ 29.775661518987341, -1.479343275862069 ], [ 29.775392025316457, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30232, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.479343275862069 ], [ 29.775661518987341, -1.47961275862069 ], [ 29.775931012658226, -1.47961275862069 ], [ 29.775931012658226, -1.479343275862069 ], [ 29.775661518987341, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30233, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.479343275862069 ], [ 29.775931012658226, -1.47961275862069 ], [ 29.776200506329115, -1.47961275862069 ], [ 29.776200506329115, -1.479343275862069 ], [ 29.775931012658226, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30234, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.479343275862069 ], [ 29.776200506329115, -1.47961275862069 ], [ 29.77647, -1.47961275862069 ], [ 29.77647, -1.479343275862069 ], [ 29.776200506329115, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30235, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.479343275862069 ], [ 29.77647, -1.47961275862069 ], [ 29.776739493670885, -1.47961275862069 ], [ 29.776739493670885, -1.479343275862069 ], [ 29.77647, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30236, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.479343275862069 ], [ 29.776739493670885, -1.47961275862069 ], [ 29.777008987341773, -1.47961275862069 ], [ 29.777008987341773, -1.479343275862069 ], [ 29.776739493670885, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30237, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.479343275862069 ], [ 29.777008987341773, -1.47961275862069 ], [ 29.777278481012658, -1.47961275862069 ], [ 29.777278481012658, -1.479343275862069 ], [ 29.777008987341773, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30238, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.479343275862069 ], [ 29.777278481012658, -1.47961275862069 ], [ 29.777547974683543, -1.47961275862069 ], [ 29.777547974683543, -1.479343275862069 ], [ 29.777278481012658, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30239, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.479343275862069 ], [ 29.777547974683543, -1.47961275862069 ], [ 29.777817468354431, -1.47961275862069 ], [ 29.777817468354431, -1.479343275862069 ], [ 29.777547974683543, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30240, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.479343275862069 ], [ 29.778086962025316, -1.47988224137931 ], [ 29.778356455696201, -1.47988224137931 ], [ 29.778356455696201, -1.479343275862069 ], [ 29.778086962025316, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30241, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.479343275862069 ], [ 29.778356455696201, -1.47961275862069 ], [ 29.77862594936709, -1.47961275862069 ], [ 29.77862594936709, -1.479343275862069 ], [ 29.778356455696201, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30242, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.479343275862069 ], [ 29.77862594936709, -1.47961275862069 ], [ 29.778895443037975, -1.47961275862069 ], [ 29.778895443037975, -1.479343275862069 ], [ 29.77862594936709, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30243, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.479343275862069 ], [ 29.778895443037975, -1.47961275862069 ], [ 29.77916493670886, -1.47961275862069 ], [ 29.77916493670886, -1.479343275862069 ], [ 29.778895443037975, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30244, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.479343275862069 ], [ 29.77916493670886, -1.47961275862069 ], [ 29.779434430379748, -1.47961275862069 ], [ 29.779434430379748, -1.479343275862069 ], [ 29.77916493670886, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30245, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.479343275862069 ], [ 29.779434430379748, -1.47961275862069 ], [ 29.779703924050633, -1.47961275862069 ], [ 29.779703924050633, -1.479343275862069 ], [ 29.779434430379748, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30246, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.479343275862069 ], [ 29.779703924050633, -1.47961275862069 ], [ 29.779973417721518, -1.47961275862069 ], [ 29.779973417721518, -1.479343275862069 ], [ 29.779703924050633, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30247, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.479343275862069 ], [ 29.779973417721518, -1.47961275862069 ], [ 29.780242911392406, -1.47961275862069 ], [ 29.780242911392406, -1.479343275862069 ], [ 29.779973417721518, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30248, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.479343275862069 ], [ 29.780242911392406, -1.47961275862069 ], [ 29.780512405063291, -1.47961275862069 ], [ 29.780512405063291, -1.479343275862069 ], [ 29.780242911392406, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30249, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.479343275862069 ], [ 29.780512405063291, -1.47961275862069 ], [ 29.780781898734176, -1.47961275862069 ], [ 29.780781898734176, -1.479343275862069 ], [ 29.780512405063291, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30250, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.479343275862069 ], [ 29.780781898734176, -1.47961275862069 ], [ 29.781051392405065, -1.47961275862069 ], [ 29.781051392405065, -1.479343275862069 ], [ 29.780781898734176, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30251, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.479343275862069 ], [ 29.781051392405065, -1.47961275862069 ], [ 29.78132088607595, -1.47961275862069 ], [ 29.78132088607595, -1.479343275862069 ], [ 29.781051392405065, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30252, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.479343275862069 ], [ 29.78132088607595, -1.47961275862069 ], [ 29.781590379746834, -1.47961275862069 ], [ 29.781590379746834, -1.479343275862069 ], [ 29.78132088607595, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30253, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.479343275862069 ], [ 29.781590379746834, -1.47961275862069 ], [ 29.781859873417723, -1.47961275862069 ], [ 29.781859873417723, -1.479343275862069 ], [ 29.781590379746834, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30254, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.479343275862069 ], [ 29.782129367088608, -1.47961275862069 ], [ 29.782398860759493, -1.47961275862069 ], [ 29.782398860759493, -1.479343275862069 ], [ 29.782129367088608, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30255, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.479343275862069 ], [ 29.782398860759493, -1.47961275862069 ], [ 29.782668354430381, -1.47961275862069 ], [ 29.782668354430381, -1.479343275862069 ], [ 29.782398860759493, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30256, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.479343275862069 ], [ 29.782668354430381, -1.47961275862069 ], [ 29.782937848101266, -1.47961275862069 ], [ 29.782937848101266, -1.479343275862069 ], [ 29.782668354430381, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30257, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.479343275862069 ], [ 29.782937848101266, -1.47988224137931 ], [ 29.783207341772151, -1.47988224137931 ], [ 29.783207341772151, -1.479343275862069 ], [ 29.782937848101266, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30258, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.479343275862069 ], [ 29.783207341772151, -1.47961275862069 ], [ 29.783476835443039, -1.47961275862069 ], [ 29.783476835443039, -1.479343275862069 ], [ 29.783207341772151, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30259, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.479343275862069 ], [ 29.783476835443039, -1.47961275862069 ], [ 29.784015822784809, -1.47961275862069 ], [ 29.784015822784809, -1.479343275862069 ], [ 29.783476835443039, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30260, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.479343275862069 ], [ 29.784015822784809, -1.47961275862069 ], [ 29.784285316455698, -1.47961275862069 ], [ 29.784285316455698, -1.479343275862069 ], [ 29.784015822784809, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30261, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.479343275862069 ], [ 29.784285316455698, -1.47961275862069 ], [ 29.784554810126583, -1.47961275862069 ], [ 29.784554810126583, -1.479343275862069 ], [ 29.784285316455698, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30262, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.479343275862069 ], [ 29.784554810126583, -1.47961275862069 ], [ 29.784824303797468, -1.47961275862069 ], [ 29.784824303797468, -1.479343275862069 ], [ 29.784554810126583, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30263, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.479343275862069 ], [ 29.784824303797468, -1.47961275862069 ], [ 29.785093797468356, -1.47961275862069 ], [ 29.785093797468356, -1.479343275862069 ], [ 29.784824303797468, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30264, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.479343275862069 ], [ 29.785093797468356, -1.47961275862069 ], [ 29.785363291139241, -1.47961275862069 ], [ 29.785363291139241, -1.479343275862069 ], [ 29.785093797468356, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30265, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.479343275862069 ], [ 29.785363291139241, -1.47961275862069 ], [ 29.785632784810126, -1.47961275862069 ], [ 29.785632784810126, -1.479343275862069 ], [ 29.785363291139241, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30266, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.479343275862069 ], [ 29.785632784810126, -1.47961275862069 ], [ 29.785902278481014, -1.47961275862069 ], [ 29.785902278481014, -1.479343275862069 ], [ 29.785632784810126, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30267, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.479343275862069 ], [ 29.785902278481014, -1.47961275862069 ], [ 29.786171772151899, -1.47961275862069 ], [ 29.786171772151899, -1.479343275862069 ], [ 29.785902278481014, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30268, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.479343275862069 ], [ 29.786171772151899, -1.47961275862069 ], [ 29.786980253164558, -1.47961275862069 ], [ 29.786980253164558, -1.479343275862069 ], [ 29.786171772151899, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30269, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.479343275862069 ], [ 29.786980253164558, -1.47961275862069 ], [ 29.787249746835442, -1.47961275862069 ], [ 29.787249746835442, -1.479343275862069 ], [ 29.786980253164558, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30270, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.479343275862069 ], [ 29.787249746835442, -1.47961275862069 ], [ 29.787788734177216, -1.47961275862069 ], [ 29.787788734177216, -1.479343275862069 ], [ 29.787249746835442, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30271, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.479343275862069 ], [ 29.787788734177216, -1.47961275862069 ], [ 29.788058227848101, -1.47961275862069 ], [ 29.788058227848101, -1.479343275862069 ], [ 29.787788734177216, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30272, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.479343275862069 ], [ 29.788058227848101, -1.47961275862069 ], [ 29.788327721518986, -1.47961275862069 ], [ 29.788327721518986, -1.479343275862069 ], [ 29.788058227848101, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30273, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.479343275862069 ], [ 29.788327721518986, -1.47961275862069 ], [ 29.789136202531644, -1.47961275862069 ], [ 29.789136202531644, -1.479343275862069 ], [ 29.788327721518986, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30274, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.479343275862069 ], [ 29.789136202531644, -1.47961275862069 ], [ 29.789405696202532, -1.47961275862069 ], [ 29.789405696202532, -1.479343275862069 ], [ 29.789136202531644, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30275, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.479343275862069 ], [ 29.80045493670886, -1.47961275862069 ], [ 29.800724430379748, -1.47961275862069 ], [ 29.800724430379748, -1.479343275862069 ], [ 29.80045493670886, -1.479343275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30276, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.47961275862069 ], [ 29.761647848101266, -1.480421206896552 ], [ 29.761917341772151, -1.480421206896552 ], [ 29.761917341772151, -1.47961275862069 ], [ 29.761647848101266, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30277, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.47961275862069 ], [ 29.761917341772151, -1.480421206896552 ], [ 29.762186835443039, -1.480421206896552 ], [ 29.762186835443039, -1.47961275862069 ], [ 29.761917341772151, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30278, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.47961275862069 ], [ 29.762725822784809, -1.47988224137931 ], [ 29.762995316455697, -1.47988224137931 ], [ 29.762995316455697, -1.47961275862069 ], [ 29.762725822784809, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30279, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.47961275862069 ], [ 29.763534303797467, -1.47988224137931 ], [ 29.763803797468356, -1.47988224137931 ], [ 29.763803797468356, -1.47961275862069 ], [ 29.763534303797467, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30280, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.47961275862069 ], [ 29.763803797468356, -1.47988224137931 ], [ 29.76407329113924, -1.47988224137931 ], [ 29.76407329113924, -1.47961275862069 ], [ 29.763803797468356, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30281, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.47961275862069 ], [ 29.76407329113924, -1.47988224137931 ], [ 29.764342784810125, -1.47988224137931 ], [ 29.764342784810125, -1.47961275862069 ], [ 29.76407329113924, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30282, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.47961275862069 ], [ 29.764342784810125, -1.47988224137931 ], [ 29.764612278481014, -1.47988224137931 ], [ 29.764612278481014, -1.47961275862069 ], [ 29.764342784810125, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30283, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.47961275862069 ], [ 29.764612278481014, -1.47988224137931 ], [ 29.764881772151899, -1.47988224137931 ], [ 29.764881772151899, -1.47961275862069 ], [ 29.764612278481014, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30284, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.47961275862069 ], [ 29.764881772151899, -1.47988224137931 ], [ 29.765151265822784, -1.47988224137931 ], [ 29.765151265822784, -1.47961275862069 ], [ 29.764881772151899, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30285, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.47961275862069 ], [ 29.765151265822784, -1.47988224137931 ], [ 29.765420759493672, -1.47988224137931 ], [ 29.765420759493672, -1.47961275862069 ], [ 29.765151265822784, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30286, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.47961275862069 ], [ 29.765420759493672, -1.47988224137931 ], [ 29.765959746835442, -1.47988224137931 ], [ 29.765959746835442, -1.47961275862069 ], [ 29.765420759493672, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30287, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.47961275862069 ], [ 29.765959746835442, -1.47988224137931 ], [ 29.766229240506327, -1.47988224137931 ], [ 29.766229240506327, -1.47961275862069 ], [ 29.765959746835442, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30288, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.47961275862069 ], [ 29.766229240506327, -1.47988224137931 ], [ 29.766498734177215, -1.47988224137931 ], [ 29.766498734177215, -1.47961275862069 ], [ 29.766229240506327, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30289, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.47961275862069 ], [ 29.766498734177215, -1.47988224137931 ], [ 29.7667682278481, -1.47988224137931 ], [ 29.7667682278481, -1.47961275862069 ], [ 29.766498734177215, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30290, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.47961275862069 ], [ 29.7667682278481, -1.47988224137931 ], [ 29.767037721518985, -1.47988224137931 ], [ 29.767037721518985, -1.47961275862069 ], [ 29.7667682278481, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30291, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.47961275862069 ], [ 29.767037721518985, -1.47988224137931 ], [ 29.767307215189874, -1.47988224137931 ], [ 29.767307215189874, -1.47961275862069 ], [ 29.767037721518985, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30292, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.47961275862069 ], [ 29.767307215189874, -1.47988224137931 ], [ 29.767576708860759, -1.47988224137931 ], [ 29.767576708860759, -1.47961275862069 ], [ 29.767307215189874, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30293, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.47961275862069 ], [ 29.767576708860759, -1.47988224137931 ], [ 29.767846202531643, -1.47988224137931 ], [ 29.767846202531643, -1.47961275862069 ], [ 29.767576708860759, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30294, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.47961275862069 ], [ 29.767846202531643, -1.47988224137931 ], [ 29.768115696202532, -1.47988224137931 ], [ 29.768115696202532, -1.47961275862069 ], [ 29.767846202531643, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30295, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.47961275862069 ], [ 29.768115696202532, -1.47988224137931 ], [ 29.768385189873417, -1.47988224137931 ], [ 29.768385189873417, -1.47961275862069 ], [ 29.768115696202532, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30296, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.47961275862069 ], [ 29.768385189873417, -1.47988224137931 ], [ 29.770541139240507, -1.47988224137931 ], [ 29.770541139240507, -1.47961275862069 ], [ 29.768385189873417, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30297, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.47961275862069 ], [ 29.770541139240507, -1.47988224137931 ], [ 29.770810632911392, -1.47988224137931 ], [ 29.770810632911392, -1.47961275862069 ], [ 29.770541139240507, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30298, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.47961275862069 ], [ 29.770810632911392, -1.47988224137931 ], [ 29.771080126582277, -1.47988224137931 ], [ 29.771080126582277, -1.47961275862069 ], [ 29.770810632911392, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30299, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.47961275862069 ], [ 29.771080126582277, -1.47988224137931 ], [ 29.771349620253165, -1.47988224137931 ], [ 29.771349620253165, -1.47961275862069 ], [ 29.771080126582277, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30300, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.47961275862069 ], [ 29.771349620253165, -1.47988224137931 ], [ 29.77161911392405, -1.47988224137931 ], [ 29.77161911392405, -1.47961275862069 ], [ 29.771349620253165, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30301, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.47961275862069 ], [ 29.77161911392405, -1.47988224137931 ], [ 29.771888607594935, -1.47988224137931 ], [ 29.771888607594935, -1.47961275862069 ], [ 29.77161911392405, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30302, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.47961275862069 ], [ 29.771888607594935, -1.47988224137931 ], [ 29.772158101265823, -1.47988224137931 ], [ 29.772158101265823, -1.47961275862069 ], [ 29.771888607594935, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30303, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.47961275862069 ], [ 29.772158101265823, -1.47988224137931 ], [ 29.772427594936708, -1.47988224137931 ], [ 29.772427594936708, -1.47961275862069 ], [ 29.772158101265823, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30304, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.47961275862069 ], [ 29.772427594936708, -1.47988224137931 ], [ 29.772697088607593, -1.47988224137931 ], [ 29.772697088607593, -1.47961275862069 ], [ 29.772427594936708, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30305, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.47961275862069 ], [ 29.772697088607593, -1.47988224137931 ], [ 29.772966582278482, -1.47988224137931 ], [ 29.772966582278482, -1.47961275862069 ], [ 29.772697088607593, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30306, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.47961275862069 ], [ 29.772966582278482, -1.47988224137931 ], [ 29.773236075949367, -1.47988224137931 ], [ 29.773236075949367, -1.47961275862069 ], [ 29.772966582278482, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30307, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.47961275862069 ], [ 29.773236075949367, -1.47988224137931 ], [ 29.773505569620252, -1.47988224137931 ], [ 29.773505569620252, -1.47961275862069 ], [ 29.773236075949367, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30308, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.47961275862069 ], [ 29.773505569620252, -1.47988224137931 ], [ 29.77377506329114, -1.47988224137931 ], [ 29.77377506329114, -1.47961275862069 ], [ 29.773505569620252, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30309, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.47961275862069 ], [ 29.77377506329114, -1.47988224137931 ], [ 29.774044556962025, -1.47988224137931 ], [ 29.774044556962025, -1.47961275862069 ], [ 29.77377506329114, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30310, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.47961275862069 ], [ 29.774044556962025, -1.47988224137931 ], [ 29.77431405063291, -1.47988224137931 ], [ 29.77431405063291, -1.47961275862069 ], [ 29.774044556962025, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30311, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.47961275862069 ], [ 29.77431405063291, -1.47988224137931 ], [ 29.774853037974683, -1.47988224137931 ], [ 29.774853037974683, -1.47961275862069 ], [ 29.77431405063291, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30312, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.47961275862069 ], [ 29.774853037974683, -1.47988224137931 ], [ 29.775122531645568, -1.47988224137931 ], [ 29.775122531645568, -1.47961275862069 ], [ 29.774853037974683, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30313, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.47961275862069 ], [ 29.775122531645568, -1.47988224137931 ], [ 29.775392025316457, -1.47988224137931 ], [ 29.775392025316457, -1.47961275862069 ], [ 29.775122531645568, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30314, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.47961275862069 ], [ 29.775392025316457, -1.47988224137931 ], [ 29.775661518987341, -1.47988224137931 ], [ 29.775661518987341, -1.47961275862069 ], [ 29.775392025316457, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30315, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.47961275862069 ], [ 29.775661518987341, -1.47988224137931 ], [ 29.775931012658226, -1.47988224137931 ], [ 29.775931012658226, -1.47961275862069 ], [ 29.775661518987341, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30316, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.47961275862069 ], [ 29.775931012658226, -1.47988224137931 ], [ 29.776200506329115, -1.47988224137931 ], [ 29.776200506329115, -1.47961275862069 ], [ 29.775931012658226, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30317, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.47961275862069 ], [ 29.776200506329115, -1.47988224137931 ], [ 29.77647, -1.47988224137931 ], [ 29.77647, -1.47961275862069 ], [ 29.776200506329115, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30318, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.47961275862069 ], [ 29.77647, -1.47988224137931 ], [ 29.776739493670885, -1.47988224137931 ], [ 29.776739493670885, -1.47961275862069 ], [ 29.77647, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30319, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.47961275862069 ], [ 29.776739493670885, -1.47988224137931 ], [ 29.777008987341773, -1.47988224137931 ], [ 29.777008987341773, -1.47961275862069 ], [ 29.776739493670885, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30320, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.47961275862069 ], [ 29.777008987341773, -1.47988224137931 ], [ 29.777278481012658, -1.47988224137931 ], [ 29.777278481012658, -1.47961275862069 ], [ 29.777008987341773, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30321, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.47961275862069 ], [ 29.777278481012658, -1.47988224137931 ], [ 29.777547974683543, -1.47988224137931 ], [ 29.777547974683543, -1.47961275862069 ], [ 29.777278481012658, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30322, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.47961275862069 ], [ 29.777547974683543, -1.47988224137931 ], [ 29.777817468354431, -1.47988224137931 ], [ 29.777817468354431, -1.47961275862069 ], [ 29.777547974683543, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30323, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.47961275862069 ], [ 29.777817468354431, -1.47988224137931 ], [ 29.778086962025316, -1.47988224137931 ], [ 29.778086962025316, -1.47961275862069 ], [ 29.777817468354431, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30324, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.47961275862069 ], [ 29.778356455696201, -1.47988224137931 ], [ 29.77862594936709, -1.47988224137931 ], [ 29.77862594936709, -1.47961275862069 ], [ 29.778356455696201, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30325, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.47961275862069 ], [ 29.77862594936709, -1.47988224137931 ], [ 29.778895443037975, -1.47988224137931 ], [ 29.778895443037975, -1.47961275862069 ], [ 29.77862594936709, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30326, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.47961275862069 ], [ 29.778895443037975, -1.47988224137931 ], [ 29.77916493670886, -1.47988224137931 ], [ 29.77916493670886, -1.47961275862069 ], [ 29.778895443037975, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30327, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.47961275862069 ], [ 29.77916493670886, -1.480151724137931 ], [ 29.779434430379748, -1.480151724137931 ], [ 29.779434430379748, -1.47961275862069 ], [ 29.77916493670886, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30328, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.47961275862069 ], [ 29.779434430379748, -1.47988224137931 ], [ 29.779703924050633, -1.47988224137931 ], [ 29.779703924050633, -1.47961275862069 ], [ 29.779434430379748, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30329, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.47961275862069 ], [ 29.779703924050633, -1.47988224137931 ], [ 29.779973417721518, -1.47988224137931 ], [ 29.779973417721518, -1.47961275862069 ], [ 29.779703924050633, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30330, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.47961275862069 ], [ 29.779973417721518, -1.47988224137931 ], [ 29.780242911392406, -1.47988224137931 ], [ 29.780242911392406, -1.47961275862069 ], [ 29.779973417721518, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30331, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.47961275862069 ], [ 29.780242911392406, -1.47988224137931 ], [ 29.780512405063291, -1.47988224137931 ], [ 29.780512405063291, -1.47961275862069 ], [ 29.780242911392406, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30332, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.47961275862069 ], [ 29.780512405063291, -1.47988224137931 ], [ 29.780781898734176, -1.47988224137931 ], [ 29.780781898734176, -1.47961275862069 ], [ 29.780512405063291, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30333, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.47961275862069 ], [ 29.780781898734176, -1.47988224137931 ], [ 29.781051392405065, -1.47988224137931 ], [ 29.781051392405065, -1.47961275862069 ], [ 29.780781898734176, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30334, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.47961275862069 ], [ 29.781051392405065, -1.47988224137931 ], [ 29.78132088607595, -1.47988224137931 ], [ 29.78132088607595, -1.47961275862069 ], [ 29.781051392405065, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30335, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.47961275862069 ], [ 29.78132088607595, -1.47988224137931 ], [ 29.781590379746834, -1.47988224137931 ], [ 29.781590379746834, -1.47961275862069 ], [ 29.78132088607595, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30336, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.47961275862069 ], [ 29.781590379746834, -1.47988224137931 ], [ 29.781859873417723, -1.47988224137931 ], [ 29.781859873417723, -1.47961275862069 ], [ 29.781590379746834, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30337, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.47961275862069 ], [ 29.781859873417723, -1.47988224137931 ], [ 29.782129367088608, -1.47988224137931 ], [ 29.782129367088608, -1.47961275862069 ], [ 29.781859873417723, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30338, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.47961275862069 ], [ 29.782129367088608, -1.47988224137931 ], [ 29.782398860759493, -1.47988224137931 ], [ 29.782398860759493, -1.47961275862069 ], [ 29.782129367088608, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30339, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.47961275862069 ], [ 29.782398860759493, -1.47988224137931 ], [ 29.782668354430381, -1.47988224137931 ], [ 29.782668354430381, -1.47961275862069 ], [ 29.782398860759493, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30340, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.47961275862069 ], [ 29.782668354430381, -1.47988224137931 ], [ 29.782937848101266, -1.47988224137931 ], [ 29.782937848101266, -1.47961275862069 ], [ 29.782668354430381, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30341, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.47961275862069 ], [ 29.783207341772151, -1.47988224137931 ], [ 29.783476835443039, -1.47988224137931 ], [ 29.783476835443039, -1.47961275862069 ], [ 29.783207341772151, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30342, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.47961275862069 ], [ 29.783476835443039, -1.47988224137931 ], [ 29.784015822784809, -1.47988224137931 ], [ 29.784015822784809, -1.47961275862069 ], [ 29.783476835443039, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30343, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.47961275862069 ], [ 29.784015822784809, -1.47988224137931 ], [ 29.784285316455698, -1.47988224137931 ], [ 29.784285316455698, -1.47961275862069 ], [ 29.784015822784809, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30344, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.47961275862069 ], [ 29.784285316455698, -1.480151724137931 ], [ 29.784554810126583, -1.480151724137931 ], [ 29.784554810126583, -1.47961275862069 ], [ 29.784285316455698, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30345, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.47961275862069 ], [ 29.784554810126583, -1.47988224137931 ], [ 29.784824303797468, -1.47988224137931 ], [ 29.784824303797468, -1.47961275862069 ], [ 29.784554810126583, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30346, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.47961275862069 ], [ 29.784824303797468, -1.47988224137931 ], [ 29.785093797468356, -1.47988224137931 ], [ 29.785093797468356, -1.47961275862069 ], [ 29.784824303797468, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30347, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.47961275862069 ], [ 29.785093797468356, -1.47988224137931 ], [ 29.785363291139241, -1.47988224137931 ], [ 29.785363291139241, -1.47961275862069 ], [ 29.785093797468356, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30348, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.47961275862069 ], [ 29.785363291139241, -1.47988224137931 ], [ 29.785632784810126, -1.47988224137931 ], [ 29.785632784810126, -1.47961275862069 ], [ 29.785363291139241, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30349, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.47961275862069 ], [ 29.785632784810126, -1.47988224137931 ], [ 29.785902278481014, -1.47988224137931 ], [ 29.785902278481014, -1.47961275862069 ], [ 29.785632784810126, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30350, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.47961275862069 ], [ 29.785902278481014, -1.47988224137931 ], [ 29.786171772151899, -1.47988224137931 ], [ 29.786171772151899, -1.47961275862069 ], [ 29.785902278481014, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30351, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.47961275862069 ], [ 29.786171772151899, -1.47988224137931 ], [ 29.786980253164558, -1.47988224137931 ], [ 29.786980253164558, -1.47961275862069 ], [ 29.786171772151899, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30352, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.47961275862069 ], [ 29.786980253164558, -1.47988224137931 ], [ 29.787249746835442, -1.47988224137931 ], [ 29.787249746835442, -1.47961275862069 ], [ 29.786980253164558, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30353, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.47961275862069 ], [ 29.787249746835442, -1.47988224137931 ], [ 29.787788734177216, -1.47988224137931 ], [ 29.787788734177216, -1.47961275862069 ], [ 29.787249746835442, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30354, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.47961275862069 ], [ 29.787788734177216, -1.47988224137931 ], [ 29.788058227848101, -1.47988224137931 ], [ 29.788058227848101, -1.47961275862069 ], [ 29.787788734177216, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30355, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.47961275862069 ], [ 29.788058227848101, -1.47988224137931 ], [ 29.788866708860759, -1.47988224137931 ], [ 29.788866708860759, -1.47961275862069 ], [ 29.788058227848101, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30356, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.47961275862069 ], [ 29.788866708860759, -1.47988224137931 ], [ 29.789136202531644, -1.47988224137931 ], [ 29.789136202531644, -1.47961275862069 ], [ 29.788866708860759, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30357, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.47961275862069 ], [ 29.789136202531644, -1.47988224137931 ], [ 29.789405696202532, -1.47988224137931 ], [ 29.789405696202532, -1.47961275862069 ], [ 29.789136202531644, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30358, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.47961275862069 ], [ 29.789675189873417, -1.47988224137931 ], [ 29.789944683544302, -1.47988224137931 ], [ 29.789944683544302, -1.47961275862069 ], [ 29.789675189873417, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30359, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.47961275862069 ], [ 29.789944683544302, -1.47988224137931 ], [ 29.790214177215191, -1.47988224137931 ], [ 29.790214177215191, -1.47961275862069 ], [ 29.789944683544302, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30360, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.47961275862069 ], [ 29.790214177215191, -1.47988224137931 ], [ 29.790483670886076, -1.47988224137931 ], [ 29.790483670886076, -1.47961275862069 ], [ 29.790214177215191, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30361, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.47961275862069 ], [ 29.790483670886076, -1.47988224137931 ], [ 29.790753164556961, -1.47988224137931 ], [ 29.790753164556961, -1.47961275862069 ], [ 29.790483670886076, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30362, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.47961275862069 ], [ 29.79991594936709, -1.47988224137931 ], [ 29.800185443037975, -1.47988224137931 ], [ 29.800185443037975, -1.47961275862069 ], [ 29.79991594936709, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30363, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.47961275862069 ], [ 29.800185443037975, -1.47988224137931 ], [ 29.80045493670886, -1.47988224137931 ], [ 29.80045493670886, -1.47961275862069 ], [ 29.800185443037975, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30364, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.47961275862069 ], [ 29.80045493670886, -1.47988224137931 ], [ 29.800724430379748, -1.47988224137931 ], [ 29.800724430379748, -1.47961275862069 ], [ 29.80045493670886, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30365, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.47988224137931 ], [ 29.762725822784809, -1.480151724137931 ], [ 29.762995316455697, -1.480151724137931 ], [ 29.762995316455697, -1.47988224137931 ], [ 29.762725822784809, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30366, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.47988224137931 ], [ 29.762995316455697, -1.480151724137931 ], [ 29.763264810126582, -1.480151724137931 ], [ 29.763264810126582, -1.47988224137931 ], [ 29.762995316455697, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30367, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.47988224137931 ], [ 29.763264810126582, -1.480151724137931 ], [ 29.763534303797467, -1.480151724137931 ], [ 29.763534303797467, -1.47988224137931 ], [ 29.763264810126582, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30368, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.47988224137931 ], [ 29.763534303797467, -1.480151724137931 ], [ 29.763803797468356, -1.480151724137931 ], [ 29.763803797468356, -1.47988224137931 ], [ 29.763534303797467, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30369, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.47988224137931 ], [ 29.763803797468356, -1.480151724137931 ], [ 29.76407329113924, -1.480151724137931 ], [ 29.76407329113924, -1.47988224137931 ], [ 29.763803797468356, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30370, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.47988224137931 ], [ 29.76407329113924, -1.480151724137931 ], [ 29.764342784810125, -1.480151724137931 ], [ 29.764342784810125, -1.47988224137931 ], [ 29.76407329113924, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30371, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.47988224137931 ], [ 29.764342784810125, -1.480151724137931 ], [ 29.764612278481014, -1.480151724137931 ], [ 29.764612278481014, -1.47988224137931 ], [ 29.764342784810125, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30372, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.47988224137931 ], [ 29.764612278481014, -1.480151724137931 ], [ 29.764881772151899, -1.480151724137931 ], [ 29.764881772151899, -1.47988224137931 ], [ 29.764612278481014, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30373, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.47988224137931 ], [ 29.764881772151899, -1.480151724137931 ], [ 29.765151265822784, -1.480151724137931 ], [ 29.765151265822784, -1.47988224137931 ], [ 29.764881772151899, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30374, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.47988224137931 ], [ 29.765151265822784, -1.480151724137931 ], [ 29.765420759493672, -1.480151724137931 ], [ 29.765420759493672, -1.47988224137931 ], [ 29.765151265822784, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30375, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.47988224137931 ], [ 29.765420759493672, -1.480151724137931 ], [ 29.765959746835442, -1.480151724137931 ], [ 29.765959746835442, -1.47988224137931 ], [ 29.765420759493672, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30376, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.47988224137931 ], [ 29.765959746835442, -1.480151724137931 ], [ 29.766229240506327, -1.480151724137931 ], [ 29.766229240506327, -1.47988224137931 ], [ 29.765959746835442, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30377, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.47988224137931 ], [ 29.766229240506327, -1.480421206896552 ], [ 29.766498734177215, -1.480421206896552 ], [ 29.766498734177215, -1.47988224137931 ], [ 29.766229240506327, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30378, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.47988224137931 ], [ 29.766498734177215, -1.480151724137931 ], [ 29.7667682278481, -1.480151724137931 ], [ 29.7667682278481, -1.47988224137931 ], [ 29.766498734177215, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30379, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.47988224137931 ], [ 29.7667682278481, -1.480151724137931 ], [ 29.767037721518985, -1.480151724137931 ], [ 29.767037721518985, -1.47988224137931 ], [ 29.7667682278481, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30380, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.47988224137931 ], [ 29.767037721518985, -1.480151724137931 ], [ 29.767307215189874, -1.480151724137931 ], [ 29.767307215189874, -1.47988224137931 ], [ 29.767037721518985, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30381, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.47988224137931 ], [ 29.767307215189874, -1.480151724137931 ], [ 29.767576708860759, -1.480151724137931 ], [ 29.767576708860759, -1.47988224137931 ], [ 29.767307215189874, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30382, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.47988224137931 ], [ 29.767576708860759, -1.480151724137931 ], [ 29.767846202531643, -1.480151724137931 ], [ 29.767846202531643, -1.47988224137931 ], [ 29.767576708860759, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30383, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.47988224137931 ], [ 29.767846202531643, -1.480151724137931 ], [ 29.768115696202532, -1.480151724137931 ], [ 29.768115696202532, -1.47988224137931 ], [ 29.767846202531643, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30384, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.47988224137931 ], [ 29.768115696202532, -1.480151724137931 ], [ 29.768654683544302, -1.480151724137931 ], [ 29.768654683544302, -1.47988224137931 ], [ 29.768115696202532, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30385, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.47988224137931 ], [ 29.768654683544302, -1.480151724137931 ], [ 29.770271645569618, -1.480151724137931 ], [ 29.770271645569618, -1.47988224137931 ], [ 29.768654683544302, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30386, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.47988224137931 ], [ 29.770271645569618, -1.480151724137931 ], [ 29.770810632911392, -1.480151724137931 ], [ 29.770810632911392, -1.47988224137931 ], [ 29.770271645569618, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30387, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.47988224137931 ], [ 29.770810632911392, -1.480151724137931 ], [ 29.771080126582277, -1.480151724137931 ], [ 29.771080126582277, -1.47988224137931 ], [ 29.770810632911392, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30388, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.47988224137931 ], [ 29.771080126582277, -1.480151724137931 ], [ 29.771349620253165, -1.480151724137931 ], [ 29.771349620253165, -1.47988224137931 ], [ 29.771080126582277, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30389, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.47988224137931 ], [ 29.771349620253165, -1.480151724137931 ], [ 29.77161911392405, -1.480151724137931 ], [ 29.77161911392405, -1.47988224137931 ], [ 29.771349620253165, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30390, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.47988224137931 ], [ 29.77161911392405, -1.480151724137931 ], [ 29.771888607594935, -1.480151724137931 ], [ 29.771888607594935, -1.47988224137931 ], [ 29.77161911392405, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30391, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.47988224137931 ], [ 29.771888607594935, -1.480151724137931 ], [ 29.772158101265823, -1.480151724137931 ], [ 29.772158101265823, -1.47988224137931 ], [ 29.771888607594935, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30392, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.47988224137931 ], [ 29.772158101265823, -1.480151724137931 ], [ 29.772427594936708, -1.480151724137931 ], [ 29.772427594936708, -1.47988224137931 ], [ 29.772158101265823, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30393, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.47988224137931 ], [ 29.772427594936708, -1.480151724137931 ], [ 29.772697088607593, -1.480151724137931 ], [ 29.772697088607593, -1.47988224137931 ], [ 29.772427594936708, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30394, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.47988224137931 ], [ 29.772697088607593, -1.480151724137931 ], [ 29.772966582278482, -1.480151724137931 ], [ 29.772966582278482, -1.47988224137931 ], [ 29.772697088607593, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30395, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.47988224137931 ], [ 29.772966582278482, -1.480151724137931 ], [ 29.773236075949367, -1.480151724137931 ], [ 29.773236075949367, -1.47988224137931 ], [ 29.772966582278482, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30396, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.47988224137931 ], [ 29.773236075949367, -1.480151724137931 ], [ 29.773505569620252, -1.480151724137931 ], [ 29.773505569620252, -1.47988224137931 ], [ 29.773236075949367, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30397, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.47988224137931 ], [ 29.773505569620252, -1.480151724137931 ], [ 29.77377506329114, -1.480151724137931 ], [ 29.77377506329114, -1.47988224137931 ], [ 29.773505569620252, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30398, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.47988224137931 ], [ 29.77377506329114, -1.480151724137931 ], [ 29.774044556962025, -1.480151724137931 ], [ 29.774044556962025, -1.47988224137931 ], [ 29.77377506329114, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30399, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.47988224137931 ], [ 29.774044556962025, -1.480151724137931 ], [ 29.77431405063291, -1.480151724137931 ], [ 29.77431405063291, -1.47988224137931 ], [ 29.774044556962025, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30400, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.47988224137931 ], [ 29.77431405063291, -1.480151724137931 ], [ 29.774853037974683, -1.480151724137931 ], [ 29.774853037974683, -1.47988224137931 ], [ 29.77431405063291, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30401, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.47988224137931 ], [ 29.774853037974683, -1.480151724137931 ], [ 29.775122531645568, -1.480151724137931 ], [ 29.775122531645568, -1.47988224137931 ], [ 29.774853037974683, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30402, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.47988224137931 ], [ 29.775122531645568, -1.480151724137931 ], [ 29.775392025316457, -1.480151724137931 ], [ 29.775392025316457, -1.47988224137931 ], [ 29.775122531645568, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30403, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.47988224137931 ], [ 29.775392025316457, -1.480151724137931 ], [ 29.775661518987341, -1.480151724137931 ], [ 29.775661518987341, -1.47988224137931 ], [ 29.775392025316457, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30404, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.47988224137931 ], [ 29.775661518987341, -1.480151724137931 ], [ 29.775931012658226, -1.480151724137931 ], [ 29.775931012658226, -1.47988224137931 ], [ 29.775661518987341, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30405, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.47988224137931 ], [ 29.775931012658226, -1.480151724137931 ], [ 29.776200506329115, -1.480151724137931 ], [ 29.776200506329115, -1.47988224137931 ], [ 29.775931012658226, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30406, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.47988224137931 ], [ 29.776200506329115, -1.480151724137931 ], [ 29.77647, -1.480151724137931 ], [ 29.77647, -1.47988224137931 ], [ 29.776200506329115, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30407, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.47988224137931 ], [ 29.77647, -1.480151724137931 ], [ 29.776739493670885, -1.480151724137931 ], [ 29.776739493670885, -1.47988224137931 ], [ 29.77647, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30408, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.47988224137931 ], [ 29.776739493670885, -1.480151724137931 ], [ 29.777008987341773, -1.480151724137931 ], [ 29.777008987341773, -1.47988224137931 ], [ 29.776739493670885, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30409, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.47988224137931 ], [ 29.777008987341773, -1.480151724137931 ], [ 29.777278481012658, -1.480151724137931 ], [ 29.777278481012658, -1.47988224137931 ], [ 29.777008987341773, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30410, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.47988224137931 ], [ 29.777278481012658, -1.480151724137931 ], [ 29.777547974683543, -1.480151724137931 ], [ 29.777547974683543, -1.47988224137931 ], [ 29.777278481012658, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30411, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.47988224137931 ], [ 29.777547974683543, -1.480151724137931 ], [ 29.777817468354431, -1.480151724137931 ], [ 29.777817468354431, -1.47988224137931 ], [ 29.777547974683543, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30412, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.47988224137931 ], [ 29.777817468354431, -1.480151724137931 ], [ 29.778086962025316, -1.480151724137931 ], [ 29.778086962025316, -1.47988224137931 ], [ 29.777817468354431, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30413, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.47988224137931 ], [ 29.778086962025316, -1.480151724137931 ], [ 29.778356455696201, -1.480151724137931 ], [ 29.778356455696201, -1.47988224137931 ], [ 29.778086962025316, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30414, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.47988224137931 ], [ 29.778356455696201, -1.480151724137931 ], [ 29.77862594936709, -1.480151724137931 ], [ 29.77862594936709, -1.47988224137931 ], [ 29.778356455696201, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30415, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.47988224137931 ], [ 29.77862594936709, -1.480151724137931 ], [ 29.778895443037975, -1.480151724137931 ], [ 29.778895443037975, -1.47988224137931 ], [ 29.77862594936709, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30416, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.47988224137931 ], [ 29.778895443037975, -1.480151724137931 ], [ 29.77916493670886, -1.480151724137931 ], [ 29.77916493670886, -1.47988224137931 ], [ 29.778895443037975, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30417, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.47988224137931 ], [ 29.779434430379748, -1.480151724137931 ], [ 29.779703924050633, -1.480151724137931 ], [ 29.779703924050633, -1.47988224137931 ], [ 29.779434430379748, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30418, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.47988224137931 ], [ 29.779703924050633, -1.480151724137931 ], [ 29.779973417721518, -1.480151724137931 ], [ 29.779973417721518, -1.47988224137931 ], [ 29.779703924050633, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30419, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.47988224137931 ], [ 29.779973417721518, -1.480151724137931 ], [ 29.780242911392406, -1.480151724137931 ], [ 29.780242911392406, -1.47988224137931 ], [ 29.779973417721518, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30420, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.47988224137931 ], [ 29.780242911392406, -1.480421206896552 ], [ 29.780512405063291, -1.480421206896552 ], [ 29.780512405063291, -1.47988224137931 ], [ 29.780242911392406, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30421, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.47988224137931 ], [ 29.780512405063291, -1.480151724137931 ], [ 29.780781898734176, -1.480151724137931 ], [ 29.780781898734176, -1.47988224137931 ], [ 29.780512405063291, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30422, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.47988224137931 ], [ 29.780781898734176, -1.480151724137931 ], [ 29.781051392405065, -1.480151724137931 ], [ 29.781051392405065, -1.47988224137931 ], [ 29.780781898734176, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30423, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.47988224137931 ], [ 29.781051392405065, -1.480151724137931 ], [ 29.78132088607595, -1.480151724137931 ], [ 29.78132088607595, -1.47988224137931 ], [ 29.781051392405065, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30424, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.47988224137931 ], [ 29.78132088607595, -1.480151724137931 ], [ 29.781590379746834, -1.480151724137931 ], [ 29.781590379746834, -1.47988224137931 ], [ 29.78132088607595, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30425, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.47988224137931 ], [ 29.781590379746834, -1.480151724137931 ], [ 29.781859873417723, -1.480151724137931 ], [ 29.781859873417723, -1.47988224137931 ], [ 29.781590379746834, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30426, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.47988224137931 ], [ 29.781859873417723, -1.480151724137931 ], [ 29.782129367088608, -1.480151724137931 ], [ 29.782129367088608, -1.47988224137931 ], [ 29.781859873417723, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30427, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.47988224137931 ], [ 29.782129367088608, -1.480151724137931 ], [ 29.782398860759493, -1.480151724137931 ], [ 29.782398860759493, -1.47988224137931 ], [ 29.782129367088608, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30428, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.47988224137931 ], [ 29.782398860759493, -1.480151724137931 ], [ 29.782668354430381, -1.480151724137931 ], [ 29.782668354430381, -1.47988224137931 ], [ 29.782398860759493, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30429, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.47988224137931 ], [ 29.782668354430381, -1.480151724137931 ], [ 29.782937848101266, -1.480151724137931 ], [ 29.782937848101266, -1.47988224137931 ], [ 29.782668354430381, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30430, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.47988224137931 ], [ 29.782937848101266, -1.480151724137931 ], [ 29.783207341772151, -1.480151724137931 ], [ 29.783207341772151, -1.47988224137931 ], [ 29.782937848101266, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30431, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.47988224137931 ], [ 29.783207341772151, -1.480151724137931 ], [ 29.783476835443039, -1.480151724137931 ], [ 29.783476835443039, -1.47988224137931 ], [ 29.783207341772151, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30432, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.47988224137931 ], [ 29.783476835443039, -1.480151724137931 ], [ 29.784015822784809, -1.480151724137931 ], [ 29.784015822784809, -1.47988224137931 ], [ 29.783476835443039, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30433, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.47988224137931 ], [ 29.784015822784809, -1.480151724137931 ], [ 29.784285316455698, -1.480151724137931 ], [ 29.784285316455698, -1.47988224137931 ], [ 29.784015822784809, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30434, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.47988224137931 ], [ 29.784554810126583, -1.480421206896552 ], [ 29.784824303797468, -1.480421206896552 ], [ 29.784824303797468, -1.47988224137931 ], [ 29.784554810126583, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30435, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.47988224137931 ], [ 29.784824303797468, -1.480421206896552 ], [ 29.785093797468356, -1.480421206896552 ], [ 29.785093797468356, -1.47988224137931 ], [ 29.784824303797468, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30436, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.47988224137931 ], [ 29.785093797468356, -1.480151724137931 ], [ 29.785363291139241, -1.480151724137931 ], [ 29.785363291139241, -1.47988224137931 ], [ 29.785093797468356, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30437, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.47988224137931 ], [ 29.785363291139241, -1.480151724137931 ], [ 29.785632784810126, -1.480151724137931 ], [ 29.785632784810126, -1.47988224137931 ], [ 29.785363291139241, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30438, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.47988224137931 ], [ 29.785632784810126, -1.480151724137931 ], [ 29.785902278481014, -1.480151724137931 ], [ 29.785902278481014, -1.47988224137931 ], [ 29.785632784810126, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30439, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.47988224137931 ], [ 29.785902278481014, -1.480151724137931 ], [ 29.786171772151899, -1.480151724137931 ], [ 29.786171772151899, -1.47988224137931 ], [ 29.785902278481014, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30440, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.47988224137931 ], [ 29.786980253164558, -1.480151724137931 ], [ 29.787788734177216, -1.480151724137931 ], [ 29.787788734177216, -1.47988224137931 ], [ 29.786980253164558, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30441, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.47988224137931 ], [ 29.787788734177216, -1.480151724137931 ], [ 29.788058227848101, -1.480151724137931 ], [ 29.788058227848101, -1.47988224137931 ], [ 29.787788734177216, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30442, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.47988224137931 ], [ 29.788058227848101, -1.480151724137931 ], [ 29.788866708860759, -1.480151724137931 ], [ 29.788866708860759, -1.47988224137931 ], [ 29.788058227848101, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30443, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.47988224137931 ], [ 29.788866708860759, -1.480151724137931 ], [ 29.789136202531644, -1.480151724137931 ], [ 29.789136202531644, -1.47988224137931 ], [ 29.788866708860759, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30444, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.47988224137931 ], [ 29.789136202531644, -1.480151724137931 ], [ 29.789405696202532, -1.480151724137931 ], [ 29.789405696202532, -1.47988224137931 ], [ 29.789136202531644, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30445, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.47988224137931 ], [ 29.789944683544302, -1.480151724137931 ], [ 29.790214177215191, -1.480151724137931 ], [ 29.790214177215191, -1.47988224137931 ], [ 29.789944683544302, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30446, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.47988224137931 ], [ 29.790214177215191, -1.480151724137931 ], [ 29.790483670886076, -1.480151724137931 ], [ 29.790483670886076, -1.47988224137931 ], [ 29.790214177215191, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30447, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.47988224137931 ], [ 29.790483670886076, -1.480151724137931 ], [ 29.790753164556961, -1.480151724137931 ], [ 29.790753164556961, -1.47988224137931 ], [ 29.790483670886076, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30448, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.47988224137931 ], [ 29.790753164556961, -1.480151724137931 ], [ 29.791022658227849, -1.480151724137931 ], [ 29.791022658227849, -1.47988224137931 ], [ 29.790753164556961, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30449, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.47988224137931 ], [ 29.799107468354432, -1.480151724137931 ], [ 29.799376962025317, -1.480151724137931 ], [ 29.799376962025317, -1.47988224137931 ], [ 29.799107468354432, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30450, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.47988224137931 ], [ 29.799376962025317, -1.480151724137931 ], [ 29.799646455696202, -1.480151724137931 ], [ 29.799646455696202, -1.47988224137931 ], [ 29.799376962025317, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30451, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.47988224137931 ], [ 29.799646455696202, -1.480151724137931 ], [ 29.79991594936709, -1.480151724137931 ], [ 29.79991594936709, -1.47988224137931 ], [ 29.799646455696202, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30452, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.47988224137931 ], [ 29.79991594936709, -1.480151724137931 ], [ 29.800185443037975, -1.480151724137931 ], [ 29.800185443037975, -1.47988224137931 ], [ 29.79991594936709, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30453, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.47988224137931 ], [ 29.800185443037975, -1.480151724137931 ], [ 29.80045493670886, -1.480151724137931 ], [ 29.80045493670886, -1.47988224137931 ], [ 29.800185443037975, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30454, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.47988224137931 ], [ 29.80045493670886, -1.480151724137931 ], [ 29.800724430379748, -1.480151724137931 ], [ 29.800724430379748, -1.47988224137931 ], [ 29.80045493670886, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30455, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.47988224137931 ], [ 29.800724430379748, -1.480151724137931 ], [ 29.800993924050633, -1.480151724137931 ], [ 29.800993924050633, -1.47988224137931 ], [ 29.800724430379748, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30456, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.480151724137931 ], [ 29.762186835443039, -1.480421206896552 ], [ 29.762456329113924, -1.480421206896552 ], [ 29.762456329113924, -1.480151724137931 ], [ 29.762186835443039, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30457, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.480151724137931 ], [ 29.762456329113924, -1.480421206896552 ], [ 29.762725822784809, -1.480421206896552 ], [ 29.762725822784809, -1.480151724137931 ], [ 29.762456329113924, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30458, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.480151724137931 ], [ 29.762725822784809, -1.480421206896552 ], [ 29.762995316455697, -1.480421206896552 ], [ 29.762995316455697, -1.480151724137931 ], [ 29.762725822784809, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30459, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.480151724137931 ], [ 29.762995316455697, -1.480421206896552 ], [ 29.763264810126582, -1.480421206896552 ], [ 29.763264810126582, -1.480151724137931 ], [ 29.762995316455697, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30460, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.480151724137931 ], [ 29.763264810126582, -1.480421206896552 ], [ 29.763534303797467, -1.480421206896552 ], [ 29.763534303797467, -1.480151724137931 ], [ 29.763264810126582, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30461, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.480151724137931 ], [ 29.763534303797467, -1.480421206896552 ], [ 29.763803797468356, -1.480421206896552 ], [ 29.763803797468356, -1.480151724137931 ], [ 29.763534303797467, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30462, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.480151724137931 ], [ 29.763803797468356, -1.480421206896552 ], [ 29.76407329113924, -1.480421206896552 ], [ 29.76407329113924, -1.480151724137931 ], [ 29.763803797468356, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30463, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.480151724137931 ], [ 29.76407329113924, -1.480421206896552 ], [ 29.764342784810125, -1.480421206896552 ], [ 29.764342784810125, -1.480151724137931 ], [ 29.76407329113924, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30464, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.480151724137931 ], [ 29.764342784810125, -1.480421206896552 ], [ 29.764612278481014, -1.480421206896552 ], [ 29.764612278481014, -1.480151724137931 ], [ 29.764342784810125, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30465, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.480151724137931 ], [ 29.764612278481014, -1.480421206896552 ], [ 29.764881772151899, -1.480421206896552 ], [ 29.764881772151899, -1.480151724137931 ], [ 29.764612278481014, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30466, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.480151724137931 ], [ 29.764881772151899, -1.480421206896552 ], [ 29.765151265822784, -1.480421206896552 ], [ 29.765151265822784, -1.480151724137931 ], [ 29.764881772151899, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30467, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.480151724137931 ], [ 29.765151265822784, -1.480421206896552 ], [ 29.765420759493672, -1.480421206896552 ], [ 29.765420759493672, -1.480151724137931 ], [ 29.765151265822784, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30468, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.480151724137931 ], [ 29.765420759493672, -1.480421206896552 ], [ 29.765959746835442, -1.480421206896552 ], [ 29.765959746835442, -1.480151724137931 ], [ 29.765420759493672, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30469, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.480151724137931 ], [ 29.765959746835442, -1.480421206896552 ], [ 29.766229240506327, -1.480421206896552 ], [ 29.766229240506327, -1.480151724137931 ], [ 29.765959746835442, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30470, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.480151724137931 ], [ 29.766498734177215, -1.480421206896552 ], [ 29.7667682278481, -1.480421206896552 ], [ 29.7667682278481, -1.480151724137931 ], [ 29.766498734177215, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30471, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.480151724137931 ], [ 29.7667682278481, -1.480421206896552 ], [ 29.767037721518985, -1.480421206896552 ], [ 29.767037721518985, -1.480151724137931 ], [ 29.7667682278481, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30472, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.480151724137931 ], [ 29.767037721518985, -1.480421206896552 ], [ 29.767307215189874, -1.480421206896552 ], [ 29.767307215189874, -1.480151724137931 ], [ 29.767037721518985, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30473, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.480151724137931 ], [ 29.767307215189874, -1.480421206896552 ], [ 29.767576708860759, -1.480421206896552 ], [ 29.767576708860759, -1.480151724137931 ], [ 29.767307215189874, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30474, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.480151724137931 ], [ 29.767576708860759, -1.480421206896552 ], [ 29.767846202531643, -1.480421206896552 ], [ 29.767846202531643, -1.480151724137931 ], [ 29.767576708860759, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30475, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.480151724137931 ], [ 29.767846202531643, -1.480421206896552 ], [ 29.768115696202532, -1.480421206896552 ], [ 29.768115696202532, -1.480151724137931 ], [ 29.767846202531643, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30476, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.480151724137931 ], [ 29.768115696202532, -1.480421206896552 ], [ 29.768385189873417, -1.480421206896552 ], [ 29.768385189873417, -1.480151724137931 ], [ 29.768115696202532, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30477, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.480151724137931 ], [ 29.768385189873417, -1.480421206896552 ], [ 29.76892417721519, -1.480421206896552 ], [ 29.76892417721519, -1.480151724137931 ], [ 29.768385189873417, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30478, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.480151724137931 ], [ 29.76892417721519, -1.480421206896552 ], [ 29.770002151898733, -1.480421206896552 ], [ 29.770002151898733, -1.480151724137931 ], [ 29.76892417721519, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30479, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.480151724137931 ], [ 29.770002151898733, -1.480421206896552 ], [ 29.770541139240507, -1.480421206896552 ], [ 29.770541139240507, -1.480151724137931 ], [ 29.770002151898733, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30480, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.480151724137931 ], [ 29.770541139240507, -1.480421206896552 ], [ 29.770810632911392, -1.480421206896552 ], [ 29.770810632911392, -1.480151724137931 ], [ 29.770541139240507, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30481, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.480151724137931 ], [ 29.770810632911392, -1.480421206896552 ], [ 29.771080126582277, -1.480421206896552 ], [ 29.771080126582277, -1.480151724137931 ], [ 29.770810632911392, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30482, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.480151724137931 ], [ 29.771080126582277, -1.480421206896552 ], [ 29.771349620253165, -1.480421206896552 ], [ 29.771349620253165, -1.480151724137931 ], [ 29.771080126582277, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30483, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.480151724137931 ], [ 29.771349620253165, -1.480421206896552 ], [ 29.77161911392405, -1.480421206896552 ], [ 29.77161911392405, -1.480151724137931 ], [ 29.771349620253165, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30484, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.480151724137931 ], [ 29.77161911392405, -1.480421206896552 ], [ 29.771888607594935, -1.480421206896552 ], [ 29.771888607594935, -1.480151724137931 ], [ 29.77161911392405, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30485, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.480151724137931 ], [ 29.771888607594935, -1.480421206896552 ], [ 29.772158101265823, -1.480421206896552 ], [ 29.772158101265823, -1.480151724137931 ], [ 29.771888607594935, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30486, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.480151724137931 ], [ 29.772158101265823, -1.480421206896552 ], [ 29.772427594936708, -1.480421206896552 ], [ 29.772427594936708, -1.480151724137931 ], [ 29.772158101265823, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30487, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.480151724137931 ], [ 29.772427594936708, -1.480421206896552 ], [ 29.772697088607593, -1.480421206896552 ], [ 29.772697088607593, -1.480151724137931 ], [ 29.772427594936708, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30488, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.480151724137931 ], [ 29.772697088607593, -1.480421206896552 ], [ 29.772966582278482, -1.480421206896552 ], [ 29.772966582278482, -1.480151724137931 ], [ 29.772697088607593, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30489, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.480151724137931 ], [ 29.772966582278482, -1.480421206896552 ], [ 29.773236075949367, -1.480421206896552 ], [ 29.773236075949367, -1.480151724137931 ], [ 29.772966582278482, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30490, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.480151724137931 ], [ 29.773236075949367, -1.480421206896552 ], [ 29.773505569620252, -1.480421206896552 ], [ 29.773505569620252, -1.480151724137931 ], [ 29.773236075949367, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30491, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.480151724137931 ], [ 29.773505569620252, -1.480421206896552 ], [ 29.77377506329114, -1.480421206896552 ], [ 29.77377506329114, -1.480151724137931 ], [ 29.773505569620252, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30492, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.480151724137931 ], [ 29.77377506329114, -1.480421206896552 ], [ 29.774044556962025, -1.480421206896552 ], [ 29.774044556962025, -1.480151724137931 ], [ 29.77377506329114, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30493, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.480151724137931 ], [ 29.774044556962025, -1.480421206896552 ], [ 29.77431405063291, -1.480421206896552 ], [ 29.77431405063291, -1.480151724137931 ], [ 29.774044556962025, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30494, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.480151724137931 ], [ 29.77431405063291, -1.480421206896552 ], [ 29.774853037974683, -1.480421206896552 ], [ 29.774853037974683, -1.480151724137931 ], [ 29.77431405063291, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30495, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.480151724137931 ], [ 29.774853037974683, -1.480421206896552 ], [ 29.775122531645568, -1.480421206896552 ], [ 29.775122531645568, -1.480151724137931 ], [ 29.774853037974683, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30496, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.480151724137931 ], [ 29.775122531645568, -1.480421206896552 ], [ 29.775392025316457, -1.480421206896552 ], [ 29.775392025316457, -1.480151724137931 ], [ 29.775122531645568, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30497, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.480151724137931 ], [ 29.775392025316457, -1.480421206896552 ], [ 29.775661518987341, -1.480421206896552 ], [ 29.775661518987341, -1.480151724137931 ], [ 29.775392025316457, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30498, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.480151724137931 ], [ 29.775661518987341, -1.480421206896552 ], [ 29.775931012658226, -1.480421206896552 ], [ 29.775931012658226, -1.480151724137931 ], [ 29.775661518987341, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30499, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.480151724137931 ], [ 29.775931012658226, -1.480421206896552 ], [ 29.776200506329115, -1.480421206896552 ], [ 29.776200506329115, -1.480151724137931 ], [ 29.775931012658226, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30500, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.480151724137931 ], [ 29.776200506329115, -1.480421206896552 ], [ 29.77647, -1.480421206896552 ], [ 29.77647, -1.480151724137931 ], [ 29.776200506329115, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30501, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.480151724137931 ], [ 29.77647, -1.480421206896552 ], [ 29.776739493670885, -1.480421206896552 ], [ 29.776739493670885, -1.480151724137931 ], [ 29.77647, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30502, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.480151724137931 ], [ 29.776739493670885, -1.480421206896552 ], [ 29.777008987341773, -1.480421206896552 ], [ 29.777008987341773, -1.480151724137931 ], [ 29.776739493670885, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30503, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.480151724137931 ], [ 29.777008987341773, -1.480421206896552 ], [ 29.777278481012658, -1.480421206896552 ], [ 29.777278481012658, -1.480151724137931 ], [ 29.777008987341773, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30504, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.480151724137931 ], [ 29.777278481012658, -1.480421206896552 ], [ 29.777817468354431, -1.480421206896552 ], [ 29.777817468354431, -1.480151724137931 ], [ 29.777278481012658, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30505, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.480151724137931 ], [ 29.778086962025316, -1.480421206896552 ], [ 29.778356455696201, -1.480421206896552 ], [ 29.778356455696201, -1.480151724137931 ], [ 29.778086962025316, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30506, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.480151724137931 ], [ 29.778356455696201, -1.480421206896552 ], [ 29.77862594936709, -1.480421206896552 ], [ 29.77862594936709, -1.480151724137931 ], [ 29.778356455696201, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30507, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.480151724137931 ], [ 29.77862594936709, -1.480421206896552 ], [ 29.778895443037975, -1.480421206896552 ], [ 29.778895443037975, -1.480151724137931 ], [ 29.77862594936709, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30508, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.480151724137931 ], [ 29.778895443037975, -1.480421206896552 ], [ 29.77916493670886, -1.480421206896552 ], [ 29.77916493670886, -1.480151724137931 ], [ 29.778895443037975, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30509, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.480151724137931 ], [ 29.77916493670886, -1.480421206896552 ], [ 29.779434430379748, -1.480421206896552 ], [ 29.779434430379748, -1.480151724137931 ], [ 29.77916493670886, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30510, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.480151724137931 ], [ 29.779434430379748, -1.480421206896552 ], [ 29.779703924050633, -1.480421206896552 ], [ 29.779703924050633, -1.480151724137931 ], [ 29.779434430379748, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30511, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.480151724137931 ], [ 29.779703924050633, -1.480421206896552 ], [ 29.779973417721518, -1.480421206896552 ], [ 29.779973417721518, -1.480151724137931 ], [ 29.779703924050633, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30512, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.480151724137931 ], [ 29.779973417721518, -1.480421206896552 ], [ 29.780242911392406, -1.480421206896552 ], [ 29.780242911392406, -1.480151724137931 ], [ 29.779973417721518, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30513, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.480151724137931 ], [ 29.780512405063291, -1.480421206896552 ], [ 29.780781898734176, -1.480421206896552 ], [ 29.780781898734176, -1.480151724137931 ], [ 29.780512405063291, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30514, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.480151724137931 ], [ 29.780781898734176, -1.480421206896552 ], [ 29.781051392405065, -1.480421206896552 ], [ 29.781051392405065, -1.480151724137931 ], [ 29.780781898734176, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30515, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.480151724137931 ], [ 29.781051392405065, -1.480421206896552 ], [ 29.78132088607595, -1.480421206896552 ], [ 29.78132088607595, -1.480151724137931 ], [ 29.781051392405065, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30516, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.480151724137931 ], [ 29.78132088607595, -1.480421206896552 ], [ 29.781590379746834, -1.480421206896552 ], [ 29.781590379746834, -1.480151724137931 ], [ 29.78132088607595, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30517, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.480151724137931 ], [ 29.781590379746834, -1.480421206896552 ], [ 29.781859873417723, -1.480421206896552 ], [ 29.781859873417723, -1.480151724137931 ], [ 29.781590379746834, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30518, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.480151724137931 ], [ 29.781859873417723, -1.480421206896552 ], [ 29.782129367088608, -1.480421206896552 ], [ 29.782129367088608, -1.480151724137931 ], [ 29.781859873417723, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30519, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.480151724137931 ], [ 29.782129367088608, -1.480421206896552 ], [ 29.782398860759493, -1.480421206896552 ], [ 29.782398860759493, -1.480151724137931 ], [ 29.782129367088608, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30520, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.480151724137931 ], [ 29.782398860759493, -1.480421206896552 ], [ 29.782668354430381, -1.480421206896552 ], [ 29.782668354430381, -1.480151724137931 ], [ 29.782398860759493, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30521, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.480151724137931 ], [ 29.782668354430381, -1.480421206896552 ], [ 29.782937848101266, -1.480421206896552 ], [ 29.782937848101266, -1.480151724137931 ], [ 29.782668354430381, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30522, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.480151724137931 ], [ 29.782937848101266, -1.480421206896552 ], [ 29.783207341772151, -1.480421206896552 ], [ 29.783207341772151, -1.480151724137931 ], [ 29.782937848101266, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30523, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.480151724137931 ], [ 29.783207341772151, -1.480421206896552 ], [ 29.783476835443039, -1.480421206896552 ], [ 29.783476835443039, -1.480151724137931 ], [ 29.783207341772151, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30524, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.480151724137931 ], [ 29.783476835443039, -1.480421206896552 ], [ 29.784015822784809, -1.480421206896552 ], [ 29.784015822784809, -1.480151724137931 ], [ 29.783476835443039, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30525, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.480151724137931 ], [ 29.784015822784809, -1.480421206896552 ], [ 29.784285316455698, -1.480421206896552 ], [ 29.784285316455698, -1.480151724137931 ], [ 29.784015822784809, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30526, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.480151724137931 ], [ 29.784285316455698, -1.480421206896552 ], [ 29.784554810126583, -1.480421206896552 ], [ 29.784554810126583, -1.480151724137931 ], [ 29.784285316455698, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30527, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.480151724137931 ], [ 29.787788734177216, -1.480421206896552 ], [ 29.788866708860759, -1.480421206896552 ], [ 29.788866708860759, -1.480151724137931 ], [ 29.787788734177216, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30528, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.480151724137931 ], [ 29.788866708860759, -1.480421206896552 ], [ 29.789136202531644, -1.480421206896552 ], [ 29.789136202531644, -1.480151724137931 ], [ 29.788866708860759, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30529, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.480151724137931 ], [ 29.789136202531644, -1.480421206896552 ], [ 29.789405696202532, -1.480421206896552 ], [ 29.789405696202532, -1.480151724137931 ], [ 29.789136202531644, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30530, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.480151724137931 ], [ 29.789405696202532, -1.480421206896552 ], [ 29.789675189873417, -1.480421206896552 ], [ 29.789675189873417, -1.480151724137931 ], [ 29.789405696202532, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30531, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.480151724137931 ], [ 29.790214177215191, -1.480421206896552 ], [ 29.790483670886076, -1.480421206896552 ], [ 29.790483670886076, -1.480151724137931 ], [ 29.790214177215191, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30532, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.480151724137931 ], [ 29.790483670886076, -1.480421206896552 ], [ 29.790753164556961, -1.480421206896552 ], [ 29.790753164556961, -1.480151724137931 ], [ 29.790483670886076, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30533, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.480151724137931 ], [ 29.790753164556961, -1.480421206896552 ], [ 29.791022658227849, -1.480421206896552 ], [ 29.791022658227849, -1.480151724137931 ], [ 29.790753164556961, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30534, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.480151724137931 ], [ 29.791022658227849, -1.480421206896552 ], [ 29.791292151898734, -1.480421206896552 ], [ 29.791292151898734, -1.480151724137931 ], [ 29.791022658227849, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30535, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.480151724137931 ], [ 29.798568481012659, -1.480421206896552 ], [ 29.798837974683543, -1.480421206896552 ], [ 29.798837974683543, -1.480151724137931 ], [ 29.798568481012659, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30536, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.480151724137931 ], [ 29.798837974683543, -1.480421206896552 ], [ 29.799107468354432, -1.480421206896552 ], [ 29.799107468354432, -1.480151724137931 ], [ 29.798837974683543, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30537, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.480151724137931 ], [ 29.799107468354432, -1.480421206896552 ], [ 29.799376962025317, -1.480421206896552 ], [ 29.799376962025317, -1.480151724137931 ], [ 29.799107468354432, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30538, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.480151724137931 ], [ 29.799376962025317, -1.480421206896552 ], [ 29.799646455696202, -1.480421206896552 ], [ 29.799646455696202, -1.480151724137931 ], [ 29.799376962025317, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30539, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.480151724137931 ], [ 29.799646455696202, -1.480421206896552 ], [ 29.79991594936709, -1.480421206896552 ], [ 29.79991594936709, -1.480151724137931 ], [ 29.799646455696202, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30540, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.480151724137931 ], [ 29.79991594936709, -1.480421206896552 ], [ 29.800185443037975, -1.480421206896552 ], [ 29.800185443037975, -1.480151724137931 ], [ 29.79991594936709, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30541, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.480151724137931 ], [ 29.800185443037975, -1.480421206896552 ], [ 29.80045493670886, -1.480421206896552 ], [ 29.80045493670886, -1.480151724137931 ], [ 29.800185443037975, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30542, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.480151724137931 ], [ 29.80045493670886, -1.480421206896552 ], [ 29.800724430379748, -1.480421206896552 ], [ 29.800724430379748, -1.480151724137931 ], [ 29.80045493670886, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30543, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.480151724137931 ], [ 29.800724430379748, -1.480421206896552 ], [ 29.800993924050633, -1.480421206896552 ], [ 29.800993924050633, -1.480151724137931 ], [ 29.800724430379748, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30544, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79776, -1.477995862068966 ], [ 29.79776, -1.480960172413793 ], [ 29.798029493670885, -1.480960172413793 ], [ 29.798029493670885, -1.477995862068966 ], [ 29.79776, -1.477995862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30545, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.478534827586207 ], [ 29.801263417721518, -1.481768620689655 ], [ 29.801532911392407, -1.481768620689655 ], [ 29.801532911392407, -1.478534827586207 ], [ 29.801263417721518, -1.478534827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30546, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.478804310344828 ], [ 29.800993924050633, -1.481768620689655 ], [ 29.801263417721518, -1.481768620689655 ], [ 29.801263417721518, -1.478804310344828 ], [ 29.800993924050633, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30547, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.478804310344828 ], [ 29.801532911392407, -1.482038103448276 ], [ 29.802071898734177, -1.482038103448276 ], [ 29.802071898734177, -1.478804310344828 ], [ 29.801532911392407, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30548, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.478804310344828 ], [ 29.802071898734177, -1.482038103448276 ], [ 29.802341392405065, -1.482038103448276 ], [ 29.802341392405065, -1.478804310344828 ], [ 29.802071898734177, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30549, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.478804310344828 ], [ 29.80261088607595, -1.481768620689655 ], [ 29.802880379746835, -1.481768620689655 ], [ 29.802880379746835, -1.478804310344828 ], [ 29.80261088607595, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30550, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.478804310344828 ], [ 29.802880379746835, -1.481768620689655 ], [ 29.803149873417723, -1.481768620689655 ], [ 29.803149873417723, -1.478804310344828 ], [ 29.802880379746835, -1.478804310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30551, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.479073793103448 ], [ 29.802341392405065, -1.481499137931034 ], [ 29.80261088607595, -1.481499137931034 ], [ 29.80261088607595, -1.479073793103448 ], [ 29.802341392405065, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30552, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.479073793103448 ], [ 29.803149873417723, -1.481499137931034 ], [ 29.803419367088608, -1.481499137931034 ], [ 29.803419367088608, -1.479073793103448 ], [ 29.803149873417723, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30553, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.479073793103448 ], [ 29.803419367088608, -1.481499137931034 ], [ 29.803688860759493, -1.481499137931034 ], [ 29.803688860759493, -1.479073793103448 ], [ 29.803419367088608, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30554, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.479073793103448 ], [ 29.803688860759493, -1.481499137931034 ], [ 29.803958354430382, -1.481499137931034 ], [ 29.803958354430382, -1.479073793103448 ], [ 29.803688860759493, -1.479073793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30555, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761378354430381, -1.47961275862069 ], [ 29.761378354430381, -1.480960172413793 ], [ 29.761647848101266, -1.480960172413793 ], [ 29.761647848101266, -1.47961275862069 ], [ 29.761378354430381, -1.47961275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30556, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.47988224137931 ], [ 29.789405696202532, -1.480151724137931 ], [ 29.789675189873417, -1.480151724137931 ], [ 29.789675189873417, -1.480421206896552 ], [ 29.789944683544302, -1.480421206896552 ], [ 29.789944683544302, -1.480960172413793 ], [ 29.790214177215191, -1.480960172413793 ], [ 29.790214177215191, -1.481229655172414 ], [ 29.790483670886076, -1.481229655172414 ], [ 29.790483670886076, -1.481499137931034 ], [ 29.791022658227849, -1.481499137931034 ], [ 29.791022658227849, -1.481229655172414 ], [ 29.790753164556961, -1.481229655172414 ], [ 29.790753164556961, -1.480960172413793 ], [ 29.790483670886076, -1.480960172413793 ], [ 29.790483670886076, -1.480421206896552 ], [ 29.790214177215191, -1.480421206896552 ], [ 29.790214177215191, -1.480151724137931 ], [ 29.789944683544302, -1.480151724137931 ], [ 29.789944683544302, -1.47988224137931 ], [ 29.789405696202532, -1.47988224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30557, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.480151724137931 ], [ 29.777817468354431, -1.480960172413793 ], [ 29.778086962025316, -1.480960172413793 ], [ 29.778086962025316, -1.480151724137931 ], [ 29.777817468354431, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30558, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.480151724137931 ], [ 29.785093797468356, -1.480960172413793 ], [ 29.785363291139241, -1.480960172413793 ], [ 29.785363291139241, -1.480151724137931 ], [ 29.785093797468356, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30559, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.480151724137931 ], [ 29.785363291139241, -1.480960172413793 ], [ 29.785632784810126, -1.480960172413793 ], [ 29.785632784810126, -1.480151724137931 ], [ 29.785363291139241, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30560, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.480151724137931 ], [ 29.785632784810126, -1.481229655172414 ], [ 29.785902278481014, -1.481229655172414 ], [ 29.785902278481014, -1.480151724137931 ], [ 29.785632784810126, -1.480151724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30561, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.480421206896552 ], [ 29.761647848101266, -1.480960172413793 ], [ 29.761917341772151, -1.480960172413793 ], [ 29.761917341772151, -1.480421206896552 ], [ 29.761647848101266, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30562, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.480421206896552 ], [ 29.761917341772151, -1.480960172413793 ], [ 29.762186835443039, -1.480960172413793 ], [ 29.762186835443039, -1.480421206896552 ], [ 29.761917341772151, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.480421206896552 ], [ 29.762186835443039, -1.480960172413793 ], [ 29.762456329113924, -1.480960172413793 ], [ 29.762456329113924, -1.480421206896552 ], [ 29.762186835443039, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30564, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.480421206896552 ], [ 29.762456329113924, -1.480960172413793 ], [ 29.762725822784809, -1.480960172413793 ], [ 29.762725822784809, -1.480421206896552 ], [ 29.762456329113924, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30565, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.480421206896552 ], [ 29.762725822784809, -1.480960172413793 ], [ 29.762995316455697, -1.480960172413793 ], [ 29.762995316455697, -1.480421206896552 ], [ 29.762725822784809, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30566, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.480421206896552 ], [ 29.762995316455697, -1.480960172413793 ], [ 29.763264810126582, -1.480960172413793 ], [ 29.763264810126582, -1.480421206896552 ], [ 29.762995316455697, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30567, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.480421206896552 ], [ 29.763264810126582, -1.480960172413793 ], [ 29.763534303797467, -1.480960172413793 ], [ 29.763534303797467, -1.480421206896552 ], [ 29.763264810126582, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30568, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.480421206896552 ], [ 29.763534303797467, -1.480960172413793 ], [ 29.763803797468356, -1.480960172413793 ], [ 29.763803797468356, -1.480421206896552 ], [ 29.763534303797467, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30569, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.480421206896552 ], [ 29.763803797468356, -1.480960172413793 ], [ 29.76407329113924, -1.480960172413793 ], [ 29.76407329113924, -1.480421206896552 ], [ 29.763803797468356, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30570, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.480421206896552 ], [ 29.76407329113924, -1.480960172413793 ], [ 29.764342784810125, -1.480960172413793 ], [ 29.764342784810125, -1.480421206896552 ], [ 29.76407329113924, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30571, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.480421206896552 ], [ 29.764342784810125, -1.480960172413793 ], [ 29.764612278481014, -1.480960172413793 ], [ 29.764612278481014, -1.480421206896552 ], [ 29.764342784810125, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30572, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.480421206896552 ], [ 29.764612278481014, -1.480960172413793 ], [ 29.764881772151899, -1.480960172413793 ], [ 29.764881772151899, -1.480421206896552 ], [ 29.764612278481014, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30573, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.480421206896552 ], [ 29.764881772151899, -1.480960172413793 ], [ 29.765151265822784, -1.480960172413793 ], [ 29.765151265822784, -1.480421206896552 ], [ 29.764881772151899, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30574, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.480421206896552 ], [ 29.765151265822784, -1.480960172413793 ], [ 29.765420759493672, -1.480960172413793 ], [ 29.765420759493672, -1.480421206896552 ], [ 29.765151265822784, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30575, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.480421206896552 ], [ 29.765420759493672, -1.480960172413793 ], [ 29.765959746835442, -1.480960172413793 ], [ 29.765959746835442, -1.480421206896552 ], [ 29.765420759493672, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30576, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.480421206896552 ], [ 29.765959746835442, -1.480960172413793 ], [ 29.766229240506327, -1.480960172413793 ], [ 29.766229240506327, -1.480421206896552 ], [ 29.765959746835442, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30577, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.480421206896552 ], [ 29.766229240506327, -1.480960172413793 ], [ 29.766498734177215, -1.480960172413793 ], [ 29.766498734177215, -1.480421206896552 ], [ 29.766229240506327, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30578, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.480421206896552 ], [ 29.766498734177215, -1.480960172413793 ], [ 29.7667682278481, -1.480960172413793 ], [ 29.7667682278481, -1.480421206896552 ], [ 29.766498734177215, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30579, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.480421206896552 ], [ 29.7667682278481, -1.480960172413793 ], [ 29.767037721518985, -1.480960172413793 ], [ 29.767037721518985, -1.480421206896552 ], [ 29.7667682278481, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30580, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.480421206896552 ], [ 29.767037721518985, -1.480960172413793 ], [ 29.767307215189874, -1.480960172413793 ], [ 29.767307215189874, -1.480421206896552 ], [ 29.767037721518985, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30581, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.480421206896552 ], [ 29.767307215189874, -1.480960172413793 ], [ 29.767576708860759, -1.480960172413793 ], [ 29.767576708860759, -1.480421206896552 ], [ 29.767307215189874, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30582, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.480421206896552 ], [ 29.767576708860759, -1.480960172413793 ], [ 29.767846202531643, -1.480960172413793 ], [ 29.767846202531643, -1.480421206896552 ], [ 29.767576708860759, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30583, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.480421206896552 ], [ 29.767846202531643, -1.480960172413793 ], [ 29.768115696202532, -1.480960172413793 ], [ 29.768115696202532, -1.480421206896552 ], [ 29.767846202531643, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30584, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.480421206896552 ], [ 29.768115696202532, -1.480960172413793 ], [ 29.768385189873417, -1.480960172413793 ], [ 29.768385189873417, -1.480421206896552 ], [ 29.768115696202532, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30585, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.480421206896552 ], [ 29.768385189873417, -1.480960172413793 ], [ 29.770541139240507, -1.480960172413793 ], [ 29.770541139240507, -1.480421206896552 ], [ 29.768385189873417, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30586, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.480421206896552 ], [ 29.770541139240507, -1.480960172413793 ], [ 29.770810632911392, -1.480960172413793 ], [ 29.770810632911392, -1.480421206896552 ], [ 29.770541139240507, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30587, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.480421206896552 ], [ 29.770810632911392, -1.480960172413793 ], [ 29.771080126582277, -1.480960172413793 ], [ 29.771080126582277, -1.480421206896552 ], [ 29.770810632911392, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30588, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.480421206896552 ], [ 29.771080126582277, -1.480960172413793 ], [ 29.771349620253165, -1.480960172413793 ], [ 29.771349620253165, -1.480421206896552 ], [ 29.771080126582277, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30589, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.480421206896552 ], [ 29.771349620253165, -1.480960172413793 ], [ 29.77161911392405, -1.480960172413793 ], [ 29.77161911392405, -1.480421206896552 ], [ 29.771349620253165, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30590, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.480421206896552 ], [ 29.77161911392405, -1.480960172413793 ], [ 29.771888607594935, -1.480960172413793 ], [ 29.771888607594935, -1.480421206896552 ], [ 29.77161911392405, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30591, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.480421206896552 ], [ 29.771888607594935, -1.480960172413793 ], [ 29.772158101265823, -1.480960172413793 ], [ 29.772158101265823, -1.480421206896552 ], [ 29.771888607594935, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30592, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.480421206896552 ], [ 29.772158101265823, -1.480960172413793 ], [ 29.772427594936708, -1.480960172413793 ], [ 29.772427594936708, -1.480421206896552 ], [ 29.772158101265823, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30593, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.480421206896552 ], [ 29.772427594936708, -1.480960172413793 ], [ 29.772697088607593, -1.480960172413793 ], [ 29.772697088607593, -1.480421206896552 ], [ 29.772427594936708, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30594, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.480421206896552 ], [ 29.772697088607593, -1.480960172413793 ], [ 29.772966582278482, -1.480960172413793 ], [ 29.772966582278482, -1.480421206896552 ], [ 29.772697088607593, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30595, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.480421206896552 ], [ 29.772966582278482, -1.480960172413793 ], [ 29.773236075949367, -1.480960172413793 ], [ 29.773236075949367, -1.480421206896552 ], [ 29.772966582278482, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30596, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.480421206896552 ], [ 29.773236075949367, -1.480960172413793 ], [ 29.773505569620252, -1.480960172413793 ], [ 29.773505569620252, -1.480421206896552 ], [ 29.773236075949367, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30597, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.480421206896552 ], [ 29.773505569620252, -1.480960172413793 ], [ 29.77377506329114, -1.480960172413793 ], [ 29.77377506329114, -1.480421206896552 ], [ 29.773505569620252, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30598, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.480421206896552 ], [ 29.77377506329114, -1.480960172413793 ], [ 29.774044556962025, -1.480960172413793 ], [ 29.774044556962025, -1.480421206896552 ], [ 29.77377506329114, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30599, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.480421206896552 ], [ 29.774044556962025, -1.480960172413793 ], [ 29.77431405063291, -1.480960172413793 ], [ 29.77431405063291, -1.480421206896552 ], [ 29.774044556962025, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30600, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.480421206896552 ], [ 29.77431405063291, -1.480960172413793 ], [ 29.774853037974683, -1.480960172413793 ], [ 29.774853037974683, -1.480421206896552 ], [ 29.77431405063291, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30601, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.480421206896552 ], [ 29.774853037974683, -1.480960172413793 ], [ 29.775122531645568, -1.480960172413793 ], [ 29.775122531645568, -1.480421206896552 ], [ 29.774853037974683, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30602, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.480421206896552 ], [ 29.775122531645568, -1.480960172413793 ], [ 29.775392025316457, -1.480960172413793 ], [ 29.775392025316457, -1.480421206896552 ], [ 29.775122531645568, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30603, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.480421206896552 ], [ 29.775392025316457, -1.480960172413793 ], [ 29.775661518987341, -1.480960172413793 ], [ 29.775661518987341, -1.480421206896552 ], [ 29.775392025316457, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30604, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.480421206896552 ], [ 29.775661518987341, -1.480960172413793 ], [ 29.775931012658226, -1.480960172413793 ], [ 29.775931012658226, -1.480421206896552 ], [ 29.775661518987341, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30605, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.480421206896552 ], [ 29.775931012658226, -1.480960172413793 ], [ 29.776200506329115, -1.480960172413793 ], [ 29.776200506329115, -1.480421206896552 ], [ 29.775931012658226, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30606, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.480421206896552 ], [ 29.776200506329115, -1.480960172413793 ], [ 29.77647, -1.480960172413793 ], [ 29.77647, -1.480421206896552 ], [ 29.776200506329115, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30607, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.480421206896552 ], [ 29.77647, -1.480960172413793 ], [ 29.776739493670885, -1.480960172413793 ], [ 29.776739493670885, -1.480421206896552 ], [ 29.77647, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30608, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.480421206896552 ], [ 29.776739493670885, -1.480960172413793 ], [ 29.777008987341773, -1.480960172413793 ], [ 29.777008987341773, -1.480421206896552 ], [ 29.776739493670885, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30609, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.480421206896552 ], [ 29.777008987341773, -1.480960172413793 ], [ 29.777278481012658, -1.480960172413793 ], [ 29.777278481012658, -1.480421206896552 ], [ 29.777008987341773, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30610, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.480421206896552 ], [ 29.777278481012658, -1.480960172413793 ], [ 29.777547974683543, -1.480960172413793 ], [ 29.777547974683543, -1.480421206896552 ], [ 29.777278481012658, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30611, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.480421206896552 ], [ 29.777547974683543, -1.480960172413793 ], [ 29.777817468354431, -1.480960172413793 ], [ 29.777817468354431, -1.480421206896552 ], [ 29.777547974683543, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30612, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.480421206896552 ], [ 29.778086962025316, -1.480960172413793 ], [ 29.778356455696201, -1.480960172413793 ], [ 29.778356455696201, -1.480421206896552 ], [ 29.778086962025316, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30613, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.480421206896552 ], [ 29.778356455696201, -1.480960172413793 ], [ 29.77862594936709, -1.480960172413793 ], [ 29.77862594936709, -1.480421206896552 ], [ 29.778356455696201, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30614, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.480421206896552 ], [ 29.77862594936709, -1.480960172413793 ], [ 29.778895443037975, -1.480960172413793 ], [ 29.778895443037975, -1.480421206896552 ], [ 29.77862594936709, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30615, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.480421206896552 ], [ 29.778895443037975, -1.481229655172414 ], [ 29.77916493670886, -1.481229655172414 ], [ 29.77916493670886, -1.480421206896552 ], [ 29.778895443037975, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30616, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.480421206896552 ], [ 29.77916493670886, -1.480960172413793 ], [ 29.779434430379748, -1.480960172413793 ], [ 29.779434430379748, -1.480421206896552 ], [ 29.77916493670886, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30617, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.480421206896552 ], [ 29.779434430379748, -1.480960172413793 ], [ 29.779703924050633, -1.480960172413793 ], [ 29.779703924050633, -1.480421206896552 ], [ 29.779434430379748, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30618, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.480421206896552 ], [ 29.779703924050633, -1.480960172413793 ], [ 29.779973417721518, -1.480960172413793 ], [ 29.779973417721518, -1.480421206896552 ], [ 29.779703924050633, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30619, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.480421206896552 ], [ 29.779973417721518, -1.480960172413793 ], [ 29.780242911392406, -1.480960172413793 ], [ 29.780242911392406, -1.480421206896552 ], [ 29.779973417721518, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30620, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.480421206896552 ], [ 29.780242911392406, -1.480960172413793 ], [ 29.780512405063291, -1.480960172413793 ], [ 29.780512405063291, -1.480421206896552 ], [ 29.780242911392406, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30621, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.480421206896552 ], [ 29.780512405063291, -1.480960172413793 ], [ 29.780781898734176, -1.480960172413793 ], [ 29.780781898734176, -1.480421206896552 ], [ 29.780512405063291, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30622, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.480421206896552 ], [ 29.780781898734176, -1.480960172413793 ], [ 29.781051392405065, -1.480960172413793 ], [ 29.781051392405065, -1.480421206896552 ], [ 29.780781898734176, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30623, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.480421206896552 ], [ 29.781051392405065, -1.480960172413793 ], [ 29.78132088607595, -1.480960172413793 ], [ 29.78132088607595, -1.480421206896552 ], [ 29.781051392405065, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30624, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.480421206896552 ], [ 29.78132088607595, -1.481229655172414 ], [ 29.781590379746834, -1.481229655172414 ], [ 29.781590379746834, -1.480421206896552 ], [ 29.78132088607595, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30625, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.480421206896552 ], [ 29.781590379746834, -1.480960172413793 ], [ 29.781859873417723, -1.480960172413793 ], [ 29.781859873417723, -1.480421206896552 ], [ 29.781590379746834, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30626, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.480421206896552 ], [ 29.781859873417723, -1.480960172413793 ], [ 29.782129367088608, -1.480960172413793 ], [ 29.782129367088608, -1.480421206896552 ], [ 29.781859873417723, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30627, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.480421206896552 ], [ 29.782129367088608, -1.480960172413793 ], [ 29.782398860759493, -1.480960172413793 ], [ 29.782398860759493, -1.480421206896552 ], [ 29.782129367088608, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30628, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.480421206896552 ], [ 29.782398860759493, -1.480960172413793 ], [ 29.782668354430381, -1.480960172413793 ], [ 29.782668354430381, -1.480421206896552 ], [ 29.782398860759493, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30629, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.480421206896552 ], [ 29.782668354430381, -1.480960172413793 ], [ 29.782937848101266, -1.480960172413793 ], [ 29.782937848101266, -1.480421206896552 ], [ 29.782668354430381, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30630, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.480421206896552 ], [ 29.782937848101266, -1.480960172413793 ], [ 29.783207341772151, -1.480960172413793 ], [ 29.783207341772151, -1.480421206896552 ], [ 29.782937848101266, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30631, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.480421206896552 ], [ 29.783207341772151, -1.480960172413793 ], [ 29.783476835443039, -1.480960172413793 ], [ 29.783476835443039, -1.480421206896552 ], [ 29.783207341772151, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30632, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.480421206896552 ], [ 29.783476835443039, -1.480960172413793 ], [ 29.784015822784809, -1.480960172413793 ], [ 29.784015822784809, -1.480421206896552 ], [ 29.783476835443039, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30633, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.480421206896552 ], [ 29.784015822784809, -1.480960172413793 ], [ 29.784285316455698, -1.480960172413793 ], [ 29.784285316455698, -1.480421206896552 ], [ 29.784015822784809, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30634, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.480421206896552 ], [ 29.784285316455698, -1.480960172413793 ], [ 29.784554810126583, -1.480960172413793 ], [ 29.784554810126583, -1.480421206896552 ], [ 29.784285316455698, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30635, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.480421206896552 ], [ 29.784554810126583, -1.480960172413793 ], [ 29.784824303797468, -1.480960172413793 ], [ 29.784824303797468, -1.480421206896552 ], [ 29.784554810126583, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30636, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.480421206896552 ], [ 29.784824303797468, -1.480960172413793 ], [ 29.785093797468356, -1.480960172413793 ], [ 29.785093797468356, -1.480421206896552 ], [ 29.784824303797468, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30637, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.480421206896552 ], [ 29.788866708860759, -1.480960172413793 ], [ 29.789136202531644, -1.480960172413793 ], [ 29.789136202531644, -1.480421206896552 ], [ 29.788866708860759, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30638, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.480421206896552 ], [ 29.789136202531644, -1.480960172413793 ], [ 29.789405696202532, -1.480960172413793 ], [ 29.789405696202532, -1.480421206896552 ], [ 29.789136202531644, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30639, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.480421206896552 ], [ 29.789405696202532, -1.480960172413793 ], [ 29.789675189873417, -1.480960172413793 ], [ 29.789675189873417, -1.480421206896552 ], [ 29.789405696202532, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30640, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.480421206896552 ], [ 29.789675189873417, -1.480960172413793 ], [ 29.789944683544302, -1.480960172413793 ], [ 29.789944683544302, -1.480421206896552 ], [ 29.789675189873417, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30641, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.480421206896552 ], [ 29.790483670886076, -1.480960172413793 ], [ 29.790753164556961, -1.480960172413793 ], [ 29.790753164556961, -1.480421206896552 ], [ 29.790483670886076, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30642, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.480421206896552 ], [ 29.790753164556961, -1.480960172413793 ], [ 29.791022658227849, -1.480960172413793 ], [ 29.791022658227849, -1.480421206896552 ], [ 29.790753164556961, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30643, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.480421206896552 ], [ 29.791022658227849, -1.480960172413793 ], [ 29.791292151898734, -1.480960172413793 ], [ 29.791292151898734, -1.480421206896552 ], [ 29.791022658227849, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30644, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.480421206896552 ], [ 29.791292151898734, -1.480960172413793 ], [ 29.791561645569619, -1.480960172413793 ], [ 29.791561645569619, -1.480421206896552 ], [ 29.791292151898734, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30645, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.480421206896552 ], [ 29.798029493670885, -1.480960172413793 ], [ 29.798298987341774, -1.480960172413793 ], [ 29.798298987341774, -1.480421206896552 ], [ 29.798029493670885, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30646, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.480421206896552 ], [ 29.798298987341774, -1.480960172413793 ], [ 29.798568481012659, -1.480960172413793 ], [ 29.798568481012659, -1.480421206896552 ], [ 29.798298987341774, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30647, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.480421206896552 ], [ 29.798568481012659, -1.480960172413793 ], [ 29.798837974683543, -1.480960172413793 ], [ 29.798837974683543, -1.480421206896552 ], [ 29.798568481012659, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30648, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.480421206896552 ], [ 29.798837974683543, -1.480960172413793 ], [ 29.799107468354432, -1.480960172413793 ], [ 29.799107468354432, -1.480421206896552 ], [ 29.798837974683543, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30649, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.480421206896552 ], [ 29.799107468354432, -1.480960172413793 ], [ 29.799376962025317, -1.480960172413793 ], [ 29.799376962025317, -1.480421206896552 ], [ 29.799107468354432, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30650, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.480421206896552 ], [ 29.799376962025317, -1.480960172413793 ], [ 29.799646455696202, -1.480960172413793 ], [ 29.799646455696202, -1.480421206896552 ], [ 29.799376962025317, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30651, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.480421206896552 ], [ 29.799646455696202, -1.480960172413793 ], [ 29.79991594936709, -1.480960172413793 ], [ 29.79991594936709, -1.480421206896552 ], [ 29.799646455696202, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30652, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.480421206896552 ], [ 29.79991594936709, -1.480960172413793 ], [ 29.800185443037975, -1.480960172413793 ], [ 29.800185443037975, -1.480421206896552 ], [ 29.79991594936709, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30653, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.480421206896552 ], [ 29.800185443037975, -1.480960172413793 ], [ 29.80045493670886, -1.480960172413793 ], [ 29.80045493670886, -1.480421206896552 ], [ 29.800185443037975, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30654, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.480421206896552 ], [ 29.80045493670886, -1.480960172413793 ], [ 29.800724430379748, -1.480960172413793 ], [ 29.800724430379748, -1.480421206896552 ], [ 29.80045493670886, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30655, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.480421206896552 ], [ 29.800724430379748, -1.480960172413793 ], [ 29.800993924050633, -1.480960172413793 ], [ 29.800993924050633, -1.480421206896552 ], [ 29.800724430379748, -1.480421206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30656, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761647848101266, -1.480960172413793 ], [ 29.761647848101266, -1.481229655172414 ], [ 29.761917341772151, -1.481229655172414 ], [ 29.761917341772151, -1.480960172413793 ], [ 29.761647848101266, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30657, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.480960172413793 ], [ 29.761917341772151, -1.481229655172414 ], [ 29.762186835443039, -1.481229655172414 ], [ 29.762186835443039, -1.480960172413793 ], [ 29.761917341772151, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30658, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.480960172413793 ], [ 29.762186835443039, -1.481229655172414 ], [ 29.762456329113924, -1.481229655172414 ], [ 29.762456329113924, -1.480960172413793 ], [ 29.762186835443039, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30659, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.480960172413793 ], [ 29.762456329113924, -1.481229655172414 ], [ 29.762725822784809, -1.481229655172414 ], [ 29.762725822784809, -1.480960172413793 ], [ 29.762456329113924, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30660, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.480960172413793 ], [ 29.762725822784809, -1.481229655172414 ], [ 29.762995316455697, -1.481229655172414 ], [ 29.762995316455697, -1.480960172413793 ], [ 29.762725822784809, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30661, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.480960172413793 ], [ 29.762995316455697, -1.481229655172414 ], [ 29.763264810126582, -1.481229655172414 ], [ 29.763264810126582, -1.480960172413793 ], [ 29.762995316455697, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30662, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.480960172413793 ], [ 29.763264810126582, -1.481229655172414 ], [ 29.763534303797467, -1.481229655172414 ], [ 29.763534303797467, -1.480960172413793 ], [ 29.763264810126582, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30663, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.480960172413793 ], [ 29.763534303797467, -1.481229655172414 ], [ 29.763803797468356, -1.481229655172414 ], [ 29.763803797468356, -1.480960172413793 ], [ 29.763534303797467, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30664, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.480960172413793 ], [ 29.763803797468356, -1.481229655172414 ], [ 29.76407329113924, -1.481229655172414 ], [ 29.76407329113924, -1.480960172413793 ], [ 29.763803797468356, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30665, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.480960172413793 ], [ 29.76407329113924, -1.481229655172414 ], [ 29.764342784810125, -1.481229655172414 ], [ 29.764342784810125, -1.480960172413793 ], [ 29.76407329113924, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30666, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.480960172413793 ], [ 29.764342784810125, -1.481229655172414 ], [ 29.764612278481014, -1.481229655172414 ], [ 29.764612278481014, -1.480960172413793 ], [ 29.764342784810125, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30667, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.480960172413793 ], [ 29.764612278481014, -1.481229655172414 ], [ 29.764881772151899, -1.481229655172414 ], [ 29.764881772151899, -1.480960172413793 ], [ 29.764612278481014, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30668, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.480960172413793 ], [ 29.764881772151899, -1.481229655172414 ], [ 29.765151265822784, -1.481229655172414 ], [ 29.765151265822784, -1.480960172413793 ], [ 29.764881772151899, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30669, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.480960172413793 ], [ 29.765151265822784, -1.481229655172414 ], [ 29.765420759493672, -1.481229655172414 ], [ 29.765420759493672, -1.480960172413793 ], [ 29.765151265822784, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30670, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.480960172413793 ], [ 29.765420759493672, -1.481229655172414 ], [ 29.765959746835442, -1.481229655172414 ], [ 29.765959746835442, -1.480960172413793 ], [ 29.765420759493672, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30671, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.480960172413793 ], [ 29.765959746835442, -1.481229655172414 ], [ 29.766229240506327, -1.481229655172414 ], [ 29.766229240506327, -1.480960172413793 ], [ 29.765959746835442, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30672, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.480960172413793 ], [ 29.766229240506327, -1.481229655172414 ], [ 29.766498734177215, -1.481229655172414 ], [ 29.766498734177215, -1.480960172413793 ], [ 29.766229240506327, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30673, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.480960172413793 ], [ 29.766498734177215, -1.481229655172414 ], [ 29.7667682278481, -1.481229655172414 ], [ 29.7667682278481, -1.480960172413793 ], [ 29.766498734177215, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30674, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.480960172413793 ], [ 29.7667682278481, -1.481229655172414 ], [ 29.767037721518985, -1.481229655172414 ], [ 29.767037721518985, -1.480960172413793 ], [ 29.7667682278481, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30675, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.480960172413793 ], [ 29.767037721518985, -1.481229655172414 ], [ 29.767307215189874, -1.481229655172414 ], [ 29.767307215189874, -1.480960172413793 ], [ 29.767037721518985, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30676, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.480960172413793 ], [ 29.767307215189874, -1.481229655172414 ], [ 29.767576708860759, -1.481229655172414 ], [ 29.767576708860759, -1.480960172413793 ], [ 29.767307215189874, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30677, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.480960172413793 ], [ 29.767576708860759, -1.481229655172414 ], [ 29.767846202531643, -1.481229655172414 ], [ 29.767846202531643, -1.480960172413793 ], [ 29.767576708860759, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30678, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.480960172413793 ], [ 29.767846202531643, -1.481229655172414 ], [ 29.768115696202532, -1.481229655172414 ], [ 29.768115696202532, -1.480960172413793 ], [ 29.767846202531643, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30679, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.480960172413793 ], [ 29.768115696202532, -1.481229655172414 ], [ 29.768654683544302, -1.481229655172414 ], [ 29.768654683544302, -1.480960172413793 ], [ 29.768115696202532, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30680, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.480960172413793 ], [ 29.768654683544302, -1.481229655172414 ], [ 29.770271645569618, -1.481229655172414 ], [ 29.770271645569618, -1.480960172413793 ], [ 29.768654683544302, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30681, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.480960172413793 ], [ 29.770271645569618, -1.481229655172414 ], [ 29.770810632911392, -1.481229655172414 ], [ 29.770810632911392, -1.480960172413793 ], [ 29.770271645569618, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30682, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.480960172413793 ], [ 29.770810632911392, -1.481229655172414 ], [ 29.771080126582277, -1.481229655172414 ], [ 29.771080126582277, -1.480960172413793 ], [ 29.770810632911392, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30683, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.480960172413793 ], [ 29.771080126582277, -1.481229655172414 ], [ 29.771349620253165, -1.481229655172414 ], [ 29.771349620253165, -1.480960172413793 ], [ 29.771080126582277, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30684, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.480960172413793 ], [ 29.771349620253165, -1.481229655172414 ], [ 29.77161911392405, -1.481229655172414 ], [ 29.77161911392405, -1.480960172413793 ], [ 29.771349620253165, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30685, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.480960172413793 ], [ 29.77161911392405, -1.481229655172414 ], [ 29.771888607594935, -1.481229655172414 ], [ 29.771888607594935, -1.480960172413793 ], [ 29.77161911392405, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30686, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.480960172413793 ], [ 29.771888607594935, -1.481229655172414 ], [ 29.772158101265823, -1.481229655172414 ], [ 29.772158101265823, -1.480960172413793 ], [ 29.771888607594935, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30687, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.480960172413793 ], [ 29.772158101265823, -1.481229655172414 ], [ 29.772427594936708, -1.481229655172414 ], [ 29.772427594936708, -1.480960172413793 ], [ 29.772158101265823, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30688, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.480960172413793 ], [ 29.772427594936708, -1.481229655172414 ], [ 29.772697088607593, -1.481229655172414 ], [ 29.772697088607593, -1.480960172413793 ], [ 29.772427594936708, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30689, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.480960172413793 ], [ 29.772697088607593, -1.481229655172414 ], [ 29.772966582278482, -1.481229655172414 ], [ 29.772966582278482, -1.480960172413793 ], [ 29.772697088607593, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30690, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.480960172413793 ], [ 29.772966582278482, -1.481229655172414 ], [ 29.773236075949367, -1.481229655172414 ], [ 29.773236075949367, -1.480960172413793 ], [ 29.772966582278482, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30691, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.480960172413793 ], [ 29.773236075949367, -1.481229655172414 ], [ 29.773505569620252, -1.481229655172414 ], [ 29.773505569620252, -1.480960172413793 ], [ 29.773236075949367, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30692, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.480960172413793 ], [ 29.773505569620252, -1.481229655172414 ], [ 29.77377506329114, -1.481229655172414 ], [ 29.77377506329114, -1.480960172413793 ], [ 29.773505569620252, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30693, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.480960172413793 ], [ 29.77377506329114, -1.481229655172414 ], [ 29.774044556962025, -1.481229655172414 ], [ 29.774044556962025, -1.480960172413793 ], [ 29.77377506329114, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30694, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.480960172413793 ], [ 29.774044556962025, -1.481229655172414 ], [ 29.77431405063291, -1.481229655172414 ], [ 29.77431405063291, -1.480960172413793 ], [ 29.774044556962025, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30695, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.480960172413793 ], [ 29.77431405063291, -1.481229655172414 ], [ 29.774853037974683, -1.481229655172414 ], [ 29.774853037974683, -1.480960172413793 ], [ 29.77431405063291, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30696, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.480960172413793 ], [ 29.774853037974683, -1.481229655172414 ], [ 29.775122531645568, -1.481229655172414 ], [ 29.775122531645568, -1.480960172413793 ], [ 29.774853037974683, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30697, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.480960172413793 ], [ 29.775122531645568, -1.481229655172414 ], [ 29.775392025316457, -1.481229655172414 ], [ 29.775392025316457, -1.480960172413793 ], [ 29.775122531645568, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30698, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.480960172413793 ], [ 29.775392025316457, -1.481229655172414 ], [ 29.775661518987341, -1.481229655172414 ], [ 29.775661518987341, -1.480960172413793 ], [ 29.775392025316457, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30699, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.480960172413793 ], [ 29.775661518987341, -1.481229655172414 ], [ 29.775931012658226, -1.481229655172414 ], [ 29.775931012658226, -1.480960172413793 ], [ 29.775661518987341, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30700, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.480960172413793 ], [ 29.775931012658226, -1.481229655172414 ], [ 29.776200506329115, -1.481229655172414 ], [ 29.776200506329115, -1.480960172413793 ], [ 29.775931012658226, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30701, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.480960172413793 ], [ 29.776200506329115, -1.481229655172414 ], [ 29.77647, -1.481229655172414 ], [ 29.77647, -1.480960172413793 ], [ 29.776200506329115, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30702, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.480960172413793 ], [ 29.77647, -1.481229655172414 ], [ 29.776739493670885, -1.481229655172414 ], [ 29.776739493670885, -1.480960172413793 ], [ 29.77647, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30703, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.480960172413793 ], [ 29.776739493670885, -1.481229655172414 ], [ 29.777008987341773, -1.481229655172414 ], [ 29.777008987341773, -1.480960172413793 ], [ 29.776739493670885, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30704, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.480960172413793 ], [ 29.777008987341773, -1.481229655172414 ], [ 29.777278481012658, -1.481229655172414 ], [ 29.777278481012658, -1.481499137931034 ], [ 29.777547974683543, -1.481499137931034 ], [ 29.777547974683543, -1.480960172413793 ], [ 29.777008987341773, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30705, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.480960172413793 ], [ 29.777547974683543, -1.481229655172414 ], [ 29.777817468354431, -1.481229655172414 ], [ 29.777817468354431, -1.480960172413793 ], [ 29.777547974683543, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30706, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.480960172413793 ], [ 29.777817468354431, -1.481229655172414 ], [ 29.778086962025316, -1.481229655172414 ], [ 29.778086962025316, -1.480960172413793 ], [ 29.777817468354431, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30707, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.480960172413793 ], [ 29.778086962025316, -1.481499137931034 ], [ 29.778356455696201, -1.481499137931034 ], [ 29.778356455696201, -1.480960172413793 ], [ 29.778086962025316, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30708, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.480960172413793 ], [ 29.778356455696201, -1.481229655172414 ], [ 29.77862594936709, -1.481229655172414 ], [ 29.77862594936709, -1.480960172413793 ], [ 29.778356455696201, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30709, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.480960172413793 ], [ 29.77862594936709, -1.481229655172414 ], [ 29.778895443037975, -1.481229655172414 ], [ 29.778895443037975, -1.480960172413793 ], [ 29.77862594936709, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30710, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.480960172413793 ], [ 29.77916493670886, -1.481229655172414 ], [ 29.779434430379748, -1.481229655172414 ], [ 29.779434430379748, -1.480960172413793 ], [ 29.77916493670886, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30711, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.480960172413793 ], [ 29.779434430379748, -1.481229655172414 ], [ 29.779703924050633, -1.481229655172414 ], [ 29.779703924050633, -1.480960172413793 ], [ 29.779434430379748, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30712, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.480960172413793 ], [ 29.779703924050633, -1.481229655172414 ], [ 29.779973417721518, -1.481229655172414 ], [ 29.779973417721518, -1.480960172413793 ], [ 29.779703924050633, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30713, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.480960172413793 ], [ 29.779973417721518, -1.481499137931034 ], [ 29.780242911392406, -1.481499137931034 ], [ 29.780242911392406, -1.480960172413793 ], [ 29.779973417721518, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30714, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.480960172413793 ], [ 29.780242911392406, -1.481229655172414 ], [ 29.780512405063291, -1.481229655172414 ], [ 29.780512405063291, -1.480960172413793 ], [ 29.780242911392406, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30715, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.480960172413793 ], [ 29.780512405063291, -1.481229655172414 ], [ 29.780781898734176, -1.481229655172414 ], [ 29.780781898734176, -1.480960172413793 ], [ 29.780512405063291, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30716, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.480960172413793 ], [ 29.780781898734176, -1.481229655172414 ], [ 29.781051392405065, -1.481229655172414 ], [ 29.781051392405065, -1.480960172413793 ], [ 29.780781898734176, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30717, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.480960172413793 ], [ 29.781051392405065, -1.481229655172414 ], [ 29.78132088607595, -1.481229655172414 ], [ 29.78132088607595, -1.480960172413793 ], [ 29.781051392405065, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30718, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.480960172413793 ], [ 29.781590379746834, -1.481229655172414 ], [ 29.781859873417723, -1.481229655172414 ], [ 29.781859873417723, -1.480960172413793 ], [ 29.781590379746834, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30719, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.480960172413793 ], [ 29.781859873417723, -1.481229655172414 ], [ 29.782129367088608, -1.481229655172414 ], [ 29.782129367088608, -1.480960172413793 ], [ 29.781859873417723, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30720, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.480960172413793 ], [ 29.782129367088608, -1.481229655172414 ], [ 29.782398860759493, -1.481229655172414 ], [ 29.782398860759493, -1.480960172413793 ], [ 29.782129367088608, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30721, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.480960172413793 ], [ 29.782398860759493, -1.481499137931034 ], [ 29.782668354430381, -1.481499137931034 ], [ 29.782668354430381, -1.480960172413793 ], [ 29.782398860759493, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30722, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.480960172413793 ], [ 29.782668354430381, -1.481229655172414 ], [ 29.782937848101266, -1.481229655172414 ], [ 29.782937848101266, -1.480960172413793 ], [ 29.782668354430381, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30723, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.480960172413793 ], [ 29.782937848101266, -1.481229655172414 ], [ 29.783207341772151, -1.481229655172414 ], [ 29.783207341772151, -1.480960172413793 ], [ 29.782937848101266, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30724, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.480960172413793 ], [ 29.783207341772151, -1.481229655172414 ], [ 29.783476835443039, -1.481229655172414 ], [ 29.783476835443039, -1.480960172413793 ], [ 29.783207341772151, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30725, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.480960172413793 ], [ 29.783476835443039, -1.481229655172414 ], [ 29.784015822784809, -1.481229655172414 ], [ 29.784015822784809, -1.480960172413793 ], [ 29.783476835443039, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30726, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.480960172413793 ], [ 29.784015822784809, -1.481229655172414 ], [ 29.784285316455698, -1.481229655172414 ], [ 29.784285316455698, -1.480960172413793 ], [ 29.784015822784809, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30727, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.480960172413793 ], [ 29.784285316455698, -1.481229655172414 ], [ 29.784554810126583, -1.481229655172414 ], [ 29.784554810126583, -1.480960172413793 ], [ 29.784285316455698, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30728, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.480960172413793 ], [ 29.784554810126583, -1.481229655172414 ], [ 29.784824303797468, -1.481229655172414 ], [ 29.784824303797468, -1.480960172413793 ], [ 29.784554810126583, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30729, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.480960172413793 ], [ 29.784824303797468, -1.481229655172414 ], [ 29.785093797468356, -1.481229655172414 ], [ 29.785093797468356, -1.480960172413793 ], [ 29.784824303797468, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30730, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.480960172413793 ], [ 29.785093797468356, -1.481229655172414 ], [ 29.785363291139241, -1.481229655172414 ], [ 29.785363291139241, -1.480960172413793 ], [ 29.785093797468356, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30731, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.480960172413793 ], [ 29.785363291139241, -1.481229655172414 ], [ 29.785632784810126, -1.481229655172414 ], [ 29.785632784810126, -1.480960172413793 ], [ 29.785363291139241, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30732, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.480960172413793 ], [ 29.789136202531644, -1.481229655172414 ], [ 29.789405696202532, -1.481229655172414 ], [ 29.789405696202532, -1.480960172413793 ], [ 29.789136202531644, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30733, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.480960172413793 ], [ 29.789405696202532, -1.481229655172414 ], [ 29.789675189873417, -1.481229655172414 ], [ 29.789675189873417, -1.480960172413793 ], [ 29.789405696202532, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30734, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.480960172413793 ], [ 29.789675189873417, -1.481229655172414 ], [ 29.789944683544302, -1.481229655172414 ], [ 29.789944683544302, -1.480960172413793 ], [ 29.789675189873417, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30735, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.480960172413793 ], [ 29.789944683544302, -1.481229655172414 ], [ 29.790214177215191, -1.481229655172414 ], [ 29.790214177215191, -1.480960172413793 ], [ 29.789944683544302, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30736, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790753164556961, -1.480960172413793 ], [ 29.790753164556961, -1.481229655172414 ], [ 29.791022658227849, -1.481229655172414 ], [ 29.791022658227849, -1.480960172413793 ], [ 29.790753164556961, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30737, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.480960172413793 ], [ 29.791022658227849, -1.481229655172414 ], [ 29.791292151898734, -1.481229655172414 ], [ 29.791292151898734, -1.480960172413793 ], [ 29.791022658227849, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30738, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.480960172413793 ], [ 29.791292151898734, -1.481229655172414 ], [ 29.791561645569619, -1.481229655172414 ], [ 29.791561645569619, -1.480960172413793 ], [ 29.791292151898734, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30739, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.480960172413793 ], [ 29.791561645569619, -1.481499137931034 ], [ 29.791831139240507, -1.481499137931034 ], [ 29.791831139240507, -1.480960172413793 ], [ 29.791561645569619, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30740, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.480960172413793 ], [ 29.798029493670885, -1.481229655172414 ], [ 29.798298987341774, -1.481229655172414 ], [ 29.798298987341774, -1.480960172413793 ], [ 29.798029493670885, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30741, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.480960172413793 ], [ 29.798298987341774, -1.481229655172414 ], [ 29.798568481012659, -1.481229655172414 ], [ 29.798568481012659, -1.480960172413793 ], [ 29.798298987341774, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30742, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.480960172413793 ], [ 29.798568481012659, -1.481229655172414 ], [ 29.798837974683543, -1.481229655172414 ], [ 29.798837974683543, -1.480960172413793 ], [ 29.798568481012659, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30743, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.480960172413793 ], [ 29.798837974683543, -1.481229655172414 ], [ 29.799107468354432, -1.481229655172414 ], [ 29.799107468354432, -1.480960172413793 ], [ 29.798837974683543, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30744, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.480960172413793 ], [ 29.799107468354432, -1.481229655172414 ], [ 29.799376962025317, -1.481229655172414 ], [ 29.799376962025317, -1.480960172413793 ], [ 29.799107468354432, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30745, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.480960172413793 ], [ 29.799376962025317, -1.481229655172414 ], [ 29.799646455696202, -1.481229655172414 ], [ 29.799646455696202, -1.480960172413793 ], [ 29.799376962025317, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30746, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.480960172413793 ], [ 29.799646455696202, -1.481229655172414 ], [ 29.79991594936709, -1.481229655172414 ], [ 29.79991594936709, -1.480960172413793 ], [ 29.799646455696202, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30747, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.480960172413793 ], [ 29.79991594936709, -1.481499137931034 ], [ 29.800185443037975, -1.481499137931034 ], [ 29.800185443037975, -1.480960172413793 ], [ 29.79991594936709, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30748, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.480960172413793 ], [ 29.800185443037975, -1.481229655172414 ], [ 29.80045493670886, -1.481229655172414 ], [ 29.80045493670886, -1.480960172413793 ], [ 29.800185443037975, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30749, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.480960172413793 ], [ 29.80045493670886, -1.481229655172414 ], [ 29.800724430379748, -1.481229655172414 ], [ 29.800724430379748, -1.480960172413793 ], [ 29.80045493670886, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30750, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.480960172413793 ], [ 29.800724430379748, -1.482577068965517 ], [ 29.800993924050633, -1.482577068965517 ], [ 29.800993924050633, -1.480960172413793 ], [ 29.800724430379748, -1.480960172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30751, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.761917341772151, -1.481229655172414 ], [ 29.761917341772151, -1.481768620689655 ], [ 29.762186835443039, -1.481768620689655 ], [ 29.762186835443039, -1.481229655172414 ], [ 29.761917341772151, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30752, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.481229655172414 ], [ 29.762186835443039, -1.481499137931034 ], [ 29.762456329113924, -1.481499137931034 ], [ 29.762456329113924, -1.481229655172414 ], [ 29.762186835443039, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30753, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.481229655172414 ], [ 29.762456329113924, -1.481499137931034 ], [ 29.762725822784809, -1.481499137931034 ], [ 29.762725822784809, -1.481229655172414 ], [ 29.762456329113924, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30754, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.481229655172414 ], [ 29.762725822784809, -1.481499137931034 ], [ 29.762995316455697, -1.481499137931034 ], [ 29.762995316455697, -1.481229655172414 ], [ 29.762725822784809, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30755, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.481229655172414 ], [ 29.762995316455697, -1.481499137931034 ], [ 29.763264810126582, -1.481499137931034 ], [ 29.763264810126582, -1.481229655172414 ], [ 29.762995316455697, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30756, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.481229655172414 ], [ 29.763264810126582, -1.481499137931034 ], [ 29.763534303797467, -1.481499137931034 ], [ 29.763534303797467, -1.481229655172414 ], [ 29.763264810126582, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30757, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.481229655172414 ], [ 29.763534303797467, -1.481499137931034 ], [ 29.763803797468356, -1.481499137931034 ], [ 29.763803797468356, -1.481229655172414 ], [ 29.763534303797467, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30758, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.481229655172414 ], [ 29.763803797468356, -1.481499137931034 ], [ 29.76407329113924, -1.481499137931034 ], [ 29.76407329113924, -1.481229655172414 ], [ 29.763803797468356, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30759, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.481229655172414 ], [ 29.76407329113924, -1.481499137931034 ], [ 29.764342784810125, -1.481499137931034 ], [ 29.764342784810125, -1.481229655172414 ], [ 29.76407329113924, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30760, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.481229655172414 ], [ 29.764342784810125, -1.481499137931034 ], [ 29.764612278481014, -1.481499137931034 ], [ 29.764612278481014, -1.481229655172414 ], [ 29.764342784810125, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30761, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.481229655172414 ], [ 29.764612278481014, -1.481499137931034 ], [ 29.764881772151899, -1.481499137931034 ], [ 29.764881772151899, -1.481229655172414 ], [ 29.764612278481014, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30762, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.481229655172414 ], [ 29.764881772151899, -1.481499137931034 ], [ 29.765151265822784, -1.481499137931034 ], [ 29.765151265822784, -1.481229655172414 ], [ 29.764881772151899, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30763, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.481229655172414 ], [ 29.765151265822784, -1.481499137931034 ], [ 29.765420759493672, -1.481499137931034 ], [ 29.765420759493672, -1.481229655172414 ], [ 29.765151265822784, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30764, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.481229655172414 ], [ 29.765420759493672, -1.481499137931034 ], [ 29.765959746835442, -1.481499137931034 ], [ 29.765959746835442, -1.481229655172414 ], [ 29.765420759493672, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30765, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.481229655172414 ], [ 29.765959746835442, -1.481499137931034 ], [ 29.766229240506327, -1.481499137931034 ], [ 29.766229240506327, -1.481229655172414 ], [ 29.765959746835442, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30766, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.481229655172414 ], [ 29.766229240506327, -1.481499137931034 ], [ 29.766498734177215, -1.481499137931034 ], [ 29.766498734177215, -1.481229655172414 ], [ 29.766229240506327, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30767, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.481229655172414 ], [ 29.766498734177215, -1.481499137931034 ], [ 29.7667682278481, -1.481499137931034 ], [ 29.7667682278481, -1.481229655172414 ], [ 29.766498734177215, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30768, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.481229655172414 ], [ 29.7667682278481, -1.481499137931034 ], [ 29.767037721518985, -1.481499137931034 ], [ 29.767037721518985, -1.481229655172414 ], [ 29.7667682278481, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30769, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.481229655172414 ], [ 29.767037721518985, -1.481499137931034 ], [ 29.767307215189874, -1.481499137931034 ], [ 29.767307215189874, -1.481229655172414 ], [ 29.767037721518985, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30770, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.481229655172414 ], [ 29.767307215189874, -1.481499137931034 ], [ 29.767576708860759, -1.481499137931034 ], [ 29.767576708860759, -1.481229655172414 ], [ 29.767307215189874, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30771, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.481229655172414 ], [ 29.767576708860759, -1.481499137931034 ], [ 29.767846202531643, -1.481499137931034 ], [ 29.767846202531643, -1.481229655172414 ], [ 29.767576708860759, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30772, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.481229655172414 ], [ 29.767846202531643, -1.481499137931034 ], [ 29.768385189873417, -1.481499137931034 ], [ 29.768385189873417, -1.481229655172414 ], [ 29.767846202531643, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30773, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.481229655172414 ], [ 29.768385189873417, -1.481499137931034 ], [ 29.770541139240507, -1.481499137931034 ], [ 29.770541139240507, -1.481229655172414 ], [ 29.768385189873417, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30774, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.481229655172414 ], [ 29.770541139240507, -1.481499137931034 ], [ 29.771080126582277, -1.481499137931034 ], [ 29.771080126582277, -1.481229655172414 ], [ 29.770541139240507, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30775, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.481229655172414 ], [ 29.771080126582277, -1.481499137931034 ], [ 29.771349620253165, -1.481499137931034 ], [ 29.771349620253165, -1.481229655172414 ], [ 29.771080126582277, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30776, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.481229655172414 ], [ 29.771349620253165, -1.481499137931034 ], [ 29.77161911392405, -1.481499137931034 ], [ 29.77161911392405, -1.481229655172414 ], [ 29.771349620253165, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30777, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.481229655172414 ], [ 29.77161911392405, -1.481499137931034 ], [ 29.771888607594935, -1.481499137931034 ], [ 29.771888607594935, -1.481229655172414 ], [ 29.77161911392405, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30778, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.481229655172414 ], [ 29.771888607594935, -1.481499137931034 ], [ 29.772158101265823, -1.481499137931034 ], [ 29.772158101265823, -1.481229655172414 ], [ 29.771888607594935, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30779, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.481229655172414 ], [ 29.772158101265823, -1.481499137931034 ], [ 29.772427594936708, -1.481499137931034 ], [ 29.772427594936708, -1.481229655172414 ], [ 29.772158101265823, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30780, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.481229655172414 ], [ 29.772427594936708, -1.481499137931034 ], [ 29.772697088607593, -1.481499137931034 ], [ 29.772697088607593, -1.481229655172414 ], [ 29.772427594936708, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30781, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.481229655172414 ], [ 29.772697088607593, -1.481499137931034 ], [ 29.772966582278482, -1.481499137931034 ], [ 29.772966582278482, -1.481229655172414 ], [ 29.772697088607593, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30782, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.481229655172414 ], [ 29.772966582278482, -1.481499137931034 ], [ 29.773236075949367, -1.481499137931034 ], [ 29.773236075949367, -1.481229655172414 ], [ 29.772966582278482, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30783, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.481229655172414 ], [ 29.773236075949367, -1.481499137931034 ], [ 29.773505569620252, -1.481499137931034 ], [ 29.773505569620252, -1.481229655172414 ], [ 29.773236075949367, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30784, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.481229655172414 ], [ 29.773505569620252, -1.481499137931034 ], [ 29.77377506329114, -1.481499137931034 ], [ 29.77377506329114, -1.481229655172414 ], [ 29.773505569620252, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30785, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.481229655172414 ], [ 29.77377506329114, -1.481499137931034 ], [ 29.774044556962025, -1.481499137931034 ], [ 29.774044556962025, -1.481229655172414 ], [ 29.77377506329114, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30786, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.481229655172414 ], [ 29.774044556962025, -1.481499137931034 ], [ 29.77431405063291, -1.481499137931034 ], [ 29.77431405063291, -1.481229655172414 ], [ 29.774044556962025, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30787, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.481229655172414 ], [ 29.77431405063291, -1.481499137931034 ], [ 29.774853037974683, -1.481499137931034 ], [ 29.774853037974683, -1.481229655172414 ], [ 29.77431405063291, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30788, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.481229655172414 ], [ 29.774853037974683, -1.481499137931034 ], [ 29.775122531645568, -1.481499137931034 ], [ 29.775122531645568, -1.481229655172414 ], [ 29.774853037974683, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30789, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.481229655172414 ], [ 29.775122531645568, -1.481499137931034 ], [ 29.775392025316457, -1.481499137931034 ], [ 29.775392025316457, -1.481229655172414 ], [ 29.775122531645568, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30790, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.481229655172414 ], [ 29.775392025316457, -1.481499137931034 ], [ 29.775661518987341, -1.481499137931034 ], [ 29.775661518987341, -1.481229655172414 ], [ 29.775392025316457, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30791, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.481229655172414 ], [ 29.775661518987341, -1.481499137931034 ], [ 29.775931012658226, -1.481499137931034 ], [ 29.775931012658226, -1.481229655172414 ], [ 29.775661518987341, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30792, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.481229655172414 ], [ 29.775931012658226, -1.481499137931034 ], [ 29.776200506329115, -1.481499137931034 ], [ 29.776200506329115, -1.481229655172414 ], [ 29.775931012658226, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30793, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.481229655172414 ], [ 29.776200506329115, -1.481499137931034 ], [ 29.77647, -1.481499137931034 ], [ 29.77647, -1.481229655172414 ], [ 29.776200506329115, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30794, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.481229655172414 ], [ 29.77647, -1.481499137931034 ], [ 29.776739493670885, -1.481499137931034 ], [ 29.776739493670885, -1.481229655172414 ], [ 29.77647, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30795, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.481229655172414 ], [ 29.776739493670885, -1.481499137931034 ], [ 29.777008987341773, -1.481499137931034 ], [ 29.777008987341773, -1.481229655172414 ], [ 29.776739493670885, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30796, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.481229655172414 ], [ 29.777008987341773, -1.481499137931034 ], [ 29.777278481012658, -1.481499137931034 ], [ 29.777278481012658, -1.481229655172414 ], [ 29.777008987341773, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30797, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.481229655172414 ], [ 29.777547974683543, -1.481768620689655 ], [ 29.777817468354431, -1.481768620689655 ], [ 29.777817468354431, -1.481229655172414 ], [ 29.777547974683543, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30798, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.481229655172414 ], [ 29.777817468354431, -1.481499137931034 ], [ 29.778086962025316, -1.481499137931034 ], [ 29.778086962025316, -1.481229655172414 ], [ 29.777817468354431, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30799, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.481229655172414 ], [ 29.778356455696201, -1.481768620689655 ], [ 29.77862594936709, -1.481768620689655 ], [ 29.77862594936709, -1.481229655172414 ], [ 29.778356455696201, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30800, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.481229655172414 ], [ 29.77862594936709, -1.481499137931034 ], [ 29.778895443037975, -1.481499137931034 ], [ 29.778895443037975, -1.481229655172414 ], [ 29.77862594936709, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30801, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.481229655172414 ], [ 29.778895443037975, -1.481499137931034 ], [ 29.77916493670886, -1.481499137931034 ], [ 29.77916493670886, -1.481229655172414 ], [ 29.778895443037975, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30802, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.481229655172414 ], [ 29.77916493670886, -1.481768620689655 ], [ 29.779434430379748, -1.481768620689655 ], [ 29.779434430379748, -1.481229655172414 ], [ 29.77916493670886, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30803, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.481229655172414 ], [ 29.779434430379748, -1.481499137931034 ], [ 29.779703924050633, -1.481499137931034 ], [ 29.779703924050633, -1.481229655172414 ], [ 29.779434430379748, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30804, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.481229655172414 ], [ 29.779703924050633, -1.481499137931034 ], [ 29.779973417721518, -1.481499137931034 ], [ 29.779973417721518, -1.481229655172414 ], [ 29.779703924050633, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30805, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.481229655172414 ], [ 29.780242911392406, -1.481499137931034 ], [ 29.780512405063291, -1.481499137931034 ], [ 29.780512405063291, -1.481229655172414 ], [ 29.780242911392406, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30806, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.481229655172414 ], [ 29.780512405063291, -1.481499137931034 ], [ 29.780781898734176, -1.481499137931034 ], [ 29.780781898734176, -1.481229655172414 ], [ 29.780512405063291, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30807, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.481229655172414 ], [ 29.780781898734176, -1.481499137931034 ], [ 29.781051392405065, -1.481499137931034 ], [ 29.781051392405065, -1.481229655172414 ], [ 29.780781898734176, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30808, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.481229655172414 ], [ 29.781051392405065, -1.481499137931034 ], [ 29.78132088607595, -1.481499137931034 ], [ 29.78132088607595, -1.481229655172414 ], [ 29.781051392405065, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30809, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.481229655172414 ], [ 29.78132088607595, -1.481499137931034 ], [ 29.781590379746834, -1.481499137931034 ], [ 29.781590379746834, -1.481229655172414 ], [ 29.78132088607595, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30810, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.481229655172414 ], [ 29.781590379746834, -1.481499137931034 ], [ 29.781859873417723, -1.481499137931034 ], [ 29.781859873417723, -1.481229655172414 ], [ 29.781590379746834, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30811, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.481229655172414 ], [ 29.781859873417723, -1.481499137931034 ], [ 29.782129367088608, -1.481499137931034 ], [ 29.782129367088608, -1.481229655172414 ], [ 29.781859873417723, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30812, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.481229655172414 ], [ 29.782129367088608, -1.481499137931034 ], [ 29.782398860759493, -1.481499137931034 ], [ 29.782398860759493, -1.481229655172414 ], [ 29.782129367088608, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30813, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.481229655172414 ], [ 29.782668354430381, -1.481499137931034 ], [ 29.782937848101266, -1.481499137931034 ], [ 29.782937848101266, -1.481229655172414 ], [ 29.782668354430381, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30814, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.481229655172414 ], [ 29.782937848101266, -1.481499137931034 ], [ 29.783207341772151, -1.481499137931034 ], [ 29.783207341772151, -1.481229655172414 ], [ 29.782937848101266, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30815, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.481229655172414 ], [ 29.783207341772151, -1.481499137931034 ], [ 29.783476835443039, -1.481499137931034 ], [ 29.783476835443039, -1.481229655172414 ], [ 29.783207341772151, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30816, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.481229655172414 ], [ 29.783476835443039, -1.481768620689655 ], [ 29.784015822784809, -1.481768620689655 ], [ 29.784015822784809, -1.481229655172414 ], [ 29.783476835443039, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30817, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.481229655172414 ], [ 29.784015822784809, -1.481499137931034 ], [ 29.784285316455698, -1.481499137931034 ], [ 29.784285316455698, -1.481229655172414 ], [ 29.784015822784809, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30818, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.481229655172414 ], [ 29.784285316455698, -1.481499137931034 ], [ 29.784554810126583, -1.481499137931034 ], [ 29.784554810126583, -1.481229655172414 ], [ 29.784285316455698, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30819, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.481229655172414 ], [ 29.784554810126583, -1.481499137931034 ], [ 29.784824303797468, -1.481499137931034 ], [ 29.784824303797468, -1.481229655172414 ], [ 29.784554810126583, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30820, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.481229655172414 ], [ 29.784824303797468, -1.481499137931034 ], [ 29.785093797468356, -1.481499137931034 ], [ 29.785093797468356, -1.481229655172414 ], [ 29.784824303797468, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30821, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.481229655172414 ], [ 29.785093797468356, -1.481499137931034 ], [ 29.785363291139241, -1.481499137931034 ], [ 29.785363291139241, -1.481229655172414 ], [ 29.785093797468356, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30822, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.481229655172414 ], [ 29.785363291139241, -1.481768620689655 ], [ 29.785632784810126, -1.481768620689655 ], [ 29.785632784810126, -1.481229655172414 ], [ 29.785363291139241, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30823, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.481229655172414 ], [ 29.789405696202532, -1.481499137931034 ], [ 29.789675189873417, -1.481499137931034 ], [ 29.789675189873417, -1.481229655172414 ], [ 29.789405696202532, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30824, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.481229655172414 ], [ 29.789675189873417, -1.481499137931034 ], [ 29.789944683544302, -1.481499137931034 ], [ 29.789944683544302, -1.481229655172414 ], [ 29.789675189873417, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30825, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.481229655172414 ], [ 29.789944683544302, -1.481499137931034 ], [ 29.790214177215191, -1.481499137931034 ], [ 29.790214177215191, -1.481229655172414 ], [ 29.789944683544302, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30826, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.481229655172414 ], [ 29.790214177215191, -1.481499137931034 ], [ 29.790483670886076, -1.481499137931034 ], [ 29.790483670886076, -1.481229655172414 ], [ 29.790214177215191, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30827, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.481229655172414 ], [ 29.791022658227849, -1.481499137931034 ], [ 29.791292151898734, -1.481499137931034 ], [ 29.791292151898734, -1.481229655172414 ], [ 29.791022658227849, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30828, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.481229655172414 ], [ 29.791292151898734, -1.481499137931034 ], [ 29.791561645569619, -1.481499137931034 ], [ 29.791561645569619, -1.481229655172414 ], [ 29.791292151898734, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30829, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.481229655172414 ], [ 29.798298987341774, -1.481499137931034 ], [ 29.798568481012659, -1.481499137931034 ], [ 29.798568481012659, -1.481229655172414 ], [ 29.798298987341774, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30830, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.481229655172414 ], [ 29.798568481012659, -1.481499137931034 ], [ 29.798837974683543, -1.481499137931034 ], [ 29.798837974683543, -1.481229655172414 ], [ 29.798568481012659, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30831, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.481229655172414 ], [ 29.798837974683543, -1.481499137931034 ], [ 29.799107468354432, -1.481499137931034 ], [ 29.799107468354432, -1.481229655172414 ], [ 29.798837974683543, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30832, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.481229655172414 ], [ 29.799107468354432, -1.481499137931034 ], [ 29.799376962025317, -1.481499137931034 ], [ 29.799376962025317, -1.481229655172414 ], [ 29.799107468354432, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30833, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.481499137931034 ], [ 29.762186835443039, -1.481768620689655 ], [ 29.762456329113924, -1.481768620689655 ], [ 29.762456329113924, -1.481499137931034 ], [ 29.762186835443039, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30834, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.481499137931034 ], [ 29.762456329113924, -1.481768620689655 ], [ 29.762725822784809, -1.481768620689655 ], [ 29.762725822784809, -1.481499137931034 ], [ 29.762456329113924, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30835, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.481499137931034 ], [ 29.762725822784809, -1.481768620689655 ], [ 29.762995316455697, -1.481768620689655 ], [ 29.762995316455697, -1.481499137931034 ], [ 29.762725822784809, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30836, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.481499137931034 ], [ 29.762995316455697, -1.481768620689655 ], [ 29.763264810126582, -1.481768620689655 ], [ 29.763264810126582, -1.481499137931034 ], [ 29.762995316455697, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30837, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.481499137931034 ], [ 29.763264810126582, -1.481768620689655 ], [ 29.763534303797467, -1.481768620689655 ], [ 29.763534303797467, -1.481499137931034 ], [ 29.763264810126582, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30838, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.481499137931034 ], [ 29.763534303797467, -1.481768620689655 ], [ 29.763803797468356, -1.481768620689655 ], [ 29.763803797468356, -1.481499137931034 ], [ 29.763534303797467, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30839, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.481499137931034 ], [ 29.763803797468356, -1.481768620689655 ], [ 29.76407329113924, -1.481768620689655 ], [ 29.76407329113924, -1.481499137931034 ], [ 29.763803797468356, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30840, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.481499137931034 ], [ 29.76407329113924, -1.481768620689655 ], [ 29.764342784810125, -1.481768620689655 ], [ 29.764342784810125, -1.481499137931034 ], [ 29.76407329113924, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30841, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.481499137931034 ], [ 29.764342784810125, -1.481768620689655 ], [ 29.764612278481014, -1.481768620689655 ], [ 29.764612278481014, -1.481499137931034 ], [ 29.764342784810125, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30842, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.481499137931034 ], [ 29.764612278481014, -1.481768620689655 ], [ 29.764881772151899, -1.481768620689655 ], [ 29.764881772151899, -1.481499137931034 ], [ 29.764612278481014, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30843, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.481499137931034 ], [ 29.764881772151899, -1.481768620689655 ], [ 29.765151265822784, -1.481768620689655 ], [ 29.765151265822784, -1.481499137931034 ], [ 29.764881772151899, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30844, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.481499137931034 ], [ 29.765151265822784, -1.481768620689655 ], [ 29.765420759493672, -1.481768620689655 ], [ 29.765420759493672, -1.481499137931034 ], [ 29.765151265822784, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30845, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.481499137931034 ], [ 29.765420759493672, -1.481768620689655 ], [ 29.765959746835442, -1.481768620689655 ], [ 29.765959746835442, -1.481499137931034 ], [ 29.765420759493672, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30846, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.481499137931034 ], [ 29.765959746835442, -1.481768620689655 ], [ 29.766229240506327, -1.481768620689655 ], [ 29.766229240506327, -1.481499137931034 ], [ 29.765959746835442, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30847, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.481499137931034 ], [ 29.766229240506327, -1.481768620689655 ], [ 29.766498734177215, -1.481768620689655 ], [ 29.766498734177215, -1.481499137931034 ], [ 29.766229240506327, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30848, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.481499137931034 ], [ 29.766498734177215, -1.481768620689655 ], [ 29.7667682278481, -1.481768620689655 ], [ 29.7667682278481, -1.481499137931034 ], [ 29.766498734177215, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30849, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.481499137931034 ], [ 29.7667682278481, -1.481768620689655 ], [ 29.767037721518985, -1.481768620689655 ], [ 29.767037721518985, -1.481499137931034 ], [ 29.7667682278481, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30850, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.481499137931034 ], [ 29.767037721518985, -1.481768620689655 ], [ 29.767307215189874, -1.481768620689655 ], [ 29.767307215189874, -1.481499137931034 ], [ 29.767037721518985, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30851, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.481499137931034 ], [ 29.767307215189874, -1.481768620689655 ], [ 29.767576708860759, -1.481768620689655 ], [ 29.767576708860759, -1.481499137931034 ], [ 29.767307215189874, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30852, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.481499137931034 ], [ 29.767576708860759, -1.481768620689655 ], [ 29.767846202531643, -1.481768620689655 ], [ 29.767846202531643, -1.481499137931034 ], [ 29.767576708860759, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30853, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.481499137931034 ], [ 29.767846202531643, -1.481768620689655 ], [ 29.768115696202532, -1.481768620689655 ], [ 29.768115696202532, -1.481499137931034 ], [ 29.767846202531643, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30854, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.481499137931034 ], [ 29.768115696202532, -1.481768620689655 ], [ 29.768385189873417, -1.481768620689655 ], [ 29.768385189873417, -1.481499137931034 ], [ 29.768115696202532, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30855, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.481499137931034 ], [ 29.768385189873417, -1.481768620689655 ], [ 29.770541139240507, -1.481768620689655 ], [ 29.770541139240507, -1.481499137931034 ], [ 29.768385189873417, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30856, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.481499137931034 ], [ 29.770541139240507, -1.481768620689655 ], [ 29.770810632911392, -1.481768620689655 ], [ 29.770810632911392, -1.481499137931034 ], [ 29.770541139240507, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30857, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.481499137931034 ], [ 29.770810632911392, -1.481768620689655 ], [ 29.771080126582277, -1.481768620689655 ], [ 29.771080126582277, -1.481499137931034 ], [ 29.770810632911392, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30858, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.481499137931034 ], [ 29.771080126582277, -1.481768620689655 ], [ 29.771349620253165, -1.481768620689655 ], [ 29.771349620253165, -1.481499137931034 ], [ 29.771080126582277, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30859, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.481499137931034 ], [ 29.771349620253165, -1.481768620689655 ], [ 29.77161911392405, -1.481768620689655 ], [ 29.77161911392405, -1.481499137931034 ], [ 29.771349620253165, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30860, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.481499137931034 ], [ 29.77161911392405, -1.481768620689655 ], [ 29.771888607594935, -1.481768620689655 ], [ 29.771888607594935, -1.481499137931034 ], [ 29.77161911392405, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30861, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.481499137931034 ], [ 29.771888607594935, -1.481768620689655 ], [ 29.772158101265823, -1.481768620689655 ], [ 29.772158101265823, -1.481499137931034 ], [ 29.771888607594935, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30862, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.481499137931034 ], [ 29.772158101265823, -1.481768620689655 ], [ 29.772427594936708, -1.481768620689655 ], [ 29.772427594936708, -1.481499137931034 ], [ 29.772158101265823, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30863, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.481499137931034 ], [ 29.772427594936708, -1.481768620689655 ], [ 29.772697088607593, -1.481768620689655 ], [ 29.772697088607593, -1.481499137931034 ], [ 29.772427594936708, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30864, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.481499137931034 ], [ 29.772697088607593, -1.481768620689655 ], [ 29.772966582278482, -1.481768620689655 ], [ 29.772966582278482, -1.481499137931034 ], [ 29.772697088607593, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30865, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.481499137931034 ], [ 29.772966582278482, -1.481768620689655 ], [ 29.773236075949367, -1.481768620689655 ], [ 29.773236075949367, -1.481499137931034 ], [ 29.772966582278482, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30866, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.481499137931034 ], [ 29.773236075949367, -1.481768620689655 ], [ 29.773505569620252, -1.481768620689655 ], [ 29.773505569620252, -1.481499137931034 ], [ 29.773236075949367, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30867, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.481499137931034 ], [ 29.773505569620252, -1.481768620689655 ], [ 29.77377506329114, -1.481768620689655 ], [ 29.77377506329114, -1.481499137931034 ], [ 29.773505569620252, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30868, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.481499137931034 ], [ 29.77377506329114, -1.481768620689655 ], [ 29.774044556962025, -1.481768620689655 ], [ 29.774044556962025, -1.481499137931034 ], [ 29.77377506329114, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30869, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.481499137931034 ], [ 29.774044556962025, -1.481768620689655 ], [ 29.77431405063291, -1.481768620689655 ], [ 29.77431405063291, -1.481499137931034 ], [ 29.774044556962025, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30870, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.481499137931034 ], [ 29.77431405063291, -1.481768620689655 ], [ 29.774853037974683, -1.481768620689655 ], [ 29.774853037974683, -1.481499137931034 ], [ 29.77431405063291, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30871, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.481499137931034 ], [ 29.774853037974683, -1.481768620689655 ], [ 29.775122531645568, -1.481768620689655 ], [ 29.775122531645568, -1.481499137931034 ], [ 29.774853037974683, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30872, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.481499137931034 ], [ 29.775122531645568, -1.481768620689655 ], [ 29.775392025316457, -1.481768620689655 ], [ 29.775392025316457, -1.481499137931034 ], [ 29.775122531645568, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30873, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.481499137931034 ], [ 29.775392025316457, -1.481768620689655 ], [ 29.775661518987341, -1.481768620689655 ], [ 29.775661518987341, -1.481499137931034 ], [ 29.775392025316457, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30874, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.481499137931034 ], [ 29.775661518987341, -1.481768620689655 ], [ 29.775931012658226, -1.481768620689655 ], [ 29.775931012658226, -1.481499137931034 ], [ 29.775661518987341, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30875, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.481499137931034 ], [ 29.775931012658226, -1.481768620689655 ], [ 29.776200506329115, -1.481768620689655 ], [ 29.776200506329115, -1.481499137931034 ], [ 29.775931012658226, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30876, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.481499137931034 ], [ 29.776200506329115, -1.481768620689655 ], [ 29.77647, -1.481768620689655 ], [ 29.77647, -1.481499137931034 ], [ 29.776200506329115, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30877, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.481499137931034 ], [ 29.77647, -1.481768620689655 ], [ 29.776739493670885, -1.481768620689655 ], [ 29.776739493670885, -1.481499137931034 ], [ 29.77647, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30878, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.481499137931034 ], [ 29.776739493670885, -1.481768620689655 ], [ 29.777008987341773, -1.481768620689655 ], [ 29.777008987341773, -1.481499137931034 ], [ 29.776739493670885, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30879, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.481499137931034 ], [ 29.777008987341773, -1.481768620689655 ], [ 29.777278481012658, -1.481768620689655 ], [ 29.777278481012658, -1.481499137931034 ], [ 29.777008987341773, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30880, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.481499137931034 ], [ 29.777278481012658, -1.482038103448276 ], [ 29.777547974683543, -1.482038103448276 ], [ 29.777547974683543, -1.481499137931034 ], [ 29.777278481012658, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30881, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.481499137931034 ], [ 29.777817468354431, -1.482307586206897 ], [ 29.778086962025316, -1.482307586206897 ], [ 29.778086962025316, -1.481499137931034 ], [ 29.777817468354431, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30882, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.481499137931034 ], [ 29.778086962025316, -1.482038103448276 ], [ 29.778356455696201, -1.482038103448276 ], [ 29.778356455696201, -1.481499137931034 ], [ 29.778086962025316, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30883, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.481499137931034 ], [ 29.77862594936709, -1.482038103448276 ], [ 29.778895443037975, -1.482038103448276 ], [ 29.778895443037975, -1.481499137931034 ], [ 29.77862594936709, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30884, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.481499137931034 ], [ 29.778895443037975, -1.481768620689655 ], [ 29.77916493670886, -1.481768620689655 ], [ 29.77916493670886, -1.481499137931034 ], [ 29.778895443037975, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30885, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.481499137931034 ], [ 29.779434430379748, -1.482038103448276 ], [ 29.779703924050633, -1.482038103448276 ], [ 29.779703924050633, -1.481499137931034 ], [ 29.779434430379748, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30886, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.481499137931034 ], [ 29.779703924050633, -1.481768620689655 ], [ 29.779973417721518, -1.481768620689655 ], [ 29.779973417721518, -1.481499137931034 ], [ 29.779703924050633, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30887, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.481499137931034 ], [ 29.779973417721518, -1.481768620689655 ], [ 29.780242911392406, -1.481768620689655 ], [ 29.780242911392406, -1.481499137931034 ], [ 29.779973417721518, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30888, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.481499137931034 ], [ 29.780242911392406, -1.482038103448276 ], [ 29.780512405063291, -1.482038103448276 ], [ 29.780512405063291, -1.481499137931034 ], [ 29.780242911392406, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30889, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.481499137931034 ], [ 29.780512405063291, -1.481768620689655 ], [ 29.780781898734176, -1.481768620689655 ], [ 29.780781898734176, -1.481499137931034 ], [ 29.780512405063291, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30890, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.481499137931034 ], [ 29.780781898734176, -1.481768620689655 ], [ 29.781051392405065, -1.481768620689655 ], [ 29.781051392405065, -1.481499137931034 ], [ 29.780781898734176, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30891, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.481499137931034 ], [ 29.781051392405065, -1.482038103448276 ], [ 29.78132088607595, -1.482038103448276 ], [ 29.78132088607595, -1.481499137931034 ], [ 29.781051392405065, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30892, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.481499137931034 ], [ 29.78132088607595, -1.481768620689655 ], [ 29.781590379746834, -1.481768620689655 ], [ 29.781590379746834, -1.481499137931034 ], [ 29.78132088607595, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30893, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.481499137931034 ], [ 29.781590379746834, -1.481768620689655 ], [ 29.781859873417723, -1.481768620689655 ], [ 29.781859873417723, -1.481499137931034 ], [ 29.781590379746834, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30894, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.481499137931034 ], [ 29.781859873417723, -1.481768620689655 ], [ 29.782129367088608, -1.481768620689655 ], [ 29.782129367088608, -1.481499137931034 ], [ 29.781859873417723, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30895, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.481499137931034 ], [ 29.782129367088608, -1.481768620689655 ], [ 29.782398860759493, -1.481768620689655 ], [ 29.782398860759493, -1.481499137931034 ], [ 29.782129367088608, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30896, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.481499137931034 ], [ 29.782398860759493, -1.481768620689655 ], [ 29.782668354430381, -1.481768620689655 ], [ 29.782668354430381, -1.481499137931034 ], [ 29.782398860759493, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30897, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.481499137931034 ], [ 29.782668354430381, -1.481768620689655 ], [ 29.782937848101266, -1.481768620689655 ], [ 29.782937848101266, -1.481499137931034 ], [ 29.782668354430381, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30898, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.481499137931034 ], [ 29.782937848101266, -1.481768620689655 ], [ 29.783207341772151, -1.481768620689655 ], [ 29.783207341772151, -1.481499137931034 ], [ 29.782937848101266, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30899, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.481499137931034 ], [ 29.783207341772151, -1.481768620689655 ], [ 29.783476835443039, -1.481768620689655 ], [ 29.783476835443039, -1.481499137931034 ], [ 29.783207341772151, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30900, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.481499137931034 ], [ 29.784015822784809, -1.482038103448276 ], [ 29.784285316455698, -1.482038103448276 ], [ 29.784285316455698, -1.481499137931034 ], [ 29.784015822784809, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30901, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.481499137931034 ], [ 29.784285316455698, -1.482038103448276 ], [ 29.784554810126583, -1.482038103448276 ], [ 29.784554810126583, -1.481499137931034 ], [ 29.784285316455698, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30902, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.481499137931034 ], [ 29.784554810126583, -1.481768620689655 ], [ 29.784824303797468, -1.481768620689655 ], [ 29.784824303797468, -1.481499137931034 ], [ 29.784554810126583, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30903, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.481499137931034 ], [ 29.784824303797468, -1.481768620689655 ], [ 29.785093797468356, -1.481768620689655 ], [ 29.785093797468356, -1.481499137931034 ], [ 29.784824303797468, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30904, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.481499137931034 ], [ 29.785093797468356, -1.481768620689655 ], [ 29.785363291139241, -1.481768620689655 ], [ 29.785363291139241, -1.481499137931034 ], [ 29.785093797468356, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30905, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789675189873417, -1.481499137931034 ], [ 29.789675189873417, -1.481768620689655 ], [ 29.789944683544302, -1.481768620689655 ], [ 29.789944683544302, -1.481499137931034 ], [ 29.789675189873417, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30906, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.481499137931034 ], [ 29.789944683544302, -1.481768620689655 ], [ 29.790214177215191, -1.481768620689655 ], [ 29.790214177215191, -1.481499137931034 ], [ 29.789944683544302, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30907, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.481499137931034 ], [ 29.790214177215191, -1.481768620689655 ], [ 29.790483670886076, -1.481768620689655 ], [ 29.790483670886076, -1.481499137931034 ], [ 29.790214177215191, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30908, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.481499137931034 ], [ 29.790483670886076, -1.481768620689655 ], [ 29.791022658227849, -1.481768620689655 ], [ 29.791022658227849, -1.481499137931034 ], [ 29.790483670886076, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30909, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.481499137931034 ], [ 29.791022658227849, -1.481768620689655 ], [ 29.791292151898734, -1.481768620689655 ], [ 29.791292151898734, -1.481499137931034 ], [ 29.791022658227849, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30910, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.481499137931034 ], [ 29.791292151898734, -1.481768620689655 ], [ 29.791561645569619, -1.481768620689655 ], [ 29.791561645569619, -1.481499137931034 ], [ 29.791292151898734, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30911, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.481499137931034 ], [ 29.791561645569619, -1.481768620689655 ], [ 29.791831139240507, -1.481768620689655 ], [ 29.791831139240507, -1.481499137931034 ], [ 29.791561645569619, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30912, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791831139240507, -1.481499137931034 ], [ 29.791831139240507, -1.481768620689655 ], [ 29.792100632911392, -1.481768620689655 ], [ 29.792100632911392, -1.481499137931034 ], [ 29.791831139240507, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30913, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.481499137931034 ], [ 29.798568481012659, -1.482577068965517 ], [ 29.798837974683543, -1.482577068965517 ], [ 29.798837974683543, -1.481499137931034 ], [ 29.798568481012659, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30914, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.481499137931034 ], [ 29.798837974683543, -1.482577068965517 ], [ 29.799107468354432, -1.482577068965517 ], [ 29.799107468354432, -1.481499137931034 ], [ 29.798837974683543, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30915, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.481499137931034 ], [ 29.799107468354432, -1.482577068965517 ], [ 29.799376962025317, -1.482577068965517 ], [ 29.799376962025317, -1.481499137931034 ], [ 29.799107468354432, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30916, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.481499137931034 ], [ 29.79991594936709, -1.482577068965517 ], [ 29.800185443037975, -1.482577068965517 ], [ 29.800185443037975, -1.481499137931034 ], [ 29.79991594936709, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30917, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.481499137931034 ], [ 29.802341392405065, -1.482038103448276 ], [ 29.80261088607595, -1.482038103448276 ], [ 29.80261088607595, -1.481499137931034 ], [ 29.802341392405065, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30918, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.481499137931034 ], [ 29.803149873417723, -1.481768620689655 ], [ 29.803419367088608, -1.481768620689655 ], [ 29.803419367088608, -1.481499137931034 ], [ 29.803149873417723, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30919, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.481499137931034 ], [ 29.803419367088608, -1.481768620689655 ], [ 29.803688860759493, -1.481768620689655 ], [ 29.803688860759493, -1.481499137931034 ], [ 29.803419367088608, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30920, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.481499137931034 ], [ 29.803688860759493, -1.481768620689655 ], [ 29.803958354430382, -1.481768620689655 ], [ 29.803958354430382, -1.481499137931034 ], [ 29.803688860759493, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30921, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.481499137931034 ], [ 29.803958354430382, -1.482577068965517 ], [ 29.804227848101267, -1.482577068965517 ], [ 29.804227848101267, -1.481499137931034 ], [ 29.803958354430382, -1.481499137931034 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30922, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762186835443039, -1.481768620689655 ], [ 29.762186835443039, -1.482038103448276 ], [ 29.762456329113924, -1.482038103448276 ], [ 29.762456329113924, -1.481768620689655 ], [ 29.762186835443039, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30923, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.481768620689655 ], [ 29.762456329113924, -1.482038103448276 ], [ 29.762725822784809, -1.482038103448276 ], [ 29.762725822784809, -1.481768620689655 ], [ 29.762456329113924, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30924, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.481768620689655 ], [ 29.762725822784809, -1.482038103448276 ], [ 29.762995316455697, -1.482038103448276 ], [ 29.762995316455697, -1.481768620689655 ], [ 29.762725822784809, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30925, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.481768620689655 ], [ 29.762995316455697, -1.482038103448276 ], [ 29.763264810126582, -1.482038103448276 ], [ 29.763264810126582, -1.481768620689655 ], [ 29.762995316455697, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30926, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.481768620689655 ], [ 29.763264810126582, -1.482038103448276 ], [ 29.763534303797467, -1.482038103448276 ], [ 29.763534303797467, -1.481768620689655 ], [ 29.763264810126582, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30927, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.481768620689655 ], [ 29.763534303797467, -1.482038103448276 ], [ 29.763803797468356, -1.482038103448276 ], [ 29.763803797468356, -1.481768620689655 ], [ 29.763534303797467, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30928, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.481768620689655 ], [ 29.763803797468356, -1.482038103448276 ], [ 29.76407329113924, -1.482038103448276 ], [ 29.76407329113924, -1.481768620689655 ], [ 29.763803797468356, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30929, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.481768620689655 ], [ 29.76407329113924, -1.482038103448276 ], [ 29.764342784810125, -1.482038103448276 ], [ 29.764342784810125, -1.481768620689655 ], [ 29.76407329113924, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30930, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.481768620689655 ], [ 29.764342784810125, -1.482038103448276 ], [ 29.764612278481014, -1.482038103448276 ], [ 29.764612278481014, -1.481768620689655 ], [ 29.764342784810125, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30931, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.481768620689655 ], [ 29.764612278481014, -1.482038103448276 ], [ 29.764881772151899, -1.482038103448276 ], [ 29.764881772151899, -1.481768620689655 ], [ 29.764612278481014, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30932, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.481768620689655 ], [ 29.764881772151899, -1.482038103448276 ], [ 29.765151265822784, -1.482038103448276 ], [ 29.765151265822784, -1.481768620689655 ], [ 29.764881772151899, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30933, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.481768620689655 ], [ 29.765151265822784, -1.482038103448276 ], [ 29.765420759493672, -1.482038103448276 ], [ 29.765420759493672, -1.481768620689655 ], [ 29.765151265822784, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30934, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.481768620689655 ], [ 29.765420759493672, -1.482038103448276 ], [ 29.765959746835442, -1.482038103448276 ], [ 29.765959746835442, -1.481768620689655 ], [ 29.765420759493672, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30935, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.481768620689655 ], [ 29.765959746835442, -1.482038103448276 ], [ 29.766229240506327, -1.482038103448276 ], [ 29.766229240506327, -1.481768620689655 ], [ 29.765959746835442, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30936, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.481768620689655 ], [ 29.766229240506327, -1.482038103448276 ], [ 29.766498734177215, -1.482038103448276 ], [ 29.766498734177215, -1.481768620689655 ], [ 29.766229240506327, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30937, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.481768620689655 ], [ 29.766498734177215, -1.482038103448276 ], [ 29.7667682278481, -1.482038103448276 ], [ 29.7667682278481, -1.481768620689655 ], [ 29.766498734177215, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30938, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.481768620689655 ], [ 29.7667682278481, -1.482038103448276 ], [ 29.767037721518985, -1.482038103448276 ], [ 29.767037721518985, -1.481768620689655 ], [ 29.7667682278481, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30939, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.481768620689655 ], [ 29.767037721518985, -1.482038103448276 ], [ 29.767307215189874, -1.482038103448276 ], [ 29.767307215189874, -1.481768620689655 ], [ 29.767037721518985, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30940, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.481768620689655 ], [ 29.767307215189874, -1.482038103448276 ], [ 29.767576708860759, -1.482038103448276 ], [ 29.767576708860759, -1.481768620689655 ], [ 29.767307215189874, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30941, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.481768620689655 ], [ 29.767576708860759, -1.482038103448276 ], [ 29.767846202531643, -1.482038103448276 ], [ 29.767846202531643, -1.481768620689655 ], [ 29.767576708860759, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30942, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.481768620689655 ], [ 29.767846202531643, -1.482038103448276 ], [ 29.768115696202532, -1.482038103448276 ], [ 29.768115696202532, -1.481768620689655 ], [ 29.767846202531643, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30943, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.481768620689655 ], [ 29.768115696202532, -1.482038103448276 ], [ 29.768385189873417, -1.482038103448276 ], [ 29.768385189873417, -1.481768620689655 ], [ 29.768115696202532, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30944, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.481768620689655 ], [ 29.768385189873417, -1.482038103448276 ], [ 29.768654683544302, -1.482038103448276 ], [ 29.768654683544302, -1.481768620689655 ], [ 29.768385189873417, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30945, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.481768620689655 ], [ 29.768654683544302, -1.482038103448276 ], [ 29.770271645569618, -1.482038103448276 ], [ 29.770271645569618, -1.481768620689655 ], [ 29.768654683544302, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30946, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.481768620689655 ], [ 29.770271645569618, -1.482038103448276 ], [ 29.770810632911392, -1.482038103448276 ], [ 29.770810632911392, -1.481768620689655 ], [ 29.770271645569618, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30947, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.481768620689655 ], [ 29.770810632911392, -1.482038103448276 ], [ 29.771080126582277, -1.482038103448276 ], [ 29.771080126582277, -1.481768620689655 ], [ 29.770810632911392, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30948, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.481768620689655 ], [ 29.771080126582277, -1.482038103448276 ], [ 29.771349620253165, -1.482038103448276 ], [ 29.771349620253165, -1.481768620689655 ], [ 29.771080126582277, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30949, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.481768620689655 ], [ 29.771349620253165, -1.482038103448276 ], [ 29.77161911392405, -1.482038103448276 ], [ 29.77161911392405, -1.481768620689655 ], [ 29.771349620253165, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30950, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.481768620689655 ], [ 29.77161911392405, -1.482038103448276 ], [ 29.771888607594935, -1.482038103448276 ], [ 29.771888607594935, -1.481768620689655 ], [ 29.77161911392405, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30951, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.481768620689655 ], [ 29.771888607594935, -1.482038103448276 ], [ 29.772158101265823, -1.482038103448276 ], [ 29.772158101265823, -1.481768620689655 ], [ 29.771888607594935, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30952, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.481768620689655 ], [ 29.772158101265823, -1.482038103448276 ], [ 29.772427594936708, -1.482038103448276 ], [ 29.772427594936708, -1.481768620689655 ], [ 29.772158101265823, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30953, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.481768620689655 ], [ 29.772427594936708, -1.482038103448276 ], [ 29.772697088607593, -1.482038103448276 ], [ 29.772697088607593, -1.481768620689655 ], [ 29.772427594936708, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30954, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.481768620689655 ], [ 29.772697088607593, -1.482038103448276 ], [ 29.772966582278482, -1.482038103448276 ], [ 29.772966582278482, -1.481768620689655 ], [ 29.772697088607593, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30955, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.481768620689655 ], [ 29.772966582278482, -1.482038103448276 ], [ 29.773236075949367, -1.482038103448276 ], [ 29.773236075949367, -1.481768620689655 ], [ 29.772966582278482, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30956, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.481768620689655 ], [ 29.773236075949367, -1.482038103448276 ], [ 29.773505569620252, -1.482038103448276 ], [ 29.773505569620252, -1.481768620689655 ], [ 29.773236075949367, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30957, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.481768620689655 ], [ 29.773505569620252, -1.482038103448276 ], [ 29.77377506329114, -1.482038103448276 ], [ 29.77377506329114, -1.481768620689655 ], [ 29.773505569620252, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30958, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.481768620689655 ], [ 29.77377506329114, -1.482038103448276 ], [ 29.774044556962025, -1.482038103448276 ], [ 29.774044556962025, -1.481768620689655 ], [ 29.77377506329114, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30959, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.481768620689655 ], [ 29.774044556962025, -1.482038103448276 ], [ 29.77431405063291, -1.482038103448276 ], [ 29.77431405063291, -1.481768620689655 ], [ 29.774044556962025, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30960, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.481768620689655 ], [ 29.77431405063291, -1.482038103448276 ], [ 29.774853037974683, -1.482038103448276 ], [ 29.774853037974683, -1.481768620689655 ], [ 29.77431405063291, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30961, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.481768620689655 ], [ 29.774853037974683, -1.482038103448276 ], [ 29.775122531645568, -1.482038103448276 ], [ 29.775122531645568, -1.481768620689655 ], [ 29.774853037974683, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30962, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.481768620689655 ], [ 29.775122531645568, -1.482038103448276 ], [ 29.775392025316457, -1.482038103448276 ], [ 29.775392025316457, -1.481768620689655 ], [ 29.775122531645568, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30963, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.481768620689655 ], [ 29.775392025316457, -1.482038103448276 ], [ 29.775661518987341, -1.482038103448276 ], [ 29.775661518987341, -1.481768620689655 ], [ 29.775392025316457, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30964, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.481768620689655 ], [ 29.775661518987341, -1.482038103448276 ], [ 29.775931012658226, -1.482038103448276 ], [ 29.775931012658226, -1.481768620689655 ], [ 29.775661518987341, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30965, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.481768620689655 ], [ 29.775931012658226, -1.482038103448276 ], [ 29.776200506329115, -1.482038103448276 ], [ 29.776200506329115, -1.481768620689655 ], [ 29.775931012658226, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30966, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.481768620689655 ], [ 29.776200506329115, -1.482038103448276 ], [ 29.77647, -1.482038103448276 ], [ 29.77647, -1.481768620689655 ], [ 29.776200506329115, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30967, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.481768620689655 ], [ 29.77647, -1.482038103448276 ], [ 29.776739493670885, -1.482038103448276 ], [ 29.776739493670885, -1.481768620689655 ], [ 29.77647, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30968, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.481768620689655 ], [ 29.776739493670885, -1.482038103448276 ], [ 29.777008987341773, -1.482038103448276 ], [ 29.777008987341773, -1.481768620689655 ], [ 29.776739493670885, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30969, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.481768620689655 ], [ 29.77916493670886, -1.482307586206897 ], [ 29.779434430379748, -1.482307586206897 ], [ 29.779434430379748, -1.481768620689655 ], [ 29.77916493670886, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30970, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.481768620689655 ], [ 29.779973417721518, -1.482307586206897 ], [ 29.780242911392406, -1.482307586206897 ], [ 29.780242911392406, -1.481768620689655 ], [ 29.779973417721518, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30971, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.481768620689655 ], [ 29.780512405063291, -1.482307586206897 ], [ 29.780781898734176, -1.482307586206897 ], [ 29.780781898734176, -1.481768620689655 ], [ 29.780512405063291, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30972, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.481768620689655 ], [ 29.780781898734176, -1.482038103448276 ], [ 29.781051392405065, -1.482038103448276 ], [ 29.781051392405065, -1.481768620689655 ], [ 29.780781898734176, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30973, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.481768620689655 ], [ 29.78132088607595, -1.482307586206897 ], [ 29.781590379746834, -1.482307586206897 ], [ 29.781590379746834, -1.481768620689655 ], [ 29.78132088607595, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30974, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.481768620689655 ], [ 29.781590379746834, -1.482038103448276 ], [ 29.781859873417723, -1.482038103448276 ], [ 29.781859873417723, -1.481768620689655 ], [ 29.781590379746834, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30975, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.481768620689655 ], [ 29.781859873417723, -1.482038103448276 ], [ 29.782129367088608, -1.482038103448276 ], [ 29.782129367088608, -1.481768620689655 ], [ 29.781859873417723, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30976, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.481768620689655 ], [ 29.782129367088608, -1.482307586206897 ], [ 29.782398860759493, -1.482307586206897 ], [ 29.782398860759493, -1.481768620689655 ], [ 29.782129367088608, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30977, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.481768620689655 ], [ 29.782398860759493, -1.482038103448276 ], [ 29.782668354430381, -1.482038103448276 ], [ 29.782668354430381, -1.481768620689655 ], [ 29.782398860759493, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30978, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.481768620689655 ], [ 29.782668354430381, -1.482038103448276 ], [ 29.782937848101266, -1.482038103448276 ], [ 29.782937848101266, -1.481768620689655 ], [ 29.782668354430381, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30979, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.481768620689655 ], [ 29.782937848101266, -1.482038103448276 ], [ 29.783207341772151, -1.482038103448276 ], [ 29.783207341772151, -1.481768620689655 ], [ 29.782937848101266, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30980, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.481768620689655 ], [ 29.783207341772151, -1.482038103448276 ], [ 29.783476835443039, -1.482038103448276 ], [ 29.783476835443039, -1.481768620689655 ], [ 29.783207341772151, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30981, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.481768620689655 ], [ 29.783476835443039, -1.482038103448276 ], [ 29.784015822784809, -1.482038103448276 ], [ 29.784015822784809, -1.481768620689655 ], [ 29.783476835443039, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30982, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.481768620689655 ], [ 29.784554810126583, -1.482307586206897 ], [ 29.784824303797468, -1.482307586206897 ], [ 29.784824303797468, -1.481768620689655 ], [ 29.784554810126583, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30983, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.481768620689655 ], [ 29.784824303797468, -1.482307586206897 ], [ 29.785093797468356, -1.482307586206897 ], [ 29.785093797468356, -1.481768620689655 ], [ 29.784824303797468, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30984, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.481768620689655 ], [ 29.785093797468356, -1.482577068965517 ], [ 29.785363291139241, -1.482577068965517 ], [ 29.785363291139241, -1.481768620689655 ], [ 29.785093797468356, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30985, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789944683544302, -1.481768620689655 ], [ 29.789944683544302, -1.482038103448276 ], [ 29.790214177215191, -1.482038103448276 ], [ 29.790214177215191, -1.481768620689655 ], [ 29.789944683544302, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30986, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.481768620689655 ], [ 29.790214177215191, -1.482038103448276 ], [ 29.790483670886076, -1.482038103448276 ], [ 29.790483670886076, -1.481768620689655 ], [ 29.790214177215191, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30987, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790483670886076, -1.481768620689655 ], [ 29.790483670886076, -1.482038103448276 ], [ 29.791022658227849, -1.482038103448276 ], [ 29.791022658227849, -1.481768620689655 ], [ 29.790483670886076, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30988, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.481768620689655 ], [ 29.791022658227849, -1.482038103448276 ], [ 29.791292151898734, -1.482038103448276 ], [ 29.791292151898734, -1.481768620689655 ], [ 29.791022658227849, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30989, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791292151898734, -1.481768620689655 ], [ 29.791292151898734, -1.482038103448276 ], [ 29.791561645569619, -1.482038103448276 ], [ 29.791561645569619, -1.481768620689655 ], [ 29.791292151898734, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30990, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791561645569619, -1.481768620689655 ], [ 29.791561645569619, -1.482307586206897 ], [ 29.791831139240507, -1.482307586206897 ], [ 29.791831139240507, -1.481768620689655 ], [ 29.791561645569619, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30991, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.481768620689655 ], [ 29.800993924050633, -1.482038103448276 ], [ 29.801263417721518, -1.482038103448276 ], [ 29.801263417721518, -1.481768620689655 ], [ 29.800993924050633, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30992, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.481768620689655 ], [ 29.801263417721518, -1.482038103448276 ], [ 29.801532911392407, -1.482038103448276 ], [ 29.801532911392407, -1.481768620689655 ], [ 29.801263417721518, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30993, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.481768620689655 ], [ 29.80261088607595, -1.482038103448276 ], [ 29.802880379746835, -1.482038103448276 ], [ 29.802880379746835, -1.481768620689655 ], [ 29.80261088607595, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30994, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.481768620689655 ], [ 29.802880379746835, -1.482038103448276 ], [ 29.803149873417723, -1.482038103448276 ], [ 29.803149873417723, -1.481768620689655 ], [ 29.802880379746835, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30995, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.481768620689655 ], [ 29.803149873417723, -1.482307586206897 ], [ 29.803419367088608, -1.482307586206897 ], [ 29.803419367088608, -1.481768620689655 ], [ 29.803149873417723, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30996, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.481768620689655 ], [ 29.803419367088608, -1.482307586206897 ], [ 29.803688860759493, -1.482307586206897 ], [ 29.803688860759493, -1.481768620689655 ], [ 29.803419367088608, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30997, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.481768620689655 ], [ 29.803688860759493, -1.482307586206897 ], [ 29.803958354430382, -1.482307586206897 ], [ 29.803958354430382, -1.481768620689655 ], [ 29.803688860759493, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30998, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762456329113924, -1.482038103448276 ], [ 29.762456329113924, -1.482307586206897 ], [ 29.762725822784809, -1.482307586206897 ], [ 29.762725822784809, -1.482038103448276 ], [ 29.762456329113924, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 30999, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.482038103448276 ], [ 29.762725822784809, -1.482307586206897 ], [ 29.762995316455697, -1.482307586206897 ], [ 29.762995316455697, -1.482038103448276 ], [ 29.762725822784809, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31000, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.482038103448276 ], [ 29.762995316455697, -1.482307586206897 ], [ 29.763264810126582, -1.482307586206897 ], [ 29.763264810126582, -1.482038103448276 ], [ 29.762995316455697, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31001, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.482038103448276 ], [ 29.763264810126582, -1.482307586206897 ], [ 29.763534303797467, -1.482307586206897 ], [ 29.763534303797467, -1.482038103448276 ], [ 29.763264810126582, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31002, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.482038103448276 ], [ 29.763534303797467, -1.482307586206897 ], [ 29.763803797468356, -1.482307586206897 ], [ 29.763803797468356, -1.482038103448276 ], [ 29.763534303797467, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31003, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.482038103448276 ], [ 29.763803797468356, -1.482307586206897 ], [ 29.76407329113924, -1.482307586206897 ], [ 29.76407329113924, -1.482038103448276 ], [ 29.763803797468356, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31004, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.482038103448276 ], [ 29.76407329113924, -1.482307586206897 ], [ 29.764342784810125, -1.482307586206897 ], [ 29.764342784810125, -1.482038103448276 ], [ 29.76407329113924, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31005, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.482038103448276 ], [ 29.764342784810125, -1.482307586206897 ], [ 29.764612278481014, -1.482307586206897 ], [ 29.764612278481014, -1.482038103448276 ], [ 29.764342784810125, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31006, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.482038103448276 ], [ 29.764612278481014, -1.482307586206897 ], [ 29.764881772151899, -1.482307586206897 ], [ 29.764881772151899, -1.482038103448276 ], [ 29.764612278481014, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31007, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.482038103448276 ], [ 29.764881772151899, -1.482307586206897 ], [ 29.765151265822784, -1.482307586206897 ], [ 29.765151265822784, -1.482038103448276 ], [ 29.764881772151899, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31008, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.482038103448276 ], [ 29.765151265822784, -1.482307586206897 ], [ 29.765420759493672, -1.482307586206897 ], [ 29.765420759493672, -1.482038103448276 ], [ 29.765151265822784, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31009, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.482038103448276 ], [ 29.765420759493672, -1.482307586206897 ], [ 29.765959746835442, -1.482307586206897 ], [ 29.765959746835442, -1.482038103448276 ], [ 29.765420759493672, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31010, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.482038103448276 ], [ 29.765959746835442, -1.482307586206897 ], [ 29.766229240506327, -1.482307586206897 ], [ 29.766229240506327, -1.482038103448276 ], [ 29.765959746835442, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31011, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.482038103448276 ], [ 29.766229240506327, -1.482307586206897 ], [ 29.766498734177215, -1.482307586206897 ], [ 29.766498734177215, -1.482038103448276 ], [ 29.766229240506327, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31012, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.482038103448276 ], [ 29.766498734177215, -1.482307586206897 ], [ 29.7667682278481, -1.482307586206897 ], [ 29.7667682278481, -1.482038103448276 ], [ 29.766498734177215, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31013, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.482038103448276 ], [ 29.7667682278481, -1.482307586206897 ], [ 29.767037721518985, -1.482307586206897 ], [ 29.767037721518985, -1.482038103448276 ], [ 29.7667682278481, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31014, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.482038103448276 ], [ 29.767037721518985, -1.482307586206897 ], [ 29.767307215189874, -1.482307586206897 ], [ 29.767307215189874, -1.482038103448276 ], [ 29.767037721518985, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31015, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.482038103448276 ], [ 29.767307215189874, -1.482307586206897 ], [ 29.767576708860759, -1.482307586206897 ], [ 29.767576708860759, -1.482038103448276 ], [ 29.767307215189874, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31016, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.482038103448276 ], [ 29.767576708860759, -1.482307586206897 ], [ 29.767846202531643, -1.482307586206897 ], [ 29.767846202531643, -1.482038103448276 ], [ 29.767576708860759, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31017, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.482038103448276 ], [ 29.767846202531643, -1.482307586206897 ], [ 29.768115696202532, -1.482307586206897 ], [ 29.768115696202532, -1.482038103448276 ], [ 29.767846202531643, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31018, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.482038103448276 ], [ 29.768115696202532, -1.482307586206897 ], [ 29.768385189873417, -1.482307586206897 ], [ 29.768385189873417, -1.482038103448276 ], [ 29.768115696202532, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31019, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.482038103448276 ], [ 29.768385189873417, -1.482307586206897 ], [ 29.768654683544302, -1.482307586206897 ], [ 29.768654683544302, -1.482038103448276 ], [ 29.768385189873417, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31020, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.482038103448276 ], [ 29.768654683544302, -1.482307586206897 ], [ 29.76892417721519, -1.482307586206897 ], [ 29.76892417721519, -1.482038103448276 ], [ 29.768654683544302, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31021, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.482038103448276 ], [ 29.76892417721519, -1.482307586206897 ], [ 29.769193670886075, -1.482307586206897 ], [ 29.769193670886075, -1.482038103448276 ], [ 29.76892417721519, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31022, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.482038103448276 ], [ 29.769193670886075, -1.482307586206897 ], [ 29.76946316455696, -1.482307586206897 ], [ 29.76946316455696, -1.482577068965517 ], [ 29.769732658227849, -1.482577068965517 ], [ 29.769732658227849, -1.482307586206897 ], [ 29.770541139240507, -1.482307586206897 ], [ 29.770541139240507, -1.482038103448276 ], [ 29.769193670886075, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31023, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.482038103448276 ], [ 29.770541139240507, -1.482307586206897 ], [ 29.771080126582277, -1.482307586206897 ], [ 29.771080126582277, -1.482038103448276 ], [ 29.770541139240507, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31024, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.482038103448276 ], [ 29.771080126582277, -1.482307586206897 ], [ 29.771349620253165, -1.482307586206897 ], [ 29.771349620253165, -1.482038103448276 ], [ 29.771080126582277, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31025, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.482038103448276 ], [ 29.771349620253165, -1.482307586206897 ], [ 29.77161911392405, -1.482307586206897 ], [ 29.77161911392405, -1.482038103448276 ], [ 29.771349620253165, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31026, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.482038103448276 ], [ 29.77161911392405, -1.482307586206897 ], [ 29.771888607594935, -1.482307586206897 ], [ 29.771888607594935, -1.482038103448276 ], [ 29.77161911392405, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31027, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.482038103448276 ], [ 29.771888607594935, -1.482307586206897 ], [ 29.772158101265823, -1.482307586206897 ], [ 29.772158101265823, -1.482038103448276 ], [ 29.771888607594935, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31028, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.482038103448276 ], [ 29.772158101265823, -1.482307586206897 ], [ 29.772427594936708, -1.482307586206897 ], [ 29.772427594936708, -1.482038103448276 ], [ 29.772158101265823, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31029, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.482038103448276 ], [ 29.772427594936708, -1.482307586206897 ], [ 29.772697088607593, -1.482307586206897 ], [ 29.772697088607593, -1.482038103448276 ], [ 29.772427594936708, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31030, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.482038103448276 ], [ 29.772697088607593, -1.482307586206897 ], [ 29.772966582278482, -1.482307586206897 ], [ 29.772966582278482, -1.482038103448276 ], [ 29.772697088607593, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31031, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.482038103448276 ], [ 29.772966582278482, -1.482307586206897 ], [ 29.773236075949367, -1.482307586206897 ], [ 29.773236075949367, -1.482038103448276 ], [ 29.772966582278482, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31032, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.482038103448276 ], [ 29.773236075949367, -1.482307586206897 ], [ 29.773505569620252, -1.482307586206897 ], [ 29.773505569620252, -1.482038103448276 ], [ 29.773236075949367, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31033, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.482038103448276 ], [ 29.773505569620252, -1.482307586206897 ], [ 29.77377506329114, -1.482307586206897 ], [ 29.77377506329114, -1.482038103448276 ], [ 29.773505569620252, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31034, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.482038103448276 ], [ 29.77377506329114, -1.482307586206897 ], [ 29.774044556962025, -1.482307586206897 ], [ 29.774044556962025, -1.482038103448276 ], [ 29.77377506329114, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31035, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.482038103448276 ], [ 29.774044556962025, -1.482307586206897 ], [ 29.77431405063291, -1.482307586206897 ], [ 29.77431405063291, -1.482038103448276 ], [ 29.774044556962025, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31036, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.482038103448276 ], [ 29.77431405063291, -1.482307586206897 ], [ 29.774853037974683, -1.482307586206897 ], [ 29.774853037974683, -1.482038103448276 ], [ 29.77431405063291, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31037, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.482038103448276 ], [ 29.774853037974683, -1.482307586206897 ], [ 29.775122531645568, -1.482307586206897 ], [ 29.775122531645568, -1.482038103448276 ], [ 29.774853037974683, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31038, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.482038103448276 ], [ 29.775122531645568, -1.482307586206897 ], [ 29.775392025316457, -1.482307586206897 ], [ 29.775392025316457, -1.482038103448276 ], [ 29.775122531645568, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31039, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.482038103448276 ], [ 29.775392025316457, -1.482307586206897 ], [ 29.775661518987341, -1.482307586206897 ], [ 29.775661518987341, -1.482038103448276 ], [ 29.775392025316457, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31040, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.482038103448276 ], [ 29.775661518987341, -1.482307586206897 ], [ 29.775931012658226, -1.482307586206897 ], [ 29.775931012658226, -1.482038103448276 ], [ 29.775661518987341, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31041, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.482038103448276 ], [ 29.775931012658226, -1.482307586206897 ], [ 29.776200506329115, -1.482307586206897 ], [ 29.776200506329115, -1.482038103448276 ], [ 29.775931012658226, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31042, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.482038103448276 ], [ 29.776200506329115, -1.482307586206897 ], [ 29.77647, -1.482307586206897 ], [ 29.77647, -1.482038103448276 ], [ 29.776200506329115, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31043, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.482038103448276 ], [ 29.77647, -1.482307586206897 ], [ 29.776739493670885, -1.482307586206897 ], [ 29.776739493670885, -1.482038103448276 ], [ 29.77647, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31044, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.482038103448276 ], [ 29.781859873417723, -1.482577068965517 ], [ 29.782129367088608, -1.482577068965517 ], [ 29.782129367088608, -1.482038103448276 ], [ 29.781859873417723, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31045, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.482038103448276 ], [ 29.782668354430381, -1.482307586206897 ], [ 29.782937848101266, -1.482307586206897 ], [ 29.782937848101266, -1.482038103448276 ], [ 29.782668354430381, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31046, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.482038103448276 ], [ 29.782937848101266, -1.482307586206897 ], [ 29.783207341772151, -1.482307586206897 ], [ 29.783207341772151, -1.482038103448276 ], [ 29.782937848101266, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31047, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.482038103448276 ], [ 29.783207341772151, -1.482577068965517 ], [ 29.783476835443039, -1.482577068965517 ], [ 29.783476835443039, -1.482038103448276 ], [ 29.783207341772151, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31048, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.482038103448276 ], [ 29.783476835443039, -1.482307586206897 ], [ 29.784015822784809, -1.482307586206897 ], [ 29.784015822784809, -1.482038103448276 ], [ 29.783476835443039, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31049, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.482038103448276 ], [ 29.784015822784809, -1.482307586206897 ], [ 29.784285316455698, -1.482307586206897 ], [ 29.784285316455698, -1.482038103448276 ], [ 29.784015822784809, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31050, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.482038103448276 ], [ 29.784285316455698, -1.482307586206897 ], [ 29.784554810126583, -1.482307586206897 ], [ 29.784554810126583, -1.482038103448276 ], [ 29.784285316455698, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31051, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.790214177215191, -1.482038103448276 ], [ 29.790214177215191, -1.482307586206897 ], [ 29.791022658227849, -1.482307586206897 ], [ 29.791022658227849, -1.482038103448276 ], [ 29.790214177215191, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31052, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.482038103448276 ], [ 29.791022658227849, -1.482307586206897 ], [ 29.791561645569619, -1.482307586206897 ], [ 29.791561645569619, -1.482038103448276 ], [ 29.791022658227849, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31053, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.482038103448276 ], [ 29.800993924050633, -1.482307586206897 ], [ 29.801263417721518, -1.482307586206897 ], [ 29.801263417721518, -1.482038103448276 ], [ 29.800993924050633, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31054, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.482038103448276 ], [ 29.801263417721518, -1.482307586206897 ], [ 29.801532911392407, -1.482307586206897 ], [ 29.801532911392407, -1.482038103448276 ], [ 29.801263417721518, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31055, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.482038103448276 ], [ 29.801532911392407, -1.482307586206897 ], [ 29.802071898734177, -1.482307586206897 ], [ 29.802071898734177, -1.482038103448276 ], [ 29.801532911392407, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31056, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.482038103448276 ], [ 29.802071898734177, -1.482307586206897 ], [ 29.802341392405065, -1.482307586206897 ], [ 29.802341392405065, -1.482038103448276 ], [ 29.802071898734177, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31057, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.482038103448276 ], [ 29.802341392405065, -1.482307586206897 ], [ 29.80261088607595, -1.482307586206897 ], [ 29.80261088607595, -1.482038103448276 ], [ 29.802341392405065, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31058, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.482038103448276 ], [ 29.80261088607595, -1.482307586206897 ], [ 29.802880379746835, -1.482307586206897 ], [ 29.802880379746835, -1.482038103448276 ], [ 29.80261088607595, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31059, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.482038103448276 ], [ 29.802880379746835, -1.482307586206897 ], [ 29.803149873417723, -1.482307586206897 ], [ 29.803149873417723, -1.482038103448276 ], [ 29.802880379746835, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31060, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762995316455697, -1.482307586206897 ], [ 29.762995316455697, -1.482577068965517 ], [ 29.763264810126582, -1.482577068965517 ], [ 29.763264810126582, -1.482307586206897 ], [ 29.762995316455697, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31061, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.482307586206897 ], [ 29.763264810126582, -1.482577068965517 ], [ 29.763534303797467, -1.482577068965517 ], [ 29.763534303797467, -1.482307586206897 ], [ 29.763264810126582, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31062, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.482307586206897 ], [ 29.763534303797467, -1.482577068965517 ], [ 29.763803797468356, -1.482577068965517 ], [ 29.763803797468356, -1.482307586206897 ], [ 29.763534303797467, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31063, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.482307586206897 ], [ 29.763803797468356, -1.482577068965517 ], [ 29.76407329113924, -1.482577068965517 ], [ 29.76407329113924, -1.482307586206897 ], [ 29.763803797468356, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31064, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.482307586206897 ], [ 29.76407329113924, -1.482577068965517 ], [ 29.764342784810125, -1.482577068965517 ], [ 29.764342784810125, -1.482307586206897 ], [ 29.76407329113924, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31065, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.482307586206897 ], [ 29.764342784810125, -1.482577068965517 ], [ 29.764612278481014, -1.482577068965517 ], [ 29.764612278481014, -1.482307586206897 ], [ 29.764342784810125, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31066, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.482307586206897 ], [ 29.764612278481014, -1.482577068965517 ], [ 29.764881772151899, -1.482577068965517 ], [ 29.764881772151899, -1.482307586206897 ], [ 29.764612278481014, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31067, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.482307586206897 ], [ 29.764881772151899, -1.482577068965517 ], [ 29.765151265822784, -1.482577068965517 ], [ 29.765151265822784, -1.482307586206897 ], [ 29.764881772151899, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31068, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.482307586206897 ], [ 29.765151265822784, -1.482577068965517 ], [ 29.765420759493672, -1.482577068965517 ], [ 29.765420759493672, -1.482307586206897 ], [ 29.765151265822784, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31069, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.482307586206897 ], [ 29.765420759493672, -1.482577068965517 ], [ 29.765959746835442, -1.482577068965517 ], [ 29.765959746835442, -1.482307586206897 ], [ 29.765420759493672, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31070, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.482307586206897 ], [ 29.765959746835442, -1.482577068965517 ], [ 29.766229240506327, -1.482577068965517 ], [ 29.766229240506327, -1.482307586206897 ], [ 29.765959746835442, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31071, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.482307586206897 ], [ 29.766229240506327, -1.482577068965517 ], [ 29.766498734177215, -1.482577068965517 ], [ 29.766498734177215, -1.482307586206897 ], [ 29.766229240506327, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31072, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.482307586206897 ], [ 29.766498734177215, -1.482577068965517 ], [ 29.7667682278481, -1.482577068965517 ], [ 29.7667682278481, -1.482307586206897 ], [ 29.766498734177215, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31073, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.482307586206897 ], [ 29.7667682278481, -1.482577068965517 ], [ 29.767037721518985, -1.482577068965517 ], [ 29.767037721518985, -1.482307586206897 ], [ 29.7667682278481, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31074, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.482307586206897 ], [ 29.767037721518985, -1.482577068965517 ], [ 29.767307215189874, -1.482577068965517 ], [ 29.767307215189874, -1.482307586206897 ], [ 29.767037721518985, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31075, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.482307586206897 ], [ 29.767307215189874, -1.482577068965517 ], [ 29.767576708860759, -1.482577068965517 ], [ 29.767576708860759, -1.482307586206897 ], [ 29.767307215189874, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31076, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.482307586206897 ], [ 29.767576708860759, -1.482577068965517 ], [ 29.767846202531643, -1.482577068965517 ], [ 29.767846202531643, -1.482307586206897 ], [ 29.767576708860759, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31077, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.482307586206897 ], [ 29.767846202531643, -1.482577068965517 ], [ 29.768115696202532, -1.482577068965517 ], [ 29.768115696202532, -1.482307586206897 ], [ 29.767846202531643, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31078, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.482307586206897 ], [ 29.768115696202532, -1.482577068965517 ], [ 29.768385189873417, -1.482577068965517 ], [ 29.768385189873417, -1.482307586206897 ], [ 29.768115696202532, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31079, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.482307586206897 ], [ 29.768385189873417, -1.482577068965517 ], [ 29.768654683544302, -1.482577068965517 ], [ 29.768654683544302, -1.482307586206897 ], [ 29.768385189873417, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31080, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.482307586206897 ], [ 29.768654683544302, -1.482577068965517 ], [ 29.76892417721519, -1.482577068965517 ], [ 29.76892417721519, -1.482307586206897 ], [ 29.768654683544302, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31081, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.482307586206897 ], [ 29.76892417721519, -1.482577068965517 ], [ 29.769193670886075, -1.482577068965517 ], [ 29.769193670886075, -1.482307586206897 ], [ 29.76892417721519, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31082, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.482307586206897 ], [ 29.769193670886075, -1.482577068965517 ], [ 29.76946316455696, -1.482577068965517 ], [ 29.76946316455696, -1.482307586206897 ], [ 29.769193670886075, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31083, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.482307586206897 ], [ 29.770271645569618, -1.482577068965517 ], [ 29.770810632911392, -1.482577068965517 ], [ 29.770810632911392, -1.482307586206897 ], [ 29.770271645569618, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31084, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.482307586206897 ], [ 29.770810632911392, -1.482577068965517 ], [ 29.771080126582277, -1.482577068965517 ], [ 29.771080126582277, -1.482307586206897 ], [ 29.770810632911392, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31085, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.482307586206897 ], [ 29.771080126582277, -1.482577068965517 ], [ 29.77161911392405, -1.482577068965517 ], [ 29.77161911392405, -1.482307586206897 ], [ 29.771080126582277, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31086, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.482307586206897 ], [ 29.77161911392405, -1.482577068965517 ], [ 29.771888607594935, -1.482577068965517 ], [ 29.771888607594935, -1.482307586206897 ], [ 29.77161911392405, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31087, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.482307586206897 ], [ 29.771888607594935, -1.482577068965517 ], [ 29.772158101265823, -1.482577068965517 ], [ 29.772158101265823, -1.482307586206897 ], [ 29.771888607594935, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31088, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.482307586206897 ], [ 29.772158101265823, -1.482577068965517 ], [ 29.772427594936708, -1.482577068965517 ], [ 29.772427594936708, -1.482307586206897 ], [ 29.772158101265823, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31089, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.482307586206897 ], [ 29.772427594936708, -1.482577068965517 ], [ 29.772697088607593, -1.482577068965517 ], [ 29.772697088607593, -1.482307586206897 ], [ 29.772427594936708, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31090, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.482307586206897 ], [ 29.772697088607593, -1.482577068965517 ], [ 29.772966582278482, -1.482577068965517 ], [ 29.772966582278482, -1.482307586206897 ], [ 29.772697088607593, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31091, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.482307586206897 ], [ 29.772966582278482, -1.482577068965517 ], [ 29.773236075949367, -1.482577068965517 ], [ 29.773236075949367, -1.482307586206897 ], [ 29.772966582278482, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31092, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.482307586206897 ], [ 29.773236075949367, -1.482577068965517 ], [ 29.773505569620252, -1.482577068965517 ], [ 29.773505569620252, -1.482307586206897 ], [ 29.773236075949367, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31093, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.482307586206897 ], [ 29.773505569620252, -1.482577068965517 ], [ 29.77377506329114, -1.482577068965517 ], [ 29.77377506329114, -1.482307586206897 ], [ 29.773505569620252, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31094, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.482307586206897 ], [ 29.77377506329114, -1.482577068965517 ], [ 29.774044556962025, -1.482577068965517 ], [ 29.774044556962025, -1.482307586206897 ], [ 29.77377506329114, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31095, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.482307586206897 ], [ 29.774044556962025, -1.482577068965517 ], [ 29.77431405063291, -1.482577068965517 ], [ 29.77431405063291, -1.482307586206897 ], [ 29.774044556962025, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31096, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.482307586206897 ], [ 29.77431405063291, -1.482577068965517 ], [ 29.774853037974683, -1.482577068965517 ], [ 29.774853037974683, -1.482307586206897 ], [ 29.77431405063291, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31097, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.482307586206897 ], [ 29.774853037974683, -1.482577068965517 ], [ 29.775122531645568, -1.482577068965517 ], [ 29.775122531645568, -1.482307586206897 ], [ 29.774853037974683, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31098, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.482307586206897 ], [ 29.775122531645568, -1.482577068965517 ], [ 29.775392025316457, -1.482577068965517 ], [ 29.775392025316457, -1.482307586206897 ], [ 29.775122531645568, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31099, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.482307586206897 ], [ 29.775392025316457, -1.482577068965517 ], [ 29.775661518987341, -1.482577068965517 ], [ 29.775661518987341, -1.482307586206897 ], [ 29.775392025316457, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31100, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.482307586206897 ], [ 29.775661518987341, -1.482577068965517 ], [ 29.775931012658226, -1.482577068965517 ], [ 29.775931012658226, -1.482307586206897 ], [ 29.775661518987341, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31101, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.482307586206897 ], [ 29.775931012658226, -1.482577068965517 ], [ 29.776200506329115, -1.482577068965517 ], [ 29.776200506329115, -1.482307586206897 ], [ 29.775931012658226, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31102, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.482307586206897 ], [ 29.776200506329115, -1.482577068965517 ], [ 29.77647, -1.482577068965517 ], [ 29.77647, -1.482307586206897 ], [ 29.776200506329115, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31103, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.482307586206897 ], [ 29.77647, -1.482577068965517 ], [ 29.776739493670885, -1.482577068965517 ], [ 29.776739493670885, -1.482307586206897 ], [ 29.77647, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31104, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.482307586206897 ], [ 29.784285316455698, -1.482577068965517 ], [ 29.784554810126583, -1.482577068965517 ], [ 29.784554810126583, -1.482307586206897 ], [ 29.784285316455698, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31105, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.482307586206897 ], [ 29.784554810126583, -1.482577068965517 ], [ 29.784824303797468, -1.482577068965517 ], [ 29.784824303797468, -1.482307586206897 ], [ 29.784554810126583, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31106, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.482307586206897 ], [ 29.784824303797468, -1.482577068965517 ], [ 29.785093797468356, -1.482577068965517 ], [ 29.785093797468356, -1.482307586206897 ], [ 29.784824303797468, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31107, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.791022658227849, -1.482307586206897 ], [ 29.791022658227849, -1.482577068965517 ], [ 29.791561645569619, -1.482577068965517 ], [ 29.791561645569619, -1.482307586206897 ], [ 29.791022658227849, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31108, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.482307586206897 ], [ 29.800993924050633, -1.482577068965517 ], [ 29.801263417721518, -1.482577068965517 ], [ 29.801263417721518, -1.482307586206897 ], [ 29.800993924050633, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31109, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.482307586206897 ], [ 29.801263417721518, -1.482577068965517 ], [ 29.801532911392407, -1.482577068965517 ], [ 29.801532911392407, -1.482307586206897 ], [ 29.801263417721518, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31110, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.482307586206897 ], [ 29.801532911392407, -1.482577068965517 ], [ 29.802071898734177, -1.482577068965517 ], [ 29.802071898734177, -1.482307586206897 ], [ 29.801532911392407, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31111, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.482307586206897 ], [ 29.802071898734177, -1.482577068965517 ], [ 29.802341392405065, -1.482577068965517 ], [ 29.802341392405065, -1.482307586206897 ], [ 29.802071898734177, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31112, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.482307586206897 ], [ 29.802341392405065, -1.482577068965517 ], [ 29.80261088607595, -1.482577068965517 ], [ 29.80261088607595, -1.482307586206897 ], [ 29.802341392405065, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31113, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.482307586206897 ], [ 29.80261088607595, -1.482577068965517 ], [ 29.802880379746835, -1.482577068965517 ], [ 29.802880379746835, -1.482307586206897 ], [ 29.80261088607595, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31114, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.482307586206897 ], [ 29.802880379746835, -1.482577068965517 ], [ 29.803149873417723, -1.482577068965517 ], [ 29.803149873417723, -1.482307586206897 ], [ 29.802880379746835, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31115, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.482307586206897 ], [ 29.803149873417723, -1.482577068965517 ], [ 29.803419367088608, -1.482577068965517 ], [ 29.803419367088608, -1.482307586206897 ], [ 29.803149873417723, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31116, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.482307586206897 ], [ 29.803419367088608, -1.482577068965517 ], [ 29.803688860759493, -1.482577068965517 ], [ 29.803688860759493, -1.482307586206897 ], [ 29.803419367088608, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31117, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.482307586206897 ], [ 29.803688860759493, -1.482577068965517 ], [ 29.803958354430382, -1.482577068965517 ], [ 29.803958354430382, -1.482307586206897 ], [ 29.803688860759493, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31118, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.481229655172414 ], [ 29.799376962025317, -1.482846551724138 ], [ 29.799646455696202, -1.482846551724138 ], [ 29.799646455696202, -1.481229655172414 ], [ 29.799376962025317, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31119, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.481229655172414 ], [ 29.799646455696202, -1.482846551724138 ], [ 29.79991594936709, -1.482846551724138 ], [ 29.79991594936709, -1.481229655172414 ], [ 29.799646455696202, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31120, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.481229655172414 ], [ 29.800185443037975, -1.482846551724138 ], [ 29.80045493670886, -1.482846551724138 ], [ 29.80045493670886, -1.481229655172414 ], [ 29.800185443037975, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31121, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.481229655172414 ], [ 29.80045493670886, -1.482846551724138 ], [ 29.800724430379748, -1.482846551724138 ], [ 29.800724430379748, -1.481229655172414 ], [ 29.80045493670886, -1.481229655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31122, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.481768620689655 ], [ 29.777008987341773, -1.484193965517242 ], [ 29.777278481012658, -1.484193965517242 ], [ 29.777278481012658, -1.481768620689655 ], [ 29.777008987341773, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31123, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.481768620689655 ], [ 29.777547974683543, -1.484193965517242 ], [ 29.777817468354431, -1.484193965517242 ], [ 29.777817468354431, -1.481768620689655 ], [ 29.777547974683543, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31124, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.481768620689655 ], [ 29.778356455696201, -1.484193965517242 ], [ 29.77862594936709, -1.484193965517242 ], [ 29.77862594936709, -1.481768620689655 ], [ 29.778356455696201, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31125, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.481768620689655 ], [ 29.778895443037975, -1.484193965517242 ], [ 29.77916493670886, -1.484193965517242 ], [ 29.77916493670886, -1.481768620689655 ], [ 29.778895443037975, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31126, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.481768620689655 ], [ 29.779703924050633, -1.484193965517242 ], [ 29.779973417721518, -1.484193965517242 ], [ 29.779973417721518, -1.481768620689655 ], [ 29.779703924050633, -1.481768620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31127, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.482038103448276 ], [ 29.776739493670885, -1.484193965517242 ], [ 29.777008987341773, -1.484193965517242 ], [ 29.777008987341773, -1.482038103448276 ], [ 29.776739493670885, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31128, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.482038103448276 ], [ 29.777278481012658, -1.483924482758621 ], [ 29.777547974683543, -1.483924482758621 ], [ 29.777547974683543, -1.482038103448276 ], [ 29.777278481012658, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31129, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.482038103448276 ], [ 29.778086962025316, -1.483924482758621 ], [ 29.778356455696201, -1.483924482758621 ], [ 29.778356455696201, -1.482038103448276 ], [ 29.778086962025316, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31130, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.482038103448276 ], [ 29.77862594936709, -1.483924482758621 ], [ 29.778895443037975, -1.483924482758621 ], [ 29.778895443037975, -1.482038103448276 ], [ 29.77862594936709, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31131, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.482038103448276 ], [ 29.779434430379748, -1.483924482758621 ], [ 29.779703924050633, -1.483924482758621 ], [ 29.779703924050633, -1.482038103448276 ], [ 29.779434430379748, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31132, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.482038103448276 ], [ 29.780242911392406, -1.483924482758621 ], [ 29.780512405063291, -1.483924482758621 ], [ 29.780512405063291, -1.482038103448276 ], [ 29.780242911392406, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31133, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.482038103448276 ], [ 29.780781898734176, -1.483924482758621 ], [ 29.781051392405065, -1.483924482758621 ], [ 29.781051392405065, -1.482038103448276 ], [ 29.780781898734176, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31134, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.482038103448276 ], [ 29.781051392405065, -1.483924482758621 ], [ 29.78132088607595, -1.483924482758621 ], [ 29.78132088607595, -1.482038103448276 ], [ 29.781051392405065, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31135, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.482038103448276 ], [ 29.781590379746834, -1.483924482758621 ], [ 29.781859873417723, -1.483924482758621 ], [ 29.781859873417723, -1.482038103448276 ], [ 29.781590379746834, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31136, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.482038103448276 ], [ 29.782398860759493, -1.483924482758621 ], [ 29.782668354430381, -1.483924482758621 ], [ 29.782668354430381, -1.482038103448276 ], [ 29.782398860759493, -1.482038103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31137, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.762725822784809, -1.482307586206897 ], [ 29.762725822784809, -1.482846551724138 ], [ 29.763264810126582, -1.482846551724138 ], [ 29.763264810126582, -1.482577068965517 ], [ 29.762995316455697, -1.482577068965517 ], [ 29.762995316455697, -1.482307586206897 ], [ 29.762725822784809, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31138, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.482307586206897 ], [ 29.769732658227849, -1.482577068965517 ], [ 29.770002151898733, -1.482577068965517 ], [ 29.770002151898733, -1.482846551724138 ], [ 29.770271645569618, -1.482846551724138 ], [ 29.770271645569618, -1.482307586206897 ], [ 29.769732658227849, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31139, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.482307586206897 ], [ 29.777817468354431, -1.483655 ], [ 29.778086962025316, -1.483655 ], [ 29.778086962025316, -1.482307586206897 ], [ 29.777817468354431, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31140, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.482307586206897 ], [ 29.77916493670886, -1.483655 ], [ 29.779434430379748, -1.483655 ], [ 29.779434430379748, -1.482307586206897 ], [ 29.77916493670886, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31141, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.482307586206897 ], [ 29.779973417721518, -1.483655 ], [ 29.780242911392406, -1.483655 ], [ 29.780242911392406, -1.482307586206897 ], [ 29.779973417721518, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31142, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.482307586206897 ], [ 29.780512405063291, -1.483655 ], [ 29.780781898734176, -1.483655 ], [ 29.780781898734176, -1.482307586206897 ], [ 29.780512405063291, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31143, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.482307586206897 ], [ 29.78132088607595, -1.483655 ], [ 29.781590379746834, -1.483655 ], [ 29.781590379746834, -1.482307586206897 ], [ 29.78132088607595, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31144, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.482307586206897 ], [ 29.782129367088608, -1.483655 ], [ 29.782398860759493, -1.483655 ], [ 29.782398860759493, -1.482307586206897 ], [ 29.782129367088608, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31145, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.482307586206897 ], [ 29.782668354430381, -1.483655 ], [ 29.782937848101266, -1.483655 ], [ 29.782937848101266, -1.482307586206897 ], [ 29.782668354430381, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31146, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.482307586206897 ], [ 29.782937848101266, -1.483655 ], [ 29.783207341772151, -1.483655 ], [ 29.783207341772151, -1.482307586206897 ], [ 29.782937848101266, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31147, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.482307586206897 ], [ 29.783476835443039, -1.483655 ], [ 29.784015822784809, -1.483655 ], [ 29.784015822784809, -1.482307586206897 ], [ 29.783476835443039, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31148, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.482307586206897 ], [ 29.784015822784809, -1.483655 ], [ 29.784285316455698, -1.483655 ], [ 29.784285316455698, -1.482307586206897 ], [ 29.784015822784809, -1.482307586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31149, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.482577068965517 ], [ 29.763264810126582, -1.482846551724138 ], [ 29.763534303797467, -1.482846551724138 ], [ 29.763534303797467, -1.482577068965517 ], [ 29.763264810126582, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31150, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.482577068965517 ], [ 29.763534303797467, -1.482846551724138 ], [ 29.763803797468356, -1.482846551724138 ], [ 29.763803797468356, -1.482577068965517 ], [ 29.763534303797467, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31151, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.482577068965517 ], [ 29.763803797468356, -1.482846551724138 ], [ 29.76407329113924, -1.482846551724138 ], [ 29.76407329113924, -1.482577068965517 ], [ 29.763803797468356, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31152, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.482577068965517 ], [ 29.76407329113924, -1.482846551724138 ], [ 29.764342784810125, -1.482846551724138 ], [ 29.764342784810125, -1.482577068965517 ], [ 29.76407329113924, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31153, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.482577068965517 ], [ 29.764342784810125, -1.482846551724138 ], [ 29.764612278481014, -1.482846551724138 ], [ 29.764612278481014, -1.482577068965517 ], [ 29.764342784810125, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31154, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.482577068965517 ], [ 29.764612278481014, -1.482846551724138 ], [ 29.764881772151899, -1.482846551724138 ], [ 29.764881772151899, -1.482577068965517 ], [ 29.764612278481014, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31155, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.482577068965517 ], [ 29.764881772151899, -1.482846551724138 ], [ 29.765151265822784, -1.482846551724138 ], [ 29.765151265822784, -1.482577068965517 ], [ 29.764881772151899, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31156, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.482577068965517 ], [ 29.765151265822784, -1.482846551724138 ], [ 29.765420759493672, -1.482846551724138 ], [ 29.765420759493672, -1.482577068965517 ], [ 29.765151265822784, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31157, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.482577068965517 ], [ 29.765420759493672, -1.482846551724138 ], [ 29.765959746835442, -1.482846551724138 ], [ 29.765959746835442, -1.482577068965517 ], [ 29.765420759493672, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31158, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.482577068965517 ], [ 29.765959746835442, -1.482846551724138 ], [ 29.766229240506327, -1.482846551724138 ], [ 29.766229240506327, -1.482577068965517 ], [ 29.765959746835442, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31159, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.482577068965517 ], [ 29.766229240506327, -1.482846551724138 ], [ 29.766498734177215, -1.482846551724138 ], [ 29.766498734177215, -1.482577068965517 ], [ 29.766229240506327, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31160, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.482577068965517 ], [ 29.766498734177215, -1.482846551724138 ], [ 29.7667682278481, -1.482846551724138 ], [ 29.7667682278481, -1.482577068965517 ], [ 29.766498734177215, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31161, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.482577068965517 ], [ 29.7667682278481, -1.482846551724138 ], [ 29.767037721518985, -1.482846551724138 ], [ 29.767037721518985, -1.482577068965517 ], [ 29.7667682278481, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31162, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.482577068965517 ], [ 29.767037721518985, -1.482846551724138 ], [ 29.767307215189874, -1.482846551724138 ], [ 29.767307215189874, -1.482577068965517 ], [ 29.767037721518985, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31163, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.482577068965517 ], [ 29.767307215189874, -1.482846551724138 ], [ 29.767576708860759, -1.482846551724138 ], [ 29.767576708860759, -1.482577068965517 ], [ 29.767307215189874, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31164, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.482577068965517 ], [ 29.767576708860759, -1.482846551724138 ], [ 29.767846202531643, -1.482846551724138 ], [ 29.767846202531643, -1.482577068965517 ], [ 29.767576708860759, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31165, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.482577068965517 ], [ 29.767846202531643, -1.482846551724138 ], [ 29.768115696202532, -1.482846551724138 ], [ 29.768115696202532, -1.482577068965517 ], [ 29.767846202531643, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31166, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.482577068965517 ], [ 29.768115696202532, -1.482846551724138 ], [ 29.768385189873417, -1.482846551724138 ], [ 29.768385189873417, -1.482577068965517 ], [ 29.768115696202532, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31167, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.482577068965517 ], [ 29.768385189873417, -1.482846551724138 ], [ 29.768654683544302, -1.482846551724138 ], [ 29.768654683544302, -1.482577068965517 ], [ 29.768385189873417, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31168, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.482577068965517 ], [ 29.768654683544302, -1.482846551724138 ], [ 29.76892417721519, -1.482846551724138 ], [ 29.76892417721519, -1.482577068965517 ], [ 29.768654683544302, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31169, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.482577068965517 ], [ 29.76892417721519, -1.482846551724138 ], [ 29.769193670886075, -1.482846551724138 ], [ 29.769193670886075, -1.482577068965517 ], [ 29.76892417721519, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31170, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.482577068965517 ], [ 29.769193670886075, -1.482846551724138 ], [ 29.76946316455696, -1.482846551724138 ], [ 29.76946316455696, -1.482577068965517 ], [ 29.769193670886075, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31171, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.482577068965517 ], [ 29.76946316455696, -1.482846551724138 ], [ 29.769732658227849, -1.482846551724138 ], [ 29.769732658227849, -1.482577068965517 ], [ 29.76946316455696, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31172, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.482577068965517 ], [ 29.769732658227849, -1.482846551724138 ], [ 29.770002151898733, -1.482846551724138 ], [ 29.770002151898733, -1.482577068965517 ], [ 29.769732658227849, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31173, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.482577068965517 ], [ 29.770271645569618, -1.482846551724138 ], [ 29.770541139240507, -1.482846551724138 ], [ 29.770541139240507, -1.482577068965517 ], [ 29.770271645569618, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31174, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.482577068965517 ], [ 29.770541139240507, -1.483116034482759 ], [ 29.770810632911392, -1.483116034482759 ], [ 29.770810632911392, -1.482577068965517 ], [ 29.770541139240507, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31175, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.482577068965517 ], [ 29.770810632911392, -1.482846551724138 ], [ 29.771080126582277, -1.482846551724138 ], [ 29.771080126582277, -1.482577068965517 ], [ 29.770810632911392, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31176, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.482577068965517 ], [ 29.771080126582277, -1.482846551724138 ], [ 29.771349620253165, -1.482846551724138 ], [ 29.771349620253165, -1.482577068965517 ], [ 29.771080126582277, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31177, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.482577068965517 ], [ 29.771349620253165, -1.482846551724138 ], [ 29.77161911392405, -1.482846551724138 ], [ 29.77161911392405, -1.482577068965517 ], [ 29.771349620253165, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31178, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.482577068965517 ], [ 29.77161911392405, -1.482846551724138 ], [ 29.771888607594935, -1.482846551724138 ], [ 29.771888607594935, -1.482577068965517 ], [ 29.77161911392405, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31179, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.482577068965517 ], [ 29.771888607594935, -1.482846551724138 ], [ 29.772158101265823, -1.482846551724138 ], [ 29.772158101265823, -1.482577068965517 ], [ 29.771888607594935, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31180, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.482577068965517 ], [ 29.772158101265823, -1.482846551724138 ], [ 29.772427594936708, -1.482846551724138 ], [ 29.772427594936708, -1.482577068965517 ], [ 29.772158101265823, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31181, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.482577068965517 ], [ 29.772427594936708, -1.482846551724138 ], [ 29.772697088607593, -1.482846551724138 ], [ 29.772697088607593, -1.482577068965517 ], [ 29.772427594936708, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31182, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.482577068965517 ], [ 29.772697088607593, -1.482846551724138 ], [ 29.772966582278482, -1.482846551724138 ], [ 29.772966582278482, -1.482577068965517 ], [ 29.772697088607593, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31183, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.482577068965517 ], [ 29.772966582278482, -1.482846551724138 ], [ 29.773236075949367, -1.482846551724138 ], [ 29.773236075949367, -1.482577068965517 ], [ 29.772966582278482, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31184, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.482577068965517 ], [ 29.773236075949367, -1.482846551724138 ], [ 29.773505569620252, -1.482846551724138 ], [ 29.773505569620252, -1.482577068965517 ], [ 29.773236075949367, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31185, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.482577068965517 ], [ 29.773505569620252, -1.482846551724138 ], [ 29.77377506329114, -1.482846551724138 ], [ 29.77377506329114, -1.482577068965517 ], [ 29.773505569620252, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31186, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.482577068965517 ], [ 29.77377506329114, -1.482846551724138 ], [ 29.774044556962025, -1.482846551724138 ], [ 29.774044556962025, -1.482577068965517 ], [ 29.77377506329114, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31187, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.482577068965517 ], [ 29.774044556962025, -1.482846551724138 ], [ 29.77431405063291, -1.482846551724138 ], [ 29.77431405063291, -1.482577068965517 ], [ 29.774044556962025, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31188, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.482577068965517 ], [ 29.77431405063291, -1.482846551724138 ], [ 29.774853037974683, -1.482846551724138 ], [ 29.774853037974683, -1.482577068965517 ], [ 29.77431405063291, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31189, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.482577068965517 ], [ 29.774853037974683, -1.482846551724138 ], [ 29.775122531645568, -1.482846551724138 ], [ 29.775122531645568, -1.482577068965517 ], [ 29.774853037974683, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31190, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.482577068965517 ], [ 29.775122531645568, -1.482846551724138 ], [ 29.775392025316457, -1.482846551724138 ], [ 29.775392025316457, -1.482577068965517 ], [ 29.775122531645568, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31191, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.482577068965517 ], [ 29.775392025316457, -1.482846551724138 ], [ 29.775661518987341, -1.482846551724138 ], [ 29.775661518987341, -1.482577068965517 ], [ 29.775392025316457, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31192, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.482577068965517 ], [ 29.775661518987341, -1.482846551724138 ], [ 29.775931012658226, -1.482846551724138 ], [ 29.775931012658226, -1.482577068965517 ], [ 29.775661518987341, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31193, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.482577068965517 ], [ 29.775931012658226, -1.482846551724138 ], [ 29.776200506329115, -1.482846551724138 ], [ 29.776200506329115, -1.482577068965517 ], [ 29.775931012658226, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31194, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.482577068965517 ], [ 29.776200506329115, -1.482846551724138 ], [ 29.77647, -1.482846551724138 ], [ 29.77647, -1.482577068965517 ], [ 29.776200506329115, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31195, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.482577068965517 ], [ 29.77647, -1.482846551724138 ], [ 29.776739493670885, -1.482846551724138 ], [ 29.776739493670885, -1.482577068965517 ], [ 29.77647, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31196, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.482577068965517 ], [ 29.781859873417723, -1.483385517241379 ], [ 29.782129367088608, -1.483385517241379 ], [ 29.782129367088608, -1.482577068965517 ], [ 29.781859873417723, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31197, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.482577068965517 ], [ 29.783207341772151, -1.483385517241379 ], [ 29.783476835443039, -1.483385517241379 ], [ 29.783476835443039, -1.482577068965517 ], [ 29.783207341772151, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31198, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.482577068965517 ], [ 29.784285316455698, -1.483385517241379 ], [ 29.784554810126583, -1.483385517241379 ], [ 29.784554810126583, -1.482577068965517 ], [ 29.784285316455698, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31199, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.482577068965517 ], [ 29.784554810126583, -1.483385517241379 ], [ 29.784824303797468, -1.483385517241379 ], [ 29.784824303797468, -1.482577068965517 ], [ 29.784554810126583, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31200, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.482577068965517 ], [ 29.784824303797468, -1.483385517241379 ], [ 29.785093797468356, -1.483385517241379 ], [ 29.785093797468356, -1.482577068965517 ], [ 29.784824303797468, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31201, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.482577068965517 ], [ 29.798298987341774, -1.483655 ], [ 29.798568481012659, -1.483655 ], [ 29.798568481012659, -1.482577068965517 ], [ 29.798298987341774, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31202, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.482577068965517 ], [ 29.798568481012659, -1.482846551724138 ], [ 29.798837974683543, -1.482846551724138 ], [ 29.798837974683543, -1.482577068965517 ], [ 29.798568481012659, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31203, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.482577068965517 ], [ 29.798837974683543, -1.482846551724138 ], [ 29.799107468354432, -1.482846551724138 ], [ 29.799107468354432, -1.482577068965517 ], [ 29.798837974683543, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31204, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.482577068965517 ], [ 29.799107468354432, -1.482846551724138 ], [ 29.799376962025317, -1.482846551724138 ], [ 29.799376962025317, -1.482577068965517 ], [ 29.799107468354432, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31205, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.482577068965517 ], [ 29.79991594936709, -1.483116034482759 ], [ 29.800185443037975, -1.483116034482759 ], [ 29.800185443037975, -1.482577068965517 ], [ 29.79991594936709, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31206, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.482577068965517 ], [ 29.800724430379748, -1.482846551724138 ], [ 29.800993924050633, -1.482846551724138 ], [ 29.800993924050633, -1.482577068965517 ], [ 29.800724430379748, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31207, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.482577068965517 ], [ 29.800993924050633, -1.482846551724138 ], [ 29.801263417721518, -1.482846551724138 ], [ 29.801263417721518, -1.482577068965517 ], [ 29.800993924050633, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31208, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.482577068965517 ], [ 29.801263417721518, -1.482846551724138 ], [ 29.801532911392407, -1.482846551724138 ], [ 29.801532911392407, -1.482577068965517 ], [ 29.801263417721518, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31209, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.482577068965517 ], [ 29.801532911392407, -1.482846551724138 ], [ 29.802071898734177, -1.482846551724138 ], [ 29.802071898734177, -1.482577068965517 ], [ 29.801532911392407, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31210, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.482577068965517 ], [ 29.802071898734177, -1.482846551724138 ], [ 29.802341392405065, -1.482846551724138 ], [ 29.802341392405065, -1.482577068965517 ], [ 29.802071898734177, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31211, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.482577068965517 ], [ 29.802341392405065, -1.482846551724138 ], [ 29.80261088607595, -1.482846551724138 ], [ 29.80261088607595, -1.482577068965517 ], [ 29.802341392405065, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31212, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.482577068965517 ], [ 29.80261088607595, -1.482846551724138 ], [ 29.802880379746835, -1.482846551724138 ], [ 29.802880379746835, -1.482577068965517 ], [ 29.80261088607595, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31213, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.482577068965517 ], [ 29.802880379746835, -1.482846551724138 ], [ 29.803149873417723, -1.482846551724138 ], [ 29.803149873417723, -1.482577068965517 ], [ 29.802880379746835, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31214, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.482577068965517 ], [ 29.803149873417723, -1.482846551724138 ], [ 29.803419367088608, -1.482846551724138 ], [ 29.803419367088608, -1.482577068965517 ], [ 29.803149873417723, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31215, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.482577068965517 ], [ 29.803419367088608, -1.482846551724138 ], [ 29.803688860759493, -1.482846551724138 ], [ 29.803688860759493, -1.482577068965517 ], [ 29.803419367088608, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31216, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.482577068965517 ], [ 29.803688860759493, -1.482846551724138 ], [ 29.803958354430382, -1.482846551724138 ], [ 29.803958354430382, -1.482577068965517 ], [ 29.803688860759493, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31217, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763264810126582, -1.482846551724138 ], [ 29.763264810126582, -1.483116034482759 ], [ 29.763534303797467, -1.483116034482759 ], [ 29.763534303797467, -1.482846551724138 ], [ 29.763264810126582, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31218, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.482846551724138 ], [ 29.763534303797467, -1.483116034482759 ], [ 29.763803797468356, -1.483116034482759 ], [ 29.763803797468356, -1.482846551724138 ], [ 29.763534303797467, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31219, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763803797468356, -1.482846551724138 ], [ 29.763803797468356, -1.483116034482759 ], [ 29.76407329113924, -1.483116034482759 ], [ 29.76407329113924, -1.482846551724138 ], [ 29.763803797468356, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31220, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.482846551724138 ], [ 29.76407329113924, -1.483116034482759 ], [ 29.764342784810125, -1.483116034482759 ], [ 29.764342784810125, -1.482846551724138 ], [ 29.76407329113924, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31221, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.482846551724138 ], [ 29.764342784810125, -1.483116034482759 ], [ 29.764612278481014, -1.483116034482759 ], [ 29.764612278481014, -1.482846551724138 ], [ 29.764342784810125, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31222, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.482846551724138 ], [ 29.764612278481014, -1.483116034482759 ], [ 29.764881772151899, -1.483116034482759 ], [ 29.764881772151899, -1.482846551724138 ], [ 29.764612278481014, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31223, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.482846551724138 ], [ 29.764881772151899, -1.483116034482759 ], [ 29.765151265822784, -1.483116034482759 ], [ 29.765151265822784, -1.482846551724138 ], [ 29.764881772151899, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31224, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.482846551724138 ], [ 29.765151265822784, -1.483116034482759 ], [ 29.765420759493672, -1.483116034482759 ], [ 29.765420759493672, -1.482846551724138 ], [ 29.765151265822784, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31225, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.482846551724138 ], [ 29.765420759493672, -1.483116034482759 ], [ 29.765959746835442, -1.483116034482759 ], [ 29.765959746835442, -1.482846551724138 ], [ 29.765420759493672, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31226, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.482846551724138 ], [ 29.765959746835442, -1.483116034482759 ], [ 29.766229240506327, -1.483116034482759 ], [ 29.766229240506327, -1.482846551724138 ], [ 29.765959746835442, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31227, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.482846551724138 ], [ 29.766229240506327, -1.483116034482759 ], [ 29.766498734177215, -1.483116034482759 ], [ 29.766498734177215, -1.482846551724138 ], [ 29.766229240506327, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31228, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.482846551724138 ], [ 29.766498734177215, -1.483116034482759 ], [ 29.7667682278481, -1.483116034482759 ], [ 29.7667682278481, -1.482846551724138 ], [ 29.766498734177215, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31229, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.482846551724138 ], [ 29.7667682278481, -1.483116034482759 ], [ 29.767037721518985, -1.483116034482759 ], [ 29.767037721518985, -1.482846551724138 ], [ 29.7667682278481, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31230, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.482846551724138 ], [ 29.767037721518985, -1.483116034482759 ], [ 29.767307215189874, -1.483116034482759 ], [ 29.767307215189874, -1.482846551724138 ], [ 29.767037721518985, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31231, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.482846551724138 ], [ 29.767307215189874, -1.483116034482759 ], [ 29.767576708860759, -1.483116034482759 ], [ 29.767576708860759, -1.482846551724138 ], [ 29.767307215189874, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31232, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.482846551724138 ], [ 29.767576708860759, -1.483116034482759 ], [ 29.767846202531643, -1.483116034482759 ], [ 29.767846202531643, -1.482846551724138 ], [ 29.767576708860759, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31233, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.482846551724138 ], [ 29.767846202531643, -1.483116034482759 ], [ 29.768115696202532, -1.483116034482759 ], [ 29.768115696202532, -1.482846551724138 ], [ 29.767846202531643, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31234, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.482846551724138 ], [ 29.768115696202532, -1.483116034482759 ], [ 29.768385189873417, -1.483116034482759 ], [ 29.768385189873417, -1.482846551724138 ], [ 29.768115696202532, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31235, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.482846551724138 ], [ 29.768385189873417, -1.483116034482759 ], [ 29.768654683544302, -1.483116034482759 ], [ 29.768654683544302, -1.482846551724138 ], [ 29.768385189873417, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31236, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.482846551724138 ], [ 29.768654683544302, -1.483116034482759 ], [ 29.76892417721519, -1.483116034482759 ], [ 29.76892417721519, -1.482846551724138 ], [ 29.768654683544302, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31237, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.482846551724138 ], [ 29.76892417721519, -1.483116034482759 ], [ 29.769193670886075, -1.483116034482759 ], [ 29.769193670886075, -1.482846551724138 ], [ 29.76892417721519, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31238, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.482846551724138 ], [ 29.769193670886075, -1.483116034482759 ], [ 29.76946316455696, -1.483116034482759 ], [ 29.76946316455696, -1.482846551724138 ], [ 29.769193670886075, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31239, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.482846551724138 ], [ 29.76946316455696, -1.483116034482759 ], [ 29.769732658227849, -1.483116034482759 ], [ 29.769732658227849, -1.482846551724138 ], [ 29.76946316455696, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31240, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.482846551724138 ], [ 29.769732658227849, -1.483116034482759 ], [ 29.770002151898733, -1.483116034482759 ], [ 29.770002151898733, -1.482846551724138 ], [ 29.769732658227849, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31241, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.482846551724138 ], [ 29.770002151898733, -1.483116034482759 ], [ 29.770271645569618, -1.483116034482759 ], [ 29.770271645569618, -1.482846551724138 ], [ 29.770002151898733, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31242, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.482846551724138 ], [ 29.770271645569618, -1.483116034482759 ], [ 29.770541139240507, -1.483116034482759 ], [ 29.770541139240507, -1.482846551724138 ], [ 29.770271645569618, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31243, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.482846551724138 ], [ 29.770810632911392, -1.483116034482759 ], [ 29.771080126582277, -1.483116034482759 ], [ 29.771080126582277, -1.482846551724138 ], [ 29.770810632911392, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31244, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.482846551724138 ], [ 29.771080126582277, -1.483116034482759 ], [ 29.771349620253165, -1.483116034482759 ], [ 29.771349620253165, -1.482846551724138 ], [ 29.771080126582277, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31245, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.482846551724138 ], [ 29.771349620253165, -1.483116034482759 ], [ 29.77161911392405, -1.483116034482759 ], [ 29.77161911392405, -1.482846551724138 ], [ 29.771349620253165, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31246, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.482846551724138 ], [ 29.77161911392405, -1.483116034482759 ], [ 29.771888607594935, -1.483116034482759 ], [ 29.771888607594935, -1.482846551724138 ], [ 29.77161911392405, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31247, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.482846551724138 ], [ 29.771888607594935, -1.483116034482759 ], [ 29.772158101265823, -1.483116034482759 ], [ 29.772158101265823, -1.482846551724138 ], [ 29.771888607594935, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31248, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.482846551724138 ], [ 29.772158101265823, -1.483116034482759 ], [ 29.772427594936708, -1.483116034482759 ], [ 29.772427594936708, -1.482846551724138 ], [ 29.772158101265823, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31249, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.482846551724138 ], [ 29.772427594936708, -1.483116034482759 ], [ 29.772697088607593, -1.483116034482759 ], [ 29.772697088607593, -1.482846551724138 ], [ 29.772427594936708, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31250, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.482846551724138 ], [ 29.772697088607593, -1.483116034482759 ], [ 29.772966582278482, -1.483116034482759 ], [ 29.772966582278482, -1.482846551724138 ], [ 29.772697088607593, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31251, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.482846551724138 ], [ 29.772966582278482, -1.483116034482759 ], [ 29.773236075949367, -1.483116034482759 ], [ 29.773236075949367, -1.482846551724138 ], [ 29.772966582278482, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31252, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.482846551724138 ], [ 29.773236075949367, -1.483116034482759 ], [ 29.773505569620252, -1.483116034482759 ], [ 29.773505569620252, -1.482846551724138 ], [ 29.773236075949367, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31253, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.482846551724138 ], [ 29.773505569620252, -1.483116034482759 ], [ 29.77377506329114, -1.483116034482759 ], [ 29.77377506329114, -1.482846551724138 ], [ 29.773505569620252, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31254, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.482846551724138 ], [ 29.77377506329114, -1.483116034482759 ], [ 29.774044556962025, -1.483116034482759 ], [ 29.774044556962025, -1.482846551724138 ], [ 29.77377506329114, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31255, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.482846551724138 ], [ 29.774044556962025, -1.483116034482759 ], [ 29.77431405063291, -1.483116034482759 ], [ 29.77431405063291, -1.482846551724138 ], [ 29.774044556962025, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31256, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.482846551724138 ], [ 29.77431405063291, -1.483116034482759 ], [ 29.774853037974683, -1.483116034482759 ], [ 29.774853037974683, -1.482846551724138 ], [ 29.77431405063291, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31257, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.482846551724138 ], [ 29.774853037974683, -1.483116034482759 ], [ 29.775122531645568, -1.483116034482759 ], [ 29.775122531645568, -1.482846551724138 ], [ 29.774853037974683, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31258, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.482846551724138 ], [ 29.775122531645568, -1.483116034482759 ], [ 29.775392025316457, -1.483116034482759 ], [ 29.775392025316457, -1.482846551724138 ], [ 29.775122531645568, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31259, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.482846551724138 ], [ 29.775392025316457, -1.483116034482759 ], [ 29.775661518987341, -1.483116034482759 ], [ 29.775661518987341, -1.482846551724138 ], [ 29.775392025316457, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31260, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.482846551724138 ], [ 29.775661518987341, -1.483116034482759 ], [ 29.775931012658226, -1.483116034482759 ], [ 29.775931012658226, -1.482846551724138 ], [ 29.775661518987341, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31261, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.482846551724138 ], [ 29.775931012658226, -1.483116034482759 ], [ 29.776200506329115, -1.483116034482759 ], [ 29.776200506329115, -1.482846551724138 ], [ 29.775931012658226, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31262, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.482846551724138 ], [ 29.776200506329115, -1.483116034482759 ], [ 29.77647, -1.483116034482759 ], [ 29.77647, -1.482846551724138 ], [ 29.776200506329115, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31263, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.482846551724138 ], [ 29.77647, -1.483655 ], [ 29.776739493670885, -1.483655 ], [ 29.776739493670885, -1.482846551724138 ], [ 29.77647, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31264, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.482577068965517 ], [ 29.787788734177216, -1.482577068965517 ], [ 29.787788734177216, -1.483655 ], [ 29.787519240506327, -1.483655 ], [ 29.787519240506327, -1.483116034482759 ], [ 29.786980253164558, -1.483116034482759 ], [ 29.786980253164558, -1.482846551724138 ], [ 29.787519240506327, -1.482846551724138 ], [ 29.787519240506327, -1.482577068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31265, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.482846551724138 ], [ 29.798568481012659, -1.483655 ], [ 29.798837974683543, -1.483655 ], [ 29.798837974683543, -1.482846551724138 ], [ 29.798568481012659, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31266, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.482846551724138 ], [ 29.798837974683543, -1.483655 ], [ 29.799107468354432, -1.483655 ], [ 29.799107468354432, -1.482846551724138 ], [ 29.798837974683543, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31267, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.482846551724138 ], [ 29.799107468354432, -1.483116034482759 ], [ 29.799376962025317, -1.483116034482759 ], [ 29.799376962025317, -1.482846551724138 ], [ 29.799107468354432, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31268, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.482846551724138 ], [ 29.799376962025317, -1.483116034482759 ], [ 29.799646455696202, -1.483116034482759 ], [ 29.799646455696202, -1.482846551724138 ], [ 29.799376962025317, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31269, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.482846551724138 ], [ 29.799646455696202, -1.483116034482759 ], [ 29.79991594936709, -1.483116034482759 ], [ 29.79991594936709, -1.482846551724138 ], [ 29.799646455696202, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31270, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.482846551724138 ], [ 29.800185443037975, -1.483116034482759 ], [ 29.80045493670886, -1.483116034482759 ], [ 29.80045493670886, -1.482846551724138 ], [ 29.800185443037975, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31271, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.482846551724138 ], [ 29.80045493670886, -1.483116034482759 ], [ 29.800724430379748, -1.483116034482759 ], [ 29.800724430379748, -1.482846551724138 ], [ 29.80045493670886, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31272, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.482846551724138 ], [ 29.800724430379748, -1.483116034482759 ], [ 29.800993924050633, -1.483116034482759 ], [ 29.800993924050633, -1.482846551724138 ], [ 29.800724430379748, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31273, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.482846551724138 ], [ 29.800993924050633, -1.483116034482759 ], [ 29.801263417721518, -1.483116034482759 ], [ 29.801263417721518, -1.482846551724138 ], [ 29.800993924050633, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31274, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.482846551724138 ], [ 29.801263417721518, -1.483116034482759 ], [ 29.801532911392407, -1.483116034482759 ], [ 29.801532911392407, -1.482846551724138 ], [ 29.801263417721518, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31275, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.482846551724138 ], [ 29.801532911392407, -1.483116034482759 ], [ 29.802071898734177, -1.483116034482759 ], [ 29.802071898734177, -1.482846551724138 ], [ 29.801532911392407, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31276, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.482846551724138 ], [ 29.802071898734177, -1.483116034482759 ], [ 29.802341392405065, -1.483116034482759 ], [ 29.802341392405065, -1.482846551724138 ], [ 29.802071898734177, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31277, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.482846551724138 ], [ 29.802341392405065, -1.483116034482759 ], [ 29.80261088607595, -1.483116034482759 ], [ 29.80261088607595, -1.482846551724138 ], [ 29.802341392405065, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31278, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.482846551724138 ], [ 29.80261088607595, -1.483116034482759 ], [ 29.802880379746835, -1.483116034482759 ], [ 29.802880379746835, -1.482846551724138 ], [ 29.80261088607595, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31279, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.482846551724138 ], [ 29.802880379746835, -1.483116034482759 ], [ 29.803149873417723, -1.483116034482759 ], [ 29.803149873417723, -1.482846551724138 ], [ 29.802880379746835, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31280, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.482846551724138 ], [ 29.803149873417723, -1.483116034482759 ], [ 29.803419367088608, -1.483116034482759 ], [ 29.803419367088608, -1.482846551724138 ], [ 29.803149873417723, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31281, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.482846551724138 ], [ 29.803419367088608, -1.483116034482759 ], [ 29.803688860759493, -1.483116034482759 ], [ 29.803688860759493, -1.482846551724138 ], [ 29.803419367088608, -1.482846551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31282, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.763534303797467, -1.483116034482759 ], [ 29.763534303797467, -1.483385517241379 ], [ 29.76407329113924, -1.483385517241379 ], [ 29.76407329113924, -1.483116034482759 ], [ 29.763534303797467, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31283, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.483116034482759 ], [ 29.76407329113924, -1.483385517241379 ], [ 29.764342784810125, -1.483385517241379 ], [ 29.764342784810125, -1.483116034482759 ], [ 29.76407329113924, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31284, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.483116034482759 ], [ 29.764342784810125, -1.483385517241379 ], [ 29.764612278481014, -1.483385517241379 ], [ 29.764612278481014, -1.483116034482759 ], [ 29.764342784810125, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31285, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.483116034482759 ], [ 29.764612278481014, -1.483385517241379 ], [ 29.764881772151899, -1.483385517241379 ], [ 29.764881772151899, -1.483116034482759 ], [ 29.764612278481014, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31286, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.483116034482759 ], [ 29.764881772151899, -1.483385517241379 ], [ 29.765151265822784, -1.483385517241379 ], [ 29.765151265822784, -1.483116034482759 ], [ 29.764881772151899, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31287, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.483116034482759 ], [ 29.765151265822784, -1.483385517241379 ], [ 29.765420759493672, -1.483385517241379 ], [ 29.765420759493672, -1.483116034482759 ], [ 29.765151265822784, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31288, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.483116034482759 ], [ 29.765420759493672, -1.483385517241379 ], [ 29.765959746835442, -1.483385517241379 ], [ 29.765959746835442, -1.483116034482759 ], [ 29.765420759493672, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31289, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.483116034482759 ], [ 29.765959746835442, -1.483385517241379 ], [ 29.766229240506327, -1.483385517241379 ], [ 29.766229240506327, -1.483116034482759 ], [ 29.765959746835442, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31290, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.483116034482759 ], [ 29.766229240506327, -1.483385517241379 ], [ 29.766498734177215, -1.483385517241379 ], [ 29.766498734177215, -1.483116034482759 ], [ 29.766229240506327, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31291, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.483116034482759 ], [ 29.766498734177215, -1.483385517241379 ], [ 29.7667682278481, -1.483385517241379 ], [ 29.7667682278481, -1.483116034482759 ], [ 29.766498734177215, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31292, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.483116034482759 ], [ 29.7667682278481, -1.483385517241379 ], [ 29.767037721518985, -1.483385517241379 ], [ 29.767037721518985, -1.483116034482759 ], [ 29.7667682278481, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31293, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.483116034482759 ], [ 29.767037721518985, -1.483385517241379 ], [ 29.767307215189874, -1.483385517241379 ], [ 29.767307215189874, -1.483116034482759 ], [ 29.767037721518985, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31294, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.483116034482759 ], [ 29.767307215189874, -1.483385517241379 ], [ 29.767576708860759, -1.483385517241379 ], [ 29.767576708860759, -1.483116034482759 ], [ 29.767307215189874, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31295, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.483116034482759 ], [ 29.767576708860759, -1.483385517241379 ], [ 29.767846202531643, -1.483385517241379 ], [ 29.767846202531643, -1.483116034482759 ], [ 29.767576708860759, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31296, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.483116034482759 ], [ 29.767846202531643, -1.483385517241379 ], [ 29.768115696202532, -1.483385517241379 ], [ 29.768115696202532, -1.483116034482759 ], [ 29.767846202531643, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31297, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.483116034482759 ], [ 29.768115696202532, -1.483385517241379 ], [ 29.768385189873417, -1.483385517241379 ], [ 29.768385189873417, -1.483116034482759 ], [ 29.768115696202532, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31298, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.483116034482759 ], [ 29.768385189873417, -1.483385517241379 ], [ 29.768654683544302, -1.483385517241379 ], [ 29.768654683544302, -1.483116034482759 ], [ 29.768385189873417, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31299, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.483116034482759 ], [ 29.768654683544302, -1.483385517241379 ], [ 29.76892417721519, -1.483385517241379 ], [ 29.76892417721519, -1.483116034482759 ], [ 29.768654683544302, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31300, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.483116034482759 ], [ 29.76892417721519, -1.483385517241379 ], [ 29.769193670886075, -1.483385517241379 ], [ 29.769193670886075, -1.483116034482759 ], [ 29.76892417721519, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31301, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.483116034482759 ], [ 29.769193670886075, -1.483385517241379 ], [ 29.76946316455696, -1.483385517241379 ], [ 29.76946316455696, -1.483116034482759 ], [ 29.769193670886075, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31302, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.483116034482759 ], [ 29.76946316455696, -1.483385517241379 ], [ 29.769732658227849, -1.483385517241379 ], [ 29.769732658227849, -1.483116034482759 ], [ 29.76946316455696, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31303, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.483116034482759 ], [ 29.769732658227849, -1.483385517241379 ], [ 29.770002151898733, -1.483385517241379 ], [ 29.770002151898733, -1.483116034482759 ], [ 29.769732658227849, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31304, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.483116034482759 ], [ 29.770002151898733, -1.483385517241379 ], [ 29.770271645569618, -1.483385517241379 ], [ 29.770271645569618, -1.483116034482759 ], [ 29.770002151898733, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31305, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.483116034482759 ], [ 29.770271645569618, -1.483385517241379 ], [ 29.770541139240507, -1.483385517241379 ], [ 29.770541139240507, -1.483116034482759 ], [ 29.770271645569618, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31306, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.483116034482759 ], [ 29.770541139240507, -1.483385517241379 ], [ 29.770810632911392, -1.483385517241379 ], [ 29.770810632911392, -1.483116034482759 ], [ 29.770541139240507, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31307, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.483116034482759 ], [ 29.770810632911392, -1.483385517241379 ], [ 29.771080126582277, -1.483385517241379 ], [ 29.771080126582277, -1.483116034482759 ], [ 29.770810632911392, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31308, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.483116034482759 ], [ 29.771080126582277, -1.483385517241379 ], [ 29.771349620253165, -1.483385517241379 ], [ 29.771349620253165, -1.483116034482759 ], [ 29.771080126582277, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31309, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.483116034482759 ], [ 29.771349620253165, -1.483385517241379 ], [ 29.77161911392405, -1.483385517241379 ], [ 29.77161911392405, -1.483116034482759 ], [ 29.771349620253165, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31310, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.483116034482759 ], [ 29.77161911392405, -1.483385517241379 ], [ 29.771888607594935, -1.483385517241379 ], [ 29.771888607594935, -1.483116034482759 ], [ 29.77161911392405, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31311, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.483116034482759 ], [ 29.771888607594935, -1.483655 ], [ 29.772158101265823, -1.483655 ], [ 29.772158101265823, -1.483116034482759 ], [ 29.771888607594935, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31312, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.483116034482759 ], [ 29.772158101265823, -1.483385517241379 ], [ 29.772427594936708, -1.483385517241379 ], [ 29.772427594936708, -1.483116034482759 ], [ 29.772158101265823, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31313, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.483116034482759 ], [ 29.772427594936708, -1.483385517241379 ], [ 29.772697088607593, -1.483385517241379 ], [ 29.772697088607593, -1.483116034482759 ], [ 29.772427594936708, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31314, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.483116034482759 ], [ 29.772697088607593, -1.483385517241379 ], [ 29.772966582278482, -1.483385517241379 ], [ 29.772966582278482, -1.483116034482759 ], [ 29.772697088607593, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31315, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.483116034482759 ], [ 29.772966582278482, -1.483385517241379 ], [ 29.773236075949367, -1.483385517241379 ], [ 29.773236075949367, -1.483116034482759 ], [ 29.772966582278482, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31316, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.483116034482759 ], [ 29.773236075949367, -1.483385517241379 ], [ 29.773505569620252, -1.483385517241379 ], [ 29.773505569620252, -1.483116034482759 ], [ 29.773236075949367, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31317, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.483116034482759 ], [ 29.773505569620252, -1.483385517241379 ], [ 29.77377506329114, -1.483385517241379 ], [ 29.77377506329114, -1.483116034482759 ], [ 29.773505569620252, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31318, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.483116034482759 ], [ 29.77377506329114, -1.483385517241379 ], [ 29.774044556962025, -1.483385517241379 ], [ 29.774044556962025, -1.483116034482759 ], [ 29.77377506329114, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31319, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.483116034482759 ], [ 29.774044556962025, -1.483385517241379 ], [ 29.77431405063291, -1.483385517241379 ], [ 29.77431405063291, -1.483116034482759 ], [ 29.774044556962025, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31320, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.483116034482759 ], [ 29.77431405063291, -1.483385517241379 ], [ 29.774853037974683, -1.483385517241379 ], [ 29.774853037974683, -1.483116034482759 ], [ 29.77431405063291, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31321, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.483116034482759 ], [ 29.774853037974683, -1.483385517241379 ], [ 29.775122531645568, -1.483385517241379 ], [ 29.775122531645568, -1.483116034482759 ], [ 29.774853037974683, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31322, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.483116034482759 ], [ 29.775122531645568, -1.483385517241379 ], [ 29.775392025316457, -1.483385517241379 ], [ 29.775392025316457, -1.483116034482759 ], [ 29.775122531645568, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31323, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.483116034482759 ], [ 29.775392025316457, -1.483385517241379 ], [ 29.775661518987341, -1.483385517241379 ], [ 29.775661518987341, -1.483116034482759 ], [ 29.775392025316457, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31324, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.483116034482759 ], [ 29.775661518987341, -1.483385517241379 ], [ 29.775931012658226, -1.483385517241379 ], [ 29.775931012658226, -1.483116034482759 ], [ 29.775661518987341, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31325, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.483116034482759 ], [ 29.775931012658226, -1.483385517241379 ], [ 29.776200506329115, -1.483385517241379 ], [ 29.776200506329115, -1.483116034482759 ], [ 29.775931012658226, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31326, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.483116034482759 ], [ 29.776200506329115, -1.483385517241379 ], [ 29.77647, -1.483385517241379 ], [ 29.77647, -1.483116034482759 ], [ 29.776200506329115, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31327, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.483116034482759 ], [ 29.786441265822784, -1.483385517241379 ], [ 29.786980253164558, -1.483385517241379 ], [ 29.786980253164558, -1.483116034482759 ], [ 29.786441265822784, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31328, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.483116034482759 ], [ 29.786980253164558, -1.483385517241379 ], [ 29.787249746835442, -1.483385517241379 ], [ 29.787249746835442, -1.483116034482759 ], [ 29.786980253164558, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31329, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.483116034482759 ], [ 29.787249746835442, -1.483655 ], [ 29.787519240506327, -1.483655 ], [ 29.787519240506327, -1.483116034482759 ], [ 29.787249746835442, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31330, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.483116034482759 ], [ 29.799107468354432, -1.483655 ], [ 29.799376962025317, -1.483655 ], [ 29.799376962025317, -1.483116034482759 ], [ 29.799107468354432, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31331, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.483116034482759 ], [ 29.799376962025317, -1.483655 ], [ 29.799646455696202, -1.483655 ], [ 29.799646455696202, -1.483116034482759 ], [ 29.799376962025317, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31332, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.483116034482759 ], [ 29.799646455696202, -1.483385517241379 ], [ 29.79991594936709, -1.483385517241379 ], [ 29.79991594936709, -1.483116034482759 ], [ 29.799646455696202, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31333, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.483116034482759 ], [ 29.79991594936709, -1.483385517241379 ], [ 29.800185443037975, -1.483385517241379 ], [ 29.800185443037975, -1.483116034482759 ], [ 29.79991594936709, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31334, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.483116034482759 ], [ 29.800185443037975, -1.483385517241379 ], [ 29.80045493670886, -1.483385517241379 ], [ 29.80045493670886, -1.483116034482759 ], [ 29.800185443037975, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31335, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.483116034482759 ], [ 29.80045493670886, -1.483385517241379 ], [ 29.800724430379748, -1.483385517241379 ], [ 29.800724430379748, -1.483116034482759 ], [ 29.80045493670886, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31336, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.483116034482759 ], [ 29.800724430379748, -1.483385517241379 ], [ 29.800993924050633, -1.483385517241379 ], [ 29.800993924050633, -1.483116034482759 ], [ 29.800724430379748, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31337, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.483116034482759 ], [ 29.800993924050633, -1.483385517241379 ], [ 29.801263417721518, -1.483385517241379 ], [ 29.801263417721518, -1.483116034482759 ], [ 29.800993924050633, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31338, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.483116034482759 ], [ 29.801263417721518, -1.483655 ], [ 29.801532911392407, -1.483655 ], [ 29.801532911392407, -1.483116034482759 ], [ 29.801263417721518, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31339, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.483116034482759 ], [ 29.801532911392407, -1.483385517241379 ], [ 29.802071898734177, -1.483385517241379 ], [ 29.802071898734177, -1.483116034482759 ], [ 29.801532911392407, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31340, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.483116034482759 ], [ 29.802071898734177, -1.483385517241379 ], [ 29.802341392405065, -1.483385517241379 ], [ 29.802341392405065, -1.483116034482759 ], [ 29.802071898734177, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31341, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.483116034482759 ], [ 29.802341392405065, -1.483385517241379 ], [ 29.80261088607595, -1.483385517241379 ], [ 29.80261088607595, -1.483116034482759 ], [ 29.802341392405065, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31342, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.483116034482759 ], [ 29.80261088607595, -1.483385517241379 ], [ 29.802880379746835, -1.483385517241379 ], [ 29.802880379746835, -1.483116034482759 ], [ 29.80261088607595, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31343, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.483116034482759 ], [ 29.802880379746835, -1.483385517241379 ], [ 29.803149873417723, -1.483385517241379 ], [ 29.803149873417723, -1.483116034482759 ], [ 29.802880379746835, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31344, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.483116034482759 ], [ 29.803149873417723, -1.483655 ], [ 29.803419367088608, -1.483655 ], [ 29.803419367088608, -1.483116034482759 ], [ 29.803149873417723, -1.483116034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31345, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76407329113924, -1.483385517241379 ], [ 29.76407329113924, -1.483655 ], [ 29.764342784810125, -1.483655 ], [ 29.764342784810125, -1.483385517241379 ], [ 29.76407329113924, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31346, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.483385517241379 ], [ 29.764342784810125, -1.483655 ], [ 29.764612278481014, -1.483655 ], [ 29.764612278481014, -1.483385517241379 ], [ 29.764342784810125, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31347, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.483385517241379 ], [ 29.764612278481014, -1.483655 ], [ 29.764881772151899, -1.483655 ], [ 29.764881772151899, -1.483385517241379 ], [ 29.764612278481014, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31348, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.483385517241379 ], [ 29.764881772151899, -1.483655 ], [ 29.765151265822784, -1.483655 ], [ 29.765151265822784, -1.483385517241379 ], [ 29.764881772151899, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31349, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.483385517241379 ], [ 29.765151265822784, -1.483655 ], [ 29.765420759493672, -1.483655 ], [ 29.765420759493672, -1.483385517241379 ], [ 29.765151265822784, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31350, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.483385517241379 ], [ 29.765420759493672, -1.483655 ], [ 29.765959746835442, -1.483655 ], [ 29.765959746835442, -1.483385517241379 ], [ 29.765420759493672, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31351, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.483385517241379 ], [ 29.765959746835442, -1.483655 ], [ 29.766229240506327, -1.483655 ], [ 29.766229240506327, -1.483385517241379 ], [ 29.765959746835442, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31352, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.483385517241379 ], [ 29.766229240506327, -1.483655 ], [ 29.766498734177215, -1.483655 ], [ 29.766498734177215, -1.483385517241379 ], [ 29.766229240506327, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31353, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.483385517241379 ], [ 29.766498734177215, -1.483655 ], [ 29.7667682278481, -1.483655 ], [ 29.7667682278481, -1.483385517241379 ], [ 29.766498734177215, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31354, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.483385517241379 ], [ 29.7667682278481, -1.483655 ], [ 29.767037721518985, -1.483655 ], [ 29.767037721518985, -1.483385517241379 ], [ 29.7667682278481, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31355, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.483385517241379 ], [ 29.767037721518985, -1.483655 ], [ 29.767307215189874, -1.483655 ], [ 29.767307215189874, -1.483385517241379 ], [ 29.767037721518985, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31356, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.483385517241379 ], [ 29.767307215189874, -1.483655 ], [ 29.767576708860759, -1.483655 ], [ 29.767576708860759, -1.483385517241379 ], [ 29.767307215189874, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31357, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.483385517241379 ], [ 29.767576708860759, -1.483655 ], [ 29.767846202531643, -1.483655 ], [ 29.767846202531643, -1.483385517241379 ], [ 29.767576708860759, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31358, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.483385517241379 ], [ 29.767846202531643, -1.483655 ], [ 29.768115696202532, -1.483655 ], [ 29.768115696202532, -1.483385517241379 ], [ 29.767846202531643, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31359, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.483385517241379 ], [ 29.768115696202532, -1.483655 ], [ 29.768385189873417, -1.483655 ], [ 29.768385189873417, -1.483385517241379 ], [ 29.768115696202532, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31360, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.483385517241379 ], [ 29.768385189873417, -1.483655 ], [ 29.768654683544302, -1.483655 ], [ 29.768654683544302, -1.483385517241379 ], [ 29.768385189873417, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31361, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.483385517241379 ], [ 29.768654683544302, -1.483655 ], [ 29.76892417721519, -1.483655 ], [ 29.76892417721519, -1.483385517241379 ], [ 29.768654683544302, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31362, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.483385517241379 ], [ 29.76892417721519, -1.483655 ], [ 29.769193670886075, -1.483655 ], [ 29.769193670886075, -1.483385517241379 ], [ 29.76892417721519, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31363, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.483385517241379 ], [ 29.769193670886075, -1.483655 ], [ 29.76946316455696, -1.483655 ], [ 29.76946316455696, -1.483385517241379 ], [ 29.769193670886075, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31364, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.483385517241379 ], [ 29.76946316455696, -1.483655 ], [ 29.769732658227849, -1.483655 ], [ 29.769732658227849, -1.483385517241379 ], [ 29.76946316455696, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31365, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.483385517241379 ], [ 29.769732658227849, -1.483655 ], [ 29.770002151898733, -1.483655 ], [ 29.770002151898733, -1.483385517241379 ], [ 29.769732658227849, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31366, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.483385517241379 ], [ 29.770002151898733, -1.483655 ], [ 29.770271645569618, -1.483655 ], [ 29.770271645569618, -1.483385517241379 ], [ 29.770002151898733, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31367, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.483385517241379 ], [ 29.770271645569618, -1.483655 ], [ 29.770541139240507, -1.483655 ], [ 29.770541139240507, -1.483385517241379 ], [ 29.770271645569618, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31368, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.483385517241379 ], [ 29.770541139240507, -1.483655 ], [ 29.770810632911392, -1.483655 ], [ 29.770810632911392, -1.483385517241379 ], [ 29.770541139240507, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31369, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.483385517241379 ], [ 29.770810632911392, -1.483655 ], [ 29.771080126582277, -1.483655 ], [ 29.771080126582277, -1.483385517241379 ], [ 29.770810632911392, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31370, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.483385517241379 ], [ 29.771080126582277, -1.483655 ], [ 29.771349620253165, -1.483655 ], [ 29.771349620253165, -1.483385517241379 ], [ 29.771080126582277, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31371, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.483385517241379 ], [ 29.771349620253165, -1.483655 ], [ 29.77161911392405, -1.483655 ], [ 29.77161911392405, -1.483385517241379 ], [ 29.771349620253165, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31372, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.483385517241379 ], [ 29.77161911392405, -1.483655 ], [ 29.771888607594935, -1.483655 ], [ 29.771888607594935, -1.483385517241379 ], [ 29.77161911392405, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31373, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.483385517241379 ], [ 29.772158101265823, -1.483655 ], [ 29.772427594936708, -1.483655 ], [ 29.772427594936708, -1.483385517241379 ], [ 29.772158101265823, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31374, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.483385517241379 ], [ 29.772427594936708, -1.483655 ], [ 29.772697088607593, -1.483655 ], [ 29.772697088607593, -1.483385517241379 ], [ 29.772427594936708, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31375, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.483385517241379 ], [ 29.772697088607593, -1.483924482758621 ], [ 29.772966582278482, -1.483924482758621 ], [ 29.772966582278482, -1.483385517241379 ], [ 29.772697088607593, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31376, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.483385517241379 ], [ 29.772966582278482, -1.483655 ], [ 29.773236075949367, -1.483655 ], [ 29.773236075949367, -1.483385517241379 ], [ 29.772966582278482, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31377, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.483385517241379 ], [ 29.773236075949367, -1.483655 ], [ 29.773505569620252, -1.483655 ], [ 29.773505569620252, -1.483385517241379 ], [ 29.773236075949367, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31378, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.483385517241379 ], [ 29.773505569620252, -1.483655 ], [ 29.77377506329114, -1.483655 ], [ 29.77377506329114, -1.483385517241379 ], [ 29.773505569620252, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31379, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.483385517241379 ], [ 29.77377506329114, -1.483655 ], [ 29.774044556962025, -1.483655 ], [ 29.774044556962025, -1.483385517241379 ], [ 29.77377506329114, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31380, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.483385517241379 ], [ 29.774044556962025, -1.483655 ], [ 29.77431405063291, -1.483655 ], [ 29.77431405063291, -1.483385517241379 ], [ 29.774044556962025, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31381, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.483385517241379 ], [ 29.77431405063291, -1.483655 ], [ 29.774853037974683, -1.483655 ], [ 29.774853037974683, -1.483385517241379 ], [ 29.77431405063291, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31382, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.483385517241379 ], [ 29.774853037974683, -1.483655 ], [ 29.775122531645568, -1.483655 ], [ 29.775122531645568, -1.483385517241379 ], [ 29.774853037974683, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31383, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.483385517241379 ], [ 29.775122531645568, -1.483655 ], [ 29.775392025316457, -1.483655 ], [ 29.775392025316457, -1.483385517241379 ], [ 29.775122531645568, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31384, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.483385517241379 ], [ 29.775392025316457, -1.483655 ], [ 29.775661518987341, -1.483655 ], [ 29.775661518987341, -1.483385517241379 ], [ 29.775392025316457, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31385, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.483385517241379 ], [ 29.775661518987341, -1.483655 ], [ 29.775931012658226, -1.483655 ], [ 29.775931012658226, -1.483385517241379 ], [ 29.775661518987341, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31386, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.483385517241379 ], [ 29.775931012658226, -1.483655 ], [ 29.776200506329115, -1.483655 ], [ 29.776200506329115, -1.483385517241379 ], [ 29.775931012658226, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31387, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.483385517241379 ], [ 29.776200506329115, -1.483655 ], [ 29.77647, -1.483655 ], [ 29.77647, -1.483385517241379 ], [ 29.776200506329115, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31388, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.483385517241379 ], [ 29.781859873417723, -1.483924482758621 ], [ 29.782129367088608, -1.483924482758621 ], [ 29.782129367088608, -1.483385517241379 ], [ 29.781859873417723, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31389, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.483385517241379 ], [ 29.783207341772151, -1.483924482758621 ], [ 29.783476835443039, -1.483924482758621 ], [ 29.783476835443039, -1.483385517241379 ], [ 29.783207341772151, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31390, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.483385517241379 ], [ 29.784285316455698, -1.483655 ], [ 29.784554810126583, -1.483655 ], [ 29.784554810126583, -1.483385517241379 ], [ 29.784285316455698, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31391, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.483385517241379 ], [ 29.784554810126583, -1.483655 ], [ 29.784824303797468, -1.483655 ], [ 29.784824303797468, -1.483385517241379 ], [ 29.784554810126583, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31392, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.483385517241379 ], [ 29.784824303797468, -1.483655 ], [ 29.785093797468356, -1.483655 ], [ 29.785093797468356, -1.483385517241379 ], [ 29.784824303797468, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31393, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.483385517241379 ], [ 29.785093797468356, -1.484193965517242 ], [ 29.785363291139241, -1.484193965517242 ], [ 29.785363291139241, -1.483385517241379 ], [ 29.785093797468356, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31394, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.483385517241379 ], [ 29.785902278481014, -1.483655 ], [ 29.786441265822784, -1.483655 ], [ 29.786441265822784, -1.483385517241379 ], [ 29.785902278481014, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31395, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.483385517241379 ], [ 29.786441265822784, -1.483655 ], [ 29.786710759493673, -1.483655 ], [ 29.786710759493673, -1.483385517241379 ], [ 29.786441265822784, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31396, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.483385517241379 ], [ 29.786710759493673, -1.483655 ], [ 29.786980253164558, -1.483655 ], [ 29.786980253164558, -1.483385517241379 ], [ 29.786710759493673, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31397, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.483385517241379 ], [ 29.786980253164558, -1.483655 ], [ 29.787249746835442, -1.483655 ], [ 29.787249746835442, -1.483385517241379 ], [ 29.786980253164558, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31398, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.483385517241379 ], [ 29.799646455696202, -1.483655 ], [ 29.79991594936709, -1.483655 ], [ 29.79991594936709, -1.483385517241379 ], [ 29.799646455696202, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31399, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.483385517241379 ], [ 29.79991594936709, -1.483655 ], [ 29.800185443037975, -1.483655 ], [ 29.800185443037975, -1.483385517241379 ], [ 29.79991594936709, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31400, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.483385517241379 ], [ 29.800185443037975, -1.483655 ], [ 29.80045493670886, -1.483655 ], [ 29.80045493670886, -1.483385517241379 ], [ 29.800185443037975, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31401, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.483385517241379 ], [ 29.80045493670886, -1.483655 ], [ 29.800724430379748, -1.483655 ], [ 29.800724430379748, -1.483924482758621 ], [ 29.800993924050633, -1.483924482758621 ], [ 29.800993924050633, -1.483385517241379 ], [ 29.80045493670886, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31402, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.483385517241379 ], [ 29.800993924050633, -1.484193965517242 ], [ 29.801263417721518, -1.484193965517242 ], [ 29.801263417721518, -1.483385517241379 ], [ 29.800993924050633, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31403, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.483385517241379 ], [ 29.801532911392407, -1.484193965517242 ], [ 29.802071898734177, -1.484193965517242 ], [ 29.802071898734177, -1.483385517241379 ], [ 29.801532911392407, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31404, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.483385517241379 ], [ 29.802071898734177, -1.484193965517242 ], [ 29.802341392405065, -1.484193965517242 ], [ 29.802341392405065, -1.483385517241379 ], [ 29.802071898734177, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31405, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.483385517241379 ], [ 29.802341392405065, -1.483655 ], [ 29.80261088607595, -1.483655 ], [ 29.80261088607595, -1.483385517241379 ], [ 29.802341392405065, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31406, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.483385517241379 ], [ 29.80261088607595, -1.483655 ], [ 29.802880379746835, -1.483655 ], [ 29.802880379746835, -1.483385517241379 ], [ 29.80261088607595, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31407, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.483385517241379 ], [ 29.802880379746835, -1.483655 ], [ 29.803149873417723, -1.483655 ], [ 29.803149873417723, -1.483385517241379 ], [ 29.802880379746835, -1.483385517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31408, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764342784810125, -1.483655 ], [ 29.764342784810125, -1.483924482758621 ], [ 29.764612278481014, -1.483924482758621 ], [ 29.764612278481014, -1.483655 ], [ 29.764342784810125, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31409, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.483655 ], [ 29.764612278481014, -1.483924482758621 ], [ 29.764881772151899, -1.483924482758621 ], [ 29.764881772151899, -1.483655 ], [ 29.764612278481014, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31410, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.483655 ], [ 29.764881772151899, -1.483924482758621 ], [ 29.765151265822784, -1.483924482758621 ], [ 29.765151265822784, -1.483655 ], [ 29.764881772151899, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31411, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.483655 ], [ 29.765151265822784, -1.483924482758621 ], [ 29.765420759493672, -1.483924482758621 ], [ 29.765420759493672, -1.483655 ], [ 29.765151265822784, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31412, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.483655 ], [ 29.765420759493672, -1.483924482758621 ], [ 29.765959746835442, -1.483924482758621 ], [ 29.765959746835442, -1.483655 ], [ 29.765420759493672, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31413, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.483655 ], [ 29.765959746835442, -1.483924482758621 ], [ 29.766229240506327, -1.483924482758621 ], [ 29.766229240506327, -1.483655 ], [ 29.765959746835442, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31414, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.483655 ], [ 29.766229240506327, -1.483924482758621 ], [ 29.766498734177215, -1.483924482758621 ], [ 29.766498734177215, -1.483655 ], [ 29.766229240506327, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31415, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.483655 ], [ 29.766498734177215, -1.483924482758621 ], [ 29.7667682278481, -1.483924482758621 ], [ 29.7667682278481, -1.483655 ], [ 29.766498734177215, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31416, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.483655 ], [ 29.7667682278481, -1.483924482758621 ], [ 29.767037721518985, -1.483924482758621 ], [ 29.767037721518985, -1.483655 ], [ 29.7667682278481, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31417, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.483655 ], [ 29.767037721518985, -1.483924482758621 ], [ 29.767307215189874, -1.483924482758621 ], [ 29.767307215189874, -1.483655 ], [ 29.767037721518985, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31418, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.483655 ], [ 29.767307215189874, -1.483924482758621 ], [ 29.767576708860759, -1.483924482758621 ], [ 29.767576708860759, -1.483655 ], [ 29.767307215189874, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31419, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.483655 ], [ 29.767576708860759, -1.483924482758621 ], [ 29.767846202531643, -1.483924482758621 ], [ 29.767846202531643, -1.483655 ], [ 29.767576708860759, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31420, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.483655 ], [ 29.767846202531643, -1.483924482758621 ], [ 29.768115696202532, -1.483924482758621 ], [ 29.768115696202532, -1.483655 ], [ 29.767846202531643, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31421, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.483655 ], [ 29.768115696202532, -1.483924482758621 ], [ 29.768385189873417, -1.483924482758621 ], [ 29.768385189873417, -1.483655 ], [ 29.768115696202532, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31422, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.483655 ], [ 29.768385189873417, -1.483924482758621 ], [ 29.768654683544302, -1.483924482758621 ], [ 29.768654683544302, -1.483655 ], [ 29.768385189873417, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31423, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.483655 ], [ 29.768654683544302, -1.483924482758621 ], [ 29.76892417721519, -1.483924482758621 ], [ 29.76892417721519, -1.483655 ], [ 29.768654683544302, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31424, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.483655 ], [ 29.76892417721519, -1.483924482758621 ], [ 29.769193670886075, -1.483924482758621 ], [ 29.769193670886075, -1.483655 ], [ 29.76892417721519, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31425, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.483655 ], [ 29.769193670886075, -1.483924482758621 ], [ 29.76946316455696, -1.483924482758621 ], [ 29.76946316455696, -1.483655 ], [ 29.769193670886075, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31426, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.483655 ], [ 29.76946316455696, -1.483924482758621 ], [ 29.769732658227849, -1.483924482758621 ], [ 29.769732658227849, -1.483655 ], [ 29.76946316455696, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31427, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.483655 ], [ 29.769732658227849, -1.483924482758621 ], [ 29.770002151898733, -1.483924482758621 ], [ 29.770002151898733, -1.483655 ], [ 29.769732658227849, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31428, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.483655 ], [ 29.770002151898733, -1.483924482758621 ], [ 29.770271645569618, -1.483924482758621 ], [ 29.770271645569618, -1.483655 ], [ 29.770002151898733, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31429, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.483655 ], [ 29.770271645569618, -1.483924482758621 ], [ 29.770541139240507, -1.483924482758621 ], [ 29.770541139240507, -1.483655 ], [ 29.770271645569618, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31430, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.483655 ], [ 29.770541139240507, -1.483924482758621 ], [ 29.770810632911392, -1.483924482758621 ], [ 29.770810632911392, -1.483655 ], [ 29.770541139240507, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31431, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.483655 ], [ 29.770810632911392, -1.483924482758621 ], [ 29.771080126582277, -1.483924482758621 ], [ 29.771080126582277, -1.483655 ], [ 29.770810632911392, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31432, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.483655 ], [ 29.771080126582277, -1.483924482758621 ], [ 29.771349620253165, -1.483924482758621 ], [ 29.771349620253165, -1.483655 ], [ 29.771080126582277, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31433, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.483655 ], [ 29.771349620253165, -1.483924482758621 ], [ 29.77161911392405, -1.483924482758621 ], [ 29.77161911392405, -1.483655 ], [ 29.771349620253165, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31434, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.483655 ], [ 29.77161911392405, -1.483924482758621 ], [ 29.771888607594935, -1.483924482758621 ], [ 29.771888607594935, -1.483655 ], [ 29.77161911392405, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31435, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.483655 ], [ 29.771888607594935, -1.483924482758621 ], [ 29.772158101265823, -1.483924482758621 ], [ 29.772158101265823, -1.483655 ], [ 29.771888607594935, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31436, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.483655 ], [ 29.772158101265823, -1.483924482758621 ], [ 29.772427594936708, -1.483924482758621 ], [ 29.772427594936708, -1.483655 ], [ 29.772158101265823, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31437, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.483655 ], [ 29.772427594936708, -1.483924482758621 ], [ 29.772697088607593, -1.483924482758621 ], [ 29.772697088607593, -1.483655 ], [ 29.772427594936708, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31438, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.483655 ], [ 29.772966582278482, -1.483924482758621 ], [ 29.773236075949367, -1.483924482758621 ], [ 29.773236075949367, -1.483655 ], [ 29.772966582278482, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31439, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.483655 ], [ 29.773236075949367, -1.483924482758621 ], [ 29.773505569620252, -1.483924482758621 ], [ 29.773505569620252, -1.483655 ], [ 29.773236075949367, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31440, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.483655 ], [ 29.773505569620252, -1.484463448275862 ], [ 29.77377506329114, -1.484463448275862 ], [ 29.77377506329114, -1.483655 ], [ 29.773505569620252, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31441, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.483655 ], [ 29.77377506329114, -1.483924482758621 ], [ 29.774044556962025, -1.483924482758621 ], [ 29.774044556962025, -1.483655 ], [ 29.77377506329114, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31442, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.483655 ], [ 29.774044556962025, -1.483924482758621 ], [ 29.77431405063291, -1.483924482758621 ], [ 29.77431405063291, -1.483655 ], [ 29.774044556962025, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31443, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.483655 ], [ 29.77431405063291, -1.483924482758621 ], [ 29.774853037974683, -1.483924482758621 ], [ 29.774853037974683, -1.483655 ], [ 29.77431405063291, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31444, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.483655 ], [ 29.774853037974683, -1.483924482758621 ], [ 29.775122531645568, -1.483924482758621 ], [ 29.775122531645568, -1.483655 ], [ 29.774853037974683, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31445, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.483655 ], [ 29.775122531645568, -1.483924482758621 ], [ 29.775392025316457, -1.483924482758621 ], [ 29.775392025316457, -1.483655 ], [ 29.775122531645568, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31446, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.483655 ], [ 29.775392025316457, -1.483924482758621 ], [ 29.775661518987341, -1.483924482758621 ], [ 29.775661518987341, -1.483655 ], [ 29.775392025316457, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31447, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.483655 ], [ 29.775661518987341, -1.483924482758621 ], [ 29.775931012658226, -1.483924482758621 ], [ 29.775931012658226, -1.483655 ], [ 29.775661518987341, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31448, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.483655 ], [ 29.775931012658226, -1.483924482758621 ], [ 29.776200506329115, -1.483924482758621 ], [ 29.776200506329115, -1.483655 ], [ 29.775931012658226, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31449, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.483655 ], [ 29.776200506329115, -1.484193965517242 ], [ 29.77647, -1.484193965517242 ], [ 29.77647, -1.483655 ], [ 29.776200506329115, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31450, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.483655 ], [ 29.77647, -1.484463448275862 ], [ 29.776739493670885, -1.484463448275862 ], [ 29.776739493670885, -1.483655 ], [ 29.77647, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31451, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.483655 ], [ 29.777817468354431, -1.484463448275862 ], [ 29.778086962025316, -1.484463448275862 ], [ 29.778086962025316, -1.483655 ], [ 29.777817468354431, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31452, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.483655 ], [ 29.77916493670886, -1.484193965517242 ], [ 29.779434430379748, -1.484193965517242 ], [ 29.779434430379748, -1.483655 ], [ 29.77916493670886, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31453, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.483655 ], [ 29.779973417721518, -1.484193965517242 ], [ 29.780242911392406, -1.484193965517242 ], [ 29.780242911392406, -1.483655 ], [ 29.779973417721518, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31454, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.483655 ], [ 29.780512405063291, -1.484193965517242 ], [ 29.780781898734176, -1.484193965517242 ], [ 29.780781898734176, -1.483655 ], [ 29.780512405063291, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31455, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.483655 ], [ 29.78132088607595, -1.484193965517242 ], [ 29.781590379746834, -1.484193965517242 ], [ 29.781590379746834, -1.483655 ], [ 29.78132088607595, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31456, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.483655 ], [ 29.782129367088608, -1.484193965517242 ], [ 29.782398860759493, -1.484193965517242 ], [ 29.782398860759493, -1.483655 ], [ 29.782129367088608, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31457, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.483655 ], [ 29.782668354430381, -1.483924482758621 ], [ 29.782937848101266, -1.483924482758621 ], [ 29.782937848101266, -1.483655 ], [ 29.782668354430381, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31458, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.483655 ], [ 29.782937848101266, -1.483924482758621 ], [ 29.783207341772151, -1.483924482758621 ], [ 29.783207341772151, -1.483655 ], [ 29.782937848101266, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31459, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.483655 ], [ 29.783476835443039, -1.483924482758621 ], [ 29.784015822784809, -1.483924482758621 ], [ 29.784015822784809, -1.483655 ], [ 29.783476835443039, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31460, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.483655 ], [ 29.784015822784809, -1.483924482758621 ], [ 29.784285316455698, -1.483924482758621 ], [ 29.784285316455698, -1.483655 ], [ 29.784015822784809, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31461, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.483655 ], [ 29.784285316455698, -1.483924482758621 ], [ 29.784554810126583, -1.483924482758621 ], [ 29.784554810126583, -1.483655 ], [ 29.784285316455698, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31462, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.483655 ], [ 29.784554810126583, -1.484193965517242 ], [ 29.784824303797468, -1.484193965517242 ], [ 29.784824303797468, -1.483655 ], [ 29.784554810126583, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31463, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.483655 ], [ 29.784824303797468, -1.484193965517242 ], [ 29.785093797468356, -1.484193965517242 ], [ 29.785093797468356, -1.483655 ], [ 29.784824303797468, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31464, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.483655 ], [ 29.785632784810126, -1.484193965517242 ], [ 29.785902278481014, -1.484193965517242 ], [ 29.785902278481014, -1.483655 ], [ 29.785632784810126, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31465, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.483655 ], [ 29.785902278481014, -1.483924482758621 ], [ 29.786171772151899, -1.483924482758621 ], [ 29.786171772151899, -1.483655 ], [ 29.785902278481014, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31466, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.483655 ], [ 29.786171772151899, -1.483924482758621 ], [ 29.786441265822784, -1.483924482758621 ], [ 29.786441265822784, -1.483655 ], [ 29.786171772151899, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31467, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.483655 ], [ 29.786441265822784, -1.483924482758621 ], [ 29.786710759493673, -1.483924482758621 ], [ 29.786710759493673, -1.483655 ], [ 29.786441265822784, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31468, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.483655 ], [ 29.786710759493673, -1.484193965517242 ], [ 29.786980253164558, -1.484193965517242 ], [ 29.786980253164558, -1.483924482758621 ], [ 29.787249746835442, -1.483924482758621 ], [ 29.787249746835442, -1.483655 ], [ 29.786710759493673, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31469, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.483655 ], [ 29.787249746835442, -1.483924482758621 ], [ 29.787519240506327, -1.483924482758621 ], [ 29.787519240506327, -1.483655 ], [ 29.787249746835442, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31470, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.483655 ], [ 29.787519240506327, -1.483924482758621 ], [ 29.787788734177216, -1.483924482758621 ], [ 29.787788734177216, -1.483655 ], [ 29.787519240506327, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31471, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798029493670885, -1.483655 ], [ 29.798029493670885, -1.484193965517242 ], [ 29.798298987341774, -1.484193965517242 ], [ 29.798298987341774, -1.483655 ], [ 29.798029493670885, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31472, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.483655 ], [ 29.798298987341774, -1.484193965517242 ], [ 29.798568481012659, -1.484193965517242 ], [ 29.798568481012659, -1.483655 ], [ 29.798298987341774, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31473, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.483655 ], [ 29.798568481012659, -1.483924482758621 ], [ 29.798837974683543, -1.483924482758621 ], [ 29.798837974683543, -1.483655 ], [ 29.798568481012659, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31474, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.483655 ], [ 29.798837974683543, -1.483924482758621 ], [ 29.799107468354432, -1.483924482758621 ], [ 29.799107468354432, -1.483655 ], [ 29.798837974683543, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31475, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.483655 ], [ 29.799107468354432, -1.483924482758621 ], [ 29.799376962025317, -1.483924482758621 ], [ 29.799376962025317, -1.483655 ], [ 29.799107468354432, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31476, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.483655 ], [ 29.799376962025317, -1.483924482758621 ], [ 29.799646455696202, -1.483924482758621 ], [ 29.799646455696202, -1.483655 ], [ 29.799376962025317, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31477, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.483655 ], [ 29.799646455696202, -1.483924482758621 ], [ 29.79991594936709, -1.483924482758621 ], [ 29.79991594936709, -1.483655 ], [ 29.799646455696202, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31478, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.483655 ], [ 29.79991594936709, -1.483924482758621 ], [ 29.800185443037975, -1.483924482758621 ], [ 29.800185443037975, -1.483655 ], [ 29.79991594936709, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31479, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.483655 ], [ 29.800185443037975, -1.483924482758621 ], [ 29.80045493670886, -1.483924482758621 ], [ 29.80045493670886, -1.483655 ], [ 29.800185443037975, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31480, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.483655 ], [ 29.80045493670886, -1.483924482758621 ], [ 29.800724430379748, -1.483924482758621 ], [ 29.800724430379748, -1.483655 ], [ 29.80045493670886, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31481, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.483655 ], [ 29.801263417721518, -1.483924482758621 ], [ 29.801532911392407, -1.483924482758621 ], [ 29.801532911392407, -1.483655 ], [ 29.801263417721518, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31482, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.483655 ], [ 29.802341392405065, -1.483924482758621 ], [ 29.80261088607595, -1.483924482758621 ], [ 29.80261088607595, -1.483655 ], [ 29.802341392405065, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31483, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.483655 ], [ 29.80261088607595, -1.483924482758621 ], [ 29.802880379746835, -1.483924482758621 ], [ 29.802880379746835, -1.483655 ], [ 29.80261088607595, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31484, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.483655 ], [ 29.802880379746835, -1.483924482758621 ], [ 29.803149873417723, -1.483924482758621 ], [ 29.803149873417723, -1.483655 ], [ 29.802880379746835, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31485, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764612278481014, -1.483924482758621 ], [ 29.764612278481014, -1.484193965517242 ], [ 29.764881772151899, -1.484193965517242 ], [ 29.764881772151899, -1.483924482758621 ], [ 29.764612278481014, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31486, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.483924482758621 ], [ 29.764881772151899, -1.484193965517242 ], [ 29.765151265822784, -1.484193965517242 ], [ 29.765151265822784, -1.483924482758621 ], [ 29.764881772151899, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31487, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.483924482758621 ], [ 29.765151265822784, -1.484193965517242 ], [ 29.765420759493672, -1.484193965517242 ], [ 29.765420759493672, -1.483924482758621 ], [ 29.765151265822784, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31488, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.483924482758621 ], [ 29.765420759493672, -1.484193965517242 ], [ 29.765959746835442, -1.484193965517242 ], [ 29.765959746835442, -1.483924482758621 ], [ 29.765420759493672, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31489, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.483924482758621 ], [ 29.765959746835442, -1.484193965517242 ], [ 29.766229240506327, -1.484193965517242 ], [ 29.766229240506327, -1.483924482758621 ], [ 29.765959746835442, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31490, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.483924482758621 ], [ 29.766229240506327, -1.484193965517242 ], [ 29.766498734177215, -1.484193965517242 ], [ 29.766498734177215, -1.483924482758621 ], [ 29.766229240506327, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31491, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.483924482758621 ], [ 29.766498734177215, -1.484193965517242 ], [ 29.7667682278481, -1.484193965517242 ], [ 29.7667682278481, -1.483924482758621 ], [ 29.766498734177215, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31492, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.483924482758621 ], [ 29.7667682278481, -1.484193965517242 ], [ 29.767037721518985, -1.484193965517242 ], [ 29.767037721518985, -1.483924482758621 ], [ 29.7667682278481, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31493, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.483924482758621 ], [ 29.767037721518985, -1.484193965517242 ], [ 29.767307215189874, -1.484193965517242 ], [ 29.767307215189874, -1.483924482758621 ], [ 29.767037721518985, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31494, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.483924482758621 ], [ 29.767307215189874, -1.484193965517242 ], [ 29.767576708860759, -1.484193965517242 ], [ 29.767576708860759, -1.483924482758621 ], [ 29.767307215189874, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31495, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.483924482758621 ], [ 29.767576708860759, -1.484193965517242 ], [ 29.767846202531643, -1.484193965517242 ], [ 29.767846202531643, -1.483924482758621 ], [ 29.767576708860759, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31496, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.483924482758621 ], [ 29.767846202531643, -1.484193965517242 ], [ 29.768115696202532, -1.484193965517242 ], [ 29.768115696202532, -1.483924482758621 ], [ 29.767846202531643, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31497, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.483924482758621 ], [ 29.768115696202532, -1.484193965517242 ], [ 29.768385189873417, -1.484193965517242 ], [ 29.768385189873417, -1.483924482758621 ], [ 29.768115696202532, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31498, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.483924482758621 ], [ 29.768385189873417, -1.484193965517242 ], [ 29.768654683544302, -1.484193965517242 ], [ 29.768654683544302, -1.483924482758621 ], [ 29.768385189873417, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31499, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.483924482758621 ], [ 29.768654683544302, -1.484193965517242 ], [ 29.76892417721519, -1.484193965517242 ], [ 29.76892417721519, -1.483924482758621 ], [ 29.768654683544302, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31500, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.483924482758621 ], [ 29.76892417721519, -1.484193965517242 ], [ 29.769193670886075, -1.484193965517242 ], [ 29.769193670886075, -1.483924482758621 ], [ 29.76892417721519, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31501, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.483924482758621 ], [ 29.769193670886075, -1.484193965517242 ], [ 29.76946316455696, -1.484193965517242 ], [ 29.76946316455696, -1.483924482758621 ], [ 29.769193670886075, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31502, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.483924482758621 ], [ 29.76946316455696, -1.484193965517242 ], [ 29.769732658227849, -1.484193965517242 ], [ 29.769732658227849, -1.483924482758621 ], [ 29.76946316455696, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31503, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.483924482758621 ], [ 29.769732658227849, -1.484193965517242 ], [ 29.770002151898733, -1.484193965517242 ], [ 29.770002151898733, -1.483924482758621 ], [ 29.769732658227849, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31504, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.483924482758621 ], [ 29.770002151898733, -1.484193965517242 ], [ 29.770271645569618, -1.484193965517242 ], [ 29.770271645569618, -1.483924482758621 ], [ 29.770002151898733, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31505, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.483924482758621 ], [ 29.770271645569618, -1.484193965517242 ], [ 29.770541139240507, -1.484193965517242 ], [ 29.770541139240507, -1.483924482758621 ], [ 29.770271645569618, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31506, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.483924482758621 ], [ 29.770541139240507, -1.484193965517242 ], [ 29.770810632911392, -1.484193965517242 ], [ 29.770810632911392, -1.483924482758621 ], [ 29.770541139240507, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31507, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.483924482758621 ], [ 29.770810632911392, -1.484193965517242 ], [ 29.771080126582277, -1.484193965517242 ], [ 29.771080126582277, -1.483924482758621 ], [ 29.770810632911392, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31508, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.483924482758621 ], [ 29.771080126582277, -1.484193965517242 ], [ 29.771349620253165, -1.484193965517242 ], [ 29.771349620253165, -1.483924482758621 ], [ 29.771080126582277, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31509, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.483924482758621 ], [ 29.771349620253165, -1.484193965517242 ], [ 29.77161911392405, -1.484193965517242 ], [ 29.77161911392405, -1.483924482758621 ], [ 29.771349620253165, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31510, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.483924482758621 ], [ 29.77161911392405, -1.484193965517242 ], [ 29.771888607594935, -1.484193965517242 ], [ 29.771888607594935, -1.483924482758621 ], [ 29.77161911392405, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31511, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.483924482758621 ], [ 29.771888607594935, -1.484193965517242 ], [ 29.772158101265823, -1.484193965517242 ], [ 29.772158101265823, -1.483924482758621 ], [ 29.771888607594935, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31512, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.483924482758621 ], [ 29.772158101265823, -1.484193965517242 ], [ 29.772427594936708, -1.484193965517242 ], [ 29.772427594936708, -1.483924482758621 ], [ 29.772158101265823, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31513, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.483924482758621 ], [ 29.772427594936708, -1.484193965517242 ], [ 29.772697088607593, -1.484193965517242 ], [ 29.772697088607593, -1.483924482758621 ], [ 29.772427594936708, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31514, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.483924482758621 ], [ 29.772697088607593, -1.484193965517242 ], [ 29.772966582278482, -1.484193965517242 ], [ 29.772966582278482, -1.483924482758621 ], [ 29.772697088607593, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31515, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.483924482758621 ], [ 29.772966582278482, -1.484193965517242 ], [ 29.773236075949367, -1.484193965517242 ], [ 29.773236075949367, -1.483924482758621 ], [ 29.772966582278482, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31516, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.483924482758621 ], [ 29.773236075949367, -1.484193965517242 ], [ 29.773505569620252, -1.484193965517242 ], [ 29.773505569620252, -1.483924482758621 ], [ 29.773236075949367, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31517, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.483924482758621 ], [ 29.77377506329114, -1.484193965517242 ], [ 29.774044556962025, -1.484193965517242 ], [ 29.774044556962025, -1.483924482758621 ], [ 29.77377506329114, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31518, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.483924482758621 ], [ 29.774044556962025, -1.484463448275862 ], [ 29.77431405063291, -1.484463448275862 ], [ 29.77431405063291, -1.483924482758621 ], [ 29.774044556962025, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31519, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.483924482758621 ], [ 29.77431405063291, -1.484193965517242 ], [ 29.774853037974683, -1.484193965517242 ], [ 29.774853037974683, -1.483924482758621 ], [ 29.77431405063291, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31520, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.483924482758621 ], [ 29.774853037974683, -1.484193965517242 ], [ 29.775122531645568, -1.484193965517242 ], [ 29.775122531645568, -1.483924482758621 ], [ 29.774853037974683, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31521, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.483924482758621 ], [ 29.775122531645568, -1.484193965517242 ], [ 29.775392025316457, -1.484193965517242 ], [ 29.775392025316457, -1.483924482758621 ], [ 29.775122531645568, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31522, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.483924482758621 ], [ 29.775392025316457, -1.484193965517242 ], [ 29.775661518987341, -1.484193965517242 ], [ 29.775661518987341, -1.483924482758621 ], [ 29.775392025316457, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31523, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.483924482758621 ], [ 29.775661518987341, -1.484193965517242 ], [ 29.775931012658226, -1.484193965517242 ], [ 29.775931012658226, -1.483924482758621 ], [ 29.775661518987341, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31524, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.483924482758621 ], [ 29.775931012658226, -1.484193965517242 ], [ 29.776200506329115, -1.484193965517242 ], [ 29.776200506329115, -1.483924482758621 ], [ 29.775931012658226, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31525, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.483924482758621 ], [ 29.777278481012658, -1.484463448275862 ], [ 29.777547974683543, -1.484463448275862 ], [ 29.777547974683543, -1.483924482758621 ], [ 29.777278481012658, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31526, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.483924482758621 ], [ 29.778086962025316, -1.484463448275862 ], [ 29.778356455696201, -1.484463448275862 ], [ 29.778356455696201, -1.483924482758621 ], [ 29.778086962025316, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31527, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.483924482758621 ], [ 29.77862594936709, -1.484463448275862 ], [ 29.778895443037975, -1.484463448275862 ], [ 29.778895443037975, -1.483924482758621 ], [ 29.77862594936709, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31528, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.483924482758621 ], [ 29.779434430379748, -1.484463448275862 ], [ 29.779703924050633, -1.484463448275862 ], [ 29.779703924050633, -1.483924482758621 ], [ 29.779434430379748, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31529, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.483924482758621 ], [ 29.780242911392406, -1.484463448275862 ], [ 29.780512405063291, -1.484463448275862 ], [ 29.780512405063291, -1.483924482758621 ], [ 29.780242911392406, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31530, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.483924482758621 ], [ 29.780781898734176, -1.484193965517242 ], [ 29.781051392405065, -1.484193965517242 ], [ 29.781051392405065, -1.483924482758621 ], [ 29.780781898734176, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31531, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.483924482758621 ], [ 29.781051392405065, -1.484463448275862 ], [ 29.78132088607595, -1.484463448275862 ], [ 29.78132088607595, -1.483924482758621 ], [ 29.781051392405065, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31532, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.483924482758621 ], [ 29.781590379746834, -1.484193965517242 ], [ 29.781859873417723, -1.484193965517242 ], [ 29.781859873417723, -1.483924482758621 ], [ 29.781590379746834, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31533, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.483924482758621 ], [ 29.781859873417723, -1.484193965517242 ], [ 29.782129367088608, -1.484193965517242 ], [ 29.782129367088608, -1.483924482758621 ], [ 29.781859873417723, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31534, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.483924482758621 ], [ 29.782398860759493, -1.484193965517242 ], [ 29.782668354430381, -1.484193965517242 ], [ 29.782668354430381, -1.483924482758621 ], [ 29.782398860759493, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31535, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.483924482758621 ], [ 29.782668354430381, -1.484193965517242 ], [ 29.782937848101266, -1.484193965517242 ], [ 29.782937848101266, -1.483924482758621 ], [ 29.782668354430381, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31536, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.483924482758621 ], [ 29.782937848101266, -1.484193965517242 ], [ 29.783207341772151, -1.484193965517242 ], [ 29.783207341772151, -1.483924482758621 ], [ 29.782937848101266, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31537, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.483924482758621 ], [ 29.783207341772151, -1.484193965517242 ], [ 29.783476835443039, -1.484193965517242 ], [ 29.783476835443039, -1.483924482758621 ], [ 29.783207341772151, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31538, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.483924482758621 ], [ 29.783476835443039, -1.484193965517242 ], [ 29.784015822784809, -1.484193965517242 ], [ 29.784015822784809, -1.483924482758621 ], [ 29.783476835443039, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31539, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.483924482758621 ], [ 29.784015822784809, -1.484463448275862 ], [ 29.784285316455698, -1.484463448275862 ], [ 29.784285316455698, -1.483924482758621 ], [ 29.784015822784809, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31540, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.483924482758621 ], [ 29.784285316455698, -1.484463448275862 ], [ 29.784554810126583, -1.484463448275862 ], [ 29.784554810126583, -1.483924482758621 ], [ 29.784285316455698, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31541, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.483924482758621 ], [ 29.785902278481014, -1.484193965517242 ], [ 29.786171772151899, -1.484193965517242 ], [ 29.786171772151899, -1.483924482758621 ], [ 29.785902278481014, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31542, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.483924482758621 ], [ 29.786171772151899, -1.484193965517242 ], [ 29.786441265822784, -1.484193965517242 ], [ 29.786441265822784, -1.483924482758621 ], [ 29.786171772151899, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31543, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.483924482758621 ], [ 29.786980253164558, -1.484193965517242 ], [ 29.787249746835442, -1.484193965517242 ], [ 29.787249746835442, -1.483924482758621 ], [ 29.786980253164558, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31544, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.483924482758621 ], [ 29.787249746835442, -1.484193965517242 ], [ 29.787519240506327, -1.484193965517242 ], [ 29.787519240506327, -1.483924482758621 ], [ 29.787249746835442, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31545, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.483655 ], [ 29.788058227848101, -1.483655 ], [ 29.788058227848101, -1.484193965517242 ], [ 29.787519240506327, -1.484193965517242 ], [ 29.787519240506327, -1.483924482758621 ], [ 29.787788734177216, -1.483924482758621 ], [ 29.787788734177216, -1.483655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31546, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798568481012659, -1.483924482758621 ], [ 29.798568481012659, -1.484193965517242 ], [ 29.798837974683543, -1.484193965517242 ], [ 29.798837974683543, -1.483924482758621 ], [ 29.798568481012659, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31547, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.483924482758621 ], [ 29.798837974683543, -1.484193965517242 ], [ 29.799107468354432, -1.484193965517242 ], [ 29.799107468354432, -1.483924482758621 ], [ 29.798837974683543, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31548, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.483924482758621 ], [ 29.799107468354432, -1.484193965517242 ], [ 29.799376962025317, -1.484193965517242 ], [ 29.799376962025317, -1.483924482758621 ], [ 29.799107468354432, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31549, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.483924482758621 ], [ 29.799376962025317, -1.484193965517242 ], [ 29.799646455696202, -1.484193965517242 ], [ 29.799646455696202, -1.483924482758621 ], [ 29.799376962025317, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31550, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.483924482758621 ], [ 29.799646455696202, -1.484193965517242 ], [ 29.79991594936709, -1.484193965517242 ], [ 29.79991594936709, -1.483924482758621 ], [ 29.799646455696202, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31551, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.483924482758621 ], [ 29.79991594936709, -1.484193965517242 ], [ 29.800185443037975, -1.484193965517242 ], [ 29.800185443037975, -1.483924482758621 ], [ 29.79991594936709, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31552, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.483924482758621 ], [ 29.800185443037975, -1.484193965517242 ], [ 29.80045493670886, -1.484193965517242 ], [ 29.80045493670886, -1.483924482758621 ], [ 29.800185443037975, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31553, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.483924482758621 ], [ 29.80045493670886, -1.484193965517242 ], [ 29.800724430379748, -1.484193965517242 ], [ 29.800724430379748, -1.483924482758621 ], [ 29.80045493670886, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31554, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.483924482758621 ], [ 29.800724430379748, -1.484193965517242 ], [ 29.800993924050633, -1.484193965517242 ], [ 29.800993924050633, -1.483924482758621 ], [ 29.800724430379748, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31555, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.483924482758621 ], [ 29.801263417721518, -1.484463448275862 ], [ 29.801532911392407, -1.484463448275862 ], [ 29.801532911392407, -1.483924482758621 ], [ 29.801263417721518, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31556, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.483924482758621 ], [ 29.802341392405065, -1.484193965517242 ], [ 29.80261088607595, -1.484193965517242 ], [ 29.80261088607595, -1.483924482758621 ], [ 29.802341392405065, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31557, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.483924482758621 ], [ 29.80261088607595, -1.484193965517242 ], [ 29.802880379746835, -1.484193965517242 ], [ 29.802880379746835, -1.483924482758621 ], [ 29.80261088607595, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31558, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.483924482758621 ], [ 29.802880379746835, -1.484193965517242 ], [ 29.803149873417723, -1.484193965517242 ], [ 29.803149873417723, -1.483924482758621 ], [ 29.802880379746835, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31559, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.483924482758621 ], [ 29.803149873417723, -1.484193965517242 ], [ 29.803688860759493, -1.484193965517242 ], [ 29.803688860759493, -1.483924482758621 ], [ 29.803149873417723, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31560, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.764881772151899, -1.484193965517242 ], [ 29.764881772151899, -1.484463448275862 ], [ 29.765151265822784, -1.484463448275862 ], [ 29.765151265822784, -1.484193965517242 ], [ 29.764881772151899, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31561, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.484193965517242 ], [ 29.765151265822784, -1.484463448275862 ], [ 29.765420759493672, -1.484463448275862 ], [ 29.765420759493672, -1.484193965517242 ], [ 29.765151265822784, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31562, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.484193965517242 ], [ 29.765420759493672, -1.484463448275862 ], [ 29.765959746835442, -1.484463448275862 ], [ 29.765959746835442, -1.484193965517242 ], [ 29.765420759493672, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31563, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.484193965517242 ], [ 29.765959746835442, -1.484463448275862 ], [ 29.766229240506327, -1.484463448275862 ], [ 29.766229240506327, -1.484193965517242 ], [ 29.765959746835442, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31564, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.484193965517242 ], [ 29.766229240506327, -1.484463448275862 ], [ 29.766498734177215, -1.484463448275862 ], [ 29.766498734177215, -1.484193965517242 ], [ 29.766229240506327, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31565, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.484193965517242 ], [ 29.766498734177215, -1.484463448275862 ], [ 29.7667682278481, -1.484463448275862 ], [ 29.7667682278481, -1.484193965517242 ], [ 29.766498734177215, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31566, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.484193965517242 ], [ 29.7667682278481, -1.484463448275862 ], [ 29.767037721518985, -1.484463448275862 ], [ 29.767037721518985, -1.484193965517242 ], [ 29.7667682278481, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31567, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.484193965517242 ], [ 29.767037721518985, -1.484463448275862 ], [ 29.767307215189874, -1.484463448275862 ], [ 29.767307215189874, -1.484193965517242 ], [ 29.767037721518985, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31568, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.484193965517242 ], [ 29.767307215189874, -1.484463448275862 ], [ 29.767576708860759, -1.484463448275862 ], [ 29.767576708860759, -1.484193965517242 ], [ 29.767307215189874, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31569, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.484193965517242 ], [ 29.767576708860759, -1.484463448275862 ], [ 29.767846202531643, -1.484463448275862 ], [ 29.767846202531643, -1.484193965517242 ], [ 29.767576708860759, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31570, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.484193965517242 ], [ 29.767846202531643, -1.484463448275862 ], [ 29.768115696202532, -1.484463448275862 ], [ 29.768115696202532, -1.484193965517242 ], [ 29.767846202531643, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31571, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.484193965517242 ], [ 29.768115696202532, -1.484463448275862 ], [ 29.768385189873417, -1.484463448275862 ], [ 29.768385189873417, -1.484193965517242 ], [ 29.768115696202532, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31572, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.484193965517242 ], [ 29.768385189873417, -1.484463448275862 ], [ 29.768654683544302, -1.484463448275862 ], [ 29.768654683544302, -1.484193965517242 ], [ 29.768385189873417, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31573, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.484193965517242 ], [ 29.768654683544302, -1.484463448275862 ], [ 29.76892417721519, -1.484463448275862 ], [ 29.76892417721519, -1.484193965517242 ], [ 29.768654683544302, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31574, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.484193965517242 ], [ 29.76892417721519, -1.484463448275862 ], [ 29.769193670886075, -1.484463448275862 ], [ 29.769193670886075, -1.484193965517242 ], [ 29.76892417721519, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31575, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.484193965517242 ], [ 29.769193670886075, -1.484463448275862 ], [ 29.76946316455696, -1.484463448275862 ], [ 29.76946316455696, -1.484193965517242 ], [ 29.769193670886075, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31576, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.484193965517242 ], [ 29.76946316455696, -1.484463448275862 ], [ 29.769732658227849, -1.484463448275862 ], [ 29.769732658227849, -1.484193965517242 ], [ 29.76946316455696, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31577, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.484193965517242 ], [ 29.769732658227849, -1.484463448275862 ], [ 29.770002151898733, -1.484463448275862 ], [ 29.770002151898733, -1.484193965517242 ], [ 29.769732658227849, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31578, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.484193965517242 ], [ 29.770002151898733, -1.484463448275862 ], [ 29.770271645569618, -1.484463448275862 ], [ 29.770271645569618, -1.484193965517242 ], [ 29.770002151898733, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31579, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.484193965517242 ], [ 29.770271645569618, -1.484463448275862 ], [ 29.770541139240507, -1.484463448275862 ], [ 29.770541139240507, -1.484193965517242 ], [ 29.770271645569618, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31580, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.484193965517242 ], [ 29.770541139240507, -1.484463448275862 ], [ 29.770810632911392, -1.484463448275862 ], [ 29.770810632911392, -1.484193965517242 ], [ 29.770541139240507, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31581, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.484193965517242 ], [ 29.770810632911392, -1.484463448275862 ], [ 29.771080126582277, -1.484463448275862 ], [ 29.771080126582277, -1.484193965517242 ], [ 29.770810632911392, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31582, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.484193965517242 ], [ 29.771080126582277, -1.484463448275862 ], [ 29.771349620253165, -1.484463448275862 ], [ 29.771349620253165, -1.484193965517242 ], [ 29.771080126582277, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31583, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.484193965517242 ], [ 29.771349620253165, -1.484463448275862 ], [ 29.77161911392405, -1.484463448275862 ], [ 29.77161911392405, -1.484193965517242 ], [ 29.771349620253165, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31584, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.484193965517242 ], [ 29.77161911392405, -1.484463448275862 ], [ 29.771888607594935, -1.484463448275862 ], [ 29.771888607594935, -1.484193965517242 ], [ 29.77161911392405, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31585, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.484193965517242 ], [ 29.771888607594935, -1.484463448275862 ], [ 29.772158101265823, -1.484463448275862 ], [ 29.772158101265823, -1.484193965517242 ], [ 29.771888607594935, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31586, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.484193965517242 ], [ 29.772158101265823, -1.484463448275862 ], [ 29.772427594936708, -1.484463448275862 ], [ 29.772427594936708, -1.484193965517242 ], [ 29.772158101265823, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31587, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.484193965517242 ], [ 29.772427594936708, -1.484732931034483 ], [ 29.772697088607593, -1.484732931034483 ], [ 29.772697088607593, -1.484193965517242 ], [ 29.772427594936708, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31588, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.484193965517242 ], [ 29.772697088607593, -1.484463448275862 ], [ 29.772966582278482, -1.484463448275862 ], [ 29.772966582278482, -1.484193965517242 ], [ 29.772697088607593, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31589, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.484193965517242 ], [ 29.772966582278482, -1.484463448275862 ], [ 29.773236075949367, -1.484463448275862 ], [ 29.773236075949367, -1.484193965517242 ], [ 29.772966582278482, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31590, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.484193965517242 ], [ 29.773236075949367, -1.484463448275862 ], [ 29.773505569620252, -1.484463448275862 ], [ 29.773505569620252, -1.484193965517242 ], [ 29.773236075949367, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31591, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.484193965517242 ], [ 29.77377506329114, -1.484463448275862 ], [ 29.774044556962025, -1.484463448275862 ], [ 29.774044556962025, -1.484193965517242 ], [ 29.77377506329114, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31592, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.484193965517242 ], [ 29.77431405063291, -1.484463448275862 ], [ 29.774853037974683, -1.484463448275862 ], [ 29.774853037974683, -1.484193965517242 ], [ 29.77431405063291, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31593, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.484193965517242 ], [ 29.774853037974683, -1.484463448275862 ], [ 29.775122531645568, -1.484463448275862 ], [ 29.775122531645568, -1.484193965517242 ], [ 29.774853037974683, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31594, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.484193965517242 ], [ 29.775122531645568, -1.484463448275862 ], [ 29.775392025316457, -1.484463448275862 ], [ 29.775392025316457, -1.484193965517242 ], [ 29.775122531645568, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31595, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.484193965517242 ], [ 29.775392025316457, -1.484463448275862 ], [ 29.775661518987341, -1.484463448275862 ], [ 29.775661518987341, -1.484193965517242 ], [ 29.775392025316457, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31596, "El": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.484193965517242 ], [ 29.775661518987341, -1.484463448275862 ], [ 29.775931012658226, -1.484463448275862 ], [ 29.775931012658226, -1.484193965517242 ], [ 29.775661518987341, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31597, "El": 113 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.484193965517242 ], [ 29.775931012658226, -1.484463448275862 ], [ 29.776200506329115, -1.484463448275862 ], [ 29.776200506329115, -1.484193965517242 ], [ 29.775931012658226, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31598, "El": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.484193965517242 ], [ 29.776200506329115, -1.484732931034483 ], [ 29.77647, -1.484732931034483 ], [ 29.77647, -1.484193965517242 ], [ 29.776200506329115, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31599, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.484193965517242 ], [ 29.776739493670885, -1.484732931034483 ], [ 29.777008987341773, -1.484732931034483 ], [ 29.777008987341773, -1.484193965517242 ], [ 29.776739493670885, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31600, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.484193965517242 ], [ 29.777008987341773, -1.484732931034483 ], [ 29.777278481012658, -1.484732931034483 ], [ 29.777278481012658, -1.484193965517242 ], [ 29.777008987341773, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31601, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.484193965517242 ], [ 29.777547974683543, -1.484732931034483 ], [ 29.777817468354431, -1.484732931034483 ], [ 29.777817468354431, -1.484193965517242 ], [ 29.777547974683543, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31602, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.484193965517242 ], [ 29.778356455696201, -1.484732931034483 ], [ 29.77862594936709, -1.484732931034483 ], [ 29.77862594936709, -1.484193965517242 ], [ 29.778356455696201, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31603, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.484193965517242 ], [ 29.778895443037975, -1.484463448275862 ], [ 29.77916493670886, -1.484463448275862 ], [ 29.77916493670886, -1.484193965517242 ], [ 29.778895443037975, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31604, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.484193965517242 ], [ 29.77916493670886, -1.484732931034483 ], [ 29.779434430379748, -1.484732931034483 ], [ 29.779434430379748, -1.484193965517242 ], [ 29.77916493670886, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31605, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.484193965517242 ], [ 29.779703924050633, -1.484463448275862 ], [ 29.779973417721518, -1.484463448275862 ], [ 29.779973417721518, -1.484193965517242 ], [ 29.779703924050633, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31606, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.484193965517242 ], [ 29.779973417721518, -1.484463448275862 ], [ 29.780242911392406, -1.484463448275862 ], [ 29.780242911392406, -1.484193965517242 ], [ 29.779973417721518, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31607, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.484193965517242 ], [ 29.780512405063291, -1.484463448275862 ], [ 29.780781898734176, -1.484463448275862 ], [ 29.780781898734176, -1.484193965517242 ], [ 29.780512405063291, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31608, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.484193965517242 ], [ 29.780781898734176, -1.484463448275862 ], [ 29.781051392405065, -1.484463448275862 ], [ 29.781051392405065, -1.484193965517242 ], [ 29.780781898734176, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31609, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.484193965517242 ], [ 29.78132088607595, -1.484463448275862 ], [ 29.781590379746834, -1.484463448275862 ], [ 29.781590379746834, -1.484193965517242 ], [ 29.78132088607595, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31610, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.484193965517242 ], [ 29.781590379746834, -1.484463448275862 ], [ 29.781859873417723, -1.484463448275862 ], [ 29.781859873417723, -1.484193965517242 ], [ 29.781590379746834, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31611, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.484193965517242 ], [ 29.781859873417723, -1.484463448275862 ], [ 29.782129367088608, -1.484463448275862 ], [ 29.782129367088608, -1.484193965517242 ], [ 29.781859873417723, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31612, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.484193965517242 ], [ 29.782129367088608, -1.484463448275862 ], [ 29.782398860759493, -1.484463448275862 ], [ 29.782398860759493, -1.484193965517242 ], [ 29.782129367088608, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31613, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.484193965517242 ], [ 29.782398860759493, -1.484463448275862 ], [ 29.782668354430381, -1.484463448275862 ], [ 29.782668354430381, -1.484193965517242 ], [ 29.782398860759493, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31614, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.484193965517242 ], [ 29.782668354430381, -1.484463448275862 ], [ 29.782937848101266, -1.484463448275862 ], [ 29.782937848101266, -1.484193965517242 ], [ 29.782668354430381, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31615, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.484193965517242 ], [ 29.782937848101266, -1.484463448275862 ], [ 29.783207341772151, -1.484463448275862 ], [ 29.783207341772151, -1.484193965517242 ], [ 29.782937848101266, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31616, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.484193965517242 ], [ 29.783207341772151, -1.484463448275862 ], [ 29.783476835443039, -1.484463448275862 ], [ 29.783476835443039, -1.484193965517242 ], [ 29.783207341772151, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31617, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.484193965517242 ], [ 29.783476835443039, -1.484732931034483 ], [ 29.784015822784809, -1.484732931034483 ], [ 29.784015822784809, -1.484193965517242 ], [ 29.783476835443039, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31618, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.484193965517242 ], [ 29.784554810126583, -1.484463448275862 ], [ 29.784824303797468, -1.484463448275862 ], [ 29.784824303797468, -1.484193965517242 ], [ 29.784554810126583, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31619, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.484193965517242 ], [ 29.784824303797468, -1.484463448275862 ], [ 29.785093797468356, -1.484463448275862 ], [ 29.785093797468356, -1.484193965517242 ], [ 29.784824303797468, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31620, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.484193965517242 ], [ 29.785093797468356, -1.484463448275862 ], [ 29.785363291139241, -1.484463448275862 ], [ 29.785363291139241, -1.484193965517242 ], [ 29.785093797468356, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31621, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.484193965517242 ], [ 29.785363291139241, -1.484463448275862 ], [ 29.785632784810126, -1.484463448275862 ], [ 29.785632784810126, -1.484193965517242 ], [ 29.785363291139241, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31622, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.484193965517242 ], [ 29.785632784810126, -1.484463448275862 ], [ 29.785902278481014, -1.484463448275862 ], [ 29.785902278481014, -1.484193965517242 ], [ 29.785632784810126, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31623, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.484193965517242 ], [ 29.785902278481014, -1.484463448275862 ], [ 29.786171772151899, -1.484463448275862 ], [ 29.786171772151899, -1.484193965517242 ], [ 29.785902278481014, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31624, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.483924482758621 ], [ 29.786710759493673, -1.483924482758621 ], [ 29.786710759493673, -1.484463448275862 ], [ 29.786171772151899, -1.484463448275862 ], [ 29.786171772151899, -1.484193965517242 ], [ 29.786441265822784, -1.484193965517242 ], [ 29.786441265822784, -1.483924482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31625, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.484193965517242 ], [ 29.786710759493673, -1.484463448275862 ], [ 29.786980253164558, -1.484463448275862 ], [ 29.786980253164558, -1.484193965517242 ], [ 29.786710759493673, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31626, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.484193965517242 ], [ 29.786980253164558, -1.484463448275862 ], [ 29.787249746835442, -1.484463448275862 ], [ 29.787249746835442, -1.484193965517242 ], [ 29.786980253164558, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31627, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.484193965517242 ], [ 29.787249746835442, -1.484463448275862 ], [ 29.787519240506327, -1.484463448275862 ], [ 29.787519240506327, -1.484193965517242 ], [ 29.787249746835442, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31628, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798298987341774, -1.484193965517242 ], [ 29.798298987341774, -1.484463448275862 ], [ 29.798837974683543, -1.484463448275862 ], [ 29.798837974683543, -1.484193965517242 ], [ 29.798298987341774, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31629, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.484193965517242 ], [ 29.798837974683543, -1.484463448275862 ], [ 29.799107468354432, -1.484463448275862 ], [ 29.799107468354432, -1.484193965517242 ], [ 29.798837974683543, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31630, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.484193965517242 ], [ 29.799107468354432, -1.484463448275862 ], [ 29.799376962025317, -1.484463448275862 ], [ 29.799376962025317, -1.484193965517242 ], [ 29.799107468354432, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31631, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.484193965517242 ], [ 29.799376962025317, -1.484463448275862 ], [ 29.799646455696202, -1.484463448275862 ], [ 29.799646455696202, -1.484193965517242 ], [ 29.799376962025317, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31632, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.484193965517242 ], [ 29.799646455696202, -1.484463448275862 ], [ 29.79991594936709, -1.484463448275862 ], [ 29.79991594936709, -1.484193965517242 ], [ 29.799646455696202, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31633, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.484193965517242 ], [ 29.79991594936709, -1.484463448275862 ], [ 29.800185443037975, -1.484463448275862 ], [ 29.800185443037975, -1.484193965517242 ], [ 29.79991594936709, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31634, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.484193965517242 ], [ 29.800185443037975, -1.484463448275862 ], [ 29.80045493670886, -1.484463448275862 ], [ 29.80045493670886, -1.484193965517242 ], [ 29.800185443037975, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31635, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.484193965517242 ], [ 29.80045493670886, -1.484732931034483 ], [ 29.800724430379748, -1.484732931034483 ], [ 29.800724430379748, -1.484193965517242 ], [ 29.80045493670886, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31636, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.484193965517242 ], [ 29.800724430379748, -1.484463448275862 ], [ 29.801263417721518, -1.484463448275862 ], [ 29.801263417721518, -1.484193965517242 ], [ 29.800724430379748, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31637, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.484193965517242 ], [ 29.801532911392407, -1.484463448275862 ], [ 29.802071898734177, -1.484463448275862 ], [ 29.802071898734177, -1.484193965517242 ], [ 29.801532911392407, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31638, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.484193965517242 ], [ 29.802071898734177, -1.484463448275862 ], [ 29.802341392405065, -1.484463448275862 ], [ 29.802341392405065, -1.484193965517242 ], [ 29.802071898734177, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31639, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.484193965517242 ], [ 29.802341392405065, -1.484463448275862 ], [ 29.80261088607595, -1.484463448275862 ], [ 29.80261088607595, -1.484193965517242 ], [ 29.802341392405065, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31640, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.484193965517242 ], [ 29.80261088607595, -1.484463448275862 ], [ 29.802880379746835, -1.484463448275862 ], [ 29.802880379746835, -1.484193965517242 ], [ 29.80261088607595, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31641, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.484193965517242 ], [ 29.802880379746835, -1.484463448275862 ], [ 29.803149873417723, -1.484463448275862 ], [ 29.803149873417723, -1.484193965517242 ], [ 29.802880379746835, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31642, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.484193965517242 ], [ 29.803149873417723, -1.484463448275862 ], [ 29.803419367088608, -1.484463448275862 ], [ 29.803419367088608, -1.484193965517242 ], [ 29.803149873417723, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31643, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.484193965517242 ], [ 29.803419367088608, -1.484463448275862 ], [ 29.803688860759493, -1.484463448275862 ], [ 29.803688860759493, -1.484193965517242 ], [ 29.803419367088608, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31644, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765151265822784, -1.484463448275862 ], [ 29.765151265822784, -1.484732931034483 ], [ 29.765420759493672, -1.484732931034483 ], [ 29.765420759493672, -1.484463448275862 ], [ 29.765151265822784, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31645, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.484463448275862 ], [ 29.765420759493672, -1.484732931034483 ], [ 29.765959746835442, -1.484732931034483 ], [ 29.765959746835442, -1.484463448275862 ], [ 29.765420759493672, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31646, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.484463448275862 ], [ 29.765959746835442, -1.484732931034483 ], [ 29.766229240506327, -1.484732931034483 ], [ 29.766229240506327, -1.484463448275862 ], [ 29.765959746835442, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31647, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.484463448275862 ], [ 29.766229240506327, -1.484732931034483 ], [ 29.766498734177215, -1.484732931034483 ], [ 29.766498734177215, -1.484463448275862 ], [ 29.766229240506327, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31648, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.484463448275862 ], [ 29.766498734177215, -1.484732931034483 ], [ 29.7667682278481, -1.484732931034483 ], [ 29.7667682278481, -1.484463448275862 ], [ 29.766498734177215, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31649, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.484463448275862 ], [ 29.7667682278481, -1.484732931034483 ], [ 29.767037721518985, -1.484732931034483 ], [ 29.767037721518985, -1.484463448275862 ], [ 29.7667682278481, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31650, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.484463448275862 ], [ 29.767037721518985, -1.484732931034483 ], [ 29.767307215189874, -1.484732931034483 ], [ 29.767307215189874, -1.484463448275862 ], [ 29.767037721518985, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31651, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.484463448275862 ], [ 29.767307215189874, -1.484732931034483 ], [ 29.767576708860759, -1.484732931034483 ], [ 29.767576708860759, -1.484463448275862 ], [ 29.767307215189874, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31652, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.484463448275862 ], [ 29.767576708860759, -1.484732931034483 ], [ 29.767846202531643, -1.484732931034483 ], [ 29.767846202531643, -1.484463448275862 ], [ 29.767576708860759, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31653, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.484463448275862 ], [ 29.767846202531643, -1.484732931034483 ], [ 29.768115696202532, -1.484732931034483 ], [ 29.768115696202532, -1.484463448275862 ], [ 29.767846202531643, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31654, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.484463448275862 ], [ 29.768115696202532, -1.484732931034483 ], [ 29.768385189873417, -1.484732931034483 ], [ 29.768385189873417, -1.484463448275862 ], [ 29.768115696202532, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31655, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.484463448275862 ], [ 29.768385189873417, -1.484732931034483 ], [ 29.768654683544302, -1.484732931034483 ], [ 29.768654683544302, -1.484463448275862 ], [ 29.768385189873417, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31656, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.484463448275862 ], [ 29.768654683544302, -1.484732931034483 ], [ 29.76892417721519, -1.484732931034483 ], [ 29.76892417721519, -1.484463448275862 ], [ 29.768654683544302, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31657, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.484463448275862 ], [ 29.76892417721519, -1.484732931034483 ], [ 29.769193670886075, -1.484732931034483 ], [ 29.769193670886075, -1.484463448275862 ], [ 29.76892417721519, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31658, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.484463448275862 ], [ 29.769193670886075, -1.484732931034483 ], [ 29.76946316455696, -1.484732931034483 ], [ 29.76946316455696, -1.484463448275862 ], [ 29.769193670886075, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31659, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.484463448275862 ], [ 29.76946316455696, -1.484732931034483 ], [ 29.769732658227849, -1.484732931034483 ], [ 29.769732658227849, -1.484463448275862 ], [ 29.76946316455696, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31660, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.484463448275862 ], [ 29.769732658227849, -1.484732931034483 ], [ 29.770002151898733, -1.484732931034483 ], [ 29.770002151898733, -1.484463448275862 ], [ 29.769732658227849, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31661, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.484463448275862 ], [ 29.770002151898733, -1.484732931034483 ], [ 29.770271645569618, -1.484732931034483 ], [ 29.770271645569618, -1.484463448275862 ], [ 29.770002151898733, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31662, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.484463448275862 ], [ 29.770271645569618, -1.484732931034483 ], [ 29.770541139240507, -1.484732931034483 ], [ 29.770541139240507, -1.484463448275862 ], [ 29.770271645569618, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31663, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.484463448275862 ], [ 29.770541139240507, -1.484732931034483 ], [ 29.770810632911392, -1.484732931034483 ], [ 29.770810632911392, -1.484463448275862 ], [ 29.770541139240507, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31664, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.484463448275862 ], [ 29.770810632911392, -1.484732931034483 ], [ 29.771080126582277, -1.484732931034483 ], [ 29.771080126582277, -1.484463448275862 ], [ 29.770810632911392, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31665, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.484463448275862 ], [ 29.771080126582277, -1.484732931034483 ], [ 29.771349620253165, -1.484732931034483 ], [ 29.771349620253165, -1.484463448275862 ], [ 29.771080126582277, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31666, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.484463448275862 ], [ 29.77161911392405, -1.484732931034483 ], [ 29.771888607594935, -1.484732931034483 ], [ 29.771888607594935, -1.484463448275862 ], [ 29.77161911392405, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31667, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.484463448275862 ], [ 29.771888607594935, -1.484732931034483 ], [ 29.772158101265823, -1.484732931034483 ], [ 29.772158101265823, -1.484463448275862 ], [ 29.771888607594935, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31668, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.484463448275862 ], [ 29.772158101265823, -1.484732931034483 ], [ 29.772427594936708, -1.484732931034483 ], [ 29.772427594936708, -1.484463448275862 ], [ 29.772158101265823, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31669, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.484463448275862 ], [ 29.772697088607593, -1.484732931034483 ], [ 29.772966582278482, -1.484732931034483 ], [ 29.772966582278482, -1.484463448275862 ], [ 29.772697088607593, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31670, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.484463448275862 ], [ 29.772966582278482, -1.484732931034483 ], [ 29.773236075949367, -1.484732931034483 ], [ 29.773236075949367, -1.484463448275862 ], [ 29.772966582278482, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31671, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.484463448275862 ], [ 29.773505569620252, -1.484732931034483 ], [ 29.77377506329114, -1.484732931034483 ], [ 29.77377506329114, -1.484463448275862 ], [ 29.773505569620252, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31672, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.484463448275862 ], [ 29.77377506329114, -1.484732931034483 ], [ 29.774044556962025, -1.484732931034483 ], [ 29.774044556962025, -1.484463448275862 ], [ 29.77377506329114, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31673, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.484463448275862 ], [ 29.774044556962025, -1.484732931034483 ], [ 29.77431405063291, -1.484732931034483 ], [ 29.77431405063291, -1.484463448275862 ], [ 29.774044556962025, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31674, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.484463448275862 ], [ 29.77431405063291, -1.484732931034483 ], [ 29.774853037974683, -1.484732931034483 ], [ 29.774853037974683, -1.484463448275862 ], [ 29.77431405063291, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31675, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.484463448275862 ], [ 29.774853037974683, -1.484732931034483 ], [ 29.775122531645568, -1.484732931034483 ], [ 29.775122531645568, -1.484463448275862 ], [ 29.774853037974683, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31676, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.484463448275862 ], [ 29.775122531645568, -1.484732931034483 ], [ 29.775392025316457, -1.484732931034483 ], [ 29.775392025316457, -1.484463448275862 ], [ 29.775122531645568, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31677, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.484463448275862 ], [ 29.775392025316457, -1.484732931034483 ], [ 29.775661518987341, -1.484732931034483 ], [ 29.775661518987341, -1.484463448275862 ], [ 29.775392025316457, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31678, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.484463448275862 ], [ 29.775661518987341, -1.484732931034483 ], [ 29.775931012658226, -1.484732931034483 ], [ 29.775931012658226, -1.484463448275862 ], [ 29.775661518987341, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31679, "El": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.484463448275862 ], [ 29.775931012658226, -1.484732931034483 ], [ 29.776200506329115, -1.484732931034483 ], [ 29.776200506329115, -1.484463448275862 ], [ 29.775931012658226, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31680, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.484463448275862 ], [ 29.777817468354431, -1.484732931034483 ], [ 29.778086962025316, -1.484732931034483 ], [ 29.778086962025316, -1.484463448275862 ], [ 29.777817468354431, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31681, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.484463448275862 ], [ 29.77862594936709, -1.484732931034483 ], [ 29.778895443037975, -1.484732931034483 ], [ 29.778895443037975, -1.484463448275862 ], [ 29.77862594936709, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31682, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.484463448275862 ], [ 29.778895443037975, -1.484732931034483 ], [ 29.77916493670886, -1.484732931034483 ], [ 29.77916493670886, -1.484463448275862 ], [ 29.778895443037975, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31683, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.484463448275862 ], [ 29.779434430379748, -1.484732931034483 ], [ 29.779703924050633, -1.484732931034483 ], [ 29.779703924050633, -1.484463448275862 ], [ 29.779434430379748, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31684, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.484463448275862 ], [ 29.779703924050633, -1.484732931034483 ], [ 29.779973417721518, -1.484732931034483 ], [ 29.779973417721518, -1.484463448275862 ], [ 29.779703924050633, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31685, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.484463448275862 ], [ 29.780242911392406, -1.484732931034483 ], [ 29.780512405063291, -1.484732931034483 ], [ 29.780512405063291, -1.484463448275862 ], [ 29.780242911392406, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31686, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.484463448275862 ], [ 29.780512405063291, -1.484732931034483 ], [ 29.780781898734176, -1.484732931034483 ], [ 29.780781898734176, -1.484463448275862 ], [ 29.780512405063291, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31687, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.484463448275862 ], [ 29.780781898734176, -1.484732931034483 ], [ 29.781051392405065, -1.484732931034483 ], [ 29.781051392405065, -1.484463448275862 ], [ 29.780781898734176, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31688, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.484463448275862 ], [ 29.781051392405065, -1.484732931034483 ], [ 29.78132088607595, -1.484732931034483 ], [ 29.78132088607595, -1.484463448275862 ], [ 29.781051392405065, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31689, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.484463448275862 ], [ 29.78132088607595, -1.484732931034483 ], [ 29.781590379746834, -1.484732931034483 ], [ 29.781590379746834, -1.484463448275862 ], [ 29.78132088607595, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31690, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.484463448275862 ], [ 29.781590379746834, -1.484732931034483 ], [ 29.781859873417723, -1.484732931034483 ], [ 29.781859873417723, -1.484463448275862 ], [ 29.781590379746834, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31691, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.484463448275862 ], [ 29.781859873417723, -1.484732931034483 ], [ 29.782129367088608, -1.484732931034483 ], [ 29.782129367088608, -1.484463448275862 ], [ 29.781859873417723, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31692, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.484463448275862 ], [ 29.782129367088608, -1.484732931034483 ], [ 29.782398860759493, -1.484732931034483 ], [ 29.782398860759493, -1.484463448275862 ], [ 29.782129367088608, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31693, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.484463448275862 ], [ 29.782668354430381, -1.484732931034483 ], [ 29.782937848101266, -1.484732931034483 ], [ 29.782937848101266, -1.484463448275862 ], [ 29.782668354430381, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31694, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.484463448275862 ], [ 29.782937848101266, -1.484732931034483 ], [ 29.783207341772151, -1.484732931034483 ], [ 29.783207341772151, -1.484463448275862 ], [ 29.782937848101266, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31695, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.484463448275862 ], [ 29.783207341772151, -1.484732931034483 ], [ 29.783476835443039, -1.484732931034483 ], [ 29.783476835443039, -1.484463448275862 ], [ 29.783207341772151, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31696, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.484463448275862 ], [ 29.784015822784809, -1.484732931034483 ], [ 29.784285316455698, -1.484732931034483 ], [ 29.784285316455698, -1.484463448275862 ], [ 29.784015822784809, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31697, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.484463448275862 ], [ 29.784285316455698, -1.484732931034483 ], [ 29.784554810126583, -1.484732931034483 ], [ 29.784554810126583, -1.484463448275862 ], [ 29.784285316455698, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31698, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.484463448275862 ], [ 29.784554810126583, -1.484732931034483 ], [ 29.784824303797468, -1.484732931034483 ], [ 29.784824303797468, -1.484463448275862 ], [ 29.784554810126583, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31699, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.484463448275862 ], [ 29.784824303797468, -1.484732931034483 ], [ 29.785093797468356, -1.484732931034483 ], [ 29.785093797468356, -1.484463448275862 ], [ 29.784824303797468, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31700, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.484463448275862 ], [ 29.785093797468356, -1.484732931034483 ], [ 29.785363291139241, -1.484732931034483 ], [ 29.785363291139241, -1.484463448275862 ], [ 29.785093797468356, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31701, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.484463448275862 ], [ 29.785363291139241, -1.484732931034483 ], [ 29.785632784810126, -1.484732931034483 ], [ 29.785632784810126, -1.484463448275862 ], [ 29.785363291139241, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31702, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.484463448275862 ], [ 29.785632784810126, -1.484732931034483 ], [ 29.785902278481014, -1.484732931034483 ], [ 29.785902278481014, -1.484463448275862 ], [ 29.785632784810126, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31703, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.484463448275862 ], [ 29.785902278481014, -1.484732931034483 ], [ 29.786171772151899, -1.484732931034483 ], [ 29.786171772151899, -1.484463448275862 ], [ 29.785902278481014, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31704, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.484463448275862 ], [ 29.786171772151899, -1.484732931034483 ], [ 29.786441265822784, -1.484732931034483 ], [ 29.786441265822784, -1.484463448275862 ], [ 29.786171772151899, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31705, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.798837974683543, -1.484463448275862 ], [ 29.798837974683543, -1.484732931034483 ], [ 29.799107468354432, -1.484732931034483 ], [ 29.799107468354432, -1.484463448275862 ], [ 29.798837974683543, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31706, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.484463448275862 ], [ 29.799107468354432, -1.484732931034483 ], [ 29.799376962025317, -1.484732931034483 ], [ 29.799376962025317, -1.484463448275862 ], [ 29.799107468354432, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31707, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.484463448275862 ], [ 29.799376962025317, -1.484732931034483 ], [ 29.799646455696202, -1.484732931034483 ], [ 29.799646455696202, -1.484463448275862 ], [ 29.799376962025317, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31708, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.484463448275862 ], [ 29.799646455696202, -1.484732931034483 ], [ 29.79991594936709, -1.484732931034483 ], [ 29.79991594936709, -1.484463448275862 ], [ 29.799646455696202, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31709, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.484463448275862 ], [ 29.800993924050633, -1.484732931034483 ], [ 29.801263417721518, -1.484732931034483 ], [ 29.801263417721518, -1.484463448275862 ], [ 29.800993924050633, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31710, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.484463448275862 ], [ 29.801263417721518, -1.484732931034483 ], [ 29.801532911392407, -1.484732931034483 ], [ 29.801532911392407, -1.484463448275862 ], [ 29.801263417721518, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31711, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.484463448275862 ], [ 29.801532911392407, -1.484732931034483 ], [ 29.802071898734177, -1.484732931034483 ], [ 29.802071898734177, -1.484463448275862 ], [ 29.801532911392407, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31712, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.484463448275862 ], [ 29.802071898734177, -1.484732931034483 ], [ 29.802341392405065, -1.484732931034483 ], [ 29.802341392405065, -1.484463448275862 ], [ 29.802071898734177, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31713, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.484463448275862 ], [ 29.802341392405065, -1.484732931034483 ], [ 29.80261088607595, -1.484732931034483 ], [ 29.80261088607595, -1.484463448275862 ], [ 29.802341392405065, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31714, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.484463448275862 ], [ 29.80261088607595, -1.484732931034483 ], [ 29.802880379746835, -1.484732931034483 ], [ 29.802880379746835, -1.484463448275862 ], [ 29.80261088607595, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31715, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.484463448275862 ], [ 29.802880379746835, -1.484732931034483 ], [ 29.803149873417723, -1.484732931034483 ], [ 29.803149873417723, -1.484463448275862 ], [ 29.802880379746835, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31716, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.484463448275862 ], [ 29.803149873417723, -1.484732931034483 ], [ 29.803419367088608, -1.484732931034483 ], [ 29.803419367088608, -1.484463448275862 ], [ 29.803149873417723, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31717, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.484463448275862 ], [ 29.803419367088608, -1.484732931034483 ], [ 29.803688860759493, -1.484732931034483 ], [ 29.803688860759493, -1.484463448275862 ], [ 29.803419367088608, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31718, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.484463448275862 ], [ 29.771349620253165, -1.485002413793103 ], [ 29.77161911392405, -1.485002413793103 ], [ 29.77161911392405, -1.484463448275862 ], [ 29.771349620253165, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31719, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.484463448275862 ], [ 29.773236075949367, -1.485002413793103 ], [ 29.773505569620252, -1.485002413793103 ], [ 29.773505569620252, -1.484463448275862 ], [ 29.773236075949367, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31720, "El": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.484463448275862 ], [ 29.77647, -1.485271896551724 ], [ 29.776739493670885, -1.485271896551724 ], [ 29.776739493670885, -1.484463448275862 ], [ 29.77647, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31721, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.484463448275862 ], [ 29.777278481012658, -1.485002413793103 ], [ 29.777547974683543, -1.485002413793103 ], [ 29.777547974683543, -1.484463448275862 ], [ 29.777278481012658, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31722, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.484463448275862 ], [ 29.778086962025316, -1.485002413793103 ], [ 29.778356455696201, -1.485002413793103 ], [ 29.778356455696201, -1.484463448275862 ], [ 29.778086962025316, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31723, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.484463448275862 ], [ 29.779973417721518, -1.485002413793103 ], [ 29.780242911392406, -1.485002413793103 ], [ 29.780242911392406, -1.484463448275862 ], [ 29.779973417721518, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31724, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.484463448275862 ], [ 29.782398860759493, -1.485002413793103 ], [ 29.782668354430381, -1.485002413793103 ], [ 29.782668354430381, -1.484463448275862 ], [ 29.782398860759493, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31725, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.484463448275862 ], [ 29.786441265822784, -1.486080344827586 ], [ 29.786710759493673, -1.486080344827586 ], [ 29.786710759493673, -1.484463448275862 ], [ 29.786441265822784, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31726, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.484463448275862 ], [ 29.786710759493673, -1.486080344827586 ], [ 29.786980253164558, -1.486080344827586 ], [ 29.786980253164558, -1.484463448275862 ], [ 29.786710759493673, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31727, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.484463448275862 ], [ 29.786980253164558, -1.486080344827586 ], [ 29.787249746835442, -1.486080344827586 ], [ 29.787249746835442, -1.484463448275862 ], [ 29.786980253164558, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31728, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.484463448275862 ], [ 29.79991594936709, -1.485810862068966 ], [ 29.800185443037975, -1.485810862068966 ], [ 29.800185443037975, -1.484463448275862 ], [ 29.79991594936709, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31729, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.484463448275862 ], [ 29.800185443037975, -1.485810862068966 ], [ 29.80045493670886, -1.485810862068966 ], [ 29.80045493670886, -1.484463448275862 ], [ 29.800185443037975, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31730, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.484463448275862 ], [ 29.800724430379748, -1.485541379310345 ], [ 29.800993924050633, -1.485541379310345 ], [ 29.800993924050633, -1.484463448275862 ], [ 29.800724430379748, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31731, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765420759493672, -1.484732931034483 ], [ 29.765420759493672, -1.485002413793103 ], [ 29.765959746835442, -1.485002413793103 ], [ 29.765959746835442, -1.484732931034483 ], [ 29.765420759493672, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31732, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.484732931034483 ], [ 29.765959746835442, -1.485002413793103 ], [ 29.766229240506327, -1.485002413793103 ], [ 29.766229240506327, -1.484732931034483 ], [ 29.765959746835442, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31733, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.484732931034483 ], [ 29.766229240506327, -1.485002413793103 ], [ 29.766498734177215, -1.485002413793103 ], [ 29.766498734177215, -1.484732931034483 ], [ 29.766229240506327, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31734, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.484732931034483 ], [ 29.766498734177215, -1.485002413793103 ], [ 29.7667682278481, -1.485002413793103 ], [ 29.7667682278481, -1.484732931034483 ], [ 29.766498734177215, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31735, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.484732931034483 ], [ 29.7667682278481, -1.485002413793103 ], [ 29.767037721518985, -1.485002413793103 ], [ 29.767037721518985, -1.484732931034483 ], [ 29.7667682278481, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31736, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.484732931034483 ], [ 29.767037721518985, -1.485002413793103 ], [ 29.767307215189874, -1.485002413793103 ], [ 29.767307215189874, -1.484732931034483 ], [ 29.767037721518985, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31737, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.484732931034483 ], [ 29.767307215189874, -1.485002413793103 ], [ 29.767576708860759, -1.485002413793103 ], [ 29.767576708860759, -1.484732931034483 ], [ 29.767307215189874, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31738, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.484732931034483 ], [ 29.767576708860759, -1.485002413793103 ], [ 29.767846202531643, -1.485002413793103 ], [ 29.767846202531643, -1.484732931034483 ], [ 29.767576708860759, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31739, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.484732931034483 ], [ 29.767846202531643, -1.485002413793103 ], [ 29.768115696202532, -1.485002413793103 ], [ 29.768115696202532, -1.484732931034483 ], [ 29.767846202531643, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31740, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.484732931034483 ], [ 29.768115696202532, -1.485002413793103 ], [ 29.768385189873417, -1.485002413793103 ], [ 29.768385189873417, -1.484732931034483 ], [ 29.768115696202532, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31741, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.484732931034483 ], [ 29.768385189873417, -1.485002413793103 ], [ 29.768654683544302, -1.485002413793103 ], [ 29.768654683544302, -1.484732931034483 ], [ 29.768385189873417, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31742, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.484732931034483 ], [ 29.768654683544302, -1.485002413793103 ], [ 29.76892417721519, -1.485002413793103 ], [ 29.76892417721519, -1.484732931034483 ], [ 29.768654683544302, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31743, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.484732931034483 ], [ 29.76892417721519, -1.485002413793103 ], [ 29.769193670886075, -1.485002413793103 ], [ 29.769193670886075, -1.484732931034483 ], [ 29.76892417721519, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31744, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.484732931034483 ], [ 29.769193670886075, -1.485002413793103 ], [ 29.76946316455696, -1.485002413793103 ], [ 29.76946316455696, -1.484732931034483 ], [ 29.769193670886075, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31745, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.484732931034483 ], [ 29.76946316455696, -1.485002413793103 ], [ 29.769732658227849, -1.485002413793103 ], [ 29.769732658227849, -1.484732931034483 ], [ 29.76946316455696, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31746, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.484732931034483 ], [ 29.769732658227849, -1.485002413793103 ], [ 29.770002151898733, -1.485002413793103 ], [ 29.770002151898733, -1.484732931034483 ], [ 29.769732658227849, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31747, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.484732931034483 ], [ 29.770002151898733, -1.485002413793103 ], [ 29.770271645569618, -1.485002413793103 ], [ 29.770271645569618, -1.484732931034483 ], [ 29.770002151898733, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31748, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.484732931034483 ], [ 29.770271645569618, -1.485002413793103 ], [ 29.770541139240507, -1.485002413793103 ], [ 29.770541139240507, -1.484732931034483 ], [ 29.770271645569618, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31749, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.484732931034483 ], [ 29.770541139240507, -1.485002413793103 ], [ 29.770810632911392, -1.485002413793103 ], [ 29.770810632911392, -1.484732931034483 ], [ 29.770541139240507, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31750, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.484732931034483 ], [ 29.770810632911392, -1.485002413793103 ], [ 29.771080126582277, -1.485002413793103 ], [ 29.771080126582277, -1.484732931034483 ], [ 29.770810632911392, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31751, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.484732931034483 ], [ 29.771080126582277, -1.485002413793103 ], [ 29.771349620253165, -1.485002413793103 ], [ 29.771349620253165, -1.484732931034483 ], [ 29.771080126582277, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31752, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.484732931034483 ], [ 29.77161911392405, -1.485002413793103 ], [ 29.771888607594935, -1.485002413793103 ], [ 29.771888607594935, -1.484732931034483 ], [ 29.77161911392405, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31753, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.484732931034483 ], [ 29.771888607594935, -1.485002413793103 ], [ 29.772158101265823, -1.485002413793103 ], [ 29.772158101265823, -1.484732931034483 ], [ 29.771888607594935, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31754, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.484732931034483 ], [ 29.772158101265823, -1.485271896551724 ], [ 29.772427594936708, -1.485271896551724 ], [ 29.772427594936708, -1.484732931034483 ], [ 29.772158101265823, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31755, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.484732931034483 ], [ 29.772427594936708, -1.485002413793103 ], [ 29.772697088607593, -1.485002413793103 ], [ 29.772697088607593, -1.484732931034483 ], [ 29.772427594936708, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31756, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.484732931034483 ], [ 29.772697088607593, -1.485002413793103 ], [ 29.772966582278482, -1.485002413793103 ], [ 29.772966582278482, -1.484732931034483 ], [ 29.772697088607593, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31757, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.484732931034483 ], [ 29.772966582278482, -1.485002413793103 ], [ 29.773236075949367, -1.485002413793103 ], [ 29.773236075949367, -1.484732931034483 ], [ 29.772966582278482, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31758, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.484732931034483 ], [ 29.773505569620252, -1.485002413793103 ], [ 29.77377506329114, -1.485002413793103 ], [ 29.77377506329114, -1.484732931034483 ], [ 29.773505569620252, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31759, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.484732931034483 ], [ 29.77377506329114, -1.485002413793103 ], [ 29.774044556962025, -1.485002413793103 ], [ 29.774044556962025, -1.484732931034483 ], [ 29.77377506329114, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31760, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.484732931034483 ], [ 29.774044556962025, -1.485002413793103 ], [ 29.77431405063291, -1.485002413793103 ], [ 29.77431405063291, -1.484732931034483 ], [ 29.774044556962025, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31761, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.484732931034483 ], [ 29.77431405063291, -1.485002413793103 ], [ 29.774853037974683, -1.485002413793103 ], [ 29.774853037974683, -1.484732931034483 ], [ 29.77431405063291, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31762, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.484732931034483 ], [ 29.774853037974683, -1.485002413793103 ], [ 29.775122531645568, -1.485002413793103 ], [ 29.775122531645568, -1.484732931034483 ], [ 29.774853037974683, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31763, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.484732931034483 ], [ 29.775122531645568, -1.485002413793103 ], [ 29.775392025316457, -1.485002413793103 ], [ 29.775392025316457, -1.484732931034483 ], [ 29.775122531645568, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31764, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.484732931034483 ], [ 29.775392025316457, -1.485002413793103 ], [ 29.775661518987341, -1.485002413793103 ], [ 29.775661518987341, -1.484732931034483 ], [ 29.775392025316457, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31765, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.484732931034483 ], [ 29.775661518987341, -1.485002413793103 ], [ 29.775931012658226, -1.485002413793103 ], [ 29.775931012658226, -1.484732931034483 ], [ 29.775661518987341, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31766, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.484732931034483 ], [ 29.775931012658226, -1.485002413793103 ], [ 29.776200506329115, -1.485002413793103 ], [ 29.776200506329115, -1.484732931034483 ], [ 29.775931012658226, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31767, "El": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.484732931034483 ], [ 29.776200506329115, -1.485002413793103 ], [ 29.77647, -1.485002413793103 ], [ 29.77647, -1.484732931034483 ], [ 29.776200506329115, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31768, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.484732931034483 ], [ 29.776739493670885, -1.485002413793103 ], [ 29.777008987341773, -1.485002413793103 ], [ 29.777008987341773, -1.484732931034483 ], [ 29.776739493670885, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31769, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.484732931034483 ], [ 29.777008987341773, -1.485271896551724 ], [ 29.777278481012658, -1.485271896551724 ], [ 29.777278481012658, -1.484732931034483 ], [ 29.777008987341773, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31770, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.484732931034483 ], [ 29.777547974683543, -1.485002413793103 ], [ 29.777817468354431, -1.485002413793103 ], [ 29.777817468354431, -1.484732931034483 ], [ 29.777547974683543, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31771, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.484732931034483 ], [ 29.777817468354431, -1.485002413793103 ], [ 29.778086962025316, -1.485002413793103 ], [ 29.778086962025316, -1.484732931034483 ], [ 29.777817468354431, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31772, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.484732931034483 ], [ 29.778356455696201, -1.485002413793103 ], [ 29.77862594936709, -1.485002413793103 ], [ 29.77862594936709, -1.484732931034483 ], [ 29.778356455696201, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31773, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.484732931034483 ], [ 29.77862594936709, -1.485002413793103 ], [ 29.778895443037975, -1.485002413793103 ], [ 29.778895443037975, -1.484732931034483 ], [ 29.77862594936709, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31774, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.484732931034483 ], [ 29.778895443037975, -1.485271896551724 ], [ 29.77916493670886, -1.485271896551724 ], [ 29.77916493670886, -1.484732931034483 ], [ 29.778895443037975, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31775, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.484732931034483 ], [ 29.77916493670886, -1.485002413793103 ], [ 29.779434430379748, -1.485002413793103 ], [ 29.779434430379748, -1.484732931034483 ], [ 29.77916493670886, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31776, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.484732931034483 ], [ 29.779434430379748, -1.485002413793103 ], [ 29.779703924050633, -1.485002413793103 ], [ 29.779703924050633, -1.484732931034483 ], [ 29.779434430379748, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31777, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.484732931034483 ], [ 29.779703924050633, -1.485002413793103 ], [ 29.779973417721518, -1.485002413793103 ], [ 29.779973417721518, -1.484732931034483 ], [ 29.779703924050633, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31778, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.484732931034483 ], [ 29.780242911392406, -1.485002413793103 ], [ 29.780512405063291, -1.485002413793103 ], [ 29.780512405063291, -1.484732931034483 ], [ 29.780242911392406, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31779, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.484732931034483 ], [ 29.780512405063291, -1.485002413793103 ], [ 29.780781898734176, -1.485002413793103 ], [ 29.780781898734176, -1.484732931034483 ], [ 29.780512405063291, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31780, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.484732931034483 ], [ 29.780781898734176, -1.485002413793103 ], [ 29.781051392405065, -1.485002413793103 ], [ 29.781051392405065, -1.484732931034483 ], [ 29.780781898734176, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31781, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.484732931034483 ], [ 29.781051392405065, -1.485002413793103 ], [ 29.78132088607595, -1.485002413793103 ], [ 29.78132088607595, -1.484732931034483 ], [ 29.781051392405065, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31782, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.484732931034483 ], [ 29.78132088607595, -1.485271896551724 ], [ 29.781590379746834, -1.485271896551724 ], [ 29.781590379746834, -1.484732931034483 ], [ 29.78132088607595, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31783, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.484732931034483 ], [ 29.781590379746834, -1.485002413793103 ], [ 29.781859873417723, -1.485002413793103 ], [ 29.781859873417723, -1.484732931034483 ], [ 29.781590379746834, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31784, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.484732931034483 ], [ 29.781859873417723, -1.485002413793103 ], [ 29.782129367088608, -1.485002413793103 ], [ 29.782129367088608, -1.484732931034483 ], [ 29.781859873417723, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31785, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.484732931034483 ], [ 29.782129367088608, -1.485002413793103 ], [ 29.782398860759493, -1.485002413793103 ], [ 29.782398860759493, -1.484732931034483 ], [ 29.782129367088608, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31786, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.484732931034483 ], [ 29.782668354430381, -1.485002413793103 ], [ 29.782937848101266, -1.485002413793103 ], [ 29.782937848101266, -1.484732931034483 ], [ 29.782668354430381, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31787, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.484732931034483 ], [ 29.782937848101266, -1.485002413793103 ], [ 29.783207341772151, -1.485002413793103 ], [ 29.783207341772151, -1.484732931034483 ], [ 29.782937848101266, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31788, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.484732931034483 ], [ 29.783207341772151, -1.485002413793103 ], [ 29.783476835443039, -1.485002413793103 ], [ 29.783476835443039, -1.484732931034483 ], [ 29.783207341772151, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31789, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.484732931034483 ], [ 29.783476835443039, -1.485002413793103 ], [ 29.784015822784809, -1.485002413793103 ], [ 29.784015822784809, -1.484732931034483 ], [ 29.783476835443039, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31790, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.484732931034483 ], [ 29.784015822784809, -1.485002413793103 ], [ 29.784285316455698, -1.485002413793103 ], [ 29.784285316455698, -1.484732931034483 ], [ 29.784015822784809, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31791, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.484732931034483 ], [ 29.784285316455698, -1.485002413793103 ], [ 29.784554810126583, -1.485002413793103 ], [ 29.784554810126583, -1.484732931034483 ], [ 29.784285316455698, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31792, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.484732931034483 ], [ 29.784554810126583, -1.485002413793103 ], [ 29.784824303797468, -1.485002413793103 ], [ 29.784824303797468, -1.484732931034483 ], [ 29.784554810126583, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31793, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.484732931034483 ], [ 29.784824303797468, -1.485002413793103 ], [ 29.785093797468356, -1.485002413793103 ], [ 29.785093797468356, -1.484732931034483 ], [ 29.784824303797468, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31794, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.484732931034483 ], [ 29.785093797468356, -1.485002413793103 ], [ 29.785363291139241, -1.485002413793103 ], [ 29.785363291139241, -1.484732931034483 ], [ 29.785093797468356, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31795, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.484732931034483 ], [ 29.785363291139241, -1.485002413793103 ], [ 29.785632784810126, -1.485002413793103 ], [ 29.785632784810126, -1.484732931034483 ], [ 29.785363291139241, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31796, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.484732931034483 ], [ 29.785632784810126, -1.485002413793103 ], [ 29.785902278481014, -1.485002413793103 ], [ 29.785902278481014, -1.484732931034483 ], [ 29.785632784810126, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31797, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.484732931034483 ], [ 29.785902278481014, -1.485002413793103 ], [ 29.786171772151899, -1.485002413793103 ], [ 29.786171772151899, -1.484732931034483 ], [ 29.785902278481014, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31798, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.484732931034483 ], [ 29.786171772151899, -1.486349827586207 ], [ 29.786441265822784, -1.486349827586207 ], [ 29.786441265822784, -1.484732931034483 ], [ 29.786171772151899, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31799, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799107468354432, -1.484732931034483 ], [ 29.799107468354432, -1.486349827586207 ], [ 29.799376962025317, -1.486349827586207 ], [ 29.799376962025317, -1.484732931034483 ], [ 29.799107468354432, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31800, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.484732931034483 ], [ 29.799376962025317, -1.486080344827586 ], [ 29.799646455696202, -1.486080344827586 ], [ 29.799646455696202, -1.484732931034483 ], [ 29.799376962025317, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31801, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.484732931034483 ], [ 29.799646455696202, -1.486080344827586 ], [ 29.79991594936709, -1.486080344827586 ], [ 29.79991594936709, -1.484732931034483 ], [ 29.799646455696202, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31802, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.484732931034483 ], [ 29.80045493670886, -1.485810862068966 ], [ 29.800724430379748, -1.485810862068966 ], [ 29.800724430379748, -1.484732931034483 ], [ 29.80045493670886, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31803, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.484732931034483 ], [ 29.800993924050633, -1.485541379310345 ], [ 29.801263417721518, -1.485541379310345 ], [ 29.801263417721518, -1.485271896551724 ], [ 29.801532911392407, -1.485271896551724 ], [ 29.801532911392407, -1.485002413793103 ], [ 29.801263417721518, -1.485002413793103 ], [ 29.801263417721518, -1.484732931034483 ], [ 29.800993924050633, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31804, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.484732931034483 ], [ 29.801263417721518, -1.485002413793103 ], [ 29.801532911392407, -1.485002413793103 ], [ 29.801532911392407, -1.484732931034483 ], [ 29.801263417721518, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31805, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.484732931034483 ], [ 29.801532911392407, -1.485002413793103 ], [ 29.802071898734177, -1.485002413793103 ], [ 29.802071898734177, -1.484732931034483 ], [ 29.801532911392407, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31806, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.484732931034483 ], [ 29.802071898734177, -1.485002413793103 ], [ 29.802341392405065, -1.485002413793103 ], [ 29.802341392405065, -1.484732931034483 ], [ 29.802071898734177, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31807, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.484732931034483 ], [ 29.802341392405065, -1.485002413793103 ], [ 29.80261088607595, -1.485002413793103 ], [ 29.80261088607595, -1.484732931034483 ], [ 29.802341392405065, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31808, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.484732931034483 ], [ 29.80261088607595, -1.485002413793103 ], [ 29.802880379746835, -1.485002413793103 ], [ 29.802880379746835, -1.484732931034483 ], [ 29.80261088607595, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31809, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.484732931034483 ], [ 29.802880379746835, -1.485002413793103 ], [ 29.803149873417723, -1.485002413793103 ], [ 29.803149873417723, -1.484732931034483 ], [ 29.802880379746835, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31810, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.484732931034483 ], [ 29.803149873417723, -1.485002413793103 ], [ 29.803419367088608, -1.485002413793103 ], [ 29.803419367088608, -1.484732931034483 ], [ 29.803149873417723, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31811, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.484732931034483 ], [ 29.803419367088608, -1.485002413793103 ], [ 29.803688860759493, -1.485002413793103 ], [ 29.803688860759493, -1.484732931034483 ], [ 29.803419367088608, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31812, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.765959746835442, -1.485002413793103 ], [ 29.765959746835442, -1.485271896551724 ], [ 29.766229240506327, -1.485271896551724 ], [ 29.766229240506327, -1.485002413793103 ], [ 29.765959746835442, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31813, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.485002413793103 ], [ 29.766229240506327, -1.485271896551724 ], [ 29.766498734177215, -1.485271896551724 ], [ 29.766498734177215, -1.485002413793103 ], [ 29.766229240506327, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31814, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.485002413793103 ], [ 29.766498734177215, -1.485271896551724 ], [ 29.7667682278481, -1.485271896551724 ], [ 29.7667682278481, -1.485002413793103 ], [ 29.766498734177215, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31815, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.485002413793103 ], [ 29.7667682278481, -1.485271896551724 ], [ 29.767037721518985, -1.485271896551724 ], [ 29.767037721518985, -1.485002413793103 ], [ 29.7667682278481, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31816, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.485002413793103 ], [ 29.767037721518985, -1.485271896551724 ], [ 29.767307215189874, -1.485271896551724 ], [ 29.767307215189874, -1.485002413793103 ], [ 29.767037721518985, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31817, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.485002413793103 ], [ 29.767307215189874, -1.485271896551724 ], [ 29.767576708860759, -1.485271896551724 ], [ 29.767576708860759, -1.485002413793103 ], [ 29.767307215189874, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31818, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.485002413793103 ], [ 29.767576708860759, -1.485271896551724 ], [ 29.767846202531643, -1.485271896551724 ], [ 29.767846202531643, -1.485002413793103 ], [ 29.767576708860759, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31819, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.485002413793103 ], [ 29.767846202531643, -1.485271896551724 ], [ 29.768115696202532, -1.485271896551724 ], [ 29.768115696202532, -1.485002413793103 ], [ 29.767846202531643, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31820, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.485002413793103 ], [ 29.768115696202532, -1.485271896551724 ], [ 29.768385189873417, -1.485271896551724 ], [ 29.768385189873417, -1.485002413793103 ], [ 29.768115696202532, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31821, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.485002413793103 ], [ 29.768385189873417, -1.485271896551724 ], [ 29.768654683544302, -1.485271896551724 ], [ 29.768654683544302, -1.485002413793103 ], [ 29.768385189873417, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31822, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.485002413793103 ], [ 29.768654683544302, -1.485271896551724 ], [ 29.76892417721519, -1.485271896551724 ], [ 29.76892417721519, -1.485002413793103 ], [ 29.768654683544302, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31823, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.485002413793103 ], [ 29.76892417721519, -1.485271896551724 ], [ 29.769193670886075, -1.485271896551724 ], [ 29.769193670886075, -1.485002413793103 ], [ 29.76892417721519, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31824, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.485002413793103 ], [ 29.769193670886075, -1.485271896551724 ], [ 29.76946316455696, -1.485271896551724 ], [ 29.76946316455696, -1.485002413793103 ], [ 29.769193670886075, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31825, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.485002413793103 ], [ 29.76946316455696, -1.485271896551724 ], [ 29.769732658227849, -1.485271896551724 ], [ 29.769732658227849, -1.485002413793103 ], [ 29.76946316455696, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31826, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.485002413793103 ], [ 29.769732658227849, -1.485271896551724 ], [ 29.770002151898733, -1.485271896551724 ], [ 29.770002151898733, -1.485002413793103 ], [ 29.769732658227849, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31827, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.485002413793103 ], [ 29.770002151898733, -1.485271896551724 ], [ 29.770271645569618, -1.485271896551724 ], [ 29.770271645569618, -1.485002413793103 ], [ 29.770002151898733, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31828, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.485002413793103 ], [ 29.770271645569618, -1.485541379310345 ], [ 29.770541139240507, -1.485541379310345 ], [ 29.770541139240507, -1.485002413793103 ], [ 29.770271645569618, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31829, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.485002413793103 ], [ 29.770541139240507, -1.485271896551724 ], [ 29.770810632911392, -1.485271896551724 ], [ 29.770810632911392, -1.485002413793103 ], [ 29.770541139240507, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31830, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.485002413793103 ], [ 29.770810632911392, -1.485271896551724 ], [ 29.771080126582277, -1.485271896551724 ], [ 29.771080126582277, -1.485002413793103 ], [ 29.770810632911392, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31831, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.485002413793103 ], [ 29.771080126582277, -1.485541379310345 ], [ 29.771349620253165, -1.485541379310345 ], [ 29.771349620253165, -1.485002413793103 ], [ 29.771080126582277, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31832, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.485002413793103 ], [ 29.771349620253165, -1.485271896551724 ], [ 29.77161911392405, -1.485271896551724 ], [ 29.77161911392405, -1.485002413793103 ], [ 29.771349620253165, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31833, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.485002413793103 ], [ 29.77161911392405, -1.485271896551724 ], [ 29.771888607594935, -1.485271896551724 ], [ 29.771888607594935, -1.485002413793103 ], [ 29.77161911392405, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31834, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.485002413793103 ], [ 29.771888607594935, -1.485271896551724 ], [ 29.772158101265823, -1.485271896551724 ], [ 29.772158101265823, -1.485002413793103 ], [ 29.771888607594935, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31835, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.485002413793103 ], [ 29.772427594936708, -1.485271896551724 ], [ 29.772697088607593, -1.485271896551724 ], [ 29.772697088607593, -1.485002413793103 ], [ 29.772427594936708, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31836, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.485002413793103 ], [ 29.772697088607593, -1.485271896551724 ], [ 29.772966582278482, -1.485271896551724 ], [ 29.772966582278482, -1.485002413793103 ], [ 29.772697088607593, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31837, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.485002413793103 ], [ 29.772966582278482, -1.485271896551724 ], [ 29.773236075949367, -1.485271896551724 ], [ 29.773236075949367, -1.485002413793103 ], [ 29.772966582278482, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31838, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.485002413793103 ], [ 29.773236075949367, -1.485271896551724 ], [ 29.773505569620252, -1.485271896551724 ], [ 29.773505569620252, -1.485002413793103 ], [ 29.773236075949367, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31839, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.485002413793103 ], [ 29.773505569620252, -1.485271896551724 ], [ 29.77377506329114, -1.485271896551724 ], [ 29.77377506329114, -1.485002413793103 ], [ 29.773505569620252, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31840, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.485002413793103 ], [ 29.77377506329114, -1.485271896551724 ], [ 29.774044556962025, -1.485271896551724 ], [ 29.774044556962025, -1.485002413793103 ], [ 29.77377506329114, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31841, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.485002413793103 ], [ 29.774044556962025, -1.485271896551724 ], [ 29.77431405063291, -1.485271896551724 ], [ 29.77431405063291, -1.485002413793103 ], [ 29.774044556962025, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31842, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.485002413793103 ], [ 29.77431405063291, -1.485271896551724 ], [ 29.774853037974683, -1.485271896551724 ], [ 29.774853037974683, -1.485002413793103 ], [ 29.77431405063291, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31843, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.485002413793103 ], [ 29.774853037974683, -1.485271896551724 ], [ 29.775122531645568, -1.485271896551724 ], [ 29.775122531645568, -1.485002413793103 ], [ 29.774853037974683, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31844, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.485002413793103 ], [ 29.775122531645568, -1.485271896551724 ], [ 29.775392025316457, -1.485271896551724 ], [ 29.775392025316457, -1.485002413793103 ], [ 29.775122531645568, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31845, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.485002413793103 ], [ 29.775392025316457, -1.485271896551724 ], [ 29.775661518987341, -1.485271896551724 ], [ 29.775661518987341, -1.485002413793103 ], [ 29.775392025316457, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31846, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.485002413793103 ], [ 29.775661518987341, -1.485271896551724 ], [ 29.775931012658226, -1.485271896551724 ], [ 29.775931012658226, -1.485002413793103 ], [ 29.775661518987341, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31847, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.485002413793103 ], [ 29.775931012658226, -1.485271896551724 ], [ 29.776200506329115, -1.485271896551724 ], [ 29.776200506329115, -1.485002413793103 ], [ 29.775931012658226, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31848, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.485002413793103 ], [ 29.776200506329115, -1.485271896551724 ], [ 29.77647, -1.485271896551724 ], [ 29.77647, -1.485002413793103 ], [ 29.776200506329115, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31849, "El": 105 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.485002413793103 ], [ 29.776739493670885, -1.485271896551724 ], [ 29.777008987341773, -1.485271896551724 ], [ 29.777008987341773, -1.485002413793103 ], [ 29.776739493670885, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31850, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.485002413793103 ], [ 29.777278481012658, -1.485271896551724 ], [ 29.777547974683543, -1.485271896551724 ], [ 29.777547974683543, -1.485002413793103 ], [ 29.777278481012658, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31851, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.485002413793103 ], [ 29.777547974683543, -1.485271896551724 ], [ 29.777817468354431, -1.485271896551724 ], [ 29.777817468354431, -1.485002413793103 ], [ 29.777547974683543, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31852, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.485002413793103 ], [ 29.777817468354431, -1.485541379310345 ], [ 29.778086962025316, -1.485541379310345 ], [ 29.778086962025316, -1.485002413793103 ], [ 29.777817468354431, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31853, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.485002413793103 ], [ 29.778086962025316, -1.485271896551724 ], [ 29.778356455696201, -1.485271896551724 ], [ 29.778356455696201, -1.485002413793103 ], [ 29.778086962025316, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31854, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.485002413793103 ], [ 29.778356455696201, -1.485271896551724 ], [ 29.77862594936709, -1.485271896551724 ], [ 29.77862594936709, -1.485002413793103 ], [ 29.778356455696201, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31855, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.485002413793103 ], [ 29.77862594936709, -1.485271896551724 ], [ 29.778895443037975, -1.485271896551724 ], [ 29.778895443037975, -1.485002413793103 ], [ 29.77862594936709, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31856, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.485002413793103 ], [ 29.77916493670886, -1.485271896551724 ], [ 29.779434430379748, -1.485271896551724 ], [ 29.779434430379748, -1.485002413793103 ], [ 29.77916493670886, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31857, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.485002413793103 ], [ 29.779434430379748, -1.485271896551724 ], [ 29.779703924050633, -1.485271896551724 ], [ 29.779703924050633, -1.485002413793103 ], [ 29.779434430379748, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31858, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.485002413793103 ], [ 29.779703924050633, -1.485271896551724 ], [ 29.779973417721518, -1.485271896551724 ], [ 29.779973417721518, -1.485002413793103 ], [ 29.779703924050633, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31859, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.485002413793103 ], [ 29.779973417721518, -1.485271896551724 ], [ 29.780242911392406, -1.485271896551724 ], [ 29.780242911392406, -1.485002413793103 ], [ 29.779973417721518, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31860, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.485002413793103 ], [ 29.780242911392406, -1.485271896551724 ], [ 29.780512405063291, -1.485271896551724 ], [ 29.780512405063291, -1.485002413793103 ], [ 29.780242911392406, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31861, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.485002413793103 ], [ 29.780512405063291, -1.485271896551724 ], [ 29.780781898734176, -1.485271896551724 ], [ 29.780781898734176, -1.485002413793103 ], [ 29.780512405063291, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31862, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.485002413793103 ], [ 29.780781898734176, -1.485271896551724 ], [ 29.781051392405065, -1.485271896551724 ], [ 29.781051392405065, -1.485002413793103 ], [ 29.780781898734176, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31863, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.485002413793103 ], [ 29.781051392405065, -1.485271896551724 ], [ 29.78132088607595, -1.485271896551724 ], [ 29.78132088607595, -1.485002413793103 ], [ 29.781051392405065, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31864, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.485002413793103 ], [ 29.781590379746834, -1.485271896551724 ], [ 29.781859873417723, -1.485271896551724 ], [ 29.781859873417723, -1.485002413793103 ], [ 29.781590379746834, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31865, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.485002413793103 ], [ 29.781859873417723, -1.485271896551724 ], [ 29.782129367088608, -1.485271896551724 ], [ 29.782129367088608, -1.485002413793103 ], [ 29.781859873417723, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31866, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.485002413793103 ], [ 29.782129367088608, -1.485271896551724 ], [ 29.782398860759493, -1.485271896551724 ], [ 29.782398860759493, -1.485002413793103 ], [ 29.782129367088608, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31867, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.485002413793103 ], [ 29.782398860759493, -1.485271896551724 ], [ 29.782668354430381, -1.485271896551724 ], [ 29.782668354430381, -1.485002413793103 ], [ 29.782398860759493, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31868, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.485002413793103 ], [ 29.782668354430381, -1.485271896551724 ], [ 29.782937848101266, -1.485271896551724 ], [ 29.782937848101266, -1.485002413793103 ], [ 29.782668354430381, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31869, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.485002413793103 ], [ 29.782937848101266, -1.485271896551724 ], [ 29.783207341772151, -1.485271896551724 ], [ 29.783207341772151, -1.485002413793103 ], [ 29.782937848101266, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31870, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.485002413793103 ], [ 29.783207341772151, -1.485271896551724 ], [ 29.783476835443039, -1.485271896551724 ], [ 29.783476835443039, -1.485002413793103 ], [ 29.783207341772151, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31871, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.485002413793103 ], [ 29.783476835443039, -1.485271896551724 ], [ 29.784015822784809, -1.485271896551724 ], [ 29.784015822784809, -1.485002413793103 ], [ 29.783476835443039, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31872, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.485002413793103 ], [ 29.784015822784809, -1.485271896551724 ], [ 29.784285316455698, -1.485271896551724 ], [ 29.784285316455698, -1.485002413793103 ], [ 29.784015822784809, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31873, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.485002413793103 ], [ 29.784285316455698, -1.485271896551724 ], [ 29.784554810126583, -1.485271896551724 ], [ 29.784554810126583, -1.485002413793103 ], [ 29.784285316455698, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31874, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.485002413793103 ], [ 29.784554810126583, -1.485271896551724 ], [ 29.784824303797468, -1.485271896551724 ], [ 29.784824303797468, -1.485002413793103 ], [ 29.784554810126583, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31875, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.485002413793103 ], [ 29.784824303797468, -1.485271896551724 ], [ 29.785093797468356, -1.485271896551724 ], [ 29.785093797468356, -1.485002413793103 ], [ 29.784824303797468, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31876, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.485002413793103 ], [ 29.785093797468356, -1.485271896551724 ], [ 29.785902278481014, -1.485271896551724 ], [ 29.785902278481014, -1.485002413793103 ], [ 29.785093797468356, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31877, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.485002413793103 ], [ 29.785902278481014, -1.485271896551724 ], [ 29.786171772151899, -1.485271896551724 ], [ 29.786171772151899, -1.485002413793103 ], [ 29.785902278481014, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31878, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.485002413793103 ], [ 29.801532911392407, -1.485271896551724 ], [ 29.802071898734177, -1.485271896551724 ], [ 29.802071898734177, -1.485002413793103 ], [ 29.801532911392407, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31879, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.485002413793103 ], [ 29.802071898734177, -1.485271896551724 ], [ 29.802341392405065, -1.485271896551724 ], [ 29.802341392405065, -1.485002413793103 ], [ 29.802071898734177, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31880, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.485002413793103 ], [ 29.802341392405065, -1.485271896551724 ], [ 29.80261088607595, -1.485271896551724 ], [ 29.80261088607595, -1.485002413793103 ], [ 29.802341392405065, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31881, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.485002413793103 ], [ 29.80261088607595, -1.485271896551724 ], [ 29.802880379746835, -1.485271896551724 ], [ 29.802880379746835, -1.485002413793103 ], [ 29.80261088607595, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31882, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.485002413793103 ], [ 29.802880379746835, -1.485271896551724 ], [ 29.803149873417723, -1.485271896551724 ], [ 29.803149873417723, -1.485002413793103 ], [ 29.802880379746835, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31883, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.485002413793103 ], [ 29.803149873417723, -1.485271896551724 ], [ 29.803419367088608, -1.485271896551724 ], [ 29.803419367088608, -1.485002413793103 ], [ 29.803149873417723, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31884, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766229240506327, -1.485271896551724 ], [ 29.766229240506327, -1.485810862068966 ], [ 29.766498734177215, -1.485810862068966 ], [ 29.766498734177215, -1.485271896551724 ], [ 29.766229240506327, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31885, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.485271896551724 ], [ 29.766498734177215, -1.485541379310345 ], [ 29.7667682278481, -1.485541379310345 ], [ 29.7667682278481, -1.485271896551724 ], [ 29.766498734177215, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31886, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.485271896551724 ], [ 29.7667682278481, -1.485541379310345 ], [ 29.767037721518985, -1.485541379310345 ], [ 29.767037721518985, -1.485271896551724 ], [ 29.7667682278481, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31887, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.485271896551724 ], [ 29.767037721518985, -1.485541379310345 ], [ 29.767307215189874, -1.485541379310345 ], [ 29.767307215189874, -1.485271896551724 ], [ 29.767037721518985, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31888, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.485271896551724 ], [ 29.767307215189874, -1.485541379310345 ], [ 29.767576708860759, -1.485541379310345 ], [ 29.767576708860759, -1.485271896551724 ], [ 29.767307215189874, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31889, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.485271896551724 ], [ 29.767576708860759, -1.485541379310345 ], [ 29.767846202531643, -1.485541379310345 ], [ 29.767846202531643, -1.485271896551724 ], [ 29.767576708860759, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31890, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.485271896551724 ], [ 29.767846202531643, -1.485541379310345 ], [ 29.768115696202532, -1.485541379310345 ], [ 29.768115696202532, -1.485271896551724 ], [ 29.767846202531643, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31891, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.485271896551724 ], [ 29.768115696202532, -1.485541379310345 ], [ 29.768385189873417, -1.485541379310345 ], [ 29.768385189873417, -1.485271896551724 ], [ 29.768115696202532, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31892, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.485271896551724 ], [ 29.768385189873417, -1.485541379310345 ], [ 29.768654683544302, -1.485541379310345 ], [ 29.768654683544302, -1.485271896551724 ], [ 29.768385189873417, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31893, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.485271896551724 ], [ 29.768654683544302, -1.485541379310345 ], [ 29.76892417721519, -1.485541379310345 ], [ 29.76892417721519, -1.485271896551724 ], [ 29.768654683544302, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31894, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.485271896551724 ], [ 29.76892417721519, -1.485541379310345 ], [ 29.769193670886075, -1.485541379310345 ], [ 29.769193670886075, -1.485271896551724 ], [ 29.76892417721519, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31895, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.485271896551724 ], [ 29.769193670886075, -1.485810862068966 ], [ 29.76946316455696, -1.485810862068966 ], [ 29.76946316455696, -1.485271896551724 ], [ 29.769193670886075, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31896, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.485271896551724 ], [ 29.76946316455696, -1.485541379310345 ], [ 29.769732658227849, -1.485541379310345 ], [ 29.769732658227849, -1.485271896551724 ], [ 29.76946316455696, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31897, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.485271896551724 ], [ 29.769732658227849, -1.485541379310345 ], [ 29.770002151898733, -1.485541379310345 ], [ 29.770002151898733, -1.485271896551724 ], [ 29.769732658227849, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31898, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.485271896551724 ], [ 29.770002151898733, -1.485810862068966 ], [ 29.770271645569618, -1.485810862068966 ], [ 29.770271645569618, -1.485271896551724 ], [ 29.770002151898733, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31899, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.485271896551724 ], [ 29.770541139240507, -1.485541379310345 ], [ 29.770810632911392, -1.485541379310345 ], [ 29.770810632911392, -1.485271896551724 ], [ 29.770541139240507, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31900, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.485271896551724 ], [ 29.770810632911392, -1.485810862068966 ], [ 29.771080126582277, -1.485810862068966 ], [ 29.771080126582277, -1.485271896551724 ], [ 29.770810632911392, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31901, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.485271896551724 ], [ 29.771349620253165, -1.485541379310345 ], [ 29.77161911392405, -1.485541379310345 ], [ 29.77161911392405, -1.485271896551724 ], [ 29.771349620253165, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31902, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.485271896551724 ], [ 29.77161911392405, -1.485541379310345 ], [ 29.771888607594935, -1.485541379310345 ], [ 29.771888607594935, -1.485271896551724 ], [ 29.77161911392405, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31903, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.485271896551724 ], [ 29.771888607594935, -1.485541379310345 ], [ 29.772158101265823, -1.485541379310345 ], [ 29.772158101265823, -1.485271896551724 ], [ 29.771888607594935, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31904, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.485271896551724 ], [ 29.772158101265823, -1.485541379310345 ], [ 29.772427594936708, -1.485541379310345 ], [ 29.772427594936708, -1.485271896551724 ], [ 29.772158101265823, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31905, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.485271896551724 ], [ 29.772427594936708, -1.485541379310345 ], [ 29.772697088607593, -1.485541379310345 ], [ 29.772697088607593, -1.485271896551724 ], [ 29.772427594936708, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31906, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.485271896551724 ], [ 29.772697088607593, -1.485541379310345 ], [ 29.772966582278482, -1.485541379310345 ], [ 29.772966582278482, -1.485271896551724 ], [ 29.772697088607593, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31907, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.485271896551724 ], [ 29.772966582278482, -1.485541379310345 ], [ 29.773236075949367, -1.485541379310345 ], [ 29.773236075949367, -1.485271896551724 ], [ 29.772966582278482, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31908, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.485271896551724 ], [ 29.773236075949367, -1.485541379310345 ], [ 29.773505569620252, -1.485541379310345 ], [ 29.773505569620252, -1.485271896551724 ], [ 29.773236075949367, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31909, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.485271896551724 ], [ 29.773505569620252, -1.485541379310345 ], [ 29.77377506329114, -1.485541379310345 ], [ 29.77377506329114, -1.485271896551724 ], [ 29.773505569620252, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31910, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.485271896551724 ], [ 29.77377506329114, -1.485541379310345 ], [ 29.774044556962025, -1.485541379310345 ], [ 29.774044556962025, -1.485271896551724 ], [ 29.77377506329114, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31911, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.485271896551724 ], [ 29.774044556962025, -1.485541379310345 ], [ 29.77431405063291, -1.485541379310345 ], [ 29.77431405063291, -1.485271896551724 ], [ 29.774044556962025, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31912, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.485271896551724 ], [ 29.77431405063291, -1.485541379310345 ], [ 29.774853037974683, -1.485541379310345 ], [ 29.774853037974683, -1.485271896551724 ], [ 29.77431405063291, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31913, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.485271896551724 ], [ 29.774853037974683, -1.485541379310345 ], [ 29.775122531645568, -1.485541379310345 ], [ 29.775122531645568, -1.485271896551724 ], [ 29.774853037974683, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31914, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.485271896551724 ], [ 29.775122531645568, -1.485541379310345 ], [ 29.775392025316457, -1.485541379310345 ], [ 29.775392025316457, -1.485271896551724 ], [ 29.775122531645568, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31915, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.485271896551724 ], [ 29.775392025316457, -1.485541379310345 ], [ 29.775661518987341, -1.485541379310345 ], [ 29.775661518987341, -1.485271896551724 ], [ 29.775392025316457, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31916, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.485271896551724 ], [ 29.775661518987341, -1.485541379310345 ], [ 29.775931012658226, -1.485541379310345 ], [ 29.775931012658226, -1.485271896551724 ], [ 29.775661518987341, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31917, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.485271896551724 ], [ 29.775931012658226, -1.485541379310345 ], [ 29.776200506329115, -1.485541379310345 ], [ 29.776200506329115, -1.485271896551724 ], [ 29.775931012658226, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31918, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.485271896551724 ], [ 29.776200506329115, -1.485541379310345 ], [ 29.77647, -1.485541379310345 ], [ 29.77647, -1.485271896551724 ], [ 29.776200506329115, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31919, "El": 104 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.485271896551724 ], [ 29.77647, -1.485541379310345 ], [ 29.776739493670885, -1.485541379310345 ], [ 29.776739493670885, -1.485271896551724 ], [ 29.77647, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31920, "El": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.485271896551724 ], [ 29.776739493670885, -1.485541379310345 ], [ 29.777008987341773, -1.485541379310345 ], [ 29.777008987341773, -1.485271896551724 ], [ 29.776739493670885, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31921, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.485271896551724 ], [ 29.777008987341773, -1.485541379310345 ], [ 29.777278481012658, -1.485541379310345 ], [ 29.777278481012658, -1.485271896551724 ], [ 29.777008987341773, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31922, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.485271896551724 ], [ 29.777278481012658, -1.485541379310345 ], [ 29.777547974683543, -1.485541379310345 ], [ 29.777547974683543, -1.485271896551724 ], [ 29.777278481012658, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31923, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.485271896551724 ], [ 29.777547974683543, -1.485541379310345 ], [ 29.777817468354431, -1.485541379310345 ], [ 29.777817468354431, -1.485271896551724 ], [ 29.777547974683543, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31924, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.485271896551724 ], [ 29.778086962025316, -1.485541379310345 ], [ 29.778356455696201, -1.485541379310345 ], [ 29.778356455696201, -1.485271896551724 ], [ 29.778086962025316, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31925, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.485271896551724 ], [ 29.778356455696201, -1.485541379310345 ], [ 29.77862594936709, -1.485541379310345 ], [ 29.77862594936709, -1.485271896551724 ], [ 29.778356455696201, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31926, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.485271896551724 ], [ 29.77862594936709, -1.485541379310345 ], [ 29.778895443037975, -1.485541379310345 ], [ 29.778895443037975, -1.485271896551724 ], [ 29.77862594936709, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31927, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.485271896551724 ], [ 29.778895443037975, -1.485541379310345 ], [ 29.77916493670886, -1.485541379310345 ], [ 29.77916493670886, -1.485271896551724 ], [ 29.778895443037975, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31928, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.485271896551724 ], [ 29.77916493670886, -1.485541379310345 ], [ 29.779434430379748, -1.485541379310345 ], [ 29.779434430379748, -1.485271896551724 ], [ 29.77916493670886, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31929, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.485271896551724 ], [ 29.779434430379748, -1.485541379310345 ], [ 29.779703924050633, -1.485541379310345 ], [ 29.779703924050633, -1.485271896551724 ], [ 29.779434430379748, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31930, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.485271896551724 ], [ 29.779703924050633, -1.485541379310345 ], [ 29.779973417721518, -1.485541379310345 ], [ 29.779973417721518, -1.485271896551724 ], [ 29.779703924050633, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31931, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.485271896551724 ], [ 29.779973417721518, -1.485541379310345 ], [ 29.780242911392406, -1.485541379310345 ], [ 29.780242911392406, -1.485271896551724 ], [ 29.779973417721518, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31932, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.485271896551724 ], [ 29.780242911392406, -1.485810862068966 ], [ 29.780512405063291, -1.485810862068966 ], [ 29.780512405063291, -1.485271896551724 ], [ 29.780242911392406, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31933, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.485271896551724 ], [ 29.780512405063291, -1.485541379310345 ], [ 29.780781898734176, -1.485541379310345 ], [ 29.780781898734176, -1.485271896551724 ], [ 29.780512405063291, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31934, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.485271896551724 ], [ 29.780781898734176, -1.485541379310345 ], [ 29.781051392405065, -1.485541379310345 ], [ 29.781051392405065, -1.485271896551724 ], [ 29.780781898734176, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31935, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.485271896551724 ], [ 29.781051392405065, -1.485541379310345 ], [ 29.78132088607595, -1.485541379310345 ], [ 29.78132088607595, -1.485271896551724 ], [ 29.781051392405065, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31936, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.485271896551724 ], [ 29.78132088607595, -1.485541379310345 ], [ 29.781590379746834, -1.485541379310345 ], [ 29.781590379746834, -1.485271896551724 ], [ 29.78132088607595, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31937, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.485271896551724 ], [ 29.781590379746834, -1.485541379310345 ], [ 29.781859873417723, -1.485541379310345 ], [ 29.781859873417723, -1.485271896551724 ], [ 29.781590379746834, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31938, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.485271896551724 ], [ 29.781859873417723, -1.485541379310345 ], [ 29.782129367088608, -1.485541379310345 ], [ 29.782129367088608, -1.485271896551724 ], [ 29.781859873417723, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31939, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.485271896551724 ], [ 29.782129367088608, -1.485541379310345 ], [ 29.782398860759493, -1.485541379310345 ], [ 29.782398860759493, -1.485271896551724 ], [ 29.782129367088608, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31940, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.485271896551724 ], [ 29.782398860759493, -1.485541379310345 ], [ 29.782668354430381, -1.485541379310345 ], [ 29.782668354430381, -1.485271896551724 ], [ 29.782398860759493, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31941, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.485271896551724 ], [ 29.782668354430381, -1.485541379310345 ], [ 29.782937848101266, -1.485541379310345 ], [ 29.782937848101266, -1.485271896551724 ], [ 29.782668354430381, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31942, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.485271896551724 ], [ 29.782937848101266, -1.485541379310345 ], [ 29.783207341772151, -1.485541379310345 ], [ 29.783207341772151, -1.485271896551724 ], [ 29.782937848101266, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31943, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.485271896551724 ], [ 29.783207341772151, -1.485541379310345 ], [ 29.783476835443039, -1.485541379310345 ], [ 29.783476835443039, -1.485271896551724 ], [ 29.783207341772151, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31944, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.485271896551724 ], [ 29.783476835443039, -1.485541379310345 ], [ 29.784015822784809, -1.485541379310345 ], [ 29.784015822784809, -1.485271896551724 ], [ 29.783476835443039, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31945, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.485271896551724 ], [ 29.784015822784809, -1.485541379310345 ], [ 29.784285316455698, -1.485541379310345 ], [ 29.784285316455698, -1.485271896551724 ], [ 29.784015822784809, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31946, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.485271896551724 ], [ 29.784285316455698, -1.485541379310345 ], [ 29.784554810126583, -1.485541379310345 ], [ 29.784554810126583, -1.485271896551724 ], [ 29.784285316455698, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31947, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.485271896551724 ], [ 29.784554810126583, -1.485541379310345 ], [ 29.784824303797468, -1.485541379310345 ], [ 29.784824303797468, -1.485271896551724 ], [ 29.784554810126583, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31948, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.485271896551724 ], [ 29.784824303797468, -1.485541379310345 ], [ 29.785093797468356, -1.485541379310345 ], [ 29.785093797468356, -1.485271896551724 ], [ 29.784824303797468, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31949, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.485271896551724 ], [ 29.785093797468356, -1.485541379310345 ], [ 29.785902278481014, -1.485541379310345 ], [ 29.785902278481014, -1.486349827586207 ], [ 29.786171772151899, -1.486349827586207 ], [ 29.786171772151899, -1.485271896551724 ], [ 29.785093797468356, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31950, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.485271896551724 ], [ 29.801263417721518, -1.485810862068966 ], [ 29.802071898734177, -1.485810862068966 ], [ 29.802071898734177, -1.485541379310345 ], [ 29.801532911392407, -1.485541379310345 ], [ 29.801532911392407, -1.485271896551724 ], [ 29.801263417721518, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31951, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.485271896551724 ], [ 29.801532911392407, -1.485541379310345 ], [ 29.802071898734177, -1.485541379310345 ], [ 29.802071898734177, -1.485271896551724 ], [ 29.801532911392407, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31952, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.485271896551724 ], [ 29.802071898734177, -1.485541379310345 ], [ 29.802341392405065, -1.485541379310345 ], [ 29.802341392405065, -1.485271896551724 ], [ 29.802071898734177, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31953, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.485271896551724 ], [ 29.802341392405065, -1.485541379310345 ], [ 29.80261088607595, -1.485541379310345 ], [ 29.80261088607595, -1.485271896551724 ], [ 29.802341392405065, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31954, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.485271896551724 ], [ 29.80261088607595, -1.485541379310345 ], [ 29.802880379746835, -1.485541379310345 ], [ 29.802880379746835, -1.485271896551724 ], [ 29.80261088607595, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31955, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.485541379310345 ], [ 29.766498734177215, -1.485810862068966 ], [ 29.7667682278481, -1.485810862068966 ], [ 29.7667682278481, -1.485541379310345 ], [ 29.766498734177215, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31956, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.485541379310345 ], [ 29.7667682278481, -1.485810862068966 ], [ 29.767037721518985, -1.485810862068966 ], [ 29.767037721518985, -1.485541379310345 ], [ 29.7667682278481, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31957, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.485541379310345 ], [ 29.767037721518985, -1.485810862068966 ], [ 29.767307215189874, -1.485810862068966 ], [ 29.767307215189874, -1.485541379310345 ], [ 29.767037721518985, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31958, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.485541379310345 ], [ 29.767307215189874, -1.485810862068966 ], [ 29.767576708860759, -1.485810862068966 ], [ 29.767576708860759, -1.485541379310345 ], [ 29.767307215189874, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31959, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.485541379310345 ], [ 29.767576708860759, -1.485810862068966 ], [ 29.767846202531643, -1.485810862068966 ], [ 29.767846202531643, -1.485541379310345 ], [ 29.767576708860759, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31960, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.485541379310345 ], [ 29.767846202531643, -1.485810862068966 ], [ 29.768115696202532, -1.485810862068966 ], [ 29.768115696202532, -1.485541379310345 ], [ 29.767846202531643, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31961, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.485541379310345 ], [ 29.768115696202532, -1.486080344827586 ], [ 29.768385189873417, -1.486080344827586 ], [ 29.768385189873417, -1.485541379310345 ], [ 29.768115696202532, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31962, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.485541379310345 ], [ 29.768385189873417, -1.485810862068966 ], [ 29.768654683544302, -1.485810862068966 ], [ 29.768654683544302, -1.485541379310345 ], [ 29.768385189873417, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31963, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.485541379310345 ], [ 29.768654683544302, -1.485810862068966 ], [ 29.76892417721519, -1.485810862068966 ], [ 29.76892417721519, -1.485541379310345 ], [ 29.768654683544302, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31964, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.485541379310345 ], [ 29.76892417721519, -1.486080344827586 ], [ 29.769193670886075, -1.486080344827586 ], [ 29.769193670886075, -1.485541379310345 ], [ 29.76892417721519, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31965, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.485541379310345 ], [ 29.76946316455696, -1.486080344827586 ], [ 29.769732658227849, -1.486080344827586 ], [ 29.769732658227849, -1.485541379310345 ], [ 29.76946316455696, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31966, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.485541379310345 ], [ 29.769732658227849, -1.486080344827586 ], [ 29.770002151898733, -1.486080344827586 ], [ 29.770002151898733, -1.485541379310345 ], [ 29.769732658227849, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31967, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.485541379310345 ], [ 29.770271645569618, -1.485810862068966 ], [ 29.770541139240507, -1.485810862068966 ], [ 29.770541139240507, -1.485541379310345 ], [ 29.770271645569618, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31968, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.485541379310345 ], [ 29.770541139240507, -1.485810862068966 ], [ 29.770810632911392, -1.485810862068966 ], [ 29.770810632911392, -1.485541379310345 ], [ 29.770541139240507, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31969, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.485541379310345 ], [ 29.771080126582277, -1.485810862068966 ], [ 29.771349620253165, -1.485810862068966 ], [ 29.771349620253165, -1.485541379310345 ], [ 29.771080126582277, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31970, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.485541379310345 ], [ 29.771349620253165, -1.485810862068966 ], [ 29.77161911392405, -1.485810862068966 ], [ 29.77161911392405, -1.485541379310345 ], [ 29.771349620253165, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31971, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.485541379310345 ], [ 29.77161911392405, -1.485810862068966 ], [ 29.771888607594935, -1.485810862068966 ], [ 29.771888607594935, -1.485541379310345 ], [ 29.77161911392405, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31972, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.485541379310345 ], [ 29.771888607594935, -1.485810862068966 ], [ 29.772158101265823, -1.485810862068966 ], [ 29.772158101265823, -1.485541379310345 ], [ 29.771888607594935, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31973, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.485541379310345 ], [ 29.772158101265823, -1.485810862068966 ], [ 29.772427594936708, -1.485810862068966 ], [ 29.772427594936708, -1.485541379310345 ], [ 29.772158101265823, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31974, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.485541379310345 ], [ 29.772427594936708, -1.485810862068966 ], [ 29.772697088607593, -1.485810862068966 ], [ 29.772697088607593, -1.485541379310345 ], [ 29.772427594936708, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31975, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.485541379310345 ], [ 29.772697088607593, -1.485810862068966 ], [ 29.772966582278482, -1.485810862068966 ], [ 29.772966582278482, -1.485541379310345 ], [ 29.772697088607593, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31976, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.485541379310345 ], [ 29.772966582278482, -1.485810862068966 ], [ 29.773236075949367, -1.485810862068966 ], [ 29.773236075949367, -1.485541379310345 ], [ 29.772966582278482, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31977, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.485541379310345 ], [ 29.773236075949367, -1.485810862068966 ], [ 29.773505569620252, -1.485810862068966 ], [ 29.773505569620252, -1.485541379310345 ], [ 29.773236075949367, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31978, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.485541379310345 ], [ 29.773505569620252, -1.485810862068966 ], [ 29.77377506329114, -1.485810862068966 ], [ 29.77377506329114, -1.485541379310345 ], [ 29.773505569620252, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31979, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.485541379310345 ], [ 29.77377506329114, -1.485810862068966 ], [ 29.774044556962025, -1.485810862068966 ], [ 29.774044556962025, -1.485541379310345 ], [ 29.77377506329114, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31980, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.485541379310345 ], [ 29.774044556962025, -1.485810862068966 ], [ 29.77431405063291, -1.485810862068966 ], [ 29.77431405063291, -1.485541379310345 ], [ 29.774044556962025, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31981, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.485541379310345 ], [ 29.77431405063291, -1.485810862068966 ], [ 29.774853037974683, -1.485810862068966 ], [ 29.774853037974683, -1.485541379310345 ], [ 29.77431405063291, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31982, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.485541379310345 ], [ 29.774853037974683, -1.485810862068966 ], [ 29.775122531645568, -1.485810862068966 ], [ 29.775122531645568, -1.485541379310345 ], [ 29.774853037974683, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31983, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.485541379310345 ], [ 29.775122531645568, -1.485810862068966 ], [ 29.775392025316457, -1.485810862068966 ], [ 29.775392025316457, -1.485541379310345 ], [ 29.775122531645568, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31984, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.485541379310345 ], [ 29.775392025316457, -1.485810862068966 ], [ 29.775661518987341, -1.485810862068966 ], [ 29.775661518987341, -1.485541379310345 ], [ 29.775392025316457, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31985, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.485541379310345 ], [ 29.775661518987341, -1.485810862068966 ], [ 29.775931012658226, -1.485810862068966 ], [ 29.775931012658226, -1.485541379310345 ], [ 29.775661518987341, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31986, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.485541379310345 ], [ 29.775931012658226, -1.485810862068966 ], [ 29.776200506329115, -1.485810862068966 ], [ 29.776200506329115, -1.485541379310345 ], [ 29.775931012658226, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31987, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.485541379310345 ], [ 29.776200506329115, -1.485810862068966 ], [ 29.77647, -1.485810862068966 ], [ 29.77647, -1.485541379310345 ], [ 29.776200506329115, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31988, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.485541379310345 ], [ 29.77647, -1.485810862068966 ], [ 29.776739493670885, -1.485810862068966 ], [ 29.776739493670885, -1.485541379310345 ], [ 29.77647, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31989, "El": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.485541379310345 ], [ 29.776739493670885, -1.485810862068966 ], [ 29.777278481012658, -1.485810862068966 ], [ 29.777278481012658, -1.485541379310345 ], [ 29.776739493670885, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31990, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.485541379310345 ], [ 29.777278481012658, -1.485810862068966 ], [ 29.777547974683543, -1.485810862068966 ], [ 29.777547974683543, -1.485541379310345 ], [ 29.777278481012658, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31991, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.485541379310345 ], [ 29.777547974683543, -1.485810862068966 ], [ 29.777817468354431, -1.485810862068966 ], [ 29.777817468354431, -1.485541379310345 ], [ 29.777547974683543, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31992, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.485541379310345 ], [ 29.777817468354431, -1.485810862068966 ], [ 29.778086962025316, -1.485810862068966 ], [ 29.778086962025316, -1.485541379310345 ], [ 29.777817468354431, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31993, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.485541379310345 ], [ 29.778086962025316, -1.485810862068966 ], [ 29.778356455696201, -1.485810862068966 ], [ 29.778356455696201, -1.485541379310345 ], [ 29.778086962025316, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31994, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.485541379310345 ], [ 29.778356455696201, -1.485810862068966 ], [ 29.77862594936709, -1.485810862068966 ], [ 29.77862594936709, -1.485541379310345 ], [ 29.778356455696201, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31995, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.485541379310345 ], [ 29.77862594936709, -1.485810862068966 ], [ 29.778895443037975, -1.485810862068966 ], [ 29.778895443037975, -1.485541379310345 ], [ 29.77862594936709, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31996, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.485541379310345 ], [ 29.778895443037975, -1.485810862068966 ], [ 29.77916493670886, -1.485810862068966 ], [ 29.77916493670886, -1.485541379310345 ], [ 29.778895443037975, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31997, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.485541379310345 ], [ 29.77916493670886, -1.486080344827586 ], [ 29.779434430379748, -1.486080344827586 ], [ 29.779434430379748, -1.485541379310345 ], [ 29.77916493670886, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31998, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.485541379310345 ], [ 29.779434430379748, -1.485810862068966 ], [ 29.779703924050633, -1.485810862068966 ], [ 29.779703924050633, -1.485541379310345 ], [ 29.779434430379748, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 31999, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.485541379310345 ], [ 29.779703924050633, -1.485810862068966 ], [ 29.779973417721518, -1.485810862068966 ], [ 29.779973417721518, -1.485541379310345 ], [ 29.779703924050633, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32000, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.485541379310345 ], [ 29.779973417721518, -1.485810862068966 ], [ 29.780242911392406, -1.485810862068966 ], [ 29.780242911392406, -1.485541379310345 ], [ 29.779973417721518, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32001, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.485541379310345 ], [ 29.780512405063291, -1.485810862068966 ], [ 29.780781898734176, -1.485810862068966 ], [ 29.780781898734176, -1.485541379310345 ], [ 29.780512405063291, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32002, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.485541379310345 ], [ 29.780781898734176, -1.485810862068966 ], [ 29.781051392405065, -1.485810862068966 ], [ 29.781051392405065, -1.485541379310345 ], [ 29.780781898734176, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32003, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.485541379310345 ], [ 29.781051392405065, -1.485810862068966 ], [ 29.78132088607595, -1.485810862068966 ], [ 29.78132088607595, -1.485541379310345 ], [ 29.781051392405065, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32004, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.485541379310345 ], [ 29.78132088607595, -1.485810862068966 ], [ 29.781590379746834, -1.485810862068966 ], [ 29.781590379746834, -1.485541379310345 ], [ 29.78132088607595, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32005, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.485541379310345 ], [ 29.781590379746834, -1.485810862068966 ], [ 29.781859873417723, -1.485810862068966 ], [ 29.781859873417723, -1.485541379310345 ], [ 29.781590379746834, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32006, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.485541379310345 ], [ 29.781859873417723, -1.485810862068966 ], [ 29.782129367088608, -1.485810862068966 ], [ 29.782129367088608, -1.485541379310345 ], [ 29.781859873417723, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32007, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.485541379310345 ], [ 29.782129367088608, -1.485810862068966 ], [ 29.782398860759493, -1.485810862068966 ], [ 29.782398860759493, -1.485541379310345 ], [ 29.782129367088608, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32008, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.485541379310345 ], [ 29.782398860759493, -1.485810862068966 ], [ 29.782668354430381, -1.485810862068966 ], [ 29.782668354430381, -1.485541379310345 ], [ 29.782398860759493, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32009, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.485541379310345 ], [ 29.782668354430381, -1.485810862068966 ], [ 29.782937848101266, -1.485810862068966 ], [ 29.782937848101266, -1.485541379310345 ], [ 29.782668354430381, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32010, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.485541379310345 ], [ 29.782937848101266, -1.485810862068966 ], [ 29.783207341772151, -1.485810862068966 ], [ 29.783207341772151, -1.485541379310345 ], [ 29.782937848101266, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32011, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.485541379310345 ], [ 29.783207341772151, -1.485810862068966 ], [ 29.783476835443039, -1.485810862068966 ], [ 29.783476835443039, -1.485541379310345 ], [ 29.783207341772151, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32012, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.485541379310345 ], [ 29.783476835443039, -1.485810862068966 ], [ 29.784015822784809, -1.485810862068966 ], [ 29.784015822784809, -1.485541379310345 ], [ 29.783476835443039, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32013, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.485541379310345 ], [ 29.784015822784809, -1.485810862068966 ], [ 29.784285316455698, -1.485810862068966 ], [ 29.784285316455698, -1.485541379310345 ], [ 29.784015822784809, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32014, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.485541379310345 ], [ 29.784285316455698, -1.485810862068966 ], [ 29.784554810126583, -1.485810862068966 ], [ 29.784554810126583, -1.485541379310345 ], [ 29.784285316455698, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32015, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.485541379310345 ], [ 29.784554810126583, -1.485810862068966 ], [ 29.784824303797468, -1.485810862068966 ], [ 29.784824303797468, -1.485541379310345 ], [ 29.784554810126583, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32016, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.485541379310345 ], [ 29.784824303797468, -1.485810862068966 ], [ 29.785363291139241, -1.485810862068966 ], [ 29.785363291139241, -1.485541379310345 ], [ 29.784824303797468, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32017, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.485541379310345 ], [ 29.785363291139241, -1.485810862068966 ], [ 29.785632784810126, -1.485810862068966 ], [ 29.785632784810126, -1.486080344827586 ], [ 29.785902278481014, -1.486080344827586 ], [ 29.785902278481014, -1.485541379310345 ], [ 29.785363291139241, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32018, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.485541379310345 ], [ 29.788866708860759, -1.485810862068966 ], [ 29.789136202531644, -1.485810862068966 ], [ 29.789136202531644, -1.486349827586207 ], [ 29.789405696202532, -1.486349827586207 ], [ 29.789405696202532, -1.485541379310345 ], [ 29.788866708860759, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32019, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.485541379310345 ], [ 29.800724430379748, -1.485810862068966 ], [ 29.800993924050633, -1.485810862068966 ], [ 29.800993924050633, -1.485541379310345 ], [ 29.800724430379748, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32020, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.485541379310345 ], [ 29.800993924050633, -1.485810862068966 ], [ 29.801263417721518, -1.485810862068966 ], [ 29.801263417721518, -1.485541379310345 ], [ 29.800993924050633, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32021, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.485541379310345 ], [ 29.802071898734177, -1.485810862068966 ], [ 29.802341392405065, -1.485810862068966 ], [ 29.802341392405065, -1.485541379310345 ], [ 29.802071898734177, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32022, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.485541379310345 ], [ 29.802341392405065, -1.485810862068966 ], [ 29.80261088607595, -1.485810862068966 ], [ 29.80261088607595, -1.485541379310345 ], [ 29.802341392405065, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32023, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.766498734177215, -1.485810862068966 ], [ 29.766498734177215, -1.486080344827586 ], [ 29.7667682278481, -1.486080344827586 ], [ 29.7667682278481, -1.485810862068966 ], [ 29.766498734177215, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32024, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.485810862068966 ], [ 29.7667682278481, -1.486080344827586 ], [ 29.767037721518985, -1.486080344827586 ], [ 29.767037721518985, -1.485810862068966 ], [ 29.7667682278481, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32025, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.485810862068966 ], [ 29.767037721518985, -1.486080344827586 ], [ 29.767307215189874, -1.486080344827586 ], [ 29.767307215189874, -1.485810862068966 ], [ 29.767037721518985, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32026, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.485810862068966 ], [ 29.767307215189874, -1.486080344827586 ], [ 29.767576708860759, -1.486080344827586 ], [ 29.767576708860759, -1.485810862068966 ], [ 29.767307215189874, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32027, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.485810862068966 ], [ 29.767576708860759, -1.486619310344828 ], [ 29.767846202531643, -1.486619310344828 ], [ 29.767846202531643, -1.485810862068966 ], [ 29.767576708860759, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32028, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.485810862068966 ], [ 29.767846202531643, -1.486619310344828 ], [ 29.768115696202532, -1.486619310344828 ], [ 29.768115696202532, -1.485810862068966 ], [ 29.767846202531643, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32029, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.485810862068966 ], [ 29.768385189873417, -1.486349827586207 ], [ 29.768654683544302, -1.486349827586207 ], [ 29.768654683544302, -1.485810862068966 ], [ 29.768385189873417, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32030, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.485810862068966 ], [ 29.768654683544302, -1.486349827586207 ], [ 29.76892417721519, -1.486349827586207 ], [ 29.76892417721519, -1.485810862068966 ], [ 29.768654683544302, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32031, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.485810862068966 ], [ 29.769193670886075, -1.486080344827586 ], [ 29.76946316455696, -1.486080344827586 ], [ 29.76946316455696, -1.485810862068966 ], [ 29.769193670886075, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32032, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.485810862068966 ], [ 29.770002151898733, -1.486080344827586 ], [ 29.770271645569618, -1.486080344827586 ], [ 29.770271645569618, -1.485810862068966 ], [ 29.770002151898733, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32033, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.485810862068966 ], [ 29.770271645569618, -1.486080344827586 ], [ 29.770541139240507, -1.486080344827586 ], [ 29.770541139240507, -1.485810862068966 ], [ 29.770271645569618, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32034, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.485810862068966 ], [ 29.770541139240507, -1.486080344827586 ], [ 29.770810632911392, -1.486080344827586 ], [ 29.770810632911392, -1.485810862068966 ], [ 29.770541139240507, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32035, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.485810862068966 ], [ 29.770810632911392, -1.486080344827586 ], [ 29.771080126582277, -1.486080344827586 ], [ 29.771080126582277, -1.485810862068966 ], [ 29.770810632911392, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32036, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.485810862068966 ], [ 29.771080126582277, -1.486080344827586 ], [ 29.771349620253165, -1.486080344827586 ], [ 29.771349620253165, -1.485810862068966 ], [ 29.771080126582277, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32037, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.485810862068966 ], [ 29.771349620253165, -1.486080344827586 ], [ 29.77161911392405, -1.486080344827586 ], [ 29.77161911392405, -1.485810862068966 ], [ 29.771349620253165, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32038, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.485810862068966 ], [ 29.77161911392405, -1.486080344827586 ], [ 29.771888607594935, -1.486080344827586 ], [ 29.771888607594935, -1.485810862068966 ], [ 29.77161911392405, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32039, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.485810862068966 ], [ 29.771888607594935, -1.486080344827586 ], [ 29.772158101265823, -1.486080344827586 ], [ 29.772158101265823, -1.485810862068966 ], [ 29.771888607594935, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32040, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.485810862068966 ], [ 29.772158101265823, -1.486080344827586 ], [ 29.772427594936708, -1.486080344827586 ], [ 29.772427594936708, -1.485810862068966 ], [ 29.772158101265823, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32041, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.485810862068966 ], [ 29.772427594936708, -1.486080344827586 ], [ 29.772697088607593, -1.486080344827586 ], [ 29.772697088607593, -1.485810862068966 ], [ 29.772427594936708, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32042, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.485810862068966 ], [ 29.772697088607593, -1.486080344827586 ], [ 29.772966582278482, -1.486080344827586 ], [ 29.772966582278482, -1.485810862068966 ], [ 29.772697088607593, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32043, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.485810862068966 ], [ 29.772966582278482, -1.486080344827586 ], [ 29.773236075949367, -1.486080344827586 ], [ 29.773236075949367, -1.485810862068966 ], [ 29.772966582278482, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32044, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.485810862068966 ], [ 29.773236075949367, -1.486080344827586 ], [ 29.773505569620252, -1.486080344827586 ], [ 29.773505569620252, -1.485810862068966 ], [ 29.773236075949367, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32045, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.485810862068966 ], [ 29.773505569620252, -1.486080344827586 ], [ 29.77377506329114, -1.486080344827586 ], [ 29.77377506329114, -1.485810862068966 ], [ 29.773505569620252, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32046, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.485810862068966 ], [ 29.77377506329114, -1.486080344827586 ], [ 29.774044556962025, -1.486080344827586 ], [ 29.774044556962025, -1.485810862068966 ], [ 29.77377506329114, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32047, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.485810862068966 ], [ 29.774044556962025, -1.486080344827586 ], [ 29.77431405063291, -1.486080344827586 ], [ 29.77431405063291, -1.485810862068966 ], [ 29.774044556962025, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32048, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.485810862068966 ], [ 29.77431405063291, -1.486080344827586 ], [ 29.774853037974683, -1.486080344827586 ], [ 29.774853037974683, -1.485810862068966 ], [ 29.77431405063291, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32049, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.485810862068966 ], [ 29.774853037974683, -1.486080344827586 ], [ 29.775122531645568, -1.486080344827586 ], [ 29.775122531645568, -1.485810862068966 ], [ 29.774853037974683, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32050, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.485810862068966 ], [ 29.775122531645568, -1.486080344827586 ], [ 29.775392025316457, -1.486080344827586 ], [ 29.775392025316457, -1.485810862068966 ], [ 29.775122531645568, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32051, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.485810862068966 ], [ 29.775392025316457, -1.486080344827586 ], [ 29.775661518987341, -1.486080344827586 ], [ 29.775661518987341, -1.485810862068966 ], [ 29.775392025316457, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32052, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.485810862068966 ], [ 29.775661518987341, -1.486080344827586 ], [ 29.775931012658226, -1.486080344827586 ], [ 29.775931012658226, -1.485810862068966 ], [ 29.775661518987341, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32053, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.485810862068966 ], [ 29.775931012658226, -1.486080344827586 ], [ 29.776200506329115, -1.486080344827586 ], [ 29.776200506329115, -1.485810862068966 ], [ 29.775931012658226, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32054, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.485810862068966 ], [ 29.776200506329115, -1.486080344827586 ], [ 29.77647, -1.486080344827586 ], [ 29.77647, -1.485810862068966 ], [ 29.776200506329115, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32055, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.485810862068966 ], [ 29.77647, -1.486080344827586 ], [ 29.776739493670885, -1.486080344827586 ], [ 29.776739493670885, -1.485810862068966 ], [ 29.77647, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32056, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.485810862068966 ], [ 29.776739493670885, -1.486080344827586 ], [ 29.777008987341773, -1.486080344827586 ], [ 29.777008987341773, -1.485810862068966 ], [ 29.776739493670885, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32057, "El": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.485810862068966 ], [ 29.777008987341773, -1.486080344827586 ], [ 29.777278481012658, -1.486080344827586 ], [ 29.777278481012658, -1.485810862068966 ], [ 29.777008987341773, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32058, "El": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.485810862068966 ], [ 29.777278481012658, -1.486349827586207 ], [ 29.777547974683543, -1.486349827586207 ], [ 29.777547974683543, -1.485810862068966 ], [ 29.777278481012658, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32059, "El": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.485810862068966 ], [ 29.777547974683543, -1.486080344827586 ], [ 29.777817468354431, -1.486080344827586 ], [ 29.777817468354431, -1.485810862068966 ], [ 29.777547974683543, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32060, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.485810862068966 ], [ 29.777817468354431, -1.486080344827586 ], [ 29.778086962025316, -1.486080344827586 ], [ 29.778086962025316, -1.485810862068966 ], [ 29.777817468354431, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32061, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.485810862068966 ], [ 29.778086962025316, -1.486349827586207 ], [ 29.778356455696201, -1.486349827586207 ], [ 29.778356455696201, -1.485810862068966 ], [ 29.778086962025316, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32062, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.485810862068966 ], [ 29.778356455696201, -1.486080344827586 ], [ 29.77862594936709, -1.486080344827586 ], [ 29.77862594936709, -1.485810862068966 ], [ 29.778356455696201, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32063, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.485810862068966 ], [ 29.77862594936709, -1.486080344827586 ], [ 29.778895443037975, -1.486080344827586 ], [ 29.778895443037975, -1.485810862068966 ], [ 29.77862594936709, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32064, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.485810862068966 ], [ 29.778895443037975, -1.486080344827586 ], [ 29.77916493670886, -1.486080344827586 ], [ 29.77916493670886, -1.485810862068966 ], [ 29.778895443037975, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32065, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.485810862068966 ], [ 29.779434430379748, -1.486080344827586 ], [ 29.779703924050633, -1.486080344827586 ], [ 29.779703924050633, -1.485810862068966 ], [ 29.779434430379748, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32066, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.485810862068966 ], [ 29.779703924050633, -1.486080344827586 ], [ 29.779973417721518, -1.486080344827586 ], [ 29.779973417721518, -1.485810862068966 ], [ 29.779703924050633, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32067, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.485810862068966 ], [ 29.779973417721518, -1.486080344827586 ], [ 29.780242911392406, -1.486080344827586 ], [ 29.780242911392406, -1.485810862068966 ], [ 29.779973417721518, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32068, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.485810862068966 ], [ 29.780242911392406, -1.486080344827586 ], [ 29.780512405063291, -1.486080344827586 ], [ 29.780512405063291, -1.485810862068966 ], [ 29.780242911392406, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32069, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.485810862068966 ], [ 29.780512405063291, -1.486080344827586 ], [ 29.780781898734176, -1.486080344827586 ], [ 29.780781898734176, -1.485810862068966 ], [ 29.780512405063291, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32070, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.485810862068966 ], [ 29.780781898734176, -1.486080344827586 ], [ 29.781051392405065, -1.486080344827586 ], [ 29.781051392405065, -1.485810862068966 ], [ 29.780781898734176, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32071, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.485810862068966 ], [ 29.781051392405065, -1.486080344827586 ], [ 29.78132088607595, -1.486080344827586 ], [ 29.78132088607595, -1.485810862068966 ], [ 29.781051392405065, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32072, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.485810862068966 ], [ 29.78132088607595, -1.486080344827586 ], [ 29.781590379746834, -1.486080344827586 ], [ 29.781590379746834, -1.485810862068966 ], [ 29.78132088607595, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32073, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.485810862068966 ], [ 29.781590379746834, -1.486080344827586 ], [ 29.781859873417723, -1.486080344827586 ], [ 29.781859873417723, -1.485810862068966 ], [ 29.781590379746834, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32074, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.485810862068966 ], [ 29.781859873417723, -1.486080344827586 ], [ 29.782129367088608, -1.486080344827586 ], [ 29.782129367088608, -1.485810862068966 ], [ 29.781859873417723, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32075, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.485810862068966 ], [ 29.782129367088608, -1.486080344827586 ], [ 29.782398860759493, -1.486080344827586 ], [ 29.782398860759493, -1.485810862068966 ], [ 29.782129367088608, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32076, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.485810862068966 ], [ 29.782398860759493, -1.486080344827586 ], [ 29.782668354430381, -1.486080344827586 ], [ 29.782668354430381, -1.485810862068966 ], [ 29.782398860759493, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32077, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.485810862068966 ], [ 29.782668354430381, -1.486080344827586 ], [ 29.782937848101266, -1.486080344827586 ], [ 29.782937848101266, -1.485810862068966 ], [ 29.782668354430381, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32078, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.485810862068966 ], [ 29.782937848101266, -1.486080344827586 ], [ 29.783207341772151, -1.486080344827586 ], [ 29.783207341772151, -1.485810862068966 ], [ 29.782937848101266, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32079, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.485810862068966 ], [ 29.783207341772151, -1.486080344827586 ], [ 29.783476835443039, -1.486080344827586 ], [ 29.783476835443039, -1.485810862068966 ], [ 29.783207341772151, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32080, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.485810862068966 ], [ 29.783476835443039, -1.486080344827586 ], [ 29.784015822784809, -1.486080344827586 ], [ 29.784015822784809, -1.485810862068966 ], [ 29.783476835443039, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32081, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.485810862068966 ], [ 29.784015822784809, -1.486080344827586 ], [ 29.784285316455698, -1.486080344827586 ], [ 29.784285316455698, -1.485810862068966 ], [ 29.784015822784809, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32082, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.485810862068966 ], [ 29.784285316455698, -1.486080344827586 ], [ 29.784554810126583, -1.486080344827586 ], [ 29.784554810126583, -1.485810862068966 ], [ 29.784285316455698, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32083, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.485810862068966 ], [ 29.784554810126583, -1.486080344827586 ], [ 29.784824303797468, -1.486080344827586 ], [ 29.784824303797468, -1.485810862068966 ], [ 29.784554810126583, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32084, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.485810862068966 ], [ 29.784824303797468, -1.486080344827586 ], [ 29.785093797468356, -1.486080344827586 ], [ 29.785093797468356, -1.485810862068966 ], [ 29.784824303797468, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32085, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.485810862068966 ], [ 29.785093797468356, -1.486080344827586 ], [ 29.785363291139241, -1.486080344827586 ], [ 29.785363291139241, -1.486349827586207 ], [ 29.785632784810126, -1.486349827586207 ], [ 29.785632784810126, -1.485810862068966 ], [ 29.785093797468356, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32086, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.485810862068966 ], [ 29.788058227848101, -1.486080344827586 ], [ 29.788866708860759, -1.486080344827586 ], [ 29.788866708860759, -1.485810862068966 ], [ 29.788058227848101, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32087, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.485810862068966 ], [ 29.788866708860759, -1.486080344827586 ], [ 29.789136202531644, -1.486080344827586 ], [ 29.789136202531644, -1.485810862068966 ], [ 29.788866708860759, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32088, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.485810862068966 ], [ 29.79991594936709, -1.486080344827586 ], [ 29.800185443037975, -1.486080344827586 ], [ 29.800185443037975, -1.485810862068966 ], [ 29.79991594936709, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32089, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.485810862068966 ], [ 29.800185443037975, -1.486080344827586 ], [ 29.80045493670886, -1.486080344827586 ], [ 29.80045493670886, -1.485810862068966 ], [ 29.800185443037975, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32090, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.485810862068966 ], [ 29.80045493670886, -1.486080344827586 ], [ 29.800724430379748, -1.486080344827586 ], [ 29.800724430379748, -1.485810862068966 ], [ 29.80045493670886, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32091, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.485810862068966 ], [ 29.800724430379748, -1.486349827586207 ], [ 29.800993924050633, -1.486349827586207 ], [ 29.800993924050633, -1.485810862068966 ], [ 29.800724430379748, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32092, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.485810862068966 ], [ 29.800993924050633, -1.486080344827586 ], [ 29.801263417721518, -1.486080344827586 ], [ 29.801263417721518, -1.485810862068966 ], [ 29.800993924050633, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32093, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.485810862068966 ], [ 29.801263417721518, -1.486080344827586 ], [ 29.801532911392407, -1.486080344827586 ], [ 29.801532911392407, -1.485810862068966 ], [ 29.801263417721518, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32094, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.485810862068966 ], [ 29.801532911392407, -1.486349827586207 ], [ 29.802071898734177, -1.486349827586207 ], [ 29.802071898734177, -1.486619310344828 ], [ 29.802341392405065, -1.486619310344828 ], [ 29.802341392405065, -1.486080344827586 ], [ 29.802071898734177, -1.486080344827586 ], [ 29.802071898734177, -1.485810862068966 ], [ 29.801532911392407, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32095, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.485810862068966 ], [ 29.802071898734177, -1.486080344827586 ], [ 29.802341392405065, -1.486080344827586 ], [ 29.802341392405065, -1.485810862068966 ], [ 29.802071898734177, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32096, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.486080344827586 ], [ 29.767037721518985, -1.486888793103448 ], [ 29.767307215189874, -1.486888793103448 ], [ 29.767307215189874, -1.486080344827586 ], [ 29.767037721518985, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32097, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.486080344827586 ], [ 29.767307215189874, -1.486888793103448 ], [ 29.767576708860759, -1.486888793103448 ], [ 29.767576708860759, -1.486080344827586 ], [ 29.767307215189874, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32098, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.486080344827586 ], [ 29.768115696202532, -1.486619310344828 ], [ 29.768385189873417, -1.486619310344828 ], [ 29.768385189873417, -1.486080344827586 ], [ 29.768115696202532, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32099, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.486080344827586 ], [ 29.76892417721519, -1.486349827586207 ], [ 29.769193670886075, -1.486349827586207 ], [ 29.769193670886075, -1.486080344827586 ], [ 29.76892417721519, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32100, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.486080344827586 ], [ 29.769193670886075, -1.486349827586207 ], [ 29.76946316455696, -1.486349827586207 ], [ 29.76946316455696, -1.486080344827586 ], [ 29.769193670886075, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32101, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.486080344827586 ], [ 29.76946316455696, -1.486349827586207 ], [ 29.769732658227849, -1.486349827586207 ], [ 29.769732658227849, -1.486080344827586 ], [ 29.76946316455696, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32102, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.486080344827586 ], [ 29.769732658227849, -1.486349827586207 ], [ 29.770002151898733, -1.486349827586207 ], [ 29.770002151898733, -1.486080344827586 ], [ 29.769732658227849, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32103, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.486080344827586 ], [ 29.770002151898733, -1.486349827586207 ], [ 29.770271645569618, -1.486349827586207 ], [ 29.770271645569618, -1.486080344827586 ], [ 29.770002151898733, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32104, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.486080344827586 ], [ 29.770271645569618, -1.486349827586207 ], [ 29.770541139240507, -1.486349827586207 ], [ 29.770541139240507, -1.486080344827586 ], [ 29.770271645569618, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32105, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.486080344827586 ], [ 29.770541139240507, -1.486349827586207 ], [ 29.770810632911392, -1.486349827586207 ], [ 29.770810632911392, -1.486080344827586 ], [ 29.770541139240507, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32106, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.486080344827586 ], [ 29.770810632911392, -1.486349827586207 ], [ 29.771080126582277, -1.486349827586207 ], [ 29.771080126582277, -1.486080344827586 ], [ 29.770810632911392, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32107, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.486080344827586 ], [ 29.771080126582277, -1.486349827586207 ], [ 29.771349620253165, -1.486349827586207 ], [ 29.771349620253165, -1.486080344827586 ], [ 29.771080126582277, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32108, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.486080344827586 ], [ 29.771349620253165, -1.486349827586207 ], [ 29.77161911392405, -1.486349827586207 ], [ 29.77161911392405, -1.486080344827586 ], [ 29.771349620253165, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32109, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.486080344827586 ], [ 29.77161911392405, -1.486349827586207 ], [ 29.771888607594935, -1.486349827586207 ], [ 29.771888607594935, -1.486080344827586 ], [ 29.77161911392405, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32110, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.486080344827586 ], [ 29.771888607594935, -1.486349827586207 ], [ 29.772158101265823, -1.486349827586207 ], [ 29.772158101265823, -1.486080344827586 ], [ 29.771888607594935, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32111, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.486080344827586 ], [ 29.772158101265823, -1.486349827586207 ], [ 29.772427594936708, -1.486349827586207 ], [ 29.772427594936708, -1.486080344827586 ], [ 29.772158101265823, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32112, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.486080344827586 ], [ 29.772427594936708, -1.486349827586207 ], [ 29.772697088607593, -1.486349827586207 ], [ 29.772697088607593, -1.486080344827586 ], [ 29.772427594936708, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32113, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.486080344827586 ], [ 29.772697088607593, -1.486349827586207 ], [ 29.772966582278482, -1.486349827586207 ], [ 29.772966582278482, -1.486080344827586 ], [ 29.772697088607593, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32114, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.486080344827586 ], [ 29.772966582278482, -1.486349827586207 ], [ 29.773236075949367, -1.486349827586207 ], [ 29.773236075949367, -1.486080344827586 ], [ 29.772966582278482, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32115, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.486080344827586 ], [ 29.773236075949367, -1.486349827586207 ], [ 29.773505569620252, -1.486349827586207 ], [ 29.773505569620252, -1.486080344827586 ], [ 29.773236075949367, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32116, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.486080344827586 ], [ 29.773505569620252, -1.486349827586207 ], [ 29.77377506329114, -1.486349827586207 ], [ 29.77377506329114, -1.486080344827586 ], [ 29.773505569620252, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32117, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.486080344827586 ], [ 29.77377506329114, -1.486349827586207 ], [ 29.774044556962025, -1.486349827586207 ], [ 29.774044556962025, -1.486080344827586 ], [ 29.77377506329114, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32118, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.486080344827586 ], [ 29.774044556962025, -1.486349827586207 ], [ 29.77431405063291, -1.486349827586207 ], [ 29.77431405063291, -1.486080344827586 ], [ 29.774044556962025, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32119, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.486080344827586 ], [ 29.77431405063291, -1.486349827586207 ], [ 29.774853037974683, -1.486349827586207 ], [ 29.774853037974683, -1.486080344827586 ], [ 29.77431405063291, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32120, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.486080344827586 ], [ 29.774853037974683, -1.486349827586207 ], [ 29.775122531645568, -1.486349827586207 ], [ 29.775122531645568, -1.486080344827586 ], [ 29.774853037974683, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32121, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.486080344827586 ], [ 29.775122531645568, -1.486349827586207 ], [ 29.775392025316457, -1.486349827586207 ], [ 29.775392025316457, -1.486080344827586 ], [ 29.775122531645568, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32122, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.486080344827586 ], [ 29.775392025316457, -1.486349827586207 ], [ 29.775661518987341, -1.486349827586207 ], [ 29.775661518987341, -1.486080344827586 ], [ 29.775392025316457, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32123, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.486080344827586 ], [ 29.775661518987341, -1.486349827586207 ], [ 29.775931012658226, -1.486349827586207 ], [ 29.775931012658226, -1.486080344827586 ], [ 29.775661518987341, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32124, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.486080344827586 ], [ 29.775931012658226, -1.486349827586207 ], [ 29.776200506329115, -1.486349827586207 ], [ 29.776200506329115, -1.486080344827586 ], [ 29.775931012658226, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32125, "El": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.486080344827586 ], [ 29.776200506329115, -1.486349827586207 ], [ 29.77647, -1.486349827586207 ], [ 29.77647, -1.486080344827586 ], [ 29.776200506329115, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32126, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.486080344827586 ], [ 29.77647, -1.486349827586207 ], [ 29.776739493670885, -1.486349827586207 ], [ 29.776739493670885, -1.486080344827586 ], [ 29.77647, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32127, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.486080344827586 ], [ 29.776739493670885, -1.486349827586207 ], [ 29.777008987341773, -1.486349827586207 ], [ 29.777008987341773, -1.486080344827586 ], [ 29.776739493670885, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32128, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.486080344827586 ], [ 29.777008987341773, -1.486349827586207 ], [ 29.777278481012658, -1.486349827586207 ], [ 29.777278481012658, -1.486080344827586 ], [ 29.777008987341773, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32129, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.486080344827586 ], [ 29.777547974683543, -1.486349827586207 ], [ 29.777817468354431, -1.486349827586207 ], [ 29.777817468354431, -1.486080344827586 ], [ 29.777547974683543, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32130, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.486080344827586 ], [ 29.777817468354431, -1.486349827586207 ], [ 29.778086962025316, -1.486349827586207 ], [ 29.778086962025316, -1.486080344827586 ], [ 29.777817468354431, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32131, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.486080344827586 ], [ 29.778356455696201, -1.486349827586207 ], [ 29.77862594936709, -1.486349827586207 ], [ 29.77862594936709, -1.486080344827586 ], [ 29.778356455696201, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32132, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.486080344827586 ], [ 29.77862594936709, -1.486349827586207 ], [ 29.778895443037975, -1.486349827586207 ], [ 29.778895443037975, -1.486080344827586 ], [ 29.77862594936709, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32133, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.486080344827586 ], [ 29.778895443037975, -1.486349827586207 ], [ 29.77916493670886, -1.486349827586207 ], [ 29.77916493670886, -1.486080344827586 ], [ 29.778895443037975, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32134, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.486080344827586 ], [ 29.77916493670886, -1.486349827586207 ], [ 29.779434430379748, -1.486349827586207 ], [ 29.779434430379748, -1.486080344827586 ], [ 29.77916493670886, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32135, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.486080344827586 ], [ 29.779434430379748, -1.486349827586207 ], [ 29.779703924050633, -1.486349827586207 ], [ 29.779703924050633, -1.486080344827586 ], [ 29.779434430379748, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32136, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.486080344827586 ], [ 29.779703924050633, -1.486349827586207 ], [ 29.779973417721518, -1.486349827586207 ], [ 29.779973417721518, -1.486080344827586 ], [ 29.779703924050633, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32137, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.486080344827586 ], [ 29.779973417721518, -1.486349827586207 ], [ 29.780242911392406, -1.486349827586207 ], [ 29.780242911392406, -1.486080344827586 ], [ 29.779973417721518, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32138, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.486080344827586 ], [ 29.780242911392406, -1.486349827586207 ], [ 29.780512405063291, -1.486349827586207 ], [ 29.780512405063291, -1.486080344827586 ], [ 29.780242911392406, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32139, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.486080344827586 ], [ 29.780512405063291, -1.486349827586207 ], [ 29.780781898734176, -1.486349827586207 ], [ 29.780781898734176, -1.486080344827586 ], [ 29.780512405063291, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32140, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.486080344827586 ], [ 29.780781898734176, -1.486349827586207 ], [ 29.781051392405065, -1.486349827586207 ], [ 29.781051392405065, -1.486080344827586 ], [ 29.780781898734176, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32141, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.486080344827586 ], [ 29.781051392405065, -1.486349827586207 ], [ 29.78132088607595, -1.486349827586207 ], [ 29.78132088607595, -1.486080344827586 ], [ 29.781051392405065, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32142, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.486080344827586 ], [ 29.78132088607595, -1.486349827586207 ], [ 29.781590379746834, -1.486349827586207 ], [ 29.781590379746834, -1.486080344827586 ], [ 29.78132088607595, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32143, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.486080344827586 ], [ 29.781590379746834, -1.486349827586207 ], [ 29.781859873417723, -1.486349827586207 ], [ 29.781859873417723, -1.486080344827586 ], [ 29.781590379746834, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32144, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.486080344827586 ], [ 29.781859873417723, -1.486349827586207 ], [ 29.782129367088608, -1.486349827586207 ], [ 29.782129367088608, -1.486080344827586 ], [ 29.781859873417723, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32145, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.486080344827586 ], [ 29.782129367088608, -1.486349827586207 ], [ 29.782398860759493, -1.486349827586207 ], [ 29.782398860759493, -1.486080344827586 ], [ 29.782129367088608, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32146, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.486080344827586 ], [ 29.782398860759493, -1.486349827586207 ], [ 29.782668354430381, -1.486349827586207 ], [ 29.782668354430381, -1.486080344827586 ], [ 29.782398860759493, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32147, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.486080344827586 ], [ 29.782668354430381, -1.486349827586207 ], [ 29.782937848101266, -1.486349827586207 ], [ 29.782937848101266, -1.486080344827586 ], [ 29.782668354430381, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32148, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.486080344827586 ], [ 29.782937848101266, -1.486349827586207 ], [ 29.783207341772151, -1.486349827586207 ], [ 29.783207341772151, -1.486080344827586 ], [ 29.782937848101266, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32149, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.486080344827586 ], [ 29.783207341772151, -1.486349827586207 ], [ 29.783476835443039, -1.486349827586207 ], [ 29.783476835443039, -1.486080344827586 ], [ 29.783207341772151, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32150, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.486080344827586 ], [ 29.783476835443039, -1.486349827586207 ], [ 29.784015822784809, -1.486349827586207 ], [ 29.784015822784809, -1.486080344827586 ], [ 29.783476835443039, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32151, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.486080344827586 ], [ 29.784015822784809, -1.486349827586207 ], [ 29.784285316455698, -1.486349827586207 ], [ 29.784285316455698, -1.486080344827586 ], [ 29.784015822784809, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32152, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.486080344827586 ], [ 29.784285316455698, -1.486349827586207 ], [ 29.784554810126583, -1.486349827586207 ], [ 29.784554810126583, -1.486080344827586 ], [ 29.784285316455698, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32153, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.486080344827586 ], [ 29.784554810126583, -1.486349827586207 ], [ 29.784824303797468, -1.486349827586207 ], [ 29.784824303797468, -1.486080344827586 ], [ 29.784554810126583, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32154, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.486080344827586 ], [ 29.784824303797468, -1.486349827586207 ], [ 29.785093797468356, -1.486349827586207 ], [ 29.785093797468356, -1.486080344827586 ], [ 29.784824303797468, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32155, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.486080344827586 ], [ 29.785093797468356, -1.486349827586207 ], [ 29.785363291139241, -1.486349827586207 ], [ 29.785363291139241, -1.486080344827586 ], [ 29.785093797468356, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32156, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.486080344827586 ], [ 29.785632784810126, -1.486619310344828 ], [ 29.785902278481014, -1.486619310344828 ], [ 29.785902278481014, -1.486080344827586 ], [ 29.785632784810126, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32157, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.486080344827586 ], [ 29.786441265822784, -1.486349827586207 ], [ 29.786710759493673, -1.486349827586207 ], [ 29.786710759493673, -1.486080344827586 ], [ 29.786441265822784, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32158, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.486080344827586 ], [ 29.786710759493673, -1.486349827586207 ], [ 29.786980253164558, -1.486349827586207 ], [ 29.786980253164558, -1.486080344827586 ], [ 29.786710759493673, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32159, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.486080344827586 ], [ 29.786980253164558, -1.486349827586207 ], [ 29.787249746835442, -1.486349827586207 ], [ 29.787249746835442, -1.486080344827586 ], [ 29.786980253164558, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32160, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.486080344827586 ], [ 29.787249746835442, -1.486349827586207 ], [ 29.788058227848101, -1.486349827586207 ], [ 29.788058227848101, -1.486080344827586 ], [ 29.787249746835442, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32161, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.486080344827586 ], [ 29.788058227848101, -1.486349827586207 ], [ 29.788327721518986, -1.486349827586207 ], [ 29.788327721518986, -1.486080344827586 ], [ 29.788058227848101, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32162, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.486080344827586 ], [ 29.788327721518986, -1.486349827586207 ], [ 29.789136202531644, -1.486349827586207 ], [ 29.789136202531644, -1.486080344827586 ], [ 29.788327721518986, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32163, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.486080344827586 ], [ 29.799376962025317, -1.486349827586207 ], [ 29.799646455696202, -1.486349827586207 ], [ 29.799646455696202, -1.486080344827586 ], [ 29.799376962025317, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32164, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.486080344827586 ], [ 29.799646455696202, -1.486349827586207 ], [ 29.79991594936709, -1.486349827586207 ], [ 29.79991594936709, -1.486080344827586 ], [ 29.799646455696202, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32165, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.486080344827586 ], [ 29.79991594936709, -1.486349827586207 ], [ 29.800185443037975, -1.486349827586207 ], [ 29.800185443037975, -1.486080344827586 ], [ 29.79991594936709, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32166, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.486080344827586 ], [ 29.800185443037975, -1.486888793103448 ], [ 29.80045493670886, -1.486888793103448 ], [ 29.80045493670886, -1.486080344827586 ], [ 29.800185443037975, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32167, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.486080344827586 ], [ 29.80045493670886, -1.486888793103448 ], [ 29.800724430379748, -1.486888793103448 ], [ 29.800724430379748, -1.486080344827586 ], [ 29.80045493670886, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32168, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.486080344827586 ], [ 29.800993924050633, -1.486619310344828 ], [ 29.801263417721518, -1.486619310344828 ], [ 29.801263417721518, -1.486080344827586 ], [ 29.800993924050633, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32169, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.486080344827586 ], [ 29.801263417721518, -1.486619310344828 ], [ 29.801532911392407, -1.486619310344828 ], [ 29.801532911392407, -1.486080344827586 ], [ 29.801263417721518, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32170, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.486349827586207 ], [ 29.768385189873417, -1.486619310344828 ], [ 29.768654683544302, -1.486619310344828 ], [ 29.768654683544302, -1.486349827586207 ], [ 29.768385189873417, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32171, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.486349827586207 ], [ 29.768654683544302, -1.486619310344828 ], [ 29.76892417721519, -1.486619310344828 ], [ 29.76892417721519, -1.486349827586207 ], [ 29.768654683544302, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32172, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.486349827586207 ], [ 29.76892417721519, -1.486619310344828 ], [ 29.769193670886075, -1.486619310344828 ], [ 29.769193670886075, -1.486349827586207 ], [ 29.76892417721519, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32173, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.486349827586207 ], [ 29.769193670886075, -1.486619310344828 ], [ 29.76946316455696, -1.486619310344828 ], [ 29.76946316455696, -1.486349827586207 ], [ 29.769193670886075, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32174, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.486349827586207 ], [ 29.76946316455696, -1.486619310344828 ], [ 29.769732658227849, -1.486619310344828 ], [ 29.769732658227849, -1.486349827586207 ], [ 29.76946316455696, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32175, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.486349827586207 ], [ 29.769732658227849, -1.486619310344828 ], [ 29.770002151898733, -1.486619310344828 ], [ 29.770002151898733, -1.486349827586207 ], [ 29.769732658227849, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32176, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.486349827586207 ], [ 29.770002151898733, -1.486619310344828 ], [ 29.770271645569618, -1.486619310344828 ], [ 29.770271645569618, -1.486349827586207 ], [ 29.770002151898733, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32177, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.486349827586207 ], [ 29.770271645569618, -1.486619310344828 ], [ 29.770541139240507, -1.486619310344828 ], [ 29.770541139240507, -1.486349827586207 ], [ 29.770271645569618, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32178, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.486349827586207 ], [ 29.770541139240507, -1.486619310344828 ], [ 29.770810632911392, -1.486619310344828 ], [ 29.770810632911392, -1.486349827586207 ], [ 29.770541139240507, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32179, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.486349827586207 ], [ 29.770810632911392, -1.486619310344828 ], [ 29.771080126582277, -1.486619310344828 ], [ 29.771080126582277, -1.486349827586207 ], [ 29.770810632911392, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32180, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.486349827586207 ], [ 29.771080126582277, -1.486619310344828 ], [ 29.771349620253165, -1.486619310344828 ], [ 29.771349620253165, -1.486349827586207 ], [ 29.771080126582277, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32181, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.486349827586207 ], [ 29.771349620253165, -1.486619310344828 ], [ 29.77161911392405, -1.486619310344828 ], [ 29.77161911392405, -1.486349827586207 ], [ 29.771349620253165, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32182, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.486349827586207 ], [ 29.77161911392405, -1.486619310344828 ], [ 29.771888607594935, -1.486619310344828 ], [ 29.771888607594935, -1.486349827586207 ], [ 29.77161911392405, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32183, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.486349827586207 ], [ 29.771888607594935, -1.486619310344828 ], [ 29.772158101265823, -1.486619310344828 ], [ 29.772158101265823, -1.486349827586207 ], [ 29.771888607594935, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32184, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.486349827586207 ], [ 29.772158101265823, -1.486619310344828 ], [ 29.772427594936708, -1.486619310344828 ], [ 29.772427594936708, -1.486349827586207 ], [ 29.772158101265823, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32185, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.486349827586207 ], [ 29.772427594936708, -1.486619310344828 ], [ 29.772697088607593, -1.486619310344828 ], [ 29.772697088607593, -1.486349827586207 ], [ 29.772427594936708, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32186, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.486349827586207 ], [ 29.772697088607593, -1.486619310344828 ], [ 29.772966582278482, -1.486619310344828 ], [ 29.772966582278482, -1.486349827586207 ], [ 29.772697088607593, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32187, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.486349827586207 ], [ 29.772966582278482, -1.486619310344828 ], [ 29.773236075949367, -1.486619310344828 ], [ 29.773236075949367, -1.486349827586207 ], [ 29.772966582278482, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32188, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.486349827586207 ], [ 29.773236075949367, -1.486619310344828 ], [ 29.773505569620252, -1.486619310344828 ], [ 29.773505569620252, -1.486349827586207 ], [ 29.773236075949367, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32189, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.486349827586207 ], [ 29.773505569620252, -1.486619310344828 ], [ 29.77377506329114, -1.486619310344828 ], [ 29.77377506329114, -1.486349827586207 ], [ 29.773505569620252, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32190, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.486349827586207 ], [ 29.77377506329114, -1.486619310344828 ], [ 29.774044556962025, -1.486619310344828 ], [ 29.774044556962025, -1.486349827586207 ], [ 29.77377506329114, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32191, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.486349827586207 ], [ 29.774044556962025, -1.486619310344828 ], [ 29.77431405063291, -1.486619310344828 ], [ 29.77431405063291, -1.486349827586207 ], [ 29.774044556962025, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32192, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.486349827586207 ], [ 29.77431405063291, -1.486619310344828 ], [ 29.774853037974683, -1.486619310344828 ], [ 29.774853037974683, -1.486349827586207 ], [ 29.77431405063291, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32193, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.486349827586207 ], [ 29.774853037974683, -1.486619310344828 ], [ 29.775122531645568, -1.486619310344828 ], [ 29.775122531645568, -1.486349827586207 ], [ 29.774853037974683, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32194, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.486349827586207 ], [ 29.775122531645568, -1.486619310344828 ], [ 29.775392025316457, -1.486619310344828 ], [ 29.775392025316457, -1.486349827586207 ], [ 29.775122531645568, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32195, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.486349827586207 ], [ 29.775392025316457, -1.486619310344828 ], [ 29.775661518987341, -1.486619310344828 ], [ 29.775661518987341, -1.486349827586207 ], [ 29.775392025316457, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32196, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.486349827586207 ], [ 29.775661518987341, -1.486619310344828 ], [ 29.775931012658226, -1.486619310344828 ], [ 29.775931012658226, -1.486349827586207 ], [ 29.775661518987341, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32197, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.486349827586207 ], [ 29.775931012658226, -1.486619310344828 ], [ 29.776200506329115, -1.486619310344828 ], [ 29.776200506329115, -1.486349827586207 ], [ 29.775931012658226, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32198, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.486349827586207 ], [ 29.776200506329115, -1.486619310344828 ], [ 29.77647, -1.486619310344828 ], [ 29.77647, -1.486349827586207 ], [ 29.776200506329115, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32199, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.486349827586207 ], [ 29.77647, -1.486619310344828 ], [ 29.776739493670885, -1.486619310344828 ], [ 29.776739493670885, -1.486349827586207 ], [ 29.77647, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32200, "El": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.486349827586207 ], [ 29.776739493670885, -1.486619310344828 ], [ 29.777008987341773, -1.486619310344828 ], [ 29.777008987341773, -1.486349827586207 ], [ 29.776739493670885, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32201, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.486349827586207 ], [ 29.777008987341773, -1.486619310344828 ], [ 29.777278481012658, -1.486619310344828 ], [ 29.777278481012658, -1.486349827586207 ], [ 29.777008987341773, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32202, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.486349827586207 ], [ 29.777278481012658, -1.486619310344828 ], [ 29.777547974683543, -1.486619310344828 ], [ 29.777547974683543, -1.486349827586207 ], [ 29.777278481012658, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32203, "El": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.486349827586207 ], [ 29.777547974683543, -1.486619310344828 ], [ 29.777817468354431, -1.486619310344828 ], [ 29.777817468354431, -1.486349827586207 ], [ 29.777547974683543, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32204, "El": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.486349827586207 ], [ 29.777817468354431, -1.486619310344828 ], [ 29.778086962025316, -1.486619310344828 ], [ 29.778086962025316, -1.486349827586207 ], [ 29.777817468354431, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32205, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.486349827586207 ], [ 29.778086962025316, -1.486888793103448 ], [ 29.778356455696201, -1.486888793103448 ], [ 29.778356455696201, -1.486349827586207 ], [ 29.778086962025316, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32206, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.486349827586207 ], [ 29.778356455696201, -1.486619310344828 ], [ 29.77862594936709, -1.486619310344828 ], [ 29.77862594936709, -1.486349827586207 ], [ 29.778356455696201, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32207, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.486349827586207 ], [ 29.77862594936709, -1.486619310344828 ], [ 29.778895443037975, -1.486619310344828 ], [ 29.778895443037975, -1.486349827586207 ], [ 29.77862594936709, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32208, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.486349827586207 ], [ 29.778895443037975, -1.486619310344828 ], [ 29.77916493670886, -1.486619310344828 ], [ 29.77916493670886, -1.486349827586207 ], [ 29.778895443037975, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32209, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.486349827586207 ], [ 29.77916493670886, -1.486619310344828 ], [ 29.779434430379748, -1.486619310344828 ], [ 29.779434430379748, -1.486349827586207 ], [ 29.77916493670886, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32210, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.486349827586207 ], [ 29.779434430379748, -1.486619310344828 ], [ 29.779703924050633, -1.486619310344828 ], [ 29.779703924050633, -1.486349827586207 ], [ 29.779434430379748, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32211, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.486349827586207 ], [ 29.779703924050633, -1.486619310344828 ], [ 29.779973417721518, -1.486619310344828 ], [ 29.779973417721518, -1.486349827586207 ], [ 29.779703924050633, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32212, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.486349827586207 ], [ 29.779973417721518, -1.486619310344828 ], [ 29.780242911392406, -1.486619310344828 ], [ 29.780242911392406, -1.486349827586207 ], [ 29.779973417721518, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32213, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.486349827586207 ], [ 29.780242911392406, -1.486619310344828 ], [ 29.780512405063291, -1.486619310344828 ], [ 29.780512405063291, -1.486349827586207 ], [ 29.780242911392406, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32214, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.486349827586207 ], [ 29.780512405063291, -1.486619310344828 ], [ 29.780781898734176, -1.486619310344828 ], [ 29.780781898734176, -1.486349827586207 ], [ 29.780512405063291, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32215, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.486349827586207 ], [ 29.780781898734176, -1.486619310344828 ], [ 29.781051392405065, -1.486619310344828 ], [ 29.781051392405065, -1.486349827586207 ], [ 29.780781898734176, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32216, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.486349827586207 ], [ 29.781051392405065, -1.486619310344828 ], [ 29.78132088607595, -1.486619310344828 ], [ 29.78132088607595, -1.486349827586207 ], [ 29.781051392405065, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32217, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.486349827586207 ], [ 29.78132088607595, -1.486619310344828 ], [ 29.781590379746834, -1.486619310344828 ], [ 29.781590379746834, -1.486349827586207 ], [ 29.78132088607595, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32218, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.486349827586207 ], [ 29.781590379746834, -1.486619310344828 ], [ 29.781859873417723, -1.486619310344828 ], [ 29.781859873417723, -1.486349827586207 ], [ 29.781590379746834, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32219, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.486349827586207 ], [ 29.781859873417723, -1.486619310344828 ], [ 29.782129367088608, -1.486619310344828 ], [ 29.782129367088608, -1.486349827586207 ], [ 29.781859873417723, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32220, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.486349827586207 ], [ 29.782129367088608, -1.486619310344828 ], [ 29.782398860759493, -1.486619310344828 ], [ 29.782398860759493, -1.486349827586207 ], [ 29.782129367088608, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32221, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.486349827586207 ], [ 29.782398860759493, -1.486619310344828 ], [ 29.782668354430381, -1.486619310344828 ], [ 29.782668354430381, -1.486349827586207 ], [ 29.782398860759493, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32222, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.486349827586207 ], [ 29.782668354430381, -1.486619310344828 ], [ 29.782937848101266, -1.486619310344828 ], [ 29.782937848101266, -1.486349827586207 ], [ 29.782668354430381, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32223, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.486349827586207 ], [ 29.782937848101266, -1.486619310344828 ], [ 29.783207341772151, -1.486619310344828 ], [ 29.783207341772151, -1.486349827586207 ], [ 29.782937848101266, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32224, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.486349827586207 ], [ 29.783207341772151, -1.486619310344828 ], [ 29.783476835443039, -1.486619310344828 ], [ 29.783476835443039, -1.486349827586207 ], [ 29.783207341772151, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32225, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.486349827586207 ], [ 29.783476835443039, -1.486619310344828 ], [ 29.784015822784809, -1.486619310344828 ], [ 29.784015822784809, -1.486349827586207 ], [ 29.783476835443039, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32226, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.486349827586207 ], [ 29.784015822784809, -1.486619310344828 ], [ 29.784285316455698, -1.486619310344828 ], [ 29.784285316455698, -1.486349827586207 ], [ 29.784015822784809, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32227, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.486349827586207 ], [ 29.784285316455698, -1.486619310344828 ], [ 29.784554810126583, -1.486619310344828 ], [ 29.784554810126583, -1.486349827586207 ], [ 29.784285316455698, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32228, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.486349827586207 ], [ 29.784554810126583, -1.486619310344828 ], [ 29.784824303797468, -1.486619310344828 ], [ 29.784824303797468, -1.486349827586207 ], [ 29.784554810126583, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32229, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.486349827586207 ], [ 29.784824303797468, -1.486619310344828 ], [ 29.785093797468356, -1.486619310344828 ], [ 29.785093797468356, -1.486349827586207 ], [ 29.784824303797468, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32230, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.486349827586207 ], [ 29.785093797468356, -1.486619310344828 ], [ 29.785363291139241, -1.486619310344828 ], [ 29.785363291139241, -1.486349827586207 ], [ 29.785093797468356, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32231, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.486349827586207 ], [ 29.785363291139241, -1.486619310344828 ], [ 29.785632784810126, -1.486619310344828 ], [ 29.785632784810126, -1.486349827586207 ], [ 29.785363291139241, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32232, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.486349827586207 ], [ 29.785902278481014, -1.486619310344828 ], [ 29.786171772151899, -1.486619310344828 ], [ 29.786171772151899, -1.486349827586207 ], [ 29.785902278481014, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32233, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.486349827586207 ], [ 29.786171772151899, -1.486619310344828 ], [ 29.786441265822784, -1.486619310344828 ], [ 29.786441265822784, -1.486349827586207 ], [ 29.786171772151899, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32234, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.486349827586207 ], [ 29.786441265822784, -1.486619310344828 ], [ 29.786710759493673, -1.486619310344828 ], [ 29.786710759493673, -1.486349827586207 ], [ 29.786441265822784, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32235, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.486349827586207 ], [ 29.786710759493673, -1.486619310344828 ], [ 29.786980253164558, -1.486619310344828 ], [ 29.786980253164558, -1.486349827586207 ], [ 29.786710759493673, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32236, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.486349827586207 ], [ 29.786980253164558, -1.486619310344828 ], [ 29.787249746835442, -1.486619310344828 ], [ 29.787249746835442, -1.486349827586207 ], [ 29.786980253164558, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32237, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.486349827586207 ], [ 29.787249746835442, -1.486619310344828 ], [ 29.788058227848101, -1.486619310344828 ], [ 29.788058227848101, -1.486349827586207 ], [ 29.787249746835442, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32238, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.486349827586207 ], [ 29.788058227848101, -1.486619310344828 ], [ 29.788327721518986, -1.486619310344828 ], [ 29.788327721518986, -1.486349827586207 ], [ 29.788058227848101, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32239, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.486349827586207 ], [ 29.788327721518986, -1.486619310344828 ], [ 29.788866708860759, -1.486619310344828 ], [ 29.788866708860759, -1.486349827586207 ], [ 29.788327721518986, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32240, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.486349827586207 ], [ 29.788866708860759, -1.486619310344828 ], [ 29.789136202531644, -1.486619310344828 ], [ 29.789136202531644, -1.486349827586207 ], [ 29.788866708860759, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32241, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.486349827586207 ], [ 29.789136202531644, -1.486619310344828 ], [ 29.789405696202532, -1.486619310344828 ], [ 29.789405696202532, -1.486349827586207 ], [ 29.789136202531644, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32242, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.486349827586207 ], [ 29.800724430379748, -1.486619310344828 ], [ 29.800993924050633, -1.486619310344828 ], [ 29.800993924050633, -1.486349827586207 ], [ 29.800724430379748, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32243, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.486349827586207 ], [ 29.801532911392407, -1.486619310344828 ], [ 29.802071898734177, -1.486619310344828 ], [ 29.802071898734177, -1.486349827586207 ], [ 29.801532911392407, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32244, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.486619310344828 ], [ 29.767576708860759, -1.486888793103448 ], [ 29.767846202531643, -1.486888793103448 ], [ 29.767846202531643, -1.486619310344828 ], [ 29.767576708860759, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32245, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.486619310344828 ], [ 29.767846202531643, -1.486888793103448 ], [ 29.768115696202532, -1.486888793103448 ], [ 29.768115696202532, -1.486619310344828 ], [ 29.767846202531643, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32246, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.486619310344828 ], [ 29.768115696202532, -1.486888793103448 ], [ 29.768385189873417, -1.486888793103448 ], [ 29.768385189873417, -1.486619310344828 ], [ 29.768115696202532, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32247, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.486619310344828 ], [ 29.768385189873417, -1.486888793103448 ], [ 29.768654683544302, -1.486888793103448 ], [ 29.768654683544302, -1.486619310344828 ], [ 29.768385189873417, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32248, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.486619310344828 ], [ 29.768654683544302, -1.486888793103448 ], [ 29.76892417721519, -1.486888793103448 ], [ 29.76892417721519, -1.486619310344828 ], [ 29.768654683544302, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32249, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.486619310344828 ], [ 29.76892417721519, -1.486888793103448 ], [ 29.769193670886075, -1.486888793103448 ], [ 29.769193670886075, -1.486619310344828 ], [ 29.76892417721519, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32250, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.486619310344828 ], [ 29.769193670886075, -1.486888793103448 ], [ 29.76946316455696, -1.486888793103448 ], [ 29.76946316455696, -1.486619310344828 ], [ 29.769193670886075, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32251, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.486619310344828 ], [ 29.76946316455696, -1.486888793103448 ], [ 29.769732658227849, -1.486888793103448 ], [ 29.769732658227849, -1.486619310344828 ], [ 29.76946316455696, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32252, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.486619310344828 ], [ 29.769732658227849, -1.486888793103448 ], [ 29.770002151898733, -1.486888793103448 ], [ 29.770002151898733, -1.486619310344828 ], [ 29.769732658227849, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32253, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.486619310344828 ], [ 29.770002151898733, -1.486888793103448 ], [ 29.770271645569618, -1.486888793103448 ], [ 29.770271645569618, -1.486619310344828 ], [ 29.770002151898733, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32254, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.486619310344828 ], [ 29.770271645569618, -1.486888793103448 ], [ 29.770541139240507, -1.486888793103448 ], [ 29.770541139240507, -1.486619310344828 ], [ 29.770271645569618, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32255, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.486619310344828 ], [ 29.770541139240507, -1.486888793103448 ], [ 29.770810632911392, -1.486888793103448 ], [ 29.770810632911392, -1.486619310344828 ], [ 29.770541139240507, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32256, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.486619310344828 ], [ 29.770810632911392, -1.486888793103448 ], [ 29.771080126582277, -1.486888793103448 ], [ 29.771080126582277, -1.486619310344828 ], [ 29.770810632911392, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32257, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.486619310344828 ], [ 29.771080126582277, -1.486888793103448 ], [ 29.771349620253165, -1.486888793103448 ], [ 29.771349620253165, -1.486619310344828 ], [ 29.771080126582277, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32258, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.486619310344828 ], [ 29.771349620253165, -1.486888793103448 ], [ 29.77161911392405, -1.486888793103448 ], [ 29.77161911392405, -1.486619310344828 ], [ 29.771349620253165, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32259, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.486619310344828 ], [ 29.77161911392405, -1.486888793103448 ], [ 29.771888607594935, -1.486888793103448 ], [ 29.771888607594935, -1.486619310344828 ], [ 29.77161911392405, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32260, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.486619310344828 ], [ 29.771888607594935, -1.486888793103448 ], [ 29.772158101265823, -1.486888793103448 ], [ 29.772158101265823, -1.486619310344828 ], [ 29.771888607594935, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32261, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.486619310344828 ], [ 29.772158101265823, -1.486888793103448 ], [ 29.772427594936708, -1.486888793103448 ], [ 29.772427594936708, -1.486619310344828 ], [ 29.772158101265823, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32262, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.486619310344828 ], [ 29.772427594936708, -1.486888793103448 ], [ 29.772697088607593, -1.486888793103448 ], [ 29.772697088607593, -1.486619310344828 ], [ 29.772427594936708, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32263, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.486619310344828 ], [ 29.772697088607593, -1.486888793103448 ], [ 29.772966582278482, -1.486888793103448 ], [ 29.772966582278482, -1.486619310344828 ], [ 29.772697088607593, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32264, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.486619310344828 ], [ 29.772966582278482, -1.486888793103448 ], [ 29.773236075949367, -1.486888793103448 ], [ 29.773236075949367, -1.486619310344828 ], [ 29.772966582278482, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32265, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.486619310344828 ], [ 29.773236075949367, -1.486888793103448 ], [ 29.773505569620252, -1.486888793103448 ], [ 29.773505569620252, -1.486619310344828 ], [ 29.773236075949367, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32266, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.486619310344828 ], [ 29.773505569620252, -1.486888793103448 ], [ 29.77377506329114, -1.486888793103448 ], [ 29.77377506329114, -1.486619310344828 ], [ 29.773505569620252, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32267, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.486619310344828 ], [ 29.77377506329114, -1.486888793103448 ], [ 29.774044556962025, -1.486888793103448 ], [ 29.774044556962025, -1.486619310344828 ], [ 29.77377506329114, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32268, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.486619310344828 ], [ 29.774044556962025, -1.486888793103448 ], [ 29.77431405063291, -1.486888793103448 ], [ 29.77431405063291, -1.486619310344828 ], [ 29.774044556962025, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32269, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.486619310344828 ], [ 29.77431405063291, -1.486888793103448 ], [ 29.774853037974683, -1.486888793103448 ], [ 29.774853037974683, -1.486619310344828 ], [ 29.77431405063291, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32270, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.486619310344828 ], [ 29.774853037974683, -1.486888793103448 ], [ 29.775122531645568, -1.486888793103448 ], [ 29.775122531645568, -1.486619310344828 ], [ 29.774853037974683, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32271, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.486619310344828 ], [ 29.775122531645568, -1.486888793103448 ], [ 29.775392025316457, -1.486888793103448 ], [ 29.775392025316457, -1.486619310344828 ], [ 29.775122531645568, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32272, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.486619310344828 ], [ 29.775392025316457, -1.486888793103448 ], [ 29.775661518987341, -1.486888793103448 ], [ 29.775661518987341, -1.486619310344828 ], [ 29.775392025316457, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32273, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.486619310344828 ], [ 29.775661518987341, -1.486888793103448 ], [ 29.775931012658226, -1.486888793103448 ], [ 29.775931012658226, -1.486619310344828 ], [ 29.775661518987341, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32274, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.486619310344828 ], [ 29.775931012658226, -1.486888793103448 ], [ 29.776200506329115, -1.486888793103448 ], [ 29.776200506329115, -1.486619310344828 ], [ 29.775931012658226, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32275, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.486619310344828 ], [ 29.776200506329115, -1.486888793103448 ], [ 29.77647, -1.486888793103448 ], [ 29.77647, -1.486619310344828 ], [ 29.776200506329115, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32276, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.486619310344828 ], [ 29.77647, -1.486888793103448 ], [ 29.776739493670885, -1.486888793103448 ], [ 29.776739493670885, -1.486619310344828 ], [ 29.77647, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32277, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.486619310344828 ], [ 29.776739493670885, -1.486888793103448 ], [ 29.777008987341773, -1.486888793103448 ], [ 29.777008987341773, -1.486619310344828 ], [ 29.776739493670885, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32278, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.486619310344828 ], [ 29.777008987341773, -1.486888793103448 ], [ 29.777278481012658, -1.486888793103448 ], [ 29.777278481012658, -1.486619310344828 ], [ 29.777008987341773, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32279, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.486619310344828 ], [ 29.777278481012658, -1.486888793103448 ], [ 29.777547974683543, -1.486888793103448 ], [ 29.777547974683543, -1.486619310344828 ], [ 29.777278481012658, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32280, "El": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.486619310344828 ], [ 29.777547974683543, -1.486888793103448 ], [ 29.777817468354431, -1.486888793103448 ], [ 29.777817468354431, -1.486619310344828 ], [ 29.777547974683543, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32281, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.486619310344828 ], [ 29.777817468354431, -1.486888793103448 ], [ 29.778086962025316, -1.486888793103448 ], [ 29.778086962025316, -1.486619310344828 ], [ 29.777817468354431, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32282, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.486619310344828 ], [ 29.778356455696201, -1.486888793103448 ], [ 29.77862594936709, -1.486888793103448 ], [ 29.77862594936709, -1.486619310344828 ], [ 29.778356455696201, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32283, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.486619310344828 ], [ 29.77862594936709, -1.486888793103448 ], [ 29.778895443037975, -1.486888793103448 ], [ 29.778895443037975, -1.486619310344828 ], [ 29.77862594936709, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32284, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.486619310344828 ], [ 29.778895443037975, -1.486888793103448 ], [ 29.77916493670886, -1.486888793103448 ], [ 29.77916493670886, -1.486619310344828 ], [ 29.778895443037975, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32285, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.486619310344828 ], [ 29.779703924050633, -1.486888793103448 ], [ 29.779973417721518, -1.486888793103448 ], [ 29.779973417721518, -1.486619310344828 ], [ 29.779703924050633, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32286, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.486619310344828 ], [ 29.779973417721518, -1.486888793103448 ], [ 29.780242911392406, -1.486888793103448 ], [ 29.780242911392406, -1.486619310344828 ], [ 29.779973417721518, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32287, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.486619310344828 ], [ 29.780242911392406, -1.486888793103448 ], [ 29.780512405063291, -1.486888793103448 ], [ 29.780512405063291, -1.486619310344828 ], [ 29.780242911392406, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32288, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.486619310344828 ], [ 29.780512405063291, -1.486888793103448 ], [ 29.780781898734176, -1.486888793103448 ], [ 29.780781898734176, -1.486619310344828 ], [ 29.780512405063291, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32289, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.486619310344828 ], [ 29.780781898734176, -1.486888793103448 ], [ 29.781051392405065, -1.486888793103448 ], [ 29.781051392405065, -1.486619310344828 ], [ 29.780781898734176, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32290, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.486619310344828 ], [ 29.781051392405065, -1.486888793103448 ], [ 29.78132088607595, -1.486888793103448 ], [ 29.78132088607595, -1.486619310344828 ], [ 29.781051392405065, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32291, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.486619310344828 ], [ 29.78132088607595, -1.486888793103448 ], [ 29.781590379746834, -1.486888793103448 ], [ 29.781590379746834, -1.486619310344828 ], [ 29.78132088607595, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32292, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.486619310344828 ], [ 29.781590379746834, -1.486888793103448 ], [ 29.781859873417723, -1.486888793103448 ], [ 29.781859873417723, -1.486619310344828 ], [ 29.781590379746834, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32293, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.486619310344828 ], [ 29.781859873417723, -1.486888793103448 ], [ 29.782129367088608, -1.486888793103448 ], [ 29.782129367088608, -1.486619310344828 ], [ 29.781859873417723, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32294, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.486619310344828 ], [ 29.782129367088608, -1.486888793103448 ], [ 29.782398860759493, -1.486888793103448 ], [ 29.782398860759493, -1.486619310344828 ], [ 29.782129367088608, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32295, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.486619310344828 ], [ 29.782398860759493, -1.486888793103448 ], [ 29.782668354430381, -1.486888793103448 ], [ 29.782668354430381, -1.486619310344828 ], [ 29.782398860759493, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32296, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.486619310344828 ], [ 29.782668354430381, -1.486888793103448 ], [ 29.782937848101266, -1.486888793103448 ], [ 29.782937848101266, -1.486619310344828 ], [ 29.782668354430381, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32297, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.486619310344828 ], [ 29.782937848101266, -1.486888793103448 ], [ 29.783207341772151, -1.486888793103448 ], [ 29.783207341772151, -1.486619310344828 ], [ 29.782937848101266, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32298, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.486619310344828 ], [ 29.783207341772151, -1.486888793103448 ], [ 29.783476835443039, -1.486888793103448 ], [ 29.783476835443039, -1.486619310344828 ], [ 29.783207341772151, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32299, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.486619310344828 ], [ 29.783476835443039, -1.486888793103448 ], [ 29.784015822784809, -1.486888793103448 ], [ 29.784015822784809, -1.486619310344828 ], [ 29.783476835443039, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32300, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.486619310344828 ], [ 29.784015822784809, -1.486888793103448 ], [ 29.784285316455698, -1.486888793103448 ], [ 29.784285316455698, -1.486619310344828 ], [ 29.784015822784809, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32301, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.486619310344828 ], [ 29.784285316455698, -1.486888793103448 ], [ 29.784554810126583, -1.486888793103448 ], [ 29.784554810126583, -1.486619310344828 ], [ 29.784285316455698, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32302, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.486619310344828 ], [ 29.784554810126583, -1.486888793103448 ], [ 29.784824303797468, -1.486888793103448 ], [ 29.784824303797468, -1.486619310344828 ], [ 29.784554810126583, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32303, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.486619310344828 ], [ 29.784824303797468, -1.486888793103448 ], [ 29.785093797468356, -1.486888793103448 ], [ 29.785093797468356, -1.486619310344828 ], [ 29.784824303797468, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32304, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.486619310344828 ], [ 29.785093797468356, -1.486888793103448 ], [ 29.785363291139241, -1.486888793103448 ], [ 29.785363291139241, -1.486619310344828 ], [ 29.785093797468356, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32305, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.486619310344828 ], [ 29.785363291139241, -1.486888793103448 ], [ 29.785632784810126, -1.486888793103448 ], [ 29.785632784810126, -1.486619310344828 ], [ 29.785363291139241, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32306, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.486619310344828 ], [ 29.785632784810126, -1.486888793103448 ], [ 29.785902278481014, -1.486888793103448 ], [ 29.785902278481014, -1.486619310344828 ], [ 29.785632784810126, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32307, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.486619310344828 ], [ 29.785902278481014, -1.486888793103448 ], [ 29.786171772151899, -1.486888793103448 ], [ 29.786171772151899, -1.486619310344828 ], [ 29.785902278481014, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32308, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.486619310344828 ], [ 29.786171772151899, -1.486888793103448 ], [ 29.786441265822784, -1.486888793103448 ], [ 29.786441265822784, -1.486619310344828 ], [ 29.786171772151899, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32309, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.486619310344828 ], [ 29.786441265822784, -1.486888793103448 ], [ 29.786710759493673, -1.486888793103448 ], [ 29.786710759493673, -1.486619310344828 ], [ 29.786441265822784, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32310, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.486619310344828 ], [ 29.786710759493673, -1.486888793103448 ], [ 29.786980253164558, -1.486888793103448 ], [ 29.786980253164558, -1.486619310344828 ], [ 29.786710759493673, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32311, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.486619310344828 ], [ 29.786980253164558, -1.486888793103448 ], [ 29.787249746835442, -1.486888793103448 ], [ 29.787249746835442, -1.486619310344828 ], [ 29.786980253164558, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32312, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.486619310344828 ], [ 29.787249746835442, -1.486888793103448 ], [ 29.788058227848101, -1.486888793103448 ], [ 29.788058227848101, -1.486619310344828 ], [ 29.787249746835442, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32313, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.486619310344828 ], [ 29.788058227848101, -1.486888793103448 ], [ 29.788327721518986, -1.486888793103448 ], [ 29.788327721518986, -1.486619310344828 ], [ 29.788058227848101, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32314, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.486619310344828 ], [ 29.788327721518986, -1.486888793103448 ], [ 29.788866708860759, -1.486888793103448 ], [ 29.788866708860759, -1.486619310344828 ], [ 29.788327721518986, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32315, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.486619310344828 ], [ 29.800724430379748, -1.486888793103448 ], [ 29.800993924050633, -1.486888793103448 ], [ 29.800993924050633, -1.486619310344828 ], [ 29.800724430379748, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32316, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.486619310344828 ], [ 29.800993924050633, -1.486888793103448 ], [ 29.801263417721518, -1.486888793103448 ], [ 29.801263417721518, -1.486619310344828 ], [ 29.800993924050633, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32317, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.486619310344828 ], [ 29.801263417721518, -1.486888793103448 ], [ 29.801532911392407, -1.486888793103448 ], [ 29.801532911392407, -1.486619310344828 ], [ 29.801263417721518, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32318, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.486619310344828 ], [ 29.801532911392407, -1.486888793103448 ], [ 29.802071898734177, -1.486888793103448 ], [ 29.802071898734177, -1.486619310344828 ], [ 29.801532911392407, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32319, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.486619310344828 ], [ 29.802071898734177, -1.486888793103448 ], [ 29.802341392405065, -1.486888793103448 ], [ 29.802341392405065, -1.486619310344828 ], [ 29.802071898734177, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32320, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.484193965517242 ], [ 29.803688860759493, -1.484463448275862 ], [ 29.804227848101267, -1.484463448275862 ], [ 29.804227848101267, -1.487966724137931 ], [ 29.804497341772151, -1.487966724137931 ], [ 29.804497341772151, -1.484193965517242 ], [ 29.803688860759493, -1.484193965517242 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32321, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.484463448275862 ], [ 29.803688860759493, -1.484732931034483 ], [ 29.803958354430382, -1.484732931034483 ], [ 29.803958354430382, -1.487966724137931 ], [ 29.804227848101267, -1.487966724137931 ], [ 29.804227848101267, -1.484463448275862 ], [ 29.803688860759493, -1.484463448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32322, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.484732931034483 ], [ 29.803688860759493, -1.487966724137931 ], [ 29.803958354430382, -1.487966724137931 ], [ 29.803958354430382, -1.484732931034483 ], [ 29.803688860759493, -1.484732931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32323, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.485002413793103 ], [ 29.803419367088608, -1.488236206896552 ], [ 29.803688860759493, -1.488236206896552 ], [ 29.803688860759493, -1.485002413793103 ], [ 29.803419367088608, -1.485002413793103 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32324, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.485271896551724 ], [ 29.802880379746835, -1.487966724137931 ], [ 29.803149873417723, -1.487966724137931 ], [ 29.803149873417723, -1.485271896551724 ], [ 29.802880379746835, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32325, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.485271896551724 ], [ 29.803149873417723, -1.488236206896552 ], [ 29.803419367088608, -1.488236206896552 ], [ 29.803419367088608, -1.485271896551724 ], [ 29.803149873417723, -1.485271896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32326, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.7667682278481, -1.486080344827586 ], [ 29.7667682278481, -1.487158275862069 ], [ 29.767037721518985, -1.487158275862069 ], [ 29.767037721518985, -1.486080344827586 ], [ 29.7667682278481, -1.486080344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32327, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799376962025317, -1.486349827586207 ], [ 29.799376962025317, -1.48769724137931 ], [ 29.79991594936709, -1.48769724137931 ], [ 29.79991594936709, -1.48742775862069 ], [ 29.799646455696202, -1.48742775862069 ], [ 29.799646455696202, -1.486349827586207 ], [ 29.799376962025317, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32328, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.799646455696202, -1.486349827586207 ], [ 29.799646455696202, -1.48742775862069 ], [ 29.79991594936709, -1.48742775862069 ], [ 29.79991594936709, -1.486349827586207 ], [ 29.799646455696202, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32329, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.486349827586207 ], [ 29.79991594936709, -1.487158275862069 ], [ 29.800185443037975, -1.487158275862069 ], [ 29.800185443037975, -1.486349827586207 ], [ 29.79991594936709, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32330, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.486619310344828 ], [ 29.77916493670886, -1.487158275862069 ], [ 29.779434430379748, -1.487158275862069 ], [ 29.779434430379748, -1.486619310344828 ], [ 29.77916493670886, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32331, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.486619310344828 ], [ 29.779434430379748, -1.487158275862069 ], [ 29.779703924050633, -1.487158275862069 ], [ 29.779703924050633, -1.486619310344828 ], [ 29.779434430379748, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32332, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.486888793103448 ], [ 29.767037721518985, -1.487158275862069 ], [ 29.767307215189874, -1.487158275862069 ], [ 29.767307215189874, -1.486888793103448 ], [ 29.767037721518985, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32333, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.486888793103448 ], [ 29.767307215189874, -1.487158275862069 ], [ 29.767576708860759, -1.487158275862069 ], [ 29.767576708860759, -1.486888793103448 ], [ 29.767307215189874, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32334, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.486888793103448 ], [ 29.767576708860759, -1.487158275862069 ], [ 29.767846202531643, -1.487158275862069 ], [ 29.767846202531643, -1.486888793103448 ], [ 29.767576708860759, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32335, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.486888793103448 ], [ 29.767846202531643, -1.487158275862069 ], [ 29.768115696202532, -1.487158275862069 ], [ 29.768115696202532, -1.486888793103448 ], [ 29.767846202531643, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32336, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.486888793103448 ], [ 29.768115696202532, -1.487158275862069 ], [ 29.768385189873417, -1.487158275862069 ], [ 29.768385189873417, -1.486888793103448 ], [ 29.768115696202532, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32337, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.486888793103448 ], [ 29.768385189873417, -1.487158275862069 ], [ 29.768654683544302, -1.487158275862069 ], [ 29.768654683544302, -1.486888793103448 ], [ 29.768385189873417, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32338, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.486888793103448 ], [ 29.768654683544302, -1.487158275862069 ], [ 29.76892417721519, -1.487158275862069 ], [ 29.76892417721519, -1.486888793103448 ], [ 29.768654683544302, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32339, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.486888793103448 ], [ 29.76892417721519, -1.487158275862069 ], [ 29.769193670886075, -1.487158275862069 ], [ 29.769193670886075, -1.486888793103448 ], [ 29.76892417721519, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32340, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.486888793103448 ], [ 29.769193670886075, -1.487158275862069 ], [ 29.76946316455696, -1.487158275862069 ], [ 29.76946316455696, -1.486888793103448 ], [ 29.769193670886075, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32341, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.486888793103448 ], [ 29.76946316455696, -1.487158275862069 ], [ 29.769732658227849, -1.487158275862069 ], [ 29.769732658227849, -1.486888793103448 ], [ 29.76946316455696, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32342, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.486888793103448 ], [ 29.769732658227849, -1.487158275862069 ], [ 29.770002151898733, -1.487158275862069 ], [ 29.770002151898733, -1.486888793103448 ], [ 29.769732658227849, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32343, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.486888793103448 ], [ 29.770002151898733, -1.487158275862069 ], [ 29.770271645569618, -1.487158275862069 ], [ 29.770271645569618, -1.486888793103448 ], [ 29.770002151898733, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32344, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.486888793103448 ], [ 29.770271645569618, -1.487158275862069 ], [ 29.770541139240507, -1.487158275862069 ], [ 29.770541139240507, -1.486888793103448 ], [ 29.770271645569618, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32345, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.486888793103448 ], [ 29.770541139240507, -1.487158275862069 ], [ 29.770810632911392, -1.487158275862069 ], [ 29.770810632911392, -1.486888793103448 ], [ 29.770541139240507, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32346, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.486888793103448 ], [ 29.770810632911392, -1.487158275862069 ], [ 29.771080126582277, -1.487158275862069 ], [ 29.771080126582277, -1.486888793103448 ], [ 29.770810632911392, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32347, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.486888793103448 ], [ 29.771080126582277, -1.487158275862069 ], [ 29.771349620253165, -1.487158275862069 ], [ 29.771349620253165, -1.486888793103448 ], [ 29.771080126582277, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32348, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.486888793103448 ], [ 29.771349620253165, -1.487158275862069 ], [ 29.77161911392405, -1.487158275862069 ], [ 29.77161911392405, -1.486888793103448 ], [ 29.771349620253165, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32349, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.486888793103448 ], [ 29.77161911392405, -1.487158275862069 ], [ 29.771888607594935, -1.487158275862069 ], [ 29.771888607594935, -1.486888793103448 ], [ 29.77161911392405, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32350, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.486888793103448 ], [ 29.771888607594935, -1.487158275862069 ], [ 29.772158101265823, -1.487158275862069 ], [ 29.772158101265823, -1.486888793103448 ], [ 29.771888607594935, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32351, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.486888793103448 ], [ 29.772158101265823, -1.487158275862069 ], [ 29.772427594936708, -1.487158275862069 ], [ 29.772427594936708, -1.486888793103448 ], [ 29.772158101265823, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32352, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.486888793103448 ], [ 29.772427594936708, -1.487158275862069 ], [ 29.772697088607593, -1.487158275862069 ], [ 29.772697088607593, -1.486888793103448 ], [ 29.772427594936708, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32353, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.486888793103448 ], [ 29.772697088607593, -1.487158275862069 ], [ 29.772966582278482, -1.487158275862069 ], [ 29.772966582278482, -1.486888793103448 ], [ 29.772697088607593, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32354, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.486888793103448 ], [ 29.772966582278482, -1.487158275862069 ], [ 29.773236075949367, -1.487158275862069 ], [ 29.773236075949367, -1.486888793103448 ], [ 29.772966582278482, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32355, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.486888793103448 ], [ 29.773236075949367, -1.487158275862069 ], [ 29.773505569620252, -1.487158275862069 ], [ 29.773505569620252, -1.486888793103448 ], [ 29.773236075949367, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32356, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.486888793103448 ], [ 29.773505569620252, -1.487158275862069 ], [ 29.77377506329114, -1.487158275862069 ], [ 29.77377506329114, -1.486888793103448 ], [ 29.773505569620252, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32357, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.486888793103448 ], [ 29.77377506329114, -1.487158275862069 ], [ 29.774044556962025, -1.487158275862069 ], [ 29.774044556962025, -1.486888793103448 ], [ 29.77377506329114, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32358, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.486888793103448 ], [ 29.774044556962025, -1.487158275862069 ], [ 29.77431405063291, -1.487158275862069 ], [ 29.77431405063291, -1.486888793103448 ], [ 29.774044556962025, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32359, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.486888793103448 ], [ 29.77431405063291, -1.487158275862069 ], [ 29.774853037974683, -1.487158275862069 ], [ 29.774853037974683, -1.486888793103448 ], [ 29.77431405063291, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32360, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.486888793103448 ], [ 29.774853037974683, -1.487158275862069 ], [ 29.775122531645568, -1.487158275862069 ], [ 29.775122531645568, -1.486888793103448 ], [ 29.774853037974683, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32361, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.486888793103448 ], [ 29.775122531645568, -1.487158275862069 ], [ 29.775392025316457, -1.487158275862069 ], [ 29.775392025316457, -1.486888793103448 ], [ 29.775122531645568, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32362, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.486888793103448 ], [ 29.775392025316457, -1.487158275862069 ], [ 29.775661518987341, -1.487158275862069 ], [ 29.775661518987341, -1.486888793103448 ], [ 29.775392025316457, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32363, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.486888793103448 ], [ 29.775661518987341, -1.487158275862069 ], [ 29.775931012658226, -1.487158275862069 ], [ 29.775931012658226, -1.486888793103448 ], [ 29.775661518987341, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32364, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.486888793103448 ], [ 29.775931012658226, -1.487158275862069 ], [ 29.776200506329115, -1.487158275862069 ], [ 29.776200506329115, -1.486888793103448 ], [ 29.775931012658226, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32365, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.486888793103448 ], [ 29.776200506329115, -1.487158275862069 ], [ 29.77647, -1.487158275862069 ], [ 29.77647, -1.486888793103448 ], [ 29.776200506329115, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32366, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.486888793103448 ], [ 29.77647, -1.487158275862069 ], [ 29.776739493670885, -1.487158275862069 ], [ 29.776739493670885, -1.486888793103448 ], [ 29.77647, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32367, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.486888793103448 ], [ 29.776739493670885, -1.487158275862069 ], [ 29.777008987341773, -1.487158275862069 ], [ 29.777008987341773, -1.486888793103448 ], [ 29.776739493670885, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32368, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.486888793103448 ], [ 29.777008987341773, -1.487158275862069 ], [ 29.777278481012658, -1.487158275862069 ], [ 29.777278481012658, -1.486888793103448 ], [ 29.777008987341773, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32369, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.486888793103448 ], [ 29.777278481012658, -1.487158275862069 ], [ 29.777547974683543, -1.487158275862069 ], [ 29.777547974683543, -1.486888793103448 ], [ 29.777278481012658, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32370, "El": 96 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.486888793103448 ], [ 29.777547974683543, -1.487158275862069 ], [ 29.777817468354431, -1.487158275862069 ], [ 29.777817468354431, -1.486888793103448 ], [ 29.777547974683543, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32371, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.486888793103448 ], [ 29.777817468354431, -1.487158275862069 ], [ 29.778086962025316, -1.487158275862069 ], [ 29.778086962025316, -1.486888793103448 ], [ 29.777817468354431, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32372, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.486888793103448 ], [ 29.778086962025316, -1.487158275862069 ], [ 29.778356455696201, -1.487158275862069 ], [ 29.778356455696201, -1.486888793103448 ], [ 29.778086962025316, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32373, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.486888793103448 ], [ 29.778356455696201, -1.487158275862069 ], [ 29.77862594936709, -1.487158275862069 ], [ 29.77862594936709, -1.486888793103448 ], [ 29.778356455696201, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32374, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.486888793103448 ], [ 29.77862594936709, -1.487158275862069 ], [ 29.778895443037975, -1.487158275862069 ], [ 29.778895443037975, -1.486888793103448 ], [ 29.77862594936709, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32375, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.486888793103448 ], [ 29.778895443037975, -1.487158275862069 ], [ 29.77916493670886, -1.487158275862069 ], [ 29.77916493670886, -1.486888793103448 ], [ 29.778895443037975, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32376, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.486888793103448 ], [ 29.779703924050633, -1.487158275862069 ], [ 29.779973417721518, -1.487158275862069 ], [ 29.779973417721518, -1.486888793103448 ], [ 29.779703924050633, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32377, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.486888793103448 ], [ 29.779973417721518, -1.487158275862069 ], [ 29.780242911392406, -1.487158275862069 ], [ 29.780242911392406, -1.486888793103448 ], [ 29.779973417721518, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32378, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.486888793103448 ], [ 29.780242911392406, -1.487158275862069 ], [ 29.780512405063291, -1.487158275862069 ], [ 29.780512405063291, -1.486888793103448 ], [ 29.780242911392406, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32379, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.486888793103448 ], [ 29.780512405063291, -1.487158275862069 ], [ 29.780781898734176, -1.487158275862069 ], [ 29.780781898734176, -1.486888793103448 ], [ 29.780512405063291, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32380, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.486888793103448 ], [ 29.780781898734176, -1.48742775862069 ], [ 29.781051392405065, -1.48742775862069 ], [ 29.781051392405065, -1.486888793103448 ], [ 29.780781898734176, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32381, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.486888793103448 ], [ 29.781051392405065, -1.487158275862069 ], [ 29.78132088607595, -1.487158275862069 ], [ 29.78132088607595, -1.486888793103448 ], [ 29.781051392405065, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32382, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.486888793103448 ], [ 29.78132088607595, -1.487158275862069 ], [ 29.781590379746834, -1.487158275862069 ], [ 29.781590379746834, -1.486888793103448 ], [ 29.78132088607595, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32383, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.486888793103448 ], [ 29.781590379746834, -1.487158275862069 ], [ 29.781859873417723, -1.487158275862069 ], [ 29.781859873417723, -1.486888793103448 ], [ 29.781590379746834, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32384, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.486888793103448 ], [ 29.781859873417723, -1.487158275862069 ], [ 29.782129367088608, -1.487158275862069 ], [ 29.782129367088608, -1.486888793103448 ], [ 29.781859873417723, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32385, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.486888793103448 ], [ 29.782129367088608, -1.487158275862069 ], [ 29.782398860759493, -1.487158275862069 ], [ 29.782398860759493, -1.486888793103448 ], [ 29.782129367088608, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32386, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.486888793103448 ], [ 29.782398860759493, -1.487158275862069 ], [ 29.782668354430381, -1.487158275862069 ], [ 29.782668354430381, -1.486888793103448 ], [ 29.782398860759493, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32387, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.486888793103448 ], [ 29.782668354430381, -1.487158275862069 ], [ 29.782937848101266, -1.487158275862069 ], [ 29.782937848101266, -1.486888793103448 ], [ 29.782668354430381, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32388, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.486888793103448 ], [ 29.782937848101266, -1.487158275862069 ], [ 29.783207341772151, -1.487158275862069 ], [ 29.783207341772151, -1.486888793103448 ], [ 29.782937848101266, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32389, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.486888793103448 ], [ 29.783207341772151, -1.487158275862069 ], [ 29.783476835443039, -1.487158275862069 ], [ 29.783476835443039, -1.486888793103448 ], [ 29.783207341772151, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32390, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.486888793103448 ], [ 29.783476835443039, -1.487158275862069 ], [ 29.784015822784809, -1.487158275862069 ], [ 29.784015822784809, -1.486888793103448 ], [ 29.783476835443039, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32391, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.486888793103448 ], [ 29.784015822784809, -1.487158275862069 ], [ 29.784285316455698, -1.487158275862069 ], [ 29.784285316455698, -1.486888793103448 ], [ 29.784015822784809, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32392, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.486888793103448 ], [ 29.784285316455698, -1.487158275862069 ], [ 29.784554810126583, -1.487158275862069 ], [ 29.784554810126583, -1.486888793103448 ], [ 29.784285316455698, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32393, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.486888793103448 ], [ 29.784554810126583, -1.487158275862069 ], [ 29.784824303797468, -1.487158275862069 ], [ 29.784824303797468, -1.486888793103448 ], [ 29.784554810126583, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32394, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.486888793103448 ], [ 29.784824303797468, -1.487158275862069 ], [ 29.785093797468356, -1.487158275862069 ], [ 29.785093797468356, -1.486888793103448 ], [ 29.784824303797468, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32395, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.486888793103448 ], [ 29.785093797468356, -1.487158275862069 ], [ 29.785363291139241, -1.487158275862069 ], [ 29.785363291139241, -1.486888793103448 ], [ 29.785093797468356, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32396, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.486888793103448 ], [ 29.785363291139241, -1.487158275862069 ], [ 29.785632784810126, -1.487158275862069 ], [ 29.785632784810126, -1.486888793103448 ], [ 29.785363291139241, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32397, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.486888793103448 ], [ 29.785632784810126, -1.487158275862069 ], [ 29.785902278481014, -1.487158275862069 ], [ 29.785902278481014, -1.486888793103448 ], [ 29.785632784810126, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32398, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.486888793103448 ], [ 29.785902278481014, -1.487158275862069 ], [ 29.786171772151899, -1.487158275862069 ], [ 29.786171772151899, -1.486888793103448 ], [ 29.785902278481014, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32399, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.486888793103448 ], [ 29.786171772151899, -1.487158275862069 ], [ 29.786441265822784, -1.487158275862069 ], [ 29.786441265822784, -1.486888793103448 ], [ 29.786171772151899, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32400, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.486888793103448 ], [ 29.786441265822784, -1.487158275862069 ], [ 29.786710759493673, -1.487158275862069 ], [ 29.786710759493673, -1.486888793103448 ], [ 29.786441265822784, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32401, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.486888793103448 ], [ 29.786710759493673, -1.487158275862069 ], [ 29.786980253164558, -1.487158275862069 ], [ 29.786980253164558, -1.486888793103448 ], [ 29.786710759493673, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32402, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.486888793103448 ], [ 29.786980253164558, -1.487158275862069 ], [ 29.788327721518986, -1.487158275862069 ], [ 29.788327721518986, -1.486888793103448 ], [ 29.786980253164558, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32403, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.486888793103448 ], [ 29.788327721518986, -1.487158275862069 ], [ 29.788597215189874, -1.487158275862069 ], [ 29.788597215189874, -1.486888793103448 ], [ 29.788327721518986, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32404, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.486888793103448 ], [ 29.800185443037975, -1.487158275862069 ], [ 29.80045493670886, -1.487158275862069 ], [ 29.80045493670886, -1.486888793103448 ], [ 29.800185443037975, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32405, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.486888793103448 ], [ 29.80045493670886, -1.487158275862069 ], [ 29.800724430379748, -1.487158275862069 ], [ 29.800724430379748, -1.486888793103448 ], [ 29.80045493670886, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32406, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.486888793103448 ], [ 29.800724430379748, -1.487158275862069 ], [ 29.800993924050633, -1.487158275862069 ], [ 29.800993924050633, -1.486888793103448 ], [ 29.800724430379748, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32407, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.486888793103448 ], [ 29.800993924050633, -1.487158275862069 ], [ 29.801263417721518, -1.487158275862069 ], [ 29.801263417721518, -1.486888793103448 ], [ 29.800993924050633, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32408, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.486888793103448 ], [ 29.801263417721518, -1.487158275862069 ], [ 29.801532911392407, -1.487158275862069 ], [ 29.801532911392407, -1.486888793103448 ], [ 29.801263417721518, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32409, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.486888793103448 ], [ 29.801532911392407, -1.487158275862069 ], [ 29.802071898734177, -1.487158275862069 ], [ 29.802071898734177, -1.486888793103448 ], [ 29.801532911392407, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32410, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.485810862068966 ], [ 29.80261088607595, -1.485810862068966 ], [ 29.80261088607595, -1.48742775862069 ], [ 29.802341392405065, -1.48742775862069 ], [ 29.802341392405065, -1.487158275862069 ], [ 29.802071898734177, -1.487158275862069 ], [ 29.802071898734177, -1.486888793103448 ], [ 29.802341392405065, -1.486888793103448 ], [ 29.802341392405065, -1.485810862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32411, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767037721518985, -1.487158275862069 ], [ 29.767037721518985, -1.48769724137931 ], [ 29.767307215189874, -1.48769724137931 ], [ 29.767307215189874, -1.487158275862069 ], [ 29.767037721518985, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32412, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.487158275862069 ], [ 29.767307215189874, -1.48742775862069 ], [ 29.767576708860759, -1.48742775862069 ], [ 29.767576708860759, -1.487158275862069 ], [ 29.767307215189874, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32413, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.487158275862069 ], [ 29.767576708860759, -1.48742775862069 ], [ 29.767846202531643, -1.48742775862069 ], [ 29.767846202531643, -1.487158275862069 ], [ 29.767576708860759, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32414, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.487158275862069 ], [ 29.767846202531643, -1.48742775862069 ], [ 29.768115696202532, -1.48742775862069 ], [ 29.768115696202532, -1.487158275862069 ], [ 29.767846202531643, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32415, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.487158275862069 ], [ 29.768115696202532, -1.48742775862069 ], [ 29.768385189873417, -1.48742775862069 ], [ 29.768385189873417, -1.487158275862069 ], [ 29.768115696202532, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32416, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.487158275862069 ], [ 29.768385189873417, -1.48742775862069 ], [ 29.768654683544302, -1.48742775862069 ], [ 29.768654683544302, -1.487158275862069 ], [ 29.768385189873417, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32417, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.487158275862069 ], [ 29.768654683544302, -1.48742775862069 ], [ 29.76892417721519, -1.48742775862069 ], [ 29.76892417721519, -1.487158275862069 ], [ 29.768654683544302, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32418, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.487158275862069 ], [ 29.76892417721519, -1.48742775862069 ], [ 29.769193670886075, -1.48742775862069 ], [ 29.769193670886075, -1.487158275862069 ], [ 29.76892417721519, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32419, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.487158275862069 ], [ 29.769193670886075, -1.48742775862069 ], [ 29.76946316455696, -1.48742775862069 ], [ 29.76946316455696, -1.487158275862069 ], [ 29.769193670886075, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32420, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.487158275862069 ], [ 29.76946316455696, -1.48742775862069 ], [ 29.769732658227849, -1.48742775862069 ], [ 29.769732658227849, -1.487158275862069 ], [ 29.76946316455696, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32421, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.487158275862069 ], [ 29.769732658227849, -1.48742775862069 ], [ 29.770002151898733, -1.48742775862069 ], [ 29.770002151898733, -1.487158275862069 ], [ 29.769732658227849, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32422, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.487158275862069 ], [ 29.770002151898733, -1.48742775862069 ], [ 29.770271645569618, -1.48742775862069 ], [ 29.770271645569618, -1.487158275862069 ], [ 29.770002151898733, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32423, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.487158275862069 ], [ 29.770271645569618, -1.48742775862069 ], [ 29.770541139240507, -1.48742775862069 ], [ 29.770541139240507, -1.487158275862069 ], [ 29.770271645569618, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32424, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.487158275862069 ], [ 29.770541139240507, -1.48742775862069 ], [ 29.770810632911392, -1.48742775862069 ], [ 29.770810632911392, -1.487158275862069 ], [ 29.770541139240507, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32425, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.487158275862069 ], [ 29.770810632911392, -1.48742775862069 ], [ 29.771080126582277, -1.48742775862069 ], [ 29.771080126582277, -1.487158275862069 ], [ 29.770810632911392, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32426, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.487158275862069 ], [ 29.771080126582277, -1.48742775862069 ], [ 29.771349620253165, -1.48742775862069 ], [ 29.771349620253165, -1.487158275862069 ], [ 29.771080126582277, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32427, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.487158275862069 ], [ 29.771349620253165, -1.48742775862069 ], [ 29.77161911392405, -1.48742775862069 ], [ 29.77161911392405, -1.487158275862069 ], [ 29.771349620253165, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32428, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.487158275862069 ], [ 29.77161911392405, -1.48742775862069 ], [ 29.771888607594935, -1.48742775862069 ], [ 29.771888607594935, -1.487158275862069 ], [ 29.77161911392405, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32429, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.487158275862069 ], [ 29.771888607594935, -1.48742775862069 ], [ 29.772158101265823, -1.48742775862069 ], [ 29.772158101265823, -1.487158275862069 ], [ 29.771888607594935, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32430, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.487158275862069 ], [ 29.772158101265823, -1.48742775862069 ], [ 29.772427594936708, -1.48742775862069 ], [ 29.772427594936708, -1.487158275862069 ], [ 29.772158101265823, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32431, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.487158275862069 ], [ 29.772427594936708, -1.48742775862069 ], [ 29.772697088607593, -1.48742775862069 ], [ 29.772697088607593, -1.487158275862069 ], [ 29.772427594936708, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32432, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.487158275862069 ], [ 29.772697088607593, -1.48742775862069 ], [ 29.772966582278482, -1.48742775862069 ], [ 29.772966582278482, -1.487158275862069 ], [ 29.772697088607593, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32433, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.487158275862069 ], [ 29.772966582278482, -1.48742775862069 ], [ 29.773236075949367, -1.48742775862069 ], [ 29.773236075949367, -1.487158275862069 ], [ 29.772966582278482, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32434, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.487158275862069 ], [ 29.773236075949367, -1.48742775862069 ], [ 29.773505569620252, -1.48742775862069 ], [ 29.773505569620252, -1.487158275862069 ], [ 29.773236075949367, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32435, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.487158275862069 ], [ 29.773505569620252, -1.48742775862069 ], [ 29.77377506329114, -1.48742775862069 ], [ 29.77377506329114, -1.487158275862069 ], [ 29.773505569620252, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32436, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.487158275862069 ], [ 29.77377506329114, -1.48742775862069 ], [ 29.774044556962025, -1.48742775862069 ], [ 29.774044556962025, -1.487158275862069 ], [ 29.77377506329114, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32437, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.487158275862069 ], [ 29.774044556962025, -1.48742775862069 ], [ 29.77431405063291, -1.48742775862069 ], [ 29.77431405063291, -1.487158275862069 ], [ 29.774044556962025, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32438, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.487158275862069 ], [ 29.77431405063291, -1.48742775862069 ], [ 29.774853037974683, -1.48742775862069 ], [ 29.774853037974683, -1.487158275862069 ], [ 29.77431405063291, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32439, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.487158275862069 ], [ 29.774853037974683, -1.48742775862069 ], [ 29.775122531645568, -1.48742775862069 ], [ 29.775122531645568, -1.487158275862069 ], [ 29.774853037974683, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32440, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.487158275862069 ], [ 29.775122531645568, -1.48742775862069 ], [ 29.775392025316457, -1.48742775862069 ], [ 29.775392025316457, -1.487158275862069 ], [ 29.775122531645568, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32441, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.487158275862069 ], [ 29.775392025316457, -1.48742775862069 ], [ 29.775661518987341, -1.48742775862069 ], [ 29.775661518987341, -1.487158275862069 ], [ 29.775392025316457, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32442, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.487158275862069 ], [ 29.775661518987341, -1.48742775862069 ], [ 29.775931012658226, -1.48742775862069 ], [ 29.775931012658226, -1.487158275862069 ], [ 29.775661518987341, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32443, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.487158275862069 ], [ 29.775931012658226, -1.48742775862069 ], [ 29.776200506329115, -1.48742775862069 ], [ 29.776200506329115, -1.487158275862069 ], [ 29.775931012658226, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32444, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.487158275862069 ], [ 29.776200506329115, -1.48742775862069 ], [ 29.77647, -1.48742775862069 ], [ 29.77647, -1.487158275862069 ], [ 29.776200506329115, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32445, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.487158275862069 ], [ 29.77647, -1.48742775862069 ], [ 29.776739493670885, -1.48742775862069 ], [ 29.776739493670885, -1.487158275862069 ], [ 29.77647, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32446, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.487158275862069 ], [ 29.776739493670885, -1.48742775862069 ], [ 29.777008987341773, -1.48742775862069 ], [ 29.777008987341773, -1.487158275862069 ], [ 29.776739493670885, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32447, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.487158275862069 ], [ 29.777008987341773, -1.48742775862069 ], [ 29.777278481012658, -1.48742775862069 ], [ 29.777278481012658, -1.487158275862069 ], [ 29.777008987341773, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32448, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.487158275862069 ], [ 29.777278481012658, -1.48742775862069 ], [ 29.777547974683543, -1.48742775862069 ], [ 29.777547974683543, -1.487158275862069 ], [ 29.777278481012658, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32449, "El": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.487158275862069 ], [ 29.777547974683543, -1.48742775862069 ], [ 29.777817468354431, -1.48742775862069 ], [ 29.777817468354431, -1.487158275862069 ], [ 29.777547974683543, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32450, "El": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.487158275862069 ], [ 29.777817468354431, -1.48742775862069 ], [ 29.778086962025316, -1.48742775862069 ], [ 29.778086962025316, -1.487158275862069 ], [ 29.777817468354431, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32451, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.487158275862069 ], [ 29.778086962025316, -1.48742775862069 ], [ 29.778356455696201, -1.48742775862069 ], [ 29.778356455696201, -1.487158275862069 ], [ 29.778086962025316, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32452, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.487158275862069 ], [ 29.778356455696201, -1.48742775862069 ], [ 29.77862594936709, -1.48742775862069 ], [ 29.77862594936709, -1.487158275862069 ], [ 29.778356455696201, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32453, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.487158275862069 ], [ 29.77862594936709, -1.48742775862069 ], [ 29.778895443037975, -1.48742775862069 ], [ 29.778895443037975, -1.487158275862069 ], [ 29.77862594936709, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32454, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.487158275862069 ], [ 29.778895443037975, -1.48742775862069 ], [ 29.77916493670886, -1.48742775862069 ], [ 29.77916493670886, -1.487158275862069 ], [ 29.778895443037975, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32455, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.487158275862069 ], [ 29.77916493670886, -1.48742775862069 ], [ 29.779434430379748, -1.48742775862069 ], [ 29.779434430379748, -1.487158275862069 ], [ 29.77916493670886, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32456, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.487158275862069 ], [ 29.779434430379748, -1.48742775862069 ], [ 29.779703924050633, -1.48742775862069 ], [ 29.779703924050633, -1.487158275862069 ], [ 29.779434430379748, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32457, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.487158275862069 ], [ 29.779703924050633, -1.48742775862069 ], [ 29.779973417721518, -1.48742775862069 ], [ 29.779973417721518, -1.487158275862069 ], [ 29.779703924050633, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32458, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.487158275862069 ], [ 29.779973417721518, -1.48742775862069 ], [ 29.780242911392406, -1.48742775862069 ], [ 29.780242911392406, -1.487158275862069 ], [ 29.779973417721518, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32459, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.487158275862069 ], [ 29.780242911392406, -1.48742775862069 ], [ 29.780512405063291, -1.48742775862069 ], [ 29.780512405063291, -1.487158275862069 ], [ 29.780242911392406, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32460, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.487158275862069 ], [ 29.780512405063291, -1.48742775862069 ], [ 29.780781898734176, -1.48742775862069 ], [ 29.780781898734176, -1.487158275862069 ], [ 29.780512405063291, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32461, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.487158275862069 ], [ 29.781051392405065, -1.48742775862069 ], [ 29.78132088607595, -1.48742775862069 ], [ 29.78132088607595, -1.487158275862069 ], [ 29.781051392405065, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32462, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.487158275862069 ], [ 29.78132088607595, -1.48742775862069 ], [ 29.781590379746834, -1.48742775862069 ], [ 29.781590379746834, -1.487158275862069 ], [ 29.78132088607595, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32463, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.487158275862069 ], [ 29.781590379746834, -1.48742775862069 ], [ 29.781859873417723, -1.48742775862069 ], [ 29.781859873417723, -1.487158275862069 ], [ 29.781590379746834, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32464, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.487158275862069 ], [ 29.781859873417723, -1.48742775862069 ], [ 29.782129367088608, -1.48742775862069 ], [ 29.782129367088608, -1.487158275862069 ], [ 29.781859873417723, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32465, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.487158275862069 ], [ 29.782129367088608, -1.48769724137931 ], [ 29.782398860759493, -1.48769724137931 ], [ 29.782398860759493, -1.487158275862069 ], [ 29.782129367088608, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32466, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.487158275862069 ], [ 29.782398860759493, -1.48742775862069 ], [ 29.782668354430381, -1.48742775862069 ], [ 29.782668354430381, -1.487158275862069 ], [ 29.782398860759493, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32467, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.487158275862069 ], [ 29.782668354430381, -1.48742775862069 ], [ 29.782937848101266, -1.48742775862069 ], [ 29.782937848101266, -1.487158275862069 ], [ 29.782668354430381, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32468, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.487158275862069 ], [ 29.782937848101266, -1.48742775862069 ], [ 29.783207341772151, -1.48742775862069 ], [ 29.783207341772151, -1.487158275862069 ], [ 29.782937848101266, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32469, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.487158275862069 ], [ 29.783207341772151, -1.48742775862069 ], [ 29.783476835443039, -1.48742775862069 ], [ 29.783476835443039, -1.487158275862069 ], [ 29.783207341772151, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32470, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.487158275862069 ], [ 29.783476835443039, -1.48742775862069 ], [ 29.784015822784809, -1.48742775862069 ], [ 29.784015822784809, -1.487158275862069 ], [ 29.783476835443039, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32471, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.487158275862069 ], [ 29.784015822784809, -1.48742775862069 ], [ 29.784285316455698, -1.48742775862069 ], [ 29.784285316455698, -1.487158275862069 ], [ 29.784015822784809, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32472, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.487158275862069 ], [ 29.784285316455698, -1.48742775862069 ], [ 29.784554810126583, -1.48742775862069 ], [ 29.784554810126583, -1.487158275862069 ], [ 29.784285316455698, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32473, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.487158275862069 ], [ 29.784554810126583, -1.48742775862069 ], [ 29.784824303797468, -1.48742775862069 ], [ 29.784824303797468, -1.487158275862069 ], [ 29.784554810126583, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32474, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.487158275862069 ], [ 29.784824303797468, -1.48742775862069 ], [ 29.785093797468356, -1.48742775862069 ], [ 29.785093797468356, -1.487158275862069 ], [ 29.784824303797468, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32475, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.487158275862069 ], [ 29.785093797468356, -1.48742775862069 ], [ 29.785363291139241, -1.48742775862069 ], [ 29.785363291139241, -1.487158275862069 ], [ 29.785093797468356, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32476, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.487158275862069 ], [ 29.785363291139241, -1.48742775862069 ], [ 29.785632784810126, -1.48742775862069 ], [ 29.785632784810126, -1.487158275862069 ], [ 29.785363291139241, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32477, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.487158275862069 ], [ 29.785632784810126, -1.48742775862069 ], [ 29.785902278481014, -1.48742775862069 ], [ 29.785902278481014, -1.487158275862069 ], [ 29.785632784810126, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32478, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.487158275862069 ], [ 29.785902278481014, -1.48742775862069 ], [ 29.786171772151899, -1.48742775862069 ], [ 29.786171772151899, -1.487158275862069 ], [ 29.785902278481014, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32479, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.487158275862069 ], [ 29.786171772151899, -1.48742775862069 ], [ 29.786441265822784, -1.48742775862069 ], [ 29.786441265822784, -1.487158275862069 ], [ 29.786171772151899, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32480, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.487158275862069 ], [ 29.786441265822784, -1.48742775862069 ], [ 29.786710759493673, -1.48742775862069 ], [ 29.786710759493673, -1.487158275862069 ], [ 29.786441265822784, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32481, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.487158275862069 ], [ 29.786710759493673, -1.48742775862069 ], [ 29.786980253164558, -1.48742775862069 ], [ 29.786980253164558, -1.487158275862069 ], [ 29.786710759493673, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32482, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.487158275862069 ], [ 29.79991594936709, -1.48742775862069 ], [ 29.800185443037975, -1.48742775862069 ], [ 29.800185443037975, -1.487158275862069 ], [ 29.79991594936709, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32483, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.487158275862069 ], [ 29.800185443037975, -1.48742775862069 ], [ 29.80045493670886, -1.48742775862069 ], [ 29.80045493670886, -1.487158275862069 ], [ 29.800185443037975, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32484, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.487158275862069 ], [ 29.80045493670886, -1.48742775862069 ], [ 29.800724430379748, -1.48742775862069 ], [ 29.800724430379748, -1.487158275862069 ], [ 29.80045493670886, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32485, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.487158275862069 ], [ 29.800724430379748, -1.48742775862069 ], [ 29.800993924050633, -1.48742775862069 ], [ 29.800993924050633, -1.487158275862069 ], [ 29.800724430379748, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32486, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.487158275862069 ], [ 29.800993924050633, -1.48742775862069 ], [ 29.801263417721518, -1.48742775862069 ], [ 29.801263417721518, -1.487158275862069 ], [ 29.800993924050633, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32487, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.487158275862069 ], [ 29.801263417721518, -1.48742775862069 ], [ 29.801532911392407, -1.48742775862069 ], [ 29.801532911392407, -1.487158275862069 ], [ 29.801263417721518, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32488, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.487158275862069 ], [ 29.801532911392407, -1.48742775862069 ], [ 29.802071898734177, -1.48742775862069 ], [ 29.802071898734177, -1.487158275862069 ], [ 29.801532911392407, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32489, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.487158275862069 ], [ 29.802071898734177, -1.48742775862069 ], [ 29.802341392405065, -1.48742775862069 ], [ 29.802341392405065, -1.487158275862069 ], [ 29.802071898734177, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32490, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.48742775862069 ], [ 29.767307215189874, -1.48769724137931 ], [ 29.767576708860759, -1.48769724137931 ], [ 29.767576708860759, -1.48742775862069 ], [ 29.767307215189874, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32491, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.48742775862069 ], [ 29.767576708860759, -1.48769724137931 ], [ 29.767846202531643, -1.48769724137931 ], [ 29.767846202531643, -1.48742775862069 ], [ 29.767576708860759, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32492, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.48742775862069 ], [ 29.767846202531643, -1.48769724137931 ], [ 29.768115696202532, -1.48769724137931 ], [ 29.768115696202532, -1.48742775862069 ], [ 29.767846202531643, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32493, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.48742775862069 ], [ 29.768115696202532, -1.48769724137931 ], [ 29.768385189873417, -1.48769724137931 ], [ 29.768385189873417, -1.48742775862069 ], [ 29.768115696202532, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32494, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.48742775862069 ], [ 29.768385189873417, -1.48769724137931 ], [ 29.768654683544302, -1.48769724137931 ], [ 29.768654683544302, -1.48742775862069 ], [ 29.768385189873417, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32495, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.48742775862069 ], [ 29.768654683544302, -1.48769724137931 ], [ 29.76892417721519, -1.48769724137931 ], [ 29.76892417721519, -1.48742775862069 ], [ 29.768654683544302, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32496, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.48742775862069 ], [ 29.76892417721519, -1.48769724137931 ], [ 29.769193670886075, -1.48769724137931 ], [ 29.769193670886075, -1.48742775862069 ], [ 29.76892417721519, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32497, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.48742775862069 ], [ 29.769193670886075, -1.48769724137931 ], [ 29.76946316455696, -1.48769724137931 ], [ 29.76946316455696, -1.48742775862069 ], [ 29.769193670886075, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32498, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.48742775862069 ], [ 29.76946316455696, -1.48769724137931 ], [ 29.769732658227849, -1.48769724137931 ], [ 29.769732658227849, -1.48742775862069 ], [ 29.76946316455696, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32499, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.48742775862069 ], [ 29.769732658227849, -1.48769724137931 ], [ 29.770002151898733, -1.48769724137931 ], [ 29.770002151898733, -1.48742775862069 ], [ 29.769732658227849, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32500, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.48742775862069 ], [ 29.770002151898733, -1.48769724137931 ], [ 29.770271645569618, -1.48769724137931 ], [ 29.770271645569618, -1.48742775862069 ], [ 29.770002151898733, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32501, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.48742775862069 ], [ 29.770271645569618, -1.48769724137931 ], [ 29.770541139240507, -1.48769724137931 ], [ 29.770541139240507, -1.48742775862069 ], [ 29.770271645569618, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32502, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.48742775862069 ], [ 29.770541139240507, -1.48769724137931 ], [ 29.770810632911392, -1.48769724137931 ], [ 29.770810632911392, -1.48742775862069 ], [ 29.770541139240507, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32503, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.48742775862069 ], [ 29.770810632911392, -1.48769724137931 ], [ 29.771080126582277, -1.48769724137931 ], [ 29.771080126582277, -1.48742775862069 ], [ 29.770810632911392, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32504, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.48742775862069 ], [ 29.771080126582277, -1.48769724137931 ], [ 29.771349620253165, -1.48769724137931 ], [ 29.771349620253165, -1.48742775862069 ], [ 29.771080126582277, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32505, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.48742775862069 ], [ 29.771349620253165, -1.48769724137931 ], [ 29.77161911392405, -1.48769724137931 ], [ 29.77161911392405, -1.48742775862069 ], [ 29.771349620253165, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32506, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.48742775862069 ], [ 29.77161911392405, -1.48769724137931 ], [ 29.771888607594935, -1.48769724137931 ], [ 29.771888607594935, -1.48742775862069 ], [ 29.77161911392405, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32507, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.48742775862069 ], [ 29.771888607594935, -1.48769724137931 ], [ 29.772158101265823, -1.48769724137931 ], [ 29.772158101265823, -1.48742775862069 ], [ 29.771888607594935, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32508, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.48742775862069 ], [ 29.772158101265823, -1.48769724137931 ], [ 29.772427594936708, -1.48769724137931 ], [ 29.772427594936708, -1.48742775862069 ], [ 29.772158101265823, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32509, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.48742775862069 ], [ 29.772427594936708, -1.48769724137931 ], [ 29.772697088607593, -1.48769724137931 ], [ 29.772697088607593, -1.48742775862069 ], [ 29.772427594936708, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32510, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.48742775862069 ], [ 29.772697088607593, -1.48769724137931 ], [ 29.772966582278482, -1.48769724137931 ], [ 29.772966582278482, -1.48742775862069 ], [ 29.772697088607593, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32511, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.48742775862069 ], [ 29.772966582278482, -1.48769724137931 ], [ 29.773236075949367, -1.48769724137931 ], [ 29.773236075949367, -1.48742775862069 ], [ 29.772966582278482, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32512, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.48742775862069 ], [ 29.773236075949367, -1.48769724137931 ], [ 29.773505569620252, -1.48769724137931 ], [ 29.773505569620252, -1.48742775862069 ], [ 29.773236075949367, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32513, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.48742775862069 ], [ 29.773505569620252, -1.48769724137931 ], [ 29.77377506329114, -1.48769724137931 ], [ 29.77377506329114, -1.48742775862069 ], [ 29.773505569620252, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32514, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.48742775862069 ], [ 29.77377506329114, -1.48769724137931 ], [ 29.774044556962025, -1.48769724137931 ], [ 29.774044556962025, -1.48742775862069 ], [ 29.77377506329114, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32515, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.48742775862069 ], [ 29.774044556962025, -1.48769724137931 ], [ 29.77431405063291, -1.48769724137931 ], [ 29.77431405063291, -1.48742775862069 ], [ 29.774044556962025, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32516, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.48742775862069 ], [ 29.77431405063291, -1.48769724137931 ], [ 29.774853037974683, -1.48769724137931 ], [ 29.774853037974683, -1.48742775862069 ], [ 29.77431405063291, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32517, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.48742775862069 ], [ 29.774853037974683, -1.48769724137931 ], [ 29.775122531645568, -1.48769724137931 ], [ 29.775122531645568, -1.48742775862069 ], [ 29.774853037974683, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32518, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.48742775862069 ], [ 29.775122531645568, -1.48769724137931 ], [ 29.775392025316457, -1.48769724137931 ], [ 29.775392025316457, -1.48742775862069 ], [ 29.775122531645568, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32519, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.48742775862069 ], [ 29.775392025316457, -1.48769724137931 ], [ 29.775661518987341, -1.48769724137931 ], [ 29.775661518987341, -1.48742775862069 ], [ 29.775392025316457, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32520, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.48742775862069 ], [ 29.775661518987341, -1.48769724137931 ], [ 29.775931012658226, -1.48769724137931 ], [ 29.775931012658226, -1.48742775862069 ], [ 29.775661518987341, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32521, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.48742775862069 ], [ 29.775931012658226, -1.48769724137931 ], [ 29.776200506329115, -1.48769724137931 ], [ 29.776200506329115, -1.48742775862069 ], [ 29.775931012658226, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32522, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.48742775862069 ], [ 29.776200506329115, -1.48769724137931 ], [ 29.77647, -1.48769724137931 ], [ 29.77647, -1.48742775862069 ], [ 29.776200506329115, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32523, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.48742775862069 ], [ 29.77647, -1.48769724137931 ], [ 29.776739493670885, -1.48769724137931 ], [ 29.776739493670885, -1.48742775862069 ], [ 29.77647, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32524, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.48742775862069 ], [ 29.776739493670885, -1.48769724137931 ], [ 29.777008987341773, -1.48769724137931 ], [ 29.777008987341773, -1.48742775862069 ], [ 29.776739493670885, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32525, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.48742775862069 ], [ 29.777008987341773, -1.48769724137931 ], [ 29.777278481012658, -1.48769724137931 ], [ 29.777278481012658, -1.48742775862069 ], [ 29.777008987341773, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32526, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.48742775862069 ], [ 29.777278481012658, -1.48769724137931 ], [ 29.777547974683543, -1.48769724137931 ], [ 29.777547974683543, -1.48742775862069 ], [ 29.777278481012658, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32527, "El": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.48742775862069 ], [ 29.777547974683543, -1.48769724137931 ], [ 29.777817468354431, -1.48769724137931 ], [ 29.777817468354431, -1.48742775862069 ], [ 29.777547974683543, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32528, "El": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.48742775862069 ], [ 29.777817468354431, -1.48769724137931 ], [ 29.778086962025316, -1.48769724137931 ], [ 29.778086962025316, -1.48742775862069 ], [ 29.777817468354431, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32529, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.48742775862069 ], [ 29.778086962025316, -1.48769724137931 ], [ 29.778356455696201, -1.48769724137931 ], [ 29.778356455696201, -1.48742775862069 ], [ 29.778086962025316, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32530, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.48742775862069 ], [ 29.778356455696201, -1.48769724137931 ], [ 29.77862594936709, -1.48769724137931 ], [ 29.77862594936709, -1.48742775862069 ], [ 29.778356455696201, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32531, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.48742775862069 ], [ 29.77862594936709, -1.48769724137931 ], [ 29.778895443037975, -1.48769724137931 ], [ 29.778895443037975, -1.48742775862069 ], [ 29.77862594936709, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32532, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.48742775862069 ], [ 29.778895443037975, -1.48769724137931 ], [ 29.77916493670886, -1.48769724137931 ], [ 29.77916493670886, -1.48742775862069 ], [ 29.778895443037975, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32533, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.48742775862069 ], [ 29.77916493670886, -1.48769724137931 ], [ 29.779434430379748, -1.48769724137931 ], [ 29.779434430379748, -1.48742775862069 ], [ 29.77916493670886, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32534, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.48742775862069 ], [ 29.779434430379748, -1.48769724137931 ], [ 29.779703924050633, -1.48769724137931 ], [ 29.779703924050633, -1.48742775862069 ], [ 29.779434430379748, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32535, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.48742775862069 ], [ 29.779703924050633, -1.48769724137931 ], [ 29.779973417721518, -1.48769724137931 ], [ 29.779973417721518, -1.48742775862069 ], [ 29.779703924050633, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32536, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.48742775862069 ], [ 29.779973417721518, -1.48769724137931 ], [ 29.780242911392406, -1.48769724137931 ], [ 29.780242911392406, -1.48742775862069 ], [ 29.779973417721518, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32537, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.48742775862069 ], [ 29.780242911392406, -1.48769724137931 ], [ 29.780512405063291, -1.48769724137931 ], [ 29.780512405063291, -1.48742775862069 ], [ 29.780242911392406, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32538, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.48742775862069 ], [ 29.780512405063291, -1.48769724137931 ], [ 29.780781898734176, -1.48769724137931 ], [ 29.780781898734176, -1.48742775862069 ], [ 29.780512405063291, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32539, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.48742775862069 ], [ 29.780781898734176, -1.48769724137931 ], [ 29.781051392405065, -1.48769724137931 ], [ 29.781051392405065, -1.48742775862069 ], [ 29.780781898734176, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32540, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.48742775862069 ], [ 29.781051392405065, -1.48769724137931 ], [ 29.78132088607595, -1.48769724137931 ], [ 29.78132088607595, -1.48742775862069 ], [ 29.781051392405065, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32541, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.48742775862069 ], [ 29.78132088607595, -1.48769724137931 ], [ 29.781590379746834, -1.48769724137931 ], [ 29.781590379746834, -1.48742775862069 ], [ 29.78132088607595, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32542, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.48742775862069 ], [ 29.781590379746834, -1.48769724137931 ], [ 29.781859873417723, -1.48769724137931 ], [ 29.781859873417723, -1.48742775862069 ], [ 29.781590379746834, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32543, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.48742775862069 ], [ 29.781859873417723, -1.48769724137931 ], [ 29.782129367088608, -1.48769724137931 ], [ 29.782129367088608, -1.48742775862069 ], [ 29.781859873417723, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32544, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.48742775862069 ], [ 29.782398860759493, -1.48769724137931 ], [ 29.782668354430381, -1.48769724137931 ], [ 29.782668354430381, -1.48742775862069 ], [ 29.782398860759493, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32545, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.48742775862069 ], [ 29.782668354430381, -1.48769724137931 ], [ 29.782937848101266, -1.48769724137931 ], [ 29.782937848101266, -1.48742775862069 ], [ 29.782668354430381, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32546, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.48742775862069 ], [ 29.782937848101266, -1.48769724137931 ], [ 29.783207341772151, -1.48769724137931 ], [ 29.783207341772151, -1.48742775862069 ], [ 29.782937848101266, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32547, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.48742775862069 ], [ 29.783207341772151, -1.48769724137931 ], [ 29.783476835443039, -1.48769724137931 ], [ 29.783476835443039, -1.48742775862069 ], [ 29.783207341772151, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32548, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.48742775862069 ], [ 29.783476835443039, -1.48769724137931 ], [ 29.784015822784809, -1.48769724137931 ], [ 29.784015822784809, -1.48742775862069 ], [ 29.783476835443039, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32549, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.48742775862069 ], [ 29.784015822784809, -1.48769724137931 ], [ 29.784285316455698, -1.48769724137931 ], [ 29.784285316455698, -1.48742775862069 ], [ 29.784015822784809, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32550, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.48742775862069 ], [ 29.784285316455698, -1.48769724137931 ], [ 29.784554810126583, -1.48769724137931 ], [ 29.784554810126583, -1.48742775862069 ], [ 29.784285316455698, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32551, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.48742775862069 ], [ 29.784554810126583, -1.48769724137931 ], [ 29.784824303797468, -1.48769724137931 ], [ 29.784824303797468, -1.48742775862069 ], [ 29.784554810126583, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32552, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.48742775862069 ], [ 29.784824303797468, -1.48769724137931 ], [ 29.785093797468356, -1.48769724137931 ], [ 29.785093797468356, -1.48742775862069 ], [ 29.784824303797468, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32553, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.48742775862069 ], [ 29.785093797468356, -1.48769724137931 ], [ 29.785363291139241, -1.48769724137931 ], [ 29.785363291139241, -1.48742775862069 ], [ 29.785093797468356, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32554, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.48742775862069 ], [ 29.785363291139241, -1.48769724137931 ], [ 29.785632784810126, -1.48769724137931 ], [ 29.785632784810126, -1.48742775862069 ], [ 29.785363291139241, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32555, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.48742775862069 ], [ 29.785632784810126, -1.48769724137931 ], [ 29.785902278481014, -1.48769724137931 ], [ 29.785902278481014, -1.48742775862069 ], [ 29.785632784810126, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32556, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.48742775862069 ], [ 29.785902278481014, -1.48769724137931 ], [ 29.786171772151899, -1.48769724137931 ], [ 29.786171772151899, -1.48742775862069 ], [ 29.785902278481014, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32557, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.48742775862069 ], [ 29.786171772151899, -1.48769724137931 ], [ 29.786441265822784, -1.48769724137931 ], [ 29.786441265822784, -1.48742775862069 ], [ 29.786171772151899, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32558, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.48742775862069 ], [ 29.786441265822784, -1.48769724137931 ], [ 29.786710759493673, -1.48769724137931 ], [ 29.786710759493673, -1.48742775862069 ], [ 29.786441265822784, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32559, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.48742775862069 ], [ 29.786710759493673, -1.48769724137931 ], [ 29.787249746835442, -1.48769724137931 ], [ 29.787249746835442, -1.48742775862069 ], [ 29.786710759493673, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32560, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.48742775862069 ], [ 29.79991594936709, -1.48769724137931 ], [ 29.800185443037975, -1.48769724137931 ], [ 29.800185443037975, -1.48742775862069 ], [ 29.79991594936709, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32561, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.48742775862069 ], [ 29.800185443037975, -1.48769724137931 ], [ 29.80045493670886, -1.48769724137931 ], [ 29.80045493670886, -1.48742775862069 ], [ 29.800185443037975, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32562, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.48742775862069 ], [ 29.80045493670886, -1.48769724137931 ], [ 29.800724430379748, -1.48769724137931 ], [ 29.800724430379748, -1.48742775862069 ], [ 29.80045493670886, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32563, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.48742775862069 ], [ 29.800724430379748, -1.48769724137931 ], [ 29.800993924050633, -1.48769724137931 ], [ 29.800993924050633, -1.48742775862069 ], [ 29.800724430379748, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32564, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.48742775862069 ], [ 29.800993924050633, -1.48769724137931 ], [ 29.801263417721518, -1.48769724137931 ], [ 29.801263417721518, -1.48742775862069 ], [ 29.800993924050633, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32565, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.48742775862069 ], [ 29.801263417721518, -1.48769724137931 ], [ 29.801532911392407, -1.48769724137931 ], [ 29.801532911392407, -1.48742775862069 ], [ 29.801263417721518, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32566, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.48742775862069 ], [ 29.801532911392407, -1.48769724137931 ], [ 29.802071898734177, -1.48769724137931 ], [ 29.802071898734177, -1.48742775862069 ], [ 29.801532911392407, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32567, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.48742775862069 ], [ 29.802071898734177, -1.48769724137931 ], [ 29.802341392405065, -1.48769724137931 ], [ 29.802341392405065, -1.48742775862069 ], [ 29.802071898734177, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32568, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.48742775862069 ], [ 29.802341392405065, -1.48769724137931 ], [ 29.80261088607595, -1.48769724137931 ], [ 29.80261088607595, -1.48742775862069 ], [ 29.802341392405065, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32569, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767307215189874, -1.48769724137931 ], [ 29.767307215189874, -1.488236206896552 ], [ 29.767576708860759, -1.488236206896552 ], [ 29.767576708860759, -1.48769724137931 ], [ 29.767307215189874, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32570, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.48769724137931 ], [ 29.767576708860759, -1.487966724137931 ], [ 29.767846202531643, -1.487966724137931 ], [ 29.767846202531643, -1.48769724137931 ], [ 29.767576708860759, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32571, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.48769724137931 ], [ 29.767846202531643, -1.487966724137931 ], [ 29.768115696202532, -1.487966724137931 ], [ 29.768115696202532, -1.48769724137931 ], [ 29.767846202531643, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32572, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.48769724137931 ], [ 29.768115696202532, -1.487966724137931 ], [ 29.768385189873417, -1.487966724137931 ], [ 29.768385189873417, -1.48769724137931 ], [ 29.768115696202532, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32573, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.48769724137931 ], [ 29.768385189873417, -1.487966724137931 ], [ 29.768654683544302, -1.487966724137931 ], [ 29.768654683544302, -1.48769724137931 ], [ 29.768385189873417, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32574, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.48769724137931 ], [ 29.768654683544302, -1.487966724137931 ], [ 29.76892417721519, -1.487966724137931 ], [ 29.76892417721519, -1.48769724137931 ], [ 29.768654683544302, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32575, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.48769724137931 ], [ 29.76892417721519, -1.487966724137931 ], [ 29.769193670886075, -1.487966724137931 ], [ 29.769193670886075, -1.48769724137931 ], [ 29.76892417721519, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32576, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.48769724137931 ], [ 29.769193670886075, -1.487966724137931 ], [ 29.76946316455696, -1.487966724137931 ], [ 29.76946316455696, -1.48769724137931 ], [ 29.769193670886075, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32577, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.48769724137931 ], [ 29.76946316455696, -1.487966724137931 ], [ 29.769732658227849, -1.487966724137931 ], [ 29.769732658227849, -1.48769724137931 ], [ 29.76946316455696, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32578, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.48769724137931 ], [ 29.769732658227849, -1.487966724137931 ], [ 29.770002151898733, -1.487966724137931 ], [ 29.770002151898733, -1.48769724137931 ], [ 29.769732658227849, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32579, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.48769724137931 ], [ 29.770002151898733, -1.487966724137931 ], [ 29.770271645569618, -1.487966724137931 ], [ 29.770271645569618, -1.48769724137931 ], [ 29.770002151898733, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32580, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.48769724137931 ], [ 29.770271645569618, -1.487966724137931 ], [ 29.770541139240507, -1.487966724137931 ], [ 29.770541139240507, -1.48769724137931 ], [ 29.770271645569618, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32581, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.48769724137931 ], [ 29.770541139240507, -1.487966724137931 ], [ 29.770810632911392, -1.487966724137931 ], [ 29.770810632911392, -1.48769724137931 ], [ 29.770541139240507, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32582, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.48769724137931 ], [ 29.770810632911392, -1.487966724137931 ], [ 29.771080126582277, -1.487966724137931 ], [ 29.771080126582277, -1.48769724137931 ], [ 29.770810632911392, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32583, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.48769724137931 ], [ 29.771080126582277, -1.487966724137931 ], [ 29.771349620253165, -1.487966724137931 ], [ 29.771349620253165, -1.48769724137931 ], [ 29.771080126582277, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32584, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.48769724137931 ], [ 29.771349620253165, -1.487966724137931 ], [ 29.77161911392405, -1.487966724137931 ], [ 29.77161911392405, -1.48769724137931 ], [ 29.771349620253165, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32585, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.48769724137931 ], [ 29.77161911392405, -1.487966724137931 ], [ 29.771888607594935, -1.487966724137931 ], [ 29.771888607594935, -1.48769724137931 ], [ 29.77161911392405, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32586, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.48769724137931 ], [ 29.771888607594935, -1.487966724137931 ], [ 29.772158101265823, -1.487966724137931 ], [ 29.772158101265823, -1.48769724137931 ], [ 29.771888607594935, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32587, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.48769724137931 ], [ 29.772158101265823, -1.487966724137931 ], [ 29.772427594936708, -1.487966724137931 ], [ 29.772427594936708, -1.48769724137931 ], [ 29.772158101265823, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32588, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.48769724137931 ], [ 29.772427594936708, -1.487966724137931 ], [ 29.772697088607593, -1.487966724137931 ], [ 29.772697088607593, -1.48769724137931 ], [ 29.772427594936708, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32589, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.48769724137931 ], [ 29.772697088607593, -1.487966724137931 ], [ 29.772966582278482, -1.487966724137931 ], [ 29.772966582278482, -1.48769724137931 ], [ 29.772697088607593, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32590, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.48769724137931 ], [ 29.772966582278482, -1.487966724137931 ], [ 29.773236075949367, -1.487966724137931 ], [ 29.773236075949367, -1.48769724137931 ], [ 29.772966582278482, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32591, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.48769724137931 ], [ 29.773236075949367, -1.487966724137931 ], [ 29.773505569620252, -1.487966724137931 ], [ 29.773505569620252, -1.48769724137931 ], [ 29.773236075949367, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32592, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.48769724137931 ], [ 29.773505569620252, -1.487966724137931 ], [ 29.77377506329114, -1.487966724137931 ], [ 29.77377506329114, -1.48769724137931 ], [ 29.773505569620252, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32593, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.48769724137931 ], [ 29.77377506329114, -1.487966724137931 ], [ 29.774044556962025, -1.487966724137931 ], [ 29.774044556962025, -1.48769724137931 ], [ 29.77377506329114, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32594, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.48769724137931 ], [ 29.774044556962025, -1.487966724137931 ], [ 29.77431405063291, -1.487966724137931 ], [ 29.77431405063291, -1.48769724137931 ], [ 29.774044556962025, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32595, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.48769724137931 ], [ 29.77431405063291, -1.487966724137931 ], [ 29.774853037974683, -1.487966724137931 ], [ 29.774853037974683, -1.48769724137931 ], [ 29.77431405063291, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32596, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.48769724137931 ], [ 29.774853037974683, -1.487966724137931 ], [ 29.775122531645568, -1.487966724137931 ], [ 29.775122531645568, -1.48769724137931 ], [ 29.774853037974683, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32597, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.48769724137931 ], [ 29.775122531645568, -1.487966724137931 ], [ 29.775392025316457, -1.487966724137931 ], [ 29.775392025316457, -1.48769724137931 ], [ 29.775122531645568, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32598, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.48769724137931 ], [ 29.775392025316457, -1.487966724137931 ], [ 29.775661518987341, -1.487966724137931 ], [ 29.775661518987341, -1.48769724137931 ], [ 29.775392025316457, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32599, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.48769724137931 ], [ 29.775661518987341, -1.487966724137931 ], [ 29.775931012658226, -1.487966724137931 ], [ 29.775931012658226, -1.48769724137931 ], [ 29.775661518987341, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32600, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.48769724137931 ], [ 29.775931012658226, -1.487966724137931 ], [ 29.776200506329115, -1.487966724137931 ], [ 29.776200506329115, -1.48769724137931 ], [ 29.775931012658226, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32601, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.48769724137931 ], [ 29.776200506329115, -1.487966724137931 ], [ 29.77647, -1.487966724137931 ], [ 29.77647, -1.48769724137931 ], [ 29.776200506329115, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32602, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.48769724137931 ], [ 29.77647, -1.487966724137931 ], [ 29.776739493670885, -1.487966724137931 ], [ 29.776739493670885, -1.48769724137931 ], [ 29.77647, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32603, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.48769724137931 ], [ 29.776739493670885, -1.487966724137931 ], [ 29.777008987341773, -1.487966724137931 ], [ 29.777008987341773, -1.48769724137931 ], [ 29.776739493670885, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32604, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.48769724137931 ], [ 29.777008987341773, -1.487966724137931 ], [ 29.777278481012658, -1.487966724137931 ], [ 29.777278481012658, -1.48769724137931 ], [ 29.777008987341773, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32605, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.48769724137931 ], [ 29.777278481012658, -1.487966724137931 ], [ 29.777547974683543, -1.487966724137931 ], [ 29.777547974683543, -1.48769724137931 ], [ 29.777278481012658, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32606, "El": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.48769724137931 ], [ 29.777547974683543, -1.487966724137931 ], [ 29.777817468354431, -1.487966724137931 ], [ 29.777817468354431, -1.48769724137931 ], [ 29.777547974683543, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32607, "El": 88 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.48769724137931 ], [ 29.777817468354431, -1.487966724137931 ], [ 29.778086962025316, -1.487966724137931 ], [ 29.778086962025316, -1.48769724137931 ], [ 29.777817468354431, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32608, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.48769724137931 ], [ 29.778086962025316, -1.487966724137931 ], [ 29.778356455696201, -1.487966724137931 ], [ 29.778356455696201, -1.48769724137931 ], [ 29.778086962025316, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32609, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.48769724137931 ], [ 29.778356455696201, -1.487966724137931 ], [ 29.77862594936709, -1.487966724137931 ], [ 29.77862594936709, -1.48769724137931 ], [ 29.778356455696201, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32610, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.48769724137931 ], [ 29.77862594936709, -1.487966724137931 ], [ 29.778895443037975, -1.487966724137931 ], [ 29.778895443037975, -1.48769724137931 ], [ 29.77862594936709, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32611, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.48769724137931 ], [ 29.778895443037975, -1.487966724137931 ], [ 29.77916493670886, -1.487966724137931 ], [ 29.77916493670886, -1.48769724137931 ], [ 29.778895443037975, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32612, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.48769724137931 ], [ 29.77916493670886, -1.487966724137931 ], [ 29.779434430379748, -1.487966724137931 ], [ 29.779434430379748, -1.48769724137931 ], [ 29.77916493670886, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32613, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.48769724137931 ], [ 29.779434430379748, -1.487966724137931 ], [ 29.779703924050633, -1.487966724137931 ], [ 29.779703924050633, -1.48769724137931 ], [ 29.779434430379748, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32614, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.48769724137931 ], [ 29.779703924050633, -1.487966724137931 ], [ 29.779973417721518, -1.487966724137931 ], [ 29.779973417721518, -1.48769724137931 ], [ 29.779703924050633, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32615, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.48769724137931 ], [ 29.779973417721518, -1.487966724137931 ], [ 29.780242911392406, -1.487966724137931 ], [ 29.780242911392406, -1.48769724137931 ], [ 29.779973417721518, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32616, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.48769724137931 ], [ 29.780242911392406, -1.487966724137931 ], [ 29.780512405063291, -1.487966724137931 ], [ 29.780512405063291, -1.48769724137931 ], [ 29.780242911392406, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32617, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.48769724137931 ], [ 29.780512405063291, -1.487966724137931 ], [ 29.780781898734176, -1.487966724137931 ], [ 29.780781898734176, -1.48769724137931 ], [ 29.780512405063291, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32618, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.48769724137931 ], [ 29.780781898734176, -1.487966724137931 ], [ 29.781051392405065, -1.487966724137931 ], [ 29.781051392405065, -1.48769724137931 ], [ 29.780781898734176, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32619, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.48769724137931 ], [ 29.781051392405065, -1.487966724137931 ], [ 29.78132088607595, -1.487966724137931 ], [ 29.78132088607595, -1.48769724137931 ], [ 29.781051392405065, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32620, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.48769724137931 ], [ 29.78132088607595, -1.487966724137931 ], [ 29.781590379746834, -1.487966724137931 ], [ 29.781590379746834, -1.48769724137931 ], [ 29.78132088607595, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32621, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.48769724137931 ], [ 29.781590379746834, -1.487966724137931 ], [ 29.781859873417723, -1.487966724137931 ], [ 29.781859873417723, -1.48769724137931 ], [ 29.781590379746834, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32622, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.48769724137931 ], [ 29.781859873417723, -1.487966724137931 ], [ 29.782129367088608, -1.487966724137931 ], [ 29.782129367088608, -1.48769724137931 ], [ 29.781859873417723, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32623, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.48769724137931 ], [ 29.782129367088608, -1.487966724137931 ], [ 29.782398860759493, -1.487966724137931 ], [ 29.782398860759493, -1.48769724137931 ], [ 29.782129367088608, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32624, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.48769724137931 ], [ 29.782398860759493, -1.487966724137931 ], [ 29.782668354430381, -1.487966724137931 ], [ 29.782668354430381, -1.48769724137931 ], [ 29.782398860759493, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32625, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.48769724137931 ], [ 29.782668354430381, -1.487966724137931 ], [ 29.783207341772151, -1.487966724137931 ], [ 29.783207341772151, -1.48769724137931 ], [ 29.782668354430381, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32626, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.48769724137931 ], [ 29.783207341772151, -1.487966724137931 ], [ 29.784015822784809, -1.487966724137931 ], [ 29.784015822784809, -1.48769724137931 ], [ 29.783207341772151, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32627, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.48769724137931 ], [ 29.784015822784809, -1.487966724137931 ], [ 29.784285316455698, -1.487966724137931 ], [ 29.784285316455698, -1.48769724137931 ], [ 29.784015822784809, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32628, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784554810126583, -1.48769724137931 ], [ 29.784554810126583, -1.487966724137931 ], [ 29.784824303797468, -1.487966724137931 ], [ 29.784824303797468, -1.48769724137931 ], [ 29.784554810126583, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32629, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.48769724137931 ], [ 29.784824303797468, -1.487966724137931 ], [ 29.785093797468356, -1.487966724137931 ], [ 29.785093797468356, -1.48769724137931 ], [ 29.784824303797468, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32630, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.48769724137931 ], [ 29.785093797468356, -1.487966724137931 ], [ 29.785363291139241, -1.487966724137931 ], [ 29.785363291139241, -1.48769724137931 ], [ 29.785093797468356, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32631, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.48769724137931 ], [ 29.785363291139241, -1.487966724137931 ], [ 29.785632784810126, -1.487966724137931 ], [ 29.785632784810126, -1.48769724137931 ], [ 29.785363291139241, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32632, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.48769724137931 ], [ 29.785632784810126, -1.487966724137931 ], [ 29.785902278481014, -1.487966724137931 ], [ 29.785902278481014, -1.48769724137931 ], [ 29.785632784810126, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32633, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.48769724137931 ], [ 29.785902278481014, -1.487966724137931 ], [ 29.786171772151899, -1.487966724137931 ], [ 29.786171772151899, -1.48769724137931 ], [ 29.785902278481014, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32634, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.48769724137931 ], [ 29.786171772151899, -1.487966724137931 ], [ 29.786441265822784, -1.487966724137931 ], [ 29.786441265822784, -1.48769724137931 ], [ 29.786171772151899, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32635, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.48769724137931 ], [ 29.786441265822784, -1.487966724137931 ], [ 29.786710759493673, -1.487966724137931 ], [ 29.786710759493673, -1.48769724137931 ], [ 29.786441265822784, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32636, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.48769724137931 ], [ 29.786710759493673, -1.487966724137931 ], [ 29.786980253164558, -1.487966724137931 ], [ 29.786980253164558, -1.48769724137931 ], [ 29.786710759493673, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32637, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.48769724137931 ], [ 29.786980253164558, -1.487966724137931 ], [ 29.787788734177216, -1.487966724137931 ], [ 29.787788734177216, -1.489044655172414 ], [ 29.788058227848101, -1.489044655172414 ], [ 29.788058227848101, -1.48769724137931 ], [ 29.786980253164558, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32638, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.79991594936709, -1.48769724137931 ], [ 29.79991594936709, -1.487966724137931 ], [ 29.800185443037975, -1.487966724137931 ], [ 29.800185443037975, -1.48769724137931 ], [ 29.79991594936709, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32639, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.48769724137931 ], [ 29.800185443037975, -1.487966724137931 ], [ 29.80045493670886, -1.487966724137931 ], [ 29.80045493670886, -1.48769724137931 ], [ 29.800185443037975, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32640, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.48769724137931 ], [ 29.80045493670886, -1.487966724137931 ], [ 29.800724430379748, -1.487966724137931 ], [ 29.800724430379748, -1.48769724137931 ], [ 29.80045493670886, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32641, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.48769724137931 ], [ 29.800724430379748, -1.487966724137931 ], [ 29.800993924050633, -1.487966724137931 ], [ 29.800993924050633, -1.48769724137931 ], [ 29.800724430379748, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32642, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.48769724137931 ], [ 29.800993924050633, -1.487966724137931 ], [ 29.801263417721518, -1.487966724137931 ], [ 29.801263417721518, -1.48769724137931 ], [ 29.800993924050633, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32643, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.48769724137931 ], [ 29.801263417721518, -1.487966724137931 ], [ 29.801532911392407, -1.487966724137931 ], [ 29.801532911392407, -1.48769724137931 ], [ 29.801263417721518, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32644, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.48769724137931 ], [ 29.801532911392407, -1.487966724137931 ], [ 29.802071898734177, -1.487966724137931 ], [ 29.802071898734177, -1.48769724137931 ], [ 29.801532911392407, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32645, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.48769724137931 ], [ 29.802071898734177, -1.488236206896552 ], [ 29.802341392405065, -1.488236206896552 ], [ 29.802341392405065, -1.48769724137931 ], [ 29.802071898734177, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32646, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.48769724137931 ], [ 29.802341392405065, -1.487966724137931 ], [ 29.80261088607595, -1.487966724137931 ], [ 29.80261088607595, -1.48769724137931 ], [ 29.802341392405065, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32647, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.487966724137931 ], [ 29.767576708860759, -1.488236206896552 ], [ 29.767846202531643, -1.488236206896552 ], [ 29.767846202531643, -1.487966724137931 ], [ 29.767576708860759, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32648, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.487966724137931 ], [ 29.767846202531643, -1.488236206896552 ], [ 29.768115696202532, -1.488236206896552 ], [ 29.768115696202532, -1.487966724137931 ], [ 29.767846202531643, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32649, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.487966724137931 ], [ 29.768115696202532, -1.488236206896552 ], [ 29.768385189873417, -1.488236206896552 ], [ 29.768385189873417, -1.487966724137931 ], [ 29.768115696202532, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32650, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.487966724137931 ], [ 29.768385189873417, -1.488236206896552 ], [ 29.768654683544302, -1.488236206896552 ], [ 29.768654683544302, -1.487966724137931 ], [ 29.768385189873417, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32651, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.487966724137931 ], [ 29.768654683544302, -1.488236206896552 ], [ 29.76892417721519, -1.488236206896552 ], [ 29.76892417721519, -1.487966724137931 ], [ 29.768654683544302, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32652, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.487966724137931 ], [ 29.76892417721519, -1.488236206896552 ], [ 29.769193670886075, -1.488236206896552 ], [ 29.769193670886075, -1.487966724137931 ], [ 29.76892417721519, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32653, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.487966724137931 ], [ 29.769193670886075, -1.488236206896552 ], [ 29.76946316455696, -1.488236206896552 ], [ 29.76946316455696, -1.487966724137931 ], [ 29.769193670886075, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32654, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.487966724137931 ], [ 29.76946316455696, -1.488236206896552 ], [ 29.769732658227849, -1.488236206896552 ], [ 29.769732658227849, -1.487966724137931 ], [ 29.76946316455696, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32655, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.487966724137931 ], [ 29.769732658227849, -1.488236206896552 ], [ 29.770002151898733, -1.488236206896552 ], [ 29.770002151898733, -1.487966724137931 ], [ 29.769732658227849, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32656, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.487966724137931 ], [ 29.770002151898733, -1.488236206896552 ], [ 29.770271645569618, -1.488236206896552 ], [ 29.770271645569618, -1.487966724137931 ], [ 29.770002151898733, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32657, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.487966724137931 ], [ 29.770271645569618, -1.488236206896552 ], [ 29.770541139240507, -1.488236206896552 ], [ 29.770541139240507, -1.487966724137931 ], [ 29.770271645569618, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32658, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.487966724137931 ], [ 29.770541139240507, -1.488236206896552 ], [ 29.770810632911392, -1.488236206896552 ], [ 29.770810632911392, -1.487966724137931 ], [ 29.770541139240507, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32659, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.487966724137931 ], [ 29.770810632911392, -1.488236206896552 ], [ 29.771080126582277, -1.488236206896552 ], [ 29.771080126582277, -1.487966724137931 ], [ 29.770810632911392, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32660, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.487966724137931 ], [ 29.771080126582277, -1.488236206896552 ], [ 29.771349620253165, -1.488236206896552 ], [ 29.771349620253165, -1.487966724137931 ], [ 29.771080126582277, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32661, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.487966724137931 ], [ 29.771349620253165, -1.488236206896552 ], [ 29.77161911392405, -1.488236206896552 ], [ 29.77161911392405, -1.487966724137931 ], [ 29.771349620253165, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32662, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.487966724137931 ], [ 29.77161911392405, -1.488236206896552 ], [ 29.772158101265823, -1.488236206896552 ], [ 29.772158101265823, -1.487966724137931 ], [ 29.77161911392405, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32663, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.487966724137931 ], [ 29.772158101265823, -1.488236206896552 ], [ 29.772427594936708, -1.488236206896552 ], [ 29.772427594936708, -1.487966724137931 ], [ 29.772158101265823, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32664, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.487966724137931 ], [ 29.772427594936708, -1.488236206896552 ], [ 29.772697088607593, -1.488236206896552 ], [ 29.772697088607593, -1.487966724137931 ], [ 29.772427594936708, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32665, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.487966724137931 ], [ 29.772697088607593, -1.488236206896552 ], [ 29.772966582278482, -1.488236206896552 ], [ 29.772966582278482, -1.487966724137931 ], [ 29.772697088607593, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32666, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.487966724137931 ], [ 29.772966582278482, -1.488236206896552 ], [ 29.773236075949367, -1.488236206896552 ], [ 29.773236075949367, -1.487966724137931 ], [ 29.772966582278482, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32667, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.487966724137931 ], [ 29.773236075949367, -1.488236206896552 ], [ 29.773505569620252, -1.488236206896552 ], [ 29.773505569620252, -1.487966724137931 ], [ 29.773236075949367, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32668, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.487966724137931 ], [ 29.773505569620252, -1.488236206896552 ], [ 29.77377506329114, -1.488236206896552 ], [ 29.77377506329114, -1.487966724137931 ], [ 29.773505569620252, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32669, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.487966724137931 ], [ 29.77377506329114, -1.488236206896552 ], [ 29.774044556962025, -1.488236206896552 ], [ 29.774044556962025, -1.487966724137931 ], [ 29.77377506329114, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32670, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.487966724137931 ], [ 29.774044556962025, -1.488236206896552 ], [ 29.77431405063291, -1.488236206896552 ], [ 29.77431405063291, -1.487966724137931 ], [ 29.774044556962025, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32671, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.487966724137931 ], [ 29.77431405063291, -1.488236206896552 ], [ 29.774853037974683, -1.488236206896552 ], [ 29.774853037974683, -1.487966724137931 ], [ 29.77431405063291, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32672, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.487966724137931 ], [ 29.774853037974683, -1.488236206896552 ], [ 29.775122531645568, -1.488236206896552 ], [ 29.775122531645568, -1.487966724137931 ], [ 29.774853037974683, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32673, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.487966724137931 ], [ 29.775122531645568, -1.488236206896552 ], [ 29.775392025316457, -1.488236206896552 ], [ 29.775392025316457, -1.487966724137931 ], [ 29.775122531645568, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32674, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.487966724137931 ], [ 29.775392025316457, -1.488236206896552 ], [ 29.775661518987341, -1.488236206896552 ], [ 29.775661518987341, -1.487966724137931 ], [ 29.775392025316457, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32675, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.487966724137931 ], [ 29.775661518987341, -1.488236206896552 ], [ 29.775931012658226, -1.488236206896552 ], [ 29.775931012658226, -1.487966724137931 ], [ 29.775661518987341, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32676, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.487966724137931 ], [ 29.775931012658226, -1.488236206896552 ], [ 29.776200506329115, -1.488236206896552 ], [ 29.776200506329115, -1.487966724137931 ], [ 29.775931012658226, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32677, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.487966724137931 ], [ 29.776200506329115, -1.488236206896552 ], [ 29.77647, -1.488236206896552 ], [ 29.77647, -1.487966724137931 ], [ 29.776200506329115, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32678, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.487966724137931 ], [ 29.77647, -1.488236206896552 ], [ 29.776739493670885, -1.488236206896552 ], [ 29.776739493670885, -1.487966724137931 ], [ 29.77647, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32679, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.487966724137931 ], [ 29.776739493670885, -1.488236206896552 ], [ 29.777008987341773, -1.488236206896552 ], [ 29.777008987341773, -1.487966724137931 ], [ 29.776739493670885, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32680, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.487966724137931 ], [ 29.777008987341773, -1.488236206896552 ], [ 29.777278481012658, -1.488236206896552 ], [ 29.777278481012658, -1.487966724137931 ], [ 29.777008987341773, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32681, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.487966724137931 ], [ 29.777278481012658, -1.488236206896552 ], [ 29.777547974683543, -1.488236206896552 ], [ 29.777547974683543, -1.487966724137931 ], [ 29.777278481012658, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32682, "El": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.487966724137931 ], [ 29.777547974683543, -1.488236206896552 ], [ 29.777817468354431, -1.488236206896552 ], [ 29.777817468354431, -1.487966724137931 ], [ 29.777547974683543, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32683, "El": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.487966724137931 ], [ 29.777817468354431, -1.488236206896552 ], [ 29.778086962025316, -1.488236206896552 ], [ 29.778086962025316, -1.487966724137931 ], [ 29.777817468354431, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32684, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.487966724137931 ], [ 29.778086962025316, -1.488236206896552 ], [ 29.778356455696201, -1.488236206896552 ], [ 29.778356455696201, -1.487966724137931 ], [ 29.778086962025316, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32685, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.487966724137931 ], [ 29.778356455696201, -1.488236206896552 ], [ 29.77862594936709, -1.488236206896552 ], [ 29.77862594936709, -1.487966724137931 ], [ 29.778356455696201, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32686, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.487966724137931 ], [ 29.77862594936709, -1.488236206896552 ], [ 29.778895443037975, -1.488236206896552 ], [ 29.778895443037975, -1.487966724137931 ], [ 29.77862594936709, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32687, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.487966724137931 ], [ 29.778895443037975, -1.488236206896552 ], [ 29.77916493670886, -1.488236206896552 ], [ 29.77916493670886, -1.487966724137931 ], [ 29.778895443037975, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32688, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.487966724137931 ], [ 29.77916493670886, -1.488236206896552 ], [ 29.779434430379748, -1.488236206896552 ], [ 29.779434430379748, -1.487966724137931 ], [ 29.77916493670886, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32689, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.487966724137931 ], [ 29.779434430379748, -1.488236206896552 ], [ 29.779703924050633, -1.488236206896552 ], [ 29.779703924050633, -1.487966724137931 ], [ 29.779434430379748, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32690, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.487966724137931 ], [ 29.779703924050633, -1.488236206896552 ], [ 29.779973417721518, -1.488236206896552 ], [ 29.779973417721518, -1.487966724137931 ], [ 29.779703924050633, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32691, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.487966724137931 ], [ 29.779973417721518, -1.488236206896552 ], [ 29.780242911392406, -1.488236206896552 ], [ 29.780242911392406, -1.487966724137931 ], [ 29.779973417721518, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32692, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.487966724137931 ], [ 29.780242911392406, -1.488236206896552 ], [ 29.780512405063291, -1.488236206896552 ], [ 29.780512405063291, -1.487966724137931 ], [ 29.780242911392406, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32693, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.487966724137931 ], [ 29.780512405063291, -1.488236206896552 ], [ 29.780781898734176, -1.488236206896552 ], [ 29.780781898734176, -1.487966724137931 ], [ 29.780512405063291, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32694, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.487966724137931 ], [ 29.780781898734176, -1.488236206896552 ], [ 29.781051392405065, -1.488236206896552 ], [ 29.781051392405065, -1.487966724137931 ], [ 29.780781898734176, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32695, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.487966724137931 ], [ 29.781051392405065, -1.488236206896552 ], [ 29.78132088607595, -1.488236206896552 ], [ 29.78132088607595, -1.487966724137931 ], [ 29.781051392405065, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32696, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.487966724137931 ], [ 29.78132088607595, -1.488236206896552 ], [ 29.781590379746834, -1.488236206896552 ], [ 29.781590379746834, -1.487966724137931 ], [ 29.78132088607595, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32697, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.487966724137931 ], [ 29.781590379746834, -1.488236206896552 ], [ 29.781859873417723, -1.488236206896552 ], [ 29.781859873417723, -1.487966724137931 ], [ 29.781590379746834, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32698, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.487966724137931 ], [ 29.781859873417723, -1.488236206896552 ], [ 29.782129367088608, -1.488236206896552 ], [ 29.782129367088608, -1.487966724137931 ], [ 29.781859873417723, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32699, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.487966724137931 ], [ 29.782129367088608, -1.488236206896552 ], [ 29.782398860759493, -1.488236206896552 ], [ 29.782398860759493, -1.487966724137931 ], [ 29.782129367088608, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32700, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.487966724137931 ], [ 29.782398860759493, -1.488236206896552 ], [ 29.782668354430381, -1.488236206896552 ], [ 29.782668354430381, -1.487966724137931 ], [ 29.782398860759493, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32701, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.487966724137931 ], [ 29.782668354430381, -1.488236206896552 ], [ 29.782937848101266, -1.488236206896552 ], [ 29.782937848101266, -1.487966724137931 ], [ 29.782668354430381, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32702, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.487966724137931 ], [ 29.782937848101266, -1.488236206896552 ], [ 29.783476835443039, -1.488236206896552 ], [ 29.783476835443039, -1.487966724137931 ], [ 29.782937848101266, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32703, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784285316455698, -1.48769724137931 ], [ 29.784554810126583, -1.48769724137931 ], [ 29.784554810126583, -1.487966724137931 ], [ 29.784824303797468, -1.487966724137931 ], [ 29.784824303797468, -1.488236206896552 ], [ 29.783476835443039, -1.488236206896552 ], [ 29.783476835443039, -1.487966724137931 ], [ 29.784285316455698, -1.487966724137931 ], [ 29.784285316455698, -1.48769724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32704, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785093797468356, -1.487966724137931 ], [ 29.785093797468356, -1.488236206896552 ], [ 29.785363291139241, -1.488236206896552 ], [ 29.785363291139241, -1.487966724137931 ], [ 29.785093797468356, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32705, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.487966724137931 ], [ 29.785363291139241, -1.488236206896552 ], [ 29.785632784810126, -1.488236206896552 ], [ 29.785632784810126, -1.487966724137931 ], [ 29.785363291139241, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32706, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.487966724137931 ], [ 29.785632784810126, -1.488236206896552 ], [ 29.785902278481014, -1.488236206896552 ], [ 29.785902278481014, -1.487966724137931 ], [ 29.785632784810126, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32707, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.487966724137931 ], [ 29.785902278481014, -1.488236206896552 ], [ 29.786171772151899, -1.488236206896552 ], [ 29.786171772151899, -1.487966724137931 ], [ 29.785902278481014, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32708, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.487966724137931 ], [ 29.786171772151899, -1.488236206896552 ], [ 29.786441265822784, -1.488236206896552 ], [ 29.786441265822784, -1.487966724137931 ], [ 29.786171772151899, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32709, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.487966724137931 ], [ 29.786441265822784, -1.488236206896552 ], [ 29.786710759493673, -1.488236206896552 ], [ 29.786710759493673, -1.487966724137931 ], [ 29.786441265822784, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32710, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.487966724137931 ], [ 29.786710759493673, -1.488236206896552 ], [ 29.786980253164558, -1.488236206896552 ], [ 29.786980253164558, -1.487966724137931 ], [ 29.786710759493673, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32711, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.487966724137931 ], [ 29.786980253164558, -1.488236206896552 ], [ 29.787519240506327, -1.488236206896552 ], [ 29.787519240506327, -1.488775172413793 ], [ 29.787788734177216, -1.488775172413793 ], [ 29.787788734177216, -1.487966724137931 ], [ 29.786980253164558, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32712, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800185443037975, -1.487966724137931 ], [ 29.800185443037975, -1.488236206896552 ], [ 29.80045493670886, -1.488236206896552 ], [ 29.80045493670886, -1.487966724137931 ], [ 29.800185443037975, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32713, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.487966724137931 ], [ 29.80045493670886, -1.488236206896552 ], [ 29.800724430379748, -1.488236206896552 ], [ 29.800724430379748, -1.487966724137931 ], [ 29.80045493670886, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32714, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.487966724137931 ], [ 29.800724430379748, -1.488236206896552 ], [ 29.800993924050633, -1.488236206896552 ], [ 29.800993924050633, -1.487966724137931 ], [ 29.800724430379748, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32715, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.487966724137931 ], [ 29.800993924050633, -1.488236206896552 ], [ 29.801263417721518, -1.488236206896552 ], [ 29.801263417721518, -1.487966724137931 ], [ 29.800993924050633, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32716, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.487966724137931 ], [ 29.801263417721518, -1.488775172413793 ], [ 29.801532911392407, -1.488775172413793 ], [ 29.801532911392407, -1.487966724137931 ], [ 29.801263417721518, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32717, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.487966724137931 ], [ 29.801532911392407, -1.488775172413793 ], [ 29.802071898734177, -1.488775172413793 ], [ 29.802071898734177, -1.487966724137931 ], [ 29.801532911392407, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32718, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.485541379310345 ], [ 29.802880379746835, -1.485541379310345 ], [ 29.802880379746835, -1.488236206896552 ], [ 29.80261088607595, -1.488236206896552 ], [ 29.80261088607595, -1.488505689655172 ], [ 29.802341392405065, -1.488505689655172 ], [ 29.802341392405065, -1.487966724137931 ], [ 29.80261088607595, -1.487966724137931 ], [ 29.80261088607595, -1.485541379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32719, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.487966724137931 ], [ 29.802880379746835, -1.488505689655172 ], [ 29.803149873417723, -1.488505689655172 ], [ 29.803149873417723, -1.487966724137931 ], [ 29.802880379746835, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32720, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.487966724137931 ], [ 29.803688860759493, -1.488236206896552 ], [ 29.803958354430382, -1.488236206896552 ], [ 29.803958354430382, -1.487966724137931 ], [ 29.803688860759493, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32721, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.487966724137931 ], [ 29.803958354430382, -1.488236206896552 ], [ 29.804227848101267, -1.488236206896552 ], [ 29.804227848101267, -1.487966724137931 ], [ 29.803958354430382, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32722, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.487966724137931 ], [ 29.804227848101267, -1.488236206896552 ], [ 29.804497341772151, -1.488236206896552 ], [ 29.804497341772151, -1.487966724137931 ], [ 29.804227848101267, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32723, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767576708860759, -1.488236206896552 ], [ 29.767576708860759, -1.488775172413793 ], [ 29.767846202531643, -1.488775172413793 ], [ 29.767846202531643, -1.488236206896552 ], [ 29.767576708860759, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32724, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.488236206896552 ], [ 29.767846202531643, -1.488505689655172 ], [ 29.768115696202532, -1.488505689655172 ], [ 29.768115696202532, -1.488236206896552 ], [ 29.767846202531643, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32725, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.488236206896552 ], [ 29.768115696202532, -1.488505689655172 ], [ 29.768385189873417, -1.488505689655172 ], [ 29.768385189873417, -1.488236206896552 ], [ 29.768115696202532, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32726, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.488236206896552 ], [ 29.768385189873417, -1.488505689655172 ], [ 29.768654683544302, -1.488505689655172 ], [ 29.768654683544302, -1.488236206896552 ], [ 29.768385189873417, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32727, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.488236206896552 ], [ 29.768654683544302, -1.488505689655172 ], [ 29.76892417721519, -1.488505689655172 ], [ 29.76892417721519, -1.488236206896552 ], [ 29.768654683544302, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32728, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.488236206896552 ], [ 29.76892417721519, -1.488505689655172 ], [ 29.769193670886075, -1.488505689655172 ], [ 29.769193670886075, -1.488236206896552 ], [ 29.76892417721519, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32729, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.488236206896552 ], [ 29.769193670886075, -1.488505689655172 ], [ 29.76946316455696, -1.488505689655172 ], [ 29.76946316455696, -1.488236206896552 ], [ 29.769193670886075, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32730, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.488236206896552 ], [ 29.76946316455696, -1.488505689655172 ], [ 29.769732658227849, -1.488505689655172 ], [ 29.769732658227849, -1.488236206896552 ], [ 29.76946316455696, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32731, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.488236206896552 ], [ 29.769732658227849, -1.488505689655172 ], [ 29.770002151898733, -1.488505689655172 ], [ 29.770002151898733, -1.488236206896552 ], [ 29.769732658227849, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32732, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.488236206896552 ], [ 29.770002151898733, -1.488505689655172 ], [ 29.770271645569618, -1.488505689655172 ], [ 29.770271645569618, -1.488236206896552 ], [ 29.770002151898733, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32733, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.488236206896552 ], [ 29.770271645569618, -1.488505689655172 ], [ 29.770541139240507, -1.488505689655172 ], [ 29.770541139240507, -1.488236206896552 ], [ 29.770271645569618, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32734, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.488236206896552 ], [ 29.770541139240507, -1.488505689655172 ], [ 29.770810632911392, -1.488505689655172 ], [ 29.770810632911392, -1.488236206896552 ], [ 29.770541139240507, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32735, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.488236206896552 ], [ 29.770810632911392, -1.488505689655172 ], [ 29.771080126582277, -1.488505689655172 ], [ 29.771080126582277, -1.488236206896552 ], [ 29.770810632911392, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32736, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.488236206896552 ], [ 29.771080126582277, -1.488505689655172 ], [ 29.771349620253165, -1.488505689655172 ], [ 29.771349620253165, -1.488236206896552 ], [ 29.771080126582277, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32737, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.488236206896552 ], [ 29.771349620253165, -1.488505689655172 ], [ 29.77161911392405, -1.488505689655172 ], [ 29.77161911392405, -1.488236206896552 ], [ 29.771349620253165, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32738, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.488236206896552 ], [ 29.77161911392405, -1.488505689655172 ], [ 29.771888607594935, -1.488505689655172 ], [ 29.771888607594935, -1.488236206896552 ], [ 29.77161911392405, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32739, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.488236206896552 ], [ 29.771888607594935, -1.488505689655172 ], [ 29.772158101265823, -1.488505689655172 ], [ 29.772158101265823, -1.488236206896552 ], [ 29.771888607594935, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32740, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.488236206896552 ], [ 29.772158101265823, -1.488505689655172 ], [ 29.772427594936708, -1.488505689655172 ], [ 29.772427594936708, -1.488236206896552 ], [ 29.772158101265823, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32741, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.488236206896552 ], [ 29.772427594936708, -1.488505689655172 ], [ 29.772697088607593, -1.488505689655172 ], [ 29.772697088607593, -1.488236206896552 ], [ 29.772427594936708, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32742, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.488236206896552 ], [ 29.772697088607593, -1.488505689655172 ], [ 29.772966582278482, -1.488505689655172 ], [ 29.772966582278482, -1.488236206896552 ], [ 29.772697088607593, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32743, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.488236206896552 ], [ 29.772966582278482, -1.488505689655172 ], [ 29.773236075949367, -1.488505689655172 ], [ 29.773236075949367, -1.488236206896552 ], [ 29.772966582278482, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32744, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.488236206896552 ], [ 29.773236075949367, -1.488505689655172 ], [ 29.773505569620252, -1.488505689655172 ], [ 29.773505569620252, -1.488236206896552 ], [ 29.773236075949367, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32745, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.488236206896552 ], [ 29.773505569620252, -1.488505689655172 ], [ 29.77377506329114, -1.488505689655172 ], [ 29.77377506329114, -1.488236206896552 ], [ 29.773505569620252, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32746, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.488236206896552 ], [ 29.77377506329114, -1.488505689655172 ], [ 29.774044556962025, -1.488505689655172 ], [ 29.774044556962025, -1.488236206896552 ], [ 29.77377506329114, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32747, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.488236206896552 ], [ 29.774044556962025, -1.488505689655172 ], [ 29.77431405063291, -1.488505689655172 ], [ 29.77431405063291, -1.488236206896552 ], [ 29.774044556962025, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32748, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.488236206896552 ], [ 29.77431405063291, -1.488505689655172 ], [ 29.774853037974683, -1.488505689655172 ], [ 29.774853037974683, -1.488236206896552 ], [ 29.77431405063291, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32749, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.488236206896552 ], [ 29.774853037974683, -1.488505689655172 ], [ 29.775122531645568, -1.488505689655172 ], [ 29.775122531645568, -1.488236206896552 ], [ 29.774853037974683, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32750, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.488236206896552 ], [ 29.775122531645568, -1.488505689655172 ], [ 29.775392025316457, -1.488505689655172 ], [ 29.775392025316457, -1.488236206896552 ], [ 29.775122531645568, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32751, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.488236206896552 ], [ 29.775392025316457, -1.488505689655172 ], [ 29.775661518987341, -1.488505689655172 ], [ 29.775661518987341, -1.488236206896552 ], [ 29.775392025316457, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32752, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.488236206896552 ], [ 29.775661518987341, -1.488505689655172 ], [ 29.775931012658226, -1.488505689655172 ], [ 29.775931012658226, -1.488236206896552 ], [ 29.775661518987341, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32753, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.488236206896552 ], [ 29.775931012658226, -1.488505689655172 ], [ 29.776200506329115, -1.488505689655172 ], [ 29.776200506329115, -1.488236206896552 ], [ 29.775931012658226, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32754, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.488236206896552 ], [ 29.776200506329115, -1.488505689655172 ], [ 29.77647, -1.488505689655172 ], [ 29.77647, -1.488236206896552 ], [ 29.776200506329115, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32755, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.488236206896552 ], [ 29.77647, -1.488505689655172 ], [ 29.776739493670885, -1.488505689655172 ], [ 29.776739493670885, -1.488236206896552 ], [ 29.77647, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32756, "El": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.488236206896552 ], [ 29.776739493670885, -1.488505689655172 ], [ 29.777008987341773, -1.488505689655172 ], [ 29.777008987341773, -1.488236206896552 ], [ 29.776739493670885, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32757, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.488236206896552 ], [ 29.777008987341773, -1.488505689655172 ], [ 29.777278481012658, -1.488505689655172 ], [ 29.777278481012658, -1.488236206896552 ], [ 29.777008987341773, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32758, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.488236206896552 ], [ 29.777278481012658, -1.488505689655172 ], [ 29.777547974683543, -1.488505689655172 ], [ 29.777547974683543, -1.488236206896552 ], [ 29.777278481012658, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32759, "El": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.488236206896552 ], [ 29.777547974683543, -1.488505689655172 ], [ 29.777817468354431, -1.488505689655172 ], [ 29.777817468354431, -1.488236206896552 ], [ 29.777547974683543, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32760, "El": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.488236206896552 ], [ 29.777817468354431, -1.488505689655172 ], [ 29.778086962025316, -1.488505689655172 ], [ 29.778086962025316, -1.488236206896552 ], [ 29.777817468354431, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32761, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.488236206896552 ], [ 29.778086962025316, -1.488505689655172 ], [ 29.778356455696201, -1.488505689655172 ], [ 29.778356455696201, -1.488236206896552 ], [ 29.778086962025316, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32762, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.488236206896552 ], [ 29.778356455696201, -1.488505689655172 ], [ 29.77862594936709, -1.488505689655172 ], [ 29.77862594936709, -1.488236206896552 ], [ 29.778356455696201, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32763, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.488236206896552 ], [ 29.77862594936709, -1.488505689655172 ], [ 29.778895443037975, -1.488505689655172 ], [ 29.778895443037975, -1.488236206896552 ], [ 29.77862594936709, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32764, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.488236206896552 ], [ 29.778895443037975, -1.488505689655172 ], [ 29.77916493670886, -1.488505689655172 ], [ 29.77916493670886, -1.488236206896552 ], [ 29.778895443037975, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32765, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.488236206896552 ], [ 29.77916493670886, -1.488505689655172 ], [ 29.779434430379748, -1.488505689655172 ], [ 29.779434430379748, -1.488236206896552 ], [ 29.77916493670886, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32766, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.488236206896552 ], [ 29.779434430379748, -1.488505689655172 ], [ 29.779703924050633, -1.488505689655172 ], [ 29.779703924050633, -1.488236206896552 ], [ 29.779434430379748, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32767, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.488236206896552 ], [ 29.779703924050633, -1.488505689655172 ], [ 29.779973417721518, -1.488505689655172 ], [ 29.779973417721518, -1.488236206896552 ], [ 29.779703924050633, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32768, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.488236206896552 ], [ 29.779973417721518, -1.488505689655172 ], [ 29.780242911392406, -1.488505689655172 ], [ 29.780242911392406, -1.488236206896552 ], [ 29.779973417721518, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32769, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.488236206896552 ], [ 29.780242911392406, -1.488505689655172 ], [ 29.780512405063291, -1.488505689655172 ], [ 29.780512405063291, -1.488236206896552 ], [ 29.780242911392406, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32770, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.488236206896552 ], [ 29.780512405063291, -1.488505689655172 ], [ 29.780781898734176, -1.488505689655172 ], [ 29.780781898734176, -1.488236206896552 ], [ 29.780512405063291, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32771, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.488236206896552 ], [ 29.780781898734176, -1.488505689655172 ], [ 29.781051392405065, -1.488505689655172 ], [ 29.781051392405065, -1.488236206896552 ], [ 29.780781898734176, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32772, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.488236206896552 ], [ 29.781051392405065, -1.488505689655172 ], [ 29.78132088607595, -1.488505689655172 ], [ 29.78132088607595, -1.488236206896552 ], [ 29.781051392405065, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32773, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.488236206896552 ], [ 29.78132088607595, -1.488505689655172 ], [ 29.781590379746834, -1.488505689655172 ], [ 29.781590379746834, -1.488236206896552 ], [ 29.78132088607595, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32774, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.488236206896552 ], [ 29.781590379746834, -1.488505689655172 ], [ 29.781859873417723, -1.488505689655172 ], [ 29.781859873417723, -1.488236206896552 ], [ 29.781590379746834, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32775, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.488236206896552 ], [ 29.781859873417723, -1.488505689655172 ], [ 29.782129367088608, -1.488505689655172 ], [ 29.782129367088608, -1.488236206896552 ], [ 29.781859873417723, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32776, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.488236206896552 ], [ 29.782129367088608, -1.488505689655172 ], [ 29.782398860759493, -1.488505689655172 ], [ 29.782398860759493, -1.488236206896552 ], [ 29.782129367088608, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32777, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.488236206896552 ], [ 29.782398860759493, -1.488505689655172 ], [ 29.782668354430381, -1.488505689655172 ], [ 29.782668354430381, -1.488236206896552 ], [ 29.782398860759493, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32778, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.488236206896552 ], [ 29.782668354430381, -1.488505689655172 ], [ 29.782937848101266, -1.488505689655172 ], [ 29.782937848101266, -1.488236206896552 ], [ 29.782668354430381, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32779, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.488236206896552 ], [ 29.782937848101266, -1.488505689655172 ], [ 29.783207341772151, -1.488505689655172 ], [ 29.783207341772151, -1.488236206896552 ], [ 29.782937848101266, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32780, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784824303797468, -1.487966724137931 ], [ 29.785093797468356, -1.487966724137931 ], [ 29.785093797468356, -1.488236206896552 ], [ 29.785363291139241, -1.488236206896552 ], [ 29.785363291139241, -1.488505689655172 ], [ 29.783207341772151, -1.488505689655172 ], [ 29.783207341772151, -1.488236206896552 ], [ 29.784824303797468, -1.488236206896552 ], [ 29.784824303797468, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32781, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785363291139241, -1.488236206896552 ], [ 29.785363291139241, -1.488505689655172 ], [ 29.785632784810126, -1.488505689655172 ], [ 29.785632784810126, -1.488236206896552 ], [ 29.785363291139241, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32782, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785902278481014, -1.488236206896552 ], [ 29.785902278481014, -1.488505689655172 ], [ 29.786171772151899, -1.488505689655172 ], [ 29.786171772151899, -1.488236206896552 ], [ 29.785902278481014, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32783, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786171772151899, -1.488236206896552 ], [ 29.786171772151899, -1.488505689655172 ], [ 29.786441265822784, -1.488505689655172 ], [ 29.786441265822784, -1.488236206896552 ], [ 29.786171772151899, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32784, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.488236206896552 ], [ 29.786441265822784, -1.488505689655172 ], [ 29.786710759493673, -1.488505689655172 ], [ 29.786710759493673, -1.488236206896552 ], [ 29.786441265822784, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32785, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.488236206896552 ], [ 29.786710759493673, -1.488505689655172 ], [ 29.787519240506327, -1.488505689655172 ], [ 29.787519240506327, -1.488236206896552 ], [ 29.786710759493673, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32786, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.488236206896552 ], [ 29.800724430379748, -1.489044655172414 ], [ 29.800993924050633, -1.489044655172414 ], [ 29.800993924050633, -1.488236206896552 ], [ 29.800724430379748, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32787, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.488236206896552 ], [ 29.800993924050633, -1.489044655172414 ], [ 29.801263417721518, -1.489044655172414 ], [ 29.801263417721518, -1.488236206896552 ], [ 29.800993924050633, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32788, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.488236206896552 ], [ 29.802071898734177, -1.488775172413793 ], [ 29.802341392405065, -1.488775172413793 ], [ 29.802341392405065, -1.488236206896552 ], [ 29.802071898734177, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32789, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.488236206896552 ], [ 29.80261088607595, -1.488505689655172 ], [ 29.802880379746835, -1.488505689655172 ], [ 29.802880379746835, -1.488236206896552 ], [ 29.80261088607595, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32790, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.488236206896552 ], [ 29.803149873417723, -1.488505689655172 ], [ 29.803419367088608, -1.488505689655172 ], [ 29.803419367088608, -1.488236206896552 ], [ 29.803149873417723, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32791, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.488236206896552 ], [ 29.803419367088608, -1.488505689655172 ], [ 29.803688860759493, -1.488505689655172 ], [ 29.803688860759493, -1.488236206896552 ], [ 29.803419367088608, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32792, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.488236206896552 ], [ 29.803688860759493, -1.488505689655172 ], [ 29.803958354430382, -1.488505689655172 ], [ 29.803958354430382, -1.488236206896552 ], [ 29.803688860759493, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32793, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.488505689655172 ], [ 29.767846202531643, -1.488775172413793 ], [ 29.768115696202532, -1.488775172413793 ], [ 29.768115696202532, -1.488505689655172 ], [ 29.767846202531643, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32794, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.488505689655172 ], [ 29.768115696202532, -1.488775172413793 ], [ 29.768385189873417, -1.488775172413793 ], [ 29.768385189873417, -1.488505689655172 ], [ 29.768115696202532, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32795, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.488505689655172 ], [ 29.768385189873417, -1.488775172413793 ], [ 29.768654683544302, -1.488775172413793 ], [ 29.768654683544302, -1.488505689655172 ], [ 29.768385189873417, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32796, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.488505689655172 ], [ 29.768654683544302, -1.488775172413793 ], [ 29.76892417721519, -1.488775172413793 ], [ 29.76892417721519, -1.488505689655172 ], [ 29.768654683544302, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32797, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.488505689655172 ], [ 29.76892417721519, -1.488775172413793 ], [ 29.769193670886075, -1.488775172413793 ], [ 29.769193670886075, -1.488505689655172 ], [ 29.76892417721519, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32798, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.488505689655172 ], [ 29.769193670886075, -1.488775172413793 ], [ 29.76946316455696, -1.488775172413793 ], [ 29.76946316455696, -1.488505689655172 ], [ 29.769193670886075, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32799, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.488505689655172 ], [ 29.76946316455696, -1.488775172413793 ], [ 29.769732658227849, -1.488775172413793 ], [ 29.769732658227849, -1.488505689655172 ], [ 29.76946316455696, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32800, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.488505689655172 ], [ 29.769732658227849, -1.488775172413793 ], [ 29.770002151898733, -1.488775172413793 ], [ 29.770002151898733, -1.488505689655172 ], [ 29.769732658227849, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32801, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.488505689655172 ], [ 29.770002151898733, -1.488775172413793 ], [ 29.770271645569618, -1.488775172413793 ], [ 29.770271645569618, -1.488505689655172 ], [ 29.770002151898733, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32802, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.488505689655172 ], [ 29.770271645569618, -1.488775172413793 ], [ 29.770541139240507, -1.488775172413793 ], [ 29.770541139240507, -1.488505689655172 ], [ 29.770271645569618, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32803, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.488505689655172 ], [ 29.770541139240507, -1.488775172413793 ], [ 29.770810632911392, -1.488775172413793 ], [ 29.770810632911392, -1.488505689655172 ], [ 29.770541139240507, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32804, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.488505689655172 ], [ 29.770810632911392, -1.488775172413793 ], [ 29.771080126582277, -1.488775172413793 ], [ 29.771080126582277, -1.488505689655172 ], [ 29.770810632911392, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32805, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.488505689655172 ], [ 29.771080126582277, -1.488775172413793 ], [ 29.771349620253165, -1.488775172413793 ], [ 29.771349620253165, -1.488505689655172 ], [ 29.771080126582277, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32806, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.488505689655172 ], [ 29.771349620253165, -1.488775172413793 ], [ 29.77161911392405, -1.488775172413793 ], [ 29.77161911392405, -1.488505689655172 ], [ 29.771349620253165, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32807, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.488505689655172 ], [ 29.77161911392405, -1.488775172413793 ], [ 29.771888607594935, -1.488775172413793 ], [ 29.771888607594935, -1.488505689655172 ], [ 29.77161911392405, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32808, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.488505689655172 ], [ 29.771888607594935, -1.488775172413793 ], [ 29.772158101265823, -1.488775172413793 ], [ 29.772158101265823, -1.488505689655172 ], [ 29.771888607594935, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32809, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.488505689655172 ], [ 29.772158101265823, -1.488775172413793 ], [ 29.772427594936708, -1.488775172413793 ], [ 29.772427594936708, -1.488505689655172 ], [ 29.772158101265823, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32810, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.488505689655172 ], [ 29.772427594936708, -1.488775172413793 ], [ 29.772697088607593, -1.488775172413793 ], [ 29.772697088607593, -1.488505689655172 ], [ 29.772427594936708, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32811, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.488505689655172 ], [ 29.772697088607593, -1.488775172413793 ], [ 29.772966582278482, -1.488775172413793 ], [ 29.772966582278482, -1.488505689655172 ], [ 29.772697088607593, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32812, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.488505689655172 ], [ 29.772966582278482, -1.488775172413793 ], [ 29.773236075949367, -1.488775172413793 ], [ 29.773236075949367, -1.488505689655172 ], [ 29.772966582278482, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32813, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.488505689655172 ], [ 29.773236075949367, -1.488775172413793 ], [ 29.773505569620252, -1.488775172413793 ], [ 29.773505569620252, -1.488505689655172 ], [ 29.773236075949367, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32814, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.488505689655172 ], [ 29.773505569620252, -1.488775172413793 ], [ 29.77377506329114, -1.488775172413793 ], [ 29.77377506329114, -1.488505689655172 ], [ 29.773505569620252, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32815, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.488505689655172 ], [ 29.77377506329114, -1.488775172413793 ], [ 29.774044556962025, -1.488775172413793 ], [ 29.774044556962025, -1.488505689655172 ], [ 29.77377506329114, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32816, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.488505689655172 ], [ 29.774044556962025, -1.488775172413793 ], [ 29.77431405063291, -1.488775172413793 ], [ 29.77431405063291, -1.488505689655172 ], [ 29.774044556962025, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32817, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.488505689655172 ], [ 29.77431405063291, -1.488775172413793 ], [ 29.774853037974683, -1.488775172413793 ], [ 29.774853037974683, -1.488505689655172 ], [ 29.77431405063291, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32818, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.488505689655172 ], [ 29.774853037974683, -1.488775172413793 ], [ 29.775122531645568, -1.488775172413793 ], [ 29.775122531645568, -1.488505689655172 ], [ 29.774853037974683, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32819, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.488505689655172 ], [ 29.775122531645568, -1.488775172413793 ], [ 29.775392025316457, -1.488775172413793 ], [ 29.775392025316457, -1.488505689655172 ], [ 29.775122531645568, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32820, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.488505689655172 ], [ 29.775392025316457, -1.488775172413793 ], [ 29.775661518987341, -1.488775172413793 ], [ 29.775661518987341, -1.488505689655172 ], [ 29.775392025316457, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32821, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.488505689655172 ], [ 29.775661518987341, -1.488775172413793 ], [ 29.775931012658226, -1.488775172413793 ], [ 29.775931012658226, -1.488505689655172 ], [ 29.775661518987341, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32822, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.488505689655172 ], [ 29.775931012658226, -1.488775172413793 ], [ 29.776200506329115, -1.488775172413793 ], [ 29.776200506329115, -1.488505689655172 ], [ 29.775931012658226, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32823, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.488505689655172 ], [ 29.776200506329115, -1.488775172413793 ], [ 29.77647, -1.488775172413793 ], [ 29.77647, -1.488505689655172 ], [ 29.776200506329115, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32824, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.488505689655172 ], [ 29.77647, -1.488775172413793 ], [ 29.776739493670885, -1.488775172413793 ], [ 29.776739493670885, -1.488505689655172 ], [ 29.77647, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32825, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.488505689655172 ], [ 29.776739493670885, -1.488775172413793 ], [ 29.777008987341773, -1.488775172413793 ], [ 29.777008987341773, -1.488505689655172 ], [ 29.776739493670885, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32826, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.488505689655172 ], [ 29.777008987341773, -1.488775172413793 ], [ 29.777278481012658, -1.488775172413793 ], [ 29.777278481012658, -1.488505689655172 ], [ 29.777008987341773, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32827, "El": 80 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.488505689655172 ], [ 29.777278481012658, -1.488775172413793 ], [ 29.777547974683543, -1.488775172413793 ], [ 29.777547974683543, -1.488505689655172 ], [ 29.777278481012658, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32828, "El": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.488505689655172 ], [ 29.777547974683543, -1.488775172413793 ], [ 29.777817468354431, -1.488775172413793 ], [ 29.777817468354431, -1.488505689655172 ], [ 29.777547974683543, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32829, "El": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.488505689655172 ], [ 29.777817468354431, -1.488775172413793 ], [ 29.778086962025316, -1.488775172413793 ], [ 29.778086962025316, -1.488505689655172 ], [ 29.777817468354431, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32830, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.488505689655172 ], [ 29.778086962025316, -1.488775172413793 ], [ 29.778356455696201, -1.488775172413793 ], [ 29.778356455696201, -1.488505689655172 ], [ 29.778086962025316, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32831, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.488505689655172 ], [ 29.778356455696201, -1.488775172413793 ], [ 29.77862594936709, -1.488775172413793 ], [ 29.77862594936709, -1.488505689655172 ], [ 29.778356455696201, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32832, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.488505689655172 ], [ 29.77862594936709, -1.488775172413793 ], [ 29.778895443037975, -1.488775172413793 ], [ 29.778895443037975, -1.488505689655172 ], [ 29.77862594936709, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32833, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.488505689655172 ], [ 29.778895443037975, -1.488775172413793 ], [ 29.77916493670886, -1.488775172413793 ], [ 29.77916493670886, -1.488505689655172 ], [ 29.778895443037975, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32834, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.488505689655172 ], [ 29.77916493670886, -1.488775172413793 ], [ 29.779434430379748, -1.488775172413793 ], [ 29.779434430379748, -1.488505689655172 ], [ 29.77916493670886, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32835, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.488505689655172 ], [ 29.779434430379748, -1.488775172413793 ], [ 29.779703924050633, -1.488775172413793 ], [ 29.779703924050633, -1.488505689655172 ], [ 29.779434430379748, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32836, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.488505689655172 ], [ 29.779703924050633, -1.488775172413793 ], [ 29.779973417721518, -1.488775172413793 ], [ 29.779973417721518, -1.488505689655172 ], [ 29.779703924050633, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32837, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.488505689655172 ], [ 29.779973417721518, -1.488775172413793 ], [ 29.780242911392406, -1.488775172413793 ], [ 29.780242911392406, -1.488505689655172 ], [ 29.779973417721518, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32838, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.488505689655172 ], [ 29.780242911392406, -1.488775172413793 ], [ 29.780512405063291, -1.488775172413793 ], [ 29.780512405063291, -1.488505689655172 ], [ 29.780242911392406, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32839, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.488505689655172 ], [ 29.780512405063291, -1.488775172413793 ], [ 29.780781898734176, -1.488775172413793 ], [ 29.780781898734176, -1.488505689655172 ], [ 29.780512405063291, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32840, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.488505689655172 ], [ 29.780781898734176, -1.488775172413793 ], [ 29.781051392405065, -1.488775172413793 ], [ 29.781051392405065, -1.488505689655172 ], [ 29.780781898734176, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32841, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.488505689655172 ], [ 29.781051392405065, -1.488775172413793 ], [ 29.78132088607595, -1.488775172413793 ], [ 29.78132088607595, -1.488505689655172 ], [ 29.781051392405065, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32842, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.488505689655172 ], [ 29.78132088607595, -1.488775172413793 ], [ 29.781590379746834, -1.488775172413793 ], [ 29.781590379746834, -1.488505689655172 ], [ 29.78132088607595, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32843, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.488505689655172 ], [ 29.781590379746834, -1.488775172413793 ], [ 29.781859873417723, -1.488775172413793 ], [ 29.781859873417723, -1.488505689655172 ], [ 29.781590379746834, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32844, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.488505689655172 ], [ 29.781859873417723, -1.488775172413793 ], [ 29.782129367088608, -1.488775172413793 ], [ 29.782129367088608, -1.488505689655172 ], [ 29.781859873417723, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32845, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.488505689655172 ], [ 29.782129367088608, -1.488775172413793 ], [ 29.782398860759493, -1.488775172413793 ], [ 29.782398860759493, -1.488505689655172 ], [ 29.782129367088608, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32846, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.488505689655172 ], [ 29.782398860759493, -1.488775172413793 ], [ 29.782668354430381, -1.488775172413793 ], [ 29.782668354430381, -1.488505689655172 ], [ 29.782398860759493, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32847, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.488505689655172 ], [ 29.782668354430381, -1.488775172413793 ], [ 29.782937848101266, -1.488775172413793 ], [ 29.782937848101266, -1.488505689655172 ], [ 29.782668354430381, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32848, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.488505689655172 ], [ 29.782937848101266, -1.488775172413793 ], [ 29.783207341772151, -1.488775172413793 ], [ 29.783207341772151, -1.488505689655172 ], [ 29.782937848101266, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32849, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.488505689655172 ], [ 29.783207341772151, -1.488775172413793 ], [ 29.784015822784809, -1.488775172413793 ], [ 29.784015822784809, -1.488505689655172 ], [ 29.783207341772151, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32850, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.785632784810126, -1.488236206896552 ], [ 29.785902278481014, -1.488236206896552 ], [ 29.785902278481014, -1.488505689655172 ], [ 29.786441265822784, -1.488505689655172 ], [ 29.786441265822784, -1.488775172413793 ], [ 29.784015822784809, -1.488775172413793 ], [ 29.784015822784809, -1.488505689655172 ], [ 29.785632784810126, -1.488505689655172 ], [ 29.785632784810126, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32851, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.488505689655172 ], [ 29.786441265822784, -1.488775172413793 ], [ 29.786710759493673, -1.488775172413793 ], [ 29.786710759493673, -1.488505689655172 ], [ 29.786441265822784, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32852, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.488505689655172 ], [ 29.786710759493673, -1.488775172413793 ], [ 29.786980253164558, -1.488775172413793 ], [ 29.786980253164558, -1.488505689655172 ], [ 29.786710759493673, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32853, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.488505689655172 ], [ 29.786980253164558, -1.488775172413793 ], [ 29.787249746835442, -1.488775172413793 ], [ 29.787249746835442, -1.488505689655172 ], [ 29.786980253164558, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32854, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.488505689655172 ], [ 29.787249746835442, -1.488775172413793 ], [ 29.787519240506327, -1.488775172413793 ], [ 29.787519240506327, -1.488505689655172 ], [ 29.787249746835442, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32855, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.488505689655172 ], [ 29.802341392405065, -1.488775172413793 ], [ 29.80261088607595, -1.488775172413793 ], [ 29.80261088607595, -1.488505689655172 ], [ 29.802341392405065, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32856, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.488505689655172 ], [ 29.80261088607595, -1.488775172413793 ], [ 29.802880379746835, -1.488775172413793 ], [ 29.802880379746835, -1.488505689655172 ], [ 29.80261088607595, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32857, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.488505689655172 ], [ 29.802880379746835, -1.488775172413793 ], [ 29.803149873417723, -1.488775172413793 ], [ 29.803149873417723, -1.488505689655172 ], [ 29.802880379746835, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32858, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.767846202531643, -1.488775172413793 ], [ 29.767846202531643, -1.489044655172414 ], [ 29.768115696202532, -1.489044655172414 ], [ 29.768115696202532, -1.488775172413793 ], [ 29.767846202531643, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32859, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.488775172413793 ], [ 29.768115696202532, -1.489044655172414 ], [ 29.768385189873417, -1.489044655172414 ], [ 29.768385189873417, -1.488775172413793 ], [ 29.768115696202532, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32860, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.488775172413793 ], [ 29.768385189873417, -1.489044655172414 ], [ 29.768654683544302, -1.489044655172414 ], [ 29.768654683544302, -1.488775172413793 ], [ 29.768385189873417, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32861, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.488775172413793 ], [ 29.768654683544302, -1.489044655172414 ], [ 29.76892417721519, -1.489044655172414 ], [ 29.76892417721519, -1.488775172413793 ], [ 29.768654683544302, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32862, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.488775172413793 ], [ 29.76892417721519, -1.489044655172414 ], [ 29.769193670886075, -1.489044655172414 ], [ 29.769193670886075, -1.488775172413793 ], [ 29.76892417721519, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32863, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.488775172413793 ], [ 29.769193670886075, -1.489044655172414 ], [ 29.76946316455696, -1.489044655172414 ], [ 29.76946316455696, -1.488775172413793 ], [ 29.769193670886075, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32864, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.488775172413793 ], [ 29.76946316455696, -1.489044655172414 ], [ 29.769732658227849, -1.489044655172414 ], [ 29.769732658227849, -1.488775172413793 ], [ 29.76946316455696, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32865, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.488775172413793 ], [ 29.769732658227849, -1.489044655172414 ], [ 29.770002151898733, -1.489044655172414 ], [ 29.770002151898733, -1.488775172413793 ], [ 29.769732658227849, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32866, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.488775172413793 ], [ 29.770002151898733, -1.489044655172414 ], [ 29.770271645569618, -1.489044655172414 ], [ 29.770271645569618, -1.488775172413793 ], [ 29.770002151898733, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32867, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.488775172413793 ], [ 29.770271645569618, -1.489044655172414 ], [ 29.770541139240507, -1.489044655172414 ], [ 29.770541139240507, -1.488775172413793 ], [ 29.770271645569618, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32868, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.488775172413793 ], [ 29.770541139240507, -1.489044655172414 ], [ 29.770810632911392, -1.489044655172414 ], [ 29.770810632911392, -1.488775172413793 ], [ 29.770541139240507, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32869, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.488775172413793 ], [ 29.770810632911392, -1.489044655172414 ], [ 29.771080126582277, -1.489044655172414 ], [ 29.771080126582277, -1.488775172413793 ], [ 29.770810632911392, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32870, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.488775172413793 ], [ 29.771080126582277, -1.489044655172414 ], [ 29.771349620253165, -1.489044655172414 ], [ 29.771349620253165, -1.488775172413793 ], [ 29.771080126582277, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32871, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.488775172413793 ], [ 29.771349620253165, -1.489044655172414 ], [ 29.77161911392405, -1.489044655172414 ], [ 29.77161911392405, -1.488775172413793 ], [ 29.771349620253165, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32872, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.488775172413793 ], [ 29.77161911392405, -1.489044655172414 ], [ 29.771888607594935, -1.489044655172414 ], [ 29.771888607594935, -1.488775172413793 ], [ 29.77161911392405, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32873, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.488775172413793 ], [ 29.771888607594935, -1.489044655172414 ], [ 29.772158101265823, -1.489044655172414 ], [ 29.772158101265823, -1.488775172413793 ], [ 29.771888607594935, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32874, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.488775172413793 ], [ 29.772158101265823, -1.489044655172414 ], [ 29.772427594936708, -1.489044655172414 ], [ 29.772427594936708, -1.488775172413793 ], [ 29.772158101265823, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32875, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.488775172413793 ], [ 29.772427594936708, -1.489044655172414 ], [ 29.772697088607593, -1.489044655172414 ], [ 29.772697088607593, -1.488775172413793 ], [ 29.772427594936708, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32876, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.488775172413793 ], [ 29.772697088607593, -1.489044655172414 ], [ 29.772966582278482, -1.489044655172414 ], [ 29.772966582278482, -1.488775172413793 ], [ 29.772697088607593, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32877, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.488775172413793 ], [ 29.772966582278482, -1.489044655172414 ], [ 29.773236075949367, -1.489044655172414 ], [ 29.773236075949367, -1.488775172413793 ], [ 29.772966582278482, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32878, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.488775172413793 ], [ 29.773236075949367, -1.489044655172414 ], [ 29.773505569620252, -1.489044655172414 ], [ 29.773505569620252, -1.488775172413793 ], [ 29.773236075949367, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32879, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.488775172413793 ], [ 29.773505569620252, -1.489044655172414 ], [ 29.77377506329114, -1.489044655172414 ], [ 29.77377506329114, -1.488775172413793 ], [ 29.773505569620252, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32880, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.488775172413793 ], [ 29.77377506329114, -1.489044655172414 ], [ 29.774044556962025, -1.489044655172414 ], [ 29.774044556962025, -1.488775172413793 ], [ 29.77377506329114, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32881, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.488775172413793 ], [ 29.774044556962025, -1.489044655172414 ], [ 29.77431405063291, -1.489044655172414 ], [ 29.77431405063291, -1.488775172413793 ], [ 29.774044556962025, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32882, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.488775172413793 ], [ 29.77431405063291, -1.489044655172414 ], [ 29.774853037974683, -1.489044655172414 ], [ 29.774853037974683, -1.488775172413793 ], [ 29.77431405063291, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32883, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.488775172413793 ], [ 29.774853037974683, -1.489044655172414 ], [ 29.775122531645568, -1.489044655172414 ], [ 29.775122531645568, -1.488775172413793 ], [ 29.774853037974683, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32884, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.488775172413793 ], [ 29.775122531645568, -1.489044655172414 ], [ 29.775392025316457, -1.489044655172414 ], [ 29.775392025316457, -1.488775172413793 ], [ 29.775122531645568, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32885, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.488775172413793 ], [ 29.775392025316457, -1.489044655172414 ], [ 29.775661518987341, -1.489044655172414 ], [ 29.775661518987341, -1.488775172413793 ], [ 29.775392025316457, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32886, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.488775172413793 ], [ 29.775661518987341, -1.489044655172414 ], [ 29.775931012658226, -1.489044655172414 ], [ 29.775931012658226, -1.488775172413793 ], [ 29.775661518987341, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32887, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.488775172413793 ], [ 29.775931012658226, -1.489044655172414 ], [ 29.776200506329115, -1.489044655172414 ], [ 29.776200506329115, -1.488775172413793 ], [ 29.775931012658226, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32888, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.488775172413793 ], [ 29.776200506329115, -1.489044655172414 ], [ 29.77647, -1.489044655172414 ], [ 29.77647, -1.488775172413793 ], [ 29.776200506329115, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32889, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.488775172413793 ], [ 29.77647, -1.489044655172414 ], [ 29.776739493670885, -1.489044655172414 ], [ 29.776739493670885, -1.488775172413793 ], [ 29.77647, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32890, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.488775172413793 ], [ 29.776739493670885, -1.489044655172414 ], [ 29.777008987341773, -1.489044655172414 ], [ 29.777008987341773, -1.488775172413793 ], [ 29.776739493670885, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32891, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.488775172413793 ], [ 29.777008987341773, -1.489044655172414 ], [ 29.777278481012658, -1.489044655172414 ], [ 29.777278481012658, -1.488775172413793 ], [ 29.777008987341773, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32892, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.488775172413793 ], [ 29.777278481012658, -1.489044655172414 ], [ 29.777547974683543, -1.489044655172414 ], [ 29.777547974683543, -1.488775172413793 ], [ 29.777278481012658, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32893, "El": 81 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.488775172413793 ], [ 29.777547974683543, -1.489044655172414 ], [ 29.778086962025316, -1.489044655172414 ], [ 29.778086962025316, -1.488775172413793 ], [ 29.777547974683543, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32894, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.488775172413793 ], [ 29.778086962025316, -1.489044655172414 ], [ 29.778356455696201, -1.489044655172414 ], [ 29.778356455696201, -1.488775172413793 ], [ 29.778086962025316, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32895, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.488775172413793 ], [ 29.778356455696201, -1.489044655172414 ], [ 29.77862594936709, -1.489044655172414 ], [ 29.77862594936709, -1.488775172413793 ], [ 29.778356455696201, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32896, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.488775172413793 ], [ 29.77862594936709, -1.489044655172414 ], [ 29.778895443037975, -1.489044655172414 ], [ 29.778895443037975, -1.488775172413793 ], [ 29.77862594936709, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32897, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.488775172413793 ], [ 29.778895443037975, -1.489044655172414 ], [ 29.77916493670886, -1.489044655172414 ], [ 29.77916493670886, -1.488775172413793 ], [ 29.778895443037975, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32898, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.488775172413793 ], [ 29.77916493670886, -1.489044655172414 ], [ 29.779434430379748, -1.489044655172414 ], [ 29.779434430379748, -1.488775172413793 ], [ 29.77916493670886, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32899, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.488775172413793 ], [ 29.779434430379748, -1.489044655172414 ], [ 29.779703924050633, -1.489044655172414 ], [ 29.779703924050633, -1.488775172413793 ], [ 29.779434430379748, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32900, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.488775172413793 ], [ 29.779703924050633, -1.489044655172414 ], [ 29.779973417721518, -1.489044655172414 ], [ 29.779973417721518, -1.488775172413793 ], [ 29.779703924050633, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32901, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.488775172413793 ], [ 29.779973417721518, -1.489044655172414 ], [ 29.780242911392406, -1.489044655172414 ], [ 29.780242911392406, -1.488775172413793 ], [ 29.779973417721518, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32902, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.488775172413793 ], [ 29.780242911392406, -1.489044655172414 ], [ 29.780512405063291, -1.489044655172414 ], [ 29.780512405063291, -1.488775172413793 ], [ 29.780242911392406, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32903, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.488775172413793 ], [ 29.780512405063291, -1.489044655172414 ], [ 29.780781898734176, -1.489044655172414 ], [ 29.780781898734176, -1.488775172413793 ], [ 29.780512405063291, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32904, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.488775172413793 ], [ 29.780781898734176, -1.489044655172414 ], [ 29.781051392405065, -1.489044655172414 ], [ 29.781051392405065, -1.488775172413793 ], [ 29.780781898734176, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32905, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.488775172413793 ], [ 29.781051392405065, -1.489044655172414 ], [ 29.78132088607595, -1.489044655172414 ], [ 29.78132088607595, -1.488775172413793 ], [ 29.781051392405065, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32906, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.488775172413793 ], [ 29.78132088607595, -1.489044655172414 ], [ 29.781590379746834, -1.489044655172414 ], [ 29.781590379746834, -1.488775172413793 ], [ 29.78132088607595, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32907, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.488775172413793 ], [ 29.781590379746834, -1.489044655172414 ], [ 29.781859873417723, -1.489044655172414 ], [ 29.781859873417723, -1.488775172413793 ], [ 29.781590379746834, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32908, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.488775172413793 ], [ 29.781859873417723, -1.489044655172414 ], [ 29.782129367088608, -1.489044655172414 ], [ 29.782129367088608, -1.488775172413793 ], [ 29.781859873417723, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32909, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.488775172413793 ], [ 29.782129367088608, -1.489044655172414 ], [ 29.782398860759493, -1.489044655172414 ], [ 29.782398860759493, -1.488775172413793 ], [ 29.782129367088608, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32910, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.488775172413793 ], [ 29.782398860759493, -1.489044655172414 ], [ 29.782668354430381, -1.489044655172414 ], [ 29.782668354430381, -1.488775172413793 ], [ 29.782398860759493, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32911, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.488775172413793 ], [ 29.782668354430381, -1.489044655172414 ], [ 29.782937848101266, -1.489044655172414 ], [ 29.782937848101266, -1.488775172413793 ], [ 29.782668354430381, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32912, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.488775172413793 ], [ 29.782937848101266, -1.489044655172414 ], [ 29.783476835443039, -1.489044655172414 ], [ 29.783476835443039, -1.488775172413793 ], [ 29.782937848101266, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32913, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.488775172413793 ], [ 29.783476835443039, -1.489044655172414 ], [ 29.786441265822784, -1.489044655172414 ], [ 29.786441265822784, -1.488775172413793 ], [ 29.783476835443039, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32914, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.488775172413793 ], [ 29.786441265822784, -1.489044655172414 ], [ 29.786710759493673, -1.489044655172414 ], [ 29.786710759493673, -1.488775172413793 ], [ 29.786441265822784, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32915, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.488775172413793 ], [ 29.786710759493673, -1.489044655172414 ], [ 29.786980253164558, -1.489044655172414 ], [ 29.786980253164558, -1.488775172413793 ], [ 29.786710759493673, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32916, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.488775172413793 ], [ 29.786980253164558, -1.489044655172414 ], [ 29.787249746835442, -1.489044655172414 ], [ 29.787249746835442, -1.488775172413793 ], [ 29.786980253164558, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32917, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.488775172413793 ], [ 29.787249746835442, -1.489044655172414 ], [ 29.787519240506327, -1.489044655172414 ], [ 29.787519240506327, -1.488775172413793 ], [ 29.787249746835442, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32918, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.488775172413793 ], [ 29.787519240506327, -1.489044655172414 ], [ 29.787788734177216, -1.489044655172414 ], [ 29.787788734177216, -1.488775172413793 ], [ 29.787519240506327, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32919, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.488775172413793 ], [ 29.801263417721518, -1.489044655172414 ], [ 29.801532911392407, -1.489044655172414 ], [ 29.801532911392407, -1.488775172413793 ], [ 29.801263417721518, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32920, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.488775172413793 ], [ 29.801532911392407, -1.489044655172414 ], [ 29.802071898734177, -1.489044655172414 ], [ 29.802071898734177, -1.488775172413793 ], [ 29.801532911392407, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32921, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.488775172413793 ], [ 29.802071898734177, -1.489044655172414 ], [ 29.802341392405065, -1.489044655172414 ], [ 29.802341392405065, -1.488775172413793 ], [ 29.802071898734177, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32922, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.488775172413793 ], [ 29.80261088607595, -1.489044655172414 ], [ 29.802880379746835, -1.489044655172414 ], [ 29.802880379746835, -1.488775172413793 ], [ 29.80261088607595, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32923, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.488775172413793 ], [ 29.802880379746835, -1.489044655172414 ], [ 29.803149873417723, -1.489044655172414 ], [ 29.803149873417723, -1.488775172413793 ], [ 29.802880379746835, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32924, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.486619310344828 ], [ 29.788866708860759, -1.490122586206897 ], [ 29.789136202531644, -1.490122586206897 ], [ 29.789136202531644, -1.486619310344828 ], [ 29.788866708860759, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32925, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789136202531644, -1.486619310344828 ], [ 29.789136202531644, -1.490392068965517 ], [ 29.789405696202532, -1.490392068965517 ], [ 29.789405696202532, -1.486619310344828 ], [ 29.789136202531644, -1.486619310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32926, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.486888793103448 ], [ 29.788597215189874, -1.489853103448276 ], [ 29.788866708860759, -1.489853103448276 ], [ 29.788866708860759, -1.486888793103448 ], [ 29.788597215189874, -1.486888793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32927, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.48742775862069 ], [ 29.787249746835442, -1.48769724137931 ], [ 29.788058227848101, -1.48769724137931 ], [ 29.788058227848101, -1.489314137931035 ], [ 29.788327721518986, -1.489314137931035 ], [ 29.788327721518986, -1.48742775862069 ], [ 29.787249746835442, -1.48742775862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32928, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804497341772151, -1.487966724137931 ], [ 29.804497341772151, -1.490122586206897 ], [ 29.80476683544304, -1.490122586206897 ], [ 29.80476683544304, -1.487966724137931 ], [ 29.804497341772151, -1.487966724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32929, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80045493670886, -1.488236206896552 ], [ 29.80045493670886, -1.489314137931035 ], [ 29.800724430379748, -1.489314137931035 ], [ 29.800724430379748, -1.488236206896552 ], [ 29.80045493670886, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32930, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.488236206896552 ], [ 29.803958354430382, -1.489853103448276 ], [ 29.804227848101267, -1.489853103448276 ], [ 29.804227848101267, -1.488236206896552 ], [ 29.803958354430382, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32931, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.488236206896552 ], [ 29.804227848101267, -1.489853103448276 ], [ 29.804497341772151, -1.489853103448276 ], [ 29.804497341772151, -1.488236206896552 ], [ 29.804227848101267, -1.488236206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32932, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.488505689655172 ], [ 29.803149873417723, -1.489314137931035 ], [ 29.803419367088608, -1.489314137931035 ], [ 29.803419367088608, -1.488505689655172 ], [ 29.803149873417723, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32933, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.488505689655172 ], [ 29.803419367088608, -1.489314137931035 ], [ 29.803688860759493, -1.489314137931035 ], [ 29.803688860759493, -1.488505689655172 ], [ 29.803419367088608, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32934, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.488505689655172 ], [ 29.803688860759493, -1.489314137931035 ], [ 29.803958354430382, -1.489314137931035 ], [ 29.803958354430382, -1.488505689655172 ], [ 29.803688860759493, -1.488505689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32935, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.488775172413793 ], [ 29.802341392405065, -1.489314137931035 ], [ 29.80261088607595, -1.489314137931035 ], [ 29.80261088607595, -1.488775172413793 ], [ 29.802341392405065, -1.488775172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32936, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768115696202532, -1.489044655172414 ], [ 29.768115696202532, -1.489853103448276 ], [ 29.768385189873417, -1.489853103448276 ], [ 29.768385189873417, -1.489044655172414 ], [ 29.768115696202532, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32937, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.489044655172414 ], [ 29.768385189873417, -1.489314137931035 ], [ 29.768654683544302, -1.489314137931035 ], [ 29.768654683544302, -1.489044655172414 ], [ 29.768385189873417, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32938, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.489044655172414 ], [ 29.768654683544302, -1.489314137931035 ], [ 29.76892417721519, -1.489314137931035 ], [ 29.76892417721519, -1.489044655172414 ], [ 29.768654683544302, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32939, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.489044655172414 ], [ 29.76892417721519, -1.489314137931035 ], [ 29.769193670886075, -1.489314137931035 ], [ 29.769193670886075, -1.489044655172414 ], [ 29.76892417721519, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32940, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.489044655172414 ], [ 29.769193670886075, -1.489314137931035 ], [ 29.76946316455696, -1.489314137931035 ], [ 29.76946316455696, -1.489044655172414 ], [ 29.769193670886075, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32941, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.489044655172414 ], [ 29.76946316455696, -1.489314137931035 ], [ 29.769732658227849, -1.489314137931035 ], [ 29.769732658227849, -1.489044655172414 ], [ 29.76946316455696, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32942, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.489044655172414 ], [ 29.769732658227849, -1.489314137931035 ], [ 29.770002151898733, -1.489314137931035 ], [ 29.770002151898733, -1.489044655172414 ], [ 29.769732658227849, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32943, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.489044655172414 ], [ 29.770002151898733, -1.489314137931035 ], [ 29.770271645569618, -1.489314137931035 ], [ 29.770271645569618, -1.489044655172414 ], [ 29.770002151898733, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32944, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.489044655172414 ], [ 29.770271645569618, -1.489314137931035 ], [ 29.770541139240507, -1.489314137931035 ], [ 29.770541139240507, -1.489044655172414 ], [ 29.770271645569618, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32945, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.489044655172414 ], [ 29.770541139240507, -1.489314137931035 ], [ 29.770810632911392, -1.489314137931035 ], [ 29.770810632911392, -1.489044655172414 ], [ 29.770541139240507, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32946, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.489044655172414 ], [ 29.770810632911392, -1.489314137931035 ], [ 29.771080126582277, -1.489314137931035 ], [ 29.771080126582277, -1.489044655172414 ], [ 29.770810632911392, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32947, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.489044655172414 ], [ 29.771080126582277, -1.489314137931035 ], [ 29.771349620253165, -1.489314137931035 ], [ 29.771349620253165, -1.489044655172414 ], [ 29.771080126582277, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32948, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.489044655172414 ], [ 29.771349620253165, -1.489314137931035 ], [ 29.771888607594935, -1.489314137931035 ], [ 29.771888607594935, -1.489044655172414 ], [ 29.771349620253165, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32949, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.489044655172414 ], [ 29.771888607594935, -1.489314137931035 ], [ 29.772158101265823, -1.489314137931035 ], [ 29.772158101265823, -1.489044655172414 ], [ 29.771888607594935, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32950, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.489044655172414 ], [ 29.772158101265823, -1.489314137931035 ], [ 29.772427594936708, -1.489314137931035 ], [ 29.772427594936708, -1.489044655172414 ], [ 29.772158101265823, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32951, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.489044655172414 ], [ 29.772427594936708, -1.489314137931035 ], [ 29.772697088607593, -1.489314137931035 ], [ 29.772697088607593, -1.489044655172414 ], [ 29.772427594936708, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32952, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.489044655172414 ], [ 29.772697088607593, -1.489314137931035 ], [ 29.772966582278482, -1.489314137931035 ], [ 29.772966582278482, -1.489044655172414 ], [ 29.772697088607593, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32953, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.489044655172414 ], [ 29.772966582278482, -1.489314137931035 ], [ 29.773236075949367, -1.489314137931035 ], [ 29.773236075949367, -1.489044655172414 ], [ 29.772966582278482, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32954, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.489044655172414 ], [ 29.773236075949367, -1.489314137931035 ], [ 29.773505569620252, -1.489314137931035 ], [ 29.773505569620252, -1.489044655172414 ], [ 29.773236075949367, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32955, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.489044655172414 ], [ 29.773505569620252, -1.489314137931035 ], [ 29.77377506329114, -1.489314137931035 ], [ 29.77377506329114, -1.489044655172414 ], [ 29.773505569620252, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32956, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.489044655172414 ], [ 29.77377506329114, -1.489314137931035 ], [ 29.774044556962025, -1.489314137931035 ], [ 29.774044556962025, -1.489044655172414 ], [ 29.77377506329114, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32957, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.489044655172414 ], [ 29.774044556962025, -1.489314137931035 ], [ 29.77431405063291, -1.489314137931035 ], [ 29.77431405063291, -1.489044655172414 ], [ 29.774044556962025, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32958, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.489044655172414 ], [ 29.77431405063291, -1.489314137931035 ], [ 29.774853037974683, -1.489314137931035 ], [ 29.774853037974683, -1.489044655172414 ], [ 29.77431405063291, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32959, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.489044655172414 ], [ 29.774853037974683, -1.489314137931035 ], [ 29.775122531645568, -1.489314137931035 ], [ 29.775122531645568, -1.489044655172414 ], [ 29.774853037974683, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32960, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.489044655172414 ], [ 29.775122531645568, -1.489314137931035 ], [ 29.775392025316457, -1.489314137931035 ], [ 29.775392025316457, -1.489044655172414 ], [ 29.775122531645568, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32961, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.489044655172414 ], [ 29.775392025316457, -1.489314137931035 ], [ 29.775661518987341, -1.489314137931035 ], [ 29.775661518987341, -1.489044655172414 ], [ 29.775392025316457, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32962, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.489044655172414 ], [ 29.775661518987341, -1.489314137931035 ], [ 29.775931012658226, -1.489314137931035 ], [ 29.775931012658226, -1.489044655172414 ], [ 29.775661518987341, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32963, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.489044655172414 ], [ 29.775931012658226, -1.489314137931035 ], [ 29.776200506329115, -1.489314137931035 ], [ 29.776200506329115, -1.489044655172414 ], [ 29.775931012658226, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32964, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.489044655172414 ], [ 29.776200506329115, -1.489314137931035 ], [ 29.77647, -1.489314137931035 ], [ 29.77647, -1.489044655172414 ], [ 29.776200506329115, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32965, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.489044655172414 ], [ 29.77647, -1.489314137931035 ], [ 29.776739493670885, -1.489314137931035 ], [ 29.776739493670885, -1.489044655172414 ], [ 29.77647, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32966, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.489044655172414 ], [ 29.776739493670885, -1.489314137931035 ], [ 29.777008987341773, -1.489314137931035 ], [ 29.777008987341773, -1.489044655172414 ], [ 29.776739493670885, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32967, "El": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.489044655172414 ], [ 29.777008987341773, -1.489314137931035 ], [ 29.777278481012658, -1.489314137931035 ], [ 29.777278481012658, -1.489044655172414 ], [ 29.777008987341773, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32968, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.489044655172414 ], [ 29.777278481012658, -1.489314137931035 ], [ 29.777547974683543, -1.489314137931035 ], [ 29.777547974683543, -1.489044655172414 ], [ 29.777278481012658, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32969, "El": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.489044655172414 ], [ 29.777547974683543, -1.489314137931035 ], [ 29.778086962025316, -1.489314137931035 ], [ 29.778086962025316, -1.489044655172414 ], [ 29.777547974683543, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32970, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.489044655172414 ], [ 29.778086962025316, -1.489314137931035 ], [ 29.778356455696201, -1.489314137931035 ], [ 29.778356455696201, -1.489044655172414 ], [ 29.778086962025316, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32971, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.489044655172414 ], [ 29.778356455696201, -1.489314137931035 ], [ 29.77862594936709, -1.489314137931035 ], [ 29.77862594936709, -1.489044655172414 ], [ 29.778356455696201, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32972, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.489044655172414 ], [ 29.77862594936709, -1.489314137931035 ], [ 29.778895443037975, -1.489314137931035 ], [ 29.778895443037975, -1.489044655172414 ], [ 29.77862594936709, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32973, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.489044655172414 ], [ 29.778895443037975, -1.489314137931035 ], [ 29.77916493670886, -1.489314137931035 ], [ 29.77916493670886, -1.489044655172414 ], [ 29.778895443037975, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32974, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.489044655172414 ], [ 29.77916493670886, -1.489314137931035 ], [ 29.779434430379748, -1.489314137931035 ], [ 29.779434430379748, -1.489044655172414 ], [ 29.77916493670886, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32975, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.489044655172414 ], [ 29.779434430379748, -1.489314137931035 ], [ 29.779703924050633, -1.489314137931035 ], [ 29.779703924050633, -1.489044655172414 ], [ 29.779434430379748, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32976, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.489044655172414 ], [ 29.779703924050633, -1.489314137931035 ], [ 29.779973417721518, -1.489314137931035 ], [ 29.779973417721518, -1.489044655172414 ], [ 29.779703924050633, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32977, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.489044655172414 ], [ 29.779973417721518, -1.489314137931035 ], [ 29.780242911392406, -1.489314137931035 ], [ 29.780242911392406, -1.489044655172414 ], [ 29.779973417721518, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32978, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.489044655172414 ], [ 29.780242911392406, -1.489314137931035 ], [ 29.780512405063291, -1.489314137931035 ], [ 29.780512405063291, -1.489044655172414 ], [ 29.780242911392406, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32979, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.489044655172414 ], [ 29.780512405063291, -1.489314137931035 ], [ 29.780781898734176, -1.489314137931035 ], [ 29.780781898734176, -1.489044655172414 ], [ 29.780512405063291, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32980, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.489044655172414 ], [ 29.780781898734176, -1.489314137931035 ], [ 29.781051392405065, -1.489314137931035 ], [ 29.781051392405065, -1.489044655172414 ], [ 29.780781898734176, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32981, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.489044655172414 ], [ 29.781051392405065, -1.489314137931035 ], [ 29.78132088607595, -1.489314137931035 ], [ 29.78132088607595, -1.489044655172414 ], [ 29.781051392405065, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32982, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.489044655172414 ], [ 29.78132088607595, -1.489314137931035 ], [ 29.781590379746834, -1.489314137931035 ], [ 29.781590379746834, -1.489044655172414 ], [ 29.78132088607595, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32983, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.489044655172414 ], [ 29.781590379746834, -1.489314137931035 ], [ 29.781859873417723, -1.489314137931035 ], [ 29.781859873417723, -1.489044655172414 ], [ 29.781590379746834, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32984, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.489044655172414 ], [ 29.781859873417723, -1.489314137931035 ], [ 29.782129367088608, -1.489314137931035 ], [ 29.782129367088608, -1.489044655172414 ], [ 29.781859873417723, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32985, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.489044655172414 ], [ 29.782129367088608, -1.489314137931035 ], [ 29.782398860759493, -1.489314137931035 ], [ 29.782398860759493, -1.489044655172414 ], [ 29.782129367088608, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32986, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.489044655172414 ], [ 29.782398860759493, -1.489314137931035 ], [ 29.782668354430381, -1.489314137931035 ], [ 29.782668354430381, -1.489044655172414 ], [ 29.782398860759493, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32987, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.489044655172414 ], [ 29.782668354430381, -1.489314137931035 ], [ 29.782937848101266, -1.489314137931035 ], [ 29.782937848101266, -1.489044655172414 ], [ 29.782668354430381, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32988, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.489044655172414 ], [ 29.782937848101266, -1.489314137931035 ], [ 29.783207341772151, -1.489314137931035 ], [ 29.783207341772151, -1.489044655172414 ], [ 29.782937848101266, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32989, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.489044655172414 ], [ 29.783207341772151, -1.489314137931035 ], [ 29.786441265822784, -1.489314137931035 ], [ 29.786441265822784, -1.489044655172414 ], [ 29.783207341772151, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32990, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786441265822784, -1.489044655172414 ], [ 29.786441265822784, -1.489314137931035 ], [ 29.786710759493673, -1.489314137931035 ], [ 29.786710759493673, -1.489044655172414 ], [ 29.786441265822784, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32991, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.489044655172414 ], [ 29.786710759493673, -1.489314137931035 ], [ 29.786980253164558, -1.489314137931035 ], [ 29.786980253164558, -1.489044655172414 ], [ 29.786710759493673, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32992, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.489044655172414 ], [ 29.786980253164558, -1.489314137931035 ], [ 29.787249746835442, -1.489314137931035 ], [ 29.787249746835442, -1.489044655172414 ], [ 29.786980253164558, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32993, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.489044655172414 ], [ 29.787249746835442, -1.489314137931035 ], [ 29.787519240506327, -1.489314137931035 ], [ 29.787519240506327, -1.489044655172414 ], [ 29.787249746835442, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32994, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.489044655172414 ], [ 29.787519240506327, -1.489314137931035 ], [ 29.787788734177216, -1.489314137931035 ], [ 29.787788734177216, -1.489044655172414 ], [ 29.787519240506327, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32995, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.489044655172414 ], [ 29.787788734177216, -1.489314137931035 ], [ 29.788058227848101, -1.489314137931035 ], [ 29.788058227848101, -1.489044655172414 ], [ 29.787788734177216, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32996, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.489044655172414 ], [ 29.800724430379748, -1.489314137931035 ], [ 29.800993924050633, -1.489314137931035 ], [ 29.800993924050633, -1.489044655172414 ], [ 29.800724430379748, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32997, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.489044655172414 ], [ 29.800993924050633, -1.489314137931035 ], [ 29.801263417721518, -1.489314137931035 ], [ 29.801263417721518, -1.489044655172414 ], [ 29.800993924050633, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32998, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.489044655172414 ], [ 29.801263417721518, -1.489314137931035 ], [ 29.801532911392407, -1.489314137931035 ], [ 29.801532911392407, -1.489044655172414 ], [ 29.801263417721518, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 32999, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.489044655172414 ], [ 29.801532911392407, -1.490392068965517 ], [ 29.802071898734177, -1.490392068965517 ], [ 29.802071898734177, -1.489044655172414 ], [ 29.801532911392407, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33000, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.489044655172414 ], [ 29.802071898734177, -1.490392068965517 ], [ 29.802341392405065, -1.490392068965517 ], [ 29.802341392405065, -1.489044655172414 ], [ 29.802071898734177, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33001, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.489044655172414 ], [ 29.80261088607595, -1.489853103448276 ], [ 29.802880379746835, -1.489853103448276 ], [ 29.802880379746835, -1.489044655172414 ], [ 29.80261088607595, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33002, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.489044655172414 ], [ 29.802880379746835, -1.489314137931035 ], [ 29.803149873417723, -1.489314137931035 ], [ 29.803149873417723, -1.489044655172414 ], [ 29.802880379746835, -1.489044655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33003, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.489314137931035 ], [ 29.768385189873417, -1.489853103448276 ], [ 29.768654683544302, -1.489853103448276 ], [ 29.768654683544302, -1.489314137931035 ], [ 29.768385189873417, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33004, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.489314137931035 ], [ 29.768654683544302, -1.489853103448276 ], [ 29.76892417721519, -1.489853103448276 ], [ 29.76892417721519, -1.489314137931035 ], [ 29.768654683544302, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33005, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.489314137931035 ], [ 29.76892417721519, -1.489853103448276 ], [ 29.769193670886075, -1.489853103448276 ], [ 29.769193670886075, -1.489314137931035 ], [ 29.76892417721519, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33006, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.489314137931035 ], [ 29.769193670886075, -1.489853103448276 ], [ 29.76946316455696, -1.489853103448276 ], [ 29.76946316455696, -1.489314137931035 ], [ 29.769193670886075, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33007, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.489314137931035 ], [ 29.76946316455696, -1.489853103448276 ], [ 29.769732658227849, -1.489853103448276 ], [ 29.769732658227849, -1.489314137931035 ], [ 29.76946316455696, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33008, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.489314137931035 ], [ 29.769732658227849, -1.489853103448276 ], [ 29.770002151898733, -1.489853103448276 ], [ 29.770002151898733, -1.489314137931035 ], [ 29.769732658227849, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33009, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.489314137931035 ], [ 29.770002151898733, -1.489853103448276 ], [ 29.770271645569618, -1.489853103448276 ], [ 29.770271645569618, -1.489314137931035 ], [ 29.770002151898733, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33010, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.489314137931035 ], [ 29.770271645569618, -1.489853103448276 ], [ 29.770541139240507, -1.489853103448276 ], [ 29.770541139240507, -1.489314137931035 ], [ 29.770271645569618, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33011, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.489314137931035 ], [ 29.770541139240507, -1.489853103448276 ], [ 29.770810632911392, -1.489853103448276 ], [ 29.770810632911392, -1.489314137931035 ], [ 29.770541139240507, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33012, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.489314137931035 ], [ 29.770810632911392, -1.489853103448276 ], [ 29.771080126582277, -1.489853103448276 ], [ 29.771080126582277, -1.489314137931035 ], [ 29.770810632911392, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33013, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.489314137931035 ], [ 29.771080126582277, -1.489853103448276 ], [ 29.771349620253165, -1.489853103448276 ], [ 29.771349620253165, -1.489314137931035 ], [ 29.771080126582277, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33014, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.489314137931035 ], [ 29.771349620253165, -1.489853103448276 ], [ 29.77161911392405, -1.489853103448276 ], [ 29.77161911392405, -1.489314137931035 ], [ 29.771349620253165, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33015, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.489314137931035 ], [ 29.77161911392405, -1.489853103448276 ], [ 29.771888607594935, -1.489853103448276 ], [ 29.771888607594935, -1.489314137931035 ], [ 29.77161911392405, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33016, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.489314137931035 ], [ 29.771888607594935, -1.489853103448276 ], [ 29.772158101265823, -1.489853103448276 ], [ 29.772158101265823, -1.489314137931035 ], [ 29.771888607594935, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33017, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.489314137931035 ], [ 29.772158101265823, -1.489853103448276 ], [ 29.772427594936708, -1.489853103448276 ], [ 29.772427594936708, -1.489314137931035 ], [ 29.772158101265823, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33018, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.489314137931035 ], [ 29.772427594936708, -1.489853103448276 ], [ 29.772697088607593, -1.489853103448276 ], [ 29.772697088607593, -1.489314137931035 ], [ 29.772427594936708, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33019, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.489314137931035 ], [ 29.772697088607593, -1.489853103448276 ], [ 29.772966582278482, -1.489853103448276 ], [ 29.772966582278482, -1.489314137931035 ], [ 29.772697088607593, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33020, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.489314137931035 ], [ 29.772966582278482, -1.489853103448276 ], [ 29.773236075949367, -1.489853103448276 ], [ 29.773236075949367, -1.489314137931035 ], [ 29.772966582278482, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33021, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.489314137931035 ], [ 29.773236075949367, -1.489853103448276 ], [ 29.773505569620252, -1.489853103448276 ], [ 29.773505569620252, -1.489314137931035 ], [ 29.773236075949367, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33022, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.489314137931035 ], [ 29.773505569620252, -1.489853103448276 ], [ 29.77377506329114, -1.489853103448276 ], [ 29.77377506329114, -1.489314137931035 ], [ 29.773505569620252, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33023, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.489314137931035 ], [ 29.77377506329114, -1.489853103448276 ], [ 29.774044556962025, -1.489853103448276 ], [ 29.774044556962025, -1.489314137931035 ], [ 29.77377506329114, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33024, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.489314137931035 ], [ 29.774044556962025, -1.489853103448276 ], [ 29.77431405063291, -1.489853103448276 ], [ 29.77431405063291, -1.489314137931035 ], [ 29.774044556962025, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33025, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.489314137931035 ], [ 29.77431405063291, -1.489853103448276 ], [ 29.774853037974683, -1.489853103448276 ], [ 29.774853037974683, -1.489314137931035 ], [ 29.77431405063291, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33026, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.489314137931035 ], [ 29.774853037974683, -1.489853103448276 ], [ 29.775122531645568, -1.489853103448276 ], [ 29.775122531645568, -1.489314137931035 ], [ 29.774853037974683, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33027, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.489314137931035 ], [ 29.775122531645568, -1.489853103448276 ], [ 29.775392025316457, -1.489853103448276 ], [ 29.775392025316457, -1.489314137931035 ], [ 29.775122531645568, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33028, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.489314137931035 ], [ 29.775392025316457, -1.489853103448276 ], [ 29.775661518987341, -1.489853103448276 ], [ 29.775661518987341, -1.489314137931035 ], [ 29.775392025316457, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33029, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.489314137931035 ], [ 29.775661518987341, -1.489853103448276 ], [ 29.775931012658226, -1.489853103448276 ], [ 29.775931012658226, -1.489314137931035 ], [ 29.775661518987341, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33030, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.489314137931035 ], [ 29.775931012658226, -1.489853103448276 ], [ 29.776200506329115, -1.489853103448276 ], [ 29.776200506329115, -1.489314137931035 ], [ 29.775931012658226, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33031, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.489314137931035 ], [ 29.776200506329115, -1.489853103448276 ], [ 29.77647, -1.489853103448276 ], [ 29.77647, -1.489314137931035 ], [ 29.776200506329115, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33032, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.489314137931035 ], [ 29.77647, -1.489853103448276 ], [ 29.776739493670885, -1.489853103448276 ], [ 29.776739493670885, -1.489314137931035 ], [ 29.77647, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33033, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.489314137931035 ], [ 29.776739493670885, -1.489853103448276 ], [ 29.777008987341773, -1.489853103448276 ], [ 29.777008987341773, -1.489314137931035 ], [ 29.776739493670885, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33034, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.489314137931035 ], [ 29.777008987341773, -1.489853103448276 ], [ 29.777278481012658, -1.489853103448276 ], [ 29.777278481012658, -1.489314137931035 ], [ 29.777008987341773, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33035, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.489314137931035 ], [ 29.777278481012658, -1.489853103448276 ], [ 29.777547974683543, -1.489853103448276 ], [ 29.777547974683543, -1.489314137931035 ], [ 29.777278481012658, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33036, "El": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.489314137931035 ], [ 29.777547974683543, -1.489853103448276 ], [ 29.778086962025316, -1.489853103448276 ], [ 29.778086962025316, -1.489314137931035 ], [ 29.777547974683543, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33037, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.489314137931035 ], [ 29.778086962025316, -1.489853103448276 ], [ 29.778356455696201, -1.489853103448276 ], [ 29.778356455696201, -1.489314137931035 ], [ 29.778086962025316, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33038, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.489314137931035 ], [ 29.778356455696201, -1.489853103448276 ], [ 29.77862594936709, -1.489853103448276 ], [ 29.77862594936709, -1.489314137931035 ], [ 29.778356455696201, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33039, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.489314137931035 ], [ 29.77862594936709, -1.489853103448276 ], [ 29.778895443037975, -1.489853103448276 ], [ 29.778895443037975, -1.489314137931035 ], [ 29.77862594936709, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33040, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.489314137931035 ], [ 29.778895443037975, -1.489853103448276 ], [ 29.77916493670886, -1.489853103448276 ], [ 29.77916493670886, -1.489314137931035 ], [ 29.778895443037975, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33041, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.489314137931035 ], [ 29.77916493670886, -1.489853103448276 ], [ 29.779434430379748, -1.489853103448276 ], [ 29.779434430379748, -1.489314137931035 ], [ 29.77916493670886, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33042, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.489314137931035 ], [ 29.779434430379748, -1.489853103448276 ], [ 29.779703924050633, -1.489853103448276 ], [ 29.779703924050633, -1.489314137931035 ], [ 29.779434430379748, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33043, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.489314137931035 ], [ 29.779703924050633, -1.489853103448276 ], [ 29.779973417721518, -1.489853103448276 ], [ 29.779973417721518, -1.489314137931035 ], [ 29.779703924050633, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33044, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.489314137931035 ], [ 29.779973417721518, -1.489853103448276 ], [ 29.780242911392406, -1.489853103448276 ], [ 29.780242911392406, -1.489314137931035 ], [ 29.779973417721518, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33045, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.489314137931035 ], [ 29.780242911392406, -1.489853103448276 ], [ 29.780512405063291, -1.489853103448276 ], [ 29.780512405063291, -1.489314137931035 ], [ 29.780242911392406, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33046, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.489314137931035 ], [ 29.780512405063291, -1.489853103448276 ], [ 29.780781898734176, -1.489853103448276 ], [ 29.780781898734176, -1.489314137931035 ], [ 29.780512405063291, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33047, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.489314137931035 ], [ 29.780781898734176, -1.489853103448276 ], [ 29.781051392405065, -1.489853103448276 ], [ 29.781051392405065, -1.489314137931035 ], [ 29.780781898734176, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33048, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.489314137931035 ], [ 29.781051392405065, -1.489853103448276 ], [ 29.78132088607595, -1.489853103448276 ], [ 29.78132088607595, -1.489314137931035 ], [ 29.781051392405065, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33049, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.489314137931035 ], [ 29.78132088607595, -1.489853103448276 ], [ 29.781590379746834, -1.489853103448276 ], [ 29.781590379746834, -1.489314137931035 ], [ 29.78132088607595, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33050, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.489314137931035 ], [ 29.781590379746834, -1.489853103448276 ], [ 29.781859873417723, -1.489853103448276 ], [ 29.781859873417723, -1.489314137931035 ], [ 29.781590379746834, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33051, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.489314137931035 ], [ 29.781859873417723, -1.489853103448276 ], [ 29.782129367088608, -1.489853103448276 ], [ 29.782129367088608, -1.489314137931035 ], [ 29.781859873417723, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33052, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.489314137931035 ], [ 29.782129367088608, -1.489853103448276 ], [ 29.782398860759493, -1.489853103448276 ], [ 29.782398860759493, -1.489314137931035 ], [ 29.782129367088608, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33053, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.489314137931035 ], [ 29.782398860759493, -1.489853103448276 ], [ 29.782668354430381, -1.489853103448276 ], [ 29.782668354430381, -1.489314137931035 ], [ 29.782398860759493, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33054, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.489314137931035 ], [ 29.782668354430381, -1.489853103448276 ], [ 29.782937848101266, -1.489853103448276 ], [ 29.782937848101266, -1.489314137931035 ], [ 29.782668354430381, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33055, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.489314137931035 ], [ 29.782937848101266, -1.489853103448276 ], [ 29.783207341772151, -1.489853103448276 ], [ 29.783207341772151, -1.489314137931035 ], [ 29.782937848101266, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33056, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.489314137931035 ], [ 29.783207341772151, -1.489853103448276 ], [ 29.783476835443039, -1.489853103448276 ], [ 29.783476835443039, -1.489314137931035 ], [ 29.783207341772151, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33057, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.489314137931035 ], [ 29.783476835443039, -1.489853103448276 ], [ 29.786710759493673, -1.489853103448276 ], [ 29.786710759493673, -1.489314137931035 ], [ 29.783476835443039, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33058, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.489314137931035 ], [ 29.786710759493673, -1.489853103448276 ], [ 29.786980253164558, -1.489853103448276 ], [ 29.786980253164558, -1.489314137931035 ], [ 29.786710759493673, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33059, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.489314137931035 ], [ 29.786980253164558, -1.489853103448276 ], [ 29.787249746835442, -1.489853103448276 ], [ 29.787249746835442, -1.489314137931035 ], [ 29.786980253164558, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33060, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.489314137931035 ], [ 29.787249746835442, -1.489853103448276 ], [ 29.787519240506327, -1.489853103448276 ], [ 29.787519240506327, -1.489314137931035 ], [ 29.787249746835442, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33061, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.489314137931035 ], [ 29.787519240506327, -1.489853103448276 ], [ 29.787788734177216, -1.489853103448276 ], [ 29.787788734177216, -1.489314137931035 ], [ 29.787519240506327, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33062, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.489314137931035 ], [ 29.787788734177216, -1.489853103448276 ], [ 29.788058227848101, -1.489853103448276 ], [ 29.788058227848101, -1.489314137931035 ], [ 29.787788734177216, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33063, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.487158275862069 ], [ 29.788597215189874, -1.487158275862069 ], [ 29.788597215189874, -1.489853103448276 ], [ 29.788058227848101, -1.489853103448276 ], [ 29.788058227848101, -1.489314137931035 ], [ 29.788327721518986, -1.489314137931035 ], [ 29.788327721518986, -1.48742775862069 ], [ 29.786980253164558, -1.48742775862069 ], [ 29.786980253164558, -1.487158275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33064, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800724430379748, -1.489314137931035 ], [ 29.800724430379748, -1.490931034482759 ], [ 29.800993924050633, -1.490931034482759 ], [ 29.800993924050633, -1.489314137931035 ], [ 29.800724430379748, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33065, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.489314137931035 ], [ 29.800993924050633, -1.490661551724138 ], [ 29.801263417721518, -1.490661551724138 ], [ 29.801263417721518, -1.489314137931035 ], [ 29.800993924050633, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33066, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.489314137931035 ], [ 29.801263417721518, -1.490661551724138 ], [ 29.801532911392407, -1.490661551724138 ], [ 29.801532911392407, -1.489314137931035 ], [ 29.801263417721518, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33067, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.489314137931035 ], [ 29.802341392405065, -1.490392068965517 ], [ 29.80261088607595, -1.490392068965517 ], [ 29.80261088607595, -1.489314137931035 ], [ 29.802341392405065, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33068, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.489314137931035 ], [ 29.802880379746835, -1.489853103448276 ], [ 29.803149873417723, -1.489853103448276 ], [ 29.803149873417723, -1.489314137931035 ], [ 29.802880379746835, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33069, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.489314137931035 ], [ 29.803149873417723, -1.489853103448276 ], [ 29.803419367088608, -1.489853103448276 ], [ 29.803419367088608, -1.489314137931035 ], [ 29.803149873417723, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33070, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.489314137931035 ], [ 29.803419367088608, -1.489853103448276 ], [ 29.803688860759493, -1.489853103448276 ], [ 29.803688860759493, -1.489314137931035 ], [ 29.803419367088608, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33071, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.489314137931035 ], [ 29.803688860759493, -1.489853103448276 ], [ 29.803958354430382, -1.489853103448276 ], [ 29.803958354430382, -1.489314137931035 ], [ 29.803688860759493, -1.489314137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33072, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768385189873417, -1.489853103448276 ], [ 29.768385189873417, -1.490392068965517 ], [ 29.768654683544302, -1.490392068965517 ], [ 29.768654683544302, -1.489853103448276 ], [ 29.768385189873417, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33073, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.489853103448276 ], [ 29.768654683544302, -1.490122586206897 ], [ 29.76892417721519, -1.490122586206897 ], [ 29.76892417721519, -1.489853103448276 ], [ 29.768654683544302, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33074, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.489853103448276 ], [ 29.76892417721519, -1.490122586206897 ], [ 29.769193670886075, -1.490122586206897 ], [ 29.769193670886075, -1.489853103448276 ], [ 29.76892417721519, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33075, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.489853103448276 ], [ 29.769193670886075, -1.490122586206897 ], [ 29.76946316455696, -1.490122586206897 ], [ 29.76946316455696, -1.489853103448276 ], [ 29.769193670886075, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33076, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.489853103448276 ], [ 29.76946316455696, -1.490122586206897 ], [ 29.769732658227849, -1.490122586206897 ], [ 29.769732658227849, -1.489853103448276 ], [ 29.76946316455696, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33077, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.489853103448276 ], [ 29.769732658227849, -1.490122586206897 ], [ 29.770002151898733, -1.490122586206897 ], [ 29.770002151898733, -1.489853103448276 ], [ 29.769732658227849, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33078, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.489853103448276 ], [ 29.770002151898733, -1.490122586206897 ], [ 29.770271645569618, -1.490122586206897 ], [ 29.770271645569618, -1.489853103448276 ], [ 29.770002151898733, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33079, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.489853103448276 ], [ 29.770271645569618, -1.490122586206897 ], [ 29.770541139240507, -1.490122586206897 ], [ 29.770541139240507, -1.489853103448276 ], [ 29.770271645569618, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33080, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.489853103448276 ], [ 29.770541139240507, -1.490122586206897 ], [ 29.770810632911392, -1.490122586206897 ], [ 29.770810632911392, -1.489853103448276 ], [ 29.770541139240507, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33081, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.489853103448276 ], [ 29.770810632911392, -1.490122586206897 ], [ 29.771080126582277, -1.490122586206897 ], [ 29.771080126582277, -1.489853103448276 ], [ 29.770810632911392, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33082, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.489853103448276 ], [ 29.771080126582277, -1.490122586206897 ], [ 29.771349620253165, -1.490122586206897 ], [ 29.771349620253165, -1.489853103448276 ], [ 29.771080126582277, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33083, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.489853103448276 ], [ 29.771349620253165, -1.490122586206897 ], [ 29.77161911392405, -1.490122586206897 ], [ 29.77161911392405, -1.489853103448276 ], [ 29.771349620253165, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33084, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.489853103448276 ], [ 29.77161911392405, -1.490122586206897 ], [ 29.771888607594935, -1.490122586206897 ], [ 29.771888607594935, -1.489853103448276 ], [ 29.77161911392405, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33085, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.489853103448276 ], [ 29.771888607594935, -1.490122586206897 ], [ 29.772158101265823, -1.490122586206897 ], [ 29.772158101265823, -1.489853103448276 ], [ 29.771888607594935, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33086, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.489853103448276 ], [ 29.772158101265823, -1.490122586206897 ], [ 29.772427594936708, -1.490122586206897 ], [ 29.772427594936708, -1.489853103448276 ], [ 29.772158101265823, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33087, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.489853103448276 ], [ 29.772427594936708, -1.490122586206897 ], [ 29.772697088607593, -1.490122586206897 ], [ 29.772697088607593, -1.489853103448276 ], [ 29.772427594936708, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33088, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.489853103448276 ], [ 29.772697088607593, -1.490122586206897 ], [ 29.772966582278482, -1.490122586206897 ], [ 29.772966582278482, -1.489853103448276 ], [ 29.772697088607593, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33089, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.489853103448276 ], [ 29.772966582278482, -1.490122586206897 ], [ 29.773236075949367, -1.490122586206897 ], [ 29.773236075949367, -1.489853103448276 ], [ 29.772966582278482, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33090, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.489853103448276 ], [ 29.773236075949367, -1.490122586206897 ], [ 29.773505569620252, -1.490122586206897 ], [ 29.773505569620252, -1.489853103448276 ], [ 29.773236075949367, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33091, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.489853103448276 ], [ 29.773505569620252, -1.490122586206897 ], [ 29.77377506329114, -1.490122586206897 ], [ 29.77377506329114, -1.489853103448276 ], [ 29.773505569620252, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33092, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.489853103448276 ], [ 29.77377506329114, -1.490122586206897 ], [ 29.774044556962025, -1.490122586206897 ], [ 29.774044556962025, -1.489853103448276 ], [ 29.77377506329114, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33093, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.489853103448276 ], [ 29.774044556962025, -1.490122586206897 ], [ 29.77431405063291, -1.490122586206897 ], [ 29.77431405063291, -1.489853103448276 ], [ 29.774044556962025, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33094, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.489853103448276 ], [ 29.77431405063291, -1.490122586206897 ], [ 29.774853037974683, -1.490122586206897 ], [ 29.774853037974683, -1.489853103448276 ], [ 29.77431405063291, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33095, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.489853103448276 ], [ 29.774853037974683, -1.490122586206897 ], [ 29.775122531645568, -1.490122586206897 ], [ 29.775122531645568, -1.489853103448276 ], [ 29.774853037974683, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33096, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.489853103448276 ], [ 29.775122531645568, -1.490122586206897 ], [ 29.775392025316457, -1.490122586206897 ], [ 29.775392025316457, -1.489853103448276 ], [ 29.775122531645568, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33097, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.489853103448276 ], [ 29.775392025316457, -1.490122586206897 ], [ 29.775661518987341, -1.490122586206897 ], [ 29.775661518987341, -1.489853103448276 ], [ 29.775392025316457, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33098, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.489853103448276 ], [ 29.775661518987341, -1.490122586206897 ], [ 29.775931012658226, -1.490122586206897 ], [ 29.775931012658226, -1.489853103448276 ], [ 29.775661518987341, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33099, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.489853103448276 ], [ 29.775931012658226, -1.490122586206897 ], [ 29.776200506329115, -1.490122586206897 ], [ 29.776200506329115, -1.489853103448276 ], [ 29.775931012658226, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33100, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.489853103448276 ], [ 29.776200506329115, -1.490122586206897 ], [ 29.77647, -1.490122586206897 ], [ 29.77647, -1.489853103448276 ], [ 29.776200506329115, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33101, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.489853103448276 ], [ 29.77647, -1.490122586206897 ], [ 29.776739493670885, -1.490122586206897 ], [ 29.776739493670885, -1.489853103448276 ], [ 29.77647, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33102, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.489853103448276 ], [ 29.776739493670885, -1.490122586206897 ], [ 29.777008987341773, -1.490122586206897 ], [ 29.777008987341773, -1.489853103448276 ], [ 29.776739493670885, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33103, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.489853103448276 ], [ 29.777008987341773, -1.490122586206897 ], [ 29.777278481012658, -1.490122586206897 ], [ 29.777278481012658, -1.489853103448276 ], [ 29.777008987341773, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33104, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.489853103448276 ], [ 29.777278481012658, -1.490122586206897 ], [ 29.777547974683543, -1.490122586206897 ], [ 29.777547974683543, -1.489853103448276 ], [ 29.777278481012658, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33105, "El": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.489853103448276 ], [ 29.777547974683543, -1.490122586206897 ], [ 29.778086962025316, -1.490122586206897 ], [ 29.778086962025316, -1.489853103448276 ], [ 29.777547974683543, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33106, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.489853103448276 ], [ 29.778086962025316, -1.490122586206897 ], [ 29.778356455696201, -1.490122586206897 ], [ 29.778356455696201, -1.489853103448276 ], [ 29.778086962025316, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33107, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.489853103448276 ], [ 29.778356455696201, -1.490122586206897 ], [ 29.77862594936709, -1.490122586206897 ], [ 29.77862594936709, -1.489853103448276 ], [ 29.778356455696201, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33108, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.489853103448276 ], [ 29.77862594936709, -1.490122586206897 ], [ 29.778895443037975, -1.490122586206897 ], [ 29.778895443037975, -1.489853103448276 ], [ 29.77862594936709, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33109, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.489853103448276 ], [ 29.778895443037975, -1.490122586206897 ], [ 29.77916493670886, -1.490122586206897 ], [ 29.77916493670886, -1.489853103448276 ], [ 29.778895443037975, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33110, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.489853103448276 ], [ 29.77916493670886, -1.490122586206897 ], [ 29.779434430379748, -1.490122586206897 ], [ 29.779434430379748, -1.489853103448276 ], [ 29.77916493670886, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33111, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.489853103448276 ], [ 29.779434430379748, -1.490122586206897 ], [ 29.779703924050633, -1.490122586206897 ], [ 29.779703924050633, -1.489853103448276 ], [ 29.779434430379748, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33112, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.489853103448276 ], [ 29.779703924050633, -1.490122586206897 ], [ 29.779973417721518, -1.490122586206897 ], [ 29.779973417721518, -1.489853103448276 ], [ 29.779703924050633, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33113, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.489853103448276 ], [ 29.779973417721518, -1.490122586206897 ], [ 29.780242911392406, -1.490122586206897 ], [ 29.780242911392406, -1.489853103448276 ], [ 29.779973417721518, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33114, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.489853103448276 ], [ 29.780242911392406, -1.490122586206897 ], [ 29.780512405063291, -1.490122586206897 ], [ 29.780512405063291, -1.489853103448276 ], [ 29.780242911392406, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33115, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.489853103448276 ], [ 29.780512405063291, -1.490122586206897 ], [ 29.780781898734176, -1.490122586206897 ], [ 29.780781898734176, -1.489853103448276 ], [ 29.780512405063291, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33116, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.489853103448276 ], [ 29.780781898734176, -1.490122586206897 ], [ 29.781051392405065, -1.490122586206897 ], [ 29.781051392405065, -1.489853103448276 ], [ 29.780781898734176, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33117, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.489853103448276 ], [ 29.781051392405065, -1.490122586206897 ], [ 29.78132088607595, -1.490122586206897 ], [ 29.78132088607595, -1.489853103448276 ], [ 29.781051392405065, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33118, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.489853103448276 ], [ 29.78132088607595, -1.490122586206897 ], [ 29.781590379746834, -1.490122586206897 ], [ 29.781590379746834, -1.489853103448276 ], [ 29.78132088607595, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33119, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.489853103448276 ], [ 29.781590379746834, -1.490122586206897 ], [ 29.781859873417723, -1.490122586206897 ], [ 29.781859873417723, -1.489853103448276 ], [ 29.781590379746834, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33120, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.489853103448276 ], [ 29.781859873417723, -1.490122586206897 ], [ 29.782129367088608, -1.490122586206897 ], [ 29.782129367088608, -1.489853103448276 ], [ 29.781859873417723, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33121, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.489853103448276 ], [ 29.782129367088608, -1.490122586206897 ], [ 29.782398860759493, -1.490122586206897 ], [ 29.782398860759493, -1.489853103448276 ], [ 29.782129367088608, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33122, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.489853103448276 ], [ 29.782398860759493, -1.490122586206897 ], [ 29.782668354430381, -1.490122586206897 ], [ 29.782668354430381, -1.489853103448276 ], [ 29.782398860759493, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33123, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.489853103448276 ], [ 29.782668354430381, -1.490122586206897 ], [ 29.782937848101266, -1.490122586206897 ], [ 29.782937848101266, -1.489853103448276 ], [ 29.782668354430381, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33124, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.489853103448276 ], [ 29.782937848101266, -1.490122586206897 ], [ 29.783207341772151, -1.490122586206897 ], [ 29.783207341772151, -1.489853103448276 ], [ 29.782937848101266, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33125, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.489853103448276 ], [ 29.783207341772151, -1.490122586206897 ], [ 29.783476835443039, -1.490122586206897 ], [ 29.783476835443039, -1.489853103448276 ], [ 29.783207341772151, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33126, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.489853103448276 ], [ 29.783476835443039, -1.490122586206897 ], [ 29.786710759493673, -1.490122586206897 ], [ 29.786710759493673, -1.489853103448276 ], [ 29.783476835443039, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33127, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.489853103448276 ], [ 29.786710759493673, -1.490122586206897 ], [ 29.786980253164558, -1.490122586206897 ], [ 29.786980253164558, -1.489853103448276 ], [ 29.786710759493673, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33128, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.489853103448276 ], [ 29.786980253164558, -1.490122586206897 ], [ 29.787249746835442, -1.490122586206897 ], [ 29.787249746835442, -1.489853103448276 ], [ 29.786980253164558, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33129, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.489853103448276 ], [ 29.787249746835442, -1.490122586206897 ], [ 29.787519240506327, -1.490122586206897 ], [ 29.787519240506327, -1.489853103448276 ], [ 29.787249746835442, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33130, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.489853103448276 ], [ 29.787519240506327, -1.490122586206897 ], [ 29.787788734177216, -1.490122586206897 ], [ 29.787788734177216, -1.489853103448276 ], [ 29.787519240506327, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33131, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.489853103448276 ], [ 29.787788734177216, -1.490122586206897 ], [ 29.788058227848101, -1.490122586206897 ], [ 29.788058227848101, -1.489853103448276 ], [ 29.787788734177216, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33132, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.489853103448276 ], [ 29.788058227848101, -1.490122586206897 ], [ 29.788597215189874, -1.490122586206897 ], [ 29.788597215189874, -1.489853103448276 ], [ 29.788058227848101, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33133, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788597215189874, -1.489853103448276 ], [ 29.788597215189874, -1.490122586206897 ], [ 29.788866708860759, -1.490122586206897 ], [ 29.788866708860759, -1.489853103448276 ], [ 29.788597215189874, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33134, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.489853103448276 ], [ 29.80261088607595, -1.490122586206897 ], [ 29.802880379746835, -1.490122586206897 ], [ 29.802880379746835, -1.489853103448276 ], [ 29.80261088607595, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33135, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.489853103448276 ], [ 29.802880379746835, -1.490122586206897 ], [ 29.803149873417723, -1.490122586206897 ], [ 29.803149873417723, -1.489853103448276 ], [ 29.802880379746835, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33136, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.489853103448276 ], [ 29.803149873417723, -1.490122586206897 ], [ 29.803419367088608, -1.490122586206897 ], [ 29.803419367088608, -1.489853103448276 ], [ 29.803149873417723, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33137, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.489853103448276 ], [ 29.803419367088608, -1.490122586206897 ], [ 29.803688860759493, -1.490122586206897 ], [ 29.803688860759493, -1.489853103448276 ], [ 29.803419367088608, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33138, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.489853103448276 ], [ 29.803688860759493, -1.490122586206897 ], [ 29.803958354430382, -1.490122586206897 ], [ 29.803958354430382, -1.489853103448276 ], [ 29.803688860759493, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33139, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.489853103448276 ], [ 29.803958354430382, -1.490122586206897 ], [ 29.804227848101267, -1.490122586206897 ], [ 29.804227848101267, -1.489853103448276 ], [ 29.803958354430382, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33140, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.489853103448276 ], [ 29.804227848101267, -1.490122586206897 ], [ 29.804497341772151, -1.490122586206897 ], [ 29.804497341772151, -1.489853103448276 ], [ 29.804227848101267, -1.489853103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33141, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.490122586206897 ], [ 29.768654683544302, -1.490392068965517 ], [ 29.76892417721519, -1.490392068965517 ], [ 29.76892417721519, -1.490122586206897 ], [ 29.768654683544302, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33142, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.490122586206897 ], [ 29.76892417721519, -1.490392068965517 ], [ 29.769193670886075, -1.490392068965517 ], [ 29.769193670886075, -1.490122586206897 ], [ 29.76892417721519, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33143, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.490122586206897 ], [ 29.769193670886075, -1.490392068965517 ], [ 29.76946316455696, -1.490392068965517 ], [ 29.76946316455696, -1.490122586206897 ], [ 29.769193670886075, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33144, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.490122586206897 ], [ 29.76946316455696, -1.490392068965517 ], [ 29.769732658227849, -1.490392068965517 ], [ 29.769732658227849, -1.490122586206897 ], [ 29.76946316455696, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33145, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.490122586206897 ], [ 29.769732658227849, -1.490392068965517 ], [ 29.770002151898733, -1.490392068965517 ], [ 29.770002151898733, -1.490122586206897 ], [ 29.769732658227849, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33146, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.490122586206897 ], [ 29.770002151898733, -1.490392068965517 ], [ 29.770271645569618, -1.490392068965517 ], [ 29.770271645569618, -1.490122586206897 ], [ 29.770002151898733, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33147, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.490122586206897 ], [ 29.770271645569618, -1.490392068965517 ], [ 29.770541139240507, -1.490392068965517 ], [ 29.770541139240507, -1.490122586206897 ], [ 29.770271645569618, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33148, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.490122586206897 ], [ 29.770541139240507, -1.490392068965517 ], [ 29.770810632911392, -1.490392068965517 ], [ 29.770810632911392, -1.490122586206897 ], [ 29.770541139240507, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33149, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.490122586206897 ], [ 29.770810632911392, -1.490392068965517 ], [ 29.771080126582277, -1.490392068965517 ], [ 29.771080126582277, -1.490122586206897 ], [ 29.770810632911392, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33150, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.490122586206897 ], [ 29.771080126582277, -1.490392068965517 ], [ 29.771349620253165, -1.490392068965517 ], [ 29.771349620253165, -1.490122586206897 ], [ 29.771080126582277, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33151, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.490122586206897 ], [ 29.771349620253165, -1.490392068965517 ], [ 29.77161911392405, -1.490392068965517 ], [ 29.77161911392405, -1.490122586206897 ], [ 29.771349620253165, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33152, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.490122586206897 ], [ 29.77161911392405, -1.490392068965517 ], [ 29.771888607594935, -1.490392068965517 ], [ 29.771888607594935, -1.490122586206897 ], [ 29.77161911392405, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33153, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.490122586206897 ], [ 29.771888607594935, -1.490392068965517 ], [ 29.772158101265823, -1.490392068965517 ], [ 29.772158101265823, -1.490122586206897 ], [ 29.771888607594935, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33154, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.490122586206897 ], [ 29.772158101265823, -1.490392068965517 ], [ 29.772427594936708, -1.490392068965517 ], [ 29.772427594936708, -1.490122586206897 ], [ 29.772158101265823, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33155, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.490122586206897 ], [ 29.772427594936708, -1.490392068965517 ], [ 29.772697088607593, -1.490392068965517 ], [ 29.772697088607593, -1.490122586206897 ], [ 29.772427594936708, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33156, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.490122586206897 ], [ 29.772697088607593, -1.490392068965517 ], [ 29.772966582278482, -1.490392068965517 ], [ 29.772966582278482, -1.490122586206897 ], [ 29.772697088607593, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33157, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.490122586206897 ], [ 29.772966582278482, -1.490392068965517 ], [ 29.773236075949367, -1.490392068965517 ], [ 29.773236075949367, -1.490122586206897 ], [ 29.772966582278482, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33158, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.490122586206897 ], [ 29.773236075949367, -1.490392068965517 ], [ 29.773505569620252, -1.490392068965517 ], [ 29.773505569620252, -1.490122586206897 ], [ 29.773236075949367, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33159, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.490122586206897 ], [ 29.773505569620252, -1.490392068965517 ], [ 29.77377506329114, -1.490392068965517 ], [ 29.77377506329114, -1.490122586206897 ], [ 29.773505569620252, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33160, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.490122586206897 ], [ 29.77377506329114, -1.490392068965517 ], [ 29.774044556962025, -1.490392068965517 ], [ 29.774044556962025, -1.490122586206897 ], [ 29.77377506329114, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33161, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.490122586206897 ], [ 29.774044556962025, -1.490392068965517 ], [ 29.77431405063291, -1.490392068965517 ], [ 29.77431405063291, -1.490122586206897 ], [ 29.774044556962025, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33162, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.490122586206897 ], [ 29.77431405063291, -1.490392068965517 ], [ 29.774853037974683, -1.490392068965517 ], [ 29.774853037974683, -1.490122586206897 ], [ 29.77431405063291, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33163, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.490122586206897 ], [ 29.774853037974683, -1.490392068965517 ], [ 29.775122531645568, -1.490392068965517 ], [ 29.775122531645568, -1.490122586206897 ], [ 29.774853037974683, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33164, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.490122586206897 ], [ 29.775122531645568, -1.490392068965517 ], [ 29.775392025316457, -1.490392068965517 ], [ 29.775392025316457, -1.490122586206897 ], [ 29.775122531645568, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33165, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.490122586206897 ], [ 29.775392025316457, -1.490392068965517 ], [ 29.775661518987341, -1.490392068965517 ], [ 29.775661518987341, -1.490122586206897 ], [ 29.775392025316457, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33166, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.490122586206897 ], [ 29.775661518987341, -1.490392068965517 ], [ 29.775931012658226, -1.490392068965517 ], [ 29.775931012658226, -1.490122586206897 ], [ 29.775661518987341, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33167, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.490122586206897 ], [ 29.775931012658226, -1.490392068965517 ], [ 29.776200506329115, -1.490392068965517 ], [ 29.776200506329115, -1.490122586206897 ], [ 29.775931012658226, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33168, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.490122586206897 ], [ 29.776200506329115, -1.490392068965517 ], [ 29.77647, -1.490392068965517 ], [ 29.77647, -1.490122586206897 ], [ 29.776200506329115, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33169, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.490122586206897 ], [ 29.77647, -1.490392068965517 ], [ 29.776739493670885, -1.490392068965517 ], [ 29.776739493670885, -1.490122586206897 ], [ 29.77647, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33170, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.490122586206897 ], [ 29.776739493670885, -1.490392068965517 ], [ 29.777008987341773, -1.490392068965517 ], [ 29.777008987341773, -1.490122586206897 ], [ 29.776739493670885, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33171, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.490122586206897 ], [ 29.777008987341773, -1.490392068965517 ], [ 29.777278481012658, -1.490392068965517 ], [ 29.777278481012658, -1.490122586206897 ], [ 29.777008987341773, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33172, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.490122586206897 ], [ 29.777278481012658, -1.490392068965517 ], [ 29.777547974683543, -1.490392068965517 ], [ 29.777547974683543, -1.490122586206897 ], [ 29.777278481012658, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33173, "El": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.490122586206897 ], [ 29.777547974683543, -1.490392068965517 ], [ 29.778086962025316, -1.490392068965517 ], [ 29.778086962025316, -1.490122586206897 ], [ 29.777547974683543, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33174, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.490122586206897 ], [ 29.778086962025316, -1.490392068965517 ], [ 29.778356455696201, -1.490392068965517 ], [ 29.778356455696201, -1.490122586206897 ], [ 29.778086962025316, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33175, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.490122586206897 ], [ 29.778356455696201, -1.490392068965517 ], [ 29.77862594936709, -1.490392068965517 ], [ 29.77862594936709, -1.490122586206897 ], [ 29.778356455696201, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33176, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.490122586206897 ], [ 29.77862594936709, -1.490392068965517 ], [ 29.778895443037975, -1.490392068965517 ], [ 29.778895443037975, -1.490122586206897 ], [ 29.77862594936709, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33177, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.490122586206897 ], [ 29.778895443037975, -1.490392068965517 ], [ 29.77916493670886, -1.490392068965517 ], [ 29.77916493670886, -1.490122586206897 ], [ 29.778895443037975, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33178, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.490122586206897 ], [ 29.77916493670886, -1.490392068965517 ], [ 29.779434430379748, -1.490392068965517 ], [ 29.779434430379748, -1.490122586206897 ], [ 29.77916493670886, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33179, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.490122586206897 ], [ 29.779434430379748, -1.490392068965517 ], [ 29.779703924050633, -1.490392068965517 ], [ 29.779703924050633, -1.490122586206897 ], [ 29.779434430379748, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33180, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.490122586206897 ], [ 29.779703924050633, -1.490392068965517 ], [ 29.779973417721518, -1.490392068965517 ], [ 29.779973417721518, -1.490122586206897 ], [ 29.779703924050633, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33181, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.490122586206897 ], [ 29.779973417721518, -1.490392068965517 ], [ 29.780242911392406, -1.490392068965517 ], [ 29.780242911392406, -1.490122586206897 ], [ 29.779973417721518, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33182, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.490122586206897 ], [ 29.780242911392406, -1.490392068965517 ], [ 29.780512405063291, -1.490392068965517 ], [ 29.780512405063291, -1.490122586206897 ], [ 29.780242911392406, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33183, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.490122586206897 ], [ 29.780512405063291, -1.490392068965517 ], [ 29.780781898734176, -1.490392068965517 ], [ 29.780781898734176, -1.490122586206897 ], [ 29.780512405063291, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33184, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.490122586206897 ], [ 29.780781898734176, -1.490392068965517 ], [ 29.781051392405065, -1.490392068965517 ], [ 29.781051392405065, -1.490122586206897 ], [ 29.780781898734176, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33185, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.490122586206897 ], [ 29.781051392405065, -1.490392068965517 ], [ 29.78132088607595, -1.490392068965517 ], [ 29.78132088607595, -1.490122586206897 ], [ 29.781051392405065, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33186, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.490122586206897 ], [ 29.78132088607595, -1.490392068965517 ], [ 29.781590379746834, -1.490392068965517 ], [ 29.781590379746834, -1.490122586206897 ], [ 29.78132088607595, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33187, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.490122586206897 ], [ 29.781590379746834, -1.490392068965517 ], [ 29.781859873417723, -1.490392068965517 ], [ 29.781859873417723, -1.490122586206897 ], [ 29.781590379746834, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33188, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.490122586206897 ], [ 29.781859873417723, -1.490392068965517 ], [ 29.782129367088608, -1.490392068965517 ], [ 29.782129367088608, -1.490122586206897 ], [ 29.781859873417723, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33189, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.490122586206897 ], [ 29.782129367088608, -1.490392068965517 ], [ 29.782398860759493, -1.490392068965517 ], [ 29.782398860759493, -1.490122586206897 ], [ 29.782129367088608, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33190, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.490122586206897 ], [ 29.782398860759493, -1.490392068965517 ], [ 29.782668354430381, -1.490392068965517 ], [ 29.782668354430381, -1.490122586206897 ], [ 29.782398860759493, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33191, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.490122586206897 ], [ 29.782668354430381, -1.490392068965517 ], [ 29.782937848101266, -1.490392068965517 ], [ 29.782937848101266, -1.490122586206897 ], [ 29.782668354430381, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33192, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.490122586206897 ], [ 29.782937848101266, -1.490392068965517 ], [ 29.783207341772151, -1.490392068965517 ], [ 29.783207341772151, -1.490122586206897 ], [ 29.782937848101266, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33193, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.490122586206897 ], [ 29.783207341772151, -1.490392068965517 ], [ 29.784015822784809, -1.490392068965517 ], [ 29.784015822784809, -1.490122586206897 ], [ 29.783207341772151, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33194, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.490122586206897 ], [ 29.784015822784809, -1.490392068965517 ], [ 29.786710759493673, -1.490392068965517 ], [ 29.786710759493673, -1.490122586206897 ], [ 29.784015822784809, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33195, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786710759493673, -1.490122586206897 ], [ 29.786710759493673, -1.490392068965517 ], [ 29.786980253164558, -1.490392068965517 ], [ 29.786980253164558, -1.490122586206897 ], [ 29.786710759493673, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33196, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.490122586206897 ], [ 29.786980253164558, -1.490392068965517 ], [ 29.787249746835442, -1.490392068965517 ], [ 29.787249746835442, -1.490122586206897 ], [ 29.786980253164558, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33197, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.490122586206897 ], [ 29.787249746835442, -1.490392068965517 ], [ 29.787519240506327, -1.490392068965517 ], [ 29.787519240506327, -1.490122586206897 ], [ 29.787249746835442, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33198, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.490122586206897 ], [ 29.787519240506327, -1.490392068965517 ], [ 29.787788734177216, -1.490392068965517 ], [ 29.787788734177216, -1.490122586206897 ], [ 29.787519240506327, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33199, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.490122586206897 ], [ 29.787788734177216, -1.490392068965517 ], [ 29.788058227848101, -1.490392068965517 ], [ 29.788058227848101, -1.490122586206897 ], [ 29.787788734177216, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33200, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.490122586206897 ], [ 29.788058227848101, -1.490392068965517 ], [ 29.788327721518986, -1.490392068965517 ], [ 29.788327721518986, -1.490122586206897 ], [ 29.788058227848101, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33201, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.490122586206897 ], [ 29.788327721518986, -1.490392068965517 ], [ 29.788866708860759, -1.490392068965517 ], [ 29.788866708860759, -1.490122586206897 ], [ 29.788327721518986, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33202, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.490122586206897 ], [ 29.788866708860759, -1.490392068965517 ], [ 29.789136202531644, -1.490392068965517 ], [ 29.789136202531644, -1.490122586206897 ], [ 29.788866708860759, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33203, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.490122586206897 ], [ 29.80261088607595, -1.490392068965517 ], [ 29.802880379746835, -1.490392068965517 ], [ 29.802880379746835, -1.490122586206897 ], [ 29.80261088607595, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33204, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.490122586206897 ], [ 29.802880379746835, -1.490392068965517 ], [ 29.803149873417723, -1.490392068965517 ], [ 29.803149873417723, -1.490122586206897 ], [ 29.802880379746835, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33205, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.490122586206897 ], [ 29.803149873417723, -1.490392068965517 ], [ 29.803419367088608, -1.490392068965517 ], [ 29.803419367088608, -1.490122586206897 ], [ 29.803149873417723, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33206, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.490122586206897 ], [ 29.803419367088608, -1.490392068965517 ], [ 29.803688860759493, -1.490392068965517 ], [ 29.803688860759493, -1.490122586206897 ], [ 29.803419367088608, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33207, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.490122586206897 ], [ 29.803688860759493, -1.490392068965517 ], [ 29.803958354430382, -1.490392068965517 ], [ 29.803958354430382, -1.490122586206897 ], [ 29.803688860759493, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33208, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.490122586206897 ], [ 29.803958354430382, -1.490392068965517 ], [ 29.804227848101267, -1.490392068965517 ], [ 29.804227848101267, -1.490122586206897 ], [ 29.803958354430382, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33209, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.804227848101267, -1.490122586206897 ], [ 29.804227848101267, -1.490661551724138 ], [ 29.804497341772151, -1.490661551724138 ], [ 29.804497341772151, -1.490122586206897 ], [ 29.804227848101267, -1.490122586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33210, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.768654683544302, -1.490392068965517 ], [ 29.768654683544302, -1.490931034482759 ], [ 29.76892417721519, -1.490931034482759 ], [ 29.76892417721519, -1.490392068965517 ], [ 29.768654683544302, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33211, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.490392068965517 ], [ 29.76892417721519, -1.490661551724138 ], [ 29.769193670886075, -1.490661551724138 ], [ 29.769193670886075, -1.490392068965517 ], [ 29.76892417721519, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33212, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.490392068965517 ], [ 29.769193670886075, -1.490661551724138 ], [ 29.76946316455696, -1.490661551724138 ], [ 29.76946316455696, -1.490392068965517 ], [ 29.769193670886075, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33213, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.490392068965517 ], [ 29.76946316455696, -1.490661551724138 ], [ 29.769732658227849, -1.490661551724138 ], [ 29.769732658227849, -1.490392068965517 ], [ 29.76946316455696, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33214, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.490392068965517 ], [ 29.769732658227849, -1.490661551724138 ], [ 29.770002151898733, -1.490661551724138 ], [ 29.770002151898733, -1.490392068965517 ], [ 29.769732658227849, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33215, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.490392068965517 ], [ 29.770002151898733, -1.490661551724138 ], [ 29.770271645569618, -1.490661551724138 ], [ 29.770271645569618, -1.490392068965517 ], [ 29.770002151898733, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33216, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.490392068965517 ], [ 29.770271645569618, -1.490661551724138 ], [ 29.770541139240507, -1.490661551724138 ], [ 29.770541139240507, -1.490392068965517 ], [ 29.770271645569618, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33217, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.490392068965517 ], [ 29.770541139240507, -1.490661551724138 ], [ 29.770810632911392, -1.490661551724138 ], [ 29.770810632911392, -1.490392068965517 ], [ 29.770541139240507, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33218, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.490392068965517 ], [ 29.770810632911392, -1.490661551724138 ], [ 29.771080126582277, -1.490661551724138 ], [ 29.771080126582277, -1.490392068965517 ], [ 29.770810632911392, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33219, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.490392068965517 ], [ 29.771080126582277, -1.490661551724138 ], [ 29.77161911392405, -1.490661551724138 ], [ 29.77161911392405, -1.490392068965517 ], [ 29.771080126582277, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33220, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.490392068965517 ], [ 29.77161911392405, -1.490661551724138 ], [ 29.771888607594935, -1.490661551724138 ], [ 29.771888607594935, -1.490392068965517 ], [ 29.77161911392405, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33221, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.490392068965517 ], [ 29.771888607594935, -1.490661551724138 ], [ 29.772158101265823, -1.490661551724138 ], [ 29.772158101265823, -1.490392068965517 ], [ 29.771888607594935, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33222, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.490392068965517 ], [ 29.772158101265823, -1.490661551724138 ], [ 29.772427594936708, -1.490661551724138 ], [ 29.772427594936708, -1.490392068965517 ], [ 29.772158101265823, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33223, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.490392068965517 ], [ 29.772427594936708, -1.490661551724138 ], [ 29.772697088607593, -1.490661551724138 ], [ 29.772697088607593, -1.490392068965517 ], [ 29.772427594936708, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33224, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.490392068965517 ], [ 29.772697088607593, -1.490661551724138 ], [ 29.772966582278482, -1.490661551724138 ], [ 29.772966582278482, -1.490392068965517 ], [ 29.772697088607593, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33225, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.490392068965517 ], [ 29.772966582278482, -1.490661551724138 ], [ 29.773236075949367, -1.490661551724138 ], [ 29.773236075949367, -1.490392068965517 ], [ 29.772966582278482, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33226, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.490392068965517 ], [ 29.773236075949367, -1.490661551724138 ], [ 29.773505569620252, -1.490661551724138 ], [ 29.773505569620252, -1.490392068965517 ], [ 29.773236075949367, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33227, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.490392068965517 ], [ 29.773505569620252, -1.490661551724138 ], [ 29.77377506329114, -1.490661551724138 ], [ 29.77377506329114, -1.490392068965517 ], [ 29.773505569620252, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33228, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.490392068965517 ], [ 29.77377506329114, -1.490661551724138 ], [ 29.774044556962025, -1.490661551724138 ], [ 29.774044556962025, -1.490392068965517 ], [ 29.77377506329114, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33229, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.490392068965517 ], [ 29.774044556962025, -1.490661551724138 ], [ 29.77431405063291, -1.490661551724138 ], [ 29.77431405063291, -1.490392068965517 ], [ 29.774044556962025, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33230, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.490392068965517 ], [ 29.77431405063291, -1.490661551724138 ], [ 29.774853037974683, -1.490661551724138 ], [ 29.774853037974683, -1.490392068965517 ], [ 29.77431405063291, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33231, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.490392068965517 ], [ 29.774853037974683, -1.490661551724138 ], [ 29.775122531645568, -1.490661551724138 ], [ 29.775122531645568, -1.490392068965517 ], [ 29.774853037974683, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33232, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.490392068965517 ], [ 29.775122531645568, -1.490661551724138 ], [ 29.775392025316457, -1.490661551724138 ], [ 29.775392025316457, -1.490392068965517 ], [ 29.775122531645568, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33233, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.490392068965517 ], [ 29.775392025316457, -1.490661551724138 ], [ 29.775661518987341, -1.490661551724138 ], [ 29.775661518987341, -1.490392068965517 ], [ 29.775392025316457, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33234, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.490392068965517 ], [ 29.775661518987341, -1.490661551724138 ], [ 29.775931012658226, -1.490661551724138 ], [ 29.775931012658226, -1.490392068965517 ], [ 29.775661518987341, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33235, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.490392068965517 ], [ 29.775931012658226, -1.490661551724138 ], [ 29.776200506329115, -1.490661551724138 ], [ 29.776200506329115, -1.490392068965517 ], [ 29.775931012658226, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33236, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.490392068965517 ], [ 29.776200506329115, -1.490661551724138 ], [ 29.77647, -1.490661551724138 ], [ 29.77647, -1.490392068965517 ], [ 29.776200506329115, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33237, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.490392068965517 ], [ 29.77647, -1.490661551724138 ], [ 29.776739493670885, -1.490661551724138 ], [ 29.776739493670885, -1.490392068965517 ], [ 29.77647, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33238, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.490392068965517 ], [ 29.776739493670885, -1.490661551724138 ], [ 29.777008987341773, -1.490661551724138 ], [ 29.777008987341773, -1.490392068965517 ], [ 29.776739493670885, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33239, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.490392068965517 ], [ 29.777008987341773, -1.490661551724138 ], [ 29.777278481012658, -1.490661551724138 ], [ 29.777278481012658, -1.490392068965517 ], [ 29.777008987341773, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33240, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.490392068965517 ], [ 29.777278481012658, -1.490661551724138 ], [ 29.777547974683543, -1.490661551724138 ], [ 29.777547974683543, -1.490392068965517 ], [ 29.777278481012658, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33241, "El": 73 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.490392068965517 ], [ 29.777547974683543, -1.490661551724138 ], [ 29.778086962025316, -1.490661551724138 ], [ 29.778086962025316, -1.490392068965517 ], [ 29.777547974683543, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33242, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.490392068965517 ], [ 29.778086962025316, -1.490661551724138 ], [ 29.778356455696201, -1.490661551724138 ], [ 29.778356455696201, -1.490392068965517 ], [ 29.778086962025316, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33243, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.490392068965517 ], [ 29.778356455696201, -1.490661551724138 ], [ 29.77862594936709, -1.490661551724138 ], [ 29.77862594936709, -1.490392068965517 ], [ 29.778356455696201, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33244, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.490392068965517 ], [ 29.77862594936709, -1.490661551724138 ], [ 29.778895443037975, -1.490661551724138 ], [ 29.778895443037975, -1.490392068965517 ], [ 29.77862594936709, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33245, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.490392068965517 ], [ 29.778895443037975, -1.490661551724138 ], [ 29.77916493670886, -1.490661551724138 ], [ 29.77916493670886, -1.490392068965517 ], [ 29.778895443037975, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33246, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.490392068965517 ], [ 29.77916493670886, -1.490661551724138 ], [ 29.779434430379748, -1.490661551724138 ], [ 29.779434430379748, -1.490392068965517 ], [ 29.77916493670886, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33247, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.490392068965517 ], [ 29.779434430379748, -1.490661551724138 ], [ 29.779703924050633, -1.490661551724138 ], [ 29.779703924050633, -1.490392068965517 ], [ 29.779434430379748, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33248, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.490392068965517 ], [ 29.779703924050633, -1.490661551724138 ], [ 29.779973417721518, -1.490661551724138 ], [ 29.779973417721518, -1.490392068965517 ], [ 29.779703924050633, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33249, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.490392068965517 ], [ 29.779973417721518, -1.490661551724138 ], [ 29.780242911392406, -1.490661551724138 ], [ 29.780242911392406, -1.490392068965517 ], [ 29.779973417721518, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33250, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.490392068965517 ], [ 29.780242911392406, -1.490661551724138 ], [ 29.780512405063291, -1.490661551724138 ], [ 29.780512405063291, -1.490392068965517 ], [ 29.780242911392406, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33251, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.490392068965517 ], [ 29.780512405063291, -1.490661551724138 ], [ 29.780781898734176, -1.490661551724138 ], [ 29.780781898734176, -1.490392068965517 ], [ 29.780512405063291, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33252, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.490392068965517 ], [ 29.780781898734176, -1.490661551724138 ], [ 29.781051392405065, -1.490661551724138 ], [ 29.781051392405065, -1.490392068965517 ], [ 29.780781898734176, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33253, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.490392068965517 ], [ 29.781051392405065, -1.490661551724138 ], [ 29.78132088607595, -1.490661551724138 ], [ 29.78132088607595, -1.490392068965517 ], [ 29.781051392405065, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33254, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.490392068965517 ], [ 29.78132088607595, -1.490661551724138 ], [ 29.781590379746834, -1.490661551724138 ], [ 29.781590379746834, -1.490392068965517 ], [ 29.78132088607595, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33255, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.490392068965517 ], [ 29.781590379746834, -1.490661551724138 ], [ 29.781859873417723, -1.490661551724138 ], [ 29.781859873417723, -1.490392068965517 ], [ 29.781590379746834, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33256, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.490392068965517 ], [ 29.781859873417723, -1.490661551724138 ], [ 29.782129367088608, -1.490661551724138 ], [ 29.782129367088608, -1.490392068965517 ], [ 29.781859873417723, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33257, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.490392068965517 ], [ 29.782129367088608, -1.490661551724138 ], [ 29.782398860759493, -1.490661551724138 ], [ 29.782398860759493, -1.490392068965517 ], [ 29.782129367088608, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33258, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.490392068965517 ], [ 29.782398860759493, -1.490661551724138 ], [ 29.782668354430381, -1.490661551724138 ], [ 29.782668354430381, -1.490392068965517 ], [ 29.782398860759493, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33259, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.490392068965517 ], [ 29.782668354430381, -1.490661551724138 ], [ 29.782937848101266, -1.490661551724138 ], [ 29.782937848101266, -1.490392068965517 ], [ 29.782668354430381, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33260, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.490392068965517 ], [ 29.782937848101266, -1.490661551724138 ], [ 29.783207341772151, -1.490661551724138 ], [ 29.783207341772151, -1.490392068965517 ], [ 29.782937848101266, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33261, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.490392068965517 ], [ 29.783207341772151, -1.490661551724138 ], [ 29.783476835443039, -1.490661551724138 ], [ 29.783476835443039, -1.490392068965517 ], [ 29.783207341772151, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33262, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.490392068965517 ], [ 29.783476835443039, -1.490661551724138 ], [ 29.786980253164558, -1.490661551724138 ], [ 29.786980253164558, -1.490392068965517 ], [ 29.783476835443039, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33263, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.490392068965517 ], [ 29.786980253164558, -1.490661551724138 ], [ 29.787249746835442, -1.490661551724138 ], [ 29.787249746835442, -1.490392068965517 ], [ 29.786980253164558, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33264, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.490392068965517 ], [ 29.787249746835442, -1.490661551724138 ], [ 29.787519240506327, -1.490661551724138 ], [ 29.787519240506327, -1.490392068965517 ], [ 29.787249746835442, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33265, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.490392068965517 ], [ 29.787519240506327, -1.490661551724138 ], [ 29.787788734177216, -1.490661551724138 ], [ 29.787788734177216, -1.490392068965517 ], [ 29.787519240506327, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33266, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.490392068965517 ], [ 29.787788734177216, -1.490661551724138 ], [ 29.788058227848101, -1.490661551724138 ], [ 29.788058227848101, -1.490392068965517 ], [ 29.787788734177216, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33267, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.490392068965517 ], [ 29.788058227848101, -1.490661551724138 ], [ 29.788327721518986, -1.490661551724138 ], [ 29.788327721518986, -1.490392068965517 ], [ 29.788058227848101, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33268, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.490392068965517 ], [ 29.788327721518986, -1.490661551724138 ], [ 29.788866708860759, -1.490661551724138 ], [ 29.788866708860759, -1.490392068965517 ], [ 29.788327721518986, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33269, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788866708860759, -1.490392068965517 ], [ 29.788866708860759, -1.490661551724138 ], [ 29.789136202531644, -1.490661551724138 ], [ 29.789136202531644, -1.490392068965517 ], [ 29.788866708860759, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33270, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.789405696202532, -1.486349827586207 ], [ 29.789675189873417, -1.486349827586207 ], [ 29.789675189873417, -1.490661551724138 ], [ 29.789136202531644, -1.490661551724138 ], [ 29.789136202531644, -1.490392068965517 ], [ 29.789405696202532, -1.490392068965517 ], [ 29.789405696202532, -1.486349827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33271, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.490392068965517 ], [ 29.801532911392407, -1.490661551724138 ], [ 29.802071898734177, -1.490661551724138 ], [ 29.802071898734177, -1.490392068965517 ], [ 29.801532911392407, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33272, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.490392068965517 ], [ 29.802071898734177, -1.490661551724138 ], [ 29.802341392405065, -1.490661551724138 ], [ 29.802341392405065, -1.490392068965517 ], [ 29.802071898734177, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33273, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.490392068965517 ], [ 29.802341392405065, -1.490661551724138 ], [ 29.802880379746835, -1.490661551724138 ], [ 29.802880379746835, -1.490392068965517 ], [ 29.802341392405065, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33274, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.490392068965517 ], [ 29.802880379746835, -1.490661551724138 ], [ 29.803149873417723, -1.490661551724138 ], [ 29.803149873417723, -1.490392068965517 ], [ 29.802880379746835, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33275, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.490392068965517 ], [ 29.803149873417723, -1.490661551724138 ], [ 29.803419367088608, -1.490661551724138 ], [ 29.803419367088608, -1.490392068965517 ], [ 29.803149873417723, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33276, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.490392068965517 ], [ 29.803419367088608, -1.490661551724138 ], [ 29.803688860759493, -1.490661551724138 ], [ 29.803688860759493, -1.490392068965517 ], [ 29.803419367088608, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33277, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.490392068965517 ], [ 29.803688860759493, -1.490661551724138 ], [ 29.803958354430382, -1.490661551724138 ], [ 29.803958354430382, -1.490392068965517 ], [ 29.803688860759493, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33278, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.490392068965517 ], [ 29.803958354430382, -1.490661551724138 ], [ 29.804227848101267, -1.490661551724138 ], [ 29.804227848101267, -1.490392068965517 ], [ 29.803958354430382, -1.490392068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33279, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.490661551724138 ], [ 29.76892417721519, -1.490931034482759 ], [ 29.769193670886075, -1.490931034482759 ], [ 29.769193670886075, -1.490661551724138 ], [ 29.76892417721519, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33280, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.490661551724138 ], [ 29.769193670886075, -1.490931034482759 ], [ 29.76946316455696, -1.490931034482759 ], [ 29.76946316455696, -1.490661551724138 ], [ 29.769193670886075, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33281, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.490661551724138 ], [ 29.76946316455696, -1.490931034482759 ], [ 29.769732658227849, -1.490931034482759 ], [ 29.769732658227849, -1.490661551724138 ], [ 29.76946316455696, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33282, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.490661551724138 ], [ 29.769732658227849, -1.490931034482759 ], [ 29.770002151898733, -1.490931034482759 ], [ 29.770002151898733, -1.490661551724138 ], [ 29.769732658227849, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33283, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.490661551724138 ], [ 29.770002151898733, -1.490931034482759 ], [ 29.770271645569618, -1.490931034482759 ], [ 29.770271645569618, -1.490661551724138 ], [ 29.770002151898733, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33284, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.490661551724138 ], [ 29.770271645569618, -1.490931034482759 ], [ 29.770541139240507, -1.490931034482759 ], [ 29.770541139240507, -1.490661551724138 ], [ 29.770271645569618, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33285, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.490661551724138 ], [ 29.770541139240507, -1.490931034482759 ], [ 29.770810632911392, -1.490931034482759 ], [ 29.770810632911392, -1.490661551724138 ], [ 29.770541139240507, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33286, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.490661551724138 ], [ 29.770810632911392, -1.490931034482759 ], [ 29.771349620253165, -1.490931034482759 ], [ 29.771349620253165, -1.490661551724138 ], [ 29.770810632911392, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33287, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.490661551724138 ], [ 29.771349620253165, -1.490931034482759 ], [ 29.77161911392405, -1.490931034482759 ], [ 29.77161911392405, -1.490661551724138 ], [ 29.771349620253165, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33288, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.490661551724138 ], [ 29.77161911392405, -1.490931034482759 ], [ 29.771888607594935, -1.490931034482759 ], [ 29.771888607594935, -1.490661551724138 ], [ 29.77161911392405, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33289, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.490661551724138 ], [ 29.771888607594935, -1.490931034482759 ], [ 29.772158101265823, -1.490931034482759 ], [ 29.772158101265823, -1.490661551724138 ], [ 29.771888607594935, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33290, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.490661551724138 ], [ 29.772158101265823, -1.490931034482759 ], [ 29.772427594936708, -1.490931034482759 ], [ 29.772427594936708, -1.490661551724138 ], [ 29.772158101265823, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33291, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.490661551724138 ], [ 29.772427594936708, -1.490931034482759 ], [ 29.772697088607593, -1.490931034482759 ], [ 29.772697088607593, -1.490661551724138 ], [ 29.772427594936708, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33292, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.490661551724138 ], [ 29.772697088607593, -1.490931034482759 ], [ 29.772966582278482, -1.490931034482759 ], [ 29.772966582278482, -1.490661551724138 ], [ 29.772697088607593, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33293, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.490661551724138 ], [ 29.772966582278482, -1.490931034482759 ], [ 29.773236075949367, -1.490931034482759 ], [ 29.773236075949367, -1.490661551724138 ], [ 29.772966582278482, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33294, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.490661551724138 ], [ 29.773236075949367, -1.490931034482759 ], [ 29.773505569620252, -1.490931034482759 ], [ 29.773505569620252, -1.490661551724138 ], [ 29.773236075949367, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33295, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.490661551724138 ], [ 29.773505569620252, -1.490931034482759 ], [ 29.77377506329114, -1.490931034482759 ], [ 29.77377506329114, -1.490661551724138 ], [ 29.773505569620252, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33296, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.490661551724138 ], [ 29.77377506329114, -1.490931034482759 ], [ 29.774044556962025, -1.490931034482759 ], [ 29.774044556962025, -1.490661551724138 ], [ 29.77377506329114, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33297, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.490661551724138 ], [ 29.774044556962025, -1.490931034482759 ], [ 29.77431405063291, -1.490931034482759 ], [ 29.77431405063291, -1.490661551724138 ], [ 29.774044556962025, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33298, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.490661551724138 ], [ 29.77431405063291, -1.490931034482759 ], [ 29.774853037974683, -1.490931034482759 ], [ 29.774853037974683, -1.490661551724138 ], [ 29.77431405063291, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33299, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.490661551724138 ], [ 29.774853037974683, -1.490931034482759 ], [ 29.775122531645568, -1.490931034482759 ], [ 29.775122531645568, -1.490661551724138 ], [ 29.774853037974683, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33300, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.490661551724138 ], [ 29.775122531645568, -1.490931034482759 ], [ 29.775392025316457, -1.490931034482759 ], [ 29.775392025316457, -1.490661551724138 ], [ 29.775122531645568, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33301, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.490661551724138 ], [ 29.775392025316457, -1.490931034482759 ], [ 29.775661518987341, -1.490931034482759 ], [ 29.775661518987341, -1.490661551724138 ], [ 29.775392025316457, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33302, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.490661551724138 ], [ 29.775661518987341, -1.490931034482759 ], [ 29.775931012658226, -1.490931034482759 ], [ 29.775931012658226, -1.490661551724138 ], [ 29.775661518987341, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33303, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.490661551724138 ], [ 29.775931012658226, -1.490931034482759 ], [ 29.776200506329115, -1.490931034482759 ], [ 29.776200506329115, -1.490661551724138 ], [ 29.775931012658226, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33304, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.490661551724138 ], [ 29.776200506329115, -1.490931034482759 ], [ 29.77647, -1.490931034482759 ], [ 29.77647, -1.490661551724138 ], [ 29.776200506329115, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33305, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.490661551724138 ], [ 29.77647, -1.490931034482759 ], [ 29.776739493670885, -1.490931034482759 ], [ 29.776739493670885, -1.490661551724138 ], [ 29.77647, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33306, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.490661551724138 ], [ 29.776739493670885, -1.490931034482759 ], [ 29.777008987341773, -1.490931034482759 ], [ 29.777008987341773, -1.490661551724138 ], [ 29.776739493670885, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33307, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.490661551724138 ], [ 29.777008987341773, -1.490931034482759 ], [ 29.777278481012658, -1.490931034482759 ], [ 29.777278481012658, -1.490661551724138 ], [ 29.777008987341773, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33308, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.490661551724138 ], [ 29.777278481012658, -1.490931034482759 ], [ 29.777547974683543, -1.490931034482759 ], [ 29.777547974683543, -1.490661551724138 ], [ 29.777278481012658, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33309, "El": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.490661551724138 ], [ 29.777547974683543, -1.490931034482759 ], [ 29.777817468354431, -1.490931034482759 ], [ 29.777817468354431, -1.490661551724138 ], [ 29.777547974683543, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33310, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.490661551724138 ], [ 29.777817468354431, -1.490931034482759 ], [ 29.778086962025316, -1.490931034482759 ], [ 29.778086962025316, -1.490661551724138 ], [ 29.777817468354431, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33311, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.490661551724138 ], [ 29.778086962025316, -1.490931034482759 ], [ 29.778356455696201, -1.490931034482759 ], [ 29.778356455696201, -1.490661551724138 ], [ 29.778086962025316, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33312, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.490661551724138 ], [ 29.778356455696201, -1.490931034482759 ], [ 29.77862594936709, -1.490931034482759 ], [ 29.77862594936709, -1.490661551724138 ], [ 29.778356455696201, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33313, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.490661551724138 ], [ 29.77862594936709, -1.490931034482759 ], [ 29.778895443037975, -1.490931034482759 ], [ 29.778895443037975, -1.490661551724138 ], [ 29.77862594936709, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33314, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.490661551724138 ], [ 29.778895443037975, -1.490931034482759 ], [ 29.77916493670886, -1.490931034482759 ], [ 29.77916493670886, -1.490661551724138 ], [ 29.778895443037975, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33315, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.490661551724138 ], [ 29.77916493670886, -1.490931034482759 ], [ 29.779434430379748, -1.490931034482759 ], [ 29.779434430379748, -1.490661551724138 ], [ 29.77916493670886, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33316, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.490661551724138 ], [ 29.779434430379748, -1.490931034482759 ], [ 29.779703924050633, -1.490931034482759 ], [ 29.779703924050633, -1.490661551724138 ], [ 29.779434430379748, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33317, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.490661551724138 ], [ 29.779703924050633, -1.490931034482759 ], [ 29.779973417721518, -1.490931034482759 ], [ 29.779973417721518, -1.490661551724138 ], [ 29.779703924050633, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33318, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.490661551724138 ], [ 29.779973417721518, -1.490931034482759 ], [ 29.780242911392406, -1.490931034482759 ], [ 29.780242911392406, -1.490661551724138 ], [ 29.779973417721518, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33319, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.490661551724138 ], [ 29.780242911392406, -1.490931034482759 ], [ 29.780512405063291, -1.490931034482759 ], [ 29.780512405063291, -1.490661551724138 ], [ 29.780242911392406, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33320, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.490661551724138 ], [ 29.780512405063291, -1.490931034482759 ], [ 29.780781898734176, -1.490931034482759 ], [ 29.780781898734176, -1.490661551724138 ], [ 29.780512405063291, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33321, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.490661551724138 ], [ 29.780781898734176, -1.490931034482759 ], [ 29.781051392405065, -1.490931034482759 ], [ 29.781051392405065, -1.490661551724138 ], [ 29.780781898734176, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33322, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.490661551724138 ], [ 29.781051392405065, -1.490931034482759 ], [ 29.78132088607595, -1.490931034482759 ], [ 29.78132088607595, -1.490661551724138 ], [ 29.781051392405065, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33323, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.490661551724138 ], [ 29.78132088607595, -1.490931034482759 ], [ 29.781590379746834, -1.490931034482759 ], [ 29.781590379746834, -1.490661551724138 ], [ 29.78132088607595, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33324, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.490661551724138 ], [ 29.781590379746834, -1.490931034482759 ], [ 29.781859873417723, -1.490931034482759 ], [ 29.781859873417723, -1.490661551724138 ], [ 29.781590379746834, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33325, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.490661551724138 ], [ 29.781859873417723, -1.490931034482759 ], [ 29.782129367088608, -1.490931034482759 ], [ 29.782129367088608, -1.490661551724138 ], [ 29.781859873417723, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33326, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.490661551724138 ], [ 29.782129367088608, -1.490931034482759 ], [ 29.782398860759493, -1.490931034482759 ], [ 29.782398860759493, -1.490661551724138 ], [ 29.782129367088608, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33327, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.490661551724138 ], [ 29.782398860759493, -1.490931034482759 ], [ 29.782668354430381, -1.490931034482759 ], [ 29.782668354430381, -1.490661551724138 ], [ 29.782398860759493, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33328, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.490661551724138 ], [ 29.782668354430381, -1.490931034482759 ], [ 29.782937848101266, -1.490931034482759 ], [ 29.782937848101266, -1.490661551724138 ], [ 29.782668354430381, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33329, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.490661551724138 ], [ 29.782937848101266, -1.490931034482759 ], [ 29.783207341772151, -1.490931034482759 ], [ 29.783207341772151, -1.490661551724138 ], [ 29.782937848101266, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33330, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.490661551724138 ], [ 29.783207341772151, -1.490931034482759 ], [ 29.783476835443039, -1.490931034482759 ], [ 29.783476835443039, -1.490661551724138 ], [ 29.783207341772151, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33331, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.490661551724138 ], [ 29.783476835443039, -1.490931034482759 ], [ 29.786980253164558, -1.490931034482759 ], [ 29.786980253164558, -1.490661551724138 ], [ 29.783476835443039, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33332, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.786980253164558, -1.490661551724138 ], [ 29.786980253164558, -1.490931034482759 ], [ 29.787249746835442, -1.490931034482759 ], [ 29.787249746835442, -1.490661551724138 ], [ 29.786980253164558, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33333, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.490661551724138 ], [ 29.787249746835442, -1.490931034482759 ], [ 29.787519240506327, -1.490931034482759 ], [ 29.787519240506327, -1.490661551724138 ], [ 29.787249746835442, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33334, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.490661551724138 ], [ 29.787519240506327, -1.490931034482759 ], [ 29.787788734177216, -1.490931034482759 ], [ 29.787788734177216, -1.490661551724138 ], [ 29.787519240506327, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33335, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.490661551724138 ], [ 29.787788734177216, -1.490931034482759 ], [ 29.788058227848101, -1.490931034482759 ], [ 29.788058227848101, -1.490661551724138 ], [ 29.787788734177216, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33336, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788058227848101, -1.490661551724138 ], [ 29.788058227848101, -1.490931034482759 ], [ 29.788327721518986, -1.490931034482759 ], [ 29.788327721518986, -1.490661551724138 ], [ 29.788058227848101, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33337, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.788327721518986, -1.490661551724138 ], [ 29.788327721518986, -1.490931034482759 ], [ 29.789136202531644, -1.490931034482759 ], [ 29.789136202531644, -1.490661551724138 ], [ 29.788327721518986, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33338, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.490661551724138 ], [ 29.800993924050633, -1.490931034482759 ], [ 29.801263417721518, -1.490931034482759 ], [ 29.801263417721518, -1.490661551724138 ], [ 29.800993924050633, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33339, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.490661551724138 ], [ 29.801263417721518, -1.490931034482759 ], [ 29.801532911392407, -1.490931034482759 ], [ 29.801532911392407, -1.490661551724138 ], [ 29.801263417721518, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33340, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.490661551724138 ], [ 29.801532911392407, -1.490931034482759 ], [ 29.802071898734177, -1.490931034482759 ], [ 29.802071898734177, -1.490661551724138 ], [ 29.801532911392407, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33341, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.490661551724138 ], [ 29.802071898734177, -1.490931034482759 ], [ 29.802880379746835, -1.490931034482759 ], [ 29.802880379746835, -1.490661551724138 ], [ 29.802071898734177, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33342, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802880379746835, -1.490661551724138 ], [ 29.802880379746835, -1.490931034482759 ], [ 29.803149873417723, -1.490931034482759 ], [ 29.803149873417723, -1.490661551724138 ], [ 29.802880379746835, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33343, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.490661551724138 ], [ 29.803149873417723, -1.490931034482759 ], [ 29.803419367088608, -1.490931034482759 ], [ 29.803419367088608, -1.490661551724138 ], [ 29.803149873417723, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33344, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.490661551724138 ], [ 29.803419367088608, -1.490931034482759 ], [ 29.803688860759493, -1.490931034482759 ], [ 29.803688860759493, -1.490661551724138 ], [ 29.803419367088608, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33345, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.490661551724138 ], [ 29.803688860759493, -1.490931034482759 ], [ 29.803958354430382, -1.490931034482759 ], [ 29.803958354430382, -1.490661551724138 ], [ 29.803688860759493, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33346, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803958354430382, -1.490661551724138 ], [ 29.803958354430382, -1.490931034482759 ], [ 29.804227848101267, -1.490931034482759 ], [ 29.804227848101267, -1.490661551724138 ], [ 29.803958354430382, -1.490661551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33347, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76892417721519, -1.490931034482759 ], [ 29.76892417721519, -1.491200517241379 ], [ 29.769193670886075, -1.491200517241379 ], [ 29.769193670886075, -1.490931034482759 ], [ 29.76892417721519, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33348, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.490931034482759 ], [ 29.769193670886075, -1.491200517241379 ], [ 29.76946316455696, -1.491200517241379 ], [ 29.76946316455696, -1.490931034482759 ], [ 29.769193670886075, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33349, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.76946316455696, -1.490931034482759 ], [ 29.76946316455696, -1.491200517241379 ], [ 29.769732658227849, -1.491200517241379 ], [ 29.769732658227849, -1.490931034482759 ], [ 29.76946316455696, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33350, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.490931034482759 ], [ 29.769732658227849, -1.491200517241379 ], [ 29.770002151898733, -1.491200517241379 ], [ 29.770002151898733, -1.490931034482759 ], [ 29.769732658227849, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33351, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.490931034482759 ], [ 29.770002151898733, -1.491200517241379 ], [ 29.770271645569618, -1.491200517241379 ], [ 29.770271645569618, -1.490931034482759 ], [ 29.770002151898733, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33352, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.490931034482759 ], [ 29.770271645569618, -1.491200517241379 ], [ 29.770541139240507, -1.491200517241379 ], [ 29.770541139240507, -1.490931034482759 ], [ 29.770271645569618, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33353, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.490931034482759 ], [ 29.770541139240507, -1.491200517241379 ], [ 29.770810632911392, -1.491200517241379 ], [ 29.770810632911392, -1.490931034482759 ], [ 29.770541139240507, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33354, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770810632911392, -1.490931034482759 ], [ 29.770810632911392, -1.491200517241379 ], [ 29.771349620253165, -1.491200517241379 ], [ 29.771349620253165, -1.490931034482759 ], [ 29.770810632911392, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33355, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.490931034482759 ], [ 29.771349620253165, -1.491200517241379 ], [ 29.77161911392405, -1.491200517241379 ], [ 29.77161911392405, -1.490931034482759 ], [ 29.771349620253165, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33356, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.490931034482759 ], [ 29.77161911392405, -1.491200517241379 ], [ 29.771888607594935, -1.491200517241379 ], [ 29.771888607594935, -1.490931034482759 ], [ 29.77161911392405, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33357, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.490931034482759 ], [ 29.771888607594935, -1.491200517241379 ], [ 29.772158101265823, -1.491200517241379 ], [ 29.772158101265823, -1.490931034482759 ], [ 29.771888607594935, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33358, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.490931034482759 ], [ 29.772158101265823, -1.491200517241379 ], [ 29.772427594936708, -1.491200517241379 ], [ 29.772427594936708, -1.490931034482759 ], [ 29.772158101265823, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33359, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.490931034482759 ], [ 29.772427594936708, -1.491200517241379 ], [ 29.772697088607593, -1.491200517241379 ], [ 29.772697088607593, -1.490931034482759 ], [ 29.772427594936708, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33360, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.490931034482759 ], [ 29.772697088607593, -1.491200517241379 ], [ 29.772966582278482, -1.491200517241379 ], [ 29.772966582278482, -1.490931034482759 ], [ 29.772697088607593, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33361, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.490931034482759 ], [ 29.772966582278482, -1.491200517241379 ], [ 29.773236075949367, -1.491200517241379 ], [ 29.773236075949367, -1.490931034482759 ], [ 29.772966582278482, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33362, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.490931034482759 ], [ 29.773236075949367, -1.491200517241379 ], [ 29.773505569620252, -1.491200517241379 ], [ 29.773505569620252, -1.490931034482759 ], [ 29.773236075949367, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33363, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.490931034482759 ], [ 29.773505569620252, -1.491200517241379 ], [ 29.77377506329114, -1.491200517241379 ], [ 29.77377506329114, -1.490931034482759 ], [ 29.773505569620252, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33364, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.490931034482759 ], [ 29.77377506329114, -1.491200517241379 ], [ 29.774044556962025, -1.491200517241379 ], [ 29.774044556962025, -1.490931034482759 ], [ 29.77377506329114, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33365, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.490931034482759 ], [ 29.774044556962025, -1.491200517241379 ], [ 29.77431405063291, -1.491200517241379 ], [ 29.77431405063291, -1.490931034482759 ], [ 29.774044556962025, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33366, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.490931034482759 ], [ 29.77431405063291, -1.491200517241379 ], [ 29.774853037974683, -1.491200517241379 ], [ 29.774853037974683, -1.490931034482759 ], [ 29.77431405063291, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33367, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.490931034482759 ], [ 29.774853037974683, -1.491200517241379 ], [ 29.775122531645568, -1.491200517241379 ], [ 29.775122531645568, -1.490931034482759 ], [ 29.774853037974683, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33368, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.490931034482759 ], [ 29.775122531645568, -1.491200517241379 ], [ 29.775392025316457, -1.491200517241379 ], [ 29.775392025316457, -1.490931034482759 ], [ 29.775122531645568, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33369, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.490931034482759 ], [ 29.775392025316457, -1.491200517241379 ], [ 29.775661518987341, -1.491200517241379 ], [ 29.775661518987341, -1.490931034482759 ], [ 29.775392025316457, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33370, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.490931034482759 ], [ 29.775661518987341, -1.491200517241379 ], [ 29.775931012658226, -1.491200517241379 ], [ 29.775931012658226, -1.490931034482759 ], [ 29.775661518987341, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33371, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.490931034482759 ], [ 29.775931012658226, -1.491200517241379 ], [ 29.776200506329115, -1.491200517241379 ], [ 29.776200506329115, -1.490931034482759 ], [ 29.775931012658226, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33372, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.490931034482759 ], [ 29.776200506329115, -1.491200517241379 ], [ 29.77647, -1.491200517241379 ], [ 29.77647, -1.490931034482759 ], [ 29.776200506329115, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33373, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.490931034482759 ], [ 29.77647, -1.491200517241379 ], [ 29.776739493670885, -1.491200517241379 ], [ 29.776739493670885, -1.490931034482759 ], [ 29.77647, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33374, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.490931034482759 ], [ 29.776739493670885, -1.491200517241379 ], [ 29.777008987341773, -1.491200517241379 ], [ 29.777008987341773, -1.490931034482759 ], [ 29.776739493670885, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33375, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.490931034482759 ], [ 29.777278481012658, -1.491200517241379 ], [ 29.777547974683543, -1.491200517241379 ], [ 29.777547974683543, -1.490931034482759 ], [ 29.777278481012658, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33376, "El": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.490931034482759 ], [ 29.777547974683543, -1.491200517241379 ], [ 29.777817468354431, -1.491200517241379 ], [ 29.777817468354431, -1.490931034482759 ], [ 29.777547974683543, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33377, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.490931034482759 ], [ 29.777817468354431, -1.491200517241379 ], [ 29.778086962025316, -1.491200517241379 ], [ 29.778086962025316, -1.490931034482759 ], [ 29.777817468354431, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33378, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.490931034482759 ], [ 29.778086962025316, -1.491200517241379 ], [ 29.778356455696201, -1.491200517241379 ], [ 29.778356455696201, -1.490931034482759 ], [ 29.778086962025316, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33379, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.490931034482759 ], [ 29.778356455696201, -1.491200517241379 ], [ 29.77862594936709, -1.491200517241379 ], [ 29.77862594936709, -1.490931034482759 ], [ 29.778356455696201, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33380, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.490931034482759 ], [ 29.77862594936709, -1.491200517241379 ], [ 29.778895443037975, -1.491200517241379 ], [ 29.778895443037975, -1.490931034482759 ], [ 29.77862594936709, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33381, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.490931034482759 ], [ 29.778895443037975, -1.491200517241379 ], [ 29.77916493670886, -1.491200517241379 ], [ 29.77916493670886, -1.490931034482759 ], [ 29.778895443037975, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33382, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.490931034482759 ], [ 29.77916493670886, -1.491200517241379 ], [ 29.779434430379748, -1.491200517241379 ], [ 29.779434430379748, -1.490931034482759 ], [ 29.77916493670886, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33383, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.490931034482759 ], [ 29.779434430379748, -1.491200517241379 ], [ 29.779703924050633, -1.491200517241379 ], [ 29.779703924050633, -1.490931034482759 ], [ 29.779434430379748, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33384, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.490931034482759 ], [ 29.779703924050633, -1.491200517241379 ], [ 29.779973417721518, -1.491200517241379 ], [ 29.779973417721518, -1.490931034482759 ], [ 29.779703924050633, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33385, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.490931034482759 ], [ 29.779973417721518, -1.491200517241379 ], [ 29.780242911392406, -1.491200517241379 ], [ 29.780242911392406, -1.490931034482759 ], [ 29.779973417721518, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33386, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.490931034482759 ], [ 29.780242911392406, -1.491200517241379 ], [ 29.780512405063291, -1.491200517241379 ], [ 29.780512405063291, -1.490931034482759 ], [ 29.780242911392406, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33387, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.490931034482759 ], [ 29.780512405063291, -1.491200517241379 ], [ 29.780781898734176, -1.491200517241379 ], [ 29.780781898734176, -1.490931034482759 ], [ 29.780512405063291, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33388, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.490931034482759 ], [ 29.780781898734176, -1.491200517241379 ], [ 29.781051392405065, -1.491200517241379 ], [ 29.781051392405065, -1.490931034482759 ], [ 29.780781898734176, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33389, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.490931034482759 ], [ 29.781051392405065, -1.491200517241379 ], [ 29.78132088607595, -1.491200517241379 ], [ 29.78132088607595, -1.490931034482759 ], [ 29.781051392405065, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33390, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.490931034482759 ], [ 29.78132088607595, -1.491200517241379 ], [ 29.781590379746834, -1.491200517241379 ], [ 29.781590379746834, -1.490931034482759 ], [ 29.78132088607595, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33391, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.490931034482759 ], [ 29.781590379746834, -1.491200517241379 ], [ 29.781859873417723, -1.491200517241379 ], [ 29.781859873417723, -1.490931034482759 ], [ 29.781590379746834, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33392, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.490931034482759 ], [ 29.781859873417723, -1.491200517241379 ], [ 29.782129367088608, -1.491200517241379 ], [ 29.782129367088608, -1.490931034482759 ], [ 29.781859873417723, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33393, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.490931034482759 ], [ 29.782129367088608, -1.491200517241379 ], [ 29.782398860759493, -1.491200517241379 ], [ 29.782398860759493, -1.490931034482759 ], [ 29.782129367088608, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33394, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.490931034482759 ], [ 29.782398860759493, -1.491200517241379 ], [ 29.782668354430381, -1.491200517241379 ], [ 29.782668354430381, -1.490931034482759 ], [ 29.782398860759493, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33395, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.490931034482759 ], [ 29.782668354430381, -1.491200517241379 ], [ 29.782937848101266, -1.491200517241379 ], [ 29.782937848101266, -1.490931034482759 ], [ 29.782668354430381, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33396, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.490931034482759 ], [ 29.782937848101266, -1.491200517241379 ], [ 29.783207341772151, -1.491200517241379 ], [ 29.783207341772151, -1.490931034482759 ], [ 29.782937848101266, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33397, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.490931034482759 ], [ 29.783207341772151, -1.491200517241379 ], [ 29.783476835443039, -1.491200517241379 ], [ 29.783476835443039, -1.490931034482759 ], [ 29.783207341772151, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33398, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.490931034482759 ], [ 29.783476835443039, -1.491200517241379 ], [ 29.784015822784809, -1.491200517241379 ], [ 29.784015822784809, -1.490931034482759 ], [ 29.783476835443039, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33399, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.490931034482759 ], [ 29.784015822784809, -1.491200517241379 ], [ 29.787249746835442, -1.491200517241379 ], [ 29.787249746835442, -1.490931034482759 ], [ 29.784015822784809, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33400, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.490931034482759 ], [ 29.787249746835442, -1.491200517241379 ], [ 29.787519240506327, -1.491200517241379 ], [ 29.787519240506327, -1.490931034482759 ], [ 29.787249746835442, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33401, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.490931034482759 ], [ 29.787519240506327, -1.491200517241379 ], [ 29.787788734177216, -1.491200517241379 ], [ 29.787788734177216, -1.490931034482759 ], [ 29.787519240506327, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33402, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787788734177216, -1.490931034482759 ], [ 29.787788734177216, -1.491200517241379 ], [ 29.788327721518986, -1.491200517241379 ], [ 29.788327721518986, -1.490931034482759 ], [ 29.787788734177216, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33403, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.490931034482759 ], [ 29.801263417721518, -1.491200517241379 ], [ 29.801532911392407, -1.491200517241379 ], [ 29.801532911392407, -1.490931034482759 ], [ 29.801263417721518, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33404, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.490931034482759 ], [ 29.801532911392407, -1.491200517241379 ], [ 29.802071898734177, -1.491200517241379 ], [ 29.802071898734177, -1.490931034482759 ], [ 29.801532911392407, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33405, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.490931034482759 ], [ 29.802071898734177, -1.491200517241379 ], [ 29.802341392405065, -1.491200517241379 ], [ 29.802341392405065, -1.490931034482759 ], [ 29.802071898734177, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33406, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.490931034482759 ], [ 29.802341392405065, -1.491200517241379 ], [ 29.80261088607595, -1.491200517241379 ], [ 29.80261088607595, -1.490931034482759 ], [ 29.802341392405065, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33407, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.490931034482759 ], [ 29.80261088607595, -1.491200517241379 ], [ 29.803149873417723, -1.491200517241379 ], [ 29.803149873417723, -1.490931034482759 ], [ 29.80261088607595, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33408, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.490931034482759 ], [ 29.803149873417723, -1.491200517241379 ], [ 29.803419367088608, -1.491200517241379 ], [ 29.803419367088608, -1.490931034482759 ], [ 29.803149873417723, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33409, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.490931034482759 ], [ 29.803419367088608, -1.491200517241379 ], [ 29.803688860759493, -1.491200517241379 ], [ 29.803688860759493, -1.490931034482759 ], [ 29.803419367088608, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33410, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803688860759493, -1.490931034482759 ], [ 29.803688860759493, -1.491200517241379 ], [ 29.803958354430382, -1.491200517241379 ], [ 29.803958354430382, -1.490931034482759 ], [ 29.803688860759493, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33411, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.490931034482759 ], [ 29.777008987341773, -1.49147 ], [ 29.777278481012658, -1.49147 ], [ 29.777278481012658, -1.490931034482759 ], [ 29.777008987341773, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33412, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.800993924050633, -1.490931034482759 ], [ 29.800993924050633, -1.49147 ], [ 29.801263417721518, -1.49147 ], [ 29.801263417721518, -1.490931034482759 ], [ 29.800993924050633, -1.490931034482759 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33413, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769193670886075, -1.491200517241379 ], [ 29.769193670886075, -1.49147 ], [ 29.769732658227849, -1.49147 ], [ 29.769732658227849, -1.491200517241379 ], [ 29.769193670886075, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33414, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.491200517241379 ], [ 29.769732658227849, -1.49147 ], [ 29.770002151898733, -1.49147 ], [ 29.770002151898733, -1.491200517241379 ], [ 29.769732658227849, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33415, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770002151898733, -1.491200517241379 ], [ 29.770002151898733, -1.49147 ], [ 29.770271645569618, -1.49147 ], [ 29.770271645569618, -1.491200517241379 ], [ 29.770002151898733, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33416, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.491200517241379 ], [ 29.770271645569618, -1.49147 ], [ 29.770541139240507, -1.49147 ], [ 29.770541139240507, -1.491200517241379 ], [ 29.770271645569618, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33417, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.491200517241379 ], [ 29.770541139240507, -1.49147 ], [ 29.771080126582277, -1.49147 ], [ 29.771080126582277, -1.491200517241379 ], [ 29.770541139240507, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33418, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.491200517241379 ], [ 29.771080126582277, -1.49147 ], [ 29.771349620253165, -1.49147 ], [ 29.771349620253165, -1.491200517241379 ], [ 29.771080126582277, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33419, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.491200517241379 ], [ 29.771349620253165, -1.49147 ], [ 29.77161911392405, -1.49147 ], [ 29.77161911392405, -1.491200517241379 ], [ 29.771349620253165, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33420, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.491200517241379 ], [ 29.77161911392405, -1.49147 ], [ 29.771888607594935, -1.49147 ], [ 29.771888607594935, -1.491200517241379 ], [ 29.77161911392405, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33421, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.491200517241379 ], [ 29.771888607594935, -1.49147 ], [ 29.772158101265823, -1.49147 ], [ 29.772158101265823, -1.491200517241379 ], [ 29.771888607594935, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33422, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.491200517241379 ], [ 29.772158101265823, -1.49147 ], [ 29.772427594936708, -1.49147 ], [ 29.772427594936708, -1.491200517241379 ], [ 29.772158101265823, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33423, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.491200517241379 ], [ 29.772427594936708, -1.49147 ], [ 29.772697088607593, -1.49147 ], [ 29.772697088607593, -1.491200517241379 ], [ 29.772427594936708, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33424, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.491200517241379 ], [ 29.772697088607593, -1.49147 ], [ 29.772966582278482, -1.49147 ], [ 29.772966582278482, -1.491200517241379 ], [ 29.772697088607593, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33425, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.491200517241379 ], [ 29.772966582278482, -1.49147 ], [ 29.773236075949367, -1.49147 ], [ 29.773236075949367, -1.491200517241379 ], [ 29.772966582278482, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33426, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.491200517241379 ], [ 29.773236075949367, -1.49147 ], [ 29.773505569620252, -1.49147 ], [ 29.773505569620252, -1.491200517241379 ], [ 29.773236075949367, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33427, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.491200517241379 ], [ 29.773505569620252, -1.49147 ], [ 29.77377506329114, -1.49147 ], [ 29.77377506329114, -1.491200517241379 ], [ 29.773505569620252, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33428, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.491200517241379 ], [ 29.77377506329114, -1.49147 ], [ 29.774044556962025, -1.49147 ], [ 29.774044556962025, -1.491200517241379 ], [ 29.77377506329114, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33429, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.491200517241379 ], [ 29.774044556962025, -1.49147 ], [ 29.77431405063291, -1.49147 ], [ 29.77431405063291, -1.491200517241379 ], [ 29.774044556962025, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33430, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.491200517241379 ], [ 29.77431405063291, -1.49147 ], [ 29.774853037974683, -1.49147 ], [ 29.774853037974683, -1.491200517241379 ], [ 29.77431405063291, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33431, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.491200517241379 ], [ 29.774853037974683, -1.49147 ], [ 29.775122531645568, -1.49147 ], [ 29.775122531645568, -1.491200517241379 ], [ 29.774853037974683, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33432, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.491200517241379 ], [ 29.775122531645568, -1.49147 ], [ 29.775392025316457, -1.49147 ], [ 29.775392025316457, -1.491200517241379 ], [ 29.775122531645568, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33433, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.491200517241379 ], [ 29.775392025316457, -1.49147 ], [ 29.775661518987341, -1.49147 ], [ 29.775661518987341, -1.491200517241379 ], [ 29.775392025316457, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33434, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.491200517241379 ], [ 29.775661518987341, -1.49147 ], [ 29.775931012658226, -1.49147 ], [ 29.775931012658226, -1.491200517241379 ], [ 29.775661518987341, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33435, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.491200517241379 ], [ 29.775931012658226, -1.49147 ], [ 29.776200506329115, -1.49147 ], [ 29.776200506329115, -1.491200517241379 ], [ 29.775931012658226, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33436, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.491200517241379 ], [ 29.776200506329115, -1.49147 ], [ 29.77647, -1.49147 ], [ 29.77647, -1.491200517241379 ], [ 29.776200506329115, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33437, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.491200517241379 ], [ 29.77647, -1.49147 ], [ 29.776739493670885, -1.49147 ], [ 29.776739493670885, -1.491200517241379 ], [ 29.77647, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33438, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.491200517241379 ], [ 29.776739493670885, -1.49147 ], [ 29.777008987341773, -1.49147 ], [ 29.777008987341773, -1.491200517241379 ], [ 29.776739493670885, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33439, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.491200517241379 ], [ 29.777278481012658, -1.49147 ], [ 29.777547974683543, -1.49147 ], [ 29.777547974683543, -1.491200517241379 ], [ 29.777278481012658, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33440, "El": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.491200517241379 ], [ 29.777547974683543, -1.49147 ], [ 29.777817468354431, -1.49147 ], [ 29.777817468354431, -1.491200517241379 ], [ 29.777547974683543, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33441, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.491200517241379 ], [ 29.777817468354431, -1.49147 ], [ 29.778086962025316, -1.49147 ], [ 29.778086962025316, -1.491200517241379 ], [ 29.777817468354431, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33442, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.491200517241379 ], [ 29.778086962025316, -1.49147 ], [ 29.778356455696201, -1.49147 ], [ 29.778356455696201, -1.491200517241379 ], [ 29.778086962025316, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33443, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.491200517241379 ], [ 29.778356455696201, -1.49147 ], [ 29.77862594936709, -1.49147 ], [ 29.77862594936709, -1.491200517241379 ], [ 29.778356455696201, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33444, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.491200517241379 ], [ 29.77862594936709, -1.49147 ], [ 29.778895443037975, -1.49147 ], [ 29.778895443037975, -1.491200517241379 ], [ 29.77862594936709, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33445, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.491200517241379 ], [ 29.778895443037975, -1.49147 ], [ 29.77916493670886, -1.49147 ], [ 29.77916493670886, -1.491200517241379 ], [ 29.778895443037975, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33446, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.491200517241379 ], [ 29.77916493670886, -1.49147 ], [ 29.779434430379748, -1.49147 ], [ 29.779434430379748, -1.491200517241379 ], [ 29.77916493670886, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33447, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.491200517241379 ], [ 29.779434430379748, -1.49147 ], [ 29.779703924050633, -1.49147 ], [ 29.779703924050633, -1.491200517241379 ], [ 29.779434430379748, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33448, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.491200517241379 ], [ 29.779703924050633, -1.49147 ], [ 29.779973417721518, -1.49147 ], [ 29.779973417721518, -1.491200517241379 ], [ 29.779703924050633, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33449, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.491200517241379 ], [ 29.779973417721518, -1.49147 ], [ 29.780242911392406, -1.49147 ], [ 29.780242911392406, -1.491200517241379 ], [ 29.779973417721518, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33450, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.491200517241379 ], [ 29.780242911392406, -1.49147 ], [ 29.780512405063291, -1.49147 ], [ 29.780512405063291, -1.491200517241379 ], [ 29.780242911392406, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33451, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.491200517241379 ], [ 29.780512405063291, -1.49147 ], [ 29.780781898734176, -1.49147 ], [ 29.780781898734176, -1.491200517241379 ], [ 29.780512405063291, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33452, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.491200517241379 ], [ 29.780781898734176, -1.49147 ], [ 29.781051392405065, -1.49147 ], [ 29.781051392405065, -1.491200517241379 ], [ 29.780781898734176, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33453, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.491200517241379 ], [ 29.781051392405065, -1.49147 ], [ 29.78132088607595, -1.49147 ], [ 29.78132088607595, -1.491200517241379 ], [ 29.781051392405065, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33454, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.491200517241379 ], [ 29.78132088607595, -1.49147 ], [ 29.781590379746834, -1.49147 ], [ 29.781590379746834, -1.491200517241379 ], [ 29.78132088607595, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33455, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.491200517241379 ], [ 29.781590379746834, -1.49147 ], [ 29.781859873417723, -1.49147 ], [ 29.781859873417723, -1.491200517241379 ], [ 29.781590379746834, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33456, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.491200517241379 ], [ 29.781859873417723, -1.49147 ], [ 29.782129367088608, -1.49147 ], [ 29.782129367088608, -1.491200517241379 ], [ 29.781859873417723, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33457, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.491200517241379 ], [ 29.782129367088608, -1.49147 ], [ 29.782398860759493, -1.49147 ], [ 29.782398860759493, -1.491200517241379 ], [ 29.782129367088608, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33458, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.491200517241379 ], [ 29.782398860759493, -1.49147 ], [ 29.782668354430381, -1.49147 ], [ 29.782668354430381, -1.491200517241379 ], [ 29.782398860759493, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33459, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.491200517241379 ], [ 29.782668354430381, -1.49147 ], [ 29.782937848101266, -1.49147 ], [ 29.782937848101266, -1.491200517241379 ], [ 29.782668354430381, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33460, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.491200517241379 ], [ 29.782937848101266, -1.49147 ], [ 29.783207341772151, -1.49147 ], [ 29.783207341772151, -1.491200517241379 ], [ 29.782937848101266, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33461, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.491200517241379 ], [ 29.783207341772151, -1.49147 ], [ 29.783476835443039, -1.49147 ], [ 29.783476835443039, -1.491200517241379 ], [ 29.783207341772151, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33462, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.491200517241379 ], [ 29.783476835443039, -1.49147 ], [ 29.784015822784809, -1.49147 ], [ 29.784015822784809, -1.491200517241379 ], [ 29.783476835443039, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33463, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.491200517241379 ], [ 29.784015822784809, -1.49147 ], [ 29.787249746835442, -1.49147 ], [ 29.787249746835442, -1.491200517241379 ], [ 29.784015822784809, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33464, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787249746835442, -1.491200517241379 ], [ 29.787249746835442, -1.49147 ], [ 29.787519240506327, -1.49147 ], [ 29.787519240506327, -1.491200517241379 ], [ 29.787249746835442, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33465, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.787519240506327, -1.491200517241379 ], [ 29.787519240506327, -1.49147 ], [ 29.787788734177216, -1.49147 ], [ 29.787788734177216, -1.491200517241379 ], [ 29.787519240506327, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33466, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.491200517241379 ], [ 29.801263417721518, -1.49147 ], [ 29.801532911392407, -1.49147 ], [ 29.801532911392407, -1.491200517241379 ], [ 29.801263417721518, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33467, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.491200517241379 ], [ 29.801532911392407, -1.49147 ], [ 29.802071898734177, -1.49147 ], [ 29.802071898734177, -1.491200517241379 ], [ 29.801532911392407, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33468, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.491200517241379 ], [ 29.802071898734177, -1.49147 ], [ 29.802341392405065, -1.49147 ], [ 29.802341392405065, -1.491200517241379 ], [ 29.802071898734177, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33469, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.491200517241379 ], [ 29.802341392405065, -1.49147 ], [ 29.80261088607595, -1.49147 ], [ 29.80261088607595, -1.491200517241379 ], [ 29.802341392405065, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33470, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.491200517241379 ], [ 29.80261088607595, -1.49147 ], [ 29.803149873417723, -1.49147 ], [ 29.803149873417723, -1.491200517241379 ], [ 29.80261088607595, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33471, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803149873417723, -1.491200517241379 ], [ 29.803149873417723, -1.49147 ], [ 29.803419367088608, -1.49147 ], [ 29.803419367088608, -1.491200517241379 ], [ 29.803149873417723, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33472, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.803419367088608, -1.491200517241379 ], [ 29.803419367088608, -1.49147 ], [ 29.803688860759493, -1.49147 ], [ 29.803688860759493, -1.491200517241379 ], [ 29.803419367088608, -1.491200517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33473, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.769732658227849, -1.49147 ], [ 29.769732658227849, -1.491739482758621 ], [ 29.770271645569618, -1.491739482758621 ], [ 29.770271645569618, -1.49147 ], [ 29.769732658227849, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33474, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.49147 ], [ 29.770271645569618, -1.491739482758621 ], [ 29.770541139240507, -1.491739482758621 ], [ 29.770541139240507, -1.49147 ], [ 29.770271645569618, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33475, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770541139240507, -1.49147 ], [ 29.770541139240507, -1.491739482758621 ], [ 29.771080126582277, -1.491739482758621 ], [ 29.771080126582277, -1.49147 ], [ 29.770541139240507, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33476, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.49147 ], [ 29.771080126582277, -1.491739482758621 ], [ 29.771349620253165, -1.491739482758621 ], [ 29.771349620253165, -1.49147 ], [ 29.771080126582277, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33477, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.49147 ], [ 29.771349620253165, -1.491739482758621 ], [ 29.77161911392405, -1.491739482758621 ], [ 29.77161911392405, -1.49147 ], [ 29.771349620253165, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33478, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.49147 ], [ 29.77161911392405, -1.491739482758621 ], [ 29.771888607594935, -1.491739482758621 ], [ 29.771888607594935, -1.49147 ], [ 29.77161911392405, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33479, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.49147 ], [ 29.771888607594935, -1.491739482758621 ], [ 29.772158101265823, -1.491739482758621 ], [ 29.772158101265823, -1.49147 ], [ 29.771888607594935, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33480, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.49147 ], [ 29.772158101265823, -1.491739482758621 ], [ 29.772427594936708, -1.491739482758621 ], [ 29.772427594936708, -1.49147 ], [ 29.772158101265823, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33481, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.49147 ], [ 29.772427594936708, -1.491739482758621 ], [ 29.772697088607593, -1.491739482758621 ], [ 29.772697088607593, -1.49147 ], [ 29.772427594936708, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33482, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.49147 ], [ 29.772697088607593, -1.491739482758621 ], [ 29.772966582278482, -1.491739482758621 ], [ 29.772966582278482, -1.49147 ], [ 29.772697088607593, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33483, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.49147 ], [ 29.772966582278482, -1.491739482758621 ], [ 29.773236075949367, -1.491739482758621 ], [ 29.773236075949367, -1.49147 ], [ 29.772966582278482, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33484, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.49147 ], [ 29.773236075949367, -1.491739482758621 ], [ 29.773505569620252, -1.491739482758621 ], [ 29.773505569620252, -1.49147 ], [ 29.773236075949367, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33485, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.49147 ], [ 29.773505569620252, -1.491739482758621 ], [ 29.77377506329114, -1.491739482758621 ], [ 29.77377506329114, -1.49147 ], [ 29.773505569620252, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33486, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.49147 ], [ 29.77377506329114, -1.491739482758621 ], [ 29.774044556962025, -1.491739482758621 ], [ 29.774044556962025, -1.49147 ], [ 29.77377506329114, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33487, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.49147 ], [ 29.774044556962025, -1.491739482758621 ], [ 29.77431405063291, -1.491739482758621 ], [ 29.77431405063291, -1.49147 ], [ 29.774044556962025, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33488, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.49147 ], [ 29.77431405063291, -1.491739482758621 ], [ 29.774853037974683, -1.491739482758621 ], [ 29.774853037974683, -1.49147 ], [ 29.77431405063291, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33489, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.49147 ], [ 29.774853037974683, -1.491739482758621 ], [ 29.775122531645568, -1.491739482758621 ], [ 29.775122531645568, -1.49147 ], [ 29.774853037974683, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33490, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.49147 ], [ 29.775122531645568, -1.491739482758621 ], [ 29.775392025316457, -1.491739482758621 ], [ 29.775392025316457, -1.49147 ], [ 29.775122531645568, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33491, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.49147 ], [ 29.775392025316457, -1.491739482758621 ], [ 29.775661518987341, -1.491739482758621 ], [ 29.775661518987341, -1.49147 ], [ 29.775392025316457, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33492, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.49147 ], [ 29.775661518987341, -1.491739482758621 ], [ 29.775931012658226, -1.491739482758621 ], [ 29.775931012658226, -1.49147 ], [ 29.775661518987341, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33493, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.49147 ], [ 29.775931012658226, -1.492008965517241 ], [ 29.776200506329115, -1.492008965517241 ], [ 29.776200506329115, -1.49147 ], [ 29.775931012658226, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33494, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.49147 ], [ 29.776200506329115, -1.491739482758621 ], [ 29.77647, -1.491739482758621 ], [ 29.77647, -1.49147 ], [ 29.776200506329115, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33495, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.49147 ], [ 29.77647, -1.491739482758621 ], [ 29.776739493670885, -1.491739482758621 ], [ 29.776739493670885, -1.49147 ], [ 29.77647, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33496, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.49147 ], [ 29.776739493670885, -1.492008965517241 ], [ 29.777008987341773, -1.492008965517241 ], [ 29.777008987341773, -1.49147 ], [ 29.776739493670885, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33497, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.49147 ], [ 29.777008987341773, -1.491739482758621 ], [ 29.777278481012658, -1.491739482758621 ], [ 29.777278481012658, -1.49147 ], [ 29.777008987341773, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33498, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.49147 ], [ 29.777278481012658, -1.491739482758621 ], [ 29.777547974683543, -1.491739482758621 ], [ 29.777547974683543, -1.49147 ], [ 29.777278481012658, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33499, "El": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.49147 ], [ 29.777547974683543, -1.492008965517241 ], [ 29.777817468354431, -1.492008965517241 ], [ 29.777817468354431, -1.49147 ], [ 29.777547974683543, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33500, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.49147 ], [ 29.777817468354431, -1.491739482758621 ], [ 29.778086962025316, -1.491739482758621 ], [ 29.778086962025316, -1.49147 ], [ 29.777817468354431, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33501, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.49147 ], [ 29.778086962025316, -1.492008965517241 ], [ 29.778356455696201, -1.492008965517241 ], [ 29.778356455696201, -1.49147 ], [ 29.778086962025316, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33502, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.49147 ], [ 29.778356455696201, -1.491739482758621 ], [ 29.77862594936709, -1.491739482758621 ], [ 29.77862594936709, -1.49147 ], [ 29.778356455696201, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33503, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.49147 ], [ 29.77862594936709, -1.491739482758621 ], [ 29.778895443037975, -1.491739482758621 ], [ 29.778895443037975, -1.49147 ], [ 29.77862594936709, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33504, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.49147 ], [ 29.778895443037975, -1.491739482758621 ], [ 29.77916493670886, -1.491739482758621 ], [ 29.77916493670886, -1.49147 ], [ 29.778895443037975, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33505, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.49147 ], [ 29.77916493670886, -1.491739482758621 ], [ 29.779434430379748, -1.491739482758621 ], [ 29.779434430379748, -1.49147 ], [ 29.77916493670886, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33506, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.49147 ], [ 29.779434430379748, -1.491739482758621 ], [ 29.779703924050633, -1.491739482758621 ], [ 29.779703924050633, -1.49147 ], [ 29.779434430379748, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33507, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.49147 ], [ 29.779703924050633, -1.491739482758621 ], [ 29.779973417721518, -1.491739482758621 ], [ 29.779973417721518, -1.49147 ], [ 29.779703924050633, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33508, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.49147 ], [ 29.779973417721518, -1.491739482758621 ], [ 29.780242911392406, -1.491739482758621 ], [ 29.780242911392406, -1.49147 ], [ 29.779973417721518, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33509, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.49147 ], [ 29.780242911392406, -1.491739482758621 ], [ 29.780512405063291, -1.491739482758621 ], [ 29.780512405063291, -1.49147 ], [ 29.780242911392406, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33510, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.49147 ], [ 29.780512405063291, -1.491739482758621 ], [ 29.780781898734176, -1.491739482758621 ], [ 29.780781898734176, -1.49147 ], [ 29.780512405063291, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33511, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.49147 ], [ 29.780781898734176, -1.491739482758621 ], [ 29.781051392405065, -1.491739482758621 ], [ 29.781051392405065, -1.49147 ], [ 29.780781898734176, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33512, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.49147 ], [ 29.781051392405065, -1.491739482758621 ], [ 29.78132088607595, -1.491739482758621 ], [ 29.78132088607595, -1.49147 ], [ 29.781051392405065, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33513, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.49147 ], [ 29.78132088607595, -1.491739482758621 ], [ 29.781590379746834, -1.491739482758621 ], [ 29.781590379746834, -1.49147 ], [ 29.78132088607595, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33514, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.49147 ], [ 29.781590379746834, -1.491739482758621 ], [ 29.781859873417723, -1.491739482758621 ], [ 29.781859873417723, -1.49147 ], [ 29.781590379746834, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33515, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.49147 ], [ 29.781859873417723, -1.491739482758621 ], [ 29.782129367088608, -1.491739482758621 ], [ 29.782129367088608, -1.49147 ], [ 29.781859873417723, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33516, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.49147 ], [ 29.782129367088608, -1.491739482758621 ], [ 29.782398860759493, -1.491739482758621 ], [ 29.782398860759493, -1.49147 ], [ 29.782129367088608, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33517, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.49147 ], [ 29.782398860759493, -1.491739482758621 ], [ 29.782668354430381, -1.491739482758621 ], [ 29.782668354430381, -1.49147 ], [ 29.782398860759493, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33518, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.49147 ], [ 29.782668354430381, -1.491739482758621 ], [ 29.782937848101266, -1.491739482758621 ], [ 29.782937848101266, -1.49147 ], [ 29.782668354430381, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33519, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.49147 ], [ 29.782937848101266, -1.491739482758621 ], [ 29.783207341772151, -1.491739482758621 ], [ 29.783207341772151, -1.49147 ], [ 29.782937848101266, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33520, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.49147 ], [ 29.783207341772151, -1.491739482758621 ], [ 29.783476835443039, -1.491739482758621 ], [ 29.783476835443039, -1.49147 ], [ 29.783207341772151, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33521, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.49147 ], [ 29.783476835443039, -1.491739482758621 ], [ 29.784015822784809, -1.491739482758621 ], [ 29.784015822784809, -1.49147 ], [ 29.783476835443039, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33522, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.784015822784809, -1.49147 ], [ 29.784015822784809, -1.491739482758621 ], [ 29.787519240506327, -1.491739482758621 ], [ 29.787519240506327, -1.49147 ], [ 29.784015822784809, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33523, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801263417721518, -1.49147 ], [ 29.801263417721518, -1.491739482758621 ], [ 29.801532911392407, -1.491739482758621 ], [ 29.801532911392407, -1.49147 ], [ 29.801263417721518, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33524, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.49147 ], [ 29.801532911392407, -1.491739482758621 ], [ 29.802071898734177, -1.491739482758621 ], [ 29.802071898734177, -1.49147 ], [ 29.801532911392407, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33525, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802071898734177, -1.49147 ], [ 29.802071898734177, -1.491739482758621 ], [ 29.802341392405065, -1.491739482758621 ], [ 29.802341392405065, -1.49147 ], [ 29.802071898734177, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33526, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.802341392405065, -1.49147 ], [ 29.802341392405065, -1.491739482758621 ], [ 29.80261088607595, -1.491739482758621 ], [ 29.80261088607595, -1.49147 ], [ 29.802341392405065, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33527, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.80261088607595, -1.49147 ], [ 29.80261088607595, -1.491739482758621 ], [ 29.803419367088608, -1.491739482758621 ], [ 29.803419367088608, -1.49147 ], [ 29.80261088607595, -1.49147 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33528, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.770271645569618, -1.491739482758621 ], [ 29.770271645569618, -1.492008965517241 ], [ 29.771080126582277, -1.492008965517241 ], [ 29.771080126582277, -1.491739482758621 ], [ 29.770271645569618, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33529, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.491739482758621 ], [ 29.771080126582277, -1.492008965517241 ], [ 29.771349620253165, -1.492008965517241 ], [ 29.771349620253165, -1.491739482758621 ], [ 29.771080126582277, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33530, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771349620253165, -1.491739482758621 ], [ 29.771349620253165, -1.492008965517241 ], [ 29.77161911392405, -1.492008965517241 ], [ 29.77161911392405, -1.491739482758621 ], [ 29.771349620253165, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33531, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.491739482758621 ], [ 29.77161911392405, -1.492008965517241 ], [ 29.771888607594935, -1.492008965517241 ], [ 29.771888607594935, -1.491739482758621 ], [ 29.77161911392405, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33532, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.491739482758621 ], [ 29.771888607594935, -1.492008965517241 ], [ 29.772158101265823, -1.492008965517241 ], [ 29.772158101265823, -1.491739482758621 ], [ 29.771888607594935, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33533, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.491739482758621 ], [ 29.772158101265823, -1.492008965517241 ], [ 29.772427594936708, -1.492008965517241 ], [ 29.772427594936708, -1.491739482758621 ], [ 29.772158101265823, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33534, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.491739482758621 ], [ 29.772427594936708, -1.492008965517241 ], [ 29.772697088607593, -1.492008965517241 ], [ 29.772697088607593, -1.491739482758621 ], [ 29.772427594936708, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33535, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.491739482758621 ], [ 29.772697088607593, -1.492008965517241 ], [ 29.772966582278482, -1.492008965517241 ], [ 29.772966582278482, -1.491739482758621 ], [ 29.772697088607593, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33536, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.491739482758621 ], [ 29.772966582278482, -1.492008965517241 ], [ 29.773236075949367, -1.492008965517241 ], [ 29.773236075949367, -1.491739482758621 ], [ 29.772966582278482, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33537, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.491739482758621 ], [ 29.773236075949367, -1.492008965517241 ], [ 29.773505569620252, -1.492008965517241 ], [ 29.773505569620252, -1.491739482758621 ], [ 29.773236075949367, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33538, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.491739482758621 ], [ 29.773505569620252, -1.492008965517241 ], [ 29.77377506329114, -1.492008965517241 ], [ 29.77377506329114, -1.491739482758621 ], [ 29.773505569620252, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33539, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.491739482758621 ], [ 29.77377506329114, -1.492008965517241 ], [ 29.774044556962025, -1.492008965517241 ], [ 29.774044556962025, -1.491739482758621 ], [ 29.77377506329114, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33540, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.491739482758621 ], [ 29.774044556962025, -1.492008965517241 ], [ 29.77431405063291, -1.492008965517241 ], [ 29.77431405063291, -1.491739482758621 ], [ 29.774044556962025, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33541, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.491739482758621 ], [ 29.77431405063291, -1.492008965517241 ], [ 29.774853037974683, -1.492008965517241 ], [ 29.774853037974683, -1.491739482758621 ], [ 29.77431405063291, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33542, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.491739482758621 ], [ 29.774853037974683, -1.492278448275862 ], [ 29.775122531645568, -1.492278448275862 ], [ 29.775122531645568, -1.491739482758621 ], [ 29.774853037974683, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33543, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.491739482758621 ], [ 29.775122531645568, -1.492008965517241 ], [ 29.775392025316457, -1.492008965517241 ], [ 29.775392025316457, -1.491739482758621 ], [ 29.775122531645568, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33544, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.491739482758621 ], [ 29.775392025316457, -1.492008965517241 ], [ 29.775661518987341, -1.492008965517241 ], [ 29.775661518987341, -1.491739482758621 ], [ 29.775392025316457, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33545, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.491739482758621 ], [ 29.775661518987341, -1.492278448275862 ], [ 29.775931012658226, -1.492278448275862 ], [ 29.775931012658226, -1.491739482758621 ], [ 29.775661518987341, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33546, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.491739482758621 ], [ 29.776200506329115, -1.492008965517241 ], [ 29.77647, -1.492008965517241 ], [ 29.77647, -1.491739482758621 ], [ 29.776200506329115, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33547, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.491739482758621 ], [ 29.77647, -1.492278448275862 ], [ 29.776739493670885, -1.492278448275862 ], [ 29.776739493670885, -1.491739482758621 ], [ 29.77647, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33548, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.491739482758621 ], [ 29.777008987341773, -1.492008965517241 ], [ 29.777278481012658, -1.492008965517241 ], [ 29.777278481012658, -1.491739482758621 ], [ 29.777008987341773, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33549, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.491739482758621 ], [ 29.777278481012658, -1.492008965517241 ], [ 29.777547974683543, -1.492008965517241 ], [ 29.777547974683543, -1.491739482758621 ], [ 29.777278481012658, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33550, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.491739482758621 ], [ 29.777817468354431, -1.492008965517241 ], [ 29.778086962025316, -1.492008965517241 ], [ 29.778086962025316, -1.491739482758621 ], [ 29.777817468354431, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33551, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.491739482758621 ], [ 29.778356455696201, -1.492008965517241 ], [ 29.77862594936709, -1.492008965517241 ], [ 29.77862594936709, -1.491739482758621 ], [ 29.778356455696201, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33552, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.491739482758621 ], [ 29.77862594936709, -1.492008965517241 ], [ 29.778895443037975, -1.492008965517241 ], [ 29.778895443037975, -1.491739482758621 ], [ 29.77862594936709, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33553, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.491739482758621 ], [ 29.778895443037975, -1.492008965517241 ], [ 29.77916493670886, -1.492008965517241 ], [ 29.77916493670886, -1.491739482758621 ], [ 29.778895443037975, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33554, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.491739482758621 ], [ 29.77916493670886, -1.492008965517241 ], [ 29.779434430379748, -1.492008965517241 ], [ 29.779434430379748, -1.491739482758621 ], [ 29.77916493670886, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33555, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.491739482758621 ], [ 29.779434430379748, -1.492008965517241 ], [ 29.779703924050633, -1.492008965517241 ], [ 29.779703924050633, -1.491739482758621 ], [ 29.779434430379748, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33556, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.491739482758621 ], [ 29.779703924050633, -1.492008965517241 ], [ 29.779973417721518, -1.492008965517241 ], [ 29.779973417721518, -1.491739482758621 ], [ 29.779703924050633, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33557, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.491739482758621 ], [ 29.779973417721518, -1.492008965517241 ], [ 29.780242911392406, -1.492008965517241 ], [ 29.780242911392406, -1.491739482758621 ], [ 29.779973417721518, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33558, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.491739482758621 ], [ 29.780242911392406, -1.492008965517241 ], [ 29.780512405063291, -1.492008965517241 ], [ 29.780512405063291, -1.491739482758621 ], [ 29.780242911392406, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33559, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.491739482758621 ], [ 29.780512405063291, -1.492008965517241 ], [ 29.780781898734176, -1.492008965517241 ], [ 29.780781898734176, -1.491739482758621 ], [ 29.780512405063291, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33560, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.491739482758621 ], [ 29.780781898734176, -1.492008965517241 ], [ 29.781051392405065, -1.492008965517241 ], [ 29.781051392405065, -1.491739482758621 ], [ 29.780781898734176, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33561, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.491739482758621 ], [ 29.781051392405065, -1.492008965517241 ], [ 29.78132088607595, -1.492008965517241 ], [ 29.78132088607595, -1.491739482758621 ], [ 29.781051392405065, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33562, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.491739482758621 ], [ 29.78132088607595, -1.492008965517241 ], [ 29.781590379746834, -1.492008965517241 ], [ 29.781590379746834, -1.491739482758621 ], [ 29.78132088607595, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33563, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.491739482758621 ], [ 29.781590379746834, -1.492008965517241 ], [ 29.781859873417723, -1.492008965517241 ], [ 29.781859873417723, -1.491739482758621 ], [ 29.781590379746834, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33564, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.491739482758621 ], [ 29.781859873417723, -1.492008965517241 ], [ 29.782129367088608, -1.492008965517241 ], [ 29.782129367088608, -1.491739482758621 ], [ 29.781859873417723, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33565, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.491739482758621 ], [ 29.782129367088608, -1.492008965517241 ], [ 29.782398860759493, -1.492008965517241 ], [ 29.782398860759493, -1.491739482758621 ], [ 29.782129367088608, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33566, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.491739482758621 ], [ 29.782398860759493, -1.492008965517241 ], [ 29.782668354430381, -1.492008965517241 ], [ 29.782668354430381, -1.491739482758621 ], [ 29.782398860759493, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33567, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.491739482758621 ], [ 29.782668354430381, -1.492008965517241 ], [ 29.782937848101266, -1.492008965517241 ], [ 29.782937848101266, -1.491739482758621 ], [ 29.782668354430381, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33568, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.491739482758621 ], [ 29.782937848101266, -1.492008965517241 ], [ 29.783207341772151, -1.492008965517241 ], [ 29.783207341772151, -1.491739482758621 ], [ 29.782937848101266, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33569, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.491739482758621 ], [ 29.783207341772151, -1.492008965517241 ], [ 29.783476835443039, -1.492008965517241 ], [ 29.783476835443039, -1.491739482758621 ], [ 29.783207341772151, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33570, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783476835443039, -1.491739482758621 ], [ 29.783476835443039, -1.492008965517241 ], [ 29.784015822784809, -1.492008965517241 ], [ 29.784015822784809, -1.491739482758621 ], [ 29.783476835443039, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33571, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.801532911392407, -1.491739482758621 ], [ 29.801532911392407, -1.492008965517241 ], [ 29.80261088607595, -1.492008965517241 ], [ 29.80261088607595, -1.491739482758621 ], [ 29.801532911392407, -1.491739482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33572, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771080126582277, -1.492008965517241 ], [ 29.771080126582277, -1.492278448275862 ], [ 29.77161911392405, -1.492278448275862 ], [ 29.77161911392405, -1.492008965517241 ], [ 29.771080126582277, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33573, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.492008965517241 ], [ 29.77161911392405, -1.492278448275862 ], [ 29.771888607594935, -1.492278448275862 ], [ 29.771888607594935, -1.492008965517241 ], [ 29.77161911392405, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33574, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.771888607594935, -1.492008965517241 ], [ 29.771888607594935, -1.492278448275862 ], [ 29.772158101265823, -1.492278448275862 ], [ 29.772158101265823, -1.492008965517241 ], [ 29.771888607594935, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33575, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.492008965517241 ], [ 29.772158101265823, -1.492278448275862 ], [ 29.772427594936708, -1.492278448275862 ], [ 29.772427594936708, -1.492008965517241 ], [ 29.772158101265823, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33576, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.492008965517241 ], [ 29.772427594936708, -1.492278448275862 ], [ 29.772697088607593, -1.492278448275862 ], [ 29.772697088607593, -1.492008965517241 ], [ 29.772427594936708, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33577, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.492008965517241 ], [ 29.772697088607593, -1.492278448275862 ], [ 29.772966582278482, -1.492278448275862 ], [ 29.772966582278482, -1.492008965517241 ], [ 29.772697088607593, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33578, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.492008965517241 ], [ 29.772966582278482, -1.492278448275862 ], [ 29.773236075949367, -1.492278448275862 ], [ 29.773236075949367, -1.492008965517241 ], [ 29.772966582278482, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33579, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.492008965517241 ], [ 29.773236075949367, -1.492278448275862 ], [ 29.773505569620252, -1.492278448275862 ], [ 29.773505569620252, -1.492008965517241 ], [ 29.773236075949367, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33580, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.492008965517241 ], [ 29.773505569620252, -1.492547931034483 ], [ 29.77377506329114, -1.492547931034483 ], [ 29.77377506329114, -1.492008965517241 ], [ 29.773505569620252, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33581, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.492008965517241 ], [ 29.77377506329114, -1.492278448275862 ], [ 29.774044556962025, -1.492278448275862 ], [ 29.774044556962025, -1.492008965517241 ], [ 29.77377506329114, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33582, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.492008965517241 ], [ 29.774044556962025, -1.492278448275862 ], [ 29.77431405063291, -1.492278448275862 ], [ 29.77431405063291, -1.492008965517241 ], [ 29.774044556962025, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33583, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.492008965517241 ], [ 29.77431405063291, -1.492547931034483 ], [ 29.774853037974683, -1.492547931034483 ], [ 29.774853037974683, -1.492008965517241 ], [ 29.77431405063291, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33584, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.492008965517241 ], [ 29.775122531645568, -1.492547931034483 ], [ 29.775392025316457, -1.492547931034483 ], [ 29.775392025316457, -1.492008965517241 ], [ 29.775122531645568, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33585, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.492008965517241 ], [ 29.775392025316457, -1.492547931034483 ], [ 29.775661518987341, -1.492547931034483 ], [ 29.775661518987341, -1.492008965517241 ], [ 29.775392025316457, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33586, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.492008965517241 ], [ 29.775931012658226, -1.492278448275862 ], [ 29.776200506329115, -1.492278448275862 ], [ 29.776200506329115, -1.492008965517241 ], [ 29.775931012658226, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33587, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.492008965517241 ], [ 29.776200506329115, -1.492547931034483 ], [ 29.77647, -1.492547931034483 ], [ 29.77647, -1.492008965517241 ], [ 29.776200506329115, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33588, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.492008965517241 ], [ 29.776739493670885, -1.492278448275862 ], [ 29.777008987341773, -1.492278448275862 ], [ 29.777008987341773, -1.492008965517241 ], [ 29.776739493670885, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33589, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.492008965517241 ], [ 29.777008987341773, -1.492278448275862 ], [ 29.777278481012658, -1.492278448275862 ], [ 29.777278481012658, -1.492008965517241 ], [ 29.777008987341773, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33590, "El": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.492008965517241 ], [ 29.777278481012658, -1.492547931034483 ], [ 29.777547974683543, -1.492547931034483 ], [ 29.777547974683543, -1.492008965517241 ], [ 29.777278481012658, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33591, "El": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.492008965517241 ], [ 29.777547974683543, -1.492278448275862 ], [ 29.777817468354431, -1.492278448275862 ], [ 29.777817468354431, -1.492008965517241 ], [ 29.777547974683543, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33592, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.492008965517241 ], [ 29.777817468354431, -1.492278448275862 ], [ 29.778086962025316, -1.492278448275862 ], [ 29.778086962025316, -1.492008965517241 ], [ 29.777817468354431, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33593, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.492008965517241 ], [ 29.778086962025316, -1.492278448275862 ], [ 29.778356455696201, -1.492278448275862 ], [ 29.778356455696201, -1.492008965517241 ], [ 29.778086962025316, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33594, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.492008965517241 ], [ 29.778356455696201, -1.492547931034483 ], [ 29.77862594936709, -1.492547931034483 ], [ 29.77862594936709, -1.492008965517241 ], [ 29.778356455696201, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33595, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.492008965517241 ], [ 29.77862594936709, -1.492278448275862 ], [ 29.778895443037975, -1.492278448275862 ], [ 29.778895443037975, -1.492008965517241 ], [ 29.77862594936709, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33596, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.492008965517241 ], [ 29.778895443037975, -1.492278448275862 ], [ 29.77916493670886, -1.492278448275862 ], [ 29.77916493670886, -1.492008965517241 ], [ 29.778895443037975, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33597, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.492008965517241 ], [ 29.77916493670886, -1.492547931034483 ], [ 29.779434430379748, -1.492547931034483 ], [ 29.779434430379748, -1.492008965517241 ], [ 29.77916493670886, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33598, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.492008965517241 ], [ 29.779434430379748, -1.492278448275862 ], [ 29.779703924050633, -1.492278448275862 ], [ 29.779703924050633, -1.492008965517241 ], [ 29.779434430379748, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33599, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.492008965517241 ], [ 29.779703924050633, -1.492278448275862 ], [ 29.779973417721518, -1.492278448275862 ], [ 29.779973417721518, -1.492008965517241 ], [ 29.779703924050633, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33600, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.492008965517241 ], [ 29.779973417721518, -1.492278448275862 ], [ 29.780242911392406, -1.492278448275862 ], [ 29.780242911392406, -1.492008965517241 ], [ 29.779973417721518, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33601, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.492008965517241 ], [ 29.780242911392406, -1.492278448275862 ], [ 29.780512405063291, -1.492278448275862 ], [ 29.780512405063291, -1.492008965517241 ], [ 29.780242911392406, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33602, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.492008965517241 ], [ 29.780512405063291, -1.492278448275862 ], [ 29.780781898734176, -1.492278448275862 ], [ 29.780781898734176, -1.492008965517241 ], [ 29.780512405063291, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33603, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.492008965517241 ], [ 29.780781898734176, -1.492278448275862 ], [ 29.781051392405065, -1.492278448275862 ], [ 29.781051392405065, -1.492008965517241 ], [ 29.780781898734176, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33604, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.492008965517241 ], [ 29.781051392405065, -1.492278448275862 ], [ 29.78132088607595, -1.492278448275862 ], [ 29.78132088607595, -1.492008965517241 ], [ 29.781051392405065, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33605, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.492008965517241 ], [ 29.78132088607595, -1.492278448275862 ], [ 29.781590379746834, -1.492278448275862 ], [ 29.781590379746834, -1.492008965517241 ], [ 29.78132088607595, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33606, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.492008965517241 ], [ 29.781590379746834, -1.492278448275862 ], [ 29.781859873417723, -1.492278448275862 ], [ 29.781859873417723, -1.492008965517241 ], [ 29.781590379746834, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33607, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.492008965517241 ], [ 29.781859873417723, -1.492278448275862 ], [ 29.782129367088608, -1.492278448275862 ], [ 29.782129367088608, -1.492008965517241 ], [ 29.781859873417723, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33608, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.492008965517241 ], [ 29.782129367088608, -1.492278448275862 ], [ 29.782398860759493, -1.492278448275862 ], [ 29.782398860759493, -1.492008965517241 ], [ 29.782129367088608, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33609, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.492008965517241 ], [ 29.782398860759493, -1.492278448275862 ], [ 29.782668354430381, -1.492278448275862 ], [ 29.782668354430381, -1.492008965517241 ], [ 29.782398860759493, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33610, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.492008965517241 ], [ 29.782668354430381, -1.492278448275862 ], [ 29.782937848101266, -1.492278448275862 ], [ 29.782937848101266, -1.492008965517241 ], [ 29.782668354430381, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33611, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.492008965517241 ], [ 29.782937848101266, -1.492278448275862 ], [ 29.783207341772151, -1.492278448275862 ], [ 29.783207341772151, -1.492008965517241 ], [ 29.782937848101266, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33612, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.783207341772151, -1.492008965517241 ], [ 29.783207341772151, -1.492547931034483 ], [ 29.783476835443039, -1.492547931034483 ], [ 29.783476835443039, -1.492008965517241 ], [ 29.783207341772151, -1.492008965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33613, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77161911392405, -1.492278448275862 ], [ 29.77161911392405, -1.492547931034483 ], [ 29.772158101265823, -1.492547931034483 ], [ 29.772158101265823, -1.492278448275862 ], [ 29.77161911392405, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33614, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.492278448275862 ], [ 29.772158101265823, -1.492547931034483 ], [ 29.772427594936708, -1.492547931034483 ], [ 29.772427594936708, -1.492278448275862 ], [ 29.772158101265823, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33615, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.492278448275862 ], [ 29.772427594936708, -1.492547931034483 ], [ 29.772697088607593, -1.492547931034483 ], [ 29.772697088607593, -1.492278448275862 ], [ 29.772427594936708, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33616, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.492278448275862 ], [ 29.772697088607593, -1.492547931034483 ], [ 29.772966582278482, -1.492547931034483 ], [ 29.772966582278482, -1.492278448275862 ], [ 29.772697088607593, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33617, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.492278448275862 ], [ 29.772966582278482, -1.493086896551724 ], [ 29.773236075949367, -1.493086896551724 ], [ 29.773236075949367, -1.492278448275862 ], [ 29.772966582278482, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33618, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.492278448275862 ], [ 29.773236075949367, -1.493086896551724 ], [ 29.773505569620252, -1.493086896551724 ], [ 29.773505569620252, -1.492278448275862 ], [ 29.773236075949367, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33619, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.492278448275862 ], [ 29.77377506329114, -1.492817413793104 ], [ 29.774044556962025, -1.492817413793104 ], [ 29.774044556962025, -1.492278448275862 ], [ 29.77377506329114, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33620, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.492278448275862 ], [ 29.774044556962025, -1.492817413793104 ], [ 29.77431405063291, -1.492817413793104 ], [ 29.77431405063291, -1.492278448275862 ], [ 29.774044556962025, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33621, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.492278448275862 ], [ 29.774853037974683, -1.492547931034483 ], [ 29.775122531645568, -1.492547931034483 ], [ 29.775122531645568, -1.492278448275862 ], [ 29.774853037974683, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33622, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.492278448275862 ], [ 29.775661518987341, -1.492547931034483 ], [ 29.775931012658226, -1.492547931034483 ], [ 29.775931012658226, -1.492278448275862 ], [ 29.775661518987341, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33623, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.492278448275862 ], [ 29.775931012658226, -1.492547931034483 ], [ 29.776200506329115, -1.492547931034483 ], [ 29.776200506329115, -1.492278448275862 ], [ 29.775931012658226, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33624, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.492278448275862 ], [ 29.77647, -1.492547931034483 ], [ 29.776739493670885, -1.492547931034483 ], [ 29.776739493670885, -1.492278448275862 ], [ 29.77647, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33625, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.492278448275862 ], [ 29.776739493670885, -1.492547931034483 ], [ 29.777008987341773, -1.492547931034483 ], [ 29.777008987341773, -1.492278448275862 ], [ 29.776739493670885, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33626, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.492278448275862 ], [ 29.777008987341773, -1.492547931034483 ], [ 29.777278481012658, -1.492547931034483 ], [ 29.777278481012658, -1.492278448275862 ], [ 29.777008987341773, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33627, "El": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.492278448275862 ], [ 29.777547974683543, -1.492547931034483 ], [ 29.777817468354431, -1.492547931034483 ], [ 29.777817468354431, -1.492278448275862 ], [ 29.777547974683543, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33628, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.492278448275862 ], [ 29.778086962025316, -1.492817413793104 ], [ 29.778356455696201, -1.492817413793104 ], [ 29.778356455696201, -1.492278448275862 ], [ 29.778086962025316, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33629, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.492278448275862 ], [ 29.77862594936709, -1.492817413793104 ], [ 29.778895443037975, -1.492817413793104 ], [ 29.778895443037975, -1.492278448275862 ], [ 29.77862594936709, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33630, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.492278448275862 ], [ 29.778895443037975, -1.492547931034483 ], [ 29.77916493670886, -1.492547931034483 ], [ 29.77916493670886, -1.492278448275862 ], [ 29.778895443037975, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33631, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.492278448275862 ], [ 29.779434430379748, -1.492817413793104 ], [ 29.779703924050633, -1.492817413793104 ], [ 29.779703924050633, -1.492278448275862 ], [ 29.779434430379748, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33632, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.492278448275862 ], [ 29.779703924050633, -1.492547931034483 ], [ 29.779973417721518, -1.492547931034483 ], [ 29.779973417721518, -1.492278448275862 ], [ 29.779703924050633, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33633, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.492278448275862 ], [ 29.779973417721518, -1.492547931034483 ], [ 29.780242911392406, -1.492547931034483 ], [ 29.780242911392406, -1.492278448275862 ], [ 29.779973417721518, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33634, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.492278448275862 ], [ 29.780242911392406, -1.492817413793104 ], [ 29.780512405063291, -1.492817413793104 ], [ 29.780512405063291, -1.492278448275862 ], [ 29.780242911392406, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33635, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.492278448275862 ], [ 29.780512405063291, -1.492547931034483 ], [ 29.780781898734176, -1.492547931034483 ], [ 29.780781898734176, -1.492278448275862 ], [ 29.780512405063291, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33636, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.492278448275862 ], [ 29.780781898734176, -1.492547931034483 ], [ 29.781051392405065, -1.492547931034483 ], [ 29.781051392405065, -1.492278448275862 ], [ 29.780781898734176, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33637, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.492278448275862 ], [ 29.781051392405065, -1.492547931034483 ], [ 29.78132088607595, -1.492547931034483 ], [ 29.78132088607595, -1.492278448275862 ], [ 29.781051392405065, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33638, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.492278448275862 ], [ 29.78132088607595, -1.492547931034483 ], [ 29.781590379746834, -1.492547931034483 ], [ 29.781590379746834, -1.492278448275862 ], [ 29.78132088607595, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33639, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.492278448275862 ], [ 29.781590379746834, -1.492547931034483 ], [ 29.781859873417723, -1.492547931034483 ], [ 29.781859873417723, -1.492278448275862 ], [ 29.781590379746834, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33640, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.492278448275862 ], [ 29.781859873417723, -1.492547931034483 ], [ 29.782129367088608, -1.492547931034483 ], [ 29.782129367088608, -1.492278448275862 ], [ 29.781859873417723, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33641, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.492278448275862 ], [ 29.782129367088608, -1.492547931034483 ], [ 29.782398860759493, -1.492547931034483 ], [ 29.782398860759493, -1.492278448275862 ], [ 29.782129367088608, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33642, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.492278448275862 ], [ 29.782398860759493, -1.492547931034483 ], [ 29.782668354430381, -1.492547931034483 ], [ 29.782668354430381, -1.492278448275862 ], [ 29.782398860759493, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33643, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.492278448275862 ], [ 29.782668354430381, -1.492547931034483 ], [ 29.782937848101266, -1.492547931034483 ], [ 29.782937848101266, -1.492278448275862 ], [ 29.782668354430381, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33644, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.492278448275862 ], [ 29.782937848101266, -1.492547931034483 ], [ 29.783207341772151, -1.492547931034483 ], [ 29.783207341772151, -1.492278448275862 ], [ 29.782937848101266, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33645, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.492547931034483 ], [ 29.772427594936708, -1.493356379310345 ], [ 29.772697088607593, -1.493356379310345 ], [ 29.772697088607593, -1.492547931034483 ], [ 29.772427594936708, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33646, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.492547931034483 ], [ 29.772697088607593, -1.493356379310345 ], [ 29.772966582278482, -1.493356379310345 ], [ 29.772966582278482, -1.492547931034483 ], [ 29.772697088607593, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33647, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.492547931034483 ], [ 29.773505569620252, -1.493086896551724 ], [ 29.77377506329114, -1.493086896551724 ], [ 29.77377506329114, -1.492547931034483 ], [ 29.773505569620252, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33648, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.492547931034483 ], [ 29.77431405063291, -1.492817413793104 ], [ 29.774853037974683, -1.492817413793104 ], [ 29.774853037974683, -1.492547931034483 ], [ 29.77431405063291, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33649, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.492547931034483 ], [ 29.774853037974683, -1.492817413793104 ], [ 29.775122531645568, -1.492817413793104 ], [ 29.775122531645568, -1.492547931034483 ], [ 29.774853037974683, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33650, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.492547931034483 ], [ 29.775122531645568, -1.492817413793104 ], [ 29.775392025316457, -1.492817413793104 ], [ 29.775392025316457, -1.492547931034483 ], [ 29.775122531645568, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33651, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.492547931034483 ], [ 29.775392025316457, -1.492817413793104 ], [ 29.775661518987341, -1.492817413793104 ], [ 29.775661518987341, -1.492547931034483 ], [ 29.775392025316457, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33652, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.492547931034483 ], [ 29.775661518987341, -1.492817413793104 ], [ 29.775931012658226, -1.492817413793104 ], [ 29.775931012658226, -1.492547931034483 ], [ 29.775661518987341, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33653, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.492547931034483 ], [ 29.775931012658226, -1.492817413793104 ], [ 29.776200506329115, -1.492817413793104 ], [ 29.776200506329115, -1.492547931034483 ], [ 29.775931012658226, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33654, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.492547931034483 ], [ 29.776200506329115, -1.493086896551724 ], [ 29.77647, -1.493086896551724 ], [ 29.77647, -1.492547931034483 ], [ 29.776200506329115, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33655, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.492547931034483 ], [ 29.77647, -1.492817413793104 ], [ 29.776739493670885, -1.492817413793104 ], [ 29.776739493670885, -1.492547931034483 ], [ 29.77647, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33656, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.492547931034483 ], [ 29.776739493670885, -1.492817413793104 ], [ 29.777008987341773, -1.492817413793104 ], [ 29.777008987341773, -1.492547931034483 ], [ 29.776739493670885, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33657, "El": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.492547931034483 ], [ 29.777008987341773, -1.493086896551724 ], [ 29.777278481012658, -1.493086896551724 ], [ 29.777278481012658, -1.492547931034483 ], [ 29.777008987341773, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33658, "El": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.492547931034483 ], [ 29.777278481012658, -1.492817413793104 ], [ 29.777547974683543, -1.492817413793104 ], [ 29.777547974683543, -1.492547931034483 ], [ 29.777278481012658, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33659, "El": 66 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.492547931034483 ], [ 29.777547974683543, -1.492817413793104 ], [ 29.777817468354431, -1.492817413793104 ], [ 29.777817468354431, -1.492547931034483 ], [ 29.777547974683543, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33660, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.492547931034483 ], [ 29.779973417721518, -1.493086896551724 ], [ 29.780242911392406, -1.493086896551724 ], [ 29.780242911392406, -1.492547931034483 ], [ 29.779973417721518, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33661, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.492547931034483 ], [ 29.780781898734176, -1.492817413793104 ], [ 29.781051392405065, -1.492817413793104 ], [ 29.781051392405065, -1.492547931034483 ], [ 29.780781898734176, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33662, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.492547931034483 ], [ 29.781051392405065, -1.492817413793104 ], [ 29.78132088607595, -1.492817413793104 ], [ 29.78132088607595, -1.492547931034483 ], [ 29.781051392405065, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33663, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.492547931034483 ], [ 29.78132088607595, -1.493086896551724 ], [ 29.781590379746834, -1.493086896551724 ], [ 29.781590379746834, -1.492547931034483 ], [ 29.78132088607595, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33664, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.492547931034483 ], [ 29.781590379746834, -1.492817413793104 ], [ 29.781859873417723, -1.492817413793104 ], [ 29.781859873417723, -1.492547931034483 ], [ 29.781590379746834, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33665, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.492547931034483 ], [ 29.781859873417723, -1.492817413793104 ], [ 29.782129367088608, -1.492817413793104 ], [ 29.782129367088608, -1.492547931034483 ], [ 29.781859873417723, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33666, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.492547931034483 ], [ 29.782129367088608, -1.492817413793104 ], [ 29.782398860759493, -1.492817413793104 ], [ 29.782398860759493, -1.492547931034483 ], [ 29.782129367088608, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33667, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.492547931034483 ], [ 29.782398860759493, -1.492817413793104 ], [ 29.782668354430381, -1.492817413793104 ], [ 29.782668354430381, -1.492547931034483 ], [ 29.782398860759493, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33668, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.492547931034483 ], [ 29.782668354430381, -1.492817413793104 ], [ 29.782937848101266, -1.492817413793104 ], [ 29.782937848101266, -1.492547931034483 ], [ 29.782668354430381, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33669, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782937848101266, -1.492547931034483 ], [ 29.782937848101266, -1.493086896551724 ], [ 29.783207341772151, -1.493086896551724 ], [ 29.783207341772151, -1.492547931034483 ], [ 29.782937848101266, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33670, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.492817413793104 ], [ 29.77377506329114, -1.493086896551724 ], [ 29.774044556962025, -1.493086896551724 ], [ 29.774044556962025, -1.492817413793104 ], [ 29.77377506329114, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33671, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.492817413793104 ], [ 29.774044556962025, -1.493086896551724 ], [ 29.77431405063291, -1.493086896551724 ], [ 29.77431405063291, -1.492817413793104 ], [ 29.774044556962025, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33672, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.492817413793104 ], [ 29.77431405063291, -1.493086896551724 ], [ 29.774853037974683, -1.493086896551724 ], [ 29.774853037974683, -1.492817413793104 ], [ 29.77431405063291, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33673, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.492817413793104 ], [ 29.774853037974683, -1.493086896551724 ], [ 29.775122531645568, -1.493086896551724 ], [ 29.775122531645568, -1.492817413793104 ], [ 29.774853037974683, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33674, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.492817413793104 ], [ 29.775122531645568, -1.493356379310345 ], [ 29.775392025316457, -1.493356379310345 ], [ 29.775392025316457, -1.492817413793104 ], [ 29.775122531645568, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33675, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.492817413793104 ], [ 29.775392025316457, -1.493086896551724 ], [ 29.775661518987341, -1.493086896551724 ], [ 29.775661518987341, -1.492817413793104 ], [ 29.775392025316457, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33676, "El": 42 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.492817413793104 ], [ 29.775661518987341, -1.493086896551724 ], [ 29.775931012658226, -1.493086896551724 ], [ 29.775931012658226, -1.492817413793104 ], [ 29.775661518987341, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33677, "El": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.492817413793104 ], [ 29.775931012658226, -1.493356379310345 ], [ 29.776200506329115, -1.493356379310345 ], [ 29.776200506329115, -1.492817413793104 ], [ 29.775931012658226, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33678, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.492817413793104 ], [ 29.77647, -1.493086896551724 ], [ 29.776739493670885, -1.493086896551724 ], [ 29.776739493670885, -1.492817413793104 ], [ 29.77647, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33679, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.492817413793104 ], [ 29.776739493670885, -1.493356379310345 ], [ 29.777008987341773, -1.493356379310345 ], [ 29.777008987341773, -1.492817413793104 ], [ 29.776739493670885, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33680, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.492817413793104 ], [ 29.777278481012658, -1.493086896551724 ], [ 29.777547974683543, -1.493086896551724 ], [ 29.777547974683543, -1.492817413793104 ], [ 29.777278481012658, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33681, "El": 65 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.492278448275862 ], [ 29.778086962025316, -1.492278448275862 ], [ 29.778086962025316, -1.493356379310345 ], [ 29.777817468354431, -1.493356379310345 ], [ 29.777817468354431, -1.493086896551724 ], [ 29.777547974683543, -1.493086896551724 ], [ 29.777547974683543, -1.492817413793104 ], [ 29.777817468354431, -1.492817413793104 ], [ 29.777817468354431, -1.492278448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33682, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.492817413793104 ], [ 29.782129367088608, -1.493086896551724 ], [ 29.782398860759493, -1.493086896551724 ], [ 29.782398860759493, -1.492817413793104 ], [ 29.782129367088608, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33683, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.492817413793104 ], [ 29.782398860759493, -1.493086896551724 ], [ 29.782668354430381, -1.493086896551724 ], [ 29.782668354430381, -1.492817413793104 ], [ 29.782398860759493, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33684, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.492817413793104 ], [ 29.782668354430381, -1.493086896551724 ], [ 29.782937848101266, -1.493086896551724 ], [ 29.782937848101266, -1.492817413793104 ], [ 29.782668354430381, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33685, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.493086896551724 ], [ 29.772966582278482, -1.493356379310345 ], [ 29.773236075949367, -1.493356379310345 ], [ 29.773236075949367, -1.493086896551724 ], [ 29.772966582278482, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33686, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.493086896551724 ], [ 29.773236075949367, -1.493356379310345 ], [ 29.773505569620252, -1.493356379310345 ], [ 29.773505569620252, -1.493086896551724 ], [ 29.773236075949367, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33687, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.493086896551724 ], [ 29.773505569620252, -1.493356379310345 ], [ 29.77377506329114, -1.493356379310345 ], [ 29.77377506329114, -1.493086896551724 ], [ 29.773505569620252, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33688, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.493086896551724 ], [ 29.774044556962025, -1.493356379310345 ], [ 29.77431405063291, -1.493356379310345 ], [ 29.77431405063291, -1.493086896551724 ], [ 29.774044556962025, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33689, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.493086896551724 ], [ 29.77431405063291, -1.493356379310345 ], [ 29.774853037974683, -1.493356379310345 ], [ 29.774853037974683, -1.493086896551724 ], [ 29.77431405063291, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33690, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.493086896551724 ], [ 29.777008987341773, -1.493356379310345 ], [ 29.777278481012658, -1.493356379310345 ], [ 29.777278481012658, -1.493086896551724 ], [ 29.777008987341773, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33691, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.493086896551724 ], [ 29.777278481012658, -1.493356379310345 ], [ 29.777547974683543, -1.493356379310345 ], [ 29.777547974683543, -1.493086896551724 ], [ 29.777278481012658, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33692, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.493086896551724 ], [ 29.777547974683543, -1.493356379310345 ], [ 29.777817468354431, -1.493356379310345 ], [ 29.777817468354431, -1.493086896551724 ], [ 29.777547974683543, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33693, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772158101265823, -1.492547931034483 ], [ 29.772158101265823, -1.493625862068966 ], [ 29.772427594936708, -1.493625862068966 ], [ 29.772427594936708, -1.492547931034483 ], [ 29.772158101265823, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33694, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.492547931034483 ], [ 29.778895443037975, -1.49524275862069 ], [ 29.77916493670886, -1.49524275862069 ], [ 29.77916493670886, -1.492547931034483 ], [ 29.778895443037975, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33695, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.492547931034483 ], [ 29.77916493670886, -1.494973275862069 ], [ 29.779434430379748, -1.494973275862069 ], [ 29.779434430379748, -1.492547931034483 ], [ 29.77916493670886, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33696, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.492547931034483 ], [ 29.779703924050633, -1.49524275862069 ], [ 29.779973417721518, -1.49524275862069 ], [ 29.779973417721518, -1.492547931034483 ], [ 29.779703924050633, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33697, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.492547931034483 ], [ 29.780512405063291, -1.49524275862069 ], [ 29.780781898734176, -1.49524275862069 ], [ 29.780781898734176, -1.492547931034483 ], [ 29.780512405063291, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33698, "El": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.492817413793104 ], [ 29.778086962025316, -1.494164827586207 ], [ 29.778356455696201, -1.494164827586207 ], [ 29.778356455696201, -1.492817413793104 ], [ 29.778086962025316, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33699, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.492817413793104 ], [ 29.77862594936709, -1.494973275862069 ], [ 29.778895443037975, -1.494973275862069 ], [ 29.778895443037975, -1.492817413793104 ], [ 29.77862594936709, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33700, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.492817413793104 ], [ 29.779434430379748, -1.49524275862069 ], [ 29.779703924050633, -1.49524275862069 ], [ 29.779703924050633, -1.492817413793104 ], [ 29.779434430379748, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33701, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.492817413793104 ], [ 29.780242911392406, -1.49524275862069 ], [ 29.780512405063291, -1.49524275862069 ], [ 29.780512405063291, -1.492817413793104 ], [ 29.780242911392406, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33702, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.492817413793104 ], [ 29.780781898734176, -1.49551224137931 ], [ 29.781051392405065, -1.49551224137931 ], [ 29.781051392405065, -1.492817413793104 ], [ 29.780781898734176, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33703, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.492817413793104 ], [ 29.781051392405065, -1.49551224137931 ], [ 29.78132088607595, -1.49551224137931 ], [ 29.78132088607595, -1.492817413793104 ], [ 29.781051392405065, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33704, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.493086896551724 ], [ 29.77377506329114, -1.493625862068966 ], [ 29.774044556962025, -1.493625862068966 ], [ 29.774044556962025, -1.493086896551724 ], [ 29.77377506329114, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33705, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.493086896551724 ], [ 29.774853037974683, -1.494164827586207 ], [ 29.775122531645568, -1.494164827586207 ], [ 29.775122531645568, -1.493086896551724 ], [ 29.774853037974683, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33706, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.493086896551724 ], [ 29.775392025316457, -1.493625862068966 ], [ 29.775661518987341, -1.493625862068966 ], [ 29.775661518987341, -1.493086896551724 ], [ 29.775392025316457, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33707, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.493086896551724 ], [ 29.775661518987341, -1.493895344827586 ], [ 29.775931012658226, -1.493895344827586 ], [ 29.775931012658226, -1.493086896551724 ], [ 29.775661518987341, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33708, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.493086896551724 ], [ 29.776200506329115, -1.493625862068966 ], [ 29.77647, -1.493625862068966 ], [ 29.77647, -1.493086896551724 ], [ 29.776200506329115, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33709, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.493086896551724 ], [ 29.77647, -1.493625862068966 ], [ 29.776739493670885, -1.493625862068966 ], [ 29.776739493670885, -1.493086896551724 ], [ 29.77647, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33710, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.493086896551724 ], [ 29.779973417721518, -1.49551224137931 ], [ 29.780242911392406, -1.49551224137931 ], [ 29.780242911392406, -1.493086896551724 ], [ 29.779973417721518, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33711, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.493356379310345 ], [ 29.772427594936708, -1.493625862068966 ], [ 29.772697088607593, -1.493625862068966 ], [ 29.772697088607593, -1.493356379310345 ], [ 29.772427594936708, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33712, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.493356379310345 ], [ 29.772697088607593, -1.493625862068966 ], [ 29.772966582278482, -1.493625862068966 ], [ 29.772966582278482, -1.493356379310345 ], [ 29.772697088607593, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33713, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.493356379310345 ], [ 29.772966582278482, -1.493625862068966 ], [ 29.773236075949367, -1.493625862068966 ], [ 29.773236075949367, -1.493356379310345 ], [ 29.772966582278482, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33714, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.493356379310345 ], [ 29.773236075949367, -1.494703793103448 ], [ 29.773505569620252, -1.494703793103448 ], [ 29.773505569620252, -1.493356379310345 ], [ 29.773236075949367, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33715, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.493356379310345 ], [ 29.773505569620252, -1.494703793103448 ], [ 29.77377506329114, -1.494703793103448 ], [ 29.77377506329114, -1.493356379310345 ], [ 29.773505569620252, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33716, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.493356379310345 ], [ 29.774044556962025, -1.494434310344828 ], [ 29.77431405063291, -1.494434310344828 ], [ 29.77431405063291, -1.493356379310345 ], [ 29.774044556962025, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33717, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.493356379310345 ], [ 29.77431405063291, -1.494434310344828 ], [ 29.774853037974683, -1.494434310344828 ], [ 29.774853037974683, -1.493356379310345 ], [ 29.77431405063291, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33718, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.493356379310345 ], [ 29.775122531645568, -1.494164827586207 ], [ 29.775392025316457, -1.494164827586207 ], [ 29.775392025316457, -1.493356379310345 ], [ 29.775122531645568, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33719, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.493356379310345 ], [ 29.775931012658226, -1.493625862068966 ], [ 29.776200506329115, -1.493625862068966 ], [ 29.776200506329115, -1.493356379310345 ], [ 29.775931012658226, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33720, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.493356379310345 ], [ 29.776739493670885, -1.493625862068966 ], [ 29.777008987341773, -1.493625862068966 ], [ 29.777008987341773, -1.493356379310345 ], [ 29.776739493670885, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33721, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.493356379310345 ], [ 29.777008987341773, -1.493625862068966 ], [ 29.777278481012658, -1.493625862068966 ], [ 29.777278481012658, -1.493356379310345 ], [ 29.777008987341773, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33722, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.493356379310345 ], [ 29.777278481012658, -1.493625862068966 ], [ 29.777547974683543, -1.493625862068966 ], [ 29.777547974683543, -1.493356379310345 ], [ 29.777278481012658, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33723, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.493356379310345 ], [ 29.777547974683543, -1.493625862068966 ], [ 29.777817468354431, -1.493625862068966 ], [ 29.777817468354431, -1.493356379310345 ], [ 29.777547974683543, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33724, "El": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.493356379310345 ], [ 29.777817468354431, -1.493625862068966 ], [ 29.778086962025316, -1.493625862068966 ], [ 29.778086962025316, -1.493356379310345 ], [ 29.777817468354431, -1.493356379310345 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33725, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772427594936708, -1.493625862068966 ], [ 29.772427594936708, -1.49524275862069 ], [ 29.772697088607593, -1.49524275862069 ], [ 29.772697088607593, -1.493625862068966 ], [ 29.772427594936708, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33726, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.493625862068966 ], [ 29.772697088607593, -1.494973275862069 ], [ 29.772966582278482, -1.494973275862069 ], [ 29.772966582278482, -1.493625862068966 ], [ 29.772697088607593, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33727, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.493625862068966 ], [ 29.772966582278482, -1.494973275862069 ], [ 29.773236075949367, -1.494973275862069 ], [ 29.773236075949367, -1.493625862068966 ], [ 29.772966582278482, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33728, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.493625862068966 ], [ 29.77377506329114, -1.494434310344828 ], [ 29.774044556962025, -1.494434310344828 ], [ 29.774044556962025, -1.493625862068966 ], [ 29.77377506329114, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33729, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.493625862068966 ], [ 29.775392025316457, -1.493895344827586 ], [ 29.775661518987341, -1.493895344827586 ], [ 29.775661518987341, -1.493625862068966 ], [ 29.775392025316457, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33730, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.493625862068966 ], [ 29.775931012658226, -1.493895344827586 ], [ 29.776200506329115, -1.493895344827586 ], [ 29.776200506329115, -1.493625862068966 ], [ 29.775931012658226, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33731, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.493625862068966 ], [ 29.776200506329115, -1.493895344827586 ], [ 29.77647, -1.493895344827586 ], [ 29.77647, -1.493625862068966 ], [ 29.776200506329115, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33732, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.493625862068966 ], [ 29.77647, -1.493895344827586 ], [ 29.776739493670885, -1.493895344827586 ], [ 29.776739493670885, -1.493625862068966 ], [ 29.77647, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33733, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.493625862068966 ], [ 29.776739493670885, -1.493895344827586 ], [ 29.777008987341773, -1.493895344827586 ], [ 29.777008987341773, -1.493625862068966 ], [ 29.776739493670885, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33734, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.493625862068966 ], [ 29.777008987341773, -1.493895344827586 ], [ 29.777278481012658, -1.493895344827586 ], [ 29.777278481012658, -1.493625862068966 ], [ 29.777008987341773, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33735, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.493625862068966 ], [ 29.777278481012658, -1.493895344827586 ], [ 29.777547974683543, -1.493895344827586 ], [ 29.777547974683543, -1.493625862068966 ], [ 29.777278481012658, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33736, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.493625862068966 ], [ 29.777547974683543, -1.493895344827586 ], [ 29.777817468354431, -1.493895344827586 ], [ 29.777817468354431, -1.493625862068966 ], [ 29.777547974683543, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33737, "El": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.493625862068966 ], [ 29.777817468354431, -1.493895344827586 ], [ 29.778086962025316, -1.493895344827586 ], [ 29.778086962025316, -1.493625862068966 ], [ 29.777817468354431, -1.493625862068966 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33738, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.493895344827586 ], [ 29.775392025316457, -1.494164827586207 ], [ 29.775661518987341, -1.494164827586207 ], [ 29.775661518987341, -1.493895344827586 ], [ 29.775392025316457, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33739, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.493895344827586 ], [ 29.775661518987341, -1.494164827586207 ], [ 29.775931012658226, -1.494164827586207 ], [ 29.775931012658226, -1.493895344827586 ], [ 29.775661518987341, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33740, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.493895344827586 ], [ 29.775931012658226, -1.494164827586207 ], [ 29.776200506329115, -1.494164827586207 ], [ 29.776200506329115, -1.493895344827586 ], [ 29.775931012658226, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33741, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.493895344827586 ], [ 29.776200506329115, -1.494164827586207 ], [ 29.77647, -1.494164827586207 ], [ 29.77647, -1.493895344827586 ], [ 29.776200506329115, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33742, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.493895344827586 ], [ 29.77647, -1.494164827586207 ], [ 29.776739493670885, -1.494164827586207 ], [ 29.776739493670885, -1.493895344827586 ], [ 29.77647, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33743, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.493895344827586 ], [ 29.776739493670885, -1.494164827586207 ], [ 29.777008987341773, -1.494164827586207 ], [ 29.777008987341773, -1.493895344827586 ], [ 29.776739493670885, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33744, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.493895344827586 ], [ 29.777008987341773, -1.494164827586207 ], [ 29.777278481012658, -1.494164827586207 ], [ 29.777278481012658, -1.493895344827586 ], [ 29.777008987341773, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33745, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.493895344827586 ], [ 29.777278481012658, -1.494164827586207 ], [ 29.777547974683543, -1.494164827586207 ], [ 29.777547974683543, -1.493895344827586 ], [ 29.777278481012658, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33746, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.493895344827586 ], [ 29.777547974683543, -1.494164827586207 ], [ 29.777817468354431, -1.494164827586207 ], [ 29.777817468354431, -1.493895344827586 ], [ 29.777547974683543, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33747, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.493895344827586 ], [ 29.777817468354431, -1.494164827586207 ], [ 29.778086962025316, -1.494164827586207 ], [ 29.778086962025316, -1.493895344827586 ], [ 29.777817468354431, -1.493895344827586 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33748, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.494164827586207 ], [ 29.774853037974683, -1.494434310344828 ], [ 29.775122531645568, -1.494434310344828 ], [ 29.775122531645568, -1.494164827586207 ], [ 29.774853037974683, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33749, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.494164827586207 ], [ 29.775122531645568, -1.494434310344828 ], [ 29.775392025316457, -1.494434310344828 ], [ 29.775392025316457, -1.494164827586207 ], [ 29.775122531645568, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33750, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.494164827586207 ], [ 29.775392025316457, -1.494434310344828 ], [ 29.775661518987341, -1.494434310344828 ], [ 29.775661518987341, -1.494164827586207 ], [ 29.775392025316457, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33751, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.494164827586207 ], [ 29.775661518987341, -1.494434310344828 ], [ 29.775931012658226, -1.494434310344828 ], [ 29.775931012658226, -1.494164827586207 ], [ 29.775661518987341, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33752, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.494164827586207 ], [ 29.775931012658226, -1.494434310344828 ], [ 29.776200506329115, -1.494434310344828 ], [ 29.776200506329115, -1.494164827586207 ], [ 29.775931012658226, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33753, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.494164827586207 ], [ 29.776200506329115, -1.494434310344828 ], [ 29.77647, -1.494434310344828 ], [ 29.77647, -1.494164827586207 ], [ 29.776200506329115, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33754, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.494164827586207 ], [ 29.77647, -1.494434310344828 ], [ 29.776739493670885, -1.494434310344828 ], [ 29.776739493670885, -1.494164827586207 ], [ 29.77647, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33755, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.494164827586207 ], [ 29.776739493670885, -1.494434310344828 ], [ 29.777008987341773, -1.494434310344828 ], [ 29.777008987341773, -1.494164827586207 ], [ 29.776739493670885, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33756, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.494164827586207 ], [ 29.777008987341773, -1.494434310344828 ], [ 29.777278481012658, -1.494434310344828 ], [ 29.777278481012658, -1.494164827586207 ], [ 29.777008987341773, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33757, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.494164827586207 ], [ 29.777278481012658, -1.494434310344828 ], [ 29.777547974683543, -1.494434310344828 ], [ 29.777547974683543, -1.494164827586207 ], [ 29.777278481012658, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33758, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.494164827586207 ], [ 29.777547974683543, -1.494434310344828 ], [ 29.777817468354431, -1.494434310344828 ], [ 29.777817468354431, -1.494164827586207 ], [ 29.777547974683543, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33759, "El": 56 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.494164827586207 ], [ 29.777817468354431, -1.494434310344828 ], [ 29.778086962025316, -1.494434310344828 ], [ 29.778086962025316, -1.494164827586207 ], [ 29.777817468354431, -1.494164827586207 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33760, "El": 58 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.492547931034483 ], [ 29.77862594936709, -1.492547931034483 ], [ 29.77862594936709, -1.494973275862069 ], [ 29.778356455696201, -1.494973275862069 ], [ 29.778356455696201, -1.494434310344828 ], [ 29.778086962025316, -1.494434310344828 ], [ 29.778086962025316, -1.494164827586207 ], [ 29.778356455696201, -1.494164827586207 ], [ 29.778356455696201, -1.492547931034483 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33761, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.494434310344828 ], [ 29.77377506329114, -1.494703793103448 ], [ 29.774044556962025, -1.494703793103448 ], [ 29.774044556962025, -1.494434310344828 ], [ 29.77377506329114, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33762, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.494434310344828 ], [ 29.774044556962025, -1.494703793103448 ], [ 29.77431405063291, -1.494703793103448 ], [ 29.77431405063291, -1.494434310344828 ], [ 29.774044556962025, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33763, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.494434310344828 ], [ 29.77431405063291, -1.494703793103448 ], [ 29.774853037974683, -1.494703793103448 ], [ 29.774853037974683, -1.494434310344828 ], [ 29.77431405063291, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33764, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.494434310344828 ], [ 29.774853037974683, -1.494703793103448 ], [ 29.775122531645568, -1.494703793103448 ], [ 29.775122531645568, -1.494434310344828 ], [ 29.774853037974683, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33765, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.494434310344828 ], [ 29.775122531645568, -1.494703793103448 ], [ 29.775392025316457, -1.494703793103448 ], [ 29.775392025316457, -1.494434310344828 ], [ 29.775122531645568, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33766, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.494434310344828 ], [ 29.775392025316457, -1.494703793103448 ], [ 29.775661518987341, -1.494703793103448 ], [ 29.775661518987341, -1.494434310344828 ], [ 29.775392025316457, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33767, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.494434310344828 ], [ 29.775661518987341, -1.494703793103448 ], [ 29.775931012658226, -1.494703793103448 ], [ 29.775931012658226, -1.494434310344828 ], [ 29.775661518987341, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33768, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.494434310344828 ], [ 29.775931012658226, -1.494703793103448 ], [ 29.776200506329115, -1.494703793103448 ], [ 29.776200506329115, -1.494434310344828 ], [ 29.775931012658226, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33769, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.494434310344828 ], [ 29.776200506329115, -1.494703793103448 ], [ 29.77647, -1.494703793103448 ], [ 29.77647, -1.494434310344828 ], [ 29.776200506329115, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33770, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.494434310344828 ], [ 29.77647, -1.494703793103448 ], [ 29.776739493670885, -1.494703793103448 ], [ 29.776739493670885, -1.494434310344828 ], [ 29.77647, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33771, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.494434310344828 ], [ 29.776739493670885, -1.494703793103448 ], [ 29.777008987341773, -1.494703793103448 ], [ 29.777008987341773, -1.494434310344828 ], [ 29.776739493670885, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33772, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.494434310344828 ], [ 29.777008987341773, -1.494703793103448 ], [ 29.777278481012658, -1.494703793103448 ], [ 29.777278481012658, -1.494434310344828 ], [ 29.777008987341773, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33773, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.494434310344828 ], [ 29.777278481012658, -1.494703793103448 ], [ 29.777547974683543, -1.494703793103448 ], [ 29.777547974683543, -1.494434310344828 ], [ 29.777278481012658, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33774, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.494434310344828 ], [ 29.777547974683543, -1.494703793103448 ], [ 29.777817468354431, -1.494703793103448 ], [ 29.777817468354431, -1.494434310344828 ], [ 29.777547974683543, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33775, "El": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.494434310344828 ], [ 29.777817468354431, -1.494703793103448 ], [ 29.778086962025316, -1.494703793103448 ], [ 29.778086962025316, -1.494434310344828 ], [ 29.777817468354431, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33776, "El": 57 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.494434310344828 ], [ 29.778086962025316, -1.494703793103448 ], [ 29.778356455696201, -1.494703793103448 ], [ 29.778356455696201, -1.494434310344828 ], [ 29.778086962025316, -1.494434310344828 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33777, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.494703793103448 ], [ 29.773236075949367, -1.494973275862069 ], [ 29.773505569620252, -1.494973275862069 ], [ 29.773505569620252, -1.494703793103448 ], [ 29.773236075949367, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33778, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.494703793103448 ], [ 29.773505569620252, -1.494973275862069 ], [ 29.77377506329114, -1.494973275862069 ], [ 29.77377506329114, -1.494703793103448 ], [ 29.773505569620252, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33779, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.494703793103448 ], [ 29.77377506329114, -1.494973275862069 ], [ 29.774044556962025, -1.494973275862069 ], [ 29.774044556962025, -1.494703793103448 ], [ 29.77377506329114, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33780, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.494703793103448 ], [ 29.774044556962025, -1.494973275862069 ], [ 29.77431405063291, -1.494973275862069 ], [ 29.77431405063291, -1.494703793103448 ], [ 29.774044556962025, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33781, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.494703793103448 ], [ 29.77431405063291, -1.494973275862069 ], [ 29.774853037974683, -1.494973275862069 ], [ 29.774853037974683, -1.494703793103448 ], [ 29.77431405063291, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33782, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.494703793103448 ], [ 29.774853037974683, -1.494973275862069 ], [ 29.775122531645568, -1.494973275862069 ], [ 29.775122531645568, -1.494703793103448 ], [ 29.774853037974683, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33783, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.494703793103448 ], [ 29.775122531645568, -1.494973275862069 ], [ 29.775392025316457, -1.494973275862069 ], [ 29.775392025316457, -1.494703793103448 ], [ 29.775122531645568, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33784, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.494703793103448 ], [ 29.775392025316457, -1.494973275862069 ], [ 29.775661518987341, -1.494973275862069 ], [ 29.775661518987341, -1.494703793103448 ], [ 29.775392025316457, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33785, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.494703793103448 ], [ 29.775661518987341, -1.494973275862069 ], [ 29.775931012658226, -1.494973275862069 ], [ 29.775931012658226, -1.494703793103448 ], [ 29.775661518987341, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33786, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.494703793103448 ], [ 29.775931012658226, -1.494973275862069 ], [ 29.776200506329115, -1.494973275862069 ], [ 29.776200506329115, -1.494703793103448 ], [ 29.775931012658226, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33787, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.494703793103448 ], [ 29.776200506329115, -1.494973275862069 ], [ 29.77647, -1.494973275862069 ], [ 29.77647, -1.494703793103448 ], [ 29.776200506329115, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33788, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.494703793103448 ], [ 29.77647, -1.494973275862069 ], [ 29.776739493670885, -1.494973275862069 ], [ 29.776739493670885, -1.494703793103448 ], [ 29.77647, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33789, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.494703793103448 ], [ 29.776739493670885, -1.494973275862069 ], [ 29.777008987341773, -1.494973275862069 ], [ 29.777008987341773, -1.494703793103448 ], [ 29.776739493670885, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33790, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.494703793103448 ], [ 29.777008987341773, -1.494973275862069 ], [ 29.777278481012658, -1.494973275862069 ], [ 29.777278481012658, -1.494703793103448 ], [ 29.777008987341773, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33791, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.494703793103448 ], [ 29.777278481012658, -1.494973275862069 ], [ 29.777547974683543, -1.494973275862069 ], [ 29.777547974683543, -1.494703793103448 ], [ 29.777278481012658, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33792, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.494703793103448 ], [ 29.777547974683543, -1.494973275862069 ], [ 29.777817468354431, -1.494973275862069 ], [ 29.777817468354431, -1.494703793103448 ], [ 29.777547974683543, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33793, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.494703793103448 ], [ 29.777817468354431, -1.494973275862069 ], [ 29.778086962025316, -1.494973275862069 ], [ 29.778086962025316, -1.494703793103448 ], [ 29.777817468354431, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33794, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.494703793103448 ], [ 29.778086962025316, -1.494973275862069 ], [ 29.778356455696201, -1.494973275862069 ], [ 29.778356455696201, -1.494703793103448 ], [ 29.778086962025316, -1.494703793103448 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33795, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.494973275862069 ], [ 29.772697088607593, -1.49524275862069 ], [ 29.772966582278482, -1.49524275862069 ], [ 29.772966582278482, -1.494973275862069 ], [ 29.772697088607593, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33796, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.494973275862069 ], [ 29.772966582278482, -1.49524275862069 ], [ 29.773236075949367, -1.49524275862069 ], [ 29.773236075949367, -1.494973275862069 ], [ 29.772966582278482, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33797, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.494973275862069 ], [ 29.773236075949367, -1.49524275862069 ], [ 29.773505569620252, -1.49524275862069 ], [ 29.773505569620252, -1.494973275862069 ], [ 29.773236075949367, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33798, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.494973275862069 ], [ 29.773505569620252, -1.49524275862069 ], [ 29.77377506329114, -1.49524275862069 ], [ 29.77377506329114, -1.494973275862069 ], [ 29.773505569620252, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33799, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.494973275862069 ], [ 29.77377506329114, -1.49524275862069 ], [ 29.774044556962025, -1.49524275862069 ], [ 29.774044556962025, -1.494973275862069 ], [ 29.77377506329114, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33800, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.494973275862069 ], [ 29.774044556962025, -1.49524275862069 ], [ 29.77431405063291, -1.49524275862069 ], [ 29.77431405063291, -1.494973275862069 ], [ 29.774044556962025, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33801, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.494973275862069 ], [ 29.77431405063291, -1.49524275862069 ], [ 29.774853037974683, -1.49524275862069 ], [ 29.774853037974683, -1.494973275862069 ], [ 29.77431405063291, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33802, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.494973275862069 ], [ 29.774853037974683, -1.49524275862069 ], [ 29.775122531645568, -1.49524275862069 ], [ 29.775122531645568, -1.494973275862069 ], [ 29.774853037974683, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33803, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.494973275862069 ], [ 29.775122531645568, -1.49524275862069 ], [ 29.775392025316457, -1.49524275862069 ], [ 29.775392025316457, -1.494973275862069 ], [ 29.775122531645568, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33804, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.494973275862069 ], [ 29.775392025316457, -1.49524275862069 ], [ 29.775661518987341, -1.49524275862069 ], [ 29.775661518987341, -1.494973275862069 ], [ 29.775392025316457, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33805, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.494973275862069 ], [ 29.775661518987341, -1.49524275862069 ], [ 29.775931012658226, -1.49524275862069 ], [ 29.775931012658226, -1.494973275862069 ], [ 29.775661518987341, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33806, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.494973275862069 ], [ 29.775931012658226, -1.49524275862069 ], [ 29.776200506329115, -1.49524275862069 ], [ 29.776200506329115, -1.494973275862069 ], [ 29.775931012658226, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33807, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.494973275862069 ], [ 29.776200506329115, -1.49524275862069 ], [ 29.77647, -1.49524275862069 ], [ 29.77647, -1.494973275862069 ], [ 29.776200506329115, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33808, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.494973275862069 ], [ 29.77647, -1.49524275862069 ], [ 29.776739493670885, -1.49524275862069 ], [ 29.776739493670885, -1.494973275862069 ], [ 29.77647, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33809, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.494973275862069 ], [ 29.776739493670885, -1.49524275862069 ], [ 29.777008987341773, -1.49524275862069 ], [ 29.777008987341773, -1.494973275862069 ], [ 29.776739493670885, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33810, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.494973275862069 ], [ 29.777008987341773, -1.49524275862069 ], [ 29.777278481012658, -1.49524275862069 ], [ 29.777278481012658, -1.494973275862069 ], [ 29.777008987341773, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33811, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.494973275862069 ], [ 29.777278481012658, -1.49524275862069 ], [ 29.777547974683543, -1.49524275862069 ], [ 29.777547974683543, -1.494973275862069 ], [ 29.777278481012658, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33812, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.494973275862069 ], [ 29.777547974683543, -1.49524275862069 ], [ 29.777817468354431, -1.49524275862069 ], [ 29.777817468354431, -1.494973275862069 ], [ 29.777547974683543, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33813, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.494973275862069 ], [ 29.777817468354431, -1.49524275862069 ], [ 29.778086962025316, -1.49524275862069 ], [ 29.778086962025316, -1.494973275862069 ], [ 29.777817468354431, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33814, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.494973275862069 ], [ 29.778086962025316, -1.49524275862069 ], [ 29.778356455696201, -1.49524275862069 ], [ 29.778356455696201, -1.494973275862069 ], [ 29.778086962025316, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33815, "El": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.494973275862069 ], [ 29.778356455696201, -1.49524275862069 ], [ 29.77862594936709, -1.49524275862069 ], [ 29.77862594936709, -1.494973275862069 ], [ 29.778356455696201, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33816, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.494973275862069 ], [ 29.77862594936709, -1.49524275862069 ], [ 29.778895443037975, -1.49524275862069 ], [ 29.778895443037975, -1.494973275862069 ], [ 29.77862594936709, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33817, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.494973275862069 ], [ 29.77916493670886, -1.49524275862069 ], [ 29.779434430379748, -1.49524275862069 ], [ 29.779434430379748, -1.494973275862069 ], [ 29.77916493670886, -1.494973275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33818, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772697088607593, -1.49524275862069 ], [ 29.772697088607593, -1.49551224137931 ], [ 29.772966582278482, -1.49551224137931 ], [ 29.772966582278482, -1.49524275862069 ], [ 29.772697088607593, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33819, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.49524275862069 ], [ 29.772966582278482, -1.49551224137931 ], [ 29.773236075949367, -1.49551224137931 ], [ 29.773236075949367, -1.49524275862069 ], [ 29.772966582278482, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33820, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.49524275862069 ], [ 29.773236075949367, -1.49551224137931 ], [ 29.773505569620252, -1.49551224137931 ], [ 29.773505569620252, -1.49524275862069 ], [ 29.773236075949367, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33821, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.49524275862069 ], [ 29.773505569620252, -1.49551224137931 ], [ 29.77377506329114, -1.49551224137931 ], [ 29.77377506329114, -1.49524275862069 ], [ 29.773505569620252, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33822, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.49524275862069 ], [ 29.77377506329114, -1.49551224137931 ], [ 29.774044556962025, -1.49551224137931 ], [ 29.774044556962025, -1.49524275862069 ], [ 29.77377506329114, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33823, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.49524275862069 ], [ 29.774044556962025, -1.49551224137931 ], [ 29.77431405063291, -1.49551224137931 ], [ 29.77431405063291, -1.49524275862069 ], [ 29.774044556962025, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33824, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.49524275862069 ], [ 29.77431405063291, -1.49551224137931 ], [ 29.774853037974683, -1.49551224137931 ], [ 29.774853037974683, -1.49524275862069 ], [ 29.77431405063291, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33825, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.49524275862069 ], [ 29.774853037974683, -1.49551224137931 ], [ 29.775122531645568, -1.49551224137931 ], [ 29.775122531645568, -1.49524275862069 ], [ 29.774853037974683, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33826, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.49524275862069 ], [ 29.775122531645568, -1.49551224137931 ], [ 29.775392025316457, -1.49551224137931 ], [ 29.775392025316457, -1.49524275862069 ], [ 29.775122531645568, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33827, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.49524275862069 ], [ 29.775392025316457, -1.49551224137931 ], [ 29.775661518987341, -1.49551224137931 ], [ 29.775661518987341, -1.49524275862069 ], [ 29.775392025316457, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33828, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.49524275862069 ], [ 29.775661518987341, -1.49551224137931 ], [ 29.775931012658226, -1.49551224137931 ], [ 29.775931012658226, -1.49524275862069 ], [ 29.775661518987341, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33829, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.49524275862069 ], [ 29.775931012658226, -1.49551224137931 ], [ 29.776200506329115, -1.49551224137931 ], [ 29.776200506329115, -1.49524275862069 ], [ 29.775931012658226, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33830, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.49524275862069 ], [ 29.776200506329115, -1.49551224137931 ], [ 29.77647, -1.49551224137931 ], [ 29.77647, -1.49524275862069 ], [ 29.776200506329115, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33831, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.49524275862069 ], [ 29.77647, -1.49551224137931 ], [ 29.776739493670885, -1.49551224137931 ], [ 29.776739493670885, -1.49524275862069 ], [ 29.77647, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33832, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.49524275862069 ], [ 29.776739493670885, -1.49551224137931 ], [ 29.777008987341773, -1.49551224137931 ], [ 29.777008987341773, -1.49524275862069 ], [ 29.776739493670885, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33833, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.49524275862069 ], [ 29.777008987341773, -1.49551224137931 ], [ 29.777278481012658, -1.49551224137931 ], [ 29.777278481012658, -1.49524275862069 ], [ 29.777008987341773, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33834, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.49524275862069 ], [ 29.777278481012658, -1.49551224137931 ], [ 29.777547974683543, -1.49551224137931 ], [ 29.777547974683543, -1.49524275862069 ], [ 29.777278481012658, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33835, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.49524275862069 ], [ 29.777547974683543, -1.49551224137931 ], [ 29.777817468354431, -1.49551224137931 ], [ 29.777817468354431, -1.49524275862069 ], [ 29.777547974683543, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33836, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.49524275862069 ], [ 29.777817468354431, -1.49551224137931 ], [ 29.778086962025316, -1.49551224137931 ], [ 29.778086962025316, -1.49524275862069 ], [ 29.777817468354431, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33837, "El": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.49524275862069 ], [ 29.778086962025316, -1.49551224137931 ], [ 29.778356455696201, -1.49551224137931 ], [ 29.778356455696201, -1.49524275862069 ], [ 29.778086962025316, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33838, "El": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.49524275862069 ], [ 29.778356455696201, -1.49551224137931 ], [ 29.77862594936709, -1.49551224137931 ], [ 29.77862594936709, -1.49524275862069 ], [ 29.778356455696201, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33839, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.49524275862069 ], [ 29.77862594936709, -1.49551224137931 ], [ 29.778895443037975, -1.49551224137931 ], [ 29.778895443037975, -1.49524275862069 ], [ 29.77862594936709, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33840, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.49524275862069 ], [ 29.778895443037975, -1.49551224137931 ], [ 29.77916493670886, -1.49551224137931 ], [ 29.77916493670886, -1.49524275862069 ], [ 29.778895443037975, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33841, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.49524275862069 ], [ 29.77916493670886, -1.49551224137931 ], [ 29.779434430379748, -1.49551224137931 ], [ 29.779434430379748, -1.49524275862069 ], [ 29.77916493670886, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33842, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.49524275862069 ], [ 29.779434430379748, -1.49551224137931 ], [ 29.779703924050633, -1.49551224137931 ], [ 29.779703924050633, -1.49524275862069 ], [ 29.779434430379748, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33843, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.49524275862069 ], [ 29.779703924050633, -1.49551224137931 ], [ 29.779973417721518, -1.49551224137931 ], [ 29.779973417721518, -1.49524275862069 ], [ 29.779703924050633, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33844, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.49524275862069 ], [ 29.780242911392406, -1.49551224137931 ], [ 29.780512405063291, -1.49551224137931 ], [ 29.780512405063291, -1.49524275862069 ], [ 29.780242911392406, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33845, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.49524275862069 ], [ 29.780512405063291, -1.49551224137931 ], [ 29.780781898734176, -1.49551224137931 ], [ 29.780781898734176, -1.49524275862069 ], [ 29.780512405063291, -1.49524275862069 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33846, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.492817413793104 ], [ 29.781590379746834, -1.495781724137931 ], [ 29.781859873417723, -1.495781724137931 ], [ 29.781859873417723, -1.492817413793104 ], [ 29.781590379746834, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33847, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.492817413793104 ], [ 29.781859873417723, -1.495781724137931 ], [ 29.782129367088608, -1.495781724137931 ], [ 29.782129367088608, -1.492817413793104 ], [ 29.781859873417723, -1.492817413793104 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33848, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.493086896551724 ], [ 29.78132088607595, -1.495781724137931 ], [ 29.781590379746834, -1.495781724137931 ], [ 29.781590379746834, -1.493086896551724 ], [ 29.78132088607595, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33849, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.493086896551724 ], [ 29.782129367088608, -1.496051206896552 ], [ 29.782398860759493, -1.496051206896552 ], [ 29.782398860759493, -1.493086896551724 ], [ 29.782129367088608, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33850, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.493086896551724 ], [ 29.782398860759493, -1.496051206896552 ], [ 29.782668354430381, -1.496051206896552 ], [ 29.782668354430381, -1.493086896551724 ], [ 29.782398860759493, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33851, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782668354430381, -1.493086896551724 ], [ 29.782668354430381, -1.496320689655172 ], [ 29.782937848101266, -1.496320689655172 ], [ 29.782937848101266, -1.493086896551724 ], [ 29.782668354430381, -1.493086896551724 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33852, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.772966582278482, -1.49551224137931 ], [ 29.772966582278482, -1.495781724137931 ], [ 29.773236075949367, -1.495781724137931 ], [ 29.773236075949367, -1.49551224137931 ], [ 29.772966582278482, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33853, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.49551224137931 ], [ 29.773236075949367, -1.495781724137931 ], [ 29.773505569620252, -1.495781724137931 ], [ 29.773505569620252, -1.49551224137931 ], [ 29.773236075949367, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33854, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.49551224137931 ], [ 29.773505569620252, -1.495781724137931 ], [ 29.77377506329114, -1.495781724137931 ], [ 29.77377506329114, -1.49551224137931 ], [ 29.773505569620252, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33855, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.49551224137931 ], [ 29.77377506329114, -1.495781724137931 ], [ 29.774044556962025, -1.495781724137931 ], [ 29.774044556962025, -1.49551224137931 ], [ 29.77377506329114, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33856, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.49551224137931 ], [ 29.774044556962025, -1.495781724137931 ], [ 29.77431405063291, -1.495781724137931 ], [ 29.77431405063291, -1.49551224137931 ], [ 29.774044556962025, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33857, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.49551224137931 ], [ 29.77431405063291, -1.495781724137931 ], [ 29.774853037974683, -1.495781724137931 ], [ 29.774853037974683, -1.49551224137931 ], [ 29.77431405063291, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33858, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.49551224137931 ], [ 29.774853037974683, -1.495781724137931 ], [ 29.775122531645568, -1.495781724137931 ], [ 29.775122531645568, -1.49551224137931 ], [ 29.774853037974683, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33859, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.49551224137931 ], [ 29.775122531645568, -1.495781724137931 ], [ 29.775392025316457, -1.495781724137931 ], [ 29.775392025316457, -1.49551224137931 ], [ 29.775122531645568, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33860, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.49551224137931 ], [ 29.775392025316457, -1.495781724137931 ], [ 29.775661518987341, -1.495781724137931 ], [ 29.775661518987341, -1.49551224137931 ], [ 29.775392025316457, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33861, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.49551224137931 ], [ 29.775661518987341, -1.495781724137931 ], [ 29.775931012658226, -1.495781724137931 ], [ 29.775931012658226, -1.49551224137931 ], [ 29.775661518987341, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33862, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.49551224137931 ], [ 29.775931012658226, -1.495781724137931 ], [ 29.776200506329115, -1.495781724137931 ], [ 29.776200506329115, -1.49551224137931 ], [ 29.775931012658226, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33863, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.49551224137931 ], [ 29.776200506329115, -1.495781724137931 ], [ 29.77647, -1.495781724137931 ], [ 29.77647, -1.49551224137931 ], [ 29.776200506329115, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33864, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.49551224137931 ], [ 29.77647, -1.495781724137931 ], [ 29.776739493670885, -1.495781724137931 ], [ 29.776739493670885, -1.49551224137931 ], [ 29.77647, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33865, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.49551224137931 ], [ 29.776739493670885, -1.495781724137931 ], [ 29.777008987341773, -1.495781724137931 ], [ 29.777008987341773, -1.49551224137931 ], [ 29.776739493670885, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33866, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.49551224137931 ], [ 29.777008987341773, -1.495781724137931 ], [ 29.777278481012658, -1.495781724137931 ], [ 29.777278481012658, -1.49551224137931 ], [ 29.777008987341773, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33867, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.49551224137931 ], [ 29.777278481012658, -1.495781724137931 ], [ 29.777547974683543, -1.495781724137931 ], [ 29.777547974683543, -1.49551224137931 ], [ 29.777278481012658, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33868, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.49551224137931 ], [ 29.777547974683543, -1.495781724137931 ], [ 29.777817468354431, -1.495781724137931 ], [ 29.777817468354431, -1.49551224137931 ], [ 29.777547974683543, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33869, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.49551224137931 ], [ 29.777817468354431, -1.495781724137931 ], [ 29.778086962025316, -1.495781724137931 ], [ 29.778086962025316, -1.49551224137931 ], [ 29.777817468354431, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33870, "El": 50 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.49551224137931 ], [ 29.778086962025316, -1.495781724137931 ], [ 29.778356455696201, -1.495781724137931 ], [ 29.778356455696201, -1.49551224137931 ], [ 29.778086962025316, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33871, "El": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.49551224137931 ], [ 29.778356455696201, -1.495781724137931 ], [ 29.77862594936709, -1.495781724137931 ], [ 29.77862594936709, -1.49551224137931 ], [ 29.778356455696201, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33872, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.49551224137931 ], [ 29.77862594936709, -1.495781724137931 ], [ 29.778895443037975, -1.495781724137931 ], [ 29.778895443037975, -1.49551224137931 ], [ 29.77862594936709, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33873, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.49551224137931 ], [ 29.778895443037975, -1.495781724137931 ], [ 29.77916493670886, -1.495781724137931 ], [ 29.77916493670886, -1.49551224137931 ], [ 29.778895443037975, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33874, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.49551224137931 ], [ 29.77916493670886, -1.495781724137931 ], [ 29.779434430379748, -1.495781724137931 ], [ 29.779434430379748, -1.49551224137931 ], [ 29.77916493670886, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33875, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.49551224137931 ], [ 29.779434430379748, -1.495781724137931 ], [ 29.779703924050633, -1.495781724137931 ], [ 29.779703924050633, -1.49551224137931 ], [ 29.779434430379748, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33876, "El": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.49551224137931 ], [ 29.779703924050633, -1.495781724137931 ], [ 29.779973417721518, -1.495781724137931 ], [ 29.779973417721518, -1.49551224137931 ], [ 29.779703924050633, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33877, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.49551224137931 ], [ 29.779973417721518, -1.495781724137931 ], [ 29.780242911392406, -1.495781724137931 ], [ 29.780242911392406, -1.49551224137931 ], [ 29.779973417721518, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33878, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.49551224137931 ], [ 29.780242911392406, -1.495781724137931 ], [ 29.780512405063291, -1.495781724137931 ], [ 29.780512405063291, -1.49551224137931 ], [ 29.780242911392406, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33879, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.49551224137931 ], [ 29.780512405063291, -1.495781724137931 ], [ 29.780781898734176, -1.495781724137931 ], [ 29.780781898734176, -1.49551224137931 ], [ 29.780512405063291, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33880, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.49551224137931 ], [ 29.780781898734176, -1.495781724137931 ], [ 29.781051392405065, -1.495781724137931 ], [ 29.781051392405065, -1.49551224137931 ], [ 29.780781898734176, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33881, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.49551224137931 ], [ 29.781051392405065, -1.495781724137931 ], [ 29.78132088607595, -1.495781724137931 ], [ 29.78132088607595, -1.49551224137931 ], [ 29.781051392405065, -1.49551224137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33882, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773236075949367, -1.495781724137931 ], [ 29.773236075949367, -1.496051206896552 ], [ 29.773505569620252, -1.496051206896552 ], [ 29.773505569620252, -1.495781724137931 ], [ 29.773236075949367, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33883, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.495781724137931 ], [ 29.773505569620252, -1.496051206896552 ], [ 29.77377506329114, -1.496051206896552 ], [ 29.77377506329114, -1.495781724137931 ], [ 29.773505569620252, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33884, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.495781724137931 ], [ 29.77377506329114, -1.496051206896552 ], [ 29.774044556962025, -1.496051206896552 ], [ 29.774044556962025, -1.495781724137931 ], [ 29.77377506329114, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33885, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.495781724137931 ], [ 29.774044556962025, -1.496051206896552 ], [ 29.77431405063291, -1.496051206896552 ], [ 29.77431405063291, -1.495781724137931 ], [ 29.774044556962025, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33886, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.495781724137931 ], [ 29.77431405063291, -1.496051206896552 ], [ 29.774853037974683, -1.496051206896552 ], [ 29.774853037974683, -1.495781724137931 ], [ 29.77431405063291, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33887, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.495781724137931 ], [ 29.774853037974683, -1.496051206896552 ], [ 29.775122531645568, -1.496051206896552 ], [ 29.775122531645568, -1.495781724137931 ], [ 29.774853037974683, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33888, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.495781724137931 ], [ 29.775122531645568, -1.496051206896552 ], [ 29.775392025316457, -1.496051206896552 ], [ 29.775392025316457, -1.495781724137931 ], [ 29.775122531645568, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33889, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.495781724137931 ], [ 29.775392025316457, -1.496051206896552 ], [ 29.775661518987341, -1.496051206896552 ], [ 29.775661518987341, -1.495781724137931 ], [ 29.775392025316457, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33890, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.495781724137931 ], [ 29.775661518987341, -1.496051206896552 ], [ 29.775931012658226, -1.496051206896552 ], [ 29.775931012658226, -1.495781724137931 ], [ 29.775661518987341, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33891, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.495781724137931 ], [ 29.775931012658226, -1.496051206896552 ], [ 29.776200506329115, -1.496051206896552 ], [ 29.776200506329115, -1.495781724137931 ], [ 29.775931012658226, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33892, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.495781724137931 ], [ 29.776200506329115, -1.496051206896552 ], [ 29.77647, -1.496051206896552 ], [ 29.77647, -1.495781724137931 ], [ 29.776200506329115, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33893, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.495781724137931 ], [ 29.77647, -1.496051206896552 ], [ 29.776739493670885, -1.496051206896552 ], [ 29.776739493670885, -1.495781724137931 ], [ 29.77647, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33894, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.495781724137931 ], [ 29.776739493670885, -1.496051206896552 ], [ 29.777008987341773, -1.496051206896552 ], [ 29.777008987341773, -1.495781724137931 ], [ 29.776739493670885, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33895, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.495781724137931 ], [ 29.777008987341773, -1.496051206896552 ], [ 29.777278481012658, -1.496051206896552 ], [ 29.777278481012658, -1.495781724137931 ], [ 29.777008987341773, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33896, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.495781724137931 ], [ 29.777278481012658, -1.496051206896552 ], [ 29.777547974683543, -1.496051206896552 ], [ 29.777547974683543, -1.495781724137931 ], [ 29.777278481012658, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33897, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.495781724137931 ], [ 29.777547974683543, -1.496051206896552 ], [ 29.777817468354431, -1.496051206896552 ], [ 29.777817468354431, -1.495781724137931 ], [ 29.777547974683543, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33898, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.495781724137931 ], [ 29.777817468354431, -1.496051206896552 ], [ 29.778086962025316, -1.496051206896552 ], [ 29.778086962025316, -1.495781724137931 ], [ 29.777817468354431, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33899, "El": 49 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.495781724137931 ], [ 29.778086962025316, -1.496051206896552 ], [ 29.778356455696201, -1.496051206896552 ], [ 29.778356455696201, -1.495781724137931 ], [ 29.778086962025316, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33900, "El": 48 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.495781724137931 ], [ 29.778356455696201, -1.496051206896552 ], [ 29.77862594936709, -1.496051206896552 ], [ 29.77862594936709, -1.495781724137931 ], [ 29.778356455696201, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33901, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.495781724137931 ], [ 29.77862594936709, -1.496051206896552 ], [ 29.778895443037975, -1.496051206896552 ], [ 29.778895443037975, -1.495781724137931 ], [ 29.77862594936709, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33902, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.495781724137931 ], [ 29.778895443037975, -1.496051206896552 ], [ 29.77916493670886, -1.496051206896552 ], [ 29.77916493670886, -1.495781724137931 ], [ 29.778895443037975, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33903, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.495781724137931 ], [ 29.77916493670886, -1.496051206896552 ], [ 29.779434430379748, -1.496051206896552 ], [ 29.779434430379748, -1.495781724137931 ], [ 29.77916493670886, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33904, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.495781724137931 ], [ 29.779434430379748, -1.496051206896552 ], [ 29.779703924050633, -1.496051206896552 ], [ 29.779703924050633, -1.495781724137931 ], [ 29.779434430379748, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33905, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.495781724137931 ], [ 29.779703924050633, -1.496051206896552 ], [ 29.779973417721518, -1.496051206896552 ], [ 29.779973417721518, -1.495781724137931 ], [ 29.779703924050633, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33906, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.495781724137931 ], [ 29.779973417721518, -1.496051206896552 ], [ 29.780242911392406, -1.496051206896552 ], [ 29.780242911392406, -1.495781724137931 ], [ 29.779973417721518, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33907, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.495781724137931 ], [ 29.780242911392406, -1.496051206896552 ], [ 29.780512405063291, -1.496051206896552 ], [ 29.780512405063291, -1.495781724137931 ], [ 29.780242911392406, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33908, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.495781724137931 ], [ 29.780512405063291, -1.496051206896552 ], [ 29.780781898734176, -1.496051206896552 ], [ 29.780781898734176, -1.495781724137931 ], [ 29.780512405063291, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33909, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.495781724137931 ], [ 29.780781898734176, -1.496051206896552 ], [ 29.781051392405065, -1.496051206896552 ], [ 29.781051392405065, -1.495781724137931 ], [ 29.780781898734176, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33910, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.495781724137931 ], [ 29.781051392405065, -1.496320689655172 ], [ 29.78132088607595, -1.496320689655172 ], [ 29.78132088607595, -1.495781724137931 ], [ 29.781051392405065, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33911, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.495781724137931 ], [ 29.78132088607595, -1.496051206896552 ], [ 29.781590379746834, -1.496051206896552 ], [ 29.781590379746834, -1.495781724137931 ], [ 29.78132088607595, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33912, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.495781724137931 ], [ 29.781590379746834, -1.496051206896552 ], [ 29.781859873417723, -1.496051206896552 ], [ 29.781859873417723, -1.495781724137931 ], [ 29.781590379746834, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33913, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.495781724137931 ], [ 29.781859873417723, -1.496051206896552 ], [ 29.782129367088608, -1.496051206896552 ], [ 29.782129367088608, -1.495781724137931 ], [ 29.781859873417723, -1.495781724137931 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33914, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.773505569620252, -1.496051206896552 ], [ 29.773505569620252, -1.496320689655172 ], [ 29.77377506329114, -1.496320689655172 ], [ 29.77377506329114, -1.496051206896552 ], [ 29.773505569620252, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33915, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.496051206896552 ], [ 29.77377506329114, -1.496320689655172 ], [ 29.774044556962025, -1.496320689655172 ], [ 29.774044556962025, -1.496051206896552 ], [ 29.77377506329114, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33916, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.496051206896552 ], [ 29.774044556962025, -1.496320689655172 ], [ 29.77431405063291, -1.496320689655172 ], [ 29.77431405063291, -1.496051206896552 ], [ 29.774044556962025, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33917, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.496051206896552 ], [ 29.77431405063291, -1.496320689655172 ], [ 29.774853037974683, -1.496320689655172 ], [ 29.774853037974683, -1.496051206896552 ], [ 29.77431405063291, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33918, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.496051206896552 ], [ 29.774853037974683, -1.496320689655172 ], [ 29.775122531645568, -1.496320689655172 ], [ 29.775122531645568, -1.496051206896552 ], [ 29.774853037974683, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33919, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.496051206896552 ], [ 29.775122531645568, -1.496320689655172 ], [ 29.775392025316457, -1.496320689655172 ], [ 29.775392025316457, -1.496051206896552 ], [ 29.775122531645568, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33920, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.496051206896552 ], [ 29.775392025316457, -1.496320689655172 ], [ 29.775661518987341, -1.496320689655172 ], [ 29.775661518987341, -1.496051206896552 ], [ 29.775392025316457, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33921, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.496051206896552 ], [ 29.775661518987341, -1.496320689655172 ], [ 29.775931012658226, -1.496320689655172 ], [ 29.775931012658226, -1.496051206896552 ], [ 29.775661518987341, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33922, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.496051206896552 ], [ 29.775931012658226, -1.496320689655172 ], [ 29.776200506329115, -1.496320689655172 ], [ 29.776200506329115, -1.496051206896552 ], [ 29.775931012658226, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33923, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.496051206896552 ], [ 29.776200506329115, -1.496320689655172 ], [ 29.77647, -1.496320689655172 ], [ 29.77647, -1.496051206896552 ], [ 29.776200506329115, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33924, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.496051206896552 ], [ 29.77647, -1.496320689655172 ], [ 29.776739493670885, -1.496320689655172 ], [ 29.776739493670885, -1.496051206896552 ], [ 29.77647, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33925, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.496051206896552 ], [ 29.776739493670885, -1.496320689655172 ], [ 29.777008987341773, -1.496320689655172 ], [ 29.777008987341773, -1.496051206896552 ], [ 29.776739493670885, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33926, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.496051206896552 ], [ 29.777008987341773, -1.496320689655172 ], [ 29.777278481012658, -1.496320689655172 ], [ 29.777278481012658, -1.496051206896552 ], [ 29.777008987341773, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33927, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.496051206896552 ], [ 29.777278481012658, -1.496320689655172 ], [ 29.777547974683543, -1.496320689655172 ], [ 29.777547974683543, -1.496051206896552 ], [ 29.777278481012658, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33928, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.496051206896552 ], [ 29.777547974683543, -1.496320689655172 ], [ 29.777817468354431, -1.496320689655172 ], [ 29.777817468354431, -1.496051206896552 ], [ 29.777547974683543, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33929, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.496051206896552 ], [ 29.777817468354431, -1.496320689655172 ], [ 29.778086962025316, -1.496320689655172 ], [ 29.778086962025316, -1.496051206896552 ], [ 29.777817468354431, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33930, "El": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.496051206896552 ], [ 29.778086962025316, -1.496320689655172 ], [ 29.778356455696201, -1.496320689655172 ], [ 29.778356455696201, -1.496051206896552 ], [ 29.778086962025316, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33931, "El": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.496051206896552 ], [ 29.778356455696201, -1.496320689655172 ], [ 29.77862594936709, -1.496320689655172 ], [ 29.77862594936709, -1.496051206896552 ], [ 29.778356455696201, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33932, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.496051206896552 ], [ 29.77862594936709, -1.496320689655172 ], [ 29.778895443037975, -1.496320689655172 ], [ 29.778895443037975, -1.496051206896552 ], [ 29.77862594936709, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33933, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.496051206896552 ], [ 29.778895443037975, -1.496320689655172 ], [ 29.77916493670886, -1.496320689655172 ], [ 29.77916493670886, -1.496051206896552 ], [ 29.778895443037975, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33934, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.496051206896552 ], [ 29.77916493670886, -1.496320689655172 ], [ 29.779434430379748, -1.496320689655172 ], [ 29.779434430379748, -1.496051206896552 ], [ 29.77916493670886, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33935, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.496051206896552 ], [ 29.779434430379748, -1.496320689655172 ], [ 29.779703924050633, -1.496320689655172 ], [ 29.779703924050633, -1.496051206896552 ], [ 29.779434430379748, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33936, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.496051206896552 ], [ 29.779703924050633, -1.496320689655172 ], [ 29.779973417721518, -1.496320689655172 ], [ 29.779973417721518, -1.496051206896552 ], [ 29.779703924050633, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33937, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.496051206896552 ], [ 29.779973417721518, -1.496320689655172 ], [ 29.780242911392406, -1.496320689655172 ], [ 29.780242911392406, -1.496051206896552 ], [ 29.779973417721518, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33938, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.496051206896552 ], [ 29.780242911392406, -1.496320689655172 ], [ 29.780512405063291, -1.496320689655172 ], [ 29.780512405063291, -1.496051206896552 ], [ 29.780242911392406, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33939, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.496051206896552 ], [ 29.780512405063291, -1.496320689655172 ], [ 29.780781898734176, -1.496320689655172 ], [ 29.780781898734176, -1.496051206896552 ], [ 29.780512405063291, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33940, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.496051206896552 ], [ 29.780781898734176, -1.496320689655172 ], [ 29.781051392405065, -1.496320689655172 ], [ 29.781051392405065, -1.496051206896552 ], [ 29.780781898734176, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33941, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.496051206896552 ], [ 29.78132088607595, -1.496590172413793 ], [ 29.781590379746834, -1.496590172413793 ], [ 29.781590379746834, -1.496051206896552 ], [ 29.78132088607595, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33942, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.496051206896552 ], [ 29.781590379746834, -1.496590172413793 ], [ 29.781859873417723, -1.496590172413793 ], [ 29.781859873417723, -1.496051206896552 ], [ 29.781590379746834, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33943, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.496051206896552 ], [ 29.781859873417723, -1.496320689655172 ], [ 29.782129367088608, -1.496320689655172 ], [ 29.782129367088608, -1.496051206896552 ], [ 29.781859873417723, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33944, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.496051206896552 ], [ 29.782129367088608, -1.496320689655172 ], [ 29.782398860759493, -1.496320689655172 ], [ 29.782398860759493, -1.496051206896552 ], [ 29.782129367088608, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33945, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.496051206896552 ], [ 29.782398860759493, -1.496320689655172 ], [ 29.782668354430381, -1.496320689655172 ], [ 29.782668354430381, -1.496051206896552 ], [ 29.782398860759493, -1.496051206896552 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33946, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77377506329114, -1.496320689655172 ], [ 29.77377506329114, -1.496590172413793 ], [ 29.774044556962025, -1.496590172413793 ], [ 29.774044556962025, -1.496320689655172 ], [ 29.77377506329114, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33947, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.496320689655172 ], [ 29.774044556962025, -1.496590172413793 ], [ 29.77431405063291, -1.496590172413793 ], [ 29.77431405063291, -1.496320689655172 ], [ 29.774044556962025, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33948, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.496320689655172 ], [ 29.77431405063291, -1.496590172413793 ], [ 29.774853037974683, -1.496590172413793 ], [ 29.774853037974683, -1.496320689655172 ], [ 29.77431405063291, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33949, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.496320689655172 ], [ 29.774853037974683, -1.496590172413793 ], [ 29.775122531645568, -1.496590172413793 ], [ 29.775122531645568, -1.496320689655172 ], [ 29.774853037974683, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33950, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.496320689655172 ], [ 29.775122531645568, -1.496590172413793 ], [ 29.775392025316457, -1.496590172413793 ], [ 29.775392025316457, -1.496320689655172 ], [ 29.775122531645568, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33951, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.496320689655172 ], [ 29.775392025316457, -1.496590172413793 ], [ 29.775661518987341, -1.496590172413793 ], [ 29.775661518987341, -1.496320689655172 ], [ 29.775392025316457, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33952, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.496320689655172 ], [ 29.775661518987341, -1.496590172413793 ], [ 29.775931012658226, -1.496590172413793 ], [ 29.775931012658226, -1.496320689655172 ], [ 29.775661518987341, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33953, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.496320689655172 ], [ 29.775931012658226, -1.496590172413793 ], [ 29.776200506329115, -1.496590172413793 ], [ 29.776200506329115, -1.496320689655172 ], [ 29.775931012658226, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33954, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.496320689655172 ], [ 29.776200506329115, -1.496590172413793 ], [ 29.77647, -1.496590172413793 ], [ 29.77647, -1.496320689655172 ], [ 29.776200506329115, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33955, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.496320689655172 ], [ 29.77647, -1.496590172413793 ], [ 29.776739493670885, -1.496590172413793 ], [ 29.776739493670885, -1.496320689655172 ], [ 29.77647, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33956, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.496320689655172 ], [ 29.776739493670885, -1.496590172413793 ], [ 29.777008987341773, -1.496590172413793 ], [ 29.777008987341773, -1.496320689655172 ], [ 29.776739493670885, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33957, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.496320689655172 ], [ 29.777008987341773, -1.496590172413793 ], [ 29.777278481012658, -1.496590172413793 ], [ 29.777278481012658, -1.496320689655172 ], [ 29.777008987341773, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33958, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.496320689655172 ], [ 29.777278481012658, -1.496590172413793 ], [ 29.777547974683543, -1.496590172413793 ], [ 29.777547974683543, -1.496320689655172 ], [ 29.777278481012658, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33959, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.496320689655172 ], [ 29.777547974683543, -1.496590172413793 ], [ 29.777817468354431, -1.496590172413793 ], [ 29.777817468354431, -1.496320689655172 ], [ 29.777547974683543, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33960, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.496320689655172 ], [ 29.777817468354431, -1.496590172413793 ], [ 29.778086962025316, -1.496590172413793 ], [ 29.778086962025316, -1.496320689655172 ], [ 29.777817468354431, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33961, "El": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.496320689655172 ], [ 29.778086962025316, -1.496590172413793 ], [ 29.778356455696201, -1.496590172413793 ], [ 29.778356455696201, -1.496320689655172 ], [ 29.778086962025316, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33962, "El": 43 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778356455696201, -1.496320689655172 ], [ 29.778356455696201, -1.496590172413793 ], [ 29.77862594936709, -1.496590172413793 ], [ 29.77862594936709, -1.496320689655172 ], [ 29.778356455696201, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33963, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.496320689655172 ], [ 29.77862594936709, -1.496590172413793 ], [ 29.778895443037975, -1.496590172413793 ], [ 29.778895443037975, -1.496320689655172 ], [ 29.77862594936709, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33964, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.496320689655172 ], [ 29.778895443037975, -1.496590172413793 ], [ 29.77916493670886, -1.496590172413793 ], [ 29.77916493670886, -1.496320689655172 ], [ 29.778895443037975, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33965, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.496320689655172 ], [ 29.77916493670886, -1.496590172413793 ], [ 29.779434430379748, -1.496590172413793 ], [ 29.779434430379748, -1.496320689655172 ], [ 29.77916493670886, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33966, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.496320689655172 ], [ 29.779434430379748, -1.496590172413793 ], [ 29.779703924050633, -1.496590172413793 ], [ 29.779703924050633, -1.496320689655172 ], [ 29.779434430379748, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33967, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.496320689655172 ], [ 29.779703924050633, -1.496590172413793 ], [ 29.779973417721518, -1.496590172413793 ], [ 29.779973417721518, -1.496320689655172 ], [ 29.779703924050633, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33968, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.496320689655172 ], [ 29.779973417721518, -1.496590172413793 ], [ 29.780242911392406, -1.496590172413793 ], [ 29.780242911392406, -1.496320689655172 ], [ 29.779973417721518, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33969, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.496320689655172 ], [ 29.780242911392406, -1.496590172413793 ], [ 29.780512405063291, -1.496590172413793 ], [ 29.780512405063291, -1.496320689655172 ], [ 29.780242911392406, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33970, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.496320689655172 ], [ 29.780512405063291, -1.496590172413793 ], [ 29.780781898734176, -1.496590172413793 ], [ 29.780781898734176, -1.496320689655172 ], [ 29.780512405063291, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33971, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.496320689655172 ], [ 29.780781898734176, -1.496590172413793 ], [ 29.781051392405065, -1.496590172413793 ], [ 29.781051392405065, -1.496320689655172 ], [ 29.780781898734176, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33972, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.496320689655172 ], [ 29.781051392405065, -1.496590172413793 ], [ 29.78132088607595, -1.496590172413793 ], [ 29.78132088607595, -1.496320689655172 ], [ 29.781051392405065, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33973, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.496320689655172 ], [ 29.781859873417723, -1.496859655172414 ], [ 29.782129367088608, -1.496859655172414 ], [ 29.782129367088608, -1.496320689655172 ], [ 29.781859873417723, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33974, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.496320689655172 ], [ 29.782129367088608, -1.496859655172414 ], [ 29.782398860759493, -1.496859655172414 ], [ 29.782398860759493, -1.496320689655172 ], [ 29.782129367088608, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33975, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782398860759493, -1.496320689655172 ], [ 29.782398860759493, -1.497129137931035 ], [ 29.782668354430381, -1.497129137931035 ], [ 29.782668354430381, -1.496320689655172 ], [ 29.782398860759493, -1.496320689655172 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33976, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774044556962025, -1.496590172413793 ], [ 29.774044556962025, -1.496859655172414 ], [ 29.77431405063291, -1.496859655172414 ], [ 29.77431405063291, -1.496590172413793 ], [ 29.774044556962025, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33977, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.496590172413793 ], [ 29.77431405063291, -1.496859655172414 ], [ 29.774853037974683, -1.496859655172414 ], [ 29.774853037974683, -1.496590172413793 ], [ 29.77431405063291, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33978, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.496590172413793 ], [ 29.774853037974683, -1.496859655172414 ], [ 29.775122531645568, -1.496859655172414 ], [ 29.775122531645568, -1.496590172413793 ], [ 29.774853037974683, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33979, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.496590172413793 ], [ 29.775122531645568, -1.496859655172414 ], [ 29.775392025316457, -1.496859655172414 ], [ 29.775392025316457, -1.496590172413793 ], [ 29.775122531645568, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33980, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.496590172413793 ], [ 29.775392025316457, -1.496859655172414 ], [ 29.775661518987341, -1.496859655172414 ], [ 29.775661518987341, -1.496590172413793 ], [ 29.775392025316457, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33981, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.496590172413793 ], [ 29.775661518987341, -1.496859655172414 ], [ 29.775931012658226, -1.496859655172414 ], [ 29.775931012658226, -1.496590172413793 ], [ 29.775661518987341, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33982, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.496590172413793 ], [ 29.775931012658226, -1.496859655172414 ], [ 29.776200506329115, -1.496859655172414 ], [ 29.776200506329115, -1.496590172413793 ], [ 29.775931012658226, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33983, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.496590172413793 ], [ 29.776200506329115, -1.496859655172414 ], [ 29.77647, -1.496859655172414 ], [ 29.77647, -1.496590172413793 ], [ 29.776200506329115, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33984, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.496590172413793 ], [ 29.77647, -1.496859655172414 ], [ 29.776739493670885, -1.496859655172414 ], [ 29.776739493670885, -1.496590172413793 ], [ 29.77647, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33985, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.496590172413793 ], [ 29.776739493670885, -1.496859655172414 ], [ 29.777008987341773, -1.496859655172414 ], [ 29.777008987341773, -1.496590172413793 ], [ 29.776739493670885, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33986, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.496590172413793 ], [ 29.777008987341773, -1.496859655172414 ], [ 29.777278481012658, -1.496859655172414 ], [ 29.777278481012658, -1.496590172413793 ], [ 29.777008987341773, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33987, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.496590172413793 ], [ 29.777278481012658, -1.496859655172414 ], [ 29.777547974683543, -1.496859655172414 ], [ 29.777547974683543, -1.496590172413793 ], [ 29.777278481012658, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33988, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.496590172413793 ], [ 29.777547974683543, -1.496859655172414 ], [ 29.777817468354431, -1.496859655172414 ], [ 29.777817468354431, -1.496590172413793 ], [ 29.777547974683543, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33989, "El": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.496590172413793 ], [ 29.777817468354431, -1.496859655172414 ], [ 29.778086962025316, -1.496859655172414 ], [ 29.778086962025316, -1.496590172413793 ], [ 29.777817468354431, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33990, "El": 41 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.496590172413793 ], [ 29.778086962025316, -1.496859655172414 ], [ 29.77862594936709, -1.496859655172414 ], [ 29.77862594936709, -1.496590172413793 ], [ 29.778086962025316, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33991, "El": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.496590172413793 ], [ 29.77862594936709, -1.496859655172414 ], [ 29.778895443037975, -1.496859655172414 ], [ 29.778895443037975, -1.496590172413793 ], [ 29.77862594936709, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33992, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.496590172413793 ], [ 29.778895443037975, -1.496859655172414 ], [ 29.77916493670886, -1.496859655172414 ], [ 29.77916493670886, -1.496590172413793 ], [ 29.778895443037975, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33993, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.496590172413793 ], [ 29.77916493670886, -1.496859655172414 ], [ 29.779434430379748, -1.496859655172414 ], [ 29.779434430379748, -1.496590172413793 ], [ 29.77916493670886, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33994, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.496590172413793 ], [ 29.779434430379748, -1.496859655172414 ], [ 29.779703924050633, -1.496859655172414 ], [ 29.779703924050633, -1.496590172413793 ], [ 29.779434430379748, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33995, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.496590172413793 ], [ 29.779703924050633, -1.496859655172414 ], [ 29.779973417721518, -1.496859655172414 ], [ 29.779973417721518, -1.496590172413793 ], [ 29.779703924050633, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33996, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.496590172413793 ], [ 29.779973417721518, -1.496859655172414 ], [ 29.780242911392406, -1.496859655172414 ], [ 29.780242911392406, -1.496590172413793 ], [ 29.779973417721518, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33997, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.496590172413793 ], [ 29.780242911392406, -1.496859655172414 ], [ 29.780512405063291, -1.496859655172414 ], [ 29.780512405063291, -1.496590172413793 ], [ 29.780242911392406, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33998, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.496590172413793 ], [ 29.780512405063291, -1.496859655172414 ], [ 29.780781898734176, -1.496859655172414 ], [ 29.780781898734176, -1.496590172413793 ], [ 29.780512405063291, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 33999, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.496590172413793 ], [ 29.780781898734176, -1.496859655172414 ], [ 29.781051392405065, -1.496859655172414 ], [ 29.781051392405065, -1.496590172413793 ], [ 29.780781898734176, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34000, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.496590172413793 ], [ 29.781051392405065, -1.496859655172414 ], [ 29.78132088607595, -1.496859655172414 ], [ 29.78132088607595, -1.496590172413793 ], [ 29.781051392405065, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34001, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.496590172413793 ], [ 29.78132088607595, -1.496859655172414 ], [ 29.781590379746834, -1.496859655172414 ], [ 29.781590379746834, -1.496590172413793 ], [ 29.78132088607595, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34002, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.496590172413793 ], [ 29.781590379746834, -1.496859655172414 ], [ 29.781859873417723, -1.496859655172414 ], [ 29.781859873417723, -1.496590172413793 ], [ 29.781590379746834, -1.496590172413793 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34003, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77431405063291, -1.496859655172414 ], [ 29.77431405063291, -1.497398620689655 ], [ 29.774853037974683, -1.497398620689655 ], [ 29.774853037974683, -1.496859655172414 ], [ 29.77431405063291, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34004, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.496859655172414 ], [ 29.774853037974683, -1.497129137931035 ], [ 29.775122531645568, -1.497129137931035 ], [ 29.775122531645568, -1.496859655172414 ], [ 29.774853037974683, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34005, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.496859655172414 ], [ 29.775122531645568, -1.497129137931035 ], [ 29.775392025316457, -1.497129137931035 ], [ 29.775392025316457, -1.496859655172414 ], [ 29.775122531645568, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34006, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.496859655172414 ], [ 29.775392025316457, -1.497129137931035 ], [ 29.775661518987341, -1.497129137931035 ], [ 29.775661518987341, -1.496859655172414 ], [ 29.775392025316457, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34007, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.496859655172414 ], [ 29.775661518987341, -1.497129137931035 ], [ 29.775931012658226, -1.497129137931035 ], [ 29.775931012658226, -1.496859655172414 ], [ 29.775661518987341, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34008, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.496859655172414 ], [ 29.775931012658226, -1.497129137931035 ], [ 29.776200506329115, -1.497129137931035 ], [ 29.776200506329115, -1.496859655172414 ], [ 29.775931012658226, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34009, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.496859655172414 ], [ 29.776200506329115, -1.497398620689655 ], [ 29.77647, -1.497398620689655 ], [ 29.77647, -1.496859655172414 ], [ 29.776200506329115, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34010, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.496859655172414 ], [ 29.77647, -1.497129137931035 ], [ 29.776739493670885, -1.497129137931035 ], [ 29.776739493670885, -1.496859655172414 ], [ 29.77647, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34011, "El": 28 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.496859655172414 ], [ 29.776739493670885, -1.497129137931035 ], [ 29.777008987341773, -1.497129137931035 ], [ 29.777008987341773, -1.496859655172414 ], [ 29.776739493670885, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34012, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.496859655172414 ], [ 29.777008987341773, -1.497129137931035 ], [ 29.777278481012658, -1.497129137931035 ], [ 29.777278481012658, -1.496859655172414 ], [ 29.777008987341773, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34013, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.496859655172414 ], [ 29.777278481012658, -1.497129137931035 ], [ 29.777547974683543, -1.497129137931035 ], [ 29.777547974683543, -1.496859655172414 ], [ 29.777278481012658, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34014, "El": 35 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.496859655172414 ], [ 29.777547974683543, -1.497129137931035 ], [ 29.777817468354431, -1.497129137931035 ], [ 29.777817468354431, -1.496859655172414 ], [ 29.777547974683543, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34015, "El": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.496859655172414 ], [ 29.777817468354431, -1.497129137931035 ], [ 29.77862594936709, -1.497129137931035 ], [ 29.77862594936709, -1.496859655172414 ], [ 29.777817468354431, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34016, "El": 36 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.496859655172414 ], [ 29.77862594936709, -1.497129137931035 ], [ 29.778895443037975, -1.497129137931035 ], [ 29.778895443037975, -1.496859655172414 ], [ 29.77862594936709, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34017, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.496859655172414 ], [ 29.778895443037975, -1.497129137931035 ], [ 29.77916493670886, -1.497129137931035 ], [ 29.77916493670886, -1.496859655172414 ], [ 29.778895443037975, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34018, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.496859655172414 ], [ 29.77916493670886, -1.497129137931035 ], [ 29.779434430379748, -1.497129137931035 ], [ 29.779434430379748, -1.496859655172414 ], [ 29.77916493670886, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34019, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.496859655172414 ], [ 29.779434430379748, -1.497129137931035 ], [ 29.779703924050633, -1.497129137931035 ], [ 29.779703924050633, -1.496859655172414 ], [ 29.779434430379748, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34020, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.496859655172414 ], [ 29.779703924050633, -1.497129137931035 ], [ 29.779973417721518, -1.497129137931035 ], [ 29.779973417721518, -1.496859655172414 ], [ 29.779703924050633, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34021, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.496859655172414 ], [ 29.779973417721518, -1.497129137931035 ], [ 29.780242911392406, -1.497129137931035 ], [ 29.780242911392406, -1.496859655172414 ], [ 29.779973417721518, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34022, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.496859655172414 ], [ 29.780242911392406, -1.497129137931035 ], [ 29.780512405063291, -1.497129137931035 ], [ 29.780512405063291, -1.496859655172414 ], [ 29.780242911392406, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34023, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.496859655172414 ], [ 29.780512405063291, -1.497129137931035 ], [ 29.780781898734176, -1.497129137931035 ], [ 29.780781898734176, -1.496859655172414 ], [ 29.780512405063291, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34024, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.496859655172414 ], [ 29.780781898734176, -1.497129137931035 ], [ 29.781051392405065, -1.497129137931035 ], [ 29.781051392405065, -1.496859655172414 ], [ 29.780781898734176, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34025, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.496859655172414 ], [ 29.781051392405065, -1.497129137931035 ], [ 29.78132088607595, -1.497129137931035 ], [ 29.78132088607595, -1.496859655172414 ], [ 29.781051392405065, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34026, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.496859655172414 ], [ 29.78132088607595, -1.497129137931035 ], [ 29.781590379746834, -1.497129137931035 ], [ 29.781590379746834, -1.496859655172414 ], [ 29.78132088607595, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34027, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.496859655172414 ], [ 29.781590379746834, -1.497129137931035 ], [ 29.781859873417723, -1.497129137931035 ], [ 29.781859873417723, -1.496859655172414 ], [ 29.781590379746834, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34028, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.496859655172414 ], [ 29.781859873417723, -1.497129137931035 ], [ 29.782129367088608, -1.497129137931035 ], [ 29.782129367088608, -1.496859655172414 ], [ 29.781859873417723, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34029, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.496859655172414 ], [ 29.782129367088608, -1.497129137931035 ], [ 29.782398860759493, -1.497129137931035 ], [ 29.782398860759493, -1.496859655172414 ], [ 29.782129367088608, -1.496859655172414 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34030, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.497129137931035 ], [ 29.774853037974683, -1.497398620689655 ], [ 29.775122531645568, -1.497398620689655 ], [ 29.775122531645568, -1.497129137931035 ], [ 29.774853037974683, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34031, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.497129137931035 ], [ 29.775122531645568, -1.497398620689655 ], [ 29.775392025316457, -1.497398620689655 ], [ 29.775392025316457, -1.497129137931035 ], [ 29.775122531645568, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34032, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.497129137931035 ], [ 29.775392025316457, -1.497398620689655 ], [ 29.775661518987341, -1.497398620689655 ], [ 29.775661518987341, -1.497129137931035 ], [ 29.775392025316457, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34033, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.497129137931035 ], [ 29.775661518987341, -1.497668103448276 ], [ 29.775931012658226, -1.497668103448276 ], [ 29.775931012658226, -1.497129137931035 ], [ 29.775661518987341, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34034, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.497129137931035 ], [ 29.775931012658226, -1.497668103448276 ], [ 29.776200506329115, -1.497668103448276 ], [ 29.776200506329115, -1.497129137931035 ], [ 29.775931012658226, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34035, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.497129137931035 ], [ 29.77647, -1.497398620689655 ], [ 29.776739493670885, -1.497398620689655 ], [ 29.776739493670885, -1.497129137931035 ], [ 29.77647, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34036, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.497129137931035 ], [ 29.776739493670885, -1.497398620689655 ], [ 29.777008987341773, -1.497398620689655 ], [ 29.777008987341773, -1.497129137931035 ], [ 29.776739493670885, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34037, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.497129137931035 ], [ 29.777008987341773, -1.497398620689655 ], [ 29.777278481012658, -1.497398620689655 ], [ 29.777278481012658, -1.497129137931035 ], [ 29.777008987341773, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34038, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.497129137931035 ], [ 29.777278481012658, -1.497398620689655 ], [ 29.777547974683543, -1.497398620689655 ], [ 29.777547974683543, -1.497129137931035 ], [ 29.777278481012658, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34039, "El": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.497129137931035 ], [ 29.777547974683543, -1.497398620689655 ], [ 29.777817468354431, -1.497398620689655 ], [ 29.777817468354431, -1.497129137931035 ], [ 29.777547974683543, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34040, "El": 33 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.497129137931035 ], [ 29.777817468354431, -1.497398620689655 ], [ 29.778086962025316, -1.497398620689655 ], [ 29.778086962025316, -1.497129137931035 ], [ 29.777817468354431, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34041, "El": 34 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.497129137931035 ], [ 29.778086962025316, -1.497398620689655 ], [ 29.778895443037975, -1.497398620689655 ], [ 29.778895443037975, -1.497129137931035 ], [ 29.778086962025316, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34042, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.497129137931035 ], [ 29.778895443037975, -1.497398620689655 ], [ 29.77916493670886, -1.497398620689655 ], [ 29.77916493670886, -1.497129137931035 ], [ 29.778895443037975, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34043, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.497129137931035 ], [ 29.77916493670886, -1.497398620689655 ], [ 29.779434430379748, -1.497398620689655 ], [ 29.779434430379748, -1.497129137931035 ], [ 29.77916493670886, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34044, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.497129137931035 ], [ 29.779434430379748, -1.497398620689655 ], [ 29.779703924050633, -1.497398620689655 ], [ 29.779703924050633, -1.497129137931035 ], [ 29.779434430379748, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34045, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.497129137931035 ], [ 29.779703924050633, -1.497398620689655 ], [ 29.779973417721518, -1.497398620689655 ], [ 29.779973417721518, -1.497129137931035 ], [ 29.779703924050633, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34046, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.497129137931035 ], [ 29.779973417721518, -1.497398620689655 ], [ 29.780242911392406, -1.497398620689655 ], [ 29.780242911392406, -1.497129137931035 ], [ 29.779973417721518, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34047, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.497129137931035 ], [ 29.780242911392406, -1.497398620689655 ], [ 29.780512405063291, -1.497398620689655 ], [ 29.780512405063291, -1.497129137931035 ], [ 29.780242911392406, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34048, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.497129137931035 ], [ 29.780512405063291, -1.497398620689655 ], [ 29.780781898734176, -1.497398620689655 ], [ 29.780781898734176, -1.497129137931035 ], [ 29.780512405063291, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34049, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.497129137931035 ], [ 29.780781898734176, -1.497398620689655 ], [ 29.781051392405065, -1.497398620689655 ], [ 29.781051392405065, -1.497129137931035 ], [ 29.780781898734176, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34050, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.497129137931035 ], [ 29.781051392405065, -1.497398620689655 ], [ 29.78132088607595, -1.497398620689655 ], [ 29.78132088607595, -1.497129137931035 ], [ 29.781051392405065, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34051, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.497129137931035 ], [ 29.78132088607595, -1.497398620689655 ], [ 29.781590379746834, -1.497398620689655 ], [ 29.781590379746834, -1.497129137931035 ], [ 29.78132088607595, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34052, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.497129137931035 ], [ 29.781590379746834, -1.497398620689655 ], [ 29.781859873417723, -1.497398620689655 ], [ 29.781859873417723, -1.497129137931035 ], [ 29.781590379746834, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34053, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.497129137931035 ], [ 29.781859873417723, -1.497398620689655 ], [ 29.782129367088608, -1.497398620689655 ], [ 29.782129367088608, -1.497129137931035 ], [ 29.781859873417723, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34054, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.782129367088608, -1.497129137931035 ], [ 29.782129367088608, -1.497398620689655 ], [ 29.782398860759493, -1.497398620689655 ], [ 29.782398860759493, -1.497129137931035 ], [ 29.782129367088608, -1.497129137931035 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34055, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.497398620689655 ], [ 29.776200506329115, -1.497668103448276 ], [ 29.77647, -1.497668103448276 ], [ 29.77647, -1.497398620689655 ], [ 29.776200506329115, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34056, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.497398620689655 ], [ 29.77647, -1.497668103448276 ], [ 29.776739493670885, -1.497668103448276 ], [ 29.776739493670885, -1.497398620689655 ], [ 29.77647, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34057, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.497398620689655 ], [ 29.776739493670885, -1.497668103448276 ], [ 29.777008987341773, -1.497668103448276 ], [ 29.777008987341773, -1.497398620689655 ], [ 29.776739493670885, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34058, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.497398620689655 ], [ 29.777008987341773, -1.497668103448276 ], [ 29.777278481012658, -1.497668103448276 ], [ 29.777278481012658, -1.497398620689655 ], [ 29.777008987341773, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34059, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.497398620689655 ], [ 29.777278481012658, -1.497668103448276 ], [ 29.777547974683543, -1.497668103448276 ], [ 29.777547974683543, -1.497398620689655 ], [ 29.777278481012658, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34060, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.497398620689655 ], [ 29.777547974683543, -1.497668103448276 ], [ 29.777817468354431, -1.497668103448276 ], [ 29.777817468354431, -1.497398620689655 ], [ 29.777547974683543, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34061, "El": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.497398620689655 ], [ 29.777817468354431, -1.497668103448276 ], [ 29.778086962025316, -1.497668103448276 ], [ 29.778086962025316, -1.497398620689655 ], [ 29.777817468354431, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34062, "El": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778086962025316, -1.497398620689655 ], [ 29.778086962025316, -1.497668103448276 ], [ 29.778895443037975, -1.497668103448276 ], [ 29.778895443037975, -1.497398620689655 ], [ 29.778086962025316, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34063, "El": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.497398620689655 ], [ 29.778895443037975, -1.497668103448276 ], [ 29.77916493670886, -1.497668103448276 ], [ 29.77916493670886, -1.497398620689655 ], [ 29.778895443037975, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34064, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.497398620689655 ], [ 29.77916493670886, -1.497668103448276 ], [ 29.779434430379748, -1.497668103448276 ], [ 29.779434430379748, -1.497398620689655 ], [ 29.77916493670886, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34065, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.497398620689655 ], [ 29.779434430379748, -1.497668103448276 ], [ 29.779703924050633, -1.497668103448276 ], [ 29.779703924050633, -1.497398620689655 ], [ 29.779434430379748, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34066, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.497398620689655 ], [ 29.779703924050633, -1.497668103448276 ], [ 29.779973417721518, -1.497668103448276 ], [ 29.779973417721518, -1.497398620689655 ], [ 29.779703924050633, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34067, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.497398620689655 ], [ 29.779973417721518, -1.497668103448276 ], [ 29.780242911392406, -1.497668103448276 ], [ 29.780242911392406, -1.497398620689655 ], [ 29.779973417721518, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34068, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.497398620689655 ], [ 29.780242911392406, -1.497668103448276 ], [ 29.780512405063291, -1.497668103448276 ], [ 29.780512405063291, -1.497398620689655 ], [ 29.780242911392406, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34069, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.497398620689655 ], [ 29.780512405063291, -1.497668103448276 ], [ 29.780781898734176, -1.497668103448276 ], [ 29.780781898734176, -1.497398620689655 ], [ 29.780512405063291, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34070, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.497398620689655 ], [ 29.780781898734176, -1.497668103448276 ], [ 29.781051392405065, -1.497668103448276 ], [ 29.781051392405065, -1.497398620689655 ], [ 29.780781898734176, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34071, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.497398620689655 ], [ 29.781051392405065, -1.497668103448276 ], [ 29.78132088607595, -1.497668103448276 ], [ 29.78132088607595, -1.497398620689655 ], [ 29.781051392405065, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34072, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.497398620689655 ], [ 29.78132088607595, -1.497668103448276 ], [ 29.781590379746834, -1.497668103448276 ], [ 29.781590379746834, -1.497398620689655 ], [ 29.78132088607595, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34073, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.497398620689655 ], [ 29.781590379746834, -1.497668103448276 ], [ 29.781859873417723, -1.497668103448276 ], [ 29.781859873417723, -1.497398620689655 ], [ 29.781590379746834, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34074, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781859873417723, -1.497398620689655 ], [ 29.781859873417723, -1.497668103448276 ], [ 29.782129367088608, -1.497668103448276 ], [ 29.782129367088608, -1.497398620689655 ], [ 29.781859873417723, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34075, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.774853037974683, -1.497398620689655 ], [ 29.774853037974683, -1.498207068965517 ], [ 29.775122531645568, -1.498207068965517 ], [ 29.775122531645568, -1.497398620689655 ], [ 29.774853037974683, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34076, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.497398620689655 ], [ 29.775122531645568, -1.497937586206897 ], [ 29.775392025316457, -1.497937586206897 ], [ 29.775392025316457, -1.497398620689655 ], [ 29.775122531645568, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34077, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.497398620689655 ], [ 29.775392025316457, -1.497937586206897 ], [ 29.775661518987341, -1.497937586206897 ], [ 29.775661518987341, -1.497398620689655 ], [ 29.775392025316457, -1.497398620689655 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34078, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.497668103448276 ], [ 29.775661518987341, -1.497937586206897 ], [ 29.775931012658226, -1.497937586206897 ], [ 29.775931012658226, -1.497668103448276 ], [ 29.775661518987341, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34079, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.497668103448276 ], [ 29.775931012658226, -1.497937586206897 ], [ 29.776200506329115, -1.497937586206897 ], [ 29.776200506329115, -1.497668103448276 ], [ 29.775931012658226, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34080, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.497668103448276 ], [ 29.776200506329115, -1.497937586206897 ], [ 29.77647, -1.497937586206897 ], [ 29.77647, -1.497668103448276 ], [ 29.776200506329115, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34081, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.497668103448276 ], [ 29.77647, -1.497937586206897 ], [ 29.776739493670885, -1.497937586206897 ], [ 29.776739493670885, -1.497668103448276 ], [ 29.77647, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34082, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.497668103448276 ], [ 29.776739493670885, -1.497937586206897 ], [ 29.777008987341773, -1.497937586206897 ], [ 29.777008987341773, -1.497668103448276 ], [ 29.776739493670885, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34083, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.497668103448276 ], [ 29.777008987341773, -1.497937586206897 ], [ 29.777278481012658, -1.497937586206897 ], [ 29.777278481012658, -1.497668103448276 ], [ 29.777008987341773, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34084, "El": 25 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.497668103448276 ], [ 29.777278481012658, -1.497937586206897 ], [ 29.777547974683543, -1.497937586206897 ], [ 29.777547974683543, -1.497668103448276 ], [ 29.777278481012658, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34085, "El": 26 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.497668103448276 ], [ 29.777547974683543, -1.497937586206897 ], [ 29.777817468354431, -1.497937586206897 ], [ 29.777817468354431, -1.497668103448276 ], [ 29.777547974683543, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34086, "El": 27 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.497668103448276 ], [ 29.777817468354431, -1.497937586206897 ], [ 29.77916493670886, -1.497937586206897 ], [ 29.77916493670886, -1.497668103448276 ], [ 29.777817468354431, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34087, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.497668103448276 ], [ 29.77916493670886, -1.497937586206897 ], [ 29.779434430379748, -1.497937586206897 ], [ 29.779434430379748, -1.497668103448276 ], [ 29.77916493670886, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34088, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.497668103448276 ], [ 29.779434430379748, -1.497937586206897 ], [ 29.779703924050633, -1.497937586206897 ], [ 29.779703924050633, -1.497668103448276 ], [ 29.779434430379748, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34089, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.497668103448276 ], [ 29.779703924050633, -1.497937586206897 ], [ 29.779973417721518, -1.497937586206897 ], [ 29.779973417721518, -1.497668103448276 ], [ 29.779703924050633, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34090, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.497668103448276 ], [ 29.779973417721518, -1.497937586206897 ], [ 29.780242911392406, -1.497937586206897 ], [ 29.780242911392406, -1.497668103448276 ], [ 29.779973417721518, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34091, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.497668103448276 ], [ 29.780242911392406, -1.497937586206897 ], [ 29.780512405063291, -1.497937586206897 ], [ 29.780512405063291, -1.497668103448276 ], [ 29.780242911392406, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34092, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.497668103448276 ], [ 29.780512405063291, -1.497937586206897 ], [ 29.780781898734176, -1.497937586206897 ], [ 29.780781898734176, -1.497668103448276 ], [ 29.780512405063291, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34093, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.497668103448276 ], [ 29.780781898734176, -1.497937586206897 ], [ 29.781051392405065, -1.497937586206897 ], [ 29.781051392405065, -1.497668103448276 ], [ 29.780781898734176, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34094, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.497668103448276 ], [ 29.781051392405065, -1.497937586206897 ], [ 29.78132088607595, -1.497937586206897 ], [ 29.78132088607595, -1.497668103448276 ], [ 29.781051392405065, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34095, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.497668103448276 ], [ 29.78132088607595, -1.497937586206897 ], [ 29.781590379746834, -1.497937586206897 ], [ 29.781590379746834, -1.497668103448276 ], [ 29.78132088607595, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34096, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781590379746834, -1.497668103448276 ], [ 29.781590379746834, -1.497937586206897 ], [ 29.781859873417723, -1.497937586206897 ], [ 29.781859873417723, -1.497668103448276 ], [ 29.781590379746834, -1.497668103448276 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34097, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.497937586206897 ], [ 29.775122531645568, -1.498207068965517 ], [ 29.775392025316457, -1.498207068965517 ], [ 29.775392025316457, -1.497937586206897 ], [ 29.775122531645568, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34098, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.497937586206897 ], [ 29.775392025316457, -1.498207068965517 ], [ 29.775661518987341, -1.498207068965517 ], [ 29.775661518987341, -1.497937586206897 ], [ 29.775392025316457, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34099, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.497937586206897 ], [ 29.775661518987341, -1.498207068965517 ], [ 29.775931012658226, -1.498207068965517 ], [ 29.775931012658226, -1.497937586206897 ], [ 29.775661518987341, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34100, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.497937586206897 ], [ 29.775931012658226, -1.498207068965517 ], [ 29.776200506329115, -1.498207068965517 ], [ 29.776200506329115, -1.497937586206897 ], [ 29.775931012658226, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34101, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.497937586206897 ], [ 29.776200506329115, -1.498207068965517 ], [ 29.77647, -1.498207068965517 ], [ 29.77647, -1.497937586206897 ], [ 29.776200506329115, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34102, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.497937586206897 ], [ 29.77647, -1.498207068965517 ], [ 29.776739493670885, -1.498207068965517 ], [ 29.776739493670885, -1.497937586206897 ], [ 29.77647, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34103, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.497937586206897 ], [ 29.776739493670885, -1.498207068965517 ], [ 29.777008987341773, -1.498207068965517 ], [ 29.777008987341773, -1.497937586206897 ], [ 29.776739493670885, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34104, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.497937586206897 ], [ 29.777008987341773, -1.498207068965517 ], [ 29.777278481012658, -1.498207068965517 ], [ 29.777278481012658, -1.497937586206897 ], [ 29.777008987341773, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34105, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.497937586206897 ], [ 29.777278481012658, -1.498207068965517 ], [ 29.777547974683543, -1.498207068965517 ], [ 29.777547974683543, -1.497937586206897 ], [ 29.777278481012658, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34106, "El": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.497937586206897 ], [ 29.777547974683543, -1.498207068965517 ], [ 29.777817468354431, -1.498207068965517 ], [ 29.777817468354431, -1.497937586206897 ], [ 29.777547974683543, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34107, "El": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777817468354431, -1.497937586206897 ], [ 29.777817468354431, -1.498207068965517 ], [ 29.77916493670886, -1.498207068965517 ], [ 29.77916493670886, -1.497937586206897 ], [ 29.777817468354431, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34108, "El": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.497937586206897 ], [ 29.77916493670886, -1.498207068965517 ], [ 29.779434430379748, -1.498207068965517 ], [ 29.779434430379748, -1.497937586206897 ], [ 29.77916493670886, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34109, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.497937586206897 ], [ 29.779434430379748, -1.498207068965517 ], [ 29.779703924050633, -1.498207068965517 ], [ 29.779703924050633, -1.497937586206897 ], [ 29.779434430379748, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34110, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.497937586206897 ], [ 29.779703924050633, -1.498207068965517 ], [ 29.779973417721518, -1.498207068965517 ], [ 29.779973417721518, -1.497937586206897 ], [ 29.779703924050633, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34111, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.497937586206897 ], [ 29.779973417721518, -1.498207068965517 ], [ 29.780242911392406, -1.498207068965517 ], [ 29.780242911392406, -1.497937586206897 ], [ 29.779973417721518, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34112, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.497937586206897 ], [ 29.780242911392406, -1.498207068965517 ], [ 29.780512405063291, -1.498207068965517 ], [ 29.780512405063291, -1.497937586206897 ], [ 29.780242911392406, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34113, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.497937586206897 ], [ 29.780512405063291, -1.498207068965517 ], [ 29.780781898734176, -1.498207068965517 ], [ 29.780781898734176, -1.497937586206897 ], [ 29.780512405063291, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34114, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.497937586206897 ], [ 29.780781898734176, -1.498207068965517 ], [ 29.781051392405065, -1.498207068965517 ], [ 29.781051392405065, -1.497937586206897 ], [ 29.780781898734176, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34115, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.497937586206897 ], [ 29.781051392405065, -1.498207068965517 ], [ 29.78132088607595, -1.498207068965517 ], [ 29.78132088607595, -1.497937586206897 ], [ 29.781051392405065, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34116, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.78132088607595, -1.497937586206897 ], [ 29.78132088607595, -1.498207068965517 ], [ 29.781590379746834, -1.498207068965517 ], [ 29.781590379746834, -1.497937586206897 ], [ 29.78132088607595, -1.497937586206897 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34117, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775122531645568, -1.498207068965517 ], [ 29.775122531645568, -1.498476551724138 ], [ 29.775392025316457, -1.498476551724138 ], [ 29.775392025316457, -1.498207068965517 ], [ 29.775122531645568, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34118, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.498207068965517 ], [ 29.775392025316457, -1.498476551724138 ], [ 29.775661518987341, -1.498476551724138 ], [ 29.775661518987341, -1.498207068965517 ], [ 29.775392025316457, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34119, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.498207068965517 ], [ 29.775661518987341, -1.498476551724138 ], [ 29.775931012658226, -1.498476551724138 ], [ 29.775931012658226, -1.498207068965517 ], [ 29.775661518987341, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34120, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.498207068965517 ], [ 29.775931012658226, -1.498476551724138 ], [ 29.776200506329115, -1.498476551724138 ], [ 29.776200506329115, -1.498207068965517 ], [ 29.775931012658226, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34121, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.498207068965517 ], [ 29.776200506329115, -1.498476551724138 ], [ 29.77647, -1.498476551724138 ], [ 29.77647, -1.498207068965517 ], [ 29.776200506329115, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34122, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.498207068965517 ], [ 29.77647, -1.498476551724138 ], [ 29.776739493670885, -1.498476551724138 ], [ 29.776739493670885, -1.498207068965517 ], [ 29.77647, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34123, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.498207068965517 ], [ 29.776739493670885, -1.498476551724138 ], [ 29.777008987341773, -1.498476551724138 ], [ 29.777008987341773, -1.498207068965517 ], [ 29.776739493670885, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34124, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.498207068965517 ], [ 29.777008987341773, -1.498476551724138 ], [ 29.777278481012658, -1.498476551724138 ], [ 29.777278481012658, -1.498207068965517 ], [ 29.777008987341773, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34125, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.498207068965517 ], [ 29.777278481012658, -1.498476551724138 ], [ 29.777547974683543, -1.498476551724138 ], [ 29.777547974683543, -1.498207068965517 ], [ 29.777278481012658, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34126, "El": 20 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.498207068965517 ], [ 29.777547974683543, -1.498476551724138 ], [ 29.77862594936709, -1.498476551724138 ], [ 29.77862594936709, -1.498207068965517 ], [ 29.777547974683543, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34127, "El": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.498207068965517 ], [ 29.77862594936709, -1.498476551724138 ], [ 29.77916493670886, -1.498476551724138 ], [ 29.77916493670886, -1.498207068965517 ], [ 29.77862594936709, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34128, "El": 19 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.498207068965517 ], [ 29.77916493670886, -1.498476551724138 ], [ 29.779434430379748, -1.498476551724138 ], [ 29.779434430379748, -1.498207068965517 ], [ 29.77916493670886, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34129, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.498207068965517 ], [ 29.779434430379748, -1.498476551724138 ], [ 29.779703924050633, -1.498476551724138 ], [ 29.779703924050633, -1.498207068965517 ], [ 29.779434430379748, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34130, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.498207068965517 ], [ 29.779703924050633, -1.498476551724138 ], [ 29.779973417721518, -1.498476551724138 ], [ 29.779973417721518, -1.498207068965517 ], [ 29.779703924050633, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34131, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.498207068965517 ], [ 29.779973417721518, -1.498476551724138 ], [ 29.780242911392406, -1.498476551724138 ], [ 29.780242911392406, -1.498207068965517 ], [ 29.779973417721518, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34132, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.498207068965517 ], [ 29.780242911392406, -1.498476551724138 ], [ 29.780512405063291, -1.498476551724138 ], [ 29.780512405063291, -1.498207068965517 ], [ 29.780242911392406, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34133, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.498207068965517 ], [ 29.780512405063291, -1.498476551724138 ], [ 29.780781898734176, -1.498476551724138 ], [ 29.780781898734176, -1.498207068965517 ], [ 29.780512405063291, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34134, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.498207068965517 ], [ 29.780781898734176, -1.498476551724138 ], [ 29.781051392405065, -1.498476551724138 ], [ 29.781051392405065, -1.498207068965517 ], [ 29.780781898734176, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34135, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.781051392405065, -1.498207068965517 ], [ 29.781051392405065, -1.498476551724138 ], [ 29.78132088607595, -1.498476551724138 ], [ 29.78132088607595, -1.498207068965517 ], [ 29.781051392405065, -1.498207068965517 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34136, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775392025316457, -1.498476551724138 ], [ 29.775392025316457, -1.499015517241379 ], [ 29.775661518987341, -1.499015517241379 ], [ 29.775661518987341, -1.498476551724138 ], [ 29.775392025316457, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34137, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.498476551724138 ], [ 29.775661518987341, -1.499015517241379 ], [ 29.775931012658226, -1.499015517241379 ], [ 29.775931012658226, -1.498476551724138 ], [ 29.775661518987341, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34138, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.498476551724138 ], [ 29.775931012658226, -1.499015517241379 ], [ 29.776200506329115, -1.499015517241379 ], [ 29.776200506329115, -1.498476551724138 ], [ 29.775931012658226, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34139, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.498476551724138 ], [ 29.776200506329115, -1.499015517241379 ], [ 29.77647, -1.499015517241379 ], [ 29.77647, -1.498476551724138 ], [ 29.776200506329115, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34140, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.498476551724138 ], [ 29.77647, -1.499015517241379 ], [ 29.776739493670885, -1.499015517241379 ], [ 29.776739493670885, -1.498476551724138 ], [ 29.77647, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34141, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.498476551724138 ], [ 29.776739493670885, -1.499015517241379 ], [ 29.777008987341773, -1.499015517241379 ], [ 29.777008987341773, -1.498476551724138 ], [ 29.776739493670885, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34142, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.498476551724138 ], [ 29.777008987341773, -1.499015517241379 ], [ 29.777278481012658, -1.499015517241379 ], [ 29.777278481012658, -1.498476551724138 ], [ 29.777008987341773, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34143, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.498476551724138 ], [ 29.777278481012658, -1.499015517241379 ], [ 29.777547974683543, -1.499015517241379 ], [ 29.777547974683543, -1.498476551724138 ], [ 29.777278481012658, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34144, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.498476551724138 ], [ 29.777547974683543, -1.499015517241379 ], [ 29.778895443037975, -1.499015517241379 ], [ 29.778895443037975, -1.498476551724138 ], [ 29.777547974683543, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34145, "El": 18 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.498476551724138 ], [ 29.778895443037975, -1.499015517241379 ], [ 29.77916493670886, -1.499015517241379 ], [ 29.77916493670886, -1.498476551724138 ], [ 29.778895443037975, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34146, "El": 17 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.498476551724138 ], [ 29.77916493670886, -1.499015517241379 ], [ 29.779434430379748, -1.499015517241379 ], [ 29.779434430379748, -1.498476551724138 ], [ 29.77916493670886, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34147, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.498476551724138 ], [ 29.779434430379748, -1.499015517241379 ], [ 29.779703924050633, -1.499015517241379 ], [ 29.779703924050633, -1.498476551724138 ], [ 29.779434430379748, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34148, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.498476551724138 ], [ 29.779703924050633, -1.499015517241379 ], [ 29.779973417721518, -1.499015517241379 ], [ 29.779973417721518, -1.498476551724138 ], [ 29.779703924050633, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34149, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.498476551724138 ], [ 29.779973417721518, -1.499015517241379 ], [ 29.780242911392406, -1.499015517241379 ], [ 29.780242911392406, -1.498476551724138 ], [ 29.779973417721518, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34150, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.498476551724138 ], [ 29.780242911392406, -1.499015517241379 ], [ 29.780512405063291, -1.499015517241379 ], [ 29.780512405063291, -1.498476551724138 ], [ 29.780242911392406, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34151, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.498476551724138 ], [ 29.780512405063291, -1.499015517241379 ], [ 29.780781898734176, -1.499015517241379 ], [ 29.780781898734176, -1.498476551724138 ], [ 29.780512405063291, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34152, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780781898734176, -1.498476551724138 ], [ 29.780781898734176, -1.499015517241379 ], [ 29.781051392405065, -1.499015517241379 ], [ 29.781051392405065, -1.498476551724138 ], [ 29.780781898734176, -1.498476551724138 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34153, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775661518987341, -1.499015517241379 ], [ 29.775661518987341, -1.499285 ], [ 29.775931012658226, -1.499285 ], [ 29.775931012658226, -1.499015517241379 ], [ 29.775661518987341, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34154, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.499015517241379 ], [ 29.775931012658226, -1.499285 ], [ 29.776200506329115, -1.499285 ], [ 29.776200506329115, -1.499015517241379 ], [ 29.775931012658226, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34155, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776200506329115, -1.499015517241379 ], [ 29.776200506329115, -1.499285 ], [ 29.77647, -1.499285 ], [ 29.77647, -1.499015517241379 ], [ 29.776200506329115, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34156, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.499015517241379 ], [ 29.77647, -1.499285 ], [ 29.776739493670885, -1.499285 ], [ 29.776739493670885, -1.499015517241379 ], [ 29.77647, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34157, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.499015517241379 ], [ 29.776739493670885, -1.499285 ], [ 29.777008987341773, -1.499285 ], [ 29.777008987341773, -1.499015517241379 ], [ 29.776739493670885, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34158, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.499015517241379 ], [ 29.777008987341773, -1.499285 ], [ 29.777278481012658, -1.499285 ], [ 29.777278481012658, -1.499015517241379 ], [ 29.777008987341773, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34159, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.499015517241379 ], [ 29.777278481012658, -1.499285 ], [ 29.777547974683543, -1.499285 ], [ 29.777547974683543, -1.499015517241379 ], [ 29.777278481012658, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34160, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777547974683543, -1.499015517241379 ], [ 29.777547974683543, -1.499285 ], [ 29.778895443037975, -1.499285 ], [ 29.778895443037975, -1.499015517241379 ], [ 29.777547974683543, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34161, "El": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.499015517241379 ], [ 29.778895443037975, -1.499285 ], [ 29.77916493670886, -1.499285 ], [ 29.77916493670886, -1.499015517241379 ], [ 29.778895443037975, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34162, "El": 14 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.499015517241379 ], [ 29.77916493670886, -1.499285 ], [ 29.779434430379748, -1.499285 ], [ 29.779434430379748, -1.499015517241379 ], [ 29.77916493670886, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34163, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.499015517241379 ], [ 29.779434430379748, -1.499285 ], [ 29.779703924050633, -1.499285 ], [ 29.779703924050633, -1.499015517241379 ], [ 29.779434430379748, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34164, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.499015517241379 ], [ 29.779703924050633, -1.499285 ], [ 29.779973417721518, -1.499285 ], [ 29.779973417721518, -1.499015517241379 ], [ 29.779703924050633, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34165, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.499015517241379 ], [ 29.779973417721518, -1.499285 ], [ 29.780242911392406, -1.499285 ], [ 29.780242911392406, -1.499015517241379 ], [ 29.779973417721518, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34166, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.499015517241379 ], [ 29.780242911392406, -1.499285 ], [ 29.780512405063291, -1.499285 ], [ 29.780512405063291, -1.499015517241379 ], [ 29.780242911392406, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34167, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780512405063291, -1.499015517241379 ], [ 29.780512405063291, -1.499285 ], [ 29.780781898734176, -1.499285 ], [ 29.780781898734176, -1.499015517241379 ], [ 29.780512405063291, -1.499015517241379 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34168, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.775931012658226, -1.499285 ], [ 29.775931012658226, -1.499554482758621 ], [ 29.77647, -1.499554482758621 ], [ 29.77647, -1.499285 ], [ 29.775931012658226, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34169, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.499285 ], [ 29.77647, -1.499554482758621 ], [ 29.776739493670885, -1.499554482758621 ], [ 29.776739493670885, -1.499285 ], [ 29.77647, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34170, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.776739493670885, -1.499285 ], [ 29.776739493670885, -1.499554482758621 ], [ 29.777008987341773, -1.499554482758621 ], [ 29.777008987341773, -1.499285 ], [ 29.776739493670885, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34171, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.499285 ], [ 29.777008987341773, -1.499554482758621 ], [ 29.777278481012658, -1.499554482758621 ], [ 29.777278481012658, -1.499285 ], [ 29.777008987341773, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34172, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.499285 ], [ 29.777278481012658, -1.499554482758621 ], [ 29.77862594936709, -1.499554482758621 ], [ 29.77862594936709, -1.499285 ], [ 29.777278481012658, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34173, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.499285 ], [ 29.77862594936709, -1.499554482758621 ], [ 29.778895443037975, -1.499554482758621 ], [ 29.778895443037975, -1.499285 ], [ 29.77862594936709, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34174, "El": 12 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.499285 ], [ 29.778895443037975, -1.499554482758621 ], [ 29.779434430379748, -1.499554482758621 ], [ 29.779434430379748, -1.499285 ], [ 29.778895443037975, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34175, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.499285 ], [ 29.779434430379748, -1.499554482758621 ], [ 29.779703924050633, -1.499554482758621 ], [ 29.779703924050633, -1.499285 ], [ 29.779434430379748, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34176, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.499285 ], [ 29.779703924050633, -1.499554482758621 ], [ 29.779973417721518, -1.499554482758621 ], [ 29.779973417721518, -1.499285 ], [ 29.779703924050633, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34177, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.499285 ], [ 29.779973417721518, -1.499554482758621 ], [ 29.780242911392406, -1.499554482758621 ], [ 29.780242911392406, -1.499285 ], [ 29.779973417721518, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34178, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.780242911392406, -1.499285 ], [ 29.780242911392406, -1.499554482758621 ], [ 29.780512405063291, -1.499554482758621 ], [ 29.780512405063291, -1.499285 ], [ 29.780242911392406, -1.499285 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34179, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77647, -1.499554482758621 ], [ 29.77647, -1.499823965517241 ], [ 29.777008987341773, -1.499823965517241 ], [ 29.777008987341773, -1.499554482758621 ], [ 29.77647, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34180, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.499554482758621 ], [ 29.777008987341773, -1.499823965517241 ], [ 29.777278481012658, -1.499823965517241 ], [ 29.777278481012658, -1.499554482758621 ], [ 29.777008987341773, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34181, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777278481012658, -1.499554482758621 ], [ 29.777278481012658, -1.499823965517241 ], [ 29.77862594936709, -1.499823965517241 ], [ 29.77862594936709, -1.499554482758621 ], [ 29.777278481012658, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34182, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.499554482758621 ], [ 29.77862594936709, -1.499823965517241 ], [ 29.778895443037975, -1.499823965517241 ], [ 29.778895443037975, -1.499554482758621 ], [ 29.77862594936709, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34183, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.499554482758621 ], [ 29.778895443037975, -1.499823965517241 ], [ 29.77916493670886, -1.499823965517241 ], [ 29.77916493670886, -1.499554482758621 ], [ 29.778895443037975, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34184, "El": 11 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.499554482758621 ], [ 29.77916493670886, -1.499823965517241 ], [ 29.779434430379748, -1.499823965517241 ], [ 29.779434430379748, -1.499554482758621 ], [ 29.77916493670886, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34185, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.499554482758621 ], [ 29.779434430379748, -1.499823965517241 ], [ 29.779703924050633, -1.499823965517241 ], [ 29.779703924050633, -1.499554482758621 ], [ 29.779434430379748, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34186, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.499554482758621 ], [ 29.779703924050633, -1.499823965517241 ], [ 29.779973417721518, -1.499823965517241 ], [ 29.779973417721518, -1.499554482758621 ], [ 29.779703924050633, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34187, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779973417721518, -1.499554482758621 ], [ 29.779973417721518, -1.499823965517241 ], [ 29.780242911392406, -1.499823965517241 ], [ 29.780242911392406, -1.499554482758621 ], [ 29.779973417721518, -1.499554482758621 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34188, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.777008987341773, -1.499823965517241 ], [ 29.777008987341773, -1.500093448275862 ], [ 29.77862594936709, -1.500093448275862 ], [ 29.77862594936709, -1.499823965517241 ], [ 29.777008987341773, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34189, "El": 5 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.499823965517241 ], [ 29.77862594936709, -1.500093448275862 ], [ 29.778895443037975, -1.500093448275862 ], [ 29.778895443037975, -1.499823965517241 ], [ 29.77862594936709, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34190, "El": 8 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.499823965517241 ], [ 29.778895443037975, -1.500093448275862 ], [ 29.77916493670886, -1.500093448275862 ], [ 29.77916493670886, -1.499823965517241 ], [ 29.778895443037975, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34191, "El": 10 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77916493670886, -1.499823965517241 ], [ 29.77916493670886, -1.500362931034483 ], [ 29.779434430379748, -1.500362931034483 ], [ 29.779434430379748, -1.499823965517241 ], [ 29.77916493670886, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34192, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779434430379748, -1.499823965517241 ], [ 29.779434430379748, -1.500362931034483 ], [ 29.779703924050633, -1.500362931034483 ], [ 29.779703924050633, -1.499823965517241 ], [ 29.779434430379748, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34193, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.779703924050633, -1.499823965517241 ], [ 29.779703924050633, -1.500362931034483 ], [ 29.779973417721518, -1.500362931034483 ], [ 29.779973417721518, -1.499823965517241 ], [ 29.779703924050633, -1.499823965517241 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34194, "El": 3 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.77862594936709, -1.500093448275862 ], [ 29.77862594936709, -1.500362931034483 ], [ 29.778895443037975, -1.500362931034483 ], [ 29.778895443037975, -1.500093448275862 ], [ 29.77862594936709, -1.500093448275862 ] ] ] } },
{ "type": "Feature", "properties": { "fid": 34195, "El": 7 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 29.778895443037975, -1.500093448275862 ], [ 29.778895443037975, -1.500362931034483 ], [ 29.77916493670886, -1.500362931034483 ], [ 29.77916493670886, -1.500093448275862 ], [ 29.778895443037975, -1.500093448275862 ] ] ] } }
]
}

export default burera_bathymetry